import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 400,
      CanvasHeight: 260,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    
    DataItems:   [
      {SUBSYSTEM:"21-9921-9922-01",SUBSYSTEM_STATUS:"LQ HVAC UCPs, EDBs",MC_STATUS:"Full MC Complete",B_OV_PROG:11.59},
      {SUBSYSTEM:"21-9921-9922-02",SUBSYSTEM_STATUS:"LQ HVAC AHUs, Ducting and Dampers",LOSH:"Y",MC_STATUS:"A ITR in progress",B_OV_PROG:0.28},
      {SUBSYSTEM:"21-9921-9922-03",SUBSYSTEM_STATUS:"LQ HVAC Extracts, Ducting and Dampers",LOSH:"Y",MC_STATUS:"A ITR in progress",B_OV_PROG:0},
      {SUBSYSTEM:"21-9921-9922-04",SUBSYSTEM_STATUS:"LQ Galley AHUs, Ducting and Dampers",LOSH:"Y",MC_STATUS:"A ITR in progress",B_OV_PROG:0},
      {SUBSYSTEM:"21-9921-9922-05",SUBSYSTEM_STATUS:"LQ General Exhaust Air, Ducting and Dampers",LOSH:"Y",MC_STATUS:"A ITR in progress",B_OV_PROG:0},
      {SUBSYSTEM:"21-9921-9922-06",SUBSYSTEM_STATUS:"LQ Galley Hood Exhaust Fans, Ducting and Dampers",LOSH:"Y",MC_STATUS:"A ITR in progress",B_OV_PROG:0},
      {SUBSYSTEM:"21-9921-9922-07",SUBSYSTEM_STATUS:"LQ Toilet Exhaust Fans, Ducting and Dampers",LOSH:"Y",MC_STATUS:"A ITR in progress",B_OV_PROG:0},
      {SUBSYSTEM:"21-9921-9922-08",SUBSYSTEM_STATUS:"LQ Laundry Exhaust Fans, Ducting and Dampers",LOSH:"Y",MC_STATUS:"A ITR in progress",B_OV_PROG:0},
      {SUBSYSTEM:"21-9921-9922-09",SUBSYSTEM_STATUS:"LQ Sick Bay Exhaust Fans, Ducting and Dampers",MC_STATUS:"Full MC Complete",B_OV_PROG:14.81},
      {SUBSYSTEM:"21-9921-9922-11",SUBSYSTEM_STATUS:"LQ Laboratory Exhaust Fans, Ducting and Dampers",LOSH:"Y",MC_STATUS:"A ITR Completed",B_OV_PROG:0},
      {SUBSYSTEM:"21-9921-9922-12",SUBSYSTEM_STATUS:"LQ Provision Plant Exhaust Fans, Ducting and Dampers",LOSH:"Y",MC_STATUS:"Full MC Complete",B_OV_PROG:16.66},
      {SUBSYSTEM:"21-9921-9922-13",SUBSYSTEM_STATUS:"HULL Purge",LOSH:"Y",MC_STATUS:"Full MC Complete",B_OV_PROG:75},
      {SUBSYSTEM:"21-9921-9922-14",SUBSYSTEM_STATUS:"LQ Paint Store Supply Fans, Ducting and Dampers",LOSH:"Y",MC_STATUS:"A ITR in progress",B_OV_PROG:0},
      {SUBSYSTEM:"21-9921-9922-15",SUBSYSTEM_STATUS:"LQ Dining Room Exhaust Fan, Ducting and Dampers",LOSH:"Y",MC_STATUS:"Full MC Complete",B_OV_PROG:8}
      ]
}