import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 600,
      CanvasHeight: 330,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     

    DataItems: [
      { NO: 1,   MILESTONE: 'Battery Autonomy Tes',                  FORECAST: '2018-05-05', ACTUAL: '2018-05-05',},
      { NO: 2,   MILESTONE: 'Fast Rescue Boat & Davit weight Tes',   FORECAST: '2018-05-05', ACTUAL: '2018-05-05',},
      { NO: 3,   MILESTONE: 'LQ Elevator',                           FORECAST: '2018-05-05', ACTUAL: '2018-05-05',},
      { NO: 4,   MILESTONE: 'Nav-Aids & Fog Horn 24V DC Battery ',   FORECAST: '2018-05-05', ACTUAL: '2018-05-05',},
      { NO: 5,   MILESTONE: 'Hull Columns Elevators',                FORECAST: '2018-05-05', ACTUAL: '2018-05-05',},
      { NO: 6,   MILESTONE: 'Battery Autonomy Tes',                  FORECAST: '2018-05-05', ACTUAL: '2018-05-05',},
      { NO: 7,   MILESTONE: 'Fast Rescue Boat & Davit weight Tes',   FORECAST: '2018-05-05', ACTUAL: '2018-05-05',},
      { NO: 8,   MILESTONE: 'LQ Elevator',                           FORECAST: '2018-05-05', ACTUAL: '2018-05-05',},
      { NO: 9,   MILESTONE: 'Nav-Aids & Fog Horn 24V DC Battery ',   FORECAST: '2018-05-05', ACTUAL: '2018-05-05',},
      { NO: 10,   MILESTONE: 'Hull Columns Elevators',               FORECAST: '2018-05-05', ACTUAL: '2018-05-05',},
      { NO: 11,   MILESTONE: 'Battery Autonomy Tes',                 FORECAST: '2018-05-05', ACTUAL: '2018-05-05',},
      { NO: 12,   MILESTONE: 'Fast Rescue Boat & Davit weight Tes',  FORECAST: '2018-05-05', ACTUAL: '2018-05-05',},
    ],
}