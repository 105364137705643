import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1400,
      CanvasHeight: 1000,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },
    DataItems:  [

      {NO:1,PRIORITY:"P1",MODULE:"Topsides",SUBSYSTEM:"21-6070-6074-06",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables MW04",MC_PLAN:"2022-05-19",MC_FULL_DATE:"2022-06-07",A_OV_TOT:14,A_OV_COM:14,A_OV_REM:0,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_PROG:100,PA_OV_TOT:9,PA_OV_COM:9,PA_OV_REM:0,PA_OV_COM_LW:2,PA_OV_COM_TW:5,PA_OV_PROG:100,MC_STATUS:"Full MC Complete",MC_STATUS_CODE:"FULL_MC_COMPLETE"},
      {NO:2,PRIORITY:"P3",MODULE:"Topsides",SUBSYSTEM:"21-2200-2230-02",SUBSYSTEM_DESCR:"Interstage Heaters",MC_PLAN:"2022-05-26",MC_FULL_DATE:"2022-06-08",A_OV_TOT:102,A_OV_COM:91,A_OV_REM:11,A_OV_COM_LW:17,A_OV_COM_TW:0,A_OV_PROG:89.21,PA_OV_TOT:7,PA_OV_COM:7,PA_OV_REM:0,PA_OV_COM_LW:0,PA_OV_COM_TW:7,PA_OV_PROG:100,MC_STATUS:"Full MC Complete",MC_STATUS_CODE:"FULL_MC_COMPLETE"},
      {NO:3,PRIORITY:"P1",MODULE:"Topsides",SUBSYSTEM:"21-6800-6840-03",SUBSYSTEM_DESCR:"Fire Zone 6840-3 - Gas Turbine Generator Package 21-Z-861101",MC_PLAN:"2022-06-09",MC_FULL_DATE:"2022-06-06",A_OV_TOT:4,A_OV_COM:4,A_OV_REM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:100,PA_OV_TOT:5,PA_OV_COM:4,PA_OV_REM:1,PA_OV_COM_LW:2,PA_OV_COM_TW:1,PA_OV_PROG:80,MC_STATUS:"Full MC Complete",MC_STATUS_CODE:"FULL_MC_COMPLETE"},
      {NO:4,PRIORITY:"P2",MODULE:"LQ",SUBSYSTEM:"21-6800-6842-13",SUBSYSTEM_DESCR:"Fire Zone 6843-3 - Emergency Diesel Generators",MC_PLAN:"2022-06-23",MC_FULL_DATE:"2022-06-06",A_OV_PROG:100,MC_STATUS:"Full MC Complete",MC_STATUS_CODE:"FULL_MC_COMPLETE"},
      {NO:5,PRIORITY:"P2",MODULE:"Hull",SUBSYSTEM:"21-6600-6624-05",SUBSYSTEM_DESCR:"HULL Emergency Lighting Panel and Circuits ,Room: M104 21-ELP-662401",MC_PLAN:"2022-03-28",MC_PARTIAL_DATE:"2022-03-24",MC_FULL_DATE:"2022-06-08",A_OV_TOT:123,A_OV_COM:97,A_OV_REM:26,A_OV_COM_LW:48,A_OV_COM_TW:0,A_OV_PROG:78.86,PA_OV_TOT:11,PA_OV_COM:11,PA_OV_REM:0,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:100,MC_STATUS:"Full MC Complete",MC_STATUS_CODE:"FULL_MC_COMPLETE"},
      {NO:6,PRIORITY:"P1",MODULE:"Topsides",SUBSYSTEM:"21-6070-6074-05",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables ME04",MC_PLAN:"2022-05-19",MC_FULL_DATE:"2022-06-07",A_OV_TOT:12,A_OV_COM:12,A_OV_REM:0,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_PROG:100,PA_OV_TOT:9,PA_OV_COM:9,PA_OV_REM:0,PA_OV_COM_LW:2,PA_OV_COM_TW:5,PA_OV_PROG:100,MC_STATUS:"Partial MC Complete",MC_STATUS_CODE:"PARTIAL_MC_COMPLETE"},
      {NO:7,PRIORITY:"P1",MODULE:"Topsides",SUBSYSTEM:"21-6070-6074-07",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables MW05",MC_PLAN:"2022-05-26",MC_FULL_DATE:"2022-06-08",A_OV_TOT:12,A_OV_COM:12,A_OV_REM:0,A_OV_COM_LW:4,A_OV_COM_TW:0,A_OV_PROG:100,PA_OV_TOT:8,PA_OV_COM:8,PA_OV_REM:0,PA_OV_COM_LW:2,PA_OV_COM_TW:4,PA_OV_PROG:100,MC_STATUS:"Full MC Complete",MC_STATUS_CODE:"FULL_MC_COMPLETE"},
      {NO:8,PRIORITY:"P2",MODULE:"Hull",SUBSYSTEM:"21-6600-6640-05",SUBSYSTEM_DESCR:"Panel Control For External Lighting - 21-ECP-667680",MC_PLAN:"2022-06-17",MC_FULL_DATE:"2022-06-09",A_OV_TOT:37,A_OV_COM:36,A_OV_REM:1,A_OV_COM_LW:7,A_OV_COM_TW:2,A_OV_PROG:97.29,PA_OV_TOT:13,PA_OV_COM:13,PA_OV_REM:0,PA_OV_COM_LW:0,PA_OV_COM_TW:13,PA_OV_PROG:100,MC_STATUS:"Full MC Complete",MC_STATUS_CODE:"FULL_MC_COMPLETE"},
      {NO:9,PRIORITY:"P1",MODULE:"Topsides",SUBSYSTEM:"21-6200-6210-04",SUBSYSTEM_DESCR:"PAGA Field Equipment ME04andMW04",MC_PLAN:"2022-06-09",MC_FULL_DATE:"2022-06-07",A_OV_TOT:366,A_OV_COM:366,A_OV_REM:0,A_OV_COM_LW:0,A_OV_COM_TW:3,A_OV_PROG:100,PA_OV_TOT:63,PA_OV_COM:63,PA_OV_REM:0,PA_OV_COM_LW:34,PA_OV_COM_TW:1,PA_OV_PROG:100,MC_STATUS:"Full MC Complete",MC_STATUS_CODE:"FULL_MC_COMPLETE"},
      {NO:10,PRIORITY:"P1",MODULE:"Topsides",SUBSYSTEM:"21-6020-6022-07",SUBSYSTEM_DESCR:"PAS Remote IOs ,incl Junction Box and Multicore Cables MW05",MC_PLAN:"2022-05-26",MC_FULL_DATE:"2022-06-09",A_OV_TOT:30,A_OV_COM:30,A_OV_REM:0,A_OV_COM_LW:5,A_OV_COM_TW:0,A_OV_PROG:100,PA_OV_TOT:13,PA_OV_COM:13,PA_OV_REM:0,PA_OV_COM_LW:1,PA_OV_COM_TW:7,PA_OV_PROG:100,MC_STATUS:"Full MC Complete",MC_STATUS_CODE:"FULL_MC_COMPLETE"},
      {NO:11,PRIORITY:"P1",MODULE:"Topsides",SUBSYSTEM:"21-6800-6840-01",SUBSYSTEM_DESCR:"Fire Zone 6840-1 - Power Generation Module-MW04 ,Open Area",MC_PLAN:"2022-06-02",MC_FULL_DATE:"2022-06-07",A_OV_TOT:99,A_OV_COM:99,A_OV_REM:0,A_OV_COM_LW:2,A_OV_COM_TW:0,A_OV_PROG:100,PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PA_OV_COM_LW:0,PA_OV_COM_TW:2,PA_OV_PROG:100,MC_STATUS:"Full MC Complete",MC_STATUS_CODE:"FULL_MC_COMPLETE"},
      {NO:12,PRIORITY:"P1",MODULE:"Topsides",SUBSYSTEM:"21-6800-6840-04",SUBSYSTEM_DESCR:"Fire Zone 6840-4 - Gas Turbine Generator Package 21-Z-861201",MC_PLAN:"2022-06-09",MC_FULL_DATE:"2022-06-06",A_OV_TOT:4,A_OV_COM:4,A_OV_REM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:100,MC_STATUS:"Full MC Complete",MC_STATUS_CODE:"FULL_MC_COMPLETE"},
      {NO:13,PRIORITY:"P2",MODULE:"Hull",SUBSYSTEM:"21-8000-8013-02",SUBSYSTEM_DESCR:"Hot Water Distribution and Recirculation Pumps",MC_PLAN:"2022-05-18",MC_FULL_DATE:"2022-06-07",A_OV_TOT:232,A_OV_COM:228,A_OV_REM:4,A_OV_COM_LW:0,A_OV_COM_TW:1,A_OV_PROG:98.27,PA_OV_TOT:17,PA_OV_COM:17,PA_OV_REM:0,PA_OV_COM_LW:8,PA_OV_COM_TW:1,PA_OV_PROG:100,MC_STATUS:"Partial MC Complete",MC_STATUS_CODE:"PARTIAL_MC_COMPLETE"},
      {NO:14,PRIORITY:"P2",MODULE:"Topsides",SUBSYSTEM:"21-9976-9977-03",SUBSYSTEM_DESCR:"Topsides HVAC Extracts, Ducting and Dampers",MC_PLAN:"2022-05-31",MC_FULL_DATE:"2022-06-09",A_OV_TOT:165,A_OV_COM:165,A_OV_REM:0,A_OV_COM_LW:0,A_OV_COM_TW:8,A_OV_PROG:100,PA_OV_TOT:6,PA_OV_COM:6,PA_OV_REM:0,PA_OV_COM_LW:1,PA_OV_COM_TW:0,PA_OV_PROG:100,MC_STATUS:"Full MC Complete",MC_STATUS_CODE:"FULL_MC_COMPLETE"},
      {NO:15,PRIORITY:"P2",MODULE:"Topsides",SUBSYSTEM:"21-9976-9976-02",SUBSYSTEM_DESCR:"Topsides Battery Exhaust Fans, Ducting and Dampers",MC_PLAN:"2022-05-31",MC_FULL_DATE:"2022-06-09",A_OV_TOT:79,A_OV_COM:79,A_OV_REM:0,A_OV_COM_LW:2,A_OV_COM_TW:1,A_OV_PROG:100,PA_OV_TOT:6,PA_OV_COM:5,PA_OV_REM:1,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:83.33,MC_STATUS:"Full MC Complete",MC_STATUS_CODE:"FULL_MC_COMPLETE"},
      {NO:16,PRIORITY:"P1",MODULE:"Topsides",SUBSYSTEM:"21-9976-9977-01",SUBSYSTEM_DESCR:"Topsides HVAC UCPs, EDBs",MC_PLAN:"2022-05-31",MC_FULL_DATE:"2022-06-06",A_OV_TOT:24,A_OV_COM:24,A_OV_REM:0,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_PROG:100,PA_OV_TOT:4,PA_OV_COM:4,PA_OV_REM:0,PA_OV_COM_LW:1,PA_OV_COM_TW:1,PA_OV_PROG:100,MC_STATUS:"Full MC Complete",MC_STATUS_CODE:"FULL_MC_COMPLETE"},
      {NO:17,PRIORITY:"P1A",MODULE:"Hull",SUBSYSTEM:"21-9330-9334-01",SUBSYSTEM_DESCR:"HULL Valves HPU",MC_PLAN:"2022-02-25",MC_PARTIAL_DATE:"2022-02-24",MC_FULL_DATE:"2022-06-08",A_OV_TOT:104,A_OV_COM:104,A_OV_REM:0,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_PROG:100,PA_OV_TOT:48,PA_OV_COM:48,PA_OV_REM:0,PA_OV_COM_LW:1,PA_OV_COM_TW:8,PA_OV_PROG:100,MC_STATUS:"Partial MC Complete",MC_STATUS_CODE:"PARTIAL_MC_COMPLETE"}

      // {TABLE:"WEEKLY",TYPE:"1.Priority MC Complete",MC_FULL_DATE:"2020-05-16",NO:1,PRIORITY:"P1",MODULE:"Hull",SUBSYSTEM:"21-6600-6674-01",SUBSYSTEM_DESCR:"HULL Normal and Essential 690 V Swtchboard 21-ESB-667401",MC_PLAN:"2021-09-29",A_OV_TOT:317,A_OV_COM:315,A_OV_REM:2,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:99.36,PA_OV_TOT:9,PA_OV_COM:9,PA_OV_REM:0,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:100,MC_STATUS:"Partial MC Complete"},
      // {TABLE:"WEEKLY",TYPE:"1.Priority MC Complete",MC_FULL_DATE:"2020-05-16",NO:2,PRIORITY:"P1",MODULE:"Hull",SUBSYSTEM:"21-6600-6675-01",SUBSYSTEM_DESCR:"HULL Normal and Essential 400 V Switchboard 21-ESB-667601",MC_PLAN:"2021-10-20",A_OV_TOT:60,A_OV_COM:58,A_OV_REM:2,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:96.66,PA_OV_TOT:8,PA_OV_COM:8,PA_OV_REM:0,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:100,MC_STATUS:"Partial MC Complete"},
      // {TABLE:"WEEKLY",TYPE:"1.Priority MC Complete",MC_FULL_DATE:"2020-05-16",NO:4,PRIORITY:"P1A",MODULE:"LQ",SUBSYSTEM:"21-6600-6660-01",SUBSYSTEM_DESCR:"Emergency 690 V Switchboard 21-ESB-666001",MC_PLAN:"2021-10-29",A_OV_TOT:233,A_OV_COM:221,A_OV_REM:12,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:94.84,PA_OV_TOT:10,PA_OV_COM:10,PA_OV_REM:0,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:100,MC_STATUS:"Partial MC Complete"},
      // {TABLE:"WEEKLY",TYPE:"1.Priority MC Complete",MC_FULL_DATE:"2020-05-16",NO:3,PRIORITY:"P1A",MODULE:"LQ",SUBSYSTEM:"21-6700-6701-01",SUBSYSTEM_DESCR:"Main LQ UPS System ,Incl Batteries, Breakers and Subdistribution Board",MC_PLAN:"2021-10-29",A_OV_TOT:46,A_OV_COM:39,A_OV_REM:7,A_OV_COM_LW:0,A_OV_COM_TW:1,A_OV_PROG:84.78,PA_OV_TOT:31,PA_OV_COM:31,PA_OV_REM:0,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:100,MC_STATUS:"Full MC Complete"},
      // {TABLE:"WEEKLY",TYPE:"1.Priority MC Complete",MC_FULL_DATE:"2020-05-16",NO:6,PRIORITY:"P1A",MODULE:"LQ",SUBSYSTEM:"21-6030-6036-02",SUBSYSTEM_DESCR:"PDBs Inside CER",MC_PLAN:"2021-11-25",A_OV_TOT:29,A_OV_COM:21,A_OV_REM:8,A_OV_COM_LW:1,A_OV_COM_TW:1,A_OV_PROG:72.41,PA_OV_TOT:20,PA_OV_COM:20,PA_OV_REM:0,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:100,MC_STATUS:"Partial MC Complete"},
      // {TABLE:"WEEKLY",TYPE:"1.Priority MC Complete",MC_FULL_DATE:"2020-05-16",NO:5,PRIORITY:"P1A",MODULE:"Hull",SUBSYSTEM:"21-6030-6036-01",SUBSYSTEM_DESCR:"PDBs Inside HER",MC_PLAN:"2021-11-25",A_OV_TOT:17,A_OV_COM:15,A_OV_REM:2,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:88.23,PA_OV_TOT:19,PA_OV_COM:19,PA_OV_REM:0,PA_OV_COM_LW:0,PA_OV_COM_TW:1,PA_OV_PROG:100,MC_STATUS:"Partial MC Complete"},
      // {TABLE:"WEEKLY",TYPE:"1.Priority MC Complete",MC_FULL_DATE:"2020-05-16",NO:7,PRIORITY:"P1A",MODULE:"LQ",SUBSYSTEM:"21-6020-6021-02",SUBSYSTEM_DESCR:"PAS Marshalling - LQ ,CER",MC_PLAN:"2022-02-10",A_OV_TOT:15,A_OV_COM:15,A_OV_REM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:100,PA_OV_TOT:11,PA_OV_COM:11,PA_OV_REM:0,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:100,MC_STATUS:"Full MC Complete"},
      // {TABLE:"WEEKLY",TYPE:"1.Priority MC Complete",MC_FULL_DATE:"2020-05-16",NO:8,PRIORITY:"P1A",MODULE:"Hull",SUBSYSTEM:"21-6020-6021-01",SUBSYSTEM_DESCR:"PAS Marshalling - Machinery Space ,HER",MC_PLAN:"2022-02-16",A_OV_TOT:48,A_OV_COM:48,A_OV_REM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:100,PA_OV_TOT:20,PA_OV_COM:20,PA_OV_REM:0,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:100,MC_STATUS:"Full MC Complete"},
      // {TABLE:"WEEKLY",TYPE:"1.Priority MC Complete",MC_FULL_DATE:"2020-05-16",NO:9,PRIORITY:"P2",MODULE:"Hull",SUBSYSTEM:"21-8100-8120-01",SUBSYSTEM_DESCR:"HULL Air Compressor 21-Z-811002",MC_PLAN:"2022-02-16",A_OV_TOT:53,A_OV_COM:53,A_OV_REM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:100,PA_OV_TOT:15,PA_OV_COM:14,PA_OV_REM:1,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:93.33,MC_STATUS:"Full MC Complete"},
],
}