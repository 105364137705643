const MasterProgress = [

  { Lv1: 'PROJECT', Lv2: 'OVERALL', Lv3: null, PLAN: 33.01, ACTUAL: 33.45, CERT: 33.85, DELTA: -33.56, },
  { Lv1: 'PROJECT', Lv2: 'CONSTRUCTION', Lv3: null, PLAN: 27.01, ACTUAL: 18.45, CERT: 13.85, DELTA: -8.56, },
  { Lv1: 'HULL', Lv2: 'OVERALL', Lv3: null, PLAN: 45.68, ACTUAL: 36.55, CERT: 28.12, DELTA: -9.13, },
  { Lv1: 'HULL', Lv2: 'COMMON', Lv3: 'OVERALL', PLAN: 6.59, ACTUAL: 1.06, CERT: 0, DELTA: -5.53, },
  { Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'OVERALL', PLAN: 55.13, ACTUAL: 45.42, CERT: 35.27, DELTA: -9.7, },
  { Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'HL02', PLAN: 59.5, ACTUAL: 53.6, CERT: 42.01, DELTA: -5.9, },
  { Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'MD00a', PLAN: 0, ACTUAL: 0, CERT: 0, DELTA: 0, },
  { Lv1: 'HULL', Lv2: 'HULL-AFT', Lv3: 'RB01-02', PLAN: 29.6, ACTUAL: 0, CERT: 0, DELTA: -29.6, },
  { Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'OVERALL', PLAN: 57.2, ACTUAL: 49.47, CERT: 38.77, DELTA: -7.72, },
  { Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'HL03', PLAN: 55.13, ACTUAL: 45.42, CERT: 35.27, DELTA: -9.71, },
  { Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'MD00f', PLAN: 0, ACTUAL: 0, CERT: 0, DELTA: 0, },
  { Lv1: 'HULL', Lv2: 'HULL-FOR', Lv3: 'VS04-5', PLAN: 0, ACTUAL: 0, CERT: 0, DELTA: 0, },
  { Lv1: 'HULL', Lv2: 'LQ', Lv3: 'OVERALL', PLAN: 30.4, ACTUAL: 14.3, CERT: 8.81, DELTA: -16.1, },
  { Lv1: 'HULL', Lv2: 'LQ', Lv3: 'LQ00', PLAN: 30.4, ACTUAL: 14.3, CERT: 8.81, DELTA: -16.1, },
  { Lv1: 'HULL', Lv2: 'LQ', Lv3: 'HD01', PLAN: 41.32, ACTUAL: 19.19, CERT: 0, DELTA: -22.13, },
  { Lv1: 'TOPSIDES', Lv2: 'OVERALL', Lv3: null, PLAN: 9.27, ACTUAL: 1.25, CERT: 0.29, DELTA: -8.02, },
  { Lv1: 'TOPSIDES', Lv2: 'COMMON', Lv3: 'OVERALL', PLAN: 4.5, ACTUAL: 0.83, CERT: 0.17, DELTA: -3.67, },
  { Lv1: 'TOPSIDES', Lv2: 'COMMON', Lv3: 'FL01', PLAN: 0, ACTUAL: 0, CERT: 0, DELTA: 0, },
  { Lv1: 'TOPSIDES', Lv2: 'COMMON', Lv3: 'KO01', PLAN: 0, ACTUAL: 0, CERT: 0, DELTA: 0, },
  { Lv1: 'TOPSIDES', Lv2: 'COMMON', Lv3: 'TSRC01', PLAN: 0, ACTUAL: 0, CERT: 0, DELTA: 0, },
  { Lv1: 'TOPSIDES', Lv2: 'COMMON', Lv3: 'TSRC02', PLAN: 0, ACTUAL: 0, CERT: 0, DELTA: 0, },
  { Lv1: 'TOPSIDES', Lv2: 'COMMON', Lv3: 'TSRC03', PLAN: 0, ACTUAL: 0, CERT: 0, DELTA: 0, },
  { Lv1: 'TOPSIDES', Lv2: 'COMMON', Lv3: 'TSRC04', PLAN: 0, ACTUAL: 0, CERT: 0, DELTA: 0, },
  { Lv1: 'TOPSIDES', Lv2: 'COMMON', Lv3: 'TSRC05', PLAN: 0, ACTUAL: 0, CERT: 0, DELTA: 0, },
  { Lv1: 'TOPSIDES', Lv2: 'COMMON', Lv3: 'LD00DA', PLAN: 0, ACTUAL: 0, CERT: 0, DELTA: 0, },
  { Lv1: 'TOPSIDES', Lv2: 'MODULES', Lv3: 'OVERALL', PLAN: 10.8, ACTUAL: 1.4, CERT: 0.33, DELTA: -9.4, },
  { Lv1: 'TOPSIDES', Lv2: 'MODULES', Lv3: 'MW01', PLAN: 16, ACTUAL: 1.44, CERT: 0.73, DELTA: -14.56, },
  { Lv1: 'TOPSIDES', Lv2: 'MODULES', Lv3: 'MW02', PLAN: 14.67, ACTUAL: 1.4, CERT: 0.48, DELTA: -13.27, },
  { Lv1: 'TOPSIDES', Lv2: 'MODULES', Lv3: 'MW04', PLAN: 7.96, ACTUAL: 1.75, CERT: 0.02, DELTA: -6.21, },
  { Lv1: 'TOPSIDES', Lv2: 'MODULES', Lv3: 'MW05', PLAN: 11.09, ACTUAL: 2.19, CERT: 0.01, DELTA: -8.9, },
  { Lv1: 'TOPSIDES', Lv2: 'MODULES', Lv3: 'ME01', PLAN: 16.45, ACTUAL: 1.3, CERT: 0.2, DELTA: -15.15, },
  { Lv1: 'TOPSIDES', Lv2: 'MODULES', Lv3: 'ME02', PLAN: 12.42, ACTUAL: 1.39, CERT: 0.56, DELTA: -11.03, },
  { Lv1: 'TOPSIDES', Lv2: 'MODULES', Lv3: 'ME04', PLAN: 7.12, ACTUAL: 2.53, CERT: 0.01, DELTA: -4.59, },
  { Lv1: 'TOPSIDES', Lv2: 'MODULES', Lv3: 'ME05', PLAN: 4.13, ACTUAL: 1.76, CERT: 0.87, DELTA: -2.37, },
  { Lv1: 'TOPSIDES', Lv2: 'INTEGRATION', Lv3: null, PLAN: 0, ACTUAL: 0, CERT: 0, DELTA: 0, },
  { Lv1: 'MOORING', Lv2: 'OVERALL', Lv3: 'OVERALL', PLAN: 0, ACTUAL: 0, CERT: 0, DELTA: 0, },
  { Lv1: 'MOORING', Lv2: 'MOORING', Lv3: null, PLAN: 0, ACTUAL: 0, CERT: 0, DELTA: 0, },


]


export default MasterProgress