import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1100,
      CanvasHeight: 600,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#e0e0e0',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 380,
      CanvasChartHeight: 280,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Overall Progress', x:450, y: 10, font: 'roboto', class: 'regular', size: 12, color: '#424242', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: 50, y: 20, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'start' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: 30,
          y: 20,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'start'
        },
      ]
    },
    DataItems: [
      {WK_NO:"W2020_07",CUM_PLAN_PROG:1.300513464655841,CUM_ACTUAL_PROG:0.8128648720790213,WK_PLAN_PROG:0.05762338102471735,WK_ACTUAL_PROG:0},
      {WK_NO:"W2020_08",CUM_PLAN_PROG:1.357549748296861,CUM_ACTUAL_PROG:0.8128648720790215,WK_PLAN_PROG:0.05703628364102048,WK_ACTUAL_PROG:0},
      {WK_NO:"W2020_09",CUM_PLAN_PROG:1.429996622214358,CUM_ACTUAL_PROG:0.8132514954480496,WK_PLAN_PROG:0.07244687391749664,WK_ACTUAL_PROG:0.0003866233690281425},
      {WK_NO:"W2020_10",CUM_PLAN_PROG:1.546269008947945,CUM_ACTUAL_PROG:0.8132514954480496,WK_PLAN_PROG:0.1162723867335875,WK_ACTUAL_PROG:0},
      {WK_NO:"W2020_11",CUM_PLAN_PROG:1.680873118224747,CUM_ACTUAL_PROG:0.837662174949052,WK_PLAN_PROG:0.1346041092768015,WK_ACTUAL_PROG:0.02441067950100246},
      {WK_NO:"W2020_12",CUM_PLAN_PROG:1.878388035215946,CUM_ACTUAL_PROG:1.201812536270895,WK_PLAN_PROG:0.1975149169911991,WK_ACTUAL_PROG:0.3641503613218426},
      {WK_NO:"W2020_13",CUM_PLAN_PROG:2.113839387604839,CUM_ACTUAL_PROG:1.314045338805771,WK_PLAN_PROG:0.235451352388893,WK_ACTUAL_PROG:0.1122328025348763},
      {WK_NO:"W2020_14",CUM_PLAN_PROG:2.349350715370762,CUM_ACTUAL_PROG:1.406991390208096,WK_PLAN_PROG:0.2355113277659228,WK_ACTUAL_PROG:0.0929460514023246},
      {WK_NO:"W2020_15",CUM_PLAN_PROG:2.633558262865952,CUM_ACTUAL_PROG:1.44587648101467,WK_PLAN_PROG:0.2842075474951901,WK_ACTUAL_PROG:0.03888509080657471},
      {WK_NO:"W2020_16",CUM_PLAN_PROG:2.972921432181112,CUM_ACTUAL_PROG:1.689162732789789,WK_PLAN_PROG:0.3393631693151606,WK_ACTUAL_PROG:0.2432862517751183},
      {WK_NO:"W2020_17",CUM_PLAN_PROG:3.209236328028348,CUM_ACTUAL_PROG:1.78031664959654,WK_PLAN_PROG:0.2363148958472356,WK_ACTUAL_PROG:0.09115391680675161},
      {WK_NO:"W2020_18",CUM_PLAN_PROG:3.445776482064371,CUM_ACTUAL_PROG:1.855875585020476,WK_PLAN_PROG:0.2365401540360228,WK_ACTUAL_PROG:0.07555893542393648},
      {WK_NO:"W2020_19",CUM_PLAN_PROG:3.718221147862286,CUM_ACTUAL_PROG:1.993038436276443,WK_PLAN_PROG:0.2724446657979146,WK_ACTUAL_PROG:0.1371628512559665},
      {WK_NO:"W2020_20",CUM_PLAN_PROG:3.861905764654191,CUM_ACTUAL_PROG:2.057489799162339,WK_PLAN_PROG:0.143684616791906,WK_ACTUAL_PROG:0.0644513628858961},
      {WK_NO:"W2020_21",CUM_PLAN_PROG:4.015313331793773,CUM_ACTUAL_PROG:2.161274669831763,WK_PLAN_PROG:0.1534075671395816,WK_ACTUAL_PROG:0.1037848706694239},
      {WK_NO:"W2020_22",CUM_PLAN_PROG:4.176366604834319,CUM_ACTUAL_PROG:2.202260072249373,WK_PLAN_PROG:0.1610532730405469,WK_ACTUAL_PROG:0.04098540241760972},
      {WK_NO:"W2020_23",CUM_PLAN_PROG:4.298948473960684,CUM_ACTUAL_PROG:2.401067995087402,WK_PLAN_PROG:0.1225818691263646,WK_ACTUAL_PROG:0.1988079228380297},
      {WK_NO:"W2020_24",CUM_PLAN_PROG:4.425186329302984,CUM_ACTUAL_PROG:2.450264974349823,WK_PLAN_PROG:0.1262378553422996,WK_ACTUAL_PROG:0.04919697926242071},
      {WK_NO:"W2020_25",CUM_PLAN_PROG:4.525554286751133,CUM_ACTUAL_PROG:2.516588646441441,WK_PLAN_PROG:0.1003679574481486,WK_ACTUAL_PROG:0.06632367209161778},
      {WK_NO:"W2020_26",CUM_PLAN_PROG:4.641253627089466,CUM_ACTUAL_PROG:2.929014541348445,WK_PLAN_PROG:0.1156993403383335,WK_ACTUAL_PROG:0.4124258949070041},
      {WK_NO:"W2020_27",CUM_PLAN_PROG:4.774342808878066,CUM_ACTUAL_PROG:2.983256256588744,WK_PLAN_PROG:0.1330891817885991,WK_ACTUAL_PROG:0.0542417152402989},
      {WK_NO:"W2020_28",CUM_PLAN_PROG:4.923253936001271,CUM_ACTUAL_PROG:3.504446714213092,WK_PLAN_PROG:0.1489111271232064,WK_ACTUAL_PROG:0.5211904576243481},
      {WK_NO:"W2020_29",CUM_PLAN_PROG:5.087466735746944,CUM_ACTUAL_PROG:3.817318004459727,WK_PLAN_PROG:0.1642127997456728,WK_ACTUAL_PROG:0.3128712902466347},
      {WK_NO:"W2020_30",CUM_PLAN_PROG:5.264376061884332,CUM_ACTUAL_PROG:4.045136078649545,WK_PLAN_PROG:0.1769093261373878,WK_ACTUAL_PROG:0.2278180741898189},
      {WK_NO:"W2020_31",CUM_PLAN_PROG:5.479920207780678,CUM_ACTUAL_PROG:4.323131379741085,WK_PLAN_PROG:0.2155441458963466,WK_ACTUAL_PROG:0.2779953010915384},
      {WK_NO:"W2020_32",CUM_PLAN_PROG:5.827832443837285,CUM_ACTUAL_PROG:4.409191925705212,WK_PLAN_PROG:0.3479122360566058,WK_ACTUAL_PROG:0.08606054596412713},
      {WK_NO:"W2020_33",CUM_PLAN_PROG:6.265787260237203,CUM_ACTUAL_PROG:4.532079816212471,WK_PLAN_PROG:0.4379548163999177,WK_ACTUAL_PROG:0.1228878905072603},
      {WK_NO:"W2020_34",CUM_PLAN_PROG:6.467274835534455,CUM_ACTUAL_PROG:4.620801172515248,WK_PLAN_PROG:0.2014875752972526,WK_ACTUAL_PROG:0.08872135630277564},
      {WK_NO:"W2020_35",CUM_PLAN_PROG:6.712687918849168,CUM_ACTUAL_PROG:4.77552650343327,WK_PLAN_PROG:0.2454130833147137,WK_ACTUAL_PROG:0.1547253309180229},
      {WK_NO:"W2020_36",CUM_PLAN_PROG:7.004345725545358,CUM_ACTUAL_PROG:4.957330987003211,WK_PLAN_PROG:0.2916578066961881,WK_ACTUAL_PROG:0.1818044835699408},
      {WK_NO:"W2020_37",CUM_PLAN_PROG:7.34858070021009,CUM_ACTUAL_PROG:5.158267720815707,WK_PLAN_PROG:0.3442349746647335,WK_ACTUAL_PROG:0.2009367338124966},
      {WK_NO:"W2020_38",CUM_PLAN_PROG:7.729384017113114,CUM_ACTUAL_PROG:5.329513077766298,WK_PLAN_PROG:0.380803316903024,WK_ACTUAL_PROG:0.1712453569505905},
      {WK_NO:"W2020_39",CUM_PLAN_PROG:8.210877819382567,CUM_ACTUAL_PROG:5.559117970569402,WK_PLAN_PROG:0.481493802269452,WK_ACTUAL_PROG:0.2296048928031046},
      {WK_NO:"W2020_40",CUM_PLAN_PROG:8.81929320678946,CUM_ACTUAL_PROG:5.641344564876728,WK_PLAN_PROG:0.6084153874068953,WK_ACTUAL_PROG:0.08222659430732523},
      {WK_NO:"W2020_41",CUM_PLAN_PROG:9.193185630502493,CUM_ACTUAL_PROG:5.72640017275068,WK_PLAN_PROG:0.3738924237130301,WK_ACTUAL_PROG:0.08505560787395194},
      {WK_NO:"W2020_42",CUM_PLAN_PROG:9.557130595464994,CUM_ACTUAL_PROG:6.008823098505378,WK_PLAN_PROG:0.363944964962503,WK_ACTUAL_PROG:0.2824229257546973},
      {WK_NO:"W2020_43",CUM_PLAN_PROG:10.03637770916403,CUM_ACTUAL_PROG:6.075247751766871,WK_PLAN_PROG:0.4792471136990392,WK_ACTUAL_PROG:0.06642465326149397},
      {WK_NO:"W2020_44",CUM_PLAN_PROG:10.51193643089898,CUM_ACTUAL_PROG:6.267759126019522,WK_PLAN_PROG:0.4755587217349447,WK_ACTUAL_PROG:0.1925113742526506},
      {WK_NO:"W2020_45",CUM_PLAN_PROG:11.03559524380789,CUM_ACTUAL_PROG:6.599197935239489,WK_PLAN_PROG:0.5236588129089144,WK_ACTUAL_PROG:0.3314388092199676},
      {WK_NO:"W2020_46",CUM_PLAN_PROG:11.70624293889095,CUM_ACTUAL_PROG:8.26820512493683,WK_PLAN_PROG:0.670647695083054,WK_ACTUAL_PROG:1.669007189697342},
      {WK_NO:"W2020_47",CUM_PLAN_PROG:12.48047519933059,CUM_ACTUAL_PROG:8.638558978911522,WK_PLAN_PROG:0.7742322604396445,WK_ACTUAL_PROG:0.3703538539746901},
      {WK_NO:"W2020_48",CUM_PLAN_PROG:13.37178371075058,CUM_ACTUAL_PROG:9.14425437537583,WK_PLAN_PROG:0.8913085114199893,WK_ACTUAL_PROG:0.5056953964643094,WKS4_TREND:9.14425437537583},
      {WK_NO:"W2020_49",CUM_PLAN_PROG:14.41245574763405,WK_PLAN_PROG:1.04067203688347,WKS4_TREND:9.863377815633637},
      {WK_NO:"W2020_50",CUM_PLAN_PROG:15.67554787608138,WK_PLAN_PROG:1.263092128447328,WKS4_TREND:10.58250125589144},
      {WK_NO:"W2020_51",CUM_PLAN_PROG:17.10327383157108,WK_PLAN_PROG:1.427725955489708,WKS4_TREND:11.30162469614925},
      {WK_NO:"W2020_52",CUM_PLAN_PROG:18.09160098416902,WK_PLAN_PROG:0.9883271525979332,WKS4_TREND:12.02074813640706},
      {WK_NO:"W2021_01",CUM_PLAN_PROG:19.19522888715272,WK_PLAN_PROG:1.103627902983699,WKS4_TREND:12.73987157666486},
      {WK_NO:"W2021_02",CUM_PLAN_PROG:20.27460553761901,WK_PLAN_PROG:1.079376650466292,WKS4_TREND:13.45899501692267},
      {WK_NO:"W2021_03",CUM_PLAN_PROG:21.48253687447233,WK_PLAN_PROG:1.207931336853327,WKS4_TREND:14.17811845718048},
      {WK_NO:"W2021_04",CUM_PLAN_PROG:23.21449300599679,WK_PLAN_PROG:1.73195613152445,WKS4_TREND:14.89724189743828},
      {WK_NO:"W2021_05",CUM_PLAN_PROG:25.1593060693672,WK_PLAN_PROG:1.944813063370407,WKS4_TREND:15.61636533769609},
      {WK_NO:"W2021_06",CUM_PLAN_PROG:27.17897376424031,WK_PLAN_PROG:2.019667694873113,WKS4_TREND:16.3354887779539}
    ],
}