import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1400,
      CanvasHeight: 990,
      CanvasBorderWeight: 0.3,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.1,

      CanvasChartX: 50,
      CanvasChartY: 50,
      CanvasChartWidth: 480,
      CanvasChartHeight: 250,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

    DataItems: [
      { SYSTEM: 1010, SYS_DESCR: 'Production Flowlines- FPU Components 1 & 2', COMM_DATE: '2020-07-22', PRIORITY: 'M6', A_T: 583, A_A: 125, A_O: 458, A_P: 21.44, B_T: 91, B_A: 0, B_O: 91, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 67, PA_A: 56, PA_O: 11, PB_T: 145, PB_A: 127, PB_O: 18, MC_T: 2, MC_A: 0, MC_R: 2, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 1020, SYS_DESCR: 'Production Flowlines- FPU Components 3 & 4', COMM_DATE: '2020-07-22', PRIORITY: 'M6', A_T: 568, A_A: 110, A_O: 458, A_P: 19.37, B_T: 90, B_A: 0, B_O: 90, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 26, PA_A: 18, PA_O: 8, PB_T: 118, PB_A: 110, PB_O: 8, MC_T: 2, MC_A: 0, MC_R: 2, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 1030, SYS_DESCR: 'Future Production Flowlines - FPU Components', COMM_DATE: null, PRIORITY: null, A_T: 28, A_A: 8, A_O: 20, A_P: 28.57, B_T: null, B_A: null, B_O: null, B_P: null, C_T: null, C_A: null, C_O: null, PA_T: null, PA_A: null, PA_O: null, PB_T: null, PB_A: null, PB_O: null, MC_T: 2, MC_A: 0, MC_R: 2, STATUS: null, ASSOCIATED: 'N', CAT: null, },
      { SYSTEM: 1090, SYS_DESCR: 'Test Flowlines (Future) - FPU Components', COMM_DATE: null, PRIORITY: null, A_T: 14, A_A: 4, A_O: 10, A_P: 28.57, B_T: null, B_A: null, B_O: null, B_P: null, C_T: null, C_A: null, C_O: null, PA_T: null, PA_A: null, PA_O: null, PB_T: null, PB_A: null, PB_O: null, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: null, ASSOCIATED: 'N', CAT: null, },
      { SYSTEM: 1911, SYS_DESCR: 'Hydraulic Power System for Topsides Valves', COMM_DATE: '2020-06-10', PRIORITY: 'M6', A_T: 455, A_A: 292, A_O: 163, A_P: 64.18, B_T: 45, B_A: 0, B_O: 45, B_P: 0, C_T: 3, C_A: 0, C_O: 3, PA_T: 43, PA_A: 35, PA_O: 8, PB_T: 71, PB_A: 65, PB_O: 6, MC_T: 2, MC_A: 0, MC_R: 2, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 1912, SYS_DESCR: 'Hydraulic Power System for Subsea Controls', COMM_DATE: '2020-06-11', PRIORITY: 'M6', A_T: 1022, A_A: 817, A_O: 205, A_P: 79.94, B_T: 146, B_A: 0, B_O: 146, B_P: 0, C_T: 3, C_A: 0, C_O: 3, PA_T: 90, PA_A: 76, PA_O: 14, PB_T: 47, PB_A: 42, PB_O: 5, MC_T: 2, MC_A: 0, MC_R: 2, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 2110, SYS_DESCR: 'Test Separation', COMM_DATE: '2020-06-25', PRIORITY: 'M5', A_T: 395, A_A: 94, A_O: 301, A_P: 23.8, B_T: 40, B_A: 0, B_O: 40, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 28, PA_A: 23, PA_O: 5, PB_T: 58, PB_A: 51, PB_O: 7, MC_T: 2, MC_A: 0, MC_R: 2, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 2120, SYS_DESCR: 'Well Clean-Up', COMM_DATE: '2020-08-19', PRIORITY: 'M5', A_T: 594, A_A: 228, A_O: 366, A_P: 38.38, B_T: 59, B_A: 0, B_O: 59, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 55, PA_A: 45, PA_O: 10, PB_T: 87, PB_A: 78, PB_O: 9, MC_T: 3, MC_A: 0, MC_R: 3, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 2210, SYS_DESCR: 'HP Separation', COMM_DATE: '2020-08-06', PRIORITY: 'M5', A_T: 475, A_A: 112, A_O: 363, A_P: 23.58, B_T: 46, B_A: 0, B_O: 46, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 25, PA_A: 19, PA_O: 6, PB_T: 86, PB_A: 81, PB_O: 5, MC_T: 4, MC_A: 0, MC_R: 4, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 2220, SYS_DESCR: 'LP Separation', COMM_DATE: '2020-07-20', PRIORITY: 'M5', A_T: 568, A_A: 189, A_O: 379, A_P: 33.27, B_T: 50, B_A: 0, B_O: 50, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 49, PA_A: 44, PA_O: 5, PB_T: 57, PB_A: 50, PB_O: 7, MC_T: 3, MC_A: 0, MC_R: 3, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 2400, SYS_DESCR: 'Liquid Export', COMM_DATE: '2020-08-12', PRIORITY: 'M5', A_T: 2578, A_A: 542, A_O: 2036, A_P: 21.02, B_T: 620, B_A: 0, B_O: 620, B_P: 0, C_T: 4, C_A: 0, C_O: 4, PA_T: 1731, PA_A: 1183, PA_O: 548, PB_T: 591, PB_A: 404, PB_O: 187, MC_T: 10, MC_A: 0, MC_R: 10, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 3000, SYS_DESCR: 'LP HP Export Compressor', COMM_DATE: '2020-08-20', PRIORITY: 'M7', A_T: 358, A_A: 156, A_O: 202, A_P: 43.58, B_T: 262, B_A: 0, B_O: 262, B_P: 0, C_T: 4, C_A: 0, C_O: 4, PA_T: 415, PA_A: 286, PA_O: 129, PB_T: 267, PB_A: 109, PB_O: 158, MC_T: 2, MC_A: 0, MC_R: 2, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 3010, SYS_DESCR: 'LP Gas Compression', COMM_DATE: '2020-07-14', PRIORITY: 'M7', A_T: 1173, A_A: 546, A_O: 627, A_P: 46.55, B_T: 181, B_A: 0, B_O: 181, B_P: 0, C_T: null, C_A: null, C_O: null, PA_T: 110, PA_A: 90, PA_O: 20, PB_T: 225, PB_A: 204, PB_O: 21, MC_T: 3, MC_A: 0, MC_R: 3, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 3020, SYS_DESCR: 'HP Gas Compression', COMM_DATE: '2020-07-14', PRIORITY: 'M7', A_T: 1312, A_A: 344, A_O: 968, A_P: 26.22, B_T: 184, B_A: 0, B_O: 184, B_P: 0, C_T: null, C_A: null, C_O: null, PA_T: 53, PA_A: 48, PA_O: 5, PB_T: 228, PB_A: 199, PB_O: 29, MC_T: 3, MC_A: 0, MC_R: 3, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 3030, SYS_DESCR: 'Export Gas Compression', COMM_DATE: '2020-07-14', PRIORITY: 'M7', A_T: 799, A_A: 227, A_O: 572, A_P: 28.41, B_T: 140, B_A: 0, B_O: 140, B_P: 0, C_T: null, C_A: null, C_O: null, PA_T: 22, PA_A: 20, PA_O: 2, PB_T: 134, PB_A: 122, PB_O: 12, MC_T: 2, MC_A: 0, MC_R: 2, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 3040, SYS_DESCR: 'VRU Gas Compression', COMM_DATE: '2020-08-20', PRIORITY: 'M5', A_T: 2115, A_A: 1472, A_O: 643, A_P: 69.6, B_T: 335, B_A: 0, B_O: 335, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 104, PA_A: 49, PA_O: 55, PB_T: 143, PB_A: 109, PB_O: 34, MC_T: 3, MC_A: 0, MC_R: 3, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 3200, SYS_DESCR: 'Glycol Dehydration & Regeneration System', COMM_DATE: '2020-07-25', PRIORITY: 'M7', A_T: 1241, A_A: 542, A_O: 699, A_P: 43.67, B_T: 153, B_A: 0, B_O: 153, B_P: 0, C_T: 4, C_A: 0, C_O: 4, PA_T: 209, PA_A: 175, PA_O: 34, PB_T: 269, PB_A: 241, PB_O: 28, MC_T: 2, MC_A: 0, MC_R: 2, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 3400, SYS_DESCR: 'Gas Distribution / Metering & Export', COMM_DATE: '2020-08-01', PRIORITY: 'M7', A_T: 602, A_A: 163, A_O: 439, A_P: 27.08, B_T: 102, B_A: 0, B_O: 102, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 53, PA_A: 31, PA_O: 22, PB_T: 98, PB_A: 75, PB_O: 23, MC_T: 2, MC_A: 0, MC_R: 2, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 3430, SYS_DESCR: 'Gas Lift Pipeline', COMM_DATE: '2020-07-27', PRIORITY: 'M7', A_T: 189, A_A: 40, A_O: 149, A_P: 21.16, B_T: 30, B_A: 0, B_O: 30, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 1, PA_A: 1, PA_O: 0, PB_T: 37, PB_A: 20, PB_O: 17, MC_T: 2, MC_A: 0, MC_R: 2, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'B', },
      { SYSTEM: 3470, SYS_DESCR: 'Gas Export Pipeline', COMM_DATE: '2020-07-21', PRIORITY: 'M7', A_T: 279, A_A: 72, A_O: 207, A_P: 25.81, B_T: 26, B_A: 0, B_O: 26, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 10, PA_A: 7, PA_O: 3, PB_T: 34, PB_A: 26, PB_O: 8, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 4100, SYS_DESCR: 'PW Treatment', COMM_DATE: '2020-07-21', PRIORITY: 'M5', A_T: 922, A_A: 290, A_O: 632, A_P: 31.45, B_T: 116, B_A: 0, B_O: 116, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 86, PA_A: 77, PA_O: 9, PB_T: 174, PB_A: 156, PB_O: 18, MC_T: 4, MC_A: 0, MC_R: 4, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 4330, SYS_DESCR: 'Open Drains', COMM_DATE: '2020-07-21', PRIORITY: 'M3', A_T: 2987, A_A: 1751, A_O: 1236, A_P: 58.62, B_T: 103, B_A: 0, B_O: 103, B_P: 0, C_T: 4, C_A: 0, C_O: 4, PA_T: 122, PA_A: 98, PA_O: 24, PB_T: 323, PB_A: 222, PB_O: 101, MC_T: 9, MC_A: 0, MC_R: 9, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 4340, SYS_DESCR: 'Closed Drains', COMM_DATE: '2020-08-02', PRIORITY: 'M3', A_T: 1078, A_A: 422, A_O: 656, A_P: 39.15, B_T: 87, B_A: 0, B_O: 87, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 135, PA_A: 114, PA_O: 21, PB_T: 168, PB_A: 150, PB_O: 18, MC_T: 2, MC_A: 0, MC_R: 2, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 4610, SYS_DESCR: 'Water Injection - De-Oxygenation', COMM_DATE: '2020-06-09', PRIORITY: 'M7', A_T: 589, A_A: 381, A_O: 208, A_P: 64.69, B_T: 82, B_A: 0, B_O: 82, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 167, PA_A: 143, PA_O: 24, PB_T: 176, PB_A: 148, PB_O: 28, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 4620, SYS_DESCR: 'Water Injection - Ultrafiltration', COMM_DATE: '2020-07-11', PRIORITY: 'M7', A_T: 1887, A_A: 1361, A_O: 526, A_P: 72.13, B_T: 353, B_A: 0, B_O: 353, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 1025, PA_A: 711, PA_O: 314, PB_T: 441, PB_A: 276, PB_O: 165, MC_T: 4, MC_A: 0, MC_R: 4, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 4640, SYS_DESCR: 'Water Injection - Reverse Osmosis Desalination', COMM_DATE: '2020-06-17', PRIORITY: 'M7', A_T: 780, A_A: 690, A_O: 90, A_P: 88.46, B_T: 84, B_A: 0, B_O: 84, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 31, PA_A: 16, PA_O: 15, PB_T: 80, PB_A: 71, PB_O: 9, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 4650, SYS_DESCR: 'Water Injection - Membrane Clean In Place', COMM_DATE: '2020-03-29', PRIORITY: 'M7', A_T: 296, A_A: 223, A_O: 73, A_P: 75.34, B_T: 44, B_A: 0, B_O: 44, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 28, PA_A: 22, PA_O: 6, PB_T: 91, PB_A: 61, PB_O: 30, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 4730, SYS_DESCR: 'Water Injection Pumping & Distribution', COMM_DATE: '2020-08-20', PRIORITY: 'M7', A_T: 1347, A_A: 757, A_O: 590, A_P: 56.2, B_T: 521, B_A: 254, B_O: 267, B_P: 48.75, C_T: 7, C_A: 0, C_O: 7, PA_T: 1326, PA_A: 743, PA_O: 583, PB_T: 777, PB_A: 570, PB_O: 207, MC_T: 5, MC_A: 0, MC_R: 5, STATUS: 'In Progress', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 5010, SYS_DESCR: 'HP Flare', COMM_DATE: '2020-06-18', PRIORITY: 'M4', A_T: 726, A_A: 332, A_O: 394, A_P: 45.73, B_T: 83, B_A: 0, B_O: 83, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 86, PA_A: 70, PA_O: 16, PB_T: 159, PB_A: 130, PB_O: 29, MC_T: 3, MC_A: 0, MC_R: 3, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 5030, SYS_DESCR: 'Flare Ignition Systems', COMM_DATE: '2020-08-12', PRIORITY: 'M7', A_T: 241, A_A: 133, A_O: 108, A_P: 55.19, B_T: 27, B_A: 0, B_O: 27, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 79, PA_A: 55, PA_O: 24, PB_T: 29, PB_A: 11, PB_O: 18, MC_T: 2, MC_A: 0, MC_R: 2, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 5210, SYS_DESCR: 'LP Flare', COMM_DATE: '2020-06-10', PRIORITY: 'M4', A_T: 338, A_A: 144, A_O: 194, A_P: 42.6, B_T: 26, B_A: 0, B_O: 26, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 14, PA_A: 10, PA_O: 4, PB_T: 29, PB_A: 16, PB_O: 13, MC_T: 2, MC_A: 0, MC_R: 2, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 5410, SYS_DESCR: 'Atmospheric Vent Collection & Vent Tip', COMM_DATE: '2020-07-10', PRIORITY: 'M4', A_T: 281, A_A: 149, A_O: 132, A_P: 53.02, B_T: 13, B_A: 0, B_O: 13, B_P: 0, C_T: null, C_A: null, C_O: null, PA_T: 48, PA_A: 39, PA_O: 9, PB_T: 24, PB_A: 23, PB_O: 1, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 6010, SYS_DESCR: 'Emergency Shutdown / Process Safety System (PSS)', COMM_DATE: '2020-03-26', PRIORITY: 'M2', A_T: 1950, A_A: 1798, A_O: 152, A_P: 92.21, B_T: 282, B_A: 40, B_O: 242, B_P: 14.18, C_T: null, C_A: null, C_O: null, PA_T: 187, PA_A: 174, PA_O: 13, PB_T: 362, PB_A: 303, PB_O: 59, MC_T: 17, MC_A: 11, MC_R: 6, STATUS: 'In Progress', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 6020, SYS_DESCR: 'Process Control Systems', COMM_DATE: '2020-03-29', PRIORITY: 'M2', A_T: 1500, A_A: 1306, A_O: 194, A_P: 87.07, B_T: 198, B_A: 107, B_O: 91, B_P: 54.04, C_T: null, C_A: null, C_O: null, PA_T: 148, PA_A: 142, PA_O: 6, PB_T: 628, PB_A: 521, PB_O: 107, MC_T: 14, MC_A: 12, MC_R: 2, STATUS: 'In Progress', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 6030, SYS_DESCR: 'Control Networks', COMM_DATE: '2020-06-08', PRIORITY: 'M2', A_T: 635, A_A: 372, A_O: 263, A_P: 58.58, B_T: 202, B_A: 52, B_O: 150, B_P: 25.74, C_T: 2, C_A: 0, C_O: 2, PA_T: 70, PA_A: 66, PA_O: 4, PB_T: 164, PB_A: 106, PB_O: 58, MC_T: 16, MC_A: 9, MC_R: 7, STATUS: 'In Progress', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 6050, SYS_DESCR: 'Subsea Controls', COMM_DATE: '2020-04-08', PRIORITY: 'M6', A_T: 494, A_A: 138, A_O: 356, A_P: 27.94, B_T: 42, B_A: 0, B_O: 42, B_P: 0, C_T: null, C_A: null, C_O: null, PA_T: 1, PA_A: 0, PA_O: 1, PB_T: 64, PB_A: 0, PB_O: 64, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 6060, SYS_DESCR: 'Condition & Performance Monitoring System (CPMS)', COMM_DATE: '2020-04-08', PRIORITY: 'M4', A_T: 181, A_A: 168, A_O: 13, A_P: 92.82, B_T: 99, B_A: 0, B_O: 99, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 41, PA_A: 40, PA_O: 1, PB_T: 151, PB_A: 130, PB_O: 21, MC_T: 5, MC_A: 2, MC_R: 3, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 6070, SYS_DESCR: 'Fire & Gas / ESS', COMM_DATE: '2020-06-10', PRIORITY: 'M2', A_T: 1206, A_A: 1073, A_O: 133, A_P: 88.97, B_T: 280, B_A: 25, B_O: 255, B_P: 8.93, C_T: 3, C_A: 0, C_O: 3, PA_T: 130, PA_A: 124, PA_O: 6, PB_T: 523, PB_A: 374, PB_O: 149, MC_T: 15, MC_A: 11, MC_R: 4, STATUS: 'In Progress', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 6071, SYS_DESCR: 'Fire & Gas: Hull - Pontoon Level', COMM_DATE: '2020-06-08', PRIORITY: 'M4', A_T: 120, A_A: 120, A_O: 0, A_P: 100, B_T: 67, B_A: 0, B_O: 67, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 9, PA_A: 9, PA_O: 0, PB_T: 21, PB_A: 20, PB_O: 1, MC_T: 1, MC_A: 1, MC_R: 0, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'B', },
      { SYSTEM: 6072, SYS_DESCR: 'Fire & Gas: Hull - Column Level', COMM_DATE: '2020-08-22', PRIORITY: 'M4', A_T: 114, A_A: 114, A_O: 0, A_P: 100, B_T: 68, B_A: 0, B_O: 68, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 5, PA_A: 5, PA_O: 0, PB_T: 27, PB_A: 24, PB_O: 3, MC_T: 1, MC_A: 1, MC_R: 0, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'B', },
      { SYSTEM: 6073, SYS_DESCR: 'Fire & Gas: Hull - Upper Hull', COMM_DATE: '2020-06-05', PRIORITY: 'M4', A_T: 3596, A_A: 1223, A_O: 2373, A_P: 34.01, B_T: 1527, B_A: 0, B_O: 1527, B_P: 0, C_T: 40, C_A: 0, C_O: 40, PA_T: 56, PA_A: 29, PA_O: 27, PB_T: 114, PB_A: 59, PB_O: 55, MC_T: 20, MC_A: 0, MC_R: 20, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 6074, SYS_DESCR: 'Fire & Gas: Main Deck', COMM_DATE: '2020-05-31', PRIORITY: 'M4', A_T: 154, A_A: 3, A_O: 151, A_P: 1.95, B_T: 46, B_A: 0, B_O: 46, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: null, PA_A: 0, PA_O: 0, PB_T: 2, PB_A: 2, PB_O: 0, MC_T: 2, MC_A: 0, MC_R: 2, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: null, },
      { SYSTEM: 6075, SYS_DESCR: 'Fire & Gas: Production Module', COMM_DATE: '2020-08-22', PRIORITY: 'M4', A_T: 978, A_A: 736, A_O: 242, A_P: 75.26, B_T: 236, B_A: 0, B_O: 236, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 21, PA_A: 17, PA_O: 4, PB_T: 171, PB_A: 111, PB_O: 60, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 6076, SYS_DESCR: 'Fire & Gas: Compression Module', COMM_DATE: '2020-05-22', PRIORITY: 'M5', A_T: 1078, A_A: 840, A_O: 238, A_P: 77.92, B_T: 289, B_A: 0, B_O: 289, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 46, PA_A: 46, PA_O: 0, PB_T: 395, PB_A: 368, PB_O: 27, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'B', },
      { SYSTEM: 6077, SYS_DESCR: 'Fire & Gas: Water Treatment Module', COMM_DATE: '2020-06-12', PRIORITY: 'M5', A_T: 487, A_A: 364, A_O: 123, A_P: 74.74, B_T: 143, B_A: 40, B_O: 103, B_P: 27.97, C_T: 2, C_A: 0, C_O: 2, PA_T: 73, PA_A: 59, PA_O: 14, PB_T: 206, PB_A: 149, PB_O: 57, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: 'In Progress', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 6078, SYS_DESCR: 'Fire & Gas: Power Module', COMM_DATE: '2020-06-19', PRIORITY: 'M3', A_T: 581, A_A: 435, A_O: 146, A_P: 74.87, B_T: 164, B_A: 68, B_O: 96, B_P: 41.46, C_T: 2, C_A: 0, C_O: 2, PA_T: 30, PA_A: 22, PA_O: 8, PB_T: 253, PB_A: 133, PB_O: 120, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: 'In Progress', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 6079, SYS_DESCR: 'FIRE & GAS: BUILDING -', COMM_DATE: '2020-05-31', PRIORITY: 'M5', A_T: 84, A_A: 40, A_O: 44, A_P: 47.62, B_T: 17, B_A: 0, B_O: 17, B_P: 0, C_T: null, C_A: null, C_O: null, PA_T: 5, PA_A: 0, PA_O: 5, PB_T: 16, PB_A: 14, PB_O: 2, MC_T: 3, MC_A: 0, MC_R: 3, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 6080, SYS_DESCR: 'Integrated Marine Monitoring System (IMMS)', COMM_DATE: '2020-07-14', PRIORITY: 'M5', A_T: 159, A_A: 63, A_O: 96, A_P: 39.62, B_T: 22, B_A: 0, B_O: 22, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 24, PA_A: 23, PA_O: 1, PB_T: 11, PB_A: 11, PB_O: 0, MC_T: 4, MC_A: 0, MC_R: 4, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 6110, SYS_DESCR: 'Fire Water Pumps', COMM_DATE: '2020-08-20', PRIORITY: 'M5', A_T: 906, A_A: 421, A_O: 485, A_P: 46.47, B_T: 198, B_A: 0, B_O: 198, B_P: 0, C_T: 3, C_A: 0, C_O: 3, PA_T: 113, PA_A: 87, PA_O: 26, PB_T: 211, PB_A: 148, PB_O: 63, MC_T: 4, MC_A: 0, MC_R: 4, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 6120, SYS_DESCR: 'Firewater Distribution & Protection Equipment', COMM_DATE: '2020-07-31', PRIORITY: 'M5', A_T: 1453, A_A: 458, A_O: 995, A_P: 31.52, B_T: 48, B_A: 0, B_O: 48, B_P: 0, C_T: 3, C_A: 0, C_O: 3, PA_T: 205, PA_A: 196, PA_O: 9, PB_T: 1243, PB_A: 1219, PB_O: 24, MC_T: 10, MC_A: 0, MC_R: 10, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 6130, SYS_DESCR: 'Firewater -Water Mist', COMM_DATE: '2020-08-04', PRIORITY: 'M5', A_T: 730, A_A: 295, A_O: 435, A_P: 40.41, B_T: 127, B_A: 0, B_O: 127, B_P: 0, C_T: 3, C_A: 0, C_O: 3, PA_T: 83, PA_A: 63, PA_O: 20, PB_T: 23, PB_A: 18, PB_O: 5, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 6140, SYS_DESCR: 'Foam Systems-Helideck', COMM_DATE: '2020-06-08', PRIORITY: 'M5', A_T: 36, A_A: 21, A_O: 15, A_P: 58.33, B_T: 4, B_A: 0, B_O: 4, B_P: 0, C_T: 3, C_A: 0, C_O: 3, PA_T: 1, PA_A: 1, PA_O: 0, PB_T: 6, PB_A: 5, PB_O: 1, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'B', },
      { SYSTEM: 6150, SYS_DESCR: 'Firewater-Deluge', COMM_DATE: '2020-08-20', PRIORITY: 'M5', A_T: 903, A_A: 383, A_O: 520, A_P: 42.41, B_T: 44, B_A: 0, B_O: 44, B_P: 0, C_T: 3, C_A: 0, C_O: 3, PA_T: 20, PA_A: 18, PA_O: 2, PB_T: 14, PB_A: 3, PB_O: 11, MC_T: 4, MC_A: 0, MC_R: 4, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 6160, SYS_DESCR: 'Fusible Loop Systems', COMM_DATE: '2020-06-19', PRIORITY: 'M4', A_T: 799, A_A: 562, A_O: 237, A_P: 70.34, B_T: 97, B_A: 0, B_O: 97, B_P: 0, C_T: null, C_A: null, C_O: null, PA_T: 90, PA_A: 74, PA_O: 16, PB_T: 86, PB_A: 84, PB_O: 2, MC_T: 8, MC_A: 0, MC_R: 8, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 6170, SYS_DESCR: 'Fire Extinguishers & Ancillaries', COMM_DATE: null, PRIORITY: null, A_T: 330, A_A: 0, A_O: 330, A_P: 0, B_T: null, B_A: null, B_O: null, B_P: null, C_T: null, C_A: null, C_O: null, PA_T: null, PA_A: 0, PA_O: 0, PB_T: 2, PB_A: 2, PB_O: 0, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: null, ASSOCIATED: 'N', CAT: null, },
      { SYSTEM: 6210, SYS_DESCR: 'Public Address / Alarms / Annunciation', COMM_DATE: '2020-07-12', PRIORITY: 'M1', A_T: 3238, A_A: 1854, A_O: 1384, A_P: 57.26, B_T: 685, B_A: 63, B_O: 622, B_P: 9.2, C_T: 3, C_A: 0, C_O: 3, PA_T: 781, PA_A: 714, PA_O: 67, PB_T: 988, PB_A: 866, PB_O: 122, MC_T: 11, MC_A: 2, MC_R: 9, STATUS: 'In Progress', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 6221, SYS_DESCR: 'UHF Radio System', COMM_DATE: '2020-07-28', PRIORITY: 'M4', A_T: 915, A_A: 493, A_O: 422, A_P: 53.88, B_T: 274, B_A: 0, B_O: 274, B_P: 0, C_T: 6, C_A: 0, C_O: 6, PA_T: 13, PA_A: 11, PA_O: 2, PB_T: 56, PB_A: 50, PB_O: 6, MC_T: 9, MC_A: 0, MC_R: 9, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 6222, SYS_DESCR: 'VHF Marine Radio System', COMM_DATE: '2020-06-28', PRIORITY: 'M3', A_T: 55, A_A: 0, A_O: 55, A_P: 0, B_T: 11, B_A: 0, B_O: 11, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 10, PA_A: 9, PA_O: 1, PB_T: 5, PB_A: 5, PB_O: 0, MC_T: 2, MC_A: 0, MC_R: 2, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 6223, SYS_DESCR: 'VHF Aeronautical Radio System', COMM_DATE: '2020-06-20', PRIORITY: 'M3', A_T: 38, A_A: 5, A_O: 33, A_P: 13.16, B_T: 18, B_A: 0, B_O: 18, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: null, PA_A: 0, PA_O: 0, PB_T: 8, PB_A: 8, PB_O: 0, MC_T: 3, MC_A: 0, MC_R: 3, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: null, },
      { SYSTEM: 6320, SYS_DESCR: 'Survival Capsules', COMM_DATE: '2020-06-23', PRIORITY: 'M6', A_T: 116, A_A: 32, A_O: 84, A_P: 27.59, B_T: 48, B_A: 0, B_O: 48, B_P: 0, C_T: 3, C_A: 0, C_O: 3, PA_T: 17, PA_A: 4, PA_O: 13, PB_T: 22, PB_A: 18, PB_O: 4, MC_T: 4, MC_A: 0, MC_R: 4, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 6330, SYS_DESCR: 'Liferafts', COMM_DATE: null, PRIORITY: null, A_T: 21, A_A: 0, A_O: 21, A_P: 0, B_T: null, B_A: null, B_O: null, B_P: null, C_T: null, C_A: null, C_O: null, PA_T: 6, PA_A: 6, PA_O: 0, PB_T: 16, PB_A: 16, PB_O: 0, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: null, ASSOCIATED: 'N', CAT: null, },
      { SYSTEM: 6340, SYS_DESCR: 'Fast Rescue Craft', COMM_DATE: '2020-05-31', PRIORITY: 'M6', A_T: 50, A_A: 20, A_O: 30, A_P: 40, B_T: 16, B_A: 0, B_O: 16, B_P: 0, C_T: 3, C_A: 0, C_O: 3, PA_T: 6, PA_A: 2, PA_O: 4, PB_T: 12, PB_A: 7, PB_O: 5, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 6440, SYS_DESCR: 'Life Jackets, & Buoys', COMM_DATE: null, PRIORITY: null, A_T: 238, A_A: 0, A_O: 238, A_P: 0, B_T: null, B_A: null, B_O: null, B_P: null, C_T: null, C_A: null, C_O: null, PA_T: null, PA_A: 0, PA_O: 0, PB_T: 7, PB_A: 2, PB_O: 5, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: null, ASSOCIATED: 'N', CAT: 'B', },
      { SYSTEM: 6510, SYS_DESCR: 'Navaids', COMM_DATE: '2020-07-22', PRIORITY: 'M4', A_T: 121, A_A: 24, A_O: 97, A_P: 19.83, B_T: 34, B_A: 0, B_O: 34, B_P: 0, C_T: 3, C_A: 0, C_O: 3, PA_T: 101, PA_A: 100, PA_O: 1, PB_T: 32, PB_A: 32, PB_O: 0, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 6630, SYS_DESCR: 'Emergency Power Generation (480V)', COMM_DATE: '2020-05-18', PRIORITY: 'M4', A_T: 487, A_A: 383, A_O: 104, A_P: 78.64, B_T: 108, B_A: 0, B_O: 108, B_P: 0, C_T: 3, C_A: 0, C_O: 3, PA_T: 85, PA_A: 73, PA_O: 12, PB_T: 77, PB_A: 53, PB_O: 24, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 6710, SYS_DESCR: 'Emergency Power Distribution <=110V', COMM_DATE: '2020-03-18', PRIORITY: 'M5', A_T: 4059, A_A: 2287, A_O: 1772, A_P: 56.34, B_T: 219, B_A: 0, B_O: 219, B_P: 0, C_T: 5, C_A: 0, C_O: 5, PA_T: 66, PA_A: 51, PA_O: 15, PB_T: 548, PB_A: 465, PB_O: 83, MC_T: 16, MC_A: 0, MC_R: 16, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 6720, SYS_DESCR: 'Emergency Power Distribution 110 / 120 / 208V', COMM_DATE: '2020-04-02', PRIORITY: 'M1', A_T: 3487, A_A: 2456, A_O: 1031, A_P: 70.43, B_T: 390, B_A: 12, B_O: 378, B_P: 3.08, C_T: 18, C_A: 1, C_O: 17, PA_T: 369, PA_A: 320, PA_O: 49, PB_T: 974, PB_A: 728, PB_O: 246, MC_T: 45, MC_A: 21, MC_R: 24, STATUS: 'In Progress', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 6730, SYS_DESCR: 'Emergency Power Distribution 240 / 254 / 277V', COMM_DATE: '2020-04-02', PRIORITY: 'M1', A_T: 71, A_A: 19, A_O: 52, A_P: 26.76, B_T: 18, B_A: 0, B_O: 18, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 18, PA_A: 14, PA_O: 4, PB_T: 13, PB_A: 12, PB_O: 1, MC_T: 2, MC_A: 0, MC_R: 2, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 6740, SYS_DESCR: 'Emergency Power Distribution 415 / 440 / 480 / 600V', COMM_DATE: '2020-03-13', PRIORITY: 'M1', A_T: 182, A_A: 181, A_O: 1, A_P: 99.45, B_T: 124, B_A: 49, B_O: 75, B_P: 39.52, C_T: 6, C_A: 1, C_O: 5, PA_T: 163, PA_A: 158, PA_O: 5, PB_T: 196, PB_A: 147, PB_O: 49, MC_T: 8, MC_A: 5, MC_R: 3, STATUS: 'In Progress', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 6810, SYS_DESCR: 'Essential Power Generation', COMM_DATE: '2020-06-25', PRIORITY: 'M4', A_T: 1266, A_A: 677, A_O: 589, A_P: 53.48, B_T: 245, B_A: 0, B_O: 245, B_P: 0, C_T: 5, C_A: 0, C_O: 5, PA_T: 190, PA_A: 168, PA_O: 22, PB_T: 171, PB_A: 137, PB_O: 34, MC_T: 3, MC_A: 0, MC_R: 3, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 6820, SYS_DESCR: 'Essential Power Distribution', COMM_DATE: '2020-03-11', PRIORITY: 'M1', A_T: 7953, A_A: 5804, A_O: 2149, A_P: 72.98, B_T: 1080, B_A: 323, B_O: 757, B_P: 29.91, C_T: 6, C_A: 1, C_O: 5, PA_T: 604, PA_A: 549, PA_O: 55, PB_T: 1769, PB_A: 1391, PB_O: 378, MC_T: 53, MC_A: 30, MC_R: 23, STATUS: 'In Progress', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 8002, SYS_DESCR: 'Compressor Seal Gas Filter Skid', COMM_DATE: '2020-08-10', PRIORITY: 'M7', A_T: 469, A_A: 82, A_O: 387, A_P: 17.48, B_T: 138, B_A: 0, B_O: 138, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 263, PA_A: 22, PA_O: 241, PB_T: 155, PB_A: 16, PB_O: 139, MC_T: 3, MC_A: 0, MC_R: 3, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 8011, SYS_DESCR: 'Potable Water Distribution', COMM_DATE: '2020-07-31', PRIORITY: 'M4', A_T: 1034, A_A: 531, A_O: 503, A_P: 51.35, B_T: 112, B_A: 0, B_O: 112, B_P: 0, C_T: 3, C_A: 0, C_O: 3, PA_T: 121, PA_A: 100, PA_O: 21, PB_T: 318, PB_A: 276, PB_O: 42, MC_T: 5, MC_A: 0, MC_R: 5, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 8012, SYS_DESCR: 'Utility Water Distribution', COMM_DATE: null, PRIORITY: null, A_T: 308, A_A: 147, A_O: 161, A_P: 47.73, B_T: 4, B_A: 0, B_O: 4, B_P: 0, C_T: null, C_A: null, C_O: null, PA_T: 2, PA_A: 2, PA_O: 0, PB_T: 34, PB_A: 32, PB_O: 2, MC_T: 4, MC_A: 2, MC_R: 2, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'B', },
      { SYSTEM: 8014, SYS_DESCR: 'Fresh Water Storage Tanks', COMM_DATE: '2020-05-25', PRIORITY: 'M4', A_T: 249, A_A: 233, A_O: 16, A_P: 93.57, B_T: 23, B_A: 0, B_O: 23, B_P: 0, C_T: null, C_A: null, C_O: null, PA_T: 49, PA_A: 44, PA_O: 5, PB_T: 88, PB_A: 83, PB_O: 5, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 8016, SYS_DESCR: 'Fresh Water Maker', COMM_DATE: '2020-07-17', PRIORITY: 'M4', A_T: 807, A_A: 470, A_O: 337, A_P: 58.24, B_T: 75, B_A: 0, B_O: 75, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 69, PA_A: 62, PA_O: 7, PB_T: 168, PB_A: 150, PB_O: 18, MC_T: 3, MC_A: 0, MC_R: 3, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 8021, SYS_DESCR: 'Seawater Lift & Distribution - Cooling', COMM_DATE: '2020-08-13', PRIORITY: 'M4', A_T: 1741, A_A: 736, A_O: 1005, A_P: 42.27, B_T: 140, B_A: 0, B_O: 140, B_P: 0, C_T: 3, C_A: 0, C_O: 3, PA_T: 78, PA_A: 56, PA_O: 22, PB_T: 304, PB_A: 251, PB_O: 53, MC_T: 6, MC_A: 0, MC_R: 6, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 8023, SYS_DESCR: 'Water Injection - Lift & Transfer', COMM_DATE: '2020-07-31', PRIORITY: 'M7', A_T: 485, A_A: 149, A_O: 336, A_P: 30.72, B_T: 69, B_A: 0, B_O: 69, B_P: 0, C_T: null, C_A: null, C_O: null, PA_T: 31, PA_A: 26, PA_O: 5, PB_T: 138, PB_A: 126, PB_O: 12, MC_T: 4, MC_A: 1, MC_R: 3, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 8030, SYS_DESCR: 'Turbine Wash Water', COMM_DATE: '2020-05-29', PRIORITY: 'M4', A_T: 63, A_A: 44, A_O: 19, A_P: 69.84, B_T: 34, B_A: 8, B_O: 26, B_P: 23.53, C_T: null, C_A: null, C_O: null, PA_T: 32, PA_A: 25, PA_O: 7, PB_T: 9, PB_A: 2, PB_O: 7, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: 'In Progress', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 8040, SYS_DESCR: 'Heating Medium', COMM_DATE: '2020-04-24', PRIORITY: 'M4', A_T: 957, A_A: 616, A_O: 341, A_P: 64.37, B_T: 123, B_A: 0, B_O: 123, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 217, PA_A: 148, PA_O: 69, PB_T: 330, PB_A: 266, PB_O: 64, MC_T: 5, MC_A: 0, MC_R: 5, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 8060, SYS_DESCR: 'Diesel Filter, Transfer and Topsides Storage', COMM_DATE: '2020-05-28', PRIORITY: 'M3', A_T: 965, A_A: 446, A_O: 519, A_P: 46.22, B_T: 49, B_A: 0, B_O: 49, B_P: 0, C_T: 4, C_A: 0, C_O: 4, PA_T: 71, PA_A: 54, PA_O: 17, PB_T: 240, PB_A: 202, PB_O: 38, MC_T: 5, MC_A: 0, MC_R: 5, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 8063, SYS_DESCR: 'Diesel Storage Tanks (in Hull)', COMM_DATE: '2020-04-26', PRIORITY: 'M3', A_T: 410, A_A: 374, A_O: 36, A_P: 91.22, B_T: 37, B_A: 0, B_O: 37, B_P: 0, C_T: null, C_A: null, C_O: null, PA_T: 70, PA_A: 64, PA_O: 6, PB_T: 139, PB_A: 131, PB_O: 8, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 8070, SYS_DESCR: 'Heli-Fuel', COMM_DATE: '2020-08-04', PRIORITY: 'M6', A_T: 233, A_A: 188, A_O: 45, A_P: 80.69, B_T: 37, B_A: 0, B_O: 37, B_P: 0, C_T: 3, C_A: 0, C_O: 3, PA_T: 90, PA_A: 79, PA_O: 11, PB_T: 34, PB_A: 30, PB_O: 4, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 8080, SYS_DESCR: 'Chilled Water (HVAC)', COMM_DATE: '2020-08-23', PRIORITY: 'M4', A_T: 2143, A_A: 741, A_O: 1402, A_P: 34.58, B_T: 205, B_A: 0, B_O: 205, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 177, PA_A: 158, PA_O: 19, PB_T: 389, PB_A: 340, PB_O: 49, MC_T: 13, MC_A: 0, MC_R: 13, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 8110, SYS_DESCR: 'Utility Air', COMM_DATE: '2020-07-02', PRIORITY: 'M2', A_T: 327, A_A: 182, A_O: 145, A_P: 55.66, B_T: 7, B_A: 0, B_O: 7, B_P: 0, C_T: null, C_A: null, C_O: null, PA_T: 13, PA_A: 8, PA_O: 5, PB_T: 108, PB_A: 104, PB_O: 4, MC_T: 2, MC_A: 0, MC_R: 2, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 8120, SYS_DESCR: 'Instrument Air', COMM_DATE: '2020-08-08', PRIORITY: 'M2', A_T: 1588, A_A: 1020, A_O: 568, A_P: 64.23, B_T: 193, B_A: 0, B_O: 193, B_P: 0, C_T: 3, C_A: 0, C_O: 3, PA_T: 354, PA_A: 316, PA_O: 38, PB_T: 262, PB_A: 209, PB_O: 53, MC_T: 10, MC_A: 1, MC_R: 9, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 8130, SYS_DESCR: 'Fuel Gas', COMM_DATE: '2020-07-20', PRIORITY: 'M7', A_T: 1011, A_A: 383, A_O: 628, A_P: 37.88, B_T: 125, B_A: 0, B_O: 125, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 54, PA_A: 45, PA_O: 9, PB_T: 241, PB_A: 206, PB_O: 35, MC_T: 3, MC_A: 0, MC_R: 3, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 8150, SYS_DESCR: 'HP/LP Nitrogen', COMM_DATE: '2020-07-18', PRIORITY: 'M4', A_T: 781, A_A: 376, A_O: 405, A_P: 48.14, B_T: 91, B_A: 0, B_O: 91, B_P: 0, C_T: 3, C_A: 0, C_O: 3, PA_T: 164, PA_A: 157, PA_O: 7, PB_T: 140, PB_A: 126, PB_O: 14, MC_T: 3, MC_A: 0, MC_R: 3, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 8170, SYS_DESCR: 'Water Injection - Ultra Filtration Air Scour System', COMM_DATE: '2020-05-06', PRIORITY: 'M7', A_T: 86, A_A: 62, A_O: 24, A_P: 72.09, B_T: 20, B_A: 0, B_O: 20, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 10, PA_A: 5, PA_O: 5, PB_T: 45, PB_A: 42, PB_O: 3, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 8200, SYS_DESCR: 'Chemicals - Subsea Production Fluids', COMM_DATE: '2020-07-01', PRIORITY: 'M5', A_T: 970, A_A: 862, A_O: 108, A_P: 88.87, B_T: 189, B_A: 0, B_O: 189, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 111, PA_A: 101, PA_O: 10, PB_T: 112, PB_A: 81, PB_O: 31, MC_T: 4, MC_A: 0, MC_R: 4, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 8300, SYS_DESCR: 'Chemicals - Topsides Production Fluids', COMM_DATE: '2020-07-18', PRIORITY: 'M5', A_T: 3156, A_A: 1337, A_O: 1819, A_P: 42.36, B_T: 379, B_A: 0, B_O: 379, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 44, PA_A: 35, PA_O: 9, PB_T: 58, PB_A: 52, PB_O: 6, MC_T: 9, MC_A: 0, MC_R: 9, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 8400, SYS_DESCR: 'Chemicals - Water Injection', COMM_DATE: '2020-08-14', PRIORITY: 'M5', A_T: 1290, A_A: 775, A_O: 515, A_P: 60.08, B_T: 211, B_A: 0, B_O: 211, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 26, PA_A: 21, PA_O: 5, PB_T: 39, PB_A: 31, PB_O: 8, MC_T: 9, MC_A: 0, MC_R: 9, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 8430, SYS_DESCR: 'Chemicals - Hypochlorite Generator & Injection', COMM_DATE: '2020-07-08', PRIORITY: 'M6', A_T: 441, A_A: 198, A_O: 243, A_P: 44.9, B_T: 66, B_A: 0, B_O: 66, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 187, PA_A: 153, PA_O: 34, PB_T: 27, PB_A: 17, PB_O: 10, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 8500, SYS_DESCR: 'Chemicals - Methanol & LDHI', COMM_DATE: '2020-07-14', PRIORITY: 'M5', A_T: 3503, A_A: 2280, A_O: 1223, A_P: 65.09, B_T: 412, B_A: 0, B_O: 412, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 135, PA_A: 83, PA_O: 52, PB_T: 138, PB_A: 113, PB_O: 25, MC_T: 2, MC_A: 0, MC_R: 2, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 8610, SYS_DESCR: 'Main Power Supply', COMM_DATE: '2020-03-19', PRIORITY: 'M5', A_T: 532, A_A: 521, A_O: 11, A_P: 97.93, B_T: 79, B_A: 22, B_O: 57, B_P: 27.85, C_T: 4, C_A: 0, C_O: 4, PA_T: 117, PA_A: 104, PA_O: 13, PB_T: 249, PB_A: 199, PB_O: 50, MC_T: 16, MC_A: 10, MC_R: 6, STATUS: 'In Progress', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 8661, SYS_DESCR: 'Power Generation - Train 1', COMM_DATE: '2020-07-12', PRIORITY: 'M4', A_T: 871, A_A: 678, A_O: 193, A_P: 77.84, B_T: 342, B_A: 143, B_O: 199, B_P: 41.81, C_T: 3, C_A: 0, C_O: 3, PA_T: 359, PA_A: 318, PA_O: 41, PB_T: 577, PB_A: 426, PB_O: 151, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: 'In Progress', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 8662, SYS_DESCR: 'Power Generation - Train 2', COMM_DATE: '2020-07-11', PRIORITY: 'M4', A_T: 884, A_A: 692, A_O: 192, A_P: 78.28, B_T: 339, B_A: 127, B_O: 212, B_P: 37.46, C_T: 2, C_A: 0, C_O: 2, PA_T: 340, PA_A: 296, PA_O: 44, PB_T: 501, PB_A: 371, PB_O: 130, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: 'In Progress', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 8663, SYS_DESCR: 'Power Generation - Train 3', COMM_DATE: '2020-07-10', PRIORITY: 'M5', A_T: 859, A_A: 668, A_O: 191, A_P: 77.76, B_T: 339, B_A: 123, B_O: 216, B_P: 36.28, C_T: 2, C_A: 0, C_O: 2, PA_T: 315, PA_A: 294, PA_O: 21, PB_T: 446, PB_A: 325, PB_O: 121, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: 'In Progress', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 8720, SYS_DESCR: 'Normal Power Distribution 110 / 120 / 208V', COMM_DATE: '2020-05-29', PRIORITY: 'M1', A_T: 4077, A_A: 2906, A_O: 1171, A_P: 71.28, B_T: 257, B_A: 0, B_O: 257, B_P: 0, C_T: null, C_A: null, C_O: null, PA_T: 68, PA_A: 36, PA_O: 32, PB_T: 588, PB_A: 444, PB_O: 144, MC_T: 16, MC_A: 0, MC_R: 16, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 8740, SYS_DESCR: 'Normal Power Distribution 480 V', COMM_DATE: '2020-03-06', PRIORITY: 'M1', A_T: 412, A_A: 331, A_O: 81, A_P: 80.34, B_T: 432, B_A: 144, B_O: 288, B_P: 33.33, C_T: 8, C_A: 1, C_O: 7, PA_T: 254, PA_A: 230, PA_O: 24, PB_T: 393, PB_A: 305, PB_O: 88, MC_T: 26, MC_A: 11, MC_R: 15, STATUS: 'In Progress', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 8760, SYS_DESCR: 'Normal Power Distribution 4.16KV', COMM_DATE: '2020-04-04', PRIORITY: 'M2', A_T: 228, A_A: 103, A_O: 125, A_P: 45.18, B_T: 170, B_A: 0, B_O: 170, B_P: 0, C_T: 6, C_A: 0, C_O: 6, PA_T: 44, PA_A: 39, PA_O: 5, PB_T: 74, PB_A: 66, PB_O: 8, MC_T: 6, MC_A: 0, MC_R: 6, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 8790, SYS_DESCR: 'Normal Power Distribution 13.8 KV', COMM_DATE: '2020-03-26', PRIORITY: 'M2', A_T: 34, A_A: 34, A_O: 0, A_P: 100, B_T: 47, B_A: 0, B_O: 47, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 45, PA_A: 39, PA_O: 6, PB_T: 58, PB_A: 25, PB_O: 33, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 8950, SYS_DESCR: 'Heat Trace', COMM_DATE: '2020-07-31', PRIORITY: 'M7', A_T: 1377, A_A: 98, A_O: 1279, A_P: 7.12, B_T: 372, B_A: 0, B_O: 372, B_P: 0, C_T: null, C_A: null, C_O: null, PA_T: 13, PA_A: 11, PA_O: 2, PB_T: 23, PB_A: 23, PB_O: 0, MC_T: 4, MC_A: 0, MC_R: 4, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 9131, SYS_DESCR: 'Topside Structure - Production Module', COMM_DATE: '2020-07-17', PRIORITY: 'M7', A_T: 183, A_A: 0, A_O: 183, A_P: 0, B_T: 11, B_A: 0, B_O: 11, B_P: 0, C_T: null, C_A: null, C_O: null, PA_T: null, PA_A: 0, PA_O: 0, PB_T: 8, PB_A: 2, PB_O: 6, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'B', },
      { SYSTEM: 9132, SYS_DESCR: 'Topside Structure - Compression Module', COMM_DATE: '2020-07-17', PRIORITY: 'M7', A_T: 331, A_A: 187, A_O: 144, A_P: 56.5, B_T: 11, B_A: 0, B_O: 11, B_P: 0, C_T: null, C_A: null, C_O: null, PA_T: 1, PA_A: 1, PA_O: 0, PB_T: 29, PB_A: 23, PB_O: 6, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'B', },
      { SYSTEM: 9133, SYS_DESCR: 'Topside Structure - Water Injection Module', COMM_DATE: '2020-07-10', PRIORITY: 'M7', A_T: 102, A_A: 27, A_O: 75, A_P: 26.47, B_T: 7, B_A: 0, B_O: 7, B_P: 0, C_T: null, C_A: null, C_O: null, PA_T: null, PA_A: 0, PA_O: 0, PB_T: 9, PB_A: 3, PB_O: 6, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'B', },
      { SYSTEM: 9134, SYS_DESCR: 'Topside Structure - Power Generation Module', COMM_DATE: '2020-07-24', PRIORITY: 'M7', A_T: 133, A_A: 1, A_O: 132, A_P: 0.75, B_T: 8, B_A: 0, B_O: 8, B_P: 0, C_T: null, C_A: null, C_O: null, PA_T: 1, PA_A: 0, PA_O: 1, PB_T: 6, PB_A: 0, PB_O: 6, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 9150, SYS_DESCR: 'Topside Structure - Flare Boom', COMM_DATE: null, PRIORITY: null, A_T: 3, A_A: 1, A_O: 2, A_P: 33.33, B_T: null, B_A: null, B_O: null, B_P: null, C_T: null, C_A: null, C_O: null, PA_T: 2, PA_A: 1, PA_O: 1, PB_T: 1, PB_A: 1, PB_O: 0, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: null, ASSOCIATED: 'N', CAT: 'A', },
      { SYSTEM: 9220, SYS_DESCR: 'Main Accommodation, incl entertainment', COMM_DATE: '2020-07-29', PRIORITY: 'M7', A_T: 1621, A_A: 181, A_O: 1440, A_P: 11.17, B_T: 97, B_A: 0, B_O: 97, B_P: 0, C_T: 4, C_A: 0, C_O: 4, PA_T: 17, PA_A: 15, PA_O: 2, PB_T: 112, PB_A: 77, PB_O: 35, MC_T: 7, MC_A: 0, MC_R: 7, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 9240, SYS_DESCR: 'Workshops', COMM_DATE: '2020-07-31', PRIORITY: 'M7', A_T: 88, A_A: 2, A_O: 86, A_P: 2.27, B_T: 2, B_A: 0, B_O: 2, B_P: 0, C_T: null, C_A: null, C_O: null, PA_T: 4, PA_A: 4, PA_O: 0, PB_T: 4, PB_A: 3, PB_O: 1, MC_T: 3, MC_A: 0, MC_R: 3, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'B', },
      { SYSTEM: 9250, SYS_DESCR: 'Deckhouse HVAC', COMM_DATE: '2020-08-28', PRIORITY: 'M6', A_T: 3919, A_A: 2120, A_O: 1799, A_P: 54.1, B_T: 559, B_A: 0, B_O: 559, B_P: 0, C_T: null, C_A: null, C_O: null, PA_T: 464, PA_A: 384, PA_O: 80, PB_T: 635, PB_A: 468, PB_O: 167, MC_T: 5, MC_A: 0, MC_R: 5, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 9254, SYS_DESCR: 'HVAC Galley', COMM_DATE: '2020-08-28', PRIORITY: 'M6', A_T: 926, A_A: 490, A_O: 436, A_P: 52.92, B_T: 145, B_A: 0, B_O: 145, B_P: 0, C_T: 3, C_A: 0, C_O: 3, PA_T: 150, PA_A: 141, PA_O: 9, PB_T: 111, PB_A: 83, PB_O: 28, MC_T: 2, MC_A: 0, MC_R: 2, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 9255, SYS_DESCR: 'HVAC Battery Rooms', COMM_DATE: '2020-08-26', PRIORITY: 'M5', A_T: 807, A_A: 420, A_O: 387, A_P: 52.04, B_T: 126, B_A: 0, B_O: 126, B_P: 0, C_T: null, C_A: null, C_O: null, PA_T: 192, PA_A: 182, PA_O: 10, PB_T: 157, PB_A: 116, PB_O: 41, MC_T: 2, MC_A: 0, MC_R: 2, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 9260, SYS_DESCR: 'Living Qquater HVAC', COMM_DATE: '2020-08-29', PRIORITY: 'M5', A_T: 3597, A_A: 1893, A_O: 1704, A_P: 52.63, B_T: 471, B_A: 0, B_O: 471, B_P: 0, C_T: 4, C_A: 0, C_O: 4, PA_T: 753, PA_A: 720, PA_O: 33, PB_T: 292, PB_A: 240, PB_O: 52, MC_T: 4, MC_A: 0, MC_R: 4, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 9270, SYS_DESCR: 'Deckbox  HVAC-South', COMM_DATE: '2020-08-26', PRIORITY: 'M5', A_T: 6408, A_A: 3585, A_O: 2823, A_P: 55.95, B_T: 1092, B_A: 0, B_O: 1092, B_P: 0, C_T: 3, C_A: 0, C_O: 3, PA_T: 951, PA_A: 895, PA_O: 56, PB_T: 658, PB_A: 551, PB_O: 107, MC_T: 12, MC_A: 0, MC_R: 12, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 9280, SYS_DESCR: 'Deckbox HVAC-North', COMM_DATE: '2020-08-23', PRIORITY: 'M5', A_T: 3129, A_A: 1705, A_O: 1424, A_P: 54.49, B_T: 478, B_A: 0, B_O: 478, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 636, PA_A: 587, PA_O: 49, PB_T: 380, PB_A: 324, PB_O: 56, MC_T: 7, MC_A: 0, MC_R: 7, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 9291, SYS_DESCR: 'Temporary Living Quarter', COMM_DATE: '2020-09-04', PRIORITY: 'M7', A_T: 38, A_A: 0, A_O: 38, A_P: 0, B_T: 5, B_A: 0, B_O: 5, B_P: 0, C_T: null, C_A: null, C_O: null, PA_T: null, PA_A: null, PA_O: null, PB_T: null, PB_A: null, PB_O: null, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: null, },
      { SYSTEM: 9300, SYS_DESCR: 'Upper Hull Structure - Deckbox/Deckhouse', COMM_DATE: '2020-07-31', PRIORITY: 'M7', A_T: 820, A_A: 12, A_O: 808, A_P: 1.46, B_T: 62, B_A: 0, B_O: 62, B_P: 0, C_T: null, C_A: null, C_O: null, PA_T: 3, PA_A: 0, PA_O: 3, PB_T: 63, PB_A: 19, PB_O: 44, MC_T: 3, MC_A: 0, MC_R: 3, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 9310, SYS_DESCR: 'Hull Columns Structure', COMM_DATE: '2020-05-08', PRIORITY: 'M7', A_T: 195, A_A: 62, A_O: 133, A_P: 31.79, B_T: 24, B_A: 0, B_O: 24, B_P: 0, C_T: null, C_A: null, C_O: null, PA_T: 60, PA_A: 37, PA_O: 23, PB_T: 152, PB_A: 111, PB_O: 41, MC_T: 8, MC_A: 0, MC_R: 8, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 9320, SYS_DESCR: 'Hull Vents and Sounding', COMM_DATE: '2020-03-31', PRIORITY: 'M3', A_T: 462, A_A: 456, A_O: 6, A_P: 98.7, B_T: 3, B_A: 0, B_O: 3, B_P: 0, C_T: null, C_A: null, C_O: null, PA_T: 2, PA_A: 2, PA_O: 0, PB_T: 56, PB_A: 56, PB_O: 0, MC_T: 1, MC_A: 1, MC_R: 0, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: null, },
      { SYSTEM: 9340, SYS_DESCR: 'Mooring Systems', COMM_DATE: '2020-05-31', PRIORITY: 'M6', A_T: 2252, A_A: 2001, A_O: 251, A_P: 88.85, B_T: 403, B_A: 0, B_O: 403, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 236, PA_A: 229, PA_O: 7, PB_T: 228, PB_A: 200, PB_O: 28, MC_T: 5, MC_A: 1, MC_R: 4, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 9350, SYS_DESCR: 'Ballast System', COMM_DATE: '2020-07-21', PRIORITY: 'M3', A_T: 3991, A_A: 3965, A_O: 26, A_P: 99.35, B_T: 263, B_A: 141, B_O: 122, B_P: 53.61, C_T: 3, C_A: 0, C_O: 3, PA_T: 500, PA_A: 485, PA_O: 15, PB_T: 1444, PB_A: 1376, PB_O: 68, MC_T: 4, MC_A: 4, MC_R: 0, STATUS: 'In Progress', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 9355, SYS_DESCR: 'Utility / Ballast Seawater water', COMM_DATE: '2020-05-21', PRIORITY: 'M2', A_T: 626, A_A: 413, A_O: 213, A_P: 65.97, B_T: 66, B_A: 5, B_O: 61, B_P: 7.58, C_T: null, C_A: null, C_O: null, PA_T: 58, PA_A: 38, PA_O: 20, PB_T: 184, PB_A: 136, PB_O: 48, MC_T: 4, MC_A: 1, MC_R: 3, STATUS: 'In Progress', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 9360, SYS_DESCR: 'Water Tight Systems (Doors, etc)', COMM_DATE: '2020-08-16', PRIORITY: 'M3', A_T: 1482, A_A: 1111, A_O: 371, A_P: 74.97, B_T: 346, B_A: 0, B_O: 346, B_P: 0, C_T: 5, C_A: 0, C_O: 5, PA_T: 41, PA_A: 32, PA_O: 9, PB_T: 390, PB_A: 363, PB_O: 27, MC_T: 2, MC_A: 0, MC_R: 2, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 9380, SYS_DESCR: 'Bilge System & Void Tanks', COMM_DATE: '2020-07-02', PRIORITY: 'M3', A_T: 9136, A_A: 8279, A_O: 857, A_P: 90.62, B_T: 543, B_A: 193, B_O: 350, B_P: 35.54, C_T: 3, C_A: 0, C_O: 3, PA_T: 987, PA_A: 954, PA_O: 33, PB_T: 2149, PB_A: 1970, PB_O: 179, MC_T: 10, MC_A: 4, MC_R: 6, STATUS: 'In Progress', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 9390, SYS_DESCR: 'Helideck', COMM_DATE: '2020-05-15', PRIORITY: 'M5', A_T: 100, A_A: 58, A_O: 42, A_P: 58, B_T: 2, B_A: 0, B_O: 2, B_P: 0, C_T: null, C_A: null, C_O: null, PA_T: 104, PA_A: 103, PA_O: 1, PB_T: 33, PB_A: 4, PB_O: 29, MC_T: 2, MC_A: 0, MC_R: 2, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 9450, SYS_DESCR: 'Electrical / MCC Buildings', COMM_DATE: '2020-08-07', PRIORITY: 'M7', A_T: 450, A_A: 8, A_O: 442, A_P: 1.78, B_T: 14, B_A: 0, B_O: 14, B_P: 0, C_T: null, C_A: null, C_O: null, PA_T: 1, PA_A: 0, PA_O: 1, PB_T: 26, PB_A: 22, PB_O: 4, MC_T: 20, MC_A: 0, MC_R: 20, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 9451, SYS_DESCR: 'Mechanical Room & HVAC Room', COMM_DATE: null, PRIORITY: null, A_T: 499, A_A: 2, A_O: 497, A_P: 0.4, B_T: null, B_A: null, B_O: null, B_P: null, C_T: null, C_A: null, C_O: null, PA_T: null, PA_A: 0, PA_O: 0, PB_T: 6, PB_A: 4, PB_O: 2, MC_T: 13, MC_A: 0, MC_R: 13, STATUS: null, ASSOCIATED: 'N', CAT: 'B', },
      { SYSTEM: 9510, SYS_DESCR: 'VSAT', COMM_DATE: '2020-05-18', PRIORITY: 'M3', A_T: 36, A_A: 6, A_O: 30, A_P: 16.67, B_T: 11, B_A: 0, B_O: 11, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 15, PA_A: 15, PA_O: 0, PB_T: 13, PB_A: 13, PB_O: 0, MC_T: 3, MC_A: 0, MC_R: 3, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: null, },
      { SYSTEM: 9512, SYS_DESCR: 'Subsea Fiber Optic', COMM_DATE: '2020-06-23', PRIORITY: 'M7', A_T: 10, A_A: 2, A_O: 8, A_P: 20, B_T: 3, B_A: 0, B_O: 3, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 2, PA_A: 0, PA_O: 2, PB_T: null, PB_A: 0, PB_O: 0, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 9520, SYS_DESCR: 'Business and Communications LANs (incl VoIP)', COMM_DATE: '2020-07-30', PRIORITY: 'M3', A_T: 729, A_A: 112, A_O: 617, A_P: 15.36, B_T: 312, B_A: 0, B_O: 312, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 47, PA_A: 37, PA_O: 10, PB_T: 61, PB_A: 56, PB_O: 5, MC_T: 11, MC_A: 0, MC_R: 11, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 9530, SYS_DESCR: 'Satellite Phone (Broadband)', COMM_DATE: '2020-04-30', PRIORITY: 'M4', A_T: 19, A_A: 0, A_O: 19, A_P: 0, B_T: 9, B_A: 0, B_O: 9, B_P: 0, C_T: null, C_A: null, C_O: null, PA_T: 5, PA_A: 5, PA_O: 0, PB_T: null, PB_A: 0, PB_O: 0, MC_T: 2, MC_A: 0, MC_R: 2, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: null, },
      { SYSTEM: 9540, SYS_DESCR: 'Close-Circuit Television (CCTV) Cameras', COMM_DATE: '2020-07-24', PRIORITY: 'M3', A_T: 347, A_A: 49, A_O: 298, A_P: 14.12, B_T: 75, B_A: 0, B_O: 75, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 215, PA_A: 207, PA_O: 8, PB_T: 27, PB_A: 19, PB_O: 8, MC_T: 5, MC_A: 0, MC_R: 5, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 9550, SYS_DESCR: 'Collision Avoidance Radar and AIS', COMM_DATE: '2020-06-24', PRIORITY: 'M4', A_T: 55, A_A: 15, A_O: 40, A_P: 27.27, B_T: 17, B_A: 0, B_O: 17, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 8, PA_A: 5, PA_O: 3, PB_T: 47, PB_A: 41, PB_O: 6, MC_T: 4, MC_A: 0, MC_R: 4, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 9560, SYS_DESCR: 'Fiber Optic and Structured Cabling', COMM_DATE: '2020-05-19', PRIORITY: 'M1', A_T: 1587, A_A: 848, A_O: 739, A_P: 53.43, B_T: 560, B_A: 0, B_O: 560, B_P: 0, C_T: null, C_A: null, C_O: null, PA_T: 120, PA_A: 103, PA_O: 17, PB_T: 523, PB_A: 319, PB_O: 204, MC_T: 19, MC_A: 10, MC_R: 9, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 9580, SYS_DESCR: 'Advanced Collaboration Equipment', COMM_DATE: '2020-06-21', PRIORITY: 'M4', A_T: 595, A_A: 55, A_O: 540, A_P: 9.24, B_T: 185, B_A: 0, B_O: 185, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 9, PA_A: 9, PA_O: 0, PB_T: 42, PB_A: 42, PB_O: 0, MC_T: 6, MC_A: 0, MC_R: 6, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: null, },
      { SYSTEM: 9590, SYS_DESCR: 'Entertainments TV (TVRO)', COMM_DATE: '2020-08-15', PRIORITY: 'M3', A_T: 652, A_A: 251, A_O: 401, A_P: 38.5, B_T: 493, B_A: 0, B_O: 493, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 26, PA_A: 25, PA_O: 1, PB_T: 35, PB_A: 29, PB_O: 6, MC_T: 4, MC_A: 0, MC_R: 4, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 9810, SYS_DESCR: 'Cranes', COMM_DATE: '2020-06-12', PRIORITY: 'M3', A_T: 94, A_A: 39, A_O: 55, A_P: 41.49, B_T: 6, B_A: 0, B_O: 6, B_P: 0, C_T: 5, C_A: 0, C_O: 5, PA_T: 23, PA_A: 21, PA_O: 2, PB_T: 36, PB_A: 31, PB_O: 5, MC_T: 2, MC_A: 0, MC_R: 2, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 9820, SYS_DESCR: 'Lifting Equipment', COMM_DATE: '2020-07-31', PRIORITY: 'M7', A_T: 1753, A_A: 50, A_O: 1703, A_P: 2.85, B_T: 19, B_A: 0, B_O: 19, B_P: 0, C_T: null, C_A: null, C_O: null, PA_T: 5, PA_A: 0, PA_O: 5, PB_T: 3, PB_A: 0, PB_O: 3, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 9840, SYS_DESCR: 'Laboratory', COMM_DATE: '2020-05-22', PRIORITY: 'M7', A_T: 3, A_A: 0, A_O: 3, A_P: 0, B_T: 1, B_A: 0, B_O: 1, B_P: 0, C_T: null, C_A: null, C_O: null, PA_T: null, PA_A: null, PA_O: null, PB_T: null, PB_A: null, PB_O: null, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: null, },
      { SYSTEM: 9851, SYS_DESCR: 'Elevators - Hull Columns', COMM_DATE: '2020-06-06', PRIORITY: 'M6', A_T: 369, A_A: 357, A_O: 12, A_P: 96.75, B_T: 29, B_A: 0, B_O: 29, B_P: 0, C_T: 3, C_A: 0, C_O: 3, PA_T: 100, PA_A: 73, PA_O: 27, PB_T: 277, PB_A: 243, PB_O: 34, MC_T: 2, MC_A: 0, MC_R: 2, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 9852, SYS_DESCR: 'Elevators - Hull Quarters', COMM_DATE: '2020-04-10', PRIORITY: 'M6', A_T: 127, A_A: 0, A_O: 127, A_P: 0, B_T: 13, B_A: 0, B_O: 13, B_P: 0, C_T: 3, C_A: 0, C_O: 3, PA_T: 8, PA_A: 7, PA_O: 1, PB_T: 9, PB_A: 7, PB_O: 2, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 9860, SYS_DESCR: 'Sewage Treatment System', COMM_DATE: '2020-08-18', PRIORITY: 'M4', A_T: 834, A_A: 572, A_O: 262, A_P: 68.59, B_T: 82, B_A: 0, B_O: 82, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 120, PA_A: 100, PA_O: 20, PB_T: 144, PB_A: 99, PB_O: 45, MC_T: 3, MC_A: 0, MC_R: 3, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 9882, SYS_DESCR: 'Independent Remote Monitoring System', COMM_DATE: '2020-07-12', PRIORITY: 'M5', A_T: 15, A_A: 0, A_O: 15, A_P: 0, B_T: 6, B_A: 0, B_O: 6, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: null, PA_A: null, PA_O: null, PB_T: null, PB_A: null, PB_O: null, MC_T: 2, MC_A: 0, MC_R: 2, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: null, },
      { SYSTEM: 9890, SYS_DESCR: 'Near-Field Vessel Wireless System', COMM_DATE: '2020-06-02', PRIORITY: 'M4', A_T: 104, A_A: 25, A_O: 79, A_P: 24.04, B_T: 18, B_A: 0, B_O: 18, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 26, PA_A: 23, PA_O: 3, PB_T: 36, PB_A: 33, PB_O: 3, MC_T: 3, MC_A: 0, MC_R: 3, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 9891, SYS_DESCR: 'Clock system', COMM_DATE: '2020-07-17', PRIORITY: 'M5', A_T: 59, A_A: 0, A_O: 59, A_P: 0, B_T: 30, B_A: 0, B_O: 30, B_P: 0, C_T: null, C_A: null, C_O: null, PA_T: null, PA_A: null, PA_O: null, PB_T: null, PB_A: null, PB_O: null, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: null, },
      { SYSTEM: 9910, SYS_DESCR: 'Marine Valve Hydraulic Power Units', COMM_DATE: '2020-05-31', PRIORITY: 'M2', A_T: 657, A_A: 611, A_O: 46, A_P: 93, B_T: 104, B_A: 41, B_O: 63, B_P: 39.42, C_T: 3, C_A: 0, C_O: 3, PA_T: 137, PA_A: 137, PA_O: 0, PB_T: 467, PB_A: 433, PB_O: 34, MC_T: 4, MC_A: 4, MC_R: 0, STATUS: 'In Progress', ASSOCIATED: 'Y', CAT: 'B', },
      { SYSTEM: 9921, SYS_DESCR: 'HVAC Lower Hull North West', COMM_DATE: '2020-08-29', PRIORITY: 'M5', A_T: 385, A_A: 272, A_O: 113, A_P: 70.65, B_T: 51, B_A: 0, B_O: 51, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 86, PA_A: 86, PA_O: 0, PB_T: 105, PB_A: 60, PB_O: 45, MC_T: 2, MC_A: 0, MC_R: 2, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'B', },
      { SYSTEM: 9922, SYS_DESCR: 'HVAC Lower Hull North East', COMM_DATE: '2020-08-29', PRIORITY: 'M5', A_T: 720, A_A: 586, A_O: 134, A_P: 81.39, B_T: 127, B_A: 0, B_O: 127, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 155, PA_A: 138, PA_O: 17, PB_T: 159, PB_A: 97, PB_O: 62, MC_T: 3, MC_A: 0, MC_R: 3, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 9923, SYS_DESCR: 'HVAC Lower Hull South East', COMM_DATE: '2020-08-26', PRIORITY: 'M5', A_T: 360, A_A: 179, A_O: 181, A_P: 49.72, B_T: 47, B_A: 0, B_O: 47, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 66, PA_A: 64, PA_O: 2, PB_T: 64, PB_A: 57, PB_O: 7, MC_T: 2, MC_A: 0, MC_R: 2, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 9924, SYS_DESCR: 'HVAC Lower Hull South West', COMM_DATE: '2020-08-28', PRIORITY: 'M5', A_T: 713, A_A: 422, A_O: 291, A_P: 59.19, B_T: 128, B_A: 0, B_O: 128, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 139, PA_A: 136, PA_O: 3, PB_T: 89, PB_A: 76, PB_O: 13, MC_T: 3, MC_A: 0, MC_R: 3, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 9930, SYS_DESCR: 'Control Room', COMM_DATE: '2020-08-07', PRIORITY: 'M3', A_T: 234, A_A: 0, A_O: 234, A_P: 0, B_T: 1, B_A: 0, B_O: 1, B_P: 0, C_T: null, C_A: null, C_O: null, PA_T: 1, PA_A: 1, PA_O: 0, PB_T: 2, PB_A: 0, PB_O: 2, MC_T: 9, MC_A: 0, MC_R: 9, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'B', },
      { SYSTEM: 9971, SYS_DESCR: 'HVAC - Compression Module', COMM_DATE: '2020-07-17', PRIORITY: 'M6', A_T: 1040, A_A: 783, A_O: 257, A_P: 75.29, B_T: 170, B_A: 0, B_O: 170, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 141, PA_A: 116, PA_O: 25, PB_T: 90, PB_A: 65, PB_O: 25, MC_T: 1, MC_A: 0, MC_R: 1, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 9972, SYS_DESCR: 'HVAC - Generation Module', COMM_DATE: '2020-06-25', PRIORITY: 'M6', A_T: 1794, A_A: 1427, A_O: 367, A_P: 79.54, B_T: 307, B_A: 0, B_O: 307, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 314, PA_A: 293, PA_O: 21, PB_T: 194, PB_A: 164, PB_O: 30, MC_T: 2, MC_A: 0, MC_R: 2, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 9973, SYS_DESCR: 'HVAC - Water Injection Module', COMM_DATE: '2020-07-17', PRIORITY: 'M6', A_T: 1917, A_A: 1531, A_O: 386, A_P: 79.86, B_T: 346, B_A: 0, B_O: 346, B_P: 0, C_T: 2, C_A: 0, C_O: 2, PA_T: 339, PA_A: 224, PA_O: 115, PB_T: 227, PB_A: 171, PB_O: 56, MC_T: 2, MC_A: 0, MC_R: 2, STATUS: 'Not Started', ASSOCIATED: 'Y', CAT: 'A', },
      { SYSTEM: 9999, SYS_DESCR: 'Temporary Equipment for Commissioning', COMM_DATE: '2020-04-11', PRIORITY: 'M1', A_T: 315, A_A: 307, A_O: 8, A_P: 97.46, B_T: 221, B_A: 178, B_O: 43, B_P: 80.54, C_T: 2, C_A: 1, C_O: 1, PA_T: null, PA_A: null, PA_O: null, PB_T: null, PB_A: null, PB_O: null, MC_T: 7, MC_A: 3, MC_R: 4, STATUS: 'In Progress', ASSOCIATED: 'Y', CAT: null, },
  ],
  
  Queries: {
    SQL1: [
      { DESC: 'DataSource SPCS Data 03-Mar-20' }
    ],
    SQL2: [
      { PHASE: 'B' ,DATE: '2020-02-28', BPQ: 201,   BAQ: 2158,  BPP: 9.57,   BAP: 8.69, },
      { PHASE: 'B' ,DATE: '2020-03-06', BPQ: 805,   BAQ: null,  BPP: 12.03,  BAP: null, },
      { PHASE: 'B' ,DATE: '2020-03-13', BPQ: 1472,  BAQ: null,  BPP: 14.75,  BAP: null, },
      { PHASE: 'B' ,DATE: '2020-03-20', BPQ: 2102,  BAQ: null,  BPP: 17.31,  BAP: null, },
      { PHASE: 'B' ,DATE: '2020-03-27', BPQ: 2727,  BAQ: null,  BPP: 19.86,  BAP: null, },
      { PHASE: 'B' ,DATE: '2020-04-03', BPQ: 3222,  BAQ: null,  BPP: 21.88,  BAP: null, },
      { PHASE: 'B' ,DATE: '2020-04-10', BPQ: 3869,  BAQ: null,  BPP: 24.51,  BAP: null, },
      { PHASE: 'B' ,DATE: '2020-04-17', BPQ: 4611,  BAQ: null,  BPP: 27.53,  BAP: null, },
      { PHASE: 'B' ,DATE: '2020-04-24', BPQ: 5489,  BAQ: null,  BPP: 31.11,  BAP: null, },
      { PHASE: 'B' ,DATE: '2020-05-01', BPQ: 6425,  BAQ: null,  BPP: 34.92,  BAP: null, },
      { PHASE: 'B' ,DATE: '2020-05-08', BPQ: 7505,  BAQ: null,  BPP: 39.32,  BAP: null, },
      { PHASE: 'B' ,DATE: '2020-05-15', BPQ: 8731,  BAQ: null,  BPP: 44.31,  BAP: null, },
      { PHASE: 'B' ,DATE: '2020-05-22', BPQ: 10028, BAQ: null,  BPP: 49.59,  BAP: null, },
      { PHASE: 'B' ,DATE: '2020-05-29', BPQ: 11270, BAQ: null,  BPP: 54.64,  BAP: null, },
      { PHASE: 'B' ,DATE: '2020-06-05', BPQ: 12527, BAQ: null,  BPP: 59.76,  BAP: null, },
      { PHASE: 'B' ,DATE: '2020-06-12', BPQ: 13804, BAQ: null,  BPP: 64.96,  BAP: null, },
      { PHASE: 'B' ,DATE: '2020-06-19', BPQ: 15104, BAQ: null,  BPP: 70.25,  BAP: null, },
      { PHASE: 'B' ,DATE: '2020-06-26', BPQ: 16397, BAQ: null,  BPP: 75.52,  BAP: null, },
      { PHASE: 'B' ,DATE: '2020-07-03', BPQ: 17603, BAQ: null,  BPP: 80.43,  BAP: null, },
      { PHASE: 'B' ,DATE: '2020-07-10', BPQ: 18633, BAQ: null,  BPP: 84.62,  BAP: null, },
      { PHASE: 'B' ,DATE: '2020-07-17', BPQ: 19490, BAQ: null,  BPP: 88.11,  BAP: null, },
      { PHASE: 'B' ,DATE: '2020-07-24', BPQ: 20315, BAQ: null,  BPP: 91.47,  BAP: null, },
      { PHASE: 'B' ,DATE: '2020-07-31', BPQ: 20892, BAQ: null,  BPP: 93.82,  BAP: null, },
      { PHASE: 'B' ,DATE: '2020-08-07', BPQ: 21620, BAQ: null,  BPP: 96.78,  BAP: null, },
      { PHASE: 'B' ,DATE: '2020-08-14', BPQ: 22155, BAQ: null,  BPP: 98.96,  BAP: null, },
      { PHASE: 'B' ,DATE: '2020-08-21', BPQ: 22328, BAQ: null,  BPP: 99.67,  BAP: null, },
      { PHASE: 'B' ,DATE: '2020-08-28', BPQ: 22409, BAQ: null,  BPP: 100,    BAP: null, },
      { PHASE: 'B' ,DATE: '2020-09-04', BPQ: 22409, BAQ: null,  BPP: 100,    BAP: null, },
      { PHASE: 'B' ,DATE: '2020-09-11', BPQ: 22409, BAQ: null,  BPP: 100,    BAP: null, },
                                                                               
    ],
    SQL3: [
      { SYSTEM: 1010, SUBSYSTEM: '1010-01', A_T: 296, A_PG: 21.96, B_T: 46, B_PG: 0, },
      { SYSTEM: 1010, SUBSYSTEM: '1010-02', A_T: 287, A_PG: 20.91, B_T: 45, B_PG: 0, },
      { SYSTEM: 1020, SUBSYSTEM: '1020-01', A_T: 290, A_PG: 19.31, B_T: 45, B_PG: 0, },
      { SYSTEM: 1020, SUBSYSTEM: '1020-02', A_T: 278, A_PG: 19.42, B_T: 45, B_PG: 0, },
      { SYSTEM: 1030, SUBSYSTEM: '1030-02', A_T: 14, A_PG: 28.57, B_T: null, B_PG: null, },
      { SYSTEM: 1030, SUBSYSTEM: '1030-01', A_T: 14, A_PG: 28.57, B_T: null, B_PG: null, },
      { SYSTEM: 1090, SUBSYSTEM: '1090-01', A_T: 14, A_PG: 28.57, B_T: null, B_PG: null, },
      { SYSTEM: 1911, SUBSYSTEM: '1911-02', A_T: 219, A_PG: 42.47, B_T: 2, B_PG: 0, },
      { SYSTEM: 1911, SUBSYSTEM: '1911-01', A_T: 236, A_PG: 84.32, B_T: 43, B_PG: 0, },
      { SYSTEM: 1912, SUBSYSTEM: '1912-02', A_T: 111, A_PG: 36.04, B_T: 2, B_PG: 0, },
      { SYSTEM: 1912, SUBSYSTEM: '1912-01', A_T: 911, A_PG: 85.29, B_T: 144, B_PG: 0, },
      { SYSTEM: 2110, SUBSYSTEM: '2110-02', A_T: 189, A_PG: 17.99, B_T: 22, B_PG: 0, },
      { SYSTEM: 2110, SUBSYSTEM: '2110-01', A_T: 206, A_PG: 29.13, B_T: 18, B_PG: 0, },
      { SYSTEM: 2120, SUBSYSTEM: '2120-03', A_T: 208, A_PG: 37.5, B_T: 30, B_PG: 0, },
      { SYSTEM: 2120, SUBSYSTEM: '2120-02', A_T: 297, A_PG: 41.41, B_T: 23, B_PG: 0, },
      { SYSTEM: 2120, SUBSYSTEM: '2120-01', A_T: 89, A_PG: 30.34, B_T: 6, B_PG: 0, },
      { SYSTEM: 2210, SUBSYSTEM: '2210-04', A_T: 182, A_PG: 29.67, B_T: 13, B_PG: 0, },
      { SYSTEM: 2210, SUBSYSTEM: '2210-03', A_T: 47, A_PG: 17.02, B_T: 6, B_PG: 0, },
      { SYSTEM: 2210, SUBSYSTEM: '2210-02', A_T: 203, A_PG: 20.2, B_T: 20, B_PG: 0, },
      { SYSTEM: 2210, SUBSYSTEM: '2210-01', A_T: 43, A_PG: 20.93, B_T: 7, B_PG: 0, },
      { SYSTEM: 2220, SUBSYSTEM: '2220-01', A_T: 210, A_PG: 24.29, B_T: 16, B_PG: 0, },
      { SYSTEM: 2220, SUBSYSTEM: '2220-02', A_T: 197, A_PG: 34.01, B_T: 18, B_PG: 0, },
      { SYSTEM: 2220, SUBSYSTEM: '2220-03', A_T: 161, A_PG: 44.1, B_T: 16, B_PG: 0, },
      { SYSTEM: 2400, SUBSYSTEM: '2400-06', A_T: 165, A_PG: 23.64, B_T: 94, B_PG: 0, },
      { SYSTEM: 2400, SUBSYSTEM: '2400-07', A_T: 166, A_PG: 25.3, B_T: 97, B_PG: 0, },
      { SYSTEM: 2400, SUBSYSTEM: '2400-08', A_T: 282, A_PG: 23.4, B_T: 34, B_PG: 0, },
      { SYSTEM: 2400, SUBSYSTEM: '2400-09', A_T: 545, A_PG: 2.2, B_T: 98, B_PG: 0, },
      { SYSTEM: 2400, SUBSYSTEM: '2400-10', A_T: 80, A_PG: 8.75, B_T: 16, B_PG: 0, },
      { SYSTEM: 2400, SUBSYSTEM: '2400-05', A_T: 193, A_PG: 32.64, B_T: 97, B_PG: 0, },
      { SYSTEM: 2400, SUBSYSTEM: '2400-04', A_T: 603, A_PG: 16.92, B_T: 97, B_PG: 0, },
      { SYSTEM: 2400, SUBSYSTEM: '2400-03', A_T: 356, A_PG: 42.98, B_T: 69, B_PG: 0, },
      { SYSTEM: 2400, SUBSYSTEM: '2400-02', A_T: 136, A_PG: 38.97, B_T: 13, B_PG: 0, },
      { SYSTEM: 2400, SUBSYSTEM: '2400-01', A_T: 52, A_PG: 9.62, B_T: 5, B_PG: 0, },
      { SYSTEM: 2400, SUBSYSTEM: '2400-09', A_T: 545, A_PG: 2.2, B_T: 98, B_PG: 0, },
      { SYSTEM: 2400, SUBSYSTEM: '2400-09', A_T: 545, A_PG: 2.2, B_T: 98, B_PG: 0, },
      { SYSTEM: 2400, SUBSYSTEM: '2400-09', A_T: 545, A_PG: 2.2, B_T: 98, B_PG: 0, },
      { SYSTEM: 3000, SUBSYSTEM: '3000-02', A_T: 187, A_PG: 43.32, B_T: 131, B_PG: 0, },
      { SYSTEM: 3000, SUBSYSTEM: '3000-01', A_T: 171, A_PG: 43.86, B_T: 131, B_PG: 0, },
      { SYSTEM: 3010, SUBSYSTEM: '3010-03', A_T: 553, A_PG: 48.64, B_T: 85, B_PG: 0, },
      { SYSTEM: 3010, SUBSYSTEM: '3010-02', A_T: 545, A_PG: 49.72, B_T: 87, B_PG: 0, },
      { SYSTEM: 3010, SUBSYSTEM: '3010-01', A_T: 75, A_PG: 8, B_T: 9, B_PG: 0, },
      { SYSTEM: 3020, SUBSYSTEM: '3020-02', A_T: 586, A_PG: 28.67, B_T: 84,   B_PG: 25, },
      { SYSTEM: 3020, SUBSYSTEM: '3020-03', A_T: 592, A_PG: 25,    B_T: 84,   B_PG: 75, },
      { SYSTEM: 3020, SUBSYSTEM: '3020-01', A_T: 134, A_PG: 20.9,  B_T: 16,   B_PG: 50, },
      { SYSTEM: 3020, SUBSYSTEM: '3030-01', A_T: 400, A_PG: 28,    B_T: 70,   B_PG: 33, },
      { SYSTEM: 3020, SUBSYSTEM: '3030-02', A_T: 399, A_PG: 28.82, B_T: 70,   B_PG: 15, },
      { SYSTEM: 3020, SUBSYSTEM: '3040-03', A_T: 812, A_PG: 75.25, B_T: 141,  B_PG: 3, },
      { SYSTEM: 3020, SUBSYSTEM: '3040-02', A_T: 825, A_PG: 74.06, B_T: null,  B_PG: null, },
      { SYSTEM: 3020, SUBSYSTEM: '3040-01', A_T: null, A_PG: 52.3,  B_T: 54,   B_PG: 100, },
      { SYSTEM: 3020, SUBSYSTEM: '3200-01', A_T: 445, A_PG: 47.64, B_T: 46,   B_PG: 85, },
      { SYSTEM: 3020, SUBSYSTEM: '3200-02', A_T: 796, A_PG: 41.46, B_T: 107,  B_PG: 36, },
      { SYSTEM: 3020, SUBSYSTEM: '3400-01', A_T: 280, A_PG: 23.93, B_T: 44,   B_PG: 45, },
      { SYSTEM: 3020, SUBSYSTEM: '3400-03', A_T: 322, A_PG: 29.81, B_T: 58,   B_PG: 24, },
      { SYSTEM: 3020, SUBSYSTEM: '3430-01', A_T: 133, A_PG: 14.29, B_T: 19,   B_PG: 10, },
      { SYSTEM: 3020, SUBSYSTEM: '3430-02', A_T: 56, A_PG: 37.5,   B_T: 11,   B_PG: 50, },
      { SYSTEM: 3470, SUBSYSTEM: '3470-01', A_T: 279, A_PG: 25.81, B_T: 26, B_PG: 0, },
      { SYSTEM: 4100, SUBSYSTEM: '4100-01', A_T: 140, A_PG: 20, B_T: 16, B_PG: 0, },
      { SYSTEM: 4100, SUBSYSTEM: '4100-02', A_T: 497, A_PG: 43.46, B_T: 69, B_PG: 0, },
      { SYSTEM: 4100, SUBSYSTEM: '4100-03', A_T: 146, A_PG: 11.64, B_T: 19, B_PG: 0, },
      { SYSTEM: 4100, SUBSYSTEM: '4100-04', A_T: 139, A_PG: 20.86, B_T: 12, B_PG: 0, },
      { SYSTEM: 4330, SUBSYSTEM: '4330-09', A_T: 419, A_PG: 54.18, B_T: 3, B_PG: 0, },
      { SYSTEM: 4330, SUBSYSTEM: '4330-08', A_T: 116, A_PG: 48.28, B_T: null, B_PG: null, },
      { SYSTEM: 4330, SUBSYSTEM: '4330-07', A_T: 197, A_PG: 75.63, B_T: 4, B_PG: 0, },
      { SYSTEM: 4330, SUBSYSTEM: '4330-06', A_T: 693, A_PG: 69.41, B_T: 7, B_PG: 0, },
      { SYSTEM: 4330, SUBSYSTEM: '4330-05', A_T: 399, A_PG: 55.89, B_T: 6, B_PG: 0, },
      { SYSTEM: 4330, SUBSYSTEM: '4330-01', A_T: 449, A_PG: 81.74, B_T: 6, B_PG: 0, },
      { SYSTEM: 4330, SUBSYSTEM: '4330-02', A_T: 118, A_PG: 27.97, B_T: 38, B_PG: 0, },
      { SYSTEM: 4330, SUBSYSTEM: '4330-03', A_T: 124, A_PG: 27.42, B_T: 38, B_PG: 0, },
      { SYSTEM: 4330, SUBSYSTEM: '4330-04', A_T: 472, A_PG: 38.35, B_T: 1, B_PG: 0, },
      { SYSTEM: 4340, SUBSYSTEM: '4340-02', A_T: 617, A_PG: 38.25, B_T: 77, B_PG: 0, },
      { SYSTEM: 4340, SUBSYSTEM: '4340-01', A_T: 461, A_PG: 40.35, B_T: 10, B_PG: 0, },
      { SYSTEM: 4610, SUBSYSTEM: '4610-01', A_T: 589, A_PG: 64.69, B_T: 82, B_PG: 0, },
      { SYSTEM: 4620, SUBSYSTEM: '4620-04', A_T: 307, A_PG: 52.12, B_T: 40, B_PG: 0, },
      { SYSTEM: 4620, SUBSYSTEM: '4620-03', A_T: 606, A_PG: 49.34, B_T: 186, B_PG: 0, },
      { SYSTEM: 4620, SUBSYSTEM: '4620-02', A_T: null, A_PG: null, B_T: null, B_PG: null, },
      { SYSTEM: 4620, SUBSYSTEM: '4620-01', A_T: 974, A_PG: 92.61, B_T: 127, B_PG: 0, },
      { SYSTEM: 4640, SUBSYSTEM: '4640-01', A_T: 780, A_PG: 88.46, B_T: 84, B_PG: 0, },
      { SYSTEM: 4650, SUBSYSTEM: '4650-01', A_T: 296, A_PG: 75.34, B_T: 44, B_PG: 0, },
      { SYSTEM: 4730, SUBSYSTEM: '4730-01', A_T: 184, A_PG: 14.13, B_T: 19, B_PG: 0, },
      { SYSTEM: 4730, SUBSYSTEM: '4730-02', A_T: 525, A_PG: 63.43, B_T: 244, B_PG: 52.05, },
      { SYSTEM: 4730, SUBSYSTEM: '4730-06', A_T: null, A_PG: null, B_T: null, B_PG: null, },
      { SYSTEM: 4730, SUBSYSTEM: '4730-04', A_T: 119, A_PG: 52.1, B_T: 16, B_PG: 0, },
      { SYSTEM: 4730, SUBSYSTEM: '4730-03', A_T: 519, A_PG: 64.74, B_T: 242, B_PG: 52.48, },
      { SYSTEM: 5010, SUBSYSTEM: '5010-03', A_T: 136, A_PG: 22.79, B_T: 13, B_PG: 0, },
      { SYSTEM: 5010, SUBSYSTEM: '5010-02', A_T: 390, A_PG: 61.28, B_T: 51, B_PG: 0, },
      { SYSTEM: 5010, SUBSYSTEM: '5010-01', A_T: 200, A_PG: 31, B_T: 19, B_PG: 0, },
      { SYSTEM: 5030, SUBSYSTEM: '5030-02', A_T: 104, A_PG: 83.65, B_T: 4, B_PG: 0, },
      { SYSTEM: 5030, SUBSYSTEM: '5030-01', A_T: 137, A_PG: 33.58, B_T: 23, B_PG: 0, },
      { SYSTEM: 5210, SUBSYSTEM: '5210-02', A_T: 149, A_PG: 54.36, B_T: 9, B_PG: 0, },
      { SYSTEM: 5210, SUBSYSTEM: '5210-01', A_T: 189, A_PG: 33.33, B_T: 17, B_PG: 0, },
      { SYSTEM: 5410, SUBSYSTEM: '5410-01', A_T: 281, A_PG: 53.02, B_T: 13, B_PG: 0, },
      { SYSTEM: 5555, SUBSYSTEM: '5555-55', A_T: null, A_PG: null, B_T: 2, B_PG: 0, },
      { SYSTEM: 6010, SUBSYSTEM: '6010-02', A_T: 72, A_PG: 100, B_T: 15, B_PG: 46.67, },
      { SYSTEM: 6010, SUBSYSTEM: '6010-21', A_T: 455, A_PG: 92.31, B_T: 18, B_PG: 0, },
      { SYSTEM: 6010, SUBSYSTEM: '6010-20', A_T: 183, A_PG: 92.9, B_T: 7, B_PG: 0, },
      { SYSTEM: 6010, SUBSYSTEM: '6010-19', A_T: 91, A_PG: 94.51, B_T: 4, B_PG: 0, },
      { SYSTEM: 6010, SUBSYSTEM: '6010-01', A_T: 38, A_PG: 100, B_T: 8, B_PG: 50, },
      { SYSTEM: 6010, SUBSYSTEM: '6010-03', A_T: 118, A_PG: 94.92, B_T: 157, B_PG: 0.64, },
      { SYSTEM: 6010, SUBSYSTEM: '6010-04', A_T: 25, A_PG: 100, B_T: 6, B_PG: 50, },
      { SYSTEM: 6010, SUBSYSTEM: '6010-05', A_T: 25, A_PG: 100, B_T: 6, B_PG: 50, },
      { SYSTEM: 6010, SUBSYSTEM: '6010-06', A_T: 113, A_PG: 96.46, B_T: 22, B_PG: 63.64, },
      { SYSTEM: 6010, SUBSYSTEM: '6010-07', A_T: 39, A_PG: 97.44, B_T: 8, B_PG: 50, },
      { SYSTEM: 6010, SUBSYSTEM: '6010-08', A_T: 34, A_PG: 100, B_T: 7, B_PG: 57.14, },
      { SYSTEM: 6010, SUBSYSTEM: '6010-10', A_T: 15, A_PG: 80, B_T: null, B_PG: null, },
      { SYSTEM: 6010, SUBSYSTEM: '6010-11', A_T: 14, A_PG: 71.43, B_T: null, B_PG: null, },
      { SYSTEM: 6010, SUBSYSTEM: '6010-12', A_T: 38, A_PG: 81.58, B_T: null, B_PG: null, },
      { SYSTEM: 6010, SUBSYSTEM: '6010-13', A_T: 35, A_PG: 60, B_T: null, B_PG: null, },
      { SYSTEM: 6010, SUBSYSTEM: '6010-14', A_T: 62, A_PG: 51.61, B_T: null, B_PG: null, },
      { SYSTEM: 6010, SUBSYSTEM: '6010-18', A_T: 593, A_PG: 94.94, B_T: 24, B_PG: 0, },
      { SYSTEM: 6020, SUBSYSTEM: '6020-06', A_T: 307, A_PG: 99.02, B_T: 64, B_PG: 67.19, },
      { SYSTEM: 6020, SUBSYSTEM: '6020-07', A_T: 99, A_PG: 100, B_T: 22, B_PG: 50, },
      { SYSTEM: 6020, SUBSYSTEM: '6020-08', A_T: 46, A_PG: 100, B_T: 10, B_PG: 70, },
      { SYSTEM: 6020, SUBSYSTEM: '6020-10', A_T: 22, A_PG: 81.82, B_T: null, B_PG: null, },
      { SYSTEM: 6020, SUBSYSTEM: '6020-11', A_T: 27, A_PG: 81.48, B_T: null, B_PG: null, },
      { SYSTEM: 6020, SUBSYSTEM: '6020-12', A_T: 84, A_PG: 80.95, B_T: null, B_PG: null, },
      { SYSTEM: 6020, SUBSYSTEM: '6020-13', A_T: 80, A_PG: 68.75, B_T: null, B_PG: null, },
      { SYSTEM: 6020, SUBSYSTEM: '6020-14', A_T: 260, A_PG: 47.69, B_T: null, B_PG: null, },
      { SYSTEM: 6020, SUBSYSTEM: '6020-16', A_T: 35, A_PG: 100, B_T: null, B_PG: null, },
      { SYSTEM: 6020, SUBSYSTEM: '6020-05', A_T: 109, A_PG: 100, B_T: 22, B_PG: 50, },
      { SYSTEM: 6020, SUBSYSTEM: '6020-04', A_T: 111, A_PG: 100, B_T: 22, B_PG: 50, },
      { SYSTEM: 6020, SUBSYSTEM: '6020-03', A_T: 89, A_PG: 100, B_T: 14, B_PG: 14.29, },
      { SYSTEM: 6020, SUBSYSTEM: '6020-02', A_T: 134, A_PG: 98.51, B_T: 24, B_PG: 50, },
      { SYSTEM: 6020, SUBSYSTEM: '6020-01', A_T: 97, A_PG: 96.91, B_T: 20, B_PG: 50, },
      { SYSTEM: 6030, SUBSYSTEM: '6030-06', A_T: 50, A_PG: 100, B_T: 24, B_PG: 79.17, },
      { SYSTEM: 6030, SUBSYSTEM: '6030-07', A_T: 28, A_PG: 100, B_T: 10, B_PG: 50, },
      { SYSTEM: 6030, SUBSYSTEM: '6030-08', A_T: 20, A_PG: 100, B_T: 10, B_PG: 80, },
      { SYSTEM: 6030, SUBSYSTEM: '6030-09', A_T: 93, A_PG: 0, B_T: 48, B_PG: 0, },
      { SYSTEM: 6030, SUBSYSTEM: '6030-11', A_T: 20, A_PG: 0, B_T: 13, B_PG: 0, },
      { SYSTEM: 6030, SUBSYSTEM: '6030-12', A_T: 133, A_PG: 95.49, B_T: 22, B_PG: 0, },
      { SYSTEM: 6030, SUBSYSTEM: '6030-13', A_T: 54, A_PG: 0, B_T: 4, B_PG: 0, },
      { SYSTEM: 6030, SUBSYSTEM: '6030-14', A_T: 29, A_PG: 0, B_T: 7, B_PG: 0, },
      { SYSTEM: 6030, SUBSYSTEM: '6030-15', A_T: 32, A_PG: 0, B_T: 8, B_PG: 0, },
      { SYSTEM: 6030, SUBSYSTEM: '6030-16', A_T: 12, A_PG: 0, B_T: 3, B_PG: 0, },
      { SYSTEM: 6030, SUBSYSTEM: '6030-17', A_T: 12, A_PG: 0, B_T: 3, B_PG: 0, },
      { SYSTEM: 6030, SUBSYSTEM: '6030-05', A_T: 37, A_PG: 100, B_T: 10, B_PG: 40, },
      { SYSTEM: 6030, SUBSYSTEM: '6030-04', A_T: 32, A_PG: 93.75, B_T: 10, B_PG: 40, },
      { SYSTEM: 6030, SUBSYSTEM: '6030-03', A_T: 18, A_PG: 94.44, B_T: 10, B_PG: 40, },
      { SYSTEM: 6030, SUBSYSTEM: '6030-02', A_T: 33, A_PG: 93.94, B_T: 10, B_PG: 40, },
      { SYSTEM: 6030, SUBSYSTEM: '6030-01', A_T: 32, A_PG: 100, B_T: 10, B_PG: 40, },
      { SYSTEM: 6050, SUBSYSTEM: '6050-01', A_T: 494, A_PG: 27.94, B_T: 42, B_PG: 0, },
      { SYSTEM: 6060, SUBSYSTEM: '6060-05', A_T: 29, A_PG: 100, B_T: 18, B_PG: 0, },
      { SYSTEM: 6060, SUBSYSTEM: '6060-04', A_T: 66, A_PG: 100, B_T: 34, B_PG: 0, },
      { SYSTEM: 6060, SUBSYSTEM: '6060-03', A_T: 15, A_PG: 100, B_T: 10, B_PG: 0, },
      { SYSTEM: 6060, SUBSYSTEM: '6060-02', A_T: 29, A_PG: 96.55, B_T: 18, B_PG: 0, },
      { SYSTEM: 6060, SUBSYSTEM: '6060-01', A_T: 42, A_PG: 71.43, B_T: 19, B_PG: 0, },
      { SYSTEM: 6070, SUBSYSTEM: '6070-01', A_T: 63, A_PG: 100, B_T: 14, B_PG: 0, },
      { SYSTEM: 6070, SUBSYSTEM: '6070-02', A_T: 63, A_PG: 100, B_T: 14, B_PG: 0, },
      { SYSTEM: 6070, SUBSYSTEM: '6070-03', A_T: 212, A_PG: 97.64, B_T: 168, B_PG: 2.98, },
      { SYSTEM: 6070, SUBSYSTEM: '6070-04', A_T: 63, A_PG: 100, B_T: 14, B_PG: 0, },
      { SYSTEM: 6070, SUBSYSTEM: '6070-05', A_T: 114, A_PG: 100, B_T: 24, B_PG: 0, },
      { SYSTEM: 6070, SUBSYSTEM: '6070-06', A_T: 111, A_PG: 100, B_T: 26, B_PG: 38.46, },
      { SYSTEM: 6070, SUBSYSTEM: '6070-07', A_T: 47, A_PG: 100, B_T: 12, B_PG: 41.67, },
      { SYSTEM: 6070, SUBSYSTEM: '6070-08', A_T: 42, A_PG: 100, B_T: 8, B_PG: 62.5, },
      { SYSTEM: 6070, SUBSYSTEM: '6070-10', A_T: 40, A_PG: 80, B_T: null, B_PG: null, },
      { SYSTEM: 6070, SUBSYSTEM: '6070-11', A_T: 50, A_PG: 76, B_T: null, B_PG: null, },
      { SYSTEM: 6070, SUBSYSTEM: '6070-12', A_T: 116, A_PG: 88.79, B_T: null, B_PG: null, },
      { SYSTEM: 6070, SUBSYSTEM: '6070-13', A_T: 125, A_PG: 72.8, B_T: null, B_PG: null, },
      { SYSTEM: 6070, SUBSYSTEM: '6070-14', A_T: 109, A_PG: 60.55, B_T: null, B_PG: null, },
      { SYSTEM: 6070, SUBSYSTEM: '6070-16', A_T: 2, A_PG: 100, B_T: null, B_PG: null, },
      { SYSTEM: 6070, SUBSYSTEM: '6070-17', A_T: 49, A_PG: 63.27, B_T: null, B_PG: null, },
      { SYSTEM: 6071, SUBSYSTEM: '6071-01', A_T: 120, A_PG: 100, B_T: 67, B_PG: 0, },
      { SYSTEM: 6072, SUBSYSTEM: '6072-01', A_T: 114, A_PG: 100, B_T: 68, B_PG: 0, },
      { SYSTEM: 6073, SUBSYSTEM: '6073-20', A_T: 209, A_PG: 7.18, B_T: 92, B_PG: 0, },
      { SYSTEM: 6073, SUBSYSTEM: '6073-19', A_T: 264, A_PG: 28.03, B_T: 114, B_PG: 0, },
      { SYSTEM: 6073, SUBSYSTEM: '6073-18', A_T: 208, A_PG: 51.92, B_T: 108, B_PG: 0, },
      { SYSTEM: 6073, SUBSYSTEM: '6073-17', A_T: 223, A_PG: 39.91, B_T: 99, B_PG: 0, },
      { SYSTEM: 6073, SUBSYSTEM: '6073-01', A_T: 132, A_PG: 34.09, B_T: 65, B_PG: 0, },
      { SYSTEM: 6073, SUBSYSTEM: '6073-02', A_T: 129, A_PG: 0, B_T: 67, B_PG: 0, },
      { SYSTEM: 6073, SUBSYSTEM: '6073-03', A_T: 278, A_PG: 40.65, B_T: 74, B_PG: 0, },
      { SYSTEM: 6073, SUBSYSTEM: '6073-04', A_T: 157, A_PG: 7.64, B_T: 39, B_PG: 0, },
      { SYSTEM: 6073, SUBSYSTEM: '6073-05', A_T: 116, A_PG: 0, B_T: 53, B_PG: 0, },
      { SYSTEM: 6073, SUBSYSTEM: '6073-06', A_T: 185, A_PG: 15.14, B_T: 79, B_PG: 0, },
      { SYSTEM: 6073, SUBSYSTEM: '6073-07', A_T: 115, A_PG: 26.09, B_T: 65, B_PG: 0, },
      { SYSTEM: 6073, SUBSYSTEM: '6073-16', A_T: 181, A_PG: 49.17, B_T: 89, B_PG: 0, },
      { SYSTEM: 6073, SUBSYSTEM: '6073-15', A_T: 588, A_PG: 82.65, B_T: 178, B_PG: 0, },
      { SYSTEM: 6073, SUBSYSTEM: '6073-14', A_T: 187, A_PG: 17.11, B_T: 104, B_PG: 0, },
      { SYSTEM: 6073, SUBSYSTEM: '6073-13', A_T: 181, A_PG: 35.91, B_T: 92, B_PG: 0, },
      { SYSTEM: 6073, SUBSYSTEM: '6073-12', A_T: 105, A_PG: 0, B_T: 42, B_PG: 0, },
      { SYSTEM: 6073, SUBSYSTEM: '6073-11', A_T: 47, A_PG: 0, B_T: 24, B_PG: 0, },
      { SYSTEM: 6073, SUBSYSTEM: '6073-10', A_T: 160, A_PG: 0, B_T: 78, B_PG: 0, },
      { SYSTEM: 6073, SUBSYSTEM: '6073-09', A_T: 50, A_PG: 12, B_T: 30, B_PG: 0, },
      { SYSTEM: 6073, SUBSYSTEM: '6073-08', A_T: 81, A_PG: 38.27, B_T: 35, B_PG: 0, },
      { SYSTEM: 6074, SUBSYSTEM: '6074-02', A_T: 104, A_PG: 1.92, B_T: 28, B_PG: 0, },
      { SYSTEM: 6074, SUBSYSTEM: '6074-01', A_T: 50, A_PG: 2, B_T: 18, B_PG: 0, },
      { SYSTEM: 6075, SUBSYSTEM: '6075-01', A_T: 978, A_PG: 75.26, B_T: 236, B_PG: 0, },
      { SYSTEM: 6076, SUBSYSTEM: '6076-01', A_T: 1078, A_PG: 77.92, B_T: 289, B_PG: 0, },
      { SYSTEM: 6077, SUBSYSTEM: '6077-01', A_T: 487, A_PG: 74.74, B_T: 143, B_PG: 27.97, },
      { SYSTEM: 6078, SUBSYSTEM: '6078-01', A_T: 581, A_PG: 74.87, B_T: 164, B_PG: 41.46, },
      { SYSTEM: 6079, SUBSYSTEM: '6079-01', A_T: 31, A_PG: 80.65, B_T: 3, B_PG: 0, },
      { SYSTEM: 6079, SUBSYSTEM: '6079-02', A_T: 34, A_PG: 23.53, B_T: 10, B_PG: 0, },
      { SYSTEM: 6079, SUBSYSTEM: '6079-03', A_T: 19, A_PG: 36.84, B_T: 4, B_PG: 0, },
      { SYSTEM: 6080, SUBSYSTEM: '6080-04', A_T: 15, A_PG: 13.33, B_T: 1, B_PG: 0, },
      { SYSTEM: 6080, SUBSYSTEM: '6080-03', A_T: 34, A_PG: 0, B_T: 9, B_PG: 0, },
      { SYSTEM: 6080, SUBSYSTEM: '6080-02', A_T: 94, A_PG: 63.83, B_T: 8, B_PG: 0, },
      { SYSTEM: 6080, SUBSYSTEM: '6080-01', A_T: 16, A_PG: 6.25, B_T: 4, B_PG: 0, },
      { SYSTEM: 6110, SUBSYSTEM: '6110-04', A_T: 33, A_PG: 3.03, B_T: 32, B_PG: 0, },
      { SYSTEM: 6110, SUBSYSTEM: '6110-03', A_T: 553, A_PG: 57.32, B_T: 119, B_PG: 0, },
      { SYSTEM: 6110, SUBSYSTEM: '6110-02', A_T: 174, A_PG: 36.21, B_T: 21, B_PG: 0, },
      { SYSTEM: 6110, SUBSYSTEM: '6110-01', A_T: 146, A_PG: 27.4, B_T: 26, B_PG: 0, },
      { SYSTEM: 6120, SUBSYSTEM: '6120-09', A_T: 112, A_PG: 35.71, B_T: 1, B_PG: 0, },
      { SYSTEM: 6120, SUBSYSTEM: '6120-08', A_T: 98, A_PG: 32.65, B_T: 1, B_PG: 0, },
      { SYSTEM: 6120, SUBSYSTEM: '6120-07', A_T: 170, A_PG: 23.53, B_T: 1, B_PG: 0, },
      { SYSTEM: 6120, SUBSYSTEM: '6120-06', A_T: 37, A_PG: 8.11, B_T: 1, B_PG: 0, },
      { SYSTEM: 6120, SUBSYSTEM: '6120-05', A_T: 66, A_PG: 19.7, B_T: 1, B_PG: 0, },
      { SYSTEM: 6120, SUBSYSTEM: '6120-04', A_T: 57, A_PG: 5.26, B_T: 1, B_PG: 0, },
      { SYSTEM: 6120, SUBSYSTEM: '6120-03', A_T: 71, A_PG: 5.63, B_T: 1, B_PG: 0, },
      { SYSTEM: 6120, SUBSYSTEM: '6120-01', A_T: 494, A_PG: 21.66, B_T: 39, B_PG: 0, },
      { SYSTEM: 6120, SUBSYSTEM: '6120-11', A_T: 272, A_PG: 66.18, B_T: 1, B_PG: 0, },
      { SYSTEM: 6120, SUBSYSTEM: '6120-10', A_T: 76, A_PG: 47.37, B_T: 1, B_PG: 0, },
      { SYSTEM: 6130, SUBSYSTEM: '6130-01', A_T: 730, A_PG: 40.41, B_T: 127, B_PG: 0, },
      { SYSTEM: 6130, SUBSYSTEM: '6130-01', A_T: 730, A_PG: 40.41, B_T: 127, B_PG: 0, },
      { SYSTEM: 6130, SUBSYSTEM: '6130-01', A_T: 730, A_PG: 40.41, B_T: 127, B_PG: 0, },
      { SYSTEM: 6130, SUBSYSTEM: '6130-01', A_T: 730, A_PG: 40.41, B_T: 127, B_PG: 0, },
      { SYSTEM: 6140, SUBSYSTEM: '6140-01', A_T: 36, A_PG: 58.33, B_T: 4, B_PG: 0, },
      { SYSTEM: 6150, SUBSYSTEM: '6150-04', A_T: 67, A_PG: 70.15, B_T: 3, B_PG: 0, },
      { SYSTEM: 6150, SUBSYSTEM: '6150-03', A_T: 224, A_PG: 41.96, B_T: 12, B_PG: 0, },
      { SYSTEM: 6150, SUBSYSTEM: '6150-02', A_T: 355, A_PG: 36.9, B_T: 15, B_PG: 0, },
      { SYSTEM: 6150, SUBSYSTEM: '6150-01', A_T: 257, A_PG: 43.19, B_T: 14, B_PG: 0, },
      { SYSTEM: 6160, SUBSYSTEM: '6160-08', A_T: 40, A_PG: 87.5, B_T: 4, B_PG: 0, },
      { SYSTEM: 6160, SUBSYSTEM: '6160-07', A_T: 89, A_PG: 58.43, B_T: 8, B_PG: 0, },
      { SYSTEM: 6160, SUBSYSTEM: '6160-06', A_T: 107, A_PG: 84.11, B_T: 16, B_PG: 0, },
      { SYSTEM: 6160, SUBSYSTEM: '6160-05', A_T: 100, A_PG: 86, B_T: 16, B_PG: 0, },
      { SYSTEM: 6160, SUBSYSTEM: '6160-04', A_T: 123, A_PG: 62.6, B_T: 13, B_PG: 0, },
      { SYSTEM: 6160, SUBSYSTEM: '6160-03', A_T: 72, A_PG: 69.44, B_T: 8, B_PG: 0, },
      { SYSTEM: 6160, SUBSYSTEM: '6160-02', A_T: 149, A_PG: 63.76, B_T: 18, B_PG: 0, },
      { SYSTEM: 6160, SUBSYSTEM: '6160-01', A_T: 119, A_PG: 64.71, B_T: 14, B_PG: 0, },
      { SYSTEM: 6170, SUBSYSTEM: '6170-01', A_T: 330, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 6210, SUBSYSTEM: '6210-11', A_T: 18, A_PG: 0, B_T: 10, B_PG: 0, },
      { SYSTEM: 6210, SUBSYSTEM: '6210-10', A_T: 6, A_PG: 83.33, B_T: null, B_PG: null, },
      { SYSTEM: 6210, SUBSYSTEM: '6210-08', A_T: 210, A_PG: 98.1, B_T: 40, B_PG: 67.5, },
      { SYSTEM: 6210, SUBSYSTEM: '6210-07', A_T: 210, A_PG: 97.14, B_T: 37, B_PG: 70.27, },
      { SYSTEM: 6210, SUBSYSTEM: '6210-06', A_T: 214, A_PG: 97.2, B_T: 38, B_PG: 26.32, },
      { SYSTEM: 6210, SUBSYSTEM: '6210-05', A_T: 189, A_PG: 95.24, B_T: 34, B_PG: 0, },
      { SYSTEM: 6210, SUBSYSTEM: '6210-04', A_T: 598, A_PG: 9.03, B_T: 139, B_PG: 0, },
      { SYSTEM: 6210, SUBSYSTEM: '6210-03', A_T: 749, A_PG: 72.76, B_T: 23, B_PG: 0, },
      { SYSTEM: 6210, SUBSYSTEM: '6210-02', A_T: 1001, A_PG: 43.46, B_T: 246, B_PG: 0, },
      { SYSTEM: 6210, SUBSYSTEM: '6210-01', A_T: 31, A_PG: 51.61, B_T: 114, B_PG: 0, },
      { SYSTEM: 6210, SUBSYSTEM: '6210-09', A_T: 12, A_PG: 8.33, B_T: 4, B_PG: 0, },
      { SYSTEM: 6221, SUBSYSTEM: '6221-09', A_T: 26, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 6221, SUBSYSTEM: '6221-08', A_T: 48, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 6221, SUBSYSTEM: '6221-07', A_T: 88, A_PG: 0, B_T: 3, B_PG: 0, },
      { SYSTEM: 6221, SUBSYSTEM: '6221-01', A_T: 29, A_PG: 0, B_T: 2, B_PG: 0, },
      { SYSTEM: 6221, SUBSYSTEM: '6221-02', A_T: 29, A_PG: 0, B_T: 2, B_PG: 0, },
      { SYSTEM: 6221, SUBSYSTEM: '6221-03', A_T: 16, A_PG: 37.5, B_T: 4, B_PG: 0, },
      { SYSTEM: 6221, SUBSYSTEM: '6221-04', A_T: 39, A_PG: 28.21, B_T: 10, B_PG: 0, },
      { SYSTEM: 6221, SUBSYSTEM: '6221-05', A_T: 572, A_PG: 83.22, B_T: 252, B_PG: 0, },
      { SYSTEM: 6221, SUBSYSTEM: '6221-06', A_T: 68, A_PG: 0, B_T: 1, B_PG: 0, },
      { SYSTEM: 6222, SUBSYSTEM: '6222-01', A_T: 31, A_PG: 0, B_T: 4, B_PG: 0, },
      { SYSTEM: 6222, SUBSYSTEM: '6222-02', A_T: 24, A_PG: 0, B_T: 7, B_PG: 0, },
      { SYSTEM: 6223, SUBSYSTEM: '6223-01', A_T: 22, A_PG: 22.73, B_T: 8, B_PG: 0, },
      { SYSTEM: 6223, SUBSYSTEM: '6223-02', A_T: 10, A_PG: 0, B_T: 9, B_PG: 0, },
      { SYSTEM: 6223, SUBSYSTEM: '6223-03', A_T: 6, A_PG: 0, B_T: 1, B_PG: 0, },
      { SYSTEM: 6320, SUBSYSTEM: '6320-01', A_T: 29, A_PG: 27.59, B_T: 12, B_PG: 0, },
      { SYSTEM: 6320, SUBSYSTEM: '6320-02', A_T: 29, A_PG: 27.59, B_T: 12, B_PG: 0, },
      { SYSTEM: 6320, SUBSYSTEM: '6320-03', A_T: 29, A_PG: 27.59, B_T: 12, B_PG: 0, },
      { SYSTEM: 6320, SUBSYSTEM: '6320-04', A_T: 29, A_PG: 27.59, B_T: 12, B_PG: 0, },
      { SYSTEM: 6330, SUBSYSTEM: '6330-01', A_T: 21, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 6340, SUBSYSTEM: '6340-01', A_T: 50, A_PG: 40, B_T: 16, B_PG: 0, },
      { SYSTEM: 6440, SUBSYSTEM: '6440-01', A_T: 238, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 6510, SUBSYSTEM: '6510-01', A_T: 121, A_PG: 19.83, B_T: 34, B_PG: 0, },
      { SYSTEM: 6630, SUBSYSTEM: '6630-01', A_T: 487, A_PG: 78.64, B_T: 108, B_PG: 0, },
      { SYSTEM: 6710, SUBSYSTEM: '6710-18', A_T: 447, A_PG: 44.52, B_T: 23, B_PG: 0, },
      { SYSTEM: 6710, SUBSYSTEM: '6710-17', A_T: 202, A_PG: 98.51, B_T: 14, B_PG: 0, },
      { SYSTEM: 6710, SUBSYSTEM: '6710-16', A_T: 427, A_PG: 65.11, B_T: 17, B_PG: 0, },
      { SYSTEM: 6710, SUBSYSTEM: '6710-15', A_T: 476, A_PG: 67.65, B_T: 22, B_PG: 0, },
      { SYSTEM: 6710, SUBSYSTEM: '6710-14', A_T: 519, A_PG: 63.58, B_T: 20, B_PG: 0, },
      { SYSTEM: 6710, SUBSYSTEM: '6710-01', A_T: 59, A_PG: 28.81, B_T: 15, B_PG: 0, },
      { SYSTEM: 6710, SUBSYSTEM: '6710-19', A_T: 145, A_PG: 16.55, B_T: 11, B_PG: 0, },
      { SYSTEM: 6710, SUBSYSTEM: '6710-20', A_T: 164, A_PG: 10.98, B_T: 12, B_PG: 0, },
      { SYSTEM: 6710, SUBSYSTEM: '6710-21', A_T: 152, A_PG: 53.29, B_T: 8, B_PG: 0, },
      { SYSTEM: 6710, SUBSYSTEM: '6710-22', A_T: 200, A_PG: 42, B_T: 11, B_PG: 0, },
      { SYSTEM: 6710, SUBSYSTEM: '6710-23', A_T: 295, A_PG: 22.37, B_T: 15, B_PG: 0, },
      { SYSTEM: 6710, SUBSYSTEM: '6710-24', A_T: 14, A_PG: 78.57, B_T: 3, B_PG: 0, },
      { SYSTEM: 6710, SUBSYSTEM: '6710-25', A_T: 201, A_PG: 66.67, B_T: 14, B_PG: 0, },
      { SYSTEM: 6710, SUBSYSTEM: '6710-28', A_T: 484, A_PG: 88.64, B_T: 16, B_PG: 0, },
      { SYSTEM: 6710, SUBSYSTEM: '6710-27', A_T: 82, A_PG: 42.68, B_T: 8, B_PG: 0, },
      { SYSTEM: 6710, SUBSYSTEM: '6710-26', A_T: 192, A_PG: 31.25, B_T: 10, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-29', A_T: 6, A_PG: 33.33, B_T: 4, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-28', A_T: 6, A_PG: 66.67, B_T: 4, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-27', A_T: 6, A_PG: 66.67, B_T: 4, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-26', A_T: 6, A_PG: 33.33, B_T: 4, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-25', A_T: 6, A_PG: 33.33, B_T: 4, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-24', A_T: 4, A_PG: 50, B_T: 3, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-30', A_T: 8, A_PG: 25, B_T: 4, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-31', A_T: 6, A_PG: 66.67, B_T: 4, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-32', A_T: 6, A_PG: 50, B_T: 4, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-33', A_T: 4, A_PG: 50, B_T: 4, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-34', A_T: 4, A_PG: 100, B_T: 4, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-35', A_T: 4, A_PG: 0, B_T: 4, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-36', A_T: 4, A_PG: 0, B_T: 4, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-37', A_T: 4, A_PG: 0, B_T: 4, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-38', A_T: 6, A_PG: 33.33, B_T: 4, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-39', A_T: 2, A_PG: 0, B_T: 4, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-41', A_T: 600, A_PG: 89.33, B_T: 22, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-42', A_T: 672, A_PG: 94.79, B_T: 22, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-43', A_T: 54, A_PG: 59.26, B_T: 10, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-44', A_T: 17, A_PG: 70.59, B_T: 7, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-45', A_T: 48, A_PG: 95.83, B_T: 6, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-46', A_T: 45, A_PG: 93.33, B_T: 6, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-47', A_T: 4, A_PG: 50, B_T: 4, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-01', A_T: 44, A_PG: 95.45, B_T: 14, B_PG: 85.71, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-02', A_T: 43, A_PG: 95.35, B_T: 15, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-03', A_T: 598, A_PG: 95.32, B_T: 28, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-04', A_T: 38, A_PG: 36.84, B_T: 13, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-05', A_T: 45, A_PG: 20, B_T: 14, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-06', A_T: 70, A_PG: 97.14, B_T: 18, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-07', A_T: 72, A_PG: 18.06, B_T: 19, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-08', A_T: 68, A_PG: 27.94, B_T: 18, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-09', A_T: 93, A_PG: 38.71, B_T: 21, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-10', A_T: 43, A_PG: 100, B_T: 10, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-12', A_T: 170, A_PG: 57.06, B_T: 10, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-13', A_T: 227, A_PG: 40.97, B_T: 18, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-14', A_T: 173, A_PG: 17.34, B_T: 14, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-15', A_T: 227, A_PG: 3.08, B_T: 10, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-16', A_T: 3, A_PG: 100, B_T: 1, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-17', A_T: 7, A_PG: 100, B_T: 3, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-18', A_T: 8, A_PG: 50, B_T: 4, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-19', A_T: 10, A_PG: 60, B_T: 4, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-20', A_T: 6, A_PG: 33.33, B_T: 4, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-21', A_T: 6, A_PG: 33.33, B_T: 4, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-22', A_T: 8, A_PG: 75, B_T: 4, B_PG: 0, },
      { SYSTEM: 6720, SUBSYSTEM: '6720-23', A_T: 6, A_PG: 66.67, B_T: 4, B_PG: 0, },
      { SYSTEM: 6730, SUBSYSTEM: '6730-02', A_T: 8, A_PG: 0, B_T: 2, B_PG: 0, },
      { SYSTEM: 6730, SUBSYSTEM: '6730-01', A_T: 63, A_PG: 30.16, B_T: 16, B_PG: 0, },
      { SYSTEM: 6740, SUBSYSTEM: '6740-08', A_T: 19, A_PG: 100, B_T: 20, B_PG: 80, },
      { SYSTEM: 6740, SUBSYSTEM: '6740-07', A_T: 11, A_PG: 100, B_T: 7, B_PG: 42.86, },
      { SYSTEM: 6740, SUBSYSTEM: '6740-06', A_T: 22, A_PG: 100, B_T: 20, B_PG: 80, },
      { SYSTEM: 6740, SUBSYSTEM: '6740-05', A_T: 23, A_PG: 100, B_T: 15, B_PG: 73.33, },
      { SYSTEM: 6740, SUBSYSTEM: '6740-04', A_T: 11, A_PG: 100, B_T: 10, B_PG: 0, },
      { SYSTEM: 6740, SUBSYSTEM: '6740-03', A_T: 10, A_PG: 100, B_T: 10, B_PG: 0, },
      { SYSTEM: 6740, SUBSYSTEM: '6740-02', A_T: 11, A_PG: 100, B_T: 9, B_PG: 0, },
      { SYSTEM: 6740, SUBSYSTEM: '6740-01', A_T: 75, A_PG: 98.67, B_T: 33, B_PG: 9.09, },
      { SYSTEM: 6810, SUBSYSTEM: '6810-03', A_T: 170, A_PG: 44.12, B_T: 23, B_PG: 0, },
      { SYSTEM: 6810, SUBSYSTEM: '6810-02', A_T: 593, A_PG: 55.48, B_T: 113, B_PG: 0, },
      { SYSTEM: 6810, SUBSYSTEM: '6810-01', A_T: 503, A_PG: 54.27, B_T: 109, B_PG: 0, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-35', A_T: 428, A_PG: 56.31, B_T: 42, B_PG: 0, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-34', A_T: 255, A_PG: 31.76, B_T: 25, B_PG: 0, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-33', A_T: 1027, A_PG: 88.8, B_T: 42, B_PG: 0, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-32', A_T: 307, A_PG: 20.52, B_T: 25, B_PG: 0, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-31', A_T: 9, A_PG: 77.78, B_T: 6, B_PG: 0, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-30', A_T: 280, A_PG: 67.14, B_T: 21, B_PG: 0, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-29', A_T: 391, A_PG: 64.45, B_T: 27, B_PG: 0, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-28', A_T: 297, A_PG: 94.28, B_T: 21, B_PG: 0, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-27', A_T: 185, A_PG: 100, B_T: 16, B_PG: 0, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-26', A_T: 163, A_PG: 100, B_T: 13, B_PG: 0, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-25', A_T: 208, A_PG: 93.75, B_T: 15, B_PG: 0, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-24', A_T: 178, A_PG: 100, B_T: 12, B_PG: 0, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-23', A_T: 25, A_PG: 100, B_T: 33, B_PG: 63.64, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-21', A_T: 11, A_PG: 100, B_T: 8, B_PG: 0, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-20', A_T: 11, A_PG: 100, B_T: 6, B_PG: 0, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-19', A_T: 13, A_PG: 100, B_T: 6, B_PG: 0, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-18', A_T: 13, A_PG: 100, B_T: 7, B_PG: 0, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-36', A_T: 359, A_PG: 45.4, B_T: 30, B_PG: 0, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-37', A_T: 435, A_PG: 34.25, B_T: 42, B_PG: 0, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-38', A_T: 396, A_PG: 19.19, B_T: 34, B_PG: 0, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-39', A_T: 1009, A_PG: 91.77, B_T: 39, B_PG: 0, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-40', A_T: 955, A_PG: 92.77, B_T: 38, B_PG: 0, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-42', A_T: 11, A_PG: 100, B_T: 6, B_PG: 100, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-43', A_T: 11, A_PG: 100, B_T: 6, B_PG: 100, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-44', A_T: 11, A_PG: 100, B_T: 6, B_PG: 83.33, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-45', A_T: 9, A_PG: 88.89, B_T: 5, B_PG: 0, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-46', A_T: 9, A_PG: 88.89, B_T: 6, B_PG: 0, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-47', A_T: 10, A_PG: 90, B_T: 6, B_PG: 0, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-01', A_T: 110, A_PG: 77.27, B_T: 62, B_PG: 29.03, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-02', A_T: 13, A_PG: 100, B_T: 24, B_PG: 100, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-03', A_T: 14, A_PG: 100, B_T: 21, B_PG: 100, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-04', A_T: 12, A_PG: 100, B_T: 21, B_PG: 100, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-05', A_T: 13, A_PG: 69.23, B_T: 21, B_PG: 80.95, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-06', A_T: 16, A_PG: 100, B_T: 23, B_PG: 86.96, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-07', A_T: 81, A_PG: 96.3, B_T: 40, B_PG: 15, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-08', A_T: 34, A_PG: 64.71, B_T: 31, B_PG: 45.16, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-09', A_T: 30, A_PG: 83.33, B_T: 28, B_PG: 53.57, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-10', A_T: 24, A_PG: 100, B_T: 37, B_PG: 70.27, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-11', A_T: 20, A_PG: 100, B_T: 35, B_PG: 14.29, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-12', A_T: 22, A_PG: 81.82, B_T: 18, B_PG: 72.22, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-13', A_T: 22, A_PG: 100, B_T: 31, B_PG: 74.19, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-14', A_T: 22, A_PG: 100, B_T: 65, B_PG: 95.38, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-15', A_T: 16, A_PG: 100, B_T: 6, B_PG: 0, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-16', A_T: 13, A_PG: 100, B_T: 6, B_PG: 0, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-17', A_T: 12, A_PG: 100, B_T: 9, B_PG: 0, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-61', A_T: 14, A_PG: 100, B_T: 7, B_PG: 0, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-60', A_T: 39, A_PG: 41.03, B_T: 4, B_PG: 0, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-59', A_T: 162, A_PG: 95.68, B_T: 12, B_PG: 0, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-49', A_T: 10, A_PG: 40, B_T: 6, B_PG: 0, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-58', A_T: 215, A_PG: 47.44, B_T: 14, B_PG: 0, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-53', A_T: 2, A_PG: 0, B_T: 2, B_PG: 0, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-52', A_T: 10, A_PG: 50, B_T: 7, B_PG: 0, },
      { SYSTEM: 6820, SUBSYSTEM: '6820-51', A_T: 11, A_PG: 100, B_T: 7, B_PG: 0, },
      { SYSTEM: 8002, SUBSYSTEM: '8002-03', A_T: 190, A_PG: 2.11, B_T: 53, B_PG: 0, },
      { SYSTEM: 8002, SUBSYSTEM: '8002-02', A_T: 189, A_PG: 22.22, B_T: 53, B_PG: 0, },
      { SYSTEM: 8002, SUBSYSTEM: '8002-01', A_T: 90, A_PG: 40, B_T: 32, B_PG: 0, },
      { SYSTEM: 8011, SUBSYSTEM: '8011-05', A_T: 560, A_PG: 51.61, B_T: 53, B_PG: 0, },
      { SYSTEM: 8011, SUBSYSTEM: '8011-04', A_T: 91, A_PG: 13.19, B_T: 25, B_PG: 0, },
      { SYSTEM: 8011, SUBSYSTEM: '8011-03', A_T: 107, A_PG: 28.04, B_T: 32, B_PG: 0, },
      { SYSTEM: 8011, SUBSYSTEM: '8011-02', A_T: 159, A_PG: 77.36, B_T: 1, B_PG: 0, },
      { SYSTEM: 8011, SUBSYSTEM: '8011-01', A_T: 117, A_PG: 65.81, B_T: 1, B_PG: 0, },
      { SYSTEM: 8012, SUBSYSTEM: '8012-04', A_T: 38, A_PG: 94.74, B_T: 1, B_PG: 0, },
      { SYSTEM: 8012, SUBSYSTEM: '8012-03', A_T: 38, A_PG: 94.74, B_T: 1, B_PG: 0, },
      { SYSTEM: 8012, SUBSYSTEM: '8012-02', A_T: 86, A_PG: 31.4, B_T: 1, B_PG: 0, },
      { SYSTEM: 8012, SUBSYSTEM: '8012-01', A_T: 146, A_PG: 32.88, B_T: 1, B_PG: 0, },
      { SYSTEM: 8014, SUBSYSTEM: '8014-01', A_T: 249, A_PG: 93.57, B_T: 23, B_PG: 0, },
      { SYSTEM: 8016, SUBSYSTEM: '8016-03', A_T: 284, A_PG: 57.04, B_T: 25, B_PG: 0, },
      { SYSTEM: 8016, SUBSYSTEM: '8016-02', A_T: 220, A_PG: 62.27, B_T: 10, B_PG: 0, },
      { SYSTEM: 8016, SUBSYSTEM: '8016-01', A_T: 303, A_PG: 56.44, B_T: 40, B_PG: 0, },
      { SYSTEM: 8021, SUBSYSTEM: '8021-01', A_T: 198, A_PG: 30.81, B_T: 22, B_PG: 0, },
      { SYSTEM: 8021, SUBSYSTEM: '8021-02', A_T: 161, A_PG: 24.84, B_T: 22, B_PG: 0, },
      { SYSTEM: 8021, SUBSYSTEM: '8021-03', A_T: 174, A_PG: 32.18, B_T: 22, B_PG: 0, },
      { SYSTEM: 8021, SUBSYSTEM: '8021-04', A_T: 289, A_PG: 39.45, B_T: 30, B_PG: 0, },
      { SYSTEM: 8021, SUBSYSTEM: '8021-05', A_T: 597, A_PG: 42.88, B_T: 42, B_PG: 0, },
      { SYSTEM: 8021, SUBSYSTEM: '8021-06', A_T: 322, A_PG: 64.91, B_T: 2, B_PG: 0, },
      { SYSTEM: 8023, SUBSYSTEM: '8023-03', A_T: 14, A_PG: 42.86, B_T: 1, B_PG: 0, },
      { SYSTEM: 8023, SUBSYSTEM: '8023-04', A_T: 219, A_PG: 41.1, B_T: 24, B_PG: 0, },
      { SYSTEM: 8023, SUBSYSTEM: '8023-02', A_T: 127, A_PG: 22.05, B_T: 22, B_PG: 0, },
      { SYSTEM: 8023, SUBSYSTEM: '8023-01', A_T: 125, A_PG: 20, B_T: 22, B_PG: 0, },
      { SYSTEM: 8030, SUBSYSTEM: '8030-01', A_T: 63, A_PG: 69.84, B_T: 34, B_PG: 23.53, },
      { SYSTEM: 8040, SUBSYSTEM: '8040-01', A_T: 176, A_PG: 72.16, B_T: 11, B_PG: 0, },
      { SYSTEM: 8040, SUBSYSTEM: '8040-02', A_T: 455, A_PG: 76.26, B_T: 85, B_PG: 0, },
      { SYSTEM: 8040, SUBSYSTEM: '8040-03', A_T: 112, A_PG: 41.96, B_T: 9, B_PG: 0, },
      { SYSTEM: 8040, SUBSYSTEM: '8040-04', A_T: 106, A_PG: 44.34, B_T: 9, B_PG: 0, },
      { SYSTEM: 8040, SUBSYSTEM: '8040-05', A_T: 108, A_PG: 44.44, B_T: 9, B_PG: 0, },
      { SYSTEM: 8060, SUBSYSTEM: '8060-05', A_T: 146, A_PG: 21.23, B_T: 1, B_PG: 0, },
      { SYSTEM: 8060, SUBSYSTEM: '8060-04', A_T: 100, A_PG: 7, B_T: 7, B_PG: 0, },
      { SYSTEM: 8060, SUBSYSTEM: '8060-03', A_T: 117, A_PG: 35.04, B_T: 22, B_PG: 0, },
      { SYSTEM: 8060, SUBSYSTEM: '8060-02', A_T: 403, A_PG: 55.09, B_T: 14, B_PG: 0, },
      { SYSTEM: 8060, SUBSYSTEM: '8060-01', A_T: 199, A_PG: 72.86, B_T: 5, B_PG: 0, },
      { SYSTEM: 8063, SUBSYSTEM: '8063-01', A_T: 410, A_PG: 91.22, B_T: 37, B_PG: 0, },
      { SYSTEM: 8070, SUBSYSTEM: '8070-01', A_T: 233, A_PG: 80.69, B_T: 37, B_PG: 0, },
      { SYSTEM: 8080, SUBSYSTEM: '8080-02', A_T: 25, A_PG: 12, B_T: 1, B_PG: 0, },
      { SYSTEM: 8080, SUBSYSTEM: '8080-01', A_T: 676, A_PG: 39.64, B_T: 138, B_PG: 0, },
      { SYSTEM: 8080, SUBSYSTEM: '8080-03', A_T: 25, A_PG: 8, B_T: 2, B_PG: 0, },
      { SYSTEM: 8080, SUBSYSTEM: '8080-04', A_T: 31, A_PG: 19.35, B_T: 2, B_PG: 0, },
      { SYSTEM: 8080, SUBSYSTEM: '8080-13', A_T: 410, A_PG: 48.05, B_T: 12, B_PG: 0, },
      { SYSTEM: 8080, SUBSYSTEM: '8080-12', A_T: 81, A_PG: 38.27, B_T: 4, B_PG: 0, },
      { SYSTEM: 8080, SUBSYSTEM: '8080-11', A_T: 81, A_PG: 32.1, B_T: 5, B_PG: 0, },
      { SYSTEM: 8080, SUBSYSTEM: '8080-10', A_T: 81, A_PG: 33.33, B_T: 5, B_PG: 0, },
      { SYSTEM: 8080, SUBSYSTEM: '8080-09', A_T: 175, A_PG: 21.71, B_T: 7, B_PG: 0, },
      { SYSTEM: 8080, SUBSYSTEM: '8080-08', A_T: 368, A_PG: 32.61, B_T: 16, B_PG: 0, },
      { SYSTEM: 8080, SUBSYSTEM: '8080-07', A_T: 80, A_PG: 11.25, B_T: 6, B_PG: 0, },
      { SYSTEM: 8080, SUBSYSTEM: '8080-06', A_T: 79, A_PG: 12.66, B_T: 6, B_PG: 0, },
      { SYSTEM: 8080, SUBSYSTEM: '8080-05', A_T: 31, A_PG: 12.9, B_T: 1, B_PG: 0, },
      { SYSTEM: 8110, SUBSYSTEM: '8110-01', A_T: 191, A_PG: 75.39, B_T: 6, B_PG: 0, },
      { SYSTEM: 8110, SUBSYSTEM: '8110-02', A_T: 136, A_PG: 27.94, B_T: 1, B_PG: 0, },
      { SYSTEM: 8120, SUBSYSTEM: '8120-08', A_T: 49, A_PG: 26.53, B_T: 2, B_PG: 0, },
      { SYSTEM: 8120, SUBSYSTEM: '8120-07', A_T: 21, A_PG: 90.48, B_T: 20, B_PG: 0, },
      { SYSTEM: 8120, SUBSYSTEM: '8120-05', A_T: 485, A_PG: 31.55, B_T: 5, B_PG: 0, },
      { SYSTEM: 8120, SUBSYSTEM: '8120-04', A_T: 414, A_PG: 77.54, B_T: 49, B_PG: 0, },
      { SYSTEM: 8120, SUBSYSTEM: '8120-03', A_T: 135, A_PG: 91.85, B_T: 36, B_PG: 0, },
      { SYSTEM: 8120, SUBSYSTEM: '8120-02', A_T: 148, A_PG: 91.89, B_T: 37, B_PG: 0, },
      { SYSTEM: 8120, SUBSYSTEM: '8120-01', A_T: 149, A_PG: 92.62, B_T: 37, B_PG: 0, },
      { SYSTEM: 8120, SUBSYSTEM: '8120-09', A_T: 77, A_PG: 16.88, B_T: 2, B_PG: 0, },
      { SYSTEM: 8120, SUBSYSTEM: '8120-10', A_T: 62, A_PG: 91.94, B_T: 3, B_PG: 0, },
      { SYSTEM: 8120, SUBSYSTEM: '8120-11', A_T: 48, A_PG: 95.83, B_T: 2, B_PG: 0, },
      { SYSTEM: 8130, SUBSYSTEM: '8130-03', A_T: 371, A_PG: 53.64, B_T: 30, B_PG: 0, },
      { SYSTEM: 8130, SUBSYSTEM: '8130-02', A_T: 280, A_PG: 29.29, B_T: 42, B_PG: 0, },
      { SYSTEM: 8130, SUBSYSTEM: '8130-01', A_T: 360, A_PG: 28.33, B_T: 53, B_PG: 0, },
      { SYSTEM: 8150, SUBSYSTEM: '8150-02', A_T: 303, A_PG: 28.71, B_T: 10, B_PG: 0, },
      { SYSTEM: 8150, SUBSYSTEM: '8150-03', A_T: 51, A_PG: 3.92, B_T: 1, B_PG: 0, },
      { SYSTEM: 8150, SUBSYSTEM: '8150-01', A_T: 427, A_PG: 67.21, B_T: 80, B_PG: 0, },
      { SYSTEM: 8170, SUBSYSTEM: '8170-01', A_T: 86, A_PG: 72.09, B_T: 20, B_PG: 0, },
      { SYSTEM: 8200, SUBSYSTEM: '8200-04', A_T: 14, A_PG: 100, B_T: 1, B_PG: 0, },
      { SYSTEM: 8200, SUBSYSTEM: '8200-03', A_T: 353, A_PG: 88.95, B_T: 71, B_PG: 0, },
      { SYSTEM: 8200, SUBSYSTEM: '8200-02', A_T: 261, A_PG: 88.89, B_T: 46, B_PG: 0, },
      { SYSTEM: 8200, SUBSYSTEM: '8200-01', A_T: 342, A_PG: 88.3, B_T: 71, B_PG: 0, },
      { SYSTEM: 8300, SUBSYSTEM: '8300-01', A_T: 580, A_PG: 30.34, B_T: 47, B_PG: 0, },
      { SYSTEM: 8300, SUBSYSTEM: '8300-02', A_T: 131, A_PG: 76.34, B_T: 26, B_PG: 0, },
      { SYSTEM: 8300, SUBSYSTEM: '8300-03', A_T: 557, A_PG: 53.68, B_T: 94, B_PG: 0, },
      { SYSTEM: 8300, SUBSYSTEM: '8300-04', A_T: 451, A_PG: 47.67, B_T: 65, B_PG: 0, },
      { SYSTEM: 8300, SUBSYSTEM: '8300-05', A_T: 367, A_PG: 53.68, B_T: 52, B_PG: 0, },
      { SYSTEM: 8300, SUBSYSTEM: '8300-06', A_T: 276, A_PG: 59.78, B_T: 59, B_PG: 0, },
      { SYSTEM: 8300, SUBSYSTEM: '8300-07', A_T: 81, A_PG: 62.96, B_T: 17, B_PG: 0, },
      { SYSTEM: 8300, SUBSYSTEM: '8300-08', A_T: 711, A_PG: 18.71, B_T: 19, B_PG: 0, },
      { SYSTEM: 8300, SUBSYSTEM: '8300-09', A_T: 2, A_PG: 50, B_T: null, B_PG: null, },
      { SYSTEM: 8400, SUBSYSTEM: '8400-09', A_T: 36, A_PG: 97.22, B_T: null, B_PG: null, },
      { SYSTEM: 8400, SUBSYSTEM: '8400-08', A_T: 293, A_PG: 46.08, B_T: 48, B_PG: 0, },
      { SYSTEM: 8400, SUBSYSTEM: '8400-07', A_T: 96, A_PG: 57.29, B_T: 16, B_PG: 0, },
      { SYSTEM: 8400, SUBSYSTEM: '8400-06', A_T: 96, A_PG: 61.46, B_T: 16, B_PG: 0, },
      { SYSTEM: 8400, SUBSYSTEM: '8400-05', A_T: 105, A_PG: 68.57, B_T: 24, B_PG: 0, },
      { SYSTEM: 8400, SUBSYSTEM: '8400-04', A_T: 107, A_PG: 68.22, B_T: 24, B_PG: 0, },
      { SYSTEM: 8400, SUBSYSTEM: '8400-03', A_T: 194, A_PG: 58.25, B_T: 28, B_PG: 0, },
      { SYSTEM: 8400, SUBSYSTEM: '8400-02', A_T: 256, A_PG: 61.72, B_T: 31, B_PG: 0, },
      { SYSTEM: 8400, SUBSYSTEM: '8400-01', A_T: 107, A_PG: 70.09, B_T: 24, B_PG: 0, },
      { SYSTEM: 8430, SUBSYSTEM: '8430-01', A_T: 441, A_PG: 44.9, B_T: 66, B_PG: 0, },
      { SYSTEM: 8500, SUBSYSTEM: '8500-02', A_T: 477, A_PG: 78.2, B_T: 81, B_PG: 0, },
      { SYSTEM: 8500, SUBSYSTEM: '8500-01', A_T: 3026, A_PG: 63.02, B_T: 331, B_PG: 0, },
      { SYSTEM: 8610, SUBSYSTEM: '8610-16', A_T: 34, A_PG: 100, B_T: 5, B_PG: 40, },
      { SYSTEM: 8610, SUBSYSTEM: '8610-15', A_T: 58, A_PG: 100, B_T: 5, B_PG: 60, },
      { SYSTEM: 8610, SUBSYSTEM: '8610-14', A_T: 105, A_PG: 100, B_T: 6, B_PG: 0, },
      { SYSTEM: 8610, SUBSYSTEM: '8610-13', A_T: 42, A_PG: 100, B_T: 5, B_PG: 40, },
      { SYSTEM: 8610, SUBSYSTEM: '8610-12', A_T: 48, A_PG: 100, B_T: 5, B_PG: 60, },
      { SYSTEM: 8610, SUBSYSTEM: '8610-11', A_T: 38, A_PG: 100, B_T: 5, B_PG: 60, },
      { SYSTEM: 8610, SUBSYSTEM: '8610-10', A_T: 42, A_PG: 100, B_T: 5, B_PG: 80, },
      { SYSTEM: 8610, SUBSYSTEM: '8610-09', A_T: 24, A_PG: 100, B_T: 6, B_PG: 0, },
      { SYSTEM: 8610, SUBSYSTEM: '8610-08', A_T: 9, A_PG: 100, B_T: 4, B_PG: 0, },
      { SYSTEM: 8610, SUBSYSTEM: '8610-07', A_T: 8, A_PG: 87.5, B_T: 4, B_PG: 0, },
      { SYSTEM: 8610, SUBSYSTEM: '8610-06', A_T: 13, A_PG: 100, B_T: 4, B_PG: 0, },
      { SYSTEM: 8610, SUBSYSTEM: '8610-05', A_T: 23, A_PG: 82.61, B_T: 4, B_PG: 0, },
      { SYSTEM: 8610, SUBSYSTEM: '8610-04', A_T: 19, A_PG: 89.47, B_T: 5, B_PG: 0, },
      { SYSTEM: 8610, SUBSYSTEM: '8610-03', A_T: 13, A_PG: 100, B_T: 4, B_PG: 0, },
      { SYSTEM: 8610, SUBSYSTEM: '8610-02', A_T: 22, A_PG: 100, B_T: 4, B_PG: 25, },
      { SYSTEM: 8610, SUBSYSTEM: '8610-01', A_T: 34, A_PG: 88.24, B_T: 8, B_PG: 50, },
      { SYSTEM: 8661, SUBSYSTEM: '8661-01', A_T: 871, A_PG: 77.84, B_T: 342, B_PG: 41.81, },
      { SYSTEM: 8662, SUBSYSTEM: '8662-01', A_T: 884, A_PG: 78.28, B_T: 339, B_PG: 37.46, },
      { SYSTEM: 8663, SUBSYSTEM: '8663-01', A_T: 859, A_PG: 77.76, B_T: 339, B_PG: 36.28, },
      { SYSTEM: 8720, SUBSYSTEM: '8720-02', A_T: 465, A_PG: 90.75, B_T: 26, B_PG: 0, },
      { SYSTEM: 8720, SUBSYSTEM: '8720-03', A_T: 486, A_PG: 96.71, B_T: 24, B_PG: 0, },
      { SYSTEM: 8720, SUBSYSTEM: '8720-04', A_T: 487, A_PG: 98.36, B_T: 24, B_PG: 0, },
      { SYSTEM: 8720, SUBSYSTEM: '8720-05', A_T: 479, A_PG: 94.78, B_T: 28, B_PG: 0, },
      { SYSTEM: 8720, SUBSYSTEM: '8720-17', A_T: 2, A_PG: 50, B_T: 2, B_PG: 0, },
      { SYSTEM: 8720, SUBSYSTEM: '8720-16', A_T: 3, A_PG: 0, B_T: 3, B_PG: 0, },
      { SYSTEM: 8720, SUBSYSTEM: '8720-15', A_T: 2, A_PG: 0, B_T: 2, B_PG: 0, },
      { SYSTEM: 8720, SUBSYSTEM: '8720-14', A_T: 96, A_PG: 44.79, B_T: 11, B_PG: 0, },
      { SYSTEM: 8720, SUBSYSTEM: '8720-13', A_T: 88, A_PG: 47.73, B_T: 7, B_PG: 0, },
      { SYSTEM: 8720, SUBSYSTEM: '8720-12', A_T: 122, A_PG: 46.72, B_T: 13, B_PG: 0, },
      { SYSTEM: 8720, SUBSYSTEM: '8720-06', A_T: 425, A_PG: 57.88, B_T: 22, B_PG: 0, },
      { SYSTEM: 8720, SUBSYSTEM: '8720-07', A_T: 504, A_PG: 45.24, B_T: 30, B_PG: 0, },
      { SYSTEM: 8720, SUBSYSTEM: '8720-08', A_T: 333, A_PG: 41.44, B_T: 20, B_PG: 0, },
      { SYSTEM: 8720, SUBSYSTEM: '8720-09', A_T: 11, A_PG: 54.55, B_T: 6, B_PG: 0, },
      { SYSTEM: 8720, SUBSYSTEM: '8720-10', A_T: 461, A_PG: 59.44, B_T: 23, B_PG: 0, },
      { SYSTEM: 8720, SUBSYSTEM: '8720-11', A_T: 113, A_PG: 40.71, B_T: 16, B_PG: 0, },
      { SYSTEM: 8740, SUBSYSTEM: '8740-23', A_T: 29, A_PG: 82.76, B_T: 31, B_PG: 70.97, },
      { SYSTEM: 8740, SUBSYSTEM: '8740-24', A_T: 19, A_PG: 89.47, B_T: 30, B_PG: 86.67, },
      { SYSTEM: 8740, SUBSYSTEM: '8740-25', A_T: 12, A_PG: 100, B_T: 22, B_PG: 100, },
      { SYSTEM: 8740, SUBSYSTEM: '8740-26', A_T: 3, A_PG: 33.33, B_T: 2, B_PG: 0, },
      { SYSTEM: 8740, SUBSYSTEM: '8740-22', A_T: 25, A_PG: 88, B_T: 39, B_PG: 84.62, },
      { SYSTEM: 8740, SUBSYSTEM: '8740-21', A_T: 2, A_PG: 50, B_T: 2, B_PG: 0, },
      { SYSTEM: 8740, SUBSYSTEM: '8740-20', A_T: 2, A_PG: 0, B_T: 2, B_PG: 0, },
      { SYSTEM: 8740, SUBSYSTEM: '8740-19', A_T: 2, A_PG: 50, B_T: 2, B_PG: 0, },
      { SYSTEM: 8740, SUBSYSTEM: '8740-18', A_T: 2, A_PG: 50, B_T: 2, B_PG: 0, },
      { SYSTEM: 8740, SUBSYSTEM: '8740-17', A_T: 2, A_PG: 0, B_T: 2, B_PG: 0, },
      { SYSTEM: 8740, SUBSYSTEM: '8740-16', A_T: 2, A_PG: 50, B_T: 2, B_PG: 0, },
      { SYSTEM: 8740, SUBSYSTEM: '8740-15', A_T: 7, A_PG: 14.29, B_T: 3, B_PG: 0, },
      { SYSTEM: 8740, SUBSYSTEM: '8740-14', A_T: 6, A_PG: 33.33, B_T: 3, B_PG: 0, },
      { SYSTEM: 8740, SUBSYSTEM: '8740-13', A_T: 7, A_PG: 42.86, B_T: 3, B_PG: 0, },
      { SYSTEM: 8740, SUBSYSTEM: '8740-12', A_T: 6, A_PG: 33.33, B_T: 3, B_PG: 0, },
      { SYSTEM: 8740, SUBSYSTEM: '8740-11', A_T: 3, A_PG: 66.67, B_T: 2, B_PG: 0, },
      { SYSTEM: 8740, SUBSYSTEM: '8740-10', A_T: 21, A_PG: 80.95, B_T: 18, B_PG: 66.67, },
      { SYSTEM: 8740, SUBSYSTEM: '8740-09', A_T: 17, A_PG: 100, B_T: 14, B_PG: 0, },
      { SYSTEM: 8740, SUBSYSTEM: '8740-08', A_T: 12, A_PG: 100, B_T: 21, B_PG: 0, },
      { SYSTEM: 8740, SUBSYSTEM: '8740-07', A_T: 17, A_PG: 100, B_T: 26, B_PG: 0, },
      { SYSTEM: 8740, SUBSYSTEM: '8740-06', A_T: 12, A_PG: 100, B_T: 14, B_PG: 0, },
      { SYSTEM: 8740, SUBSYSTEM: '8740-05', A_T: 15, A_PG: 100, B_T: 14, B_PG: 0, },
      { SYSTEM: 8740, SUBSYSTEM: '8740-04', A_T: 18, A_PG: 100, B_T: 43, B_PG: 0, },
      { SYSTEM: 8740, SUBSYSTEM: '8740-03', A_T: 62, A_PG: 90.32, B_T: 39, B_PG: 0, },
      { SYSTEM: 8740, SUBSYSTEM: '8740-02', A_T: 47, A_PG: 65.96, B_T: 47, B_PG: 29.79, },
      { SYSTEM: 8740, SUBSYSTEM: '8740-01', A_T: 62, A_PG: 74.19, B_T: 46, B_PG: 32.61, },
      { SYSTEM: 8760, SUBSYSTEM: '8760-07', A_T: 20, A_PG: 65, B_T: 14, B_PG: 0, },
      { SYSTEM: 8760, SUBSYSTEM: '8760-06', A_T: 23, A_PG: 60.87, B_T: 14, B_PG: 0, },
      { SYSTEM: 8760, SUBSYSTEM: '8760-05', A_T: 22, A_PG: 40.91, B_T: 15, B_PG: 0, },
      { SYSTEM: 8760, SUBSYSTEM: '8760-04', A_T: 23, A_PG: 30.43, B_T: 12, B_PG: 0, },
      { SYSTEM: 8760, SUBSYSTEM: '8760-02', A_T: 75, A_PG: 53.33, B_T: 56, B_PG: 0, },
      { SYSTEM: 8760, SUBSYSTEM: '8760-01', A_T: 65, A_PG: 30.77, B_T: 59, B_PG: 0, },
      { SYSTEM: 8790, SUBSYSTEM: '8790-01', A_T: 34, A_PG: 100, B_T: 47, B_PG: 0, },
      { SYSTEM: 8950, SUBSYSTEM: '8950-04', A_T: 190, A_PG: 27.37, B_T: 49, B_PG: 0, },
      { SYSTEM: 8950, SUBSYSTEM: '8950-03', A_T: 304, A_PG: 7.89, B_T: 90, B_PG: 0, },
      { SYSTEM: 8950, SUBSYSTEM: '8950-02', A_T: 386, A_PG: 2.85, B_T: 107, B_PG: 0, },
      { SYSTEM: 8950, SUBSYSTEM: '8950-01', A_T: 497, A_PG: 2.21, B_T: 126, B_PG: 0, },
      { SYSTEM: 9131, SUBSYSTEM: '9131-01', A_T: 183, A_PG: 0, B_T: 11, B_PG: 0, },
      { SYSTEM: 9132, SUBSYSTEM: '9132-01', A_T: 331, A_PG: 56.5, B_T: 11, B_PG: 0, },
      { SYSTEM: 9133, SUBSYSTEM: '9133-01', A_T: 102, A_PG: 26.47, B_T: 7, B_PG: 0, },
      { SYSTEM: 9134, SUBSYSTEM: '9134-01', A_T: 133, A_PG: 0.75, B_T: 8, B_PG: 0, },
      { SYSTEM: 9150, SUBSYSTEM: '9150-01', A_T: 3, A_PG: 33.33, B_T: null, B_PG: null, },
      { SYSTEM: 9220, SUBSYSTEM: '9220-07', A_T: 64, A_PG: 0, B_T: 1, B_PG: 0, },
      { SYSTEM: 9220, SUBSYSTEM: '9220-06', A_T: 188, A_PG: 0, B_T: 1, B_PG: 0, },
      { SYSTEM: 9220, SUBSYSTEM: '9220-05', A_T: 441, A_PG: 0, B_T: 63, B_PG: 0, },
      { SYSTEM: 9220, SUBSYSTEM: '9220-04', A_T: 125, A_PG: 0, B_T: 1, B_PG: 0, },
      { SYSTEM: 9220, SUBSYSTEM: '9220-03', A_T: 148, A_PG: 0, B_T: 1, B_PG: 0, },
      { SYSTEM: 9220, SUBSYSTEM: '9220-02', A_T: 545, A_PG: 32.84, B_T: 28, B_PG: 0, },
      { SYSTEM: 9220, SUBSYSTEM: '9220-01', A_T: 110, A_PG: 1.82, B_T: 2, B_PG: 0, },
      { SYSTEM: 9240, SUBSYSTEM: '9240-01', A_T: 43, A_PG: 0, B_T: 2, B_PG: 0, },
      { SYSTEM: 9240, SUBSYSTEM: '9240-02', A_T: 9, A_PG: 22.22, B_T: null, B_PG: null, },
      { SYSTEM: 9240, SUBSYSTEM: '9240-03', A_T: 36, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 9250, SUBSYSTEM: '9250-01', A_T: 302, A_PG: 42.05, B_T: 34, B_PG: 0, },
      { SYSTEM: 9250, SUBSYSTEM: '9250-02', A_T: 2678, A_PG: 52.35, B_T: 370, B_PG: 0, },
      { SYSTEM: 9250, SUBSYSTEM: '9250-03', A_T: 268, A_PG: 58.96, B_T: 23, B_PG: 0, },
      { SYSTEM: 9250, SUBSYSTEM: '9250-04', A_T: 114, A_PG: 46.49, B_T: 20, B_PG: 0, },
      { SYSTEM: 9250, SUBSYSTEM: '9250-06', A_T: 557, A_PG: 68.22, B_T: 112, B_PG: 0, },
      { SYSTEM: 9254, SUBSYSTEM: '9254-02', A_T: 336, A_PG: 62.2, B_T: 52, B_PG: 0, },
      { SYSTEM: 9254, SUBSYSTEM: '9254-01', A_T: 590, A_PG: 47.63, B_T: 93, B_PG: 0, },
      { SYSTEM: 9255, SUBSYSTEM: '9255-02', A_T: 467, A_PG: 50.54, B_T: 68, B_PG: 0, },
      { SYSTEM: 9255, SUBSYSTEM: '9255-01', A_T: 340, A_PG: 54.12, B_T: 58, B_PG: 0, },
      { SYSTEM: 9260, SUBSYSTEM: '9260-01', A_T: 950, A_PG: 53.47, B_T: 152, B_PG: 0, },
      { SYSTEM: 9260, SUBSYSTEM: '9260-02', A_T: 716, A_PG: 43.16, B_T: 92, B_PG: 0, },
      { SYSTEM: 9260, SUBSYSTEM: '9260-03', A_T: 1626, A_PG: 52.95, B_T: 206, B_PG: 0, },
      { SYSTEM: 9260, SUBSYSTEM: '9260-04', A_T: 305, A_PG: 70.49, B_T: 21, B_PG: 0, },
      { SYSTEM: 9270, SUBSYSTEM: '9270-10', A_T: 259, A_PG: 42.08, B_T: 39, B_PG: 0, },
      { SYSTEM: 9270, SUBSYSTEM: '9270-09', A_T: 271, A_PG: 46.13, B_T: 39, B_PG: 0, },
      { SYSTEM: 9270, SUBSYSTEM: '9270-08', A_T: 529, A_PG: 62.57, B_T: 102, B_PG: 0, },
      { SYSTEM: 9270, SUBSYSTEM: '9270-07', A_T: 257, A_PG: 49.03, B_T: 38, B_PG: 0, },
      { SYSTEM: 9270, SUBSYSTEM: '9270-06', A_T: 549, A_PG: 47.91, B_T: 64, B_PG: 0, },
      { SYSTEM: 9270, SUBSYSTEM: '9270-05', A_T: 447, A_PG: 43.62, B_T: 64, B_PG: 0, },
      { SYSTEM: 9270, SUBSYSTEM: '9270-04', A_T: 1208, A_PG: 63.49, B_T: 233, B_PG: 0, },
      { SYSTEM: 9270, SUBSYSTEM: '9270-03', A_T: 385, A_PG: 44.94, B_T: 56, B_PG: 0, },
      { SYSTEM: 9270, SUBSYSTEM: '9270-02', A_T: 846, A_PG: 57.68, B_T: 143, B_PG: 0, },
      { SYSTEM: 9270, SUBSYSTEM: '9270-01', A_T: 1124, A_PG: 67.08, B_T: 224, B_PG: 0, },
      { SYSTEM: 9270, SUBSYSTEM: '9270-13', A_T: 243, A_PG: 44.86, B_T: 38, B_PG: 0, },
      { SYSTEM: 9270, SUBSYSTEM: '9270-11', A_T: 290, A_PG: 50, B_T: 52, B_PG: 0, },
      { SYSTEM: 9280, SUBSYSTEM: '9280-01', A_T: 848, A_PG: 61.67, B_T: 130, B_PG: 0, },
      { SYSTEM: 9280, SUBSYSTEM: '9280-02', A_T: 155, A_PG: 38.71, B_T: 22, B_PG: 0, },
      { SYSTEM: 9280, SUBSYSTEM: '9280-03', A_T: 341, A_PG: 49.27, B_T: 50, B_PG: 0, },
      { SYSTEM: 9280, SUBSYSTEM: '9280-04', A_T: 340, A_PG: 42.65, B_T: 47, B_PG: 0, },
      { SYSTEM: 9280, SUBSYSTEM: '9280-05', A_T: 901, A_PG: 57.27, B_T: 152, B_PG: 0, },
      { SYSTEM: 9280, SUBSYSTEM: '9280-06', A_T: 109, A_PG: 54.13, B_T: 17, B_PG: 0, },
      { SYSTEM: 9280, SUBSYSTEM: '9280-07', A_T: 435, A_PG: 53.79, B_T: 60, B_PG: 0, },
      { SYSTEM: 9291, SUBSYSTEM: '9291-01', A_T: 38, A_PG: 0, B_T: 5, B_PG: 0, },
      { SYSTEM: 9300, SUBSYSTEM: '9300-03', A_T: 79, A_PG: 1.27, B_T: 17, B_PG: 0, },
      { SYSTEM: 9300, SUBSYSTEM: '9300-02', A_T: 154, A_PG: 0, B_T: 15, B_PG: 0, },
      { SYSTEM: 9300, SUBSYSTEM: '9300-01', A_T: 587, A_PG: 1.87, B_T: 30, B_PG: 0, },
      { SYSTEM: 9310, SUBSYSTEM: '9310-07', A_T: 19, A_PG: 15.79, B_T: 4, B_PG: 0, },
      { SYSTEM: 9310, SUBSYSTEM: '9310-08', A_T: 19, A_PG: 15.79, B_T: 3, B_PG: 0, },
      { SYSTEM: 9310, SUBSYSTEM: '9310-06', A_T: 23, A_PG: 52.17, B_T: 4, B_PG: 0, },
      { SYSTEM: 9310, SUBSYSTEM: '9310-05', A_T: 34, A_PG: 41.18, B_T: 9, B_PG: 0, },
      { SYSTEM: 9310, SUBSYSTEM: '9310-04', A_T: 26, A_PG: 26.92, B_T: 1, B_PG: 0, },
      { SYSTEM: 9310, SUBSYSTEM: '9310-03', A_T: 23, A_PG: 30.43, B_T: 1, B_PG: 0, },
      { SYSTEM: 9310, SUBSYSTEM: '9310-02', A_T: 26, A_PG: 30.77, B_T: 1, B_PG: 0, },
      { SYSTEM: 9310, SUBSYSTEM: '9310-01', A_T: 25, A_PG: 32, B_T: 1, B_PG: 0, },
      { SYSTEM: 9320, SUBSYSTEM: '9320-01', A_T: 462, A_PG: 98.7, B_T: 3, B_PG: 0, },
      { SYSTEM: 9340, SUBSYSTEM: '9340-05', A_T: 6, A_PG: 100, B_T: 3, B_PG: 0, },
      { SYSTEM: 9340, SUBSYSTEM: '9340-04', A_T: 562, A_PG: 91.81, B_T: 100, B_PG: 0, },
      { SYSTEM: 9340, SUBSYSTEM: '9340-03', A_T: 560, A_PG: 87.5, B_T: 100, B_PG: 0, },
      { SYSTEM: 9340, SUBSYSTEM: '9340-02', A_T: 561, A_PG: 87.34, B_T: 100, B_PG: 0, },
      { SYSTEM: 9340, SUBSYSTEM: '9340-01', A_T: 563, A_PG: 88.63, B_T: 100, B_PG: 0, },
      { SYSTEM: 9350, SUBSYSTEM: '9350-04', A_T: 839, A_PG: 99.28, B_T: 60, B_PG: 55, },
      { SYSTEM: 9350, SUBSYSTEM: '9350-03', A_T: 1054, A_PG: 99.43, B_T: 66, B_PG: 53.03, },
      { SYSTEM: 9350, SUBSYSTEM: '9350-02', A_T: 992, A_PG: 99.29, B_T: 66, B_PG: 51.52, },
      { SYSTEM: 9350, SUBSYSTEM: '9350-01', A_T: 1106, A_PG: 99.37, B_T: 71, B_PG: 54.93, },
      { SYSTEM: 9355, SUBSYSTEM: '9355-04', A_T: 82, A_PG: 96.34, B_T: 6, B_PG: 66.67, },
      { SYSTEM: 9355, SUBSYSTEM: '9355-03', A_T: 150, A_PG: 67.33, B_T: 23, B_PG: 0, },
      { SYSTEM: 9355, SUBSYSTEM: '9355-02', A_T: 143, A_PG: 49.65, B_T: 23, B_PG: 0, },
      { SYSTEM: 9355, SUBSYSTEM: '9355-01', A_T: 251, A_PG: 64.54, B_T: 14, B_PG: 7.14, },
      { SYSTEM: 9360, SUBSYSTEM: '9360-01', A_T: 1300, A_PG: 77.46, B_T: 320, B_PG: 0, },
      { SYSTEM: 9360, SUBSYSTEM: '9360-02', A_T: 182, A_PG: 57.14, B_T: 26, B_PG: 0, },
      { SYSTEM: 9380, SUBSYSTEM: '9380-01', A_T: 1316, A_PG: 98.94, B_T: 85, B_PG: 51.76, },
      { SYSTEM: 9380, SUBSYSTEM: '9380-02', A_T: 1321, A_PG: 98.94, B_T: 86, B_PG: 51.16, },
      { SYSTEM: 9380, SUBSYSTEM: '9380-03', A_T: 1304, A_PG: 98.08, B_T: 85, B_PG: 45.88, },
      { SYSTEM: 9380, SUBSYSTEM: '9380-04', A_T: 1315, A_PG: 98.94, B_T: 86, B_PG: 51.16, },
      { SYSTEM: 9380, SUBSYSTEM: '9380-05', A_T: 235, A_PG: 91.49, B_T: 10, B_PG: 0, },
      { SYSTEM: 9380, SUBSYSTEM: '9380-06', A_T: 549, A_PG: 80.33, B_T: 35, B_PG: 34.29, },
      { SYSTEM: 9380, SUBSYSTEM: '9380-07', A_T: 530, A_PG: 85.09, B_T: 31, B_PG: 32.26, },
      { SYSTEM: 9380, SUBSYSTEM: '9380-08', A_T: 762, A_PG: 83.2, B_T: 45, B_PG: 0, },
      { SYSTEM: 9380, SUBSYSTEM: '9380-09', A_T: 884, A_PG: 77.71, B_T: 52, B_PG: 0, },
      { SYSTEM: 9380, SUBSYSTEM: '9380-10', A_T: 920, A_PG: 71.96, B_T: 28, B_PG: 0, },
      { SYSTEM: 9390, SUBSYSTEM: '9390-01', A_T: 4, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 9390, SUBSYSTEM: '9390-02', A_T: 96, A_PG: 60.42, B_T: 2, B_PG: 0, },
      { SYSTEM: 9390, SUBSYSTEM: '9390-01', A_T: 4, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 9450, SUBSYSTEM: '9450-01', A_T: 19, A_PG: 5.26, B_T: null, B_PG: null, },
      { SYSTEM: 9450, SUBSYSTEM: '9450-02', A_T: 22, A_PG: 9.09, B_T: 3, B_PG: 0, },
      { SYSTEM: 9450, SUBSYSTEM: '9450-03', A_T: 27, A_PG: 3.7, B_T: null, B_PG: null, },
      { SYSTEM: 9450, SUBSYSTEM: '9450-04', A_T: 10, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 9450, SUBSYSTEM: '9450-05', A_T: 66, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 9450, SUBSYSTEM: '9450-06', A_T: 27, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 9450, SUBSYSTEM: '9450-07', A_T: 73, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 9450, SUBSYSTEM: '9450-21', A_T: 4, A_PG: 0, B_T: 1, B_PG: 0, },
      { SYSTEM: 9450, SUBSYSTEM: '9450-20', A_T: 4, A_PG: 25, B_T: 1, B_PG: 0, },
      { SYSTEM: 9450, SUBSYSTEM: '9450-19', A_T: 7, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 9450, SUBSYSTEM: '9450-18', A_T: 8, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 9450, SUBSYSTEM: '9450-17', A_T: 25, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 9450, SUBSYSTEM: '9450-16', A_T: 31, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 9450, SUBSYSTEM: '9450-15', A_T: 18, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 9450, SUBSYSTEM: '9450-14', A_T: 20, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 9450, SUBSYSTEM: '9450-13', A_T: 30, A_PG: 10, B_T: 3, B_PG: 0, },
      { SYSTEM: 9450, SUBSYSTEM: '9450-12', A_T: 23, A_PG: 0, B_T: 3, B_PG: 0, },
      { SYSTEM: 9450, SUBSYSTEM: '9450-11', A_T: 18, A_PG: 0, B_T: 3, B_PG: 0, },
      { SYSTEM: 9450, SUBSYSTEM: '9450-10', A_T: 10, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 9450, SUBSYSTEM: '9450-09', A_T: 8, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 9451, SUBSYSTEM: '9451-01', A_T: 21, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 9451, SUBSYSTEM: '9451-02', A_T: 22, A_PG: 4.55, B_T: null, B_PG: null, },
      { SYSTEM: 9451, SUBSYSTEM: '9451-03', A_T: 28, A_PG: 3.57, B_T: null, B_PG: null, },
      { SYSTEM: 9451, SUBSYSTEM: '9451-04', A_T: 11, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 9451, SUBSYSTEM: '9451-05', A_T: 104, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 9451, SUBSYSTEM: '9451-06', A_T: 32, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 9451, SUBSYSTEM: '9451-07', A_T: 78, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 9451, SUBSYSTEM: '9451-08', A_T: 51, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 9451, SUBSYSTEM: '9451-09', A_T: 18, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 9451, SUBSYSTEM: '9451-10', A_T: 14, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 9451, SUBSYSTEM: '9451-11', A_T: 36, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 9451, SUBSYSTEM: '9451-12', A_T: 71, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 9451, SUBSYSTEM: '9451-13', A_T: 13, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 9510, SUBSYSTEM: '9510-01', A_T: 12, A_PG: 50, B_T: 7, B_PG: 0, },
      { SYSTEM: 9510, SUBSYSTEM: '9510-02', A_T: 14, A_PG: 0, B_T: 2, B_PG: 0, },
      { SYSTEM: 9510, SUBSYSTEM: '9510-03', A_T: 10, A_PG: 0, B_T: 2, B_PG: 0, },
      { SYSTEM: 9512, SUBSYSTEM: '9512-01', A_T: 10, A_PG: 20, B_T: 3, B_PG: 0, },
      { SYSTEM: 9520, SUBSYSTEM: '9520-13', A_T: 79, A_PG: 65.82, B_T: 36, B_PG: 0, },
      { SYSTEM: 9520, SUBSYSTEM: '9520-12', A_T: 2, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 9520, SUBSYSTEM: '9520-10', A_T: 50, A_PG: 0, B_T: 25, B_PG: 0, },
      { SYSTEM: 9520, SUBSYSTEM: '9520-09', A_T: 177, A_PG: 0, B_T: 89, B_PG: 0, },
      { SYSTEM: 9520, SUBSYSTEM: '9520-07', A_T: 28, A_PG: 0, B_T: 9, B_PG: 0, },
      { SYSTEM: 9520, SUBSYSTEM: '9520-06', A_T: 34, A_PG: 5.88, B_T: 9, B_PG: 0, },
      { SYSTEM: 9520, SUBSYSTEM: '9520-05', A_T: 54, A_PG: 3.7, B_T: 14, B_PG: 0, },
      { SYSTEM: 9520, SUBSYSTEM: '9520-04', A_T: 50, A_PG: 0, B_T: 25, B_PG: 0, },
      { SYSTEM: 9520, SUBSYSTEM: '9520-03', A_T: 88, A_PG: 0, B_T: 44, B_PG: 0, },
      { SYSTEM: 9520, SUBSYSTEM: '9520-02', A_T: 88, A_PG: 13.64, B_T: 42, B_PG: 0, },
      { SYSTEM: 9520, SUBSYSTEM: '9520-01', A_T: 79, A_PG: 55.7, B_T: 19, B_PG: 0, },
      { SYSTEM: 9530, SUBSYSTEM: '9530-01', A_T: 10, A_PG: 0, B_T: 3, B_PG: 0, },
      { SYSTEM: 9530, SUBSYSTEM: '9530-02', A_T: 9, A_PG: 0, B_T: 6, B_PG: 0, },
      { SYSTEM: 9540, SUBSYSTEM: '9540-01', A_T: 163, A_PG: 10.43, B_T: 43, B_PG: 0, },
      { SYSTEM: 9540, SUBSYSTEM: '9540-02', A_T: 41, A_PG: 0, B_T: 10, B_PG: 0, },
      { SYSTEM: 9540, SUBSYSTEM: '9540-03', A_T: 85, A_PG: 14.12, B_T: 12, B_PG: 0, },
      { SYSTEM: 9540, SUBSYSTEM: '9540-04', A_T: 13, A_PG: 0, B_T: 3, B_PG: 0, },
      { SYSTEM: 9540, SUBSYSTEM: '9540-05', A_T: 45, A_PG: 44.44, B_T: 7, B_PG: 0, },
      { SYSTEM: 9550, SUBSYSTEM: '9550-01', A_T: 21, A_PG: 28.57, B_T: 7, B_PG: 0, },
      { SYSTEM: 9550, SUBSYSTEM: '9550-02', A_T: 14, A_PG: 64.29, B_T: 6, B_PG: 0, },
      { SYSTEM: 9550, SUBSYSTEM: '9550-04', A_T: 6, A_PG: 0, B_T: 1, B_PG: 0, },
      { SYSTEM: 9550, SUBSYSTEM: '9550-03', A_T: 14, A_PG: 0, B_T: 3, B_PG: 0, },
      { SYSTEM: 9560, SUBSYSTEM: '9560-12', A_T: 4, A_PG: 100, B_T: null, B_PG: null, },
      { SYSTEM: 9560, SUBSYSTEM: '9560-11', A_T: 4, A_PG: 100, B_T: null, B_PG: null, },
      { SYSTEM: 9560, SUBSYSTEM: '9560-10', A_T: 4, A_PG: 100, B_T: null, B_PG: null, },
      { SYSTEM: 9560, SUBSYSTEM: '9560-09', A_T: 8, A_PG: 100, B_T: null, B_PG: null, },
      { SYSTEM: 9560, SUBSYSTEM: '9560-08', A_T: 12, A_PG: 100, B_T: null, B_PG: null, },
      { SYSTEM: 9560, SUBSYSTEM: '9560-07', A_T: 65, A_PG: 100, B_T: 21, B_PG: 0, },
      { SYSTEM: 9560, SUBSYSTEM: '9560-06', A_T: 60, A_PG: 90, B_T: null, B_PG: null, },
      { SYSTEM: 9560, SUBSYSTEM: '9560-05', A_T: 12, A_PG: 75, B_T: null, B_PG: null, },
      { SYSTEM: 9560, SUBSYSTEM: '9560-04', A_T: 16, A_PG: 100, B_T: 8, B_PG: 0, },
      { SYSTEM: 9560, SUBSYSTEM: '9560-03', A_T: 31, A_PG: 100, B_T: 16, B_PG: 0, },
      { SYSTEM: 9560, SUBSYSTEM: '9560-02', A_T: 29, A_PG: 100, B_T: 8, B_PG: 0, },
      { SYSTEM: 9560, SUBSYSTEM: '9560-01', A_T: 79, A_PG: 100, B_T: 41, B_PG: 0, },
      { SYSTEM: 9560, SUBSYSTEM: '9560-14', A_T: 357, A_PG: 39.78, B_T: 126, B_PG: 0, },
      { SYSTEM: 9560, SUBSYSTEM: '9560-13', A_T: 223, A_PG: 34.98, B_T: 86, B_PG: 0, },
      { SYSTEM: 9560, SUBSYSTEM: '9560-15', A_T: 259, A_PG: 94.59, B_T: 96, B_PG: 0, },
      { SYSTEM: 9560, SUBSYSTEM: '9560-16', A_T: 219, A_PG: 7.31, B_T: 79, B_PG: 0, },
      { SYSTEM: 9560, SUBSYSTEM: '9560-17', A_T: 173, A_PG: 11.56, B_T: 65, B_PG: 0, },
      { SYSTEM: 9560, SUBSYSTEM: '9560-18', A_T: 16, A_PG: 100, B_T: 7, B_PG: 0, },
      { SYSTEM: 9560, SUBSYSTEM: '9560-19', A_T: 16, A_PG: 100, B_T: 7, B_PG: 0, },
      { SYSTEM: 9580, SUBSYSTEM: '9580-06', A_T: 200, A_PG: 0, B_T: 68, B_PG: 0, },
      { SYSTEM: 9580, SUBSYSTEM: '9580-05', A_T: 107, A_PG: 0, B_T: 43, B_PG: 0, },
      { SYSTEM: 9580, SUBSYSTEM: '9580-04', A_T: 27, A_PG: 0, B_T: 12, B_PG: 0, },
      { SYSTEM: 9580, SUBSYSTEM: '9580-03', A_T: 91, A_PG: 0, B_T: 22, B_PG: 0, },
      { SYSTEM: 9580, SUBSYSTEM: '9580-02', A_T: 104, A_PG: 0, B_T: 28, B_PG: 0, },
      { SYSTEM: 9580, SUBSYSTEM: '9580-01', A_T: 66, A_PG: 83.33, B_T: 12, B_PG: 0, },
      { SYSTEM: 9590, SUBSYSTEM: '9590-04', A_T: 27, A_PG: 0, B_T: 13, B_PG: 0, },
      { SYSTEM: 9590, SUBSYSTEM: '9590-03', A_T: 600, A_PG: 40.83, B_T: 476, B_PG: 0, },
      { SYSTEM: 9590, SUBSYSTEM: '9590-02', A_T: 15, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 9590, SUBSYSTEM: '9590-01', A_T: 10, A_PG: 60, B_T: 4, B_PG: 0, },
      { SYSTEM: 9810, SUBSYSTEM: '9810-02', A_T: 54, A_PG: 55.56, B_T: 3, B_PG: 0, },
      { SYSTEM: 9810, SUBSYSTEM: '9810-01', A_T: 40, A_PG: 22.5, B_T: 3, B_PG: 0, },
      { SYSTEM: 9820, SUBSYSTEM: '9820-01', A_T: 1753, A_PG: 2.85, B_T: 19, B_PG: 0, },
      { SYSTEM: 9840, SUBSYSTEM: '9840-01', A_T: 3, A_PG: 0, B_T: 1, B_PG: 0, },
      { SYSTEM: 9851, SUBSYSTEM: '9851-02', A_T: 185, A_PG: 94.05, B_T: 15, B_PG: 0, },
      { SYSTEM: 9851, SUBSYSTEM: '9851-01', A_T: 184, A_PG: 99.46, B_T: 14, B_PG: 0, },
      { SYSTEM: 9852, SUBSYSTEM: '9852-01', A_T: 127, A_PG: 0, B_T: 13, B_PG: 0, },
      { SYSTEM: 9860, SUBSYSTEM: '9860-01', A_T: 204, A_PG: 63.73, B_T: 44, B_PG: 0, },
      { SYSTEM: 9860, SUBSYSTEM: '9860-02', A_T: 264, A_PG: 69.32, B_T: 35, B_PG: 0, },
      { SYSTEM: 9860, SUBSYSTEM: '9860-03', A_T: 366, A_PG: 70.77, B_T: 3, B_PG: 0, },
      { SYSTEM: 9882, SUBSYSTEM: '9882-02', A_T: 12, A_PG: 0, B_T: 4, B_PG: 0, },
      { SYSTEM: 9882, SUBSYSTEM: '9882-01', A_T: 3, A_PG: 0, B_T: 2, B_PG: 0, },
      { SYSTEM: 9890, SUBSYSTEM: '9890-01', A_T: 62, A_PG: 20.97, B_T: 10, B_PG: 0, },
      { SYSTEM: 9890, SUBSYSTEM: '9890-02', A_T: 17, A_PG: 41.18, B_T: 4, B_PG: 0, },
      { SYSTEM: 9890, SUBSYSTEM: '9890-03', A_T: 25, A_PG: 20, B_T: 4, B_PG: 0, },
      { SYSTEM: 9891, SUBSYSTEM: '9891-01', A_T: 59, A_PG: 0, B_T: 30, B_PG: 0, },
      { SYSTEM: 9910, SUBSYSTEM: '9910-04', A_T: 162, A_PG: 93.21, B_T: 26, B_PG: 38.46, },
      { SYSTEM: 9910, SUBSYSTEM: '9910-01', A_T: 167, A_PG: 92.81, B_T: 26, B_PG: 42.31, },
      { SYSTEM: 9910, SUBSYSTEM: '9910-02', A_T: 166, A_PG: 92.77, B_T: 26, B_PG: 34.62, },
      { SYSTEM: 9910, SUBSYSTEM: '9910-03', A_T: 162, A_PG: 93.21, B_T: 26, B_PG: 42.31, },
      { SYSTEM: 9921, SUBSYSTEM: '9921-02', A_T: 154, A_PG: 67.53, B_T: 21, B_PG: 0, },
      { SYSTEM: 9921, SUBSYSTEM: '9921-01', A_T: 231, A_PG: 72.73, B_T: 30, B_PG: 0, },
      { SYSTEM: 9922, SUBSYSTEM: '9922-03', A_T: 286, A_PG: 91.26, B_T: 70, B_PG: 0, },
      { SYSTEM: 9922, SUBSYSTEM: '9922-02', A_T: 171, A_PG: 72.51, B_T: 22, B_PG: 0, },
      { SYSTEM: 9922, SUBSYSTEM: '9922-01', A_T: 263, A_PG: 76.43, B_T: 35, B_PG: 0, },
      { SYSTEM: 9923, SUBSYSTEM: '9923-02', A_T: 152, A_PG: 44.08, B_T: 21, B_PG: 0, },
      { SYSTEM: 9923, SUBSYSTEM: '9923-01', A_T: 208, A_PG: 53.85, B_T: 26, B_PG: 0, },
      { SYSTEM: 9924, SUBSYSTEM: '9924-01', A_T: 263, A_PG: 52.85, B_T: 36, B_PG: 0, },
      { SYSTEM: 9924, SUBSYSTEM: '9924-02', A_T: 170, A_PG: 40.59, B_T: 22, B_PG: 0, },
      { SYSTEM: 9924, SUBSYSTEM: '9924-03', A_T: 280, A_PG: 76.43, B_T: 70, B_PG: 0, },
      { SYSTEM: 9930, SUBSYSTEM: '9930-09', A_T: 40, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 9930, SUBSYSTEM: '9930-08', A_T: 33, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 9930, SUBSYSTEM: '9930-07', A_T: 8, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 9930, SUBSYSTEM: '9930-06', A_T: 13, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 9930, SUBSYSTEM: '9930-05', A_T: 25, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 9930, SUBSYSTEM: '9930-04', A_T: 44, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 9930, SUBSYSTEM: '9930-03', A_T: 28, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 9930, SUBSYSTEM: '9930-02', A_T: 23, A_PG: 0, B_T: null, B_PG: null, },
      { SYSTEM: 9930, SUBSYSTEM: '9930-01', A_T: 20, A_PG: 0, B_T: 1, B_PG: 0, },
      { SYSTEM: 9971, SUBSYSTEM: '9971-01', A_T: 1040, A_PG: 75.29, B_T: 170, B_PG: 0, },
      { SYSTEM: 9972, SUBSYSTEM: '9972-01', A_T: 1121, A_PG: 82.87, B_T: 180, B_PG: 0, },
      { SYSTEM: 9972, SUBSYSTEM: '9972-02', A_T: 673, A_PG: 74, B_T: 127, B_PG: 0, },
      { SYSTEM: 9973, SUBSYSTEM: '9973-02', A_T: 875, A_PG: 78.63, B_T: 178, B_PG: 0, },
      { SYSTEM: 9973, SUBSYSTEM: '9973-01', A_T: 1042, A_PG: 80.9, B_T: 168, B_PG: 0, },
      { SYSTEM: 9999, SUBSYSTEM: '9999-07', A_T: 16, A_PG: 100, B_T: 5, B_PG: 100, },
      { SYSTEM: 9999, SUBSYSTEM: '9999-06', A_T: 6, A_PG: 0, B_T: 1, B_PG: 0, },
      { SYSTEM: 9999, SUBSYSTEM: '9999-05', A_T: 10, A_PG: 100, B_T: 1, B_PG: 0, },
      { SYSTEM: 9999, SUBSYSTEM: '9999-04', A_T: 68, A_PG: 97.06, B_T: 18, B_PG: 50, },
      { SYSTEM: 9999, SUBSYSTEM: '9999-03', A_T: 47, A_PG: 100, B_T: 99, B_PG: 67.68, },
      { SYSTEM: 9999, SUBSYSTEM: '9999-02', A_T: 72, A_PG: 100, B_T: 21, B_PG: 100, },
      { SYSTEM: 9999, SUBSYSTEM: '9999-01', A_T: 96, A_PG: 100, B_T: 76, B_PG: 100, },
    ],
    SQL4: [
      { SYSTEM: 1010, PHASE: 'A', DISC: 'Piping', TOTAL: 180, ACTUAL: 54, REMAIN: 126, PROG: 30, },
{ SYSTEM: 1010, PHASE: 'A', DISC: 'Mechanical', TOTAL: 20, ACTUAL: 6, REMAIN: 14, PROG: 30, },
{ SYSTEM: 1010, PHASE: 'A', DISC: 'Instrument', TOTAL: 383, ACTUAL: 65, REMAIN: 318, PROG: 16.97, },
{ SYSTEM: 1010, PHASE: 'B', DISC: 'Piping', TOTAL: 7, ACTUAL: 0, REMAIN: 7, PROG: 0, },
{ SYSTEM: 1010, PHASE: 'B', DISC: 'Mechanical', TOTAL: 12, ACTUAL: 0, REMAIN: 12, PROG: 0, },
{ SYSTEM: 1010, PHASE: 'B', DISC: 'Instrument', TOTAL: 72, ACTUAL: 0, REMAIN: 72, PROG: 0, },
{ SYSTEM: 1010, PHASE: 'PA', DISC: 'Piping', TOTAL: 4, ACTUAL: 1, REMAIN: 3, PROG: 25, },
{ SYSTEM: 1010, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 8, ACTUAL: 8, REMAIN: 0, PROG: 100, },
{ SYSTEM: 1010, PHASE: 'PA', DISC: 'Electrical', TOTAL: 8, ACTUAL: 7, REMAIN: 1, PROG: 87.5, },
{ SYSTEM: 1010, PHASE: 'PA', DISC: 'Instrument', TOTAL: 47, ACTUAL: 40, REMAIN: 7, PROG: 85.11, },
{ SYSTEM: 1010, PHASE: 'PB', DISC: 'Piping', TOTAL: 22, ACTUAL: 22, REMAIN: 0, PROG: 100, },
{ SYSTEM: 1010, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 15, ACTUAL: 15, REMAIN: 0, PROG: 100, },
{ SYSTEM: 1010, PHASE: 'PB', DISC: 'Electrical', TOTAL: 2, ACTUAL: 1, REMAIN: 1, PROG: 50, },
{ SYSTEM: 1010, PHASE: 'PB', DISC: 'Instrument', TOTAL: 99, ACTUAL: 84, REMAIN: 15, PROG: 84.85, },
{ SYSTEM: 1010, PHASE: 'PB', DISC: 'HVAC', TOTAL: 7, ACTUAL: 5, REMAIN: 2, PROG: 71.43, },
{ SYSTEM: 1010, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 29, ACTUAL: 19, REMAIN: 10, PROG: 65.52, },
{ SYSTEM: 1010, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 29, ACTUAL: 19, REMAIN: 10, PROG: 65.52, },
{ SYSTEM: 1010, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 29, ACTUAL: 17, REMAIN: 12, PROG: 58.62, },
{ SYSTEM: 1010, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 29, ACTUAL: 9, REMAIN: 20, PROG: 31.03, },
{ SYSTEM: 1010, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 29, ACTUAL: 7, REMAIN: 22, PROG: 24.14, },
{ SYSTEM: 1010, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 29, ACTUAL: 1, REMAIN: 28, PROG: 3.45, },
{ SYSTEM: 1010, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 29, ACTUAL: 1, REMAIN: 28, PROG: 3.45, },
{ SYSTEM: 1020, PHASE: 'A', DISC: 'Piping', TOTAL: 168, ACTUAL: 46, REMAIN: 122, PROG: 27.38, },
{ SYSTEM: 1020, PHASE: 'A', DISC: 'Mechanical', TOTAL: 20, ACTUAL: 6, REMAIN: 14, PROG: 30, },
{ SYSTEM: 1020, PHASE: 'A', DISC: 'Instrument', TOTAL: 380, ACTUAL: 58, REMAIN: 322, PROG: 15.26, },
{ SYSTEM: 1020, PHASE: 'B', DISC: 'Piping', TOTAL: 6, ACTUAL: 0, REMAIN: 6, PROG: 0, },
{ SYSTEM: 1020, PHASE: 'B', DISC: 'Mechanical', TOTAL: 12, ACTUAL: 0, REMAIN: 12, PROG: 0, },
{ SYSTEM: 1020, PHASE: 'B', DISC: 'Instrument', TOTAL: 72, ACTUAL: 0, REMAIN: 72, PROG: 0, },
{ SYSTEM: 1020, PHASE: 'PA', DISC: 'Piping', TOTAL: 2, ACTUAL: 1, REMAIN: 1, PROG: 50, },
{ SYSTEM: 1020, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 3, ACTUAL: 3, REMAIN: 0, PROG: 100, },
{ SYSTEM: 1020, PHASE: 'PA', DISC: 'Electrical', TOTAL: 7, ACTUAL: 7, REMAIN: 0, PROG: 100, },
{ SYSTEM: 1020, PHASE: 'PA', DISC: 'Instrument', TOTAL: 14, ACTUAL: 7, REMAIN: 7, PROG: 50, },
{ SYSTEM: 1020, PHASE: 'PB', DISC: 'Piping', TOTAL: 26, ACTUAL: 25, REMAIN: 1, PROG: 96.15, },
{ SYSTEM: 1020, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 13, ACTUAL: 13, REMAIN: 0, PROG: 100, },
{ SYSTEM: 1020, PHASE: 'PB', DISC: 'Electrical', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 1020, PHASE: 'PB', DISC: 'Instrument', TOTAL: 78, ACTUAL: 72, REMAIN: 6, PROG: 92.31, },
{ SYSTEM: 1020, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 27, ACTUAL: 17, REMAIN: 10, PROG: 62.96, },
{ SYSTEM: 1020, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 27, ACTUAL: 17, REMAIN: 10, PROG: 62.96, },
{ SYSTEM: 1020, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 27, ACTUAL: 15, REMAIN: 12, PROG: 55.56, },
{ SYSTEM: 1020, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 27, ACTUAL: 8, REMAIN: 19, PROG: 29.63, },
{ SYSTEM: 1020, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 27, ACTUAL: 6, REMAIN: 21, PROG: 22.22, },
{ SYSTEM: 1020, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 27, ACTUAL: 0, REMAIN: 27, PROG: 0, },
{ SYSTEM: 1020, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 27, ACTUAL: 0, REMAIN: 27, PROG: 0, },
{ SYSTEM: 1030, PHASE: 'A', DISC: 'Piping', TOTAL: 28, ACTUAL: 8, REMAIN: 20, PROG: 28.57, },
{ SYSTEM: 1030, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 4, ACTUAL: 4, REMAIN: 0, PROG: 100, },
{ SYSTEM: 1030, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 4, ACTUAL: 4, REMAIN: 0, PROG: 100, },
{ SYSTEM: 1030, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 4, ACTUAL: 4, REMAIN: 0, PROG: 100, },
{ SYSTEM: 1030, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 1030, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 1030, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 1030, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 1090, PHASE: 'A', DISC: 'Piping', TOTAL: 14, ACTUAL: 4, REMAIN: 10, PROG: 28.57, },
{ SYSTEM: 1090, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 1090, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 1090, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 1090, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 1090, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 1090, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 1090, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 1911, PHASE: 'A', DISC: 'Piping', TOTAL: 245, ACTUAL: 114, REMAIN: 131, PROG: 46.53, },
{ SYSTEM: 1911, PHASE: 'A', DISC: 'Mechanical', TOTAL: 34, ACTUAL: 33, REMAIN: 1, PROG: 97.06, },
{ SYSTEM: 1911, PHASE: 'A', DISC: 'Electrical', TOTAL: 26, ACTUAL: 26, REMAIN: 0, PROG: 100, },
{ SYSTEM: 1911, PHASE: 'A', DISC: 'Instrument', TOTAL: 150, ACTUAL: 119, REMAIN: 31, PROG: 79.33, },
{ SYSTEM: 1911, PHASE: 'B', DISC: 'Piping', TOTAL: 3, ACTUAL: 0, REMAIN: 3, PROG: 0, },
{ SYSTEM: 1911, PHASE: 'B', DISC: 'Mechanical', TOTAL: 14, ACTUAL: 0, REMAIN: 14, PROG: 0, },
{ SYSTEM: 1911, PHASE: 'B', DISC: 'Electrical', TOTAL: 9, ACTUAL: 0, REMAIN: 9, PROG: 0, },
{ SYSTEM: 1911, PHASE: 'B', DISC: 'Instrument', TOTAL: 19, ACTUAL: 0, REMAIN: 19, PROG: 0, },
{ SYSTEM: 1911, PHASE: 'PA', DISC: 'Piping', TOTAL: 3, ACTUAL: 1, REMAIN: 2, PROG: 33.33, },
{ SYSTEM: 1911, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 22, ACTUAL: 20, REMAIN: 2, PROG: 90.91, },
{ SYSTEM: 1911, PHASE: 'PA', DISC: 'Electrical', TOTAL: 13, ACTUAL: 11, REMAIN: 2, PROG: 84.62, },
{ SYSTEM: 1911, PHASE: 'PA', DISC: 'Instrument', TOTAL: 5, ACTUAL: 3, REMAIN: 2, PROG: 60, },
{ SYSTEM: 1911, PHASE: 'PB', DISC: 'Piping', TOTAL: 41, ACTUAL: 40, REMAIN: 1, PROG: 97.56, },
{ SYSTEM: 1911, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 12, ACTUAL: 10, REMAIN: 2, PROG: 83.33, },
{ SYSTEM: 1911, PHASE: 'PB', DISC: 'Electrical', TOTAL: 15, ACTUAL: 14, REMAIN: 1, PROG: 93.33, },
{ SYSTEM: 1911, PHASE: 'PB', DISC: 'Instrument', TOTAL: 3, ACTUAL: 1, REMAIN: 2, PROG: 33.33, },
{ SYSTEM: 1911, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 40, ACTUAL: 38, REMAIN: 2, PROG: 95, },
{ SYSTEM: 1911, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 40, ACTUAL: 39, REMAIN: 1, PROG: 97.5, },
{ SYSTEM: 1911, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 40, ACTUAL: 21, REMAIN: 19, PROG: 52.5, },
{ SYSTEM: 1911, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 40, ACTUAL: 21, REMAIN: 19, PROG: 52.5, },
{ SYSTEM: 1911, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 40, ACTUAL: 20, REMAIN: 20, PROG: 50, },
{ SYSTEM: 1911, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 40, ACTUAL: 11, REMAIN: 29, PROG: 27.5, },
{ SYSTEM: 1911, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 40, ACTUAL: 2, REMAIN: 38, PROG: 5, },
{ SYSTEM: 1912, PHASE: 'A', DISC: 'Piping', TOTAL: 179, ACTUAL: 85, REMAIN: 94, PROG: 47.49, },
{ SYSTEM: 1912, PHASE: 'A', DISC: 'Mechanical', TOTAL: 81, ACTUAL: 79, REMAIN: 2, PROG: 97.53, },
{ SYSTEM: 1912, PHASE: 'A', DISC: 'Electrical', TOTAL: 58, ACTUAL: 16, REMAIN: 42, PROG: 27.59, },
{ SYSTEM: 1912, PHASE: 'A', DISC: 'Instrument', TOTAL: 704, ACTUAL: 637, REMAIN: 67, PROG: 90.48, },
{ SYSTEM: 1912, PHASE: 'B', DISC: 'Piping', TOTAL: 3, ACTUAL: 0, REMAIN: 3, PROG: 0, },
{ SYSTEM: 1912, PHASE: 'B', DISC: 'Mechanical', TOTAL: 40, ACTUAL: 0, REMAIN: 40, PROG: 0, },
{ SYSTEM: 1912, PHASE: 'B', DISC: 'Electrical', TOTAL: 22, ACTUAL: 0, REMAIN: 22, PROG: 0, },
{ SYSTEM: 1912, PHASE: 'B', DISC: 'Instrument', TOTAL: 81, ACTUAL: 0, REMAIN: 81, PROG: 0, },
{ SYSTEM: 1912, PHASE: 'PA', DISC: 'Piping', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 1912, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 53, ACTUAL: 40, REMAIN: 13, PROG: 75.47, },
{ SYSTEM: 1912, PHASE: 'PA', DISC: 'Electrical', TOTAL: 18, ACTUAL: 17, REMAIN: 1, PROG: 94.44, },
{ SYSTEM: 1912, PHASE: 'PA', DISC: 'Instrument', TOTAL: 18, ACTUAL: 18, REMAIN: 0, PROG: 100, },
{ SYSTEM: 1912, PHASE: 'PB', DISC: 'Piping', TOTAL: 2, ACTUAL: 1, REMAIN: 1, PROG: 50, },
{ SYSTEM: 1912, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 39, ACTUAL: 35, REMAIN: 4, PROG: 89.74, },
{ SYSTEM: 1912, PHASE: 'PB', DISC: 'Electrical', TOTAL: 5, ACTUAL: 5, REMAIN: 0, PROG: 100, },
{ SYSTEM: 1912, PHASE: 'PB', DISC: 'Instrument', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 1912, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 29, ACTUAL: 29, REMAIN: 0, PROG: 100, },
{ SYSTEM: 1912, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 29, ACTUAL: 27, REMAIN: 2, PROG: 93.1, },
{ SYSTEM: 1912, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 29, ACTUAL: 18, REMAIN: 11, PROG: 62.07, },
{ SYSTEM: 1912, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 29, ACTUAL: 14, REMAIN: 15, PROG: 48.28, },
{ SYSTEM: 1912, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 29, ACTUAL: 13, REMAIN: 16, PROG: 44.83, },
{ SYSTEM: 1912, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 29, ACTUAL: 7, REMAIN: 22, PROG: 24.14, },
{ SYSTEM: 1912, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 29, ACTUAL: 6, REMAIN: 23, PROG: 20.69, },
{ SYSTEM: 2110, PHASE: 'A', DISC: 'Piping', TOTAL: 186, ACTUAL: 63, REMAIN: 123, PROG: 33.87, },
{ SYSTEM: 2110, PHASE: 'A', DISC: 'Mechanical', TOTAL: 6, ACTUAL: 4, REMAIN: 2, PROG: 66.67, },
{ SYSTEM: 2110, PHASE: 'A', DISC: 'Instrument', TOTAL: 203, ACTUAL: 27, REMAIN: 176, PROG: 13.3, },
{ SYSTEM: 2110, PHASE: 'B', DISC: 'Piping', TOTAL: 3, ACTUAL: 0, REMAIN: 3, PROG: 0, },
{ SYSTEM: 2110, PHASE: 'B', DISC: 'Mechanical', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 2110, PHASE: 'B', DISC: 'Instrument', TOTAL: 36, ACTUAL: 0, REMAIN: 36, PROG: 0, },
{ SYSTEM: 2110, PHASE: 'PA', DISC: 'Insulation', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 2110, PHASE: 'PA', DISC: 'Piping', TOTAL: 2, ACTUAL: 1, REMAIN: 1, PROG: 50, },
{ SYSTEM: 2110, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 4, ACTUAL: 2, REMAIN: 2, PROG: 50, },
{ SYSTEM: 2110, PHASE: 'PA', DISC: 'Electrical', TOTAL: 2, ACTUAL: 1, REMAIN: 1, PROG: 50, },
{ SYSTEM: 2110, PHASE: 'PA', DISC: 'Instrument', TOTAL: 19, ACTUAL: 19, REMAIN: 0, PROG: 100, },
{ SYSTEM: 2110, PHASE: 'PB', DISC: 'Piping', TOTAL: 25, ACTUAL: 21, REMAIN: 4, PROG: 84, },
{ SYSTEM: 2110, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 12, ACTUAL: 9, REMAIN: 3, PROG: 75, },
{ SYSTEM: 2110, PHASE: 'PB', DISC: 'Instrument', TOTAL: 21, ACTUAL: 21, REMAIN: 0, PROG: 100, },
{ SYSTEM: 2110, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 30, ACTUAL: 22, REMAIN: 8, PROG: 73.33, },
{ SYSTEM: 2110, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 30, ACTUAL: 14, REMAIN: 16, PROG: 46.67, },
{ SYSTEM: 2110, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 30, ACTUAL: 13, REMAIN: 17, PROG: 43.33, },
{ SYSTEM: 2110, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 30, ACTUAL: 12, REMAIN: 18, PROG: 40, },
{ SYSTEM: 2110, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 30, ACTUAL: 12, REMAIN: 18, PROG: 40, },
{ SYSTEM: 2110, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 30, ACTUAL: 9, REMAIN: 21, PROG: 30, },
{ SYSTEM: 2110, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 30, ACTUAL: 3, REMAIN: 27, PROG: 10, },
{ SYSTEM: 2120, PHASE: 'A', DISC: 'Piping', TOTAL: 333, ACTUAL: 133, REMAIN: 200, PROG: 39.94, },
{ SYSTEM: 2120, PHASE: 'A', DISC: 'Mechanical', TOTAL: 22, ACTUAL: 9, REMAIN: 13, PROG: 40.91, },
{ SYSTEM: 2120, PHASE: 'A', DISC: 'Electrical', TOTAL: 34, ACTUAL: 14, REMAIN: 20, PROG: 41.18, },
{ SYSTEM: 2120, PHASE: 'A', DISC: 'Instrument', TOTAL: 205, ACTUAL: 72, REMAIN: 133, PROG: 35.12, },
{ SYSTEM: 2120, PHASE: 'B', DISC: 'Piping', TOTAL: 5, ACTUAL: 0, REMAIN: 5, PROG: 0, },
{ SYSTEM: 2120, PHASE: 'B', DISC: 'Mechanical', TOTAL: 10, ACTUAL: 0, REMAIN: 10, PROG: 0, },
{ SYSTEM: 2120, PHASE: 'B', DISC: 'Electrical', TOTAL: 11, ACTUAL: 0, REMAIN: 11, PROG: 0, },
{ SYSTEM: 2120, PHASE: 'B', DISC: 'Instrument', TOTAL: 33, ACTUAL: 0, REMAIN: 33, PROG: 0, },
{ SYSTEM: 2120, PHASE: 'PA', DISC: 'Insulation', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 2120, PHASE: 'PA', DISC: 'Piping', TOTAL: 16, ACTUAL: 11, REMAIN: 5, PROG: 68.75, },
{ SYSTEM: 2120, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 10, ACTUAL: 10, REMAIN: 0, PROG: 100, },
{ SYSTEM: 2120, PHASE: 'PA', DISC: 'Electrical', TOTAL: 17, ACTUAL: 13, REMAIN: 4, PROG: 76.47, },
{ SYSTEM: 2120, PHASE: 'PA', DISC: 'Instrument', TOTAL: 11, ACTUAL: 11, REMAIN: 0, PROG: 100, },
{ SYSTEM: 2120, PHASE: 'PB', DISC: 'Piping', TOTAL: 52, ACTUAL: 49, REMAIN: 3, PROG: 94.23, },
{ SYSTEM: 2120, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 15, ACTUAL: 12, REMAIN: 3, PROG: 80, },
{ SYSTEM: 2120, PHASE: 'PB', DISC: 'Electrical', TOTAL: 10, ACTUAL: 8, REMAIN: 2, PROG: 80, },
{ SYSTEM: 2120, PHASE: 'PB', DISC: 'Instrument', TOTAL: 10, ACTUAL: 9, REMAIN: 1, PROG: 90, },
{ SYSTEM: 2120, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 54, ACTUAL: 38, REMAIN: 16, PROG: 70.37, },
{ SYSTEM: 2120, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 54, ACTUAL: 39, REMAIN: 15, PROG: 72.22, },
{ SYSTEM: 2120, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 54, ACTUAL: 22, REMAIN: 32, PROG: 40.74, },
{ SYSTEM: 2120, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 54, ACTUAL: 21, REMAIN: 33, PROG: 38.89, },
{ SYSTEM: 2120, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 54, ACTUAL: 21, REMAIN: 33, PROG: 38.89, },
{ SYSTEM: 2120, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 54, ACTUAL: 18, REMAIN: 36, PROG: 33.33, },
{ SYSTEM: 2120, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 54, ACTUAL: 12, REMAIN: 42, PROG: 22.22, },
{ SYSTEM: 2210, PHASE: 'A', DISC: 'Piping', TOTAL: 234, ACTUAL: 83, REMAIN: 151, PROG: 35.47, },
{ SYSTEM: 2210, PHASE: 'A', DISC: 'Mechanical', TOTAL: 16, ACTUAL: 5, REMAIN: 11, PROG: 31.25, },
{ SYSTEM: 2210, PHASE: 'A', DISC: 'Instrument', TOTAL: 225, ACTUAL: 24, REMAIN: 201, PROG: 10.67, },
{ SYSTEM: 2210, PHASE: 'B', DISC: 'Piping', TOTAL: 7, ACTUAL: 0, REMAIN: 7, PROG: 0, },
{ SYSTEM: 2210, PHASE: 'B', DISC: 'Mechanical', TOTAL: 6, ACTUAL: 0, REMAIN: 6, PROG: 0, },
{ SYSTEM: 2210, PHASE: 'B', DISC: 'Instrument', TOTAL: 33, ACTUAL: 0, REMAIN: 33, PROG: 0, },
{ SYSTEM: 2210, PHASE: 'PA', DISC: 'Insulation', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 2210, PHASE: 'PA', DISC: 'Piping', TOTAL: 3, ACTUAL: 2, REMAIN: 1, PROG: 66.67, },
{ SYSTEM: 2210, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 2210, PHASE: 'PA', DISC: 'Instrument', TOTAL: 19, ACTUAL: 17, REMAIN: 2, PROG: 89.47, },
{ SYSTEM: 2210, PHASE: 'PB', DISC: 'Piping', TOTAL: 48, ACTUAL: 46, REMAIN: 2, PROG: 95.83, },
{ SYSTEM: 2210, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 11, ACTUAL: 8, REMAIN: 3, PROG: 72.73, },
{ SYSTEM: 2210, PHASE: 'PB', DISC: 'Instrument', TOTAL: 27, ACTUAL: 27, REMAIN: 0, PROG: 100, },
{ SYSTEM: 2210, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 37, ACTUAL: 24, REMAIN: 13, PROG: 64.86, },
{ SYSTEM: 2210, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 37, ACTUAL: 18, REMAIN: 19, PROG: 48.65, },
{ SYSTEM: 2210, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 37, ACTUAL: 17, REMAIN: 20, PROG: 45.95, },
{ SYSTEM: 2210, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 37, ACTUAL: 16, REMAIN: 21, PROG: 43.24, },
{ SYSTEM: 2210, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 37, ACTUAL: 15, REMAIN: 22, PROG: 40.54, },
{ SYSTEM: 2210, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 37, ACTUAL: 9, REMAIN: 28, PROG: 24.32, },
{ SYSTEM: 2210, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 37, ACTUAL: 8, REMAIN: 29, PROG: 21.62, },
{ SYSTEM: 2220, PHASE: 'A', DISC: 'Piping', TOTAL: 315, ACTUAL: 145, REMAIN: 170, PROG: 46.03, },
{ SYSTEM: 2220, PHASE: 'A', DISC: 'Mechanical', TOTAL: 18, ACTUAL: 4, REMAIN: 14, PROG: 22.22, },
{ SYSTEM: 2220, PHASE: 'A', DISC: 'Electrical', TOTAL: 10, ACTUAL: 3, REMAIN: 7, PROG: 30, },
{ SYSTEM: 2220, PHASE: 'A', DISC: 'Instrument', TOTAL: 225, ACTUAL: 37, REMAIN: 188, PROG: 16.44, },
{ SYSTEM: 2220, PHASE: 'B', DISC: 'Piping', TOTAL: 5, ACTUAL: 0, REMAIN: 5, PROG: 0, },
{ SYSTEM: 2220, PHASE: 'B', DISC: 'Mechanical', TOTAL: 7, ACTUAL: 0, REMAIN: 7, PROG: 0, },
{ SYSTEM: 2220, PHASE: 'B', DISC: 'Electrical', TOTAL: 3, ACTUAL: 0, REMAIN: 3, PROG: 0, },
{ SYSTEM: 2220, PHASE: 'B', DISC: 'Instrument', TOTAL: 35, ACTUAL: 0, REMAIN: 35, PROG: 0, },
{ SYSTEM: 2220, PHASE: 'PA', DISC: 'Piping', TOTAL: 6, ACTUAL: 2, REMAIN: 4, PROG: 33.33, },
{ SYSTEM: 2220, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 15, ACTUAL: 15, REMAIN: 0, PROG: 100, },
{ SYSTEM: 2220, PHASE: 'PA', DISC: 'Electrical', TOTAL: 11, ACTUAL: 10, REMAIN: 1, PROG: 90.91, },
{ SYSTEM: 2220, PHASE: 'PA', DISC: 'Instrument', TOTAL: 17, ACTUAL: 17, REMAIN: 0, PROG: 100, },
{ SYSTEM: 2220, PHASE: 'PB', DISC: 'Piping', TOTAL: 23, ACTUAL: 16, REMAIN: 7, PROG: 69.57, },
{ SYSTEM: 2220, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 10, ACTUAL: 10, REMAIN: 0, PROG: 100, },
{ SYSTEM: 2220, PHASE: 'PB', DISC: 'Electrical', TOTAL: 4, ACTUAL: 4, REMAIN: 0, PROG: 100, },
{ SYSTEM: 2220, PHASE: 'PB', DISC: 'Instrument', TOTAL: 20, ACTUAL: 20, REMAIN: 0, PROG: 100, },
{ SYSTEM: 2220, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 51, ACTUAL: 39, REMAIN: 12, PROG: 76.47, },
{ SYSTEM: 2220, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 51, ACTUAL: 33, REMAIN: 18, PROG: 64.71, },
{ SYSTEM: 2220, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 51, ACTUAL: 32, REMAIN: 19, PROG: 62.75, },
{ SYSTEM: 2220, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 51, ACTUAL: 31, REMAIN: 20, PROG: 60.78, },
{ SYSTEM: 2220, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 51, ACTUAL: 31, REMAIN: 20, PROG: 60.78, },
{ SYSTEM: 2220, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 51, ACTUAL: 15, REMAIN: 36, PROG: 29.41, },
{ SYSTEM: 2220, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 51, ACTUAL: 3, REMAIN: 48, PROG: 5.88, },
{ SYSTEM: 2400, PHASE: 'A', DISC: 'Piping', TOTAL: 734, ACTUAL: 265, REMAIN: 469, PROG: 36.1, },
{ SYSTEM: 2400, PHASE: 'A', DISC: 'Mechanical', TOTAL: 111, ACTUAL: 17, REMAIN: 94, PROG: 15.32, },
{ SYSTEM: 2400, PHASE: 'A', DISC: 'Electrical', TOTAL: 323, ACTUAL: 33, REMAIN: 290, PROG: 10.22, },
{ SYSTEM: 2400, PHASE: 'A', DISC: 'Instrument', TOTAL: 1407, ACTUAL: 227, REMAIN: 1180, PROG: 16.13, },
{ SYSTEM: 2400, PHASE: 'A', DISC: 'Telecom', TOTAL: 3, ACTUAL: 0, REMAIN: 3, PROG: 0, },
{ SYSTEM: 2400, PHASE: 'B', DISC: 'Piping', TOTAL: 23, ACTUAL: 0, REMAIN: 23, PROG: 0, },
{ SYSTEM: 2400, PHASE: 'B', DISC: 'Mechanical', TOTAL: 89, ACTUAL: 0, REMAIN: 89, PROG: 0, },
{ SYSTEM: 2400, PHASE: 'B', DISC: 'Electrical', TOTAL: 120, ACTUAL: 0, REMAIN: 120, PROG: 0, },
{ SYSTEM: 2400, PHASE: 'B', DISC: 'Instrument', TOTAL: 388, ACTUAL: 0, REMAIN: 388, PROG: 0, },
{ SYSTEM: 2400, PHASE: 'PA', DISC: 'Piping', TOTAL: 17, ACTUAL: 9, REMAIN: 8, PROG: 52.94, },
{ SYSTEM: 2400, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 94, ACTUAL: 60, REMAIN: 34, PROG: 63.83, },
{ SYSTEM: 2400, PHASE: 'PA', DISC: 'Electrical', TOTAL: 230, ACTUAL: 150, REMAIN: 80, PROG: 65.22, },
{ SYSTEM: 2400, PHASE: 'PA', DISC: 'Instrument', TOTAL: 1390, ACTUAL: 964, REMAIN: 426, PROG: 69.35, },
{ SYSTEM: 2400, PHASE: 'PB', DISC: 'Company', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 2400, PHASE: 'PB', DISC: 'Insulation', TOTAL: 5, ACTUAL: 3, REMAIN: 2, PROG: 60, },
{ SYSTEM: 2400, PHASE: 'PB', DISC: 'Piping', TOTAL: 198, ACTUAL: 185, REMAIN: 13, PROG: 93.43, },
{ SYSTEM: 2400, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 152, ACTUAL: 120, REMAIN: 32, PROG: 78.95, },
{ SYSTEM: 2400, PHASE: 'PB', DISC: 'Electrical', TOTAL: 43, ACTUAL: 2, REMAIN: 41, PROG: 4.65, },
{ SYSTEM: 2400, PHASE: 'PB', DISC: 'Instrument', TOTAL: 192, ACTUAL: 93, REMAIN: 99, PROG: 48.44, },
{ SYSTEM: 2400, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 118, ACTUAL: 63, REMAIN: 55, PROG: 53.39, },
{ SYSTEM: 2400, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 118, ACTUAL: 56, REMAIN: 62, PROG: 47.46, },
{ SYSTEM: 2400, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 118, ACTUAL: 52, REMAIN: 66, PROG: 44.07, },
{ SYSTEM: 2400, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 118, ACTUAL: 49, REMAIN: 69, PROG: 41.53, },
{ SYSTEM: 2400, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 118, ACTUAL: 48, REMAIN: 70, PROG: 40.68, },
{ SYSTEM: 2400, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 118, ACTUAL: 36, REMAIN: 82, PROG: 30.51, },
{ SYSTEM: 2400, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 118, ACTUAL: 24, REMAIN: 94, PROG: 20.34, },
{ SYSTEM: 3000, PHASE: 'A', DISC: 'Piping', TOTAL: 58, ACTUAL: 26, REMAIN: 32, PROG: 44.83, },
{ SYSTEM: 3000, PHASE: 'A', DISC: 'Mechanical', TOTAL: 14, ACTUAL: 0, REMAIN: 14, PROG: 0, },
{ SYSTEM: 3000, PHASE: 'A', DISC: 'Electrical', TOTAL: 108, ACTUAL: 60, REMAIN: 48, PROG: 55.56, },
{ SYSTEM: 3000, PHASE: 'A', DISC: 'Instrument', TOTAL: 178, ACTUAL: 70, REMAIN: 108, PROG: 39.33, },
{ SYSTEM: 3000, PHASE: 'B', DISC: 'Piping', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 3000, PHASE: 'B', DISC: 'Mechanical', TOTAL: 12, ACTUAL: 0, REMAIN: 12, PROG: 0, },
{ SYSTEM: 3000, PHASE: 'B', DISC: 'Electrical', TOTAL: 44, ACTUAL: 0, REMAIN: 44, PROG: 0, },
{ SYSTEM: 3000, PHASE: 'B', DISC: 'Instrument', TOTAL: 202, ACTUAL: 0, REMAIN: 202, PROG: 0, },
{ SYSTEM: 3000, PHASE: 'PA', DISC: 'Piping', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 3000, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 16, ACTUAL: 6, REMAIN: 10, PROG: 37.5, },
{ SYSTEM: 3000, PHASE: 'PA', DISC: 'Electrical', TOTAL: 57, ACTUAL: 29, REMAIN: 28, PROG: 50.88, },
{ SYSTEM: 3000, PHASE: 'PA', DISC: 'Instrument', TOTAL: 341, ACTUAL: 250, REMAIN: 91, PROG: 73.31, },
{ SYSTEM: 3000, PHASE: 'PB', DISC: 'Insulation', TOTAL: 8, ACTUAL: 2, REMAIN: 6, PROG: 25, },
{ SYSTEM: 3000, PHASE: 'PB', DISC: 'Piping', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 3000, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 44, ACTUAL: 7, REMAIN: 37, PROG: 15.91, },
{ SYSTEM: 3000, PHASE: 'PB', DISC: 'Electrical', TOTAL: 64, ACTUAL: 34, REMAIN: 30, PROG: 53.13, },
{ SYSTEM: 3000, PHASE: 'PB', DISC: 'Instrument', TOTAL: 150, ACTUAL: 65, REMAIN: 85, PROG: 43.33, },
{ SYSTEM: 3000, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 9, ACTUAL: 7, REMAIN: 2, PROG: 77.78, },
{ SYSTEM: 3000, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 9, ACTUAL: 8, REMAIN: 1, PROG: 88.89, },
{ SYSTEM: 3000, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 9, ACTUAL: 6, REMAIN: 3, PROG: 66.67, },
{ SYSTEM: 3000, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 9, ACTUAL: 6, REMAIN: 3, PROG: 66.67, },
{ SYSTEM: 3000, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 9, ACTUAL: 4, REMAIN: 5, PROG: 44.44, },
{ SYSTEM: 3000, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 9, ACTUAL: 1, REMAIN: 8, PROG: 11.11, },
{ SYSTEM: 3000, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 9, ACTUAL: 1, REMAIN: 8, PROG: 11.11, },
{ SYSTEM: 3010, PHASE: 'A', DISC: 'Piping', TOTAL: 351, ACTUAL: 190, REMAIN: 161, PROG: 54.13, },
{ SYSTEM: 3010, PHASE: 'A', DISC: 'Mechanical', TOTAL: 55, ACTUAL: 16, REMAIN: 39, PROG: 29.09, },
{ SYSTEM: 3010, PHASE: 'A', DISC: 'Electrical', TOTAL: 36, ACTUAL: 36, REMAIN: 0, PROG: 100, },
{ SYSTEM: 3010, PHASE: 'A', DISC: 'Instrument', TOTAL: 731, ACTUAL: 304, REMAIN: 427, PROG: 41.59, },
{ SYSTEM: 3010, PHASE: 'B', DISC: 'Piping', TOTAL: 11, ACTUAL: 0, REMAIN: 11, PROG: 0, },
{ SYSTEM: 3010, PHASE: 'B', DISC: 'Mechanical', TOTAL: 28, ACTUAL: 0, REMAIN: 28, PROG: 0, },
{ SYSTEM: 3010, PHASE: 'B', DISC: 'Electrical', TOTAL: 12, ACTUAL: 0, REMAIN: 12, PROG: 0, },
{ SYSTEM: 3010, PHASE: 'B', DISC: 'Instrument', TOTAL: 130, ACTUAL: 0, REMAIN: 130, PROG: 0, },
{ SYSTEM: 3010, PHASE: 'PA', DISC: 'Piping', TOTAL: 15, ACTUAL: 9, REMAIN: 6, PROG: 60, },
{ SYSTEM: 3010, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 49, ACTUAL: 44, REMAIN: 5, PROG: 89.8, },
{ SYSTEM: 3010, PHASE: 'PA', DISC: 'Electrical', TOTAL: 18, ACTUAL: 13, REMAIN: 5, PROG: 72.22, },
{ SYSTEM: 3010, PHASE: 'PA', DISC: 'Instrument', TOTAL: 28, ACTUAL: 24, REMAIN: 4, PROG: 85.71, },
{ SYSTEM: 3010, PHASE: 'PB', DISC: 'Piping', TOTAL: 124, ACTUAL: 116, REMAIN: 8, PROG: 93.55, },
{ SYSTEM: 3010, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 41, ACTUAL: 40, REMAIN: 1, PROG: 97.56, },
{ SYSTEM: 3010, PHASE: 'PB', DISC: 'Electrical', TOTAL: 16, ACTUAL: 12, REMAIN: 4, PROG: 75, },
{ SYSTEM: 3010, PHASE: 'PB', DISC: 'Instrument', TOTAL: 44, ACTUAL: 36, REMAIN: 8, PROG: 81.82, },
{ SYSTEM: 3010, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 57, ACTUAL: 43, REMAIN: 14, PROG: 75.44, },
{ SYSTEM: 3010, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 57, ACTUAL: 43, REMAIN: 14, PROG: 75.44, },
{ SYSTEM: 3010, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 57, ACTUAL: 40, REMAIN: 17, PROG: 70.18, },
{ SYSTEM: 3010, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 57, ACTUAL: 40, REMAIN: 17, PROG: 70.18, },
{ SYSTEM: 3010, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 57, ACTUAL: 34, REMAIN: 23, PROG: 59.65, },
{ SYSTEM: 3010, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 57, ACTUAL: 25, REMAIN: 32, PROG: 43.86, },
{ SYSTEM: 3010, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 57, ACTUAL: 8, REMAIN: 49, PROG: 14.04, },
{ SYSTEM: 3020, PHASE: 'A', DISC: 'Piping', TOTAL: 399, ACTUAL: 134, REMAIN: 265, PROG: 33.58, },
{ SYSTEM: 3020, PHASE: 'A', DISC: 'Mechanical', TOTAL: 72, ACTUAL: 16, REMAIN: 56, PROG: 22.22, },
{ SYSTEM: 3020, PHASE: 'A', DISC: 'Electrical', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 3020, PHASE: 'A', DISC: 'Instrument', TOTAL: 839, ACTUAL: 194, REMAIN: 645, PROG: 23.12, },
{ SYSTEM: 3020, PHASE: 'B', DISC: 'Piping', TOTAL: 11, ACTUAL: 0, REMAIN: 11, PROG: 0, },
{ SYSTEM: 3020, PHASE: 'B', DISC: 'Mechanical', TOTAL: 44, ACTUAL: 0, REMAIN: 44, PROG: 0, },
{ SYSTEM: 3020, PHASE: 'B', DISC: 'Instrument', TOTAL: 129, ACTUAL: 0, REMAIN: 129, PROG: 0, },
{ SYSTEM: 3020, PHASE: 'PA', DISC: 'Piping', TOTAL: 9, ACTUAL: 6, REMAIN: 3, PROG: 66.67, },
{ SYSTEM: 3020, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 15, ACTUAL: 14, REMAIN: 1, PROG: 93.33, },
{ SYSTEM: 3020, PHASE: 'PA', DISC: 'Electrical', TOTAL: 8, ACTUAL: 8, REMAIN: 0, PROG: 100, },
{ SYSTEM: 3020, PHASE: 'PA', DISC: 'Instrument', TOTAL: 21, ACTUAL: 20, REMAIN: 1, PROG: 95.24, },
{ SYSTEM: 3020, PHASE: 'PB', DISC: 'Insulation', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 3020, PHASE: 'PB', DISC: 'Piping', TOTAL: 109, ACTUAL: 104, REMAIN: 5, PROG: 95.41, },
{ SYSTEM: 3020, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 59, ACTUAL: 58, REMAIN: 1, PROG: 98.31, },
{ SYSTEM: 3020, PHASE: 'PB', DISC: 'Electrical', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 3020, PHASE: 'PB', DISC: 'Instrument', TOTAL: 57, ACTUAL: 36, REMAIN: 21, PROG: 63.16, },
{ SYSTEM: 3020, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 65, ACTUAL: 31, REMAIN: 34, PROG: 47.69, },
{ SYSTEM: 3020, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 65, ACTUAL: 32, REMAIN: 33, PROG: 49.23, },
{ SYSTEM: 3020, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 65, ACTUAL: 29, REMAIN: 36, PROG: 44.62, },
{ SYSTEM: 3020, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 65, ACTUAL: 28, REMAIN: 37, PROG: 43.08, },
{ SYSTEM: 3020, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 65, ACTUAL: 26, REMAIN: 39, PROG: 40, },
{ SYSTEM: 3020, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 65, ACTUAL: 15, REMAIN: 50, PROG: 23.08, },
{ SYSTEM: 3020, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 65, ACTUAL: 4, REMAIN: 61, PROG: 6.15, },
{ SYSTEM: 3030, PHASE: 'A', DISC: 'Piping', TOTAL: 222, ACTUAL: 87, REMAIN: 135, PROG: 39.19, },
{ SYSTEM: 3030, PHASE: 'A', DISC: 'Mechanical', TOTAL: 50, ACTUAL: 6, REMAIN: 44, PROG: 12, },
{ SYSTEM: 3030, PHASE: 'A', DISC: 'Instrument', TOTAL: 527, ACTUAL: 134, REMAIN: 393, PROG: 25.43, },
{ SYSTEM: 3030, PHASE: 'B', DISC: 'Piping', TOTAL: 10, ACTUAL: 0, REMAIN: 10, PROG: 0, },
{ SYSTEM: 3030, PHASE: 'B', DISC: 'Mechanical', TOTAL: 36, ACTUAL: 0, REMAIN: 36, PROG: 0, },
{ SYSTEM: 3030, PHASE: 'B', DISC: 'Instrument', TOTAL: 94, ACTUAL: 0, REMAIN: 94, PROG: 0, },
{ SYSTEM: 3030, PHASE: 'PA', DISC: 'Piping', TOTAL: 3, ACTUAL: 2, REMAIN: 1, PROG: 66.67, },
{ SYSTEM: 3030, PHASE: 'PA', DISC: 'Electrical', TOTAL: 6, ACTUAL: 5, REMAIN: 1, PROG: 83.33, },
{ SYSTEM: 3030, PHASE: 'PA', DISC: 'Instrument', TOTAL: 13, ACTUAL: 13, REMAIN: 0, PROG: 100, },
{ SYSTEM: 3030, PHASE: 'PB', DISC: 'Insulation', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 3030, PHASE: 'PB', DISC: 'Company', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 3030, PHASE: 'PB', DISC: 'Piping', TOTAL: 61, ACTUAL: 56, REMAIN: 5, PROG: 91.8, },
{ SYSTEM: 3030, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 39, ACTUAL: 39, REMAIN: 0, PROG: 100, },
{ SYSTEM: 3030, PHASE: 'PB', DISC: 'Electrical', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 3030, PHASE: 'PB', DISC: 'Instrument', TOTAL: 30, ACTUAL: 26, REMAIN: 4, PROG: 86.67, },
{ SYSTEM: 3030, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 36, ACTUAL: 20, REMAIN: 16, PROG: 55.56, },
{ SYSTEM: 3030, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 36, ACTUAL: 20, REMAIN: 16, PROG: 55.56, },
{ SYSTEM: 3030, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 36, ACTUAL: 18, REMAIN: 18, PROG: 50, },
{ SYSTEM: 3030, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 36, ACTUAL: 18, REMAIN: 18, PROG: 50, },
{ SYSTEM: 3030, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 36, ACTUAL: 18, REMAIN: 18, PROG: 50, },
{ SYSTEM: 3030, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 36, ACTUAL: 11, REMAIN: 25, PROG: 30.56, },
{ SYSTEM: 3030, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 36, ACTUAL: 2, REMAIN: 34, PROG: 5.56, },
{ SYSTEM: 3040, PHASE: 'A', DISC: 'Piping', TOTAL: 273, ACTUAL: 121, REMAIN: 152, PROG: 44.32, },
{ SYSTEM: 3040, PHASE: 'A', DISC: 'Mechanical', TOTAL: 99, ACTUAL: 70, REMAIN: 29, PROG: 70.71, },
{ SYSTEM: 3040, PHASE: 'A', DISC: 'Electrical', TOTAL: 200, ACTUAL: 100, REMAIN: 100, PROG: 50, },
{ SYSTEM: 3040, PHASE: 'A', DISC: 'Instrument', TOTAL: 1543, ACTUAL: 1181, REMAIN: 362, PROG: 76.54, },
{ SYSTEM: 3040, PHASE: 'B', DISC: 'Piping', TOTAL: 11, ACTUAL: 0, REMAIN: 11, PROG: 0, },
{ SYSTEM: 3040, PHASE: 'B', DISC: 'Mechanical', TOTAL: 38, ACTUAL: 0, REMAIN: 38, PROG: 0, },
{ SYSTEM: 3040, PHASE: 'B', DISC: 'Electrical', TOTAL: 62, ACTUAL: 0, REMAIN: 62, PROG: 0, },
{ SYSTEM: 3040, PHASE: 'B', DISC: 'Instrument', TOTAL: 224, ACTUAL: 0, REMAIN: 224, PROG: 0, },
{ SYSTEM: 3040, PHASE: 'PA', DISC: 'Piping', TOTAL: 13, ACTUAL: 13, REMAIN: 0, PROG: 100, },
{ SYSTEM: 3040, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 57, ACTUAL: 9, REMAIN: 48, PROG: 15.79, },
{ SYSTEM: 3040, PHASE: 'PA', DISC: 'Electrical', TOTAL: 14, ACTUAL: 10, REMAIN: 4, PROG: 71.43, },
{ SYSTEM: 3040, PHASE: 'PA', DISC: 'Instrument', TOTAL: 20, ACTUAL: 17, REMAIN: 3, PROG: 85, },
{ SYSTEM: 3040, PHASE: 'PB', DISC: 'Insulation', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 3040, PHASE: 'PB', DISC: 'Piping', TOTAL: 59, ACTUAL: 56, REMAIN: 3, PROG: 94.92, },
{ SYSTEM: 3040, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 39, ACTUAL: 30, REMAIN: 9, PROG: 76.92, },
{ SYSTEM: 3040, PHASE: 'PB', DISC: 'Electrical', TOTAL: 18, ACTUAL: 3, REMAIN: 15, PROG: 16.67, },
{ SYSTEM: 3040, PHASE: 'PB', DISC: 'Instrument', TOTAL: 26, ACTUAL: 20, REMAIN: 6, PROG: 76.92, },
{ SYSTEM: 3040, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 44, ACTUAL: 32, REMAIN: 12, PROG: 72.73, },
{ SYSTEM: 3040, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 44, ACTUAL: 28, REMAIN: 16, PROG: 63.64, },
{ SYSTEM: 3040, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 44, ACTUAL: 26, REMAIN: 18, PROG: 59.09, },
{ SYSTEM: 3040, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 44, ACTUAL: 26, REMAIN: 18, PROG: 59.09, },
{ SYSTEM: 3040, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 44, ACTUAL: 20, REMAIN: 24, PROG: 45.45, },
{ SYSTEM: 3040, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 44, ACTUAL: 15, REMAIN: 29, PROG: 34.09, },
{ SYSTEM: 3040, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 44, ACTUAL: 6, REMAIN: 38, PROG: 13.64, },
{ SYSTEM: 3200, PHASE: 'A', DISC: 'Piping', TOTAL: 215, ACTUAL: 135, REMAIN: 80, PROG: 62.79, },
{ SYSTEM: 3200, PHASE: 'A', DISC: 'Mechanical', TOTAL: 65, ACTUAL: 42, REMAIN: 23, PROG: 64.62, },
{ SYSTEM: 3200, PHASE: 'A', DISC: 'Electrical', TOTAL: 42, ACTUAL: 32, REMAIN: 10, PROG: 76.19, },
{ SYSTEM: 3200, PHASE: 'A', DISC: 'Instrument', TOTAL: 919, ACTUAL: 333, REMAIN: 586, PROG: 36.24, },
{ SYSTEM: 3200, PHASE: 'B', DISC: 'Piping', TOTAL: 9, ACTUAL: 0, REMAIN: 9, PROG: 0, },
{ SYSTEM: 3200, PHASE: 'B', DISC: 'Mechanical', TOTAL: 19, ACTUAL: 0, REMAIN: 19, PROG: 0, },
{ SYSTEM: 3200, PHASE: 'B', DISC: 'Electrical', TOTAL: 12, ACTUAL: 0, REMAIN: 12, PROG: 0, },
{ SYSTEM: 3200, PHASE: 'B', DISC: 'Instrument', TOTAL: 113, ACTUAL: 0, REMAIN: 113, PROG: 0, },
{ SYSTEM: 3200, PHASE: 'PA', DISC: 'Piping', TOTAL: 4, ACTUAL: 2, REMAIN: 2, PROG: 50, },
{ SYSTEM: 3200, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 160, ACTUAL: 147, REMAIN: 13, PROG: 91.88, },
{ SYSTEM: 3200, PHASE: 'PA', DISC: 'Electrical', TOTAL: 28, ACTUAL: 16, REMAIN: 12, PROG: 57.14, },
{ SYSTEM: 3200, PHASE: 'PA', DISC: 'Instrument', TOTAL: 17, ACTUAL: 10, REMAIN: 7, PROG: 58.82, },
{ SYSTEM: 3200, PHASE: 'PB', DISC: 'Insulation', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 3200, PHASE: 'PB', DISC: 'Piping', TOTAL: 57, ACTUAL: 45, REMAIN: 12, PROG: 78.95, },
{ SYSTEM: 3200, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 135, ACTUAL: 128, REMAIN: 7, PROG: 94.81, },
{ SYSTEM: 3200, PHASE: 'PB', DISC: 'Electrical', TOTAL: 42, ACTUAL: 39, REMAIN: 3, PROG: 92.86, },
{ SYSTEM: 3200, PHASE: 'PB', DISC: 'Instrument', TOTAL: 34, ACTUAL: 29, REMAIN: 5, PROG: 85.29, },
{ SYSTEM: 3200, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 35, ACTUAL: 26, REMAIN: 9, PROG: 74.29, },
{ SYSTEM: 3200, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 35, ACTUAL: 26, REMAIN: 9, PROG: 74.29, },
{ SYSTEM: 3200, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 35, ACTUAL: 25, REMAIN: 10, PROG: 71.43, },
{ SYSTEM: 3200, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 35, ACTUAL: 25, REMAIN: 10, PROG: 71.43, },
{ SYSTEM: 3200, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 35, ACTUAL: 24, REMAIN: 11, PROG: 68.57, },
{ SYSTEM: 3200, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 35, ACTUAL: 21, REMAIN: 14, PROG: 60, },
{ SYSTEM: 3200, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 35, ACTUAL: 14, REMAIN: 21, PROG: 40, },
{ SYSTEM: 3400, PHASE: 'A', DISC: 'Piping', TOTAL: 101, ACTUAL: 26, REMAIN: 75, PROG: 25.74, },
{ SYSTEM: 3400, PHASE: 'A', DISC: 'Mechanical', TOTAL: 9, ACTUAL: 1, REMAIN: 8, PROG: 11.11, },
{ SYSTEM: 3400, PHASE: 'A', DISC: 'Electrical', TOTAL: 43, ACTUAL: 4, REMAIN: 39, PROG: 9.3, },
{ SYSTEM: 3400, PHASE: 'A', DISC: 'Instrument', TOTAL: 449, ACTUAL: 132, REMAIN: 317, PROG: 29.4, },
{ SYSTEM: 3400, PHASE: 'B', DISC: 'Piping', TOTAL: 6, ACTUAL: 0, REMAIN: 6, PROG: 0, },
{ SYSTEM: 3400, PHASE: 'B', DISC: 'Mechanical', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 3400, PHASE: 'B', DISC: 'Electrical', TOTAL: 10, ACTUAL: 0, REMAIN: 10, PROG: 0, },
{ SYSTEM: 3400, PHASE: 'B', DISC: 'Instrument', TOTAL: 82, ACTUAL: 0, REMAIN: 82, PROG: 0, },
{ SYSTEM: 3400, PHASE: 'PA', DISC: 'Insulation', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 3400, PHASE: 'PA', DISC: 'Piping', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 3400, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 21, ACTUAL: 9, REMAIN: 12, PROG: 42.86, },
{ SYSTEM: 3400, PHASE: 'PA', DISC: 'Electrical', TOTAL: 24, ACTUAL: 15, REMAIN: 9, PROG: 62.5, },
{ SYSTEM: 3400, PHASE: 'PA', DISC: 'Instrument', TOTAL: 6, ACTUAL: 6, REMAIN: 0, PROG: 100, },
{ SYSTEM: 3400, PHASE: 'PB', DISC: 'Piping', TOTAL: 29, ACTUAL: 25, REMAIN: 4, PROG: 86.21, },
{ SYSTEM: 3400, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 26, ACTUAL: 13, REMAIN: 13, PROG: 50, },
{ SYSTEM: 3400, PHASE: 'PB', DISC: 'Electrical', TOTAL: 19, ACTUAL: 13, REMAIN: 6, PROG: 68.42, },
{ SYSTEM: 3400, PHASE: 'PB', DISC: 'Instrument', TOTAL: 24, ACTUAL: 24, REMAIN: 0, PROG: 100, },
{ SYSTEM: 3400, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 16, ACTUAL: 5, REMAIN: 11, PROG: 31.25, },
{ SYSTEM: 3400, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 16, ACTUAL: 5, REMAIN: 11, PROG: 31.25, },
{ SYSTEM: 3400, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 16, ACTUAL: 5, REMAIN: 11, PROG: 31.25, },
{ SYSTEM: 3400, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 16, ACTUAL: 5, REMAIN: 11, PROG: 31.25, },
{ SYSTEM: 3400, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 16, ACTUAL: 5, REMAIN: 11, PROG: 31.25, },
{ SYSTEM: 3400, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 16, ACTUAL: 4, REMAIN: 12, PROG: 25, },
{ SYSTEM: 3400, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 16, ACTUAL: 2, REMAIN: 14, PROG: 12.5, },
{ SYSTEM: 3430, PHASE: 'A', DISC: 'Piping', TOTAL: 77, ACTUAL: 13, REMAIN: 64, PROG: 16.88, },
{ SYSTEM: 3430, PHASE: 'A', DISC: 'Mechanical', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 3430, PHASE: 'A', DISC: 'Electrical', TOTAL: 3, ACTUAL: 0, REMAIN: 3, PROG: 0, },
{ SYSTEM: 3430, PHASE: 'A', DISC: 'Instrument', TOTAL: 105, ACTUAL: 27, REMAIN: 78, PROG: 25.71, },
{ SYSTEM: 3430, PHASE: 'B', DISC: 'Piping', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 3430, PHASE: 'B', DISC: 'Mechanical', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 3430, PHASE: 'B', DISC: 'Electrical', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 3430, PHASE: 'B', DISC: 'Instrument', TOTAL: 20, ACTUAL: 0, REMAIN: 20, PROG: 0, },
{ SYSTEM: 3430, PHASE: 'PA', DISC: 'Electrical', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 3430, PHASE: 'PB', DISC: 'Piping', TOTAL: 12, ACTUAL: 12, REMAIN: 0, PROG: 100, },
{ SYSTEM: 3430, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 5, ACTUAL: 5, REMAIN: 0, PROG: 100, },
{ SYSTEM: 3430, PHASE: 'PB', DISC: 'Instrument', TOTAL: 9, ACTUAL: 3, REMAIN: 6, PROG: 33.33, },
{ SYSTEM: 3430, PHASE: 'PB', DISC: 'Telecom', TOTAL: 11, ACTUAL: 0, REMAIN: 11, PROG: 0, },
{ SYSTEM: 3430, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 12, ACTUAL: 8, REMAIN: 4, PROG: 66.67, },
{ SYSTEM: 3430, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 12, ACTUAL: 5, REMAIN: 7, PROG: 41.67, },
{ SYSTEM: 3430, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 12, ACTUAL: 4, REMAIN: 8, PROG: 33.33, },
{ SYSTEM: 3430, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 12, ACTUAL: 2, REMAIN: 10, PROG: 16.67, },
{ SYSTEM: 3430, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 12, ACTUAL: 2, REMAIN: 10, PROG: 16.67, },
{ SYSTEM: 3430, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 12, ACTUAL: 0, REMAIN: 12, PROG: 0, },
{ SYSTEM: 3430, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 12, ACTUAL: 0, REMAIN: 12, PROG: 0, },
{ SYSTEM: 3470, PHASE: 'A', DISC: 'Piping', TOTAL: 122, ACTUAL: 44, REMAIN: 78, PROG: 36.07, },
{ SYSTEM: 3470, PHASE: 'A', DISC: 'Mechanical', TOTAL: 4, ACTUAL: 3, REMAIN: 1, PROG: 75, },
{ SYSTEM: 3470, PHASE: 'A', DISC: 'Instrument', TOTAL: 153, ACTUAL: 25, REMAIN: 128, PROG: 16.34, },
{ SYSTEM: 3470, PHASE: 'B', DISC: 'Piping', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 3470, PHASE: 'B', DISC: 'Mechanical', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 3470, PHASE: 'B', DISC: 'Instrument', TOTAL: 24, ACTUAL: 0, REMAIN: 24, PROG: 0, },
{ SYSTEM: 3470, PHASE: 'PA', DISC: 'Piping', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 3470, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 3470, PHASE: 'PA', DISC: 'Electrical', TOTAL: 3, ACTUAL: 3, REMAIN: 0, PROG: 100, },
{ SYSTEM: 3470, PHASE: 'PA', DISC: 'Instrument', TOTAL: 5, ACTUAL: 3, REMAIN: 2, PROG: 60, },
{ SYSTEM: 3470, PHASE: 'PB', DISC: 'Piping', TOTAL: 18, ACTUAL: 17, REMAIN: 1, PROG: 94.44, },
{ SYSTEM: 3470, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 3, ACTUAL: 3, REMAIN: 0, PROG: 100, },
{ SYSTEM: 3470, PHASE: 'PB', DISC: 'Instrument', TOTAL: 13, ACTUAL: 6, REMAIN: 7, PROG: 46.15, },
{ SYSTEM: 3470, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 20, ACTUAL: 13, REMAIN: 7, PROG: 65, },
{ SYSTEM: 3470, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 20, ACTUAL: 13, REMAIN: 7, PROG: 65, },
{ SYSTEM: 3470, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 20, ACTUAL: 12, REMAIN: 8, PROG: 60, },
{ SYSTEM: 3470, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 20, ACTUAL: 9, REMAIN: 11, PROG: 45, },
{ SYSTEM: 3470, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 20, ACTUAL: 7, REMAIN: 13, PROG: 35, },
{ SYSTEM: 3470, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 20, ACTUAL: 2, REMAIN: 18, PROG: 10, },
{ SYSTEM: 3470, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 20, ACTUAL: 1, REMAIN: 19, PROG: 5, },
{ SYSTEM: 4100, PHASE: 'A', DISC: 'Piping', TOTAL: 264, ACTUAL: 94, REMAIN: 170, PROG: 35.61, },
{ SYSTEM: 4100, PHASE: 'A', DISC: 'Mechanical', TOTAL: 48, ACTUAL: 20, REMAIN: 28, PROG: 41.67, },
{ SYSTEM: 4100, PHASE: 'A', DISC: 'Electrical', TOTAL: 30, ACTUAL: 6, REMAIN: 24, PROG: 20, },
{ SYSTEM: 4100, PHASE: 'A', DISC: 'Instrument', TOTAL: 580, ACTUAL: 170, REMAIN: 410, PROG: 29.31, },
{ SYSTEM: 4100, PHASE: 'B', DISC: 'Piping', TOTAL: 12, ACTUAL: 0, REMAIN: 12, PROG: 0, },
{ SYSTEM: 4100, PHASE: 'B', DISC: 'Mechanical', TOTAL: 15, ACTUAL: 0, REMAIN: 15, PROG: 0, },
{ SYSTEM: 4100, PHASE: 'B', DISC: 'Electrical', TOTAL: 13, ACTUAL: 0, REMAIN: 13, PROG: 0, },
{ SYSTEM: 4100, PHASE: 'B', DISC: 'Instrument', TOTAL: 76, ACTUAL: 0, REMAIN: 76, PROG: 0, },
{ SYSTEM: 4100, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 55, ACTUAL: 48, REMAIN: 7, PROG: 87.27, },
{ SYSTEM: 4100, PHASE: 'PA', DISC: 'Electrical', TOTAL: 7, ACTUAL: 6, REMAIN: 1, PROG: 85.71, },
{ SYSTEM: 4100, PHASE: 'PA', DISC: 'Instrument', TOTAL: 24, ACTUAL: 23, REMAIN: 1, PROG: 95.83, },
{ SYSTEM: 4100, PHASE: 'PB', DISC: 'Company', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 4100, PHASE: 'PB', DISC: 'Piping', TOTAL: 61, ACTUAL: 61, REMAIN: 0, PROG: 100, },
{ SYSTEM: 4100, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 67, ACTUAL: 55, REMAIN: 12, PROG: 82.09, },
{ SYSTEM: 4100, PHASE: 'PB', DISC: 'Electrical', TOTAL: 14, ACTUAL: 14, REMAIN: 0, PROG: 100, },
{ SYSTEM: 4100, PHASE: 'PB', DISC: 'Instrument', TOTAL: 31, ACTUAL: 26, REMAIN: 5, PROG: 83.87, },
{ SYSTEM: 4100, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 42, ACTUAL: 25, REMAIN: 17, PROG: 59.52, },
{ SYSTEM: 4100, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 42, ACTUAL: 22, REMAIN: 20, PROG: 52.38, },
{ SYSTEM: 4100, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 42, ACTUAL: 17, REMAIN: 25, PROG: 40.48, },
{ SYSTEM: 4100, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 42, ACTUAL: 17, REMAIN: 25, PROG: 40.48, },
{ SYSTEM: 4100, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 42, ACTUAL: 16, REMAIN: 26, PROG: 38.1, },
{ SYSTEM: 4100, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 42, ACTUAL: 14, REMAIN: 28, PROG: 33.33, },
{ SYSTEM: 4100, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 42, ACTUAL: 8, REMAIN: 34, PROG: 19.05, },
{ SYSTEM: 4330, PHASE: 'A', DISC: 'Piping', TOTAL: 2654, ACTUAL: 1660, REMAIN: 994, PROG: 62.55, },
{ SYSTEM: 4330, PHASE: 'A', DISC: 'Mechanical', TOTAL: 38, ACTUAL: 30, REMAIN: 8, PROG: 78.95, },
{ SYSTEM: 4330, PHASE: 'A', DISC: 'Electrical', TOTAL: 64, ACTUAL: 16, REMAIN: 48, PROG: 25, },
{ SYSTEM: 4330, PHASE: 'A', DISC: 'Instrument', TOTAL: 231, ACTUAL: 45, REMAIN: 186, PROG: 19.48, },
{ SYSTEM: 4330, PHASE: 'B', DISC: 'Mechanical', TOTAL: 12, ACTUAL: 0, REMAIN: 12, PROG: 0, },
{ SYSTEM: 4330, PHASE: 'B', DISC: 'Electrical', TOTAL: 24, ACTUAL: 0, REMAIN: 24, PROG: 0, },
{ SYSTEM: 4330, PHASE: 'B', DISC: 'Instrument', TOTAL: 67, ACTUAL: 0, REMAIN: 67, PROG: 0, },
{ SYSTEM: 4330, PHASE: 'PA', DISC: 'Piping', TOTAL: 78, ACTUAL: 63, REMAIN: 15, PROG: 80.77, },
{ SYSTEM: 4330, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 13, ACTUAL: 9, REMAIN: 4, PROG: 69.23, },
{ SYSTEM: 4330, PHASE: 'PA', DISC: 'Electrical', TOTAL: 17, ACTUAL: 16, REMAIN: 1, PROG: 94.12, },
{ SYSTEM: 4330, PHASE: 'PA', DISC: 'Instrument', TOTAL: 14, ACTUAL: 10, REMAIN: 4, PROG: 71.43, },
{ SYSTEM: 4330, PHASE: 'PB', DISC: 'Company', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 4330, PHASE: 'PB', DISC: 'Piping', TOTAL: 240, ACTUAL: 147, REMAIN: 93, PROG: 61.25, },
{ SYSTEM: 4330, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 40, ACTUAL: 37, REMAIN: 3, PROG: 92.5, },
{ SYSTEM: 4330, PHASE: 'PB', DISC: 'Electrical', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 4330, PHASE: 'PB', DISC: 'Instrument', TOTAL: 40, ACTUAL: 35, REMAIN: 5, PROG: 87.5, },
{ SYSTEM: 4330, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 439, ACTUAL: 376, REMAIN: 63, PROG: 85.65, },
{ SYSTEM: 4330, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 439, ACTUAL: 370, REMAIN: 69, PROG: 84.28, },
{ SYSTEM: 4330, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 439, ACTUAL: 287, REMAIN: 152, PROG: 65.38, },
{ SYSTEM: 4330, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 439, ACTUAL: 287, REMAIN: 152, PROG: 65.38, },
{ SYSTEM: 4330, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 439, ACTUAL: 284, REMAIN: 155, PROG: 64.69, },
{ SYSTEM: 4330, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 439, ACTUAL: 254, REMAIN: 185, PROG: 57.86, },
{ SYSTEM: 4330, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 439, ACTUAL: 178, REMAIN: 261, PROG: 40.55, },
{ SYSTEM: 4340, PHASE: 'A', DISC: 'Piping', TOTAL: 696, ACTUAL: 270, REMAIN: 426, PROG: 38.79, },
{ SYSTEM: 4340, PHASE: 'A', DISC: 'Mechanical', TOTAL: 35, ACTUAL: 18, REMAIN: 17, PROG: 51.43, },
{ SYSTEM: 4340, PHASE: 'A', DISC: 'Electrical', TOTAL: 61, ACTUAL: 37, REMAIN: 24, PROG: 60.66, },
{ SYSTEM: 4340, PHASE: 'A', DISC: 'Instrument', TOTAL: 286, ACTUAL: 97, REMAIN: 189, PROG: 33.92, },
{ SYSTEM: 4340, PHASE: 'B', DISC: 'Piping', TOTAL: 6, ACTUAL: 0, REMAIN: 6, PROG: 0, },
{ SYSTEM: 4340, PHASE: 'B', DISC: 'Mechanical', TOTAL: 19, ACTUAL: 0, REMAIN: 19, PROG: 0, },
{ SYSTEM: 4340, PHASE: 'B', DISC: 'Electrical', TOTAL: 21, ACTUAL: 0, REMAIN: 21, PROG: 0, },
{ SYSTEM: 4340, PHASE: 'B', DISC: 'Instrument', TOTAL: 41, ACTUAL: 0, REMAIN: 41, PROG: 0, },
{ SYSTEM: 4340, PHASE: 'PA', DISC: 'Insulation', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 4340, PHASE: 'PA', DISC: 'Piping', TOTAL: 7, ACTUAL: 7, REMAIN: 0, PROG: 100, },
{ SYSTEM: 4340, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 98, ACTUAL: 79, REMAIN: 19, PROG: 80.61, },
{ SYSTEM: 4340, PHASE: 'PA', DISC: 'Electrical', TOTAL: 15, ACTUAL: 14, REMAIN: 1, PROG: 93.33, },
{ SYSTEM: 4340, PHASE: 'PA', DISC: 'Instrument', TOTAL: 14, ACTUAL: 14, REMAIN: 0, PROG: 100, },
{ SYSTEM: 4340, PHASE: 'PB', DISC: 'Company', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 4340, PHASE: 'PB', DISC: 'Piping', TOTAL: 84, ACTUAL: 80, REMAIN: 4, PROG: 95.24, },
{ SYSTEM: 4340, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 53, ACTUAL: 40, REMAIN: 13, PROG: 75.47, },
{ SYSTEM: 4340, PHASE: 'PB', DISC: 'Electrical', TOTAL: 14, ACTUAL: 13, REMAIN: 1, PROG: 92.86, },
{ SYSTEM: 4340, PHASE: 'PB', DISC: 'Instrument', TOTAL: 16, ACTUAL: 16, REMAIN: 0, PROG: 100, },
{ SYSTEM: 4340, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 115, ACTUAL: 85, REMAIN: 30, PROG: 73.91, },
{ SYSTEM: 4340, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 115, ACTUAL: 80, REMAIN: 35, PROG: 69.57, },
{ SYSTEM: 4340, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 115, ACTUAL: 51, REMAIN: 64, PROG: 44.35, },
{ SYSTEM: 4340, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 115, ACTUAL: 48, REMAIN: 67, PROG: 41.74, },
{ SYSTEM: 4340, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 115, ACTUAL: 40, REMAIN: 75, PROG: 34.78, },
{ SYSTEM: 4340, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 115, ACTUAL: 32, REMAIN: 83, PROG: 27.83, },
{ SYSTEM: 4340, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 115, ACTUAL: 19, REMAIN: 96, PROG: 16.52, },
{ SYSTEM: 4610, PHASE: 'A', DISC: 'Piping', TOTAL: 140, ACTUAL: 123, REMAIN: 17, PROG: 87.86, },
{ SYSTEM: 4610, PHASE: 'A', DISC: 'Mechanical', TOTAL: 37, ACTUAL: 21, REMAIN: 16, PROG: 56.76, },
{ SYSTEM: 4610, PHASE: 'A', DISC: 'Electrical', TOTAL: 67, ACTUAL: 48, REMAIN: 19, PROG: 71.64, },
{ SYSTEM: 4610, PHASE: 'A', DISC: 'Instrument', TOTAL: 345, ACTUAL: 189, REMAIN: 156, PROG: 54.78, },
{ SYSTEM: 4610, PHASE: 'B', DISC: 'Piping', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 4610, PHASE: 'B', DISC: 'Mechanical', TOTAL: 16, ACTUAL: 0, REMAIN: 16, PROG: 0, },
{ SYSTEM: 4610, PHASE: 'B', DISC: 'Electrical', TOTAL: 20, ACTUAL: 0, REMAIN: 20, PROG: 0, },
{ SYSTEM: 4610, PHASE: 'B', DISC: 'Instrument', TOTAL: 45, ACTUAL: 0, REMAIN: 45, PROG: 0, },
{ SYSTEM: 4610, PHASE: 'PA', DISC: 'Piping', TOTAL: 14, ACTUAL: 8, REMAIN: 6, PROG: 57.14, },
{ SYSTEM: 4610, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 106, ACTUAL: 93, REMAIN: 13, PROG: 87.74, },
{ SYSTEM: 4610, PHASE: 'PA', DISC: 'Electrical', TOTAL: 30, ACTUAL: 27, REMAIN: 3, PROG: 90, },
{ SYSTEM: 4610, PHASE: 'PA', DISC: 'Instrument', TOTAL: 17, ACTUAL: 15, REMAIN: 2, PROG: 88.24, },
{ SYSTEM: 4610, PHASE: 'PB', DISC: 'Piping', TOTAL: 55, ACTUAL: 44, REMAIN: 11, PROG: 80, },
{ SYSTEM: 4610, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 61, ACTUAL: 54, REMAIN: 7, PROG: 88.52, },
{ SYSTEM: 4610, PHASE: 'PB', DISC: 'Electrical', TOTAL: 21, ACTUAL: 18, REMAIN: 3, PROG: 85.71, },
{ SYSTEM: 4610, PHASE: 'PB', DISC: 'Instrument', TOTAL: 39, ACTUAL: 32, REMAIN: 7, PROG: 82.05, },
{ SYSTEM: 4610, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 23, ACTUAL: 23, REMAIN: 0, PROG: 100, },
{ SYSTEM: 4610, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 23, ACTUAL: 23, REMAIN: 0, PROG: 100, },
{ SYSTEM: 4610, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 23, ACTUAL: 23, REMAIN: 0, PROG: 100, },
{ SYSTEM: 4610, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 23, ACTUAL: 23, REMAIN: 0, PROG: 100, },
{ SYSTEM: 4610, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 23, ACTUAL: 22, REMAIN: 1, PROG: 95.65, },
{ SYSTEM: 4610, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 23, ACTUAL: 20, REMAIN: 3, PROG: 86.96, },
{ SYSTEM: 4610, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 23, ACTUAL: 12, REMAIN: 11, PROG: 52.17, },
{ SYSTEM: 4620, PHASE: 'A', DISC: 'Piping', TOTAL: 264, ACTUAL: 172, REMAIN: 92, PROG: 65.15, },
{ SYSTEM: 4620, PHASE: 'A', DISC: 'Mechanical', TOTAL: 45, ACTUAL: 16, REMAIN: 29, PROG: 35.56, },
{ SYSTEM: 4620, PHASE: 'A', DISC: 'Electrical', TOTAL: 124, ACTUAL: 34, REMAIN: 90, PROG: 27.42, },
{ SYSTEM: 4620, PHASE: 'A', DISC: 'Instrument', TOTAL: 1454, ACTUAL: 1139, REMAIN: 315, PROG: 78.34, },
{ SYSTEM: 4620, PHASE: 'B', DISC: 'Piping', TOTAL: 5, ACTUAL: 0, REMAIN: 5, PROG: 0, },
{ SYSTEM: 4620, PHASE: 'B', DISC: 'Mechanical', TOTAL: 21, ACTUAL: 0, REMAIN: 21, PROG: 0, },
{ SYSTEM: 4620, PHASE: 'B', DISC: 'Electrical', TOTAL: 46, ACTUAL: 0, REMAIN: 46, PROG: 0, },
{ SYSTEM: 4620, PHASE: 'B', DISC: 'Instrument', TOTAL: 281, ACTUAL: 0, REMAIN: 281, PROG: 0, },
{ SYSTEM: 4620, PHASE: 'PA', DISC: 'Piping', TOTAL: 25, ACTUAL: 11, REMAIN: 14, PROG: 44, },
{ SYSTEM: 4620, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 59, ACTUAL: 31, REMAIN: 28, PROG: 52.54, },
{ SYSTEM: 4620, PHASE: 'PA', DISC: 'Electrical', TOTAL: 137, ACTUAL: 93, REMAIN: 44, PROG: 67.88, },
{ SYSTEM: 4620, PHASE: 'PA', DISC: 'Instrument', TOTAL: 804, ACTUAL: 576, REMAIN: 228, PROG: 71.64, },
{ SYSTEM: 4620, PHASE: 'PB', DISC: 'Company', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 4620, PHASE: 'PB', DISC: 'Piping', TOTAL: 74, ACTUAL: 55, REMAIN: 19, PROG: 74.32, },
{ SYSTEM: 4620, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 104, ACTUAL: 63, REMAIN: 41, PROG: 60.58, },
{ SYSTEM: 4620, PHASE: 'PB', DISC: 'Electrical', TOTAL: 74, ACTUAL: 42, REMAIN: 32, PROG: 56.76, },
{ SYSTEM: 4620, PHASE: 'PB', DISC: 'Instrument', TOTAL: 188, ACTUAL: 116, REMAIN: 72, PROG: 61.7, },
{ SYSTEM: 4620, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 43, ACTUAL: 39, REMAIN: 4, PROG: 90.7, },
{ SYSTEM: 4620, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 43, ACTUAL: 39, REMAIN: 4, PROG: 90.7, },
{ SYSTEM: 4620, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 43, ACTUAL: 33, REMAIN: 10, PROG: 76.74, },
{ SYSTEM: 4620, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 43, ACTUAL: 33, REMAIN: 10, PROG: 76.74, },
{ SYSTEM: 4620, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 43, ACTUAL: 29, REMAIN: 14, PROG: 67.44, },
{ SYSTEM: 4620, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 43, ACTUAL: 26, REMAIN: 17, PROG: 60.47, },
{ SYSTEM: 4620, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 43, ACTUAL: 12, REMAIN: 31, PROG: 27.91, },
{ SYSTEM: 4640, PHASE: 'A', DISC: 'Piping', TOTAL: 20, ACTUAL: 15, REMAIN: 5, PROG: 75, },
{ SYSTEM: 4640, PHASE: 'A', DISC: 'Mechanical', TOTAL: 10, ACTUAL: 8, REMAIN: 2, PROG: 80, },
{ SYSTEM: 4640, PHASE: 'A', DISC: 'Electrical', TOTAL: 123, ACTUAL: 114, REMAIN: 9, PROG: 92.68, },
{ SYSTEM: 4640, PHASE: 'A', DISC: 'Instrument', TOTAL: 627, ACTUAL: 553, REMAIN: 74, PROG: 88.2, },
{ SYSTEM: 4640, PHASE: 'B', DISC: 'Piping', TOTAL: 3, ACTUAL: 0, REMAIN: 3, PROG: 0, },
{ SYSTEM: 4640, PHASE: 'B', DISC: 'Electrical', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 4640, PHASE: 'B', DISC: 'Instrument', TOTAL: 79, ACTUAL: 0, REMAIN: 79, PROG: 0, },
{ SYSTEM: 4640, PHASE: 'PA', DISC: 'Piping', TOTAL: 3, ACTUAL: 0, REMAIN: 3, PROG: 0, },
{ SYSTEM: 4640, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 23, ACTUAL: 13, REMAIN: 10, PROG: 56.52, },
{ SYSTEM: 4640, PHASE: 'PA', DISC: 'Instrument', TOTAL: 5, ACTUAL: 3, REMAIN: 2, PROG: 60, },
{ SYSTEM: 4640, PHASE: 'PB', DISC: 'Piping', TOTAL: 15, ACTUAL: 15, REMAIN: 0, PROG: 100, },
{ SYSTEM: 4640, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 20, ACTUAL: 19, REMAIN: 1, PROG: 95, },
{ SYSTEM: 4640, PHASE: 'PB', DISC: 'Electrical', TOTAL: 12, ACTUAL: 8, REMAIN: 4, PROG: 66.67, },
{ SYSTEM: 4640, PHASE: 'PB', DISC: 'Instrument', TOTAL: 33, ACTUAL: 29, REMAIN: 4, PROG: 87.88, },
{ SYSTEM: 4640, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 3, ACTUAL: 3, REMAIN: 0, PROG: 100, },
{ SYSTEM: 4640, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 3, ACTUAL: 3, REMAIN: 0, PROG: 100, },
{ SYSTEM: 4640, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 3, ACTUAL: 3, REMAIN: 0, PROG: 100, },
{ SYSTEM: 4640, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 3, ACTUAL: 3, REMAIN: 0, PROG: 100, },
{ SYSTEM: 4640, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 3, ACTUAL: 2, REMAIN: 1, PROG: 66.67, },
{ SYSTEM: 4640, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 3, ACTUAL: 2, REMAIN: 1, PROG: 66.67, },
{ SYSTEM: 4640, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 3, ACTUAL: 2, REMAIN: 1, PROG: 66.67, },
{ SYSTEM: 4650, PHASE: 'A', DISC: 'Piping', TOTAL: 44, ACTUAL: 40, REMAIN: 4, PROG: 90.91, },
{ SYSTEM: 4650, PHASE: 'A', DISC: 'Mechanical', TOTAL: 10, ACTUAL: 9, REMAIN: 1, PROG: 90, },
{ SYSTEM: 4650, PHASE: 'A', DISC: 'Electrical', TOTAL: 28, ACTUAL: 22, REMAIN: 6, PROG: 78.57, },
{ SYSTEM: 4650, PHASE: 'A', DISC: 'Instrument', TOTAL: 214, ACTUAL: 152, REMAIN: 62, PROG: 71.03, },
{ SYSTEM: 4650, PHASE: 'B', DISC: 'Piping', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 4650, PHASE: 'B', DISC: 'Mechanical', TOTAL: 3, ACTUAL: 0, REMAIN: 3, PROG: 0, },
{ SYSTEM: 4650, PHASE: 'B', DISC: 'Electrical', TOTAL: 10, ACTUAL: 0, REMAIN: 10, PROG: 0, },
{ SYSTEM: 4650, PHASE: 'B', DISC: 'Instrument', TOTAL: 30, ACTUAL: 0, REMAIN: 30, PROG: 0, },
{ SYSTEM: 4650, PHASE: 'PA', DISC: 'Piping', TOTAL: 6, ACTUAL: 6, REMAIN: 0, PROG: 100, },
{ SYSTEM: 4650, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 5, ACTUAL: 3, REMAIN: 2, PROG: 60, },
{ SYSTEM: 4650, PHASE: 'PA', DISC: 'Electrical', TOTAL: 7, ACTUAL: 5, REMAIN: 2, PROG: 71.43, },
{ SYSTEM: 4650, PHASE: 'PA', DISC: 'Instrument', TOTAL: 10, ACTUAL: 8, REMAIN: 2, PROG: 80, },
{ SYSTEM: 4650, PHASE: 'PB', DISC: 'Piping', TOTAL: 16, ACTUAL: 13, REMAIN: 3, PROG: 81.25, },
{ SYSTEM: 4650, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 27, ACTUAL: 16, REMAIN: 11, PROG: 59.26, },
{ SYSTEM: 4650, PHASE: 'PB', DISC: 'Electrical', TOTAL: 19, ACTUAL: 11, REMAIN: 8, PROG: 57.89, },
{ SYSTEM: 4650, PHASE: 'PB', DISC: 'Instrument', TOTAL: 29, ACTUAL: 21, REMAIN: 8, PROG: 72.41, },
{ SYSTEM: 4650, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 7, ACTUAL: 7, REMAIN: 0, PROG: 100, },
{ SYSTEM: 4650, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 7, ACTUAL: 7, REMAIN: 0, PROG: 100, },
{ SYSTEM: 4650, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 7, ACTUAL: 7, REMAIN: 0, PROG: 100, },
{ SYSTEM: 4650, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 7, ACTUAL: 7, REMAIN: 0, PROG: 100, },
{ SYSTEM: 4650, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 7, ACTUAL: 7, REMAIN: 0, PROG: 100, },
{ SYSTEM: 4650, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 7, ACTUAL: 7, REMAIN: 0, PROG: 100, },
{ SYSTEM: 4650, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 7, ACTUAL: 5, REMAIN: 2, PROG: 71.43, },
{ SYSTEM: 4730, PHASE: 'A', DISC: 'Piping', TOTAL: 474, ACTUAL: 319, REMAIN: 155, PROG: 67.3, },
{ SYSTEM: 4730, PHASE: 'A', DISC: 'Mechanical', TOTAL: 17, ACTUAL: 5, REMAIN: 12, PROG: 29.41, },
{ SYSTEM: 4730, PHASE: 'A', DISC: 'Electrical', TOTAL: 237, ACTUAL: 146, REMAIN: 91, PROG: 61.6, },
{ SYSTEM: 4730, PHASE: 'A', DISC: 'Instrument', TOTAL: 617, ACTUAL: 285, REMAIN: 332, PROG: 46.19, },
{ SYSTEM: 4730, PHASE: 'A', DISC: 'Telecom', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 4730, PHASE: 'B', DISC: 'Piping', TOTAL: 10, ACTUAL: 0, REMAIN: 10, PROG: 0, },
{ SYSTEM: 4730, PHASE: 'B', DISC: 'Mechanical', TOTAL: 32, ACTUAL: 0, REMAIN: 32, PROG: 0, },
{ SYSTEM: 4730, PHASE: 'B', DISC: 'Electrical', TOTAL: 84, ACTUAL: 2, REMAIN: 82, PROG: 2.38, },
{ SYSTEM: 4730, PHASE: 'B', DISC: 'Instrument', TOTAL: 395, ACTUAL: 252, REMAIN: 143, PROG: 63.8, },
{ SYSTEM: 4730, PHASE: 'PA', DISC: 'Piping', TOTAL: 31, ACTUAL: 25, REMAIN: 6, PROG: 80.65, },
{ SYSTEM: 4730, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 16, ACTUAL: 11, REMAIN: 5, PROG: 68.75, },
{ SYSTEM: 4730, PHASE: 'PA', DISC: 'Electrical', TOTAL: 167, ACTUAL: 121, REMAIN: 46, PROG: 72.46, },
{ SYSTEM: 4730, PHASE: 'PA', DISC: 'Instrument', TOTAL: 1112, ACTUAL: 586, REMAIN: 526, PROG: 52.7, },
{ SYSTEM: 4730, PHASE: 'PB', DISC: 'Company', TOTAL: 2, ACTUAL: 1, REMAIN: 1, PROG: 50, },
{ SYSTEM: 4730, PHASE: 'PB', DISC: 'Piping', TOTAL: 125, ACTUAL: 104, REMAIN: 21, PROG: 83.2, },
{ SYSTEM: 4730, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 69, ACTUAL: 27, REMAIN: 42, PROG: 39.13, },
{ SYSTEM: 4730, PHASE: 'PB', DISC: 'Electrical', TOTAL: 130, ACTUAL: 91, REMAIN: 39, PROG: 70, },
{ SYSTEM: 4730, PHASE: 'PB', DISC: 'Instrument', TOTAL: 451, ACTUAL: 347, REMAIN: 104, PROG: 76.94, },
{ SYSTEM: 4730, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 77, ACTUAL: 64, REMAIN: 13, PROG: 83.12, },
{ SYSTEM: 4730, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 77, ACTUAL: 63, REMAIN: 14, PROG: 81.82, },
{ SYSTEM: 4730, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 77, ACTUAL: 54, REMAIN: 23, PROG: 70.13, },
{ SYSTEM: 4730, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 77, ACTUAL: 53, REMAIN: 24, PROG: 68.83, },
{ SYSTEM: 4730, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 77, ACTUAL: 53, REMAIN: 24, PROG: 68.83, },
{ SYSTEM: 4730, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 77, ACTUAL: 51, REMAIN: 26, PROG: 66.23, },
{ SYSTEM: 4730, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 77, ACTUAL: 43, REMAIN: 34, PROG: 55.84, },
{ SYSTEM: 5010, PHASE: 'A', DISC: 'Piping', TOTAL: 320, ACTUAL: 150, REMAIN: 170, PROG: 46.88, },
{ SYSTEM: 5010, PHASE: 'A', DISC: 'Mechanical', TOTAL: 16, ACTUAL: 7, REMAIN: 9, PROG: 43.75, },
{ SYSTEM: 5010, PHASE: 'A', DISC: 'Electrical', TOTAL: 35, ACTUAL: 31, REMAIN: 4, PROG: 88.57, },
{ SYSTEM: 5010, PHASE: 'A', DISC: 'Instrument', TOTAL: 355, ACTUAL: 144, REMAIN: 211, PROG: 40.56, },
{ SYSTEM: 5010, PHASE: 'B', DISC: 'Piping', TOTAL: 5, ACTUAL: 0, REMAIN: 5, PROG: 0, },
{ SYSTEM: 5010, PHASE: 'B', DISC: 'Mechanical', TOTAL: 6, ACTUAL: 0, REMAIN: 6, PROG: 0, },
{ SYSTEM: 5010, PHASE: 'B', DISC: 'Electrical', TOTAL: 10, ACTUAL: 0, REMAIN: 10, PROG: 0, },
{ SYSTEM: 5010, PHASE: 'B', DISC: 'Instrument', TOTAL: 62, ACTUAL: 0, REMAIN: 62, PROG: 0, },
{ SYSTEM: 5010, PHASE: 'PA', DISC: 'Piping', TOTAL: 26, ACTUAL: 17, REMAIN: 9, PROG: 65.38, },
{ SYSTEM: 5010, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 36, ACTUAL: 34, REMAIN: 2, PROG: 94.44, },
{ SYSTEM: 5010, PHASE: 'PA', DISC: 'Electrical', TOTAL: 7, ACTUAL: 4, REMAIN: 3, PROG: 57.14, },
{ SYSTEM: 5010, PHASE: 'PA', DISC: 'Instrument', TOTAL: 17, ACTUAL: 15, REMAIN: 2, PROG: 88.24, },
{ SYSTEM: 5010, PHASE: 'PB', DISC: 'Insulation', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 5010, PHASE: 'PB', DISC: 'Piping', TOTAL: 86, ACTUAL: 78, REMAIN: 8, PROG: 90.7, },
{ SYSTEM: 5010, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 16, ACTUAL: 12, REMAIN: 4, PROG: 75, },
{ SYSTEM: 5010, PHASE: 'PB', DISC: 'Electrical', TOTAL: 15, ACTUAL: 8, REMAIN: 7, PROG: 53.33, },
{ SYSTEM: 5010, PHASE: 'PB', DISC: 'Instrument', TOTAL: 41, ACTUAL: 32, REMAIN: 9, PROG: 78.05, },
{ SYSTEM: 5010, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 52, ACTUAL: 37, REMAIN: 15, PROG: 71.15, },
{ SYSTEM: 5010, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 52, ACTUAL: 36, REMAIN: 16, PROG: 69.23, },
{ SYSTEM: 5010, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 52, ACTUAL: 28, REMAIN: 24, PROG: 53.85, },
{ SYSTEM: 5010, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 52, ACTUAL: 28, REMAIN: 24, PROG: 53.85, },
{ SYSTEM: 5010, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 52, ACTUAL: 26, REMAIN: 26, PROG: 50, },
{ SYSTEM: 5010, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 52, ACTUAL: 20, REMAIN: 32, PROG: 38.46, },
{ SYSTEM: 5010, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 52, ACTUAL: 12, REMAIN: 40, PROG: 23.08, },
{ SYSTEM: 5030, PHASE: 'A', DISC: 'Piping', TOTAL: 130, ACTUAL: 70, REMAIN: 60, PROG: 53.85, },
{ SYSTEM: 5030, PHASE: 'A', DISC: 'Mechanical', TOTAL: 9, ACTUAL: 9, REMAIN: 0, PROG: 100, },
{ SYSTEM: 5030, PHASE: 'A', DISC: 'Electrical', TOTAL: 15, ACTUAL: 7, REMAIN: 8, PROG: 46.67, },
{ SYSTEM: 5030, PHASE: 'A', DISC: 'Instrument', TOTAL: 87, ACTUAL: 47, REMAIN: 40, PROG: 54.02, },
{ SYSTEM: 5030, PHASE: 'B', DISC: 'Electrical', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 5030, PHASE: 'B', DISC: 'Instrument', TOTAL: 26, ACTUAL: 0, REMAIN: 26, PROG: 0, },
{ SYSTEM: 5030, PHASE: 'PA', DISC: 'Piping', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 5030, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 28, ACTUAL: 25, REMAIN: 3, PROG: 89.29, },
{ SYSTEM: 5030, PHASE: 'PA', DISC: 'Electrical', TOTAL: 10, ACTUAL: 1, REMAIN: 9, PROG: 10, },
{ SYSTEM: 5030, PHASE: 'PA', DISC: 'Instrument', TOTAL: 40, ACTUAL: 28, REMAIN: 12, PROG: 70, },
{ SYSTEM: 5030, PHASE: 'PB', DISC: 'Piping', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 5030, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 12, ACTUAL: 8, REMAIN: 4, PROG: 66.67, },
{ SYSTEM: 5030, PHASE: 'PB', DISC: 'Electrical', TOTAL: 3, ACTUAL: 0, REMAIN: 3, PROG: 0, },
{ SYSTEM: 5030, PHASE: 'PB', DISC: 'Instrument', TOTAL: 12, ACTUAL: 1, REMAIN: 11, PROG: 8.33, },
{ SYSTEM: 5030, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 21, ACTUAL: 14, REMAIN: 7, PROG: 66.67, },
{ SYSTEM: 5030, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 21, ACTUAL: 13, REMAIN: 8, PROG: 61.9, },
{ SYSTEM: 5030, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 21, ACTUAL: 13, REMAIN: 8, PROG: 61.9, },
{ SYSTEM: 5030, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 21, ACTUAL: 13, REMAIN: 8, PROG: 61.9, },
{ SYSTEM: 5030, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 21, ACTUAL: 13, REMAIN: 8, PROG: 61.9, },
{ SYSTEM: 5030, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 21, ACTUAL: 9, REMAIN: 12, PROG: 42.86, },
{ SYSTEM: 5030, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 21, ACTUAL: 9, REMAIN: 12, PROG: 42.86, },
{ SYSTEM: 5210, PHASE: 'A', DISC: 'Piping', TOTAL: 197, ACTUAL: 99, REMAIN: 98, PROG: 50.25, },
{ SYSTEM: 5210, PHASE: 'A', DISC: 'Mechanical', TOTAL: 4, ACTUAL: 3, REMAIN: 1, PROG: 75, },
{ SYSTEM: 5210, PHASE: 'A', DISC: 'Instrument', TOTAL: 137, ACTUAL: 42, REMAIN: 95, PROG: 30.66, },
{ SYSTEM: 5210, PHASE: 'B', DISC: 'Piping', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 5210, PHASE: 'B', DISC: 'Instrument', TOTAL: 22, ACTUAL: 0, REMAIN: 22, PROG: 0, },
{ SYSTEM: 5210, PHASE: 'PA', DISC: 'Piping', TOTAL: 10, ACTUAL: 6, REMAIN: 4, PROG: 60, },
{ SYSTEM: 5210, PHASE: 'PA', DISC: 'Instrument', TOTAL: 4, ACTUAL: 4, REMAIN: 0, PROG: 100, },
{ SYSTEM: 5210, PHASE: 'PB', DISC: 'Piping', TOTAL: 14, ACTUAL: 7, REMAIN: 7, PROG: 50, },
{ SYSTEM: 5210, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 3, ACTUAL: 2, REMAIN: 1, PROG: 66.67, },
{ SYSTEM: 5210, PHASE: 'PB', DISC: 'Instrument', TOTAL: 12, ACTUAL: 7, REMAIN: 5, PROG: 58.33, },
{ SYSTEM: 5210, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 32, ACTUAL: 20, REMAIN: 12, PROG: 62.5, },
{ SYSTEM: 5210, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 32, ACTUAL: 22, REMAIN: 10, PROG: 68.75, },
{ SYSTEM: 5210, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 32, ACTUAL: 20, REMAIN: 12, PROG: 62.5, },
{ SYSTEM: 5210, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 32, ACTUAL: 20, REMAIN: 12, PROG: 62.5, },
{ SYSTEM: 5210, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 32, ACTUAL: 20, REMAIN: 12, PROG: 62.5, },
{ SYSTEM: 5210, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 32, ACTUAL: 11, REMAIN: 21, PROG: 34.38, },
{ SYSTEM: 5210, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 32, ACTUAL: 6, REMAIN: 26, PROG: 18.75, },
{ SYSTEM: 5410, PHASE: 'A', DISC: 'Piping', TOTAL: 212, ACTUAL: 110, REMAIN: 102, PROG: 51.89, },
{ SYSTEM: 5410, PHASE: 'A', DISC: 'Mechanical', TOTAL: 12, ACTUAL: 5, REMAIN: 7, PROG: 41.67, },
{ SYSTEM: 5410, PHASE: 'A', DISC: 'Instrument', TOTAL: 57, ACTUAL: 34, REMAIN: 23, PROG: 59.65, },
{ SYSTEM: 5410, PHASE: 'B', DISC: 'Piping', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 5410, PHASE: 'B', DISC: 'Mechanical', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 5410, PHASE: 'B', DISC: 'Instrument', TOTAL: 8, ACTUAL: 0, REMAIN: 8, PROG: 0, },
{ SYSTEM: 5410, PHASE: 'PA', DISC: 'Piping', TOTAL: 9, ACTUAL: 7, REMAIN: 2, PROG: 77.78, },
{ SYSTEM: 5410, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 21, ACTUAL: 21, REMAIN: 0, PROG: 100, },
{ SYSTEM: 5410, PHASE: 'PA', DISC: 'Electrical', TOTAL: 11, ACTUAL: 6, REMAIN: 5, PROG: 54.55, },
{ SYSTEM: 5410, PHASE: 'PA', DISC: 'Instrument', TOTAL: 7, ACTUAL: 5, REMAIN: 2, PROG: 71.43, },
{ SYSTEM: 5410, PHASE: 'PB', DISC: 'Piping', TOTAL: 12, ACTUAL: 12, REMAIN: 0, PROG: 100, },
{ SYSTEM: 5410, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 10, ACTUAL: 10, REMAIN: 0, PROG: 100, },
{ SYSTEM: 5410, PHASE: 'PB', DISC: 'Instrument', TOTAL: 2, ACTUAL: 1, REMAIN: 1, PROG: 50, },
{ SYSTEM: 5410, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 35, ACTUAL: 28, REMAIN: 7, PROG: 80, },
{ SYSTEM: 5410, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 35, ACTUAL: 27, REMAIN: 8, PROG: 77.14, },
{ SYSTEM: 5410, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 35, ACTUAL: 19, REMAIN: 16, PROG: 54.29, },
{ SYSTEM: 5410, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 35, ACTUAL: 19, REMAIN: 16, PROG: 54.29, },
{ SYSTEM: 5410, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 35, ACTUAL: 18, REMAIN: 17, PROG: 51.43, },
{ SYSTEM: 5410, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 35, ACTUAL: 16, REMAIN: 19, PROG: 45.71, },
{ SYSTEM: 5410, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 35, ACTUAL: 11, REMAIN: 24, PROG: 31.43, },
{ SYSTEM: 5555, PHASE: 'B', DISC: 'Instrument', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 6010, PHASE: 'A', DISC: 'Mechanical', TOTAL: 47, ACTUAL: 47, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6010, PHASE: 'A', DISC: 'Electrical', TOTAL: 69, ACTUAL: 55, REMAIN: 14, PROG: 79.71, },
{ SYSTEM: 6010, PHASE: 'A', DISC: 'Instrument', TOTAL: 1778, ACTUAL: 1640, REMAIN: 138, PROG: 92.24, },
{ SYSTEM: 6010, PHASE: 'A', DISC: 'Telecom', TOTAL: 56, ACTUAL: 56, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6010, PHASE: 'B', DISC: 'Electrical', TOTAL: 41, ACTUAL: 15, REMAIN: 26, PROG: 36.59, },
{ SYSTEM: 6010, PHASE: 'B', DISC: 'Instrument', TOTAL: 241, ACTUAL: 25, REMAIN: 216, PROG: 10.37, },
{ SYSTEM: 6010, PHASE: 'PA', DISC: 'Electrical', TOTAL: 20, ACTUAL: 16, REMAIN: 4, PROG: 80, },
{ SYSTEM: 6010, PHASE: 'PA', DISC: 'Instrument', TOTAL: 136, ACTUAL: 127, REMAIN: 9, PROG: 93.38, },
{ SYSTEM: 6010, PHASE: 'PA', DISC: 'Telecom', TOTAL: 31, ACTUAL: 31, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6010, PHASE: 'PB', DISC: 'Electrical', TOTAL: 21, ACTUAL: 18, REMAIN: 3, PROG: 85.71, },
{ SYSTEM: 6010, PHASE: 'PB', DISC: 'Instrument', TOTAL: 330, ACTUAL: 279, REMAIN: 51, PROG: 84.55, },
{ SYSTEM: 6010, PHASE: 'PB', DISC: 'Telecom', TOTAL: 11, ACTUAL: 6, REMAIN: 5, PROG: 54.55, },
{ SYSTEM: 6020, PHASE: 'A', DISC: 'Electrical', TOTAL: 88, ACTUAL: 87, REMAIN: 1, PROG: 98.86, },
{ SYSTEM: 6020, PHASE: 'A', DISC: 'Instrument', TOTAL: 1408, ACTUAL: 1215, REMAIN: 193, PROG: 86.29, },
{ SYSTEM: 6020, PHASE: 'A', DISC: 'Telecom', TOTAL: 4, ACTUAL: 4, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6020, PHASE: 'B', DISC: 'Electrical', TOTAL: 72, ACTUAL: 50, REMAIN: 22, PROG: 69.44, },
{ SYSTEM: 6020, PHASE: 'B', DISC: 'Instrument', TOTAL: 126, ACTUAL: 57, REMAIN: 69, PROG: 45.24, },
{ SYSTEM: 6020, PHASE: 'PA', DISC: 'Electrical', TOTAL: 21, ACTUAL: 19, REMAIN: 2, PROG: 90.48, },
{ SYSTEM: 6020, PHASE: 'PA', DISC: 'Instrument', TOTAL: 127, ACTUAL: 123, REMAIN: 4, PROG: 96.85, },
{ SYSTEM: 6020, PHASE: 'PB', DISC: 'Electrical', TOTAL: 83, ACTUAL: 68, REMAIN: 15, PROG: 81.93, },
{ SYSTEM: 6020, PHASE: 'PB', DISC: 'Instrument', TOTAL: 545, ACTUAL: 453, REMAIN: 92, PROG: 83.12, },
{ SYSTEM: 6030, PHASE: 'A', DISC: 'Electrical', TOTAL: 177, ACTUAL: 117, REMAIN: 60, PROG: 66.1, },
{ SYSTEM: 6030, PHASE: 'A', DISC: 'Instrument', TOTAL: 381, ACTUAL: 251, REMAIN: 130, PROG: 65.88, },
{ SYSTEM: 6030, PHASE: 'A', DISC: 'Telecom', TOTAL: 77, ACTUAL: 4, REMAIN: 73, PROG: 5.19, },
{ SYSTEM: 6030, PHASE: 'B', DISC: 'Electrical', TOTAL: 116, ACTUAL: 34, REMAIN: 82, PROG: 29.31, },
{ SYSTEM: 6030, PHASE: 'B', DISC: 'Instrument', TOTAL: 86, ACTUAL: 18, REMAIN: 68, PROG: 20.93, },
{ SYSTEM: 6030, PHASE: 'PA', DISC: 'Electrical', TOTAL: 16, ACTUAL: 16, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6030, PHASE: 'PA', DISC: 'Instrument', TOTAL: 45, ACTUAL: 45, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6030, PHASE: 'PA', DISC: 'Telecom', TOTAL: 9, ACTUAL: 5, REMAIN: 4, PROG: 55.56, },
{ SYSTEM: 6030, PHASE: 'PB', DISC: 'Electrical', TOTAL: 15, ACTUAL: 14, REMAIN: 1, PROG: 93.33, },
{ SYSTEM: 6030, PHASE: 'PB', DISC: 'Instrument', TOTAL: 136, ACTUAL: 87, REMAIN: 49, PROG: 63.97, },
{ SYSTEM: 6030, PHASE: 'PB', DISC: 'Telecom', TOTAL: 13, ACTUAL: 5, REMAIN: 8, PROG: 38.46, },
{ SYSTEM: 6050, PHASE: 'A', DISC: 'Electrical', TOTAL: 181, ACTUAL: 18, REMAIN: 163, PROG: 9.94, },
{ SYSTEM: 6050, PHASE: 'A', DISC: 'Instrument', TOTAL: 295, ACTUAL: 120, REMAIN: 175, PROG: 40.68, },
{ SYSTEM: 6050, PHASE: 'A', DISC: 'Telecom', TOTAL: 18, ACTUAL: 0, REMAIN: 18, PROG: 0, },
{ SYSTEM: 6050, PHASE: 'B', DISC: 'Electrical', TOTAL: 29, ACTUAL: 0, REMAIN: 29, PROG: 0, },
{ SYSTEM: 6050, PHASE: 'B', DISC: 'Instrument', TOTAL: 13, ACTUAL: 0, REMAIN: 13, PROG: 0, },
{ SYSTEM: 6050, PHASE: 'PA', DISC: 'Instrument', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 6050, PHASE: 'PB', DISC: 'Electrical', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 6050, PHASE: 'PB', DISC: 'Telecom', TOTAL: 62, ACTUAL: 0, REMAIN: 62, PROG: 0, },
{ SYSTEM: 6060, PHASE: 'A', DISC: 'Electrical', TOTAL: 33, ACTUAL: 33, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6060, PHASE: 'A', DISC: 'Instrument', TOTAL: 148, ACTUAL: 135, REMAIN: 13, PROG: 91.22, },
{ SYSTEM: 6060, PHASE: 'B', DISC: 'Electrical', TOTAL: 24, ACTUAL: 0, REMAIN: 24, PROG: 0, },
{ SYSTEM: 6060, PHASE: 'B', DISC: 'Instrument', TOTAL: 75, ACTUAL: 0, REMAIN: 75, PROG: 0, },
{ SYSTEM: 6060, PHASE: 'PA', DISC: 'Instrument', TOTAL: 41, ACTUAL: 40, REMAIN: 1, PROG: 97.56, },
{ SYSTEM: 6060, PHASE: 'PB', DISC: 'Electrical', TOTAL: 10, ACTUAL: 10, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6060, PHASE: 'PB', DISC: 'Instrument', TOTAL: 138, ACTUAL: 119, REMAIN: 19, PROG: 86.23, },
{ SYSTEM: 6060, PHASE: 'PB', DISC: 'Telecom', TOTAL: 3, ACTUAL: 1, REMAIN: 2, PROG: 33.33, },
{ SYSTEM: 6070, PHASE: 'A', DISC: 'Electrical', TOTAL: 83, ACTUAL: 81, REMAIN: 2, PROG: 97.59, },
{ SYSTEM: 6070, PHASE: 'A', DISC: 'Instrument', TOTAL: 1123, ACTUAL: 992, REMAIN: 131, PROG: 88.33, },
{ SYSTEM: 6070, PHASE: 'B', DISC: 'Electrical', TOTAL: 49, ACTUAL: 9, REMAIN: 40, PROG: 18.37, },
{ SYSTEM: 6070, PHASE: 'B', DISC: 'Instrument', TOTAL: 231, ACTUAL: 16, REMAIN: 215, PROG: 6.93, },
{ SYSTEM: 6070, PHASE: 'PA', DISC: 'Electrical', TOTAL: 24, ACTUAL: 22, REMAIN: 2, PROG: 91.67, },
{ SYSTEM: 6070, PHASE: 'PA', DISC: 'Instrument', TOTAL: 106, ACTUAL: 102, REMAIN: 4, PROG: 96.23, },
{ SYSTEM: 6070, PHASE: 'PB', DISC: 'Electrical', TOTAL: 56, ACTUAL: 27, REMAIN: 29, PROG: 48.21, },
{ SYSTEM: 6070, PHASE: 'PB', DISC: 'Instrument', TOTAL: 465, ACTUAL: 345, REMAIN: 120, PROG: 74.19, },
{ SYSTEM: 6070, PHASE: 'PB', DISC: 'Telecom', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6071, PHASE: 'A', DISC: 'Instrument', TOTAL: 120, ACTUAL: 120, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6071, PHASE: 'B', DISC: 'Instrument', TOTAL: 67, ACTUAL: 0, REMAIN: 67, PROG: 0, },
{ SYSTEM: 6071, PHASE: 'PA', DISC: 'Instrument', TOTAL: 9, ACTUAL: 9, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6071, PHASE: 'PB', DISC: 'Electrical', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6071, PHASE: 'PB', DISC: 'Instrument', TOTAL: 20, ACTUAL: 19, REMAIN: 1, PROG: 95, },
{ SYSTEM: 6072, PHASE: 'A', DISC: 'Electrical', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6072, PHASE: 'A', DISC: 'Instrument', TOTAL: 112, ACTUAL: 112, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6072, PHASE: 'B', DISC: 'Instrument', TOTAL: 68, ACTUAL: 0, REMAIN: 68, PROG: 0, },
{ SYSTEM: 6072, PHASE: 'PA', DISC: 'Safety', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6072, PHASE: 'PA', DISC: 'Instrument', TOTAL: 4, ACTUAL: 4, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6072, PHASE: 'PB', DISC: 'Instrument', TOTAL: 27, ACTUAL: 24, REMAIN: 3, PROG: 88.89, },
{ SYSTEM: 6073, PHASE: 'A', DISC: 'Electrical', TOTAL: 10, ACTUAL: 1, REMAIN: 9, PROG: 10, },
{ SYSTEM: 6073, PHASE: 'A', DISC: 'Instrument', TOTAL: 3586, ACTUAL: 1222, REMAIN: 2364, PROG: 34.08, },
{ SYSTEM: 6073, PHASE: 'B', DISC: 'Instrument', TOTAL: 1527, ACTUAL: 0, REMAIN: 1527, PROG: 0, },
{ SYSTEM: 6073, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 6, ACTUAL: 0, REMAIN: 6, PROG: 0, },
{ SYSTEM: 6073, PHASE: 'PA', DISC: 'Electrical', TOTAL: 14, ACTUAL: 13, REMAIN: 1, PROG: 92.86, },
{ SYSTEM: 6073, PHASE: 'PA', DISC: 'Instrument', TOTAL: 36, ACTUAL: 16, REMAIN: 20, PROG: 44.44, },
{ SYSTEM: 6073, PHASE: 'PB', DISC: 'Architecture', TOTAL: 41, ACTUAL: 41, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6073, PHASE: 'PB', DISC: 'Electrical', TOTAL: 4, ACTUAL: 2, REMAIN: 2, PROG: 50, },
{ SYSTEM: 6073, PHASE: 'PB', DISC: 'Instrument', TOTAL: 69, ACTUAL: 16, REMAIN: 53, PROG: 23.19, },
{ SYSTEM: 6074, PHASE: 'A', DISC: 'Electrical', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 6074, PHASE: 'A', DISC: 'Instrument', TOTAL: 152, ACTUAL: 3, REMAIN: 149, PROG: 1.97, },
{ SYSTEM: 6074, PHASE: 'B', DISC: 'Instrument', TOTAL: 46, ACTUAL: 0, REMAIN: 46, PROG: 0, },
{ SYSTEM: 6074, PHASE: 'PB', DISC: 'Electrical', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6075, PHASE: 'A', DISC: 'Instrument', TOTAL: 978, ACTUAL: 736, REMAIN: 242, PROG: 75.26, },
{ SYSTEM: 6075, PHASE: 'B', DISC: 'Instrument', TOTAL: 236, ACTUAL: 0, REMAIN: 236, PROG: 0, },
{ SYSTEM: 6075, PHASE: 'PA', DISC: 'Instrument', TOTAL: 21, ACTUAL: 17, REMAIN: 4, PROG: 80.95, },
{ SYSTEM: 6075, PHASE: 'PB', DISC: 'Instrument', TOTAL: 171, ACTUAL: 111, REMAIN: 60, PROG: 64.91, },
{ SYSTEM: 6076, PHASE: 'A', DISC: 'Instrument', TOTAL: 1078, ACTUAL: 840, REMAIN: 238, PROG: 77.92, },
{ SYSTEM: 6076, PHASE: 'B', DISC: 'Instrument', TOTAL: 289, ACTUAL: 0, REMAIN: 289, PROG: 0, },
{ SYSTEM: 6076, PHASE: 'PA', DISC: 'Electrical', TOTAL: 12, ACTUAL: 12, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6076, PHASE: 'PA', DISC: 'Instrument', TOTAL: 34, ACTUAL: 34, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6076, PHASE: 'PB', DISC: 'Safety', TOTAL: 3, ACTUAL: 2, REMAIN: 1, PROG: 66.67, },
{ SYSTEM: 6076, PHASE: 'PB', DISC: 'Electrical', TOTAL: 5, ACTUAL: 0, REMAIN: 5, PROG: 0, },
{ SYSTEM: 6076, PHASE: 'PB', DISC: 'Instrument', TOTAL: 387, ACTUAL: 366, REMAIN: 21, PROG: 94.57, },
{ SYSTEM: 6077, PHASE: 'A', DISC: 'Instrument', TOTAL: 487, ACTUAL: 364, REMAIN: 123, PROG: 74.74, },
{ SYSTEM: 6077, PHASE: 'B', DISC: 'Instrument', TOTAL: 143, ACTUAL: 40, REMAIN: 103, PROG: 27.97, },
{ SYSTEM: 6077, PHASE: 'PA', DISC: 'Electrical', TOTAL: 12, ACTUAL: 12, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6077, PHASE: 'PA', DISC: 'Instrument', TOTAL: 61, ACTUAL: 47, REMAIN: 14, PROG: 77.05, },
{ SYSTEM: 6077, PHASE: 'PB', DISC: 'Electrical', TOTAL: 2, ACTUAL: 1, REMAIN: 1, PROG: 50, },
{ SYSTEM: 6077, PHASE: 'PB', DISC: 'Instrument', TOTAL: 204, ACTUAL: 148, REMAIN: 56, PROG: 72.55, },
{ SYSTEM: 6078, PHASE: 'A', DISC: 'Instrument', TOTAL: 581, ACTUAL: 435, REMAIN: 146, PROG: 74.87, },
{ SYSTEM: 6078, PHASE: 'B', DISC: 'Instrument', TOTAL: 164, ACTUAL: 68, REMAIN: 96, PROG: 41.46, },
{ SYSTEM: 6078, PHASE: 'PA', DISC: 'Electrical', TOTAL: 16, ACTUAL: 16, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6078, PHASE: 'PA', DISC: 'Instrument', TOTAL: 10, ACTUAL: 6, REMAIN: 4, PROG: 60, },
{ SYSTEM: 6078, PHASE: 'PA', DISC: 'Telecom', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 6078, PHASE: 'PB', DISC: 'Electrical', TOTAL: 44, ACTUAL: 14, REMAIN: 30, PROG: 31.82, },
{ SYSTEM: 6078, PHASE: 'PB', DISC: 'Instrument', TOTAL: 207, ACTUAL: 119, REMAIN: 88, PROG: 57.49, },
{ SYSTEM: 6078, PHASE: 'PB', DISC: 'Telecom', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 6079, PHASE: 'A', DISC: 'Electrical', TOTAL: 11, ACTUAL: 7, REMAIN: 4, PROG: 63.64, },
{ SYSTEM: 6079, PHASE: 'A', DISC: 'Instrument', TOTAL: 73, ACTUAL: 33, REMAIN: 40, PROG: 45.21, },
{ SYSTEM: 6079, PHASE: 'B', DISC: 'Electrical', TOTAL: 5, ACTUAL: 0, REMAIN: 5, PROG: 0, },
{ SYSTEM: 6079, PHASE: 'B', DISC: 'Instrument', TOTAL: 12, ACTUAL: 0, REMAIN: 12, PROG: 0, },
{ SYSTEM: 6079, PHASE: 'PA', DISC: 'Electrical', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 6079, PHASE: 'PA', DISC: 'Instrument', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 6079, PHASE: 'PB', DISC: 'Safety', TOTAL: 7, ACTUAL: 6, REMAIN: 1, PROG: 85.71, },
{ SYSTEM: 6079, PHASE: 'PB', DISC: 'Electrical', TOTAL: 4, ACTUAL: 4, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6079, PHASE: 'PB', DISC: 'Instrument', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 6079, PHASE: 'PB', DISC: 'Telecom', TOTAL: 4, ACTUAL: 4, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6080, PHASE: 'A', DISC: 'Piping', TOTAL: 64, ACTUAL: 60, REMAIN: 4, PROG: 93.75, },
{ SYSTEM: 6080, PHASE: 'A', DISC: 'Electrical', TOTAL: 5, ACTUAL: 1, REMAIN: 4, PROG: 20, },
{ SYSTEM: 6080, PHASE: 'A', DISC: 'Instrument', TOTAL: 14, ACTUAL: 0, REMAIN: 14, PROG: 0, },
{ SYSTEM: 6080, PHASE: 'A', DISC: 'Telecom', TOTAL: 76, ACTUAL: 2, REMAIN: 74, PROG: 2.63, },
{ SYSTEM: 6080, PHASE: 'B', DISC: 'Electrical', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 6080, PHASE: 'B', DISC: 'Instrument', TOTAL: 12, ACTUAL: 0, REMAIN: 12, PROG: 0, },
{ SYSTEM: 6080, PHASE: 'B', DISC: 'Telecom', TOTAL: 8, ACTUAL: 0, REMAIN: 8, PROG: 0, },
{ SYSTEM: 6080, PHASE: 'PA', DISC: 'Piping', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6080, PHASE: 'PA', DISC: 'Instrument', TOTAL: 6, ACTUAL: 6, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6080, PHASE: 'PA', DISC: 'Telecom', TOTAL: 17, ACTUAL: 16, REMAIN: 1, PROG: 94.12, },
{ SYSTEM: 6080, PHASE: 'PB', DISC: 'Piping', TOTAL: 6, ACTUAL: 6, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6080, PHASE: 'PB', DISC: 'Instrument', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6080, PHASE: 'PB', DISC: 'Telecom', TOTAL: 3, ACTUAL: 3, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6080, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 10, ACTUAL: 10, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6080, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 10, ACTUAL: 10, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6080, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 10, ACTUAL: 10, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6080, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 10, ACTUAL: 10, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6080, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 10, ACTUAL: 10, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6080, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 10, ACTUAL: 10, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6080, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 10, ACTUAL: 10, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6110, PHASE: 'A', DISC: 'Piping', TOTAL: 362, ACTUAL: 217, REMAIN: 145, PROG: 59.94, },
{ SYSTEM: 6110, PHASE: 'A', DISC: 'Mechanical', TOTAL: 67, ACTUAL: 50, REMAIN: 17, PROG: 74.63, },
{ SYSTEM: 6110, PHASE: 'A', DISC: 'Electrical', TOTAL: 176, ACTUAL: 40, REMAIN: 136, PROG: 22.73, },
{ SYSTEM: 6110, PHASE: 'A', DISC: 'Instrument', TOTAL: 301, ACTUAL: 114, REMAIN: 187, PROG: 37.87, },
{ SYSTEM: 6110, PHASE: 'B', DISC: 'Piping', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 6110, PHASE: 'B', DISC: 'Mechanical', TOTAL: 11, ACTUAL: 0, REMAIN: 11, PROG: 0, },
{ SYSTEM: 6110, PHASE: 'B', DISC: 'Electrical', TOTAL: 76, ACTUAL: 0, REMAIN: 76, PROG: 0, },
{ SYSTEM: 6110, PHASE: 'B', DISC: 'Instrument', TOTAL: 107, ACTUAL: 0, REMAIN: 107, PROG: 0, },
{ SYSTEM: 6110, PHASE: 'PA', DISC: 'Piping', TOTAL: 14, ACTUAL: 9, REMAIN: 5, PROG: 64.29, },
{ SYSTEM: 6110, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 45, ACTUAL: 40, REMAIN: 5, PROG: 88.89, },
{ SYSTEM: 6110, PHASE: 'PA', DISC: 'Electrical', TOTAL: 41, ACTUAL: 27, REMAIN: 14, PROG: 65.85, },
{ SYSTEM: 6110, PHASE: 'PA', DISC: 'Instrument', TOTAL: 13, ACTUAL: 11, REMAIN: 2, PROG: 84.62, },
{ SYSTEM: 6110, PHASE: 'PB', DISC: 'Piping', TOTAL: 47, ACTUAL: 31, REMAIN: 16, PROG: 65.96, },
{ SYSTEM: 6110, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 61, ACTUAL: 48, REMAIN: 13, PROG: 78.69, },
{ SYSTEM: 6110, PHASE: 'PB', DISC: 'Electrical', TOTAL: 76, ACTUAL: 47, REMAIN: 29, PROG: 61.84, },
{ SYSTEM: 6110, PHASE: 'PB', DISC: 'Instrument', TOTAL: 27, ACTUAL: 22, REMAIN: 5, PROG: 81.48, },
{ SYSTEM: 6110, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 59, ACTUAL: 54, REMAIN: 5, PROG: 91.53, },
{ SYSTEM: 6110, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 59, ACTUAL: 54, REMAIN: 5, PROG: 91.53, },
{ SYSTEM: 6110, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 59, ACTUAL: 42, REMAIN: 17, PROG: 71.19, },
{ SYSTEM: 6110, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 59, ACTUAL: 40, REMAIN: 19, PROG: 67.8, },
{ SYSTEM: 6110, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 59, ACTUAL: 32, REMAIN: 27, PROG: 54.24, },
{ SYSTEM: 6110, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 59, ACTUAL: 30, REMAIN: 29, PROG: 50.85, },
{ SYSTEM: 6110, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 59, ACTUAL: 19, REMAIN: 40, PROG: 32.2, },
{ SYSTEM: 6120, PHASE: 'A', DISC: 'Safety', TOTAL: 269, ACTUAL: 36, REMAIN: 233, PROG: 13.38, },
{ SYSTEM: 6120, PHASE: 'A', DISC: 'Piping', TOTAL: 992, ACTUAL: 386, REMAIN: 606, PROG: 38.91, },
{ SYSTEM: 6120, PHASE: 'A', DISC: 'Mechanical', TOTAL: 19, ACTUAL: 4, REMAIN: 15, PROG: 21.05, },
{ SYSTEM: 6120, PHASE: 'A', DISC: 'Electrical', TOTAL: 18, ACTUAL: 4, REMAIN: 14, PROG: 22.22, },
{ SYSTEM: 6120, PHASE: 'A', DISC: 'Instrument', TOTAL: 155, ACTUAL: 28, REMAIN: 127, PROG: 18.06, },
{ SYSTEM: 6120, PHASE: 'B', DISC: 'Piping', TOTAL: 10, ACTUAL: 0, REMAIN: 10, PROG: 0, },
{ SYSTEM: 6120, PHASE: 'B', DISC: 'Mechanical', TOTAL: 6, ACTUAL: 0, REMAIN: 6, PROG: 0, },
{ SYSTEM: 6120, PHASE: 'B', DISC: 'Electrical', TOTAL: 6, ACTUAL: 0, REMAIN: 6, PROG: 0, },
{ SYSTEM: 6120, PHASE: 'B', DISC: 'Instrument', TOTAL: 26, ACTUAL: 0, REMAIN: 26, PROG: 0, },
{ SYSTEM: 6120, PHASE: 'PA', DISC: 'Piping', TOTAL: 22, ACTUAL: 16, REMAIN: 6, PROG: 72.73, },
{ SYSTEM: 6120, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 168, ACTUAL: 167, REMAIN: 1, PROG: 99.4, },
{ SYSTEM: 6120, PHASE: 'PA', DISC: 'Electrical', TOTAL: 14, ACTUAL: 13, REMAIN: 1, PROG: 92.86, },
{ SYSTEM: 6120, PHASE: 'PA', DISC: 'Instrument', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 6120, PHASE: 'PB', DISC: 'Company', TOTAL: 4, ACTUAL: 3, REMAIN: 1, PROG: 75, },
{ SYSTEM: 6120, PHASE: 'PB', DISC: 'Safety', TOTAL: 24, ACTUAL: 18, REMAIN: 6, PROG: 75, },
{ SYSTEM: 6120, PHASE: 'PB', DISC: 'Piping', TOTAL: 47, ACTUAL: 33, REMAIN: 14, PROG: 70.21, },
{ SYSTEM: 6120, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 1139, ACTUAL: 1138, REMAIN: 1, PROG: 99.91, },
{ SYSTEM: 6120, PHASE: 'PB', DISC: 'Instrument', TOTAL: 29, ACTUAL: 27, REMAIN: 2, PROG: 93.1, },
{ SYSTEM: 6120, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 162, ACTUAL: 128, REMAIN: 34, PROG: 79.01, },
{ SYSTEM: 6120, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 162, ACTUAL: 128, REMAIN: 34, PROG: 79.01, },
{ SYSTEM: 6120, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 162, ACTUAL: 65, REMAIN: 97, PROG: 40.12, },
{ SYSTEM: 6120, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 162, ACTUAL: 60, REMAIN: 102, PROG: 37.04, },
{ SYSTEM: 6120, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 162, ACTUAL: 61, REMAIN: 101, PROG: 37.65, },
{ SYSTEM: 6120, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 162, ACTUAL: 40, REMAIN: 122, PROG: 24.69, },
{ SYSTEM: 6120, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 162, ACTUAL: 32, REMAIN: 130, PROG: 19.75, },
{ SYSTEM: 6130, PHASE: 'A', DISC: 'Piping', TOTAL: 326, ACTUAL: 137, REMAIN: 189, PROG: 42.02, },
{ SYSTEM: 6130, PHASE: 'A', DISC: 'Mechanical', TOTAL: 83, ACTUAL: 79, REMAIN: 4, PROG: 95.18, },
{ SYSTEM: 6130, PHASE: 'A', DISC: 'Electrical', TOTAL: 20, ACTUAL: 8, REMAIN: 12, PROG: 40, },
{ SYSTEM: 6130, PHASE: 'A', DISC: 'Instrument', TOTAL: 301, ACTUAL: 71, REMAIN: 230, PROG: 23.59, },
{ SYSTEM: 6130, PHASE: 'B', DISC: 'Piping', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 6130, PHASE: 'B', DISC: 'Mechanical', TOTAL: 22, ACTUAL: 0, REMAIN: 22, PROG: 0, },
{ SYSTEM: 6130, PHASE: 'B', DISC: 'Electrical', TOTAL: 10, ACTUAL: 0, REMAIN: 10, PROG: 0, },
{ SYSTEM: 6130, PHASE: 'B', DISC: 'Instrument', TOTAL: 94, ACTUAL: 0, REMAIN: 94, PROG: 0, },
{ SYSTEM: 6130, PHASE: 'PA', DISC: 'Piping', TOTAL: 4, ACTUAL: 2, REMAIN: 2, PROG: 50, },
{ SYSTEM: 6130, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 27, ACTUAL: 19, REMAIN: 8, PROG: 70.37, },
{ SYSTEM: 6130, PHASE: 'PA', DISC: 'Electrical', TOTAL: 5, ACTUAL: 4, REMAIN: 1, PROG: 80, },
{ SYSTEM: 6130, PHASE: 'PA', DISC: 'Instrument', TOTAL: 47, ACTUAL: 38, REMAIN: 9, PROG: 80.85, },
{ SYSTEM: 6130, PHASE: 'PB', DISC: 'Piping', TOTAL: 9, ACTUAL: 9, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6130, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 10, ACTUAL: 7, REMAIN: 3, PROG: 70, },
{ SYSTEM: 6130, PHASE: 'PB', DISC: 'Electrical', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 6130, PHASE: 'PB', DISC: 'Instrument', TOTAL: 3, ACTUAL: 2, REMAIN: 1, PROG: 66.67, },
{ SYSTEM: 6130, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 54, ACTUAL: 48, REMAIN: 6, PROG: 88.89, },
{ SYSTEM: 6130, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 54, ACTUAL: 35, REMAIN: 19, PROG: 64.81, },
{ SYSTEM: 6130, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 54, ACTUAL: 22, REMAIN: 32, PROG: 40.74, },
{ SYSTEM: 6130, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 54, ACTUAL: 21, REMAIN: 33, PROG: 38.89, },
{ SYSTEM: 6130, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 54, ACTUAL: 21, REMAIN: 33, PROG: 38.89, },
{ SYSTEM: 6130, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 54, ACTUAL: 20, REMAIN: 34, PROG: 37.04, },
{ SYSTEM: 6130, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 54, ACTUAL: 18, REMAIN: 36, PROG: 33.33, },
{ SYSTEM: 6140, PHASE: 'A', DISC: 'Safety', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 6140, PHASE: 'A', DISC: 'Piping', TOTAL: 20, ACTUAL: 8, REMAIN: 12, PROG: 40, },
{ SYSTEM: 6140, PHASE: 'A', DISC: 'Mechanical', TOTAL: 5, ACTUAL: 4, REMAIN: 1, PROG: 80, },
{ SYSTEM: 6140, PHASE: 'A', DISC: 'Instrument', TOTAL: 10, ACTUAL: 9, REMAIN: 1, PROG: 90, },
{ SYSTEM: 6140, PHASE: 'B', DISC: 'Piping', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 6140, PHASE: 'B', DISC: 'Electrical', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 6140, PHASE: 'B', DISC: 'Instrument', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 6140, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6140, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 6, ACTUAL: 5, REMAIN: 1, PROG: 83.33, },
{ SYSTEM: 6140, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 3, ACTUAL: 2, REMAIN: 1, PROG: 66.67, },
{ SYSTEM: 6140, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 3, ACTUAL: 2, REMAIN: 1, PROG: 66.67, },
{ SYSTEM: 6140, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 3, ACTUAL: 2, REMAIN: 1, PROG: 66.67, },
{ SYSTEM: 6140, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 3, ACTUAL: 1, REMAIN: 2, PROG: 33.33, },
{ SYSTEM: 6140, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 3, ACTUAL: 1, REMAIN: 2, PROG: 33.33, },
{ SYSTEM: 6140, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 3, ACTUAL: 1, REMAIN: 2, PROG: 33.33, },
{ SYSTEM: 6140, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 3, ACTUAL: 1, REMAIN: 2, PROG: 33.33, },
{ SYSTEM: 6150, PHASE: 'A', DISC: 'Piping', TOTAL: 422, ACTUAL: 66, REMAIN: 356, PROG: 15.64, },
{ SYSTEM: 6150, PHASE: 'A', DISC: 'Mechanical', TOTAL: 12, ACTUAL: 0, REMAIN: 12, PROG: 0, },
{ SYSTEM: 6150, PHASE: 'A', DISC: 'Instrument', TOTAL: 469, ACTUAL: 317, REMAIN: 152, PROG: 67.59, },
{ SYSTEM: 6150, PHASE: 'B', DISC: 'Piping', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 6150, PHASE: 'B', DISC: 'Instrument', TOTAL: 40, ACTUAL: 0, REMAIN: 40, PROG: 0, },
{ SYSTEM: 6150, PHASE: 'PA', DISC: 'Piping', TOTAL: 20, ACTUAL: 18, REMAIN: 2, PROG: 90, },
{ SYSTEM: 6150, PHASE: 'PB', DISC: 'Instrument', TOTAL: 14, ACTUAL: 3, REMAIN: 11, PROG: 21.43, },
{ SYSTEM: 6150, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 69, ACTUAL: 38, REMAIN: 31, PROG: 55.07, },
{ SYSTEM: 6150, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 69, ACTUAL: 40, REMAIN: 29, PROG: 57.97, },
{ SYSTEM: 6150, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 69, ACTUAL: 7, REMAIN: 62, PROG: 10.14, },
{ SYSTEM: 6150, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 69, ACTUAL: 7, REMAIN: 62, PROG: 10.14, },
{ SYSTEM: 6150, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 69, ACTUAL: 7, REMAIN: 62, PROG: 10.14, },
{ SYSTEM: 6150, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 69, ACTUAL: 3, REMAIN: 66, PROG: 4.35, },
{ SYSTEM: 6150, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 69, ACTUAL: 2, REMAIN: 67, PROG: 2.9, },
{ SYSTEM: 6160, PHASE: 'A', DISC: 'Mechanical', TOTAL: 16, ACTUAL: 16, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6160, PHASE: 'A', DISC: 'Electrical', TOTAL: 8, ACTUAL: 2, REMAIN: 6, PROG: 25, },
{ SYSTEM: 6160, PHASE: 'A', DISC: 'Instrument', TOTAL: 775, ACTUAL: 544, REMAIN: 231, PROG: 70.19, },
{ SYSTEM: 6160, PHASE: 'B', DISC: 'Electrical', TOTAL: 7, ACTUAL: 0, REMAIN: 7, PROG: 0, },
{ SYSTEM: 6160, PHASE: 'B', DISC: 'Instrument', TOTAL: 90, ACTUAL: 0, REMAIN: 90, PROG: 0, },
{ SYSTEM: 6160, PHASE: 'PA', DISC: 'Instrument', TOTAL: 90, ACTUAL: 74, REMAIN: 16, PROG: 82.22, },
{ SYSTEM: 6160, PHASE: 'PB', DISC: 'Instrument', TOTAL: 85, ACTUAL: 84, REMAIN: 1, PROG: 98.82, },
{ SYSTEM: 6160, PHASE: 'PB', DISC: 'Telecom', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 6170, PHASE: 'A', DISC: 'Safety', TOTAL: 330, ACTUAL: 0, REMAIN: 330, PROG: 0, },
{ SYSTEM: 6170, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6210, PHASE: 'A', DISC: 'Electrical', TOTAL: 66, ACTUAL: 42, REMAIN: 24, PROG: 63.64, },
{ SYSTEM: 6210, PHASE: 'A', DISC: 'Instrument', TOTAL: 7, ACTUAL: 1, REMAIN: 6, PROG: 14.29, },
{ SYSTEM: 6210, PHASE: 'A', DISC: 'Telecom', TOTAL: 3165, ACTUAL: 1811, REMAIN: 1354, PROG: 57.22, },
{ SYSTEM: 6210, PHASE: 'B', DISC: 'Electrical', TOTAL: 40, ACTUAL: 0, REMAIN: 40, PROG: 0, },
{ SYSTEM: 6210, PHASE: 'B', DISC: 'Instrument', TOTAL: 108, ACTUAL: 0, REMAIN: 108, PROG: 0, },
{ SYSTEM: 6210, PHASE: 'B', DISC: 'Telecom', TOTAL: 537, ACTUAL: 63, REMAIN: 474, PROG: 11.73, },
{ SYSTEM: 6210, PHASE: 'PA', DISC: 'Architecture', TOTAL: 83, ACTUAL: 83, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6210, PHASE: 'PA', DISC: 'Electrical', TOTAL: 346, ACTUAL: 337, REMAIN: 9, PROG: 97.4, },
{ SYSTEM: 6210, PHASE: 'PA', DISC: 'Instrument', TOTAL: 5, ACTUAL: 3, REMAIN: 2, PROG: 60, },
{ SYSTEM: 6210, PHASE: 'PA', DISC: 'Telecom', TOTAL: 347, ACTUAL: 291, REMAIN: 56, PROG: 83.86, },
{ SYSTEM: 6210, PHASE: 'PB', DISC: 'Architecture', TOTAL: 139, ACTUAL: 139, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6210, PHASE: 'PB', DISC: 'Electrical', TOTAL: 135, ACTUAL: 126, REMAIN: 9, PROG: 93.33, },
{ SYSTEM: 6210, PHASE: 'PB', DISC: 'Instrument', TOTAL: 126, ACTUAL: 126, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6210, PHASE: 'PB', DISC: 'Telecom', TOTAL: 588, ACTUAL: 475, REMAIN: 113, PROG: 80.78, },
{ SYSTEM: 6221, PHASE: 'A', DISC: 'Electrical', TOTAL: 16, ACTUAL: 15, REMAIN: 1, PROG: 93.75, },
{ SYSTEM: 6221, PHASE: 'A', DISC: 'Telecom', TOTAL: 899, ACTUAL: 478, REMAIN: 421, PROG: 53.17, },
{ SYSTEM: 6221, PHASE: 'B', DISC: 'Electrical', TOTAL: 9, ACTUAL: 0, REMAIN: 9, PROG: 0, },
{ SYSTEM: 6221, PHASE: 'B', DISC: 'Instrument', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 6221, PHASE: 'B', DISC: 'Telecom', TOTAL: 261, ACTUAL: 0, REMAIN: 261, PROG: 0, },
{ SYSTEM: 6221, PHASE: 'PA', DISC: 'Telecom', TOTAL: 13, ACTUAL: 11, REMAIN: 2, PROG: 84.62, },
{ SYSTEM: 6221, PHASE: 'PB', DISC: 'Telecom', TOTAL: 56, ACTUAL: 50, REMAIN: 6, PROG: 89.29, },
{ SYSTEM: 6222, PHASE: 'A', DISC: 'Electrical', TOTAL: 7, ACTUAL: 0, REMAIN: 7, PROG: 0, },
{ SYSTEM: 6222, PHASE: 'A', DISC: 'Telecom', TOTAL: 48, ACTUAL: 0, REMAIN: 48, PROG: 0, },
{ SYSTEM: 6222, PHASE: 'B', DISC: 'Telecom', TOTAL: 11, ACTUAL: 0, REMAIN: 11, PROG: 0, },
{ SYSTEM: 6222, PHASE: 'PA', DISC: 'Telecom', TOTAL: 10, ACTUAL: 9, REMAIN: 1, PROG: 90, },
{ SYSTEM: 6222, PHASE: 'PB', DISC: 'Telecom', TOTAL: 5, ACTUAL: 5, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6223, PHASE: 'A', DISC: 'Electrical', TOTAL: 5, ACTUAL: 5, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6223, PHASE: 'A', DISC: 'Telecom', TOTAL: 33, ACTUAL: 0, REMAIN: 33, PROG: 0, },
{ SYSTEM: 6223, PHASE: 'B', DISC: 'Electrical', TOTAL: 3, ACTUAL: 0, REMAIN: 3, PROG: 0, },
{ SYSTEM: 6223, PHASE: 'B', DISC: 'Instrument', TOTAL: 3, ACTUAL: 0, REMAIN: 3, PROG: 0, },
{ SYSTEM: 6223, PHASE: 'B', DISC: 'Telecom', TOTAL: 12, ACTUAL: 0, REMAIN: 12, PROG: 0, },
{ SYSTEM: 6223, PHASE: 'PB', DISC: 'Telecom', TOTAL: 8, ACTUAL: 8, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6320, PHASE: 'A', DISC: 'Safety', TOTAL: 8, ACTUAL: 4, REMAIN: 4, PROG: 50, },
{ SYSTEM: 6320, PHASE: 'A', DISC: 'Mechanical', TOTAL: 12, ACTUAL: 12, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6320, PHASE: 'A', DISC: 'Electrical', TOTAL: 32, ACTUAL: 4, REMAIN: 28, PROG: 12.5, },
{ SYSTEM: 6320, PHASE: 'A', DISC: 'Instrument', TOTAL: 64, ACTUAL: 12, REMAIN: 52, PROG: 18.75, },
{ SYSTEM: 6320, PHASE: 'B', DISC: 'Mechanical', TOTAL: 20, ACTUAL: 0, REMAIN: 20, PROG: 0, },
{ SYSTEM: 6320, PHASE: 'B', DISC: 'Electrical', TOTAL: 16, ACTUAL: 0, REMAIN: 16, PROG: 0, },
{ SYSTEM: 6320, PHASE: 'B', DISC: 'Instrument', TOTAL: 12, ACTUAL: 0, REMAIN: 12, PROG: 0, },
{ SYSTEM: 6320, PHASE: 'PA', DISC: 'Safety', TOTAL: 5, ACTUAL: 3, REMAIN: 2, PROG: 60, },
{ SYSTEM: 6320, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 8, ACTUAL: 0, REMAIN: 8, PROG: 0, },
{ SYSTEM: 6320, PHASE: 'PA', DISC: 'Electrical', TOTAL: 4, ACTUAL: 1, REMAIN: 3, PROG: 25, },
{ SYSTEM: 6320, PHASE: 'PB', DISC: 'Safety', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6320, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 12, ACTUAL: 8, REMAIN: 4, PROG: 66.67, },
{ SYSTEM: 6320, PHASE: 'PB', DISC: 'Electrical', TOTAL: 9, ACTUAL: 9, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6330, PHASE: 'A', DISC: 'Safety', TOTAL: 9, ACTUAL: 0, REMAIN: 9, PROG: 0, },
{ SYSTEM: 6330, PHASE: 'A', DISC: 'Electrical', TOTAL: 12, ACTUAL: 0, REMAIN: 12, PROG: 0, },
{ SYSTEM: 6330, PHASE: 'PA', DISC: 'Safety', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6330, PHASE: 'PA', DISC: 'Instrument', TOTAL: 4, ACTUAL: 4, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6330, PHASE: 'PB', DISC: 'Safety', TOTAL: 4, ACTUAL: 4, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6330, PHASE: 'PB', DISC: 'Instrument', TOTAL: 12, ACTUAL: 12, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6340, PHASE: 'A', DISC: 'Safety', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 6340, PHASE: 'A', DISC: 'Piping', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 6340, PHASE: 'A', DISC: 'Mechanical', TOTAL: 5, ACTUAL: 5, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6340, PHASE: 'A', DISC: 'Electrical', TOTAL: 13, ACTUAL: 1, REMAIN: 12, PROG: 7.69, },
{ SYSTEM: 6340, PHASE: 'A', DISC: 'Instrument', TOTAL: 29, ACTUAL: 14, REMAIN: 15, PROG: 48.28, },
{ SYSTEM: 6340, PHASE: 'B', DISC: 'Mechanical', TOTAL: 6, ACTUAL: 0, REMAIN: 6, PROG: 0, },
{ SYSTEM: 6340, PHASE: 'B', DISC: 'Electrical', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 6340, PHASE: 'B', DISC: 'Instrument', TOTAL: 6, ACTUAL: 0, REMAIN: 6, PROG: 0, },
{ SYSTEM: 6340, PHASE: 'PA', DISC: 'Safety', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 6340, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 3, ACTUAL: 1, REMAIN: 2, PROG: 33.33, },
{ SYSTEM: 6340, PHASE: 'PA', DISC: 'Electrical', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6340, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 8, ACTUAL: 5, REMAIN: 3, PROG: 62.5, },
{ SYSTEM: 6340, PHASE: 'PB', DISC: 'Electrical', TOTAL: 4, ACTUAL: 2, REMAIN: 2, PROG: 50, },
{ SYSTEM: 6440, PHASE: 'A', DISC: 'Safety', TOTAL: 238, ACTUAL: 0, REMAIN: 238, PROG: 0, },
{ SYSTEM: 6440, PHASE: 'PB', DISC: 'Safety', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 6440, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 3, ACTUAL: 2, REMAIN: 1, PROG: 66.67, },
{ SYSTEM: 6510, PHASE: 'A', DISC: 'Electrical', TOTAL: 95, ACTUAL: 23, REMAIN: 72, PROG: 24.21, },
{ SYSTEM: 6510, PHASE: 'A', DISC: 'Instrument', TOTAL: 26, ACTUAL: 1, REMAIN: 25, PROG: 3.85, },
{ SYSTEM: 6510, PHASE: 'B', DISC: 'Electrical', TOTAL: 17, ACTUAL: 0, REMAIN: 17, PROG: 0, },
{ SYSTEM: 6510, PHASE: 'B', DISC: 'Instrument', TOTAL: 17, ACTUAL: 0, REMAIN: 17, PROG: 0, },
{ SYSTEM: 6510, PHASE: 'PA', DISC: 'Electrical', TOTAL: 101, ACTUAL: 100, REMAIN: 1, PROG: 99.01, },
{ SYSTEM: 6510, PHASE: 'PB', DISC: 'Electrical', TOTAL: 32, ACTUAL: 32, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6630, PHASE: 'A', DISC: 'Piping', TOTAL: 148, ACTUAL: 134, REMAIN: 14, PROG: 90.54, },
{ SYSTEM: 6630, PHASE: 'A', DISC: 'Mechanical', TOTAL: 51, ACTUAL: 45, REMAIN: 6, PROG: 88.24, },
{ SYSTEM: 6630, PHASE: 'A', DISC: 'Electrical', TOTAL: 101, ACTUAL: 73, REMAIN: 28, PROG: 72.28, },
{ SYSTEM: 6630, PHASE: 'A', DISC: 'Instrument', TOTAL: 187, ACTUAL: 131, REMAIN: 56, PROG: 70.05, },
{ SYSTEM: 6630, PHASE: 'B', DISC: 'Piping', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 6630, PHASE: 'B', DISC: 'Mechanical', TOTAL: 9, ACTUAL: 0, REMAIN: 9, PROG: 0, },
{ SYSTEM: 6630, PHASE: 'B', DISC: 'Electrical', TOTAL: 30, ACTUAL: 0, REMAIN: 30, PROG: 0, },
{ SYSTEM: 6630, PHASE: 'B', DISC: 'Instrument', TOTAL: 68, ACTUAL: 0, REMAIN: 68, PROG: 0, },
{ SYSTEM: 6630, PHASE: 'PA', DISC: 'Piping', TOTAL: 4, ACTUAL: 3, REMAIN: 1, PROG: 75, },
{ SYSTEM: 6630, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 24, ACTUAL: 20, REMAIN: 4, PROG: 83.33, },
{ SYSTEM: 6630, PHASE: 'PA', DISC: 'Electrical', TOTAL: 29, ACTUAL: 24, REMAIN: 5, PROG: 82.76, },
{ SYSTEM: 6630, PHASE: 'PA', DISC: 'Instrument', TOTAL: 28, ACTUAL: 26, REMAIN: 2, PROG: 92.86, },
{ SYSTEM: 6630, PHASE: 'PB', DISC: 'Piping', TOTAL: 26, ACTUAL: 10, REMAIN: 16, PROG: 38.46, },
{ SYSTEM: 6630, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 11, ACTUAL: 9, REMAIN: 2, PROG: 81.82, },
{ SYSTEM: 6630, PHASE: 'PB', DISC: 'Electrical', TOTAL: 26, ACTUAL: 21, REMAIN: 5, PROG: 80.77, },
{ SYSTEM: 6630, PHASE: 'PB', DISC: 'Instrument', TOTAL: 14, ACTUAL: 13, REMAIN: 1, PROG: 92.86, },
{ SYSTEM: 6630, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 24, ACTUAL: 24, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6630, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 24, ACTUAL: 24, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6630, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 24, ACTUAL: 24, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6630, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 24, ACTUAL: 24, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6630, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 24, ACTUAL: 24, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6630, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 24, ACTUAL: 21, REMAIN: 3, PROG: 87.5, },
{ SYSTEM: 6630, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 24, ACTUAL: 17, REMAIN: 7, PROG: 70.83, },
{ SYSTEM: 6710, PHASE: 'A', DISC: 'Electrical', TOTAL: 4052, ACTUAL: 2287, REMAIN: 1765, PROG: 56.44, },
{ SYSTEM: 6710, PHASE: 'A', DISC: 'Instrument', TOTAL: 6, ACTUAL: 0, REMAIN: 6, PROG: 0, },
{ SYSTEM: 6710, PHASE: 'A', DISC: 'Telecom', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 6710, PHASE: 'B', DISC: 'Electrical', TOTAL: 213, ACTUAL: 0, REMAIN: 213, PROG: 0, },
{ SYSTEM: 6710, PHASE: 'B', DISC: 'Instrument', TOTAL: 6, ACTUAL: 0, REMAIN: 6, PROG: 0, },
{ SYSTEM: 6710, PHASE: 'PA', DISC: 'Electrical', TOTAL: 64, ACTUAL: 49, REMAIN: 15, PROG: 76.56, },
{ SYSTEM: 6710, PHASE: 'PA', DISC: 'Telecom', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6710, PHASE: 'PB', DISC: 'Electrical', TOTAL: 507, ACTUAL: 425, REMAIN: 82, PROG: 83.83, },
{ SYSTEM: 6710, PHASE: 'PB', DISC: 'Instrument', TOTAL: 41, ACTUAL: 40, REMAIN: 1, PROG: 97.56, },
{ SYSTEM: 6720, PHASE: 'A', DISC: 'Electrical', TOTAL: 3414, ACTUAL: 2420, REMAIN: 994, PROG: 70.88, },
{ SYSTEM: 6720, PHASE: 'A', DISC: 'Instrument', TOTAL: 62, ACTUAL: 32, REMAIN: 30, PROG: 51.61, },
{ SYSTEM: 6720, PHASE: 'A', DISC: 'Telecom', TOTAL: 11, ACTUAL: 4, REMAIN: 7, PROG: 36.36, },
{ SYSTEM: 6720, PHASE: 'B', DISC: 'Electrical', TOTAL: 329, ACTUAL: 6, REMAIN: 323, PROG: 1.82, },
{ SYSTEM: 6720, PHASE: 'B', DISC: 'Instrument', TOTAL: 61, ACTUAL: 6, REMAIN: 55, PROG: 9.84, },
{ SYSTEM: 6720, PHASE: 'PA', DISC: 'Electrical', TOTAL: 365, ACTUAL: 317, REMAIN: 48, PROG: 86.85, },
{ SYSTEM: 6720, PHASE: 'PA', DISC: 'Instrument', TOTAL: 4, ACTUAL: 3, REMAIN: 1, PROG: 75, },
{ SYSTEM: 6720, PHASE: 'PB', DISC: 'Electrical', TOTAL: 956, ACTUAL: 713, REMAIN: 243, PROG: 74.58, },
{ SYSTEM: 6720, PHASE: 'PB', DISC: 'Instrument', TOTAL: 14, ACTUAL: 13, REMAIN: 1, PROG: 92.86, },
{ SYSTEM: 6720, PHASE: 'PB', DISC: 'Telecom', TOTAL: 4, ACTUAL: 2, REMAIN: 2, PROG: 50, },
{ SYSTEM: 6730, PHASE: 'A', DISC: 'Electrical', TOTAL: 63, ACTUAL: 19, REMAIN: 44, PROG: 30.16, },
{ SYSTEM: 6730, PHASE: 'A', DISC: 'Instrument', TOTAL: 6, ACTUAL: 0, REMAIN: 6, PROG: 0, },
{ SYSTEM: 6730, PHASE: 'A', DISC: 'Telecom', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 6730, PHASE: 'B', DISC: 'Electrical', TOTAL: 12, ACTUAL: 0, REMAIN: 12, PROG: 0, },
{ SYSTEM: 6730, PHASE: 'B', DISC: 'Instrument', TOTAL: 6, ACTUAL: 0, REMAIN: 6, PROG: 0, },
{ SYSTEM: 6730, PHASE: 'PA', DISC: 'Electrical', TOTAL: 18, ACTUAL: 14, REMAIN: 4, PROG: 77.78, },
{ SYSTEM: 6730, PHASE: 'PB', DISC: 'Electrical', TOTAL: 13, ACTUAL: 12, REMAIN: 1, PROG: 92.31, },
{ SYSTEM: 6740, PHASE: 'A', DISC: 'Electrical', TOTAL: 154, ACTUAL: 153, REMAIN: 1, PROG: 99.35, },
{ SYSTEM: 6740, PHASE: 'A', DISC: 'Instrument', TOTAL: 28, ACTUAL: 28, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6740, PHASE: 'B', DISC: 'Electrical', TOTAL: 108, ACTUAL: 49, REMAIN: 59, PROG: 45.37, },
{ SYSTEM: 6740, PHASE: 'B', DISC: 'Instrument', TOTAL: 16, ACTUAL: 0, REMAIN: 16, PROG: 0, },
{ SYSTEM: 6740, PHASE: 'PA', DISC: 'Electrical', TOTAL: 134, ACTUAL: 129, REMAIN: 5, PROG: 96.27, },
{ SYSTEM: 6740, PHASE: 'PA', DISC: 'Instrument', TOTAL: 29, ACTUAL: 29, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6740, PHASE: 'PB', DISC: 'Electrical', TOTAL: 192, ACTUAL: 143, REMAIN: 49, PROG: 74.48, },
{ SYSTEM: 6740, PHASE: 'PB', DISC: 'Instrument', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6740, PHASE: 'PB', DISC: 'Telecom', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6810, PHASE: 'A', DISC: 'Piping', TOTAL: 460, ACTUAL: 207, REMAIN: 253, PROG: 45, },
{ SYSTEM: 6810, PHASE: 'A', DISC: 'Mechanical', TOTAL: 127, ACTUAL: 97, REMAIN: 30, PROG: 76.38, },
{ SYSTEM: 6810, PHASE: 'A', DISC: 'Electrical', TOTAL: 222, ACTUAL: 55, REMAIN: 167, PROG: 24.77, },
{ SYSTEM: 6810, PHASE: 'A', DISC: 'Instrument', TOTAL: 457, ACTUAL: 318, REMAIN: 139, PROG: 69.58, },
{ SYSTEM: 6810, PHASE: 'B', DISC: 'Piping', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 6810, PHASE: 'B', DISC: 'Mechanical', TOTAL: 18, ACTUAL: 0, REMAIN: 18, PROG: 0, },
{ SYSTEM: 6810, PHASE: 'B', DISC: 'Electrical', TOTAL: 70, ACTUAL: 0, REMAIN: 70, PROG: 0, },
{ SYSTEM: 6810, PHASE: 'B', DISC: 'Instrument', TOTAL: 155, ACTUAL: 0, REMAIN: 155, PROG: 0, },
{ SYSTEM: 6810, PHASE: 'PA', DISC: 'Piping', TOTAL: 13, ACTUAL: 5, REMAIN: 8, PROG: 38.46, },
{ SYSTEM: 6810, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 143, ACTUAL: 135, REMAIN: 8, PROG: 94.41, },
{ SYSTEM: 6810, PHASE: 'PA', DISC: 'Electrical', TOTAL: 15, ACTUAL: 14, REMAIN: 1, PROG: 93.33, },
{ SYSTEM: 6810, PHASE: 'PA', DISC: 'Instrument', TOTAL: 15, ACTUAL: 12, REMAIN: 3, PROG: 80, },
{ SYSTEM: 6810, PHASE: 'PA', DISC: 'Telecom', TOTAL: 4, ACTUAL: 2, REMAIN: 2, PROG: 50, },
{ SYSTEM: 6810, PHASE: 'PB', DISC: 'Company', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 6810, PHASE: 'PB', DISC: 'Piping', TOTAL: 54, ACTUAL: 35, REMAIN: 19, PROG: 64.81, },
{ SYSTEM: 6810, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 59, ACTUAL: 48, REMAIN: 11, PROG: 81.36, },
{ SYSTEM: 6810, PHASE: 'PB', DISC: 'Electrical', TOTAL: 42, ACTUAL: 39, REMAIN: 3, PROG: 92.86, },
{ SYSTEM: 6810, PHASE: 'PB', DISC: 'Instrument', TOTAL: 13, ACTUAL: 13, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6810, PHASE: 'PB', DISC: 'Telecom', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6810, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 75, ACTUAL: 63, REMAIN: 12, PROG: 84, },
{ SYSTEM: 6810, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 75, ACTUAL: 51, REMAIN: 24, PROG: 68, },
{ SYSTEM: 6810, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 75, ACTUAL: 42, REMAIN: 33, PROG: 56, },
{ SYSTEM: 6810, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 75, ACTUAL: 42, REMAIN: 33, PROG: 56, },
{ SYSTEM: 6810, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 75, ACTUAL: 33, REMAIN: 42, PROG: 44, },
{ SYSTEM: 6810, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 75, ACTUAL: 25, REMAIN: 50, PROG: 33.33, },
{ SYSTEM: 6810, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 75, ACTUAL: 14, REMAIN: 61, PROG: 18.67, },
{ SYSTEM: 6820, PHASE: 'A', DISC: 'Electrical', TOTAL: 7856, ACTUAL: 5713, REMAIN: 2143, PROG: 72.72, },
{ SYSTEM: 6820, PHASE: 'A', DISC: 'Instrument', TOTAL: 97, ACTUAL: 91, REMAIN: 6, PROG: 93.81, },
{ SYSTEM: 6820, PHASE: 'B', DISC: 'Electrical', TOTAL: 1030, ACTUAL: 318, REMAIN: 712, PROG: 30.87, },
{ SYSTEM: 6820, PHASE: 'B', DISC: 'Instrument', TOTAL: 50, ACTUAL: 5, REMAIN: 45, PROG: 10, },
{ SYSTEM: 6820, PHASE: 'PA', DISC: 'Architecture', TOTAL: 39, ACTUAL: 39, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6820, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 5, ACTUAL: 5, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6820, PHASE: 'PA', DISC: 'Electrical', TOTAL: 511, ACTUAL: 458, REMAIN: 53, PROG: 89.63, },
{ SYSTEM: 6820, PHASE: 'PA', DISC: 'Instrument', TOTAL: 48, ACTUAL: 46, REMAIN: 2, PROG: 95.83, },
{ SYSTEM: 6820, PHASE: 'PA', DISC: 'Telecom', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6820, PHASE: 'PB', DISC: 'Architecture', TOTAL: 123, ACTUAL: 123, REMAIN: 0, PROG: 100, },
{ SYSTEM: 6820, PHASE: 'PB', DISC: 'Electrical', TOTAL: 1330, ACTUAL: 982, REMAIN: 348, PROG: 73.83, },
{ SYSTEM: 6820, PHASE: 'PB', DISC: 'Instrument', TOTAL: 314, ACTUAL: 284, REMAIN: 30, PROG: 90.45, },
{ SYSTEM: 6820, PHASE: 'PB', DISC: 'Telecom', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8002, PHASE: 'A', DISC: 'Piping', TOTAL: 372, ACTUAL: 48, REMAIN: 324, PROG: 12.9, },
{ SYSTEM: 8002, PHASE: 'A', DISC: 'Mechanical', TOTAL: 3, ACTUAL: 3, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8002, PHASE: 'A', DISC: 'Electrical', TOTAL: 14, ACTUAL: 4, REMAIN: 10, PROG: 28.57, },
{ SYSTEM: 8002, PHASE: 'A', DISC: 'Instrument', TOTAL: 80, ACTUAL: 27, REMAIN: 53, PROG: 33.75, },
{ SYSTEM: 8002, PHASE: 'B', DISC: 'Piping', TOTAL: 5, ACTUAL: 0, REMAIN: 5, PROG: 0, },
{ SYSTEM: 8002, PHASE: 'B', DISC: 'Electrical', TOTAL: 6, ACTUAL: 0, REMAIN: 6, PROG: 0, },
{ SYSTEM: 8002, PHASE: 'B', DISC: 'Instrument', TOTAL: 127, ACTUAL: 0, REMAIN: 127, PROG: 0, },
{ SYSTEM: 8002, PHASE: 'PA', DISC: 'Piping', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 8002, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 24, ACTUAL: 3, REMAIN: 21, PROG: 12.5, },
{ SYSTEM: 8002, PHASE: 'PA', DISC: 'Electrical', TOTAL: 3, ACTUAL: 0, REMAIN: 3, PROG: 0, },
{ SYSTEM: 8002, PHASE: 'PA', DISC: 'Instrument', TOTAL: 235, ACTUAL: 19, REMAIN: 216, PROG: 8.09, },
{ SYSTEM: 8002, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 98, ACTUAL: 7, REMAIN: 91, PROG: 7.14, },
{ SYSTEM: 8002, PHASE: 'PB', DISC: 'Electrical', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 8002, PHASE: 'PB', DISC: 'Instrument', TOTAL: 53, ACTUAL: 9, REMAIN: 44, PROG: 16.98, },
{ SYSTEM: 8002, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 52, ACTUAL: 19, REMAIN: 33, PROG: 36.54, },
{ SYSTEM: 8002, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 52, ACTUAL: 14, REMAIN: 38, PROG: 26.92, },
{ SYSTEM: 8002, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 52, ACTUAL: 12, REMAIN: 40, PROG: 23.08, },
{ SYSTEM: 8002, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 52, ACTUAL: 12, REMAIN: 40, PROG: 23.08, },
{ SYSTEM: 8002, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 52, ACTUAL: 10, REMAIN: 42, PROG: 19.23, },
{ SYSTEM: 8002, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 52, ACTUAL: 0, REMAIN: 52, PROG: 0, },
{ SYSTEM: 8002, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 52, ACTUAL: 0, REMAIN: 52, PROG: 0, },
{ SYSTEM: 8011, PHASE: 'A', DISC: 'Safety', TOTAL: 58, ACTUAL: 0, REMAIN: 58, PROG: 0, },
{ SYSTEM: 8011, PHASE: 'A', DISC: 'Piping', TOTAL: 816, ACTUAL: 459, REMAIN: 357, PROG: 56.25, },
{ SYSTEM: 8011, PHASE: 'A', DISC: 'Mechanical', TOTAL: 21, ACTUAL: 19, REMAIN: 2, PROG: 90.48, },
{ SYSTEM: 8011, PHASE: 'A', DISC: 'Electrical', TOTAL: 49, ACTUAL: 7, REMAIN: 42, PROG: 14.29, },
{ SYSTEM: 8011, PHASE: 'A', DISC: 'Instrument', TOTAL: 90, ACTUAL: 46, REMAIN: 44, PROG: 51.11, },
{ SYSTEM: 8011, PHASE: 'B', DISC: 'Piping', TOTAL: 5, ACTUAL: 0, REMAIN: 5, PROG: 0, },
{ SYSTEM: 8011, PHASE: 'B', DISC: 'Mechanical', TOTAL: 70, ACTUAL: 0, REMAIN: 70, PROG: 0, },
{ SYSTEM: 8011, PHASE: 'B', DISC: 'Electrical', TOTAL: 22, ACTUAL: 0, REMAIN: 22, PROG: 0, },
{ SYSTEM: 8011, PHASE: 'B', DISC: 'Instrument', TOTAL: 15, ACTUAL: 0, REMAIN: 15, PROG: 0, },
{ SYSTEM: 8011, PHASE: 'PA', DISC: 'Structure', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8011, PHASE: 'PA', DISC: 'Piping', TOTAL: 23, ACTUAL: 11, REMAIN: 12, PROG: 47.83, },
{ SYSTEM: 8011, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 81, ACTUAL: 73, REMAIN: 8, PROG: 90.12, },
{ SYSTEM: 8011, PHASE: 'PA', DISC: 'Electrical', TOTAL: 15, ACTUAL: 14, REMAIN: 1, PROG: 93.33, },
{ SYSTEM: 8011, PHASE: 'PB', DISC: 'Company', TOTAL: 3, ACTUAL: 1, REMAIN: 2, PROG: 33.33, },
{ SYSTEM: 8011, PHASE: 'PB', DISC: 'Piping', TOTAL: 260, ACTUAL: 223, REMAIN: 37, PROG: 85.77, },
{ SYSTEM: 8011, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 23, ACTUAL: 21, REMAIN: 2, PROG: 91.3, },
{ SYSTEM: 8011, PHASE: 'PB', DISC: 'Electrical', TOTAL: 31, ACTUAL: 30, REMAIN: 1, PROG: 96.77, },
{ SYSTEM: 8011, PHASE: 'PB', DISC: 'Instrument', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8011, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 134, ACTUAL: 115, REMAIN: 19, PROG: 85.82, },
{ SYSTEM: 8011, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 134, ACTUAL: 111, REMAIN: 23, PROG: 82.84, },
{ SYSTEM: 8011, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 134, ACTUAL: 84, REMAIN: 50, PROG: 62.69, },
{ SYSTEM: 8011, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 134, ACTUAL: 84, REMAIN: 50, PROG: 62.69, },
{ SYSTEM: 8011, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 134, ACTUAL: 84, REMAIN: 50, PROG: 62.69, },
{ SYSTEM: 8011, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 134, ACTUAL: 65, REMAIN: 69, PROG: 48.51, },
{ SYSTEM: 8011, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 134, ACTUAL: 31, REMAIN: 103, PROG: 23.13, },
{ SYSTEM: 8012, PHASE: 'A', DISC: 'Piping', TOTAL: 308, ACTUAL: 147, REMAIN: 161, PROG: 47.73, },
{ SYSTEM: 8012, PHASE: 'B', DISC: 'Piping', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 8012, PHASE: 'PA', DISC: 'Piping', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8012, PHASE: 'PB', DISC: 'Piping', TOTAL: 34, ACTUAL: 32, REMAIN: 2, PROG: 94.12, },
{ SYSTEM: 8012, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 50, ACTUAL: 44, REMAIN: 6, PROG: 88, },
{ SYSTEM: 8012, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 50, ACTUAL: 45, REMAIN: 5, PROG: 90, },
{ SYSTEM: 8012, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 50, ACTUAL: 22, REMAIN: 28, PROG: 44, },
{ SYSTEM: 8012, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 50, ACTUAL: 22, REMAIN: 28, PROG: 44, },
{ SYSTEM: 8012, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 50, ACTUAL: 22, REMAIN: 28, PROG: 44, },
{ SYSTEM: 8012, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 50, ACTUAL: 19, REMAIN: 31, PROG: 38, },
{ SYSTEM: 8012, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 50, ACTUAL: 17, REMAIN: 33, PROG: 34, },
{ SYSTEM: 8014, PHASE: 'A', DISC: 'Piping', TOTAL: 152, ACTUAL: 142, REMAIN: 10, PROG: 93.42, },
{ SYSTEM: 8014, PHASE: 'A', DISC: 'Mechanical', TOTAL: 16, ACTUAL: 15, REMAIN: 1, PROG: 93.75, },
{ SYSTEM: 8014, PHASE: 'A', DISC: 'Electrical', TOTAL: 18, ACTUAL: 18, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8014, PHASE: 'A', DISC: 'Instrument', TOTAL: 63, ACTUAL: 58, REMAIN: 5, PROG: 92.06, },
{ SYSTEM: 8014, PHASE: 'B', DISC: 'Piping', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 8014, PHASE: 'B', DISC: 'Mechanical', TOTAL: 6, ACTUAL: 0, REMAIN: 6, PROG: 0, },
{ SYSTEM: 8014, PHASE: 'B', DISC: 'Electrical', TOTAL: 6, ACTUAL: 0, REMAIN: 6, PROG: 0, },
{ SYSTEM: 8014, PHASE: 'B', DISC: 'Instrument', TOTAL: 10, ACTUAL: 0, REMAIN: 10, PROG: 0, },
{ SYSTEM: 8014, PHASE: 'PA', DISC: 'Piping', TOTAL: 14, ACTUAL: 13, REMAIN: 1, PROG: 92.86, },
{ SYSTEM: 8014, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 29, ACTUAL: 29, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8014, PHASE: 'PA', DISC: 'Electrical', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8014, PHASE: 'PA', DISC: 'Instrument', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 8014, PHASE: 'PB', DISC: 'Piping', TOTAL: 24, ACTUAL: 23, REMAIN: 1, PROG: 95.83, },
{ SYSTEM: 8014, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 40, ACTUAL: 40, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8014, PHASE: 'PB', DISC: 'Electrical', TOTAL: 5, ACTUAL: 4, REMAIN: 1, PROG: 80, },
{ SYSTEM: 8014, PHASE: 'PB', DISC: 'Instrument', TOTAL: 19, ACTUAL: 16, REMAIN: 3, PROG: 84.21, },
{ SYSTEM: 8014, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 25, ACTUAL: 24, REMAIN: 1, PROG: 96, },
{ SYSTEM: 8014, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 25, ACTUAL: 24, REMAIN: 1, PROG: 96, },
{ SYSTEM: 8014, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 25, ACTUAL: 24, REMAIN: 1, PROG: 96, },
{ SYSTEM: 8014, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 25, ACTUAL: 24, REMAIN: 1, PROG: 96, },
{ SYSTEM: 8014, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 25, ACTUAL: 24, REMAIN: 1, PROG: 96, },
{ SYSTEM: 8014, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 25, ACTUAL: 24, REMAIN: 1, PROG: 96, },
{ SYSTEM: 8014, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 25, ACTUAL: 22, REMAIN: 3, PROG: 88, },
{ SYSTEM: 8016, PHASE: 'A', DISC: 'Piping', TOTAL: 444, ACTUAL: 292, REMAIN: 152, PROG: 65.77, },
{ SYSTEM: 8016, PHASE: 'A', DISC: 'Mechanical', TOTAL: 88, ACTUAL: 48, REMAIN: 40, PROG: 54.55, },
{ SYSTEM: 8016, PHASE: 'A', DISC: 'Electrical', TOTAL: 42, ACTUAL: 18, REMAIN: 24, PROG: 42.86, },
{ SYSTEM: 8016, PHASE: 'A', DISC: 'Instrument', TOTAL: 233, ACTUAL: 112, REMAIN: 121, PROG: 48.07, },
{ SYSTEM: 8016, PHASE: 'B', DISC: 'Piping', TOTAL: 3, ACTUAL: 0, REMAIN: 3, PROG: 0, },
{ SYSTEM: 8016, PHASE: 'B', DISC: 'Mechanical', TOTAL: 11, ACTUAL: 0, REMAIN: 11, PROG: 0, },
{ SYSTEM: 8016, PHASE: 'B', DISC: 'Electrical', TOTAL: 18, ACTUAL: 0, REMAIN: 18, PROG: 0, },
{ SYSTEM: 8016, PHASE: 'B', DISC: 'Instrument', TOTAL: 43, ACTUAL: 0, REMAIN: 43, PROG: 0, },
{ SYSTEM: 8016, PHASE: 'PA', DISC: 'Piping', TOTAL: 9, ACTUAL: 6, REMAIN: 3, PROG: 66.67, },
{ SYSTEM: 8016, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 52, ACTUAL: 50, REMAIN: 2, PROG: 96.15, },
{ SYSTEM: 8016, PHASE: 'PA', DISC: 'Electrical', TOTAL: 6, ACTUAL: 5, REMAIN: 1, PROG: 83.33, },
{ SYSTEM: 8016, PHASE: 'PA', DISC: 'Instrument', TOTAL: 2, ACTUAL: 1, REMAIN: 1, PROG: 50, },
{ SYSTEM: 8016, PHASE: 'PB', DISC: 'Piping', TOTAL: 66, ACTUAL: 60, REMAIN: 6, PROG: 90.91, },
{ SYSTEM: 8016, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 52, ACTUAL: 44, REMAIN: 8, PROG: 84.62, },
{ SYSTEM: 8016, PHASE: 'PB', DISC: 'Electrical', TOTAL: 30, ACTUAL: 29, REMAIN: 1, PROG: 96.67, },
{ SYSTEM: 8016, PHASE: 'PB', DISC: 'Instrument', TOTAL: 20, ACTUAL: 17, REMAIN: 3, PROG: 85, },
{ SYSTEM: 8016, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 73, ACTUAL: 63, REMAIN: 10, PROG: 86.3, },
{ SYSTEM: 8016, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 73, ACTUAL: 64, REMAIN: 9, PROG: 87.67, },
{ SYSTEM: 8016, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 73, ACTUAL: 51, REMAIN: 22, PROG: 69.86, },
{ SYSTEM: 8016, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 73, ACTUAL: 52, REMAIN: 21, PROG: 71.23, },
{ SYSTEM: 8016, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 73, ACTUAL: 53, REMAIN: 20, PROG: 72.6, },
{ SYSTEM: 8016, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 73, ACTUAL: 45, REMAIN: 28, PROG: 61.64, },
{ SYSTEM: 8016, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 73, ACTUAL: 27, REMAIN: 46, PROG: 36.99, },
{ SYSTEM: 8021, PHASE: 'A', DISC: 'Piping', TOTAL: 985, ACTUAL: 562, REMAIN: 423, PROG: 57.06, },
{ SYSTEM: 8021, PHASE: 'A', DISC: 'Mechanical', TOTAL: 23, ACTUAL: 22, REMAIN: 1, PROG: 95.65, },
{ SYSTEM: 8021, PHASE: 'A', DISC: 'Electrical', TOTAL: 59, ACTUAL: 13, REMAIN: 46, PROG: 22.03, },
{ SYSTEM: 8021, PHASE: 'A', DISC: 'Instrument', TOTAL: 674, ACTUAL: 139, REMAIN: 535, PROG: 20.62, },
{ SYSTEM: 8021, PHASE: 'B', DISC: 'Piping', TOTAL: 5, ACTUAL: 0, REMAIN: 5, PROG: 0, },
{ SYSTEM: 8021, PHASE: 'B', DISC: 'Mechanical', TOTAL: 3, ACTUAL: 0, REMAIN: 3, PROG: 0, },
{ SYSTEM: 8021, PHASE: 'B', DISC: 'Electrical', TOTAL: 28, ACTUAL: 0, REMAIN: 28, PROG: 0, },
{ SYSTEM: 8021, PHASE: 'B', DISC: 'Instrument', TOTAL: 104, ACTUAL: 0, REMAIN: 104, PROG: 0, },
{ SYSTEM: 8021, PHASE: 'PA', DISC: 'Piping', TOTAL: 28, ACTUAL: 10, REMAIN: 18, PROG: 35.71, },
{ SYSTEM: 8021, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 17, ACTUAL: 16, REMAIN: 1, PROG: 94.12, },
{ SYSTEM: 8021, PHASE: 'PA', DISC: 'Electrical', TOTAL: 24, ACTUAL: 24, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8021, PHASE: 'PA', DISC: 'Instrument', TOTAL: 9, ACTUAL: 6, REMAIN: 3, PROG: 66.67, },
{ SYSTEM: 8021, PHASE: 'PB', DISC: 'Piping', TOTAL: 152, ACTUAL: 116, REMAIN: 36, PROG: 76.32, },
{ SYSTEM: 8021, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 37, ACTUAL: 26, REMAIN: 11, PROG: 70.27, },
{ SYSTEM: 8021, PHASE: 'PB', DISC: 'Electrical', TOTAL: 41, ACTUAL: 38, REMAIN: 3, PROG: 92.68, },
{ SYSTEM: 8021, PHASE: 'PB', DISC: 'Instrument', TOTAL: 74, ACTUAL: 71, REMAIN: 3, PROG: 95.95, },
{ SYSTEM: 8021, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 162, ACTUAL: 149, REMAIN: 13, PROG: 91.98, },
{ SYSTEM: 8021, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 162, ACTUAL: 146, REMAIN: 16, PROG: 90.12, },
{ SYSTEM: 8021, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 162, ACTUAL: 105, REMAIN: 57, PROG: 64.81, },
{ SYSTEM: 8021, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 162, ACTUAL: 101, REMAIN: 61, PROG: 62.35, },
{ SYSTEM: 8021, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 162, ACTUAL: 98, REMAIN: 64, PROG: 60.49, },
{ SYSTEM: 8021, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 162, ACTUAL: 76, REMAIN: 86, PROG: 46.91, },
{ SYSTEM: 8021, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 162, ACTUAL: 36, REMAIN: 126, PROG: 22.22, },
{ SYSTEM: 8023, PHASE: 'A', DISC: 'Piping', TOTAL: 176, ACTUAL: 90, REMAIN: 86, PROG: 51.14, },
{ SYSTEM: 8023, PHASE: 'A', DISC: 'Mechanical', TOTAL: 10, ACTUAL: 10, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8023, PHASE: 'A', DISC: 'Electrical', TOTAL: 36, ACTUAL: 8, REMAIN: 28, PROG: 22.22, },
{ SYSTEM: 8023, PHASE: 'A', DISC: 'Instrument', TOTAL: 263, ACTUAL: 41, REMAIN: 222, PROG: 15.59, },
{ SYSTEM: 8023, PHASE: 'B', DISC: 'Piping', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 8023, PHASE: 'B', DISC: 'Mechanical', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 8023, PHASE: 'B', DISC: 'Electrical', TOTAL: 18, ACTUAL: 0, REMAIN: 18, PROG: 0, },
{ SYSTEM: 8023, PHASE: 'B', DISC: 'Instrument', TOTAL: 45, ACTUAL: 0, REMAIN: 45, PROG: 0, },
{ SYSTEM: 8023, PHASE: 'PA', DISC: 'Piping', TOTAL: 10, ACTUAL: 7, REMAIN: 3, PROG: 70, },
{ SYSTEM: 8023, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 5, ACTUAL: 5, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8023, PHASE: 'PA', DISC: 'Electrical', TOTAL: 12, ACTUAL: 12, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8023, PHASE: 'PA', DISC: 'Instrument', TOTAL: 4, ACTUAL: 2, REMAIN: 2, PROG: 50, },
{ SYSTEM: 8023, PHASE: 'PB', DISC: 'Piping', TOTAL: 39, ACTUAL: 35, REMAIN: 4, PROG: 89.74, },
{ SYSTEM: 8023, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 31, ACTUAL: 27, REMAIN: 4, PROG: 87.1, },
{ SYSTEM: 8023, PHASE: 'PB', DISC: 'Electrical', TOTAL: 23, ACTUAL: 20, REMAIN: 3, PROG: 86.96, },
{ SYSTEM: 8023, PHASE: 'PB', DISC: 'Instrument', TOTAL: 45, ACTUAL: 44, REMAIN: 1, PROG: 97.78, },
{ SYSTEM: 8023, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 28, ACTUAL: 21, REMAIN: 7, PROG: 75, },
{ SYSTEM: 8023, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 28, ACTUAL: 21, REMAIN: 7, PROG: 75, },
{ SYSTEM: 8023, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 28, ACTUAL: 15, REMAIN: 13, PROG: 53.57, },
{ SYSTEM: 8023, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 28, ACTUAL: 15, REMAIN: 13, PROG: 53.57, },
{ SYSTEM: 8023, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 28, ACTUAL: 15, REMAIN: 13, PROG: 53.57, },
{ SYSTEM: 8023, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 28, ACTUAL: 15, REMAIN: 13, PROG: 53.57, },
{ SYSTEM: 8023, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 28, ACTUAL: 9, REMAIN: 19, PROG: 32.14, },
{ SYSTEM: 8030, PHASE: 'A', DISC: 'Piping', TOTAL: 32, ACTUAL: 24, REMAIN: 8, PROG: 75, },
{ SYSTEM: 8030, PHASE: 'A', DISC: 'Mechanical', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8030, PHASE: 'A', DISC: 'Electrical', TOTAL: 20, ACTUAL: 11, REMAIN: 9, PROG: 55, },
{ SYSTEM: 8030, PHASE: 'A', DISC: 'Instrument', TOTAL: 10, ACTUAL: 8, REMAIN: 2, PROG: 80, },
{ SYSTEM: 8030, PHASE: 'B', DISC: 'Piping', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 8030, PHASE: 'B', DISC: 'Mechanical', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 8030, PHASE: 'B', DISC: 'Electrical', TOTAL: 9, ACTUAL: 0, REMAIN: 9, PROG: 0, },
{ SYSTEM: 8030, PHASE: 'B', DISC: 'Instrument', TOTAL: 22, ACTUAL: 8, REMAIN: 14, PROG: 36.36, },
{ SYSTEM: 8030, PHASE: 'PA', DISC: 'Electrical', TOTAL: 5, ACTUAL: 5, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8030, PHASE: 'PA', DISC: 'Instrument', TOTAL: 27, ACTUAL: 20, REMAIN: 7, PROG: 74.07, },
{ SYSTEM: 8030, PHASE: 'PB', DISC: 'Piping', TOTAL: 3, ACTUAL: 1, REMAIN: 2, PROG: 33.33, },
{ SYSTEM: 8030, PHASE: 'PB', DISC: 'Electrical', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 8030, PHASE: 'PB', DISC: 'Instrument', TOTAL: 4, ACTUAL: 1, REMAIN: 3, PROG: 25, },
{ SYSTEM: 8030, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 5, ACTUAL: 5, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8030, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 5, ACTUAL: 5, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8030, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 5, ACTUAL: 4, REMAIN: 1, PROG: 80, },
{ SYSTEM: 8030, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 5, ACTUAL: 4, REMAIN: 1, PROG: 80, },
{ SYSTEM: 8030, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 5, ACTUAL: 4, REMAIN: 1, PROG: 80, },
{ SYSTEM: 8030, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 5, ACTUAL: 4, REMAIN: 1, PROG: 80, },
{ SYSTEM: 8030, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 5, ACTUAL: 3, REMAIN: 2, PROG: 60, },
{ SYSTEM: 8040, PHASE: 'A', DISC: 'Piping', TOTAL: 321, ACTUAL: 275, REMAIN: 46, PROG: 85.67, },
{ SYSTEM: 8040, PHASE: 'A', DISC: 'Mechanical', TOTAL: 43, ACTUAL: 26, REMAIN: 17, PROG: 60.47, },
{ SYSTEM: 8040, PHASE: 'A', DISC: 'Electrical', TOTAL: 59, ACTUAL: 36, REMAIN: 23, PROG: 61.02, },
{ SYSTEM: 8040, PHASE: 'A', DISC: 'Instrument', TOTAL: 534, ACTUAL: 279, REMAIN: 255, PROG: 52.25, },
{ SYSTEM: 8040, PHASE: 'B', DISC: 'Piping', TOTAL: 5, ACTUAL: 0, REMAIN: 5, PROG: 0, },
{ SYSTEM: 8040, PHASE: 'B', DISC: 'Mechanical', TOTAL: 20, ACTUAL: 0, REMAIN: 20, PROG: 0, },
{ SYSTEM: 8040, PHASE: 'B', DISC: 'Electrical', TOTAL: 24, ACTUAL: 0, REMAIN: 24, PROG: 0, },
{ SYSTEM: 8040, PHASE: 'B', DISC: 'Instrument', TOTAL: 74, ACTUAL: 0, REMAIN: 74, PROG: 0, },
{ SYSTEM: 8040, PHASE: 'PA', DISC: 'Insulation', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8040, PHASE: 'PA', DISC: 'Piping', TOTAL: 35, ACTUAL: 25, REMAIN: 10, PROG: 71.43, },
{ SYSTEM: 8040, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 62, ACTUAL: 56, REMAIN: 6, PROG: 90.32, },
{ SYSTEM: 8040, PHASE: 'PA', DISC: 'Electrical', TOTAL: 42, ACTUAL: 31, REMAIN: 11, PROG: 73.81, },
{ SYSTEM: 8040, PHASE: 'PA', DISC: 'Instrument', TOTAL: 77, ACTUAL: 35, REMAIN: 42, PROG: 45.45, },
{ SYSTEM: 8040, PHASE: 'PB', DISC: 'Company', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8040, PHASE: 'PB', DISC: 'Insulation', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 8040, PHASE: 'PB', DISC: 'Piping', TOTAL: 116, ACTUAL: 103, REMAIN: 13, PROG: 88.79, },
{ SYSTEM: 8040, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 79, ACTUAL: 67, REMAIN: 12, PROG: 84.81, },
{ SYSTEM: 8040, PHASE: 'PB', DISC: 'Electrical', TOTAL: 21, ACTUAL: 14, REMAIN: 7, PROG: 66.67, },
{ SYSTEM: 8040, PHASE: 'PB', DISC: 'Instrument', TOTAL: 112, ACTUAL: 81, REMAIN: 31, PROG: 72.32, },
{ SYSTEM: 8040, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 51, ACTUAL: 51, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8040, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 51, ACTUAL: 51, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8040, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 51, ACTUAL: 50, REMAIN: 1, PROG: 98.04, },
{ SYSTEM: 8040, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 51, ACTUAL: 50, REMAIN: 1, PROG: 98.04, },
{ SYSTEM: 8040, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 51, ACTUAL: 50, REMAIN: 1, PROG: 98.04, },
{ SYSTEM: 8040, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 51, ACTUAL: 44, REMAIN: 7, PROG: 86.27, },
{ SYSTEM: 8040, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 51, ACTUAL: 30, REMAIN: 21, PROG: 58.82, },
{ SYSTEM: 8060, PHASE: 'A', DISC: 'Piping', TOTAL: 730, ACTUAL: 391, REMAIN: 339, PROG: 53.56, },
{ SYSTEM: 8060, PHASE: 'A', DISC: 'Mechanical', TOTAL: 38, ACTUAL: 21, REMAIN: 17, PROG: 55.26, },
{ SYSTEM: 8060, PHASE: 'A', DISC: 'Electrical', TOTAL: 22, ACTUAL: 7, REMAIN: 15, PROG: 31.82, },
{ SYSTEM: 8060, PHASE: 'A', DISC: 'Instrument', TOTAL: 175, ACTUAL: 27, REMAIN: 148, PROG: 15.43, },
{ SYSTEM: 8060, PHASE: 'B', DISC: 'Piping', TOTAL: 5, ACTUAL: 0, REMAIN: 5, PROG: 0, },
{ SYSTEM: 8060, PHASE: 'B', DISC: 'Mechanical', TOTAL: 6, ACTUAL: 0, REMAIN: 6, PROG: 0, },
{ SYSTEM: 8060, PHASE: 'B', DISC: 'Electrical', TOTAL: 6, ACTUAL: 0, REMAIN: 6, PROG: 0, },
{ SYSTEM: 8060, PHASE: 'B', DISC: 'Instrument', TOTAL: 32, ACTUAL: 0, REMAIN: 32, PROG: 0, },
{ SYSTEM: 8060, PHASE: 'PA', DISC: 'Piping', TOTAL: 18, ACTUAL: 8, REMAIN: 10, PROG: 44.44, },
{ SYSTEM: 8060, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 39, ACTUAL: 32, REMAIN: 7, PROG: 82.05, },
{ SYSTEM: 8060, PHASE: 'PA', DISC: 'Electrical', TOTAL: 6, ACTUAL: 6, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8060, PHASE: 'PA', DISC: 'Instrument', TOTAL: 8, ACTUAL: 8, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8060, PHASE: 'PB', DISC: 'Piping', TOTAL: 185, ACTUAL: 155, REMAIN: 30, PROG: 83.78, },
{ SYSTEM: 8060, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 37, ACTUAL: 30, REMAIN: 7, PROG: 81.08, },
{ SYSTEM: 8060, PHASE: 'PB', DISC: 'Electrical', TOTAL: 4, ACTUAL: 4, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8060, PHASE: 'PB', DISC: 'Instrument', TOTAL: 14, ACTUAL: 13, REMAIN: 1, PROG: 92.86, },
{ SYSTEM: 8060, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 120, ACTUAL: 107, REMAIN: 13, PROG: 89.17, },
{ SYSTEM: 8060, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 120, ACTUAL: 105, REMAIN: 15, PROG: 87.5, },
{ SYSTEM: 8060, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 120, ACTUAL: 66, REMAIN: 54, PROG: 55, },
{ SYSTEM: 8060, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 120, ACTUAL: 65, REMAIN: 55, PROG: 54.17, },
{ SYSTEM: 8060, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 120, ACTUAL: 65, REMAIN: 55, PROG: 54.17, },
{ SYSTEM: 8060, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 120, ACTUAL: 56, REMAIN: 64, PROG: 46.67, },
{ SYSTEM: 8060, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 120, ACTUAL: 34, REMAIN: 86, PROG: 28.33, },
{ SYSTEM: 8063, PHASE: 'A', DISC: 'Piping', TOTAL: 212, ACTUAL: 207, REMAIN: 5, PROG: 97.64, },
{ SYSTEM: 8063, PHASE: 'A', DISC: 'Mechanical', TOTAL: 16, ACTUAL: 15, REMAIN: 1, PROG: 93.75, },
{ SYSTEM: 8063, PHASE: 'A', DISC: 'Electrical', TOTAL: 22, ACTUAL: 14, REMAIN: 8, PROG: 63.64, },
{ SYSTEM: 8063, PHASE: 'A', DISC: 'Instrument', TOTAL: 160, ACTUAL: 138, REMAIN: 22, PROG: 86.25, },
{ SYSTEM: 8063, PHASE: 'B', DISC: 'Piping', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 8063, PHASE: 'B', DISC: 'Mechanical', TOTAL: 6, ACTUAL: 0, REMAIN: 6, PROG: 0, },
{ SYSTEM: 8063, PHASE: 'B', DISC: 'Electrical', TOTAL: 8, ACTUAL: 0, REMAIN: 8, PROG: 0, },
{ SYSTEM: 8063, PHASE: 'B', DISC: 'Instrument', TOTAL: 22, ACTUAL: 0, REMAIN: 22, PROG: 0, },
{ SYSTEM: 8063, PHASE: 'PA', DISC: 'Piping', TOTAL: 21, ACTUAL: 21, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8063, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 37, ACTUAL: 37, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8063, PHASE: 'PA', DISC: 'Electrical', TOTAL: 5, ACTUAL: 3, REMAIN: 2, PROG: 60, },
{ SYSTEM: 8063, PHASE: 'PA', DISC: 'Instrument', TOTAL: 7, ACTUAL: 3, REMAIN: 4, PROG: 42.86, },
{ SYSTEM: 8063, PHASE: 'PB', DISC: 'Piping', TOTAL: 20, ACTUAL: 18, REMAIN: 2, PROG: 90, },
{ SYSTEM: 8063, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 48, ACTUAL: 48, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8063, PHASE: 'PB', DISC: 'Electrical', TOTAL: 10, ACTUAL: 8, REMAIN: 2, PROG: 80, },
{ SYSTEM: 8063, PHASE: 'PB', DISC: 'Instrument', TOTAL: 61, ACTUAL: 57, REMAIN: 4, PROG: 93.44, },
{ SYSTEM: 8063, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 35, ACTUAL: 35, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8063, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 35, ACTUAL: 35, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8063, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 35, ACTUAL: 35, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8063, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 35, ACTUAL: 35, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8063, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 35, ACTUAL: 35, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8063, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 35, ACTUAL: 35, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8063, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 35, ACTUAL: 32, REMAIN: 3, PROG: 91.43, },
{ SYSTEM: 8070, PHASE: 'A', DISC: 'Piping', TOTAL: 38, ACTUAL: 35, REMAIN: 3, PROG: 92.11, },
{ SYSTEM: 8070, PHASE: 'A', DISC: 'Mechanical', TOTAL: 35, ACTUAL: 31, REMAIN: 4, PROG: 88.57, },
{ SYSTEM: 8070, PHASE: 'A', DISC: 'Electrical', TOTAL: 34, ACTUAL: 12, REMAIN: 22, PROG: 35.29, },
{ SYSTEM: 8070, PHASE: 'A', DISC: 'Instrument', TOTAL: 124, ACTUAL: 110, REMAIN: 14, PROG: 88.71, },
{ SYSTEM: 8070, PHASE: 'A', DISC: 'Telecom', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 8070, PHASE: 'B', DISC: 'Piping', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 8070, PHASE: 'B', DISC: 'Mechanical', TOTAL: 7, ACTUAL: 0, REMAIN: 7, PROG: 0, },
{ SYSTEM: 8070, PHASE: 'B', DISC: 'Electrical', TOTAL: 8, ACTUAL: 0, REMAIN: 8, PROG: 0, },
{ SYSTEM: 8070, PHASE: 'B', DISC: 'Instrument', TOTAL: 20, ACTUAL: 0, REMAIN: 20, PROG: 0, },
{ SYSTEM: 8070, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 39, ACTUAL: 35, REMAIN: 4, PROG: 89.74, },
{ SYSTEM: 8070, PHASE: 'PA', DISC: 'Electrical', TOTAL: 34, ACTUAL: 30, REMAIN: 4, PROG: 88.24, },
{ SYSTEM: 8070, PHASE: 'PA', DISC: 'Instrument', TOTAL: 15, ACTUAL: 13, REMAIN: 2, PROG: 86.67, },
{ SYSTEM: 8070, PHASE: 'PA', DISC: 'Telecom', TOTAL: 2, ACTUAL: 1, REMAIN: 1, PROG: 50, },
{ SYSTEM: 8070, PHASE: 'PB', DISC: 'Piping', TOTAL: 4, ACTUAL: 1, REMAIN: 3, PROG: 25, },
{ SYSTEM: 8070, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 30, ACTUAL: 29, REMAIN: 1, PROG: 96.67, },
{ SYSTEM: 8070, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 6, ACTUAL: 6, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8070, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 6, ACTUAL: 6, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8070, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 6, ACTUAL: 6, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8070, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 6, ACTUAL: 6, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8070, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 6, ACTUAL: 6, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8070, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 6, ACTUAL: 5, REMAIN: 1, PROG: 83.33, },
{ SYSTEM: 8070, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 6, ACTUAL: 6, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8080, PHASE: 'A', DISC: 'Piping', TOTAL: 1035, ACTUAL: 544, REMAIN: 491, PROG: 52.56, },
{ SYSTEM: 8080, PHASE: 'A', DISC: 'Mechanical', TOTAL: 77, ACTUAL: 23, REMAIN: 54, PROG: 29.87, },
{ SYSTEM: 8080, PHASE: 'A', DISC: 'Electrical', TOTAL: 63, ACTUAL: 21, REMAIN: 42, PROG: 33.33, },
{ SYSTEM: 8080, PHASE: 'A', DISC: 'Instrument', TOTAL: 950, ACTUAL: 138, REMAIN: 812, PROG: 14.53, },
{ SYSTEM: 8080, PHASE: 'A', DISC: 'HVAC', TOTAL: 18, ACTUAL: 15, REMAIN: 3, PROG: 83.33, },
{ SYSTEM: 8080, PHASE: 'B', DISC: 'Piping', TOTAL: 13, ACTUAL: 0, REMAIN: 13, PROG: 0, },
{ SYSTEM: 8080, PHASE: 'B', DISC: 'Mechanical', TOTAL: 21, ACTUAL: 0, REMAIN: 21, PROG: 0, },
{ SYSTEM: 8080, PHASE: 'B', DISC: 'Electrical', TOTAL: 36, ACTUAL: 0, REMAIN: 36, PROG: 0, },
{ SYSTEM: 8080, PHASE: 'B', DISC: 'Instrument', TOTAL: 135, ACTUAL: 0, REMAIN: 135, PROG: 0, },
{ SYSTEM: 8080, PHASE: 'PA', DISC: 'Piping', TOTAL: 17, ACTUAL: 8, REMAIN: 9, PROG: 47.06, },
{ SYSTEM: 8080, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 8080, PHASE: 'PA', DISC: 'Electrical', TOTAL: 40, ACTUAL: 38, REMAIN: 2, PROG: 95, },
{ SYSTEM: 8080, PHASE: 'PA', DISC: 'Instrument', TOTAL: 3, ACTUAL: 0, REMAIN: 3, PROG: 0, },
{ SYSTEM: 8080, PHASE: 'PA', DISC: 'HVAC', TOTAL: 116, ACTUAL: 112, REMAIN: 4, PROG: 96.55, },
{ SYSTEM: 8080, PHASE: 'PB', DISC: 'Piping', TOTAL: 261, ACTUAL: 218, REMAIN: 43, PROG: 83.52, },
{ SYSTEM: 8080, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8080, PHASE: 'PB', DISC: 'Electrical', TOTAL: 26, ACTUAL: 25, REMAIN: 1, PROG: 96.15, },
{ SYSTEM: 8080, PHASE: 'PB', DISC: 'Instrument', TOTAL: 85, ACTUAL: 83, REMAIN: 2, PROG: 97.65, },
{ SYSTEM: 8080, PHASE: 'PB', DISC: 'HVAC', TOTAL: 16, ACTUAL: 13, REMAIN: 3, PROG: 81.25, },
{ SYSTEM: 8080, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 166, ACTUAL: 147, REMAIN: 19, PROG: 88.55, },
{ SYSTEM: 8080, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 166, ACTUAL: 145, REMAIN: 21, PROG: 87.35, },
{ SYSTEM: 8080, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 166, ACTUAL: 116, REMAIN: 50, PROG: 69.88, },
{ SYSTEM: 8080, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 166, ACTUAL: 116, REMAIN: 50, PROG: 69.88, },
{ SYSTEM: 8080, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 166, ACTUAL: 88, REMAIN: 78, PROG: 53.01, },
{ SYSTEM: 8080, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 166, ACTUAL: 55, REMAIN: 111, PROG: 33.13, },
{ SYSTEM: 8080, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 166, ACTUAL: 24, REMAIN: 142, PROG: 14.46, },
{ SYSTEM: 8110, PHASE: 'A', DISC: 'Piping', TOTAL: 304, ACTUAL: 181, REMAIN: 123, PROG: 59.54, },
{ SYSTEM: 8110, PHASE: 'A', DISC: 'Instrument', TOTAL: 23, ACTUAL: 1, REMAIN: 22, PROG: 4.35, },
{ SYSTEM: 8110, PHASE: 'B', DISC: 'Piping', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 8110, PHASE: 'B', DISC: 'Instrument', TOTAL: 5, ACTUAL: 0, REMAIN: 5, PROG: 0, },
{ SYSTEM: 8110, PHASE: 'PA', DISC: 'Piping', TOTAL: 13, ACTUAL: 8, REMAIN: 5, PROG: 61.54, },
{ SYSTEM: 8110, PHASE: 'PB', DISC: 'Piping', TOTAL: 108, ACTUAL: 104, REMAIN: 4, PROG: 96.3, },
{ SYSTEM: 8110, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 50, ACTUAL: 46, REMAIN: 4, PROG: 92, },
{ SYSTEM: 8110, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 50, ACTUAL: 46, REMAIN: 4, PROG: 92, },
{ SYSTEM: 8110, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 50, ACTUAL: 33, REMAIN: 17, PROG: 66, },
{ SYSTEM: 8110, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 50, ACTUAL: 33, REMAIN: 17, PROG: 66, },
{ SYSTEM: 8110, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 50, ACTUAL: 33, REMAIN: 17, PROG: 66, },
{ SYSTEM: 8110, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 50, ACTUAL: 20, REMAIN: 30, PROG: 40, },
{ SYSTEM: 8110, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 50, ACTUAL: 16, REMAIN: 34, PROG: 32, },
{ SYSTEM: 8120, PHASE: 'A', DISC: 'Piping', TOTAL: 648, ACTUAL: 318, REMAIN: 330, PROG: 49.07, },
{ SYSTEM: 8120, PHASE: 'A', DISC: 'Mechanical', TOTAL: 94, ACTUAL: 87, REMAIN: 7, PROG: 92.55, },
{ SYSTEM: 8120, PHASE: 'A', DISC: 'Electrical', TOTAL: 77, ACTUAL: 58, REMAIN: 19, PROG: 75.32, },
{ SYSTEM: 8120, PHASE: 'A', DISC: 'Instrument', TOTAL: 769, ACTUAL: 557, REMAIN: 212, PROG: 72.43, },
{ SYSTEM: 8120, PHASE: 'B', DISC: 'Piping', TOTAL: 6, ACTUAL: 0, REMAIN: 6, PROG: 0, },
{ SYSTEM: 8120, PHASE: 'B', DISC: 'Mechanical', TOTAL: 21, ACTUAL: 0, REMAIN: 21, PROG: 0, },
{ SYSTEM: 8120, PHASE: 'B', DISC: 'Electrical', TOTAL: 38, ACTUAL: 0, REMAIN: 38, PROG: 0, },
{ SYSTEM: 8120, PHASE: 'B', DISC: 'Instrument', TOTAL: 128, ACTUAL: 0, REMAIN: 128, PROG: 0, },
{ SYSTEM: 8120, PHASE: 'PA', DISC: 'Structure', TOTAL: 3, ACTUAL: 3, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8120, PHASE: 'PA', DISC: 'Piping', TOTAL: 22, ACTUAL: 17, REMAIN: 5, PROG: 77.27, },
{ SYSTEM: 8120, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 177, ACTUAL: 175, REMAIN: 2, PROG: 98.87, },
{ SYSTEM: 8120, PHASE: 'PA', DISC: 'Electrical', TOTAL: 81, ACTUAL: 59, REMAIN: 22, PROG: 72.84, },
{ SYSTEM: 8120, PHASE: 'PA', DISC: 'Instrument', TOTAL: 71, ACTUAL: 62, REMAIN: 9, PROG: 87.32, },
{ SYSTEM: 8120, PHASE: 'PB', DISC: 'Piping', TOTAL: 76, ACTUAL: 61, REMAIN: 15, PROG: 80.26, },
{ SYSTEM: 8120, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 114, ACTUAL: 103, REMAIN: 11, PROG: 90.35, },
{ SYSTEM: 8120, PHASE: 'PB', DISC: 'Electrical', TOTAL: 43, ACTUAL: 22, REMAIN: 21, PROG: 51.16, },
{ SYSTEM: 8120, PHASE: 'PB', DISC: 'Instrument', TOTAL: 29, ACTUAL: 23, REMAIN: 6, PROG: 79.31, },
{ SYSTEM: 8120, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 106, ACTUAL: 97, REMAIN: 9, PROG: 91.51, },
{ SYSTEM: 8120, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 106, ACTUAL: 96, REMAIN: 10, PROG: 90.57, },
{ SYSTEM: 8120, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 106, ACTUAL: 49, REMAIN: 57, PROG: 46.23, },
{ SYSTEM: 8120, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 106, ACTUAL: 48, REMAIN: 58, PROG: 45.28, },
{ SYSTEM: 8120, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 106, ACTUAL: 47, REMAIN: 59, PROG: 44.34, },
{ SYSTEM: 8120, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 106, ACTUAL: 46, REMAIN: 60, PROG: 43.4, },
{ SYSTEM: 8120, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 106, ACTUAL: 32, REMAIN: 74, PROG: 30.19, },
{ SYSTEM: 8130, PHASE: 'A', DISC: 'Piping', TOTAL: 411, ACTUAL: 194, REMAIN: 217, PROG: 47.2, },
{ SYSTEM: 8130, PHASE: 'A', DISC: 'Mechanical', TOTAL: 46, ACTUAL: 9, REMAIN: 37, PROG: 19.57, },
{ SYSTEM: 8130, PHASE: 'A', DISC: 'Electrical', TOTAL: 12, ACTUAL: 10, REMAIN: 2, PROG: 83.33, },
{ SYSTEM: 8130, PHASE: 'A', DISC: 'Instrument', TOTAL: 542, ACTUAL: 170, REMAIN: 372, PROG: 31.37, },
{ SYSTEM: 8130, PHASE: 'B', DISC: 'Piping', TOTAL: 7, ACTUAL: 0, REMAIN: 7, PROG: 0, },
{ SYSTEM: 8130, PHASE: 'B', DISC: 'Mechanical', TOTAL: 36, ACTUAL: 0, REMAIN: 36, PROG: 0, },
{ SYSTEM: 8130, PHASE: 'B', DISC: 'Electrical', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 8130, PHASE: 'B', DISC: 'Instrument', TOTAL: 80, ACTUAL: 0, REMAIN: 80, PROG: 0, },
{ SYSTEM: 8130, PHASE: 'PA', DISC: 'Piping', TOTAL: 13, ACTUAL: 9, REMAIN: 4, PROG: 69.23, },
{ SYSTEM: 8130, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 9, ACTUAL: 5, REMAIN: 4, PROG: 55.56, },
{ SYSTEM: 8130, PHASE: 'PA', DISC: 'Electrical', TOTAL: 7, ACTUAL: 6, REMAIN: 1, PROG: 85.71, },
{ SYSTEM: 8130, PHASE: 'PA', DISC: 'Instrument', TOTAL: 25, ACTUAL: 25, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8130, PHASE: 'PB', DISC: 'Insulation', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 8130, PHASE: 'PB', DISC: 'Piping', TOTAL: 102, ACTUAL: 96, REMAIN: 6, PROG: 94.12, },
{ SYSTEM: 8130, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 58, ACTUAL: 51, REMAIN: 7, PROG: 87.93, },
{ SYSTEM: 8130, PHASE: 'PB', DISC: 'Electrical', TOTAL: 22, ACTUAL: 19, REMAIN: 3, PROG: 86.36, },
{ SYSTEM: 8130, PHASE: 'PB', DISC: 'Instrument', TOTAL: 55, ACTUAL: 40, REMAIN: 15, PROG: 72.73, },
{ SYSTEM: 8130, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 67, ACTUAL: 45, REMAIN: 22, PROG: 67.16, },
{ SYSTEM: 8130, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 67, ACTUAL: 45, REMAIN: 22, PROG: 67.16, },
{ SYSTEM: 8130, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 67, ACTUAL: 37, REMAIN: 30, PROG: 55.22, },
{ SYSTEM: 8130, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 67, ACTUAL: 37, REMAIN: 30, PROG: 55.22, },
{ SYSTEM: 8130, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 67, ACTUAL: 34, REMAIN: 33, PROG: 50.75, },
{ SYSTEM: 8130, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 67, ACTUAL: 24, REMAIN: 43, PROG: 35.82, },
{ SYSTEM: 8130, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 67, ACTUAL: 17, REMAIN: 50, PROG: 25.37, },
{ SYSTEM: 8150, PHASE: 'A', DISC: 'Piping', TOTAL: 378, ACTUAL: 114, REMAIN: 264, PROG: 30.16, },
{ SYSTEM: 8150, PHASE: 'A', DISC: 'Mechanical', TOTAL: 34, ACTUAL: 31, REMAIN: 3, PROG: 91.18, },
{ SYSTEM: 8150, PHASE: 'A', DISC: 'Electrical', TOTAL: 37, ACTUAL: 13, REMAIN: 24, PROG: 35.14, },
{ SYSTEM: 8150, PHASE: 'A', DISC: 'Instrument', TOTAL: 332, ACTUAL: 218, REMAIN: 114, PROG: 65.66, },
{ SYSTEM: 8150, PHASE: 'B', DISC: 'Piping', TOTAL: 3, ACTUAL: 0, REMAIN: 3, PROG: 0, },
{ SYSTEM: 8150, PHASE: 'B', DISC: 'Mechanical', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 8150, PHASE: 'B', DISC: 'Electrical', TOTAL: 14, ACTUAL: 0, REMAIN: 14, PROG: 0, },
{ SYSTEM: 8150, PHASE: 'B', DISC: 'Instrument', TOTAL: 70, ACTUAL: 0, REMAIN: 70, PROG: 0, },
{ SYSTEM: 8150, PHASE: 'PA', DISC: 'Piping', TOTAL: 7, ACTUAL: 7, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8150, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 137, ACTUAL: 134, REMAIN: 3, PROG: 97.81, },
{ SYSTEM: 8150, PHASE: 'PA', DISC: 'Electrical', TOTAL: 8, ACTUAL: 8, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8150, PHASE: 'PA', DISC: 'Instrument', TOTAL: 12, ACTUAL: 8, REMAIN: 4, PROG: 66.67, },
{ SYSTEM: 8150, PHASE: 'PB', DISC: 'Piping', TOTAL: 113, ACTUAL: 108, REMAIN: 5, PROG: 95.58, },
{ SYSTEM: 8150, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 11, ACTUAL: 7, REMAIN: 4, PROG: 63.64, },
{ SYSTEM: 8150, PHASE: 'PB', DISC: 'Electrical', TOTAL: 5, ACTUAL: 5, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8150, PHASE: 'PB', DISC: 'Instrument', TOTAL: 11, ACTUAL: 6, REMAIN: 5, PROG: 54.55, },
{ SYSTEM: 8150, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 62, ACTUAL: 46, REMAIN: 16, PROG: 74.19, },
{ SYSTEM: 8150, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 62, ACTUAL: 47, REMAIN: 15, PROG: 75.81, },
{ SYSTEM: 8150, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 62, ACTUAL: 16, REMAIN: 46, PROG: 25.81, },
{ SYSTEM: 8150, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 62, ACTUAL: 16, REMAIN: 46, PROG: 25.81, },
{ SYSTEM: 8150, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 62, ACTUAL: 16, REMAIN: 46, PROG: 25.81, },
{ SYSTEM: 8150, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 62, ACTUAL: 12, REMAIN: 50, PROG: 19.35, },
{ SYSTEM: 8150, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 62, ACTUAL: 7, REMAIN: 55, PROG: 11.29, },
{ SYSTEM: 8170, PHASE: 'A', DISC: 'Piping', TOTAL: 9, ACTUAL: 3, REMAIN: 6, PROG: 33.33, },
{ SYSTEM: 8170, PHASE: 'A', DISC: 'Mechanical', TOTAL: 8, ACTUAL: 4, REMAIN: 4, PROG: 50, },
{ SYSTEM: 8170, PHASE: 'A', DISC: 'Electrical', TOTAL: 18, ACTUAL: 10, REMAIN: 8, PROG: 55.56, },
{ SYSTEM: 8170, PHASE: 'A', DISC: 'Instrument', TOTAL: 51, ACTUAL: 45, REMAIN: 6, PROG: 88.24, },
{ SYSTEM: 8170, PHASE: 'B', DISC: 'Piping', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 8170, PHASE: 'B', DISC: 'Mechanical', TOTAL: 6, ACTUAL: 0, REMAIN: 6, PROG: 0, },
{ SYSTEM: 8170, PHASE: 'B', DISC: 'Electrical', TOTAL: 6, ACTUAL: 0, REMAIN: 6, PROG: 0, },
{ SYSTEM: 8170, PHASE: 'B', DISC: 'Instrument', TOTAL: 7, ACTUAL: 0, REMAIN: 7, PROG: 0, },
{ SYSTEM: 8170, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 9, ACTUAL: 4, REMAIN: 5, PROG: 44.44, },
{ SYSTEM: 8170, PHASE: 'PA', DISC: 'Instrument', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8170, PHASE: 'PB', DISC: 'Piping', TOTAL: 3, ACTUAL: 3, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8170, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 16, ACTUAL: 15, REMAIN: 1, PROG: 93.75, },
{ SYSTEM: 8170, PHASE: 'PB', DISC: 'Electrical', TOTAL: 17, ACTUAL: 17, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8170, PHASE: 'PB', DISC: 'Instrument', TOTAL: 9, ACTUAL: 7, REMAIN: 2, PROG: 77.78, },
{ SYSTEM: 8170, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8170, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8170, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8170, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8170, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 8170, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 8170, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 8200, PHASE: 'A', DISC: 'Piping', TOTAL: 96, ACTUAL: 25, REMAIN: 71, PROG: 26.04, },
{ SYSTEM: 8200, PHASE: 'A', DISC: 'Mechanical', TOTAL: 73, ACTUAL: 72, REMAIN: 1, PROG: 98.63, },
{ SYSTEM: 8200, PHASE: 'A', DISC: 'Electrical', TOTAL: 78, ACTUAL: 65, REMAIN: 13, PROG: 83.33, },
{ SYSTEM: 8200, PHASE: 'A', DISC: 'Instrument', TOTAL: 723, ACTUAL: 700, REMAIN: 23, PROG: 96.82, },
{ SYSTEM: 8200, PHASE: 'B', DISC: 'Piping', TOTAL: 13, ACTUAL: 0, REMAIN: 13, PROG: 0, },
{ SYSTEM: 8200, PHASE: 'B', DISC: 'Mechanical', TOTAL: 46, ACTUAL: 0, REMAIN: 46, PROG: 0, },
{ SYSTEM: 8200, PHASE: 'B', DISC: 'Electrical', TOTAL: 18, ACTUAL: 0, REMAIN: 18, PROG: 0, },
{ SYSTEM: 8200, PHASE: 'B', DISC: 'Instrument', TOTAL: 112, ACTUAL: 0, REMAIN: 112, PROG: 0, },
{ SYSTEM: 8200, PHASE: 'PA', DISC: 'Piping', TOTAL: 2, ACTUAL: 1, REMAIN: 1, PROG: 50, },
{ SYSTEM: 8200, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 24, ACTUAL: 24, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8200, PHASE: 'PA', DISC: 'Electrical', TOTAL: 13, ACTUAL: 8, REMAIN: 5, PROG: 61.54, },
{ SYSTEM: 8200, PHASE: 'PA', DISC: 'Instrument', TOTAL: 72, ACTUAL: 68, REMAIN: 4, PROG: 94.44, },
{ SYSTEM: 8200, PHASE: 'PB', DISC: 'Piping', TOTAL: 3, ACTUAL: 1, REMAIN: 2, PROG: 33.33, },
{ SYSTEM: 8200, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 41, ACTUAL: 28, REMAIN: 13, PROG: 68.29, },
{ SYSTEM: 8200, PHASE: 'PB', DISC: 'Electrical', TOTAL: 33, ACTUAL: 23, REMAIN: 10, PROG: 69.7, },
{ SYSTEM: 8200, PHASE: 'PB', DISC: 'Instrument', TOTAL: 35, ACTUAL: 29, REMAIN: 6, PROG: 82.86, },
{ SYSTEM: 8200, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 15, ACTUAL: 14, REMAIN: 1, PROG: 93.33, },
{ SYSTEM: 8200, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 15, ACTUAL: 15, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8200, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 15, ACTUAL: 3, REMAIN: 12, PROG: 20, },
{ SYSTEM: 8200, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 15, ACTUAL: 3, REMAIN: 12, PROG: 20, },
{ SYSTEM: 8200, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 15, ACTUAL: 2, REMAIN: 13, PROG: 13.33, },
{ SYSTEM: 8200, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 15, ACTUAL: 2, REMAIN: 13, PROG: 13.33, },
{ SYSTEM: 8200, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 15, ACTUAL: 0, REMAIN: 15, PROG: 0, },
{ SYSTEM: 8300, PHASE: 'A', DISC: 'Piping', TOTAL: 1780, ACTUAL: 220, REMAIN: 1560, PROG: 12.36, },
{ SYSTEM: 8300, PHASE: 'A', DISC: 'Mechanical', TOTAL: 155, ACTUAL: 140, REMAIN: 15, PROG: 90.32, },
{ SYSTEM: 8300, PHASE: 'A', DISC: 'Electrical', TOTAL: 240, ACTUAL: 94, REMAIN: 146, PROG: 39.17, },
{ SYSTEM: 8300, PHASE: 'A', DISC: 'Instrument', TOTAL: 981, ACTUAL: 883, REMAIN: 98, PROG: 90.01, },
{ SYSTEM: 8300, PHASE: 'B', DISC: 'Piping', TOTAL: 26, ACTUAL: 0, REMAIN: 26, PROG: 0, },
{ SYSTEM: 8300, PHASE: 'B', DISC: 'Mechanical', TOTAL: 137, ACTUAL: 0, REMAIN: 137, PROG: 0, },
{ SYSTEM: 8300, PHASE: 'B', DISC: 'Electrical', TOTAL: 67, ACTUAL: 0, REMAIN: 67, PROG: 0, },
{ SYSTEM: 8300, PHASE: 'B', DISC: 'Instrument', TOTAL: 149, ACTUAL: 0, REMAIN: 149, PROG: 0, },
{ SYSTEM: 8300, PHASE: 'PA', DISC: 'Piping', TOTAL: 9, ACTUAL: 4, REMAIN: 5, PROG: 44.44, },
{ SYSTEM: 8300, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 24, ACTUAL: 22, REMAIN: 2, PROG: 91.67, },
{ SYSTEM: 8300, PHASE: 'PA', DISC: 'Electrical', TOTAL: 11, ACTUAL: 9, REMAIN: 2, PROG: 81.82, },
{ SYSTEM: 8300, PHASE: 'PB', DISC: 'Piping', TOTAL: 13, ACTUAL: 10, REMAIN: 3, PROG: 76.92, },
{ SYSTEM: 8300, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 24, ACTUAL: 21, REMAIN: 3, PROG: 87.5, },
{ SYSTEM: 8300, PHASE: 'PB', DISC: 'Electrical', TOTAL: 21, ACTUAL: 21, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8300, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 293, ACTUAL: 262, REMAIN: 31, PROG: 89.42, },
{ SYSTEM: 8300, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 293, ACTUAL: 171, REMAIN: 122, PROG: 58.36, },
{ SYSTEM: 8300, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 293, ACTUAL: 10, REMAIN: 283, PROG: 3.41, },
{ SYSTEM: 8300, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 293, ACTUAL: 10, REMAIN: 283, PROG: 3.41, },
{ SYSTEM: 8300, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 293, ACTUAL: 10, REMAIN: 283, PROG: 3.41, },
{ SYSTEM: 8300, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 293, ACTUAL: 10, REMAIN: 283, PROG: 3.41, },
{ SYSTEM: 8300, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 293, ACTUAL: 9, REMAIN: 284, PROG: 3.07, },
{ SYSTEM: 8400, PHASE: 'A', DISC: 'Piping', TOTAL: 484, ACTUAL: 155, REMAIN: 329, PROG: 32.02, },
{ SYSTEM: 8400, PHASE: 'A', DISC: 'Mechanical', TOTAL: 116, ACTUAL: 107, REMAIN: 9, PROG: 92.24, },
{ SYSTEM: 8400, PHASE: 'A', DISC: 'Electrical', TOTAL: 179, ACTUAL: 75, REMAIN: 104, PROG: 41.9, },
{ SYSTEM: 8400, PHASE: 'A', DISC: 'Instrument', TOTAL: 511, ACTUAL: 438, REMAIN: 73, PROG: 85.71, },
{ SYSTEM: 8400, PHASE: 'B', DISC: 'Piping', TOTAL: 24, ACTUAL: 0, REMAIN: 24, PROG: 0, },
{ SYSTEM: 8400, PHASE: 'B', DISC: 'Mechanical', TOTAL: 55, ACTUAL: 0, REMAIN: 55, PROG: 0, },
{ SYSTEM: 8400, PHASE: 'B', DISC: 'Electrical', TOTAL: 49, ACTUAL: 0, REMAIN: 49, PROG: 0, },
{ SYSTEM: 8400, PHASE: 'B', DISC: 'Instrument', TOTAL: 83, ACTUAL: 0, REMAIN: 83, PROG: 0, },
{ SYSTEM: 8400, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 22, ACTUAL: 18, REMAIN: 4, PROG: 81.82, },
{ SYSTEM: 8400, PHASE: 'PA', DISC: 'Electrical', TOTAL: 4, ACTUAL: 3, REMAIN: 1, PROG: 75, },
{ SYSTEM: 8400, PHASE: 'PB', DISC: 'Piping', TOTAL: 18, ACTUAL: 12, REMAIN: 6, PROG: 66.67, },
{ SYSTEM: 8400, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 16, ACTUAL: 14, REMAIN: 2, PROG: 87.5, },
{ SYSTEM: 8400, PHASE: 'PB', DISC: 'Electrical', TOTAL: 5, ACTUAL: 5, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8400, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 78, ACTUAL: 76, REMAIN: 2, PROG: 97.44, },
{ SYSTEM: 8400, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 78, ACTUAL: 71, REMAIN: 7, PROG: 91.03, },
{ SYSTEM: 8400, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 78, ACTUAL: 30, REMAIN: 48, PROG: 38.46, },
{ SYSTEM: 8400, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 78, ACTUAL: 30, REMAIN: 48, PROG: 38.46, },
{ SYSTEM: 8400, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 78, ACTUAL: 14, REMAIN: 64, PROG: 17.95, },
{ SYSTEM: 8400, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 78, ACTUAL: 6, REMAIN: 72, PROG: 7.69, },
{ SYSTEM: 8400, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 78, ACTUAL: 4, REMAIN: 74, PROG: 5.13, },
{ SYSTEM: 8430, PHASE: 'A', DISC: 'Piping', TOTAL: 146, ACTUAL: 39, REMAIN: 107, PROG: 26.71, },
{ SYSTEM: 8430, PHASE: 'A', DISC: 'Mechanical', TOTAL: 25, ACTUAL: 14, REMAIN: 11, PROG: 56, },
{ SYSTEM: 8430, PHASE: 'A', DISC: 'Electrical', TOTAL: 74, ACTUAL: 14, REMAIN: 60, PROG: 18.92, },
{ SYSTEM: 8430, PHASE: 'A', DISC: 'Instrument', TOTAL: 194, ACTUAL: 131, REMAIN: 63, PROG: 67.53, },
{ SYSTEM: 8430, PHASE: 'A', DISC: 'HVAC', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 8430, PHASE: 'B', DISC: 'Piping', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 8430, PHASE: 'B', DISC: 'Mechanical', TOTAL: 10, ACTUAL: 0, REMAIN: 10, PROG: 0, },
{ SYSTEM: 8430, PHASE: 'B', DISC: 'Electrical', TOTAL: 17, ACTUAL: 0, REMAIN: 17, PROG: 0, },
{ SYSTEM: 8430, PHASE: 'B', DISC: 'Instrument', TOTAL: 38, ACTUAL: 0, REMAIN: 38, PROG: 0, },
{ SYSTEM: 8430, PHASE: 'PA', DISC: 'Safety', TOTAL: 5, ACTUAL: 2, REMAIN: 3, PROG: 40, },
{ SYSTEM: 8430, PHASE: 'PA', DISC: 'Piping', TOTAL: 4, ACTUAL: 3, REMAIN: 1, PROG: 75, },
{ SYSTEM: 8430, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 142, ACTUAL: 125, REMAIN: 17, PROG: 88.03, },
{ SYSTEM: 8430, PHASE: 'PA', DISC: 'Electrical', TOTAL: 21, ACTUAL: 17, REMAIN: 4, PROG: 80.95, },
{ SYSTEM: 8430, PHASE: 'PA', DISC: 'Instrument', TOTAL: 15, ACTUAL: 6, REMAIN: 9, PROG: 40, },
{ SYSTEM: 8430, PHASE: 'PB', DISC: 'Company', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8430, PHASE: 'PB', DISC: 'Piping', TOTAL: 8, ACTUAL: 5, REMAIN: 3, PROG: 62.5, },
{ SYSTEM: 8430, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 12, ACTUAL: 7, REMAIN: 5, PROG: 58.33, },
{ SYSTEM: 8430, PHASE: 'PB', DISC: 'Electrical', TOTAL: 6, ACTUAL: 4, REMAIN: 2, PROG: 66.67, },
{ SYSTEM: 8430, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 24, ACTUAL: 19, REMAIN: 5, PROG: 79.17, },
{ SYSTEM: 8430, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 24, ACTUAL: 17, REMAIN: 7, PROG: 70.83, },
{ SYSTEM: 8430, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 24, ACTUAL: 5, REMAIN: 19, PROG: 20.83, },
{ SYSTEM: 8430, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 24, ACTUAL: 5, REMAIN: 19, PROG: 20.83, },
{ SYSTEM: 8430, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 24, ACTUAL: 5, REMAIN: 19, PROG: 20.83, },
{ SYSTEM: 8430, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 24, ACTUAL: 5, REMAIN: 19, PROG: 20.83, },
{ SYSTEM: 8430, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 24, ACTUAL: 2, REMAIN: 22, PROG: 8.33, },
{ SYSTEM: 8500, PHASE: 'A', DISC: 'Piping', TOTAL: 1159, ACTUAL: 228, REMAIN: 931, PROG: 19.67, },
{ SYSTEM: 8500, PHASE: 'A', DISC: 'Mechanical', TOTAL: 118, ACTUAL: 102, REMAIN: 16, PROG: 86.44, },
{ SYSTEM: 8500, PHASE: 'A', DISC: 'Electrical', TOTAL: 197, ACTUAL: 108, REMAIN: 89, PROG: 54.82, },
{ SYSTEM: 8500, PHASE: 'A', DISC: 'Instrument', TOTAL: 2029, ACTUAL: 1842, REMAIN: 187, PROG: 90.78, },
{ SYSTEM: 8500, PHASE: 'B', DISC: 'Piping', TOTAL: 21, ACTUAL: 0, REMAIN: 21, PROG: 0, },
{ SYSTEM: 8500, PHASE: 'B', DISC: 'Mechanical', TOTAL: 62, ACTUAL: 0, REMAIN: 62, PROG: 0, },
{ SYSTEM: 8500, PHASE: 'B', DISC: 'Electrical', TOTAL: 38, ACTUAL: 0, REMAIN: 38, PROG: 0, },
{ SYSTEM: 8500, PHASE: 'B', DISC: 'Instrument', TOTAL: 291, ACTUAL: 0, REMAIN: 291, PROG: 0, },
{ SYSTEM: 8500, PHASE: 'PA', DISC: 'Piping', TOTAL: 10, ACTUAL: 8, REMAIN: 2, PROG: 80, },
{ SYSTEM: 8500, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 19, ACTUAL: 17, REMAIN: 2, PROG: 89.47, },
{ SYSTEM: 8500, PHASE: 'PA', DISC: 'Electrical', TOTAL: 25, ACTUAL: 21, REMAIN: 4, PROG: 84, },
{ SYSTEM: 8500, PHASE: 'PA', DISC: 'Instrument', TOTAL: 81, ACTUAL: 37, REMAIN: 44, PROG: 45.68, },
{ SYSTEM: 8500, PHASE: 'PB', DISC: 'Company', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8500, PHASE: 'PB', DISC: 'Piping', TOTAL: 38, ACTUAL: 36, REMAIN: 2, PROG: 94.74, },
{ SYSTEM: 8500, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 31, ACTUAL: 29, REMAIN: 2, PROG: 93.55, },
{ SYSTEM: 8500, PHASE: 'PB', DISC: 'Electrical', TOTAL: 27, ACTUAL: 18, REMAIN: 9, PROG: 66.67, },
{ SYSTEM: 8500, PHASE: 'PB', DISC: 'Instrument', TOTAL: 40, ACTUAL: 28, REMAIN: 12, PROG: 70, },
{ SYSTEM: 8500, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 192, ACTUAL: 148, REMAIN: 44, PROG: 77.08, },
{ SYSTEM: 8500, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 192, ACTUAL: 138, REMAIN: 54, PROG: 71.88, },
{ SYSTEM: 8500, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 192, ACTUAL: 24, REMAIN: 168, PROG: 12.5, },
{ SYSTEM: 8500, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 192, ACTUAL: 22, REMAIN: 170, PROG: 11.46, },
{ SYSTEM: 8500, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 192, ACTUAL: 21, REMAIN: 171, PROG: 10.94, },
{ SYSTEM: 8500, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 192, ACTUAL: 14, REMAIN: 178, PROG: 7.29, },
{ SYSTEM: 8500, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 192, ACTUAL: 9, REMAIN: 183, PROG: 4.69, },
{ SYSTEM: 8610, PHASE: 'A', DISC: 'Electrical', TOTAL: 85, ACTUAL: 85, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8610, PHASE: 'A', DISC: 'Instrument', TOTAL: 441, ACTUAL: 434, REMAIN: 7, PROG: 98.41, },
{ SYSTEM: 8610, PHASE: 'A', DISC: 'Telecom', TOTAL: 6, ACTUAL: 2, REMAIN: 4, PROG: 33.33, },
{ SYSTEM: 8610, PHASE: 'B', DISC: 'Electrical', TOTAL: 61, ACTUAL: 14, REMAIN: 47, PROG: 22.95, },
{ SYSTEM: 8610, PHASE: 'B', DISC: 'Instrument', TOTAL: 18, ACTUAL: 8, REMAIN: 10, PROG: 44.44, },
{ SYSTEM: 8610, PHASE: 'PA', DISC: 'Electrical', TOTAL: 89, ACTUAL: 79, REMAIN: 10, PROG: 88.76, },
{ SYSTEM: 8610, PHASE: 'PA', DISC: 'Instrument', TOTAL: 24, ACTUAL: 23, REMAIN: 1, PROG: 95.83, },
{ SYSTEM: 8610, PHASE: 'PA', DISC: 'Telecom', TOTAL: 4, ACTUAL: 2, REMAIN: 2, PROG: 50, },
{ SYSTEM: 8610, PHASE: 'PB', DISC: 'Electrical', TOTAL: 80, ACTUAL: 64, REMAIN: 16, PROG: 80, },
{ SYSTEM: 8610, PHASE: 'PB', DISC: 'Instrument', TOTAL: 148, ACTUAL: 118, REMAIN: 30, PROG: 79.73, },
{ SYSTEM: 8610, PHASE: 'PB', DISC: 'Telecom', TOTAL: 21, ACTUAL: 17, REMAIN: 4, PROG: 80.95, },
{ SYSTEM: 8661, PHASE: 'A', DISC: 'Piping', TOTAL: 359, ACTUAL: 333, REMAIN: 26, PROG: 92.76, },
{ SYSTEM: 8661, PHASE: 'A', DISC: 'Mechanical', TOTAL: 11, ACTUAL: 9, REMAIN: 2, PROG: 81.82, },
{ SYSTEM: 8661, PHASE: 'A', DISC: 'Electrical', TOTAL: 214, ACTUAL: 165, REMAIN: 49, PROG: 77.1, },
{ SYSTEM: 8661, PHASE: 'A', DISC: 'Instrument', TOTAL: 286, ACTUAL: 171, REMAIN: 115, PROG: 59.79, },
{ SYSTEM: 8661, PHASE: 'A', DISC: 'Telecom', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 8661, PHASE: 'B', DISC: 'Piping', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 8661, PHASE: 'B', DISC: 'Mechanical', TOTAL: 22, ACTUAL: 6, REMAIN: 16, PROG: 27.27, },
{ SYSTEM: 8661, PHASE: 'B', DISC: 'Electrical', TOTAL: 94, ACTUAL: 4, REMAIN: 90, PROG: 4.26, },
{ SYSTEM: 8661, PHASE: 'B', DISC: 'Instrument', TOTAL: 222, ACTUAL: 133, REMAIN: 89, PROG: 59.91, },
{ SYSTEM: 8661, PHASE: 'PA', DISC: 'Piping', TOTAL: 31, ACTUAL: 27, REMAIN: 4, PROG: 87.1, },
{ SYSTEM: 8661, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 30, ACTUAL: 30, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8661, PHASE: 'PA', DISC: 'Electrical', TOTAL: 111, ACTUAL: 94, REMAIN: 17, PROG: 84.68, },
{ SYSTEM: 8661, PHASE: 'PA', DISC: 'Instrument', TOTAL: 185, ACTUAL: 165, REMAIN: 20, PROG: 89.19, },
{ SYSTEM: 8661, PHASE: 'PA', DISC: 'Telecom', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8661, PHASE: 'PB', DISC: 'Piping', TOTAL: 77, ACTUAL: 67, REMAIN: 10, PROG: 87.01, },
{ SYSTEM: 8661, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 126, ACTUAL: 99, REMAIN: 27, PROG: 78.57, },
{ SYSTEM: 8661, PHASE: 'PB', DISC: 'Electrical', TOTAL: 166, ACTUAL: 135, REMAIN: 31, PROG: 81.33, },
{ SYSTEM: 8661, PHASE: 'PB', DISC: 'Instrument', TOTAL: 206, ACTUAL: 125, REMAIN: 81, PROG: 60.68, },
{ SYSTEM: 8661, PHASE: 'PB', DISC: 'Telecom', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 8661, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 59, ACTUAL: 56, REMAIN: 3, PROG: 94.92, },
{ SYSTEM: 8661, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 59, ACTUAL: 58, REMAIN: 1, PROG: 98.31, },
{ SYSTEM: 8661, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 59, ACTUAL: 58, REMAIN: 1, PROG: 98.31, },
{ SYSTEM: 8661, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 59, ACTUAL: 58, REMAIN: 1, PROG: 98.31, },
{ SYSTEM: 8661, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 59, ACTUAL: 57, REMAIN: 2, PROG: 96.61, },
{ SYSTEM: 8661, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 59, ACTUAL: 55, REMAIN: 4, PROG: 93.22, },
{ SYSTEM: 8661, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 59, ACTUAL: 45, REMAIN: 14, PROG: 76.27, },
{ SYSTEM: 8662, PHASE: 'A', DISC: 'Piping', TOTAL: 389, ACTUAL: 363, REMAIN: 26, PROG: 93.32, },
{ SYSTEM: 8662, PHASE: 'A', DISC: 'Mechanical', TOTAL: 11, ACTUAL: 9, REMAIN: 2, PROG: 81.82, },
{ SYSTEM: 8662, PHASE: 'A', DISC: 'Electrical', TOTAL: 205, ACTUAL: 160, REMAIN: 45, PROG: 78.05, },
{ SYSTEM: 8662, PHASE: 'A', DISC: 'Instrument', TOTAL: 278, ACTUAL: 160, REMAIN: 118, PROG: 57.55, },
{ SYSTEM: 8662, PHASE: 'A', DISC: 'Telecom', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 8662, PHASE: 'B', DISC: 'Piping', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 8662, PHASE: 'B', DISC: 'Mechanical', TOTAL: 22, ACTUAL: 0, REMAIN: 22, PROG: 0, },
{ SYSTEM: 8662, PHASE: 'B', DISC: 'Electrical', TOTAL: 91, ACTUAL: 2, REMAIN: 89, PROG: 2.2, },
{ SYSTEM: 8662, PHASE: 'B', DISC: 'Instrument', TOTAL: 222, ACTUAL: 125, REMAIN: 97, PROG: 56.31, },
{ SYSTEM: 8662, PHASE: 'PA', DISC: 'Piping', TOTAL: 31, ACTUAL: 28, REMAIN: 3, PROG: 90.32, },
{ SYSTEM: 8662, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 32, ACTUAL: 32, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8662, PHASE: 'PA', DISC: 'Electrical', TOTAL: 129, ACTUAL: 106, REMAIN: 23, PROG: 82.17, },
{ SYSTEM: 8662, PHASE: 'PA', DISC: 'Instrument', TOTAL: 148, ACTUAL: 130, REMAIN: 18, PROG: 87.84, },
{ SYSTEM: 8662, PHASE: 'PB', DISC: 'Piping', TOTAL: 93, ACTUAL: 85, REMAIN: 8, PROG: 91.4, },
{ SYSTEM: 8662, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 129, ACTUAL: 108, REMAIN: 21, PROG: 83.72, },
{ SYSTEM: 8662, PHASE: 'PB', DISC: 'Electrical', TOTAL: 132, ACTUAL: 100, REMAIN: 32, PROG: 75.76, },
{ SYSTEM: 8662, PHASE: 'PB', DISC: 'Instrument', TOTAL: 147, ACTUAL: 78, REMAIN: 69, PROG: 53.06, },
{ SYSTEM: 8662, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 64, ACTUAL: 62, REMAIN: 2, PROG: 96.88, },
{ SYSTEM: 8662, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 64, ACTUAL: 63, REMAIN: 1, PROG: 98.44, },
{ SYSTEM: 8662, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 64, ACTUAL: 63, REMAIN: 1, PROG: 98.44, },
{ SYSTEM: 8662, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 64, ACTUAL: 63, REMAIN: 1, PROG: 98.44, },
{ SYSTEM: 8662, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 64, ACTUAL: 63, REMAIN: 1, PROG: 98.44, },
{ SYSTEM: 8662, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 64, ACTUAL: 60, REMAIN: 4, PROG: 93.75, },
{ SYSTEM: 8662, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 64, ACTUAL: 49, REMAIN: 15, PROG: 76.56, },
{ SYSTEM: 8663, PHASE: 'A', DISC: 'Piping', TOTAL: 359, ACTUAL: 332, REMAIN: 27, PROG: 92.48, },
{ SYSTEM: 8663, PHASE: 'A', DISC: 'Mechanical', TOTAL: 11, ACTUAL: 9, REMAIN: 2, PROG: 81.82, },
{ SYSTEM: 8663, PHASE: 'A', DISC: 'Electrical', TOTAL: 210, ACTUAL: 160, REMAIN: 50, PROG: 76.19, },
{ SYSTEM: 8663, PHASE: 'A', DISC: 'Instrument', TOTAL: 278, ACTUAL: 167, REMAIN: 111, PROG: 60.07, },
{ SYSTEM: 8663, PHASE: 'A', DISC: 'Telecom', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 8663, PHASE: 'B', DISC: 'Piping', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 8663, PHASE: 'B', DISC: 'Mechanical', TOTAL: 22, ACTUAL: 6, REMAIN: 16, PROG: 27.27, },
{ SYSTEM: 8663, PHASE: 'B', DISC: 'Electrical', TOTAL: 91, ACTUAL: 2, REMAIN: 89, PROG: 2.2, },
{ SYSTEM: 8663, PHASE: 'B', DISC: 'Instrument', TOTAL: 222, ACTUAL: 115, REMAIN: 107, PROG: 51.8, },
{ SYSTEM: 8663, PHASE: 'PA', DISC: 'Piping', TOTAL: 41, ACTUAL: 37, REMAIN: 4, PROG: 90.24, },
{ SYSTEM: 8663, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 25, ACTUAL: 25, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8663, PHASE: 'PA', DISC: 'Electrical', TOTAL: 101, ACTUAL: 94, REMAIN: 7, PROG: 93.07, },
{ SYSTEM: 8663, PHASE: 'PA', DISC: 'Instrument', TOTAL: 148, ACTUAL: 138, REMAIN: 10, PROG: 93.24, },
{ SYSTEM: 8663, PHASE: 'PB', DISC: 'Company', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 8663, PHASE: 'PB', DISC: 'Piping', TOTAL: 88, ACTUAL: 85, REMAIN: 3, PROG: 96.59, },
{ SYSTEM: 8663, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 99, ACTUAL: 83, REMAIN: 16, PROG: 83.84, },
{ SYSTEM: 8663, PHASE: 'PB', DISC: 'Electrical', TOTAL: 99, ACTUAL: 70, REMAIN: 29, PROG: 70.71, },
{ SYSTEM: 8663, PHASE: 'PB', DISC: 'Instrument', TOTAL: 159, ACTUAL: 87, REMAIN: 72, PROG: 54.72, },
{ SYSTEM: 8663, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 59, ACTUAL: 56, REMAIN: 3, PROG: 94.92, },
{ SYSTEM: 8663, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 59, ACTUAL: 58, REMAIN: 1, PROG: 98.31, },
{ SYSTEM: 8663, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 59, ACTUAL: 58, REMAIN: 1, PROG: 98.31, },
{ SYSTEM: 8663, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 59, ACTUAL: 58, REMAIN: 1, PROG: 98.31, },
{ SYSTEM: 8663, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 59, ACTUAL: 58, REMAIN: 1, PROG: 98.31, },
{ SYSTEM: 8663, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 59, ACTUAL: 54, REMAIN: 5, PROG: 91.53, },
{ SYSTEM: 8663, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 59, ACTUAL: 44, REMAIN: 15, PROG: 74.58, },
{ SYSTEM: 8720, PHASE: 'A', DISC: 'Electrical', TOTAL: 4068, ACTUAL: 2898, REMAIN: 1170, PROG: 71.24, },
{ SYSTEM: 8720, PHASE: 'A', DISC: 'Instrument', TOTAL: 9, ACTUAL: 8, REMAIN: 1, PROG: 88.89, },
{ SYSTEM: 8720, PHASE: 'B', DISC: 'Electrical', TOTAL: 247, ACTUAL: 0, REMAIN: 247, PROG: 0, },
{ SYSTEM: 8720, PHASE: 'B', DISC: 'Instrument', TOTAL: 10, ACTUAL: 0, REMAIN: 10, PROG: 0, },
{ SYSTEM: 8720, PHASE: 'PA', DISC: 'Electrical', TOTAL: 68, ACTUAL: 36, REMAIN: 32, PROG: 52.94, },
{ SYSTEM: 8720, PHASE: 'PB', DISC: 'Electrical', TOTAL: 583, ACTUAL: 442, REMAIN: 141, PROG: 75.81, },
{ SYSTEM: 8720, PHASE: 'PB', DISC: 'Instrument', TOTAL: 5, ACTUAL: 2, REMAIN: 3, PROG: 40, },
{ SYSTEM: 8740, PHASE: 'A', DISC: 'Electrical', TOTAL: 362, ACTUAL: 289, REMAIN: 73, PROG: 79.83, },
{ SYSTEM: 8740, PHASE: 'A', DISC: 'Instrument', TOTAL: 50, ACTUAL: 42, REMAIN: 8, PROG: 84, },
{ SYSTEM: 8740, PHASE: 'B', DISC: 'Electrical', TOTAL: 396, ACTUAL: 143, REMAIN: 253, PROG: 36.11, },
{ SYSTEM: 8740, PHASE: 'B', DISC: 'Instrument', TOTAL: 36, ACTUAL: 1, REMAIN: 35, PROG: 2.78, },
{ SYSTEM: 8740, PHASE: 'PA', DISC: 'Electrical', TOTAL: 252, ACTUAL: 228, REMAIN: 24, PROG: 90.48, },
{ SYSTEM: 8740, PHASE: 'PA', DISC: 'Instrument', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8740, PHASE: 'PB', DISC: 'Electrical', TOTAL: 382, ACTUAL: 298, REMAIN: 84, PROG: 78.01, },
{ SYSTEM: 8740, PHASE: 'PB', DISC: 'Instrument', TOTAL: 10, ACTUAL: 7, REMAIN: 3, PROG: 70, },
{ SYSTEM: 8740, PHASE: 'PB', DISC: 'Telecom', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 8760, PHASE: 'A', DISC: 'Electrical', TOTAL: 165, ACTUAL: 71, REMAIN: 94, PROG: 43.03, },
{ SYSTEM: 8760, PHASE: 'A', DISC: 'Instrument', TOTAL: 61, ACTUAL: 31, REMAIN: 30, PROG: 50.82, },
{ SYSTEM: 8760, PHASE: 'A', DISC: 'Telecom', TOTAL: 2, ACTUAL: 1, REMAIN: 1, PROG: 50, },
{ SYSTEM: 8760, PHASE: 'B', DISC: 'Electrical', TOTAL: 143, ACTUAL: 0, REMAIN: 143, PROG: 0, },
{ SYSTEM: 8760, PHASE: 'B', DISC: 'Instrument', TOTAL: 27, ACTUAL: 0, REMAIN: 27, PROG: 0, },
{ SYSTEM: 8760, PHASE: 'PA', DISC: 'Electrical', TOTAL: 42, ACTUAL: 39, REMAIN: 3, PROG: 92.86, },
{ SYSTEM: 8760, PHASE: 'PA', DISC: 'Instrument', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 8760, PHASE: 'PB', DISC: 'Electrical', TOTAL: 69, ACTUAL: 65, REMAIN: 4, PROG: 94.2, },
{ SYSTEM: 8760, PHASE: 'PB', DISC: 'Instrument', TOTAL: 5, ACTUAL: 1, REMAIN: 4, PROG: 20, },
{ SYSTEM: 8790, PHASE: 'A', DISC: 'Electrical', TOTAL: 24, ACTUAL: 24, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8790, PHASE: 'A', DISC: 'Instrument', TOTAL: 8, ACTUAL: 8, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8790, PHASE: 'A', DISC: 'Telecom', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8790, PHASE: 'B', DISC: 'Electrical', TOTAL: 32, ACTUAL: 0, REMAIN: 32, PROG: 0, },
{ SYSTEM: 8790, PHASE: 'B', DISC: 'Instrument', TOTAL: 15, ACTUAL: 0, REMAIN: 15, PROG: 0, },
{ SYSTEM: 8790, PHASE: 'PA', DISC: 'Electrical', TOTAL: 38, ACTUAL: 32, REMAIN: 6, PROG: 84.21, },
{ SYSTEM: 8790, PHASE: 'PA', DISC: 'Instrument', TOTAL: 7, ACTUAL: 7, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8790, PHASE: 'PB', DISC: 'Electrical', TOTAL: 57, ACTUAL: 25, REMAIN: 32, PROG: 43.86, },
{ SYSTEM: 8790, PHASE: 'PB', DISC: 'Instrument', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 8950, PHASE: 'A', DISC: 'Electrical', TOTAL: 1373, ACTUAL: 94, REMAIN: 1279, PROG: 6.85, },
{ SYSTEM: 8950, PHASE: 'A', DISC: 'Instrument', TOTAL: 4, ACTUAL: 4, REMAIN: 0, PROG: 100, },
{ SYSTEM: 8950, PHASE: 'B', DISC: 'Electrical', TOTAL: 368, ACTUAL: 0, REMAIN: 368, PROG: 0, },
{ SYSTEM: 8950, PHASE: 'B', DISC: 'Instrument', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 8950, PHASE: 'PA', DISC: 'Electrical', TOTAL: 13, ACTUAL: 11, REMAIN: 2, PROG: 84.62, },
{ SYSTEM: 8950, PHASE: 'PB', DISC: 'Electrical', TOTAL: 23, ACTUAL: 23, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9131, PHASE: 'A', DISC: 'Architecture', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 9131, PHASE: 'A', DISC: 'Electrical', TOTAL: 181, ACTUAL: 0, REMAIN: 181, PROG: 0, },
{ SYSTEM: 9131, PHASE: 'B', DISC: 'Electrical', TOTAL: 11, ACTUAL: 0, REMAIN: 11, PROG: 0, },
{ SYSTEM: 9131, PHASE: 'PB', DISC: 'Company', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 9131, PHASE: 'PB', DISC: 'Structure', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 9131, PHASE: 'PB', DISC: 'Electrical', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9132, PHASE: 'A', DISC: 'Architecture', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 9132, PHASE: 'A', DISC: 'Electrical', TOTAL: 327, ACTUAL: 187, REMAIN: 140, PROG: 57.19, },
{ SYSTEM: 9132, PHASE: 'B', DISC: 'Electrical', TOTAL: 11, ACTUAL: 0, REMAIN: 11, PROG: 0, },
{ SYSTEM: 9132, PHASE: 'PA', DISC: 'Electrical', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9132, PHASE: 'PB', DISC: 'Structure', TOTAL: 2, ACTUAL: 1, REMAIN: 1, PROG: 50, },
{ SYSTEM: 9132, PHASE: 'PB', DISC: 'Electrical', TOTAL: 27, ACTUAL: 22, REMAIN: 5, PROG: 81.48, },
{ SYSTEM: 9133, PHASE: 'A', DISC: 'Architecture', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 9133, PHASE: 'A', DISC: 'Electrical', TOTAL: 98, ACTUAL: 27, REMAIN: 71, PROG: 27.55, },
{ SYSTEM: 9133, PHASE: 'B', DISC: 'Electrical', TOTAL: 7, ACTUAL: 0, REMAIN: 7, PROG: 0, },
{ SYSTEM: 9133, PHASE: 'PB', DISC: 'Architecture', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 9133, PHASE: 'PB', DISC: 'Company', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 9133, PHASE: 'PB', DISC: 'Structure', TOTAL: 4, ACTUAL: 3, REMAIN: 1, PROG: 75, },
{ SYSTEM: 9133, PHASE: 'PB', DISC: 'Electrical', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 9133, PHASE: 'PB', DISC: 'Instrument', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 9134, PHASE: 'A', DISC: 'Architecture', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 9134, PHASE: 'A', DISC: 'Electrical', TOTAL: 131, ACTUAL: 1, REMAIN: 130, PROG: 0.76, },
{ SYSTEM: 9134, PHASE: 'B', DISC: 'Electrical', TOTAL: 8, ACTUAL: 0, REMAIN: 8, PROG: 0, },
{ SYSTEM: 9134, PHASE: 'PA', DISC: 'Electrical', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 9134, PHASE: 'PB', DISC: 'Company', TOTAL: 5, ACTUAL: 0, REMAIN: 5, PROG: 0, },
{ SYSTEM: 9134, PHASE: 'PB', DISC: 'Structure', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 9150, PHASE: 'A', DISC: 'Architecture', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 9150, PHASE: 'A', DISC: 'Electrical', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9150, PHASE: 'PA', DISC: 'Electrical', TOTAL: 2, ACTUAL: 1, REMAIN: 1, PROG: 50, },
{ SYSTEM: 9150, PHASE: 'PB', DISC: 'Electrical', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9220, PHASE: 'A', DISC: 'Architecture', TOTAL: 883, ACTUAL: 179, REMAIN: 704, PROG: 20.27, },
{ SYSTEM: 9220, PHASE: 'A', DISC: 'Mechanical', TOTAL: 18, ACTUAL: 0, REMAIN: 18, PROG: 0, },
{ SYSTEM: 9220, PHASE: 'A', DISC: 'Electrical', TOTAL: 717, ACTUAL: 2, REMAIN: 715, PROG: 0.28, },
{ SYSTEM: 9220, PHASE: 'A', DISC: 'Instrument', TOTAL: 3, ACTUAL: 0, REMAIN: 3, PROG: 0, },
{ SYSTEM: 9220, PHASE: 'B', DISC: 'Mechanical', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 9220, PHASE: 'B', DISC: 'Electrical', TOTAL: 93, ACTUAL: 0, REMAIN: 93, PROG: 0, },
{ SYSTEM: 9220, PHASE: 'PA', DISC: 'Architecture', TOTAL: 16, ACTUAL: 15, REMAIN: 1, PROG: 93.75, },
{ SYSTEM: 9220, PHASE: 'PA', DISC: 'Electrical', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 9220, PHASE: 'PB', DISC: 'Structure', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 9220, PHASE: 'PB', DISC: 'Architecture', TOTAL: 95, ACTUAL: 70, REMAIN: 25, PROG: 73.68, },
{ SYSTEM: 9220, PHASE: 'PB', DISC: 'Company', TOTAL: 10, ACTUAL: 7, REMAIN: 3, PROG: 70, },
{ SYSTEM: 9220, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 5, ACTUAL: 0, REMAIN: 5, PROG: 0, },
{ SYSTEM: 9220, PHASE: 'PB', DISC: 'Electrical', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 9240, PHASE: 'A', DISC: 'Architecture', TOTAL: 37, ACTUAL: 0, REMAIN: 37, PROG: 0, },
{ SYSTEM: 9240, PHASE: 'A', DISC: 'Electrical', TOTAL: 51, ACTUAL: 2, REMAIN: 49, PROG: 3.92, },
{ SYSTEM: 9240, PHASE: 'B', DISC: 'Mechanical', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 9240, PHASE: 'PA', DISC: 'Architecture', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9240, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9240, PHASE: 'PB', DISC: 'Company', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9240, PHASE: 'PB', DISC: 'Electrical', TOTAL: 3, ACTUAL: 2, REMAIN: 1, PROG: 66.67, },
{ SYSTEM: 9250, PHASE: 'A', DISC: 'Mechanical', TOTAL: 100, ACTUAL: 96, REMAIN: 4, PROG: 96, },
{ SYSTEM: 9250, PHASE: 'A', DISC: 'Electrical', TOTAL: 1837, ACTUAL: 820, REMAIN: 1017, PROG: 44.64, },
{ SYSTEM: 9250, PHASE: 'A', DISC: 'Instrument', TOTAL: 940, ACTUAL: 369, REMAIN: 571, PROG: 39.26, },
{ SYSTEM: 9250, PHASE: 'A', DISC: 'Telecom', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 9250, PHASE: 'A', DISC: 'HVAC', TOTAL: 1041, ACTUAL: 835, REMAIN: 206, PROG: 80.21, },
{ SYSTEM: 9250, PHASE: 'B', DISC: 'Mechanical', TOTAL: 50, ACTUAL: 0, REMAIN: 50, PROG: 0, },
{ SYSTEM: 9250, PHASE: 'B', DISC: 'Electrical', TOTAL: 237, ACTUAL: 0, REMAIN: 237, PROG: 0, },
{ SYSTEM: 9250, PHASE: 'B', DISC: 'Instrument', TOTAL: 144, ACTUAL: 0, REMAIN: 144, PROG: 0, },
{ SYSTEM: 9250, PHASE: 'B', DISC: 'HVAC', TOTAL: 128, ACTUAL: 0, REMAIN: 128, PROG: 0, },
{ SYSTEM: 9250, PHASE: 'PA', DISC: 'Electrical', TOTAL: 169, ACTUAL: 122, REMAIN: 47, PROG: 72.19, },
{ SYSTEM: 9250, PHASE: 'PA', DISC: 'Instrument', TOTAL: 4, ACTUAL: 4, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9250, PHASE: 'PA', DISC: 'Telecom', TOTAL: 8, ACTUAL: 5, REMAIN: 3, PROG: 62.5, },
{ SYSTEM: 9250, PHASE: 'PA', DISC: 'HVAC', TOTAL: 283, ACTUAL: 253, REMAIN: 30, PROG: 89.4, },
{ SYSTEM: 9250, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 3, ACTUAL: 2, REMAIN: 1, PROG: 66.67, },
{ SYSTEM: 9250, PHASE: 'PB', DISC: 'Electrical', TOTAL: 190, ACTUAL: 115, REMAIN: 75, PROG: 60.53, },
{ SYSTEM: 9250, PHASE: 'PB', DISC: 'Instrument', TOTAL: 9, ACTUAL: 4, REMAIN: 5, PROG: 44.44, },
{ SYSTEM: 9250, PHASE: 'PB', DISC: 'HVAC', TOTAL: 433, ACTUAL: 347, REMAIN: 86, PROG: 80.14, },
{ SYSTEM: 9254, PHASE: 'A', DISC: 'Mechanical', TOTAL: 42, ACTUAL: 32, REMAIN: 10, PROG: 76.19, },
{ SYSTEM: 9254, PHASE: 'A', DISC: 'Electrical', TOTAL: 423, ACTUAL: 181, REMAIN: 242, PROG: 42.79, },
{ SYSTEM: 9254, PHASE: 'A', DISC: 'Instrument', TOTAL: 274, ACTUAL: 126, REMAIN: 148, PROG: 45.99, },
{ SYSTEM: 9254, PHASE: 'A', DISC: 'HVAC', TOTAL: 187, ACTUAL: 151, REMAIN: 36, PROG: 80.75, },
{ SYSTEM: 9254, PHASE: 'B', DISC: 'Mechanical', TOTAL: 15, ACTUAL: 0, REMAIN: 15, PROG: 0, },
{ SYSTEM: 9254, PHASE: 'B', DISC: 'Electrical', TOTAL: 51, ACTUAL: 0, REMAIN: 51, PROG: 0, },
{ SYSTEM: 9254, PHASE: 'B', DISC: 'Instrument', TOTAL: 48, ACTUAL: 0, REMAIN: 48, PROG: 0, },
{ SYSTEM: 9254, PHASE: 'B', DISC: 'HVAC', TOTAL: 31, ACTUAL: 0, REMAIN: 31, PROG: 0, },
{ SYSTEM: 9254, PHASE: 'PA', DISC: 'Electrical', TOTAL: 18, ACTUAL: 18, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9254, PHASE: 'PA', DISC: 'Instrument', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9254, PHASE: 'PA', DISC: 'Telecom', TOTAL: 4, ACTUAL: 2, REMAIN: 2, PROG: 50, },
{ SYSTEM: 9254, PHASE: 'PA', DISC: 'HVAC', TOTAL: 126, ACTUAL: 119, REMAIN: 7, PROG: 94.44, },
{ SYSTEM: 9254, PHASE: 'PB', DISC: 'Company', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9254, PHASE: 'PB', DISC: 'Electrical', TOTAL: 25, ACTUAL: 18, REMAIN: 7, PROG: 72, },
{ SYSTEM: 9254, PHASE: 'PB', DISC: 'Instrument', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9254, PHASE: 'PB', DISC: 'HVAC', TOTAL: 83, ACTUAL: 62, REMAIN: 21, PROG: 74.7, },
{ SYSTEM: 9255, PHASE: 'A', DISC: 'Mechanical', TOTAL: 6, ACTUAL: 2, REMAIN: 4, PROG: 33.33, },
{ SYSTEM: 9255, PHASE: 'A', DISC: 'Electrical', TOTAL: 399, ACTUAL: 195, REMAIN: 204, PROG: 48.87, },
{ SYSTEM: 9255, PHASE: 'A', DISC: 'Instrument', TOTAL: 229, ACTUAL: 80, REMAIN: 149, PROG: 34.93, },
{ SYSTEM: 9255, PHASE: 'A', DISC: 'HVAC', TOTAL: 173, ACTUAL: 143, REMAIN: 30, PROG: 82.66, },
{ SYSTEM: 9255, PHASE: 'B', DISC: 'Mechanical', TOTAL: 8, ACTUAL: 0, REMAIN: 8, PROG: 0, },
{ SYSTEM: 9255, PHASE: 'B', DISC: 'Electrical', TOTAL: 51, ACTUAL: 0, REMAIN: 51, PROG: 0, },
{ SYSTEM: 9255, PHASE: 'B', DISC: 'Instrument', TOTAL: 34, ACTUAL: 0, REMAIN: 34, PROG: 0, },
{ SYSTEM: 9255, PHASE: 'B', DISC: 'HVAC', TOTAL: 33, ACTUAL: 0, REMAIN: 33, PROG: 0, },
{ SYSTEM: 9255, PHASE: 'PA', DISC: 'Electrical', TOTAL: 24, ACTUAL: 24, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9255, PHASE: 'PA', DISC: 'HVAC', TOTAL: 168, ACTUAL: 158, REMAIN: 10, PROG: 94.05, },
{ SYSTEM: 9255, PHASE: 'PB', DISC: 'Electrical', TOTAL: 36, ACTUAL: 32, REMAIN: 4, PROG: 88.89, },
{ SYSTEM: 9255, PHASE: 'PB', DISC: 'Instrument', TOTAL: 6, ACTUAL: 6, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9255, PHASE: 'PB', DISC: 'HVAC', TOTAL: 115, ACTUAL: 78, REMAIN: 37, PROG: 67.83, },
{ SYSTEM: 9260, PHASE: 'A', DISC: 'Mechanical', TOTAL: 49, ACTUAL: 42, REMAIN: 7, PROG: 85.71, },
{ SYSTEM: 9260, PHASE: 'A', DISC: 'Electrical', TOTAL: 1595, ACTUAL: 690, REMAIN: 905, PROG: 43.26, },
{ SYSTEM: 9260, PHASE: 'A', DISC: 'Instrument', TOTAL: 647, ACTUAL: 188, REMAIN: 459, PROG: 29.06, },
{ SYSTEM: 9260, PHASE: 'A', DISC: 'HVAC', TOTAL: 1306, ACTUAL: 973, REMAIN: 333, PROG: 74.5, },
{ SYSTEM: 9260, PHASE: 'B', DISC: 'Mechanical', TOTAL: 32, ACTUAL: 0, REMAIN: 32, PROG: 0, },
{ SYSTEM: 9260, PHASE: 'B', DISC: 'Electrical', TOTAL: 232, ACTUAL: 0, REMAIN: 232, PROG: 0, },
{ SYSTEM: 9260, PHASE: 'B', DISC: 'Instrument', TOTAL: 96, ACTUAL: 0, REMAIN: 96, PROG: 0, },
{ SYSTEM: 9260, PHASE: 'B', DISC: 'HVAC', TOTAL: 111, ACTUAL: 0, REMAIN: 111, PROG: 0, },
{ SYSTEM: 9260, PHASE: 'PA', DISC: 'Electrical', TOTAL: 339, ACTUAL: 321, REMAIN: 18, PROG: 94.69, },
{ SYSTEM: 9260, PHASE: 'PA', DISC: 'Instrument', TOTAL: 5, ACTUAL: 5, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9260, PHASE: 'PA', DISC: 'HVAC', TOTAL: 409, ACTUAL: 394, REMAIN: 15, PROG: 96.33, },
{ SYSTEM: 9260, PHASE: 'PB', DISC: 'Architecture', TOTAL: 5, ACTUAL: 5, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9260, PHASE: 'PB', DISC: 'Electrical', TOTAL: 109, ACTUAL: 100, REMAIN: 9, PROG: 91.74, },
{ SYSTEM: 9260, PHASE: 'PB', DISC: 'Instrument', TOTAL: 21, ACTUAL: 6, REMAIN: 15, PROG: 28.57, },
{ SYSTEM: 9260, PHASE: 'PB', DISC: 'HVAC', TOTAL: 157, ACTUAL: 129, REMAIN: 28, PROG: 82.17, },
{ SYSTEM: 9270, PHASE: 'A', DISC: 'Mechanical', TOTAL: 243, ACTUAL: 209, REMAIN: 34, PROG: 86.01, },
{ SYSTEM: 9270, PHASE: 'A', DISC: 'Electrical', TOTAL: 3145, ACTUAL: 1807, REMAIN: 1338, PROG: 57.46, },
{ SYSTEM: 9270, PHASE: 'A', DISC: 'Instrument', TOTAL: 1660, ACTUAL: 747, REMAIN: 913, PROG: 45, },
{ SYSTEM: 9270, PHASE: 'A', DISC: 'HVAC', TOTAL: 1360, ACTUAL: 822, REMAIN: 538, PROG: 60.44, },
{ SYSTEM: 9270, PHASE: 'B', DISC: 'Mechanical', TOTAL: 136, ACTUAL: 0, REMAIN: 136, PROG: 0, },
{ SYSTEM: 9270, PHASE: 'B', DISC: 'Electrical', TOTAL: 420, ACTUAL: 0, REMAIN: 420, PROG: 0, },
{ SYSTEM: 9270, PHASE: 'B', DISC: 'Instrument', TOTAL: 305, ACTUAL: 0, REMAIN: 305, PROG: 0, },
{ SYSTEM: 9270, PHASE: 'B', DISC: 'HVAC', TOTAL: 231, ACTUAL: 0, REMAIN: 231, PROG: 0, },
{ SYSTEM: 9270, PHASE: 'PA', DISC: 'Electrical', TOTAL: 232, ACTUAL: 219, REMAIN: 13, PROG: 94.4, },
{ SYSTEM: 9270, PHASE: 'PA', DISC: 'Instrument', TOTAL: 38, ACTUAL: 30, REMAIN: 8, PROG: 78.95, },
{ SYSTEM: 9270, PHASE: 'PA', DISC: 'HVAC', TOTAL: 681, ACTUAL: 646, REMAIN: 35, PROG: 94.86, },
{ SYSTEM: 9270, PHASE: 'PB', DISC: 'Electrical', TOTAL: 280, ACTUAL: 241, REMAIN: 39, PROG: 86.07, },
{ SYSTEM: 9270, PHASE: 'PB', DISC: 'Instrument', TOTAL: 13, ACTUAL: 11, REMAIN: 2, PROG: 84.62, },
{ SYSTEM: 9270, PHASE: 'PB', DISC: 'Telecom', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9270, PHASE: 'PB', DISC: 'HVAC', TOTAL: 364, ACTUAL: 298, REMAIN: 66, PROG: 81.87, },
{ SYSTEM: 9280, PHASE: 'A', DISC: 'Mechanical', TOTAL: 54, ACTUAL: 40, REMAIN: 14, PROG: 74.07, },
{ SYSTEM: 9280, PHASE: 'A', DISC: 'Electrical', TOTAL: 1494, ACTUAL: 936, REMAIN: 558, PROG: 62.65, },
{ SYSTEM: 9280, PHASE: 'A', DISC: 'Instrument', TOTAL: 802, ACTUAL: 319, REMAIN: 483, PROG: 39.78, },
{ SYSTEM: 9280, PHASE: 'A', DISC: 'HVAC', TOTAL: 779, ACTUAL: 410, REMAIN: 369, PROG: 52.63, },
{ SYSTEM: 9280, PHASE: 'B', DISC: 'Mechanical', TOTAL: 35, ACTUAL: 0, REMAIN: 35, PROG: 0, },
{ SYSTEM: 9280, PHASE: 'B', DISC: 'Electrical', TOTAL: 184, ACTUAL: 0, REMAIN: 184, PROG: 0, },
{ SYSTEM: 9280, PHASE: 'B', DISC: 'Instrument', TOTAL: 132, ACTUAL: 0, REMAIN: 132, PROG: 0, },
{ SYSTEM: 9280, PHASE: 'B', DISC: 'HVAC', TOTAL: 127, ACTUAL: 0, REMAIN: 127, PROG: 0, },
{ SYSTEM: 9280, PHASE: 'PA', DISC: 'Electrical', TOTAL: 205, ACTUAL: 178, REMAIN: 27, PROG: 86.83, },
{ SYSTEM: 9280, PHASE: 'PA', DISC: 'Instrument', TOTAL: 38, ACTUAL: 33, REMAIN: 5, PROG: 86.84, },
{ SYSTEM: 9280, PHASE: 'PA', DISC: 'HVAC', TOTAL: 393, ACTUAL: 376, REMAIN: 17, PROG: 95.67, },
{ SYSTEM: 9280, PHASE: 'PB', DISC: 'Electrical', TOTAL: 202, ACTUAL: 168, REMAIN: 34, PROG: 83.17, },
{ SYSTEM: 9280, PHASE: 'PB', DISC: 'Instrument', TOTAL: 11, ACTUAL: 6, REMAIN: 5, PROG: 54.55, },
{ SYSTEM: 9280, PHASE: 'PB', DISC: 'Telecom', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 9280, PHASE: 'PB', DISC: 'HVAC', TOTAL: 165, ACTUAL: 150, REMAIN: 15, PROG: 90.91, },
{ SYSTEM: 9291, PHASE: 'A', DISC: 'Mechanical', TOTAL: 16, ACTUAL: 0, REMAIN: 16, PROG: 0, },
{ SYSTEM: 9291, PHASE: 'A', DISC: 'Electrical', TOTAL: 9, ACTUAL: 0, REMAIN: 9, PROG: 0, },
{ SYSTEM: 9291, PHASE: 'A', DISC: 'Instrument', TOTAL: 3, ACTUAL: 0, REMAIN: 3, PROG: 0, },
{ SYSTEM: 9291, PHASE: 'A', DISC: 'Telecom', TOTAL: 10, ACTUAL: 0, REMAIN: 10, PROG: 0, },
{ SYSTEM: 9291, PHASE: 'B', DISC: 'Electrical', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 9291, PHASE: 'B', DISC: 'Instrument', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 9300, PHASE: 'A', DISC: 'Architecture', TOTAL: 112, ACTUAL: 0, REMAIN: 112, PROG: 0, },
{ SYSTEM: 9300, PHASE: 'A', DISC: 'Electrical', TOTAL: 708, ACTUAL: 12, REMAIN: 696, PROG: 1.69, },
{ SYSTEM: 9300, PHASE: 'B', DISC: 'Electrical', TOTAL: 62, ACTUAL: 0, REMAIN: 62, PROG: 0, },
{ SYSTEM: 9300, PHASE: 'PA', DISC: 'Structure', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 9300, PHASE: 'PA', DISC: 'Electrical', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 9300, PHASE: 'PB', DISC: 'Company', TOTAL: 18, ACTUAL: 10, REMAIN: 8, PROG: 55.56, },
{ SYSTEM: 9300, PHASE: 'PB', DISC: 'Structure', TOTAL: 21, ACTUAL: 1, REMAIN: 20, PROG: 4.76, },
{ SYSTEM: 9300, PHASE: 'PB', DISC: 'Electrical', TOTAL: 24, ACTUAL: 8, REMAIN: 16, PROG: 33.33, },
{ SYSTEM: 9310, PHASE: 'A', DISC: 'Architecture', TOTAL: 22, ACTUAL: 8, REMAIN: 14, PROG: 36.36, },
{ SYSTEM: 9310, PHASE: 'A', DISC: 'Mechanical', TOTAL: 36, ACTUAL: 24, REMAIN: 12, PROG: 66.67, },
{ SYSTEM: 9310, PHASE: 'A', DISC: 'Electrical', TOTAL: 137, ACTUAL: 30, REMAIN: 107, PROG: 21.9, },
{ SYSTEM: 9310, PHASE: 'B', DISC: 'Electrical', TOTAL: 24, ACTUAL: 0, REMAIN: 24, PROG: 0, },
{ SYSTEM: 9310, PHASE: 'PA', DISC: 'Company', TOTAL: 21, ACTUAL: 13, REMAIN: 8, PROG: 61.9, },
{ SYSTEM: 9310, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 36, ACTUAL: 21, REMAIN: 15, PROG: 58.33, },
{ SYSTEM: 9310, PHASE: 'PA', DISC: 'Electrical', TOTAL: 3, ACTUAL: 3, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9310, PHASE: 'PB', DISC: 'Company', TOTAL: 16, ACTUAL: 16, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9310, PHASE: 'PB', DISC: 'Architecture', TOTAL: 7, ACTUAL: 0, REMAIN: 7, PROG: 0, },
{ SYSTEM: 9310, PHASE: 'PB', DISC: 'Structure', TOTAL: 10, ACTUAL: 4, REMAIN: 6, PROG: 40, },
{ SYSTEM: 9310, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 39, ACTUAL: 31, REMAIN: 8, PROG: 79.49, },
{ SYSTEM: 9310, PHASE: 'PB', DISC: 'Electrical', TOTAL: 73, ACTUAL: 57, REMAIN: 16, PROG: 78.08, },
{ SYSTEM: 9310, PHASE: 'PB', DISC: 'Instrument', TOTAL: 7, ACTUAL: 3, REMAIN: 4, PROG: 42.86, },
{ SYSTEM: 9320, PHASE: 'A', DISC: 'Piping', TOTAL: 458, ACTUAL: 456, REMAIN: 2, PROG: 99.56, },
{ SYSTEM: 9320, PHASE: 'A', DISC: 'HVAC', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 9320, PHASE: 'B', DISC: 'Piping', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 9320, PHASE: 'B', DISC: 'Mechanical', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 9320, PHASE: 'PA', DISC: 'Piping', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9320, PHASE: 'PB', DISC: 'Piping', TOTAL: 56, ACTUAL: 56, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9320, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 76, ACTUAL: 76, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9320, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 76, ACTUAL: 76, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9320, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 76, ACTUAL: 76, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9320, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 76, ACTUAL: 76, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9320, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 76, ACTUAL: 76, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9320, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 76, ACTUAL: 76, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9320, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 76, ACTUAL: 76, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9340, PHASE: 'A', DISC: 'Piping', TOTAL: 84, ACTUAL: 61, REMAIN: 23, PROG: 72.62, },
{ SYSTEM: 9340, PHASE: 'A', DISC: 'Mechanical', TOTAL: 319, ACTUAL: 313, REMAIN: 6, PROG: 98.12, },
{ SYSTEM: 9340, PHASE: 'A', DISC: 'Electrical', TOTAL: 153, ACTUAL: 58, REMAIN: 95, PROG: 37.91, },
{ SYSTEM: 9340, PHASE: 'A', DISC: 'Instrument', TOTAL: 1688, ACTUAL: 1561, REMAIN: 127, PROG: 92.48, },
{ SYSTEM: 9340, PHASE: 'A', DISC: 'HVAC', TOTAL: 8, ACTUAL: 8, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9340, PHASE: 'B', DISC: 'Piping', TOTAL: 8, ACTUAL: 0, REMAIN: 8, PROG: 0, },
{ SYSTEM: 9340, PHASE: 'B', DISC: 'Mechanical', TOTAL: 52, ACTUAL: 0, REMAIN: 52, PROG: 0, },
{ SYSTEM: 9340, PHASE: 'B', DISC: 'Electrical', TOTAL: 89, ACTUAL: 0, REMAIN: 89, PROG: 0, },
{ SYSTEM: 9340, PHASE: 'B', DISC: 'Instrument', TOTAL: 254, ACTUAL: 0, REMAIN: 254, PROG: 0, },
{ SYSTEM: 9340, PHASE: 'PA', DISC: 'Piping', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9340, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 87, ACTUAL: 85, REMAIN: 2, PROG: 97.7, },
{ SYSTEM: 9340, PHASE: 'PA', DISC: 'Electrical', TOTAL: 125, ACTUAL: 122, REMAIN: 3, PROG: 97.6, },
{ SYSTEM: 9340, PHASE: 'PA', DISC: 'Instrument', TOTAL: 20, ACTUAL: 18, REMAIN: 2, PROG: 90, },
{ SYSTEM: 9340, PHASE: 'PA', DISC: 'Telecom', TOTAL: 3, ACTUAL: 3, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9340, PHASE: 'PB', DISC: 'Piping', TOTAL: 2, ACTUAL: 1, REMAIN: 1, PROG: 50, },
{ SYSTEM: 9340, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 194, ACTUAL: 170, REMAIN: 24, PROG: 87.63, },
{ SYSTEM: 9340, PHASE: 'PB', DISC: 'Electrical', TOTAL: 29, ACTUAL: 27, REMAIN: 2, PROG: 93.1, },
{ SYSTEM: 9340, PHASE: 'PB', DISC: 'Instrument', TOTAL: 3, ACTUAL: 2, REMAIN: 1, PROG: 66.67, },
{ SYSTEM: 9340, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 12, ACTUAL: 12, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9340, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 12, ACTUAL: 12, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9340, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 12, ACTUAL: 10, REMAIN: 2, PROG: 83.33, },
{ SYSTEM: 9340, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 12, ACTUAL: 11, REMAIN: 1, PROG: 91.67, },
{ SYSTEM: 9340, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 12, ACTUAL: 11, REMAIN: 1, PROG: 91.67, },
{ SYSTEM: 9340, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 12, ACTUAL: 8, REMAIN: 4, PROG: 66.67, },
{ SYSTEM: 9340, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 12, ACTUAL: 8, REMAIN: 4, PROG: 66.67, },
{ SYSTEM: 9350, PHASE: 'A', DISC: 'Piping', TOTAL: 2456, ACTUAL: 2444, REMAIN: 12, PROG: 99.51, },
{ SYSTEM: 9350, PHASE: 'A', DISC: 'Mechanical', TOTAL: 192, ACTUAL: 178, REMAIN: 14, PROG: 92.71, },
{ SYSTEM: 9350, PHASE: 'A', DISC: 'Electrical', TOTAL: 80, ACTUAL: 80, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9350, PHASE: 'A', DISC: 'Instrument', TOTAL: 1263, ACTUAL: 1263, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9350, PHASE: 'B', DISC: 'Piping', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 9350, PHASE: 'B', DISC: 'Mechanical', TOTAL: 24, ACTUAL: 12, REMAIN: 12, PROG: 50, },
{ SYSTEM: 9350, PHASE: 'B', DISC: 'Electrical', TOTAL: 31, ACTUAL: 16, REMAIN: 15, PROG: 51.61, },
{ SYSTEM: 9350, PHASE: 'B', DISC: 'Instrument', TOTAL: 204, ACTUAL: 113, REMAIN: 91, PROG: 55.39, },
{ SYSTEM: 9350, PHASE: 'PA', DISC: 'Piping', TOTAL: 205, ACTUAL: 201, REMAIN: 4, PROG: 98.05, },
{ SYSTEM: 9350, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 132, ACTUAL: 128, REMAIN: 4, PROG: 96.97, },
{ SYSTEM: 9350, PHASE: 'PA', DISC: 'Electrical', TOTAL: 98, ACTUAL: 91, REMAIN: 7, PROG: 92.86, },
{ SYSTEM: 9350, PHASE: 'PA', DISC: 'Instrument', TOTAL: 65, ACTUAL: 65, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9350, PHASE: 'PB', DISC: 'Company', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 9350, PHASE: 'PB', DISC: 'Piping', TOTAL: 239, ACTUAL: 227, REMAIN: 12, PROG: 94.98, },
{ SYSTEM: 9350, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 125, ACTUAL: 121, REMAIN: 4, PROG: 96.8, },
{ SYSTEM: 9350, PHASE: 'PB', DISC: 'Electrical', TOTAL: 214, ACTUAL: 194, REMAIN: 20, PROG: 90.65, },
{ SYSTEM: 9350, PHASE: 'PB', DISC: 'Instrument', TOTAL: 865, ACTUAL: 834, REMAIN: 31, PROG: 96.42, },
{ SYSTEM: 9350, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 408, ACTUAL: 408, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9350, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 408, ACTUAL: 408, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9350, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 408, ACTUAL: 408, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9350, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 408, ACTUAL: 408, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9350, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 408, ACTUAL: 408, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9350, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 408, ACTUAL: 408, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9350, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 408, ACTUAL: 404, REMAIN: 4, PROG: 99.02, },
{ SYSTEM: 9355, PHASE: 'A', DISC: 'Piping', TOTAL: 290, ACTUAL: 238, REMAIN: 52, PROG: 82.07, },
{ SYSTEM: 9355, PHASE: 'A', DISC: 'Mechanical', TOTAL: 9, ACTUAL: 6, REMAIN: 3, PROG: 66.67, },
{ SYSTEM: 9355, PHASE: 'A', DISC: 'Electrical', TOTAL: 30, ACTUAL: 24, REMAIN: 6, PROG: 80, },
{ SYSTEM: 9355, PHASE: 'A', DISC: 'Instrument', TOTAL: 297, ACTUAL: 145, REMAIN: 152, PROG: 48.82, },
{ SYSTEM: 9355, PHASE: 'B', DISC: 'Piping', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 9355, PHASE: 'B', DISC: 'Mechanical', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 9355, PHASE: 'B', DISC: 'Electrical', TOTAL: 10, ACTUAL: 0, REMAIN: 10, PROG: 0, },
{ SYSTEM: 9355, PHASE: 'B', DISC: 'Instrument', TOTAL: 48, ACTUAL: 5, REMAIN: 43, PROG: 10.42, },
{ SYSTEM: 9355, PHASE: 'PA', DISC: 'Piping', TOTAL: 36, ACTUAL: 25, REMAIN: 11, PROG: 69.44, },
{ SYSTEM: 9355, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 7, ACTUAL: 6, REMAIN: 1, PROG: 85.71, },
{ SYSTEM: 9355, PHASE: 'PA', DISC: 'Electrical', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 9355, PHASE: 'PA', DISC: 'Instrument', TOTAL: 13, ACTUAL: 7, REMAIN: 6, PROG: 53.85, },
{ SYSTEM: 9355, PHASE: 'PB', DISC: 'Company', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9355, PHASE: 'PB', DISC: 'Piping', TOTAL: 111, ACTUAL: 75, REMAIN: 36, PROG: 67.57, },
{ SYSTEM: 9355, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 10, ACTUAL: 9, REMAIN: 1, PROG: 90, },
{ SYSTEM: 9355, PHASE: 'PB', DISC: 'Electrical', TOTAL: 9, ACTUAL: 5, REMAIN: 4, PROG: 55.56, },
{ SYSTEM: 9355, PHASE: 'PB', DISC: 'Instrument', TOTAL: 53, ACTUAL: 46, REMAIN: 7, PROG: 86.79, },
{ SYSTEM: 9355, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 47, ACTUAL: 47, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9355, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 47, ACTUAL: 47, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9355, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 47, ACTUAL: 47, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9355, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 47, ACTUAL: 47, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9355, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 47, ACTUAL: 47, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9355, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 47, ACTUAL: 34, REMAIN: 13, PROG: 72.34, },
{ SYSTEM: 9355, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 47, ACTUAL: 16, REMAIN: 31, PROG: 34.04, },
{ SYSTEM: 9360, PHASE: 'A', DISC: 'Architecture', TOTAL: 38, ACTUAL: 21, REMAIN: 17, PROG: 55.26, },
{ SYSTEM: 9360, PHASE: 'A', DISC: 'Piping', TOTAL: 53, ACTUAL: 8, REMAIN: 45, PROG: 15.09, },
{ SYSTEM: 9360, PHASE: 'A', DISC: 'Mechanical', TOTAL: 162, ACTUAL: 137, REMAIN: 25, PROG: 84.57, },
{ SYSTEM: 9360, PHASE: 'A', DISC: 'Electrical', TOTAL: 100, ACTUAL: 73, REMAIN: 27, PROG: 73, },
{ SYSTEM: 9360, PHASE: 'A', DISC: 'Instrument', TOTAL: 1009, ACTUAL: 776, REMAIN: 233, PROG: 76.91, },
{ SYSTEM: 9360, PHASE: 'A', DISC: 'Telecom', TOTAL: 120, ACTUAL: 96, REMAIN: 24, PROG: 80, },
{ SYSTEM: 9360, PHASE: 'B', DISC: 'Piping', TOTAL: 13, ACTUAL: 0, REMAIN: 13, PROG: 0, },
{ SYSTEM: 9360, PHASE: 'B', DISC: 'Mechanical', TOTAL: 61, ACTUAL: 0, REMAIN: 61, PROG: 0, },
{ SYSTEM: 9360, PHASE: 'B', DISC: 'Electrical', TOTAL: 54, ACTUAL: 0, REMAIN: 54, PROG: 0, },
{ SYSTEM: 9360, PHASE: 'B', DISC: 'Instrument', TOTAL: 218, ACTUAL: 0, REMAIN: 218, PROG: 0, },
{ SYSTEM: 9360, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 21, ACTUAL: 17, REMAIN: 4, PROG: 80.95, },
{ SYSTEM: 9360, PHASE: 'PA', DISC: 'Electrical', TOTAL: 6, ACTUAL: 6, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9360, PHASE: 'PA', DISC: 'Instrument', TOTAL: 14, ACTUAL: 9, REMAIN: 5, PROG: 64.29, },
{ SYSTEM: 9360, PHASE: 'PB', DISC: 'Architecture', TOTAL: 10, ACTUAL: 10, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9360, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 99, ACTUAL: 82, REMAIN: 17, PROG: 82.83, },
{ SYSTEM: 9360, PHASE: 'PB', DISC: 'Electrical', TOTAL: 144, ACTUAL: 140, REMAIN: 4, PROG: 97.22, },
{ SYSTEM: 9360, PHASE: 'PB', DISC: 'Instrument', TOTAL: 96, ACTUAL: 90, REMAIN: 6, PROG: 93.75, },
{ SYSTEM: 9360, PHASE: 'PB', DISC: 'Telecom', TOTAL: 41, ACTUAL: 41, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9360, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 6, ACTUAL: 6, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9360, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 6, ACTUAL: 0, REMAIN: 6, PROG: 0, },
{ SYSTEM: 9360, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 6, ACTUAL: 0, REMAIN: 6, PROG: 0, },
{ SYSTEM: 9360, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 6, ACTUAL: 0, REMAIN: 6, PROG: 0, },
{ SYSTEM: 9360, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 6, ACTUAL: 0, REMAIN: 6, PROG: 0, },
{ SYSTEM: 9360, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 6, ACTUAL: 0, REMAIN: 6, PROG: 0, },
{ SYSTEM: 9360, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 6, ACTUAL: 0, REMAIN: 6, PROG: 0, },
{ SYSTEM: 9380, PHASE: 'A', DISC: 'Piping', TOTAL: 6272, ACTUAL: 6062, REMAIN: 210, PROG: 96.65, },
{ SYSTEM: 9380, PHASE: 'A', DISC: 'Mechanical', TOTAL: 503, ACTUAL: 263, REMAIN: 240, PROG: 52.29, },
{ SYSTEM: 9380, PHASE: 'A', DISC: 'Electrical', TOTAL: 80, ACTUAL: 79, REMAIN: 1, PROG: 98.75, },
{ SYSTEM: 9380, PHASE: 'A', DISC: 'Instrument', TOTAL: 2281, ACTUAL: 1875, REMAIN: 406, PROG: 82.2, },
{ SYSTEM: 9380, PHASE: 'B', DISC: 'Piping', TOTAL: 9, ACTUAL: 0, REMAIN: 9, PROG: 0, },
{ SYSTEM: 9380, PHASE: 'B', DISC: 'Mechanical', TOTAL: 27, ACTUAL: 12, REMAIN: 15, PROG: 44.44, },
{ SYSTEM: 9380, PHASE: 'B', DISC: 'Electrical', TOTAL: 35, ACTUAL: 16, REMAIN: 19, PROG: 45.71, },
{ SYSTEM: 9380, PHASE: 'B', DISC: 'Instrument', TOTAL: 472, ACTUAL: 165, REMAIN: 307, PROG: 34.96, },
{ SYSTEM: 9380, PHASE: 'PA', DISC: 'Piping', TOTAL: 446, ACTUAL: 434, REMAIN: 12, PROG: 97.31, },
{ SYSTEM: 9380, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 181, ACTUAL: 168, REMAIN: 13, PROG: 92.82, },
{ SYSTEM: 9380, PHASE: 'PA', DISC: 'Electrical', TOTAL: 221, ACTUAL: 220, REMAIN: 1, PROG: 99.55, },
{ SYSTEM: 9380, PHASE: 'PA', DISC: 'Instrument', TOTAL: 139, ACTUAL: 132, REMAIN: 7, PROG: 94.96, },
{ SYSTEM: 9380, PHASE: 'PB', DISC: 'Company', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 9380, PHASE: 'PB', DISC: 'Piping', TOTAL: 590, ACTUAL: 511, REMAIN: 79, PROG: 86.61, },
{ SYSTEM: 9380, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 288, ACTUAL: 281, REMAIN: 7, PROG: 97.57, },
{ SYSTEM: 9380, PHASE: 'PB', DISC: 'Electrical', TOTAL: 227, ACTUAL: 199, REMAIN: 28, PROG: 87.67, },
{ SYSTEM: 9380, PHASE: 'PB', DISC: 'Instrument', TOTAL: 1043, ACTUAL: 979, REMAIN: 64, PROG: 93.86, },
{ SYSTEM: 9380, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 1042, ACTUAL: 1039, REMAIN: 3, PROG: 99.71, },
{ SYSTEM: 9380, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 1042, ACTUAL: 1039, REMAIN: 3, PROG: 99.71, },
{ SYSTEM: 9380, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 1042, ACTUAL: 1038, REMAIN: 4, PROG: 99.62, },
{ SYSTEM: 9380, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 1042, ACTUAL: 1037, REMAIN: 5, PROG: 99.52, },
{ SYSTEM: 9380, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 1042, ACTUAL: 1036, REMAIN: 6, PROG: 99.42, },
{ SYSTEM: 9380, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 1042, ACTUAL: 989, REMAIN: 53, PROG: 94.91, },
{ SYSTEM: 9380, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 1042, ACTUAL: 923, REMAIN: 119, PROG: 88.58, },
{ SYSTEM: 9390, PHASE: 'A', DISC: 'Architecture', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 9390, PHASE: 'A', DISC: 'Electrical', TOTAL: 90, ACTUAL: 54, REMAIN: 36, PROG: 60, },
{ SYSTEM: 9390, PHASE: 'A', DISC: 'Instrument', TOTAL: 8, ACTUAL: 4, REMAIN: 4, PROG: 50, },
{ SYSTEM: 9390, PHASE: 'B', DISC: 'Electrical', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 9390, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 78, ACTUAL: 77, REMAIN: 1, PROG: 98.72, },
{ SYSTEM: 9390, PHASE: 'PA', DISC: 'Electrical', TOTAL: 26, ACTUAL: 26, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9390, PHASE: 'PB', DISC: 'Company', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 9390, PHASE: 'PB', DISC: 'Structure', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 9390, PHASE: 'PB', DISC: 'Electrical', TOTAL: 31, ACTUAL: 4, REMAIN: 27, PROG: 12.9, },
{ SYSTEM: 9450, PHASE: 'A', DISC: 'Architecture', TOTAL: 130, ACTUAL: 0, REMAIN: 130, PROG: 0, },
{ SYSTEM: 9450, PHASE: 'A', DISC: 'Electrical', TOTAL: 320, ACTUAL: 8, REMAIN: 312, PROG: 2.5, },
{ SYSTEM: 9450, PHASE: 'B', DISC: 'Electrical', TOTAL: 14, ACTUAL: 0, REMAIN: 14, PROG: 0, },
{ SYSTEM: 9450, PHASE: 'PA', DISC: 'Electrical', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 9450, PHASE: 'PB', DISC: 'Company', TOTAL: 4, ACTUAL: 2, REMAIN: 2, PROG: 50, },
{ SYSTEM: 9450, PHASE: 'PB', DISC: 'Electrical', TOTAL: 22, ACTUAL: 20, REMAIN: 2, PROG: 90.91, },
{ SYSTEM: 9451, PHASE: 'A', DISC: 'Architecture', TOTAL: 146, ACTUAL: 0, REMAIN: 146, PROG: 0, },
{ SYSTEM: 9451, PHASE: 'A', DISC: 'Electrical', TOTAL: 353, ACTUAL: 2, REMAIN: 351, PROG: 0.57, },
{ SYSTEM: 9451, PHASE: 'PB', DISC: 'Company', TOTAL: 3, ACTUAL: 2, REMAIN: 1, PROG: 66.67, },
{ SYSTEM: 9451, PHASE: 'PB', DISC: 'Electrical', TOTAL: 3, ACTUAL: 2, REMAIN: 1, PROG: 66.67, },
{ SYSTEM: 9510, PHASE: 'A', DISC: 'Electrical', TOTAL: 13, ACTUAL: 5, REMAIN: 8, PROG: 38.46, },
{ SYSTEM: 9510, PHASE: 'A', DISC: 'Telecom', TOTAL: 23, ACTUAL: 1, REMAIN: 22, PROG: 4.35, },
{ SYSTEM: 9510, PHASE: 'B', DISC: 'Electrical', TOTAL: 5, ACTUAL: 0, REMAIN: 5, PROG: 0, },
{ SYSTEM: 9510, PHASE: 'B', DISC: 'Instrument', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 9510, PHASE: 'B', DISC: 'Telecom', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 9510, PHASE: 'PA', DISC: 'Electrical', TOTAL: 12, ACTUAL: 12, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9510, PHASE: 'PA', DISC: 'Telecom', TOTAL: 3, ACTUAL: 3, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9510, PHASE: 'PB', DISC: 'Telecom', TOTAL: 13, ACTUAL: 13, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9512, PHASE: 'A', DISC: 'Electrical', TOTAL: 5, ACTUAL: 2, REMAIN: 3, PROG: 40, },
{ SYSTEM: 9512, PHASE: 'A', DISC: 'Telecom', TOTAL: 5, ACTUAL: 0, REMAIN: 5, PROG: 0, },
{ SYSTEM: 9512, PHASE: 'B', DISC: 'Electrical', TOTAL: 3, ACTUAL: 0, REMAIN: 3, PROG: 0, },
{ SYSTEM: 9512, PHASE: 'PA', DISC: 'Electrical', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 9520, PHASE: 'A', DISC: 'Electrical', TOTAL: 93, ACTUAL: 63, REMAIN: 30, PROG: 67.74, },
{ SYSTEM: 9520, PHASE: 'A', DISC: 'Instrument', TOTAL: 8, ACTUAL: 8, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9520, PHASE: 'A', DISC: 'Telecom', TOTAL: 628, ACTUAL: 41, REMAIN: 587, PROG: 6.53, },
{ SYSTEM: 9520, PHASE: 'B', DISC: 'Electrical', TOTAL: 41, ACTUAL: 0, REMAIN: 41, PROG: 0, },
{ SYSTEM: 9520, PHASE: 'B', DISC: 'Instrument', TOTAL: 14, ACTUAL: 0, REMAIN: 14, PROG: 0, },
{ SYSTEM: 9520, PHASE: 'B', DISC: 'Telecom', TOTAL: 257, ACTUAL: 0, REMAIN: 257, PROG: 0, },
{ SYSTEM: 9520, PHASE: 'PA', DISC: 'Electrical', TOTAL: 3, ACTUAL: 3, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9520, PHASE: 'PA', DISC: 'Telecom', TOTAL: 44, ACTUAL: 34, REMAIN: 10, PROG: 77.27, },
{ SYSTEM: 9520, PHASE: 'PB', DISC: 'Electrical', TOTAL: 6, ACTUAL: 2, REMAIN: 4, PROG: 33.33, },
{ SYSTEM: 9520, PHASE: 'PB', DISC: 'Telecom', TOTAL: 55, ACTUAL: 54, REMAIN: 1, PROG: 98.18, },
{ SYSTEM: 9530, PHASE: 'A', DISC: 'Telecom', TOTAL: 19, ACTUAL: 0, REMAIN: 19, PROG: 0, },
{ SYSTEM: 9530, PHASE: 'B', DISC: 'Telecom', TOTAL: 9, ACTUAL: 0, REMAIN: 9, PROG: 0, },
{ SYSTEM: 9530, PHASE: 'PA', DISC: 'Telecom', TOTAL: 5, ACTUAL: 5, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9540, PHASE: 'A', DISC: 'Electrical', TOTAL: 65, ACTUAL: 23, REMAIN: 42, PROG: 35.38, },
{ SYSTEM: 9540, PHASE: 'A', DISC: 'Instrument', TOTAL: 7, ACTUAL: 0, REMAIN: 7, PROG: 0, },
{ SYSTEM: 9540, PHASE: 'A', DISC: 'Telecom', TOTAL: 275, ACTUAL: 26, REMAIN: 249, PROG: 9.45, },
{ SYSTEM: 9540, PHASE: 'B', DISC: 'Telecom', TOTAL: 75, ACTUAL: 0, REMAIN: 75, PROG: 0, },
{ SYSTEM: 9540, PHASE: 'PA', DISC: 'Electrical', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 9540, PHASE: 'PA', DISC: 'Telecom', TOTAL: 213, ACTUAL: 207, REMAIN: 6, PROG: 97.18, },
{ SYSTEM: 9540, PHASE: 'PB', DISC: 'Electrical', TOTAL: 9, ACTUAL: 9, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9540, PHASE: 'PB', DISC: 'Telecom', TOTAL: 18, ACTUAL: 10, REMAIN: 8, PROG: 55.56, },
{ SYSTEM: 9550, PHASE: 'A', DISC: 'Electrical', TOTAL: 9, ACTUAL: 8, REMAIN: 1, PROG: 88.89, },
{ SYSTEM: 9550, PHASE: 'A', DISC: 'Instrument', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9550, PHASE: 'A', DISC: 'Telecom', TOTAL: 44, ACTUAL: 5, REMAIN: 39, PROG: 11.36, },
{ SYSTEM: 9550, PHASE: 'B', DISC: 'Electrical', TOTAL: 7, ACTUAL: 0, REMAIN: 7, PROG: 0, },
{ SYSTEM: 9550, PHASE: 'B', DISC: 'Instrument', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 9550, PHASE: 'B', DISC: 'Telecom', TOTAL: 6, ACTUAL: 0, REMAIN: 6, PROG: 0, },
{ SYSTEM: 9550, PHASE: 'PA', DISC: 'Instrument', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 9550, PHASE: 'PA', DISC: 'Telecom', TOTAL: 7, ACTUAL: 5, REMAIN: 2, PROG: 71.43, },
{ SYSTEM: 9550, PHASE: 'PB', DISC: 'Electrical', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9550, PHASE: 'PB', DISC: 'Telecom', TOTAL: 45, ACTUAL: 39, REMAIN: 6, PROG: 86.67, },
{ SYSTEM: 9560, PHASE: 'A', DISC: 'Electrical', TOTAL: 101, ACTUAL: 101, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9560, PHASE: 'A', DISC: 'Instrument', TOTAL: 24, ACTUAL: 24, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9560, PHASE: 'A', DISC: 'Telecom', TOTAL: 1462, ACTUAL: 723, REMAIN: 739, PROG: 49.45, },
{ SYSTEM: 9560, PHASE: 'B', DISC: 'Electrical', TOTAL: 63, ACTUAL: 0, REMAIN: 63, PROG: 0, },
{ SYSTEM: 9560, PHASE: 'B', DISC: 'Instrument', TOTAL: 24, ACTUAL: 0, REMAIN: 24, PROG: 0, },
{ SYSTEM: 9560, PHASE: 'B', DISC: 'Telecom', TOTAL: 473, ACTUAL: 0, REMAIN: 473, PROG: 0, },
{ SYSTEM: 9560, PHASE: 'PA', DISC: 'Electrical', TOTAL: 13, ACTUAL: 11, REMAIN: 2, PROG: 84.62, },
{ SYSTEM: 9560, PHASE: 'PA', DISC: 'Instrument', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9560, PHASE: 'PA', DISC: 'Telecom', TOTAL: 105, ACTUAL: 90, REMAIN: 15, PROG: 85.71, },
{ SYSTEM: 9560, PHASE: 'PB', DISC: 'Electrical', TOTAL: 21, ACTUAL: 12, REMAIN: 9, PROG: 57.14, },
{ SYSTEM: 9560, PHASE: 'PB', DISC: 'Instrument', TOTAL: 4, ACTUAL: 3, REMAIN: 1, PROG: 75, },
{ SYSTEM: 9560, PHASE: 'PB', DISC: 'Telecom', TOTAL: 498, ACTUAL: 304, REMAIN: 194, PROG: 61.04, },
{ SYSTEM: 9580, PHASE: 'A', DISC: 'Electrical', TOTAL: 23, ACTUAL: 15, REMAIN: 8, PROG: 65.22, },
{ SYSTEM: 9580, PHASE: 'A', DISC: 'Telecom', TOTAL: 572, ACTUAL: 40, REMAIN: 532, PROG: 6.99, },
{ SYSTEM: 9580, PHASE: 'B', DISC: 'Electrical', TOTAL: 17, ACTUAL: 0, REMAIN: 17, PROG: 0, },
{ SYSTEM: 9580, PHASE: 'B', DISC: 'Instrument', TOTAL: 3, ACTUAL: 0, REMAIN: 3, PROG: 0, },
{ SYSTEM: 9580, PHASE: 'B', DISC: 'Telecom', TOTAL: 165, ACTUAL: 0, REMAIN: 165, PROG: 0, },
{ SYSTEM: 9580, PHASE: 'PA', DISC: 'Electrical', TOTAL: 6, ACTUAL: 6, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9580, PHASE: 'PA', DISC: 'Telecom', TOTAL: 3, ACTUAL: 3, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9580, PHASE: 'PB', DISC: 'Telecom', TOTAL: 42, ACTUAL: 42, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9590, PHASE: 'A', DISC: 'Electrical', TOTAL: 12, ACTUAL: 8, REMAIN: 4, PROG: 66.67, },
{ SYSTEM: 9590, PHASE: 'A', DISC: 'Telecom', TOTAL: 640, ACTUAL: 243, REMAIN: 397, PROG: 37.97, },
{ SYSTEM: 9590, PHASE: 'B', DISC: 'Electrical', TOTAL: 6, ACTUAL: 0, REMAIN: 6, PROG: 0, },
{ SYSTEM: 9590, PHASE: 'B', DISC: 'Instrument', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 9590, PHASE: 'B', DISC: 'Telecom', TOTAL: 485, ACTUAL: 0, REMAIN: 485, PROG: 0, },
{ SYSTEM: 9590, PHASE: 'PA', DISC: 'Electrical', TOTAL: 13, ACTUAL: 13, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9590, PHASE: 'PA', DISC: 'Telecom', TOTAL: 13, ACTUAL: 12, REMAIN: 1, PROG: 92.31, },
{ SYSTEM: 9590, PHASE: 'PB', DISC: 'Telecom', TOTAL: 35, ACTUAL: 29, REMAIN: 6, PROG: 82.86, },
{ SYSTEM: 9810, PHASE: 'A', DISC: 'Piping', TOTAL: 16, ACTUAL: 4, REMAIN: 12, PROG: 25, },
{ SYSTEM: 9810, PHASE: 'A', DISC: 'Mechanical', TOTAL: 4, ACTUAL: 3, REMAIN: 1, PROG: 75, },
{ SYSTEM: 9810, PHASE: 'A', DISC: 'Electrical', TOTAL: 24, ACTUAL: 8, REMAIN: 16, PROG: 33.33, },
{ SYSTEM: 9810, PHASE: 'A', DISC: 'Instrument', TOTAL: 46, ACTUAL: 24, REMAIN: 22, PROG: 52.17, },
{ SYSTEM: 9810, PHASE: 'A', DISC: 'Telecom', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 9810, PHASE: 'B', DISC: 'Electrical', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 9810, PHASE: 'B', DISC: 'Instrument', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 9810, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 3, ACTUAL: 2, REMAIN: 1, PROG: 66.67, },
{ SYSTEM: 9810, PHASE: 'PA', DISC: 'Electrical', TOTAL: 19, ACTUAL: 19, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9810, PHASE: 'PA', DISC: 'Instrument', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 9810, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 34, ACTUAL: 29, REMAIN: 5, PROG: 85.29, },
{ SYSTEM: 9810, PHASE: 'PB', DISC: 'Electrical', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9810, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 2, ACTUAL: 1, REMAIN: 1, PROG: 50, },
{ SYSTEM: 9810, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 2, ACTUAL: 1, REMAIN: 1, PROG: 50, },
{ SYSTEM: 9810, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 2, ACTUAL: 1, REMAIN: 1, PROG: 50, },
{ SYSTEM: 9810, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 2, ACTUAL: 1, REMAIN: 1, PROG: 50, },
{ SYSTEM: 9810, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 2, ACTUAL: 1, REMAIN: 1, PROG: 50, },
{ SYSTEM: 9810, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 9810, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 9820, PHASE: 'A', DISC: 'Mechanical', TOTAL: 1724, ACTUAL: 50, REMAIN: 1674, PROG: 2.9, },
{ SYSTEM: 9820, PHASE: 'A', DISC: 'Electrical', TOTAL: 21, ACTUAL: 0, REMAIN: 21, PROG: 0, },
{ SYSTEM: 9820, PHASE: 'A', DISC: 'Instrument', TOTAL: 8, ACTUAL: 0, REMAIN: 8, PROG: 0, },
{ SYSTEM: 9820, PHASE: 'B', DISC: 'Mechanical', TOTAL: 10, ACTUAL: 0, REMAIN: 10, PROG: 0, },
{ SYSTEM: 9820, PHASE: 'B', DISC: 'Electrical', TOTAL: 9, ACTUAL: 0, REMAIN: 9, PROG: 0, },
{ SYSTEM: 9820, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 5, ACTUAL: 0, REMAIN: 5, PROG: 0, },
{ SYSTEM: 9820, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 3, ACTUAL: 0, REMAIN: 3, PROG: 0, },
{ SYSTEM: 9840, PHASE: 'A', DISC: 'Architecture', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 9840, PHASE: 'A', DISC: 'Electrical', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 9840, PHASE: 'B', DISC: 'Electrical', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 9851, PHASE: 'A', DISC: 'Architecture', TOTAL: 4, ACTUAL: 2, REMAIN: 2, PROG: 50, },
{ SYSTEM: 9851, PHASE: 'A', DISC: 'Mechanical', TOTAL: 4, ACTUAL: 4, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9851, PHASE: 'A', DISC: 'Electrical', TOTAL: 130, ACTUAL: 127, REMAIN: 3, PROG: 97.69, },
{ SYSTEM: 9851, PHASE: 'A', DISC: 'Instrument', TOTAL: 215, ACTUAL: 209, REMAIN: 6, PROG: 97.21, },
{ SYSTEM: 9851, PHASE: 'A', DISC: 'Telecom', TOTAL: 16, ACTUAL: 15, REMAIN: 1, PROG: 93.75, },
{ SYSTEM: 9851, PHASE: 'B', DISC: 'Mechanical', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 9851, PHASE: 'B', DISC: 'Electrical', TOTAL: 15, ACTUAL: 0, REMAIN: 15, PROG: 0, },
{ SYSTEM: 9851, PHASE: 'B', DISC: 'Instrument', TOTAL: 8, ACTUAL: 0, REMAIN: 8, PROG: 0, },
{ SYSTEM: 9851, PHASE: 'B', DISC: 'Telecom', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 9851, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 16, ACTUAL: 16, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9851, PHASE: 'PA', DISC: 'Electrical', TOTAL: 30, ACTUAL: 21, REMAIN: 9, PROG: 70, },
{ SYSTEM: 9851, PHASE: 'PA', DISC: 'Instrument', TOTAL: 52, ACTUAL: 34, REMAIN: 18, PROG: 65.38, },
{ SYSTEM: 9851, PHASE: 'PA', DISC: 'Telecom', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9851, PHASE: 'PB', DISC: 'Architecture', TOTAL: 4, ACTUAL: 4, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9851, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 87, ACTUAL: 78, REMAIN: 9, PROG: 89.66, },
{ SYSTEM: 9851, PHASE: 'PB', DISC: 'Electrical', TOTAL: 82, ACTUAL: 69, REMAIN: 13, PROG: 84.15, },
{ SYSTEM: 9851, PHASE: 'PB', DISC: 'Instrument', TOTAL: 98, ACTUAL: 86, REMAIN: 12, PROG: 87.76, },
{ SYSTEM: 9851, PHASE: 'PB', DISC: 'Telecom', TOTAL: 6, ACTUAL: 6, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9852, PHASE: 'A', DISC: 'Mechanical', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 9852, PHASE: 'A', DISC: 'Electrical', TOTAL: 19, ACTUAL: 0, REMAIN: 19, PROG: 0, },
{ SYSTEM: 9852, PHASE: 'A', DISC: 'Instrument', TOTAL: 105, ACTUAL: 0, REMAIN: 105, PROG: 0, },
{ SYSTEM: 9852, PHASE: 'A', DISC: 'Telecom', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 9852, PHASE: 'B', DISC: 'Mechanical', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 9852, PHASE: 'B', DISC: 'Electrical', TOTAL: 7, ACTUAL: 0, REMAIN: 7, PROG: 0, },
{ SYSTEM: 9852, PHASE: 'B', DISC: 'Instrument', TOTAL: 3, ACTUAL: 0, REMAIN: 3, PROG: 0, },
{ SYSTEM: 9852, PHASE: 'B', DISC: 'Telecom', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 9852, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 8, ACTUAL: 7, REMAIN: 1, PROG: 87.5, },
{ SYSTEM: 9852, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 9, ACTUAL: 7, REMAIN: 2, PROG: 77.78, },
{ SYSTEM: 9860, PHASE: 'A', DISC: 'Piping', TOTAL: 655, ACTUAL: 461, REMAIN: 194, PROG: 70.38, },
{ SYSTEM: 9860, PHASE: 'A', DISC: 'Mechanical', TOTAL: 31, ACTUAL: 20, REMAIN: 11, PROG: 64.52, },
{ SYSTEM: 9860, PHASE: 'A', DISC: 'Electrical', TOTAL: 51, ACTUAL: 28, REMAIN: 23, PROG: 54.9, },
{ SYSTEM: 9860, PHASE: 'A', DISC: 'Instrument', TOTAL: 97, ACTUAL: 63, REMAIN: 34, PROG: 64.95, },
{ SYSTEM: 9860, PHASE: 'B', DISC: 'Mechanical', TOTAL: 14, ACTUAL: 0, REMAIN: 14, PROG: 0, },
{ SYSTEM: 9860, PHASE: 'B', DISC: 'Electrical', TOTAL: 21, ACTUAL: 0, REMAIN: 21, PROG: 0, },
{ SYSTEM: 9860, PHASE: 'B', DISC: 'Instrument', TOTAL: 47, ACTUAL: 0, REMAIN: 47, PROG: 0, },
{ SYSTEM: 9860, PHASE: 'PA', DISC: 'Piping', TOTAL: 19, ACTUAL: 7, REMAIN: 12, PROG: 36.84, },
{ SYSTEM: 9860, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 66, ACTUAL: 62, REMAIN: 4, PROG: 93.94, },
{ SYSTEM: 9860, PHASE: 'PA', DISC: 'Electrical', TOTAL: 33, ACTUAL: 29, REMAIN: 4, PROG: 87.88, },
{ SYSTEM: 9860, PHASE: 'PA', DISC: 'Instrument', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9860, PHASE: 'PB', DISC: 'Company', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9860, PHASE: 'PB', DISC: 'Piping', TOTAL: 68, ACTUAL: 34, REMAIN: 34, PROG: 50, },
{ SYSTEM: 9860, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 34, ACTUAL: 32, REMAIN: 2, PROG: 94.12, },
{ SYSTEM: 9860, PHASE: 'PB', DISC: 'Electrical', TOTAL: 11, ACTUAL: 3, REMAIN: 8, PROG: 27.27, },
{ SYSTEM: 9860, PHASE: 'PB', DISC: 'Instrument', TOTAL: 29, ACTUAL: 28, REMAIN: 1, PROG: 96.55, },
{ SYSTEM: 9860, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 108, ACTUAL: 96, REMAIN: 12, PROG: 88.89, },
{ SYSTEM: 9860, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 108, ACTUAL: 95, REMAIN: 13, PROG: 87.96, },
{ SYSTEM: 9860, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 108, ACTUAL: 81, REMAIN: 27, PROG: 75, },
{ SYSTEM: 9860, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 108, ACTUAL: 81, REMAIN: 27, PROG: 75, },
{ SYSTEM: 9860, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 108, ACTUAL: 81, REMAIN: 27, PROG: 75, },
{ SYSTEM: 9860, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 108, ACTUAL: 67, REMAIN: 41, PROG: 62.04, },
{ SYSTEM: 9860, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 108, ACTUAL: 56, REMAIN: 52, PROG: 51.85, },
{ SYSTEM: 9882, PHASE: 'A', DISC: 'Electrical', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 9882, PHASE: 'A', DISC: 'Instrument', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 9882, PHASE: 'A', DISC: 'Telecom', TOTAL: 13, ACTUAL: 0, REMAIN: 13, PROG: 0, },
{ SYSTEM: 9882, PHASE: 'B', DISC: 'Electrical', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 9882, PHASE: 'B', DISC: 'Instrument', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 9882, PHASE: 'B', DISC: 'Telecom', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 9890, PHASE: 'A', DISC: 'Electrical', TOTAL: 17, ACTUAL: 16, REMAIN: 1, PROG: 94.12, },
{ SYSTEM: 9890, PHASE: 'A', DISC: 'Instrument', TOTAL: 4, ACTUAL: 4, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9890, PHASE: 'A', DISC: 'Telecom', TOTAL: 83, ACTUAL: 5, REMAIN: 78, PROG: 6.02, },
{ SYSTEM: 9890, PHASE: 'B', DISC: 'Electrical', TOTAL: 12, ACTUAL: 0, REMAIN: 12, PROG: 0, },
{ SYSTEM: 9890, PHASE: 'B', DISC: 'Instrument', TOTAL: 6, ACTUAL: 0, REMAIN: 6, PROG: 0, },
{ SYSTEM: 9890, PHASE: 'PA', DISC: 'Electrical', TOTAL: 18, ACTUAL: 18, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9890, PHASE: 'PA', DISC: 'Telecom', TOTAL: 8, ACTUAL: 5, REMAIN: 3, PROG: 62.5, },
{ SYSTEM: 9890, PHASE: 'PB', DISC: 'Electrical', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9890, PHASE: 'PB', DISC: 'Telecom', TOTAL: 35, ACTUAL: 32, REMAIN: 3, PROG: 91.43, },
{ SYSTEM: 9891, PHASE: 'A', DISC: 'Telecom', TOTAL: 59, ACTUAL: 0, REMAIN: 59, PROG: 0, },
{ SYSTEM: 9891, PHASE: 'B', DISC: 'Telecom', TOTAL: 30, ACTUAL: 0, REMAIN: 30, PROG: 0, },
{ SYSTEM: 9910, PHASE: 'A', DISC: 'Piping', TOTAL: 144, ACTUAL: 136, REMAIN: 8, PROG: 94.44, },
{ SYSTEM: 9910, PHASE: 'A', DISC: 'Mechanical', TOTAL: 99, ACTUAL: 99, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9910, PHASE: 'A', DISC: 'Electrical', TOTAL: 56, ACTUAL: 56, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9910, PHASE: 'A', DISC: 'Instrument', TOTAL: 358, ACTUAL: 320, REMAIN: 38, PROG: 89.39, },
{ SYSTEM: 9910, PHASE: 'B', DISC: 'Piping', TOTAL: 8, ACTUAL: 0, REMAIN: 8, PROG: 0, },
{ SYSTEM: 9910, PHASE: 'B', DISC: 'Mechanical', TOTAL: 32, ACTUAL: 10, REMAIN: 22, PROG: 31.25, },
{ SYSTEM: 9910, PHASE: 'B', DISC: 'Electrical', TOTAL: 24, ACTUAL: 0, REMAIN: 24, PROG: 0, },
{ SYSTEM: 9910, PHASE: 'B', DISC: 'Instrument', TOTAL: 40, ACTUAL: 31, REMAIN: 9, PROG: 77.5, },
{ SYSTEM: 9910, PHASE: 'PA', DISC: 'Piping', TOTAL: 6, ACTUAL: 6, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9910, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 36, ACTUAL: 36, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9910, PHASE: 'PA', DISC: 'Electrical', TOTAL: 33, ACTUAL: 33, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9910, PHASE: 'PA', DISC: 'Instrument', TOTAL: 62, ACTUAL: 62, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9910, PHASE: 'PB', DISC: 'Piping', TOTAL: 32, ACTUAL: 28, REMAIN: 4, PROG: 87.5, },
{ SYSTEM: 9910, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 265, ACTUAL: 262, REMAIN: 3, PROG: 98.87, },
{ SYSTEM: 9910, PHASE: 'PB', DISC: 'Electrical', TOTAL: 93, ACTUAL: 74, REMAIN: 19, PROG: 79.57, },
{ SYSTEM: 9910, PHASE: 'PB', DISC: 'Instrument', TOTAL: 77, ACTUAL: 69, REMAIN: 8, PROG: 89.61, },
{ SYSTEM: 9910, PHASE: 'PKG', DISC: 'Package Review', TOTAL: 22, ACTUAL: 22, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9910, PHASE: 'PKG', DISC: 'Line Walk', TOTAL: 22, ACTUAL: 22, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9910, PHASE: 'PKG', DISC: 'Flushing', TOTAL: 22, ACTUAL: 22, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9910, PHASE: 'PKG', DISC: 'Pressure Test', TOTAL: 22, ACTUAL: 22, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9910, PHASE: 'PKG', DISC: 'Cleanliness', TOTAL: 22, ACTUAL: 22, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9910, PHASE: 'PKG', DISC: 'Reinstatement', TOTAL: 22, ACTUAL: 22, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9910, PHASE: 'PKG', DISC: 'Certificate', TOTAL: 22, ACTUAL: 22, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9921, PHASE: 'A', DISC: 'Mechanical', TOTAL: 3, ACTUAL: 0, REMAIN: 3, PROG: 0, },
{ SYSTEM: 9921, PHASE: 'A', DISC: 'Electrical', TOTAL: 199, ACTUAL: 149, REMAIN: 50, PROG: 74.87, },
{ SYSTEM: 9921, PHASE: 'A', DISC: 'Instrument', TOTAL: 94, ACTUAL: 49, REMAIN: 45, PROG: 52.13, },
{ SYSTEM: 9921, PHASE: 'A', DISC: 'HVAC', TOTAL: 89, ACTUAL: 74, REMAIN: 15, PROG: 83.15, },
{ SYSTEM: 9921, PHASE: 'B', DISC: 'Mechanical', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 9921, PHASE: 'B', DISC: 'Electrical', TOTAL: 11, ACTUAL: 0, REMAIN: 11, PROG: 0, },
{ SYSTEM: 9921, PHASE: 'B', DISC: 'Instrument', TOTAL: 14, ACTUAL: 0, REMAIN: 14, PROG: 0, },
{ SYSTEM: 9921, PHASE: 'B', DISC: 'HVAC', TOTAL: 22, ACTUAL: 0, REMAIN: 22, PROG: 0, },
{ SYSTEM: 9921, PHASE: 'PA', DISC: 'Electrical', TOTAL: 25, ACTUAL: 25, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9921, PHASE: 'PA', DISC: 'Instrument', TOTAL: 6, ACTUAL: 6, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9921, PHASE: 'PA', DISC: 'HVAC', TOTAL: 55, ACTUAL: 55, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9921, PHASE: 'PB', DISC: 'Electrical', TOTAL: 45, ACTUAL: 18, REMAIN: 27, PROG: 40, },
{ SYSTEM: 9921, PHASE: 'PB', DISC: 'Instrument', TOTAL: 11, ACTUAL: 5, REMAIN: 6, PROG: 45.45, },
{ SYSTEM: 9921, PHASE: 'PB', DISC: 'HVAC', TOTAL: 49, ACTUAL: 37, REMAIN: 12, PROG: 75.51, },
{ SYSTEM: 9922, PHASE: 'A', DISC: 'Mechanical', TOTAL: 35, ACTUAL: 32, REMAIN: 3, PROG: 91.43, },
{ SYSTEM: 9922, PHASE: 'A', DISC: 'Electrical', TOTAL: 347, ACTUAL: 293, REMAIN: 54, PROG: 84.44, },
{ SYSTEM: 9922, PHASE: 'A', DISC: 'Instrument', TOTAL: 199, ACTUAL: 134, REMAIN: 65, PROG: 67.34, },
{ SYSTEM: 9922, PHASE: 'A', DISC: 'HVAC', TOTAL: 139, ACTUAL: 127, REMAIN: 12, PROG: 91.37, },
{ SYSTEM: 9922, PHASE: 'B', DISC: 'Mechanical', TOTAL: 18, ACTUAL: 0, REMAIN: 18, PROG: 0, },
{ SYSTEM: 9922, PHASE: 'B', DISC: 'Electrical', TOTAL: 42, ACTUAL: 0, REMAIN: 42, PROG: 0, },
{ SYSTEM: 9922, PHASE: 'B', DISC: 'Instrument', TOTAL: 37, ACTUAL: 0, REMAIN: 37, PROG: 0, },
{ SYSTEM: 9922, PHASE: 'B', DISC: 'HVAC', TOTAL: 30, ACTUAL: 0, REMAIN: 30, PROG: 0, },
{ SYSTEM: 9922, PHASE: 'PA', DISC: 'Insulation', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9922, PHASE: 'PA', DISC: 'Mechanical', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9922, PHASE: 'PA', DISC: 'Electrical', TOTAL: 23, ACTUAL: 19, REMAIN: 4, PROG: 82.61, },
{ SYSTEM: 9922, PHASE: 'PA', DISC: 'Instrument', TOTAL: 19, ACTUAL: 10, REMAIN: 9, PROG: 52.63, },
{ SYSTEM: 9922, PHASE: 'PA', DISC: 'HVAC', TOTAL: 110, ACTUAL: 106, REMAIN: 4, PROG: 96.36, },
{ SYSTEM: 9922, PHASE: 'PB', DISC: 'Mechanical', TOTAL: 3, ACTUAL: 3, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9922, PHASE: 'PB', DISC: 'Electrical', TOTAL: 42, ACTUAL: 19, REMAIN: 23, PROG: 45.24, },
{ SYSTEM: 9922, PHASE: 'PB', DISC: 'Instrument', TOTAL: 22, ACTUAL: 13, REMAIN: 9, PROG: 59.09, },
{ SYSTEM: 9922, PHASE: 'PB', DISC: 'HVAC', TOTAL: 92, ACTUAL: 62, REMAIN: 30, PROG: 67.39, },
{ SYSTEM: 9923, PHASE: 'A', DISC: 'Mechanical', TOTAL: 3, ACTUAL: 0, REMAIN: 3, PROG: 0, },
{ SYSTEM: 9923, PHASE: 'A', DISC: 'Electrical', TOTAL: 194, ACTUAL: 97, REMAIN: 97, PROG: 50, },
{ SYSTEM: 9923, PHASE: 'A', DISC: 'Instrument', TOTAL: 74, ACTUAL: 16, REMAIN: 58, PROG: 21.62, },
{ SYSTEM: 9923, PHASE: 'A', DISC: 'HVAC', TOTAL: 89, ACTUAL: 66, REMAIN: 23, PROG: 74.16, },
{ SYSTEM: 9923, PHASE: 'B', DISC: 'Mechanical', TOTAL: 4, ACTUAL: 0, REMAIN: 4, PROG: 0, },
{ SYSTEM: 9923, PHASE: 'B', DISC: 'Electrical', TOTAL: 10, ACTUAL: 0, REMAIN: 10, PROG: 0, },
{ SYSTEM: 9923, PHASE: 'B', DISC: 'Instrument', TOTAL: 11, ACTUAL: 0, REMAIN: 11, PROG: 0, },
{ SYSTEM: 9923, PHASE: 'B', DISC: 'HVAC', TOTAL: 22, ACTUAL: 0, REMAIN: 22, PROG: 0, },
{ SYSTEM: 9923, PHASE: 'PA', DISC: 'Electrical', TOTAL: 8, ACTUAL: 8, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9923, PHASE: 'PA', DISC: 'Instrument', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9923, PHASE: 'PA', DISC: 'HVAC', TOTAL: 57, ACTUAL: 55, REMAIN: 2, PROG: 96.49, },
{ SYSTEM: 9923, PHASE: 'PB', DISC: 'Electrical', TOTAL: 8, ACTUAL: 8, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9923, PHASE: 'PB', DISC: 'Instrument', TOTAL: 7, ACTUAL: 4, REMAIN: 3, PROG: 57.14, },
{ SYSTEM: 9923, PHASE: 'PB', DISC: 'HVAC', TOTAL: 49, ACTUAL: 45, REMAIN: 4, PROG: 91.84, },
{ SYSTEM: 9924, PHASE: 'A', DISC: 'Mechanical', TOTAL: 33, ACTUAL: 30, REMAIN: 3, PROG: 90.91, },
{ SYSTEM: 9924, PHASE: 'A', DISC: 'Electrical', TOTAL: 346, ACTUAL: 200, REMAIN: 146, PROG: 57.8, },
{ SYSTEM: 9924, PHASE: 'A', DISC: 'Instrument', TOTAL: 195, ACTUAL: 84, REMAIN: 111, PROG: 43.08, },
{ SYSTEM: 9924, PHASE: 'A', DISC: 'HVAC', TOTAL: 139, ACTUAL: 108, REMAIN: 31, PROG: 77.7, },
{ SYSTEM: 9924, PHASE: 'B', DISC: 'Mechanical', TOTAL: 18, ACTUAL: 0, REMAIN: 18, PROG: 0, },
{ SYSTEM: 9924, PHASE: 'B', DISC: 'Electrical', TOTAL: 42, ACTUAL: 0, REMAIN: 42, PROG: 0, },
{ SYSTEM: 9924, PHASE: 'B', DISC: 'Instrument', TOTAL: 38, ACTUAL: 0, REMAIN: 38, PROG: 0, },
{ SYSTEM: 9924, PHASE: 'B', DISC: 'HVAC', TOTAL: 30, ACTUAL: 0, REMAIN: 30, PROG: 0, },
{ SYSTEM: 9924, PHASE: 'PA', DISC: 'Electrical', TOTAL: 20, ACTUAL: 20, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9924, PHASE: 'PA', DISC: 'Instrument', TOTAL: 3, ACTUAL: 2, REMAIN: 1, PROG: 66.67, },
{ SYSTEM: 9924, PHASE: 'PA', DISC: 'HVAC', TOTAL: 116, ACTUAL: 114, REMAIN: 2, PROG: 98.28, },
{ SYSTEM: 9924, PHASE: 'PB', DISC: 'Electrical', TOTAL: 18, ACTUAL: 18, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9924, PHASE: 'PB', DISC: 'Instrument', TOTAL: 5, ACTUAL: 5, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9924, PHASE: 'PB', DISC: 'HVAC', TOTAL: 66, ACTUAL: 53, REMAIN: 13, PROG: 80.3, },
{ SYSTEM: 9930, PHASE: 'A', DISC: 'Architecture', TOTAL: 55, ACTUAL: 0, REMAIN: 55, PROG: 0, },
{ SYSTEM: 9930, PHASE: 'A', DISC: 'Electrical', TOTAL: 179, ACTUAL: 0, REMAIN: 179, PROG: 0, },
{ SYSTEM: 9930, PHASE: 'B', DISC: 'Electrical', TOTAL: 1, ACTUAL: 0, REMAIN: 1, PROG: 0, },
{ SYSTEM: 9930, PHASE: 'PA', DISC: 'Electrical', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9930, PHASE: 'PB', DISC: 'Electrical', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 9971, PHASE: 'A', DISC: 'Mechanical', TOTAL: 72, ACTUAL: 68, REMAIN: 4, PROG: 94.44, },
{ SYSTEM: 9971, PHASE: 'A', DISC: 'Electrical', TOTAL: 532, ACTUAL: 409, REMAIN: 123, PROG: 76.88, },
{ SYSTEM: 9971, PHASE: 'A', DISC: 'Instrument', TOTAL: 258, ACTUAL: 193, REMAIN: 65, PROG: 74.81, },
{ SYSTEM: 9971, PHASE: 'A', DISC: 'Telecom', TOTAL: 1, ACTUAL: 1, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9971, PHASE: 'A', DISC: 'HVAC', TOTAL: 177, ACTUAL: 112, REMAIN: 65, PROG: 63.28, },
{ SYSTEM: 9971, PHASE: 'B', DISC: 'Mechanical', TOTAL: 28, ACTUAL: 0, REMAIN: 28, PROG: 0, },
{ SYSTEM: 9971, PHASE: 'B', DISC: 'Electrical', TOTAL: 62, ACTUAL: 0, REMAIN: 62, PROG: 0, },
{ SYSTEM: 9971, PHASE: 'B', DISC: 'Instrument', TOTAL: 48, ACTUAL: 0, REMAIN: 48, PROG: 0, },
{ SYSTEM: 9971, PHASE: 'B', DISC: 'HVAC', TOTAL: 32, ACTUAL: 0, REMAIN: 32, PROG: 0, },
{ SYSTEM: 9971, PHASE: 'PA', DISC: 'Electrical', TOTAL: 36, ACTUAL: 20, REMAIN: 16, PROG: 55.56, },
{ SYSTEM: 9971, PHASE: 'PA', DISC: 'Instrument', TOTAL: 13, ACTUAL: 7, REMAIN: 6, PROG: 53.85, },
{ SYSTEM: 9971, PHASE: 'PA', DISC: 'HVAC', TOTAL: 92, ACTUAL: 89, REMAIN: 3, PROG: 96.74, },
{ SYSTEM: 9971, PHASE: 'PB', DISC: 'Electrical', TOTAL: 47, ACTUAL: 32, REMAIN: 15, PROG: 68.09, },
{ SYSTEM: 9971, PHASE: 'PB', DISC: 'Instrument', TOTAL: 6, ACTUAL: 5, REMAIN: 1, PROG: 83.33, },
{ SYSTEM: 9971, PHASE: 'PB', DISC: 'HVAC', TOTAL: 37, ACTUAL: 28, REMAIN: 9, PROG: 75.68, },
{ SYSTEM: 9972, PHASE: 'A', DISC: 'Mechanical', TOTAL: 104, ACTUAL: 97, REMAIN: 7, PROG: 93.27, },
{ SYSTEM: 9972, PHASE: 'A', DISC: 'Electrical', TOTAL: 897, ACTUAL: 772, REMAIN: 125, PROG: 86.06, },
{ SYSTEM: 9972, PHASE: 'A', DISC: 'Instrument', TOTAL: 491, ACTUAL: 329, REMAIN: 162, PROG: 67.01, },
{ SYSTEM: 9972, PHASE: 'A', DISC: 'Telecom', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9972, PHASE: 'A', DISC: 'HVAC', TOTAL: 300, ACTUAL: 227, REMAIN: 73, PROG: 75.67, },
{ SYSTEM: 9972, PHASE: 'B', DISC: 'Mechanical', TOTAL: 46, ACTUAL: 0, REMAIN: 46, PROG: 0, },
{ SYSTEM: 9972, PHASE: 'B', DISC: 'Electrical', TOTAL: 114, ACTUAL: 0, REMAIN: 114, PROG: 0, },
{ SYSTEM: 9972, PHASE: 'B', DISC: 'Instrument', TOTAL: 88, ACTUAL: 0, REMAIN: 88, PROG: 0, },
{ SYSTEM: 9972, PHASE: 'B', DISC: 'HVAC', TOTAL: 59, ACTUAL: 0, REMAIN: 59, PROG: 0, },
{ SYSTEM: 9972, PHASE: 'PA', DISC: 'Electrical', TOTAL: 101, ACTUAL: 94, REMAIN: 7, PROG: 93.07, },
{ SYSTEM: 9972, PHASE: 'PA', DISC: 'Instrument', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9972, PHASE: 'PA', DISC: 'HVAC', TOTAL: 211, ACTUAL: 197, REMAIN: 14, PROG: 93.36, },
{ SYSTEM: 9972, PHASE: 'PB', DISC: 'Electrical', TOTAL: 83, ACTUAL: 78, REMAIN: 5, PROG: 93.98, },
{ SYSTEM: 9972, PHASE: 'PB', DISC: 'Instrument', TOTAL: 11, ACTUAL: 11, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9972, PHASE: 'PB', DISC: 'HVAC', TOTAL: 100, ACTUAL: 75, REMAIN: 25, PROG: 75, },
{ SYSTEM: 9973, PHASE: 'A', DISC: 'Mechanical', TOTAL: 136, ACTUAL: 124, REMAIN: 12, PROG: 91.18, },
{ SYSTEM: 9973, PHASE: 'A', DISC: 'Electrical', TOTAL: 959, ACTUAL: 829, REMAIN: 130, PROG: 86.44, },
{ SYSTEM: 9973, PHASE: 'A', DISC: 'Instrument', TOTAL: 529, ACTUAL: 377, REMAIN: 152, PROG: 71.27, },
{ SYSTEM: 9973, PHASE: 'A', DISC: 'HVAC', TOTAL: 293, ACTUAL: 201, REMAIN: 92, PROG: 68.6, },
{ SYSTEM: 9973, PHASE: 'B', DISC: 'Mechanical', TOTAL: 58, ACTUAL: 0, REMAIN: 58, PROG: 0, },
{ SYSTEM: 9973, PHASE: 'B', DISC: 'Electrical', TOTAL: 131, ACTUAL: 0, REMAIN: 131, PROG: 0, },
{ SYSTEM: 9973, PHASE: 'B', DISC: 'Instrument', TOTAL: 97, ACTUAL: 0, REMAIN: 97, PROG: 0, },
{ SYSTEM: 9973, PHASE: 'B', DISC: 'HVAC', TOTAL: 60, ACTUAL: 0, REMAIN: 60, PROG: 0, },
{ SYSTEM: 9973, PHASE: 'PA', DISC: 'Electrical', TOTAL: 162, ACTUAL: 66, REMAIN: 96, PROG: 40.74, },
{ SYSTEM: 9973, PHASE: 'PA', DISC: 'Instrument', TOTAL: 14, ACTUAL: 5, REMAIN: 9, PROG: 35.71, },
{ SYSTEM: 9973, PHASE: 'PA', DISC: 'HVAC', TOTAL: 163, ACTUAL: 153, REMAIN: 10, PROG: 93.87, },
{ SYSTEM: 9973, PHASE: 'PB', DISC: 'Electrical', TOTAL: 103, ACTUAL: 70, REMAIN: 33, PROG: 67.96, },
{ SYSTEM: 9973, PHASE: 'PB', DISC: 'Instrument', TOTAL: 32, ACTUAL: 27, REMAIN: 5, PROG: 84.38, },
{ SYSTEM: 9973, PHASE: 'PB', DISC: 'HVAC', TOTAL: 92, ACTUAL: 74, REMAIN: 18, PROG: 80.43, },
{ SYSTEM: 9999, PHASE: 'A', DISC: 'Mechanical', TOTAL: 16, ACTUAL: 10, REMAIN: 6, PROG: 62.5, },
{ SYSTEM: 9999, PHASE: 'A', DISC: 'Electrical', TOTAL: 272, ACTUAL: 270, REMAIN: 2, PROG: 99.26, },
{ SYSTEM: 9999, PHASE: 'A', DISC: 'Instrument', TOTAL: 25, ACTUAL: 25, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9999, PHASE: 'A', DISC: 'Telecom', TOTAL: 2, ACTUAL: 2, REMAIN: 0, PROG: 100, },
{ SYSTEM: 9999, PHASE: 'B', DISC: 'Piping', TOTAL: 2, ACTUAL: 0, REMAIN: 2, PROG: 0, },
{ SYSTEM: 9999, PHASE: 'B', DISC: 'Electrical', TOTAL: 69, ACTUAL: 56, REMAIN: 13, PROG: 81.16, },
{ SYSTEM: 9999, PHASE: 'B', DISC: 'Instrument', TOTAL: 150, ACTUAL: 122, REMAIN: 28, PROG: 81.33, },
    ],

    SQL5: [
      { DISC: 'Electrical', TOTAL: 7167,  ACTUAL: 736,  REMAIN: 6431,   PROG: 10.27, },
      { DISC: 'HVAC',       TOTAL: 916,   ACTUAL: 0,    REMAIN: 916,    PROG: 0, },
      { DISC: 'Instrument', TOTAL: 12112, ACTUAL: 1313, REMAIN: 10799,  PROG: 10.84, },
      { DISC: 'Mechanical', TOTAL: 1884,  ACTUAL: 46,   REMAIN: 1838,   PROG: 2.44, },
      { DISC: 'Piping',     TOTAL: 420,   ACTUAL: 0,    REMAIN: 420,    PROG: 0, },
      { DISC: 'Telecom',    TOTAL: 2338,  ACTUAL: 63,   REMAIN: 2275,   PROG: 2.69, },

    ],

  },
}