import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 450,
      CanvasHeight: 350,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },
    DataItems:  [
      {SUBSYSTEM:"21-8000-8013-01",QCF:"P01A",QCF_DESCR:"Piping / Equipment Internal Cleaning Inspection",TOTAL:6,COMPLETED:1,REMAIN:5,PROG:96.7},
      {SUBSYSTEM:"21-8000-8013-01",QCF:"P03A",QCF_DESCR:"Piping Pressure Test Checksheet",TOTAL:6,COMPLETED:1,REMAIN:5,PROG:86.7},
      {SUBSYSTEM:"21-8000-8013-01",QCF:"P05A",QCF_DESCR:"Test Pack Reinstatement",TOTAL:6,COMPLETED:0,REMAIN:6,PROG:70},
      {SUBSYSTEM:"21-8000-8013-01",QCF:"P06A",QCF_DESCR:"Joint Integrity Certificate",TOTAL:6,COMPLETED:0,REMAIN:6,PROG:40}
    ],
}