import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 350,
      CanvasHeight: 530,
      CanvasBorderWeight: 1,
      CanvasBorderColor: '#ccc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 350,
      CanvasChartHeight: 530,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: 'Transparent',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },

    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },

    DataItems: [
      // { disc: 'Structure', activity: 'Steel Cutting',                          plan: 50, actual: 75},
      // { disc: 'Structure', activity: 'Steel Outfitting Fabrication',           plan: 100, actual: 75},
      // { disc: 'Structure', activity: 'Block Assembly',                         plan: 85, actual: 99.5},
      // { disc: 'Structure', activity: 'Joint Welding',                          plan: 100, actual: 75},
      // { disc: 'Structure', activity: 'Steel Outfitting Installation',          plan: 100, actual: 75},
      // { disc: 'Structure', activity: 'Block Erection',                         plan: 60, actual: 45},

      // { disc: 'Piping',     activity: 'Pipe Spool Fabrication',                 plan: null, actual: null},
      // { disc: 'Piping',     activity: 'Pipe Support Installation',              plan: 100, actual: 75},
      // { disc: 'Piping',     activity: 'Pipe Piece Installation',                plan: 100, actual: 100},

      // { disc: 'Electrical & Instrument', activity: 'Cable Tray Support / Seat Installation', plan: 100, actual: 75},
      // { disc: 'Electrical & Instrument', activity: 'Cable Tray Installation',                plan: 100, actual: 16},

      // { disc: 'Mechanical', activity: 'Equipment Seat Installation',            plan: 100, actual: 14},

      // { disc: 'HVAC',       activity: 'HVAC Support Installation',              plan: 100, actual: 75},
      // { disc: 'HVAC',       activity: 'Duct / Fittings Installation',           plan: 100, actual: 75},
    
      // { disc: 'Painting',   activity: 'Block Panting & PFP',                    plan: 100, actual: 75},

      {NO:11,disc:"STRUCTURE",activity:"BLOCK FABRICATION",plan:100,actual:100},
      {NO:11,disc:"STRUCTURE",activity:"MEGABLOCK ASSEMBLY",plan:0,actual:7.2},
      {NO:11,disc:"STRUCTURE",activity:"MEGABLOCK ERECTION",plan:63.33,actual:0},
      {NO:11,disc:"STRUCTURE",activity:"STEEL PRE-OUTFITTING FABRICATION",plan:100,actual:100},
      {NO:11,disc:"STRUCTURE",activity:"STEEL PRE-OUTFITTING INSTALLATION",plan:100,actual:100},
      {NO:16,disc:"ELECTRICAL",activity:"CATHODIC PROTECTION SYSTEM",plan:0,actual:100},
      {NO:16,disc:"ELECTRICAL",activity:"EICT PRE-OUTFITTINGS FABRICATION",plan:0,actual:100},
      {NO:16,disc:"ELECTRICAL",activity:"EICT PRE-OUTFITTINGS INSTALLATION",plan:0,actual:100},
      {NO:23,disc:"PAINTING",activity:"STEEL STRUCT. PAINTING",plan:100,actual:99.74},
      
      {NO:11,disc:"STRUCTURE",activity:"BLOCK FABRICATION",plan:100,actual:100},
      {NO:11,disc:"STRUCTURE",activity:"MEGABLOCK ASSEMBLY",plan:0,actual:0},
      {NO:11,disc:"STRUCTURE",activity:"MEGABLOCK ERECTION",plan:0,actual:0},
      {NO:11,disc:"STRUCTURE",activity:"STEEL PRE-OUTFITTING FABRICATION",plan:100,actual:100},
      {NO:11,disc:"STRUCTURE",activity:"STEEL PRE-OUTFITTING INSTALLATION",plan:100,actual:100},
      {NO:13,disc:"PIPING",activity:"AREA PIPING FABRICATION",plan:0,actual:79.3},
      {NO:13,disc:"PIPING",activity:"AREA PIPING INSTALLATION",plan:0,actual:0},
      {NO:13,disc:"PIPING",activity:"BLOCK PIPING FABRICATION",plan:100,actual:100},
      {NO:13,disc:"PIPING",activity:"BLOCK PIPING INSTALLATION",plan:100,actual:98.25},
      {NO:16,disc:"ELECTRICAL",activity:"EICT PRE-OUTFITTINGS FABRICATION",plan:100,actual:100},
      {NO:16,disc:"ELECTRICAL",activity:"EICT PRE-OUTFITTINGS INSTALLATION",plan:100,actual:100},
      {NO:20,disc:"ARCHITECTURE",activity:"ARCHITECTURAL OUTFITTINGS INSTALLATION",plan:0,actual:100},
      {NO:20,disc:"ARCHITECTURE",activity:"COMPARTMENT INSULATION",plan:0,actual:0},
      {NO:21,disc:"HVAC",activity:"HVAC PRE-OUTFITTINGS FABRICATION",plan:100,actual:100},
      {NO:21,disc:"HVAC",activity:"HVAC PRE-OUTFITTINGS INSTALLATION",plan:100,actual:96.88},
      {NO:23,disc:"PAINTING",activity:"PIPING PAINTING",plan:0,actual:18.14},
      {NO:23,disc:"PAINTING",activity:"STEEL STRUCT. PAINTING",plan:100,actual:36.17}
    ],
}