import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 300,
      CanvasHeight: 330,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     

    DataItems: [
      { TYPE: 'ITRs',    DATE: null,     DISC: 'Architecture', TOTAL: 1444,    ACTUAL: 210,    REMAIN: 1234,   PROG: 14.54, },
      { TYPE: 'ITRs',    DATE: null,     DISC: 'Electrical',   TOTAL: 43742,   ACTUAL: 25361,  REMAIN: 18381,  PROG: 57.98, },
      { TYPE: 'ITRs',    DATE: null,     DISC: 'HVAC',         TOTAL: 6104,    ACTUAL: 4272,   REMAIN: 1832,   PROG: 69.99, },
      { TYPE: 'ITRs',    DATE: null,     DISC: 'Instrument',   TOTAL: 54380,   ACTUAL: 32939,  REMAIN: 21441,  PROG: 60.57, },
      { TYPE: 'ITRs',    DATE: null,     DISC: 'Mechanical',   TOTAL: 6512,    ACTUAL: 3533,   REMAIN: 2979,   PROG: 54.25, },
      { TYPE: 'ITRs',    DATE: null,     DISC: 'Overall',      TOTAL: 156703,  ACTUAL: 91625,  REMAIN: 65078,  PROG: 58.47, },
      { TYPE: 'WEEK', DATE: '43889',  DISC: 'LWK',          TOTAL: null,    ACTUAL: 3426,   REMAIN: null,   PROG: null, },
      { TYPE: 'WEEK', DATE: '43890',  DISC: 'SAT',          TOTAL: null,    ACTUAL: 411,    REMAIN: null,   PROG: null, },
      { TYPE: 'WEEK', DATE: '43891',  DISC: 'SUN',          TOTAL: null,    ACTUAL: 95,     REMAIN: null,   PROG: null, },
      { TYPE: 'WEEK', DATE: '43892',  DISC: 'MON',          TOTAL: null,    ACTUAL: 0,      REMAIN: null,   PROG: null, },
      { TYPE: 'WEEK', DATE: '43893',  DISC: 'TUE',          TOTAL: null,    ACTUAL: 0,      REMAIN: null,   PROG: null, },
      { TYPE: 'WEEK', DATE: '43894',  DISC: 'WEN',          TOTAL: null,    ACTUAL: 0,      REMAIN: null,   PROG: null, },
      { TYPE: 'WEEK', DATE: '43895',  DISC: 'TUE',          TOTAL: null,    ACTUAL: 0,      REMAIN: null,   PROG: null, },
      { TYPE: 'WEEK', DATE: '43896',  DISC: 'FRI',          TOTAL: null,    ACTUAL: 0,      REMAIN: null,   PROG: null, },
      { TYPE: 'WEEK', DATE: '43896',  DISC: 'TWK',          TOTAL: null,    ACTUAL: 506,    REMAIN: null,   PROG: null, },
    ],

    Queries: {
      SQL1: [
        { CDATE: '2020-01-17', DISC: 'PI', CUMULATE: '19117', PERIOD: 1116, },
        { CDATE: '2020-01-24', DISC: 'PI', CUMULATE: '18248', PERIOD: 869, },
        { CDATE: '2020-01-31', DISC: 'PI', CUMULATE: '17521', PERIOD: 727, },
        { CDATE: '2020-02-07', DISC: 'PI', CUMULATE: '16493', PERIOD: 1028, },
      ],       
      
      
      
    },
}