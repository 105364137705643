import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 578,
      CanvasHeight: 380,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#e0e0e0',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 380,
      CanvasChartHeight: 280,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Support Fabrication', x:20, y: 10, font: 'roboto', class: 'regular', size: 12, color: '#424242', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: -50, y: 20, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'start' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: -30,
          y: 20,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'start'
        },
      ]
    },
    Queries: {
      SQL1: [
        {PLAN:100,ACTUAL:99.99,DELTA:-0.01000000000000512}
      ],
      SQL2: [
        {PLAN:117181.3,ACTUAL:93100.6,DELTA:-24080.7}
      ]
    },
    DataItems: [
      // {WK_NO:"W2020_33",CUM_PLAN_PROG:23.00765414177223,CUM_ACTUAL_PROG:16.8661405599784,WK_PLAN_QTY:414.3999999999996,WK_ACTUAL_QTY:353.3000000000084,CUM_PLAN_QTY:68462.4,CUM_ACTUAL_QTY:49666.2,WK_PLAN_PROG:0.1493410550657317,WK_ACTUAL_PROG:0.1128027953078776},
      // {WK_NO:"W2020_34",CUM_PLAN_PROG:23.01676986079489,CUM_ACTUAL_PROG:16.86708249927418,WK_PLAN_QTY:28,WK_ACTUAL_QTY:2.200000000000728,CUM_PLAN_QTY:68490.4,CUM_ACTUAL_QTY:49668.40000000001,WK_PLAN_PROG:0.00911571902266024,WK_ACTUAL_PROG:0.0009419392957738578},
      // {WK_NO:"W2020_35",CUM_PLAN_PROG:23.01676986079489,CUM_ACTUAL_PROG:17.53805362427354,WK_PLAN_QTY:0,WK_ACTUAL_QTY:2092.299999999996,CUM_PLAN_QTY:68490.4,CUM_ACTUAL_QTY:51760.7,WK_PLAN_PROG:0,WK_ACTUAL_PROG:0.6709711249993626},
      // {WK_NO:"W2020_36",CUM_PLAN_PROG:23.01676986079489,CUM_ACTUAL_PROG:18.06730442267826,WK_PLAN_QTY:0,WK_ACTUAL_QTY:1611.4,CUM_PLAN_QTY:68490.4,CUM_ACTUAL_QTY:53372.1,WK_PLAN_PROG:0,WK_ACTUAL_PROG:0.529250798404719},
      // {WK_NO:"W2020_37",CUM_PLAN_PROG:23.01676986079489,CUM_ACTUAL_PROG:18.75671923149407,WK_PLAN_QTY:0,WK_ACTUAL_QTY:2119.6,CUM_PLAN_QTY:68490.4,CUM_ACTUAL_QTY:55491.7,WK_PLAN_PROG:0,WK_ACTUAL_PROG:0.6894148088158149},
      // {WK_NO:"W2020_38",CUM_PLAN_PROG:23.01676986079489,CUM_ACTUAL_PROG:18.75671923149407,WK_PLAN_QTY:0,WK_ACTUAL_QTY:0,CUM_PLAN_QTY:68490.4,CUM_ACTUAL_QTY:55491.7,WK_PLAN_PROG:0,WK_ACTUAL_PROG:0},
      // {WK_NO:"W2020_39",CUM_PLAN_PROG:23.01676986079489,CUM_ACTUAL_PROG:19.12891029852951,WK_PLAN_QTY:0,WK_ACTUAL_QTY:1001.799999999992,CUM_PLAN_QTY:68490.4,CUM_ACTUAL_QTY:56493.49999999999,WK_PLAN_PROG:0,WK_ACTUAL_PROG:0.372191067035434},
      // {WK_NO:"W2020_40",CUM_PLAN_PROG:23.9488265106021,CUM_ACTUAL_PROG:19.19290614224083,WK_PLAN_QTY:2840.811194999999,WK_ACTUAL_QTY:198.8000000000066,CUM_PLAN_QTY:71331.21119500001,CUM_ACTUAL_QTY:56692.3,WK_PLAN_PROG:0.9320566498072114,WK_ACTUAL_PROG:0.06399584371132208},
      // {WK_NO:"W2020_41",CUM_PLAN_PROG:25.14492021046305,CUM_ACTUAL_PROG:19.25212673624375,WK_PLAN_QTY:3807.039852000004,WK_ACTUAL_QTY:187.6000000000004,CUM_PLAN_QTY:75138.251047,CUM_ACTUAL_QTY:56879.9,WK_PLAN_PROG:1.196093699860949,WK_ACTUAL_PROG:0.05922059400292377},
      // {WK_NO:"W2020_42",CUM_PLAN_PROG:26.68901339078791,CUM_ACTUAL_PROG:20.27235757325714,WK_PLAN_QTY:5448.126976999997,WK_ACTUAL_QTY:2765,CUM_PLAN_QTY:80586.378024,CUM_ACTUAL_QTY:59644.9,WK_PLAN_PROG:1.544093180324861,WK_ACTUAL_PROG:1.020230837013381},
      // {WK_NO:"W2020_43",CUM_PLAN_PROG:28.83098314563819,CUM_ACTUAL_PROG:20.27235757325714,WK_PLAN_QTY:6956.251976000001,WK_ACTUAL_QTY:0,CUM_PLAN_QTY:87542.62999999999,CUM_ACTUAL_QTY:59644.9,WK_PLAN_PROG:2.141969754850277,WK_ACTUAL_PROG:0},
      // {WK_NO:"W2020_44",CUM_PLAN_PROG:30.38005663962023,CUM_ACTUAL_PROG:20.79024076932089,WK_PLAN_QTY:5418.400000000003,WK_ACTUAL_QTY:3385.230000000001,CUM_PLAN_QTY:92961.03,CUM_ACTUAL_QTY:63030.13,WK_PLAN_PROG:1.549073493982041,WK_ACTUAL_PROG:0.5178831960637501},
      // {WK_NO:"W2020_45",CUM_PLAN_PROG:31.95950817250636,CUM_ACTUAL_PROG:21.89613195197857,WK_PLAN_QTY:5501.099999999995,WK_ACTUAL_QTY:3457.700000000012,CUM_PLAN_QTY:98462.13,CUM_ACTUAL_QTY:66487.83000000002,WK_PLAN_PROG:1.579451532886124,WK_ACTUAL_PROG:1.105891182657683},
      // {WK_NO:"W2020_46",CUM_PLAN_PROG:33.88111007543461,CUM_ACTUAL_PROG:30.69447539589159,WK_PLAN_QTY:6538.400000000007,WK_ACTUAL_QTY:24748.99999999999,CUM_PLAN_QTY:105000.53,CUM_ACTUAL_QTY:91236.82999999999,WK_PLAN_PROG:1.92160190292825,WK_ACTUAL_PROG:8.798343443913021},
      // {WK_NO:"W2020_47",CUM_PLAN_PROG:35.5903295488695,CUM_ACTUAL_PROG:31.25165548645386,WK_PLAN_QTY:5873.599999999989,WK_ACTUAL_QTY:1420.299999999999,CUM_PLAN_QTY:110874.13,CUM_ACTUAL_QTY:92657.13,WK_PLAN_PROG:1.709219473434897,WK_ACTUAL_PROG:0.5571800905622734},
      // {WK_NO:"W2020_48",CUM_PLAN_PROG:37.45972036734258,CUM_ACTUAL_PROG:31.42437551253253,WK_PLAN_QTY:6307.200000000004,WK_ACTUAL_QTY:443.4999999999964,CUM_PLAN_QTY:117181.33,CUM_ACTUAL_QTY:93100.62999999999,WK_PLAN_PROG:1.869390818473072,WK_ACTUAL_PROG:0.1727200260786724,WKS4_TREND:31.42437551253254},
      // {WK_NO:"W2020_49",CUM_PLAN_PROG:39.2793062155871,WK_PLAN_QTY:5925.5,CUM_PLAN_QTY:123106.83,WK_PLAN_PROG:1.819585848244529,WKS4_TREND:34.08287221959058},
      // {WK_NO:"W2020_50",CUM_PLAN_PROG:40.62343357323676,WK_PLAN_QTY:5378.145,CUM_PLAN_QTY:128484.975,WK_PLAN_PROG:1.344127357649659,WKS4_TREND:36.74136892664864},
      // {WK_NO:"W2020_51",CUM_PLAN_PROG:41.75651610307188,WK_PLAN_QTY:7300.847499999998,CUM_PLAN_QTY:135785.8225,WK_PLAN_PROG:1.133082529835123,WKS4_TREND:39.39986563370669},
      // {WK_NO:"W2020_52",CUM_PLAN_PROG:42.78623703069847,WK_PLAN_QTY:7404.002499999997,CUM_PLAN_QTY:143189.825,WK_PLAN_PROG:1.029720927626586,WKS4_TREND:42.05836234076474}
    
      {WK_NO:"W2021_37",CUM_PLAN_PROG:100,CUM_ACTUAL_PROG:100,WK_PLAN_QTY:0,WK_ACTUAL_QTY:0,CUM_PLAN_QTY:77264.70999999999,CUM_ACTUAL_QTY:77264.70999999999,WK_PLAN_PROG:0,WK_ACTUAL_PROG:0},
      {WK_NO:"W2021_38",CUM_PLAN_PROG:100,CUM_ACTUAL_PROG:100,WK_PLAN_QTY:0,WK_ACTUAL_QTY:0,CUM_PLAN_QTY:77264.70999999999,CUM_ACTUAL_QTY:77264.70999999999,WK_PLAN_PROG:0,WK_ACTUAL_PROG:0},
      {WK_NO:"W2021_39",CUM_PLAN_PROG:100,CUM_ACTUAL_PROG:100,WK_PLAN_QTY:0,WK_ACTUAL_QTY:0,CUM_PLAN_QTY:77264.70999999999,CUM_ACTUAL_QTY:77264.70999999999,WK_PLAN_PROG:0,WK_ACTUAL_PROG:0},
      {WK_NO:"W2021_40",CUM_PLAN_PROG:100,CUM_ACTUAL_PROG:100,WK_PLAN_QTY:0,WK_ACTUAL_QTY:0,CUM_PLAN_QTY:77264.70999999999,CUM_ACTUAL_QTY:77264.70999999999,WK_PLAN_PROG:0,WK_ACTUAL_PROG:0},
      {WK_NO:"W2021_41",CUM_PLAN_PROG:100,CUM_ACTUAL_PROG:100,WK_PLAN_QTY:0,WK_ACTUAL_QTY:0,CUM_PLAN_QTY:77264.70999999999,CUM_ACTUAL_QTY:77264.70999999999,WK_PLAN_PROG:0,WK_ACTUAL_PROG:0},
      {WK_NO:"W2021_42",CUM_PLAN_PROG:100,CUM_ACTUAL_PROG:100,WK_PLAN_QTY:0,WK_ACTUAL_QTY:0,CUM_PLAN_QTY:77264.70999999999,CUM_ACTUAL_QTY:77264.70999999999,WK_PLAN_PROG:0,WK_ACTUAL_PROG:0},
      {WK_NO:"W2021_43",CUM_PLAN_PROG:100,CUM_ACTUAL_PROG:100,WK_PLAN_QTY:0,WK_ACTUAL_QTY:0,CUM_PLAN_QTY:77264.70999999999,CUM_ACTUAL_QTY:77264.70999999999,WK_PLAN_PROG:0,WK_ACTUAL_PROG:0},
      {WK_NO:"W2021_44",CUM_PLAN_PROG:100,CUM_ACTUAL_PROG:100,WK_PLAN_QTY:0,WK_ACTUAL_QTY:0,CUM_PLAN_QTY:77264.70999999999,CUM_ACTUAL_QTY:77264.70999999999,WK_PLAN_PROG:0,WK_ACTUAL_PROG:0},
      {WK_NO:"W2021_45",CUM_PLAN_PROG:100,CUM_ACTUAL_PROG:100,WK_PLAN_QTY:0,WK_ACTUAL_QTY:0,CUM_PLAN_QTY:77264.70999999999,CUM_ACTUAL_QTY:77264.70999999999,WK_PLAN_PROG:0,WK_ACTUAL_PROG:0},
      {WK_NO:"W2021_46",CUM_PLAN_PROG:100,CUM_ACTUAL_PROG:100,WK_PLAN_QTY:0,WK_ACTUAL_QTY:0,CUM_PLAN_QTY:77264.70999999999,CUM_ACTUAL_QTY:77264.70999999999,WK_PLAN_PROG:0,WK_ACTUAL_PROG:0,WKS4_TREND:100},
      {WK_NO:"W2021_47",CUM_PLAN_PROG:100,WK_PLAN_QTY:0,CUM_PLAN_QTY:77264.70999999999,WK_PLAN_PROG:0,WKS4_TREND:100},
      {WK_NO:"W2021_48",CUM_PLAN_PROG:100,WK_PLAN_QTY:0,CUM_PLAN_QTY:77264.70999999999,WK_PLAN_PROG:0,WKS4_TREND:100},
      {WK_NO:"W2021_49",CUM_PLAN_PROG:100,WK_PLAN_QTY:0,CUM_PLAN_QTY:77264.70999999999,WK_PLAN_PROG:0,WKS4_TREND:100},
      {WK_NO:"W2021_50",CUM_PLAN_PROG:100,WK_PLAN_QTY:0,CUM_PLAN_QTY:77264.70999999999,WK_PLAN_PROG:0,WKS4_TREND:100}
    ],
}