import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 1200,
    CanvasHeight: 1000,
    CanvasBorderWeight: 0.1,
    CanvasBorderColor: '#e0e0e0',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.2,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 380,
    CanvasChartHeight: 280,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  Bar: {
    BarAutoSize: 'Y',
    BarThickness: 100,
    BarDistance: 20,
    BarRadius: 10, // Embossed only
    // BarColorType: "LinearA9",
    // BarColorSet: ["Gold", "Orange", "Pink", "Gray", "SkyBlue", "LightBlue", "YellowGreen", "Red", "Thistle", "Purple", "Indigo", "LightBlue", "SkyBlue", "SteelBlue", "YellowGreen","DarkGreen"],
    BarColorType: 'LinearA2',
    BarColorSet: ['#81C784', '#FFEE58', '#E0E0E0', '#81C784', '#AED581', '#DCE775'],
    BarReflection: 'Y',
    // ["#ffee58","#ffeb3b","#fdd835","#fbc02d","#f9a825","#f57f17"]

    BarBackDisplay: 'Y',
    BarBackColor: '#efefef',

    BarSeriesDisplay: 'Y',
    BarSeriesSpace: 5,
    BarSeriesFont: 'roboto',
    BarSeriesStyle: 'regular',
    BarSeriesSize: 12,
    BarSeriesAutoColor: 'Y',
    BarSeriesColor: '#757575',

    BarValueDisplay: 'Y',
    BarValueFont: 'roboto',
    BarValueStyle: 'regular',
    BarValueSize: 11,
    BarValueRound: 1,
    BarValueAutoColor: 'N',
    BarValueColor: '#757575',
    BarValuePosition: 'top',
    BarValueSpace: 7, // Tube only

    BarUnitDisplay: 'Y',
    BarUnitFormat: '%',
    BarUnitSize: 11,
    BarUnitColor: '#757575',

    BarSeriesPosition: 'above' 
  },
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },
  DataItems: [
    //Overall Summary
    {TOTAL:1323,LOA_PLANNED:1015,LOA_ISSUED:284,PO_PLANNED:1016,PO_ISSUED:154}
  ],
  Queries: {
    SQL1: //LOA Summary Table
    [
      {NO:2,PSRNUM:"220kV ",LOA_NOT_ISSUED:27,LOA_ISSUED:0,TOTAL:27},
      {NO:3,PSRNUM:"P1_PSR",LOA_NOT_ISSUED:315,LOA_ISSUED:113,TOTAL:428},
      {NO:4,PSRNUM:"P2_PSR",LOA_NOT_ISSUED:206,LOA_ISSUED:93,TOTAL:299},
      {NO:5,PSRNUM:"P3_PSR",LOA_NOT_ISSUED:183,LOA_ISSUED:78,TOTAL:261},
      {NO:9,PSRNUM:"Grand Total",LOA_NOT_ISSUED:731,LOA_ISSUED:284,TOTAL:1015}
    ],
    SQL2: // PO Summary Table
    [
      {NO:2,PSRNUM:"220kV ",PO_NOT_ISSUED:27,PO_ISSUED:0,TOTAL:27},
      {NO:3,PSRNUM:"P1_PSR",PO_NOT_ISSUED:373,PO_ISSUED:52,TOTAL:425},
      {NO:4,PSRNUM:"P2_PSR",PO_NOT_ISSUED:253,PO_ISSUED:50,TOTAL:303},
      {NO:5,PSRNUM:"P3_PSR",PO_NOT_ISSUED:209,PO_ISSUED:52,TOTAL:261},
      {NO:9,PSRNUM:"Grand Total",PO_NOT_ISSUED:862,PO_ISSUED:154,TOTAL:1016}
    ],
    SQL3: // Calendar days between LOA and PO Issue Summary Table
    [
      {NO:2,PSRNUM:"220kV "},
      {NO:3,PSRNUM:"P1_PSR",AVG:48},
      {NO:4,PSRNUM:"P2_PSR",AVG:47},
      {NO:5,PSRNUM:"P3_PSR",AVG:46},
      {NO:9,PSRNUM:"Grand Total",AVG:47}
    ],
    SQL4: // LOA AND PO EXECUTED
    [
      {NO:2,PSRNUM:"P1_PSR",TOTAL:52},
      {NO:3,PSRNUM:"P2_PSR",TOTAL:46},
      {NO:4,PSRNUM:"P3_PSR",TOTAL:52},
      {NO:9,PSRNUM:"Grand Total",TOTAL:150}
    ],
    SQL5: // LOA EXECUTED(NOT YET PO)
    [
      {NO:2,PSRNUM:"P1_PSR",TOTAL:61},
      {NO:3,PSRNUM:"P2_PSR",TOTAL:47},
      {NO:4,PSRNUM:"P3_PSR",TOTAL:26},
      {NO:9,PSRNUM:"Grand Total",TOTAL:134}
    ],
    SQL6: // Bar Chart - Calendar Days Between LOA and PO Executed
    [
      {CATEGORY:"H-0-30 Days/Since LOA",P1_PSR:21,P2_PSR:9,P3_PSR:0,TOTAL:30},
      {CATEGORY:"H-30-45 Days/Since LOA",P1_PSR:7,P2_PSR:12,P3_PSR:45,TOTAL:64},
      {CATEGORY:"H-45-60 Days/Since LOA",P1_PSR:3,P2_PSR:16,P3_PSR:3,TOTAL:22},
      {CATEGORY:"H-60-90 Days/Since LOA",P1_PSR:18,P2_PSR:8,P3_PSR:2,TOTAL:28},
      {CATEGORY:"H>90 Days/Since LOA",P1_PSR:3,P2_PSR:1,P3_PSR:2,TOTAL:6}
    ],
    SQL7: // LOA EXECUTED (NOT YET PO)
    [
      {CATEGORY:"0-30 Days/Since LOA",P1_PSR:27,P2_PSR:24,P3_PSR:36,TOTAL:87},
      {CATEGORY:"30-45 Days/Since LOA",P1_PSR:2,P2_PSR:0,P3_PSR:4,TOTAL:6},
      {CATEGORY:"45-60 Days/Since LOA",P1_PSR:41,P2_PSR:19,P3_PSR:16,TOTAL:76},
      {CATEGORY:"60-90 Days/Since LOA",P1_PSR:4,P2_PSR:4,P3_PSR:2,TOTAL:10},
      {CATEGORY:"90 Days/Since LOA",P1_PSR:5,P2_PSR:0,P3_PSR:14,TOTAL:19}
    ]
  },
}