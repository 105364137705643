export default {
    Canvas: {
      CanvasWidth: 1250,
      CanvasHeight: 800,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 145,
      CanvasChartY: 250,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        {text:'Weekly Open/Closed Status for TQ',x: 70,y: 42,font: 'roboto',size: 14,}
      ],
      Filter: [],
      Sql: [
        {
          query: "SELECT 'Datasource: Shop Drawing Index 04-Oct-20' TITLE", 
          text: 'Datasource: Shop Drawing Index 04-Oct-20',
          x: 70,
          y: 62,
          font: 'roboto',
          style: 'regular',
          size: 10,
          color: '#757575',
          align: 'start'
        },
      ]
    },
    DataItems: [
      {CUTOFF:'2020-05-22', CUM_OPEN:93,  WK_OPEN:0, CUM_CLOSED:45 , WK_CLOSED:0},
      {CUTOFF:'2020-05-29', CUM_OPEN:95,  WK_OPEN:2, CUM_CLOSED:47 , WK_CLOSED:0},
      {CUTOFF:'2020-06-05', CUM_OPEN:96,  WK_OPEN:0, CUM_CLOSED:49 , WK_CLOSED:0},
      {CUTOFF:'2020-06-12', CUM_OPEN:98,  WK_OPEN:1, CUM_CLOSED:49 , WK_CLOSED:0},
      {CUTOFF:'2020-06-19', CUM_OPEN:101, WK_OPEN:0, CUM_CLOSED:25 , WK_CLOSED:0},
      {CUTOFF:'2020-06-26', CUM_OPEN:102, WK_OPEN:0, CUM_CLOSED:40 , WK_CLOSED:1},
      {CUTOFF:'2020-07-03', CUM_OPEN:105, WK_OPEN:1, CUM_CLOSED:38 , WK_CLOSED:0},
      {CUTOFF:'2020-07-10', CUM_OPEN:107, WK_OPEN:0, CUM_CLOSED:35 , WK_CLOSED:0},
      {CUTOFF:'2020-07-17', CUM_OPEN:110, WK_OPEN:0, CUM_CLOSED:25 , WK_CLOSED:0},
      {CUTOFF:'2020-07-24', CUM_OPEN:125, WK_OPEN:9, CUM_CLOSED:94 , WK_CLOSED:2},
      {CUTOFF:'2020-07-31', CUM_OPEN:127, WK_OPEN:2, CUM_CLOSED:71 , WK_CLOSED:4},
      {CUTOFF:'2020-08-07', CUM_OPEN:133, WK_OPEN:6, CUM_CLOSED:85 , WK_CLOSED:4},
      {CUTOFF:'2020-08-14', CUM_OPEN:141, WK_OPEN:5, CUM_CLOSED:85 , WK_CLOSED:0},
      {CUTOFF:'2020-08-21', CUM_OPEN:146, WK_OPEN:0, CUM_CLOSED:85 , WK_CLOSED:0},
      {CUTOFF:'2020-08-28', CUM_OPEN:152, WK_OPEN:0, CUM_CLOSED:85 , WK_CLOSED:0},
    ],    
    Queries: {
      SQL1: [
        {title:"Engineering Query",total:782,value:95.5},
        {title:"Site Query",total:26,value:3.2},
        {title:"N/A",total:11,value:1.3}
      ],       
      SQL2: [
        {TOTAL: 164, OPEN: 164, OVERDUE:2, BP_REVIEW: 27, TFMC_DEV: 46, },
      ],       
    },
}