import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 2300,
    CanvasHeight: 980,
    CanvasBorderWeight: 0.05,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.1,

    CanvasChartX: 50,
    CanvasChartY: 50,
    CanvasChartWidth: 480,
    CanvasChartHeight: 250,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  DataItems:   [
    {SUBSYSTEM:"21-6200-6210-01",SUBSYSTEM_DESCR:"PAGA System Cabinets and Central Equipment",SUBSYSTEM_STATUS:"Subsystems with Telecoms and Telecoms Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2021-12-02",A_OV_TOT:51,A_OV_COM:4,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:47,A_OV_PROG:7.84,B_OV_TOT:19,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:19,B_OV_PROG:0,PB1_OV_TOT:6,PB1_OV_REM:0},
    {SUBSYSTEM:"21-6200-6210-02",SUBSYSTEM_DESCR:"PAGA Field Equipment ME05andMW05",SUBSYSTEM_STATUS:"Subsystems with Telecoms and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-21",A_OV_TOT:731,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:731,A_OV_PROG:0},
    {SUBSYSTEM:"21-6200-6210-03",SUBSYSTEM_DESCR:"PAGA Field Equipment ME02andMW02",SUBSYSTEM_STATUS:"Subsystems with Telecoms and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-19",A_OV_TOT:764,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:764,A_OV_PROG:0},
    {SUBSYSTEM:"21-6200-6210-04",SUBSYSTEM_DESCR:"PAGA Field Equipment ME04andMW04",SUBSYSTEM_STATUS:"Subsystems with Telecoms and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-14",A_OV_TOT:366,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:366,A_OV_PROG:0},
    {SUBSYSTEM:"21-6200-6210-05",SUBSYSTEM_DESCR:"PAGA Field Equipment ME01andMW01",SUBSYSTEM_STATUS:"Subsystems with Telecoms and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-19",A_OV_TOT:972,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:972,A_OV_PROG:0},
    {SUBSYSTEM:"21-6200-6210-06",SUBSYSTEM_DESCR:"PAGA Field Equipment Topsides HULL Deck",SUBSYSTEM_STATUS:"Subsystems with Telecoms and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-06-02",A_OV_TOT:771,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:771,A_OV_PROG:0},
    {SUBSYSTEM:"21-6200-6210-07",SUBSYSTEM_DESCR:"PAGA Field Equipment LQ HULL Deck A",SUBSYSTEM_STATUS:"Subsystems with Telecoms and Telecoms Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-26",A_OV_TOT:594,A_OV_COM:331,A_OV_COM_LW:21,A_OV_COM_TW:2,A_OV_COM_CLIENT:192,A_OV_REM:263,A_OV_PROG:55.72,PA_OV_TOT:17,PA_OV_REM:13,PB1_OV_TOT:26,PB1_OV_REM:11},
    {SUBSYSTEM:"21-6200-6210-08",SUBSYSTEM_DESCR:"PAGA Field Equipment LQ Levels 1 and 2",SUBSYSTEM_STATUS:"Subsystems with Telecoms and Telecoms Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-02-17",A_OV_TOT:624,A_OV_COM:468,A_OV_COM_LW:67,A_OV_COM_TW:21,A_OV_COM_CLIENT:227,A_OV_REM:156,A_OV_PROG:75,PA_OV_TOT:23,PA_OV_REM:16,PB1_OV_TOT:32,PB1_OV_REM:18},
    {SUBSYSTEM:"21-6200-6210-09",SUBSYSTEM_DESCR:"PAGA Field Equipment LQ Levels 3 and 4",SUBSYSTEM_STATUS:"Subsystems with Telecoms and Telecoms Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-01-27",A_OV_TOT:1332,A_OV_COM:1134,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:951,A_OV_REM:198,A_OV_PROG:85.13,PA_OV_TOT:44,PA_OV_REM:35,PB1_OV_TOT:44,PB1_OV_REM:18},
    {SUBSYSTEM:"21-6200-6210-10",SUBSYSTEM_DESCR:"PAGA Field Equipment LQ Level 5 and LQ Roof",SUBSYSTEM_STATUS:"Subsystems with Telecoms and Telecoms Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-01-27",A_OV_TOT:368,A_OV_COM:214,A_OV_COM_LW:13,A_OV_COM_TW:0,A_OV_COM_CLIENT:131,A_OV_REM:154,A_OV_PROG:58.15,PA_OV_TOT:21,PA_OV_REM:17,PB1_OV_TOT:17,PB1_OV_REM:9},
    {SUBSYSTEM:"21-6200-6210-11",SUBSYSTEM_DESCR:"PAGA Field Equipment Machinery Space 1st and 2nd Levels",SUBSYSTEM_STATUS:"Subsystems with Telecoms and Telecoms Prerequisite MC Complete",PUNCH_STATUS:"A",MC_PLAN:"2022-01-06",A_OV_TOT:370,A_OV_COM:370,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:244,A_OV_REM:0,A_OV_PROG:100,PA_OV_TOT:8,PA_OV_REM:1,PB1_OV_TOT:20,PB1_OV_REM:7},
    {SUBSYSTEM:"21-6200-6210-12",SUBSYSTEM_DESCR:"PAGA Field Equipment Machinery Space 3rd Level",SUBSYSTEM_STATUS:"Subsystems with Telecoms and Telecoms Prerequisite MC Complete",PUNCH_STATUS:"A",MC_PLAN:"2022-01-06",A_OV_TOT:250,A_OV_COM:250,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:231,A_OV_REM:0,A_OV_PROG:100,PA_OV_TOT:8,PA_OV_REM:1,PB1_OV_TOT:16,PB1_OV_REM:8},
    {SUBSYSTEM:"21-6200-6224-01",SUBSYSTEM_DESCR:"Digital UHF Network",SUBSYSTEM_STATUS:"Subsystems with Telecoms and Telecoms Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-01-13",A_OV_TOT:240,A_OV_COM:6,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:234,A_OV_PROG:2.5,B_OV_TOT:15,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:15,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:0},
    {SUBSYSTEM:"21-6200-6225-01",SUBSYSTEM_DESCR:"VHF ,AM Aeronautical Radio",SUBSYSTEM_STATUS:"Subsystems with Telecoms and Telecoms Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2021-12-30",A_OV_TOT:22,A_OV_COM:3,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:19,A_OV_PROG:13.63,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0,PB1_OV_TOT:1,PB1_OV_REM:1},
    {SUBSYSTEM:"21-6200-6226-01",SUBSYSTEM_DESCR:"Non-Directional Beacon ,NDB",SUBSYSTEM_STATUS:"Subsystems with Telecoms and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2021-12-30",A_OV_TOT:21,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:21,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0},
    {SUBSYSTEM:"21-6200-6227-01",SUBSYSTEM_DESCR:"Crane Radio System",SUBSYSTEM_STATUS:"Subsystems with Telecoms and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-21",A_OV_TOT:44,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:44,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0},
    {SUBSYSTEM:"21-6200-6228-01",SUBSYSTEM_DESCR:"VHF ,FM Marine Radio",SUBSYSTEM_STATUS:"Subsystems with Telecoms and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2021-12-30",A_OV_TOT:20,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:20,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0},
    {SUBSYSTEM:"21-6500-6550-01",SUBSYSTEM_DESCR:"RADAR Vessel Early Warning System",SUBSYSTEM_STATUS:"Subsystems with Telecoms and Telecoms Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2021-12-16",A_OV_TOT:58,A_OV_COM:5,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:53,A_OV_PROG:8.62,B_OV_TOT:9,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:9,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:0,PB1_OV_TOT:1,PB1_OV_REM:1},
    {SUBSYSTEM:"21-6500-6556-01",SUBSYSTEM_DESCR:"Automatic Identification System",SUBSYSTEM_STATUS:"Subsystems with Telecoms and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-01-06",A_OV_TOT:4,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:4,A_OV_PROG:0,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0},
    {SUBSYSTEM:"21-9220-9224-01",SUBSYSTEM_DESCR:"Entertainment System",SUBSYSTEM_STATUS:"Subsystems with Telecoms and Telecoms Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2021-12-16",A_OV_TOT:739,A_OV_COM:5,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:734,A_OV_PROG:0.67,B_OV_TOT:5,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:5,B_OV_PROG:0,PB1_OV_TOT:2,PB1_OV_REM:0},
    {SUBSYSTEM:"21-9500-9514-01",SUBSYSTEM_DESCR:"Telephone System - LQ",SUBSYSTEM_STATUS:"Subsystems with Telecoms and Telecoms Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2021-12-30",A_OV_TOT:141,A_OV_COM:4,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:137,A_OV_PROG:2.83,B_OV_TOT:6,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:6,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:0,PB1_OV_TOT:5,PB1_OV_REM:1},
    {SUBSYSTEM:"21-9500-9514-02",SUBSYSTEM_DESCR:"Telephone System - Machinery Space and Topsides",SUBSYSTEM_STATUS:"Subsystems with Telecoms and Telecoms Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2021-12-30",A_OV_TOT:161,A_OV_COM:3,A_OV_COM_LW:0,A_OV_COM_TW:3,A_OV_COM_CLIENT:0,A_OV_REM:158,A_OV_PROG:1.86},
    {SUBSYSTEM:"21-9500-9515-01",SUBSYSTEM_DESCR:"Hot line Telephones",SUBSYSTEM_STATUS:"Subsystems with Telecoms and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2021-12-30",A_OV_TOT:15,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:15,A_OV_PROG:0,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0},
    {SUBSYSTEM:"21-9500-9525-01",SUBSYSTEM_DESCR:"Microwave Line of Sight",SUBSYSTEM_STATUS:"Subsystems with Telecoms and Telecoms Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-01-13",A_OV_TOT:20,A_OV_COM:1,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:19,A_OV_PROG:5,B_OV_TOT:5,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:5,B_OV_PROG:0,PB1_OV_TOT:1,PB1_OV_REM:0},
    {SUBSYSTEM:"21-9500-9527-01",SUBSYSTEM_DESCR:"INMARSAT Terminal and Portable Sat Phones",SUBSYSTEM_STATUS:"Subsystems with Telecoms and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-01-06",A_OV_TOT:14,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:14,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0},
    {SUBSYSTEM:"21-9500-9534-01",SUBSYSTEM_DESCR:"Structured Cabling System - LQ Deck-A",SUBSYSTEM_STATUS:"Subsystems with Telecoms and Telecoms Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2021-12-02",A_OV_TOT:342,A_OV_COM:16,A_OV_COM_LW:0,A_OV_COM_TW:16,A_OV_COM_CLIENT:0,A_OV_REM:326,A_OV_PROG:4.67},
    {SUBSYSTEM:"21-9500-9534-02",SUBSYSTEM_DESCR:"Structured Cabling System - LQ Levels 1,2 and 3",SUBSYSTEM_STATUS:"Subsystems with Telecoms and Telecoms Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2021-12-02",A_OV_TOT:307,A_OV_COM:63,A_OV_COM_LW:35,A_OV_COM_TW:6,A_OV_COM_CLIENT:0,A_OV_REM:244,A_OV_PROG:20.52},
    {SUBSYSTEM:"21-9500-9534-03",SUBSYSTEM_DESCR:"Structured Cabling System - LQ Levels 4 and 5",SUBSYSTEM_STATUS:"Subsystems with Telecoms and Telecoms Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2021-12-02",A_OV_TOT:249,A_OV_COM:9,A_OV_COM_LW:0,A_OV_COM_TW:2,A_OV_COM_CLIENT:0,A_OV_REM:240,A_OV_PROG:3.61},
    {SUBSYSTEM:"21-9500-9534-04",SUBSYSTEM_DESCR:"Structured Cabling System - Machinery Space",SUBSYSTEM_STATUS:"Subsystems with Telecoms and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2021-12-02",A_OV_TOT:20,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:20,A_OV_PROG:0},
    {SUBSYSTEM:"21-9500-9534-05",SUBSYSTEM_DESCR:"Structured Cabling System - ME05",SUBSYSTEM_STATUS:"Subsystems with Telecoms and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2021-12-16",A_OV_TOT:18,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:18,A_OV_PROG:0},
    {SUBSYSTEM:"21-9500-9535-01",SUBSYSTEM_DESCR:"FPSO Fibre Optic Cable",SUBSYSTEM_STATUS:"Subsystems with Telecoms and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2021-12-02",A_OV_TOT:11,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:11,A_OV_PROG:0,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0},
    {SUBSYSTEM:"21-9500-9536-01",SUBSYSTEM_DESCR:"LANandWAN ,Data Network Infrastructure",SUBSYSTEM_STATUS:"Subsystems with Telecoms and Telecoms Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2021-12-23",A_OV_TOT:537,A_OV_COM:119,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:85,A_OV_REM:418,A_OV_PROG:22.16,B_OV_TOT:45,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:45,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:0,PB1_OV_TOT:8,PB1_OV_REM:2},
    {SUBSYSTEM:"21-9500-9539-01",SUBSYSTEM_DESCR:"Video Conferencing and Collaboration System",SUBSYSTEM_STATUS:"Subsystems with Telecoms and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-01-06",A_OV_TOT:75,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:75,A_OV_PROG:0,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0},
    {SUBSYSTEM:"21-9500-9544-01",SUBSYSTEM_DESCR:"Marine and Security CCTV - LQ",SUBSYSTEM_STATUS:"Subsystems with Telecoms and Telecoms Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2021-12-16",A_OV_TOT:162,A_OV_COM:23,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:139,A_OV_PROG:14.19,B_OV_TOT:11,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:11,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:0,PB1_OV_TOT:7,PB1_OV_REM:0},
    {SUBSYSTEM:"21-9500-9544-02",SUBSYSTEM_DESCR:"Marine and Security CCTV - Topside HULL Deck",SUBSYSTEM_STATUS:"Subsystems with Telecoms and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-03-10",A_OV_TOT:212,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:212,A_OV_PROG:0},
    {SUBSYSTEM:"21-9500-9554-01",SUBSYSTEM_DESCR:"Meterological METOCEAN System",SUBSYSTEM_STATUS:"Subsystems with Telecoms and Telecoms Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2021-12-16",A_OV_TOT:62,A_OV_COM:2,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:60,A_OV_PROG:3.22,B_OV_TOT:8,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:8,B_OV_PROG:0},
    {SUBSYSTEM:"21-9500-9555-01",SUBSYSTEM_DESCR:"Berthing Aids and Approach System",SUBSYSTEM_STATUS:"Subsystems with Telecoms and Telecoms Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2021-12-16",A_OV_TOT:53,A_OV_COM:2,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:51,A_OV_PROG:3.77,B_OV_TOT:6,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:6,B_OV_PROG:0,PB1_OV_TOT:2,PB1_OV_REM:2},
    {SUBSYSTEM:"21-9500-9557-01",SUBSYSTEM_DESCR:"Global Maritime Distress and Safety System",SUBSYSTEM_STATUS:"Subsystems with Telecoms and Telecoms Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-02-24",A_OV_TOT:64,A_OV_COM:2,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:62,A_OV_PROG:3.12,B_OV_TOT:5,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:5,B_OV_PROG:0,PB1_OV_TOT:2,PB1_OV_REM:0},
    {SUBSYSTEM:"21-9500-9564-01",SUBSYSTEM_DESCR:"Access Control System and Emergency Mustering System",SUBSYSTEM_STATUS:"Subsystems with Telecoms and Telecoms Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2021-12-16",A_OV_TOT:612,A_OV_COM:100,A_OV_COM_LW:47,A_OV_COM_TW:13,A_OV_COM_CLIENT:27,A_OV_REM:512,A_OV_PROG:16.33,B_OV_TOT:14,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:14,B_OV_PROG:0,PA_OV_TOT:8,PA_OV_REM:4,PB1_OV_TOT:14,PB1_OV_REM:5},
    {SUBSYSTEM:"21-9500-9565-01",SUBSYSTEM_DESCR:"Ship Security Alert System ,SSAS",SUBSYSTEM_STATUS:"Subsystems with Telecoms and Telecoms Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-01-06",A_OV_TOT:14,A_OV_COM:3,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:11,A_OV_PROG:21.42,B_OV_TOT:4,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:4,B_OV_PROG:0},
    {SUBSYSTEM:"21-9500-9570-01",SUBSYSTEM_DESCR:"Gyrocompass",SUBSYSTEM_STATUS:"Subsystems with Telecoms and Telecoms Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-01-13",A_OV_TOT:13,A_OV_COM:2,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:11,A_OV_PROG:15.38,B_OV_TOT:4,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:4,B_OV_PROG:0},
    {SUBSYSTEM:"21-9500-9570-02",SUBSYSTEM_DESCR:"D-GPS System",SUBSYSTEM_STATUS:"Subsystems with Telecoms and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-01-13",A_OV_TOT:10,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:10,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0},
    {SUBSYSTEM:"21-9500-9570-03",SUBSYSTEM_DESCR:"Hand held satellite phone",SUBSYSTEM_STATUS:"Subsystems with Telecoms and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-01-06",A_OV_TOT:10,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:10,A_OV_PROG:0,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0},
    {SUBSYSTEM:"21-9500-9570-04",SUBSYSTEM_DESCR:"Video Wall System",SUBSYSTEM_STATUS:"Subsystems with Telecoms and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-01-13",A_OV_TOT:39,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:39,A_OV_PROG:0,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0},
    {SUBSYSTEM:"21-9500-9574-01",SUBSYSTEM_DESCR:"Network Management System",SUBSYSTEM_STATUS:"Subsystems with Telecoms and Telecoms Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-01-13",A_OV_TOT:11,A_OV_COM:1,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:10,A_OV_PROG:9.09,B_OV_TOT:31,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:31,B_OV_PROG:0},
    {SUBSYSTEM:"21-9500-9575-01",SUBSYSTEM_DESCR:"Digital Voice Recorder",SUBSYSTEM_STATUS:"Subsystems with Telecoms and Telecoms Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-01-13",A_OV_TOT:15,A_OV_COM:1,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:14,A_OV_PROG:6.66,B_OV_TOT:4,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:4,B_OV_PROG:0}
],
  Queries: {
    SQL1: [
      { DESC: 'DataSource SPCS Data 03-Mar-20' }
    ],
    SQL2: [
      { CDATE: '2021-10-21' }
    ],
    SQL3: [
      {MC_PLAN:"2021-12-02",A_T:980,A_A:92,A_O:888,B_T:21,B_A:0,B_O:21},
      {MC_PLAN:"2021-12-09"},
      {MC_PLAN:"2021-12-16",A_T:1704,A_A:137,A_O:1567,B_T:53,B_A:0,B_O:53},
      {MC_PLAN:"2021-12-23",A_T:537,A_A:119,A_O:418,B_T:45,B_A:0,B_O:45},
      {MC_PLAN:"2021-12-30",A_T:380,A_A:10,A_O:370,B_T:14,B_A:0,B_O:14},
      {MC_PLAN:"2022-01-06",A_T:737,A_A:623,A_O:114,B_T:12,B_A:0,B_O:12},
      {MC_PLAN:"2022-01-13",A_T:348,A_A:11,A_O:337,B_T:63,B_A:0,B_O:63},
      {MC_PLAN:"2022-01-20"},
      {MC_PLAN:"2022-01-27",A_T:1700,A_A:1348,A_O:352},
      {MC_PLAN:"2022-02-03"},
      {MC_PLAN:"2022-02-10"},
      {MC_PLAN:"2022-02-17",A_T:624,A_A:468,A_O:156},
      {MC_PLAN:"2022-02-24",A_T:64,A_A:2,A_O:62,B_T:5,B_A:0,B_O:5},
      {MC_PLAN:"2022-03-03"},
      {MC_PLAN:"2022-03-10",A_T:212,A_A:0,A_O:212},
      {MC_PLAN:"2022-03-17"},
      {MC_PLAN:"2022-03-24"},
      {MC_PLAN:"2022-03-31"},
      {MC_PLAN:"2022-04-07"},
      {MC_PLAN:"2022-04-14",A_T:366,A_A:0,A_O:366},
      {MC_PLAN:"2022-04-21",A_T:775,A_A:0,A_O:775,B_T:1,B_A:0,B_O:1},
      {MC_PLAN:"2022-04-28"},
      {MC_PLAN:"2022-05-05"},
      {MC_PLAN:"2022-05-12"},
      {MC_PLAN:"2022-05-19",A_T:1736,A_A:0,A_O:1736},
      {MC_PLAN:"2022-05-26",A_T:594,A_A:331,A_O:263},
      {MC_PLAN:"2022-06-02",A_T:771,A_A:0,A_O:771},
      {MC_PLAN:"2022-06-09"}
    ],
    SQL4: [
      {CUTOFF:"2018-06-21",WK_NO:"W2018_25"},
      {CUTOFF:"2018-06-28",WK_NO:"W2018_26"},
      {CUTOFF:"2018-07-05",WK_NO:"W2018_27"},
      {CUTOFF:"2018-07-12",WK_NO:"W2018_28"},
      {CUTOFF:"2018-07-19",WK_NO:"W2018_29"},
      {CUTOFF:"2018-07-26",WK_NO:"W2018_30"},
      {CUTOFF:"2018-08-02",WK_NO:"W2018_31"},
      {CUTOFF:"2018-08-09",WK_NO:"W2018_32"},
      {CUTOFF:"2018-08-16",WK_NO:"W2018_33"},
      {CUTOFF:"2018-08-23",WK_NO:"W2018_34"},
      {CUTOFF:"2018-08-30",WK_NO:"W2018_35"},
      {CUTOFF:"2018-09-06",WK_NO:"W2018_36"},
      {CUTOFF:"2018-09-13",WK_NO:"W2018_37"},
      {CUTOFF:"2018-09-20",WK_NO:"W2018_38"},
      {CUTOFF:"2018-09-27",WK_NO:"W2018_39"},
      {CUTOFF:"2018-10-04",WK_NO:"W2018_40"},
      {CUTOFF:"2018-10-11",WK_NO:"W2018_41"},
      {CUTOFF:"2018-10-18",WK_NO:"W2018_42"},
      {CUTOFF:"2018-10-25",WK_NO:"W2018_43"},
      {CUTOFF:"2018-11-01",WK_NO:"W2018_44"},
      {CUTOFF:"2018-11-08",WK_NO:"W2018_45"},
      {CUTOFF:"2018-11-15",WK_NO:"W2018_46"},
      {CUTOFF:"2018-11-22",WK_NO:"W2018_47"},
      {CUTOFF:"2018-11-29",WK_NO:"W2018_48"},
      {CUTOFF:"2018-12-06",WK_NO:"W2018_49"},
      {CUTOFF:"2018-12-13",WK_NO:"W2018_50"},
      {CUTOFF:"2018-12-20",WK_NO:"W2018_51"},
      {CUTOFF:"2018-12-27",WK_NO:"W2018_52"},
      {CUTOFF:"2019-01-03",WK_NO:"W2019_01"},
      {CUTOFF:"2019-01-10",WK_NO:"W2019_02"},
      {CUTOFF:"2019-01-17",WK_NO:"W2019_03"},
      {CUTOFF:"2019-01-24",WK_NO:"W2019_04"},
      {CUTOFF:"2019-01-31",WK_NO:"W2019_05"},
      {CUTOFF:"2019-02-07",WK_NO:"W2019_06"},
      {CUTOFF:"2019-02-14",WK_NO:"W2019_07"},
      {CUTOFF:"2019-02-21",WK_NO:"W2019_08"},
      {CUTOFF:"2019-02-28",WK_NO:"W2019_09"},
      {CUTOFF:"2019-03-07",WK_NO:"W2019_10"},
      {CUTOFF:"2019-03-14",WK_NO:"W2019_11"},
      {CUTOFF:"2019-03-21",WK_NO:"W2019_12"},
      {CUTOFF:"2019-03-28",WK_NO:"W2019_13"},
      {CUTOFF:"2019-04-04",WK_NO:"W2019_14"},
      {CUTOFF:"2019-04-11",WK_NO:"W2019_15"},
      {CUTOFF:"2019-04-18",WK_NO:"W2019_16"},
      {CUTOFF:"2019-04-25",WK_NO:"W2019_17"},
      {CUTOFF:"2019-05-02",WK_NO:"W2019_18"},
      {CUTOFF:"2019-05-09",WK_NO:"W2019_19"},
      {CUTOFF:"2019-05-16",WK_NO:"W2019_20"},
      {CUTOFF:"2019-05-23",WK_NO:"W2019_21"},
      {CUTOFF:"2019-05-30",WK_NO:"W2019_22"},
      {CUTOFF:"2019-06-06",WK_NO:"W2019_23"},
      {CUTOFF:"2019-06-13",WK_NO:"W2019_24"},
      {CUTOFF:"2019-06-20",WK_NO:"W2019_25"},
      {CUTOFF:"2019-06-27",WK_NO:"W2019_26"},
      {CUTOFF:"2019-07-04",WK_NO:"W2019_27"},
      {CUTOFF:"2019-07-11",WK_NO:"W2019_28"},
      {CUTOFF:"2019-07-18",WK_NO:"W2019_29"},
      {CUTOFF:"2019-07-25",WK_NO:"W2019_30"},
      {CUTOFF:"2019-08-01",WK_NO:"W2019_31"},
      {CUTOFF:"2019-08-08",WK_NO:"W2019_32"},
      {CUTOFF:"2019-08-15",WK_NO:"W2019_33"},
      {CUTOFF:"2019-08-22",WK_NO:"W2019_34"},
      {CUTOFF:"2019-08-29",WK_NO:"W2019_35"},
      {CUTOFF:"2019-09-05",WK_NO:"W2019_36"},
      {CUTOFF:"2019-09-12",WK_NO:"W2019_37"},
      {CUTOFF:"2019-09-19",WK_NO:"W2019_38"},
      {CUTOFF:"2019-09-26",WK_NO:"W2019_39"},
      {CUTOFF:"2019-10-03",WK_NO:"W2019_40"},
      {CUTOFF:"2019-10-10",WK_NO:"W2019_41"},
      {CUTOFF:"2019-10-17",WK_NO:"W2019_42"},
      {CUTOFF:"2019-10-24",WK_NO:"W2019_43"},
      {CUTOFF:"2019-10-31",WK_NO:"W2019_44"},
      {CUTOFF:"2019-11-07",WK_NO:"W2019_45"},
      {CUTOFF:"2019-11-14",WK_NO:"W2019_46"},
      {CUTOFF:"2019-11-21",WK_NO:"W2019_47"},
      {CUTOFF:"2019-11-28",WK_NO:"W2019_48"},
      {CUTOFF:"2019-12-05",WK_NO:"W2019_49"},
      {CUTOFF:"2019-12-12",WK_NO:"W2019_50"},
      {CUTOFF:"2019-12-19",WK_NO:"W2019_51"},
      {CUTOFF:"2019-12-26",WK_NO:"W2019_52"},
      {CUTOFF:"2020-01-02",WK_NO:"W2020_01"},
      {CUTOFF:"2020-01-09",WK_NO:"W2020_02"},
      {CUTOFF:"2020-01-16",WK_NO:"W2020_03"},
      {CUTOFF:"2020-01-23",WK_NO:"W2020_04"},
      {CUTOFF:"2020-01-30",WK_NO:"W2020_05"},
      {CUTOFF:"2020-02-06",WK_NO:"W2020_06"},
      {CUTOFF:"2020-02-13",WK_NO:"W2020_07"},
      {CUTOFF:"2020-02-20",WK_NO:"W2020_08"},
      {CUTOFF:"2020-02-27",WK_NO:"W2020_09"},
      {CUTOFF:"2020-03-05",WK_NO:"W2020_10"},
      {CUTOFF:"2020-03-12",WK_NO:"W2020_11"},
      {CUTOFF:"2020-03-19",WK_NO:"W2020_12"},
      {CUTOFF:"2020-03-26",WK_NO:"W2020_13"},
      {CUTOFF:"2020-04-02",WK_NO:"W2020_14"},
      {CUTOFF:"2020-04-09",WK_NO:"W2020_15"},
      {CUTOFF:"2020-04-16",WK_NO:"W2020_16"},
      {CUTOFF:"2020-04-23",WK_NO:"W2020_17"},
      {CUTOFF:"2020-04-30",WK_NO:"W2020_18"},
      {CUTOFF:"2020-05-07",WK_NO:"W2020_19"},
      {CUTOFF:"2020-05-14",WK_NO:"W2020_20"},
      {CUTOFF:"2020-05-21",WK_NO:"W2020_21"},
      {CUTOFF:"2020-05-28",WK_NO:"W2020_22"},
      {CUTOFF:"2020-06-04",WK_NO:"W2020_23"},
      {CUTOFF:"2020-06-11",WK_NO:"W2020_24"},
      {CUTOFF:"2020-06-18",WK_NO:"W2020_25"},
      {CUTOFF:"2020-06-25",WK_NO:"W2020_26"},
      {CUTOFF:"2020-07-02",WK_NO:"W2020_27"},
      {CUTOFF:"2020-07-09",WK_NO:"W2020_28"},
      {CUTOFF:"2020-07-16",WK_NO:"W2020_29"},
      {CUTOFF:"2020-07-23",WK_NO:"W2020_30"},
      {CUTOFF:"2020-07-30",WK_NO:"W2020_31"},
      {CUTOFF:"2020-08-06",WK_NO:"W2020_32"},
      {CUTOFF:"2020-08-13",WK_NO:"W2020_33"},
      {CUTOFF:"2020-08-20",WK_NO:"W2020_34"},
      {CUTOFF:"2020-08-27",WK_NO:"W2020_35"},
      {CUTOFF:"2020-09-03",WK_NO:"W2020_36"},
      {CUTOFF:"2020-09-10",WK_NO:"W2020_37"},
      {CUTOFF:"2020-09-17",WK_NO:"W2020_38"},
      {CUTOFF:"2020-09-24",WK_NO:"W2020_39"},
      {CUTOFF:"2020-10-01",WK_NO:"W2020_40"},
      {CUTOFF:"2020-10-08",WK_NO:"W2020_41"},
      {CUTOFF:"2020-10-15",WK_NO:"W2020_42"},
      {CUTOFF:"2020-10-22",WK_NO:"W2020_43"},
      {CUTOFF:"2020-10-29",WK_NO:"W2020_44"},
      {CUTOFF:"2020-11-05",WK_NO:"W2020_45"},
      {CUTOFF:"2020-11-12",WK_NO:"W2020_46"},
      {CUTOFF:"2020-11-19",WK_NO:"W2020_47"},
      {CUTOFF:"2020-11-26",WK_NO:"W2020_48"},
      {CUTOFF:"2020-12-03",WK_NO:"W2020_49"},
      {CUTOFF:"2020-12-10",WK_NO:"W2020_50"},
      {CUTOFF:"2020-12-17",WK_NO:"W2020_51"},
      {CUTOFF:"2020-12-24",WK_NO:"W2020_52"},
      {CUTOFF:"2020-12-31",WK_NO:"W2020_53"},
      {CUTOFF:"2021-01-07",WK_NO:"W2021_01"},
      {CUTOFF:"2021-01-14",WK_NO:"W2021_02"},
      {CUTOFF:"2021-01-21",WK_NO:"W2021_03"},
      {CUTOFF:"2021-01-28",WK_NO:"W2021_04"},
      {CUTOFF:"2021-02-04",WK_NO:"W2021_05"},
      {CUTOFF:"2021-02-11",WK_NO:"W2021_06"},
      {CUTOFF:"2021-02-18",WK_NO:"W2021_07"},
      {CUTOFF:"2021-02-25",WK_NO:"W2021_08"},
      {CUTOFF:"2021-03-04",WK_NO:"W2021_09"},
      {CUTOFF:"2021-03-11",WK_NO:"W2021_10"},
      {CUTOFF:"2021-03-18",WK_NO:"W2021_11"},
      {CUTOFF:"2021-03-25",WK_NO:"W2021_12"},
      {CUTOFF:"2021-04-01",WK_NO:"W2021_13"},
      {CUTOFF:"2021-04-08",WK_NO:"W2021_14"},
      {CUTOFF:"2021-04-15",WK_NO:"W2021_15"},
      {CUTOFF:"2021-04-22",WK_NO:"W2021_16"},
      {CUTOFF:"2021-04-29",WK_NO:"W2021_17"},
      {CUTOFF:"2021-05-06",WK_NO:"W2021_18"},
      {CUTOFF:"2021-05-13",WK_NO:"W2021_19"},
      {CUTOFF:"2021-05-20",WK_NO:"W2021_20"},
      {CUTOFF:"2021-05-27",WK_NO:"W2021_21"},
      {CUTOFF:"2021-06-03",WK_NO:"W2021_22"},
      {CUTOFF:"2021-06-10",WK_NO:"W2021_23"},
      {CUTOFF:"2021-06-17",WK_NO:"W2021_24"},
      {CUTOFF:"2021-06-24",WK_NO:"W2021_25"},
      {CUTOFF:"2021-07-01",WK_NO:"W2021_26"},
      {CUTOFF:"2021-07-08",WK_NO:"W2021_27"},
      {CUTOFF:"2021-07-15",WK_NO:"W2021_28"},
      {CUTOFF:"2021-07-22",WK_NO:"W2021_29"},
      {CUTOFF:"2021-07-29",WK_NO:"W2021_30"},
      {CUTOFF:"2021-08-05",WK_NO:"W2021_31"},
      {CUTOFF:"2021-08-12",WK_NO:"W2021_32"},
      {CUTOFF:"2021-08-19",WK_NO:"W2021_33"},
      {CUTOFF:"2021-08-26",WK_NO:"W2021_34"},
      {CUTOFF:"2021-09-02",WK_NO:"W2021_35"},
      {CUTOFF:"2021-09-09",WK_NO:"W2021_36"},
      {CUTOFF:"2021-09-16",WK_NO:"W2021_37"},
      {CUTOFF:"2021-09-23",WK_NO:"W2021_38"},
      {CUTOFF:"2021-09-30",WK_NO:"W2021_39"},
      {CUTOFF:"2021-10-07",WK_NO:"W2021_40"},
      {CUTOFF:"2021-10-14",WK_NO:"W2021_41"},
      {CUTOFF:"2021-10-21",WK_NO:"W2021_42"},
      {CUTOFF:"2021-10-28",WK_NO:"W2021_43"},
      {CUTOFF:"2021-11-04",WK_NO:"W2021_44"},
      {CUTOFF:"2021-11-11",WK_NO:"W2021_45"},
      {CUTOFF:"2021-11-18",WK_NO:"W2021_46"},
      {CUTOFF:"2021-11-25",WK_NO:"W2021_47"},
      {CUTOFF:"2021-12-02",WK_NO:"W2021_48"},
      {CUTOFF:"2021-12-09",WK_NO:"W2021_49"},
      {CUTOFF:"2021-12-16",WK_NO:"W2021_50"},
      {CUTOFF:"2021-12-23",WK_NO:"W2021_51"},
      {CUTOFF:"2021-12-30",WK_NO:"W2021_52"},
      {CUTOFF:"2022-01-06",WK_NO:"W2022_01"},
      {CUTOFF:"2022-01-13",WK_NO:"W2022_02"},
      {CUTOFF:"2022-01-20",WK_NO:"W2022_03"},
      {CUTOFF:"2022-01-27",WK_NO:"W2022_04"},
      {CUTOFF:"2022-02-03",WK_NO:"W2022_05"},
      {CUTOFF:"2022-02-10",WK_NO:"W2022_06"},
      {CUTOFF:"2022-02-17",WK_NO:"W2022_07"},
      {CUTOFF:"2022-02-24",WK_NO:"W2022_08"},
      {CUTOFF:"2022-03-03",WK_NO:"W2022_09"},
      {CUTOFF:"2022-03-10",WK_NO:"W2022_10"},
      {CUTOFF:"2022-03-17",WK_NO:"W2022_11"},
      {CUTOFF:"2022-03-24",WK_NO:"W2022_12"},
      {CUTOFF:"2022-03-31",WK_NO:"W2022_13"},
      {CUTOFF:"2022-04-07",WK_NO:"W2022_14"},
      {CUTOFF:"2022-04-14",WK_NO:"W2022_15"},
      {CUTOFF:"2022-04-21",WK_NO:"W2022_16"},
      {CUTOFF:"2022-04-28",WK_NO:"W2022_17"},
      {CUTOFF:"2022-05-05",WK_NO:"W2022_18"},
      {CUTOFF:"2022-05-12",WK_NO:"W2022_19"},
      {CUTOFF:"2022-05-19",WK_NO:"W2022_20"},
      {CUTOFF:"2022-05-26",WK_NO:"W2022_21"},
      {CUTOFF:"2022-06-02",WK_NO:"W2022_22"},
      {CUTOFF:"2022-06-09",WK_NO:"W2022_23"},
      {CUTOFF:"2022-06-16",WK_NO:"W2022_24"},
      {CUTOFF:"2022-06-23",WK_NO:"W2022_25"},
      {CUTOFF:"2022-06-30",WK_NO:"W2022_26"},
      {CUTOFF:"2022-07-07",WK_NO:"W2022_27"},
      {CUTOFF:"2022-07-14",WK_NO:"W2022_28"},
      {CUTOFF:"2022-07-21",WK_NO:"W2022_29"},
      {CUTOFF:"2022-07-28",WK_NO:"W2022_30"},
      {CUTOFF:"2022-08-04",WK_NO:"W2022_31"},
      {CUTOFF:"2022-08-11",WK_NO:"W2022_32"},
      {CUTOFF:"2022-08-18",WK_NO:"W2022_33"},
      {CUTOFF:"2022-08-25",WK_NO:"W2022_34"},
      {CUTOFF:"2022-09-01",WK_NO:"W2022_35"},
      {CUTOFF:"2022-09-08",WK_NO:"W2022_36"},
      {CUTOFF:"2022-09-15",WK_NO:"W2022_37"},
      {CUTOFF:"2022-09-22",WK_NO:"W2022_38"},
      {CUTOFF:"2022-09-29",WK_NO:"W2022_39"},
      {CUTOFF:"2022-10-06",WK_NO:"W2022_40"},
      {CUTOFF:"2022-10-13",WK_NO:"W2022_41"},
      {CUTOFF:"2022-10-20",WK_NO:"W2022_42"},
      {CUTOFF:"2022-10-27",WK_NO:"W2022_43"},
      {CUTOFF:"2022-11-03",WK_NO:"W2022_44"},
      {CUTOFF:"2022-11-10",WK_NO:"W2022_45"},
      {CUTOFF:"2022-11-17",WK_NO:"W2022_46"},
      {CUTOFF:"2022-11-24",WK_NO:"W2022_47"},
      {CUTOFF:"2022-12-01",WK_NO:"W2022_48"},
      {CUTOFF:"2022-12-08",WK_NO:"W2022_49"},
      {CUTOFF:"2022-12-15",WK_NO:"W2022_50"},
      {CUTOFF:"2022-12-22",WK_NO:"W2022_51"},
      {CUTOFF:"2022-12-29",WK_NO:"W2022_52"}
    ],
    SQL5: [
      // {TOTAL:334,NOTSTARTED:141,APROGRESS:191,ACOMPLETED:2,BPROGRESS:0,LOOPTEST:0, MCCOMPLETED: 1}
      {TOTAL:46,NOTSTARTED:19,APROGRESS:25,ACOMPLETED:2,PAR_TELECOMS:0,TELECOMS:0}
    ],
    SQL6: [
      // {'Total Loops':1111,'ICFT based on Loop Test Prerequisites':45,'ICFT available based on MC Completed':2,'Incomplete ICFT':1102,'Complete ICFT':9}
      {'Total Telecoms':214,'Telecoms based on Telecoms Prerequisites':0,'Telecoms available based on Partial MC Completed':0,'Telecoms available based on Full MC Completed':0,'Incomplete Telecoms':214,'Complete Telecoms':0}

    ],
    SQL7: [ // week data
      {DATE:"2021-12-02",CUTOFF:"2021-12-02",LAST_WK:0,SAT:0,SUN:0,MON:0,TUE:0,WEN:0,THU:0,FRI:0,THIS_WK:0}
    ]
  }
}