import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 600,
      CanvasHeight: 380,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 380,
      CanvasChartHeight: 280,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Weight : ', x:240, y: 220, font: 'roboto', class: 'regular', size: 11, color: '#BCBCBC', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: 350, y: 200, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'end' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: 350,
          y: 220,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'end'
        },
      ]
    },
    DataItems: [
      {WK_NO:"W2019_25",FAT_PLAN_CUM:0,FAT_PLAN_WK:0,FAT_FORECAST_CUM:0,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:0,FAT_ACTUAL_WK:0},
      {WK_NO:"W2019_26",FAT_PLAN_CUM:0,FAT_PLAN_WK:0,FAT_FORECAST_CUM:0,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:0,FAT_ACTUAL_WK:0},
      {WK_NO:"W2019_27",FAT_PLAN_CUM:1,FAT_PLAN_WK:1,FAT_FORECAST_CUM:1,FAT_FORECAST_WK:1,FAT_ACTUAL_CUM:1,FAT_ACTUAL_WK:1},
      {WK_NO:"W2019_28",FAT_PLAN_CUM:1,FAT_PLAN_WK:0,FAT_FORECAST_CUM:1,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:1,FAT_ACTUAL_WK:0},
      {WK_NO:"W2019_29",FAT_PLAN_CUM:1,FAT_PLAN_WK:0,FAT_FORECAST_CUM:1,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:1,FAT_ACTUAL_WK:0},
      {WK_NO:"W2019_30",FAT_PLAN_CUM:1,FAT_PLAN_WK:0,FAT_FORECAST_CUM:1,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:1,FAT_ACTUAL_WK:0},
      {WK_NO:"W2019_31",FAT_PLAN_CUM:1,FAT_PLAN_WK:0,FAT_FORECAST_CUM:1,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:1,FAT_ACTUAL_WK:0},
      {WK_NO:"W2019_32",FAT_PLAN_CUM:1,FAT_PLAN_WK:0,FAT_FORECAST_CUM:1,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:1,FAT_ACTUAL_WK:0},
      {WK_NO:"W2019_33",FAT_PLAN_CUM:1,FAT_PLAN_WK:0,FAT_FORECAST_CUM:1,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:1,FAT_ACTUAL_WK:0},
      {WK_NO:"W2019_34",FAT_PLAN_CUM:1,FAT_PLAN_WK:0,FAT_FORECAST_CUM:1,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:1,FAT_ACTUAL_WK:0},
      {WK_NO:"W2019_35",FAT_PLAN_CUM:1,FAT_PLAN_WK:0,FAT_FORECAST_CUM:1,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:1,FAT_ACTUAL_WK:0},
      {WK_NO:"W2019_36",FAT_PLAN_CUM:1,FAT_PLAN_WK:0,FAT_FORECAST_CUM:1,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:1,FAT_ACTUAL_WK:0},
      {WK_NO:"W2019_37",FAT_PLAN_CUM:1,FAT_PLAN_WK:0,FAT_FORECAST_CUM:1,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:1,FAT_ACTUAL_WK:0},
      {WK_NO:"W2019_38",FAT_PLAN_CUM:1,FAT_PLAN_WK:0,FAT_FORECAST_CUM:1,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:1,FAT_ACTUAL_WK:0},
      {WK_NO:"W2019_39",FAT_PLAN_CUM:1,FAT_PLAN_WK:0,FAT_FORECAST_CUM:1,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:1,FAT_ACTUAL_WK:0},
      {WK_NO:"W2019_40",FAT_PLAN_CUM:1,FAT_PLAN_WK:0,FAT_FORECAST_CUM:1,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:1,FAT_ACTUAL_WK:0},
      {WK_NO:"W2019_41",FAT_PLAN_CUM:1,FAT_PLAN_WK:0,FAT_FORECAST_CUM:1,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:1,FAT_ACTUAL_WK:0},
      {WK_NO:"W2019_42",FAT_PLAN_CUM:1,FAT_PLAN_WK:0,FAT_FORECAST_CUM:1,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:1,FAT_ACTUAL_WK:0},
      {WK_NO:"W2019_43",FAT_PLAN_CUM:1,FAT_PLAN_WK:0,FAT_FORECAST_CUM:1,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:1,FAT_ACTUAL_WK:0},
      {WK_NO:"W2019_44",FAT_PLAN_CUM:1,FAT_PLAN_WK:0,FAT_FORECAST_CUM:1,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:1,FAT_ACTUAL_WK:0},
      {WK_NO:"W2019_45",FAT_PLAN_CUM:1,FAT_PLAN_WK:0,FAT_FORECAST_CUM:1,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:1,FAT_ACTUAL_WK:0},
      {WK_NO:"W2019_46",FAT_PLAN_CUM:1,FAT_PLAN_WK:0,FAT_FORECAST_CUM:1,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:1,FAT_ACTUAL_WK:0},
      {WK_NO:"W2019_47",FAT_PLAN_CUM:1,FAT_PLAN_WK:0,FAT_FORECAST_CUM:1,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:1,FAT_ACTUAL_WK:0},
      {WK_NO:"W2019_48",FAT_PLAN_CUM:1,FAT_PLAN_WK:0,FAT_FORECAST_CUM:1,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:1,FAT_ACTUAL_WK:0},
      {WK_NO:"W2019_49",FAT_PLAN_CUM:1,FAT_PLAN_WK:0,FAT_FORECAST_CUM:1,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:1,FAT_ACTUAL_WK:0},
      {WK_NO:"W2019_50",FAT_PLAN_CUM:1,FAT_PLAN_WK:0,FAT_FORECAST_CUM:1,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:1,FAT_ACTUAL_WK:0},
      {WK_NO:"W2019_51",FAT_PLAN_CUM:1,FAT_PLAN_WK:0,FAT_FORECAST_CUM:1,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:1,FAT_ACTUAL_WK:0},
      {WK_NO:"W2019_52",FAT_PLAN_CUM:1,FAT_PLAN_WK:0,FAT_FORECAST_CUM:1,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:1,FAT_ACTUAL_WK:0},
      {WK_NO:"W2020_01",FAT_PLAN_CUM:1,FAT_PLAN_WK:0,FAT_FORECAST_CUM:1,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:1,FAT_ACTUAL_WK:0},
      {WK_NO:"W2020_02",FAT_PLAN_CUM:1,FAT_PLAN_WK:0,FAT_FORECAST_CUM:1,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:1,FAT_ACTUAL_WK:0},
      {WK_NO:"W2020_03",FAT_PLAN_CUM:2,FAT_PLAN_WK:1,FAT_FORECAST_CUM:2,FAT_FORECAST_WK:1,FAT_ACTUAL_CUM:2,FAT_ACTUAL_WK:1},
      {WK_NO:"W2020_04",FAT_PLAN_CUM:2,FAT_PLAN_WK:0,FAT_FORECAST_CUM:2,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:2,FAT_ACTUAL_WK:0},
      {WK_NO:"W2020_05",FAT_PLAN_CUM:2,FAT_PLAN_WK:0,FAT_FORECAST_CUM:2,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:2,FAT_ACTUAL_WK:0},
      {WK_NO:"W2020_06",FAT_PLAN_CUM:2,FAT_PLAN_WK:0,FAT_FORECAST_CUM:2,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:2,FAT_ACTUAL_WK:0},
      {WK_NO:"W2020_07",FAT_PLAN_CUM:2,FAT_PLAN_WK:0,FAT_FORECAST_CUM:2,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:2,FAT_ACTUAL_WK:0},
      {WK_NO:"W2020_08",FAT_PLAN_CUM:3,FAT_PLAN_WK:1,FAT_FORECAST_CUM:3,FAT_FORECAST_WK:1,FAT_ACTUAL_CUM:3,FAT_ACTUAL_WK:1},
      {WK_NO:"W2020_09",FAT_PLAN_CUM:3,FAT_PLAN_WK:0,FAT_FORECAST_CUM:3,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:3,FAT_ACTUAL_WK:0},
      {WK_NO:"W2020_10",FAT_PLAN_CUM:3,FAT_PLAN_WK:0,FAT_FORECAST_CUM:3,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:5,FAT_ACTUAL_WK:2},
      {WK_NO:"W2020_11",FAT_PLAN_CUM:3,FAT_PLAN_WK:0,FAT_FORECAST_CUM:3,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:5,FAT_ACTUAL_WK:0},
      {WK_NO:"W2020_12",FAT_PLAN_CUM:3,FAT_PLAN_WK:0,FAT_FORECAST_CUM:3,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:5,FAT_ACTUAL_WK:0},
      {WK_NO:"W2020_13",FAT_PLAN_CUM:3,FAT_PLAN_WK:0,FAT_FORECAST_CUM:3,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:5,FAT_ACTUAL_WK:0},
      {WK_NO:"W2020_14",FAT_PLAN_CUM:5,FAT_PLAN_WK:2,FAT_FORECAST_CUM:5,FAT_FORECAST_WK:2,FAT_ACTUAL_CUM:7,FAT_ACTUAL_WK:2},
      {WK_NO:"W2020_15",FAT_PLAN_CUM:6,FAT_PLAN_WK:1,FAT_FORECAST_CUM:6,FAT_FORECAST_WK:1,FAT_ACTUAL_CUM:8,FAT_ACTUAL_WK:1},
      {WK_NO:"W2020_16",FAT_PLAN_CUM:6,FAT_PLAN_WK:0,FAT_FORECAST_CUM:6,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:8,FAT_ACTUAL_WK:0},
      {WK_NO:"W2020_17",FAT_PLAN_CUM:6,FAT_PLAN_WK:0,FAT_FORECAST_CUM:6,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:8,FAT_ACTUAL_WK:0},
      {WK_NO:"W2020_18",FAT_PLAN_CUM:8,FAT_PLAN_WK:2,FAT_FORECAST_CUM:8,FAT_FORECAST_WK:2,FAT_ACTUAL_CUM:8,FAT_ACTUAL_WK:0},
      {WK_NO:"W2020_19",FAT_PLAN_CUM:8,FAT_PLAN_WK:0,FAT_FORECAST_CUM:8,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:8,FAT_ACTUAL_WK:0},
      {WK_NO:"W2020_20",FAT_PLAN_CUM:8,FAT_PLAN_WK:0,FAT_FORECAST_CUM:8,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:8,FAT_ACTUAL_WK:0},
      {WK_NO:"W2020_21",FAT_PLAN_CUM:8,FAT_PLAN_WK:0,FAT_FORECAST_CUM:8,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:8,FAT_ACTUAL_WK:0},
      {WK_NO:"W2020_22",FAT_PLAN_CUM:8,FAT_PLAN_WK:0,FAT_FORECAST_CUM:8,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:8,FAT_ACTUAL_WK:0},
      {WK_NO:"W2020_23",FAT_PLAN_CUM:9,FAT_PLAN_WK:1,FAT_FORECAST_CUM:9,FAT_FORECAST_WK:1,FAT_ACTUAL_CUM:9,FAT_ACTUAL_WK:1},
      {WK_NO:"W2020_24",FAT_PLAN_CUM:9,FAT_PLAN_WK:0,FAT_FORECAST_CUM:9,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:9,FAT_ACTUAL_WK:0},
      {WK_NO:"W2020_25",FAT_PLAN_CUM:12,FAT_PLAN_WK:3,FAT_FORECAST_CUM:12,FAT_FORECAST_WK:3,FAT_ACTUAL_CUM:12,FAT_ACTUAL_WK:3},
      {WK_NO:"W2020_26",FAT_PLAN_CUM:12,FAT_PLAN_WK:0,FAT_FORECAST_CUM:12,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:12,FAT_ACTUAL_WK:0},
      {WK_NO:"W2020_27",FAT_PLAN_CUM:13,FAT_PLAN_WK:1,FAT_FORECAST_CUM:13,FAT_FORECAST_WK:1,FAT_ACTUAL_CUM:13,FAT_ACTUAL_WK:1},
      {WK_NO:"W2020_28",FAT_PLAN_CUM:13,FAT_PLAN_WK:0,FAT_FORECAST_CUM:13,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:13,FAT_ACTUAL_WK:0},
      {WK_NO:"W2020_29",FAT_PLAN_CUM:13,FAT_PLAN_WK:0,FAT_FORECAST_CUM:13,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:13,FAT_ACTUAL_WK:0},
      {WK_NO:"W2020_30",FAT_PLAN_CUM:15,FAT_PLAN_WK:2,FAT_FORECAST_CUM:15,FAT_FORECAST_WK:2,FAT_ACTUAL_CUM:15,FAT_ACTUAL_WK:2},
      {WK_NO:"W2020_31",FAT_PLAN_CUM:17,FAT_PLAN_WK:2,FAT_FORECAST_CUM:17,FAT_FORECAST_WK:2,FAT_ACTUAL_CUM:17,FAT_ACTUAL_WK:2},
      {WK_NO:"W2020_32",FAT_PLAN_CUM:17,FAT_PLAN_WK:0,FAT_FORECAST_CUM:17,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:17,FAT_ACTUAL_WK:0},
      {WK_NO:"W2020_33",FAT_PLAN_CUM:19,FAT_PLAN_WK:2,FAT_FORECAST_CUM:19,FAT_FORECAST_WK:2,FAT_ACTUAL_CUM:19,FAT_ACTUAL_WK:2},
      {WK_NO:"W2020_34",FAT_PLAN_CUM:20,FAT_PLAN_WK:1,FAT_FORECAST_CUM:20,FAT_FORECAST_WK:1,FAT_ACTUAL_CUM:20,FAT_ACTUAL_WK:1},
      {WK_NO:"W2020_35",FAT_PLAN_CUM:21,FAT_PLAN_WK:1,FAT_FORECAST_CUM:21,FAT_FORECAST_WK:1,FAT_ACTUAL_CUM:21,FAT_ACTUAL_WK:1},
      {WK_NO:"W2020_36",FAT_PLAN_CUM:23,FAT_PLAN_WK:2,FAT_FORECAST_CUM:23,FAT_FORECAST_WK:2,FAT_ACTUAL_CUM:23,FAT_ACTUAL_WK:2},
      {WK_NO:"W2020_37",FAT_PLAN_CUM:24,FAT_PLAN_WK:1,FAT_FORECAST_CUM:24,FAT_FORECAST_WK:1,FAT_ACTUAL_CUM:24,FAT_ACTUAL_WK:1},
      {WK_NO:"W2020_38",FAT_PLAN_CUM:24,FAT_PLAN_WK:0,FAT_FORECAST_CUM:24,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:24,FAT_ACTUAL_WK:0},
      {WK_NO:"W2020_39",FAT_PLAN_CUM:26,FAT_PLAN_WK:2,FAT_FORECAST_CUM:26,FAT_FORECAST_WK:2,FAT_ACTUAL_CUM:26,FAT_ACTUAL_WK:2},
      {WK_NO:"W2020_40",FAT_PLAN_CUM:27,FAT_PLAN_WK:1,FAT_FORECAST_CUM:27,FAT_FORECAST_WK:1,FAT_ACTUAL_CUM:27,FAT_ACTUAL_WK:1},
      {WK_NO:"W2020_41",FAT_PLAN_CUM:27,FAT_PLAN_WK:0,FAT_FORECAST_CUM:27,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:27,FAT_ACTUAL_WK:0},
      {WK_NO:"W2020_42",FAT_PLAN_CUM:30,FAT_PLAN_WK:3,FAT_FORECAST_CUM:31,FAT_FORECAST_WK:4,FAT_ACTUAL_CUM:31,FAT_ACTUAL_WK:4},
      {WK_NO:"W2020_43",FAT_PLAN_CUM:31,FAT_PLAN_WK:1,FAT_FORECAST_CUM:32,FAT_FORECAST_WK:1,FAT_ACTUAL_CUM:32,FAT_ACTUAL_WK:1},
      {WK_NO:"W2020_44",FAT_PLAN_CUM:32,FAT_PLAN_WK:1,FAT_FORECAST_CUM:32,FAT_FORECAST_WK:0,FAT_ACTUAL_CUM:32,FAT_ACTUAL_WK:0},
      {WK_NO:"W2020_45",FAT_PLAN_CUM:32,FAT_PLAN_WK:0,FAT_FORECAST_CUM:32,FAT_FORECAST_WK:0},
      {WK_NO:"W2020_46",FAT_PLAN_CUM:32,FAT_PLAN_WK:0,FAT_FORECAST_CUM:33,FAT_FORECAST_WK:1},
      {WK_NO:"W2020_47",FAT_PLAN_CUM:34,FAT_PLAN_WK:2,FAT_FORECAST_CUM:37,FAT_FORECAST_WK:4},
      {WK_NO:"W2020_48",FAT_PLAN_CUM:35,FAT_PLAN_WK:1,FAT_FORECAST_CUM:38,FAT_FORECAST_WK:1},
      {WK_NO:"W2020_49",FAT_PLAN_CUM:36,FAT_PLAN_WK:1,FAT_FORECAST_CUM:39,FAT_FORECAST_WK:1},
      {WK_NO:"W2020_50",FAT_PLAN_CUM:37,FAT_PLAN_WK:1,FAT_FORECAST_CUM:39,FAT_FORECAST_WK:0},
      {WK_NO:"W2020_51",FAT_PLAN_CUM:38,FAT_PLAN_WK:1,FAT_FORECAST_CUM:40,FAT_FORECAST_WK:1},
      {WK_NO:"W2020_52",FAT_PLAN_CUM:40,FAT_PLAN_WK:2,FAT_FORECAST_CUM:41,FAT_FORECAST_WK:1},
      {WK_NO:"W2021_01",FAT_PLAN_CUM:40,FAT_PLAN_WK:0,FAT_FORECAST_CUM:42,FAT_FORECAST_WK:1},
      {WK_NO:"W2021_02",FAT_PLAN_CUM:40,FAT_PLAN_WK:0,FAT_FORECAST_CUM:42,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_03",FAT_PLAN_CUM:40,FAT_PLAN_WK:0,FAT_FORECAST_CUM:42,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_04",FAT_PLAN_CUM:42,FAT_PLAN_WK:2,FAT_FORECAST_CUM:45,FAT_FORECAST_WK:3},
      {WK_NO:"W2021_05",FAT_PLAN_CUM:42,FAT_PLAN_WK:0,FAT_FORECAST_CUM:45,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_06",FAT_PLAN_CUM:42,FAT_PLAN_WK:0,FAT_FORECAST_CUM:45,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_07",FAT_PLAN_CUM:42,FAT_PLAN_WK:0,FAT_FORECAST_CUM:45,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_08",FAT_PLAN_CUM:44,FAT_PLAN_WK:2,FAT_FORECAST_CUM:47,FAT_FORECAST_WK:2},
      {WK_NO:"W2021_09",FAT_PLAN_CUM:44,FAT_PLAN_WK:0,FAT_FORECAST_CUM:47,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_10",FAT_PLAN_CUM:44,FAT_PLAN_WK:0,FAT_FORECAST_CUM:47,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_11",FAT_PLAN_CUM:45,FAT_PLAN_WK:1,FAT_FORECAST_CUM:48,FAT_FORECAST_WK:1},
      {WK_NO:"W2021_12",FAT_PLAN_CUM:45,FAT_PLAN_WK:0,FAT_FORECAST_CUM:48,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_13",FAT_PLAN_CUM:47,FAT_PLAN_WK:2,FAT_FORECAST_CUM:49,FAT_FORECAST_WK:1},
      {WK_NO:"W2021_14",FAT_PLAN_CUM:48,FAT_PLAN_WK:1,FAT_FORECAST_CUM:49,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_15",FAT_PLAN_CUM:48,FAT_PLAN_WK:0,FAT_FORECAST_CUM:49,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_16",FAT_PLAN_CUM:49,FAT_PLAN_WK:1,FAT_FORECAST_CUM:50,FAT_FORECAST_WK:1},
      {WK_NO:"W2021_17",FAT_PLAN_CUM:50,FAT_PLAN_WK:1,FAT_FORECAST_CUM:50,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_18",FAT_PLAN_CUM:50,FAT_PLAN_WK:0,FAT_FORECAST_CUM:50,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_19",FAT_PLAN_CUM:51,FAT_PLAN_WK:1,FAT_FORECAST_CUM:51,FAT_FORECAST_WK:1},
      {WK_NO:"W2021_20",FAT_PLAN_CUM:51,FAT_PLAN_WK:0,FAT_FORECAST_CUM:51,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_21",FAT_PLAN_CUM:53,FAT_PLAN_WK:2,FAT_FORECAST_CUM:52,FAT_FORECAST_WK:1},
      {WK_NO:"W2021_22",FAT_PLAN_CUM:56,FAT_PLAN_WK:3,FAT_FORECAST_CUM:56,FAT_FORECAST_WK:4},
      {WK_NO:"W2021_23",FAT_PLAN_CUM:58,FAT_PLAN_WK:2,FAT_FORECAST_CUM:58,FAT_FORECAST_WK:2},
      {WK_NO:"W2021_24",FAT_PLAN_CUM:58,FAT_PLAN_WK:0,FAT_FORECAST_CUM:58,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_25",FAT_PLAN_CUM:58,FAT_PLAN_WK:0,FAT_FORECAST_CUM:58,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_26",FAT_PLAN_CUM:58,FAT_PLAN_WK:0,FAT_FORECAST_CUM:58,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_27",FAT_PLAN_CUM:58,FAT_PLAN_WK:0,FAT_FORECAST_CUM:58,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_28",FAT_PLAN_CUM:60,FAT_PLAN_WK:2,FAT_FORECAST_CUM:60,FAT_FORECAST_WK:2},
      {WK_NO:"W2021_29",FAT_PLAN_CUM:60,FAT_PLAN_WK:0,FAT_FORECAST_CUM:60,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_30",FAT_PLAN_CUM:60,FAT_PLAN_WK:0,FAT_FORECAST_CUM:60,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_31",FAT_PLAN_CUM:60,FAT_PLAN_WK:0,FAT_FORECAST_CUM:60,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_32",FAT_PLAN_CUM:60,FAT_PLAN_WK:0,FAT_FORECAST_CUM:60,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_33",FAT_PLAN_CUM:60,FAT_PLAN_WK:0,FAT_FORECAST_CUM:60,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_34",FAT_PLAN_CUM:60,FAT_PLAN_WK:0,FAT_FORECAST_CUM:60,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_35",FAT_PLAN_CUM:60,FAT_PLAN_WK:0,FAT_FORECAST_CUM:60,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_36",FAT_PLAN_CUM:60,FAT_PLAN_WK:0,FAT_FORECAST_CUM:60,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_37",FAT_PLAN_CUM:60,FAT_PLAN_WK:0,FAT_FORECAST_CUM:60,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_38",FAT_PLAN_CUM:60,FAT_PLAN_WK:0,FAT_FORECAST_CUM:60,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_39",FAT_PLAN_CUM:60,FAT_PLAN_WK:0,FAT_FORECAST_CUM:60,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_40",FAT_PLAN_CUM:60,FAT_PLAN_WK:0,FAT_FORECAST_CUM:60,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_41",FAT_PLAN_CUM:60,FAT_PLAN_WK:0,FAT_FORECAST_CUM:60,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_42",FAT_PLAN_CUM:60,FAT_PLAN_WK:0,FAT_FORECAST_CUM:60,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_43",FAT_PLAN_CUM:60,FAT_PLAN_WK:0,FAT_FORECAST_CUM:60,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_44",FAT_PLAN_CUM:60,FAT_PLAN_WK:0,FAT_FORECAST_CUM:60,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_45",FAT_PLAN_CUM:60,FAT_PLAN_WK:0,FAT_FORECAST_CUM:60,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_46",FAT_PLAN_CUM:60,FAT_PLAN_WK:0,FAT_FORECAST_CUM:60,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_47",FAT_PLAN_CUM:62,FAT_PLAN_WK:2,FAT_FORECAST_CUM:62,FAT_FORECAST_WK:2},
      {WK_NO:"W2021_48",FAT_PLAN_CUM:62,FAT_PLAN_WK:0,FAT_FORECAST_CUM:62,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_49",FAT_PLAN_CUM:62,FAT_PLAN_WK:0,FAT_FORECAST_CUM:62,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_50",FAT_PLAN_CUM:62,FAT_PLAN_WK:0,FAT_FORECAST_CUM:62,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_51",FAT_PLAN_CUM:62,FAT_PLAN_WK:0,FAT_FORECAST_CUM:62,FAT_FORECAST_WK:0},
      {WK_NO:"W2021_52",FAT_PLAN_CUM:64,FAT_PLAN_WK:2,FAT_FORECAST_CUM:64,FAT_FORECAST_WK:2},
      {WK_NO:"W2021_53",FAT_PLAN_CUM:64,FAT_PLAN_WK:0,FAT_FORECAST_CUM:64,FAT_FORECAST_WK:0},
      {WK_NO:"W2022_02",FAT_PLAN_CUM:64,FAT_PLAN_WK:0,FAT_FORECAST_CUM:64,FAT_FORECAST_WK:0},
      {WK_NO:"W2022_03",FAT_PLAN_CUM:64,FAT_PLAN_WK:0,FAT_FORECAST_CUM:64,FAT_FORECAST_WK:0},
      {WK_NO:"W2022_04",FAT_PLAN_CUM:64,FAT_PLAN_WK:0,FAT_FORECAST_CUM:64,FAT_FORECAST_WK:0},
      {WK_NO:"W2022_05",FAT_PLAN_CUM:64,FAT_PLAN_WK:0,FAT_FORECAST_CUM:64,FAT_FORECAST_WK:0},
      {WK_NO:"W2022_06",FAT_PLAN_CUM:64,FAT_PLAN_WK:0,FAT_FORECAST_CUM:64,FAT_FORECAST_WK:0},
      {WK_NO:"W2022_07",FAT_PLAN_CUM:64,FAT_PLAN_WK:0,FAT_FORECAST_CUM:64,FAT_FORECAST_WK:0},
      {WK_NO:"W2022_08",FAT_PLAN_CUM:64,FAT_PLAN_WK:0,FAT_FORECAST_CUM:64,FAT_FORECAST_WK:0},
      {WK_NO:"W2022_09",FAT_PLAN_CUM:64,FAT_PLAN_WK:0,FAT_FORECAST_CUM:64,FAT_FORECAST_WK:0},
      {WK_NO:"W2022_10",FAT_PLAN_CUM:64,FAT_PLAN_WK:0,FAT_FORECAST_CUM:64,FAT_FORECAST_WK:0},
      {WK_NO:"W2022_11",FAT_PLAN_CUM:64,FAT_PLAN_WK:0,FAT_FORECAST_CUM:64,FAT_FORECAST_WK:0},
      {WK_NO:"W2022_12",FAT_PLAN_CUM:64,FAT_PLAN_WK:0,FAT_FORECAST_CUM:64,FAT_FORECAST_WK:0},
      {WK_NO:"W2022_13",FAT_PLAN_CUM:64,FAT_PLAN_WK:0,FAT_FORECAST_CUM:64,FAT_FORECAST_WK:0},
      {WK_NO:"W2022_14",FAT_PLAN_CUM:64,FAT_PLAN_WK:0,FAT_FORECAST_CUM:64,FAT_FORECAST_WK:0}
    ],
}