import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 2300,
    CanvasHeight: 980,
    CanvasBorderWeight: 0.05,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.1,

    CanvasChartX: 50,
    CanvasChartY: 50,
    CanvasChartWidth: 480,
    CanvasChartHeight: 250,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  DataItems:   [
    {NO:260,SUBSYSTEM:"21-1001-1001-01",SUBSYSTEM_DESCR:"Annulus Riser vent Monitoring System",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-28",A_OV_TOT:3,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:3,A_OV_PROG:100,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0},
    {NO:261,SUBSYSTEM:"21-1010-1011-01",SUBSYSTEM_DESCR:"Flowline 1 & Receiver",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"A",MC_PLAN:"2022-04-28",A_OV_TOT:193,A_OV_COM:8,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:3,A_OV_REM:185,A_OV_PROG:100,B_OV_TOT:34,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:34,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:2},
    {NO:262,SUBSYSTEM:"21-1010-1012-01",SUBSYSTEM_DESCR:"Flowline 2 & Receiver",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"N",MC_PLAN:"2022-04-28",A_OV_TOT:192,A_OV_COM:8,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:4,A_OV_REM:184,A_OV_PROG:100,B_OV_TOT:34,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:34,B_OV_PROG:0},
    {NO:263,SUBSYSTEM:"21-1510-1510-01",SUBSYSTEM_DESCR:"MEG Riser & Pipeline System to DC1",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-28",A_OV_TOT:9,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:9,A_OV_PROG:100,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0},
    {NO:264,SUBSYSTEM:"21-1600-1611-01",SUBSYSTEM_DESCR:"Gas Export Riser 1",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"N",MC_PLAN:"2022-04-28",A_OV_TOT:39,A_OV_COM:3,A_OV_COM_LW:2,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:36,A_OV_PROG:100,B_OV_TOT:9,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:9,B_OV_PROG:0},
    {NO:265,SUBSYSTEM:"21-1600-1612-01",SUBSYSTEM_DESCR:"Gas Export Riser 2",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"N",MC_PLAN:"2022-04-28",A_OV_TOT:39,A_OV_COM:3,A_OV_COM_LW:2,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:36,A_OV_PROG:100,B_OV_TOT:9,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:9,B_OV_PROG:0},
    {NO:266,SUBSYSTEM:"21-1911-1911-01",SUBSYSTEM_DESCR:"Hydraulic HPUs, RIOs & TUTUs",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"N",MC_PLAN:"2022-04-28",A_OV_TOT:38,A_OV_COM:1,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:37,A_OV_PROG:2.63,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0},
    {NO:233,SUBSYSTEM:"21-1931-1931-01",SUBSYSTEM_DESCR:"Subsea Control System (MCS), SSIV SPCU, EPUs, HVE JBs & LVE JBs",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-17",A_OV_TOT:50,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:50,A_OV_PROG:100,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0,PB1_OV_TOT:11,PB1_OV_REM:11},
    {NO:269,SUBSYSTEM:"21-2200-2131-01",SUBSYSTEM_DESCR:"Slug Catcher 1 & Liquid Heater 1",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"B1",MC_PLAN:"2022-05-05",A_OV_TOT:258,A_OV_COM:24,A_OV_COM_LW:2,A_OV_COM_TW:1,A_OV_COM_CLIENT:2,A_OV_REM:234,A_OV_PROG:9.3,B_OV_TOT:45,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:45,B_OV_PROG:0,PB1_OV_TOT:1,PB1_OV_REM:1},
    {NO:270,SUBSYSTEM:"21-2200-2132-01",SUBSYSTEM_DESCR:"Slug Catcher 2 & Liquid heater 2",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"B1",MC_PLAN:"2022-05-05",A_OV_TOT:261,A_OV_COM:22,A_OV_COM_LW:2,A_OV_COM_TW:2,A_OV_COM_CLIENT:2,A_OV_REM:239,A_OV_PROG:8.42,B_OV_TOT:44,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:44,B_OV_PROG:0,PB1_OV_TOT:1,PB1_OV_REM:1},
    {NO:328,SUBSYSTEM:"21-2200-2221-01",SUBSYSTEM_DESCR:"MP Separator",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"A",MC_PLAN:"2022-07-14",A_OV_TOT:152,A_OV_COM:26,A_OV_COM_LW:7,A_OV_COM_TW:2,A_OV_COM_CLIENT:3,A_OV_REM:126,A_OV_PROG:17.1,B_OV_TOT:32,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:32,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:1},
    {NO:302,SUBSYSTEM:"21-2200-2230-01",SUBSYSTEM_DESCR:"LP Separator",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"A",MC_PLAN:"2022-06-02",A_OV_TOT:118,A_OV_COM:20,A_OV_COM_LW:6,A_OV_COM_TW:0,A_OV_COM_CLIENT:4,A_OV_REM:98,A_OV_PROG:16.94,B_OV_TOT:26,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:26,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:1},
    {NO:320,SUBSYSTEM:"21-2200-2230-02",SUBSYSTEM_DESCR:"Interstage Heaters",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",MC_PLAN:"2022-06-16",A_OV_TOT:20,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:20,A_OV_PROG:0,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0},
    {NO:276,SUBSYSTEM:"21-2200-2240-01",SUBSYSTEM_DESCR:"LLP separator, Condensate Coolers & Condensate Coalescer Pumps",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"A",MC_PLAN:"2022-05-12",A_OV_TOT:210,A_OV_COM:17,A_OV_COM_LW:2,A_OV_COM_TW:5,A_OV_COM_CLIENT:0,A_OV_REM:193,A_OV_PROG:8.09,B_OV_TOT:43,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:43,B_OV_PROG:0,PA_OV_TOT:3,PA_OV_REM:2},
    {NO:277,SUBSYSTEM:"21-2200-2240-03",SUBSYSTEM_DESCR:"Condensate Coalescer Filters",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-12",A_OV_TOT:18,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:18,A_OV_PROG:0,B_OV_TOT:12,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:12,B_OV_PROG:0},
    {NO:278,SUBSYSTEM:"21-2200-2240-04",SUBSYSTEM_DESCR:"Condensate Coalescer Package",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"N",MC_PLAN:"2022-05-12",A_OV_TOT:74,A_OV_COM:2,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:72,A_OV_PROG:2.7,B_OV_TOT:17,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:17,B_OV_PROG:0,PB1_OV_TOT:3,PB1_OV_REM:0},
    {NO:329,SUBSYSTEM:"21-2200-2470-01",SUBSYSTEM_DESCR:"Warm Condensate Heater, Offspec Condensate Pump & Distribution",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"A",MC_PLAN:"2022-07-14",A_OV_TOT:98,A_OV_COM:5,A_OV_COM_LW:2,A_OV_COM_TW:1,A_OV_COM_CLIENT:1,A_OV_REM:93,A_OV_PROG:5.1,B_OV_TOT:43,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:43,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:1,PB1_OV_TOT:1,PB1_OV_REM:1},
    {NO:212,SUBSYSTEM:"21-2400-2450-01",SUBSYSTEM_DESCR:"CFW1 Offspec Condensate Tank & Pumps (Incl Washing Machine)",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"A",MC_PLAN:"2022-02-24",A_OV_TOT:115,A_OV_COM:6,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:109,A_OV_PROG:5.21,B_OV_TOT:23,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:23,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:1},
    {NO:179,SUBSYSTEM:"21-2400-9360-01",SUBSYSTEM_DESCR:"Cargo Oil Washing Header & Distribution",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",MC_PLAN:"2022-01-27",A_OV_TOT:41,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:41,A_OV_PROG:0,B_OV_TOT:8,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:8,B_OV_PROG:0},
    {NO:180,SUBSYSTEM:"21-2400-9360-02",SUBSYSTEM_DESCR:"Condensate Import/Loading Header",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",MC_PLAN:"2022-01-27",A_OV_TOT:53,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:53,A_OV_PROG:0,B_OV_TOT:11,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:11,B_OV_PROG:0},
    {NO:181,SUBSYSTEM:"21-2400-9360-03",SUBSYSTEM_DESCR:"Condensate Transfer Header",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",MC_PLAN:"2022-01-27",A_OV_TOT:238,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:238,A_OV_PROG:0,B_OV_TOT:54,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:54,B_OV_PROG:0},
    {NO:182,SUBSYSTEM:"21-2400-9360-04",SUBSYSTEM_DESCR:"Condensate Export/Offloading Header",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",MC_PLAN:"2022-01-27",A_OV_TOT:111,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:111,A_OV_PROG:0,B_OV_TOT:22,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:22,B_OV_PROG:0},
    {NO:62,SUBSYSTEM:"21-2400-9361-01",SUBSYSTEM_DESCR:"COW1 Tank & Pumps (Incl Washing Machine)",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"N",MC_PLAN:"2021-11-25",A_OV_TOT:52,A_OV_COM:5,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:47,A_OV_PROG:9.61,B_OV_TOT:15,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:15,B_OV_PROG:0},
    {NO:63,SUBSYSTEM:"21-2400-9362-01",SUBSYSTEM_DESCR:"COW2 Tank & Pumps (Incl Washing Machine)",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"N",MC_PLAN:"2021-11-25",A_OV_TOT:52,A_OV_COM:5,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:47,A_OV_PROG:9.61,B_OV_TOT:15,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:15,B_OV_PROG:0},
    {NO:64,SUBSYSTEM:"21-2400-9363-01",SUBSYSTEM_DESCR:"COW3 Tank & Pumps (Incl Washing Machine)",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"N",MC_PLAN:"2021-11-25",A_OV_TOT:51,A_OV_COM:5,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:46,A_OV_PROG:9.8,B_OV_TOT:15,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:15,B_OV_PROG:0},
    {NO:65,SUBSYSTEM:"21-2400-9364-01",SUBSYSTEM_DESCR:"COW4 Tank & Pumps (Incl Washing Machine)",SUBSYSTEM_STATUS:"MC Completed",PUNCH_STATUS:"N",MC_PLAN:"2021-11-25",A_OV_TOT:51,A_OV_COM:5,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:46,A_OV_PROG:100,B_OV_TOT:15,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:0,B_OV_PROG:100},
    {NO:183,SUBSYSTEM:"21-2400-9365-01",SUBSYSTEM_DESCR:"COE1 Tank & Pumps (Incl Washing Machine)",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"N",MC_PLAN:"2022-01-27",A_OV_TOT:51,A_OV_COM:5,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:46,A_OV_PROG:9.8,B_OV_TOT:15,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:15,B_OV_PROG:0},
    {NO:184,SUBSYSTEM:"21-2400-9366-01",SUBSYSTEM_DESCR:"COE2 Tank & Pumps (Incl Washing Machine)",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"N",MC_PLAN:"2022-01-27",A_OV_TOT:50,A_OV_COM:5,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:45,A_OV_PROG:10,B_OV_TOT:15,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:15,B_OV_PROG:0},
    {NO:185,SUBSYSTEM:"21-2400-9367-01",SUBSYSTEM_DESCR:"COE3 Tank & Pumps (Incl Washing Machine)",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"N",MC_PLAN:"2022-01-27",A_OV_TOT:51,A_OV_COM:5,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:46,A_OV_PROG:9.8,B_OV_TOT:15,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:15,B_OV_PROG:0},
    {NO:186,SUBSYSTEM:"21-2400-9368-01",SUBSYSTEM_DESCR:"COE4 Tank & Pumps (Incl Washing Machine)",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"N",MC_PLAN:"2022-01-27",A_OV_TOT:52,A_OV_COM:5,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:47,A_OV_PROG:9.61,B_OV_TOT:15,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:15,B_OV_PROG:0}
    ],
  Queries: {
    SQL1: [
      { DESC: 'DataSource SPCS Data 03-Mar-20' }
    ],
    SQL2: [
      { CDATE: '2021-10-21' }
    ],
    SQL3: [
      {MC_PLAN:"2021-09-23",A_T:13,A_A:12,A_O:1,B_T:14,B_A:0,B_O:14},
      {MC_PLAN:"2021-09-30",A_T:79,A_A:37,A_O:42,B_T:40,B_A:0,B_O:40},
      {MC_PLAN:"2021-10-07"},
      {MC_PLAN:"2021-10-14",A_T:319,A_A:166,A_O:153,B_T:15,B_A:0,B_O:15},
      {MC_PLAN:"2021-10-21",A_T:180,A_A:12,A_O:168,B_T:86,B_A:0,B_O:86},
      {MC_PLAN:"2021-10-28",A_T:422,A_A:2,A_O:420,B_T:82,B_A:0,B_O:82},
      {MC_PLAN:"2021-11-04",A_T:493,A_A:17,A_O:476,B_T:98,B_A:0,B_O:98},
      {MC_PLAN:"2021-11-11",A_T:461,A_A:27,A_O:434,B_T:159,B_A:0,B_O:159},
      {MC_PLAN:"2021-11-18",A_T:452,A_A:42,A_O:410,B_T:122,B_A:0,B_O:122},
      {MC_PLAN:"2021-11-25",A_T:372,A_A:25,A_O:347,B_T:277,B_A:0,B_O:277},
      {MC_PLAN:"2021-12-02",A_T:534,A_A:105,A_O:429,B_T:175,B_A:0,B_O:175},
      {MC_PLAN:"2021-12-09",A_T:1383,A_A:264,A_O:1119,B_T:474,B_A:0,B_O:474},
      {MC_PLAN:"2021-12-16",A_T:2220,A_A:76,A_O:2144,B_T:284,B_A:0,B_O:284},
      {MC_PLAN:"2021-12-23",A_T:1353,A_A:338,A_O:1015,B_T:585,B_A:0,B_O:585},
      {MC_PLAN:"2021-12-30",A_T:314,A_A:45,A_O:269,B_T:95,B_A:0,B_O:95},
      {MC_PLAN:"2022-01-06",A_T:440,A_A:15,A_O:425,B_T:111,B_A:0,B_O:111},
      {MC_PLAN:"2022-01-13",A_T:486,A_A:12,A_O:474,B_T:148,B_A:0,B_O:148},
      {MC_PLAN:"2022-01-20",A_T:703,A_A:93,A_O:610,B_T:207,B_A:0,B_O:207},
      {MC_PLAN:"2022-01-27",A_T:818,A_A:36,A_O:782,B_T:210,B_A:0,B_O:210},
      {MC_PLAN:"2022-02-03"},
      {MC_PLAN:"2022-02-10",A_T:85,A_A:5,A_O:80,B_T:12,B_A:0,B_O:12},
      {MC_PLAN:"2022-02-17",A_T:593,A_A:36,A_O:557,B_T:145,B_A:0,B_O:145},
      {MC_PLAN:"2022-02-24",A_T:1164,A_A:64,A_O:1100,B_T:334,B_A:0,B_O:334},
      {MC_PLAN:"2022-03-03",A_T:268,A_A:4,A_O:264,B_T:48,B_A:0,B_O:48},
      {MC_PLAN:"2022-03-10",A_T:535,A_A:16,A_O:519,B_T:239,B_A:0,B_O:239},
      {MC_PLAN:"2022-03-17",A_T:86,A_A:0,A_O:86,B_T:10,B_A:0,B_O:10},
      {MC_PLAN:"2022-03-24"},
      {MC_PLAN:"2022-03-31",A_T:24,A_A:0,A_O:24,B_T:34,B_A:0,B_O:34},
      {MC_PLAN:"2022-04-07",A_T:14,A_A:6,A_O:8,B_T:6,B_A:0,B_O:6},
      {MC_PLAN:"2022-04-14",A_T:284,A_A:13,A_O:271,B_T:231,B_A:0,B_O:231},
      {MC_PLAN:"2022-04-21",A_T:186,A_A:8,A_O:178,B_T:408,B_A:0,B_O:408},
      {MC_PLAN:"2022-04-28",A_T:660,A_A:29,A_O:631,B_T:204,B_A:0,B_O:204},
      {MC_PLAN:"2022-05-05",A_T:632,A_A:55,A_O:577,B_T:118,B_A:0,B_O:118},
      {MC_PLAN:"2022-05-12",A_T:781,A_A:69,A_O:712,B_T:172,B_A:0,B_O:172},
      {MC_PLAN:"2022-05-19",A_T:527,A_A:29,A_O:498,B_T:138,B_A:0,B_O:138},
      {MC_PLAN:"2022-05-26",A_T:391,A_A:32,A_O:359,B_T:96,B_A:0,B_O:96},
      {MC_PLAN:"2022-06-02",A_T:806,A_A:88,A_O:718,B_T:230,B_A:0,B_O:230},
      {MC_PLAN:"2022-06-09",A_T:209,A_A:17,A_O:192,B_T:118,B_A:0,B_O:118},
      {MC_PLAN:"2022-06-16",A_T:46,A_A:2,A_O:44,B_T:8,B_A:0,B_O:8},
      {MC_PLAN:"2022-06-23",A_T:159,A_A:5,A_O:154,B_T:69,B_A:0,B_O:69},
      {MC_PLAN:"2022-06-30",A_T:67,A_A:5,A_O:62,B_T:19,B_A:0,B_O:19},
      {MC_PLAN:"2022-07-07",A_T:608,A_A:46,A_O:562,B_T:150,B_A:0,B_O:150},
      {MC_PLAN:"2022-07-14",A_T:307,A_A:34,A_O:273,B_T:91,B_A:0,B_O:91},
      {MC_PLAN:"2022-07-21",A_T:23,A_A:2,A_O:21,B_T:10,B_A:0,B_O:10},
      {MC_PLAN:"2022-07-28"},
      {MC_PLAN:"2022-08-04",A_T:102,A_A:15,A_O:87,B_T:25,B_A:0,B_O:25},
      {MC_PLAN:"2022-08-11"}
    ],
    SQL4: [
      {CUTOFF:"2018-06-21",WK_NO:"W2018_25"},
      {CUTOFF:"2018-06-28",WK_NO:"W2018_26"},
      {CUTOFF:"2018-07-05",WK_NO:"W2018_27"},
      {CUTOFF:"2018-07-12",WK_NO:"W2018_28"},
      {CUTOFF:"2018-07-19",WK_NO:"W2018_29"},
      {CUTOFF:"2018-07-26",WK_NO:"W2018_30"},
      {CUTOFF:"2018-08-02",WK_NO:"W2018_31"},
      {CUTOFF:"2018-08-09",WK_NO:"W2018_32"},
      {CUTOFF:"2018-08-16",WK_NO:"W2018_33"},
      {CUTOFF:"2018-08-23",WK_NO:"W2018_34"},
      {CUTOFF:"2018-08-30",WK_NO:"W2018_35"},
      {CUTOFF:"2018-09-06",WK_NO:"W2018_36"},
      {CUTOFF:"2018-09-13",WK_NO:"W2018_37"},
      {CUTOFF:"2018-09-20",WK_NO:"W2018_38"},
      {CUTOFF:"2018-09-27",WK_NO:"W2018_39"},
      {CUTOFF:"2018-10-04",WK_NO:"W2018_40"},
      {CUTOFF:"2018-10-11",WK_NO:"W2018_41"},
      {CUTOFF:"2018-10-18",WK_NO:"W2018_42"},
      {CUTOFF:"2018-10-25",WK_NO:"W2018_43"},
      {CUTOFF:"2018-11-01",WK_NO:"W2018_44"},
      {CUTOFF:"2018-11-08",WK_NO:"W2018_45"},
      {CUTOFF:"2018-11-15",WK_NO:"W2018_46"},
      {CUTOFF:"2018-11-22",WK_NO:"W2018_47"},
      {CUTOFF:"2018-11-29",WK_NO:"W2018_48"},
      {CUTOFF:"2018-12-06",WK_NO:"W2018_49"},
      {CUTOFF:"2018-12-13",WK_NO:"W2018_50"},
      {CUTOFF:"2018-12-20",WK_NO:"W2018_51"},
      {CUTOFF:"2018-12-27",WK_NO:"W2018_52"},
      {CUTOFF:"2019-01-03",WK_NO:"W2019_01"},
      {CUTOFF:"2019-01-10",WK_NO:"W2019_02"},
      {CUTOFF:"2019-01-17",WK_NO:"W2019_03"},
      {CUTOFF:"2019-01-24",WK_NO:"W2019_04"},
      {CUTOFF:"2019-01-31",WK_NO:"W2019_05"},
      {CUTOFF:"2019-02-07",WK_NO:"W2019_06"},
      {CUTOFF:"2019-02-14",WK_NO:"W2019_07"},
      {CUTOFF:"2019-02-21",WK_NO:"W2019_08"},
      {CUTOFF:"2019-02-28",WK_NO:"W2019_09"},
      {CUTOFF:"2019-03-07",WK_NO:"W2019_10"},
      {CUTOFF:"2019-03-14",WK_NO:"W2019_11"},
      {CUTOFF:"2019-03-21",WK_NO:"W2019_12"},
      {CUTOFF:"2019-03-28",WK_NO:"W2019_13"},
      {CUTOFF:"2019-04-04",WK_NO:"W2019_14"},
      {CUTOFF:"2019-04-11",WK_NO:"W2019_15"},
      {CUTOFF:"2019-04-18",WK_NO:"W2019_16"},
      {CUTOFF:"2019-04-25",WK_NO:"W2019_17"},
      {CUTOFF:"2019-05-02",WK_NO:"W2019_18"},
      {CUTOFF:"2019-05-09",WK_NO:"W2019_19"},
      {CUTOFF:"2019-05-16",WK_NO:"W2019_20"},
      {CUTOFF:"2019-05-23",WK_NO:"W2019_21"},
      {CUTOFF:"2019-05-30",WK_NO:"W2019_22"},
      {CUTOFF:"2019-06-06",WK_NO:"W2019_23"},
      {CUTOFF:"2019-06-13",WK_NO:"W2019_24"},
      {CUTOFF:"2019-06-20",WK_NO:"W2019_25"},
      {CUTOFF:"2019-06-27",WK_NO:"W2019_26"},
      {CUTOFF:"2019-07-04",WK_NO:"W2019_27"},
      {CUTOFF:"2019-07-11",WK_NO:"W2019_28"},
      {CUTOFF:"2019-07-18",WK_NO:"W2019_29"},
      {CUTOFF:"2019-07-25",WK_NO:"W2019_30"},
      {CUTOFF:"2019-08-01",WK_NO:"W2019_31"},
      {CUTOFF:"2019-08-08",WK_NO:"W2019_32"},
      {CUTOFF:"2019-08-15",WK_NO:"W2019_33"},
      {CUTOFF:"2019-08-22",WK_NO:"W2019_34"},
      {CUTOFF:"2019-08-29",WK_NO:"W2019_35"},
      {CUTOFF:"2019-09-05",WK_NO:"W2019_36"},
      {CUTOFF:"2019-09-12",WK_NO:"W2019_37"},
      {CUTOFF:"2019-09-19",WK_NO:"W2019_38"},
      {CUTOFF:"2019-09-26",WK_NO:"W2019_39"},
      {CUTOFF:"2019-10-03",WK_NO:"W2019_40"},
      {CUTOFF:"2019-10-10",WK_NO:"W2019_41"},
      {CUTOFF:"2019-10-17",WK_NO:"W2019_42"},
      {CUTOFF:"2019-10-24",WK_NO:"W2019_43"},
      {CUTOFF:"2019-10-31",WK_NO:"W2019_44"},
      {CUTOFF:"2019-11-07",WK_NO:"W2019_45"},
      {CUTOFF:"2019-11-14",WK_NO:"W2019_46"},
      {CUTOFF:"2019-11-21",WK_NO:"W2019_47"},
      {CUTOFF:"2019-11-28",WK_NO:"W2019_48"},
      {CUTOFF:"2019-12-05",WK_NO:"W2019_49"},
      {CUTOFF:"2019-12-12",WK_NO:"W2019_50"},
      {CUTOFF:"2019-12-19",WK_NO:"W2019_51"},
      {CUTOFF:"2019-12-26",WK_NO:"W2019_52"},
      {CUTOFF:"2020-01-02",WK_NO:"W2020_01"},
      {CUTOFF:"2020-01-09",WK_NO:"W2020_02"},
      {CUTOFF:"2020-01-16",WK_NO:"W2020_03"},
      {CUTOFF:"2020-01-23",WK_NO:"W2020_04"},
      {CUTOFF:"2020-01-30",WK_NO:"W2020_05"},
      {CUTOFF:"2020-02-06",WK_NO:"W2020_06"},
      {CUTOFF:"2020-02-13",WK_NO:"W2020_07"},
      {CUTOFF:"2020-02-20",WK_NO:"W2020_08"},
      {CUTOFF:"2020-02-27",WK_NO:"W2020_09"},
      {CUTOFF:"2020-03-05",WK_NO:"W2020_10"},
      {CUTOFF:"2020-03-12",WK_NO:"W2020_11"},
      {CUTOFF:"2020-03-19",WK_NO:"W2020_12"},
      {CUTOFF:"2020-03-26",WK_NO:"W2020_13"},
      {CUTOFF:"2020-04-02",WK_NO:"W2020_14"},
      {CUTOFF:"2020-04-09",WK_NO:"W2020_15"},
      {CUTOFF:"2020-04-16",WK_NO:"W2020_16"},
      {CUTOFF:"2020-04-23",WK_NO:"W2020_17"},
      {CUTOFF:"2020-04-30",WK_NO:"W2020_18"},
      {CUTOFF:"2020-05-07",WK_NO:"W2020_19"},
      {CUTOFF:"2020-05-14",WK_NO:"W2020_20"},
      {CUTOFF:"2020-05-21",WK_NO:"W2020_21"},
      {CUTOFF:"2020-05-28",WK_NO:"W2020_22"},
      {CUTOFF:"2020-06-04",WK_NO:"W2020_23"},
      {CUTOFF:"2020-06-11",WK_NO:"W2020_24"},
      {CUTOFF:"2020-06-18",WK_NO:"W2020_25"},
      {CUTOFF:"2020-06-25",WK_NO:"W2020_26"},
      {CUTOFF:"2020-07-02",WK_NO:"W2020_27"},
      {CUTOFF:"2020-07-09",WK_NO:"W2020_28"},
      {CUTOFF:"2020-07-16",WK_NO:"W2020_29"},
      {CUTOFF:"2020-07-23",WK_NO:"W2020_30"},
      {CUTOFF:"2020-07-30",WK_NO:"W2020_31"},
      {CUTOFF:"2020-08-06",WK_NO:"W2020_32"},
      {CUTOFF:"2020-08-13",WK_NO:"W2020_33"},
      {CUTOFF:"2020-08-20",WK_NO:"W2020_34"},
      {CUTOFF:"2020-08-27",WK_NO:"W2020_35"},
      {CUTOFF:"2020-09-03",WK_NO:"W2020_36"},
      {CUTOFF:"2020-09-10",WK_NO:"W2020_37"},
      {CUTOFF:"2020-09-17",WK_NO:"W2020_38"},
      {CUTOFF:"2020-09-24",WK_NO:"W2020_39"},
      {CUTOFF:"2020-10-01",WK_NO:"W2020_40"},
      {CUTOFF:"2020-10-08",WK_NO:"W2020_41"},
      {CUTOFF:"2020-10-15",WK_NO:"W2020_42"},
      {CUTOFF:"2020-10-22",WK_NO:"W2020_43"},
      {CUTOFF:"2020-10-29",WK_NO:"W2020_44"},
      {CUTOFF:"2020-11-05",WK_NO:"W2020_45"},
      {CUTOFF:"2020-11-12",WK_NO:"W2020_46"},
      {CUTOFF:"2020-11-19",WK_NO:"W2020_47"},
      {CUTOFF:"2020-11-26",WK_NO:"W2020_48"},
      {CUTOFF:"2020-12-03",WK_NO:"W2020_49"},
      {CUTOFF:"2020-12-10",WK_NO:"W2020_50"},
      {CUTOFF:"2020-12-17",WK_NO:"W2020_51"},
      {CUTOFF:"2020-12-24",WK_NO:"W2020_52"},
      {CUTOFF:"2020-12-31",WK_NO:"W2020_53"},
      {CUTOFF:"2021-01-07",WK_NO:"W2021_01"},
      {CUTOFF:"2021-01-14",WK_NO:"W2021_02"},
      {CUTOFF:"2021-01-21",WK_NO:"W2021_03"},
      {CUTOFF:"2021-01-28",WK_NO:"W2021_04"},
      {CUTOFF:"2021-02-04",WK_NO:"W2021_05"},
      {CUTOFF:"2021-02-11",WK_NO:"W2021_06"},
      {CUTOFF:"2021-02-18",WK_NO:"W2021_07"},
      {CUTOFF:"2021-02-25",WK_NO:"W2021_08"},
      {CUTOFF:"2021-03-04",WK_NO:"W2021_09"},
      {CUTOFF:"2021-03-11",WK_NO:"W2021_10"},
      {CUTOFF:"2021-03-18",WK_NO:"W2021_11"},
      {CUTOFF:"2021-03-25",WK_NO:"W2021_12"},
      {CUTOFF:"2021-04-01",WK_NO:"W2021_13"},
      {CUTOFF:"2021-04-08",WK_NO:"W2021_14"},
      {CUTOFF:"2021-04-15",WK_NO:"W2021_15"},
      {CUTOFF:"2021-04-22",WK_NO:"W2021_16"},
      {CUTOFF:"2021-04-29",WK_NO:"W2021_17"},
      {CUTOFF:"2021-05-06",WK_NO:"W2021_18"},
      {CUTOFF:"2021-05-13",WK_NO:"W2021_19"},
      {CUTOFF:"2021-05-20",WK_NO:"W2021_20"},
      {CUTOFF:"2021-05-27",WK_NO:"W2021_21"},
      {CUTOFF:"2021-06-03",WK_NO:"W2021_22"},
      {CUTOFF:"2021-06-10",WK_NO:"W2021_23"},
      {CUTOFF:"2021-06-17",WK_NO:"W2021_24"},
      {CUTOFF:"2021-06-24",WK_NO:"W2021_25"},
      {CUTOFF:"2021-07-01",WK_NO:"W2021_26"},
      {CUTOFF:"2021-07-08",WK_NO:"W2021_27"},
      {CUTOFF:"2021-07-15",WK_NO:"W2021_28"},
      {CUTOFF:"2021-07-22",WK_NO:"W2021_29"},
      {CUTOFF:"2021-07-29",WK_NO:"W2021_30"},
      {CUTOFF:"2021-08-05",WK_NO:"W2021_31"},
      {CUTOFF:"2021-08-12",WK_NO:"W2021_32"},
      {CUTOFF:"2021-08-19",WK_NO:"W2021_33"},
      {CUTOFF:"2021-08-26",WK_NO:"W2021_34"},
      {CUTOFF:"2021-09-02",WK_NO:"W2021_35"},
      {CUTOFF:"2021-09-09",WK_NO:"W2021_36"},
      {CUTOFF:"2021-09-16",WK_NO:"W2021_37"},
      {CUTOFF:"2021-09-23",WK_NO:"W2021_38"},
      {CUTOFF:"2021-09-30",WK_NO:"W2021_39"},
      {CUTOFF:"2021-10-07",WK_NO:"W2021_40"},
      {CUTOFF:"2021-10-14",WK_NO:"W2021_41"},
      {CUTOFF:"2021-10-21",WK_NO:"W2021_42"},
      {CUTOFF:"2021-10-28",WK_NO:"W2021_43"},
      {CUTOFF:"2021-11-04",WK_NO:"W2021_44"},
      {CUTOFF:"2021-11-11",WK_NO:"W2021_45"},
      {CUTOFF:"2021-11-18",WK_NO:"W2021_46"},
      {CUTOFF:"2021-11-25",WK_NO:"W2021_47"},
      {CUTOFF:"2021-12-02",WK_NO:"W2021_48"},
      {CUTOFF:"2021-12-09",WK_NO:"W2021_49"},
      {CUTOFF:"2021-12-16",WK_NO:"W2021_50"},
      {CUTOFF:"2021-12-23",WK_NO:"W2021_51"},
      {CUTOFF:"2021-12-30",WK_NO:"W2021_52"},
      {CUTOFF:"2022-01-06",WK_NO:"W2022_01"},
      {CUTOFF:"2022-01-13",WK_NO:"W2022_02"},
      {CUTOFF:"2022-01-20",WK_NO:"W2022_03"},
      {CUTOFF:"2022-01-27",WK_NO:"W2022_04"},
      {CUTOFF:"2022-02-03",WK_NO:"W2022_05"},
      {CUTOFF:"2022-02-10",WK_NO:"W2022_06"},
      {CUTOFF:"2022-02-17",WK_NO:"W2022_07"},
      {CUTOFF:"2022-02-24",WK_NO:"W2022_08"},
      {CUTOFF:"2022-03-03",WK_NO:"W2022_09"},
      {CUTOFF:"2022-03-10",WK_NO:"W2022_10"},
      {CUTOFF:"2022-03-17",WK_NO:"W2022_11"},
      {CUTOFF:"2022-03-24",WK_NO:"W2022_12"},
      {CUTOFF:"2022-03-31",WK_NO:"W2022_13"},
      {CUTOFF:"2022-04-07",WK_NO:"W2022_14"},
      {CUTOFF:"2022-04-14",WK_NO:"W2022_15"},
      {CUTOFF:"2022-04-21",WK_NO:"W2022_16"},
      {CUTOFF:"2022-04-28",WK_NO:"W2022_17"},
      {CUTOFF:"2022-05-05",WK_NO:"W2022_18"},
      {CUTOFF:"2022-05-12",WK_NO:"W2022_19"},
      {CUTOFF:"2022-05-19",WK_NO:"W2022_20"},
      {CUTOFF:"2022-05-26",WK_NO:"W2022_21"},
      {CUTOFF:"2022-06-02",WK_NO:"W2022_22"},
      {CUTOFF:"2022-06-09",WK_NO:"W2022_23"},
      {CUTOFF:"2022-06-16",WK_NO:"W2022_24"},
      {CUTOFF:"2022-06-23",WK_NO:"W2022_25"},
      {CUTOFF:"2022-06-30",WK_NO:"W2022_26"},
      {CUTOFF:"2022-07-07",WK_NO:"W2022_27"},
      {CUTOFF:"2022-07-14",WK_NO:"W2022_28"},
      {CUTOFF:"2022-07-21",WK_NO:"W2022_29"},
      {CUTOFF:"2022-07-28",WK_NO:"W2022_30"},
      {CUTOFF:"2022-08-04",WK_NO:"W2022_31"},
      {CUTOFF:"2022-08-11",WK_NO:"W2022_32"},
      {CUTOFF:"2022-08-18",WK_NO:"W2022_33"},
      {CUTOFF:"2022-08-25",WK_NO:"W2022_34"},
      {CUTOFF:"2022-09-01",WK_NO:"W2022_35"},
      {CUTOFF:"2022-09-08",WK_NO:"W2022_36"},
      {CUTOFF:"2022-09-15",WK_NO:"W2022_37"},
      {CUTOFF:"2022-09-22",WK_NO:"W2022_38"},
      {CUTOFF:"2022-09-29",WK_NO:"W2022_39"},
      {CUTOFF:"2022-10-06",WK_NO:"W2022_40"},
      {CUTOFF:"2022-10-13",WK_NO:"W2022_41"},
      {CUTOFF:"2022-10-20",WK_NO:"W2022_42"},
      {CUTOFF:"2022-10-27",WK_NO:"W2022_43"},
      {CUTOFF:"2022-11-03",WK_NO:"W2022_44"},
      {CUTOFF:"2022-11-10",WK_NO:"W2022_45"},
      {CUTOFF:"2022-11-17",WK_NO:"W2022_46"},
      {CUTOFF:"2022-11-24",WK_NO:"W2022_47"},
      {CUTOFF:"2022-12-01",WK_NO:"W2022_48"},
      {CUTOFF:"2022-12-08",WK_NO:"W2022_49"},
      {CUTOFF:"2022-12-15",WK_NO:"W2022_50"},
      {CUTOFF:"2022-12-22",WK_NO:"W2022_51"},
      {CUTOFF:"2022-12-29",WK_NO:"W2022_52"}
    ],
    SQL5: [
      {TOTAL:334,NOTSTARTED:141,APROGRESS:191,ACOMPLETED:2,BPROGRESS:0,LOOPTEST:0, MCCOMPLETED: 1}
    ],
    SQL6: [
      {'Total Loops':1111,'ICFT based on Loop Test Prerequisites':45,'ICFT available based on MC Completed':2,'Incomplete ICFT':1102,'Complete ICFT':9}
      // {'Total Loops':9413,'Loops available based on Loop Test Prerequisites':13,'Loops available based on MC Completed':0,'Incomplete Loops':9413,'Complete Loops':0}
    ],
    SQL7: [ // week date
      {TYPE:"WEEK",DATE:"2022-03-24",DISC:"LWK",ACTUAL:81},
      {TYPE:"WEEK",DATE:"2022-03-25",DISC:"FRI",ACTUAL:16},
      {TYPE:"WEEK",DATE:"2022-03-26",DISC:"SAT",ACTUAL:0},
      {TYPE:"WEEK",DATE:"2022-03-27",DISC:"SUN",ACTUAL:7},
      {TYPE:"WEEK",DATE:"2022-03-28",DISC:"MON",ACTUAL:4},
      {TYPE:"WEEK",DATE:"2022-03-29",DISC:"TUE",ACTUAL:0},
      {TYPE:"WEEK",DATE:"2022-03-30",DISC:"WED",ACTUAL:0},
      {TYPE:"WEEK",DATE:"2022-03-31",DISC:"THU",ACTUAL:0},
      {TYPE:"WEEK",DATE:"2022-03-31",DISC:"TWK",ACTUAL:27}
    ]
  }
}