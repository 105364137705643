import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 850,
      CanvasHeight: 300,
      CanvasBorderWeight: 0.5,
      CanvasBorderColor: '#ccc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Weight : ', x:20, y: 0, font: 'roboto', class: 'regular', size: 11, color: '#BCBCBC', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: 20, y: 0, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'end' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: 20,
          y: 0,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'end'
        },
      ]
    },
    Queries: {
      SQL1:  [
        {CUTOFF:"2022-01-28"}
      ],
    },
    DataItems: [
      {NO:1,PHASE_SEQ:1,PHASE:"TS",CUTOFF:"2021-12-17",WK_NO:"W2021_50",PLAN_PROG:88.7,ACTUAL_PROG:86.8},
{NO:2,PHASE_SEQ:1,PHASE:"TS",CUTOFF:"2021-12-24",WK_NO:"W2021_51",PLAN_PROG:89.4,ACTUAL_PROG:88.1},
{NO:3,PHASE_SEQ:1,PHASE:"TS",CUTOFF:"2021-12-31",WK_NO:"W2021_52",PLAN_PROG:89.9,ACTUAL_PROG:89.6},
{NO:4,PHASE_SEQ:1,PHASE:"TS",CUTOFF:"2022-01-07",WK_NO:"W2022_01",PLAN_PROG:90.3,ACTUAL_PROG:90.1},
{NO:5,PHASE_SEQ:1,PHASE:"TS",CUTOFF:"2022-01-14",WK_NO:"W2022_02",PLAN_PROG:90.8,ACTUAL_PROG:90.4},
{NO:6,PHASE_SEQ:1,PHASE:"TS",CUTOFF:"2022-01-21",WK_NO:"W2022_03",PLAN_PROG:91.6,ACTUAL_PROG:90.8},
{NO:7,PHASE_SEQ:1,PHASE:"TS",CUTOFF:"2022-01-28",WK_NO:"W2022_04",PLAN_PROG:92.3,ACTUAL_PROG:0},
{NO:8,PHASE_SEQ:1,PHASE:"TS",CUTOFF:"2022-02-04",WK_NO:"W2022_05",PLAN_PROG:92.3,ACTUAL_PROG:0},
{NO:9,PHASE_SEQ:1,PHASE:"TS",CUTOFF:"2022-02-11",WK_NO:"W2022_06",PLAN_PROG:92.3,ACTUAL_PROG:0},
{NO:1,PHASE_SEQ:2,PHASE:"Integration",CUTOFF:"2021-12-17",WK_NO:"W2021_50",PLAN_PROG:37.5,ACTUAL_PROG:33.7},
{NO:2,PHASE_SEQ:2,PHASE:"Integration",CUTOFF:"2021-12-24",WK_NO:"W2021_51",PLAN_PROG:42.4,ACTUAL_PROG:37.4},
{NO:3,PHASE_SEQ:2,PHASE:"Integration",CUTOFF:"2021-12-31",WK_NO:"W2021_52",PLAN_PROG:44.1,ACTUAL_PROG:43.2},
{NO:4,PHASE_SEQ:2,PHASE:"Integration",CUTOFF:"2022-01-07",WK_NO:"W2022_01",PLAN_PROG:46.9,ACTUAL_PROG:46.5},
{NO:5,PHASE_SEQ:2,PHASE:"Integration",CUTOFF:"2022-01-14",WK_NO:"W2022_02",PLAN_PROG:48.7,ACTUAL_PROG:48.5},
{NO:6,PHASE_SEQ:2,PHASE:"Integration",CUTOFF:"2022-01-21",WK_NO:"W2022_03",PLAN_PROG:50.6,ACTUAL_PROG:50.3},
{NO:7,PHASE_SEQ:2,PHASE:"Integration",CUTOFF:"2022-01-28",WK_NO:"W2022_04",PLAN_PROG:52.7},
{NO:8,PHASE_SEQ:2,PHASE:"Integration",CUTOFF:"2022-02-04",WK_NO:"W2022_05",PLAN_PROG:52.7},
{NO:9,PHASE_SEQ:2,PHASE:"Integration",CUTOFF:"2022-02-11",WK_NO:"W2022_06",PLAN_PROG:52.7},
{NO:1,PHASE_SEQ:3,PHASE:"Hull",CUTOFF:"2021-12-17",WK_NO:"W2021_50",PLAN_PROG:96.2,ACTUAL_PROG:95.4},
{NO:2,PHASE_SEQ:3,PHASE:"Hull",CUTOFF:"2021-12-24",WK_NO:"W2021_51",PLAN_PROG:96.6,ACTUAL_PROG:95.9},
{NO:3,PHASE_SEQ:3,PHASE:"Hull",CUTOFF:"2021-12-31",WK_NO:"W2021_52",PLAN_PROG:96.9,ACTUAL_PROG:96.5},
{NO:4,PHASE_SEQ:3,PHASE:"Hull",CUTOFF:"2022-01-07",WK_NO:"W2022_01",PLAN_PROG:97.3,ACTUAL_PROG:96.7},
{NO:5,PHASE_SEQ:3,PHASE:"Hull",CUTOFF:"2022-01-14",WK_NO:"W2022_02",PLAN_PROG:97.6,ACTUAL_PROG:96.9},
{NO:6,PHASE_SEQ:3,PHASE:"Hull",CUTOFF:"2022-01-21",WK_NO:"W2022_03",PLAN_PROG:98,ACTUAL_PROG:97},
{NO:7,PHASE_SEQ:3,PHASE:"Hull",CUTOFF:"2022-01-28",WK_NO:"W2022_04",PLAN_PROG:98.3},
{NO:8,PHASE_SEQ:3,PHASE:"Hull",CUTOFF:"2022-02-04",WK_NO:"W2022_05",PLAN_PROG:98.3},
{NO:9,PHASE_SEQ:3,PHASE:"Hull",CUTOFF:"2022-02-11",WK_NO:"W2022_06",PLAN_PROG:98.3},
{NO:1,PHASE_SEQ:4,PHASE:"LQ",CUTOFF:"2021-12-17",WK_NO:"W2021_50",PLAN_PROG:94.1,ACTUAL_PROG:90.2},
{NO:2,PHASE_SEQ:4,PHASE:"LQ",CUTOFF:"2021-12-24",WK_NO:"W2021_51",PLAN_PROG:95,ACTUAL_PROG:91.5},
{NO:3,PHASE_SEQ:4,PHASE:"LQ",CUTOFF:"2021-12-31",WK_NO:"W2021_52",PLAN_PROG:95.5,ACTUAL_PROG:92.7},
{NO:4,PHASE_SEQ:4,PHASE:"LQ",CUTOFF:"2022-01-07",WK_NO:"W2022_01",PLAN_PROG:96.2,ACTUAL_PROG:93.6},
{NO:5,PHASE_SEQ:4,PHASE:"LQ",CUTOFF:"2022-01-14",WK_NO:"W2022_02",PLAN_PROG:96.7,ACTUAL_PROG:94.2},
{NO:6,PHASE_SEQ:4,PHASE:"LQ",CUTOFF:"2022-01-21",WK_NO:"W2022_03",PLAN_PROG:97.1,ACTUAL_PROG:94.5},
{NO:7,PHASE_SEQ:4,PHASE:"LQ",CUTOFF:"2022-01-28",WK_NO:"W2022_04",PLAN_PROG:97.6},
{NO:8,PHASE_SEQ:4,PHASE:"LQ",CUTOFF:"2022-02-04",WK_NO:"W2022_05",PLAN_PROG:97.6},
{NO:9,PHASE_SEQ:4,PHASE:"LQ",CUTOFF:"2022-02-11",WK_NO:"W2022_06",PLAN_PROG:97.6}
      ]
    }