import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 200,
      CanvasHeight: 200,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1787,
      CanvasChartHeight: 1050,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Note', x: 0, y: -20, font: 'roboto', class: 'regular', size: 11, color: '#44A9DF', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: 0, y: -30, font: 'roboto', class: 'regular', size: 11, color: '#757575', align: 'start' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: 0,
          y: -50,
          font: 'roboto',
          style: 'regular',
          size: 10,
          color: '#757575',
          align: 'start'
        },
      ]
    },
    Queries: {
      SQL1:[
        
      ],
    },
    DataItems: [
      {PACKAGE:"SC_Overall"},
    ]
}