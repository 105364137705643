
export default {
  Canvas: {
    CanvasWidth: 1600,
    CanvasHeight: 1120,
    CanvasBorderWeight: 0.2,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',//0E1B2B
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 760,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#fff',
    CanvasChartOpacity: 1,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },

  Queries: {
    SQL1:  [
      // ------------------Photos------------------
      { AREA: '111300', SEI: '111300', NAME: 'photo-gallery-1682498202.png' },
      { AREA: '111300', SEI: '111300', NAME: 'photo-gallery-1681808073.png' },
      { AREA: '111300', SEI: '111300', NAME: 'photo-gallery-1681209902.png' },
      { AREA: '111300', SEI: '111300', NAME: 'photo-gallery-1681207933.png' },
      { AREA: '121300', SEI: '152804', NAME: 'photo-gallery-1682499540.png' },
      { AREA: '121300', SEI: '152804', NAME: 'photo-gallery-1682499519.png' },
      { AREA: '121300', SEI: '121342', NAME: 'photo-gallery-1682498543.png' },
      { AREA: '121300', SEI: '121307', NAME: 'photo-gallery-1682498518.png' },
      { AREA: '121400', SEI: '152803', NAME: 'photo-gallery-1682499487.png' },
      { AREA: '121400', SEI: '152803', NAME: 'photo-gallery-1682499466.png' },
      { AREA: '121400', SEI: '121407', NAME: 'photo-gallery-1682498657.png' },
      { AREA: '121400', SEI: '121400', NAME: 'photo-gallery-1682498631.png' },
      { AREA: '121900', SEI: '121991', NAME: 'photo-gallery-1682498801.png' },
      { AREA: '121900', SEI: '121920', NAME: 'photo-gallery-1682498779.png' },
      { AREA: '121900', SEI: '121904', NAME: 'photo-gallery-1682498754.png' },
      { AREA: '121900', SEI: '121902', NAME: 'photo-gallery-1682498735.png' },
      { AREA: '127700', SEI: '127700', NAME: 'photo-gallery-1682498843.png' },
      { AREA: '127700', SEI: '127700', NAME: 'photo-gallery-1682498825.png' },
      { AREA: '127700', SEI: '127791', NAME: 'photo-gallery-1681808780.png' },
      { AREA: '127700', SEI: '127700', NAME: 'photo-gallery-1681808760.png' },
      { AREA: '127800', SEI: '127800', NAME: 'photo-gallery-1682498884.png' },
      { AREA: '127800', SEI: '127800', NAME: 'photo-gallery-1682498865.png' },
      { AREA: '127800', SEI: '127800', NAME: 'photo-gallery-1681808809.png' },
      { AREA: '127900', SEI: '127901', NAME: 'photo-gallery-1682499083.png' },
      { AREA: '127900', SEI: '127900', NAME: 'photo-gallery-1682499025.png' },
      { AREA: '127900', SEI: '127900', NAME: 'photo-gallery-1682499008.png' },
      { AREA: '127900', SEI: '127900', NAME: 'photo-gallery-1682498991.png' },
      { AREA: '136200', SEI: '136200', NAME: 'photo-gallery-1682499149.png' },
      { AREA: '136200', SEI: '136200', NAME: 'photo-gallery-1682499131.png' },
      { AREA: '136200', SEI: '136200', NAME: 'photo-gallery-1681808977.png' },
      { AREA: '151000', SEI: '154000', NAME: 'photo-gallery-1682499176.png' },
      { AREA: '151000', SEI: '151000', NAME: 'photo-gallery-1682495841.jpeg' },
      { AREA: '151000', SEI: '151000', NAME: 'photo-gallery-1682495813.jpeg' },
      { AREA: '151000', SEI: '154000', NAME: 'photo-gallery-1681809009.png' },
      { AREA: '152400', SEI: '152200', NAME: 'photo-gallery-1682493821.jpeg' },
      { AREA: '152400', SEI: '181200', NAME: 'photo-gallery-1681799663.jpeg' },
      { AREA: '152400', SEI: '181200', NAME: 'photo-gallery-1681206659.jpeg' },
      { AREA: '152400', SEI: '181200', NAME: 'photo-gallery-1681206633.jpeg' },
      { AREA: '152700', SEI: '152700', NAME: 'photo-gallery-1678845884.jpeg' },
      { AREA: '152801', SEI: '152801', NAME: 'photo-gallery-1682499316.png' },
      { AREA: '152801', SEI: '152801', NAME: 'photo-gallery-1682499298.png' },
      { AREA: '152801', SEI: '152801', NAME: 'photo-gallery-1682499278.png' },
      { AREA: '152801', SEI: '152801', NAME: 'photo-gallery-1682499258.png' },
      { AREA: '152802', SEI: '152802', NAME: 'photo-gallery-1682499434.png' },
      { AREA: '152802', SEI: '152802', NAME: 'photo-gallery-1682499415.png' },
      { AREA: '152802', SEI: '152802', NAME: 'photo-gallery-1682499397.png' },
      { AREA: '152802', SEI: '152802', NAME: 'photo-gallery-1682499380.png' },
      { AREA: '170000', SEI: '170300', NAME: 'photo-gallery-1682495194.jpeg' },
      { AREA: '170000', SEI: '170100', NAME: 'photo-gallery-1682495148.jpeg' },
      { AREA: '170000', SEI: '170300', NAME: 'photo-gallery-1681799541.jpeg' },
      { AREA: '170000', SEI: '124700', NAME: 'photo-gallery-1681799213.jpeg' },
      { AREA: '178000', SEI: '178000', NAME: 'photo-gallery-1682499611.png' },
      { AREA: '178000', SEI: '178000', NAME: 'photo-gallery-1681809464.png' },
      { AREA: '178000', SEI: '178000', NAME: 'photo-gallery-1681211111.png' },
      { AREA: '178000', SEI: '178000', NAME: 'photo-gallery-1681209208.png' },
      { AREA: '178900', SEI: '178900', NAME: 'photo-gallery-1682499686.png' },
      { AREA: '178900', SEI: '166800', NAME: 'photo-gallery-1682499571.png' },
      { AREA: '178900', SEI: '178992', NAME: 'photo-gallery-1681809519.png' },
      { AREA: '178900', SEI: '178900', NAME: 'photo-gallery-1681809498.png' },
      { AREA: '179000', SEI: '179000', NAME: 'photo-gallery-1682499729.png' },
      { AREA: '179000', SEI: '179000', NAME: 'photo-gallery-1682499710.png' },
      { AREA: '179000', SEI: '179000', NAME: 'photo-gallery-1681809560.png' },
      { AREA: '179000', SEI: '179000', NAME: 'photo-gallery-1681809539.png' },
      { AREA: '180200', SEI: '180200', NAME: 'photo-gallery-1682499773.png' },
      { AREA: '180200', SEI: '180200', NAME: 'photo-gallery-1682495327.jpeg' },
      { AREA: '180200', SEI: '180200', NAME: 'photo-gallery-1681809593.png' },
      { AREA: '180200', SEI: '180200', NAME: 'photo-gallery-1681211215.png' },
      { AREA: '180600', SEI: '180600', NAME: 'photo-gallery-1682499835.png' },
      { AREA: '180600', SEI: '180600', NAME: 'photo-gallery-1682499813.png' },
      { AREA: '180600', SEI: '180600', NAME: 'photo-gallery-1682495353.jpeg' },
      { AREA: '180600', SEI: '180600', NAME: 'photo-gallery-1681809644.png' },
      { AREA: '181000', SEI: '181000', NAME: 'photo-gallery-1682499860.png' },
      { AREA: '181000', SEI: '181000', NAME: 'photo-gallery-1681809668.png' },
      { AREA: '181000', SEI: '181000', NAME: 'photo-gallery-1681799636.jpeg' },
      { AREA: '181000', SEI: '181000', NAME: 'photo-gallery-1681211285.png' },
      { AREA: '182400', SEI: '182400', NAME: 'photo-gallery-1682499880.png' },
      { AREA: '182400', SEI: '182400', NAME: 'photo-gallery-1682495381.jpeg' },
      { AREA: '182400', SEI: '182400', NAME: 'photo-gallery-1681809697.png' },
      { AREA: '182400', SEI: '182400', NAME: 'photo-gallery-1681211324.png' },
      { AREA: '183000', SEI: '183000', NAME: 'photo-gallery-1682499899.png' },
      { AREA: '183000', SEI: '183000', NAME: 'photo-gallery-1682495440.jpeg' },
      { AREA: '183000', SEI: '183000', NAME: 'photo-gallery-1682495413.jpeg' },
      { AREA: '183000', SEI: '183000', NAME: 'photo-gallery-1681799717.jpeg' },
      { AREA: '184300', SEI: '184300', NAME: 'photo-gallery-1682499985.png' },
      { AREA: '184300', SEI: '184300', NAME: 'photo-gallery-1682499966.png' },
      { AREA: '184300', SEI: '184300', NAME: 'photo-gallery-1682499948.png' },
      { AREA: '184300', SEI: '184300', NAME: 'photo-gallery-1682499929.png' },
      { AREA: '184800', SEI: '184800', NAME: 'photo-gallery-1682500044.png' },
      { AREA: '184800', SEI: '184800', NAME: 'photo-gallery-1682500026.png' },
      { AREA: '184800', SEI: '184800', NAME: 'photo-gallery-1682500008.png' },
      { AREA: '184800', SEI: '184800', NAME: 'photo-gallery-1682495500.jpeg' },
      { AREA: '185300', SEI: '185300', NAME: 'photo-gallery-1682495544.jpeg' },
      { AREA: '185300', SEI: '185300', NAME: 'photo-gallery-1681809929.png' },
      { AREA: '185300', SEI: '185300', NAME: 'photo-gallery-1681211525.png' },
      { AREA: '185300', SEI: '185300', NAME: 'photo-gallery-1681209807.png' },
      { AREA: '277000', SEI: '277000', NAME: 'photo-gallery-1682495575.jpeg' },
      { AREA: '277000', SEI: '168000', NAME: 'photo-gallery-1680767604.jpeg' },
      { AREA: '277000', SEI: '168000', NAME: 'photo-gallery-1680767573.jpeg' },
      { AREA: '277000', SEI: '177100', NAME: 'photo-gallery-1680086384.png' },
      { AREA: '277100', SEI: '170800', NAME: 'photo-gallery-1682495238.jpeg' },
      { AREA: '277100', SEI: '170800', NAME: 'photo-gallery-1681799568.jpeg' },
      { AREA: '277100', SEI: '170800', NAME: 'photo-gallery-1681206479.jpeg' },
      { AREA: '277100', SEI: '170800', NAME: 'photo-gallery-1680767671.jpeg' },
      { AREA: '280200', SEI: '280200', NAME: 'photo-gallery-1682500069.png' },
      { AREA: '280200', SEI: '280200', NAME: 'photo-gallery-1682495686.jpeg' },
      { AREA: '280200', SEI: '280200', NAME: 'photo-gallery-1682495658.jpeg' },
      { AREA: '280200', SEI: '280200', NAME: 'photo-gallery-1681809961.png' },
      { AREA: '281000', SEI: '281000', NAME: 'photo-gallery-1682495784.jpeg' },
      { AREA: '281000', SEI: '281000', NAME: 'photo-gallery-1681799884.jpeg' },
      { AREA: '281000', SEI: '281000', NAME: 'photo-gallery-1681206947.jpeg' },
      { AREA: '281000', SEI: '281000', NAME: 'photo-gallery-1680083815.jpeg' },
    ],
    SQL2:  [
      // ------------------Areas------------------
      { sn: 1,  package: 'P1', code: '111300', width: 120, photoNo: 2, name: 'BTX-Benzene Toluene Unit', },
      { sn: 2,  package: 'P1', code: '127700', width: 120, photoNo: 3, name: 'BD-Butadiene Unit', },
      { sn: 3,  package: 'P1', code: '127800', width: 120, photoNo: 1, name: 'GHU-Gasoline Hydrotreating Unit', },
      { sn: 4,  package: 'P1', code: '127900', width: 120, photoNo: 5, name: 'OR-Olefins Unit', },
      { sn: 5,  package: 'P1', code: '136200', width: 120, photoNo: 1, name: 'DIB-DI-Isobutylene Unit', },
      { sn: 6,  package: 'P1', code: '170000', width: 245, photoNo: 4, name: 'Pressurized Tank Farm Common Area', },
      { sn: 7,  package: 'P1', code: '184300', width: 120, photoNo: 3, name: 'Demineralized Water Unit', },
      { sn: 8,  package: 'P1', code: '184800', width: 120, photoNo: 2, name: 'Boiler Unit', },
      { sn: 9,  package: 'P1', code: '277000', width: 250, photoNo: 4, name: 'Atmospheric Tank Farm Common Area', },
      { sn: 10, package: 'P1', code: '277100', width: 185, photoNo: 3, name: 'Intermediate Tank Farm 2', },
      { sn: 11, package: 'P2', code: '121300', width: 150, photoNo: 5, name: 'GPPE-Gas Phase Polyethylene Unit', },
      { sn: 12, package: 'P2', code: '121400', width: 150, photoNo: 5, name: 'LDPE-Low Density Polyethylene Unit', },
      { sn: 13, package: 'P2', code: '121900', width: 150, photoNo: 5, name: 'PP - Polypropylene Unit', },
      { sn: 14, package: 'P2', code: '152801', width: 140, photoNo: 1, name: 'No.1 Warehouse', },
      { sn: 15, package: 'P2', code: '152802', width: 140, photoNo: 1, name: 'No.2 Warehouse', },
      { sn: 16, package: 'P3', code: '151000', width: 255, photoNo: 4, name: 'Common Area of Management Facilities', },
      { sn: 17, package: 'P3', code: '152400', width: 165, photoNo: 5, name: 'Chemical Warehouses', },
      { sn: 18, package: 'P3', code: '152700', width: 180, photoNo: 1, name: 'Main plant Dispatch Area', },
      { sn: 19, package: 'P3', code: '170092', width: 180, photoNo: 2, name: 'Tank Farm 1st Substation', },
      { sn: 20, package: 'P3', code: '178000', width: 190, photoNo: 2, name: 'Loading/Unloading Station', },
      { sn: 21, package: 'P3', code: '178900', width: 120, photoNo: 4, name: 'Ground Flare', },
      { sn: 22, package: 'P3', code: '180200', width: 180, photoNo: 1, name: 'Cooling Water System 1', },
      { sn: 23, package: 'P3', code: '180292', width: 110, photoNo: 1, name: 'Substation',  },
      { sn: 24, package: 'P3', code: '180600', width: 320, photoNo: 1, name: 'Storm Water Pump Station and Accident Buffer Basin', },
      { sn: 25, package: 'P3', code: '181000', width: 235, photoNo: 1, name: 'Water and Firewater Pump Station 1', },
      { sn: 26, package: 'P3', code: '182400', width: 210, photoNo: 2, name: 'Wastewater Pre-Treatment Unit', },
      { sn: 27, package: 'P3', code: '183000', width: 135, photoNo: 1, name: 'Main Substation', },
      { sn: 28, package: 'P3', code: '185300', width: 175, photoNo: 2, name: 'Air Compression Station', },
      { sn: 29, package: 'P3', code: '280200', width: 175, photoNo: 2, name: 'Cooling Water System 2', },
      { sn: 30, package: 'P3', code: '281000', width: 235, photoNo: 1, name: 'Water and Firewater Pump Station 2', },
    ],
  },

  Text: {
    Text: [
      { text: 'Note', x: 1350, y: 20, font: 'roboto', class: 'regular', size: 11, color: '#44A9DF', align: 'start' },
      
    ],
    Filter: [
      { name: 'MOD', x: 1350, y: 30, font: 'roboto', class: 'regular', size: 11, color: '#757575', align: 'start' },
    ],
    Sql: [
      {
        query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
        text: 'Data Source',
        x: 41,
        y: 115,
        font: 'roboto',
        style: 'regular',
        size: 10,
        color: '#757575',
        align: 'start'
      },
    ]
  },

  DataItems: [
    { NO: 1,  SN: 1,  AREA: '111300', SEI: '111342', CC: 'SNEL', JIANLI: 'BJHX',   SEI_ENG: 'Sanitary Sewage Sump',                                              SEI_CHI: '生活废水池', },
    { NO: 1,  SN: 2,  AREA: '111300', SEI: '111394', CC: 'SNEL', JIANLI: 'BJHX',   SEI_ENG: 'Co-product Operator Shelter',                                       SEI_CHI: '联合装置外操间', },
    { NO: 2,  SN: 1,  AREA: '121300', SEI: '121301', CC: 'FCC',  JIANLI: 'Gosun',  SEI_ENG: 'GPPE Interconnecting Pipe Rack',                                    SEI_CHI: 'GPPE街区管廊', },
    { NO: 2,  SN: 2,  AREA: '121300', SEI: '121302', CC: 'FCC',  JIANLI: 'Gosun',  SEI_ENG: 'GPPE Feed And Utility Area  A',                                     SEI_CHI: 'GPPE 进料和共用工程A区', },
    { NO: 2,  SN: 3,  AREA: '121300', SEI: '121303', CC: 'FCC',  JIANLI: 'Gosun',  SEI_ENG: 'GPPE Feed And Utility Area B',                                      SEI_CHI: 'GPPE 进料和共用工程B区', },
    { NO: 2,  SN: 4,  AREA: '121300', SEI: '121304', CC: 'FCC',  JIANLI: 'Gosun',  SEI_ENG: 'GPPE Extrusion Building',                                           SEI_CHI: 'GPPE 挤压造粒厂房', },
    { NO: 2,  SN: 5,  AREA: '121300', SEI: '121305', CC: 'FCC',  JIANLI: 'Gosun',  SEI_ENG: 'GPPE Thermal Oxidizer Blower Area',                                 SEI_CHI: 'GPPE 热氧化物风机单元', },
    { NO: 2,  SN: 6,  AREA: '121300', SEI: '121306', CC: 'FCC',  JIANLI: 'Gosun',  SEI_ENG: 'GPPE Pellet Dryers Classifiers And Surge Bin Area',                 SEI_CHI: 'GPPE 粒料干燥及出料单元', },
    { NO: 2,  SN: 7,  AREA: '121300', SEI: '121307', CC: 'FCC',  JIANLI: 'Gosun',  SEI_ENG: 'GPPE Pellet Transfer Compressor Area',                              SEI_CHI: 'GPPE 粒料输送风机单元', },
    { NO: 2,  SN: 8,  AREA: '121300', SEI: '121308', CC: 'FCC',  JIANLI: 'Gosun',  SEI_ENG: 'GPPE Elutriator And Pellet Silos Area',                             SEI_CHI: 'GPPE 淘析和成品包装', },
    { NO: 2,  SN: 9,  AREA: '121300', SEI: '121311', CC: 'FCC',  JIANLI: 'Gosun',  SEI_ENG: 'GPPE Reaction And Purge Bin Area Of Line 1',                        SEI_CHI: 'GPPE 1反应脱气单元', },
    { NO: 2,  SN: 10, AREA: '121300', SEI: '121312', CC: 'FCC',  JIANLI: 'Gosun',  SEI_ENG: 'GPPE Vent Gas Recovery And Propylene Refrigeration Area Of Line 1', SEI_CHI: 'GPPE 1线排放气回收及丙烯制冷单元', },
    { NO: 2,  SN: 11, AREA: '121300', SEI: '121313', CC: 'FCC',  JIANLI: 'Gosun',  SEI_ENG: 'GPPE Granules Conveying System Of Line 1',                          SEI_CHI: 'GPPE 1线粉料输送风机单元', },
    { NO: 2,  SN: 12, AREA: '121300', SEI: '121321', CC: 'FCC',  JIANLI: 'Gosun',  SEI_ENG: 'GPPE Reaction And Purge Bin Area Of Line 2',                        SEI_CHI: 'GPPE 2线反应脱气单元', },
    { NO: 2,  SN: 13, AREA: '121300', SEI: '121322', CC: 'FCC',  JIANLI: 'Gosun',  SEI_ENG: 'GPPE Vent Gas Recovery And Propylene Refrigeration Area Of Line 2', SEI_CHI: 'GPPE 2线排放气回收及丙烯制冷单元', },
    { NO: 2,  SN: 14, AREA: '121300', SEI: '121323', CC: 'FCC',  JIANLI: 'Gosun',  SEI_ENG: 'GPPE Granules Conveying System Of Line 2',                          SEI_CHI: 'GPPE 2线粉料输送风机单元', },
    { NO: 2,  SN: 15, AREA: '121300', SEI: '121332', CC: 'FCC',  JIANLI: 'Gosun',  SEI_ENG: 'GPPE CHEMICAL STORAGE',                                             SEI_CHI: 'GPPE化学品库 ', },
    { NO: 2,  SN: 16, AREA: '121300', SEI: '121342', CC: 'FCC',  JIANLI: 'Gosun',  SEI_ENG: 'GPPE Waste Water/Storm Water Area',                                 SEI_CHI: 'GPPE 废水池', },
    { NO: 2,  SN: 17, AREA: '121300', SEI: '121391', CC: 'FCC',  JIANLI: 'Gosun',  SEI_ENG: 'GPPE RIE 5',                                                        SEI_CHI: 'GPPE 现场机柜室', },
    { NO: 2,  SN: 18, AREA: '121300', SEI: '121392', CC: 'FCC',  JIANLI: 'Gosun',  SEI_ENG: 'GPPE Substation',                                                   SEI_CHI: 'GPPE变电所', },
    { NO: 2,  SN: 19, AREA: '121300', SEI: '152804', CC: 'FCC',  JIANLI: 'QYECC',  SEI_ENG: 'No.2 Package Plant',                                                SEI_CHI: '2#成品包装', },
    { NO: 2,  SN: 20, AREA: '121300', SEI: '152805', CC: 'FCC',  JIANLI: 'QYECC',  SEI_ENG: 'Auxiliary Facility',                                                SEI_CHI: '辅助生产设施', },
    { NO: 2,  SN: 21, AREA: '121300', SEI: '252702', CC: 'FCC',  JIANLI: 'QYECC',  SEI_ENG: 'Truck Scale2',                                                      SEI_CHI: '汽车衡12', },
    { NO: 3,  SN: 1,  AREA: '121400', SEI: '121401', CC: 'SNEL', JIANLI: 'Gosun ', SEI_ENG: 'Compressor House',                                                  SEI_CHI: '压缩机厂房', },
    { NO: 3,  SN: 2,  AREA: '121400', SEI: '121402', CC: 'SNEL', JIANLI: 'Gosun ', SEI_ENG: 'Reaction Area',                                                     SEI_CHI: '反应区', },
    { NO: 3,  SN: 3,  AREA: '121400', SEI: '121403', CC: 'SNEL', JIANLI: 'Gosun ', SEI_ENG: 'Hp Recycle Area',                                                   SEI_CHI: '高压循环区', },
    { NO: 3,  SN: 4,  AREA: '121400', SEI: '121404', CC: 'SNEL', JIANLI: 'Gosun ', SEI_ENG: 'Purge Gas Area',                                                    SEI_CHI: '排放气框架区', },
    { NO: 3,  SN: 5,  AREA: '121400', SEI: '121405', CC: 'SNEL', JIANLI: 'Gosun ', SEI_ENG: 'Extrusion Area',                                                    SEI_CHI: '挤压造粒区', },
    { NO: 3,  SN: 6,  AREA: '121400', SEI: '121406', CC: 'SNEL', JIANLI: 'Gosun ', SEI_ENG: 'Compressor Lubrication Cooling Oil',                                SEI_CHI: '压缩机油站区', },
    { NO: 3,  SN: 7,  AREA: '121400', SEI: '121407', CC: 'SNEL', JIANLI: 'Gosun ', SEI_ENG: 'Initiator Mixing',                                                  SEI_CHI: '引发剂配置区', },
    { NO: 3,  SN: 8,  AREA: '121400', SEI: '121408', CC: 'SNEL', JIANLI: 'Gosun ', SEI_ENG: 'Chilled Water',                                                     SEI_CHI: '冷冻水', },
    { NO: 3,  SN: 9,  AREA: '121400', SEI: '121409', CC: 'SNEL', JIANLI: 'Gosun ', SEI_ENG: 'Flare Knock-Out',                                                   SEI_CHI: '火炬罐', },
    { NO: 3,  SN: 10, AREA: '121400', SEI: '121410', CC: 'SNEL', JIANLI: 'Gosun ', SEI_ENG: 'Utility Water Area',                                                SEI_CHI: '公用工程区', },
    { NO: 3,  SN: 11, AREA: '121400', SEI: '121411', CC: 'SNEL', JIANLI: 'Gosun ', SEI_ENG: 'Modifier and Solvent System',                                       SEI_CHI: 'RTO炉', },
    { NO: 3,  SN: 12, AREA: '121400', SEI: '121412', CC: 'SNEL', JIANLI: 'Gosun ', SEI_ENG: 'Vent Gas Treatment Package ',                                       SEI_CHI: '', },
    { NO: 3,  SN: 13, AREA: '121400', SEI: '121413', CC: 'SNEL', JIANLI: 'Gosun ', SEI_ENG: 'Pellet Degassing Silos',                                            SEI_CHI: '脱气仓', },
    { NO: 3,  SN: 14, AREA: '121400', SEI: '121414', CC: 'SNEL', JIANLI: 'Gosun ', SEI_ENG: 'Blend And Bagging Silos',                                           SEI_CHI: '掺混包装料仓', },
    { NO: 3,  SN: 15, AREA: '121400', SEI: '121415', CC: 'SNEL', JIANLI: 'Gosun ', SEI_ENG: 'At Line-LAB',                                                       SEI_CHI: '在线分析室', },
    { NO: 3,  SN: 16, AREA: '121400', SEI: '121442', CC: 'SNEL', JIANLI: 'Gosun ', SEI_ENG: 'Sump Area',                                                         SEI_CHI: '废水池', },
    { NO: 3,  SN: 17, AREA: '121400', SEI: '121491', CC: 'SNEL', JIANLI: 'Gosun ', SEI_ENG: 'LDPE RIE 3',                                                        SEI_CHI: '现场机柜室', },
    { NO: 3,  SN: 18, AREA: '121400', SEI: '121492', CC: 'SNEL', JIANLI: 'Gosun ', SEI_ENG: 'LDPE Substation',                                                   SEI_CHI: 'LDPE变电所', },
    { NO: 3,  SN: 19, AREA: '121400', SEI: '152406', CC: 'HZDJ', JIANLI: 'TJU',    SEI_ENG: 'Chemical Warehouses 6',                                             SEI_CHI: '危险化学品库6', },
    { NO: 3,  SN: 20, AREA: '121400', SEI: '152803', CC: 'SFCC', JIANLI: 'QYECC',  SEI_ENG: 'No.1 Package Plant',                                                SEI_CHI: '1#成品包装', },
    { NO: 3,  SN: 21, AREA: '121400', SEI: '252701', CC: 'SFCC', JIANLI: 'QYECC',  SEI_ENG: 'Truck Scale1',                                                      SEI_CHI: '汽车衡1', },
    { NO: 4,  SN: 1,  AREA: '121900', SEI: '121901', CC: 'SFCC', JIANLI: 'QYECC',  SEI_ENG: 'PP BATTERY LIMIT PIPE RACK',                                        SEI_CHI: '聚丙烯装置街区管廊', },
    { NO: 4,  SN: 2,  AREA: '121900', SEI: '121902', CC: 'SFCC', JIANLI: 'QYECC',  SEI_ENG: 'PP TEAL FACILITY AREA',                                             SEI_CHI: 'PP烷基铝配制', },
    { NO: 4,  SN: 3,  AREA: '121900', SEI: '121903', CC: 'SFCC', JIANLI: 'QYECC',  SEI_ENG: 'PP GRANULES TRANSFER AND EXTRUSION BUILDING',                       SEI_CHI: 'PP粉料输送和挤压造粒厂房', },
    { NO: 4,  SN: 4,  AREA: '121900', SEI: '121904', CC: 'SFCC', JIANLI: 'QYECC',  SEI_ENG: 'PP PELLET SILOS AREA',                                              SEI_CHI: 'PP颗粒料仓', },
    { NO: 4,  SN: 5,  AREA: '121900', SEI: '121905', CC: 'SFCC', JIANLI: 'QYECC',  SEI_ENG: 'PP PELLET ELUTRIATION AND BAGGING AREA',                            SEI_CHI: 'PP淘析和成品包装', },
    { NO: 4,  SN: 6,  AREA: '121900', SEI: '121906', CC: 'SFCC', JIANLI: 'QYECC',  SEI_ENG: 'PP HYDROGEN COMPRESSOR UNIT',                                       SEI_CHI: 'PP氢气压缩机单元', },
    { NO: 4,  SN: 7,  AREA: '121900', SEI: '121907', CC: 'SFCC', JIANLI: 'QYECC',  SEI_ENG: 'PP OIL/GREASE STORAGE AND MIXING,PROPYLENE DRYER AREA',             SEI_CHI: 'PP油/脂储存和混合,及丙烯干燥单元', },
    { NO: 4,  SN: 8,  AREA: '121900', SEI: '121908', CC: 'SFCC', JIANLI: 'QYECC',  SEI_ENG: 'PP OIL TREATMENT,CHILLED WATER AND STEAM CONDENSATE DRUM AREA',     SEI_CHI: 'PP油处理,冷冻水和蒸汽凝液单元', },
    { NO: 4,  SN: 9,  AREA: '121900', SEI: '121909', CC: 'SFCC', JIANLI: 'QYECC',  SEI_ENG: 'PP REGENERATIVE THERMAL OXIDIZER AREA',                             SEI_CHI: 'PP RTO单元', },
    { NO: 4,  SN: 10, AREA: '121900', SEI: '121910', CC: 'SFCC', JIANLI: 'QYECC',  SEI_ENG: 'CPP-1 POLYMERIZATION AREA',                                         SEI_CHI: 'PP聚合区1线', },
    { NO: 4,  SN: 11, AREA: '121900', SEI: '121911', CC: 'SFCC', JIANLI: 'QYECC',  SEI_ENG: 'CPP-1 PROPYLENE FEED AND STEAM CONDENSATE DRUM AREA',               SEI_CHI: 'PP聚合区1线丙烯进料和蒸汽凝液罐单元', },
    { NO: 4,  SN: 12, AREA: '121900', SEI: '121912', CC: 'SFCC', JIANLI: 'QYECC',  SEI_ENG: 'CPP-1 CATALYST PREPARATION AND METERING AREA',                      SEI_CHI: 'PP聚合区1线催化剂制备单元', },
    { NO: 4,  SN: 13, AREA: '121900', SEI: '121913', CC: 'SFCC', JIANLI: 'QYECC',  SEI_ENG: 'CPP-1 BULK POLYMERIZATION AREA',                                    SEI_CHI: 'PP聚合区1线反应单元', },
    { NO: 4,  SN: 14, AREA: '121900', SEI: '121914', CC: 'SFCC', JIANLI: 'QYECC',  SEI_ENG: 'CPP-1 GAS PHASE REACTORS,POLYMER DEGASSING AND GRANULES DRYING AREA', SEI_CHI: 'PP聚合区1线聚合干燥单元', },
    { NO: 4,  SN: 15, AREA: '121900', SEI: '121915', CC: 'SFCC', JIANLI: 'QYECC',  SEI_ENG: 'CPP-1 POLYMERIZATION AREA PIPE RACK',                               SEI_CHI: 'PP聚合区1线管廊', },
    { NO: 4,  SN: 16, AREA: '121900', SEI: '121916', CC: 'SFCC', JIANLI: 'QYECC',  SEI_ENG: 'CPP-1 TEAL SCRUBBER AND RECYCLE GAS COMPRESSOR AREA',               SEI_CHI: 'PP聚合区1线TEAL洗涤和循环气压缩机单元', },
    { NO: 4,  SN: 17, AREA: '121900', SEI: '121917', CC: 'SFCC', JIANLI: 'QYECC',  SEI_ENG: 'CPP-1 BLOWDOWN AREA',                                               SEI_CHI: 'PP聚合区1线火炬单元', },
    { NO: 4,  SN: 18, AREA: '121900', SEI: '121920', CC: 'SFCC', JIANLI: 'QYECC',  SEI_ENG: 'CPP-2 POLYMERIZATION AREA',                                         SEI_CHI: 'PP聚合区2线', },
    { NO: 4,  SN: 19, AREA: '121900', SEI: '121921', CC: 'SFCC', JIANLI: 'QYECC',  SEI_ENG: 'CPP-2 PROPYLENE FEED AREA',                                         SEI_CHI: 'PP聚合区2线丙烯进料单元', },
    { NO: 4,  SN: 20, AREA: '121900', SEI: '121922', CC: 'SFCC', JIANLI: 'QYECC',  SEI_ENG: 'CPP-2 CATALYST PREPARATION AND METERING AREA',                      SEI_CHI: 'PP聚合区2线催化剂制备单元', },
    { NO: 4,  SN: 21, AREA: '121900', SEI: '121923', CC: 'SFCC', JIANLI: 'QYECC',  SEI_ENG: 'CPP-2 BULK POLYMERIZATION AREA',                                    SEI_CHI: 'PP聚合区2线反应单元', },
    { NO: 4,  SN: 22, AREA: '121900', SEI: '121924', CC: 'SFCC', JIANLI: 'QYECC',  SEI_ENG: 'CPP-2 POLYMER DEGASSING AND GRANULES DRYING AREA',                  SEI_CHI: 'PP聚合区2线汽蒸干燥单元', },
    { NO: 4,  SN: 23, AREA: '121900', SEI: '121925', CC: 'SFCC', JIANLI: 'QYECC',  SEI_ENG: 'CPP-2 POLYMERIZATION AREA PIPE RACK',                               SEI_CHI: 'PP聚合区2线管廊', },
    { NO: 4,  SN: 24, AREA: '121900', SEI: '121926', CC: 'SFCC', JIANLI: 'QYECC',  SEI_ENG: 'CPP-2 TEAL SCRUBBER AND RECYCLE GAS COMPERSSOR AREA',               SEI_CHI: 'PP聚合区2线TEAL洗涤和循环气压缩机单元', },
    { NO: 4,  SN: 25, AREA: '121900', SEI: '121927', CC: 'SFCC', JIANLI: 'QYECC',  SEI_ENG: 'CPP-2 BLOWDOWN AND ETHYLENE COMPRESSOR AREA',                       SEI_CHI: 'PP聚合区2线火炬和乙烯压缩机单元', },
    { NO: 4,  SN: 26, AREA: '121900', SEI: '121931', CC: 'SFCC', JIANLI: 'QYECC',  SEI_ENG: 'PP NEW LUBE/WASTE OIL DRUM STORAGE',                                SEI_CHI: 'PP新鲜油/废油储存间', },
    { NO: 4,  SN: 27, AREA: '121900', SEI: '121932', CC: 'SFCC', JIANLI: 'QYECC',  SEI_ENG: 'PP CHEMICAL STORAGE',                                               SEI_CHI: '化学品间', },
    { NO: 4,  SN: 28, AREA: '121900', SEI: '121942', CC: 'SFCC', JIANLI: 'QYECC',  SEI_ENG: 'PP WASTE WATER/STORM WATER AREA',                                   SEI_CHI: 'PP废水池', },
    { NO: 4,  SN: 29, AREA: '121900', SEI: '121991', CC: 'SFCC', JIANLI: 'QYECC',  SEI_ENG: 'PP RIE 4',                                                          SEI_CHI: 'PP 现场机柜室', },
    { NO: 4,  SN: 30, AREA: '121900', SEI: '121992', CC: 'SFCC', JIANLI: 'QYECC',  SEI_ENG: 'PP Substation',                                                     SEI_CHI: 'PP 变电所', },
    { NO: 4,  SN: 31, AREA: '121900', SEI: '152803', CC: 'SFCC', JIANLI: 'QYECC',  SEI_ENG: 'No.1 Package Plant',                                                SEI_CHI: '1#成品包装', },
    { NO: 5,  SN: 1,  AREA: '127700', SEI: '127742', CC: 'SNEL', JIANLI: 'BJHX',   SEI_ENG: 'Co-product Waste Water Sump',                                       SEI_CHI: '联合装置废水池', },
    { NO: 5,  SN: 2,  AREA: '127700', SEI: '127791', CC: 'SNEL', JIANLI: 'BJHX',   SEI_ENG: 'RIE-2',                                                             SEI_CHI: '联合现场机柜室', },
    { NO: 5,  SN: 3,  AREA: '127700', SEI: '127792', CC: 'SNEL', JIANLI: 'BJHX',   SEI_ENG: 'Co-product Substation',                                             SEI_CHI: '联合变电所', },
    { NO: 6,  SN: 1,  AREA: '127800', SEI: '127800', CC: 'SNEL', JIANLI: 'BJHX',   SEI_ENG: 'GHU ',                                                              SEI_CHI: '裂解汽油加氢装置', },
    { NO: 7,  SN: 1,  AREA: '127900', SEI: '127901', CC: 'STCC', JIANLI: 'YPES',   SEI_ENG: 'Furnaces Area',                                                     SEI_CHI: '急冷区', },
    { NO: 7,  SN: 2,  AREA: '127900', SEI: '127902', CC: 'STCC', JIANLI: 'YPES',   SEI_ENG: 'Quench Area',                                                       SEI_CHI: '急冷区', },
    { NO: 7,  SN: 3,  AREA: '127900', SEI: '127903', CC: 'STCC', JIANLI: 'YPES',   SEI_ENG: 'Compression Area',                                                  SEI_CHI: '压缩区', },
    { NO: 7,  SN: 4,  AREA: '127900', SEI: '127904', CC: 'STCC', JIANLI: 'YPES',   SEI_ENG: 'Cold Fraction Area',                                                SEI_CHI: '冷区', },
    { NO: 7,  SN: 5,  AREA: '127900', SEI: '127905', CC: 'STCC', JIANLI: 'YPES',   SEI_ENG: 'Hot Fraction Area',                                                 SEI_CHI: '热区', },
    { NO: 7,  SN: 6,  AREA: '127900', SEI: '127906', CC: 'STCC', JIANLI: 'YPES',   SEI_ENG: 'PSA Area',                                                          SEI_CHI: 'PSA单元', },
    { NO: 7,  SN: 7,  AREA: '127900', SEI: '127907', CC: 'STCC', JIANLI: 'YPES',   SEI_ENG: 'Spent Caustic Area',                                                SEI_CHI: '废碱氧化单元', },
    { NO: 7,  SN: 8,  AREA: '127900', SEI: '127908', CC: 'STCC', JIANLI: 'YPES',   SEI_ENG: 'Steam & Condensate Area',                                           SEI_CHI: '蒸汽及凝液单元', },
    { NO: 7,  SN: 9,  AREA: '127900', SEI: '127909', CC: 'STCC', JIANLI: 'YPES',   SEI_ENG: 'Benzene Removal Area',                                              SEI_CHI: '污水脱苯单元', },
    { NO: 7,  SN: 10, AREA: '127900', SEI: '127942', CC: 'STCC', JIANLI: 'YPES',   SEI_ENG: 'Waste Water Sump',                                                  SEI_CHI: '废水池', },
    { NO: 7,  SN: 11, AREA: '127900', SEI: '127982', CC: 'STCC', JIANLI: 'YPES',   SEI_ENG: 'OR 2nd Substation',                                                 SEI_CHI: '第二变电所', },
    { NO: 7,  SN: 12, AREA: '127900', SEI: '127991', CC: 'STCC', JIANLI: 'YPES',   SEI_ENG: 'RIE-1',                                                             SEI_CHI: '现场机柜室', },
    { NO: 7,  SN: 13, AREA: '127900', SEI: '127992', CC: 'STCC', JIANLI: 'YPES',   SEI_ENG: 'OR 1st Substation',                                                 SEI_CHI: '第一变电所', },
    { NO: 7,  SN: 14, AREA: '127900', SEI: '127993', CC: 'STCC', JIANLI: 'YPES',   SEI_ENG: 'Water Spray',                                                       SEI_CHI: '雨淋阀', },
    { NO: 8,  SN: 1,  AREA: '136200', SEI: '136200', CC: 'SNEL', JIANLI: 'BJHX',   SEI_ENG: 'DIB ',                                                              SEI_CHI: '装置', },
    { NO: 9,  SN: 1,  AREA: '151000', SEI: '152000', CC: 'GDHJ', JIANLI: 'TJU',    SEI_ENG: 'Central Laboratory and Environmental Monitoring Station',           SEI_CHI: '中心化验室及环境监测站', },
    { NO: 9,  SN: 2,  AREA: '151000', SEI: '154000', CC: 'ZGLY', JIANLI: 'TJU',    SEI_ENG: 'Administration Office Building B (including canteen & locker room)', SEI_CHI: '综合办公楼B包含餐厅和更衣室', },
    { NO: 9,  SN: 3,  AREA: '151000', SEI: '180401', CC: 'GDHJ', JIANLI: 'TJU',    SEI_ENG: 'Waste Water Pump Station 1',                                        SEI_CHI: '污水提升泵站1', },
    { NO: 9,  SN: 4,  AREA: '151000', SEI: '183100', CC: 'GDHJ', JIANLI: 'TJU',    SEI_ENG: 'Area B+ Substation',                                                SEI_CHI: '厂前区变电所', },
    { NO: 9,  SN: 5,  AREA: '151000', SEI: '186000', CC: 'GDHJ', JIANLI: 'TJU',    SEI_ENG: 'Control Center Building',                                           SEI_CHI: '中心控制室', },
    { NO: 9,  SN: 6,  AREA: '151000', SEI: '187501', CC: 'SFCC', JIANLI: 'Gosun',  SEI_ENG: 'Telecom equipment room',                                            SEI_CHI: '电信机柜间', },
    { NO: 9,  SN: 7,  AREA: '151000', SEI: '381006', CC: 'GDHJ', JIANLI: 'TJU',    SEI_ENG: 'Firewater Pump Station (B+)',                                        SEI_CHI: '厂前区消防水泵站', },
    { NO: 10, SN: 1,  AREA: '152400', SEI: '152200', CC: 'HZDJ', JIANLI: 'TJU',    SEI_ENG: 'Maintenance Workshop',                                              SEI_CHI: '维修中心', },
    { NO: 10, SN: 2,  AREA: '152400', SEI: '152300', CC: 'HZDJ', JIANLI: 'TJU',    SEI_ENG: 'Warehouse Management Room and Spare Parts Warehouse',               SEI_CHI: '全厂性仓库', },
    { NO: 10, SN: 3,  AREA: '152400', SEI: '152401', CC: 'HZDJ', JIANLI: 'TJU',    SEI_ENG: 'Chemical Warehouses 1',                                             SEI_CHI: '危险化学品库1', },
    { NO: 10, SN: 4,  AREA: '152400', SEI: '152402', CC: 'HZDJ', JIANLI: 'TJU',    SEI_ENG: 'Chemical Warehouses 2',                                             SEI_CHI: '危险化学品库2', },
    { NO: 10, SN: 5,  AREA: '152400', SEI: '152403', CC: 'HZDJ', JIANLI: 'TJU',    SEI_ENG: 'Chemical Warehouses 3',                                             SEI_CHI: '危险化学品库3', },
    { NO: 10, SN: 6,  AREA: '152400', SEI: '152404', CC: 'HZDJ', JIANLI: 'TJU',    SEI_ENG: 'Chemical Warehouses 4',                                             SEI_CHI: '危险化学品库4', },
    { NO: 10, SN: 7,  AREA: '152400', SEI: '152405', CC: 'HZDJ', JIANLI: 'TJU',    SEI_ENG: 'Chemical Warehouses 5',                                             SEI_CHI: '危险化学品库5', },
    { NO: 10, SN: 8,  AREA: '152400', SEI: '152407', CC: 'HZDJ', JIANLI: 'TJU',    SEI_ENG: 'Chemical Warehouses 7',                                             SEI_CHI: '危险化学品库1', },
    { NO: 10, SN: 9,  AREA: '152400', SEI: '152408', CC: 'HZDJ', JIANLI: 'TJU',    SEI_ENG: 'Chemical Warehouses 8',                                             SEI_CHI: '危险化学品库8', },
    { NO: 10, SN: 10, AREA: '152400', SEI: '152492', CC: 'HZDJ', JIANLI: 'TJU',    SEI_ENG: 'Substation',                                                        SEI_CHI: '配电间', },
    { NO: 10, SN: 11, AREA: '152400', SEI: '180404', CC: 'HZDJ', JIANLI: 'TJU',    SEI_ENG: 'Waste Water Pump Station 4 For Chemical Warehouse',                 SEI_CHI: '污水提升泵站 4', },
    { NO: 10, SN: 12, AREA: '152400', SEI: '181200', CC: 'HZDJ', JIANLI: 'TJU',    SEI_ENG: 'FF & Gas Protection Station',                                       SEI_CHI: '消防及气防站', },
    { NO: 10, SN: 13, AREA: '152400', SEI: '182200', CC: 'HZDJ', JIANLI: 'TJU',    SEI_ENG: 'Hazardous waste temporary warehouse ',                              SEI_CHI: '危废暂存库', },
    { NO: 10, SN: 14, AREA: '152400', SEI: '252703', CC: 'HZDJ', JIANLI: 'TJU',    SEI_ENG: 'Truck Scale3',                                                      SEI_CHI: '汽车衡3', },
    { NO: 11, SN: 1,  AREA: '152700', SEI: '152700', CC: 'FCC',  JIANLI: 'QYECC',  SEI_ENG: 'Main plant Dispatch Area',                                          SEI_CHI: '停车场', },
    { NO: 12, SN: 1,  AREA: '152801', SEI: '152801', CC: 'SFCC', JIANLI: 'QYECC',  SEI_ENG: 'No.1 Warehouse',                                                    SEI_CHI: '1#立体仓库', },
    { NO: 13, SN: 1,  AREA: '152802', SEI: '152802', CC: 'FCC',  JIANLI: 'QYECC',  SEI_ENG: 'No.2 Warehouse',                                                    SEI_CHI: '2#立体仓库', },
    { NO: 14, SN: 1,  AREA: '170000', SEI: '124700', CC: 'SFCC', JIANLI: 'JSS',    SEI_ENG: 'Propylene Tank Farm',                                               SEI_CHI: '丙烯罐区', },
    { NO: 14, SN: 2,  AREA: '170000', SEI: '170100', CC: 'SFCC', JIANLI: 'JSS',    SEI_ENG: 'Ethylene Cryogenic Tank Farm',                                      SEI_CHI: '乙烯低温罐区', },
    { NO: 14, SN: 3,  AREA: '170000', SEI: '170300', CC: 'SFCC', JIANLI: 'JSS',    SEI_ENG: 'Pressurized Tank Farm 1',                                           SEI_CHI: '压力罐区1', },
    { NO: 14, SN: 4,  AREA: '170000', SEI: '180402', CC: 'SFCC', JIANLI: 'JSS',    SEI_ENG: 'Waste Water Pump Station 2',                                        SEI_CHI: '污水提升泵站2', },
    { NO: 14, SN: 5,  AREA: '170000', SEI: '181301', CC: 'SFCC', JIANLI: 'JSS',    SEI_ENG: 'Foam Station 1',                                                    SEI_CHI: '泡沫站1', },
    { NO: 14, SN: 6,  AREA: '170000', SEI: '270100', CC: 'SFCC', JIANLI: 'JSS',    SEI_ENG: 'Offspec Ethylene Tank Farm',                                        SEI_CHI: '不合格乙烯罐区', },
    { NO: 14, SN: 7,  AREA: '170000', SEI: '270300', CC: 'SFCC', JIANLI: 'JSS',    SEI_ENG: 'Pressurized Tank Farm 2',                                           SEI_CHI: '压力罐区2', },
    { NO: 14, SN: 8,  AREA: '170000', SEI: '370100', CC: 'SFCC', JIANLI: 'JSS',    SEI_ENG: 'Hexene-1 Tank Farm',                                                SEI_CHI: '己烯-1罐区', },
    { NO: 15, SN: 1,  AREA: '170092', SEI: '170091', CC: 'SFCC', JIANLI: 'JSS',    SEI_ENG: '7RIE 7(W/OS)',                                                      SEI_CHI: '现场机柜室', },
    { NO: 15, SN: 2,  AREA: '170092', SEI: '170092', CC: 'SFCC', JIANLI: 'JSS',    SEI_ENG: 'Tank Farm 1st Substation',                                          SEI_CHI: '罐区第一变电所', },
    { NO: 16, SN: 1,  AREA: '178000', SEI: '178000', CC: 'CNCEC', JIANLI: 'JSS',    SEI_ENG: 'Loading/Unloading Station',                                         SEI_CHI: '汽车装卸设施', },
    { NO: 16, SN: 2,  AREA: '178000', SEI: '178001', CC: 'CNCEC', JIANLI: 'JSS',    SEI_ENG: 'Station Building',                                                  SEI_CHI: '站房', },
    { NO: 17, SN: 1,  AREA: '178900', SEI: '152000', CC: 'GDHJ ', JIANLI: 'TJU',    SEI_ENG: 'Environmental Monitoring Sub-station',                              SEI_CHI: '环境监测子站', },
    { NO: 17, SN: 2,  AREA: '178900', SEI: '166800', CC: 'CNCEC', JIANLI: 'JSS',    SEI_ENG: 'Ammonia Water Tank Farm',                                           SEI_CHI: '氨水罐区', },
    { NO: 17, SN: 3,  AREA: '178900', SEI: '178600', CC: 'SYPEC', JIANLI: 'JSS',    SEI_ENG: 'Elevated Flare',                                                    SEI_CHI: '高架火炬', },
    { NO: 17, SN: 4,  AREA: '178900', SEI: '178900', CC: 'SYPEC', JIANLI: 'JSS',    SEI_ENG: 'Ground Flare',                                                      SEI_CHI: '地面火炬', },
    { NO: 17, SN: 5,  AREA: '178900', SEI: '178992', CC: 'SYPEC', JIANLI: 'JSS',    SEI_ENG: 'Substation',                                                        SEI_CHI: '变电所', },
    { NO: 18, SN: 1,  AREA: '180200', SEI: '180200', CC: 'SFCC', JIANLI: 'TJCD',   SEI_ENG: 'Cooling Water System 1',                                            SEI_CHI: '第一循环水场', },
    { NO: 19, SN: 1,  AREA: '180292', SEI: '180292', CC: 'SFCC', JIANLI: 'TJCD',   SEI_ENG: 'Substation',                                                        SEI_CHI: '变电所', },
    { NO: 20, SN: 1,  AREA: '180600', SEI: '180600', CC: 'CNCEC', JIANLI: 'JSS',    SEI_ENG: 'Storm Water Pump Station and Accident Buffer Basin',                SEI_CHI: '雨水泵站及事故水池', },
    { NO: 21, SN: 1,  AREA: '181000', SEI: '181000', CC: 'SFCC', JIANLI: 'TJCD',   SEI_ENG: 'Water and Firewater Pump Station 1',                                SEI_CHI: '第一给水及消防泵站', },
    { NO: 22, SN: 1,  AREA: '182400', SEI: '182400', CC: 'CNCEC', JIANLI: 'JSS',    SEI_ENG: 'Wastewater Pre-Treatment Unit',                                     SEI_CHI: '污水预处理场', },
    { NO: 22, SN: 2,  AREA: '182400', SEI: '182492', CC: 'CNCEC', JIANLI: 'JSS',    SEI_ENG: 'Substation',                                                        SEI_CHI: '变电所', },
    { NO: 23, SN: 1,  AREA: '183000', SEI: '183000', CC: 'HNEDI', JIANLI: 'Gosun',  SEI_ENG: 'Main Substation',                                                   SEI_CHI: '总变电站', },
    { NO: 24, SN: 1,  AREA: '184300', SEI: '184301', CC: 'SNEI', JIANLI: 'BJHX',   SEI_ENG: 'Chemical Water Treatment',                                          SEI_CHI: '化学水处理单元', },
    { NO: 24, SN: 2,  AREA: '184300', SEI: '184302', CC: 'SNEI', JIANLI: 'BJHX',   SEI_ENG: 'Condensate Water Treatment',                                        SEI_CHI: '凝结水单元', },
    { NO: 24, SN: 3,  AREA: '184300', SEI: '184303', CC: 'SNEI', JIANLI: 'BJHX',   SEI_ENG: 'Acid & Caustic Station',                                            SEI_CHI: '酸碱站', },
    { NO: 25, SN: 1,  AREA: '184800', SEI: '184891', CC: 'SNEI', JIANLI: 'BJHX',   SEI_ENG: 'RIE-6',                                                             SEI_CHI: '联合现场机柜室', },
    { NO: 25, SN: 2,  AREA: '184800', SEI: '184892', CC: 'SNEI', JIANLI: 'BJHX',   SEI_ENG: 'Substation',                                                        SEI_CHI: '变电所', },
    { NO: 26, SN: 1,  AREA: '185300', SEI: '185392', CC: 'SFCC', JIANLI: 'TJCD',   SEI_ENG: 'Substation',                                                        SEI_CHI: '变电所', },
    { NO: 26, SN: 2,  AREA: '185300', SEI: '185394', CC: 'SFCC', JIANLI: 'TJCD',   SEI_ENG: 'Operator Shelter',                                                  SEI_CHI: '外操间', },
    { NO: 27, SN: 1,  AREA: '277000', SEI: '168000', CC: 'CC7',  JIANLI: 'JSS',    SEI_ENG: 'Naphtha Tank Farm',                                                 SEI_CHI: '石脑油罐区', },
    { NO: 27, SN: 2,  AREA: '277000', SEI: '177100', CC: 'CC7',  JIANLI: 'JSS',    SEI_ENG: 'Intermediate Tank Farm 1',                                          SEI_CHI: '中间罐区1', },
    { NO: 27, SN: 3,  AREA: '277000', SEI: '179600', CC: 'CC7',  JIANLI: 'JSS',    SEI_ENG: 'Vapor Gas Recovery Unit',                                           SEI_CHI: '油气回收处理设施', },
    { NO: 27, SN: 4,  AREA: '277000', SEI: '180403', CC: 'CC7',  JIANLI: 'JSS',    SEI_ENG: 'Waste Water Pump Station 3',                                        SEI_CHI: '污水提升泵站3', },
    { NO: 27, SN: 5,  AREA: '277000', SEI: '181302', CC: 'CC7',  JIANLI: 'JSS',    SEI_ENG: 'Foam Station 2',                                                    SEI_CHI: '泡沫站2', },
    { NO: 27, SN: 6,  AREA: '277000', SEI: '181303', CC: 'CC7',  JIANLI: 'JSS',    SEI_ENG: 'Foam Station 3',                                                    SEI_CHI: '泡沫站3', },
    { NO: 27, SN: 7,  AREA: '277000', SEI: '181304', CC: 'CC7',  JIANLI: 'JSS',    SEI_ENG: 'Foam Station 4',                                                    SEI_CHI: '泡沫站4', },
    { NO: 27, SN: 8,  AREA: '277000', SEI: '277082', CC: 'CC7',  JIANLI: 'JSS',    SEI_ENG: 'Tank Farm 2nd Substation',                                          SEI_CHI: '罐区第二变电所', },
    { NO: 28, SN: 1,  AREA: '277100', SEI: '170800', CC: 'WDECE', JIANLI: 'JSS',    SEI_ENG: 'Mogas Tank Farm',                                                   SEI_CHI: '汽油调和罐区', },
    { NO: 28, SN: 2,  AREA: '277100', SEI: '277100', CC: 'WDECE', JIANLI: 'JSS',    SEI_ENG: 'Intermediate Tank Farm 2',                                          SEI_CHI: '中间罐区2', },
    { NO: 28, SN: 3,  AREA: '277100', SEI: '377100', CC: 'WDECE', JIANLI: 'JSS',    SEI_ENG: 'Intermediate Tank Farm 3',                                          SEI_CHI: '中间罐区3', },
    { NO: 29, SN: 1,  AREA: '280200', SEI: '280292', CC: 'SFCC', JIANLI: 'TJCD',   SEI_ENG: 'Substation',                                                        SEI_CHI: '变电所', },
    { NO: 29, SN: 2,  AREA: '280200', SEI: '280294', CC: 'SFCC', JIANLI: 'TJCD',   SEI_ENG: 'Operator Shelter',                                                  SEI_CHI: '外操间', },
    { NO: 30, SN: 1,  AREA: '281000', SEI: '281000', CC: 'CNCEC', JIANLI: 'JSS',    SEI_ENG: 'Water and Firewater Pump Station 2',                                SEI_CHI: '第二给水及消防泵站', },

  ],
}