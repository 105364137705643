import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1400,
      CanvasHeight: 200,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },
    DataItems:  [
      {DEV_GROUP:"Total",ISSUED:354,RESPONDED:283,OPEN:61,OPEN_NOT_OVERDUE:37,OPEN_OVERDUE:24,CANCELLED:10},
      {DEV_GROUP:"EM to SEI",ISSUED:229,RESPONDED:179,OPEN:44,OPEN_NOT_OVERDUE:22,OPEN_OVERDUE:22,CANCELLED:6},
      {DEV_GROUP:"SEI to EM",ISSUED:125,RESPONDED:104,OPEN:17,OPEN_NOT_OVERDUE:15,OPEN_OVERDUE:2,CANCELLED:4}
    ],
}