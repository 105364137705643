import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 960,
    CanvasHeight: 500,
    CanvasBorderWeight: 0.5,
    CanvasBorderColor: '#f5f5f5',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.2,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 960,
    CanvasChartHeight: 500,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: 'Transparent',
    CanvasChartFillColor: 'Transparent',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },

  // ----------------------------------------------- Filter Text
  Text: {
    Text: [
      { text: 'Module', x: 0, y: 0, font: 'roboto', class: 'regular', size: 20, color: '#44A9DF', align: 'start' },
    ],
    Filter: [
      { name: 'MOD'   , x: 0, y: 0, font: 'roboto', class: 'regular', size: 20, color: '#44A9DF', align: 'start' },
    ],
    Sql: [
      {
        query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
        text: 'PBF COMPESSOR AIR COOLER - TRAIN 4',
        x: 0,
        y: -20,
        font: 'roboto',
        style: 'regular',
        size: 14,
        color: '#757575',
        align: 'start'
      },
    ]
  },
  // -----------------------------------------------------------

  DataItems: [
    {NO:4,FILTER:"TOPSIDES",MODULE:"ME05",MODULE_DESCR:"E/I Building",WCCODE:"36210",WCDESCRIPTION:"Block Pre-Fabrication",CON_PROG:27.6,PLAN_SD:"2019-12-23",PLAN_FD:"2021-05-06",PLAN_PROG:82.63,ACTUAL_SD:"2020-03-13",ACTUAL_PROG:86.85},
    {NO:13,FILTER:"TOPSIDES",MODULE:"ME05",MODULE_DESCR:"E/I Building",WCCODE:"36220",WCDESCRIPTION:"Block Assembly",CON_PROG:27.6,PLAN_SD:"2020-05-26",PLAN_FD:"2021-06-30",PLAN_PROG:58.21,ACTUAL_SD:"2020-06-19",ACTUAL_PROG:56.67},
    {NO:30,FILTER:"TOPSIDES",MODULE:"ME05",MODULE_DESCR:"E/I Building",WCCODE:"23110",WCDESCRIPTION:"Structure Painting",CON_PROG:27.6,PLAN_SD:"2020-10-17",PLAN_FD:"2021-06-05",PLAN_PROG:59.88,ACTUAL_SD:"2020-10-30",ACTUAL_PROG:38.9},
    {NO:42,FILTER:"TOPSIDES",MODULE:"ME05",MODULE_DESCR:"E/I Building",WCCODE:"13420",WCDESCRIPTION:"Piping Installation",CON_PROG:27.6,PLAN_SD:"2021-03-08",PLAN_FD:"2021-09-07",PLAN_PROG:0,ACTUAL_PROG:0},
    {NO:43,FILTER:"TOPSIDES",MODULE:"ME05",MODULE_DESCR:"E/I Building",WCCODE:"13900",WCDESCRIPTION:"Hydro Test",CON_PROG:27.6,PLAN_SD:"2021-05-17",PLAN_FD:"2021-10-09",PLAN_PROG:0,ACTUAL_PROG:0},
    {NO:62,FILTER:"TOPSIDES",MODULE:"ME05",MODULE_DESCR:"E/I Building",WCCODE:"16430",WCDESCRIPTION:"Cable Tray",CON_PROG:27.6,PLAN_SD:"2021-03-23",PLAN_FD:"2021-06-17",PLAN_PROG:0,ACTUAL_PROG:0},

    // {NO:5,FILTER:"TOPSIDES",MODULE:"MW01",MODULE_DESCR:"Reception Facilities & Production Separation",WCCODE:"36210",WCDESCRIPTION:"Block Pre-Fabrication",CON_PROG:12.29,PLAN_SD:"2020-02-25",PLAN_FD:"2021-07-12",PLAN_PROG:64.46,ACTUAL_SD:"2020-03-13",ACTUAL_PROG:62.21},
    // {NO:12,FILTER:"TOPSIDES",MODULE:"MW01",MODULE_DESCR:"Reception Facilities & Production Separation",WCCODE:"36220",WCDESCRIPTION:"Block Assembly",CON_PROG:12.29,PLAN_SD:"2020-07-15",PLAN_FD:"2021-10-15",PLAN_PROG:25.55,ACTUAL_SD:"2020-07-17",ACTUAL_PROG:27.1},
    // {NO:31,FILTER:"TOPSIDES",MODULE:"MW01",MODULE_DESCR:"Reception Facilities & Production Separation",WCCODE:"23110",WCDESCRIPTION:"Structure Painting",CON_PROG:12.29,PLAN_SD:"2020-12-24",PLAN_FD:"2021-08-13",PLAN_PROG:2.45,ACTUAL_SD:"2020-11-27",ACTUAL_PROG:3.69},
    // {NO:37,FILTER:"TOPSIDES",MODULE:"MW01",MODULE_DESCR:"Reception Facilities & Production Separation",WCCODE:"13420",WCDESCRIPTION:"Piping Installation",CON_PROG:12.29,PLAN_SD:"2021-03-17",PLAN_FD:"2022-01-25",PLAN_PROG:0,ACTUAL_PROG:0},
    // {NO:48,FILTER:"TOPSIDES",MODULE:"MW01",MODULE_DESCR:"Reception Facilities & Production Separation",WCCODE:"13900",WCDESCRIPTION:"Hydro Test",CON_PROG:12.29,PLAN_SD:"2021-05-11",PLAN_FD:"2022-03-21",PLAN_PROG:0,ACTUAL_PROG:0},
    // {NO:57,FILTER:"TOPSIDES",MODULE:"MW01",MODULE_DESCR:"Reception Facilities & Production Separation",WCCODE:"16430",WCDESCRIPTION:"Cable Tray",CON_PROG:12.29,PLAN_SD:"2021-05-17",PLAN_FD:"2021-08-30",PLAN_PROG:0,ACTUAL_PROG:0}
  ],
}