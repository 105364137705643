import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 2200,
    CanvasHeight: 5000,
    CanvasBorderWeight: 1,
    CanvasBorderColor: '#000',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.2,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 860,
    CanvasChartHeight: 310,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: 'Transparent',
    CanvasChartFillColor: 'Transparent',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },

  // ----------------------------------------------- Filter Text
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },
  // -----------------------------------------------------------
  
  Queries: {
    SQL1:  [{
      START_DATE:"2021-04-01",
      END_DATE:"2024-09-30",
      CUTOFF:"2023-01-01",
      PACKAGES: [
        {LEVEL:0,P6_LV1:"P1"},
        {LEVEL:0,P6_LV1:"P2"},
        {LEVEL:0,P6_LV1:"P3"},
        {LEVEL:0,P6_LV1:"OP"}
      ],
      AREAS: [
        {LEVEL:1,P6_LV1:"P1",P6_LV2:"DEM"},
        {LEVEL:1,P6_LV1:"P1",P6_LV2:"BOILER"},
        {LEVEL:1,P6_LV1:"P1",P6_LV2:"FUR"},
        {LEVEL:1,P6_LV1:"P1",P6_LV2:"OR"},
        {LEVEL:1,P6_LV1:"P1",P6_LV2:"BD"},
        {LEVEL:1,P6_LV1:"P1",P6_LV2:"BTX"},
        {LEVEL:1,P6_LV1:"P1",P6_LV2:"DIB"},
        {LEVEL:1,P6_LV1:"P1",P6_LV2:"GHU"},
        {LEVEL:1,P6_LV1:"P1",P6_LV2:"PBL"},
        {LEVEL:1,P6_LV1:"P2",P6_LV2:"PBL"},
        {LEVEL:1,P6_LV1:"P2",P6_LV2:"PP"},
        {LEVEL:1,P6_LV1:"P2",P6_LV2:"LDPE"},
        {LEVEL:1,P6_LV1:"P2",P6_LV2:"GPPE"},
        {LEVEL:1,P6_LV1:"P3",P6_LV2:"Control Center Building"},
        {LEVEL:1,P6_LV1:"P3",P6_LV2:"Area B+ Substation"},
        {LEVEL:1,P6_LV1:"P3",P6_LV2:"220KV Main Substation"},
        {LEVEL:1,P6_LV1:"P3",P6_LV2:"B+ Firewater Pump Station"},
        {LEVEL:1,P6_LV1:"P3",P6_LV2:"P3-Overall General Layout"},
        {LEVEL:1,P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit-update"},
        {LEVEL:1,P6_LV1:"P3",P6_LV2:"FF & Gas Protection Station"},
        {LEVEL:1,P6_LV1:"P3",P6_LV2:"Hazardous waste temporary warehouse"},
        {LEVEL:1,P6_LV1:"P3",P6_LV2:"B+ Area Common Area (Waste Water Pump Station 1)"},
        {LEVEL:1,P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 1"},
        {LEVEL:1,P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 2"},
        {LEVEL:1,P6_LV1:"P3",P6_LV2:"Warehouse Management Room and Spare Parts Warehouse"},
        {LEVEL:1,P6_LV1:"P3",P6_LV2:"Storm Water Pump Staton  and  Accident  Buffer  Basin"},
        {LEVEL:1,P6_LV1:"P3",P6_LV2:"Chemicals Storage Warehouse"},
        {LEVEL:1,P6_LV1:"P3",P6_LV2:"Air Compression Station"},
        {LEVEL:1,P6_LV1:"P3",P6_LV2:"Administration Office Building"},
        {LEVEL:1,P6_LV1:"P3",P6_LV2:"Eastward Pipeline"},
        {LEVEL:1,P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit"},
        {LEVEL:1,P6_LV1:"P3",P6_LV2:"Maintenance Workshop"},
        {LEVEL:1,P6_LV1:"P3",P6_LV2:"Central Laboratory and Environmental Monitoring Station"},
        {LEVEL:1,P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1"},
        {LEVEL:1,P6_LV1:"P3",P6_LV2:"Cooling Water Plant 2"},
        {LEVEL:1,P6_LV1:"P3",P6_LV2:"Loading / Unloading Station"},
        {LEVEL:1,P6_LV1:"P3",P6_LV2:"Elevated Flare System"},
        {LEVEL:1,P6_LV1:"P3",P6_LV2:"Ground Flare"},
        {LEVEL:1,P6_LV1:"P3",P6_LV2:"Ethylene Cryogenic Tank Farm"},
        {LEVEL:1,P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area"},
        {LEVEL:1,P6_LV1:"P3",P6_LV2:"Overall Interconnecting Network & EIT"},
        {LEVEL:1,P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm"},
        {LEVEL:1,P6_LV1:"OP",P6_LV2:"Section A"},
        {LEVEL:1,P6_LV1:"OP",P6_LV2:"Section B"},
        {LEVEL:1,P6_LV1:"OP",P6_LV2:"Section C"},
        {LEVEL:1,P6_LV1:"OP",P6_LV2:"Section D"},
        {LEVEL:1,P6_LV1:"OP",P6_LV2:"Civil Work"},
        {LEVEL:1,P6_LV1:"OP",P6_LV2:"Commissioning"},
        {LEVEL:1,P6_LV1:"OP",P6_LV2:"Components Prefabrication"},
        {LEVEL:1,P6_LV1:"OP",P6_LV2:"Construction Material"},
        {LEVEL:1,P6_LV1:"OP",P6_LV2:"Construction Preparation"},
        {LEVEL:1,P6_LV1:"OP",P6_LV2:"Dredging"},
        {LEVEL:1,P6_LV1:"OP",P6_LV2:"General"},
        {LEVEL:1,P6_LV1:"OP",P6_LV2:"Navigation System Installation"},
        {LEVEL:1,P6_LV1:"OP",P6_LV2:"NO WBS"},
        {LEVEL:1,P6_LV1:"OP",P6_LV2:"Pile Trial"},
        {LEVEL:1,P6_LV1:"OP",P6_LV2:"Pipeline Pressure test"},
        {LEVEL:1,P6_LV1:"OP",P6_LV2:"Project Material"},
        {LEVEL:1,P6_LV1:"OP",P6_LV2:"Safety sign and device"},
        {LEVEL:1,P6_LV1:"OP",P6_LV2:"Section E"},
        {LEVEL:1,P6_LV1:"OP",P6_LV2:"Sub-contracting"},
        {LEVEL:1,P6_LV1:"OP",P6_LV2:"System Completion & Handover Acceptance"},
        {LEVEL:1,P6_LV1:"OP",P6_LV2:"Temporary Construction Work"}
      ],
      PHASES: [
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"PBL",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P2",P6_LV2:"PBL",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P2",P6_LV2:"PBL",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P2",P6_LV2:"PBL",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P2",P6_LV2:"PP",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P2",P6_LV2:"PP",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P2",P6_LV2:"PP",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P2",P6_LV2:"LDPE",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P2",P6_LV2:"LDPE",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P2",P6_LV2:"LDPE",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P2",P6_LV2:"GPPE",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P2",P6_LV2:"GPPE",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P2",P6_LV2:"GPPE",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Control Center Building",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Control Center Building",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Control Center Building",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Area B+ Substation",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Area B+ Substation",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Area B+ Substation",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"220KV Main Substation",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"220KV Main Substation",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"220KV Main Substation",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"B+ Firewater Pump Station",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"B+ Firewater Pump Station",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"B+ Firewater Pump Station",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"P3-Overall General Layout",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"P3-Overall General Layout",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"P3-Overall General Layout",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit-update",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit-update",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"FF & Gas Protection Station",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"FF & Gas Protection Station",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"FF & Gas Protection Station",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Hazardous waste temporary warehouse",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Hazardous waste temporary warehouse",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Hazardous waste temporary warehouse",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"B+ Area Common Area (Waste Water Pump Station 1)",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"B+ Area Common Area (Waste Water Pump Station 1)",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"B+ Area Common Area (Waste Water Pump Station 1)",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 1",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 1",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 1",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 2",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 2",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 2",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Warehouse Management Room and Spare Parts Warehouse",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Warehouse Management Room and Spare Parts Warehouse",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Warehouse Management Room and Spare Parts Warehouse",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Storm Water Pump Staton  and  Accident  Buffer  Basin",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Storm Water Pump Staton  and  Accident  Buffer  Basin",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Storm Water Pump Staton  and  Accident  Buffer  Basin",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Chemicals Storage Warehouse",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Chemicals Storage Warehouse",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Chemicals Storage Warehouse",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Air Compression Station",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Air Compression Station",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Air Compression Station",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Administration Office Building",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Administration Office Building",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Administration Office Building",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Eastward Pipeline",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Eastward Pipeline",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Eastward Pipeline",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Maintenance Workshop",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Maintenance Workshop",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Maintenance Workshop",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Central Laboratory and Environmental Monitoring Station",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Central Laboratory and Environmental Monitoring Station",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Central Laboratory and Environmental Monitoring Station",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Cooling Water Plant 2",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Cooling Water Plant 2",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Cooling Water Plant 2",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Elevated Flare System",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Elevated Flare System",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Elevated Flare System",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Ground Flare",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Ground Flare",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Ground Flare",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Ethylene Cryogenic Tank Farm",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Ethylene Cryogenic Tank Farm",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Ethylene Cryogenic Tank Farm",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Overall Interconnecting Network & EIT",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Overall Interconnecting Network & EIT",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Overall Interconnecting Network & EIT",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"OP",P6_LV2:"Section A",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"OP",P6_LV2:"Section B",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"OP",P6_LV2:"Section C",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"OP",P6_LV2:"Section D",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"OP",P6_LV2:"NO WBS",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"OP",P6_LV2:"Construction Material",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"OP",P6_LV2:"NO WBS",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"OP",P6_LV2:"Project Material",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"OP",P6_LV2:"Sub-contracting",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"OP",P6_LV2:"Civil Work",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"OP",P6_LV2:"Commissioning",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"OP",P6_LV2:"Components Prefabrication",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"OP",P6_LV2:"Construction Preparation",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"OP",P6_LV2:"Dredging",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"OP",P6_LV2:"General",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"OP",P6_LV2:"Navigation System Installation",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"OP",P6_LV2:"NO WBS",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"OP",P6_LV2:"Pile Trial",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"OP",P6_LV2:"Pipeline Pressure test",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"OP",P6_LV2:"Safety sign and device",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"OP",P6_LV2:"Section E",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"OP",P6_LV2:"System Completion & Handover Acceptance",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"OP",P6_LV2:"Temporary Construction Work",P6_LV3:"Construction"}
      ]
    }
  ]
  },
  DataItems: [
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P1",P6_LV5:"Mechanical",LEVEL:"0",ITEM_DESC:"MECH",MS_PLAN:"2021-05-21",MS_ACT:"2021-05-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P1",P6_LV5:"Piping",LEVEL:"0",ITEM_DESC:"PIPE",MS_PLAN:"2021-11-27",MS_ACT:"2021-11-27",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P1",P6_LV5:"Structure",LEVEL:"0",ITEM_DESC:"STL",MS_PLAN:"2022-02-07",MS_ACT:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P1",P6_LV5:"Civil",LEVEL:"0",ITEM_DESC:"CIVIL",MS_PLAN:"2021-09-18",MS_ACT:"2021-09-18",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P1",P6_LV5:"Electrical",LEVEL:"0",ITEM_DESC:"ELECT",MS_PLAN:"2022-02-07",MS_ACT:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P1",P6_LV5:"MC",LEVEL:"0",ITEM_DESC:"MC",MS_PLAN:"2024-10-31",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {ACTIVITY_ID:"P1",ACTIVITY_NAME:"P1",BL0_SD:"2021-05-21",BL0_FD:"2024-10-12",PLAN_SD:"2021-05-21",PLAN_FD:"2024-09-30",ACT_SD:"2021-05-21",P6_LV1:"P1",LEVEL:"0",ITEM_DESC:"Overall",ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"Engineering",ACTIVITY_NAME:"Engineering",BL0_SD:"2021-05-21",BL0_FD:"2024-05-27",PLAN_SD:"2021-05-21",PLAN_FD:"2024-06-11",ACT_SD:"2021-05-21",P6_LV1:"P1",LEVEL:"0",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"Procurement",ACTIVITY_NAME:"Procurement",BL0_SD:"2021-06-01",BL0_FD:"2024-07-26",PLAN_SD:"2021-06-01",PLAN_FD:"2024-06-22",ACT_SD:"2021-06-01",P6_LV1:"P1",LEVEL:"0",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"Construction",ACTIVITY_NAME:"Construction",BL0_SD:"2021-12-24",BL0_FD:"2024-10-12",PLAN_SD:"2021-12-24",PLAN_FD:"2024-09-30",ACT_SD:"2021-12-24",P6_LV1:"P1",LEVEL:"0",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P1",P6_LV2:"DEM",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P1",P6_LV2:"DEM",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P1",P6_LV2:"DEM",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2021-09-29",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P1",P6_LV2:"DEM",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P1",P6_LV2:"DEM",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-05-17",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P1",P6_LV2:"DEM",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2024-03-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {BL0_SD:"2021-09-29",BL0_FD:"2024-03-21",PLAN_SD:"2021-09-29",PLAN_FD:"2024-03-21",ACT_SD:"2021-09-29",P6_LV1:"P1",P6_LV2:"DEM",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"DEM",ACTIVITY_NAME:"DEM",BL0_SD:"2021-09-29",BL0_FD:"2023-06-16",PLAN_SD:"2021-09-29",PLAN_FD:"2023-08-02",ACT_SD:"2021-09-29",P6_LV1:"P1",P6_LV2:"DEM",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"DEM",ACTIVITY_NAME:"DEM",BL0_SD:"2021-12-12",BL0_FD:"2023-08-01",PLAN_SD:"2021-12-12",PLAN_FD:"2023-09-08",ACT_SD:"2021-12-12",P6_LV1:"P1",P6_LV2:"DEM",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"DEM",ACTIVITY_NAME:"DEM",BL0_SD:"2022-04-30",BL0_FD:"2024-03-21",PLAN_SD:"2022-04-29",PLAN_FD:"2024-03-21",ACT_SD:"2022-04-29",P6_LV1:"P1",P6_LV2:"DEM",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-12-20",BL0_FD:"2023-05-05",PLAN_SD:"2021-12-20",PLAN_FD:"2023-08-02",ACT_SD:"2021-12-20",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-02-07",BL0_FD:"2022-07-26",PLAN_SD:"2022-02-07",PLAN_FD:"2022-12-24",ACT_SD:"2022-02-07",ACT_FD:"2022-12-24",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-05-17",BL0_FD:"2022-12-21",PLAN_SD:"2022-03-14",PLAN_FD:"2023-02-06",ACT_SD:"2022-03-14",ACT_FD:"2022-12-15",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-02-07",BL0_FD:"2023-06-02",PLAN_SD:"2022-02-07",PLAN_FD:"2023-07-05",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV3_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-09-29",BL0_FD:"2023-05-19",PLAN_SD:"2021-09-29",PLAN_FD:"2023-07-05",ACT_SD:"2021-09-29",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV3_SEQ:2,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-02-07",BL0_FD:"2023-06-16",PLAN_SD:"2022-02-07",PLAN_FD:"2023-06-22",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-08-10",BL0_FD:"2023-06-02",PLAN_SD:"2022-08-10",PLAN_FD:"2023-06-12",ACT_SD:"2022-08-10",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Engineering",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-03-07",BL0_FD:"2023-06-02",PLAN_SD:"2022-03-07",PLAN_FD:"2023-08-02",ACT_SD:"2022-03-07",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV3_SEQ:2,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-10-19",BL0_FD:"2022-10-19",PLAN_SD:"2022-11-18",PLAN_FD:"2023-03-25",ACT_SD:"2022-11-18",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Procurement",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV3_SEQ:3,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-04-30",BL0_FD:"2023-08-01",PLAN_SD:"2022-04-30",PLAN_FD:"2023-09-08",ACT_SD:"2022-04-30",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-12-12",BL0_FD:"2023-06-15",PLAN_SD:"2021-12-12",PLAN_FD:"2023-07-25",ACT_SD:"2021-12-12",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-08-05",BL0_FD:"2023-02-16",PLAN_SD:"2022-05-07",PLAN_FD:"2023-08-13",ACT_SD:"2022-05-07",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-04-02",BL0_FD:"2023-08-01",PLAN_SD:"2022-04-02",PLAN_FD:"2023-08-23",ACT_SD:"2022-04-02",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-04-30",BL0_FD:"2022-11-11",PLAN_SD:"2022-04-29",PLAN_FD:"2023-03-19",ACT_SD:"2022-04-29",ACT_FD:"2022-12-15",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-12-21",BL0_FD:"2023-08-01",PLAN_SD:"2023-02-24",PLAN_FD:"2023-09-29",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Construction",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV3_SEQ:4,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-11-10",BL0_FD:"2024-02-19",PLAN_SD:"2022-11-25",PLAN_FD:"2024-03-21",ACT_SD:"2022-11-25",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2023-02-10",BL0_FD:"2023-05-05",PLAN_SD:"2023-02-23",PLAN_FD:"2023-09-05",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV3_SEQ:4,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2023-08-25",BL0_FD:"2024-02-21",PLAN_SD:"2023-08-09",PLAN_FD:"2024-02-27",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-08-02",BL0_FD:"2024-01-06",PLAN_SD:"2023-07-22",PLAN_FD:"2024-02-08",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV3_SEQ:4,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Painting",ACTIVITY_NAME:"Painting",BL0_SD:"2023-09-16",BL0_FD:"2023-09-16",PLAN_SD:"2023-09-30",PLAN_FD:"2023-12-02",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Construction",P6_LV5:"Painting",LEVEL:"2",ITEM_DESC:"Painting",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV3_SEQ:4,P6_LV5_SEQ:50,ITEM_SEQ:50},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",BL0_SD:"2024-02-19",BL0_FD:"2024-03-21",PLAN_SD:"2024-02-20",PLAN_FD:"2024-03-21",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2022-01-30",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2021-06-15",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-01-05",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2024-04-05",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {BL0_SD:"2021-06-15",BL0_FD:"2024-04-04",PLAN_SD:"2021-06-15",PLAN_FD:"2024-04-01",ACT_SD:"2021-06-15",P6_LV1:"P1",P6_LV2:"BOILER",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"BOILER",ACTIVITY_NAME:"BOILER",BL0_SD:"2021-06-15",BL0_FD:"2023-06-15",PLAN_SD:"2021-06-15",PLAN_FD:"2023-06-30",ACT_SD:"2021-06-15",P6_LV1:"P1",P6_LV2:"BOILER",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"BOILER",ACTIVITY_NAME:"BOILER",BL0_SD:"2021-10-08",BL0_FD:"2023-08-19",PLAN_SD:"2021-10-08",PLAN_FD:"2023-10-18",ACT_SD:"2021-10-08",P6_LV1:"P1",P6_LV2:"BOILER",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"BOILER",ACTIVITY_NAME:"BOILER",BL0_SD:"2022-07-01",BL0_FD:"2024-04-04",PLAN_SD:"2022-07-11",PLAN_FD:"2024-04-01",ACT_SD:"2022-07-11",P6_LV1:"P1",P6_LV2:"BOILER",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-12-06",BL0_FD:"2023-04-28",PLAN_SD:"2021-12-06",PLAN_FD:"2023-06-30",ACT_SD:"2021-12-06",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-01-30",BL0_FD:"2022-08-24",PLAN_SD:"2022-01-30",PLAN_FD:"2022-12-24",ACT_SD:"2022-01-30",ACT_FD:"2022-12-24",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-03-21",BL0_FD:"2023-03-08",PLAN_SD:"2022-03-21",PLAN_FD:"2023-04-10",ACT_SD:"2022-03-21",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Engineering",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV3_SEQ:2,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-02-07",BL0_FD:"2022-12-13",PLAN_SD:"2022-02-07",PLAN_FD:"2023-01-30",ACT_SD:"2022-02-07",ACT_FD:"2022-08-19",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-01-05",BL0_FD:"2023-06-05",PLAN_SD:"2022-01-05",PLAN_FD:"2023-06-27",ACT_SD:"2022-01-05",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV3_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-06-15",BL0_FD:"2023-05-05",PLAN_SD:"2021-06-15",PLAN_FD:"2023-06-30",ACT_SD:"2021-06-15",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV3_SEQ:2,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-02-07",BL0_FD:"2023-06-15",PLAN_SD:"2022-02-07",PLAN_FD:"2023-06-29",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-06-01",BL0_FD:"2023-05-22",PLAN_SD:"2022-05-30",PLAN_FD:"2023-05-25",ACT_SD:"2022-05-30",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Engineering",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-02-18",BL0_FD:"2023-06-05",PLAN_SD:"2022-02-18",PLAN_FD:"2023-06-28",ACT_SD:"2022-02-18",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV3_SEQ:2,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-09-17",BL0_FD:"2022-09-17",PLAN_SD:"2022-10-31",PLAN_FD:"2023-03-07",ACT_SD:"2022-10-31",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Procurement",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV3_SEQ:3,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-04-30",BL0_FD:"2023-08-19",PLAN_SD:"2022-04-30",PLAN_FD:"2023-09-30",ACT_SD:"2022-04-30",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-10-08",BL0_FD:"2023-06-30",PLAN_SD:"2021-10-08",PLAN_FD:"2023-09-15",ACT_SD:"2021-10-08",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-08-04",BL0_FD:"2023-04-16",PLAN_SD:"2022-07-18",PLAN_FD:"2023-10-18",ACT_SD:"2022-07-18",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-03-18",BL0_FD:"2023-07-20",PLAN_SD:"2022-03-18",PLAN_FD:"2023-08-12",ACT_SD:"2022-03-18",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-07-01",BL0_FD:"2022-09-25",PLAN_SD:"2022-07-11",PLAN_FD:"2023-04-11",ACT_SD:"2022-07-11",ACT_FD:"2022-11-29",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-09-21",BL0_FD:"2023-05-22",PLAN_SD:"2022-08-12",PLAN_FD:"2023-05-27",ACT_SD:"2022-08-12",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Construction",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV3_SEQ:4,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-12-21",BL0_FD:"2023-03-01",PLAN_SD:"2023-02-11",PLAN_FD:"2023-05-06",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Construction",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV3_SEQ:4,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-11-22",BL0_FD:"2024-03-07",PLAN_SD:"2022-11-28",PLAN_FD:"2024-03-30",ACT_SD:"2022-11-28",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2023-03-31",BL0_FD:"2023-11-22",PLAN_SD:"2023-04-01",PLAN_FD:"2024-01-24",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV3_SEQ:4,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2023-05-31",BL0_FD:"2024-02-19",PLAN_SD:"2023-06-16",PLAN_FD:"2024-03-29",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-06-29",BL0_FD:"2024-03-11",PLAN_SD:"2023-07-10",PLAN_FD:"2024-03-29",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV3_SEQ:4,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",BL0_SD:"2024-03-07",BL0_FD:"2024-04-04",PLAN_SD:"2024-03-07",PLAN_FD:"2024-04-01",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P1",P6_LV2:"FUR",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2021-11-26",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P1",P6_LV2:"FUR",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-05-25",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P1",P6_LV2:"FUR",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2021-06-18",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P1",P6_LV2:"FUR",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2021-11-27",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P1",P6_LV2:"FUR",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P1",P6_LV2:"FUR",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2024-10-31",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {BL0_SD:"2021-06-18",BL0_FD:"2024-09-05",PLAN_SD:"2021-06-18",PLAN_FD:"2024-09-30",ACT_SD:"2021-06-18",P6_LV1:"P1",P6_LV2:"FUR",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"FUR",ACTIVITY_NAME:"FUR",BL0_SD:"2021-09-01",BL0_FD:"2023-10-09",PLAN_SD:"2021-09-01",PLAN_FD:"2023-10-20",ACT_SD:"2021-09-01",P6_LV1:"P1",P6_LV2:"FUR",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"FUR",ACTIVITY_NAME:"FUR",BL0_SD:"2021-06-18",BL0_FD:"2024-01-24",PLAN_SD:"2021-06-18",PLAN_FD:"2024-02-16",ACT_SD:"2021-06-18",P6_LV1:"P1",P6_LV2:"FUR",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"FUR",ACTIVITY_NAME:"FUR",BL0_SD:"2021-12-24",BL0_FD:"2024-09-05",PLAN_SD:"2021-12-24",PLAN_FD:"2024-09-30",ACT_SD:"2021-12-24",P6_LV1:"P1",P6_LV2:"FUR",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2022-01-29",BL0_FD:"2023-08-15",PLAN_SD:"2022-01-29",PLAN_FD:"2023-10-13",ACT_SD:"2022-01-29",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2021-11-26",BL0_FD:"2022-07-25",PLAN_SD:"2021-11-26",PLAN_FD:"2022-11-27",ACT_SD:"2021-11-26",ACT_FD:"2022-11-27",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-08-29",BL0_FD:"2023-04-25",PLAN_SD:"2022-07-29",PLAN_FD:"2023-06-23",ACT_SD:"2022-07-29",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Engineering",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV3_SEQ:2,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-02-07",BL0_FD:"2023-04-25",PLAN_SD:"2022-02-07",PLAN_FD:"2023-06-30",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-01-25",BL0_FD:"2023-09-04",PLAN_SD:"2022-01-25",PLAN_FD:"2023-10-13",ACT_SD:"2022-01-25",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV3_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-09-01",BL0_FD:"2023-08-16",PLAN_SD:"2021-09-01",PLAN_FD:"2023-09-12",ACT_SD:"2021-09-01",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV3_SEQ:2,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-05-25",BL0_FD:"2023-09-10",PLAN_SD:"2022-05-25",PLAN_FD:"2023-09-29",ACT_SD:"2022-05-25",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-02-16",BL0_FD:"2023-10-09",PLAN_SD:"2022-02-16",PLAN_FD:"2023-10-20",ACT_SD:"2022-02-16",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV3_SEQ:2,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-08-17",BL0_FD:"2022-10-01",PLAN_SD:"2022-08-17",PLAN_FD:"2023-10-09",ACT_SD:"2022-08-17",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Procurement",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV3_SEQ:3,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2021-11-27",BL0_FD:"2023-12-04",PLAN_SD:"2021-11-27",PLAN_FD:"2024-02-16",ACT_SD:"2021-11-27",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-06-18",BL0_FD:"2024-01-24",PLAN_SD:"2021-06-18",PLAN_FD:"2023-12-30",ACT_SD:"2021-06-18",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-07-26",BL0_FD:"2023-09-30",PLAN_SD:"2022-07-26",PLAN_FD:"2024-01-05",ACT_SD:"2022-07-26",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-03-30",BL0_FD:"2024-01-22",PLAN_SD:"2023-03-30",PLAN_FD:"2024-01-29",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2021-12-24",BL0_FD:"2022-10-01",PLAN_SD:"2021-12-24",PLAN_FD:"2023-02-28",ACT_SD:"2021-12-24",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-10-08",BL0_FD:"2023-09-18",PLAN_SD:"2022-10-06",PLAN_FD:"2024-09-30",ACT_SD:"2022-10-06",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Construction",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV3_SEQ:4,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-08-03",BL0_FD:"2024-07-27",PLAN_SD:"2022-08-25",PLAN_FD:"2024-09-28",ACT_SD:"2022-08-25",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-09-30",BL0_FD:"2024-04-28",PLAN_SD:"2022-10-05",PLAN_FD:"2024-03-12",ACT_SD:"2022-10-05",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV3_SEQ:4,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-08-30",BL0_FD:"2024-08-06",PLAN_SD:"2022-08-23",PLAN_FD:"2024-09-25",ACT_SD:"2022-08-23",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-05-29",BL0_FD:"2024-09-05",PLAN_SD:"2023-05-29",PLAN_FD:"2024-09-20",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV3_SEQ:4,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",PLAN_FD:"2024-09-30",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P1",P6_LV2:"OR",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2024-10-31",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P1",P6_LV2:"OR",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2021-09-18",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P1",P6_LV2:"OR",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P1",P6_LV2:"OR",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2021-05-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P1",P6_LV2:"OR",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2021-12-02",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P1",P6_LV2:"OR",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {BL0_SD:"2021-05-21",BL0_FD:"2024-09-14",PLAN_SD:"2021-05-21",PLAN_FD:"2024-09-30",ACT_SD:"2021-05-21",P6_LV1:"P1",P6_LV2:"OR",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"OR",ACTIVITY_NAME:"OR",BL0_SD:"2021-05-21",BL0_FD:"2023-09-13",PLAN_SD:"2021-05-21",PLAN_FD:"2023-10-12",ACT_SD:"2021-05-21",P6_LV1:"P1",P6_LV2:"OR",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"OR",ACTIVITY_NAME:"OR",BL0_SD:"2021-06-01",BL0_FD:"2024-04-24",PLAN_SD:"2021-06-01",PLAN_FD:"2024-06-18",ACT_SD:"2021-06-01",P6_LV1:"P1",P6_LV2:"OR",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"OR",ACTIVITY_NAME:"OR",BL0_SD:"2022-01-04",BL0_FD:"2024-09-14",PLAN_SD:"2022-01-04",PLAN_FD:"2024-09-30",ACT_SD:"2022-01-04",P6_LV1:"P1",P6_LV2:"OR",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-12-02",BL0_FD:"2023-07-27",PLAN_SD:"2021-12-02",PLAN_FD:"2023-10-12",ACT_SD:"2021-12-02",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2021-09-18",BL0_FD:"2022-11-08",PLAN_SD:"2021-09-18",PLAN_FD:"2023-05-10",ACT_SD:"2021-09-18",ACT_FD:"2022-12-25",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-03-15",BL0_FD:"2022-10-21",PLAN_SD:"2022-03-15",PLAN_FD:"2023-05-25",ACT_SD:"2022-03-15",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Engineering",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:2,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-02-07",BL0_FD:"2023-02-09",PLAN_SD:"2022-02-07",PLAN_FD:"2023-05-08",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2021-12-02",BL0_FD:"2023-09-12",PLAN_SD:"2021-12-02",PLAN_FD:"2023-08-28",ACT_SD:"2021-12-02",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-05-21",BL0_FD:"2023-07-27",PLAN_SD:"2021-05-21",PLAN_FD:"2023-07-03",ACT_SD:"2021-05-21",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:2,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-02-07",BL0_FD:"2023-09-13",PLAN_SD:"2022-02-07",PLAN_FD:"2023-09-25",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-05-31",BL0_FD:"2023-07-27",PLAN_SD:"2022-05-31",PLAN_FD:"2023-08-24",ACT_SD:"2022-05-31",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Engineering",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-01-30",BL0_FD:"2023-09-12",PLAN_SD:"2022-01-30",PLAN_FD:"2023-10-12",ACT_SD:"2022-01-30",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:2,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2023-01-06",BL0_FD:"2023-06-10",PLAN_SD:"2022-09-15",PLAN_FD:"2023-06-16",ACT_SD:"2022-09-15",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Procurement",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:3,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-01-30",BL0_FD:"2024-04-05",PLAN_SD:"2022-01-30",PLAN_FD:"2024-03-25",ACT_SD:"2022-01-30",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-06-01",BL0_FD:"2023-12-31",PLAN_SD:"2021-06-01",PLAN_FD:"2023-12-06",ACT_SD:"2021-06-01",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-03-08",BL0_FD:"2024-04-24",PLAN_SD:"2022-03-08",PLAN_FD:"2024-06-18",ACT_SD:"2022-03-08",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-12-16",BL0_FD:"2023-07-17",PLAN_SD:"2023-02-11",PLAN_FD:"2023-07-25",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Procurement",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-03-02",BL0_FD:"2024-03-27",PLAN_SD:"2022-03-02",PLAN_FD:"2024-03-19",ACT_SD:"2022-03-02",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-01-04",BL0_FD:"2023-08-28",PLAN_SD:"2022-01-04",PLAN_FD:"2023-10-23",ACT_SD:"2022-01-04",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2023-03-29",BL0_FD:"2023-11-01",PLAN_SD:"2023-02-11",PLAN_FD:"2023-11-17",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Construction",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:4,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-06-01",BL0_FD:"2024-08-19",PLAN_SD:"2022-05-08",PLAN_FD:"2024-09-28",ACT_SD:"2022-05-08",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-10-01",BL0_FD:"2024-05-09",PLAN_SD:"2022-10-27",PLAN_FD:"2024-08-21",ACT_SD:"2022-10-27",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:4,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-09-23",BL0_FD:"2024-09-14",PLAN_SD:"2022-10-28",PLAN_FD:"2024-09-13",ACT_SD:"2022-10-28",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2023-07-24",BL0_FD:"2023-07-24",PLAN_SD:"2023-07-26",PLAN_FD:"2023-09-29",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Construction",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-11-08",BL0_FD:"2024-08-14",PLAN_SD:"2023-10-28",PLAN_FD:"2024-09-23",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:4,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Painting",ACTIVITY_NAME:"Painting",BL0_SD:"2024-03-19",BL0_FD:"2024-05-31",PLAN_SD:"2024-02-26",PLAN_FD:"2024-07-16",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Construction",P6_LV5:"Painting",LEVEL:"2",ITEM_DESC:"Painting",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:4,P6_LV5_SEQ:50,ITEM_SEQ:50},
    {ACTIVITY_ID:"Insulation",ACTIVITY_NAME:"Insulation",BL0_SD:"2024-01-22",BL0_FD:"2024-06-12",PLAN_SD:"2024-01-04",PLAN_FD:"2024-07-29",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Construction",P6_LV5:"Insulation",LEVEL:"2",ITEM_DESC:"Insulation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:4,P6_LV5_SEQ:55,ITEM_SEQ:55},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",BL0_SD:"2024-03-25",BL0_FD:"2024-08-06",PLAN_SD:"2024-02-02",PLAN_FD:"2024-09-30",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P1",P6_LV2:"BD",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2021-12-01",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P1",P6_LV2:"BD",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-03-16",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P1",P6_LV2:"BD",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2021-08-03",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P1",P6_LV2:"BD",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P1",P6_LV2:"BD",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-02-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P1",P6_LV2:"BD",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2024-10-31",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {BL0_SD:"2021-08-01",BL0_FD:"2024-09-30",PLAN_SD:"2021-08-01",PLAN_FD:"2024-08-30",ACT_SD:"2021-08-01",P6_LV1:"P1",P6_LV2:"BD",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"BD",ACTIVITY_NAME:"BD",BL0_SD:"2021-08-01",BL0_FD:"2024-05-27",PLAN_SD:"2021-08-01",PLAN_FD:"2024-06-11",ACT_SD:"2021-08-01",P6_LV1:"P1",P6_LV2:"BD",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"BD",ACTIVITY_NAME:"BD",BL0_SD:"2022-02-07",BL0_FD:"2024-07-26",PLAN_SD:"2021-11-11",PLAN_FD:"2024-06-22",ACT_SD:"2021-11-11",P6_LV1:"P1",P6_LV2:"BD",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"BD",ACTIVITY_NAME:"BD",BL0_SD:"2022-02-14",BL0_FD:"2024-09-30",PLAN_SD:"2022-02-14",PLAN_FD:"2024-08-30",ACT_SD:"2022-02-14",P6_LV1:"P1",P6_LV2:"BD",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-08-01",BL0_FD:"2023-09-07",PLAN_SD:"2021-08-01",PLAN_FD:"2023-11-21",ACT_SD:"2021-08-01",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2021-12-01",BL0_FD:"2023-05-11",PLAN_SD:"2022-02-07",PLAN_FD:"2023-03-13",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-03-16",BL0_FD:"2023-07-07",PLAN_SD:"2022-03-16",PLAN_FD:"2023-07-26",ACT_SD:"2022-03-16",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Engineering",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:2,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-02-21",BL0_FD:"2023-04-27",PLAN_SD:"2022-02-21",PLAN_FD:"2023-06-15",ACT_SD:"2022-02-21",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-02-07",BL0_FD:"2024-01-15",PLAN_SD:"2022-02-07",PLAN_FD:"2024-03-07",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-08-03",BL0_FD:"2023-07-21",PLAN_SD:"2021-08-03",PLAN_FD:"2023-08-23",ACT_SD:"2021-08-03",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:2,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-03-16",BL0_FD:"2024-05-27",PLAN_SD:"2022-03-16",PLAN_FD:"2024-06-11",ACT_SD:"2022-03-16",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-12-06",BL0_FD:"2023-11-13",PLAN_SD:"2022-11-17",PLAN_FD:"2023-12-15",ACT_SD:"2022-11-17",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Engineering",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-03-07",BL0_FD:"2023-12-11",PLAN_SD:"2022-03-07",PLAN_FD:"2024-01-17",ACT_SD:"2022-03-07",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:2,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-11-23",BL0_FD:"2023-05-20",PLAN_SD:"2022-11-17",PLAN_FD:"2023-04-11",ACT_SD:"2022-11-17",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Procurement",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:3,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-03-19",BL0_FD:"2024-05-20",PLAN_SD:"2022-03-19",PLAN_FD:"2024-06-01",ACT_SD:"2022-03-19",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-02-07",BL0_FD:"2024-02-17",PLAN_SD:"2021-11-11",PLAN_FD:"2024-01-23",ACT_SD:"2021-11-11",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2023-06-06",BL0_FD:"2024-07-26",PLAN_SD:"2022-08-27",PLAN_FD:"2024-06-22",ACT_SD:"2022-08-27",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2023-05-20",BL0_FD:"2024-03-04",PLAN_SD:"2023-05-19",PLAN_FD:"2024-03-25",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Procurement",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-05-28",BL0_FD:"2024-03-17",PLAN_SD:"2022-06-08",PLAN_FD:"2024-03-29",ACT_SD:"2022-06-08",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-02-14",BL0_FD:"2024-04-16",PLAN_SD:"2022-02-14",PLAN_FD:"2024-07-02",ACT_SD:"2022-02-14",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2023-03-30",BL0_FD:"2023-11-01",PLAN_SD:"2023-04-04",PLAN_FD:"2024-01-11",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Construction",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:4,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-07-06",BL0_FD:"2024-09-30",PLAN_SD:"2022-10-08",PLAN_FD:"2024-08-30",ACT_SD:"2022-10-08",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2023-02-07",BL0_FD:"2024-01-16",PLAN_SD:"2022-12-28",PLAN_FD:"2024-03-05",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:4,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2023-10-13",BL0_FD:"2024-09-27",PLAN_SD:"2023-03-01",PLAN_FD:"2024-08-24",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2024-03-26",BL0_FD:"2024-09-26",PLAN_SD:"2024-03-25",PLAN_FD:"2024-08-28",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Construction",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-07-22",BL0_FD:"2024-09-27",PLAN_SD:"2023-06-26",PLAN_FD:"2024-08-27",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:4,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Painting",ACTIVITY_NAME:"Painting",BL0_SD:"2023-03-30",BL0_FD:"2024-08-02",PLAN_SD:"2023-05-15",PLAN_FD:"2024-08-23",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Construction",P6_LV5:"Painting",LEVEL:"2",ITEM_DESC:"Painting",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:4,P6_LV5_SEQ:50,ITEM_SEQ:50},
    {ACTIVITY_ID:"Insulation",ACTIVITY_NAME:"Insulation",BL0_SD:"2023-09-05",BL0_FD:"2024-09-20",PLAN_SD:"2023-08-18",PLAN_FD:"2024-08-13",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Construction",P6_LV5:"Insulation",LEVEL:"2",ITEM_DESC:"Insulation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:4,P6_LV5_SEQ:55,ITEM_SEQ:55},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",BL0_SD:"2024-06-18",BL0_FD:"2024-06-18",PLAN_SD:"2024-06-30",PLAN_FD:"2024-08-30",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P1",P6_LV2:"BTX",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2021-12-28",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P1",P6_LV2:"BTX",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P1",P6_LV2:"BTX",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2021-11-15",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P1",P6_LV2:"BTX",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P1",P6_LV2:"BTX",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-02-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P1",P6_LV2:"BTX",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2024-10-31",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {BL0_SD:"2021-09-16",BL0_FD:"2024-10-10",PLAN_SD:"2021-11-15",PLAN_FD:"2024-09-30",ACT_SD:"2021-11-15",P6_LV1:"P1",P6_LV2:"BTX",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"BTX",ACTIVITY_NAME:"BTX",BL0_SD:"2021-09-16",BL0_FD:"2024-03-14",PLAN_SD:"2021-11-15",PLAN_FD:"2024-05-10",ACT_SD:"2021-11-15",P6_LV1:"P1",P6_LV2:"BTX",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"BTX",ACTIVITY_NAME:"BTX",BL0_SD:"2022-02-07",BL0_FD:"2024-05-16",PLAN_SD:"2022-02-07",PLAN_FD:"2024-05-22",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"BTX",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"BTX",ACTIVITY_NAME:"BTX",BL0_SD:"2022-02-14",BL0_FD:"2024-10-10",PLAN_SD:"2022-02-14",PLAN_FD:"2024-09-30",ACT_SD:"2022-02-14",P6_LV1:"P1",P6_LV2:"BTX",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-09-16",BL0_FD:"2023-09-15",PLAN_SD:"2021-12-16",PLAN_FD:"2023-11-07",ACT_SD:"2021-12-16",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2021-12-28",BL0_FD:"2023-05-08",PLAN_SD:"2022-02-07",PLAN_FD:"2023-03-03",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-02-07",BL0_FD:"2023-07-10",PLAN_SD:"2022-02-07",PLAN_FD:"2023-08-16",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Engineering",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:2,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-02-21",BL0_FD:"2023-04-18",PLAN_SD:"2022-02-21",PLAN_FD:"2023-06-15",ACT_SD:"2022-02-21",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-02-07",BL0_FD:"2023-11-15",PLAN_SD:"2022-02-07",PLAN_FD:"2024-02-07",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-11-15",BL0_FD:"2023-09-15",PLAN_SD:"2021-11-15",PLAN_FD:"2023-06-26",ACT_SD:"2021-11-15",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:2,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-02-07",BL0_FD:"2024-03-14",PLAN_SD:"2022-02-07",PLAN_FD:"2024-05-10",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-10-10",BL0_FD:"2023-09-11",PLAN_SD:"2022-11-07",PLAN_FD:"2023-11-17",ACT_SD:"2022-11-07",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Engineering",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-03-07",BL0_FD:"2023-12-11",PLAN_SD:"2022-03-07",PLAN_FD:"2024-02-02",ACT_SD:"2022-03-07",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:2,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-11-12",BL0_FD:"2023-05-24",PLAN_SD:"2022-11-19",PLAN_FD:"2023-04-11",ACT_SD:"2022-11-19",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Procurement",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:3,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-03-19",BL0_FD:"2024-03-22",PLAN_SD:"2022-03-19",PLAN_FD:"2024-03-08",ACT_SD:"2022-03-19",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-02-07",BL0_FD:"2024-02-02",PLAN_SD:"2022-02-07",PLAN_FD:"2024-02-22",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-07-06",BL0_FD:"2024-05-16",PLAN_SD:"2022-07-20",PLAN_FD:"2024-05-22",ACT_SD:"2022-07-20",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2023-03-21",BL0_FD:"2023-12-30",PLAN_SD:"2023-04-18",PLAN_FD:"2024-02-26",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Procurement",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-05-28",BL0_FD:"2024-03-17",PLAN_SD:"2022-06-08",PLAN_FD:"2024-04-18",ACT_SD:"2022-06-08",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-02-14",BL0_FD:"2024-04-19",PLAN_SD:"2022-02-14",PLAN_FD:"2024-06-26",ACT_SD:"2022-02-14",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2023-03-20",BL0_FD:"2023-11-04",PLAN_SD:"2023-05-15",PLAN_FD:"2024-01-05",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Construction",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:4,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-07-21",BL0_FD:"2024-10-10",PLAN_SD:"2022-10-10",PLAN_FD:"2024-09-30",ACT_SD:"2022-10-10",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2023-03-27",BL0_FD:"2024-01-16",PLAN_SD:"2022-12-26",PLAN_FD:"2024-02-05",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:4,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-11-21",BL0_FD:"2024-08-13",PLAN_SD:"2022-12-09",PLAN_FD:"2024-09-13",ACT_SD:"2022-12-09",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2024-01-24",BL0_FD:"2024-08-01",PLAN_SD:"2024-02-26",PLAN_FD:"2024-09-20",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Construction",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-07-12",BL0_FD:"2024-10-10",PLAN_SD:"2023-06-26",PLAN_FD:"2024-09-26",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:4,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Painting",ACTIVITY_NAME:"Painting",BL0_SD:"2023-03-20",BL0_FD:"2024-08-20",PLAN_SD:"2023-05-15",PLAN_FD:"2024-09-05",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Construction",P6_LV5:"Painting",LEVEL:"2",ITEM_DESC:"Painting",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:4,P6_LV5_SEQ:50,ITEM_SEQ:50},
    {ACTIVITY_ID:"Insulation",ACTIVITY_NAME:"Insulation",BL0_SD:"2023-09-19",BL0_FD:"2024-08-15",PLAN_SD:"2023-09-28",PLAN_FD:"2024-08-20",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Construction",P6_LV5:"Insulation",LEVEL:"2",ITEM_DESC:"Insulation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:4,P6_LV5_SEQ:55,ITEM_SEQ:55},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",BL0_SD:"2024-05-21",BL0_FD:"2024-05-21",PLAN_SD:"2024-06-30",PLAN_FD:"2024-09-30",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P1",P6_LV2:"DIB",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2021-12-28",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P1",P6_LV2:"DIB",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-03-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P1",P6_LV2:"DIB",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2021-11-15",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P1",P6_LV2:"DIB",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P1",P6_LV2:"DIB",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-02-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P1",P6_LV2:"DIB",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2024-10-31",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {BL0_SD:"2021-09-16",BL0_FD:"2024-10-10",PLAN_SD:"2021-11-15",PLAN_FD:"2024-09-30",ACT_SD:"2021-11-15",P6_LV1:"P1",P6_LV2:"DIB",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"DIB",ACTIVITY_NAME:"DIB",BL0_SD:"2021-09-16",BL0_FD:"2024-03-14",PLAN_SD:"2021-11-15",PLAN_FD:"2024-05-31",ACT_SD:"2021-11-15",P6_LV1:"P1",P6_LV2:"DIB",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"DIB",ACTIVITY_NAME:"DIB",BL0_SD:"2022-02-07",BL0_FD:"2024-05-16",PLAN_SD:"2022-02-07",PLAN_FD:"2024-06-12",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"DIB",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"DIB",ACTIVITY_NAME:"DIB",BL0_SD:"2022-02-14",BL0_FD:"2024-10-10",PLAN_SD:"2022-02-14",PLAN_FD:"2024-09-30",ACT_SD:"2022-02-14",P6_LV1:"P1",P6_LV2:"DIB",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-09-16",BL0_FD:"2023-09-15",PLAN_SD:"2021-12-16",PLAN_FD:"2023-12-05",ACT_SD:"2021-12-16",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2021-12-28",BL0_FD:"2023-05-22",PLAN_SD:"2022-02-07",PLAN_FD:"2023-03-10",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-02-21",BL0_FD:"2023-04-18",PLAN_SD:"2022-02-21",PLAN_FD:"2023-06-15",ACT_SD:"2022-02-21",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-02-07",BL0_FD:"2023-11-15",PLAN_SD:"2022-02-07",PLAN_FD:"2024-02-07",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV3_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-11-15",BL0_FD:"2023-09-15",PLAN_SD:"2021-11-15",PLAN_FD:"2023-07-27",ACT_SD:"2021-11-15",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV3_SEQ:2,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-03-07",BL0_FD:"2024-03-14",PLAN_SD:"2022-03-07",PLAN_FD:"2024-05-31",ACT_SD:"2022-03-07",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-03-07",BL0_FD:"2023-12-11",PLAN_SD:"2022-03-07",PLAN_FD:"2024-02-02",ACT_SD:"2022-03-07",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV3_SEQ:2,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-11-12",BL0_FD:"2023-05-24",PLAN_SD:"2022-11-18",PLAN_FD:"2023-04-11",ACT_SD:"2022-11-18",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Procurement",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV3_SEQ:3,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-03-19",BL0_FD:"2024-03-22",PLAN_SD:"2022-03-19",PLAN_FD:"2024-04-04",ACT_SD:"2022-03-19",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-02-07",BL0_FD:"2024-03-12",PLAN_SD:"2022-02-07",PLAN_FD:"2024-02-10",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-08-24",BL0_FD:"2024-05-16",PLAN_SD:"2022-08-27",PLAN_FD:"2024-06-12",ACT_SD:"2022-08-27",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-05-28",BL0_FD:"2024-03-17",PLAN_SD:"2022-05-28",PLAN_FD:"2024-04-18",ACT_SD:"2022-05-28",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-02-14",BL0_FD:"2024-04-19",PLAN_SD:"2022-02-14",PLAN_FD:"2024-06-17",ACT_SD:"2022-02-14",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2023-03-20",BL0_FD:"2023-11-04",PLAN_SD:"2023-05-08",PLAN_FD:"2023-12-23",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Construction",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV3_SEQ:4,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-07-21",BL0_FD:"2024-10-10",PLAN_SD:"2022-10-10",PLAN_FD:"2024-09-30",ACT_SD:"2022-10-10",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2023-03-27",BL0_FD:"2024-01-16",PLAN_SD:"2023-03-27",PLAN_FD:"2024-02-05",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV3_SEQ:4,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2023-01-11",BL0_FD:"2024-08-13",PLAN_SD:"2023-03-24",PLAN_FD:"2024-09-06",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-07-12",BL0_FD:"2024-09-28",PLAN_SD:"2023-06-26",PLAN_FD:"2024-09-30",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV3_SEQ:4,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Painting",ACTIVITY_NAME:"Painting",BL0_SD:"2023-03-20",BL0_FD:"2024-08-20",PLAN_SD:"2023-05-09",PLAN_FD:"2024-09-05",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Construction",P6_LV5:"Painting",LEVEL:"2",ITEM_DESC:"Painting",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV3_SEQ:4,P6_LV5_SEQ:50,ITEM_SEQ:50},
    {ACTIVITY_ID:"Insulation",ACTIVITY_NAME:"Insulation",BL0_SD:"2023-09-08",BL0_FD:"2024-08-15",PLAN_SD:"2023-09-08",PLAN_FD:"2024-08-20",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Construction",P6_LV5:"Insulation",LEVEL:"2",ITEM_DESC:"Insulation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV3_SEQ:4,P6_LV5_SEQ:55,ITEM_SEQ:55},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",BL0_SD:"2024-05-21",BL0_FD:"2024-05-21",PLAN_SD:"2024-06-30",PLAN_FD:"2024-09-30",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P1",P6_LV2:"GHU",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2021-12-28",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P1",P6_LV2:"GHU",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P1",P6_LV2:"GHU",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2024-10-31",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P1",P6_LV2:"GHU",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2021-11-15",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P1",P6_LV2:"GHU",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P1",P6_LV2:"GHU",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-02-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {BL0_SD:"2021-09-16",BL0_FD:"2024-10-12",PLAN_SD:"2021-11-15",PLAN_FD:"2024-08-30",ACT_SD:"2021-11-15",P6_LV1:"P1",P6_LV2:"GHU",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"GHU",ACTIVITY_NAME:"GHU",BL0_SD:"2021-09-16",BL0_FD:"2024-03-14",PLAN_SD:"2021-11-15",PLAN_FD:"2024-05-10",ACT_SD:"2021-11-15",P6_LV1:"P1",P6_LV2:"GHU",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"GHU",ACTIVITY_NAME:"GHU",BL0_SD:"2022-02-07",BL0_FD:"2024-05-16",PLAN_SD:"2022-02-07",PLAN_FD:"2024-05-22",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"GHU",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"GHU",ACTIVITY_NAME:"GHU",BL0_SD:"2022-02-14",BL0_FD:"2024-10-12",PLAN_SD:"2022-02-14",PLAN_FD:"2024-08-30",ACT_SD:"2022-02-14",P6_LV1:"P1",P6_LV2:"GHU",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-09-16",BL0_FD:"2023-09-29",PLAN_SD:"2021-12-16",PLAN_FD:"2023-11-07",ACT_SD:"2021-12-16",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2021-12-28",BL0_FD:"2023-04-25",PLAN_SD:"2022-02-07",PLAN_FD:"2023-03-13",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-02-21",BL0_FD:"2023-04-18",PLAN_SD:"2022-02-21",PLAN_FD:"2023-06-15",ACT_SD:"2022-02-21",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-02-07",BL0_FD:"2023-11-01",PLAN_SD:"2022-02-07",PLAN_FD:"2024-02-07",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV3_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-11-15",BL0_FD:"2023-09-29",PLAN_SD:"2021-11-15",PLAN_FD:"2023-07-27",ACT_SD:"2021-11-15",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV3_SEQ:2,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-02-07",BL0_FD:"2024-03-14",PLAN_SD:"2022-02-07",PLAN_FD:"2024-05-10",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-03-07",BL0_FD:"2023-12-11",PLAN_SD:"2022-03-07",PLAN_FD:"2024-02-02",ACT_SD:"2022-03-07",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV3_SEQ:2,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-11-12",BL0_FD:"2023-05-24",PLAN_SD:"2022-11-18",PLAN_FD:"2023-04-11",ACT_SD:"2022-11-18",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Procurement",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV3_SEQ:3,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-03-19",BL0_FD:"2024-03-08",PLAN_SD:"2022-03-19",PLAN_FD:"2024-03-21",ACT_SD:"2022-03-19",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-02-07",BL0_FD:"2024-03-13",PLAN_SD:"2022-02-07",PLAN_FD:"2024-01-30",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-08-24",BL0_FD:"2024-05-16",PLAN_SD:"2022-08-24",PLAN_FD:"2024-05-22",ACT_SD:"2022-08-24",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-05-28",BL0_FD:"2024-03-16",PLAN_SD:"2022-05-28",PLAN_FD:"2024-04-18",ACT_SD:"2022-05-28",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-02-14",BL0_FD:"2024-04-19",PLAN_SD:"2022-02-14",PLAN_FD:"2024-07-12",ACT_SD:"2022-02-14",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2023-03-20",BL0_FD:"2023-11-04",PLAN_SD:"2023-05-18",PLAN_FD:"2024-01-22",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Construction",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV3_SEQ:4,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-07-21",BL0_FD:"2024-10-09",PLAN_SD:"2022-10-10",PLAN_FD:"2024-08-30",ACT_SD:"2022-10-10",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2023-03-13",BL0_FD:"2024-01-16",PLAN_SD:"2023-03-13",PLAN_FD:"2024-02-05",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV3_SEQ:4,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2023-01-11",BL0_FD:"2024-10-10",PLAN_SD:"2023-01-11",PLAN_FD:"2024-08-27",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-07-12",BL0_FD:"2024-10-12",PLAN_SD:"2023-06-26",PLAN_FD:"2024-08-28",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV3_SEQ:4,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Painting",ACTIVITY_NAME:"Painting",BL0_SD:"2023-03-20",BL0_FD:"2024-08-20",PLAN_SD:"2023-06-17",PLAN_FD:"2024-08-29",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Construction",P6_LV5:"Painting",LEVEL:"2",ITEM_DESC:"Painting",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV3_SEQ:4,P6_LV5_SEQ:50,ITEM_SEQ:50},
    {ACTIVITY_ID:"Insulation",ACTIVITY_NAME:"Insulation",BL0_SD:"2023-08-08",BL0_FD:"2024-08-15",PLAN_SD:"2023-08-31",PLAN_FD:"2024-08-20",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Construction",P6_LV5:"Insulation",LEVEL:"2",ITEM_DESC:"Insulation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV3_SEQ:4,P6_LV5_SEQ:55,ITEM_SEQ:55},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",BL0_SD:"2024-07-12",BL0_FD:"2024-07-12",PLAN_SD:"2024-06-30",PLAN_FD:"2024-08-30",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {PLAN_SD:"2023-01-07",PLAN_FD:"2023-03-30",P6_LV1:"P1",P6_LV2:"PBL",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:190,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"PBL",ACTIVITY_NAME:"PBL",PLAN_SD:"2023-01-07",PLAN_FD:"2023-03-30",P6_LV1:"P1",P6_LV2:"PBL",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:190,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Achitecture",ACTIVITY_NAME:"Achitecture",PLAN_SD:"2023-01-07",PLAN_FD:"2023-03-30",P6_LV1:"P1",P6_LV2:"PBL",P6_LV3:"Construction",P6_LV5:"Achitecture",LEVEL:"2",ITEM_DESC:"Achitecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:190,P6_LV3_SEQ:4,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P2",P6_LV5:"MC",LEVEL:"0",ITEM_DESC:"MC",MS_PLAN:"2024-09-20",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:2,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P2",P6_LV5:"Piping",LEVEL:"0",ITEM_DESC:"PIPE",MS_PLAN:"2021-05-18",MS_ACT:"2021-05-18",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P2",P6_LV5:"Mechanical",LEVEL:"0",ITEM_DESC:"MECH",MS_PLAN:"2021-04-19",MS_ACT:"2021-02-22",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:2,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P2",P6_LV5:"Civil",LEVEL:"0",ITEM_DESC:"CIVIL",MS_PLAN:"2021-12-13",MS_ACT:"2021-12-13",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P2",P6_LV5:"Structure",LEVEL:"0",ITEM_DESC:"STL",MS_PLAN:"2021-09-01",MS_ACT:"2021-09-01",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P2",P6_LV5:"Electrical",LEVEL:"0",ITEM_DESC:"ELECT",MS_PLAN:"2021-08-13",MS_ACT:"2021-08-13",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"P2",ACTIVITY_NAME:"P2",BL0_SD:"2021-04-19",BL0_FD:"2024-09-05",PLAN_SD:"2021-02-22",PLAN_FD:"2024-08-31",ACT_SD:"2021-02-22",P6_LV1:"P2",LEVEL:"0",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"Engineering",ACTIVITY_NAME:"Engineering",BL0_SD:"2021-04-19",BL0_FD:"2023-10-09",PLAN_SD:"2021-02-22",PLAN_FD:"2024-02-26",ACT_SD:"2021-02-22",P6_LV1:"P2",LEVEL:"0",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"Procurement",ACTIVITY_NAME:"Procurement",BL0_SD:"2021-06-30",BL0_FD:"2024-05-06",PLAN_SD:"2021-06-30",PLAN_FD:"2024-05-15",ACT_SD:"2021-06-30",P6_LV1:"P2",LEVEL:"0",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"Construction",ACTIVITY_NAME:"Construction",BL0_SD:"2021-12-24",BL0_FD:"2024-09-05",PLAN_SD:"2021-12-24",PLAN_FD:"2024-08-31",ACT_SD:"2021-12-24",P6_LV1:"P2",LEVEL:"0",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P2",P6_LV2:"PBL",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2024-03-30",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:2,P6_LV2_SEQ:210,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P2",P6_LV2:"PBL",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:2,P6_LV2_SEQ:210,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P2",P6_LV2:"PBL",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:2,P6_LV2_SEQ:210,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P2",P6_LV2:"PBL",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2021-09-29",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:2,P6_LV2_SEQ:210,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P2",P6_LV2:"PBL",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:2,P6_LV2_SEQ:210,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P2",P6_LV2:"PBL",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:2,P6_LV2_SEQ:210,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {BL0_SD:"2021-09-29",BL0_FD:"2024-03-20",PLAN_SD:"2021-09-29",PLAN_FD:"2024-03-30",ACT_SD:"2021-09-29",P6_LV1:"P2",P6_LV2:"PBL",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:210,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"PBL",ACTIVITY_NAME:"PBL",BL0_SD:"2021-09-29",BL0_FD:"2023-07-13",PLAN_SD:"2021-09-29",PLAN_FD:"2023-08-24",ACT_SD:"2021-09-29",P6_LV1:"P2",P6_LV2:"PBL",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:210,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"PBL",ACTIVITY_NAME:"PBL",BL0_SD:"2021-12-08",BL0_FD:"2023-11-03",PLAN_SD:"2021-12-08",PLAN_FD:"2023-11-30",ACT_SD:"2021-12-08",P6_LV1:"P2",P6_LV2:"PBL",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:210,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"PBL",ACTIVITY_NAME:"PBL",BL0_SD:"2022-02-07",BL0_FD:"2024-03-20",PLAN_SD:"2022-02-07",PLAN_FD:"2024-03-30",ACT_SD:"2022-02-07",P6_LV1:"P2",P6_LV2:"PBL",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:210,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2022-02-07",BL0_FD:"2023-04-17",PLAN_SD:"2022-02-07",PLAN_FD:"2023-08-15",ACT_SD:"2022-02-07",P6_LV1:"P2",P6_LV2:"PBL",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:210,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-02-07",BL0_FD:"2022-11-29",PLAN_SD:"2022-02-07",PLAN_FD:"2023-02-27",ACT_SD:"2022-02-07",ACT_FD:"2022-12-25",P6_LV1:"P2",P6_LV2:"PBL",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:210,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-02-07",BL0_FD:"2023-02-14",PLAN_SD:"2022-02-07",PLAN_FD:"2023-04-03",ACT_SD:"2022-02-07",P6_LV1:"P2",P6_LV2:"PBL",P6_LV3:"Engineering",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:210,P6_LV3_SEQ:2,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-02-07",BL0_FD:"2023-03-03",PLAN_SD:"2022-02-07",PLAN_FD:"2023-03-23",ACT_SD:"2022-02-07",P6_LV1:"P2",P6_LV2:"PBL",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:210,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-02-07",BL0_FD:"2023-07-13",PLAN_SD:"2022-02-07",PLAN_FD:"2023-07-17",ACT_SD:"2022-02-07",P6_LV1:"P2",P6_LV2:"PBL",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:210,P6_LV3_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-09-29",BL0_FD:"2023-03-20",PLAN_SD:"2021-09-29",PLAN_FD:"2023-04-25",ACT_SD:"2021-09-29",P6_LV1:"P2",P6_LV2:"PBL",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:210,P6_LV3_SEQ:2,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-02-07",BL0_FD:"2023-06-02",PLAN_SD:"2022-02-07",PLAN_FD:"2023-08-24",ACT_SD:"2022-02-07",P6_LV1:"P2",P6_LV2:"PBL",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:210,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-02-07",BL0_FD:"2023-05-24",PLAN_SD:"2022-02-07",PLAN_FD:"2023-07-24",ACT_SD:"2022-02-07",P6_LV1:"P2",P6_LV2:"PBL",P6_LV3:"Engineering",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:210,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-02-07",BL0_FD:"2023-07-13",PLAN_SD:"2022-02-07",PLAN_FD:"2023-08-24",ACT_SD:"2022-02-07",P6_LV1:"P2",P6_LV2:"PBL",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:210,P6_LV3_SEQ:2,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-02-07",BL0_FD:"2023-05-25",PLAN_SD:"2022-02-07",PLAN_FD:"2023-02-27",ACT_SD:"2022-02-07",P6_LV1:"P2",P6_LV2:"PBL",P6_LV3:"Procurement",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:210,P6_LV3_SEQ:3,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-02-07",BL0_FD:"2023-09-11",PLAN_SD:"2022-02-07",PLAN_FD:"2023-10-24",ACT_SD:"2022-02-07",P6_LV1:"P2",P6_LV2:"PBL",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:210,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-12-08",BL0_FD:"2023-08-16",PLAN_SD:"2021-12-08",PLAN_FD:"2023-09-20",ACT_SD:"2021-12-08",P6_LV1:"P2",P6_LV2:"PBL",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:210,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-03-30",BL0_FD:"2023-10-18",PLAN_SD:"2022-02-11",PLAN_FD:"2023-11-10",ACT_SD:"2022-02-11",P6_LV1:"P2",P6_LV2:"PBL",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:210,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-02-07",BL0_FD:"2023-08-12",PLAN_SD:"2022-02-07",PLAN_FD:"2023-09-24",ACT_SD:"2022-02-07",P6_LV1:"P2",P6_LV2:"PBL",P6_LV3:"Procurement",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:210,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-02-07",BL0_FD:"2023-11-03",PLAN_SD:"2022-02-07",PLAN_FD:"2023-11-30",ACT_SD:"2022-02-07",P6_LV1:"P2",P6_LV2:"PBL",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:210,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-02-07",BL0_FD:"2023-10-16",PLAN_SD:"2022-02-07",PLAN_FD:"2023-07-14",ACT_SD:"2022-02-07",P6_LV1:"P2",P6_LV2:"PBL",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:210,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Achitecture",ACTIVITY_NAME:"Achitecture",PLAN_SD:"2022-10-01",PLAN_FD:"2023-01-10",ACT_SD:"2022-10-01",ACT_FD:"2022-12-25",P6_LV1:"P2",P6_LV2:"PBL",P6_LV3:"Construction",P6_LV5:"Achitecture",LEVEL:"2",ITEM_DESC:"Achitecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:210,P6_LV3_SEQ:4,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-12-02",BL0_FD:"2023-10-24",PLAN_SD:"2022-08-31",PLAN_FD:"2023-06-30",ACT_SD:"2022-08-31",P6_LV1:"P2",P6_LV2:"PBL",P6_LV3:"Construction",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:210,P6_LV3_SEQ:4,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2023-06-28",BL0_FD:"2023-07-12",PLAN_SD:"2023-02-14",PLAN_FD:"2023-06-19",P6_LV1:"P2",P6_LV2:"PBL",P6_LV3:"Construction",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:210,P6_LV3_SEQ:4,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-02-08",BL0_FD:"2024-02-22",PLAN_SD:"2022-02-08",PLAN_FD:"2024-01-09",ACT_SD:"2022-02-08",P6_LV1:"P2",P6_LV2:"PBL",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:210,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2023-09-12",BL0_FD:"2024-03-20",PLAN_SD:"2023-05-04",PLAN_FD:"2024-03-01",P6_LV1:"P2",P6_LV2:"PBL",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:210,P6_LV3_SEQ:4,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2023-02-08",BL0_FD:"2024-02-16",PLAN_SD:"2022-12-26",PLAN_FD:"2024-03-30",P6_LV1:"P2",P6_LV2:"PBL",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:210,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2023-09-04",BL0_FD:"2024-02-03",PLAN_SD:"2023-09-25",PLAN_FD:"2024-02-07",P6_LV1:"P2",P6_LV2:"PBL",P6_LV3:"Construction",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:210,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-05-19",BL0_FD:"2024-03-06",PLAN_SD:"2023-06-30",PLAN_FD:"2024-03-30",P6_LV1:"P2",P6_LV2:"PBL",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:210,P6_LV3_SEQ:4,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Painting",ACTIVITY_NAME:"Painting",BL0_SD:"2023-08-09",BL0_FD:"2023-11-22",PLAN_SD:"2023-04-24",PLAN_FD:"2023-12-19",P6_LV1:"P2",P6_LV2:"PBL",P6_LV3:"Construction",P6_LV5:"Painting",LEVEL:"2",ITEM_DESC:"Painting",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:210,P6_LV3_SEQ:4,P6_LV5_SEQ:50,ITEM_SEQ:50},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",PLAN_FD:"2024-03-30",P6_LV1:"P2",P6_LV2:"PBL",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:210,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P2",P6_LV2:"PP",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2021-12-17",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:2,P6_LV2_SEQ:220,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P2",P6_LV2:"PP",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2021-08-13",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:2,P6_LV2_SEQ:220,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P2",P6_LV2:"PP",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2021-04-30",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:2,P6_LV2_SEQ:220,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P2",P6_LV2:"PP",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2021-11-30",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:2,P6_LV2_SEQ:220,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P2",P6_LV2:"PP",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:2,P6_LV2_SEQ:220,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P2",P6_LV2:"PP",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2024-08-30",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:2,P6_LV2_SEQ:220,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {BL0_SD:"2021-04-30",BL0_FD:"2024-07-01",PLAN_SD:"2021-04-30",PLAN_FD:"2024-08-30",ACT_SD:"2021-04-30",P6_LV1:"P2",P6_LV2:"PP",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:220,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"PP",ACTIVITY_NAME:"PP",BL0_SD:"2021-04-30",BL0_FD:"2023-10-09",PLAN_SD:"2021-04-30",PLAN_FD:"2023-11-23",ACT_SD:"2021-04-30",P6_LV1:"P2",P6_LV2:"PP",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:220,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"PP",ACTIVITY_NAME:"PP",BL0_SD:"2021-07-20",BL0_FD:"2024-05-06",PLAN_SD:"2021-07-20",PLAN_FD:"2024-01-17",ACT_SD:"2021-07-20",P6_LV1:"P2",P6_LV2:"PP",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:220,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"PP",ACTIVITY_NAME:"PP",BL0_SD:"2021-12-24",BL0_FD:"2024-07-01",PLAN_SD:"2021-12-24",PLAN_FD:"2024-08-30",ACT_SD:"2021-12-24",P6_LV1:"P2",P6_LV2:"PP",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:220,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-10-22",BL0_FD:"2023-08-02",PLAN_SD:"2021-10-22",PLAN_FD:"2023-11-23",ACT_SD:"2021-10-22",P6_LV1:"P2",P6_LV2:"PP",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:220,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2021-12-17",BL0_FD:"2022-12-23",PLAN_SD:"2021-12-17",PLAN_FD:"2023-03-27",ACT_SD:"2021-12-17",ACT_FD:"2022-12-26",P6_LV1:"P2",P6_LV2:"PP",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:220,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-02-07",BL0_FD:"2023-07-17",PLAN_SD:"2022-02-07",PLAN_FD:"2023-07-28",ACT_SD:"2022-02-07",P6_LV1:"P2",P6_LV2:"PP",P6_LV3:"Engineering",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:220,P6_LV3_SEQ:2,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-02-07",BL0_FD:"2023-06-06",PLAN_SD:"2022-01-28",PLAN_FD:"2023-07-07",ACT_SD:"2022-01-28",P6_LV1:"P2",P6_LV2:"PP",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:220,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-02-07",BL0_FD:"2023-08-18",PLAN_SD:"2022-02-07",PLAN_FD:"2023-08-15",ACT_SD:"2022-02-07",P6_LV1:"P2",P6_LV2:"PP",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:220,P6_LV3_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-04-30",BL0_FD:"2023-08-02",PLAN_SD:"2021-04-30",PLAN_FD:"2023-08-01",ACT_SD:"2021-04-30",P6_LV1:"P2",P6_LV2:"PP",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:220,P6_LV3_SEQ:2,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2021-08-13",BL0_FD:"2023-09-29",PLAN_SD:"2021-08-13",PLAN_FD:"2023-11-23",ACT_SD:"2021-08-13",P6_LV1:"P2",P6_LV2:"PP",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:220,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-02-07",BL0_FD:"2023-07-31",PLAN_SD:"2022-02-07",PLAN_FD:"2023-08-25",ACT_SD:"2022-02-07",P6_LV1:"P2",P6_LV2:"PP",P6_LV3:"Engineering",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:220,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-01-23",BL0_FD:"2023-10-09",PLAN_SD:"2022-01-23",PLAN_FD:"2023-09-19",ACT_SD:"2022-01-23",P6_LV1:"P2",P6_LV2:"PP",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:220,P6_LV3_SEQ:2,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-02-07",BL0_FD:"2023-07-29",PLAN_SD:"2022-02-07",PLAN_FD:"2023-09-13",ACT_SD:"2022-02-07",P6_LV1:"P2",P6_LV2:"PP",P6_LV3:"Procurement",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:220,P6_LV3_SEQ:3,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2021-11-30",BL0_FD:"2023-11-10",PLAN_SD:"2021-11-30",PLAN_FD:"2023-11-07",ACT_SD:"2021-11-30",P6_LV1:"P2",P6_LV2:"PP",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:220,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-07-20",BL0_FD:"2024-05-06",PLAN_SD:"2021-07-20",PLAN_FD:"2024-01-16",ACT_SD:"2021-07-20",P6_LV1:"P2",P6_LV2:"PP",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:220,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-03-30",BL0_FD:"2023-11-05",PLAN_SD:"2022-03-30",PLAN_FD:"2023-10-26",ACT_SD:"2022-03-30",P6_LV1:"P2",P6_LV2:"PP",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:220,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2023-01-14",BL0_FD:"2023-07-07",PLAN_SD:"2023-02-07",PLAN_FD:"2023-08-17",P6_LV1:"P2",P6_LV2:"PP",P6_LV3:"Procurement",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:220,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2021-11-30",BL0_FD:"2024-01-08",PLAN_SD:"2021-11-30",PLAN_FD:"2024-01-17",ACT_SD:"2021-11-30",P6_LV1:"P2",P6_LV2:"PP",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:220,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2021-12-24",BL0_FD:"2024-04-29",PLAN_SD:"2021-12-24",PLAN_FD:"2024-04-18",ACT_SD:"2021-12-24",P6_LV1:"P2",P6_LV2:"PP",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:220,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-07-20",BL0_FD:"2023-07-22",PLAN_SD:"2022-07-24",PLAN_FD:"2023-12-14",ACT_SD:"2022-07-24",P6_LV1:"P2",P6_LV2:"PP",P6_LV3:"Construction",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:220,P6_LV3_SEQ:4,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-07-21",BL0_FD:"2023-09-27",PLAN_SD:"2022-06-24",PLAN_FD:"2024-01-17",ACT_SD:"2022-06-24",P6_LV1:"P2",P6_LV2:"PP",P6_LV3:"Construction",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:220,P6_LV3_SEQ:4,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-09-19",BL0_FD:"2024-03-20",PLAN_SD:"2022-09-05",PLAN_FD:"2024-08-30",ACT_SD:"2022-09-05",P6_LV1:"P2",P6_LV2:"PP",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:220,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2023-04-10",BL0_FD:"2024-02-10",PLAN_SD:"2022-12-26",PLAN_FD:"2024-04-24",P6_LV1:"P2",P6_LV2:"PP",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:220,P6_LV3_SEQ:4,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-12-27",BL0_FD:"2024-04-25",PLAN_SD:"2022-11-04",PLAN_FD:"2024-07-11",ACT_SD:"2022-11-04",P6_LV1:"P2",P6_LV2:"PP",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:220,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-09-25",BL0_FD:"2024-04-24",PLAN_SD:"2023-08-25",PLAN_FD:"2024-04-17",P6_LV1:"P2",P6_LV2:"PP",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:220,P6_LV3_SEQ:4,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Painting",ACTIVITY_NAME:"Painting",BL0_SD:"2023-07-15",BL0_FD:"2023-09-22",PLAN_SD:"2023-06-03",PLAN_FD:"2024-08-16",P6_LV1:"P2",P6_LV2:"PP",P6_LV3:"Construction",P6_LV5:"Painting",LEVEL:"2",ITEM_DESC:"Painting",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:220,P6_LV3_SEQ:4,P6_LV5_SEQ:50,ITEM_SEQ:50},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",BL0_SD:"2023-12-11",BL0_FD:"2024-07-01",PLAN_SD:"2023-10-21",PLAN_FD:"2024-08-30",P6_LV1:"P2",P6_LV2:"PP",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:220,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P2",P6_LV2:"LDPE",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2024-08-31",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:2,P6_LV2_SEQ:230,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P2",P6_LV2:"LDPE",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2021-12-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:2,P6_LV2_SEQ:230,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P2",P6_LV2:"LDPE",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2021-09-01",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:2,P6_LV2_SEQ:230,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P2",P6_LV2:"LDPE",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2021-04-19",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:2,P6_LV2_SEQ:230,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P2",P6_LV2:"LDPE",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2021-05-18",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:2,P6_LV2_SEQ:230,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P2",P6_LV2:"LDPE",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:2,P6_LV2_SEQ:230,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {BL0_SD:"2021-04-19",BL0_FD:"2024-08-26",PLAN_SD:"2021-02-22",PLAN_FD:"2024-08-30",ACT_SD:"2021-02-22",P6_LV1:"P2",P6_LV2:"LDPE",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:230,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"LDPE",ACTIVITY_NAME:"LDPE",BL0_SD:"2021-04-19",BL0_FD:"2023-08-25",PLAN_SD:"2021-02-22",PLAN_FD:"2023-11-27",ACT_SD:"2021-02-22",P6_LV1:"P2",P6_LV2:"LDPE",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:230,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"LDPE",ACTIVITY_NAME:"LDPE",BL0_SD:"2021-06-30",BL0_FD:"2024-03-12",PLAN_SD:"2021-06-30",PLAN_FD:"2024-05-15",ACT_SD:"2021-06-30",P6_LV1:"P2",P6_LV2:"LDPE",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:230,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"LDPE",ACTIVITY_NAME:"LDPE",BL0_SD:"2022-02-08",BL0_FD:"2024-08-26",PLAN_SD:"2022-02-08",PLAN_FD:"2024-08-30",ACT_SD:"2022-02-08",P6_LV1:"P2",P6_LV2:"LDPE",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:230,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-06-18",BL0_FD:"2023-07-28",PLAN_SD:"2021-06-18",PLAN_FD:"2023-11-07",ACT_SD:"2021-06-18",P6_LV1:"P2",P6_LV2:"LDPE",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:230,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2021-12-21",BL0_FD:"2023-05-26",PLAN_SD:"2021-12-21",PLAN_FD:"2023-06-28",ACT_SD:"2021-12-21",P6_LV1:"P2",P6_LV2:"LDPE",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:230,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-02-07",BL0_FD:"2023-06-23",PLAN_SD:"2022-02-07",PLAN_FD:"2023-08-01",ACT_SD:"2022-02-07",P6_LV1:"P2",P6_LV2:"LDPE",P6_LV3:"Engineering",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:230,P6_LV3_SEQ:2,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-02-07",BL0_FD:"2023-07-20",PLAN_SD:"2022-02-07",PLAN_FD:"2023-10-24",ACT_SD:"2022-02-07",P6_LV1:"P2",P6_LV2:"LDPE",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:230,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2021-05-18",BL0_FD:"2023-08-16",PLAN_SD:"2021-05-18",PLAN_FD:"2023-10-09",ACT_SD:"2021-05-18",P6_LV1:"P2",P6_LV2:"LDPE",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:230,P6_LV3_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-04-19",BL0_FD:"2023-08-11",PLAN_SD:"2021-02-22",PLAN_FD:"2023-08-15",ACT_SD:"2021-02-22",P6_LV1:"P2",P6_LV2:"LDPE",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:230,P6_LV3_SEQ:2,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2021-09-01",BL0_FD:"2023-08-16",PLAN_SD:"2021-09-01",PLAN_FD:"2023-09-11",ACT_SD:"2021-09-01",P6_LV1:"P2",P6_LV2:"LDPE",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:230,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-06-01",BL0_FD:"2023-08-01",PLAN_SD:"2022-06-01",PLAN_FD:"2023-07-27",ACT_SD:"2022-06-01",P6_LV1:"P2",P6_LV2:"LDPE",P6_LV3:"Engineering",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:230,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2021-09-22",BL0_FD:"2023-08-25",PLAN_SD:"2021-09-22",PLAN_FD:"2023-11-27",ACT_SD:"2021-09-22",P6_LV1:"P2",P6_LV2:"LDPE",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:230,P6_LV3_SEQ:2,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-09-02",BL0_FD:"2023-10-14",PLAN_SD:"2022-09-02",PLAN_FD:"2024-01-04",ACT_SD:"2022-09-02",P6_LV1:"P2",P6_LV2:"LDPE",P6_LV3:"Procurement",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:230,P6_LV3_SEQ:3,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2021-06-30",BL0_FD:"2024-03-05",PLAN_SD:"2021-06-30",PLAN_FD:"2024-05-15",ACT_SD:"2021-06-30",P6_LV1:"P2",P6_LV2:"LDPE",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:230,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-06-30",BL0_FD:"2024-03-12",PLAN_SD:"2021-08-17",PLAN_FD:"2024-04-14",ACT_SD:"2021-08-17",P6_LV1:"P2",P6_LV2:"LDPE",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:230,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-02-08",BL0_FD:"2024-06-25",PLAN_SD:"2022-02-08",PLAN_FD:"2024-08-07",ACT_SD:"2022-02-08",P6_LV1:"P2",P6_LV2:"LDPE",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:230,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2024-05-29",BL0_FD:"2024-05-29",PLAN_SD:"2024-07-25",PLAN_FD:"2024-08-07",P6_LV1:"P2",P6_LV2:"LDPE",P6_LV3:"Construction",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:230,P6_LV3_SEQ:4,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-02-08",BL0_FD:"2024-07-02",PLAN_SD:"2022-02-08",PLAN_FD:"2024-08-30",ACT_SD:"2022-02-08",P6_LV1:"P2",P6_LV2:"LDPE",P6_LV3:"Construction",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:230,P6_LV3_SEQ:4,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-11-21",BL0_FD:"2024-06-20",PLAN_SD:"2022-06-25",PLAN_FD:"2024-08-29",ACT_SD:"2022-06-25",P6_LV1:"P2",P6_LV2:"LDPE",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:230,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2023-05-06",BL0_FD:"2024-08-26",PLAN_SD:"2023-06-03",PLAN_FD:"2024-08-30",P6_LV1:"P2",P6_LV2:"LDPE",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:230,P6_LV3_SEQ:4,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-08-03",BL0_FD:"2024-08-14",PLAN_SD:"2022-12-26",PLAN_FD:"2024-08-30",P6_LV1:"P2",P6_LV2:"LDPE",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:230,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2024-02-21",BL0_FD:"2024-07-17",PLAN_SD:"2023-12-29",PLAN_FD:"2024-08-10",P6_LV1:"P2",P6_LV2:"LDPE",P6_LV3:"Construction",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:230,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-04-18",BL0_FD:"2024-08-16",PLAN_SD:"2023-11-01",PLAN_FD:"2024-08-29",P6_LV1:"P2",P6_LV2:"LDPE",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:230,P6_LV3_SEQ:4,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",BL0_SD:"2023-09-07",BL0_FD:"2024-08-09",PLAN_SD:"2023-11-20",PLAN_FD:"2024-08-30",P6_LV1:"P2",P6_LV2:"LDPE",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:230,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P2",P6_LV2:"GPPE",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2021-12-13",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:2,P6_LV2_SEQ:240,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P2",P6_LV2:"GPPE",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2021-09-01",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:2,P6_LV2_SEQ:240,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P2",P6_LV2:"GPPE",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2021-05-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:2,P6_LV2_SEQ:240,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P2",P6_LV2:"GPPE",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2021-09-01",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:2,P6_LV2_SEQ:240,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P2",P6_LV2:"GPPE",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2021-09-01",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:2,P6_LV2_SEQ:240,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P2",P6_LV2:"GPPE",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2024-09-20",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:2,P6_LV2_SEQ:240,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {BL0_SD:"2021-05-21",BL0_FD:"2024-09-05",PLAN_SD:"2021-04-12",PLAN_FD:"2024-08-31",ACT_SD:"2021-04-12",P6_LV1:"P2",P6_LV2:"GPPE",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:240,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"GPPE",ACTIVITY_NAME:"GPPE",BL0_SD:"2021-05-21",BL0_FD:"2023-08-16",PLAN_SD:"2021-04-12",PLAN_FD:"2024-02-26",ACT_SD:"2021-04-12",P6_LV1:"P2",P6_LV2:"GPPE",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:240,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"GPPE",ACTIVITY_NAME:"GPPE",BL0_SD:"2021-09-01",BL0_FD:"2024-04-01",PLAN_SD:"2021-09-01",PLAN_FD:"2024-05-08",ACT_SD:"2021-09-01",P6_LV1:"P2",P6_LV2:"GPPE",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:240,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"GPPE",ACTIVITY_NAME:"GPPE",BL0_SD:"2022-01-09",BL0_FD:"2024-09-05",PLAN_SD:"2022-01-09",PLAN_FD:"2024-08-31",ACT_SD:"2022-01-09",P6_LV1:"P2",P6_LV2:"GPPE",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:240,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-09-01",BL0_FD:"2023-08-08",PLAN_SD:"2021-04-12",PLAN_FD:"2024-02-26",ACT_SD:"2021-04-12",P6_LV1:"P2",P6_LV2:"GPPE",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:240,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2021-12-13",BL0_FD:"2023-06-08",PLAN_SD:"2021-12-13",PLAN_FD:"2023-06-01",ACT_SD:"2021-12-13",P6_LV1:"P2",P6_LV2:"GPPE",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:240,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2021-10-08",BL0_FD:"2023-04-11",PLAN_SD:"2021-10-08",PLAN_FD:"2023-07-12",ACT_SD:"2021-10-08",P6_LV1:"P2",P6_LV2:"GPPE",P6_LV3:"Engineering",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:240,P6_LV3_SEQ:2,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2021-09-01",BL0_FD:"2023-08-01",PLAN_SD:"2021-09-01",PLAN_FD:"2023-12-20",ACT_SD:"2021-09-01",P6_LV1:"P2",P6_LV2:"GPPE",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:240,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2021-09-01",BL0_FD:"2023-08-16",PLAN_SD:"2021-05-18",PLAN_FD:"2023-12-20",ACT_SD:"2021-05-18",P6_LV1:"P2",P6_LV2:"GPPE",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:240,P6_LV3_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-05-21",BL0_FD:"2023-07-17",PLAN_SD:"2021-07-26",PLAN_FD:"2023-08-18",ACT_SD:"2021-07-26",P6_LV1:"P2",P6_LV2:"GPPE",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:240,P6_LV3_SEQ:2,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2021-09-01",BL0_FD:"2023-08-03",PLAN_SD:"2021-09-01",PLAN_FD:"2024-01-22",ACT_SD:"2021-09-01",P6_LV1:"P2",P6_LV2:"GPPE",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:240,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-06-01",BL0_FD:"2023-07-18",PLAN_SD:"2022-06-01",PLAN_FD:"2023-08-02",ACT_SD:"2022-06-01",P6_LV1:"P2",P6_LV2:"GPPE",P6_LV3:"Engineering",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:240,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2021-09-01",BL0_FD:"2023-08-04",PLAN_SD:"2021-09-01",PLAN_FD:"2023-12-29",ACT_SD:"2021-09-01",P6_LV1:"P2",P6_LV2:"GPPE",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:240,P6_LV3_SEQ:2,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-10-09",BL0_FD:"2023-11-12",PLAN_SD:"2022-09-01",PLAN_FD:"2023-12-17",ACT_SD:"2022-09-01",P6_LV1:"P2",P6_LV2:"GPPE",P6_LV3:"Procurement",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:240,P6_LV3_SEQ:3,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2021-12-29",BL0_FD:"2024-03-06",PLAN_SD:"2022-02-24",PLAN_FD:"2024-05-08",ACT_SD:"2022-02-24",P6_LV1:"P2",P6_LV2:"GPPE",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:240,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-09-01",BL0_FD:"2024-04-01",PLAN_SD:"2021-09-01",PLAN_FD:"2024-04-05",ACT_SD:"2021-09-01",P6_LV1:"P2",P6_LV2:"GPPE",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:240,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",PLAN_SD:"2023-01-18",PLAN_FD:"2023-12-17",P6_LV1:"P2",P6_LV2:"GPPE",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:240,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",PLAN_SD:"2023-08-12",PLAN_FD:"2023-11-14",P6_LV1:"P2",P6_LV2:"GPPE",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:240,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-01-09",BL0_FD:"2023-09-29",PLAN_SD:"2022-01-09",PLAN_FD:"2023-11-06",ACT_SD:"2022-01-09",P6_LV1:"P2",P6_LV2:"GPPE",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:240,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-09-13",BL0_FD:"2024-01-29",PLAN_SD:"2022-07-20",PLAN_FD:"2024-03-25",ACT_SD:"2022-07-20",P6_LV1:"P2",P6_LV2:"GPPE",P6_LV3:"Construction",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:240,P6_LV3_SEQ:4,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-03-16",BL0_FD:"2024-03-28",PLAN_SD:"2022-03-16",PLAN_FD:"2024-08-31",ACT_SD:"2022-03-16",P6_LV1:"P2",P6_LV2:"GPPE",P6_LV3:"Construction",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:240,P6_LV3_SEQ:4,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-09-01",BL0_FD:"2024-05-08",PLAN_SD:"2022-08-08",PLAN_FD:"2024-08-30",ACT_SD:"2022-08-08",P6_LV1:"P2",P6_LV2:"GPPE",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:240,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2023-03-24",BL0_FD:"2024-01-25",PLAN_SD:"2023-03-30",PLAN_FD:"2024-07-22",P6_LV1:"P2",P6_LV2:"GPPE",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:240,P6_LV3_SEQ:4,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-12-03",BL0_FD:"2024-05-24",PLAN_SD:"2023-03-06",PLAN_FD:"2024-07-09",P6_LV1:"P2",P6_LV2:"GPPE",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:240,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2023-11-11",BL0_FD:"2023-11-11",PLAN_SD:"2023-11-11",PLAN_FD:"2024-03-04",P6_LV1:"P2",P6_LV2:"GPPE",P6_LV3:"Construction",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:240,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-09-18",BL0_FD:"2024-07-19",PLAN_SD:"2023-09-12",PLAN_FD:"2024-07-25",P6_LV1:"P2",P6_LV2:"GPPE",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:240,P6_LV3_SEQ:4,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Insulation",ACTIVITY_NAME:"Insulation",BL0_SD:"2023-05-09",BL0_FD:"2024-07-01",PLAN_SD:"2023-07-18",PLAN_FD:"2024-07-30",P6_LV1:"P2",P6_LV2:"GPPE",P6_LV3:"Construction",P6_LV5:"Insulation",LEVEL:"2",ITEM_DESC:"Insulation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:240,P6_LV3_SEQ:4,P6_LV5_SEQ:55,ITEM_SEQ:55},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",BL0_SD:"2023-04-15",BL0_FD:"2024-09-05",PLAN_SD:"2023-08-09",PLAN_FD:"2024-08-31",P6_LV1:"P2",P6_LV2:"GPPE",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:2,P6_LV2_SEQ:240,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P3",P6_LV5:"MC",LEVEL:"0",ITEM_DESC:"MC",MS_PLAN:"2024-08-09",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P3",P6_LV5:"Mechanical",LEVEL:"0",ITEM_DESC:"MECH",MS_PLAN:"2021-09-01",MS_ACT:"2021-09-01",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P3",P6_LV5:"Civil",LEVEL:"0",ITEM_DESC:"CIVIL",MS_PLAN:"2021-10-01",MS_ACT:"2021-10-01",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P3",P6_LV5:"Electrical",LEVEL:"0",ITEM_DESC:"ELECT",MS_PLAN:"2021-08-31",MS_ACT:"2021-08-31",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P3",P6_LV5:"Piping",LEVEL:"0",ITEM_DESC:"PIPE",MS_PLAN:"2021-12-15",MS_ACT:"2021-12-15",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P3",P6_LV5:"Structure",LEVEL:"0",ITEM_DESC:"STL",MS_PLAN:"2022-02-07",MS_ACT:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {ACTIVITY_ID:"P3",ACTIVITY_NAME:"P3",BL0_SD:"2021-04-01",BL0_FD:"2024-08-09",PLAN_SD:"2021-02-18",PLAN_FD:"2024-09-19",ACT_SD:"2021-02-18",P6_LV1:"P3",LEVEL:"0",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"Engineering",ACTIVITY_NAME:"Engineering",BL0_SD:"2021-04-01",BL0_FD:"2023-08-24",PLAN_SD:"2021-02-18",PLAN_FD:"2023-10-18",ACT_SD:"2021-02-18",P6_LV1:"P3",LEVEL:"0",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"Procurement",ACTIVITY_NAME:"Procurement",BL0_SD:"2021-12-10",BL0_FD:"2024-06-25",PLAN_SD:"2021-12-10",PLAN_FD:"2024-09-19",ACT_SD:"2021-12-10",P6_LV1:"P3",LEVEL:"0",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"Construction",ACTIVITY_NAME:"Construction",BL0_SD:"2021-08-31",BL0_FD:"2024-08-09",PLAN_SD:"2021-08-31",PLAN_FD:"2024-08-09",ACT_SD:"2021-08-31",P6_LV1:"P3",LEVEL:"0",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P3",P6_LV2:"Control Center Building",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2023-09-28",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:302,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P3",P6_LV2:"Control Center Building",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2022-02-28",MS_ACT:"2022-11-10",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:302,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P3",P6_LV2:"Control Center Building",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-03-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:302,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P3",P6_LV2:"Control Center Building",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2022-07-27",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:302,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P3",P6_LV2:"Control Center Building",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-03-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:302,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P3",P6_LV2:"Control Center Building",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-04-28",MS_ACT:"2022-09-23",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:302,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {BL0_SD:"2021-04-01",BL0_FD:"2023-05-16",PLAN_SD:"2021-04-01",PLAN_FD:"2023-09-28",ACT_SD:"2021-04-01",P6_LV1:"P3",P6_LV2:"Control Center Building",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:302,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"Control Center Building",ACTIVITY_NAME:"Control Center Building",BL0_SD:"2021-04-01",BL0_FD:"2023-01-06",PLAN_SD:"2021-04-01",PLAN_FD:"2023-05-11",ACT_SD:"2021-04-01",P6_LV1:"P3",P6_LV2:"Control Center Building",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:302,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"Control Center Building",ACTIVITY_NAME:"Control Center Building",BL0_SD:"2022-02-28",BL0_FD:"2023-01-15",PLAN_SD:"2022-02-28",PLAN_FD:"2023-03-29",ACT_SD:"2022-02-28",P6_LV1:"P3",P6_LV2:"Control Center Building",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:302,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"Control Center Building",ACTIVITY_NAME:"Control Center Building",BL0_SD:"2022-05-04",BL0_FD:"2023-05-16",PLAN_SD:"2022-06-15",PLAN_FD:"2023-09-28",ACT_SD:"2022-06-15",P6_LV1:"P3",P6_LV2:"Control Center Building",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:302,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-04-01",BL0_FD:"2022-10-12",PLAN_SD:"2021-04-01",PLAN_FD:"2023-05-11",ACT_SD:"2021-04-01",ACT_FD:"2022-08-25",P6_LV1:"P3",P6_LV2:"Control Center Building",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:302,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-05-20",BL0_FD:"2022-09-09",PLAN_SD:"2022-05-10",PLAN_FD:"2022-10-30",ACT_SD:"2022-05-10",ACT_FD:"2022-10-30",P6_LV1:"P3",P6_LV2:"Control Center Building",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:302,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-07-01",BL0_FD:"2022-11-17",PLAN_SD:"2022-07-04",PLAN_FD:"2022-11-25",ACT_SD:"2022-07-04",ACT_FD:"2022-11-25",P6_LV1:"P3",P6_LV2:"Control Center Building",P6_LV3:"Engineering",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:302,P6_LV3_SEQ:2,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-04-28",BL0_FD:"2022-08-12",PLAN_SD:"2022-04-28",PLAN_FD:"2022-09-23",ACT_SD:"2022-04-28",ACT_FD:"2022-09-23",P6_LV1:"P3",P6_LV2:"Control Center Building",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:302,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-08-26",BL0_FD:"2022-12-07",PLAN_SD:"2022-09-25",PLAN_FD:"2023-01-10",ACT_SD:"2022-09-25",ACT_FD:"2022-11-24",P6_LV1:"P3",P6_LV2:"Control Center Building",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:302,P6_LV3_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-07-01",BL0_FD:"2023-01-06",PLAN_SD:"2022-07-04",PLAN_FD:"2023-05-11",ACT_SD:"2022-07-04",P6_LV1:"P3",P6_LV2:"Control Center Building",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:302,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-08-26",BL0_FD:"2022-11-30",PLAN_SD:"2022-09-25",PLAN_FD:"2023-01-30",ACT_SD:"2022-09-25",ACT_FD:"2022-11-26",P6_LV1:"P3",P6_LV2:"Control Center Building",P6_LV3:"Engineering",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:302,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-05-20",BL0_FD:"2022-12-23",PLAN_SD:"2022-05-20",PLAN_FD:"2023-02-02",ACT_SD:"2022-05-20",ACT_FD:"2022-12-26",P6_LV1:"P3",P6_LV2:"Control Center Building",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:302,P6_LV3_SEQ:2,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-02-28",BL0_FD:"2022-05-04",PLAN_SD:"2022-02-28",PLAN_FD:"2022-06-23",ACT_SD:"2022-02-28",ACT_FD:"2022-06-23",P6_LV1:"P3",P6_LV2:"Control Center Building",P6_LV3:"Procurement",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:302,P6_LV3_SEQ:3,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-03-21",BL0_FD:"2022-03-21",PLAN_SD:"2022-03-21",PLAN_FD:"2022-08-26",ACT_SD:"2022-03-21",ACT_FD:"2022-08-26",P6_LV1:"P3",P6_LV2:"Control Center Building",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:302,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-07-27",BL0_FD:"2023-01-15",PLAN_SD:"2022-08-29",PLAN_FD:"2023-03-24",ACT_SD:"2022-08-29",P6_LV1:"P3",P6_LV2:"Control Center Building",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:302,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-03-21",BL0_FD:"2023-01-15",PLAN_SD:"2022-03-21",PLAN_FD:"2023-03-24",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Control Center Building",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:302,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-03-21",BL0_FD:"2023-01-15",PLAN_SD:"2022-03-21",PLAN_FD:"2023-03-29",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Control Center Building",P6_LV3:"Procurement",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:302,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-03-21",BL0_FD:"2023-01-15",PLAN_SD:"2022-03-21",PLAN_FD:"2023-03-24",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Control Center Building",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:302,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-05-04",BL0_FD:"2022-08-31",PLAN_SD:"2022-06-15",PLAN_FD:"2022-11-10",ACT_SD:"2022-06-15",ACT_FD:"2022-11-10",P6_LV1:"P3",P6_LV2:"Control Center Building",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:302,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-06-08",BL0_FD:"2023-05-04",PLAN_SD:"2022-07-16",PLAN_FD:"2023-09-28",ACT_SD:"2022-07-16",P6_LV1:"P3",P6_LV2:"Control Center Building",P6_LV3:"Construction",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:302,P6_LV3_SEQ:4,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2023-03-22",BL0_FD:"2023-03-22",PLAN_SD:"2023-03-25",PLAN_FD:"2023-04-27",P6_LV1:"P3",P6_LV2:"Control Center Building",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:302,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2023-05-16",BL0_FD:"2023-05-16",PLAN_SD:"2023-05-07",P6_LV1:"P3",P6_LV2:"Control Center Building",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:302,P6_LV3_SEQ:4,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-09-19",BL0_FD:"2023-03-22",PLAN_SD:"2022-10-21",PLAN_FD:"2023-05-11",ACT_SD:"2022-10-21",P6_LV1:"P3",P6_LV2:"Control Center Building",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:302,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2023-03-22",BL0_FD:"2023-05-04",PLAN_SD:"2023-03-30",PLAN_FD:"2023-05-18",P6_LV1:"P3",P6_LV2:"Control Center Building",P6_LV3:"Construction",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:302,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-06-01",BL0_FD:"2023-05-16",PLAN_SD:"2022-07-22",PLAN_FD:"2023-09-28",ACT_SD:"2022-07-22",P6_LV1:"P3",P6_LV2:"Control Center Building",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:302,P6_LV3_SEQ:4,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",PLAN_FD:"2023-09-28",P6_LV1:"P3",P6_LV2:"Control Center Building",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:302,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P3",P6_LV2:"Area B+ Substation",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2022-03-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:304,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P3",P6_LV2:"Area B+ Substation",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-03-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:304,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P3",P6_LV2:"Area B+ Substation",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2022-07-27",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:304,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P3",P6_LV2:"Area B+ Substation",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-03-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:304,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P3",P6_LV2:"Area B+ Substation",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-02-28",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:304,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P3",P6_LV2:"Area B+ Substation",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2023-09-28",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:304,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {BL0_SD:"2021-04-01",BL0_FD:"2023-08-14",PLAN_SD:"2021-04-01",PLAN_FD:"2023-09-28",ACT_SD:"2021-04-01",P6_LV1:"P3",P6_LV2:"Area B+ Substation",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:304,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"Area B+ Substation",ACTIVITY_NAME:"Area B+ Substation",BL0_SD:"2021-04-01",BL0_FD:"2023-01-16",PLAN_SD:"2021-04-01",PLAN_FD:"2023-02-24",ACT_SD:"2021-04-01",P6_LV1:"P3",P6_LV2:"Area B+ Substation",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:304,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"Area B+ Substation",ACTIVITY_NAME:"Area B+ Substation",BL0_SD:"2022-03-21",BL0_FD:"2023-05-09",PLAN_SD:"2022-03-21",PLAN_FD:"2023-06-20",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Area B+ Substation",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:304,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"Area B+ Substation",ACTIVITY_NAME:"Area B+ Substation",BL0_SD:"2022-03-21",BL0_FD:"2023-08-14",PLAN_SD:"2022-03-21",PLAN_FD:"2023-09-28",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Area B+ Substation",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:304,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-04-01",BL0_FD:"2022-09-05",PLAN_SD:"2021-04-01",PLAN_FD:"2022-12-22",ACT_SD:"2021-04-01",ACT_FD:"2022-12-22",P6_LV1:"P3",P6_LV2:"Area B+ Substation",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:304,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-05-31",BL0_FD:"2022-10-28",PLAN_SD:"2022-05-31",PLAN_FD:"2023-01-10",ACT_SD:"2022-05-31",ACT_FD:"2022-10-30",P6_LV1:"P3",P6_LV2:"Area B+ Substation",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:304,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-07-05",BL0_FD:"2022-10-20",PLAN_SD:"2022-07-21",PLAN_FD:"2022-11-25",ACT_SD:"2022-07-21",ACT_FD:"2022-11-25",P6_LV1:"P3",P6_LV2:"Area B+ Substation",P6_LV3:"Engineering",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:304,P6_LV3_SEQ:2,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-02-28",BL0_FD:"2022-09-26",PLAN_SD:"2022-02-28",PLAN_FD:"2023-01-10",ACT_SD:"2022-02-28",ACT_FD:"2022-10-30",P6_LV1:"P3",P6_LV2:"Area B+ Substation",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:304,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-12-15",BL0_FD:"2022-12-15",PLAN_SD:"2022-09-21",PLAN_FD:"2023-02-13",ACT_SD:"2022-09-21",P6_LV1:"P3",P6_LV2:"Area B+ Substation",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:304,P6_LV3_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-06-21",BL0_FD:"2022-11-17",PLAN_SD:"2022-06-21",PLAN_FD:"2023-02-24",ACT_SD:"2022-06-21",ACT_FD:"2022-12-06",P6_LV1:"P3",P6_LV2:"Area B+ Substation",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:304,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-10-20",BL0_FD:"2023-01-16",PLAN_SD:"2022-10-31",PLAN_FD:"2023-02-13",ACT_SD:"2022-10-31",P6_LV1:"P3",P6_LV2:"Area B+ Substation",P6_LV3:"Engineering",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:304,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-03-21",BL0_FD:"2022-03-21",PLAN_SD:"2022-03-21",PLAN_FD:"2022-08-25",ACT_SD:"2022-03-21",ACT_FD:"2022-08-25",P6_LV1:"P3",P6_LV2:"Area B+ Substation",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:304,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-07-27",BL0_FD:"2023-05-09",PLAN_SD:"2022-07-27",PLAN_FD:"2023-06-20",ACT_SD:"2022-07-27",P6_LV1:"P3",P6_LV2:"Area B+ Substation",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:304,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-03-21",BL0_FD:"2023-01-15",PLAN_SD:"2022-03-21",PLAN_FD:"2023-04-02",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Area B+ Substation",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:304,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-03-21",BL0_FD:"2023-02-01",PLAN_SD:"2022-03-21",PLAN_FD:"2023-05-05",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Area B+ Substation",P6_LV3:"Procurement",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:304,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-03-21",BL0_FD:"2022-08-12",PLAN_SD:"2022-03-21",PLAN_FD:"2022-08-27",ACT_SD:"2022-03-21",ACT_FD:"2022-08-27",P6_LV1:"P3",P6_LV2:"Area B+ Substation",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:304,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-08-12",BL0_FD:"2023-04-27",PLAN_SD:"2022-08-25",PLAN_FD:"2023-05-19",ACT_SD:"2022-08-25",P6_LV1:"P3",P6_LV2:"Area B+ Substation",P6_LV3:"Construction",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:304,P6_LV3_SEQ:4,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2023-06-05",BL0_FD:"2023-07-27",PLAN_SD:"2023-05-20",PLAN_FD:"2023-09-07",P6_LV1:"P3",P6_LV2:"Area B+ Substation",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:304,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",PLAN_FD:"2023-05-19",P6_LV1:"P3",P6_LV2:"Area B+ Substation",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:304,P6_LV3_SEQ:4,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-06-28",BL0_FD:"2023-08-14",PLAN_SD:"2022-07-26",PLAN_FD:"2023-09-28",ACT_SD:"2022-07-26",P6_LV1:"P3",P6_LV2:"Area B+ Substation",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:304,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2023-06-05",BL0_FD:"2023-06-05",PLAN_SD:"2023-05-20",PLAN_FD:"2023-08-26",P6_LV1:"P3",P6_LV2:"Area B+ Substation",P6_LV3:"Construction",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:304,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",PLAN_FD:"2023-09-28",P6_LV1:"P3",P6_LV2:"Area B+ Substation",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:304,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P3",P6_LV2:"220KV Main Substation",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2023-08-28",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:306,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P3",P6_LV2:"220KV Main Substation",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2022-06-01",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:306,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P3",P6_LV2:"220KV Main Substation",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2021-08-31",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:306,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P3",P6_LV2:"220KV Main Substation",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2022-07-18",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:306,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P3",P6_LV2:"220KV Main Substation",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2023-06-13",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:306,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P3",P6_LV2:"220KV Main Substation",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-06-23",MS_ACT:"2022-12-20",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:306,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {BL0_SD:"2021-08-05",BL0_FD:"2023-08-16",PLAN_SD:"2021-08-05",PLAN_FD:"2023-08-30",ACT_SD:"2021-08-05",P6_LV1:"P3",P6_LV2:"220KV Main Substation",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:306,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"220KV Main Substation",ACTIVITY_NAME:"220KV Main Substation",BL0_SD:"2021-08-05",BL0_FD:"2022-12-07",PLAN_SD:"2021-08-05",PLAN_FD:"2023-01-09",ACT_SD:"2021-08-05",ACT_FD:"2022-12-25",P6_LV1:"P3",P6_LV2:"220KV Main Substation",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:306,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"220KV Main Substation",ACTIVITY_NAME:"220KV Main Substation",BL0_SD:"2022-07-18",BL0_FD:"2023-03-25",PLAN_SD:"2022-08-12",PLAN_FD:"2023-07-02",ACT_SD:"2022-08-12",P6_LV1:"P3",P6_LV2:"220KV Main Substation",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:306,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"220KV Main Substation",ACTIVITY_NAME:"220KV Main Substation",BL0_SD:"2021-08-31",BL0_FD:"2023-08-16",PLAN_SD:"2021-08-31",PLAN_FD:"2023-08-30",ACT_SD:"2021-08-31",P6_LV1:"P3",P6_LV2:"220KV Main Substation",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:306,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-08-05",BL0_FD:"2022-07-07",PLAN_SD:"2021-08-05",PLAN_FD:"2022-07-13",ACT_SD:"2021-08-05",ACT_FD:"2022-07-13",P6_LV1:"P3",P6_LV2:"220KV Main Substation",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:306,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-06-01",BL0_FD:"2022-08-04",PLAN_SD:"2022-06-01",PLAN_FD:"2022-12-02",ACT_SD:"2022-06-01",ACT_FD:"2022-12-02",P6_LV1:"P3",P6_LV2:"220KV Main Substation",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:306,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-06-23",BL0_FD:"2022-08-26",PLAN_SD:"2022-07-22",PLAN_FD:"2022-10-27",ACT_SD:"2022-07-22",ACT_FD:"2022-10-27",P6_LV1:"P3",P6_LV2:"220KV Main Substation",P6_LV3:"Engineering",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:306,P6_LV3_SEQ:2,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-06-23",BL0_FD:"2022-11-08",PLAN_SD:"2022-06-30",PLAN_FD:"2022-12-20",ACT_SD:"2022-06-30",ACT_FD:"2022-12-20",P6_LV1:"P3",P6_LV2:"220KV Main Substation",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:306,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-04-12",BL0_FD:"2022-12-07",PLAN_SD:"2022-04-12",PLAN_FD:"2023-01-09",ACT_SD:"2022-04-12",ACT_FD:"2022-12-25",P6_LV1:"P3",P6_LV2:"220KV Main Substation",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:306,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-06-23",BL0_FD:"2022-08-31",PLAN_SD:"2022-06-30",PLAN_FD:"2022-11-21",ACT_SD:"2022-06-30",ACT_FD:"2022-11-21",P6_LV1:"P3",P6_LV2:"220KV Main Substation",P6_LV3:"Engineering",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:306,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-07-18",BL0_FD:"2023-03-25",PLAN_SD:"2022-08-12",PLAN_FD:"2023-07-02",ACT_SD:"2022-08-12",P6_LV1:"P3",P6_LV2:"220KV Main Substation",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:306,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-09-15",BL0_FD:"2023-03-14",PLAN_SD:"2022-11-24",PLAN_FD:"2023-05-25",ACT_SD:"2022-11-24",P6_LV1:"P3",P6_LV2:"220KV Main Substation",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:306,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-09-27",BL0_FD:"2023-03-18",PLAN_SD:"2022-12-26",PLAN_FD:"2023-06-16",P6_LV1:"P3",P6_LV2:"220KV Main Substation",P6_LV3:"Procurement",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:306,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-06-23",BL0_FD:"2023-01-16",PLAN_SD:"2022-06-14",PLAN_FD:"2023-04-07",ACT_SD:"2022-06-14",P6_LV1:"P3",P6_LV2:"220KV Main Substation",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:306,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2023-03-18",BL0_FD:"2023-03-18",PLAN_SD:"2023-03-23",PLAN_FD:"2023-05-30",P6_LV1:"P3",P6_LV2:"220KV Main Substation",P6_LV3:"Construction",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:306,P6_LV3_SEQ:4,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2023-06-13",BL0_FD:"2023-06-13",PLAN_SD:"2023-07-03",PLAN_FD:"2023-07-24",P6_LV1:"P3",P6_LV2:"220KV Main Substation",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:306,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2021-08-31",BL0_FD:"2023-08-16",PLAN_SD:"2021-08-31",PLAN_FD:"2023-08-30",ACT_SD:"2021-08-31",P6_LV1:"P3",P6_LV2:"220KV Main Substation",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:306,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2023-03-30",BL0_FD:"2023-04-26",PLAN_SD:"2023-04-04",PLAN_FD:"2023-08-05",P6_LV1:"P3",P6_LV2:"220KV Main Substation",P6_LV3:"Construction",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:306,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",PLAN_FD:"2023-08-28",P6_LV1:"P3",P6_LV2:"220KV Main Substation",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:306,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P3",P6_LV2:"B+ Firewater Pump Station",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2022-02-07",MS_ACT:"2022-09-25",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:308,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P3",P6_LV2:"B+ Firewater Pump Station",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:308,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P3",P6_LV2:"B+ Firewater Pump Station",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2022-06-30",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:308,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P3",P6_LV2:"B+ Firewater Pump Station",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:308,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P3",P6_LV2:"B+ Firewater Pump Station",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-03-17",MS_ACT:"2022-07-15",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:308,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P3",P6_LV2:"B+ Firewater Pump Station",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2023-09-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:308,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {BL0_SD:"2021-04-01",BL0_FD:"2023-08-18",PLAN_SD:"2021-04-01",PLAN_FD:"2023-09-27",ACT_SD:"2021-04-01",P6_LV1:"P3",P6_LV2:"B+ Firewater Pump Station",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:308,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"B+ Firewater Pump Station",ACTIVITY_NAME:"B+ Firewater Pump Station",BL0_SD:"2021-04-01",BL0_FD:"2022-12-07",PLAN_SD:"2021-04-01",PLAN_FD:"2023-01-19",ACT_SD:"2021-04-01",P6_LV1:"P3",P6_LV2:"B+ Firewater Pump Station",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:308,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"B+ Firewater Pump Station",ACTIVITY_NAME:"B+ Firewater Pump Station",BL0_SD:"2022-02-07",BL0_FD:"2023-03-25",PLAN_SD:"2022-02-07",PLAN_FD:"2023-05-17",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"B+ Firewater Pump Station",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:308,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"B+ Firewater Pump Station",ACTIVITY_NAME:"B+ Firewater Pump Station",BL0_SD:"2022-02-07",BL0_FD:"2023-08-18",PLAN_SD:"2022-02-07",PLAN_FD:"2023-09-27",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"B+ Firewater Pump Station",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:308,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-04-01",BL0_FD:"2022-09-01",PLAN_SD:"2021-04-01",PLAN_FD:"2022-08-25",ACT_SD:"2021-04-01",ACT_FD:"2022-08-25",P6_LV1:"P3",P6_LV2:"B+ Firewater Pump Station",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:308,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-03-17",BL0_FD:"2022-09-15",PLAN_SD:"2022-03-17",PLAN_FD:"2022-08-25",ACT_SD:"2022-03-17",ACT_FD:"2022-08-25",P6_LV1:"P3",P6_LV2:"B+ Firewater Pump Station",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:308,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-07-21",BL0_FD:"2022-09-01",PLAN_SD:"2022-07-21",PLAN_FD:"2022-10-14",ACT_SD:"2022-07-21",ACT_FD:"2022-10-14",P6_LV1:"P3",P6_LV2:"B+ Firewater Pump Station",P6_LV3:"Engineering",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:308,P6_LV3_SEQ:2,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-03-17",BL0_FD:"2022-05-30",PLAN_SD:"2022-03-17",PLAN_FD:"2022-07-15",ACT_SD:"2022-03-17",ACT_FD:"2022-07-15",P6_LV1:"P3",P6_LV2:"B+ Firewater Pump Station",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:308,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-07-27",BL0_FD:"2022-08-24",PLAN_SD:"2022-07-27",PLAN_FD:"2022-09-29",ACT_SD:"2022-07-27",ACT_FD:"2022-09-29",P6_LV1:"P3",P6_LV2:"B+ Firewater Pump Station",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:308,P6_LV3_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-06-30",BL0_FD:"2022-08-25",PLAN_SD:"2022-06-30",PLAN_FD:"2022-09-14",ACT_SD:"2022-06-30",ACT_FD:"2022-09-14",P6_LV1:"P3",P6_LV2:"B+ Firewater Pump Station",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:308,P6_LV3_SEQ:2,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-02-07",BL0_FD:"2022-12-07",PLAN_SD:"2022-02-07",PLAN_FD:"2023-01-19",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"B+ Firewater Pump Station",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:308,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-07-21",BL0_FD:"2022-11-15",PLAN_SD:"2022-07-21",PLAN_FD:"2022-12-30",ACT_SD:"2022-07-21",ACT_FD:"2022-12-14",P6_LV1:"P3",P6_LV2:"B+ Firewater Pump Station",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:308,P6_LV3_SEQ:2,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-02-07",BL0_FD:"2023-01-28",PLAN_SD:"2022-02-07",PLAN_FD:"2023-02-06",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"B+ Firewater Pump Station",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:308,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-07-27",BL0_FD:"2023-03-25",PLAN_SD:"2022-08-27",PLAN_FD:"2023-05-17",ACT_SD:"2022-08-27",P6_LV1:"P3",P6_LV2:"B+ Firewater Pump Station",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:308,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-02-07",BL0_FD:"2022-11-29",PLAN_SD:"2022-02-07",PLAN_FD:"2023-05-17",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"B+ Firewater Pump Station",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:308,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-02-07",BL0_FD:"2022-02-07",PLAN_SD:"2022-02-07",PLAN_FD:"2022-07-26",ACT_SD:"2022-02-07",ACT_FD:"2022-07-26",P6_LV1:"P3",P6_LV2:"B+ Firewater Pump Station",P6_LV3:"Procurement",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:308,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-02-07",BL0_FD:"2022-11-29",PLAN_SD:"2022-02-07",PLAN_FD:"2023-05-17",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"B+ Firewater Pump Station",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:308,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-02-07",BL0_FD:"2022-07-29",PLAN_SD:"2022-02-07",PLAN_FD:"2022-09-25",ACT_SD:"2022-02-07",ACT_FD:"2022-09-25",P6_LV1:"P3",P6_LV2:"B+ Firewater Pump Station",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:308,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-07-29",BL0_FD:"2022-10-13",PLAN_SD:"2022-07-20",PLAN_FD:"2023-03-27",ACT_SD:"2022-07-20",P6_LV1:"P3",P6_LV2:"B+ Firewater Pump Station",P6_LV3:"Construction",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:308,P6_LV3_SEQ:4,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2023-06-09",BL0_FD:"2023-07-03",PLAN_SD:"2023-05-30",PLAN_FD:"2023-07-14",P6_LV1:"P3",P6_LV2:"B+ Firewater Pump Station",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:308,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2023-03-04",BL0_FD:"2023-04-20",PLAN_SD:"2023-02-15",PLAN_FD:"2023-05-29",P6_LV1:"P3",P6_LV2:"B+ Firewater Pump Station",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:308,P6_LV3_SEQ:4,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-07-18",BL0_FD:"2023-08-18",PLAN_SD:"2022-08-04",PLAN_FD:"2023-09-21",ACT_SD:"2022-08-04",P6_LV1:"P3",P6_LV2:"B+ Firewater Pump Station",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:308,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-08-18",BL0_FD:"2023-08-18",PLAN_SD:"2023-08-12",PLAN_FD:"2023-09-21",P6_LV1:"P3",P6_LV2:"B+ Firewater Pump Station",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:308,P6_LV3_SEQ:4,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",PLAN_FD:"2023-09-27",P6_LV1:"P3",P6_LV2:"B+ Firewater Pump Station",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:308,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P3",P6_LV2:"P3-Overall General Layout",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2024-03-19",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:310,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P3",P6_LV2:"P3-Overall General Layout",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:310,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P3",P6_LV2:"P3-Overall General Layout",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:310,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P3",P6_LV2:"P3-Overall General Layout",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2022-03-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:310,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P3",P6_LV2:"P3-Overall General Layout",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:310,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P3",P6_LV2:"P3-Overall General Layout",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-02-28",MS_ACT:"2022-10-12",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:310,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {BL0_SD:"2021-04-01",BL0_FD:"2023-08-23",PLAN_SD:"2021-04-01",PLAN_FD:"2024-03-19",ACT_SD:"2021-04-01",P6_LV1:"P3",P6_LV2:"P3-Overall General Layout",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:310,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"P3-Overall General Layout",ACTIVITY_NAME:"P3-Overall General Layout",BL0_SD:"2021-04-01",BL0_FD:"2023-01-05",PLAN_SD:"2021-04-01",PLAN_FD:"2023-01-10",ACT_SD:"2021-04-01",ACT_FD:"2022-12-25",P6_LV1:"P3",P6_LV2:"P3-Overall General Layout",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:310,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"P3-Overall General Layout",ACTIVITY_NAME:"P3-Overall General Layout",BL0_SD:"2022-02-07",BL0_FD:"2023-05-31",PLAN_SD:"2022-02-07",PLAN_FD:"2023-08-06",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"P3-Overall General Layout",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:310,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"P3-Overall General Layout",ACTIVITY_NAME:"P3-Overall General Layout",BL0_SD:"2022-02-07",BL0_FD:"2023-08-23",PLAN_SD:"2022-02-07",PLAN_FD:"2024-03-19",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"P3-Overall General Layout",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:310,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-04-01",BL0_FD:"2023-01-05",PLAN_SD:"2021-04-01",PLAN_FD:"2023-01-10",ACT_SD:"2021-04-01",ACT_FD:"2022-12-25",P6_LV1:"P3",P6_LV2:"P3-Overall General Layout",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:310,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-05-31",BL0_FD:"2022-08-29",PLAN_SD:"2022-05-31",PLAN_FD:"2022-09-09",ACT_SD:"2022-05-31",ACT_FD:"2022-09-09",P6_LV1:"P3",P6_LV2:"P3-Overall General Layout",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:310,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-07-12",BL0_FD:"2022-09-20",PLAN_SD:"2022-07-14",PLAN_FD:"2022-11-07",ACT_SD:"2022-07-14",ACT_FD:"2022-11-07",P6_LV1:"P3",P6_LV2:"P3-Overall General Layout",P6_LV3:"Engineering",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:310,P6_LV3_SEQ:2,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-02-28",BL0_FD:"2022-08-01",PLAN_SD:"2022-02-28",PLAN_FD:"2022-10-12",ACT_SD:"2022-02-28",ACT_FD:"2022-10-12",P6_LV1:"P3",P6_LV2:"P3-Overall General Layout",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:310,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-09-25",BL0_FD:"2022-12-14",PLAN_SD:"2022-08-29",PLAN_FD:"2022-12-19",ACT_SD:"2022-08-29",ACT_FD:"2022-12-19",P6_LV1:"P3",P6_LV2:"P3-Overall General Layout",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:310,P6_LV3_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-03-07",BL0_FD:"2022-10-28",PLAN_SD:"2022-03-07",PLAN_FD:"2022-11-24",ACT_SD:"2022-03-07",ACT_FD:"2022-11-24",P6_LV1:"P3",P6_LV2:"P3-Overall General Layout",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:310,P6_LV3_SEQ:2,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-05-31",BL0_FD:"2022-12-16",PLAN_SD:"2022-05-31",PLAN_FD:"2023-01-10",ACT_SD:"2022-05-31",ACT_FD:"2022-12-25",P6_LV1:"P3",P6_LV2:"P3-Overall General Layout",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:310,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-09-25",BL0_FD:"2022-11-24",PLAN_SD:"2022-09-08",PLAN_FD:"2022-12-19",ACT_SD:"2022-09-08",ACT_FD:"2022-12-19",P6_LV1:"P3",P6_LV2:"P3-Overall General Layout",P6_LV3:"Engineering",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:310,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-02-07",BL0_FD:"2022-02-07",PLAN_SD:"2022-02-07",PLAN_FD:"2022-07-26",ACT_SD:"2022-02-07",ACT_FD:"2022-07-26",P6_LV1:"P3",P6_LV2:"P3-Overall General Layout",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:310,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-07-27",BL0_FD:"2023-05-31",PLAN_SD:"2022-08-27",PLAN_FD:"2023-08-06",ACT_SD:"2022-08-27",P6_LV1:"P3",P6_LV2:"P3-Overall General Layout",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:310,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-02-07",BL0_FD:"2023-04-04",PLAN_SD:"2022-02-07",PLAN_FD:"2023-05-16",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"P3-Overall General Layout",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:310,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-02-07",BL0_FD:"2023-02-22",PLAN_SD:"2022-02-07",PLAN_FD:"2023-06-20",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"P3-Overall General Layout",P6_LV3:"Procurement",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:310,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-02-07",BL0_FD:"2023-02-02",PLAN_SD:"2022-02-07",PLAN_FD:"2024-01-19",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"P3-Overall General Layout",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:310,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-07-02",BL0_FD:"2023-08-23",PLAN_SD:"2022-08-13",PLAN_FD:"2023-12-18",ACT_SD:"2022-08-13",P6_LV1:"P3",P6_LV2:"P3-Overall General Layout",P6_LV3:"Construction",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:310,P6_LV3_SEQ:4,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-11-25",BL0_FD:"2023-07-19",PLAN_SD:"2022-12-02",PLAN_FD:"2024-01-25",ACT_SD:"2022-12-02",P6_LV1:"P3",P6_LV2:"P3-Overall General Layout",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:310,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2023-08-08",BL0_FD:"2023-08-08",PLAN_SD:"2023-08-08",PLAN_FD:"2024-03-19",P6_LV1:"P3",P6_LV2:"P3-Overall General Layout",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:310,P6_LV3_SEQ:4,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-09-23",BL0_FD:"2023-05-31",PLAN_SD:"2022-08-01",PLAN_FD:"2023-12-06",ACT_SD:"2022-08-01",P6_LV1:"P3",P6_LV2:"P3-Overall General Layout",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:310,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2023-04-24",BL0_FD:"2023-04-24",PLAN_SD:"2023-06-21",PLAN_FD:"2023-12-18",P6_LV1:"P3",P6_LV2:"P3-Overall General Layout",P6_LV3:"Construction",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:310,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",PLAN_FD:"2024-03-19",P6_LV1:"P3",P6_LV2:"P3-Overall General Layout",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:310,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit-update",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2022-08-23",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:312,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit-update",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-06-17",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:312,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit-update",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2022-06-10",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:312,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit-update",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-06-24",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:312,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit-update",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-10-19",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:312,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {BL0_SD:"2022-06-10",BL0_FD:"2023-09-10",PLAN_SD:"2022-06-24",PLAN_FD:"2023-09-01",ACT_SD:"2022-06-24",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit-update",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:312,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"Wastewater Pre-Treatment Unit-update",ACTIVITY_NAME:"Wastewater Pre-Treatment Unit-update",BL0_SD:"2022-10-08",BL0_FD:"2022-10-08",PLAN_SD:"2022-11-14",PLAN_FD:"2023-01-12",ACT_SD:"2022-11-14",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit-update",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:312,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"Wastewater Pre-Treatment Unit-update",ACTIVITY_NAME:"Wastewater Pre-Treatment Unit-update",BL0_SD:"2022-06-10",BL0_FD:"2023-09-10",PLAN_SD:"2022-06-24",PLAN_FD:"2023-09-01",ACT_SD:"2022-06-24",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit-update",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:312,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-10-08",BL0_FD:"2022-10-08",PLAN_SD:"2022-11-14",PLAN_FD:"2023-01-12",ACT_SD:"2022-11-14",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit-update",P6_LV3:"Engineering",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:312,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-08-23",BL0_FD:"2022-12-21",PLAN_SD:"2022-10-31",PLAN_FD:"2023-03-22",ACT_SD:"2022-10-31",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit-update",P6_LV3:"Procurement",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:312,P6_LV3_SEQ:3,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-10-19",BL0_FD:"2023-04-28",PLAN_SD:"2022-11-25",PLAN_FD:"2023-04-13",ACT_SD:"2022-11-25",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit-update",P6_LV3:"Procurement",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:312,P6_LV3_SEQ:3,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-06-24",BL0_FD:"2023-09-10",PLAN_SD:"2022-06-24",PLAN_FD:"2023-08-12",ACT_SD:"2022-06-24",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit-update",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:312,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-06-10",BL0_FD:"2023-07-03",PLAN_SD:"2022-07-15",PLAN_FD:"2023-08-30",ACT_SD:"2022-07-15",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit-update",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:312,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-06-17",BL0_FD:"2023-07-15",PLAN_SD:"2022-10-31",PLAN_FD:"2023-09-01",ACT_SD:"2022-10-31",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit-update",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:312,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-09-20",BL0_FD:"2023-06-29",PLAN_SD:"2022-10-31",PLAN_FD:"2023-05-30",ACT_SD:"2022-10-31",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit-update",P6_LV3:"Procurement",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:312,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-09-16",BL0_FD:"2023-08-25",PLAN_SD:"2022-09-26",PLAN_FD:"2023-08-20",ACT_SD:"2022-09-26",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit-update",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:312,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P3",P6_LV2:"FF & Gas Protection Station",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2022-03-09",MS_ACT:"2022-09-25",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:314,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P3",P6_LV2:"FF & Gas Protection Station",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-03-09",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:314,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P3",P6_LV2:"FF & Gas Protection Station",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2022-03-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:314,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P3",P6_LV2:"FF & Gas Protection Station",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-03-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:314,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P3",P6_LV2:"FF & Gas Protection Station",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-03-09",MS_ACT:"2022-11-10",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:314,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P3",P6_LV2:"FF & Gas Protection Station",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2023-09-28",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:314,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {BL0_SD:"2021-04-01",BL0_FD:"2023-09-19",PLAN_SD:"2021-04-01",PLAN_FD:"2023-09-28",ACT_SD:"2021-04-01",P6_LV1:"P3",P6_LV2:"FF & Gas Protection Station",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:314,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"FF & Gas Protection Station",ACTIVITY_NAME:"FF & Gas Protection Station",BL0_SD:"2021-04-01",BL0_FD:"2022-12-14",PLAN_SD:"2021-04-01",PLAN_FD:"2023-03-20",ACT_SD:"2021-04-01",P6_LV1:"P3",P6_LV2:"FF & Gas Protection Station",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:314,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"FF & Gas Protection Station",ACTIVITY_NAME:"FF & Gas Protection Station",BL0_SD:"2022-03-21",BL0_FD:"2023-06-04",PLAN_SD:"2022-03-21",PLAN_FD:"2023-08-28",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"FF & Gas Protection Station",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:314,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"FF & Gas Protection Station",ACTIVITY_NAME:"FF & Gas Protection Station",BL0_SD:"2022-03-09",BL0_FD:"2023-09-19",PLAN_SD:"2022-03-09",PLAN_FD:"2023-09-28",ACT_SD:"2022-03-09",P6_LV1:"P3",P6_LV2:"FF & Gas Protection Station",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:314,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-04-01",BL0_FD:"2022-08-10",PLAN_SD:"2021-04-01",PLAN_FD:"2023-03-20",ACT_SD:"2021-04-01",ACT_FD:"2022-10-08",P6_LV1:"P3",P6_LV2:"FF & Gas Protection Station",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:314,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-05-13",BL0_FD:"2022-07-08",PLAN_SD:"2022-05-13",PLAN_FD:"2022-09-25",ACT_SD:"2022-05-13",ACT_FD:"2022-09-25",P6_LV1:"P3",P6_LV2:"FF & Gas Protection Station",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:314,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-06-24",BL0_FD:"2022-09-29",PLAN_SD:"2022-06-24",PLAN_FD:"2022-11-25",ACT_SD:"2022-06-24",ACT_FD:"2022-11-25",P6_LV1:"P3",P6_LV2:"FF & Gas Protection Station",P6_LV3:"Engineering",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:314,P6_LV3_SEQ:2,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-03-09",BL0_FD:"2022-08-19",PLAN_SD:"2022-03-09",PLAN_FD:"2022-11-10",ACT_SD:"2022-03-09",ACT_FD:"2022-11-10",P6_LV1:"P3",P6_LV2:"FF & Gas Protection Station",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:314,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-06-29",BL0_FD:"2022-11-21",PLAN_SD:"2022-06-29",PLAN_FD:"2023-01-10",ACT_SD:"2022-06-29",ACT_FD:"2022-11-30",P6_LV1:"P3",P6_LV2:"FF & Gas Protection Station",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:314,P6_LV3_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-08-16",BL0_FD:"2022-08-16",PLAN_SD:"2022-08-16",PLAN_FD:"2022-10-08",ACT_SD:"2022-08-16",ACT_FD:"2022-10-08",P6_LV1:"P3",P6_LV2:"FF & Gas Protection Station",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:314,P6_LV3_SEQ:2,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-03-09",BL0_FD:"2022-12-14",PLAN_SD:"2022-03-09",PLAN_FD:"2023-03-20",ACT_SD:"2022-03-09",P6_LV1:"P3",P6_LV2:"FF & Gas Protection Station",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:314,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-10-12",BL0_FD:"2022-11-23",PLAN_SD:"2022-10-14",PLAN_FD:"2023-01-10",ACT_SD:"2022-10-14",ACT_FD:"2022-11-26",P6_LV1:"P3",P6_LV2:"FF & Gas Protection Station",P6_LV3:"Engineering",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:314,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-03-21",BL0_FD:"2022-11-21",PLAN_SD:"2022-03-21",PLAN_FD:"2023-02-13",ACT_SD:"2022-03-21",ACT_FD:"2022-12-23",P6_LV1:"P3",P6_LV2:"FF & Gas Protection Station",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:314,P6_LV3_SEQ:2,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-03-21",BL0_FD:"2022-11-13",PLAN_SD:"2022-03-21",PLAN_FD:"2023-01-16",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"FF & Gas Protection Station",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:314,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-03-21",BL0_FD:"2023-06-04",PLAN_SD:"2022-03-21",PLAN_FD:"2023-08-28",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"FF & Gas Protection Station",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:314,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-03-21",BL0_FD:"2022-12-13",PLAN_SD:"2022-03-21",PLAN_FD:"2023-03-24",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"FF & Gas Protection Station",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:314,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-03-21",BL0_FD:"2023-02-01",PLAN_SD:"2022-03-21",PLAN_FD:"2023-05-26",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"FF & Gas Protection Station",P6_LV3:"Procurement",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:314,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-03-21",BL0_FD:"2023-03-15",PLAN_SD:"2022-03-21",PLAN_FD:"2023-03-31",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"FF & Gas Protection Station",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:314,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-03-09",BL0_FD:"2022-12-10",PLAN_SD:"2022-03-09",PLAN_FD:"2022-09-24",ACT_SD:"2022-03-09",ACT_FD:"2022-09-24",P6_LV1:"P3",P6_LV2:"FF & Gas Protection Station",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:314,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-07-29",BL0_FD:"2023-04-22",PLAN_SD:"2022-09-29",PLAN_FD:"2023-05-27",ACT_SD:"2022-09-29",P6_LV1:"P3",P6_LV2:"FF & Gas Protection Station",P6_LV3:"Construction",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:314,P6_LV3_SEQ:4,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-12-10",BL0_FD:"2023-09-06",PLAN_SD:"2023-01-17",PLAN_FD:"2023-09-28",P6_LV1:"P3",P6_LV2:"FF & Gas Protection Station",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:314,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-07-22",BL0_FD:"2022-07-22",PLAN_SD:"2022-09-02",PLAN_FD:"2023-05-27",ACT_SD:"2022-09-02",ACT_FD:"2022-09-24",P6_LV1:"P3",P6_LV2:"FF & Gas Protection Station",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:314,P6_LV3_SEQ:4,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2023-01-13",BL0_FD:"2023-07-07",PLAN_SD:"2022-12-01",PLAN_FD:"2023-08-10",ACT_SD:"2022-12-01",P6_LV1:"P3",P6_LV2:"FF & Gas Protection Station",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:314,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2023-05-31",BL0_FD:"2023-05-31",PLAN_SD:"2023-05-27",PLAN_FD:"2023-09-08",P6_LV1:"P3",P6_LV2:"FF & Gas Protection Station",P6_LV3:"Construction",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:314,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-05-31",BL0_FD:"2023-09-19",PLAN_SD:"2023-04-20",PLAN_FD:"2023-08-03",P6_LV1:"P3",P6_LV2:"FF & Gas Protection Station",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:314,P6_LV3_SEQ:4,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",PLAN_FD:"2023-09-28",P6_LV1:"P3",P6_LV2:"FF & Gas Protection Station",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:314,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P3",P6_LV2:"Hazardous waste temporary warehouse",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2023-09-25",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:316,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P3",P6_LV2:"Hazardous waste temporary warehouse",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2022-04-01",MS_ACT:"2022-11-24",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:316,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P3",P6_LV2:"Hazardous waste temporary warehouse",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-03-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:316,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P3",P6_LV2:"Hazardous waste temporary warehouse",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2022-07-27",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:316,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P3",P6_LV2:"Hazardous waste temporary warehouse",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-03-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:316,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P3",P6_LV2:"Hazardous waste temporary warehouse",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:316,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {BL0_SD:"2021-04-01",BL0_FD:"2023-09-20",PLAN_SD:"2021-04-01",PLAN_FD:"2023-09-25",ACT_SD:"2021-04-01",P6_LV1:"P3",P6_LV2:"Hazardous waste temporary warehouse",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:316,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"Hazardous waste temporary warehouse",ACTIVITY_NAME:"Hazardous waste temporary warehouse",BL0_SD:"2021-04-01",BL0_FD:"2022-12-02",PLAN_SD:"2021-04-01",PLAN_FD:"2023-03-09",ACT_SD:"2021-04-01",P6_LV1:"P3",P6_LV2:"Hazardous waste temporary warehouse",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:316,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"Hazardous waste temporary warehouse",ACTIVITY_NAME:"Hazardous waste temporary warehouse",BL0_SD:"2022-03-21",BL0_FD:"2023-02-06",PLAN_SD:"2022-03-21",PLAN_FD:"2023-04-13",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Hazardous waste temporary warehouse",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:316,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"Hazardous waste temporary warehouse",ACTIVITY_NAME:"Hazardous waste temporary warehouse",BL0_SD:"2022-05-31",BL0_FD:"2023-09-20",PLAN_SD:"2022-05-31",PLAN_FD:"2023-09-25",ACT_SD:"2022-05-31",P6_LV1:"P3",P6_LV2:"Hazardous waste temporary warehouse",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:316,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-04-01",BL0_FD:"2022-12-02",PLAN_SD:"2021-04-01",PLAN_FD:"2023-01-12",ACT_SD:"2021-04-01",ACT_FD:"2022-07-16",P6_LV1:"P3",P6_LV2:"Hazardous waste temporary warehouse",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:316,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-05-04",BL0_FD:"2022-09-14",PLAN_SD:"2022-05-04",PLAN_FD:"2022-11-24",ACT_SD:"2022-05-04",ACT_FD:"2022-11-24",P6_LV1:"P3",P6_LV2:"Hazardous waste temporary warehouse",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:316,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-02-07",BL0_FD:"2022-08-10",PLAN_SD:"2022-02-07",PLAN_FD:"2022-11-25",ACT_SD:"2022-02-07",ACT_FD:"2022-11-25",P6_LV1:"P3",P6_LV2:"Hazardous waste temporary warehouse",P6_LV3:"Engineering",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:316,P6_LV3_SEQ:2,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-02-07",BL0_FD:"2022-08-10",PLAN_SD:"2022-02-07",PLAN_FD:"2023-01-10",ACT_SD:"2022-02-07",ACT_FD:"2022-04-28",P6_LV1:"P3",P6_LV2:"Hazardous waste temporary warehouse",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:316,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-09-07",BL0_FD:"2022-11-24",PLAN_SD:"2022-08-17",PLAN_FD:"2023-03-09",ACT_SD:"2022-08-17",P6_LV1:"P3",P6_LV2:"Hazardous waste temporary warehouse",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:316,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-03-25",BL0_FD:"2022-11-25",PLAN_SD:"2022-03-25",PLAN_FD:"2023-01-17",ACT_SD:"2022-03-25",ACT_FD:"2022-11-30",P6_LV1:"P3",P6_LV2:"Hazardous waste temporary warehouse",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:316,P6_LV3_SEQ:2,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-04-01",BL0_FD:"2022-05-31",PLAN_SD:"2022-04-01",PLAN_FD:"2022-09-15",ACT_SD:"2022-04-01",ACT_FD:"2022-09-15",P6_LV1:"P3",P6_LV2:"Hazardous waste temporary warehouse",P6_LV3:"Procurement",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:316,P6_LV3_SEQ:3,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-03-21",BL0_FD:"2022-03-21",PLAN_SD:"2022-03-21",PLAN_FD:"2022-09-25",ACT_SD:"2022-03-21",ACT_FD:"2022-09-25",P6_LV1:"P3",P6_LV2:"Hazardous waste temporary warehouse",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:316,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-07-27",BL0_FD:"2023-02-06",PLAN_SD:"2022-08-01",PLAN_FD:"2023-04-03",ACT_SD:"2022-08-01",P6_LV1:"P3",P6_LV2:"Hazardous waste temporary warehouse",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:316,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-03-21",BL0_FD:"2023-02-01",PLAN_SD:"2022-03-21",PLAN_FD:"2023-04-13",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Hazardous waste temporary warehouse",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:316,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-03-21",BL0_FD:"2023-02-06",PLAN_SD:"2022-03-21",PLAN_FD:"2023-04-03",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Hazardous waste temporary warehouse",P6_LV3:"Procurement",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:316,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-03-21",BL0_FD:"2023-01-15",PLAN_SD:"2022-03-21",PLAN_FD:"2023-04-03",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Hazardous waste temporary warehouse",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:316,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-05-31",BL0_FD:"2022-08-27",PLAN_SD:"2022-05-31",PLAN_FD:"2022-09-25",ACT_SD:"2022-05-31",ACT_FD:"2022-09-25",P6_LV1:"P3",P6_LV2:"Hazardous waste temporary warehouse",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:316,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-08-27",BL0_FD:"2023-05-22",PLAN_SD:"2022-10-18",PLAN_FD:"2023-07-08",ACT_SD:"2022-10-18",P6_LV1:"P3",P6_LV2:"Hazardous waste temporary warehouse",P6_LV3:"Construction",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:316,P6_LV3_SEQ:4,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2023-05-31",BL0_FD:"2023-07-19",PLAN_SD:"2023-05-31",PLAN_FD:"2023-07-25",P6_LV1:"P3",P6_LV2:"Hazardous waste temporary warehouse",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:316,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-11-19",BL0_FD:"2023-09-20",PLAN_SD:"2022-12-05",PLAN_FD:"2023-09-25",ACT_SD:"2022-12-05",P6_LV1:"P3",P6_LV2:"Hazardous waste temporary warehouse",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:316,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2023-05-31",BL0_FD:"2023-07-19",PLAN_SD:"2023-05-31",PLAN_FD:"2023-07-25",P6_LV1:"P3",P6_LV2:"Hazardous waste temporary warehouse",P6_LV3:"Construction",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:316,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-05-31",BL0_FD:"2023-09-15",PLAN_SD:"2023-05-31",PLAN_FD:"2023-09-21",P6_LV1:"P3",P6_LV2:"Hazardous waste temporary warehouse",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:316,P6_LV3_SEQ:4,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",PLAN_FD:"2023-09-25",P6_LV1:"P3",P6_LV2:"Hazardous waste temporary warehouse",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:316,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P3",P6_LV2:"B+ Area Common Area (Waste Water Pump Station 1)",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2021-12-31",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:318,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P3",P6_LV2:"B+ Area Common Area (Waste Water Pump Station 1)",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:318,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P3",P6_LV2:"B+ Area Common Area (Waste Water Pump Station 1)",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2022-03-15",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:318,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P3",P6_LV2:"B+ Area Common Area (Waste Water Pump Station 1)",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2021-12-15",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:318,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P3",P6_LV2:"B+ Area Common Area (Waste Water Pump Station 1)",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-03-01",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:318,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P3",P6_LV2:"B+ Area Common Area (Waste Water Pump Station 1)",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2023-09-28",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:318,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {BL0_SD:"2021-04-01",BL0_FD:"2023-09-22",PLAN_SD:"2021-04-01",PLAN_FD:"2023-09-28",ACT_SD:"2021-04-01",P6_LV1:"P3",P6_LV2:"B+ Area Common Area (Waste Water Pump Station 1)",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:318,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"B+ Area Common Area (Waste Water Pump Station 1)",ACTIVITY_NAME:"B+ Area Common Area (Waste Water Pump Station 1)",BL0_SD:"2021-04-01",BL0_FD:"2023-02-01",PLAN_SD:"2021-04-01",PLAN_FD:"2023-03-13",ACT_SD:"2021-04-01",P6_LV1:"P3",P6_LV2:"B+ Area Common Area (Waste Water Pump Station 1)",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:318,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"B+ Area Common Area (Waste Water Pump Station 1)",ACTIVITY_NAME:"B+ Area Common Area (Waste Water Pump Station 1)",BL0_SD:"2021-12-31",BL0_FD:"2023-06-02",PLAN_SD:"2021-12-31",PLAN_FD:"2023-04-29",ACT_SD:"2021-12-31",P6_LV1:"P3",P6_LV2:"B+ Area Common Area (Waste Water Pump Station 1)",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:318,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"B+ Area Common Area (Waste Water Pump Station 1)",ACTIVITY_NAME:"B+ Area Common Area (Waste Water Pump Station 1)",BL0_SD:"2021-12-31",BL0_FD:"2023-09-22",PLAN_SD:"2022-02-07",PLAN_FD:"2023-09-28",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"B+ Area Common Area (Waste Water Pump Station 1)",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:318,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-04-01",BL0_FD:"2023-02-01",PLAN_SD:"2021-04-01",PLAN_FD:"2023-03-13",ACT_SD:"2021-04-01",P6_LV1:"P3",P6_LV2:"B+ Area Common Area (Waste Water Pump Station 1)",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:318,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-02-07",BL0_FD:"2022-08-18",PLAN_SD:"2022-02-07",PLAN_FD:"2022-10-30",ACT_SD:"2022-02-07",ACT_FD:"2022-10-30",P6_LV1:"P3",P6_LV2:"B+ Area Common Area (Waste Water Pump Station 1)",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:318,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-07-07",BL0_FD:"2022-07-07",PLAN_SD:"2022-07-07",PLAN_FD:"2022-08-17",ACT_SD:"2022-07-07",ACT_FD:"2022-08-17",P6_LV1:"P3",P6_LV2:"B+ Area Common Area (Waste Water Pump Station 1)",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:318,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2021-12-15",BL0_FD:"2023-01-12",PLAN_SD:"2021-12-15",PLAN_FD:"2023-02-14",ACT_SD:"2021-12-15",P6_LV1:"P3",P6_LV2:"B+ Area Common Area (Waste Water Pump Station 1)",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:318,P6_LV3_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-03-15",BL0_FD:"2022-12-13",PLAN_SD:"2022-03-15",PLAN_FD:"2023-02-13",ACT_SD:"2022-03-15",ACT_FD:"2022-12-09",P6_LV1:"P3",P6_LV2:"B+ Area Common Area (Waste Water Pump Station 1)",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:318,P6_LV3_SEQ:2,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-05-25",BL0_FD:"2022-12-16",PLAN_SD:"2022-05-25",PLAN_FD:"2023-02-08",ACT_SD:"2022-05-25",ACT_FD:"2022-11-10",P6_LV1:"P3",P6_LV2:"B+ Area Common Area (Waste Water Pump Station 1)",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:318,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-05-25",BL0_FD:"2022-11-14",PLAN_SD:"2022-05-25",PLAN_FD:"2023-01-10",ACT_SD:"2022-05-25",ACT_FD:"2022-11-26",P6_LV1:"P3",P6_LV2:"B+ Area Common Area (Waste Water Pump Station 1)",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:318,P6_LV3_SEQ:2,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2021-12-31",BL0_FD:"2022-09-03",PLAN_SD:"2021-12-31",PLAN_FD:"2022-10-27",ACT_SD:"2021-12-31",ACT_FD:"2022-10-27",P6_LV1:"P3",P6_LV2:"B+ Area Common Area (Waste Water Pump Station 1)",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:318,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-07-15",BL0_FD:"2023-02-15",PLAN_SD:"2022-06-15",PLAN_FD:"2023-03-29",ACT_SD:"2022-06-15",P6_LV1:"P3",P6_LV2:"B+ Area Common Area (Waste Water Pump Station 1)",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:318,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-02-07",BL0_FD:"2022-12-25",PLAN_SD:"2022-02-07",PLAN_FD:"2023-03-29",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"B+ Area Common Area (Waste Water Pump Station 1)",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:318,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-02-07",BL0_FD:"2023-06-02",PLAN_SD:"2022-02-07",PLAN_FD:"2023-04-29",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"B+ Area Common Area (Waste Water Pump Station 1)",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:318,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2021-12-31",BL0_FD:"2022-10-08",PLAN_SD:"2022-02-07",PLAN_FD:"2023-02-27",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"B+ Area Common Area (Waste Water Pump Station 1)",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:318,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-03-01",BL0_FD:"2022-12-14",PLAN_SD:"2022-03-01",PLAN_FD:"2023-04-17",ACT_SD:"2022-03-01",P6_LV1:"P3",P6_LV2:"B+ Area Common Area (Waste Water Pump Station 1)",P6_LV3:"Construction",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:318,P6_LV3_SEQ:4,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-03-30",BL0_FD:"2023-04-19",PLAN_SD:"2022-03-30",PLAN_FD:"2023-07-26",ACT_SD:"2022-03-30",P6_LV1:"P3",P6_LV2:"B+ Area Common Area (Waste Water Pump Station 1)",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:318,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-05-27",BL0_FD:"2023-05-16",PLAN_SD:"2022-05-27",PLAN_FD:"2023-09-28",ACT_SD:"2022-05-27",P6_LV1:"P3",P6_LV2:"B+ Area Common Area (Waste Water Pump Station 1)",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:318,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-06-30",BL0_FD:"2023-09-22",PLAN_SD:"2023-05-04",PLAN_FD:"2023-08-16",P6_LV1:"P3",P6_LV2:"B+ Area Common Area (Waste Water Pump Station 1)",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:318,P6_LV3_SEQ:4,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",PLAN_FD:"2023-09-28",P6_LV1:"P3",P6_LV2:"B+ Area Common Area (Waste Water Pump Station 1)",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:318,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 1",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2023-10-18",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:320,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 1",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:320,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 1",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:320,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 1",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2021-12-20",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:320,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 1",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:320,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 1",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-02-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:320,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {BL0_SD:"2021-12-16",BL0_FD:"2023-09-29",PLAN_SD:"2021-12-16",PLAN_FD:"2023-10-18",ACT_SD:"2021-12-16",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 1",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:320,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"Water and Firewater Pump Station 1",ACTIVITY_NAME:"Water and Firewater Pump Station 1",BL0_SD:"2021-12-16",BL0_FD:"2023-05-11",PLAN_SD:"2021-12-16",PLAN_FD:"2023-06-22",ACT_SD:"2021-12-16",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 1",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:320,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"Water and Firewater Pump Station 1",ACTIVITY_NAME:"Water and Firewater Pump Station 1",BL0_SD:"2021-12-20",BL0_FD:"2023-07-09",PLAN_SD:"2021-12-20",PLAN_FD:"2023-08-14",ACT_SD:"2021-12-20",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 1",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:320,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"Water and Firewater Pump Station 1",ACTIVITY_NAME:"Water and Firewater Pump Station 1",BL0_SD:"2022-04-18",BL0_FD:"2023-09-29",PLAN_SD:"2022-04-22",PLAN_FD:"2023-10-18",ACT_SD:"2022-04-22",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 1",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:320,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-12-16",BL0_FD:"2022-12-29",PLAN_SD:"2021-12-16",PLAN_FD:"2023-05-18",ACT_SD:"2021-12-16",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 1",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:320,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-02-07",BL0_FD:"2022-09-14",PLAN_SD:"2022-02-07",PLAN_FD:"2023-01-17",ACT_SD:"2022-02-07",ACT_FD:"2022-11-01",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 1",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:320,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-02-21",BL0_FD:"2022-12-26",PLAN_SD:"2022-02-21",PLAN_FD:"2023-04-11",ACT_SD:"2022-02-21",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 1",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:320,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-02-07",BL0_FD:"2022-12-22",PLAN_SD:"2022-02-07",PLAN_FD:"2023-02-13",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 1",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:320,P6_LV3_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-01-14",BL0_FD:"2022-12-19",PLAN_SD:"2022-01-14",PLAN_FD:"2023-02-28",ACT_SD:"2022-01-14",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 1",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:320,P6_LV3_SEQ:2,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-02-07",BL0_FD:"2023-05-11",PLAN_SD:"2022-02-07",PLAN_FD:"2023-06-22",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 1",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:320,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-02-07",BL0_FD:"2023-04-07",PLAN_SD:"2022-02-07",PLAN_FD:"2023-06-01",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 1",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:320,P6_LV3_SEQ:2,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-10-15",BL0_FD:"2023-04-27",PLAN_SD:"2022-10-22",PLAN_FD:"2023-07-09",ACT_SD:"2022-10-22",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 1",P6_LV3:"Procurement",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:320,P6_LV3_SEQ:3,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-07-16",BL0_FD:"2023-07-09",PLAN_SD:"2022-05-09",PLAN_FD:"2023-08-14",ACT_SD:"2022-05-09",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 1",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:320,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-12-20",BL0_FD:"2023-05-05",PLAN_SD:"2021-12-20",PLAN_FD:"2023-07-01",ACT_SD:"2021-12-20",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 1",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:320,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-11-18",BL0_FD:"2023-06-16",PLAN_SD:"2022-09-27",PLAN_FD:"2023-08-02",ACT_SD:"2022-09-27",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 1",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:320,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-06-29",BL0_FD:"2023-05-29",PLAN_SD:"2022-07-25",PLAN_FD:"2023-07-13",ACT_SD:"2022-07-25",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 1",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:320,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-04-18",BL0_FD:"2023-07-27",PLAN_SD:"2022-04-22",PLAN_FD:"2023-07-06",ACT_SD:"2022-04-22",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 1",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:320,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-11-15",BL0_FD:"2023-03-28",PLAN_SD:"2022-11-18",PLAN_FD:"2023-03-08",ACT_SD:"2022-11-18",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 1",P6_LV3:"Construction",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:320,P6_LV3_SEQ:4,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-07-27",BL0_FD:"2023-07-28",PLAN_SD:"2022-08-15",PLAN_FD:"2023-10-10",ACT_SD:"2022-08-15",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 1",P6_LV3:"Construction",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:320,P6_LV3_SEQ:4,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-11-12",BL0_FD:"2023-09-29",PLAN_SD:"2022-12-19",PLAN_FD:"2023-10-18",ACT_SD:"2022-12-19",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 1",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:320,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2023-03-11",BL0_FD:"2023-08-23",PLAN_SD:"2022-12-26",PLAN_FD:"2023-09-14",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 1",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:320,P6_LV3_SEQ:4,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2023-02-18",BL0_FD:"2023-09-22",PLAN_SD:"2022-12-26",PLAN_FD:"2023-10-17",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 1",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:320,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-02-18",BL0_FD:"2023-09-19",PLAN_SD:"2023-05-04",PLAN_FD:"2023-10-18",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 1",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:320,P6_LV3_SEQ:4,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Insulation",ACTIVITY_NAME:"Insulation",BL0_SD:"2023-07-19",BL0_FD:"2023-09-27",PLAN_SD:"2023-07-21",PLAN_FD:"2023-10-18",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 1",P6_LV3:"Construction",P6_LV5:"Insulation",LEVEL:"2",ITEM_DESC:"Insulation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:320,P6_LV3_SEQ:4,P6_LV5_SEQ:55,ITEM_SEQ:55},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",PLAN_FD:"2023-10-18",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 1",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:320,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 2",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:322,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 2",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:322,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 2",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2021-12-20",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:322,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 2",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-02-28",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:322,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 2",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-03-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:322,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 2",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2023-10-18",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:322,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {BL0_SD:"2021-12-16",BL0_FD:"2023-09-29",PLAN_SD:"2021-12-16",PLAN_FD:"2023-10-18",ACT_SD:"2021-12-16",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 2",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:322,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"Water and Firewater Pump Station 2",ACTIVITY_NAME:"Water and Firewater Pump Station 2",BL0_SD:"2021-12-16",BL0_FD:"2023-03-20",PLAN_SD:"2021-12-16",PLAN_FD:"2023-06-08",ACT_SD:"2021-12-16",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 2",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:322,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"Water and Firewater Pump Station 2",ACTIVITY_NAME:"Water and Firewater Pump Station 2",BL0_SD:"2021-12-20",BL0_FD:"2023-06-22",PLAN_SD:"2021-12-20",PLAN_FD:"2023-08-02",ACT_SD:"2021-12-20",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 2",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:322,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"Water and Firewater Pump Station 2",ACTIVITY_NAME:"Water and Firewater Pump Station 2",BL0_SD:"2022-02-07",BL0_FD:"2023-09-29",PLAN_SD:"2022-02-07",PLAN_FD:"2023-10-18",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 2",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:322,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-12-16",BL0_FD:"2022-12-09",PLAN_SD:"2021-12-16",PLAN_FD:"2023-06-08",ACT_SD:"2021-12-16",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 2",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:322,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-02-07",BL0_FD:"2022-10-17",PLAN_SD:"2022-02-07",PLAN_FD:"2022-11-01",ACT_SD:"2022-02-07",ACT_FD:"2022-11-01",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 2",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:322,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-03-07",BL0_FD:"2023-01-10",PLAN_SD:"2022-03-07",PLAN_FD:"2023-02-20",ACT_SD:"2022-03-07",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 2",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:322,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-02-28",BL0_FD:"2023-01-30",PLAN_SD:"2022-02-28",PLAN_FD:"2023-03-06",ACT_SD:"2022-02-28",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 2",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:322,P6_LV3_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-02-28",BL0_FD:"2022-10-10",PLAN_SD:"2022-02-28",PLAN_FD:"2023-01-10",ACT_SD:"2022-02-28",ACT_FD:"2022-09-30",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 2",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:322,P6_LV3_SEQ:2,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-02-07",BL0_FD:"2023-03-13",PLAN_SD:"2022-02-07",PLAN_FD:"2023-06-08",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 2",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:322,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-02-21",BL0_FD:"2023-03-20",PLAN_SD:"2022-02-21",PLAN_FD:"2023-05-11",ACT_SD:"2022-02-21",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 2",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:322,P6_LV3_SEQ:2,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-09-21",BL0_FD:"2023-04-07",PLAN_SD:"2022-10-22",PLAN_FD:"2023-05-11",ACT_SD:"2022-10-22",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 2",P6_LV3:"Procurement",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:322,P6_LV3_SEQ:3,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-06-29",BL0_FD:"2023-06-22",PLAN_SD:"2022-05-09",PLAN_FD:"2023-07-04",ACT_SD:"2022-05-09",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 2",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:322,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-12-20",BL0_FD:"2023-06-11",PLAN_SD:"2021-12-20",PLAN_FD:"2023-07-08",ACT_SD:"2021-12-20",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 2",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:322,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-09-07",BL0_FD:"2023-05-21",PLAN_SD:"2022-08-27",PLAN_FD:"2023-08-02",ACT_SD:"2022-08-27",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 2",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:322,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-06-22",BL0_FD:"2023-05-30",PLAN_SD:"2022-07-25",PLAN_FD:"2023-07-13",ACT_SD:"2022-07-25",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 2",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:322,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-02-07",BL0_FD:"2023-05-11",PLAN_SD:"2022-02-07",PLAN_FD:"2023-06-03",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 2",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:322,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-12-24",BL0_FD:"2023-05-10",PLAN_SD:"2022-11-21",PLAN_FD:"2023-03-08",ACT_SD:"2022-11-21",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 2",P6_LV3:"Construction",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:322,P6_LV3_SEQ:4,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-08-17",BL0_FD:"2023-07-22",PLAN_SD:"2022-08-08",PLAN_FD:"2023-08-07",ACT_SD:"2022-08-08",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 2",P6_LV3:"Construction",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:322,P6_LV3_SEQ:4,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-11-23",BL0_FD:"2023-09-29",PLAN_SD:"2022-12-19",PLAN_FD:"2023-10-18",ACT_SD:"2022-12-19",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 2",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:322,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2023-02-10",BL0_FD:"2023-08-14",PLAN_SD:"2023-03-02",PLAN_FD:"2023-08-26",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 2",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:322,P6_LV3_SEQ:4,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2023-04-15",BL0_FD:"2023-09-11",PLAN_SD:"2022-12-26",PLAN_FD:"2023-10-10",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 2",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:322,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-12-26",BL0_FD:"2023-09-20",PLAN_SD:"2023-04-13",PLAN_FD:"2023-10-11",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 2",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:322,P6_LV3_SEQ:4,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Insulation",ACTIVITY_NAME:"Insulation",BL0_SD:"2023-06-26",BL0_FD:"2023-09-22",PLAN_SD:"2023-07-10",PLAN_FD:"2023-09-28",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 2",P6_LV3:"Construction",P6_LV5:"Insulation",LEVEL:"2",ITEM_DESC:"Insulation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:322,P6_LV3_SEQ:4,P6_LV5_SEQ:55,ITEM_SEQ:55},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",BL0_SD:"2023-09-10",BL0_FD:"2023-09-10",PLAN_FD:"2023-10-18",P6_LV1:"P3",P6_LV2:"Water and Firewater Pump Station 2",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:322,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P3",P6_LV2:"Warehouse Management Room and Spare Parts Warehouse",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2023-11-25",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:324,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P3",P6_LV2:"Warehouse Management Room and Spare Parts Warehouse",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2022-05-12",MS_ACT:"2022-11-04",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:324,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P3",P6_LV2:"Warehouse Management Room and Spare Parts Warehouse",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-04-20",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:324,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P3",P6_LV2:"Warehouse Management Room and Spare Parts Warehouse",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2022-08-26",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:324,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P3",P6_LV2:"Warehouse Management Room and Spare Parts Warehouse",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-04-20",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:324,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P3",P6_LV2:"Warehouse Management Room and Spare Parts Warehouse",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-03-31",MS_ACT:"2022-10-10",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:324,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {BL0_SD:"2021-04-01",BL0_FD:"2023-11-09",PLAN_SD:"2021-02-18",PLAN_FD:"2023-11-25",ACT_SD:"2021-02-18",P6_LV1:"P3",P6_LV2:"Warehouse Management Room and Spare Parts Warehouse",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:324,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"Warehouse Management Room and Spare Parts Warehouse",ACTIVITY_NAME:"Warehouse Management Room and Spare Parts Warehouse",BL0_SD:"2021-04-01",BL0_FD:"2023-02-17",PLAN_SD:"2021-02-18",PLAN_FD:"2023-04-20",ACT_SD:"2021-02-18",P6_LV1:"P3",P6_LV2:"Warehouse Management Room and Spare Parts Warehouse",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:324,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"Warehouse Management Room and Spare Parts Warehouse",ACTIVITY_NAME:"Warehouse Management Room and Spare Parts Warehouse",BL0_SD:"2022-04-20",BL0_FD:"2023-06-11",PLAN_SD:"2022-04-20",PLAN_FD:"2023-07-01",ACT_SD:"2022-04-20",P6_LV1:"P3",P6_LV2:"Warehouse Management Room and Spare Parts Warehouse",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:324,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"Warehouse Management Room and Spare Parts Warehouse",ACTIVITY_NAME:"Warehouse Management Room and Spare Parts Warehouse",BL0_SD:"2022-05-12",BL0_FD:"2023-11-09",PLAN_SD:"2022-04-25",PLAN_FD:"2023-11-25",ACT_SD:"2022-04-25",P6_LV1:"P3",P6_LV2:"Warehouse Management Room and Spare Parts Warehouse",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:324,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-04-01",BL0_FD:"2022-09-05",PLAN_SD:"2021-02-18",PLAN_FD:"2022-10-27",ACT_SD:"2021-02-18",ACT_FD:"2022-10-27",P6_LV1:"P3",P6_LV2:"Warehouse Management Room and Spare Parts Warehouse",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:324,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-05-16",BL0_FD:"2022-12-01",PLAN_SD:"2022-05-16",PLAN_FD:"2022-11-04",ACT_SD:"2022-05-16",ACT_FD:"2022-11-04",P6_LV1:"P3",P6_LV2:"Warehouse Management Room and Spare Parts Warehouse",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:324,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-09-05",BL0_FD:"2022-10-20",PLAN_SD:"2022-09-26",PLAN_FD:"2022-11-25",ACT_SD:"2022-09-26",ACT_FD:"2022-11-25",P6_LV1:"P3",P6_LV2:"Warehouse Management Room and Spare Parts Warehouse",P6_LV3:"Engineering",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:324,P6_LV3_SEQ:2,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-03-31",BL0_FD:"2022-11-03",PLAN_SD:"2022-03-31",PLAN_FD:"2022-10-10",ACT_SD:"2022-03-31",ACT_FD:"2022-10-10",P6_LV1:"P3",P6_LV2:"Warehouse Management Room and Spare Parts Warehouse",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:324,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-11-17",BL0_FD:"2023-01-20",PLAN_SD:"2022-12-23",PLAN_FD:"2023-04-05",ACT_SD:"2022-12-23",P6_LV1:"P3",P6_LV2:"Warehouse Management Room and Spare Parts Warehouse",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:324,P6_LV3_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-09-19",BL0_FD:"2023-02-03",PLAN_SD:"2022-05-01",PLAN_FD:"2023-01-09",ACT_SD:"2022-05-01",ACT_FD:"2022-12-22",P6_LV1:"P3",P6_LV2:"Warehouse Management Room and Spare Parts Warehouse",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:324,P6_LV3_SEQ:2,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-09-05",BL0_FD:"2023-02-17",PLAN_SD:"2022-09-26",PLAN_FD:"2023-04-20",ACT_SD:"2022-09-26",P6_LV1:"P3",P6_LV2:"Warehouse Management Room and Spare Parts Warehouse",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:324,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-11-17",BL0_FD:"2023-02-17",PLAN_SD:"2022-06-01",PLAN_FD:"2023-02-27",ACT_SD:"2022-06-01",P6_LV1:"P3",P6_LV2:"Warehouse Management Room and Spare Parts Warehouse",P6_LV3:"Engineering",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:324,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-10-20",BL0_FD:"2023-01-09",PLAN_SD:"2022-09-26",PLAN_FD:"2023-01-09",ACT_SD:"2022-09-26",ACT_FD:"2022-11-29",P6_LV1:"P3",P6_LV2:"Warehouse Management Room and Spare Parts Warehouse",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:324,P6_LV3_SEQ:2,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-04-20",BL0_FD:"2022-10-30",PLAN_SD:"2022-04-20",PLAN_FD:"2022-11-24",ACT_SD:"2022-04-20",ACT_FD:"2022-11-24",P6_LV1:"P3",P6_LV2:"Warehouse Management Room and Spare Parts Warehouse",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:324,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-08-26",BL0_FD:"2023-06-11",PLAN_SD:"2022-08-26",PLAN_FD:"2023-07-01",ACT_SD:"2022-08-26",P6_LV1:"P3",P6_LV2:"Warehouse Management Room and Spare Parts Warehouse",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:324,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-04-20",BL0_FD:"2023-01-11",PLAN_SD:"2022-04-20",PLAN_FD:"2023-04-17",ACT_SD:"2022-04-20",P6_LV1:"P3",P6_LV2:"Warehouse Management Room and Spare Parts Warehouse",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:324,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-04-20",BL0_FD:"2023-02-17",PLAN_SD:"2022-04-20",PLAN_FD:"2023-04-23",ACT_SD:"2022-04-20",P6_LV1:"P3",P6_LV2:"Warehouse Management Room and Spare Parts Warehouse",P6_LV3:"Procurement",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:324,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-04-20",BL0_FD:"2023-02-27",PLAN_SD:"2022-04-20",PLAN_FD:"2023-05-16",ACT_SD:"2022-04-20",P6_LV1:"P3",P6_LV2:"Warehouse Management Room and Spare Parts Warehouse",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:324,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-05-12",BL0_FD:"2022-09-17",PLAN_SD:"2022-04-25",PLAN_FD:"2022-09-15",ACT_SD:"2022-04-25",ACT_FD:"2022-09-15",P6_LV1:"P3",P6_LV2:"Warehouse Management Room and Spare Parts Warehouse",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:324,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-08-08",BL0_FD:"2023-05-11",PLAN_SD:"2022-10-14",PLAN_FD:"2023-09-26",ACT_SD:"2022-10-14",P6_LV1:"P3",P6_LV2:"Warehouse Management Room and Spare Parts Warehouse",P6_LV3:"Construction",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:324,P6_LV3_SEQ:4,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2023-03-04",BL0_FD:"2023-11-09",PLAN_SD:"2023-02-03",PLAN_FD:"2023-11-25",P6_LV1:"P3",P6_LV2:"Warehouse Management Room and Spare Parts Warehouse",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:324,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2023-05-11",BL0_FD:"2023-09-09",PLAN_SD:"2023-07-19",PLAN_FD:"2023-09-14",P6_LV1:"P3",P6_LV2:"Warehouse Management Room and Spare Parts Warehouse",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:324,P6_LV3_SEQ:4,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2023-03-04",BL0_FD:"2023-07-20",PLAN_SD:"2023-05-04",PLAN_FD:"2023-10-31",P6_LV1:"P3",P6_LV2:"Warehouse Management Room and Spare Parts Warehouse",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:324,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2023-05-11",BL0_FD:"2023-05-11",PLAN_SD:"2023-07-19",PLAN_FD:"2023-10-13",P6_LV1:"P3",P6_LV2:"Warehouse Management Room and Spare Parts Warehouse",P6_LV3:"Construction",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:324,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-05-11",BL0_FD:"2023-07-20",PLAN_SD:"2023-07-19",PLAN_FD:"2023-10-25",P6_LV1:"P3",P6_LV2:"Warehouse Management Room and Spare Parts Warehouse",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:324,P6_LV3_SEQ:4,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",PLAN_FD:"2023-11-25",P6_LV1:"P3",P6_LV2:"Warehouse Management Room and Spare Parts Warehouse",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:324,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P3",P6_LV2:"Storm Water Pump Staton  and  Accident  Buffer  Basin",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2021-10-01",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:326,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P3",P6_LV2:"Storm Water Pump Staton  and  Accident  Buffer  Basin",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-01-30",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:326,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P3",P6_LV2:"Storm Water Pump Staton  and  Accident  Buffer  Basin",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2021-12-20",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:326,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P3",P6_LV2:"Storm Water Pump Staton  and  Accident  Buffer  Basin",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-01-30",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:326,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P3",P6_LV2:"Storm Water Pump Staton  and  Accident  Buffer  Basin",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-05-31",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:326,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P3",P6_LV2:"Storm Water Pump Staton  and  Accident  Buffer  Basin",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2023-11-16",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:326,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {BL0_SD:"2021-10-01",BL0_FD:"2023-11-10",PLAN_SD:"2021-10-01",PLAN_FD:"2023-11-01",ACT_SD:"2021-10-01",P6_LV1:"P3",P6_LV2:"Storm Water Pump Staton  and  Accident  Buffer  Basin",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:326,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"Storm Water Pump Staton  and  Accident  Buffer  Basin",ACTIVITY_NAME:"Storm Water Pump Staton  and  Accident  Buffer  Basin",BL0_SD:"2021-10-01",BL0_FD:"2023-06-22",PLAN_SD:"2021-10-01",PLAN_FD:"2023-07-14",ACT_SD:"2021-10-01",P6_LV1:"P3",P6_LV2:"Storm Water Pump Staton  and  Accident  Buffer  Basin",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:326,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"Storm Water Pump Staton  and  Accident  Buffer  Basin",ACTIVITY_NAME:"Storm Water Pump Staton  and  Accident  Buffer  Basin",BL0_SD:"2021-12-20",BL0_FD:"2023-09-26",PLAN_SD:"2021-12-20",PLAN_FD:"2023-09-16",ACT_SD:"2021-12-20",P6_LV1:"P3",P6_LV2:"Storm Water Pump Staton  and  Accident  Buffer  Basin",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:326,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"Storm Water Pump Staton  and  Accident  Buffer  Basin",ACTIVITY_NAME:"Storm Water Pump Staton  and  Accident  Buffer  Basin",BL0_SD:"2021-11-01",BL0_FD:"2023-11-10",PLAN_SD:"2021-11-01",PLAN_FD:"2023-11-01",ACT_SD:"2021-11-01",P6_LV1:"P3",P6_LV2:"Storm Water Pump Staton  and  Accident  Buffer  Basin",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:326,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-11-16",BL0_FD:"2023-03-29",PLAN_SD:"2021-11-16",PLAN_FD:"2023-07-14",ACT_SD:"2021-11-16",P6_LV1:"P3",P6_LV2:"Storm Water Pump Staton  and  Accident  Buffer  Basin",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:326,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2021-10-01",BL0_FD:"2022-10-14",PLAN_SD:"2021-10-01",PLAN_FD:"2022-10-31",ACT_SD:"2021-10-01",ACT_FD:"2022-10-31",P6_LV1:"P3",P6_LV2:"Storm Water Pump Staton  and  Accident  Buffer  Basin",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:326,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-05-31",BL0_FD:"2023-02-15",PLAN_SD:"2022-08-02",PLAN_FD:"2023-03-10",ACT_SD:"2022-08-02",ACT_FD:"2022-11-23",P6_LV1:"P3",P6_LV2:"Storm Water Pump Staton  and  Accident  Buffer  Basin",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:326,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-01-30",BL0_FD:"2023-05-15",PLAN_SD:"2022-01-30",PLAN_FD:"2023-04-14",ACT_SD:"2022-01-30",P6_LV1:"P3",P6_LV2:"Storm Water Pump Staton  and  Accident  Buffer  Basin",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:326,P6_LV3_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-01-30",BL0_FD:"2023-03-22",PLAN_SD:"2022-01-30",PLAN_FD:"2023-02-17",ACT_SD:"2022-01-30",P6_LV1:"P3",P6_LV2:"Storm Water Pump Staton  and  Accident  Buffer  Basin",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:326,P6_LV3_SEQ:2,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-01-30",BL0_FD:"2023-06-22",PLAN_SD:"2022-01-30",PLAN_FD:"2023-06-21",ACT_SD:"2022-01-30",P6_LV1:"P3",P6_LV2:"Storm Water Pump Staton  and  Accident  Buffer  Basin",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:326,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-01-30",BL0_FD:"2023-06-12",PLAN_SD:"2022-01-30",PLAN_FD:"2023-07-14",ACT_SD:"2022-01-30",P6_LV1:"P3",P6_LV2:"Storm Water Pump Staton  and  Accident  Buffer  Basin",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:326,P6_LV3_SEQ:2,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-10-21",BL0_FD:"2023-06-12",PLAN_SD:"2022-06-30",PLAN_FD:"2023-06-03",ACT_SD:"2022-06-30",P6_LV1:"P3",P6_LV2:"Storm Water Pump Staton  and  Accident  Buffer  Basin",P6_LV3:"Procurement",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:326,P6_LV3_SEQ:3,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-05-19",BL0_FD:"2023-09-26",PLAN_SD:"2022-04-25",PLAN_FD:"2023-07-12",ACT_SD:"2022-04-25",P6_LV1:"P3",P6_LV2:"Storm Water Pump Staton  and  Accident  Buffer  Basin",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:326,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-12-20",BL0_FD:"2023-07-06",PLAN_SD:"2021-12-20",PLAN_FD:"2023-06-13",ACT_SD:"2021-12-20",P6_LV1:"P3",P6_LV2:"Storm Water Pump Staton  and  Accident  Buffer  Basin",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:326,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2023-02-10",BL0_FD:"2023-07-30",PLAN_SD:"2023-01-18",PLAN_FD:"2023-08-01",P6_LV1:"P3",P6_LV2:"Storm Water Pump Staton  and  Accident  Buffer  Basin",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:326,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-11-18",BL0_FD:"2023-08-21",PLAN_SD:"2022-10-18",PLAN_FD:"2023-09-16",ACT_SD:"2022-10-18",P6_LV1:"P3",P6_LV2:"Storm Water Pump Staton  and  Accident  Buffer  Basin",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:326,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2021-11-01",BL0_FD:"2023-10-26",PLAN_SD:"2021-11-01",PLAN_FD:"2023-09-01",ACT_SD:"2021-11-01",P6_LV1:"P3",P6_LV2:"Storm Water Pump Staton  and  Accident  Buffer  Basin",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:326,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2023-04-17",BL0_FD:"2023-11-06",PLAN_SD:"2023-04-14",PLAN_FD:"2023-10-11",P6_LV1:"P3",P6_LV2:"Storm Water Pump Staton  and  Accident  Buffer  Basin",P6_LV3:"Construction",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:326,P6_LV3_SEQ:4,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-11-09",BL0_FD:"2023-11-01",PLAN_SD:"2022-10-31",PLAN_FD:"2023-08-19",ACT_SD:"2022-10-31",P6_LV1:"P3",P6_LV2:"Storm Water Pump Staton  and  Accident  Buffer  Basin",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:326,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2023-03-20",BL0_FD:"2023-07-31",PLAN_SD:"2023-04-06",PLAN_FD:"2023-06-24",P6_LV1:"P3",P6_LV2:"Storm Water Pump Staton  and  Accident  Buffer  Basin",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:326,P6_LV3_SEQ:4,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2023-07-13",BL0_FD:"2023-09-22",PLAN_SD:"2023-05-04",PLAN_FD:"2023-09-02",P6_LV1:"P3",P6_LV2:"Storm Water Pump Staton  and  Accident  Buffer  Basin",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:326,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-06-29",BL0_FD:"2023-10-26",PLAN_SD:"2023-04-21",PLAN_FD:"2023-11-01",P6_LV1:"P3",P6_LV2:"Storm Water Pump Staton  and  Accident  Buffer  Basin",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:326,P6_LV3_SEQ:4,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Insulation",ACTIVITY_NAME:"Insulation",BL0_SD:"2023-07-05",BL0_FD:"2023-11-10",PLAN_SD:"2023-04-29",PLAN_FD:"2023-08-18",P6_LV1:"P3",P6_LV2:"Storm Water Pump Staton  and  Accident  Buffer  Basin",P6_LV3:"Construction",P6_LV5:"Insulation",LEVEL:"2",ITEM_DESC:"Insulation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:326,P6_LV3_SEQ:4,P6_LV5_SEQ:55,ITEM_SEQ:55},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",PLAN_FD:"2023-11-01",P6_LV1:"P3",P6_LV2:"Storm Water Pump Staton  and  Accident  Buffer  Basin",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:326,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P3",P6_LV2:"Chemicals Storage Warehouse",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2023-11-25",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:328,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P3",P6_LV2:"Chemicals Storage Warehouse",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:328,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P3",P6_LV2:"Chemicals Storage Warehouse",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-03-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:328,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P3",P6_LV2:"Chemicals Storage Warehouse",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2022-07-27",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:328,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P3",P6_LV2:"Chemicals Storage Warehouse",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-03-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:328,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P3",P6_LV2:"Chemicals Storage Warehouse",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-02-07",MS_ACT:"2022-07-17",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:328,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {BL0_SD:"2021-04-01",BL0_FD:"2023-11-15",PLAN_SD:"2021-04-01",PLAN_FD:"2023-11-25",ACT_SD:"2021-04-01",P6_LV1:"P3",P6_LV2:"Chemicals Storage Warehouse",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:328,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"Chemicals Storage Warehouse",ACTIVITY_NAME:"Chemicals Storage Warehouse",BL0_SD:"2021-04-01",BL0_FD:"2023-03-03",PLAN_SD:"2021-04-01",PLAN_FD:"2023-04-17",ACT_SD:"2021-04-01",P6_LV1:"P3",P6_LV2:"Chemicals Storage Warehouse",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:328,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"Chemicals Storage Warehouse",ACTIVITY_NAME:"Chemicals Storage Warehouse",BL0_SD:"2022-03-21",BL0_FD:"2023-06-23",PLAN_SD:"2022-03-21",PLAN_FD:"2023-08-02",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Chemicals Storage Warehouse",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:328,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"Chemicals Storage Warehouse",ACTIVITY_NAME:"Chemicals Storage Warehouse",BL0_SD:"2022-07-18",BL0_FD:"2023-11-15",PLAN_SD:"2022-07-18",PLAN_FD:"2023-11-25",ACT_SD:"2022-07-18",P6_LV1:"P3",P6_LV2:"Chemicals Storage Warehouse",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:328,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-04-01",BL0_FD:"2023-02-24",PLAN_SD:"2021-04-01",PLAN_FD:"2023-02-13",ACT_SD:"2021-04-01",P6_LV1:"P3",P6_LV2:"Chemicals Storage Warehouse",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:328,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-02-07",BL0_FD:"2022-09-19",PLAN_SD:"2022-02-07",PLAN_FD:"2023-01-30",ACT_SD:"2022-02-07",ACT_FD:"2022-08-22",P6_LV1:"P3",P6_LV2:"Chemicals Storage Warehouse",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:328,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-02-07",BL0_FD:"2022-12-01",PLAN_SD:"2022-02-07",PLAN_FD:"2022-11-25",ACT_SD:"2022-02-07",ACT_FD:"2022-11-25",P6_LV1:"P3",P6_LV2:"Chemicals Storage Warehouse",P6_LV3:"Engineering",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:328,P6_LV3_SEQ:2,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-02-07",BL0_FD:"2022-06-27",PLAN_SD:"2022-02-07",PLAN_FD:"2022-07-17",ACT_SD:"2022-02-07",ACT_FD:"2022-07-17",P6_LV1:"P3",P6_LV2:"Chemicals Storage Warehouse",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:328,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-10-20",BL0_FD:"2023-02-10",PLAN_SD:"2022-10-28",PLAN_FD:"2023-02-13",ACT_SD:"2022-10-28",ACT_FD:"2022-11-30",P6_LV1:"P3",P6_LV2:"Chemicals Storage Warehouse",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:328,P6_LV3_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-06-27",BL0_FD:"2023-03-03",PLAN_SD:"2022-06-27",PLAN_FD:"2023-04-10",ACT_SD:"2022-06-27",P6_LV1:"P3",P6_LV2:"Chemicals Storage Warehouse",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:328,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-10-20",BL0_FD:"2023-02-03",PLAN_SD:"2022-10-28",PLAN_FD:"2023-04-17",ACT_SD:"2022-10-28",P6_LV1:"P3",P6_LV2:"Chemicals Storage Warehouse",P6_LV3:"Engineering",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:328,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-06-27",BL0_FD:"2023-01-16",PLAN_SD:"2022-06-27",PLAN_FD:"2023-02-20",ACT_SD:"2022-06-27",ACT_FD:"2022-10-27",P6_LV1:"P3",P6_LV2:"Chemicals Storage Warehouse",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:328,P6_LV3_SEQ:2,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-05-11",BL0_FD:"2022-08-01",PLAN_SD:"2022-05-11",PLAN_FD:"2022-09-15",ACT_SD:"2022-05-11",ACT_FD:"2022-09-15",P6_LV1:"P3",P6_LV2:"Chemicals Storage Warehouse",P6_LV3:"Procurement",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:328,P6_LV3_SEQ:3,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-03-21",BL0_FD:"2022-03-21",PLAN_SD:"2022-03-21",PLAN_FD:"2022-08-25",ACT_SD:"2022-03-21",ACT_FD:"2022-08-25",P6_LV1:"P3",P6_LV2:"Chemicals Storage Warehouse",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:328,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-07-27",BL0_FD:"2023-02-05",PLAN_SD:"2022-07-27",PLAN_FD:"2023-02-24",ACT_SD:"2022-07-27",P6_LV1:"P3",P6_LV2:"Chemicals Storage Warehouse",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:328,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-03-21",BL0_FD:"2023-01-08",PLAN_SD:"2022-03-21",PLAN_FD:"2023-02-24",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Chemicals Storage Warehouse",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:328,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-03-21",BL0_FD:"2022-03-21",PLAN_SD:"2022-03-21",PLAN_FD:"2022-08-25",ACT_SD:"2022-03-21",ACT_FD:"2022-08-25",P6_LV1:"P3",P6_LV2:"Chemicals Storage Warehouse",P6_LV3:"Procurement",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:328,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-03-21",BL0_FD:"2023-06-23",PLAN_SD:"2022-03-21",PLAN_FD:"2023-08-02",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Chemicals Storage Warehouse",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:328,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-07-18",BL0_FD:"2022-09-05",PLAN_SD:"2022-07-18",PLAN_FD:"2022-09-25",ACT_SD:"2022-07-18",ACT_FD:"2022-09-25",P6_LV1:"P3",P6_LV2:"Chemicals Storage Warehouse",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:328,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-08-31",BL0_FD:"2023-03-20",PLAN_SD:"2022-09-30",PLAN_FD:"2023-05-17",ACT_SD:"2022-09-30",P6_LV1:"P3",P6_LV2:"Chemicals Storage Warehouse",P6_LV3:"Construction",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:328,P6_LV3_SEQ:4,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2023-07-06",BL0_FD:"2023-08-02",PLAN_SD:"2023-07-27",PLAN_FD:"2023-08-18",P6_LV1:"P3",P6_LV2:"Chemicals Storage Warehouse",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:328,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-09-05",BL0_FD:"2023-08-22",PLAN_SD:"2022-08-26",PLAN_FD:"2023-10-10",ACT_SD:"2022-08-26",P6_LV1:"P3",P6_LV2:"Chemicals Storage Warehouse",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:328,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2023-07-18",BL0_FD:"2023-08-10",PLAN_SD:"2023-08-08",PLAN_FD:"2023-09-11",P6_LV1:"P3",P6_LV2:"Chemicals Storage Warehouse",P6_LV3:"Construction",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:328,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-07-18",BL0_FD:"2023-11-15",PLAN_SD:"2023-08-08",PLAN_FD:"2023-11-25",P6_LV1:"P3",P6_LV2:"Chemicals Storage Warehouse",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:328,P6_LV3_SEQ:4,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",PLAN_FD:"2023-11-25",P6_LV1:"P3",P6_LV2:"Chemicals Storage Warehouse",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:328,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P3",P6_LV2:"Air Compression Station",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2022-03-21",MS_ACT:"2022-11-26",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:330,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P3",P6_LV2:"Air Compression Station",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-03-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:330,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P3",P6_LV2:"Air Compression Station",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2021-09-01",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:330,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P3",P6_LV2:"Air Compression Station",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-03-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:330,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P3",P6_LV2:"Air Compression Station",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-07-19",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:330,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P3",P6_LV2:"Air Compression Station",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2023-12-13",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:330,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {BL0_SD:"2021-04-01",BL0_FD:"2023-12-07",PLAN_SD:"2021-04-01",PLAN_FD:"2023-10-31",ACT_SD:"2021-04-01",P6_LV1:"P3",P6_LV2:"Air Compression Station",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:330,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"Air Compression Station",ACTIVITY_NAME:"Air Compression Station",BL0_SD:"2021-04-01",BL0_FD:"2023-05-05",PLAN_SD:"2021-04-01",PLAN_FD:"2023-06-22",ACT_SD:"2021-04-01",P6_LV1:"P3",P6_LV2:"Air Compression Station",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:330,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"Air Compression Station",ACTIVITY_NAME:"Air Compression Station",BL0_SD:"2022-02-07",BL0_FD:"2023-07-22",PLAN_SD:"2022-02-01",PLAN_FD:"2023-07-29",ACT_SD:"2022-02-01",P6_LV1:"P3",P6_LV2:"Air Compression Station",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:330,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"Air Compression Station",ACTIVITY_NAME:"Air Compression Station",BL0_SD:"2022-06-15",BL0_FD:"2023-12-07",PLAN_SD:"2022-06-15",PLAN_FD:"2023-10-31",ACT_SD:"2022-06-15",P6_LV1:"P3",P6_LV2:"Air Compression Station",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:330,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-04-01",BL0_FD:"2023-01-30",PLAN_SD:"2021-04-01",PLAN_FD:"2023-02-28",ACT_SD:"2021-04-01",P6_LV1:"P3",P6_LV2:"Air Compression Station",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:330,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-04-08",BL0_FD:"2022-07-05",PLAN_SD:"2022-04-08",PLAN_FD:"2022-09-25",ACT_SD:"2022-04-08",ACT_FD:"2022-09-25",P6_LV1:"P3",P6_LV2:"Air Compression Station",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:330,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-07-05",BL0_FD:"2022-11-04",PLAN_SD:"2022-07-05",PLAN_FD:"2022-11-25",ACT_SD:"2022-07-05",ACT_FD:"2022-11-25",P6_LV1:"P3",P6_LV2:"Air Compression Station",P6_LV3:"Engineering",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:330,P6_LV3_SEQ:2,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-07-19",BL0_FD:"2022-10-28",PLAN_SD:"2022-07-19",PLAN_FD:"2023-02-06",ACT_SD:"2022-07-19",ACT_FD:"2022-11-25",P6_LV1:"P3",P6_LV2:"Air Compression Station",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:330,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-05-09",BL0_FD:"2023-01-30",PLAN_SD:"2022-05-09",PLAN_FD:"2023-02-28",ACT_SD:"2022-05-09",P6_LV1:"P3",P6_LV2:"Air Compression Station",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:330,P6_LV3_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-09-01",BL0_FD:"2022-12-28",PLAN_SD:"2021-09-01",PLAN_FD:"2023-03-20",ACT_SD:"2021-09-01",P6_LV1:"P3",P6_LV2:"Air Compression Station",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:330,P6_LV3_SEQ:2,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-03-21",BL0_FD:"2023-05-05",PLAN_SD:"2022-03-21",PLAN_FD:"2023-06-22",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Air Compression Station",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:330,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-12-02",BL0_FD:"2023-03-06",PLAN_SD:"2023-01-31",PLAN_FD:"2023-05-25",P6_LV1:"P3",P6_LV2:"Air Compression Station",P6_LV3:"Engineering",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:330,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-03-21",BL0_FD:"2023-01-31",PLAN_SD:"2022-03-21",PLAN_FD:"2023-03-06",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Air Compression Station",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:330,P6_LV3_SEQ:2,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-03-21",BL0_FD:"2022-06-15",PLAN_SD:"2022-03-21",PLAN_FD:"2022-06-17",ACT_SD:"2022-03-21",ACT_FD:"2022-06-17",P6_LV1:"P3",P6_LV2:"Air Compression Station",P6_LV3:"Procurement",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:330,P6_LV3_SEQ:3,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-09-26",BL0_FD:"2023-02-05",PLAN_SD:"2022-09-25",PLAN_FD:"2023-02-13",ACT_SD:"2022-09-25",P6_LV1:"P3",P6_LV2:"Air Compression Station",P6_LV3:"Procurement",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:330,P6_LV3_SEQ:3,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-03-21",BL0_FD:"2023-03-03",PLAN_SD:"2022-03-21",PLAN_FD:"2023-03-15",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Air Compression Station",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:330,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-02-07",BL0_FD:"2023-07-22",PLAN_SD:"2022-02-01",PLAN_FD:"2023-07-29",ACT_SD:"2022-02-01",P6_LV1:"P3",P6_LV2:"Air Compression Station",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:330,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-03-21",BL0_FD:"2023-01-13",PLAN_SD:"2022-03-21",PLAN_FD:"2023-03-27",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Air Compression Station",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:330,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-03-21",BL0_FD:"2023-01-13",PLAN_SD:"2022-03-21",PLAN_FD:"2023-03-17",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Air Compression Station",P6_LV3:"Procurement",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:330,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-03-21",BL0_FD:"2023-06-08",PLAN_SD:"2022-03-21",PLAN_FD:"2023-07-02",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Air Compression Station",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:330,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-06-15",BL0_FD:"2023-01-31",PLAN_SD:"2022-06-15",PLAN_FD:"2022-11-26",ACT_SD:"2022-06-15",ACT_FD:"2022-11-26",P6_LV1:"P3",P6_LV2:"Air Compression Station",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:330,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-08-19",BL0_FD:"2023-05-23",PLAN_SD:"2022-08-19",PLAN_FD:"2023-06-08",ACT_SD:"2022-08-19",P6_LV1:"P3",P6_LV2:"Air Compression Station",P6_LV3:"Construction",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:330,P6_LV3_SEQ:4,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2023-04-05",BL0_FD:"2023-08-15",PLAN_SD:"2023-02-14",PLAN_FD:"2023-08-15",P6_LV1:"P3",P6_LV2:"Air Compression Station",P6_LV3:"Construction",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:330,P6_LV3_SEQ:4,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-12-21",BL0_FD:"2023-12-07",PLAN_SD:"2022-10-26",PLAN_FD:"2023-10-31",ACT_SD:"2022-10-26",P6_LV1:"P3",P6_LV2:"Air Compression Station",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:330,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2023-08-01",BL0_FD:"2023-11-10",PLAN_SD:"2023-05-22",PLAN_FD:"2023-09-25",P6_LV1:"P3",P6_LV2:"Air Compression Station",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:330,P6_LV3_SEQ:4,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2023-04-20",BL0_FD:"2023-10-30",PLAN_SD:"2023-04-26",PLAN_FD:"2023-09-29",P6_LV1:"P3",P6_LV2:"Air Compression Station",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:330,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2023-04-18",BL0_FD:"2023-05-24",PLAN_SD:"2023-03-28",PLAN_FD:"2023-05-31",P6_LV1:"P3",P6_LV2:"Air Compression Station",P6_LV3:"Construction",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:330,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-05-17",BL0_FD:"2023-06-14",PLAN_SD:"2023-04-29",PLAN_FD:"2023-07-22",P6_LV1:"P3",P6_LV2:"Air Compression Station",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:330,P6_LV3_SEQ:4,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",PLAN_FD:"2023-10-31",P6_LV1:"P3",P6_LV2:"Air Compression Station",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:330,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P3",P6_LV2:"Administration Office Building",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2024-01-30",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:332,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P3",P6_LV2:"Administration Office Building",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2022-02-07",MS_ACT:"2022-11-11",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:332,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P3",P6_LV2:"Administration Office Building",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-03-14",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:332,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P3",P6_LV2:"Administration Office Building",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2022-03-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:332,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P3",P6_LV2:"Administration Office Building",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-03-14",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:332,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P3",P6_LV2:"Administration Office Building",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-03-19",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:332,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {BL0_SD:"2021-04-01",BL0_FD:"2023-12-23",PLAN_SD:"2021-04-01",PLAN_FD:"2024-01-30",ACT_SD:"2021-04-01",P6_LV1:"P3",P6_LV2:"Administration Office Building",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:332,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"Administration Office Building",ACTIVITY_NAME:"Administration Office Building",BL0_SD:"2021-04-01",BL0_FD:"2023-02-20",PLAN_SD:"2021-04-01",PLAN_FD:"2023-02-22",ACT_SD:"2021-04-01",P6_LV1:"P3",P6_LV2:"Administration Office Building",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:332,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"Administration Office Building",ACTIVITY_NAME:"Administration Office Building",BL0_SD:"2022-03-14",BL0_FD:"2023-06-02",PLAN_SD:"2022-03-14",PLAN_FD:"2023-07-05",ACT_SD:"2022-03-14",P6_LV1:"P3",P6_LV2:"Administration Office Building",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:332,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"Administration Office Building",ACTIVITY_NAME:"Administration Office Building",BL0_SD:"2022-02-07",BL0_FD:"2023-12-23",PLAN_SD:"2022-02-07",PLAN_FD:"2024-01-30",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Administration Office Building",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:332,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-04-01",BL0_FD:"2022-10-26",PLAN_SD:"2021-04-01",PLAN_FD:"2023-02-22",ACT_SD:"2021-04-01",ACT_FD:"2022-08-05",P6_LV1:"P3",P6_LV2:"Administration Office Building",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:332,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-05-20",BL0_FD:"2022-10-26",PLAN_SD:"2022-05-20",PLAN_FD:"2022-10-30",ACT_SD:"2022-05-20",ACT_FD:"2022-10-30",P6_LV1:"P3",P6_LV2:"Administration Office Building",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:332,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-03-09",BL0_FD:"2022-10-26",PLAN_SD:"2022-03-09",PLAN_FD:"2022-11-25",ACT_SD:"2022-03-09",ACT_FD:"2022-11-25",P6_LV1:"P3",P6_LV2:"Administration Office Building",P6_LV3:"Engineering",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:332,P6_LV3_SEQ:2,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-03-19",BL0_FD:"2022-09-23",PLAN_SD:"2022-03-19",PLAN_FD:"2023-01-10",ACT_SD:"2022-03-19",P6_LV1:"P3",P6_LV2:"Administration Office Building",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:332,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-08-12",BL0_FD:"2023-02-20",PLAN_SD:"2022-08-01",PLAN_FD:"2023-02-22",ACT_SD:"2022-08-01",ACT_FD:"2022-10-18",P6_LV1:"P3",P6_LV2:"Administration Office Building",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:332,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-09-23",BL0_FD:"2022-11-16",PLAN_SD:"2022-09-23",PLAN_FD:"2023-01-10",ACT_SD:"2022-09-23",ACT_FD:"2022-12-20",P6_LV1:"P3",P6_LV2:"Administration Office Building",P6_LV3:"Engineering",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:332,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-03-14",BL0_FD:"2022-11-13",PLAN_SD:"2022-03-14",PLAN_FD:"2022-12-11",ACT_SD:"2022-03-14",ACT_FD:"2022-12-11",P6_LV1:"P3",P6_LV2:"Administration Office Building",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:332,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-03-21",BL0_FD:"2023-06-02",PLAN_SD:"2022-03-21",PLAN_FD:"2023-07-05",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Administration Office Building",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:332,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-03-14",BL0_FD:"2022-12-27",PLAN_SD:"2022-03-14",PLAN_FD:"2023-02-20",ACT_SD:"2022-03-14",P6_LV1:"P3",P6_LV2:"Administration Office Building",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:332,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-03-14",BL0_FD:"2023-01-29",PLAN_SD:"2022-03-14",PLAN_FD:"2023-03-10",ACT_SD:"2022-03-14",P6_LV1:"P3",P6_LV2:"Administration Office Building",P6_LV3:"Procurement",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:332,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-02-07",BL0_FD:"2022-07-25",PLAN_SD:"2022-02-07",PLAN_FD:"2022-11-11",ACT_SD:"2022-02-07",ACT_FD:"2022-11-11",P6_LV1:"P3",P6_LV2:"Administration Office Building",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:332,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-07-11",BL0_FD:"2023-07-03",PLAN_SD:"2022-06-15",PLAN_FD:"2024-01-30",ACT_SD:"2022-06-15",P6_LV1:"P3",P6_LV2:"Administration Office Building",P6_LV3:"Construction",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:332,P6_LV3_SEQ:4,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2023-10-09",BL0_FD:"2023-10-09",PLAN_SD:"2023-10-09",PLAN_FD:"2023-12-16",P6_LV1:"P3",P6_LV2:"Administration Office Building",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:332,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-11-12",BL0_FD:"2023-12-23",PLAN_SD:"2022-11-12",PLAN_FD:"2024-01-30",ACT_SD:"2022-11-12",P6_LV1:"P3",P6_LV2:"Administration Office Building",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:332,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2023-07-20",BL0_FD:"2023-07-20",PLAN_SD:"2023-07-18",PLAN_FD:"2023-09-25",P6_LV1:"P3",P6_LV2:"Administration Office Building",P6_LV3:"Construction",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:332,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-11-12",BL0_FD:"2022-11-12",PLAN_SD:"2022-07-25",ACT_SD:"2022-07-25",P6_LV1:"P3",P6_LV2:"Administration Office Building",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:332,P6_LV3_SEQ:4,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",PLAN_FD:"2024-01-30",P6_LV1:"P3",P6_LV2:"Administration Office Building",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:332,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P3",P6_LV2:"Eastward Pipeline",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2022-11-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:334,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P3",P6_LV2:"Eastward Pipeline",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-10-18",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:334,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P3",P6_LV2:"Eastward Pipeline",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2023-12-28",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:334,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {BL0_SD:"2022-02-07",BL0_FD:"2023-12-23",PLAN_SD:"2022-02-07",PLAN_FD:"2024-03-28",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Eastward Pipeline",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:334,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"Eastward Pipeline",ACTIVITY_NAME:"Eastward Pipeline",BL0_SD:"2022-02-07",BL0_FD:"2023-05-05",PLAN_SD:"2022-02-07",PLAN_FD:"2023-05-04",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Eastward Pipeline",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:334,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"Eastward Pipeline",ACTIVITY_NAME:"Eastward Pipeline",BL0_SD:"2022-11-29",BL0_FD:"2023-08-30",PLAN_SD:"2023-01-11",PLAN_FD:"2023-08-22",P6_LV1:"P3",P6_LV2:"Eastward Pipeline",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:334,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"Eastward Pipeline",ACTIVITY_NAME:"Eastward Pipeline",BL0_SD:"2022-11-21",BL0_FD:"2023-12-23",PLAN_SD:"2022-11-21",PLAN_FD:"2024-03-28",ACT_SD:"2022-11-21",P6_LV1:"P3",P6_LV2:"Eastward Pipeline",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:334,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2022-02-07",BL0_FD:"2023-01-11",PLAN_SD:"2022-02-07",PLAN_FD:"2023-05-04",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Eastward Pipeline",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:334,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-10-18",BL0_FD:"2023-05-05",PLAN_SD:"2022-10-18",PLAN_FD:"2023-05-04",ACT_SD:"2022-10-18",P6_LV1:"P3",P6_LV2:"Eastward Pipeline",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:334,P6_LV3_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-11-29",BL0_FD:"2023-08-30",PLAN_SD:"2023-01-11",PLAN_FD:"2023-08-22",P6_LV1:"P3",P6_LV2:"Eastward Pipeline",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:334,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-11-21",BL0_FD:"2023-04-05",PLAN_SD:"2022-11-21",PLAN_FD:"2023-03-02",ACT_SD:"2022-11-21",P6_LV1:"P3",P6_LV2:"Eastward Pipeline",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:334,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2023-04-05",BL0_FD:"2023-12-09",PLAN_SD:"2023-05-18",PLAN_FD:"2024-01-19",P6_LV1:"P3",P6_LV2:"Eastward Pipeline",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:334,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Insulation",ACTIVITY_NAME:"Insulation",BL0_SD:"2023-10-17",BL0_FD:"2023-12-12",PLAN_SD:"2023-10-16",PLAN_FD:"2023-12-16",P6_LV1:"P3",P6_LV2:"Eastward Pipeline",P6_LV3:"Construction",P6_LV5:"Insulation",LEVEL:"2",ITEM_DESC:"Insulation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:334,P6_LV3_SEQ:4,P6_LV5_SEQ:55,ITEM_SEQ:55},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",BL0_SD:"2023-11-11",BL0_FD:"2023-12-23",PLAN_SD:"2023-11-10",PLAN_FD:"2024-03-28",P6_LV1:"P3",P6_LV2:"Eastward Pipeline",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:334,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2024-01-16",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:336,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2022-01-10",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:336,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-03-16",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:336,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2022-01-30",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:336,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-03-16",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:336,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-03-16",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:336,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {BL0_SD:"2021-11-17",BL0_FD:"2023-12-23",PLAN_SD:"2021-12-15",PLAN_FD:"2024-01-16",ACT_SD:"2021-12-15",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:336,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"Wastewater Pre-Treatment Unit",ACTIVITY_NAME:"Wastewater Pre-Treatment Unit",BL0_SD:"2021-11-17",BL0_FD:"2023-06-02",PLAN_SD:"2021-12-15",PLAN_FD:"2023-06-30",ACT_SD:"2021-12-15",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:336,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"Wastewater Pre-Treatment Unit",ACTIVITY_NAME:"Wastewater Pre-Treatment Unit",BL0_SD:"2022-01-30",BL0_FD:"2022-06-28",PLAN_SD:"2022-01-10",PLAN_FD:"2022-10-28",ACT_SD:"2022-01-10",ACT_FD:"2022-10-28",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:336,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"Wastewater Pre-Treatment Unit",ACTIVITY_NAME:"Wastewater Pre-Treatment Unit",BL0_SD:"2022-01-30",BL0_FD:"2023-12-23",PLAN_SD:"2022-01-17",PLAN_FD:"2024-01-16",ACT_SD:"2022-01-17",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:336,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-11-17",BL0_FD:"2023-03-09",PLAN_SD:"2021-12-15",PLAN_FD:"2023-05-11",ACT_SD:"2021-12-15",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:336,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-01-10",BL0_FD:"2023-02-09",PLAN_SD:"2022-01-06",PLAN_FD:"2023-02-13",ACT_SD:"2022-01-06",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:336,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-04-15",BL0_FD:"2022-11-04",PLAN_SD:"2022-04-15",PLAN_FD:"2022-12-22",ACT_SD:"2022-04-15",ACT_FD:"2022-12-22",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit",P6_LV3:"Engineering",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:336,P6_LV3_SEQ:2,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-03-16",BL0_FD:"2023-02-17",PLAN_SD:"2022-03-16",PLAN_FD:"2023-03-15",ACT_SD:"2022-03-16",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:336,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-03-16",BL0_FD:"2023-03-16",PLAN_SD:"2022-01-04",PLAN_FD:"2023-02-13",ACT_SD:"2022-01-04",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:336,P6_LV3_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-03-16",BL0_FD:"2023-04-10",PLAN_SD:"2022-02-18",PLAN_FD:"2023-04-21",ACT_SD:"2022-02-18",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:336,P6_LV3_SEQ:2,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-03-16",BL0_FD:"2023-04-24",PLAN_SD:"2022-03-16",PLAN_FD:"2023-06-30",ACT_SD:"2022-03-16",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:336,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-03-16",BL0_FD:"2023-01-10",PLAN_SD:"2022-04-11",PLAN_FD:"2023-02-13",ACT_SD:"2022-04-11",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit",P6_LV3:"Engineering",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:336,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-03-16",BL0_FD:"2023-06-02",PLAN_SD:"2022-01-03",PLAN_FD:"2023-05-11",ACT_SD:"2022-01-03",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:336,P6_LV3_SEQ:2,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-01-30",BL0_FD:"2022-06-28",PLAN_SD:"2022-01-10",PLAN_FD:"2022-10-28",ACT_SD:"2022-01-10",ACT_FD:"2022-10-28",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit",P6_LV3:"Procurement",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:336,P6_LV3_SEQ:3,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-01-30",BL0_FD:"2022-01-30",PLAN_SD:"2022-01-30",PLAN_FD:"2022-03-31",ACT_SD:"2022-01-30",ACT_FD:"2022-03-31",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:336,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-01-30",BL0_FD:"2023-11-29",PLAN_SD:"2022-01-17",PLAN_FD:"2023-12-08",ACT_SD:"2022-01-17",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:336,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-09-16",BL0_FD:"2023-06-22",PLAN_SD:"2022-10-31",PLAN_FD:"2023-08-19",ACT_SD:"2022-10-31",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit",P6_LV3:"Construction",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:336,P6_LV3_SEQ:4,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2023-02-02",BL0_FD:"2023-08-21",PLAN_SD:"2023-03-23",PLAN_FD:"2023-11-30",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit",P6_LV3:"Construction",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:336,P6_LV3_SEQ:4,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-10-19",BL0_FD:"2023-12-16",PLAN_SD:"2022-11-07",PLAN_FD:"2024-01-16",ACT_SD:"2022-11-07",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:336,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2023-01-07",BL0_FD:"2023-08-29",PLAN_SD:"2023-03-16",PLAN_FD:"2023-11-21",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:336,P6_LV3_SEQ:4,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-12-29",BL0_FD:"2023-12-18",PLAN_SD:"2023-01-04",PLAN_FD:"2024-01-08",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:336,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2023-04-24",BL0_FD:"2023-10-16",PLAN_SD:"2023-05-29",PLAN_FD:"2023-10-09",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit",P6_LV3:"Construction",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:336,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-06-15",BL0_FD:"2023-12-23",PLAN_SD:"2023-07-11",PLAN_FD:"2023-12-29",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:336,P6_LV3_SEQ:4,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Painting",ACTIVITY_NAME:"Painting",BL0_SD:"2023-03-31",BL0_FD:"2023-12-09",PLAN_SD:"2023-03-21",PLAN_FD:"2023-12-21",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit",P6_LV3:"Construction",P6_LV5:"Painting",LEVEL:"2",ITEM_DESC:"Painting",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:336,P6_LV3_SEQ:4,P6_LV5_SEQ:50,ITEM_SEQ:50},
    {ACTIVITY_ID:"Insulation",ACTIVITY_NAME:"Insulation",BL0_SD:"2023-08-01",BL0_FD:"2023-12-09",PLAN_SD:"2023-09-06",PLAN_FD:"2024-01-12",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit",P6_LV3:"Construction",P6_LV5:"Insulation",LEVEL:"2",ITEM_DESC:"Insulation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:336,P6_LV3_SEQ:4,P6_LV5_SEQ:55,ITEM_SEQ:55},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",PLAN_FD:"2024-01-16",P6_LV1:"P3",P6_LV2:"Wastewater Pre-Treatment Unit",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:336,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P3",P6_LV2:"Maintenance Workshop",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2022-05-12",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:338,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P3",P6_LV2:"Maintenance Workshop",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:338,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P3",P6_LV2:"Maintenance Workshop",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2022-04-12",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:338,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P3",P6_LV2:"Maintenance Workshop",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-05-12",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:338,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P3",P6_LV2:"Maintenance Workshop",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-03-31",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:338,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P3",P6_LV2:"Maintenance Workshop",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2024-01-15",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:338,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {BL0_SD:"2021-04-01",BL0_FD:"2023-12-26",PLAN_SD:"2021-04-01",PLAN_FD:"2024-01-15",ACT_SD:"2021-04-01",P6_LV1:"P3",P6_LV2:"Maintenance Workshop",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:338,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"Maintenance Workshop",ACTIVITY_NAME:"Maintenance Workshop",BL0_SD:"2021-04-01",BL0_FD:"2023-03-29",PLAN_SD:"2021-04-01",PLAN_FD:"2023-03-27",ACT_SD:"2021-04-01",P6_LV1:"P3",P6_LV2:"Maintenance Workshop",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:338,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"Maintenance Workshop",ACTIVITY_NAME:"Maintenance Workshop",BL0_SD:"2022-02-07",BL0_FD:"2023-08-14",PLAN_SD:"2022-05-10",PLAN_FD:"2023-09-29",ACT_SD:"2022-05-10",P6_LV1:"P3",P6_LV2:"Maintenance Workshop",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:338,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"Maintenance Workshop",ACTIVITY_NAME:"Maintenance Workshop",BL0_SD:"2022-05-12",BL0_FD:"2023-12-26",PLAN_SD:"2022-05-10",PLAN_FD:"2024-01-15",ACT_SD:"2022-05-10",P6_LV1:"P3",P6_LV2:"Maintenance Workshop",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:338,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-04-01",BL0_FD:"2022-11-17",PLAN_SD:"2021-04-01",PLAN_FD:"2022-12-25",ACT_SD:"2021-04-01",ACT_FD:"2022-12-25",P6_LV1:"P3",P6_LV2:"Maintenance Workshop",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:338,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-05-16",BL0_FD:"2022-11-04",PLAN_SD:"2022-05-16",PLAN_FD:"2022-12-30",ACT_SD:"2022-05-16",ACT_FD:"2022-12-25",P6_LV1:"P3",P6_LV2:"Maintenance Workshop",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:338,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-08-08",BL0_FD:"2022-10-20",PLAN_SD:"2022-08-08",PLAN_FD:"2022-11-25",ACT_SD:"2022-08-08",ACT_FD:"2022-11-25",P6_LV1:"P3",P6_LV2:"Maintenance Workshop",P6_LV3:"Engineering",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:338,P6_LV3_SEQ:2,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-03-31",BL0_FD:"2022-09-30",PLAN_SD:"2022-03-31",PLAN_FD:"2023-01-04",ACT_SD:"2022-03-31",ACT_FD:"2022-07-15",P6_LV1:"P3",P6_LV2:"Maintenance Workshop",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:338,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-08-08",BL0_FD:"2022-12-21",PLAN_SD:"2022-08-08",PLAN_FD:"2023-02-24",ACT_SD:"2022-08-08",P6_LV1:"P3",P6_LV2:"Maintenance Workshop",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:338,P6_LV3_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-04-12",BL0_FD:"2023-03-29",PLAN_SD:"2022-03-15",PLAN_FD:"2023-03-23",ACT_SD:"2022-03-15",P6_LV1:"P3",P6_LV2:"Maintenance Workshop",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:338,P6_LV3_SEQ:2,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-07-25",BL0_FD:"2023-01-16",PLAN_SD:"2022-07-25",PLAN_FD:"2023-03-27",ACT_SD:"2022-07-25",P6_LV1:"P3",P6_LV2:"Maintenance Workshop",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:338,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-10-20",BL0_FD:"2022-12-29",PLAN_SD:"2022-11-01",PLAN_FD:"2023-01-10",ACT_SD:"2022-11-01",ACT_FD:"2022-11-25",P6_LV1:"P3",P6_LV2:"Maintenance Workshop",P6_LV3:"Engineering",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:338,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-09-19",BL0_FD:"2023-01-16",PLAN_SD:"2022-09-19",PLAN_FD:"2022-12-23",ACT_SD:"2022-09-19",ACT_FD:"2022-12-23",P6_LV1:"P3",P6_LV2:"Maintenance Workshop",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:338,P6_LV3_SEQ:2,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-05-12",BL0_FD:"2022-12-04",PLAN_SD:"2022-05-10",PLAN_FD:"2023-01-14",ACT_SD:"2022-05-10",ACT_FD:"2022-12-12",P6_LV1:"P3",P6_LV2:"Maintenance Workshop",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:338,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-09-15",BL0_FD:"2023-08-14",PLAN_SD:"2022-06-01",PLAN_FD:"2023-09-29",ACT_SD:"2022-06-01",P6_LV1:"P3",P6_LV2:"Maintenance Workshop",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:338,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-02-07",BL0_FD:"2023-02-07",PLAN_SD:"2022-05-10",PLAN_FD:"2023-06-11",ACT_SD:"2022-05-10",P6_LV1:"P3",P6_LV2:"Maintenance Workshop",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:338,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-05-12",BL0_FD:"2023-03-09",PLAN_SD:"2022-05-10",PLAN_FD:"2023-03-31",ACT_SD:"2022-05-10",P6_LV1:"P3",P6_LV2:"Maintenance Workshop",P6_LV3:"Procurement",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:338,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-05-12",BL0_FD:"2023-03-19",PLAN_SD:"2022-05-10",PLAN_FD:"2023-05-12",ACT_SD:"2022-05-10",P6_LV1:"P3",P6_LV2:"Maintenance Workshop",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:338,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-05-12",BL0_FD:"2022-09-17",PLAN_SD:"2022-05-10",PLAN_FD:"2022-09-25",ACT_SD:"2022-05-10",ACT_FD:"2022-09-25",P6_LV1:"P3",P6_LV2:"Maintenance Workshop",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:338,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-08-08",BL0_FD:"2023-07-20",PLAN_SD:"2022-10-10",PLAN_FD:"2023-10-23",ACT_SD:"2022-10-10",P6_LV1:"P3",P6_LV2:"Maintenance Workshop",P6_LV3:"Construction",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:338,P6_LV3_SEQ:4,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2023-04-20",BL0_FD:"2023-12-26",PLAN_SD:"2023-05-13",PLAN_FD:"2024-01-15",P6_LV1:"P3",P6_LV2:"Maintenance Workshop",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:338,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2023-07-20",BL0_FD:"2023-11-07",PLAN_SD:"2023-04-28",PLAN_FD:"2023-12-08",P6_LV1:"P3",P6_LV2:"Maintenance Workshop",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:338,P6_LV3_SEQ:4,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2023-04-20",BL0_FD:"2023-09-28",PLAN_SD:"2023-05-13",PLAN_FD:"2023-11-21",P6_LV1:"P3",P6_LV2:"Maintenance Workshop",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:338,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2023-06-15",BL0_FD:"2023-06-15",PLAN_SD:"2023-07-05",PLAN_FD:"2023-08-19",P6_LV1:"P3",P6_LV2:"Maintenance Workshop",P6_LV3:"Construction",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:338,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-07-20",BL0_FD:"2023-09-28",PLAN_SD:"2023-08-09",PLAN_FD:"2023-11-15",P6_LV1:"P3",P6_LV2:"Maintenance Workshop",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:338,P6_LV3_SEQ:4,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",PLAN_FD:"2024-01-15",P6_LV1:"P3",P6_LV2:"Maintenance Workshop",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:338,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P3",P6_LV2:"Central Laboratory and Environmental Monitoring Station",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2024-03-04",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:340,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P3",P6_LV2:"Central Laboratory and Environmental Monitoring Station",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2022-02-07",MS_ACT:"2022-10-28",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:340,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P3",P6_LV2:"Central Laboratory and Environmental Monitoring Station",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-03-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:340,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P3",P6_LV2:"Central Laboratory and Environmental Monitoring Station",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2022-03-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:340,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P3",P6_LV2:"Central Laboratory and Environmental Monitoring Station",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-03-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:340,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P3",P6_LV2:"Central Laboratory and Environmental Monitoring Station",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-02-07",MS_ACT:"2022-09-23",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:340,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {BL0_SD:"2021-04-01",BL0_FD:"2024-02-07",PLAN_SD:"2021-04-01",PLAN_FD:"2024-03-04",ACT_SD:"2021-04-01",P6_LV1:"P3",P6_LV2:"Central Laboratory and Environmental Monitoring Station",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:340,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"Central Laboratory and Environmental Monitoring Station",ACTIVITY_NAME:"Central Laboratory and Environmental Monitoring Station",BL0_SD:"2021-04-01",BL0_FD:"2023-04-20",PLAN_SD:"2021-04-01",PLAN_FD:"2023-05-25",ACT_SD:"2021-04-01",P6_LV1:"P3",P6_LV2:"Central Laboratory and Environmental Monitoring Station",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:340,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"Central Laboratory and Environmental Monitoring Station",ACTIVITY_NAME:"Central Laboratory and Environmental Monitoring Station",BL0_SD:"2022-03-07",BL0_FD:"2024-02-07",PLAN_SD:"2022-03-07",PLAN_FD:"2024-02-03",ACT_SD:"2022-03-07",P6_LV1:"P3",P6_LV2:"Central Laboratory and Environmental Monitoring Station",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:340,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"Central Laboratory and Environmental Monitoring Station",ACTIVITY_NAME:"Central Laboratory and Environmental Monitoring Station",BL0_SD:"2022-05-16",BL0_FD:"2023-12-15",PLAN_SD:"2022-05-17",PLAN_FD:"2024-03-04",ACT_SD:"2022-05-17",P6_LV1:"P3",P6_LV2:"Central Laboratory and Environmental Monitoring Station",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:340,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-04-01",BL0_FD:"2022-08-15",PLAN_SD:"2021-04-01",PLAN_FD:"2022-10-01",ACT_SD:"2021-04-01",ACT_FD:"2022-10-01",P6_LV1:"P3",P6_LV2:"Central Laboratory and Environmental Monitoring Station",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:340,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-02-07",BL0_FD:"2022-08-26",PLAN_SD:"2022-02-07",PLAN_FD:"2022-10-28",ACT_SD:"2022-02-07",ACT_FD:"2022-10-28",P6_LV1:"P3",P6_LV2:"Central Laboratory and Environmental Monitoring Station",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:340,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-06-06",BL0_FD:"2022-09-12",PLAN_SD:"2022-06-06",PLAN_FD:"2022-11-25",ACT_SD:"2022-06-06",ACT_FD:"2022-11-25",P6_LV1:"P3",P6_LV2:"Central Laboratory and Environmental Monitoring Station",P6_LV3:"Engineering",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:340,P6_LV3_SEQ:2,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-02-07",BL0_FD:"2022-07-29",PLAN_SD:"2022-02-07",PLAN_FD:"2022-09-23",ACT_SD:"2022-02-07",ACT_FD:"2022-09-23",P6_LV1:"P3",P6_LV2:"Central Laboratory and Environmental Monitoring Station",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:340,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-09-12",BL0_FD:"2022-10-27",PLAN_SD:"2022-10-31",PLAN_FD:"2022-12-20",ACT_SD:"2022-10-31",ACT_FD:"2022-12-20",P6_LV1:"P3",P6_LV2:"Central Laboratory and Environmental Monitoring Station",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:340,P6_LV3_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-03-21",BL0_FD:"2022-06-29",PLAN_SD:"2022-03-21",PLAN_FD:"2022-08-09",ACT_SD:"2022-03-21",ACT_FD:"2022-08-09",P6_LV1:"P3",P6_LV2:"Central Laboratory and Environmental Monitoring Station",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:340,P6_LV3_SEQ:2,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-06-06",BL0_FD:"2022-12-22",PLAN_SD:"2022-06-06",PLAN_FD:"2023-05-25",ACT_SD:"2022-06-06",P6_LV1:"P3",P6_LV2:"Central Laboratory and Environmental Monitoring Station",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:340,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-09-12",BL0_FD:"2022-11-24",PLAN_SD:"2022-09-26",PLAN_FD:"2022-12-26",ACT_SD:"2022-09-26",ACT_FD:"2022-11-26",P6_LV1:"P3",P6_LV2:"Central Laboratory and Environmental Monitoring Station",P6_LV3:"Engineering",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:340,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-03-14",BL0_FD:"2023-04-20",PLAN_SD:"2022-03-14",PLAN_FD:"2023-04-10",ACT_SD:"2022-03-14",P6_LV1:"P3",P6_LV2:"Central Laboratory and Environmental Monitoring Station",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:340,P6_LV3_SEQ:2,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-03-07",BL0_FD:"2022-04-15",PLAN_SD:"2022-03-07",PLAN_FD:"2022-06-23",ACT_SD:"2022-03-07",ACT_FD:"2022-06-23",P6_LV1:"P3",P6_LV2:"Central Laboratory and Environmental Monitoring Station",P6_LV3:"Procurement",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:340,P6_LV3_SEQ:3,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-03-21",BL0_FD:"2023-07-12",PLAN_SD:"2022-03-21",PLAN_FD:"2023-08-13",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Central Laboratory and Environmental Monitoring Station",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:340,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-03-21",BL0_FD:"2024-02-07",PLAN_SD:"2022-03-21",PLAN_FD:"2024-02-03",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Central Laboratory and Environmental Monitoring Station",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:340,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-03-21",BL0_FD:"2023-01-15",PLAN_SD:"2022-03-21",PLAN_FD:"2023-04-06",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Central Laboratory and Environmental Monitoring Station",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:340,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-03-21",BL0_FD:"2023-02-07",PLAN_SD:"2022-03-21",PLAN_FD:"2023-04-18",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Central Laboratory and Environmental Monitoring Station",P6_LV3:"Procurement",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:340,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-03-21",BL0_FD:"2023-06-24",PLAN_SD:"2022-03-21",PLAN_FD:"2023-09-11",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Central Laboratory and Environmental Monitoring Station",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:340,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-05-16",BL0_FD:"2022-07-16",PLAN_SD:"2022-05-17",PLAN_FD:"2022-08-11",ACT_SD:"2022-05-17",ACT_FD:"2022-08-11",P6_LV1:"P3",P6_LV2:"Central Laboratory and Environmental Monitoring Station",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:340,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-06-23",BL0_FD:"2023-07-29",PLAN_SD:"2022-08-08",PLAN_FD:"2024-03-04",ACT_SD:"2022-08-08",P6_LV1:"P3",P6_LV2:"Central Laboratory and Environmental Monitoring Station",P6_LV3:"Construction",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:340,P6_LV3_SEQ:4,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2023-05-22",BL0_FD:"2023-05-22",PLAN_SD:"2023-07-10",PLAN_FD:"2023-09-05",P6_LV1:"P3",P6_LV2:"Central Laboratory and Environmental Monitoring Station",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:340,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2023-09-19",BL0_FD:"2023-12-15",PLAN_SD:"2023-09-12",PLAN_FD:"2024-03-04",P6_LV1:"P3",P6_LV2:"Central Laboratory and Environmental Monitoring Station",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:340,P6_LV3_SEQ:4,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-09-30",BL0_FD:"2023-09-15",PLAN_SD:"2022-11-07",PLAN_FD:"2023-10-30",ACT_SD:"2022-11-07",P6_LV1:"P3",P6_LV2:"Central Laboratory and Environmental Monitoring Station",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:340,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2023-04-01",BL0_FD:"2023-04-01",PLAN_SD:"2023-04-22",PLAN_FD:"2023-06-10",P6_LV1:"P3",P6_LV2:"Central Laboratory and Environmental Monitoring Station",P6_LV3:"Construction",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:340,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-09-15",BL0_FD:"2023-11-07",PLAN_SD:"2023-10-19",PLAN_FD:"2024-01-11",P6_LV1:"P3",P6_LV2:"Central Laboratory and Environmental Monitoring Station",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:340,P6_LV3_SEQ:4,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",PLAN_FD:"2024-03-04",P6_LV1:"P3",P6_LV2:"Central Laboratory and Environmental Monitoring Station",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:340,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:342,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:342,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2021-12-20",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:342,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:342,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-02-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:342,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2024-04-04",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:342,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {BL0_SD:"2021-12-16",BL0_FD:"2024-03-15",PLAN_SD:"2021-12-16",PLAN_FD:"2024-02-29",ACT_SD:"2021-12-16",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:342,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"Cooling Water Plant 1",ACTIVITY_NAME:"Cooling Water Plant 1",BL0_SD:"2021-12-16",BL0_FD:"2023-04-25",PLAN_SD:"2021-12-16",PLAN_FD:"2023-06-08",ACT_SD:"2021-12-16",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:342,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"Cooling Water Plant 1",ACTIVITY_NAME:"Cooling Water Plant 1",BL0_SD:"2021-12-20",BL0_FD:"2023-12-22",PLAN_SD:"2021-12-20",PLAN_FD:"2023-11-24",ACT_SD:"2021-12-20",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:342,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"Cooling Water Plant 1",ACTIVITY_NAME:"Cooling Water Plant 1",BL0_SD:"2022-04-25",BL0_FD:"2024-03-15",PLAN_SD:"2022-04-25",PLAN_FD:"2024-02-29",ACT_SD:"2022-04-25",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:342,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-12-16",BL0_FD:"2023-01-17",PLAN_SD:"2021-12-16",PLAN_FD:"2023-05-22",ACT_SD:"2021-12-16",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:342,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-02-07",BL0_FD:"2022-11-21",PLAN_SD:"2022-02-07",PLAN_FD:"2023-02-20",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:342,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-02-21",BL0_FD:"2022-07-26",PLAN_SD:"2022-02-21",PLAN_FD:"2023-01-17",ACT_SD:"2022-02-21",ACT_FD:"2022-09-23",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",P6_LV3:"Engineering",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:342,P6_LV3_SEQ:2,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-02-21",BL0_FD:"2023-02-27",PLAN_SD:"2022-02-21",PLAN_FD:"2023-05-11",ACT_SD:"2022-02-21",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:342,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-02-07",BL0_FD:"2023-02-06",PLAN_SD:"2022-02-07",PLAN_FD:"2023-04-03",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:342,P6_LV3_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-01-21",BL0_FD:"2022-12-30",PLAN_SD:"2022-01-21",PLAN_FD:"2023-04-27",ACT_SD:"2022-01-21",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:342,P6_LV3_SEQ:2,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-02-07",BL0_FD:"2023-04-25",PLAN_SD:"2022-02-07",PLAN_FD:"2023-06-01",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:342,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-02-21",BL0_FD:"2022-10-20",PLAN_SD:"2022-02-21",PLAN_FD:"2023-04-21",ACT_SD:"2022-02-21",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",P6_LV3:"Engineering",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:342,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-02-07",BL0_FD:"2023-04-03",PLAN_SD:"2022-02-07",PLAN_FD:"2023-06-08",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:342,P6_LV3_SEQ:2,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-12-24",BL0_FD:"2023-06-17",PLAN_SD:"2022-10-23",PLAN_FD:"2023-05-14",ACT_SD:"2022-10-23",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",P6_LV3:"Procurement",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:342,P6_LV3_SEQ:3,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-06-21",BL0_FD:"2023-12-22",PLAN_SD:"2022-04-14",PLAN_FD:"2023-11-24",ACT_SD:"2022-04-14",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:342,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-12-20",BL0_FD:"2023-08-19",PLAN_SD:"2021-12-20",PLAN_FD:"2023-10-25",ACT_SD:"2021-12-20",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:342,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-12-16",BL0_FD:"2023-10-08",PLAN_SD:"2022-10-25",PLAN_FD:"2023-10-17",ACT_SD:"2022-10-25",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:342,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-08-17",BL0_FD:"2023-02-12",PLAN_SD:"2023-01-11",PLAN_FD:"2023-07-26",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",P6_LV3:"Procurement",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:342,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-08-17",BL0_FD:"2023-09-18",PLAN_SD:"2022-09-10",PLAN_FD:"2023-10-22",ACT_SD:"2022-09-10",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:342,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-04-25",BL0_FD:"2023-06-10",PLAN_SD:"2022-04-25",PLAN_FD:"2023-08-23",ACT_SD:"2022-04-25",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:342,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-09-02",BL0_FD:"2023-07-25",PLAN_SD:"2022-09-01",PLAN_FD:"2023-08-04",ACT_SD:"2022-09-01",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",P6_LV3:"Construction",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:342,P6_LV3_SEQ:4,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-10-29",BL0_FD:"2023-11-22",PLAN_SD:"2023-02-14",PLAN_FD:"2023-10-24",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",P6_LV3:"Construction",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:342,P6_LV3_SEQ:4,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-11-24",BL0_FD:"2024-02-06",PLAN_SD:"2022-11-22",PLAN_FD:"2024-02-17",ACT_SD:"2022-11-22",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:342,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2023-05-15",BL0_FD:"2024-03-15",PLAN_SD:"2023-04-27",PLAN_FD:"2024-02-29",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:342,P6_LV3_SEQ:4,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2023-06-06",BL0_FD:"2024-01-30",PLAN_SD:"2023-05-13",PLAN_FD:"2024-01-20",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:342,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2023-06-06",BL0_FD:"2023-09-16",PLAN_SD:"2023-07-26",PLAN_FD:"2023-12-05",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",P6_LV3:"Construction",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:342,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-04-28",BL0_FD:"2024-01-29",PLAN_SD:"2023-08-07",PLAN_FD:"2024-02-17",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:342,P6_LV3_SEQ:4,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Insulation",ACTIVITY_NAME:"Insulation",BL0_SD:"2023-07-03",BL0_FD:"2023-10-21",PLAN_SD:"2023-10-14",PLAN_FD:"2024-02-19",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",P6_LV3:"Construction",P6_LV5:"Insulation",LEVEL:"2",ITEM_DESC:"Insulation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:342,P6_LV3_SEQ:4,P6_LV5_SEQ:55,ITEM_SEQ:55},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",BL0_SD:"2024-02-01",BL0_FD:"2024-03-02",PLAN_SD:"2023-10-31",PLAN_FD:"2024-02-29",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 1",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:342,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 2",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2024-04-04",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:346,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 2",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:346,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 2",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:346,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 2",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2021-12-20",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:346,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 2",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:346,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 2",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-02-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:346,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {BL0_SD:"2021-12-16",BL0_FD:"2024-03-22",PLAN_SD:"2021-12-16",PLAN_FD:"2024-02-29",ACT_SD:"2021-12-16",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 2",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:346,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"Cooling Water Plant 2",ACTIVITY_NAME:"Cooling Water Plant 2",BL0_SD:"2021-12-16",BL0_FD:"2023-04-18",PLAN_SD:"2021-12-16",PLAN_FD:"2023-08-17",ACT_SD:"2021-12-16",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 2",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:346,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"Cooling Water Plant 2",ACTIVITY_NAME:"Cooling Water Plant 2",BL0_SD:"2021-12-20",BL0_FD:"2023-10-25",PLAN_SD:"2021-12-20",PLAN_FD:"2023-11-21",ACT_SD:"2021-12-20",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 2",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:346,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"Cooling Water Plant 2",ACTIVITY_NAME:"Cooling Water Plant 2",BL0_SD:"2022-03-14",BL0_FD:"2024-03-22",PLAN_SD:"2022-03-14",PLAN_FD:"2024-02-29",ACT_SD:"2022-03-14",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 2",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:346,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-12-16",BL0_FD:"2023-01-31",PLAN_SD:"2021-12-16",PLAN_FD:"2023-08-17",ACT_SD:"2021-12-16",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 2",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:346,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-02-07",BL0_FD:"2023-02-22",PLAN_SD:"2022-02-07",PLAN_FD:"2023-01-17",ACT_SD:"2022-02-07",ACT_FD:"2022-12-22",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 2",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:346,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-02-07",BL0_FD:"2022-06-28",PLAN_SD:"2022-02-07",PLAN_FD:"2023-01-30",ACT_SD:"2022-02-07",ACT_FD:"2022-09-30",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 2",P6_LV3:"Engineering",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:346,P6_LV3_SEQ:2,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-02-21",BL0_FD:"2023-01-11",PLAN_SD:"2022-02-21",PLAN_FD:"2023-02-13",ACT_SD:"2022-02-21",ACT_FD:"2022-12-09",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 2",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:346,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-02-07",BL0_FD:"2023-02-07",PLAN_SD:"2022-02-07",PLAN_FD:"2023-04-10",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 2",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:346,P6_LV3_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-01-14",BL0_FD:"2022-12-26",PLAN_SD:"2022-01-14",PLAN_FD:"2023-04-04",ACT_SD:"2022-01-14",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 2",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:346,P6_LV3_SEQ:2,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-02-07",BL0_FD:"2023-04-18",PLAN_SD:"2022-02-07",PLAN_FD:"2023-08-17",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 2",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:346,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-02-07",BL0_FD:"2023-04-18",PLAN_SD:"2022-02-07",PLAN_FD:"2023-06-01",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 2",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:346,P6_LV3_SEQ:2,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-11-05",BL0_FD:"2023-04-24",PLAN_SD:"2022-10-26",PLAN_FD:"2023-05-13",ACT_SD:"2022-10-26",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 2",P6_LV3:"Procurement",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:346,P6_LV3_SEQ:3,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-07-12",BL0_FD:"2023-09-15",PLAN_SD:"2022-04-13",PLAN_FD:"2023-08-13",ACT_SD:"2022-04-13",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 2",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:346,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-12-20",BL0_FD:"2023-08-17",PLAN_SD:"2021-12-20",PLAN_FD:"2023-10-11",ACT_SD:"2021-12-20",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 2",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:346,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-10-14",BL0_FD:"2023-06-12",PLAN_SD:"2022-10-10",PLAN_FD:"2023-10-07",ACT_SD:"2022-10-10",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 2",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:346,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-08-31",BL0_FD:"2023-10-25",PLAN_SD:"2022-09-17",PLAN_FD:"2023-11-21",ACT_SD:"2022-09-17",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 2",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:346,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-03-14",BL0_FD:"2023-08-08",PLAN_SD:"2022-03-14",PLAN_FD:"2023-08-19",ACT_SD:"2022-03-14",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 2",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:346,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-11-22",BL0_FD:"2023-07-11",PLAN_SD:"2022-11-02",PLAN_FD:"2023-08-09",ACT_SD:"2022-11-02",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 2",P6_LV3:"Construction",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:346,P6_LV3_SEQ:4,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-08-24",BL0_FD:"2023-08-17",PLAN_SD:"2022-08-27",PLAN_FD:"2023-09-16",ACT_SD:"2022-08-27",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 2",P6_LV3:"Construction",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:346,P6_LV3_SEQ:4,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-12-06",BL0_FD:"2024-03-16",PLAN_SD:"2022-11-14",PLAN_FD:"2024-02-27",ACT_SD:"2022-11-14",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 2",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:346,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-12-19",BL0_FD:"2024-02-08",PLAN_SD:"2023-04-06",PLAN_FD:"2024-01-24",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 2",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:346,P6_LV3_SEQ:4,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2023-06-05",BL0_FD:"2024-02-01",PLAN_SD:"2022-12-26",PLAN_FD:"2024-02-06",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 2",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:346,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-05-08",BL0_FD:"2024-02-27",PLAN_SD:"2023-06-02",PLAN_FD:"2024-02-22",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 2",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:346,P6_LV3_SEQ:4,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Insulation",ACTIVITY_NAME:"Insulation",BL0_SD:"2023-12-18",BL0_FD:"2024-03-22",PLAN_SD:"2023-11-13",PLAN_FD:"2024-02-29",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 2",P6_LV3:"Construction",P6_LV5:"Insulation",LEVEL:"2",ITEM_DESC:"Insulation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:346,P6_LV3_SEQ:4,P6_LV5_SEQ:55,ITEM_SEQ:55},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",BL0_SD:"2023-12-10",BL0_FD:"2024-03-19",PLAN_SD:"2023-11-30",PLAN_FD:"2024-02-29",P6_LV1:"P3",P6_LV2:"Cooling Water Plant 2",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:346,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2022-02-07",MS_ACT:"2022-09-20",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:348,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-03-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:348,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2022-03-04",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:348,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-03-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:348,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:348,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2024-04-09",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:348,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {BL0_SD:"2021-04-01",BL0_FD:"2024-03-30",PLAN_SD:"2021-04-01",PLAN_FD:"2024-03-30",ACT_SD:"2021-04-01",P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:348,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"Loading / Unloading Station",ACTIVITY_NAME:"Loading / Unloading Station",BL0_SD:"2021-04-01",BL0_FD:"2023-01-11",PLAN_SD:"2021-04-01",PLAN_FD:"2023-02-27",ACT_SD:"2021-04-01",P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:348,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"Loading / Unloading Station",ACTIVITY_NAME:"Loading / Unloading Station",BL0_SD:"2022-02-07",BL0_FD:"2023-07-07",PLAN_SD:"2022-02-07",PLAN_FD:"2023-09-20",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:348,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"Loading / Unloading Station",ACTIVITY_NAME:"Loading / Unloading Station",BL0_SD:"2022-04-25",BL0_FD:"2024-03-30",PLAN_SD:"2022-04-25",PLAN_FD:"2024-03-30",ACT_SD:"2022-04-25",P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:348,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-04-01",BL0_FD:"2023-01-11",PLAN_SD:"2021-04-01",PLAN_FD:"2023-02-13",ACT_SD:"2021-04-01",P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:348,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-03-05",BL0_FD:"2022-07-13",PLAN_SD:"2022-03-05",PLAN_FD:"2022-09-09",ACT_SD:"2022-03-05",ACT_FD:"2022-09-09",P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:348,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-02-07",BL0_FD:"2022-09-19",PLAN_SD:"2022-02-07",PLAN_FD:"2022-11-25",ACT_SD:"2022-02-07",ACT_FD:"2022-11-25",P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",P6_LV3:"Engineering",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:348,P6_LV3_SEQ:2,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-02-07",BL0_FD:"2022-08-24",PLAN_SD:"2022-02-07",PLAN_FD:"2023-01-10",ACT_SD:"2022-02-07",ACT_FD:"2022-03-04",P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:348,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-10-17",BL0_FD:"2022-12-12",PLAN_SD:"2022-10-17",PLAN_FD:"2023-01-30",ACT_SD:"2022-10-17",ACT_FD:"2022-11-26",P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:348,P6_LV3_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-03-04",BL0_FD:"2022-11-14",PLAN_SD:"2022-03-04",PLAN_FD:"2023-01-30",ACT_SD:"2022-03-04",ACT_FD:"2022-11-30",P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:348,P6_LV3_SEQ:2,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-06-06",BL0_FD:"2022-12-22",PLAN_SD:"2022-06-06",PLAN_FD:"2023-02-27",ACT_SD:"2022-06-06",ACT_FD:"2022-12-04",P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:348,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-09-12",BL0_FD:"2022-11-24",PLAN_SD:"2022-10-31",PLAN_FD:"2023-01-17",ACT_SD:"2022-10-31",P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",P6_LV3:"Engineering",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:348,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-06-06",BL0_FD:"2022-10-27",PLAN_SD:"2022-06-06",PLAN_FD:"2023-01-17",ACT_SD:"2022-06-06",ACT_FD:"2022-11-25",P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:348,P6_LV3_SEQ:2,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-02-07",BL0_FD:"2022-06-03",PLAN_SD:"2022-02-07",PLAN_FD:"2022-07-18",ACT_SD:"2022-02-07",ACT_FD:"2022-07-18",P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",P6_LV3:"Procurement",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:348,P6_LV3_SEQ:3,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-03-21",BL0_FD:"2023-01-13",PLAN_SD:"2022-03-21",PLAN_FD:"2023-04-10",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",P6_LV3:"Procurement",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:348,P6_LV3_SEQ:3,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-03-21",BL0_FD:"2023-02-20",PLAN_SD:"2022-03-21",PLAN_FD:"2023-04-24",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:348,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-03-21",BL0_FD:"2023-05-27",PLAN_SD:"2022-03-21",PLAN_FD:"2023-07-27",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:348,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-03-21",BL0_FD:"2022-11-21",PLAN_SD:"2022-03-21",PLAN_FD:"2023-02-19",ACT_SD:"2022-03-21",ACT_FD:"2022-10-12",P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:348,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-03-21",BL0_FD:"2023-02-19",PLAN_SD:"2022-03-21",PLAN_FD:"2023-03-29",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",P6_LV3:"Procurement",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:348,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-03-21",BL0_FD:"2023-07-07",PLAN_SD:"2022-03-21",PLAN_FD:"2023-09-20",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:348,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-04-25",BL0_FD:"2023-02-06",PLAN_SD:"2022-04-25",PLAN_FD:"2022-09-20",ACT_SD:"2022-04-25",ACT_FD:"2022-09-20",P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:348,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2023-02-06",BL0_FD:"2023-07-22",PLAN_SD:"2022-10-24",PLAN_FD:"2023-07-27",ACT_SD:"2022-10-24",P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",P6_LV3:"Construction",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:348,P6_LV3_SEQ:4,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2023-04-17",BL0_FD:"2023-04-17",PLAN_SD:"2023-04-11",P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",P6_LV3:"Construction",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:348,P6_LV3_SEQ:4,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2023-03-13",BL0_FD:"2023-10-27",PLAN_SD:"2022-12-31",PLAN_FD:"2023-11-14",P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:348,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2023-07-01",BL0_FD:"2023-07-01",PLAN_SD:"2023-07-13",PLAN_FD:"2023-09-08",P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:348,P6_LV3_SEQ:4,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2023-03-01",BL0_FD:"2023-11-10",PLAN_SD:"2022-12-10",PLAN_FD:"2023-11-13",ACT_SD:"2022-12-10",P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:348,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2023-06-29",BL0_FD:"2023-09-07",PLAN_SD:"2023-06-23",PLAN_FD:"2023-09-12",P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",P6_LV3:"Construction",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:348,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-10-10",BL0_FD:"2024-03-30",PLAN_SD:"2023-09-30",PLAN_FD:"2024-03-30",P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:348,P6_LV3_SEQ:4,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",PLAN_FD:"2024-03-30",P6_LV1:"P3",P6_LV2:"Loading / Unloading Station",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:348,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P3",P6_LV2:"Elevated Flare System",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2024-05-27",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:350,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P3",P6_LV2:"Elevated Flare System",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2022-02-28",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:350,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P3",P6_LV2:"Elevated Flare System",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-03-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:350,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P3",P6_LV2:"Elevated Flare System",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:350,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P3",P6_LV2:"Elevated Flare System",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-03-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:350,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P3",P6_LV2:"Elevated Flare System",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:350,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {BL0_SD:"2021-04-01",BL0_FD:"2024-05-18",PLAN_SD:"2021-04-01",PLAN_FD:"2024-03-30",ACT_SD:"2021-04-01",P6_LV1:"P3",P6_LV2:"Elevated Flare System",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:350,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"Elevated Flare System",ACTIVITY_NAME:"Elevated Flare System",BL0_SD:"2021-04-01",BL0_FD:"2023-02-02",PLAN_SD:"2021-04-01",PLAN_FD:"2023-04-24",ACT_SD:"2021-04-01",P6_LV1:"P3",P6_LV2:"Elevated Flare System",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:350,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"Elevated Flare System",ACTIVITY_NAME:"Elevated Flare System",BL0_SD:"2022-03-09",BL0_FD:"2023-09-08",PLAN_SD:"2022-03-09",PLAN_FD:"2023-11-22",ACT_SD:"2022-03-09",P6_LV1:"P3",P6_LV2:"Elevated Flare System",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:350,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"Elevated Flare System",ACTIVITY_NAME:"Elevated Flare System",BL0_SD:"2022-05-27",BL0_FD:"2024-05-18",PLAN_SD:"2022-05-27",PLAN_FD:"2024-03-30",ACT_SD:"2022-05-27",P6_LV1:"P3",P6_LV2:"Elevated Flare System",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:350,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-04-01",BL0_FD:"2023-02-02",PLAN_SD:"2021-04-01",PLAN_FD:"2023-02-20",ACT_SD:"2021-04-01",P6_LV1:"P3",P6_LV2:"Elevated Flare System",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:350,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-02-28",BL0_FD:"2022-06-01",PLAN_SD:"2022-02-28",PLAN_FD:"2022-09-23",ACT_SD:"2022-02-28",ACT_FD:"2022-09-23",P6_LV1:"P3",P6_LV2:"Elevated Flare System",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:350,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-02-07",BL0_FD:"2022-07-27",PLAN_SD:"2022-02-07",PLAN_FD:"2022-11-25",ACT_SD:"2022-02-07",ACT_FD:"2022-11-25",P6_LV1:"P3",P6_LV2:"Elevated Flare System",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:350,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-04-25",BL0_FD:"2023-02-02",PLAN_SD:"2022-04-25",PLAN_FD:"2023-02-22",ACT_SD:"2022-04-25",P6_LV1:"P3",P6_LV2:"Elevated Flare System",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:350,P6_LV3_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-02-07",BL0_FD:"2023-02-02",PLAN_SD:"2022-02-07",PLAN_FD:"2023-02-20",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Elevated Flare System",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:350,P6_LV3_SEQ:2,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-09-21",BL0_FD:"2022-12-21",PLAN_SD:"2022-08-01",PLAN_FD:"2023-04-24",ACT_SD:"2022-08-01",P6_LV1:"P3",P6_LV2:"Elevated Flare System",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:350,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-03-21",BL0_FD:"2023-01-04",PLAN_SD:"2022-03-21",PLAN_FD:"2023-04-03",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Elevated Flare System",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:350,P6_LV3_SEQ:2,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-03-09",BL0_FD:"2022-05-27",PLAN_SD:"2022-03-09",PLAN_FD:"2022-05-26",ACT_SD:"2022-03-09",ACT_FD:"2022-05-26",P6_LV1:"P3",P6_LV2:"Elevated Flare System",P6_LV3:"Procurement",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:350,P6_LV3_SEQ:3,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-06-01",BL0_FD:"2022-11-02",PLAN_SD:"2022-06-01",PLAN_FD:"2023-02-07",ACT_SD:"2022-06-01",P6_LV1:"P3",P6_LV2:"Elevated Flare System",P6_LV3:"Procurement",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:350,P6_LV3_SEQ:3,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-03-21",BL0_FD:"2023-04-28",PLAN_SD:"2022-03-21",PLAN_FD:"2023-06-08",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Elevated Flare System",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:350,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-03-21",BL0_FD:"2023-08-18",PLAN_SD:"2022-03-21",PLAN_FD:"2023-11-22",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Elevated Flare System",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:350,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-03-21",BL0_FD:"2022-12-25",PLAN_SD:"2022-03-21",PLAN_FD:"2023-04-24",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Elevated Flare System",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:350,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-03-21",BL0_FD:"2023-09-08",PLAN_SD:"2022-03-21",PLAN_FD:"2023-09-05",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Elevated Flare System",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:350,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-05-27",BL0_FD:"2022-12-02",PLAN_SD:"2022-05-27",PLAN_FD:"2023-03-07",ACT_SD:"2022-05-27",ACT_FD:"2022-10-18",P6_LV1:"P3",P6_LV2:"Elevated Flare System",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:350,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2023-01-28",BL0_FD:"2023-03-28",PLAN_SD:"2023-02-08",PLAN_FD:"2023-06-13",P6_LV1:"P3",P6_LV2:"Elevated Flare System",P6_LV3:"Construction",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:350,P6_LV3_SEQ:4,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-11-17",BL0_FD:"2024-05-18",PLAN_SD:"2022-11-05",PLAN_FD:"2024-03-30",ACT_SD:"2022-11-05",P6_LV1:"P3",P6_LV2:"Elevated Flare System",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:350,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2023-07-10",BL0_FD:"2024-03-05",PLAN_SD:"2023-07-01",PLAN_FD:"2023-11-27",P6_LV1:"P3",P6_LV2:"Elevated Flare System",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:350,P6_LV3_SEQ:4,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2023-01-17",BL0_FD:"2024-02-22",PLAN_SD:"2023-04-25",PLAN_FD:"2023-12-21",P6_LV1:"P3",P6_LV2:"Elevated Flare System",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:350,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-05-17",BL0_FD:"2023-11-11",PLAN_SD:"2023-06-01",PLAN_FD:"2024-01-08",P6_LV1:"P3",P6_LV2:"Elevated Flare System",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:350,P6_LV3_SEQ:4,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",PLAN_FD:"2024-03-30",P6_LV1:"P3",P6_LV2:"Elevated Flare System",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:350,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P3",P6_LV2:"Ground Flare",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:352,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P3",P6_LV2:"Ground Flare",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-03-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:352,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P3",P6_LV2:"Ground Flare",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2022-02-28",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:352,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P3",P6_LV2:"Ground Flare",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-03-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:352,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P3",P6_LV2:"Ground Flare",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-03-19",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:352,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P3",P6_LV2:"Ground Flare",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2024-05-25",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:352,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {BL0_SD:"2021-04-01",BL0_FD:"2024-05-20",PLAN_SD:"2021-04-01",PLAN_FD:"2024-05-25",ACT_SD:"2021-04-01",P6_LV1:"P3",P6_LV2:"Ground Flare",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:352,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"Ground Flare",ACTIVITY_NAME:"Ground Flare",BL0_SD:"2021-04-01",BL0_FD:"2023-02-07",PLAN_SD:"2021-04-01",PLAN_FD:"2023-02-09",ACT_SD:"2021-04-01",P6_LV1:"P3",P6_LV2:"Ground Flare",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:352,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"Ground Flare",ACTIVITY_NAME:"Ground Flare",BL0_SD:"2022-02-07",BL0_FD:"2024-02-03",PLAN_SD:"2022-02-07",PLAN_FD:"2023-10-30",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Ground Flare",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:352,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"Ground Flare",ACTIVITY_NAME:"Ground Flare",BL0_SD:"2022-05-16",BL0_FD:"2024-05-20",PLAN_SD:"2022-05-16",PLAN_FD:"2024-05-25",ACT_SD:"2022-05-16",P6_LV1:"P3",P6_LV2:"Ground Flare",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:352,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-04-01",BL0_FD:"2023-01-17",PLAN_SD:"2021-04-01",PLAN_FD:"2023-01-10",ACT_SD:"2021-04-01",P6_LV1:"P3",P6_LV2:"Ground Flare",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:352,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-03-21",BL0_FD:"2022-08-31",PLAN_SD:"2022-03-21",PLAN_FD:"2023-01-10",ACT_SD:"2022-03-21",ACT_FD:"2022-11-30",P6_LV1:"P3",P6_LV2:"Ground Flare",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:352,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-06-29",BL0_FD:"2022-09-07",PLAN_SD:"2022-08-01",PLAN_FD:"2022-11-25",ACT_SD:"2022-08-01",ACT_FD:"2022-11-25",P6_LV1:"P3",P6_LV2:"Ground Flare",P6_LV3:"Engineering",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:352,P6_LV3_SEQ:2,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-03-19",BL0_FD:"2022-10-17",PLAN_SD:"2022-03-19",PLAN_FD:"2022-11-30",ACT_SD:"2022-03-19",ACT_FD:"2022-11-30",P6_LV1:"P3",P6_LV2:"Ground Flare",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:352,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-10-10",BL0_FD:"2023-01-17",PLAN_SD:"2022-09-01",PLAN_FD:"2023-02-09",ACT_SD:"2022-09-01",P6_LV1:"P3",P6_LV2:"Ground Flare",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:352,P6_LV3_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-02-28",BL0_FD:"2023-01-17",PLAN_SD:"2022-02-28",PLAN_FD:"2023-02-06",ACT_SD:"2022-02-28",P6_LV1:"P3",P6_LV2:"Ground Flare",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:352,P6_LV3_SEQ:2,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-06-29",BL0_FD:"2023-01-11",PLAN_SD:"2022-06-29",PLAN_FD:"2023-02-06",ACT_SD:"2022-06-29",ACT_FD:"2022-12-20",P6_LV1:"P3",P6_LV2:"Ground Flare",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:352,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-03-21",BL0_FD:"2023-02-07",PLAN_SD:"2022-01-01",PLAN_FD:"2023-01-17",ACT_SD:"2022-01-01",ACT_FD:"2022-12-25",P6_LV1:"P3",P6_LV2:"Ground Flare",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:352,P6_LV3_SEQ:2,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-02-07",BL0_FD:"2022-08-03",PLAN_SD:"2022-02-07",PLAN_FD:"2022-06-17",ACT_SD:"2022-02-07",ACT_FD:"2022-06-17",P6_LV1:"P3",P6_LV2:"Ground Flare",P6_LV3:"Procurement",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:352,P6_LV3_SEQ:3,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-08-31",BL0_FD:"2023-02-02",PLAN_SD:"2022-08-31",PLAN_FD:"2023-02-21",ACT_SD:"2022-08-31",P6_LV1:"P3",P6_LV2:"Ground Flare",P6_LV3:"Procurement",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:352,P6_LV3_SEQ:3,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-03-21",BL0_FD:"2023-04-11",PLAN_SD:"2022-03-21",PLAN_FD:"2023-06-11",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Ground Flare",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:352,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-03-21",BL0_FD:"2024-02-03",PLAN_SD:"2022-03-21",PLAN_FD:"2023-10-30",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Ground Flare",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:352,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-03-21",BL0_FD:"2022-11-21",PLAN_SD:"2022-03-21",PLAN_FD:"2023-01-13",ACT_SD:"2022-03-21",ACT_FD:"2022-10-12",P6_LV1:"P3",P6_LV2:"Ground Flare",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:352,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-03-21",BL0_FD:"2023-09-08",PLAN_SD:"2022-03-21",PLAN_FD:"2023-08-09",ACT_SD:"2022-03-21",P6_LV1:"P3",P6_LV2:"Ground Flare",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:352,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-05-16",BL0_FD:"2022-12-05",PLAN_SD:"2022-05-16",PLAN_FD:"2023-04-19",ACT_SD:"2022-05-16",P6_LV1:"P3",P6_LV2:"Ground Flare",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:352,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-09-20",BL0_FD:"2023-03-07",PLAN_SD:"2022-10-08",PLAN_FD:"2023-02-03",ACT_SD:"2022-10-08",P6_LV1:"P3",P6_LV2:"Ground Flare",P6_LV3:"Construction",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:352,P6_LV3_SEQ:4,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2023-02-22",BL0_FD:"2023-08-21",PLAN_SD:"2023-02-22",PLAN_FD:"2024-01-11",P6_LV1:"P3",P6_LV2:"Ground Flare",P6_LV3:"Construction",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:352,P6_LV3_SEQ:4,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2023-01-30",BL0_FD:"2024-05-20",PLAN_SD:"2023-01-28",PLAN_FD:"2024-05-25",P6_LV1:"P3",P6_LV2:"Ground Flare",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:352,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2023-07-05",BL0_FD:"2024-04-08",PLAN_SD:"2023-07-01",PLAN_FD:"2024-04-08",P6_LV1:"P3",P6_LV2:"Ground Flare",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:352,P6_LV3_SEQ:4,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2024-01-18",BL0_FD:"2024-04-29",PLAN_SD:"2023-12-21",PLAN_FD:"2024-04-10",P6_LV1:"P3",P6_LV2:"Ground Flare",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:352,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2024-02-16",BL0_FD:"2024-05-14",PLAN_SD:"2024-01-17",PLAN_FD:"2024-04-22",P6_LV1:"P3",P6_LV2:"Ground Flare",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:352,P6_LV3_SEQ:4,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",PLAN_FD:"2024-05-25",P6_LV1:"P3",P6_LV2:"Ground Flare",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:352,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P3",P6_LV2:"Ethylene Cryogenic Tank Farm",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2024-06-27",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:356,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P3",P6_LV2:"Ethylene Cryogenic Tank Farm",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2021-12-28",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:356,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P3",P6_LV2:"Ethylene Cryogenic Tank Farm",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-02-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:356,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P3",P6_LV2:"Ethylene Cryogenic Tank Farm",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2021-11-05",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:356,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P3",P6_LV2:"Ethylene Cryogenic Tank Farm",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2021-12-18",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:356,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P3",P6_LV2:"Ethylene Cryogenic Tank Farm",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:356,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {BL0_SD:"2021-11-05",BL0_FD:"2024-06-14",PLAN_SD:"2021-11-05",PLAN_FD:"2024-06-27",ACT_SD:"2021-11-05",P6_LV1:"P3",P6_LV2:"Ethylene Cryogenic Tank Farm",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:356,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"Ethylene Cryogenic Tank Farm",ACTIVITY_NAME:"Ethylene Cryogenic Tank Farm",BL0_SD:"2021-11-05",BL0_FD:"2023-08-24",PLAN_SD:"2021-11-05",PLAN_FD:"2023-10-18",ACT_SD:"2021-11-05",P6_LV1:"P3",P6_LV2:"Ethylene Cryogenic Tank Farm",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:356,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"Ethylene Cryogenic Tank Farm",ACTIVITY_NAME:"Ethylene Cryogenic Tank Farm",BL0_SD:"2021-12-10",BL0_FD:"2023-12-26",PLAN_SD:"2021-12-10",PLAN_FD:"2024-02-04",ACT_SD:"2021-12-10",P6_LV1:"P3",P6_LV2:"Ethylene Cryogenic Tank Farm",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:356,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"Ethylene Cryogenic Tank Farm",ACTIVITY_NAME:"Ethylene Cryogenic Tank Farm",BL0_SD:"2022-02-28",BL0_FD:"2024-06-14",PLAN_SD:"2022-02-28",PLAN_FD:"2024-06-27",ACT_SD:"2022-02-28",P6_LV1:"P3",P6_LV2:"Ethylene Cryogenic Tank Farm",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:356,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-11-19",BL0_FD:"2023-06-01",PLAN_SD:"2021-11-19",PLAN_FD:"2023-10-18",ACT_SD:"2021-11-19",P6_LV1:"P3",P6_LV2:"Ethylene Cryogenic Tank Farm",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:356,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2021-12-28",BL0_FD:"2023-02-27",PLAN_SD:"2021-12-28",PLAN_FD:"2023-03-27",ACT_SD:"2021-12-28",P6_LV1:"P3",P6_LV2:"Ethylene Cryogenic Tank Farm",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:356,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-09-15",BL0_FD:"2023-05-04",PLAN_SD:"2022-06-27",PLAN_FD:"2023-02-27",ACT_SD:"2022-06-27",P6_LV1:"P3",P6_LV2:"Ethylene Cryogenic Tank Farm",P6_LV3:"Engineering",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:356,P6_LV3_SEQ:2,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-02-07",BL0_FD:"2023-03-01",PLAN_SD:"2022-02-07",PLAN_FD:"2023-07-05",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Ethylene Cryogenic Tank Farm",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:356,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-02-07",BL0_FD:"2023-07-24",PLAN_SD:"2022-02-07",PLAN_FD:"2023-07-27",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Ethylene Cryogenic Tank Farm",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:356,P6_LV3_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-11-05",BL0_FD:"2023-04-03",PLAN_SD:"2021-11-05",PLAN_FD:"2023-07-20",ACT_SD:"2021-11-05",P6_LV1:"P3",P6_LV2:"Ethylene Cryogenic Tank Farm",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:356,P6_LV3_SEQ:2,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-02-21",BL0_FD:"2023-07-13",PLAN_SD:"2022-02-21",PLAN_FD:"2023-10-18",ACT_SD:"2022-02-21",P6_LV1:"P3",P6_LV2:"Ethylene Cryogenic Tank Farm",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:356,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-02-07",BL0_FD:"2023-08-24",PLAN_SD:"2022-02-07",PLAN_FD:"2023-08-18",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Ethylene Cryogenic Tank Farm",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:356,P6_LV3_SEQ:2,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-05-25",BL0_FD:"2023-06-02",PLAN_SD:"2022-06-13",PLAN_FD:"2023-07-18",ACT_SD:"2022-06-13",P6_LV1:"P3",P6_LV2:"Ethylene Cryogenic Tank Farm",P6_LV3:"Procurement",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:356,P6_LV3_SEQ:3,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2021-12-18",BL0_FD:"2023-11-05",PLAN_SD:"2021-12-18",PLAN_FD:"2023-10-10",ACT_SD:"2021-12-18",P6_LV1:"P3",P6_LV2:"Ethylene Cryogenic Tank Farm",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:356,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-12-10",BL0_FD:"2023-12-11",PLAN_SD:"2021-12-10",PLAN_FD:"2024-02-04",ACT_SD:"2021-12-10",P6_LV1:"P3",P6_LV2:"Ethylene Cryogenic Tank Farm",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:356,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-09-07",BL0_FD:"2023-11-07",PLAN_SD:"2022-09-24",PLAN_FD:"2024-01-18",ACT_SD:"2022-09-24",P6_LV1:"P3",P6_LV2:"Ethylene Cryogenic Tank Farm",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:356,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-07-23",BL0_FD:"2023-12-26",PLAN_SD:"2022-08-27",PLAN_FD:"2023-10-11",ACT_SD:"2022-08-27",P6_LV1:"P3",P6_LV2:"Ethylene Cryogenic Tank Farm",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:356,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-02-28",BL0_FD:"2024-06-14",PLAN_SD:"2022-02-28",PLAN_FD:"2024-06-21",ACT_SD:"2022-02-28",P6_LV1:"P3",P6_LV2:"Ethylene Cryogenic Tank Farm",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:356,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2023-02-16",BL0_FD:"2024-05-10",PLAN_SD:"2023-02-16",PLAN_FD:"2024-05-30",P6_LV1:"P3",P6_LV2:"Ethylene Cryogenic Tank Farm",P6_LV3:"Construction",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:356,P6_LV3_SEQ:4,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2023-04-08",BL0_FD:"2024-05-25",PLAN_SD:"2023-02-28",PLAN_FD:"2024-06-22",P6_LV1:"P3",P6_LV2:"Ethylene Cryogenic Tank Farm",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:356,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2023-02-16",BL0_FD:"2024-06-07",PLAN_SD:"2023-02-16",PLAN_FD:"2024-06-27",P6_LV1:"P3",P6_LV2:"Ethylene Cryogenic Tank Farm",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:356,P6_LV3_SEQ:4,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2023-06-02",BL0_FD:"2024-05-04",PLAN_SD:"2023-04-12",PLAN_FD:"2024-06-25",P6_LV1:"P3",P6_LV2:"Ethylene Cryogenic Tank Farm",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:356,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-04-28",BL0_FD:"2024-05-22",PLAN_SD:"2023-08-28",PLAN_FD:"2024-03-12",P6_LV1:"P3",P6_LV2:"Ethylene Cryogenic Tank Farm",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:356,P6_LV3_SEQ:4,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Painting",ACTIVITY_NAME:"Painting",BL0_SD:"2023-03-30",BL0_FD:"2024-03-20",PLAN_SD:"2023-03-02",PLAN_FD:"2024-04-10",P6_LV1:"P3",P6_LV2:"Ethylene Cryogenic Tank Farm",P6_LV3:"Construction",P6_LV5:"Painting",LEVEL:"2",ITEM_DESC:"Painting",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:356,P6_LV3_SEQ:4,P6_LV5_SEQ:50,ITEM_SEQ:50},
    {ACTIVITY_ID:"Insulation",ACTIVITY_NAME:"Insulation",BL0_SD:"2023-12-15",BL0_FD:"2024-03-15",PLAN_SD:"2023-12-09",PLAN_FD:"2024-03-16",P6_LV1:"P3",P6_LV2:"Ethylene Cryogenic Tank Farm",P6_LV3:"Construction",P6_LV5:"Insulation",LEVEL:"2",ITEM_DESC:"Insulation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:356,P6_LV3_SEQ:4,P6_LV5_SEQ:55,ITEM_SEQ:55},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",PLAN_FD:"2024-06-27",P6_LV1:"P3",P6_LV2:"Ethylene Cryogenic Tank Farm",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:356,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:358,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:358,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:358,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:358,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:358,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2024-06-19",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:358,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {BL0_SD:"2022-02-07",BL0_FD:"2024-06-19",PLAN_SD:"2022-02-07",PLAN_FD:"2024-06-19",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:358,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"Pressurized Tank Farm Common Area",ACTIVITY_NAME:"Pressurized Tank Farm Common Area",BL0_SD:"2022-02-07",BL0_FD:"2023-06-02",PLAN_SD:"2022-02-07",PLAN_FD:"2023-09-14",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:358,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"Pressurized Tank Farm Common Area",ACTIVITY_NAME:"Pressurized Tank Farm Common Area",BL0_SD:"2022-02-07",BL0_FD:"2023-10-01",PLAN_SD:"2022-02-07",PLAN_FD:"2023-12-05",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:358,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"Pressurized Tank Farm Common Area",ACTIVITY_NAME:"Pressurized Tank Farm Common Area",BL0_SD:"2022-02-08",BL0_FD:"2024-06-19",PLAN_SD:"2022-02-08",PLAN_FD:"2024-06-19",ACT_SD:"2022-02-08",P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:358,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2022-02-07",BL0_FD:"2023-04-03",PLAN_SD:"2022-02-07",PLAN_FD:"2023-09-14",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:358,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-07-13",BL0_FD:"2022-12-01",PLAN_SD:"2022-07-13",PLAN_FD:"2023-02-20",ACT_SD:"2022-07-13",ACT_FD:"2022-11-25",P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:358,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-04-28",BL0_FD:"2023-04-04",PLAN_SD:"2022-04-28",PLAN_FD:"2023-04-24",ACT_SD:"2022-04-28",P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area",P6_LV3:"Engineering",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:358,P6_LV3_SEQ:2,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-04-28",BL0_FD:"2023-01-04",PLAN_SD:"2022-04-28",PLAN_FD:"2023-06-29",ACT_SD:"2022-04-28",P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:358,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-02-07",BL0_FD:"2023-03-30",PLAN_SD:"2022-02-07",PLAN_FD:"2023-05-12",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:358,P6_LV3_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-02-07",BL0_FD:"2023-04-03",PLAN_SD:"2022-02-07",PLAN_FD:"2023-07-26",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:358,P6_LV3_SEQ:2,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-02-07",BL0_FD:"2023-06-02",PLAN_SD:"2022-02-07",PLAN_FD:"2023-09-14",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:358,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-04-28",BL0_FD:"2023-02-21",PLAN_SD:"2022-04-28",PLAN_FD:"2023-06-02",ACT_SD:"2022-04-28",P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area",P6_LV3:"Engineering",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:358,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-02-07",BL0_FD:"2023-03-01",PLAN_SD:"2022-02-07",PLAN_FD:"2023-07-19",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:358,P6_LV3_SEQ:2,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-02-07",BL0_FD:"2022-06-01",PLAN_SD:"2022-02-07",PLAN_FD:"2022-09-06",ACT_SD:"2022-02-07",ACT_FD:"2022-09-06",P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area",P6_LV3:"Procurement",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:358,P6_LV3_SEQ:3,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-02-07",BL0_FD:"2023-05-27",PLAN_SD:"2022-02-07",PLAN_FD:"2023-03-11",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area",P6_LV3:"Procurement",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:358,P6_LV3_SEQ:3,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-02-07",BL0_FD:"2023-06-11",PLAN_SD:"2022-02-07",PLAN_FD:"2023-05-30",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:358,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-02-07",BL0_FD:"2023-10-01",PLAN_SD:"2022-02-07",PLAN_FD:"2023-10-29",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:358,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-02-07",BL0_FD:"2023-09-05",PLAN_SD:"2022-02-07",PLAN_FD:"2023-12-05",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:358,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-11-12",BL0_FD:"2023-04-21",PLAN_SD:"2022-12-26",PLAN_FD:"2023-07-16",P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area",P6_LV3:"Procurement",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:358,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-02-07",BL0_FD:"2023-06-29",PLAN_SD:"2022-02-07",PLAN_FD:"2023-09-02",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:358,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-05-19",BL0_FD:"2023-11-30",PLAN_SD:"2022-05-19",PLAN_FD:"2024-03-04",ACT_SD:"2022-05-19",P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:358,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2023-04-25",BL0_FD:"2023-12-29",PLAN_SD:"2023-03-30",PLAN_FD:"2024-02-05",P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area",P6_LV3:"Construction",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:358,P6_LV3_SEQ:4,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-10-22",BL0_FD:"2024-01-29",PLAN_SD:"2022-10-29",PLAN_FD:"2024-05-09",ACT_SD:"2022-10-29",P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:358,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-02-08",BL0_FD:"2023-12-25",PLAN_SD:"2022-02-08",PLAN_FD:"2024-02-20",ACT_SD:"2022-02-08",P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:358,P6_LV3_SEQ:4,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2023-05-05",BL0_FD:"2024-06-05",PLAN_SD:"2023-05-12",PLAN_FD:"2024-03-06",P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:358,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2023-05-05",BL0_FD:"2024-02-10",PLAN_SD:"2023-06-10",PLAN_FD:"2023-12-28",P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area",P6_LV3:"Construction",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:358,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-05-31",BL0_FD:"2024-04-13",PLAN_SD:"2023-06-09",PLAN_FD:"2024-02-29",P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:358,P6_LV3_SEQ:4,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",BL0_SD:"2024-02-08",BL0_FD:"2024-06-19",PLAN_SD:"2024-02-08",PLAN_FD:"2024-06-19",P6_LV1:"P3",P6_LV2:"Pressurized Tank Farm Common Area",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:358,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P3",P6_LV2:"Overall Interconnecting Network & EIT",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2024-07-20",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:360,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P3",P6_LV2:"Overall Interconnecting Network & EIT",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2022-01-05",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:360,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P3",P6_LV2:"Overall Interconnecting Network & EIT",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-02-21",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:360,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P3",P6_LV2:"Overall Interconnecting Network & EIT",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2022-06-25",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:360,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P3",P6_LV2:"Overall Interconnecting Network & EIT",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-01-30",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:360,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P3",P6_LV2:"Overall Interconnecting Network & EIT",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-04-18",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:360,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {BL0_SD:"2021-12-30",BL0_FD:"2024-07-08",PLAN_SD:"2021-12-30",PLAN_FD:"2024-09-19",ACT_SD:"2021-12-30",P6_LV1:"P3",P6_LV2:"Overall Interconnecting Network & EIT",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:360,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"Overall Interconnecting Network & EIT",ACTIVITY_NAME:"Overall Interconnecting Network & EIT",BL0_SD:"2021-12-30",BL0_FD:"2023-07-10",PLAN_SD:"2021-12-30",PLAN_FD:"2023-08-28",ACT_SD:"2021-12-30",P6_LV1:"P3",P6_LV2:"Overall Interconnecting Network & EIT",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:360,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"Overall Interconnecting Network & EIT",ACTIVITY_NAME:"Overall Interconnecting Network & EIT",BL0_SD:"2022-01-30",BL0_FD:"2024-06-25",PLAN_SD:"2022-01-30",PLAN_FD:"2024-09-19",ACT_SD:"2022-01-30",P6_LV1:"P3",P6_LV2:"Overall Interconnecting Network & EIT",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:360,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"Overall Interconnecting Network & EIT",ACTIVITY_NAME:"Overall Interconnecting Network & EIT",BL0_SD:"2022-01-25",BL0_FD:"2024-07-08",PLAN_SD:"2022-01-25",PLAN_FD:"2024-07-20",ACT_SD:"2022-01-25",P6_LV1:"P3",P6_LV2:"Overall Interconnecting Network & EIT",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:360,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-12-30",BL0_FD:"2023-03-14",PLAN_SD:"2021-12-30",PLAN_FD:"2023-08-28",ACT_SD:"2021-12-30",P6_LV1:"P3",P6_LV2:"Overall Interconnecting Network & EIT",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:360,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-01-05",BL0_FD:"2022-08-17",PLAN_SD:"2022-01-05",PLAN_FD:"2022-10-21",ACT_SD:"2022-01-05",ACT_FD:"2022-10-21",P6_LV1:"P3",P6_LV2:"Overall Interconnecting Network & EIT",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:360,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-04-18",BL0_FD:"2023-05-12",PLAN_SD:"2022-04-18",PLAN_FD:"2023-06-22",ACT_SD:"2022-04-18",P6_LV1:"P3",P6_LV2:"Overall Interconnecting Network & EIT",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:360,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-02-21",BL0_FD:"2023-07-10",PLAN_SD:"2022-02-21",PLAN_FD:"2023-08-21",ACT_SD:"2022-02-21",P6_LV1:"P3",P6_LV2:"Overall Interconnecting Network & EIT",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:360,P6_LV3_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-12-19",BL0_FD:"2022-12-19",PLAN_SD:"2022-06-29",PLAN_FD:"2022-11-18",ACT_SD:"2022-06-29",ACT_FD:"2022-11-18",P6_LV1:"P3",P6_LV2:"Overall Interconnecting Network & EIT",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:360,P6_LV3_SEQ:2,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-02-21",BL0_FD:"2023-06-09",PLAN_SD:"2022-02-21",PLAN_FD:"2023-08-28",ACT_SD:"2022-02-21",P6_LV1:"P3",P6_LV2:"Overall Interconnecting Network & EIT",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:360,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-03-31",BL0_FD:"2023-03-06",PLAN_SD:"2022-03-31",PLAN_FD:"2023-08-03",ACT_SD:"2022-03-31",P6_LV1:"P3",P6_LV2:"Overall Interconnecting Network & EIT",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:360,P6_LV3_SEQ:2,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-09-27",BL0_FD:"2023-06-22",PLAN_SD:"2022-09-06",PLAN_FD:"2023-05-24",ACT_SD:"2022-09-06",P6_LV1:"P3",P6_LV2:"Overall Interconnecting Network & EIT",P6_LV3:"Procurement",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:360,P6_LV3_SEQ:3,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-01-30",BL0_FD:"2023-11-27",PLAN_SD:"2022-01-30",PLAN_FD:"2023-09-26",ACT_SD:"2022-01-30",P6_LV1:"P3",P6_LV2:"Overall Interconnecting Network & EIT",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:360,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-06-25",BL0_FD:"2024-06-25",PLAN_SD:"2022-06-25",PLAN_FD:"2024-09-19",ACT_SD:"2022-06-25",P6_LV1:"P3",P6_LV2:"Overall Interconnecting Network & EIT",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:360,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-09-14",BL0_FD:"2023-12-10",PLAN_SD:"2022-08-23",PLAN_FD:"2023-12-23",ACT_SD:"2022-08-23",P6_LV1:"P3",P6_LV2:"Overall Interconnecting Network & EIT",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:360,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-05-18",BL0_FD:"2023-08-16",PLAN_SD:"2023-07-07",PLAN_FD:"2023-11-20",P6_LV1:"P3",P6_LV2:"Overall Interconnecting Network & EIT",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:360,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-01-25",BL0_FD:"2023-03-28",PLAN_SD:"2022-01-25",PLAN_FD:"2023-10-20",ACT_SD:"2022-01-25",P6_LV1:"P3",P6_LV2:"Overall Interconnecting Network & EIT",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:360,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2023-01-04",BL0_FD:"2023-07-27",PLAN_SD:"2022-10-01",PLAN_FD:"2023-08-30",ACT_SD:"2022-10-01",P6_LV1:"P3",P6_LV2:"Overall Interconnecting Network & EIT",P6_LV3:"Construction",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:360,P6_LV3_SEQ:4,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2023-05-12",BL0_FD:"2024-02-28",PLAN_SD:"2023-05-26",PLAN_FD:"2024-03-30",P6_LV1:"P3",P6_LV2:"Overall Interconnecting Network & EIT",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:360,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2023-01-04",BL0_FD:"2024-07-08",PLAN_SD:"2023-03-20",PLAN_FD:"2024-07-20",P6_LV1:"P3",P6_LV2:"Overall Interconnecting Network & EIT",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:360,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-05-04",BL0_FD:"2024-05-28",PLAN_SD:"2023-05-29",PLAN_FD:"2024-06-24",P6_LV1:"P3",P6_LV2:"Overall Interconnecting Network & EIT",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:360,P6_LV3_SEQ:4,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Painting",ACTIVITY_NAME:"Painting",BL0_SD:"2023-01-17",BL0_FD:"2024-03-18",PLAN_SD:"2023-01-10",PLAN_FD:"2024-03-30",P6_LV1:"P3",P6_LV2:"Overall Interconnecting Network & EIT",P6_LV3:"Construction",P6_LV5:"Painting",LEVEL:"2",ITEM_DESC:"Painting",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:360,P6_LV3_SEQ:4,P6_LV5_SEQ:50,ITEM_SEQ:50},
    {ACTIVITY_ID:"Insulation",ACTIVITY_NAME:"Insulation",BL0_SD:"2023-12-02",BL0_FD:"2024-02-21",PLAN_SD:"2023-12-12",PLAN_FD:"2024-03-05",P6_LV1:"P3",P6_LV2:"Overall Interconnecting Network & EIT",P6_LV3:"Construction",P6_LV5:"Insulation",LEVEL:"2",ITEM_DESC:"Insulation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:360,P6_LV3_SEQ:4,P6_LV5_SEQ:55,ITEM_SEQ:55},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",BL0_SD:"2024-03-20",BL0_FD:"2024-03-20",PLAN_SD:"2023-12-14",PLAN_FD:"2024-07-20",P6_LV1:"P3",P6_LV2:"Overall Interconnecting Network & EIT",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:360,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:362,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:362,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:362,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:362,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-02-07",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:362,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2024-08-09",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"MS",P6_LV1_SEQ:3,P6_LV2_SEQ:362,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {BL0_SD:"2022-02-07",BL0_FD:"2024-08-09",PLAN_SD:"2022-02-07",PLAN_FD:"2024-08-09",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:362,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"Atmospheric Tank Farm",ACTIVITY_NAME:"Atmospheric Tank Farm",BL0_SD:"2022-02-07",BL0_FD:"2023-07-14",PLAN_SD:"2022-02-07",PLAN_FD:"2023-09-21",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:362,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"Atmospheric Tank Farm",ACTIVITY_NAME:"Atmospheric Tank Farm",BL0_SD:"2022-02-07",BL0_FD:"2023-11-14",PLAN_SD:"2022-02-07",PLAN_FD:"2023-09-14",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:362,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"Atmospheric Tank Farm",ACTIVITY_NAME:"Atmospheric Tank Farm",BL0_SD:"2022-03-29",BL0_FD:"2024-08-09",PLAN_SD:"2022-03-29",PLAN_FD:"2024-08-09",ACT_SD:"2022-03-29",P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:362,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2022-02-07",BL0_FD:"2023-04-14",PLAN_SD:"2022-02-07",PLAN_FD:"2023-09-21",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:362,P6_LV3_SEQ:2,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-06-13",BL0_FD:"2022-12-01",PLAN_SD:"2022-06-13",PLAN_FD:"2022-12-26",ACT_SD:"2022-06-13",ACT_FD:"2022-11-24",P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:362,P6_LV3_SEQ:2,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-02-07",BL0_FD:"2023-04-11",PLAN_SD:"2022-02-07",PLAN_FD:"2023-07-13",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm",P6_LV3:"Engineering",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:362,P6_LV3_SEQ:2,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-03-28",BL0_FD:"2022-11-29",PLAN_SD:"2022-04-15",PLAN_FD:"2023-05-11",ACT_SD:"2022-04-15",ACT_FD:"2022-09-07",P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:362,P6_LV3_SEQ:2,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-02-07",BL0_FD:"2023-05-10",PLAN_SD:"2022-02-07",PLAN_FD:"2023-08-15",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:362,P6_LV3_SEQ:2,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-02-07",BL0_FD:"2023-03-03",PLAN_SD:"2022-02-07",PLAN_FD:"2023-08-03",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:362,P6_LV3_SEQ:2,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-02-07",BL0_FD:"2023-07-14",PLAN_SD:"2022-02-07",PLAN_FD:"2023-09-21",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:362,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-02-07",BL0_FD:"2023-04-03",PLAN_SD:"2022-02-07",PLAN_FD:"2023-05-31",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm",P6_LV3:"Engineering",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:362,P6_LV3_SEQ:2,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-02-07",BL0_FD:"2023-05-04",PLAN_SD:"2022-02-07",PLAN_FD:"2023-06-26",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:362,P6_LV3_SEQ:2,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-02-07",BL0_FD:"2022-03-28",PLAN_SD:"2022-02-07",PLAN_FD:"2022-09-13",ACT_SD:"2022-02-07",ACT_FD:"2022-09-13",P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm",P6_LV3:"Procurement",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:362,P6_LV3_SEQ:3,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-02-07",BL0_FD:"2023-02-01",PLAN_SD:"2022-02-07",PLAN_FD:"2023-03-04",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm",P6_LV3:"Procurement",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:362,P6_LV3_SEQ:3,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-02-07",BL0_FD:"2023-05-28",PLAN_SD:"2022-02-07",PLAN_FD:"2023-06-26",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:362,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-02-07",BL0_FD:"2023-11-14",PLAN_SD:"2022-02-07",PLAN_FD:"2023-09-14",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:362,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-02-07",BL0_FD:"2023-09-19",PLAN_SD:"2022-02-07",PLAN_FD:"2023-09-13",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:362,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2023-01-31",BL0_FD:"2023-09-21",PLAN_SD:"2023-02-28",PLAN_FD:"2023-08-08",P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm",P6_LV3:"Procurement",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:362,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-02-07",BL0_FD:"2023-09-18",PLAN_SD:"2022-02-07",PLAN_FD:"2023-09-07",ACT_SD:"2022-02-07",P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:362,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-03-29",BL0_FD:"2024-01-11",PLAN_SD:"2022-03-29",PLAN_FD:"2024-03-09",ACT_SD:"2022-03-29",P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:362,P6_LV3_SEQ:4,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-12-20",BL0_FD:"2023-09-14",PLAN_SD:"2023-01-28",PLAN_FD:"2024-01-13",P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm",P6_LV3:"Construction",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:362,P6_LV3_SEQ:4,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-11-12",BL0_FD:"2024-04-01",PLAN_SD:"2022-10-19",PLAN_FD:"2024-05-10",ACT_SD:"2022-10-19",P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:362,P6_LV3_SEQ:4,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2023-04-03",BL0_FD:"2023-12-11",PLAN_SD:"2023-06-13",PLAN_FD:"2024-03-08",P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:362,P6_LV3_SEQ:4,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2023-04-11",BL0_FD:"2024-05-24",PLAN_SD:"2023-04-25",PLAN_FD:"2024-05-24",P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:362,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2023-10-26",BL0_FD:"2024-03-06",PLAN_SD:"2023-08-09",PLAN_FD:"2023-12-27",P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm",P6_LV3:"Construction",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:362,P6_LV3_SEQ:4,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-08-07",BL0_FD:"2024-02-07",PLAN_SD:"2023-06-12",PLAN_FD:"2024-03-21",P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:362,P6_LV3_SEQ:4,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",BL0_SD:"2024-03-14",BL0_FD:"2024-08-09",PLAN_SD:"2024-01-13",PLAN_FD:"2024-08-09",P6_LV1:"P3",P6_LV2:"Atmospheric Tank Farm",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:3,P6_LV2_SEQ:362,P6_LV3_SEQ:4,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"OP",ACTIVITY_NAME:"OP",BL0_SD:"2021-12-30",BL0_FD:"2023-12-31",PLAN_SD:"2021-12-30",PLAN_FD:"2023-12-30",ACT_SD:"2021-12-30",P6_LV1:"OP",LEVEL:"0",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"Engineering",ACTIVITY_NAME:"Engineering",BL0_SD:"2021-12-30",BL0_FD:"2022-04-17",PLAN_SD:"2021-12-30",PLAN_FD:"2022-05-31",ACT_SD:"2021-12-30",ACT_FD:"2022-05-31",P6_LV1:"OP",LEVEL:"0",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"Procurement",ACTIVITY_NAME:"Procurement",BL0_SD:"2022-01-04",BL0_FD:"2023-06-24",PLAN_SD:"2022-01-04",PLAN_FD:"2023-07-01",ACT_SD:"2022-01-04",P6_LV1:"OP",LEVEL:"0",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"Construction",ACTIVITY_NAME:"Construction",BL0_SD:"2022-01-06",BL0_FD:"2023-12-31",PLAN_SD:"2022-01-06",PLAN_FD:"2023-12-30",ACT_SD:"2022-01-06",P6_LV1:"OP",LEVEL:"0",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {BL0_SD:"2022-04-15",BL0_FD:"2022-11-12",PLAN_SD:"2022-04-15",PLAN_FD:"2022-10-31",ACT_SD:"2022-04-15",ACT_FD:"2022-10-31",P6_LV1:"OP",P6_LV2:"Section A",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:410,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"Section A",ACTIVITY_NAME:"Section A",BL0_SD:"2022-04-15",BL0_FD:"2022-11-12",PLAN_SD:"2022-04-15",PLAN_FD:"2022-10-31",ACT_SD:"2022-04-15",ACT_FD:"2022-10-31",P6_LV1:"OP",P6_LV2:"Section A",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:410,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Jetty",ACTIVITY_NAME:"Jetty",BL0_SD:"2022-04-15",BL0_FD:"2022-11-12",PLAN_SD:"2022-04-15",PLAN_FD:"2022-10-31",ACT_SD:"2022-04-15",ACT_FD:"2022-10-31",P6_LV1:"OP",P6_LV2:"Section A",P6_LV3:"Construction",P6_LV5:"Jetty",LEVEL:"2",ITEM_DESC:"Jetty",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:410,P6_LV3_SEQ:4,P6_LV5_SEQ:65,ITEM_SEQ:65},
    {BL0_SD:"2022-05-24",BL0_FD:"2023-07-12",PLAN_SD:"2022-05-24",PLAN_FD:"2023-07-13",ACT_SD:"2022-05-24",P6_LV1:"OP",P6_LV2:"Section B",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:420,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"Section B",ACTIVITY_NAME:"Section B",BL0_SD:"2022-05-24",BL0_FD:"2023-07-12",PLAN_SD:"2022-05-24",PLAN_FD:"2023-07-13",ACT_SD:"2022-05-24",P6_LV1:"OP",P6_LV2:"Section B",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:420,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Jetty",ACTIVITY_NAME:"Jetty",BL0_SD:"2022-05-24",BL0_FD:"2023-07-12",PLAN_SD:"2022-05-24",PLAN_FD:"2023-07-13",ACT_SD:"2022-05-24",P6_LV1:"OP",P6_LV2:"Section B",P6_LV3:"Construction",P6_LV5:"Jetty",LEVEL:"2",ITEM_DESC:"Jetty",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:420,P6_LV3_SEQ:4,P6_LV5_SEQ:65,ITEM_SEQ:65},
    {BL0_SD:"2022-07-05",BL0_FD:"2023-10-07",PLAN_SD:"2022-07-06",PLAN_FD:"2023-09-17",ACT_SD:"2022-07-06",P6_LV1:"OP",P6_LV2:"Section C",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:430,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"Section C",ACTIVITY_NAME:"Section C",BL0_SD:"2022-07-05",BL0_FD:"2023-10-07",PLAN_SD:"2022-07-06",PLAN_FD:"2023-09-17",ACT_SD:"2022-07-06",P6_LV1:"OP",P6_LV2:"Section C",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:430,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Jetty",ACTIVITY_NAME:"Jetty",BL0_SD:"2022-07-05",BL0_FD:"2023-10-07",PLAN_SD:"2022-07-06",PLAN_FD:"2023-09-17",ACT_SD:"2022-07-06",P6_LV1:"OP",P6_LV2:"Section C",P6_LV3:"Construction",P6_LV5:"Jetty",LEVEL:"2",ITEM_DESC:"Jetty",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:430,P6_LV3_SEQ:4,P6_LV5_SEQ:65,ITEM_SEQ:65},
    {BL0_SD:"2022-08-17",BL0_FD:"2023-10-31",PLAN_SD:"2022-09-01",PLAN_FD:"2023-10-08",ACT_SD:"2022-09-01",P6_LV1:"OP",P6_LV2:"Section D",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:440,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"Section D",ACTIVITY_NAME:"Section D",BL0_SD:"2022-08-17",BL0_FD:"2023-10-31",PLAN_SD:"2022-09-01",PLAN_FD:"2023-10-08",ACT_SD:"2022-09-01",P6_LV1:"OP",P6_LV2:"Section D",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:440,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Jetty",ACTIVITY_NAME:"Jetty",BL0_SD:"2022-08-17",BL0_FD:"2023-10-31",PLAN_SD:"2022-09-01",PLAN_FD:"2023-10-08",ACT_SD:"2022-09-01",P6_LV1:"OP",P6_LV2:"Section D",P6_LV3:"Construction",P6_LV5:"Jetty",LEVEL:"2",ITEM_DESC:"Jetty",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:440,P6_LV3_SEQ:4,P6_LV5_SEQ:65,ITEM_SEQ:65},
    {BL0_SD:"2023-07-21",BL0_FD:"2023-07-21",PLAN_SD:"2023-08-07",PLAN_FD:"2023-08-13",P6_LV1:"OP",P6_LV2:"Safety sign and device",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {BL0_SD:"2023-04-26",BL0_FD:"2023-09-29",PLAN_SD:"2023-05-11",PLAN_FD:"2023-10-08",P6_LV1:"OP",P6_LV2:"Navigation System Installation",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {BL0_SD:"2023-08-12",BL0_FD:"2023-09-26",PLAN_SD:"2023-07-15",PLAN_FD:"2023-09-23",P6_LV1:"OP",P6_LV2:"Pipeline Pressure test",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {BL0_SD:"2022-01-06",BL0_FD:"2023-05-25",PLAN_SD:"2022-01-06",PLAN_FD:"2023-06-24",ACT_SD:"2022-01-06",P6_LV1:"OP",P6_LV2:"Construction Preparation",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {BL0_SD:"2023-10-04",BL0_FD:"2023-12-01",PLAN_SD:"2023-09-05",PLAN_FD:"2023-11-19",P6_LV1:"OP",P6_LV2:"Commissioning",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {PLAN_FD:"2023-04-27",P6_LV1:"OP",P6_LV2:"Civil Work",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {BL0_SD:"2022-05-03",BL0_FD:"2023-06-22",PLAN_SD:"2022-05-03",PLAN_FD:"2022-12-31",ACT_SD:"2022-05-03",P6_LV1:"OP",P6_LV2:"Components Prefabrication",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {BL0_SD:"2022-07-27",BL0_FD:"2023-08-14",PLAN_SD:"2022-06-27",PLAN_FD:"2023-08-15",ACT_SD:"2022-06-27",P6_LV1:"OP",P6_LV2:"Section E",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {BL0_SD:"2023-07-23",BL0_FD:"2023-12-31",PLAN_SD:"2023-06-22",PLAN_FD:"2023-12-30",P6_LV1:"OP",P6_LV2:"System Completion & Handover Acceptance",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {BL0_SD:"2022-06-02",BL0_FD:"2022-06-02",PLAN_SD:"2022-07-15",PLAN_FD:"2022-09-17",ACT_SD:"2022-07-15",ACT_FD:"2022-09-17",P6_LV1:"OP",P6_LV2:"Construction Material",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {BL0_SD:"2022-03-05",BL0_FD:"2022-10-31",PLAN_SD:"2022-03-05",PLAN_FD:"2022-11-13",ACT_SD:"2022-03-05",ACT_FD:"2022-11-13",P6_LV1:"OP",P6_LV2:"Temporary Construction Work",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {BL0_SD:"2022-04-04",BL0_FD:"2022-06-16",PLAN_SD:"2022-04-04",PLAN_FD:"2022-05-30",ACT_SD:"2022-04-04",ACT_FD:"2022-05-30",P6_LV1:"OP",P6_LV2:"Pile Trial",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {BL0_SD:"2021-12-30",BL0_FD:"2023-08-23",PLAN_SD:"2021-12-30",PLAN_FD:"2023-12-30",ACT_SD:"2021-12-30",ACT_FD:"2022-10-24",P6_LV1:"OP",P6_LV2:"NO WBS",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {BL0_SD:"2022-03-09",BL0_FD:"2023-06-24",PLAN_SD:"2022-03-09",PLAN_FD:"2023-05-30",ACT_SD:"2022-03-09",P6_LV1:"OP",P6_LV2:"Sub-contracting",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {BL0_SD:"2022-04-12",BL0_FD:"2023-10-09",PLAN_SD:"2022-04-12",PLAN_FD:"2023-04-30",ACT_SD:"2022-04-12",P6_LV1:"OP",P6_LV2:"Dredging",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {BL0_SD:"2022-01-04",BL0_FD:"2023-03-25",PLAN_SD:"2022-01-04",PLAN_FD:"2023-07-01",ACT_SD:"2022-01-04",P6_LV1:"OP",P6_LV2:"Project Material",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {BL0_SD:"2022-06-27",BL0_FD:"2022-06-27",PLAN_SD:"2022-08-22",PLAN_FD:"2022-12-03",ACT_SD:"2022-08-22",ACT_FD:"2022-12-03",P6_LV1:"OP",P6_LV2:"General",LEVEL:"1",ITEM_DESC:"Overall",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"NO WBS",ACTIVITY_NAME:"NO WBS",BL0_SD:"2021-12-30",BL0_FD:"2022-04-17",PLAN_SD:"2021-12-30",PLAN_FD:"2022-05-31",ACT_SD:"2021-12-30",ACT_FD:"2022-05-31",P6_LV1:"OP",P6_LV2:"NO WBS",LEVEL:"1",ITEM_DESC:"Engineering",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"Construction Material",ACTIVITY_NAME:"Construction Material",BL0_SD:"2022-06-02",BL0_FD:"2022-06-02",PLAN_SD:"2022-07-15",PLAN_FD:"2022-09-17",ACT_SD:"2022-07-15",ACT_FD:"2022-09-17",P6_LV1:"OP",P6_LV2:"Construction Material",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"NO WBS",ACTIVITY_NAME:"NO WBS",PLAN_SD:"2022-01-04",PLAN_FD:"2022-01-27",ACT_SD:"2022-01-04",ACT_FD:"2022-01-27",P6_LV1:"OP",P6_LV2:"NO WBS",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"Project Material",ACTIVITY_NAME:"Project Material",BL0_SD:"2022-01-04",BL0_FD:"2023-03-25",PLAN_SD:"2022-01-04",PLAN_FD:"2023-07-01",ACT_SD:"2022-01-04",P6_LV1:"OP",P6_LV2:"Project Material",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"Sub-contracting",ACTIVITY_NAME:"Sub-contracting",BL0_SD:"2022-03-09",BL0_FD:"2023-06-24",PLAN_SD:"2022-03-09",PLAN_FD:"2023-05-30",ACT_SD:"2022-03-09",P6_LV1:"OP",P6_LV2:"Sub-contracting",LEVEL:"1",ITEM_DESC:"Procurement",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"Section E",ACTIVITY_NAME:"Section E",BL0_SD:"2022-07-27",BL0_FD:"2023-08-14",PLAN_SD:"2022-06-27",PLAN_FD:"2023-08-15",ACT_SD:"2022-06-27",P6_LV1:"OP",P6_LV2:"Section E",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"System Completion & Handover Acceptance",ACTIVITY_NAME:"System Completion & Handover Acceptance",BL0_SD:"2023-07-23",BL0_FD:"2023-12-31",PLAN_SD:"2023-06-22",PLAN_FD:"2023-12-30",P6_LV1:"OP",P6_LV2:"System Completion & Handover Acceptance",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Temporary Construction Work",ACTIVITY_NAME:"Temporary Construction Work",BL0_SD:"2022-03-05",BL0_FD:"2022-10-31",PLAN_SD:"2022-03-05",PLAN_FD:"2022-11-13",ACT_SD:"2022-03-05",ACT_FD:"2022-11-13",P6_LV1:"OP",P6_LV2:"Temporary Construction Work",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Civil Work",ACTIVITY_NAME:"Civil Work",PLAN_FD:"2023-04-27",P6_LV1:"OP",P6_LV2:"Civil Work",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Commissioning",ACTIVITY_NAME:"Commissioning",BL0_SD:"2023-10-04",BL0_FD:"2023-12-01",PLAN_SD:"2023-09-05",PLAN_FD:"2023-11-19",P6_LV1:"OP",P6_LV2:"Commissioning",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Components Prefabrication",ACTIVITY_NAME:"Components Prefabrication",BL0_SD:"2022-05-03",BL0_FD:"2023-06-22",PLAN_SD:"2022-05-03",PLAN_FD:"2022-12-31",ACT_SD:"2022-05-03",P6_LV1:"OP",P6_LV2:"Components Prefabrication",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Construction Preparation",ACTIVITY_NAME:"Construction Preparation",BL0_SD:"2022-01-06",BL0_FD:"2023-05-25",PLAN_SD:"2022-01-06",PLAN_FD:"2023-06-24",ACT_SD:"2022-01-06",P6_LV1:"OP",P6_LV2:"Construction Preparation",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Dredging",ACTIVITY_NAME:"Dredging",BL0_SD:"2022-04-12",BL0_FD:"2023-10-09",PLAN_SD:"2022-04-12",PLAN_FD:"2023-04-30",ACT_SD:"2022-04-12",P6_LV1:"OP",P6_LV2:"Dredging",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"General",ACTIVITY_NAME:"General",BL0_SD:"2022-06-27",BL0_FD:"2022-06-27",PLAN_SD:"2022-08-22",PLAN_FD:"2022-12-03",ACT_SD:"2022-08-22",ACT_FD:"2022-12-03",P6_LV1:"OP",P6_LV2:"General",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Navigation System Installation",ACTIVITY_NAME:"Navigation System Installation",BL0_SD:"2023-04-26",BL0_FD:"2023-09-29",PLAN_SD:"2023-05-11",PLAN_FD:"2023-10-08",P6_LV1:"OP",P6_LV2:"Navigation System Installation",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"NO WBS",ACTIVITY_NAME:"NO WBS",BL0_SD:"2022-04-01",BL0_FD:"2023-08-23",PLAN_SD:"2022-04-01",PLAN_FD:"2023-12-30",ACT_SD:"2022-04-01",ACT_FD:"2022-10-24",P6_LV1:"OP",P6_LV2:"NO WBS",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Pile Trial",ACTIVITY_NAME:"Pile Trial",BL0_SD:"2022-04-04",BL0_FD:"2022-06-16",PLAN_SD:"2022-04-04",PLAN_FD:"2022-05-30",ACT_SD:"2022-04-04",ACT_FD:"2022-05-30",P6_LV1:"OP",P6_LV2:"Pile Trial",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Pipeline Pressure test",ACTIVITY_NAME:"Pipeline Pressure test",BL0_SD:"2023-08-12",BL0_FD:"2023-09-26",PLAN_SD:"2023-07-15",PLAN_FD:"2023-09-23",P6_LV1:"OP",P6_LV2:"Pipeline Pressure test",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Safety sign and device",ACTIVITY_NAME:"Safety sign and device",BL0_SD:"2023-07-21",BL0_FD:"2023-07-21",PLAN_SD:"2023-08-07",PLAN_FD:"2023-08-13",P6_LV1:"OP",P6_LV2:"Safety sign and device",LEVEL:"1",ITEM_DESC:"Construction",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV5_SEQ:99,ITEM_SEQ:4},
    {ACTIVITY_ID:"Jetty",ACTIVITY_NAME:"Jetty",BL0_SD:"2021-12-30",BL0_FD:"2022-04-17",PLAN_SD:"2021-12-30",PLAN_FD:"2022-05-31",ACT_SD:"2021-12-30",ACT_FD:"2022-05-31",P6_LV1:"OP",P6_LV2:"NO WBS",P6_LV3:"Engineering",P6_LV5:"Jetty",LEVEL:"2",ITEM_DESC:"Jetty",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV3_SEQ:2,P6_LV5_SEQ:65,ITEM_SEQ:65},
    {ACTIVITY_ID:"Jetty",ACTIVITY_NAME:"Jetty",BL0_SD:"2022-06-02",BL0_FD:"2022-06-02",PLAN_SD:"2022-07-15",PLAN_FD:"2022-09-17",ACT_SD:"2022-07-15",ACT_FD:"2022-09-17",P6_LV1:"OP",P6_LV2:"Construction Material",P6_LV3:"Procurement",P6_LV5:"Jetty",LEVEL:"2",ITEM_DESC:"Jetty",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV3_SEQ:3,P6_LV5_SEQ:65,ITEM_SEQ:65},
    {ACTIVITY_ID:"Jetty",ACTIVITY_NAME:"Jetty",PLAN_SD:"2022-01-04",PLAN_FD:"2022-01-27",ACT_SD:"2022-01-04",ACT_FD:"2022-01-27",P6_LV1:"OP",P6_LV2:"NO WBS",P6_LV3:"Procurement",P6_LV5:"Jetty",LEVEL:"2",ITEM_DESC:"Jetty",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV3_SEQ:3,P6_LV5_SEQ:65,ITEM_SEQ:65},
    {ACTIVITY_ID:"Jetty",ACTIVITY_NAME:"Jetty",BL0_SD:"2022-01-04",BL0_FD:"2023-03-25",PLAN_SD:"2022-01-04",PLAN_FD:"2023-07-01",ACT_SD:"2022-01-04",P6_LV1:"OP",P6_LV2:"Project Material",P6_LV3:"Procurement",P6_LV5:"Jetty",LEVEL:"2",ITEM_DESC:"Jetty",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV3_SEQ:3,P6_LV5_SEQ:65,ITEM_SEQ:65},
    {ACTIVITY_ID:"Jetty",ACTIVITY_NAME:"Jetty",BL0_SD:"2022-03-09",BL0_FD:"2023-06-24",PLAN_SD:"2022-03-09",PLAN_FD:"2023-05-30",ACT_SD:"2022-03-09",P6_LV1:"OP",P6_LV2:"Sub-contracting",P6_LV3:"Procurement",P6_LV5:"Jetty",LEVEL:"2",ITEM_DESC:"Jetty",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV3_SEQ:3,P6_LV5_SEQ:65,ITEM_SEQ:65},
    {ACTIVITY_ID:"Jetty",ACTIVITY_NAME:"Jetty",BL0_SD:"2022-07-27",BL0_FD:"2023-08-14",PLAN_SD:"2022-06-27",PLAN_FD:"2023-08-15",ACT_SD:"2022-06-27",P6_LV1:"OP",P6_LV2:"Section E",P6_LV3:"Construction",P6_LV5:"Jetty",LEVEL:"2",ITEM_DESC:"Jetty",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV3_SEQ:4,P6_LV5_SEQ:65,ITEM_SEQ:65},
    {ACTIVITY_ID:"Jetty",ACTIVITY_NAME:"Jetty",BL0_SD:"2023-07-23",BL0_FD:"2023-12-31",PLAN_SD:"2023-06-22",PLAN_FD:"2023-12-30",P6_LV1:"OP",P6_LV2:"System Completion & Handover Acceptance",P6_LV3:"Construction",P6_LV5:"Jetty",LEVEL:"2",ITEM_DESC:"Jetty",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV3_SEQ:4,P6_LV5_SEQ:65,ITEM_SEQ:65},
    {ACTIVITY_ID:"Jetty",ACTIVITY_NAME:"Jetty",BL0_SD:"2022-03-05",BL0_FD:"2022-10-31",PLAN_SD:"2022-03-05",PLAN_FD:"2022-11-13",ACT_SD:"2022-03-05",ACT_FD:"2022-11-13",P6_LV1:"OP",P6_LV2:"Temporary Construction Work",P6_LV3:"Construction",P6_LV5:"Jetty",LEVEL:"2",ITEM_DESC:"Jetty",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV3_SEQ:4,P6_LV5_SEQ:65,ITEM_SEQ:65},
    {ACTIVITY_ID:"Jetty",ACTIVITY_NAME:"Jetty",PLAN_FD:"2023-04-27",P6_LV1:"OP",P6_LV2:"Civil Work",P6_LV3:"Construction",P6_LV5:"Jetty",LEVEL:"2",ITEM_DESC:"Jetty",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV3_SEQ:4,P6_LV5_SEQ:65,ITEM_SEQ:65},
    {ACTIVITY_ID:"Jetty",ACTIVITY_NAME:"Jetty",BL0_SD:"2023-10-04",BL0_FD:"2023-12-01",PLAN_SD:"2023-09-05",PLAN_FD:"2023-11-19",P6_LV1:"OP",P6_LV2:"Commissioning",P6_LV3:"Construction",P6_LV5:"Jetty",LEVEL:"2",ITEM_DESC:"Jetty",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV3_SEQ:4,P6_LV5_SEQ:65,ITEM_SEQ:65},
    {ACTIVITY_ID:"Jetty",ACTIVITY_NAME:"Jetty",BL0_SD:"2022-05-03",BL0_FD:"2023-06-22",PLAN_SD:"2022-05-03",PLAN_FD:"2022-12-31",ACT_SD:"2022-05-03",P6_LV1:"OP",P6_LV2:"Components Prefabrication",P6_LV3:"Construction",P6_LV5:"Jetty",LEVEL:"2",ITEM_DESC:"Jetty",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV3_SEQ:4,P6_LV5_SEQ:65,ITEM_SEQ:65},
    {ACTIVITY_ID:"Jetty",ACTIVITY_NAME:"Jetty",BL0_SD:"2022-01-06",BL0_FD:"2023-05-25",PLAN_SD:"2022-01-06",PLAN_FD:"2023-06-24",ACT_SD:"2022-01-06",P6_LV1:"OP",P6_LV2:"Construction Preparation",P6_LV3:"Construction",P6_LV5:"Jetty",LEVEL:"2",ITEM_DESC:"Jetty",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV3_SEQ:4,P6_LV5_SEQ:65,ITEM_SEQ:65},
    {ACTIVITY_ID:"Jetty",ACTIVITY_NAME:"Jetty",BL0_SD:"2022-04-12",BL0_FD:"2023-10-09",PLAN_SD:"2022-04-12",PLAN_FD:"2023-04-30",ACT_SD:"2022-04-12",P6_LV1:"OP",P6_LV2:"Dredging",P6_LV3:"Construction",P6_LV5:"Jetty",LEVEL:"2",ITEM_DESC:"Jetty",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV3_SEQ:4,P6_LV5_SEQ:65,ITEM_SEQ:65},
    {ACTIVITY_ID:"Jetty",ACTIVITY_NAME:"Jetty",BL0_SD:"2022-06-27",BL0_FD:"2022-06-27",PLAN_SD:"2022-08-22",PLAN_FD:"2022-12-03",ACT_SD:"2022-08-22",ACT_FD:"2022-12-03",P6_LV1:"OP",P6_LV2:"General",P6_LV3:"Construction",P6_LV5:"Jetty",LEVEL:"2",ITEM_DESC:"Jetty",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV3_SEQ:4,P6_LV5_SEQ:65,ITEM_SEQ:65},
    {ACTIVITY_ID:"Jetty",ACTIVITY_NAME:"Jetty",BL0_SD:"2023-04-26",BL0_FD:"2023-09-29",PLAN_SD:"2023-05-11",PLAN_FD:"2023-10-08",P6_LV1:"OP",P6_LV2:"Navigation System Installation",P6_LV3:"Construction",P6_LV5:"Jetty",LEVEL:"2",ITEM_DESC:"Jetty",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV3_SEQ:4,P6_LV5_SEQ:65,ITEM_SEQ:65},
    {ACTIVITY_ID:"Jetty",ACTIVITY_NAME:"Jetty",BL0_SD:"2022-04-01",BL0_FD:"2023-08-23",PLAN_SD:"2022-04-01",PLAN_FD:"2023-12-30",ACT_SD:"2022-04-01",ACT_FD:"2022-10-24",P6_LV1:"OP",P6_LV2:"NO WBS",P6_LV3:"Construction",P6_LV5:"Jetty",LEVEL:"2",ITEM_DESC:"Jetty",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV3_SEQ:4,P6_LV5_SEQ:65,ITEM_SEQ:65},
    {ACTIVITY_ID:"Jetty",ACTIVITY_NAME:"Jetty",BL0_SD:"2022-04-04",BL0_FD:"2022-06-16",PLAN_SD:"2022-04-04",PLAN_FD:"2022-05-30",ACT_SD:"2022-04-04",ACT_FD:"2022-05-30",P6_LV1:"OP",P6_LV2:"Pile Trial",P6_LV3:"Construction",P6_LV5:"Jetty",LEVEL:"2",ITEM_DESC:"Jetty",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV3_SEQ:4,P6_LV5_SEQ:65,ITEM_SEQ:65},
    {ACTIVITY_ID:"Jetty",ACTIVITY_NAME:"Jetty",BL0_SD:"2023-08-12",BL0_FD:"2023-09-26",PLAN_SD:"2023-07-15",PLAN_FD:"2023-09-23",P6_LV1:"OP",P6_LV2:"Pipeline Pressure test",P6_LV3:"Construction",P6_LV5:"Jetty",LEVEL:"2",ITEM_DESC:"Jetty",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV3_SEQ:4,P6_LV5_SEQ:65,ITEM_SEQ:65},
    {ACTIVITY_ID:"Jetty",ACTIVITY_NAME:"Jetty",BL0_SD:"2023-07-21",BL0_FD:"2023-07-21",PLAN_SD:"2023-08-07",PLAN_FD:"2023-08-13",P6_LV1:"OP",P6_LV2:"Safety sign and device",P6_LV3:"Construction",P6_LV5:"Jetty",LEVEL:"2",ITEM_DESC:"Jetty",PLAN_PRO:20,ACT_PRO:30,ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:4,P6_LV2_SEQ:499,P6_LV3_SEQ:4,P6_LV5_SEQ:65,ITEM_SEQ:65}
  ]
  
}