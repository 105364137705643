import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 700,
      CanvasHeight: 300,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Support Fabrication', x:20, y: -20, font: 'roboto', class: 'regular', size: 12, color: '#424242', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: -50, y: -20, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'start' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: -30,
          y: -20,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'start'
        },
      ]
    },
    Queries: {
      // SQL1: [
      //   {ORIGINATING_COMPANY:"E2 - Sinopec Engineering Incorporation",ISSUED:124,RESPONDED:104,OPEN:15,CANCELLED:5},
      //   {ORIGINATING_COMPANY:"EM - ExxonMobil Chemical",ISSUED:230,RESPONDED:179,OPEN:46,CANCELLED:5},
      //   {ORIGINATING_COMPANY:"Total",ISSUED:354,RESPONDED:283,OPEN:61,CANCELLED:10}
      // ],
    },
    DataItems:    [
      {ORIGINATING_COMPANY:"E2 - Sinopec Engineering Incorporation",JS_MODIFIED:1,SUBJECT_TO_CHANGE:19,DEVIATION:1},
      {ORIGINATING_COMPANY:"EM - ExxonMobil Chemical",JS_MODIFIED:67,SUBJECT_TO_CHANGE:80,DEVIATION:0},
      {ORIGINATING_COMPANY:"Total",JS_MODIFIED:68,SUBJECT_TO_CHANGE:99,DEVIATION:1}
    ],
}