
export default {
  Canvas: {
    CanvasWidth: 1600,
    CanvasHeight: 1120,
    CanvasBorderWeight: 0.2,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',//0E1B2B
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 760,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },

  Note: {
    NoteDisplay: 'N',
    NoteX: 633,
    NoteY: 358,
    NoteAlign: 'start',
    NoteTitleText: 'Notes.',
    NoteTitleFont: 'roboto',
    NoteTitleStyle: 'regular',
    NoteTitleSize: 11,
    NoteTitleColor: '#757575',

    NoteContentsText: 'Note Contents Here!',
    NoteContentsFont: 'roboto',
    NoteContentsStyle: 'regular',
    NoteContentsSize: 10,
    NoteContentsColor: '#BDBCBC'
  },
  
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  Queries: {
    SQL1:  [
      { NO: 1, CODE: '111300', PACKAGE: 'P1', PNO: 1, NAME: 'BTX - Benzene Toluene Unit ', CNAME: '芳烃抽提装置 ', },
      { NO: 2, CODE: '127700', PACKAGE: 'P1', PNO: 2, NAME: 'BD - Butadiene Unit', CNAME: '丁二烯抽提装置', },
      { NO: 3, CODE: '127800', PACKAGE: 'P1', PNO: 3, NAME: 'GHU - Gasoline Hydrotreating Unit', CNAME: '裂解汽油加氢装置', },
      { NO: 4, CODE: '127900', PACKAGE: 'P1', PNO: 4, NAME: 'OR - Olefins Unit', CNAME: '乙烯装置', },
      { NO: 5, CODE: '136200', PACKAGE: 'P1', PNO: 5, NAME: 'DIB - DI-Isobutylene Unit', CNAME: 'DIB装置', },
      { NO: 6, CODE: '170000', PACKAGE: 'P1', PNO: 6, NAME: 'Pressurized Tank Farm Common Area', CNAME: '压力罐区街区', },
      { NO: 7, CODE: '170092', PACKAGE: 'P1', PNO: 7, NAME: 'Tank Farm 1st Substation', CNAME: '罐区第一变电所', },
      { NO: 8, CODE: '184300', PACKAGE: 'P1', PNO: 8, NAME: 'Demineralized Water Unit', CNAME: '脱盐水站', },
      { NO: 9, CODE: '184800', PACKAGE: 'P1', PNO: 9, NAME: 'Boiler Unit', CNAME: '锅炉装置', },
      { NO: 10, CODE: '277000', PACKAGE: 'P1', PNO: 10, NAME: 'Atmospheric Tank Farm Common Area', CNAME: '常压罐区街区', },
      { NO: 11, CODE: '277100', PACKAGE: 'P1', PNO: 11, NAME: 'Intermediate Tank Farm 2', CNAME: '中间罐区2', },
      { NO: 12, CODE: '121300', PACKAGE: 'P2', PNO: 1, NAME: 'GPPE - Gas Phase Polyethylene Unit ', CNAME: '线性低密度聚乙烯装置', },
      { NO: 13, CODE: '121400', PACKAGE: 'P2', PNO: 2, NAME: 'LDPE - Low Density Polyethylene Unit', CNAME: '高压低密度聚乙烯装置', },
      { NO: 14, CODE: '121900', PACKAGE: 'P2', PNO: 3, NAME: 'PP - Polypropylene Unit', CNAME: '聚丙烯装置', },
      { NO: 15, CODE: '152801', PACKAGE: 'P2', PNO: 4, NAME: 'No.1 Warehouse', CNAME: '1#立体仓库', },
      { NO: 16, CODE: '152802', PACKAGE: 'P2', PNO: 5, NAME: 'No.2 Warehouse', CNAME: '2#立体仓库', },
      { NO: 17, CODE: '151000', PACKAGE: 'P3', PNO: 1, NAME: 'Common Area of Management Facilities(B+)', CNAME: '厂前区街区', },
      { NO: 18, CODE: '152400', PACKAGE: 'P3', PNO: 2, NAME: 'Chemical Warehouses', CNAME: '化学品仓库', },
      { NO: 19, CODE: '152700', PACKAGE: 'P3', PNO: 3, NAME: 'Main Plant Dispatch Area', CNAME: '停车场', },
      { NO: 20, CODE: '178000', PACKAGE: 'P3', PNO: 4, NAME: 'Loading/Unloading Station', CNAME: '汽车装卸设施', },
      { NO: 21, CODE: '178900', PACKAGE: 'P3', PNO: 5, NAME: 'Ground Flare', CNAME: '地面火炬', },
      { NO: 22, CODE: '180200', PACKAGE: 'P3', PNO: 6, NAME: 'Cooling Water System 1', CNAME: '第一循环水场', },
      { NO: 23, CODE: '180292', PACKAGE: 'P3', PNO: 7, NAME: 'Substation', CNAME: '变电所', },
      { NO: 24, CODE: '180600', PACKAGE: 'P3', PNO: 8, NAME: 'Storm Water Pump Station and Accident Buffer Basin', CNAME: '雨水泵站及事故水池', },
      { NO: 25, CODE: '181000', PACKAGE: 'P3', PNO: 9, NAME: 'Water and Firewater Pump Station 1', CNAME: '第一给水及消防泵站', },
      { NO: 26, CODE: '182400', PACKAGE: 'P3', PNO: 10, NAME: 'Wastewater Pre-Treatment Unit', CNAME: '污水预处理场', },
      { NO: 27, CODE: '183000', PACKAGE: 'P3', PNO: 11, NAME: 'Main Substation', CNAME: '总变电站', },
      { NO: 28, CODE: '185300', PACKAGE: 'P3', PNO: 12, NAME: 'Air Compression Station', CNAME: '空压站', },
      { NO: 29, CODE: '280200', PACKAGE: 'P3', PNO: 13, NAME: 'Cooling Water System 2', CNAME: '第二循环水场', },
      { NO: 30, CODE: '281000', PACKAGE: 'P3', PNO: 14, NAME: 'Water and Firewater Pump Station 2', CNAME: '第二给水及消防泵站', },
      { NO: 31, CODE: '179001', PACKAGE: 'P3', PNO: 15, NAME: 'Pipe Galley #1', CNAME: '管道舱 #1', },
      { NO: 32, CODE: '179002', PACKAGE: 'P3', PNO: 16, NAME: 'Pipe Galley #2', CNAME: '管道舱 #2', },
      { NO: 33, CODE: '179003', PACKAGE: 'P3', PNO: 17, NAME: 'Pipe Galley #3', CNAME: '管道舱 #3', },
      { NO: 34, CODE: '179004', PACKAGE: 'P3', PNO: 18, NAME: 'Pipe Galley #4', CNAME: '管道舱 #4', },
      { NO: 35, CODE: '179005', PACKAGE: 'P3', PNO: 19, NAME: 'Pipe Galley #5', CNAME: '管道舱 #5', },
      { NO: 36, CODE: '179006', PACKAGE: 'P3', PNO: 20, NAME: 'Pipe Galley #6', CNAME: '管道舱 #6', },
      { NO: 37, CODE: '179007', PACKAGE: 'P3', PNO: 21, NAME: 'Pipe Galley #7', CNAME: '管道舱 #7', },
    ]
  },
  
  DataItems: [
    {CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"127700",SEICODE_L1_DESC_E:"BD - Butadiene Unit",SEICODE_L1_DESC_C:"丁二烯抽提装置"},
    {CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"127800",SEICODE_L1_DESC_E:"GHU - Gasoline Hydrotreating Unit",SEICODE_L1_DESC_C:"裂解汽油加氢装置"},
    {CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"127900",SEICODE_L1_DESC_E:"OR - Olefins Unit",SEICODE_L1_DESC_C:"乙烯装置"},
    {CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"136200",SEICODE_L1_DESC_E:"DIB - DI-Isobutylene Unit",SEICODE_L1_DESC_C:"DIB装置"},
    {CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"170000",SEICODE_L1_DESC_E:"Pressurized Tank Farm Common Area",SEICODE_L1_DESC_C:"压力罐区街区"},
    {CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"170092",SEICODE_L1_DESC_E:"Tank Farm 1st Substation",SEICODE_L1_DESC_C:"罐区第一变电所"},
    {CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"184300",SEICODE_L1_DESC_E:"Demineralized Water Unit",SEICODE_L1_DESC_C:"脱盐水站"},
    {CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"184800",SEICODE_L1_DESC_E:"Boiler Unit",SEICODE_L1_DESC_C:"锅炉装置"},
    {CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"277000",SEICODE_L1_DESC_E:"Atmospheric Tank Farm Common Area",SEICODE_L1_DESC_C:"常压罐区街区"},
    {CATEGORY:"Mainplant",PACKAGE:"P1",SEICODE_L1:"277100",SEICODE_L1_DESC_E:"Intermediate Tank Farm 2",SEICODE_L1_DESC_C:"中间罐区2"},
    {CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"111300",SEICODE_L1_DESC_E:"BTX - Benzene Toluene Unit ",SEICODE_L1_DESC_C:"芳烃抽提装置 "},
    {CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121300",SEICODE_L1_DESC_E:"GPPE - Gas Phase Polyethylene Unit ",SEICODE_L1_DESC_C:"线性低密度聚乙烯装置"},
    {CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121400",SEICODE_L1_DESC_E:"LDPE ? Low Density Polyethylene Unit",SEICODE_L1_DESC_C:"高压低密度聚乙烯装置"},
    {CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"121900",SEICODE_L1_DESC_E:"PP ? Polypropylene Unit",SEICODE_L1_DESC_C:"聚丙烯装置"},
    {CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"152801",SEICODE_L1_DESC_E:"No.1 Warehouse",SEICODE_L1_DESC_C:"1#立体仓库"},
    {CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"152802",SEICODE_L1_DESC_E:"No.2 Warehouse",SEICODE_L1_DESC_C:"2#立体仓库"},
    {CATEGORY:"Mainplant",PACKAGE:"P2",SEICODE_L1:"NA",SEICODE_L1_DESC_E:"Polymer Bagging and Logistics",SEICODE_L1_DESC_C:"聚烯烃包装及立体库"},
    {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"151000",SEICODE_L1_DESC_E:"Common Area of Management Facilities(B+)",SEICODE_L1_DESC_C:"厂前区街区"},
    {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"152400",SEICODE_L1_DESC_E:"Chemical Warehouses",SEICODE_L1_DESC_C:"化学品仓库"},
    {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"152700",SEICODE_L1_DESC_E:"Main plant Dispatch Area",SEICODE_L1_DESC_C:"停车场"},
    {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"178000",SEICODE_L1_DESC_E:"Loading/Unloading Station",SEICODE_L1_DESC_C:"汽车装卸设施"},
    {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"178900",SEICODE_L1_DESC_E:"Ground Flare",SEICODE_L1_DESC_C:"地面火炬"},
    {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"179000",SEICODE_L1_DESC_E:"Overall Interconnecting Pipe (A.G.) 1"},
    {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"179001",SEICODE_L1_DESC_E:"Pipe Galley #1"},
    {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"179002",SEICODE_L1_DESC_E:"Pipe Galley #2"},
    {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"179003",SEICODE_L1_DESC_E:"Pipe Galley #3"},
    {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"179004",SEICODE_L1_DESC_E:"Pipe Galley #4"},
    {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"179005",SEICODE_L1_DESC_E:"Pipe Galley #5"},
    {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"179006",SEICODE_L1_DESC_E:"Pipe Galley #6"},
    {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"179007",SEICODE_L1_DESC_E:"Pipe Galley #7"},
    {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"179100",SEICODE_L1_DESC_E:"Overall Interconnecting Pipe (A.G.) 2"},
    {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"180200",SEICODE_L1_DESC_E:"Cooling Water System 1",SEICODE_L1_DESC_C:"第一循环水场"},
    {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"180292",SEICODE_L1_DESC_E:"Substation",SEICODE_L1_DESC_C:"变电所"},
    {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"180600",SEICODE_L1_DESC_E:"Storm Water Pump Station and Accident Buffer Basin",SEICODE_L1_DESC_C:"雨水泵站及事故水池"},
    {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"181000",SEICODE_L1_DESC_E:"Water and Firewater Pump Station 1",SEICODE_L1_DESC_C:"第一给水及消防泵站"},
    {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"182400",SEICODE_L1_DESC_E:"Wastewater Pre-Treatment Unit",SEICODE_L1_DESC_C:"污水预处理场"},
    {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"183000",SEICODE_L1_DESC_E:"Main Substation",SEICODE_L1_DESC_C:"总变电站"},
    {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"185300",SEICODE_L1_DESC_E:"Air Compression Station",SEICODE_L1_DESC_C:"空压站"},
    {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"280200",SEICODE_L1_DESC_E:"Cooling Water System 2",SEICODE_L1_DESC_C:"第二循环水场"},
    {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"281000",SEICODE_L1_DESC_E:"Water and Firewater Pump Station 2",SEICODE_L1_DESC_C:"第二给水及消防泵站"},
    {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"NA",SEICODE_L1_DESC_E:"Electrical£¬ Instrument and Telecom System",SEICODE_L1_DESC_C:"供配电及仪表通信系统"},
    {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"NA",SEICODE_L1_DESC_E:"Overall General Layout",SEICODE_L1_DESC_C:"全厂总图运输"},
    {CATEGORY:"Mainplant",PACKAGE:"P3",SEICODE_L1:"NA",SEICODE_L1_DESC_E:"Storage and Transportation Facilities",SEICODE_L1_DESC_C:"储运工程"}
  ],
}