import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1300,
      CanvasHeight: 920,
      CanvasBorderWeight: 0.2,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },


    DataItems: [
      { AREA: 'Hull', SN: 1,  MODULE: 'NP2UH', DISC: 'ME', PKG_NO: 'MR002', BLOCK: 'N250H', DECK: null, SVG: 'NP2UH', TAG_NO: 'LFL-934221', TAG_DESCR: 'Mooring Fairlead, 4 NE', DWG: 'GM004-ST-STA-241-00065-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-09-11', FAT_ACTUAL: '2019-07-05', ETA_PLAN: '2019-03-07', ETA_ACTUAL: '2019-08-19', ROS: '2018-12-31', INSTALL: '2019-08-30', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 2,  MODULE: 'NP2UH', DISC: 'ME', PKG_NO: 'MR002', BLOCK: 'N250H', DECK: null, SVG: 'NP2UH', TAG_NO: 'LFL-934222', TAG_DESCR: 'Mooring Fairlead, 5  NE', DWG: 'GM004-ST-STA-241-00065-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-09-11', FAT_ACTUAL: '2019-06-06', ETA_PLAN: '2019-03-07', ETA_ACTUAL: '2019-08-19', ROS: '2018-12-31', INSTALL: '2019-09-05', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 3,  MODULE: 'NP2UH', DISC: 'ME', PKG_NO: 'MR002', BLOCK: 'N250H', DECK: null, SVG: 'NP2UH', TAG_NO: 'LFL-934223', TAG_DESCR: 'Mooring Fairlead, 6 NE', DWG: 'GM004-ST-STA-241-00065-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-09-11', FAT_ACTUAL: '2019-07-15', ETA_PLAN: '2019-03-07', ETA_ACTUAL: '2019-09-04', ROS: '2018-12-31', INSTALL: '2019-09-08', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 4,  MODULE: 'NP2UH', DISC: 'ME', PKG_NO: 'MO002', BLOCK: 'N250H', DECK: null, SVG: 'NP2UH', TAG_NO: 'TLE-985121', TAG_DESCR: 'Rack An Pinion Type Elevator (NE)', DWG: 'GM004-PI-LAY-010-00051-001', PO_PLAN: '2017-07-14', PO_ACTUAL: '2017-07-14', FAT_PLAN: '2018-12-11', FAT_ACTUAL: '2018-12-11', ETA_PLAN: '2019-03-04', ETA_ACTUAL: '2018-12-31', ROS: '2018-12-23', INSTALL: '2019-04-03', UP_BLOCK_PE: '2019-03-08', ROS_ETA: -8, ROS_ETA_UPBLOCK: 67, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 5,  MODULE: 'NP2UH', DISC: 'AR', PKG_NO: 'MO001', BLOCK: 'N210H', DECK: null, SVG: 'NP2UH', TAG_NO: 'LDW-936021', TAG_DESCR: 'Sliding', DWG: 'GM004-PI-LAY-010-00051-001', PO_PLAN: '2017-11-03', PO_ACTUAL: '2018-01-10', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2018-10-25', ETA_ACTUAL: '2018-10-19', ROS: '2018-10-30', INSTALL: '2018-11-14', UP_BLOCK_PE: '2018-11-06', ROS_ETA: 11, ROS_ETA_UPBLOCK: 18, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 6,  MODULE: 'NP2UH', DISC: 'AR', PKG_NO: 'MO001', BLOCK: 'N210H', DECK: null, SVG: 'NP2UH', TAG_NO: 'LDW-936022', TAG_DESCR: 'Sliding', DWG: 'GM004-PI-LAY-010-00051-001', PO_PLAN: '2017-11-03', PO_ACTUAL: '2018-01-10', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2018-10-25', ETA_ACTUAL: '2018-10-19', ROS: '2018-10-30', INSTALL: '2018-11-14', UP_BLOCK_PE: '2018-11-06', ROS_ETA: 11, ROS_ETA_UPBLOCK: 18, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 7,  MODULE: 'NP2UH', DISC: 'ME', PKG_NO: 'MU010', BLOCK: 'N210H', DECK: null, SVG: 'NP2UH', TAG_NO: 'PBE-935202', TAG_DESCR: 'De-Ballast Pump - NE Quadrant', DWG: 'GM004-PI-LAY-010-00051-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-08-24', FAT_ACTUAL: '2018-10-31', ETA_PLAN: '2018-11-15', ETA_ACTUAL: '2018-11-19', ROS: '2018-11-08', INSTALL: '2018-11-19', UP_BLOCK_PE: '2018-11-21', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 8,  MODULE: 'NP2UH', DISC: 'ME', PKG_NO: 'MU010', BLOCK: 'N210H', DECK: null, SVG: 'NP2UH', TAG_NO: 'ZZZ-935204', TAG_DESCR: 'Priming Unit For De-Ballast Pump- NE Quadrant', DWG: 'GM004-PI-LAY-010-00051-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-08-24', FAT_ACTUAL: '2019-05-15', ETA_PLAN: '2018-11-15', ETA_ACTUAL: '2019-06-17', ROS: '2018-11-08', INSTALL: '2019-06-21', UP_BLOCK_PE: '2018-11-21', ROS_ETA: -221, ROS_ETA_UPBLOCK: -208, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 9,  MODULE: 'NP2UH', DISC: 'ME', PKG_NO: 'MU010', BLOCK: 'N210H', DECK: null, SVG: 'NP2UH', TAG_NO: 'ZZZ-938205', TAG_DESCR: 'Priming Unit For Main Bilge Pump- NE Quadrant', DWG: 'GM004-PI-LAY-010-00051-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-08-24', FAT_ACTUAL: '2019-06-20', ETA_PLAN: '2018-11-15', ETA_ACTUAL: '2019-06-28', ROS: '2018-11-08', INSTALL: '2019-07-02', UP_BLOCK_PE: '2018-11-21', ROS_ETA: -232, ROS_ETA_UPBLOCK: -219, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 10, MODULE: 'NP2UH', DISC: 'ME', PKG_NO: 'MU010', BLOCK: 'N210H', DECK: null, SVG: 'NP2UH', TAG_NO: 'PBE-938202', TAG_DESCR: 'Main Bilge Pump - NE Quadrant', DWG: 'GM004-PI-LAY-010-00051-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-08-24', FAT_ACTUAL: '2018-08-25', ETA_PLAN: '2018-11-15', ETA_ACTUAL: '2018-11-15', ROS: '2018-11-08', INSTALL: '2018-11-16', UP_BLOCK_PE: '2018-11-21', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 11, MODULE: 'NP2UH', DISC: 'ME', PKG_NO: 'MU010', BLOCK: 'N210H', DECK: null, SVG: 'NP2UH', TAG_NO: 'PBE-935201', TAG_DESCR: 'De-Ballast Pump - NE Quadrant', DWG: 'GM004-PI-LAY-010-00051-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-08-24', FAT_ACTUAL: '2018-10-31', ETA_PLAN: '2018-11-15', ETA_ACTUAL: '2018-11-19', ROS: '2018-11-08', INSTALL: '2018-11-19', UP_BLOCK_PE: '2018-11-21', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 12, MODULE: 'NP2UH', DISC: 'ME', PKG_NO: 'MU010', BLOCK: 'N210H', DECK: null, SVG: 'NP2UH', TAG_NO: 'ZZZ-935205', TAG_DESCR: 'Priming Unit For De-Ballast Pump- NE Quadrant', DWG: 'GM004-PI-LAY-010-00051-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-08-24', FAT_ACTUAL: '2019-05-15', ETA_PLAN: '2018-11-15', ETA_ACTUAL: '2019-06-17', ROS: '2018-11-08', INSTALL: '2019-06-21', UP_BLOCK_PE: '2018-11-21', ROS_ETA: -221, ROS_ETA_UPBLOCK: -208, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 13, MODULE: 'NP2UH', DISC: 'ME', PKG_NO: 'MU010', BLOCK: 'N210H', DECK: null, SVG: 'NP2UH', TAG_NO: 'PBE-938201', TAG_DESCR: 'Main Bilge Pump - NE Quadrant', DWG: 'GM004-PI-LAY-010-00051-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-08-24', FAT_ACTUAL: '2018-08-25', ETA_PLAN: '2018-11-15', ETA_ACTUAL: '2018-11-15', ROS: '2018-11-08', INSTALL: '2018-11-16', UP_BLOCK_PE: '2018-11-21', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 14, MODULE: 'NP2UH', DISC: 'ME', PKG_NO: 'MU010', BLOCK: 'N210H', DECK: null, SVG: 'NP2UH', TAG_NO: 'ZZZ-938204', TAG_DESCR: 'Priming Unit For Main Bilge Pump- NE Quadrant', DWG: 'GM004-PI-LAY-010-00051-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-08-24', FAT_ACTUAL: '2019-06-20', ETA_PLAN: '2018-11-15', ETA_ACTUAL: '2019-06-28', ROS: '2018-11-08', INSTALL: '2019-07-02', UP_BLOCK_PE: '2018-11-21', ROS_ETA: -232, ROS_ETA_UPBLOCK: -219, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 15, MODULE: 'NP2UH', DISC: 'ME', PKG_NO: 'MU020', BLOCK: 'N210H', DECK: null, SVG: 'NP2UH', TAG_NO: 'PBE-938203', TAG_DESCR: 'Lower Hull Daily Bilge Pumps', DWG: 'GM004-PI-LAY-010-00051-001', PO_PLAN: '2017-08-02', PO_ACTUAL: '2017-08-02', FAT_PLAN: '2018-08-16', FAT_ACTUAL: '2018-09-07', ETA_PLAN: '2018-10-30', ETA_ACTUAL: '2018-10-30', ROS: '2018-11-08', INSTALL: '2018-11-09', UP_BLOCK_PE: '2018-11-21', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 1,  MODULE: 'NP1UH', DISC: 'ME', PKG_NO: 'MR002', BLOCK: 'N150H', DECK: null, SVG: 'NP1UH', TAG_NO: 'LFL-934121', TAG_DESCR: 'Mooring Fairlead, 1 NW', DWG: 'GM004-ST-STA-241-00065-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-09-11', FAT_ACTUAL: '2019-07-02', ETA_PLAN: '2019-03-07', ETA_ACTUAL: '2019-08-19', ROS: '2018-12-13', INSTALL: '2019-08-29', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 2,  MODULE: 'NP1UH', DISC: 'ME', PKG_NO: 'MR002', BLOCK: 'N150H', DECK: null, SVG: 'NP1UH', TAG_NO: 'LFL-934122', TAG_DESCR: 'Mooring Fairlead, 2 NW', DWG: 'GM004-ST-STA-241-00065-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-09-11', FAT_ACTUAL: '2019-08-05', ETA_PLAN: '2019-03-07', ETA_ACTUAL: '2019-09-04', ROS: '2018-12-13', INSTALL: '2019-09-10', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 3,  MODULE: 'NP1UH', DISC: 'ME', PKG_NO: 'MR002', BLOCK: 'N150H', DECK: null, SVG: 'NP1UH', TAG_NO: 'LFL-934123', TAG_DESCR: 'Mooring Fairlead, 3 NW', DWG: 'GM004-ST-STA-241-00065-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-09-11', FAT_ACTUAL: '2019-08-05', ETA_PLAN: '2019-03-07', ETA_ACTUAL: '2019-07-30', ROS: '2018-12-13', INSTALL: '2019-08-29', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 4,  MODULE: 'NP1UH', DISC: 'ME', PKG_NO: 'MU020', BLOCK: 'N110H', DECK: null, SVG: 'NP1UH', TAG_NO: 'PBE-938103', TAG_DESCR: 'Lower Hull Daily Bilge Pumps', DWG: 'GM004-PI-LAY-010-00052-001', PO_PLAN: '2017-08-02', PO_ACTUAL: '2017-08-02', FAT_PLAN: '2018-08-16', FAT_ACTUAL: '2018-09-07', ETA_PLAN: '2018-10-30', ETA_ACTUAL: '2018-10-30', ROS: '2018-10-15', INSTALL: '2018-10-31', UP_BLOCK_PE: '2018-11-03', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 5,  MODULE: 'NP1UH', DISC: 'AR', PKG_NO: 'MO001', BLOCK: 'N110H', DECK: null, SVG: 'NP1UH', TAG_NO: 'LDW-936011', TAG_DESCR: 'Sliding', DWG: 'GM004-PI-LAY-010-00052-001', PO_PLAN: '2017-11-03', PO_ACTUAL: '2018-01-10', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2018-10-25', ETA_ACTUAL: '2018-10-19', ROS: '2018-10-16', INSTALL: '2018-10-26', UP_BLOCK_PE: '2018-10-23', ROS_ETA: -3, ROS_ETA_UPBLOCK: 4, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 6,  MODULE: 'NP1UH', DISC: 'ME', PKG_NO: 'MU010', BLOCK: 'N110H', DECK: null, SVG: 'NP1UH', TAG_NO: 'PBE-935101', TAG_DESCR: 'De-Ballast Pump - NW Quadrant', DWG: 'GM004-PI-LAY-010-00052-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-08-24', FAT_ACTUAL: '2018-11-01', ETA_PLAN: '2018-11-15', ETA_ACTUAL: '2018-11-26', ROS: '2018-10-15', INSTALL: '2018-12-04', UP_BLOCK_PE: '2018-11-03', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 7,  MODULE: 'NP1UH', DISC: 'ME', PKG_NO: 'MU010', BLOCK: 'N110H', DECK: null, SVG: 'NP1UH', TAG_NO: 'PBE-938101', TAG_DESCR: 'Main Bilge Pump - NW Quadrant', DWG: 'GM004-PI-LAY-010-00052-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-08-24', FAT_ACTUAL: '2018-08-24', ETA_PLAN: '2018-11-15', ETA_ACTUAL: '2018-11-15', ROS: '2018-10-15', INSTALL: '2018-12-04', UP_BLOCK_PE: '2018-11-03', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 8,  MODULE: 'NP1UH', DISC: 'ME', PKG_NO: 'MU010', BLOCK: 'N110H', DECK: null, SVG: 'NP1UH', TAG_NO: 'ZZZ-935105', TAG_DESCR: 'Priming Unit For De-Ballast Pump- NW Quadrant', DWG: 'GM004-PI-LAY-010-00052-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-08-24', FAT_ACTUAL: '2019-05-15', ETA_PLAN: '2018-11-15', ETA_ACTUAL: '2019-06-17', ROS: '2018-10-15', INSTALL: '2019-06-21', UP_BLOCK_PE: '2018-11-03', ROS_ETA: -245, ROS_ETA_UPBLOCK: -226, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 9,  MODULE: 'NP1UH', DISC: 'ME', PKG_NO: 'MU010', BLOCK: 'N110H', DECK: null, SVG: 'NP1UH', TAG_NO: 'ZZZ-938104', TAG_DESCR: 'Priming Unit For Main Bilge Pump- NW Quadrant', DWG: 'GM004-PI-LAY-010-00052-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-08-24', FAT_ACTUAL: '2019-06-20', ETA_PLAN: '2018-11-15', ETA_ACTUAL: '2019-06-28', ROS: '2018-10-15', INSTALL: '2019-07-02', UP_BLOCK_PE: '2018-11-03', ROS_ETA: -256, ROS_ETA_UPBLOCK: -237, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 10, MODULE: 'NP1UH', DISC: 'AR', PKG_NO: 'MO001', BLOCK: 'N110H', DECK: null, SVG: 'NP1UH', TAG_NO: 'LDW-936012', TAG_DESCR: 'Sliding', DWG: 'GM004-PI-LAY-010-00052-001', PO_PLAN: '2017-11-03', PO_ACTUAL: '2018-01-10', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2018-10-25', ETA_ACTUAL: '2018-10-19', ROS: '2018-10-16', INSTALL: '2018-10-26', UP_BLOCK_PE: '2018-10-23', ROS_ETA: -3, ROS_ETA_UPBLOCK: 4, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 11, MODULE: 'NP1UH', DISC: 'ME', PKG_NO: 'MU010', BLOCK: 'N110H', DECK: null, SVG: 'NP1UH', TAG_NO: 'PBE-938102', TAG_DESCR: 'Main Bilge Pump - NW Quadrant', DWG: 'GM004-PI-LAY-010-00052-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-08-24', FAT_ACTUAL: '2018-08-24', ETA_PLAN: '2018-11-15', ETA_ACTUAL: '2018-11-15', ROS: '2018-10-15', INSTALL: '2018-12-03', UP_BLOCK_PE: '2018-11-03', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 12, MODULE: 'NP1UH', DISC: 'ME', PKG_NO: 'MU010', BLOCK: 'N110H', DECK: null, SVG: 'NP1UH', TAG_NO: 'ZZZ-938105', TAG_DESCR: 'Priming Unit For Main Bilge Pump- NW Quadrant', DWG: 'GM004-PI-LAY-010-00052-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-08-24', FAT_ACTUAL: '2019-06-20', ETA_PLAN: '2018-11-15', ETA_ACTUAL: '2019-06-28', ROS: '2018-10-15', INSTALL: '2019-07-02', UP_BLOCK_PE: '2018-11-03', ROS_ETA: -256, ROS_ETA_UPBLOCK: -237, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 13, MODULE: 'NP1UH', DISC: 'ME', PKG_NO: 'MU010', BLOCK: 'N110H', DECK: null, SVG: 'NP1UH', TAG_NO: 'ZZZ-935104', TAG_DESCR: 'Priming Unit For De-Ballast Pump- NW Quadrant', DWG: 'GM004-PI-LAY-010-00052-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-08-24', FAT_ACTUAL: '2019-05-15', ETA_PLAN: '2018-11-15', ETA_ACTUAL: '2019-06-17', ROS: '2018-10-15', INSTALL: '2019-06-21', UP_BLOCK_PE: '2018-11-03', ROS_ETA: -245, ROS_ETA_UPBLOCK: -226, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 14, MODULE: 'NP1UH', DISC: 'ME', PKG_NO: 'MU010', BLOCK: 'N110H', DECK: null, SVG: 'NP1UH', TAG_NO: 'PBE-935102', TAG_DESCR: 'De-Ballast Pump - NW Quadrant', DWG: 'GM004-PI-LAY-010-00052-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-08-24', FAT_ACTUAL: '2018-11-01', ETA_PLAN: '2018-11-15', ETA_ACTUAL: '2018-11-26', ROS: '2018-10-15', INSTALL: '2018-12-03', UP_BLOCK_PE: '2018-11-03', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 1,  MODULE: 'NP3UH', DISC: 'ME', PKG_NO: 'MR002', BLOCK: 'N350H', DECK: null, SVG: 'NP3UH', TAG_NO: 'LFL-934321', TAG_DESCR: 'Mooring Fairlead, 7 SE', DWG: 'GM004-ST-STA-241-00065-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-09-11', FAT_ACTUAL: '2019-06-27', ETA_PLAN: '2019-03-07', ETA_ACTUAL: '2019-07-30', ROS: '2019-02-02', INSTALL: '2019-08-25', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 2,  MODULE: 'NP3UH', DISC: 'ME', PKG_NO: 'MR002', BLOCK: 'N350H', DECK: null, SVG: 'NP3UH', TAG_NO: 'LFL-934322', TAG_DESCR: 'Mooring Fairlead, 8 SE', DWG: 'GM004-ST-STA-241-00065-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-09-11', FAT_ACTUAL: '2019-08-05', ETA_PLAN: '2019-03-07', ETA_ACTUAL: '2019-09-04', ROS: '2019-02-02', INSTALL: '2019-09-09', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 3,  MODULE: 'NP3UH', DISC: 'ME', PKG_NO: 'MR002', BLOCK: 'N350H', DECK: null, SVG: 'NP3UH', TAG_NO: 'LFL-934323', TAG_DESCR: 'Mooring Fairlead, 9 SE', DWG: 'GM004-ST-STA-241-00065-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-09-11', FAT_ACTUAL: '2019-06-11', ETA_PLAN: '2019-03-07', ETA_ACTUAL: '2019-08-19', ROS: '2019-02-02', INSTALL: '2019-08-26', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 4,  MODULE: 'NP3UH', DISC: 'ME', PKG_NO: 'MU020', BLOCK: 'N310H', DECK: null, SVG: 'NP3UH', TAG_NO: 'PBE-938303', TAG_DESCR: 'Lower Hull Daily Bilge Pumps', DWG: 'GM004-PI-LAY-010-00053-001', PO_PLAN: '2017-08-02', PO_ACTUAL: '2017-08-02', FAT_PLAN: '2018-08-16', FAT_ACTUAL: '2018-09-07', ETA_PLAN: '2018-10-30', ETA_ACTUAL: '2018-12-07', ROS: '2018-12-17', INSTALL: '2018-12-19', UP_BLOCK_PE: '2018-12-24', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 5,  MODULE: 'NP3UH', DISC: 'AR', PKG_NO: 'MO001', BLOCK: 'N310H', DECK: null, SVG: 'NP3UH', TAG_NO: 'LDW-936031', TAG_DESCR: 'Sliding', DWG: 'GM004-PI-LAY-010-00053-001', PO_PLAN: '2017-11-03', PO_ACTUAL: '2018-01-10', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2018-10-25', ETA_ACTUAL: '2018-10-19', ROS: '2018-11-29', INSTALL: '2018-12-19', UP_BLOCK_PE: '2018-12-06', ROS_ETA: 41, ROS_ETA_UPBLOCK: 48, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 6,  MODULE: 'NP3UH', DISC: 'AR', PKG_NO: 'MO001', BLOCK: 'N310H', DECK: null, SVG: 'NP3UH', TAG_NO: 'LDW-936032', TAG_DESCR: 'Sliding', DWG: 'GM004-PI-LAY-010-00053-001', PO_PLAN: '2017-11-03', PO_ACTUAL: '2018-01-10', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2018-10-25', ETA_ACTUAL: '2018-10-19', ROS: '2018-11-29', INSTALL: '2018-12-19', UP_BLOCK_PE: '2018-12-06', ROS_ETA: 41, ROS_ETA_UPBLOCK: 48, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 7,  MODULE: 'NP3UH', DISC: 'ME', PKG_NO: 'MU010', BLOCK: 'N310H', DECK: null, SVG: 'NP3UH', TAG_NO: 'PBE-935301', TAG_DESCR: 'De-Ballast Pump - SE Quadrant', DWG: 'GM004-PI-LAY-010-00053-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-08-24', FAT_ACTUAL: '2018-11-05', ETA_PLAN: '2018-11-15', ETA_ACTUAL: '2018-11-26', ROS: '2018-12-24', INSTALL: '2018-12-19', UP_BLOCK_PE: '2018-12-24', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 8,  MODULE: 'NP3UH', DISC: 'ME', PKG_NO: 'MU010', BLOCK: 'N310H', DECK: null, SVG: 'NP3UH', TAG_NO: 'PBE-938301', TAG_DESCR: 'Main Bilge Pump - SE Quadrant', DWG: 'GM004-PI-LAY-010-00053-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-08-24', FAT_ACTUAL: '2018-08-27', ETA_PLAN: '2018-11-15', ETA_ACTUAL: '2018-11-15', ROS: '2018-12-24', INSTALL: '2018-12-19', UP_BLOCK_PE: '2018-12-24', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 9,  MODULE: 'NP3UH', DISC: 'ME', PKG_NO: 'MU010', BLOCK: 'N310H', DECK: null, SVG: 'NP3UH', TAG_NO: 'ZZZ-935304', TAG_DESCR: 'Priming Unit For De-Ballast Pump- SE Quadrant', DWG: 'GM004-PI-LAY-010-00053-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-08-24', FAT_ACTUAL: '2019-05-15', ETA_PLAN: '2018-11-15', ETA_ACTUAL: '2019-06-17', ROS: '2018-12-24', INSTALL: '2019-06-21', UP_BLOCK_PE: '2018-12-24', ROS_ETA: -175, ROS_ETA_UPBLOCK: -175, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 10, MODULE: 'NP3UH', DISC: 'ME', PKG_NO: 'MU010', BLOCK: 'N310H', DECK: null, SVG: 'NP3UH', TAG_NO: 'ZZZ-938304', TAG_DESCR: 'Priming Unit For Main Bilge Pump- SE Quadrant', DWG: 'GM004-PI-LAY-010-00053-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-08-24', FAT_ACTUAL: '2019-06-20', ETA_PLAN: '2018-11-15', ETA_ACTUAL: '2019-06-28', ROS: '2018-12-24', INSTALL: '2019-07-02', UP_BLOCK_PE: '2018-12-24', ROS_ETA: -186, ROS_ETA_UPBLOCK: -186, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 11, MODULE: 'NP3UH', DISC: 'ME', PKG_NO: 'MU018', BLOCK: 'N320H', DECK: null, SVG: 'NP3UH', TAG_NO: 'PBE-806034', TAG_DESCR: 'Diesel Transfer Pump', DWG: 'GM004-PI-LAY-010-00053-001', PO_PLAN: '2017-08-03', PO_ACTUAL: '2017-08-03', FAT_PLAN: '2018-08-29', FAT_ACTUAL: '2018-10-19', ETA_PLAN: '2018-11-21', ETA_ACTUAL: '2018-11-20', ROS: '2018-12-20', INSTALL: '2018-12-17', UP_BLOCK_PE: '2018-12-20', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 12, MODULE: 'NP3UH', DISC: 'ME', PKG_NO: 'MU018', BLOCK: 'N310H', DECK: null, SVG: 'NP3UH', TAG_NO: 'PBE-806033', TAG_DESCR: 'Diesel Transfer Pump', DWG: 'GM004-PI-LAY-010-00053-001', PO_PLAN: '2017-08-03', PO_ACTUAL: '2017-08-03', FAT_PLAN: '2018-08-29', FAT_ACTUAL: '2018-10-19', ETA_PLAN: '2018-11-21', ETA_ACTUAL: '2018-11-20', ROS: '2018-12-24', INSTALL: '2018-12-17', UP_BLOCK_PE: '2018-12-24', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 13, MODULE: 'NP3UH', DISC: 'ME', PKG_NO: 'MU010', BLOCK: 'N320H', DECK: null, SVG: 'NP3UH', TAG_NO: 'PBE-938302', TAG_DESCR: 'Main Bilge Pump - SE Quadrant', DWG: 'GM004-PI-LAY-010-00053-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-08-24', FAT_ACTUAL: '2018-08-27', ETA_PLAN: '2018-11-15', ETA_ACTUAL: '2018-11-15', ROS: '2018-12-20', INSTALL: '2018-12-17', UP_BLOCK_PE: '2018-12-20', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 14, MODULE: 'NP3UH', DISC: 'ME', PKG_NO: 'MU010', BLOCK: 'N320H', DECK: null, SVG: 'NP3UH', TAG_NO: 'ZZZ-938305', TAG_DESCR: 'Priming Unit For Main Bilge Pump- SE Quadrant', DWG: 'GM004-PI-LAY-010-00053-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-08-24', FAT_ACTUAL: '2019-06-20', ETA_PLAN: '2018-11-15', ETA_ACTUAL: '2019-06-28', ROS: '2018-12-20', INSTALL: '2019-07-02', UP_BLOCK_PE: '2018-12-20', ROS_ETA: -190, ROS_ETA_UPBLOCK: -190, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 15, MODULE: 'NP3UH', DISC: 'ME', PKG_NO: 'MU010', BLOCK: 'N320H', DECK: null, SVG: 'NP3UH', TAG_NO: 'PBE-935302', TAG_DESCR: 'De-Ballast Pump - SE Quadrant', DWG: 'GM004-PI-LAY-010-00053-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-08-24', FAT_ACTUAL: '2018-11-05', ETA_PLAN: '2018-11-15', ETA_ACTUAL: '2018-11-26', ROS: '2018-12-20', INSTALL: '2018-12-17', UP_BLOCK_PE: '2018-12-20', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 16, MODULE: 'NP3UH', DISC: 'ME', PKG_NO: 'MU010', BLOCK: 'N320H', DECK: null, SVG: 'NP3UH', TAG_NO: 'ZZZ-935305', TAG_DESCR: 'Priming Unit For De-Ballast Pump- SE Quadrant', DWG: 'GM004-PI-LAY-010-00053-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-08-24', FAT_ACTUAL: '2019-05-15', ETA_PLAN: '2018-11-15', ETA_ACTUAL: '2019-06-17', ROS: '2018-12-20', INSTALL: '2019-06-21', UP_BLOCK_PE: '2018-12-20', ROS_ETA: -179, ROS_ETA_UPBLOCK: -179, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 1,  MODULE: 'NP4UH', DISC: 'ME', PKG_NO: 'MR002', BLOCK: 'N450H', DECK: null, SVG: 'NP4UH', TAG_NO: 'LFL-934421', TAG_DESCR: 'Mooring Fairlead, 10 SW', DWG: 'GM004-ST-STA-241-00065-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-09-11', FAT_ACTUAL: '2019-07-02', ETA_PLAN: '2019-03-07', ETA_ACTUAL: '2019-08-19', ROS: '2019-01-16', INSTALL: '2019-08-27', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 2,  MODULE: 'NP4UH', DISC: 'ME', PKG_NO: 'MR002', BLOCK: 'N450H', DECK: null, SVG: 'NP4UH', TAG_NO: 'LFL-934422', TAG_DESCR: 'Mooring Fairlead, 11 SW', DWG: 'GM004-ST-STA-241-00065-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-09-11', FAT_ACTUAL: '2019-07-05', ETA_PLAN: '2019-03-07', ETA_ACTUAL: '2019-08-19', ROS: '2019-01-16', INSTALL: '2019-08-27', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 3,  MODULE: 'NP4UH', DISC: 'ME', PKG_NO: 'MR002', BLOCK: 'N450H', DECK: null, SVG: 'NP4UH', TAG_NO: 'LFL-934423', TAG_DESCR: 'Mooring Fairlead, 12 SW', DWG: 'GM004-ST-STA-241-00065-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-09-11', FAT_ACTUAL: '2019-07-15', ETA_PLAN: '2019-03-07', ETA_ACTUAL: '2019-09-04', ROS: '2019-01-16', INSTALL: '2019-09-09', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 4,  MODULE: 'NP4UH', DISC: 'ME', PKG_NO: 'MO002', BLOCK: 'N450H', DECK: null, SVG: 'NP4UH', TAG_NO: 'TLE-985141', TAG_DESCR: 'Rack An Pinion Type Elevator (SW)', DWG: 'GM004-PI-LAY-010-00054-001', PO_PLAN: '2017-07-14', PO_ACTUAL: '2017-07-14', FAT_PLAN: '2018-12-11', FAT_ACTUAL: '2018-12-11', ETA_PLAN: '2019-03-04', ETA_ACTUAL: '2018-12-31', ROS: '2019-01-07', INSTALL: '2019-03-19', UP_BLOCK_PE: '2019-02-25', ROS_ETA: 7, ROS_ETA_UPBLOCK: 56, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 5,  MODULE: 'NP4UH', DISC: 'AR', PKG_NO: 'MO001', BLOCK: 'N410H', DECK: null, SVG: 'NP4UH', TAG_NO: 'LDW-936041', TAG_DESCR: 'Sliding', DWG: 'GM004-PI-LAY-010-00054-001', PO_PLAN: '2017-11-03', PO_ACTUAL: '2018-01-10', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2018-10-25', ETA_ACTUAL: '2018-10-19', ROS: '2018-11-14', INSTALL: '2018-12-05', UP_BLOCK_PE: '2018-11-21', ROS_ETA: 26, ROS_ETA_UPBLOCK: 33, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 6,  MODULE: 'NP4UH', DISC: 'AR', PKG_NO: 'MO001', BLOCK: 'N410H', DECK: null, SVG: 'NP4UH', TAG_NO: 'LDW-936042', TAG_DESCR: 'Sliding', DWG: 'GM004-PI-LAY-010-00054-001', PO_PLAN: '2017-11-03', PO_ACTUAL: '2018-01-10', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2018-10-25', ETA_ACTUAL: '2018-10-19', ROS: '2018-11-14', INSTALL: '2018-12-05', UP_BLOCK_PE: '2018-11-21', ROS_ETA: 26, ROS_ETA_UPBLOCK: 33, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 7,  MODULE: 'NP4UH', DISC: 'ME', PKG_NO: 'MU010', BLOCK: 'N410H', DECK: null, SVG: 'NP4UH', TAG_NO: 'PBE-935402', TAG_DESCR: 'De-Ballast Pump - SW Quadrant', DWG: 'GM004-PI-LAY-010-00054-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-08-24', FAT_ACTUAL: '2018-11-06', ETA_PLAN: '2018-11-15', ETA_ACTUAL: '2018-11-26', ROS: '2018-12-04', INSTALL: '2018-11-29', UP_BLOCK_PE: '2018-12-07', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 8,  MODULE: 'NP4UH', DISC: 'ME', PKG_NO: 'MU010', BLOCK: 'N420H', DECK: null, SVG: 'NP4UH', TAG_NO: 'ZZZ-935405', TAG_DESCR: 'Priming Unit For De-Ballast Pump- SW Quadrant', DWG: 'GM004-PI-LAY-010-00054-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-08-24', FAT_ACTUAL: '2019-05-15', ETA_PLAN: '2018-11-15', ETA_ACTUAL: '2019-06-17', ROS: '2018-12-03', INSTALL: '2019-06-21', UP_BLOCK_PE: '2018-12-06', ROS_ETA: -196, ROS_ETA_UPBLOCK: -193, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 9,  MODULE: 'NP4UH', DISC: 'ME', PKG_NO: 'MU010', BLOCK: 'N410H', DECK: null, SVG: 'NP4UH', TAG_NO: 'ZZZ-938405', TAG_DESCR: 'Priming Unit For Main Bilge Pump- SW Quadrant', DWG: 'GM004-PI-LAY-010-00054-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-08-24', FAT_ACTUAL: '2019-06-20', ETA_PLAN: '2018-11-15', ETA_ACTUAL: '2019-06-28', ROS: '2018-12-04', INSTALL: '2019-07-02', UP_BLOCK_PE: '2018-12-07', ROS_ETA: -206, ROS_ETA_UPBLOCK: -203, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 10, MODULE: 'NP4UH', DISC: 'ME', PKG_NO: 'MU010', BLOCK: 'N410H', DECK: null, SVG: 'NP4UH', TAG_NO: 'PBE-938402', TAG_DESCR: 'Main Bilge Pump - SW Quadrant', DWG: 'GM004-PI-LAY-010-00054-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-08-24', FAT_ACTUAL: '2018-08-28', ETA_PLAN: '2018-11-15', ETA_ACTUAL: '2018-11-15', ROS: '2018-12-04', INSTALL: '2018-11-29', UP_BLOCK_PE: '2018-12-07', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 11, MODULE: 'NP4UH', DISC: 'ME', PKG_NO: 'MU018', BLOCK: 'N410H', DECK: null, SVG: 'NP4UH', TAG_NO: 'PBE-801633', TAG_DESCR: 'Fresh Water Transfer Pump', DWG: 'GM004-PI-LAY-010-00054-001', PO_PLAN: '2017-08-03', PO_ACTUAL: '2017-08-03', FAT_PLAN: '2018-08-29', FAT_ACTUAL: '2018-08-29', ETA_PLAN: '2018-11-21', ETA_ACTUAL: '2018-11-20', ROS: '2018-12-04', INSTALL: '2018-11-29', UP_BLOCK_PE: '2018-12-07', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 12, MODULE: 'NP4UH', DISC: 'ME', PKG_NO: 'MU010', BLOCK: 'N410H', DECK: null, SVG: 'NP4UH', TAG_NO: 'PBE-938401', TAG_DESCR: 'Main Bilge Pump - SW Quadrant', DWG: 'GM004-PI-LAY-010-00054-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-08-24', FAT_ACTUAL: '2018-08-28', ETA_PLAN: '2018-11-15', ETA_ACTUAL: '2018-11-15', ROS: '2018-12-04', INSTALL: '2018-11-29', UP_BLOCK_PE: '2018-12-07', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 13, MODULE: 'NP4UH', DISC: 'ME', PKG_NO: 'MU010', BLOCK: 'N420H', DECK: null, SVG: 'NP4UH', TAG_NO: 'ZZZ-938404', TAG_DESCR: 'Priming Unit For Main Bilge Pump- SW Quadrant', DWG: 'GM004-PI-LAY-010-00054-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-08-24', FAT_ACTUAL: '2019-06-20', ETA_PLAN: '2018-11-15', ETA_ACTUAL: '2019-06-28', ROS: '2018-12-03', INSTALL: '2019-07-02', UP_BLOCK_PE: '2018-12-06', ROS_ETA: -207, ROS_ETA_UPBLOCK: -204, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 14, MODULE: 'NP4UH', DISC: 'ME', PKG_NO: 'MU018', BLOCK: 'N420H', DECK: null, SVG: 'NP4UH', TAG_NO: 'PBE-801634', TAG_DESCR: 'Fresh Water Transfer Pump', DWG: 'GM004-PI-LAY-010-00054-001', PO_PLAN: '2017-08-03', PO_ACTUAL: '2017-08-03', FAT_PLAN: '2018-08-29', FAT_ACTUAL: '2018-08-29', ETA_PLAN: '2018-11-21', ETA_ACTUAL: '2018-11-20', ROS: '2018-12-03', INSTALL: '2018-11-29', UP_BLOCK_PE: '2018-12-06', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 15, MODULE: 'NP4UH', DISC: 'ME', PKG_NO: 'MU010', BLOCK: 'N440H', DECK: null, SVG: 'NP4UH', TAG_NO: 'PBE-935401', TAG_DESCR: 'De-Ballast Pump - SW Quadrant', DWG: 'GM004-PI-LAY-010-00054-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-08-24', FAT_ACTUAL: '2018-11-06', ETA_PLAN: '2018-11-15', ETA_ACTUAL: '2018-11-26', ROS: '2018-11-22', INSTALL: '2018-11-29', UP_BLOCK_PE: '2018-12-03', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 16, MODULE: 'NP4UH', DISC: 'ME', PKG_NO: 'MU010', BLOCK: 'N440H', DECK: null, SVG: 'NP4UH', TAG_NO: 'ZZZ-935404', TAG_DESCR: 'Priming Unit For De-Ballast Pump- SW Quadrant', DWG: 'GM004-PI-LAY-010-00054-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-08-24', FAT_ACTUAL: '2019-05-15', ETA_PLAN: '2018-11-15', ETA_ACTUAL: '2019-06-17', ROS: '2018-11-22', INSTALL: '2019-06-21', UP_BLOCK_PE: '2018-12-03', ROS_ETA: -207, ROS_ETA_UPBLOCK: -196, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Hull', SN: 17, MODULE: 'NP4UH', DISC: 'ME', PKG_NO: 'MU020', BLOCK: 'N410H', DECK: null, SVG: 'NP4UH', TAG_NO: 'PBE-938403', TAG_DESCR: 'Lower Hull Daily Bilge Pumps', DWG: 'GM004-PI-LAY-010-00054-001', PO_PLAN: '2017-08-02', PO_ACTUAL: '2017-08-02', FAT_PLAN: '2018-08-16', FAT_ACTUAL: '2018-09-07', ETA_PLAN: '2018-10-30', ETA_ACTUAL: '2018-12-07', ROS: '2018-12-04', INSTALL: '2018-12-27', UP_BLOCK_PE: '2018-12-07', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      
      
      { AREA: 'Deckbox', SN: 1,   MODULE: 'E10UH', DISC: 'EI', PKG_NO: 'IC008', BLOCK: 'L440H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'PNL-6010322',     TAG_DESCR: 'Pressure Safety Test Panel', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-03', ETA_ACTUAL: '2019-08-12', ROS: '2019-04-03', INSTALL: '', UP_BLOCK_PE: '2019-05-13', ROS_ETA: -131, ROS_ETA_UPBLOCK: -91, CANBE_INST: '186', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 2,   MODULE: 'E10UH', DISC: 'EI', PKG_NO: 'SE005', BLOCK: 'L440H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'PNL-6160325',     TAG_DESCR: 'Fusible Loop Charge Panel (NE)', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-08', ETA_ACTUAL: '2019-03-22', ROS: '2019-04-03', INSTALL: '', UP_BLOCK_PE: '2019-05-13', ROS_ETA: 12, ROS_ETA_UPBLOCK: 52, CANBE_INST: '329', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 3,   MODULE: 'E10UH', DISC: 'ME', PKG_NO: 'MU022', BLOCK: 'L450H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'UEH-191000',      TAG_DESCR: 'Subsea HPU (Hydraulic Power Unit) Phase 2A', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-07-28', PO_ACTUAL: '2017-07-28', FAT_PLAN: '2019-02-20', FAT_ACTUAL: '2019-06-12', ETA_PLAN: '2019-05-11', ETA_ACTUAL: '2019-06-25', ROS: '2019-04-11', INSTALL: '2019-06-25', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -75, ROS_ETA_UPBLOCK: -47, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 4,   MODULE: 'E10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'SPSE-6413-804',   TAG_DESCR: 'Safety Shower and Eyewash', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-15', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 5,   MODULE: 'E10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'SPSE-6413-805',   TAG_DESCR: 'Safety Shower and Eyewash', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-15', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 6,   MODULE: 'E10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L470H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'HCU-927682',      TAG_DESCR: 'LV Switchgear MCC Room, U2212', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-13', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-06-11', ROS: '2019-04-29', INSTALL: '2019-06-13', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '248', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 7,   MODULE: 'E10UH', DISC: 'ME', PKG_NO: 'MJ011', BLOCK: 'L470H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'PBE-938507',      TAG_DESCR: 'Bilge Water Holding Tank CIR/Transfer Pump.', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-08-17', PO_ACTUAL: '2017-08-17', FAT_PLAN: '2019-01-18', FAT_ACTUAL: '2019-02-12', ETA_PLAN: '2019-03-29', ETA_ACTUAL: '2019-06-19', ROS: '2019-04-10', INSTALL: '2019-06-25', UP_BLOCK_PE: '2019-05-02', ROS_ETA: -70, ROS_ETA_UPBLOCK: -48, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 8,   MODULE: 'E10UH', DISC: 'ME', PKG_NO: 'MU020', BLOCK: 'Loose',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'PBE-938506',      TAG_DESCR: 'Portable Bilge Pumps', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-08-02', PO_ACTUAL: '2017-08-02', FAT_PLAN: '2018-08-16', FAT_ACTUAL: '2018-09-07', ETA_PLAN: '2018-10-30', ETA_ACTUAL: '2018-10-30', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '472', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 9,   MODULE: 'E10UH', DISC: 'ME', PKG_NO: 'MU009', BLOCK: 'L470H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'ZZZ-681050',      TAG_DESCR: 'ESS. Generator East Skid', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-01-26', PO_ACTUAL: '2017-01-26', FAT_PLAN: '2018-12-10', FAT_ACTUAL: '2018-12-10', ETA_PLAN: '2019-03-28', ETA_ACTUAL: '2019-04-26', ROS: '2019-04-10', INSTALL: '2019-05-03', UP_BLOCK_PE: '2019-05-02', ROS_ETA: -16, ROS_ETA_UPBLOCK: 6, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 10,  MODULE: 'E10UH', DISC: 'ME', PKG_NO: 'MU009', BLOCK: 'L470H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'ABJ-681060',      TAG_DESCR: 'ESS. Generator East Lube Oil Tank', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-01-26', PO_ACTUAL: '2017-01-26', FAT_PLAN: '2018-12-10', FAT_ACTUAL: '2018-12-10', ETA_PLAN: '2019-03-28', ETA_ACTUAL: '2019-04-26', ROS: '2019-04-10', INSTALL: '2019-05-23', UP_BLOCK_PE: '2019-05-02', ROS_ETA: -16, ROS_ETA_UPBLOCK: 6, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 11,  MODULE: 'E10UH', DISC: 'ME', PKG_NO: 'MU009', BLOCK: 'L470H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'ZZZ-681070',      TAG_DESCR: 'DEF Dosing Cabinet', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-01-26', PO_ACTUAL: '2017-01-26', FAT_PLAN: '2018-12-10', FAT_ACTUAL: '2018-12-10', ETA_PLAN: '2019-03-28', ETA_ACTUAL: '2019-04-26', ROS: '2019-04-10', INSTALL: '2019-05-17', UP_BLOCK_PE: '2019-05-02', ROS_ETA: -16, ROS_ETA_UPBLOCK: 6, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 12,  MODULE: 'E10UH', DISC: 'ME', PKG_NO: 'MU009', BLOCK: 'L470H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'ZZZ-681080',      TAG_DESCR: 'SCR Unit', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-01-26', PO_ACTUAL: '2017-01-26', FAT_PLAN: '2018-12-10', FAT_ACTUAL: '2018-12-10', ETA_PLAN: '2019-03-28', ETA_ACTUAL: '2019-04-26', ROS: '2019-04-10', INSTALL: '2019-06-18', UP_BLOCK_PE: '2019-05-02', ROS_ETA: -16, ROS_ETA_UPBLOCK: 6, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 13,  MODULE: 'E10UH', DISC: 'EI', PKG_NO: 'EL002', BLOCK: 'L350H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'VSGE-611104',     TAG_DESCR: '5KV Dedicated Firewater Pump Switchgear - Hull', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-10', ETA_ACTUAL: '2019-04-03', ROS: '2019-04-19', INSTALL: '2019-06-14', UP_BLOCK_PE: '2019-05-03', ROS_ETA: 16, ROS_ETA_UPBLOCK: 30, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 14,  MODULE: 'E10UH', DISC: 'ME', PKG_NO: 'MU020', BLOCK: 'L470H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'PBE-938502',      TAG_DESCR: 'Upper Hull Daily Bilge Pumps', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-08-02', PO_ACTUAL: '2017-08-02', FAT_PLAN: '2018-08-16', FAT_ACTUAL: '2018-09-07', ETA_PLAN: '2018-10-30', ETA_ACTUAL: '2018-10-30', ROS: '2019-04-10', INSTALL: '2019-04-15', UP_BLOCK_PE: '2019-05-02', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 15,  MODULE: 'E10UH', DISC: 'EI', PKG_NO: 'EL007', BLOCK: 'L350H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'VMCS-682051',     TAG_DESCR: '480V Motor Control Center Essential MCC1A', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-10', ETA_ACTUAL: '2019-05-03', ROS: '2019-04-19', INSTALL: '', UP_BLOCK_PE: '2019-05-03', ROS_ETA: -14, ROS_ETA_UPBLOCK: 0, CANBE_INST: '287', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 16,  MODULE: 'E10UH', DISC: 'ME', PKG_NO: 'MC005', BLOCK: 'L470H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'VHP-2120020',     TAG_DESCR: 'SCR For Wet Oil/Well Flowback Tank Heater', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-09-21', PO_ACTUAL: '2017-09-21', FAT_PLAN: '2018-09-26', FAT_ACTUAL: '2019-01-07', ETA_PLAN: '2019-04-01', ETA_ACTUAL: '2019-05-06', ROS: '2019-04-10', INSTALL: '2019-06-25', UP_BLOCK_PE: '2019-05-02', ROS_ETA: -26, ROS_ETA_UPBLOCK: -4, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 17,  MODULE: 'E10UH', DISC: 'EI', PKG_NO: 'EL002', BLOCK: 'L350H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'VSGS-682051',     TAG_DESCR: '480V Essential Switchgear - Hull', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-10', ETA_ACTUAL: '2019-04-03', ROS: '2019-04-19', INSTALL: '', UP_BLOCK_PE: '2019-05-03', ROS_ETA: 16, ROS_ETA_UPBLOCK: 30, CANBE_INST: '317', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 18,  MODULE: 'E10UH', DISC: 'ME', PKG_NO: 'MC005', BLOCK: 'L470H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'VHP-4340070',     TAG_DESCR: 'SCR For Closed Drain Drum Immersion Heater', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-09-21', PO_ACTUAL: '2017-09-21', FAT_PLAN: '2018-09-26', FAT_ACTUAL: '2019-01-07', ETA_PLAN: '2019-04-01', ETA_ACTUAL: '2019-05-06', ROS: '2019-04-10', INSTALL: '2019-06-25', UP_BLOCK_PE: '2019-05-02', ROS_ETA: -26, ROS_ETA_UPBLOCK: -4, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 19,  MODULE: 'E10UH', DISC: 'EI', PKG_NO: 'EL007', BLOCK: 'L350H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'VMCN-874051',     TAG_DESCR: '480V Motor Control Center Normal MCC1A', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-10', ETA_ACTUAL: '2019-05-03', ROS: '2019-04-19', INSTALL: '', UP_BLOCK_PE: '2019-05-03', ROS_ETA: -14, ROS_ETA_UPBLOCK: 0, CANBE_INST: '287', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 20,  MODULE: 'E10UH', DISC: 'EI', PKG_NO: 'EL002', BLOCK: 'L350H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'VSGN-874051',     TAG_DESCR: '480V Normal Switchgear - Hull', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-10', ETA_ACTUAL: '2019-04-03', ROS: '2019-04-19', INSTALL: '', UP_BLOCK_PE: '2019-05-03', ROS_ETA: 16, ROS_ETA_UPBLOCK: 30, CANBE_INST: '317', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 21,  MODULE: 'E10UH', DISC: 'EI', PKG_NO: 'EL007', BLOCK: 'L350H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'VMCN-874054',     TAG_DESCR: '480V Motor Control Center Normal MCC1B', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-10', ETA_ACTUAL: '2019-05-03', ROS: '2019-04-19', INSTALL: '', UP_BLOCK_PE: '2019-05-03', ROS_ETA: -14, ROS_ETA_UPBLOCK: 0, CANBE_INST: '287', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 22,  MODULE: 'E10UH', DISC: 'ME', PKG_NO: 'ML008', BLOCK: 'L460H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'ZZZ-830000',      TAG_DESCR: 'Subsea Chemical Injection Skid', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-09-11', FAT_ACTUAL: '2018-11-06', ETA_PLAN: '2019-04-01', ETA_ACTUAL: '2019-04-11', ROS: '2019-04-12', INSTALL: '2019-05-22', UP_BLOCK_PE: '2019-05-09', ROS_ETA: 1, ROS_ETA_UPBLOCK: 28, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 23,  MODULE: 'E10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'SPHR-6130-811',   TAG_DESCR: 'SPHR-6130 Water Only Hose Reel', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 24,  MODULE: 'E10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'XHHE-6130-3211',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 25,  MODULE: 'E10UH', DISC: 'EI', PKG_NO: 'EL002', BLOCK: 'L350H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'VHRE-611104',     TAG_DESCR: 'Dedicated Firewater Pump Generator High Resistance Ground', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-10', ETA_ACTUAL: '2019-04-03', ROS: '2019-04-19', INSTALL: '2019-06-14', UP_BLOCK_PE: '2019-05-03', ROS_ETA: 16, ROS_ETA_UPBLOCK: 30, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 26,  MODULE: 'E10UH', DISC: 'ME', PKG_NO: 'MU009', BLOCK: 'L470H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'ZZZ-611100',      TAG_DESCR: 'Fire Pump Diesel Generator', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-01-26', PO_ACTUAL: '2017-01-26', FAT_PLAN: '2018-12-10', FAT_ACTUAL: '2019-02-18', ETA_PLAN: '2019-03-28', ETA_ACTUAL: '2019-05-10', ROS: '2019-04-10', INSTALL: '2019-05-17', UP_BLOCK_PE: '2019-05-02', ROS_ETA: -30, ROS_ETA_UPBLOCK: -8, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 27,  MODULE: 'E10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'XHHE-6130-8140',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 28,  MODULE: 'E10UH', DISC: 'EI', PKG_NO: 'EL003', BLOCK: 'L350H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'VTE-611104',      TAG_DESCR: '4.16KV-480V Power Transformer Dedicated Firewater Pump Auxiliary 150KVA', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-17', ETA_ACTUAL: '2019-04-03', ROS: '2019-04-19', INSTALL: '2019-05-23', UP_BLOCK_PE: '2019-05-03', ROS_ETA: 16, ROS_ETA_UPBLOCK: 30, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 29,  MODULE: 'E10UH', DISC: 'EI', PKG_NO: 'EL007', BLOCK: 'L350H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'VMCE-611104',     TAG_DESCR: '480V Motor Control Center - Dedicated Primary Fire Water Pump', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-10', ETA_ACTUAL: '2019-05-03', ROS: '2019-04-19', INSTALL: '2019-06-14', UP_BLOCK_PE: '2019-05-03', ROS_ETA: -14, ROS_ETA_UPBLOCK: 0, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 30,  MODULE: 'E10UH', DISC: 'EI', PKG_NO: 'EL003', BLOCK: 'L340H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'VTS-682051B',     TAG_DESCR: '4.16KV-480V Power Transformer Essential 2500/3333 KVA (AN/AF)', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-17', ETA_ACTUAL: '2019-04-03', ROS: '2019-04-22', INSTALL: '2019-05-23', UP_BLOCK_PE: '2019-05-10', ROS_ETA: 19, ROS_ETA_UPBLOCK: 37, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 31,  MODULE: 'E10UH', DISC: 'EI', PKG_NO: 'EL003', BLOCK: 'L340H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'VTS-682051A',     TAG_DESCR: '4.16KV-480V Power Transformer Essential 2500/3333 KVA (AN/AF)', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-17', ETA_ACTUAL: '2019-04-03', ROS: '2019-04-22', INSTALL: '2019-05-23', UP_BLOCK_PE: '2019-05-10', ROS_ETA: 19, ROS_ETA_UPBLOCK: 37, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 32,  MODULE: 'E10UH', DISC: 'EI', PKG_NO: 'EL003', BLOCK: 'L340H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'VTN-876051B',     TAG_DESCR: '13.8KV-4.16KV Power Transformer 12000KVA (AFWF)', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-17', ETA_ACTUAL: '2019-04-03', ROS: '2019-04-22', INSTALL: '2019-05-23', UP_BLOCK_PE: '2019-05-10', ROS_ETA: 19, ROS_ETA_UPBLOCK: 37, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 33,  MODULE: 'E10UH', DISC: 'EI', PKG_NO: 'EL003', BLOCK: 'L340H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'VTN-876051A',     TAG_DESCR: '13.8KV-4.16KV Power Transformer 12000KVA (AFWF)', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-17', ETA_ACTUAL: '2019-04-03', ROS: '2019-04-22', INSTALL: '2019-05-23', UP_BLOCK_PE: '2019-05-10', ROS_ETA: 19, ROS_ETA_UPBLOCK: 37, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 34,  MODULE: 'E10UH', DISC: 'EI', PKG_NO: 'EL003', BLOCK: 'L340H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'VTN-874051A',     TAG_DESCR: '4.16KV-480V Power Transformer Normal 2500/3333 KVA (AN/AF)', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-17', ETA_ACTUAL: '2019-04-03', ROS: '2019-04-22', INSTALL: '2019-05-23', UP_BLOCK_PE: '2019-05-10', ROS_ETA: 19, ROS_ETA_UPBLOCK: 37, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 35,  MODULE: 'E10UH', DISC: 'EI', PKG_NO: 'EL003', BLOCK: 'L340H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'VTN-874051B',     TAG_DESCR: '4.16KV-480V Power Transformer Normal 2500/3333 KVA (AN/AF)', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-17', ETA_ACTUAL: '2019-04-03', ROS: '2019-04-22', INSTALL: '2019-05-23', UP_BLOCK_PE: '2019-05-10', ROS_ETA: 19, ROS_ETA_UPBLOCK: 37, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 36,  MODULE: 'E10UH', DISC: 'EI', PKG_NO: 'EL003', BLOCK: 'L340H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'VTE-674051',      TAG_DESCR: '4.16KV-480V Power Transformer Emergency 2500/2667 KVA (AN/AF)', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-17', ETA_ACTUAL: '2019-04-03', ROS: '2019-04-22', INSTALL: '2019-05-23', UP_BLOCK_PE: '2019-05-10', ROS_ETA: 19, ROS_ETA_UPBLOCK: 37, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 37,  MODULE: 'E10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'XHHE-6130-3282',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 38,  MODULE: 'E10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L340H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'HCU-927180',      TAG_DESCR: 'LV MCC Room, U3213', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-29', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-06-26', ROS: '2019-04-16', INSTALL: '2019-07-25', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '233', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 39,  MODULE: 'E10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L340H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'HCU-927181',      TAG_DESCR: 'LV MCC Room, U3213', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-29', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-06-26', ROS: '2019-04-16', INSTALL: '2019-07-25', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '233', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 40,  MODULE: 'E10UH', DISC: 'ME', PKG_NO: 'MU001', BLOCK: 'L340H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'MAJ-806002',      TAG_DESCR: 'Diesel Coarse Filter', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '2017-09-28', PO_ACTUAL: '2017-09-28', FAT_PLAN: '2019-01-10', FAT_ACTUAL: '2019-03-19', ETA_PLAN: '2019-04-04', ETA_ACTUAL: '2019-04-23', ROS: '2019-04-29', INSTALL: '2019-05-03', UP_BLOCK_PE: '2019-05-10', ROS_ETA: 6, ROS_ETA_UPBLOCK: 17, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 41,  MODULE: 'E10UH', DISC: 'ME', PKG_NO: 'MU004', BLOCK: 'L350H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'MAJ-806042',      TAG_DESCR: 'Diesel Coalescing Filter', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '2017-09-15', PO_ACTUAL: '2017-09-15', FAT_PLAN: '2019-03-08', FAT_ACTUAL: '2019-04-12', ETA_PLAN: '2019-04-19', ETA_ACTUAL: '2019-05-02', ROS: '2019-04-26', INSTALL: '2019-05-23', UP_BLOCK_PE: '2019-05-03', ROS_ETA: -6, ROS_ETA_UPBLOCK: 1, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 42,  MODULE: 'E10UH', DISC: 'ME', PKG_NO: 'MU004', BLOCK: 'L350H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'MAJ-806052',      TAG_DESCR: 'Diesel Coalescing Filter', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '2017-09-15', PO_ACTUAL: '2017-09-15', FAT_PLAN: '2019-03-08', FAT_ACTUAL: '2019-04-12', ETA_PLAN: '2019-04-19', ETA_ACTUAL: '2019-05-02', ROS: '2019-04-26', INSTALL: '2019-05-23', UP_BLOCK_PE: '2019-05-03', ROS_ETA: -6, ROS_ETA_UPBLOCK: 1, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 43,  MODULE: 'E10UH', DISC: 'ME', PKG_NO: 'MU003', BLOCK: 'L350H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'MAJ-802305',      TAG_DESCR: 'Seawater Strainer (WI)', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '2017-08-04', PO_ACTUAL: '2017-08-04', FAT_PLAN: '2018-11-16', FAT_ACTUAL: '2018-12-03', ETA_PLAN: '2019-03-28', ETA_ACTUAL: '2019-04-15', ROS: '2019-04-26', INSTALL: '2019-05-02', UP_BLOCK_PE: '2019-05-03', ROS_ETA: 11, ROS_ETA_UPBLOCK: 18, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 44,  MODULE: 'E10UH', DISC: 'ME', PKG_NO: 'MU003', BLOCK: 'L350H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'MAJ-802304',      TAG_DESCR: 'Seawater Strainer (WI)', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '2017-08-04', PO_ACTUAL: '2017-08-04', FAT_PLAN: '2018-11-16', FAT_ACTUAL: '2018-12-03', ETA_PLAN: '2019-03-28', ETA_ACTUAL: '2019-04-15', ROS: '2019-04-26', INSTALL: '2019-05-02', UP_BLOCK_PE: '2019-05-03', ROS_ETA: 11, ROS_ETA_UPBLOCK: 18, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 45,  MODULE: 'E10UH', DISC: 'ME', PKG_NO: 'MU020', BLOCK: 'L350H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'PBE-938503',      TAG_DESCR: 'Upper Hull Daily Bilge Pumps', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '2017-08-02', PO_ACTUAL: '2017-08-02', FAT_PLAN: '2018-08-16', FAT_ACTUAL: '2018-09-07', ETA_PLAN: '2018-10-30', ETA_ACTUAL: '2018-10-30', ROS: '2019-04-26', INSTALL: '2019-04-19', UP_BLOCK_PE: '2019-05-03', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 46,  MODULE: 'E10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'SPHR-6130-806',   TAG_DESCR: 'SPHR-6130 Water Only Hose Reel', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 47,  MODULE: 'E10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'XHHE-6130-8150',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 48,  MODULE: 'E10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'SPHR-8012495',    TAG_DESCR: 'SPHR-6130 Water Only Hose Reel', DWG: null, PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 49,  MODULE: 'E10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'SPHR-9110264',    TAG_DESCR: 'SPHR-6130 Water Only Hose Reel', DWG: null, PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 50,  MODULE: 'E10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'M450H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'HSF-992510',      TAG_DESCR: 'Supply Fan A Essential Generator East', DWG: 'GM004-PI-LAY-241-30005-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-08-16', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-10-18', ROS: '2019-07-09', INSTALL: '2019-12-04', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '119', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 51,  MODULE: 'E10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'M450H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'HSF-992515',      TAG_DESCR: 'Supply Fan B Essential Generator East', DWG: 'GM004-PI-LAY-241-30005-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-08-16', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-10-18', ROS: '2019-07-09', INSTALL: '2019-12-03', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '119', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 52,  MODULE: 'E10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'M450H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'HEF-992755',      TAG_DESCR: 'Extract Fan B Fire Water Pump Generator', DWG: 'GM004-PI-LAY-241-30005-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2020-01-07', ROS: '2019-07-09', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '38', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 53,  MODULE: 'E10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'M450H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'HEF-992750',      TAG_DESCR: 'Extract Fan A Fire Water Pump Generator', DWG: 'GM004-PI-LAY-241-30005-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2020-01-07', ROS: '2019-07-09', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '38', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 54,  MODULE: 'E10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'M350H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'HSF-992710',      TAG_DESCR: 'Supply Fan A Fire Water Pump Generator', DWG: 'GM004-PI-LAY-241-30007-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2020-01-27', ROS: '2019-07-09', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '18', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 55,  MODULE: 'E10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'M350H',         DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'HSF-992720',      TAG_DESCR: 'Supply Fan B Fire Water Pump Generator', DWG: 'GM004-PI-LAY-241-30007-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2020-01-27', ROS: '2019-07-09', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '18', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 56,  MODULE: 'E10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'E10UH_L1', TAG_NO: 'US-310-9',        TAG_DESCR: 'Utility Station', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-10-30', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 1,   MODULE: 'E10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'E10UH_L2', TAG_NO: 'SPSE-6414-605',   TAG_DESCR: 'Safety Shower and Eyewash', DWG: 'GM004-PI-LAY-241-50002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-15', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 2,   MODULE: 'E10UH', DISC: 'ME', PKG_NO: 'ML008', BLOCK: 'M430H',         DECK: 'MAIN DECK',    SVG: 'E10UH_L2', TAG_NO: 'ZZZ-800000',      TAG_DESCR: 'Topsides Chemical Injection Skid', DWG: 'GM004-PI-LAY-241-50002-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-09-11', FAT_ACTUAL: '2019-03-18', ETA_PLAN: '2019-04-01', ETA_ACTUAL: '2019-09-11', ROS: '2019-07-30', INSTALL: '2019-09-29', UP_BLOCK_PE: '', ROS_ETA: -43, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 3,   MODULE: 'E10UH', DISC: 'EI', PKG_NO: 'IC008', BLOCK: 'M440H',         DECK: 'MAIN DECK',    SVG: 'E10UH_L2', TAG_NO: 'PNL-6010323',     TAG_DESCR: 'Pressure Safety Test Panel', DWG: 'GM004-PI-LAY-241-50001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-03', ETA_ACTUAL: '2019-08-12', ROS: '2019-05-13', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: -91, ROS_ETA_UPBLOCK: null, CANBE_INST: '186', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 4,   MODULE: 'E10UH', DISC: 'EI', PKG_NO: 'IC008', BLOCK: 'M440H',         DECK: 'MAIN DECK',    SVG: 'E10UH_L2', TAG_NO: 'PNL-6010324',     TAG_DESCR: 'Pressure Safety Test Panel', DWG: 'GM004-PI-LAY-241-50001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-03', ETA_ACTUAL: '2019-08-12', ROS: '2019-05-13', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: -91, ROS_ETA_UPBLOCK: null, CANBE_INST: '186', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 5,   MODULE: 'E10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'E10UH_L2', TAG_NO: 'SPSE-6414-603',   TAG_DESCR: 'Safety Shower and Eyewash', DWG: 'GM004-PI-LAY-241-50001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-15', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 6,   MODULE: 'E10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'M440H',         DECK: 'MAIN DECK',    SVG: 'E10UH_L2', TAG_NO: 'HEF-992455',      TAG_DESCR: 'Extract Fan Air Lock,  U2214', DWG: 'GM004-PI-LAY-241-50001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-07-22', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-08-26', ROS: '2019-07-09', INSTALL: '2019-12-10', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '172', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 7,   MODULE: 'E10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'E10UH_L2', TAG_NO: 'US-510-4',        TAG_DESCR: 'Utility Station', DWG: 'GM004-PI-LAY-241-50001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-10-30', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 8,   MODULE: 'E10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'E10UH_L2', TAG_NO: 'SPHR-8012438',    TAG_DESCR: 'Water Only Hose Reel', DWG: null, PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 9,   MODULE: 'E10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'E10UH_L2', TAG_NO: 'SPHR-8110207',    TAG_DESCR: 'Water Only Hose Reel', DWG: null, PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 10,  MODULE: 'E10UH', DISC: 'ME', PKG_NO: 'ML001', BLOCK: 'M340H',         DECK: 'MAIN DECK',    SVG: 'E10UH_L2', TAG_NO: 'ZZZ-465010',      TAG_DESCR: 'MF CIP Skid', DWG: 'GM004-PI-LAY-241-50001-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2019-03-27', FAT_ACTUAL: '2019-07-13', ETA_PLAN: '2019-05-08', ETA_ACTUAL: '2019-07-26', ROS: '2019-05-17', INSTALL: '2019-08-02', UP_BLOCK_PE: '2019-12-09', ROS_ETA: -70, ROS_ETA_UPBLOCK: 136, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 11,  MODULE: 'E10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'M340H',         DECK: 'MAIN DECK',    SVG: 'E10UH_L2', TAG_NO: 'HCN-927686',      TAG_DESCR: 'SWGR/MCC Room, U4214', DWG: 'GM004-PI-LAY-241-50003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-06-12', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-07-03', ROS: '2019-05-21', INSTALL: '2019-11-05', UP_BLOCK_PE: '', ROS_ETA: -43, ROS_ETA_UPBLOCK: null, CANBE_INST: '226', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 12,  MODULE: 'E10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'M340H',         DECK: 'MAIN DECK',    SVG: 'E10UH_L2', TAG_NO: 'HCN-927392',      TAG_DESCR: 'UPS, U3215', DWG: 'GM004-PI-LAY-241-50003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-06-21', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-06-11', ROS: '2019-05-21', INSTALL: '2020-01-22', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '248', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 13,  MODULE: 'E10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'E10UH_L2', TAG_NO: 'US-510-8',        TAG_DESCR: 'Utility Station', DWG: 'GM004-PI-LAY-241-50003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-10-30', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 14,  MODULE: 'E10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'E10UH_L2', TAG_NO: 'SPHR-8012442',    TAG_DESCR: 'Water Only Hose Reel', DWG: null, PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 15,  MODULE: 'E10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'E10UH_L2', TAG_NO: 'SPHR-8110211',    TAG_DESCR: 'Water Only Hose Reel', DWG: null, PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 16,  MODULE: 'E10UH', DISC: 'ME', PKG_NO: 'MU009', BLOCK: 'M920H',         DECK: 'MAIN DECK',    SVG: 'E10UH_L2', TAG_NO: 'HCA-681056',      TAG_DESCR: 'Essential Diesel Gnerator Remote Radiator', DWG: 'GM004-PI-LAY-241-50003-001', PO_PLAN: '2017-01-26', PO_ACTUAL: '2017-01-26', FAT_PLAN: '2018-12-10', FAT_ACTUAL: '2018-12-10', ETA_PLAN: '2019-03-28', ETA_ACTUAL: '2019-04-26', ROS: '2019-05-20', INSTALL: '2019-07-02', UP_BLOCK_PE: '', ROS_ETA: 24, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 17,  MODULE: 'E10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'E10UH_L2', TAG_NO: 'SPSE-6414-602',   TAG_DESCR: 'Safety Shower and Eyewash', DWG: 'GM004-PI-LAY-241-50003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-15', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 18,  MODULE: 'E10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'E10UH_L2', TAG_NO: 'SPHR-6130-614',   TAG_DESCR: 'Water Only Hose Reel', DWG: 'GM004-PI-LAY-241-50003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 19,  MODULE: 'E10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'M340H',         DECK: 'MAIN DECK',    SVG: 'E10UH_L2', TAG_NO: 'HEF-992850',      TAG_DESCR: 'Extract Fan A Paint Locker', DWG: 'GM004-PI-LAY-241-50003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-11-29', ROS: '2019-05-21', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '77', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 20,  MODULE: 'E10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'M340H',         DECK: 'MAIN DECK',    SVG: 'E10UH_L2', TAG_NO: 'HEF-992855',      TAG_DESCR: 'Extract Fan B Paint Locker', DWG: 'GM004-PI-LAY-241-50003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-11-29', ROS: '2019-05-21', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '77', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 1,   MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MU009', BLOCK: 'L210H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'HCA-681016',      TAG_DESCR: 'Essential Diesel Gnerator Remote Radiator', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '2017-01-26', PO_ACTUAL: '2017-01-26', FAT_PLAN: '2018-12-10', FAT_ACTUAL: '2019-01-08', ETA_PLAN: '2019-03-28', ETA_ACTUAL: '2019-04-26', ROS: '2019-04-30', INSTALL: '2019-08-12', UP_BLOCK_PE: '2019-05-22', ROS_ETA: 4, ROS_ETA_UPBLOCK: 26, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 2,   MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MJ013', BLOCK: 'L210H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'ZZZ-812000',      TAG_DESCR: 'Air Dryer and Filtration Skid', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '2017-01-13', PO_ACTUAL: '2017-01-13', FAT_PLAN: '2019-01-23', FAT_ACTUAL: '2019-07-29', ETA_PLAN: '2019-03-27', ETA_ACTUAL: '2019-09-10', ROS: '2019-04-30', INSTALL: '2019-10-19', UP_BLOCK_PE: '2019-05-22', ROS_ETA: -133, ROS_ETA_UPBLOCK: -111, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 3,   MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MJ011', BLOCK: 'L210H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'PBE-612001',      TAG_DESCR: 'Firewater Jockey Pump', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '2017-08-17', PO_ACTUAL: '2017-08-17', FAT_PLAN: '2019-01-18', FAT_ACTUAL: '2019-02-12', ETA_PLAN: '2019-03-29', ETA_ACTUAL: '2019-07-25', ROS: '2019-04-30', INSTALL: '2019-08-14', UP_BLOCK_PE: '2019-05-22', ROS_ETA: -86, ROS_ETA_UPBLOCK: -64, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 4,   MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MJ011', BLOCK: 'L210H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'PBE-612002',      TAG_DESCR: 'Firewater Jockey Pump', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '2017-08-17', PO_ACTUAL: '2017-08-17', FAT_PLAN: '2019-01-18', FAT_ACTUAL: '2019-02-12', ETA_PLAN: '2019-03-29', ETA_ACTUAL: '2019-07-25', ROS: '2019-04-30', INSTALL: '2019-08-14', UP_BLOCK_PE: '2019-05-22', ROS_ETA: -86, ROS_ETA_UPBLOCK: -64, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 5,   MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'SPHR-6130-810',   TAG_DESCR: 'SPHR-6130 Water Only Hose Reel', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 6,   MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L210H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'HEF-927450',      TAG_DESCR: 'Extract Fan SW. Cellar & Tween Deck', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-29', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-07-15', ROS: '2019-05-01', INSTALL: '2019-07-29', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '214', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 7,   MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MU014', BLOCK: 'L210H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'ZZZ-986100',      TAG_DESCR: 'Black Water Vacuum Unit', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '2017-08-25', PO_ACTUAL: '2017-08-25', FAT_PLAN: '2019-02-18', FAT_ACTUAL: '2019-05-29', ETA_PLAN: '2019-04-22', ETA_ACTUAL: '2019-06-10', ROS: '2019-04-30', INSTALL: '2019-06-18', UP_BLOCK_PE: '2019-05-15', ROS_ETA: -41, ROS_ETA_UPBLOCK: -26, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 8,   MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MJ007', BLOCK: 'L210H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'PBE-935502',      TAG_DESCR: 'Hull Ballast/Utility Lift Pump', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-08', FAT_ACTUAL: '2018-11-08', ETA_PLAN: '2019-07-02', ETA_ACTUAL: '2019-08-09', ROS: '2019-07-25', INSTALL: '2019-12-20', UP_BLOCK_PE: '2019-07-30', ROS_ETA: -15, ROS_ETA_UPBLOCK: -10, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 9,   MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MU014', BLOCK: 'L110H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'ZZZ-986150',      TAG_DESCR: 'Sewage Treatment Unit', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '2017-08-25', PO_ACTUAL: '2017-08-25', FAT_PLAN: '2019-02-18', FAT_ACTUAL: '2019-05-29', ETA_PLAN: '2019-04-22', ETA_ACTUAL: '2019-06-10', ROS: '2019-04-29', INSTALL: '2019-06-18', UP_BLOCK_PE: '2019-05-14', ROS_ETA: -42, ROS_ETA_UPBLOCK: -27, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 10,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MU020', BLOCK: 'L110H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'PBE-938509',      TAG_DESCR: 'Portable Bilge Pumps', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '2017-08-02', PO_ACTUAL: '2017-08-02', FAT_PLAN: '2018-08-16', FAT_ACTUAL: '2018-09-07', ETA_PLAN: '2018-10-30', ETA_ACTUAL: '2018-10-30', ROS: '2019-04-29', INSTALL: '2019-05-30', UP_BLOCK_PE: '2019-05-14', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 11,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'SPSE-6413-808',   TAG_DESCR: 'Safety Shower and Eyewash', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-15', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 12,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MU016', BLOCK: 'L110H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'ZZZ-991410',      TAG_DESCR: 'HPU Skid', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '2017-07-28', PO_ACTUAL: '2017-07-28', FAT_PLAN: '2019-02-15', FAT_ACTUAL: '2019-03-02', ETA_PLAN: '2019-04-30', ETA_ACTUAL: '2019-05-16', ROS: '2019-04-29', INSTALL: '2019-05-22', UP_BLOCK_PE: '2019-05-14', ROS_ETA: -17, ROS_ETA_UPBLOCK: -2, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 13,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'SPHR-6130-817',   TAG_DESCR: 'SPHR-6130 Water Only Hose Reel', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 14,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'XHHE-6130-2044',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 15,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MU016', BLOCK: 'L110H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'ZZZ-991470',      TAG_DESCR: 'Solenoid Cabinet', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '2017-07-28', PO_ACTUAL: '2017-07-28', FAT_PLAN: '2019-02-15', FAT_ACTUAL: '2019-03-02', ETA_PLAN: '2019-04-30', ETA_ACTUAL: '2019-05-16', ROS: '2019-04-29', INSTALL: '2019-05-22', UP_BLOCK_PE: '2019-05-14', ROS_ETA: -17, ROS_ETA_UPBLOCK: -2, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 16,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MU016', BLOCK: 'L110H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'ZZZ-991475',      TAG_DESCR: 'Solenoid Cabinet', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '2017-07-28', PO_ACTUAL: '2017-07-28', FAT_PLAN: '2019-02-15', FAT_ACTUAL: '2019-03-02', ETA_PLAN: '2019-04-30', ETA_ACTUAL: '2019-05-16', ROS: '2019-04-29', INSTALL: '2019-05-22', UP_BLOCK_PE: '2019-05-14', ROS_ETA: -17, ROS_ETA_UPBLOCK: -2, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 17,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'SPHR-6130-809',   TAG_DESCR: 'SPHR-6130 Water Only Hose Reel', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 18,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MU016', BLOCK: 'L110H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'ZZZ-991490',      TAG_DESCR: 'Portable Hydraulic Pump Unit', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '2017-07-28', PO_ACTUAL: '2017-07-28', FAT_PLAN: '2019-02-15', FAT_ACTUAL: '2019-03-02', ETA_PLAN: '2019-04-30', ETA_ACTUAL: '2019-05-16', ROS: '2019-04-29', INSTALL: '2019-05-21', UP_BLOCK_PE: '2019-05-14', ROS_ETA: -17, ROS_ETA_UPBLOCK: -2, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 19,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'XHHE-6130-3512',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 20,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L210H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'HAU-927410',      TAG_DESCR: 'Supply Southwest Area', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-18', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-12-27', ROS: '2019-05-01', INSTALL: '2019-06-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '49', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 21,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L110H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'HCU-992680',      TAG_DESCR: 'HVAC, L4204', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-06-25', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-08-14', ROS: '2019-07-05', INSTALL: '2019-08-16', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '184', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 22,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MU006', BLOCK: 'S10UH',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'TCN-433008',      TAG_DESCR: 'SW Disposal Caisson', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '2017-07-24', PO_ACTUAL: '2017-07-24', FAT_PLAN: '2019-04-23', FAT_ACTUAL: '2018-12-10', ETA_PLAN: '2019-06-11', ETA_ACTUAL: '2019-05-23', ROS: '2019-07-04', INSTALL: '2019-08-19', UP_BLOCK_PE: '', ROS_ETA: 42, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 23,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MU006', BLOCK: 'Caisson',       DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'PBE-433024',      TAG_DESCR: 'SW Disposal Caisson Bucket Pump - Sump Area', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '2017-07-24', PO_ACTUAL: '2017-07-24', FAT_PLAN: '2019-04-23', FAT_ACTUAL: '2018-12-10', ETA_PLAN: '2019-06-11', ETA_ACTUAL: '2019-07-25', ROS: '2019-07-25', INSTALL: '2019-11-18', UP_BLOCK_PE: '', ROS_ETA: 0, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 24,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MU006', BLOCK: 'Caisson',       DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'PBE-433025',      TAG_DESCR: 'SW Disposal Caisson Bucket Pump - Sump Area', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '2017-07-24', PO_ACTUAL: '2017-07-24', FAT_PLAN: '2019-04-23', FAT_ACTUAL: '2018-12-10', ETA_PLAN: '2019-06-11', ETA_ACTUAL: '2019-07-25', ROS: '2019-07-25', INSTALL: '2019-11-18', UP_BLOCK_PE: '', ROS_ETA: 0, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 25,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MU006', BLOCK: 'Caisson',       DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'PBE-433034',      TAG_DESCR: 'SW Disposal Caisson Bucket Pump - Quiet Chamber', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '2017-07-24', PO_ACTUAL: '2017-07-24', FAT_PLAN: '2019-04-23', FAT_ACTUAL: '2018-12-10', ETA_PLAN: '2019-06-11', ETA_ACTUAL: '2019-07-25', ROS: '2019-07-25', INSTALL: '2019-11-20', UP_BLOCK_PE: '', ROS_ETA: 0, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 26,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MU006', BLOCK: 'Caisson',       DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'PBE-433035',      TAG_DESCR: 'SW Disposal Bucket Pump', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '2017-07-24', PO_ACTUAL: '2017-07-24', FAT_PLAN: '2019-04-23', FAT_ACTUAL: '2018-12-10', ETA_PLAN: '2019-06-11', ETA_ACTUAL: '2019-07-25', ROS: '2019-07-25', INSTALL: '2019-11-20', UP_BLOCK_PE: '', ROS_ETA: 0, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 27,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MU001', BLOCK: 'L110H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'MAJ-432004',      TAG_DESCR: 'SW Non-Hazardous Open Drain Filter', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '2017-09-28', PO_ACTUAL: '2017-09-28', FAT_PLAN: '2019-01-10', FAT_ACTUAL: '2019-06-19', ETA_PLAN: '2019-04-04', ETA_ACTUAL: '2019-08-14', ROS: '2019-04-29', INSTALL: '2019-08-19', UP_BLOCK_PE: '2019-05-22', ROS_ETA: -107, ROS_ETA_UPBLOCK: -84, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 28,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MU001', BLOCK: 'L110H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'MAJ-432003',      TAG_DESCR: 'SW Non-Hazardous Open Drain Filter', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '2017-09-28', PO_ACTUAL: '2017-09-28', FAT_PLAN: '2019-01-10', FAT_ACTUAL: '2019-06-19', ETA_PLAN: '2019-04-04', ETA_ACTUAL: '2019-08-14', ROS: '2019-04-29', INSTALL: '2019-08-17', UP_BLOCK_PE: '2019-05-22', ROS_ETA: -107, ROS_ETA_UPBLOCK: -84, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 29,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L110H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'HAU-926125',      TAG_DESCR: 'Air Supply Cellar Deck Offices', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-25', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-10-18', ROS: '2019-04-30', INSTALL: '2019-05-30', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '119', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 30,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L110H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'HAU-926115',      TAG_DESCR: 'Air Supply Cellar Deck Offices', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-25', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-10-18', ROS: '2019-04-30', INSTALL: '2019-05-30', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '119', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 31,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L110H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'HEF-926145',      TAG_DESCR: 'Air Recirculation Cellar Deck Offices', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-04-15', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-07-15', ROS: '2019-05-14', INSTALL: '2019-07-29', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '214', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 32,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L210H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'HEF-926135',      TAG_DESCR: 'Air Recirculation Cellar Deck Offices', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-04-15', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-07-15', ROS: '2019-05-14', INSTALL: '2019-07-29', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '214', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 33,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'XHHE-6130-3513',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 34,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'XHHE-6130-3521',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 35,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MU020', BLOCK: 'Loose',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'PBE-938505',      TAG_DESCR: 'Portable Bilge Pumps', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '2017-08-02', PO_ACTUAL: '2017-08-02', FAT_PLAN: '2018-08-16', FAT_ACTUAL: '2018-09-07', ETA_PLAN: '2018-10-30', ETA_ACTUAL: '2018-10-30', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '472', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 36,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L220H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'HCU-927792',      TAG_DESCR: 'LER, U4208', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-29', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-06-26', ROS: '2019-04-16', INSTALL: '2019-06-28', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '233', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 37,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'XHHE-6130-3516',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 38,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L220H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'HCU-927791',      TAG_DESCR: 'Subsea CTRL, U4212', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-13', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-06-11', ROS: '2019-04-16', INSTALL: '2019-06-13', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '248', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 39,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L220H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'HCU-927781',      TAG_DESCR: 'Subsea CTRL, U4212', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-29', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-06-26', ROS: '2019-04-16', INSTALL: '2019-08-08', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '233', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 40,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L220H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'HCU-927780',      TAG_DESCR: 'Subsea CTRL, U4213', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-06-25', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-08-01', ROS: '2019-07-11', INSTALL: '2019-08-06', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '197', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 41,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L220H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'HCU-927391',      TAG_DESCR: 'UPS, U4215', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-13', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-06-11', ROS: '2019-05-06', INSTALL: '2019-06-11', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '248', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 42,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L230H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'HCU-927390',      TAG_DESCR: 'UPS, U3215', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-13', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-06-11', ROS: '2019-04-16', INSTALL: '2019-06-13', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '248', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 43,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'XHHE-6130-3283',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 44,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'XHHE-6130-3352',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 45,  MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'EL007', BLOCK: 'L340H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'VMCS-682054',     TAG_DESCR: '480V Motor Control Center Essential MCC1B', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-10', ETA_ACTUAL: '2019-05-03', ROS: '2019-04-22', INSTALL: '2019-06-15', UP_BLOCK_PE: '2019-05-10', ROS_ETA: -11, ROS_ETA_UPBLOCK: 7, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 46,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MU001', BLOCK: 'L230H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'MAJ-806001',      TAG_DESCR: 'Diesel Coarse Filter', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '2017-09-28', PO_ACTUAL: '2017-09-28', FAT_PLAN: '2019-01-10', FAT_ACTUAL: '2019-03-19', ETA_PLAN: '2019-04-04', ETA_ACTUAL: '2019-04-23', ROS: '2019-04-08', INSTALL: '2019-04-25', UP_BLOCK_PE: '2019-05-21', ROS_ETA: -15, ROS_ETA_UPBLOCK: 28, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 47,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'SPSE-6413-806',   TAG_DESCR: 'Safety Shower and Eyewash', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-15', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 48,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MU001', BLOCK: 'L240H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'MAJ-806041',      TAG_DESCR: 'Diesel Coarse Filter', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '2017-09-28', PO_ACTUAL: '2017-09-28', FAT_PLAN: '2019-01-10', FAT_ACTUAL: '2019-03-12', ETA_PLAN: '2019-04-04', ETA_ACTUAL: '2019-05-02', ROS: '2019-05-06', INSTALL: '2019-05-15', UP_BLOCK_PE: '2019-05-23', ROS_ETA: 4, ROS_ETA_UPBLOCK: 21, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 49,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MU001', BLOCK: 'L240H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'MAJ-806051',      TAG_DESCR: 'Diesel Coarse Filter', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '2017-09-28', PO_ACTUAL: '2017-09-28', FAT_PLAN: '2019-01-10', FAT_ACTUAL: '2019-03-12', ETA_PLAN: '2019-04-04', ETA_ACTUAL: '2019-05-02', ROS: '2019-05-06', INSTALL: '2019-05-15', UP_BLOCK_PE: '2019-05-23', ROS_ETA: 4, ROS_ETA_UPBLOCK: 21, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 50,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L240H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'HCU-927190',      TAG_DESCR: 'LER, U3209', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-29', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-06-26', ROS: '2019-04-16', INSTALL: '2019-06-11', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '233', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 51,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'XHHE-6130-3351',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 52,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'PBE-802303',      TAG_DESCR: 'Water Injection Seawater Lift Pump (Future)', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 53,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MJ007', BLOCK: 'L240H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'PBE-802302',      TAG_DESCR: 'Seawater Lift Pump (Water Injection)', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-08', FAT_ACTUAL: '2019-06-24', ETA_PLAN: '2019-07-02', ETA_ACTUAL: '2019-08-21', ROS: '2019-07-25', INSTALL: '2019-12-20', UP_BLOCK_PE: '', ROS_ETA: -27, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 54,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MJ007', BLOCK: 'L240H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'PBE-802301',      TAG_DESCR: 'Seawater Lift Pump (Water Injection)', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-08', FAT_ACTUAL: '2019-06-24', ETA_PLAN: '2019-07-02', ETA_ACTUAL: '2019-08-21', ROS: '2019-07-25', INSTALL: '2019-12-20', UP_BLOCK_PE: '', ROS_ETA: -27, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 55,  MODULE: 'S10UH', DISC: 'PI', PKG_NO: 'IC006', BLOCK: 'M250H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'WISDV-47510103',  TAG_DESCR: 'Boarding Valve', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-29', ETA_ACTUAL: '2019-08-02', ROS: '2019-04-19', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: -101, ROS_ETA_UPBLOCK: null, CANBE_INST: '196', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 56,  MODULE: 'S10UH', DISC: 'PI', PKG_NO: 'IC006', BLOCK: 'M250H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'WISDV-47520103',  TAG_DESCR: 'Boarding Valve', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-29', ETA_ACTUAL: '2019-11-15', ROS: '2019-04-19', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: -210, ROS_ETA_UPBLOCK: null, CANBE_INST: '91', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 57,  MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'SE005', BLOCK: 'L240H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'PNL-6160335',     TAG_DESCR: 'Fusible Loop Charge Panel (SE)', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-08', ETA_ACTUAL: '2019-03-22', ROS: '2019-04-29', INSTALL: '', UP_BLOCK_PE: '2019-05-13', ROS_ETA: 38, ROS_ETA_UPBLOCK: 52, CANBE_INST: '329', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 58,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L240H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'HAU-927110',      TAG_DESCR: 'Supply LER/Electrical Workshop', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-28', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-11-15', ROS: '2019-04-29', INSTALL: '2019-06-04', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '91', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 59,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L240H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'HAU-927210',      TAG_DESCR: 'Supply Southeast Area', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-21', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-11-15', ROS: '2019-04-29', INSTALL: '2019-06-04', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '91', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 60,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L240H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'HEF-927250',      TAG_DESCR: 'Extract Fan SE. Cellar & Tween Deck', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-28', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-07-15', ROS: '2019-05-09', INSTALL: '2019-07-27', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '214', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 61,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L140H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'HAU-926710',      TAG_DESCR: 'Back-Up CCR, Main LER, ICC/Conference Room', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-29', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-12-31', ROS: '2019-04-30', INSTALL: '2019-06-04', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '45', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 62,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L140H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'HEF-927150',      TAG_DESCR: 'Extract Fan SE. Cellar Deck', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-28', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-07-15', ROS: '2019-04-30', INSTALL: '2019-07-27', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '214', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 63,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'XHHE-6130-3411',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 64,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'XHHE-6130-2031',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 65,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'XHHE-6130-3412',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 66,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'SPHR-6130-807',   TAG_DESCR: 'SPHR-6130 Water Only Hose Reel', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 67,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MU016', BLOCK: 'L140H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'ZZZ-991310',      TAG_DESCR: 'HPU Skid', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '2017-07-28', PO_ACTUAL: '2017-07-28', FAT_PLAN: '2019-02-15', FAT_ACTUAL: '2019-02-28', ETA_PLAN: '2019-04-30', ETA_ACTUAL: '2019-05-16', ROS: '2019-05-03', INSTALL: '2019-05-22', UP_BLOCK_PE: '2019-05-14', ROS_ETA: -13, ROS_ETA_UPBLOCK: -2, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 68,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MU016', BLOCK: 'L140H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'ZZZ-991370',      TAG_DESCR: 'Solenoid Cabinet', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '2017-07-28', PO_ACTUAL: '2017-07-28', FAT_PLAN: '2019-02-15', FAT_ACTUAL: '2019-02-28', ETA_PLAN: '2019-04-30', ETA_ACTUAL: '2019-05-16', ROS: '2019-05-03', INSTALL: '2019-05-22', UP_BLOCK_PE: '2019-05-14', ROS_ETA: -13, ROS_ETA_UPBLOCK: -2, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 69,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MU016', BLOCK: 'L140H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'ZZZ-991375',      TAG_DESCR: 'Solenoid Cabinet', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '2017-07-28', PO_ACTUAL: '2017-07-28', FAT_PLAN: '2019-02-15', FAT_ACTUAL: '2019-02-28', ETA_PLAN: '2019-04-30', ETA_ACTUAL: '2019-05-16', ROS: '2019-05-03', INSTALL: '2019-05-22', UP_BLOCK_PE: '2019-05-14', ROS_ETA: -13, ROS_ETA_UPBLOCK: -2, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 70,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L140H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'HAU-926120',      TAG_DESCR: 'Air Supply Cellar Deck Control Room', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-28', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-12-31', ROS: '2019-04-30', INSTALL: '2019-06-04', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '45', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 71,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L140H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'HAU-926110',      TAG_DESCR: 'Air Supply Cellar Deck Control Room', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-27', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-10-18', ROS: '2019-04-30', INSTALL: '2019-06-04', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '119', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 72,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L140H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'HEF-926140',      TAG_DESCR: 'Air Recirculation Cellar Deck CTRL Rooms', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-07', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-06-28', ROS: '2019-05-14', INSTALL: '2019-07-12', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '231', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 73,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L140H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'HEF-926130',      TAG_DESCR: 'Air Recirculation Cellar Deck CTRL Rooms', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-06-28', ROS: '2019-04-30', INSTALL: '2019-07-12', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '231', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 74,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MU016', BLOCK: 'L140H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'ZZZ-991390',      TAG_DESCR: 'Portable Hydraulic Pump Unit', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '2017-07-28', PO_ACTUAL: '2017-07-28', FAT_PLAN: '2019-02-15', FAT_ACTUAL: '2019-02-28', ETA_PLAN: '2019-04-30', ETA_ACTUAL: '2019-05-16', ROS: '2019-05-03', INSTALL: '2019-05-21', UP_BLOCK_PE: '2019-05-14', ROS_ETA: -13, ROS_ETA_UPBLOCK: -2, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 75,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MJ007', BLOCK: 'L140H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'PBE-611101',      TAG_DESCR: 'Southeast Fire Water Pump', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-08', FAT_ACTUAL: '2019-06-24', ETA_PLAN: '2019-07-02', ETA_ACTUAL: '2019-08-08', ROS: '2019-07-25', INSTALL: '2019-12-25', UP_BLOCK_PE: '', ROS_ETA: -14, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 76,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'SPHR-6130-808',   TAG_DESCR: 'SPHR-6130 Water Only Hose Reel', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 77,  MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'IC034', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'CON-6003311',     TAG_DESCR: 'VTC Console', DWG: 'GM004-IC-SPE-050-00129(22)', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-16', ETA_ACTUAL: '2019-07-20', ROS: '2019-03-28', INSTALL: '', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -114, ROS_ETA_UPBLOCK: -72, CANBE_INST: '209', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 78,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'CON-6003308',     TAG_DESCR: 'Side Bridge For Subsea', DWG: 'GM004-241IC034-E01-00940-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 79,  MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'IC034', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'CON-6003304',     TAG_DESCR: 'Subsea Operator Console Station', DWG: 'GM004-241IC034-E01-00940-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-16', ETA_ACTUAL: '2019-07-20', ROS: '2019-03-28', INSTALL: '', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -114, ROS_ETA_UPBLOCK: -72, CANBE_INST: '209', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 80,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'CON-6003310',     TAG_DESCR: 'Shared Bridge-Topside & Subsea', DWG: 'GM004-241IC034-E01-00910-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 81,  MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'IC034', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'CON-6003303',     TAG_DESCR: 'Topside Operator Console Station', DWG: 'GM004-241IC034-E01-00910-001 ', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-16', ETA_ACTUAL: '2019-07-20', ROS: '2019-03-28', INSTALL: '', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -114, ROS_ETA_UPBLOCK: -72, CANBE_INST: '209', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 82,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'CON-6003307',     TAG_DESCR: 'Side Bridge For Topside', DWG: 'GM004-241IC034-E01-00910-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 83,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'CON-6003305',     TAG_DESCR: 'Side Bridge For Marine', DWG: 'GM004-241IC034-E01-00930-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 84,  MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'IC034', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'CON-6003301',     TAG_DESCR: 'Marine Operator Console Station', DWG: 'GM004-241IC034-E01-00930-001 ', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-16', ETA_ACTUAL: '2019-05-13', ROS: '2019-03-28', INSTALL: '', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -46, ROS_ETA_UPBLOCK: -4, CANBE_INST: '277', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 85,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'CON-6003309',     TAG_DESCR: 'Shared Bridge - Marine & Water Injection', DWG: 'GM004-241IC034-E01-00930-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 86,  MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'IC034', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'CON-6003302',     TAG_DESCR: 'Water Injection Operator Coosole Station', DWG: 'GM004-241IC034-E01-00920-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-16', ETA_ACTUAL: '2019-09-20', ROS: '2019-03-28', INSTALL: '', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -176, ROS_ETA_UPBLOCK: -134, CANBE_INST: '147', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 87,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'CON-6003306',     TAG_DESCR: 'Side Bridge For Water Injection', DWG: 'GM004-241IC034-E01-00920-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 88,  MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'IC034', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'ISVR-603033-05',  TAG_DESCR: 'ICSS Server Cabinet 5', DWG: 'GM004-241IC034-E0I-00415-001 ', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-16', ETA_ACTUAL: '2019-10-30', ROS: '2019-03-28', INSTALL: '', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -216, ROS_ETA_UPBLOCK: -174, CANBE_INST: '107', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 89,  MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'IC034', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'ISVR-603033-04',  TAG_DESCR: 'ICSS Server Cabinet 4', DWG: 'GM004-241IC034-E0I-00414-001 ', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-16', ETA_ACTUAL: '2019-10-30', ROS: '2019-03-28', INSTALL: '', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -216, ROS_ETA_UPBLOCK: -174, CANBE_INST: '107', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 90,  MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'IC034', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'ISVR-603033-03',  TAG_DESCR: 'ICSS Server Cabinet 3', DWG: 'GM004-241IC034-E0I-00413-001 ', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-16', ETA_ACTUAL: '2019-10-30', ROS: '2019-03-28', INSTALL: '', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -216, ROS_ETA_UPBLOCK: -174, CANBE_INST: '107', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 91,  MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'IC034', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'ISVR-603033-02',  TAG_DESCR: 'ICSS Server Cabinet 2', DWG: 'GM004-241IC034-E0I-00412-001 ', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-16', ETA_ACTUAL: '2019-10-30', ROS: '2019-03-28', INSTALL: '', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -216, ROS_ETA_UPBLOCK: -174, CANBE_INST: '107', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 92,  MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'IC034', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'ISVR-603033-01',  TAG_DESCR: 'ICSS Server Cabinet 1', DWG: 'GM004-241IC034-E0I-00411-001 ', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-16', ETA_ACTUAL: '2019-10-30', ROS: '2019-03-28', INSTALL: '', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -216, ROS_ETA_UPBLOCK: -174, CANBE_INST: '107', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 93,  MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'IC034', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'INET-603033-06',  TAG_DESCR: 'ICSS Network Cabinet', DWG: 'GM004-241IC034-E01-00416-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-16', ETA_ACTUAL: '2019-07-20', ROS: '2019-03-28', INSTALL: '', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -114, ROS_ETA_UPBLOCK: -72, CANBE_INST: '209', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 94,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'TBD',             TAG_DESCR: 'Network Cabinet For PIN', DWG: 'GM004-IC-LAY-010-00007-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 95,  MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'EL007', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'VCP-861009',      TAG_DESCR: 'Workstation/Network Cabinet', DWG: 'GM004-IC-LAY-010-00007-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-10', ETA_ACTUAL: '2019-06-08', ROS: '2019-03-28', INSTALL: '', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -72, ROS_ETA_UPBLOCK: -30, CANBE_INST: '251', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 96,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'CPMS-6060334',    TAG_DESCR: 'MPMS/CPMS Cabinet 3', DWG: 'GM004-241I032-B01-00003-002 ', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 97,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'CPMS-6060333',    TAG_DESCR: 'MPMS/CPMS Cabinet 2', DWG: 'GM004-241I032-B01-00002-002 ', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 98,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'CPMS-6060332',    TAG_DESCR: 'MPMS/CPMS Cabinet 1', DWG: 'GM004-241I032-B01-00001-002 ', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 99,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'RRK-6080001',     TAG_DESCR: 'IMMS Cabinet', DWG: 'GM004-IC-LAY-010-00007-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 100, MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'TE001', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'RRK-9520010',     TAG_DESCR: 'PIN Server Cabinet For Telecom', DWG: 'GM004-IC-LAY-010-00007-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-02-08', ETA_ACTUAL: '2019-05-15', ROS: '2019-03-28', INSTALL: '', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -48, ROS_ETA_UPBLOCK: -6, CANBE_INST: '275', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 101, MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'TE001', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'RRK-9560021',     TAG_DESCR: 'Structured Cabling Cabinet - IDF', DWG: 'GM004-241TE001-B01-00049-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-02-08', ETA_ACTUAL: '2019-07-19', ROS: '2019-03-28', INSTALL: '', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -113, ROS_ETA_UPBLOCK: -71, CANBE_INST: '210', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 102, MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'TE001', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'RRK-9560020',     TAG_DESCR: 'Structured Cabling Cabinet-MOFP-B MAC Fiber', DWG: 'GM004-241TE001-B01-00048-001 ', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-02-08', ETA_ACTUAL: '2019-02-10', ROS: '2019-03-28', INSTALL: '', UP_BLOCK_PE: '2019-05-09', ROS_ETA: 46, ROS_ETA_UPBLOCK: 88, CANBE_INST: '369', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 103, MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'TE001', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'RRK-9560019',     TAG_DESCR: 'Structured Cabling Cabinet-MOFP-A MAC Fiber', DWG: 'GM004-241TE001-B01-00048-001 ', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-02-08', ETA_ACTUAL: '2019-07-19', ROS: '2019-03-28', INSTALL: '', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -113, ROS_ETA_UPBLOCK: -71, CANBE_INST: '210', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 104, MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'IC034', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'IPDC-871033-01',  TAG_DESCR: '24VOC Power Distribution Cabinet', DWG: 'GM004-241IC034-E01-00417-001 ', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-16', ETA_ACTUAL: '2019-07-20', ROS: '2019-03-28', INSTALL: '', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -114, ROS_ETA_UPBLOCK: -72, CANBE_INST: '209', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 105, MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'TE001', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'RRK-9540001',     TAG_DESCR: 'CCTV Server Cabinet', DWG: 'GM004-IC-LAY-010-00007-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-02-08', ETA_ACTUAL: '2019-07-19', ROS: '2019-03-28', INSTALL: '', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -113, ROS_ETA_UPBLOCK: -71, CANBE_INST: '210', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 106, MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'IC034', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'IPCS-602033-01',  TAG_DESCR: 'PCS System Cabinet 1', DWG: 'GM004-241IC034-E01-00400-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-16', ETA_ACTUAL: '2019-07-20', ROS: '2019-03-28', INSTALL: '', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -114, ROS_ETA_UPBLOCK: -72, CANBE_INST: '209', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 107, MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'IC034', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'IPSS-601033-04',  TAG_DESCR: 'PSS System Cabinet 4', DWG: 'GM004-IC-LAY-010-00007-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-16', ETA_ACTUAL: '2019-10-30', ROS: '2019-03-28', INSTALL: '', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -216, ROS_ETA_UPBLOCK: -174, CANBE_INST: '107', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 108, MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'IC034', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'IPSS-601033-03',  TAG_DESCR: 'PSS System Cabinet 3', DWG: 'GM004-IC-LAY-010-00007-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-16', ETA_ACTUAL: '2019-10-30', ROS: '2019-03-28', INSTALL: '', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -216, ROS_ETA_UPBLOCK: -174, CANBE_INST: '107', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 109, MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'IC034', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'IPSS-601033-02',  TAG_DESCR: 'PSS System Cabinet 2', DWG: 'GM004-241IC034-E01-00402-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-16', ETA_ACTUAL: '2019-10-30', ROS: '2019-03-28', INSTALL: '', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -216, ROS_ETA_UPBLOCK: -174, CANBE_INST: '107', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 110, MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'IC034', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'IPSS-601033-01',  TAG_DESCR: 'PSS System Cabinet 1', DWG: 'GM004-241IC034-E01-00401-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-16', ETA_ACTUAL: '2019-10-30', ROS: '2019-03-28', INSTALL: '', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -216, ROS_ETA_UPBLOCK: -174, CANBE_INST: '107', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 111, MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'IC034', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'IPSS-601033-05',  TAG_DESCR: 'PSS Usio Cabinet', DWG: 'GM004-241IC034-E01-00404-001 ', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-16', ETA_ACTUAL: '2019-07-20', ROS: '2019-03-28', INSTALL: '', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -114, ROS_ETA_UPBLOCK: -72, CANBE_INST: '209', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 112, MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'IC034', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'IESS-607033-08',  TAG_DESCR: 'ESS Usio Cabinet 2', DWG: 'GM004-241IC034-E01-00410-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-16', ETA_ACTUAL: '2019-07-20', ROS: '2019-03-28', INSTALL: '', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -114, ROS_ETA_UPBLOCK: -72, CANBE_INST: '209', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 113, MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'IC034', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'IESS-607033-07',  TAG_DESCR: 'ESS Usio Cabinet 1', DWG: 'GM004-241IC034-E01-00410-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-16', ETA_ACTUAL: '2019-07-20', ROS: '2019-03-28', INSTALL: '', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -114, ROS_ETA_UPBLOCK: -72, CANBE_INST: '209', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 114, MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'IC034', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'IESS-607033-06',  TAG_DESCR: 'ESS System Cabinet 6', DWG: 'GM004-241IC034-E01-00409-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-16', ETA_ACTUAL: '2019-10-30', ROS: '2019-03-28', INSTALL: '', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -216, ROS_ETA_UPBLOCK: -174, CANBE_INST: '107', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 115, MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'IC034', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'IESS-607033-05',  TAG_DESCR: 'ESS System Cabinet 5', DWG: 'GM004-241IC034-E01-00409-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-16', ETA_ACTUAL: '2019-10-30', ROS: '2019-03-28', INSTALL: '', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -216, ROS_ETA_UPBLOCK: -174, CANBE_INST: '107', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 116, MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'IC034', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'IESS-607033-04',  TAG_DESCR: 'ESS System Cabinet 4', DWG: 'GM004-241IC034-E01-00408-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-16', ETA_ACTUAL: '2019-10-30', ROS: '2019-03-28', INSTALL: '', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -216, ROS_ETA_UPBLOCK: -174, CANBE_INST: '107', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 117, MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'IC034', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'IESS-607033-03',  TAG_DESCR: 'ESS System Cabinet 3', DWG: 'GM004-241IC034-E01-00407-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-16', ETA_ACTUAL: '2019-10-30', ROS: '2019-03-28', INSTALL: '', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -216, ROS_ETA_UPBLOCK: -174, CANBE_INST: '107', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 118, MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'IC034', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'IESS-607033-02',  TAG_DESCR: 'ESS System Cabinet 2', DWG: 'GM004-241IC034-E01-00406-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-16', ETA_ACTUAL: '2019-10-30', ROS: '2019-03-28', INSTALL: '', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -216, ROS_ETA_UPBLOCK: -174, CANBE_INST: '107', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 119, MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'IC034', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'IESS-607033-01',  TAG_DESCR: 'ESS System Cabinet 1', DWG: 'GM004-241IC034-E01-00405-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-16', ETA_ACTUAL: '2019-10-30', ROS: '2019-03-28', INSTALL: '', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -216, ROS_ETA_UPBLOCK: -174, CANBE_INST: '107', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 120, MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'ILFP-607363-71',  TAG_DESCR: 'Local Fire Panel For South Secondary ', DWG: 'GM004-241 SE012-B01-00001-001 ', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 121, MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'ILFP-607363-70',  TAG_DESCR: 'Local Fire Panel For South Primary', DWG: 'GM004-241 SE012-B01-00001-001 ', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 122, MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'IC034', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'IMDF-602033-03',  TAG_DESCR: 'PCS Marshalling Cabinet 3', DWG: 'GM004-241IC034-E01-00419-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-16', ETA_ACTUAL: '2019-04-25', ROS: '2019-03-28', INSTALL: '2019-05-23', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -28, ROS_ETA_UPBLOCK: 14, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 123, MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'IC034', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'IMDF-602033-02',  TAG_DESCR: 'PCS Marshalling Cabinet 2', DWG: 'GM004-241IC034-E01-00419-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-16', ETA_ACTUAL: '2019-04-25', ROS: '2019-03-28', INSTALL: '2019-05-23', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -28, ROS_ETA_UPBLOCK: 14, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 124, MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'IC034', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'IMDF-602033-01',  TAG_DESCR: 'PCS Marshalling Cabinet 1', DWG: 'GM004-241IC034-E01-00418-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-16', ETA_ACTUAL: '2019-04-25', ROS: '2019-03-28', INSTALL: '2019-05-23', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -28, ROS_ETA_UPBLOCK: 14, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 125, MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'IC034', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'IMDF-602033-04',  TAG_DESCR: 'PCS Marshalling Cabinet 4', DWG: 'GM004-241IC034-E01-00419-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-16', ETA_ACTUAL: '2019-04-25', ROS: '2019-03-28', INSTALL: '2019-05-23', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -28, ROS_ETA_UPBLOCK: 14, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 126, MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'IC034', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'IMDF-601033-01',  TAG_DESCR: 'PSS Marshalling Cabinet 1', DWG: 'GM004-241IC034-E01-00420-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-16', ETA_ACTUAL: '2019-04-25', ROS: '2019-03-28', INSTALL: '2019-05-23', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -28, ROS_ETA_UPBLOCK: 14, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 127, MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'IC034', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'IMDF-607033-04',  TAG_DESCR: 'ESS Marshalling Cabinet 4', DWG: 'GM004-241IC034-E01-00422-001 ', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-16', ETA_ACTUAL: '2019-04-25', ROS: '2019-03-28', INSTALL: '2019-05-23', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -28, ROS_ETA_UPBLOCK: 14, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 128, MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'IC034', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'IMDF-607033-03',  TAG_DESCR: 'ESS Marshalling Cabinet 3', DWG: 'GM004-241IC034-E01-00422-001 ', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-16', ETA_ACTUAL: '2019-04-25', ROS: '2019-03-28', INSTALL: '2019-05-23', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -28, ROS_ETA_UPBLOCK: 14, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 129, MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'IC034', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'IMDF-607033-02',  TAG_DESCR: 'ESS Marshalling Cabinet 2', DWG: 'GM004-241IC034-E01-00422-001 ', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-16', ETA_ACTUAL: '2019-04-25', ROS: '2019-03-28', INSTALL: '2019-05-23', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -28, ROS_ETA_UPBLOCK: 14, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 130, MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'IC034', BLOCK: 'L130H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'IMDF-607033-01',  TAG_DESCR: 'ESS Marshalling Cabinet 1', DWG: 'GM004-241IC034-E01-00422-001 ', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-16', ETA_ACTUAL: '2019-04-25', ROS: '2019-03-28', INSTALL: '2019-05-23', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -28, ROS_ETA_UPBLOCK: 14, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 131, MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'EL007', BLOCK: 'L110H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'VMCS-682063',     TAG_DESCR: '480V Essential VFD MCC (EMCC3B) ', DWG: 'GM004-EL-GAS-010-00009-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-10', ETA_ACTUAL: '2019-04-14', ROS: '2019-04-22', INSTALL: '2019-05-22', UP_BLOCK_PE: '2019-05-16', ROS_ETA: 8, ROS_ETA_UPBLOCK: 32, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 132, MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'EL007', BLOCK: 'L110H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'VMCN-874056',     TAG_DESCR: '480V Normal MCC (NMCC3B) ', DWG: 'GM004-EL-GAS-010-00009-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-10', ETA_ACTUAL: '2019-04-14', ROS: '2019-04-22', INSTALL: '2019-05-22', UP_BLOCK_PE: '2019-05-16', ROS_ETA: 8, ROS_ETA_UPBLOCK: 32, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 133, MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'EL007', BLOCK: 'L110H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'VMCS-682056',     TAG_DESCR: '480V Essential MCC (EMCC3B)', DWG: 'GM004-EL-GAS-010-00009-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-10', ETA_ACTUAL: '2019-04-14', ROS: '2019-04-22', INSTALL: '2019-05-22', UP_BLOCK_PE: '2019-05-16', ROS_ETA: 8, ROS_ETA_UPBLOCK: 32, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 134, MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'EL007', BLOCK: 'L110H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'VMCS-682060',     TAG_DESCR: '480V Essential VFD MCC (EMCC3A) ', DWG: 'GM004-EL-GAS-010-00009-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-10', ETA_ACTUAL: '2019-04-14', ROS: '2019-04-22', INSTALL: '2019-05-22', UP_BLOCK_PE: '2019-05-16', ROS_ETA: 8, ROS_ETA_UPBLOCK: 32, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 135, MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'EL007', BLOCK: 'L110H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'VMCN-874053',     TAG_DESCR: '480V Normal MCC (NMCC3A) ', DWG: 'GM004-EL-GAS-010-00009-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-10', ETA_ACTUAL: '2019-04-14', ROS: '2019-04-22', INSTALL: '2019-05-22', UP_BLOCK_PE: '2019-05-16', ROS_ETA: 8, ROS_ETA_UPBLOCK: 32, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 136, MODULE: 'S10UH', DISC: 'EI', PKG_NO: 'EL007', BLOCK: 'L110H',         DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'VMCS-682053',     TAG_DESCR: '480V Essential MCC (EMCC3A)', DWG: 'GM004-EL-GAS-010-00009-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-10', ETA_ACTUAL: '2019-04-14', ROS: '2019-04-22', INSTALL: '2019-05-22', UP_BLOCK_PE: '2019-05-16', ROS_ETA: 8, ROS_ETA_UPBLOCK: 32, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 137, MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'S10UH_L1', TAG_NO: 'TLE-985241',      TAG_DESCR: 'Traction Type Elevator', DWG: 'GM004-AR-LAY-010-00031-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-07-25', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 1,   MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'T210H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'HCU-927480',      TAG_DESCR: 'HVAC, U4302', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-06-25', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-08-01', ROS: '2019-07-05', INSTALL: '2019-08-07', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '197', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 2,   MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'T210H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'HEF-892455',      TAG_DESCR: 'Extract Fan B Lower Hull Southwest', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-04-14', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-06-18', ROS: '2019-05-14', INSTALL: '2019-07-25', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '241', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 3,   MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'T210H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'HEF-892450',      TAG_DESCR: 'Extract Fan A Lower Hull Southwest', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-04-12', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-06-18', ROS: '2019-05-14', INSTALL: '2019-07-25', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '241', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 4,   MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'T210H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'HAU-892410',      TAG_DESCR: 'Supply Lower Hull Southwest', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-06-20', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-10-21', ROS: '2019-05-12', INSTALL: '2019-06-25', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '116', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 5,   MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'XHHE-6130-3361',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 6,   MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MR001', BLOCK: 'T110H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'ZMM-934450',      TAG_DESCR: 'Mooring HPU SW', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-06', FAT_ACTUAL: '2019-06-10', ETA_PLAN: '2019-04-15', ETA_ACTUAL: '2019-06-19', ROS: '2019-05-21', INSTALL: '2019-06-21', UP_BLOCK_PE: '2019-05-22', ROS_ETA: -29, ROS_ETA_UPBLOCK: -28, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 7,   MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'LCN-934551',      TAG_DESCR: 'Platform Chain Segment', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-08-29', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 8,   MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MR003', BLOCK: 'T110H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'LMM-934443',      TAG_DESCR: 'Chain Locker Weathertight Closure', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '2018-02-14', PO_ACTUAL: '2018-02-14', FAT_PLAN: '2019-02-03', FAT_ACTUAL: '2018-12-17', ETA_PLAN: '2019-04-25', ETA_ACTUAL: '2019-06-28', ROS: '2019-05-21', INSTALL: '2019-08-14', UP_BLOCK_PE: '', ROS_ETA: -38, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 9,   MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MR001', BLOCK: 'T110H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'LTS-934403',      TAG_DESCR: 'Turndown Sheave Assembly, 12 SW', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-06', FAT_ACTUAL: '2018-11-13', ETA_PLAN: '2019-04-15', ETA_ACTUAL: '2019-06-14', ROS: '2019-05-21', INSTALL: '2019-08-04', UP_BLOCK_PE: '2019-07-30', ROS_ETA: -24, ROS_ETA_UPBLOCK: 46, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 10,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MR001', BLOCK: 'E110H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'LCJ-934403',      TAG_DESCR: 'Mooring Chain Jack SW', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-06', FAT_ACTUAL: '2018-12-11', ETA_PLAN: '2019-04-15', ETA_ACTUAL: '2019-06-07', ROS: '2019-05-21', INSTALL: '2019-08-05', UP_BLOCK_PE: '2019-07-30', ROS_ETA: -17, ROS_ETA_UPBLOCK: 53, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 11,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'LCN-934550',      TAG_DESCR: 'Platform Chain Segment', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-08-29', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 12,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MR003', BLOCK: 'T110H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'LMM-934442',      TAG_DESCR: 'Chain Locker Weathertight Closure', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '2018-02-14', PO_ACTUAL: '2018-02-14', FAT_PLAN: '2019-02-03', FAT_ACTUAL: '2018-12-17', ETA_PLAN: '2019-04-25', ETA_ACTUAL: '2019-06-28', ROS: '2019-05-21', INSTALL: '2019-08-14', UP_BLOCK_PE: '', ROS_ETA: -38, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 13,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MR001', BLOCK: 'T110H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'LTS-934402',      TAG_DESCR: 'Turndown Sheave Assembly, 11 SW', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-06', FAT_ACTUAL: '2018-11-13', ETA_PLAN: '2019-04-15', ETA_ACTUAL: '2019-06-14', ROS: '2019-05-21', INSTALL: '2019-08-04', UP_BLOCK_PE: '2019-07-30', ROS_ETA: -24, ROS_ETA_UPBLOCK: 46, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 14,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MR001', BLOCK: 'E110H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'LCJ-934402',      TAG_DESCR: 'Mooring Chain Jack SW', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-06', FAT_ACTUAL: '2019-01-22', ETA_PLAN: '2019-04-15', ETA_ACTUAL: '2019-06-07', ROS: '2019-05-21', INSTALL: '2019-08-05', UP_BLOCK_PE: '2019-07-30', ROS_ETA: -17, ROS_ETA_UPBLOCK: 53, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 15,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'LCN-934549',      TAG_DESCR: 'Platform Chain Segment', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-08-30', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 16,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MR003', BLOCK: 'T110H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'LMM-934441',      TAG_DESCR: 'Chain Locker Weathertight Closure', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '2018-02-14', PO_ACTUAL: '2018-02-14', FAT_PLAN: '2019-02-03', FAT_ACTUAL: '2018-12-17', ETA_PLAN: '2019-04-25', ETA_ACTUAL: '2019-06-28', ROS: '2019-05-21', INSTALL: '2019-08-14', UP_BLOCK_PE: '', ROS_ETA: -38, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 17,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MR001', BLOCK: 'T110H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'LTS-934401',      TAG_DESCR: 'Turndown Sheave Assembly, 10 SW', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-06', FAT_ACTUAL: '2018-11-13', ETA_PLAN: '2019-04-15', ETA_ACTUAL: '2019-06-14', ROS: '2019-05-21', INSTALL: '2019-08-04', UP_BLOCK_PE: '2019-07-30', ROS_ETA: -24, ROS_ETA_UPBLOCK: 46, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 18,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MR001', BLOCK: 'E110H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'LCJ-934401',      TAG_DESCR: 'Mooring Chain Jack SW', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-06', FAT_ACTUAL: '2018-12-19', ETA_PLAN: '2019-04-15', ETA_ACTUAL: '2019-06-07', ROS: '2019-05-21', INSTALL: '2019-08-05', UP_BLOCK_PE: '2019-07-30', ROS_ETA: -17, ROS_ETA_UPBLOCK: 53, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 19,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MR001', BLOCK: 'T110H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'ZMM-934460',      TAG_DESCR: 'Mooring Local Control Panel SW', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-06', FAT_ACTUAL: '2018-11-13', ETA_PLAN: '2019-04-15', ETA_ACTUAL: '2019-06-07', ROS: '2019-05-21', INSTALL: '', UP_BLOCK_PE: '2019-05-22', ROS_ETA: -17, ROS_ETA_UPBLOCK: -16, CANBE_INST: '252', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 20,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'SPHR-6130-705',   TAG_DESCR: 'SPHR-6130 Water Only Hose Reel', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 21,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MU001', BLOCK: 'E110H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'MAJ-431004',      TAG_DESCR: 'SW Hazardous Open Drain Filter', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '2017-09-28', PO_ACTUAL: '2017-09-28', FAT_PLAN: '2019-01-10', FAT_ACTUAL: '2019-06-19', ETA_PLAN: '2019-04-04', ETA_ACTUAL: '2019-08-14', ROS: '2019-05-07', INSTALL: '2019-08-17', UP_BLOCK_PE: '2019-05-22', ROS_ETA: -99, ROS_ETA_UPBLOCK: -84, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 22,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MU001', BLOCK: 'E110H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'MAJ-431003',      TAG_DESCR: 'SW Hazardous Open Drain Filter', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '2017-09-28', PO_ACTUAL: '2017-09-28', FAT_PLAN: '2019-01-10', FAT_ACTUAL: '2019-06-19', ETA_PLAN: '2019-04-04', ETA_ACTUAL: '2019-08-14', ROS: '2019-05-07', INSTALL: '2019-08-17', UP_BLOCK_PE: '2019-05-22', ROS_ETA: -99, ROS_ETA_UPBLOCK: -84, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 23,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'T110H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'HAU-926210',      TAG_DESCR: 'Air Supply Tween Deck Southwest', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-06-21', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-12-31', ROS: '2019-05-12', INSTALL: '2019-06-25', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '45', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 24,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'T110H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'HAU-926220',      TAG_DESCR: 'Air Supply Tween Deck Southwest', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-06-21', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-12-31', ROS: '2019-05-12', INSTALL: '2019-06-25', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '45', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 25,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'T110H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'HEF-926230',      TAG_DESCR: 'Recirculation Tween Deck Southwest', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-08-07', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-08-16', ROS: '2019-04-11', INSTALL: '2019-08-17', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '182', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 26,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'T110H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'HEF-926240',      TAG_DESCR: 'Recirculation Tween Deck Southwest', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-08-07', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-08-16', ROS: '2019-05-14', INSTALL: '2019-08-17', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '182', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 27,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'XHHE-6130-3391',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 28,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'T110H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'HEF-926155',      TAG_DESCR: 'Dirty Extract Tween/Cellar Deck', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-08-07', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-08-16', ROS: '2019-04-11', INSTALL: '2019-08-17', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '182', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 29,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'T110H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'HEF-926150',      TAG_DESCR: 'Dirty Extract Tween/Cellar Deck', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-08-07', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-08-16', ROS: '2019-05-14', INSTALL: '2019-08-17', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '182', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 30,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MU012', BLOCK: 'T310H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'ZZZ-801501',      TAG_DESCR: 'Fresh Water Maker', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '2017-08-25', PO_ACTUAL: '2017-08-25', FAT_PLAN: '2019-03-15', FAT_ACTUAL: '', ETA_PLAN: '2019-05-07', ETA_ACTUAL: '2019-06-06', ROS: '2019-05-20', INSTALL: '2019-06-22', UP_BLOCK_PE: '2019-05-22', ROS_ETA: -17, ROS_ETA_UPBLOCK: -15, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 31,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MU012', BLOCK: 'T310H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'ZZZ-801635',      TAG_DESCR: 'Chlorination Unit', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '2017-08-25', PO_ACTUAL: '2017-08-25', FAT_PLAN: '2019-03-15', FAT_ACTUAL: '', ETA_PLAN: '2019-05-07', ETA_ACTUAL: '2019-06-17', ROS: '2019-05-20', INSTALL: '2019-06-22', UP_BLOCK_PE: '2019-05-22', ROS_ETA: -28, ROS_ETA_UPBLOCK: -26, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 32,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'SPSE-6413-702',   TAG_DESCR: 'Safety Shower and Eyewash', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-15', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 33,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'XHHE-6130-3511',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 34,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'SE004', BLOCK: 'T310H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'ZZZ-613510',      TAG_DESCR: 'Water Mist Package', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '2018-01-09', PO_ACTUAL: '2018-01-09', FAT_PLAN: '2019-04-22', FAT_ACTUAL: '2019-04-26', ETA_PLAN: '2019-05-20', ETA_ACTUAL: '2019-05-17', ROS: '2019-05-20', INSTALL: '2019-06-22', UP_BLOCK_PE: '2019-05-22', ROS_ETA: 3, ROS_ETA_UPBLOCK: 5, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 35,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'T310H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'HAU-992610',      TAG_DESCR: 'Supply Corridor West', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-06-04', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-12-06', ROS: '2019-05-12', INSTALL: '2019-06-26', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '70', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 36,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'T310H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'HAU-992910',      TAG_DESCR: 'Supply Chiller Room', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-06-19', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-08-26', ROS: '2019-05-12', INSTALL: '2019-06-26', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '172', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 37,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'T310H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'HEF-992950',      TAG_DESCR: 'Recirculation Fan Chiller Rooms', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-06-28', ROS: '2019-05-14', INSTALL: '2019-07-25', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '231', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 38,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'T310H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'HAU-927710',      TAG_DESCR: 'Supply Southwest', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-06-24', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-08-26', ROS: '2019-05-12', INSTALL: '2019-06-26', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '172', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 39,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'T310H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'HAU-927510',      TAG_DESCR: 'Supply HVAC / Spare Rooms South', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-06-25', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-08-26', ROS: '2019-05-12', INSTALL: '2019-06-26', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '172', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 40,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'T310H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'HEF-927750',      TAG_DESCR: 'Extract Fan SW. Cellar Deck', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-30', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-07-15', ROS: '2019-05-14', INSTALL: '2019-07-31', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '214', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 41,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'T310H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'HEF-927550',      TAG_DESCR: 'Extract Fan South. Tween & Main Deck', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-28', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-07-15', ROS: '2019-04-12', INSTALL: '2019-07-31', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '214', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 42,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'XHHE-6130-3251',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 43,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'XHHE-6130-3331',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 44,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'T320H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'HAU-927310',      TAG_DESCR: 'Supply UPS Rooms', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-31', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-08-31', ROS: '2019-05-11', INSTALL: '2019-06-19', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '167', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 45,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L140H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'HEF-927330',      TAG_DESCR: 'Recirculation Fan UPS Rooms', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-28', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-07-15', ROS: '2019-05-14', INSTALL: '2019-07-31', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '214', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 46,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'T320H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'HEF-927630',      TAG_DESCR: 'Recirculation Fan A Electrical Rooms', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-04-12', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-06-28', ROS: '2019-05-14', INSTALL: '2019-07-25', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '231', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 47,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'T320H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'HEF-927640',      TAG_DESCR: 'Recirculation Fan B Electrical Rooms', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-04-12', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-06-28', ROS: '2019-05-14', INSTALL: '2019-07-25', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '231', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 48,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'XHHE-6130-3041',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 49,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'T320H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'HAU-927920',      TAG_DESCR: 'Supply Battery Room', DWG: 'GM004-PI-LAY-241-30007-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-28', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-08-26', ROS: '2019-05-11', INSTALL: '2019-06-10', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '172', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 50,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'T320H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'HAU-927910',      TAG_DESCR: 'Supply Battery Room', DWG: 'GM004-PI-LAY-241-30007-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-28', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-08-26', ROS: '2019-05-11', INSTALL: '2019-06-10', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '172', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 51,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'T320H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'HAU-927620',      TAG_DESCR: 'Supply Electrical Room Southeast', DWG: 'GM004-PI-LAY-241-30007-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-31', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-08-31', ROS: '2019-05-11', INSTALL: '2019-06-19', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '167', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 52,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'T320H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'HAU-927610',      TAG_DESCR: 'Supply Electrical Room Southeast', DWG: 'GM004-PI-LAY-241-30007-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-06-05', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-08-31', ROS: '2019-05-11', INSTALL: '2019-06-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '167', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 53,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'T240H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'HEF-892355',      TAG_DESCR: 'Extract Fan B Lower Hull Southeast', DWG: 'GM004-PI-LAY-241-30007-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-04-16', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-06-28', ROS: '2019-05-09', INSTALL: '2019-07-25', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '231', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 54,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'T240H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'HEF-892350',      TAG_DESCR: 'Extract Fan A Lower Hull Southeast', DWG: 'GM004-PI-LAY-241-30007-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-04-09', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-06-18', ROS: '2019-05-09', INSTALL: '2019-07-25', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '241', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 55,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MU009', BLOCK: 'E210H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'HCA-611105',      TAG_DESCR: 'Fire Water Pump Generator Remote Radiator', DWG: 'GM004-PI-LAY-241-30007-001', PO_PLAN: '2017-01-26', PO_ACTUAL: '2017-01-26', FAT_PLAN: '2018-12-10', FAT_ACTUAL: '2019-02-18', ETA_PLAN: '2019-03-28', ETA_ACTUAL: '2019-05-10', ROS: '2019-05-09', INSTALL: '2019-08-20', UP_BLOCK_PE: '', ROS_ETA: -1, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 56,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'T240H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'HAU-892310',      TAG_DESCR: 'Supply Lower Hull Southeast', DWG: 'GM004-PI-LAY-241-30007-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-06-26', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-08-26', ROS: '2019-05-12', INSTALL: '2019-06-28', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '172', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 57,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'XHHE-6130-3411',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 58,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'SPHR-6130-704',   TAG_DESCR: 'SPHR-6130 Water Only Hose Reel', DWG: 'GM004-PI-LAY-241-30007-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 59,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MR001', BLOCK: 'T140H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'ZMM-934360',      TAG_DESCR: 'Mooring Local Control Panel SE', DWG: 'GM004-PI-LAY-241-30007-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-06', FAT_ACTUAL: '2018-11-13', ETA_PLAN: '2019-04-15', ETA_ACTUAL: '2019-06-07', ROS: '2019-05-21', INSTALL: '', UP_BLOCK_PE: '2019-05-24', ROS_ETA: -17, ROS_ETA_UPBLOCK: -14, CANBE_INST: '252', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 60,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MR001', BLOCK: 'T140H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'ZMM-934350',      TAG_DESCR: 'Mooring HPU SE', DWG: 'GM004-PI-LAY-241-30007-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-06', FAT_ACTUAL: '2019-06-10', ETA_PLAN: '2019-04-15', ETA_ACTUAL: '2019-06-19', ROS: '2019-05-21', INSTALL: '2019-06-20', UP_BLOCK_PE: '2019-05-24', ROS_ETA: -29, ROS_ETA_UPBLOCK: -26, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 61,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'LCN-934546',      TAG_DESCR: 'Platform Chain Segment', DWG: 'GM004-PI-LAY-241-30007-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-10-09', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 62,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MR003', BLOCK: 'T140H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'LMM-934341',      TAG_DESCR: 'Chain Locker Weathertight Closure', DWG: 'GM004-PI-LAY-241-30007-001', PO_PLAN: '2018-02-14', PO_ACTUAL: '2018-02-14', FAT_PLAN: '2019-02-03', FAT_ACTUAL: '2018-12-17', ETA_PLAN: '2019-04-25', ETA_ACTUAL: '2019-06-28', ROS: '2019-05-21', INSTALL: '2019-08-14', UP_BLOCK_PE: '', ROS_ETA: -38, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 63,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MR001', BLOCK: 'T140H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'LTS-934301',      TAG_DESCR: 'Turndown Sheave Assembly, 7 SE', DWG: 'GM004-PI-LAY-241-30007-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-06', FAT_ACTUAL: '2018-11-13', ETA_PLAN: '2019-04-15', ETA_ACTUAL: '2019-06-14', ROS: '2019-05-21', INSTALL: '2019-08-04', UP_BLOCK_PE: '', ROS_ETA: -24, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 64,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MR001', BLOCK: 'E210H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'LCJ-934301',      TAG_DESCR: 'Mooring Chain Jack SE', DWG: 'GM004-PI-LAY-241-30007-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-06', FAT_ACTUAL: '2018-12-11', ETA_PLAN: '2019-04-15', ETA_ACTUAL: '2019-06-07', ROS: '2019-05-21', INSTALL: '2019-08-05', UP_BLOCK_PE: '', ROS_ETA: -17, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 65,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'LCN-934547',      TAG_DESCR: 'Platform Chain Segment', DWG: 'GM004-PI-LAY-241-30007-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-08-30', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 66,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MR003', BLOCK: 'T140H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'LMM-934342',      TAG_DESCR: 'Chain Locker Weathertight Closure', DWG: 'GM004-PI-LAY-241-30007-001', PO_PLAN: '2018-02-14', PO_ACTUAL: '2018-02-14', FAT_PLAN: '2019-02-03', FAT_ACTUAL: '2018-12-17', ETA_PLAN: '2019-04-25', ETA_ACTUAL: '2019-06-28', ROS: '2019-05-21', INSTALL: '2019-08-14', UP_BLOCK_PE: '', ROS_ETA: -38, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 67,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MR001', BLOCK: 'T140H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'LTS-934302',      TAG_DESCR: 'Turndown Sheave Assembly, 8 SE', DWG: 'GM004-PI-LAY-241-30007-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-06', FAT_ACTUAL: '2018-11-13', ETA_PLAN: '2019-04-15', ETA_ACTUAL: '2019-06-14', ROS: '2019-05-21', INSTALL: '2019-08-04', UP_BLOCK_PE: '', ROS_ETA: -24, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 68,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MR001', BLOCK: 'E210H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'LCJ-934302',      TAG_DESCR: 'Mooring Chain Jack SE', DWG: 'GM004-PI-LAY-241-30007-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-06', FAT_ACTUAL: '2018-12-19', ETA_PLAN: '2019-04-15', ETA_ACTUAL: '2019-06-07', ROS: '2019-05-21', INSTALL: '2019-08-05', UP_BLOCK_PE: '', ROS_ETA: -17, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 69,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'LCN-934548',      TAG_DESCR: 'Platform Chain Segment', DWG: 'GM004-PI-LAY-241-30007-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-10-08', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 70,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MR003', BLOCK: 'T140H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'LMM-934343',      TAG_DESCR: 'Chain Locker Weathertight Closure', DWG: 'GM004-PI-LAY-241-30007-001', PO_PLAN: '2018-02-14', PO_ACTUAL: '2018-02-14', FAT_PLAN: '2019-02-03', FAT_ACTUAL: '2018-12-17', ETA_PLAN: '2019-04-25', ETA_ACTUAL: '2019-06-28', ROS: '2019-05-21', INSTALL: '2019-08-14', UP_BLOCK_PE: '', ROS_ETA: -38, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 71,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MR001', BLOCK: 'T140H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'LTS-934303',      TAG_DESCR: 'Turndown Sheave Assembly, 9 SE', DWG: 'GM004-PI-LAY-241-30007-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-06', FAT_ACTUAL: '2018-11-13', ETA_PLAN: '2019-04-15', ETA_ACTUAL: '2019-06-14', ROS: '2019-05-21', INSTALL: '2019-08-04', UP_BLOCK_PE: '', ROS_ETA: -24, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 72,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'MR001', BLOCK: 'E210H',         DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'LCJ-934303',      TAG_DESCR: 'Mooring Chain Jack SE', DWG: 'GM004-PI-LAY-241-30007-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-06', FAT_ACTUAL: '2019-01-22', ETA_PLAN: '2019-04-15', ETA_ACTUAL: '2019-06-07', ROS: '2019-05-21', INSTALL: '2019-08-05', UP_BLOCK_PE: '', ROS_ETA: -17, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 73,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'YFL-920005A ',    TAG_DESCR: 'Stackable Dryer', DWG: 'GM004-AR-LST-241-00017', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 74,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'YFL-920005B ',    TAG_DESCR: 'Stackable Dryer', DWG: 'GM004-AR-LST-241-00017', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 75,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'YFL-920005C',     TAG_DESCR: 'Stackable Dryer', DWG: 'GM004-AR-LST-241-00017', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 76,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'YFL-920005D',     TAG_DESCR: 'Stackable Dryer', DWG: 'GM004-AR-LST-241-00017', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 77,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'YFL-920005E',     TAG_DESCR: 'Stackable Dryer', DWG: 'GM004-AR-LST-241-00017', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 78,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'YFL-920005F',     TAG_DESCR: 'Stackable Dryer', DWG: 'GM004-AR-LST-241-00017', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 79,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'YFL-920005G',     TAG_DESCR: 'Stackable Dryer', DWG: 'GM004-AR-LST-241-00017', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 80,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'YFL-920005H',     TAG_DESCR: 'Stackable Dryer', DWG: 'GM004-AR-LST-241-00017', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 81,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'YFL-920005I',     TAG_DESCR: 'Stackable Dryer', DWG: 'GM004-AR-LST-241-00017', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 82,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'YFL-920005J',     TAG_DESCR: 'Stackable Dryer', DWG: 'GM004-AR-LST-241-00017', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 83,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'YFL-920006A ',    TAG_DESCR: 'Stackable Washer', DWG: 'GM004-AR-LST-241-00017', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 84,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'YFL-920006B',     TAG_DESCR: 'Stackable Washer', DWG: 'GM004-AR-LST-241-00017', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 85,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'YFL-920006C',     TAG_DESCR: 'Stackable Washer', DWG: 'GM004-AR-LST-241-00017', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 86,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'YFL-920006D',     TAG_DESCR: 'Stackable Washer', DWG: 'GM004-AR-LST-241-00017', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 87,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'YFL-920006E',     TAG_DESCR: 'Stackable Washer', DWG: 'GM004-AR-LST-241-00017', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 88,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'YFL-920006F',     TAG_DESCR: 'Stackable Washer', DWG: 'GM004-AR-LST-241-00017', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 89,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'YFL-920006G',     TAG_DESCR: 'Stackable Washer', DWG: 'GM004-AR-LST-241-00017', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 90,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'YFL-920006H',     TAG_DESCR: 'Stackable Washer', DWG: 'GM004-AR-LST-241-00017', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 91,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'YFL-920006I',     TAG_DESCR: 'Stackable Washer', DWG: 'GM004-AR-LST-241-00017', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 92,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'YFL-920006J',     TAG_DESCR: 'Stackable Washer', DWG: 'GM004-AR-LST-241-00017', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 93,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'YFL-920012A',     TAG_DESCR: 'Tumble Dryer', DWG: 'GM004-AR-LST-241-00017', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 94,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'YFL-920011A',     TAG_DESCR: 'Washer·extrator', DWG: 'GM004-AR-LST-241-00017', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 95,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'YFL-920012B',     TAG_DESCR: 'Tumble Dryer', DWG: 'GM004-AR-LST-241-00017', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 96,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'S10UH_L2', TAG_NO: 'YFL-920011B',     TAG_DESCR: 'Washer·extrator', DWG: 'GM004-AR-LST-241-00017', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 1,   MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'S10UH_L3', TAG_NO: 'SPHR-6130-616',   TAG_DESCR: 'Water Only Hose Reel', DWG: 'GM004-PI-LAY-241-50004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 2,   MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'S10UH_L3', TAG_NO: 'SPHR-6130-615',   TAG_DESCR: 'Water Only Hose Reel', DWG: 'GM004-PI-LAY-241-50004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 3,   MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'M230H',         DECK: 'MAIN DECK',    SVG: 'S10UH_L3', TAG_NO: 'HEF-927950',      TAG_DESCR: 'Extract Fan A Battery Room', DWG: 'GM004-PI-LAY-241-50004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-12-06', ROS: '2019-10-08', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '70', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 4,   MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'M230H',         DECK: 'MAIN DECK',    SVG: 'S10UH_L3', TAG_NO: 'HEF-927955',      TAG_DESCR: 'Extract Fan B Battery Room', DWG: 'GM004-PI-LAY-241-50003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-12-06', ROS: '2019-10-08', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '70', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 5,   MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'M230H',         DECK: 'MAIN DECK',    SVG: 'S10UH_L3', TAG_NO: 'HAU-992810',      TAG_DESCR: 'Supply Paint Locker', DWG: 'GM004-PI-LAY-241-50003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-07-14', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-12-06', ROS: '2019-05-07', INSTALL: '2019-08-16', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '70', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 6,   MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'S10UH_L3', TAG_NO: 'SPHR-6130-609',   TAG_DESCR: 'Water Only Hose Reel', DWG: 'GM004-PI-LAY-241-50003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 7,   MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'S10UH_L3', TAG_NO: 'SPHR-6130-608',   TAG_DESCR: 'Water Only Hose Reel', DWG: 'GM004-PI-LAY-241-50003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 8,   MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'S10UH_L3', TAG_NO: 'SPHR-6130-618',   TAG_DESCR: 'Water Only Hose Reel', DWG: 'GM004-PI-LAY-241-50004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 9,   MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'S10UH_L3', TAG_NO: 'SPHR-6130-613',   TAG_DESCR: 'Water Only Hose Reel', DWG: 'GM004-PI-LAY-241-50004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 10,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'S10UH_L3', TAG_NO: 'US-510-6',        TAG_DESCR: 'Utility Station', DWG: 'GM004-PI-LAY-241-50004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-10-30', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 11,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'S10UH_L3', TAG_NO: 'SPHR-8012440',    TAG_DESCR: 'Water Only Hose Reel', DWG: null, PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 12,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'S10UH_L3', TAG_NO: 'SPHR-8110209',    TAG_DESCR: 'Water Only Hose Reel', DWG: null, PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 13,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'S10UH_L3', TAG_NO: 'SPHR-8061014',    TAG_DESCR: 'SPHR-6130 Water Only Hose Reel', DWG: 'GM004-PI-LAY-241-50004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 14,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'S10UH_L3', TAG_NO: 'SPHR-6130-612',   TAG_DESCR: 'Water Only Hose Reel', DWG: 'GM004-PI-LAY-241-50004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 15,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'S10UH_L3', TAG_NO: 'SPHR-6130-610',   TAG_DESCR: 'Water Only Hose Reel', DWG: 'GM004-PI-LAY-241-50003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 16,  MODULE: 'S10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'M140H',         DECK: 'MAIN DECK',    SVG: 'S10UH_L3', TAG_NO: 'HCN-926720',      TAG_DESCR: 'Back-Up CCR, Main LER, ICC/Conference Room', DWG: 'GM004-PI-LAY-241-50003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-06-12', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-06-11', ROS: '2019-06-17', INSTALL: '2019-08-16', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '248', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 17,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'S10UH_L3', TAG_NO: 'US-510-5',        TAG_DESCR: 'Utility Station', DWG: 'GM004-PI-LAY-241-50003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-10-30', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 18,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'S10UH_L3', TAG_NO: 'SPHR-8012439',    TAG_DESCR: 'Water Only Hose Reel', DWG: null, PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 19,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'S10UH_L3', TAG_NO: 'SPHR-8110208',    TAG_DESCR: 'Water Only Hose Reel', DWG: null, PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 20,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'S10UH_L3', TAG_NO: 'SPHR-6130-611',   TAG_DESCR: 'Water Only Hose Reel', DWG: 'GM004-PI-LAY-241-50003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 21,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'SE001', BLOCK: 'SP10H',         DECK: 'MAIN DECK',    SVG: 'S10UH_L3', TAG_NO: 'XLL-632040',      TAG_DESCR: 'Lifeboat', DWG: 'GM004-PI-LAY-241-50004-001', PO_PLAN: '2017-09-20', PO_ACTUAL: '2017-09-20', FAT_PLAN: '2018-10-18', FAT_ACTUAL: '2019-11-28', ETA_PLAN: '2019-05-13', ETA_ACTUAL: '', ROS: '2019-11-04', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: -116, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'FAT', },
      { AREA: 'Deckbox', SN: 22,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'S10UH_L3', TAG_NO: 'XDB-632041',      TAG_DESCR: 'Survival Craft Davit Winch', DWG: null, PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-07-30', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 23,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'SE001', BLOCK: 'SP10H',         DECK: 'MAIN DECK',    SVG: 'S10UH_L3', TAG_NO: 'XLL-632030',      TAG_DESCR: 'Lifeboat', DWG: 'GM004-PI-LAY-241-50004-001', PO_PLAN: '2017-09-20', PO_ACTUAL: '2017-09-20', FAT_PLAN: '2018-10-18', FAT_ACTUAL: '2019-11-28', ETA_PLAN: '2019-05-13', ETA_ACTUAL: '', ROS: '2019-11-04', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: -116, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'FAT', },
      { AREA: 'Deckbox', SN: 24,  MODULE: 'S10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'S10UH_L3', TAG_NO: 'XDB-632031',      TAG_DESCR: 'Survival Craft Davit Winch', DWG: null, PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-07-22', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 25,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'SE001', BLOCK: 'SP10H',         DECK: 'MAIN DECK',    SVG: 'S10UH_L3', TAG_NO: 'XBR-632050',      TAG_DESCR: 'Fast Rescue Boat', DWG: 'GM004-PI-LAY-241-50004-001', PO_PLAN: '2017-09-20', PO_ACTUAL: '2017-09-20', FAT_PLAN: '2018-10-18', FAT_ACTUAL: '2019-09-12', ETA_PLAN: '2019-05-13', ETA_ACTUAL: '', ROS: '2019-11-04', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: -116, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'FAT', },
      { AREA: 'Deckbox', SN: 26,  MODULE: 'S10UH', DISC: 'ME', PKG_NO: 'SE001', BLOCK: 'SP10H',         DECK: 'MAIN DECK',    SVG: 'S10UH_L3', TAG_NO: 'XRD-634010',      TAG_DESCR: 'Fast Rescue Boat Davit', DWG: null, PO_PLAN: '2017-09-20', PO_ACTUAL: '2017-09-20', FAT_PLAN: '2018-10-18', FAT_ACTUAL: '2018-10-18', ETA_PLAN: '2019-05-13', ETA_ACTUAL: '', ROS: '2019-06-03', INSTALL: '2019-07-01', UP_BLOCK_PE: '', ROS_ETA: -270, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 1,   MODULE: 'W10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'ZZZ-986152',      TAG_DESCR: 'Marine Sanitation Device Equipment', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 2,   MODULE: 'W10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'ZZZ-986151',      TAG_DESCR: 'Marine Sanitation Device Equipment', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 3,   MODULE: 'W10UH', DISC: 'ME', PKG_NO: 'MD006', BLOCK: 'L410H',         DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'MBJ-812009',      TAG_DESCR: 'Instrument Air Receiver', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '2017-08-22', PO_ACTUAL: '2017-08-24', FAT_PLAN: '2018-07-23', FAT_ACTUAL: '2018-09-21', ETA_PLAN: '2019-04-07', ETA_ACTUAL: '2019-04-18', ROS: '2019-04-12', INSTALL: '2019-05-03', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -6, ROS_ETA_UPBLOCK: 21, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 4,   MODULE: 'W10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'SPHR-6130-802',   TAG_DESCR: 'SPHR-6130 Water Only Hose Reel', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 5,   MODULE: 'W10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'M410H',         DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'HEF-992255',      TAG_DESCR: 'Extract Fan Air Lock, U1212', DWG: 'GM004-PI-LAY-241-30006-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-07-22', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-08-26', ROS: '2019-07-09', INSTALL: '2019-12-18', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '172', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 6,   MODULE: 'W10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'M410H',         DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'HSF-992220',      TAG_DESCR: 'Supply Fan Air Lock, U1212', DWG: 'GM004-PI-LAY-241-30006-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-07-22', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-08-26', ROS: '2019-07-09', INSTALL: '2019-01-22', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '172', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 7,   MODULE: 'W10UH', DISC: 'ME', PKG_NO: 'MD006', BLOCK: 'L410H',         DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'MBJ-812013',      TAG_DESCR: 'Instrument Air Receiver', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '2017-08-22', PO_ACTUAL: '2017-08-24', FAT_PLAN: '2018-07-23', FAT_ACTUAL: '2018-09-21', ETA_PLAN: '2019-04-07', ETA_ACTUAL: '2019-04-18', ROS: '2019-04-12', INSTALL: '2019-05-03', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -6, ROS_ETA_UPBLOCK: 21, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 8,   MODULE: 'W10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'ZZZ-681010/SILENCER-STUDY', TAG_DESCR: 'ESS. Generator West Skid', DWG: null, PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 9,   MODULE: 'W10UH', DISC: 'ME', PKG_NO: 'MJ013', BLOCK: 'L410H',         DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'ZZZ-812310',      TAG_DESCR: 'Instrument/Utility Air Compressor Skid', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '2017-01-13', PO_ACTUAL: '2017-01-13', FAT_PLAN: '2019-01-23', FAT_ACTUAL: '2019-06-06', ETA_PLAN: '2019-03-27', ETA_ACTUAL: '2019-09-10', ROS: '2019-04-12', INSTALL: '2019-09-18', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -151, ROS_ETA_UPBLOCK: -124, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 10,  MODULE: 'W10UH', DISC: 'ME', PKG_NO: 'MU003', BLOCK: 'L410H',         DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'MAJ-802104',      TAG_DESCR: 'Seawater Strainer (CW)', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '2017-08-04', PO_ACTUAL: '2017-08-04', FAT_PLAN: '2018-11-16', FAT_ACTUAL: '2018-12-03', ETA_PLAN: '2019-03-28', ETA_ACTUAL: '2019-04-15', ROS: '2019-04-12', INSTALL: '2019-04-18', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -3, ROS_ETA_UPBLOCK: 24, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 11,  MODULE: 'W10UH', DISC: 'ME', PKG_NO: 'MU003', BLOCK: 'L410H',         DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'MAJ-802105',      TAG_DESCR: 'Seawater Strainer (CW)', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '2017-08-04', PO_ACTUAL: '2017-08-04', FAT_PLAN: '2018-11-16', FAT_ACTUAL: '2018-12-03', ETA_PLAN: '2019-03-28', ETA_ACTUAL: '2019-04-15', ROS: '2019-04-12', INSTALL: '2019-04-25', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -3, ROS_ETA_UPBLOCK: 24, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 12,  MODULE: 'W10UH', DISC: 'ME', PKG_NO: 'MU003', BLOCK: 'L420H',         DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'MAJ-802106',      TAG_DESCR: 'Seawater Strainer (CW)', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '2017-08-04', PO_ACTUAL: '2017-08-04', FAT_PLAN: '2018-11-16', FAT_ACTUAL: '2018-12-03', ETA_PLAN: '2019-03-28', ETA_ACTUAL: '2019-04-15', ROS: '2019-04-04', INSTALL: '2019-04-18', UP_BLOCK_PE: '2019-05-08', ROS_ETA: -11, ROS_ETA_UPBLOCK: 23, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 13,  MODULE: 'W10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'M410H',         DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'HEF-992565',      TAG_DESCR: 'Extract Fan B Essential Generator West', DWG: 'GM004-PI-LAY-241-30006-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-10-21', ROS: '2019-07-09', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '116', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 14,  MODULE: 'W10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'M420H',         DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'HEF-992560',      TAG_DESCR: 'Extract Fan A Essential Generator West', DWG: 'GM004-PI-LAY-241-30006-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-10-21', ROS: '2019-07-09', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '116', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 15,  MODULE: 'W10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'XHHE-6130-8020',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 16,  MODULE: 'W10UH', DISC: 'ME', PKG_NO: 'MU009', BLOCK: 'L420H',         DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'ABJ-681026',      TAG_DESCR: 'ESS. Generator West Lube Oil Tank', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '2017-01-26', PO_ACTUAL: '2017-01-26', FAT_PLAN: '2018-12-10', FAT_ACTUAL: '2019-01-08', ETA_PLAN: '2019-03-28', ETA_ACTUAL: '2019-04-26', ROS: '2019-04-04', INSTALL: '2019-05-15', UP_BLOCK_PE: '2019-05-08', ROS_ETA: -22, ROS_ETA_UPBLOCK: 12, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 17,  MODULE: 'W10UH', DISC: 'ME', PKG_NO: 'MU009', BLOCK: 'L410H',         DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'ZZZ-681010',      TAG_DESCR: 'ESS. Generator West Skid', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '2017-01-26', PO_ACTUAL: '2017-01-26', FAT_PLAN: '2018-12-10', FAT_ACTUAL: '2019-01-08', ETA_PLAN: '2019-03-28', ETA_ACTUAL: '2019-04-26', ROS: '2019-04-12', INSTALL: '2019-05-14', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -14, ROS_ETA_UPBLOCK: 13, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 18,  MODULE: 'W10UH', DISC: 'ME', PKG_NO: 'MU009', BLOCK: 'L410H',         DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'ZZZ-681020',      TAG_DESCR: 'DEF Dosing Cabinet', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '2017-01-26', PO_ACTUAL: '2017-01-26', FAT_PLAN: '2018-12-10', FAT_ACTUAL: '2019-01-08', ETA_PLAN: '2019-03-28', ETA_ACTUAL: '2019-04-26', ROS: '2019-04-12', INSTALL: '2019-05-14', UP_BLOCK_PE: '2019-05-09', ROS_ETA: -14, ROS_ETA_UPBLOCK: 13, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 19,  MODULE: 'W10UH', DISC: 'ME', PKG_NO: 'MU009', BLOCK: 'L310H',         DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'ZZZ-681030',      TAG_DESCR: 'SCR Unit', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '2017-01-26', PO_ACTUAL: '2017-01-26', FAT_PLAN: '2018-12-10', FAT_ACTUAL: '2019-01-08', ETA_PLAN: '2019-03-28', ETA_ACTUAL: '2019-04-26', ROS: '2019-04-08', INSTALL: '2019-06-06', UP_BLOCK_PE: '2019-05-13', ROS_ETA: -18, ROS_ETA_UPBLOCK: 17, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 20,  MODULE: 'W10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'SPHR-8012488',    TAG_DESCR: 'SPHR-6130 Water Only Hose Reel', DWG: null, PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 21,  MODULE: 'W10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'SPHR-8110257',    TAG_DESCR: 'SPHR-6130 Water Only Hose Reel', DWG: null, PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 22,  MODULE: 'W10UH', DISC: 'ME', PKG_NO: 'MJ013', BLOCK: 'L310H',         DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'ZZZ-812200',      TAG_DESCR: 'Instrument/Utility Air Compressor Skid', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '2017-01-13', PO_ACTUAL: '2017-01-13', FAT_PLAN: '2019-01-23', FAT_ACTUAL: '2019-08-22', ETA_PLAN: '2019-03-27', ETA_ACTUAL: '2019-10-11', ROS: '2019-04-08', INSTALL: '2019-10-16', UP_BLOCK_PE: '2019-05-13', ROS_ETA: -186, ROS_ETA_UPBLOCK: -151, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 23,  MODULE: 'W10UH', DISC: 'ME', PKG_NO: 'MJ013', BLOCK: 'L310H',         DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'ZZZ-812100',      TAG_DESCR: 'Instrument/Utility Air Compressor Skid', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '2017-01-13', PO_ACTUAL: '2017-01-13', FAT_PLAN: '2019-01-23', FAT_ACTUAL: '2019-08-21', ETA_PLAN: '2019-03-27', ETA_ACTUAL: '2019-10-11', ROS: '2019-04-08', INSTALL: '2019-10-16', UP_BLOCK_PE: '2019-05-13', ROS_ETA: -186, ROS_ETA_UPBLOCK: -151, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 24,  MODULE: 'W10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'SPHR-6130-801',   TAG_DESCR: 'SPHR-6130 Water Only Hose Reel', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 25,  MODULE: 'W10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'M320H',         DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'HSF-992520',      TAG_DESCR: 'Supply Fan A Essential Generator West', DWG: 'GM004-PI-LAY-241-30006-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-10-21', ROS: '2019-07-09', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '116', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 26,  MODULE: 'W10UH', DISC: 'ME', PKG_NO: 'MU020', BLOCK: 'L320H',         DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'PBE-938501',      TAG_DESCR: 'Upper Hull Daily Bilge Pumps', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '2017-08-02', PO_ACTUAL: '2017-08-02', FAT_PLAN: '2018-08-16', FAT_ACTUAL: '2018-09-07', ETA_PLAN: '2018-10-30', ETA_ACTUAL: '2018-10-30', ROS: '2019-04-05', INSTALL: '2019-04-10', UP_BLOCK_PE: '2019-05-14', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 27,  MODULE: 'W10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'XHHE-6130-3515',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 28,  MODULE: 'W10UH', DISC: 'ME', PKG_NO: 'MU020', BLOCK: 'L320H',         DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'PBE-938504',      TAG_DESCR: 'Upper Hull Daily Bilge Pumps', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '2017-08-02', PO_ACTUAL: '2017-08-02', FAT_PLAN: '2018-08-16', FAT_ACTUAL: '2018-09-07', ETA_PLAN: '2018-10-30', ETA_ACTUAL: '2018-10-30', ROS: '2019-04-05', INSTALL: '2019-04-10', UP_BLOCK_PE: '2019-05-14', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 29,  MODULE: 'W10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'XHHE-6130-3031',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 30,  MODULE: 'W10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'XHHE-6130-3514',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 31,  MODULE: 'W10UH', DISC: 'HV', PKG_NO: 'HV002', BLOCK: 'L420H',         DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'ZCW-808040',      TAG_DESCR: 'Chiller Compressor Unit', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-03-05', FAT_ACTUAL: '', ETA_PLAN: '2019-04-02', ETA_ACTUAL: '2019-06-11', ROS: '2019-05-21', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '248', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 32,  MODULE: 'W10UH', DISC: 'HV', PKG_NO: 'HV002', BLOCK: 'L420H',         DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'PBE-808010',      TAG_DESCR: 'Chilled Water Circulation Pump', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-03-05', FAT_ACTUAL: '', ETA_PLAN: '2019-04-02', ETA_ACTUAL: '2019-06-04', ROS: '2019-04-24', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '255', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 33,  MODULE: 'W10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'XHHE-6130-8040',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 34,  MODULE: 'W10UH', DISC: 'ME', PKG_NO: 'MU011', BLOCK: 'L430H',         DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'ZZZ-843300',      TAG_DESCR: 'Utility Hypochlorite Generator Skid', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '2017-09-15', PO_ACTUAL: '2017-09-15', FAT_PLAN: '2019-02-21', FAT_ACTUAL: '2019-05-22', ETA_PLAN: '2019-04-18', ETA_ACTUAL: '2019-06-10', ROS: '2019-04-09', INSTALL: '2019-06-14', UP_BLOCK_PE: '2019-05-10', ROS_ETA: -62, ROS_ETA_UPBLOCK: -31, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 35,  MODULE: 'W10UH', DISC: 'HV', PKG_NO: 'HV002', BLOCK: 'L420H',         DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'ZCW-808050',      TAG_DESCR: 'Chiller Compressor Unit', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-03-05', FAT_ACTUAL: '', ETA_PLAN: '2019-04-02', ETA_ACTUAL: '2019-06-11', ROS: '2019-05-21', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '248', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 36,  MODULE: 'W10UH', DISC: 'HV', PKG_NO: 'HV002', BLOCK: 'L420H',         DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'PBE-808020',      TAG_DESCR: 'Chilled Water Circulation Pump', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-03-05', FAT_ACTUAL: '', ETA_PLAN: '2019-04-02', ETA_ACTUAL: '2019-06-04', ROS: '2019-04-24', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '255', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 37,  MODULE: 'W10UH', DISC: 'HV', PKG_NO: 'HV002', BLOCK: 'L320H',         DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'ZCW-808060',      TAG_DESCR: 'Chiller Compressor Unit', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-03-05', FAT_ACTUAL: '', ETA_PLAN: '2019-04-02', ETA_ACTUAL: '2019-06-07', ROS: '2019-05-21', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '252', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 38,  MODULE: 'W10UH', DISC: 'HV', PKG_NO: 'HV002', BLOCK: 'L420H',         DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'PBE-808030',      TAG_DESCR: 'Chilled Water Circulation Pump', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-03-05', FAT_ACTUAL: '', ETA_PLAN: '2019-04-02', ETA_ACTUAL: '2019-06-04', ROS: '2019-04-24', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '255', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 39,  MODULE: 'W10UH', DISC: 'EI', PKG_NO: 'SE005', BLOCK: 'L320H',         DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'PNL-6160345',     TAG_DESCR: 'Fusible Loop Charge Panel (SW)', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-08', ETA_ACTUAL: '2019-03-22', ROS: '2019-03-29', INSTALL: '', UP_BLOCK_PE: '2019-05-14', ROS_ETA: 7, ROS_ETA_UPBLOCK: 53, CANBE_INST: '329', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 40,  MODULE: 'W10UH', DISC: 'ME', PKG_NO: 'MJ013', BLOCK: 'L320H',         DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'ZZZ-812400',      TAG_DESCR: 'Emergency Air Compressor Skid', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '2017-01-13', PO_ACTUAL: '2017-01-13', FAT_PLAN: '2019-01-23', FAT_ACTUAL: '2018-12-27', ETA_PLAN: '2019-03-27', ETA_ACTUAL: '2019-05-10', ROS: '2019-04-05', INSTALL: '2019-05-22', UP_BLOCK_PE: '2019-05-14', ROS_ETA: -35, ROS_ETA_UPBLOCK: 4, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 41,  MODULE: 'W10UH', DISC: 'ME', PKG_NO: 'MU012', BLOCK: 'L320H',         DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'ZZZ-801040',      TAG_DESCR: 'Potable/Utility Water Pressure Set', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '2017-08-25', PO_ACTUAL: '2017-08-25', FAT_PLAN: '2019-03-15', FAT_ACTUAL: '', ETA_PLAN: '2019-05-07', ETA_ACTUAL: '2019-06-20', ROS: '2019-04-05', INSTALL: '2019-06-21', UP_BLOCK_PE: '2019-05-14', ROS_ETA: -76, ROS_ETA_UPBLOCK: -37, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 42,  MODULE: 'W10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'SPHR-8012493',    TAG_DESCR: 'SPHR-6130 Water Only Hose Reel', DWG: null, PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 43,  MODULE: 'W10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'SPHR-8110262',    TAG_DESCR: 'SPHR-6130 Water Only Hose Reel', DWG: null, PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 44,  MODULE: 'W10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'SPSE-6413-802',   TAG_DESCR: 'Safety Shower and Eyewash', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-15', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 45,  MODULE: 'W10UH', DISC: 'EI', PKG_NO: 'EL002', BLOCK: 'L330H',         DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'VMCN-876052',     TAG_DESCR: '5KV Motor Control Center - Hull MCC1B', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-10', ETA_ACTUAL: '2019-04-03', ROS: '2019-04-04', INSTALL: '', UP_BLOCK_PE: '2019-05-15', ROS_ETA: 1, ROS_ETA_UPBLOCK: 42, CANBE_INST: '317', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 46,  MODULE: 'W10UH', DISC: 'EI', PKG_NO: 'EL002', BLOCK: 'L330H',         DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'VMCN-876051',     TAG_DESCR: '5KV Motor Control Center - Hull MCC1A', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-10', ETA_ACTUAL: '2019-04-03', ROS: '2019-04-04', INSTALL: '', UP_BLOCK_PE: '2019-05-15', ROS_ETA: 1, ROS_ETA_UPBLOCK: 42, CANBE_INST: '317', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 47,  MODULE: 'W10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'XHHE-6130-3271',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 48,  MODULE: 'W10UH', DISC: 'EI', PKG_NO: 'EL002', BLOCK: 'L330H',         DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'VSGN-876051',     TAG_DESCR: '5KV Switchgear - Hull', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-10', ETA_ACTUAL: '2019-04-03', ROS: '2019-04-04', INSTALL: '', UP_BLOCK_PE: '2019-05-15', ROS_ETA: 1, ROS_ETA_UPBLOCK: 42, CANBE_INST: '317', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 49,  MODULE: 'W10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'XHHE-6130-3032',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 50,  MODULE: 'W10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'XHHE-6130-3301',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30008-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 51,  MODULE: 'W10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L330H',         DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'HCU-927790',      TAG_DESCR: 'Subsea CTRL, U4213', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-06-21', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-08-08', ROS: '2019-07-09', INSTALL: '2019-08-03', UP_BLOCK_PE: '', ROS_ETA: -30, ROS_ETA_UPBLOCK: null, CANBE_INST: '190', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 52,  MODULE: 'W10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'VSS-87605101',    TAG_DESCR: '5KV Soft Start For Backup Water Pump VM-611201', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 53,  MODULE: 'W10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'US-310-2',        TAG_DESCR: 'Utility Station', DWG: 'GM004-PI-LAY-241-30004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-10-30', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 54,  MODULE: 'W10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'W10UH_L1', TAG_NO: 'US-310-7',        TAG_DESCR: 'Utility Station', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-10-30', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 1,   MODULE: 'W10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'W10UH_L2', TAG_NO: 'US-510-1',        TAG_DESCR: 'Utility Station', DWG: 'GM004-PI-LAY-241-50002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-10-30', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 2,   MODULE: 'W10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'W10UH_L2', TAG_NO: 'SPHR-8012435',    TAG_DESCR: 'Water Only Hose Reel', DWG: null, PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 3,   MODULE: 'W10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'W10UH_L2', TAG_NO: 'SPHR-8110204',    TAG_DESCR: 'Water Only Hose Reel', DWG: null, PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 4,   MODULE: 'W10UH', DISC: 'EI', PKG_NO: 'IC008', BLOCK: 'M430H',         DECK: 'MAIN DECK',    SVG: 'W10UH_L2', TAG_NO: 'PNL-6010310',     TAG_DESCR: 'Pressure Safety Test Panel', DWG: 'GM004-PI-LAY-241-50002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-03', ETA_ACTUAL: '2019-08-12', ROS: '2019-05-16', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: -88, ROS_ETA_UPBLOCK: null, CANBE_INST: '186', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 5,   MODULE: 'W10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'W10UH_L2', TAG_NO: 'SPSE-6414-601',   TAG_DESCR: 'Safety Shower and Eyewash', DWG: 'GM004-PI-LAY-241-50002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-15', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 6,   MODULE: 'W10UH', DISC: 'ME', PKG_NO: 'ML008', BLOCK: 'M430H',         DECK: 'MAIN DECK',    SVG: 'W10UH_L2', TAG_NO: 'ZZZ-840000',      TAG_DESCR: 'SWI Chemical Injection Skid', DWG: 'GM004-PI-LAY-241-50002-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-09-11', FAT_ACTUAL: '2018-12-03', ETA_PLAN: '2019-04-01', ETA_ACTUAL: '2019-09-11', ROS: '2019-05-17', INSTALL: '2019-09-29', UP_BLOCK_PE: '', ROS_ETA: -117, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 7,   MODULE: 'W10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'W10UH_L2', TAG_NO: 'US-510-2',        TAG_DESCR: 'Utility Station', DWG: 'GM004-PI-LAY-241-50002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-10-30', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 8,   MODULE: 'W10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'W10UH_L2', TAG_NO: 'SPHR-8012436',    TAG_DESCR: 'Water Only Hose Reel', DWG: null, PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 9,   MODULE: 'W10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'W10UH_L2', TAG_NO: 'SPHR-8110205',    TAG_DESCR: 'Water Only Hose Reel', DWG: null, PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 10,  MODULE: 'W10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'W10UH_L2', TAG_NO: 'US-510-3',        TAG_DESCR: 'Utility Station', DWG: 'GM004-PI-LAY-241-50002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-10-30', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 11,  MODULE: 'W10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'W10UH_L2', TAG_NO: 'SPHR-8012437',    TAG_DESCR: 'Water Only Hose Reel', DWG: null, PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 12,  MODULE: 'W10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'W10UH_L2', TAG_NO: 'SPHR-8110206',    TAG_DESCR: 'Water Only Hose Reel', DWG: null, PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 13,  MODULE: 'W10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'W10UH_L2', TAG_NO: 'SPHR-6130-603',   TAG_DESCR: 'Water Only Hose Reel', DWG: 'GM004-PI-LAY-241-50002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 14,  MODULE: 'W10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'W10UH_L2', TAG_NO: 'SPHR-6130-617',   TAG_DESCR: 'Water Only Hose Reel', DWG: 'GM004-PI-LAY-241-50004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 15,  MODULE: 'W10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'M320H',         DECK: 'MAIN DECK',    SVG: 'W10UH_L2', TAG_NO: 'HCN-892481',      TAG_DESCR: 'HVAC MCC Room(SW) Cooling', DWG: 'GM004-PI-LAY-241-50004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-24', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-06-11', ROS: '2019-06-30', INSTALL: '2019-11-30', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '248', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 16,  MODULE: 'W10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'M320H',         DECK: 'MAIN DECK',    SVG: 'W10UH_L2', TAG_NO: 'HCN-892480',      TAG_DESCR: 'HVAC MCC Room(SW) Cooling', DWG: 'GM004-PI-LAY-241-50004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-24', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-08-01', ROS: '2019-06-30', INSTALL: '2019-11-30', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '197', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 17,  MODULE: 'W10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'W10UH_L2', TAG_NO: 'SPAR-6130-606',   TAG_DESCR: 'Foam Hose Reel (Left Hand/Back Inlet)', DWG: 'GM004-PI-LAY-241-50004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 18,  MODULE: 'W10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'M320H',         DECK: 'MAIN DECK',    SVG: 'W10UH_L2', TAG_NO: 'HCN-927795',      TAG_DESCR: 'LER, U4208', DWG: 'GM004-PI-LAY-241-50004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-06-11', ROS: '2019-10-25', INSTALL: '2019-11-12', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '248', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 19,  MODULE: 'W10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'M330H',         DECK: 'MAIN DECK',    SVG: 'W10UH_L2', TAG_NO: 'HCN-927393',      TAG_DESCR: 'UPS, U4215', DWG: 'GM004-PI-LAY-241-50004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-06-21', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-06-26', ROS: '2019-10-25', INSTALL: '2019-11-12', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '233', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 20,  MODULE: 'W10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'M330H',         DECK: 'MAIN DECK',    SVG: 'W10UH_L2', TAG_NO: 'HCN-927185',      TAG_DESCR: 'LV MCC Room U3213 Cooling', DWG: 'GM004-PI-LAY-241-50004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-07-17', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-05-24', ROS: '2019-10-25', INSTALL: '2019-11-11', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '266', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 21,  MODULE: 'W10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'W10UH_L2', TAG_NO: 'SPAR-6130-604',   TAG_DESCR: 'Foam Hose Reel (Left Hand/Back Inlet)', DWG: 'GM004-PI-LAY-241-50004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 22,  MODULE: 'W10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'M330H',         DECK: 'MAIN DECK',    SVG: 'W10UH_L2', TAG_NO: 'HCN-927191',      TAG_DESCR: 'LER, U3209', DWG: 'GM004-PI-LAY-241-50004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-06-09', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-06-26', ROS: '2019-10-25', INSTALL: '2019-11-04', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '233', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 23,  MODULE: 'W10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'M330H',         DECK: 'MAIN DECK',    SVG: 'W10UH_L2', TAG_NO: 'HCN-927186',      TAG_DESCR: 'LV MCC Room U3213 Cooling', DWG: 'GM004-PI-LAY-241-50004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-07-17', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-07-29', ROS: '2019-10-25', INSTALL: '2019-11-14', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '200', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 24,  MODULE: 'W10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'M330H',         DECK: 'MAIN DECK',    SVG: 'W10UH_L2', TAG_NO: 'HEF-992650',      TAG_DESCR: 'Extract Fan W. Corridor', DWG: 'GM004-PI-LAY-241-50004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-12-18', ROS: '2019-06-01', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '58', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 25,  MODULE: 'W10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'M330H',         DECK: 'MAIN DECK',    SVG: 'W10UH_L2', TAG_NO: 'HCN-927797',      TAG_DESCR: 'Subsea CTRL, U4212', DWG: 'GM004-PI-LAY-241-50004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-07-12', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-07-16', ROS: '2019-10-25', INSTALL: '2019-11-04', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '213', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 26,  MODULE: 'W10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'M330H',         DECK: 'MAIN DECK',    SVG: 'W10UH_L2', TAG_NO: 'HCN-927687',      TAG_DESCR: 'LV Switchgear MCC Room, U2212', DWG: 'GM004-PI-LAY-241-50004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-05-24', ROS: '2019-10-25', INSTALL: '2019-11-26', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '266', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 27,  MODULE: 'W10UH', DISC: 'ME', PKG_NO: 'MU017', BLOCK: 'M910H',         DECK: 'MAIN DECK',    SVG: 'W10UH_L2', TAG_NO: 'ZZZ-807000',      TAG_DESCR: 'Helifuel Tank Skid', DWG: 'GM004-PI-LAY-241-50004-001', PO_PLAN: '2017-09-27', PO_ACTUAL: '2017-09-27', FAT_PLAN: '2019-03-18', FAT_ACTUAL: '2019-08-27', ETA_PLAN: '2019-06-09', ETA_ACTUAL: '2019-11-18', ROS: '2019-06-16', INSTALL: '2019-11-28', UP_BLOCK_PE: '', ROS_ETA: -155, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 28,  MODULE: 'W10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'M330H',         DECK: 'MAIN DECK',    SVG: 'W10UH_L2', TAG_NO: 'HCN-927793',      TAG_DESCR: 'Subsea CTRL, U4213', DWG: 'GM004-PI-LAY-241-50004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-07-10', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-08-08', ROS: '2019-10-25', INSTALL: '2019-11-04', UP_BLOCK_PE: '', ROS_ETA: 78, ROS_ETA_UPBLOCK: null, CANBE_INST: '190', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 29,  MODULE: 'W10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'M330H',         DECK: 'MAIN DECK',    SVG: 'W10UH_L2', TAG_NO: 'HCN-927796',      TAG_DESCR: 'Subsea CTRL, U4213', DWG: 'GM004-PI-LAY-241-50004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-07-12', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-08-01', ROS: '2019-10-25', INSTALL: '2019-11-04', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '197', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 30,  MODULE: 'W10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'M330H',         DECK: 'MAIN DECK',    SVG: 'W10UH_L2', TAG_NO: 'HCN-927794',      TAG_DESCR: 'Subsea CTRL, U4212', DWG: 'GM004-PI-LAY-241-50004-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-07-12', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-05-24', ROS: '2019-10-25', INSTALL: '2019-11-04', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '266', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 1,   MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'XLB-6460-011',    TAG_DESCR: 'Life Buoy With Light', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 2,   MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'SPHR-6130-803',   TAG_DESCR: 'SPHR-6130 Water Only Hose Reel', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 3,   MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MJ007', BLOCK: 'L510H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'PBE-935501',      TAG_DESCR: 'Hull Ballast/Utility Lift Pump', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-08', FAT_ACTUAL: '2018-11-08', ETA_PLAN: '2019-07-02', ETA_ACTUAL: '2019-08-09', ROS: '2019-07-09', INSTALL: '', UP_BLOCK_PE: '2019-11-21', ROS_ETA: -31, ROS_ETA_UPBLOCK: 104, CANBE_INST: '189', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 4,   MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MU016', BLOCK: 'L610H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'ZZZ-991110',      TAG_DESCR: 'HPU Skid', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '2017-07-28', PO_ACTUAL: '2017-07-28', FAT_PLAN: '2019-02-15', FAT_ACTUAL: '2019-03-06', ETA_PLAN: '2019-04-30', ETA_ACTUAL: '2019-05-16', ROS: '2019-04-09', INSTALL: '2019-05-22', UP_BLOCK_PE: '2019-04-25', ROS_ETA: -37, ROS_ETA_UPBLOCK: -21, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 5,   MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MU016', BLOCK: 'L610H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'ZZZ-991170',      TAG_DESCR: 'Solenoid Cabinet', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '2017-07-28', PO_ACTUAL: '2017-07-28', FAT_PLAN: '2019-02-15', FAT_ACTUAL: '2019-03-06', ETA_PLAN: '2019-04-30', ETA_ACTUAL: '2019-05-16', ROS: '2019-04-09', INSTALL: '2019-05-21', UP_BLOCK_PE: '2019-04-25', ROS_ETA: -37, ROS_ETA_UPBLOCK: -21, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 6,   MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MU016', BLOCK: 'L610H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'ZZZ-991175',      TAG_DESCR: 'Solenoid Cabinet', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '2017-07-28', PO_ACTUAL: '2017-07-28', FAT_PLAN: '2019-02-15', FAT_ACTUAL: '2019-03-06', ETA_PLAN: '2019-04-30', ETA_ACTUAL: '2019-05-16', ROS: '2019-04-09', INSTALL: '2019-05-21', UP_BLOCK_PE: '2019-04-25', ROS_ETA: -37, ROS_ETA_UPBLOCK: -21, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 7,   MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MU016', BLOCK: 'L610H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'ZZZ-991190',      TAG_DESCR: 'Portable Hydraulic Pump Unit', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '2017-07-28', PO_ACTUAL: '2017-07-28', FAT_PLAN: '2019-02-15', FAT_ACTUAL: '2019-03-06', ETA_PLAN: '2019-04-30', ETA_ACTUAL: '2019-05-16', ROS: '2019-04-09', INSTALL: '2019-05-21', UP_BLOCK_PE: '2019-04-25', ROS_ETA: -37, ROS_ETA_UPBLOCK: -21, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 8,   MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'XCL-6450-009',    TAG_DESCR: 'Life Preserver Box 30 Units', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 9,   MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'SE003', BLOCK: 'L610H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'XLR-633011',      TAG_DESCR: 'Liferaft (Single Rack)', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '2017-09-04', PO_ACTUAL: '2017-09-04', FAT_PLAN: '2019-06-24', FAT_ACTUAL: '2019-10-01', ETA_PLAN: '2019-08-29', ETA_ACTUAL: '2019-12-15', ROS: '2019-08-06', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: -131, ROS_ETA_UPBLOCK: null, CANBE_INST: '61', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 10,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MJ007', BLOCK: 'L510H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'PBE-611201',      TAG_DESCR: 'Northwest Fire Water Pump', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-08', FAT_ACTUAL: '2019-06-24', ETA_PLAN: '2019-07-02', ETA_ACTUAL: '2019-08-12', ROS: '2019-07-09', INSTALL: '2019-12-20', UP_BLOCK_PE: '', ROS_ETA: -34, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 11,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MJ007', BLOCK: 'L510H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'PBE-802101',      TAG_DESCR: 'Seawater Lift Pump (Cooling Water)', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-08', FAT_ACTUAL: '2019-10-03', ETA_PLAN: '2019-07-02', ETA_ACTUAL: '2019-11-05', ROS: '2019-07-09', INSTALL: '2019-12-25', UP_BLOCK_PE: '', ROS_ETA: -119, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 12,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MJ007', BLOCK: 'L510H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'PBE-802102',      TAG_DESCR: 'Seawater Lift Pump (Cooling Water)', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-08', FAT_ACTUAL: '2019-10-03', ETA_PLAN: '2019-07-02', ETA_ACTUAL: '2019-11-05', ROS: '2019-07-09', INSTALL: '2019-12-24', UP_BLOCK_PE: '', ROS_ETA: -119, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 13,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MJ007', BLOCK: 'L510H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'PBE-802103',      TAG_DESCR: 'Seawater Lift Pump (Cooling Water)', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-08', FAT_ACTUAL: '2019-10-03', ETA_PLAN: '2019-07-02', ETA_ACTUAL: '2019-11-05', ROS: '2019-07-09', INSTALL: '2019-12-23', UP_BLOCK_PE: '', ROS_ETA: -119, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 14,  MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'XHHE-6130-2011',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 15,  MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'XHHE-6130-3451',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 16,  MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'XHHE-6130-3152',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 17,  MODULE: 'N10UH', DISC: 'EI', PKG_NO: 'EL007', BLOCK: 'L510H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'VMCS-682055',     TAG_DESCR: '480V Motor Control Center Essential MCC2B', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-10', ETA_ACTUAL: '2019-05-03', ROS: '2019-04-03', INSTALL: '2019-06-03', UP_BLOCK_PE: '2019-04-26', ROS_ETA: -30, ROS_ETA_UPBLOCK: -7, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 18,  MODULE: 'N10UH', DISC: 'EI', PKG_NO: 'EL007', BLOCK: 'L510H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'VMCN-874055',     TAG_DESCR: '480V Motor Control Center Normal MCC2B', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-10', ETA_ACTUAL: '2019-05-03', ROS: '2019-04-03', INSTALL: '2019-06-03', UP_BLOCK_PE: '2019-04-26', ROS_ETA: -30, ROS_ETA_UPBLOCK: -7, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 19,  MODULE: 'N10UH', DISC: 'EI', PKG_NO: 'EL007', BLOCK: 'L510H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'VMCS-682062',     TAG_DESCR: '480V Motor Control Center Asd Essential', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-10', ETA_ACTUAL: '2019-05-03', ROS: '2019-04-03', INSTALL: '2019-06-03', UP_BLOCK_PE: '2019-04-26', ROS_ETA: -30, ROS_ETA_UPBLOCK: -7, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 20,  MODULE: 'N10UH', DISC: 'EI', PKG_NO: 'EL007', BLOCK: 'L510H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'VMCN-874052',     TAG_DESCR: '480V Motor Control Center Normal MCC2A', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-10', ETA_ACTUAL: '2019-05-03', ROS: '2019-04-03', INSTALL: '2019-06-03', UP_BLOCK_PE: '2019-04-26', ROS_ETA: -30, ROS_ETA_UPBLOCK: -7, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 21,  MODULE: 'N10UH', DISC: 'EI', PKG_NO: 'EL007', BLOCK: 'L510H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'VMCS-682059',     TAG_DESCR: '480V Motor Control Center Asd Essential', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-10', ETA_ACTUAL: '2019-05-03', ROS: '2019-04-03', INSTALL: '2019-06-03', UP_BLOCK_PE: '2019-04-26', ROS_ETA: -30, ROS_ETA_UPBLOCK: -7, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 22,  MODULE: 'N10UH', DISC: 'EI', PKG_NO: 'EL007', BLOCK: 'L510H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'VMCS-682052',     TAG_DESCR: '480V Motor Control Center Essential MCC2A', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-10', ETA_ACTUAL: '2019-05-03', ROS: '2019-04-03', INSTALL: '2019-06-03', UP_BLOCK_PE: '2019-04-26', ROS_ETA: -30, ROS_ETA_UPBLOCK: -7, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 23,  MODULE: 'N10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L610H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'HCU-992181',      TAG_DESCR: 'LV MCC Room, U1213', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-04-16', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-05-24', ROS: '2019-04-16', INSTALL: '2019-06-11', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '266', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 24,  MODULE: 'N10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L610H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'HCU-992180',      TAG_DESCR: 'LV MCC Room, U1213', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-04-16', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-05-24', ROS: '2019-04-16', INSTALL: '2019-06-11', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '266', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 25,  MODULE: 'N10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L610H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'HAU-992210',      TAG_DESCR: 'Supply Northwest Area', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-13', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-08-09', ROS: '2019-04-11', INSTALL: '2019-06-05', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '189', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 26,  MODULE: 'N10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L610H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'HEF-992250',      TAG_DESCR: 'Extract Fan NW. Cellar & Tween Deck', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-28', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-06-07', ROS: '2019-04-11', INSTALL: '2019-07-24', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '252', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 27,  MODULE: 'N10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L610H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'HEF-992150',      TAG_DESCR: 'Extract Fan NW. Cellar & Tween Deck', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-06-11', ROS: '2019-04-24', INSTALL: '2019-07-24', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '248', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 28,  MODULE: 'N10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L610H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'HAU-992110',      TAG_DESCR: 'Supply Northwest Area', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-21', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-08-09', ROS: '2019-04-11', INSTALL: '2019-06-05', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '189', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 29,  MODULE: 'N10UH', DISC: 'EI', PKG_NO: 'SE005', BLOCK: 'L510H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'PNL-6160315',     TAG_DESCR: 'Fusible Loop Charge Panel (NW)', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-08', ETA_ACTUAL: '2019-03-22', ROS: '2019-04-03', INSTALL: '', UP_BLOCK_PE: '2019-04-23', ROS_ETA: 12, ROS_ETA_UPBLOCK: 32, CANBE_INST: '329', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 30,  MODULE: 'N10UH', DISC: 'PI', PKG_NO: 'IC006', BLOCK: 'L520H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'XSV-10200201',    TAG_DESCR: 'Boarding Valve', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-29', ETA_ACTUAL: '2019-10-15', ROS: '2019-04-19', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: -179, ROS_ETA_UPBLOCK: null, CANBE_INST: '122', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 31,  MODULE: 'N10UH', DISC: 'PI', PKG_NO: 'IC006', BLOCK: 'L520H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'BSDV-10200202',   TAG_DESCR: 'Boarding Valve', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-29', ETA_ACTUAL: '2019-11-15', ROS: '2019-04-19', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: -210, ROS_ETA_UPBLOCK: null, CANBE_INST: '91', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 32,  MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'SPHR-8012487',    TAG_DESCR: 'SPHR-6130 Water Only Hose Reel', DWG: null, PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 33,  MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'SPSE-6413-801',   TAG_DESCR: 'Safety Shower and Eyewash', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-15', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 34,  MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'SPHR-8012492',    TAG_DESCR: 'SPHR-6130 Water Only Hose Reel', DWG: null, PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 35,  MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'SPHR-8110261',    TAG_DESCR: 'SPHR-6130 Water Only Hose Reel', DWG: null, PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 36,  MODULE: 'N10UH', DISC: 'EI', PKG_NO: 'IC008', BLOCK: 'L620H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'PNL-6010311',     TAG_DESCR: 'Pressure Safety Test Panel', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-03', ETA_ACTUAL: '2019-10-15', ROS: '2019-03-28', INSTALL: '', UP_BLOCK_PE: '2019-04-22', ROS_ETA: -201, ROS_ETA_UPBLOCK: -176, CANBE_INST: '122', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 37,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MJ013', BLOCK: 'L520H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'ZZZ-815000',      TAG_DESCR: 'Nitrogen Generation Skid', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '2017-01-13', PO_ACTUAL: '2017-01-13', FAT_PLAN: '2019-01-23', FAT_ACTUAL: '2019-05-23', ETA_PLAN: '2019-03-27', ETA_ACTUAL: '2019-07-12', ROS: '2019-04-03', INSTALL: '2019-07-25', UP_BLOCK_PE: '2019-04-23', ROS_ETA: -100, ROS_ETA_UPBLOCK: -80, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 38,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MJ011', BLOCK: 'L520H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'PBE-801636',      TAG_DESCR: 'Deluge System Rinse Pump', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '2017-08-17', PO_ACTUAL: '2017-08-17', FAT_PLAN: '2019-01-18', FAT_ACTUAL: '2019-02-12', ETA_PLAN: '2019-03-29', ETA_ACTUAL: '2019-06-18', ROS: '2019-04-03', INSTALL: '2019-06-24', UP_BLOCK_PE: '2019-04-23', ROS_ETA: -76, ROS_ETA_UPBLOCK: -56, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 39,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MJ011', BLOCK: 'L520H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'PBE-801635',      TAG_DESCR: 'Deluge System Rinse Pump', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '2017-08-17', PO_ACTUAL: '2017-08-17', FAT_PLAN: '2019-01-18', FAT_ACTUAL: '2019-02-12', ETA_PLAN: '2019-03-29', ETA_ACTUAL: '2019-06-18', ROS: '2019-04-03', INSTALL: '2019-06-24', UP_BLOCK_PE: '2019-04-23', ROS_ETA: -76, ROS_ETA_UPBLOCK: -56, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 40,  MODULE: 'N10UH', DISC: 'PI', PKG_NO: 'IC006', BLOCK: 'L520H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'BSDV-10100203',   TAG_DESCR: 'Boarding Valve', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-29', ETA_ACTUAL: '2019-09-10', ROS: '2019-06-07', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: -95, ROS_ETA_UPBLOCK: null, CANBE_INST: '157', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 41,  MODULE: 'N10UH', DISC: 'PI', PKG_NO: 'IC006', BLOCK: 'L520H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'BSDV-10100103',   TAG_DESCR: 'Boarding Valve', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-29', ETA_ACTUAL: '2019-09-10', ROS: '2019-06-07', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: -95, ROS_ETA_UPBLOCK: null, CANBE_INST: '157', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 42,  MODULE: 'N10UH', DISC: 'PI', PKG_NO: 'IC006', BLOCK: 'L520H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'BSDV-10100102',   TAG_DESCR: 'Boarding Valve', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-29', ETA_ACTUAL: '2019-10-25', ROS: '2019-04-19', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: -185, ROS_ETA_UPBLOCK: null, CANBE_INST: '112', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 43,  MODULE: 'N10UH', DISC: 'PI', PKG_NO: 'IC006', BLOCK: 'L520H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'BSDV-10100202',   TAG_DESCR: 'Boarding Valve', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-29', ETA_ACTUAL: '2019-10-01', ROS: '2019-04-19', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: -165, ROS_ETA_UPBLOCK: null, CANBE_INST: '136', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 44,  MODULE: 'N10UH', DISC: 'PI', PKG_NO: 'IC006', BLOCK: 'L520H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'XSV-10100101',    TAG_DESCR: 'Boarding Valve', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-29', ETA_ACTUAL: '2019-12-13', ROS: '2019-04-19', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: -241, ROS_ETA_UPBLOCK: null, CANBE_INST: '63', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 45,  MODULE: 'N10UH', DISC: 'PI', PKG_NO: 'IC006', BLOCK: 'L520H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'XSV-10100201',    TAG_DESCR: 'Boarding Valve', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-29', ETA_ACTUAL: '2019-10-28', ROS: '2019-04-19', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: -185, ROS_ETA_UPBLOCK: null, CANBE_INST: '109', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 46,  MODULE: 'N10UH', DISC: 'PI', PKG_NO: 'IC006', BLOCK: 'L520H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'GLSDV-34110101',  TAG_DESCR: 'Boarding Valve', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-29', ETA_ACTUAL: '', ROS: '2019-05-29', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: -276, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'NOT PO', },
      { AREA: 'Deckbox', SN: 47,  MODULE: 'N10UH', DISC: 'PI', PKG_NO: 'IC006', BLOCK: 'L620H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'BSDV-10200103',   TAG_DESCR: 'Boarding Valve', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-29', ETA_ACTUAL: '2019-09-10', ROS: '2019-06-07', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: -95, ROS_ETA_UPBLOCK: null, CANBE_INST: '157', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 48,  MODULE: 'N10UH', DISC: 'PI', PKG_NO: 'IC006', BLOCK: 'L620H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'BSDV-10200102',   TAG_DESCR: 'Boarding Valve', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-29', ETA_ACTUAL: '2019-12-13', ROS: '2019-04-19', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: -241, ROS_ETA_UPBLOCK: null, CANBE_INST: '63', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 49,  MODULE: 'N10UH', DISC: 'PI', PKG_NO: 'IC006', BLOCK: 'L620H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'XSV-10200101',    TAG_DESCR: 'Boarding Valve', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-29', ETA_ACTUAL: '2019-11-15', ROS: '2019-04-19', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: -210, ROS_ETA_UPBLOCK: null, CANBE_INST: '91', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 50,  MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'SPHR-8012489',    TAG_DESCR: 'SPHR-6130 Water Only Hose Reel', DWG: null, PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 51,  MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'SPHR-8110260',    TAG_DESCR: 'SPHR-6130 Water Only Hose Reel', DWG: null, PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 52,  MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'SPHR-8012491',    TAG_DESCR: 'SPHR-6130 Water Only Hose Reel', DWG: null, PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 53,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MD006', BLOCK: 'L450H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'MBJ-815008',      TAG_DESCR: 'LP Nitrogen Receiver', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-08-22', PO_ACTUAL: '2017-08-24', FAT_PLAN: '2018-07-23', FAT_ACTUAL: '2018-07-23', ETA_PLAN: '2019-04-07', ETA_ACTUAL: '2019-02-20', ROS: '2019-04-11', INSTALL: '2019-06-25', UP_BLOCK_PE: '2019-05-09', ROS_ETA: 50, ROS_ETA_UPBLOCK: 78, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 54,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MU020', BLOCK: 'L530H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'PBE-433015',      TAG_DESCR: 'Open Drain Sump Pumps', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-08-02', PO_ACTUAL: '2017-08-02', FAT_PLAN: '2018-08-16', FAT_ACTUAL: '2018-09-07', ETA_PLAN: '2018-10-30', ETA_ACTUAL: '2018-10-30', ROS: '2019-04-08', INSTALL: '2019-04-30', UP_BLOCK_PE: '2019-04-24', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 55,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MU020', BLOCK: 'L530H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'PBE-433014',      TAG_DESCR: 'Open Drain Sump Pumps', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-08-02', PO_ACTUAL: '2017-08-02', FAT_PLAN: '2018-08-16', FAT_ACTUAL: '2018-09-07', ETA_PLAN: '2018-10-30', ETA_ACTUAL: '2018-10-30', ROS: '2019-04-08', INSTALL: '2019-04-12', UP_BLOCK_PE: '2019-04-24', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 56,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MJ011', BLOCK: 'L630H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'PBA-434006',      TAG_DESCR: 'Closed Drain Drum Water Pump', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-08-17', PO_ACTUAL: '2017-08-17', FAT_PLAN: '2019-01-18', FAT_ACTUAL: '2019-02-12', ETA_PLAN: '2019-03-29', ETA_ACTUAL: '2019-07-25', ROS: '2019-04-01', INSTALL: '2019-07-27', UP_BLOCK_PE: '2019-04-03', ROS_ETA: -115, ROS_ETA_UPBLOCK: -113, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 57,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MJ011', BLOCK: 'L630H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'PBA-434005',      TAG_DESCR: 'Closed Drain Drum Water Pump', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-08-17', PO_ACTUAL: '2017-08-17', FAT_PLAN: '2019-01-18', FAT_ACTUAL: '2019-02-12', ETA_PLAN: '2019-03-29', ETA_ACTUAL: '2019-07-25', ROS: '2019-04-01', INSTALL: '2019-07-27', UP_BLOCK_PE: '2019-04-03', ROS_ETA: -115, ROS_ETA_UPBLOCK: -113, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 58,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MU001', BLOCK: 'L620H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'MAJ-851001',      TAG_DESCR: 'Bulk Methanol Guard Filter', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-09-28', PO_ACTUAL: '2017-09-28', FAT_PLAN: '2019-01-10', FAT_ACTUAL: '2019-03-14', ETA_PLAN: '2019-04-04', ETA_ACTUAL: '2019-04-23', ROS: '2019-04-04', INSTALL: '2019-04-25', UP_BLOCK_PE: '2019-04-22', ROS_ETA: -19, ROS_ETA_UPBLOCK: -1, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 59,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MD006', BLOCK: 'L530H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'MBJ-815007',      TAG_DESCR: 'HP Nitrogen Receiver', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-08-22', PO_ACTUAL: '2017-08-24', FAT_PLAN: '2018-07-23', FAT_ACTUAL: '2018-07-27', ETA_PLAN: '2019-04-07', ETA_ACTUAL: '2019-02-20', ROS: '2019-04-08', INSTALL: '2019-04-10', UP_BLOCK_PE: '2019-04-24', ROS_ETA: 47, ROS_ETA_UPBLOCK: 63, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 60,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MC005', BLOCK: 'L530H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'ZAP-434007',      TAG_DESCR: 'Closed Drain Drum Immersion Heater', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-09-21', PO_ACTUAL: '2017-09-21', FAT_PLAN: '2018-09-26', FAT_ACTUAL: '2019-01-07', ETA_PLAN: '2019-04-01', ETA_ACTUAL: '2019-04-19', ROS: '2019-04-08', INSTALL: '2019-07-29', UP_BLOCK_PE: '2019-04-24', ROS_ETA: -11, ROS_ETA_UPBLOCK: 5, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 61,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MD006', BLOCK: 'L530H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'NBD-434002',      TAG_DESCR: 'Closed Drain Drum', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-08-22', PO_ACTUAL: '2017-08-24', FAT_PLAN: '2018-07-23', FAT_ACTUAL: '2018-07-27', ETA_PLAN: '2019-04-07', ETA_ACTUAL: '2019-04-23', ROS: '2019-04-08', INSTALL: '2019-05-04', UP_BLOCK_PE: '2019-04-24', ROS_ETA: -15, ROS_ETA_UPBLOCK: 1, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 62,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MU022', BLOCK: 'L530H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'ZZZ-191050',      TAG_DESCR: 'Subsea HP Accumulator', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-07-28', PO_ACTUAL: '2017-07-28', FAT_PLAN: '2019-02-20', FAT_ACTUAL: '2019-04-30', ETA_PLAN: '2019-05-11', ETA_ACTUAL: '2019-07-19', ROS: '2019-04-08', INSTALL: '2019-07-29', UP_BLOCK_PE: '2019-04-24', ROS_ETA: -102, ROS_ETA_UPBLOCK: -86, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 63,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MJ010', BLOCK: 'L530H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'PBA-212003',      TAG_DESCR: 'Wet Oil/Well Flowback Tank Pump', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-10-16', PO_ACTUAL: '2017-10-16', FAT_PLAN: '2019-01-28', FAT_ACTUAL: '2019-02-07', ETA_PLAN: '2019-04-01', ETA_ACTUAL: '2019-07-22', ROS: '2019-04-08', INSTALL: '2019-07-28', UP_BLOCK_PE: '2019-04-24', ROS_ETA: -105, ROS_ETA_UPBLOCK: -89, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 64,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MJ010', BLOCK: 'L530H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'PBA-212004',      TAG_DESCR: 'Wet Oil/Well Flowback Tank Pump', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-10-16', PO_ACTUAL: '2017-10-16', FAT_PLAN: '2019-01-28', FAT_ACTUAL: '2019-02-08', ETA_PLAN: '2019-04-01', ETA_ACTUAL: '2019-07-22', ROS: '2019-04-08', INSTALL: '2019-07-28', UP_BLOCK_PE: '2019-04-24', ROS_ETA: -105, ROS_ETA_UPBLOCK: -89, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 65,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MJ012', BLOCK: 'L630H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'PBA-434003',      TAG_DESCR: 'Closed Drain Drum Oil Pump', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-08-25', PO_ACTUAL: '2017-08-25', FAT_PLAN: '2019-03-29', FAT_ACTUAL: '2019-06-18', ETA_PLAN: '2019-05-31', ETA_ACTUAL: '2019-07-29', ROS: '2019-04-01', INSTALL: '2019-07-30', UP_BLOCK_PE: '2019-04-03', ROS_ETA: -119, ROS_ETA_UPBLOCK: -117, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 66,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MJ012', BLOCK: 'L630H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'PBA-434004',      TAG_DESCR: 'Closed Drain Drum Oil Pump', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-08-25', PO_ACTUAL: '2017-08-25', FAT_PLAN: '2019-03-29', FAT_ACTUAL: '2019-06-18', ETA_PLAN: '2019-05-31', ETA_ACTUAL: '2019-07-29', ROS: '2019-04-01', INSTALL: '2019-07-30', UP_BLOCK_PE: '2019-04-03', ROS_ETA: -119, ROS_ETA_UPBLOCK: -117, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 67,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MD003', BLOCK: 'L630H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'MBJ-851002',      TAG_DESCR: 'Bulk Methanol Storage Tank', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-06-15', PO_ACTUAL: '2017-06-16', FAT_PLAN: '2018-10-11', FAT_ACTUAL: '2018-10-11', ETA_PLAN: '2019-03-22', ETA_ACTUAL: '2019-03-12', ROS: '2019-03-29', INSTALL: '2019-05-13', UP_BLOCK_PE: '2019-04-03', ROS_ETA: 17, ROS_ETA_UPBLOCK: 22, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 68,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MC005', BLOCK: 'L530H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'ZAP-212002',      TAG_DESCR: 'Wet Oil/Well Flowback Tank Heater', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-09-21', PO_ACTUAL: '2017-09-21', FAT_PLAN: '2018-09-26', FAT_ACTUAL: '2019-01-07', ETA_PLAN: '2019-04-01', ETA_ACTUAL: '2019-04-19', ROS: '2019-04-08', INSTALL: '2019-07-29', UP_BLOCK_PE: '2019-04-24', ROS_ETA: -11, ROS_ETA_UPBLOCK: 5, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 69,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MD006', BLOCK: 'L530H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'NBJ-212001',      TAG_DESCR: 'Wet Oil/Well Flowback Tank', DWG: 'GM004-PI-LAY-241-30005-001', PO_PLAN: '2017-08-22', PO_ACTUAL: '2017-08-24', FAT_PLAN: '2018-07-23', FAT_ACTUAL: '2018-07-26', ETA_PLAN: '2019-04-07', ETA_ACTUAL: '2019-04-23', ROS: '2019-04-08', INSTALL: '2019-05-04', UP_BLOCK_PE: '2019-04-24', ROS_ETA: -15, ROS_ETA_UPBLOCK: 1, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 70,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'ML008', BLOCK: 'L530H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'ZZZ-851000',      TAG_DESCR: 'Methanol Injection Skid', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-09-11', FAT_ACTUAL: '2018-09-05', ETA_PLAN: '2019-04-01', ETA_ACTUAL: '2019-03-24', ROS: '2019-04-08', INSTALL: '2019-05-03', UP_BLOCK_PE: '2019-04-24', ROS_ETA: 15, ROS_ETA_UPBLOCK: 31, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 71,  MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'SPSE-6413-803',   TAG_DESCR: 'Safety Shower and Eyewash', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-15', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 72,  MODULE: 'N10UH', DISC: 'EI', PKG_NO: 'IC008', BLOCK: 'L630H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'PNL-6010321',     TAG_DESCR: 'Pressure Safety Test Panel', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-03', ETA_ACTUAL: '2019-08-12', ROS: '2019-03-29', INSTALL: '', UP_BLOCK_PE: '2019-04-19', ROS_ETA: -136, ROS_ETA_UPBLOCK: -115, CANBE_INST: '186', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 73,  MODULE: 'N10UH', DISC: 'EI', PKG_NO: 'IC008', BLOCK: 'L630H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'PNL-6010320',     TAG_DESCR: 'Pressure Safety Test Panel', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-03', ETA_ACTUAL: '2019-08-12', ROS: '2019-03-29', INSTALL: '', UP_BLOCK_PE: '2019-04-19', ROS_ETA: -136, ROS_ETA_UPBLOCK: -115, CANBE_INST: '186', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 74,  MODULE: 'N10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L640H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'HEF-992350',      TAG_DESCR: 'Extract Fan Tween Deck NE', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-29', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-10-29', ROS: '2019-04-15', INSTALL: '2019-07-25', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '108', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 75,  MODULE: 'N10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L640H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'HEF-992450',      TAG_DESCR: 'Extract Fan NE. Cellar & Tween Deck', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-27', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-06-04', ROS: '2019-04-15', INSTALL: '2019-07-25', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '255', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 76,  MODULE: 'N10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L640H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'HAU-992310',      TAG_DESCR: 'Supply Northeast Area', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-17', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-08-01', ROS: '2019-04-15', INSTALL: '2019-06-04', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '197', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 77,  MODULE: 'N10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L640H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'HAU-992410',      TAG_DESCR: 'Supply Northeast Area', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-15', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-07-29', ROS: '2019-04-15', INSTALL: '2019-06-04', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '200', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 78,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MU001', BLOCK: 'L640H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'MAJ-431001',      TAG_DESCR: 'NE Hazardous Open Drain Filter', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-09-28', PO_ACTUAL: '2017-09-28', FAT_PLAN: '2019-01-10', FAT_ACTUAL: '2019-06-19', ETA_PLAN: '2019-04-04', ETA_ACTUAL: '2019-08-14', ROS: '2019-04-04', INSTALL: '2019-08-17', UP_BLOCK_PE: '2019-05-07', ROS_ETA: -132, ROS_ETA_UPBLOCK: -99, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 79,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MU001', BLOCK: 'L640H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'MAJ-431002',      TAG_DESCR: 'NE Hazardous Open Drain Filter', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-09-28', PO_ACTUAL: '2017-09-28', FAT_PLAN: '2019-01-10', FAT_ACTUAL: '2019-06-19', ETA_PLAN: '2019-04-04', ETA_ACTUAL: '2019-08-14', ROS: '2019-04-07', INSTALL: '2019-08-17', UP_BLOCK_PE: '2019-05-07', ROS_ETA: -129, ROS_ETA_UPBLOCK: -99, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 80,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MU001', BLOCK: 'L640H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'MAJ-432001',      TAG_DESCR: 'NE Non-Hazardous Open Drain Filter', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-09-28', PO_ACTUAL: '2017-09-28', FAT_PLAN: '2019-01-10', FAT_ACTUAL: '2019-06-19', ETA_PLAN: '2019-04-04', ETA_ACTUAL: '2019-08-14', ROS: '2019-04-07', INSTALL: '2019-08-17', UP_BLOCK_PE: '2019-05-07', ROS_ETA: -129, ROS_ETA_UPBLOCK: -99, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 81,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MU001', BLOCK: 'L640H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'MAJ-432002',      TAG_DESCR: 'NE Non-Hazardous Open Drain Filter', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-09-28', PO_ACTUAL: '2017-09-28', FAT_PLAN: '2019-01-10', FAT_ACTUAL: '2019-06-19', ETA_PLAN: '2019-04-04', ETA_ACTUAL: '2019-08-14', ROS: '2019-04-07', INSTALL: '2019-09-24', UP_BLOCK_PE: '2019-05-07', ROS_ETA: -129, ROS_ETA_UPBLOCK: -99, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 82,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MU006', BLOCK: 'N10UH',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'TCN-433003',      TAG_DESCR: 'NE Disposal Caisson', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-07-24', PO_ACTUAL: '2017-07-24', FAT_PLAN: '2019-04-23', FAT_ACTUAL: '2018-12-10', ETA_PLAN: '2019-06-11', ETA_ACTUAL: '2019-05-23', ROS: '2019-06-18', INSTALL: '2019-08-04', UP_BLOCK_PE: '', ROS_ETA: 26, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 83,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MU006', BLOCK: 'Caisson',       DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'PBE-433031',      TAG_DESCR: 'NE Disposal Bucket Pump', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-07-24', PO_ACTUAL: '2017-07-24', FAT_PLAN: '2019-04-23', FAT_ACTUAL: '2018-12-10', ETA_PLAN: '2019-06-11', ETA_ACTUAL: '2019-07-25', ROS: '2019-07-09', INSTALL: '2019-11-15', UP_BLOCK_PE: '', ROS_ETA: -16, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 84,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MU006', BLOCK: 'Caisson',       DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'PBE-433030',      TAG_DESCR: 'NE Disposal Caisson Oil Bucket Pump - Quiet Chamber', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-07-24', PO_ACTUAL: '2017-07-24', FAT_PLAN: '2019-04-23', FAT_ACTUAL: '2018-12-10', ETA_PLAN: '2019-06-11', ETA_ACTUAL: '2019-07-25', ROS: '2019-07-09', INSTALL: '2019-11-14', UP_BLOCK_PE: '', ROS_ETA: -16, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 85,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MU006', BLOCK: 'Caisson',       DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'PBE-433021',      TAG_DESCR: 'NE Disposal Caisson Oil Bucket Pump - Sump Area', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-07-24', PO_ACTUAL: '2017-07-24', FAT_PLAN: '2019-04-23', FAT_ACTUAL: '2018-12-10', ETA_PLAN: '2019-06-11', ETA_ACTUAL: '2019-07-25', ROS: '2019-07-09', INSTALL: '2019-11-13', UP_BLOCK_PE: '', ROS_ETA: -16, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 86,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MU006', BLOCK: 'Caisson',       DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'PBE-433020',      TAG_DESCR: 'NE Disposal Caisson Oil Bucket Pump - Sump Area', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-07-24', PO_ACTUAL: '2017-07-24', FAT_PLAN: '2019-04-23', FAT_ACTUAL: '2018-12-10', ETA_PLAN: '2019-06-11', ETA_ACTUAL: '2019-07-25', ROS: '2019-07-09', INSTALL: '2019-11-12', UP_BLOCK_PE: '', ROS_ETA: -16, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 87,  MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'XHHE-6130-8120',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 88,  MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'XHHE-6130-2021',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 89,  MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'XHHE-6130-3501',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 90,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MU016', BLOCK: 'L640H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'ZZZ-991275',      TAG_DESCR: 'Solenoid Cabinet', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-07-28', PO_ACTUAL: '2017-07-28', FAT_PLAN: '2019-02-15', FAT_ACTUAL: '2019-02-22', ETA_PLAN: '2019-04-30', ETA_ACTUAL: '2019-05-16', ROS: '2019-04-11', INSTALL: '2019-05-22', UP_BLOCK_PE: '2019-04-29', ROS_ETA: -35, ROS_ETA_UPBLOCK: -17, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 91,  MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'SPHR-6130-804',   TAG_DESCR: 'SPHR-6130 Water Only Hose Reel', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 92,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MU020', BLOCK: 'L540H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'PBE-802108',      TAG_DESCR: 'Seawater Hydrocarbon Pump', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-08-02', PO_ACTUAL: '2017-08-02', FAT_PLAN: '2018-08-16', FAT_ACTUAL: '2018-09-07', ETA_PLAN: '2018-10-30', ETA_ACTUAL: '2018-10-30', ROS: '2019-04-12', INSTALL: '2019-04-12', UP_BLOCK_PE: '2019-05-08', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 93,  MODULE: 'N10UH', DISC: 'EI', PKG_NO: 'EL007', BLOCK: 'L540H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'VMCS-682061',     TAG_DESCR: '480V Motor Control Center Asd Essential', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-10', ETA_ACTUAL: '2019-05-03', ROS: '2019-04-05', INSTALL: '2019-05-31', UP_BLOCK_PE: '2019-04-30', ROS_ETA: -28, ROS_ETA_UPBLOCK: -3, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 94,  MODULE: 'N10UH', DISC: 'EI', PKG_NO: 'EL007', BLOCK: 'L540H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'VMCS-682058',     TAG_DESCR: '480V Motor Control Center Asd Essential', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-10', ETA_ACTUAL: '2019-05-03', ROS: '2019-04-05', INSTALL: '2019-05-31', UP_BLOCK_PE: '2019-04-30', ROS_ETA: -28, ROS_ETA_UPBLOCK: -3, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 95,  MODULE: 'N10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L540H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'HCU-992381',      TAG_DESCR: 'LV MCC Room, U2219', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-04-16', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-05-24', ROS: '2019-04-16', INSTALL: '2019-05-31', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '266', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 96,  MODULE: 'N10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L540H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'HCU-992380',      TAG_DESCR: 'LV MCC Room, U2219', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-04-16', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-05-24', ROS: '2019-04-16', INSTALL: '2019-05-31', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '266', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 97,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MU016', BLOCK: 'L640H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'ZZZ-991210',      TAG_DESCR: 'HPU Skid', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-07-28', PO_ACTUAL: '2017-07-28', FAT_PLAN: '2019-02-15', FAT_ACTUAL: '2019-02-22', ETA_PLAN: '2019-04-30', ETA_ACTUAL: '2019-05-16', ROS: '2019-04-11', INSTALL: '2019-05-22', UP_BLOCK_PE: '2019-04-29', ROS_ETA: -35, ROS_ETA_UPBLOCK: -17, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 98,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MU016', BLOCK: 'L640H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'ZZZ-991270',      TAG_DESCR: 'Solenoid Cabinet', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-07-28', PO_ACTUAL: '2017-07-28', FAT_PLAN: '2019-02-15', FAT_ACTUAL: '2019-02-22', ETA_PLAN: '2019-04-30', ETA_ACTUAL: '2019-05-16', ROS: '2019-04-11', INSTALL: '2019-05-22', UP_BLOCK_PE: '2019-04-29', ROS_ETA: -35, ROS_ETA_UPBLOCK: -17, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 99,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MD006', BLOCK: 'L540H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'TCN-802107',      TAG_DESCR: 'Seawater Hydrocarbon Separator', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '2017-08-22', PO_ACTUAL: '2017-08-24', FAT_PLAN: '2018-07-23', FAT_ACTUAL: '2018-07-23', ETA_PLAN: '2019-04-07', ETA_ACTUAL: '2019-05-07', ROS: '2019-04-12', INSTALL: '2019-09-30', UP_BLOCK_PE: '2019-05-08', ROS_ETA: -25, ROS_ETA_UPBLOCK: 1, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 100, MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'SPHR-6130-805',   TAG_DESCR: 'SPHR-6130 Water Only Hose Reel', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 101, MODULE: 'N10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L540H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'HCN-892280',      TAG_DESCR: 'HVAC MCC Room(NE) Cooling', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-14', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-12-31', ROS: '2019-04-16', INSTALL: '2019-12-11', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '45', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 102, MODULE: 'N10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'L640H',         DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'HCN-892281',      TAG_DESCR: 'HVAC MCC Room(NE) Cooling', DWG: 'GM004-PI-LAY-241-30001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-17', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-12-30', ROS: '2019-04-16', INSTALL: '2019-12-10', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '46', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 103, MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'US-310-1',        TAG_DESCR: 'Utility Station', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-10-30', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 104, MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'US-310-6',        TAG_DESCR: 'Utility Station', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-10-30', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 105, MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'US-310-3',        TAG_DESCR: 'Utility Station', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-10-30', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 106, MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'CELLAR DECK',  SVG: 'N10UH_L1', TAG_NO: 'US-310-5',        TAG_DESCR: 'Utility Station', DWG: 'GM004-PI-LAY-241-30002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-10-30', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 1,   MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'LCN-934540',      TAG_DESCR: 'Platform Chain Segment', DWG: 'GM004-PI-LAY-241-30006-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-10-15', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 2,   MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MR003', BLOCK: 'T710H',         DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'LMM-934141',      TAG_DESCR: 'Chain Locker Weathertight Closure', DWG: 'GM004-PI-LAY-241-30006-001', PO_PLAN: '2018-02-14', PO_ACTUAL: '2018-02-14', FAT_PLAN: '2019-02-03', FAT_ACTUAL: '2018-12-17', ETA_PLAN: '2019-04-25', ETA_ACTUAL: '2019-06-28', ROS: '2019-05-02', INSTALL: '2019-07-25', UP_BLOCK_PE: '', ROS_ETA: -57, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 3,   MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MR001', BLOCK: 'E310H',         DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'LCJ-934101',      TAG_DESCR: 'Mooring Chain Jack NW', DWG: 'GM004-PI-LAY-241-30006-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-06', FAT_ACTUAL: '2019-01-08', ETA_PLAN: '2019-04-15', ETA_ACTUAL: '2019-06-07', ROS: '2019-05-02', INSTALL: '2019-07-12', UP_BLOCK_PE: '2019-11-21', ROS_ETA: -36, ROS_ETA_UPBLOCK: 167, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 4,   MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MR001', BLOCK: 'T710H',         DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'LTS-934101',      TAG_DESCR: 'Turndown Sheave Assembly, 1 NW', DWG: 'GM004-PI-LAY-241-30006-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-06', FAT_ACTUAL: '2018-11-13', ETA_PLAN: '2019-04-15', ETA_ACTUAL: '2019-04-18', ROS: '2019-05-02', INSTALL: '2019-07-11', UP_BLOCK_PE: '2019-11-21', ROS_ETA: 14, ROS_ETA_UPBLOCK: 217, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 5,   MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'LCN-934541',      TAG_DESCR: 'Platform Chain Segment', DWG: 'GM004-PI-LAY-241-30006-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-10-16', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 6,   MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MR003', BLOCK: 'T710H',         DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'LMM-934142',      TAG_DESCR: 'Chain Locker Weathertight Closure', DWG: 'GM004-PI-LAY-241-30006-001', PO_PLAN: '2018-02-14', PO_ACTUAL: '2018-02-14', FAT_PLAN: '2019-02-03', FAT_ACTUAL: '2018-12-17', ETA_PLAN: '2019-04-25', ETA_ACTUAL: '2019-06-28', ROS: '2019-05-02', INSTALL: '2019-07-25', UP_BLOCK_PE: '', ROS_ETA: -57, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 7,   MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MR001', BLOCK: 'E310H',         DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'LCJ-934102',      TAG_DESCR: 'Mooring Chain Jack NW', DWG: 'GM004-PI-LAY-241-30006-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-06', FAT_ACTUAL: '2019-01-08', ETA_PLAN: '2019-04-15', ETA_ACTUAL: '2019-06-07', ROS: '2019-05-02', INSTALL: '2019-07-15', UP_BLOCK_PE: '2019-11-21', ROS_ETA: -36, ROS_ETA_UPBLOCK: 167, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 8,   MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MR001', BLOCK: 'T710H',         DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'LTS-934102',      TAG_DESCR: 'Turndown Sheave Assembly, 2 NW', DWG: 'GM004-PI-LAY-241-30006-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-06', FAT_ACTUAL: '2018-11-13', ETA_PLAN: '2019-04-15', ETA_ACTUAL: '2019-06-14', ROS: '2019-05-02', INSTALL: '2019-07-11', UP_BLOCK_PE: '2019-11-21', ROS_ETA: -43, ROS_ETA_UPBLOCK: 160, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 9,   MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'LCN-934542',      TAG_DESCR: 'Platform Chain Segment', DWG: 'GM004-PI-LAY-241-30006-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-10-16', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 10,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MR003', BLOCK: 'T710H',         DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'LMM-934143',      TAG_DESCR: 'Chain Locker Weathertight Closure', DWG: 'GM004-PI-LAY-241-30006-001', PO_PLAN: '2018-02-14', PO_ACTUAL: '2018-02-14', FAT_PLAN: '2019-02-03', FAT_ACTUAL: '2018-12-17', ETA_PLAN: '2019-04-25', ETA_ACTUAL: '2019-06-28', ROS: '2019-05-02', INSTALL: '2019-07-25', UP_BLOCK_PE: '', ROS_ETA: -57, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 11,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MR001', BLOCK: 'E310H',         DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'LCJ-934103',      TAG_DESCR: 'Mooring Chain Jack NW', DWG: 'GM004-PI-LAY-241-30006-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-06', FAT_ACTUAL: '2018-10-17', ETA_PLAN: '2019-04-15', ETA_ACTUAL: '2019-06-07', ROS: '2019-05-02', INSTALL: '2019-07-15', UP_BLOCK_PE: '2019-11-21', ROS_ETA: -36, ROS_ETA_UPBLOCK: 167, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 12,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MR001', BLOCK: 'T710H',         DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'LTS-934103',      TAG_DESCR: 'Turndown Sheave Assembly, 3 NW', DWG: 'GM004-PI-LAY-241-30006-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-06', FAT_ACTUAL: '2018-11-13', ETA_PLAN: '2019-04-15', ETA_ACTUAL: '2019-06-14', ROS: '2019-05-02', INSTALL: '2019-07-11', UP_BLOCK_PE: '2019-11-21', ROS_ETA: -43, ROS_ETA_UPBLOCK: 160, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 13,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MR001', BLOCK: 'T710H',         DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'ZMM-934160',      TAG_DESCR: 'Mooring Local Control Panel NW', DWG: 'GM004-PI-LAY-241-30006-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-06', FAT_ACTUAL: '2018-11-13', ETA_PLAN: '2019-04-15', ETA_ACTUAL: '2019-06-07', ROS: '2019-05-02', INSTALL: '2020-01-10', UP_BLOCK_PE: '2019-11-21', ROS_ETA: -36, ROS_ETA_UPBLOCK: 167, CANBE_INST: '252', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 14,  MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'SPHR-6130-701',   TAG_DESCR: 'SPHR-6130 Water Only Hose Reel', DWG: 'GM004-PI-LAY-241-30006-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 15,  MODULE: 'N10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'T610H',         DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'HAU-892110',      TAG_DESCR: 'Supply Lower Hull Northwest', DWG: 'GM004-PI-LAY-241-30006-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-06-17', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-10-18', ROS: '2019-04-26', INSTALL: '2019-06-18', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '119', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 16,  MODULE: 'N10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'T610H',         DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'HEF-892155',      TAG_DESCR: 'Extract Fan B Lower Hull Northwest', DWG: 'GM004-PI-LAY-241-30006-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-29', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-07-12', ROS: '2019-04-26', INSTALL: '2019-07-25', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '217', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 17,  MODULE: 'N10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'T610H',         DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'HEF-892150',      TAG_DESCR: 'Extract Fan A Lower Hull Northwest', DWG: 'GM004-PI-LAY-241-30006-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-29', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-07-12', ROS: '2019-05-09', INSTALL: '2019-07-25', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '217', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 18,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MR001', BLOCK: 'T710H',         DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'ZMM-934150',      TAG_DESCR: 'Mooring HPU NW', DWG: 'GM004-PI-LAY-241-30006-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-06', FAT_ACTUAL: '2019-06-10', ETA_PLAN: '2019-04-15', ETA_ACTUAL: '2019-06-25', ROS: '2019-05-02', INSTALL: '2019-07-05', UP_BLOCK_PE: '2019-11-21', ROS_ETA: -54, ROS_ETA_UPBLOCK: 149, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 19,  MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'XHHE-6130-3151',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30006-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 20,  MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'XHHE-6130-3131',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30006-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 21,  MODULE: 'N10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'T610H',         DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'HCU-992192',      TAG_DESCR: 'LER, U1305', DWG: 'GM004-PI-LAY-241-30006-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-06-25', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-07-26', ROS: '2019-07-05', INSTALL: '2019-07-29', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '203', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 22,  MODULE: 'N10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'T710H',         DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'HCU-992190',      TAG_DESCR: 'Alt. Incident, U1304', DWG: 'GM004-PI-LAY-241-30006-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-06-25', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-07-26', ROS: '2019-07-05', INSTALL: '2019-07-29', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '203', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 23,  MODULE: 'N10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'T620H',         DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'HCU-992390',      TAG_DESCR: 'LER, U2305', DWG: 'GM004-PI-LAY-241-30005-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-06-25', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-07-26', ROS: '2019-07-05', INSTALL: '2019-07-27', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '203', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 24,  MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'XHHE-6130-3161',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30005-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 25,  MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'SPSE-6413-701',   TAG_DESCR: 'Safety Shower and Eyewash', DWG: 'GM004-PI-LAY-241-30005-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-15', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 26,  MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'XHHE-6130-3171',  TAG_DESCR: 'Fire Water Hose Reel Cabinet', DWG: 'GM004-PI-LAY-241-30005-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 27,  MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'SPHR-6130-702',   TAG_DESCR: 'SPHR-6130 Water Only Hose Reel', DWG: 'GM004-PI-LAY-241-30005-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 28,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MR001', BLOCK: 'T720H',         DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'ZMM-934260',      TAG_DESCR: 'Mooring Local Control Panel NE', DWG: 'GM004-PI-LAY-241-30005-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-06', FAT_ACTUAL: '2018-11-13', ETA_PLAN: '2019-04-15', ETA_ACTUAL: '2019-06-07', ROS: '2019-05-06', INSTALL: '2020-01-17', UP_BLOCK_PE: '2019-11-15', ROS_ETA: -32, ROS_ETA_UPBLOCK: 161, CANBE_INST: '252', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 29,  MODULE: 'N10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'T620H',         DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'HEF-892255',      TAG_DESCR: 'Extract Fan B Lower Hull Northeast', DWG: 'GM004-PI-LAY-241-30005-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-04-15', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-06-04', ROS: '2019-05-14', INSTALL: '2019-07-24', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '255', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 30,  MODULE: 'N10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'T620H',         DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'HEF-892250',      TAG_DESCR: 'Extract Fan A Lower Hull Northeast', DWG: 'GM004-PI-LAY-241-30005-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-04-16', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-06-04', ROS: '2019-05-07', INSTALL: '2019-07-12', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '255', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 31,  MODULE: 'N10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'T620H',         DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'HAU-892210',      TAG_DESCR: 'Supply Lower Hull Northeast', DWG: 'GM004-PI-LAY-241-30005-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-06-18', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-12-27', ROS: '2019-04-24', INSTALL: '2019-06-18', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '49', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 32,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MR001', BLOCK: 'T720H',         DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'ZMM-934250',      TAG_DESCR: 'Mooring HPU NE', DWG: 'GM004-PI-LAY-241-30005-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-06', FAT_ACTUAL: '2019-06-10', ETA_PLAN: '2019-04-15', ETA_ACTUAL: '2019-06-25', ROS: '2019-05-06', INSTALL: '2019-07-05', UP_BLOCK_PE: '2019-11-15', ROS_ETA: -50, ROS_ETA_UPBLOCK: 143, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 33,  MODULE: 'N10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'T620H',         DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'HCN-992391',      TAG_DESCR: 'LER, U2305', DWG: 'GM004-PI-LAY-241-30005-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-06-12', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-12-30', ROS: '2019-04-24', INSTALL: '2019-11-06', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '46', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 34,  MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'LCN-934545',      TAG_DESCR: 'Platform Chain Segment', DWG: 'GM004-PI-LAY-241-30005-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-10-18', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 35,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MR003', BLOCK: 'T720H',         DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'LMM-934243',      TAG_DESCR: 'Chain Locker Weathertight Closure', DWG: 'GM004-PI-LAY-241-30005-001', PO_PLAN: '2018-02-14', PO_ACTUAL: '2018-02-14', FAT_PLAN: '2019-02-03', FAT_ACTUAL: '2018-12-17', ETA_PLAN: '2019-04-25', ETA_ACTUAL: '2019-06-28', ROS: '2019-05-06', INSTALL: '2019-07-25', UP_BLOCK_PE: '', ROS_ETA: -53, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 36,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MR001', BLOCK: 'E410H',         DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'LCJ-934203',      TAG_DESCR: 'Mooring Chain Jack NE', DWG: 'GM004-PI-LAY-241-30005-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-06', FAT_ACTUAL: '2018-11-27', ETA_PLAN: '2019-04-15', ETA_ACTUAL: '2019-06-07', ROS: '2019-05-06', INSTALL: '2019-07-15', UP_BLOCK_PE: '2019-11-15', ROS_ETA: -32, ROS_ETA_UPBLOCK: 161, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 37,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MR001', BLOCK: 'T720H',         DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'LTS-934203',      TAG_DESCR: 'Turndown Sheave Assembly, 6 NE', DWG: 'GM004-PI-LAY-241-30005-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-06', FAT_ACTUAL: '2018-11-13', ETA_PLAN: '2019-04-15', ETA_ACTUAL: '2019-06-14', ROS: '2019-05-06', INSTALL: '2019-07-11', UP_BLOCK_PE: '2019-11-15', ROS_ETA: -39, ROS_ETA_UPBLOCK: 154, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 38,  MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'LCN-934544',      TAG_DESCR: 'Platform Chain Segment', DWG: 'GM004-PI-LAY-241-30005-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-10-17', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 39,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MR003', BLOCK: 'T720H',         DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'LMM-934242',      TAG_DESCR: 'Chain Locker Weathertight Closure', DWG: 'GM004-PI-LAY-241-30005-001', PO_PLAN: '2018-02-14', PO_ACTUAL: '2018-02-14', FAT_PLAN: '2019-02-03', FAT_ACTUAL: '2018-12-17', ETA_PLAN: '2019-04-25', ETA_ACTUAL: '2019-06-28', ROS: '2019-05-06', INSTALL: '2019-07-25', UP_BLOCK_PE: '', ROS_ETA: -53, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 40,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MR001', BLOCK: 'E410H',         DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'LCJ-934202',      TAG_DESCR: 'Mooring Chain Jack NE', DWG: 'GM004-PI-LAY-241-30005-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-06', FAT_ACTUAL: '2018-11-14', ETA_PLAN: '2019-04-15', ETA_ACTUAL: '2019-06-07', ROS: '2019-05-06', INSTALL: '2019-07-15', UP_BLOCK_PE: '2019-11-15', ROS_ETA: -32, ROS_ETA_UPBLOCK: 161, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 41,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MR001', BLOCK: 'T720H',         DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'LTS-934202',      TAG_DESCR: 'Turndown Sheave Assembly, 5 NE', DWG: 'GM004-PI-LAY-241-30005-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-06', FAT_ACTUAL: '2018-11-13', ETA_PLAN: '2019-04-15', ETA_ACTUAL: '2019-06-14', ROS: '2019-05-06', INSTALL: '2019-07-11', UP_BLOCK_PE: '2019-11-15', ROS_ETA: -39, ROS_ETA_UPBLOCK: 154, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 42,  MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'LCN-934543',      TAG_DESCR: 'Platform Chain Segment', DWG: 'GM004-PI-LAY-241-30005-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-10-17', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 43,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MR003', BLOCK: 'T720H',         DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'LMM-934241',      TAG_DESCR: 'Chain Locker Weathertight Closure', DWG: 'GM004-PI-LAY-241-30005-001', PO_PLAN: '2018-02-14', PO_ACTUAL: '2018-02-14', FAT_PLAN: '2019-02-03', FAT_ACTUAL: '2018-12-17', ETA_PLAN: '2019-04-25', ETA_ACTUAL: '2019-06-28', ROS: '2019-05-06', INSTALL: '2019-07-25', UP_BLOCK_PE: '', ROS_ETA: -53, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 44,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MR001', BLOCK: 'E410H',         DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'LCJ-934201',      TAG_DESCR: 'Mooring Chain Jack NE', DWG: 'GM004-PI-LAY-241-30005-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-06', FAT_ACTUAL: '2018-11-27', ETA_PLAN: '2019-04-15', ETA_ACTUAL: '2019-06-07', ROS: '2019-05-06', INSTALL: '2019-07-15', UP_BLOCK_PE: '2019-11-15', ROS_ETA: -32, ROS_ETA_UPBLOCK: 161, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 45,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'MR001', BLOCK: 'T720H',         DECK: 'TWEEN DECK',   SVG: 'N10UH_L2', TAG_NO: 'LTS-934201',      TAG_DESCR: 'Turndown Sheave Assembly, 4 NE', DWG: 'GM004-PI-LAY-241-30005-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2018-11-06', FAT_ACTUAL: '2018-11-13', ETA_PLAN: '2019-04-15', ETA_ACTUAL: '2019-06-14', ROS: '2019-05-06', INSTALL: '2019-07-11', UP_BLOCK_PE: '2019-11-15', ROS_ETA: -39, ROS_ETA_UPBLOCK: 154, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 1,   MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'SE001', BLOCK: 'NP10H',         DECK: 'MAIN DECK',    SVG: 'N10UH_L3', TAG_NO: 'XLL-632010',      TAG_DESCR: 'Lifeboat', DWG: 'GM004-PI-LAY-241-50002-001', PO_PLAN: '2017-09-20', PO_ACTUAL: '2017-09-20', FAT_PLAN: '2018-10-18', FAT_ACTUAL: '2019-11-28', ETA_PLAN: '2019-05-13', ETA_ACTUAL: '', ROS: '2019-11-04', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: -116, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'FAT', },
      { AREA: 'Deckbox', SN: 2,   MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'SE001', BLOCK: 'NP20H',         DECK: 'MAIN DECK',    SVG: 'N10UH_L3', TAG_NO: 'XLL-632020',      TAG_DESCR: 'Lifeboat', DWG: 'GM004-PI-LAY-241-50002-001', PO_PLAN: '2017-09-20', PO_ACTUAL: '2017-09-20', FAT_PLAN: '2018-10-18', FAT_ACTUAL: '2019-11-28', ETA_PLAN: '2019-05-13', ETA_ACTUAL: '', ROS: '2019-11-04', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: -116, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'FAT', },
      { AREA: 'Deckbox', SN: 3,   MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'N10UH_L3', TAG_NO: 'XDB-632011',      TAG_DESCR: 'Survival Craft Davit Winch', DWG: null, PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-07-22', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 4,   MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'N10UH_L3', TAG_NO: 'XDB-632021',      TAG_DESCR: 'Survival Craft Davit Winch', DWG: null, PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-07-30', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 5,   MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'N10UH_L3', TAG_NO: 'SPHR-6130-604',   TAG_DESCR: 'Water Only Hose Reel', DWG: 'GM004-PI-LAY-241-50002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 6,   MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'N10UH_L3', TAG_NO: 'XLB-6460-001',    TAG_DESCR: 'Life Buoy With Light', DWG: 'GM004-PI-LAY-241-50002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 7,   MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'SE001', BLOCK: 'NP20H',         DECK: 'MAIN DECK',    SVG: 'N10UH_L3', TAG_NO: 'VCPS-6320111',    TAG_DESCR: 'Starter Cabinet For Survival Craft Davit', DWG: null, PO_PLAN: '2017-09-20', PO_ACTUAL: '2017-09-20', FAT_PLAN: '2018-10-18', FAT_ACTUAL: '2018-10-18', ETA_PLAN: '2019-05-13', ETA_ACTUAL: '2019-04-08', ROS: '2019-05-20', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: 42, ROS_ETA_UPBLOCK: null, CANBE_INST: '312', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 8,   MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'SE001', BLOCK: 'NP20H',         DECK: 'MAIN DECK',    SVG: 'N10UH_L3', TAG_NO: 'VCPS-6320110',    TAG_DESCR: 'Remote Control For Survival Craft Davit', DWG: null, PO_PLAN: '2017-09-20', PO_ACTUAL: '2017-09-20', FAT_PLAN: '2018-10-18', FAT_ACTUAL: '2018-10-18', ETA_PLAN: '2019-05-13', ETA_ACTUAL: '2019-04-08', ROS: '2019-05-20', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: 42, ROS_ETA_UPBLOCK: null, CANBE_INST: '312', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 9,   MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'SE001', BLOCK: 'NP20H',         DECK: 'MAIN DECK',    SVG: 'N10UH_L3', TAG_NO: 'VCPS-6320210',    TAG_DESCR: 'Remote Control For Survival Craft Davit', DWG: null, PO_PLAN: '2017-09-20', PO_ACTUAL: '2017-09-20', FAT_PLAN: '2018-10-18', FAT_ACTUAL: '2018-10-18', ETA_PLAN: '2019-05-13', ETA_ACTUAL: '2019-04-08', ROS: '2019-05-20', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: 42, ROS_ETA_UPBLOCK: null, CANBE_INST: '312', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 10,  MODULE: 'N10UH', DISC: 'ME', PKG_NO: 'SE001', BLOCK: 'NP20H',         DECK: 'MAIN DECK',    SVG: 'N10UH_L3', TAG_NO: 'VCPS-6320211',    TAG_DESCR: 'Starter Cabinet For Survival Craft Davit', DWG: null, PO_PLAN: '2017-09-20', PO_ACTUAL: '2017-09-20', FAT_PLAN: '2018-10-18', FAT_ACTUAL: '2018-10-18', ETA_PLAN: '2019-05-13', ETA_ACTUAL: '2019-04-08', ROS: '2019-05-20', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: 42, ROS_ETA_UPBLOCK: null, CANBE_INST: '312', STATUS: 'ETA', },
      { AREA: 'Deckbox', SN: 11,  MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'N10UH_L3', TAG_NO: 'XCL-6450-003',    TAG_DESCR: 'Life Preserver Box 30 Units', DWG: 'GM004-PI-LAY-241-50001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 12,  MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'N10UH_L3', TAG_NO: 'XCL-6450-004',    TAG_DESCR: 'Life Preserver Box 30 Units', DWG: 'GM004-PI-LAY-241-50001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 13,  MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'N10UH_L3', TAG_NO: 'SPHR-6130-605',   TAG_DESCR: 'SPHR-6130 Water Only Hose Reel', DWG: 'GM004-PI-LAY-241-50001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 14,  MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'N10UH_L3', TAG_NO: 'XCL-6450-001',    TAG_DESCR: 'Life Preserver Box 30 Units', DWG: 'GM004-PI-LAY-241-50002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 15,  MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'N10UH_L3', TAG_NO: 'XCL-6450-002',    TAG_DESCR: 'Life Preserver Box 30 Units', DWG: 'GM004-PI-LAY-241-50002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 16,  MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'N10UH_L3', TAG_NO: 'SPHR-8061015',    TAG_DESCR: 'SPHR-6130 Water Only Hose Reel', DWG: 'GM004-PI-LAY-241-50002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 17,  MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'N10UH_L3', TAG_NO: 'US-510-7',        TAG_DESCR: 'Utility Station', DWG: 'GM004-PI-LAY-241-50002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-10-30', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 18,  MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'N10UH_L3', TAG_NO: 'SPHR-8012441',    TAG_DESCR: 'Water Only Hose Reel', DWG: null, PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 19,  MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'N10UH_L3', TAG_NO: 'SPHR-8110210',    TAG_DESCR: 'Water Only Hose Reel', DWG: null, PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 20,  MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'N10UH_L3', TAG_NO: 'XLB-6460-003',    TAG_DESCR: 'Life Buoy With Light', DWG: 'GM004-PI-LAY-241-50001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 21,  MODULE: 'N10UH', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'M540H',         DECK: 'MAIN DECK',    SVG: 'N10UH_L3', TAG_NO: 'HEF-992355',      TAG_DESCR: 'Extract Fan Lab', DWG: 'GM004-PI-LAY-241-50001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-11-06', ROS: '2019-10-16', INSTALL: '2019-11-16', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '100', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 22,  MODULE: 'N10UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MAIN DECK',    SVG: 'N10UH_L3', TAG_NO: 'XLB-6460-002',    TAG_DESCR: 'Life Buoy With Light', DWG: 'GM004-PI-LAY-241-50002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 1,   MODULE: 'LQ1UH', DISC: 'EI', PKG_NO: 'EL002', BLOCK: 'M11PH',         DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'VSGE-674051',     TAG_DESCR: '480V Emergency Switchgear', DWG: 'GM004-EL-GAS-010-00011-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-10', ETA_ACTUAL: '2019-05-25', ROS: '2019-05-28', INSTALL: '2020-01-01', UP_BLOCK_PE: '2019-07-30', ROS_ETA: 3, ROS_ETA_UPBLOCK: 66, CANBE_INST: '265', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 2,   MODULE: 'LQ1UH', DISC: 'EI', PKG_NO: 'EL007', BLOCK: 'M11PH',         DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'VMCE-674052',     TAG_DESCR: '480V Emergency MCC (EMMCC)', DWG: 'GM004-EL-GAS-010-00011-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-10', ETA_ACTUAL: '2019-04-14', ROS: '2019-05-28', INSTALL: '2020-01-01', UP_BLOCK_PE: '2019-07-30', ROS_ETA: 44, ROS_ETA_UPBLOCK: 107, CANBE_INST: '306', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 3,   MODULE: 'LQ1UH', DISC: 'EI', PKG_NO: 'EL007', BLOCK: 'M11PH',         DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'VMCE-674067',     TAG_DESCR: '480V Emergency VFD MCC (EMMCC)', DWG: 'GM004-EL-GAS-010-00011-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-10', ETA_ACTUAL: '2019-04-14', ROS: '2019-05-28', INSTALL: '2020-01-01', UP_BLOCK_PE: '2019-07-30', ROS_ETA: 44, ROS_ETA_UPBLOCK: 107, CANBE_INST: '306', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 4,   MODULE: 'LQ1UH', DISC: 'EI', PKG_NO: 'EL007', BLOCK: 'M11PH',         DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'VMCE-674051',     TAG_DESCR: '480V Emergency MCC (EMMCC)', DWG: 'GM004-EL-GAS-010-00011-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-10', ETA_ACTUAL: '2019-04-14', ROS: '2019-05-28', INSTALL: '2020-01-01', UP_BLOCK_PE: '2019-07-30', ROS_ETA: 44, ROS_ETA_UPBLOCK: 107, CANBE_INST: '306', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 5,   MODULE: 'LQ1UH', DISC: 'EI', PKG_NO: 'EL007', BLOCK: 'M11PH',         DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'VMCE-674053',     TAG_DESCR: '480V Emergency MCC (EMMCC)', DWG: 'GM004-EL-GAS-010-00011-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-10', ETA_ACTUAL: '2019-04-14', ROS: '2019-05-28', INSTALL: '2020-01-01', UP_BLOCK_PE: '2019-07-30', ROS_ETA: 44, ROS_ETA_UPBLOCK: 107, CANBE_INST: '306', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 6,   MODULE: 'LQ1UH', DISC: 'EI', PKG_NO: 'TE001', BLOCK: 'M11PH',         DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'RRK-9550003',     TAG_DESCR: 'Radar Cabinet 1 ', DWG: 'GM004-TE-LAY-010-00005-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-02-08', ETA_ACTUAL: '2019-07-19', ROS: '2019-05-28', INSTALL: '2020-01-01', UP_BLOCK_PE: '2019-07-30', ROS_ETA: -52, ROS_ETA_UPBLOCK: 11, CANBE_INST: '210', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 7,   MODULE: 'LQ1UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'RRK-9512001',     TAG_DESCR: 'GOM F.O Cabinet', DWG: 'GM004-TE-LAY-010-00005-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 8,   MODULE: 'LQ1UH', DISC: 'EI', PKG_NO: 'TE001', BLOCK: 'M11PH',         DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'RRK-9560008',     TAG_DESCR: 'SCS Telecom Cabinet 5 ', DWG: 'GM004-TE-LAY-010-00005-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-02-08', ETA_ACTUAL: '2019-07-19', ROS: '2019-05-28', INSTALL: '2020-01-01', UP_BLOCK_PE: '2019-07-30', ROS_ETA: -52, ROS_ETA_UPBLOCK: 11, CANBE_INST: '210', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 9,   MODULE: 'LQ1UH', DISC: 'EI', PKG_NO: 'TE001', BLOCK: 'M11PH',         DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'RRK-9520006',     TAG_DESCR: 'LAN Cabinet 3', DWG: 'GM004-TE-LAY-010-00005-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-02-08', ETA_ACTUAL: '2019-07-19', ROS: '2019-05-28', INSTALL: '2020-01-01', UP_BLOCK_PE: '2019-07-30', ROS_ETA: -52, ROS_ETA_UPBLOCK: 11, CANBE_INST: '210', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 10,  MODULE: 'LQ1UH', DISC: 'EI', PKG_NO: 'TE001', BLOCK: 'M11PH',         DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'RRK-9560009',     TAG_DESCR: 'SCS Telecom Cabinet 6', DWG: 'GM004-TE-LAY-010-00005-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-02-08', ETA_ACTUAL: '2019-07-19', ROS: '2019-05-28', INSTALL: '2020-01-01', UP_BLOCK_PE: '2019-07-30', ROS_ETA: -52, ROS_ETA_UPBLOCK: 11, CANBE_INST: '210', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 11,  MODULE: 'LQ1UH', DISC: 'EI', PKG_NO: 'TE001', BLOCK: 'M11PH',         DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'RRK-9520007',     TAG_DESCR: 'LAN Cabinet 4', DWG: 'GM004-TE-LAY-010-00005-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-02-08', ETA_ACTUAL: '2019-07-19', ROS: '2019-05-28', INSTALL: '2020-01-01', UP_BLOCK_PE: '2019-07-30', ROS_ETA: -52, ROS_ETA_UPBLOCK: 11, CANBE_INST: '210', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 12,  MODULE: 'LQ1UH', DISC: 'EI', PKG_NO: 'TE001', BLOCK: 'M11PH',         DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'RRK-9580001',     TAG_DESCR: 'ACE Cabinet 1', DWG: 'GM004-TE-LAY-010-00005-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-02-08', ETA_ACTUAL: '2019-02-10', ROS: '2019-05-28', INSTALL: '2020-01-01', UP_BLOCK_PE: '2019-07-30', ROS_ETA: 107, ROS_ETA_UPBLOCK: 170, CANBE_INST: '369', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 13,  MODULE: 'LQ1UH', DISC: 'EI', PKG_NO: 'TE002', BLOCK: 'M11PH',         DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'RRK-6210003',     TAG_DESCR: 'PAGA Cabinel', DWG: 'GM004-TE-LAY-010-00005-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '2019-08-30', ROS: '2019-05-28', INSTALL: '2020-01-01', UP_BLOCK_PE: '2019-07-30', ROS_ETA: -94, ROS_ETA_UPBLOCK: -31, CANBE_INST: '168', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 14,  MODULE: 'LQ1UH', DISC: 'EI', PKG_NO: 'TE002', BLOCK: 'M11PH',         DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'RRK-6210002',     TAG_DESCR: 'PAGA Cabinel', DWG: 'GM004-TE-LAY-010-00005-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '2019-08-30', ROS: '2019-05-28', INSTALL: '2020-01-01', UP_BLOCK_PE: '2019-07-30', ROS_ETA: -94, ROS_ETA_UPBLOCK: -31, CANBE_INST: '168', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 15,  MODULE: 'LQ1UH', DISC: 'EI', PKG_NO: 'TE002', BLOCK: 'M11PH',         DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'RRK-6210001',     TAG_DESCR: 'PAGA Cabinel', DWG: 'GM004-TE-LAY-010-00005-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '2019-08-30', ROS: '2019-05-28', INSTALL: '2020-01-01', UP_BLOCK_PE: '2019-07-30', ROS_ETA: -94, ROS_ETA_UPBLOCK: -31, CANBE_INST: '168', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 16,  MODULE: 'LQ1UH', DISC: 'EI', PKG_NO: 'TE001', BLOCK: 'M11PH',         DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'RRK-9500003',     TAG_DESCR: 'Spare Cabinet3', DWG: 'GM004-TE-LAY-010-00005-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-02-08', ETA_ACTUAL: '2019-05-15', ROS: '2019-05-28', INSTALL: '2020-01-01', UP_BLOCK_PE: '2019-07-30', ROS_ETA: 13, ROS_ETA_UPBLOCK: 76, CANBE_INST: '275', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 17,  MODULE: 'LQ1UH', DISC: 'EI', PKG_NO: 'TE001', BLOCK: 'M11PH',         DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'RRK-9500002',     TAG_DESCR: 'Spare Cabinet2', DWG: 'GM004-TE-LAY-010-00005-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-02-08', ETA_ACTUAL: '2019-05-15', ROS: '2019-05-28', INSTALL: '2020-01-01', UP_BLOCK_PE: '2019-07-30', ROS_ETA: 13, ROS_ETA_UPBLOCK: 76, CANBE_INST: '275', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 18,  MODULE: 'LQ1UH', DISC: 'EI', PKG_NO: 'TE001', BLOCK: 'M11PH',         DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'RRK-9520005',     TAG_DESCR: 'LAN Cabinet 2', DWG: 'GM004-TE-LAY-010-00005-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-02-08', ETA_ACTUAL: '2019-07-19', ROS: '2019-05-28', INSTALL: '2020-01-01', UP_BLOCK_PE: '2019-07-30', ROS_ETA: -52, ROS_ETA_UPBLOCK: 11, CANBE_INST: '210', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 19,  MODULE: 'LQ1UH', DISC: 'EI', PKG_NO: 'TE001', BLOCK: 'M11PH',         DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'RRK-9520004',     TAG_DESCR: 'LAN Cabinet 1', DWG: 'GM004-TE-LAY-010-00005-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-02-08', ETA_ACTUAL: '2019-05-15', ROS: '2019-05-28', INSTALL: '2020-01-01', UP_BLOCK_PE: '2019-07-30', ROS_ETA: 13, ROS_ETA_UPBLOCK: 76, CANBE_INST: '275', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 20,  MODULE: 'LQ1UH', DISC: 'EI', PKG_NO: 'TE001', BLOCK: 'M11PH',         DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'RRK-6221001',     TAG_DESCR: 'UHF Cabinet 1', DWG: 'GM004-TE-LAY-010-00005-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-02-08', ETA_ACTUAL: '2019-07-19', ROS: '2019-05-28', INSTALL: '2020-01-01', UP_BLOCK_PE: '2019-07-30', ROS_ETA: -52, ROS_ETA_UPBLOCK: 11, CANBE_INST: '210', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 21,  MODULE: 'LQ1UH', DISC: 'EI', PKG_NO: 'TE001', BLOCK: 'M11PH',         DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'RRK-6221002',     TAG_DESCR: 'UHF Cabinet 2', DWG: 'GM004-TE-LAY-010-00005-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-02-08', ETA_ACTUAL: '2019-07-19', ROS: '2019-05-28', INSTALL: '2020-01-01', UP_BLOCK_PE: '2019-07-30', ROS_ETA: -52, ROS_ETA_UPBLOCK: 11, CANBE_INST: '210', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 22,  MODULE: 'LQ1UH', DISC: 'EI', PKG_NO: 'TE001', BLOCK: 'M11PH',         DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'RRK-6221003',     TAG_DESCR: 'UHF Voice Recorder Cabinet ', DWG: 'GM004-TE-LAY-010-00005-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-02-08', ETA_ACTUAL: '2019-07-19', ROS: '2019-05-28', INSTALL: '2020-01-01', UP_BLOCK_PE: '2019-07-30', ROS_ETA: -52, ROS_ETA_UPBLOCK: 11, CANBE_INST: '210', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 23,  MODULE: 'LQ1UH', DISC: 'EI', PKG_NO: 'EL004', BLOCK: 'M11PH',         DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'VUS-672051B',     TAG_DESCR: 'Hull 120/208 VAC PAGA/Telecom UPS B', DWG: 'GM004-TE-LAY-010-00005-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-18', ETA_ACTUAL: '2019-04-15', ROS: '2019-05-28', INSTALL: '2020-01-01', UP_BLOCK_PE: '2019-07-30', ROS_ETA: 43, ROS_ETA_UPBLOCK: 106, CANBE_INST: '305', STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 24,  MODULE: 'LQ1UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'VCP-672051B',     TAG_DESCR: 'Battery Monitoring Cabinet- PAGA/Telecom UPS B', DWG: 'GM004-TE-LAY-010-00005-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 25,  MODULE: 'LQ1UH', DISC: 'ME', PKG_NO: 'MU009', BLOCK: 'M11PH',         DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'ZZZ-663010',      TAG_DESCR: 'Emergency Generator Skid', DWG: 'GM004-PI-LAY-241-00001-001', PO_PLAN: '2017-01-26', PO_ACTUAL: '2017-01-26', FAT_PLAN: '2018-12-10', FAT_ACTUAL: '2019-04-16', ETA_PLAN: '2019-03-28', ETA_ACTUAL: '2019-08-10', ROS: '2019-05-29', INSTALL: '2019-08-17', UP_BLOCK_PE: '2019-07-30', ROS_ETA: -73, ROS_ETA_UPBLOCK: -11, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 26,  MODULE: 'LQ1UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'L4515',           TAG_DESCR: 'Conference Table', DWG: 'GM004-AR-LST-241-00016', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 27,  MODULE: 'LQ1UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'YFG-920077A',     TAG_DESCR: 'General Waste Compactor ', DWG: 'GM004-AR-LST-241-00016', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 28,  MODULE: 'LQ1UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'YFG-920077B',     TAG_DESCR: 'General Waste Compactor ', DWG: 'GM004-AR-LST-241-00016', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 29,  MODULE: 'LQ1UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'YFG-920078',      TAG_DESCR: 'Recycle  Compactor ', DWG: 'GM004-AR-LST-241-00016', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 30,  MODULE: 'LQ1UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'YFG-920079',      TAG_DESCR: 'Food Macerator', DWG: 'GM004-AR-LST-241-00016', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 31,  MODULE: 'LQ1UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'YFG-920080',      TAG_DESCR: 'Grease Storage Container', DWG: 'GM004-AR-LST-241-00016', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 32,  MODULE: 'LQ1UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'YFG-920062',      TAG_DESCR: 'Walk-In Thawing', DWG: 'GM004-AR-LST-241-00016', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 33,  MODULE: 'LQ1UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'YFG-920064',      TAG_DESCR: 'Walk-In Cooler', DWG: 'GM004-AR-LST-241-00016', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 34,  MODULE: 'LQ1UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'YFG-920063',      TAG_DESCR: 'Walk-In Freezer', DWG: 'GM004-AR-LST-241-00016', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Deckbox', SN: 35,  MODULE: 'LQ1UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'YFG-920066A~G',   TAG_DESCR: 'Moveable Shelving System', DWG: 'GM004-AR-LST-241-00016', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 36,  MODULE: 'LQ1UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'TLE-982001A',     TAG_DESCR: 'Baggage Lift', DWG: 'GM004-AR-LAY-01 0-00025-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 37,  MODULE: 'LQ1UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'ZZZ-614000',      TAG_DESCR: 'Heli Foam Skid', DWG: 'GM004-AR-LAY-01 0-00025-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 38,  MODULE: 'LQ1UH', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'TLE-982002A',     TAG_DESCR: 'Scissor_Lift', DWG: 'GM004-AR-LAY-01 0-00025-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Deckbox', SN: 39,  MODULE: 'LQ1UH', DISC: 'ME', PKG_NO: 'MU017', BLOCK: 'Q410H',         DECK: 'LQ',           SVG: 'LQ1UH',    TAG_NO: 'ZZZ-807020',      TAG_DESCR: 'Helifuel Dispenser Skid', DWG: 'GM004-AR-LAY-01 0-00025-001', PO_PLAN: '2017-09-27', PO_ACTUAL: '2017-09-27', FAT_PLAN: '2019-03-18', FAT_ACTUAL: '2019-08-27', ETA_PLAN: '2019-06-09', ETA_ACTUAL: '2019-09-29', ROS: '2019-06-30', INSTALL: '2019-10-17', UP_BLOCK_PE: '', ROS_ETA: -91, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      
      
      { AREA: 'Topside', SN: 1,   MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'MU002', BLOCK: 'B110T',         DECK: 'LOWER DECK',   SVG: 'CM1UT_L1',  TAG_NO: 'ZEH-191010',     TAG_DESCR: 'Topsides Hydraulic Power Unit', DWG: 'GM004-PI-LAY-241-70001-001', PO_PLAN: '2017-06-26', PO_ACTUAL: '2017-06-26', FAT_PLAN: '2019-03-09', FAT_ACTUAL: '2019-06-24', ETA_PLAN: '2019-05-14', ETA_ACTUAL: '2019-08-09', ROS: '2019-05-21', INSTALL: '2019-09-06', UP_BLOCK_PE: '2019-06-07', ROS_ETA: -80, ROS_ETA_UPBLOCK: -63, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 2,   MODULE: 'CM1UT', DISC: 'EI', PKG_NO: 'IC008', BLOCK: 'B110T',         DECK: 'LOWER DECK',   SVG: 'CM1UT_L1',  TAG_NO: 'PNL-6010610',    TAG_DESCR: 'Pressure Safety Test Panel', DWG: 'GM004-PI-LAY-241-70001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-03', ETA_ACTUAL: '2019-08-12', ROS: '2019-05-15', INSTALL: '', UP_BLOCK_PE: '2019-05-21', ROS_ETA: -89, ROS_ETA_UPBLOCK: -83, CANBE_INST: '186', STATUS: 'ETA', },
      { AREA: 'Topside', SN: 3,   MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'MC004', BLOCK: 'B120T',         DECK: 'LOWER DECK',   SVG: 'CM1UT_L1',  TAG_NO: 'HBG-813001',     TAG_DESCR: 'Fuel Gas Preheater', DWG: 'GM004-PI-LAY-241-70001-001', PO_PLAN: '2017-01-19', PO_ACTUAL: '2017-01-19', FAT_PLAN: '2018-06-14', FAT_ACTUAL: '2018-06-14', ETA_PLAN: '2019-05-15', ETA_ACTUAL: '2019-05-28', ROS: '2019-05-22', INSTALL: '2019-06-05', UP_BLOCK_PE: '2019-06-07', ROS_ETA: -6, ROS_ETA_UPBLOCK: 10, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 4,   MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'ML006', BLOCK: 'B120T',         DECK: 'LOWER DECK',   SVG: 'CM1UT_L1',  TAG_NO: 'MAJ-813005',     TAG_DESCR: 'Turbine Fuel Gas Filter', DWG: 'GM004-PI-LAY-241-70001-001', PO_PLAN: '2017-09-22', PO_ACTUAL: '2017-09-22', FAT_PLAN: '2019-04-09', FAT_ACTUAL: '2019-04-30', ETA_PLAN: '2019-05-08', ETA_ACTUAL: '2019-05-29', ROS: '2019-05-15', INSTALL: '2019-06-04', UP_BLOCK_PE: '2019-05-17', ROS_ETA: -14, ROS_ETA_UPBLOCK: -12, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 5,   MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'ML006', BLOCK: 'B120T',         DECK: 'LOWER DECK',   SVG: 'CM1UT_L1',  TAG_NO: 'MAJ-813006',     TAG_DESCR: 'Turbine Fuel Gas Filter', DWG: 'GM004-PI-LAY-241-70001-001', PO_PLAN: '2017-09-22', PO_ACTUAL: '2017-09-22', FAT_PLAN: '2019-04-09', FAT_ACTUAL: '2019-05-02', ETA_PLAN: '2019-05-08', ETA_ACTUAL: '2019-05-29', ROS: '2019-05-15', INSTALL: '2019-06-04', UP_BLOCK_PE: '2019-05-17', ROS_ETA: -14, ROS_ETA_UPBLOCK: -12, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 6,   MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'MC005', BLOCK: 'B120T',         DECK: 'LOWER DECK',   SVG: 'CM1UT_L1',  TAG_NO: 'HHE-813004',     TAG_DESCR: 'Fuel Gas Electric Superheater', DWG: 'GM004-PI-LAY-241-70001-001', PO_PLAN: '2017-09-21', PO_ACTUAL: '2017-09-21', FAT_PLAN: '2018-09-26', FAT_ACTUAL: '2019-01-07', ETA_PLAN: '2019-04-01', ETA_ACTUAL: '2019-05-24', ROS: '2019-05-22', INSTALL: '2019-06-07', UP_BLOCK_PE: '2019-06-07', ROS_ETA: -2, ROS_ETA_UPBLOCK: 14, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 7,   MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'MC004', BLOCK: 'B120T',         DECK: 'LOWER DECK',   SVG: 'CM1UT_L1',  TAG_NO: 'HBG-813003',     TAG_DESCR: 'Fuel Gas Superheater', DWG: 'GM004-PI-LAY-241-70001-001', PO_PLAN: '2017-01-19', PO_ACTUAL: '2017-01-19', FAT_PLAN: '2018-06-14', FAT_ACTUAL: '2018-06-14', ETA_PLAN: '2019-05-15', ETA_ACTUAL: '2019-05-28', ROS: '2019-05-22', INSTALL: '2019-06-05', UP_BLOCK_PE: '2019-06-07', ROS_ETA: -6, ROS_ETA_UPBLOCK: 10, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 8,   MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'ML002', BLOCK: 'B130T',         DECK: 'LOWER DECK',   SVG: 'CM1UT_L1',  TAG_NO: 'ZZZ-323000',     TAG_DESCR: 'Glycol Regeneration Skid', DWG: 'GM004-PI-LAY-241-70001-001', PO_PLAN: '2017-01-24', PO_ACTUAL: '2017-01-24', FAT_PLAN: '2019-03-28', FAT_ACTUAL: '2019-05-01', ETA_PLAN: '2019-05-09', ETA_ACTUAL: '2019-05-31', ROS: '2019-05-16', INSTALL: '2019-06-04', UP_BLOCK_PE: '2019-05-20', ROS_ETA: -15, ROS_ETA_UPBLOCK: -11, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 9,   MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'MD001', BLOCK: 'B110T',         DECK: 'LOWER DECK',   SVG: 'CM1UT_L1',  TAG_NO: 'MBF-521001',     TAG_DESCR: 'LP Flare Scrubber', DWG: 'GM004-PI-LAY-241-70001-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-11-30', FAT_ACTUAL: '2019-01-31', ETA_PLAN: '2019-05-10', ETA_ACTUAL: '2019-05-04', ROS: '2019-05-21', INSTALL: '2019-06-17', UP_BLOCK_PE: '2019-06-07', ROS_ETA: 17, ROS_ETA_UPBLOCK: 34, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 10,  MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'MD001', BLOCK: 'B110T',         DECK: 'LOWER DECK',   SVG: 'CM1UT_L1',  TAG_NO: 'NBF-501001',     TAG_DESCR: 'HP/LT Flare Scrubber', DWG: 'GM004-PI-LAY-241-70001-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-11-30', FAT_ACTUAL: '2018-12-10', ETA_PLAN: '2019-05-10', ETA_ACTUAL: '2019-05-04', ROS: '2019-05-21', INSTALL: '2019-06-14', UP_BLOCK_PE: '2019-06-07', ROS_ETA: 17, ROS_ETA_UPBLOCK: 34, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 11,  MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'MC005', BLOCK: 'B110T',         DECK: 'LOWER DECK',   SVG: 'CM1UT_L1',  TAG_NO: 'ZAP-501002',     TAG_DESCR: 'HP/LT Flare Scrubber Electric Heater', DWG: 'GM004-PI-LAY-241-70001-001', PO_PLAN: '2017-09-21', PO_ACTUAL: '2017-09-21', FAT_PLAN: '2018-09-26', FAT_ACTUAL: '2019-01-07', ETA_PLAN: '2019-04-01', ETA_ACTUAL: '2019-05-24', ROS: '2019-05-21', INSTALL: '2019-06-11', UP_BLOCK_PE: '2019-06-07', ROS_ETA: -3, ROS_ETA_UPBLOCK: 14, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 12,  MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'MD001', BLOCK: 'B120T',         DECK: 'LOWER DECK',   SVG: 'CM1UT_L1',  TAG_NO: 'MBF-303101',     TAG_DESCR: 'Export Compressor Suct Scrubber - Train 1', DWG: 'GM004-PI-LAY-241-70001-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-11-30', FAT_ACTUAL: '2019-01-31', ETA_PLAN: '2019-05-10', ETA_ACTUAL: '2019-05-04', ROS: '2019-05-22', INSTALL: '2019-06-16', UP_BLOCK_PE: '2019-06-07', ROS_ETA: 18, ROS_ETA_UPBLOCK: 34, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 13,  MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'MD001', BLOCK: 'B120T',         DECK: 'LOWER DECK',   SVG: 'CM1UT_L1',  TAG_NO: 'MBF-302105',     TAG_DESCR: 'HP Compressor Discharge Scrubber - Train 1', DWG: 'GM004-PI-LAY-241-70001-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-11-30', FAT_ACTUAL: '2019-01-31', ETA_PLAN: '2019-05-10', ETA_ACTUAL: '2019-05-04', ROS: '2019-05-22', INSTALL: '2019-06-17', UP_BLOCK_PE: '2019-06-07', ROS_ETA: 18, ROS_ETA_UPBLOCK: 34, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 14,  MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'MD001', BLOCK: 'B120T',         DECK: 'LOWER DECK',   SVG: 'CM1UT_L1',  TAG_NO: 'MBF-302205',     TAG_DESCR: 'HP Compressor Discharge Scrubber - Train 2', DWG: 'GM004-PI-LAY-241-70001-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-11-30', FAT_ACTUAL: '2019-01-31', ETA_PLAN: '2019-05-10', ETA_ACTUAL: '2019-05-04', ROS: '2019-05-22', INSTALL: '2019-06-17', UP_BLOCK_PE: '2019-06-07', ROS_ETA: 18, ROS_ETA_UPBLOCK: 34, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 15,  MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'MD001', BLOCK: 'B130T',         DECK: 'LOWER DECK',   SVG: 'CM1UT_L1',  TAG_NO: 'MBF-303201',     TAG_DESCR: 'Export Compressor Suct Scrubber - Train 2', DWG: 'GM004-PI-LAY-241-70001-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-11-30', FAT_ACTUAL: '2019-01-31', ETA_PLAN: '2019-05-10', ETA_ACTUAL: '2019-05-04', ROS: '2019-05-19', INSTALL: '2019-06-16', UP_BLOCK_PE: '2019-05-20', ROS_ETA: 15, ROS_ETA_UPBLOCK: 16, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 16,  MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'MJ009', BLOCK: 'B110T',         DECK: 'LOWER DECK',   SVG: 'CM1UT_L1',  TAG_NO: 'PBA-501004',     TAG_DESCR: 'HP/LT Flare Scrubber Pump', DWG: 'GM004-PI-LAY-241-70001-001', PO_PLAN: '2017-07-21', PO_ACTUAL: '2017-07-21', FAT_PLAN: '2019-03-14', FAT_ACTUAL: '2019-08-21', ETA_PLAN: '2019-05-16', ETA_ACTUAL: '2019-11-11', ROS: '2019-05-21', INSTALL: '2019-11-18', UP_BLOCK_PE: '2019-06-07', ROS_ETA: -174, ROS_ETA_UPBLOCK: -157, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 17,  MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'MJ009', BLOCK: 'B110T',         DECK: 'LOWER DECK',   SVG: 'CM1UT_L1',  TAG_NO: 'PBA-501003',     TAG_DESCR: 'HP/LT Flare Scrubber Pump', DWG: 'GM004-PI-LAY-241-70001-001', PO_PLAN: '2017-07-21', PO_ACTUAL: '2017-07-21', FAT_PLAN: '2019-03-14', FAT_ACTUAL: '2019-08-21', ETA_PLAN: '2019-05-16', ETA_ACTUAL: '2019-11-11', ROS: '2019-05-21', INSTALL: '2019-11-18', UP_BLOCK_PE: '2019-06-07', ROS_ETA: -174, ROS_ETA_UPBLOCK: -157, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 18,  MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'MD001', BLOCK: 'B120T',         DECK: 'LOWER DECK',   SVG: 'CM1UT_L1',  TAG_NO: 'MBF-302102',     TAG_DESCR: 'HP Compressor Suction Scrubber - Train 1', DWG: 'GM004-PI-LAY-241-70001-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-11-30', FAT_ACTUAL: '2019-01-31', ETA_PLAN: '2019-05-10', ETA_ACTUAL: '2019-05-04', ROS: '2019-05-22', INSTALL: '2019-06-16', UP_BLOCK_PE: '2019-06-07', ROS_ETA: 18, ROS_ETA_UPBLOCK: 34, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 19,  MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'MD001', BLOCK: 'B120T',         DECK: 'LOWER DECK',   SVG: 'CM1UT_L1',  TAG_NO: 'MBF-302202',     TAG_DESCR: 'HP Compressor Suction Scrubber - Train 2', DWG: 'GM004-PI-LAY-241-70001-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-11-30', FAT_ACTUAL: '2019-01-31', ETA_PLAN: '2019-05-10', ETA_ACTUAL: '2019-05-04', ROS: '2019-05-22', INSTALL: '2019-06-16', UP_BLOCK_PE: '2019-06-07', ROS_ETA: 18, ROS_ETA_UPBLOCK: 34, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 20,  MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'MJ012', BLOCK: 'B110T',         DECK: 'LOWER DECK',   SVG: 'CM1UT_L1',  TAG_NO: 'PBA-304005',     TAG_DESCR: 'VRU Compressor Condensate Pump', DWG: 'GM004-PI-LAY-241-70001-001', PO_PLAN: '2017-08-25', PO_ACTUAL: '2017-08-25', FAT_PLAN: '2019-03-29', FAT_ACTUAL: '2019-06-18', ETA_PLAN: '2019-05-31', ETA_ACTUAL: '2019-07-29', ROS: '2019-05-21', INSTALL: '2019-08-08', UP_BLOCK_PE: '2019-06-07', ROS_ETA: -69, ROS_ETA_UPBLOCK: -52, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 21,  MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'MJ012', BLOCK: 'B110T',         DECK: 'LOWER DECK',   SVG: 'CM1UT_L1',  TAG_NO: 'PBA-304004',     TAG_DESCR: 'VRU Compressor Condensate Pump', DWG: 'GM004-PI-LAY-241-70001-001', PO_PLAN: '2017-08-25', PO_ACTUAL: '2017-08-25', FAT_PLAN: '2019-03-29', FAT_ACTUAL: '2019-06-18', ETA_PLAN: '2019-05-31', ETA_ACTUAL: '2019-07-29', ROS: '2019-05-21', INSTALL: '2019-08-07', UP_BLOCK_PE: '2019-06-07', ROS_ETA: -69, ROS_ETA_UPBLOCK: -52, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 22,  MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'MD001', BLOCK: 'B110T',         DECK: 'LOWER DECK',   SVG: 'CM1UT_L1',  TAG_NO: 'MBF-304003',     TAG_DESCR: 'VRU Compressor Suction Scrubber', DWG: 'GM004-PI-LAY-241-70001-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-11-30', FAT_ACTUAL: '2019-01-31', ETA_PLAN: '2019-05-10', ETA_ACTUAL: '2019-05-04', ROS: '2019-05-21', INSTALL: '2019-06-16', UP_BLOCK_PE: '2019-06-07', ROS_ETA: 17, ROS_ETA_UPBLOCK: 34, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 23,  MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'MD001', BLOCK: 'B120T',         DECK: 'LOWER DECK',   SVG: 'CM1UT_L1',  TAG_NO: 'MBF-301102',     TAG_DESCR: 'LP Compressor Suction Scrubber - Train 1', DWG: 'GM004-PI-LAY-241-70001-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-11-30', FAT_ACTUAL: '2019-01-31', ETA_PLAN: '2019-05-10', ETA_ACTUAL: '2019-05-04', ROS: '2019-05-22', INSTALL: '2019-06-16', UP_BLOCK_PE: '2019-06-07', ROS_ETA: 18, ROS_ETA_UPBLOCK: 34, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 24,  MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'MJ009', BLOCK: 'B120T',         DECK: 'LOWER DECK',   SVG: 'CM1UT_L1',  TAG_NO: 'PBA-301105',     TAG_DESCR: 'LP Compressor Condensate Pump - Train 1', DWG: 'GM004-PI-LAY-241-70001-001', PO_PLAN: '2017-07-21', PO_ACTUAL: '2017-07-21', FAT_PLAN: '2019-03-14', FAT_ACTUAL: '2019-03-26', ETA_PLAN: '2019-05-16', ETA_ACTUAL: '2019-07-19', ROS: '2019-05-22', INSTALL: '2019-08-28', UP_BLOCK_PE: '2019-06-07', ROS_ETA: -58, ROS_ETA_UPBLOCK: -42, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 25,  MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'MJ009', BLOCK: 'B120T',         DECK: 'LOWER DECK',   SVG: 'CM1UT_L1',  TAG_NO: 'PBA-301104',     TAG_DESCR: 'LP Compressor Condensate Pump - Train 1', DWG: 'GM004-PI-LAY-241-70001-001', PO_PLAN: '2017-07-21', PO_ACTUAL: '2017-07-21', FAT_PLAN: '2019-03-14', FAT_ACTUAL: '2019-03-27', ETA_PLAN: '2019-05-16', ETA_ACTUAL: '2019-07-19', ROS: '2019-05-22', INSTALL: '2019-08-28', UP_BLOCK_PE: '2019-06-07', ROS_ETA: -58, ROS_ETA_UPBLOCK: -42, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 26,  MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'MJ009', BLOCK: 'B120T',         DECK: 'LOWER DECK',   SVG: 'CM1UT_L1',  TAG_NO: 'PBA-301205',     TAG_DESCR: 'LP Compressor Condensate Pump - Train 2', DWG: 'GM004-PI-LAY-241-70001-001', PO_PLAN: '2017-07-21', PO_ACTUAL: '2017-07-21', FAT_PLAN: '2019-03-14', FAT_ACTUAL: '2019-04-04', ETA_PLAN: '2019-05-16', ETA_ACTUAL: '2019-07-19', ROS: '2019-05-22', INSTALL: '2019-08-28', UP_BLOCK_PE: '2019-06-07', ROS_ETA: -58, ROS_ETA_UPBLOCK: -42, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 27,  MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'MJ009', BLOCK: 'B120T',         DECK: 'LOWER DECK',   SVG: 'CM1UT_L1',  TAG_NO: 'PBA-301204',     TAG_DESCR: 'LP Compressor Condensate Pump - Train 2', DWG: 'GM004-PI-LAY-241-70001-001', PO_PLAN: '2017-07-21', PO_ACTUAL: '2017-07-21', FAT_PLAN: '2019-03-14', FAT_ACTUAL: '2019-04-04', ETA_PLAN: '2019-05-16', ETA_ACTUAL: '2019-07-19', ROS: '2019-05-22', INSTALL: '2019-08-28', UP_BLOCK_PE: '2019-06-07', ROS_ETA: -58, ROS_ETA_UPBLOCK: -42, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 28,  MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'MD001', BLOCK: 'B120T',         DECK: 'LOWER DECK',   SVG: 'CM1UT_L1',  TAG_NO: 'MBF-301202',     TAG_DESCR: 'LP Compressor Suction Scrubber - Train 2', DWG: 'GM004-PI-LAY-241-70001-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-11-30', FAT_ACTUAL: '2019-01-31', ETA_PLAN: '2019-05-10', ETA_ACTUAL: '2019-05-04', ROS: '2019-05-22', INSTALL: '2019-06-16', UP_BLOCK_PE: '2019-06-07', ROS_ETA: 18, ROS_ETA_UPBLOCK: 34, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 29,  MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'MD001', BLOCK: 'B130T',         DECK: 'LOWER DECK',   SVG: 'CM1UT_L1',  TAG_NO: 'MBF-813002',     TAG_DESCR: 'Fuel Gas Scrubber', DWG: 'GM004-PI-LAY-241-70001-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-11-30', FAT_ACTUAL: '2019-01-31', ETA_PLAN: '2019-05-10', ETA_ACTUAL: '2019-05-04', ROS: '2019-05-19', INSTALL: '2019-06-05', UP_BLOCK_PE: '2019-05-20', ROS_ETA: 15, ROS_ETA_UPBLOCK: 16, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 30,  MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'ML002', BLOCK: 'B130T',         DECK: 'LOWER DECK',   SVG: 'CM1UT_L1',  TAG_NO: 'MAF-321002',     TAG_DESCR: 'Glycol Contactor', DWG: 'GM004-PI-LAY-241-70001-001', PO_PLAN: '2017-01-24', PO_ACTUAL: '2017-01-24', FAT_PLAN: '2019-03-28', FAT_ACTUAL: '2019-05-01', ETA_PLAN: '2019-05-09', ETA_ACTUAL: '2019-05-31', ROS: '2019-05-16', INSTALL: '2019-06-04', UP_BLOCK_PE: '2019-05-20', ROS_ETA: -15, ROS_ETA_UPBLOCK: -11, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 1,   MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'MC004', BLOCK: 'B20PT',         DECK: 'MIDDLE DECK',  SVG: 'CM1UT_L2',  TAG_NO: 'HBG-304001',     TAG_DESCR: 'VRU Compressor Suction Cooler - Train 1', DWG: 'GM004-PI-LAY-241-70002-001', PO_PLAN: '2017-01-19', PO_ACTUAL: '2017-01-19', FAT_PLAN: '2018-06-14', FAT_ACTUAL: '2018-11-19', ETA_PLAN: '2019-05-15', ETA_ACTUAL: '2019-05-28', ROS: '2019-06-14', INSTALL: '2019-06-28', UP_BLOCK_PE: '2019-06-19', ROS_ETA: 17, ROS_ETA_UPBLOCK: 22, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 2,   MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'MC004', BLOCK: 'B20PT',         DECK: 'MIDDLE DECK',  SVG: 'CM1UT_L2',  TAG_NO: 'HBG-303103',     TAG_DESCR: 'Export Compressor Disch Cooler - Train 1', DWG: 'GM004-PI-LAY-241-70002-001', PO_PLAN: '2017-01-19', PO_ACTUAL: '2017-01-19', FAT_PLAN: '2018-06-14', FAT_ACTUAL: '2018-11-26', ETA_PLAN: '2019-05-15', ETA_ACTUAL: '2019-05-28', ROS: '2019-06-14', INSTALL: '2019-07-01', UP_BLOCK_PE: '2019-06-19', ROS_ETA: 17, ROS_ETA_UPBLOCK: 22, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 3,   MODULE: 'CM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MIDDLE DECK',  SVG: 'CM1UT_L2',  TAG_NO: 'MBL-30310301',   TAG_DESCR: 'Air Volume Bottle', DWG: 'GM004-PI-LAY-241-70002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Topside', SN: 4,   MODULE: 'CM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MIDDLE DECK',  SVG: 'CM1UT_L2',  TAG_NO: 'MBL-30320301',   TAG_DESCR: 'Air Volume Bottle', DWG: 'GM004-PI-LAY-241-70002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Topside', SN: 5,   MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'MC004', BLOCK: 'B20PT',         DECK: 'MIDDLE DECK',  SVG: 'CM1UT_L2',  TAG_NO: 'HBG-303203',     TAG_DESCR: 'Export Compressor Disch Cooler - Train 2', DWG: 'GM004-PI-LAY-241-70002-001', PO_PLAN: '2017-01-19', PO_ACTUAL: '2017-01-19', FAT_PLAN: '2018-06-14', FAT_ACTUAL: '2018-12-10', ETA_PLAN: '2019-05-15', ETA_ACTUAL: '2019-06-08', ROS: '2019-06-14', INSTALL: '2019-06-28', UP_BLOCK_PE: '2019-06-19', ROS_ETA: 6, ROS_ETA_UPBLOCK: 11, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 6,   MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'MC004', BLOCK: 'B20PT',         DECK: 'MIDDLE DECK',  SVG: 'CM1UT_L2',  TAG_NO: 'HBG-302101',     TAG_DESCR: 'HP Compressor Suction Cooler - Train 1', DWG: 'GM004-PI-LAY-241-70002-001', PO_PLAN: '2017-01-19', PO_ACTUAL: '2017-01-19', FAT_PLAN: '2018-06-14', FAT_ACTUAL: '2019-07-04', ETA_PLAN: '2019-05-15', ETA_ACTUAL: '2019-08-28', ROS: '2019-06-14', INSTALL: '2019-10-11', UP_BLOCK_PE: '2019-06-19', ROS_ETA: -75, ROS_ETA_UPBLOCK: -70, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 7,   MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'MC004', BLOCK: 'B20PT',         DECK: 'MIDDLE DECK',  SVG: 'CM1UT_L2',  TAG_NO: 'HBG-302201',     TAG_DESCR: 'HP Compressor Suction Cooler - Train 2', DWG: 'GM004-PI-LAY-241-70002-001', PO_PLAN: '2017-01-19', PO_ACTUAL: '2017-01-19', FAT_PLAN: '2018-06-14', FAT_ACTUAL: '2019-07-11', ETA_PLAN: '2019-05-15', ETA_ACTUAL: '2019-08-28', ROS: '2019-06-14', INSTALL: '2019-10-11', UP_BLOCK_PE: '2019-06-19', ROS_ETA: -75, ROS_ETA_UPBLOCK: -70, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 8,   MODULE: 'CM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MIDDLE DECK',  SVG: 'CM1UT_L2',  TAG_NO: 'MBL-30320305',   TAG_DESCR: 'Air Volume Bottle', DWG: 'GM004-PI-LAY-241-70002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Topside', SN: 9,   MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'MC002', BLOCK: 'B20PT',         DECK: 'MIDDLE DECK',  SVG: 'CM1UT_L2',  TAG_NO: 'HBG-301101',     TAG_DESCR: 'LP Compressor Suction Cooler - Train 1', DWG: 'GM004-PI-LAY-241-70002-001', PO_PLAN: '2017-09-30', PO_ACTUAL: '2017-09-30', FAT_PLAN: '2019-03-15', FAT_ACTUAL: '2019-06-04', ETA_PLAN: '2019-06-07', ETA_ACTUAL: '2019-06-24', ROS: '2019-06-14', INSTALL: '2019-06-27', UP_BLOCK_PE: '2019-06-19', ROS_ETA: -10, ROS_ETA_UPBLOCK: -5, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 10,  MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'MC002', BLOCK: 'B20PT',         DECK: 'MIDDLE DECK',  SVG: 'CM1UT_L2',  TAG_NO: 'HBG-301201',     TAG_DESCR: 'LP Compressor Suction Cooler - Train 2', DWG: 'GM004-PI-LAY-241-70002-001', PO_PLAN: '2017-09-30', PO_ACTUAL: '2017-09-30', FAT_PLAN: '2019-03-15', FAT_ACTUAL: '2019-06-04', ETA_PLAN: '2019-06-07', ETA_ACTUAL: '2019-06-24', ROS: '2019-06-14', INSTALL: '2019-06-28', UP_BLOCK_PE: '2019-06-19', ROS_ETA: -10, ROS_ETA_UPBLOCK: -5, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 11,  MODULE: 'CM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MIDDLE DECK',  SVG: 'CM1UT_L2',  TAG_NO: 'MBL-30110301',   TAG_DESCR: 'Air Volume Bottle', DWG: 'GM004-PI-LAY-241-70002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Topside', SN: 12,  MODULE: 'CM1UT', DISC: 'EI', PKG_NO: 'SE005', BLOCK: 'CM MEZZ',       DECK: 'MIDDLE DECK',  SVG: 'CM1UT_L2',  TAG_NO: 'PNL-6160625',    TAG_DESCR: 'Fusible Loop Charge System (Compression Moudle)', DWG: 'GM004-PI-LAY-241-70002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-08', ETA_ACTUAL: '2019-03-22', ROS: '2019-05-20', INSTALL: '', UP_BLOCK_PE: '2019-05-20', ROS_ETA: 59, ROS_ETA_UPBLOCK: 59, CANBE_INST: '329', STATUS: 'ETA', },
      { AREA: 'Topside', SN: 13,  MODULE: 'CM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MIDDLE DECK',  SVG: 'CM1UT_L2',  TAG_NO: 'MBL-30120301',   TAG_DESCR: 'Air Volume Bottle', DWG: 'GM004-PI-LAY-241-70002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Topside', SN: 14,  MODULE: 'CM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MIDDLE DECK',  SVG: 'CM1UT_L2',  TAG_NO: 'MBL-32100301',   TAG_DESCR: 'Air Volume Bottle', DWG: 'GM004-PI-LAY-241-70002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Topside', SN: 15,  MODULE: 'CM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MIDDLE DECK',  SVG: 'CM1UT_L2',  TAG_NO: 'MBL-30100001',   TAG_DESCR: 'Air Volume Bottle', DWG: 'GM004-PI-LAY-241-70002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Topside', SN: 16,  MODULE: 'CM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MIDDLE DECK',  SVG: 'CM1UT_L2',  TAG_NO: 'MBL-81300505',   TAG_DESCR: 'Air Volume Bottle', DWG: 'GM004-PI-LAY-241-70002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 17,  MODULE: 'CM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MIDDLE DECK',  SVG: 'CM1UT_L2',  TAG_NO: 'MBL-81300203A',  TAG_DESCR: 'Vertical Air Receiver', DWG: 'GM004-PI-LAY-241-70001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Topside', SN: 18,  MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'ML002', BLOCK: 'B20PT',         DECK: 'MIDDLE DECK',  SVG: 'CM1UT_L2',  TAG_NO: 'HBG-321003',     TAG_DESCR: 'Gas/Glycol Heat Exchanger', DWG: 'GM004-PI-LAY-241-70002-001', PO_PLAN: '2017-01-24', PO_ACTUAL: '2017-01-24', FAT_PLAN: '2019-03-28', FAT_ACTUAL: '2019-05-01', ETA_PLAN: '2019-05-09', ETA_ACTUAL: '2019-05-31', ROS: '2019-06-14', INSTALL: '2019-07-01', UP_BLOCK_PE: '2019-06-19', ROS_ETA: 14, ROS_ETA_UPBLOCK: 19, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 19,  MODULE: 'CM1UT', DISC: 'EI', PKG_NO: 'IC008', BLOCK: 'B230T',         DECK: 'MIDDLE DECK',  SVG: 'CM1UT_L2',  TAG_NO: 'PNL-6010620',    TAG_DESCR: 'Pressure Safety Test Panel', DWG: 'GM004-PI-LAY-241-70002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-03', ETA_ACTUAL: '2019-08-12', ROS: '2019-05-23', INSTALL: '', UP_BLOCK_PE: '2019-05-31', ROS_ETA: -81, ROS_ETA_UPBLOCK: -73, CANBE_INST: '186', STATUS: 'ETA', },
      { AREA: 'Topside', SN: 1,   MODULE: 'CM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'CM1UT_L3',  TAG_NO: 'ZZZ-301120',     TAG_DESCR: 'LP/HP/Export Compressor Seal Gas Skid - Train 1', DWG: 'GM004-PI-LAY-241-70003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-08-14', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 2,   MODULE: 'CM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'CM1UT_L3',  TAG_NO: 'ZZZ-301220',     TAG_DESCR: 'LP/HP/Export Compressor Seal Gas Skid - Train 2', DWG: 'GM004-PI-LAY-241-70003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-08-14', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 3,   MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'MC004', BLOCK: 'B30PT',         DECK: 'UPPER DECK',   SVG: 'CM1UT_L3',  TAG_NO: 'HBG-302104',     TAG_DESCR: 'HP Compressor Discharge Cooler - Train 1', DWG: 'GM004-PI-LAY-241-70003-001', PO_PLAN: '2017-01-19', PO_ACTUAL: '2017-01-19', FAT_PLAN: '2018-06-14', FAT_ACTUAL: '2018-12-20', ETA_PLAN: '2019-05-15', ETA_ACTUAL: '2019-05-28', ROS: '2019-06-26', INSTALL: '2019-07-18', UP_BLOCK_PE: '', ROS_ETA: 29, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 4,   MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'MC004', BLOCK: 'B30PT',         DECK: 'UPPER DECK',   SVG: 'CM1UT_L3',  TAG_NO: 'HBG-302204',     TAG_DESCR: 'HP Compressor Discharge Cooler - Train 2', DWG: 'GM004-PI-LAY-241-70003-001', PO_PLAN: '2017-01-19', PO_ACTUAL: '2017-01-19', FAT_PLAN: '2018-06-14', FAT_ACTUAL: '2018-12-20', ETA_PLAN: '2019-05-15', ETA_ACTUAL: '2019-05-28', ROS: '2019-06-26', INSTALL: '2019-07-18', UP_BLOCK_PE: '', ROS_ETA: 29, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 5,   MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'ML007', BLOCK: 'B30PT',         DECK: 'UPPER DECK',   SVG: 'CM1UT_L3',  TAG_NO: 'ZZZ-503001',     TAG_DESCR: 'Flare Control Package', DWG: 'GM004-PI-LAY-241-70003-001', PO_PLAN: '2018-01-26', PO_ACTUAL: '2018-01-26', FAT_PLAN: '2019-03-29', FAT_ACTUAL: '2019-10-11', ETA_PLAN: '2019-06-14', ETA_ACTUAL: '2019-11-18', ROS: '2019-06-26', INSTALL: '2019-11-18', UP_BLOCK_PE: '', ROS_ETA: -145, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 6,   MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'ML007', BLOCK: 'B30PT',         DECK: 'UPPER DECK',   SVG: 'CM1UT_L3',  TAG_NO: 'ZZZ-545001',     TAG_DESCR: 'Nitrogen Snuffing System', DWG: 'GM004-PI-LAY-241-70003-001', PO_PLAN: '2018-01-26', PO_ACTUAL: '2018-01-26', FAT_PLAN: '2019-03-29', FAT_ACTUAL: '', ETA_PLAN: '2019-06-14', ETA_ACTUAL: '2019-09-11', ROS: '2019-06-26', INSTALL: '2019-11-18', UP_BLOCK_PE: '', ROS_ETA: -77, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 7,   MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'ML006', BLOCK: 'B30PT',         DECK: 'UPPER DECK',   SVG: 'CM1UT_L3',  TAG_NO: 'MAK-321001',     TAG_DESCR: 'Gas Filter Separator', DWG: 'GM004-PI-LAY-241-70003-001', PO_PLAN: '2017-09-22', PO_ACTUAL: '2017-09-22', FAT_PLAN: '2019-04-09', FAT_ACTUAL: '2019-06-20', ETA_PLAN: '2019-05-08', ETA_ACTUAL: '2019-07-22', ROS: '2019-06-26', INSTALL: '2019-08-06', UP_BLOCK_PE: '', ROS_ETA: -26, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 8,   MODULE: 'CM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'CM1UT_L3',  TAG_NO: 'MBL-30200001',   TAG_DESCR: 'Air Volume Bottle', DWG: 'GM004-PI-LAY-241-70003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Topside', SN: 9,   MODULE: 'CM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'CM1UT_L3',  TAG_NO: 'ZZZ-301100',     TAG_DESCR: 'LP/HP/Export Compressor Package - Train 1', DWG: 'GM004-PI-LAY-241-70003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-07-31', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 10,  MODULE: 'CM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'CM1UT_L3',  TAG_NO: 'ZZZ-301200',     TAG_DESCR: 'LP/HP/Export Compressor Package - Train 2', DWG: 'GM004-PI-LAY-241-70003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-07-31', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 11,  MODULE: 'CM1UT', DISC: 'EI', PKG_NO: 'IC008', BLOCK: 'B320T',         DECK: 'UPPER DECK',   SVG: 'CM1UT_L3',  TAG_NO: 'PNL-6010630',    TAG_DESCR: 'Pressure Safety Test Panel', DWG: 'GM004-PI-LAY-241-70003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-03', ETA_ACTUAL: '2019-08-12', ROS: '2019-05-30', INSTALL: '', UP_BLOCK_PE: '2019-06-26', ROS_ETA: -74, ROS_ETA_UPBLOCK: -47, CANBE_INST: '186', STATUS: 'ETA', },
      { AREA: 'Topside', SN: 12,  MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'MJ005', BLOCK: 'B30PT',         DECK: 'UPPER DECK',   SVG: 'CM1UT_L3',  TAG_NO: 'ZZZ-304100',     TAG_DESCR: 'VRU Compressor Package - Train 1', DWG: 'GM004-PI-LAY-241-70003-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2019-04-10', FAT_ACTUAL: '2020-01-16', ETA_PLAN: '2019-06-12', ETA_ACTUAL: '2020-02-11', ROS: '2019-06-19', INSTALL: '2020-02-21', UP_BLOCK_PE: '2019-06-21', ROS_ETA: -237, ROS_ETA_UPBLOCK: -235, CANBE_INST: '3', STATUS: 'INST', },
      { AREA: 'Topside', SN: 13,  MODULE: 'CM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'CM1UT_L3',  TAG_NO: 'MBL-30210504',   TAG_DESCR: 'Air Volume Bottle', DWG: 'GM004-PI-LAY-241-70003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Topside', SN: 14,  MODULE: 'CM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'CM1UT_L3',  TAG_NO: 'MBL-30410701',   TAG_DESCR: 'Air Volume Bottle', DWG: 'GM004-PI-LAY-241-70003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Topside', SN: 15,  MODULE: 'CM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'CM1UT_L3',  TAG_NO: 'ZZZ-818000',     TAG_DESCR: 'Compressor Seal Gas Conditioning Skid', DWG: 'GM004-PI-LAY-241-70003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-07-24', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 16,  MODULE: 'CM1UT', DISC: 'ME', PKG_NO: 'MJ005', BLOCK: 'B30PT',         DECK: 'UPPER DECK',   SVG: 'CM1UT_L3',  TAG_NO: 'ZZZ-304200',     TAG_DESCR: 'VRU Compressor Package - Train 2', DWG: 'GM004-PI-LAY-241-70003-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2019-04-10', FAT_ACTUAL: '2020-01-16', ETA_PLAN: '2019-06-12', ETA_ACTUAL: '2020-02-11', ROS: '2019-06-26', INSTALL: '2020-02-21', UP_BLOCK_PE: '', ROS_ETA: -230, ROS_ETA_UPBLOCK: null, CANBE_INST: '3', STATUS: 'INST', },
      { AREA: 'Topside', SN: 17,  MODULE: 'CM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'CM1UT_L3',  TAG_NO: 'MBL-30220504',   TAG_DESCR: 'Air Volume Bottle', DWG: 'GM004-PI-LAY-241-70003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Topside', SN: 18,  MODULE: 'CM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'CM1UT_L3',  TAG_NO: 'MBL-30420701',   TAG_DESCR: 'Air Volume Bottle', DWG: 'GM004-PI-LAY-241-70003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Topside', SN: 1,   MODULE: 'GM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'LOWER DECK',   SVG: 'GM1UT_L1',  TAG_NO: 'ZZZ-866350',     TAG_DESCR: 'Liquid Fuel Skid', DWG: 'GM004-PI-LAY-241-90001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-05-28', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 2,   MODULE: 'GM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'LOWER DECK',   SVG: 'GM1UT_L1',  TAG_NO: 'ZZZ-866250',     TAG_DESCR: 'Liquid Fuel Skid', DWG: 'GM004-PI-LAY-241-90001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-05-28', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 3,   MODULE: 'GM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'LOWER DECK',   SVG: 'GM1UT_L1',  TAG_NO: 'ZZZ-866150',     TAG_DESCR: 'Liquid Fuel Skid', DWG: 'GM004-PI-LAY-241-90001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-05-28', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 4,   MODULE: 'GM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'LOWER DECK',   SVG: 'GM1UT_L1',  TAG_NO: 'VLRN-866301',    TAG_DESCR: 'Neutral Grounding Resistor', DWG: 'GM004-PI-LAY-241-90001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Topside', SN: 5,   MODULE: 'GM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'LOWER DECK',   SVG: 'GM1UT_L1',  TAG_NO: 'VLRN-866201',    TAG_DESCR: 'Neutral Grounding Resistor', DWG: 'GM004-PI-LAY-241-90001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Topside', SN: 6,   MODULE: 'GM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'LOWER DECK',   SVG: 'GM1UT_L1',  TAG_NO: 'VLRN-866101',    TAG_DESCR: 'Neutral Grounding Resistor', DWG: 'GM004-PI-LAY-241-90001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Topside', SN: 7,   MODULE: 'GM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'LOWER DECK',   SVG: 'GM1UT_L1',  TAG_NO: 'ZZZ-866100',     TAG_DESCR: 'Turbine Generator Set', DWG: 'GM004-PI-LAY-241-90001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-06-06', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 8,   MODULE: 'GM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'LOWER DECK',   SVG: 'GM1UT_L1',  TAG_NO: 'EBN-804107',     TAG_DESCR: 'WHRU No.1', DWG: 'GM004-PI-LAY-241-90001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-09-11', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 9,   MODULE: 'GM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'LOWER DECK',   SVG: 'GM1UT_L1',  TAG_NO: 'ZZZ-866200',     TAG_DESCR: 'Turbine Generator Set', DWG: 'GM004-PI-LAY-241-90001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-06-06', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 10,  MODULE: 'GM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'LOWER DECK',   SVG: 'GM1UT_L1',  TAG_NO: 'EBN-804208',     TAG_DESCR: 'WHRU No.2', DWG: 'GM004-PI-LAY-241-90001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-09-19', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 11,  MODULE: 'GM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'LOWER DECK',   SVG: 'GM1UT_L1',  TAG_NO: 'ZZZ-866300',     TAG_DESCR: 'Turbine Generator Set', DWG: 'GM004-PI-LAY-241-90001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-06-06', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 12,  MODULE: 'GM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'LOWER DECK',   SVG: 'GM1UT_L1',  TAG_NO: 'EBN-804309',     TAG_DESCR: 'WHRU No.3', DWG: 'GM004-PI-LAY-241-90001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-09-24', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 1,   MODULE: 'GM1UT', DISC: 'ME', PKG_NO: 'MU001', BLOCK: 'G20PT',         DECK: 'UPPER DECK',   SVG: 'GM1UT_L2',  TAG_NO: 'MAJ-804005',     TAG_DESCR: 'Heating Medium Filter', DWG: 'GM004-PI-LAY-241-90002-001', PO_PLAN: '2017-09-28', PO_ACTUAL: '2017-09-28', FAT_PLAN: '2019-01-10', FAT_ACTUAL: '2019-03-14', ETA_PLAN: '2019-04-04', ETA_ACTUAL: '2019-05-02', ROS: '2019-06-11', INSTALL: '2019-07-25', UP_BLOCK_PE: '2019-06-12', ROS_ETA: 40, ROS_ETA_UPBLOCK: 41, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 2,   MODULE: 'GM1UT', DISC: 'ME', PKG_NO: 'MD006', BLOCK: 'G210F',         DECK: 'UPPER DECK',   SVG: 'GM1UT_L2',  TAG_NO: 'MBJ-804001',     TAG_DESCR: 'Heating Medium Expansion Tank', DWG: 'GM004-PI-LAY-241-90002-001', PO_PLAN: '2017-08-22', PO_ACTUAL: '2017-08-24', FAT_PLAN: '2018-07-23', FAT_ACTUAL: '2018-07-24', ETA_PLAN: '2019-04-07', ETA_ACTUAL: '2019-02-20', ROS: '2019-06-12', INSTALL: '2019-08-01', UP_BLOCK_PE: '2019-06-12', ROS_ETA: 112, ROS_ETA_UPBLOCK: 112, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 3,   MODULE: 'GM1UT', DISC: 'EI', PKG_NO: 'SE005', BLOCK: 'G220T',         DECK: 'UPPER DECK',   SVG: 'GM1UT_L2',  TAG_NO: 'PNL-6160835',    TAG_DESCR: 'Fusible Loop Charge System (Generation Module)', DWG: 'GM004-PI-LAY-241-90002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-08', ETA_ACTUAL: '2019-03-22', ROS: '2019-05-15', INSTALL: '', UP_BLOCK_PE: '2019-06-10', ROS_ETA: 54, ROS_ETA_UPBLOCK: 80, CANBE_INST: '329', STATUS: 'ETA', },
      { AREA: 'Topside', SN: 4,   MODULE: 'GM1UT', DISC: 'ME', PKG_NO: 'MJ010', BLOCK: 'G20PT',         DECK: 'UPPER DECK',   SVG: 'GM1UT_L2',  TAG_NO: 'PBE-804002',     TAG_DESCR: 'Heating Medium Circulation Pump', DWG: 'GM004-PI-LAY-241-90002-001', PO_PLAN: '2017-10-16', PO_ACTUAL: '2017-10-16', FAT_PLAN: '2019-01-28', FAT_ACTUAL: '2019-04-08', ETA_PLAN: '2019-04-01', ETA_ACTUAL: '2019-08-12', ROS: '2019-06-11', INSTALL: '2019-08-24', UP_BLOCK_PE: '', ROS_ETA: -62, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 5,   MODULE: 'GM1UT', DISC: 'ME', PKG_NO: 'MJ010', BLOCK: 'G20PT',         DECK: 'UPPER DECK',   SVG: 'GM1UT_L2',  TAG_NO: 'PBE-804003',     TAG_DESCR: 'Heating Medium Circulation Pump', DWG: 'GM004-PI-LAY-241-90002-001', PO_PLAN: '2017-10-16', PO_ACTUAL: '2017-10-16', FAT_PLAN: '2019-01-28', FAT_ACTUAL: '2019-04-08', ETA_PLAN: '2019-04-01', ETA_ACTUAL: '2019-08-12', ROS: '2019-06-11', INSTALL: '2019-08-24', UP_BLOCK_PE: '', ROS_ETA: -62, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 6,   MODULE: 'GM1UT', DISC: 'ME', PKG_NO: 'MJ010', BLOCK: 'G20PT',         DECK: 'UPPER DECK',   SVG: 'GM1UT_L2',  TAG_NO: 'PBE-804004',     TAG_DESCR: 'Heating Medium Circulation Pump', DWG: 'GM004-PI-LAY-241-90002-001', PO_PLAN: '2017-10-16', PO_ACTUAL: '2017-10-16', FAT_PLAN: '2019-01-28', FAT_ACTUAL: '2019-04-08', ETA_PLAN: '2019-04-01', ETA_ACTUAL: '2019-08-12', ROS: '2019-06-11', INSTALL: '2019-08-24', UP_BLOCK_PE: '', ROS_ETA: -62, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 7,   MODULE: 'GM1UT', DISC: 'EI', PKG_NO: 'EL003', BLOCK: 'Top',           DECK: 'UPPER DECK',   SVG: 'GM1UT_L2',  TAG_NO: 'VTS-682001A',    TAG_DESCR: '13.8KV – 480V Power Transformer 1500/1680KVA (55/65°c) ONAN,  1932KVA ONAF', DWG: 'GM004-PI-LAY-241-90002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-17', ETA_ACTUAL: '2019-05-10', ROS: '2019-06-20', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: 41, ROS_ETA_UPBLOCK: null, CANBE_INST: '280', STATUS: 'ETA', },
      { AREA: 'Topside', SN: 8,   MODULE: 'GM1UT', DISC: 'EI', PKG_NO: 'EL003', BLOCK: 'Top',           DECK: 'UPPER DECK',   SVG: 'GM1UT_L2',  TAG_NO: 'VTS-682002',     TAG_DESCR: '4.16KV – 480V Power Transformer 1500/1680KVA (55/65°c) ONAN,  1932KVA ONAF', DWG: 'GM004-PI-LAY-241-90002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-17', ETA_ACTUAL: '2019-05-10', ROS: '2019-06-20', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: 41, ROS_ETA_UPBLOCK: null, CANBE_INST: '280', STATUS: 'ETA', },
      { AREA: 'Topside', SN: 9,   MODULE: 'GM1UT', DISC: 'EI', PKG_NO: 'EL003', BLOCK: 'Top',           DECK: 'UPPER DECK',   SVG: 'GM1UT_L2',  TAG_NO: 'VTS-682001B',    TAG_DESCR: '13.8KV – 480V Power Transformer 1500/1680KVA (55/65°c) ONAN,  1932KVA ONAF', DWG: 'GM004-PI-LAY-241-90002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-17', ETA_ACTUAL: '2019-05-10', ROS: '2019-06-20', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: 41, ROS_ETA_UPBLOCK: null, CANBE_INST: '280', STATUS: 'ETA', },
      { AREA: 'Topside', SN: 10,  MODULE: 'GM1UT', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'G120E',         DECK: 'UPPER DECK',   SVG: 'GM1UT_L2',  TAG_NO: 'HEF-692070',     TAG_DESCR: 'Power Generation Module - Battery Exhaust A', DWG: 'GM004-PI-LAY-241-90002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-11-29', ROS: '2019-09-23', INSTALL: '2020-01-22', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '77', STATUS: 'INST', },
      { AREA: 'Topside', SN: 11,  MODULE: 'GM1UT', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'G120E',         DECK: 'UPPER DECK',   SVG: 'GM1UT_L2',  TAG_NO: 'HCN-692045',     TAG_DESCR: 'Supply Battery Room Power Generation Module', DWG: 'GM004-PI-LAY-241-90002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-07-10', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-10-15', ROS: '2019-09-23', INSTALL: '2019-10-07', UP_BLOCK_PE: '', ROS_ETA: -22, ROS_ETA_UPBLOCK: null, CANBE_INST: '122', STATUS: 'INST', },
      { AREA: 'Topside', SN: 12,  MODULE: 'GM1UT', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'G120E',         DECK: 'UPPER DECK',   SVG: 'GM1UT_L2',  TAG_NO: 'HCN-692055',     TAG_DESCR: 'Supply Battery Room Power Generation Module', DWG: 'GM004-PI-LAY-241-90002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-08-13', ROS: '2019-09-23', INSTALL: '2019-10-21', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '185', STATUS: 'INST', },
      { AREA: 'Topside', SN: 13,  MODULE: 'GM1UT', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'G120E',         DECK: 'UPPER DECK',   SVG: 'GM1UT_L2',  TAG_NO: 'HEF-692075',     TAG_DESCR: 'Power Generation Module - Battery Exhaust B', DWG: 'GM004-PI-LAY-241-90002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-11-29', ROS: '2019-09-23', INSTALL: '2020-01-22', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '77', STATUS: 'INST', },
      { AREA: 'Topside', SN: 14,  MODULE: 'GM1UT', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'G230F',         DECK: 'UPPER DECK',   SVG: 'GM1UT_L2',  TAG_NO: 'HAU-692040',     TAG_DESCR: 'Supply Battery Room Power Generation Module', DWG: 'GM004-PI-LAY-241-90002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-09', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-06-21', ROS: '2019-04-10', INSTALL: '2019-10-07', UP_BLOCK_PE: '', ROS_ETA: -72, ROS_ETA_UPBLOCK: null, CANBE_INST: '238', STATUS: 'INST', },
      { AREA: 'Topside', SN: 15,  MODULE: 'GM1UT', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'G120E',         DECK: 'UPPER DECK',   SVG: 'GM1UT_L2',  TAG_NO: 'HAU-692050',     TAG_DESCR: 'Supply Battery Room Power Generation Module', DWG: 'GM004-PI-LAY-241-90002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-05-10', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-08-26', ROS: '2019-04-10', INSTALL: '2019-06-14', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '172', STATUS: 'INST', },
      { AREA: 'Topside', SN: 16,  MODULE: 'GM1UT', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'G120E',         DECK: 'UPPER DECK',   SVG: 'GM1UT_L2',  TAG_NO: 'HSF-692060',     TAG_DESCR: 'Power Generation Module - Battery Pressurization A', DWG: 'GM004-PI-LAY-241-90002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2020-01-07', ROS: '2019-09-23', INSTALL: '2020-01-18', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '38', STATUS: 'INST', },
      { AREA: 'Topside', SN: 17,  MODULE: 'GM1UT', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'G120E',         DECK: 'UPPER DECK',   SVG: 'GM1UT_L2',  TAG_NO: 'HSF-692065',     TAG_DESCR: 'Power Generation Module - Battery Pressurization B', DWG: 'GM004-PI-LAY-241-90002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2020-01-07', ROS: '2019-09-23', INSTALL: '2020-01-18', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '38', STATUS: 'INST', },
      { AREA: 'Topside', SN: 18,  MODULE: 'GM1UT', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'G120E',         DECK: 'UPPER DECK',   SVG: 'GM1UT_L2',  TAG_NO: 'HSF-692030',     TAG_DESCR: 'Power Generation Module - Pressurization A', DWG: 'GM004-PI-LAY-241-90002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2020-01-07', ROS: '2019-09-23', INSTALL: '2020-01-21', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '38', STATUS: 'INST', },
      { AREA: 'Topside', SN: 19,  MODULE: 'GM1UT', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'G120E',         DECK: 'UPPER DECK',   SVG: 'GM1UT_L2',  TAG_NO: 'HSF-692035',     TAG_DESCR: 'Power Generation Module - Pressurization B', DWG: 'GM004-PI-LAY-241-90002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2020-01-07', ROS: '2019-09-23', INSTALL: '2020-01-21', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '38', STATUS: 'INST', },
      { AREA: 'Topside', SN: 20,  MODULE: 'GM1UT', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'G120E',         DECK: 'UPPER DECK',   SVG: 'GM1UT_L2',  TAG_NO: 'HAU-692020',     TAG_DESCR: 'Supply Electrical Room Power Generation Module', DWG: 'GM004-PI-LAY-241-90002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-10-21', ROS: '2019-09-23', INSTALL: '2019-10-05', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '116', STATUS: 'INST', },
      { AREA: 'Topside', SN: 21,  MODULE: 'GM1UT', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'G120E',         DECK: 'UPPER DECK',   SVG: 'GM1UT_L2',  TAG_NO: 'HAU-692010',     TAG_DESCR: 'Supply Electrical Room Power Generation Module', DWG: 'GM004-PI-LAY-241-90002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-08-13', ROS: '2019-09-23', INSTALL: '2019-10-05', UP_BLOCK_PE: '', ROS_ETA: 41, ROS_ETA_UPBLOCK: null, CANBE_INST: '185', STATUS: 'INST', },
      { AREA: 'Topside', SN: 22,  MODULE: 'GM1UT', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'G260T',         DECK: 'UPPER DECK',   SVG: 'GM1UT_L2',  TAG_NO: 'HCN-692015',     TAG_DESCR: 'Supply Electrical Room Power Generation Module', DWG: 'GM004-PI-LAY-241-90002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-07-24', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-10-08', ROS: '2019-09-23', INSTALL: '2019-10-21', UP_BLOCK_PE: '', ROS_ETA: -15, ROS_ETA_UPBLOCK: null, CANBE_INST: '129', STATUS: 'INST', },
      { AREA: 'Topside', SN: 23,  MODULE: 'GM1UT', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'G260T',         DECK: 'UPPER DECK',   SVG: 'GM1UT_L2',  TAG_NO: 'HCN-692025',     TAG_DESCR: 'Supply Electrical Room Power Generation Module', DWG: 'GM004-PI-LAY-241-90002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-10-12', ROS: '2019-09-23', INSTALL: '2019-10-21', UP_BLOCK_PE: '', ROS_ETA: -19, ROS_ETA_UPBLOCK: null, CANBE_INST: '125', STATUS: 'INST', },
      { AREA: 'Topside', SN: 24,  MODULE: 'GM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'GM1UT_L2',  TAG_NO: 'ZZZ-803010',     TAG_DESCR: 'Turbine Generator Wash Water Skid', DWG: 'GM004-PI-LAY-241-90002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-07-26', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 25,  MODULE: 'GM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'GM1UT_L2',  TAG_NO: 'ZZZ-866121',     TAG_DESCR: 'Synthetic Oil Vapour Separator', DWG: 'GM004-PI-LAY-241-90002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-07-26', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 26,  MODULE: 'GM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'GM1UT_L2',  TAG_NO: 'ABJ-866130',     TAG_DESCR: 'Overhead Accumulator Pipe - Unit A', DWG: 'GM004-PI-LAY-241-90002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-07-30', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 27,  MODULE: 'GM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'GM1UT_L2',  TAG_NO: 'ZZZ-866160',     TAG_DESCR: 'Water Mist Fire Suppression System', DWG: 'GM004-PI-LAY-241-90002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-07-26', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 28,  MODULE: 'GM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'GM1UT_L2',  TAG_NO: 'ZZZ-866221',     TAG_DESCR: 'Synthetic Oil Vapour Separator', DWG: 'GM004-PI-LAY-241-90002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-07-26', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 29,  MODULE: 'GM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'GM1UT_L2',  TAG_NO: 'ABJ-866230',     TAG_DESCR: 'Overhead Accumulator Pipe - Unit B', DWG: 'GM004-PI-LAY-241-90002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-07-30', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 30,  MODULE: 'GM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'GM1UT_L2',  TAG_NO: 'ZZZ-866260',     TAG_DESCR: 'Water Mist Fire Suppression System', DWG: 'GM004-PI-LAY-241-90002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-07-26', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 31,  MODULE: 'GM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'GM1UT_L2',  TAG_NO: 'ZZZ-866321',     TAG_DESCR: 'Synthetic Oil Vapour Separator', DWG: 'GM004-PI-LAY-241-90002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-07-26', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 32,  MODULE: 'GM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'GM1UT_L2',  TAG_NO: 'ABJ-866330',     TAG_DESCR: 'Overhead Accumulator Pipe - Unit C', DWG: 'GM004-PI-LAY-241-90002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-07-30', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 33,  MODULE: 'GM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'GM1UT_L2',  TAG_NO: 'ZZZ-866360',     TAG_DESCR: 'Water Mist Fire Suppression System', DWG: 'GM004-PI-LAY-241-90002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-07-26', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 1,   MODULE: 'PM1UT', DISC: 'EI', PKG_NO: 'IC008', BLOCK: 'A110T',         DECK: 'LOWER DECK',   SVG: 'PM1UT_L1',  TAG_NO: 'PNL-6010510',    TAG_DESCR: 'Pressure Safety Test Panel', DWG: 'GM004-PI-LAY-241-60001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-03', ETA_ACTUAL: '2019-08-12', ROS: '2019-05-16', INSTALL: '2020-01-01', UP_BLOCK_PE: '2019-05-27', ROS_ETA: -88, ROS_ETA_UPBLOCK: -77, CANBE_INST: '186', STATUS: 'INST', },
      { AREA: 'Topside', SN: 2,   MODULE: 'PM1UT', DISC: 'ME', PKG_NO: 'ML004', BLOCK: 'A110T',         DECK: 'LOWER DECK',   SVG: 'PM1UT_L1',  TAG_NO: 'ZZZ-413000',     TAG_DESCR: 'Flotation Cell Package', DWG: 'GM004-PI-LAY-241-60001-001', PO_PLAN: '2017-06-23', PO_ACTUAL: '2017-06-23', FAT_PLAN: '2019-02-27', FAT_ACTUAL: '2019-04-15', ETA_PLAN: '2019-05-08', ETA_ACTUAL: '2019-05-16', ROS: '2019-05-15', INSTALL: '2019-06-04', UP_BLOCK_PE: '2019-05-21', ROS_ETA: -1, ROS_ETA_UPBLOCK: 5, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 3,   MODULE: 'PM1UT', DISC: 'ME', PKG_NO: 'MJ010', BLOCK: 'A110T',         DECK: 'LOWER DECK',   SVG: 'PM1UT_L1',  TAG_NO: 'PBA-423001',     TAG_DESCR: 'Polishing Booster Pump', DWG: 'GM004-PI-LAY-241-60001-001', PO_PLAN: '2017-10-16', PO_ACTUAL: '2017-10-16', FAT_PLAN: '2019-01-28', FAT_ACTUAL: '2019-08-28', ETA_PLAN: '2019-04-01', ETA_ACTUAL: '2019-11-11', ROS: '2019-05-18', INSTALL: '2019-11-18', UP_BLOCK_PE: '2019-05-21', ROS_ETA: -177, ROS_ETA_UPBLOCK: -174, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 4,   MODULE: 'PM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'LOWER DECK',   SVG: 'PM1UT_L1',  TAG_NO: 'PBA-423002',     TAG_DESCR: 'Polishing Booster Pump (Future)', DWG: 'GM004-PI-LAY-241-60001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Topside', SN: 5,   MODULE: 'PM1UT', DISC: 'ME', PKG_NO: 'MD005', BLOCK: 'A110T',         DECK: 'LOWER DECK',   SVG: 'PM1UT_L1',  TAG_NO: 'MBK-222002',     TAG_DESCR: 'Bulk Oil Treater', DWG: 'GM004-PI-LAY-241-60001-001', PO_PLAN: '2017-01-24', PO_ACTUAL: '2017-01-24', FAT_PLAN: '2019-03-27', FAT_ACTUAL: '2019-01-31', ETA_PLAN: '2019-05-08', ETA_ACTUAL: '2019-04-30', ROS: '2019-05-15', INSTALL: '2019-06-18', UP_BLOCK_PE: '2019-05-21', ROS_ETA: 15, ROS_ETA_UPBLOCK: 21, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 6,   MODULE: 'PM1UT', DISC: 'ME', PKG_NO: 'MD005', BLOCK: 'A110T',         DECK: 'LOWER DECK',   SVG: 'PM1UT_L1',  TAG_NO: 'VTN-222002',     TAG_DESCR: 'PWR Transf.100 KVA (For Bulk Oil Treater)', DWG: 'GM004-PI-LAY-241-60001-001', PO_PLAN: '2017-01-24', PO_ACTUAL: '2017-01-24', FAT_PLAN: '2019-03-27', FAT_ACTUAL: '2019-01-31', ETA_PLAN: '2019-05-08', ETA_ACTUAL: '2019-04-30', ROS: '2019-05-18', INSTALL: '2019-06-08', UP_BLOCK_PE: '2019-05-21', ROS_ETA: 18, ROS_ETA_UPBLOCK: 21, CANBE_INST: '290', STATUS: 'INST', },
      { AREA: 'Topside', SN: 7,   MODULE: 'PM1UT', DISC: 'ME', PKG_NO: 'MD005', BLOCK: 'A110T',         DECK: 'LOWER DECK',   SVG: 'PM1UT_L1',  TAG_NO: 'MAM-222001',     TAG_DESCR: 'Free Water Knockout', DWG: 'GM004-PI-LAY-241-60001-001', PO_PLAN: '2017-01-24', PO_ACTUAL: '2017-01-24', FAT_PLAN: '2019-03-27', FAT_ACTUAL: '2019-01-31', ETA_PLAN: '2019-05-08', ETA_ACTUAL: '2019-04-30', ROS: '2019-05-15', INSTALL: '2019-06-25', UP_BLOCK_PE: '2019-05-21', ROS_ETA: 15, ROS_ETA_UPBLOCK: 21, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 8,   MODULE: 'PM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'LOWER DECK',   SVG: 'PM1UT_L1',  TAG_NO: 'ZZZ-245100',     TAG_DESCR: 'Oil Pipeline Pump Package', DWG: 'GM004-PI-LAY-241-60001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-06-21', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 9,   MODULE: 'PM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'LOWER DECK',   SVG: 'PM1UT_L1',  TAG_NO: 'ZZZ-245200',     TAG_DESCR: 'Oil Pipeline Pump Package', DWG: 'GM004-PI-LAY-241-60001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-06-21', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 10,  MODULE: 'PM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'LOWER DECK',   SVG: 'PM1UT_L1',  TAG_NO: 'ZZZ-245300',     TAG_DESCR: 'Oil Pipeline Pump Package', DWG: 'GM004-PI-LAY-241-60001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-06-21', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 11,  MODULE: 'PM1UT', DISC: 'ME', PKG_NO: 'MD001', BLOCK: 'A130T',         DECK: 'LOWER DECK',   SVG: 'PM1UT_L1',  TAG_NO: 'MBD-221001',     TAG_DESCR: 'HP Separator', DWG: 'GM004-PI-LAY-241-60001-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-11-30', FAT_ACTUAL: '2019-01-31', ETA_PLAN: '2019-05-10', ETA_ACTUAL: '2019-05-04', ROS: '2019-05-17', INSTALL: '2019-06-18', UP_BLOCK_PE: '2019-05-24', ROS_ETA: 13, ROS_ETA_UPBLOCK: 20, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 12,  MODULE: 'PM1UT', DISC: 'ME', PKG_NO: 'MJ010', BLOCK: 'A130T',         DECK: 'LOWER DECK',   SVG: 'PM1UT_L1',  TAG_NO: 'PBA-242101',     TAG_DESCR: 'Lact Booster Pump', DWG: 'GM004-PI-LAY-241-60001-001', PO_PLAN: '2017-10-16', PO_ACTUAL: '2017-10-16', FAT_PLAN: '2019-01-28', FAT_ACTUAL: '2019-04-08', ETA_PLAN: '2019-04-01', ETA_ACTUAL: '2019-07-22', ROS: '2019-05-17', INSTALL: '2019-07-22', UP_BLOCK_PE: '2019-05-24', ROS_ETA: -66, ROS_ETA_UPBLOCK: -59, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 13,  MODULE: 'PM1UT', DISC: 'ME', PKG_NO: 'MJ010', BLOCK: 'A130T',         DECK: 'LOWER DECK',   SVG: 'PM1UT_L1',  TAG_NO: 'PBA-242201',     TAG_DESCR: 'Lact Booster Pump', DWG: 'GM004-PI-LAY-241-60001-001', PO_PLAN: '2017-10-16', PO_ACTUAL: '2017-10-16', FAT_PLAN: '2019-01-28', FAT_ACTUAL: '2019-04-08', ETA_PLAN: '2019-04-01', ETA_ACTUAL: '2019-07-22', ROS: '2019-05-17', INSTALL: '2019-07-22', UP_BLOCK_PE: '2019-05-24', ROS_ETA: -66, ROS_ETA_UPBLOCK: -59, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 14,  MODULE: 'PM1UT', DISC: 'ME', PKG_NO: 'MJ010', BLOCK: 'A130T',         DECK: 'LOWER DECK',   SVG: 'PM1UT_L1',  TAG_NO: 'PBA-242301',     TAG_DESCR: 'Lact Booster Pump', DWG: 'GM004-PI-LAY-241-60001-001', PO_PLAN: '2017-10-16', PO_ACTUAL: '2017-10-16', FAT_PLAN: '2019-01-28', FAT_ACTUAL: '2019-04-08', ETA_PLAN: '2019-04-01', ETA_ACTUAL: '2019-07-22', ROS: '2019-05-17', INSTALL: '2019-07-22', UP_BLOCK_PE: '2019-05-24', ROS_ETA: -66, ROS_ETA_UPBLOCK: -59, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 1,   MODULE: 'PM1UT', DISC: 'ME', PKG_NO: 'MJ011', BLOCK: 'A210F',         DECK: 'MIDDLE DECK',  SVG: 'PM1UT_L2',  TAG_NO: 'PBE-806012',     TAG_DESCR: 'Diesel Fuel Charge Pump', DWG: 'GM004-PI-LAY-241-60002-001', PO_PLAN: '2017-08-17', PO_ACTUAL: '2017-08-17', FAT_PLAN: '2019-01-18', FAT_ACTUAL: '2019-04-26', ETA_PLAN: '2019-03-29', ETA_ACTUAL: '2019-07-19', ROS: '2019-06-03', INSTALL: '2019-07-21', UP_BLOCK_PE: '2019-06-19', ROS_ETA: -46, ROS_ETA_UPBLOCK: -30, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 2,   MODULE: 'PM1UT', DISC: 'ME', PKG_NO: 'MJ011', BLOCK: 'A210F',         DECK: 'MIDDLE DECK',  SVG: 'PM1UT_L2',  TAG_NO: 'PBE-806011',     TAG_DESCR: 'Diesel Fuel Charge Pump', DWG: 'GM004-PI-LAY-241-60002-001', PO_PLAN: '2017-08-17', PO_ACTUAL: '2017-08-17', FAT_PLAN: '2019-01-18', FAT_ACTUAL: '2019-04-26', ETA_PLAN: '2019-03-29', ETA_ACTUAL: '2019-07-19', ROS: '2019-06-03', INSTALL: '2019-07-21', UP_BLOCK_PE: '2019-06-19', ROS_ETA: -46, ROS_ETA_UPBLOCK: -30, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 3,   MODULE: 'PM1UT', DISC: 'ME', PKG_NO: 'ML003', BLOCK: 'A210T',         DECK: 'MIDDLE DECK',  SVG: 'PM1UT_L2',  TAG_NO: 'MBM-412001',     TAG_DESCR: 'HP Hydrocyclone', DWG: 'GM004-PI-LAY-241-60002-001', PO_PLAN: '2017-09-22', PO_ACTUAL: '2017-09-22', FAT_PLAN: '2018-11-30', FAT_ACTUAL: '2018-12-26', ETA_PLAN: '2019-05-21', ETA_ACTUAL: '2019-04-26', ROS: '2019-05-26', INSTALL: '2019-07-09', UP_BLOCK_PE: '2019-05-27', ROS_ETA: 30, ROS_ETA_UPBLOCK: 31, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 4,   MODULE: 'PM1UT', DISC: 'ME', PKG_NO: 'ML003', BLOCK: 'A210T',         DECK: 'MIDDLE DECK',  SVG: 'PM1UT_L2',  TAG_NO: 'MBM-412002',     TAG_DESCR: 'FWKO Hydrocyclone', DWG: 'GM004-PI-LAY-241-60002-001', PO_PLAN: '2017-09-22', PO_ACTUAL: '2017-09-22', FAT_PLAN: '2018-11-30', FAT_ACTUAL: '2018-12-26', ETA_PLAN: '2019-05-21', ETA_ACTUAL: '2019-04-26', ROS: '2019-05-26', INSTALL: '2019-07-09', UP_BLOCK_PE: '2019-05-27', ROS_ETA: 30, ROS_ETA_UPBLOCK: 31, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 5,   MODULE: 'PM1UT', DISC: 'ME', PKG_NO: 'ML003', BLOCK: 'A210T',         DECK: 'MIDDLE DECK',  SVG: 'PM1UT_L2',  TAG_NO: 'MBM-412003',     TAG_DESCR: 'Treater Hydrocyclone', DWG: 'GM004-PI-LAY-241-60002-001', PO_PLAN: '2017-09-22', PO_ACTUAL: '2017-09-22', FAT_PLAN: '2018-11-30', FAT_ACTUAL: '2018-11-30', ETA_PLAN: '2019-05-21', ETA_ACTUAL: '2019-04-26', ROS: '2019-05-26', INSTALL: '2019-07-09', UP_BLOCK_PE: '2019-05-27', ROS_ETA: 30, ROS_ETA_UPBLOCK: 31, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 6,   MODULE: 'PM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MIDDLE DECK',  SVG: 'PM1UT_L2',  TAG_NO: 'MBL-22200107',   TAG_DESCR: 'Air Volume Bottle', DWG: 'GM004-PI-LAY-241-60002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Topside', SN: 7,   MODULE: 'PM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MIDDLE DECK',  SVG: 'PM1UT_L2',  TAG_NO: 'SPSN-4310001',   TAG_DESCR: 'PM Haz. Open Drain Seal Pot', DWG: 'GM004-PI-LAY-241-60002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Topside', SN: 8,   MODULE: 'PM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MIDDLE DECK',  SVG: 'PM1UT_L2',  TAG_NO: 'PNL-6160525',    TAG_DESCR: 'Fusible Loop Charge Panel With Doubler Plates', DWG: 'GM004-PI-LAY-241-60002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 9,   MODULE: 'PM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'MIDDLE DECK',  SVG: 'PM1UT_L2',  TAG_NO: 'MBL-22100107',   TAG_DESCR: 'Air Volume Bottle', DWG: 'GM004-PI-LAY-241-60002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Topside', SN: 10,  MODULE: 'PM1UT', DISC: 'ME', PKG_NO: 'MC003', BLOCK: 'A220T',         DECK: 'MIDDLE DECK',  SVG: 'PM1UT_L2',  TAG_NO: 'HHP-243001A',    TAG_DESCR: 'Sales Oil Export Cooler', DWG: 'GM004-PI-LAY-241-60002-001', PO_PLAN: '2017-06-23', PO_ACTUAL: '2017-06-23', FAT_PLAN: '2019-03-06', FAT_ACTUAL: '2019-01-21', ETA_PLAN: '2019-05-22', ETA_ACTUAL: '2019-06-25', ROS: '2019-05-29', INSTALL: '2019-07-21', UP_BLOCK_PE: '2019-06-19', ROS_ETA: -27, ROS_ETA_UPBLOCK: -6, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 11,  MODULE: 'PM1UT', DISC: 'ME', PKG_NO: 'MC003', BLOCK: 'A220T',         DECK: 'MIDDLE DECK',  SVG: 'PM1UT_L2',  TAG_NO: 'HHP-243001B',    TAG_DESCR: 'Sales Oil Export Cooler', DWG: 'GM004-PI-LAY-241-60002-001', PO_PLAN: '2017-06-23', PO_ACTUAL: '2017-06-23', FAT_PLAN: '2019-03-06', FAT_ACTUAL: '2019-01-21', ETA_PLAN: '2019-05-22', ETA_ACTUAL: '2019-06-25', ROS: '2019-05-29', INSTALL: '2019-07-21', UP_BLOCK_PE: '2019-06-19', ROS_ETA: -27, ROS_ETA_UPBLOCK: -6, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 12,  MODULE: 'PM1UT', DISC: 'ME', PKG_NO: 'MC003', BLOCK: 'A220T',         DECK: 'MIDDLE DECK',  SVG: 'PM1UT_L2',  TAG_NO: 'HHP-245001',     TAG_DESCR: 'Sales Oil Recycle Cooler', DWG: 'GM004-PI-LAY-241-60002-001', PO_PLAN: '2017-06-23', PO_ACTUAL: '2017-06-23', FAT_PLAN: '2019-03-06', FAT_ACTUAL: '2019-01-21', ETA_PLAN: '2019-05-22', ETA_ACTUAL: '2019-06-25', ROS: '2019-05-29', INSTALL: '2019-07-21', UP_BLOCK_PE: '2019-06-19', ROS_ETA: -27, ROS_ETA_UPBLOCK: -6, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 13,  MODULE: 'PM1UT', DISC: 'EI', PKG_NO: 'IC008', BLOCK: 'A240T',         DECK: 'MIDDLE DECK',  SVG: 'PM1UT_L2',  TAG_NO: 'PNL-6010520',    TAG_DESCR: 'Pressure Safety Test Panel', DWG: 'GM004-PI-LAY-241-60002-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-03', ETA_ACTUAL: '2019-10-15', ROS: '2019-05-30', INSTALL: '2020-01-01', UP_BLOCK_PE: '2019-05-31', ROS_ETA: -138, ROS_ETA_UPBLOCK: -137, CANBE_INST: '122', STATUS: 'INST', },
      { AREA: 'Topside', SN: 1,   MODULE: 'PM1UT', DISC: 'ME', PKG_NO: 'MD004', BLOCK: 'A30PT',         DECK: 'UPPER DECK',   SVG: 'PM1UT_L3',  TAG_NO: 'KZZ-246001',     TAG_DESCR: 'Sales Oil Pipeline Pig Launcher', DWG: 'GM004-PI-LAY-241-60003-001', PO_PLAN: '2017-06-30', PO_ACTUAL: '2017-06-30', FAT_PLAN: '2019-04-03', FAT_ACTUAL: '2019-03-25', ETA_PLAN: '2019-06-12', ETA_ACTUAL: '2019-07-29', ROS: '2019-06-19', INSTALL: '2019-08-05', UP_BLOCK_PE: '2019-06-20', ROS_ETA: -40, ROS_ETA_UPBLOCK: -39, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 2,   MODULE: 'PM1UT', DISC: 'ME', PKG_NO: 'MD004', BLOCK: 'A30PT',         DECK: 'UPPER DECK',   SVG: 'PM1UT_L3',  TAG_NO: 'KZZ-347001',     TAG_DESCR: 'Sales Gas Pipeline Pig Launcher / Receiver', DWG: 'GM004-PI-LAY-241-60003-001', PO_PLAN: '2017-06-30', PO_ACTUAL: '2017-06-30', FAT_PLAN: '2019-04-03', FAT_ACTUAL: '2019-03-25', ETA_PLAN: '2019-06-12', ETA_ACTUAL: '2019-07-29', ROS: '2019-06-19', INSTALL: '2019-08-05', UP_BLOCK_PE: '2019-06-20', ROS_ETA: -40, ROS_ETA_UPBLOCK: -39, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 3,   MODULE: 'PM1UT', DISC: 'ME', PKG_NO: 'MD004', BLOCK: 'A30PT',         DECK: 'UPPER DECK',   SVG: 'PM1UT_L3',  TAG_NO: 'FZZ-101002',     TAG_DESCR: 'Flowline Pig Receiver', DWG: 'GM004-PI-LAY-241-60003-001', PO_PLAN: '2017-06-30', PO_ACTUAL: '2017-06-30', FAT_PLAN: '2019-04-03', FAT_ACTUAL: '2019-03-25', ETA_PLAN: '2019-06-12', ETA_ACTUAL: '2019-06-21', ROS: '2019-06-19', INSTALL: '2019-07-31', UP_BLOCK_PE: '2019-06-20', ROS_ETA: -2, ROS_ETA_UPBLOCK: -1, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 4,   MODULE: 'PM1UT', DISC: 'ME', PKG_NO: 'MD004', BLOCK: 'A30PT',         DECK: 'UPPER DECK',   SVG: 'PM1UT_L3',  TAG_NO: 'FZZ-102001',     TAG_DESCR: 'Flowline Pig Launcher', DWG: 'GM004-PI-LAY-241-60003-001', PO_PLAN: '2017-06-30', PO_ACTUAL: '2017-06-30', FAT_PLAN: '2019-04-03', FAT_ACTUAL: '2019-03-25', ETA_PLAN: '2019-06-12', ETA_ACTUAL: '2019-07-29', ROS: '2019-06-19', INSTALL: '2019-08-05', UP_BLOCK_PE: '2019-06-20', ROS_ETA: -40, ROS_ETA_UPBLOCK: -39, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 5,   MODULE: 'PM1UT', DISC: 'ME', PKG_NO: 'MD004', BLOCK: 'A30PT',         DECK: 'UPPER DECK',   SVG: 'PM1UT_L3',  TAG_NO: 'FZZ-102002',     TAG_DESCR: 'Flowline Pig Receiver', DWG: 'GM004-PI-LAY-241-60003-001', PO_PLAN: '2017-06-30', PO_ACTUAL: '2017-06-30', FAT_PLAN: '2019-04-03', FAT_ACTUAL: '2019-03-25', ETA_PLAN: '2019-06-12', ETA_ACTUAL: '2019-06-21', ROS: '2019-06-19', INSTALL: '2019-08-05', UP_BLOCK_PE: '2019-06-20', ROS_ETA: -2, ROS_ETA_UPBLOCK: -1, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 6,   MODULE: 'PM1UT', DISC: 'EI', PKG_NO: 'IC008', BLOCK: 'A310T',         DECK: 'UPPER DECK',   SVG: 'PM1UT_L3',  TAG_NO: 'PNL-6010530',    TAG_DESCR: 'Pressure Safety Test Panel', DWG: 'GM004-PI-LAY-241-60003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-03', ETA_ACTUAL: '2019-10-15', ROS: '2019-06-03', INSTALL: '', UP_BLOCK_PE: '2019-06-25', ROS_ETA: -134, ROS_ETA_UPBLOCK: -112, CANBE_INST: '122', STATUS: 'ETA', },
      { AREA: 'Topside', SN: 7,   MODULE: 'PM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'PM1UT_L3',  TAG_NO: 'ABJ-806010',     TAG_DESCR: 'Diesel Storage-Crane No. 1 Pedestal Tank', DWG: 'GM004-PI-LAY-241-60003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-12-09', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 8,   MODULE: 'PM1UT', DISC: 'ME', PKG_NO: 'MU004', BLOCK: 'A30PT',         DECK: 'UPPER DECK',   SVG: 'PM1UT_L3',  TAG_NO: 'MAJ-806007',     TAG_DESCR: 'Diesel Coalescing Filter', DWG: 'GM004-PI-LAY-241-60003-001', PO_PLAN: '2017-09-15', PO_ACTUAL: '2017-09-15', FAT_PLAN: '2019-03-08', FAT_ACTUAL: '2019-06-05', ETA_PLAN: '2019-04-19', ETA_ACTUAL: '2019-07-10', ROS: '2019-06-26', INSTALL: '2019-07-31', UP_BLOCK_PE: '', ROS_ETA: -14, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 9,   MODULE: 'PM1UT', DISC: 'ME', PKG_NO: 'MU004', BLOCK: 'A30PT',         DECK: 'UPPER DECK',   SVG: 'PM1UT_L3',  TAG_NO: 'MAJ-806008',     TAG_DESCR: 'Diesel Coalescing Filter', DWG: 'GM004-PI-LAY-241-60003-001', PO_PLAN: '2017-09-15', PO_ACTUAL: '2017-09-15', FAT_PLAN: '2019-03-08', FAT_ACTUAL: '2019-06-05', ETA_PLAN: '2019-04-19', ETA_ACTUAL: '2019-07-10', ROS: '2019-06-26', INSTALL: '2019-07-31', UP_BLOCK_PE: '', ROS_ETA: -14, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 10,  MODULE: 'PM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'PM1UT_L3',  TAG_NO: 'MBL-10100112',   TAG_DESCR: 'Air Volume Bottle', DWG: 'GM004-PI-LAY-241-60003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Topside', SN: 11,  MODULE: 'PM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'PM1UT_L3',  TAG_NO: 'MBL-10100212',   TAG_DESCR: 'Air Volume Bottle', DWG: 'GM004-PI-LAY-241-60003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Topside', SN: 12,  MODULE: 'PM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'PM1UT_L3',  TAG_NO: 'MBL-10200112',   TAG_DESCR: 'Air Volume Bottle', DWG: 'GM004-PI-LAY-241-60003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Topside', SN: 13,  MODULE: 'PM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'PM1UT_L3',  TAG_NO: 'MBL-10200212',   TAG_DESCR: 'Air Volume Bottle', DWG: 'GM004-PI-LAY-241-60003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 14,  MODULE: 'PM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'PM1UT_L3',  TAG_NO: 'MBL-34100104',   TAG_DESCR: 'Vertical Air Receiver', DWG: 'GM004-PI-LAY-241-60003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Topside', SN: 15,  MODULE: 'PM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'PM1UT_L3',  TAG_NO: 'MBL-34700104',   TAG_DESCR: 'Air Volume Bottle', DWG: 'GM004-PI-LAY-241-60003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Topside', SN: 16,  MODULE: 'PM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'PM1UT_L3',  TAG_NO: 'MBL-34800404',   TAG_DESCR: 'Air Volume Bottle', DWG: 'GM004-PI-LAY-241-60003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Topside', SN: 17,  MODULE: 'PM1UT', DISC: 'ME', PKG_NO: 'MC004', BLOCK: 'A30PT',         DECK: 'UPPER DECK',   SVG: 'PM1UT_L3',  TAG_NO: 'HBG-348003',     TAG_DESCR: 'Buy-Back Gas Preheater', DWG: 'GM004-PI-LAY-241-60003-001', PO_PLAN: '2017-01-19', PO_ACTUAL: '2017-01-19', FAT_PLAN: '2018-06-14', FAT_ACTUAL: '2018-06-14', ETA_PLAN: '2019-05-15', ETA_ACTUAL: '2019-05-28', ROS: '2019-06-19', INSTALL: '2019-07-31', UP_BLOCK_PE: '2019-06-20', ROS_ETA: 22, ROS_ETA_UPBLOCK: 23, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 18,  MODULE: 'PM1UT', DISC: 'ME', PKG_NO: 'MC005', BLOCK: 'A30PT',         DECK: 'UPPER DECK',   SVG: 'PM1UT_L3',  TAG_NO: 'HHE-348004',     TAG_DESCR: 'Buy-Back Gas Electric Preheater', DWG: 'GM004-PI-LAY-241-60003-001', PO_PLAN: '2017-09-21', PO_ACTUAL: '2017-09-21', FAT_PLAN: '2018-09-26', FAT_ACTUAL: '2019-01-07', ETA_PLAN: '2019-04-01', ETA_ACTUAL: '2019-05-24', ROS: '2019-06-19', INSTALL: '2019-08-26', UP_BLOCK_PE: '2019-06-20', ROS_ETA: 26, ROS_ETA_UPBLOCK: 27, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 19,  MODULE: 'PM1UT', DISC: 'ME', PKG_NO: 'ML012', BLOCK: 'A30PT',         DECK: 'UPPER DECK',   SVG: 'PM1UT_L3',  TAG_NO: 'ZZZ-345000',     TAG_DESCR: 'Sales Gas Metering Skid', DWG: 'GM004-PI-LAY-241-60003-001', PO_PLAN: '2017-01-16', PO_ACTUAL: '2017-01-16', FAT_PLAN: '2019-04-17', FAT_ACTUAL: '2019-06-03', ETA_PLAN: '2019-06-19', ETA_ACTUAL: '2019-07-29', ROS: '2019-06-05', INSTALL: '2019-08-14', UP_BLOCK_PE: '2019-07-30', ROS_ETA: -54, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 20,  MODULE: 'PM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'PM1UT_L3',  TAG_NO: 'MBL-21100103',   TAG_DESCR: 'Air Volume Bottle', DWG: 'GM004-PI-LAY-241-60003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Topside', SN: 21,  MODULE: 'PM1UT', DISC: 'ME', PKG_NO: 'MC004', BLOCK: 'A30PT',         DECK: 'UPPER DECK',   SVG: 'PM1UT_L3',  TAG_NO: 'HBG-211001',     TAG_DESCR: 'Test Exchanger', DWG: 'GM004-PI-LAY-241-60003-001', PO_PLAN: '2017-01-19', PO_ACTUAL: '2017-01-19', FAT_PLAN: '2018-06-14', FAT_ACTUAL: '2018-06-14', ETA_PLAN: '2019-05-15', ETA_ACTUAL: '2019-05-28', ROS: '2019-06-19', INSTALL: '2019-07-14', UP_BLOCK_PE: '2019-06-20', ROS_ETA: 22, ROS_ETA_UPBLOCK: 23, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 22,  MODULE: 'PM1UT', DISC: 'ME', PKG_NO: 'MC004', BLOCK: 'A30PT',         DECK: 'UPPER DECK',   SVG: 'PM1UT_L3',  TAG_NO: 'HBG-221003A',    TAG_DESCR: 'Bulk Oil Heater', DWG: 'GM004-PI-LAY-241-60003-001', PO_PLAN: '2017-01-19', PO_ACTUAL: '2017-01-19', FAT_PLAN: '2018-06-14', FAT_ACTUAL: '2018-11-20', ETA_PLAN: '2019-05-15', ETA_ACTUAL: '2019-05-28', ROS: '2019-06-19', INSTALL: '2019-07-31', UP_BLOCK_PE: '2019-06-20', ROS_ETA: 22, ROS_ETA_UPBLOCK: 23, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 23,  MODULE: 'PM1UT', DISC: 'ME', PKG_NO: 'MC004', BLOCK: 'A30PT',         DECK: 'UPPER DECK',   SVG: 'PM1UT_L3',  TAG_NO: 'HBG-221003B',    TAG_DESCR: 'Bulk Oil Heater', DWG: 'GM004-PI-LAY-241-60003-001', PO_PLAN: '2017-01-19', PO_ACTUAL: '2017-01-19', FAT_PLAN: '2018-06-14', FAT_ACTUAL: '2018-11-20', ETA_PLAN: '2019-05-15', ETA_ACTUAL: '2019-05-28', ROS: '2019-06-19', INSTALL: '2019-07-31', UP_BLOCK_PE: '2019-06-20', ROS_ETA: 22, ROS_ETA_UPBLOCK: 23, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 24,  MODULE: 'PM1UT', DISC: 'ME', PKG_NO: 'MD001', BLOCK: 'A30PT',         DECK: 'UPPER DECK',   SVG: 'PM1UT_L3',  TAG_NO: 'MBD-211002',     TAG_DESCR: 'Test Separator', DWG: 'GM004-PI-LAY-241-60003-001', PO_PLAN: '2017-07-13', PO_ACTUAL: '2017-07-13', FAT_PLAN: '2018-11-30', FAT_ACTUAL: '2018-11-30', ETA_PLAN: '2019-05-10', ETA_ACTUAL: '2019-03-12', ROS: '2019-06-26', INSTALL: '2019-08-26', UP_BLOCK_PE: '', ROS_ETA: 106, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 25,  MODULE: 'PM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'PM1UT_L3',  TAG_NO: 'MBL-21100207',   TAG_DESCR: 'Air Volume Bottle', DWG: 'GM004-PI-LAY-241-60003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 26,  MODULE: 'PM1UT', DISC: 'ME', PKG_NO: 'ML011', BLOCK: 'A30PT',         DECK: 'UPPER DECK',   SVG: 'PM1UT_L3',  TAG_NO: 'ZZZ-244000',     TAG_DESCR: 'Sales Gas Metering Skid', DWG: 'GM004-PI-LAY-241-60003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '2019-09-18', ETA_PLAN: '', ETA_ACTUAL: '2019-10-30', ROS: '2019-07-29', INSTALL: '2019-11-08', UP_BLOCK_PE: '', ROS_ETA: -93, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 27,  MODULE: 'PM1UT', DISC: 'ME', PKG_NO: 'MD006', BLOCK: 'A30PT',         DECK: 'UPPER DECK',   SVG: 'PM1UT_L3',  TAG_NO: 'MBJ-241001',     TAG_DESCR: 'Dry Oil Tank', DWG: 'GM004-PI-LAY-241-60003-001', PO_PLAN: '2017-08-22', PO_ACTUAL: '2017-08-24', FAT_PLAN: '2018-07-23', FAT_ACTUAL: '2018-07-24', ETA_PLAN: '2019-04-07', ETA_ACTUAL: '2019-02-20', ROS: '2019-06-26', INSTALL: '2019-08-26', UP_BLOCK_PE: '', ROS_ETA: 126, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 28,  MODULE: 'PM1UT', DISC: 'ME', PKG_NO: 'MV001', BLOCK: 'CP1PT',         DECK: 'UPPER DECK',   SVG: 'PM1UT_L3',  TAG_NO: 'TLP-981010',     TAG_DESCR: 'Pedestal Crane No. 1', DWG: 'GM004-PI-LAY-241-60003-001', PO_PLAN: '2017-01-18', PO_ACTUAL: '2017-01-18', FAT_PLAN: '2018-04-03', FAT_ACTUAL: '2018-04-03', ETA_PLAN: '2019-10-20', ETA_ACTUAL: '2019-10-25', ROS: '2019-10-27', INSTALL: '2019-12-20', UP_BLOCK_PE: '', ROS_ETA: 2, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 1,   MODULE: 'WM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'LOWER DECK',   SVG: 'WM1UT_L1',  TAG_NO: 'PAT-472301',     TAG_DESCR: 'Water Injection Booster Pump  (Future)', DWG: 'GM004-PI-LAY-241-80001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Topside', SN: 2,   MODULE: 'WM1UT', DISC: 'ME', PKG_NO: 'MJ009', BLOCK: 'W120T',         DECK: 'LOWER DECK',   SVG: 'WM1UT_L1',  TAG_NO: 'PAT-472201',     TAG_DESCR: 'Water Injection Booster Pump', DWG: 'GM004-PI-LAY-241-80001-001', PO_PLAN: '2017-07-21', PO_ACTUAL: '2017-07-21', FAT_PLAN: '2019-03-14', FAT_ACTUAL: '2019-03-29', ETA_PLAN: '2019-05-16', ETA_ACTUAL: '2019-05-30', ROS: '2019-05-16', INSTALL: '2019-06-15', UP_BLOCK_PE: '2019-05-30', ROS_ETA: -14, ROS_ETA_UPBLOCK: 0, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 3,   MODULE: 'WM1UT', DISC: 'ME', PKG_NO: 'MJ009', BLOCK: 'W120T',         DECK: 'LOWER DECK',   SVG: 'WM1UT_L1',  TAG_NO: 'PAT-472101',     TAG_DESCR: 'Water Injection Booster Pump', DWG: 'GM004-PI-LAY-241-80001-001', PO_PLAN: '2017-07-21', PO_ACTUAL: '2017-07-21', FAT_PLAN: '2019-03-14', FAT_ACTUAL: '2019-03-30', ETA_PLAN: '2019-05-16', ETA_ACTUAL: '2019-05-30', ROS: '2019-05-16', INSTALL: '2019-06-15', UP_BLOCK_PE: '2019-05-30', ROS_ETA: -14, ROS_ETA_UPBLOCK: 0, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 4,   MODULE: 'WM1UT', DISC: 'EI', PKG_NO: 'IC008', BLOCK: 'W120T',         DECK: 'LOWER DECK',   SVG: 'WM1UT_L1',  TAG_NO: 'PNL-6010710',    TAG_DESCR: 'Pressure Safety Test Panel', DWG: 'GM004-PI-LAY-241-80001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-06-03', ETA_ACTUAL: '2019-08-12', ROS: '2019-05-16', INSTALL: '2020-01-01', UP_BLOCK_PE: '2019-05-13', ROS_ETA: -88, ROS_ETA_UPBLOCK: -91, CANBE_INST: '186', STATUS: 'INST', },
      { AREA: 'Topside', SN: 5,   MODULE: 'WM1UT', DISC: 'EI', PKG_NO: 'SE005', BLOCK: 'WM LOWER DECK', DECK: 'LOWER DECK',   SVG: 'WM1UT_L1',  TAG_NO: 'PNL-6160715',    TAG_DESCR: 'Fusible Loop Charge System (Water Injection Moudle)', DWG: 'GM004-PI-LAY-241-80001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-08', ETA_ACTUAL: '2019-03-22', ROS: '2019-05-14', INSTALL: '2020-01-01', UP_BLOCK_PE: '2019-05-14', ROS_ETA: 53, ROS_ETA_UPBLOCK: 53, CANBE_INST: '329', STATUS: 'INST', },
      { AREA: 'Topside', SN: 6,   MODULE: 'WM1UT', DISC: 'ME', PKG_NO: 'ML001', BLOCK: 'W130T',         DECK: 'LOWER DECK',   SVG: 'WM1UT_L1',  TAG_NO: 'ZZZ-462100',     TAG_DESCR: 'Membrane Filtration Skid', DWG: 'GM004-PI-LAY-241-80001-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2019-03-27', FAT_ACTUAL: '2019-03-25', ETA_PLAN: '2019-05-08', ETA_ACTUAL: '2019-06-11', ROS: '2019-05-15', INSTALL: '2019-06-25', UP_BLOCK_PE: '2019-06-17', ROS_ETA: -27, ROS_ETA_UPBLOCK: 6, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 7,   MODULE: 'WM1UT', DISC: 'ME', PKG_NO: 'ML001', BLOCK: 'W130T',         DECK: 'LOWER DECK',   SVG: 'WM1UT_L1',  TAG_NO: 'ZZZ-462200',     TAG_DESCR: 'Membrane Filtration Skid', DWG: 'GM004-PI-LAY-241-80001-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2019-03-27', FAT_ACTUAL: '2019-03-25', ETA_PLAN: '2019-05-08', ETA_ACTUAL: '2019-05-10', ROS: '2019-05-11', INSTALL: '2019-05-28', UP_BLOCK_PE: '2019-05-13', ROS_ETA: 1, ROS_ETA_UPBLOCK: 3, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 8,   MODULE: 'WM1UT', DISC: 'ME', PKG_NO: 'ML001', BLOCK: 'W130T',         DECK: 'LOWER DECK',   SVG: 'WM1UT_L1',  TAG_NO: 'ZZZ-462300',     TAG_DESCR: 'Membrane Filtration Skid', DWG: 'GM004-PI-LAY-241-80001-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2019-03-27', FAT_ACTUAL: '2019-03-25', ETA_PLAN: '2019-05-08', ETA_ACTUAL: '2019-06-11', ROS: '2019-05-15', INSTALL: '2019-06-25', UP_BLOCK_PE: '2019-06-17', ROS_ETA: -27, ROS_ETA_UPBLOCK: 6, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 9,   MODULE: 'WM1UT', DISC: 'ME', PKG_NO: 'ML001', BLOCK: 'W130T',         DECK: 'LOWER DECK',   SVG: 'WM1UT_L1',  TAG_NO: 'ZZZ-462400',     TAG_DESCR: 'Membrane Filtration Skid', DWG: 'GM004-PI-LAY-241-80001-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2019-03-27', FAT_ACTUAL: '2019-03-25', ETA_PLAN: '2019-05-08', ETA_ACTUAL: '2019-05-10', ROS: '2019-05-11', INSTALL: '2019-05-28', UP_BLOCK_PE: '2019-05-13', ROS_ETA: 1, ROS_ETA_UPBLOCK: 3, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 10,  MODULE: 'WM1UT', DISC: 'ME', PKG_NO: 'ML001', BLOCK: 'W130T',         DECK: 'LOWER DECK',   SVG: 'WM1UT_L1',  TAG_NO: 'ZZZ-462500',     TAG_DESCR: 'Membrane Filtration Skid', DWG: 'GM004-PI-LAY-241-80001-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2019-03-27', FAT_ACTUAL: '2019-03-25', ETA_PLAN: '2019-05-08', ETA_ACTUAL: '2019-06-11', ROS: '2019-05-15', INSTALL: '2019-06-25', UP_BLOCK_PE: '2019-06-17', ROS_ETA: -27, ROS_ETA_UPBLOCK: 6, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 11,  MODULE: 'WM1UT', DISC: 'ME', PKG_NO: 'ML001', BLOCK: 'W130T',         DECK: 'LOWER DECK',   SVG: 'WM1UT_L1',  TAG_NO: 'ZZZ-462600',     TAG_DESCR: 'Membrane Filtration Skid', DWG: 'GM004-PI-LAY-241-80001-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2019-03-27', FAT_ACTUAL: '2019-03-25', ETA_PLAN: '2019-05-08', ETA_ACTUAL: '2019-05-10', ROS: '2019-05-11', INSTALL: '2019-05-28', UP_BLOCK_PE: '2019-05-13', ROS_ETA: 1, ROS_ETA_UPBLOCK: 3, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 12,  MODULE: 'WM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'LOWER DECK',   SVG: 'WM1UT_L1',  TAG_NO: 'ZZZ-464030',     TAG_DESCR: 'Membrane Booster Pump Package', DWG: 'GM004-PI-LAY-241-80001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Topside', SN: 13,  MODULE: 'WM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'LOWER DECK',   SVG: 'WM1UT_L1',  TAG_NO: 'ZZZ-464010',     TAG_DESCR: 'Membrane Booster Pump Package', DWG: 'GM004-PI-LAY-241-80001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-06-22', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 14,  MODULE: 'WM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'LOWER DECK',   SVG: 'WM1UT_L1',  TAG_NO: 'ZZZ-464020',     TAG_DESCR: 'Membrane Booster Pump Package', DWG: 'GM004-PI-LAY-241-80001-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-06-22', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 1,   MODULE: 'WM1UT', DISC: 'ME', PKG_NO: 'ML001', BLOCK: 'W20PT',         DECK: 'MIDDLE DECK',  SVG: 'WM1UT_L2',  TAG_NO: 'ZZZ-464100',     TAG_DESCR: 'Low Salinity Membrane Rack', DWG: 'GM004-PI-LAY-241-80002-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2019-03-27', FAT_ACTUAL: '2019-05-21', ETA_PLAN: '2019-05-08', ETA_ACTUAL: '2019-06-11', ROS: '2019-06-06', INSTALL: '2019-07-04', UP_BLOCK_PE: '2019-06-17', ROS_ETA: -5, ROS_ETA_UPBLOCK: 6, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 2,   MODULE: 'WM1UT', DISC: 'ME', PKG_NO: 'ML001', BLOCK: 'W20PT',         DECK: 'MIDDLE DECK',  SVG: 'WM1UT_L2',  TAG_NO: 'ZZZ-464200',     TAG_DESCR: 'Low Salinity Membrane Rack', DWG: 'GM004-PI-LAY-241-80002-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2019-03-27', FAT_ACTUAL: '2019-05-21', ETA_PLAN: '2019-05-08', ETA_ACTUAL: '2019-06-11', ROS: '2019-06-06', INSTALL: '2019-07-04', UP_BLOCK_PE: '2019-06-17', ROS_ETA: -5, ROS_ETA_UPBLOCK: 6, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 1,   MODULE: 'WM1UT', DISC: 'ME', PKG_NO: 'MD006', BLOCK: 'W30PT',         DECK: 'UPPER DECK',   SVG: 'WM1UT_L3',  TAG_NO: 'MBJ-462001',     TAG_DESCR: 'Micro Filtration Buffer Tank', DWG: 'GM004-PI-LAY-241-80003-001', PO_PLAN: '2017-08-22', PO_ACTUAL: '2017-08-24', FAT_PLAN: '2018-07-23', FAT_ACTUAL: '2018-07-25', ETA_PLAN: '2019-04-07', ETA_ACTUAL: '2019-05-07', ROS: '2019-06-24', INSTALL: '2019-07-23', UP_BLOCK_PE: '', ROS_ETA: 48, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 2,   MODULE: 'WM1UT', DISC: 'ME', PKG_NO: 'MC004', BLOCK: 'W30PT',         DECK: 'UPPER DECK',   SVG: 'WM1UT_L3',  TAG_NO: 'HBG-473001',     TAG_DESCR: 'Water Injection Pump Recycle Cooler', DWG: 'GM004-PI-LAY-241-80003-001', PO_PLAN: '2017-01-19', PO_ACTUAL: '2017-01-19', FAT_PLAN: '2018-06-14', FAT_ACTUAL: '2018-11-19', ETA_PLAN: '2019-05-15', ETA_ACTUAL: '2019-05-28', ROS: '2019-06-17', INSTALL: '2019-07-17', UP_BLOCK_PE: '2019-06-21', ROS_ETA: 20, ROS_ETA_UPBLOCK: 24, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 3,   MODULE: 'WM1UT', DISC: 'ME', PKG_NO: 'ML010', BLOCK: 'W30PT',         DECK: 'UPPER DECK',   SVG: 'WM1UT_L3',  TAG_NO: 'ZZZ-461002',     TAG_DESCR: 'Deaerator Vacuum Pump Skid', DWG: 'GM004-PI-LAY-241-80003-001', PO_PLAN: '2017-07-03', PO_ACTUAL: '2017-07-03', FAT_PLAN: '2019-03-18', FAT_ACTUAL: '2019-04-04', ETA_PLAN: '2019-06-10', ETA_ACTUAL: '2019-07-10', ROS: '2019-06-24', INSTALL: '2019-07-31', UP_BLOCK_PE: '', ROS_ETA: -16, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 4,   MODULE: 'WM1UT', DISC: 'ME', PKG_NO: 'ML001', BLOCK: 'W30PT',         DECK: 'UPPER DECK',   SVG: 'WM1UT_L3',  TAG_NO: 'ZZZ-465000',     TAG_DESCR: 'Low Salinity Clean-In-Place Skid', DWG: 'GM004-PI-LAY-241-80003-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2019-03-27', FAT_ACTUAL: '2019-04-03', ETA_PLAN: '2019-05-08', ETA_ACTUAL: '2019-07-11', ROS: '2019-06-24', INSTALL: '2019-07-25', UP_BLOCK_PE: '', ROS_ETA: -17, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 5,   MODULE: 'WM1UT', DISC: 'ME', PKG_NO: 'ML001', BLOCK: 'W30PT',         DECK: 'UPPER DECK',   SVG: 'WM1UT_L3',  TAG_NO: 'ZZZ-817000',     TAG_DESCR: 'UF Air Scour Skid', DWG: 'GM004-PI-LAY-241-80003-001', PO_PLAN: '2017-01-17', PO_ACTUAL: '2017-01-17', FAT_PLAN: '2019-03-27', FAT_ACTUAL: '2019-03-27', ETA_PLAN: '2019-05-08', ETA_ACTUAL: '2019-06-11', ROS: '2019-06-24', INSTALL: '2019-07-31', UP_BLOCK_PE: '', ROS_ETA: 13, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 6,   MODULE: 'WM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'WM1UT_L3',  TAG_NO: 'ZZZ-473100',     TAG_DESCR: 'Water Injection Pump Package', DWG: 'GM004-PI-LAY-241-80003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-08-05', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 7,   MODULE: 'WM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'WM1UT_L3',  TAG_NO: 'ZZZ-473200',     TAG_DESCR: 'Water Injection Pump Package', DWG: 'GM004-PI-LAY-241-80003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-08-05', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 8,   MODULE: 'WM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'WM1UT_L3',  TAG_NO: 'MAJ-473104',     TAG_DESCR: 'Fuel Gas Guard Filter', DWG: 'GM004-PI-LAY-241-80003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-11-10', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 9,   MODULE: 'WM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'WM1UT_L3',  TAG_NO: 'MAJ-473204',     TAG_DESCR: 'Fuel Gas Guard Filter', DWG: 'GM004-PI-LAY-241-80003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-11-10', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 10,  MODULE: 'WM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'WM1UT_L3',  TAG_NO: 'MAJ-4731159',    TAG_DESCR: 'Tank Vent Filter Separator', DWG: 'GM004-PI-LAY-241-80003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-09-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 11,  MODULE: 'WM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'WM1UT_L3',  TAG_NO: 'MAJ-4732159',    TAG_DESCR: 'Tank Vent Filter Separator', DWG: 'GM004-PI-LAY-241-80003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-09-20', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 12,  MODULE: 'WM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'WM1UT_L3',  TAG_NO: 'ZZZ-473139',     TAG_DESCR: 'Water Mist Fire Suppression System', DWG: 'GM004-PI-LAY-241-80003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-08-02', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 13,  MODULE: 'WM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'WM1UT_L3',  TAG_NO: 'ZZZ-473239',     TAG_DESCR: 'Water Mist Fire Supression System', DWG: 'GM004-PI-LAY-241-80003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-08-02', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 14,  MODULE: 'WM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'WM1UT_L3',  TAG_NO: 'ZZZ-803020',     TAG_DESCR: 'Water Injection Turbine Generator Wash Water Skid', DWG: null, PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'PO', },
      { AREA: 'Topside', SN: 15,  MODULE: 'WM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'WM1UT_L3',  TAG_NO: 'HHP-473136',     TAG_DESCR: 'Lube Oil Cooler', DWG: 'GM004-PI-LAY-241-80003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-08-26', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 16,  MODULE: 'WM1UT', DISC: null, PKG_NO: null,    BLOCK: null,            DECK: 'UPPER DECK',   SVG: 'WM1UT_L3',  TAG_NO: 'HHP-473236',     TAG_DESCR: 'Lube Oil Cooler', DWG: 'GM004-PI-LAY-241-80003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '', ETA_ACTUAL: '', ROS: '', INSTALL: '2019-08-26', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 17,  MODULE: 'WM1UT', DISC: 'ME', PKG_NO: 'ML010', BLOCK: 'W30PT',         DECK: 'UPPER DECK',   SVG: 'WM1UT_L3',  TAG_NO: 'MBM-461001',     TAG_DESCR: 'Deaeration Tower', DWG: 'GM004-PI-LAY-241-80003-001', PO_PLAN: '2017-07-03', PO_ACTUAL: '2017-07-03', FAT_PLAN: '2019-03-18', FAT_ACTUAL: '2019-03-15', ETA_PLAN: '2019-06-10', ETA_ACTUAL: '2019-07-08', ROS: '2019-06-17', INSTALL: '2019-07-23', UP_BLOCK_PE: '2019-06-24', ROS_ETA: -21, ROS_ETA_UPBLOCK: -14, CANBE_INST: null, STATUS: 'INST', },
      { AREA: 'Topside', SN: 18,  MODULE: 'WM1UT', DISC: 'ME', PKG_NO: 'MV001', BLOCK: 'CP2PT',         DECK: 'UPPER DECK',   SVG: 'WM1UT_L3',  TAG_NO: 'TLP-981020',     TAG_DESCR: 'Pedestal Crane No. 2', DWG: 'GM004-PI-LAY-241-80003-001', PO_PLAN: '2017-01-18', PO_ACTUAL: '2017-01-18', FAT_PLAN: '2018-04-03', FAT_ACTUAL: '2018-05-03', ETA_PLAN: '2019-10-20', ETA_ACTUAL: '2019-10-25', ROS: '2019-11-13', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: 19, ROS_ETA_UPBLOCK: null, CANBE_INST: '112', STATUS: 'ETA', },
      { AREA: 'Topside', SN: 19,  MODULE: 'WM1UT', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'W380F',         DECK: 'UPPER DECK',   SVG: 'WM1UT_L3',  TAG_NO: 'HEF-492075',     TAG_DESCR: 'Water Injection Module-Battery Exhaust Fan B', DWG: 'GM004-PI-LAY-241-80003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-12-30', ROS: '2019-10-25', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '46', STATUS: 'ETA', },
      { AREA: 'Topside', SN: 20,  MODULE: 'WM1UT', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'W380F',         DECK: 'UPPER DECK',   SVG: 'WM1UT_L3',  TAG_NO: 'HEF-492070',     TAG_DESCR: 'Water Injection Module-Battery Exhaust Fan A', DWG: 'GM004-PI-LAY-241-80003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-12-30', ROS: '2019-10-25', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '46', STATUS: 'ETA', },
      { AREA: 'Topside', SN: 21,  MODULE: 'WM1UT', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'W380F',         DECK: 'UPPER DECK',   SVG: 'WM1UT_L3',  TAG_NO: 'HSF-492065',     TAG_DESCR: 'Water Injection Module-Battery Pressurization B', DWG: 'GM004-PI-LAY-241-80003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2020-01-07', ROS: '2019-10-25', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '38', STATUS: 'ETA', },
      { AREA: 'Topside', SN: 22,  MODULE: 'WM1UT', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'W380F',         DECK: 'UPPER DECK',   SVG: 'WM1UT_L3',  TAG_NO: 'HSF-492060',     TAG_DESCR: 'Water Injection Module-Battery Pressurization A', DWG: 'GM004-PI-LAY-241-80003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2020-01-07', ROS: '2019-10-25', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '38', STATUS: 'ETA', },
      { AREA: 'Topside', SN: 23,  MODULE: 'WM1UT', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'W380F',         DECK: 'UPPER DECK',   SVG: 'WM1UT_L3',  TAG_NO: 'HSF-492035',     TAG_DESCR: 'Water Injection Module-Pressurization B', DWG: 'GM004-PI-LAY-241-80003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-12-26', ROS: '2019-10-25', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '50', STATUS: 'ETA', },
      { AREA: 'Topside', SN: 24,  MODULE: 'WM1UT', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'W380F',         DECK: 'UPPER DECK',   SVG: 'WM1UT_L3',  TAG_NO: 'HSF-492030',     TAG_DESCR: 'Water Injection Module-Pressurization A', DWG: 'GM004-PI-LAY-241-80003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-12-26', ROS: '2019-10-25', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '50', STATUS: 'ETA', },
      { AREA: 'Topside', SN: 25,  MODULE: 'WM1UT', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'W420E',         DECK: 'UPPER DECK',   SVG: 'WM1UT_L3',  TAG_NO: 'HCN-492055',     TAG_DESCR: 'Supply Battery Room Water Injection Module', DWG: 'GM004-PI-LAY-241-80003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-08-14', ROS: '2019-10-25', INSTALL: '2019-11-18', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '184', STATUS: 'INST', },
      { AREA: 'Topside', SN: 26,  MODULE: 'WM1UT', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'W420E',         DECK: 'UPPER DECK',   SVG: 'WM1UT_L3',  TAG_NO: 'HCN-492045',     TAG_DESCR: 'Supply Battery Room Water Injection Module', DWG: 'GM004-PI-LAY-241-80003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-08-09', ROS: '2019-10-25', INSTALL: '2019-11-19', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '189', STATUS: 'INST', },
      { AREA: 'Topside', SN: 27,  MODULE: 'WM1UT', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'W420E',         DECK: 'UPPER DECK',   SVG: 'WM1UT_L3',  TAG_NO: 'HCN-492025',     TAG_DESCR: 'Supply Electrical Room Water Injection Module', DWG: 'GM004-PI-LAY-241-80003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-07-26', ROS: '2019-10-25', INSTALL: '2019-12-24', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '203', STATUS: 'INST', },
      { AREA: 'Topside', SN: 28,  MODULE: 'WM1UT', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'W420E',         DECK: 'UPPER DECK',   SVG: 'WM1UT_L3',  TAG_NO: 'HCN-492015',     TAG_DESCR: 'Supply Electrical Room Water Injection Module', DWG: 'GM004-PI-LAY-241-80003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-07-26', ROS: '2019-10-25', INSTALL: '2019-12-23', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '203', STATUS: 'INST', },
      { AREA: 'Topside', SN: 29,  MODULE: 'WM1UT', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'W410E',         DECK: 'UPPER DECK',   SVG: 'WM1UT_L3',  TAG_NO: 'HAU-492040',     TAG_DESCR: 'Supply Battery Room Water Injection Module', DWG: 'GM004-PI-LAY-241-80003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-07-22', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-12-31', ROS: '2019-10-25', INSTALL: '2019-09-05', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '45', STATUS: 'INST', },
      { AREA: 'Topside', SN: 30,  MODULE: 'WM1UT', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'W410E',         DECK: 'UPPER DECK',   SVG: 'WM1UT_L3',  TAG_NO: 'HAU-492050',     TAG_DESCR: 'Supply Battery Room Water Injection Module', DWG: 'GM004-PI-LAY-241-80003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '2019-07-23', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-12-27', ROS: '2019-10-25', INSTALL: '2019-09-05', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '49', STATUS: 'INST', },
      { AREA: 'Topside', SN: 31,  MODULE: 'WM1UT', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'W410E',         DECK: 'UPPER DECK',   SVG: 'WM1UT_L3',  TAG_NO: 'HAU-492020',     TAG_DESCR: 'Supply Electrical Room Water Injection Module', DWG: 'GM004-PI-LAY-241-80003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-12-31', ROS: '2019-10-25', INSTALL: '2019-09-06', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '45', STATUS: 'INST', },
      { AREA: 'Topside', SN: 32,  MODULE: 'WM1UT', DISC: 'HV', PKG_NO: 'HV001', BLOCK: 'W410E',         DECK: 'UPPER DECK',   SVG: 'WM1UT_L3',  TAG_NO: 'HAU-492010',     TAG_DESCR: 'Supply Electrical Room Water Injection Module', DWG: 'GM004-PI-LAY-241-80003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '2019-02-06', FAT_ACTUAL: '', ETA_PLAN: '2019-03-15', ETA_ACTUAL: '2019-12-31', ROS: '2019-10-25', INSTALL: '2019-09-06', UP_BLOCK_PE: '', ROS_ETA: null, ROS_ETA_UPBLOCK: null, CANBE_INST: '45', STATUS: 'INST', },
      { AREA: 'Topside', SN: 33,  MODULE: 'WM1UT', DISC: 'EI', PKG_NO: 'EL003', BLOCK: 'Top',           DECK: 'UPPER DECK',   SVG: 'WM1UT_L3',  TAG_NO: 'VTN-876001A',    TAG_DESCR: '13.8KV – 4.16KV Power Transformer 7500/8400KVA (55/65°c) ONAN,  10500KVA ONAF ', DWG: 'GM004-PI-LAY-241-80003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-17', ETA_ACTUAL: '2019-05-10', ROS: '2019-06-20', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: 41, ROS_ETA_UPBLOCK: null, CANBE_INST: '280', STATUS: 'INST', },
      { AREA: 'Topside', SN: 34,  MODULE: 'WM1UT', DISC: 'EI', PKG_NO: 'EL003', BLOCK: 'Top',           DECK: 'UPPER DECK',   SVG: 'WM1UT_L3',  TAG_NO: 'VTN-876001B',    TAG_DESCR: '13.8KV – 4.16KV Power Transformer 7500/8400KVA (55/65°c) ONAN,  10500KVA ONAF ', DWG: 'GM004-PI-LAY-241-80003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-17', ETA_ACTUAL: '2019-05-10', ROS: '2019-06-20', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: 41, ROS_ETA_UPBLOCK: null, CANBE_INST: '280', STATUS: 'INST', },
      { AREA: 'Topside', SN: 35,  MODULE: 'WM1UT', DISC: 'EI', PKG_NO: 'EL003', BLOCK: 'Top',           DECK: 'UPPER DECK',   SVG: 'WM1UT_L3',  TAG_NO: 'VTN-874003B',    TAG_DESCR: '13.8KV – 480V Power Transformer 1000/1120KVA (55/65°c) ONAN,  1288KVA ONAF ', DWG: 'GM004-PI-LAY-241-80003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-17', ETA_ACTUAL: '2019-05-10', ROS: '2019-06-20', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: 41, ROS_ETA_UPBLOCK: null, CANBE_INST: '280', STATUS: 'INST', },
      { AREA: 'Topside', SN: 36,  MODULE: 'WM1UT', DISC: 'EI', PKG_NO: 'EL003', BLOCK: 'Top',           DECK: 'UPPER DECK',   SVG: 'WM1UT_L3',  TAG_NO: 'VTN-874003A',    TAG_DESCR: '13.8KV – 480V Power Transformer 1000/1120KVA (55/65°c) ONAN,  1288KVA ONAF ', DWG: 'GM004-PI-LAY-241-80003-001', PO_PLAN: '', PO_ACTUAL: '', FAT_PLAN: '', FAT_ACTUAL: '', ETA_PLAN: '2019-04-17', ETA_ACTUAL: '2019-05-10', ROS: '2019-06-20', INSTALL: '2020-01-01', UP_BLOCK_PE: '', ROS_ETA: 41, ROS_ETA_UPBLOCK: null, CANBE_INST: '280', STATUS: 'INST', },
      { AREA: 'Topside', SN: 1,   MODULE: 'FB1UT', DISC: 'ME', PKG_NO: 'ML007', BLOCK: 'F130T',         DECK: 'LOWER DECK',   SVG: 'FB1UT',     TAG_NO: 'TTF-522001',     TAG_DESCR: 'Flare Package', DWG: null, PO_PLAN: '2018-01-26', PO_ACTUAL: '2018-01-26', FAT_PLAN: '2019-03-29', FAT_ACTUAL: '', ETA_PLAN: '2019-06-14', ETA_ACTUAL: '2019-07-30', ROS: '2019-06-18', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: -42, ROS_ETA_UPBLOCK: null, CANBE_INST: '199', STATUS: 'ETA', },
      { AREA: 'Topside', SN: 2,   MODULE: 'FB1UT', DISC: 'ME', PKG_NO: 'ML007', BLOCK: 'F130T',         DECK: 'LOWER DECK',   SVG: 'FB1UT',     TAG_NO: 'TTF-502001',     TAG_DESCR: 'Flare Package', DWG: null, PO_PLAN: '2018-01-26', PO_ACTUAL: '2018-01-26', FAT_PLAN: '2019-03-29', FAT_ACTUAL: '', ETA_PLAN: '2019-06-14', ETA_ACTUAL: '2019-07-30', ROS: '2019-06-18', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: -42, ROS_ETA_UPBLOCK: null, CANBE_INST: '199', STATUS: 'ETA', },
      { AREA: 'Topside', SN: 3,   MODULE: 'FB1UT', DISC: 'ME', PKG_NO: 'ML007', BLOCK: 'F120T',         DECK: 'LOWER DECK',   SVG: 'FB1UT',     TAG_NO: 'ZZZ-543001',     TAG_DESCR: 'Flare Package', DWG: null, PO_PLAN: '2018-01-26', PO_ACTUAL: '2018-01-26', FAT_PLAN: '2019-03-29', FAT_ACTUAL: '', ETA_PLAN: '2019-06-14', ETA_ACTUAL: '2019-07-30', ROS: '2019-06-22', INSTALL: '', UP_BLOCK_PE: '', ROS_ETA: -38, ROS_ETA_UPBLOCK: null, CANBE_INST: '199', STATUS: 'ETA', },
],

    Queries: {
      MasterProgress: [
        { 
          AREA: 'Overall', 
          CON_P_PG: 83.6, CON_A_PG: 83.42, CON_D_PG: -0.18, 
          MOD: 45, BLK: 45, WT: 85980.7, 
          A_T: 157784, A_A: 84608, A_O: 73176, A_PG: 53.62, 
          B_T: 24633, B_A: 1661, B_O: 22972, B_PG: 6.74, 
          PA_T: 20200, PA_A: 15501, PA_O: 4699,
          PB_T: 28813, PB_A: 22900, PB_O: 5913, 
        },

        { AREA: 'Topside', 
          CON_P_PG: 98, CON_A_PG: 97.52, CON_D_PG: -0.48, 
          MOD: 9, BLK: 9, WT: 13933.7, 
          A_T: 51199, A_A: 27623, A_O: 23576, A_PG: 53.95, 
          B_T: 8735, B_A: 1211, B_O: 7524, B_PG: 13.86, 
          PA_T: 9388, PA_A: 5865, PA_O: 3523, 
          PB_T: 11176, PB_A: 8315, PB_O: 2861, 
        },
      
        { AREA: 'Deckbox', 
          CON_P_PG: 99.64, CON_A_PG: 99.57, CON_D_PG: -0.07, 
          MOD: 9, BLK: 9, WT: 19422, 
          A_T: 92421, A_A: 44360, A_O: 48061, A_PG: 48, 
          B_T: 13729, B_A: 226, B_O: 13503, B_PG: 1.65, 
          PA_T: 8821, PA_A: 7769, PA_O: 1052, 
          PB_T: 10984, PB_A: 8483, PB_O: 2501, 
        },
      
        { 
          AREA: 'Hull', CON_P_PG: 100, CON_A_PG: 100, CON_D_PG: 0,
          MOD: 27, BLK: 27, WT: 52625, 
          A_T: 11978, A_A: 11489, A_O: 489, A_PG: 95.92, 
          B_T: 1184, B_A: 173, B_O: 1011, B_PG: 14.61, 
          PA_T: 1852, PA_A: 1743, PA_O: 109, 
          PB_T: 5448, PB_A: 4960, PB_O: 488, 
        },
      ]




    },



}