import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 400,
      CanvasHeight: 300,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },
    DataItems:    [
      {MDR:" ",ACONEX:"True",MDRCOUNT:10478,UNIQUE:10471},
      {MDR:"True",ACONEX:"True",MDRCOUNT:7170,UNIQUE:6648},
      {MDR:"True",ACONEX:" ",MDRCOUNT:6564,UNIQUE:6102},
      {MDR:"Total",ACONEX:" ",MDRCOUNT:24212,UNIQUE:23221}
    ],
}