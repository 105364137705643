import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 850,
      CanvasHeight: 450,
      CanvasBorderWeight: 0,
      CanvasBorderColor: '#e0e0e0',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 380,
      CanvasChartHeight: 280,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Support Fabrication', x:20, y: -20, font: 'roboto', class: 'regular', size: 12, color: '#424242', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: -50, y: -20, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'start' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: -30,
          y: -20,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'start'
        },
      ]
    },
    Queries: {
      // SQL1: [
      //   {PLAN:100,ACTUAL:99.99,DELTA:-0.01000000000000512}
      // ],
    },
    DataItems: [
      {CDATE:"2023-10-01",PLAN_CUM:254,PLAN_WK:52,ACTUAL_WK:11},
      {CDATE:"2023-10-08",PLAN_CUM:294,PLAN_WK:0,ACTUAL_WK:11},
      {CDATE:"2023-10-15",PLAN_CUM:64,PLAN_WK:10,ACTUAL_WK:11},
      {CDATE:"2023-10-22",PLAN_CUM:49,PLAN_WK:27,ACTUAL_WK:11},
      {CDATE:"2023-10-29",PLAN_CUM:36,PLAN_WK:15,ACTUAL_WK:11},
      {CDATE:"2023-11-05",PLAN_CUM:22,PLAN_WK:8,ACTUAL_WK:11},
      {CDATE:"2023-11-12",PLAN_CUM:18,PLAN_WK:10,ACTUAL_WK:11},
      {CDATE:"2023-11-19",PLAN_CUM:13,PLAN_WK:5,ACTUAL_WK:11},
      {CDATE:"2023-11-26",PLAN_CUM:13,PLAN_WK:13,ACTUAL_WK:11},
      {CDATE:"2023-12-03",PLAN_CUM:14,PLAN_WK:24,ACTUAL_WK:11},
      {CDATE:"2023-12-10",PLAN_CUM:15,PLAN_WK:103,ACTUAL_WK:11},
      {CDATE:"2023-12-17",PLAN_CUM:17,PLAN_WK:60,ACTUAL_WK:11},
      {CDATE:"2023-12-24",PLAN_CUM:19,PLAN_WK:245,ACTUAL_WK:11},
      {CDATE:"2023-12-31",PLAN_CUM:18,PLAN_WK:15,ACTUAL_WK:11},
      {CDATE:"2023-01-07",PLAN_CUM:19,PLAN_WK:21,ACTUAL_WK:11},
      {CDATE:"2023-01-14",PLAN_CUM:18,PLAN_WK:14,ACTUAL_WK:11},
      {CDATE:"2023-01-21",PLAN_CUM:15,PLAN_WK:6,ACTUAL_WK:11},
      {CDATE:"2023-01-28",PLAN_CUM:14,PLAN_WK:13,ACTUAL_WK:11},
      {CDATE:"2023-02-04",PLAN_CUM:13,PLAN_WK:10,ACTUAL_WK:11},
      {CDATE:"2023-02-11",PLAN_CUM:11,PLAN_WK:0,ACTUAL_WK:11},
      {CDATE:"2023-02-18",PLAN_CUM:11,PLAN_WK:0,ACTUAL_WK:11},
    ],
}