import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 1700,
    CanvasHeight: 600,
    CanvasBorderWeight: 0.1,
    CanvasBorderColor: '#e0e0e0',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.2,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 380,
    CanvasChartHeight: 280,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  Text: {
    Text: [
      { text: '', x:20, y: 10, font: 'roboto', class: 'regular', size: 12, color: '#424242', align: 'start' },
    ],
    Filter: [
      { name: 'MOD'   , x: -50, y: 20, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'start' },
    ],
    Sql: [
      {
        query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
        text: 'Data Source',
        x: -30,
        y: 20,
        font: 'roboto',
        style: 'regular',
        size: 11,
        color: '#44A9DF',
        align: 'start'
      },
    ]
  },
  Queries: {
    SQL1: [
      {WORK_TYPE_SEQ:1,WORK_TYPE_CODE:"PP",WORK_TYPE_DESCR:"Purchasing Plan to Owner"},
      {WORK_TYPE_SEQ:2,WORK_TYPE_CODE:"PPA",WORK_TYPE_DESCR:"Purchasing Plan approved by Owner"},
      {WORK_TYPE_SEQ:3,WORK_TYPE_CODE:"MR",WORK_TYPE_DESCR:"MR Received by SEI Procurement"},
      {WORK_TYPE_SEQ:4,WORK_TYPE_CODE:"RFQ",WORK_TYPE_DESCR:"RFQ Issue"},
      {WORK_TYPE_SEQ:5,WORK_TYPE_CODE:"BC",WORK_TYPE_DESCR:"Bid Closing Date"},
      {WORK_TYPE_SEQ:6,WORK_TYPE_CODE:"TBE",WORK_TYPE_DESCR:"TBE"},
      {WORK_TYPE_SEQ:7,WORK_TYPE_CODE:"CBE",WORK_TYPE_DESCR:"CBE"},
      {WORK_TYPE_SEQ:8,WORK_TYPE_CODE:"AR",WORK_TYPE_DESCR:"Award Recom. To Owner"},
      {WORK_TYPE_SEQ:9,WORK_TYPE_CODE:"ARA",WORK_TYPE_DESCR:"Award Recom. Approved by Owner"},
      {WORK_TYPE_SEQ:10,WORK_TYPE_CODE:"LOA",WORK_TYPE_DESCR:"LOA Issue"},
      {WORK_TYPE_SEQ:11,WORK_TYPE_CODE:"PO",WORK_TYPE_DESCR:"PO Issue"},
      {WORK_TYPE_SEQ:12,WORK_TYPE_CODE:"MDI",WORK_TYPE_DESCR:"Main Drawing Issue"},
      {WORK_TYPE_SEQ:13,WORK_TYPE_CODE:"MO",WORK_TYPE_DESCR:"Material Order"},
      {WORK_TYPE_SEQ:14,WORK_TYPE_CODE:"MMR",WORK_TYPE_DESCR:"Main Material Received"},
      {WORK_TYPE_SEQ:15,WORK_TYPE_CODE:"FI",WORK_TYPE_DESCR:"Final Inspection"},
      {WORK_TYPE_SEQ:16,WORK_TYPE_CODE:"EW",WORK_TYPE_DESCR:"Ex-work"},
      {WORK_TYPE_SEQ:17,WORK_TYPE_CODE:"SA",WORK_TYPE_DESCR:"Shipping Arrival at Destination Port"},
      {WORK_TYPE_SEQ:18,WORK_TYPE_CODE:"CC",WORK_TYPE_DESCR:"Custom Clearance"},
      {WORK_TYPE_SEQ:19,WORK_TYPE_CODE:"DJS",WORK_TYPE_DESCR:"Delivery to Job Site"}
    ],
    SQL2:  [
      {CUTOFF:"2022-07-29"}
    ],
  },
  DataItems: [
    {CUTOFF:"2022-01-07",WK_NO:"W2022_01",WORK_TYPE_CODE:"AR",WORK_TYPE_COLOR:"#9E480E",PSR_PLAN_CUM:47,PSR_PLAN_WK:5,PSR_FORECAST_CUM:48,PSR_FORECAST_WK:4,PSR_ACTUAL_CUM:48,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-07",WK_NO:"W2022_01",WORK_TYPE_CODE:"BC",WORK_TYPE_COLOR:"#5B9BD5",PSR_PLAN_CUM:81,PSR_PLAN_WK:10,PSR_FORECAST_CUM:87,PSR_FORECAST_WK:6,PSR_ACTUAL_CUM:88,PSR_ACTUAL_WK:1,RAS_ROS:0},
    {CUTOFF:"2022-01-07",WK_NO:"W2022_01",WORK_TYPE_CODE:"ARA",WORK_TYPE_COLOR:"#636363",PSR_PLAN_CUM:42,PSR_PLAN_WK:2,PSR_FORECAST_CUM:48,PSR_FORECAST_WK:2,PSR_ACTUAL_CUM:27,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-07",WK_NO:"W2022_01",WORK_TYPE_CODE:"CBE",WORK_TYPE_COLOR:"#264478",PSR_PLAN_CUM:53,PSR_PLAN_WK:2,PSR_FORECAST_CUM:51,PSR_FORECAST_WK:2,PSR_ACTUAL_CUM:51,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-07",WK_NO:"W2022_01",WORK_TYPE_CODE:"CC",WORK_TYPE_COLOR:"#8CC168",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-07",WK_NO:"W2022_01",WORK_TYPE_CODE:"DJS",WORK_TYPE_COLOR:"#335AA1",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-07",WK_NO:"W2022_01",WORK_TYPE_CODE:"EW",WORK_TYPE_COLOR:"#FFCD33",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-07",WK_NO:"W2022_01",WORK_TYPE_CODE:"FI",WORK_TYPE_COLOR:"#B7B7B7",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-07",WK_NO:"W2022_01",WORK_TYPE_CODE:"MO",WORK_TYPE_COLOR:"#698ED0",PSR_PLAN_CUM:3,PSR_PLAN_WK:0,PSR_FORECAST_CUM:3,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-07",WK_NO:"W2022_01",WORK_TYPE_CODE:"LOA",WORK_TYPE_COLOR:"#997300",PSR_PLAN_CUM:37,PSR_PLAN_WK:6,PSR_FORECAST_CUM:44,PSR_FORECAST_WK:7,PSR_ACTUAL_CUM:44,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-07",WK_NO:"W2022_01",WORK_TYPE_CODE:"MR",WORK_TYPE_COLOR:"#A5A5A5",PSR_PLAN_CUM:115,PSR_PLAN_WK:0,PSR_FORECAST_CUM:114,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:65,PSR_ACTUAL_WK:2,RAS_ROS:0},
    {CUTOFF:"2022-01-07",WK_NO:"W2022_01",WORK_TYPE_CODE:"MDI",WORK_TYPE_COLOR:"#43682B",PSR_PLAN_CUM:12,PSR_PLAN_WK:0,PSR_FORECAST_CUM:10,PSR_FORECAST_WK:4,PSR_ACTUAL_CUM:11,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-07",WK_NO:"W2022_01",WORK_TYPE_CODE:"PO",WORK_TYPE_COLOR:"#255E91",PSR_PLAN_CUM:22,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:19,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-07",WK_NO:"W2022_01",WORK_TYPE_CODE:"MMR",WORK_TYPE_COLOR:"#F1975A",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-07",WK_NO:"W2022_01",WORK_TYPE_CODE:"PPA",WORK_TYPE_COLOR:"#ED7D31",PSR_PLAN_CUM:119,PSR_PLAN_WK:3,PSR_FORECAST_CUM:119,PSR_FORECAST_WK:4,PSR_ACTUAL_CUM:121,PSR_ACTUAL_WK:2,RAS_ROS:0},
    {CUTOFF:"2022-01-07",WK_NO:"W2022_01",WORK_TYPE_CODE:"PP",WORK_TYPE_COLOR:"#4472C4",PSR_PLAN_CUM:122,PSR_PLAN_WK:0,PSR_FORECAST_CUM:120,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:123,PSR_ACTUAL_WK:2,RAS_ROS:0},
    {CUTOFF:"2022-01-07",WK_NO:"W2022_01",WORK_TYPE_CODE:"TBE",WORK_TYPE_COLOR:"#70AD47",PSR_PLAN_CUM:54,PSR_PLAN_WK:1,PSR_FORECAST_CUM:51,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:55,PSR_ACTUAL_WK:3,RAS_ROS:0},
    {CUTOFF:"2022-01-07",WK_NO:"W2022_01",WORK_TYPE_CODE:"RFQ",WORK_TYPE_COLOR:"#FFC000",PSR_PLAN_CUM:114,PSR_PLAN_WK:1,PSR_FORECAST_CUM:111,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:113,PSR_ACTUAL_WK:1,RAS_ROS:0},
    {CUTOFF:"2022-01-07",WK_NO:"W2022_01",WORK_TYPE_CODE:"SA",WORK_TYPE_COLOR:"#7CAFDD",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-14",WK_NO:"W2022_02",WORK_TYPE_CODE:"AR",WORK_TYPE_COLOR:"#9E480E",PSR_PLAN_CUM:50,PSR_PLAN_WK:3,PSR_FORECAST_CUM:48,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:48,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-14",WK_NO:"W2022_02",WORK_TYPE_CODE:"ARA",WORK_TYPE_COLOR:"#636363",PSR_PLAN_CUM:46,PSR_PLAN_WK:4,PSR_FORECAST_CUM:48,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:27,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-14",WK_NO:"W2022_02",WORK_TYPE_CODE:"CC",WORK_TYPE_COLOR:"#8CC168",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-14",WK_NO:"W2022_02",WORK_TYPE_CODE:"BC",WORK_TYPE_COLOR:"#5B9BD5",PSR_PLAN_CUM:94,PSR_PLAN_WK:13,PSR_FORECAST_CUM:93,PSR_FORECAST_WK:6,PSR_ACTUAL_CUM:97,PSR_ACTUAL_WK:9,RAS_ROS:0},
    {CUTOFF:"2022-01-14",WK_NO:"W2022_02",WORK_TYPE_CODE:"EW",WORK_TYPE_COLOR:"#FFCD33",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-14",WK_NO:"W2022_02",WORK_TYPE_CODE:"CBE",WORK_TYPE_COLOR:"#264478",PSR_PLAN_CUM:53,PSR_PLAN_WK:0,PSR_FORECAST_CUM:51,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:52,PSR_ACTUAL_WK:1,RAS_ROS:0},
    {CUTOFF:"2022-01-14",WK_NO:"W2022_02",WORK_TYPE_CODE:"FI",WORK_TYPE_COLOR:"#B7B7B7",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-14",WK_NO:"W2022_02",WORK_TYPE_CODE:"DJS",WORK_TYPE_COLOR:"#335AA1",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-14",WK_NO:"W2022_02",WORK_TYPE_CODE:"LOA",WORK_TYPE_COLOR:"#997300",PSR_PLAN_CUM:39,PSR_PLAN_WK:2,PSR_FORECAST_CUM:44,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:44,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-14",WK_NO:"W2022_02",WORK_TYPE_CODE:"MDI",WORK_TYPE_COLOR:"#43682B",PSR_PLAN_CUM:12,PSR_PLAN_WK:0,PSR_FORECAST_CUM:10,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:11,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-14",WK_NO:"W2022_02",WORK_TYPE_CODE:"MMR",WORK_TYPE_COLOR:"#F1975A",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-14",WK_NO:"W2022_02",WORK_TYPE_CODE:"MR",WORK_TYPE_COLOR:"#A5A5A5",PSR_PLAN_CUM:117,PSR_PLAN_WK:2,PSR_FORECAST_CUM:115,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:65,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-14",WK_NO:"W2022_02",WORK_TYPE_CODE:"MO",WORK_TYPE_COLOR:"#698ED0",PSR_PLAN_CUM:3,PSR_PLAN_WK:0,PSR_FORECAST_CUM:3,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-14",WK_NO:"W2022_02",WORK_TYPE_CODE:"PPA",WORK_TYPE_COLOR:"#ED7D31",PSR_PLAN_CUM:122,PSR_PLAN_WK:3,PSR_FORECAST_CUM:119,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:122,PSR_ACTUAL_WK:1,RAS_ROS:0},
    {CUTOFF:"2022-01-14",WK_NO:"W2022_02",WORK_TYPE_CODE:"PO",WORK_TYPE_COLOR:"#255E91",PSR_PLAN_CUM:23,PSR_PLAN_WK:1,PSR_FORECAST_CUM:15,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:19,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-14",WK_NO:"W2022_02",WORK_TYPE_CODE:"RFQ",WORK_TYPE_COLOR:"#FFC000",PSR_PLAN_CUM:116,PSR_PLAN_WK:2,PSR_FORECAST_CUM:113,PSR_FORECAST_WK:2,PSR_ACTUAL_CUM:116,PSR_ACTUAL_WK:3,RAS_ROS:0},
    {CUTOFF:"2022-01-14",WK_NO:"W2022_02",WORK_TYPE_CODE:"PP",WORK_TYPE_COLOR:"#4472C4",PSR_PLAN_CUM:123,PSR_PLAN_WK:1,PSR_FORECAST_CUM:121,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:124,PSR_ACTUAL_WK:1,RAS_ROS:0},
    {CUTOFF:"2022-01-14",WK_NO:"W2022_02",WORK_TYPE_CODE:"SA",WORK_TYPE_COLOR:"#7CAFDD",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-14",WK_NO:"W2022_02",WORK_TYPE_CODE:"TBE",WORK_TYPE_COLOR:"#70AD47",PSR_PLAN_CUM:58,PSR_PLAN_WK:4,PSR_FORECAST_CUM:53,PSR_FORECAST_WK:2,PSR_ACTUAL_CUM:57,PSR_ACTUAL_WK:2,RAS_ROS:0},
    {CUTOFF:"2022-01-21",WK_NO:"W2022_03",WORK_TYPE_CODE:"AR",WORK_TYPE_COLOR:"#9E480E",PSR_PLAN_CUM:50,PSR_PLAN_WK:0,PSR_FORECAST_CUM:48,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:48,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-21",WK_NO:"W2022_03",WORK_TYPE_CODE:"CBE",WORK_TYPE_COLOR:"#264478",PSR_PLAN_CUM:55,PSR_PLAN_WK:2,PSR_FORECAST_CUM:52,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:54,PSR_ACTUAL_WK:2,RAS_ROS:0},
    {CUTOFF:"2022-01-21",WK_NO:"W2022_03",WORK_TYPE_CODE:"ARA",WORK_TYPE_COLOR:"#636363",PSR_PLAN_CUM:50,PSR_PLAN_WK:4,PSR_FORECAST_CUM:48,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:27,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-21",WK_NO:"W2022_03",WORK_TYPE_CODE:"CC",WORK_TYPE_COLOR:"#8CC168",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-21",WK_NO:"W2022_03",WORK_TYPE_CODE:"BC",WORK_TYPE_COLOR:"#5B9BD5",PSR_PLAN_CUM:99,PSR_PLAN_WK:5,PSR_FORECAST_CUM:97,PSR_FORECAST_WK:4,PSR_ACTUAL_CUM:102,PSR_ACTUAL_WK:5,RAS_ROS:0},
    {CUTOFF:"2022-01-21",WK_NO:"W2022_03",WORK_TYPE_CODE:"DJS",WORK_TYPE_COLOR:"#335AA1",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-21",WK_NO:"W2022_03",WORK_TYPE_CODE:"EW",WORK_TYPE_COLOR:"#FFCD33",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-21",WK_NO:"W2022_03",WORK_TYPE_CODE:"FI",WORK_TYPE_COLOR:"#B7B7B7",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-21",WK_NO:"W2022_03",WORK_TYPE_CODE:"LOA",WORK_TYPE_COLOR:"#997300",PSR_PLAN_CUM:40,PSR_PLAN_WK:1,PSR_FORECAST_CUM:44,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:44,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-21",WK_NO:"W2022_03",WORK_TYPE_CODE:"MMR",WORK_TYPE_COLOR:"#F1975A",PSR_PLAN_CUM:2,PSR_PLAN_WK:2,PSR_FORECAST_CUM:2,PSR_FORECAST_WK:2,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-21",WK_NO:"W2022_03",WORK_TYPE_CODE:"MDI",WORK_TYPE_COLOR:"#43682B",PSR_PLAN_CUM:12,PSR_PLAN_WK:0,PSR_FORECAST_CUM:10,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:12,PSR_ACTUAL_WK:1,RAS_ROS:0},
    {CUTOFF:"2022-01-21",WK_NO:"W2022_03",WORK_TYPE_CODE:"MO",WORK_TYPE_COLOR:"#698ED0",PSR_PLAN_CUM:3,PSR_PLAN_WK:0,PSR_FORECAST_CUM:3,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-21",WK_NO:"W2022_03",WORK_TYPE_CODE:"MR",WORK_TYPE_COLOR:"#A5A5A5",PSR_PLAN_CUM:117,PSR_PLAN_WK:0,PSR_FORECAST_CUM:116,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:65,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-21",WK_NO:"W2022_03",WORK_TYPE_CODE:"PP",WORK_TYPE_COLOR:"#4472C4",PSR_PLAN_CUM:123,PSR_PLAN_WK:0,PSR_FORECAST_CUM:123,PSR_FORECAST_WK:2,PSR_ACTUAL_CUM:139,PSR_ACTUAL_WK:15,RAS_ROS:0},
    {CUTOFF:"2022-01-21",WK_NO:"W2022_03",WORK_TYPE_CODE:"PO",WORK_TYPE_COLOR:"#255E91",PSR_PLAN_CUM:23,PSR_PLAN_WK:0,PSR_FORECAST_CUM:18,PSR_FORECAST_WK:3,PSR_ACTUAL_CUM:26,PSR_ACTUAL_WK:7,RAS_ROS:0},
    {CUTOFF:"2022-01-21",WK_NO:"W2022_03",WORK_TYPE_CODE:"SA",WORK_TYPE_COLOR:"#7CAFDD",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-21",WK_NO:"W2022_03",WORK_TYPE_CODE:"PPA",WORK_TYPE_COLOR:"#ED7D31",PSR_PLAN_CUM:122,PSR_PLAN_WK:0,PSR_FORECAST_CUM:119,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:137,PSR_ACTUAL_WK:15,RAS_ROS:0},
    {CUTOFF:"2022-01-21",WK_NO:"W2022_03",WORK_TYPE_CODE:"TBE",WORK_TYPE_COLOR:"#70AD47",PSR_PLAN_CUM:61,PSR_PLAN_WK:3,PSR_FORECAST_CUM:58,PSR_FORECAST_WK:5,PSR_ACTUAL_CUM:59,PSR_ACTUAL_WK:2,RAS_ROS:0},
    {CUTOFF:"2022-01-21",WK_NO:"W2022_03",WORK_TYPE_CODE:"RFQ",WORK_TYPE_COLOR:"#FFC000",PSR_PLAN_CUM:116,PSR_PLAN_WK:0,PSR_FORECAST_CUM:113,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:117,PSR_ACTUAL_WK:1,RAS_ROS:0},
    {CUTOFF:"2022-01-28",WK_NO:"W2022_04",WORK_TYPE_CODE:"CBE",WORK_TYPE_COLOR:"#264478",PSR_PLAN_CUM:58,PSR_PLAN_WK:3,PSR_FORECAST_CUM:54,PSR_FORECAST_WK:2,PSR_ACTUAL_CUM:59,PSR_ACTUAL_WK:5,RAS_ROS:0},
    {CUTOFF:"2022-01-28",WK_NO:"W2022_04",WORK_TYPE_CODE:"AR",WORK_TYPE_COLOR:"#9E480E",PSR_PLAN_CUM:53,PSR_PLAN_WK:3,PSR_FORECAST_CUM:51,PSR_FORECAST_WK:3,PSR_ACTUAL_CUM:53,PSR_ACTUAL_WK:5,RAS_ROS:0},
    {CUTOFF:"2022-01-28",WK_NO:"W2022_04",WORK_TYPE_CODE:"DJS",WORK_TYPE_COLOR:"#335AA1",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-28",WK_NO:"W2022_04",WORK_TYPE_CODE:"ARA",WORK_TYPE_COLOR:"#636363",PSR_PLAN_CUM:51,PSR_PLAN_WK:1,PSR_FORECAST_CUM:48,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:27,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-28",WK_NO:"W2022_04",WORK_TYPE_CODE:"FI",WORK_TYPE_COLOR:"#B7B7B7",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-28",WK_NO:"W2022_04",WORK_TYPE_CODE:"BC",WORK_TYPE_COLOR:"#5B9BD5",PSR_PLAN_CUM:101,PSR_PLAN_WK:2,PSR_FORECAST_CUM:101,PSR_FORECAST_WK:4,PSR_ACTUAL_CUM:111,PSR_ACTUAL_WK:9,RAS_ROS:0},
    {CUTOFF:"2022-01-28",WK_NO:"W2022_04",WORK_TYPE_CODE:"MDI",WORK_TYPE_COLOR:"#43682B",PSR_PLAN_CUM:13,PSR_PLAN_WK:1,PSR_FORECAST_CUM:10,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:12,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-28",WK_NO:"W2022_04",WORK_TYPE_CODE:"CC",WORK_TYPE_COLOR:"#8CC168",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-28",WK_NO:"W2022_04",WORK_TYPE_CODE:"PP",WORK_TYPE_COLOR:"#4472C4",PSR_PLAN_CUM:134,PSR_PLAN_WK:11,PSR_FORECAST_CUM:134,PSR_FORECAST_WK:11,PSR_ACTUAL_CUM:147,PSR_ACTUAL_WK:8,RAS_ROS:0},
    {CUTOFF:"2022-01-28",WK_NO:"W2022_04",WORK_TYPE_CODE:"EW",WORK_TYPE_COLOR:"#FFCD33",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-28",WK_NO:"W2022_04",WORK_TYPE_CODE:"RFQ",WORK_TYPE_COLOR:"#FFC000",PSR_PLAN_CUM:117,PSR_PLAN_WK:1,PSR_FORECAST_CUM:114,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:118,PSR_ACTUAL_WK:1,RAS_ROS:0},
    {CUTOFF:"2022-01-28",WK_NO:"W2022_04",WORK_TYPE_CODE:"LOA",WORK_TYPE_COLOR:"#997300",PSR_PLAN_CUM:41,PSR_PLAN_WK:1,PSR_FORECAST_CUM:44,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:44,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-28",WK_NO:"W2022_04",WORK_TYPE_CODE:"TBE",WORK_TYPE_COLOR:"#70AD47",PSR_PLAN_CUM:68,PSR_PLAN_WK:7,PSR_FORECAST_CUM:62,PSR_FORECAST_WK:4,PSR_ACTUAL_CUM:68,PSR_ACTUAL_WK:9,RAS_ROS:0},
    {CUTOFF:"2022-01-28",WK_NO:"W2022_04",WORK_TYPE_CODE:"MMR",WORK_TYPE_COLOR:"#F1975A",PSR_PLAN_CUM:2,PSR_PLAN_WK:0,PSR_FORECAST_CUM:2,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-28",WK_NO:"W2022_04",WORK_TYPE_CODE:"MO",WORK_TYPE_COLOR:"#698ED0",PSR_PLAN_CUM:4,PSR_PLAN_WK:1,PSR_FORECAST_CUM:4,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-28",WK_NO:"W2022_04",WORK_TYPE_CODE:"MR",WORK_TYPE_COLOR:"#A5A5A5",PSR_PLAN_CUM:118,PSR_PLAN_WK:1,PSR_FORECAST_CUM:118,PSR_FORECAST_WK:2,PSR_ACTUAL_CUM:65,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-28",WK_NO:"W2022_04",WORK_TYPE_CODE:"PO",WORK_TYPE_COLOR:"#255E91",PSR_PLAN_CUM:24,PSR_PLAN_WK:1,PSR_FORECAST_CUM:24,PSR_FORECAST_WK:6,PSR_ACTUAL_CUM:26,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-01-28",WK_NO:"W2022_04",WORK_TYPE_CODE:"PPA",WORK_TYPE_COLOR:"#ED7D31",PSR_PLAN_CUM:123,PSR_PLAN_WK:1,PSR_FORECAST_CUM:120,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:141,PSR_ACTUAL_WK:4,RAS_ROS:0},
    {CUTOFF:"2022-01-28",WK_NO:"W2022_04",WORK_TYPE_CODE:"SA",WORK_TYPE_COLOR:"#7CAFDD",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-04",WK_NO:"W2022_05",WORK_TYPE_CODE:"AR",WORK_TYPE_COLOR:"#9E480E",PSR_PLAN_CUM:54,PSR_PLAN_WK:1,PSR_FORECAST_CUM:51,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:53,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-04",WK_NO:"W2022_05",WORK_TYPE_CODE:"ARA",WORK_TYPE_COLOR:"#636363",PSR_PLAN_CUM:53,PSR_PLAN_WK:2,PSR_FORECAST_CUM:52,PSR_FORECAST_WK:4,PSR_ACTUAL_CUM:29,PSR_ACTUAL_WK:2,RAS_ROS:0},
    {CUTOFF:"2022-02-04",WK_NO:"W2022_05",WORK_TYPE_CODE:"BC",WORK_TYPE_COLOR:"#5B9BD5",PSR_PLAN_CUM:108,PSR_PLAN_WK:7,PSR_FORECAST_CUM:106,PSR_FORECAST_WK:5,PSR_ACTUAL_CUM:112,PSR_ACTUAL_WK:1,RAS_ROS:0},
    {CUTOFF:"2022-02-04",WK_NO:"W2022_05",WORK_TYPE_CODE:"CBE",WORK_TYPE_COLOR:"#264478",PSR_PLAN_CUM:59,PSR_PLAN_WK:1,PSR_FORECAST_CUM:56,PSR_FORECAST_WK:2,PSR_ACTUAL_CUM:59,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-04",WK_NO:"W2022_05",WORK_TYPE_CODE:"EW",WORK_TYPE_COLOR:"#FFCD33",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-04",WK_NO:"W2022_05",WORK_TYPE_CODE:"CC",WORK_TYPE_COLOR:"#8CC168",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-04",WK_NO:"W2022_05",WORK_TYPE_CODE:"FI",WORK_TYPE_COLOR:"#B7B7B7",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-04",WK_NO:"W2022_05",WORK_TYPE_CODE:"DJS",WORK_TYPE_COLOR:"#335AA1",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-04",WK_NO:"W2022_05",WORK_TYPE_CODE:"LOA",WORK_TYPE_COLOR:"#997300",PSR_PLAN_CUM:46,PSR_PLAN_WK:5,PSR_FORECAST_CUM:46,PSR_FORECAST_WK:2,PSR_ACTUAL_CUM:44,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-04",WK_NO:"W2022_05",WORK_TYPE_CODE:"MDI",WORK_TYPE_COLOR:"#43682B",PSR_PLAN_CUM:13,PSR_PLAN_WK:0,PSR_FORECAST_CUM:12,PSR_FORECAST_WK:2,PSR_ACTUAL_CUM:13,PSR_ACTUAL_WK:1,RAS_ROS:0},
    {CUTOFF:"2022-02-04",WK_NO:"W2022_05",WORK_TYPE_CODE:"MMR",WORK_TYPE_COLOR:"#F1975A",PSR_PLAN_CUM:2,PSR_PLAN_WK:0,PSR_FORECAST_CUM:2,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-04",WK_NO:"W2022_05",WORK_TYPE_CODE:"PPA",WORK_TYPE_COLOR:"#ED7D31",PSR_PLAN_CUM:123,PSR_PLAN_WK:0,PSR_FORECAST_CUM:120,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:144,PSR_ACTUAL_WK:3,RAS_ROS:0},
    {CUTOFF:"2022-02-04",WK_NO:"W2022_05",WORK_TYPE_CODE:"MO",WORK_TYPE_COLOR:"#698ED0",PSR_PLAN_CUM:4,PSR_PLAN_WK:0,PSR_FORECAST_CUM:4,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-04",WK_NO:"W2022_05",WORK_TYPE_CODE:"RFQ",WORK_TYPE_COLOR:"#FFC000",PSR_PLAN_CUM:117,PSR_PLAN_WK:0,PSR_FORECAST_CUM:116,PSR_FORECAST_WK:2,PSR_ACTUAL_CUM:118,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-04",WK_NO:"W2022_05",WORK_TYPE_CODE:"MR",WORK_TYPE_COLOR:"#A5A5A5",PSR_PLAN_CUM:118,PSR_PLAN_WK:0,PSR_FORECAST_CUM:118,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:65,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-04",WK_NO:"W2022_05",WORK_TYPE_CODE:"TBE",WORK_TYPE_COLOR:"#70AD47",PSR_PLAN_CUM:72,PSR_PLAN_WK:4,PSR_FORECAST_CUM:68,PSR_FORECAST_WK:6,PSR_ACTUAL_CUM:68,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-04",WK_NO:"W2022_05",WORK_TYPE_CODE:"PO",WORK_TYPE_COLOR:"#255E91",PSR_PLAN_CUM:28,PSR_PLAN_WK:4,PSR_FORECAST_CUM:24,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:26,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-04",WK_NO:"W2022_05",WORK_TYPE_CODE:"PP",WORK_TYPE_COLOR:"#4472C4",PSR_PLAN_CUM:138,PSR_PLAN_WK:4,PSR_FORECAST_CUM:138,PSR_FORECAST_WK:4,PSR_ACTUAL_CUM:154,PSR_ACTUAL_WK:7,RAS_ROS:0},
    {CUTOFF:"2022-02-04",WK_NO:"W2022_05",WORK_TYPE_CODE:"SA",WORK_TYPE_COLOR:"#7CAFDD",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-11",WK_NO:"W2022_06",WORK_TYPE_CODE:"CC",WORK_TYPE_COLOR:"#8CC168",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-11",WK_NO:"W2022_06",WORK_TYPE_CODE:"AR",WORK_TYPE_COLOR:"#9E480E",PSR_PLAN_CUM:57,PSR_PLAN_WK:3,PSR_FORECAST_CUM:53,PSR_FORECAST_WK:2,PSR_ACTUAL_CUM:53,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-11",WK_NO:"W2022_06",WORK_TYPE_CODE:"DJS",WORK_TYPE_COLOR:"#335AA1",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-11",WK_NO:"W2022_06",WORK_TYPE_CODE:"ARA",WORK_TYPE_COLOR:"#636363",PSR_PLAN_CUM:56,PSR_PLAN_WK:3,PSR_FORECAST_CUM:52,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:29,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-11",WK_NO:"W2022_06",WORK_TYPE_CODE:"EW",WORK_TYPE_COLOR:"#FFCD33",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-11",WK_NO:"W2022_06",WORK_TYPE_CODE:"BC",WORK_TYPE_COLOR:"#5B9BD5",PSR_PLAN_CUM:112,PSR_PLAN_WK:4,PSR_FORECAST_CUM:113,PSR_FORECAST_WK:7,PSR_ACTUAL_CUM:113,PSR_ACTUAL_WK:1,RAS_ROS:0},
    {CUTOFF:"2022-02-11",WK_NO:"W2022_06",WORK_TYPE_CODE:"FI",WORK_TYPE_COLOR:"#B7B7B7",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-11",WK_NO:"W2022_06",WORK_TYPE_CODE:"CBE",WORK_TYPE_COLOR:"#264478",PSR_PLAN_CUM:65,PSR_PLAN_WK:6,PSR_FORECAST_CUM:56,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:59,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-11",WK_NO:"W2022_06",WORK_TYPE_CODE:"LOA",WORK_TYPE_COLOR:"#997300",PSR_PLAN_CUM:47,PSR_PLAN_WK:1,PSR_FORECAST_CUM:46,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:44,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-11",WK_NO:"W2022_06",WORK_TYPE_CODE:"MR",WORK_TYPE_COLOR:"#A5A5A5",PSR_PLAN_CUM:130,PSR_PLAN_WK:12,PSR_FORECAST_CUM:129,PSR_FORECAST_WK:11,PSR_ACTUAL_CUM:70,PSR_ACTUAL_WK:5,RAS_ROS:0},
    {CUTOFF:"2022-02-11",WK_NO:"W2022_06",WORK_TYPE_CODE:"MDI",WORK_TYPE_COLOR:"#43682B",PSR_PLAN_CUM:14,PSR_PLAN_WK:1,PSR_FORECAST_CUM:13,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:13,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-11",WK_NO:"W2022_06",WORK_TYPE_CODE:"PO",WORK_TYPE_COLOR:"#255E91",PSR_PLAN_CUM:35,PSR_PLAN_WK:7,PSR_FORECAST_CUM:27,PSR_FORECAST_WK:3,PSR_ACTUAL_CUM:26,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-11",WK_NO:"W2022_06",WORK_TYPE_CODE:"MMR",WORK_TYPE_COLOR:"#F1975A",PSR_PLAN_CUM:2,PSR_PLAN_WK:0,PSR_FORECAST_CUM:2,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-11",WK_NO:"W2022_06",WORK_TYPE_CODE:"PPA",WORK_TYPE_COLOR:"#ED7D31",PSR_PLAN_CUM:134,PSR_PLAN_WK:11,PSR_FORECAST_CUM:136,PSR_FORECAST_WK:16,PSR_ACTUAL_CUM:153,PSR_ACTUAL_WK:9,RAS_ROS:0},
    {CUTOFF:"2022-02-11",WK_NO:"W2022_06",WORK_TYPE_CODE:"MO",WORK_TYPE_COLOR:"#698ED0",PSR_PLAN_CUM:4,PSR_PLAN_WK:0,PSR_FORECAST_CUM:4,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-11",WK_NO:"W2022_06",WORK_TYPE_CODE:"PP",WORK_TYPE_COLOR:"#4472C4",PSR_PLAN_CUM:165,PSR_PLAN_WK:27,PSR_FORECAST_CUM:165,PSR_FORECAST_WK:27,PSR_ACTUAL_CUM:172,PSR_ACTUAL_WK:18,RAS_ROS:0},
    {CUTOFF:"2022-02-11",WK_NO:"W2022_06",WORK_TYPE_CODE:"RFQ",WORK_TYPE_COLOR:"#FFC000",PSR_PLAN_CUM:128,PSR_PLAN_WK:11,PSR_FORECAST_CUM:131,PSR_FORECAST_WK:15,PSR_ACTUAL_CUM:136,PSR_ACTUAL_WK:18,RAS_ROS:0},
    {CUTOFF:"2022-02-11",WK_NO:"W2022_06",WORK_TYPE_CODE:"SA",WORK_TYPE_COLOR:"#7CAFDD",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-11",WK_NO:"W2022_06",WORK_TYPE_CODE:"TBE",WORK_TYPE_COLOR:"#70AD47",PSR_PLAN_CUM:77,PSR_PLAN_WK:5,PSR_FORECAST_CUM:68,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:69,PSR_ACTUAL_WK:1,RAS_ROS:0},
    {CUTOFF:"2022-02-18",WK_NO:"W2022_07",WORK_TYPE_CODE:"AR",WORK_TYPE_COLOR:"#9E480E",PSR_PLAN_CUM:58,PSR_PLAN_WK:1,PSR_FORECAST_CUM:54,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:54,PSR_ACTUAL_WK:1,RAS_ROS:0},
    {CUTOFF:"2022-02-18",WK_NO:"W2022_07",WORK_TYPE_CODE:"ARA",WORK_TYPE_COLOR:"#636363",PSR_PLAN_CUM:57,PSR_PLAN_WK:1,PSR_FORECAST_CUM:53,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:30,PSR_ACTUAL_WK:1,RAS_ROS:0},
    {CUTOFF:"2022-02-18",WK_NO:"W2022_07",WORK_TYPE_CODE:"BC",WORK_TYPE_COLOR:"#5B9BD5",PSR_PLAN_CUM:115,PSR_PLAN_WK:3,PSR_FORECAST_CUM:116,PSR_FORECAST_WK:3,PSR_ACTUAL_CUM:116,PSR_ACTUAL_WK:3,RAS_ROS:0},
    {CUTOFF:"2022-02-18",WK_NO:"W2022_07",WORK_TYPE_CODE:"CC",WORK_TYPE_COLOR:"#8CC168",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-18",WK_NO:"W2022_07",WORK_TYPE_CODE:"CBE",WORK_TYPE_COLOR:"#264478",PSR_PLAN_CUM:69,PSR_PLAN_WK:4,PSR_FORECAST_CUM:59,PSR_FORECAST_WK:3,PSR_ACTUAL_CUM:61,PSR_ACTUAL_WK:2,RAS_ROS:0},
    {CUTOFF:"2022-02-18",WK_NO:"W2022_07",WORK_TYPE_CODE:"FI",WORK_TYPE_COLOR:"#B7B7B7",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-18",WK_NO:"W2022_07",WORK_TYPE_CODE:"DJS",WORK_TYPE_COLOR:"#335AA1",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-18",WK_NO:"W2022_07",WORK_TYPE_CODE:"MDI",WORK_TYPE_COLOR:"#43682B",PSR_PLAN_CUM:18,PSR_PLAN_WK:4,PSR_FORECAST_CUM:21,PSR_FORECAST_WK:8,PSR_ACTUAL_CUM:13,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-18",WK_NO:"W2022_07",WORK_TYPE_CODE:"EW",WORK_TYPE_COLOR:"#FFCD33",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-18",WK_NO:"W2022_07",WORK_TYPE_CODE:"MMR",WORK_TYPE_COLOR:"#F1975A",PSR_PLAN_CUM:6,PSR_PLAN_WK:4,PSR_FORECAST_CUM:6,PSR_FORECAST_WK:4,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-18",WK_NO:"W2022_07",WORK_TYPE_CODE:"LOA",WORK_TYPE_COLOR:"#997300",PSR_PLAN_CUM:50,PSR_PLAN_WK:3,PSR_FORECAST_CUM:46,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:46,PSR_ACTUAL_WK:2,RAS_ROS:0},
    {CUTOFF:"2022-02-18",WK_NO:"W2022_07",WORK_TYPE_CODE:"MR",WORK_TYPE_COLOR:"#A5A5A5",PSR_PLAN_CUM:144,PSR_PLAN_WK:14,PSR_FORECAST_CUM:144,PSR_FORECAST_WK:15,PSR_ACTUAL_CUM:75,PSR_ACTUAL_WK:5,RAS_ROS:0},
    {CUTOFF:"2022-02-18",WK_NO:"W2022_07",WORK_TYPE_CODE:"MO",WORK_TYPE_COLOR:"#698ED0",PSR_PLAN_CUM:4,PSR_PLAN_WK:0,PSR_FORECAST_CUM:4,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-18",WK_NO:"W2022_07",WORK_TYPE_CODE:"PO",WORK_TYPE_COLOR:"#255E91",PSR_PLAN_CUM:41,PSR_PLAN_WK:6,PSR_FORECAST_CUM:38,PSR_FORECAST_WK:11,PSR_ACTUAL_CUM:35,PSR_ACTUAL_WK:9,RAS_ROS:0},
    {CUTOFF:"2022-02-18",WK_NO:"W2022_07",WORK_TYPE_CODE:"PPA",WORK_TYPE_COLOR:"#ED7D31",PSR_PLAN_CUM:156,PSR_PLAN_WK:22,PSR_FORECAST_CUM:155,PSR_FORECAST_WK:19,PSR_ACTUAL_CUM:161,PSR_ACTUAL_WK:8,RAS_ROS:0},
    {CUTOFF:"2022-02-18",WK_NO:"W2022_07",WORK_TYPE_CODE:"PP",WORK_TYPE_COLOR:"#4472C4",PSR_PLAN_CUM:173,PSR_PLAN_WK:8,PSR_FORECAST_CUM:173,PSR_FORECAST_WK:8,PSR_ACTUAL_CUM:182,PSR_ACTUAL_WK:10,RAS_ROS:0},
    {CUTOFF:"2022-02-18",WK_NO:"W2022_07",WORK_TYPE_CODE:"RFQ",WORK_TYPE_COLOR:"#FFC000",PSR_PLAN_CUM:137,PSR_PLAN_WK:9,PSR_FORECAST_CUM:137,PSR_FORECAST_WK:6,PSR_ACTUAL_CUM:148,PSR_ACTUAL_WK:12,RAS_ROS:0},
    {CUTOFF:"2022-02-18",WK_NO:"W2022_07",WORK_TYPE_CODE:"SA",WORK_TYPE_COLOR:"#7CAFDD",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-18",WK_NO:"W2022_07",WORK_TYPE_CODE:"TBE",WORK_TYPE_COLOR:"#70AD47",PSR_PLAN_CUM:87,PSR_PLAN_WK:10,PSR_FORECAST_CUM:71,PSR_FORECAST_WK:3,PSR_ACTUAL_CUM:73,PSR_ACTUAL_WK:4,RAS_ROS:0},
    {CUTOFF:"2022-02-25",WK_NO:"W2022_08",WORK_TYPE_CODE:"AR",WORK_TYPE_COLOR:"#9E480E",PSR_PLAN_CUM:62,PSR_PLAN_WK:4,PSR_FORECAST_CUM:55,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:56,PSR_ACTUAL_WK:2,RAS_ROS:0},
    {CUTOFF:"2022-02-25",WK_NO:"W2022_08",WORK_TYPE_CODE:"DJS",WORK_TYPE_COLOR:"#335AA1",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-25",WK_NO:"W2022_08",WORK_TYPE_CODE:"ARA",WORK_TYPE_COLOR:"#636363",PSR_PLAN_CUM:62,PSR_PLAN_WK:5,PSR_FORECAST_CUM:54,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:31,PSR_ACTUAL_WK:1,RAS_ROS:0},
    {CUTOFF:"2022-02-25",WK_NO:"W2022_08",WORK_TYPE_CODE:"FI",WORK_TYPE_COLOR:"#B7B7B7",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-25",WK_NO:"W2022_08",WORK_TYPE_CODE:"BC",WORK_TYPE_COLOR:"#5B9BD5",PSR_PLAN_CUM:116,PSR_PLAN_WK:1,PSR_FORECAST_CUM:116,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:116,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-25",WK_NO:"W2022_08",WORK_TYPE_CODE:"MDI",WORK_TYPE_COLOR:"#43682B",PSR_PLAN_CUM:21,PSR_PLAN_WK:3,PSR_FORECAST_CUM:31,PSR_FORECAST_WK:10,PSR_ACTUAL_CUM:15,PSR_ACTUAL_WK:2,RAS_ROS:0},
    {CUTOFF:"2022-02-25",WK_NO:"W2022_08",WORK_TYPE_CODE:"CBE",WORK_TYPE_COLOR:"#264478",PSR_PLAN_CUM:74,PSR_PLAN_WK:5,PSR_FORECAST_CUM:68,PSR_FORECAST_WK:9,PSR_ACTUAL_CUM:72,PSR_ACTUAL_WK:11,RAS_ROS:0},
    {CUTOFF:"2022-02-25",WK_NO:"W2022_08",WORK_TYPE_CODE:"MMR",WORK_TYPE_COLOR:"#F1975A",PSR_PLAN_CUM:6,PSR_PLAN_WK:0,PSR_FORECAST_CUM:6,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-25",WK_NO:"W2022_08",WORK_TYPE_CODE:"CC",WORK_TYPE_COLOR:"#8CC168",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-25",WK_NO:"W2022_08",WORK_TYPE_CODE:"PP",WORK_TYPE_COLOR:"#4472C4",PSR_PLAN_CUM:186,PSR_PLAN_WK:13,PSR_FORECAST_CUM:186,PSR_FORECAST_WK:13,PSR_ACTUAL_CUM:201,PSR_ACTUAL_WK:19,RAS_ROS:0},
    {CUTOFF:"2022-02-25",WK_NO:"W2022_08",WORK_TYPE_CODE:"EW",WORK_TYPE_COLOR:"#FFCD33",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-25",WK_NO:"W2022_08",WORK_TYPE_CODE:"RFQ",WORK_TYPE_COLOR:"#FFC000",PSR_PLAN_CUM:144,PSR_PLAN_WK:7,PSR_FORECAST_CUM:146,PSR_FORECAST_WK:9,PSR_ACTUAL_CUM:158,PSR_ACTUAL_WK:10,RAS_ROS:0},
    {CUTOFF:"2022-02-25",WK_NO:"W2022_08",WORK_TYPE_CODE:"LOA",WORK_TYPE_COLOR:"#997300",PSR_PLAN_CUM:52,PSR_PLAN_WK:2,PSR_FORECAST_CUM:49,PSR_FORECAST_WK:3,PSR_ACTUAL_CUM:49,PSR_ACTUAL_WK:3,RAS_ROS:0},
    {CUTOFF:"2022-02-25",WK_NO:"W2022_08",WORK_TYPE_CODE:"MO",WORK_TYPE_COLOR:"#698ED0",PSR_PLAN_CUM:4,PSR_PLAN_WK:0,PSR_FORECAST_CUM:4,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-25",WK_NO:"W2022_08",WORK_TYPE_CODE:"MR",WORK_TYPE_COLOR:"#A5A5A5",PSR_PLAN_CUM:153,PSR_PLAN_WK:9,PSR_FORECAST_CUM:152,PSR_FORECAST_WK:8,PSR_ACTUAL_CUM:79,PSR_ACTUAL_WK:4,RAS_ROS:0},
    {CUTOFF:"2022-02-25",WK_NO:"W2022_08",WORK_TYPE_CODE:"PO",WORK_TYPE_COLOR:"#255E91",PSR_PLAN_CUM:43,PSR_PLAN_WK:2,PSR_FORECAST_CUM:39,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:39,PSR_ACTUAL_WK:4,RAS_ROS:0},
    {CUTOFF:"2022-02-25",WK_NO:"W2022_08",WORK_TYPE_CODE:"PPA",WORK_TYPE_COLOR:"#ED7D31",PSR_PLAN_CUM:171,PSR_PLAN_WK:15,PSR_FORECAST_CUM:169,PSR_FORECAST_WK:14,PSR_ACTUAL_CUM:176,PSR_ACTUAL_WK:15,RAS_ROS:0},
    {CUTOFF:"2022-02-25",WK_NO:"W2022_08",WORK_TYPE_CODE:"SA",WORK_TYPE_COLOR:"#7CAFDD",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:0},
    {CUTOFF:"2022-02-25",WK_NO:"W2022_08",WORK_TYPE_CODE:"TBE",WORK_TYPE_COLOR:"#70AD47",PSR_PLAN_CUM:100,PSR_PLAN_WK:13,PSR_FORECAST_CUM:75,PSR_FORECAST_WK:4,PSR_ACTUAL_CUM:77,PSR_ACTUAL_WK:4,RAS_ROS:0},
    {CUTOFF:"2022-03-04",WK_NO:"W2022_09",WORK_TYPE_CODE:"ARA",WORK_TYPE_COLOR:"#636363",PSR_PLAN_CUM:66,PSR_PLAN_WK:4,PSR_FORECAST_CUM:58,PSR_FORECAST_WK:4,PSR_ACTUAL_CUM:34,PSR_ACTUAL_WK:3,RAS_ROS:1},
    {CUTOFF:"2022-03-04",WK_NO:"W2022_09",WORK_TYPE_CODE:"AR",WORK_TYPE_COLOR:"#9E480E",PSR_PLAN_CUM:69,PSR_PLAN_WK:7,PSR_FORECAST_CUM:57,PSR_FORECAST_WK:2,PSR_ACTUAL_CUM:65,PSR_ACTUAL_WK:9,RAS_ROS:1},
    {CUTOFF:"2022-03-04",WK_NO:"W2022_09",WORK_TYPE_CODE:"CC",WORK_TYPE_COLOR:"#8CC168",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-03-04",WK_NO:"W2022_09",WORK_TYPE_CODE:"BC",WORK_TYPE_COLOR:"#5B9BD5",PSR_PLAN_CUM:117,PSR_PLAN_WK:1,PSR_FORECAST_CUM:117,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:120,PSR_ACTUAL_WK:4,RAS_ROS:1},
    {CUTOFF:"2022-03-04",WK_NO:"W2022_09",WORK_TYPE_CODE:"FI",WORK_TYPE_COLOR:"#B7B7B7",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-03-04",WK_NO:"W2022_09",WORK_TYPE_CODE:"CBE",WORK_TYPE_COLOR:"#264478",PSR_PLAN_CUM:85,PSR_PLAN_WK:11,PSR_FORECAST_CUM:72,PSR_FORECAST_WK:4,PSR_ACTUAL_CUM:73,PSR_ACTUAL_WK:1,RAS_ROS:1},
    {CUTOFF:"2022-03-04",WK_NO:"W2022_09",WORK_TYPE_CODE:"MDI",WORK_TYPE_COLOR:"#43682B",PSR_PLAN_CUM:26,PSR_PLAN_WK:5,PSR_FORECAST_CUM:32,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:15,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-03-04",WK_NO:"W2022_09",WORK_TYPE_CODE:"DJS",WORK_TYPE_COLOR:"#335AA1",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-03-04",WK_NO:"W2022_09",WORK_TYPE_CODE:"MMR",WORK_TYPE_COLOR:"#F1975A",PSR_PLAN_CUM:7,PSR_PLAN_WK:1,PSR_FORECAST_CUM:7,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-03-04",WK_NO:"W2022_09",WORK_TYPE_CODE:"EW",WORK_TYPE_COLOR:"#FFCD33",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-03-04",WK_NO:"W2022_09",WORK_TYPE_CODE:"MR",WORK_TYPE_COLOR:"#A5A5A5",PSR_PLAN_CUM:166,PSR_PLAN_WK:13,PSR_FORECAST_CUM:166,PSR_FORECAST_WK:14,PSR_ACTUAL_CUM:81,PSR_ACTUAL_WK:2,RAS_ROS:1},
    {CUTOFF:"2022-03-04",WK_NO:"W2022_09",WORK_TYPE_CODE:"LOA",WORK_TYPE_COLOR:"#997300",PSR_PLAN_CUM:56,PSR_PLAN_WK:4,PSR_FORECAST_CUM:50,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:52,PSR_ACTUAL_WK:3,RAS_ROS:1},
    {CUTOFF:"2022-03-04",WK_NO:"W2022_09",WORK_TYPE_CODE:"PO",WORK_TYPE_COLOR:"#255E91",PSR_PLAN_CUM:44,PSR_PLAN_WK:1,PSR_FORECAST_CUM:47,PSR_FORECAST_WK:8,PSR_ACTUAL_CUM:43,PSR_ACTUAL_WK:4,RAS_ROS:1},
    {CUTOFF:"2022-03-04",WK_NO:"W2022_09",WORK_TYPE_CODE:"MO",WORK_TYPE_COLOR:"#698ED0",PSR_PLAN_CUM:4,PSR_PLAN_WK:0,PSR_FORECAST_CUM:4,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-03-04",WK_NO:"W2022_09",WORK_TYPE_CODE:"PP",WORK_TYPE_COLOR:"#4472C4",PSR_PLAN_CUM:191,PSR_PLAN_WK:5,PSR_FORECAST_CUM:191,PSR_FORECAST_WK:5,PSR_ACTUAL_CUM:203,PSR_ACTUAL_WK:2,RAS_ROS:1},
    {CUTOFF:"2022-03-04",WK_NO:"W2022_09",WORK_TYPE_CODE:"PPA",WORK_TYPE_COLOR:"#ED7D31",PSR_PLAN_CUM:179,PSR_PLAN_WK:8,PSR_FORECAST_CUM:177,PSR_FORECAST_WK:8,PSR_ACTUAL_CUM:177,PSR_ACTUAL_WK:1,RAS_ROS:1},
    {CUTOFF:"2022-03-04",WK_NO:"W2022_09",WORK_TYPE_CODE:"RFQ",WORK_TYPE_COLOR:"#FFC000",PSR_PLAN_CUM:163,PSR_PLAN_WK:19,PSR_FORECAST_CUM:164,PSR_FORECAST_WK:18,PSR_ACTUAL_CUM:172,PSR_ACTUAL_WK:14,RAS_ROS:1},
    {CUTOFF:"2022-03-04",WK_NO:"W2022_09",WORK_TYPE_CODE:"SA",WORK_TYPE_COLOR:"#7CAFDD",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-03-04",WK_NO:"W2022_09",WORK_TYPE_CODE:"TBE",WORK_TYPE_COLOR:"#70AD47",PSR_PLAN_CUM:101,PSR_PLAN_WK:1,PSR_FORECAST_CUM:83,PSR_FORECAST_WK:8,PSR_ACTUAL_CUM:83,PSR_ACTUAL_WK:6,RAS_ROS:1},
    {CUTOFF:"2022-03-11",WK_NO:"W2022_10",WORK_TYPE_CODE:"ARA",WORK_TYPE_COLOR:"#636363",PSR_PLAN_CUM:69,PSR_PLAN_WK:3,PSR_FORECAST_CUM:65,PSR_FORECAST_WK:7,PSR_ACTUAL_CUM:35,PSR_ACTUAL_WK:1,RAS_ROS:1},
    {CUTOFF:"2022-03-11",WK_NO:"W2022_10",WORK_TYPE_CODE:"AR",WORK_TYPE_COLOR:"#9E480E",PSR_PLAN_CUM:70,PSR_PLAN_WK:1,PSR_FORECAST_CUM:65,PSR_FORECAST_WK:8,PSR_ACTUAL_CUM:66,PSR_ACTUAL_WK:1,RAS_ROS:1},
    {CUTOFF:"2022-03-11",WK_NO:"W2022_10",WORK_TYPE_CODE:"CC",WORK_TYPE_COLOR:"#8CC168",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-03-11",WK_NO:"W2022_10",WORK_TYPE_CODE:"BC",WORK_TYPE_COLOR:"#5B9BD5",PSR_PLAN_CUM:117,PSR_PLAN_WK:0,PSR_FORECAST_CUM:122,PSR_FORECAST_WK:5,PSR_ACTUAL_CUM:123,PSR_ACTUAL_WK:3,RAS_ROS:1},
    {CUTOFF:"2022-03-11",WK_NO:"W2022_10",WORK_TYPE_CODE:"FI",WORK_TYPE_COLOR:"#B7B7B7",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-03-11",WK_NO:"W2022_10",WORK_TYPE_CODE:"CBE",WORK_TYPE_COLOR:"#264478",PSR_PLAN_CUM:91,PSR_PLAN_WK:6,PSR_FORECAST_CUM:76,PSR_FORECAST_WK:4,PSR_ACTUAL_CUM:76,PSR_ACTUAL_WK:3,RAS_ROS:1},
    {CUTOFF:"2022-03-11",WK_NO:"W2022_10",WORK_TYPE_CODE:"MDI",WORK_TYPE_COLOR:"#43682B",PSR_PLAN_CUM:28,PSR_PLAN_WK:2,PSR_FORECAST_CUM:32,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:15,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-03-11",WK_NO:"W2022_10",WORK_TYPE_CODE:"DJS",WORK_TYPE_COLOR:"#335AA1",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-03-11",WK_NO:"W2022_10",WORK_TYPE_CODE:"MMR",WORK_TYPE_COLOR:"#F1975A",PSR_PLAN_CUM:7,PSR_PLAN_WK:0,PSR_FORECAST_CUM:7,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-03-11",WK_NO:"W2022_10",WORK_TYPE_CODE:"EW",WORK_TYPE_COLOR:"#FFCD33",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-03-11",WK_NO:"W2022_10",WORK_TYPE_CODE:"PO",WORK_TYPE_COLOR:"#255E91",PSR_PLAN_CUM:45,PSR_PLAN_WK:1,PSR_FORECAST_CUM:47,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:49,PSR_ACTUAL_WK:6,RAS_ROS:1},
    {CUTOFF:"2022-03-11",WK_NO:"W2022_10",WORK_TYPE_CODE:"LOA",WORK_TYPE_COLOR:"#997300",PSR_PLAN_CUM:62,PSR_PLAN_WK:6,PSR_FORECAST_CUM:55,PSR_FORECAST_WK:5,PSR_ACTUAL_CUM:55,PSR_ACTUAL_WK:3,RAS_ROS:1},
    {CUTOFF:"2022-03-11",WK_NO:"W2022_10",WORK_TYPE_CODE:"PP",WORK_TYPE_COLOR:"#4472C4",PSR_PLAN_CUM:223,PSR_PLAN_WK:32,PSR_FORECAST_CUM:223,PSR_FORECAST_WK:32,PSR_ACTUAL_CUM:241,PSR_ACTUAL_WK:38,RAS_ROS:1},
    {CUTOFF:"2022-03-11",WK_NO:"W2022_10",WORK_TYPE_CODE:"MO",WORK_TYPE_COLOR:"#698ED0",PSR_PLAN_CUM:6,PSR_PLAN_WK:2,PSR_FORECAST_CUM:6,PSR_FORECAST_WK:2,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-03-11",WK_NO:"W2022_10",WORK_TYPE_CODE:"PPA",WORK_TYPE_COLOR:"#ED7D31",PSR_PLAN_CUM:186,PSR_PLAN_WK:7,PSR_FORECAST_CUM:187,PSR_FORECAST_WK:10,PSR_ACTUAL_CUM:191,PSR_ACTUAL_WK:14,RAS_ROS:1},
    {CUTOFF:"2022-03-11",WK_NO:"W2022_10",WORK_TYPE_CODE:"MR",WORK_TYPE_COLOR:"#A5A5A5",PSR_PLAN_CUM:174,PSR_PLAN_WK:8,PSR_FORECAST_CUM:174,PSR_FORECAST_WK:8,PSR_ACTUAL_CUM:86,PSR_ACTUAL_WK:5,RAS_ROS:1},
    {CUTOFF:"2022-03-11",WK_NO:"W2022_10",WORK_TYPE_CODE:"RFQ",WORK_TYPE_COLOR:"#FFC000",PSR_PLAN_CUM:170,PSR_PLAN_WK:7,PSR_FORECAST_CUM:171,PSR_FORECAST_WK:7,PSR_ACTUAL_CUM:180,PSR_ACTUAL_WK:8,RAS_ROS:1},
    {CUTOFF:"2022-03-11",WK_NO:"W2022_10",WORK_TYPE_CODE:"SA",WORK_TYPE_COLOR:"#7CAFDD",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-03-11",WK_NO:"W2022_10",WORK_TYPE_CODE:"TBE",WORK_TYPE_COLOR:"#70AD47",PSR_PLAN_CUM:111,PSR_PLAN_WK:10,PSR_FORECAST_CUM:84,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:85,PSR_ACTUAL_WK:2,RAS_ROS:1},
    {CUTOFF:"2022-03-18",WK_NO:"W2022_11",WORK_TYPE_CODE:"CC",WORK_TYPE_COLOR:"#8CC168",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-03-18",WK_NO:"W2022_11",WORK_TYPE_CODE:"AR",WORK_TYPE_COLOR:"#9E480E",PSR_PLAN_CUM:76,PSR_PLAN_WK:6,PSR_FORECAST_CUM:66,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:69,PSR_ACTUAL_WK:3,RAS_ROS:1},
    {CUTOFF:"2022-03-18",WK_NO:"W2022_11",WORK_TYPE_CODE:"DJS",WORK_TYPE_COLOR:"#335AA1",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-03-18",WK_NO:"W2022_11",WORK_TYPE_CODE:"ARA",WORK_TYPE_COLOR:"#636363",PSR_PLAN_CUM:72,PSR_PLAN_WK:3,PSR_FORECAST_CUM:68,PSR_FORECAST_WK:3,PSR_ACTUAL_CUM:35,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-03-18",WK_NO:"W2022_11",WORK_TYPE_CODE:"EW",WORK_TYPE_COLOR:"#FFCD33",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-03-18",WK_NO:"W2022_11",WORK_TYPE_CODE:"BC",WORK_TYPE_COLOR:"#5B9BD5",PSR_PLAN_CUM:129,PSR_PLAN_WK:12,PSR_FORECAST_CUM:135,PSR_FORECAST_WK:13,PSR_ACTUAL_CUM:131,PSR_ACTUAL_WK:8,RAS_ROS:1},
    {CUTOFF:"2022-03-18",WK_NO:"W2022_11",WORK_TYPE_CODE:"FI",WORK_TYPE_COLOR:"#B7B7B7",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-03-18",WK_NO:"W2022_11",WORK_TYPE_CODE:"CBE",WORK_TYPE_COLOR:"#264478",PSR_PLAN_CUM:101,PSR_PLAN_WK:10,PSR_FORECAST_CUM:81,PSR_FORECAST_WK:5,PSR_ACTUAL_CUM:80,PSR_ACTUAL_WK:4,RAS_ROS:1},
    {CUTOFF:"2022-03-18",WK_NO:"W2022_11",WORK_TYPE_CODE:"MDI",WORK_TYPE_COLOR:"#43682B",PSR_PLAN_CUM:28,PSR_PLAN_WK:0,PSR_FORECAST_CUM:33,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:15,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-03-18",WK_NO:"W2022_11",WORK_TYPE_CODE:"LOA",WORK_TYPE_COLOR:"#997300",PSR_PLAN_CUM:63,PSR_PLAN_WK:1,PSR_FORECAST_CUM:59,PSR_FORECAST_WK:4,PSR_ACTUAL_CUM:59,PSR_ACTUAL_WK:4,RAS_ROS:1},
    {CUTOFF:"2022-03-18",WK_NO:"W2022_11",WORK_TYPE_CODE:"MMR",WORK_TYPE_COLOR:"#F1975A",PSR_PLAN_CUM:7,PSR_PLAN_WK:0,PSR_FORECAST_CUM:7,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-03-18",WK_NO:"W2022_11",WORK_TYPE_CODE:"MR",WORK_TYPE_COLOR:"#A5A5A5",PSR_PLAN_CUM:177,PSR_PLAN_WK:3,PSR_FORECAST_CUM:177,PSR_FORECAST_WK:3,PSR_ACTUAL_CUM:90,PSR_ACTUAL_WK:4,RAS_ROS:1},
    {CUTOFF:"2022-03-18",WK_NO:"W2022_11",WORK_TYPE_CODE:"MO",WORK_TYPE_COLOR:"#698ED0",PSR_PLAN_CUM:8,PSR_PLAN_WK:2,PSR_FORECAST_CUM:8,PSR_FORECAST_WK:2,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-03-18",WK_NO:"W2022_11",WORK_TYPE_CODE:"PO",WORK_TYPE_COLOR:"#255E91",PSR_PLAN_CUM:50,PSR_PLAN_WK:5,PSR_FORECAST_CUM:49,PSR_FORECAST_WK:2,PSR_ACTUAL_CUM:51,PSR_ACTUAL_WK:2,RAS_ROS:1},
    {CUTOFF:"2022-03-18",WK_NO:"W2022_11",WORK_TYPE_CODE:"PP",WORK_TYPE_COLOR:"#4472C4",PSR_PLAN_CUM:238,PSR_PLAN_WK:15,PSR_FORECAST_CUM:238,PSR_FORECAST_WK:15,PSR_ACTUAL_CUM:243,PSR_ACTUAL_WK:2,RAS_ROS:1},
    {CUTOFF:"2022-03-18",WK_NO:"W2022_11",WORK_TYPE_CODE:"PPA",WORK_TYPE_COLOR:"#ED7D31",PSR_PLAN_CUM:199,PSR_PLAN_WK:13,PSR_FORECAST_CUM:197,PSR_FORECAST_WK:10,PSR_ACTUAL_CUM:196,PSR_ACTUAL_WK:5,RAS_ROS:1},
    {CUTOFF:"2022-03-18",WK_NO:"W2022_11",WORK_TYPE_CODE:"SA",WORK_TYPE_COLOR:"#7CAFDD",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-03-18",WK_NO:"W2022_11",WORK_TYPE_CODE:"RFQ",WORK_TYPE_COLOR:"#FFC000",PSR_PLAN_CUM:174,PSR_PLAN_WK:4,PSR_FORECAST_CUM:180,PSR_FORECAST_WK:9,PSR_ACTUAL_CUM:190,PSR_ACTUAL_WK:10,RAS_ROS:1},
    {CUTOFF:"2022-03-18",WK_NO:"W2022_11",WORK_TYPE_CODE:"TBE",WORK_TYPE_COLOR:"#70AD47",PSR_PLAN_CUM:112,PSR_PLAN_WK:1,PSR_FORECAST_CUM:86,PSR_FORECAST_WK:2,PSR_ACTUAL_CUM:85,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-03-25",WK_NO:"W2022_12",WORK_TYPE_CODE:"BC",WORK_TYPE_COLOR:"#5B9BD5",PSR_PLAN_CUM:133,PSR_PLAN_WK:4,PSR_FORECAST_CUM:142,PSR_FORECAST_WK:7,PSR_ACTUAL_CUM:149,PSR_ACTUAL_WK:18,RAS_ROS:1},
    {CUTOFF:"2022-03-25",WK_NO:"W2022_12",WORK_TYPE_CODE:"AR",WORK_TYPE_COLOR:"#9E480E",PSR_PLAN_CUM:83,PSR_PLAN_WK:7,PSR_FORECAST_CUM:68,PSR_FORECAST_WK:2,PSR_ACTUAL_CUM:75,PSR_ACTUAL_WK:6,RAS_ROS:1},
    {CUTOFF:"2022-03-25",WK_NO:"W2022_12",WORK_TYPE_CODE:"CBE",WORK_TYPE_COLOR:"#264478",PSR_PLAN_CUM:101,PSR_PLAN_WK:0,PSR_FORECAST_CUM:86,PSR_FORECAST_WK:5,PSR_ACTUAL_CUM:83,PSR_ACTUAL_WK:3,RAS_ROS:1},
    {CUTOFF:"2022-03-25",WK_NO:"W2022_12",WORK_TYPE_CODE:"ARA",WORK_TYPE_COLOR:"#636363",PSR_PLAN_CUM:80,PSR_PLAN_WK:8,PSR_FORECAST_CUM:68,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:35,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-03-25",WK_NO:"W2022_12",WORK_TYPE_CODE:"DJS",WORK_TYPE_COLOR:"#335AA1",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-03-25",WK_NO:"W2022_12",WORK_TYPE_CODE:"CC",WORK_TYPE_COLOR:"#8CC168",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-03-25",WK_NO:"W2022_12",WORK_TYPE_CODE:"LOA",WORK_TYPE_COLOR:"#997300",PSR_PLAN_CUM:65,PSR_PLAN_WK:2,PSR_FORECAST_CUM:61,PSR_FORECAST_WK:2,PSR_ACTUAL_CUM:65,PSR_ACTUAL_WK:6,RAS_ROS:1},
    {CUTOFF:"2022-03-25",WK_NO:"W2022_12",WORK_TYPE_CODE:"EW",WORK_TYPE_COLOR:"#FFCD33",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-03-25",WK_NO:"W2022_12",WORK_TYPE_CODE:"MDI",WORK_TYPE_COLOR:"#43682B",PSR_PLAN_CUM:29,PSR_PLAN_WK:1,PSR_FORECAST_CUM:33,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:15,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-03-25",WK_NO:"W2022_12",WORK_TYPE_CODE:"FI",WORK_TYPE_COLOR:"#B7B7B7",PSR_PLAN_CUM:5,PSR_PLAN_WK:5,PSR_FORECAST_CUM:5,PSR_FORECAST_WK:5,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-03-25",WK_NO:"W2022_12",WORK_TYPE_CODE:"MO",WORK_TYPE_COLOR:"#698ED0",PSR_PLAN_CUM:8,PSR_PLAN_WK:0,PSR_FORECAST_CUM:9,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-03-25",WK_NO:"W2022_12",WORK_TYPE_CODE:"MMR",WORK_TYPE_COLOR:"#F1975A",PSR_PLAN_CUM:8,PSR_PLAN_WK:1,PSR_FORECAST_CUM:8,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-03-25",WK_NO:"W2022_12",WORK_TYPE_CODE:"RFQ",WORK_TYPE_COLOR:"#FFC000",PSR_PLAN_CUM:176,PSR_PLAN_WK:2,PSR_FORECAST_CUM:181,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:196,PSR_ACTUAL_WK:6,RAS_ROS:1},
    {CUTOFF:"2022-03-25",WK_NO:"W2022_12",WORK_TYPE_CODE:"MR",WORK_TYPE_COLOR:"#A5A5A5",PSR_PLAN_CUM:190,PSR_PLAN_WK:13,PSR_FORECAST_CUM:190,PSR_FORECAST_WK:13,PSR_ACTUAL_CUM:116,PSR_ACTUAL_WK:26,RAS_ROS:1},
    {CUTOFF:"2022-03-25",WK_NO:"W2022_12",WORK_TYPE_CODE:"PO",WORK_TYPE_COLOR:"#255E91",PSR_PLAN_CUM:51,PSR_PLAN_WK:1,PSR_FORECAST_CUM:51,PSR_FORECAST_WK:2,PSR_ACTUAL_CUM:51,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-03-25",WK_NO:"W2022_12",WORK_TYPE_CODE:"PP",WORK_TYPE_COLOR:"#4472C4",PSR_PLAN_CUM:245,PSR_PLAN_WK:7,PSR_FORECAST_CUM:245,PSR_FORECAST_WK:7,PSR_ACTUAL_CUM:251,PSR_ACTUAL_WK:8,RAS_ROS:1},
    {CUTOFF:"2022-03-25",WK_NO:"W2022_12",WORK_TYPE_CODE:"PPA",WORK_TYPE_COLOR:"#ED7D31",PSR_PLAN_CUM:234,PSR_PLAN_WK:35,PSR_FORECAST_CUM:232,PSR_FORECAST_WK:35,PSR_ACTUAL_CUM:223,PSR_ACTUAL_WK:27,RAS_ROS:1},
    {CUTOFF:"2022-03-25",WK_NO:"W2022_12",WORK_TYPE_CODE:"SA",WORK_TYPE_COLOR:"#7CAFDD",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-03-25",WK_NO:"W2022_12",WORK_TYPE_CODE:"TBE",WORK_TYPE_COLOR:"#70AD47",PSR_PLAN_CUM:113,PSR_PLAN_WK:1,PSR_FORECAST_CUM:94,PSR_FORECAST_WK:8,PSR_ACTUAL_CUM:91,PSR_ACTUAL_WK:6,RAS_ROS:1},
    {CUTOFF:"2022-04-01",WK_NO:"W2022_13",WORK_TYPE_CODE:"CBE",WORK_TYPE_COLOR:"#264478",PSR_PLAN_CUM:109,PSR_PLAN_WK:8,PSR_FORECAST_CUM:86,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:87,PSR_ACTUAL_WK:4,RAS_ROS:1},
    {CUTOFF:"2022-04-01",WK_NO:"W2022_13",WORK_TYPE_CODE:"AR",WORK_TYPE_COLOR:"#9E480E",PSR_PLAN_CUM:99,PSR_PLAN_WK:16,PSR_FORECAST_CUM:75,PSR_FORECAST_WK:7,PSR_ACTUAL_CUM:77,PSR_ACTUAL_WK:2,RAS_ROS:1},
    {CUTOFF:"2022-04-01",WK_NO:"W2022_13",WORK_TYPE_CODE:"CC",WORK_TYPE_COLOR:"#8CC168",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-04-01",WK_NO:"W2022_13",WORK_TYPE_CODE:"ARA",WORK_TYPE_COLOR:"#636363",PSR_PLAN_CUM:86,PSR_PLAN_WK:6,PSR_FORECAST_CUM:76,PSR_FORECAST_WK:8,PSR_ACTUAL_CUM:42,PSR_ACTUAL_WK:7,RAS_ROS:1},
    {CUTOFF:"2022-04-01",WK_NO:"W2022_13",WORK_TYPE_CODE:"DJS",WORK_TYPE_COLOR:"#335AA1",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-04-01",WK_NO:"W2022_13",WORK_TYPE_CODE:"BC",WORK_TYPE_COLOR:"#5B9BD5",PSR_PLAN_CUM:149,PSR_PLAN_WK:16,PSR_FORECAST_CUM:171,PSR_FORECAST_WK:29,PSR_ACTUAL_CUM:154,PSR_ACTUAL_WK:5,RAS_ROS:1},
    {CUTOFF:"2022-04-01",WK_NO:"W2022_13",WORK_TYPE_CODE:"FI",WORK_TYPE_COLOR:"#B7B7B7",PSR_PLAN_CUM:5,PSR_PLAN_WK:0,PSR_FORECAST_CUM:5,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-04-01",WK_NO:"W2022_13",WORK_TYPE_CODE:"EW",WORK_TYPE_COLOR:"#FFCD33",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-04-01",WK_NO:"W2022_13",WORK_TYPE_CODE:"MMR",WORK_TYPE_COLOR:"#F1975A",PSR_PLAN_CUM:9,PSR_PLAN_WK:1,PSR_FORECAST_CUM:9,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-04-01",WK_NO:"W2022_13",WORK_TYPE_CODE:"LOA",WORK_TYPE_COLOR:"#997300",PSR_PLAN_CUM:74,PSR_PLAN_WK:9,PSR_FORECAST_CUM:71,PSR_FORECAST_WK:10,PSR_ACTUAL_CUM:71,PSR_ACTUAL_WK:6,RAS_ROS:1},
    {CUTOFF:"2022-04-01",WK_NO:"W2022_13",WORK_TYPE_CODE:"MO",WORK_TYPE_COLOR:"#698ED0",PSR_PLAN_CUM:11,PSR_PLAN_WK:3,PSR_FORECAST_CUM:12,PSR_FORECAST_WK:3,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-04-01",WK_NO:"W2022_13",WORK_TYPE_CODE:"MDI",WORK_TYPE_COLOR:"#43682B",PSR_PLAN_CUM:34,PSR_PLAN_WK:5,PSR_FORECAST_CUM:34,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:15,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-04-01",WK_NO:"W2022_13",WORK_TYPE_CODE:"PP",WORK_TYPE_COLOR:"#4472C4",PSR_PLAN_CUM:261,PSR_PLAN_WK:16,PSR_FORECAST_CUM:261,PSR_FORECAST_WK:16,PSR_ACTUAL_CUM:274,PSR_ACTUAL_WK:23,RAS_ROS:1},
    {CUTOFF:"2022-04-01",WK_NO:"W2022_13",WORK_TYPE_CODE:"MR",WORK_TYPE_COLOR:"#A5A5A5",PSR_PLAN_CUM:215,PSR_PLAN_WK:25,PSR_FORECAST_CUM:215,PSR_FORECAST_WK:25,PSR_ACTUAL_CUM:141,PSR_ACTUAL_WK:25,RAS_ROS:1},
    {CUTOFF:"2022-04-01",WK_NO:"W2022_13",WORK_TYPE_CODE:"RFQ",WORK_TYPE_COLOR:"#FFC000",PSR_PLAN_CUM:197,PSR_PLAN_WK:21,PSR_FORECAST_CUM:220,PSR_FORECAST_WK:39,PSR_ACTUAL_CUM:226,PSR_ACTUAL_WK:30,RAS_ROS:1},
    {CUTOFF:"2022-04-01",WK_NO:"W2022_13",WORK_TYPE_CODE:"PO",WORK_TYPE_COLOR:"#255E91",PSR_PLAN_CUM:54,PSR_PLAN_WK:3,PSR_FORECAST_CUM:52,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:52,PSR_ACTUAL_WK:1,RAS_ROS:1},
    {CUTOFF:"2022-04-01",WK_NO:"W2022_13",WORK_TYPE_CODE:"SA",WORK_TYPE_COLOR:"#7CAFDD",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-04-01",WK_NO:"W2022_13",WORK_TYPE_CODE:"PPA",WORK_TYPE_COLOR:"#ED7D31",PSR_PLAN_CUM:246,PSR_PLAN_WK:12,PSR_FORECAST_CUM:244,PSR_FORECAST_WK:12,PSR_ACTUAL_CUM:235,PSR_ACTUAL_WK:12,RAS_ROS:1},
    {CUTOFF:"2022-04-01",WK_NO:"W2022_13",WORK_TYPE_CODE:"TBE",WORK_TYPE_COLOR:"#70AD47",PSR_PLAN_CUM:117,PSR_PLAN_WK:4,PSR_FORECAST_CUM:101,PSR_FORECAST_WK:7,PSR_ACTUAL_CUM:104,PSR_ACTUAL_WK:13,RAS_ROS:1},
    {CUTOFF:"2022-04-08",WK_NO:"W2022_14",WORK_TYPE_CODE:"AR",WORK_TYPE_COLOR:"#9E480E",PSR_PLAN_CUM:99,PSR_PLAN_WK:0,PSR_FORECAST_CUM:76,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:105,PSR_ACTUAL_WK:28,RAS_ROS:1},
    {CUTOFF:"2022-04-08",WK_NO:"W2022_14",WORK_TYPE_CODE:"BC",WORK_TYPE_COLOR:"#5B9BD5",PSR_PLAN_CUM:161,PSR_PLAN_WK:12,PSR_FORECAST_CUM:174,PSR_FORECAST_WK:3,PSR_ACTUAL_CUM:196,PSR_ACTUAL_WK:42,RAS_ROS:1},
    {CUTOFF:"2022-04-08",WK_NO:"W2022_14",WORK_TYPE_CODE:"ARA",WORK_TYPE_COLOR:"#636363",PSR_PLAN_CUM:99,PSR_PLAN_WK:13,PSR_FORECAST_CUM:76,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:42,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-04-08",WK_NO:"W2022_14",WORK_TYPE_CODE:"CBE",WORK_TYPE_COLOR:"#264478",PSR_PLAN_CUM:112,PSR_PLAN_WK:3,PSR_FORECAST_CUM:86,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:115,PSR_ACTUAL_WK:28,RAS_ROS:1},
    {CUTOFF:"2022-04-08",WK_NO:"W2022_14",WORK_TYPE_CODE:"CC",WORK_TYPE_COLOR:"#8CC168",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-04-08",WK_NO:"W2022_14",WORK_TYPE_CODE:"DJS",WORK_TYPE_COLOR:"#335AA1",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-04-08",WK_NO:"W2022_14",WORK_TYPE_CODE:"MO",WORK_TYPE_COLOR:"#698ED0",PSR_PLAN_CUM:11,PSR_PLAN_WK:0,PSR_FORECAST_CUM:12,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-04-08",WK_NO:"W2022_14",WORK_TYPE_CODE:"EW",WORK_TYPE_COLOR:"#FFCD33",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:27,PSR_FORECAST_WK:27,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-04-08",WK_NO:"W2022_14",WORK_TYPE_CODE:"MR",WORK_TYPE_COLOR:"#A5A5A5",PSR_PLAN_CUM:224,PSR_PLAN_WK:9,PSR_FORECAST_CUM:224,PSR_FORECAST_WK:9,PSR_ACTUAL_CUM:144,PSR_ACTUAL_WK:3,RAS_ROS:1},
    {CUTOFF:"2022-04-08",WK_NO:"W2022_14",WORK_TYPE_CODE:"FI",WORK_TYPE_COLOR:"#B7B7B7",PSR_PLAN_CUM:5,PSR_PLAN_WK:0,PSR_FORECAST_CUM:5,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-04-08",WK_NO:"W2022_14",WORK_TYPE_CODE:"PO",WORK_TYPE_COLOR:"#255E91",PSR_PLAN_CUM:56,PSR_PLAN_WK:2,PSR_FORECAST_CUM:53,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:84,PSR_ACTUAL_WK:32,RAS_ROS:1},
    {CUTOFF:"2022-04-08",WK_NO:"W2022_14",WORK_TYPE_CODE:"LOA",WORK_TYPE_COLOR:"#997300",PSR_PLAN_CUM:82,PSR_PLAN_WK:8,PSR_FORECAST_CUM:72,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:99,PSR_ACTUAL_WK:28,RAS_ROS:1},
    {CUTOFF:"2022-04-08",WK_NO:"W2022_14",WORK_TYPE_CODE:"PPA",WORK_TYPE_COLOR:"#ED7D31",PSR_PLAN_CUM:247,PSR_PLAN_WK:1,PSR_FORECAST_CUM:246,PSR_FORECAST_WK:2,PSR_ACTUAL_CUM:241,PSR_ACTUAL_WK:6,RAS_ROS:1},
    {CUTOFF:"2022-04-08",WK_NO:"W2022_14",WORK_TYPE_CODE:"MDI",WORK_TYPE_COLOR:"#43682B",PSR_PLAN_CUM:35,PSR_PLAN_WK:1,PSR_FORECAST_CUM:34,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:15,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-04-08",WK_NO:"W2022_14",WORK_TYPE_CODE:"RFQ",WORK_TYPE_COLOR:"#FFC000",PSR_PLAN_CUM:207,PSR_PLAN_WK:10,PSR_FORECAST_CUM:247,PSR_FORECAST_WK:27,PSR_ACTUAL_CUM:273,PSR_ACTUAL_WK:47,RAS_ROS:1},
    {CUTOFF:"2022-04-08",WK_NO:"W2022_14",WORK_TYPE_CODE:"MMR",WORK_TYPE_COLOR:"#F1975A",PSR_PLAN_CUM:9,PSR_PLAN_WK:0,PSR_FORECAST_CUM:9,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-04-08",WK_NO:"W2022_14",WORK_TYPE_CODE:"TBE",WORK_TYPE_COLOR:"#70AD47",PSR_PLAN_CUM:119,PSR_PLAN_WK:2,PSR_FORECAST_CUM:103,PSR_FORECAST_WK:2,PSR_ACTUAL_CUM:132,PSR_ACTUAL_WK:28,RAS_ROS:1},
    {CUTOFF:"2022-04-08",WK_NO:"W2022_14",WORK_TYPE_CODE:"PP",WORK_TYPE_COLOR:"#4472C4",PSR_PLAN_CUM:266,PSR_PLAN_WK:5,PSR_FORECAST_CUM:266,PSR_FORECAST_WK:5,PSR_ACTUAL_CUM:279,PSR_ACTUAL_WK:5,RAS_ROS:1},
    {CUTOFF:"2022-04-08",WK_NO:"W2022_14",WORK_TYPE_CODE:"SA",WORK_TYPE_COLOR:"#7CAFDD",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-04-15",WK_NO:"W2022_15",WORK_TYPE_CODE:"AR",WORK_TYPE_COLOR:"#9E480E",PSR_PLAN_CUM:99,PSR_PLAN_WK:0,PSR_FORECAST_CUM:79,PSR_FORECAST_WK:3,PSR_ACTUAL_CUM:110,PSR_ACTUAL_WK:5,RAS_ROS:1},
    {CUTOFF:"2022-04-15",WK_NO:"W2022_15",WORK_TYPE_CODE:"CBE",WORK_TYPE_COLOR:"#264478",PSR_PLAN_CUM:113,PSR_PLAN_WK:1,PSR_FORECAST_CUM:93,PSR_FORECAST_WK:7,PSR_ACTUAL_CUM:124,PSR_ACTUAL_WK:9,RAS_ROS:1},
    {CUTOFF:"2022-04-15",WK_NO:"W2022_15",WORK_TYPE_CODE:"ARA",WORK_TYPE_COLOR:"#636363",PSR_PLAN_CUM:102,PSR_PLAN_WK:3,PSR_FORECAST_CUM:106,PSR_FORECAST_WK:30,PSR_ACTUAL_CUM:44,PSR_ACTUAL_WK:2,RAS_ROS:1},
    {CUTOFF:"2022-04-15",WK_NO:"W2022_15",WORK_TYPE_CODE:"CC",WORK_TYPE_COLOR:"#8CC168",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-04-15",WK_NO:"W2022_15",WORK_TYPE_CODE:"BC",WORK_TYPE_COLOR:"#5B9BD5",PSR_PLAN_CUM:171,PSR_PLAN_WK:10,PSR_FORECAST_CUM:189,PSR_FORECAST_WK:15,PSR_ACTUAL_CUM:218,PSR_ACTUAL_WK:22,RAS_ROS:1},
    {CUTOFF:"2022-04-15",WK_NO:"W2022_15",WORK_TYPE_CODE:"DJS",WORK_TYPE_COLOR:"#335AA1",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-04-15",WK_NO:"W2022_15",WORK_TYPE_CODE:"EW",WORK_TYPE_COLOR:"#FFCD33",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:27,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-04-15",WK_NO:"W2022_15",WORK_TYPE_CODE:"FI",WORK_TYPE_COLOR:"#B7B7B7",PSR_PLAN_CUM:5,PSR_PLAN_WK:0,PSR_FORECAST_CUM:5,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-04-15",WK_NO:"W2022_15",WORK_TYPE_CODE:"LOA",WORK_TYPE_COLOR:"#997300",PSR_PLAN_CUM:97,PSR_PLAN_WK:15,PSR_FORECAST_CUM:103,PSR_FORECAST_WK:31,PSR_ACTUAL_CUM:105,PSR_ACTUAL_WK:6,RAS_ROS:1},
    {CUTOFF:"2022-04-15",WK_NO:"W2022_15",WORK_TYPE_CODE:"MDI",WORK_TYPE_COLOR:"#43682B",PSR_PLAN_CUM:38,PSR_PLAN_WK:3,PSR_FORECAST_CUM:36,PSR_FORECAST_WK:2,PSR_ACTUAL_CUM:18,PSR_ACTUAL_WK:3,RAS_ROS:1},
    {CUTOFF:"2022-04-15",WK_NO:"W2022_15",WORK_TYPE_CODE:"MMR",WORK_TYPE_COLOR:"#F1975A",PSR_PLAN_CUM:9,PSR_PLAN_WK:0,PSR_FORECAST_CUM:9,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-04-15",WK_NO:"W2022_15",WORK_TYPE_CODE:"MO",WORK_TYPE_COLOR:"#698ED0",PSR_PLAN_CUM:11,PSR_PLAN_WK:0,PSR_FORECAST_CUM:12,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-04-15",WK_NO:"W2022_15",WORK_TYPE_CODE:"MR",WORK_TYPE_COLOR:"#A5A5A5",PSR_PLAN_CUM:249,PSR_PLAN_WK:25,PSR_FORECAST_CUM:252,PSR_FORECAST_WK:28,PSR_ACTUAL_CUM:167,PSR_ACTUAL_WK:23,RAS_ROS:1},
    {CUTOFF:"2022-04-15",WK_NO:"W2022_15",WORK_TYPE_CODE:"PP",WORK_TYPE_COLOR:"#4472C4",PSR_PLAN_CUM:387,PSR_PLAN_WK:121,PSR_FORECAST_CUM:387,PSR_FORECAST_WK:121,PSR_ACTUAL_CUM:316,PSR_ACTUAL_WK:37,RAS_ROS:1},
    {CUTOFF:"2022-04-15",WK_NO:"W2022_15",WORK_TYPE_CODE:"PO",WORK_TYPE_COLOR:"#255E91",PSR_PLAN_CUM:64,PSR_PLAN_WK:8,PSR_FORECAST_CUM:55,PSR_FORECAST_WK:2,PSR_ACTUAL_CUM:91,PSR_ACTUAL_WK:7,RAS_ROS:1},
    {CUTOFF:"2022-04-15",WK_NO:"W2022_15",WORK_TYPE_CODE:"RFQ",WORK_TYPE_COLOR:"#FFC000",PSR_PLAN_CUM:216,PSR_PLAN_WK:9,PSR_FORECAST_CUM:260,PSR_FORECAST_WK:13,PSR_ACTUAL_CUM:283,PSR_ACTUAL_WK:10,RAS_ROS:1},
    {CUTOFF:"2022-04-15",WK_NO:"W2022_15",WORK_TYPE_CODE:"PPA",WORK_TYPE_COLOR:"#ED7D31",PSR_PLAN_CUM:265,PSR_PLAN_WK:18,PSR_FORECAST_CUM:264,PSR_FORECAST_WK:18,PSR_ACTUAL_CUM:254,PSR_ACTUAL_WK:13,RAS_ROS:1},
    {CUTOFF:"2022-04-15",WK_NO:"W2022_15",WORK_TYPE_CODE:"SA",WORK_TYPE_COLOR:"#7CAFDD",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-04-15",WK_NO:"W2022_15",WORK_TYPE_CODE:"TBE",WORK_TYPE_COLOR:"#70AD47",PSR_PLAN_CUM:119,PSR_PLAN_WK:0,PSR_FORECAST_CUM:110,PSR_FORECAST_WK:7,PSR_ACTUAL_CUM:133,PSR_ACTUAL_WK:1,RAS_ROS:1},
    {CUTOFF:"2022-04-22",WK_NO:"W2022_16",WORK_TYPE_CODE:"AR",WORK_TYPE_COLOR:"#9E480E",PSR_PLAN_CUM:106,PSR_PLAN_WK:7,PSR_FORECAST_CUM:80,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:110,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-04-22",WK_NO:"W2022_16",WORK_TYPE_CODE:"BC",WORK_TYPE_COLOR:"#5B9BD5",PSR_PLAN_CUM:172,PSR_PLAN_WK:1,PSR_FORECAST_CUM:190,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:232,PSR_ACTUAL_WK:14,RAS_ROS:1},
    {CUTOFF:"2022-04-22",WK_NO:"W2022_16",WORK_TYPE_CODE:"ARA",WORK_TYPE_COLOR:"#636363",PSR_PLAN_CUM:103,PSR_PLAN_WK:1,PSR_FORECAST_CUM:109,PSR_FORECAST_WK:3,PSR_ACTUAL_CUM:44,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-04-22",WK_NO:"W2022_16",WORK_TYPE_CODE:"CBE",WORK_TYPE_COLOR:"#264478",PSR_PLAN_CUM:118,PSR_PLAN_WK:5,PSR_FORECAST_CUM:100,PSR_FORECAST_WK:7,PSR_ACTUAL_CUM:131,PSR_ACTUAL_WK:7,RAS_ROS:1},
    {CUTOFF:"2022-04-22",WK_NO:"W2022_16",WORK_TYPE_CODE:"EW",WORK_TYPE_COLOR:"#FFCD33",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:27,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-04-22",WK_NO:"W2022_16",WORK_TYPE_CODE:"CC",WORK_TYPE_COLOR:"#8CC168",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-04-22",WK_NO:"W2022_16",WORK_TYPE_CODE:"MR",WORK_TYPE_COLOR:"#A5A5A5",PSR_PLAN_CUM:266,PSR_PLAN_WK:17,PSR_FORECAST_CUM:269,PSR_FORECAST_WK:17,PSR_ACTUAL_CUM:182,PSR_ACTUAL_WK:15,RAS_ROS:1},
    {CUTOFF:"2022-04-22",WK_NO:"W2022_16",WORK_TYPE_CODE:"DJS",WORK_TYPE_COLOR:"#335AA1",PSR_PLAN_CUM:1,PSR_PLAN_WK:1,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-04-22",WK_NO:"W2022_16",WORK_TYPE_CODE:"PO",WORK_TYPE_COLOR:"#255E91",PSR_PLAN_CUM:70,PSR_PLAN_WK:6,PSR_FORECAST_CUM:57,PSR_FORECAST_WK:2,PSR_ACTUAL_CUM:92,PSR_ACTUAL_WK:1,RAS_ROS:1},
    {CUTOFF:"2022-04-22",WK_NO:"W2022_16",WORK_TYPE_CODE:"FI",WORK_TYPE_COLOR:"#B7B7B7",PSR_PLAN_CUM:5,PSR_PLAN_WK:0,PSR_FORECAST_CUM:5,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-04-22",WK_NO:"W2022_16",WORK_TYPE_CODE:"PPA",WORK_TYPE_COLOR:"#ED7D31",PSR_PLAN_CUM:271,PSR_PLAN_WK:6,PSR_FORECAST_CUM:270,PSR_FORECAST_WK:6,PSR_ACTUAL_CUM:257,PSR_ACTUAL_WK:3,RAS_ROS:1},
    {CUTOFF:"2022-04-22",WK_NO:"W2022_16",WORK_TYPE_CODE:"LOA",WORK_TYPE_COLOR:"#997300",PSR_PLAN_CUM:97,PSR_PLAN_WK:0,PSR_FORECAST_CUM:106,PSR_FORECAST_WK:3,PSR_ACTUAL_CUM:107,PSR_ACTUAL_WK:2,RAS_ROS:1},
    {CUTOFF:"2022-04-22",WK_NO:"W2022_16",WORK_TYPE_CODE:"SA",WORK_TYPE_COLOR:"#7CAFDD",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-04-22",WK_NO:"W2022_16",WORK_TYPE_CODE:"MDI",WORK_TYPE_COLOR:"#43682B",PSR_PLAN_CUM:40,PSR_PLAN_WK:2,PSR_FORECAST_CUM:39,PSR_FORECAST_WK:3,PSR_ACTUAL_CUM:18,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-04-22",WK_NO:"W2022_16",WORK_TYPE_CODE:"MMR",WORK_TYPE_COLOR:"#F1975A",PSR_PLAN_CUM:9,PSR_PLAN_WK:0,PSR_FORECAST_CUM:9,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-04-22",WK_NO:"W2022_16",WORK_TYPE_CODE:"MO",WORK_TYPE_COLOR:"#698ED0",PSR_PLAN_CUM:11,PSR_PLAN_WK:0,PSR_FORECAST_CUM:12,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:1},
    {CUTOFF:"2022-04-22",WK_NO:"W2022_16",WORK_TYPE_CODE:"PP",WORK_TYPE_COLOR:"#4472C4",PSR_PLAN_CUM:388,PSR_PLAN_WK:1,PSR_FORECAST_CUM:388,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:383,PSR_ACTUAL_WK:67,RAS_ROS:1},
    {CUTOFF:"2022-04-22",WK_NO:"W2022_16",WORK_TYPE_CODE:"RFQ",WORK_TYPE_COLOR:"#FFC000",PSR_PLAN_CUM:238,PSR_PLAN_WK:22,PSR_FORECAST_CUM:290,PSR_FORECAST_WK:30,PSR_ACTUAL_CUM:313,PSR_ACTUAL_WK:30,RAS_ROS:1},
    {CUTOFF:"2022-04-22",WK_NO:"W2022_16",WORK_TYPE_CODE:"TBE",WORK_TYPE_COLOR:"#70AD47",PSR_PLAN_CUM:122,PSR_PLAN_WK:3,PSR_FORECAST_CUM:114,PSR_FORECAST_WK:4,PSR_ACTUAL_CUM:143,PSR_ACTUAL_WK:10,RAS_ROS:1},
    {CUTOFF:"2022-04-29",WK_NO:"W2022_17",WORK_TYPE_CODE:"DJS",WORK_TYPE_COLOR:"#335AA1",PSR_PLAN_CUM:1,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:2},
    {CUTOFF:"2022-04-29",WK_NO:"W2022_17",WORK_TYPE_CODE:"AR",WORK_TYPE_COLOR:"#9E480E",PSR_PLAN_CUM:111,PSR_PLAN_WK:5,PSR_FORECAST_CUM:115,PSR_FORECAST_WK:35,PSR_ACTUAL_CUM:128,PSR_ACTUAL_WK:18,RAS_ROS:2},
    {CUTOFF:"2022-04-29",WK_NO:"W2022_17",WORK_TYPE_CODE:"MDI",WORK_TYPE_COLOR:"#43682B",PSR_PLAN_CUM:41,PSR_PLAN_WK:1,PSR_FORECAST_CUM:45,PSR_FORECAST_WK:6,PSR_ACTUAL_CUM:20,PSR_ACTUAL_WK:2,RAS_ROS:2},
    {CUTOFF:"2022-04-29",WK_NO:"W2022_17",WORK_TYPE_CODE:"ARA",WORK_TYPE_COLOR:"#636363",PSR_PLAN_CUM:109,PSR_PLAN_WK:6,PSR_FORECAST_CUM:116,PSR_FORECAST_WK:7,PSR_ACTUAL_CUM:48,PSR_ACTUAL_WK:4,RAS_ROS:2},
    {CUTOFF:"2022-04-29",WK_NO:"W2022_17",WORK_TYPE_CODE:"PO",WORK_TYPE_COLOR:"#255E91",PSR_PLAN_CUM:74,PSR_PLAN_WK:4,PSR_FORECAST_CUM:60,PSR_FORECAST_WK:3,PSR_ACTUAL_CUM:99,PSR_ACTUAL_WK:7,RAS_ROS:2},
    {CUTOFF:"2022-04-29",WK_NO:"W2022_17",WORK_TYPE_CODE:"BC",WORK_TYPE_COLOR:"#5B9BD5",PSR_PLAN_CUM:176,PSR_PLAN_WK:4,PSR_FORECAST_CUM:201,PSR_FORECAST_WK:11,PSR_ACTUAL_CUM:259,PSR_ACTUAL_WK:27,RAS_ROS:2},
    {CUTOFF:"2022-04-29",WK_NO:"W2022_17",WORK_TYPE_CODE:"PP",WORK_TYPE_COLOR:"#4472C4",PSR_PLAN_CUM:414,PSR_PLAN_WK:26,PSR_FORECAST_CUM:414,PSR_FORECAST_WK:26,PSR_ACTUAL_CUM:406,PSR_ACTUAL_WK:23,RAS_ROS:2},
    {CUTOFF:"2022-04-29",WK_NO:"W2022_17",WORK_TYPE_CODE:"CBE",WORK_TYPE_COLOR:"#264478",PSR_PLAN_CUM:119,PSR_PLAN_WK:1,PSR_FORECAST_CUM:129,PSR_FORECAST_WK:29,PSR_ACTUAL_CUM:137,PSR_ACTUAL_WK:6,RAS_ROS:2},
    {CUTOFF:"2022-04-29",WK_NO:"W2022_17",WORK_TYPE_CODE:"RFQ",WORK_TYPE_COLOR:"#FFC000",PSR_PLAN_CUM:260,PSR_PLAN_WK:22,PSR_FORECAST_CUM:301,PSR_FORECAST_WK:11,PSR_ACTUAL_CUM:369,PSR_ACTUAL_WK:56,RAS_ROS:2},
    {CUTOFF:"2022-04-29",WK_NO:"W2022_17",WORK_TYPE_CODE:"CC",WORK_TYPE_COLOR:"#8CC168",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:2},
    {CUTOFF:"2022-04-29",WK_NO:"W2022_17",WORK_TYPE_CODE:"SA",WORK_TYPE_COLOR:"#7CAFDD",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:2},
    {CUTOFF:"2022-04-29",WK_NO:"W2022_17",WORK_TYPE_CODE:"EW",WORK_TYPE_COLOR:"#FFCD33",PSR_PLAN_CUM:5,PSR_PLAN_WK:5,PSR_FORECAST_CUM:33,PSR_FORECAST_WK:6,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:2},
    {CUTOFF:"2022-04-29",WK_NO:"W2022_17",WORK_TYPE_CODE:"FI",WORK_TYPE_COLOR:"#B7B7B7",PSR_PLAN_CUM:5,PSR_PLAN_WK:0,PSR_FORECAST_CUM:5,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:2},
    {CUTOFF:"2022-04-29",WK_NO:"W2022_17",WORK_TYPE_CODE:"LOA",WORK_TYPE_COLOR:"#997300",PSR_PLAN_CUM:106,PSR_PLAN_WK:9,PSR_FORECAST_CUM:111,PSR_FORECAST_WK:5,PSR_ACTUAL_CUM:120,PSR_ACTUAL_WK:13,RAS_ROS:2},
    {CUTOFF:"2022-04-29",WK_NO:"W2022_17",WORK_TYPE_CODE:"MMR",WORK_TYPE_COLOR:"#F1975A",PSR_PLAN_CUM:9,PSR_PLAN_WK:0,PSR_FORECAST_CUM:9,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:2},
    {CUTOFF:"2022-04-29",WK_NO:"W2022_17",WORK_TYPE_CODE:"MO",WORK_TYPE_COLOR:"#698ED0",PSR_PLAN_CUM:11,PSR_PLAN_WK:0,PSR_FORECAST_CUM:12,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:2},
    {CUTOFF:"2022-04-29",WK_NO:"W2022_17",WORK_TYPE_CODE:"MR",WORK_TYPE_COLOR:"#A5A5A5",PSR_PLAN_CUM:323,PSR_PLAN_WK:57,PSR_FORECAST_CUM:329,PSR_FORECAST_WK:60,PSR_ACTUAL_CUM:199,PSR_ACTUAL_WK:17,RAS_ROS:2},
    {CUTOFF:"2022-04-29",WK_NO:"W2022_17",WORK_TYPE_CODE:"PPA",WORK_TYPE_COLOR:"#ED7D31",PSR_PLAN_CUM:388,PSR_PLAN_WK:117,PSR_FORECAST_CUM:304,PSR_FORECAST_WK:34,PSR_ACTUAL_CUM:301,PSR_ACTUAL_WK:44,RAS_ROS:2},
    {CUTOFF:"2022-04-29",WK_NO:"W2022_17",WORK_TYPE_CODE:"TBE",WORK_TYPE_COLOR:"#70AD47",PSR_PLAN_CUM:130,PSR_PLAN_WK:8,PSR_FORECAST_CUM:123,PSR_FORECAST_WK:9,PSR_ACTUAL_CUM:154,PSR_ACTUAL_WK:11,RAS_ROS:2},
    {CUTOFF:"2022-05-06",WK_NO:"W2022_18",WORK_TYPE_CODE:"AR",WORK_TYPE_COLOR:"#9E480E",PSR_PLAN_CUM:114,PSR_PLAN_WK:3,PSR_FORECAST_CUM:121,PSR_FORECAST_WK:6,PSR_ACTUAL_CUM:132,PSR_ACTUAL_WK:4,RAS_ROS:2},
    {CUTOFF:"2022-05-06",WK_NO:"W2022_18",WORK_TYPE_CODE:"ARA",WORK_TYPE_COLOR:"#636363",PSR_PLAN_CUM:112,PSR_PLAN_WK:3,PSR_FORECAST_CUM:127,PSR_FORECAST_WK:11,PSR_ACTUAL_CUM:49,PSR_ACTUAL_WK:1,RAS_ROS:2},
    {CUTOFF:"2022-05-06",WK_NO:"W2022_18",WORK_TYPE_CODE:"BC",WORK_TYPE_COLOR:"#5B9BD5",PSR_PLAN_CUM:203,PSR_PLAN_WK:27,PSR_FORECAST_CUM:244,PSR_FORECAST_WK:43,PSR_ACTUAL_CUM:284,PSR_ACTUAL_WK:25,RAS_ROS:2},
    {CUTOFF:"2022-05-06",WK_NO:"W2022_18",WORK_TYPE_CODE:"CBE",WORK_TYPE_COLOR:"#264478",PSR_PLAN_CUM:119,PSR_PLAN_WK:0,PSR_FORECAST_CUM:131,PSR_FORECAST_WK:2,PSR_ACTUAL_CUM:138,PSR_ACTUAL_WK:1,RAS_ROS:2},
    {CUTOFF:"2022-05-06",WK_NO:"W2022_18",WORK_TYPE_CODE:"EW",WORK_TYPE_COLOR:"#FFCD33",PSR_PLAN_CUM:5,PSR_PLAN_WK:0,PSR_FORECAST_CUM:34,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:2},
    {CUTOFF:"2022-05-06",WK_NO:"W2022_18",WORK_TYPE_CODE:"CC",WORK_TYPE_COLOR:"#8CC168",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:2},
    {CUTOFF:"2022-05-06",WK_NO:"W2022_18",WORK_TYPE_CODE:"FI",WORK_TYPE_COLOR:"#B7B7B7",PSR_PLAN_CUM:5,PSR_PLAN_WK:0,PSR_FORECAST_CUM:5,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:2},
    {CUTOFF:"2022-05-06",WK_NO:"W2022_18",WORK_TYPE_CODE:"DJS",WORK_TYPE_COLOR:"#335AA1",PSR_PLAN_CUM:12,PSR_PLAN_WK:11,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:2},
    {CUTOFF:"2022-05-06",WK_NO:"W2022_18",WORK_TYPE_CODE:"LOA",WORK_TYPE_COLOR:"#997300",PSR_PLAN_CUM:128,PSR_PLAN_WK:22,PSR_FORECAST_CUM:132,PSR_FORECAST_WK:21,PSR_ACTUAL_CUM:140,PSR_ACTUAL_WK:20,RAS_ROS:2},
    {CUTOFF:"2022-05-06",WK_NO:"W2022_18",WORK_TYPE_CODE:"MDI",WORK_TYPE_COLOR:"#43682B",PSR_PLAN_CUM:46,PSR_PLAN_WK:5,PSR_FORECAST_CUM:48,PSR_FORECAST_WK:3,PSR_ACTUAL_CUM:20,PSR_ACTUAL_WK:0,RAS_ROS:2},
    {CUTOFF:"2022-05-06",WK_NO:"W2022_18",WORK_TYPE_CODE:"MMR",WORK_TYPE_COLOR:"#F1975A",PSR_PLAN_CUM:9,PSR_PLAN_WK:0,PSR_FORECAST_CUM:9,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:2},
    {CUTOFF:"2022-05-06",WK_NO:"W2022_18",WORK_TYPE_CODE:"PPA",WORK_TYPE_COLOR:"#ED7D31",PSR_PLAN_CUM:392,PSR_PLAN_WK:4,PSR_FORECAST_CUM:374,PSR_FORECAST_WK:70,PSR_ACTUAL_CUM:301,PSR_ACTUAL_WK:0,RAS_ROS:2},
    {CUTOFF:"2022-05-06",WK_NO:"W2022_18",WORK_TYPE_CODE:"MO",WORK_TYPE_COLOR:"#698ED0",PSR_PLAN_CUM:11,PSR_PLAN_WK:0,PSR_FORECAST_CUM:12,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:2},
    {CUTOFF:"2022-05-06",WK_NO:"W2022_18",WORK_TYPE_CODE:"RFQ",WORK_TYPE_COLOR:"#FFC000",PSR_PLAN_CUM:368,PSR_PLAN_WK:108,PSR_FORECAST_CUM:349,PSR_FORECAST_WK:48,PSR_ACTUAL_CUM:379,PSR_ACTUAL_WK:10,RAS_ROS:2},
    {CUTOFF:"2022-05-06",WK_NO:"W2022_18",WORK_TYPE_CODE:"MR",WORK_TYPE_COLOR:"#A5A5A5",PSR_PLAN_CUM:366,PSR_PLAN_WK:43,PSR_FORECAST_CUM:377,PSR_FORECAST_WK:48,PSR_ACTUAL_CUM:217,PSR_ACTUAL_WK:18,RAS_ROS:2},
    {CUTOFF:"2022-05-06",WK_NO:"W2022_18",WORK_TYPE_CODE:"TBE",WORK_TYPE_COLOR:"#70AD47",PSR_PLAN_CUM:141,PSR_PLAN_WK:11,PSR_FORECAST_CUM:133,PSR_FORECAST_WK:10,PSR_ACTUAL_CUM:160,PSR_ACTUAL_WK:6,RAS_ROS:2},
    {CUTOFF:"2022-05-06",WK_NO:"W2022_18",WORK_TYPE_CODE:"PO",WORK_TYPE_COLOR:"#255E91",PSR_PLAN_CUM:89,PSR_PLAN_WK:15,PSR_FORECAST_CUM:62,PSR_FORECAST_WK:2,PSR_ACTUAL_CUM:100,PSR_ACTUAL_WK:1,RAS_ROS:2},
    {CUTOFF:"2022-05-06",WK_NO:"W2022_18",WORK_TYPE_CODE:"PP",WORK_TYPE_COLOR:"#4472C4",PSR_PLAN_CUM:422,PSR_PLAN_WK:8,PSR_FORECAST_CUM:422,PSR_FORECAST_WK:8,PSR_ACTUAL_CUM:410,PSR_ACTUAL_WK:4,RAS_ROS:2},
    {CUTOFF:"2022-05-06",WK_NO:"W2022_18",WORK_TYPE_CODE:"SA",WORK_TYPE_COLOR:"#7CAFDD",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:2},
    {CUTOFF:"2022-05-13",WK_NO:"W2022_19",WORK_TYPE_CODE:"AR",WORK_TYPE_COLOR:"#9E480E",PSR_PLAN_CUM:117,PSR_PLAN_WK:3,PSR_FORECAST_CUM:125,PSR_FORECAST_WK:4,PSR_ACTUAL_CUM:138,PSR_ACTUAL_WK:6,RAS_ROS:3},
    {CUTOFF:"2022-05-13",WK_NO:"W2022_19",WORK_TYPE_CODE:"ARA",WORK_TYPE_COLOR:"#636363",PSR_PLAN_CUM:114,PSR_PLAN_WK:2,PSR_FORECAST_CUM:133,PSR_FORECAST_WK:6,PSR_ACTUAL_CUM:54,PSR_ACTUAL_WK:5,RAS_ROS:3},
    {CUTOFF:"2022-05-13",WK_NO:"W2022_19",WORK_TYPE_CODE:"BC",WORK_TYPE_COLOR:"#5B9BD5",PSR_PLAN_CUM:206,PSR_PLAN_WK:3,PSR_FORECAST_CUM:269,PSR_FORECAST_WK:25,PSR_ACTUAL_CUM:308,PSR_ACTUAL_WK:24,RAS_ROS:3},
    {CUTOFF:"2022-05-13",WK_NO:"W2022_19",WORK_TYPE_CODE:"CC",WORK_TYPE_COLOR:"#8CC168",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-05-13",WK_NO:"W2022_19",WORK_TYPE_CODE:"CBE",WORK_TYPE_COLOR:"#264478",PSR_PLAN_CUM:122,PSR_PLAN_WK:3,PSR_FORECAST_CUM:140,PSR_FORECAST_WK:9,PSR_ACTUAL_CUM:152,PSR_ACTUAL_WK:14,RAS_ROS:3},
    {CUTOFF:"2022-05-13",WK_NO:"W2022_19",WORK_TYPE_CODE:"DJS",WORK_TYPE_COLOR:"#335AA1",PSR_PLAN_CUM:12,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-05-13",WK_NO:"W2022_19",WORK_TYPE_CODE:"EW",WORK_TYPE_COLOR:"#FFCD33",PSR_PLAN_CUM:5,PSR_PLAN_WK:0,PSR_FORECAST_CUM:39,PSR_FORECAST_WK:5,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-05-13",WK_NO:"W2022_19",WORK_TYPE_CODE:"MDI",WORK_TYPE_COLOR:"#43682B",PSR_PLAN_CUM:47,PSR_PLAN_WK:1,PSR_FORECAST_CUM:50,PSR_FORECAST_WK:2,PSR_ACTUAL_CUM:20,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-05-13",WK_NO:"W2022_19",WORK_TYPE_CODE:"FI",WORK_TYPE_COLOR:"#B7B7B7",PSR_PLAN_CUM:5,PSR_PLAN_WK:0,PSR_FORECAST_CUM:5,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-05-13",WK_NO:"W2022_19",WORK_TYPE_CODE:"MR",WORK_TYPE_COLOR:"#A5A5A5",PSR_PLAN_CUM:384,PSR_PLAN_WK:18,PSR_FORECAST_CUM:395,PSR_FORECAST_WK:18,PSR_ACTUAL_CUM:242,PSR_ACTUAL_WK:25,RAS_ROS:3},
    {CUTOFF:"2022-05-13",WK_NO:"W2022_19",WORK_TYPE_CODE:"LOA",WORK_TYPE_COLOR:"#997300",PSR_PLAN_CUM:128,PSR_PLAN_WK:0,PSR_FORECAST_CUM:142,PSR_FORECAST_WK:10,PSR_ACTUAL_CUM:148,PSR_ACTUAL_WK:8,RAS_ROS:3},
    {CUTOFF:"2022-05-13",WK_NO:"W2022_19",WORK_TYPE_CODE:"PPA",WORK_TYPE_COLOR:"#ED7D31",PSR_PLAN_CUM:415,PSR_PLAN_WK:23,PSR_FORECAST_CUM:415,PSR_FORECAST_WK:41,PSR_ACTUAL_CUM:352,PSR_ACTUAL_WK:51,RAS_ROS:3},
    {CUTOFF:"2022-05-13",WK_NO:"W2022_19",WORK_TYPE_CODE:"MMR",WORK_TYPE_COLOR:"#F1975A",PSR_PLAN_CUM:9,PSR_PLAN_WK:0,PSR_FORECAST_CUM:9,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-05-13",WK_NO:"W2022_19",WORK_TYPE_CODE:"RFQ",WORK_TYPE_COLOR:"#FFC000",PSR_PLAN_CUM:376,PSR_PLAN_WK:8,PSR_FORECAST_CUM:372,PSR_FORECAST_WK:23,PSR_ACTUAL_CUM:409,PSR_ACTUAL_WK:30,RAS_ROS:3},
    {CUTOFF:"2022-05-13",WK_NO:"W2022_19",WORK_TYPE_CODE:"MO",WORK_TYPE_COLOR:"#698ED0",PSR_PLAN_CUM:11,PSR_PLAN_WK:0,PSR_FORECAST_CUM:12,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-05-13",WK_NO:"W2022_19",WORK_TYPE_CODE:"TBE",WORK_TYPE_COLOR:"#70AD47",PSR_PLAN_CUM:153,PSR_PLAN_WK:12,PSR_FORECAST_CUM:137,PSR_FORECAST_WK:4,PSR_ACTUAL_CUM:183,PSR_ACTUAL_WK:23,RAS_ROS:3},
    {CUTOFF:"2022-05-13",WK_NO:"W2022_19",WORK_TYPE_CODE:"PO",WORK_TYPE_COLOR:"#255E91",PSR_PLAN_CUM:98,PSR_PLAN_WK:9,PSR_FORECAST_CUM:68,PSR_FORECAST_WK:6,PSR_ACTUAL_CUM:102,PSR_ACTUAL_WK:2,RAS_ROS:3},
    {CUTOFF:"2022-05-13",WK_NO:"W2022_19",WORK_TYPE_CODE:"PP",WORK_TYPE_COLOR:"#4472C4",PSR_PLAN_CUM:598,PSR_PLAN_WK:176,PSR_FORECAST_CUM:598,PSR_FORECAST_WK:176,PSR_ACTUAL_CUM:420,PSR_ACTUAL_WK:10,RAS_ROS:3},
    {CUTOFF:"2022-05-13",WK_NO:"W2022_19",WORK_TYPE_CODE:"SA",WORK_TYPE_COLOR:"#7CAFDD",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-05-20",WK_NO:"W2022_20",WORK_TYPE_CODE:"ARA",WORK_TYPE_COLOR:"#636363",PSR_PLAN_CUM:118,PSR_PLAN_WK:4,PSR_FORECAST_CUM:138,PSR_FORECAST_WK:5,PSR_ACTUAL_CUM:54,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-05-20",WK_NO:"W2022_20",WORK_TYPE_CODE:"AR",WORK_TYPE_COLOR:"#9E480E",PSR_PLAN_CUM:118,PSR_PLAN_WK:1,PSR_FORECAST_CUM:131,PSR_FORECAST_WK:6,PSR_ACTUAL_CUM:140,PSR_ACTUAL_WK:2,RAS_ROS:3},
    {CUTOFF:"2022-05-20",WK_NO:"W2022_20",WORK_TYPE_CODE:"CC",WORK_TYPE_COLOR:"#8CC168",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-05-20",WK_NO:"W2022_20",WORK_TYPE_CODE:"BC",WORK_TYPE_COLOR:"#5B9BD5",PSR_PLAN_CUM:213,PSR_PLAN_WK:7,PSR_FORECAST_CUM:288,PSR_FORECAST_WK:19,PSR_ACTUAL_CUM:319,PSR_ACTUAL_WK:11,RAS_ROS:3},
    {CUTOFF:"2022-05-20",WK_NO:"W2022_20",WORK_TYPE_CODE:"DJS",WORK_TYPE_COLOR:"#335AA1",PSR_PLAN_CUM:35,PSR_PLAN_WK:23,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:29,PSR_ACTUAL_WK:29,RAS_ROS:3},
    {CUTOFF:"2022-05-20",WK_NO:"W2022_20",WORK_TYPE_CODE:"CBE",WORK_TYPE_COLOR:"#264478",PSR_PLAN_CUM:130,PSR_PLAN_WK:8,PSR_FORECAST_CUM:151,PSR_FORECAST_WK:11,PSR_ACTUAL_CUM:156,PSR_ACTUAL_WK:4,RAS_ROS:3},
    {CUTOFF:"2022-05-20",WK_NO:"W2022_20",WORK_TYPE_CODE:"MDI",WORK_TYPE_COLOR:"#43682B",PSR_PLAN_CUM:49,PSR_PLAN_WK:2,PSR_FORECAST_CUM:50,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:20,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-05-20",WK_NO:"W2022_20",WORK_TYPE_CODE:"EW",WORK_TYPE_COLOR:"#FFCD33",PSR_PLAN_CUM:5,PSR_PLAN_WK:0,PSR_FORECAST_CUM:39,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-05-20",WK_NO:"W2022_20",WORK_TYPE_CODE:"MMR",WORK_TYPE_COLOR:"#F1975A",PSR_PLAN_CUM:9,PSR_PLAN_WK:0,PSR_FORECAST_CUM:9,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-05-20",WK_NO:"W2022_20",WORK_TYPE_CODE:"FI",WORK_TYPE_COLOR:"#B7B7B7",PSR_PLAN_CUM:5,PSR_PLAN_WK:0,PSR_FORECAST_CUM:5,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-05-20",WK_NO:"W2022_20",WORK_TYPE_CODE:"MR",WORK_TYPE_COLOR:"#A5A5A5",PSR_PLAN_CUM:1024,PSR_PLAN_WK:640,PSR_FORECAST_CUM:1037,PSR_FORECAST_WK:642,PSR_ACTUAL_CUM:255,PSR_ACTUAL_WK:13,RAS_ROS:3},
    {CUTOFF:"2022-05-20",WK_NO:"W2022_20",WORK_TYPE_CODE:"LOA",WORK_TYPE_COLOR:"#997300",PSR_PLAN_CUM:132,PSR_PLAN_WK:4,PSR_FORECAST_CUM:153,PSR_FORECAST_WK:11,PSR_ACTUAL_CUM:155,PSR_ACTUAL_WK:7,RAS_ROS:3},
    {CUTOFF:"2022-05-20",WK_NO:"W2022_20",WORK_TYPE_CODE:"PPA",WORK_TYPE_COLOR:"#ED7D31",PSR_PLAN_CUM:598,PSR_PLAN_WK:183,PSR_FORECAST_CUM:597,PSR_FORECAST_WK:182,PSR_ACTUAL_CUM:353,PSR_ACTUAL_WK:1,RAS_ROS:3},
    {CUTOFF:"2022-05-20",WK_NO:"W2022_20",WORK_TYPE_CODE:"MO",WORK_TYPE_COLOR:"#698ED0",PSR_PLAN_CUM:12,PSR_PLAN_WK:1,PSR_FORECAST_CUM:13,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-05-20",WK_NO:"W2022_20",WORK_TYPE_CODE:"RFQ",WORK_TYPE_COLOR:"#FFC000",PSR_PLAN_CUM:381,PSR_PLAN_WK:5,PSR_FORECAST_CUM:395,PSR_FORECAST_WK:23,PSR_ACTUAL_CUM:425,PSR_ACTUAL_WK:16,RAS_ROS:3},
    {CUTOFF:"2022-05-20",WK_NO:"W2022_20",WORK_TYPE_CODE:"PO",WORK_TYPE_COLOR:"#255E91",PSR_PLAN_CUM:106,PSR_PLAN_WK:8,PSR_FORECAST_CUM:68,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:103,PSR_ACTUAL_WK:1,RAS_ROS:3},
    {CUTOFF:"2022-05-20",WK_NO:"W2022_20",WORK_TYPE_CODE:"TBE",WORK_TYPE_COLOR:"#70AD47",PSR_PLAN_CUM:165,PSR_PLAN_WK:12,PSR_FORECAST_CUM:161,PSR_FORECAST_WK:24,PSR_ACTUAL_CUM:189,PSR_ACTUAL_WK:6,RAS_ROS:3},
    {CUTOFF:"2022-05-20",WK_NO:"W2022_20",WORK_TYPE_CODE:"PP",WORK_TYPE_COLOR:"#4472C4",PSR_PLAN_CUM:608,PSR_PLAN_WK:10,PSR_FORECAST_CUM:601,PSR_FORECAST_WK:3,PSR_ACTUAL_CUM:421,PSR_ACTUAL_WK:1,RAS_ROS:3},
    {CUTOFF:"2022-05-20",WK_NO:"W2022_20",WORK_TYPE_CODE:"SA",WORK_TYPE_COLOR:"#7CAFDD",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-05-27",WK_NO:"W2022_21",WORK_TYPE_CODE:"ARA",WORK_TYPE_COLOR:"#636363",PSR_PLAN_CUM:118,PSR_PLAN_WK:0,PSR_FORECAST_CUM:139,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:54,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-05-27",WK_NO:"W2022_21",WORK_TYPE_CODE:"AR",WORK_TYPE_COLOR:"#9E480E",PSR_PLAN_CUM:118,PSR_PLAN_WK:0,PSR_FORECAST_CUM:136,PSR_FORECAST_WK:5,PSR_ACTUAL_CUM:140,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-05-27",WK_NO:"W2022_21",WORK_TYPE_CODE:"BC",WORK_TYPE_COLOR:"#5B9BD5",PSR_PLAN_CUM:253,PSR_PLAN_WK:40,PSR_FORECAST_CUM:315,PSR_FORECAST_WK:27,PSR_ACTUAL_CUM:336,PSR_ACTUAL_WK:17,RAS_ROS:3},
    {CUTOFF:"2022-05-27",WK_NO:"W2022_21",WORK_TYPE_CODE:"CC",WORK_TYPE_COLOR:"#8CC168",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-05-27",WK_NO:"W2022_21",WORK_TYPE_CODE:"CBE",WORK_TYPE_COLOR:"#264478",PSR_PLAN_CUM:141,PSR_PLAN_WK:11,PSR_FORECAST_CUM:155,PSR_FORECAST_WK:4,PSR_ACTUAL_CUM:162,PSR_ACTUAL_WK:6,RAS_ROS:3},
    {CUTOFF:"2022-05-27",WK_NO:"W2022_21",WORK_TYPE_CODE:"EW",WORK_TYPE_COLOR:"#FFCD33",PSR_PLAN_CUM:5,PSR_PLAN_WK:0,PSR_FORECAST_CUM:39,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-05-27",WK_NO:"W2022_21",WORK_TYPE_CODE:"DJS",WORK_TYPE_COLOR:"#335AA1",PSR_PLAN_CUM:35,PSR_PLAN_WK:0,PSR_FORECAST_CUM:29,PSR_FORECAST_WK:29,PSR_ACTUAL_CUM:29,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-05-27",WK_NO:"W2022_21",WORK_TYPE_CODE:"FI",WORK_TYPE_COLOR:"#B7B7B7",PSR_PLAN_CUM:5,PSR_PLAN_WK:0,PSR_FORECAST_CUM:5,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-05-27",WK_NO:"W2022_21",WORK_TYPE_CODE:"LOA",WORK_TYPE_COLOR:"#997300",PSR_PLAN_CUM:133,PSR_PLAN_WK:1,PSR_FORECAST_CUM:155,PSR_FORECAST_WK:2,PSR_ACTUAL_CUM:156,PSR_ACTUAL_WK:1,RAS_ROS:3},
    {CUTOFF:"2022-05-27",WK_NO:"W2022_21",WORK_TYPE_CODE:"MDI",WORK_TYPE_COLOR:"#43682B",PSR_PLAN_CUM:63,PSR_PLAN_WK:14,PSR_FORECAST_CUM:56,PSR_FORECAST_WK:6,PSR_ACTUAL_CUM:20,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-05-27",WK_NO:"W2022_21",WORK_TYPE_CODE:"MO",WORK_TYPE_COLOR:"#698ED0",PSR_PLAN_CUM:12,PSR_PLAN_WK:0,PSR_FORECAST_CUM:13,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-05-27",WK_NO:"W2022_21",WORK_TYPE_CODE:"MMR",WORK_TYPE_COLOR:"#F1975A",PSR_PLAN_CUM:9,PSR_PLAN_WK:0,PSR_FORECAST_CUM:9,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-05-27",WK_NO:"W2022_21",WORK_TYPE_CODE:"PPA",WORK_TYPE_COLOR:"#ED7D31",PSR_PLAN_CUM:601,PSR_PLAN_WK:3,PSR_FORECAST_CUM:600,PSR_FORECAST_WK:3,PSR_ACTUAL_CUM:356,PSR_ACTUAL_WK:3,RAS_ROS:3},
    {CUTOFF:"2022-05-27",WK_NO:"W2022_21",WORK_TYPE_CODE:"MR",WORK_TYPE_COLOR:"#A5A5A5",PSR_PLAN_CUM:1027,PSR_PLAN_WK:3,PSR_FORECAST_CUM:1040,PSR_FORECAST_WK:3,PSR_ACTUAL_CUM:281,PSR_ACTUAL_WK:26,RAS_ROS:3},
    {CUTOFF:"2022-05-27",WK_NO:"W2022_21",WORK_TYPE_CODE:"RFQ",WORK_TYPE_COLOR:"#FFC000",PSR_PLAN_CUM:535,PSR_PLAN_WK:154,PSR_FORECAST_CUM:545,PSR_FORECAST_WK:150,PSR_ACTUAL_CUM:431,PSR_ACTUAL_WK:6,RAS_ROS:3},
    {CUTOFF:"2022-05-27",WK_NO:"W2022_21",WORK_TYPE_CODE:"PO",WORK_TYPE_COLOR:"#255E91",PSR_PLAN_CUM:117,PSR_PLAN_WK:11,PSR_FORECAST_CUM:101,PSR_FORECAST_WK:33,PSR_ACTUAL_CUM:107,PSR_ACTUAL_WK:4,RAS_ROS:3},
    {CUTOFF:"2022-05-27",WK_NO:"W2022_21",WORK_TYPE_CODE:"PP",WORK_TYPE_COLOR:"#4472C4",PSR_PLAN_CUM:621,PSR_PLAN_WK:13,PSR_FORECAST_CUM:614,PSR_FORECAST_WK:13,PSR_ACTUAL_CUM:433,PSR_ACTUAL_WK:12,RAS_ROS:3},
    {CUTOFF:"2022-05-27",WK_NO:"W2022_21",WORK_TYPE_CODE:"SA",WORK_TYPE_COLOR:"#7CAFDD",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-05-27",WK_NO:"W2022_21",WORK_TYPE_CODE:"TBE",WORK_TYPE_COLOR:"#70AD47",PSR_PLAN_CUM:167,PSR_PLAN_WK:2,PSR_FORECAST_CUM:211,PSR_FORECAST_WK:50,PSR_ACTUAL_CUM:199,PSR_ACTUAL_WK:10,RAS_ROS:3},
    {CUTOFF:"2022-06-03",WK_NO:"W2022_22",WORK_TYPE_CODE:"ARA",WORK_TYPE_COLOR:"#636363",PSR_PLAN_CUM:119,PSR_PLAN_WK:1,PSR_FORECAST_CUM:143,PSR_FORECAST_WK:4,PSR_ACTUAL_CUM:54,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-06-03",WK_NO:"W2022_22",WORK_TYPE_CODE:"AR",WORK_TYPE_COLOR:"#9E480E",PSR_PLAN_CUM:121,PSR_PLAN_WK:3,PSR_FORECAST_CUM:148,PSR_FORECAST_WK:12,PSR_ACTUAL_CUM:152,PSR_ACTUAL_WK:12,RAS_ROS:3},
    {CUTOFF:"2022-06-03",WK_NO:"W2022_22",WORK_TYPE_CODE:"CC",WORK_TYPE_COLOR:"#8CC168",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-06-03",WK_NO:"W2022_22",WORK_TYPE_CODE:"BC",WORK_TYPE_COLOR:"#5B9BD5",PSR_PLAN_CUM:300,PSR_PLAN_WK:47,PSR_FORECAST_CUM:339,PSR_FORECAST_WK:24,PSR_ACTUAL_CUM:345,PSR_ACTUAL_WK:9,RAS_ROS:3},
    {CUTOFF:"2022-06-03",WK_NO:"W2022_22",WORK_TYPE_CODE:"FI",WORK_TYPE_COLOR:"#B7B7B7",PSR_PLAN_CUM:5,PSR_PLAN_WK:0,PSR_FORECAST_CUM:5,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-06-03",WK_NO:"W2022_22",WORK_TYPE_CODE:"CBE",WORK_TYPE_COLOR:"#264478",PSR_PLAN_CUM:153,PSR_PLAN_WK:12,PSR_FORECAST_CUM:174,PSR_FORECAST_WK:19,PSR_ACTUAL_CUM:169,PSR_ACTUAL_WK:7,RAS_ROS:3},
    {CUTOFF:"2022-06-03",WK_NO:"W2022_22",WORK_TYPE_CODE:"MDI",WORK_TYPE_COLOR:"#43682B",PSR_PLAN_CUM:70,PSR_PLAN_WK:7,PSR_FORECAST_CUM:57,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:20,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-06-03",WK_NO:"W2022_22",WORK_TYPE_CODE:"DJS",WORK_TYPE_COLOR:"#335AA1",PSR_PLAN_CUM:35,PSR_PLAN_WK:0,PSR_FORECAST_CUM:29,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:29,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-06-03",WK_NO:"W2022_22",WORK_TYPE_CODE:"MMR",WORK_TYPE_COLOR:"#F1975A",PSR_PLAN_CUM:10,PSR_PLAN_WK:1,PSR_FORECAST_CUM:10,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-06-03",WK_NO:"W2022_22",WORK_TYPE_CODE:"EW",WORK_TYPE_COLOR:"#FFCD33",PSR_PLAN_CUM:6,PSR_PLAN_WK:1,PSR_FORECAST_CUM:39,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-06-03",WK_NO:"W2022_22",WORK_TYPE_CODE:"MR",WORK_TYPE_COLOR:"#A5A5A5",PSR_PLAN_CUM:1105,PSR_PLAN_WK:78,PSR_FORECAST_CUM:1128,PSR_FORECAST_WK:88,PSR_ACTUAL_CUM:289,PSR_ACTUAL_WK:8,RAS_ROS:3},
    {CUTOFF:"2022-06-03",WK_NO:"W2022_22",WORK_TYPE_CODE:"LOA",WORK_TYPE_COLOR:"#997300",PSR_PLAN_CUM:140,PSR_PLAN_WK:7,PSR_FORECAST_CUM:156,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:164,PSR_ACTUAL_WK:8,RAS_ROS:3},
    {CUTOFF:"2022-06-03",WK_NO:"W2022_22",WORK_TYPE_CODE:"PO",WORK_TYPE_COLOR:"#255E91",PSR_PLAN_CUM:124,PSR_PLAN_WK:7,PSR_FORECAST_CUM:107,PSR_FORECAST_WK:6,PSR_ACTUAL_CUM:109,PSR_ACTUAL_WK:2,RAS_ROS:3},
    {CUTOFF:"2022-06-03",WK_NO:"W2022_22",WORK_TYPE_CODE:"MO",WORK_TYPE_COLOR:"#698ED0",PSR_PLAN_CUM:12,PSR_PLAN_WK:0,PSR_FORECAST_CUM:13,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-06-03",WK_NO:"W2022_22",WORK_TYPE_CODE:"PP",WORK_TYPE_COLOR:"#4472C4",PSR_PLAN_CUM:622,PSR_PLAN_WK:1,PSR_FORECAST_CUM:615,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:433,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-06-03",WK_NO:"W2022_22",WORK_TYPE_CODE:"PPA",WORK_TYPE_COLOR:"#ED7D31",PSR_PLAN_CUM:606,PSR_PLAN_WK:5,PSR_FORECAST_CUM:605,PSR_FORECAST_WK:5,PSR_ACTUAL_CUM:361,PSR_ACTUAL_WK:5,RAS_ROS:3},
    {CUTOFF:"2022-06-03",WK_NO:"W2022_22",WORK_TYPE_CODE:"RFQ",WORK_TYPE_COLOR:"#FFC000",PSR_PLAN_CUM:545,PSR_PLAN_WK:10,PSR_FORECAST_CUM:582,PSR_FORECAST_WK:37,PSR_ACTUAL_CUM:463,PSR_ACTUAL_WK:32,RAS_ROS:3},
    {CUTOFF:"2022-06-03",WK_NO:"W2022_22",WORK_TYPE_CODE:"SA",WORK_TYPE_COLOR:"#7CAFDD",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-06-03",WK_NO:"W2022_22",WORK_TYPE_CODE:"TBE",WORK_TYPE_COLOR:"#70AD47",PSR_PLAN_CUM:175,PSR_PLAN_WK:8,PSR_FORECAST_CUM:220,PSR_FORECAST_WK:9,PSR_ACTUAL_CUM:216,PSR_ACTUAL_WK:17,RAS_ROS:3},
    {CUTOFF:"2022-06-10",WK_NO:"W2022_23",WORK_TYPE_CODE:"AR",WORK_TYPE_COLOR:"#9E480E",PSR_PLAN_CUM:129,PSR_PLAN_WK:8,PSR_FORECAST_CUM:152,PSR_FORECAST_WK:4,PSR_ACTUAL_CUM:154,PSR_ACTUAL_WK:2,RAS_ROS:3},
    {CUTOFF:"2022-06-10",WK_NO:"W2022_23",WORK_TYPE_CODE:"ARA",WORK_TYPE_COLOR:"#636363",PSR_PLAN_CUM:123,PSR_PLAN_WK:4,PSR_FORECAST_CUM:151,PSR_FORECAST_WK:8,PSR_ACTUAL_CUM:60,PSR_ACTUAL_WK:6,RAS_ROS:3},
    {CUTOFF:"2022-06-10",WK_NO:"W2022_23",WORK_TYPE_CODE:"CC",WORK_TYPE_COLOR:"#8CC168",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-06-10",WK_NO:"W2022_23",WORK_TYPE_CODE:"BC",WORK_TYPE_COLOR:"#5B9BD5",PSR_PLAN_CUM:355,PSR_PLAN_WK:55,PSR_FORECAST_CUM:358,PSR_FORECAST_WK:19,PSR_ACTUAL_CUM:369,PSR_ACTUAL_WK:24,RAS_ROS:3},
    {CUTOFF:"2022-06-10",WK_NO:"W2022_23",WORK_TYPE_CODE:"DJS",WORK_TYPE_COLOR:"#335AA1",PSR_PLAN_CUM:38,PSR_PLAN_WK:3,PSR_FORECAST_CUM:29,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:29,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-06-10",WK_NO:"W2022_23",WORK_TYPE_CODE:"CBE",WORK_TYPE_COLOR:"#264478",PSR_PLAN_CUM:165,PSR_PLAN_WK:12,PSR_FORECAST_CUM:188,PSR_FORECAST_WK:14,PSR_ACTUAL_CUM:184,PSR_ACTUAL_WK:15,RAS_ROS:3},
    {CUTOFF:"2022-06-10",WK_NO:"W2022_23",WORK_TYPE_CODE:"EW",WORK_TYPE_COLOR:"#FFCD33",PSR_PLAN_CUM:6,PSR_PLAN_WK:0,PSR_FORECAST_CUM:39,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-06-10",WK_NO:"W2022_23",WORK_TYPE_CODE:"LOA",WORK_TYPE_COLOR:"#997300",PSR_PLAN_CUM:142,PSR_PLAN_WK:2,PSR_FORECAST_CUM:161,PSR_FORECAST_WK:5,PSR_ACTUAL_CUM:169,PSR_ACTUAL_WK:5,RAS_ROS:3},
    {CUTOFF:"2022-06-10",WK_NO:"W2022_23",WORK_TYPE_CODE:"FI",WORK_TYPE_COLOR:"#B7B7B7",PSR_PLAN_CUM:5,PSR_PLAN_WK:0,PSR_FORECAST_CUM:5,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-06-10",WK_NO:"W2022_23",WORK_TYPE_CODE:"MDI",WORK_TYPE_COLOR:"#43682B",PSR_PLAN_CUM:76,PSR_PLAN_WK:6,PSR_FORECAST_CUM:60,PSR_FORECAST_WK:3,PSR_ACTUAL_CUM:21,PSR_ACTUAL_WK:1,RAS_ROS:3},
    {CUTOFF:"2022-06-10",WK_NO:"W2022_23",WORK_TYPE_CODE:"MMR",WORK_TYPE_COLOR:"#F1975A",PSR_PLAN_CUM:10,PSR_PLAN_WK:0,PSR_FORECAST_CUM:10,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-06-10",WK_NO:"W2022_23",WORK_TYPE_CODE:"MR",WORK_TYPE_COLOR:"#A5A5A5",PSR_PLAN_CUM:1105,PSR_PLAN_WK:0,PSR_FORECAST_CUM:1128,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:317,PSR_ACTUAL_WK:28,RAS_ROS:3},
    {CUTOFF:"2022-06-10",WK_NO:"W2022_23",WORK_TYPE_CODE:"MO",WORK_TYPE_COLOR:"#698ED0",PSR_PLAN_CUM:12,PSR_PLAN_WK:0,PSR_FORECAST_CUM:13,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-06-10",WK_NO:"W2022_23",WORK_TYPE_CODE:"PO",WORK_TYPE_COLOR:"#255E91",PSR_PLAN_CUM:130,PSR_PLAN_WK:6,PSR_FORECAST_CUM:118,PSR_FORECAST_WK:11,PSR_ACTUAL_CUM:118,PSR_ACTUAL_WK:9,RAS_ROS:3},
    {CUTOFF:"2022-06-10",WK_NO:"W2022_23",WORK_TYPE_CODE:"PP",WORK_TYPE_COLOR:"#4472C4",PSR_PLAN_CUM:645,PSR_PLAN_WK:23,PSR_FORECAST_CUM:619,PSR_FORECAST_WK:4,PSR_ACTUAL_CUM:482,PSR_ACTUAL_WK:49,RAS_ROS:3},
    {CUTOFF:"2022-06-10",WK_NO:"W2022_23",WORK_TYPE_CODE:"PPA",WORK_TYPE_COLOR:"#ED7D31",PSR_PLAN_CUM:615,PSR_PLAN_WK:9,PSR_FORECAST_CUM:614,PSR_FORECAST_WK:9,PSR_ACTUAL_CUM:368,PSR_ACTUAL_WK:7,RAS_ROS:3},
    {CUTOFF:"2022-06-10",WK_NO:"W2022_23",WORK_TYPE_CODE:"SA",WORK_TYPE_COLOR:"#7CAFDD",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-06-10",WK_NO:"W2022_23",WORK_TYPE_CODE:"RFQ",WORK_TYPE_COLOR:"#FFC000",PSR_PLAN_CUM:597,PSR_PLAN_WK:52,PSR_FORECAST_CUM:592,PSR_FORECAST_WK:10,PSR_ACTUAL_CUM:499,PSR_ACTUAL_WK:36,RAS_ROS:3},
    {CUTOFF:"2022-06-10",WK_NO:"W2022_23",WORK_TYPE_CODE:"TBE",WORK_TYPE_COLOR:"#70AD47",PSR_PLAN_CUM:195,PSR_PLAN_WK:20,PSR_FORECAST_CUM:240,PSR_FORECAST_WK:20,PSR_ACTUAL_CUM:240,PSR_ACTUAL_WK:24,RAS_ROS:3},
    {CUTOFF:"2022-06-17",WK_NO:"W2022_24",WORK_TYPE_CODE:"CC",WORK_TYPE_COLOR:"#8CC168",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-06-17",WK_NO:"W2022_24",WORK_TYPE_CODE:"AR",WORK_TYPE_COLOR:"#9E480E",PSR_PLAN_CUM:140,PSR_PLAN_WK:11,PSR_FORECAST_CUM:156,PSR_FORECAST_WK:4,PSR_ACTUAL_CUM:162,PSR_ACTUAL_WK:8,RAS_ROS:3},
    {CUTOFF:"2022-06-17",WK_NO:"W2022_24",WORK_TYPE_CODE:"DJS",WORK_TYPE_COLOR:"#335AA1",PSR_PLAN_CUM:38,PSR_PLAN_WK:0,PSR_FORECAST_CUM:29,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:29,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-06-17",WK_NO:"W2022_24",WORK_TYPE_CODE:"ARA",WORK_TYPE_COLOR:"#636363",PSR_PLAN_CUM:135,PSR_PLAN_WK:12,PSR_FORECAST_CUM:156,PSR_FORECAST_WK:5,PSR_ACTUAL_CUM:65,PSR_ACTUAL_WK:5,RAS_ROS:3},
    {CUTOFF:"2022-06-17",WK_NO:"W2022_24",WORK_TYPE_CODE:"MDI",WORK_TYPE_COLOR:"#43682B",PSR_PLAN_CUM:76,PSR_PLAN_WK:0,PSR_FORECAST_CUM:64,PSR_FORECAST_WK:4,PSR_ACTUAL_CUM:21,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-06-17",WK_NO:"W2022_24",WORK_TYPE_CODE:"BC",WORK_TYPE_COLOR:"#5B9BD5",PSR_PLAN_CUM:355,PSR_PLAN_WK:0,PSR_FORECAST_CUM:379,PSR_FORECAST_WK:21,PSR_ACTUAL_CUM:386,PSR_ACTUAL_WK:17,RAS_ROS:3},
    {CUTOFF:"2022-06-17",WK_NO:"W2022_24",WORK_TYPE_CODE:"RFQ",WORK_TYPE_COLOR:"#FFC000",PSR_PLAN_CUM:605,PSR_PLAN_WK:8,PSR_FORECAST_CUM:609,PSR_FORECAST_WK:17,PSR_ACTUAL_CUM:541,PSR_ACTUAL_WK:42,RAS_ROS:3},
    {CUTOFF:"2022-06-17",WK_NO:"W2022_24",WORK_TYPE_CODE:"CBE",WORK_TYPE_COLOR:"#264478",PSR_PLAN_CUM:167,PSR_PLAN_WK:2,PSR_FORECAST_CUM:209,PSR_FORECAST_WK:21,PSR_ACTUAL_CUM:212,PSR_ACTUAL_WK:28,RAS_ROS:3},
    {CUTOFF:"2022-06-17",WK_NO:"W2022_24",WORK_TYPE_CODE:"TBE",WORK_TYPE_COLOR:"#70AD47",PSR_PLAN_CUM:200,PSR_PLAN_WK:5,PSR_FORECAST_CUM:263,PSR_FORECAST_WK:23,PSR_ACTUAL_CUM:258,PSR_ACTUAL_WK:18,RAS_ROS:3},
    {CUTOFF:"2022-06-17",WK_NO:"W2022_24",WORK_TYPE_CODE:"EW",WORK_TYPE_COLOR:"#FFCD33",PSR_PLAN_CUM:8,PSR_PLAN_WK:2,PSR_FORECAST_CUM:43,PSR_FORECAST_WK:4,PSR_ACTUAL_CUM:4,PSR_ACTUAL_WK:4,RAS_ROS:3},
    {CUTOFF:"2022-06-17",WK_NO:"W2022_24",WORK_TYPE_CODE:"FI",WORK_TYPE_COLOR:"#B7B7B7",PSR_PLAN_CUM:5,PSR_PLAN_WK:0,PSR_FORECAST_CUM:5,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-06-17",WK_NO:"W2022_24",WORK_TYPE_CODE:"LOA",WORK_TYPE_COLOR:"#997300",PSR_PLAN_CUM:149,PSR_PLAN_WK:7,PSR_FORECAST_CUM:173,PSR_FORECAST_WK:12,PSR_ACTUAL_CUM:177,PSR_ACTUAL_WK:8,RAS_ROS:3},
    {CUTOFF:"2022-06-17",WK_NO:"W2022_24",WORK_TYPE_CODE:"MMR",WORK_TYPE_COLOR:"#F1975A",PSR_PLAN_CUM:10,PSR_PLAN_WK:0,PSR_FORECAST_CUM:10,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-06-17",WK_NO:"W2022_24",WORK_TYPE_CODE:"MO",WORK_TYPE_COLOR:"#698ED0",PSR_PLAN_CUM:13,PSR_PLAN_WK:1,PSR_FORECAST_CUM:15,PSR_FORECAST_WK:2,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-06-17",WK_NO:"W2022_24",WORK_TYPE_CODE:"MR",WORK_TYPE_COLOR:"#A5A5A5",PSR_PLAN_CUM:1105,PSR_PLAN_WK:0,PSR_FORECAST_CUM:1132,PSR_FORECAST_WK:4,PSR_ACTUAL_CUM:327,PSR_ACTUAL_WK:10,RAS_ROS:3},
    {CUTOFF:"2022-06-17",WK_NO:"W2022_24",WORK_TYPE_CODE:"PO",WORK_TYPE_COLOR:"#255E91",PSR_PLAN_CUM:139,PSR_PLAN_WK:9,PSR_FORECAST_CUM:137,PSR_FORECAST_WK:19,PSR_ACTUAL_CUM:131,PSR_ACTUAL_WK:13,RAS_ROS:3},
    {CUTOFF:"2022-06-17",WK_NO:"W2022_24",WORK_TYPE_CODE:"PP",WORK_TYPE_COLOR:"#4472C4",PSR_PLAN_CUM:695,PSR_PLAN_WK:50,PSR_FORECAST_CUM:669,PSR_FORECAST_WK:50,PSR_ACTUAL_CUM:520,PSR_ACTUAL_WK:38,RAS_ROS:3},
    {CUTOFF:"2022-06-17",WK_NO:"W2022_24",WORK_TYPE_CODE:"PPA",WORK_TYPE_COLOR:"#ED7D31",PSR_PLAN_CUM:619,PSR_PLAN_WK:4,PSR_FORECAST_CUM:618,PSR_FORECAST_WK:4,PSR_ACTUAL_CUM:386,PSR_ACTUAL_WK:18,RAS_ROS:3},
    {CUTOFF:"2022-06-17",WK_NO:"W2022_24",WORK_TYPE_CODE:"SA",WORK_TYPE_COLOR:"#7CAFDD",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-06-24",WK_NO:"W2022_25",WORK_TYPE_CODE:"ARA",WORK_TYPE_COLOR:"#636363",PSR_PLAN_CUM:148,PSR_PLAN_WK:13,PSR_FORECAST_CUM:165,PSR_FORECAST_WK:9,PSR_ACTUAL_CUM:65,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-06-24",WK_NO:"W2022_25",WORK_TYPE_CODE:"AR",WORK_TYPE_COLOR:"#9E480E",PSR_PLAN_CUM:155,PSR_PLAN_WK:15,PSR_FORECAST_CUM:170,PSR_FORECAST_WK:14,PSR_ACTUAL_CUM:183,PSR_ACTUAL_WK:21,RAS_ROS:3},
    {CUTOFF:"2022-06-24",WK_NO:"W2022_25",WORK_TYPE_CODE:"CC",WORK_TYPE_COLOR:"#8CC168",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-06-24",WK_NO:"W2022_25",WORK_TYPE_CODE:"BC",WORK_TYPE_COLOR:"#5B9BD5",PSR_PLAN_CUM:379,PSR_PLAN_WK:24,PSR_FORECAST_CUM:485,PSR_FORECAST_WK:106,PSR_ACTUAL_CUM:393,PSR_ACTUAL_WK:7,RAS_ROS:3},
    {CUTOFF:"2022-06-24",WK_NO:"W2022_25",WORK_TYPE_CODE:"DJS",WORK_TYPE_COLOR:"#335AA1",PSR_PLAN_CUM:39,PSR_PLAN_WK:1,PSR_FORECAST_CUM:33,PSR_FORECAST_WK:4,PSR_ACTUAL_CUM:31,PSR_ACTUAL_WK:2,RAS_ROS:3},
    {CUTOFF:"2022-06-24",WK_NO:"W2022_25",WORK_TYPE_CODE:"CBE",WORK_TYPE_COLOR:"#264478",PSR_PLAN_CUM:175,PSR_PLAN_WK:8,PSR_FORECAST_CUM:233,PSR_FORECAST_WK:24,PSR_ACTUAL_CUM:253,PSR_ACTUAL_WK:41,RAS_ROS:3},
    {CUTOFF:"2022-06-24",WK_NO:"W2022_25",WORK_TYPE_CODE:"MDI",WORK_TYPE_COLOR:"#43682B",PSR_PLAN_CUM:81,PSR_PLAN_WK:5,PSR_FORECAST_CUM:71,PSR_FORECAST_WK:7,PSR_ACTUAL_CUM:21,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-06-24",WK_NO:"W2022_25",WORK_TYPE_CODE:"EW",WORK_TYPE_COLOR:"#FFCD33",PSR_PLAN_CUM:8,PSR_PLAN_WK:0,PSR_FORECAST_CUM:44,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:7,PSR_ACTUAL_WK:3,RAS_ROS:3},
    {CUTOFF:"2022-06-24",WK_NO:"W2022_25",WORK_TYPE_CODE:"MR",WORK_TYPE_COLOR:"#A5A5A5",PSR_PLAN_CUM:1113,PSR_PLAN_WK:8,PSR_FORECAST_CUM:1140,PSR_FORECAST_WK:8,PSR_ACTUAL_CUM:334,PSR_ACTUAL_WK:7,RAS_ROS:3},
    {CUTOFF:"2022-06-24",WK_NO:"W2022_25",WORK_TYPE_CODE:"FI",WORK_TYPE_COLOR:"#B7B7B7",PSR_PLAN_CUM:5,PSR_PLAN_WK:0,PSR_FORECAST_CUM:5,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-06-24",WK_NO:"W2022_25",WORK_TYPE_CODE:"PPA",WORK_TYPE_COLOR:"#ED7D31",PSR_PLAN_CUM:619,PSR_PLAN_WK:0,PSR_FORECAST_CUM:618,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:386,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-06-24",WK_NO:"W2022_25",WORK_TYPE_CODE:"LOA",WORK_TYPE_COLOR:"#997300",PSR_PLAN_CUM:167,PSR_PLAN_WK:18,PSR_FORECAST_CUM:178,PSR_FORECAST_WK:5,PSR_ACTUAL_CUM:186,PSR_ACTUAL_WK:9,RAS_ROS:3},
    {CUTOFF:"2022-06-24",WK_NO:"W2022_25",WORK_TYPE_CODE:"RFQ",WORK_TYPE_COLOR:"#FFC000",PSR_PLAN_CUM:612,PSR_PLAN_WK:7,PSR_FORECAST_CUM:627,PSR_FORECAST_WK:18,PSR_ACTUAL_CUM:547,PSR_ACTUAL_WK:6,RAS_ROS:3},
    {CUTOFF:"2022-06-24",WK_NO:"W2022_25",WORK_TYPE_CODE:"MMR",WORK_TYPE_COLOR:"#F1975A",PSR_PLAN_CUM:10,PSR_PLAN_WK:0,PSR_FORECAST_CUM:10,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-06-24",WK_NO:"W2022_25",WORK_TYPE_CODE:"TBE",WORK_TYPE_COLOR:"#70AD47",PSR_PLAN_CUM:211,PSR_PLAN_WK:11,PSR_FORECAST_CUM:283,PSR_FORECAST_WK:20,PSR_ACTUAL_CUM:274,PSR_ACTUAL_WK:16,RAS_ROS:3},
    {CUTOFF:"2022-06-24",WK_NO:"W2022_25",WORK_TYPE_CODE:"MO",WORK_TYPE_COLOR:"#698ED0",PSR_PLAN_CUM:13,PSR_PLAN_WK:0,PSR_FORECAST_CUM:16,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-06-24",WK_NO:"W2022_25",WORK_TYPE_CODE:"PO",WORK_TYPE_COLOR:"#255E91",PSR_PLAN_CUM:143,PSR_PLAN_WK:4,PSR_FORECAST_CUM:150,PSR_FORECAST_WK:13,PSR_ACTUAL_CUM:141,PSR_ACTUAL_WK:10,RAS_ROS:3},
    {CUTOFF:"2022-06-24",WK_NO:"W2022_25",WORK_TYPE_CODE:"PP",WORK_TYPE_COLOR:"#4472C4",PSR_PLAN_CUM:730,PSR_PLAN_WK:35,PSR_FORECAST_CUM:704,PSR_FORECAST_WK:35,PSR_ACTUAL_CUM:541,PSR_ACTUAL_WK:21,RAS_ROS:3},
    {CUTOFF:"2022-06-24",WK_NO:"W2022_25",WORK_TYPE_CODE:"SA",WORK_TYPE_COLOR:"#7CAFDD",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:3},
    {CUTOFF:"2022-07-01",WK_NO:"W2022_26",WORK_TYPE_CODE:"AR",WORK_TYPE_COLOR:"#9E480E",PSR_PLAN_CUM:169,PSR_PLAN_WK:14,PSR_FORECAST_CUM:212,PSR_FORECAST_WK:42,PSR_ACTUAL_CUM:240,PSR_ACTUAL_WK:57,RAS_ROS:10},
    {CUTOFF:"2022-07-01",WK_NO:"W2022_26",WORK_TYPE_CODE:"DJS",WORK_TYPE_COLOR:"#335AA1",PSR_PLAN_CUM:43,PSR_PLAN_WK:4,PSR_FORECAST_CUM:36,PSR_FORECAST_WK:3,PSR_ACTUAL_CUM:36,PSR_ACTUAL_WK:5,RAS_ROS:10},
    {CUTOFF:"2022-07-01",WK_NO:"W2022_26",WORK_TYPE_CODE:"ARA",WORK_TYPE_COLOR:"#636363",PSR_PLAN_CUM:162,PSR_PLAN_WK:14,PSR_FORECAST_CUM:213,PSR_FORECAST_WK:48,PSR_ACTUAL_CUM:99,PSR_ACTUAL_WK:34,RAS_ROS:10},
    {CUTOFF:"2022-07-01",WK_NO:"W2022_26",WORK_TYPE_CODE:"FI",WORK_TYPE_COLOR:"#B7B7B7",PSR_PLAN_CUM:5,PSR_PLAN_WK:0,PSR_FORECAST_CUM:5,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:10},
    {CUTOFF:"2022-07-01",WK_NO:"W2022_26",WORK_TYPE_CODE:"BC",WORK_TYPE_COLOR:"#5B9BD5",PSR_PLAN_CUM:521,PSR_PLAN_WK:142,PSR_FORECAST_CUM:513,PSR_FORECAST_WK:28,PSR_ACTUAL_CUM:398,PSR_ACTUAL_WK:5,RAS_ROS:10},
    {CUTOFF:"2022-07-01",WK_NO:"W2022_26",WORK_TYPE_CODE:"MDI",WORK_TYPE_COLOR:"#43682B",PSR_PLAN_CUM:96,PSR_PLAN_WK:15,PSR_FORECAST_CUM:71,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:21,PSR_ACTUAL_WK:0,RAS_ROS:10},
    {CUTOFF:"2022-07-01",WK_NO:"W2022_26",WORK_TYPE_CODE:"CBE",WORK_TYPE_COLOR:"#264478",PSR_PLAN_CUM:201,PSR_PLAN_WK:26,PSR_FORECAST_CUM:256,PSR_FORECAST_WK:23,PSR_ACTUAL_CUM:258,PSR_ACTUAL_WK:5,RAS_ROS:10},
    {CUTOFF:"2022-07-01",WK_NO:"W2022_26",WORK_TYPE_CODE:"PO",WORK_TYPE_COLOR:"#255E91",PSR_PLAN_CUM:147,PSR_PLAN_WK:4,PSR_FORECAST_CUM:158,PSR_FORECAST_WK:8,PSR_ACTUAL_CUM:142,PSR_ACTUAL_WK:1,RAS_ROS:10},
    {CUTOFF:"2022-07-01",WK_NO:"W2022_26",WORK_TYPE_CODE:"CC",WORK_TYPE_COLOR:"#8CC168",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:10},
    {CUTOFF:"2022-07-01",WK_NO:"W2022_26",WORK_TYPE_CODE:"PP",WORK_TYPE_COLOR:"#4472C4",PSR_PLAN_CUM:737,PSR_PLAN_WK:7,PSR_FORECAST_CUM:713,PSR_FORECAST_WK:9,PSR_ACTUAL_CUM:578,PSR_ACTUAL_WK:37,RAS_ROS:10},
    {CUTOFF:"2022-07-01",WK_NO:"W2022_26",WORK_TYPE_CODE:"EW",WORK_TYPE_COLOR:"#FFCD33",PSR_PLAN_CUM:8,PSR_PLAN_WK:0,PSR_FORECAST_CUM:47,PSR_FORECAST_WK:3,PSR_ACTUAL_CUM:7,PSR_ACTUAL_WK:0,RAS_ROS:10},
    {CUTOFF:"2022-07-01",WK_NO:"W2022_26",WORK_TYPE_CODE:"RFQ",WORK_TYPE_COLOR:"#FFC000",PSR_PLAN_CUM:697,PSR_PLAN_WK:85,PSR_FORECAST_CUM:716,PSR_FORECAST_WK:89,PSR_ACTUAL_CUM:556,PSR_ACTUAL_WK:9,RAS_ROS:10},
    {CUTOFF:"2022-07-01",WK_NO:"W2022_26",WORK_TYPE_CODE:"LOA",WORK_TYPE_COLOR:"#997300",PSR_PLAN_CUM:186,PSR_PLAN_WK:19,PSR_FORECAST_CUM:204,PSR_FORECAST_WK:26,PSR_ACTUAL_CUM:268,PSR_ACTUAL_WK:82,RAS_ROS:10},
    {CUTOFF:"2022-07-01",WK_NO:"W2022_26",WORK_TYPE_CODE:"SA",WORK_TYPE_COLOR:"#7CAFDD",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:10},
    {CUTOFF:"2022-07-01",WK_NO:"W2022_26",WORK_TYPE_CODE:"MMR",WORK_TYPE_COLOR:"#F1975A",PSR_PLAN_CUM:10,PSR_PLAN_WK:0,PSR_FORECAST_CUM:10,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:10},
    {CUTOFF:"2022-07-01",WK_NO:"W2022_26",WORK_TYPE_CODE:"MO",WORK_TYPE_COLOR:"#698ED0",PSR_PLAN_CUM:17,PSR_PLAN_WK:4,PSR_FORECAST_CUM:17,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:10},
    {CUTOFF:"2022-07-01",WK_NO:"W2022_26",WORK_TYPE_CODE:"MR",WORK_TYPE_COLOR:"#A5A5A5",PSR_PLAN_CUM:1145,PSR_PLAN_WK:32,PSR_FORECAST_CUM:1171,PSR_FORECAST_WK:31,PSR_ACTUAL_CUM:340,PSR_ACTUAL_WK:6,RAS_ROS:10},
    {CUTOFF:"2022-07-01",WK_NO:"W2022_26",WORK_TYPE_CODE:"PPA",WORK_TYPE_COLOR:"#ED7D31",PSR_PLAN_CUM:706,PSR_PLAN_WK:87,PSR_FORECAST_CUM:685,PSR_FORECAST_WK:67,PSR_ACTUAL_CUM:397,PSR_ACTUAL_WK:11,RAS_ROS:10},
    {CUTOFF:"2022-07-01",WK_NO:"W2022_26",WORK_TYPE_CODE:"TBE",WORK_TYPE_COLOR:"#70AD47",PSR_PLAN_CUM:236,PSR_PLAN_WK:25,PSR_FORECAST_CUM:327,PSR_FORECAST_WK:44,PSR_ACTUAL_CUM:299,PSR_ACTUAL_WK:25,RAS_ROS:10},
    {CUTOFF:"2022-07-08",WK_NO:"W2022_27",WORK_TYPE_CODE:"BC",WORK_TYPE_COLOR:"#5B9BD5",PSR_PLAN_CUM:580,PSR_PLAN_WK:59,PSR_FORECAST_CUM:540,PSR_FORECAST_WK:27,PSR_ACTUAL_CUM:405,PSR_ACTUAL_WK:7,RAS_ROS:12},
    {CUTOFF:"2022-07-08",WK_NO:"W2022_27",WORK_TYPE_CODE:"AR",WORK_TYPE_COLOR:"#9E480E",PSR_PLAN_CUM:172,PSR_PLAN_WK:3,PSR_FORECAST_CUM:242,PSR_FORECAST_WK:30,PSR_ACTUAL_CUM:248,PSR_ACTUAL_WK:8,RAS_ROS:12},
    {CUTOFF:"2022-07-08",WK_NO:"W2022_27",WORK_TYPE_CODE:"CBE",WORK_TYPE_COLOR:"#264478",PSR_PLAN_CUM:204,PSR_PLAN_WK:3,PSR_FORECAST_CUM:280,PSR_FORECAST_WK:24,PSR_ACTUAL_CUM:272,PSR_ACTUAL_WK:14,RAS_ROS:12},
    {CUTOFF:"2022-07-08",WK_NO:"W2022_27",WORK_TYPE_CODE:"ARA",WORK_TYPE_COLOR:"#636363",PSR_PLAN_CUM:169,PSR_PLAN_WK:7,PSR_FORECAST_CUM:252,PSR_FORECAST_WK:39,PSR_ACTUAL_CUM:99,PSR_ACTUAL_WK:0,RAS_ROS:12},
    {CUTOFF:"2022-07-08",WK_NO:"W2022_27",WORK_TYPE_CODE:"CC",WORK_TYPE_COLOR:"#8CC168",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:12},
    {CUTOFF:"2022-07-08",WK_NO:"W2022_27",WORK_TYPE_CODE:"EW",WORK_TYPE_COLOR:"#FFCD33",PSR_PLAN_CUM:8,PSR_PLAN_WK:0,PSR_FORECAST_CUM:62,PSR_FORECAST_WK:15,PSR_ACTUAL_CUM:21,PSR_ACTUAL_WK:14,RAS_ROS:12},
    {CUTOFF:"2022-07-08",WK_NO:"W2022_27",WORK_TYPE_CODE:"DJS",WORK_TYPE_COLOR:"#335AA1",PSR_PLAN_CUM:46,PSR_PLAN_WK:3,PSR_FORECAST_CUM:36,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:36,PSR_ACTUAL_WK:0,RAS_ROS:12},
    {CUTOFF:"2022-07-08",WK_NO:"W2022_27",WORK_TYPE_CODE:"MR",WORK_TYPE_COLOR:"#A5A5A5",PSR_PLAN_CUM:1154,PSR_PLAN_WK:9,PSR_FORECAST_CUM:1182,PSR_FORECAST_WK:11,PSR_ACTUAL_CUM:348,PSR_ACTUAL_WK:8,RAS_ROS:12},
    {CUTOFF:"2022-07-08",WK_NO:"W2022_27",WORK_TYPE_CODE:"FI",WORK_TYPE_COLOR:"#B7B7B7",PSR_PLAN_CUM:5,PSR_PLAN_WK:0,PSR_FORECAST_CUM:5,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:12},
    {CUTOFF:"2022-07-08",WK_NO:"W2022_27",WORK_TYPE_CODE:"PO",WORK_TYPE_COLOR:"#255E91",PSR_PLAN_CUM:148,PSR_PLAN_WK:1,PSR_FORECAST_CUM:160,PSR_FORECAST_WK:2,PSR_ACTUAL_CUM:146,PSR_ACTUAL_WK:4,RAS_ROS:12},
    {CUTOFF:"2022-07-08",WK_NO:"W2022_27",WORK_TYPE_CODE:"LOA",WORK_TYPE_COLOR:"#997300",PSR_PLAN_CUM:193,PSR_PLAN_WK:7,PSR_FORECAST_CUM:270,PSR_FORECAST_WK:66,PSR_ACTUAL_CUM:274,PSR_ACTUAL_WK:6,RAS_ROS:12},
    {CUTOFF:"2022-07-08",WK_NO:"W2022_27",WORK_TYPE_CODE:"PPA",WORK_TYPE_COLOR:"#ED7D31",PSR_PLAN_CUM:711,PSR_PLAN_WK:5,PSR_FORECAST_CUM:693,PSR_FORECAST_WK:8,PSR_ACTUAL_CUM:405,PSR_ACTUAL_WK:8,RAS_ROS:12},
    {CUTOFF:"2022-07-08",WK_NO:"W2022_27",WORK_TYPE_CODE:"MDI",WORK_TYPE_COLOR:"#43682B",PSR_PLAN_CUM:97,PSR_PLAN_WK:1,PSR_FORECAST_CUM:105,PSR_FORECAST_WK:34,PSR_ACTUAL_CUM:21,PSR_ACTUAL_WK:0,RAS_ROS:12},
    {CUTOFF:"2022-07-08",WK_NO:"W2022_27",WORK_TYPE_CODE:"SA",WORK_TYPE_COLOR:"#7CAFDD",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:12},
    {CUTOFF:"2022-07-08",WK_NO:"W2022_27",WORK_TYPE_CODE:"MMR",WORK_TYPE_COLOR:"#F1975A",PSR_PLAN_CUM:10,PSR_PLAN_WK:0,PSR_FORECAST_CUM:10,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:12},
    {CUTOFF:"2022-07-08",WK_NO:"W2022_27",WORK_TYPE_CODE:"MO",WORK_TYPE_COLOR:"#698ED0",PSR_PLAN_CUM:17,PSR_PLAN_WK:0,PSR_FORECAST_CUM:17,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:12},
    {CUTOFF:"2022-07-08",WK_NO:"W2022_27",WORK_TYPE_CODE:"PP",WORK_TYPE_COLOR:"#4472C4",PSR_PLAN_CUM:741,PSR_PLAN_WK:4,PSR_FORECAST_CUM:729,PSR_FORECAST_WK:16,PSR_ACTUAL_CUM:583,PSR_ACTUAL_WK:5,RAS_ROS:12},
    {CUTOFF:"2022-07-08",WK_NO:"W2022_27",WORK_TYPE_CODE:"RFQ",WORK_TYPE_COLOR:"#FFC000",PSR_PLAN_CUM:728,PSR_PLAN_WK:31,PSR_FORECAST_CUM:723,PSR_FORECAST_WK:7,PSR_ACTUAL_CUM:573,PSR_ACTUAL_WK:17,RAS_ROS:12},
    {CUTOFF:"2022-07-08",WK_NO:"W2022_27",WORK_TYPE_CODE:"TBE",WORK_TYPE_COLOR:"#70AD47",PSR_PLAN_CUM:269,PSR_PLAN_WK:33,PSR_FORECAST_CUM:341,PSR_FORECAST_WK:14,PSR_ACTUAL_CUM:330,PSR_ACTUAL_WK:31,RAS_ROS:12},
    {CUTOFF:"2022-07-15",WK_NO:"W2022_28",WORK_TYPE_CODE:"AR",WORK_TYPE_COLOR:"#9E480E",PSR_PLAN_CUM:177,PSR_PLAN_WK:5,PSR_FORECAST_CUM:267,PSR_FORECAST_WK:25,PSR_ACTUAL_CUM:249,PSR_ACTUAL_WK:1,RAS_ROS:15},
    {CUTOFF:"2022-07-15",WK_NO:"W2022_28",WORK_TYPE_CODE:"CBE",WORK_TYPE_COLOR:"#264478",PSR_PLAN_CUM:215,PSR_PLAN_WK:11,PSR_FORECAST_CUM:299,PSR_FORECAST_WK:19,PSR_ACTUAL_CUM:287,PSR_ACTUAL_WK:15,RAS_ROS:15},
    {CUTOFF:"2022-07-15",WK_NO:"W2022_28",WORK_TYPE_CODE:"ARA",WORK_TYPE_COLOR:"#636363",PSR_PLAN_CUM:175,PSR_PLAN_WK:6,PSR_FORECAST_CUM:273,PSR_FORECAST_WK:21,PSR_ACTUAL_CUM:99,PSR_ACTUAL_WK:0,RAS_ROS:15},
    {CUTOFF:"2022-07-15",WK_NO:"W2022_28",WORK_TYPE_CODE:"CC",WORK_TYPE_COLOR:"#8CC168",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:15},
    {CUTOFF:"2022-07-15",WK_NO:"W2022_28",WORK_TYPE_CODE:"BC",WORK_TYPE_COLOR:"#5B9BD5",PSR_PLAN_CUM:584,PSR_PLAN_WK:4,PSR_FORECAST_CUM:589,PSR_FORECAST_WK:49,PSR_ACTUAL_CUM:413,PSR_ACTUAL_WK:8,RAS_ROS:15},
    {CUTOFF:"2022-07-15",WK_NO:"W2022_28",WORK_TYPE_CODE:"DJS",WORK_TYPE_COLOR:"#335AA1",PSR_PLAN_CUM:50,PSR_PLAN_WK:4,PSR_FORECAST_CUM:50,PSR_FORECAST_WK:14,PSR_ACTUAL_CUM:49,PSR_ACTUAL_WK:13,RAS_ROS:15},
    {CUTOFF:"2022-07-15",WK_NO:"W2022_28",WORK_TYPE_CODE:"EW",WORK_TYPE_COLOR:"#FFCD33",PSR_PLAN_CUM:8,PSR_PLAN_WK:0,PSR_FORECAST_CUM:62,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:21,PSR_ACTUAL_WK:0,RAS_ROS:15},
    {CUTOFF:"2022-07-15",WK_NO:"W2022_28",WORK_TYPE_CODE:"FI",WORK_TYPE_COLOR:"#B7B7B7",PSR_PLAN_CUM:5,PSR_PLAN_WK:0,PSR_FORECAST_CUM:5,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:15},
    {CUTOFF:"2022-07-15",WK_NO:"W2022_28",WORK_TYPE_CODE:"LOA",WORK_TYPE_COLOR:"#997300",PSR_PLAN_CUM:201,PSR_PLAN_WK:8,PSR_FORECAST_CUM:290,PSR_FORECAST_WK:20,PSR_ACTUAL_CUM:278,PSR_ACTUAL_WK:4,RAS_ROS:15},
    {CUTOFF:"2022-07-15",WK_NO:"W2022_28",WORK_TYPE_CODE:"MDI",WORK_TYPE_COLOR:"#43682B",PSR_PLAN_CUM:102,PSR_PLAN_WK:5,PSR_FORECAST_CUM:108,PSR_FORECAST_WK:3,PSR_ACTUAL_CUM:21,PSR_ACTUAL_WK:0,RAS_ROS:15},
    {CUTOFF:"2022-07-15",WK_NO:"W2022_28",WORK_TYPE_CODE:"MR",WORK_TYPE_COLOR:"#A5A5A5",PSR_PLAN_CUM:1181,PSR_PLAN_WK:27,PSR_FORECAST_CUM:1209,PSR_FORECAST_WK:27,PSR_ACTUAL_CUM:356,PSR_ACTUAL_WK:8,RAS_ROS:15},
    {CUTOFF:"2022-07-15",WK_NO:"W2022_28",WORK_TYPE_CODE:"MMR",WORK_TYPE_COLOR:"#F1975A",PSR_PLAN_CUM:12,PSR_PLAN_WK:2,PSR_FORECAST_CUM:12,PSR_FORECAST_WK:2,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:15},
    {CUTOFF:"2022-07-15",WK_NO:"W2022_28",WORK_TYPE_CODE:"PO",WORK_TYPE_COLOR:"#255E91",PSR_PLAN_CUM:152,PSR_PLAN_WK:4,PSR_FORECAST_CUM:161,PSR_FORECAST_WK:1,PSR_ACTUAL_CUM:157,PSR_ACTUAL_WK:11,RAS_ROS:15},
    {CUTOFF:"2022-07-15",WK_NO:"W2022_28",WORK_TYPE_CODE:"MO",WORK_TYPE_COLOR:"#698ED0",PSR_PLAN_CUM:17,PSR_PLAN_WK:0,PSR_FORECAST_CUM:17,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:15},
    {CUTOFF:"2022-07-15",WK_NO:"W2022_28",WORK_TYPE_CODE:"PPA",WORK_TYPE_COLOR:"#ED7D31",PSR_PLAN_CUM:730,PSR_PLAN_WK:19,PSR_FORECAST_CUM:732,PSR_FORECAST_WK:39,PSR_ACTUAL_CUM:433,PSR_ACTUAL_WK:28,RAS_ROS:15},
    {CUTOFF:"2022-07-15",WK_NO:"W2022_28",WORK_TYPE_CODE:"PP",WORK_TYPE_COLOR:"#4472C4",PSR_PLAN_CUM:748,PSR_PLAN_WK:7,PSR_FORECAST_CUM:745,PSR_FORECAST_WK:16,PSR_ACTUAL_CUM:593,PSR_ACTUAL_WK:10,RAS_ROS:15},
    {CUTOFF:"2022-07-15",WK_NO:"W2022_28",WORK_TYPE_CODE:"SA",WORK_TYPE_COLOR:"#7CAFDD",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:15},
    {CUTOFF:"2022-07-15",WK_NO:"W2022_28",WORK_TYPE_CODE:"RFQ",WORK_TYPE_COLOR:"#FFC000",PSR_PLAN_CUM:728,PSR_PLAN_WK:0,PSR_FORECAST_CUM:732,PSR_FORECAST_WK:9,PSR_ACTUAL_CUM:582,PSR_ACTUAL_WK:9,RAS_ROS:15},
    {CUTOFF:"2022-07-15",WK_NO:"W2022_28",WORK_TYPE_CODE:"TBE",WORK_TYPE_COLOR:"#70AD47",PSR_PLAN_CUM:311,PSR_PLAN_WK:42,PSR_FORECAST_CUM:371,PSR_FORECAST_WK:30,PSR_ACTUAL_CUM:338,PSR_ACTUAL_WK:8,RAS_ROS:15},
    {CUTOFF:"2022-07-22",WK_NO:"W2022_29",WORK_TYPE_CODE:"AR",WORK_TYPE_COLOR:"#9E480E",PSR_PLAN_CUM:197,PSR_PLAN_WK:20,PSR_FORECAST_CUM:280,PSR_FORECAST_WK:13,PSR_ACTUAL_CUM:254,PSR_ACTUAL_WK:5,RAS_ROS:19},
    {CUTOFF:"2022-07-22",WK_NO:"W2022_29",WORK_TYPE_CODE:"ARA",WORK_TYPE_COLOR:"#636363",PSR_PLAN_CUM:181,PSR_PLAN_WK:6,PSR_FORECAST_CUM:280,PSR_FORECAST_WK:7,PSR_ACTUAL_CUM:99,PSR_ACTUAL_WK:0,RAS_ROS:19},
    {CUTOFF:"2022-07-22",WK_NO:"W2022_29",WORK_TYPE_CODE:"BC",WORK_TYPE_COLOR:"#5B9BD5",PSR_PLAN_CUM:585,PSR_PLAN_WK:1,PSR_FORECAST_CUM:600,PSR_FORECAST_WK:11,PSR_ACTUAL_CUM:417,PSR_ACTUAL_WK:4,RAS_ROS:19},
    {CUTOFF:"2022-07-22",WK_NO:"W2022_29",WORK_TYPE_CODE:"CC",WORK_TYPE_COLOR:"#8CC168",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:19},
    {CUTOFF:"2022-07-22",WK_NO:"W2022_29",WORK_TYPE_CODE:"CBE",WORK_TYPE_COLOR:"#264478",PSR_PLAN_CUM:239,PSR_PLAN_WK:24,PSR_FORECAST_CUM:333,PSR_FORECAST_WK:34,PSR_ACTUAL_CUM:302,PSR_ACTUAL_WK:15,RAS_ROS:19},
    {CUTOFF:"2022-07-22",WK_NO:"W2022_29",WORK_TYPE_CODE:"DJS",WORK_TYPE_COLOR:"#335AA1",PSR_PLAN_CUM:53,PSR_PLAN_WK:3,PSR_FORECAST_CUM:50,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:53,PSR_ACTUAL_WK:4,RAS_ROS:19},
    {CUTOFF:"2022-07-22",WK_NO:"W2022_29",WORK_TYPE_CODE:"EW",WORK_TYPE_COLOR:"#FFCD33",PSR_PLAN_CUM:8,PSR_PLAN_WK:0,PSR_FORECAST_CUM:66,PSR_FORECAST_WK:4,PSR_ACTUAL_CUM:25,PSR_ACTUAL_WK:4,RAS_ROS:19},
    {CUTOFF:"2022-07-22",WK_NO:"W2022_29",WORK_TYPE_CODE:"MDI",WORK_TYPE_COLOR:"#43682B",PSR_PLAN_CUM:106,PSR_PLAN_WK:4,PSR_FORECAST_CUM:112,PSR_FORECAST_WK:4,PSR_ACTUAL_CUM:21,PSR_ACTUAL_WK:0,RAS_ROS:19},
    {CUTOFF:"2022-07-22",WK_NO:"W2022_29",WORK_TYPE_CODE:"FI",WORK_TYPE_COLOR:"#B7B7B7",PSR_PLAN_CUM:5,PSR_PLAN_WK:0,PSR_FORECAST_CUM:5,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:19},
    {CUTOFF:"2022-07-22",WK_NO:"W2022_29",WORK_TYPE_CODE:"PPA",WORK_TYPE_COLOR:"#ED7D31",PSR_PLAN_CUM:731,PSR_PLAN_WK:1,PSR_FORECAST_CUM:734,PSR_FORECAST_WK:2,PSR_ACTUAL_CUM:433,PSR_ACTUAL_WK:0,RAS_ROS:19},
    {CUTOFF:"2022-07-22",WK_NO:"W2022_29",WORK_TYPE_CODE:"LOA",WORK_TYPE_COLOR:"#997300",PSR_PLAN_CUM:207,PSR_PLAN_WK:6,PSR_FORECAST_CUM:294,PSR_FORECAST_WK:4,PSR_ACTUAL_CUM:278,PSR_ACTUAL_WK:0,RAS_ROS:19},
    {CUTOFF:"2022-07-22",WK_NO:"W2022_29",WORK_TYPE_CODE:"RFQ",WORK_TYPE_COLOR:"#FFC000",PSR_PLAN_CUM:752,PSR_PLAN_WK:24,PSR_FORECAST_CUM:751,PSR_FORECAST_WK:19,PSR_ACTUAL_CUM:585,PSR_ACTUAL_WK:3,RAS_ROS:19},
    {CUTOFF:"2022-07-22",WK_NO:"W2022_29",WORK_TYPE_CODE:"MMR",WORK_TYPE_COLOR:"#F1975A",PSR_PLAN_CUM:12,PSR_PLAN_WK:0,PSR_FORECAST_CUM:12,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:19},
    {CUTOFF:"2022-07-22",WK_NO:"W2022_29",WORK_TYPE_CODE:"TBE",WORK_TYPE_COLOR:"#70AD47",PSR_PLAN_CUM:338,PSR_PLAN_WK:27,PSR_FORECAST_CUM:386,PSR_FORECAST_WK:15,PSR_ACTUAL_CUM:343,PSR_ACTUAL_WK:5,RAS_ROS:19},
    {CUTOFF:"2022-07-22",WK_NO:"W2022_29",WORK_TYPE_CODE:"MO",WORK_TYPE_COLOR:"#698ED0",PSR_PLAN_CUM:17,PSR_PLAN_WK:0,PSR_FORECAST_CUM:17,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:19},
    {CUTOFF:"2022-07-22",WK_NO:"W2022_29",WORK_TYPE_CODE:"MR",WORK_TYPE_COLOR:"#A5A5A5",PSR_PLAN_CUM:1181,PSR_PLAN_WK:0,PSR_FORECAST_CUM:1209,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:356,PSR_ACTUAL_WK:0,RAS_ROS:19},
    {CUTOFF:"2022-07-22",WK_NO:"W2022_29",WORK_TYPE_CODE:"PO",WORK_TYPE_COLOR:"#255E91",PSR_PLAN_CUM:154,PSR_PLAN_WK:2,PSR_FORECAST_CUM:171,PSR_FORECAST_WK:10,PSR_ACTUAL_CUM:157,PSR_ACTUAL_WK:0,RAS_ROS:19},
    {CUTOFF:"2022-07-22",WK_NO:"W2022_29",WORK_TYPE_CODE:"PP",WORK_TYPE_COLOR:"#4472C4",PSR_PLAN_CUM:761,PSR_PLAN_WK:13,PSR_FORECAST_CUM:755,PSR_FORECAST_WK:10,PSR_ACTUAL_CUM:597,PSR_ACTUAL_WK:4,RAS_ROS:19},
    {CUTOFF:"2022-07-22",WK_NO:"W2022_29",WORK_TYPE_CODE:"SA",WORK_TYPE_COLOR:"#7CAFDD",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,PSR_ACTUAL_CUM:0,PSR_ACTUAL_WK:0,RAS_ROS:19},
    {CUTOFF:"2022-07-29",WK_NO:"W2022_30",WORK_TYPE_CODE:"DJS",WORK_TYPE_COLOR:"#335AA1",PSR_PLAN_CUM:53,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53,PSR_FORECAST_WK:3,RAS_ROS:23},
    {CUTOFF:"2022-07-29",WK_NO:"W2022_30",WORK_TYPE_CODE:"AR",WORK_TYPE_COLOR:"#9E480E",PSR_PLAN_CUM:207,PSR_PLAN_WK:10,PSR_FORECAST_CUM:293,PSR_FORECAST_WK:13,RAS_ROS:23},
    {CUTOFF:"2022-07-29",WK_NO:"W2022_30",WORK_TYPE_CODE:"FI",WORK_TYPE_COLOR:"#B7B7B7",PSR_PLAN_CUM:5,PSR_PLAN_WK:0,PSR_FORECAST_CUM:5,PSR_FORECAST_WK:0,RAS_ROS:23},
    {CUTOFF:"2022-07-29",WK_NO:"W2022_30",WORK_TYPE_CODE:"ARA",WORK_TYPE_COLOR:"#636363",PSR_PLAN_CUM:196,PSR_PLAN_WK:15,PSR_FORECAST_CUM:288,PSR_FORECAST_WK:8,RAS_ROS:23},
    {CUTOFF:"2022-07-29",WK_NO:"W2022_30",WORK_TYPE_CODE:"MDI",WORK_TYPE_COLOR:"#43682B",PSR_PLAN_CUM:106,PSR_PLAN_WK:0,PSR_FORECAST_CUM:119,PSR_FORECAST_WK:7,RAS_ROS:23},
    {CUTOFF:"2022-07-29",WK_NO:"W2022_30",WORK_TYPE_CODE:"BC",WORK_TYPE_COLOR:"#5B9BD5",PSR_PLAN_CUM:593,PSR_PLAN_WK:8,PSR_FORECAST_CUM:608,PSR_FORECAST_WK:8,RAS_ROS:23},
    {CUTOFF:"2022-07-29",WK_NO:"W2022_30",WORK_TYPE_CODE:"PP",WORK_TYPE_COLOR:"#4472C4",PSR_PLAN_CUM:912,PSR_PLAN_WK:151,PSR_FORECAST_CUM:907,PSR_FORECAST_WK:152,RAS_ROS:23},
    {CUTOFF:"2022-07-29",WK_NO:"W2022_30",WORK_TYPE_CODE:"CBE",WORK_TYPE_COLOR:"#264478",PSR_PLAN_CUM:275,PSR_PLAN_WK:36,PSR_FORECAST_CUM:355,PSR_FORECAST_WK:22,RAS_ROS:23},
    {CUTOFF:"2022-07-29",WK_NO:"W2022_30",WORK_TYPE_CODE:"RFQ",WORK_TYPE_COLOR:"#FFC000",PSR_PLAN_CUM:752,PSR_PLAN_WK:0,PSR_FORECAST_CUM:755,PSR_FORECAST_WK:4,RAS_ROS:23},
    {CUTOFF:"2022-07-29",WK_NO:"W2022_30",WORK_TYPE_CODE:"CC",WORK_TYPE_COLOR:"#8CC168",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,RAS_ROS:23},
    {CUTOFF:"2022-07-29",WK_NO:"W2022_30",WORK_TYPE_CODE:"EW",WORK_TYPE_COLOR:"#FFCD33",PSR_PLAN_CUM:8,PSR_PLAN_WK:0,PSR_FORECAST_CUM:66,PSR_FORECAST_WK:0,RAS_ROS:23},
    {CUTOFF:"2022-07-29",WK_NO:"W2022_30",WORK_TYPE_CODE:"LOA",WORK_TYPE_COLOR:"#997300",PSR_PLAN_CUM:223,PSR_PLAN_WK:16,PSR_FORECAST_CUM:304,PSR_FORECAST_WK:10,RAS_ROS:23},
    {CUTOFF:"2022-07-29",WK_NO:"W2022_30",WORK_TYPE_CODE:"MMR",WORK_TYPE_COLOR:"#F1975A",PSR_PLAN_CUM:12,PSR_PLAN_WK:0,PSR_FORECAST_CUM:12,PSR_FORECAST_WK:0,RAS_ROS:23},
    {CUTOFF:"2022-07-29",WK_NO:"W2022_30",WORK_TYPE_CODE:"MO",WORK_TYPE_COLOR:"#698ED0",PSR_PLAN_CUM:19,PSR_PLAN_WK:2,PSR_FORECAST_CUM:19,PSR_FORECAST_WK:2,RAS_ROS:23},
    {CUTOFF:"2022-07-29",WK_NO:"W2022_30",WORK_TYPE_CODE:"MR",WORK_TYPE_COLOR:"#A5A5A5",PSR_PLAN_CUM:1184,PSR_PLAN_WK:3,PSR_FORECAST_CUM:1216,PSR_FORECAST_WK:7,RAS_ROS:23},
    {CUTOFF:"2022-07-29",WK_NO:"W2022_30",WORK_TYPE_CODE:"PO",WORK_TYPE_COLOR:"#255E91",PSR_PLAN_CUM:166,PSR_PLAN_WK:12,PSR_FORECAST_CUM:182,PSR_FORECAST_WK:11,RAS_ROS:23},
    {CUTOFF:"2022-07-29",WK_NO:"W2022_30",WORK_TYPE_CODE:"PPA",WORK_TYPE_COLOR:"#ED7D31",PSR_PLAN_CUM:755,PSR_PLAN_WK:24,PSR_FORECAST_CUM:749,PSR_FORECAST_WK:15,RAS_ROS:23},
    {CUTOFF:"2022-07-29",WK_NO:"W2022_30",WORK_TYPE_CODE:"SA",WORK_TYPE_COLOR:"#7CAFDD",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,RAS_ROS:23},
    {CUTOFF:"2022-07-29",WK_NO:"W2022_30",WORK_TYPE_CODE:"TBE",WORK_TYPE_COLOR:"#70AD47",PSR_PLAN_CUM:380,PSR_PLAN_WK:42,PSR_FORECAST_CUM:400,PSR_FORECAST_WK:14,RAS_ROS:23},
    {CUTOFF:"2022-08-05",WK_NO:"W2022_31",WORK_TYPE_CODE:"AR",WORK_TYPE_COLOR:"#9E480E",PSR_PLAN_CUM:234,PSR_PLAN_WK:27,PSR_FORECAST_CUM:312,PSR_FORECAST_WK:19,RAS_ROS:23},
    {CUTOFF:"2022-08-05",WK_NO:"W2022_31",WORK_TYPE_CODE:"ARA",WORK_TYPE_COLOR:"#636363",PSR_PLAN_CUM:225,PSR_PLAN_WK:29,PSR_FORECAST_CUM:303,PSR_FORECAST_WK:15,RAS_ROS:23},
    {CUTOFF:"2022-08-05",WK_NO:"W2022_31",WORK_TYPE_CODE:"BC",WORK_TYPE_COLOR:"#5B9BD5",PSR_PLAN_CUM:665,PSR_PLAN_WK:72,PSR_FORECAST_CUM:625,PSR_FORECAST_WK:17,RAS_ROS:23},
    {CUTOFF:"2022-08-05",WK_NO:"W2022_31",WORK_TYPE_CODE:"EW",WORK_TYPE_COLOR:"#FFCD33",PSR_PLAN_CUM:8,PSR_PLAN_WK:0,PSR_FORECAST_CUM:66,PSR_FORECAST_WK:0,RAS_ROS:23},
    {CUTOFF:"2022-08-05",WK_NO:"W2022_31",WORK_TYPE_CODE:"CBE",WORK_TYPE_COLOR:"#264478",PSR_PLAN_CUM:322,PSR_PLAN_WK:47,PSR_FORECAST_CUM:386,PSR_FORECAST_WK:31,RAS_ROS:23},
    {CUTOFF:"2022-08-05",WK_NO:"W2022_31",WORK_TYPE_CODE:"MDI",WORK_TYPE_COLOR:"#43682B",PSR_PLAN_CUM:106,PSR_PLAN_WK:0,PSR_FORECAST_CUM:122,PSR_FORECAST_WK:3,RAS_ROS:23},
    {CUTOFF:"2022-08-05",WK_NO:"W2022_31",WORK_TYPE_CODE:"CC",WORK_TYPE_COLOR:"#8CC168",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,RAS_ROS:23},
    {CUTOFF:"2022-08-05",WK_NO:"W2022_31",WORK_TYPE_CODE:"MR",WORK_TYPE_COLOR:"#A5A5A5",PSR_PLAN_CUM:1307,PSR_PLAN_WK:123,PSR_FORECAST_CUM:1342,PSR_FORECAST_WK:126,RAS_ROS:23},
    {CUTOFF:"2022-08-05",WK_NO:"W2022_31",WORK_TYPE_CODE:"DJS",WORK_TYPE_COLOR:"#335AA1",PSR_PLAN_CUM:53,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53,PSR_FORECAST_WK:0,RAS_ROS:23},
    {CUTOFF:"2022-08-05",WK_NO:"W2022_31",WORK_TYPE_CODE:"PO",WORK_TYPE_COLOR:"#255E91",PSR_PLAN_CUM:180,PSR_PLAN_WK:14,PSR_FORECAST_CUM:188,PSR_FORECAST_WK:6,RAS_ROS:23},
    {CUTOFF:"2022-08-05",WK_NO:"W2022_31",WORK_TYPE_CODE:"FI",WORK_TYPE_COLOR:"#B7B7B7",PSR_PLAN_CUM:5,PSR_PLAN_WK:0,PSR_FORECAST_CUM:5,PSR_FORECAST_WK:0,RAS_ROS:23},
    {CUTOFF:"2022-08-05",WK_NO:"W2022_31",WORK_TYPE_CODE:"PPA",WORK_TYPE_COLOR:"#ED7D31",PSR_PLAN_CUM:839,PSR_PLAN_WK:84,PSR_FORECAST_CUM:834,PSR_FORECAST_WK:85,RAS_ROS:23},
    {CUTOFF:"2022-08-05",WK_NO:"W2022_31",WORK_TYPE_CODE:"LOA",WORK_TYPE_COLOR:"#997300",PSR_PLAN_CUM:247,PSR_PLAN_WK:24,PSR_FORECAST_CUM:319,PSR_FORECAST_WK:15,RAS_ROS:23},
    {CUTOFF:"2022-08-05",WK_NO:"W2022_31",WORK_TYPE_CODE:"RFQ",WORK_TYPE_COLOR:"#FFC000",PSR_PLAN_CUM:857,PSR_PLAN_WK:105,PSR_FORECAST_CUM:867,PSR_FORECAST_WK:112,RAS_ROS:23},
    {CUTOFF:"2022-08-05",WK_NO:"W2022_31",WORK_TYPE_CODE:"MMR",WORK_TYPE_COLOR:"#F1975A",PSR_PLAN_CUM:13,PSR_PLAN_WK:1,PSR_FORECAST_CUM:13,PSR_FORECAST_WK:1,RAS_ROS:23},
    {CUTOFF:"2022-08-05",WK_NO:"W2022_31",WORK_TYPE_CODE:"SA",WORK_TYPE_COLOR:"#7CAFDD",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,RAS_ROS:23},
    {CUTOFF:"2022-08-05",WK_NO:"W2022_31",WORK_TYPE_CODE:"MO",WORK_TYPE_COLOR:"#698ED0",PSR_PLAN_CUM:19,PSR_PLAN_WK:0,PSR_FORECAST_CUM:20,PSR_FORECAST_WK:1,RAS_ROS:23},
    {CUTOFF:"2022-08-05",WK_NO:"W2022_31",WORK_TYPE_CODE:"PP",WORK_TYPE_COLOR:"#4472C4",PSR_PLAN_CUM:912,PSR_PLAN_WK:0,PSR_FORECAST_CUM:907,PSR_FORECAST_WK:0,RAS_ROS:23},
    {CUTOFF:"2022-08-05",WK_NO:"W2022_31",WORK_TYPE_CODE:"TBE",WORK_TYPE_COLOR:"#70AD47",PSR_PLAN_CUM:543,PSR_PLAN_WK:163,PSR_FORECAST_CUM:434,PSR_FORECAST_WK:34,RAS_ROS:23},
    {CUTOFF:"2022-08-12",WK_NO:"W2022_32",WORK_TYPE_CODE:"BC",WORK_TYPE_COLOR:"#5B9BD5",PSR_PLAN_CUM:693,PSR_PLAN_WK:28,PSR_FORECAST_CUM:648,PSR_FORECAST_WK:23,RAS_ROS:23},
    {CUTOFF:"2022-08-12",WK_NO:"W2022_32",WORK_TYPE_CODE:"AR",WORK_TYPE_COLOR:"#9E480E",PSR_PLAN_CUM:247,PSR_PLAN_WK:13,PSR_FORECAST_CUM:353,PSR_FORECAST_WK:41,RAS_ROS:23},
    {CUTOFF:"2022-08-12",WK_NO:"W2022_32",WORK_TYPE_CODE:"CBE",WORK_TYPE_COLOR:"#264478",PSR_PLAN_CUM:367,PSR_PLAN_WK:45,PSR_FORECAST_CUM:424,PSR_FORECAST_WK:38,RAS_ROS:23},
    {CUTOFF:"2022-08-12",WK_NO:"W2022_32",WORK_TYPE_CODE:"ARA",WORK_TYPE_COLOR:"#636363",PSR_PLAN_CUM:239,PSR_PLAN_WK:14,PSR_FORECAST_CUM:318,PSR_FORECAST_WK:15,RAS_ROS:23},
    {CUTOFF:"2022-08-12",WK_NO:"W2022_32",WORK_TYPE_CODE:"CC",WORK_TYPE_COLOR:"#8CC168",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,RAS_ROS:23},
    {CUTOFF:"2022-08-12",WK_NO:"W2022_32",WORK_TYPE_CODE:"EW",WORK_TYPE_COLOR:"#FFCD33",PSR_PLAN_CUM:8,PSR_PLAN_WK:0,PSR_FORECAST_CUM:67,PSR_FORECAST_WK:1,RAS_ROS:23},
    {CUTOFF:"2022-08-12",WK_NO:"W2022_32",WORK_TYPE_CODE:"DJS",WORK_TYPE_COLOR:"#335AA1",PSR_PLAN_CUM:53,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53,PSR_FORECAST_WK:0,RAS_ROS:23},
    {CUTOFF:"2022-08-12",WK_NO:"W2022_32",WORK_TYPE_CODE:"MR",WORK_TYPE_COLOR:"#A5A5A5",PSR_PLAN_CUM:1307,PSR_PLAN_WK:0,PSR_FORECAST_CUM:1343,PSR_FORECAST_WK:1,RAS_ROS:23},
    {CUTOFF:"2022-08-12",WK_NO:"W2022_32",WORK_TYPE_CODE:"FI",WORK_TYPE_COLOR:"#B7B7B7",PSR_PLAN_CUM:8,PSR_PLAN_WK:3,PSR_FORECAST_CUM:8,PSR_FORECAST_WK:3,RAS_ROS:23},
    {CUTOFF:"2022-08-12",WK_NO:"W2022_32",WORK_TYPE_CODE:"PO",WORK_TYPE_COLOR:"#255E91",PSR_PLAN_CUM:194,PSR_PLAN_WK:14,PSR_FORECAST_CUM:221,PSR_FORECAST_WK:33,RAS_ROS:23},
    {CUTOFF:"2022-08-12",WK_NO:"W2022_32",WORK_TYPE_CODE:"LOA",WORK_TYPE_COLOR:"#997300",PSR_PLAN_CUM:257,PSR_PLAN_WK:10,PSR_FORECAST_CUM:335,PSR_FORECAST_WK:16,RAS_ROS:23},
    {CUTOFF:"2022-08-12",WK_NO:"W2022_32",WORK_TYPE_CODE:"PPA",WORK_TYPE_COLOR:"#ED7D31",PSR_PLAN_CUM:912,PSR_PLAN_WK:73,PSR_FORECAST_CUM:907,PSR_FORECAST_WK:73,RAS_ROS:23},
    {CUTOFF:"2022-08-12",WK_NO:"W2022_32",WORK_TYPE_CODE:"MDI",WORK_TYPE_COLOR:"#43682B",PSR_PLAN_CUM:107,PSR_PLAN_WK:1,PSR_FORECAST_CUM:136,PSR_FORECAST_WK:14,RAS_ROS:23},
    {CUTOFF:"2022-08-12",WK_NO:"W2022_32",WORK_TYPE_CODE:"SA",WORK_TYPE_COLOR:"#7CAFDD",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:0,PSR_FORECAST_WK:0,RAS_ROS:23},
    {CUTOFF:"2022-08-12",WK_NO:"W2022_32",WORK_TYPE_CODE:"MMR",WORK_TYPE_COLOR:"#F1975A",PSR_PLAN_CUM:13,PSR_PLAN_WK:0,PSR_FORECAST_CUM:13,PSR_FORECAST_WK:0,RAS_ROS:23},
    {CUTOFF:"2022-08-12",WK_NO:"W2022_32",WORK_TYPE_CODE:"MO",WORK_TYPE_COLOR:"#698ED0",PSR_PLAN_CUM:19,PSR_PLAN_WK:0,PSR_FORECAST_CUM:21,PSR_FORECAST_WK:1,RAS_ROS:23},
    {CUTOFF:"2022-08-12",WK_NO:"W2022_32",WORK_TYPE_CODE:"PP",WORK_TYPE_COLOR:"#4472C4",PSR_PLAN_CUM:1244,PSR_PLAN_WK:332,PSR_FORECAST_CUM:1239,PSR_FORECAST_WK:332,RAS_ROS:23},
    {CUTOFF:"2022-08-12",WK_NO:"W2022_32",WORK_TYPE_CODE:"RFQ",WORK_TYPE_COLOR:"#FFC000",PSR_PLAN_CUM:962,PSR_PLAN_WK:105,PSR_FORECAST_CUM:964,PSR_FORECAST_WK:97,RAS_ROS:23},
    {CUTOFF:"2022-08-12",WK_NO:"W2022_32",WORK_TYPE_CODE:"TBE",WORK_TYPE_COLOR:"#70AD47",PSR_PLAN_CUM:553,PSR_PLAN_WK:10,PSR_FORECAST_CUM:560,PSR_FORECAST_WK:126,RAS_ROS:23},
    {CUTOFF:"2022-08-19",WK_NO:"W2022_33",WORK_TYPE_CODE:"AR",WORK_TYPE_COLOR:"#9E480E",PSR_PLAN_CUM:275,PSR_PLAN_WK:28,PSR_FORECAST_CUM:361,PSR_FORECAST_WK:8,RAS_ROS:24},
    {CUTOFF:"2022-08-19",WK_NO:"W2022_33",WORK_TYPE_CODE:"ARA",WORK_TYPE_COLOR:"#636363",PSR_PLAN_CUM:268,PSR_PLAN_WK:29,PSR_FORECAST_CUM:345,PSR_FORECAST_WK:27,RAS_ROS:24},
    {CUTOFF:"2022-08-19",WK_NO:"W2022_33",WORK_TYPE_CODE:"BC",WORK_TYPE_COLOR:"#5B9BD5",PSR_PLAN_CUM:712,PSR_PLAN_WK:19,PSR_FORECAST_CUM:668,PSR_FORECAST_WK:20,RAS_ROS:24},
    {CUTOFF:"2022-08-19",WK_NO:"W2022_33",WORK_TYPE_CODE:"CC",WORK_TYPE_COLOR:"#8CC168",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:2,PSR_FORECAST_WK:2,RAS_ROS:24},
    {CUTOFF:"2022-08-19",WK_NO:"W2022_33",WORK_TYPE_CODE:"CBE",WORK_TYPE_COLOR:"#264478",PSR_PLAN_CUM:408,PSR_PLAN_WK:41,PSR_FORECAST_CUM:437,PSR_FORECAST_WK:13,RAS_ROS:24},
    {CUTOFF:"2022-08-19",WK_NO:"W2022_33",WORK_TYPE_CODE:"DJS",WORK_TYPE_COLOR:"#335AA1",PSR_PLAN_CUM:53,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53,PSR_FORECAST_WK:0,RAS_ROS:24},
    {CUTOFF:"2022-08-19",WK_NO:"W2022_33",WORK_TYPE_CODE:"EW",WORK_TYPE_COLOR:"#FFCD33",PSR_PLAN_CUM:8,PSR_PLAN_WK:0,PSR_FORECAST_CUM:69,PSR_FORECAST_WK:2,RAS_ROS:24},
    {CUTOFF:"2022-08-19",WK_NO:"W2022_33",WORK_TYPE_CODE:"MDI",WORK_TYPE_COLOR:"#43682B",PSR_PLAN_CUM:108,PSR_PLAN_WK:1,PSR_FORECAST_CUM:138,PSR_FORECAST_WK:2,RAS_ROS:24},
    {CUTOFF:"2022-08-19",WK_NO:"W2022_33",WORK_TYPE_CODE:"FI",WORK_TYPE_COLOR:"#B7B7B7",PSR_PLAN_CUM:8,PSR_PLAN_WK:0,PSR_FORECAST_CUM:8,PSR_FORECAST_WK:0,RAS_ROS:24},
    {CUTOFF:"2022-08-19",WK_NO:"W2022_33",WORK_TYPE_CODE:"MMR",WORK_TYPE_COLOR:"#F1975A",PSR_PLAN_CUM:13,PSR_PLAN_WK:0,PSR_FORECAST_CUM:13,PSR_FORECAST_WK:0,RAS_ROS:24},
    {CUTOFF:"2022-08-19",WK_NO:"W2022_33",WORK_TYPE_CODE:"LOA",WORK_TYPE_COLOR:"#997300",PSR_PLAN_CUM:272,PSR_PLAN_WK:15,PSR_FORECAST_CUM:359,PSR_FORECAST_WK:24,RAS_ROS:24},
    {CUTOFF:"2022-08-19",WK_NO:"W2022_33",WORK_TYPE_CODE:"PO",WORK_TYPE_COLOR:"#255E91",PSR_PLAN_CUM:201,PSR_PLAN_WK:7,PSR_FORECAST_CUM:274,PSR_FORECAST_WK:53,RAS_ROS:24},
    {CUTOFF:"2022-08-19",WK_NO:"W2022_33",WORK_TYPE_CODE:"MO",WORK_TYPE_COLOR:"#698ED0",PSR_PLAN_CUM:19,PSR_PLAN_WK:0,PSR_FORECAST_CUM:21,PSR_FORECAST_WK:0,RAS_ROS:24},
    {CUTOFF:"2022-08-19",WK_NO:"W2022_33",WORK_TYPE_CODE:"PPA",WORK_TYPE_COLOR:"#ED7D31",PSR_PLAN_CUM:912,PSR_PLAN_WK:0,PSR_FORECAST_CUM:907,PSR_FORECAST_WK:0,RAS_ROS:24},
    {CUTOFF:"2022-08-19",WK_NO:"W2022_33",WORK_TYPE_CODE:"MR",WORK_TYPE_COLOR:"#A5A5A5",PSR_PLAN_CUM:1317,PSR_PLAN_WK:10,PSR_FORECAST_CUM:1345,PSR_FORECAST_WK:2,RAS_ROS:24},
    {CUTOFF:"2022-08-19",WK_NO:"W2022_33",WORK_TYPE_CODE:"RFQ",WORK_TYPE_COLOR:"#FFC000",PSR_PLAN_CUM:962,PSR_PLAN_WK:0,PSR_FORECAST_CUM:964,PSR_FORECAST_WK:0,RAS_ROS:24},
    {CUTOFF:"2022-08-19",WK_NO:"W2022_33",WORK_TYPE_CODE:"PP",WORK_TYPE_COLOR:"#4472C4",PSR_PLAN_CUM:1248,PSR_PLAN_WK:4,PSR_FORECAST_CUM:1243,PSR_FORECAST_WK:4,RAS_ROS:24},
    {CUTOFF:"2022-08-19",WK_NO:"W2022_33",WORK_TYPE_CODE:"SA",WORK_TYPE_COLOR:"#7CAFDD",PSR_PLAN_CUM:0,PSR_PLAN_WK:0,PSR_FORECAST_CUM:2,PSR_FORECAST_WK:2,RAS_ROS:24},
    {CUTOFF:"2022-08-19",WK_NO:"W2022_33",WORK_TYPE_CODE:"TBE",WORK_TYPE_COLOR:"#70AD47",PSR_PLAN_CUM:579,PSR_PLAN_WK:26,PSR_FORECAST_CUM:570,PSR_FORECAST_WK:10,RAS_ROS:24}
    ],
}