import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1400,
      CanvasHeight: 420,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#e0e0e0',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 380,
      CanvasChartHeight: 280,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Support Fabrication', x:20, y: -20, font: 'roboto', class: 'regular', size: 12, color: '#424242', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: -50, y: -20, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'start' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: -30,
          y: -20,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'start'
        },
      ]
    },
    Queries: {
      // SQL1: [
      //   {PLAN:100,ACTUAL:99.99,DELTA:-0.01000000000000512}
      // ],
    },
    DataItems: [
      {WK_NO:"W2022_01",CUTOFF:"2022-01-05",DDMMMYY:"05-Jan-22",ISSUED_WK:2,RESPONDED_WK:3,REMAIN:241,AVG_TO_RESPONDED:15},
      {WK_NO:"W2022_02",CUTOFF:"2022-01-12",DDMMMYY:"12-Jan-22",ISSUED_WK:4,RESPONDED_WK:2,REMAIN:239,AVG_TO_RESPONDED:15},
      {WK_NO:"W2022_03",CUTOFF:"2022-01-19",DDMMMYY:"19-Jan-22",ISSUED_WK:3,RESPONDED_WK:2,REMAIN:237,AVG_TO_RESPONDED:15},
      {WK_NO:"W2022_04",CUTOFF:"2022-01-26",DDMMMYY:"26-Jan-22",ISSUED_WK:4,RESPONDED_WK:1,REMAIN:236,AVG_TO_RESPONDED:15},
      {WK_NO:"W2022_05",CUTOFF:"2022-02-02",DDMMMYY:"02-Feb-22",ISSUED_WK:5,RESPONDED_WK:1,REMAIN:235,AVG_TO_RESPONDED:15},
      {WK_NO:"W2022_06",CUTOFF:"2022-02-09",DDMMMYY:"09-Feb-22",ISSUED_WK:0,RESPONDED_WK:0,REMAIN:235,AVG_TO_RESPONDED:15},
      {WK_NO:"W2022_07",CUTOFF:"2022-02-16",DDMMMYY:"16-Feb-22",ISSUED_WK:4,RESPONDED_WK:2,REMAIN:233,AVG_TO_RESPONDED:15},
      {WK_NO:"W2022_08",CUTOFF:"2022-02-23",DDMMMYY:"23-Feb-22",ISSUED_WK:6,RESPONDED_WK:3,REMAIN:230,AVG_TO_RESPONDED:15},
      {WK_NO:"W2022_09",CUTOFF:"2022-03-02",DDMMMYY:"02-Mar-22",ISSUED_WK:9,RESPONDED_WK:1,REMAIN:229,AVG_TO_RESPONDED:15},
      {WK_NO:"W2022_10",CUTOFF:"2022-03-09",DDMMMYY:"09-Mar-22",ISSUED_WK:6,RESPONDED_WK:2,REMAIN:227,AVG_TO_RESPONDED:15},
      {WK_NO:"W2022_11",CUTOFF:"2022-03-16",DDMMMYY:"16-Mar-22",ISSUED_WK:9,RESPONDED_WK:2,REMAIN:225,AVG_TO_RESPONDED:16},
      {WK_NO:"W2022_12",CUTOFF:"2022-03-23",DDMMMYY:"23-Mar-22",ISSUED_WK:10,RESPONDED_WK:5,REMAIN:220,AVG_TO_RESPONDED:17},
      {WK_NO:"W2022_13",CUTOFF:"2022-03-30",DDMMMYY:"30-Mar-22",ISSUED_WK:6,RESPONDED_WK:4,REMAIN:216,AVG_TO_RESPONDED:17},
      {WK_NO:"W2022_14",CUTOFF:"2022-04-06",DDMMMYY:"06-Apr-22",ISSUED_WK:6,RESPONDED_WK:4,REMAIN:212,AVG_TO_RESPONDED:17},
      {WK_NO:"W2022_15",CUTOFF:"2022-04-13",DDMMMYY:"13-Apr-22",ISSUED_WK:6,RESPONDED_WK:6,REMAIN:206,AVG_TO_RESPONDED:17},
      {WK_NO:"W2022_16",CUTOFF:"2022-04-20",DDMMMYY:"20-Apr-22",ISSUED_WK:10,RESPONDED_WK:5,REMAIN:201,AVG_TO_RESPONDED:17},
      {WK_NO:"W2022_17",CUTOFF:"2022-04-27",DDMMMYY:"27-Apr-22",ISSUED_WK:4,RESPONDED_WK:2,REMAIN:199,AVG_TO_RESPONDED:17},
      {WK_NO:"W2022_18",CUTOFF:"2022-05-04",DDMMMYY:"04-May-22",ISSUED_WK:6,RESPONDED_WK:3,REMAIN:196,AVG_TO_RESPONDED:17},
      {WK_NO:"W2022_19",CUTOFF:"2022-05-11",DDMMMYY:"11-May-22",ISSUED_WK:3,RESPONDED_WK:1,REMAIN:195,AVG_TO_RESPONDED:17},
      {WK_NO:"W2022_20",CUTOFF:"2022-05-18",DDMMMYY:"18-May-22",ISSUED_WK:4,RESPONDED_WK:6,REMAIN:189,AVG_TO_RESPONDED:17},
      {WK_NO:"W2022_21",CUTOFF:"2022-05-25",DDMMMYY:"25-May-22",ISSUED_WK:11,RESPONDED_WK:4,REMAIN:185,AVG_TO_RESPONDED:18},
      {WK_NO:"W2022_22",CUTOFF:"2022-06-01",DDMMMYY:"01-Jun-22",ISSUED_WK:3,RESPONDED_WK:1,REMAIN:184,AVG_TO_RESPONDED:17},
      {WK_NO:"W2022_23",CUTOFF:"2022-06-08",DDMMMYY:"08-Jun-22",ISSUED_WK:4,RESPONDED_WK:1,REMAIN:183,AVG_TO_RESPONDED:17},
      {WK_NO:"W2022_24",CUTOFF:"2022-06-15",DDMMMYY:"15-Jun-22",ISSUED_WK:5,RESPONDED_WK:3,REMAIN:180,AVG_TO_RESPONDED:17},
      {WK_NO:"W2022_25",CUTOFF:"2022-06-22",DDMMMYY:"22-Jun-22",ISSUED_WK:5,RESPONDED_WK:5,REMAIN:175,AVG_TO_RESPONDED:17},
      {WK_NO:"W2022_26",CUTOFF:"2022-06-29",DDMMMYY:"29-Jun-22",ISSUED_WK:2,RESPONDED_WK:1,REMAIN:174,AVG_TO_RESPONDED:17},
      {WK_NO:"W2022_27",CUTOFF:"2022-07-06",DDMMMYY:"06-Jul-22",ISSUED_WK:5,RESPONDED_WK:4,REMAIN:170,AVG_TO_RESPONDED:17},
      {WK_NO:"W2022_28",CUTOFF:"2022-07-13",DDMMMYY:"13-Jul-22",ISSUED_WK:7,RESPONDED_WK:3,REMAIN:167,AVG_TO_RESPONDED:18},
      {WK_NO:"W2022_29",CUTOFF:"2022-07-20",DDMMMYY:"20-Jul-22",ISSUED_WK:5,RESPONDED_WK:1,REMAIN:166,AVG_TO_RESPONDED:18},
      {WK_NO:"W2022_30",CUTOFF:"2022-07-27",DDMMMYY:"27-Jul-22",ISSUED_WK:2,RESPONDED_WK:0,REMAIN:166,AVG_TO_RESPONDED:18},
      {WK_NO:"W2022_31",CUTOFF:"2022-08-03",DDMMMYY:"03-Aug-22",ISSUED_WK:5,RESPONDED_WK:1,REMAIN:165,AVG_TO_RESPONDED:18},
      {WK_NO:"W2022_32",CUTOFF:"2022-08-10",DDMMMYY:"10-Aug-22",ISSUED_WK:8,RESPONDED_WK:6,REMAIN:159,AVG_TO_RESPONDED:19},
      {WK_NO:"W2022_33",CUTOFF:"2022-08-17",DDMMMYY:"17-Aug-22",ISSUED_WK:2,RESPONDED_WK:3,REMAIN:156,AVG_TO_RESPONDED:19},
      {WK_NO:"W2022_34",CUTOFF:"2022-08-24",DDMMMYY:"24-Aug-22",ISSUED_WK:4,RESPONDED_WK:6,REMAIN:150,AVG_TO_RESPONDED:20},
      {WK_NO:"W2022_35",CUTOFF:"2022-08-31",DDMMMYY:"31-Aug-22",ISSUED_WK:10,RESPONDED_WK:84,REMAIN:66,AVG_TO_RESPONDED:70},
      {WK_NO:"W2022_36",CUTOFF:"2022-09-07",DDMMMYY:"07-Sep-22",ISSUED_WK:3,RESPONDED_WK:4,REMAIN:62,AVG_TO_RESPONDED:69},
      {WK_NO:"W2022_37",CUTOFF:"2022-09-14",DDMMMYY:"14-Sep-22",ISSUED_WK:4,RESPONDED_WK:1,REMAIN:61,AVG_TO_RESPONDED:69}
    ],
}