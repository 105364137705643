import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 500,
      CanvasHeight: 1100,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: '', x:10, y: 0, font: 'roboto', class: 'regular', size: 12, color: '#424242', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: -50, y: -30, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'start' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: -30,
          y: -30,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'start'
        },
      ]
    },
    Queries: {
      SQL1: [
      ],
    },
    DataItems: [
      {DESCRIPTION:"00 - General - All Areas",TOTAL:15,ISSUED:0,REMAIN:15,PROG:0},
      {DESCRIPTION:"00 - General - All Plant Area",TOTAL:694,ISSUED:549,REMAIN:145,PROG:79.11},
      {DESCRIPTION:"03 - P3 - Infrastructures",TOTAL:2,ISSUED:2,REMAIN:0,PROG:100},
      {DESCRIPTION:"10 - Furnaces",TOTAL:1135,ISSUED:249,REMAIN:886,PROG:21.94},
      {DESCRIPTION:"25 - Olefins Recovery",TOTAL:2136,ISSUED:1081,REMAIN:1055,PROG:50.61},
      {DESCRIPTION:"30 - Butadiene Extraction (BDU/SHU)",TOTAL:1134,ISSUED:638,REMAIN:496,PROG:56.26},
      {DESCRIPTION:"32 - DIB / MTBE",TOTAL:301,ISSUED:177,REMAIN:124,PROG:58.8},
      {DESCRIPTION:"34 - Butene - 1",TOTAL:17,ISSUED:13,REMAIN:4,PROG:76.47},
      {DESCRIPTION:"36 - Pygas Hydrotreater (GHU)",TOTAL:328,ISSUED:176,REMAIN:152,PROG:53.66},
      {DESCRIPTION:"38 - Aromatics Extraction (BTX)",TOTAL:366,ISSUED:179,REMAIN:187,PROG:48.91},
      {DESCRIPTION:"49 - Benzene Stripper",TOTAL:5,ISSUED:0,REMAIN:5,PROG:0},
      {DESCRIPTION:"49 - Benzene Stripper Unit",TOTAL:22,ISSUED:22,REMAIN:0,PROG:100},
      {DESCRIPTION:"50 - GPPE Polymers Common",TOTAL:520,ISSUED:300,REMAIN:220,PROG:57.69},
      {DESCRIPTION:"51 - GPPE Line 1 (CPE-1)",TOTAL:237,ISSUED:212,REMAIN:25,PROG:89.45},
      {DESCRIPTION:"52 - GPPE Line 2 (CPE-2)",TOTAL:191,ISSUED:162,REMAIN:29,PROG:84.82},
      {DESCRIPTION:"53 - LDPE (CPE-3)",TOTAL:553,ISSUED:362,REMAIN:191,PROG:65.46},
      {DESCRIPTION:"56 - Offplot Interconnecting Pipe",TOTAL:4,ISSUED:0,REMAIN:4,PROG:0},
      {DESCRIPTION:"56 - Offplot Interconnecting Pipe (including Eastward)",TOTAL:3,ISSUED:3,REMAIN:0,PROG:100},
      {DESCRIPTION:"57 - Waste Water Pump Station",TOTAL:74,ISSUED:20,REMAIN:54,PROG:27.03},
      {DESCRIPTION:"58 - Overall Plot Plan",TOTAL:154,ISSUED:18,REMAIN:136,PROG:11.69},
      {DESCRIPTION:"58-Fence, Gate and Gate house",TOTAL:7,ISSUED:0,REMAIN:7,PROG:0},
      {DESCRIPTION:"60 - PP Polymers Common",TOTAL:302,ISSUED:189,REMAIN:113,PROG:62.58},
      {DESCRIPTION:"61 - PP Line 1 (CPP-1)",TOTAL:222,ISSUED:213,REMAIN:9,PROG:95.95},
      {DESCRIPTION:"62 - PP Line 2 (CPP-2)",TOTAL:127,ISSUED:120,REMAIN:7,PROG:94.49},
      {DESCRIPTION:"66 - Overall Interconnecting Pipe",TOTAL:623,ISSUED:205,REMAIN:418,PROG:32.91},
      {DESCRIPTION:"67 - Overall Electrical System",TOTAL:14,ISSUED:11,REMAIN:3,PROG:78.57},
      {DESCRIPTION:"68 - Overall Telecom",TOTAL:22,ISSUED:20,REMAIN:2,PROG:90.91},
      {DESCRIPTION:"69 - Overall Instrument System",TOTAL:19,ISSUED:18,REMAIN:1,PROG:94.74},
      {DESCRIPTION:"70 - Storage Tank Farm",TOTAL:1132,ISSUED:775,REMAIN:357,PROG:68.46},
      {DESCRIPTION:"71 - Foam Station",TOTAL:40,ISSUED:16,REMAIN:24,PROG:40},
      {DESCRIPTION:"72 - Chemicals Storage",TOTAL:102,ISSUED:41,REMAIN:61,PROG:40.2},
      {DESCRIPTION:"73 - Acid and Caustic Station",TOTAL:13,ISSUED:11,REMAIN:2,PROG:84.62},
      {DESCRIPTION:"74 - Water and Firewater Pump Station 1",TOTAL:74,ISSUED:39,REMAIN:35,PROG:52.7},
      {DESCRIPTION:"76 - Water and Firewater Pump Station 2",TOTAL:65,ISSUED:31,REMAIN:34,PROG:47.69},
      {DESCRIPTION:"77 - Storm Water Pump Station and Accident Buffer Basin",TOTAL:71,ISSUED:40,REMAIN:31,PROG:56.34},
      {DESCRIPTION:"78 - Main Substation",TOTAL:251,ISSUED:251,REMAIN:0,PROG:100},
      {DESCRIPTION:"78 - Main Substations",TOTAL:336,ISSUED:0,REMAIN:336,PROG:0},
      {DESCRIPTION:"79 - FF & Gas Protection Station",TOTAL:74,ISSUED:35,REMAIN:39,PROG:47.3},
      {DESCRIPTION:"81 - Steam Generation (Boiler)",TOTAL:299,ISSUED:143,REMAIN:156,PROG:47.83},
      {DESCRIPTION:"82 - Condensate Collecting and Treatment",TOTAL:19,ISSUED:18,REMAIN:1,PROG:94.74},
      {DESCRIPTION:"83 - Boiler Feedwater System",TOTAL:24,ISSUED:22,REMAIN:2,PROG:91.67},
      {DESCRIPTION:"85 - Maintenance & Warehouse",TOTAL:47,ISSUED:19,REMAIN:28,PROG:40.43},
      {DESCRIPTION:"87 - Demin and Polished Water",TOTAL:206,ISSUED:85,REMAIN:121,PROG:41.26},
      {DESCRIPTION:"89 - Loading/Unloading Station",TOTAL:99,ISSUED:49,REMAIN:50,PROG:49.49},
      {DESCRIPTION:"90 - Flare Systems and Flare Gas Recovery",TOTAL:264,ISSUED:139,REMAIN:125,PROG:52.65},
      {DESCRIPTION:"91 - Cooling Water System 1",TOTAL:167,ISSUED:92,REMAIN:75,PROG:55.09},
      {DESCRIPTION:"92 - Cooling Water System 2",TOTAL:154,ISSUED:70,REMAIN:84,PROG:45.45},
      {DESCRIPTION:"94 - Air Compression Station",TOTAL:175,ISSUED:83,REMAIN:92,PROG:47.43},
      {DESCRIPTION:"97 - Waste Water Pre-Treatment Unit",TOTAL:9,ISSUED:0,REMAIN:9,PROG:0},
      {DESCRIPTION:"97 - Wastewater Pre-Treatment Unit",TOTAL:134,ISSUED:77,REMAIN:57,PROG:57.46},
      {DESCRIPTION:"98 - Management Facilities",TOTAL:519,ISSUED:195,REMAIN:324,PROG:37.57},
      {DESCRIPTION:"NA",TOTAL:233,ISSUED:0,REMAIN:233,PROG:0}
  ],
}