import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1500,
      CanvasHeight: 1050,
      CanvasBorderWeight: 0.2,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 1,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

    Queries: {

      SQL1:  [ // Block code
        { BLOCK: '0001', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '0002', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '0003', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '0004', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '0005', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '0006', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '0007', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '0008', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '0009', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '001', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '0010', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '0011', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '0012', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '0013', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '0014', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '0015', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '0016', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '002', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '0020', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '003', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '004', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '005', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '006', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '007', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '008', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '009', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '010', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '011', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '012', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '013', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '014', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '015', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '016', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '017', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '018', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '019', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '020', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '021', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '022', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '023', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '024', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '025', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '026', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '027', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '028', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '029', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '030', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '031', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '032', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '033', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '034', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '035', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '036', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '037', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '038', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '039', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '040', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '041', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '042', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '043', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '044', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '045', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '046', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '047', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '048', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '049', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '051', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '052', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '053', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '054', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '055', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '056', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '057', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '058', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '060', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '061', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '062', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '063', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '064', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '065', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '066', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '067', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '068', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '069', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '070', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '071', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '072', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '073', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '074', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '075', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '076', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '077', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '078', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '079', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '080', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '081', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '082', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '083', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '084', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '085', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '086', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '087', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '088', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '089', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '090', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '091', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '092', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '093', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '094', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '095', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '096', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '097', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '098', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '101', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '103', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '10B', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '10C', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '11C', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '123', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '124', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '125', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '126', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '12A', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '12C', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '133', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '134', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '135', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '136', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '139', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '13A', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '13C', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '143', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '14A', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '14C', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '153', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '15A', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '15C', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '180', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '190', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '1901', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '1902', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '1903', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '1904', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '1905', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '1906', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '1907', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '1908', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '1909', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '20A', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '20B', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '20C', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '20D', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '20E', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '20F', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '20G', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '20H', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '20I', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '20J', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '20K', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '20L', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '21A', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '21C', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '21E', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '21G', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '21H', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '21I', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '21J', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '21K', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '21L', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '21V', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '22A', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '22C', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '22E', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '22G', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '22H', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '22I', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '22J', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '22K', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '22L', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '22V', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '23V', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '24V', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '25V', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '26F', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '26V', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '27F', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '280', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '28F', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '290', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '2901', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '2902', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '2903', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '2904', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '2905', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '2906', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '2907', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '2908', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '29F', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '301', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '302', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '303', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '304', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '305', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '306', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '30A', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '30C', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '30E', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '30G', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '30I', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '30K', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '311', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '312', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '313', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '314', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '315', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '316', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '31A', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '31C', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '31E', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '31G', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '31I', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '31K', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '3903', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '3904', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '3905', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '3906', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '3907', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '3908', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '40A', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '40B', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '40C', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '40D', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '40E', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '40F', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '40G', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '40H', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '40I', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '40J', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '40K', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '40L', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '4904', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '4905', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '4907', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '4908', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '50A', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '50C', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '50E', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '50G', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '50I', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '50K', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '51A', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '51C', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '51E', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '51G', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '51I', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '51K', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '5904', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '5905', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '5907', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '61A', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '61B', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '61F', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '62A', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '62B', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '62F', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '63A', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '63B', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '63F', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '64A', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '64B', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '64F', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '65A', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '65F', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '66A', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '66F', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '67A', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '67F', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '68A', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '68F', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '6907', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '69A', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '69F', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '721', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '731', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '740', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '741', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '742', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '743', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '744', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '745', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '746', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '747', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '748', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '749', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '750', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '751', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '752', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '753', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '754', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '755', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '756', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '757', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '758', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '759', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '760', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '761', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '762', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '763', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '764', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '765', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '766', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '767', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '768', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '769', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '770', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '771', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '772', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '773', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '774', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '775', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '776', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '777', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '778', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '779', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '780', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '781', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '782', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '783', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '784', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '785', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '786', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '787', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '788', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '789', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '790', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '7907', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '791', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '80A', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '80B', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '80C', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '80D', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '80E', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '80F', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '80G', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '80H', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '80I', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '80J', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '80K', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '80L', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '841', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '842', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '843', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '844', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '845', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '846', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '847', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '851', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '852', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '853', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '854', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '855', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '856', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '857', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '881', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '882', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '883', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '884', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '885', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '886', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '887', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '8907', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '891', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '892', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '893', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '894', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '895', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '896', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '897', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '901', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '902', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '903', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '904', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '905', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '906', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '907', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '908', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '909', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '911', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '912', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '913', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '914', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '915', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '916', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '917', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '918', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '921', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '922', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '923', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '924', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '925', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '926', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '927', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '928', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '931', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '932', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '933', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '934', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '935', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '936', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '937', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '938', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '941', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '942', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '943', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '944', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '945', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '946', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '947', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '948', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '951', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '952', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '953', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '954', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '955', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '956', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '957', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '958', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '959', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '961', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '962', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '963', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '964', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '965', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '966', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '971', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '972', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '973', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '9907', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '991', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '992', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '993', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '994', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '995', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '996', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '997', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: 'E1D', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: 'E1M1', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: 'E2D', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: 'E2M1', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: 'E5A1', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: 'E5A2', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: 'E5B', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: 'E5C', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: 'E5D', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: 'E5E', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: 'LDA1', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: 'LDA2', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: 'LDA3', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: 'ME01DA', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'ME01DB', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'ME01DC', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'ME02DA', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'ME02DB', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'ME02DC', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'ME04DA', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'ME04DB', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'MS1', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: 'MS2', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: 'MW01DA', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'MW01DB', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'MW01DC', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'MW01DD', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'MW02DA', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'MW02DB', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'MW02DC', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'MW02DD', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'MW04DA', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'MW04DD', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'MW05DA', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'MW05DB', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'MW05DC', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'TSRC01', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'TSRC02', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'TSRC03', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'TSRC04', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'TSRC05', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'W1E', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: 'W2M1', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: 'W5M1', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', }
        
      ],
      SQL2: //Status barchart
        [
          {LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_TOTAL:39,PO_ACTUAL:23,FAT_TOTAL:39,FAT_ACTUAL:23,IRN_TOTAL:39,IRN_ACTUAL:23,ONSITE_TOTAL:39,ONSITE_ACTUAL:6,INST_TOTAL:39,INST_ACTUAL:0,NOT_PO_CNT:16,ETA_PAST_FROM_ROS:10,ETA_PAST_FROM_UPPERBLOCK:0},
          {LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_TOTAL:27,PO_ACTUAL:20,FAT_TOTAL:27,FAT_ACTUAL:1,IRN_TOTAL:27,IRN_ACTUAL:1,ONSITE_TOTAL:27,ONSITE_ACTUAL:1,INST_TOTAL:27,INST_ACTUAL:0,NOT_PO_CNT:7,ETA_PAST_FROM_ROS:18,ETA_PAST_FROM_UPPERBLOCK:0},
          {LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_TOTAL:26,PO_ACTUAL:15,FAT_TOTAL:26,FAT_ACTUAL:15,IRN_TOTAL:26,IRN_ACTUAL:15,ONSITE_TOTAL:26,ONSITE_ACTUAL:11,INST_TOTAL:26,INST_ACTUAL:0,NOT_PO_CNT:11,ETA_PAST_FROM_ROS:2,ETA_PAST_FROM_UPPERBLOCK:0},
          {LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_TOTAL:56,PO_ACTUAL:29,FAT_TOTAL:56,FAT_ACTUAL:17,IRN_TOTAL:56,IRN_ACTUAL:17,ONSITE_TOTAL:56,ONSITE_ACTUAL:16,INST_TOTAL:56,INST_ACTUAL:0,NOT_PO_CNT:27,ETA_PAST_FROM_ROS:11,ETA_PAST_FROM_UPPERBLOCK:0},
          {LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ06",PO_TOTAL:33,PO_ACTUAL:4,FAT_TOTAL:33,FAT_ACTUAL:1,IRN_TOTAL:33,IRN_ACTUAL:1,ONSITE_TOTAL:33,ONSITE_ACTUAL:0,INST_TOTAL:33,INST_ACTUAL:0,NOT_PO_CNT:29,ETA_PAST_FROM_ROS:3,ETA_PAST_FROM_UPPERBLOCK:0},
          {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_TOTAL:40,PO_ACTUAL:17,FAT_TOTAL:40,FAT_ACTUAL:16,IRN_TOTAL:40,IRN_ACTUAL:16,ONSITE_TOTAL:40,ONSITE_ACTUAL:6,INST_TOTAL:40,INST_ACTUAL:0,NOT_PO_CNT:23,ETA_PAST_FROM_ROS:6,ETA_PAST_FROM_UPPERBLOCK:0},
          {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_TOTAL:76,PO_ACTUAL:23,FAT_TOTAL:76,FAT_ACTUAL:16,IRN_TOTAL:76,IRN_ACTUAL:16,ONSITE_TOTAL:76,ONSITE_ACTUAL:5,INST_TOTAL:76,INST_ACTUAL:0,NOT_PO_CNT:53,ETA_PAST_FROM_ROS:15,ETA_PAST_FROM_UPPERBLOCK:0}

      ],
    },

    Text: {
      Text: [
        { text: 'Note', x: 1350, y: 20, font: 'roboto', class: 'regular', size: 11, color: '#44A9DF', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: 1350, y: 30, font: 'roboto', class: 'regular', size: 11, color: '#757575', align: 'start' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: 32,
          y: 100,
          font: 'roboto',
          style: 'regular',
          size: 10,
          color: '#757575',
          align: 'start'
        },
      ]
    },

    DataItems: [
      {NO:1,TAG:"21-IDB-671431",TAG_DESCR:"HER - UPS 1h ICSS",PARENT_TAG:"21-IDB-671431",PROCUREDBY:"TPFMC",DIMENSION:"2100 x 800 x 800",WEIGHT:"500",SN:1,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"S2020-12-17",IRN_FORECAST:"TBD",ETA_PLAN:"S2021-02-25",ETA_FORECAST:"TBD",ROS:"S2019-01-01",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-786,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS04",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"HER - UPS 1h ICSS",ROOM:"Instrument Room - MS 1st Level"},
      {NO:13,TAG:"21-IMC-601302",TAG_DESCR:"HER SIS IO CABINET",PARENT_TAG:"21-IMC-601302",MR_TAG:"MS002-EL-REQ-012-93001",PROCUREDBY:"TPFMC",DISC:"Electrical",DIMENSION:"2100 x 800 x 800",WEIGHT:"630",SN:14,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"Q2019-08-11",PO_FORECAST:"Q2020-02-20",PO_ACTUAL:"Q2020-02-20",FAT_PLAN:"Q2020-12-22",FAT_FORECAST:"TBD",IRN_PLAN:"Q2020-12-29",IRN_FORECAST:"TBD",ETA_PLAN:"Q2021-01-23",ETA_FORECAST:"TBD",ROS:"Q2021-01-13",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-10,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS04",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"HER SIS IO CABINET",ROOM:"Instrument Room - MS 1st Level"},
      {NO:25,TAG:"21-HCP-997102",TAG_DESCR:"HVAV UCP CABINET",PARENT_TAG:"21-HCP-997102",PROCUREDBY:"COSCO",DIMENSION:"2100 x 8800 x 805",WEIGHT:"1710",SN:31,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS04",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"HVAV UCP CABINET",ROOM:"Instrument Room - MS 1st Level"},
      {NO:37,TAG:"21-EIP-667030",TAG_DESCR:"UCP For Key Service Generator",PARENT_TAG:"21-EIP-667030",MR_TAG:"MS002-ME-REQ-012-91012",PO_CODE:"PME914",PROCUREDBY:"COSCO",DISC:"Package",DIMENSION:"1600 x 636 x 2157",WEIGHT:"500",SN:12,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"R2019-06-26",PO_FORECAST:"R2019-06-26",PO_ACTUAL:"R2019-06-26",FAT_PLAN:"R2020-04-08",FAT_FORECAST:"R2020-04-08",FAT_ACTUAL:"R2020-04-08",IRN_PLAN:"R2020-05-13",IRN_FORECAST:"R2020-05-13",IRN_ACTUAL:"R2020-05-13",ETA_PLAN:"R2020-07-21",ETA_FORECAST:"R2020-07-21",ETA_ACTUAL:"R2020-07-21",ROS:"R2020-07-21",ROS_FORECAST:"R2020-07-21",ROS_ETA_DELTA:0,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS02",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"UCP For Key Service Generator",ROOM:"Electrical Rooms - MS 3rd Level"},
      {NO:49,TAG:"21-ESB-667301B",TAG_DESCR:"MAIN ESSENTIAL HV SWITCHGEAR 6.6KV BUS B",PARENT_TAG:"21-ESB-667301B",PROCUREDBY:"TPFMC",DIMENSION:"10360 x 1340 x 2696/2898",SN:31,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS02",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"MAIN ESSENTIAL HV SWITCHGEAR 6.6KV BUS B",ROOM:"Electrical Rooms - MS 3rd Level"},
      {NO:62,TAG:"21-EUP-671001",TAG_DESCR:"AC UPS A ELECTRICAL / INSTRUMENTATION",PARENT_TAG:"21-EDB-671401",PROCUREDBY:"TPFMC",DIMENSION:"6104 x 800 x 2300",WEIGHT:"3113",SN:9,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-10-27",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-09-25",ETA_PLAN:"S2020-12-28",ETA_FORECAST:"TBD",ROS:"S2020-10-22",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-67,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"AC UPS A ELECTRICAL / INSTRUMENTATION",ROOM:"Electrical Rooms - LQ"},
      {NO:63,TAG:"21-EUP-671002",TAG_DESCR:"AC UPS B ELECTRICAL / INSTRUMENTATION",PARENT_TAG:"21-EDB-671402",PROCUREDBY:"TPFMC",DIMENSION:"6104 x 800 x 2300",WEIGHT:"3113",SN:10,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-10-27",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-09-25",ETA_PLAN:"S2020-12-28",ETA_FORECAST:"TBD",ROS:"S2020-10-22",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-67,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"AC UPS B ELECTRICAL / INSTRUMENTATION",ROOM:"Electrical Rooms - LQ"},
      {NO:64,TAG:"21-EUP-671003",TAG_DESCR:"AC UPS B TELECOM chloride",PARENT_TAG:"21-EDB-671403",PROCUREDBY:"TPFMC",DIMENSION:"3579 x 800 x 1900",WEIGHT:"2086",SN:11,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-10-27",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-09-25",ETA_PLAN:"S2020-12-28",ETA_FORECAST:"TBD",ROS:"S2020-10-22",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-67,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"AC UPS B TELECOM chloride",ROOM:"Electrical Rooms - LQ"},
      {NO:65,TAG:"21-ECP-651031",TAG_DESCR:"NAVIGATION AIDSCENTRAL CONTROL PANEL",PARENT_TAG:"21-ECP-651031",PROCUREDBY:"COSCO",DIMENSION:"2429 x 500 x 1900",WEIGHT:"1700",SN:12,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"NAVIGATION AIDSCENTRAL CONTROL PANEL",ROOM:"Electrical Rooms - LQ"},
      {NO:66,TAG:"21-EIP-600022",TAG_DESCR:"IRP - INTERFACE RELAY PANEL",PARENT_TAG:"21-EIP-600022",PROCUREDBY:"TPFMC",DIMENSION:"800 x 800 x 2000",WEIGHT:"600",SN:13,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"IRP - INTERFACE RELAY PANEL",ROOM:"Electrical Rooms - LQ"},
      {NO:67,TAG:"21-EBA-671001",TAG_DESCR:"BATTERY RACK FOR AC UPS A",PARENT_TAG:"21-EBA-671001",PROCUREDBY:"TPFMC",DIMENSION:"10890 x 884 x 1243",WEIGHT:"10878",SN:16,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-07-20",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-10-16",ETA_PLAN:"S2020-09-23",ETA_FORECAST:"TBD",ROS:"S2020-10-22",ROS_FORECAST:"TBD",ROS_ETA_DELTA:29,STATUS:"IRN",STATUS_DESCR:"4.IRN",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"BATTERY RACK FOR AC UPS A",ROOM:"Electrical Rooms - LQ"},
      {NO:111,TAG:"NA",TAG_DESCR:"ICSS CONSOLE",PARENT_TAG:"NA",SN:52,POSITION:"R",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"ICSS CONSOLE",ROOM:"Central Equipment Room"},
      {NO:112,TAG:"NA",TAG_DESCR:"ICSS CONSOLE",PARENT_TAG:"NA",SN:53,POSITION:"R",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"ICSS CONSOLE",ROOM:"Central Equipment Room"},
      {NO:137,TAG:"21-TUCP-9534002",TAG_DESCR:"SCS",PARENT_TAG:"21-TUCP-9534002",DIMENSION:"2100 x 800 x 800",SN:100,POSITION:"R",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"SCS",ROOM:"Central Equipment Room"},
      {NO:138,TAG:"21-TUCP-9535002",TAG_DESCR:"FOC",PARENT_TAG:"21-TUCP-9535002",DIMENSION:"2100 x 800 x 800",SN:101,POSITION:"R",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"FOC",ROOM:"Central Equipment Room"},
      {NO:50,TAG:"NA",TAG_DESCR:"SAFETY KIT FOR HV SWITCHGEAR FOR HV",PARENT_TAG:"NA",PROCUREDBY:"TPFMC",DIMENSION:"1100 x 550 x 1000",WEIGHT:"80",SN:32,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS02",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"SAFETY KIT FOR HV SWITCHGEAR FOR HV",ROOM:"Electrical Rooms - MS 3rd Level"},
      {NO:51,TAG:"21-LH-667310",TAG_DESCR:"TRUCK FOR HV SWITCHGEAR",PARENT_TAG:"21-LH-667310",PROCUREDBY:"TPFMC",DIMENSION:"1370 x 585 x 2230",WEIGHT:"120",SN:33,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS02",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"TRUCK FOR HV SWITCHGEAR",ROOM:"Electrical Rooms - MS 3rd Level"},
      {NO:52,TAG:"21-ELP-667310",TAG_DESCR:"AC/DC FOR HV SWITCHGEAR",PARENT_TAG:"21-ELP-667310",PROCUREDBY:"TPFMC",DIMENSION:"600 x 500 x 2000",WEIGHT:"HOLD",SN:34,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS02",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"AC/DC FOR HV SWITCHGEAR",ROOM:"Electrical Rooms - MS 3rd Level"},
      {NO:53,TAG:"21-EBD-667301",TAG_DESCR:"6.6KV BUS DUCT",PARENT_TAG:"21-EBD-667301",PROCUREDBY:"TPFMC",SN:35,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-08-07",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-08-25",ETA_PLAN:"S2020-11-30",ETA_FORECAST:"TBD",ETA_ACTUAL:"S10/24/2020",ROS:"S2020-12-27",ROS_FORECAST:"TBD",ROS_ETA_DELTA:27,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS02",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"6.6KV BUS DUCT",ROOM:"Electrical Rooms - MS 3rd Level"},
      {NO:54,TAG:"21-ESB-666001",TAG_DESCR:"EMERGNCY LV SWITCHGEAR 0.69KV BUS A/B",PARENT_TAG:"21-ESB-666001",MR_TAG:"MS002-EL-REQ-012-00011",PROCUREDBY:"TPFMC",DIMENSION:"10463 x 1252 x 2261",WEIGHT:"11050",SN:1,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-10-31",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-11-26",ETA_PLAN:"S2021-01-09",ETA_FORECAST:"TBD",ROS:"S2021-01-28",ROS_FORECAST:"TBD",ROS_ETA_DELTA:19,STATUS:"IRN",STATUS_DESCR:"4.IRN",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"EMERGNCY LV SWITCHGEAR 0.69KV BUS A/B",ROOM:"Electrical Rooms - LQ"},
      {NO:55,TAG:"21-EDB-662401",TAG_DESCR:"EMERGNCY LV DISTRIBUTION BOARD 0.4KV",PARENT_TAG:"21-EDB-662401",PROCUREDBY:"TPFMC",DIMENSION:"2051 x 600 x 2261",WEIGHT:"900",SN:2,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-10-31",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-11-26",ETA_PLAN:"S2021-01-09",ETA_FORECAST:"TBD",ROS:"S2021-01-28",ROS_FORECAST:"TBD",ROS_ETA_DELTA:19,STATUS:"IRN",STATUS_DESCR:"4.IRN",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"EMERGNCY LV DISTRIBUTION BOARD 0.4KV",ROOM:"Electrical Rooms - LQ"},
      {NO:56,TAG:"21-LH-662410",TAG_DESCR:"ACB TROLLEY FOR LV SWITCHGEAR",PARENT_TAG:"21-LH-662410",PROCUREDBY:"TPFMC",DIMENSION:"650 x 576 x 1500",WEIGHT:"91",SN:3,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"ACB TROLLEY FOR LV SWITCHGEAR",ROOM:"Electrical Rooms - LQ"},
      {NO:57,TAG:"21-ETR-666001A",TAG_DESCR:"LTG & SMALL POWER TRANSFORMER 0.69/0.4KV 250KVA",PARENT_TAG:"21-ETR-666001A",PROCUREDBY:"TPFMC",DIMENSION:"1410 x 1110 x 1380",WEIGHT:"1500",SN:4,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-08-17",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-06-30",ETA_PLAN:"S2020-09-22",ETA_FORECAST:"TBD",ETA_ACTUAL:"S9/29/2020",ROS:"S2020-12-21",ROS_FORECAST:"TBD",ROS_ETA_DELTA:90,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"LTG & SMALL POWER TRANSFORMER 0.69/0.4KV 250KVA",ROOM:"Electrical Rooms - LQ"},
      {NO:58,TAG:"21-ETR-666001B",TAG_DESCR:"LTG & SMALL POWER TRANSFORMER 0.69/0.4KV 250KVA",PARENT_TAG:"21-ETR-666001B",PROCUREDBY:"TPFMC",DIMENSION:"1410 x 1110 x 1380",WEIGHT:"1500",SN:5,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-08-17",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-06-30",ETA_PLAN:"S2020-09-22",ETA_FORECAST:"TBD",ETA_ACTUAL:"S9/29/2020",ROS:"S2020-12-21",ROS_FORECAST:"TBD",ROS_ETA_DELTA:90,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"LTG & SMALL POWER TRANSFORMER 0.69/0.4KV 250KVA",ROOM:"Electrical Rooms - LQ"},
      {NO:59,TAG:"21-ECP-605021",TAG_DESCR:"OWS/COMMUNICATION CABINET",PARENT_TAG:"21-ECP-605021",PROCUREDBY:"TPFMC",DIMENSION:"800 x 800 x 2100",WEIGHT:"600",SN:6,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-11-04",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-10-29",ETA_PLAN:"S2021-01-01",ETA_FORECAST:"TBD",ROS:"S2020-10-09",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-84,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"OWS/COMMUNICATION CABINET",ROOM:"Electrical Rooms - LQ"},
      {NO:60,TAG:"21-ECP-605022",TAG_DESCR:"REMOTE I/O CABINET",PARENT_TAG:"21-ECP-605022",PROCUREDBY:"TPFMC",DIMENSION:"800 x 800 x 2100",WEIGHT:"600",SN:7,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-11-04",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-10-29",ETA_PLAN:"S2021-01-01",ETA_FORECAST:"TBD",ROS:"S2020-10-09",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-84,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"REMOTE I/O CABINET",ROOM:"Electrical Rooms - LQ"},
      {NO:61,TAG:"21-EIP-600021",TAG_DESCR:"IRP - INTERFACE RELAY PANEL",PARENT_TAG:"21-EIP-600021",PROCUREDBY:"TPFMC",DIMENSION:"800 x 800 x 2000",WEIGHT:"600",SN:8,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"IRP - INTERFACE RELAY PANEL",ROOM:"Electrical Rooms - LQ"},
      {NO:139,TAG:"21-TUCP-9564002",TAG_DESCR:"ACS",PARENT_TAG:"21-TUCP-9564002",DIMENSION:"2100 x 800 x 800",SN:102,POSITION:"R",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"ACS",ROOM:"Central Equipment Room"},
      {NO:140,TAG:"21-TUCP-9544002",TAG_DESCR:"CCTV",PARENT_TAG:"21-TUCP-9544002",DIMENSION:"2100 x 800 x 800",SN:103,POSITION:"R",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"CCTV",ROOM:"Central Equipment Room"},
      {NO:141,TAG:"21-TUCP-9544003",TAG_DESCR:"CCTV",PARENT_TAG:"21-TUCP-9544003",DIMENSION:"2100 x 800 x 800",SN:104,POSITION:"R",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"CCTV",ROOM:"Central Equipment Room"},
      {NO:68,TAG:"21-EBA-671002",TAG_DESCR:"BATTERY RACK FOR AC UPS B",PARENT_TAG:"21-EBA-671002",PROCUREDBY:"TPFMC",DIMENSION:"10890 x 884 x 1243",WEIGHT:"10878",SN:17,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-07-20",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-10-16",ETA_PLAN:"S2020-09-23",ETA_FORECAST:"TBD",ROS:"S2020-10-22",ROS_FORECAST:"TBD",ROS_ETA_DELTA:29,STATUS:"IRN",STATUS_DESCR:"4.IRN",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"BATTERY RACK FOR AC UPS B",ROOM:"Electrical Rooms - LQ"},
      {NO:143,TAG:"21-TUCP-6210004",TAG_DESCR:"PAGA",PARENT_TAG:"21-TUCP-6210004",DIMENSION:"2100 x 800 x 800",SN:106,POSITION:"R",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"PAGA",ROOM:"Central Equipment Room"},
      {NO:153,TAG:"21-TUCP-9544001",TAG_DESCR:"CCTV",PARENT_TAG:"21-TUCP-9544001",DIMENSION:"800 x 800",SN:6,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ06",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",TAG_ORIGINAL_DESCR:"CCTV",ROOM:"Telecomms Room"},
      {NO:158,TAG:"21-TUCP-6210001",TAG_DESCR:"PAGA AMP",PARENT_TAG:"21-TUCP-6210001",DIMENSION:"800 x 800",SN:11,POSITION:"R",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ06",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",TAG_ORIGINAL_DESCR:"PAGA AMP",ROOM:"Telecomms Room"},
      {NO:162,TAG:"21-TUCP-9554001",TAG_DESCR:"METEO",PARENT_TAG:"21-TUCP-9554001",DIMENSION:"800 x 800",SN:15,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ06",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",TAG_ORIGINAL_DESCR:"METEO",ROOM:"Telecomms Room"},
      {NO:184,TAG:"21-ETR-667304A",TAG_DESCR:"MAIN ESSENTIAL TRANSFORMER 6.6/0.72kV 3150kVA",PARENT_TAG:"21-ETR-667304A",DIMENSION:"2850 x 1800 x 2700",WEIGHT:"7800",SN:3,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-08-17",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-06-30",ETA_PLAN:"S2020-09-22",ETA_FORECAST:"TBD",ETA_ACTUAL:"S9/29/2020",ROS:"S2020-12-21",ROS_FORECAST:"TBD",ROS_ETA_DELTA:90,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"MAIN ESSENTIAL TRANSFORMER 6.6/0.72kV 3150kVA",ROOM:"Electrical HV Room - Level 1"},
      {NO:185,TAG:"21-ETR-667304B",TAG_DESCR:"MAIN ESSENTIAL TRANSFORMER 6.6/0.72kV 3150kVA",PARENT_TAG:"21-ETR-667304B",DIMENSION:"2850 x 1800 x 2700",WEIGHT:"7800",SN:4,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-08-17",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-06-30",ETA_PLAN:"S2020-09-22",ETA_FORECAST:"TBD",ETA_ACTUAL:"S9/29/2020",ROS:"S2020-12-21",ROS_FORECAST:"TBD",ROS_ETA_DELTA:90,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"MAIN ESSENTIAL TRANSFORMER 6.6/0.72kV 3150kVA",ROOM:"Electrical HV Room - Level 1"},
      {NO:186,TAG:"21-EER-861101",TAG_DESCR:"EARTHING RESISTOR FOR GTG 1",PARENT_TAG:"21-EER-861101",MR_TAG:"MS002-ME-REQ-012-01002",DIMENSION:"1288 x 934 x 1220",WEIGHT:"275",SN:5,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"EARTHING RESISTOR FOR GTG 1",ROOM:"Electrical HV Room - Level 1"},
      {NO:187,TAG:"21-EER-861201",TAG_DESCR:"EARTHING RESISTOR FOR GTG 2",PARENT_TAG:"21-EER-861201",MR_TAG:"MS002-ME-REQ-012-01002",DIMENSION:"1288 x 934 x 1220",WEIGHT:"275",SN:6,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"EARTHING RESISTOR FOR GTG 2",ROOM:"Electrical HV Room - Level 1"},
      {NO:188,TAG:"21-EER-861301",TAG_DESCR:"EARTHING RESISTOR FOR GTG 3",PARENT_TAG:"21-EER-861301",MR_TAG:"MS002-ME-REQ-012-01002",DIMENSION:"1288 x 934 x 1220",WEIGHT:"275",SN:7,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"EARTHING RESISTOR FOR GTG 3",ROOM:"Electrical HV Room - Level 1"},
      {NO:189,TAG:"21-EER-861401",TAG_DESCR:"EARTHING RESISTOR FOR GTG 4",PARENT_TAG:"21-EER-861401",MR_TAG:"MS002-ME-REQ-012-01002",DIMENSION:"1288 x 934 x 1220",WEIGHT:"275",SN:8,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"EARTHING RESISTOR FOR GTG 4",ROOM:"Electrical HV Room - Level 1"},
      {NO:190,TAG:"21-ESB-877001A",TAG_DESCR:"NORMAL MAIN HV SWITCHGEAR 6.6kV BUS A - Combined 3",PARENT_TAG:"21-ESB-877001A",MR_TAG:"MS002-EL-REQ-012-00011",DIMENSION:"9260 x 1390 x 2696",WEIGHT:"12860",SN:11,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-11-24",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-11-26",ETA_PLAN:"S2021-02-02",ETA_FORECAST:"TBD",ROS:"S2021-01-28",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-5,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"NORMAL MAIN HV SWITCHGEAR 6.6kV BUS A - Combined 3",ROOM:"Electrical HV Room - Level 1"},
      {NO:191,TAG:"21-ESB-877001B",TAG_DESCR:"NORMAL MAIN HV SWITCHGEAR 6.6kV BUS B - Combined 3",PARENT_TAG:"21-ESB-877001B",MR_TAG:"MS002-EL-REQ-012-00011",DIMENSION:"9910 x 1390 x 2696",WEIGHT:"15390",SN:12,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-11-24",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-11-26",ETA_PLAN:"S2021-02-02",ETA_FORECAST:"TBD",ROS:"S2021-01-28",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-5,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"NORMAL MAIN HV SWITCHGEAR 6.6kV BUS B - Combined 3",ROOM:"Electrical HV Room - Level 1"},
      {NO:192,TAG:"NA",TAG_DESCR:"SAFETY KIT FOR HV SWITCHGEAR",PARENT_TAG:"NA",DIMENSION:"700 x 370 x 305",WEIGHT:"18.5",SN:13,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"SAFETY KIT FOR HV SWITCHGEAR",ROOM:"Electrical HV Room - Level 1"},
      {NO:193,TAG:"21-LH-877010",TAG_DESCR:"TRUCK FOR HV SWITCHGEAR",PARENT_TAG:"21-LH-877010",DIMENSION:"1370 x 585 x 2230",WEIGHT:"161",SN:14,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"TRUCK FOR HV SWITCHGEAR",ROOM:"Electrical HV Room - Level 1"},
      {NO:194,TAG:"21-EFD-256001A",TAG_DESCR:"VARIABLE SPEED DRIVE LEAN MEG INJECTION PUMP A",PARENT_TAG:"21-EFD-256001A",MR_TAG:"MS002-ME-REQ-012-01007",DIMENSION:"3630 x 1296 x 2614",WEIGHT:"3928",SN:16,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"VARIABLE SPEED DRIVE LEAN MEG INJECTION PUMP A",ROOM:"Electrical HV Room - Level 1"},
      {NO:195,TAG:"21-EFD-256001B",TAG_DESCR:"VARIABLE SPEED DRIVE LEAN MEG INJECTION PUMP B",PARENT_TAG:"21-EFD-256001B",MR_TAG:"MS002-ME-REQ-012-01007",DIMENSION:"3630 x 1296 x 2614",WEIGHT:"3928",SN:17,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"VARIABLE SPEED DRIVE LEAN MEG INJECTION PUMP B",ROOM:"Electrical HV Room - Level 1"},
      {NO:196,TAG:"21-ETP-253001",TAG_DESCR:"MEG HEATER CONTROL PANEL",PARENT_TAG:"21-ETP-253001",DIMENSION:"5395 x 995 x 2908",WEIGHT:"4400",SN:18,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"S2021-04-19",IRN_FORECAST:"TBD",ETA_PLAN:"S2021-04-19",ETA_FORECAST:"TBD",ROS:"S2021-05-29",ROS_FORECAST:"TBD",ROS_ETA_DELTA:40,STATUS:"PO",STATUS_DESCR:"2.PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"MEG HEATER CONTROL PANEL",ROOM:"Electrical HV Room - Level 1"},
      {NO:197,TAG:"21-EFD-304003",TAG_DESCR:"VARIABLE SPEED DRIVE MP/HP FLASH GAS COMPRESSOR",PARENT_TAG:"21-EFD-304003",DIMENSION:"5330 x 1296 x 2877",WEIGHT:"8300",SN:19,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"VARIABLE SPEED DRIVE MP/HP FLASH GAS COMPRESSOR",ROOM:"Electrical HV Room - Level 1"},
      {NO:198,TAG:"21-UEPU-192101/02/03",TAG_DESCR:"ELECTRICAL POWER UNIT EPU A",PARENT_TAG:"21-UEPU-192101/02/03",DIMENSION:"2904 x 980 x 2300",WEIGHT:"2730",SN:20,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"ELECTRICAL POWER UNIT EPU A",ROOM:"Electrical HV Room - Level 1"},
      {NO:199,TAG:"21-UEPU-192201/02/03",TAG_DESCR:"ELECTRICAL POWER UNIT EPU B",PARENT_TAG:"21-UEPU-192201/02/03",DIMENSION:"2904 x 980 x 2300",WEIGHT:"2730",SN:21,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"ELECTRICAL POWER UNIT EPU B",ROOM:"Electrical HV Room - Level 1"},
      {NO:200,TAG:"21-ELP-877010",TAG_DESCR:"AC/DC DISTRIBUTION PANEL",PARENT_TAG:"21-ELP-877010",DIMENSION:"631 x 508 x 2111",WEIGHT:"350",SN:22,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"AC/DC DISTRIBUTION PANEL",ROOM:"Electrical HV Room - Level 1"},
      {NO:201,TAG:"21-DSH-680010",TAG_DESCR:"HSSD PANEL",PARENT_TAG:"21-DSH-680010",DIMENSION:"350 x 135 x 225",WEIGHT:"4",SN:23,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"HSSD PANEL",ROOM:"Electrical HV Room - Level 1"},
      {NO:202,TAG:"21-DSH-680011",TAG_DESCR:"HSSD PANEL",PARENT_TAG:"21-DSH-680011",DIMENSION:"350 x 135 x 225",WEIGHT:"4",SN:24,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"HSSD PANEL",ROOM:"Electrical HV Room - Level 1"},
      {NO:203,TAG:"21-EBA-671010",TAG_DESCR:"BATTERY RACK FOR AC UPS A LOADS MODULES",PARENT_TAG:"21-EBA-671010",DIMENSION:"10890 x 684 x 1243",WEIGHT:"7590",SN:31,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-07-20",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-10-16",ETA_PLAN:"S2020-09-23",ETA_FORECAST:"TBD",ROS:"S2020-10-22",ROS_FORECAST:"TBD",ROS_ETA_DELTA:29,STATUS:"IRN",STATUS_DESCR:"4.IRN",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"BATTERY RACK FOR AC UPS A LOADS MODULES",ROOM:"Electrical HV Room - Level 1"},
      {NO:204,TAG:"21-EBA-671011",TAG_DESCR:"BATTERY RACK FOR AC UPS B LOADS MODULES",PARENT_TAG:"21-EBA-671011",DIMENSION:"10890 x 684 x 1243",WEIGHT:"7590",SN:32,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-07-20",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-10-16",ETA_PLAN:"S2020-09-23",ETA_FORECAST:"TBD",ROS:"S2020-10-22",ROS_FORECAST:"TBD",ROS_ETA_DELTA:29,STATUS:"IRN",STATUS_DESCR:"4.IRN",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"BATTERY RACK FOR AC UPS B LOADS MODULES",ROOM:"Electrical HV Room - Level 1"},
      {NO:205,TAG:"21-EBA-671012",TAG_DESCR:"BATTERY RACK FOR AC UPS A SUBSEA",PARENT_TAG:"21-EBA-671012",DIMENSION:"2130 x 760 x 1259",WEIGHT:"1390",SN:33,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-07-20",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-10-16",ETA_PLAN:"S2020-09-23",ETA_FORECAST:"TBD",ROS:"S2020-10-22",ROS_FORECAST:"TBD",ROS_ETA_DELTA:29,STATUS:"IRN",STATUS_DESCR:"4.IRN",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"BATTERY RACK FOR AC UPS A SUBSEA",ROOM:"Electrical HV Room - Level 1"},
      {NO:206,TAG:"21-EBA-671013",TAG_DESCR:"BATTERY RACK FOR AC UPS B SUBSEA",PARENT_TAG:"21-EBA-671013",DIMENSION:"2130 x 760 x 1259",WEIGHT:"1390",SN:34,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-07-20",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-10-16",ETA_PLAN:"S2020-09-23",ETA_FORECAST:"TBD",ROS:"S2020-10-22",ROS_FORECAST:"TBD",ROS_ETA_DELTA:29,STATUS:"IRN",STATUS_DESCR:"4.IRN",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"BATTERY RACK FOR AC UPS B SUBSEA",ROOM:"Electrical HV Room - Level 1"},
      {NO:207,TAG:"21-EBA-861101",TAG_DESCR:"BATTERY RACK FOR GTG 1",PARENT_TAG:"21-EBA-861101",MR_TAG:"MS002-ME-REQ-012-01002",DIMENSION:"2250 x 740 x 1681",WEIGHT:"1346",SN:37,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"BATTERY RACK FOR GTG 1",ROOM:"Electrical HV Room - Level 1"},
      {NO:208,TAG:"21-EBA-861201",TAG_DESCR:"BATTERY RACK FOR GTG 2",PARENT_TAG:"21-EBA-861201",MR_TAG:"MS002-ME-REQ-012-01002",DIMENSION:"2250 x 740 x 1681",WEIGHT:"1346",SN:38,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"BATTERY RACK FOR GTG 2",ROOM:"Electrical HV Room - Level 1"},
      {NO:209,TAG:"21-EBA-861301",TAG_DESCR:"BATTERY RACK FOR GTG 3",PARENT_TAG:"21-EBA-861301",MR_TAG:"MS002-ME-REQ-012-01002",DIMENSION:"2250 x 740 x 1681",WEIGHT:"1346",SN:39,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"BATTERY RACK FOR GTG 3",ROOM:"Electrical HV Room - Level 1"},
      {NO:210,TAG:"21-EBA-861401",TAG_DESCR:"BATTERY RACK FOR GTG 4",PARENT_TAG:"21-EBA-861401",MR_TAG:"MS002-ME-REQ-012-01002",DIMENSION:"2250 x 740 x 1681",WEIGHT:"1346",SN:40,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"BATTERY RACK FOR GTG 4",ROOM:"Electrical HV Room - Level 1"},
      {NO:211,TAG:"21-EIS-671010",TAG_DESCR:"BATTERY CIRCUIT BREAKER A LOAD MODULES",PARENT_TAG:"21-EIS-671010",DIMENSION:"435 x 340 x 1278",WEIGHT:"95",SN:41,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-10-27",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-09-25",ETA_PLAN:"S2020-12-28",ETA_FORECAST:"TBD",ROS:"S2020-10-22",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-67,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"BATTERY CIRCUIT BREAKER A LOAD MODULES",ROOM:"Electrical HV Room - Level 1"},
      {NO:212,TAG:"21-EIS-671011",TAG_DESCR:"BATTERY CIRCUIT BREAKER B LOAD MODULES",PARENT_TAG:"21-EIS-671011",DIMENSION:"435 x 340 x 1278",WEIGHT:"95",SN:42,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-10-27",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-09-25",ETA_PLAN:"S2020-12-28",ETA_FORECAST:"TBD",ROS:"S2020-10-22",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-67,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"BATTERY CIRCUIT BREAKER B LOAD MODULES",ROOM:"Electrical HV Room - Level 1"},
      {NO:213,TAG:"21-EIS-671012",TAG_DESCR:"BATTERY CIRCUIT BREAKER SUBSEA",PARENT_TAG:"21-EIS-671012",DIMENSION:"435 x 340 x 1058",WEIGHT:"70",SN:43,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-10-27",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-09-25",ETA_PLAN:"S2020-12-28",ETA_FORECAST:"TBD",ROS:"S2020-10-22",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-67,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"BATTERY CIRCUIT BREAKER SUBSEA",ROOM:"Electrical HV Room - Level 1"},
      {NO:214,TAG:"21-EIS-671013",TAG_DESCR:"BATTERY CIRCUIT BREAKER SUBSEA",PARENT_TAG:"21-EIS-671013",DIMENSION:"435 x 340 x 1058",WEIGHT:"70",SN:44,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-10-27",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-09-25",ETA_PLAN:"S2020-12-28",ETA_FORECAST:"TBD",ROS:"S2020-10-22",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-67,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"BATTERY CIRCUIT BREAKER SUBSEA",ROOM:"Electrical HV Room - Level 1"},
      {NO:215,TAG:"HOLD",TAG_DESCR:"BATTERY CIRCUIT BREAKER FUTURE SUBSEA",PARENT_TAG:"HOLD",SN:45,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"N2020-12-08",PO_FORECAST:"N2020-12-08",PO_ACTUAL:"N2019-09-12",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-11-20",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-08-03",ETA_PLAN:"N2022-02-18",ETA_FORECAST:"TBD",ETA_ACTUAL:"N2020-07-08",ROS:"N2022-03-14",ROS_FORECAST:"TBD",ROS_ETA_DELTA:24,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"BATTERY CIRCUIT BREAKER FUTURE SUBSEA",ROOM:"Electrical HV Room - Level 1"},
      {NO:216,TAG:"HOLD",TAG_DESCR:"BATTERY CIRCUIT BREAKER FUTURE SUBSEA",PARENT_TAG:"HOLD",SN:46,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"N2020-12-08",PO_FORECAST:"N2020-12-08",PO_ACTUAL:"N2019-09-12",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-11-20",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-08-03",ETA_PLAN:"N2022-02-18",ETA_FORECAST:"TBD",ETA_ACTUAL:"N2020-07-08",ROS:"N2022-03-14",ROS_FORECAST:"TBD",ROS_ETA_DELTA:24,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"BATTERY CIRCUIT BREAKER FUTURE SUBSEA",ROOM:"Electrical HV Room - Level 1"},
      {NO:217,TAG:"21-EIS-861101",TAG_DESCR:"GTG1 120 VDC ISOLATION BREAKER BOX",PARENT_TAG:"21-EIS-861101",DIMENSION:"540 x 335 x 743",WEIGHT:"132",SN:47,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"GTG1 120 VDC ISOLATION BREAKER BOX",ROOM:"Electrical HV Room - Level 1"},
      {NO:218,TAG:"21-EIS-861201",TAG_DESCR:"GTG2 120 VDC ISOLATION BREAKER BOX",PARENT_TAG:"21-EIS-861201",DIMENSION:"540 x 335 x 743",WEIGHT:"132",SN:48,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"GTG2 120 VDC ISOLATION BREAKER BOX",ROOM:"Electrical HV Room - Level 1"},
      {NO:219,TAG:"21-EIS-861301",TAG_DESCR:"GTG3 120 VDC ISOLATION BREAKER BOX",PARENT_TAG:"21-EIS-861301",DIMENSION:"540 x 335 x 743",WEIGHT:"132",SN:49,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"GTG3 120 VDC ISOLATION BREAKER BOX",ROOM:"Electrical HV Room - Level 1"},
      {NO:220,TAG:"21-EIS-861401",TAG_DESCR:"GTG4 120 VDC ISOLATION BREAKER BOX",PARENT_TAG:"21-EIS-861401",DIMENSION:"540 x 335 x 743",WEIGHT:"132",SN:50,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"GTG4 120 VDC ISOLATION BREAKER BOX",ROOM:"Electrical HV Room - Level 1"},
      {NO:221,TAG:"21-LH-671000",TAG_DESCR:"ATTERY HANDLING TABLE",PARENT_TAG:"21-LH-671000",DIMENSION:"910 x 500 x 1300",WEIGHT:"105",SN:51,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"ATTERY HANDLING TABLE",ROOM:"Electrical HV Room - Level 1"},
      {NO:222,TAG:"21-EUP-671010",TAG_DESCR:"AC UPS A LOADS MODULES 120kVA",PARENT_TAG:"21-EUP-671010",DIMENSION:"4354 x 804 x 2300",WEIGHT:"2803",SN:53,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-10-27",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-09-25",ETA_PLAN:"S2020-12-28",ETA_FORECAST:"TBD",ROS:"S2020-10-22",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-67,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"AC UPS A LOADS MODULES 120kVA",ROOM:"Electrical LV Room - Level 2"},
      {NO:223,TAG:"21-EUP-671011",TAG_DESCR:"AC UPS B LOADS MODULES 120kVA",PARENT_TAG:"21-EUP-671011",DIMENSION:"4354 x 804 x 2300",WEIGHT:"2803",SN:54,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-10-27",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-09-25",ETA_PLAN:"S2020-12-28",ETA_FORECAST:"TBD",ROS:"S2020-10-22",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-67,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"AC UPS B LOADS MODULES 120kVA",ROOM:"Electrical LV Room - Level 2"},
      {NO:224,TAG:"21-EUP-671012",TAG_DESCR:"AC UPS SUBSEA 30kVA",PARENT_TAG:"21-EUP-671012",DIMENSION:"2429 x 804 x 1900",WEIGHT:"1259",SN:56,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-10-27",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-09-25",ETA_PLAN:"S2020-12-28",ETA_FORECAST:"TBD",ROS:"S2020-10-22",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-67,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"AC UPS SUBSEA 30kVA",ROOM:"Electrical LV Room - Level 2"},
      {NO:225,TAG:"21-EUP-671013",TAG_DESCR:"AC UPS SUBSEA 31kVA",PARENT_TAG:"21-EUP-671013",DIMENSION:"2429 x 804 x 1900",WEIGHT:"1259",SN:57,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-10-27",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-09-25",ETA_PLAN:"S2020-12-28",ETA_FORECAST:"TBD",ROS:"S2020-10-22",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-67,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"AC UPS SUBSEA 31kVA",ROOM:"Electrical LV Room - Level 2"},
      {NO:226,TAG:"21-DSH-680012",TAG_DESCR:"HSSD PANEL",PARENT_TAG:"21-DSH-680012",DIMENSION:"350 x 135 x 225",WEIGHT:"10",SN:58,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"HSSD PANEL",ROOM:"Electrical LV Room - Level 2"},
      {NO:227,TAG:"21-DSH-680013",TAG_DESCR:"HSSD PANEL",PARENT_TAG:"21-DSH-680013",DIMENSION:"350 x 135 x 225",WEIGHT:"10",SN:59,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"HSSD PANEL",ROOM:"Electrical LV Room - Level 2"},
      {NO:228,TAG:"21-EBC-861101",TAG_DESCR:"DC BATTERY CHARGER GTG 1",PARENT_TAG:"21-EBC-861101",MR_TAG:"MS002-ME-REQ-012-01002",DIMENSION:"404 x 397 x 902",WEIGHT:"104",SN:60,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"DC BATTERY CHARGER GTG 1",ROOM:"Electrical LV Room - Level 2"},
      {NO:229,TAG:"21-EBC-861201",TAG_DESCR:"DC BATTERY CHARGER GTG 2",PARENT_TAG:"21-EBC-861201",MR_TAG:"MS002-ME-REQ-012-01002",DIMENSION:"404 x 397 x 902",WEIGHT:"104",SN:61,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"DC BATTERY CHARGER GTG 2",ROOM:"Electrical LV Room - Level 2"},
      {NO:230,TAG:"21-EBC-861301",TAG_DESCR:"DC BATTERY CHARGER GTG 3",PARENT_TAG:"21-EBC-861301",MR_TAG:"MS002-ME-REQ-012-01002",DIMENSION:"404 x 397 x 902",WEIGHT:"104",SN:62,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"DC BATTERY CHARGER GTG 3",ROOM:"Electrical LV Room - Level 2"},
      {NO:231,TAG:"21-EBC-861401",TAG_DESCR:"DC BATTERY CHARGER GTG 4",PARENT_TAG:"21-EBC-861401",MR_TAG:"MS002-ME-REQ-012-01002",DIMENSION:"404 x 397 x 902",WEIGHT:"104",SN:63,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"DC BATTERY CHARGER GTG 4",ROOM:"Electrical LV Room - Level 2"},
      {NO:232,TAG:"21-EDB-662402",TAG_DESCR:"EMERGENCY LIGHTING AND SMALL POWER DISTRIBUTION BOARD",PARENT_TAG:"21-EDB-662402",DIMENSION:"1000 x 600 x 2100",WEIGHT:"500",SN:64,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"EMERGENCY LIGHTING AND SMALL POWER DISTRIBUTION BOARD",ROOM:"Electrical LV Room - Level 2"},
      {NO:233,TAG:"21-ECP-605044",TAG_DESCR:"EDMCS / PMS - PATCH PANEL",PARENT_TAG:"21-ECP-605044",DIMENSION:"600 x 250 x 600",WEIGHT:"35",SN:65,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-11-04",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-10-29",ETA_PLAN:"S2021-01-01",ETA_FORECAST:"TBD",ROS:"S2020-10-09",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-84,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"EDMCS / PMS - PATCH PANEL",ROOM:"Electrical LV Room - Level 2"},
      {NO:234,TAG:"21-ESB-667402",TAG_DESCR:"ESSENTIAL LV SWITCHGEAR 0.69kV BUS A/B",PARENT_TAG:"21-ESB-667402",MR_TAG:"MS002-EL-REQ-012-00011",DIMENSION:"11462 x 1252 x 2261",WEIGHT:"13250",SN:66,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-10-31",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-11-26",ETA_PLAN:"S2021-01-09",ETA_FORECAST:"TBD",ROS:"S2021-01-28",ROS_FORECAST:"TBD",ROS_ETA_DELTA:19,STATUS:"IRN",STATUS_DESCR:"4.IRN",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"ESSENTIAL LV SWITCHGEAR 0.69kV BUS A/B",ROOM:"Electrical LV Room - Level 2"},
      {NO:235,TAG:"21-ESB-876001",TAG_DESCR:"NORMAL LV SWITCHGEAR 0.69kV BUS A/B",PARENT_TAG:"21-ESB-876001",DIMENSION:"6858 x 1252 x 2261",WEIGHT:"6700",SN:67,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-10-31",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-11-26",ETA_PLAN:"S2021-01-09",ETA_FORECAST:"TBD",ROS:"S2021-01-28",ROS_FORECAST:"TBD",ROS_ETA_DELTA:19,STATUS:"IRN",STATUS_DESCR:"4.IRN",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"NORMAL LV SWITCHGEAR 0.69kV BUS A/B",ROOM:"Electrical LV Room - Level 2"},
      {NO:236,TAG:"21-ETR-667401A",TAG_DESCR:"LTG & SMALL POWER TRANSFORMER 0.69/0.42kV 630kVA",PARENT_TAG:"21-ETR-667401A",DIMENSION:"2000 x 1200 x 1700",WEIGHT:"2500",SN:68,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-08-17",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-06-30",ETA_PLAN:"S2020-09-22",ETA_FORECAST:"TBD",ETA_ACTUAL:"S9/29/2020",ROS:"S2020-12-21",ROS_FORECAST:"TBD",ROS_ETA_DELTA:90,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"LTG & SMALL POWER TRANSFORMER 0.69/0.42kV 630kVA",ROOM:"Electrical LV Room - Level 2"},
      {NO:237,TAG:"21-ETR-667401B",TAG_DESCR:"LTG & SMALL POWER TRANSFORMER 0.69/0.42kV 630kVA",PARENT_TAG:"21-ETR-667401B",DIMENSION:"2000 x 1200 x 1700",WEIGHT:"2500",SN:69,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-08-17",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-06-30",ETA_PLAN:"S2020-09-22",ETA_FORECAST:"TBD",ETA_ACTUAL:"S9/29/2020",ROS:"S2020-12-21",ROS_FORECAST:"TBD",ROS_ETA_DELTA:90,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"LTG & SMALL POWER TRANSFORMER 0.69/0.42kV 630kVA",ROOM:"Electrical LV Room - Level 2"},
      {NO:238,TAG:"21-EDB-667602",TAG_DESCR:"NORMAL / ESSENTIAL SMALL POWER/LIGHTING DISTRIBUTION BOARD",PARENT_TAG:"21-EDB-667602",DIMENSION:"4254 x 658.5 x 2261",WEIGHT:"2800",SN:70,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-11-24",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-11-26",ETA_PLAN:"S2021-02-02",ETA_FORECAST:"TBD",ROS:"S2021-01-28",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-5,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"NORMAL / ESSENTIAL SMALL POWER/LIGHTING DISTRIBUTION BOARD",ROOM:"Electrical LV Room - Level 2"},
      {NO:239,TAG:"21-EDB-667605",TAG_DESCR:"HEAT TRACING DISTRIBUTION BOARD",PARENT_TAG:"21-EDB-667605",DIMENSION:"1000 x 600 x 2100",WEIGHT:"500",SN:71,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"HEAT TRACING DISTRIBUTION BOARD",ROOM:"Electrical LV Room - Level 2"},
      {NO:240,TAG:"21-EDB-997820",TAG_DESCR:"HVAC DISTRIBUTION BOARD",PARENT_TAG:"21-EDB-997820",DIMENSION:"600 x 250 x 1000",WEIGHT:"75",SN:72,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"HVAC DISTRIBUTION BOARD",ROOM:"Electrical LV Room - Level 2"},
      {NO:241,TAG:"21-ECP-605042",TAG_DESCR:"EDMCS / PMS - SYSTEM CABINET",PARENT_TAG:"21-ECP-605042",MR_TAG:"MS002-EL-REQ-012-00051",DIMENSION:"800 x 800 x 2000",WEIGHT:"400",SN:73,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-11-04",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-10-29",ETA_PLAN:"S2021-01-01",ETA_FORECAST:"TBD",ROS:"S2021-03-27",ROS_FORECAST:"TBD",ROS_ETA_DELTA:85,STATUS:"IRN",STATUS_DESCR:"4.IRN",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"EDMCS / PMS - SYSTEM CABINET",ROOM:"Electrical LV Room - Level 2"},
      {NO:242,TAG:"21-ECP-605043",TAG_DESCR:"EDMCS / PMS - CABINET",PARENT_TAG:"21-ECP-605043",MR_TAG:"MS002-EL-REQ-012-00051",DIMENSION:"800 x 800 x 2000",WEIGHT:"400",SN:74,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-11-04",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-10-29",ETA_PLAN:"S2021-01-01",ETA_FORECAST:"TBD",ROS:"S2020-10-09",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-84,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"EDMCS / PMS - CABINET",ROOM:"Electrical LV Room - Level 2"},
      {NO:243,TAG:"21-EIP-870001",TAG_DESCR:"IRP - INTERFACE RELAY PANEL",PARENT_TAG:"21-EIP-870001",DIMENSION:"2000 x 800 x 2000",WEIGHT:"1800",SN:75,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"IRP - INTERFACE RELAY PANEL",ROOM:"Electrical LV Room - Level 2"},
      {NO:244,TAG:"21-EDB-667606",TAG_DESCR:"MICELLEANEOUS FEEDERS DISTRIBUTION BOARD",PARENT_TAG:"21-EDB-667606",DIMENSION:"1000 x 600 x 2100",WEIGHT:"500",SN:76,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"MICELLEANEOUS FEEDERS DISTRIBUTION BOARD",ROOM:"Electrical LV Room - Level 2"},
      {NO:245,TAG:"21-LH-667400",TAG_DESCR:"ACB TROLLEY FOR LV SWITCHGEAR",PARENT_TAG:"21-LH-667400",DIMENSION:"1000 x 576 x 1500",WEIGHT:"91",SN:77,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"ACB TROLLEY FOR LV SWITCHGEAR",ROOM:"Electrical LV Room - Level 2"},
      {NO:246,TAG:"21-ECP-861101",TAG_DESCR:"GTG 1 UNIT CONTROL PANEL",PARENT_TAG:"21-ECP-861101",DIMENSION:"2388 x 911 x 2361",WEIGHT:"884",SN:78,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"GTG 1 UNIT CONTROL PANEL",ROOM:"Electrical LV Room - Level 2"},
      {NO:247,TAG:"21-ECP-861201",TAG_DESCR:"GTG 2 UNIT CONTROL PANEL",PARENT_TAG:"21-ECP-861201",DIMENSION:"2388 x 911 x 2361",WEIGHT:"884",SN:79,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"GTG 2 UNIT CONTROL PANEL",ROOM:"Electrical LV Room - Level 2"},
      {NO:248,TAG:"21-ECP-861301",TAG_DESCR:"GTG 3 UNIT CONTROL PANEL",PARENT_TAG:"21-ECP-861301",DIMENSION:"2388 x 911 x 2361",WEIGHT:"884",SN:80,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"GTG 3 UNIT CONTROL PANEL",ROOM:"Electrical LV Room - Level 2"},
      {NO:249,TAG:"21-ECP-861401",TAG_DESCR:"GTG 4 UNIT CONTROL PANEL",PARENT_TAG:"21-ECP-861401",DIMENSION:"2388 x 911 x 2361",WEIGHT:"884",SN:81,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"GTG 4 UNIT CONTROL PANEL",ROOM:"Electrical LV Room - Level 2"},
      {NO:250,TAG:"21-EFD-861105",TAG_DESCR:"VARIABLE SPEED DRIVE FOR LIQUID FUEL PUMP GTG 1",PARENT_TAG:"21-EFD-861105",DIMENSION:"610 x 445 x 1298",WEIGHT:"89",SN:82,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"VARIABLE SPEED DRIVE FOR LIQUID FUEL PUMP GTG 1",ROOM:"Electrical LV Room - Level 2"},
      {NO:251,TAG:"21-EFD-861205",TAG_DESCR:"VARIABLE SPEED DRIVE FOR LIQUID FUEL PUMP GTG 2",PARENT_TAG:"21-EFD-861205",DIMENSION:"610 x 445 x 1298",WEIGHT:"89",SN:83,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"VARIABLE SPEED DRIVE FOR LIQUID FUEL PUMP GTG 2",ROOM:"Electrical LV Room - Level 2"},
      {NO:252,TAG:"21-EFD-861305",TAG_DESCR:"VARIABLE SPEED DRIVE FOR LIQUID FUEL PUMP GTG 3",PARENT_TAG:"21-EFD-861305",DIMENSION:"610 x 445 x 1298",WEIGHT:"89",SN:84,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"VARIABLE SPEED DRIVE FOR LIQUID FUEL PUMP GTG 3",ROOM:"Electrical LV Room - Level 2"},
      {NO:253,TAG:"21-EFD-861405",TAG_DESCR:"VARIABLE SPEED DRIVE FOR LIQUID FUEL PUMP GTG 4",PARENT_TAG:"21-EFD-861405",DIMENSION:"610 x 445 x 1298",WEIGHT:"89",SN:85,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"VARIABLE SPEED DRIVE FOR LIQUID FUEL PUMP GTG 4",ROOM:"Electrical LV Room - Level 2"},
      {NO:254,TAG:"21-EFD-861102",TAG_DESCR:"VARIABLE SPEED DRIVE FOR START MOTOR GTG 1",PARENT_TAG:"21-EFD-861102",DIMENSION:"610 x 445 x 1613",WEIGHT:"62",SN:86,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"VARIABLE SPEED DRIVE FOR START MOTOR GTG 1",ROOM:"Electrical LV Room - Level 2"},
      {NO:255,TAG:"21-EFD-861202",TAG_DESCR:"VARIABLE SPEED DRIVE FOR START MOTOR GTG 2",PARENT_TAG:"21-EFD-861202",DIMENSION:"610 x 445 x 1613",WEIGHT:"62",SN:87,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"VARIABLE SPEED DRIVE FOR START MOTOR GTG 2",ROOM:"Electrical LV Room - Level 2"},
      {NO:256,TAG:"21-EFD-861302",TAG_DESCR:"VARIABLE SPEED DRIVE FOR START MOTOR GTG 3",PARENT_TAG:"21-EFD-861302",DIMENSION:"610 x 445 x 1613",WEIGHT:"62",SN:88,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"VARIABLE SPEED DRIVE FOR START MOTOR GTG 3",ROOM:"Electrical LV Room - Level 2"},
      {NO:257,TAG:"21-EFD-861402",TAG_DESCR:"VARIABLE SPEED DRIVE FOR START MOTOR GTG 4",PARENT_TAG:"21-EFD-861402",DIMENSION:"610 x 445 x 1613",WEIGHT:"62",SN:89,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"VARIABLE SPEED DRIVE FOR START MOTOR GTG 4",ROOM:"Electrical LV Room - Level 2"},
      {NO:258,TAG:"21-EMC-861109",TAG_DESCR:"DC MOTOR STARTER OIL SCAVENGE PUMP GTG 1",PARENT_TAG:"21-EMC-861109",MR_TAG:"MS002-ME-REQ-012-01002",DIMENSION:"406 x 278 x 508",WEIGHT:"21",SN:90,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"DC MOTOR STARTER OIL SCAVENGE PUMP GTG 1",ROOM:"Electrical LV Room - Level 2"},
      {NO:259,TAG:"21-EMC-861209",TAG_DESCR:"DC MOTOR STARTER OIL SCAVENGE PUMP GTG 2",PARENT_TAG:"21-EMC-861209",MR_TAG:"MS002-ME-REQ-012-01002",DIMENSION:"406 x 278 x 508",WEIGHT:"21",SN:91,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"DC MOTOR STARTER OIL SCAVENGE PUMP GTG 2",ROOM:"Electrical LV Room - Level 2"},
      {NO:260,TAG:"21-EMC-861309",TAG_DESCR:"DC MOTOR STARTER OIL SCAVENGE PUMP GTG 3",PARENT_TAG:"21-EMC-861309",MR_TAG:"MS002-ME-REQ-012-01002",DIMENSION:"406 x 278 x 508",WEIGHT:"21",SN:92,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"DC MOTOR STARTER OIL SCAVENGE PUMP GTG 3",ROOM:"Electrical LV Room - Level 2"},
      {NO:261,TAG:"21-EMC-861409",TAG_DESCR:"DC MOTOR STARTER OIL SCAVENGE PUMP GTG 4",PARENT_TAG:"21-EMC-861409",MR_TAG:"MS002-ME-REQ-012-01002",DIMENSION:"406 x 278 x 508",WEIGHT:"21",SN:93,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"DC MOTOR STARTER OIL SCAVENGE PUMP GTG 4",ROOM:"Electrical LV Room - Level 2"},
      {NO:262,TAG:"HOLD",TAG_DESCR:"COMBINED MOTOR STARTER DILUTION FAN AIR",PARENT_TAG:"HOLD",DIMENSION:"400 x 300 x 600",WEIGHT:"50",SN:94,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"N2020-12-08",PO_FORECAST:"N2020-12-08",PO_ACTUAL:"N2019-09-12",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-11-20",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-08-03",ETA_PLAN:"N2022-02-18",ETA_FORECAST:"TBD",ETA_ACTUAL:"N2020-07-08",ROS:"N2022-03-14",ROS_FORECAST:"TBD",ROS_ETA_DELTA:24,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"COMBINED MOTOR STARTER DILUTION FAN AIR",ROOM:"Electrical LV Room - Level 2"},
      {NO:263,TAG:"21-ETP-247001",TAG_DESCR:"WARM CONDENSATE HEATER THYRISTOR PANEL",PARENT_TAG:"21-ETP-247001",DIMENSION:"1812 x 408 x 2008",WEIGHT:"388",SN:95,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"S2020-11-30",IRN_FORECAST:"TBD",ETA_PLAN:"S2021-02-08",ETA_FORECAST:"TBD",ROS:"S2020-12-17",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-53,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"WARM CONDENSATE HEATER THYRISTOR PANEL",ROOM:"Electrical LV Room - Level 2"},
      {NO:264,TAG:"21-ETP-333001",TAG_DESCR:"DEW POINT LIQUID HEATER THYRISTOR PANEL",PARENT_TAG:"21-ETP-333001",DIMENSION:"1812 x 408 x 2008",WEIGHT:"388",SN:96,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"S2020-11-30",IRN_FORECAST:"TBD",ETA_PLAN:"S2021-02-08",ETA_FORECAST:"TBD",ROS:"S2020-12-17",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-53,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"DEW POINT LIQUID HEATER THYRISTOR PANEL",ROOM:"Electrical LV Room - Level 2"},
      {NO:265,TAG:"21-ETP-345001",TAG_DESCR:"REPRESSURISATION HEATER THYRISTOR PANEL",PARENT_TAG:"21-ETP-345001",DIMENSION:"1812 x 408 x 2008",WEIGHT:"388",SN:97,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"S2020-11-30",IRN_FORECAST:"TBD",ETA_PLAN:"S2021-02-08",ETA_FORECAST:"TBD",ROS:"S2020-12-17",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-53,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"REPRESSURISATION HEATER THYRISTOR PANEL",ROOM:"Electrical LV Room - Level 2"},
      {NO:266,TAG:"21-ETP-813001A",TAG_DESCR:"FUEL GAS SUPERHEATER A THYRISTOR PANEL",PARENT_TAG:"21-ETP-813001A",DIMENSION:"2012 x 408 x 2008",WEIGHT:"450",SN:98,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"S2020-11-30",IRN_FORECAST:"TBD",ETA_PLAN:"S2021-02-08",ETA_FORECAST:"TBD",ROS:"S2020-12-17",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-53,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"FUEL GAS SUPERHEATER A THYRISTOR PANEL",ROOM:"Electrical LV Room - Level 2"},
      {NO:267,TAG:"21-ETP-813001B",TAG_DESCR:"FUEL GAS SUPERHEATER B THYRISTOR PANEL",PARENT_TAG:"21-ETP-813001B",DIMENSION:"2012 x 408 x 2008",WEIGHT:"450",SN:99,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"S2020-11-30",IRN_FORECAST:"TBD",ETA_PLAN:"S2021-02-08",ETA_FORECAST:"TBD",ROS:"S2020-12-17",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-53,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"FUEL GAS SUPERHEATER B THYRISTOR PANEL",ROOM:"Electrical LV Room - Level 2"},
      {NO:268,TAG:"21-ICP-333041",TAG_DESCR:"ACTIVE MAGNETIC BEARING PANEL",PARENT_TAG:"21-ICP-333041",DIMENSION:"1604.5 x 801 x 2306.5",WEIGHT:"635",SN:101,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"ACTIVE MAGNETIC BEARING PANEL",ROOM:"Electrical LV Room - Level 2"},
      {NO:269,TAG:"21-EDB-667604",TAG_DESCR:"MOV's AND CONVENIENCE OUTLET DISTRIBUTION BOARD",PARENT_TAG:"21-EDB-667604",DIMENSION:"1000 x 600 x 2100",WEIGHT:"500",SN:102,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"MOV's AND CONVENIENCE OUTLET DISTRIBUTION BOARD",ROOM:"Electrical LV Room - Level 2"},
      {NO:270,TAG:"21-ITP-413001",TAG_DESCR:"FLOCULANT DOSING PUMPS VSD's CABINET",PARENT_TAG:"21-ITP-413001",DIMENSION:"1200 x 500 x 2000",WEIGHT:"400",SN:103,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"FLOCULANT DOSING PUMPS VSD's CABINET",ROOM:"Electrical LV Room - Level 2"},
      {NO:271,TAG:"21-ETR-802001A",TAG_DESCR:"TRANSFORMER RECTIFIER PANEL A FOR ELECTRICHLORINATION",PARENT_TAG:"21-ETR-802001A",DIMENSION:"1200 x 1420 x 2250",WEIGHT:"1250",SN:104,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"S2020-11-24",IRN_FORECAST:"TBD",ETA_PLAN:"S2021-02-02",ETA_FORECAST:"TBD",ROS:"S2020-12-16",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-48,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"TRANSFORMER RECTIFIER PANEL A FOR ELECTRICHLORINATION",ROOM:"Electrical LV Room - Level 2"},
      {NO:69,TAG:"21-EBA-671003",TAG_DESCR:"BATTERY RACK FOR AC UPS B TELECOM - chloride",PARENT_TAG:"21-EBA-671003",PROCUREDBY:"TPFMC",DIMENSION:"6050 x 884 x 1243",WEIGHT:"5558",SN:18,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-07-20",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-10-16",ETA_PLAN:"S2020-09-23",ETA_FORECAST:"TBD",ROS:"S2020-10-22",ROS_FORECAST:"TBD",ROS_ETA_DELTA:29,STATUS:"IRN",STATUS_DESCR:"4.IRN",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"BATTERY RACK FOR AC UPS B TELECOM - chloride",ROOM:"Electrical Rooms - LQ"},
      {NO:70,TAG:"21-EBA-671005",TAG_DESCR:"BATTERY RACK FOR AC UPS NAVAIDS",PARENT_TAG:"21-EBA-671005",PROCUREDBY:"COSCO",DIMENSION:"1456 x 1062 x 1100",WEIGHT:"500",SN:19,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"BATTERY RACK FOR AC UPS NAVAIDS",ROOM:"Electrical Rooms - LQ"},
      {NO:71,TAG:"21-EIS-671001",TAG_DESCR:"BATTERY CIRCUIT BREAKER AC UPS A",PARENT_TAG:"21-EIS-671001",PROCUREDBY:"TPFMC",DIMENSION:"430 x 329 x 1058",WEIGHT:"90",SN:21,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-10-27",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-09-25",ETA_PLAN:"S2020-12-28",ETA_FORECAST:"TBD",ROS:"S2020-10-22",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-67,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"BATTERY CIRCUIT BREAKER AC UPS A",ROOM:"Electrical Rooms - LQ"},
      {NO:72,TAG:"21-EIS-671002",TAG_DESCR:"BATTERY CIRCUIT BREAKER AC UPS B",PARENT_TAG:"21-EIS-671002",PROCUREDBY:"TPFMC",DIMENSION:"430 x 329 x 1058",WEIGHT:"90",SN:22,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-10-27",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-09-25",ETA_PLAN:"S2020-12-28",ETA_FORECAST:"TBD",ROS:"S2020-10-22",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-67,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"BATTERY CIRCUIT BREAKER AC UPS B",ROOM:"Electrical Rooms - LQ"},
      {NO:73,TAG:"21-EIS-671003",TAG_DESCR:"BATTERY CIRCUIT BREAKER AC UPS B TELECOM",PARENT_TAG:"21-EIS-671003",PROCUREDBY:"TPFMC",DIMENSION:"430 x 329 x 1058",WEIGHT:"90",SN:23,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-10-27",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-09-25",ETA_PLAN:"S2020-12-28",ETA_FORECAST:"TBD",ROS:"S2020-10-22",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-67,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"BATTERY CIRCUIT BREAKER AC UPS B TELECOM",ROOM:"Electrical Rooms - LQ"},
      {NO:74,TAG:"21-EIS-671005",TAG_DESCR:"BATTERY CIRCUIT BREAKER AC UPS NAVAIDS / HELIDECK",PARENT_TAG:"21-EIS-671005",PROCUREDBY:"COSCO",DIMENSION:"430 x 329 x 1058",WEIGHT:"90",SN:24,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"BATTERY CIRCUIT BREAKER AC UPS NAVAIDS / HELIDECK",ROOM:"Electrical Rooms - LQ"},
      {NO:75,TAG:"21-HAC-992107",TAG_DESCR:"AIR CONDITIONING UNIT",PARENT_TAG:"21-HAC-992107",MR_TAG:"MS002-HV-REQ-012-90001",PO_CODE:"PHV901",PROCUREDBY:"COSCO",DISC:"Package",DIMENSION:"1560 x 1200 x 2350",WEIGHT:"500",SN:25,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"R2019-08-14",PO_FORECAST:"R2019-08-14",PO_ACTUAL:"R2019-08-14",FAT_PLAN:"R2020-07-20",FAT_FORECAST:"R2020-07-20",FAT_ACTUAL:"R2020-07-20",IRN_PLAN:"R2020-08-27",IRN_FORECAST:"R2020-08-27",IRN_ACTUAL:"R2020-08-27",ETA_PLAN:"R2020-09-24",ETA_FORECAST:"R2020-09-24",ETA_ACTUAL:"R2020-09-24",ROS:"R2020-11-18",ROS_FORECAST:"R2020-11-18",ROS_ETA_DELTA:55,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"AIR CONDITIONING UNIT",ROOM:"Electrical Rooms - LQ"},
      {NO:76,TAG:"21-HAC-992106",TAG_DESCR:"AIR CONDITIONING UNIT",PARENT_TAG:"21-HAC-992106",MR_TAG:"MS002-HV-REQ-012-90001",PO_CODE:"PHV901",PROCUREDBY:"COSCO",DISC:"Package",DIMENSION:"1560 x 1200 x 2350",WEIGHT:"500",SN:26,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"R2019-08-14",PO_FORECAST:"R2019-08-14",PO_ACTUAL:"R2019-08-14",FAT_PLAN:"R2020-07-20",FAT_FORECAST:"R2020-07-20",FAT_ACTUAL:"R2020-07-20",IRN_PLAN:"R2020-08-27",IRN_FORECAST:"R2020-08-27",IRN_ACTUAL:"R2020-08-27",ETA_PLAN:"R2020-09-24",ETA_FORECAST:"R2020-09-24",ETA_ACTUAL:"R2020-09-24",ROS:"R2020-11-18",ROS_FORECAST:"R2020-11-18",ROS_ETA_DELTA:55,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"AIR CONDITIONING UNIT",ROOM:"Electrical Rooms - LQ"},
      {NO:77,TAG:"21-ECP-661001",TAG_DESCR:"EMERGENCY GENERATOR CONTROL PANEL",PARENT_TAG:"21-ECP-661001",PROCUREDBY:"TPFMC",DIMENSION:"6612 x 600 x 2100",WEIGHT:"800",SN:27,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"EMERGENCY GENERATOR CONTROL PANEL",ROOM:"Electrical Rooms - LQ"},
      {NO:78,TAG:"21-RFA-640313",TAG_DESCR:"SAFETY KIT FOR LV SWITCHROOM",PARENT_TAG:"21-RFA-640313",PROCUREDBY:"TPFMC",DIMENSION:"840 x 754 x 270",WEIGHT:"21",SN:28,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"SAFETY KIT FOR LV SWITCHROOM",ROOM:"Electrical Rooms - LQ"},
      {NO:79,TAG:"21-ELP-667660",TAG_DESCR:"Normal Lighting Panel -05",PARENT_TAG:"21-ELP-667660",PROCUREDBY:"COSCO",DIMENSION:"800 x 500 x 2000",WEIGHT:"350",SN:29,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"Normal Lighting Panel -05",ROOM:"Electrical Rooms - LQ"},
      {NO:163,TAG:"21-TUCP-9536001",TAG_DESCR:"LAN / WAN",PARENT_TAG:"21-TUCP-9536001",DIMENSION:"800 x 800",SN:16,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ06",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",TAG_ORIGINAL_DESCR:"LAN / WAN",ROOM:"Telecomms Room"},
      {NO:164,TAG:"21-TUCP-9536002",TAG_DESCR:"LAN / WAN",PARENT_TAG:"21-TUCP-9536002",DIMENSION:"800 x 800",SN:17,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ06",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",TAG_ORIGINAL_DESCR:"LAN / WAN",ROOM:"Telecomms Room"},
      {NO:165,TAG:"21-TUCP-9530001",TAG_DESCR:"PIN SERVER",PARENT_TAG:"21-TUCP-9530001",DIMENSION:"1000 x 800",SN:18,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ06",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",TAG_ORIGINAL_DESCR:"PIN SERVER",ROOM:"Telecomms Room"},
      {NO:166,TAG:"21-TUCP-9530002",TAG_DESCR:"PIN SERVER",PARENT_TAG:"21-TUCP-9530002",DIMENSION:"1000 x 800",SN:19,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ06",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",TAG_ORIGINAL_DESCR:"PIN SERVER",ROOM:"Telecomms Room"},
      {NO:80,TAG:"21-EDB-667671",TAG_DESCR:"Sub Distribution Auxiliary Panel 1(ES)",PARENT_TAG:"21-EDB-667671",PROCUREDBY:"COSCO",DIMENSION:"1300 x 500 x 2000",WEIGHT:"400",SN:30,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"Sub Distribution Auxiliary Panel 1(ES)",ROOM:"Electrical Rooms - LQ"},
      {NO:81,TAG:"21-ELP-667672",TAG_DESCR:"Small Power For LQ Auxiliary Equipment",PARENT_TAG:"21-ELP-667672",PROCUREDBY:"COSCO",DIMENSION:"1400 x 500 x 2000",WEIGHT:"450",SN:31,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"Small Power For LQ Auxiliary Equipment",ROOM:"Electrical Rooms - LQ"},
      {NO:82,TAG:"21-ELP-662460",TAG_DESCR:"Emergency Lighting Panel - 04",PARENT_TAG:"21-ELP-662460",PROCUREDBY:"COSCO",DIMENSION:"1100 x 500 x 2000",WEIGHT:"400",SN:32,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"Emergency Lighting Panel - 04",ROOM:"Electrical Rooms - LQ"},
      {NO:83,TAG:"21-EDB-99102",TAG_DESCR:"HVAC EDB B FOR LQ",PARENT_TAG:"21-EDB-99102",MR_TAG:"MS002-HV-REQ-012-90001",PROCUREDBY:"COSCO",DISC:"Package",DIMENSION:"2400 x 822 x 2100",WEIGHT:"900",SN:33,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"Q2019-06-24",PO_FORECAST:"Q2019-08-14",PO_ACTUAL:"Q2019-08-14",FAT_PLAN:"Q2020-07-20",FAT_FORECAST:"R2020-07-20",FAT_ACTUAL:"Q2020-07-21",IRN_PLAN:"Q2020-08-27",IRN_FORECAST:"R2020-08-27",IRN_ACTUAL:"Q2020-09-16",ETA_PLAN:"Q2020-09-26",ETA_FORECAST:"R2020-09-24",ETA_ACTUAL:"Q2020-09-26",ROS:"Q2020-11-18",ROS_FORECAST:"R2020-11-18",ROS_ETA_DELTA:53,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"HVAC EDB B FOR LQ",ROOM:"Electrical Rooms - LQ"},
      {NO:84,TAG:"HOLD",TAG_DESCR:"FIBRE OPTIC PATCH PANEL -LV",PARENT_TAG:"HOLD",PROCUREDBY:"TPFMC",DIMENSION:"800 x 800 x 2000",WEIGHT:"200",SN:34,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"N2020-12-08",PO_FORECAST:"N2020-12-08",PO_ACTUAL:"N2019-09-12",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-11-20",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-08-03",ETA_PLAN:"N2022-02-18",ETA_FORECAST:"TBD",ETA_ACTUAL:"N2020-07-08",ROS:"N2022-03-14",ROS_FORECAST:"TBD",ROS_ETA_DELTA:24,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"FIBRE OPTIC PATCH PANEL -LV",ROOM:"Electrical Rooms - LQ"},
      {NO:85,TAG:"NA",TAG_DESCR:"BATTERY LIFTING DEVICE",PARENT_TAG:"NA",PROCUREDBY:"TPFMC",DIMENSION:"500 x 910 x 1300",WEIGHT:"105",SN:35,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"BATTERY LIFTING DEVICE",ROOM:"Electrical Rooms - LQ"},
      {NO:86,TAG:"21-EUP-671004",TAG_DESCR:"AC UPS A TELECOM / HELIDECK",PARENT_TAG:"21-EDB-671404",PROCUREDBY:"TPFMC",DIMENSION:"3579 x 800 x 1900",WEIGHT:"2086",SN:41,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-10-27",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-09-25",ETA_PLAN:"S2020-12-28",ETA_FORECAST:"TBD",ROS:"S2020-10-22",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-67,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"AC UPS A TELECOM / HELIDECK",ROOM:"Electrical Rooms - LQ"},
      {NO:87,TAG:"21-EBA-671004",TAG_DESCR:"BATTERY RACK FOR AC UPS A TELECOM ",PARENT_TAG:"21-EBA-671004",PROCUREDBY:"TPFMC",DIMENSION:"6050 x 884 x 1243",WEIGHT:"5558",SN:42,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-07-20",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-10-16",ETA_PLAN:"S2020-09-23",ETA_FORECAST:"TBD",ROS:"S2020-10-22",ROS_FORECAST:"TBD",ROS_ETA_DELTA:29,STATUS:"IRN",STATUS_DESCR:"4.IRN",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"BATTERY RACK FOR AC UPS A TELECOM ",ROOM:"Electrical Rooms - LQ"},
      {NO:88,TAG:"21-EIS-671004",TAG_DESCR:"BATTERY CIRCUIT BREAKER AC UPS A TELECOM",PARENT_TAG:"21-EIS-671004",PROCUREDBY:"TPFMC",DIMENSION:"435 x 368 x 1280",WEIGHT:"90",SN:43,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-10-27",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-09-25",ETA_PLAN:"S2020-12-28",ETA_FORECAST:"TBD",ROS:"S2020-10-22",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-67,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"BATTERY CIRCUIT BREAKER AC UPS A TELECOM",ROOM:"Electrical Rooms - LQ"},
      {NO:89,TAG:"NA",TAG_DESCR:"BATTERY LIFTING DEVICE",PARENT_TAG:"NA",PROCUREDBY:"TPFMC",DIMENSION:"500 x 910 x 1300",WEIGHT:"105",SN:44,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"BATTERY LIFTING DEVICE",ROOM:"Electrical Rooms - LQ"},
      {NO:90,TAG:"21-ECP-605023",TAG_DESCR:"FO DISTRIBUTION CABINET",PARENT_TAG:"21-ECP-605023",MR_TAG:"MS002-EL-REQ-012-00051",PROCUREDBY:"TPFMC",DIMENSION:"600 x 250 x 600",WEIGHT:"35",SN:45,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-11-04",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-10-29",ETA_PLAN:"S2021-01-01",ETA_FORECAST:"TBD",ROS:"S2021-03-27",ROS_FORECAST:"TBD",ROS_ETA_DELTA:85,STATUS:"IRN",STATUS_DESCR:"4.IRN",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"FO DISTRIBUTION CABINET",ROOM:"Electrical Rooms - LQ"},
      {NO:91,TAG:"21-ELP-667666",TAG_DESCR:"Normal Lighting Panel -11",PARENT_TAG:"21-ELP-667666",PROCUREDBY:"COSCO",DIMENSION:"1200 x 300 x 1200",WEIGHT:"120",SN:46,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"Normal Lighting Panel -11",ROOM:"Electrical Rooms - LQ"},
      {NO:92,TAG:"21-ETP-992703",TAG_DESCR:"DUCT HEATER POWER DISTRIBUTION PANEL",PARENT_TAG:"21-ETP-992703",PROCUREDBY:"COSCO",DIMENSION:"800 x 400 x 1200",WEIGHT:"90",SN:47,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"FOR",LV4:"FOR",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"HL03",LOCATION:"HL03",TAG_ORIGINAL_DESCR:"DUCT HEATER POWER DISTRIBUTION PANEL",ROOM:"Electrical Rooms - LQ"},
      {NO:93,TAG:"21-DSH-6831115",TAG_DESCR:"HSSD HIGH SENSITIVE SMOKE DETECTOR",PARENT_TAG:"21-DSH-6831115",DIMENSION:"300 x 135 x 350",SN:1,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-09-02",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-08-27",ETA_PLAN:"S2020-11-16",ETA_FORECAST:"TBD",ETA_ACTUAL:"S11/23/2020",ROS:"S2019-01-01",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-685,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"HSSD HIGH SENSITIVE SMOKE DETECTOR",ROOM:"Central Equipment Room"},
      {NO:94,TAG:"21-ISC-603001",TAG_DESCR:"SIS/FGS CABINETS - CONTROLLER",PARENT_TAG:"21-ISC-603001",DIMENSION:"2100 x 800 x 800",SN:2,POSITION:"R",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"SIS/FGS CABINETS - CONTROLLER",ROOM:"Central Equipment Room"},
      {NO:95,TAG:"21-DSH-6831116",TAG_DESCR:"HSSD HIGH SENSITIVE SMOKE DETECTOR",PARENT_TAG:"21-DSH-6831116",DIMENSION:"300 x 135 x 350",SN:3,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-09-02",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-08-27",ETA_PLAN:"S2020-11-16",ETA_FORECAST:"TBD",ETA_ACTUAL:"S11/23/2020",ROS:"S2019-01-01",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-685,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"HSSD HIGH SENSITIVE SMOKE DETECTOR",ROOM:"Central Equipment Room"},
      {NO:96,TAG:"21-INC-603001",TAG_DESCR:"SIS/FGS CABINETS - NETWORK",PARENT_TAG:"21-INC-603001",DIMENSION:"2100 x 800 x 800",SN:4,POSITION:"R",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"SIS/FGS CABINETS - NETWORK",ROOM:"Central Equipment Room"},
      {NO:97,TAG:"21-IMC-607101",TAG_DESCR:"480 FGC CTO CABINETS",PARENT_TAG:"21-IMC-607101",MR_TAG:"MS002-IC-REQ-012-00008",DIMENSION:"2100 x 800 x 800",SN:5,POSITION:"R",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"S2020-11-20",IRN_FORECAST:"TBD",ETA_PLAN:"S2020-12-05",ETA_FORECAST:"TBD",ROS:"S2020-08-15",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-112,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"480 FGC CTO CABINETS",ROOM:"Central Equipment Room"},
      {NO:98,TAG:"21-IMC-607102",TAG_DESCR:"480 FGC CTO CABINETS",PARENT_TAG:"21-IMC-607102",DIMENSION:"2100 x 800 x 800",SN:6,POSITION:"R",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"480 FGC CTO CABINETS",ROOM:"Central Equipment Room"},
      {NO:99,TAG:"21-DSH-6831117",TAG_DESCR:"HSSD HIGH SENSITIVE SMOKE DETECTOR",PARENT_TAG:"21-DSH-6831117",DIMENSION:"300 x 135 x 350",SN:7,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-09-02",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-08-27",ETA_PLAN:"S2020-11-16",ETA_FORECAST:"TBD",ETA_ACTUAL:"S11/23/2020",ROS:"S2019-01-01",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-685,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"HSSD HIGH SENSITIVE SMOKE DETECTOR",ROOM:"Central Equipment Room"},
      {NO:100,TAG:"21-IMC-601101",TAG_DESCR:"480 SIS CTO CABINETS",PARENT_TAG:"21-IMC-601101",MR_TAG:"MS002-IC-REQ-012-00008",DIMENSION:"2100 x 800 x 800",SN:11,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"S2020-11-20",IRN_FORECAST:"TBD",ETA_PLAN:"S2020-12-05",ETA_FORECAST:"TBD",ROS:"S2020-08-15",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-112,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"480 SIS CTO CABINETS",ROOM:"Central Equipment Room"},
      {NO:101,TAG:"21-IMC-601102",TAG_DESCR:"480 SIS CTO CABINETS",PARENT_TAG:"21-IMC-601102",DIMENSION:"2100 x 800 x 800",SN:12,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"480 SIS CTO CABINETS",ROOM:"Central Equipment Room"},
      {NO:102,TAG:"21-IMC-602101",TAG_DESCR:"336 PAS CTO CABINETS",PARENT_TAG:"21-IMC-602101",DIMENSION:"2100 x 800 x 800",SN:20,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"336 PAS CTO CABINETS",ROOM:"Central Equipment Room"},
      {NO:103,TAG:"21-IPC-602101",TAG_DESCR:"PAS CABINETS - CONTROLLER",PARENT_TAG:"21-IPC-602101",DIMENSION:"2100 x 800 x 800",SN:21,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"S2020-11-20",IRN_FORECAST:"TBD",ETA_PLAN:"S2020-12-05",ETA_FORECAST:"TBD",ROS:"S2020-08-15",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-112,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"PAS CABINETS - CONTROLLER",ROOM:"Central Equipment Room"},
      {NO:104,TAG:"21-INC-602101",TAG_DESCR:"PAS CABINETS - NETWORK",PARENT_TAG:"21-INC-602101",DIMENSION:"2100 x 800 x 800",SN:22,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"PAS CABINETS - NETWORK",ROOM:"Central Equipment Room"},
      {NO:105,TAG:"21-ECP-605011",TAG_DESCR:"EDMCS SERVER/COMMUNICATION",PARENT_TAG:"21-ECP-605011",MR_TAG:"MS002-EL-REQ-012-00051",DIMENSION:"2100 x 1000 x 800",SN:25,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-11-04",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-10-29",ETA_PLAN:"S2021-01-01",ETA_FORECAST:"TBD",ROS:"S2020-10-09",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-84,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"EDMCS SERVER/COMMUNICATION",ROOM:"Central Equipment Room"},
      {NO:106,TAG:"21-IXC-603001",TAG_DESCR:"SERVER CABINETS (VRTX) PRIMARY",PARENT_TAG:"21-IXC-603001",MR_TAG:"MS002-IC-REQ-012-00008",DIMENSION:"2100 x 1000 x 800",SN:30,POSITION:"R",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"S2020-11-20",IRN_FORECAST:"TBD",ETA_PLAN:"S2020-12-05",ETA_FORECAST:"TBD",ROS:"S2020-08-15",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-112,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"SERVER CABINETS (VRTX) PRIMARY",ROOM:"Central Equipment Room"},
      {NO:107,TAG:"21-IXC-603002",TAG_DESCR:"SERVER CABINETS (VRTX) SECONDARY",PARENT_TAG:"21-IXC-603002",DIMENSION:"2100 x 1000 x 800",SN:31,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"SERVER CABINETS (VRTX) SECONDARY",ROOM:"Central Equipment Room"},
      {NO:108,TAG:"21-IPC-602102",TAG_DESCR:"CONTROLLER PLUS EIOC CABINET",PARENT_TAG:"21-IPC-602102",DIMENSION:"2100 x 800 x 800",SN:34,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"CONTROLLER PLUS EIOC CABINET",ROOM:"Central Equipment Room"},
      {NO:109,TAG:"21-ISC-607101",TAG_DESCR:"ADRESSABLE FGS CABINET",PARENT_TAG:"21-ISC-607101",DIMENSION:"2100 x 800 x 800",SN:35,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"ADRESSABLE FGS CABINET",ROOM:"Central Equipment Room"},
      {NO:110,TAG:"21-IXC-603501",TAG_DESCR:"CCTV SERVER ENCODER PROCESS",PARENT_TAG:"21-IXC-603501",MR_TAG:"MS002-IC-REQ-012-00008",DIMENSION:"2100 x 1000 x 800",SN:50,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"S2020-11-20",IRN_FORECAST:"TBD",ETA_PLAN:"S2020-12-05",ETA_FORECAST:"TBD",ROS:"S2020-08-15",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-112,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"CCTV SERVER ENCODER PROCESS",ROOM:"Central Equipment Room"},
      {NO:113,TAG:"21-IPP-603001",TAG_DESCR:"FOPP CABINET",PARENT_TAG:"21-IPP-603001",MR_TAG:"MS002-ME-REQ-012-93001",DISC:"Package",DIMENSION:"2100 x 800 x 800",SN:55,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"Q2019-07-19",PO_FORECAST:"Q2020-02-26",PO_ACTUAL:"Q2020-02-26",FAT_PLAN:"Q2020-12-10",FAT_FORECAST:"TBD",IRN_PLAN:"Q2020-12-25",IRN_FORECAST:"TBD",ETA_PLAN:"Q2020-12-17",ETA_FORECAST:"TBD",ROS:"Q2021-03-01",ROS_FORECAST:"TBD",ROS_ETA_DELTA:74,STATUS:"PO",STATUS_DESCR:"2.PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"FOPP CABINET",ROOM:"Central Equipment Room"},
      {NO:114,TAG:"21-IPP-603002",TAG_DESCR:"FOPP CABINET",PARENT_TAG:"21-IPP-603002",MR_TAG:"MS002-ME-REQ-012-93001",DISC:"Package",DIMENSION:"2100 x 800 x 800",SN:56,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"Q2019-07-19",PO_FORECAST:"Q2020-02-26",PO_ACTUAL:"Q2020-02-26",FAT_PLAN:"Q2020-12-10",FAT_FORECAST:"TBD",IRN_PLAN:"Q2020-12-25",IRN_FORECAST:"TBD",ETA_PLAN:"Q2020-12-17",ETA_FORECAST:"TBD",ROS:"Q2021-03-01",ROS_FORECAST:"TBD",ROS_ETA_DELTA:74,STATUS:"PO",STATUS_DESCR:"2.PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"FOPP CABINET",ROOM:"Central Equipment Room"},
      {NO:115,TAG:"21-ECP-632001",TAG_DESCR:"LIFT BOAT STARTER CABINET",PARENT_TAG:"21-ECP-632001",DIMENSION:"WALL MOUNTED",SN:77,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"LIFT BOAT STARTER CABINET",ROOM:"Central Equipment Room"},
      {NO:116,TAG:"21-ECP-632002",TAG_DESCR:"LIFT BOAT STARTER CABINET",PARENT_TAG:"21-ECP-632002",DIMENSION:"WALL MOUNTED",SN:78,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"LIFT BOAT STARTER CABINET",ROOM:"Central Equipment Room"},
      {NO:117,TAG:"21-ECP-632003",TAG_DESCR:"LIFT BOAT STARTER CABINET",PARENT_TAG:"21-ECP-632003",DIMENSION:"WALL MOUNTED",SN:79,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"LIFT BOAT STARTER CABINET",ROOM:"Central Equipment Room"},
      {NO:118,TAG:"21-IDB-671421",TAG_DESCR:"PDB UPS A/B 1H",PARENT_TAG:"21-IDB-671421",DIMENSION:"2100 x 800 x 800",SN:80,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"S2020-12-17",IRN_FORECAST:"TBD",ETA_PLAN:"S2021-02-25",ETA_FORECAST:"TBD",ROS:"S2019-01-01",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-786,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"PDB UPS A/B 1H",ROOM:"Central Equipment Room"},
      {NO:119,TAG:"21-IDB-671423",TAG_DESCR:"PDB UPS A 3H",PARENT_TAG:"21-IDB-671423",DIMENSION:"2100 x 800 x 800",SN:81,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"S2020-12-17",IRN_FORECAST:"TBD",ETA_PLAN:"S2021-02-25",ETA_FORECAST:"TBD",ROS:"S2019-01-01",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-786,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"PDB UPS A 3H",ROOM:"Central Equipment Room"},
      {NO:120,TAG:"21-IDB-671424",TAG_DESCR:"PDB UPS B 3H",PARENT_TAG:"21-IDB-671424",DIMENSION:"2100 x 800 x 800",SN:82,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"S2020-12-17",IRN_FORECAST:"TBD",ETA_PLAN:"S2021-02-25",ETA_FORECAST:"TBD",ROS:"S2019-01-01",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-786,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"PDB UPS B 3H",ROOM:"Central Equipment Room"},
      {NO:121,TAG:"21-IDB-671425",TAG_DESCR:"PDB UPS A/B 1H TELECOM",PARENT_TAG:"21-IDB-671425",DIMENSION:"2100 x 800 x 800",SN:83,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"S2020-12-17",IRN_FORECAST:"TBD",ETA_PLAN:"S2021-02-25",ETA_FORECAST:"TBD",ROS:"S2019-01-01",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-786,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"PDB UPS A/B 1H TELECOM",ROOM:"Central Equipment Room"},
      {NO:122,TAG:"21-IDB-671426",TAG_DESCR:"PDB UPS A/B 4H TELECOM",PARENT_TAG:"21-IDB-671426",DIMENSION:"2100 x 800 x 800",SN:84,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"S2020-12-17",IRN_FORECAST:"TBD",ETA_PLAN:"S2021-02-25",ETA_FORECAST:"TBD",ROS:"S2019-01-01",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-786,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"PDB UPS A/B 4H TELECOM",ROOM:"Central Equipment Room"},
      {NO:123,TAG:"21-IDB-671427",TAG_DESCR:"PDB ESSENTIAL",PARENT_TAG:"21-IDB-671427",DIMENSION:"2100 x 800 x 800",SN:85,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"PDB ESSENTIAL",ROOM:"Central Equipment Room"},
      {NO:124,TAG:"21-HCP-992101A",TAG_DESCR:"HVAC UCP",PARENT_TAG:"21-HCP-992101A",MR_TAG:"MS002-HV-REQ-012-90001",DISC:"Package",DIMENSION:"2100 x 800 x 800",SN:87,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"Q2019-06-24",PO_FORECAST:"Q2019-08-14",PO_ACTUAL:"Q2019-08-14",FAT_PLAN:"Q2020-07-20",FAT_FORECAST:"R2020-07-20",FAT_ACTUAL:"Q2020-07-21",IRN_PLAN:"Q2020-08-27",IRN_FORECAST:"R2020-08-27",IRN_ACTUAL:"Q2020-09-16",ETA_PLAN:"Q2020-09-26",ETA_FORECAST:"R2020-09-24",ETA_ACTUAL:"Q2020-09-26",ROS:"Q2020-11-18",ROS_FORECAST:"R2020-11-18",ROS_ETA_DELTA:53,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"HVAC UCP",ROOM:"Central Equipment Room"},
      {NO:125,TAG:"21-HCP-992101B",TAG_DESCR:"HVAC UCP",PARENT_TAG:"21-HCP-992101B",MR_TAG:"MS002-HV-REQ-012-90001",DISC:"Package",DIMENSION:"2100 x 800 x 800",SN:88,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"Q2019-06-24",PO_FORECAST:"Q2019-08-14",PO_ACTUAL:"Q2019-08-14",FAT_PLAN:"Q2020-07-20",FAT_FORECAST:"R2020-07-20",FAT_ACTUAL:"Q2020-07-21",IRN_PLAN:"Q2020-08-27",IRN_FORECAST:"R2020-08-27",IRN_ACTUAL:"Q2020-09-16",ETA_PLAN:"Q2020-09-26",ETA_FORECAST:"R2020-09-24",ETA_ACTUAL:"Q2020-09-26",ROS:"Q2020-11-18",ROS_FORECAST:"R2020-11-18",ROS_ETA_DELTA:53,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"HVAC UCP",ROOM:"Central Equipment Room"},
      {NO:126,TAG:"21-HCP-992101C",TAG_DESCR:"HVAC UCP",PARENT_TAG:"21-HCP-992101C",MR_TAG:"MS002-HV-REQ-012-90001",DISC:"Package",DIMENSION:"2100 x 800 x 800",SN:89,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"Q2019-06-24",PO_FORECAST:"Q2019-08-14",PO_ACTUAL:"Q2019-08-14",FAT_PLAN:"Q2020-07-20",FAT_FORECAST:"R2020-07-20",FAT_ACTUAL:"Q2020-07-21",IRN_PLAN:"Q2020-08-27",IRN_FORECAST:"R2020-08-27",IRN_ACTUAL:"Q2020-09-16",ETA_PLAN:"Q2020-09-26",ETA_FORECAST:"R2020-09-24",ETA_ACTUAL:"Q2020-09-26",ROS:"Q2020-11-18",ROS_FORECAST:"R2020-11-18",ROS_ETA_DELTA:53,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"HVAC UCP",ROOM:"Central Equipment Room"},
      {NO:127,TAG:"21-HCP-992101D",TAG_DESCR:"HVAC UCP",PARENT_TAG:"21-HCP-992101D",MR_TAG:"MS002-HV-REQ-012-90001",DISC:"Package",DIMENSION:"2100 x 800 x 800",SN:90,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"Q2019-06-24",PO_FORECAST:"Q2019-08-14",PO_ACTUAL:"Q2019-08-14",FAT_PLAN:"Q2020-07-20",FAT_FORECAST:"R2020-07-20",FAT_ACTUAL:"Q2020-07-21",IRN_PLAN:"Q2020-08-27",IRN_FORECAST:"R2020-08-27",IRN_ACTUAL:"Q2020-09-16",ETA_PLAN:"Q2020-09-26",ETA_FORECAST:"R2020-09-24",ETA_ACTUAL:"Q2020-09-26",ROS:"Q2020-11-18",ROS_FORECAST:"R2020-11-18",ROS_ETA_DELTA:53,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"HVAC UCP",ROOM:"Central Equipment Room"},
      {NO:128,TAG:"21-HCP-992101E",TAG_DESCR:"HVAC UCP",PARENT_TAG:"21-HCP-992101E",MR_TAG:"MS002-HV-REQ-012-90001",DISC:"Package",DIMENSION:"2100 x 800 x 800",SN:91,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"Q2019-06-24",PO_FORECAST:"Q2019-08-14",PO_ACTUAL:"Q2019-08-14",FAT_PLAN:"Q2020-07-20",FAT_FORECAST:"R2020-07-20",FAT_ACTUAL:"Q2020-07-21",IRN_PLAN:"Q2020-08-27",IRN_FORECAST:"R2020-08-27",IRN_ACTUAL:"Q2020-09-16",ETA_PLAN:"Q2020-09-26",ETA_FORECAST:"R2020-09-24",ETA_ACTUAL:"Q2020-09-26",ROS:"Q2020-11-18",ROS_FORECAST:"R2020-11-18",ROS_ETA_DELTA:53,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"HVAC UCP",ROOM:"Central Equipment Room"},
      {NO:129,TAG:"21-HCP-992101F",TAG_DESCR:"HVAC UCP",PARENT_TAG:"21-HCP-992101F",MR_TAG:"MS002-HV-REQ-012-90001",DISC:"Package",DIMENSION:"2100 x 800 x 800",SN:92,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"Q2019-06-24",PO_FORECAST:"Q2019-08-14",PO_ACTUAL:"Q2019-08-14",FAT_PLAN:"Q2020-07-20",FAT_FORECAST:"R2020-07-20",FAT_ACTUAL:"Q2020-07-21",IRN_PLAN:"Q2020-08-27",IRN_FORECAST:"R2020-08-27",IRN_ACTUAL:"Q2020-09-16",ETA_PLAN:"Q2020-09-26",ETA_FORECAST:"R2020-09-24",ETA_ACTUAL:"Q2020-09-26",ROS:"Q2020-11-18",ROS_FORECAST:"R2020-11-18",ROS_ETA_DELTA:53,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"HVAC UCP",ROOM:"Central Equipment Room"},
      {NO:130,TAG:"21-HCP-992101G",TAG_DESCR:"HVAC UCP",PARENT_TAG:"21-HCP-992101G",MR_TAG:"MS002-HV-REQ-012-90001",DISC:"Package",DIMENSION:"2100 x 800 x 800",SN:93,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"Q2019-06-24",PO_FORECAST:"Q2019-08-14",PO_ACTUAL:"Q2019-08-14",FAT_PLAN:"Q2020-07-20",FAT_FORECAST:"R2020-07-20",FAT_ACTUAL:"Q2020-07-21",IRN_PLAN:"Q2020-08-27",IRN_FORECAST:"R2020-08-27",IRN_ACTUAL:"Q2020-09-16",ETA_PLAN:"Q2020-09-26",ETA_FORECAST:"R2020-09-24",ETA_ACTUAL:"Q2020-09-26",ROS:"Q2020-11-18",ROS_FORECAST:"R2020-11-18",ROS_ETA_DELTA:53,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"HVAC UCP",ROOM:"Central Equipment Room"},
      {NO:131,TAG:"21-HCP-992101H",TAG_DESCR:"HVAC UCP",PARENT_TAG:"21-HCP-992101H",MR_TAG:"MS002-HV-REQ-012-90001",DISC:"Package",DIMENSION:"2100 x 800 x 800",SN:94,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"Q2019-06-24",PO_FORECAST:"Q2019-08-14",PO_ACTUAL:"Q2019-08-14",FAT_PLAN:"Q2020-07-20",FAT_FORECAST:"R2020-07-20",FAT_ACTUAL:"Q2020-07-21",IRN_PLAN:"Q2020-08-27",IRN_FORECAST:"R2020-08-27",IRN_ACTUAL:"Q2020-09-16",ETA_PLAN:"Q2020-09-26",ETA_FORECAST:"R2020-09-24",ETA_ACTUAL:"Q2020-09-26",ROS:"Q2020-11-18",ROS_FORECAST:"R2020-11-18",ROS_ETA_DELTA:53,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"HVAC UCP",ROOM:"Central Equipment Room"},
      {NO:132,TAG:"21-HCP-992101I",TAG_DESCR:"HVAC UCP",PARENT_TAG:"21-HCP-992101I",MR_TAG:"MS002-HV-REQ-012-90001",DISC:"Package",DIMENSION:"2100 x 800 x 800",SN:95,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"Q2019-06-24",PO_FORECAST:"Q2019-08-14",PO_ACTUAL:"Q2019-08-14",FAT_PLAN:"Q2020-07-20",FAT_FORECAST:"R2020-07-20",FAT_ACTUAL:"Q2020-07-21",IRN_PLAN:"Q2020-08-27",IRN_FORECAST:"R2020-08-27",IRN_ACTUAL:"Q2020-09-16",ETA_PLAN:"Q2020-09-26",ETA_FORECAST:"R2020-09-24",ETA_ACTUAL:"Q2020-09-26",ROS:"Q2020-11-18",ROS_FORECAST:"R2020-11-18",ROS_ETA_DELTA:53,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"HVAC UCP",ROOM:"Central Equipment Room"},
      {NO:167,TAG:"21-TUCP-9530003",TAG_DESCR:"BP SERVER",PARENT_TAG:"21-TUCP-9530003",DIMENSION:"1000 x 800",SN:20,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ06",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",TAG_ORIGINAL_DESCR:"BP SERVER",ROOM:"Telecomms Room"},
      {NO:168,TAG:"21-TUCP-9535004",TAG_DESCR:"BP SERVER",PARENT_TAG:"21-TUCP-9535004",DIMENSION:"1000 x 800",SN:21,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ06",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",TAG_ORIGINAL_DESCR:"BP SERVER",ROOM:"Telecomms Room"},
      {NO:176,TAG:"21-DSHA-6835208",TAG_DESCR:"HSSD",PARENT_TAG:"21-DSHA-6835208",DIMENSION:"WALL MOUNTED",SN:30,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ06",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",TAG_ORIGINAL_DESCR:"HSSD",ROOM:"Telecomms Room"},
      {NO:272,TAG:"21-ETR-802001B",TAG_DESCR:"TRANSFORMER RECTIFIER PANEL B FOR ELECTRICHLORINATION",PARENT_TAG:"21-ETR-802001B",DIMENSION:"1200 x 1420 x 2250",WEIGHT:"1250",SN:105,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"S2020-11-24",IRN_FORECAST:"TBD",ETA_PLAN:"S2021-02-02",ETA_FORECAST:"TBD",ROS:"S2020-12-16",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-48,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"TRANSFORMER RECTIFIER PANEL B FOR ELECTRICHLORINATION",ROOM:"Electrical LV Room - Level 2"},
      {NO:273,TAG:"21-EFD-851001A",TAG_DESCR:"VARIABLE SPEED DRIVE A FOR SUBSEA METHANOL INJECTION",PARENT_TAG:"21-EFD-851001A",DIMENSION:"252 x 421.3 x 727",WEIGHT:"45",SN:106,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"VARIABLE SPEED DRIVE A FOR SUBSEA METHANOL INJECTION",ROOM:"Electrical LV Room - Level 2"},
      {NO:274,TAG:"21-EFD-851001B",TAG_DESCR:"VARIABLE SPEED DRIVE B FOR SUBSEA METHANOL INJECTION",PARENT_TAG:"21-EFD-851001B",DIMENSION:"252 x 421.3 x 727",WEIGHT:"45",SN:107,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"VARIABLE SPEED DRIVE B FOR SUBSEA METHANOL INJECTION",ROOM:"Electrical LV Room - Level 2"},
      {NO:275,TAG:"HOLD",TAG_DESCR:"BATTERY CHARGER FOR MINI PUSHER",PARENT_TAG:"HOLD",DIMENSION:"500 x 400 x 600",WEIGHT:"100",SN:108,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"N2020-12-08",PO_FORECAST:"N2020-12-08",PO_ACTUAL:"N2019-09-12",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-11-20",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-08-03",ETA_PLAN:"N2022-02-18",ETA_FORECAST:"TBD",ETA_ACTUAL:"N2020-07-08",ROS:"N2022-03-14",ROS_FORECAST:"TBD",ROS_ETA_DELTA:24,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"BATTERY CHARGER FOR MINI PUSHER",ROOM:"Electrical LV Room - Level 2"},
      {NO:276,TAG:"HOLD",TAG_DESCR:"CHARGING SOCKET ZONE 1 / IIB / T3",PARENT_TAG:"HOLD",DIMENSION:"890 x 400 x 1150",WEIGHT:"190",SN:109,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"N2020-12-08",PO_FORECAST:"N2020-12-08",PO_ACTUAL:"N2019-09-12",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-11-20",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-08-03",ETA_PLAN:"N2022-02-18",ETA_FORECAST:"TBD",ETA_ACTUAL:"N2020-07-08",ROS:"N2022-03-14",ROS_FORECAST:"TBD",ROS_ETA_DELTA:24,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"CHARGING SOCKET ZONE 1 / IIB / T3",ROOM:"Electrical LV Room - Level 2"},
      {NO:277,TAG:"21-EDB-997805",TAG_DESCR:"HVAC HEATER PANEL",PARENT_TAG:"21-EDB-997805",DIMENSION:"600 x 227 x 600",WEIGHT:"35",SN:110,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"HVAC HEATER PANEL",ROOM:"Electrical LV Room - Level 2"},
      {NO:278,TAG:"21-EDB-997806",TAG_DESCR:"HVAC HEATER PANEL",PARENT_TAG:"21-EDB-997806",DIMENSION:"600 x 227 x 600",WEIGHT:"35",SN:111,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"HVAC HEATER PANEL",ROOM:"Electrical LV Room - Level 2"},
      {NO:279,TAG:"21-ECP-802040",TAG_DESCR:"ELECTROCHLORINATION BLOWER MOTOR STARTER",PARENT_TAG:"21-ECP-802040",DIMENSION:"600 x 210 x 600",WEIGHT:"35",SN:112,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"ELECTROCHLORINATION BLOWER MOTOR STARTER",ROOM:"Electrical LV Room - Level 2"},
      {NO:280,TAG:"21-ECP-605041",TAG_DESCR:"SYNCHRO PANEL",PARENT_TAG:"21-ECP-605041",MR_TAG:"MS002-EL-REQ-012-00051",DIMENSION:"800 x 800 x 2100",WEIGHT:"400",SN:113,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-11-04",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-10-29",ETA_PLAN:"S2021-01-01",ETA_FORECAST:"TBD",ROS:"S2020-10-09",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-84,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"SYNCHRO PANEL",ROOM:"Electrical LV Room - Level 2"},
      {NO:281,TAG:"21-ETP-333040",TAG_DESCR:"EXPANDER-COMPRESSOR SEAL GAS HEATER THYRISTOR PANEL",PARENT_TAG:"21-ETP-333040",DIMENSION:"2008 x 408 x 2008",WEIGHT:"495",SN:114,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"EXPANDER-COMPRESSOR SEAL GAS HEATER THYRISTOR PANEL",ROOM:"Electrical LV Room - Level 2"},
      {NO:282,TAG:"21-EFD-997701",TAG_DESCR:"VARIABLE SPEED DRIVE FOR PRESSURISATION FAN A",PARENT_TAG:"21-EFD-997701",DIMENSION:"800 x 800 x 2100",WEIGHT:"350",SN:115,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"VARIABLE SPEED DRIVE FOR PRESSURISATION FAN A",ROOM:"Electrical LV Room - Level 2"},
      {NO:133,TAG:"21-HCP-992101J",TAG_DESCR:"HVAC UCP",PARENT_TAG:"21-HCP-992101J",MR_TAG:"MS002-HV-REQ-012-90001",DISC:"Package",DIMENSION:"2100 x 800 x 800",SN:96,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"Q2019-06-24",PO_FORECAST:"Q2019-08-14",PO_ACTUAL:"Q2019-08-14",FAT_PLAN:"Q2020-07-20",FAT_FORECAST:"R2020-07-20",FAT_ACTUAL:"Q2020-07-21",IRN_PLAN:"Q2020-08-27",IRN_FORECAST:"R2020-08-27",IRN_ACTUAL:"Q2020-09-16",ETA_PLAN:"Q2020-09-26",ETA_FORECAST:"R2020-09-24",ETA_ACTUAL:"Q2020-09-26",ROS:"Q2020-11-18",ROS_FORECAST:"R2020-11-18",ROS_ETA_DELTA:53,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"HVAC UCP",ROOM:"Central Equipment Room"},
      {NO:134,TAG:"21-HCP-992101K",TAG_DESCR:"HVAC UCP",PARENT_TAG:"21-HCP-992101K",MR_TAG:"MS002-HV-REQ-012-90001",DISC:"Package",DIMENSION:"2100 x 800 x 800",SN:97,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"Q2019-06-24",PO_FORECAST:"Q2019-08-14",PO_ACTUAL:"Q2019-08-14",FAT_PLAN:"Q2020-07-20",FAT_FORECAST:"R2020-07-20",FAT_ACTUAL:"Q2020-07-21",IRN_PLAN:"Q2020-08-27",IRN_FORECAST:"R2020-08-27",IRN_ACTUAL:"Q2020-09-16",ETA_PLAN:"Q2020-09-26",ETA_FORECAST:"R2020-09-24",ETA_ACTUAL:"Q2020-09-26",ROS:"Q2020-11-18",ROS_FORECAST:"R2020-11-18",ROS_ETA_DELTA:53,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"HVAC UCP",ROOM:"Central Equipment Room"},
      {NO:135,TAG:"21-HCP-992101L",TAG_DESCR:"HVAC UCP",PARENT_TAG:"21-HCP-992101L",MR_TAG:"MS002-HV-REQ-012-90001",DISC:"Package",DIMENSION:"2100 x 800 x 800",SN:98,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"Q2019-06-24",PO_FORECAST:"Q2019-08-14",PO_ACTUAL:"Q2019-08-14",FAT_PLAN:"Q2020-07-20",FAT_FORECAST:"R2020-07-20",FAT_ACTUAL:"Q2020-07-21",IRN_PLAN:"Q2020-08-27",IRN_FORECAST:"R2020-08-27",IRN_ACTUAL:"Q2020-09-16",ETA_PLAN:"Q2020-09-26",ETA_FORECAST:"R2020-09-24",ETA_ACTUAL:"Q2020-09-26",ROS:"Q2020-11-18",ROS_FORECAST:"R2020-11-18",ROS_ETA_DELTA:53,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"HVAC UCP",ROOM:"Central Equipment Room"},
      {NO:136,TAG:"21-HCP-992101M",TAG_DESCR:"HVAC UCP",PARENT_TAG:"21-HCP-992101M",MR_TAG:"MS002-HV-REQ-012-90001",DISC:"Package",DIMENSION:"2100 x 800 x 800",SN:99,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"Q2019-06-24",PO_FORECAST:"Q2019-08-14",PO_ACTUAL:"Q2019-08-14",FAT_PLAN:"Q2020-07-20",FAT_FORECAST:"R2020-07-20",FAT_ACTUAL:"Q2020-07-21",IRN_PLAN:"Q2020-08-27",IRN_FORECAST:"R2020-08-27",IRN_ACTUAL:"Q2020-09-16",ETA_PLAN:"Q2020-09-26",ETA_FORECAST:"R2020-09-24",ETA_ACTUAL:"Q2020-09-26",ROS:"Q2020-11-18",ROS_FORECAST:"R2020-11-18",ROS_ETA_DELTA:53,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"HVAC UCP",ROOM:"Central Equipment Room"},
      {NO:142,TAG:"21-TUCP-9514002",TAG_DESCR:"TELEPHONE",PARENT_TAG:"21-TUCP-9514002",DIMENSION:"2100 x 1000 x 800",SN:105,POSITION:"R",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"TELEPHONE",ROOM:"Central Equipment Room"},
      {NO:146,TAG:"21-TUCP-9536003",TAG_DESCR:"LAN / WAN",PARENT_TAG:"21-TUCP-9536003",DIMENSION:"2100 x 800 x 800",SN:109,POSITION:"R",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"LAN / WAN",ROOM:"Central Equipment Room"},
      {NO:147,TAG:"21-TUCP-9536004",TAG_DESCR:"LAN / WAN",PARENT_TAG:"21-TUCP-9536004",DIMENSION:"2100 x 800 x 800",SN:110,POSITION:"R",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"LAN / WAN",ROOM:"Central Equipment Room"},
      {NO:148,TAG:"21-TUCP-9574002",TAG_DESCR:"GENERAL PURPOSE CABINET",PARENT_TAG:"21-TUCP-9574002",DIMENSION:"2100 x 800 x 800",SN:111,POSITION:"R",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"GENERAL PURPOSE CABINET",ROOM:"Central Equipment Room"},
      {NO:149,TAG:"21-TUCP-9535001",TAG_DESCR:"FIBER OPTIC CABINET",PARENT_TAG:"21-TUCP-9535001",DIMENSION:"800 x 800",SN:2,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ06",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",TAG_ORIGINAL_DESCR:"FIBER OPTIC CABINET",ROOM:"Telecomms Room"},
      {NO:150,TAG:"21-TUCP-9574001",TAG_DESCR:"NETWORK MANAGEMENT SYSTEM",PARENT_TAG:"21-TUCP-9574001",DIMENSION:"800 x 800",SN:3,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ06",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",TAG_ORIGINAL_DESCR:"NETWORK MANAGEMENT SYSTEM",ROOM:"Telecomms Room"},
      {NO:152,TAG:"21-TUCP-9564001",TAG_DESCR:"ACCESS CONTROL SYSTEM",PARENT_TAG:"21-TUCP-9564001",DIMENSION:"800 x 800",SN:5,POSITION:"R",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ06",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",TAG_ORIGINAL_DESCR:"ACCESS CONTROL SYSTEM",ROOM:"Telecomms Room"},
      {NO:157,TAG:"21-TUCP-9514001",TAG_DESCR:"TELEPHONE / VOICE RECORDER",PARENT_TAG:"21-TUCP-9514001",DIMENSION:"800 x 800",SN:10,POSITION:"R",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ06",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",TAG_ORIGINAL_DESCR:"TELEPHONE / VOICE RECORDER",ROOM:"Telecomms Room"},
      {NO:161,TAG:"21-TUCP-9555001",TAG_DESCR:"BERTHING APPROACH AIDS",PARENT_TAG:"21-TUCP-9555001",DIMENSION:"800 x 800",SN:14,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ06",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",TAG_ORIGINAL_DESCR:"BERTHING APPROACH AIDS",ROOM:"Telecomms Room"},
      {NO:169,TAG:"21-TUCP-6550001",TAG_DESCR:"RADAR EARLY WARNING SYSTEM",PARENT_TAG:"21-TUCP-6550001",DIMENSION:"800 x 800",SN:22,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ06",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",TAG_ORIGINAL_DESCR:"RADAR EARLY WARNING SYSTEM",ROOM:"Telecomms Room"},
      {NO:2,TAG:"21-IDB-671433",TAG_DESCR:"HER - UPS 3h ICSS",PARENT_TAG:"21-IDB-671433",PROCUREDBY:"TPFMC",DIMENSION:"2100 x 800 x 800",WEIGHT:"500",SN:2,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"S2020-12-17",IRN_FORECAST:"TBD",ETA_PLAN:"S2021-02-25",ETA_FORECAST:"TBD",ROS:"S2019-01-01",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-786,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS04",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"HER - UPS 3h ICSS",ROOM:"Instrument Room - MS 1st Level"},
      {NO:3,TAG:"21-IDB-671434",TAG_DESCR:"HER - UPS 1h TELECOM",PARENT_TAG:"21-IDB-671434",PROCUREDBY:"TPFMC",DIMENSION:"2100 x 800 x 800",WEIGHT:"HOLD",SN:3,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS04",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"HER - UPS 1h TELECOM",ROOM:"Instrument Room - MS 1st Level"},
      {NO:4,TAG:"21-IDB-667638",TAG_DESCR:"HER - Essential",PARENT_TAG:"21-IDB-667638",PROCUREDBY:"TPFMC",DIMENSION:"2100 x 800 x 800",WEIGHT:"500",SN:4,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"S2020-12-17",IRN_FORECAST:"TBD",ETA_PLAN:"S2021-02-25",ETA_FORECAST:"TBD",ROS:"S2019-01-01",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-786,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS04",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"HER - Essential",ROOM:"Instrument Room - MS 1st Level"},
      {NO:5,TAG:"21-IPC-602302",TAG_DESCR:"HER EIOC CABINET ",PARENT_TAG:"21-IPC-602302",MR_TAG:"MS002-EL-REQ-012-93001",PROCUREDBY:"TPFMC",DISC:"Electrical",DIMENSION:"2100 x 800 x 800",WEIGHT:"525",SN:6,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"Q2019-08-11",PO_FORECAST:"Q2020-02-20",PO_ACTUAL:"Q2020-02-20",FAT_PLAN:"Q2020-12-22",FAT_FORECAST:"TBD",IRN_PLAN:"Q2020-12-29",IRN_FORECAST:"TBD",ETA_PLAN:"Q2021-01-23",ETA_FORECAST:"TBD",ROS:"Q2021-01-13",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-10,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS04",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"HER EIOC CABINET ",ROOM:"Instrument Room - MS 1st Level"},
      {NO:6,TAG:"21-IPC-602301",TAG_DESCR:"HER PAS CONTROLLER CABINET",PARENT_TAG:"21-IPC-602301",MR_TAG:"MS002-IC-REQ-012-00008",PROCUREDBY:"TPFMC",DIMENSION:"2100 x 800 x 800",WEIGHT:"540",SN:7,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"S2020-11-20",IRN_FORECAST:"TBD",ETA_PLAN:"S2020-12-05",ETA_FORECAST:"TBD",ROS:"S2020-08-15",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-112,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS04",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"HER PAS CONTROLLER CABINET",ROOM:"Instrument Room - MS 1st Level"},
      {NO:7,TAG:"21-INC-602301",TAG_DESCR:"HER PAS NETWORK CABINET",PARENT_TAG:"21-INC-602301",MR_TAG:"MS002-IC-REQ-012-00008",PROCUREDBY:"TPFMC",DIMENSION:"2100 x 800 x 800",WEIGHT:"648",SN:8,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"S2020-11-20",IRN_FORECAST:"TBD",ETA_PLAN:"S2020-12-05",ETA_FORECAST:"TBD",ROS:"S2020-08-15",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-112,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS04",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"HER PAS NETWORK CABINET",ROOM:"Instrument Room - MS 1st Level"},
      {NO:8,TAG:"21-IMC-602301",TAG_DESCR:"HER PAS IO CABINET",PARENT_TAG:"21-IMC-602301",MR_TAG:"MS002-EL-REQ-012-93001",PROCUREDBY:"TPFMC",DISC:"Electrical",DIMENSION:"2100 x 800 x 800",WEIGHT:"585",SN:9,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"Q2019-08-11",PO_FORECAST:"Q2020-02-20",PO_ACTUAL:"Q2020-02-20",FAT_PLAN:"Q2020-12-22",FAT_FORECAST:"TBD",IRN_PLAN:"Q2020-12-29",IRN_FORECAST:"TBD",ETA_PLAN:"Q2021-01-23",ETA_FORECAST:"TBD",ROS:"Q2021-01-13",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-10,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS04",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"HER PAS IO CABINET",ROOM:"Instrument Room - MS 1st Level"},
      {NO:9,TAG:"21-IMC-602302",TAG_DESCR:"HER PAS IO CABINET",PARENT_TAG:"21-IMC-602302",MR_TAG:"MS002-EL-REQ-012-93001",PROCUREDBY:"TPFMC",DISC:"Electrical",DIMENSION:"2100 x 800 x 800",WEIGHT:"630",SN:10,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"Q2019-08-11",PO_FORECAST:"Q2020-02-20",PO_ACTUAL:"Q2020-02-20",FAT_PLAN:"Q2020-12-22",FAT_FORECAST:"TBD",IRN_PLAN:"Q2020-12-29",IRN_FORECAST:"TBD",ETA_PLAN:"Q2021-01-23",ETA_FORECAST:"TBD",ROS:"Q2021-01-13",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-10,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS04",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"HER PAS IO CABINET",ROOM:"Instrument Room - MS 1st Level"},
      {NO:10,TAG:"21-IMC-602303",TAG_DESCR:"HER PAS IO CABINET",PARENT_TAG:"21-IMC-602303",MR_TAG:"MS002-EL-REQ-012-93001",PROCUREDBY:"TPFMC",DISC:"Electrical",DIMENSION:"2100 x 800 x 800",WEIGHT:"630",SN:11,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"Q2019-08-11",PO_FORECAST:"Q2020-02-20",PO_ACTUAL:"Q2020-02-20",FAT_PLAN:"Q2020-12-22",FAT_FORECAST:"TBD",IRN_PLAN:"Q2020-12-29",IRN_FORECAST:"TBD",ETA_PLAN:"Q2021-01-23",ETA_FORECAST:"TBD",ROS:"Q2021-01-13",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-10,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS04",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"HER PAS IO CABINET",ROOM:"Instrument Room - MS 1st Level"},
      {NO:11,TAG:"21-IMC-602304",TAG_DESCR:"HER PAS IO CABINET",PARENT_TAG:"21-IMC-602304",MR_TAG:"MS002-EL-REQ-012-93001",PROCUREDBY:"TPFMC",DISC:"Electrical",DIMENSION:"2100 x 800 x 800",WEIGHT:"630",SN:12,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"Q2019-08-11",PO_FORECAST:"Q2020-02-20",PO_ACTUAL:"Q2020-02-20",FAT_PLAN:"Q2020-12-22",FAT_FORECAST:"TBD",IRN_PLAN:"Q2020-12-29",IRN_FORECAST:"TBD",ETA_PLAN:"Q2021-01-23",ETA_FORECAST:"TBD",ROS:"Q2021-01-13",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-10,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS04",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"HER PAS IO CABINET",ROOM:"Instrument Room - MS 1st Level"},
      {NO:12,TAG:"21-IMC-601301",TAG_DESCR:"HER SIS IO CABINET",PARENT_TAG:"21-IMC-601301",MR_TAG:"MS002-IC-REQ-012-00008",PROCUREDBY:"TPFMC",DIMENSION:"2100 x 800 x 800",WEIGHT:"540",SN:13,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"S2020-11-20",IRN_FORECAST:"TBD",ETA_PLAN:"S2020-12-05",ETA_FORECAST:"TBD",ROS:"S2020-08-15",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-112,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS04",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"HER SIS IO CABINET",ROOM:"Instrument Room - MS 1st Level"},
      {NO:170,TAG:"21-TUCP-6550002",TAG_DESCR:"RADAR EARLY WARNING SYSTEM",PARENT_TAG:"21-TUCP-6550002",DIMENSION:"800 x 800",SN:23,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ06",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",TAG_ORIGINAL_DESCR:"RADAR EARLY WARNING SYSTEM",ROOM:"Telecomms Room"},
      {NO:171,TAG:"21-TUCP-9525001",TAG_DESCR:"MICROWAVE LINE OF SIGHT SYSTEM",PARENT_TAG:"21-TUCP-9525001",DIMENSION:"800 x 800",SN:24,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ06",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",TAG_ORIGINAL_DESCR:"MICROWAVE LINE OF SIGHT SYSTEM",ROOM:"Telecomms Room"},
      {NO:172,TAG:"21-TUCP-9500001",TAG_DESCR:"GENERAL PURPOSE CABINET",PARENT_TAG:"21-TUCP-9500001",DIMENSION:"800 x 800",SN:25,POSITION:"R",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ06",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",TAG_ORIGINAL_DESCR:"GENERAL PURPOSE CABINET",ROOM:"Telecomms Room"},
      {NO:173,TAG:"21-TUCP-9500002",TAG_DESCR:"GENERAL PURPOSE CABINET",PARENT_TAG:"21-TUCP-9500002",DIMENSION:"800 x 800",SN:26,POSITION:"R",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ06",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",TAG_ORIGINAL_DESCR:"GENERAL PURPOSE CABINET",ROOM:"Telecomms Room"},
      {NO:174,TAG:"21-TUCP-6220001",TAG_DESCR:"GENERAL PURPOSE CABINET",PARENT_TAG:"21-TUCP-6220001",DIMENSION:"800 x 800",SN:27,POSITION:"R",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ06",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",TAG_ORIGINAL_DESCR:"GENERAL PURPOSE CABINET",ROOM:"Telecomms Room"},
      {NO:175,TAG:"21-TUCP-9500003",TAG_DESCR:"GENERAL PURPOSE CABINET",PARENT_TAG:"21-TUCP-9500003",DIMENSION:"800 x 800",SN:28,POSITION:"R",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ06",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",TAG_ORIGINAL_DESCR:"GENERAL PURPOSE CABINET",ROOM:"Telecomms Room"},
      {NO:177,TAG:"21-IDB-671435",TAG_DESCR:"POWER DISTRIBUTION BOARD - UPS TEL 1H",PARENT_TAG:"21-IDB-671435",DIMENSION:"800 x 800",SN:40,POSITION:"R",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ06",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"S2020-12-17",IRN_FORECAST:"TBD",ETA_PLAN:"S2021-02-25",ETA_FORECAST:"TBD",ROS:"S2019-01-01",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-786,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",TAG_ORIGINAL_DESCR:"POWER DISTRIBUTION BOARD - UPS TEL 1H",ROOM:"Telecomms Room"},
      {NO:178,TAG:"21-IDB-671436",TAG_DESCR:"POWER DISTRIBUTION BOARD - UPS TEL 4H",PARENT_TAG:"21-IDB-671436",DIMENSION:"800 x 800",SN:41,POSITION:"R",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ06",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"S2020-12-17",IRN_FORECAST:"TBD",ETA_PLAN:"S2021-02-25",ETA_FORECAST:"TBD",ROS:"S2019-01-01",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-786,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",TAG_ORIGINAL_DESCR:"POWER DISTRIBUTION BOARD - UPS TEL 4H",ROOM:"Telecomms Room"},
      {NO:179,TAG:"21-IDB-667637",TAG_DESCR:"POWER DISTRIBUTION BOARD ESSENTIAL",PARENT_TAG:"21-IDB-667637",DIMENSION:"800 x 800",SN:42,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ06",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"S2020-12-17",IRN_FORECAST:"TBD",ETA_PLAN:"S2021-02-25",ETA_FORECAST:"TBD",ROS:"S2019-01-01",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-786,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",TAG_ORIGINAL_DESCR:"POWER DISTRIBUTION BOARD ESSENTIAL",ROOM:"Telecomms Room"},
      {NO:180,TAG:"21-ECP-605023",TAG_DESCR:"ELECTRICAL FO DISTRIBUTION CABINET",PARENT_TAG:"21-ECP-605023",MR_TAG:"MS002-EL-REQ-012-00051",DIMENSION:"600 x 250 x 600",SN:50,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ06",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-11-04",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-10-29",ETA_PLAN:"S2021-01-01",ETA_FORECAST:"TBD",ROS:"S2021-03-27",ROS_FORECAST:"TBD",ROS_ETA_DELTA:85,STATUS:"IRN",STATUS_DESCR:"4.IRN",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",TAG_ORIGINAL_DESCR:"ELECTRICAL FO DISTRIBUTION CABINET",ROOM:"Telecomms Room"},
      {NO:181,TAG:"21-ELP-667666",TAG_DESCR:"NORMAL LIGHTING PANEL",PARENT_TAG:"21-ELP-667666",DIMENSION:"800 x 200 x 1200",SN:51,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ06",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",TAG_ORIGINAL_DESCR:"NORMAL LIGHTING PANEL",ROOM:"Telecomms Room"},
      {NO:182,TAG:"21-ETR-877001A",TAG_DESCR:"MAIN NORMAL TRANSFORMER 6.6/0.72kV 2500kVA",PARENT_TAG:"21-ETR-877001A",DIMENSION:"2600 x 1600 x 2550",WEIGHT:"5800",SN:1,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-08-17",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-06-30",ETA_PLAN:"S2020-09-22",ETA_FORECAST:"TBD",ETA_ACTUAL:"S9/29/2020",ROS:"S2020-12-21",ROS_FORECAST:"TBD",ROS_ETA_DELTA:90,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"MAIN NORMAL TRANSFORMER 6.6/0.72kV 2500kVA",ROOM:"Electrical HV Room - Level 1"},
      {NO:183,TAG:"21-ETR-877001B",TAG_DESCR:"MAIN NORMAL TRANSFORMER 6.6/0.72kV 2500kVA",PARENT_TAG:"21-ETR-877001B",DIMENSION:"2600 x 1600 x 2550",WEIGHT:"5800",SN:2,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-08-17",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-06-30",ETA_PLAN:"S2020-09-22",ETA_FORECAST:"TBD",ETA_ACTUAL:"S9/29/2020",ROS:"S2020-12-21",ROS_FORECAST:"TBD",ROS_ETA_DELTA:90,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",TAG_ORIGINAL_DESCR:"MAIN NORMAL TRANSFORMER 6.6/0.72kV 2500kVA",ROOM:"Electrical HV Room - Level 1"},
      {NO:14,TAG:"21-IMC-601303",TAG_DESCR:"HER SIS IO CABINET",PARENT_TAG:"21-IMC-601303",MR_TAG:"MS002-EL-REQ-012-93001",PROCUREDBY:"TPFMC",DISC:"Electrical",DIMENSION:"2100 x 800 x 800",WEIGHT:"630",SN:15,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"Q2019-08-11",PO_FORECAST:"Q2020-02-20",PO_ACTUAL:"Q2020-02-20",FAT_PLAN:"Q2020-12-22",FAT_FORECAST:"TBD",IRN_PLAN:"Q2020-12-29",IRN_FORECAST:"TBD",ETA_PLAN:"Q2021-01-23",ETA_FORECAST:"TBD",ROS:"Q2021-01-13",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-10,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS04",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"HER SIS IO CABINET",ROOM:"Instrument Room - MS 1st Level"},
      {NO:15,TAG:"21-ISC-603002",TAG_DESCR:"HER SIS-FGS SAFETY CONTROLLER CABINET",PARENT_TAG:"21-ISC-603002",MR_TAG:"MS002-EL-REQ-012-93001",PROCUREDBY:"TPFMC",DISC:"Electrical",DIMENSION:"2100 x 800 x 800",WEIGHT:"540",SN:16,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"Q2019-08-11",PO_FORECAST:"Q2020-02-20",PO_ACTUAL:"Q2020-02-20",FAT_PLAN:"Q2020-12-22",FAT_FORECAST:"TBD",IRN_PLAN:"Q2020-12-29",IRN_FORECAST:"TBD",ETA_PLAN:"Q2021-01-23",ETA_FORECAST:"TBD",ROS:"Q2021-01-13",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-10,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS04",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"HER SIS-FGS SAFETY CONTROLLER CABINET",ROOM:"Instrument Room - MS 1st Level"},
      {NO:16,TAG:"21-INC-603003",TAG_DESCR:"HER SIS-FGS NETWORK CABINET",PARENT_TAG:"21-INC-603003",MR_TAG:"MS002-EL-REQ-012-93001",PROCUREDBY:"TPFMC",DISC:"Electrical",DIMENSION:"2100 x 800 x 800",WEIGHT:"648",SN:17,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"Q2019-08-11",PO_FORECAST:"Q2020-02-20",PO_ACTUAL:"Q2020-02-20",FAT_PLAN:"Q2020-12-22",FAT_FORECAST:"TBD",IRN_PLAN:"Q2020-12-29",IRN_FORECAST:"TBD",ETA_PLAN:"Q2021-01-23",ETA_FORECAST:"TBD",ROS:"Q2021-01-13",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-10,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS04",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"HER SIS-FGS NETWORK CABINET",ROOM:"Instrument Room - MS 1st Level"},
      {NO:17,TAG:"21-IPP-603005",TAG_DESCR:"HER FOPP CABINET",PARENT_TAG:"21-IPP-603005",MR_TAG:"MS002-EL-REQ-012-93001",PROCUREDBY:"TPFMC",DISC:"Electrical",DIMENSION:"2100 x 800 x 800",WEIGHT:"450",SN:18,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"Q2019-08-11",PO_FORECAST:"Q2020-02-20",PO_ACTUAL:"Q2020-02-20",FAT_PLAN:"Q2020-12-22",FAT_FORECAST:"TBD",IRN_PLAN:"Q2020-12-29",IRN_FORECAST:"TBD",ETA_PLAN:"Q2021-01-23",ETA_FORECAST:"TBD",ROS:"Q2021-01-13",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-10,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS04",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"HER FOPP CABINET",ROOM:"Instrument Room - MS 1st Level"},
      {NO:18,TAG:"21-IPC-603006",TAG_DESCR:"HER FOPP CABINET",PARENT_TAG:"21-IPC-603006",MR_TAG:"MS002-EL-REQ-012-93001",PROCUREDBY:"TPFMC",DISC:"Electrical",DIMENSION:"2100 x 800 x 800",WEIGHT:"450",SN:19,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"Q2019-08-11",PO_FORECAST:"Q2020-02-20",PO_ACTUAL:"Q2020-02-20",FAT_PLAN:"Q2020-12-22",FAT_FORECAST:"TBD",IRN_PLAN:"Q2020-12-29",IRN_FORECAST:"TBD",ETA_PLAN:"Q2021-01-23",ETA_FORECAST:"TBD",ROS:"Q2021-01-13",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-10,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS04",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"HER FOPP CABINET",ROOM:"Instrument Room - MS 1st Level"},
      {NO:19,TAG:"21-IMC-607301",TAG_DESCR:"HER FGS IO CABINET",PARENT_TAG:"21-IMC-607301",MR_TAG:"MS002-IC-REQ-012-00008",PROCUREDBY:"TPFMC",DIMENSION:"2100 x 800 x 800",WEIGHT:"630",SN:20,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"S2020-11-20",IRN_FORECAST:"TBD",ETA_PLAN:"S2020-12-05",ETA_FORECAST:"TBD",ROS:"S2020-08-15",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-112,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS04",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"HER FGS IO CABINET",ROOM:"Instrument Room - MS 1st Level"},
      {NO:20,TAG:"21-ISC-607301",TAG_DESCR:"HER AFDS Cabinet",PARENT_TAG:"21-ISC-607301",MR_TAG:"MS002-ME-REQ-012-93001",PROCUREDBY:"TPFMC",DISC:"Package",DIMENSION:"2100 x 800 x 800",WEIGHT:"750",SN:21,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"Q2019-07-19",PO_FORECAST:"Q2020-02-26",PO_ACTUAL:"Q2020-02-26",FAT_PLAN:"Q2020-12-10",FAT_FORECAST:"TBD",IRN_PLAN:"Q2020-12-25",IRN_FORECAST:"TBD",ETA_PLAN:"Q2020-12-17",ETA_FORECAST:"TBD",ROS:"Q2021-03-01",ROS_FORECAST:"TBD",ROS_ETA_DELTA:74,STATUS:"PO",STATUS_DESCR:"2.PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS04",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"HER AFDS Cabinet",ROOM:"Instrument Room - MS 1st Level"},
      {NO:21,TAG:"21-TUCP-9534009",TAG_DESCR:"HER STRUCTURED CABLING SYSTEM CABINET",PARENT_TAG:"21-TUCP-9534009",PROCUREDBY:"TPFMC",DIMENSION:"2100 x 800 x 800",WEIGHT:"500",SN:22,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS04",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"HER STRUCTURED CABLING SYSTEM CABINET",ROOM:"Instrument Room - MS 1st Level"},
      {NO:22,TAG:"21-ICP-608201",TAG_DESCR:"TANK GAUGING SYSTEM CABINET",PARENT_TAG:"21-ICP-608201",PROCUREDBY:"COSCO",DIMENSION:"2100 x 800 x 800",WEIGHT:"365",SN:28,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS04",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"TANK GAUGING SYSTEM CABINET",ROOM:"Instrument Room - MS 1st Level"},
      {NO:23,TAG:"21-ECP-811030",TAG_DESCR:"Compressor Control Panel",PARENT_TAG:"21-ECP-811030",PROCUREDBY:"COSCO",DIMENSION:"2100 x 800 x 800",WEIGHT:"160",SN:29,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS04",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"Compressor Control Panel",ROOM:"Instrument Room - MS 1st Level"},
      {NO:24,TAG:"21-ECP-891070",TAG_DESCR:"CARGO PUMP PACKAGE",PARENT_TAG:"21-ECP-891070",MR_TAG:"MS002-ME-REQ-012-91008",PROCUREDBY:"COSCO",DIMENSION:"2100 x 1600 x 800",WEIGHT:"480",SN:30,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"N2019-06-21",PO_FORECAST:"N2019-06-21",PO_ACTUAL:"N2019-06-21",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",IRN_ACTUAL:"Done",ETA_PLAN:"N2020-07-07",ETA_FORECAST:"TBD",ETA_ACTUAL:"N2020-07-07",ROS:"N2021-04-19",ROS_FORECAST:"TBD",ROS_ETA_DELTA:286,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS04",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"CARGO PUMP PACKAGE",ROOM:"Instrument Room - MS 1st Level"},
      {NO:144,TAG:"21-TUCP-6210005",TAG_DESCR:"PAGA",PARENT_TAG:"21-TUCP-6210005",DIMENSION:"2100 x 800 x 800",SN:107,POSITION:"R",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"PAGA",ROOM:"Central Equipment Room"},
      {NO:145,TAG:"21-TUCP-6210006",TAG_DESCR:"PAGA",PARENT_TAG:"21-TUCP-6210006",DIMENSION:"2100 x 800 x 800",SN:108,POSITION:"R",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ02",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",TAG_ORIGINAL_DESCR:"PAGA",ROOM:"Central Equipment Room"},
      {NO:151,TAG:"21-TUCP-9224001",TAG_DESCR:"ENTERTAINMENT",PARENT_TAG:"21-TUCP-9224001",DIMENSION:"800 x 800",SN:4,POSITION:"L",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ06",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",TAG_ORIGINAL_DESCR:"ENTERTAINMENT",ROOM:"Telecomms Room"},
      {NO:26,TAG:"21-EDB-997003",TAG_DESCR:"HVAV EDB CABINET",PARENT_TAG:"21-EDB-997003",PROCUREDBY:"COSCO",DIMENSION:"2100 x 1600 x 839",WEIGHT:"560",SN:32,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS04",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"HVAV EDB CABINET",ROOM:"Instrument Room - MS 1st Level"},
      {NO:27,TAG:"NA",TAG_DESCR:"TABLE AND CHAIR",PARENT_TAG:"NA",PROCUREDBY:"COSCO",DIMENSION:"800 x 1600 x 600",WEIGHT:"60",SN:33,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS04",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"TABLE AND CHAIR",ROOM:"Instrument Room - MS 1st Level"},
      {NO:28,TAG:"21-ESB-667401",TAG_DESCR:"ESSENTIAL LV SWITCHGEAR 0.69KV",PARENT_TAG:"21-ESB-667401",MR_TAG:"MS002-EL-REQ-012-00011",PROCUREDBY:"TPFMC",DIMENSION:"11664 x 1252 x 2261",WEIGHT:"12250",SN:1,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-10-31",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-11-26",ETA_PLAN:"S2021-01-09",ETA_FORECAST:"TBD",ROS:"S2021-01-28",ROS_FORECAST:"TBD",ROS_ETA_DELTA:19,STATUS:"IRN",STATUS_DESCR:"4.IRN",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS02",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"ESSENTIAL LV SWITCHGEAR 0.69KV",ROOM:"Electrical Rooms - MS 3rd Level"},
      {NO:29,TAG:"21-ESB-667601",TAG_DESCR:"NORMAL / ESSENTIAL LV DISTRIBUTION BOARD 0.4KV",PARENT_TAG:"21-ESB-667601",MR_TAG:"MS002-EL-REQ-012-00011",PROCUREDBY:"TPFMC",DIMENSION:"9059 x 851/1052 x 2261",WEIGHT:"6800",SN:2,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-11-24",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-11-26",ETA_PLAN:"S2021-02-02",ETA_FORECAST:"TBD",ROS:"S2021-01-28",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-5,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS02",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"NORMAL / ESSENTIAL LV DISTRIBUTION BOARD 0.4KV",ROOM:"Electrical Rooms - MS 3rd Level"},
      {NO:30,TAG:"21-ECP-605031",TAG_DESCR:"OWS/COMMUNICATION CABINET",PARENT_TAG:"21-ECP-605031",PROCUREDBY:"TPFMC",DIMENSION:"800 x 800 x 2100",WEIGHT:"600",SN:3,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-11-04",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-10-29",ETA_PLAN:"S2021-01-01",ETA_FORECAST:"TBD",ROS:"S2020-10-09",ROS_FORECAST:"TBD",ROS_ETA_DELTA:-84,STATUS:"ROS_PAST",STATUS_DESCR:"ETA after ROS",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS02",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"OWS/COMMUNICATION CABINET",ROOM:"Electrical Rooms - MS 3rd Level"},
      {NO:31,TAG:"21-ECP-605032",TAG_DESCR:"REMOTE I/O CABINET",PARENT_TAG:"21-ECP-605032",PROCUREDBY:"TPFMC",DIMENSION:"800 x 800 x 2100",WEIGHT:"600",SN:4,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-11-04",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-10-29",ETA_PLAN:"S2021-01-01",ETA_FORECAST:"TBD",ROS:"S2021-03-27",ROS_FORECAST:"TBD",ROS_ETA_DELTA:85,STATUS:"IRN",STATUS_DESCR:"4.IRN",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS02",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"REMOTE I/O CABINET",ROOM:"Electrical Rooms - MS 3rd Level"},
      {NO:32,TAG:"21-EIP-870003",TAG_DESCR:"IRP - INTERFACE RELAY PANEL",PARENT_TAG:"21-EIP-870003",PROCUREDBY:"TPFMC",DIMENSION:"2400 x 800 x 2000",WEIGHT:"1800",SN:5,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS02",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"IRP - INTERFACE RELAY PANEL",ROOM:"Electrical Rooms - MS 3rd Level"},
      {NO:33,TAG:"21-LH-667401",TAG_DESCR:"ACB TROLLEY FOR LV SWITCHGEAR ",PARENT_TAG:"21-LH-667401",PROCUREDBY:"TPFMC",DIMENSION:"1100 x 550 x 1000",WEIGHT:"80",SN:6,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS02",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"ACB TROLLEY FOR LV SWITCHGEAR ",ROOM:"Electrical Rooms - MS 3rd Level"},
      {NO:34,TAG:"21-EDB-667635",TAG_DESCR:"Sub Distribution Auxiliary Panel 2 - (Power SWBD)",PARENT_TAG:"21-EDB-667635",PROCUREDBY:"COSCO",DIMENSION:"1500 x 500 x 1800",WEIGHT:"50",SN:7,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS02",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"Sub Distribution Auxiliary Panel 2 - (Power SWBD)",ROOM:"Electrical Rooms - MS 3rd Level"},
      {NO:35,TAG:"HOLD",TAG_DESCR:"FIBRE OPTIC PATCH PANEL -LV",PARENT_TAG:"HOLD",PROCUREDBY:"TPFMC",DIMENSION:"800 x 800 x 2000",WEIGHT:"20",SN:8,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"N2020-12-08",PO_FORECAST:"N2020-12-08",PO_ACTUAL:"N2019-09-12",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-11-20",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-08-03",ETA_PLAN:"N2022-02-18",ETA_FORECAST:"TBD",ETA_ACTUAL:"N2020-07-08",ROS:"N2022-03-14",ROS_FORECAST:"TBD",ROS_ETA_DELTA:24,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS02",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"FIBRE OPTIC PATCH PANEL -LV",ROOM:"Electrical Rooms - MS 3rd Level"},
      {NO:36,TAG:"21-EIP-667010",TAG_DESCR:"UCP For Key Service Generator",PARENT_TAG:"21-EIP-667010",MR_TAG:"MS002-ME-REQ-012-91012",PO_CODE:"PME914",PROCUREDBY:"COSCO",DISC:"Package",DIMENSION:"1600 x 636 x 2157",WEIGHT:"500",SN:11,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"R2019-06-26",PO_FORECAST:"R2019-06-26",PO_ACTUAL:"R2019-06-26",FAT_PLAN:"R2020-04-08",FAT_FORECAST:"R2020-04-08",FAT_ACTUAL:"R2020-04-08",IRN_PLAN:"R2020-05-13",IRN_FORECAST:"R2020-05-13",IRN_ACTUAL:"R2020-05-13",ETA_PLAN:"R2020-07-21",ETA_FORECAST:"R2020-07-21",ETA_ACTUAL:"R2020-07-21",ROS:"R2020-07-21",ROS_FORECAST:"R2020-07-21",ROS_ETA_DELTA:0,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS02",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"UCP For Key Service Generator",ROOM:"Electrical Rooms - MS 3rd Level"},
      {NO:154,TAG:"21-TUCP-9528001",TAG_DESCR:"UHF RADIO",PARENT_TAG:"21-TUCP-9528001",DIMENSION:"1000 x 800",SN:7,POSITION:"R",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ06",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",TAG_ORIGINAL_DESCR:"UHF RADIO",ROOM:"Telecomms Room"},
      {NO:155,TAG:"21-TUCP-9528002",TAG_DESCR:"UHF RADIO",PARENT_TAG:"21-TUCP-9528002",DIMENSION:"1000 x 800",SN:8,POSITION:"R",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ06",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",TAG_ORIGINAL_DESCR:"UHF RADIO",ROOM:"Telecomms Room"},
      {NO:156,TAG:"21-TUCP-9528003",TAG_DESCR:"UHF RADIO",PARENT_TAG:"21-TUCP-9528003",DIMENSION:"1000 x 800",SN:9,POSITION:"R",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ06",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",TAG_ORIGINAL_DESCR:"UHF RADIO",ROOM:"Telecomms Room"},
      {NO:38,TAG:"NA",TAG_DESCR:"SAFETY KIT FOR LV SWITCHGEAR",PARENT_TAG:"NA",PROCUREDBY:"TPFMC",DIMENSION:"600 x 450 x 800",WEIGHT:"50",SN:13,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS02",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"SAFETY KIT FOR LV SWITCHGEAR",ROOM:"Electrical Rooms - MS 3rd Level"},
      {NO:39,TAG:"HOLD",TAG_DESCR:"FIBRE OPTIC PATCH PANEL -HV",PARENT_TAG:"HOLD",PROCUREDBY:"TPFMC",DIMENSION:"800 x 800 x 2000",WEIGHT:"200",SN:14,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"N2020-12-08",PO_FORECAST:"N2020-12-08",PO_ACTUAL:"N2019-09-12",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-11-20",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-08-03",ETA_PLAN:"N2022-02-18",ETA_FORECAST:"TBD",ETA_ACTUAL:"N2020-07-08",ROS:"N2022-03-14",ROS_FORECAST:"TBD",ROS_ETA_DELTA:24,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS02",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"FIBRE OPTIC PATCH PANEL -HV",ROOM:"Electrical Rooms - MS 3rd Level"},
      {NO:40,TAG:"21-ELP-667631",TAG_DESCR:"Normal Lighting Panel -02",PARENT_TAG:"21-ELP-667631",PROCUREDBY:"COSCO",DIMENSION:"1100 x 500 x 1800",WEIGHT:"50",SN:15,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS02",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"Normal Lighting Panel -02",ROOM:"Electrical Rooms - MS 3rd Level"},
      {NO:41,TAG:"21-EDB-662433",TAG_DESCR:"Sub Distribution Auxiliary Panel 3 - (EM)",PARENT_TAG:"21-EDB-662433",PROCUREDBY:"COSCO",DIMENSION:"800 x 500 x 1800",WEIGHT:"50",SN:16,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS02",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"Sub Distribution Auxiliary Panel 3 - (EM)",ROOM:"Electrical Rooms - MS 3rd Level"},
      {NO:42,TAG:"21-ETR-667302A",TAG_DESCR:"MAIN ESSENTIAL TRANSFORMER 6.6/0.72KV 2500KVA",PARENT_TAG:"21-ETR-667302A",PROCUREDBY:"TPFMC",DIMENSION:"2600 x 1600 x 2550",WEIGHT:"5800",SN:20,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-08-17",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-06-30",ETA_PLAN:"S2020-09-22",ETA_FORECAST:"TBD",ETA_ACTUAL:"S9/29/2020",ROS:"S2020-12-21",ROS_FORECAST:"TBD",ROS_ETA_DELTA:90,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS02",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"MAIN ESSENTIAL TRANSFORMER 6.6/0.72KV 2500KVA",ROOM:"Electrical Rooms - MS 3rd Level"},
      {NO:43,TAG:"21-ETR-667302B",TAG_DESCR:"MAIN ESSENTIAL TRANSFORMER 6.6/0.72KV 2500KVA",PARENT_TAG:"21-ETR-667302B",PROCUREDBY:"TPFMC",DIMENSION:"2600 x 1600 x 2550",WEIGHT:"5800",SN:21,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-08-17",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-06-30",ETA_PLAN:"S2020-09-22",ETA_FORECAST:"TBD",ETA_ACTUAL:"S9/29/2020",ROS:"S2020-12-21",ROS_FORECAST:"TBD",ROS_ETA_DELTA:90,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS02",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"MAIN ESSENTIAL TRANSFORMER 6.6/0.72KV 2500KVA",ROOM:"Electrical Rooms - MS 3rd Level"},
      {NO:44,TAG:"21-ETR-667301A",TAG_DESCR:"NORMAL / ESSENTIAL TRANSFORMER 6.6/0.42KV 2000KVA",PARENT_TAG:"21-ETR-667301A",PROCUREDBY:"TPFMC",DIMENSION:"2500 x 1590 x 2500",WEIGHT:"5400",SN:22,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-08-17",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-06-30",ETA_PLAN:"S2020-09-22",ETA_FORECAST:"TBD",ETA_ACTUAL:"S9/29/2020",ROS:"S2020-12-21",ROS_FORECAST:"TBD",ROS_ETA_DELTA:90,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS02",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"NORMAL / ESSENTIAL TRANSFORMER 6.6/0.42KV 2000KVA",ROOM:"Electrical Rooms - MS 3rd Level"},
      {NO:45,TAG:"21-ETR-667301B",TAG_DESCR:"NORMAL / ESSENTIAL TRANSFORMER 6.6/0.42KV 2000KVA",PARENT_TAG:"21-ETR-667301B",PROCUREDBY:"TPFMC",DIMENSION:"2500 x 1590 x 2500",WEIGHT:"5400",SN:23,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-08-17",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-06-30",ETA_PLAN:"S2020-09-22",ETA_FORECAST:"TBD",ETA_ACTUAL:"S9/29/2020",ROS:"S2020-12-21",ROS_FORECAST:"TBD",ROS_ETA_DELTA:90,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS02",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"NORMAL / ESSENTIAL TRANSFORMER 6.6/0.42KV 2000KVA",ROOM:"Electrical Rooms - MS 3rd Level"},
      {NO:46,TAG:"21-ETR-667303A",TAG_DESCR:"EMERGENCY TRANSFORMER 6.6/0.72KV 2000KVA",PARENT_TAG:"21-ETR-667303A",PROCUREDBY:"TPFMC",DIMENSION:"2500 x 1590 x 2500",WEIGHT:"5100",SN:24,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-08-17",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-06-30",ETA_PLAN:"S2020-09-22",ETA_FORECAST:"TBD",ETA_ACTUAL:"S9/29/2020",ROS:"S2020-12-21",ROS_FORECAST:"TBD",ROS_ETA_DELTA:90,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS02",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"EMERGENCY TRANSFORMER 6.6/0.72KV 2000KVA",ROOM:"Electrical Rooms - MS 3rd Level"},
      {NO:47,TAG:"21-ETR-667303B",TAG_DESCR:"EMERGENCY TRANSFORMER 6.6/0.72KV 2000KVA",PARENT_TAG:"21-ETR-667303B",PROCUREDBY:"TPFMC",DIMENSION:"2500 x 1590 x 2500",WEIGHT:"5100",SN:25,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"TBD",PO_FORECAST:"TBD",PO_ACTUAL:"Done",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",FAT_ACTUAL:"Done",IRN_PLAN:"S2020-08-17",IRN_FORECAST:"TBD",IRN_ACTUAL:"S2020-06-30",ETA_PLAN:"S2020-09-22",ETA_FORECAST:"TBD",ETA_ACTUAL:"S9/29/2020",ROS:"S2020-12-21",ROS_FORECAST:"TBD",ROS_ETA_DELTA:90,STATUS:"ETA",STATUS_DESCR:"5.On Site",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS02",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"EMERGENCY TRANSFORMER 6.6/0.72KV 2000KVA",ROOM:"Electrical Rooms - MS 3rd Level"},
      {NO:48,TAG:"21-ESB-667301A",TAG_DESCR:"MAIN ESSENTIAL HV SWITCHGEAR 6.6KV BUS A",PARENT_TAG:"21-ESB-667301A",PROCUREDBY:"TPFMC",DIMENSION:"11010 x 1340 x 2696/2898",WEIGHT:"32430",SN:30,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"MS02",LOCATION:"MACHINERY SPACE",TAG_ORIGINAL_DESCR:"MAIN ESSENTIAL HV SWITCHGEAR 6.6KV BUS A",ROOM:"Electrical Rooms - MS 3rd Level"},
      {NO:159,TAG:"21-TUCP-6210002",TAG_DESCR:"PAGA AMP",PARENT_TAG:"21-TUCP-6210002",DIMENSION:"800 x 800",SN:12,POSITION:"R",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ06",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",TAG_ORIGINAL_DESCR:"PAGA AMP",ROOM:"Telecomms Room"},
      {NO:160,TAG:"21-TUCP-6210003",TAG_DESCR:"PAGA MDF",PARENT_TAG:"21-TUCP-6210003",DIMENSION:"800 x 800",SN:13,POSITION:"R",LV1:"HULL",LV2:"LQ",LV3:"LQ00",LV4:"LQ06",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",TAG_ORIGINAL_DESCR:"PAGA MDF",ROOM:"Telecomms Room"},
      {NO:283,TAG:"21-EFD-997702",TAG_DESCR:"VARIABLE SPEED DRIVE FOR PRESSURISATION FAN B",PARENT_TAG:"21-EFD-997702",DIMENSION:"800 x 800 x 2100",WEIGHT:"350",SN:116,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"VARIABLE SPEED DRIVE FOR PRESSURISATION FAN B",ROOM:"Electrical LV Room - Level 2"},
      {NO:284,TAG:"21-EMC-861112",TAG_DESCR:"BACKUP LUBE OIL DC START CONTACTOR GTG 1",PARENT_TAG:"21-EMC-861112",MR_TAG:"MS002-ME-REQ-012-01002",DIMENSION:"797 x 333 x 914",WEIGHT:"191",SN:117,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"BACKUP LUBE OIL DC START CONTACTOR GTG 1",ROOM:"Electrical LV Room - Level 2"},
      {NO:285,TAG:"21-EMC-861212",TAG_DESCR:"BACKUP LUBE OIL DC START CONTACTOR GTG 2",PARENT_TAG:"21-EMC-861212",MR_TAG:"MS002-ME-REQ-012-01002",DIMENSION:"797 x 333 x 914",WEIGHT:"191",SN:118,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"BACKUP LUBE OIL DC START CONTACTOR GTG 2",ROOM:"Electrical LV Room - Level 2"},
      {NO:286,TAG:"21-EMC-861312",TAG_DESCR:"BACKUP LUBE OIL DC START CONTACTOR GTG 3",PARENT_TAG:"21-EMC-861312",MR_TAG:"MS002-ME-REQ-012-01002",DIMENSION:"797 x 333 x 914",WEIGHT:"191",SN:119,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"BACKUP LUBE OIL DC START CONTACTOR GTG 3",ROOM:"Electrical LV Room - Level 2"},
      {NO:287,TAG:"21-EMC-861412",TAG_DESCR:"BACKUP LUBE OIL DC START CONTACTOR GTG 4",PARENT_TAG:"21-EMC-861412",MR_TAG:"MS002-ME-REQ-012-01002",DIMENSION:"797 x 333 x 914",WEIGHT:"191",SN:120,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"BACKUP LUBE OIL DC START CONTACTOR GTG 4",ROOM:"Electrical LV Room - Level 2"},
      {NO:288,TAG:"21-EDB-667603",TAG_DESCR:"NORMAL / ESSENTIAL LIGHTING DISTRIBUTION BOARD",PARENT_TAG:"21-EDB-667603",DIMENSION:"1000 x 600 x 2100",WEIGHT:"500",SN:121,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"NORMAL / ESSENTIAL LIGHTING DISTRIBUTION BOARD",ROOM:"Electrical LV Room - Level 2"},
      {NO:289,TAG:"21-ETP-304013",TAG_DESCR:"SEAL GAS HEATER THYRISTOR CONTROL PANEL",PARENT_TAG:"21-ETP-304013",DIMENSION:"800 x 300 x 1200",WEIGHT:"145",SN:123,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"SEAL GAS HEATER THYRISTOR CONTROL PANEL",ROOM:"Electrical LV Room - Level 2"},
      {NO:290,TAG:"21-ETP-304018",TAG_DESCR:"SEAL GAS HEATER THYRISTOR CONTROL PANEL",PARENT_TAG:"21-ETP-304018",DIMENSION:"800 x 300 x 1200",WEIGHT:"145",SN:124,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"SEAL GAS HEATER THYRISTOR CONTROL PANEL",ROOM:"Electrical LV Room - Level 2"},
      {NO:291,TAG:"21-ILP-861123",TAG_DESCR:"VFD FIBER OPTIC INTERFACE BOX - GTG 1",PARENT_TAG:"21-ILP-861123",DIMENSION:"508 x 298 x 668",WEIGHT:"45",SN:125,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"VFD FIBER OPTIC INTERFACE BOX - GTG 1",ROOM:"Electrical LV Room - Level 2"},
      {NO:292,TAG:"21-ILP-861223",TAG_DESCR:"VFD FIBER OPTIC INTERFACE BOX - GTG 1",PARENT_TAG:"21-ILP-861223",DIMENSION:"508 x 298 x 668",WEIGHT:"45",SN:126,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"VFD FIBER OPTIC INTERFACE BOX - GTG 1",ROOM:"Electrical LV Room - Level 2"},
      {NO:293,TAG:"21-ILP-861323",TAG_DESCR:"VFD FIBER OPTIC INTERFACE BOX - GTG 1",PARENT_TAG:"21-ILP-861323",DIMENSION:"508 x 298 x 668",WEIGHT:"45",SN:127,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"VFD FIBER OPTIC INTERFACE BOX - GTG 1",ROOM:"Electrical LV Room - Level 2"},
      {NO:294,TAG:"21-ILP-861423",TAG_DESCR:"VFD FIBER OPTIC INTERFACE BOX - GTG 1",PARENT_TAG:"21-ILP-861423",DIMENSION:"508 x 298 x 668",WEIGHT:"18.5",SN:128,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"VFD FIBER OPTIC INTERFACE BOX - GTG 1",ROOM:"Electrical LV Room - Level 2"},
      {NO:295,TAG:"NA",TAG_DESCR:"SAFETY KIT FOR LV SWITCHBOARD",PARENT_TAG:"NA",DIMENSION:"700 x 370 x 305",WEIGHT:"18.5",SN:129,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"SAFETY KIT FOR LV SWITCHBOARD",ROOM:"Electrical LV Room - Level 2"},
      {NO:296,TAG:"21-DSH-680014",TAG_DESCR:"HSSD PANEL",PARENT_TAG:"21-DSH-680014",DIMENSION:"350 x 135 x 225",WEIGHT:"10",SN:130,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"HSSD PANEL",ROOM:"Electrical LV Room - Level 2"},
      {NO:297,TAG:"21-DSH-680015",TAG_DESCR:"HSSD PANEL",PARENT_TAG:"21-DSH-680015",DIMENSION:"350 x 135 x 225",WEIGHT:"10",SN:131,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"TBD",PO_FORECAST:"TBD",FAT_PLAN:"TBD",FAT_FORECAST:"TBD",IRN_PLAN:"TBD",IRN_FORECAST:"TBD",ETA_PLAN:"TBD",ETA_FORECAST:"TBD",ROS:"TBD",ROS_FORECAST:"TBD",STATUS:"NOT_PO",STATUS_DESCR:"1.Not PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",TAG_ORIGINAL_DESCR:"HSSD PANEL",ROOM:"Electrical LV Room - Level 2"}
      ],
}