import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 1300,
    CanvasHeight: 800,
    CanvasBorderWeight: 0.05,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.1,

    CanvasChartX: 50,
    CanvasChartY: 50,
    CanvasChartWidth: 480,
    CanvasChartHeight: 250,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  DataItems:  [

    {SEQ:1,TT_NO:"TT-001",TT_DESCR:"lnlet Production Flow Line 1",PRIORITY:"P2",LT_PLAN:"2022-04-21",LT_FORECAST:"2022-04-21",LT_STATUS:"TT Pack issued A01"},
    {SEQ:2,TT_NO:"TT-002",TT_DESCR:"Production Flow line Pig Receiver 1",PRIORITY:"P2",LT_PLAN:"2022-04-21",LT_FORECAST:"2022-04-21",LT_STATUS:"TT Pack available for use"},
    {SEQ:3,TT_NO:"TT-003",TT_DESCR:"lnlet Production Flow Line 2",PRIORITY:"P2",LT_PLAN:"2022-04-21",LT_FORECAST:"2022-04-21",LT_STATUS:"TT Preparation Completed"},
    {SEQ:4,TT_NO:"TT-004",TT_DESCR:"Production Flow line Pig Receiver 2",PRIORITY:"P2",LT_PLAN:"2022-04-21",LT_FORECAST:"2022-04-21",LT_STATUS:"TT Test Pass"},
    {SEQ:5,TT_NO:"TT-005",TT_DESCR:"Slugcatcher 1",PRIORITY:"P3",LT_PLAN:"2022-04-28",LT_FORECAST:"2022-04-28",LT_STATUS:"TT Retest Required"},
    {SEQ:6,TT_NO:"TT-006",TT_DESCR:"Slugcatcher 2",PRIORITY:"P3",LT_PLAN:"2022-04-28",LT_FORECAST:"2022-04-28",LT_STATUS:"TT Reinstatement Completed"},
    {SEQ:7,TT_NO:"TT-007",TT_DESCR:"Liquid Heaters",PRIORITY:"P3",LT_PLAN:"2022-04-28",LT_FORECAST:"2022-04-28",LT_STATUS:"Not started"},
    {SEQ:8,TT_NO:"TT-008",TT_DESCR:"MP Separator",PRIORITY:"P2",LT_PLAN:"2022-04-28",LT_FORECAST:"2022-04-28",LT_STATUS:"Not started"},
    {SEQ:9,TT_NO:"TT-009A",TT_DESCR:"Flash gas MP suction",PRIORITY:"P3",LT_PLAN:"2022-05-05",LT_FORECAST:"2022-05-05",LT_STATUS:"Not started"},
    {SEQ:10,TT_NO:"TT-009B",TT_DESCR:"Flash gas MP discharge ƒ HP suction",PRIORITY:"P3",LT_PLAN:"2022-05-05",LT_FORECAST:"2022-05-05",LT_STATUS:"Not started"},
    {SEQ:11,TT_NO:"TT-009C",TT_DESCR:"Flash gas HP discharge",PRIORITY:"P3",LT_PLAN:"2022-05-05",LT_FORECAST:"2022-05-05",LT_STATUS:"Not started"},
    {SEQ:12,TT_NO:"TT-010",TT_DESCR:"Gas−gas exchanger",PRIORITY:"P3",LT_PLAN:"2022-05-05",LT_FORECAST:"2022-05-05",LT_STATUS:"TT Pack issued A01"},
    {SEQ:13,TT_NO:"TT-011",TT_DESCR:"Gas Compression",PRIORITY:"P3",LT_PLAN:"2022-05-12",LT_FORECAST:"2022-05-12",LT_STATUS:"TT Pack issued A01"},
    {SEQ:14,TT_NO:"TT-012",TT_DESCR:"Gas coalescer",PRIORITY:"P3",LT_PLAN:"2022-05-12",LT_FORECAST:"2022-05-12",LT_STATUS:"TT Pack issued A01"},
    {SEQ:15,TT_NO:"TT-013",TT_DESCR:"LP Separator",PRIORITY:"P3",LT_PLAN:"2022-05-12",LT_FORECAST:"2022-05-12",LT_STATUS:"TT Pack issued A01"},
    {SEQ:16,TT_NO:"TT-014",TT_DESCR:"LLP Separator",PRIORITY:"P2",LT_PLAN:"2022-05-12",LT_FORECAST:"2022-05-12",LT_STATUS:"TT Pack issued A01"},
    {SEQ:17,TT_NO:"TT-014A",TT_DESCR:"lnterstage Heater",PRIORITY:"P3",LT_PLAN:"2022-05-19",LT_FORECAST:"2022-05-19",LT_STATUS:"TT Pack issued A01"},
    {SEQ:18,TT_NO:"TT-015",TT_DESCR:"Flash gas LP/LLP system",PRIORITY:"P3",LT_PLAN:"2022-05-19",LT_FORECAST:"2022-05-19",LT_STATUS:"TT Pack issued A01"},
    {SEQ:19,TT_NO:"TT-016",TT_DESCR:"Gas export metering",PRIORITY:"P2",LT_PLAN:"2022-05-19",LT_FORECAST:"2022-05-19",LT_STATUS:"TT Pack issued A01"},
    {SEQ:20,TT_NO:"TT-017",TT_DESCR:"Gas Export Flow Line 1",PRIORITY:"P2",LT_PLAN:"2022-05-19",LT_FORECAST:"2022-05-19",LT_STATUS:"TT Pack issued A01"},
    {SEQ:21,TT_NO:"TT-018",TT_DESCR:"Gas Export Flow Line 2",PRIORITY:"P2",LT_PLAN:"2022-05-26",LT_FORECAST:"2022-05-26",LT_STATUS:"TT Pack issued A01"},
    {SEQ:22,TT_NO:"TT-019",TT_DESCR:"Condensate Coolers",PRIORITY:"P2",LT_PLAN:"2022-05-26",LT_FORECAST:"2022-05-26",LT_STATUS:"TT Pack issued A01"},
    {SEQ:23,TT_NO:"TT-020",TT_DESCR:"HP Fuel gas 1",PRIORITY:"P3",LT_PLAN:"2022-05-26",LT_FORECAST:"2022-05-26",LT_STATUS:"TT Pack available for use"},
    {SEQ:24,TT_NO:"TT-021",TT_DESCR:"HP Fuel gas 2",PRIORITY:"P3",LT_PLAN:"2022-05-26",LT_FORECAST:"2022-05-26",LT_STATUS:"TT Pack available for use"},
    {SEQ:25,TT_NO:"TT-022",TT_DESCR:"LP Fuel Gas",PRIORITY:"P3",LT_PLAN:"2022-06-02",LT_FORECAST:"2022-06-02",LT_STATUS:"TT Pack available for use"},
    {SEQ:26,TT_NO:"TT-023",TT_DESCR:"Condensate Offloading",PRIORITY:"P2",LT_PLAN:"2022-06-02",LT_FORECAST:"2022-06-02",LT_STATUS:"TT Pack available for use"},
    {SEQ:27,TT_NO:"TT-024",TT_DESCR:"HP Flare Header",PRIORITY:"P2",LT_PLAN:"2022-06-02",LT_FORECAST:"2022-06-02",LT_STATUS:"TT Pack available for use"},
    {SEQ:28,TT_NO:"TT-025",TT_DESCR:"LP Flare (Emergency)",PRIORITY:"P2",LT_PLAN:"2022-06-02",LT_FORECAST:"2022-06-02",LT_STATUS:"TT Pack available for use"},
    {SEQ:29,TT_NO:"TT-026",TT_DESCR:"LP Flare (Operational)",PRIORITY:"P3",LT_PLAN:"2022-06-09",LT_FORECAST:"2022-06-09",LT_STATUS:"TT Pack available for use"},
    {SEQ:30,TT_NO:"TT-027",TT_DESCR:"Closed Drain",PRIORITY:"P2",LT_PLAN:"2022-06-09",LT_FORECAST:"2022-06-09",LT_STATUS:"TT Pack available for use"},
    {SEQ:31,TT_NO:"TT-028",TT_DESCR:"Turboexpander & Recompressor",PRIORITY:"P3",LT_PLAN:"2022-06-09",LT_FORECAST:"2022-06-09",LT_STATUS:"TT Pack available for use"},
    {SEQ:32,TT_NO:"TT-029",TT_DESCR:"Warm condensates",PRIORITY:"P2",LT_PLAN:"2022-06-09",LT_FORECAST:"2022-06-09",LT_STATUS:"TT Pack available for use"},
    {SEQ:33,TT_NO:"TT-030",TT_DESCR:"Wax lnhibitor to TUTU",PRIORITY:"P3",LT_PLAN:"2022-06-16",LT_FORECAST:"2022-06-16",LT_STATUS:"TT Preparation Completed"},
    {SEQ:34,TT_NO:"TT-031",TT_DESCR:"Methanol to TUTU",PRIORITY:"P3",LT_PLAN:"2022-06-16",LT_FORECAST:"2022-06-16",LT_STATUS:"TT Preparation Completed"},
    {SEQ:35,TT_NO:"TT-032",TT_DESCR:"Scale lnhibitor to TUTU",PRIORITY:"P3",LT_PLAN:"2022-06-16",LT_FORECAST:"2022-06-16",LT_STATUS:"TT Preparation Completed"},
    {SEQ:36,TT_NO:"TT-033",TT_DESCR:"Lean MEG to Subsea",PRIORITY:"P2",LT_PLAN:"2022-06-16",LT_FORECAST:"2022-06-16",LT_STATUS:"TT Preparation Completed"},
    {SEQ:37,TT_NO:"TT-034",TT_DESCR:"Condensate lmport Loading Header",PRIORITY:"P2",LT_PLAN:"2022-06-23",LT_FORECAST:"2022-06-23",LT_STATUS:"TT Preparation Completed"},
    {SEQ:38,TT_NO:"TT-035",TT_DESCR:"Condensate Export Offloading Header",PRIORITY:"P2",LT_PLAN:"2022-06-23",LT_FORECAST:"2022-06-23",LT_STATUS:"TT Preparation Completed"},
    {SEQ:39,TT_NO:"TT-036",TT_DESCR:"Rich MEG Collection Drum",PRIORITY:"P3",LT_PLAN:"2022-06-23",LT_FORECAST:"2022-06-23",LT_STATUS:"TT Preparation Completed"},
    {SEQ:40,TT_NO:"TT-037",TT_DESCR:"MEG Transfer Header",PRIORITY:"P2",LT_PLAN:"2022-06-23",LT_FORECAST:"2022-06-23",LT_STATUS:"TT Preparation Completed"},
    {SEQ:41,TT_NO:"TT-038",TT_DESCR:"Salty Rich MEG lmport Header",PRIORITY:"P3",LT_PLAN:"2022-06-30",LT_FORECAST:"2022-06-30",LT_STATUS:"TT Preparation Completed"},
    {SEQ:42,TT_NO:"TT-039",TT_DESCR:"Salty Rich MEG Export Header",PRIORITY:"P3",LT_PLAN:"2022-06-30",LT_FORECAST:"2022-06-30",LT_STATUS:"TT Preparation Completed"},
    {SEQ:43,TT_NO:"TT-040",TT_DESCR:"Rich MEG Header to MEG Regen Package",PRIORITY:"P3",LT_PLAN:"2022-06-30",LT_FORECAST:"2022-06-30",LT_STATUS:"TT Test Pass"},
    {SEQ:44,TT_NO:"TT-041",TT_DESCR:"Rich MEG to Storage",PRIORITY:"P2",LT_PLAN:"2022-06-30",LT_FORECAST:"2022-06-30",LT_STATUS:"TT Test Pass"},
    {SEQ:45,TT_NO:"TT-042",TT_DESCR:"Lean MEG from MEG Regen & Bunkering",PRIORITY:"P2",LT_PLAN:"2022-07-07",LT_FORECAST:"2022-07-07",LT_STATUS:"TT Test Pass"},
    {SEQ:46,TT_NO:"TT-043",TT_DESCR:"Methanol form Bunkering to Filters",PRIORITY:"P2",LT_PLAN:"2022-07-07",LT_FORECAST:"2022-07-07",LT_STATUS:"TT Test Pass"},
    {SEQ:47,TT_NO:"TT-045",TT_DESCR:"CFU Package",PRIORITY:"P3",LT_PLAN:"2022-07-07",LT_FORECAST:"2022-07-07",LT_STATUS:"TT Test Pass"},
    {SEQ:48,TT_NO:"TT-046",TT_DESCR:"Demulsifier lnjection Package",PRIORITY:"P2",LT_PLAN:"2022-07-07",LT_FORECAST:"2022-07-07",LT_STATUS:"TT Test Pass"},
    {SEQ:49,TT_NO:"TT-047",TT_DESCR:"HULL Deck Equipment Drainage",PRIORITY:"P3",LT_PLAN:"2022-07-14",LT_FORECAST:"2022-07-14",LT_STATUS:"TT Test Pass"},
    {SEQ:50,TT_NO:"TT-048",TT_DESCR:"Wax lnhibitor Bunkering to Filter",PRIORITY:"P3",LT_PLAN:"2022-07-14",LT_FORECAST:"2022-07-14",LT_STATUS:"TT Test Pass"},
    {SEQ:51,TT_NO:"TT-049",TT_DESCR:"Fuel gas Blanketing Distribution",PRIORITY:"P3",LT_PLAN:"2022-07-14",LT_FORECAST:"2022-07-14",LT_STATUS:"TT Test Pass"},
    {SEQ:52,TT_NO:"TT-050",TT_DESCR:"Condensate Transfer Header",PRIORITY:"P2",LT_PLAN:"2022-07-14",LT_FORECAST:"2022-07-14",LT_STATUS:"TT Retest Required"},
    {SEQ:53,TT_NO:"TT-051",TT_DESCR:"Lean MEG Break Tank",PRIORITY:"P2",LT_PLAN:"2022-07-21",LT_FORECAST:"2022-07-21",LT_STATUS:"TT Retest Required"},
    {SEQ:54,TT_NO:"TT-052",TT_DESCR:"MEG Regeneration",PRIORITY:"P2",LT_PLAN:"2022-07-21",LT_FORECAST:"2022-07-21",LT_STATUS:"TT Retest Required"},
    {SEQ:55,TT_NO:"TT-053",TT_DESCR:"Methanol Filter & Break Tank",PRIORITY:"P2",LT_PLAN:"2022-07-21",LT_FORECAST:"2022-07-21",LT_STATUS:"TT Retest Required"},
    {SEQ:56,TT_NO:"TT-054",TT_DESCR:"Methanol HP pumps & Distribution",PRIORITY:"P2",LT_PLAN:"2022-07-21",LT_FORECAST:"2022-07-21",LT_STATUS:"TT Retest Required"},
    {SEQ:57,TT_NO:"TT-055",TT_DESCR:"Methanol MP pumps & Distribution",PRIORITY:"P2",LT_PLAN:"2022-07-28",LT_FORECAST:"2022-07-28",LT_STATUS:"TT Retest Required"},
    {SEQ:58,TT_NO:"TT-056",TT_DESCR:"Mercury Removal Package",PRIORITY:"P3",LT_PLAN:"2022-07-28",LT_FORECAST:"2022-07-28",LT_STATUS:"TT Retest Required"},
    {SEQ:59,TT_NO:"TT-057",TT_DESCR:"HP & LP Flare lgnition System",PRIORITY:"P3",LT_PLAN:"2022-07-28",LT_FORECAST:"2022-07-28",LT_STATUS:"TT Retest Required"},
    {SEQ:60,TT_NO:"TT-058",TT_DESCR:"Flare Gas Recovery package",PRIORITY:"P2",LT_PLAN:"2022-07-28",LT_FORECAST:"2022-07-28",LT_STATUS:"TT Retest Required"},
    {SEQ:61,TT_NO:"TT-059",TT_DESCR:"HULL Tank Vapour Recovery Package",PRIORITY:"P2",LT_PLAN:"2022-07-28",LT_FORECAST:"2022-07-28",LT_STATUS:"TT Retest Required"},
    {SEQ:62,TT_NO:"TT-060",TT_DESCR:"Emulsion Breaker Distribution",PRIORITY:"P3",LT_PLAN:"2022-08-04",LT_FORECAST:"2022-08-04",LT_STATUS:"TT Reinstatement Completed"},
    {SEQ:63,TT_NO:"TT-061",TT_DESCR:"Corrosion lnhibitor Distribution",PRIORITY:"P3",LT_PLAN:"2022-08-04",LT_FORECAST:"2022-08-04",LT_STATUS:"TT Reinstatement Completed"},
    {SEQ:64,TT_NO:"TT-062",TT_DESCR:"Scale lnhibitor Distribution",PRIORITY:"P3",LT_PLAN:"2022-08-04",LT_FORECAST:"2022-08-04",LT_STATUS:"TT Reinstatement Completed"},
    {SEQ:65,TT_NO:"TT-063",TT_DESCR:"Wax lnhibitor Break Tank & Pumps",PRIORITY:"P2",LT_PLAN:"2022-08-04",LT_FORECAST:"2022-08-04",LT_STATUS:"TT Reinstatement Completed"},
    {SEQ:66,TT_NO:"TT-064",TT_DESCR:"Topside Lean MEG Pump & Distribution",PRIORITY:"P2",LT_PLAN:"2022-08-04",LT_FORECAST:"2022-08-04",LT_STATUS:"TT Reinstatement Completed"},


    // {SUBSYSTEM:"21-1001-1001-01",SUBSYSTEM_DESCR:"Annulus Riser vent MonitoringSTA System",MC_PLAN:"2022-04-29",A_OV_TOT:67,A_OV_COM:4,A_OV_REM:63,A_OV_PROG:5.97,A_OV_STATUS:"less than 70%",A_PG:5.97,MC_STATUS:"PENDING WD",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:53,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    // {SUBSYSTEM:"21-1010-1011-01",SUBSYSTEM_DESCR:"Flowline 1 & Receiver",MC_PLAN:"2022-04-29",A_OV_TOT:434,A_OV_COM:2,A_OV_REM:432,A_OV_PROG:0.46,A_OV_STATUS:"less than 70%",A_PG:0.46,MC_STATUS:"PENDING WD",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,ACT_TAG_VALID:279,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    // {SUBSYSTEM:"21-1010-1012-01",SUBSYSTEM_DESCR:"Flowline 2 & Receiver",MC_PLAN:"2022-04-29",A_OV_TOT:420,A_OV_COM:3,A_OV_REM:417,A_OV_PROG:0.71,A_OV_STATUS:"less than 70%",A_PG:0.71,MC_STATUS:"PENDING WD",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:274,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    // {SUBSYSTEM:"21-1090-1090-01",SUBSYSTEM_DESCR:"Production Control Umbilical",MC_PLAN:"2022-04-29",A_OV_TOT:9,A_OV_COM:0,A_OV_REM:9,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"PENDING WD",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    // {SUBSYSTEM:"21-1510-1510-01",SUBSYSTEM_DESCR:"MEG Riser & Pipeline System to DC1",MC_PLAN:"2022-04-29",A_OV_TOT:27,A_OV_COM:0,A_OV_REM:27,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"PENDING WD",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    // {SUBSYSTEM:"21-1600-1611-01",SUBSYSTEM_DESCR:"Gas Export Riser 1",MC_PLAN:"2022-04-29",A_OV_TOT:50,A_OV_COM:0,A_OV_REM:50,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"PENDING WD",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:27,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},

  ],
  Queries: {
    SQL1: [
      { DESC: 'DataSource SPCS Data 03-Mar-20' }
    ],
    SQL2: [
      { CDATE: '2021-10-21' }
    ],
    SQL3: [
      {LT_PLAN:"2022-04-21",WEEKLY_QTY:4,CUM_QTY:4,PLAN_PROG:6.1,ACTUAL_PROG:0,REQ_AVG_DAYS:1.75,ACTUAL_AVG_DAYS:0},
      {LT_PLAN:"2022-04-28",WEEKLY_QTY:4,CUM_QTY:8,PLAN_PROG:12.1,ACTUAL_PROG:0,REQ_AVG_DAYS:1.75,ACTUAL_AVG_DAYS:0},
      {LT_PLAN:"2022-05-05",WEEKLY_QTY:4,CUM_QTY:12,PLAN_PROG:18.2,ACTUAL_PROG:0,REQ_AVG_DAYS:1.75,ACTUAL_AVG_DAYS:0},
      {LT_PLAN:"2022-05-12",WEEKLY_QTY:4,CUM_QTY:16,PLAN_PROG:24.2,ACTUAL_PROG:0,REQ_AVG_DAYS:1.75,ACTUAL_AVG_DAYS:0},
      {LT_PLAN:"2022-05-19",WEEKLY_QTY:4,CUM_QTY:20,PLAN_PROG:30.3,ACTUAL_PROG:0,REQ_AVG_DAYS:1.75,ACTUAL_AVG_DAYS:0},
      {LT_PLAN:"2022-05-26",WEEKLY_QTY:4,CUM_QTY:24,PLAN_PROG:36.4,ACTUAL_PROG:0,REQ_AVG_DAYS:1.75,ACTUAL_AVG_DAYS:0},
      {LT_PLAN:"2022-06-02",WEEKLY_QTY:4,CUM_QTY:28,PLAN_PROG:42.4,ACTUAL_PROG:0,REQ_AVG_DAYS:1.75,ACTUAL_AVG_DAYS:0},
      {LT_PLAN:"2022-06-09",WEEKLY_QTY:4,CUM_QTY:32,PLAN_PROG:48.5,ACTUAL_PROG:0,REQ_AVG_DAYS:1.75,ACTUAL_AVG_DAYS:0},
      {LT_PLAN:"2022-06-16",WEEKLY_QTY:4,CUM_QTY:36,PLAN_PROG:54.5,ACTUAL_PROG:0,REQ_AVG_DAYS:1.75,ACTUAL_AVG_DAYS:0},
      {LT_PLAN:"2022-06-23",WEEKLY_QTY:4,CUM_QTY:40,PLAN_PROG:60.6,ACTUAL_PROG:0,REQ_AVG_DAYS:1.75,ACTUAL_AVG_DAYS:0},
      {LT_PLAN:"2022-06-30",WEEKLY_QTY:4,CUM_QTY:44,PLAN_PROG:66.7,ACTUAL_PROG:0,REQ_AVG_DAYS:1.75,ACTUAL_AVG_DAYS:0},
      {LT_PLAN:"2022-07-07",WEEKLY_QTY:4,CUM_QTY:48,PLAN_PROG:72.7,ACTUAL_PROG:0,REQ_AVG_DAYS:1.75,ACTUAL_AVG_DAYS:0},
      {LT_PLAN:"2022-07-14",WEEKLY_QTY:4,CUM_QTY:52,PLAN_PROG:78.8,ACTUAL_PROG:0,REQ_AVG_DAYS:1.75,ACTUAL_AVG_DAYS:0},
      {LT_PLAN:"2022-07-21",WEEKLY_QTY:4,CUM_QTY:56,PLAN_PROG:84.8,ACTUAL_PROG:0,REQ_AVG_DAYS:1.75,ACTUAL_AVG_DAYS:0},
      {LT_PLAN:"2022-07-28",WEEKLY_QTY:5,CUM_QTY:61,PLAN_PROG:92.4,ACTUAL_PROG:0,REQ_AVG_DAYS:1.4,ACTUAL_AVG_DAYS:0},
      {LT_PLAN:"2022-08-04",WEEKLY_QTY:5,CUM_QTY:66,PLAN_PROG:100,ACTUAL_PROG:0,REQ_AVG_DAYS:1.4,ACTUAL_AVG_DAYS:0},

      // {MC_PLAN:"2021-08-13",A_T:1552,A_A:555,A_C:482,A_O:997,A_PG:35.76,A_CUM:555,A_CUM_PG:0.69,PLAN_CUM:68693.57333048206,ACTUAL_CUM:68857,PA_O:18,PB1_O:97,PB2_O:19,PB3_O:0},
      // {MC_PLAN:"2021-08-20",A_T:1119,A_A:102,A_C:102,A_O:1017,A_PG:9.12,A_CUM:657,A_CUM_PG:0.82,PLAN_CUM:67918.92722081483,ACTUAL_CUM:68741,PA_O:5,PB1_O:17,PB2_O:0,PB3_O:0},
      // {MC_PLAN:"2021-08-27",A_T:715,A_A:93,A_C:10,A_O:622,A_PG:13.01,A_CUM:750,A_CUM_PG:0.94,PLAN_CUM:67240.46292932943,ACTUAL_CUM:69748,PA_O:4,PB1_O:29,PB2_O:0,PB3_O:0},
      // {MC_PLAN:"2021-09-03",A_T:6242,A_A:0,A_C:0,A_O:6242,A_PG:0,A_CUM:750,A_CUM_PG:0.94,PLAN_CUM:66318.65409772044,ACTUAL_CUM:69904,PA_O:6,PB1_O:14,PB2_O:0,PB3_O:0},
      // {MC_PLAN:"2021-09-10",A_T:1556,A_A:87,A_C:19,A_O:1469,A_PG:5.59,A_CUM:837,A_CUM_PG:1.04,PLAN_CUM:65233.2176593381,PA_O:12,PB1_O:17,PB2_O:0,PB3_O:0},
      // {MC_PLAN:"2021-09-17",A_T:1580,A_A:48,A_C:0,A_O:1532,A_PG:3.04,A_CUM:885,A_CUM_PG:1.1,PLAN_CUM:63979.15028084907,PA_O:11,PB1_O:18,PB2_O:0,PB3_O:0},
      // {MC_PLAN:"2021-09-24",A_T:1124,A_A:98,A_C:23,A_O:1026,A_PG:8.72,A_CUM:983,A_CUM_PG:1.23,PLAN_CUM:62637.78967802465,PA_O:5,PB1_O:0,PB2_O:0,PB3_O:0},
      // {MC_PLAN:"2021-10-01",A_T:1245,A_A:70,A_C:20,A_O:1175,A_PG:5.62,A_CUM:1053,A_CUM_PG:1.31,PLAN_CUM:61174.48720221619,PA_O:8,PB1_O:0,PB2_O:0,PB3_O:0},
    ],
    SQL4: [
      {CUTOFF:"2021-01-07",WK_NO:"W2021_01"},
      {CUTOFF:"2021-01-14",WK_NO:"W2021_02"},
      {CUTOFF:"2021-01-21",WK_NO:"W2021_03"},
      {CUTOFF:"2021-01-28",WK_NO:"W2021_04"},
      {CUTOFF:"2021-02-04",WK_NO:"W2021_05"},
      {CUTOFF:"2021-02-11",WK_NO:"W2021_06"},
      {CUTOFF:"2021-02-18",WK_NO:"W2021_07"},
      {CUTOFF:"2021-02-25",WK_NO:"W2021_08"},
      {CUTOFF:"2021-03-04",WK_NO:"W2021_09"},
      {CUTOFF:"2021-03-11",WK_NO:"W2021_10"},
      {CUTOFF:"2021-03-18",WK_NO:"W2021_11"},
      {CUTOFF:"2021-03-25",WK_NO:"W2021_12"},
      {CUTOFF:"2021-04-01",WK_NO:"W2021_13"},
      {CUTOFF:"2021-04-08",WK_NO:"W2021_14"},
      {CUTOFF:"2021-04-15",WK_NO:"W2021_15"},
      {CUTOFF:"2021-04-22",WK_NO:"W2021_16"},
      {CUTOFF:"2021-04-29",WK_NO:"W2021_17"},
      {CUTOFF:"2021-05-06",WK_NO:"W2021_18"},
      {CUTOFF:"2021-05-13",WK_NO:"W2021_19"},
      {CUTOFF:"2021-05-20",WK_NO:"W2021_20"},
      {CUTOFF:"2021-05-27",WK_NO:"W2021_21"},
      {CUTOFF:"2021-06-03",WK_NO:"W2021_22"},
      {CUTOFF:"2021-06-10",WK_NO:"W2021_23"},
      {CUTOFF:"2021-06-17",WK_NO:"W2021_24"},
      {CUTOFF:"2021-06-24",WK_NO:"W2021_25"},
      {CUTOFF:"2021-07-01",WK_NO:"W2021_26"},
      {CUTOFF:"2021-07-08",WK_NO:"W2021_27"},
      {CUTOFF:"2021-07-15",WK_NO:"W2021_28"},
      {CUTOFF:"2021-07-22",WK_NO:"W2021_29"},
      {CUTOFF:"2021-07-29",WK_NO:"W2021_30"},
      {CUTOFF:"2021-08-05",WK_NO:"W2021_31"},
      {CUTOFF:"2021-08-12",WK_NO:"W2021_32"},
      {CUTOFF:"2021-08-19",WK_NO:"W2021_33"},
      {CUTOFF:"2021-08-26",WK_NO:"W2021_34"},
      {CUTOFF:"2021-09-02",WK_NO:"W2021_35"},
      {CUTOFF:"2021-09-09",WK_NO:"W2021_36"},
      {CUTOFF:"2021-09-16",WK_NO:"W2021_37"},
      {CUTOFF:"2021-09-23",WK_NO:"W2021_38"},
      {CUTOFF:"2021-09-30",WK_NO:"W2021_39"},
      {CUTOFF:"2021-10-07",WK_NO:"W2021_40"},
      {CUTOFF:"2021-10-14",WK_NO:"W2021_41"},
      {CUTOFF:"2021-10-21",WK_NO:"W2021_42"},
      {CUTOFF:"2021-10-28",WK_NO:"W2021_43"},
      {CUTOFF:"2021-11-04",WK_NO:"W2021_44"},
      {CUTOFF:"2021-11-11",WK_NO:"W2021_45"},
      {CUTOFF:"2021-11-18",WK_NO:"W2021_46"},
      {CUTOFF:"2021-11-25",WK_NO:"W2021_47"},
      {CUTOFF:"2021-12-02",WK_NO:"W2021_48"},
      {CUTOFF:"2021-12-09",WK_NO:"W2021_49"},
      {CUTOFF:"2021-12-16",WK_NO:"W2021_50"},
      {CUTOFF:"2021-12-23",WK_NO:"W2021_51"},
      {CUTOFF:"2021-12-30",WK_NO:"W2021_52"},
      {CUTOFF:"2022-01-06",WK_NO:"W2022_01"},
      {CUTOFF:"2022-01-13",WK_NO:"W2022_02"},
      {CUTOFF:"2022-01-20",WK_NO:"W2022_03"},
      {CUTOFF:"2022-01-27",WK_NO:"W2022_04"},
      {CUTOFF:"2022-02-03",WK_NO:"W2022_05"},
      {CUTOFF:"2022-02-10",WK_NO:"W2022_06"},
      {CUTOFF:"2022-02-17",WK_NO:"W2022_07"},
      {CUTOFF:"2022-02-24",WK_NO:"W2022_08"},
      {CUTOFF:"2022-03-03",WK_NO:"W2022_09"},
      {CUTOFF:"2022-03-10",WK_NO:"W2022_10"},
      {CUTOFF:"2022-03-17",WK_NO:"W2022_11"},
      {CUTOFF:"2022-03-24",WK_NO:"W2022_12"},
      {CUTOFF:"2022-03-31",WK_NO:"W2022_13"},
      {CUTOFF:"2022-04-07",WK_NO:"W2022_14"},
      {CUTOFF:"2022-04-14",WK_NO:"W2022_15"},
      {CUTOFF:"2022-04-21",WK_NO:"W2022_16"},
      {CUTOFF:"2022-04-28",WK_NO:"W2022_17"},
      {CUTOFF:"2022-05-05",WK_NO:"W2022_18"},
      {CUTOFF:"2022-05-12",WK_NO:"W2022_19"},
      {CUTOFF:"2022-05-19",WK_NO:"W2022_20"},
      {CUTOFF:"2022-05-26",WK_NO:"W2022_21"},
      {CUTOFF:"2022-06-02",WK_NO:"W2022_22"},
      {CUTOFF:"2022-06-09",WK_NO:"W2022_23"},
      {CUTOFF:"2022-06-16",WK_NO:"W2022_24"},
      {CUTOFF:"2022-06-23",WK_NO:"W2022_25"},
      {CUTOFF:"2022-06-30",WK_NO:"W2022_26"},
      {CUTOFF:"2022-07-07",WK_NO:"W2022_27"},
      {CUTOFF:"2022-07-14",WK_NO:"W2022_28"},
      {CUTOFF:"2022-07-21",WK_NO:"W2022_29"},
      {CUTOFF:"2022-07-28",WK_NO:"W2022_30"},
      {CUTOFF:"2022-08-04",WK_NO:"W2022_31"},
      {CUTOFF:"2022-08-11",WK_NO:"W2022_32"},
      {CUTOFF:"2022-08-18",WK_NO:"W2022_33"},
      {CUTOFF:"2022-08-25",WK_NO:"W2022_34"},
      {CUTOFF:"2022-09-01",WK_NO:"W2022_35"},
      {CUTOFF:"2022-09-08",WK_NO:"W2022_36"},
      {CUTOFF:"2022-09-15",WK_NO:"W2022_37"},
      {CUTOFF:"2022-09-22",WK_NO:"W2022_38"},
      {CUTOFF:"2022-09-29",WK_NO:"W2022_39"},
      {CUTOFF:"2022-10-06",WK_NO:"W2022_40"},
      {CUTOFF:"2022-10-13",WK_NO:"W2022_41"},
      {CUTOFF:"2022-10-20",WK_NO:"W2022_42"},
      {CUTOFF:"2022-10-27",WK_NO:"W2022_43"},
      {CUTOFF:"2022-11-03",WK_NO:"W2022_44"},
      {CUTOFF:"2022-11-10",WK_NO:"W2022_45"},
      {CUTOFF:"2022-11-17",WK_NO:"W2022_46"},
      {CUTOFF:"2022-11-24",WK_NO:"W2022_47"},
      {CUTOFF:"2022-12-01",WK_NO:"W2022_48"},
      {CUTOFF:"2022-12-08",WK_NO:"W2022_49"},
      {CUTOFF:"2022-12-15",WK_NO:"W2022_50"},
      {CUTOFF:"2022-12-22",WK_NO:"W2022_51"},
      {CUTOFF:"2022-12-29",WK_NO:"W2022_52"}
    ],
    SQL5: [
      {TOTAL_PACKS_DEV_REQ:66,TOTAL_PACKS_DEV_NOT_STARTED:5,TOTAL_PACKS_DEV_A01:12,TOTAL_PACKS_DEV_IFU:11,TOTAL_PACK_DEV_PREPARATION:11,TOTAL_PACKS_TEST_PASS:10,TOTAL_PACKS_TEST_RETEST:11,TOTAL_PACKS_TEST_COMPLETED:6}

    ],
  }
}