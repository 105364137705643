import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 580,
      CanvasHeight: 280,
      CanvasBorderWeight: 0.3,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 310,
      CanvasChartHeight: 270,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: 'Transparent',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },

    Text: {
      Text: [
        { text: 'Major Quantity Status', x: 20, y: 10, font: 'roboto', class: 'regular', size: 13, color: '#000', align: 'start', direction: 'Horizontal' },
      ],
      Filter: [],
      Sql: []
    },

    DataItems: [
      {DISC_DESCR:"Piping",AREA:"TOPSIDES MODULES",ITEM:"Spool",UNIT:"U",PLAN:11463,PLAN_PROG:83.03,C2_QTY:13805,ACTUAL:11519,ACTUAL_PROG:83.43,ACTUAL_TW:151,ACTUAL_TM:328,DELTA:55,REMAIN:2286},
      {DISC_DESCR:"Electrical",AREA:"TOPSIDES MODULES",ITEM:"Support",UNIT:"KG",PLAN:247876,PLAN_PROG:99.98,C2_QTY:247920,ACTUAL:206546,ACTUAL_PROG:83.31,ACTUAL_TW:3624,ACTUAL_TM:8973,DELTA:-41330,REMAIN:41374},
      {DISC_DESCR:"Electrical",AREA:"TOPSIDES MODULES",ITEM:"Cable Tray",UNIT:"LM",PLAN:45488,PLAN_PROG:86.65,C2_QTY:52491,ACTUAL:44861,ACTUAL_PROG:85.46,ACTUAL_TW:1097,ACTUAL_TM:5220,DELTA:-627,REMAIN:7630},
      {DISC_DESCR:"Electrical",AREA:"TOPSIDES MODULES",ITEM:"Cable Pulling",UNIT:"LM",PLAN:109935,PLAN_PROG:75.62,C2_QTY:145375,ACTUAL:92751,ACTUAL_PROG:63.8,ACTUAL_TW:-4228,ACTUAL_TM:4699,DELTA:-17184,REMAIN:52624},
      {DISC_DESCR:"Electrical",AREA:"TOPSIDES MODULES",ITEM:"Cable Termination",UNIT:"U",PLAN:8704,PLAN_PROG:78.19,C2_QTY:11132,ACTUAL:5084,ACTUAL_PROG:45.66,ACTUAL_TW:190,ACTUAL_TM:800,DELTA:-3620,REMAIN:6048},
      {DISC_DESCR:"Instrument",AREA:"TOPSIDES MODULES",ITEM:"Cable Pulling",UNIT:"LM",PLAN:100252,PLAN_PROG:78.93,C2_QTY:127008,ACTUAL:73088,ACTUAL_PROG:57.54,ACTUAL_TW:19546,ACTUAL_TM:34355,DELTA:-27164,REMAIN:53921},
      {DISC_DESCR:"Instrument",AREA:"TOPSIDES MODULES",ITEM:"Cable Termination",UNIT:"U",PLAN:5264,PLAN_PROG:73.91,C2_QTY:7122,ACTUAL:1004,ACTUAL_PROG:14.09,ACTUAL_TW:214,ACTUAL_TM:695,DELTA:-4260,REMAIN:6118},
      {DISC_DESCR:"HVAC",AREA:"TOPSIDES MODULES",ITEM:"Duct",UNIT:"SQM",PLAN:1912,PLAN_PROG:100,C2_QTY:1912,ACTUAL:1912,ACTUAL_PROG:100,ACTUAL_TW:0,ACTUAL_TM:0,DELTA:0,REMAIN:0},
      {DISC_DESCR:"Architecture",AREA:"TOPSIDES MODULES",ITEM:"Wall & Ceiling",UNIT:"SQM",PLAN:1152,PLAN_PROG:100,C2_QTY:1152,ACTUAL:1152,ACTUAL_PROG:100,ACTUAL_TW:0,ACTUAL_TM:0,DELTA:0,REMAIN:0}
    ],
}