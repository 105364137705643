import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 550,
      CanvasHeight: 200,
      CanvasBorderWeight: 0.3,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 310,
      CanvasChartHeight: 270,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: 'Transparent',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },

    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },


    DataItems: [
      {AREA:"HL00",TOTAL:533,FAB:44,PAT:108,INST:38,COM:115,REM:418},
      {AREA:"HL02",TOTAL:1169,FAB:5,PAT:15,INST:35,COM:0,REM:1169},
      {AREA:"HL03",TOTAL:6008,FAB:268,PAT:174,INST:277,COM:70,REM:5938},
      {AREA:"MD01-2",TOTAL:6132,FAB:454,PAT:959,INST:965,COM:1025,REM:5107},
      {AREA:"Total",TOTAL:13842,FAB:771,PAT:1256,INST:1315,COM:1210,REM:12632}
    ]
}