import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 860,
    CanvasHeight: 950,
    CanvasBorderWeight: 0.1,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.2,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 0,
    CanvasChartHeight: 0,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },

  DataItems: [
    {DISC:"Piping",ITEM:"Support",TOTAL:54,PROG:100,FAB_COM:54,FAB_PROG:100,DEL_COM:54,DEL_PROG:100,INST_COM:54,INST_PROG:100},
    {DISC:"Piping",ITEM:"Spool",TOTAL:78,PROG:0,FAB_COM:0,FAB_PROG:0,DEL_COM:0,DEL_PROG:0,INST_COM:0,INST_PROG:0},
    {DISC:"EICT",ITEM:"Outfitting",TOTAL:237,PROG:53.16,FAB_COM:126,FAB_PROG:53.16,DEL_COM:126,DEL_PROG:53.16,INST_COM:126,INST_PROG:53.16},
    {DISC:"EICT",ITEM:"Cable Tray",TOTAL:0,PROG:0,FAB_COM:0,FAB_PROG:0,DEL_COM:0,DEL_PROG:0,INST_COM:0,INST_PROG:0},
    {DISC:"HVAC",ITEM:"Support",TOTAL:33,PROG:84.84,FAB_COM:30,FAB_PROG:90.9,DEL_COM:30,DEL_PROG:90.9,INST_COM:28,INST_PROG:84.84},
    {DISC:"Architecture",ITEM:"Support",TOTAL:0,PROG:0,FAB_COM:0,FAB_PROG:0,DEL_COM:0,DEL_PROG:0,INST_COM:0,INST_PROG:0},
    {DISC:"Architecture",ITEM:"ETC",TOTAL:0,PROG:0,FAB_COM:0,FAB_PROG:0,DEL_COM:0,DEL_PROG:0,INST_COM:0,INST_PROG:0},
  ],
}