import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1400,
      CanvasHeight: 420,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#e0e0e0',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 380,
      CanvasChartHeight: 280,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Support Fabrication', x:20, y: -20, font: 'roboto', class: 'regular', size: 12, color: '#424242', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: -50, y: -20, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'start' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: -30,
          y: -20,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'start'
        },
      ]
    },
    Queries: {
      // SQL1: [
      //   {PLAN:100,ACTUAL:99.99,DELTA:-0.01000000000000512}
      // ],
    },
    DataItems: [
      {WK_NO:"W2022_01",CUTOFF:"2022-01-05",DDMMMYY:"05-Jan-22",ISSUED_CUM:154,RESPONDED_CUM:103,ISSUED_WK:2,RESPONDED_WK:3},
      {WK_NO:"W2022_02",CUTOFF:"2022-01-12",DDMMMYY:"12-Jan-22",ISSUED_CUM:158,RESPONDED_CUM:105,ISSUED_WK:4,RESPONDED_WK:2},
      {WK_NO:"W2022_03",CUTOFF:"2022-01-19",DDMMMYY:"19-Jan-22",ISSUED_CUM:161,RESPONDED_CUM:107,ISSUED_WK:3,RESPONDED_WK:2},
      {WK_NO:"W2022_04",CUTOFF:"2022-01-26",DDMMMYY:"26-Jan-22",ISSUED_CUM:165,RESPONDED_CUM:108,ISSUED_WK:4,RESPONDED_WK:1},
      {WK_NO:"W2022_05",CUTOFF:"2022-02-02",DDMMMYY:"02-Feb-22",ISSUED_CUM:170,RESPONDED_CUM:109,ISSUED_WK:5,RESPONDED_WK:1},
      {WK_NO:"W2022_06",CUTOFF:"2022-02-09",DDMMMYY:"09-Feb-22",ISSUED_CUM:170,RESPONDED_CUM:109,ISSUED_WK:0,RESPONDED_WK:0},
      {WK_NO:"W2022_07",CUTOFF:"2022-02-16",DDMMMYY:"16-Feb-22",ISSUED_CUM:174,RESPONDED_CUM:111,ISSUED_WK:4,RESPONDED_WK:2},
      {WK_NO:"W2022_08",CUTOFF:"2022-02-23",DDMMMYY:"23-Feb-22",ISSUED_CUM:180,RESPONDED_CUM:114,ISSUED_WK:6,RESPONDED_WK:3},
      {WK_NO:"W2022_09",CUTOFF:"2022-03-02",DDMMMYY:"02-Mar-22",ISSUED_CUM:189,RESPONDED_CUM:115,ISSUED_WK:9,RESPONDED_WK:1},
      {WK_NO:"W2022_10",CUTOFF:"2022-03-09",DDMMMYY:"09-Mar-22",ISSUED_CUM:195,RESPONDED_CUM:117,ISSUED_WK:6,RESPONDED_WK:2},
      {WK_NO:"W2022_11",CUTOFF:"2022-03-16",DDMMMYY:"16-Mar-22",ISSUED_CUM:204,RESPONDED_CUM:119,ISSUED_WK:9,RESPONDED_WK:2},
      {WK_NO:"W2022_12",CUTOFF:"2022-03-23",DDMMMYY:"23-Mar-22",ISSUED_CUM:214,RESPONDED_CUM:124,ISSUED_WK:10,RESPONDED_WK:5},
      {WK_NO:"W2022_13",CUTOFF:"2022-03-30",DDMMMYY:"30-Mar-22",ISSUED_CUM:220,RESPONDED_CUM:128,ISSUED_WK:6,RESPONDED_WK:4},
      {WK_NO:"W2022_14",CUTOFF:"2022-04-06",DDMMMYY:"06-Apr-22",ISSUED_CUM:226,RESPONDED_CUM:132,ISSUED_WK:6,RESPONDED_WK:4},
      {WK_NO:"W2022_15",CUTOFF:"2022-04-13",DDMMMYY:"13-Apr-22",ISSUED_CUM:232,RESPONDED_CUM:138,ISSUED_WK:6,RESPONDED_WK:6},
      {WK_NO:"W2022_16",CUTOFF:"2022-04-20",DDMMMYY:"20-Apr-22",ISSUED_CUM:242,RESPONDED_CUM:143,ISSUED_WK:10,RESPONDED_WK:5},
      {WK_NO:"W2022_17",CUTOFF:"2022-04-27",DDMMMYY:"27-Apr-22",ISSUED_CUM:246,RESPONDED_CUM:145,ISSUED_WK:4,RESPONDED_WK:2},
      {WK_NO:"W2022_18",CUTOFF:"2022-05-04",DDMMMYY:"04-May-22",ISSUED_CUM:252,RESPONDED_CUM:148,ISSUED_WK:6,RESPONDED_WK:3},
      {WK_NO:"W2022_19",CUTOFF:"2022-05-11",DDMMMYY:"11-May-22",ISSUED_CUM:255,RESPONDED_CUM:149,ISSUED_WK:3,RESPONDED_WK:1},
      {WK_NO:"W2022_20",CUTOFF:"2022-05-18",DDMMMYY:"18-May-22",ISSUED_CUM:259,RESPONDED_CUM:155,ISSUED_WK:4,RESPONDED_WK:6},
      {WK_NO:"W2022_21",CUTOFF:"2022-05-25",DDMMMYY:"25-May-22",ISSUED_CUM:270,RESPONDED_CUM:159,ISSUED_WK:11,RESPONDED_WK:4},
      {WK_NO:"W2022_22",CUTOFF:"2022-06-01",DDMMMYY:"01-Jun-22",ISSUED_CUM:273,RESPONDED_CUM:160,ISSUED_WK:3,RESPONDED_WK:1},
      {WK_NO:"W2022_23",CUTOFF:"2022-06-08",DDMMMYY:"08-Jun-22",ISSUED_CUM:277,RESPONDED_CUM:161,ISSUED_WK:4,RESPONDED_WK:1},
      {WK_NO:"W2022_24",CUTOFF:"2022-06-15",DDMMMYY:"15-Jun-22",ISSUED_CUM:282,RESPONDED_CUM:164,ISSUED_WK:5,RESPONDED_WK:3},
      {WK_NO:"W2022_25",CUTOFF:"2022-06-22",DDMMMYY:"22-Jun-22",ISSUED_CUM:287,RESPONDED_CUM:169,ISSUED_WK:5,RESPONDED_WK:5},
      {WK_NO:"W2022_26",CUTOFF:"2022-06-29",DDMMMYY:"29-Jun-22",ISSUED_CUM:289,RESPONDED_CUM:170,ISSUED_WK:2,RESPONDED_WK:1},
      {WK_NO:"W2022_27",CUTOFF:"2022-07-06",DDMMMYY:"06-Jul-22",ISSUED_CUM:294,RESPONDED_CUM:174,ISSUED_WK:5,RESPONDED_WK:4},
      {WK_NO:"W2022_28",CUTOFF:"2022-07-13",DDMMMYY:"13-Jul-22",ISSUED_CUM:301,RESPONDED_CUM:177,ISSUED_WK:7,RESPONDED_WK:3},
      {WK_NO:"W2022_29",CUTOFF:"2022-07-20",DDMMMYY:"20-Jul-22",ISSUED_CUM:306,RESPONDED_CUM:178,ISSUED_WK:5,RESPONDED_WK:1},
      {WK_NO:"W2022_30",CUTOFF:"2022-07-27",DDMMMYY:"27-Jul-22",ISSUED_CUM:308,RESPONDED_CUM:178,ISSUED_WK:2,RESPONDED_WK:0},
      {WK_NO:"W2022_31",CUTOFF:"2022-08-03",DDMMMYY:"03-Aug-22",ISSUED_CUM:313,RESPONDED_CUM:179,ISSUED_WK:5,RESPONDED_WK:1},
      {WK_NO:"W2022_32",CUTOFF:"2022-08-10",DDMMMYY:"10-Aug-22",ISSUED_CUM:321,RESPONDED_CUM:185,ISSUED_WK:8,RESPONDED_WK:6},
      {WK_NO:"W2022_33",CUTOFF:"2022-08-17",DDMMMYY:"17-Aug-22",ISSUED_CUM:323,RESPONDED_CUM:188,ISSUED_WK:2,RESPONDED_WK:3},
      {WK_NO:"W2022_34",CUTOFF:"2022-08-24",DDMMMYY:"24-Aug-22",ISSUED_CUM:327,RESPONDED_CUM:194,ISSUED_WK:4,RESPONDED_WK:6},
      {WK_NO:"W2022_35",CUTOFF:"2022-08-31",DDMMMYY:"31-Aug-22",ISSUED_CUM:337,RESPONDED_CUM:278,ISSUED_WK:10,RESPONDED_WK:84},
      {WK_NO:"W2022_36",CUTOFF:"2022-09-07",DDMMMYY:"07-Sep-22",ISSUED_CUM:340,RESPONDED_CUM:282,ISSUED_WK:3,RESPONDED_WK:4},
      {WK_NO:"W2022_37",CUTOFF:"2022-09-14",DDMMMYY:"14-Sep-22",ISSUED_CUM:344,RESPONDED_CUM:283,ISSUED_WK:4,RESPONDED_WK:1}
    ],
}