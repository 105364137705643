import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 580,
      CanvasHeight: 380,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#e0e0e0',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 380,
      CanvasChartHeight: 280,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: '', x:20, y: 10, font: 'roboto', class: 'regular', size: 12, color: '#424242', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: -50, y: 20, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'start' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: -30,
          y: 20,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'start'
        },
      ]
    },
    Queries: {
      SQL1: [
        {TOTAL:16937,COMPLETE:5073,REMAIN:11864,PROG:30}
      ]
    },
    DataItems: [
      {NO:4,CDATE:"2022-02-26",A_OV_COM:33002,A_OV_COM_DAILY:138},
      {NO:5,CDATE:"2022-02-27",A_OV_COM:33002,A_OV_COM_DAILY:0},
      {NO:6,CDATE:"2022-02-28",A_OV_COM:33013,A_OV_COM_DAILY:11},
      {NO:7,CDATE:"2022-03-01",A_OV_COM:33262,A_OV_COM_DAILY:249},
      {NO:8,CDATE:"2022-03-02",A_OV_COM:33478,A_OV_COM_DAILY:216},
      {NO:9,CDATE:"2022-03-03",A_OV_COM:33700,A_OV_COM_DAILY:222},
      {NO:10,CDATE:"2022-03-04",A_OV_COM:34250,A_OV_COM_DAILY:550}
    ],
}