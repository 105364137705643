
export default {
  Canvas: {
    CanvasWidth: 1200,
    CanvasHeight: 970,
    CanvasBorderWeight: 0.2,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1600,
    CanvasChartHeight: 760,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },
  
  DataItems: [
    { AREA: '111300', CWA: '111300', STATUS: 'EM', FORE_DATE: undefined, DESC: 'Benzene Toluene Battery Limit 745', }, 
    { AREA: '111342', CWA: '111342', STATUS: 'EM', FORE_DATE: '(Sep-20)', DESC: 'Benzene Toluene Sanitary Sewage Sump 745', }, 
    { AREA: '111394', CWA: '111394', STATUS: 'EM', FORE_DATE: undefined, DESC: 'Benzene Toluene Coproduct Operator Shelter 745', }, 
    { AREA: '121300', CWA: '121300', STATUS: 'EM', FORE_DATE: undefined, DESC: 'GPPE Battery limit 7350 GPPE', }, 
    { AREA: '121301', CWA: '121301', STATUS: 'EM', FORE_DATE: undefined, DESC: 'GPPE Interconnecting Pipe Rack 7350 GPPE', },
    { AREA: '121302', CWA: '121302', STATUS: 'EM', FORE_DATE: undefined, DESC: 'GPPE Feed And Utility Area A 7350 GPPE A', },
    { AREA: '121303', CWA: '121303', STATUS: 'EM', FORE_DATE: undefined, DESC: 'GPPE Feed And Utility Area B 7350 GPPE B', }, 
    { AREA: '121304', CWA: '121304', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'GPPE Extrusion Building 7350 GPPE', }, 
    { AREA: '121305', CWA: '121305', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'GPPE Thermal Oxidizer Blower Area 7350 GPPE', }, 
    { AREA: '121306', CWA: '121306', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'GPPE Pellet Dryers Classifiers And Surge Bin Area 7350 GPPE', }, 
    { AREA: '121307', CWA: '121307', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'GPPE Pellet Transfer Compressor Area 7350 GPPE', }, 
    { AREA: '121308', CWA: '121308', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'GPPE Elutriator And Pellet Silos Area 7350 GPPE', },
    { AREA: '121311', CWA: '121311', STATUS: 'SEI', FORE_DATE: '(Sep-20)', DESC: 'GPPE Reaction And Purge Bin Area Of Line 1 7350 GPPE 1', },
    { AREA: '121312', CWA: '121312', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'GPPE Vent Gas Recovery And Propylene Refrigeration Area Of Line 1 7350 GPPE 1', },
    { AREA: '121313', CWA: '121313', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'GPPE Granules Conveying System Of Line 1 7350 GPPE 1', },
    { AREA: '121321', CWA: '121321', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'GPPE Reaction And Purge Bin Area Of Line 2 7350 GPPE 2', },
    { AREA: '121322', CWA: '121322', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'GPPE Vent Gas Recovery And Propylene Refrigeration Area Of Line 2 7350 GPPE 2', },
    { AREA: '121323', CWA: '121323', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'GPPE Granules Conveying System Of Line 2 7350 GPPE 2', },
    { AREA: '121332', CWA: '121332', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'GPPE CHEMICAL STORAGE 7350 GPPE', },
    { AREA: '121342', CWA: '121342', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'GPPE Waste WaterStorm Water Area 7350 GPPE', },
    { AREA: '121391', CWA: '121391', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'GPPE RIE 5 7350 GPPE GPPE RIE 5', },
    { AREA: '121392', CWA: '121392', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'GPPE Substation 7350 GPPE', },
    { AREA: '121400', CWA: '121400', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'LDPE Battery Limit 50 LDPE', },
    { AREA: '121401', CWA: '121401', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'LDPE Compressor House 50', },
    { AREA: '121402', CWA: '121402', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'LDPE Reaction Area 50', },
    { AREA: '121403', CWA: '121403', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'LDPE Hp Recycle Area 50', },
    { AREA: '121404', CWA: '121404', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'LDPE Purge Gas Area 50', },
    { AREA: '121405', CWA: '121405', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'LDPE Extrusion Area 50', },
    { AREA: '121406', CWA: '121406', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'LDPE Compressor Lubrication Cooling Oil 50', },
    { AREA: '121407', CWA: '121407', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'LDPE Initiator Mixing 50', },
    { AREA: '121408', CWA: '121408', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'LDPE Chilled Water 50', },
    { AREA: '121409', CWA: '121409', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'LDPE Flare KnockOut 50', },
    { AREA: '121410', CWA: '121410', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'LDPE Utility Water Area 50', },
    { AREA: '121411', CWA: '121411', STATUS: 'SEI', FORE_DATE: '(Sep-20)', DESC: 'LDPE Modifier and Solvent System 50', },
    { AREA: '121412', CWA: '121412', STATUS: 'SEI', FORE_DATE: '(Sep-20)', DESC: 'LDPE Vent Gas Treatment Package 50 RTO', },
    { AREA: '121413', CWA: '121413', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'LDPE Pellet Degassing Silos 50', },
    { AREA: '121414', CWA: '121414', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'LDPE Blend And Bagging Silos 50', },
    { AREA: '121415', CWA: '121415', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'LDPE At LineLAB 50', },
    { AREA: '121442', CWA: '121442', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'LDPE Sump Area 50', },
    { AREA: '121491', CWA: '121491', STATUS: 'SEI', FORE_DATE: '(Sep-20)', DESC: 'LDPE RIE 3 50', },
    { AREA: '121492', CWA: '121492', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'LDPE Substation 50 LDPE', },
    { AREA: '121900', CWA: '121900', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'PP Battery Limit 48475 PP', },
    { AREA: '121901', CWA: '121901', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'PP Battery Limit Pipe Rack 48475', },
    { AREA: '121902', CWA: '121902', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'PP TEAL Facility Area 48475 PP', },
    { AREA: '121903', CWA: '121903', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'PP Granules Transfer and Extrusion Building 48475 PP', },
    { AREA: '121904', CWA: '121904', STATUS: 'SEI', FORE_DATE: '(Sep-20)', DESC: 'PP Pellet Silos Area 48475 PP', },
    { AREA: '121905', CWA: '121905', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'PP Pellet Elutriation and Bagging Area 48475 PP', },
    { AREA: '121906', CWA: '121906', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'PP Hydrogen Compressor Unit 48475 PP', },
    { AREA: '121907', CWA: '121907', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'PP OilGrease Storage and Mixing Propylene Dryer Area 48475 PP', },
    { AREA: '121908', CWA: '121908', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'PP Oil Treatment Chilled Water and Steam Condensate Drum Area 48475 PP', },
    { AREA: '121909', CWA: '121909', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'PP Regenerative Thermal Oxidizer Area 48475 PP RTO', },
    { AREA: '121911', CWA: '121911', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'PP CPP 1 Propylene Feed and Steam Condensate Area 48475 PP1', },
    { AREA: '121912', CWA: '121912', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'PP CPP 1 Catalyst Preparation and Metering Area 48475 PP1', },
    { AREA: '121913', CWA: '121913', STATUS: 'SEI', FORE_DATE: '(Sep-20)', DESC: 'PP CPP 1 Bulk Polymerization Area 48475 PP1', },
    { AREA: '121914', CWA: '121914', STATUS: 'SEI', FORE_DATE: '(Sep-20)', DESC: 'PP CPP 1 Gas Phase Reactors Polymer Degassing and Granules Drying Area 48475 PP1', },
    { AREA: '121915', CWA: '121915', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'PP CPP 1 Polymerization Area Pipe Rack 48475 PP1', },
    { AREA: '121916', CWA: '121916', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'PP CPP 1 TEAL Scrubber and Recycle Gas Compressor Area 48475 PP1TEAL', },
    { AREA: '121917', CWA: '121917', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'PP CPP 1 Blowdown Area 48475 PP1', },
    { AREA: '121921', CWA: '121921', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'PP CPP 2 Propylene Feed Area 48475 PP2', },
    { AREA: '121922', CWA: '121922', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'PP CPP 2 Catalyst Preparation and Metering Area 48475 PP2', },
    { AREA: '121923', CWA: '121923', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'PP CPP 2 Bulk Polymerization Area 48475 PP2', },
    { AREA: '121924', CWA: '121924', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'PP CPP 2 Polymer Degassing and Granules Drying Area 48475 PP2', },
    { AREA: '121925', CWA: '121925', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'PP CPP 2 Polymerization Area Pipe Rack 48475 PP2', },
    { AREA: '121926', CWA: '121926', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'PP CPP 2 TEAL Scrubber and Recycle Gas Compressor Area 48475 PP2TEAL', },
    { AREA: '121927', CWA: '121927', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'PP CPP 2 Blowdown and Ethylene Compressor Area 48475 PP2', },
    { AREA: '121931', CWA: '121931', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'PP New LubeWaste Oil Drum Storage 48475 PP', },
    { AREA: '121932', CWA: '121932', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'PP Chemical Storage 48475 PP', },
    { AREA: '121942', CWA: '121942', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'PP Waste WaterStorm Water Area 48475 PP', },
    { AREA: '121991', CWA: '121991', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'PP RIE 4 48475 PPRIE 4', },
    { AREA: '121992', CWA: '121992', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'PP Substation 48475 PP', },
    { AREA: '124700', CWA: '124700', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Infra Propylene Tank Farm', },
    { AREA: '127700', CWA: '127700', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Butadiene Battery Limit 27', },
    { AREA: '127742', CWA: '127742', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Butadiene CoProduct Waste Water Sump 27', },
    { AREA: '127791', CWA: '127791', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Butadiene RIE 2 27 RIE2', },
    { AREA: '127792', CWA: '127792', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Butadiene Coproduct Substation 27', },
    { AREA: '127800', CWA: '127800', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Gasoline Hydrotreating Battery Limit 120', },
    { AREA: '127900', CWA: '127900', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Olefins Battery Limit 160', },
    { AREA: '127901', CWA: '127901', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Olefins Furnaces Area 160', },
    { AREA: '127902', CWA: '127902', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Olefins Quench Area 160', },
    { AREA: '127903', CWA: '127903', STATUS: 'SEI', FORE_DATE: '(Sep-20)', DESC: 'Olefins Compression Area 160', },
    { AREA: '127904', CWA: '127904', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Olefins Cold Fraction Area 160', },
    { AREA: '127905', CWA: '127905', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Olefins Hot Fraction Area 160', },
    { AREA: '127906', CWA: '127906', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Olefins PSA Unit 160 PSA', },
    { AREA: '127907', CWA: '127907', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Olefins Spent Caustic Area 160', },
    { AREA: '127908', CWA: '127908', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Olefins Steam Condensate Area 160', },
    { AREA: '127909', CWA: '127909', STATUS: 'SEI', FORE_DATE: '(Sep-20)', DESC: 'Olefins Benzene Removal Area 160', },
    { AREA: '127942', CWA: '127942', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Olefins Waste Water Sump 160', },
    { AREA: '127982', CWA: '127982', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Olefins 2nd Substation 160', },
    { AREA: '127991', CWA: '127991', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Olefins RIE1 160 RIE1', },
    { AREA: '127992', CWA: '127992', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Olefins 1st Substation 160', },
    { AREA: '136200', CWA: '136200', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'DiIsobutylene Battery Limit 155DIB', },
    { AREA: '150200', CWA: '150200', STATUS: 'SEI', FORE_DATE: '(Sep-20)', DESC: 'Infra Overall Grading Layout Landscaping Road layout', },
    { AREA: '150300', CWA: '150300', STATUS: 'SEI', FORE_DATE: '(Sep-20)', DESC: 'Mini area code for Main UG Piping for whole Plant', },
    { AREA: '150400', CWA: '150400', STATUS: 'SEI', FORE_DATE: '(Sep-20)', DESC: 'Infra Fence for all area P1P2P3 P1P2P3', },
    { AREA: '150401', CWA: '150401', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Infra Gate and Gate house 1 1', },
    { AREA: '150402', CWA: '150402', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Infra Gate and Gate house 2 2', },
    { AREA: '150403', CWA: '150403', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Infra Gate and Gate house 3 3', },
    { AREA: '150404', CWA: '150404', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Infra Gate and Gate house 4 4', },
    { AREA: '150405', CWA: '150405', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Infra Gate and Gate house 5 5', },
    { AREA: '150406', CWA: '150406', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Infra Gate and Gate house 6 6', },
    { AREA: '151000', CWA: '151000', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Infra Common Area of Management Facilities B B', },
    { AREA: '152000', CWA: '152000', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Infra Central Laboratory and Environmental Monitoring Station', },
    { AREA: '152004', CWA: '152004', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Infra Environmental Monitoring station1 in Central Laboratory 1', },
    { AREA: '152014', CWA: '152014', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Infra Environmental Monitoring station2 in Central Laboratory 2', },
    { AREA: '152200', CWA: '152200', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Infra Maintenance Workshop', },
    { AREA: '152300', CWA: '152300', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Infra Warehouse Management Room and Spare Parts Warehouse', },
    { AREA: '152400', CWA: '152400', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Infra Chemical Warehouses', },
    { AREA: '152700', CWA: '152700', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Infra Main plant Dispatch Area', },
    { AREA: '152800', CWA: '152800', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'PBL PBL Battery Limit', },
    { AREA: '152801', CWA: '152801', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'PBL No1 Warehouse 1', },
    { AREA: '152802', CWA: '152802', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'PBL No2 Warehouse 2', },
    { AREA: '152803', CWA: '152803', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'PBL No1 Package Plant 1', },
    { AREA: '152804', CWA: '152804', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'PBL No2 Package Plant 2', },
    { AREA: '152805', CWA: '152805', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'PBL Auxiliary Facility', },
    { AREA: '154000', CWA: '154000', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Infra Administration Office Building B B', },
    { AREA: '154001', CWA: '154001', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Infra Connecting Corridor Overhead Bridge', },
    { AREA: '166800', CWA: '166800', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Infra Ammonia Water Tank Farm', },
    { AREA: '168000', CWA: '168000', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Infra Naphtha Tank Farm', },
    { AREA: '170000', CWA: '170000', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Infra Pressurized Tank Farm Common Area', },
    { AREA: '170091', CWA: '170091', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Infra RIE 7 RIE 7', },
    { AREA: '170092', CWA: '170092', STATUS: 'SEI', FORE_DATE: '(Sep-20)', DESC: 'Infra Tank Farm 1st Substation', },
    { AREA: '170100', CWA: '170100', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Infra Ethylene Cryogenic Tank Farm', },
    { AREA: '170300', CWA: '170300', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Infra Pressurized Tank Farm 1 1', },
    { AREA: '170800', CWA: '170800', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Infra Mogas Tank Farm', },
    { AREA: '177100', CWA: '177100', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Infra Intermediate Tank Farm 1 1', },
    { AREA: '178000', CWA: '178000', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Infra LoadingUnloading Station', },
    { AREA: '178001', CWA: '178001', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Infra LoadingUnloading Station Building', },
    { AREA: '178600', CWA: '178600', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Infra Elevated Flare', },
    { AREA: '178900', CWA: '178900', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Infra Ground Flare', },
    { AREA: '178903', CWA: '178903', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Infra Flare Gas Recovery Unit', },
    { AREA: '178992', CWA: '178992', STATUS: 'SEI', FORE_DATE: undefined, DESC: 'Infra Ground Flare substation', },
    { AREA: '179000', CWA: '179000-01', STATUS: 'SEI', FORE_DATE: '(Sep-20)', DESC: 'Overall Level1 Pipe Rack 1 1', },
    { AREA: '179000', CWA: '179000-02', STATUS: 'SEI', FORE_DATE: '(Sep-20)', DESC: 'Overall Level1 Pipe Rack 2 2', },
    { AREA: '179100', CWA: '179100-03', STATUS: 'SEI', FORE_DATE: '(Sep-20)', DESC: 'Overall Level1 Pipe Rack 3 3', },
    { AREA: '179100', CWA: '179100-04', STATUS: 'SEI', FORE_DATE: '(Sep-20)', DESC: 'Overall Level1 Pipe Rack 4 4', },
    { AREA: '179100', CWA: '179100-05', STATUS: 'SEI', FORE_DATE: '(Sep-20)', DESC: 'Overall Level1 Pipe Rack 5 5', },
    { AREA: '179100', CWA: '179100-06', STATUS: 'SEI', FORE_DATE: '(Sep-20)', DESC: 'Overall Level1 Pipe Rack 6 6', },
    { AREA: '179100', CWA: '179100-07', STATUS: 'SEI', FORE_DATE: '(Sep-20)', DESC: 'Overall Level1 Pipe Rack 7 7', },
    { AREA: '179600', CWA: '179600', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra Vapor Gas Recovery Unit', },
    { AREA: '180200', CWA: '180200', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra Cooling Water System 1', },
    { AREA: '180292', CWA: '180292', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra Cooling Water System1 Substation', },
    { AREA: '180401', CWA: '180401', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra Waste Water Pump Station 1 B Area 1', },
    { AREA: '180402', CWA: '180402', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra Waste Water Pump Station 2 Hexene1 Tank Farm 2', },
    { AREA: '180403', CWA: '180403', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra Waste Water Pump Station 3 Intermediate Tank Farm 1 3', },
    { AREA: '180404', CWA: '180404', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra Waste Water Pump Station 4 Chemical Warehouse 4', },
    { AREA: '180600', CWA: '180600', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra Storm Water Pump Station and Accident Buffer Basin', },
    { AREA: '181000', CWA: '181000', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra Water and Firewater Pump Station 1', },
    { AREA: '181001', CWA: '181001', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra Telecom equipment room Water and Firewater Pump Station1 area', },
    { AREA: '181200', CWA: '181200', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra FF Gas Protection Station', },
    { AREA: '181301', CWA: '181301', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra Foam Station 1 Ethylene Cryogenic Tank 1', },
    { AREA: '181302', CWA: '181302', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra Foam Station 2 Intermediate Tank Farm 1 2', },
    { AREA: '181303', CWA: '181303', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra Foam Station 3 Naphtha Tank Farm 3', },
    { AREA: '181304', CWA: '181304', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra Foam Station 4 Naphtha Tank Farm 4', },
    { AREA: '182200', CWA: '182200', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra Hazardous waste temporary warehouse', },
    { AREA: '182400', CWA: '182400', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra Wastewater PreTreatment Unit', },
    { AREA: '182492', CWA: '182492', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra Wastewater PreTreatment Unit Substation', },
    { AREA: '183000', CWA: '183000', STATUS: 'EM',  FORE_DATE: undefined , DESC: 'Infra Main Substation', },
    { AREA: '183100', CWA: '183100', STATUS: 'SEI', FORE_DATE: '(Sep-20)' , DESC: 'Infra Area B Substation B', },
    { AREA: '184300', CWA: '184300', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Demineralized Water Unit Battery Limit', },
    { AREA: '184301', CWA: '184301', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Demineralized Water Unit Chemical Water Treatment', },
    { AREA: '184302', CWA: '184302', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Demineralized Water Unit Condensate Water Treatment', },
    { AREA: '184303', CWA: '184303', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Demineralized Water Unit Acid Caustic Station', },
    { AREA: '184800', CWA: '184800', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Boiler Unit Boiler Battery Limit', },
    { AREA: '184891', CWA: '184891', STATUS: 'EM',  FORE_DATE: '(Sep-20)' , DESC: 'Boiler Unit Boiler RIE6 RIE6', },
    { AREA: '184892', CWA: '184892', STATUS: 'EM',  FORE_DATE: undefined , DESC: 'Boiler Unit Boiler Unit Substation', },
    { AREA: '185300', CWA: '185300', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra Air Compression Station', },
    { AREA: '185392', CWA: '185392', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra Air Compression Station Substation', },
    { AREA: '185394', CWA: '185394', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra Air Compression Station Operator Shelter', },
    { AREA: '186000', CWA: '186000', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra Control Center Building', },
    { AREA: '187501', CWA: '187501', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Radio Antenna tower incl Telecom equipment room', },
    { AREA: '190400', CWA: '190400', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra Eastward Pipeline', },
    { AREA: '252701', CWA: '252701', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra Truck Scale1 BaggingPPLDPE 1 1', },
    { AREA: '252702', CWA: '252702', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra Truck Scale2 BaggingGPPE 2 2', },
    { AREA: '252703', CWA: '252703', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra Truck Scale3 Chemical Warehouse 3', },
    { AREA: '270100', CWA: '270100', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra Offspec Ethylene Tank Farm', },
    { AREA: '270300', CWA: '270300', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra Pressurized Tank Farm 2 2', },
    { AREA: '277000', CWA: '277000', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra Atmospheric Tank Farm Common Area', },
    { AREA: '277082', CWA: '277082', STATUS: 'SEI', FORE_DATE: '(Sep-20)' , DESC: 'Infra Tank Farm 2nd Substation', },
    { AREA: '277100', CWA: '277100', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra Intermediate Tank Farm 2 2', },
    { AREA: '280200', CWA: '280200', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra Cooling Water System 2', },
    { AREA: '280292', CWA: '280292', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra Cooling Water System2 Substation', },
    { AREA: '280294', CWA: '280294', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra Operator Shelter', },
    { AREA: '281000', CWA: '281000', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra Water and Firewater Pump Station 2', },
    { AREA: '281001', CWA: '281001', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra Telecom equipment room Water and Firewater Pump Station2 area', },
    { AREA: '370100', CWA: '370100', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra Hexene1 Tank Farm 1', },
    { AREA: '377100', CWA: '377100', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra Intermediate Tank Farm 3 3', },
    { AREA: '381006', CWA: '381006', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Infra Firewater Pump Station B B', },
    { AREA: '127900', CWA: '127993', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Olefins - Water Spray', },
    { AREA: '121900', CWA: '121910', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'PP - CPP 1 Polymerization Area', },
    { AREA: '121900', CWA: '121920', STATUS: 'SEI', FORE_DATE: '(Sep-20)' , DESC: 'PP - CPP 2 Polymerization Area', },
    { AREA: '152400', CWA: '152405', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Chemical Warehouses 5', },
    { AREA: '152400', CWA: '152408', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Chemical Warehouses 8', },
    { AREA: '152400', CWA: '152492', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Substation', },
    { AREA: '179105', CWA: '179105', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Pipe Galley #5', },
    { AREA: '179106', CWA: '179106', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Pipe Galley #6', },
    { AREA: '179107', CWA: '179107', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Pipe Galley #7', },
    { AREA: '121400', CWA: '152406', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Chemical Warehouses 6', },
    { AREA: '152400', CWA: '152401', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Chemical Warehouses 1', },
    { AREA: '152400', CWA: '152402', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Chemical Warehouses 2', },
    { AREA: '152400', CWA: '152403', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Chemical Warehouses 3', },
    { AREA: '152400', CWA: '152404', STATUS: 'SEI', FORE_DATE: '(Sep-20)' , DESC: 'Chemical Warehouses 4', },
    { AREA: '152400', CWA: '152407', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Chemical Warehouses 7', },
    { AREA: '179001', CWA: '179001', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Pipe Galley #1', },
    { AREA: '179002', CWA: '179002', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Pipe Galley #2', },
    { AREA: '179003', CWA: '179003', STATUS: 'SEI', FORE_DATE: '(Sep-20)' , DESC: 'Pipe Galley #3', },
    { AREA: '179004', CWA: '179004', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Pipe Galley #4', }, 
    { AREA: '152800', CWA: '152803_TEMP', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'PBL Temporary Power', }, 
    { AREA: '152804', CWA: '5180BN001', STATUS: 'SEI', FORE_DATE: undefined , DESC: 'Silo Subsystem', }, 
  ],

  
}