import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 800,
      CanvasHeight: 260,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },
    DataItems:    [
      {COMPANY:" ExxonMobil",DOC_UNIQUE_COUNT:7269,WORKFLOW_UNIQUE_COUNT:525,REVIEW_STEPS:7269,DOC_OVERDUE:154},
{COMPANY:"NA",DOC_UNIQUE_COUNT:6782,WORKFLOW_UNIQUE_COUNT:525,REVIEW_STEPS:6782,DOC_OVERDUE:176},
{COMPANY:"TOTAL",DOC_UNIQUE_COUNT:14051,WORKFLOW_UNIQUE_COUNT:1050,REVIEW_STEPS:14051,DOC_OVERDUE:330}
    ],
}