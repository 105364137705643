import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1320,
      CanvasHeight: 200,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 1,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 200,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: '#ffffff',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },

    Text: {
      Text: [
        // { text: 'Power Generation', x: 0, y: -20, font: 'roboto', class: 'regular', size: 14, color: '#01579B', align: 'start' },
      ],
      Filter: [],
      Sql: []
    },

    DataItems: [
      {DISC_DESCR:"Structure",TARGET_PROG:100,PLAN_PROG:83.3,ACTUAL_PROG:72.1,DELTA:-11.19,DISC:"36"},
      {DISC_DESCR:"Piping",TARGET_PROG:87.53,PLAN_PROG:55.78,ACTUAL_PROG:21.5,DELTA:-34.27,DISC:"13"},
      {DISC_DESCR:"Electrical",TARGET_PROG:91.94,PLAN_PROG:22.13,ACTUAL_PROG:1.3,DELTA:-20.83,DISC:"16"},
      {DISC_DESCR:"Instrument",TARGET_PROG:95.36,PLAN_PROG:1.15,ACTUAL_PROG:0,DELTA:-1.15,DISC:"15"},
      {DISC_DESCR:"Mechanical",TARGET_PROG:100,PLAN_PROG:95.64,ACTUAL_PROG:70.77,DELTA:-24.87,DISC:"68"},
      {DISC_DESCR:"Painting",TARGET_PROG:97.44,PLAN_PROG:74.68,ACTUAL_PROG:34.55,DELTA:-40.13,DISC:"23"},
      {DISC_DESCR:"Insulation",TARGET_PROG:12.14,PLAN_PROG:0,ACTUAL_PROG:0,DELTA:0,DISC:"22"},
      {DISC_DESCR:"Fire & Safety",TARGET_PROG:8.47,PLAN_PROG:0,ACTUAL_PROG:0,DELTA:0,DISC:"61"}
    ],
    Queries: {
      SQL1: [
        {NO:6,LV1:"TOPSIDE",LV2:"MODULES",LV3:"RCxx",PLAN:62.21,ACTUAL:45.17,CERT:25.71,DELTA:-17.03,PLAN_WK:2.67,ACTUAL_WK:3.76,CERT_WK:0.49,DELTA_WK:1.08,TEXT1:"Lifting Plan",TEXT1_DATE:"30-Nov-21",TEXT2:"OGM Goal",TEXT2_PROG:90.14}
      ]
    }
}