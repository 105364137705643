import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1000,
      CanvasHeight: 350,
      CanvasBorderWeight: 0.5,
      CanvasBorderColor: '#E0E0E0',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1000,
      CanvasChartHeight: 350,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: '', x:20, y: 10, font: 'roboto', class: 'regular', size: 12, color: '#424242', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: -50, y: 20, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'start' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: -30,
          y: 20,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'start'
        },
      ]
    },

    DataItems: [
      // {MONTH:"June",TOTAL:10,WEEKS2:4,WEEKS4:6,WEEKS6:8,WEEKS8:3,WEEKS10:9,WEEKS12:3,OVER_12WEEKS:5},

      {COUNT:9,DAYS:"9 days",DAYLATE:1},
      {COUNT:8,DAYS:"8 days",DAYLATE:11},
      {COUNT:5,DAYS:"5 days",DAYLATE:2},
      {COUNT:4,DAYS:"4 days",DAYLATE:33},
      {COUNT:3,DAYS:"3 days",DAYLATE:19},
      {COUNT:2,DAYS:"2 days",DAYLATE:109},
      {COUNT:1,DAYS:"1 day",DAYLATE:57},
      {DAYS:"Blank",DAYLATE:51}
    ],
}