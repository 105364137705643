import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 300,
      CanvasHeight: 340,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 300,
      CanvasChartHeight: 340,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

    
  Text: {
    Text: [],
    Filter: [],
    Sql: [
      {
        query: "SELECT 'Data source : '+ [TITLE] + [WEEK_NO] text FROM [T01_Project].[DataSource].[Document] WHERE [PHASE] = 'PLANNING' AND [CODE] = 'HISTOGRAM'", 
        text: 'DataSource',
        x: 270,
        y: 0,
        font: 'roboto',
        style: 'regular',
        size: 10,
        color: '#757575',
        align: 'start'
      },
    ]
  },

    DataItems: [
      {LV2:"HULL-COMMON",PLAN:35.1,ACTUAL:19.76,DELTA:-15.33}
    ],
}