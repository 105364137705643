import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 50,
      CanvasHeight: 50,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: 'Transparent',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

    DataItems: [

      {GWBS_LV3:"KO01",PLAN:21.79,ACTUAL:7.83,DELTA:-13.96,PLAN_WK:1.41,ACTUAL_WK:0.73,DELTA_WK:-0.68}
    ],
}