import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 800,
    CanvasHeight: 280,
    CanvasBorderWeight: 0.3,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.2,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 310,
    CanvasChartHeight: 270,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: 'Transparent',
    CanvasChartFillColor: 'Transparent',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },

    // ----------------------------------------------- NOTE
  Note: {
    NoteDisplay: 'N',
    NoteX: 50,
    NoteY: 420,
    NoteAlign: 'start',
    NoteTitleText: 'Notes.',
    NoteTitleFont: 'roboto',
    NoteTitleStyle: 'regular',
    NoteTitleSize: 11,
    NoteTitleColor: '#757575',

    NoteContentsText: 'Note Contents Here!',
    NoteContentsFont: 'roboto',
    NoteContentsStyle: 'regular',
    NoteContentsSize: 10,
    NoteContentsColor: '#BDBCBC',
  },

  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  DataItems: [
    {DISC:'ARCHITECTURE'  , PRE_PLAN_WK: 1.3 , PLAN_WK: 0.5 , ACTUAL_WK:0   , ACTUAL_CERT_WK: 0   , VAR_WK:-0.5,PRE_PLAN_CUM:29.6,PLAN_CUM:9.5,ACTUAL_CUM:1.4,ACTUAL_CERT_CUM:0,VAR_CUM:-8},
    {DISC:'ELECTRICAL'    , PRE_PLAN_WK: 0.1 , PLAN_WK: 0.4 , ACTUAL_WK:0.4 , ACTUAL_CERT_WK: 0   , VAR_WK:0,PRE_PLAN_CUM:25.6,PLAN_CUM:7.2,ACTUAL_CUM:4.5,ACTUAL_CERT_CUM:0.4,VAR_CUM:-2.6},
    {DISC:'FIRE & SAFETY' , PRE_PLAN_WK: 0   , PLAN_WK: 0   , ACTUAL_WK:0   , ACTUAL_CERT_WK: 0   , VAR_WK:0,PRE_PLAN_CUM:0,PLAN_CUM:0,ACTUAL_CUM:0,ACTUAL_CERT_CUM:0,VAR_CUM:0},
    {DISC:'HVAC'          , PRE_PLAN_WK: 1.5 , PLAN_WK: 1.5 , ACTUAL_WK:0.4 , ACTUAL_CERT_WK: 0   , VAR_WK:-1,PRE_PLAN_CUM:57.6,PLAN_CUM:21.8,ACTUAL_CUM:8.3,ACTUAL_CERT_CUM:0,VAR_CUM:-13.4},
    {DISC:'INSTRUMENT'    , PRE_PLAN_WK: 0   , PLAN_WK: 0   , ACTUAL_WK:0   , ACTUAL_CERT_WK: 0   , VAR_WK:0,PRE_PLAN_CUM:0,PLAN_CUM:0,ACTUAL_CUM:0,ACTUAL_CERT_CUM:0,VAR_CUM:0},
    {DISC:'INSULATION'    , PRE_PLAN_WK: 0   , PLAN_WK: 0   , ACTUAL_WK:0   , ACTUAL_CERT_WK: 0   , VAR_WK:0,PRE_PLAN_CUM:0,PLAN_CUM:0,ACTUAL_CUM:0,ACTUAL_CERT_CUM:0,VAR_CUM:0},
    {DISC:'MECHANICAL'    , PRE_PLAN_WK: 3.3 , PLAN_WK: 0   , ACTUAL_WK:0   , ACTUAL_CERT_WK: 0   , VAR_WK:0,PRE_PLAN_CUM:12.8,PLAN_CUM:0.3,ACTUAL_CUM:0,ACTUAL_CERT_CUM:0,VAR_CUM:-0.3},
    {DISC:'PAINTING'      , PRE_PLAN_WK: 0.3 , PLAN_WK: 1   , ACTUAL_WK:0.2 , ACTUAL_CERT_WK: 0.1 , VAR_WK:-0.7,PRE_PLAN_CUM:31.3,PLAN_CUM:17.9,ACTUAL_CUM:12.7,ACTUAL_CERT_CUM:11,VAR_CUM:-5.2},
    {DISC:'PIPING'        , PRE_PLAN_WK: 0.2 , PLAN_WK: 0.2 , ACTUAL_WK:0   , ACTUAL_CERT_WK: 0   , VAR_WK:-0.2,PRE_PLAN_CUM:52.8,PLAN_CUM:4.5,ACTUAL_CUM:1,ACTUAL_CERT_CUM:0.6,VAR_CUM:-3.5},
    {DISC:'STRUCTURE'     , PRE_PLAN_WK: 2.3 , PLAN_WK: 2.6 , ACTUAL_WK:0.6 , ACTUAL_CERT_WK: 0.2 , VAR_WK:-1.9,PRE_PLAN_CUM:86.4,PLAN_CUM:61.2,ACTUAL_CUM:44.3,ACTUAL_CERT_CUM:33.5,VAR_CUM:-16.8},
    {DISC:'Total'         , PRE_PLAN_WK: 1.5 , PLAN_WK: 1.3 , ACTUAL_WK:0.3 , ACTUAL_CERT_WK: 0.1 , VAR_WK:-0.9,PRE_PLAN_CUM:66.1,PLAN_CUM:27,ACTUAL_CUM:18.4,ACTUAL_CERT_CUM:13.8,VAR_CUM:-8.5}
  ],
}