import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 1600,
    CanvasHeight: 950,
    CanvasBorderWeight: 1,
    CanvasBorderColor: '#000',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.2,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 860,
    CanvasChartHeight: 310,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: 'Transparent',
    CanvasChartFillColor: 'Transparent',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },

  // ----------------------------------------------- Filter Text
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },
  // -----------------------------------------------------------
  
  Queries: {
    SQL1:  [
      {NO:1,PACKAGE:"OP",EM_LEAD:"Atwood/Lin",SEI_LEAD:"NA",FUNCTIONS:"Infrastructure",LMS_NO:"INF-01",LMS_DESCR:"Nature Protection Zone Adjustment to Support Jetty Construction",PLAN:"2021-08-01",FORECAST:"2021-08-01",ACTUAL:"2021-08-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Government approval received (Oct 2022)",LOOKAHEAD:"NA",YEARS:2021,KMS_CNT:1},
      {NO:2,EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-01",LMS_DESCR:"GEG Cogen Final Agreement Signed",PLAN:"2021-09-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Final agreement signed",LOOKAHEAD:"NA"},
      {NO:3,EM_LEAD:"Batey",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-02",LMS_DESCR:"Naphtha/LPG Sourcing Plan/Shipping Strategy refresh",PLAN:"2021-09-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Report finalized and issued",LOOKAHEAD:"NA"},
      {NO:4,EM_LEAD:"XJ Li",SEI_LEAD:"NA",FUNCTIONS:"Commercial",LMS_NO:"CML-01",LMS_DESCR:"Chinese Government Engagement",PLAN:"2021-09-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Documented engagement",LOOKAHEAD:"NA"},
      {NO:5,EM_LEAD:"XJ Li",SEI_LEAD:"NA",FUNCTIONS:"Commercial",LMS_NO:"CML-02",LMS_DESCR:"Final Investment Agreement with Government",PLAN:"2021-09-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Documented agreement",LOOKAHEAD:"NA"},
      {NO:6,EM_LEAD:"K. Lin",SEI_LEAD:"NA",FUNCTIONS:"Commercial",LMS_NO:"CML-03",LMS_DESCR:"EIA for Base Plant Secured",PLAN:"2021-09-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Receipt of approval letter from authorities",LOOKAHEAD:"NA"},
      {NO:7,EM_LEAD:"K. Lin",SEI_LEAD:"NA",FUNCTIONS:"Commercial",LMS_NO:"CML-04",LMS_DESCR:"EIA Approval secured",PLAN:"2021-09-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Receipt of approval letter from authorities",LOOKAHEAD:"NA"},
      {NO:8,PACKAGE:"P1",EM_LEAD:"Webb",SEI_LEAD:"Hou",FUNCTIONS:"Olefins",LMS_NO:"OLE-01",LMS_DESCR:"Critical Furnace LOIs Issued (Radiant coils/TLEs)",PLAN:"2021-10-01",FORECAST:"2021-10-01",ACTUAL:"2021-10-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"LOIs issued",LOOKAHEAD:"NA",YEARS:2021,KMS_CNT:3},
      {NO:9,PACKAGE:"P1",EM_LEAD:"Webb",SEI_LEAD:"Hou",FUNCTIONS:"Olefins",LMS_NO:"OLE-02",LMS_DESCR:"CGC LOI Issued",PLAN:"2021-10-01",FORECAST:"2021-10-01",ACTUAL:"2021-10-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"LOI issued",LOOKAHEAD:"NA",YEARS:2021,KMS_CNT:2},
      {NO:10,PACKAGE:"P2",EM_LEAD:"Fuller",SEI_LEAD:"Ruan",FUNCTIONS:"Polymers",LMS_NO:"POL-01",LMS_DESCR:"Polymers critical equipment LOIs placed",PLAN:"2021-10-01",FORECAST:"2021-10-01",ACTUAL:"2021-10-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Defined LOIs issued - LDPE HP Pipe GPPE/PP extruders GPPE compressors",LOOKAHEAD:"NA",YEARS:2021,KMS_CNT:4},
      {NO:11,EM_LEAD:"Stein",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-03",LMS_DESCR:"FID Achieved",PLAN:"2021-11-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"FID approval receipt and funds in place",LOOKAHEAD:"NA"},
      {NO:12,EM_LEAD:"Stein",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-04",LMS_DESCR:"Gate 3 Review",PLAN:"2021-11-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Approval to progress",LOOKAHEAD:"NA"},
      {NO:13,EM_LEAD:"Batey",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-05",LMS_DESCR:"Firm deal on LPG Terminal",PLAN:"2021-11-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Documented agreement",LOOKAHEAD:"NA"},
      {NO:14,PACKAGE:"P2",EM_LEAD:"Ang",SEI_LEAD:"Bai",FUNCTIONS:"Engineering",LMS_NO:"ENG-01",LMS_DESCR:"All HAZOP Complete",PLAN:"2021-11-01",FORECAST:"2021-11-01",ACTUAL:"2021-11-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Reviews complete - resolution plan established",LOOKAHEAD:"NA",YEARS:2021,KMS_CNT:3},
      {NO:15,EM_LEAD:"K. Lin",SEI_LEAD:"NA",FUNCTIONS:"Commercial",LMS_NO:"CML-05",LMS_DESCR:"Construction Permit for Main Plant",PLAN:"2021-12-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"1st construction permit issued to allow piling to start on main plot",LOOKAHEAD:"NA"},
      {NO:16,PACKAGE:"P1",EM_LEAD:"ODonnell",SEI_LEAD:"Hou",FUNCTIONS:"Construction",LMS_NO:"CON-01",LMS_DESCR:"Start main plant piling (Basin / Furnace Areas)",PLAN:"2021-12-01",FORECAST:"2021-12-01",ACTUAL:"2021-12-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Rig on site piles available first pile driven. (continuous pile program sustainable)",LOOKAHEAD:"NA",YEARS:2021,KMS_CNT:4},
      {NO:17,EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-06",LMS_DESCR:"CWAG WWTP Final Agreement Signed",PLAN:"2022-01-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Final Agreement Signed / Documented (APRIL2022)",LOOKAHEAD:"NA"},
      {NO:18,EM_LEAD:"M. Lo",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-07",LMS_DESCR:"Define business model & scope of associated agreements",PLAN:"2022-01-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Business model proposal reviewed and aligned with key stakeholders and steering committee types of agreements defined",LOOKAHEAD:"NA"},
      {NO:19,PACKAGE:"P1",EM_LEAD:"Ang",SEI_LEAD:"Hou",FUNCTIONS:"Engineering",LMS_NO:"ENG-02",LMS_DESCR:"Olefins 30% Model Review",PLAN:"2022-01-01",FORECAST:"2022-01-01",ACTUAL:"2022-01-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Review complete with definition per CP (documented follow up plan)",LOOKAHEAD:"NA",YEARS:2022,KMS_CNT:2},
      {NO:20,PACKAGE:"P2",EM_LEAD:"Ang",SEI_LEAD:"Bai",FUNCTIONS:"Engineering",LMS_NO:"ENG-03",LMS_DESCR:"BED Completed",PLAN:"2022-01-01",FORECAST:"2022-01-01",ACTUAL:"2022-01-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Review complete with definition per CP (documented follow up plan)",LOOKAHEAD:"NA",YEARS:2022,KMS_CNT:7},
      {NO:21,PACKAGE:"P1",EM_LEAD:"Ang",SEI_LEAD:"Hou",FUNCTIONS:"Engineering",LMS_NO:"ENG-04",LMS_DESCR:"Furnaces 30% Model Review",PLAN:"2022-01-01",FORECAST:"2022-01-01",ACTUAL:"2022-01-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Review complete with definition per CP (documented follow up plan)",LOOKAHEAD:"NA",YEARS:2022,KMS_CNT:2},
      {NO:22,PACKAGE:"P1",EM_LEAD:"Ang",SEI_LEAD:"Hou",FUNCTIONS:"Engineering",LMS_NO:"ENG-05",LMS_DESCR:"Co-Products 30% Model Review",PLAN:"2022-01-01",FORECAST:"2022-01-01",ACTUAL:"2022-01-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Review complete with definition per CP (documented follow up plan)",LOOKAHEAD:"NA",YEARS:2022,KMS_CNT:3},
      {NO:23,PACKAGE:"P2",EM_LEAD:"Ang",SEI_LEAD:"Ruan",FUNCTIONS:"Engineering",LMS_NO:"ENG-06",LMS_DESCR:"Polymers 30% Model Review",PLAN:"2022-01-01",FORECAST:"2022-01-01",ACTUAL:"2022-01-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Review complete with definition per CP (documented follow up plan)",LOOKAHEAD:"NA",YEARS:2022,KMS_CNT:6},
      {NO:24,PACKAGE:"P1",EM_LEAD:"Webb",SEI_LEAD:"Hou",FUNCTIONS:"Olefins",LMS_NO:"OLE-03",LMS_DESCR:"Boiler LOI issued",PLAN:"2022-01-01",FORECAST:"2022-01-01",ACTUAL:"2022-01-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"LOI issued",LOOKAHEAD:"NA",YEARS:2022,KMS_CNT:2},
      {NO:25,PACKAGE:"P1",EM_LEAD:"Webb",SEI_LEAD:"Hou",FUNCTIONS:"Olefins",LMS_NO:"OLE-04",LMS_DESCR:"LOIs for balance LLE",PLAN:"2022-01-01",FORECAST:"2022-01-01",ACTUAL:"2022-01-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"LOIs issued for schedule critical equipment - per list (at start of EPC)",LOOKAHEAD:"NA",YEARS:2022,KMS_CNT:6},
      {NO:26,PACKAGE:"OP",EM_LEAD:"Gan",SEI_LEAD:"NA",FUNCTIONS:"Supply Chain",LMS_NO:"SUP-01A",LMS_DESCR:"Final Jetty Service Agreement with Huade",PLAN:"2022-01-01",FORECAST:"2022-01-01",ACTUAL:"2022-01-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Agreement Signed / Documented (MARCH 2022)",LOOKAHEAD:"NA",YEARS:2022,KMS_CNT:1},
      {NO:27,PACKAGE:"OP",EM_LEAD:"XJ Li",SEI_LEAD:"NA",FUNCTIONS:"Commercial",LMS_NO:"CML-06",LMS_DESCR:"Land for Liquid product Terminal turnover",PLAN:"2022-02-01",FORECAST:"2022-02-01",ACTUAL:"2022-02-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Documented ownership transfer (JUNE-2022)",LOOKAHEAD:"NA",YEARS:2022,KMS_CNT:3},
      {NO:28,EM_LEAD:"Emmen",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-01",LMS_DESCR:"Completed Hydrocarbon introduction plans for cracker coproducts & pmers",PLAN:"2022-04-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Documented Plans",LOOKAHEAD:"NA"},
      {NO:29,EM_LEAD:"Emmen",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-02",LMS_DESCR:"Develop plant testing scope plan resourcing duration and cost",PLAN:"2022-04-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Documented Plans",LOOKAHEAD:"NA"},
      {NO:30,EM_LEAD:"Gan",SEI_LEAD:"NA",FUNCTIONS:"Supply Chain",LMS_NO:"SUP-02",LMS_DESCR:"3PL JV governance structure & stewardship established/operational",PLAN:"2022-04-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Structure and stewardship in place",LOOKAHEAD:"NA"},
      {NO:31,PACKAGE:"P3",EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-37",LMS_DESCR:"Waste water treatment commencement of construction",PLAN:"2022-06-01",FORECAST:"2022-06-01",ACTUAL:"2022-06-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Commence WWT construction (JULY 2022)",LOOKAHEAD:"NA",YEARS:2022,KMS_CNT:1},
      {NO:32,EM_LEAD:"M. Lo",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-08",LMS_DESCR:"Financial & Business process system design complete cross-functional alignment",PLAN:"2022-08-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Business requirement & process defined and system design completed and aligned",LOOKAHEAD:"NA"},
      {NO:33,EM_LEAD:"S. Shu",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-53",LMS_DESCR:"PE/PP Global product movement plan defined",PLAN:"2022-10-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Match sourcing plant with receiving region (Actual Dec-22)",LOOKAHEAD:"NA"},
      {NO:34,PACKAGE:"P1",EM_LEAD:"Ang",SEI_LEAD:"Hou",FUNCTIONS:"Engineering",LMS_NO:"ENG-07",LMS_DESCR:"Furnaces 60% Model Review",PLAN:"2022-10-01",FORECAST:"2022-10-01",ACTUAL:"2022-10-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Review complete with definition per CP (documented follow up plan)",LOOKAHEAD:"NA",YEARS:2022,KMS_CNT:2},
      {NO:35,PACKAGE:"P1",EM_LEAD:"Ang",SEI_LEAD:"Hou",FUNCTIONS:"Engineering",LMS_NO:"ENG-11",LMS_DESCR:"OR 60% Model Review",PLAN:"2022-11-01",FORECAST:"2022-11-01",ACTUAL:"2022-11-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Review complete with definition per CP (documented follow up plan)",LOOKAHEAD:"NA",YEARS:2022,KMS_CNT:5},
      {NO:36,PACKAGE:"OP",EM_LEAD:"Atwood",SEI_LEAD:"NA",FUNCTIONS:"Infrastructure",LMS_NO:"INF-02",LMS_DESCR:"MOF & HHR Ready for Operation",PLAN:"2022-11-01",FORECAST:"2022-11-01",ACTUAL:"2022-11-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"MOF capable of off-loading vessels / equipment",LOOKAHEAD:"NA",YEARS:2022,KMS_CNT:10},
      {NO:37,PACKAGE:"P2",EM_LEAD:"Grubbs",SEI_LEAD:"Bai",FUNCTIONS:"Construction",LMS_NO:"CON-03",LMS_DESCR:"Heavy Lifts Commenced",PLAN:"2022-12-01",FORECAST:"2022-12-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Commence Heavy Lift program",LOOKAHEAD:"NA",YEARS:2022,KMS_CNT:8},
      {NO:38,PACKAGE:"P2",EM_LEAD:"Ang",SEI_LEAD:"Ruan",FUNCTIONS:"Engineering",LMS_NO:"ENG-08",LMS_DESCR:"Polymers 60% Model Reviews complete",PLAN:"2022-12-01",FORECAST:"2022-12-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Review complete with definition per CP (documented follow up plan)",LOOKAHEAD:"NA",YEARS:2022,KMS_CNT:11},
      {NO:39,PACKAGE:"P1",EM_LEAD:"Ang",SEI_LEAD:"Hou",FUNCTIONS:"Engineering",LMS_NO:"ENG-09",LMS_DESCR:"Co-Products 60% Model Review",PLAN:"2022-12-01",FORECAST:"2022-12-01",ACTUAL:"2022-12-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Review complete with definition per CP (documented follow up plan)",LOOKAHEAD:"NA",YEARS:2022,KMS_CNT:4},
      {NO:40,EM_LEAD:"Ang",SEI_LEAD:"Bai",FUNCTIONS:"Engineering",LMS_NO:"ENG-10",LMS_DESCR:"60% Model Reviews Complete",PLAN:"2022-12-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Review complete with definition per CP (documented follow up plan)",LOOKAHEAD:"NA"},
      {NO:41,PACKAGE:"P1",EM_LEAD:"Frye",SEI_LEAD:"Hou",FUNCTIONS:"Olefins",LMS_NO:"OLE-05",LMS_DESCR:"Olefins Critical U/G & Foundations Complete",PLAN:"2022-12-01",FORECAST:"2022-12-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Achieve a productive & safe work area complete area handover below the paving cap Underground piping ISBL Olefins complete Piling complete site wide Critical path Foundations complete",LOOKAHEAD:"NA",YEARS:2022,KMS_CNT:4},
      {NO:42,EM_LEAD:"S. Shu",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-54",LMS_DESCR:"PE/PP Functional Equivalence Plan Developed",PLAN:"2023-01-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Plan developed",LOOKAHEAD:"NA"},
      {NO:43,PACKAGE:"P1",EM_LEAD:"Frye",SEI_LEAD:"Hou",FUNCTIONS:"Olefins",LMS_NO:"OLE-06",LMS_DESCR:"Major Towers Set In-Place",PLAN:"2023-01-01",FORECAST:"2023-01-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"3 main towers set (PF C3 Rectifier Quench tower)",LOOKAHEAD:"NA",YEARS:2023,KMS_CNT:10},
      {NO:44,PACKAGE:"P1",EM_LEAD:"Frye",SEI_LEAD:"Hou",FUNCTIONS:"Olefins",LMS_NO:"OLE-07",LMS_DESCR:"Co-Products Out of the Ground - UG & Critical Foundations",PLAN:"2023-01-01",FORECAST:"2023-01-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Achieve a productive & safe work area Critical path Foundations complete to support Co-Products heavy lifts",LOOKAHEAD:"NA",YEARS:2023,KMS_CNT:6},
      {NO:45,PACKAGE:"P1",EM_LEAD:"Frye",SEI_LEAD:"Hou",FUNCTIONS:"Olefins",LMS_NO:"OLE-08",LMS_DESCR:"Furnace Radiant Erection (1st) - Olefins Furnace",PLAN:"2023-01-01",FORECAST:"2023-01-01",ACTUAL:"2023-01-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"1st tier structural steel and panels erected",LOOKAHEAD:"NA",YEARS:2023,KMS_CNT:3},
      {NO:46,EM_LEAD:"W. Zhang",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-09",LMS_DESCR:"Business Readiness Cold Eyes Review 1",PLAN:"2023-03-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Review completed with follow ups documented / planned",LOOKAHEAD:"NA"},
      {NO:47,PACKAGE:"P1",EM_LEAD:"Ang",SEI_LEAD:"Hou",FUNCTIONS:"Engineering",LMS_NO:"ENG-12",LMS_DESCR:"Furnaces 90% Model Review",PLAN:"2023-04-01",FORECAST:"2023-04-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Review complete with definition per CP (documented follow up plan)",LOOKAHEAD:"90D",YEARS:2023,KMS_CNT:2},
      {NO:48,EM_LEAD:"Letterle",SEI_LEAD:"Ruan",FUNCTIONS:"Polymers",LMS_NO:"POL-10",LMS_DESCR:"Reaction Area Ready for Frame Installation - LDPE",PLAN:"2023-04-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Reaction Area Ready for Frame Installation",LOOKAHEAD:"NA"},
      {NO:49,PACKAGE:"P1",EM_LEAD:"Ang",SEI_LEAD:"Hou",FUNCTIONS:"Engineering",LMS_NO:"ENG-15",LMS_DESCR:"Olefins 90% Model Review",PLAN:"2023-05-01",FORECAST:"2023-05-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Review complete with definition per CP (documented follow up plan)",LOOKAHEAD:"90D",YEARS:2023,KMS_CNT:2},
      {NO:50,PACKAGE:"P3",EM_LEAD:"Letterle",SEI_LEAD:"Niu",FUNCTIONS:"Infrastructure",LMS_NO:"INF-03",LMS_DESCR:"Control Room Building - Ready to install hardware",PLAN:"2023-05-01",FORECAST:"2023-05-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Access to commence installation",LOOKAHEAD:"90D",YEARS:2023,KMS_CNT:6},
      {NO:51,EM_LEAD:"S. Sun",SEI_LEAD:"NA",FUNCTIONS:"Supply Chain",LMS_NO:"SUP-03",LMS_DESCR:"Integrated end-to-end Test Plan Complete",PLAN:"2023-05-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented Plans",LOOKAHEAD:"NA"},
      {NO:52,EM_LEAD:"Yu",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-12",LMS_DESCR:"Feedstock Acquisition in Corporate Plan",PLAN:"2023-06-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented in Corp Plan",LOOKAHEAD:"NA"},
      {NO:53,EM_LEAD:"S. Shu",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-55",LMS_DESCR:"Scrap handling vendor selected and contract signed",PLAN:"2023-06-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Agreement signed",LOOKAHEAD:"NA"},
      {NO:54,PACKAGE:"P1",EM_LEAD:"Ang",SEI_LEAD:"Hou",FUNCTIONS:"Engineering",LMS_NO:"ENG-13",LMS_DESCR:"Co-Products 90% Model Review",PLAN:"2023-06-01",FORECAST:"2023-06-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Review complete with definition per CP (documented follow up plan)",LOOKAHEAD:"90D",YEARS:2023,KMS_CNT:2},
      {NO:55,PACKAGE:"P2",EM_LEAD:"Ang",SEI_LEAD:"Ruan",FUNCTIONS:"Engineering",LMS_NO:"ENG-14",LMS_DESCR:"Polymers 90% Model Review",PLAN:"2023-06-01",FORECAST:"2023-06-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Review complete with definition per CP (documented follow up plan)",LOOKAHEAD:"90D",YEARS:2023,KMS_CNT:6},
      {NO:56,EM_LEAD:"M. Lo",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-10",LMS_DESCR:"Master Data Plan Defined",PLAN:"2023-07-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Define master data requirement timeline robust execution plan to ensure data integrity",LOOKAHEAD:"NA"},
      {NO:57,EM_LEAD:"Neo",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-03",LMS_DESCR:"Recruitment/Hiring Complete",PLAN:"2023-07-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Reviewed and verified (Leo)",LOOKAHEAD:"NA"},
      {NO:58,EM_LEAD:"Neo",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-05",LMS_DESCR:"Early Stand-up Phase 1 Complete",PLAN:"2023-07-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Reviewed & Sanctioned",LOOKAHEAD:"NA"},
      {NO:59,PACKAGE:"P1",EM_LEAD:"Frye",SEI_LEAD:"Hou",FUNCTIONS:"Olefins",LMS_NO:"OLE-09",LMS_DESCR:"Furnace Set 1st TLEs - OF (Delivered)",PLAN:"2023-07-01",FORECAST:"2023-07-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"1st set of TLE delivered to site",LOOKAHEAD:"NA",YEARS:2023,KMS_CNT:3},
      {NO:60,EM_LEAD:"Letterle",SEI_LEAD:"Ruan",FUNCTIONS:"Polymers",LMS_NO:"POL-08",LMS_DESCR:"Finishing Building Ready for Extruder Installation - GPPE",PLAN:"2023-07-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Building established and prepared for the commencement of extruder equipment installation Installation methodology defined and all required procedures and work method statements approved for use",LOOKAHEAD:"NA"},
      {NO:61,EM_LEAD:"Letterle",SEI_LEAD:"Ruan",FUNCTIONS:"Polymers",LMS_NO:"POL-09",LMS_DESCR:"Finishing Building Ready for Extruder Installation - PP",PLAN:"2023-07-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Building established and prepared for the commencement of extruder equipment installation Installation methodology defined and all required procedures and work method statements approved for use",LOOKAHEAD:"NA"},
      {NO:62,EM_LEAD:"Letterle",SEI_LEAD:"Ruan",FUNCTIONS:"Polymers",LMS_NO:"POL-11",LMS_DESCR:"Commence Installation of ASRS Equipment - PBL",PLAN:"2023-07-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Warehouse erection progressed to allow for the commencement of sustained installation of ASRS equipment ASRS equipment delivery and receipt supports sustained installation of equipment and vendor reps available on site",LOOKAHEAD:"NA"},
      {NO:63,EM_LEAD:"M. Lo",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-11",LMS_DESCR:"Solids Mini URT complete",PLAN:"2023-08-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Early identification of process/system issues with gap closure plan",LOOKAHEAD:"NA"},
      {NO:64,EM_LEAD:"Mabry",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-08",LMS_DESCR:"Utilities Platform ready to start-up and operate Utilities facilities",PLAN:"2023-08-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Reviewed & Sanctioned (Si Yan) - F/C Oct-23",LOOKAHEAD:"NA"},
      {NO:65,EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-13",LMS_DESCR:"Product deployment plan  Corp Plan & S/U basis",PLAN:"2023-09-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented in Corp Plan",LOOKAHEAD:"NA"},
      {NO:66,EM_LEAD:"S. Shu",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-14",LMS_DESCR:"Polys raw matl supply plans in place / Catalyst & Product subn to PS&RA complete",PLAN:"2023-09-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Plans documented / in place",LOOKAHEAD:"NA"},
      {NO:67,EM_LEAD:"S. Shu",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-15",LMS_DESCR:"PE/PP product-family level deployment plans complete",PLAN:"2023-09-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Plans (by Area Port) documented / in place",LOOKAHEAD:"NA"},
      {NO:68,EM_LEAD:"Yu",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-18",LMS_DESCR:"Acquisition / Trading organization in-place (fuels)",PLAN:"2023-09-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Plans documented / in place",LOOKAHEAD:"NA"},
      {NO:69,PACKAGE:"P3",EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-39",LMS_DESCR:"Waste water treatment system MC (QY)",PLAN:"2023-09-01",FORECAST:"2023-09-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"WWT system turned over",LOOKAHEAD:"NA",YEARS:2023,KMS_CNT:3},
      {NO:70,EM_LEAD:"S. Shu",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-56",LMS_DESCR:"Align PE/PP go to market study outcome applied for China 1",PLAN:"2023-09-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Alignment documented",LOOKAHEAD:"NA"},
      {NO:71,PACKAGE:"P3",EM_LEAD:"Fuller",SEI_LEAD:"Niu",FUNCTIONS:"Infrastructure",LMS_NO:"INF-05",LMS_DESCR:"Main Sub Station MC",PLAN:"2023-09-01",FORECAST:"2023-09-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Main Sub-Station Turned Over",LOOKAHEAD:"NA",YEARS:2023,KMS_CNT:6},
      {NO:72,PACKAGE:"P3",EM_LEAD:"Fuller",SEI_LEAD:"NA",FUNCTIONS:"Infrastructure",LMS_NO:"INF-06",LMS_DESCR:"220kV external power source available (for Main Sub)",PLAN:"2023-09-01",FORECAST:"2023-09-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Supply available to use",LOOKAHEAD:"NA",YEARS:2023,KMS_CNT:8},
      {NO:73,EM_LEAD:"Gan",SEI_LEAD:"NA",FUNCTIONS:"Supply Chain",LMS_NO:"SUP-04",LMS_DESCR:"Network and Service Level Basis Complete",PLAN:"2023-09-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented in Corp Plan",LOOKAHEAD:"NA"},
      {NO:74,EM_LEAD:"M. Lo",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-17",LMS_DESCR:"Business Process End-to-end user readiness testing Cold Eyes Review",PLAN:"2023-10-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Any potential gaps identified and closures plan in place to close the gaps",LOOKAHEAD:"NA"},
      {NO:75,EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-20",LMS_DESCR:"PS&RA input for BC products",PLAN:"2023-10-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Plans documented / in place",LOOKAHEAD:"NA"},
      {NO:76,EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-21",LMS_DESCR:"Sign Grid power purchase agreement",PLAN:"2023-10-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Agreement documented / in place - For boiler plate pay whatever rate is",LOOKAHEAD:"NA"},
      {NO:77,PACKAGE:"P3",EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-40",LMS_DESCR:"Natural gas west link pipeline MC",PLAN:"2023-10-01",FORECAST:"2023-10-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"NG westlink pipeline turned over",LOOKAHEAD:"NA",YEARS:2023,KMS_CNT:1},
      {NO:78,EM_LEAD:"W. Zhang",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-41",LMS_DESCR:"Business Readiness Cold Eyes Review 2",PLAN:"2023-10-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Review completed with follow ups documented / planned",LOOKAHEAD:"NA"},
      {NO:79,EM_LEAD:"S. Shu",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-57",LMS_DESCR:"Hexene Supply & Logistic Readiness completed",PLAN:"2023-10-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Hexene supply demand planning and receiving logistics confirmed scheduling process and tools set up",LOOKAHEAD:"NA"},
      {NO:80,EM_LEAD:"S. Shu",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-58",LMS_DESCR:"Product/Material spec developed & uploaded to PDM",PLAN:"2023-10-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"PDS Mfg/Sales Spec RM spec PRM spec uploaded",LOOKAHEAD:"NA"},
      {NO:81,EM_LEAD:"S. Shu",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-59",LMS_DESCR:"Transfer price alignment (C2 C3 PE and PP products)",PLAN:"2023-10-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Process developed",LOOKAHEAD:"NA"},
      {NO:82,EM_LEAD:"HT. Yeo",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-62",LMS_DESCR:"Liquids transfer pricing alignment (olefins aromatics fuels)",PLAN:"2023-10-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented",LOOKAHEAD:"NA"},
      {NO:83,PACKAGE:"P3",EM_LEAD:"Letterle",SEI_LEAD:"Niu",FUNCTIONS:"Infrastructure",LMS_NO:"INF-12",LMS_DESCR:"Control Room Building MC / Turned Over",PLAN:"2023-10-01",FORECAST:"2023-10-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"MC and handed over",LOOKAHEAD:"NA",YEARS:2023,KMS_CNT:7},
      {NO:84,EM_LEAD:"Gan",SEI_LEAD:"NA",FUNCTIONS:"Supply Chain",LMS_NO:"SUP-05",LMS_DESCR:"3P logistics agreements in place (3P logistics)",PLAN:"2023-10-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Final Agreement Signed / Documented",LOOKAHEAD:"NA"},
      {NO:85,EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-42",LMS_DESCR:"GEG main plant MC",PLAN:"2023-11-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Main plant turned over",LOOKAHEAD:"NA"},
      {NO:86,PACKAGE:"P3",EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-43",LMS_DESCR:"Industrial water Purchase Agreement in Place",PLAN:"2023-11-01",FORECAST:"2023-11-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Ready to supply water",LOOKAHEAD:"NA",YEARS:2023,KMS_CNT:1},
      {NO:87,EM_LEAD:"T Skeik",SEI_LEAD:"NA",FUNCTIONS:"Completions",LMS_NO:"CMP-03",LMS_DESCR:"Main Substation Energized",PLAN:"2023-11-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Power on and ready for export",LOOKAHEAD:"NA"},
      {NO:88,PACKAGE:"P1",EM_LEAD:"Frye",SEI_LEAD:"Hou",FUNCTIONS:"Olefins",LMS_NO:"OLE-11",LMS_DESCR:"CGC Set In-Place",PLAN:"2023-11-01",FORECAST:"2023-11-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"CGC on table top",LOOKAHEAD:"NA",YEARS:2023,KMS_CNT:6},
      {NO:89,PACKAGE:"P3",EM_LEAD:"T Skeik",SEI_LEAD:"NA",FUNCTIONS:"Completions",LMS_NO:"CMP-11",LMS_DESCR:"Industrial Water / Fire Water Available",PLAN:"2023-12-01",FORECAST:"2023-12-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"IW/FW facilities complete / tested / commissioned and ready for service",LOOKAHEAD:"NA",YEARS:2023,KMS_CNT:7},
      {NO:90,EM_LEAD:"M. Lo",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-22",LMS_DESCR:"Solids End-to-end URT complete",PLAN:"2024-01-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Identification of any process/system issues and gap closure in place",LOOKAHEAD:"NA"},
      {NO:91,EM_LEAD:"S. Shu",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-23",LMS_DESCR:"Cold pack test plan complete  Proposed to delete and combine with SUP-03",PLAN:"2024-01-01",STATUS:"To Delete",DEFINITION_OF_SUCCESS:"Signed off and documented",LOOKAHEAD:"NA"},
      {NO:92,EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-25",LMS_DESCR:"BC and Fuels Resource Plan Complete",PLAN:"2024-01-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Signed off and documented",LOOKAHEAD:"NA"},
      {NO:93,EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-26",LMS_DESCR:"BC and Fuels Product testing plan (FET)",PLAN:"2024-01-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Signed off and documented",LOOKAHEAD:"NA"},
      {NO:94,PACKAGE:"P3",EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-44",LMS_DESCR:"N2 RFU",PLAN:"2024-01-01",FORECAST:"2024-01-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Ready to supply N2",LOOKAHEAD:"NA",YEARS:2024,KMS_CNT:9},
      {NO:95,EM_LEAD:"M. Lo",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-45",LMS_DESCR:"Interaffiliate agreements finalized",PLAN:"2024-01-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Agreement signed / documented",LOOKAHEAD:"NA"},
      {NO:96,PACKAGE:"P3",EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-47",LMS_DESCR:"Town gas RFU",PLAN:"2024-01-01",FORECAST:"2024-01-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Ready to supply towngas",LOOKAHEAD:"NA",YEARS:2024,KMS_CNT:4},
      {NO:97,EM_LEAD:"T Skeik",SEI_LEAD:"NA",FUNCTIONS:"Completions",LMS_NO:"CMP-02",LMS_DESCR:"CCR Ready to commence loop checking",PLAN:"2024-01-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"CCR handed over to Commissioning to support start of loop testing (ESG required)",LOOKAHEAD:"NA"},
      {NO:98,PACKAGE:"P3",EM_LEAD:"Fuller",SEI_LEAD:"Niu",FUNCTIONS:"Infrastructure",LMS_NO:"INF-09",LMS_DESCR:"Admin complex ready for occupation by manufacturing",PLAN:"2024-01-01",FORECAST:"2024-01-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Complex Handed Over",LOOKAHEAD:"NA",YEARS:2024,KMS_CNT:9},
      {NO:99,PACKAGE:"P3",EM_LEAD:"Fuller",SEI_LEAD:"Niu",FUNCTIONS:"Infrastructure",LMS_NO:"INF-10",LMS_DESCR:"Air System MC (PA/IA)",PLAN:"2024-01-01",FORECAST:"2024-01-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"System Turned Over",LOOKAHEAD:"NA",YEARS:2024,KMS_CNT:6},
      {NO:100,PACKAGE:"OP",EM_LEAD:"Fuller",SEI_LEAD:"NA",FUNCTIONS:"Infrastructure",LMS_NO:"INF-18",LMS_DESCR:"Liquid Jetty MC",PLAN:"2024-01-01",FORECAST:"2024-01-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Ready for Certification - Domestic use June-24",LOOKAHEAD:"NA",YEARS:2024,KMS_CNT:10},
      {NO:101,EM_LEAD:"S. Shu",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-24",LMS_DESCR:"Business transition plan for ASRS warehouse early operation completed",PLAN:"2024-02-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Signed off and documented",LOOKAHEAD:"NA"},
      {NO:102,PACKAGE:"OP",EM_LEAD:"Fuller",SEI_LEAD:"NA",FUNCTIONS:"Infrastructure",LMS_NO:"INF-19",LMS_DESCR:"Liquid Jetty MC",PLAN:"2024-02-01",FORECAST:"2024-02-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Ready for Certification - Domestic use July-24",LOOKAHEAD:"NA",YEARS:2024,KMS_CNT:12},
      {NO:103,EM_LEAD:"Yu",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-46",LMS_DESCR:"Feed purchase agreement in place",PLAN:"2024-03-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Agreement signed / documented",LOOKAHEAD:"NA"},
      {NO:104,PACKAGE:"P3",EM_LEAD:"T Skeik",SEI_LEAD:"Niu",FUNCTIONS:"Completions",LMS_NO:"INF-13",LMS_DESCR:"Waste Water Treatment system RFSU",PLAN:"2024-03-01",FORECAST:"2024-03-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"QY and on-plot facs ready - Ready for CWS pre-com SEI MC Jan 2024",LOOKAHEAD:"NA",YEARS:2024,KMS_CNT:14},
      {NO:105,EM_LEAD:"Mabry",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-09",LMS_DESCR:"FO Platform ready to start-up and operate Fuels and Offsites facilities",PLAN:"2024-03-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Reviewed & Sanctioned (Si Dong)",LOOKAHEAD:"NA"},
      {NO:106,EM_LEAD:"Neo",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-14",LMS_DESCR:"External interfaces (govt 3rd party) established and ready for start-up",PLAN:"2024-03-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Reviewed & Documented",LOOKAHEAD:"NA"},
      {NO:107,PACKAGE:"P1",EM_LEAD:"Frye",SEI_LEAD:"Hou",FUNCTIONS:"Olefins",LMS_NO:"OLE-12",LMS_DESCR:"Co-Products Major equip. Setting",PLAN:"2024-03-01",FORECAST:"2024-03-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"All Co-Prod. Equipment set in place",LOOKAHEAD:"NA",YEARS:2024,KMS_CNT:5},
      {NO:108,PACKAGE:"P1",EM_LEAD:"Frye",SEI_LEAD:"NA",FUNCTIONS:"Olefins",LMS_NO:"OLE-16",LMS_DESCR:"GEG steam available at site",PLAN:"2024-03-01",FORECAST:"2024-03-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Commissioned - Ready for steam blows",LOOKAHEAD:"NA",YEARS:2024,KMS_CNT:3},
      {NO:109,PACKAGE:"P2",EM_LEAD:"Letterle",SEI_LEAD:"Ruan",FUNCTIONS:"Polymers",LMS_NO:"POL-04",LMS_DESCR:"W/H & Packaging facilities MC Ready for Commissioning",PLAN:"2024-03-01",FORECAST:"2024-03-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Applicable subsystems complete",LOOKAHEAD:"NA",YEARS:2024,KMS_CNT:22},
      {NO:110,PACKAGE:"P3",EM_LEAD:"T Skeik",SEI_LEAD:"NA",FUNCTIONS:"Completions",LMS_NO:"CMP-04",LMS_DESCR:"Major utilities commissioned (support Boilers)",PLAN:"2024-04-01",FORECAST:"2024-04-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"All eqpt commissioned to provide streams for steam generation",LOOKAHEAD:"NA",YEARS:2024,KMS_CNT:3},
      {NO:111,PACKAGE:"P1",EM_LEAD:"Frye",SEI_LEAD:"Hou",FUNCTIONS:"Olefins",LMS_NO:"OLE-13",LMS_DESCR:"Last Boiler Mechanical Completion",PLAN:"2024-04-01",FORECAST:"2024-04-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"All Boiler components turned over to owner",LOOKAHEAD:"NA",YEARS:2024,KMS_CNT:5},
      {NO:112,EM_LEAD:"M. Lo",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-28",LMS_DESCR:"Business Systems Go Live (Migration to production)",PLAN:"2024-05-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Successful execution of  end-to-end commercial transactions",LOOKAHEAD:"NA"},
      {NO:113,PACKAGE:"P3",EM_LEAD:"T Skeik",SEI_LEAD:"Niu",FUNCTIONS:"Completions",LMS_NO:"INF-16",LMS_DESCR:"Elevated Flare system RFSU",PLAN:"2024-05-01",FORECAST:"2024-05-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Flare system ready to receive H/C SEI MC Elevated Mar-24 MC",LOOKAHEAD:"NA",YEARS:2024,KMS_CNT:1},
      {NO:114,PACKAGE:"OP",EM_LEAD:"Gan",SEI_LEAD:"NA",FUNCTIONS:"Supply Chain",LMS_NO:"SUP-07",LMS_DESCR:"Shipping Logistics Contracts/Process in place",PLAN:"2024-05-01",FORECAST:"2024-05-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Final Agreement Signed / Documented",LOOKAHEAD:"NA",YEARS:2024,KMS_CNT:1},
      {NO:115,EM_LEAD:"T Skeik",SEI_LEAD:"NA",FUNCTIONS:"Completions",LMS_NO:"CMP-06",LMS_DESCR:"1st Boiler Start Up",PLAN:"2024-06-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"First boiler burner lit for refractory dry-out",LOOKAHEAD:"NA",KMS_CNT:4},
      {NO:116,EM_LEAD:"T Skeik",SEI_LEAD:"NA",FUNCTIONS:"Completions",LMS_NO:"CMP-07",LMS_DESCR:"Begin critical path steam blowing campaign (Incl. Olefins)",PLAN:"2024-06-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"First steam blow path started",LOOKAHEAD:"NA"},
      {NO:117,PACKAGE:"P3",EM_LEAD:"T Skeik",SEI_LEAD:"NA",FUNCTIONS:"Completions",LMS_NO:"CMP-13",LMS_DESCR:"Cooling Water System in Service for Utilities & Olefins",PLAN:"2024-06-01",FORECAST:"2024-06-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"CWS commissioned and ready for use in Utilities/Olefins units",LOOKAHEAD:"NA",YEARS:2024,KMS_CNT:9},
      {NO:118,PACKAGE:"P2",EM_LEAD:"Letterle",SEI_LEAD:"Ruan",FUNCTIONS:"Polymers",LMS_NO:"POL-05",LMS_DESCR:"Polymers Finishing Units 1st Mechanical Completion",PLAN:"2024-06-01",FORECAST:"2024-06-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"First priority Finishing Lines MC",LOOKAHEAD:"NA",YEARS:2024,KMS_CNT:10},
      {NO:119,EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-30",LMS_DESCR:"Commercial contingy playbook  Intern. comms/on-boarding complete",PLAN:"2024-07-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented",LOOKAHEAD:"NA"},
      {NO:120,EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-63",LMS_DESCR:"Ethylene/Propylene Pipelines Ready for use (CSPC)",PLAN:"2024-07-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"C2/C3 lines MC and Ready for use",LOOKAHEAD:"NA"},
      {NO:121,PACKAGE:"P3",EM_LEAD:"T Skeik",SEI_LEAD:"NA",FUNCTIONS:"Completions",LMS_NO:"CMP-14",LMS_DESCR:"Cooling Water System in Service for Polymers & Co-Prod",PLAN:"2024-07-01",FORECAST:"2024-07-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"CWS commissioned and ready for use in Polymers & Co-Products units",LOOKAHEAD:"NA",YEARS:2024,KMS_CNT:9},
      {NO:122,EM_LEAD:"Neo",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-15",LMS_DESCR:"Stand-up Phase 2 Complete",PLAN:"2024-07-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Reviewed & Sanctioned",LOOKAHEAD:"NA"},
      {NO:123,EM_LEAD:"Mabry",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-16",LMS_DESCR:"GPPE Platform ready to start-up and operate GPPE and Packaging facilities",PLAN:"2024-07-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Reviewed & Sanctioned (Vipin)",LOOKAHEAD:"NA"},
      {NO:124,EM_LEAD:"Mabry",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-16A",LMS_DESCR:"PP/LDPE Platform ready to start-up and operate PP and LDPE facilities",PLAN:"2024-07-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Reviewed & Sanctioned (Frank)",LOOKAHEAD:"NA"},
      {NO:125,PACKAGE:"P2",EM_LEAD:"Letterle",SEI_LEAD:"Ruan",FUNCTIONS:"Polymers",LMS_NO:"POL-06",LMS_DESCR:"Polymers Poly Units 1st Mechanical Completion",PLAN:"2024-07-01",FORECAST:"2024-07-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"First priority Poly Units MC",LOOKAHEAD:"NA",YEARS:2024,KMS_CNT:20},
      {NO:126,EM_LEAD:"Gan",SEI_LEAD:"NA",FUNCTIONS:"Supply Chain",LMS_NO:"SUP-06",LMS_DESCR:"SC integration and SC readiness validated",PLAN:"2024-07-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Ready for all liquids / polymer / RM logistics activities Facilities commissioned & ready for all logistic activities including RM / system & work processes operate per design / personnel in place & trained",LOOKAHEAD:"NA"},
      {NO:127,EM_LEAD:"S. Shu",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-29",LMS_DESCR:"Customer level deployment plan including comms and product qualn plan",PLAN:"2024-08-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented",LOOKAHEAD:"NA"},
      {NO:128,EM_LEAD:"S. Shu",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-31",LMS_DESCR:"Commercial & Logistics contingency playbook complete",PLAN:"2024-08-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented",LOOKAHEAD:"NA"},
      {NO:129,EM_LEAD:"T Skeik",SEI_LEAD:"NA",FUNCTIONS:"Completions",LMS_NO:"CMP-12",LMS_DESCR:"OR Steam blow complete for CGC Air Run",PLAN:"2024-08-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"All required for CGC air run steam blow completed - To Be Advised",LOOKAHEAD:"NA",KMS_CNT:1},
      {NO:130,PACKAGE:"P3",EM_LEAD:"T Skeik",SEI_LEAD:"Niu",FUNCTIONS:"Completions",LMS_NO:"INF-16A",LMS_DESCR:"Ground Flare system RFSU",PLAN:"2024-08-01",FORECAST:"2024-08-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Flare system ready to receive H/C SEI MC May 2024",LOOKAHEAD:"NA",YEARS:2024,KMS_CNT:1},
      {NO:131,EM_LEAD:"Mabry",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-18",LMS_DESCR:"Olefins Platform ready to start-up and operate Olefins and Co-products facilities",PLAN:"2024-08-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Reviewed & Sanctioned (Chu Jiang)",LOOKAHEAD:"NA"},
      {NO:132,PACKAGE:"P1",EM_LEAD:"Frye",SEI_LEAD:"Hou",FUNCTIONS:"Olefins",LMS_NO:"OLE-14",LMS_DESCR:"Compressor System Mechanical Completion",PLAN:"2024-08-01",FORECAST:"2024-08-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"All CGC components turned over to owner",LOOKAHEAD:"NA",YEARS:2024,KMS_CNT:3},
      {NO:133,PACKAGE:"P2",EM_LEAD:"Letterle",SEI_LEAD:"Ruan",FUNCTIONS:"Polymers",LMS_NO:"POL-07",LMS_DESCR:"Polymers Facilities Final Mechanical Completion",PLAN:"2024-08-01",FORECAST:"2024-08-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"All Polymers units turned over to owner",LOOKAHEAD:"NA",YEARS:2024,KMS_CNT:11},
      {NO:134,EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-48",LMS_DESCR:"Power purchase agreement (renewables or other alternatives)",PLAN:"2024-09-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Agreement documented / in place",LOOKAHEAD:"NA"},
      {NO:135,EM_LEAD:"L Fung",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-49",LMS_DESCR:"Naphtha import license/quota obtained",PLAN:"2024-09-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented",LOOKAHEAD:"NA"},
      {NO:136,EM_LEAD:"M. Lo",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-60",LMS_DESCR:"Full Systems Go Live",PLAN:"2024-09-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Successful execution of all transactions",LOOKAHEAD:"NA"},
      {NO:137,PACKAGE:"OP",EM_LEAD:"Fuller",SEI_LEAD:"NA",FUNCTIONS:"Infrastructure",LMS_NO:"INF-17",LMS_DESCR:"Naphtha Discharge Facilities / Pipeline RFSU",PLAN:"2024-09-01",FORECAST:"2024-09-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Facilities ready to supply/receive product",LOOKAHEAD:"NA",YEARS:2024,KMS_CNT:13},
      {NO:138,EM_LEAD:"Mabry",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-17",LMS_DESCR:"Ethylene/Propylene available to Polymers for pre-treatment",PLAN:"2024-09-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"C2/C3 facilities sanctioned to supply product (Asplin)",LOOKAHEAD:"NA"},
      {NO:139,PACKAGE:"P1",EM_LEAD:"Frye",SEI_LEAD:"Hou",FUNCTIONS:"Olefins",LMS_NO:"OLE-15",LMS_DESCR:"BD Mechanical Completion",PLAN:"2024-09-01",FORECAST:"2024-09-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"All BD unit components turned over to owner",LOOKAHEAD:"NA",YEARS:2024,KMS_CNT:6},
      {NO:140,PACKAGE:"OP",EM_LEAD:"A. Ai",SEI_LEAD:"NA",FUNCTIONS:"Supply Chain",LMS_NO:"INF-15",LMS_DESCR:"Liquid Terminal / Liquid Jetty ready for material receipt",PLAN:"2024-09-01",FORECAST:"2024-09-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Certified for use (MC Jan-24) Dom June-24  1st Hexene shipment in Sep-24",LOOKAHEAD:"NA",YEARS:2024,KMS_CNT:1},
      {NO:141,EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-32",LMS_DESCR:"Liquids Command Center go-live",PLAN:"2024-10-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented",LOOKAHEAD:"NA"},
      {NO:142,EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-50",LMS_DESCR:"H2 tube trailer RFU",PLAN:"2024-10-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Ready to supply H2",LOOKAHEAD:"NA"},
      {NO:143,EM_LEAD:"S. Shu",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-61",LMS_DESCR:"Regulatory data sheet completed (SDS SEUS)",PLAN:"2024-10-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Upload completed",LOOKAHEAD:"NA"},
      {NO:144,EM_LEAD:"M.Ren",SEI_LEAD:"NA",FUNCTIONS:"Commercial",LMS_NO:"CML-07",LMS_DESCR:"Alignment with government for tax incentive application",PLAN:"2024-10-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented",LOOKAHEAD:"NA"},
      {NO:145,EM_LEAD:"T Skeik",SEI_LEAD:"NA",FUNCTIONS:"Completions",LMS_NO:"CMP-08",LMS_DESCR:"Finishing Facilities 1st RFSU",PLAN:"2024-10-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Finishing lines ready to test / prove out with granules for 3 extruders",LOOKAHEAD:"NA"},
      {NO:146,EM_LEAD:"T Skeik",SEI_LEAD:"NA",FUNCTIONS:"Completions",LMS_NO:"CMP-09",LMS_DESCR:"(CGC) Run In & Commence Air Blowing and/or Dry Out",PLAN:"2024-10-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"CGC commissioned in service ready for air blows (if reqd)",LOOKAHEAD:"NA",KMS_CNT:5},
      {NO:147,PACKAGE:"P2",EM_LEAD:"Mabry",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-19",LMS_DESCR:"Warehouse and 1st set of packaging lines proved out",PLAN:"2024-10-01",FORECAST:"2024-10-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Warehouse/packaging lines ready to receive product (3/5) (Vipin)",LOOKAHEAD:"NA",YEARS:2024,KMS_CNT:6},
      {NO:148,PACKAGE:"P1",EM_LEAD:"Frye",SEI_LEAD:"Hou",FUNCTIONS:"Olefins",LMS_NO:"OLE-17",LMS_DESCR:"Last Olefins system (incl remaining Co-Products) Mechanical Completion",PLAN:"2024-10-01",FORECAST:"2024-10-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"All Olefins units turned over to owner",LOOKAHEAD:"NA",YEARS:2024,KMS_CNT:7},
      {NO:149,EM_LEAD:"HT. Yeo",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-33",LMS_DESCR:"Mogas/FO export license and quota obtained",PLAN:"2024-11-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented",LOOKAHEAD:"NA"},
      {NO:150,EM_LEAD:"S. Shu",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-34",LMS_DESCR:"Polymers Command Center Go Live",PLAN:"2024-11-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented",LOOKAHEAD:"NA"},
      {NO:151,EM_LEAD:"Mabry",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-17A",LMS_DESCR:"Ethylene/Propylene available to Polymers for start-up",PLAN:"2024-11-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"C2/C3 facilities sanctioned to supply product  (Asplin)",LOOKAHEAD:"NA"},
      {NO:152,EM_LEAD:"Mabry",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-25",LMS_DESCR:"Polymer Units 1st Line SU (1st Pellets)",PLAN:"2024-11-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"1st Polymer Line Running (1st Pellets) (Vipin)",LOOKAHEAD:"NA"},
      {NO:153,EM_LEAD:"S. Shu",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-35",LMS_DESCR:"Polymers Business Readiness - ready to start transition to base org.",PLAN:"2024-12-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented",LOOKAHEAD:"NA"},
      {NO:154,EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-36",LMS_DESCR:"Basic Chemicals / F&L Business RFSU",PLAN:"2024-12-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented",LOOKAHEAD:"NA"},
      {NO:155,EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-51",LMS_DESCR:"Co-product sales agreement in place",PLAN:"2024-12-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Agreement signed / documented",LOOKAHEAD:"NA"},
      {NO:156,EM_LEAD:"HT. Yeo",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-52",LMS_DESCR:"Fuels product sales agreement in place",PLAN:"2024-12-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Agreement signed / documented",LOOKAHEAD:"NA"},
      {NO:157,EM_LEAD:"Mabry",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-21",LMS_DESCR:"Polymer Units (3 of 5) Proved Out for Cracker",PLAN:"2024-12-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"3 trains and lines ready for full rates from Cracker (2xPE/1xPP)  (Vipin)",LOOKAHEAD:"NA",KMS_CNT:1},
      {NO:158,PACKAGE:"OP",EM_LEAD:"A. Ai",SEI_LEAD:"NA",FUNCTIONS:"Supply Chain",LMS_NO:"SUP-08",LMS_DESCR:"First Naphtha ships discharged safely",PLAN:"2024-12-01",FORECAST:"2024-12-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented",LOOKAHEAD:"NA",YEARS:2024,KMS_CNT:1},
      {NO:159,EM_LEAD:"J. Ng",SEI_LEAD:"NA",FUNCTIONS:"Supply Chain",LMS_NO:"SUP-10",LMS_DESCR:"E2E Testing Complete (Incl. ASRS & Outbound)",PLAN:"2024-12-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Sanctioned & verified (Vipin / Siang Jyh)",LOOKAHEAD:"NA"},
      {NO:160,EM_LEAD:"T Skeik",SEI_LEAD:"NA",FUNCTIONS:"Completions",LMS_NO:"CMP-10",LMS_DESCR:"All cracker and required co-product systems RFSU",PLAN:"2025-01-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Cracker & co-products units commissioned and sanctioned for Oil In",LOOKAHEAD:"NA",KMS_CNT:12},
      {NO:161,EM_LEAD:"Mabry",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-22",LMS_DESCR:"Polymers (Plants & Logistics) (5 of 5) Proved Out for Cracker",PLAN:"2025-01-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"All trains and lines ready for full rates from Cracker  (Vipin)",LOOKAHEAD:"NA"},
      {NO:162,EM_LEAD:"Mabry",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-23",LMS_DESCR:"Cracker Started Up & Declared On Spec",PLAN:"2025-01-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Sanctioned & verified (Chu Jiang)",LOOKAHEAD:"NA",KMS_CNT:7},
      {NO:163,EM_LEAD:"Mabry",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-24",LMS_DESCR:"Facilities Performance Tests Achieved",PLAN:"2025-07-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented & verified (Wendy)",LOOKAHEAD:"NA"},
      {NO:164,EM_LEAD:"L Fung",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-38",LMS_DESCR:"LPG service agreement in place (Move to after On-Spec)",PLAN:"2025-03-24",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Agreement signed / documented",LOOKAHEAD:"NA"}
      ]
  },
  DataItems:  [
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-34",PLAN:"2024-12-01",FORECAST:"2024-11-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-44",PLAN:"2024-01-01",FORECAST:"2024-01-01",FUNCTIONS:"Business",PACKAGE:"P3",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-48",PLAN:"2024-09-01",FORECAST:"2024-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-49",PLAN:"2024-09-01",FORECAST:"2024-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"L Fung",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-60",PLAN:"2024-09-01",FORECAST:"2024-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"M. Lo",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"CON-01",PLAN:"2021-12-01",FORECAST:"2021-12-01",FUNCTIONS:"Construction",PACKAGE:"P1",EM_LEAD:"ODonnell",LOOKAHEAD:"NA",YEARS:2021},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"ENG-05",PLAN:"2022-01-01",FORECAST:"2022-01-01",FUNCTIONS:"Engineering",PACKAGE:"P1",EM_LEAD:"Ang",LOOKAHEAD:"NA",YEARS:2022},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"ENG-14",PLAN:"2023-05-01",FORECAST:"2023-06-01",FUNCTIONS:"Engineering",PACKAGE:"NA",EM_LEAD:"Ang",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-12",PLAN:"2023-10-01",FORECAST:"2023-10-01",FUNCTIONS:"Infrastructure",PACKAGE:"P3",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-16",PLAN:"2024-11-01",FORECAST:"2024-09-01",FUNCTIONS:"Completions",PACKAGE:"P3",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-09",PLAN:"2023-12-01",FORECAST:"2023-12-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-23",PLAN:"2025-04-01",FORECAST:"2025-02-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"OLE-03",PLAN:"2022-01-01",FORECAST:"2022-01-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Webb",LOOKAHEAD:"NA",YEARS:2022},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-06",PLAN:"2023-01-01",FORECAST:"2023-01-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"NA",YEARS:2023},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-07",PLAN:"2023-03-01",FORECAST:"2023-01-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"90D",YEARS:2023},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"POL-07",PLAN:"2024-09-01",FORECAST:"2024-09-01",FUNCTIONS:"Polymers",PACKAGE:"NA",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"POL-11",PLAN:"2023-07-01",FORECAST:"2023-07-01",FUNCTIONS:"Polymers",PACKAGE:"NA",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"SUP-02",PLAN:"2022-07-01",FORECAST:"2022-04-01",FUNCTIONS:"Supply Chain",PACKAGE:"NA",EM_LEAD:"Gan",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"SUP-04",PLAN:"2023-09-01",FORECAST:"2023-09-01",FUNCTIONS:"Supply Chain",PACKAGE:"NA",EM_LEAD:"Gan",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"SUP-06",PLAN:"2024-01-01",FORECAST:"2024-07-01",FUNCTIONS:"Supply Chain",PACKAGE:"NA",EM_LEAD:"Gan",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"BUS-02",PLAN:"2021-09-01",FORECAST:"2021-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Batey",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"BUS-03",PLAN:"2021-11-01",FORECAST:"2021-11-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Stein",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-12",PLAN:"2023-08-01",FORECAST:"2023-06-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Yu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-13",PLAN:"2023-09-01",FORECAST:"2023-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-17",PLAN:"2023-10-01",FORECAST:"2023-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"M. Lo",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-20",PLAN:"2023-12-01",FORECAST:"2023-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-34",PLAN:"2024-12-01",FORECAST:"2024-11-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-43",PLAN:"2023-11-01",FORECAST:"2023-11-01",FUNCTIONS:"Business",PACKAGE:"P3",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"CML-01",PLAN:"2021-09-01",FORECAST:"2021-09-01",FUNCTIONS:"Commercial",PACKAGE:"NA",EM_LEAD:"XJ Li",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"ENG-09",PLAN:"2022-12-01",FORECAST:"2022-12-01",FUNCTIONS:"Engineering",PACKAGE:"P1",EM_LEAD:"Ang",LOOKAHEAD:"NA",YEARS:2022},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"ENG-11",PLAN:"2023-01-01",FORECAST:"2022-11-01",FUNCTIONS:"Engineering",PACKAGE:"P1",EM_LEAD:"Ang",LOOKAHEAD:"NA",YEARS:2023},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-02",PLAN:"2022-11-01",FORECAST:"2022-11-01",FUNCTIONS:"Infrastructure",PACKAGE:"OP",EM_LEAD:"Atwood",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-13",PLAN:"2024-03-01",FORECAST:"2024-03-01",FUNCTIONS:"Completions",PACKAGE:"P3",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-07",PLAN:"2023-03-01",FORECAST:"2023-01-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"90D",YEARS:2023},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-11",PLAN:"2023-11-01",FORECAST:"2023-11-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"NA",YEARS:2023},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-10",PLAN:"2023-07-01",FORECAST:"2023-07-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"M. Lo",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-31",PLAN:"2024-09-01",FORECAST:"2024-08-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-33",PLAN:"2024-11-01",FORECAST:"2024-11-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"HT. Yeo",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-45",PLAN:"2024-01-01",FORECAST:"2024-01-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"M. Lo",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-53",PLAN:"2022-10-01",FORECAST:"2022-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CML-07",PLAN:"2024-12-01",FORECAST:"2024-10-01",FUNCTIONS:"Commercial",PACKAGE:"NA",EM_LEAD:"M.Ren",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-02",PLAN:"2023-11-01",FORECAST:"2023-11-01",FUNCTIONS:"Completions",PACKAGE:"NA",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-04",PLAN:"2024-07-01",FORECAST:"2024-05-01",FUNCTIONS:"Completions",PACKAGE:"P3",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-12",PLAN:"2024-09-01",FORECAST:"2024-09-01",FUNCTIONS:"Completions",PACKAGE:"NA",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-14",PLAN:"2024-08-01",FORECAST:"2024-08-01",FUNCTIONS:"Completions",PACKAGE:"P3",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CON-03",PLAN:"2022-12-01",FORECAST:"2022-12-01",FUNCTIONS:"Construction",PACKAGE:"P1",EM_LEAD:"Grubbs",LOOKAHEAD:"NA",YEARS:2022},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"ENG-03",PLAN:"2022-01-01",FORECAST:"2022-01-01",FUNCTIONS:"Engineering",PACKAGE:"NA",EM_LEAD:"Ang",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-03",PLAN:"2023-04-01",FORECAST:"2023-05-01",FUNCTIONS:"Infrastructure",PACKAGE:"P3",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-17",PLAN:"2024-11-01",FORECAST:"2024-09-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-12",PLAN:"2024-03-01",FORECAST:"2024-03-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"NA",YEARS:2024},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-16",PLAN:"2024-08-01",FORECAST:"2024-03-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"NA",YEARS:2024},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"POL-06",PLAN:"2024-08-01",FORECAST:"2024-08-01",FUNCTIONS:"Polymers",PACKAGE:"NA",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"POL-10",PLAN:"2023-04-01",FORECAST:"2023-04-01",FUNCTIONS:"Polymers",PACKAGE:"NA",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"SUP-10",PLAN:"2025-02-01",FORECAST:"2024-12-01",FUNCTIONS:"Supply Chain",PACKAGE:"NA",EM_LEAD:"J. Ng",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"BUS-01",PLAN:"2021-09-01",FORECAST:"2021-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-09",PLAN:"2023-01-01",FORECAST:"2023-03-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"W. Zhang",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-31",PLAN:"2024-09-01",FORECAST:"2024-08-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-03",PLAN:"2023-11-01",FORECAST:"2023-11-01",FUNCTIONS:"Completions",PACKAGE:"NA",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"ENG-07",PLAN:"2022-10-01",FORECAST:"2022-10-01",FUNCTIONS:"Engineering",PACKAGE:"P1",EM_LEAD:"Ang",LOOKAHEAD:"NA",YEARS:2022},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-03",PLAN:"2023-04-01",FORECAST:"2023-05-01",FUNCTIONS:"Infrastructure",PACKAGE:"P3",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-17",PLAN:"2024-11-01",FORECAST:"2024-09-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-17A",PLAN:"2024-11-01",FORECAST:"2024-11-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-25",PLAN:"2025-03-01",FORECAST:"2024-12-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"POL-10",PLAN:"2023-04-01",FORECAST:"2023-04-01",FUNCTIONS:"Polymers",PACKAGE:"NA",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"SUP-05",PLAN:"2023-10-01",FORECAST:"2023-10-01",FUNCTIONS:"Supply Chain",PACKAGE:"NA",EM_LEAD:"Gan",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"BUS-04",PLAN:"2021-11-01",FORECAST:"2021-11-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Stein",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"BUS-08",PLAN:"2022-08-01",FORECAST:"2022-08-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"M. Lo",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-14",PLAN:"2023-09-01",FORECAST:"2023-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-38",PLAN:"2023-07-01",FORECAST:"2023-07-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"L Fung",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-43",PLAN:"2023-11-01",FORECAST:"2023-11-01",FUNCTIONS:"Business",PACKAGE:"P3",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-46",PLAN:"2024-03-01",FORECAST:"2024-03-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Yu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-51",PLAN:"2024-12-01",FORECAST:"2024-12-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-58",PLAN:"2023-10-01",FORECAST:"2023-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-61",PLAN:"2024-10-01",FORECAST:"2024-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-07",PLAN:"2024-09-01",FORECAST:"2024-06-01",FUNCTIONS:"Completions",PACKAGE:"NA",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-11",PLAN:"2023-12-01",FORECAST:"2023-12-01",FUNCTIONS:"Completions",PACKAGE:"P3",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-13",PLAN:"2024-06-01",FORECAST:"2024-06-01",FUNCTIONS:"Completions",PACKAGE:"P3",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"ENG-01",PLAN:"2021-11-01",FORECAST:"2021-11-01",FUNCTIONS:"Engineering",PACKAGE:"NA",EM_LEAD:"Ang",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-05",PLAN:"2023-09-01",FORECAST:"2023-09-01",FUNCTIONS:"Infrastructure",PACKAGE:"P3",EM_LEAD:"Fuller",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-19",PLAN:"2024-09-01",FORECAST:"2024-02-01",FUNCTIONS:"Infrastructure",PACKAGE:"OP",EM_LEAD:"Fuller",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"MFG-02",PLAN:"2022-04-01",FORECAST:"2022-04-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Emmen",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-14",PLAN:"2024-05-01",FORECAST:"2024-03-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Neo",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-22",PLAN:"2025-04-01",FORECAST:"2025-01-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-24",PLAN:"2025-06-01",FORECAST:"2025-07-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-05",PLAN:"2022-12-01",FORECAST:"2022-12-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"NA",YEARS:2022},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-08",PLAN:"2023-03-01",FORECAST:"2023-01-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"90D",YEARS:2023},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"POL-04",PLAN:"2024-03-01",FORECAST:"2024-03-01",FUNCTIONS:"Polymers",PACKAGE:"NA",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"POL-08",PLAN:"2023-07-01",FORECAST:"2023-07-01",FUNCTIONS:"Polymers",PACKAGE:"NA",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"BUS-07",PLAN:"2022-01-01",FORECAST:"2022-01-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"M. Lo",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-26",PLAN:"2024-03-01",FORECAST:"2024-01-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"BUS-37",PLAN:"2022-06-01",FORECAST:"2022-06-01",FUNCTIONS:"Business",PACKAGE:"P3",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-48",PLAN:"2024-09-01",FORECAST:"2024-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-55",PLAN:"2023-06-01",FORECAST:"2023-06-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-63",PLAN:"2024-11-01",FORECAST:"2024-07-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"CML-04",PLAN:"2021-09-01",FORECAST:"2021-09-01",FUNCTIONS:"Commercial",PACKAGE:"NA",EM_LEAD:"K. Lin",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-04",PLAN:"2024-07-01",FORECAST:"2024-04-01",FUNCTIONS:"Completions",PACKAGE:"P3",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-14",PLAN:"2024-07-01",FORECAST:"2024-07-01",FUNCTIONS:"Completions",PACKAGE:"P3",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"CON-01",PLAN:"2021-12-01",FORECAST:"2021-12-01",FUNCTIONS:"Construction",PACKAGE:"P1",EM_LEAD:"ODonnell",LOOKAHEAD:"NA",YEARS:2021},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"ENG-05",PLAN:"2022-01-01",FORECAST:"2022-01-01",FUNCTIONS:"Engineering",PACKAGE:"P1",EM_LEAD:"Ang",LOOKAHEAD:"NA",YEARS:2022},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"ENG-14",PLAN:"2023-05-01",FORECAST:"2023-06-01",FUNCTIONS:"Engineering",PACKAGE:"NA",EM_LEAD:"Ang",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-05",PLAN:"2023-10-01",FORECAST:"2023-07-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Neo",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-09",PLAN:"2023-12-01",FORECAST:"2023-12-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-23",PLAN:"2025-04-01",FORECAST:"2025-01-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"OLE-03",PLAN:"2022-01-01",FORECAST:"2022-01-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Webb",LOOKAHEAD:"NA",YEARS:2022},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-06",PLAN:"2023-01-01",FORECAST:"2023-01-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"NA",YEARS:2023},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"POL-07",PLAN:"2024-09-01",FORECAST:"2024-08-01",FUNCTIONS:"Polymers",PACKAGE:"NA",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"POL-11",PLAN:"2023-07-01",FORECAST:"2023-07-01",FUNCTIONS:"Polymers",PACKAGE:"NA",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-31",PLAN:"2024-09-01",FORECAST:"2024-08-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-45",PLAN:"2024-01-01",FORECAST:"2024-01-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"M. Lo",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CML-07",PLAN:"2024-12-01",FORECAST:"2024-10-01",FUNCTIONS:"Commercial",PACKAGE:"NA",EM_LEAD:"M.Ren",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-03",PLAN:"2023-11-01",FORECAST:"2023-11-01",FUNCTIONS:"Completions",PACKAGE:"NA",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CON-03",PLAN:"2022-12-01",FORECAST:"2022-12-01",FUNCTIONS:"Construction",PACKAGE:"P1",EM_LEAD:"Grubbs",LOOKAHEAD:"NA",YEARS:2022},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-03",PLAN:"2023-04-01",FORECAST:"2023-05-01",FUNCTIONS:"Infrastructure",PACKAGE:"P3",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-17",PLAN:"2024-11-01",FORECAST:"2024-09-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-12",PLAN:"2024-03-01",FORECAST:"2024-03-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"NA",YEARS:2024},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-16",PLAN:"2024-08-01",FORECAST:"2024-03-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"NA",YEARS:2024},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"POL-06",PLAN:"2024-08-01",FORECAST:"2024-07-01",FUNCTIONS:"Polymers",PACKAGE:"NA",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"POL-10",PLAN:"2023-04-01",FORECAST:"2023-04-01",FUNCTIONS:"Polymers",PACKAGE:"NA",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"SUP-05",PLAN:"2023-10-01",FORECAST:"2023-10-01",FUNCTIONS:"Supply Chain",PACKAGE:"NA",EM_LEAD:"Gan",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-25",PLAN:"2024-03-01",FORECAST:"2024-01-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-26",PLAN:"2024-03-01",FORECAST:"2024-01-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"BUS-37",PLAN:"2022-06-01",FORECAST:"2022-06-01",FUNCTIONS:"Business",PACKAGE:"P3",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-40",PLAN:"2023-10-01",FORECAST:"2023-10-01",FUNCTIONS:"Business",PACKAGE:"P3",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-54",PLAN:"2023-01-01",FORECAST:"2023-01-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-57",PLAN:"2023-10-01",FORECAST:"2023-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-63",PLAN:"2024-11-01",FORECAST:"2024-07-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-04",PLAN:"2024-07-01",FORECAST:"2024-04-01",FUNCTIONS:"Completions",PACKAGE:"P3",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-14",PLAN:"2024-07-01",FORECAST:"2024-07-01",FUNCTIONS:"Completions",PACKAGE:"P3",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"ENG-07",PLAN:"2022-10-01",FORECAST:"2022-10-01",FUNCTIONS:"Engineering",PACKAGE:"P1",EM_LEAD:"Ang",LOOKAHEAD:"NA",YEARS:2022},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-18",PLAN:"2024-09-01",FORECAST:"2024-01-01",FUNCTIONS:"Infrastructure",PACKAGE:"OP",EM_LEAD:"Fuller",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-05",PLAN:"2023-10-01",FORECAST:"2023-07-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Neo",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-17A",PLAN:"2024-11-01",FORECAST:"2024-11-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-25",PLAN:"2025-03-01",FORECAST:"2024-11-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-07",PLAN:"2023-03-01",FORECAST:"2023-01-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"90D",YEARS:2023},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-13",PLAN:"2024-04-01",FORECAST:"2024-03-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"NA",YEARS:2024},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"POL-11",PLAN:"2023-07-01",FORECAST:"2023-07-01",FUNCTIONS:"Polymers",PACKAGE:"NA",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-21",PLAN:"2023-12-01",FORECAST:"2023-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-31",PLAN:"2024-09-01",FORECAST:"2024-08-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-32",PLAN:"2024-10-01",FORECAST:"2024-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-42",PLAN:"2023-11-01",FORECAST:"2023-11-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-46",PLAN:"2024-03-01",FORECAST:"2024-03-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Yu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-55",PLAN:"2023-06-01",FORECAST:"2023-06-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-56",PLAN:"2023-09-01",FORECAST:"2023-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-58",PLAN:"2023-10-01",FORECAST:"2023-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"CML-05",PLAN:"2021-12-01",FORECAST:"2021-12-01",FUNCTIONS:"Commercial",PACKAGE:"NA",EM_LEAD:"K. Lin",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-02",PLAN:"2023-11-01",FORECAST:"2023-11-01",FUNCTIONS:"Completions",PACKAGE:"NA",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-06",PLAN:"2024-08-01",FORECAST:"2024-08-01",FUNCTIONS:"Completions",PACKAGE:"NA",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-10",PLAN:"2025-03-01",FORECAST:"2025-02-01",FUNCTIONS:"Completions",PACKAGE:"NA",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-11",PLAN:"2023-12-01",FORECAST:"2023-12-01",FUNCTIONS:"Completions",PACKAGE:"P3",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"ENG-06",PLAN:"2022-01-01",FORECAST:"2022-01-01",FUNCTIONS:"Engineering",PACKAGE:"NA",EM_LEAD:"Ang",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-17",PLAN:"2024-11-01",FORECAST:"2024-09-01",FUNCTIONS:"Infrastructure",PACKAGE:"OP",EM_LEAD:"Fuller",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-14",PLAN:"2024-05-01",FORECAST:"2024-03-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Neo",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-16",PLAN:"2024-07-01",FORECAST:"2024-07-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-18",PLAN:"2024-11-01",FORECAST:"2024-09-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"OLE-04",PLAN:"2022-01-01",FORECAST:"2022-01-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Webb",LOOKAHEAD:"NA",YEARS:2022},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-14",PLAN:"2024-09-01",FORECAST:"2024-09-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"NA",YEARS:2024},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"POL-04",PLAN:"2024-03-01",FORECAST:"2024-03-01",FUNCTIONS:"Polymers",PACKAGE:"NA",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"POL-08",PLAN:"2023-07-01",FORECAST:"2023-07-01",FUNCTIONS:"Polymers",PACKAGE:"NA",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"SUP-01A",PLAN:"2022-01-01",FORECAST:"2022-01-01",FUNCTIONS:"Supply Chain",PACKAGE:"OP",EM_LEAD:"Gan",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"BUS-01",PLAN:"2021-09-01",FORECAST:"2021-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"BUS-07",PLAN:"2022-01-01",FORECAST:"2022-01-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"M. Lo",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-11",PLAN:"2023-08-01",FORECAST:"2023-08-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"M. Lo",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-14",PLAN:"2023-09-01",FORECAST:"2023-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-51",PLAN:"2025-01-01",FORECAST:"2025-01-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-07",PLAN:"2024-09-01",FORECAST:"2024-07-01",FUNCTIONS:"Completions",PACKAGE:"NA",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-12",PLAN:"2024-09-01",FORECAST:"2024-09-01",FUNCTIONS:"Completions",PACKAGE:"NA",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"CON-01",PLAN:"2021-12-01",FORECAST:"2021-12-01",FUNCTIONS:"Construction",PACKAGE:"P1",EM_LEAD:"ODonnell",LOOKAHEAD:"NA",YEARS:2021},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"ENG-01",PLAN:"2021-11-01",FORECAST:"2021-11-01",FUNCTIONS:"Engineering",PACKAGE:"NA",EM_LEAD:"Ang",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-03",PLAN:"2023-02-01",FORECAST:"2023-02-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Neo",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-15",PLAN:"2024-07-01",FORECAST:"2024-07-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Neo",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-22",PLAN:"2025-04-01",FORECAST:"2025-02-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-24",PLAN:"2025-06-01",FORECAST:"2025-07-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-08",PLAN:"2023-03-01",FORECAST:"2023-01-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"90D",YEARS:2023},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-12",PLAN:"2024-03-01",FORECAST:"2024-03-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"NA",YEARS:2024},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-16",PLAN:"2024-08-01",FORECAST:"2024-03-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"NA",YEARS:2024},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"POL-06",PLAN:"2024-08-01",FORECAST:"2024-08-01",FUNCTIONS:"Polymers",PACKAGE:"NA",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"POL-10",PLAN:"2023-04-01",FORECAST:"2023-04-01",FUNCTIONS:"Polymers",PACKAGE:"NA",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"BUS-06",PLAN:"2022-01-01",FORECAST:"2022-01-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-24",PLAN:"2024-02-01",FORECAST:"2024-02-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"BUS-37",PLAN:"2022-06-01",FORECAST:"2022-06-01",FUNCTIONS:"Business",PACKAGE:"P3",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-41",PLAN:"2023-10-01",FORECAST:"2023-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"W. Zhang",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-44",PLAN:"2024-01-01",FORECAST:"2024-01-01",FUNCTIONS:"Business",PACKAGE:"P3",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-47",PLAN:"2024-03-01",FORECAST:"2024-03-01",FUNCTIONS:"Business",PACKAGE:"P3",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-50",PLAN:"2024-10-01",FORECAST:"2024-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"CML-02",PLAN:"2021-09-01",FORECAST:"2021-09-01",FUNCTIONS:"Commercial",PACKAGE:"NA",EM_LEAD:"XJ Li",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"CML-06",PLAN:"2022-02-01",FORECAST:"2022-02-01",FUNCTIONS:"Commercial",PACKAGE:"OP",EM_LEAD:"XJ Li",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"ENG-11",PLAN:"2023-01-01",FORECAST:"2022-11-01",FUNCTIONS:"Engineering",PACKAGE:"P1",EM_LEAD:"Ang",LOOKAHEAD:"NA",YEARS:2023},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-06",PLAN:"2023-09-01",FORECAST:"2023-09-01",FUNCTIONS:"Infrastructure",PACKAGE:"P3",EM_LEAD:"Fuller",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-10",PLAN:"2024-02-01",FORECAST:"2024-01-01",FUNCTIONS:"Infrastructure",PACKAGE:"P3",EM_LEAD:"Fuller",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"MFG-01",PLAN:"2022-04-01",FORECAST:"2022-04-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Emmen",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-16A",PLAN:"2024-09-01",FORECAST:"2024-09-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-07",PLAN:"2023-03-01",FORECAST:"2023-01-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"90D",YEARS:2023},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-15",PLAN:"2024-10-01",FORECAST:"2024-10-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"NA",YEARS:2024},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"POL-01",PLAN:"2021-10-01",FORECAST:"2021-10-01",FUNCTIONS:"Polymers",PACKAGE:"NA",EM_LEAD:"Fuller",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"BUS-02",PLAN:"2021-09-01",FORECAST:"2021-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Batey",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-12",PLAN:"2023-08-01",FORECAST:"2023-06-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Yu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-13",PLAN:"2023-09-01",FORECAST:"2023-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-15",PLAN:"2023-09-01",FORECAST:"2023-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-32",PLAN:"2024-10-01",FORECAST:"2024-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-34",PLAN:"2024-12-01",FORECAST:"2024-11-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-49",PLAN:"2024-09-01",FORECAST:"2024-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"L Fung",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-59",PLAN:"2023-10-01",FORECAST:"2023-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"CML-03",PLAN:"2021-09-01",FORECAST:"2021-09-01",FUNCTIONS:"Commercial",PACKAGE:"NA",EM_LEAD:"K. Lin",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-10",PLAN:"2025-03-01",FORECAST:"2025-02-01",FUNCTIONS:"Completions",PACKAGE:"NA",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"ENG-02",PLAN:"2022-01-01",FORECAST:"2022-01-01",FUNCTIONS:"Engineering",PACKAGE:"P1",EM_LEAD:"Ang",LOOKAHEAD:"NA",YEARS:2022},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"ENG-04",PLAN:"2022-01-01",FORECAST:"2022-01-01",FUNCTIONS:"Engineering",PACKAGE:"P1",EM_LEAD:"Ang",LOOKAHEAD:"NA",YEARS:2022},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"ENG-09",PLAN:"2022-12-01",FORECAST:"2022-12-01",FUNCTIONS:"Engineering",PACKAGE:"P1",EM_LEAD:"Ang",LOOKAHEAD:"NA",YEARS:2022},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"ENG-13",PLAN:"2023-04-01",FORECAST:"2023-06-01",FUNCTIONS:"Engineering",PACKAGE:"P1",EM_LEAD:"Ang",LOOKAHEAD:"90D",YEARS:2023},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-09",PLAN:"2024-01-01",FORECAST:"2024-01-01",FUNCTIONS:"Infrastructure",PACKAGE:"P3",EM_LEAD:"Fuller",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-12",PLAN:"2023-10-01",FORECAST:"2023-10-01",FUNCTIONS:"Infrastructure",PACKAGE:"P3",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-16",PLAN:"2024-11-01",FORECAST:"2024-09-01",FUNCTIONS:"Completions",PACKAGE:"P3",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-16A",PLAN:"2024-11-01",FORECAST:"2024-08-01",FUNCTIONS:"Completions",PACKAGE:"P3",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-11",PLAN:"2023-11-01",FORECAST:"2023-11-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"NA",YEARS:2023},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"SUP-02",PLAN:"2022-07-01",FORECAST:"2022-04-01",FUNCTIONS:"Supply Chain",PACKAGE:"NA",EM_LEAD:"Gan",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"SUP-04",PLAN:"2023-09-01",FORECAST:"2023-09-01",FUNCTIONS:"Supply Chain",PACKAGE:"NA",EM_LEAD:"Gan",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"SUP-06",PLAN:"2024-01-01",FORECAST:"2024-07-01",FUNCTIONS:"Supply Chain",PACKAGE:"NA",EM_LEAD:"Gan",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-25",PLAN:"2024-03-01",FORECAST:"2024-01-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-35",PLAN:"2025-02-01",FORECAST:"2024-12-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"BUS-37",PLAN:"2022-06-01",FORECAST:"2022-06-01",FUNCTIONS:"Business",PACKAGE:"P3",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-40",PLAN:"2023-10-01",FORECAST:"2023-10-01",FUNCTIONS:"Business",PACKAGE:"P3",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-49",PLAN:"2024-09-01",FORECAST:"2024-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"L Fung",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-52",PLAN:"2024-12-01",FORECAST:"2024-12-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"HT. Yeo",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-54",PLAN:"2023-01-01",FORECAST:"2023-01-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-57",PLAN:"2023-10-01",FORECAST:"2023-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-60",PLAN:"2024-09-01",FORECAST:"2024-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"M. Lo",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"CML-03",PLAN:"2021-09-01",FORECAST:"2021-09-01",FUNCTIONS:"Commercial",PACKAGE:"NA",EM_LEAD:"K. Lin",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"CML-06",PLAN:"2022-02-01",FORECAST:"2022-02-01",FUNCTIONS:"Commercial",PACKAGE:"OP",EM_LEAD:"XJ Li",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-06",PLAN:"2023-09-01",FORECAST:"2023-09-01",FUNCTIONS:"Infrastructure",PACKAGE:"P3",EM_LEAD:"Fuller",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-18",PLAN:"2024-09-01",FORECAST:"2024-01-01",FUNCTIONS:"Infrastructure",PACKAGE:"OP",EM_LEAD:"Fuller",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"MFG-01",PLAN:"2022-04-01",FORECAST:"2022-04-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Emmen",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-07",PLAN:"2023-03-01",FORECAST:"2023-01-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"90D",YEARS:2023},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-09",PLAN:"2023-07-01",FORECAST:"2023-07-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"NA",YEARS:2023},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-15",PLAN:"2024-10-01",FORECAST:"2024-09-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"NA",YEARS:2024},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"POL-05",PLAN:"2024-07-01",FORECAST:"2024-06-01",FUNCTIONS:"Polymers",PACKAGE:"NA",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"POL-09",PLAN:"2023-07-01",FORECAST:"2023-07-01",FUNCTIONS:"Polymers",PACKAGE:"NA",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"BUS-03",PLAN:"2021-11-01",FORECAST:"2021-11-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Stein",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-32",PLAN:"2024-10-01",FORECAST:"2024-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-34",PLAN:"2024-12-01",FORECAST:"2024-11-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-39",PLAN:"2023-09-01",FORECAST:"2023-09-01",FUNCTIONS:"Business",PACKAGE:"P3",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-42",PLAN:"2023-11-01",FORECAST:"2023-11-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-59",PLAN:"2023-10-01",FORECAST:"2023-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-62",PLAN:"2025-06-01",FORECAST:"2023-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"HT. Yeo",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"CML-05",PLAN:"2021-12-01",FORECAST:"2021-12-01",FUNCTIONS:"Commercial",PACKAGE:"NA",EM_LEAD:"K. Lin",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-06",PLAN:"2024-08-01",FORECAST:"2024-06-01",FUNCTIONS:"Completions",PACKAGE:"NA",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-10",PLAN:"2025-03-01",FORECAST:"2025-01-01",FUNCTIONS:"Completions",PACKAGE:"NA",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"ENG-06",PLAN:"2022-01-01",FORECAST:"2022-01-01",FUNCTIONS:"Engineering",PACKAGE:"NA",EM_LEAD:"Ang",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"INF-01",PLAN:"2021-08-01",FORECAST:"2021-08-01",FUNCTIONS:"Infrastructure",PACKAGE:"OP",EM_LEAD:"Atwood/Lin",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-17",PLAN:"2024-11-01",FORECAST:"2024-09-01",FUNCTIONS:"Infrastructure",PACKAGE:"OP",EM_LEAD:"Fuller",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-16",PLAN:"2024-07-01",FORECAST:"2024-07-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-18",PLAN:"2024-11-01",FORECAST:"2024-08-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"OLE-04",PLAN:"2022-01-01",FORECAST:"2022-01-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Webb",LOOKAHEAD:"NA",YEARS:2022},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-14",PLAN:"2024-09-01",FORECAST:"2024-08-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"NA",YEARS:2024},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"POL-04",PLAN:"2024-03-01",FORECAST:"2024-03-01",FUNCTIONS:"Polymers",PACKAGE:"NA",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"SUP-01A",PLAN:"2022-01-01",FORECAST:"2022-01-01",FUNCTIONS:"Supply Chain",PACKAGE:"OP",EM_LEAD:"Gan",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-29",PLAN:"2024-08-01",FORECAST:"2024-08-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-39",PLAN:"2023-09-01",FORECAST:"2023-09-01",FUNCTIONS:"Business",PACKAGE:"P3",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-41",PLAN:"2023-10-01",FORECAST:"2023-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"W. Zhang",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-44",PLAN:"2024-01-01",FORECAST:"2024-01-01",FUNCTIONS:"Business",PACKAGE:"P3",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-50",PLAN:"2024-10-01",FORECAST:"2024-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"CML-06",PLAN:"2022-02-01",FORECAST:"2022-02-01",FUNCTIONS:"Commercial",PACKAGE:"OP",EM_LEAD:"XJ Li",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"ENG-10",PLAN:"2023-01-01",FORECAST:"2022-12-01",FUNCTIONS:"Engineering",PACKAGE:"NA",EM_LEAD:"Ang",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"ENG-11",PLAN:"2023-01-01",FORECAST:"2022-11-01",FUNCTIONS:"Engineering",PACKAGE:"P1",EM_LEAD:"Ang",LOOKAHEAD:"NA",YEARS:2023},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-06",PLAN:"2023-09-01",FORECAST:"2023-09-01",FUNCTIONS:"Infrastructure",PACKAGE:"P3",EM_LEAD:"Fuller",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-10",PLAN:"2024-02-01",FORECAST:"2024-01-01",FUNCTIONS:"Infrastructure",PACKAGE:"P3",EM_LEAD:"Fuller",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"MFG-01",PLAN:"2022-04-01",FORECAST:"2022-04-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Emmen",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-08",PLAN:"2023-12-01",FORECAST:"2023-08-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-16A",PLAN:"2024-09-01",FORECAST:"2024-07-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"OLE-01",PLAN:"2021-10-01",FORECAST:"2021-10-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Webb",LOOKAHEAD:"NA",YEARS:2021},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"BUS-08",PLAN:"2022-08-01",FORECAST:"2022-08-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"M. Lo",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-15",PLAN:"2023-09-01",FORECAST:"2023-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-17",PLAN:"2023-10-01",FORECAST:"2023-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"M. Lo",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-32",PLAN:"2024-10-01",FORECAST:"2024-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-38",PLAN:"2023-07-01",FORECAST:"2023-07-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"L Fung",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-43",PLAN:"2023-11-01",FORECAST:"2023-11-01",FUNCTIONS:"Business",PACKAGE:"P3",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-62",PLAN:"2025-06-01",FORECAST:"2023-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"HT. Yeo",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-02",PLAN:"2024-01-01",FORECAST:"2024-01-01",FUNCTIONS:"Completions",PACKAGE:"NA",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-12",PLAN:"2024-08-01",FORECAST:"2024-08-01",FUNCTIONS:"Completions",PACKAGE:"NA",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"ENG-01",PLAN:"2021-11-01",FORECAST:"2021-11-01",FUNCTIONS:"Engineering",PACKAGE:"NA",EM_LEAD:"Ang",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"ENG-02",PLAN:"2022-01-01",FORECAST:"2022-01-01",FUNCTIONS:"Engineering",PACKAGE:"P1",EM_LEAD:"Ang",LOOKAHEAD:"NA",YEARS:2022},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"ENG-04",PLAN:"2022-01-01",FORECAST:"2022-01-01",FUNCTIONS:"Engineering",PACKAGE:"P1",EM_LEAD:"Ang",LOOKAHEAD:"NA",YEARS:2022},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"ENG-08",PLAN:"2022-11-01",FORECAST:"2022-12-01",FUNCTIONS:"Engineering",PACKAGE:"NA",EM_LEAD:"Ang",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"ENG-09",PLAN:"2022-12-01",FORECAST:"2022-12-01",FUNCTIONS:"Engineering",PACKAGE:"P1",EM_LEAD:"Ang",LOOKAHEAD:"NA",YEARS:2022},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-09",PLAN:"2024-01-01",FORECAST:"2024-01-01",FUNCTIONS:"Infrastructure",PACKAGE:"P3",EM_LEAD:"Fuller",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-19",PLAN:"2024-09-01",FORECAST:"2024-02-01",FUNCTIONS:"Infrastructure",PACKAGE:"OP",EM_LEAD:"Fuller",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-11",PLAN:"2023-11-01",FORECAST:"2023-11-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"NA",YEARS:2023},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"POL-06",PLAN:"2024-08-01",FORECAST:"2024-07-01",FUNCTIONS:"Polymers",PACKAGE:"NA",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"SUP-07",PLAN:"2024-05-01",FORECAST:"2024-05-01",FUNCTIONS:"Supply Chain",PACKAGE:"OP",EM_LEAD:"Gan",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"SUP-10",PLAN:"2025-02-01",FORECAST:"2024-12-01",FUNCTIONS:"Supply Chain",PACKAGE:"NA",EM_LEAD:"J. Ng",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"BUS-05",PLAN:"2021-11-01",FORECAST:"2021-11-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Batey",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"BUS-07",PLAN:"2022-01-01",FORECAST:"2022-01-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"M. Lo",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-10",PLAN:"2023-07-01",FORECAST:"2023-07-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"M. Lo",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-14",PLAN:"2023-09-01",FORECAST:"2023-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-29",PLAN:"2024-08-01",FORECAST:"2024-08-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-50",PLAN:"2024-10-01",FORECAST:"2024-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-53",PLAN:"2022-10-01",FORECAST:"2022-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-59",PLAN:"2023-10-01",FORECAST:"2023-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-07",PLAN:"2024-09-01",FORECAST:"2024-07-01",FUNCTIONS:"Completions",PACKAGE:"NA",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"ENG-11",PLAN:"2023-01-01",FORECAST:"2022-11-01",FUNCTIONS:"Engineering",PACKAGE:"P1",EM_LEAD:"Ang",LOOKAHEAD:"NA",YEARS:2023},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-10",PLAN:"2024-02-01",FORECAST:"2024-01-01",FUNCTIONS:"Infrastructure",PACKAGE:"P3",EM_LEAD:"Fuller",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-08",PLAN:"2023-12-01",FORECAST:"2023-12-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-15",PLAN:"2024-07-01",FORECAST:"2024-07-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Neo",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-16A",PLAN:"2024-09-01",FORECAST:"2024-09-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"OLE-01",PLAN:"2021-10-01",FORECAST:"2021-10-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Webb",LOOKAHEAD:"NA",YEARS:2021},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-08",PLAN:"2023-03-01",FORECAST:"2023-01-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"90D",YEARS:2023},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-12",PLAN:"2024-03-01",FORECAST:"2024-03-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"NA",YEARS:2024},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-16",PLAN:"2024-08-01",FORECAST:"2024-03-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"NA",YEARS:2024},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"POL-10",PLAN:"2023-04-01",FORECAST:"2023-04-01",FUNCTIONS:"Polymers",PACKAGE:"NA",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-09",PLAN:"2023-01-01",FORECAST:"2022-12-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"W. Zhang",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-18",PLAN:"2023-11-01",FORECAST:"2023-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Yu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-21",PLAN:"2023-12-01",FORECAST:"2023-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-31",PLAN:"2024-09-01",FORECAST:"2024-08-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-32",PLAN:"2024-10-01",FORECAST:"2024-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-56",PLAN:"2023-09-01",FORECAST:"2023-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-58",PLAN:"2023-10-01",FORECAST:"2023-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"CML-05",PLAN:"2021-12-01",FORECAST:"2021-12-01",FUNCTIONS:"Commercial",PACKAGE:"NA",EM_LEAD:"K. Lin",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"CML-06",PLAN:"2022-02-01",FORECAST:"2022-02-01",FUNCTIONS:"Commercial",PACKAGE:"OP",EM_LEAD:"XJ Li",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-03",PLAN:"2023-11-01",FORECAST:"2023-11-01",FUNCTIONS:"Completions",PACKAGE:"NA",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-10",PLAN:"2025-03-01",FORECAST:"2025-02-01",FUNCTIONS:"Completions",PACKAGE:"NA",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"ENG-05",PLAN:"2022-01-01",FORECAST:"2022-01-01",FUNCTIONS:"Engineering",PACKAGE:"P1",EM_LEAD:"Ang",LOOKAHEAD:"NA",YEARS:2022},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"ENG-06",PLAN:"2022-01-01",FORECAST:"2022-01-01",FUNCTIONS:"Engineering",PACKAGE:"NA",EM_LEAD:"Ang",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"ENG-12",PLAN:"2023-04-01",FORECAST:"2023-04-01",FUNCTIONS:"Engineering",PACKAGE:"P1",EM_LEAD:"Ang",LOOKAHEAD:"90D",YEARS:2023},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"ENG-14",PLAN:"2023-05-01",FORECAST:"2023-06-01",FUNCTIONS:"Engineering",PACKAGE:"NA",EM_LEAD:"Ang",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-17",PLAN:"2024-11-01",FORECAST:"2024-09-01",FUNCTIONS:"Infrastructure",PACKAGE:"OP",EM_LEAD:"Fuller",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-19",PLAN:"2024-12-01",FORECAST:"2024-10-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"OLE-04",PLAN:"2022-01-01",FORECAST:"2022-01-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Webb",LOOKAHEAD:"NA",YEARS:2022},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"POL-04",PLAN:"2024-03-01",FORECAST:"2024-03-01",FUNCTIONS:"Polymers",PACKAGE:"NA",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"POL-11",PLAN:"2023-07-01",FORECAST:"2023-07-01",FUNCTIONS:"Polymers",PACKAGE:"NA",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"BUS-07",PLAN:"2022-01-01",FORECAST:"2022-01-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"M. Lo",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-09",PLAN:"2023-01-01",FORECAST:"2023-03-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"W. Zhang",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-18",PLAN:"2023-11-01",FORECAST:"2023-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Yu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-32",PLAN:"2024-10-01",FORECAST:"2024-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-34",PLAN:"2024-12-01",FORECAST:"2024-11-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-59",PLAN:"2023-10-01",FORECAST:"2023-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"CML-05",PLAN:"2021-12-01",FORECAST:"2021-12-01",FUNCTIONS:"Commercial",PACKAGE:"NA",EM_LEAD:"K. Lin",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-03",PLAN:"2023-11-01",FORECAST:"2023-11-01",FUNCTIONS:"Completions",PACKAGE:"NA",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-10",PLAN:"2025-03-01",FORECAST:"2025-02-01",FUNCTIONS:"Completions",PACKAGE:"NA",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"ENG-05",PLAN:"2022-01-01",FORECAST:"2022-01-01",FUNCTIONS:"Engineering",PACKAGE:"P1",EM_LEAD:"Ang",LOOKAHEAD:"NA",YEARS:2022},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"ENG-06",PLAN:"2022-01-01",FORECAST:"2022-01-01",FUNCTIONS:"Engineering",PACKAGE:"NA",EM_LEAD:"Ang",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"ENG-12",PLAN:"2023-04-01",FORECAST:"2023-04-01",FUNCTIONS:"Engineering",PACKAGE:"P1",EM_LEAD:"Ang",LOOKAHEAD:"90D",YEARS:2023},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"ENG-14",PLAN:"2023-05-01",FORECAST:"2023-06-01",FUNCTIONS:"Engineering",PACKAGE:"NA",EM_LEAD:"Ang",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-12",PLAN:"2023-10-01",FORECAST:"2023-10-01",FUNCTIONS:"Infrastructure",PACKAGE:"P3",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-17",PLAN:"2024-11-01",FORECAST:"2024-09-01",FUNCTIONS:"Infrastructure",PACKAGE:"OP",EM_LEAD:"Fuller",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-15",PLAN:"2024-07-01",FORECAST:"2024-07-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Neo",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-19",PLAN:"2024-12-01",FORECAST:"2024-10-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"OLE-04",PLAN:"2022-01-01",FORECAST:"2022-01-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Webb",LOOKAHEAD:"NA",YEARS:2022},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"POL-04",PLAN:"2024-03-01",FORECAST:"2024-03-01",FUNCTIONS:"Polymers",PACKAGE:"NA",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"SUP-05",PLAN:"2023-10-01",FORECAST:"2023-10-01",FUNCTIONS:"Supply Chain",PACKAGE:"NA",EM_LEAD:"Gan",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-17",PLAN:"2023-10-01",FORECAST:"2023-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"M. Lo",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-22",PLAN:"2024-01-01",FORECAST:"2024-01-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"M. Lo",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-23",PLAN:"2024-01-01",FORECAST:"2024-01-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"BUS-37",PLAN:"2022-06-01",FORECAST:"2022-06-01",FUNCTIONS:"Business",PACKAGE:"P3",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-43",PLAN:"2023-11-01",FORECAST:"2023-11-01",FUNCTIONS:"Business",PACKAGE:"P3",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-48",PLAN:"2024-09-01",FORECAST:"2024-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-51",PLAN:"2024-12-01",FORECAST:"2025-01-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-12",PLAN:"2024-08-01",FORECAST:"2024-09-01",FUNCTIONS:"Completions",PACKAGE:"NA",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-14",PLAN:"2024-07-01",FORECAST:"2024-08-01",FUNCTIONS:"Completions",PACKAGE:"P3",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"ENG-01",PLAN:"2021-11-01",FORECAST:"2021-11-01",FUNCTIONS:"Engineering",PACKAGE:"NA",EM_LEAD:"Ang",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"ENG-08",PLAN:"2022-11-01",FORECAST:"2022-12-01",FUNCTIONS:"Engineering",PACKAGE:"NA",EM_LEAD:"Ang",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-19",PLAN:"2024-09-01",FORECAST:"2024-02-01",FUNCTIONS:"Infrastructure",PACKAGE:"OP",EM_LEAD:"Fuller",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"MFG-01",PLAN:"2022-04-01",FORECAST:"2022-04-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Emmen",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-22",PLAN:"2025-04-01",FORECAST:"2025-02-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-24",PLAN:"2025-06-01",FORECAST:"2025-07-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-07",PLAN:"2023-03-01",FORECAST:"2023-01-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"90D",YEARS:2023},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-13",PLAN:"2024-04-01",FORECAST:"2024-04-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"NA",YEARS:2024},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-15",PLAN:"2024-10-01",FORECAST:"2024-10-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"NA",YEARS:2024},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"POL-06",PLAN:"2024-08-01",FORECAST:"2024-08-01",FUNCTIONS:"Polymers",PACKAGE:"NA",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"SUP-07",PLAN:"2024-05-01",FORECAST:"2024-05-01",FUNCTIONS:"Supply Chain",PACKAGE:"OP",EM_LEAD:"Gan",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"BUS-01",PLAN:"2021-09-01",FORECAST:"2021-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"BUS-02",PLAN:"2021-09-01",FORECAST:"2021-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Batey",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-11",PLAN:"2023-08-01",FORECAST:"2023-08-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"M. Lo",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-12",PLAN:"2023-08-01",FORECAST:"2023-06-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Yu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-13",PLAN:"2023-09-01",FORECAST:"2023-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-15",PLAN:"2023-09-01",FORECAST:"2023-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"CML-04",PLAN:"2021-09-01",FORECAST:"2021-09-01",FUNCTIONS:"Commercial",PACKAGE:"NA",EM_LEAD:"K. Lin",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-08",PLAN:"2024-11-01",FORECAST:"2024-10-01",FUNCTIONS:"Completions",PACKAGE:"NA",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"CON-01",PLAN:"2021-12-01",FORECAST:"2021-12-01",FUNCTIONS:"Construction",PACKAGE:"P1",EM_LEAD:"ODonnell",LOOKAHEAD:"NA",YEARS:2021},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"ENG-02",PLAN:"2022-01-01",FORECAST:"2022-01-01",FUNCTIONS:"Engineering",PACKAGE:"P1",EM_LEAD:"Ang",LOOKAHEAD:"NA",YEARS:2022},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"ENG-04",PLAN:"2022-01-01",FORECAST:"2022-01-01",FUNCTIONS:"Engineering",PACKAGE:"P1",EM_LEAD:"Ang",LOOKAHEAD:"NA",YEARS:2022},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"ENG-13",PLAN:"2023-04-01",FORECAST:"2023-06-01",FUNCTIONS:"Engineering",PACKAGE:"P1",EM_LEAD:"Ang",LOOKAHEAD:"90D",YEARS:2023},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-09",PLAN:"2024-01-01",FORECAST:"2024-01-01",FUNCTIONS:"Infrastructure",PACKAGE:"P3",EM_LEAD:"Fuller",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-13",PLAN:"2024-03-01",FORECAST:"2024-03-01",FUNCTIONS:"Completions",PACKAGE:"P3",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-16A",PLAN:"2024-11-01",FORECAST:"2024-08-01",FUNCTIONS:"Completions",PACKAGE:"P3",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-17A",PLAN:"2024-11-01",FORECAST:"2024-11-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-21",PLAN:"2025-03-01",FORECAST:"2024-12-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-25",PLAN:"2025-03-01",FORECAST:"2024-11-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"OLE-02",PLAN:"2021-10-01",FORECAST:"2021-10-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Webb",LOOKAHEAD:"NA",YEARS:2021},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-06",PLAN:"2023-01-01",FORECAST:"2023-01-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"NA",YEARS:2023},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-11",PLAN:"2023-11-01",FORECAST:"2023-11-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"NA",YEARS:2023},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-17",PLAN:"2024-11-01",FORECAST:"2024-10-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"NA",YEARS:2024},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"POL-07",PLAN:"2024-09-01",FORECAST:"2024-08-01",FUNCTIONS:"Polymers",PACKAGE:"NA",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"SUP-08",PLAN:"2024-12-01",FORECAST:"2024-12-01",FUNCTIONS:"Supply Chain",PACKAGE:"OP",EM_LEAD:"A. Ai",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"BUS-06",PLAN:"2022-01-01",FORECAST:"2022-01-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-14",PLAN:"2023-09-01",FORECAST:"2023-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-24",PLAN:"2024-02-01",FORECAST:"2024-02-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-41",PLAN:"2023-10-01",FORECAST:"2023-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"W. Zhang",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-44",PLAN:"2024-01-01",FORECAST:"2024-01-01",FUNCTIONS:"Business",PACKAGE:"P3",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-46",PLAN:"2024-03-01",FORECAST:"2024-03-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Yu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-47",PLAN:"2024-01-01",FORECAST:"2024-01-01",FUNCTIONS:"Business",PACKAGE:"P3",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-50",PLAN:"2024-10-01",FORECAST:"2024-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"CML-02",PLAN:"2021-09-01",FORECAST:"2021-09-01",FUNCTIONS:"Commercial",PACKAGE:"NA",EM_LEAD:"XJ Li",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-07",PLAN:"2024-09-01",FORECAST:"2024-06-01",FUNCTIONS:"Completions",PACKAGE:"NA",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-11",PLAN:"2023-12-01",FORECAST:"2023-12-01",FUNCTIONS:"Completions",PACKAGE:"P3",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-13",PLAN:"2024-06-01",FORECAST:"2024-06-01",FUNCTIONS:"Completions",PACKAGE:"P3",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"ENG-11",PLAN:"2023-01-01",FORECAST:"2022-11-01",FUNCTIONS:"Engineering",PACKAGE:"P1",EM_LEAD:"Ang",LOOKAHEAD:"NA",YEARS:2023},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-10",PLAN:"2024-02-01",FORECAST:"2024-01-01",FUNCTIONS:"Infrastructure",PACKAGE:"P3",EM_LEAD:"Fuller",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-14",PLAN:"2024-05-01",FORECAST:"2024-03-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Neo",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-16A",PLAN:"2024-09-01",FORECAST:"2024-07-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-08",PLAN:"2023-03-01",FORECAST:"2023-01-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"90D",YEARS:2023},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-12",PLAN:"2024-03-01",FORECAST:"2024-03-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"NA",YEARS:2024},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-16",PLAN:"2024-08-01",FORECAST:"2024-03-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"NA",YEARS:2024},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"POL-01",PLAN:"2021-10-01",FORECAST:"2021-10-01",FUNCTIONS:"Polymers",PACKAGE:"NA",EM_LEAD:"Fuller",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"POL-10",PLAN:"2023-04-01",FORECAST:"2023-04-01",FUNCTIONS:"Polymers",PACKAGE:"NA",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"BUS-05",PLAN:"2021-11-01",FORECAST:"2021-11-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Batey",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"BUS-07",PLAN:"2022-01-01",FORECAST:"2022-01-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"M. Lo",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-09",PLAN:"2023-01-01",FORECAST:"2023-03-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"W. Zhang",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-10",PLAN:"2023-07-01",FORECAST:"2023-07-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"M. Lo",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-18",PLAN:"2023-11-01",FORECAST:"2023-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Yu",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-32",PLAN:"2024-10-01",FORECAST:"2024-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-33",PLAN:"2024-11-01",FORECAST:"2024-11-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"HT. Yeo",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-53",PLAN:"2022-10-01",FORECAST:"2022-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-59",PLAN:"2023-10-01",FORECAST:"2023-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-63",PLAN:"2024-11-01",FORECAST:"2024-07-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"CML-05",PLAN:"2021-12-01",FORECAST:"2021-12-01",FUNCTIONS:"Commercial",PACKAGE:"NA",EM_LEAD:"K. Lin",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-09",PLAN:"2024-12-01",FORECAST:"2024-10-01",FUNCTIONS:"Completions",PACKAGE:"NA",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-10",PLAN:"2025-03-01",FORECAST:"2025-01-01",FUNCTIONS:"Completions",PACKAGE:"NA",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"ENG-03",PLAN:"2022-01-01",FORECAST:"2022-01-01",FUNCTIONS:"Engineering",PACKAGE:"NA",EM_LEAD:"Ang",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"ENG-05",PLAN:"2022-01-01",FORECAST:"2022-01-01",FUNCTIONS:"Engineering",PACKAGE:"P1",EM_LEAD:"Ang",LOOKAHEAD:"NA",YEARS:2022},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"ENG-06",PLAN:"2022-01-01",FORECAST:"2022-01-01",FUNCTIONS:"Engineering",PACKAGE:"NA",EM_LEAD:"Ang",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"ENG-12",PLAN:"2023-04-01",FORECAST:"2023-04-01",FUNCTIONS:"Engineering",PACKAGE:"P1",EM_LEAD:"Ang",LOOKAHEAD:"90D",YEARS:2023},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"ENG-14",PLAN:"2023-05-01",FORECAST:"2023-06-01",FUNCTIONS:"Engineering",PACKAGE:"NA",EM_LEAD:"Ang",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-17",PLAN:"2024-11-01",FORECAST:"2024-09-01",FUNCTIONS:"Infrastructure",PACKAGE:"OP",EM_LEAD:"Fuller",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-15",PLAN:"2024-07-01",FORECAST:"2024-07-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Neo",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-19",PLAN:"2024-12-01",FORECAST:"2024-10-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"OLE-04",PLAN:"2022-01-01",FORECAST:"2022-01-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Webb",LOOKAHEAD:"NA",YEARS:2022},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"POL-04",PLAN:"2024-03-01",FORECAST:"2024-03-01",FUNCTIONS:"Polymers",PACKAGE:"NA",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"POL-08",PLAN:"2023-07-01",FORECAST:"2023-07-01",FUNCTIONS:"Polymers",PACKAGE:"NA",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"POL-11",PLAN:"2023-07-01",FORECAST:"2023-07-01",FUNCTIONS:"Polymers",PACKAGE:"NA",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"BUS-01",PLAN:"2021-09-01",FORECAST:"2021-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"BUS-02",PLAN:"2021-09-01",FORECAST:"2021-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Batey",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-12",PLAN:"2023-08-01",FORECAST:"2023-06-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Yu",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-13",PLAN:"2023-09-01",FORECAST:"2023-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-23",PLAN:"2024-01-01",FORECAST:"2024-01-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-48",PLAN:"2024-09-01",FORECAST:"2024-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-51",PLAN:"2024-12-01",FORECAST:"2024-12-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"CML-04",PLAN:"2021-09-01",FORECAST:"2021-09-01",FUNCTIONS:"Commercial",PACKAGE:"NA",EM_LEAD:"K. Lin",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-08",PLAN:"2024-11-01",FORECAST:"2024-10-01",FUNCTIONS:"Completions",PACKAGE:"NA",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"CON-01",PLAN:"2021-12-01",FORECAST:"2021-12-01",FUNCTIONS:"Construction",PACKAGE:"P1",EM_LEAD:"ODonnell",LOOKAHEAD:"NA",YEARS:2021},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"ENG-13",PLAN:"2023-04-01",FORECAST:"2023-06-01",FUNCTIONS:"Engineering",PACKAGE:"P1",EM_LEAD:"Ang",LOOKAHEAD:"90D",YEARS:2023},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-16",PLAN:"2024-11-01",FORECAST:"2024-05-01",FUNCTIONS:"Completions",PACKAGE:"P3",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-16A",PLAN:"2024-11-01",FORECAST:"2024-08-01",FUNCTIONS:"Completions",PACKAGE:"P3",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"MFG-01",PLAN:"2022-04-01",FORECAST:"2022-04-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Emmen",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-22",PLAN:"2025-04-01",FORECAST:"2025-01-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-24",PLAN:"2025-06-01",FORECAST:"2025-07-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-15",PLAN:"2024-10-01",FORECAST:"2024-09-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"NA",YEARS:2024},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"POL-07",PLAN:"2024-09-01",FORECAST:"2024-08-01",FUNCTIONS:"Polymers",PACKAGE:"NA",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"SUP-02",PLAN:"2022-07-01",FORECAST:"2022-04-01",FUNCTIONS:"Supply Chain",PACKAGE:"NA",EM_LEAD:"Gan",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"SUP-06",PLAN:"2024-01-01",FORECAST:"2024-07-01",FUNCTIONS:"Supply Chain",PACKAGE:"NA",EM_LEAD:"Gan",LOOKAHEAD:"NA"},
    {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"SUP-08",PLAN:"2024-12-01",FORECAST:"2024-12-01",FUNCTIONS:"Supply Chain",PACKAGE:"OP",EM_LEAD:"A. Ai",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-25",PLAN:"2024-03-01",FORECAST:"2024-01-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-26",PLAN:"2024-03-01",FORECAST:"2024-01-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-28",PLAN:"2024-05-01",FORECAST:"2024-05-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"M. Lo",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-33",PLAN:"2024-11-01",FORECAST:"2024-11-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"HT. Yeo",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-45",PLAN:"2024-01-01",FORECAST:"2024-01-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"M. Lo",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-57",PLAN:"2023-10-01",FORECAST:"2023-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-62",PLAN:"2025-06-01",FORECAST:"2023-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"HT. Yeo",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"CML-06",PLAN:"2022-02-01",FORECAST:"2022-02-01",FUNCTIONS:"Commercial",PACKAGE:"OP",EM_LEAD:"XJ Li",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-04",PLAN:"2024-07-01",FORECAST:"2024-05-01",FUNCTIONS:"Completions",PACKAGE:"P3",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"ENG-03",PLAN:"2022-01-01",FORECAST:"2022-01-01",FUNCTIONS:"Engineering",PACKAGE:"NA",EM_LEAD:"Ang",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"ENG-10",PLAN:"2023-01-01",FORECAST:"2022-12-01",FUNCTIONS:"Engineering",PACKAGE:"NA",EM_LEAD:"Ang",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"INF-01",PLAN:"2021-08-01",FORECAST:"2021-08-01",FUNCTIONS:"Infrastructure",PACKAGE:"OP",EM_LEAD:"Atwood/Lin",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-06",PLAN:"2023-09-01",FORECAST:"2023-09-01",FUNCTIONS:"Infrastructure",PACKAGE:"P3",EM_LEAD:"Fuller",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"MFG-01",PLAN:"2022-04-01",FORECAST:"2022-04-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Emmen",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-05",PLAN:"2023-10-01",FORECAST:"2023-10-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Neo",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"POL-06",PLAN:"2024-08-01",FORECAST:"2024-08-01",FUNCTIONS:"Polymers",PACKAGE:"NA",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"SUP-10",PLAN:"2025-02-01",FORECAST:"2024-12-01",FUNCTIONS:"Supply Chain",PACKAGE:"NA",EM_LEAD:"J. Ng",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-15",PLAN:"2023-09-01",FORECAST:"2023-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-25",PLAN:"2024-03-01",FORECAST:"2024-01-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-36",PLAN:"2025-03-01",FORECAST:"2025-01-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-40",PLAN:"2023-10-01",FORECAST:"2023-10-01",FUNCTIONS:"Business",PACKAGE:"P3",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-41",PLAN:"2023-10-01",FORECAST:"2023-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"W. Zhang",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-54",PLAN:"2023-01-01",FORECAST:"2023-01-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-57",PLAN:"2023-10-01",FORECAST:"2023-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"CMP-08",PLAN:"2024-11-01",FORECAST:"2024-11-01",FUNCTIONS:"Completions",PACKAGE:"NA",EM_LEAD:"T Skeik",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"ENG-13",PLAN:"2023-04-01",FORECAST:"2023-06-01",FUNCTIONS:"Engineering",PACKAGE:"P1",EM_LEAD:"Ang",LOOKAHEAD:"90D",YEARS:2023},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"INF-03",PLAN:"2023-04-01",FORECAST:"2023-05-01",FUNCTIONS:"Infrastructure",PACKAGE:"P3",EM_LEAD:"Letterle",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-21",PLAN:"2025-03-01",FORECAST:"2025-01-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-25",PLAN:"2025-03-01",FORECAST:"2024-12-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"OLE-02",PLAN:"2021-10-01",FORECAST:"2021-10-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Webb",LOOKAHEAD:"NA",YEARS:2021},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"OLE-14",PLAN:"2024-09-01",FORECAST:"2024-09-01",FUNCTIONS:"Olefins",PACKAGE:"P1",EM_LEAD:"Frye",LOOKAHEAD:"NA",YEARS:2024},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"SUP-01A",PLAN:"2022-01-01",FORECAST:"2022-01-01",FUNCTIONS:"Supply Chain",PACKAGE:"OP",EM_LEAD:"Gan",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"SUP-05",PLAN:"2023-10-01",FORECAST:"2023-10-01",FUNCTIONS:"Supply Chain",PACKAGE:"NA",EM_LEAD:"Gan",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"SUP-10",PLAN:"2025-02-01",FORECAST:"2024-12-01",FUNCTIONS:"Supply Chain",PACKAGE:"NA",EM_LEAD:"J. Ng",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"Completed",STATUS_CODE:"C",LMS_NO:"BUS-02",PLAN:"2021-09-01",FORECAST:"2021-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Batey",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-12",PLAN:"2023-08-01",FORECAST:"2023-06-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Yu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-13",PLAN:"2023-09-01",FORECAST:"2023-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-15",PLAN:"2023-09-01",FORECAST:"2023-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-21",PLAN:"2023-12-01",FORECAST:"2023-10-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-26",PLAN:"2024-03-01",FORECAST:"2024-01-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"Thompson",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-55",PLAN:"2023-06-01",FORECAST:"2023-06-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"},
    {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"BUS-56",PLAN:"2023-09-01",FORECAST:"2023-09-01",FUNCTIONS:"Business",PACKAGE:"NA",EM_LEAD:"S. Shu",LOOKAHEAD:"NA"}
    ],
  // DataItems: [
  //   {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",STATUS_CODE:"N",LMS_NO:"MFG-08",PLAN:"2023-12-01",FORECAST:"2023-12-01",FUNCTIONS:"Mfg.",PACKAGE:"NA",EM_LEAD:"Mabry",LOOKAHEAD:"NA"},
  //   // {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"Completed",LMS_NO:"BUS-01",PLAN:"2021-09-01",FORECAST:"2021-09-01",STATUS_CODE:"C"},
  //   // {CUTOFF:"2022-07-31",MON:7,YEAR:2022,MONTH:"Jul",STATUS:"To Go",LMS_NO:"BUS-48",PLAN:"2024-09-01",FORECAST:"2024-09-01",STATUS_CODE:"N"},
  //   // {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"To Go",LMS_NO:"BUS-48",PLAN:"2024-09-01",FORECAST:"2024-09-01",STATUS_CODE:"N"},
  //   // {CUTOFF:"2022-08-31",MON:8,YEAR:2022,MONTH:"Aug",STATUS:"Completed",LMS_NO:"BUS-01",PLAN:"2021-09-01",FORECAST:"2021-09-01",STATUS_CODE:"C"},
  //   // {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"Completed",LMS_NO:"BUS-01",PLAN:"2021-09-01",FORECAST:"2021-09-01",STATUS_CODE:"C"},
  //   // {CUTOFF:"2022-09-30",MON:9,YEAR:2022,MONTH:"Sep",STATUS:"To Go",LMS_NO:"BUS-48",PLAN:"2024-09-01",FORECAST:"2024-09-01",STATUS_CODE:"N"},
  //   // {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"Completed",LMS_NO:"BUS-01",PLAN:"2021-09-01",FORECAST:"2021-09-01",STATUS_CODE:"C"},
  //   // {CUTOFF:"2022-10-31",MON:10,YEAR:2022,MONTH:"Oct",STATUS:"To Go",LMS_NO:"BUS-48",PLAN:"2024-09-01",FORECAST:"2024-09-01",STATUS_CODE:"N"},
  //   // {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"To Go",LMS_NO:"BUS-48",PLAN:"2024-09-01",FORECAST:"2024-09-01",STATUS_CODE:"N"},
  //   // {CUTOFF:"2022-11-30",MON:11,YEAR:2022,MONTH:"Nov",STATUS:"Completed",LMS_NO:"BUS-01",PLAN:"2021-09-01",FORECAST:"2021-09-01",STATUS_CODE:"C"},
  //   // {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"To Go",LMS_NO:"BUS-48",PLAN:"2024-09-01",FORECAST:"2024-09-01",STATUS_CODE:"N"},
  //   // {CUTOFF:"2022-12-31",MON:12,YEAR:2022,MONTH:"Dec",STATUS:"Completed",LMS_NO:"BUS-01",PLAN:"2021-09-01",FORECAST:"2021-09-01",STATUS_CODE:"C"},
  //   // {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"Completed",LMS_NO:"BUS-01",PLAN:"2021-09-01",FORECAST:"2021-09-01",STATUS_CODE:"C"},
  //   // {CUTOFF:"2023-01-31",MON:1,YEAR:2023,MONTH:"Jan",STATUS:"To Go",LMS_NO:"BUS-48",PLAN:"2024-09-01",FORECAST:"2024-09-01",STATUS_CODE:"N"},
  //   // {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"To Go",LMS_NO:"BUS-48",PLAN:"2024-09-01",FORECAST:"2024-09-30",STATUS_CODE:"C"},
  //   // {CUTOFF:"2023-02-28",MON:2,YEAR:2023,MONTH:"Feb",STATUS:"Completed",LMS_NO:"BUS-01",PLAN:"2021-09-01",FORECAST:"2021-09-01",STATUS_CODE:"C"}
  // ]
  
}