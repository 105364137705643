import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1530,
      CanvasHeight: 1150,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },
    // Queries: {
    //   SQL1: [
        
    //   ]
    // },
    DataItems:  [

      // { NAME: 'Xudong', SCOPE: 'Site', FUNCTIONAL: 'Tim', DAY: 'Wayne', ADMIN: 'Tim', NOTES: 'Point of contact for site planning items – co-ord. with pkg. planners', TYPE: 'noQ', },
      // { NAME: 'Jason', SCOPE: 'P3 Beijing', FUNCTIONAL: 'Tim', DAY: 'Tim/Janel', ADMIN: 'Tim', NOTES: 'Includes WWT (QY) / Power', TYPE: 'noQ', },
      // { NAME: 'Louis', SCOPE: 'P1 Beijing', FUNCTIONAL: 'Tim', DAY: 'Tim/Rodney', ADMIN: 'Tim', NOTES: 'Includes GEG', TYPE: 'noQ', },
      // { NAME: 'Michael', SCOPE: 'P2 Beijing', FUNCTIONAL: 'Tim', DAY: 'Tim/Derrick', ADMIN: 'Tim', NOTES: null, TYPE: 'noQ', },
      // { NAME: 'Sophie', SCOPE: 'Overall Beijing', FUNCTIONAL: 'Tim', DAY: 'Tim/Jason G', ADMIN: 'Tim', NOTES: 'Digital 3rd Party interface', TYPE: 'noQ', },
      // { NAME: 'Hugo', SCOPE: 'Off-plots/Site', FUNCTIONAL: 'Tim/Xudong', DAY: 'Xudong', ADMIN: 'Tim', NOTES: 'DTC', TYPE: 'noQ', },
      // { NAME: 'Allen', SCOPE: 'Mfg.', FUNCTIONAL: 'Leo', DAY: 'Tim', ADMIN: 'Leo', NOTES: null, TYPE: 'noQ', },
      // { NAME: 'Lawrence', SCOPE: 'ISCT', FUNCTIONAL: 'Andres', DAY: 'Andres', ADMIN: 'Andres', NOTES: null, TYPE: 'noQ', },
    
      { NAME: 'Xudong', SCOPE: 'Site Planning Co-ord.', FUNCTIONAL: 'Tim', DAY: 'Tim/Wayne', ADMIN: 'Tim', NOTES: 'Consistency / Consolidate', TYPE:'4Q' },
      { NAME: 'Jason', SCOPE: 'P3 Site Lead', FUNCTIONAL: 'Tim', DAY: 'Tim/Janel/CM', ADMIN: 'Tim', NOTES: 'Includes WWT (QY) / Power (Move 3Q’22)', TYPE:'4Q' },
      { NAME: 'Louis', SCOPE: 'P1 Site Lead', FUNCTIONAL: 'Tim', DAY: 'Tim/Rodney/CM', ADMIN: 'Tim', NOTES: 'Includes GEG (Move 3Q’22)', TYPE:'4Q' },
      { NAME: 'Michael', SCOPE: 'P2 Site Lead', FUNCTIONAL: 'Tim', DAY: 'Tim/Derrick/CM', ADMIN: 'Tim', NOTES: '(Move 3Q’22)', TYPE:'4Q' },
      { NAME: 'Sophie', SCOPE: 'Overall Site', FUNCTIONAL: 'Tim', DAY: 'Tim/Jason G', ADMIN: 'Tim', NOTES: 'Incl. Digital 3rd Party interface (Feb ‘23 move)', TYPE:'4Q' },
      { NAME: 'Hugo', SCOPE: 'Off-plots/Site', FUNCTIONAL: 'Tim/Xudong', DAY: 'Xudong', ADMIN: 'Tim', NOTES: 'DTC', TYPE:'4Q' },
      { NAME: 'Allen', SCOPE: 'Mfg.', FUNCTIONAL: 'Leo/Tim', DAY: 'Tim/Leo', ADMIN: 'Leo', NOTES: null, TYPE:'4Q' },
      { NAME: 'Lawrence', SCOPE: 'ISCT', FUNCTIONAL: 'Andres/Tim', DAY: 'Andres', ADMIN: 'Andres', NOTES: null, TYPE:'4Q' }

    
    
    ],
}