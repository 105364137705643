export default {
  Canvas: {
    CanvasWidth: 1510,
    CanvasHeight: 1110,
    CanvasBorderWeight: 0.1,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 0,
    CanvasChartHeight: 0,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  Text: {
    Text: [
      { text: 'Note', x: 400, y: -50, font: 'roboto', class: 'regular', size: 11, color: '#44A9DF', align: 'start' },
    ],
    Filter: [
      { name: 'MOD'   , x: 320, y: -50, font: 'roboto', class: 'regular', size: 11, color: '#44A9DF', align: 'start' },
    ],
    Sql: [
      {
        query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
        text: 'DataSource',
        x: 320,
        y: -30,
        font: 'roboto',
        style: 'regular',
        size: 10,
        color: '#757575',
        align: 'start'
      },
    ]
  },
DataItems: [
  {NO:1,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",AREA:"HULL-AFT",AREA_DESCR:"HULL-AFT",PLAN_VALUE:1617515.2516,PLAN_BU:2275647,ACTUAL_VALUE:1699592.1002,ACTUAL_BU:2275647,PLAN_PROG:71.07,ACTUAL_PROG:74.68,DELTA:3.61,FAB_START_TOTAL:234,FAB_START_ACTUAL:232,FAB_COMP_TOTAL:234,FAB_COMP_ACTUAL:188,PAINTING_TOTAL:234,PAINTING_ACTUAL:162,BLOCKASSY_TOTAL:47,BLOCKASSY_ACTUAL:50,EREC_TOTAL:179,EREC_ACTUAL:86},
  {NO:2,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",AREA:"HULL-FOR",AREA_DESCR:"HULL-FOR",PLAN_VALUE:2160221.1684,PLAN_BU:3070192,ACTUAL_VALUE:2237566.8424,ACTUAL_BU:3070192,PLAN_PROG:70.36,ACTUAL_PROG:72.88,DELTA:2.52,FAB_START_TOTAL:180,FAB_START_ACTUAL:180,FAB_COMP_TOTAL:180,FAB_COMP_ACTUAL:180,PAINTING_TOTAL:180,PAINTING_ACTUAL:180,BLOCKASSY_TOTAL:61,BLOCKASSY_ACTUAL:59,EREC_TOTAL:102,EREC_ACTUAL:101},
  {NO:3,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",AREA:"HULL-COMMON",AREA_DESCR:"HULL-COMMON",PLAN_VALUE:136970.4904,PLAN_BU:968241,ACTUAL_VALUE:99381.3561,ACTUAL_BU:968241,PLAN_PROG:14.14,ACTUAL_PROG:10.26,DELTA:-3.88,FAB_START_TOTAL:78,FAB_START_ACTUAL:77,FAB_COMP_TOTAL:78,FAB_COMP_ACTUAL:63,PAINTING_TOTAL:78,PAINTING_ACTUAL:42,BLOCKASSY_TOTAL:0,BLOCKASSY_ACTUAL:0,EREC_TOTAL:78,EREC_ACTUAL:17},
  {NO:4,GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",AREA:"LQ",AREA_DESCR:"LQ",PLAN_VALUE:395104.2155999999,PLAN_BU:870349,ACTUAL_VALUE:373894.0694,ACTUAL_BU:870349,PLAN_PROG:45.39,ACTUAL_PROG:42.95,DELTA:-2.44,FAB_START_TOTAL:102,FAB_START_ACTUAL:100,FAB_COMP_TOTAL:102,FAB_COMP_ACTUAL:99,PAINTING_TOTAL:102,PAINTING_ACTUAL:98,BLOCKASSY_TOTAL:0,BLOCKASSY_ACTUAL:0,EREC_TOTAL:102,EREC_ACTUAL:63},
  {NO:1,GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES COMMON",AREA:"RCxx",AREA_DESCR:"Topsides Piperack",PLAN_VALUE:53247.2796,PLAN_BU:285481,ACTUAL_VALUE:43524.4764,ACTUAL_BU:285481,PLAN_PROG:18.65,ACTUAL_PROG:15.24,DELTA:-3.41},
  {NO:2,GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",AREA:"M000",AREA_DESCR:"Common",PLAN_VALUE:3612.0835,PLAN_BU:588190,ACTUAL_VALUE:5263.421,ACTUAL_BU:588190,PLAN_PROG:0.61,ACTUAL_PROG:0.89,DELTA:0.28},
  {NO:3,GWBS_LV1:"TOPSIDES",GWBS_LV2:"INTEGRATION",AREA:"IG90",AREA_DESCR:"Common Integration",PLAN_VALUE:0,PLAN_BU:468164,ACTUAL_VALUE:0,ACTUAL_BU:468164,PLAN_PROG:0,ACTUAL_PROG:0,DELTA:0},
  {NO:4,GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",AREA:"ME01",AREA_DESCR:"Flash Gas Comp.",PLAN_VALUE:42920.182,PLAN_BU:401192,ACTUAL_VALUE:49457.54240000001,ACTUAL_BU:401192,PLAN_PROG:10.69,ACTUAL_PROG:12.32,DELTA:1.63,FAB_START_TOTAL:8,FAB_START_ACTUAL:7,FAB_COMP_TOTAL:8,FAB_COMP_ACTUAL:7,PAINTING_TOTAL:8,PAINTING_ACTUAL:0,BLOCKASSY_TOTAL:3,BLOCKASSY_ACTUAL:0,EREC_TOTAL:8,EREC_ACTUAL:0},
  {NO:5,GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",AREA:"ME02",AREA_DESCR:"MEG & Fule Gas",PLAN_VALUE:42193.6363,PLAN_BU:374168,ACTUAL_VALUE:35786.0316,ACTUAL_BU:374168,PLAN_PROG:11.27,ACTUAL_PROG:9.56,DELTA:-1.71,FAB_START_TOTAL:6,FAB_START_ACTUAL:6,FAB_COMP_TOTAL:6,FAB_COMP_ACTUAL:4,PAINTING_TOTAL:6,PAINTING_ACTUAL:0,BLOCKASSY_TOTAL:1,BLOCKASSY_ACTUAL:0,EREC_TOTAL:6,EREC_ACTUAL:0},
  {NO:6,GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",AREA:"ME04",AREA_DESCR:"Power Gen.",PLAN_VALUE:28255.1078,PLAN_BU:190695,ACTUAL_VALUE:27032.2862,ACTUAL_BU:190695,PLAN_PROG:14.81,ACTUAL_PROG:14.17,DELTA:-0.64,FAB_START_TOTAL:3,FAB_START_ACTUAL:3,FAB_COMP_TOTAL:3,FAB_COMP_ACTUAL:3,PAINTING_TOTAL:3,PAINTING_ACTUAL:0,BLOCKASSY_TOTAL:1,BLOCKASSY_ACTUAL:0,EREC_TOTAL:3,EREC_ACTUAL:0},
  {NO:7,GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",AREA:"ME05",AREA_DESCR:"E/I Bldg.",PLAN_VALUE:53546.034,PLAN_BU:223246,ACTUAL_VALUE:55655.84020000001,ACTUAL_BU:223246,PLAN_PROG:23.98,ACTUAL_PROG:24.93,DELTA:0.95,FAB_START_TOTAL:6,FAB_START_ACTUAL:6,FAB_COMP_TOTAL:6,FAB_COMP_ACTUAL:5,PAINTING_TOTAL:6,PAINTING_ACTUAL:2,BLOCKASSY_TOTAL:1,BLOCKASSY_ACTUAL:0,EREC_TOTAL:6,EREC_ACTUAL:2},
  {NO:8,GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",AREA:"MW01",AREA_DESCR:"Recpt. Facil. & Prod. Sepn.",PLAN_VALUE:65901.746,PLAN_BU:624915,ACTUAL_VALUE:66759.1308,ACTUAL_BU:624915,PLAN_PROG:10.54,ACTUAL_PROG:10.68,DELTA:0.14,FAB_START_TOTAL:8,FAB_START_ACTUAL:7,FAB_COMP_TOTAL:8,FAB_COMP_ACTUAL:7,PAINTING_TOTAL:8,PAINTING_ACTUAL:0,BLOCKASSY_TOTAL:3,BLOCKASSY_ACTUAL:0,EREC_TOTAL:8,EREC_ACTUAL:0},
  {NO:9,GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",AREA:"MW02",AREA_DESCR:"HC Dewpt. & Conden. Stabil.",PLAN_VALUE:68132.5742,PLAN_BU:657089,ACTUAL_VALUE:63211.77390000001,ACTUAL_BU:657089,PLAN_PROG:10.36,ACTUAL_PROG:9.61,DELTA:-0.75,FAB_START_TOTAL:8,FAB_START_ACTUAL:7,FAB_COMP_TOTAL:8,FAB_COMP_ACTUAL:7,PAINTING_TOTAL:8,PAINTING_ACTUAL:0,BLOCKASSY_TOTAL:3,BLOCKASSY_ACTUAL:0,EREC_TOTAL:8,EREC_ACTUAL:0},
  {NO:10,GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",AREA:"MW04",AREA_DESCR:"Power Gen.",PLAN_VALUE:31581.5185,PLAN_BU:213333,ACTUAL_VALUE:26415.0617,ACTUAL_BU:213333,PLAN_PROG:14.8,ACTUAL_PROG:12.38,DELTA:-2.42,FAB_START_TOTAL:3,FAB_START_ACTUAL:3,FAB_COMP_TOTAL:3,FAB_COMP_ACTUAL:3,PAINTING_TOTAL:3,PAINTING_ACTUAL:0,BLOCKASSY_TOTAL:1,BLOCKASSY_ACTUAL:1,EREC_TOTAL:3,EREC_ACTUAL:0},
  {NO:11,GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",AREA:"MW05",AREA_DESCR:"Utility",PLAN_VALUE:46154.5116,PLAN_BU:378295,ACTUAL_VALUE:39592.976,ACTUAL_BU:378295,PLAN_PROG:12.2,ACTUAL_PROG:10.46,DELTA:-1.74,FAB_START_TOTAL:8,FAB_START_ACTUAL:8,FAB_COMP_TOTAL:8,FAB_COMP_ACTUAL:6,PAINTING_TOTAL:8,PAINTING_ACTUAL:0,BLOCKASSY_TOTAL:3,BLOCKASSY_ACTUAL:0,EREC_TOTAL:8,EREC_ACTUAL:0},
  {NO:12,GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES COMMON",AREA:"FL01",AREA_DESCR:"Flare Stack",PLAN_VALUE:0,PLAN_BU:200970,ACTUAL_VALUE:1191.6912,ACTUAL_BU:200970,PLAN_PROG:0,ACTUAL_PROG:0.59,DELTA:0.59,FAB_START_TOTAL:3,FAB_START_ACTUAL:0,FAB_COMP_TOTAL:3,FAB_COMP_ACTUAL:0,PAINTING_TOTAL:3,PAINTING_ACTUAL:0,BLOCKASSY_TOTAL:1,BLOCKASSY_ACTUAL:0,EREC_TOTAL:3,EREC_ACTUAL:0},
  {NO:13,GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES COMMON",AREA:"KO01",AREA_DESCR:"Flare KO Drum",PLAN_VALUE:0,PLAN_BU:135956,ACTUAL_VALUE:2431.9215,ACTUAL_BU:135956,PLAN_PROG:0,ACTUAL_PROG:1.78,DELTA:1.78,FAB_START_TOTAL:3,FAB_START_ACTUAL:3,FAB_COMP_TOTAL:3,FAB_COMP_ACTUAL:0,PAINTING_TOTAL:3,PAINTING_ACTUAL:0,BLOCKASSY_TOTAL:0,BLOCKASSY_ACTUAL:0,EREC_TOTAL:3,EREC_ACTUAL:0}
  ],    
}