import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 380,
      CanvasHeight: 330,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     

    DataItems: [
      { CDATE: '2020-01-17', DISC: 'PI', CUMULATE: '19117', PERIOD: '1116', },
      { CDATE: '2020-01-24', DISC: 'PI', CUMULATE: '18248', PERIOD: '869', },
      { CDATE: '2020-01-31', DISC: 'PI', CUMULATE: '17521', PERIOD: '727', },
      { CDATE: '2020-02-07', DISC: 'PI', CUMULATE: '16493', PERIOD: '1028', },
      { CDATE: '2020-02-14', DISC: 'PI', CUMULATE: '15576', PERIOD: '917', },
      { CDATE: '2020-02-21', DISC: 'PI', CUMULATE: '14426', PERIOD: '1150', },
      { CDATE: '2020-02-28', DISC: 'PI', CUMULATE: '13593', PERIOD: '833', },
      { CDATE: '2020-03-06', DISC: 'PI', CUMULATE: '13511', PERIOD: '82', },

    ],


    Queries: {
      SQL1: [
              { TYPE: 'A', LINK1: 'PI', DISC: 'Piping', TOTAL: 35215, ACTUAL: 21704, REMAIN: 13511, PROG: 61.63, },
      ],       
      
      
      
    },
}