import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1100,
      CanvasHeight: 100,
      CanvasBorderWeight: 0.5,
      CanvasBorderColor: '#ccc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Weight : ', x:20, y: 0, font: 'roboto', class: 'regular', size: 11, color: '#BCBCBC', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: 20, y: 0, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'end' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: 20,
          y: 0,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'end'
        },
      ]
    },
    DataItems: [
      {title:"W2022_05",MC_Complete:39},
      {title:"W2022_06",MC_Complete:45},
      {title:"W2022_07",MC_Complete:57},
      {title:"W2022_08",MC_Complete:64},
      {title:"W2022_09",MC_Complete:87},
      {title:"W2022_10",MC_Complete:91},
      {title:"W2022_11",MC_Complete:94},
      {title:"W2022_12",MC_Complete:107},
      {title:"W2022_13",MC_Complete:112},
      {title:"W2022_14",MC_Complete:112},
      {title:"W2022_15",MC_Complete:113},
      {title:"W2022_16",MC_Complete:133},
      {title:"W2022_17",MC_Complete:148},
      {title:"W2022_18",MC_Complete:152},
      {title:"W2022_19",MC_Complete:159},
      {title:"W2022_20",MC_Complete:194},
      {title:"W2022_21",MC_Complete:204},
      {title:"W2022_22",MC_Complete:228},
      {title:"W2022_23",MC_Complete:246},
      {title:"W2022_24",MC_Complete:261},
      {title:"W2022_25",MC_Complete:283},
      {title:"W2022_26",MC_Complete:318},
      {title:"W2022_27",MC_Complete:350},
      {title:"W2022_28",MC_Complete:381},
      {title:"W2022_29",MC_Complete:401}

      // {title:"W2021_39",Partial_MC:2,Full_MC:0},
      // {title:"W2021_40",Partial_MC:0,Full_MC:0},
      // {title:"W2021_41",Partial_MC:0,Full_MC:0},
      // {title:"W2021_42",Partial_MC:1,Full_MC:0},
      // {title:"W2021_43",Partial_MC:0,Full_MC:2},
      // {title:"W2021_44",Partial_MC:1,Full_MC:2},
      // {title:"W2021_45",Partial_MC:1,Full_MC:0},
      // {title:"W2021_46",Partial_MC:0,Full_MC:0},
      // {title:"W2021_47",Partial_MC:3,Full_MC:0},
      // {title:"W2021_48",Partial_MC:2,Full_MC:4},
      // {title:"W2021_49",Partial_MC:1,Full_MC:1},
      // {title:"W2021_50",Partial_MC:0,Full_MC:1},
      // {title:"W2021_51",Partial_MC:0,Full_MC:2},
      // {title:"W2021_52",Partial_MC:0,Full_MC:2},
      // {title:"W2022_01",Partial_MC:0,Full_MC:2},
      // {title:"W2022_02",Partial_MC:0,Full_MC:2},
      // {title:"W2022_03",Partial_MC:0,Full_MC:0},
      // {title:"W2022_04",Partial_MC:4,Full_MC:4},
      // {title:"W2022_05",Partial_MC:3,Full_MC:1},
      // {title:"W2022_06",Partial_MC:0,Full_MC:4},
      // {title:"W2022_07",Partial_MC:3,Full_MC:9},
      // {title:"W2022_08",Partial_MC:0,Full_MC:7},
      // {title:"W2022_09",Partial_MC:12,Full_MC:11},
      // {title:"W2022_10",Partial_MC:0,Full_MC:4}
      ]
    }