export default {
  Canvas: {
    CanvasWidth: 1600,
    CanvasHeight: 1120,
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',//0E1B2B
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 760,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },

  Queries: {
    SQL1:  [
      {TOTAL:40}
    ],
    SQL2:  [
      {SN:1,STAGE:"ETA at MOF/at Site",PLAN:1,ACTUAL:3},
      {SN:2,STAGE:"Installed",PLAN:0,ACTUAL:15}
    ]
  },

  Text: {
    Text: [
      { text: 'Note', x: 1350, y: 20, font: 'roboto', class: 'regular', size: 11, color: '#44A9DF', align: 'start' },
    ],
    Filter: [
      { name: 'MOD', x: 1350, y: 30, font: 'roboto', class: 'regular', size: 11, color: '#757575', align: 'start' },
    ],
    Sql: [
      {
        query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
        text: 'Data Source',
        x: 41,
        y: 115,
        font: 'roboto',
        style: 'regular',
        size: 10,
        color: '#757575',
        align: 'start'
      },
    ]
  },

  DataItems: [
    {SN:1,EQ_NAME:"Primary Fractionator",TAG_REPR:"2521T001",TAG_NO:"2521T001",CONST_STAGE:"CFL",PACKAGE:"P1",AREA:"OR",MOF_SHIP_NO:"Shipment 01",CRANE_MAIN:"4000",CRANE_TAIL:"1600"},
    {SN:2,EQ_NAME:"Quench Water tower",TAG_REPR:"2522T001",TAG_NO:"2522T001",CONST_STAGE:"CFL",PACKAGE:"P1",AREA:"OR",MOF_SHIP_NO:"Shipment 02",CRANE_MAIN:"4000",CRANE_TAIL:"1600"},
    {SN:3,EQ_NAME:"Demethanizer Prefractionator",TAG_REPR:"2540T001",TAG_NO:"2540T001,2540D015",CONST_STAGE:"CFL",PACKAGE:"P1",AREA:"OR",MOF_SHIP_NO:"Shipment 04",CRANE_MAIN:"4000",CRANE_TAIL:"1600"},
    {SN:4,EQ_NAME:"C2 Splitter",TAG_REPR:"2545T001",TAG_NO:"2545T001",CONST_STAGE:"CFL",PACKAGE:"P1",AREA:"OR",MOF_SHIP_NO:"Shipment 04",CRANE_MAIN:"4000",CRANE_TAIL:"1600"},
    {SN:5,EQ_NAME:"C3 Stripper",TAG_REPR:"2554T001",TAG_NO:"2554T001",CONST_STAGE:"CFL",PACKAGE:"P1",AREA:"OR",MOF_SHIP_NO:"Shipment 03",CRANE_MAIN:"4000",CRANE_TAIL:"1600"},
    {SN:6,EQ_NAME:"C3 Rectifier",TAG_REPR:"2554T002",TAG_NO:"2554T002",CONST_STAGE:"CFL",PACKAGE:"P1",AREA:"OR",MOF_SHIP_NO:"Shipment 03",CRANE_MAIN:"4000",CRANE_TAIL:"1600"},
    {SN:7,EQ_NAME:"Casutic tower",TAG_REPR:"2534T031",TAG_NO:"2534T031",CONST_STAGE:"DOL",PACKAGE:"P1",AREA:"OR",MOF_SHIP_NO:"Shipment 05",CRANE_MAIN:"1600",CRANE_TAIL:"400"},
    {SN:8,EQ_NAME:"HP Depropanizer",TAG_REPR:"2536T001",TAG_NO:"2536T001",CONST_STAGE:"DOL",PACKAGE:"P1",AREA:"OR",MOF_SHIP_NO:"Shipment 05",CRANE_MAIN:"1600",CRANE_TAIL:"400"},
    {SN:9,EQ_NAME:"LP Depropanizer",TAG_REPR:"2536T051",TAG_NO:"2536T051",CONST_STAGE:"DOL",PACKAGE:"P1",AREA:"OR",MOF_SHIP_NO:"Shipment 06",CRANE_MAIN:"1600",CRANE_TAIL:"400"},
    {SN:10,EQ_NAME:"Demethanizer",TAG_REPR:"2540T051",TAG_NO:"2540T051,2540D065",CONST_STAGE:"DOL",PACKAGE:"P1",AREA:"OR",MOF_SHIP_NO:"Shipment 09",CRANE_MAIN:"1600",CRANE_TAIL:"400"},
    {SN:11,EQ_NAME:"Main Washer",TAG_REPR:"3011T021",TAG_NO:"3011T021",CONST_STAGE:"DOL",PACKAGE:"P1",AREA:"BD",MOF_SHIP_NO:"Shipment 08",CRANE_MAIN:"1600",CRANE_TAIL:"400"},
    {SN:12,EQ_NAME:"Rectifier Afterwasher",TAG_REPR:"3011T022",TAG_NO:"3011T022",CONST_STAGE:"DOL",PACKAGE:"P1",AREA:"BD",MOF_SHIP_NO:"Shipment 08",CRANE_MAIN:"1600",CRANE_TAIL:"400"},
    {SN:13,EQ_NAME:"C4 Splitter",TAG_REPR:"3410T001",TAG_NO:"3410T001",CONST_STAGE:"DOL",PACKAGE:"P1",AREA:"OR",MOF_SHIP_NO:"Shipment 07",CRANE_MAIN:"1600",CRANE_TAIL:"400"},
    {SN:14,EQ_NAME:"Butene-1 Splitter",TAG_REPR:"3411T001",TAG_NO:"3411T001",CONST_STAGE:"DOL",PACKAGE:"P1",AREA:"OR",MOF_SHIP_NO:"Shipment 07",CRANE_MAIN:"1600",CRANE_TAIL:"400"},
    {SN:15,EQ_NAME:"GPPE Reactor-1",TAG_REPR:"5130R001",TAG_NO:"5130R001",CONST_STAGE:"DOL",PACKAGE:"P2",AREA:"GPPE",MOF_SHIP_NO:"Shipment 12",CRANE_MAIN:"1600",CRANE_TAIL:"400"},
    {SN:16,EQ_NAME:"GPPE Reactor-2",TAG_REPR:"5230R001",TAG_NO:"5230R001",CONST_STAGE:"DOL",PACKAGE:"P2",AREA:"GPPE",MOF_SHIP_NO:"Shipment 12",CRANE_MAIN:"1600",CRANE_TAIL:"400"},
    {SN:17,EQ_NAME:"CRACKED GAS DRYERS A/B/C",TAG_REPR:"2535DR010A",TAG_NO:"2535DR010A/B/C",CONST_STAGE:"DOL",PACKAGE:"P1",AREA:"OR",MOF_SHIP_NO:"Shipment 18",CRANE_MAIN:"800",CRANE_TAIL:"280"},
    {SN:18,EQ_NAME:"RESIDUE GAS RECTIFIER",TAG_REPR:"2540T030",TAG_NO:"2540T030,2540D030",CONST_STAGE:"DOL",PACKAGE:"P1",AREA:"OR",MOF_SHIP_NO:"Shipment 09",CRANE_MAIN:"800",CRANE_TAIL:"280"},
    {SN:19,EQ_NAME:"DEETHANIZER",TAG_REPR:"2544T001",TAG_NO:"2544T001",CONST_STAGE:"DOL",PACKAGE:"P1",AREA:"OR",MOF_SHIP_NO:"Shipment 09",CRANE_MAIN:"800",CRANE_TAIL:"280"},
    {SN:20,EQ_NAME:"LLP Stream Flare Drum",TAG_REPR:"2580D002",TAG_NO:"2580D002",CONST_STAGE:"DOL",PACKAGE:"P1",AREA:"OR",MOF_SHIP_NO:"In Land Transport",CRANE_MAIN:"800",CRANE_TAIL:"280"},
    {SN:21,EQ_NAME:"Degasser",TAG_REPR:"3012T031",TAG_NO:"3012T031",CONST_STAGE:"DOL",PACKAGE:"P1",AREA:"BD",MOF_SHIP_NO:"Shipment 11",CRANE_MAIN:"800",CRANE_TAIL:"280"},
    {SN:22,EQ_NAME:"COOLING COLUMN",TAG_REPR:"3012T034",TAG_NO:"3012T034",CONST_STAGE:"CFL",PACKAGE:"P1",AREA:"BD",MOF_SHIP_NO:"In Land Transport",CRANE_MAIN:"800",CRANE_TAIL:"280"},
    {SN:23,EQ_NAME:"PROPYNE COLUMN",TAG_REPR:"3013T041",TAG_NO:"3013T041",CONST_STAGE:"CFL",PACKAGE:"P1",AREA:"BD",MOF_SHIP_NO:"In Land Transport",CRANE_MAIN:"800",CRANE_TAIL:"280"},
    {SN:24,EQ_NAME:"BUTADIENE COLUMN",TAG_REPR:"3013T045",TAG_NO:"3013T045",CONST_STAGE:"CFL",PACKAGE:"P1",AREA:"BD",MOF_SHIP_NO:"Shipment 11",CRANE_MAIN:"800",CRANE_TAIL:"280"},
    {SN:25,EQ_NAME:"WATER WASH COLUMN",TAG_REPR:"3016T001",TAG_NO:"3016T001",CONST_STAGE:"CFL",PACKAGE:"P1",AREA:"BD",MOF_SHIP_NO:"In Land Transport",CRANE_MAIN:"800",CRANE_TAIL:"280"},
    {SN:26,EQ_NAME:"HD1 DEPENTANIZER",TAG_REPR:"3620T001",TAG_NO:"3620T001",CONST_STAGE:"DO",PACKAGE:"P1",AREA:"GHU",MOF_SHIP_NO:"Shipment 13",CRANE_MAIN:"800",CRANE_TAIL:"280"},
    {SN:27,EQ_NAME:"RERUN TOWER",TAG_REPR:"3620T011",TAG_NO:"3620T011",CONST_STAGE:"DO",PACKAGE:"P1",AREA:"GHU",MOF_SHIP_NO:"Shipment 13",CRANE_MAIN:"800",CRANE_TAIL:"280"},
    {SN:28,EQ_NAME:"HD2 Depentanizer",TAG_REPR:"3640T001",TAG_NO:"3640T001",CONST_STAGE:"DO",PACKAGE:"P1",AREA:"GHU",MOF_SHIP_NO:"Shipment 13",CRANE_MAIN:"800",CRANE_TAIL:"280"},
    {SN:29,EQ_NAME:"DETOLUENIZER",TAG_REPR:"3640T011",TAG_NO:"3640T011",CONST_STAGE:"DO",PACKAGE:"P1",AREA:"GHU",MOF_SHIP_NO:"In Land Transport",CRANE_MAIN:"800",CRANE_TAIL:"280"},
    {SN:30,EQ_NAME:"Extractive Distillation Column",TAG_REPR:"3810T010",TAG_NO:"3810T010",CONST_STAGE:"DOL",PACKAGE:"P1",AREA:"BTX",MOF_SHIP_NO:"Shipment 16",CRANE_MAIN:"800",CRANE_TAIL:"280"},
    {SN:31,EQ_NAME:"Recovery Column",TAG_REPR:"3810T020",TAG_NO:"3810T020",CONST_STAGE:"DO",PACKAGE:"P1",AREA:"BTX",MOF_SHIP_NO:"Shipment 16",CRANE_MAIN:"800",CRANE_TAIL:"280"},
    {SN:32,EQ_NAME:"Benzene Column",TAG_REPR:"3820T010",TAG_NO:"3820T010",CONST_STAGE:"EFL",PACKAGE:"P1",AREA:"BTX",MOF_SHIP_NO:"Shipment 16",CRANE_MAIN:"800",CRANE_TAIL:"280"},
    {SN:33,EQ_NAME:"Toluene Column",TAG_REPR:"3820T020",TAG_NO:"3820T020",CONST_STAGE:"EFL",PACKAGE:"P1",AREA:"BTX",MOF_SHIP_NO:"Shipment 16",CRANE_MAIN:"800",CRANE_TAIL:"280"},
    {SN:34,EQ_NAME:"Purger Bin (Purge Bin #1)",TAG_REPR:"5240D001",TAG_NO:"5240D001",CONST_STAGE:"EFL",PACKAGE:"P2",AREA:"GPPE",MOF_SHIP_NO:"Shipment 10",CRANE_MAIN:"800",CRANE_TAIL:"280"},
    {SN:35,EQ_NAME:"Purger Bin (Purge Bin #2)",TAG_REPR:"5240D001",TAG_NO:"5240D001",CONST_STAGE:"EFL",PACKAGE:"P2",AREA:"GPPE",MOF_SHIP_NO:"Shipment 10",CRANE_MAIN:"800",CRANE_TAIL:"280"},
    {SN:36,EQ_NAME:"First Loop Reactor",TAG_REPR:"6130R011",TAG_NO:"6130R011",CONST_STAGE:"EFL",PACKAGE:"P2",AREA:"PP",MOF_SHIP_NO:"In Land Transport",CRANE_MAIN:"800",CRANE_TAIL:"280"},
    {SN:37,EQ_NAME:"Second Loop Reactor",TAG_REPR:"6130R012",TAG_NO:"6130R012",CONST_STAGE:"EFL",PACKAGE:"P2",AREA:"PP",MOF_SHIP_NO:"In Land Transport",CRANE_MAIN:"800",CRANE_TAIL:"280"},
    {SN:38,EQ_NAME:"1st Gas Phase Reactor",TAG_REPR:"6130R024",TAG_NO:"6130R024",CONST_STAGE:"DOL",PACKAGE:"P2",AREA:"PP",MOF_SHIP_NO:"Shipment 19",CRANE_MAIN:"800",CRANE_TAIL:"280"},
    {SN:39,EQ_NAME:"2nd Gas Phase Reactor",TAG_REPR:"6130R025",TAG_NO:"6130R025",CONST_STAGE:"DOL",PACKAGE:"P2",AREA:"PP",MOF_SHIP_NO:"Shipment 19",CRANE_MAIN:"800",CRANE_TAIL:"280"},
    {SN:40,EQ_NAME:"COLD BOX",TAG_REPR:"COLD_BOX",TAG_NO:"COLD_BOX",CONST_STAGE:"DOL",PACKAGE:"P1",AREA:"OR",MOF_SHIP_NO:"In Land Transport",CRANE_MAIN:"800",CRANE_TAIL:"280"}
  ],
}