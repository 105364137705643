import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 600,
      CanvasHeight: 600,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 380,
      CanvasChartHeight: 280,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Weight : ', x:240, y: 220, font: 'roboto', class: 'regular', size: 11, color: '#BCBCBC', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: 350, y: 200, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'end' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: 350,
          y: 220,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'end'
        },
      ]
    },
    DataItems: [
      {REQ_DESCR:"Instrument Air Compressor and Dryer Package (HULL & LQ) - Air Compressor",OWL_TOTAL:30,OWL_CLOSED:0,OWL_OPEN:30,PO_CODE:"PME907"},
      {REQ_DESCR:"Fresh and potable water package",OWL_TOTAL:20,OWL_CLOSED:0,OWL_OPEN:20,OVER_DUE:0,PO_CODE:"PME906A"},
      {REQ_DESCR:"Key service generator",OWL_TOTAL:46,OWL_CLOSED:30,OWL_OPEN:16,OVER_DUE:0,PO_CODE:"PME914"},
      {REQ_DESCR:"Cargo Pump Package (Including Ballast pump,Other hydraulic pumps)",OWL_TOTAL:22,OWL_CLOSED:7,OWL_OPEN:15,OVER_DUE:0,PO_CODE:"PME901"},
      {REQ_DESCR:"Foam Package",OWL_TOTAL:8,OWL_CLOSED:0,OWL_OPEN:8,OVER_DUE:0,PO_CODE:"PME903"},
      {REQ_DESCR:"HVAC package (in Hull Machanical Space)",OWL_TOTAL:8,OWL_CLOSED:0,OWL_OPEN:8,OVER_DUE:55,PO_CODE:"PHV901"},
      {REQ_DESCR:"Sewage treatment package",OWL_TOTAL:7,OWL_CLOSED:0,OWL_OPEN:7,PO_CODE:"PME905"},
      {REQ_DESCR:"Diesel Oil Purifier Package",OWL_TOTAL:18,OWL_CLOSED:12,OWL_OPEN:6,OVER_DUE:0,PO_CODE:"PME904"},
      {REQ_DESCR:"Mooring Fairlead and Chain Stopper",OWL_TOTAL:6,OWL_CLOSED:0,OWL_OPEN:6,PO_CODE:"PNA901"},
      {REQ_DESCR:"Hull Pumps (Non API - Horizontal type)",OWL_TOTAL:4,OWL_CLOSED:0,OWL_OPEN:4,PO_CODE:"PME915"},
      {REQ_DESCR:"Water Mist system",OWL_TOTAL:4,OWL_CLOSED:0,OWL_OPEN:4,PO_CODE:"PME910"},
      {REQ_DESCR:"Exchanger",OWL_TOTAL:4,OWL_CLOSED:0,OWL_OPEN:4,OVER_DUE:0,PO_CODE:"PME918"},
      {REQ_DESCR:"Galley Equipment",OWL_TOTAL:3,OWL_CLOSED:0,OWL_OPEN:3,OVER_DUE:189,PO_CODE:"PAR918"},
      {REQ_DESCR:"Laundry Equipment",OWL_TOTAL:3,OWL_CLOSED:0,OWL_OPEN:3,OVER_DUE:90,PO_CODE:"PAR903"},
      {REQ_DESCR:"Garbage Compactor",OWL_TOTAL:3,OWL_CLOSED:0,OWL_OPEN:3,PO_CODE:"PME922"},
      {REQ_DESCR:"Air Receiver",OWL_TOTAL:3,OWL_CLOSED:0,OWL_OPEN:3,OVER_DUE:0,PO_CODE:"PME920"},
      {REQ_DESCR:"Hull API Pumps",OWL_TOTAL:2,OWL_CLOSED:0,OWL_OPEN:2,PO_CODE:"PME916"}
    ],
}