import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1500,
      CanvasHeight: 800,
      CanvasBorderWeight: 0.3,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 310,
      CanvasChartHeight: 270,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: 'Transparent',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },

    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },
    Queries: {
      SQL1: [
        {CUTOFF:"2022-07-01"}
      ],
    },

    DataItems: [
      {MR_NO:"75231-P1-1279-FF-R-E-3801",MR_DESCR:"Monitor",PSRNUM:"P1_PSR",TAG_TOTAL:0,QTY:0,RAS_ROS:"2023-08-10",WORK_TYPE_SEQ:1,WORK_TYPE_CODE:"PP",WORK_TYPE_DESCR:"Purchasing Plan to Owner",PLAN:"2022-07-21",FORECAST:"2022-07-21",PLAN_WK:"2022-07-22",FORECAST_WK:"2022-07-22"},
      {MR_NO:"75231-P1-1279-FF-R-E-3801",MR_DESCR:"Monitor",PSRNUM:"P1_PSR",TAG_TOTAL:0,QTY:0,RAS_ROS:"2023-08-10",WORK_TYPE_SEQ:2,WORK_TYPE_CODE:"PPA",WORK_TYPE_DESCR:"Purchasing Plan approved by Owner",PLAN:"2022-07-31",FORECAST:"2022-07-31",PLAN_WK:"2022-08-05",FORECAST_WK:"2022-08-05"},
      {MR_NO:"75231-P1-1279-FF-R-E-3801",MR_DESCR:"Monitor",PSRNUM:"P1_PSR",TAG_TOTAL:0,QTY:0,RAS_ROS:"2023-08-10",WORK_TYPE_SEQ:3,WORK_TYPE_CODE:"MR",WORK_TYPE_DESCR:"MR Received by SEI Procurement",PLAN:"2022-08-03",FORECAST:"2022-08-10",PLAN_WK:"2022-08-05",FORECAST_WK:"2022-08-12"},
      {MR_NO:"75231-P1-1279-FF-R-E-3801",MR_DESCR:"Monitor",PSRNUM:"P1_PSR",TAG_TOTAL:0,QTY:0,RAS_ROS:"2023-08-10",WORK_TYPE_SEQ:4,WORK_TYPE_CODE:"RFQ",WORK_TYPE_DESCR:"RFQ Issue",PLAN:"2022-08-10",FORECAST:"2022-08-10",PLAN_WK:"2022-08-12",FORECAST_WK:"2022-08-12"},
      {MR_NO:"75231-P1-1279-FF-R-E-3801",MR_DESCR:"Monitor",PSRNUM:"P1_PSR",TAG_TOTAL:0,QTY:0,RAS_ROS:"2023-08-10",WORK_TYPE_SEQ:5,WORK_TYPE_CODE:"BC",WORK_TYPE_DESCR:"Bid Closing Date",PLAN:"2022-09-09",FORECAST:"2022-08-29",PLAN_WK:"2022-09-09",FORECAST_WK:"2022-09-02"},
      {MR_NO:"75231-P1-1279-FF-R-E-3801",MR_DESCR:"Monitor",PSRNUM:"P1_PSR",TAG_TOTAL:0,QTY:0,RAS_ROS:"2023-08-10",WORK_TYPE_SEQ:6,WORK_TYPE_CODE:"TBE",WORK_TYPE_DESCR:"TBE",PLAN:"2022-04-23",FORECAST:"2022-09-30",PLAN_WK:"2022-04-29",FORECAST_WK:"2022-09-30"},
      {MR_NO:"75231-P1-1279-FF-R-E-3801",MR_DESCR:"Monitor",PSRNUM:"P1_PSR",TAG_TOTAL:0,QTY:0,RAS_ROS:"2023-08-10",WORK_TYPE_SEQ:7,WORK_TYPE_CODE:"CBE",WORK_TYPE_DESCR:"CBE",PLAN:"2022-05-14",FORECAST:"2022-10-10",PLAN_WK:"2022-05-20",FORECAST_WK:"2022-10-14"},
      {MR_NO:"75231-P1-1279-FF-R-E-3801",MR_DESCR:"Monitor",PSRNUM:"P1_PSR",TAG_TOTAL:0,QTY:0,RAS_ROS:"2023-08-10",WORK_TYPE_SEQ:8,WORK_TYPE_CODE:"AR",WORK_TYPE_DESCR:"Award Recom. To Owner",PLAN:"2022-06-05",FORECAST:"2022-11-01",PLAN_WK:"2022-06-10",FORECAST_WK:"2022-11-04"},
      {MR_NO:"75231-P1-1279-FF-R-E-3801",MR_DESCR:"Monitor",PSRNUM:"P1_PSR",TAG_TOTAL:0,QTY:0,RAS_ROS:"2023-08-10",WORK_TYPE_SEQ:9,WORK_TYPE_CODE:"ARA",WORK_TYPE_DESCR:"Award Recom. Approved by Owner",PLAN:"2022-06-08",FORECAST:"2022-11-04",PLAN_WK:"2022-06-10",FORECAST_WK:"2022-11-04"},
      {MR_NO:"75231-P1-1279-FF-R-E-3801",MR_DESCR:"Monitor",PSRNUM:"P1_PSR",TAG_TOTAL:0,QTY:0,RAS_ROS:"2023-08-10",WORK_TYPE_SEQ:10,WORK_TYPE_CODE:"LOA",WORK_TYPE_DESCR:"LOA Issue",PLAN:"2022-06-23",FORECAST:"2022-11-07",PLAN_WK:"2022-06-24",FORECAST_WK:"2022-11-11"},
      {MR_NO:"75231-P1-1279-FF-R-E-3801",MR_DESCR:"Monitor",PSRNUM:"P1_PSR",TAG_TOTAL:0,QTY:0,RAS_ROS:"2023-08-10",WORK_TYPE_SEQ:11,WORK_TYPE_CODE:"PO",WORK_TYPE_DESCR:"PO Issue",PLAN:"2022-08-04",FORECAST:"2022-12-19",PLAN_WK:"2022-08-05",FORECAST_WK:"2022-12-23"},
      {MR_NO:"75231-P1-1279-FF-R-E-3801",MR_DESCR:"Monitor",PSRNUM:"P1_PSR",TAG_TOTAL:0,QTY:0,RAS_ROS:"2023-08-10",WORK_TYPE_SEQ:12,WORK_TYPE_CODE:"MDI",WORK_TYPE_DESCR:"Main Drawing Issue",PLAN:"2022-09-13",FORECAST:"2023-01-02",PLAN_WK:"2022-09-16",FORECAST_WK:"2023-01-06"},
      {MR_NO:"75231-P1-1279-FF-R-E-3801",MR_DESCR:"Monitor",PSRNUM:"P1_PSR",TAG_TOTAL:0,QTY:0,RAS_ROS:"2023-08-10",WORK_TYPE_SEQ:13,WORK_TYPE_CODE:"MO",WORK_TYPE_DESCR:"Material Order"},
      {MR_NO:"75231-P1-1279-FF-R-E-3801",MR_DESCR:"Monitor",PSRNUM:"P1_PSR",TAG_TOTAL:0,QTY:0,RAS_ROS:"2023-08-10",WORK_TYPE_SEQ:14,WORK_TYPE_CODE:"MMR",WORK_TYPE_DESCR:"Main Material Received"},
      {MR_NO:"75231-P1-1279-FF-R-E-3801",MR_DESCR:"Monitor",PSRNUM:"P1_PSR",TAG_TOTAL:0,QTY:0,RAS_ROS:"2023-08-10",WORK_TYPE_SEQ:15,WORK_TYPE_CODE:"FI",WORK_TYPE_DESCR:"Final Inspection",PLAN:"2023-03-20",FORECAST:"2023-08-04",PLAN_WK:"2023-03-24",FORECAST_WK:"2023-08-04"},
      {MR_NO:"75231-P1-1279-FF-R-E-3801",MR_DESCR:"Monitor",PSRNUM:"P1_PSR",TAG_TOTAL:0,QTY:0,RAS_ROS:"2023-08-10",WORK_TYPE_SEQ:16,WORK_TYPE_CODE:"EW",WORK_TYPE_DESCR:"Ex-work",PLAN:"2023-04-19",FORECAST:"2023-09-03",PLAN_WK:"2023-04-21",FORECAST_WK:"2023-09-08"},
      {MR_NO:"75231-P1-1279-FF-R-E-3801",MR_DESCR:"Monitor",PSRNUM:"P1_PSR",TAG_TOTAL:0,QTY:0,RAS_ROS:"2023-08-10",WORK_TYPE_SEQ:17,WORK_TYPE_CODE:"SA",WORK_TYPE_DESCR:"Shipping Arrival at Destination Port",PLAN:"2023-06-18",FORECAST:"2023-11-02",PLAN_WK:"2023-06-23",FORECAST_WK:"2023-11-03"},
      {MR_NO:"75231-P1-1279-FF-R-E-3801",MR_DESCR:"Monitor",PSRNUM:"P1_PSR",TAG_TOTAL:0,QTY:0,RAS_ROS:"2023-08-10",WORK_TYPE_SEQ:18,WORK_TYPE_CODE:"CC",WORK_TYPE_DESCR:"Custom Clearance",PLAN:"2023-07-02",FORECAST:"2023-11-16",PLAN_WK:"2023-07-07",FORECAST_WK:"2023-11-17"},
      {MR_NO:"75231-P1-1279-FF-R-E-3801",MR_DESCR:"Monitor",PSRNUM:"P1_PSR",TAG_TOTAL:0,QTY:0,RAS_ROS:"2023-08-10",WORK_TYPE_SEQ:19,WORK_TYPE_CODE:"DJS",WORK_TYPE_DESCR:"Delivery to Job Site",PLAN:"2023-11-23",PLAN_WK:"2023-11-24"}
      ]
    
}