import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 1380,
    CanvasHeight: 620,
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 1,

    CanvasChartX: 50,
    CanvasChartY: 50,
    CanvasChartWidth: 480,
    CanvasChartHeight: 250,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  // DataItems:  [
  //   {SUBSYSTEM:"21-1001-1001-01",SUBSYSTEM_DESCR:"Annulus Riser vent Monitoring System",LOSH:"Y",MC_B30:"2022-07-28",A_OV_TOT:160,A_OV_COM:15,A_OV_REM:145,A_OV_PROG:9.37,A_OV_STATUS:"less than 70%",A_PG:9.37,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:33,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-1010-1011-01",SUBSYSTEM_DESCR:"Flowline 1 and Receiver",LOSH:"Y",MC_B30:"2022-07-07",A_OV_TOT:388,A_OV_COM:55,A_OV_REM:333,A_OV_PROG:14.17,A_OV_STATUS:"less than 70%",A_PG:14.17,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,ACT_TAG_VALID:206,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-1010-1012-01",SUBSYSTEM_DESCR:"Flowline 2 and Receiver",LOSH:"Y",MC_B30:"2022-07-07",A_OV_TOT:378,A_OV_COM:54,A_OV_REM:324,A_OV_PROG:14.28,A_OV_STATUS:"less than 70%",A_PG:14.28,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:201,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-1090-1090-01",SUBSYSTEM_DESCR:"Production Control Umbilical",MC_B30:"2022-04-28",A_OV_TOT:27,A_OV_COM:0,A_OV_REM:27,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-1510-1510-01",SUBSYSTEM_DESCR:"MEG Riser and Pipeline System to DC1",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:33,A_OV_COM:1,A_OV_REM:32,A_OV_PROG:3.03,A_OV_STATUS:"less than 70%",A_PG:3.03,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-1600-1611-01",SUBSYSTEM_DESCR:"Gas Export Riser 1",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:65,A_OV_COM:7,A_OV_REM:58,A_OV_PROG:10.76,A_OV_STATUS:"less than 70%",A_PG:10.76,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:36,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-1600-1612-01",SUBSYSTEM_DESCR:"Gas Export Riser 2",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:65,A_OV_COM:7,A_OV_REM:58,A_OV_PROG:10.76,A_OV_STATUS:"less than 70%",A_PG:10.76,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:36,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-1911-1911-01",SUBSYSTEM_DESCR:"Hydraulic HPUs, RIOs and TUTUs",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:120,A_OV_COM:7,A_OV_REM:113,A_OV_PROG:5.83,A_OV_STATUS:"less than 70%",A_PG:5.83,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:89,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-1931-1931-01",SUBSYSTEM_DESCR:"Subsea Control System ,MCS, SSIV SPCU, EPUs, HVE JBs and LVE JBs and Subsea ICSS",MC_B30:"2022-06-30",A_OV_TOT:97,A_OV_COM:2,A_OV_REM:95,A_OV_PROG:2.06,A_OV_STATUS:"less than 70%",A_PG:2.06,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,PB2_OV_TOT:11,PB2_OV_COM:0,PB2_OV_REM:11,ACT_TAG_VALID:95,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-2200-2131-01",SUBSYSTEM_DESCR:"Slug Catcher 1 and Liquid Heater 1",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:558,A_OV_COM:128,A_OV_REM:430,A_OV_PROG:22.93,A_OV_STATUS:"less than 70%",A_PG:22.93,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P3",PB1_OV_TOT:6,PB1_OV_COM:1,PB1_OV_REM:5,PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:269,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-2200-2132-01",SUBSYSTEM_DESCR:"Slug Catcher 2 and Liquid heater 2",LOSH:"Y",MC_B30:"2022-07-07",A_OV_TOT:545,A_OV_COM:129,A_OV_REM:416,A_OV_PROG:23.66,A_OV_STATUS:"less than 70%",A_PG:23.66,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:257,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-2200-2221-01",SUBSYSTEM_DESCR:"MP Separator",LOSH:"Y",MC_B30:"2022-07-14",A_OV_TOT:311,A_OV_COM:75,A_OV_REM:236,A_OV_PROG:24.11,A_OV_STATUS:"less than 70%",A_PG:24.11,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,ACT_TAG_VALID:134,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-2200-2230-01",SUBSYSTEM_DESCR:"LP Separator",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:267,A_OV_COM:70,A_OV_REM:197,A_OV_PROG:26.21,A_OV_STATUS:"less than 70%",A_PG:26.21,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:102,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-2200-2230-02",SUBSYSTEM_DESCR:"Interstage Heaters",LOSH:"Y",MC_B30:"2022-06-16",A_OV_TOT:117,A_OV_COM:41,A_OV_REM:76,A_OV_PROG:35.04,A_OV_STATUS:"less than 70%",A_PG:35.04,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:31,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-2200-2240-01",SUBSYSTEM_DESCR:"LLP separator, Condensate Coolers and Condensate Coalescer Pumps",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:415,A_OV_COM:99,A_OV_REM:316,A_OV_PROG:23.85,A_OV_STATUS:"less than 70%",A_PG:23.85,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:0,PA_OV_REM:3,ACT_TAG_VALID:167,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-2200-2240-03",SUBSYSTEM_DESCR:"Condensate Coalescer Filters",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:161,A_OV_COM:59,A_OV_REM:102,A_OV_PROG:36.64,A_OV_STATUS:"less than 70%",A_PG:36.64,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:4,PA_OV_COM:1,PA_OV_REM:3,ACT_TAG_VALID:49,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-2200-2240-04",SUBSYSTEM_DESCR:"Condensate Coalescer Package",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:185,A_OV_COM:41,A_OV_REM:144,A_OV_PROG:22.16,A_OV_STATUS:"less than 70%",A_PG:22.16,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:4,PB1_OV_COM:3,PB1_OV_REM:1,ACT_TAG_VALID:65,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-2200-2470-01",SUBSYSTEM_DESCR:"Warm Condensate Heater, Offspec Condensate Pump and Distribution",LOSH:"Y",MC_B30:"2022-07-14",A_OV_TOT:288,A_OV_COM:37,A_OV_REM:251,A_OV_PROG:12.84,A_OV_STATUS:"less than 70%",A_PG:12.84,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:139,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-2400-2440-01",SUBSYSTEM_DESCR:"Condensate Metering",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:145,A_OV_COM:5,A_OV_REM:140,A_OV_PROG:3.44,A_OV_STATUS:"less than 70%",A_PG:3.44,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:7,PA_OV_COM:1,PA_OV_REM:6,PB1_OV_TOT:18,PB1_OV_COM:5,PB1_OV_REM:13,PB2_OV_TOT:16,PB2_OV_COM:0,PB2_OV_REM:16,ACT_TAG_VALID:90,PUNCH_NUMBER_VALID:41,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-2400-2450-01",SUBSYSTEM_DESCR:"CFW1 Offspec Condensate Tank and Pumps ,Incl Washing Machine",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:381,A_OV_COM:140,A_OV_REM:241,A_OV_PROG:36.74,A_OV_STATUS:"less than 70%",A_PG:36.74,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:3,PB1_OV_COM:2,PB1_OV_REM:1,PB2_OV_TOT:2,PB2_OV_COM:1,PB2_OV_REM:1,ACT_TAG_VALID:140,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-2400-2460-01",SUBSYSTEM_DESCR:"Condensate Offloading",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:197,A_OV_COM:12,A_OV_REM:185,A_OV_PROG:6.09,A_OV_STATUS:"less than 70%",A_PG:6.09,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:7,PB1_OV_COM:5,PB1_OV_REM:2,PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:109,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:4},
  //   {SUBSYSTEM:"21-2400-9360-01",SUBSYSTEM_DESCR:"Cargo Oil Washing Header and Distribution",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:30,A_OV_COM:4,A_OV_REM:26,A_OV_PROG:13.33,A_OV_STATUS:"less than 70%",A_PG:13.33,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:0},
  //   {SUBSYSTEM:"21-2400-9360-02",SUBSYSTEM_DESCR:"Condensate ImportandLoading Header",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:97,A_OV_COM:12,A_OV_REM:85,A_OV_PROG:12.37,A_OV_STATUS:"less than 70%",A_PG:12.37,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,ACT_TAG_VALID:53,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-2400-9360-03",SUBSYSTEM_DESCR:"Condensate Transfer Header",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:394,A_OV_COM:57,A_OV_REM:337,A_OV_PROG:14.46,A_OV_STATUS:"less than 70%",A_PG:14.46,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,ACT_TAG_VALID:229,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-2400-9360-04",SUBSYSTEM_DESCR:"Condensate ExportandOffloading Header",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:240,A_OV_COM:38,A_OV_REM:202,A_OV_PROG:15.83,A_OV_STATUS:"less than 70%",A_PG:15.83,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:110,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-2400-9361-01",SUBSYSTEM_DESCR:"COW1 Tank and Pumps ,Incl Washing Machine",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:192,A_OV_COM:93,A_OV_REM:99,A_OV_PROG:48.43,A_OV_STATUS:"less than 70%",A_PG:48.43,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,PB3_OV_TOT:6,PB3_OV_COM:0,PB3_OV_REM:6,ACT_TAG_VALID:63,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:0},
  //   {SUBSYSTEM:"21-2400-9362-01",SUBSYSTEM_DESCR:"COW2 Tank and Pumps ,Incl Washing Machine",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:186,A_OV_COM:90,A_OV_REM:96,A_OV_PROG:48.38,A_OV_STATUS:"less than 70%",A_PG:48.38,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B3",PRIORITY:"P2",PB3_OV_TOT:1,PB3_OV_COM:0,PB3_OV_REM:1,ACT_TAG_VALID:62,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0},
  //   {SUBSYSTEM:"21-2400-9363-01",SUBSYSTEM_DESCR:"COW3 Tank and Pumps ,Incl Washing Machine",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:191,A_OV_COM:91,A_OV_REM:100,A_OV_PROG:47.64,A_OV_STATUS:"less than 70%",A_PG:47.64,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:0,PA_OV_REM:3,PB3_OV_TOT:7,PB3_OV_COM:0,PB3_OV_REM:7,ACT_TAG_VALID:63,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-2400-9364-01",SUBSYSTEM_DESCR:"COW4 Tank and Pumps ,Incl Washing Machine",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:185,A_OV_COM:95,A_OV_REM:90,A_OV_PROG:51.35,A_OV_STATUS:"less than 70%",A_PG:51.35,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:6,PA_OV_COM:0,PA_OV_REM:6,PB3_OV_TOT:8,PB3_OV_COM:0,PB3_OV_REM:8,ACT_TAG_VALID:62,PUNCH_NUMBER_VALID:14,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-2400-9365-01",SUBSYSTEM_DESCR:"COE1 Tank and Pumps ,Incl Washing Machine",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:178,A_OV_COM:85,A_OV_REM:93,A_OV_PROG:47.75,A_OV_STATUS:"less than 70%",A_PG:47.75,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:61,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-2400-9366-01",SUBSYSTEM_DESCR:"COE2 Tank and Pumps ,Incl Washing Machine",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:172,A_OV_COM:88,A_OV_REM:84,A_OV_PROG:51.16,A_OV_STATUS:"less than 70%",A_PG:51.16,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PB2_OV_TOT:8,PB2_OV_COM:0,PB2_OV_REM:8,ACT_TAG_VALID:60,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:0},
  //   {SUBSYSTEM:"21-2400-9367-01",SUBSYSTEM_DESCR:"COE3 Tank and Pumps ,Incl Washing Machine",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:172,A_OV_COM:91,A_OV_REM:81,A_OV_PROG:52.9,A_OV_STATUS:"less than 70%",A_PG:52.9,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PB2_OV_TOT:5,PB2_OV_COM:0,PB2_OV_REM:5,ACT_TAG_VALID:60,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:0},
  //   {SUBSYSTEM:"21-2400-9368-01",SUBSYSTEM_DESCR:"COE4 Tank and Pumps ,Incl Washing Machine",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:172,A_OV_COM:89,A_OV_REM:83,A_OV_PROG:51.74,A_OV_STATUS:"less than 70%",A_PG:51.74,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:6,PA_OV_COM:0,PA_OV_REM:6,PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:60,PUNCH_NUMBER_VALID:9,PUNCH_TAG_VALID:0},
  //   {SUBSYSTEM:"21-2500-2510-01",SUBSYSTEM_DESCR:"Rich MEG Collection, Flash Drum and Coolers",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:152,A_OV_COM:48,A_OV_REM:104,A_OV_PROG:31.57,A_OV_STATUS:"less than 70%",A_PG:31.57,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:49,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-2500-2510-02",SUBSYSTEM_DESCR:"MEG Transfer Header",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:69,A_OV_COM:7,A_OV_REM:62,A_OV_PROG:10.14,A_OV_STATUS:"less than 70%",A_PG:10.14,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:42,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-2500-2510-04",SUBSYSTEM_DESCR:"Salty Rich MEG Import Header",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:54,A_OV_COM:8,A_OV_REM:46,A_OV_PROG:14.81,A_OV_STATUS:"less than 70%",A_PG:14.81,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:23,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-2500-2510-05",SUBSYSTEM_DESCR:"Salty Rich MEG Export Header",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:61,A_OV_COM:9,A_OV_REM:52,A_OV_PROG:14.75,A_OV_STATUS:"less than 70%",A_PG:14.75,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:32,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-2500-2510-06",SUBSYSTEM_DESCR:"Rich MEG Header to MEG Regeneration",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:47,A_OV_COM:6,A_OV_REM:41,A_OV_PROG:12.76,A_OV_STATUS:"less than 70%",A_PG:12.76,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-2500-2511-01",SUBSYSTEM_DESCR:"MRW1 Rich MEG Storage and Pumps",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:254,A_OV_COM:100,A_OV_REM:154,A_OV_PROG:39.37,A_OV_STATUS:"less than 70%",A_PG:39.37,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:5,PB1_OV_COM:5,PB1_OV_REM:0,ACT_TAG_VALID:89,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-2500-2512-01",SUBSYSTEM_DESCR:"MSE1 Salty Rich MEG Storage and Pumps",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:212,A_OV_COM:102,A_OV_REM:110,A_OV_PROG:48.11,A_OV_STATUS:"less than 70%",A_PG:48.11,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:4,PB1_OV_COM:3,PB1_OV_REM:1,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:69,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-2500-2513-01",SUBSYSTEM_DESCR:"MSW2 Salty rich MEG storage and pumps",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:156,A_OV_COM:76,A_OV_REM:80,A_OV_PROG:48.71,A_OV_STATUS:"less than 70%",A_PG:48.71,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:9,PA_OV_COM:0,PA_OV_REM:9,PB3_OV_TOT:3,PB3_OV_COM:0,PB3_OV_REM:3,ACT_TAG_VALID:49,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:0},
  //   {SUBSYSTEM:"21-2500-2514-01",SUBSYSTEM_DESCR:"MSE2 Salty Rich MEG Storage and Pumps",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:170,A_OV_COM:82,A_OV_REM:88,A_OV_PROG:48.23,A_OV_STATUS:"less than 70%",A_PG:48.23,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:51,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-2500-2530-01",SUBSYSTEM_DESCR:"MEG Regeneration",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:307,A_OV_COM:38,A_OV_REM:269,A_OV_PROG:12.37,A_OV_STATUS:"less than 70%",A_PG:12.37,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:10,PA_OV_COM:1,PA_OV_REM:9,PB1_OV_TOT:13,PB1_OV_COM:3,PB1_OV_REM:10,ACT_TAG_VALID:191,PUNCH_NUMBER_VALID:23,PUNCH_TAG_VALID:12},
  //   {SUBSYSTEM:"21-2500-2530-02",SUBSYSTEM_DESCR:"Lean MEG from MEG Regeneration to Storage",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:29,A_OV_COM:3,A_OV_REM:26,A_OV_PROG:10.34,A_OV_STATUS:"less than 70%",A_PG:10.34,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:13,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-2500-2551-01",SUBSYSTEM_DESCR:"MLW1 Lean MEG Storage and Pumps",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:279,A_OV_COM:101,A_OV_REM:178,A_OV_PROG:36.2,A_OV_STATUS:"less than 70%",A_PG:36.2,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:11,PA_OV_COM:0,PA_OV_REM:11,PB1_OV_TOT:4,PB1_OV_COM:4,PB1_OV_REM:0,PB2_OV_TOT:6,PB2_OV_COM:0,PB2_OV_REM:6,ACT_TAG_VALID:104,PUNCH_NUMBER_VALID:21,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-2500-2552-01",SUBSYSTEM_DESCR:"MLE1 Lean MEG Storage and Pumps",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:248,A_OV_COM:103,A_OV_REM:145,A_OV_PROG:41.53,A_OV_STATUS:"less than 70%",A_PG:41.53,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:9,PA_OV_COM:0,PA_OV_REM:9,PB2_OV_TOT:7,PB2_OV_COM:0,PB2_OV_REM:7,ACT_TAG_VALID:96,PUNCH_NUMBER_VALID:16,PUNCH_TAG_VALID:0},
  //   {SUBSYSTEM:"21-2500-2553-01",SUBSYSTEM_DESCR:"Lean MEG Filters and Break Tank",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:356,A_OV_COM:132,A_OV_REM:224,A_OV_PROG:37.07,A_OV_STATUS:"less than 70%",A_PG:37.07,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:96,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-2500-2553-02",SUBSYSTEM_DESCR:"Topsides Lean MEG Pumps and Distribution",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:235,A_OV_COM:38,A_OV_REM:197,A_OV_PROG:16.17,A_OV_STATUS:"less than 70%",A_PG:16.17,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,ACT_TAG_VALID:106,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-2500-2553-03",SUBSYSTEM_DESCR:"Subsea Lean MEG Pumps and Distribution",LOSH:"Y",MC_B30:"2022-06-23",A_OV_TOT:290,A_OV_COM:37,A_OV_REM:253,A_OV_PROG:12.75,A_OV_STATUS:"less than 70%",A_PG:12.75,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:21,PA_OV_COM:20,PA_OV_REM:1,PB1_OV_TOT:3,PB1_OV_COM:1,PB1_OV_REM:2,ACT_TAG_VALID:173,PUNCH_NUMBER_VALID:24,PUNCH_TAG_VALID:5},
  //   {SUBSYSTEM:"21-2500-2562-01",SUBSYSTEM_DESCR:"MEG Transfer from Bunkering Station",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:24,A_OV_COM:6,A_OV_REM:18,A_OV_PROG:25,A_OV_STATUS:"less than 70%",A_PG:25,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P3",PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-2600-2630-01",SUBSYSTEM_DESCR:"Methanol Storage and Pumps",LOSH:"Y",MC_B30:"2022-06-16",A_OV_TOT:125,A_OV_COM:45,A_OV_REM:80,A_OV_PROG:36,A_OV_STATUS:"less than 70%",A_PG:36,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PB1_OV_TOT:7,PB1_OV_COM:7,PB1_OV_REM:0,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:42,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-2600-2640-01",SUBSYSTEM_DESCR:"Methanol Filters and Break Tank",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:230,A_OV_COM:72,A_OV_REM:158,A_OV_PROG:31.3,A_OV_STATUS:"less than 70%",A_PG:31.3,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:67,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-2600-2640-02",SUBSYSTEM_DESCR:"Methanol HP Pumps",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:151,A_OV_COM:18,A_OV_REM:133,A_OV_PROG:11.92,A_OV_STATUS:"less than 70%",A_PG:11.92,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:75,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-2600-2640-03",SUBSYSTEM_DESCR:"Methanol MP Pumps",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:154,A_OV_COM:22,A_OV_REM:132,A_OV_PROG:14.28,A_OV_STATUS:"less than 70%",A_PG:14.28,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:69,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-2600-2640-04",SUBSYSTEM_DESCR:"Methanol HP Header",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:31,A_OV_COM:4,A_OV_REM:27,A_OV_PROG:12.9,A_OV_STATUS:"less than 70%",A_PG:12.9,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-2600-2640-05",SUBSYSTEM_DESCR:"Methanol MP Header",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:169,A_OV_COM:21,A_OV_REM:148,A_OV_PROG:12.42,A_OV_STATUS:"less than 70%",A_PG:12.42,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:81,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-2600-2641-01",SUBSYSTEM_DESCR:"Methanol Subsea Pumps",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:153,A_OV_COM:22,A_OV_REM:131,A_OV_PROG:14.37,A_OV_STATUS:"less than 70%",A_PG:14.37,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:73,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-2600-2641-02",SUBSYSTEM_DESCR:"Methanol Subsea Distribution",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:33,A_OV_COM:3,A_OV_REM:30,A_OV_PROG:9.09,A_OV_STATUS:"less than 70%",A_PG:9.09,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-2600-8510-01",SUBSYSTEM_DESCR:"Methanol Loading Header",LOSH:"Y",MC_B30:"2022-07-07",A_OV_TOT:32,A_OV_COM:6,A_OV_REM:26,A_OV_PROG:18.75,A_OV_STATUS:"less than 70%",A_PG:18.75,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-3040-3040-01",SUBSYSTEM_DESCR:"HPandMP Gas Compressors, UCP, VSD and Electrical ControlandSwitchboards",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:216,A_OV_COM:26,A_OV_REM:190,A_OV_PROG:12.03,A_OV_STATUS:"less than 70%",A_PG:12.03,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:33,PA_OV_COM:17,PA_OV_REM:16,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,PB2_OV_TOT:7,PB2_OV_COM:0,PB2_OV_REM:7,ACT_TAG_VALID:155,PUNCH_NUMBER_VALID:43,PUNCH_TAG_VALID:4},
  //   {SUBSYSTEM:"21-3040-3040-02",SUBSYSTEM_DESCR:"HPandMP Gas Compressors Train Scrubbers, Aftercoolers and Process Lines",LOSH:"Y",MC_B30:"2022-06-16",A_OV_TOT:516,A_OV_COM:146,A_OV_REM:370,A_OV_PROG:28.29,A_OV_STATUS:"less than 70%",A_PG:28.29,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:191,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-3040-3041-01",SUBSYSTEM_DESCR:"LPandLLP Gas Compressors , UCP, VSD and Electrical ControlandSwitchboards",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:119,A_OV_COM:16,A_OV_REM:103,A_OV_PROG:13.44,A_OV_STATUS:"less than 70%",A_PG:13.44,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:5,PA_OV_COM:0,PA_OV_REM:5,PB1_OV_TOT:13,PB1_OV_COM:2,PB1_OV_REM:11,PB2_OV_TOT:11,PB2_OV_COM:0,PB2_OV_REM:11,ACT_TAG_VALID:81,PUNCH_NUMBER_VALID:29,PUNCH_TAG_VALID:4},
  //   {SUBSYSTEM:"21-3040-3041-02",SUBSYSTEM_DESCR:"LPandLLP Gas Compressors Train Scrubbers, Aftercoolers and Process Lines",LOSH:"Y",MC_B30:"2022-06-23",A_OV_TOT:438,A_OV_COM:126,A_OV_REM:312,A_OV_PROG:28.76,A_OV_STATUS:"less than 70%",A_PG:28.76,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:163,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:0},
  //   {SUBSYSTEM:"21-3330-3330-01",SUBSYSTEM_DESCR:"Process Gas, GasandGas Exchanger, TEX Scrubber and JT Valve",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:453,A_OV_COM:111,A_OV_REM:342,A_OV_PROG:24.5,A_OV_STATUS:"less than 70%",A_PG:24.5,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P3",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:203,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-3330-3330-02",SUBSYSTEM_DESCR:"Gas Coalescer and LT Separator",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:557,A_OV_COM:133,A_OV_REM:424,A_OV_PROG:23.87,A_OV_STATUS:"less than 70%",A_PG:23.87,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,ACT_TAG_VALID:268,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-3330-3330-03",SUBSYSTEM_DESCR:"Turbo Expander, Recompressor",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:349,A_OV_COM:43,A_OV_REM:306,A_OV_PROG:12.32,A_OV_STATUS:"less than 70%",A_PG:12.32,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,PB1_OV_TOT:6,PB1_OV_COM:1,PB1_OV_REM:5,PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:222,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-3330-3410-01",SUBSYSTEM_DESCR:"Gas Export Heaters",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:112,A_OV_COM:30,A_OV_REM:82,A_OV_PROG:26.78,A_OV_STATUS:"less than 70%",A_PG:26.78,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:36,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-3330-3410-02",SUBSYSTEM_DESCR:"Export Gas Metering and Export Risers",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:161,A_OV_COM:28,A_OV_REM:133,A_OV_PROG:17.39,A_OV_STATUS:"less than 70%",A_PG:17.39,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:4,PB1_OV_COM:1,PB1_OV_REM:3,ACT_TAG_VALID:71,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-3330-3410-03",SUBSYSTEM_DESCR:"Repressurisation Heater and Header",LOSH:"Y",MC_B30:"2022-07-14",A_OV_TOT:128,A_OV_COM:26,A_OV_REM:102,A_OV_PROG:20.31,A_OV_STATUS:"less than 70%",A_PG:20.31,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,ACT_TAG_VALID:59,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-4000-4030-01",SUBSYSTEM_DESCR:"Produced Water Offspec Storage Tank and Pumps",LOSH:"Y",MC_B30:"2022-06-16",A_OV_TOT:271,A_OV_COM:98,A_OV_REM:173,A_OV_PROG:36.16,A_OV_STATUS:"less than 70%",A_PG:36.16,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:7,PA_OV_COM:0,PA_OV_REM:7,PB1_OV_TOT:9,PB1_OV_COM:7,PB1_OV_REM:2,PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:100,PUNCH_NUMBER_VALID:19,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-4000-4030-02",SUBSYSTEM_DESCR:"Produced Water Offspec Header to Storage Tank",LOSH:"Y",MC_B30:"2022-06-23",A_OV_TOT:43,A_OV_COM:9,A_OV_REM:34,A_OV_PROG:20.93,A_OV_STATUS:"less than 70%",A_PG:20.93,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-4000-4030-03",SUBSYSTEM_DESCR:"Produced Water Offspec Header to Produced Water Treatment and Bunkering Station",LOSH:"Y",MC_B30:"2022-06-23",A_OV_TOT:36,A_OV_COM:6,A_OV_REM:30,A_OV_PROG:16.66,A_OV_STATUS:"less than 70%",A_PG:16.66,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-4000-4130-01",SUBSYSTEM_DESCR:"Deoiling Package ,Hydrocyclone + CFU",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:242,A_OV_COM:43,A_OV_REM:199,A_OV_PROG:17.76,A_OV_STATUS:"less than 70%",A_PG:17.76,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:10,PA_OV_COM:6,PA_OV_REM:4,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,ACT_TAG_VALID:98,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-4000-4130-02",SUBSYSTEM_DESCR:"Flocculent Injection Package",LOSH:"Y",MC_B30:"2022-06-16",A_OV_TOT:101,A_OV_COM:17,A_OV_REM:84,A_OV_PROG:16.83,A_OV_STATUS:"less than 70%",A_PG:16.83,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:5,PB1_OV_COM:2,PB1_OV_REM:3,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:48,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:4},
  //   {SUBSYSTEM:"21-4000-4130-03",SUBSYSTEM_DESCR:"Demulsifier Injection Package",MC_B30:"2022-06-16",A_OV_TOT:23,A_OV_COM:0,A_OV_REM:23,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-4000-4130-04",SUBSYSTEM_DESCR:"Produced Water Polishing Package",MC_B30:"2022-06-23",A_OV_TOT:35,A_OV_COM:0,A_OV_REM:35,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-4000-4150-01",SUBSYSTEM_DESCR:"Mercury Removal Package",LOSH:"Y",MC_B30:"2022-06-16",A_OV_TOT:66,A_OV_COM:21,A_OV_REM:45,A_OV_PROG:31.81,A_OV_STATUS:"less than 70%",A_PG:31.81,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:3,PA_OV_COM:2,PA_OV_REM:1,PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-4000-4150-02",SUBSYSTEM_DESCR:"Treated Water Header and Discharge Caisson",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:29,A_OV_COM:6,A_OV_REM:23,A_OV_PROG:20.68,A_OV_STATUS:"less than 70%",A_PG:20.68,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-5000-5010-01",SUBSYSTEM_DESCR:"HP Flare KO Drum and Pumps",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:166,A_OV_COM:18,A_OV_REM:148,A_OV_PROG:10.84,A_OV_STATUS:"less than 70%",A_PG:10.84,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:79,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-5000-5020-01",SUBSYSTEM_DESCR:"HP Flare Ignition, Metering and Tip",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:144,A_OV_COM:41,A_OV_REM:103,A_OV_PROG:28.47,A_OV_STATUS:"less than 70%",A_PG:28.47,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:39,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-5000-5030-01",SUBSYSTEM_DESCR:"Cold and Dry HP Flare Header",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:232,A_OV_COM:49,A_OV_REM:183,A_OV_PROG:21.12,A_OV_STATUS:"less than 70%",A_PG:21.12,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:56,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-5000-5030-02",SUBSYSTEM_DESCR:"Wet HP Flare Header",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:43,A_OV_COM:5,A_OV_REM:38,A_OV_PROG:11.62,A_OV_STATUS:"less than 70%",A_PG:11.62,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-5000-5030-03",SUBSYSTEM_DESCR:"HP liquid Flare Header",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:245,A_OV_COM:41,A_OV_REM:204,A_OV_PROG:16.73,A_OV_STATUS:"less than 70%",A_PG:16.73,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:73,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-5200-4340-01",SUBSYSTEM_DESCR:"Closed Drain Drum and Pumps",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:156,A_OV_COM:18,A_OV_REM:138,A_OV_PROG:11.53,A_OV_STATUS:"less than 70%",A_PG:11.53,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,ACT_TAG_VALID:65,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-5200-4340-02",SUBSYSTEM_DESCR:"Closed Drain Header",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:620,A_OV_COM:225,A_OV_REM:395,A_OV_PROG:36.29,A_OV_STATUS:"less than 70%",A_PG:36.29,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:106,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-5200-4340-03",SUBSYSTEM_DESCR:"HULL Deck Equipment Drainage Header",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:7,A_OV_COM:0,A_OV_REM:7,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-5200-5210-01",SUBSYSTEM_DESCR:"LP Flare KO Drum and Pumps",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:136,A_OV_COM:14,A_OV_REM:122,A_OV_PROG:10.29,A_OV_STATUS:"less than 70%",A_PG:10.29,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,ACT_TAG_VALID:53,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-5200-5220-01",SUBSYSTEM_DESCR:"LP Flare Metering and Tip",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:101,A_OV_COM:31,A_OV_REM:70,A_OV_PROG:30.69,A_OV_STATUS:"less than 70%",A_PG:30.69,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:26,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-5200-5230-01",SUBSYSTEM_DESCR:"LP Flare Header",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:168,A_OV_COM:18,A_OV_REM:150,A_OV_PROG:10.71,A_OV_STATUS:"less than 70%",A_PG:10.71,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:45,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-5400-5410-01",SUBSYSTEM_DESCR:"Vent Headers, Boom and Liquid Seal Systems",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:278,A_OV_COM:56,A_OV_REM:222,A_OV_PROG:20.14,A_OV_STATUS:"less than 70%",A_PG:20.14,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,ACT_TAG_VALID:71,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-5400-5410-02",SUBSYSTEM_DESCR:"CO2 Snuffing System",MC_B30:"2022-07-21",A_OV_TOT:33,A_OV_COM:5,A_OV_REM:28,A_OV_PROG:15.15,A_OV_STATUS:"less than 70%",A_PG:15.15,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-5700-5710-01",SUBSYSTEM_DESCR:"Flare Gas Vapour Recovery Skid",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:47,A_OV_COM:11,A_OV_REM:36,A_OV_PROG:23.4,A_OV_STATUS:"less than 70%",A_PG:23.4,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:7,PA_OV_COM:3,PA_OV_REM:4,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:21,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-5700-5730-01",SUBSYSTEM_DESCR:"HULL Tank Vapour Recovery Skid",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:64,A_OV_COM:6,A_OV_REM:58,A_OV_PROG:9.37,A_OV_STATUS:"less than 70%",A_PG:9.37,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PB1_OV_TOT:2,PB1_OV_COM:1,PB1_OV_REM:1,ACT_TAG_VALID:32,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-6010-6011-01",SUBSYSTEM_DESCR:"SIS Marshalling - Machinery Space ,HER",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-17",A_OV_TOT:43,A_OV_COM:43,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-10-25",WD_ACTUAL:"2021-10-26",MC_APPROVED:"2022-02-11",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1A",PA_OV_TOT:43,PA_OV_COM:43,PA_OV_REM:0,PB1_OV_TOT:20,PB1_OV_COM:14,PB1_OV_REM:6,ACT_TAG_VALID:43,PUNCH_NUMBER_VALID:63,PUNCH_TAG_VALID:5},
  //   {SUBSYSTEM:"21-6010-6011-02",SUBSYSTEM_DESCR:"SIS Marshalling - LQ ,CER",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-10",A_OV_TOT:14,A_OV_COM:14,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-10-20",WD_ACTUAL:"2021-10-21",MC_APPROVED:"2022-02-11",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1A",PA_OV_TOT:26,PA_OV_COM:26,PA_OV_REM:0,PB1_OV_TOT:6,PB1_OV_COM:5,PB1_OV_REM:1,ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:32,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-6010-6011-03",SUBSYSTEM_DESCR:"SIS Marshalling - Topsides ,ME05 and LER",BATCH:"Incentive 2",MC_B30:"2022-02-24",A_OV_TOT:22,A_OV_COM:22,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-12",MC_APPROVED:"2022-02-21",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1B",PA_OV_TOT:12,PA_OV_COM:12,PA_OV_REM:0,PB1_OV_TOT:35,PB1_OV_COM:22,PB1_OV_REM:13,ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:47,PUNCH_TAG_VALID:6},
  //   {SUBSYSTEM:"21-6010-6011-04",SUBSYSTEM_DESCR:"CAP Panel in CCR",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:23,A_OV_COM:10,A_OV_REM:13,A_OV_PROG:43.47,A_OV_STATUS:"less than 70%",A_PG:43.47,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:2,PB1_OV_COM:1,PB1_OV_REM:1,ACT_TAG_VALID:23,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-6010-6011-07",SUBSYSTEM_DESCR:"SIS Multicore and Junction Box to Marshalling and System Cabinets in HER",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-01-27",A_OV_TOT:112,A_OV_COM:26,A_OV_REM:86,A_OV_PROG:23.21,A_OV_STATUS:"less than 70%",A_PG:23.21,RFI_ISSUED:"2022-01-08",WD_ACTUAL:"2022-01-10",MC_APPROVED:"2022-01-22",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:9,PA_OV_COM:9,PA_OV_REM:0,PB1_OV_TOT:18,PB1_OV_COM:17,PB1_OV_REM:1,ACT_TAG_VALID:89,PUNCH_NUMBER_VALID:27,PUNCH_TAG_VALID:15},
  //   {SUBSYSTEM:"21-6010-6011-08",SUBSYSTEM_DESCR:"SIS Field Push Buttons",MC_B30:"2022-05-05",A_OV_TOT:4,A_OV_COM:0,A_OV_REM:4,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6010-6012-01",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables ME01",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:12,A_OV_COM:0,A_OV_REM:12,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6010-6012-02",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables MW01",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:18,A_OV_COM:0,A_OV_REM:18,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6010-6012-03",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables ME02",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:18,A_OV_COM:0,A_OV_REM:18,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6010-6012-04",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables MW02",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:24,A_OV_COM:0,A_OV_REM:24,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6010-6012-05",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables OFL00",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:6,A_OV_COM:0,A_OV_REM:6,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6010-6012-06",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables MW04",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:12,A_OV_COM:0,A_OV_REM:12,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6010-6012-07",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables MW05",LOSH:"Y",MC_B30:"2022-03-10",A_OV_TOT:6,A_OV_COM:0,A_OV_REM:6,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6010-6012-08",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables ME04",MC_B30:"2022-03-17",A_OV_TOT:6,A_OV_COM:0,A_OV_REM:6,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6010-6012-09",SUBSYSTEM_DESCR:"SIF's for 1st Gas Start-up",MC_B30:"2022-06-16",A_OV_STATUS:"NA",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"NA",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6010-6012-10",SUBSYSTEM_DESCR:"SIF's Post 1st Gas Start-up",MC_B30:"2022-06-16",A_OV_STATUS:"NA",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"NA",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6020-6021-01",SUBSYSTEM_DESCR:"PAS Marshalling - Machinery Space ,HER",BATCH:"Incentive 1",LOSH:"Y",MC_B30:"2022-02-17",A_OV_TOT:48,A_OV_COM:48,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-12-03",WD_ACTUAL:"2021-12-04",MC_APPROVED:"2022-02-11",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1A",PA_OV_TOT:20,PA_OV_COM:20,PA_OV_REM:0,PB1_OV_TOT:19,PB1_OV_COM:19,PB1_OV_REM:0,PB2_OV_TOT:7,PB2_OV_COM:0,PB2_OV_REM:7,ACT_TAG_VALID:48,PUNCH_NUMBER_VALID:46,PUNCH_TAG_VALID:9},
  //   {SUBSYSTEM:"21-6020-6021-02",SUBSYSTEM_DESCR:"PAS Marshalling - LQ ,CER",BATCH:"Incentive 1",LOSH:"Y",MC_B30:"2022-02-10",A_OV_TOT:15,A_OV_COM:15,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-10-21",WD_ACTUAL:"2021-10-22",MC_APPROVED:"2022-02-11",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1A",PA_OV_TOT:11,PA_OV_COM:11,PA_OV_REM:0,PB1_OV_TOT:11,PB1_OV_COM:10,PB1_OV_REM:1,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:23,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-6020-6021-03",SUBSYSTEM_DESCR:"PAS Marshalling - Topsides ,ME05 and LER",BATCH:"Incentive 2",MC_B30:"2022-02-24",A_OV_TOT:15,A_OV_COM:15,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-12",MC_APPROVED:"2022-02-21",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1B",PA_OV_TOT:8,PA_OV_COM:8,PA_OV_REM:0,PB1_OV_TOT:35,PB1_OV_COM:24,PB1_OV_REM:11,ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:43,PUNCH_TAG_VALID:9},
  //   {SUBSYSTEM:"21-6020-6021-06",SUBSYSTEM_DESCR:"PAS Multicore and Junction Box to Marshalling and System Cabinets in HER",BATCH:"Incentive 2",MC_B30:"2022-02-03",A_OV_TOT:187,A_OV_COM:37,A_OV_REM:150,A_OV_PROG:19.78,A_OV_STATUS:"less than 70%",A_PG:19.78,RFI_ISSUED:"2022-01-08",WD_ACTUAL:"2022-01-10",MC_APPROVED:"2022-01-29",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:21,PA_OV_COM:20,PA_OV_REM:1,PB1_OV_TOT:19,PB1_OV_COM:17,PB1_OV_REM:2,ACT_TAG_VALID:152,PUNCH_NUMBER_VALID:40,PUNCH_TAG_VALID:34},
  //   {SUBSYSTEM:"21-6020-6022-01",SUBSYSTEM_DESCR:"PAS Remote IOs,incl Junction Box and Multicore Cables ME01",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:20,A_OV_COM:0,A_OV_REM:20,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-6020-6022-02",SUBSYSTEM_DESCR:"PAS Remote IOs ,incl Junction Box and Multicore Cables MW01",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:30,A_OV_COM:0,A_OV_REM:30,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6020-6022-03",SUBSYSTEM_DESCR:"PAS Remote IOs ,incl Junction Box and Multicore Cables ME02",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:24,A_OV_COM:0,A_OV_REM:24,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6020-6022-04",SUBSYSTEM_DESCR:"PAS Remote IOs ,incl Junction Box and Multicore Cables MW02",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:30,A_OV_COM:0,A_OV_REM:30,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6020-6022-05",SUBSYSTEM_DESCR:"PAS Remote IOs ,incl Junction Box and Multicore Cables ME04",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:12,A_OV_COM:0,A_OV_REM:12,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6020-6022-06",SUBSYSTEM_DESCR:"PAS Remote IOs ,incl Junction Box and Multicore Cables MW04",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:6,A_OV_COM:0,A_OV_REM:6,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6020-6022-07",SUBSYSTEM_DESCR:"PAS Remote IOs ,incl Junction Box and Multicore Cables MW05",LOSH:"Y",MC_B30:"2022-03-10",A_OV_TOT:30,A_OV_COM:0,A_OV_REM:30,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6020-6022-08",SUBSYSTEM_DESCR:"PAS Remote IOs ,incl Junction Box and Multicore Cables OFL00",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:12,A_OV_COM:0,A_OV_REM:12,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6030-6031-01",SUBSYSTEM_DESCR:"Electrical Data Monitoring and Constrol System ,EDMCS and Power Management System ,PMS",MC_B30:"2022-03-03",A_OV_TOT:208,A_OV_COM:90,A_OV_REM:118,A_OV_PROG:43.26,A_OV_STATUS:"less than 70%",A_PG:43.26,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1B",PA_OV_TOT:14,PA_OV_COM:11,PA_OV_REM:3,PB1_OV_TOT:14,PB1_OV_COM:7,PB1_OV_REM:7,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:208,PUNCH_NUMBER_VALID:29,PUNCH_TAG_VALID:15},
  //   {SUBSYSTEM:"21-6030-6032-01",SUBSYSTEM_DESCR:"ICSS Interface Servers, Workstations and Network Switches CER",BATCH:"Incentive 2",MC_B30:"2021-12-02",A_OV_TOT:299,A_OV_COM:278,A_OV_REM:21,A_OV_PROG:92.97,A_OV_STATUS:"greater than 90%",A_PG:92.97,RFI_ISSUED:"2021-11-20",WD_ACTUAL:"2021-11-22",MC_APPROVED:"2021-11-27",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1A",PA_OV_TOT:32,PA_OV_COM:32,PA_OV_REM:0,PB1_OV_TOT:162,PB1_OV_COM:82,PB1_OV_REM:80,PB2_OV_TOT:18,PB2_OV_COM:0,PB2_OV_REM:18,ACT_TAG_VALID:299,PUNCH_NUMBER_VALID:212,PUNCH_TAG_VALID:19},
  //   {SUBSYSTEM:"21-6030-6032-02",SUBSYSTEM_DESCR:"ICSS Interface Servers, Workstations and Network Switches LER",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-24",A_OV_TOT:185,A_OV_COM:168,A_OV_REM:17,A_OV_PROG:90.81,A_OV_STATUS:"greater than 90%",A_PG:90.81,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1B",PA_OV_TOT:3,PA_OV_COM:0,PA_OV_REM:3,PB1_OV_TOT:34,PB1_OV_COM:17,PB1_OV_REM:17,PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:185,PUNCH_NUMBER_VALID:40,PUNCH_TAG_VALID:11},
  //   {SUBSYSTEM:"21-6030-6032-03",SUBSYSTEM_DESCR:"ICSS Interface Servers, Workstations and Network Switches HER",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2021-12-09",A_OV_TOT:148,A_OV_COM:141,A_OV_REM:7,A_OV_PROG:95.27,A_OV_STATUS:"greater than 95%",A_PG:95.27,RFI_ISSUED:"2021-12-01",WD_ACTUAL:"2021-12-02",MC_APPROVED:"2021-12-08",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1A",PA_OV_TOT:6,PA_OV_COM:6,PA_OV_REM:0,PB1_OV_TOT:63,PB1_OV_COM:24,PB1_OV_REM:39,PB2_OV_TOT:10,PB2_OV_COM:1,PB2_OV_REM:9,ACT_TAG_VALID:148,PUNCH_NUMBER_VALID:79,PUNCH_TAG_VALID:15},
  //   {SUBSYSTEM:"21-6030-6032-04",SUBSYSTEM_DESCR:"ICSS Workstations and Consoles - CCR",MC_B30:"2022-03-17",A_OV_TOT:55,A_OV_COM:44,A_OV_REM:11,A_OV_PROG:80,A_OV_STATUS:"NA",A_PG:80,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:55,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-6030-6032-05",SUBSYSTEM_DESCR:"ICSS Workstations and Consoles - Safe HavenandSOP",MC_B30:"2022-06-23",A_OV_TOT:9,A_OV_COM:3,A_OV_REM:6,A_OV_PROG:33.33,A_OV_STATUS:"less than 70%",A_PG:33.33,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-6030-6034-01",SUBSYSTEM_DESCR:"Machinery Protection System ,MPS",MC_B30:"2022-03-10",A_OV_TOT:22,A_OV_COM:2,A_OV_REM:20,A_OV_PROG:9.09,A_OV_STATUS:"less than 70%",A_PG:9.09,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-6030-6035-01",SUBSYSTEM_DESCR:"Condition and Performance Monitoring System ,CPMS",MC_B30:"2022-03-10",A_OV_STATUS:"NA",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6030-6036-01",SUBSYSTEM_DESCR:"PDBs Inside HER",BATCH:"Incentive 1",MC_B30:"2021-11-25",A_OV_TOT:17,A_OV_COM:15,A_OV_REM:2,A_OV_PROG:88.23,A_OV_STATUS:"NA",A_PG:88.23,RFI_ISSUED:"2021-09-27",WD_ACTUAL:"2021-09-28",MC_APPROVED:"2021-11-25",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1A",PA_OV_TOT:19,PA_OV_COM:19,PA_OV_REM:0,PB1_OV_TOT:11,PB1_OV_COM:10,PB1_OV_REM:1,PB2_OV_TOT:2,PB2_OV_COM:1,PB2_OV_REM:1,ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:32,PUNCH_TAG_VALID:8},
  //   {SUBSYSTEM:"21-6030-6036-02",SUBSYSTEM_DESCR:"PDBs Inside CER",BATCH:"Incentive 1",MC_B30:"2021-11-25",A_OV_TOT:29,A_OV_COM:22,A_OV_REM:7,A_OV_PROG:75.86,A_OV_STATUS:"NA",A_PG:75.86,RFI_ISSUED:"2021-10-19",WD_ACTUAL:"2021-10-20",MC_APPROVED:"2021-11-25",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1A",PA_OV_TOT:20,PA_OV_COM:20,PA_OV_REM:0,PB1_OV_TOT:18,PB1_OV_COM:17,PB1_OV_REM:1,PB2_OV_TOT:5,PB2_OV_COM:2,PB2_OV_REM:3,ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:43,PUNCH_TAG_VALID:13},
  //   {SUBSYSTEM:"21-6030-6036-04",SUBSYSTEM_DESCR:"PDBs Inside ME05",BATCH:"Incentive 2",MC_B30:"2022-02-24",A_OV_TOT:20,A_OV_COM:19,A_OV_REM:1,A_OV_PROG:95,A_OV_STATUS:"greater than 95%",A_PG:95,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-28",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1B",PA_OV_TOT:30,PA_OV_COM:18,PA_OV_REM:12,PB1_OV_TOT:119,PB1_OV_COM:40,PB1_OV_REM:79,ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:149,PUNCH_TAG_VALID:9},
  //   {SUBSYSTEM:"21-6030-6036-05",SUBSYSTEM_DESCR:"PDBs Inside L5 Telecom Room",MC_B30:"2022-01-27",A_OV_TOT:10,A_OV_COM:10,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-12-28",WD_ACTUAL:"2021-12-29",MC_APPROVED:"2022-01-27",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:3,PA_OV_COM:3,PA_OV_REM:0,PB1_OV_TOT:23,PB1_OV_COM:13,PB1_OV_REM:10,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:27,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-6030-6037-01",SUBSYSTEM_DESCR:"Tank Gauging System Cabinets and Workstations",LOSH:"Y",MC_B30:"2022-01-27",A_OV_TOT:25,A_OV_COM:25,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-18",MC_APPROVED:"2022-01-22",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:11,PA_OV_COM:11,PA_OV_REM:0,PB1_OV_TOT:27,PB1_OV_COM:23,PB1_OV_REM:4,PB2_OV_TOT:16,PB2_OV_COM:4,PB2_OV_REM:12,ACT_TAG_VALID:21,PUNCH_NUMBER_VALID:54,PUNCH_TAG_VALID:16},
  //   {SUBSYSTEM:"21-6030-6038-01",SUBSYSTEM_DESCR:"Cargo Loading Computer and Workstation",MC_B30:"2022-03-24",A_OV_TOT:10,A_OV_COM:0,A_OV_REM:10,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"B2",PRIORITY:"P1",PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-6030-6039-01",SUBSYSTEM_DESCR:"BallastandVoid Gas Sampling",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:78,A_OV_COM:26,A_OV_REM:52,A_OV_PROG:33.33,A_OV_STATUS:"less than 70%",A_PG:33.33,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:5,PB1_OV_COM:0,PB1_OV_REM:5,ACT_TAG_VALID:53,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-6030-6040-01",SUBSYSTEM_DESCR:"IRP and Interface Instrumentation Cable - ME05 ,HV and LV Room",BATCH:"Incentive 2",MC_B30:"2022-03-10",A_OV_TOT:84,A_OV_COM:27,A_OV_REM:57,A_OV_PROG:32.14,A_OV_STATUS:"less than 70%",A_PG:32.14,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1B",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PB1_OV_TOT:5,PB1_OV_COM:0,PB1_OV_REM:5,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:84,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:5},
  //   {SUBSYSTEM:"21-6030-6040-02",SUBSYSTEM_DESCR:"IRP and Interface Instrumentation Cable - LQ",BATCH:"Incentive 2",MC_B30:"2021-12-30",A_OV_TOT:71,A_OV_COM:71,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-11-02",WD_ACTUAL:"2021-11-03",MC_APPROVED:"2021-12-28",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1A",PA_OV_TOT:21,PA_OV_COM:21,PA_OV_REM:0,PB1_OV_TOT:32,PB1_OV_COM:29,PB1_OV_REM:3,PB2_OV_TOT:2,PB2_OV_COM:2,PB2_OV_REM:0,PB3_OV_TOT:1,PB3_OV_COM:1,PB3_OV_REM:0,ACT_TAG_VALID:71,PUNCH_NUMBER_VALID:56,PUNCH_TAG_VALID:18},
  //   {SUBSYSTEM:"21-6030-6040-03",SUBSYSTEM_DESCR:"IRP and Interface Instrumentation Cable - MS03",BATCH:"Incentive 2",MC_B30:"2022-01-27",A_OV_TOT:181,A_OV_COM:181,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-12-23",WD_ACTUAL:"2021-12-24",MC_APPROVED:"2022-01-22",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1A",PA_OV_TOT:16,PA_OV_COM:16,PA_OV_REM:0,PB1_OV_TOT:45,PB1_OV_COM:41,PB1_OV_REM:4,PB2_OV_TOT:6,PB2_OV_COM:1,PB2_OV_REM:5,ACT_TAG_VALID:181,PUNCH_NUMBER_VALID:67,PUNCH_TAG_VALID:21},
  //   {SUBSYSTEM:"21-6030-6041-01",SUBSYSTEM_DESCR:"PEMS System",MC_B30:"2022-03-10",A_OV_TOT:3,A_OV_COM:0,A_OV_REM:3,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:5,PB1_OV_COM:1,PB1_OV_REM:4,PB2_OV_TOT:5,PB2_OV_COM:0,PB2_OV_REM:5,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-6030-6042-01",SUBSYSTEM_DESCR:"Corrosion and Erosion Monitoring",MC_B30:"2022-03-31",A_OV_TOT:82,A_OV_COM:0,A_OV_REM:82,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:2,PB1_OV_COM:1,PB1_OV_REM:1,PB2_OV_TOT:4,PB2_OV_COM:0,PB2_OV_REM:4,ACT_TAG_VALID:39,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-6030-6043-01",SUBSYSTEM_DESCR:"FOPPs and Associated Fiber Cables to LER",MC_B30:"2022-03-24",A_OV_TOT:211,A_OV_COM:109,A_OV_REM:102,A_OV_PROG:51.65,A_OV_STATUS:"less than 70%",A_PG:51.65,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1B",ACT_TAG_VALID:211,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6030-6043-02",SUBSYSTEM_DESCR:"FOPPs and Associated Fiber Cables to CER",BATCH:"Incentive 2",MC_B30:"2022-03-03",A_OV_TOT:32,A_OV_COM:24,A_OV_REM:8,A_OV_PROG:75,A_OV_STATUS:"NA",A_PG:75,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:8,PB1_OV_COM:3,PB1_OV_REM:5,PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:32,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:6},
  //   {SUBSYSTEM:"21-6030-6043-03",SUBSYSTEM_DESCR:"FOPPs and Associated Fiber Cables to HER",BATCH:"Incentive 2",MC_B30:"2022-02-24",A_OV_TOT:21,A_OV_COM:21,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-19",MC_APPROVED:"2022-02-26",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"N",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PB1_OV_TOT:18,PB1_OV_COM:18,PB1_OV_REM:0,ACT_TAG_VALID:21,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:8},
  //   {SUBSYSTEM:"21-6035-6035-01",SUBSYSTEM_DESCR:"Process CCTV Cameras, Consoles and Screens",BATCH:"Incentive 2",MC_B30:"2022-05-05",A_OV_TOT:234,A_OV_COM:12,A_OV_REM:222,A_OV_PROG:5.12,A_OV_STATUS:"less than 70%",A_PG:5.12,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",PB1_OV_TOT:5,PB1_OV_COM:5,PB1_OV_REM:0,ACT_TAG_VALID:159,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:4},
  //   {SUBSYSTEM:"21-6070-6071-01",SUBSYSTEM_DESCR:"FGS Marshalling - Machinery Space ,HER",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-10",A_OV_TOT:14,A_OV_COM:14,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-10-25",WD_ACTUAL:"2021-10-26",MC_APPROVED:"2022-02-11",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1A",PA_OV_TOT:12,PA_OV_COM:12,PA_OV_REM:0,PB1_OV_TOT:11,PB1_OV_COM:10,PB1_OV_REM:1,ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:23,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-6070-6071-02",SUBSYSTEM_DESCR:"FGS Marshalling - LQ ,CER",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-10",A_OV_TOT:30,A_OV_COM:30,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-10-20",WD_ACTUAL:"2021-10-21",MC_APPROVED:"2022-02-11",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1A",PA_OV_TOT:26,PA_OV_COM:26,PA_OV_REM:0,PB1_OV_TOT:14,PB1_OV_COM:14,PB1_OV_REM:0,PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:30,PUNCH_NUMBER_VALID:42,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-6070-6071-03",SUBSYSTEM_DESCR:"FGS Marshalling - Topsides ,ME05 and LER",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-24",A_OV_TOT:8,A_OV_COM:8,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-12",MC_APPROVED:"2022-02-21",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1B",PA_OV_TOT:6,PA_OV_COM:6,PA_OV_REM:0,PB1_OV_TOT:15,PB1_OV_COM:9,PB1_OV_REM:6,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:21,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-6070-6071-04",SUBSYSTEM_DESCR:"FGS Multicore and Junction Box to Marshalling and System Cabinets in LER",MC_B30:"2022-03-17",A_OV_TOT:10,A_OV_COM:0,A_OV_REM:10,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6070-6071-05",SUBSYSTEM_DESCR:"FGS Multicore and Junction Box to Marshalling and System Cabinets in CER",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-10",A_OV_TOT:46,A_OV_COM:46,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-20",MC_APPROVED:"2022-02-17",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"N",PRIORITY:"P1",PA_OV_TOT:15,PA_OV_COM:15,PA_OV_REM:0,PB1_OV_TOT:22,PB1_OV_COM:22,PB1_OV_REM:0,ACT_TAG_VALID:46,PUNCH_NUMBER_VALID:37,PUNCH_TAG_VALID:24},
  //   {SUBSYSTEM:"21-6070-6071-06",SUBSYSTEM_DESCR:"FGS Multicore and Junction Box to Marshalling and System Cabinets in HER",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-10",A_OV_TOT:9,A_OV_COM:6,A_OV_REM:3,A_OV_PROG:66.66,A_OV_STATUS:"less than 70%",A_PG:66.66,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-11",MC_APPROVED:"2022-02-24",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1",PA_OV_TOT:7,PA_OV_COM:7,PA_OV_REM:0,PB1_OV_TOT:5,PB1_OV_COM:5,PB1_OV_REM:0,PB2_OV_TOT:4,PB2_OV_COM:3,PB2_OV_REM:1,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:16,PUNCH_TAG_VALID:7},
  //   {SUBSYSTEM:"21-6070-6072-01",SUBSYSTEM_DESCR:"Fire Addressable Panels - LQ ,CER and LQ Fire and Gas Addressable Loop-1",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-03",A_OV_TOT:395,A_OV_COM:326,A_OV_REM:69,A_OV_PROG:82.53,A_OV_STATUS:"NA",A_PG:82.53,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-24",MC_APPROVED:"2022-01-28",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:10,PA_OV_COM:8,PA_OV_REM:2,PB1_OV_TOT:46,PB1_OV_COM:41,PB1_OV_REM:5,PB2_OV_TOT:19,PB2_OV_COM:1,PB2_OV_REM:18,PB3_OV_TOT:1,PB3_OV_COM:0,PB3_OV_REM:1,ACT_TAG_VALID:273,PUNCH_NUMBER_VALID:76,PUNCH_TAG_VALID:35},
  //   {SUBSYSTEM:"21-6070-6072-02",SUBSYSTEM_DESCR:"LQ Fire and Gas Addressable Loop-2",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:369,A_OV_COM:309,A_OV_REM:60,A_OV_PROG:83.73,A_OV_STATUS:"NA",A_PG:83.73,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:7,PA_OV_COM:5,PA_OV_REM:2,PB1_OV_TOT:39,PB1_OV_COM:32,PB1_OV_REM:7,PB2_OV_TOT:21,PB2_OV_COM:0,PB2_OV_REM:21,ACT_TAG_VALID:253,PUNCH_NUMBER_VALID:67,PUNCH_TAG_VALID:33},
  //   {SUBSYSTEM:"21-6070-6072-03",SUBSYSTEM_DESCR:"LQ Fire and Gas Addressable Loop-3",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-24",A_OV_TOT:281,A_OV_COM:247,A_OV_REM:34,A_OV_PROG:87.9,A_OV_STATUS:"NA",A_PG:87.9,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PB1_OV_TOT:14,PB1_OV_COM:12,PB1_OV_REM:2,PB2_OV_TOT:8,PB2_OV_COM:0,PB2_OV_REM:8,ACT_TAG_VALID:203,PUNCH_NUMBER_VALID:24,PUNCH_TAG_VALID:12},
  //   {SUBSYSTEM:"21-6070-6072-04",SUBSYSTEM_DESCR:"LQ Fire and Gas Addressable Loop-4",BATCH:"Incentive 2",MC_B30:"2022-03-24",A_OV_TOT:271,A_OV_COM:243,A_OV_REM:28,A_OV_PROG:89.66,A_OV_STATUS:"NA",A_PG:89.66,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:4,PA_OV_COM:4,PA_OV_REM:0,PB1_OV_TOT:39,PB1_OV_COM:36,PB1_OV_REM:3,PB2_OV_TOT:15,PB2_OV_COM:0,PB2_OV_REM:15,ACT_TAG_VALID:186,PUNCH_NUMBER_VALID:58,PUNCH_TAG_VALID:31},
  //   {SUBSYSTEM:"21-6070-6072-05",SUBSYSTEM_DESCR:"LQ Fire and Gas Addressable Loop-5",BATCH:"Incentive 2",MC_B30:"2022-03-24",A_OV_TOT:325,A_OV_COM:252,A_OV_REM:73,A_OV_PROG:77.53,A_OV_STATUS:"NA",A_PG:77.53,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:23,PB1_OV_COM:21,PB1_OV_REM:2,PB2_OV_TOT:10,PB2_OV_COM:0,PB2_OV_REM:10,ACT_TAG_VALID:210,PUNCH_NUMBER_VALID:34,PUNCH_TAG_VALID:18},
  //   {SUBSYSTEM:"21-6070-6072-06",SUBSYSTEM_DESCR:"LQ Fire and Gas Addressable Loop-6",BATCH:"Incentive 2",MC_B30:"2022-03-24",A_OV_TOT:319,A_OV_COM:259,A_OV_REM:60,A_OV_PROG:81.19,A_OV_STATUS:"NA",A_PG:81.19,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:3,PA_OV_COM:3,PA_OV_REM:0,PB1_OV_TOT:14,PB1_OV_COM:11,PB1_OV_REM:3,PB2_OV_TOT:9,PB2_OV_COM:0,PB2_OV_REM:9,ACT_TAG_VALID:218,PUNCH_NUMBER_VALID:26,PUNCH_TAG_VALID:11},
  //   {SUBSYSTEM:"21-6070-6072-08",SUBSYSTEM_DESCR:"Fire Addressable Panels - Machinery Space ,HER and Machinery Space Fire and Gas Addressable Loop-1",BATCH:"Incentive 2",MC_B30:"2022-01-27",A_OV_TOT:279,A_OV_COM:251,A_OV_REM:28,A_OV_PROG:89.96,A_OV_STATUS:"NA",A_PG:89.96,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-12",MC_APPROVED:"2022-01-22",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:4,PA_OV_COM:4,PA_OV_REM:0,PB1_OV_TOT:36,PB1_OV_COM:29,PB1_OV_REM:7,PB2_OV_TOT:12,PB2_OV_COM:1,PB2_OV_REM:11,ACT_TAG_VALID:196,PUNCH_NUMBER_VALID:52,PUNCH_TAG_VALID:24},
  //   {SUBSYSTEM:"21-6070-6072-09",SUBSYSTEM_DESCR:"Machinery Space Fire and Gas  Addressable Loop-2",BATCH:"Incentive 2",MC_B30:"2022-01-06",A_OV_TOT:261,A_OV_COM:261,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-12-22",WD_ACTUAL:"2021-12-23",MC_APPROVED:"2021-12-31",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PB1_OV_TOT:35,PB1_OV_COM:28,PB1_OV_REM:7,PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:181,PUNCH_NUMBER_VALID:40,PUNCH_TAG_VALID:17},
  //   {SUBSYSTEM:"21-6070-6072-10",SUBSYSTEM_DESCR:"Machinery Space Fire and Gas  Addressable Loop-3",BATCH:"Incentive 2",MC_B30:"2021-12-23",A_OV_TOT:66,A_OV_COM:66,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-12-14",WD_ACTUAL:"2021-12-15",MC_APPROVED:"2021-12-23",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PB1_OV_TOT:7,PB1_OV_COM:7,PB1_OV_REM:0,PB2_OV_TOT:5,PB2_OV_COM:0,PB2_OV_REM:5,ACT_TAG_VALID:44,PUNCH_NUMBER_VALID:14,PUNCH_TAG_VALID:6},
  //   {SUBSYSTEM:"21-6070-6072-11",SUBSYSTEM_DESCR:"Fire Addressable Panels  and - Addressable Fire and Gas Detectors - Topsides ME05",BATCH:"Incentive 2",MC_B30:"2022-03-24",A_OV_TOT:323,A_OV_COM:78,A_OV_REM:245,A_OV_PROG:24.14,A_OV_STATUS:"less than 70%",A_PG:24.14,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:6,PB1_OV_COM:0,PB1_OV_REM:6,ACT_TAG_VALID:209,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-6070-6073-01",SUBSYSTEM_DESCR:"HSSD - Machinery Space ,HER",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:10,A_OV_COM:8,A_OV_REM:2,A_OV_PROG:80,A_OV_STATUS:"NA",A_PG:80,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-24",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:8,PA_OV_COM:6,PA_OV_REM:2,PB1_OV_TOT:7,PB1_OV_COM:4,PB1_OV_REM:3,PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:17,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-6070-6073-02",SUBSYSTEM_DESCR:"HSSD - Machinery Space ,L3 Electrical room",MC_B30:"2022-04-21",A_OV_TOT:20,A_OV_COM:16,A_OV_REM:4,A_OV_PROG:80,A_OV_STATUS:"NA",A_PG:80,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-24",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:16,PA_OV_COM:12,PA_OV_REM:4,PB1_OV_TOT:12,PB1_OV_COM:4,PB1_OV_REM:8,PB2_OV_TOT:4,PB2_OV_COM:0,PB2_OV_REM:4,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:32,PUNCH_TAG_VALID:4},
  //   {SUBSYSTEM:"21-6070-6073-03",SUBSYSTEM_DESCR:"HSSD - LQ ,HULL Level Electrical rooms",MC_B30:"2022-04-14",A_OV_TOT:10,A_OV_COM:8,A_OV_REM:2,A_OV_PROG:80,A_OV_STATUS:"NA",A_PG:80,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-27",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:12,PA_OV_COM:10,PA_OV_REM:2,PB1_OV_TOT:16,PB1_OV_COM:12,PB1_OV_REM:4,PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:31,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-6070-6073-04",SUBSYSTEM_DESCR:"HSSD - LQ ,CCR",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:5,A_OV_COM:4,A_OV_REM:1,A_OV_PROG:80,A_OV_STATUS:"NA",A_PG:80,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-27",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:5,PA_OV_COM:4,PA_OV_REM:1,PB1_OV_TOT:10,PB1_OV_COM:8,PB1_OV_REM:2,PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:17,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-6070-6073-05",SUBSYSTEM_DESCR:"HSSD - LQ ,CER",LOSH:"Y",MC_B30:"2022-02-24",A_OV_TOT:15,A_OV_COM:12,A_OV_REM:3,A_OV_PROG:80,A_OV_STATUS:"NA",A_PG:80,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-27",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:27,PA_OV_COM:24,PA_OV_REM:3,PB1_OV_TOT:17,PB1_OV_COM:13,PB1_OV_REM:4,PB2_OV_TOT:5,PB2_OV_COM:1,PB2_OV_REM:4,ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:49,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-6070-6073-06",SUBSYSTEM_DESCR:"HSSD - LQ ,L5 telecom and UPS rooms",MC_B30:"2022-03-03",A_OV_TOT:10,A_OV_COM:8,A_OV_REM:2,A_OV_PROG:80,A_OV_STATUS:"NA",A_PG:80,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-27",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:14,PA_OV_COM:12,PA_OV_REM:2,PB1_OV_TOT:14,PB1_OV_COM:11,PB1_OV_REM:3,PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:31,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-6070-6073-07",SUBSYSTEM_DESCR:"HSSD - Topsides ,ME05 and LER",LOSH:"Y",MC_B30:"2022-03-24",A_OV_TOT:45,A_OV_COM:9,A_OV_REM:36,A_OV_PROG:20,A_OV_STATUS:"less than 70%",A_PG:20,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:27,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6070-6074-01",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables ME01",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:24,A_OV_COM:0,A_OV_REM:24,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",PB1_OV_TOT:3,PB1_OV_COM:3,PB1_OV_REM:0,ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-6070-6074-02",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables MW01",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:24,A_OV_COM:0,A_OV_REM:24,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6070-6074-03",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables ME02",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:18,A_OV_COM:0,A_OV_REM:18,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6070-6074-04",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables MW02",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:24,A_OV_COM:0,A_OV_REM:24,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6070-6074-05",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables ME04",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:12,A_OV_COM:0,A_OV_REM:12,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6070-6074-06",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables MW04",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:18,A_OV_COM:0,A_OV_REM:18,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-6070-6074-07",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables MW05",LOSH:"Y",MC_B30:"2022-03-10",A_OV_TOT:12,A_OV_COM:2,A_OV_REM:10,A_OV_PROG:16.66,A_OV_STATUS:"less than 70%",A_PG:16.66,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6100-6110-01",SUBSYSTEM_DESCR:"Topsides Fire Water Ring Main, Hydrant and Jockey Pumps",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:363,A_OV_COM:94,A_OV_REM:269,A_OV_PROG:25.89,A_OV_STATUS:"less than 70%",A_PG:25.89,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:8,PA_OV_COM:6,PA_OV_REM:2,PB1_OV_TOT:15,PB1_OV_COM:13,PB1_OV_REM:2,PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:80,PUNCH_NUMBER_VALID:25,PUNCH_TAG_VALID:9},
  //   {SUBSYSTEM:"21-6100-6110-02",SUBSYSTEM_DESCR:"Accommodation Firewater and Hydrants",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:98,A_OV_COM:41,A_OV_REM:57,A_OV_PROG:41.83,A_OV_STATUS:"less than 70%",A_PG:41.83,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:21,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6100-6110-03",SUBSYSTEM_DESCR:"HULL Fire Water Ring Main and Hydrants",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:37,A_OV_COM:16,A_OV_REM:21,A_OV_PROG:43.24,A_OV_STATUS:"less than 70%",A_PG:43.24,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6100-6111-01",SUBSYSTEM_DESCR:"Fire Water Pump A ,21-SPF-611101",MC_B30:"2022-05-05",A_OV_TOT:176,A_OV_COM:30,A_OV_REM:146,A_OV_PROG:17.04,A_OV_STATUS:"less than 70%",A_PG:17.04,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:8,PB1_OV_COM:4,PB1_OV_REM:4,PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:72,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:6},
  //   {SUBSYSTEM:"21-6100-6111-02",SUBSYSTEM_DESCR:"Fire Water Pump B ,21-SPF-611201",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:178,A_OV_COM:28,A_OV_REM:150,A_OV_PROG:15.73,A_OV_STATUS:"less than 70%",A_PG:15.73,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:3,PB1_OV_COM:2,PB1_OV_REM:1,ACT_TAG_VALID:70,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-6100-6111-03",SUBSYSTEM_DESCR:"Fire Water Pump C ,21-SPF-611301",MC_B30:"2022-06-16",A_OV_TOT:173,A_OV_COM:27,A_OV_REM:146,A_OV_PROG:15.6,A_OV_STATUS:"less than 70%",A_PG:15.6,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:4,PA_OV_COM:1,PA_OV_REM:3,PB1_OV_TOT:2,PB1_OV_COM:1,PB1_OV_REM:1,ACT_TAG_VALID:69,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-6100-6121-01",SUBSYSTEM_DESCR:"HULL Water Mist System",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:114,A_OV_COM:114,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-11",MC_APPROVED:"2022-03-07",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:36,PA_OV_COM:36,PA_OV_REM:0,PB1_OV_TOT:22,PB1_OV_COM:14,PB1_OV_REM:8,PB2_OV_TOT:10,PB2_OV_COM:5,PB2_OV_REM:5,ACT_TAG_VALID:68,PUNCH_NUMBER_VALID:68,PUNCH_TAG_VALID:18},
  //   {SUBSYSTEM:"21-6100-6140-01",SUBSYSTEM_DESCR:"Foam Package",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:67,A_OV_COM:41,A_OV_REM:26,A_OV_PROG:61.19,A_OV_STATUS:"less than 70%",A_PG:61.19,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:15,PA_OV_COM:14,PA_OV_REM:1,PB1_OV_TOT:10,PB1_OV_COM:9,PB1_OV_REM:1,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:23,PUNCH_NUMBER_VALID:26,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-6100-6140-02",SUBSYSTEM_DESCR:"Foam Ring Main",LOSH:"Y",MC_B30:"2022-06-16",A_OV_TOT:28,A_OV_COM:2,A_OV_REM:26,A_OV_PROG:7.14,A_OV_STATUS:"less than 70%",A_PG:7.14,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6100-6140-03",SUBSYSTEM_DESCR:"Helideck Foam Package",MC_B30:"2022-07-07",A_OV_TOT:35,A_OV_COM:5,A_OV_REM:30,A_OV_PROG:14.28,A_OV_STATUS:"less than 70%",A_PG:14.28,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-6100-6150-01",SUBSYSTEM_DESCR:"Topside Modules Deluged Systems",LOSH:"Y",MC_B30:"2022-06-16",A_OV_TOT:539,A_OV_COM:63,A_OV_REM:476,A_OV_PROG:11.68,A_OV_STATUS:"less than 70%",A_PG:11.68,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:12,PA_OV_COM:8,PA_OV_REM:4,PB1_OV_TOT:15,PB1_OV_COM:0,PB1_OV_REM:15,ACT_TAG_VALID:162,PUNCH_NUMBER_VALID:27,PUNCH_TAG_VALID:22},
  //   {SUBSYSTEM:"21-6100-6150-03",SUBSYSTEM_DESCR:"HULL Deck Deluged Systems",LOSH:"Y",MC_B30:"2022-06-16",A_OV_TOT:534,A_OV_COM:85,A_OV_REM:449,A_OV_PROG:15.91,A_OV_STATUS:"less than 70%",A_PG:15.91,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:8,PA_OV_COM:4,PA_OV_REM:4,PB1_OV_TOT:23,PB1_OV_COM:0,PB1_OV_REM:23,ACT_TAG_VALID:135,PUNCH_NUMBER_VALID:31,PUNCH_TAG_VALID:16},
  //   {SUBSYSTEM:"21-6170-6170-01",SUBSYSTEM_DESCR:"FF Portable Equipment ,extinguishers, etc … - HULL",MC_B30:"2022-06-16",A_OV_TOT:4,A_OV_COM:0,A_OV_REM:4,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6170-6170-02",SUBSYSTEM_DESCR:"FF Portable Equipment ,extinguishers, etc … - LQ",MC_B30:"2022-06-16",A_OV_TOT:8,A_OV_COM:0,A_OV_REM:8,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6170-6170-03",SUBSYSTEM_DESCR:"FF Portable Equipment ,extinguishers, etc … - Topsides",MC_B30:"2022-07-21",A_OV_TOT:18,A_OV_COM:0,A_OV_REM:18,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6200-6210-01",SUBSYSTEM_DESCR:"PAGA System Cabinets and Central Equipment",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:51,A_OV_COM:25,A_OV_REM:26,A_OV_PROG:49.01,A_OV_STATUS:"less than 70%",A_PG:49.01,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:19,PA_OV_COM:0,PA_OV_REM:19,PB1_OV_TOT:11,PB1_OV_COM:8,PB1_OV_REM:3,PB2_OV_TOT:10,PB2_OV_COM:0,PB2_OV_REM:10,ACT_TAG_VALID:48,PUNCH_NUMBER_VALID:40,PUNCH_TAG_VALID:12},
  //   {SUBSYSTEM:"21-6200-6210-02",SUBSYSTEM_DESCR:"PAGA Field Equipment ME05andMW05",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:731,A_OV_COM:0,A_OV_REM:731,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:498,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0},
  //   {SUBSYSTEM:"21-6200-6210-03",SUBSYSTEM_DESCR:"PAGA Field Equipment ME02andMW02",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:764,A_OV_COM:187,A_OV_REM:577,A_OV_PROG:24.47,A_OV_STATUS:"less than 70%",A_PG:24.47,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,PB2_OV_TOT:7,PB2_OV_COM:0,PB2_OV_REM:7,ACT_TAG_VALID:520,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:9},
  //   {SUBSYSTEM:"21-6200-6210-04",SUBSYSTEM_DESCR:"PAGA Field Equipment ME04andMW04",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:366,A_OV_COM:319,A_OV_REM:47,A_OV_PROG:87.15,A_OV_STATUS:"NA",A_PG:87.15,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:8,PB1_OV_COM:0,PB1_OV_REM:8,PB2_OV_TOT:8,PB2_OV_COM:0,PB2_OV_REM:8,ACT_TAG_VALID:252,PUNCH_NUMBER_VALID:17,PUNCH_TAG_VALID:14},
  //   {SUBSYSTEM:"21-6200-6210-05",SUBSYSTEM_DESCR:"PAGA Field Equipment ME01andMW01",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:972,A_OV_COM:266,A_OV_REM:706,A_OV_PROG:27.36,A_OV_STATUS:"less than 70%",A_PG:27.36,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,PB2_OV_TOT:5,PB2_OV_COM:0,PB2_OV_REM:5,ACT_TAG_VALID:660,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:5},
  //   {SUBSYSTEM:"21-6200-6210-06",SUBSYSTEM_DESCR:"PAGA Field Equipment Topsides HULL Deck",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:790,A_OV_COM:30,A_OV_REM:760,A_OV_PROG:3.79,A_OV_STATUS:"less than 70%",A_PG:3.79,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:6,PB1_OV_COM:4,PB1_OV_REM:2,PB2_OV_TOT:8,PB2_OV_COM:0,PB2_OV_REM:8,ACT_TAG_VALID:534,PUNCH_NUMBER_VALID:15,PUNCH_TAG_VALID:8},
  //   {SUBSYSTEM:"21-6200-6210-07",SUBSYSTEM_DESCR:"PAGA Field Equipment LQ HULL Deck A",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:594,A_OV_COM:532,A_OV_REM:62,A_OV_PROG:89.56,A_OV_STATUS:"NA",A_PG:89.56,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:32,PA_OV_COM:7,PA_OV_REM:25,PB1_OV_TOT:37,PB1_OV_COM:34,PB1_OV_REM:3,PB2_OV_TOT:28,PB2_OV_COM:5,PB2_OV_REM:23,ACT_TAG_VALID:398,PUNCH_NUMBER_VALID:97,PUNCH_TAG_VALID:70},
  //   {SUBSYSTEM:"21-6200-6210-08",SUBSYSTEM_DESCR:"PAGA Field Equipment LQ Levels 1 and 2",LOSH:"Y",MC_B30:"2022-03-10",A_OV_TOT:624,A_OV_COM:595,A_OV_REM:29,A_OV_PROG:95.35,A_OV_STATUS:"greater than 95%",A_PG:95.35,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:70,PA_OV_COM:10,PA_OV_REM:60,PB1_OV_TOT:46,PB1_OV_COM:37,PB1_OV_REM:9,PB2_OV_TOT:17,PB2_OV_COM:2,PB2_OV_REM:15,ACT_TAG_VALID:420,PUNCH_NUMBER_VALID:133,PUNCH_TAG_VALID:94},
  //   {SUBSYSTEM:"21-6200-6210-09",SUBSYSTEM_DESCR:"PAGA Field Equipment LQ Levels 3 and 4",LOSH:"Y",MC_B30:"2022-03-10",A_OV_TOT:1332,A_OV_COM:1332,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-03",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:69,PA_OV_COM:69,PA_OV_REM:0,PB1_OV_TOT:51,PB1_OV_COM:50,PB1_OV_REM:1,PB2_OV_TOT:13,PB2_OV_COM:1,PB2_OV_REM:12,ACT_TAG_VALID:892,PUNCH_NUMBER_VALID:133,PUNCH_TAG_VALID:99},
  //   {SUBSYSTEM:"21-6200-6210-10",SUBSYSTEM_DESCR:"PAGA Field Equipment LQ Level 5 and LQ Roof",LOSH:"Y",MC_B30:"2022-03-10",A_OV_TOT:368,A_OV_COM:368,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-03",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:36,PA_OV_COM:36,PA_OV_REM:0,PB1_OV_TOT:37,PB1_OV_COM:31,PB1_OV_REM:6,PB2_OV_TOT:14,PB2_OV_COM:0,PB2_OV_REM:14,ACT_TAG_VALID:248,PUNCH_NUMBER_VALID:87,PUNCH_TAG_VALID:57},
  //   {SUBSYSTEM:"21-6200-6210-11",SUBSYSTEM_DESCR:"PAGA Field Equipment Machinery Space 1st and 2nd Levels",LOSH:"Y",MC_B30:"2022-02-24",A_OV_TOT:370,A_OV_COM:370,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-12-28",WD_ACTUAL:"2021-12-29",MC_APPROVED:"2022-03-02",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:7,PA_OV_COM:7,PA_OV_REM:0,PB1_OV_TOT:16,PB1_OV_COM:14,PB1_OV_REM:2,PB2_OV_TOT:4,PB2_OV_COM:0,PB2_OV_REM:4,ACT_TAG_VALID:248,PUNCH_NUMBER_VALID:27,PUNCH_TAG_VALID:9},
  //   {SUBSYSTEM:"21-6200-6210-12",SUBSYSTEM_DESCR:"PAGA Field Equipment Machinery Space 3rd Level",LOSH:"Y",MC_B30:"2022-02-24",A_OV_TOT:250,A_OV_COM:250,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-12-28",WD_ACTUAL:"2021-12-29",MC_APPROVED:"2022-03-02",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:7,PA_OV_COM:7,PA_OV_REM:0,PB1_OV_TOT:11,PB1_OV_COM:9,PB1_OV_REM:2,PB2_OV_TOT:5,PB2_OV_COM:0,PB2_OV_REM:5,ACT_TAG_VALID:168,PUNCH_NUMBER_VALID:23,PUNCH_TAG_VALID:10},
  //   {SUBSYSTEM:"21-6200-6224-01",SUBSYSTEM_DESCR:"Digital UHF Network",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:235,A_OV_COM:33,A_OV_REM:202,A_OV_PROG:14.04,A_OV_STATUS:"less than 70%",A_PG:14.04,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,PB1_OV_TOT:2,PB1_OV_COM:2,PB1_OV_REM:0,PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:235,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:6},
  //   {SUBSYSTEM:"21-6200-6225-01",SUBSYSTEM_DESCR:"VHF ,AM Aeronautical Radio",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:22,A_OV_COM:6,A_OV_REM:16,A_OV_PROG:27.27,A_OV_STATUS:"less than 70%",A_PG:27.27,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P1",PB1_OV_TOT:2,PB1_OV_COM:2,PB1_OV_REM:0,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-6200-6226-01",SUBSYSTEM_DESCR:"Non-Directional Beacon ,NDB",MC_B30:"2022-04-14",A_OV_TOT:21,A_OV_COM:0,A_OV_REM:21,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6200-6227-01",SUBSYSTEM_DESCR:"Crane Radio System",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:56,A_OV_COM:0,A_OV_REM:56,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:44,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6200-6228-01",SUBSYSTEM_DESCR:"VHF ,FM Marine Radio",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:20,A_OV_COM:0,A_OV_REM:20,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6300-6320-01",SUBSYSTEM_DESCR:"Lifeboats",MC_B30:"2022-06-02",A_OV_TOT:117,A_OV_COM:20,A_OV_REM:97,A_OV_PROG:17.09,A_OV_STATUS:"less than 70%",A_PG:17.09,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:4,PB1_OV_COM:4,PB1_OV_REM:0,ACT_TAG_VALID:96,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:4},
  //   {SUBSYSTEM:"21-6300-6330-01",SUBSYSTEM_DESCR:"Escape Chutes",MC_B30:"2022-06-23",A_OV_TOT:21,A_OV_COM:0,A_OV_REM:21,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6400-6450-01",SUBSYSTEM_DESCR:"Life Saving Portable Equipment ,Live Rafts ,davit type, BA sets, Buoys, Survival Suits, etc … - HULL",MC_B30:"2022-06-23",A_OV_TOT:44,A_OV_COM:0,A_OV_REM:44,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6400-6450-02",SUBSYSTEM_DESCR:"Life Saving Portable Equipment ,Live Rafts ,davit type, BA sets, Buoys, Survival Suits, etc … - LQ",MC_B30:"2022-06-23",A_OV_TOT:8,A_OV_COM:0,A_OV_REM:8,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6400-6450-03",SUBSYSTEM_DESCR:"Life Saving Portable Equipment ,Live Rafts ,davit type, BA sets, Buoys, Survival Suits, etc … - Topsides",MC_B30:"2022-08-04",A_OV_TOT:5,A_OV_COM:0,A_OV_REM:5,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6500-6510-01",SUBSYSTEM_DESCR:"Navigation Lights Control Panel and Associated Lanterns andFoghorns Circuits",MC_B30:"2022-03-31",A_OV_TOT:75,A_OV_COM:4,A_OV_REM:71,A_OV_PROG:5.33,A_OV_STATUS:"less than 70%",A_PG:5.33,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:11,PA_OV_COM:9,PA_OV_REM:2,ACT_TAG_VALID:55,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:5},
  //   {SUBSYSTEM:"21-6500-6520-02",SUBSYSTEM_DESCR:"Helideck Lights Control Panel and Associated Beacons",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:223,A_OV_COM:55,A_OV_REM:168,A_OV_PROG:24.66,A_OV_STATUS:"less than 70%",A_PG:24.66,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:20,PA_OV_COM:19,PA_OV_REM:1,ACT_TAG_VALID:158,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-6500-6550-01",SUBSYSTEM_DESCR:"RADAR Vessel Early Warning System",LOSH:"Y",MC_B30:"2022-04-21",A_OV_TOT:58,A_OV_COM:11,A_OV_REM:47,A_OV_PROG:18.96,A_OV_STATUS:"less than 70%",A_PG:18.96,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,PB1_OV_TOT:2,PB1_OV_COM:2,PB1_OV_REM:0,PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:58,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:4},
  //   {SUBSYSTEM:"21-6500-6556-01",SUBSYSTEM_DESCR:"Automatic Identification System",LOSH:"Y",MC_B30:"2022-04-21",A_OV_TOT:4,A_OV_COM:0,A_OV_REM:4,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6600-6610-01",SUBSYSTEM_DESCR:"Emergency Generator 21-G-661001",BATCH:"Incentive 1",LOSH:"Y",MC_B30:"2022-03-24",A_OV_TOT:225,A_OV_COM:94,A_OV_REM:131,A_OV_PROG:41.77,A_OV_STATUS:"less than 70%",A_PG:41.77,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:26,PA_OV_COM:11,PA_OV_REM:15,PB1_OV_TOT:36,PB1_OV_COM:23,PB1_OV_REM:13,PB2_OV_TOT:7,PB2_OV_COM:0,PB2_OV_REM:7,ACT_TAG_VALID:163,PUNCH_NUMBER_VALID:69,PUNCH_TAG_VALID:15},
  //   {SUBSYSTEM:"21-6600-6624-01",SUBSYSTEM_DESCR:"LQ Emergency Lighting Panel and Circuits ,Room: LH91 21-ELP-662460",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-24",A_OV_TOT:235,A_OV_COM:67,A_OV_REM:168,A_OV_PROG:28.51,A_OV_STATUS:"less than 70%",A_PG:28.51,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PB1_OV_TOT:10,PB1_OV_COM:9,PB1_OV_REM:1,PB2_OV_TOT:13,PB2_OV_COM:7,PB2_OV_REM:6,ACT_TAG_VALID:212,PUNCH_NUMBER_VALID:25,PUNCH_TAG_VALID:10},
  //   {SUBSYSTEM:"21-6600-6624-02",SUBSYSTEM_DESCR:"LQ Emergency Lighting Panel and Circuits ,Room: L224 21-ELP-662461",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:159,A_OV_COM:52,A_OV_REM:107,A_OV_PROG:32.7,A_OV_STATUS:"less than 70%",A_PG:32.7,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PB1_OV_TOT:10,PB1_OV_COM:7,PB1_OV_REM:3,PB2_OV_TOT:7,PB2_OV_COM:5,PB2_OV_REM:2,ACT_TAG_VALID:138,PUNCH_NUMBER_VALID:19,PUNCH_TAG_VALID:7},
  //   {SUBSYSTEM:"21-6600-6624-03",SUBSYSTEM_DESCR:"LQ Emergency Lighting Panel and Circuits ,Room: L320 21-ELP-662462",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-01-27",A_OV_TOT:39,A_OV_COM:33,A_OV_REM:6,A_OV_PROG:84.61,A_OV_STATUS:"NA",A_PG:84.61,RFI_ISSUED:"2021-12-29",WD_ACTUAL:"2021-12-30",MC_APPROVED:"2022-01-27",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:5,PA_OV_COM:5,PA_OV_REM:0,PB1_OV_TOT:10,PB1_OV_COM:7,PB1_OV_REM:3,PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:17,PUNCH_TAG_VALID:6},
  //   {SUBSYSTEM:"21-6600-6624-04",SUBSYSTEM_DESCR:"LQ Emergency Lighting Panel and Circuits ,Room: L501 21-ELP-662463",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:198,A_OV_COM:196,A_OV_REM:2,A_OV_PROG:98.98,A_OV_STATUS:"greater than 95%",A_PG:98.98,RFI_ISSUED:"Done",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:11,PB1_OV_COM:9,PB1_OV_REM:2,PB2_OV_TOT:3,PB2_OV_COM:1,PB2_OV_REM:2,ACT_TAG_VALID:173,PUNCH_NUMBER_VALID:14,PUNCH_TAG_VALID:10},
  //   {SUBSYSTEM:"21-6600-6624-05",SUBSYSTEM_DESCR:"HULL Emergency Lighting Panel and Circuits ,Room: M104 21-ELP-662401",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:179,A_OV_COM:44,A_OV_REM:135,A_OV_PROG:24.58,A_OV_STATUS:"less than 70%",A_PG:24.58,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PA_OV_TOT:7,PA_OV_COM:7,PA_OV_REM:0,PB1_OV_TOT:43,PB1_OV_COM:43,PB1_OV_REM:0,PB2_OV_TOT:13,PB2_OV_COM:2,PB2_OV_REM:11,ACT_TAG_VALID:150,PUNCH_NUMBER_VALID:63,PUNCH_TAG_VALID:20},
  //   {SUBSYSTEM:"21-6600-6640-01",SUBSYSTEM_DESCR:"LQandHULL Emergency Lighting and small power 400V board 21-EDB-662401 ,in LQ",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2021-12-02",A_OV_TOT:41,A_OV_COM:41,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-11-09",WD_ACTUAL:"2021-11-10",MC_APPROVED:"2021-12-02",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1",PA_OV_TOT:16,PA_OV_COM:16,PA_OV_REM:0,PB1_OV_TOT:7,PB1_OV_COM:7,PB1_OV_REM:0,PB2_OV_TOT:2,PB2_OV_COM:1,PB2_OV_REM:1,ACT_TAG_VALID:41,PUNCH_NUMBER_VALID:25,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-6600-6640-02",SUBSYSTEM_DESCR:"Topsides Emergency Lighting and small power 400V board 21-EDB-662402 ,in LER",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:3022,A_OV_COM:691,A_OV_REM:2331,A_OV_PROG:22.86,A_OV_STATUS:"less than 70%",A_PG:22.86,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1B",PA_OV_TOT:21,PA_OV_COM:17,PA_OV_REM:4,PB1_OV_TOT:17,PB1_OV_COM:0,PB1_OV_REM:17,PB2_OV_TOT:8,PB2_OV_COM:0,PB2_OV_REM:8,ACT_TAG_VALID:2962,PUNCH_NUMBER_VALID:46,PUNCH_TAG_VALID:22},
  //   {SUBSYSTEM:"21-6600-6640-03",SUBSYSTEM_DESCR:"Sub Distribution Auxiliary Panel 01 ,ROOM: LH16 21-EDB-667671",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2021-12-30",A_OV_TOT:3,A_OV_COM:3,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-12-16",WD_ACTUAL:"2021-12-17",MC_APPROVED:"2021-12-30",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P2",PA_OV_TOT:4,PA_OV_COM:4,PA_OV_REM:0,PB1_OV_TOT:6,PB1_OV_COM:6,PB1_OV_REM:0,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-6600-6640-04",SUBSYSTEM_DESCR:"Sub Distribution Auxiliary Panel 03 ,ROOM: M10621-EDB-662433",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2021-12-02",A_OV_TOT:3,A_OV_COM:3,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-11-24",WD_ACTUAL:"2021-11-25",MC_APPROVED:"2021-12-02",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:5,PA_OV_COM:5,PA_OV_REM:0,PB1_OV_TOT:6,PB1_OV_COM:6,PB1_OV_REM:0,PB2_OV_TOT:1,PB2_OV_COM:1,PB2_OV_REM:0,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-6600-6640-05",SUBSYSTEM_DESCR:"Panel Control For External Lighting - 21-ECP-667680",BATCH:"Incentive 2",MC_B30:"2022-03-24",A_OV_TOT:37,A_OV_COM:1,A_OV_REM:36,A_OV_PROG:2.7,A_OV_STATUS:"less than 70%",A_PG:2.7,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:35,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6600-6660-01",SUBSYSTEM_DESCR:"Emergency 690 V Switchboard 21-ESB-666001",BATCH:"Incentive 1",LOSH:"Y",MC_B30:"2021-11-04",A_OV_TOT:233,A_OV_COM:221,A_OV_REM:12,A_OV_PROG:94.84,A_OV_STATUS:"greater than 90%",A_PG:94.84,RFI_ISSUED:"2021-10-07",WD_ACTUAL:"2021-10-08",MC_APPROVED:"2021-10-30",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1A",PA_OV_TOT:10,PA_OV_COM:10,PA_OV_REM:0,PB1_OV_TOT:8,PB1_OV_COM:7,PB1_OV_REM:1,PB2_OV_TOT:38,PB2_OV_COM:32,PB2_OV_REM:6,ACT_TAG_VALID:233,PUNCH_NUMBER_VALID:56,PUNCH_TAG_VALID:16},
  //   {SUBSYSTEM:"21-6600-6670-01",SUBSYSTEM_DESCR:"Key service Generator 21-G-667001",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-24",A_OV_TOT:144,A_OV_COM:144,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-20",MC_APPROVED:"2022-02-14",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1A",PA_OV_TOT:68,PA_OV_COM:68,PA_OV_REM:0,PB1_OV_TOT:80,PB1_OV_COM:74,PB1_OV_REM:6,PB2_OV_TOT:49,PB2_OV_COM:27,PB2_OV_REM:22,ACT_TAG_VALID:98,PUNCH_NUMBER_VALID:197,PUNCH_TAG_VALID:32},
  //   {SUBSYSTEM:"21-6600-6670-02",SUBSYSTEM_DESCR:"Key service Generator 21-G-667002",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-24",A_OV_TOT:137,A_OV_COM:137,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-21",MC_APPROVED:"2022-02-14",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1A",PA_OV_TOT:45,PA_OV_COM:45,PA_OV_REM:0,PB1_OV_TOT:70,PB1_OV_COM:66,PB1_OV_REM:4,PB2_OV_TOT:24,PB2_OV_COM:7,PB2_OV_REM:17,ACT_TAG_VALID:91,PUNCH_NUMBER_VALID:139,PUNCH_TAG_VALID:23},
  //   {SUBSYSTEM:"21-6600-6673-01",SUBSYSTEM_DESCR:"Essential 6.6 kV Switchboard 21-ESB-667301",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2021-11-25",A_OV_TOT:292,A_OV_COM:207,A_OV_REM:85,A_OV_PROG:70.89,A_OV_STATUS:"NA",A_PG:70.89,RFI_ISSUED:"2021-11-06",WD_ACTUAL:"2021-11-08",MC_APPROVED:"2021-11-20",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:11,PA_OV_COM:11,PA_OV_REM:0,PB1_OV_TOT:41,PB1_OV_COM:30,PB1_OV_REM:11,PB2_OV_TOT:5,PB2_OV_COM:0,PB2_OV_REM:5,ACT_TAG_VALID:292,PUNCH_NUMBER_VALID:57,PUNCH_TAG_VALID:17},
  //   {SUBSYSTEM:"21-6600-6674-01",SUBSYSTEM_DESCR:"HULL Normal and Essential 690 V Swtchboard 21-ESB-667401",BATCH:"Incentive 1",LOSH:"Y",MC_B30:"2021-09-30",A_OV_TOT:317,A_OV_COM:315,A_OV_REM:2,A_OV_PROG:99.36,A_OV_STATUS:"greater than 95%",A_PG:99.36,RFI_ISSUED:"2021-08-18",WD_ACTUAL:"2021-08-19",MC_APPROVED:"2021-09-29",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:9,PA_OV_COM:9,PA_OV_REM:0,PB1_OV_TOT:12,PB1_OV_COM:11,PB1_OV_REM:1,PB2_OV_TOT:37,PB2_OV_COM:33,PB2_OV_REM:4,ACT_TAG_VALID:317,PUNCH_NUMBER_VALID:58,PUNCH_TAG_VALID:11},
  //   {SUBSYSTEM:"21-6600-6674-02",SUBSYSTEM_DESCR:"Topside Essential 690 V Switchboard 21-ESB-667402",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:239,A_OV_COM:218,A_OV_REM:21,A_OV_PROG:91.21,A_OV_STATUS:"greater than 90%",A_PG:91.21,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1B",PA_OV_TOT:3,PA_OV_COM:3,PA_OV_REM:0,PB1_OV_TOT:52,PB1_OV_COM:15,PB1_OV_REM:37,PB2_OV_TOT:5,PB2_OV_COM:0,PB2_OV_REM:5,ACT_TAG_VALID:239,PUNCH_NUMBER_VALID:60,PUNCH_TAG_VALID:15},
  //   {SUBSYSTEM:"21-6600-6675-01",SUBSYSTEM_DESCR:"HULL Normal and Essential 400 V Switchboard 21-ESB-667601",BATCH:"Incentive 1",LOSH:"Y",MC_B30:"2021-10-21",A_OV_TOT:60,A_OV_COM:58,A_OV_REM:2,A_OV_PROG:96.66,A_OV_STATUS:"greater than 95%",A_PG:96.66,RFI_ISSUED:"2021-08-18",WD_ACTUAL:"2021-08-19",MC_APPROVED:"2021-10-20",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:8,PA_OV_COM:8,PA_OV_REM:0,PB1_OV_TOT:9,PB1_OV_COM:8,PB1_OV_REM:1,PB2_OV_TOT:24,PB2_OV_COM:20,PB2_OV_REM:4,ACT_TAG_VALID:60,PUNCH_NUMBER_VALID:41,PUNCH_TAG_VALID:9},
  //   {SUBSYSTEM:"21-6600-6675-02",SUBSYSTEM_DESCR:"Topsides Normal and Essential lighting and Small Power 400V 21-EDB-667602",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:195,A_OV_COM:31,A_OV_REM:164,A_OV_PROG:15.89,A_OV_STATUS:"less than 70%",A_PG:15.89,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:3,PB1_OV_COM:2,PB1_OV_REM:1,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:148,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-6600-6675-03",SUBSYSTEM_DESCR:"Topsides Normal and Essential lighting and Small Power 400V 21-EDB-667603",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:2832,A_OV_COM:627,A_OV_REM:2205,A_OV_PROG:22.13,A_OV_STATUS:"less than 70%",A_PG:22.13,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PB1_OV_TOT:18,PB1_OV_COM:0,PB1_OV_REM:18,PB2_OV_TOT:15,PB2_OV_COM:0,PB2_OV_REM:15,ACT_TAG_VALID:2755,PUNCH_NUMBER_VALID:35,PUNCH_TAG_VALID:26},
  //   {SUBSYSTEM:"21-6600-6675-04",SUBSYSTEM_DESCR:"Topsides MOV and Convenience Outlets 400V 21-EDB-667604",BATCH:"Incentive 2",MC_B30:"2022-03-31",A_OV_TOT:242,A_OV_COM:27,A_OV_REM:215,A_OV_PROG:11.15,A_OV_STATUS:"less than 70%",A_PG:11.15,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:11,PA_OV_COM:0,PA_OV_REM:11,ACT_TAG_VALID:215,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-6600-6675-05",SUBSYSTEM_DESCR:"Topsides Misc Feeders and Auxiliaries 400V 21-EDB-667606",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:30,A_OV_COM:1,A_OV_REM:29,A_OV_PROG:3.33,A_OV_STATUS:"less than 70%",A_PG:3.33,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:16,PA_OV_COM:6,PA_OV_REM:10,PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,ACT_TAG_VALID:30,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-6600-6675-06",SUBSYSTEM_DESCR:"Sub Distribution Auxiliary Panel 02 ,ROOM: M303 21-EDB-667635",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2021-10-28",A_OV_TOT:3,A_OV_COM:3,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-08-25",WD_ACTUAL:"2021-08-26",MC_APPROVED:"2021-10-28",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:13,PA_OV_COM:13,PA_OV_REM:0,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-6600-6676-01",SUBSYSTEM_DESCR:"Normal Lighting Panel 01 ,ROOM: M103 21-ELP-667630",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-01-27",A_OV_TOT:56,A_OV_COM:49,A_OV_REM:7,A_OV_PROG:87.5,A_OV_STATUS:"NA",A_PG:87.5,RFI_ISSUED:"2021-12-28",WD_ACTUAL:"2021-12-29",MC_APPROVED:"2022-01-22",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:3,PA_OV_REM:0,PB1_OV_TOT:40,PB1_OV_COM:40,PB1_OV_REM:0,PB2_OV_TOT:6,PB2_OV_COM:1,PB2_OV_REM:5,ACT_TAG_VALID:30,PUNCH_NUMBER_VALID:49,PUNCH_TAG_VALID:18,COMMENTS:"Y"},
  //   {SUBSYSTEM:"21-6600-6676-02",SUBSYSTEM_DESCR:"Normal Lighting Panel 02 ,ROOM: M303 21-ELP-667631",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2021-12-16",A_OV_TOT:50,A_OV_COM:50,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-10-11",WD_ACTUAL:"2021-10-12",MC_APPROVED:"2021-12-10",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P2",PA_OV_TOT:31,PA_OV_COM:31,PA_OV_REM:0,PB1_OV_TOT:11,PB1_OV_COM:11,PB1_OV_REM:0,PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,PB3_OV_TOT:1,PB3_OV_COM:1,PB3_OV_REM:0,ACT_TAG_VALID:28,PUNCH_NUMBER_VALID:46,PUNCH_TAG_VALID:21},
  //   {SUBSYSTEM:"21-6600-6676-03",SUBSYSTEM_DESCR:"Machinary Workshop Power Panel ,ROOM: M104 21-ELP-667636",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:45,A_OV_COM:8,A_OV_REM:37,A_OV_PROG:17.77,A_OV_STATUS:"less than 70%",A_PG:17.77,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:43,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-6600-6676-04",SUBSYSTEM_DESCR:"LQ Small Power for LQ Aux Eqt ,ROOM: LH05 21-ELP-667672",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:177,A_OV_COM:70,A_OV_REM:107,A_OV_PROG:39.54,A_OV_STATUS:"less than 70%",A_PG:39.54,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:17,PB1_OV_COM:16,PB1_OV_REM:1,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:126,PUNCH_NUMBER_VALID:19,PUNCH_TAG_VALID:13},
  //   {SUBSYSTEM:"21-6600-6676-05",SUBSYSTEM_DESCR:"LQ Galley Panel 01 ,ROOM: L210 21-ELP-667673",LOSH:"Y",MC_B30:"2021-12-02",A_OV_TOT:4,A_OV_COM:4,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-11-03",WD_ACTUAL:"2021-11-04",MC_APPROVED:"2021-12-02",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P2",PA_OV_TOT:14,PA_OV_COM:14,PA_OV_REM:0,PB1_OV_TOT:8,PB1_OV_COM:8,PB1_OV_REM:0,PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:24,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-6600-6676-06",SUBSYSTEM_DESCR:"Normal Lighting Panel 05 ,ROOM: LH05 21-ELP-667660",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-24",A_OV_TOT:79,A_OV_COM:21,A_OV_REM:58,A_OV_PROG:26.58,A_OV_STATUS:"less than 70%",A_PG:26.58,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:7,PB1_OV_COM:7,PB1_OV_REM:0,PB2_OV_TOT:7,PB2_OV_COM:5,PB2_OV_REM:2,ACT_TAG_VALID:64,PUNCH_NUMBER_VALID:15,PUNCH_TAG_VALID:9},
  //   {SUBSYSTEM:"21-6600-6676-07",SUBSYSTEM_DESCR:"Normal Lighting Panel 06 ,ROOM: LA17 21-ELP-667661",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-10",A_OV_TOT:96,A_OV_COM:41,A_OV_REM:55,A_OV_PROG:42.7,A_OV_STATUS:"less than 70%",A_PG:42.7,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:2,PA_OV_REM:1,PB1_OV_TOT:5,PB1_OV_COM:3,PB1_OV_REM:2,PB2_OV_TOT:3,PB2_OV_COM:1,PB2_OV_REM:2,ACT_TAG_VALID:70,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:5},
  //   {SUBSYSTEM:"21-6600-6676-08",SUBSYSTEM_DESCR:"Normal Lighting Panel 07 ,ROOM: L112 21-ELP-667662",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-10",A_OV_TOT:35,A_OV_COM:35,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-19",MC_APPROVED:"2022-03-01",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:11,PB1_OV_COM:8,PB1_OV_REM:3,PB2_OV_TOT:12,PB2_OV_COM:3,PB2_OV_REM:9,ACT_TAG_VALID:19,PUNCH_NUMBER_VALID:24,PUNCH_TAG_VALID:10},
  //   {SUBSYSTEM:"21-6600-6676-09",SUBSYSTEM_DESCR:"Normal Lighting Panel 08 ,ROOM: L225 21-ELP-667663",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:59,A_OV_COM:40,A_OV_REM:19,A_OV_PROG:67.79,A_OV_STATUS:"less than 70%",A_PG:67.79,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PB1_OV_TOT:5,PB1_OV_COM:5,PB1_OV_REM:0,PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:45,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:6},
  //   {SUBSYSTEM:"21-6600-6676-10",SUBSYSTEM_DESCR:"Normal Lighting Panel 09 ,ROOM: L345 21-ELP-667664",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-01-13",A_OV_TOT:83,A_OV_COM:83,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-12-16",WD_ACTUAL:"2021-12-20",MC_APPROVED:"2022-01-07",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P2",PA_OV_TOT:4,PA_OV_COM:4,PA_OV_REM:0,PB1_OV_TOT:13,PB1_OV_COM:13,PB1_OV_REM:0,PB2_OV_TOT:5,PB2_OV_COM:0,PB2_OV_REM:5,ACT_TAG_VALID:43,PUNCH_NUMBER_VALID:22,PUNCH_TAG_VALID:8},
  //   {SUBSYSTEM:"21-6600-6676-11",SUBSYSTEM_DESCR:"Normal Lighting Panel 10 ,ROOM: L445 21-ELP-667665",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-10",A_OV_TOT:98,A_OV_COM:98,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-22",MC_APPROVED:"2022-02-08",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P2",PB1_OV_TOT:6,PB1_OV_COM:6,PB1_OV_REM:0,PB2_OV_TOT:4,PB2_OV_COM:0,PB2_OV_REM:4,ACT_TAG_VALID:57,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:7},
  //   {SUBSYSTEM:"21-6600-6676-12",SUBSYSTEM_DESCR:"Normal Lighting Panel 11 ,ROOM: L591 21-ELP-667666",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:88,A_OV_COM:88,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-19",MC_APPROVED:"2022-03-08",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:7,PA_OV_COM:7,PA_OV_REM:0,PB1_OV_TOT:12,PB1_OV_COM:7,PB1_OV_REM:5,PB2_OV_TOT:6,PB2_OV_COM:0,PB2_OV_REM:6,ACT_TAG_VALID:74,PUNCH_NUMBER_VALID:25,PUNCH_TAG_VALID:11},
  //   {SUBSYSTEM:"21-6600-6676-13",SUBSYSTEM_DESCR:"LQ Laundry Panel 01 ,ROOM: L115 21-ELP-667674",LOSH:"Y",MC_B30:"2021-11-04",A_OV_TOT:4,A_OV_COM:4,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-08-27",WD_ACTUAL:"2021-08-30",MC_APPROVED:"2021-10-29",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:7,PA_OV_COM:7,PA_OV_REM:0,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-6600-6676-14",SUBSYSTEM_DESCR:"LQ Laboratory Distribution Board ,ROOM: LA04 21-ELP-667675",LOSH:"Y",MC_B30:"2021-12-09",A_OV_TOT:4,A_OV_COM:4,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-11-06",WD_ACTUAL:"2021-11-08",MC_APPROVED:"2021-12-04",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:13,PA_OV_COM:13,PA_OV_REM:0,PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,PB2_OV_TOT:3,PB2_OV_COM:3,PB2_OV_REM:0,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:17,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-6600-6676-15",SUBSYSTEM_DESCR:"LQ Laundry Panel 02 ,ROOM: L115 21-ELP-667677",LOSH:"Y",MC_B30:"2021-10-28",A_OV_TOT:4,A_OV_COM:4,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-10-07",WD_ACTUAL:"2021-10-08",MC_APPROVED:"2021-10-28",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:3,PB1_OV_COM:3,PB1_OV_REM:0,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-6600-6676-16",SUBSYSTEM_DESCR:"LQ Galley Panel 02 ,ROOM: L220 21-ELP-667678",LOSH:"Y",MC_B30:"2021-12-02",A_OV_TOT:4,A_OV_COM:4,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-11-03",WD_ACTUAL:"2021-11-04",MC_APPROVED:"2021-12-02",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P2",PA_OV_TOT:12,PA_OV_COM:12,PA_OV_REM:0,PB1_OV_TOT:5,PB1_OV_COM:5,PB1_OV_REM:0,PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:19,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-6625-8725-01",SUBSYSTEM_DESCR:"Normal and Essential Heat Trace 400V Topsides 21-EDB-667605",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:4,A_OV_COM:0,A_OV_REM:4,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"B2",PRIORITY:"P2",PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-6700-6701-01",SUBSYSTEM_DESCR:"Main LQ UPS System ,Incl Batteries, Breakers and Subdistribution Board",BATCH:"Incentive 1",LOSH:"Y",MC_B30:"2021-11-04",A_OV_TOT:46,A_OV_COM:39,A_OV_REM:7,A_OV_PROG:84.78,A_OV_STATUS:"NA",A_PG:84.78,RFI_ISSUED:"2021-10-07",WD_ACTUAL:"2021-10-08",MC_APPROVED:"2021-10-30",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"N",PRIORITY:"P1A",PA_OV_TOT:31,PA_OV_COM:31,PA_OV_REM:0,PB1_OV_TOT:54,PB1_OV_COM:54,PB1_OV_REM:0,PB2_OV_TOT:1,PB2_OV_COM:1,PB2_OV_REM:0,ACT_TAG_VALID:42,PUNCH_NUMBER_VALID:86,PUNCH_TAG_VALID:18},
  //   {SUBSYSTEM:"21-6700-6701-02",SUBSYSTEM_DESCR:"LQ Telecom UPS System B for HULL and LQ ,Incl Batteries, Breakers and Subdistribution Board",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2021-12-02",A_OV_TOT:19,A_OV_COM:16,A_OV_REM:3,A_OV_PROG:84.21,A_OV_STATUS:"NA",A_PG:84.21,RFI_ISSUED:"2021-11-08",WD_ACTUAL:"2021-11-09",MC_APPROVED:"2021-11-26",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:5,PA_OV_COM:5,PA_OV_REM:0,PB1_OV_TOT:23,PB1_OV_COM:22,PB1_OV_REM:1,PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:30,PUNCH_TAG_VALID:4},
  //   {SUBSYSTEM:"21-6700-6701-03",SUBSYSTEM_DESCR:"LQ Telecom UPS System A for Topsides ,Incl Batteries, Breakers and Subdistribution Board",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:19,A_OV_COM:6,A_OV_REM:13,A_OV_PROG:31.57,A_OV_STATUS:"less than 70%",A_PG:31.57,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PB1_OV_TOT:14,PB1_OV_COM:12,PB1_OV_REM:2,ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:16,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-6700-6702-01",SUBSYSTEM_DESCR:"Topside Main UPS System ,Incl Batteries, Breakers and Subdistribution Board",BATCH:"Incentive 2",MC_B30:"2022-02-17",A_OV_TOT:45,A_OV_COM:45,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-12-31",WD_ACTUAL:"2022-01-03",MC_APPROVED:"2022-02-10",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1B",PA_OV_TOT:5,PA_OV_COM:5,PA_OV_REM:0,PB1_OV_TOT:43,PB1_OV_COM:19,PB1_OV_REM:24,PB2_OV_TOT:5,PB2_OV_COM:0,PB2_OV_REM:5,ACT_TAG_VALID:41,PUNCH_NUMBER_VALID:53,PUNCH_TAG_VALID:10},
  //   {SUBSYSTEM:"21-6700-6702-02",SUBSYSTEM_DESCR:"Subsea UPS System ,Incl Batteries, Breakers, Voltage Stabilisers and Subdistribution Board",LOSH:"Y",MC_B30:"2022-02-17",A_OV_TOT:34,A_OV_COM:34,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-12-31",WD_ACTUAL:"2022-01-03",MC_APPROVED:"2022-02-10",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1B",PA_OV_TOT:4,PA_OV_COM:4,PA_OV_REM:0,PB1_OV_TOT:37,PB1_OV_COM:17,PB1_OV_REM:20,PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:30,PUNCH_NUMBER_VALID:44,PUNCH_TAG_VALID:8},
  //   {SUBSYSTEM:"21-6800-6830-01",SUBSYSTEM_DESCR:"Fire and Gas Detecion - LQ",MC_B30:"2022-04-07",A_OV_TOT:296,A_OV_COM:173,A_OV_REM:123,A_OV_PROG:58.44,A_OV_STATUS:"less than 70%",A_PG:58.44,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:8,PB1_OV_COM:7,PB1_OV_REM:1,PB2_OV_TOT:8,PB2_OV_COM:0,PB2_OV_REM:8,ACT_TAG_VALID:148,PUNCH_NUMBER_VALID:16,PUNCH_TAG_VALID:11},
  //   {SUBSYSTEM:"21-6800-6830-02",SUBSYSTEM_DESCR:"Magnetic Door Holder  - LQ",MC_B30:"2022-06-09",A_OV_TOT:148,A_OV_COM:0,A_OV_REM:148,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:148,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6800-6840-01",SUBSYSTEM_DESCR:"Fire Zone 6840-1 - Power Generation Module-MW04 ,Open Area",MC_B30:"2022-04-28",A_OV_TOT:99,A_OV_COM:75,A_OV_REM:24,A_OV_PROG:75.75,A_OV_STATUS:"NA",A_PG:75.75,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:11,PB1_OV_COM:0,PB1_OV_REM:11,ACT_TAG_VALID:54,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:4},
  //   {SUBSYSTEM:"21-6800-6840-02",SUBSYSTEM_DESCR:"Fire Zone 6840-2 - Power Generation Module- ME04 ,Open Area",MC_B30:"2022-04-28",A_OV_TOT:102,A_OV_COM:38,A_OV_REM:64,A_OV_PROG:37.25,A_OV_STATUS:"less than 70%",A_PG:37.25,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:56,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6800-6840-03",SUBSYSTEM_DESCR:"Fire Zone 6840-3 - Gas Turbine Generator Package 21-Z-861101",MC_B30:"2022-03-24",A_OV_TOT:25,A_OV_COM:0,A_OV_REM:25,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6800-6840-04",SUBSYSTEM_DESCR:"Fire Zone 6840-4 - Gas Turbine Generator Package 21-Z-861201",MC_B30:"2022-03-24",A_OV_TOT:25,A_OV_COM:0,A_OV_REM:25,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6800-6840-05",SUBSYSTEM_DESCR:"Fire Zone 6840-5 - Gas Turbine Generator Package 21-Z-861301",MC_B30:"2022-03-24",A_OV_TOT:25,A_OV_COM:0,A_OV_REM:25,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6800-6840-06",SUBSYSTEM_DESCR:"Fire Zone 6840-6 - Gas Turbine Generator Package 21-Z-861401",MC_B30:"2022-03-24",A_OV_TOT:25,A_OV_COM:0,A_OV_REM:25,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6800-6840-07",SUBSYSTEM_DESCR:"Fire Zone 6840-7 - Utility Module–MW05",MC_B30:"2022-03-31",A_OV_TOT:140,A_OV_COM:19,A_OV_REM:121,A_OV_PROG:13.57,A_OV_STATUS:"less than 70%",A_PG:13.57,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:79,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6800-6840-08",SUBSYSTEM_DESCR:"Fire Zone 6840-8and9 -Pedestal Cranes ,WestandEast",MC_B30:"2022-05-26",A_OV_TOT:50,A_OV_COM:6,A_OV_REM:44,A_OV_PROG:12,A_OV_STATUS:"less than 70%",A_PG:12,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:34,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6800-6841-01",SUBSYSTEM_DESCR:"Fire Zone 6841-0 - Reception Facilities and Production Separation Module - MW01",MC_B30:"2022-06-02",A_OV_TOT:393,A_OV_COM:65,A_OV_REM:328,A_OV_PROG:16.53,A_OV_STATUS:"less than 70%",A_PG:16.53,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:214,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6800-6841-02",SUBSYSTEM_DESCR:"Fire Zone 6841-1 - Flash Gas Compression Module - ME01",MC_B30:"2022-05-12",A_OV_TOT:263,A_OV_COM:45,A_OV_REM:218,A_OV_PROG:17.11,A_OV_STATUS:"less than 70%",A_PG:17.11,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:142,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6800-6841-03",SUBSYSTEM_DESCR:"Fire Zone 6841-2 - Gas Dewpointing andMEG and Methanol injection and Fuel gas Module - MW02",MC_B30:"2022-05-12",A_OV_TOT:357,A_OV_COM:61,A_OV_REM:296,A_OV_PROG:17.08,A_OV_STATUS:"less than 70%",A_PG:17.08,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:190,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6800-6841-04",SUBSYSTEM_DESCR:"Fire Zone 6841-3 - Condensate stabilization and MEG regeneration and Produced Water treatment Module  - ME02",MC_B30:"2022-07-07",A_OV_TOT:278,A_OV_COM:41,A_OV_REM:237,A_OV_PROG:14.74,A_OV_STATUS:"less than 70%",A_PG:14.74,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P3",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:153,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-6800-6841-07",SUBSYSTEM_DESCR:"Fire Zone 6841-6 - Aft Platform on Deck A",MC_B30:"2022-06-09",A_OV_TOT:59,A_OV_COM:7,A_OV_REM:52,A_OV_PROG:11.86,A_OV_STATUS:"less than 70%",A_PG:11.86,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,ACT_TAG_VALID:30,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-6800-6841-08",SUBSYSTEM_DESCR:"Fire Zone 6841-7 - Piperack Between MW01 and ME01",MC_B30:"2022-07-07",A_OV_TOT:3,A_OV_COM:0,A_OV_REM:3,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6800-6841-09",SUBSYSTEM_DESCR:"Fire Zone 6841-8 - Piperack Between MW02 and ME02",MC_B30:"2022-06-16",A_OV_TOT:3,A_OV_COM:0,A_OV_REM:3,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6800-6842-01",SUBSYSTEM_DESCR:"Fire Zone 6842-0 - Cargo Tank Top ,Rich Meg, Methanol, Salty Rich MEG 2, Offspec Condensate, Slop, Wax Inhibitor, Produced Water Storage Tanks and Flare Facilities Area, Including Marine Piperack",MC_B30:"2022-04-21",A_OV_TOT:185,A_OV_COM:26,A_OV_REM:159,A_OV_PROG:14.05,A_OV_STATUS:"less than 70%",A_PG:14.05,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:97,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6800-6842-02",SUBSYSTEM_DESCR:"Fire Zone 6842-1 - Cargo Tank Top ,Condensate Tank 1 and 2 and Closed Drain Facilities",MC_B30:"2022-04-21",A_OV_TOT:130,A_OV_COM:21,A_OV_REM:109,A_OV_PROG:16.15,A_OV_STATUS:"less than 70%",A_PG:16.15,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:70,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6800-6842-03",SUBSYSTEM_DESCR:"Fire Zone 6842-2 - Cargo Tank Top ,Salty Rich MEG Tanks 3and4",MC_B30:"2022-04-21",A_OV_TOT:137,A_OV_COM:22,A_OV_REM:115,A_OV_PROG:16.05,A_OV_STATUS:"less than 70%",A_PG:16.05,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:74,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6800-6842-04",SUBSYSTEM_DESCR:"Fire Zone 6842-3 - Cargo Tank Top ,Condensate Tank 3 and 4",MC_B30:"2022-04-21",A_OV_TOT:86,A_OV_COM:12,A_OV_REM:74,A_OV_PROG:13.95,A_OV_STATUS:"less than 70%",A_PG:13.95,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:48,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6800-6842-05",SUBSYSTEM_DESCR:"Fire Zone 6842-4 - Cargo Tank Top ,Condensate Tank 5 and 6",MC_B30:"2022-04-21",A_OV_TOT:99,A_OV_COM:17,A_OV_REM:82,A_OV_PROG:17.17,A_OV_STATUS:"less than 70%",A_PG:17.17,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:54,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6800-6842-06",SUBSYSTEM_DESCR:"Fire Zone 6842-5 - Cargo Tank Top ,Condensate Tank 7 and 8",MC_B30:"2022-04-21",A_OV_TOT:163,A_OV_COM:17,A_OV_REM:146,A_OV_PROG:10.42,A_OV_STATUS:"less than 70%",A_PG:10.42,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:92,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6800-6842-07",SUBSYSTEM_DESCR:"Fire Zone 6842-6 - Cargo Tank Top ,Lean MEG Storage Tanks",MC_B30:"2022-04-21",A_OV_TOT:110,A_OV_COM:8,A_OV_REM:102,A_OV_PROG:7.27,A_OV_STATUS:"less than 70%",A_PG:7.27,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:56,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6800-6842-08",SUBSYSTEM_DESCR:"Fire Zone 6842-7 - Fire Water Pump Enclosure 611101",MC_B30:"2022-05-26",A_OV_STATUS:"NA",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6800-6842-09",SUBSYSTEM_DESCR:"Fire Zone 6842-8 - Fire Water Pump Enclosure 611201",MC_B30:"2022-05-26",A_OV_STATUS:"NA",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6800-6842-10",SUBSYSTEM_DESCR:"Fire Zone 6842-9 - Fire Water Pump Enclosure 611301",MC_B30:"2022-03-24",A_OV_STATUS:"NA",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6800-6842-11",SUBSYSTEM_DESCR:"Fire Zone 6843-0 - West Riser Balcony Area",MC_B30:"2022-04-28",A_OV_TOT:25,A_OV_COM:4,A_OV_REM:21,A_OV_PROG:16,A_OV_STATUS:"less than 70%",A_PG:16,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6800-6842-13",SUBSYSTEM_DESCR:"Fire Zone 6843-3 - Emergency Diesel Generators",MC_B30:"2022-03-24",A_OV_STATUS:"NA",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1,COMMENTS:"Y"},
  //   {SUBSYSTEM:"21-6800-6842-16",SUBSYSTEM_DESCR:"Fire Zone 6850 - General Topsides Area",MC_B30:"2022-07-21",A_OV_TOT:32,A_OV_COM:2,A_OV_REM:30,A_OV_PROG:6.25,A_OV_STATUS:"less than 70%",A_PG:6.25,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-6800-6844-01",SUBSYSTEM_DESCR:"Fire and Gas Detecion - Machinery Space",MC_B30:"2022-02-24",A_OV_TOT:73,A_OV_COM:73,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-27",MC_APPROVED:"2022-03-08",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:6,PA_OV_COM:6,PA_OV_REM:0,PB1_OV_TOT:6,PB1_OV_COM:2,PB1_OV_REM:4,PB2_OV_TOT:18,PB2_OV_COM:0,PB2_OV_REM:18,ACT_TAG_VALID:39,PUNCH_NUMBER_VALID:30,PUNCH_TAG_VALID:14},
  //   {SUBSYSTEM:"21-6800-6850-01",SUBSYSTEM_DESCR:"Fire and Gas Detecion - ME05",MC_B30:"2022-03-24",A_OV_TOT:80,A_OV_COM:18,A_OV_REM:62,A_OV_PROG:22.5,A_OV_STATUS:"less than 70%",A_PG:22.5,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:40,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1,COMMENTS:"Y"},
  //   {SUBSYSTEM:"21-6800-6854-01",SUBSYSTEM_DESCR:"Fire Zone 6854 - Transit Refuge Area",MC_B30:"2022-06-30",A_OV_TOT:24,A_OV_COM:6,A_OV_REM:18,A_OV_PROG:25,A_OV_STATUS:"less than 70%",A_PG:25,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8000-8010-01",SUBSYSTEM_DESCR:"Potable Water Maker",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:159,A_OV_COM:105,A_OV_REM:54,A_OV_PROG:66.03,A_OV_STATUS:"less than 70%",A_PG:66.03,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:31,PA_OV_COM:25,PA_OV_REM:6,PB1_OV_TOT:27,PB1_OV_COM:13,PB1_OV_REM:14,PB2_OV_TOT:18,PB2_OV_COM:0,PB2_OV_REM:18,ACT_TAG_VALID:81,PUNCH_NUMBER_VALID:76,PUNCH_TAG_VALID:10},
  //   {SUBSYSTEM:"21-8000-8010-02",SUBSYSTEM_DESCR:"Chlorination Package",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:22,A_OV_COM:17,A_OV_REM:5,A_OV_PROG:77.27,A_OV_STATUS:"NA",A_PG:77.27,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:7,PA_OV_COM:3,PA_OV_REM:4,PB1_OV_TOT:5,PB1_OV_COM:3,PB1_OV_REM:2,PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:14,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-8000-8011-01",SUBSYSTEM_DESCR:"Potable Water Tanks and Pumps",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:213,A_OV_COM:177,A_OV_REM:36,A_OV_PROG:83.09,A_OV_STATUS:"NA",A_PG:83.09,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:19,PA_OV_COM:11,PA_OV_REM:8,PB1_OV_TOT:17,PB1_OV_COM:9,PB1_OV_REM:8,PB2_OV_TOT:17,PB2_OV_COM:9,PB2_OV_REM:8,ACT_TAG_VALID:58,PUNCH_NUMBER_VALID:53,PUNCH_TAG_VALID:13},
  //   {SUBSYSTEM:"21-8000-8011-02",SUBSYSTEM_DESCR:"Fresh Water Bunkering Header",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:28,A_OV_COM:8,A_OV_REM:20,A_OV_PROG:28.57,A_OV_STATUS:"less than 70%",A_PG:28.57,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8000-8012-01",SUBSYSTEM_DESCR:"Rehardener and UV Sterilizer",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:28,A_OV_COM:27,A_OV_REM:1,A_OV_PROG:96.42,A_OV_STATUS:"greater than 95%",A_PG:96.42,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-22",MC_APPROVED:"2022-02-07",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:5,PA_OV_COM:5,PA_OV_REM:0,PB1_OV_TOT:8,PB1_OV_COM:6,PB1_OV_REM:2,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:14,PUNCH_TAG_VALID:4},
  //   {SUBSYSTEM:"21-8000-8012-02",SUBSYSTEM_DESCR:"Main Distribution and Potable Water Cooler",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:61,A_OV_COM:61,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-28",MC_APPROVED:"2022-02-28",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:15,PA_OV_COM:15,PA_OV_REM:0,PB1_OV_TOT:21,PB1_OV_COM:10,PB1_OV_REM:11,PB2_OV_TOT:14,PB2_OV_COM:4,PB2_OV_REM:10,ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:50,PUNCH_TAG_VALID:9},
  //   {SUBSYSTEM:"21-8000-8012-03",SUBSYSTEM_DESCR:"Cold Potable Water Distribution to HULL",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:133,A_OV_COM:89,A_OV_REM:44,A_OV_PROG:66.91,A_OV_STATUS:"less than 70%",A_PG:66.91,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:3,PB1_OV_COM:2,PB1_OV_REM:1,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:37,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-8000-8012-04",SUBSYSTEM_DESCR:"Cold Potable Water Distribution to LQ",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:18,A_OV_COM:14,A_OV_REM:4,A_OV_PROG:77.77,A_OV_STATUS:"NA",A_PG:77.77,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-8000-8012-05",SUBSYSTEM_DESCR:"Cold Potable Water Distribution to Topsides ,Safety Showers and Eye Baths",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:116,A_OV_COM:26,A_OV_REM:90,A_OV_PROG:22.41,A_OV_STATUS:"less than 70%",A_PG:22.41,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:26,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8000-8013-01",SUBSYSTEM_DESCR:"Hot Water Calorifiers",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:96,A_OV_COM:27,A_OV_REM:69,A_OV_PROG:28.12,A_OV_STATUS:"less than 70%",A_PG:28.12,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:24,PA_OV_COM:19,PA_OV_REM:5,PB1_OV_TOT:5,PB1_OV_COM:3,PB1_OV_REM:2,PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:33,PUNCH_NUMBER_VALID:32,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-8000-8013-02",SUBSYSTEM_DESCR:"Hot Water Distribution and Recirculation Pumps",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:207,A_OV_COM:140,A_OV_REM:67,A_OV_PROG:67.63,A_OV_STATUS:"less than 70%",A_PG:67.63,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:4,PA_OV_COM:2,PA_OV_REM:2,PB1_OV_TOT:3,PB1_OV_COM:3,PB1_OV_REM:0,PB2_OV_TOT:4,PB2_OV_COM:0,PB2_OV_REM:4,ACT_TAG_VALID:62,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:6},
  //   {SUBSYSTEM:"21-8000-8014-01",SUBSYSTEM_DESCR:"Fresh Service Water Tank and Pumps",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:186,A_OV_COM:182,A_OV_REM:4,A_OV_PROG:97.84,A_OV_STATUS:"greater than 95%",A_PG:97.84,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:9,PA_OV_COM:8,PA_OV_REM:1,PB1_OV_TOT:25,PB1_OV_COM:17,PB1_OV_REM:8,PB2_OV_TOT:16,PB2_OV_COM:7,PB2_OV_REM:9,ACT_TAG_VALID:63,PUNCH_NUMBER_VALID:50,PUNCH_TAG_VALID:16},
  //   {SUBSYSTEM:"21-8000-8014-02",SUBSYSTEM_DESCR:"Fresh Service Water Distribution to HULL",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:15,A_OV_COM:15,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-28",MC_APPROVED:"2022-02-28",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:6,PA_OV_COM:6,PA_OV_REM:0,PB1_OV_TOT:16,PB1_OV_COM:14,PB1_OV_REM:2,PB2_OV_TOT:12,PB2_OV_COM:1,PB2_OV_REM:11,ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:34,PUNCH_TAG_VALID:5},
  //   {SUBSYSTEM:"21-8000-8014-03",SUBSYSTEM_DESCR:"Fresh Service Water Distribution to Topsides",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:144,A_OV_COM:24,A_OV_REM:120,A_OV_PROG:16.66,A_OV_STATUS:"less than 70%",A_PG:16.66,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:32,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8000-8014-04",SUBSYSTEM_DESCR:"Fresh Service Water Distribution to Water Mist System 21-SWM-613001",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-01-06",A_OV_TOT:13,A_OV_COM:13,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-12-23",WD_ACTUAL:"2021-12-24",MC_APPROVED:"2021-12-31",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1",PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0},
  //   {SUBSYSTEM:"21-8000-8014-05",SUBSYSTEM_DESCR:"Fresh Service Water Distribution to LQ",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:67,A_OV_COM:67,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-13",MC_APPROVED:"2022-02-15",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:3,PA_OV_REM:0,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,PB2_OV_TOT:5,PB2_OV_COM:0,PB2_OV_REM:5,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:0},
  //   {SUBSYSTEM:"21-8020-8020-01",SUBSYSTEM_DESCR:"Marine Sea Water Sea Suction Tube and Filter 21-F-802001",LOSH:"Y",MC_B30:"2022-02-17",A_OV_TOT:33,A_OV_COM:33,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-14",MC_APPROVED:"2022-02-25",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P3",PA_OV_TOT:6,PA_OV_COM:6,PA_OV_REM:0,PB1_OV_TOT:18,PB1_OV_COM:18,PB1_OV_REM:0,PB2_OV_TOT:10,PB2_OV_COM:3,PB2_OV_REM:7,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:34,PUNCH_TAG_VALID:8},
  //   {SUBSYSTEM:"21-8020-8020-02",SUBSYSTEM_DESCR:"Marine Sea Water Sea Suction Tube and Filter 21-F-802002",LOSH:"Y",MC_B30:"2022-02-17",A_OV_TOT:32,A_OV_COM:32,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-14",MC_APPROVED:"2022-02-26",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P3",PA_OV_TOT:9,PA_OV_COM:9,PA_OV_REM:0,PB1_OV_TOT:8,PB1_OV_COM:8,PB1_OV_REM:0,PB2_OV_TOT:11,PB2_OV_COM:6,PB2_OV_REM:5,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:28,PUNCH_TAG_VALID:9},
  //   {SUBSYSTEM:"21-8020-8020-03",SUBSYSTEM_DESCR:"Marine Sea Water Main Distribution Header",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-17",A_OV_TOT:36,A_OV_COM:36,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-15",MC_APPROVED:"2022-02-26",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1A",PA_OV_TOT:3,PA_OV_COM:3,PA_OV_REM:0,PB1_OV_TOT:18,PB1_OV_COM:18,PB1_OV_REM:0,PB2_OV_TOT:23,PB2_OV_COM:5,PB2_OV_REM:18,ACT_TAG_VALID:21,PUNCH_NUMBER_VALID:44,PUNCH_TAG_VALID:5},
  //   {SUBSYSTEM:"21-8020-8021-01",SUBSYSTEM_DESCR:"IGG Scrubber Cooling Sea Water Pumps - 21-P802003AandB",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:83,A_OV_COM:58,A_OV_REM:25,A_OV_PROG:69.87,A_OV_STATUS:"less than 70%",A_PG:69.87,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:2,PA_OV_REM:1,PB1_OV_TOT:17,PB1_OV_COM:7,PB1_OV_REM:10,PB2_OV_TOT:6,PB2_OV_COM:0,PB2_OV_REM:6,ACT_TAG_VALID:38,PUNCH_NUMBER_VALID:26,PUNCH_TAG_VALID:12},
  //   {SUBSYSTEM:"21-8020-8021-02",SUBSYSTEM_DESCR:"IGG Scrubber Cooling Sea Water Distribution",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:27,A_OV_COM:7,A_OV_REM:20,A_OV_PROG:25.92,A_OV_STATUS:"less than 70%",A_PG:25.92,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8020-8023-01",SUBSYSTEM_DESCR:"IGG Deck Seal Sea Water Pumps - 21-P802002AandB",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:83,A_OV_COM:60,A_OV_REM:23,A_OV_PROG:72.28,A_OV_STATUS:"NA",A_PG:72.28,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:12,PB1_OV_COM:7,PB1_OV_REM:5,PB2_OV_TOT:5,PB2_OV_COM:0,PB2_OV_REM:5,ACT_TAG_VALID:34,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:9},
  //   {SUBSYSTEM:"21-8020-8023-02",SUBSYSTEM_DESCR:"IGG Deck Seal Sea Water Distribution",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:31,A_OV_COM:10,A_OV_REM:21,A_OV_PROG:32.25,A_OV_STATUS:"less than 70%",A_PG:32.25,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8020-8024-01",SUBSYSTEM_DESCR:"HULL Sea Water Cooling Pumps - 21-P-802004AandB",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-24",A_OV_TOT:75,A_OV_COM:74,A_OV_REM:1,A_OV_PROG:98.66,A_OV_STATUS:"greater than 95%",A_PG:98.66,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-07",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:10,PA_OV_COM:8,PA_OV_REM:2,PB1_OV_TOT:44,PB1_OV_COM:30,PB1_OV_REM:14,PB2_OV_TOT:29,PB2_OV_COM:9,PB2_OV_REM:20,ACT_TAG_VALID:36,PUNCH_NUMBER_VALID:83,PUNCH_TAG_VALID:23},
  //   {SUBSYSTEM:"21-8020-8024-02",SUBSYSTEM_DESCR:"HULL Sea Water Cooling Header and Cooling Water Exchangers - 21-H-805001AandB",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-24",A_OV_TOT:50,A_OV_COM:48,A_OV_REM:2,A_OV_PROG:96,A_OV_STATUS:"greater than 95%",A_PG:96,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-15",MC_APPROVED:"2022-02-28",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1",PA_OV_TOT:6,PA_OV_COM:6,PA_OV_REM:0,PB1_OV_TOT:9,PB1_OV_COM:9,PB1_OV_REM:0,PB2_OV_TOT:18,PB2_OV_COM:6,PB2_OV_REM:12,ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:33,PUNCH_TAG_VALID:13},
  //   {SUBSYSTEM:"21-8020-8025-01",SUBSYSTEM_DESCR:"HULL Service Sea Water Pumps - 21-P-802005AandB",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:103,A_OV_COM:90,A_OV_REM:13,A_OV_PROG:87.37,A_OV_STATUS:"NA",A_PG:87.37,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:14,PA_OV_COM:8,PA_OV_REM:6,PB1_OV_TOT:39,PB1_OV_COM:18,PB1_OV_REM:21,PB2_OV_TOT:24,PB2_OV_COM:2,PB2_OV_REM:22,ACT_TAG_VALID:46,PUNCH_NUMBER_VALID:77,PUNCH_TAG_VALID:19},
  //   {SUBSYSTEM:"21-8020-8026-01",SUBSYSTEM_DESCR:"HULL Service Sea Water to HVAC Cooling",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:81,A_OV_COM:51,A_OV_REM:30,A_OV_PROG:62.96,A_OV_STATUS:"less than 70%",A_PG:62.96,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:13,PB1_OV_COM:10,PB1_OV_REM:3,PB2_OV_TOT:1,PB2_OV_COM:1,PB2_OV_REM:0,ACT_TAG_VALID:30,PUNCH_NUMBER_VALID:14,PUNCH_TAG_VALID:4},
  //   {SUBSYSTEM:"21-8020-8027-01",SUBSYSTEM_DESCR:"HULL Service Sea Water Booster Pumps - 21-P-802008AandB",LOSH:"Y",MC_B30:"2022-03-24",A_OV_TOT:110,A_OV_COM:101,A_OV_REM:9,A_OV_PROG:91.81,A_OV_STATUS:"greater than 90%",A_PG:91.81,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:20,PA_OV_COM:6,PA_OV_REM:14,PB1_OV_TOT:28,PB1_OV_COM:11,PB1_OV_REM:17,PB2_OV_TOT:12,PB2_OV_COM:2,PB2_OV_REM:10,ACT_TAG_VALID:40,PUNCH_NUMBER_VALID:60,PUNCH_TAG_VALID:10},
  //   {SUBSYSTEM:"21-8020-8027-02",SUBSYSTEM_DESCR:"HULL Service Sea Water to Electrochlorination Package",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:11,A_OV_COM:5,A_OV_REM:6,A_OV_PROG:45.45,A_OV_STATUS:"less than 70%",A_PG:45.45,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",PB1_OV_TOT:5,PB1_OV_COM:5,PB1_OV_REM:0,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-8020-8027-03",SUBSYSTEM_DESCR:"HULL Service Sea Water to Potable Water Package",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:11,A_OV_COM:10,A_OV_REM:1,A_OV_PROG:90.9,A_OV_STATUS:"greater than 90%",A_PG:90.9,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:5,PB1_OV_COM:4,PB1_OV_REM:1,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-8020-8029-01",SUBSYSTEM_DESCR:"KSG Cooling Sea Water Pumps 21-P-802009AandB and Distribution",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-17",A_OV_TOT:95,A_OV_COM:95,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-16",MC_APPROVED:"2022-02-25",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1A",PA_OV_TOT:10,PA_OV_COM:10,PA_OV_REM:0,PB1_OV_TOT:46,PB1_OV_COM:46,PB1_OV_REM:0,PB2_OV_TOT:64,PB2_OV_COM:15,PB2_OV_REM:49,ACT_TAG_VALID:49,PUNCH_NUMBER_VALID:120,PUNCH_TAG_VALID:30},
  //   {SUBSYSTEM:"21-8040-8040-01",SUBSYSTEM_DESCR:"Heating Medium ,Hot Water Expansion Tank",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:124,A_OV_COM:35,A_OV_REM:89,A_OV_PROG:28.22,A_OV_STATUS:"less than 70%",A_PG:28.22,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:50,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-8040-8040-02",SUBSYSTEM_DESCR:"Heating Medium ,Hot Water Pumps and Loop and Distribution to Exchangers",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:754,A_OV_COM:210,A_OV_REM:544,A_OV_PROG:27.85,A_OV_STATUS:"less than 70%",A_PG:27.85,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:7,PA_OV_COM:4,PA_OV_REM:3,ACT_TAG_VALID:280,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:6},
  //   {SUBSYSTEM:"21-8050-8051-01",SUBSYSTEM_DESCR:"Marine Cooling Medium Pumps - 21-P805002AandB",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:48,A_OV_COM:40,A_OV_REM:8,A_OV_PROG:83.33,A_OV_STATUS:"NA",A_PG:83.33,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:4,PA_OV_COM:3,PA_OV_REM:1,PB1_OV_TOT:14,PB1_OV_COM:12,PB1_OV_REM:2,PB2_OV_TOT:7,PB2_OV_COM:0,PB2_OV_REM:7,ACT_TAG_VALID:23,PUNCH_NUMBER_VALID:25,PUNCH_TAG_VALID:9},
  //   {SUBSYSTEM:"21-8050-8051-02",SUBSYSTEM_DESCR:"Marine Cooling Medium Expansion Tank",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:59,A_OV_COM:47,A_OV_REM:12,A_OV_PROG:79.66,A_OV_STATUS:"NA",A_PG:79.66,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:19,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-8050-8051-03",SUBSYSTEM_DESCR:"Marine Cooling Medium Distribution",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:126,A_OV_COM:106,A_OV_REM:20,A_OV_PROG:84.12,A_OV_STATUS:"NA",A_PG:84.12,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:13,PB1_OV_COM:10,PB1_OV_REM:3,PB2_OV_TOT:14,PB2_OV_COM:6,PB2_OV_REM:8,ACT_TAG_VALID:41,PUNCH_NUMBER_VALID:28,PUNCH_TAG_VALID:10},
  //   {SUBSYSTEM:"21-8050-8052-01",SUBSYSTEM_DESCR:"Topisdes Cooling Medium Pumps - 21-P805001AandB",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:155,A_OV_COM:30,A_OV_REM:125,A_OV_PROG:19.35,A_OV_STATUS:"less than 70%",A_PG:19.35,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:9,PA_OV_COM:6,PA_OV_REM:3,PB1_OV_TOT:3,PB1_OV_COM:1,PB1_OV_REM:2,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:85,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:4},
  //   {SUBSYSTEM:"21-8050-8052-02",SUBSYSTEM_DESCR:"Start-up Cooling Medium Pumps - 21-P805003",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:57,A_OV_COM:18,A_OV_REM:39,A_OV_PROG:31.57,A_OV_STATUS:"less than 70%",A_PG:31.57,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:23,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-8050-8052-03",SUBSYSTEM_DESCR:"Topsides Cooling Medium Expansion Tank",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:110,A_OV_COM:34,A_OV_REM:76,A_OV_PROG:30.9,A_OV_STATUS:"less than 70%",A_PG:30.9,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:47,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-8050-8052-04",SUBSYSTEM_DESCR:"Topsides Cooling Medium Distribution and Exchangers",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:522,A_OV_COM:118,A_OV_REM:404,A_OV_PROG:22.6,A_OV_STATUS:"less than 70%",A_PG:22.6,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:184,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8060-8061-01",SUBSYSTEM_DESCR:"Diesel Bunker Header",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-24",A_OV_TOT:38,A_OV_COM:35,A_OV_REM:3,A_OV_PROG:92.1,A_OV_STATUS:"greater than 90%",A_PG:92.1,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-07",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:18,PA_OV_COM:14,PA_OV_REM:4,PB1_OV_TOT:17,PB1_OV_COM:0,PB1_OV_REM:17,PB2_OV_TOT:34,PB2_OV_COM:1,PB2_OV_REM:33,ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:69,PUNCH_TAG_VALID:11},
  //   {SUBSYSTEM:"21-8060-8061-02",SUBSYSTEM_DESCR:"Diesel Storage Tanks - 21-T-806001and2 and Purifier Pumps - 21-P-806002AandB",LOSH:"Y",MC_B30:"2022-02-24",A_OV_TOT:214,A_OV_COM:214,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-12",MC_APPROVED:"2022-03-01",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:12,PA_OV_COM:12,PA_OV_REM:0,PB1_OV_TOT:61,PB1_OV_COM:56,PB1_OV_REM:5,PB2_OV_TOT:78,PB2_OV_COM:14,PB2_OV_REM:64,ACT_TAG_VALID:74,PUNCH_NUMBER_VALID:151,PUNCH_TAG_VALID:38},
  //   {SUBSYSTEM:"21-8060-8061-03",SUBSYSTEM_DESCR:"Diesel Supply and Return ,Spill Back Headers to Service Tanks - 21-T-806003and4",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-17",A_OV_TOT:63,A_OV_COM:36,A_OV_REM:27,A_OV_PROG:57.14,A_OV_STATUS:"less than 70%",A_PG:57.14,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-04",MC_APPROVED:"2022-02-25",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1",PB1_OV_TOT:2,PB1_OV_COM:2,PB1_OV_REM:0,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:13,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:0},
  //   {SUBSYSTEM:"21-8060-8062-01",SUBSYSTEM_DESCR:"Diesel Service Tanks - 21-T-806003and4 and Supply Pumps - 21-P-806001AandB",LOSH:"Y",MC_B30:"2022-02-17",A_OV_TOT:269,A_OV_COM:266,A_OV_REM:3,A_OV_PROG:98.88,A_OV_STATUS:"greater than 95%",A_PG:98.88,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-12",MC_APPROVED:"2022-02-28",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:22,PA_OV_COM:21,PA_OV_REM:1,PB1_OV_TOT:80,PB1_OV_COM:75,PB1_OV_REM:5,PB2_OV_TOT:77,PB2_OV_COM:14,PB2_OV_REM:63,ACT_TAG_VALID:93,PUNCH_NUMBER_VALID:179,PUNCH_TAG_VALID:58},
  //   {SUBSYSTEM:"21-8060-8062-02",SUBSYSTEM_DESCR:"Purifier Package",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-17",A_OV_TOT:28,A_OV_COM:28,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2022-01-06",WD_ACTUAL:"2022-01-07",MC_APPROVED:"2022-02-23",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1",PA_OV_TOT:24,PA_OV_COM:24,PA_OV_REM:0,PB1_OV_TOT:13,PB1_OV_COM:13,PB1_OV_REM:0,PB2_OV_TOT:17,PB2_OV_COM:5,PB2_OV_REM:12,ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:54,PUNCH_TAG_VALID:18},
  //   {SUBSYSTEM:"21-8060-8063-01",SUBSYSTEM_DESCR:"Diesel Distribution Header to Users",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:240,A_OV_COM:86,A_OV_REM:154,A_OV_PROG:35.83,A_OV_STATUS:"less than 70%",A_PG:35.83,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:64,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8090-8090-01",SUBSYSTEM_DESCR:"Sewage Treatment Package",MC_B30:"2022-04-14",A_OV_TOT:30,A_OV_COM:6,A_OV_REM:24,A_OV_PROG:20,A_OV_STATUS:"less than 70%",A_PG:20,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:15,PA_OV_COM:14,PA_OV_REM:1,PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,PB2_OV_TOT:6,PB2_OV_COM:0,PB2_OV_REM:6,ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:23,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-8090-8090-02",SUBSYSTEM_DESCR:"Sewage Collection and Discharge Headers",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:371,A_OV_COM:228,A_OV_REM:143,A_OV_PROG:61.45,A_OV_STATUS:"less than 70%",A_PG:61.45,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P3",PB1_OV_TOT:3,PB1_OV_COM:1,PB1_OV_REM:2,ACT_TAG_VALID:72,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-8100-8110-01",SUBSYSTEM_DESCR:"HULL Plant Air Distribution",LOSH:"Y",MC_B30:"2022-02-03",A_OV_TOT:101,A_OV_COM:85,A_OV_REM:16,A_OV_PROG:84.15,A_OV_STATUS:"NA",A_PG:84.15,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-12",MC_APPROVED:"2022-01-28",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P3",PB1_OV_TOT:3,PB1_OV_COM:3,PB1_OV_REM:0,PB2_OV_TOT:19,PB2_OV_COM:0,PB2_OV_REM:19,ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:22,PUNCH_TAG_VALID:0},
  //   {SUBSYSTEM:"21-8100-8111-01",SUBSYSTEM_DESCR:"Topsides Plant Air Distribution",LOSH:"Y",MC_B30:"2022-07-28",A_OV_TOT:97,A_OV_COM:12,A_OV_REM:85,A_OV_PROG:12.37,A_OV_STATUS:"less than 70%",A_PG:12.37,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8100-8120-01",SUBSYSTEM_DESCR:"HULL Air Compressor 21-Z-811002",BATCH:"Incentive 1",LOSH:"Y",MC_B30:"2022-02-17",A_OV_TOT:53,A_OV_COM:53,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2022-01-06",WD_ACTUAL:"2022-01-07",MC_APPROVED:"2022-02-19",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:15,PA_OV_COM:14,PA_OV_REM:1,PB1_OV_TOT:27,PB1_OV_COM:21,PB1_OV_REM:6,PB2_OV_TOT:9,PB2_OV_COM:3,PB2_OV_REM:6,PB3_OV_TOT:1,PB3_OV_COM:0,PB3_OV_REM:1,ACT_TAG_VALID:33,PUNCH_NUMBER_VALID:52,PUNCH_TAG_VALID:18},
  //   {SUBSYSTEM:"21-8100-8120-02",SUBSYSTEM_DESCR:"HULL Air Drier 21-Z-812020",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-24",A_OV_TOT:29,A_OV_COM:29,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-12-09",WD_ACTUAL:"2021-12-10",MC_APPROVED:"2022-02-25",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:8,PA_OV_COM:8,PA_OV_REM:0,PB1_OV_TOT:12,PB1_OV_COM:10,PB1_OV_REM:2,PB2_OV_TOT:10,PB2_OV_COM:4,PB2_OV_REM:6,ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:30,PUNCH_TAG_VALID:14},
  //   {SUBSYSTEM:"21-8100-8120-03",SUBSYSTEM_DESCR:"HULL Air Receiver 21-V-812002, Main header and Controls",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-24",A_OV_TOT:64,A_OV_COM:64,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2022-01-07",WD_ACTUAL:"2022-01-10",MC_APPROVED:"2022-02-19",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1A",PA_OV_TOT:5,PA_OV_COM:5,PA_OV_REM:0,PB1_OV_TOT:15,PB1_OV_COM:15,PB1_OV_REM:0,PB2_OV_TOT:6,PB2_OV_COM:0,PB2_OV_REM:6,ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:26,PUNCH_TAG_VALID:11},
  //   {SUBSYSTEM:"21-8100-8121-01",SUBSYSTEM_DESCR:"HULL Instrument Air Distribution",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:399,A_OV_COM:339,A_OV_REM:60,A_OV_PROG:84.96,A_OV_STATUS:"NA",A_PG:84.96,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-08",MC_APPROVED:"2022-02-28",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1A",PA_OV_TOT:12,PA_OV_COM:12,PA_OV_REM:0,PB1_OV_TOT:24,PB1_OV_COM:24,PB1_OV_REM:0,PB2_OV_TOT:47,PB2_OV_COM:5,PB2_OV_REM:42,ACT_TAG_VALID:94,PUNCH_NUMBER_VALID:83,PUNCH_TAG_VALID:14},
  //   {SUBSYSTEM:"21-8100-8122-01",SUBSYSTEM_DESCR:"Topside Air Compressor 21-Z-811101",LOSH:"Y",MC_B30:"2022-03-10",A_OV_TOT:68,A_OV_COM:11,A_OV_REM:57,A_OV_PROG:16.17,A_OV_STATUS:"less than 70%",A_PG:16.17,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:3,PB1_OV_COM:1,PB1_OV_REM:2,PB2_OV_TOT:7,PB2_OV_COM:0,PB2_OV_REM:7,ACT_TAG_VALID:45,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-8100-8122-02",SUBSYSTEM_DESCR:"Topside Air Compressor 21-Z-811201",LOSH:"Y",MC_B30:"2022-03-10",A_OV_TOT:46,A_OV_COM:11,A_OV_REM:35,A_OV_PROG:23.91,A_OV_STATUS:"less than 70%",A_PG:23.91,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:4,PA_OV_COM:1,PA_OV_REM:3,PB1_OV_TOT:5,PB1_OV_COM:1,PB1_OV_REM:4,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:23,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-8100-8122-03",SUBSYSTEM_DESCR:"Topside Air Compressor 21-Z-811301",LOSH:"Y",MC_B30:"2022-03-10",A_OV_TOT:45,A_OV_COM:11,A_OV_REM:34,A_OV_PROG:24.44,A_OV_STATUS:"less than 70%",A_PG:24.44,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-8100-8122-04",SUBSYSTEM_DESCR:"Topside Air Driers 21-Z-812001",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:91,A_OV_COM:10,A_OV_REM:81,A_OV_PROG:10.98,A_OV_STATUS:"less than 70%",A_PG:10.98,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PB1_OV_TOT:7,PB1_OV_COM:0,PB1_OV_REM:7,ACT_TAG_VALID:55,PUNCH_NUMBER_VALID:9,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-8100-8122-05",SUBSYSTEM_DESCR:"Topside Air Receiver 21-V-812001, Main header and Controls",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:104,A_OV_COM:43,A_OV_REM:61,A_OV_PROG:41.34,A_OV_STATUS:"less than 70%",A_PG:41.34,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:38,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8100-8123-01",SUBSYSTEM_DESCR:"Topsides Instrument Air Distribution",LOSH:"Y",MC_B30:"2022-06-16",A_OV_TOT:402,A_OV_COM:96,A_OV_REM:306,A_OV_PROG:23.88,A_OV_STATUS:"less than 70%",A_PG:23.88,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:110,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8130-8130-01",SUBSYSTEM_DESCR:"Fuel Gas KO Drum",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:176,A_OV_COM:45,A_OV_REM:131,A_OV_PROG:25.56,A_OV_STATUS:"less than 70%",A_PG:25.56,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:69,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8130-8130-02",SUBSYSTEM_DESCR:"Fuel Gas Superheaters",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:113,A_OV_COM:25,A_OV_REM:88,A_OV_PROG:22.12,A_OV_STATUS:"less than 70%",A_PG:22.12,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:58,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8130-8131-01",SUBSYSTEM_DESCR:"Fuel Gas Blanketing Distribution in HULL",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:161,A_OV_COM:18,A_OV_REM:143,A_OV_PROG:11.18,A_OV_STATUS:"less than 70%",A_PG:11.18,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:6,PA_OV_COM:0,PA_OV_REM:6,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:71,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:0},
  //   {SUBSYSTEM:"21-8130-8131-02",SUBSYSTEM_DESCR:"Fuel Gas Filters and Main Header",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:92,A_OV_COM:26,A_OV_REM:66,A_OV_PROG:28.26,A_OV_STATUS:"less than 70%",A_PG:28.26,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:31,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8130-8131-03",SUBSYSTEM_DESCR:"LP Fuel Gas Distribution Topsides",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:285,A_OV_COM:66,A_OV_REM:219,A_OV_PROG:23.15,A_OV_STATUS:"less than 70%",A_PG:23.15,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:103,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8130-8131-04",SUBSYSTEM_DESCR:"Fuel Gas Distribution to GTGs",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:99,A_OV_COM:13,A_OV_REM:86,A_OV_PROG:13.13,A_OV_STATUS:"less than 70%",A_PG:13.13,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:39,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8150-8150-01",SUBSYSTEM_DESCR:"Nitrogen Generator and Receiver",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:92,A_OV_COM:36,A_OV_REM:56,A_OV_PROG:39.13,A_OV_STATUS:"less than 70%",A_PG:39.13,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:2,PA_OV_REM:1,PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:38,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-8150-8150-02",SUBSYSTEM_DESCR:"Topside Nitrogen Distribution",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:336,A_OV_COM:70,A_OV_REM:266,A_OV_PROG:20.83,A_OV_STATUS:"less than 70%",A_PG:20.83,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:7,PA_OV_COM:0,PA_OV_REM:7,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:91,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:0},
  //   {SUBSYSTEM:"21-8160-8160-01",SUBSYSTEM_DESCR:"Inert Gas Generator",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:196,A_OV_COM:11,A_OV_REM:185,A_OV_PROG:5.61,A_OV_STATUS:"less than 70%",A_PG:5.61,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:9,PA_OV_COM:1,PA_OV_REM:8,PB1_OV_TOT:5,PB1_OV_COM:1,PB1_OV_REM:4,PB2_OV_TOT:4,PB2_OV_COM:0,PB2_OV_REM:4,ACT_TAG_VALID:128,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-8160-8160-03",SUBSYSTEM_DESCR:"IGG Header",LOSH:"Y",MC_B30:"2022-06-30",A_OV_TOT:147,A_OV_COM:19,A_OV_REM:128,A_OV_PROG:12.92,A_OV_STATUS:"less than 70%",A_PG:12.92,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:6,PA_OV_COM:0,PA_OV_REM:6,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:62,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:0},
  //   {SUBSYSTEM:"21-8300-8310-01",SUBSYSTEM_DESCR:"Corrosion Inhibitor Tank and Pumps",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:59,A_OV_COM:11,A_OV_REM:48,A_OV_PROG:18.64,A_OV_STATUS:"less than 70%",A_PG:18.64,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:7,PA_OV_COM:5,PA_OV_REM:2,PB1_OV_TOT:5,PB1_OV_COM:2,PB1_OV_REM:3,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:32,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-8300-8310-02",SUBSYSTEM_DESCR:"Corrosion Inhibitor Distribution",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:31,A_OV_COM:4,A_OV_REM:27,A_OV_PROG:12.9,A_OV_STATUS:"less than 70%",A_PG:12.9,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8300-8320-01",SUBSYSTEM_DESCR:"Scale Inhibitor Tank and Pumps Topsides",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:38,A_OV_COM:10,A_OV_REM:28,A_OV_PROG:26.31,A_OV_STATUS:"less than 70%",A_PG:26.31,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8300-8320-02",SUBSYSTEM_DESCR:"Scale Inhibitor Distribution Subsea",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:36,A_OV_COM:4,A_OV_REM:32,A_OV_PROG:11.11,A_OV_STATUS:"less than 70%",A_PG:11.11,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8300-8320-03",SUBSYSTEM_DESCR:"Scale Inhibitor Distribution Topsides",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:55,A_OV_COM:9,A_OV_REM:46,A_OV_PROG:16.36,A_OV_STATUS:"less than 70%",A_PG:16.36,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8300-8320-04",SUBSYSTEM_DESCR:"Scale Inhibitor Tank and Pumps Subsea",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:25,A_OV_COM:0,A_OV_REM:25,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-8300-8330-01",SUBSYSTEM_DESCR:"Wax Inhibitor Loading Header",LOSH:"Y",MC_B30:"2022-06-16",A_OV_TOT:34,A_OV_COM:6,A_OV_REM:28,A_OV_PROG:17.64,A_OV_STATUS:"less than 70%",A_PG:17.64,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8300-8330-02",SUBSYSTEM_DESCR:"Wax Inhibitor Storage and Pumps",LOSH:"Y",MC_B30:"2022-06-16",A_OV_TOT:131,A_OV_COM:43,A_OV_REM:88,A_OV_PROG:32.82,A_OV_STATUS:"less than 70%",A_PG:32.82,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PB1_OV_TOT:8,PB1_OV_COM:8,PB1_OV_REM:0,PB2_OV_TOT:5,PB2_OV_COM:0,PB2_OV_REM:5,ACT_TAG_VALID:43,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-8300-8331-01",SUBSYSTEM_DESCR:"Wax Inhibitor Filtration",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:51,A_OV_COM:6,A_OV_REM:45,A_OV_PROG:11.76,A_OV_STATUS:"less than 70%",A_PG:11.76,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8300-8331-02",SUBSYSTEM_DESCR:"Wax Inhibitor Break Tank and Injection Pumps",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:279,A_OV_COM:84,A_OV_REM:195,A_OV_PROG:30.1,A_OV_STATUS:"less than 70%",A_PG:30.1,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,ACT_TAG_VALID:95,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-8300-8332-01",SUBSYSTEM_DESCR:"Wax Inhibitor Injection Subsea Phase 1",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:30,A_OV_COM:1,A_OV_REM:29,A_OV_PROG:3.33,A_OV_STATUS:"less than 70%",A_PG:3.33,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8300-8340-01",SUBSYSTEM_DESCR:"Biocide Tank and Pumps",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:64,A_OV_COM:22,A_OV_REM:42,A_OV_PROG:34.37,A_OV_STATUS:"less than 70%",A_PG:34.37,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:14,PA_OV_COM:10,PA_OV_REM:4,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:27,PUNCH_NUMBER_VALID:15,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-8300-8340-02",SUBSYSTEM_DESCR:"Biocide Distribution HULL and Topsides",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:111,A_OV_COM:30,A_OV_REM:81,A_OV_PROG:27.02,A_OV_STATUS:"less than 70%",A_PG:27.02,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:31,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8300-8350-01",SUBSYSTEM_DESCR:"Antifoam Tank and Pumps",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:37,A_OV_COM:5,A_OV_REM:32,A_OV_PROG:13.51,A_OV_STATUS:"less than 70%",A_PG:13.51,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:21,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8300-8350-02",SUBSYSTEM_DESCR:"Antifoam Distribution",LOSH:"Y",MC_B30:"2022-06-16",A_OV_TOT:37,A_OV_COM:4,A_OV_REM:33,A_OV_PROG:10.81,A_OV_STATUS:"less than 70%",A_PG:10.81,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8300-8360-01",SUBSYSTEM_DESCR:"Emulsion Breaker Tank and Pumps",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:47,A_OV_COM:17,A_OV_REM:30,A_OV_PROG:36.17,A_OV_STATUS:"less than 70%",A_PG:36.17,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:23,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8300-8360-02",SUBSYSTEM_DESCR:"Emulsion Breaker Distribution",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:37,A_OV_COM:6,A_OV_REM:31,A_OV_PROG:16.21,A_OV_STATUS:"less than 70%",A_PG:16.21,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8300-8370-01",SUBSYSTEM_DESCR:"MEG PH Stabilizer Tank and Pumps",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:53,A_OV_COM:14,A_OV_REM:39,A_OV_PROG:26.41,A_OV_STATUS:"less than 70%",A_PG:26.41,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:29,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8300-8370-02",SUBSYSTEM_DESCR:"MEG PH Stabilizer Distribution",LOSH:"Y",MC_B30:"2022-06-16",A_OV_TOT:19,A_OV_COM:3,A_OV_REM:16,A_OV_PROG:15.78,A_OV_STATUS:"less than 70%",A_PG:15.78,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8300-8375-01",SUBSYSTEM_DESCR:"MEG Antifoam Tank and Pumps",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:17,A_OV_COM:10,A_OV_REM:7,A_OV_PROG:58.82,A_OV_STATUS:"less than 70%",A_PG:58.82,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8300-8375-02",SUBSYSTEM_DESCR:"MEG Antifoam Distribution",LOSH:"Y",MC_B30:"2022-06-16",A_OV_TOT:37,A_OV_COM:6,A_OV_REM:31,A_OV_PROG:16.21,A_OV_STATUS:"less than 70%",A_PG:16.21,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8300-8380-01",SUBSYSTEM_DESCR:"Spare and Future Tanks",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:4,A_OV_COM:0,A_OV_REM:4,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8400-8410-01",SUBSYSTEM_DESCR:"O2 Scavanger Tank and Pumps",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:43,A_OV_COM:14,A_OV_REM:29,A_OV_PROG:32.55,A_OV_STATUS:"less than 70%",A_PG:32.55,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:19,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8400-8410-02",SUBSYSTEM_DESCR:"O2 Scavanger Distribution HULL and Topsides",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:34,A_OV_COM:6,A_OV_REM:28,A_OV_PROG:17.64,A_OV_STATUS:"less than 70%",A_PG:17.64,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8600-8611-01",SUBSYSTEM_DESCR:"GTG 1 and WHRU1",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:675,A_OV_COM:105,A_OV_REM:570,A_OV_PROG:15.55,A_OV_STATUS:"less than 70%",A_PG:15.55,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:3,PA_OV_COM:2,PA_OV_REM:1,PB1_OV_TOT:12,PB1_OV_COM:2,PB1_OV_REM:10,PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:499,PUNCH_NUMBER_VALID:17,PUNCH_TAG_VALID:4},
  //   {SUBSYSTEM:"21-8600-8612-01",SUBSYSTEM_DESCR:"GTG 2 and WHRU2",LOSH:"Y",MC_B30:"2022-03-10",A_OV_TOT:674,A_OV_COM:117,A_OV_REM:557,A_OV_PROG:17.35,A_OV_STATUS:"less than 70%",A_PG:17.35,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:2,PB1_OV_COM:2,PB1_OV_REM:0,ACT_TAG_VALID:495,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-8600-8613-01",SUBSYSTEM_DESCR:"GTG 3 and WHRU3",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:679,A_OV_COM:114,A_OV_REM:565,A_OV_PROG:16.78,A_OV_STATUS:"less than 70%",A_PG:16.78,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:2,PB1_OV_COM:2,PB1_OV_REM:0,PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:495,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-8600-8614-01",SUBSYSTEM_DESCR:"GTG 4 and WHRU4",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:666,A_OV_COM:107,A_OV_REM:559,A_OV_PROG:16.06,A_OV_STATUS:"less than 70%",A_PG:16.06,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:2,PB1_OV_COM:2,PB1_OV_REM:0,ACT_TAG_VALID:492,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-8700-8760-01",SUBSYSTEM_DESCR:"Topside Normal 690 V Swicthboard 21-ESB-876001",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:164,A_OV_COM:144,A_OV_REM:20,A_OV_PROG:87.8,A_OV_STATUS:"NA",A_PG:87.8,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:14,PB1_OV_COM:4,PB1_OV_REM:10,PB2_OV_TOT:4,PB2_OV_COM:0,PB2_OV_REM:4,ACT_TAG_VALID:164,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:7},
  //   {SUBSYSTEM:"21-8700-8770-01",SUBSYSTEM_DESCR:"Topsides Main Normal HV Switchboard 6.6kV 21-ESB-877001",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:105,A_OV_COM:29,A_OV_REM:76,A_OV_PROG:27.61,A_OV_STATUS:"less than 70%",A_PG:27.61,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:8,PA_OV_COM:6,PA_OV_REM:2,PB1_OV_TOT:11,PB1_OV_COM:2,PB1_OV_REM:9,ACT_TAG_VALID:105,PUNCH_NUMBER_VALID:19,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-8780-8780-01",SUBSYSTEM_DESCR:"Earthing HULL",BATCH:"Incentive 2",MC_B30:"2022-05-05",A_OV_TOT:28,A_OV_COM:4,A_OV_REM:24,A_OV_PROG:14.28,A_OV_STATUS:"less than 70%",A_PG:14.28,RFI_ISSUED:"2021-09-23",WD_ACTUAL:"2021-09-24",MC_APPROVED:"2021-09-29",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"N",PRIORITY:"P3",PA_OV_TOT:5,PA_OV_COM:5,PA_OV_REM:0,PB1_OV_TOT:17,PB1_OV_COM:17,PB1_OV_REM:0,ACT_TAG_VALID:28,PUNCH_NUMBER_VALID:22,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-8780-8780-02",SUBSYSTEM_DESCR:"Earthing LQ",BATCH:"Incentive 2",MC_B30:"2022-04-28",A_OV_TOT:12,A_OV_COM:2,A_OV_REM:10,A_OV_PROG:16.66,A_OV_STATUS:"less than 70%",A_PG:16.66,RFI_ISSUED:"2021-10-22",WD_ACTUAL:"2021-10-23",MC_APPROVED:"2021-11-05",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P3",PA_OV_TOT:5,PA_OV_COM:5,PA_OV_REM:0,PB1_OV_TOT:6,PB1_OV_COM:5,PB1_OV_REM:1,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-8780-8780-03",SUBSYSTEM_DESCR:"Earthing Topsides",BATCH:"Incentive 2",MC_B30:"2022-08-04",A_OV_TOT:56,A_OV_COM:3,A_OV_REM:53,A_OV_PROG:5.35,A_OV_STATUS:"less than 70%",A_PG:5.35,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-24",MC_APPROVED:"2022-02-17",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:6,PA_OV_COM:4,PA_OV_REM:2,PB1_OV_TOT:10,PB1_OV_COM:1,PB1_OV_REM:9,ACT_TAG_VALID:56,PUNCH_NUMBER_VALID:16,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-8800-8800-01",SUBSYSTEM_DESCR:"Ses Water Lift Pump A",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:80,A_OV_COM:14,A_OV_REM:66,A_OV_PROG:17.5,A_OV_STATUS:"less than 70%",A_PG:17.5,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:33,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8800-8800-02",SUBSYSTEM_DESCR:"Ses Water Lift Pump B",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:80,A_OV_COM:15,A_OV_REM:65,A_OV_PROG:18.75,A_OV_STATUS:"less than 70%",A_PG:18.75,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:33,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8800-8800-03",SUBSYSTEM_DESCR:"Ses Water Lift Pump C",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:86,A_OV_COM:14,A_OV_REM:72,A_OV_PROG:16.27,A_OV_STATUS:"less than 70%",A_PG:16.27,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:34,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-8800-8800-04",SUBSYSTEM_DESCR:"Sea Water Filter and Distribution Header",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:117,A_OV_COM:19,A_OV_REM:98,A_OV_PROG:16.23,A_OV_STATUS:"less than 70%",A_PG:16.23,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:45,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-8800-8800-05",SUBSYSTEM_DESCR:"Sea Water Cooling Supply to Exchangers and Discharge Header",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:132,A_OV_COM:52,A_OV_REM:80,A_OV_PROG:39.39,A_OV_STATUS:"less than 70%",A_PG:39.39,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P3",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:35,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-8800-8805-01",SUBSYSTEM_DESCR:"Electrochlorination Unit",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:149,A_OV_COM:27,A_OV_REM:122,A_OV_PROG:18.12,A_OV_STATUS:"less than 70%",A_PG:18.12,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:6,PA_OV_COM:4,PA_OV_REM:2,PB1_OV_TOT:10,PB1_OV_COM:4,PB1_OV_REM:6,PB2_OV_TOT:4,PB2_OV_COM:0,PB2_OV_REM:4,ACT_TAG_VALID:111,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-8800-8805-02",SUBSYSTEM_DESCR:"Hypochlorite Distribution Topsides",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:132,A_OV_COM:46,A_OV_REM:86,A_OV_PROG:34.84,A_OV_STATUS:"less than 70%",A_PG:34.84,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P3",PB1_OV_TOT:15,PB1_OV_COM:11,PB1_OV_REM:4,ACT_TAG_VALID:50,PUNCH_NUMBER_VALID:15,PUNCH_TAG_VALID:5},
  //   {SUBSYSTEM:"21-9130-9130-01",SUBSYSTEM_DESCR:"ME01 Area Completion",LOSH:"Y",MC_B30:"2022-08-04",A_OV_TOT:45,A_OV_COM:10,A_OV_REM:35,A_OV_PROG:22.22,A_OV_STATUS:"less than 70%",A_PG:22.22,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:19,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9130-9130-02",SUBSYSTEM_DESCR:"ME02 Area Completion",LOSH:"Y",MC_B30:"2022-07-28",A_OV_TOT:23,A_OV_COM:7,A_OV_REM:16,A_OV_PROG:30.43,A_OV_STATUS:"less than 70%",A_PG:30.43,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9130-9130-03",SUBSYSTEM_DESCR:"ME03 Area Completion",MC_B30:"2022-06-09",A_OV_STATUS:"NA",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9130-9130-04",SUBSYSTEM_DESCR:"ME04 Area Completion",LOSH:"Y",MC_B30:"2022-07-21",A_OV_TOT:20,A_OV_COM:5,A_OV_REM:15,A_OV_PROG:25,A_OV_STATUS:"less than 70%",A_PG:25,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9130-9130-05",SUBSYSTEM_DESCR:"ME05 Area Completion",LOSH:"Y",MC_B30:"2022-07-21",A_OV_TOT:46,A_OV_COM:11,A_OV_REM:35,A_OV_PROG:23.91,A_OV_STATUS:"less than 70%",A_PG:23.91,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9130-9130-06",SUBSYSTEM_DESCR:"MW01 Area Completion",LOSH:"Y",MC_B30:"2022-08-04",A_OV_TOT:57,A_OV_COM:12,A_OV_REM:45,A_OV_PROG:21.05,A_OV_STATUS:"less than 70%",A_PG:21.05,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9130-9130-07",SUBSYSTEM_DESCR:"MW02 Area Completion",LOSH:"Y",MC_B30:"2022-08-04",A_OV_TOT:44,A_OV_COM:11,A_OV_REM:33,A_OV_PROG:25,A_OV_STATUS:"less than 70%",A_PG:25,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9130-9130-08",SUBSYSTEM_DESCR:"MW03 Area Completion",LOSH:"Y",MC_B30:"2022-06-09",A_OV_STATUS:"NA",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9130-9130-09",SUBSYSTEM_DESCR:"MW04 Area Completion",LOSH:"Y",MC_B30:"2022-07-21",A_OV_TOT:21,A_OV_COM:5,A_OV_REM:16,A_OV_PROG:23.8,A_OV_STATUS:"less than 70%",A_PG:23.8,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9130-9130-10",SUBSYSTEM_DESCR:"MW05 Area Completion",LOSH:"Y",MC_B30:"2022-07-28",A_OV_TOT:29,A_OV_COM:12,A_OV_REM:17,A_OV_PROG:41.37,A_OV_STATUS:"less than 70%",A_PG:41.37,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9130-9130-11",SUBSYSTEM_DESCR:"A Deck Laydown Area Completion",MC_B30:"2022-07-28",A_OV_TOT:16,A_OV_COM:0,A_OV_REM:16,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9130-9130-12",SUBSYSTEM_DESCR:"KO Drum Area Completion",LOSH:"Y",MC_B30:"2022-07-28",A_OV_TOT:5,A_OV_COM:0,A_OV_REM:5,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9130-9130-13",SUBSYSTEM_DESCR:"Offloading Area Completion",LOSH:"Y",MC_B30:"2022-06-09",A_OV_STATUS:"NA",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9130-9130-14",SUBSYSTEM_DESCR:"Flare Area Completion",LOSH:"Y",MC_B30:"2022-08-04",A_OV_TOT:21,A_OV_COM:1,A_OV_REM:20,A_OV_PROG:4.76,A_OV_STATUS:"less than 70%",A_PG:4.76,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9130-9130-15",SUBSYSTEM_DESCR:"Central Pipe Rack Area Completion",LOSH:"Y",MC_B30:"2022-07-14",A_OV_TOT:73,A_OV_COM:20,A_OV_REM:53,A_OV_PROG:27.39,A_OV_STATUS:"less than 70%",A_PG:27.39,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:45,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9130-9130-16",SUBSYSTEM_DESCR:"Safe Haven Area Completion",MC_B30:"2022-06-16",A_OV_TOT:2,A_OV_COM:0,A_OV_REM:2,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9220-9220-01",SUBSYSTEM_DESCR:"LQ Main Deck and Mezzanine",LOSH:"Y",MC_B30:"2022-06-16",A_OV_TOT:148,A_OV_COM:59,A_OV_REM:89,A_OV_PROG:39.86,A_OV_STATUS:"less than 70%",A_PG:39.86,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:38,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9220-9220-02",SUBSYSTEM_DESCR:"LQ A Deck - Area Completion",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:210,A_OV_COM:100,A_OV_REM:110,A_OV_PROG:47.61,A_OV_STATUS:"less than 70%",A_PG:47.61,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:55,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9220-9220-03",SUBSYSTEM_DESCR:"LQ Deck 1 - Area Completion",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:167,A_OV_COM:93,A_OV_REM:74,A_OV_PROG:55.68,A_OV_STATUS:"less than 70%",A_PG:55.68,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:41,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0},
  //   {SUBSYSTEM:"21-9220-9220-04",SUBSYSTEM_DESCR:"LQ Deck 2 - Area Completion",LOSH:"Y",MC_B30:"2022-08-04",A_OV_TOT:207,A_OV_COM:105,A_OV_REM:102,A_OV_PROG:50.72,A_OV_STATUS:"less than 70%",A_PG:50.72,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:50,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9220-9220-05",SUBSYSTEM_DESCR:"LQ Deck 3 - Area Completion",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:253,A_OV_COM:154,A_OV_REM:99,A_OV_PROG:60.86,A_OV_STATUS:"less than 70%",A_PG:60.86,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:13,PA_OV_COM:10,PA_OV_REM:3,PB1_OV_TOT:10,PB1_OV_COM:3,PB1_OV_REM:7,ACT_TAG_VALID:71,PUNCH_NUMBER_VALID:23,PUNCH_TAG_VALID:0},
  //   {SUBSYSTEM:"21-9220-9220-06",SUBSYSTEM_DESCR:"LQ Deck 4 - Area Completion",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:257,A_OV_COM:163,A_OV_REM:94,A_OV_PROG:63.42,A_OV_STATUS:"less than 70%",A_PG:63.42,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:10,PA_OV_COM:4,PA_OV_REM:6,PB1_OV_TOT:13,PB1_OV_COM:9,PB1_OV_REM:4,ACT_TAG_VALID:72,PUNCH_NUMBER_VALID:23,PUNCH_TAG_VALID:0},
  //   {SUBSYSTEM:"21-9220-9220-07",SUBSYSTEM_DESCR:"LQ Deck 5 - Area Completion",LOSH:"Y",MC_B30:"2022-07-28",A_OV_TOT:146,A_OV_COM:82,A_OV_REM:64,A_OV_PROG:56.16,A_OV_STATUS:"less than 70%",A_PG:56.16,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:35,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9220-9220-09",SUBSYSTEM_DESCR:"LQ Roof Deck and External  - Area Completion",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:187,A_OV_COM:165,A_OV_REM:22,A_OV_PROG:88.23,A_OV_STATUS:"NA",A_PG:88.23,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:54,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9220-9220-11",SUBSYSTEM_DESCR:"Helideck - Area Completion",LOSH:"Y",MC_B30:"2022-07-14",A_OV_TOT:6,A_OV_COM:4,A_OV_REM:2,A_OV_PROG:66.66,A_OV_STATUS:"less than 70%",A_PG:66.66,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P3",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0},
  //   {SUBSYSTEM:"21-9220-9224-01",SUBSYSTEM_DESCR:"Entertainment System",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:755,A_OV_COM:7,A_OV_REM:748,A_OV_PROG:0.92,A_OV_STATUS:"less than 70%",A_PG:0.92,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:3,PB1_OV_COM:3,PB1_OV_REM:0,PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:755,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-9310-9310-01",SUBSYSTEM_DESCR:"HULL Main Deck Area Completion",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:258,A_OV_COM:234,A_OV_REM:24,A_OV_PROG:90.69,A_OV_STATUS:"greater than 90%",A_PG:90.69,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:86,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9310-9310-02",SUBSYSTEM_DESCR:"Machinery Space Area Completion",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:205,A_OV_COM:137,A_OV_REM:68,A_OV_PROG:66.82,A_OV_STATUS:"less than 70%",A_PG:66.82,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:3,PA_OV_COM:1,PA_OV_REM:2,ACT_TAG_VALID:52,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-9310-9310-03",SUBSYSTEM_DESCR:"HULL Forward Area Completion",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:729,A_OV_COM:589,A_OV_REM:140,A_OV_PROG:80.79,A_OV_STATUS:"NA",A_PG:80.79,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:233,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9310-9310-04",SUBSYSTEM_DESCR:"HULL After Area Completion",MC_B30:"2022-05-19",A_OV_TOT:599,A_OV_COM:558,A_OV_REM:41,A_OV_PROG:93.15,A_OV_STATUS:"greater than 90%",A_PG:93.15,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:192,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9310-9310-05",SUBSYSTEM_DESCR:"Riser Balcony Area Completion",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:341,A_OV_COM:337,A_OV_REM:4,A_OV_PROG:98.82,A_OV_STATUS:"greater than 95%",A_PG:98.82,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:98,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9330-9330-01",SUBSYSTEM_DESCR:"Main Ballast Tanks WBE1 to 5, APE 1, APW 1 and WBW 1 to 5",LOSH:"Y",MC_B30:"2022-04-21",A_OV_TOT:185,A_OV_COM:101,A_OV_REM:84,A_OV_PROG:54.59,A_OV_STATUS:"less than 70%",A_PG:54.59,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1A",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:55,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0},
  //   {SUBSYSTEM:"21-9330-9330-02",SUBSYSTEM_DESCR:"Main Ballast Ring Main",LOSH:"Y",MC_B30:"2022-04-21",A_OV_TOT:381,A_OV_COM:107,A_OV_REM:274,A_OV_PROG:28.08,A_OV_STATUS:"less than 70%",A_PG:28.08,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1A",ACT_TAG_VALID:204,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9330-9330-03",SUBSYSTEM_DESCR:"Ballast Pump 21-P-935001 A",LOSH:"Y",MC_B30:"2022-04-21",A_OV_TOT:124,A_OV_COM:20,A_OV_REM:104,A_OV_PROG:16.12,A_OV_STATUS:"less than 70%",A_PG:16.12,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1A",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:60,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0},
  //   {SUBSYSTEM:"21-9330-9330-04",SUBSYSTEM_DESCR:"Ballast Pump 21-P-935001 B",LOSH:"Y",MC_B30:"2022-04-21",A_OV_TOT:120,A_OV_COM:20,A_OV_REM:100,A_OV_PROG:16.66,A_OV_STATUS:"less than 70%",A_PG:16.66,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1A",ACT_TAG_VALID:58,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9330-9330-05",SUBSYSTEM_DESCR:"Ballast Pump 21-P-935001 C",LOSH:"Y",MC_B30:"2022-04-21",A_OV_TOT:119,A_OV_COM:25,A_OV_REM:94,A_OV_PROG:21,A_OV_STATUS:"less than 70%",A_PG:21,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1A",ACT_TAG_VALID:57,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9330-9330-06",SUBSYSTEM_DESCR:"Ballast Pump 21-P-935001 D",LOSH:"Y",MC_B30:"2022-04-21",A_OV_TOT:120,A_OV_COM:23,A_OV_REM:97,A_OV_PROG:19.16,A_OV_STATUS:"less than 70%",A_PG:19.16,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1A",ACT_TAG_VALID:58,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9330-9333-01",SUBSYSTEM_DESCR:"Cargo and Ballast Pumps HPU and UCP",BATCH:"Incentive 2",MC_B30:"2022-02-24",A_OV_TOT:768,A_OV_COM:353,A_OV_REM:415,A_OV_PROG:45.96,A_OV_STATUS:"less than 70%",A_PG:45.96,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1A",PA_OV_TOT:39,PA_OV_COM:33,PA_OV_REM:6,PB1_OV_TOT:82,PB1_OV_COM:59,PB1_OV_REM:23,PB2_OV_TOT:18,PB2_OV_COM:2,PB2_OV_REM:16,ACT_TAG_VALID:324,PUNCH_NUMBER_VALID:139,PUNCH_TAG_VALID:45},
  //   {SUBSYSTEM:"21-9330-9334-01",SUBSYSTEM_DESCR:"HULL Valves HPU",BATCH:"Incentive 2",MC_B30:"2022-02-17",A_OV_TOT:103,A_OV_COM:43,A_OV_REM:60,A_OV_PROG:41.74,A_OV_STATUS:"less than 70%",A_PG:41.74,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-04",MC_APPROVED:"2022-02-25",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1A",PA_OV_TOT:39,PA_OV_COM:39,PA_OV_REM:0,PB1_OV_TOT:40,PB1_OV_COM:37,PB1_OV_REM:3,PB2_OV_TOT:16,PB2_OV_COM:9,PB2_OV_REM:7,ACT_TAG_VALID:88,PUNCH_NUMBER_VALID:95,PUNCH_TAG_VALID:20},
  //   {SUBSYSTEM:"21-9340-9341-01",SUBSYSTEM_DESCR:"Forward Mooring System and HPU",MC_B30:"2022-06-09",A_OV_TOT:73,A_OV_COM:3,A_OV_REM:70,A_OV_PROG:4.1,A_OV_STATUS:"less than 70%",A_PG:4.1,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:1,PA_OV_REM:2,PB1_OV_TOT:5,PB1_OV_COM:5,PB1_OV_REM:0,PB2_OV_TOT:6,PB2_OV_COM:0,PB2_OV_REM:6,ACT_TAG_VALID:34,PUNCH_NUMBER_VALID:14,PUNCH_TAG_VALID:6},
  //   {SUBSYSTEM:"21-9340-9341-02",SUBSYSTEM_DESCR:"Forward Mooring Fairleads, Sheaves and Accessories Mounted on HULL",MC_B30:"2022-05-12",A_OV_TOT:149,A_OV_COM:133,A_OV_REM:16,A_OV_PROG:89.26,A_OV_STATUS:"NA",A_PG:89.26,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:76,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-9340-9343-01",SUBSYSTEM_DESCR:"Aft Mooring System and HPU",MC_B30:"2022-06-09",A_OV_TOT:77,A_OV_COM:4,A_OV_REM:73,A_OV_PROG:5.19,A_OV_STATUS:"less than 70%",A_PG:5.19,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PB1_OV_TOT:4,PB1_OV_COM:4,PB1_OV_REM:0,PB2_OV_TOT:4,PB2_OV_COM:0,PB2_OV_REM:4,ACT_TAG_VALID:38,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:6},
  //   {SUBSYSTEM:"21-9340-9343-02",SUBSYSTEM_DESCR:"Aft Mooring Fairleads, Sheaves and Accessories Mounted on HULL",MC_B30:"2022-05-12",A_OV_TOT:141,A_OV_COM:130,A_OV_REM:11,A_OV_PROG:92.19,A_OV_STATUS:"greater than 90%",A_PG:92.19,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:7,PA_OV_COM:5,PA_OV_REM:2,PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,ACT_TAG_VALID:70,PUNCH_NUMBER_VALID:9,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-9350-9350-01",SUBSYSTEM_DESCR:"MS Ballast Tanks WBW6,7 and WBE6,7 and FPE1 andFPW1",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-24",A_OV_TOT:92,A_OV_COM:68,A_OV_REM:24,A_OV_PROG:73.91,A_OV_STATUS:"NA",A_PG:73.91,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-12",MC_APPROVED:"2022-02-26",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1A",PA_OV_TOT:8,PA_OV_COM:8,PA_OV_REM:0,PB1_OV_TOT:9,PB1_OV_COM:9,PB1_OV_REM:0,PB2_OV_TOT:8,PB2_OV_COM:3,PB2_OV_REM:5,ACT_TAG_VALID:27,PUNCH_NUMBER_VALID:25,PUNCH_TAG_VALID:7},
  //   {SUBSYSTEM:"21-9350-9350-02",SUBSYSTEM_DESCR:"MS Ballast Ring Main",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-24",A_OV_TOT:66,A_OV_COM:64,A_OV_REM:2,A_OV_PROG:96.96,A_OV_STATUS:"greater than 95%",A_PG:96.96,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-16",MC_APPROVED:"2022-02-28",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1A",PA_OV_TOT:3,PA_OV_COM:3,PA_OV_REM:0,PB1_OV_TOT:38,PB1_OV_COM:38,PB1_OV_REM:0,PB2_OV_TOT:23,PB2_OV_COM:8,PB2_OV_REM:15,PB3_OV_TOT:1,PB3_OV_COM:1,PB3_OV_REM:0,ACT_TAG_VALID:35,PUNCH_NUMBER_VALID:65,PUNCH_TAG_VALID:15},
  //   {SUBSYSTEM:"21-9350-9350-04",SUBSYSTEM_DESCR:"MS Ballast Pump 21-P-935002A",LOSH:"Y",MC_B30:"2022-02-24",A_OV_TOT:105,A_OV_COM:99,A_OV_REM:6,A_OV_PROG:94.28,A_OV_STATUS:"greater than 90%",A_PG:94.28,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-16",MC_APPROVED:"2022-02-26",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:11,PA_OV_COM:10,PA_OV_REM:1,PB1_OV_TOT:37,PB1_OV_COM:37,PB1_OV_REM:0,PB2_OV_TOT:31,PB2_OV_COM:4,PB2_OV_REM:27,ACT_TAG_VALID:57,PUNCH_NUMBER_VALID:79,PUNCH_TAG_VALID:18},
  //   {SUBSYSTEM:"21-9350-9350-05",SUBSYSTEM_DESCR:"MS Ballast Pump 21-P-935002B",LOSH:"Y",MC_B30:"2022-02-24",A_OV_TOT:88,A_OV_COM:82,A_OV_REM:6,A_OV_PROG:93.18,A_OV_STATUS:"greater than 90%",A_PG:93.18,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-16",MC_APPROVED:"2022-02-28",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:8,PA_OV_COM:7,PA_OV_REM:1,PB1_OV_TOT:34,PB1_OV_COM:34,PB1_OV_REM:0,PB2_OV_TOT:27,PB2_OV_COM:3,PB2_OV_REM:24,ACT_TAG_VALID:46,PUNCH_NUMBER_VALID:69,PUNCH_TAG_VALID:17},
  //   {SUBSYSTEM:"21-9370-9370-01",SUBSYSTEM_DESCR:"Slops Tank 21-T-937001 and Pumps 21-P-937001and3",LOSH:"Y",MC_B30:"2022-04-21",A_OV_TOT:294,A_OV_COM:97,A_OV_REM:197,A_OV_PROG:32.99,A_OV_STATUS:"less than 70%",A_PG:32.99,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:110,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9370-9371-01",SUBSYSTEM_DESCR:"Slops Tank 21-T-937002 and Pumps 21-P-937002and4",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:327,A_OV_COM:129,A_OV_REM:198,A_OV_PROG:39.44,A_OV_STATUS:"less than 70%",A_PG:39.44,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:115,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9370-9372-01",SUBSYSTEM_DESCR:"Hazardous Open Drain Header to Slops Tanks",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:602,A_OV_COM:205,A_OV_REM:397,A_OV_PROG:34.05,A_OV_STATUS:"less than 70%",A_PG:34.05,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:103,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9370-9372-02",SUBSYSTEM_DESCR:"Non Hazardous Open Drain Header to Slops Tanks",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:342,A_OV_COM:129,A_OV_REM:213,A_OV_PROG:37.71,A_OV_STATUS:"less than 70%",A_PG:37.71,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:57,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9370-9372-04",SUBSYSTEM_DESCR:"Slops Heaters 21-H-937001 AandB",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:104,A_OV_COM:26,A_OV_REM:78,A_OV_PROG:25,A_OV_STATUS:"less than 70%",A_PG:25,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:33,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9370-9372-07",SUBSYSTEM_DESCR:"Slops Water to Export Line Flushing",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:153,A_OV_COM:25,A_OV_REM:128,A_OV_PROG:16.33,A_OV_STATUS:"less than 70%",A_PG:16.33,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:72,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9370-9372-08",SUBSYSTEM_DESCR:"COW Hot Sea Water Filter 21-F-937001",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:39,A_OV_COM:5,A_OV_REM:34,A_OV_PROG:12.82,A_OV_STATUS:"less than 70%",A_PG:12.82,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9370-9372-09",SUBSYSTEM_DESCR:"Slops Polishing and ODMS ,Oil Discharge Monitoring Units",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:146,A_OV_COM:9,A_OV_REM:137,A_OV_PROG:6.16,A_OV_STATUS:"less than 70%",A_PG:6.16,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:17,PA_OV_COM:12,PA_OV_REM:5,PB1_OV_TOT:24,PB1_OV_COM:8,PB1_OV_REM:16,ACT_TAG_VALID:99,PUNCH_NUMBER_VALID:41,PUNCH_TAG_VALID:5},
  //   {SUBSYSTEM:"21-9370-9372-10",SUBSYSTEM_DESCR:"Treated Slops Header to Overboard",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:29,A_OV_COM:3,A_OV_REM:26,A_OV_PROG:10.34,A_OV_STATUS:"less than 70%",A_PG:10.34,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P3",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-9370-9372-11",SUBSYSTEM_DESCR:"LQ Open Drain Collection",LOSH:"Y",MC_B30:"2022-06-16",A_OV_TOT:78,A_OV_COM:48,A_OV_REM:30,A_OV_PROG:61.53,A_OV_STATUS:"less than 70%",A_PG:61.53,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:13,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9370-9372-12",SUBSYSTEM_DESCR:"HULL Open Drain Collection",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:24,A_OV_COM:5,A_OV_REM:19,A_OV_PROG:20.83,A_OV_STATUS:"less than 70%",A_PG:20.83,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9380-9380-01",SUBSYSTEM_DESCR:"Daily bilge Tank 21-T-938001 and Pumps 21-P-938001AandB",LOSH:"Y",MC_B30:"2022-03-10",A_OV_TOT:111,A_OV_COM:94,A_OV_REM:17,A_OV_PROG:84.68,A_OV_STATUS:"NA",A_PG:84.68,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:11,PA_OV_COM:4,PA_OV_REM:7,PB1_OV_TOT:19,PB1_OV_COM:7,PB1_OV_REM:12,PB2_OV_TOT:12,PB2_OV_COM:0,PB2_OV_REM:12,ACT_TAG_VALID:35,PUNCH_NUMBER_VALID:42,PUNCH_TAG_VALID:14},
  //   {SUBSYSTEM:"21-9380-9380-02",SUBSYSTEM_DESCR:"Daily Bilge Wells and Headers to Daily Bilge Tank",LOSH:"Y",MC_B30:"2022-03-10",A_OV_TOT:255,A_OV_COM:216,A_OV_REM:39,A_OV_PROG:84.7,A_OV_STATUS:"NA",A_PG:84.7,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PB1_OV_TOT:7,PB1_OV_COM:4,PB1_OV_REM:3,PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:69,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-9380-9380-03",SUBSYSTEM_DESCR:"Bilge Water from MS Bilge Collection Header",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:85,A_OV_COM:76,A_OV_REM:9,A_OV_PROG:89.41,A_OV_STATUS:"NA",A_PG:89.41,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PB1_OV_TOT:3,PB1_OV_COM:3,PB1_OV_REM:0,ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-9380-9380-04",SUBSYSTEM_DESCR:"Sludge from Diesel Purifier",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2021-12-23",A_OV_TOT:7,A_OV_COM:7,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-12-09",WD_ACTUAL:"2021-12-10",MC_APPROVED:"2021-12-23",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-9380-9380-05",SUBSYSTEM_DESCR:"Diesel Oil from Diesel Storage Tanks",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-01-13",A_OV_TOT:7,A_OV_COM:7,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-12-30",WD_ACTUAL:"2021-12-31",MC_APPROVED:"2022-01-10",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P2",PB1_OV_TOT:2,PB1_OV_COM:2,PB1_OV_REM:0,PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:0},
  //   {SUBSYSTEM:"21-9380-9380-06",SUBSYSTEM_DESCR:"Daily Bilge for CFC8 and Pump 21-P-938003",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:63,A_OV_COM:28,A_OV_REM:35,A_OV_PROG:44.44,A_OV_STATUS:"less than 70%",A_PG:44.44,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9380-9380-07",SUBSYSTEM_DESCR:"Daily Bilge for CFC5 and CFC6 and Pump 21-P-938004",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:96,A_OV_COM:35,A_OV_REM:61,A_OV_PROG:36.45,A_OV_STATUS:"less than 70%",A_PG:36.45,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:38,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9380-9380-08",SUBSYSTEM_DESCR:"Daily Bilge for CFC7 and Pump 21-P-938005",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:63,A_OV_COM:25,A_OV_REM:38,A_OV_PROG:39.68,A_OV_STATUS:"less than 70%",A_PG:39.68,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9380-9380-09",SUBSYSTEM_DESCR:"Daily Bilge for CFC1 and Pump 21-P-938006",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:65,A_OV_COM:30,A_OV_REM:35,A_OV_PROG:46.15,A_OV_STATUS:"less than 70%",A_PG:46.15,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:21,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9380-9381-01",SUBSYSTEM_DESCR:"Main bilge Pumps 21-P-938002AandB and Headers",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:96,A_OV_COM:58,A_OV_REM:38,A_OV_PROG:60.41,A_OV_STATUS:"less than 70%",A_PG:60.41,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:5,PA_OV_COM:4,PA_OV_REM:1,PB1_OV_TOT:9,PB1_OV_COM:9,PB1_OV_REM:0,PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:43,PUNCH_NUMBER_VALID:16,PUNCH_TAG_VALID:10},
  //   {SUBSYSTEM:"21-9380-9381-02",SUBSYSTEM_DESCR:"Main bilge Water Header to Overboard",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:6,A_OV_COM:6,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-13",MC_APPROVED:"2022-02-16",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0},
  //   {SUBSYSTEM:"21-9380-9382-01",SUBSYSTEM_DESCR:"Emergency Bilge well ,SB middle and header",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:11,A_OV_COM:11,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:5,PB1_OV_COM:4,PB1_OV_REM:1,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-9390-9390-01",SUBSYSTEM_DESCR:"Bunker Station and HPU",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:30,A_OV_COM:9,A_OV_REM:21,A_OV_PROG:30,A_OV_STATUS:"less than 70%",A_PG:30,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:4,PA_OV_COM:1,PA_OV_REM:3,PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-9500-9514-01",SUBSYSTEM_DESCR:"Telephone System - LQ",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:140,A_OV_COM:15,A_OV_REM:125,A_OV_PROG:10.71,A_OV_STATUS:"less than 70%",A_PG:10.71,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:8,PB1_OV_COM:8,PB1_OV_REM:0,ACT_TAG_VALID:136,PUNCH_NUMBER_VALID:9,PUNCH_TAG_VALID:5},
  //   {SUBSYSTEM:"21-9500-9514-02",SUBSYSTEM_DESCR:"Telephone System - Machinery Space and Topsides",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:160,A_OV_COM:7,A_OV_REM:153,A_OV_PROG:4.37,A_OV_STATUS:"less than 70%",A_PG:4.37,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:123,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9500-9515-01",SUBSYSTEM_DESCR:"Hot line Telephones",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:15,A_OV_COM:2,A_OV_REM:13,A_OV_PROG:13.33,A_OV_STATUS:"less than 70%",A_PG:13.33,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P1",PB1_OV_TOT:4,PB1_OV_COM:4,PB1_OV_REM:0,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-9500-9525-01",SUBSYSTEM_DESCR:"Microwave Line of Sight",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:20,A_OV_COM:7,A_OV_REM:13,A_OV_PROG:35,A_OV_STATUS:"less than 70%",A_PG:35,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:3,PB1_OV_COM:3,PB1_OV_REM:0,ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-9500-9527-01",SUBSYSTEM_DESCR:"INMARSAT Terminal and Portable Sat Phones",LOSH:"Y",MC_B30:"2022-04-21",A_OV_TOT:14,A_OV_COM:1,A_OV_REM:13,A_OV_PROG:7.14,A_OV_STATUS:"less than 70%",A_PG:7.14,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9500-9534-01",SUBSYSTEM_DESCR:"Structured Cabling System - LQ Deck-A",LOSH:"Y",MC_B30:"2022-03-10",A_OV_TOT:343,A_OV_COM:154,A_OV_REM:189,A_OV_PROG:44.89,A_OV_STATUS:"less than 70%",A_PG:44.89,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:12,PB1_OV_COM:10,PB1_OV_REM:2,PB2_OV_TOT:7,PB2_OV_COM:2,PB2_OV_REM:5,ACT_TAG_VALID:343,PUNCH_NUMBER_VALID:19,PUNCH_TAG_VALID:11},
  //   {SUBSYSTEM:"21-9500-9534-02",SUBSYSTEM_DESCR:"Structured Cabling System - LQ Levels 1,2 and 3",LOSH:"Y",MC_B30:"2022-03-10",A_OV_TOT:308,A_OV_COM:131,A_OV_REM:177,A_OV_PROG:42.53,A_OV_STATUS:"less than 70%",A_PG:42.53,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P1",PB1_OV_TOT:11,PB1_OV_COM:11,PB1_OV_REM:0,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:308,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:5},
  //   {SUBSYSTEM:"21-9500-9534-03",SUBSYSTEM_DESCR:"Structured Cabling System - LQ Levels 4 and 5",LOSH:"Y",MC_B30:"2022-03-10",A_OV_TOT:249,A_OV_COM:106,A_OV_REM:143,A_OV_PROG:42.57,A_OV_STATUS:"less than 70%",A_PG:42.57,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:21,PA_OV_COM:21,PA_OV_REM:0,PB1_OV_TOT:14,PB1_OV_COM:11,PB1_OV_REM:3,PB2_OV_TOT:27,PB2_OV_COM:5,PB2_OV_REM:22,ACT_TAG_VALID:249,PUNCH_NUMBER_VALID:62,PUNCH_TAG_VALID:27},
  //   {SUBSYSTEM:"21-9500-9534-04",SUBSYSTEM_DESCR:"Structured Cabling System - Machinery Space",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:20,A_OV_COM:0,A_OV_REM:20,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9500-9534-05",SUBSYSTEM_DESCR:"Structured Cabling System - ME05",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:16,A_OV_COM:0,A_OV_REM:16,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9500-9535-01",SUBSYSTEM_DESCR:"FPSO Fibre Optic Cable",MC_B30:"2022-03-10",A_OV_TOT:11,A_OV_COM:5,A_OV_REM:6,A_OV_PROG:45.45,A_OV_STATUS:"less than 70%",A_PG:45.45,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-9500-9535-02",SUBSYSTEM_DESCR:"Subsea FO Termination Unit Cables and Junction Box",LOSH:"Y",MC_B30:"2022-04-21",A_OV_TOT:4,A_OV_COM:0,A_OV_REM:4,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9500-9536-01",SUBSYSTEM_DESCR:"LANandWAN ,Data Network Infrastructure",LOSH:"Y",MC_B30:"2022-03-10",A_OV_TOT:521,A_OV_COM:214,A_OV_REM:307,A_OV_PROG:41.07,A_OV_STATUS:"less than 70%",A_PG:41.07,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:21,PB1_OV_COM:19,PB1_OV_REM:2,PB2_OV_TOT:8,PB2_OV_COM:0,PB2_OV_REM:8,ACT_TAG_VALID:469,PUNCH_NUMBER_VALID:30,PUNCH_TAG_VALID:18},
  //   {SUBSYSTEM:"21-9500-9539-01",SUBSYSTEM_DESCR:"Video Conferencing and Collaboration System",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:74,A_OV_COM:1,A_OV_REM:73,A_OV_PROG:1.35,A_OV_STATUS:"less than 70%",A_PG:1.35,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:74,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9500-9544-01",SUBSYSTEM_DESCR:"Marine and Security CCTV - LQ",LOSH:"Y",MC_B30:"2022-04-21",A_OV_TOT:174,A_OV_COM:36,A_OV_REM:138,A_OV_PROG:20.68,A_OV_STATUS:"less than 70%",A_PG:20.68,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:10,PB1_OV_COM:10,PB1_OV_REM:0,PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:139,PUNCH_NUMBER_VALID:14,PUNCH_TAG_VALID:9},
  //   {SUBSYSTEM:"21-9500-9544-02",SUBSYSTEM_DESCR:"Marine and Security CCTV - Topside HULL Deck",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:234,A_OV_COM:0,A_OV_REM:234,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:166,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9500-9554-01",SUBSYSTEM_DESCR:"Meterological METOCEAN System",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:66,A_OV_COM:4,A_OV_REM:62,A_OV_PROG:6.06,A_OV_STATUS:"less than 70%",A_PG:6.06,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P1",PB1_OV_TOT:2,PB1_OV_COM:2,PB1_OV_REM:0,PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:54,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-9500-9555-01",SUBSYSTEM_DESCR:"Berthing Aids and Approach System",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:56,A_OV_COM:5,A_OV_REM:51,A_OV_PROG:8.92,A_OV_STATUS:"less than 70%",A_PG:8.92,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:3,PB1_OV_COM:2,PB1_OV_REM:1,PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:44,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-9500-9557-01",SUBSYSTEM_DESCR:"Global Maritime Distress and Safety System",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:65,A_OV_COM:12,A_OV_REM:53,A_OV_PROG:18.46,A_OV_STATUS:"less than 70%",A_PG:18.46,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",PB1_OV_TOT:3,PB1_OV_COM:3,PB1_OV_REM:0,ACT_TAG_VALID:65,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:0},
  //   {SUBSYSTEM:"21-9500-9564-01",SUBSYSTEM_DESCR:"Access Control System and Emergency Mustering System",LOSH:"Y",MC_B30:"2022-04-21",A_OV_TOT:666,A_OV_COM:208,A_OV_REM:458,A_OV_PROG:31.23,A_OV_STATUS:"less than 70%",A_PG:31.23,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:23,PA_OV_COM:8,PA_OV_REM:15,PB1_OV_TOT:26,PB1_OV_COM:26,PB1_OV_REM:0,PB2_OV_TOT:11,PB2_OV_COM:0,PB2_OV_REM:11,ACT_TAG_VALID:657,PUNCH_NUMBER_VALID:60,PUNCH_TAG_VALID:31},
  //   {SUBSYSTEM:"21-9500-9565-01",SUBSYSTEM_DESCR:"Ship Security Alert System ,SSAS",LOSH:"Y",MC_B30:"2022-04-21",A_OV_TOT:14,A_OV_COM:7,A_OV_REM:7,A_OV_PROG:50,A_OV_STATUS:"less than 70%",A_PG:50,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-9500-9570-01",SUBSYSTEM_DESCR:"Gyrocompass",LOSH:"Y",MC_B30:"2022-03-24",A_OV_TOT:13,A_OV_COM:5,A_OV_REM:8,A_OV_PROG:38.46,A_OV_STATUS:"less than 70%",A_PG:38.46,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:13,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-9500-9570-02",SUBSYSTEM_DESCR:"D-GPS System",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:10,A_OV_COM:0,A_OV_REM:10,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9500-9570-03",SUBSYSTEM_DESCR:"Hand held satellite phone",LOSH:"Y",MC_B30:"2022-04-21",A_OV_TOT:10,A_OV_COM:1,A_OV_REM:9,A_OV_PROG:10,A_OV_STATUS:"less than 70%",A_PG:10,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9500-9570-04",SUBSYSTEM_DESCR:"Video Wall System",LOSH:"Y",MC_B30:"2022-03-24",A_OV_TOT:39,A_OV_COM:0,A_OV_REM:39,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"B2",PRIORITY:"P1",PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:39,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-9500-9574-01",SUBSYSTEM_DESCR:"Network Management System",LOSH:"Y",MC_B30:"2022-03-10",A_OV_TOT:12,A_OV_COM:8,A_OV_REM:4,A_OV_PROG:66.66,A_OV_STATUS:"less than 70%",A_PG:66.66,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:10,PA_OV_COM:1,PA_OV_REM:9,PB1_OV_TOT:2,PB1_OV_COM:2,PB1_OV_REM:0,PB2_OV_TOT:9,PB2_OV_COM:0,PB2_OV_REM:9,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:21,PUNCH_TAG_VALID:6},
  //   {SUBSYSTEM:"21-9500-9575-01",SUBSYSTEM_DESCR:"Digital Voice Recorder",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:15,A_OV_COM:8,A_OV_REM:7,A_OV_PROG:53.33,A_OV_STATUS:"less than 70%",A_PG:53.33,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:3,PB1_OV_COM:3,PB1_OV_REM:0,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-9810-9811-01",SUBSYSTEM_DESCR:"FPSO West Crane",MC_B30:"2022-05-26",A_OV_TOT:226,A_OV_COM:169,A_OV_REM:57,A_OV_PROG:74.77,A_OV_STATUS:"NA",A_PG:74.77,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:31,PA_OV_COM:2,PA_OV_REM:29,PB1_OV_TOT:25,PB1_OV_COM:13,PB1_OV_REM:12,PB2_OV_TOT:4,PB2_OV_COM:0,PB2_OV_REM:4,ACT_TAG_VALID:222,PUNCH_NUMBER_VALID:60,PUNCH_TAG_VALID:30},
  //   {SUBSYSTEM:"21-9810-9812-01",SUBSYSTEM_DESCR:"FPSO East Crane",MC_B30:"2022-05-26",A_OV_TOT:224,A_OV_COM:136,A_OV_REM:88,A_OV_PROG:60.71,A_OV_STATUS:"less than 70%",A_PG:60.71,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:25,PA_OV_COM:0,PA_OV_REM:25,PB1_OV_TOT:17,PB1_OV_COM:9,PB1_OV_REM:8,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:220,PUNCH_NUMBER_VALID:43,PUNCH_TAG_VALID:31},
  //   {SUBSYSTEM:"21-9820-9822-01",SUBSYSTEM_DESCR:"LQ Personnel Lift",MC_B30:"2022-05-05",A_OV_TOT:93,A_OV_COM:0,A_OV_REM:93,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:86,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-9820-9823-01",SUBSYSTEM_DESCR:"LQ Goods and Material Lift",MC_B30:"2022-05-19",A_OV_TOT:98,A_OV_COM:2,A_OV_REM:96,A_OV_PROG:2.04,A_OV_STATUS:"less than 70%",A_PG:2.04,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:4,PA_OV_COM:4,PA_OV_REM:0,ACT_TAG_VALID:89,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-9820-9824-01",SUBSYSTEM_DESCR:"Riser and Umbilical Pull in Winch",MC_B30:"2022-04-28",A_OV_TOT:14,A_OV_COM:0,A_OV_REM:14,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-9820-9824-02",SUBSYSTEM_DESCR:"Miscellaneous Lifting Beams, Davits, Hoist and Padeyes HULL",MC_B30:"2022-07-28",A_OV_TOT:185,A_OV_COM:73,A_OV_REM:112,A_OV_PROG:39.45,A_OV_STATUS:"less than 70%",A_PG:39.45,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:185,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9820-9824-03",SUBSYSTEM_DESCR:"Miscellaneous Lifting Beams, Davits, Hoist and Padeyes LQ",MC_B30:"2022-07-28",A_OV_TOT:51,A_OV_COM:5,A_OV_REM:46,A_OV_PROG:9.8,A_OV_STATUS:"less than 70%",A_PG:9.8,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:51,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9820-9824-04",SUBSYSTEM_DESCR:"Miscellaneous Lifting Beams, Davits, Hoist and Padeyes Topsides",MC_B30:"2022-04-28",A_OV_TOT:310,A_OV_COM:186,A_OV_REM:124,A_OV_PROG:60,A_OV_STATUS:"less than 70%",A_PG:60,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:310,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9840-9841-01",SUBSYSTEM_DESCR:"Workshops",MC_B30:"2022-06-02",A_OV_TOT:14,A_OV_COM:0,A_OV_REM:14,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"B1",PRIORITY:"P3",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0},
  //   {SUBSYSTEM:"21-9840-9842-01",SUBSYSTEM_DESCR:"Laboratory",MC_B30:"2022-05-12",A_OV_TOT:32,A_OV_COM:0,A_OV_REM:32,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9840-9843-01",SUBSYSTEM_DESCR:"Medical Suite",MC_B30:"2022-06-23",A_OV_TOT:4,A_OV_COM:1,A_OV_REM:3,A_OV_PROG:25,A_OV_STATUS:"less than 70%",A_PG:25,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9840-9844-01",SUBSYSTEM_DESCR:"Galley",MC_B30:"2022-05-12",A_OV_TOT:65,A_OV_COM:21,A_OV_REM:44,A_OV_PROG:32.3,A_OV_STATUS:"less than 70%",A_PG:32.3,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:11,PA_OV_COM:11,PA_OV_REM:0,PB1_OV_TOT:2,PB1_OV_COM:2,PB1_OV_REM:0,ACT_TAG_VALID:56,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:2},
  //   {SUBSYSTEM:"21-9840-9845-01",SUBSYSTEM_DESCR:"Laundry",MC_B30:"2022-05-12",A_OV_TOT:20,A_OV_COM:8,A_OV_REM:12,A_OV_PROG:40,A_OV_STATUS:"less than 70%",A_PG:40,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:19,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-9840-9846-01",SUBSYSTEM_DESCR:"Hospital and Galley Call Alarm System",MC_B30:"2022-05-05",A_OV_TOT:38,A_OV_COM:6,A_OV_REM:32,A_OV_PROG:15.78,A_OV_STATUS:"less than 70%",A_PG:15.78,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:38,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
  //   {SUBSYSTEM:"21-9921-9921-01",SUBSYSTEM_DESCR:"LQ Emergency Pressurization Fans",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:104,A_OV_COM:58,A_OV_REM:46,A_OV_PROG:55.76,A_OV_STATUS:"less than 70%",A_PG:55.76,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P3",PA_OV_TOT:5,PA_OV_COM:5,PA_OV_REM:0,PB1_OV_TOT:11,PB1_OV_COM:11,PB1_OV_REM:0,PB2_OV_TOT:4,PB2_OV_COM:0,PB2_OV_REM:4,ACT_TAG_VALID:72,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:9},
  //   {SUBSYSTEM:"21-9921-9921-02",SUBSYSTEM_DESCR:"LQ Battery Exhaust Fans, Ducting and Dampers",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:94,A_OV_COM:93,A_OV_REM:1,A_OV_PROG:98.93,A_OV_STATUS:"greater than 95%",A_PG:98.93,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-09",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:17,PA_OV_COM:3,PA_OV_REM:14,PB1_OV_TOT:31,PB1_OV_COM:26,PB1_OV_REM:5,PB2_OV_TOT:21,PB2_OV_COM:0,PB2_OV_REM:21,ACT_TAG_VALID:76,PUNCH_NUMBER_VALID:69,PUNCH_TAG_VALID:33},
  //   {SUBSYSTEM:"21-9921-9921-03",SUBSYSTEM_DESCR:"LQ Extracts, Ducting and Damper",LOSH:"Y",MC_B30:"2022-04-21",A_OV_TOT:258,A_OV_COM:136,A_OV_REM:122,A_OV_PROG:52.71,A_OV_STATUS:"less than 70%",A_PG:52.71,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:4,PA_OV_COM:4,PA_OV_REM:0,PB1_OV_TOT:31,PB1_OV_COM:30,PB1_OV_REM:1,PB2_OV_TOT:8,PB2_OV_COM:1,PB2_OV_REM:7,ACT_TAG_VALID:214,PUNCH_NUMBER_VALID:43,PUNCH_TAG_VALID:23},
  //   {SUBSYSTEM:"21-9921-9921-04",SUBSYSTEM_DESCR:"LQ Emergency SWB Room ACUsandACCUs and UCPs",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-24",A_OV_TOT:82,A_OV_COM:73,A_OV_REM:9,A_OV_PROG:89.02,A_OV_STATUS:"NA",A_PG:89.02,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:19,PA_OV_COM:17,PA_OV_REM:2,PB1_OV_TOT:46,PB1_OV_COM:29,PB1_OV_REM:17,PB2_OV_TOT:9,PB2_OV_COM:1,PB2_OV_REM:8,ACT_TAG_VALID:41,PUNCH_NUMBER_VALID:74,PUNCH_TAG_VALID:16},
  //   {SUBSYSTEM:"21-9921-9921-05",SUBSYSTEM_DESCR:"LQ CCR ACUsandACCUs and UCPs",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:82,A_OV_COM:78,A_OV_REM:4,A_OV_PROG:95.12,A_OV_STATUS:"greater than 95%",A_PG:95.12,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-28",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:13,PA_OV_COM:5,PA_OV_REM:8,PB1_OV_TOT:19,PB1_OV_COM:8,PB1_OV_REM:11,PB2_OV_TOT:12,PB2_OV_COM:2,PB2_OV_REM:10,ACT_TAG_VALID:39,PUNCH_NUMBER_VALID:44,PUNCH_TAG_VALID:16},
  //   {SUBSYSTEM:"21-9921-9921-06",SUBSYSTEM_DESCR:"LQ CER ACUsandACCUs and UCPs",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:79,A_OV_COM:75,A_OV_REM:4,A_OV_PROG:94.93,A_OV_STATUS:"greater than 90%",A_PG:94.93,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-05",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:25,PA_OV_COM:18,PA_OV_REM:7,PB1_OV_TOT:57,PB1_OV_COM:41,PB1_OV_REM:16,PB2_OV_TOT:14,PB2_OV_COM:5,PB2_OV_REM:9,ACT_TAG_VALID:40,PUNCH_NUMBER_VALID:96,PUNCH_TAG_VALID:23},
  //   {SUBSYSTEM:"21-9921-9921-07",SUBSYSTEM_DESCR:"LQ Secondary ICC ACUsandACCUs and UCPs",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:72,A_OV_COM:70,A_OV_REM:2,A_OV_PROG:97.22,A_OV_STATUS:"greater than 95%",A_PG:97.22,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-05",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:10,PA_OV_COM:9,PA_OV_REM:1,PB1_OV_TOT:18,PB1_OV_COM:10,PB1_OV_REM:8,PB2_OV_TOT:13,PB2_OV_COM:2,PB2_OV_REM:11,ACT_TAG_VALID:47,PUNCH_NUMBER_VALID:41,PUNCH_TAG_VALID:13},
  //   {SUBSYSTEM:"21-9921-9921-08",SUBSYSTEM_DESCR:"LQ Dining Room and Secondary Muster ACUsandACCUs and UCPs",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:76,A_OV_COM:72,A_OV_REM:4,A_OV_PROG:94.73,A_OV_STATUS:"greater than 90%",A_PG:94.73,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-28",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:18,PA_OV_COM:18,PA_OV_REM:0,PB1_OV_TOT:43,PB1_OV_COM:27,PB1_OV_REM:16,PB2_OV_TOT:8,PB2_OV_COM:1,PB2_OV_REM:7,ACT_TAG_VALID:36,PUNCH_NUMBER_VALID:69,PUNCH_TAG_VALID:21},
  //   {SUBSYSTEM:"21-9921-9921-09",SUBSYSTEM_DESCR:"LQ Telecom Room ACUsandACCUs and UCPs",LOSH:"Y",MC_B30:"2022-04-21",A_OV_TOT:41,A_OV_COM:39,A_OV_REM:2,A_OV_PROG:95.12,A_OV_STATUS:"greater than 95%",A_PG:95.12,RFI_ISSUED:"Done",WD_ACTUAL:"2021-03-01",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:11,PA_OV_COM:6,PA_OV_REM:5,PB1_OV_TOT:4,PB1_OV_COM:4,PB1_OV_REM:0,PB2_OV_TOT:10,PB2_OV_COM:1,PB2_OV_REM:9,ACT_TAG_VALID:19,PUNCH_NUMBER_VALID:25,PUNCH_TAG_VALID:8},
  //   {SUBSYSTEM:"21-9921-9922-01",SUBSYSTEM_DESCR:"LQ HVAC UCPs, EDBs",MC_B30:"2022-03-24",A_OV_TOT:66,A_OV_COM:66,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-28",MC_APPROVED:"2022-03-08",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:16,PA_OV_COM:16,PA_OV_REM:0,PB1_OV_TOT:43,PB1_OV_COM:38,PB1_OV_REM:5,PB2_OV_TOT:11,PB2_OV_COM:2,PB2_OV_REM:9,ACT_TAG_VALID:66,PUNCH_NUMBER_VALID:70,PUNCH_TAG_VALID:11},
  //   {SUBSYSTEM:"21-9921-9922-02",SUBSYSTEM_DESCR:"LQ HVAC AHUs, Ducting and Dampers",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:2031,A_OV_COM:1417,A_OV_REM:614,A_OV_PROG:69.76,A_OV_STATUS:"less than 70%",A_PG:69.76,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:33,PA_OV_COM:21,PA_OV_REM:12,PB1_OV_TOT:128,PB1_OV_COM:113,PB1_OV_REM:15,PB2_OV_TOT:37,PB2_OV_COM:12,PB2_OV_REM:25,ACT_TAG_VALID:1856,PUNCH_NUMBER_VALID:198,PUNCH_TAG_VALID:114},
  //   {SUBSYSTEM:"21-9921-9922-03",SUBSYSTEM_DESCR:"LQ HVAC Extracts, Ducting and Dampers",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:605,A_OV_COM:475,A_OV_REM:130,A_OV_PROG:78.51,A_OV_STATUS:"NA",A_PG:78.51,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:7,PA_OV_COM:5,PA_OV_REM:2,PB1_OV_TOT:38,PB1_OV_COM:33,PB1_OV_REM:5,PB2_OV_TOT:10,PB2_OV_COM:1,PB2_OV_REM:9,ACT_TAG_VALID:577,PUNCH_NUMBER_VALID:55,PUNCH_TAG_VALID:28},
  //   {SUBSYSTEM:"21-9921-9922-04",SUBSYSTEM_DESCR:"LQ Galley AHUs, Ducting and Dampers",LOSH:"Y",MC_B30:"2022-04-21",A_OV_TOT:207,A_OV_COM:94,A_OV_REM:113,A_OV_PROG:45.41,A_OV_STATUS:"less than 70%",A_PG:45.41,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:5,PA_OV_COM:3,PA_OV_REM:2,PB1_OV_TOT:22,PB1_OV_COM:18,PB1_OV_REM:4,PB2_OV_TOT:4,PB2_OV_COM:1,PB2_OV_REM:3,ACT_TAG_VALID:174,PUNCH_NUMBER_VALID:31,PUNCH_TAG_VALID:16},
  //   {SUBSYSTEM:"21-9921-9922-05",SUBSYSTEM_DESCR:"LQ General Exhaust Air, Ducting and Dampers",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:164,A_OV_COM:117,A_OV_REM:47,A_OV_PROG:71.34,A_OV_STATUS:"NA",A_PG:71.34,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PB1_OV_TOT:27,PB1_OV_COM:24,PB1_OV_REM:3,PB2_OV_TOT:4,PB2_OV_COM:0,PB2_OV_REM:4,ACT_TAG_VALID:149,PUNCH_NUMBER_VALID:33,PUNCH_TAG_VALID:22},
  //   {SUBSYSTEM:"21-9921-9922-06",SUBSYSTEM_DESCR:"LQ Galley Hood Exhaust Fans, Ducting and Dampers",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:247,A_OV_COM:145,A_OV_REM:102,A_OV_PROG:58.7,A_OV_STATUS:"less than 70%",A_PG:58.7,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:12,PA_OV_COM:8,PA_OV_REM:4,PB1_OV_TOT:24,PB1_OV_COM:23,PB1_OV_REM:1,PB2_OV_TOT:8,PB2_OV_COM:2,PB2_OV_REM:6,ACT_TAG_VALID:179,PUNCH_NUMBER_VALID:44,PUNCH_TAG_VALID:22},
  //   {SUBSYSTEM:"21-9921-9922-07",SUBSYSTEM_DESCR:"LQ Toilet Exhaust Fans, Ducting and Dampers",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:188,A_OV_COM:140,A_OV_REM:48,A_OV_PROG:74.46,A_OV_STATUS:"NA",A_PG:74.46,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,PB1_OV_TOT:24,PB1_OV_COM:18,PB1_OV_REM:6,PB2_OV_TOT:4,PB2_OV_COM:0,PB2_OV_REM:4,ACT_TAG_VALID:174,PUNCH_NUMBER_VALID:30,PUNCH_TAG_VALID:15},
  //   {SUBSYSTEM:"21-9921-9922-08",SUBSYSTEM_DESCR:"LQ Laundry Exhaust Fans, Ducting and Dampers",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:96,A_OV_COM:47,A_OV_REM:49,A_OV_PROG:48.95,A_OV_STATUS:"less than 70%",A_PG:48.95,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:22,PB1_OV_COM:20,PB1_OV_REM:2,ACT_TAG_VALID:66,PUNCH_NUMBER_VALID:22,PUNCH_TAG_VALID:9},
  //   {SUBSYSTEM:"21-9921-9922-09",SUBSYSTEM_DESCR:"LQ Sick Bay Exhaust Fans, Ducting and Dampers",MC_B30:"2022-04-21",A_OV_TOT:91,A_OV_COM:56,A_OV_REM:35,A_OV_PROG:61.53,A_OV_STATUS:"less than 70%",A_PG:61.53,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PB1_OV_TOT:4,PB1_OV_COM:4,PB1_OV_REM:0,ACT_TAG_VALID:71,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-9921-9922-11",SUBSYSTEM_DESCR:"LQ Laboratory Exhaust Fans, Ducting and Dampers",LOSH:"Y",MC_B30:"2022-04-21",A_OV_TOT:85,A_OV_COM:52,A_OV_REM:33,A_OV_PROG:61.17,A_OV_STATUS:"less than 70%",A_PG:61.17,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:18,PB1_OV_COM:14,PB1_OV_REM:4,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:64,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:13},
  //   {SUBSYSTEM:"21-9921-9922-12",SUBSYSTEM_DESCR:"LQ Provision Plant Exhaust Fans, Ducting and Dampers",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:54,A_OV_COM:40,A_OV_REM:14,A_OV_PROG:74.07,A_OV_STATUS:"NA",A_PG:74.07,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:0,PA_OV_REM:3,PB1_OV_TOT:8,PB1_OV_COM:7,PB1_OV_REM:1,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:47,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:6},
  //   {SUBSYSTEM:"21-9921-9922-13",SUBSYSTEM_DESCR:"HULL Purge",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:32,A_OV_COM:20,A_OV_REM:12,A_OV_PROG:62.5,A_OV_STATUS:"less than 70%",A_PG:62.5,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:6,PB1_OV_COM:3,PB1_OV_REM:3,PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:4},
  //   {SUBSYSTEM:"21-9921-9922-14",SUBSYSTEM_DESCR:"LQ Paint Store Supply Fans, Ducting and Dampers",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:133,A_OV_COM:44,A_OV_REM:89,A_OV_PROG:33.08,A_OV_STATUS:"less than 70%",A_PG:33.08,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:8,PA_OV_COM:2,PA_OV_REM:6,PB1_OV_TOT:6,PB1_OV_COM:2,PB1_OV_REM:4,PB2_OV_TOT:3,PB2_OV_COM:2,PB2_OV_REM:1,ACT_TAG_VALID:88,PUNCH_NUMBER_VALID:17,PUNCH_TAG_VALID:5},
  //   {SUBSYSTEM:"21-9921-9922-15",SUBSYSTEM_DESCR:"LQ Dining Room Exhaust Fan, Ducting and Dampers",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:73,A_OV_COM:71,A_OV_REM:2,A_OV_PROG:97.26,A_OV_STATUS:"greater than 95%",A_PG:97.26,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-01",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:26,PB1_OV_COM:21,PB1_OV_REM:5,PB2_OV_TOT:12,PB2_OV_COM:0,PB2_OV_REM:12,ACT_TAG_VALID:60,PUNCH_NUMBER_VALID:38,PUNCH_TAG_VALID:20},
  //   {SUBSYSTEM:"21-9921-9927-01",SUBSYSTEM_DESCR:"Cool Store System in Galley",MC_B30:"2022-05-05",A_OV_TOT:179,A_OV_COM:75,A_OV_REM:104,A_OV_PROG:41.89,A_OV_STATUS:"less than 70%",A_PG:41.89,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:10,PA_OV_COM:5,PA_OV_REM:5,PB1_OV_TOT:28,PB1_OV_COM:24,PB1_OV_REM:4,PB2_OV_TOT:9,PB2_OV_COM:1,PB2_OV_REM:8,ACT_TAG_VALID:141,PUNCH_NUMBER_VALID:47,PUNCH_TAG_VALID:21},
  //   {SUBSYSTEM:"21-9950-9950-01",SUBSYSTEM_DESCR:"Tertiary Refuge Air Intake, Ducting and Dampers",LOSH:"Y",MC_B30:"2022-06-23",A_OV_TOT:106,A_OV_COM:4,A_OV_REM:102,A_OV_PROG:3.77,A_OV_STATUS:"less than 70%",A_PG:3.77,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:80,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9950-9950-02",SUBSYSTEM_DESCR:"Tertiary Refuge ACUsandACCUs and UCPs",LOSH:"Y",MC_B30:"2022-06-30",A_OV_TOT:136,A_OV_COM:10,A_OV_REM:126,A_OV_PROG:7.35,A_OV_STATUS:"less than 70%",A_PG:7.35,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:90,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
  //   {SUBSYSTEM:"21-9970-9970-01",SUBSYSTEM_DESCR:"HULL Emergency Pressurisation Fans",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:115,A_OV_COM:72,A_OV_REM:43,A_OV_PROG:62.6,A_OV_STATUS:"less than 70%",A_PG:62.6,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P3",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PB1_OV_TOT:24,PB1_OV_COM:21,PB1_OV_REM:3,PB2_OV_TOT:5,PB2_OV_COM:0,PB2_OV_REM:5,ACT_TAG_VALID:84,PUNCH_NUMBER_VALID:31,PUNCH_TAG_VALID:18},
  //   {SUBSYSTEM:"21-9970-9971-01",SUBSYSTEM_DESCR:"HULL HVAC UCPs, EDBs",BATCH:"Incentive 2",MC_B30:"2022-01-27",A_OV_TOT:46,A_OV_COM:46,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-15",MC_APPROVED:"2022-01-24",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:16,PA_OV_COM:16,PA_OV_REM:0,PB1_OV_TOT:20,PB1_OV_COM:18,PB1_OV_REM:2,PB2_OV_TOT:11,PB2_OV_COM:2,PB2_OV_REM:9,ACT_TAG_VALID:45,PUNCH_NUMBER_VALID:47,PUNCH_TAG_VALID:11},
  //   {SUBSYSTEM:"21-9970-9971-02",SUBSYSTEM_DESCR:"HULL Machinery Space Supply Fans , Ducting and Dampers",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:169,A_OV_COM:157,A_OV_REM:12,A_OV_PROG:92.89,A_OV_STATUS:"greater than 90%",A_PG:92.89,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:7,PA_OV_COM:5,PA_OV_REM:2,PB1_OV_TOT:34,PB1_OV_COM:27,PB1_OV_REM:7,PB2_OV_TOT:11,PB2_OV_COM:0,PB2_OV_REM:11,ACT_TAG_VALID:153,PUNCH_NUMBER_VALID:52,PUNCH_TAG_VALID:24},
  //   {SUBSYSTEM:"21-9970-9971-03",SUBSYSTEM_DESCR:"HULL Machinery Space Exhaust Fans , Ducting and Dampers",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:169,A_OV_COM:167,A_OV_REM:2,A_OV_PROG:98.81,A_OV_STATUS:"greater than 95%",A_PG:98.81,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-02",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P3",PA_OV_TOT:10,PA_OV_COM:10,PA_OV_REM:0,PB1_OV_TOT:42,PB1_OV_COM:35,PB1_OV_REM:7,PB2_OV_TOT:17,PB2_OV_COM:0,PB2_OV_REM:17,ACT_TAG_VALID:160,PUNCH_NUMBER_VALID:69,PUNCH_TAG_VALID:29},
  //   {SUBSYSTEM:"21-9970-9971-04",SUBSYSTEM_DESCR:"KSG Room Supply Fans, Ducting and Dampers",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-24",A_OV_TOT:248,A_OV_COM:238,A_OV_REM:10,A_OV_PROG:95.96,A_OV_STATUS:"greater than 95%",A_PG:95.96,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-19",MC_APPROVED:"2022-02-28",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:7,PA_OV_COM:7,PA_OV_REM:0,PB1_OV_TOT:68,PB1_OV_COM:64,PB1_OV_REM:4,PB2_OV_TOT:44,PB2_OV_COM:6,PB2_OV_REM:38,ACT_TAG_VALID:209,PUNCH_NUMBER_VALID:119,PUNCH_TAG_VALID:49},
  //   {SUBSYSTEM:"21-9970-9971-05",SUBSYSTEM_DESCR:"KSG Room Exhaust Fans, Ducting and Dampers",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-03",A_OV_TOT:115,A_OV_COM:115,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2022-01-07",WD_ACTUAL:"2022-01-10",MC_APPROVED:"2022-01-29",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1",PA_OV_TOT:7,PA_OV_COM:7,PA_OV_REM:0,PB1_OV_TOT:34,PB1_OV_COM:34,PB1_OV_REM:0,PB2_OV_TOT:10,PB2_OV_COM:4,PB2_OV_REM:6,ACT_TAG_VALID:108,PUNCH_NUMBER_VALID:51,PUNCH_TAG_VALID:24},
  //   {SUBSYSTEM:"21-9970-9971-06",SUBSYSTEM_DESCR:"HULL Chilled Water System and UCP",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:547,A_OV_COM:226,A_OV_REM:321,A_OV_PROG:41.31,A_OV_STATUS:"less than 70%",A_PG:41.31,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:13,PA_OV_COM:10,PA_OV_REM:3,PB1_OV_TOT:14,PB1_OV_COM:9,PB1_OV_REM:5,PB2_OV_TOT:6,PB2_OV_COM:0,PB2_OV_REM:6,ACT_TAG_VALID:276,PUNCH_NUMBER_VALID:33,PUNCH_TAG_VALID:15},
  //   {SUBSYSTEM:"21-9970-9971-07",SUBSYSTEM_DESCR:"HULL Chiller Exhaust Fans, Ducting and Dampers",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:66,A_OV_COM:59,A_OV_REM:7,A_OV_PROG:89.39,A_OV_STATUS:"NA",A_PG:89.39,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:9,PB1_OV_COM:7,PB1_OV_REM:2,PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:65,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:6},
  //   {SUBSYSTEM:"21-9970-9971-08",SUBSYSTEM_DESCR:"HULL Machinery Space AHU Air Supply",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:440,A_OV_COM:368,A_OV_REM:72,A_OV_PROG:83.63,A_OV_STATUS:"NA",A_PG:83.63,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:18,PA_OV_COM:16,PA_OV_REM:2,PB1_OV_TOT:76,PB1_OV_COM:63,PB1_OV_REM:13,PB2_OV_TOT:9,PB2_OV_COM:0,PB2_OV_REM:9,ACT_TAG_VALID:397,PUNCH_NUMBER_VALID:103,PUNCH_TAG_VALID:48},
  //   {SUBSYSTEM:"21-9970-9971-09",SUBSYSTEM_DESCR:"HULL Machinery Space AHU Recirculation Air",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:216,A_OV_COM:216,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"B2",PRIORITY:"P3",PA_OV_TOT:3,PA_OV_COM:3,PA_OV_REM:0,PB1_OV_TOT:32,PB1_OV_COM:32,PB1_OV_REM:0,PB2_OV_TOT:8,PB2_OV_COM:0,PB2_OV_REM:8,ACT_TAG_VALID:205,PUNCH_NUMBER_VALID:43,PUNCH_TAG_VALID:19},
  //   {SUBSYSTEM:"21-9970-9971-10",SUBSYSTEM_DESCR:"HULL Diesel Oil Pump Room Supply Fans, Ducting and Dampers",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-24",A_OV_TOT:91,A_OV_COM:91,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:4,PA_OV_COM:3,PA_OV_REM:1,PB1_OV_TOT:37,PB1_OV_COM:33,PB1_OV_REM:4,PB2_OV_TOT:9,PB2_OV_COM:0,PB2_OV_REM:9,ACT_TAG_VALID:81,PUNCH_NUMBER_VALID:50,PUNCH_TAG_VALID:21},
  //   {SUBSYSTEM:"21-9970-9971-11",SUBSYSTEM_DESCR:"HULL Diesel Oil Pump Room Exhaust Fans, Ducting and Dampers",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-24",A_OV_TOT:77,A_OV_COM:77,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-17",MC_APPROVED:"2022-02-28",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:6,PA_OV_COM:6,PA_OV_REM:0,PB1_OV_TOT:40,PB1_OV_COM:35,PB1_OV_REM:5,PB2_OV_TOT:6,PB2_OV_COM:0,PB2_OV_REM:6,ACT_TAG_VALID:72,PUNCH_NUMBER_VALID:52,PUNCH_TAG_VALID:19},
  //   {SUBSYSTEM:"21-9970-9971-12",SUBSYSTEM_DESCR:"HULL Machinery Space HPU AHU, Ducting and Damper",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:139,A_OV_COM:135,A_OV_REM:4,A_OV_PROG:97.12,A_OV_STATUS:"greater than 95%",A_PG:97.12,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:3,PA_OV_REM:0,PB1_OV_TOT:19,PB1_OV_COM:11,PB1_OV_REM:8,PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:134,PUNCH_NUMBER_VALID:25,PUNCH_TAG_VALID:15},
  //   {SUBSYSTEM:"21-9976-9976-01",SUBSYSTEM_DESCR:"Topsides Pressurisation Fans",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:102,A_OV_COM:17,A_OV_REM:85,A_OV_PROG:16.66,A_OV_STATUS:"less than 70%",A_PG:16.66,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:5,PA_OV_COM:1,PA_OV_REM:4,PB1_OV_TOT:8,PB1_OV_COM:3,PB1_OV_REM:5,PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:71,PUNCH_NUMBER_VALID:15,PUNCH_TAG_VALID:6},
  //   {SUBSYSTEM:"21-9976-9976-02",SUBSYSTEM_DESCR:"Topsides Battery Exhaust Fans, Ducting and Dampers",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:78,A_OV_COM:17,A_OV_REM:61,A_OV_PROG:21.79,A_OV_STATUS:"less than 70%",A_PG:21.79,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:1,PA_OV_REM:2,PB1_OV_TOT:8,PB1_OV_COM:3,PB1_OV_REM:5,PB2_OV_TOT:2,PB2_OV_COM:1,PB2_OV_REM:1,ACT_TAG_VALID:56,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-9976-9976-03",SUBSYSTEM_DESCR:"Emergency Room ACUsandACCUs and UCPs",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:89,A_OV_COM:40,A_OV_REM:49,A_OV_PROG:44.94,A_OV_STATUS:"less than 70%",A_PG:44.94,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:11,PA_OV_COM:5,PA_OV_REM:6,PB1_OV_TOT:7,PB1_OV_COM:1,PB1_OV_REM:6,PB2_OV_TOT:4,PB2_OV_COM:0,PB2_OV_REM:4,ACT_TAG_VALID:51,PUNCH_NUMBER_VALID:22,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-9976-9977-01",SUBSYSTEM_DESCR:"Topsides HVAC UCPs, EDBs",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:29,A_OV_COM:1,A_OV_REM:28,A_OV_PROG:3.44,A_OV_STATUS:"less than 70%",A_PG:3.44,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:8,PB1_OV_COM:0,PB1_OV_REM:8,PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:29,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:3},
  //   {SUBSYSTEM:"21-9976-9977-02",SUBSYSTEM_DESCR:"Topsides HVAC AHUs, Ducting and Dampers",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:482,A_OV_COM:210,A_OV_REM:272,A_OV_PROG:43.56,A_OV_STATUS:"less than 70%",A_PG:43.56,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:8,PA_OV_COM:6,PA_OV_REM:2,PB1_OV_TOT:4,PB1_OV_COM:4,PB1_OV_REM:0,ACT_TAG_VALID:393,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:7},
  //   {SUBSYSTEM:"21-9976-9977-03",SUBSYSTEM_DESCR:"Topsides HVAC Extracts, Ducting and Dampers",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:169,A_OV_COM:62,A_OV_REM:107,A_OV_PROG:36.68,A_OV_STATUS:"less than 70%",A_PG:36.68,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:4,PA_OV_COM:0,PA_OV_REM:4,ACT_TAG_VALID:137,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:3}
  //   ],
  DataItems:   [
    {SUBSYSTEM:"21-1001-1001-01",SUBSYSTEM_DESCR:"Annulus Riser vent Monitoring System",LOSH:"Y",MC_B30:"2022-07-28",A_OV_TOT:160,A_OV_COM:24,A_OV_REM:136,A_OV_PROG:15,A_OV_STATUS:"less than 70%",A_PG:15,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:33,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-1010-1011-01",SUBSYSTEM_DESCR:"Flowline 1 and Receiver",LOSH:"Y",MC_B30:"2022-07-07",A_OV_TOT:417,A_OV_COM:77,A_OV_REM:340,A_OV_PROG:18.46,A_OV_STATUS:"less than 70%",A_PG:18.46,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,PB2_OV_TOT:4,PB2_OV_COM:0,PB2_OV_REM:4,ACT_TAG_VALID:227,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-1010-1012-01",SUBSYSTEM_DESCR:"Flowline 2 and Receiver",LOSH:"Y",MC_B30:"2022-07-07",A_OV_TOT:406,A_OV_COM:78,A_OV_REM:328,A_OV_PROG:19.21,A_OV_STATUS:"less than 70%",A_PG:19.21,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:221,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-1090-1090-01",SUBSYSTEM_DESCR:"Production Control Umbilical",MC_B30:"2022-06-30",A_OV_TOT:27,A_OV_COM:0,A_OV_REM:27,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-1510-1510-01",SUBSYSTEM_DESCR:"MEG Riser and Pipeline System to DC1",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:33,A_OV_COM:2,A_OV_REM:31,A_OV_PROG:6.06,A_OV_STATUS:"less than 70%",A_PG:6.06,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-1600-1611-01",SUBSYSTEM_DESCR:"Gas Export Riser 1",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:70,A_OV_COM:8,A_OV_REM:62,A_OV_PROG:11.42,A_OV_STATUS:"less than 70%",A_PG:11.42,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:37,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-1600-1612-01",SUBSYSTEM_DESCR:"Gas Export Riser 2",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:70,A_OV_COM:8,A_OV_REM:62,A_OV_PROG:11.42,A_OV_STATUS:"less than 70%",A_PG:11.42,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:37,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-1911-1911-01",SUBSYSTEM_DESCR:"Hydraulic HPUs, RIOs and TUTUs",LOSH:"Y",MC_B30:"2022-06-23",A_OV_TOT:122,A_OV_COM:8,A_OV_REM:114,A_OV_PROG:6.55,A_OV_STATUS:"less than 70%",A_PG:6.55,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:91,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-1931-1931-01",SUBSYSTEM_DESCR:"Subsea Control System ,MCS, SSIV SPCU, EPUs, HVE JBs and LVE JBs and Subsea ICSS",MC_B30:"2022-06-30",A_OV_TOT:97,A_OV_COM:18,A_OV_REM:79,A_OV_PROG:18.55,A_OV_STATUS:"less than 70%",A_PG:18.55,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PB1_OV_TOT:7,PB1_OV_COM:7,PB1_OV_REM:0,PB2_OV_TOT:13,PB2_OV_COM:0,PB2_OV_REM:13,ACT_TAG_VALID:95,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-2200-2131-01",SUBSYSTEM_DESCR:"Slug Catcher 1 and Liquid Heater 1",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:595,A_OV_COM:158,A_OV_REM:437,A_OV_PROG:26.55,A_OV_STATUS:"less than 70%",A_PG:26.55,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P3",PB1_OV_TOT:6,PB1_OV_COM:1,PB1_OV_REM:5,PB2_OV_TOT:5,PB2_OV_COM:0,PB2_OV_REM:5,ACT_TAG_VALID:287,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-2200-2132-01",SUBSYSTEM_DESCR:"Slug Catcher 2 and Liquid heater 2",LOSH:"Y",MC_B30:"2022-07-07",A_OV_TOT:587,A_OV_COM:156,A_OV_REM:431,A_OV_PROG:26.57,A_OV_STATUS:"less than 70%",A_PG:26.57,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P3",PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,PB2_OV_TOT:4,PB2_OV_COM:0,PB2_OV_REM:4,ACT_TAG_VALID:275,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-2200-2221-01",SUBSYSTEM_DESCR:"MP Separator",LOSH:"Y",MC_B30:"2022-07-14",A_OV_TOT:339,A_OV_COM:88,A_OV_REM:251,A_OV_PROG:25.95,A_OV_STATUS:"less than 70%",A_PG:25.95,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:141,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-2200-2230-01",SUBSYSTEM_DESCR:"LP Separator",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:273,A_OV_COM:76,A_OV_REM:197,A_OV_PROG:27.83,A_OV_STATUS:"less than 70%",A_PG:27.83,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:102,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-2200-2230-02",SUBSYSTEM_DESCR:"Interstage Heaters",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:131,A_OV_COM:52,A_OV_REM:79,A_OV_PROG:39.69,A_OV_STATUS:"less than 70%",A_PG:39.69,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P3",PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:32,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2200-2240-01",SUBSYSTEM_DESCR:"LLP separator, Condensate Coolers and Condensate Coalescer Pumps",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:440,A_OV_COM:135,A_OV_REM:305,A_OV_PROG:30.68,A_OV_STATUS:"less than 70%",A_PG:30.68,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:0,PA_OV_REM:3,PB2_OV_TOT:11,PB2_OV_COM:0,PB2_OV_REM:11,ACT_TAG_VALID:171,PUNCH_NUMBER_VALID:14,PUNCH_TAG_VALID:11},
    {SUBSYSTEM:"21-2200-2240-03",SUBSYSTEM_DESCR:"Condensate Coalescer Filters",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:161,A_OV_COM:65,A_OV_REM:96,A_OV_PROG:40.37,A_OV_STATUS:"less than 70%",A_PG:40.37,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:4,PA_OV_COM:1,PA_OV_REM:3,PB2_OV_TOT:6,PB2_OV_COM:0,PB2_OV_REM:6,ACT_TAG_VALID:49,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-2200-2240-04",SUBSYSTEM_DESCR:"Condensate Coalescer Package",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:185,A_OV_COM:47,A_OV_REM:138,A_OV_PROG:25.4,A_OV_STATUS:"less than 70%",A_PG:25.4,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:4,PB1_OV_COM:3,PB1_OV_REM:1,PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:65,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-2200-2470-01",SUBSYSTEM_DESCR:"Warm Condensate Heater, Offspec Condensate Pump and Distribution",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:292,A_OV_COM:49,A_OV_REM:243,A_OV_PROG:16.78,A_OV_STATUS:"less than 70%",A_PG:16.78,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:140,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-2400-2440-01",SUBSYSTEM_DESCR:"Condensate Metering",LOSH:"Y",MC_B30:"2022-06-16",A_OV_TOT:160,A_OV_COM:11,A_OV_REM:149,A_OV_PROG:6.87,A_OV_STATUS:"less than 70%",A_PG:6.87,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:7,PA_OV_COM:3,PA_OV_REM:4,PB1_OV_TOT:18,PB1_OV_COM:7,PB1_OV_REM:11,PB2_OV_TOT:16,PB2_OV_COM:1,PB2_OV_REM:15,ACT_TAG_VALID:105,PUNCH_NUMBER_VALID:41,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-2400-2450-01",SUBSYSTEM_DESCR:"CFW1 Offspec Condensate Tank and Pumps ,Incl Washing Machine",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:367,A_OV_COM:159,A_OV_REM:208,A_OV_PROG:43.32,A_OV_STATUS:"less than 70%",A_PG:43.32,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:3,PB1_OV_COM:2,PB1_OV_REM:1,PB2_OV_TOT:2,PB2_OV_COM:1,PB2_OV_REM:1,ACT_TAG_VALID:134,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-2400-2460-01",SUBSYSTEM_DESCR:"Condensate Offloading",LOSH:"Y",MC_B30:"2022-06-16",A_OV_TOT:203,A_OV_COM:18,A_OV_REM:185,A_OV_PROG:8.86,A_OV_STATUS:"less than 70%",A_PG:8.86,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:7,PB1_OV_COM:5,PB1_OV_REM:2,PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:115,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-2400-9360-01",SUBSYSTEM_DESCR:"Cargo Oil Washing Header and Distribution",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:29,A_OV_COM:8,A_OV_REM:21,A_OV_PROG:27.58,A_OV_STATUS:"less than 70%",A_PG:27.58,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-2400-9360-02",SUBSYSTEM_DESCR:"Condensate ImportandLoading Header",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:83,A_OV_COM:15,A_OV_REM:68,A_OV_PROG:18.07,A_OV_STATUS:"less than 70%",A_PG:18.07,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,ACT_TAG_VALID:46,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-2400-9360-03",SUBSYSTEM_DESCR:"Condensate Transfer Header",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:331,A_OV_COM:79,A_OV_REM:252,A_OV_PROG:23.86,A_OV_STATUS:"less than 70%",A_PG:23.86,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,ACT_TAG_VALID:200,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-2400-9360-04",SUBSYSTEM_DESCR:"Condensate ExportandOffloading Header",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:210,A_OV_COM:51,A_OV_REM:159,A_OV_PROG:24.28,A_OV_STATUS:"less than 70%",A_PG:24.28,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:96,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2400-9361-01",SUBSYSTEM_DESCR:"COW1 Tank and Pumps ,Incl Washing Machine",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:193,A_OV_COM:108,A_OV_REM:85,A_OV_PROG:55.95,A_OV_STATUS:"less than 70%",A_PG:55.95,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,PB3_OV_TOT:6,PB3_OV_COM:0,PB3_OV_REM:6,ACT_TAG_VALID:65,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-2400-9362-01",SUBSYSTEM_DESCR:"COW2 Tank and Pumps ,Incl Washing Machine",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:187,A_OV_COM:111,A_OV_REM:76,A_OV_PROG:59.35,A_OV_STATUS:"less than 70%",A_PG:59.35,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B3",PRIORITY:"P2",PB3_OV_TOT:1,PB3_OV_COM:0,PB3_OV_REM:1,ACT_TAG_VALID:64,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-2400-9363-01",SUBSYSTEM_DESCR:"COW3 Tank and Pumps ,Incl Washing Machine",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:192,A_OV_COM:115,A_OV_REM:77,A_OV_PROG:59.89,A_OV_STATUS:"less than 70%",A_PG:59.89,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:4,PA_OV_COM:0,PA_OV_REM:4,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,PB3_OV_TOT:7,PB3_OV_COM:0,PB3_OV_REM:7,ACT_TAG_VALID:65,PUNCH_NUMBER_VALID:14,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-2400-9364-01",SUBSYSTEM_DESCR:"COW4 Tank and Pumps ,Incl Washing Machine",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:187,A_OV_COM:117,A_OV_REM:70,A_OV_PROG:62.56,A_OV_STATUS:"less than 70%",A_PG:62.56,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:6,PA_OV_COM:0,PA_OV_REM:6,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,PB3_OV_TOT:8,PB3_OV_COM:0,PB3_OV_REM:8,ACT_TAG_VALID:65,PUNCH_NUMBER_VALID:16,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-2400-9365-01",SUBSYSTEM_DESCR:"COE1 Tank and Pumps ,Incl Washing Machine",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:179,A_OV_COM:95,A_OV_REM:84,A_OV_PROG:53.07,A_OV_STATUS:"less than 70%",A_PG:53.07,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:63,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2400-9366-01",SUBSYSTEM_DESCR:"COE2 Tank and Pumps ,Incl Washing Machine",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:173,A_OV_COM:106,A_OV_REM:67,A_OV_PROG:61.27,A_OV_STATUS:"less than 70%",A_PG:61.27,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,PB2_OV_TOT:10,PB2_OV_COM:0,PB2_OV_REM:10,ACT_TAG_VALID:62,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-2400-9367-01",SUBSYSTEM_DESCR:"COE3 Tank and Pumps ,Incl Washing Machine",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:172,A_OV_COM:107,A_OV_REM:65,A_OV_PROG:62.2,A_OV_STATUS:"less than 70%",A_PG:62.2,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,PB2_OV_TOT:7,PB2_OV_COM:0,PB2_OV_REM:7,ACT_TAG_VALID:61,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-2400-9368-01",SUBSYSTEM_DESCR:"COE4 Tank and Pumps ,Incl Washing Machine",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:173,A_OV_COM:103,A_OV_REM:70,A_OV_PROG:59.53,A_OV_STATUS:"less than 70%",A_PG:59.53,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:7,PA_OV_COM:0,PA_OV_REM:7,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,PB2_OV_TOT:5,PB2_OV_COM:0,PB2_OV_REM:5,ACT_TAG_VALID:62,PUNCH_NUMBER_VALID:15,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-2500-2510-01",SUBSYSTEM_DESCR:"Rich MEG Collection, Flash Drum and Coolers",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:161,A_OV_COM:52,A_OV_REM:109,A_OV_PROG:32.29,A_OV_STATUS:"less than 70%",A_PG:32.29,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:50,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-2500-2510-02",SUBSYSTEM_DESCR:"MEG Transfer Header",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:56,A_OV_COM:8,A_OV_REM:48,A_OV_PROG:14.28,A_OV_STATUS:"less than 70%",A_PG:14.28,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:36,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2500-2510-04",SUBSYSTEM_DESCR:"Salty Rich MEG Import Header",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:48,A_OV_COM:12,A_OV_REM:36,A_OV_PROG:25,A_OV_STATUS:"less than 70%",A_PG:25,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2500-2510-05",SUBSYSTEM_DESCR:"Salty Rich MEG Export Header",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:53,A_OV_COM:9,A_OV_REM:44,A_OV_PROG:16.98,A_OV_STATUS:"less than 70%",A_PG:16.98,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:28,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2500-2510-06",SUBSYSTEM_DESCR:"Rich MEG Header to MEG Regeneration",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:47,A_OV_COM:9,A_OV_REM:38,A_OV_PROG:19.14,A_OV_STATUS:"less than 70%",A_PG:19.14,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2500-2511-01",SUBSYSTEM_DESCR:"MRW1 Rich MEG Storage and Pumps",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:252,A_OV_COM:117,A_OV_REM:135,A_OV_PROG:46.42,A_OV_STATUS:"less than 70%",A_PG:46.42,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:5,PB1_OV_COM:5,PB1_OV_REM:0,ACT_TAG_VALID:89,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-2500-2512-01",SUBSYSTEM_DESCR:"MSE1 Salty Rich MEG Storage and Pumps",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:208,A_OV_COM:111,A_OV_REM:97,A_OV_PROG:53.36,A_OV_STATUS:"less than 70%",A_PG:53.36,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:4,PB1_OV_COM:3,PB1_OV_REM:1,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:67,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2500-2513-01",SUBSYSTEM_DESCR:"MSW2 Salty rich MEG storage and pumps",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:155,A_OV_COM:92,A_OV_REM:63,A_OV_PROG:59.35,A_OV_STATUS:"less than 70%",A_PG:59.35,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:9,PA_OV_COM:0,PA_OV_REM:9,PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,PB3_OV_TOT:3,PB3_OV_COM:0,PB3_OV_REM:3,ACT_TAG_VALID:48,PUNCH_NUMBER_VALID:14,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2500-2514-01",SUBSYSTEM_DESCR:"MSE2 Salty Rich MEG Storage and Pumps",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:169,A_OV_COM:100,A_OV_REM:69,A_OV_PROG:59.17,A_OV_STATUS:"less than 70%",A_PG:59.17,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,ACT_TAG_VALID:50,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2500-2530-01",SUBSYSTEM_DESCR:"MEG Regeneration",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:311,A_OV_COM:81,A_OV_REM:230,A_OV_PROG:26.04,A_OV_STATUS:"less than 70%",A_PG:26.04,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:12,PA_OV_COM:1,PA_OV_REM:11,PB1_OV_TOT:13,PB1_OV_COM:4,PB1_OV_REM:9,PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:195,PUNCH_NUMBER_VALID:27,PUNCH_TAG_VALID:14},
    {SUBSYSTEM:"21-2500-2530-02",SUBSYSTEM_DESCR:"Lean MEG from MEG Regeneration to Storage",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:25,A_OV_COM:4,A_OV_REM:21,A_OV_PROG:16,A_OV_STATUS:"less than 70%",A_PG:16,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2500-2551-01",SUBSYSTEM_DESCR:"MLW1 Lean MEG Storage and Pumps",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:266,A_OV_COM:133,A_OV_REM:133,A_OV_PROG:50,A_OV_STATUS:"less than 70%",A_PG:50,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:11,PA_OV_COM:0,PA_OV_REM:11,PB1_OV_TOT:8,PB1_OV_COM:4,PB1_OV_REM:4,PB2_OV_TOT:9,PB2_OV_COM:0,PB2_OV_REM:9,ACT_TAG_VALID:97,PUNCH_NUMBER_VALID:28,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-2500-2552-01",SUBSYSTEM_DESCR:"MLE1 Lean MEG Storage and Pumps",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:237,A_OV_COM:124,A_OV_REM:113,A_OV_PROG:52.32,A_OV_STATUS:"less than 70%",A_PG:52.32,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:9,PA_OV_COM:0,PA_OV_REM:9,PB2_OV_TOT:11,PB2_OV_COM:0,PB2_OV_REM:11,ACT_TAG_VALID:90,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-2500-2553-01",SUBSYSTEM_DESCR:"Lean MEG Filters and Break Tank",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:358,A_OV_COM:150,A_OV_REM:208,A_OV_PROG:41.89,A_OV_STATUS:"less than 70%",A_PG:41.89,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB2_OV_TOT:15,PB2_OV_COM:0,PB2_OV_REM:15,ACT_TAG_VALID:96,PUNCH_NUMBER_VALID:16,PUNCH_TAG_VALID:9},
    {SUBSYSTEM:"21-2500-2553-02",SUBSYSTEM_DESCR:"Topsides Lean MEG Pumps and Distribution",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:239,A_OV_COM:49,A_OV_REM:190,A_OV_PROG:20.5,A_OV_STATUS:"less than 70%",A_PG:20.5,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,ACT_TAG_VALID:110,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2500-2553-03",SUBSYSTEM_DESCR:"Subsea Lean MEG Pumps and Distribution",LOSH:"Y",MC_B30:"2022-06-23",A_OV_TOT:352,A_OV_COM:48,A_OV_REM:304,A_OV_PROG:13.63,A_OV_STATUS:"less than 70%",A_PG:13.63,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:21,PA_OV_COM:21,PA_OV_REM:0,PB1_OV_TOT:3,PB1_OV_COM:1,PB1_OV_REM:2,PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:235,PUNCH_NUMBER_VALID:26,PUNCH_TAG_VALID:7},
    {SUBSYSTEM:"21-2500-2562-01",SUBSYSTEM_DESCR:"MEG Transfer from Bunkering Station",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:24,A_OV_COM:8,A_OV_REM:16,A_OV_PROG:33.33,A_OV_STATUS:"less than 70%",A_PG:33.33,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P3",PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2600-2630-01",SUBSYSTEM_DESCR:"Methanol Storage and Pumps",LOSH:"Y",MC_B30:"2022-06-16",A_OV_TOT:125,A_OV_COM:57,A_OV_REM:68,A_OV_PROG:45.6,A_OV_STATUS:"less than 70%",A_PG:45.6,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PB1_OV_TOT:7,PB1_OV_COM:7,PB1_OV_REM:0,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:42,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2600-2640-01",SUBSYSTEM_DESCR:"Methanol Filters and Break Tank",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:236,A_OV_COM:96,A_OV_REM:140,A_OV_PROG:40.67,A_OV_STATUS:"less than 70%",A_PG:40.67,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:68,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-2600-2640-02",SUBSYSTEM_DESCR:"Methanol HP Pumps",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:163,A_OV_COM:30,A_OV_REM:133,A_OV_PROG:18.4,A_OV_STATUS:"less than 70%",A_PG:18.4,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:87,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2600-2640-03",SUBSYSTEM_DESCR:"Methanol MP Pumps",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:158,A_OV_COM:36,A_OV_REM:122,A_OV_PROG:22.78,A_OV_STATUS:"less than 70%",A_PG:22.78,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:73,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2600-2640-04",SUBSYSTEM_DESCR:"Methanol HP Header",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:31,A_OV_COM:5,A_OV_REM:26,A_OV_PROG:16.12,A_OV_STATUS:"less than 70%",A_PG:16.12,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2600-2640-05",SUBSYSTEM_DESCR:"Methanol MP Header",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:170,A_OV_COM:30,A_OV_REM:140,A_OV_PROG:17.64,A_OV_STATUS:"less than 70%",A_PG:17.64,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:81,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2600-2641-01",SUBSYSTEM_DESCR:"Methanol Subsea Pumps",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:159,A_OV_COM:36,A_OV_REM:123,A_OV_PROG:22.64,A_OV_STATUS:"less than 70%",A_PG:22.64,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:79,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2600-2641-02",SUBSYSTEM_DESCR:"Methanol Subsea Distribution",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:33,A_OV_COM:5,A_OV_REM:28,A_OV_PROG:15.15,A_OV_STATUS:"less than 70%",A_PG:15.15,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2600-8510-01",SUBSYSTEM_DESCR:"Methanol Loading Header",LOSH:"Y",MC_B30:"2022-07-07",A_OV_TOT:30,A_OV_COM:6,A_OV_REM:24,A_OV_PROG:20,A_OV_STATUS:"less than 70%",A_PG:20,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-3040-3040-01",SUBSYSTEM_DESCR:"HPandMP Gas Compressors, UCP, VSD and Electrical ControlandSwitchboards",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:254,A_OV_COM:48,A_OV_REM:206,A_OV_PROG:18.89,A_OV_STATUS:"less than 70%",A_PG:18.89,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:33,PA_OV_COM:17,PA_OV_REM:16,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,PB2_OV_TOT:17,PB2_OV_COM:0,PB2_OV_REM:17,ACT_TAG_VALID:187,PUNCH_NUMBER_VALID:53,PUNCH_TAG_VALID:9},
    {SUBSYSTEM:"21-3040-3040-02",SUBSYSTEM_DESCR:"HPandMP Gas Compressors Train Scrubbers, Aftercoolers and Process Lines",LOSH:"Y",MC_B30:"2022-06-16",A_OV_TOT:541,A_OV_COM:174,A_OV_REM:367,A_OV_PROG:32.16,A_OV_STATUS:"less than 70%",A_PG:32.16,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB2_OV_TOT:5,PB2_OV_COM:0,PB2_OV_REM:5,ACT_TAG_VALID:202,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-3040-3041-01",SUBSYSTEM_DESCR:"LPandLLP Gas Compressors , UCP, VSD and Electrical ControlandSwitchboards",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:156,A_OV_COM:16,A_OV_REM:140,A_OV_PROG:10.25,A_OV_STATUS:"less than 70%",A_PG:10.25,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:5,PA_OV_COM:0,PA_OV_REM:5,PB1_OV_TOT:13,PB1_OV_COM:2,PB1_OV_REM:11,PB2_OV_TOT:12,PB2_OV_COM:1,PB2_OV_REM:11,ACT_TAG_VALID:117,PUNCH_NUMBER_VALID:30,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-3040-3041-02",SUBSYSTEM_DESCR:"LPandLLP Gas Compressors Train Scrubbers, Aftercoolers and Process Lines",LOSH:"Y",MC_B30:"2022-06-23",A_OV_TOT:457,A_OV_COM:150,A_OV_REM:307,A_OV_PROG:32.82,A_OV_STATUS:"less than 70%",A_PG:32.82,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,PB2_OV_TOT:8,PB2_OV_COM:0,PB2_OV_REM:8,ACT_TAG_VALID:168,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-3330-3330-01",SUBSYSTEM_DESCR:"Process Gas, GasandGas Exchanger, TEX Scrubber and JT Valve",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:484,A_OV_COM:142,A_OV_REM:342,A_OV_PROG:29.33,A_OV_STATUS:"less than 70%",A_PG:29.33,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P3",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,PB2_OV_TOT:7,PB2_OV_COM:0,PB2_OV_REM:7,ACT_TAG_VALID:218,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-3330-3330-02",SUBSYSTEM_DESCR:"Gas Coalescer and LT Separator",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:597,A_OV_COM:149,A_OV_REM:448,A_OV_PROG:24.95,A_OV_STATUS:"less than 70%",A_PG:24.95,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,PB2_OV_TOT:9,PB2_OV_COM:0,PB2_OV_REM:9,ACT_TAG_VALID:285,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:9},
    {SUBSYSTEM:"21-3330-3330-03",SUBSYSTEM_DESCR:"Turbo Expander, Recompressor",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:383,A_OV_COM:48,A_OV_REM:335,A_OV_PROG:12.53,A_OV_STATUS:"less than 70%",A_PG:12.53,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,PB1_OV_TOT:6,PB1_OV_COM:1,PB1_OV_REM:5,PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:245,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-3330-3410-01",SUBSYSTEM_DESCR:"Gas Export Heaters",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:114,A_OV_COM:46,A_OV_REM:68,A_OV_PROG:40.35,A_OV_STATUS:"less than 70%",A_PG:40.35,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:36,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-3330-3410-02",SUBSYSTEM_DESCR:"Export Gas Metering and Export Risers",LOSH:"Y",MC_B30:"2022-06-23",A_OV_TOT:166,A_OV_COM:26,A_OV_REM:140,A_OV_PROG:15.66,A_OV_STATUS:"less than 70%",A_PG:15.66,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:4,PB1_OV_COM:1,PB1_OV_REM:3,ACT_TAG_VALID:71,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-3330-3410-03",SUBSYSTEM_DESCR:"Repressurisation Heater and Header",LOSH:"Y",MC_B30:"2022-07-14",A_OV_TOT:132,A_OV_COM:29,A_OV_REM:103,A_OV_PROG:21.96,A_OV_STATUS:"less than 70%",A_PG:21.96,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:59,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-4000-4030-01",SUBSYSTEM_DESCR:"Produced Water Offspec Storage Tank and Pumps",LOSH:"Y",MC_B30:"2022-06-16",A_OV_TOT:273,A_OV_COM:121,A_OV_REM:152,A_OV_PROG:44.32,A_OV_STATUS:"less than 70%",A_PG:44.32,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:7,PA_OV_COM:0,PA_OV_REM:7,PB1_OV_TOT:9,PB1_OV_COM:8,PB1_OV_REM:1,PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:102,PUNCH_NUMBER_VALID:19,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-4000-4030-02",SUBSYSTEM_DESCR:"Produced Water Offspec Header to Storage Tank",LOSH:"Y",MC_B30:"2022-06-23",A_OV_TOT:42,A_OV_COM:12,A_OV_REM:30,A_OV_PROG:28.57,A_OV_STATUS:"less than 70%",A_PG:28.57,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-4000-4030-03",SUBSYSTEM_DESCR:"Produced Water Offspec Header to Produced Water Treatment and Bunkering Station",LOSH:"Y",MC_B30:"2022-06-23",A_OV_TOT:36,A_OV_COM:11,A_OV_REM:25,A_OV_PROG:30.55,A_OV_STATUS:"less than 70%",A_PG:30.55,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-4000-4130-01",SUBSYSTEM_DESCR:"Deoiling Package ,Hydrocyclone + CFU",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:244,A_OV_COM:52,A_OV_REM:192,A_OV_PROG:21.31,A_OV_STATUS:"less than 70%",A_PG:21.31,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:10,PA_OV_COM:6,PA_OV_REM:4,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:100,PUNCH_NUMBER_VALID:15,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-4000-4130-02",SUBSYSTEM_DESCR:"Flocculent Injection Package",LOSH:"Y",MC_B30:"2022-06-16",A_OV_TOT:102,A_OV_COM:37,A_OV_REM:65,A_OV_PROG:36.27,A_OV_STATUS:"less than 70%",A_PG:36.27,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:5,PB1_OV_COM:2,PB1_OV_REM:3,PB2_OV_TOT:5,PB2_OV_COM:0,PB2_OV_REM:5,ACT_TAG_VALID:49,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:7},
    {SUBSYSTEM:"21-4000-4130-03",SUBSYSTEM_DESCR:"Demulsifier Injection Package",MC_B30:"2022-06-16",A_OV_TOT:24,A_OV_COM:5,A_OV_REM:19,A_OV_PROG:20.83,A_OV_STATUS:"less than 70%",A_PG:20.83,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-4000-4130-04",SUBSYSTEM_DESCR:"Produced Water Polishing Package",MC_B30:"2022-06-23",A_OV_TOT:35,A_OV_COM:0,A_OV_REM:35,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-4000-4150-01",SUBSYSTEM_DESCR:"Mercury Removal Package",LOSH:"Y",MC_B30:"2022-06-16",A_OV_TOT:66,A_OV_COM:26,A_OV_REM:40,A_OV_PROG:39.39,A_OV_STATUS:"less than 70%",A_PG:39.39,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:3,PA_OV_COM:2,PA_OV_REM:1,PB1_OV_TOT:2,PB1_OV_COM:1,PB1_OV_REM:1,ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-4000-4150-02",SUBSYSTEM_DESCR:"Treated Water Header and Discharge Caisson",LOSH:"Y",MC_B30:"2022-06-23",A_OV_TOT:30,A_OV_COM:7,A_OV_REM:23,A_OV_PROG:23.33,A_OV_STATUS:"less than 70%",A_PG:23.33,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5000-5010-01",SUBSYSTEM_DESCR:"HP Flare KO Drum and Pumps",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:164,A_OV_COM:20,A_OV_REM:144,A_OV_PROG:12.19,A_OV_STATUS:"less than 70%",A_PG:12.19,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:79,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5000-5020-01",SUBSYSTEM_DESCR:"HP Flare Ignition, Metering and Tip",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:153,A_OV_COM:45,A_OV_REM:108,A_OV_PROG:29.41,A_OV_STATUS:"less than 70%",A_PG:29.41,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:42,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5000-5030-01",SUBSYSTEM_DESCR:"Cold and Dry HP Flare Header",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:236,A_OV_COM:84,A_OV_REM:152,A_OV_PROG:35.59,A_OV_STATUS:"less than 70%",A_PG:35.59,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:57,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5000-5030-02",SUBSYSTEM_DESCR:"Wet HP Flare Header",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:43,A_OV_COM:8,A_OV_REM:35,A_OV_PROG:18.6,A_OV_STATUS:"less than 70%",A_PG:18.6,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5000-5030-03",SUBSYSTEM_DESCR:"HP liquid Flare Header",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:250,A_OV_COM:58,A_OV_REM:192,A_OV_PROG:23.2,A_OV_STATUS:"less than 70%",A_PG:23.2,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:76,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5200-4340-01",SUBSYSTEM_DESCR:"Closed Drain Drum and Pumps",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:156,A_OV_COM:24,A_OV_REM:132,A_OV_PROG:15.38,A_OV_STATUS:"less than 70%",A_PG:15.38,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,ACT_TAG_VALID:65,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-5200-4340-02",SUBSYSTEM_DESCR:"Closed Drain Header",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:634,A_OV_COM:296,A_OV_REM:338,A_OV_PROG:46.68,A_OV_STATUS:"less than 70%",A_PG:46.68,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB2_OV_TOT:23,PB2_OV_COM:0,PB2_OV_REM:23,ACT_TAG_VALID:107,PUNCH_NUMBER_VALID:24,PUNCH_TAG_VALID:18},
    {SUBSYSTEM:"21-5200-4340-03",SUBSYSTEM_DESCR:"HULL Deck Equipment Drainage Header",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:7,A_OV_COM:0,A_OV_REM:7,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5200-5210-01",SUBSYSTEM_DESCR:"LP Flare KO Drum and Pumps",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:136,A_OV_COM:20,A_OV_REM:116,A_OV_PROG:14.7,A_OV_STATUS:"less than 70%",A_PG:14.7,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,ACT_TAG_VALID:53,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-5200-5220-01",SUBSYSTEM_DESCR:"LP Flare Metering and Tip",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:103,A_OV_COM:35,A_OV_REM:68,A_OV_PROG:33.98,A_OV_STATUS:"less than 70%",A_PG:33.98,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:26,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5200-5230-01",SUBSYSTEM_DESCR:"LP Flare Header",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:175,A_OV_COM:24,A_OV_REM:151,A_OV_PROG:13.71,A_OV_STATUS:"less than 70%",A_PG:13.71,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P3",PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:47,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-5400-5410-01",SUBSYSTEM_DESCR:"Vent Headers, Boom and Liquid Seal Systems",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:261,A_OV_COM:60,A_OV_REM:201,A_OV_PROG:22.98,A_OV_STATUS:"less than 70%",A_PG:22.98,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,ACT_TAG_VALID:63,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-5400-5410-02",SUBSYSTEM_DESCR:"CO2 Snuffing System",MC_B30:"2022-07-21",A_OV_TOT:33,A_OV_COM:6,A_OV_REM:27,A_OV_PROG:18.18,A_OV_STATUS:"less than 70%",A_PG:18.18,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5700-5710-01",SUBSYSTEM_DESCR:"Flare Gas Vapour Recovery Skid",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:49,A_OV_COM:13,A_OV_REM:36,A_OV_PROG:26.53,A_OV_STATUS:"less than 70%",A_PG:26.53,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:7,PA_OV_COM:3,PA_OV_REM:4,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-5700-5730-01",SUBSYSTEM_DESCR:"HULL Tank Vapour Recovery Skid",LOSH:"Y",MC_B30:"2022-06-16",A_OV_TOT:71,A_OV_COM:8,A_OV_REM:63,A_OV_PROG:11.26,A_OV_STATUS:"less than 70%",A_PG:11.26,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PB1_OV_TOT:2,PB1_OV_COM:1,PB1_OV_REM:1,ACT_TAG_VALID:38,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6010-6011-01",SUBSYSTEM_DESCR:"SIS Marshalling - Machinery Space ,HER",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-17",A_OV_TOT:43,A_OV_COM:43,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-10-25",WD_ACTUAL:"2021-10-26",MC_APPROVED:"2022-02-11",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1A",PA_OV_TOT:43,PA_OV_COM:43,PA_OV_REM:0,PB1_OV_TOT:20,PB1_OV_COM:15,PB1_OV_REM:5,ACT_TAG_VALID:43,PUNCH_NUMBER_VALID:63,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-6010-6011-02",SUBSYSTEM_DESCR:"SIS Marshalling - LQ ,CER",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-17",A_OV_TOT:14,A_OV_COM:14,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-10-20",WD_ACTUAL:"2021-10-21",MC_APPROVED:"2022-02-11",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1A",PA_OV_TOT:26,PA_OV_COM:26,PA_OV_REM:0,PB1_OV_TOT:6,PB1_OV_COM:5,PB1_OV_REM:1,PB2_OV_TOT:5,PB2_OV_COM:0,PB2_OV_REM:5,ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:37,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6010-6011-03",SUBSYSTEM_DESCR:"SIS Marshalling - Topsides ,ME05 and LER",BATCH:"Incentive 2",MC_B30:"2022-02-24",A_OV_TOT:22,A_OV_COM:22,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-12",MC_APPROVED:"2022-02-21",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1B",PA_OV_TOT:12,PA_OV_COM:12,PA_OV_REM:0,PB1_OV_TOT:35,PB1_OV_COM:22,PB1_OV_REM:13,ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:47,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-6010-6011-04",SUBSYSTEM_DESCR:"CAP Panel in CCR",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:23,A_OV_COM:23,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-18",MC_APPROVED:"2022-03-22",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1",PB1_OV_TOT:11,PB1_OV_COM:11,PB1_OV_REM:0,PB2_OV_TOT:32,PB2_OV_COM:1,PB2_OV_REM:31,ACT_TAG_VALID:23,PUNCH_NUMBER_VALID:43,PUNCH_TAG_VALID:33},
    {SUBSYSTEM:"21-6010-6011-07",SUBSYSTEM_DESCR:"SIS Multicore and Junction Box to Marshalling and System Cabinets in HER",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-01-27",A_OV_TOT:112,A_OV_COM:34,A_OV_REM:78,A_OV_PROG:30.35,A_OV_STATUS:"less than 70%",A_PG:30.35,RFI_ISSUED:"2022-01-08",WD_ACTUAL:"2022-01-10",MC_APPROVED:"2022-01-22",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:10,PA_OV_COM:10,PA_OV_REM:0,PB1_OV_TOT:18,PB1_OV_COM:17,PB1_OV_REM:1,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:89,PUNCH_NUMBER_VALID:29,PUNCH_TAG_VALID:16},
    {SUBSYSTEM:"21-6010-6011-08",SUBSYSTEM_DESCR:"SIS Field Push Buttons",MC_B30:"2022-05-19",A_OV_TOT:4,A_OV_COM:0,A_OV_REM:4,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6010-6012-01",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables ME01",LOSH:"Y",MC_B30:"2022-04-21",A_OV_TOT:12,A_OV_COM:0,A_OV_REM:12,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6010-6012-02",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables MW01",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:18,A_OV_COM:0,A_OV_REM:18,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6010-6012-03",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables ME02",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:20,A_OV_COM:0,A_OV_REM:20,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6010-6012-04",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables MW02",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:24,A_OV_COM:0,A_OV_REM:24,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6010-6012-05",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables OFL00",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:6,A_OV_COM:0,A_OV_REM:6,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6010-6012-06",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables MW04",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:12,A_OV_COM:2,A_OV_REM:10,A_OV_PROG:16.66,A_OV_STATUS:"less than 70%",A_PG:16.66,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6010-6012-07",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables MW05",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:6,A_OV_COM:2,A_OV_REM:4,A_OV_PROG:33.33,A_OV_STATUS:"less than 70%",A_PG:33.33,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6010-6012-08",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables ME04",MC_B30:"2022-04-21",A_OV_TOT:6,A_OV_COM:0,A_OV_REM:6,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6010-6012-09",SUBSYSTEM_DESCR:"SIF's for 1st Gas Start-up",MC_B30:"2022-06-16",A_OV_STATUS:"NA",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"NA",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6010-6012-10",SUBSYSTEM_DESCR:"SIF's Post 1st Gas Start-up",MC_B30:"2022-06-16",A_OV_STATUS:"NA",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"NA",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6020-6021-01",SUBSYSTEM_DESCR:"PAS Marshalling - Machinery Space ,HER",BATCH:"Incentive 1",LOSH:"Y",MC_B30:"2022-02-17",A_OV_TOT:48,A_OV_COM:48,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-12-03",WD_ACTUAL:"2021-12-04",MC_APPROVED:"2022-02-11",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1A",PA_OV_TOT:20,PA_OV_COM:20,PA_OV_REM:0,PB1_OV_TOT:19,PB1_OV_COM:19,PB1_OV_REM:0,PB2_OV_TOT:7,PB2_OV_COM:0,PB2_OV_REM:7,ACT_TAG_VALID:48,PUNCH_NUMBER_VALID:46,PUNCH_TAG_VALID:9},
    {SUBSYSTEM:"21-6020-6021-02",SUBSYSTEM_DESCR:"PAS Marshalling - LQ ,CER",BATCH:"Incentive 1",LOSH:"Y",MC_B30:"2022-02-17",A_OV_TOT:15,A_OV_COM:15,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-10-21",WD_ACTUAL:"2021-10-22",MC_APPROVED:"2022-02-11",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1A",PA_OV_TOT:11,PA_OV_COM:11,PA_OV_REM:0,PB1_OV_TOT:11,PB1_OV_COM:10,PB1_OV_REM:1,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:23,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6020-6021-03",SUBSYSTEM_DESCR:"PAS Marshalling - Topsides ,ME05 and LER",BATCH:"Incentive 2",MC_B30:"2022-02-24",A_OV_TOT:14,A_OV_COM:14,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-12",MC_APPROVED:"2022-02-21",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1B",PA_OV_TOT:8,PA_OV_COM:8,PA_OV_REM:0,PB1_OV_TOT:35,PB1_OV_COM:24,PB1_OV_REM:11,ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:43,PUNCH_TAG_VALID:9},
    {SUBSYSTEM:"21-6020-6021-06",SUBSYSTEM_DESCR:"PAS Multicore and Junction Box to Marshalling and System Cabinets in HER",BATCH:"Incentive 2",MC_B30:"2022-02-03",A_OV_TOT:185,A_OV_COM:52,A_OV_REM:133,A_OV_PROG:28.1,A_OV_STATUS:"less than 70%",A_PG:28.1,RFI_ISSUED:"2022-01-08",WD_ACTUAL:"2022-01-10",MC_APPROVED:"2022-01-29",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:25,PA_OV_COM:25,PA_OV_REM:0,PB1_OV_TOT:19,PB1_OV_COM:17,PB1_OV_REM:2,PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:150,PUNCH_NUMBER_VALID:47,PUNCH_TAG_VALID:38},
    {SUBSYSTEM:"21-6020-6022-01",SUBSYSTEM_DESCR:"PAS Remote IOs,incl Junction Box and Multicore Cables ME01",LOSH:"Y",MC_B30:"2022-04-21",A_OV_TOT:20,A_OV_COM:0,A_OV_REM:20,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6020-6022-02",SUBSYSTEM_DESCR:"PAS Remote IOs ,incl Junction Box and Multicore Cables MW01",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:30,A_OV_COM:0,A_OV_REM:30,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6020-6022-03",SUBSYSTEM_DESCR:"PAS Remote IOs ,incl Junction Box and Multicore Cables ME02",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:24,A_OV_COM:0,A_OV_REM:24,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6020-6022-04",SUBSYSTEM_DESCR:"PAS Remote IOs ,incl Junction Box and Multicore Cables MW02",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:30,A_OV_COM:1,A_OV_REM:29,A_OV_PROG:3.33,A_OV_STATUS:"less than 70%",A_PG:3.33,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6020-6022-05",SUBSYSTEM_DESCR:"PAS Remote IOs ,incl Junction Box and Multicore Cables ME04",LOSH:"Y",MC_B30:"2022-04-21",A_OV_TOT:13,A_OV_COM:0,A_OV_REM:13,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6020-6022-06",SUBSYSTEM_DESCR:"PAS Remote IOs ,incl Junction Box and Multicore Cables MW04",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:6,A_OV_COM:0,A_OV_REM:6,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6020-6022-07",SUBSYSTEM_DESCR:"PAS Remote IOs ,incl Junction Box and Multicore Cables MW05",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:32,A_OV_COM:10,A_OV_REM:22,A_OV_PROG:31.25,A_OV_STATUS:"less than 70%",A_PG:31.25,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:27,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6020-6022-08",SUBSYSTEM_DESCR:"PAS Remote IOs ,incl Junction Box and Multicore Cables OFL00",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:12,A_OV_COM:0,A_OV_REM:12,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6030-6031-01",SUBSYSTEM_DESCR:"Electrical Data Monitoring and Constrol System ,EDMCS and Power Management System ,PMS",MC_B30:"2022-03-31",A_OV_TOT:210,A_OV_COM:108,A_OV_REM:102,A_OV_PROG:51.42,A_OV_STATUS:"less than 70%",A_PG:51.42,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1B",PA_OV_TOT:14,PA_OV_COM:13,PA_OV_REM:1,PB1_OV_TOT:14,PB1_OV_COM:10,PB1_OV_REM:4,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:210,PUNCH_NUMBER_VALID:29,PUNCH_TAG_VALID:15},
    {SUBSYSTEM:"21-6030-6032-01",SUBSYSTEM_DESCR:"ICSS Interface Servers, Workstations and Network Switches CER",BATCH:"Incentive 2",MC_B30:"2021-12-02",A_OV_TOT:299,A_OV_COM:293,A_OV_REM:6,A_OV_PROG:97.99,A_OV_STATUS:"greater than 95%",A_PG:97.99,RFI_ISSUED:"2021-11-20",WD_ACTUAL:"2021-11-22",MC_APPROVED:"2021-11-27",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1A",PA_OV_TOT:32,PA_OV_COM:32,PA_OV_REM:0,PB1_OV_TOT:162,PB1_OV_COM:83,PB1_OV_REM:79,PB2_OV_TOT:32,PB2_OV_COM:0,PB2_OV_REM:32,ACT_TAG_VALID:299,PUNCH_NUMBER_VALID:226,PUNCH_TAG_VALID:19},
    {SUBSYSTEM:"21-6030-6032-02",SUBSYSTEM_DESCR:"ICSS Interface Servers, Workstations and Network Switches LER",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:186,A_OV_COM:169,A_OV_REM:17,A_OV_PROG:90.86,A_OV_STATUS:"greater than 90%",A_PG:90.86,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1B",PA_OV_TOT:3,PA_OV_COM:0,PA_OV_REM:3,PB1_OV_TOT:34,PB1_OV_COM:18,PB1_OV_REM:16,PB2_OV_TOT:21,PB2_OV_COM:0,PB2_OV_REM:21,ACT_TAG_VALID:186,PUNCH_NUMBER_VALID:58,PUNCH_TAG_VALID:11},
    {SUBSYSTEM:"21-6030-6032-03",SUBSYSTEM_DESCR:"ICSS Interface Servers, Workstations and Network Switches HER",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2021-12-09",A_OV_TOT:148,A_OV_COM:141,A_OV_REM:7,A_OV_PROG:95.27,A_OV_STATUS:"greater than 95%",A_PG:95.27,RFI_ISSUED:"2021-12-01",WD_ACTUAL:"2021-12-02",MC_APPROVED:"2021-12-08",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1A",PA_OV_TOT:6,PA_OV_COM:6,PA_OV_REM:0,PB1_OV_TOT:63,PB1_OV_COM:24,PB1_OV_REM:39,PB2_OV_TOT:18,PB2_OV_COM:2,PB2_OV_REM:16,ACT_TAG_VALID:148,PUNCH_NUMBER_VALID:87,PUNCH_TAG_VALID:15},
    {SUBSYSTEM:"21-6030-6032-04",SUBSYSTEM_DESCR:"ICSS Workstations and Consoles - CCR",MC_B30:"2022-03-24",A_OV_TOT:58,A_OV_COM:54,A_OV_REM:4,A_OV_PROG:93.1,A_OV_STATUS:"greater than 90%",A_PG:93.1,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-11",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:4,PA_OV_COM:2,PA_OV_REM:2,PB1_OV_TOT:10,PB1_OV_COM:8,PB1_OV_REM:2,PB2_OV_TOT:3,PB2_OV_COM:1,PB2_OV_REM:2,ACT_TAG_VALID:58,PUNCH_NUMBER_VALID:17,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-6030-6032-05",SUBSYSTEM_DESCR:"ICSS Workstations and Consoles - Safe HavenandSOP",MC_B30:"2022-07-21",A_OV_TOT:9,A_OV_COM:4,A_OV_REM:5,A_OV_PROG:44.44,A_OV_STATUS:"less than 70%",A_PG:44.44,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6030-6034-01",SUBSYSTEM_DESCR:"Machinery Protection System ,MPS",MC_B30:"2022-04-07",A_OV_TOT:22,A_OV_COM:10,A_OV_REM:12,A_OV_PROG:45.45,A_OV_STATUS:"less than 70%",A_PG:45.45,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6030-6035-01",SUBSYSTEM_DESCR:"Condition and Performance Monitoring System ,CPMS",MC_B30:"2022-04-07",A_OV_STATUS:"NA",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6030-6036-01",SUBSYSTEM_DESCR:"PDBs Inside HER",BATCH:"Incentive 1",MC_B30:"2021-11-25",A_OV_TOT:17,A_OV_COM:17,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-09-27",WD_ACTUAL:"2022-04-15",MC_APPROVED:"2021-11-25",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1A",PA_OV_TOT:19,PA_OV_COM:19,PA_OV_REM:0,PB1_OV_TOT:11,PB1_OV_COM:10,PB1_OV_REM:1,PB2_OV_TOT:2,PB2_OV_COM:1,PB2_OV_REM:1,ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:32,PUNCH_TAG_VALID:8},
    {SUBSYSTEM:"21-6030-6036-02",SUBSYSTEM_DESCR:"PDBs Inside CER",BATCH:"Incentive 1",MC_B30:"2021-11-25",A_OV_TOT:29,A_OV_COM:26,A_OV_REM:3,A_OV_PROG:89.65,A_OV_STATUS:"greater than 80%",A_PG:89.65,RFI_ISSUED:"2021-10-19",WD_ACTUAL:"2021-10-20",MC_APPROVED:"2021-11-25",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1A",PA_OV_TOT:21,PA_OV_COM:21,PA_OV_REM:0,PB1_OV_TOT:18,PB1_OV_COM:18,PB1_OV_REM:0,PB2_OV_TOT:8,PB2_OV_COM:5,PB2_OV_REM:3,ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:47,PUNCH_TAG_VALID:14},
    {SUBSYSTEM:"21-6030-6036-04",SUBSYSTEM_DESCR:"PDBs Inside ME05",BATCH:"Incentive 2",MC_B30:"2022-02-24",A_OV_TOT:19,A_OV_COM:19,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-28",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"A",PRIORITY:"P1B",PA_OV_TOT:23,PA_OV_COM:20,PA_OV_REM:3,PB1_OV_TOT:126,PB1_OV_COM:40,PB1_OV_REM:86,ACT_TAG_VALID:19,PUNCH_NUMBER_VALID:149,PUNCH_TAG_VALID:9},
    {SUBSYSTEM:"21-6030-6036-05",SUBSYSTEM_DESCR:"PDBs Inside L5 Telecom Room",MC_B30:"2022-01-27",A_OV_TOT:10,A_OV_COM:10,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-12-28",WD_ACTUAL:"2021-12-29",MC_APPROVED:"2022-01-27",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:3,PA_OV_COM:3,PA_OV_REM:0,PB1_OV_TOT:23,PB1_OV_COM:13,PB1_OV_REM:10,PB2_OV_TOT:1,PB2_OV_COM:1,PB2_OV_REM:0,ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:27,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6030-6037-01",SUBSYSTEM_DESCR:"Tank Gauging System Cabinets and Workstations",LOSH:"Y",MC_B30:"2022-01-27",A_OV_TOT:25,A_OV_COM:25,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-18",MC_APPROVED:"2022-01-22",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:11,PA_OV_COM:11,PA_OV_REM:0,PB1_OV_TOT:27,PB1_OV_COM:23,PB1_OV_REM:4,PB2_OV_TOT:16,PB2_OV_COM:5,PB2_OV_REM:11,ACT_TAG_VALID:21,PUNCH_NUMBER_VALID:54,PUNCH_TAG_VALID:16},
    {SUBSYSTEM:"21-6030-6038-01",SUBSYSTEM_DESCR:"Cargo Loading Computer and Workstation",MC_B30:"2022-04-07",A_OV_TOT:10,A_OV_COM:1,A_OV_REM:9,A_OV_PROG:10,A_OV_STATUS:"less than 70%",A_PG:10,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P1",PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6030-6039-01",SUBSYSTEM_DESCR:"BallastandVoid Gas Sampling",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:81,A_OV_COM:35,A_OV_REM:46,A_OV_PROG:43.2,A_OV_STATUS:"less than 70%",A_PG:43.2,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:5,PB1_OV_COM:1,PB1_OV_REM:4,ACT_TAG_VALID:56,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6030-6040-01",SUBSYSTEM_DESCR:"IRP and Interface Instrumentation Cable - ME05 ,HV and LV Room",BATCH:"Incentive 2",MC_B30:"2022-03-31",A_OV_TOT:83,A_OV_COM:46,A_OV_REM:37,A_OV_PROG:55.42,A_OV_STATUS:"less than 70%",A_PG:55.42,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1B",PA_OV_TOT:4,PA_OV_COM:4,PA_OV_REM:0,PB1_OV_TOT:5,PB1_OV_COM:1,PB1_OV_REM:4,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:83,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:7},
    {SUBSYSTEM:"21-6030-6040-02",SUBSYSTEM_DESCR:"IRP and Interface Instrumentation Cable - LQ",BATCH:"Incentive 2",MC_B30:"2021-12-30",A_OV_TOT:71,A_OV_COM:71,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-11-02",WD_ACTUAL:"2021-11-03",MC_APPROVED:"2021-12-28",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1A",PA_OV_TOT:21,PA_OV_COM:21,PA_OV_REM:0,PB1_OV_TOT:32,PB1_OV_COM:30,PB1_OV_REM:2,PB2_OV_TOT:2,PB2_OV_COM:2,PB2_OV_REM:0,PB3_OV_TOT:1,PB3_OV_COM:1,PB3_OV_REM:0,ACT_TAG_VALID:71,PUNCH_NUMBER_VALID:56,PUNCH_TAG_VALID:18},
    {SUBSYSTEM:"21-6030-6040-03",SUBSYSTEM_DESCR:"IRP and Interface Instrumentation Cable - MS03",BATCH:"Incentive 2",MC_B30:"2022-01-27",A_OV_TOT:181,A_OV_COM:181,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-12-23",WD_ACTUAL:"2021-12-24",MC_APPROVED:"2022-01-22",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"N",PRIORITY:"P1A",PA_OV_TOT:16,PA_OV_COM:16,PA_OV_REM:0,PB1_OV_TOT:45,PB1_OV_COM:45,PB1_OV_REM:0,PB2_OV_TOT:6,PB2_OV_COM:6,PB2_OV_REM:0,ACT_TAG_VALID:181,PUNCH_NUMBER_VALID:67,PUNCH_TAG_VALID:21},
    {SUBSYSTEM:"21-6030-6041-01",SUBSYSTEM_DESCR:"PEMS System",MC_B30:"2022-04-07",A_OV_TOT:3,A_OV_COM:0,A_OV_REM:3,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:5,PB1_OV_COM:1,PB1_OV_REM:4,PB2_OV_TOT:5,PB2_OV_COM:0,PB2_OV_REM:5,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6030-6042-01",SUBSYSTEM_DESCR:"Corrosion and Erosion Monitoring",MC_B30:"2022-04-07",A_OV_TOT:81,A_OV_COM:0,A_OV_REM:81,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:2,PB1_OV_COM:1,PB1_OV_REM:1,PB2_OV_TOT:4,PB2_OV_COM:1,PB2_OV_REM:3,ACT_TAG_VALID:38,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6030-6043-01",SUBSYSTEM_DESCR:"FOPPs and Associated Fiber Cables to LER",MC_B30:"2022-03-31",A_OV_TOT:207,A_OV_COM:151,A_OV_REM:56,A_OV_PROG:72.94,A_OV_STATUS:"NA",A_PG:72.94,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1B",ACT_TAG_VALID:207,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6030-6043-02",SUBSYSTEM_DESCR:"FOPPs and Associated Fiber Cables to CER",BATCH:"Incentive 2",MC_B30:"2022-03-10",A_OV_TOT:33,A_OV_COM:30,A_OV_REM:3,A_OV_PROG:90.9,A_OV_STATUS:"greater than 90%",A_PG:90.9,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-24",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:6,PA_OV_COM:6,PA_OV_REM:0,PB1_OV_TOT:13,PB1_OV_COM:11,PB1_OV_REM:2,PB2_OV_TOT:2,PB2_OV_COM:2,PB2_OV_REM:0,ACT_TAG_VALID:33,PUNCH_NUMBER_VALID:21,PUNCH_TAG_VALID:8},
    {SUBSYSTEM:"21-6030-6043-03",SUBSYSTEM_DESCR:"FOPPs and Associated Fiber Cables to HER",BATCH:"Incentive 2",MC_B30:"2022-03-03",A_OV_TOT:21,A_OV_COM:21,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-19",MC_APPROVED:"2022-02-26",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"N",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PB1_OV_TOT:18,PB1_OV_COM:18,PB1_OV_REM:0,ACT_TAG_VALID:21,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:8},
    {SUBSYSTEM:"21-6035-6035-01",SUBSYSTEM_DESCR:"Process CCTV Cameras, Consoles and Screens",BATCH:"Incentive 2",MC_B30:"2022-05-05",A_OV_TOT:234,A_OV_COM:15,A_OV_REM:219,A_OV_PROG:6.41,A_OV_STATUS:"less than 70%",A_PG:6.41,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",PB1_OV_TOT:5,PB1_OV_COM:5,PB1_OV_REM:0,ACT_TAG_VALID:159,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-6070-6071-01",SUBSYSTEM_DESCR:"FGS Marshalling - Machinery Space ,HER",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-17",A_OV_TOT:14,A_OV_COM:14,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-10-25",WD_ACTUAL:"2021-10-26",MC_APPROVED:"2022-02-11",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1A",PA_OV_TOT:12,PA_OV_COM:12,PA_OV_REM:0,PB1_OV_TOT:11,PB1_OV_COM:10,PB1_OV_REM:1,ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:23,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6070-6071-02",SUBSYSTEM_DESCR:"FGS Marshalling - LQ ,CER",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-17",A_OV_TOT:30,A_OV_COM:30,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-10-20",WD_ACTUAL:"2021-10-21",MC_APPROVED:"2022-02-11",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1A",PA_OV_TOT:26,PA_OV_COM:26,PA_OV_REM:0,PB1_OV_TOT:14,PB1_OV_COM:14,PB1_OV_REM:0,PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:30,PUNCH_NUMBER_VALID:43,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6070-6071-03",SUBSYSTEM_DESCR:"FGS Marshalling - Topsides ,ME05 and LER",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-24",A_OV_TOT:8,A_OV_COM:8,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-12",MC_APPROVED:"2022-02-21",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1B",PA_OV_TOT:6,PA_OV_COM:6,PA_OV_REM:0,PB1_OV_TOT:15,PB1_OV_COM:10,PB1_OV_REM:5,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:21,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6070-6071-04",SUBSYSTEM_DESCR:"FGS Multicore and Junction Box to Marshalling and System Cabinets in LER",MC_B30:"2022-04-07",A_OV_TOT:9,A_OV_COM:3,A_OV_REM:6,A_OV_PROG:33.33,A_OV_STATUS:"less than 70%",A_PG:33.33,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6070-6071-05",SUBSYSTEM_DESCR:"FGS Multicore and Junction Box to Marshalling and System Cabinets in CER",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-17",A_OV_TOT:46,A_OV_COM:46,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-20",MC_APPROVED:"2022-02-17",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"N",PRIORITY:"P1",PA_OV_TOT:15,PA_OV_COM:15,PA_OV_REM:0,PB1_OV_TOT:22,PB1_OV_COM:22,PB1_OV_REM:0,ACT_TAG_VALID:46,PUNCH_NUMBER_VALID:37,PUNCH_TAG_VALID:24},
    {SUBSYSTEM:"21-6070-6071-06",SUBSYSTEM_DESCR:"FGS Multicore and Junction Box to Marshalling and System Cabinets in HER",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-24",A_OV_TOT:9,A_OV_COM:6,A_OV_REM:3,A_OV_PROG:66.66,A_OV_STATUS:"less than 70%",A_PG:66.66,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-11",MC_APPROVED:"2022-02-24",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1",PA_OV_TOT:7,PA_OV_COM:7,PA_OV_REM:0,PB1_OV_TOT:5,PB1_OV_COM:5,PB1_OV_REM:0,PB2_OV_TOT:4,PB2_OV_COM:3,PB2_OV_REM:1,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:16,PUNCH_TAG_VALID:7},
    {SUBSYSTEM:"21-6070-6072-01",SUBSYSTEM_DESCR:"Fire Addressable Panels - LQ ,CER and LQ Fire and Gas Addressable Loop-1",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-03",A_OV_TOT:395,A_OV_COM:395,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",MC_APPROVED:"2022-01-28",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:10,PA_OV_COM:10,PA_OV_REM:0,PB1_OV_TOT:46,PB1_OV_COM:45,PB1_OV_REM:1,PB2_OV_TOT:20,PB2_OV_COM:6,PB2_OV_REM:14,PB3_OV_TOT:1,PB3_OV_COM:0,PB3_OV_REM:1,ACT_TAG_VALID:273,PUNCH_NUMBER_VALID:77,PUNCH_TAG_VALID:36},
    {SUBSYSTEM:"21-6070-6072-02",SUBSYSTEM_DESCR:"LQ Fire and Gas Addressable Loop-2",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:369,A_OV_COM:369,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-24",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:19,PA_OV_COM:19,PA_OV_REM:0,PB1_OV_TOT:46,PB1_OV_COM:44,PB1_OV_REM:2,PB2_OV_TOT:29,PB2_OV_COM:6,PB2_OV_REM:23,ACT_TAG_VALID:253,PUNCH_NUMBER_VALID:94,PUNCH_TAG_VALID:51},
    {SUBSYSTEM:"21-6070-6072-03",SUBSYSTEM_DESCR:"LQ Fire and Gas Addressable Loop-3",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:281,A_OV_COM:266,A_OV_REM:15,A_OV_PROG:94.66,A_OV_STATUS:"greater than 90%",A_PG:94.66,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PB1_OV_TOT:14,PB1_OV_COM:14,PB1_OV_REM:0,PB2_OV_TOT:8,PB2_OV_COM:1,PB2_OV_REM:7,ACT_TAG_VALID:203,PUNCH_NUMBER_VALID:24,PUNCH_TAG_VALID:12},
    {SUBSYSTEM:"21-6070-6072-04",SUBSYSTEM_DESCR:"LQ Fire and Gas Addressable Loop-4",BATCH:"Incentive 2",MC_B30:"2022-03-24",A_OV_TOT:271,A_OV_COM:271,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-29",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:33,PA_OV_COM:33,PA_OV_REM:0,PB1_OV_TOT:48,PB1_OV_COM:46,PB1_OV_REM:2,PB2_OV_TOT:17,PB2_OV_COM:5,PB2_OV_REM:12,ACT_TAG_VALID:186,PUNCH_NUMBER_VALID:98,PUNCH_TAG_VALID:60},
    {SUBSYSTEM:"21-6070-6072-05",SUBSYSTEM_DESCR:"LQ Fire and Gas Addressable Loop-5",BATCH:"Incentive 2",MC_B30:"2022-04-14",A_OV_TOT:325,A_OV_COM:325,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-24",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:11,PA_OV_COM:11,PA_OV_REM:0,PB1_OV_TOT:28,PB1_OV_COM:27,PB1_OV_REM:1,PB2_OV_TOT:23,PB2_OV_COM:7,PB2_OV_REM:16,ACT_TAG_VALID:210,PUNCH_NUMBER_VALID:62,PUNCH_TAG_VALID:36},
    {SUBSYSTEM:"21-6070-6072-06",SUBSYSTEM_DESCR:"LQ Fire and Gas Addressable Loop-6",BATCH:"Incentive 2",MC_B30:"2022-04-14",A_OV_TOT:319,A_OV_COM:319,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-18",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"B2",PRIORITY:"P1",PA_OV_TOT:22,PA_OV_COM:22,PA_OV_REM:0,PB1_OV_TOT:34,PB1_OV_COM:34,PB1_OV_REM:0,PB2_OV_TOT:36,PB2_OV_COM:25,PB2_OV_REM:11,ACT_TAG_VALID:218,PUNCH_NUMBER_VALID:92,PUNCH_TAG_VALID:44},
    {SUBSYSTEM:"21-6070-6072-08",SUBSYSTEM_DESCR:"Fire Addressable Panels - Machinery Space ,HER and Machinery Space Fire and Gas Addressable Loop-1",BATCH:"Incentive 2",MC_B30:"2022-01-27",A_OV_TOT:279,A_OV_COM:251,A_OV_REM:28,A_OV_PROG:89.96,A_OV_STATUS:"greater than 80%",A_PG:89.96,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-12",MC_APPROVED:"2022-01-22",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:4,PA_OV_COM:4,PA_OV_REM:0,PB1_OV_TOT:36,PB1_OV_COM:34,PB1_OV_REM:2,PB2_OV_TOT:12,PB2_OV_COM:2,PB2_OV_REM:10,ACT_TAG_VALID:196,PUNCH_NUMBER_VALID:52,PUNCH_TAG_VALID:24},
    {SUBSYSTEM:"21-6070-6072-09",SUBSYSTEM_DESCR:"Machinery Space Fire and Gas  Addressable Loop-2",BATCH:"Incentive 2",MC_B30:"2022-01-06",A_OV_TOT:261,A_OV_COM:261,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-12-22",WD_ACTUAL:"2021-12-23",MC_APPROVED:"2021-12-31",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PB1_OV_TOT:35,PB1_OV_COM:34,PB1_OV_REM:1,PB2_OV_TOT:3,PB2_OV_COM:2,PB2_OV_REM:1,ACT_TAG_VALID:181,PUNCH_NUMBER_VALID:40,PUNCH_TAG_VALID:17},
    {SUBSYSTEM:"21-6070-6072-10",SUBSYSTEM_DESCR:"Machinery Space Fire and Gas  Addressable Loop-3",BATCH:"Incentive 2",MC_B30:"2021-12-23",A_OV_TOT:66,A_OV_COM:66,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-12-14",WD_ACTUAL:"2021-12-15",MC_APPROVED:"2021-12-23",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PB1_OV_TOT:7,PB1_OV_COM:7,PB1_OV_REM:0,PB2_OV_TOT:5,PB2_OV_COM:0,PB2_OV_REM:5,ACT_TAG_VALID:44,PUNCH_NUMBER_VALID:14,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-6070-6072-11",SUBSYSTEM_DESCR:"Fire Addressable Panels  and - Addressable Fire and Gas Detectors - Topsides ME05",BATCH:"Incentive 2",MC_B30:"2022-04-07",A_OV_TOT:323,A_OV_COM:249,A_OV_REM:74,A_OV_PROG:77.08,A_OV_STATUS:"NA",A_PG:77.08,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:5,PA_OV_COM:1,PA_OV_REM:4,PB1_OV_TOT:12,PB1_OV_COM:2,PB1_OV_REM:10,PB2_OV_TOT:6,PB2_OV_COM:0,PB2_OV_REM:6,ACT_TAG_VALID:209,PUNCH_NUMBER_VALID:23,PUNCH_TAG_VALID:12},
    {SUBSYSTEM:"21-6070-6073-01",SUBSYSTEM_DESCR:"HSSD - Machinery Space ,HER",LOSH:"Y",MC_B30:"2022-03-10",A_OV_TOT:10,A_OV_COM:10,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-24",MC_APPROVED:"2022-03-18",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:8,PA_OV_COM:8,PA_OV_REM:0,PB1_OV_TOT:12,PB1_OV_COM:11,PB1_OV_REM:1,PB2_OV_TOT:5,PB2_OV_COM:0,PB2_OV_REM:5,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:25,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6070-6073-02",SUBSYSTEM_DESCR:"HSSD - Machinery Space ,L3 Electrical room",MC_B30:"2022-03-10",A_OV_TOT:20,A_OV_COM:20,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-24",MC_APPROVED:"2022-03-18",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1",PA_OV_TOT:16,PA_OV_COM:16,PA_OV_REM:0,PB1_OV_TOT:13,PB1_OV_COM:13,PB1_OV_REM:0,PB2_OV_TOT:8,PB2_OV_COM:0,PB2_OV_REM:8,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:37,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-6070-6073-03",SUBSYSTEM_DESCR:"HSSD - LQ ,HULL Level Electrical rooms",MC_B30:"2022-03-10",A_OV_TOT:10,A_OV_COM:10,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-27",MC_APPROVED:"2022-03-18",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:12,PA_OV_COM:12,PA_OV_REM:0,PB1_OV_TOT:22,PB1_OV_COM:21,PB1_OV_REM:1,PB2_OV_TOT:6,PB2_OV_COM:0,PB2_OV_REM:6,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:40,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6070-6073-04",SUBSYSTEM_DESCR:"HSSD - LQ ,CCR",LOSH:"Y",MC_B30:"2022-03-10",A_OV_TOT:5,A_OV_COM:5,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-27",MC_APPROVED:"2022-03-18",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1",PA_OV_TOT:5,PA_OV_COM:5,PA_OV_REM:0,PB1_OV_TOT:15,PB1_OV_COM:15,PB1_OV_REM:0,PB2_OV_TOT:4,PB2_OV_COM:0,PB2_OV_REM:4,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:24,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6070-6073-05",SUBSYSTEM_DESCR:"HSSD - LQ ,CER",LOSH:"Y",MC_B30:"2022-03-10",A_OV_TOT:15,A_OV_COM:15,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-27",MC_APPROVED:"2022-03-18",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1",PA_OV_TOT:27,PA_OV_COM:27,PA_OV_REM:0,PB1_OV_TOT:23,PB1_OV_COM:23,PB1_OV_REM:0,PB2_OV_TOT:9,PB2_OV_COM:1,PB2_OV_REM:8,ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:59,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-6070-6073-06",SUBSYSTEM_DESCR:"HSSD - LQ ,L5 telecom and UPS rooms",MC_B30:"2022-03-10",A_OV_TOT:10,A_OV_COM:10,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-27",MC_APPROVED:"2022-03-18",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1",PA_OV_TOT:14,PA_OV_COM:14,PA_OV_REM:0,PB1_OV_TOT:19,PB1_OV_COM:19,PB1_OV_REM:0,PB2_OV_TOT:6,PB2_OV_COM:0,PB2_OV_REM:6,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:39,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-6070-6073-07",SUBSYSTEM_DESCR:"HSSD - Topsides ,ME05 and LER",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:45,A_OV_COM:25,A_OV_REM:20,A_OV_PROG:55.55,A_OV_STATUS:"less than 70%",A_PG:55.55,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:27,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6070-6074-01",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables ME01",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:24,A_OV_COM:0,A_OV_REM:24,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",PB1_OV_TOT:3,PB1_OV_COM:3,PB1_OV_REM:0,ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6070-6074-02",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables MW01",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:24,A_OV_COM:0,A_OV_REM:24,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6070-6074-03",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables ME02",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:18,A_OV_COM:0,A_OV_REM:18,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6070-6074-04",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables MW02",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:24,A_OV_COM:0,A_OV_REM:24,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6070-6074-05",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables ME04",LOSH:"Y",MC_B30:"2022-04-21",A_OV_TOT:12,A_OV_COM:0,A_OV_REM:12,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6070-6074-06",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables MW04",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:18,A_OV_COM:2,A_OV_REM:16,A_OV_PROG:11.11,A_OV_STATUS:"less than 70%",A_PG:11.11,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6070-6074-07",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables MW05",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:18,A_OV_COM:4,A_OV_REM:14,A_OV_PROG:22.22,A_OV_STATUS:"less than 70%",A_PG:22.22,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6100-6110-01",SUBSYSTEM_DESCR:"Topsides Fire Water Ring Main, Hydrant and Jockey Pumps",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:362,A_OV_COM:135,A_OV_REM:227,A_OV_PROG:37.29,A_OV_STATUS:"less than 70%",A_PG:37.29,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:8,PA_OV_COM:7,PA_OV_REM:1,PB1_OV_TOT:15,PB1_OV_COM:14,PB1_OV_REM:1,PB2_OV_TOT:7,PB2_OV_COM:2,PB2_OV_REM:5,ACT_TAG_VALID:80,PUNCH_NUMBER_VALID:30,PUNCH_TAG_VALID:11},
    {SUBSYSTEM:"21-6100-6110-02",SUBSYSTEM_DESCR:"Accommodation Firewater and Hydrants",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:97,A_OV_COM:51,A_OV_REM:46,A_OV_PROG:52.57,A_OV_STATUS:"less than 70%",A_PG:52.57,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6100-6110-03",SUBSYSTEM_DESCR:"HULL Fire Water Ring Main and Hydrants",LOSH:"Y",MC_B30:"2022-06-16",A_OV_TOT:37,A_OV_COM:21,A_OV_REM:16,A_OV_PROG:56.75,A_OV_STATUS:"less than 70%",A_PG:56.75,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6100-6111-01",SUBSYSTEM_DESCR:"Fire Water Pump A ,21-SPF-611101",MC_B30:"2022-05-26",A_OV_TOT:183,A_OV_COM:39,A_OV_REM:144,A_OV_PROG:21.31,A_OV_STATUS:"less than 70%",A_PG:21.31,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PB1_OV_TOT:8,PB1_OV_COM:4,PB1_OV_REM:4,PB2_OV_TOT:4,PB2_OV_COM:0,PB2_OV_REM:4,ACT_TAG_VALID:77,PUNCH_NUMBER_VALID:14,PUNCH_TAG_VALID:7},
    {SUBSYSTEM:"21-6100-6111-02",SUBSYSTEM_DESCR:"Fire Water Pump B ,21-SPF-611201",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:196,A_OV_COM:35,A_OV_REM:161,A_OV_PROG:17.85,A_OV_STATUS:"less than 70%",A_PG:17.85,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:3,PB1_OV_COM:2,PB1_OV_REM:1,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:85,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-6100-6111-03",SUBSYSTEM_DESCR:"Fire Water Pump C ,21-SPF-611301",MC_B30:"2022-07-07",A_OV_TOT:179,A_OV_COM:32,A_OV_REM:147,A_OV_PROG:17.87,A_OV_STATUS:"less than 70%",A_PG:17.87,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:4,PA_OV_COM:2,PA_OV_REM:2,PB1_OV_TOT:2,PB1_OV_COM:1,PB1_OV_REM:1,ACT_TAG_VALID:74,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6100-6121-01",SUBSYSTEM_DESCR:"HULL Water Mist System",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:114,A_OV_COM:114,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-11",MC_APPROVED:"2022-03-07",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1",PA_OV_TOT:36,PA_OV_COM:36,PA_OV_REM:0,PB1_OV_TOT:17,PB1_OV_COM:17,PB1_OV_REM:0,PB2_OV_TOT:49,PB2_OV_COM:10,PB2_OV_REM:39,ACT_TAG_VALID:68,PUNCH_NUMBER_VALID:102,PUNCH_TAG_VALID:48},
    {SUBSYSTEM:"21-6100-6140-01",SUBSYSTEM_DESCR:"Foam Package",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:65,A_OV_COM:48,A_OV_REM:17,A_OV_PROG:73.84,A_OV_STATUS:"NA",A_PG:73.84,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PA_OV_TOT:15,PA_OV_COM:15,PA_OV_REM:0,PB1_OV_TOT:11,PB1_OV_COM:11,PB1_OV_REM:0,PB2_OV_TOT:5,PB2_OV_COM:0,PB2_OV_REM:5,ACT_TAG_VALID:23,PUNCH_NUMBER_VALID:31,PUNCH_TAG_VALID:7},
    {SUBSYSTEM:"21-6100-6140-02",SUBSYSTEM_DESCR:"Foam Ring Main",LOSH:"Y",MC_B30:"2022-07-07",A_OV_TOT:28,A_OV_COM:2,A_OV_REM:26,A_OV_PROG:7.14,A_OV_STATUS:"less than 70%",A_PG:7.14,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6100-6140-03",SUBSYSTEM_DESCR:"Helideck Foam Package",MC_B30:"2022-07-14",A_OV_TOT:35,A_OV_COM:6,A_OV_REM:29,A_OV_PROG:17.14,A_OV_STATUS:"less than 70%",A_PG:17.14,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6100-6150-01",SUBSYSTEM_DESCR:"Topside Modules Deluged Systems",LOSH:"Y",MC_B30:"2022-07-07",A_OV_TOT:562,A_OV_COM:81,A_OV_REM:481,A_OV_PROG:14.41,A_OV_STATUS:"less than 70%",A_PG:14.41,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:12,PA_OV_COM:8,PA_OV_REM:4,PB1_OV_TOT:15,PB1_OV_COM:0,PB1_OV_REM:15,ACT_TAG_VALID:185,PUNCH_NUMBER_VALID:27,PUNCH_TAG_VALID:22},
    {SUBSYSTEM:"21-6100-6150-03",SUBSYSTEM_DESCR:"HULL Deck Deluged Systems",LOSH:"Y",MC_B30:"2022-07-07",A_OV_TOT:551,A_OV_COM:123,A_OV_REM:428,A_OV_PROG:22.32,A_OV_STATUS:"less than 70%",A_PG:22.32,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:8,PA_OV_COM:4,PA_OV_REM:4,PB1_OV_TOT:23,PB1_OV_COM:0,PB1_OV_REM:23,ACT_TAG_VALID:151,PUNCH_NUMBER_VALID:31,PUNCH_TAG_VALID:16},
    {SUBSYSTEM:"21-6170-6170-01",SUBSYSTEM_DESCR:"FF Portable Equipment ,extinguishers, etc … - HULL",MC_B30:"2022-06-16",A_OV_TOT:4,A_OV_COM:0,A_OV_REM:4,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6170-6170-02",SUBSYSTEM_DESCR:"FF Portable Equipment ,extinguishers, etc … - LQ",MC_B30:"2022-06-16",A_OV_TOT:8,A_OV_COM:0,A_OV_REM:8,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6170-6170-03",SUBSYSTEM_DESCR:"FF Portable Equipment ,extinguishers, etc … - Topsides",MC_B30:"2022-07-21",A_OV_TOT:18,A_OV_COM:0,A_OV_REM:18,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6200-6210-01",SUBSYSTEM_DESCR:"PAGA System Cabinets and Central Equipment",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:51,A_OV_COM:51,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-17",MC_APPROVED:"2022-03-31",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:35,PA_OV_COM:35,PA_OV_REM:0,PB1_OV_TOT:20,PB1_OV_COM:11,PB1_OV_REM:9,PB2_OV_TOT:27,PB2_OV_COM:12,PB2_OV_REM:15,ACT_TAG_VALID:48,PUNCH_NUMBER_VALID:82,PUNCH_TAG_VALID:30},
    {SUBSYSTEM:"21-6200-6210-02",SUBSYSTEM_DESCR:"PAGA Field Equipment ME05andMW05",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:731,A_OV_COM:629,A_OV_REM:102,A_OV_PROG:86.04,A_OV_STATUS:"greater than 80%",A_PG:86.04,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:3,PA_OV_COM:1,PA_OV_REM:2,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,PB2_OV_TOT:8,PB2_OV_COM:0,PB2_OV_REM:8,ACT_TAG_VALID:498,PUNCH_NUMBER_VALID:14,PUNCH_TAG_VALID:13},
    {SUBSYSTEM:"21-6200-6210-03",SUBSYSTEM_DESCR:"PAGA Field Equipment ME02andMW02",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:764,A_OV_COM:578,A_OV_REM:186,A_OV_PROG:75.65,A_OV_STATUS:"NA",A_PG:75.65,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,PB2_OV_TOT:14,PB2_OV_COM:0,PB2_OV_REM:14,ACT_TAG_VALID:520,PUNCH_NUMBER_VALID:19,PUNCH_TAG_VALID:14},
    {SUBSYSTEM:"21-6200-6210-04",SUBSYSTEM_DESCR:"PAGA Field Equipment ME04andMW04",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:366,A_OV_COM:332,A_OV_REM:34,A_OV_PROG:90.71,A_OV_STATUS:"greater than 90%",A_PG:90.71,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:22,PA_OV_COM:0,PA_OV_REM:22,PB1_OV_TOT:8,PB1_OV_COM:0,PB1_OV_REM:8,PB2_OV_TOT:11,PB2_OV_COM:0,PB2_OV_REM:11,ACT_TAG_VALID:252,PUNCH_NUMBER_VALID:41,PUNCH_TAG_VALID:38},
    {SUBSYSTEM:"21-6200-6210-05",SUBSYSTEM_DESCR:"PAGA Field Equipment ME01andMW01",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:972,A_OV_COM:631,A_OV_REM:341,A_OV_PROG:64.91,A_OV_STATUS:"less than 70%",A_PG:64.91,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:4,PB1_OV_COM:0,PB1_OV_REM:4,PB2_OV_TOT:13,PB2_OV_COM:0,PB2_OV_REM:13,ACT_TAG_VALID:660,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:11},
    {SUBSYSTEM:"21-6200-6210-06",SUBSYSTEM_DESCR:"PAGA Field Equipment Topsides HULL Deck",LOSH:"Y",MC_B30:"2022-06-23",A_OV_TOT:790,A_OV_COM:33,A_OV_REM:757,A_OV_PROG:4.17,A_OV_STATUS:"less than 70%",A_PG:4.17,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:6,PB1_OV_COM:4,PB1_OV_REM:2,PB2_OV_TOT:10,PB2_OV_COM:1,PB2_OV_REM:9,ACT_TAG_VALID:534,PUNCH_NUMBER_VALID:17,PUNCH_TAG_VALID:10},
    {SUBSYSTEM:"21-6200-6210-07",SUBSYSTEM_DESCR:"PAGA Field Equipment LQ HULL Deck A",LOSH:"Y",MC_B30:"2022-06-23",A_OV_TOT:594,A_OV_COM:594,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-04-15",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:78,PA_OV_COM:65,PA_OV_REM:13,PB1_OV_TOT:41,PB1_OV_COM:41,PB1_OV_REM:0,PB2_OV_TOT:39,PB2_OV_COM:25,PB2_OV_REM:14,ACT_TAG_VALID:398,PUNCH_NUMBER_VALID:158,PUNCH_TAG_VALID:110},
    {SUBSYSTEM:"21-6200-6210-08",SUBSYSTEM_DESCR:"PAGA Field Equipment LQ Levels 1 and 2",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:624,A_OV_COM:624,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-04-15",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:72,PA_OV_COM:68,PA_OV_REM:4,PB1_OV_TOT:46,PB1_OV_COM:44,PB1_OV_REM:2,PB2_OV_TOT:19,PB2_OV_COM:13,PB2_OV_REM:6,ACT_TAG_VALID:420,PUNCH_NUMBER_VALID:137,PUNCH_TAG_VALID:97},
    {SUBSYSTEM:"21-6200-6210-09",SUBSYSTEM_DESCR:"PAGA Field Equipment LQ Levels 3 and 4",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:1332,A_OV_COM:1332,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-03",MC_APPROVED:"2022-03-22",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1",PA_OV_TOT:69,PA_OV_COM:69,PA_OV_REM:0,PB1_OV_TOT:52,PB1_OV_COM:52,PB1_OV_REM:0,PB2_OV_TOT:13,PB2_OV_COM:12,PB2_OV_REM:1,ACT_TAG_VALID:892,PUNCH_NUMBER_VALID:134,PUNCH_TAG_VALID:99},
    {SUBSYSTEM:"21-6200-6210-10",SUBSYSTEM_DESCR:"PAGA Field Equipment LQ Level 5 and LQ Roof",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:368,A_OV_COM:368,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-03",MC_APPROVED:"2022-03-22",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1",PA_OV_TOT:36,PA_OV_COM:36,PA_OV_REM:0,PB1_OV_TOT:37,PB1_OV_COM:37,PB1_OV_REM:0,PB2_OV_TOT:14,PB2_OV_COM:8,PB2_OV_REM:6,ACT_TAG_VALID:248,PUNCH_NUMBER_VALID:87,PUNCH_TAG_VALID:57},
    {SUBSYSTEM:"21-6200-6210-11",SUBSYSTEM_DESCR:"PAGA Field Equipment Machinery Space 1st and 2nd Levels",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:370,A_OV_COM:370,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-12-28",WD_ACTUAL:"2021-12-29",MC_APPROVED:"2022-03-02",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:7,PA_OV_COM:7,PA_OV_REM:0,PB1_OV_TOT:17,PB1_OV_COM:16,PB1_OV_REM:1,PB2_OV_TOT:10,PB2_OV_COM:2,PB2_OV_REM:8,ACT_TAG_VALID:248,PUNCH_NUMBER_VALID:34,PUNCH_TAG_VALID:16},
    {SUBSYSTEM:"21-6200-6210-12",SUBSYSTEM_DESCR:"PAGA Field Equipment Machinery Space 3rd Level",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:250,A_OV_COM:250,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-12-28",WD_ACTUAL:"2021-12-29",MC_APPROVED:"2022-03-02",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1",PA_OV_TOT:7,PA_OV_COM:7,PA_OV_REM:0,PB1_OV_TOT:11,PB1_OV_COM:11,PB1_OV_REM:0,PB2_OV_TOT:7,PB2_OV_COM:4,PB2_OV_REM:3,ACT_TAG_VALID:168,PUNCH_NUMBER_VALID:25,PUNCH_TAG_VALID:12},
    {SUBSYSTEM:"21-6200-6224-01",SUBSYSTEM_DESCR:"Digital UHF Network",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:233,A_OV_COM:40,A_OV_REM:193,A_OV_PROG:17.16,A_OV_STATUS:"less than 70%",A_PG:17.16,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:3,PA_OV_COM:2,PA_OV_REM:1,PB1_OV_TOT:2,PB1_OV_COM:2,PB1_OV_REM:0,PB2_OV_TOT:4,PB2_OV_COM:1,PB2_OV_REM:3,ACT_TAG_VALID:233,PUNCH_NUMBER_VALID:9,PUNCH_TAG_VALID:8},
    {SUBSYSTEM:"21-6200-6225-01",SUBSYSTEM_DESCR:"VHF ,AM Aeronautical Radio",LOSH:"Y",MC_B30:"2022-06-23",A_OV_TOT:22,A_OV_COM:7,A_OV_REM:15,A_OV_PROG:31.81,A_OV_STATUS:"less than 70%",A_PG:31.81,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P1",PB1_OV_TOT:2,PB1_OV_COM:2,PB1_OV_REM:0,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6200-6226-01",SUBSYSTEM_DESCR:"Non-Directional Beacon ,NDB",MC_B30:"2022-06-23",A_OV_TOT:21,A_OV_COM:1,A_OV_REM:20,A_OV_PROG:4.76,A_OV_STATUS:"less than 70%",A_PG:4.76,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6200-6227-01",SUBSYSTEM_DESCR:"Crane Radio System",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:56,A_OV_COM:0,A_OV_REM:56,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:44,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6200-6228-01",SUBSYSTEM_DESCR:"VHF ,FM Marine Radio",LOSH:"Y",MC_B30:"2022-06-23",A_OV_TOT:20,A_OV_COM:2,A_OV_REM:18,A_OV_PROG:10,A_OV_STATUS:"less than 70%",A_PG:10,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6300-6320-01",SUBSYSTEM_DESCR:"Lifeboats",MC_B30:"2022-06-23",A_OV_TOT:90,A_OV_COM:27,A_OV_REM:63,A_OV_PROG:30,A_OV_STATUS:"less than 70%",A_PG:30,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:4,PB1_OV_COM:4,PB1_OV_REM:0,ACT_TAG_VALID:69,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-6300-6330-01",SUBSYSTEM_DESCR:"Escape Chutes",MC_B30:"2022-06-23",A_OV_TOT:21,A_OV_COM:0,A_OV_REM:21,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6400-6450-01",SUBSYSTEM_DESCR:"Life Saving Portable Equipment ,Live Rafts ,davit type, BA sets, Buoys, Survival Suits, etc … - HULL",MC_B30:"2022-06-23",A_OV_TOT:44,A_OV_COM:0,A_OV_REM:44,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6400-6450-02",SUBSYSTEM_DESCR:"Life Saving Portable Equipment ,Live Rafts ,davit type, BA sets, Buoys, Survival Suits, etc … - LQ",MC_B30:"2022-06-23",A_OV_TOT:8,A_OV_COM:0,A_OV_REM:8,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6400-6450-03",SUBSYSTEM_DESCR:"Life Saving Portable Equipment ,Live Rafts ,davit type, BA sets, Buoys, Survival Suits, etc … - Topsides",MC_B30:"2022-08-04",A_OV_TOT:5,A_OV_COM:0,A_OV_REM:5,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6500-6510-01",SUBSYSTEM_DESCR:"Navigation Lights Control Panel and Associated Lanterns andFoghorns Circuits",MC_B30:"2022-05-12",A_OV_TOT:73,A_OV_COM:4,A_OV_REM:69,A_OV_PROG:5.47,A_OV_STATUS:"less than 70%",A_PG:5.47,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:11,PA_OV_COM:9,PA_OV_REM:2,ACT_TAG_VALID:55,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-6500-6520-02",SUBSYSTEM_DESCR:"Helideck Lights Control Panel and Associated Beacons",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:223,A_OV_COM:56,A_OV_REM:167,A_OV_PROG:25.11,A_OV_STATUS:"less than 70%",A_PG:25.11,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PA_OV_TOT:19,PA_OV_COM:19,PA_OV_REM:0,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:158,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6500-6550-01",SUBSYSTEM_DESCR:"RADAR Vessel Early Warning System",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:58,A_OV_COM:11,A_OV_REM:47,A_OV_PROG:18.96,A_OV_STATUS:"less than 70%",A_PG:18.96,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:2,PB1_OV_COM:2,PB1_OV_REM:0,PB2_OV_TOT:4,PB2_OV_COM:0,PB2_OV_REM:4,ACT_TAG_VALID:58,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6500-6556-01",SUBSYSTEM_DESCR:"Automatic Identification System",LOSH:"Y",MC_B30:"2022-06-23",A_OV_TOT:4,A_OV_COM:0,A_OV_REM:4,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6600-6610-01",SUBSYSTEM_DESCR:"Emergency Generator 21-G-661001",BATCH:"Incentive 1",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:250,A_OV_COM:157,A_OV_REM:93,A_OV_PROG:62.79,A_OV_STATUS:"less than 70%",A_PG:62.79,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:26,PA_OV_COM:14,PA_OV_REM:12,PB1_OV_TOT:36,PB1_OV_COM:23,PB1_OV_REM:13,PB2_OV_TOT:10,PB2_OV_COM:1,PB2_OV_REM:9,ACT_TAG_VALID:187,PUNCH_NUMBER_VALID:72,PUNCH_TAG_VALID:16},
    {SUBSYSTEM:"21-6600-6624-01",SUBSYSTEM_DESCR:"LQ Emergency Lighting Panel and Circuits ,Room: LH91 21-ELP-662460",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-24",A_OV_TOT:180,A_OV_COM:127,A_OV_REM:53,A_OV_PROG:70.55,A_OV_STATUS:"NA",A_PG:70.55,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PB1_OV_TOT:14,PB1_OV_COM:14,PB1_OV_REM:0,PB2_OV_TOT:21,PB2_OV_COM:13,PB2_OV_REM:8,ACT_TAG_VALID:157,PUNCH_NUMBER_VALID:37,PUNCH_TAG_VALID:14},
    {SUBSYSTEM:"21-6600-6624-02",SUBSYSTEM_DESCR:"LQ Emergency Lighting Panel and Circuits ,Room: L224 21-ELP-662461",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:97,A_OV_COM:73,A_OV_REM:24,A_OV_PROG:75.25,A_OV_STATUS:"NA",A_PG:75.25,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PA_OV_TOT:4,PA_OV_COM:4,PA_OV_REM:0,PB1_OV_TOT:12,PB1_OV_COM:12,PB1_OV_REM:0,PB2_OV_TOT:16,PB2_OV_COM:12,PB2_OV_REM:4,ACT_TAG_VALID:76,PUNCH_NUMBER_VALID:32,PUNCH_TAG_VALID:12},
    {SUBSYSTEM:"21-6600-6624-03",SUBSYSTEM_DESCR:"LQ Emergency Lighting Panel and Circuits ,Room: L320 21-ELP-662462",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-01-27",A_OV_TOT:39,A_OV_COM:33,A_OV_REM:6,A_OV_PROG:84.61,A_OV_STATUS:"greater than 80%",A_PG:84.61,RFI_ISSUED:"2021-12-29",WD_ACTUAL:"2021-12-30",MC_APPROVED:"2022-01-27",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:5,PA_OV_COM:5,PA_OV_REM:0,PB1_OV_TOT:10,PB1_OV_COM:8,PB1_OV_REM:2,PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:17,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-6600-6624-04",SUBSYSTEM_DESCR:"LQ Emergency Lighting Panel and Circuits ,Room: L501 21-ELP-662463",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:197,A_OV_COM:197,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-10",MC_APPROVED:"2022-03-24",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:14,PB1_OV_COM:14,PB1_OV_REM:0,PB2_OV_TOT:6,PB2_OV_COM:3,PB2_OV_REM:3,ACT_TAG_VALID:172,PUNCH_NUMBER_VALID:21,PUNCH_TAG_VALID:12},
    {SUBSYSTEM:"21-6600-6624-05",SUBSYSTEM_DESCR:"HULL Emergency Lighting Panel and Circuits ,Room: M104 21-ELP-662401",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:101,A_OV_COM:49,A_OV_REM:52,A_OV_PROG:48.51,A_OV_STATUS:"less than 70%",A_PG:48.51,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-22",MC_APPROVED:"2022-03-28",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P2",PA_OV_TOT:11,PA_OV_COM:11,PA_OV_REM:0,PB1_OV_TOT:45,PB1_OV_COM:45,PB1_OV_REM:0,PB2_OV_TOT:14,PB2_OV_COM:12,PB2_OV_REM:2,ACT_TAG_VALID:72,PUNCH_NUMBER_VALID:70,PUNCH_TAG_VALID:22},
    {SUBSYSTEM:"21-6600-6640-01",SUBSYSTEM_DESCR:"LQandHULL Emergency Lighting and small power 400V board 21-EDB-662401 ,in LQ",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2021-12-02",A_OV_TOT:41,A_OV_COM:41,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-11-09",WD_ACTUAL:"2021-11-10",MC_APPROVED:"2021-12-02",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1",PA_OV_TOT:16,PA_OV_COM:16,PA_OV_REM:0,PB1_OV_TOT:7,PB1_OV_COM:7,PB1_OV_REM:0,PB2_OV_TOT:2,PB2_OV_COM:1,PB2_OV_REM:1,ACT_TAG_VALID:41,PUNCH_NUMBER_VALID:25,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6600-6640-02",SUBSYSTEM_DESCR:"Topsides Emergency Lighting and small power 400V board 21-EDB-662402 ,in LER",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:3022,A_OV_COM:1566,A_OV_REM:1456,A_OV_PROG:51.81,A_OV_STATUS:"less than 70%",A_PG:51.81,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1B",PA_OV_TOT:25,PA_OV_COM:17,PA_OV_REM:8,PB1_OV_TOT:23,PB1_OV_COM:6,PB1_OV_REM:17,PB2_OV_TOT:42,PB2_OV_COM:1,PB2_OV_REM:41,ACT_TAG_VALID:2962,PUNCH_NUMBER_VALID:90,PUNCH_TAG_VALID:59},
    {SUBSYSTEM:"21-6600-6640-03",SUBSYSTEM_DESCR:"Sub Distribution Auxiliary Panel 01 ,ROOM: LH16 21-EDB-667671",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2021-12-30",A_OV_TOT:3,A_OV_COM:3,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-12-16",WD_ACTUAL:"2021-12-17",MC_APPROVED:"2021-12-30",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P2",PA_OV_TOT:4,PA_OV_COM:4,PA_OV_REM:0,PB1_OV_TOT:6,PB1_OV_COM:6,PB1_OV_REM:0,PB2_OV_TOT:4,PB2_OV_COM:1,PB2_OV_REM:3,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:14,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6600-6640-04",SUBSYSTEM_DESCR:"Sub Distribution Auxiliary Panel 03 ,ROOM: M10621-EDB-662433",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2021-12-02",A_OV_TOT:3,A_OV_COM:3,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-11-24",WD_ACTUAL:"2021-11-25",MC_APPROVED:"2021-12-02",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P2",PA_OV_TOT:5,PA_OV_COM:5,PA_OV_REM:0,PB1_OV_TOT:6,PB1_OV_COM:6,PB1_OV_REM:0,PB2_OV_TOT:2,PB2_OV_COM:1,PB2_OV_REM:1,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6600-6640-05",SUBSYSTEM_DESCR:"Panel Control For External Lighting - 21-ECP-667680",BATCH:"Incentive 2",MC_B30:"2022-04-14",A_OV_TOT:51,A_OV_COM:1,A_OV_REM:50,A_OV_PROG:1.96,A_OV_STATUS:"less than 70%",A_PG:1.96,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:49,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6600-6660-01",SUBSYSTEM_DESCR:"Emergency 690 V Switchboard 21-ESB-666001",BATCH:"Incentive 1",LOSH:"Y",MC_B30:"2021-11-04",A_OV_TOT:233,A_OV_COM:225,A_OV_REM:8,A_OV_PROG:96.56,A_OV_STATUS:"greater than 95%",A_PG:96.56,RFI_ISSUED:"2021-10-07",WD_ACTUAL:"2021-10-08",MC_APPROVED:"2021-11-20",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1A",PA_OV_TOT:10,PA_OV_COM:10,PA_OV_REM:0,PB1_OV_TOT:11,PB1_OV_COM:8,PB1_OV_REM:3,PB2_OV_TOT:41,PB2_OV_COM:35,PB2_OV_REM:6,ACT_TAG_VALID:233,PUNCH_NUMBER_VALID:62,PUNCH_TAG_VALID:19},
    {SUBSYSTEM:"21-6600-6670-01",SUBSYSTEM_DESCR:"Key service Generator 21-G-667001",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-17",A_OV_TOT:146,A_OV_COM:146,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-20",MC_APPROVED:"2022-02-28",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1A",PA_OV_TOT:68,PA_OV_COM:68,PA_OV_REM:0,PB1_OV_TOT:82,PB1_OV_COM:80,PB1_OV_REM:2,PB2_OV_TOT:62,PB2_OV_COM:32,PB2_OV_REM:30,ACT_TAG_VALID:98,PUNCH_NUMBER_VALID:212,PUNCH_TAG_VALID:40},
    {SUBSYSTEM:"21-6600-6670-02",SUBSYSTEM_DESCR:"Key service Generator 21-G-667002",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-17",A_OV_TOT:139,A_OV_COM:138,A_OV_REM:1,A_OV_PROG:99.28,A_OV_STATUS:"greater than 95%",A_PG:99.28,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-21",MC_APPROVED:"2022-02-28",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1A",PA_OV_TOT:45,PA_OV_COM:45,PA_OV_REM:0,PB1_OV_TOT:70,PB1_OV_COM:70,PB1_OV_REM:0,PB2_OV_TOT:38,PB2_OV_COM:13,PB2_OV_REM:25,ACT_TAG_VALID:91,PUNCH_NUMBER_VALID:153,PUNCH_TAG_VALID:30},
    {SUBSYSTEM:"21-6600-6673-01",SUBSYSTEM_DESCR:"Essential 6.6 kV Switchboard 21-ESB-667301",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2021-11-25",A_OV_TOT:292,A_OV_COM:247,A_OV_REM:45,A_OV_PROG:84.58,A_OV_STATUS:"greater than 80%",A_PG:84.58,RFI_ISSUED:"2021-11-06",WD_ACTUAL:"2022-03-26",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:20,PA_OV_COM:20,PA_OV_REM:0,PB1_OV_TOT:36,PB1_OV_COM:35,PB1_OV_REM:1,PB2_OV_TOT:11,PB2_OV_COM:2,PB2_OV_REM:9,ACT_TAG_VALID:292,PUNCH_NUMBER_VALID:67,PUNCH_TAG_VALID:20},
    {SUBSYSTEM:"21-6600-6674-01",SUBSYSTEM_DESCR:"HULL Normal and Essential 690 V Swtchboard 21-ESB-667401",BATCH:"Incentive 1",LOSH:"Y",MC_B30:"2021-09-30",A_OV_TOT:317,A_OV_COM:315,A_OV_REM:2,A_OV_PROG:99.36,A_OV_STATUS:"greater than 95%",A_PG:99.36,RFI_ISSUED:"2021-08-18",WD_ACTUAL:"2021-08-19",MC_APPROVED:"2021-12-08",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:9,PA_OV_COM:9,PA_OV_REM:0,PB1_OV_TOT:12,PB1_OV_COM:11,PB1_OV_REM:1,PB2_OV_TOT:44,PB2_OV_COM:37,PB2_OV_REM:7,ACT_TAG_VALID:317,PUNCH_NUMBER_VALID:65,PUNCH_TAG_VALID:11},
    {SUBSYSTEM:"21-6600-6674-02",SUBSYSTEM_DESCR:"Topside Essential 690 V Switchboard 21-ESB-667402",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:237,A_OV_COM:227,A_OV_REM:10,A_OV_PROG:95.78,A_OV_STATUS:"greater than 95%",A_PG:95.78,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1B",PA_OV_TOT:3,PA_OV_COM:3,PA_OV_REM:0,PB1_OV_TOT:52,PB1_OV_COM:27,PB1_OV_REM:25,PB2_OV_TOT:5,PB2_OV_COM:5,PB2_OV_REM:0,ACT_TAG_VALID:237,PUNCH_NUMBER_VALID:60,PUNCH_TAG_VALID:15},
    {SUBSYSTEM:"21-6600-6675-01",SUBSYSTEM_DESCR:"HULL Normal and Essential 400 V Switchboard 21-ESB-667601",BATCH:"Incentive 1",LOSH:"Y",MC_B30:"2021-10-21",A_OV_TOT:62,A_OV_COM:58,A_OV_REM:4,A_OV_PROG:93.54,A_OV_STATUS:"greater than 90%",A_PG:93.54,RFI_ISSUED:"2021-08-18",WD_ACTUAL:"2021-08-19",MC_APPROVED:"2021-10-20",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:8,PA_OV_COM:8,PA_OV_REM:0,PB1_OV_TOT:9,PB1_OV_COM:8,PB1_OV_REM:1,PB2_OV_TOT:26,PB2_OV_COM:21,PB2_OV_REM:5,ACT_TAG_VALID:62,PUNCH_NUMBER_VALID:43,PUNCH_TAG_VALID:9},
    {SUBSYSTEM:"21-6600-6675-02",SUBSYSTEM_DESCR:"Topsides Normal and Essential lighting and Small Power 400V 21-EDB-667602",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:195,A_OV_COM:33,A_OV_REM:162,A_OV_PROG:16.92,A_OV_STATUS:"less than 70%",A_PG:16.92,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:3,PB1_OV_COM:2,PB1_OV_REM:1,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:148,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6600-6675-03",SUBSYSTEM_DESCR:"Topsides Normal and Essential lighting and Small Power 400V 21-EDB-667603",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:2831,A_OV_COM:1333,A_OV_REM:1498,A_OV_PROG:47.08,A_OV_STATUS:"less than 70%",A_PG:47.08,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:4,PA_OV_COM:0,PA_OV_REM:4,PB1_OV_TOT:20,PB1_OV_COM:6,PB1_OV_REM:14,PB2_OV_TOT:24,PB2_OV_COM:1,PB2_OV_REM:23,ACT_TAG_VALID:2754,PUNCH_NUMBER_VALID:48,PUNCH_TAG_VALID:37},
    {SUBSYSTEM:"21-6600-6675-04",SUBSYSTEM_DESCR:"Topsides MOV and Convenience Outlets 400V 21-EDB-667604",BATCH:"Incentive 2",MC_B30:"2022-04-07",A_OV_TOT:241,A_OV_COM:32,A_OV_REM:209,A_OV_PROG:13.27,A_OV_STATUS:"less than 70%",A_PG:13.27,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:11,PA_OV_COM:0,PA_OV_REM:11,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:214,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6600-6675-05",SUBSYSTEM_DESCR:"Topsides Misc Feeders and Auxiliaries 400V 21-EDB-667606",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:30,A_OV_COM:1,A_OV_REM:29,A_OV_PROG:3.33,A_OV_STATUS:"less than 70%",A_PG:3.33,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:16,PA_OV_COM:6,PA_OV_REM:10,PB1_OV_TOT:2,PB1_OV_COM:1,PB1_OV_REM:1,ACT_TAG_VALID:30,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6600-6675-06",SUBSYSTEM_DESCR:"Sub Distribution Auxiliary Panel 02 ,ROOM: M303 21-EDB-667635",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2021-10-28",A_OV_TOT:3,A_OV_COM:3,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-08-25",WD_ACTUAL:"2021-08-26",MC_APPROVED:"2021-10-28",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:13,PA_OV_COM:13,PA_OV_REM:0,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6600-6676-01",SUBSYSTEM_DESCR:"Normal Lighting Panel 01 ,ROOM: M103 21-ELP-667630",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-01-27",A_OV_TOT:56,A_OV_COM:49,A_OV_REM:7,A_OV_PROG:87.5,A_OV_STATUS:"greater than 80%",A_PG:87.5,RFI_ISSUED:"2021-12-28",WD_ACTUAL:"2021-12-29",MC_APPROVED:"2022-01-22",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:3,PA_OV_REM:0,PB1_OV_TOT:40,PB1_OV_COM:40,PB1_OV_REM:0,PB2_OV_TOT:6,PB2_OV_COM:6,PB2_OV_REM:0,ACT_TAG_VALID:30,PUNCH_NUMBER_VALID:49,PUNCH_TAG_VALID:18,COMMENTS:"Y"},
    {SUBSYSTEM:"21-6600-6676-02",SUBSYSTEM_DESCR:"Normal Lighting Panel 02 ,ROOM: M303 21-ELP-667631",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2021-12-16",A_OV_TOT:50,A_OV_COM:50,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-10-11",WD_ACTUAL:"2021-10-12",MC_APPROVED:"2021-12-10",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:31,PA_OV_COM:31,PA_OV_REM:0,PB1_OV_TOT:11,PB1_OV_COM:11,PB1_OV_REM:0,PB2_OV_TOT:3,PB2_OV_COM:3,PB2_OV_REM:0,PB3_OV_TOT:1,PB3_OV_COM:1,PB3_OV_REM:0,ACT_TAG_VALID:28,PUNCH_NUMBER_VALID:46,PUNCH_TAG_VALID:21},
    {SUBSYSTEM:"21-6600-6676-03",SUBSYSTEM_DESCR:"Machinary Workshop Power Panel ,ROOM: M104 21-ELP-667636",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:50,A_OV_COM:46,A_OV_REM:4,A_OV_PROG:92,A_OV_STATUS:"greater than 90%",A_PG:92,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PB1_OV_TOT:2,PB1_OV_COM:2,PB1_OV_REM:0,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:49,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6600-6676-04",SUBSYSTEM_DESCR:"LQ Small Power for LQ Aux Eqt ,ROOM: LH05 21-ELP-667672",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:177,A_OV_COM:85,A_OV_REM:92,A_OV_PROG:48.02,A_OV_STATUS:"less than 70%",A_PG:48.02,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PB1_OV_TOT:18,PB1_OV_COM:18,PB1_OV_REM:0,PB2_OV_TOT:4,PB2_OV_COM:3,PB2_OV_REM:1,ACT_TAG_VALID:126,PUNCH_NUMBER_VALID:24,PUNCH_TAG_VALID:17},
    {SUBSYSTEM:"21-6600-6676-05",SUBSYSTEM_DESCR:"LQ Galley Panel 01 ,ROOM: L210 21-ELP-667673",LOSH:"Y",MC_B30:"2021-12-02",A_OV_TOT:4,A_OV_COM:4,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-11-03",WD_ACTUAL:"2021-11-04",MC_APPROVED:"2021-12-02",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P2",PA_OV_TOT:14,PA_OV_COM:14,PA_OV_REM:0,PB1_OV_TOT:8,PB1_OV_COM:8,PB1_OV_REM:0,PB2_OV_TOT:2,PB2_OV_COM:1,PB2_OV_REM:1,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:24,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6600-6676-06",SUBSYSTEM_DESCR:"Normal Lighting Panel 05 ,ROOM: LH05 21-ELP-667660",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:79,A_OV_COM:67,A_OV_REM:12,A_OV_PROG:84.81,A_OV_STATUS:"greater than 80%",A_PG:84.81,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-30",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:6,PA_OV_COM:4,PA_OV_REM:2,PB1_OV_TOT:8,PB1_OV_COM:8,PB1_OV_REM:0,PB2_OV_TOT:9,PB2_OV_COM:5,PB2_OV_REM:4,ACT_TAG_VALID:64,PUNCH_NUMBER_VALID:23,PUNCH_TAG_VALID:11},
    {SUBSYSTEM:"21-6600-6676-07",SUBSYSTEM_DESCR:"Normal Lighting Panel 06 ,ROOM: LA17 21-ELP-667661",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:97,A_OV_COM:54,A_OV_REM:43,A_OV_PROG:55.67,A_OV_STATUS:"less than 70%",A_PG:55.67,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-31",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:3,PA_OV_REM:0,PB1_OV_TOT:5,PB1_OV_COM:5,PB1_OV_REM:0,PB2_OV_TOT:3,PB2_OV_COM:3,PB2_OV_REM:0,ACT_TAG_VALID:71,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-6600-6676-08",SUBSYSTEM_DESCR:"Normal Lighting Panel 07 ,ROOM: L112 21-ELP-667662",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:35,A_OV_COM:35,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-19",MC_APPROVED:"2022-03-01",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:11,PB1_OV_COM:11,PB1_OV_REM:0,PB2_OV_TOT:12,PB2_OV_COM:5,PB2_OV_REM:7,ACT_TAG_VALID:19,PUNCH_NUMBER_VALID:24,PUNCH_TAG_VALID:10},
    {SUBSYSTEM:"21-6600-6676-09",SUBSYSTEM_DESCR:"Normal Lighting Panel 08 ,ROOM: L225 21-ELP-667663",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:63,A_OV_COM:63,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"B2",PRIORITY:"P2",PB1_OV_TOT:9,PB1_OV_COM:9,PB1_OV_REM:0,PB2_OV_TOT:6,PB2_OV_COM:1,PB2_OV_REM:5,ACT_TAG_VALID:49,PUNCH_NUMBER_VALID:15,PUNCH_TAG_VALID:8},
    {SUBSYSTEM:"21-6600-6676-10",SUBSYSTEM_DESCR:"Normal Lighting Panel 09 ,ROOM: L345 21-ELP-667664",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-01-13",A_OV_TOT:83,A_OV_COM:83,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-12-16",WD_ACTUAL:"2021-12-20",MC_APPROVED:"2022-01-07",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P2",PA_OV_TOT:4,PA_OV_COM:4,PA_OV_REM:0,PB1_OV_TOT:13,PB1_OV_COM:13,PB1_OV_REM:0,PB2_OV_TOT:5,PB2_OV_COM:0,PB2_OV_REM:5,ACT_TAG_VALID:43,PUNCH_NUMBER_VALID:22,PUNCH_TAG_VALID:8},
    {SUBSYSTEM:"21-6600-6676-11",SUBSYSTEM_DESCR:"Normal Lighting Panel 10 ,ROOM: L445 21-ELP-667665",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-10",A_OV_TOT:98,A_OV_COM:98,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-22",MC_APPROVED:"2022-02-08",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P2",PB1_OV_TOT:6,PB1_OV_COM:6,PB1_OV_REM:0,PB2_OV_TOT:4,PB2_OV_COM:1,PB2_OV_REM:3,ACT_TAG_VALID:57,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:7},
    {SUBSYSTEM:"21-6600-6676-12",SUBSYSTEM_DESCR:"Normal Lighting Panel 11 ,ROOM: L591 21-ELP-667666",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-10",A_OV_TOT:88,A_OV_COM:88,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-19",MC_APPROVED:"2022-03-08",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:7,PA_OV_COM:7,PA_OV_REM:0,PB1_OV_TOT:18,PB1_OV_COM:16,PB1_OV_REM:2,PB2_OV_TOT:6,PB2_OV_COM:3,PB2_OV_REM:3,ACT_TAG_VALID:74,PUNCH_NUMBER_VALID:31,PUNCH_TAG_VALID:11},
    {SUBSYSTEM:"21-6600-6676-13",SUBSYSTEM_DESCR:"LQ Laundry Panel 01 ,ROOM: L115 21-ELP-667674",LOSH:"Y",MC_B30:"2021-11-04",A_OV_TOT:4,A_OV_COM:4,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-08-27",WD_ACTUAL:"2021-08-30",MC_APPROVED:"2021-10-29",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:7,PA_OV_COM:7,PA_OV_REM:0,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6600-6676-14",SUBSYSTEM_DESCR:"LQ Laboratory Distribution Board ,ROOM: LA04 21-ELP-667675",LOSH:"Y",MC_B30:"2021-12-09",A_OV_TOT:4,A_OV_COM:4,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-11-06",WD_ACTUAL:"2021-11-08",MC_APPROVED:"2021-12-04",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:13,PA_OV_COM:13,PA_OV_REM:0,PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,PB2_OV_TOT:3,PB2_OV_COM:3,PB2_OV_REM:0,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:17,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6600-6676-15",SUBSYSTEM_DESCR:"LQ Laundry Panel 02 ,ROOM: L115 21-ELP-667677",LOSH:"Y",MC_B30:"2021-10-28",A_OV_TOT:4,A_OV_COM:4,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-10-07",WD_ACTUAL:"2021-10-08",MC_APPROVED:"2021-10-28",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:3,PB1_OV_COM:3,PB1_OV_REM:0,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6600-6676-16",SUBSYSTEM_DESCR:"LQ Galley Panel 02 ,ROOM: L220 21-ELP-667678",LOSH:"Y",MC_B30:"2021-12-02",A_OV_TOT:4,A_OV_COM:4,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-11-03",WD_ACTUAL:"2021-11-04",MC_APPROVED:"2021-12-02",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P2",PA_OV_TOT:12,PA_OV_COM:12,PA_OV_REM:0,PB1_OV_TOT:5,PB1_OV_COM:5,PB1_OV_REM:0,PB2_OV_TOT:2,PB2_OV_COM:1,PB2_OV_REM:1,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:19,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6625-8725-01",SUBSYSTEM_DESCR:"Normal and Essential Heat Trace 400V Topsides 21-EDB-667605",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:4,A_OV_COM:1,A_OV_REM:3,A_OV_PROG:25,A_OV_STATUS:"less than 70%",A_PG:25,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6700-6701-01",SUBSYSTEM_DESCR:"Main LQ UPS System ,Incl Batteries, Breakers and Subdistribution Board",BATCH:"Incentive 1",LOSH:"Y",MC_B30:"2021-11-04",A_OV_TOT:46,A_OV_COM:39,A_OV_REM:7,A_OV_PROG:84.78,A_OV_STATUS:"greater than 80%",A_PG:84.78,RFI_ISSUED:"2021-10-07",WD_ACTUAL:"2021-10-08",MC_APPROVED:"2021-10-30",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"N",PRIORITY:"P1A",PA_OV_TOT:31,PA_OV_COM:31,PA_OV_REM:0,PB1_OV_TOT:54,PB1_OV_COM:54,PB1_OV_REM:0,PB2_OV_TOT:3,PB2_OV_COM:3,PB2_OV_REM:0,ACT_TAG_VALID:42,PUNCH_NUMBER_VALID:88,PUNCH_TAG_VALID:18},
    {SUBSYSTEM:"21-6700-6701-02",SUBSYSTEM_DESCR:"LQ Telecom UPS System B for HULL and LQ ,Incl Batteries, Breakers and Subdistribution Board",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2021-12-02",A_OV_TOT:19,A_OV_COM:16,A_OV_REM:3,A_OV_PROG:84.21,A_OV_STATUS:"greater than 80%",A_PG:84.21,RFI_ISSUED:"2021-11-08",WD_ACTUAL:"2021-11-09",MC_APPROVED:"2021-11-26",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1",PA_OV_TOT:5,PA_OV_COM:5,PA_OV_REM:0,PB1_OV_TOT:23,PB1_OV_COM:23,PB1_OV_REM:0,PB2_OV_TOT:3,PB2_OV_COM:2,PB2_OV_REM:1,ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:31,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-6700-6701-03",SUBSYSTEM_DESCR:"LQ Telecom UPS System A for Topsides ,Incl Batteries, Breakers and Subdistribution Board",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:19,A_OV_COM:16,A_OV_REM:3,A_OV_PROG:84.21,A_OV_STATUS:"greater than 80%",A_PG:84.21,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-18",MC_APPROVED:"2022-04-15",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:13,PA_OV_COM:12,PA_OV_REM:1,PB1_OV_TOT:27,PB1_OV_COM:23,PB1_OV_REM:4,PB2_OV_TOT:1,PB2_OV_COM:1,PB2_OV_REM:0,PB3_OV_TOT:3,PB3_OV_COM:3,PB3_OV_REM:0,ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:44,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-6700-6702-01",SUBSYSTEM_DESCR:"Topside Main UPS System ,Incl Batteries, Breakers and Subdistribution Board",BATCH:"Incentive 2",MC_B30:"2022-02-10",A_OV_TOT:45,A_OV_COM:45,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-12-31",WD_ACTUAL:"2022-01-03",MC_APPROVED:"2022-02-10",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1B",PA_OV_TOT:5,PA_OV_COM:5,PA_OV_REM:0,PB1_OV_TOT:43,PB1_OV_COM:28,PB1_OV_REM:15,PB2_OV_TOT:5,PB2_OV_COM:4,PB2_OV_REM:1,ACT_TAG_VALID:41,PUNCH_NUMBER_VALID:53,PUNCH_TAG_VALID:10},
    {SUBSYSTEM:"21-6700-6702-02",SUBSYSTEM_DESCR:"Subsea UPS System ,Incl Batteries, Breakers, Voltage Stabilisers and Subdistribution Board",LOSH:"Y",MC_B30:"2022-02-10",A_OV_TOT:34,A_OV_COM:34,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-12-31",WD_ACTUAL:"2022-01-03",MC_APPROVED:"2022-02-10",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1B",PA_OV_TOT:4,PA_OV_COM:4,PA_OV_REM:0,PB1_OV_TOT:37,PB1_OV_COM:28,PB1_OV_REM:9,PB2_OV_TOT:3,PB2_OV_COM:2,PB2_OV_REM:1,ACT_TAG_VALID:30,PUNCH_NUMBER_VALID:44,PUNCH_TAG_VALID:8},
    {SUBSYSTEM:"21-6800-6830-01",SUBSYSTEM_DESCR:"Fire and Gas Detecion - LQ",MC_B30:"2022-04-21",A_OV_TOT:296,A_OV_COM:264,A_OV_REM:32,A_OV_PROG:89.18,A_OV_STATUS:"greater than 80%",A_PG:89.18,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P1",PB1_OV_TOT:9,PB1_OV_COM:9,PB1_OV_REM:0,PB2_OV_TOT:11,PB2_OV_COM:1,PB2_OV_REM:10,ACT_TAG_VALID:148,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:13},
    {SUBSYSTEM:"21-6800-6830-02",SUBSYSTEM_DESCR:"Magnetic Door Holder  - LQ",MC_B30:"2022-06-30",A_OV_TOT:148,A_OV_COM:0,A_OV_REM:148,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:148,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6840-01",SUBSYSTEM_DESCR:"Fire Zone 6840-1 - Power Generation Module-MW04 ,Open Area",MC_B30:"2022-04-28",A_OV_TOT:99,A_OV_COM:87,A_OV_REM:12,A_OV_PROG:87.87,A_OV_STATUS:"greater than 80%",A_PG:87.87,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:12,PB1_OV_COM:0,PB1_OV_REM:12,ACT_TAG_VALID:54,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-6800-6840-02",SUBSYSTEM_DESCR:"Fire Zone 6840-2 - Power Generation Module- ME04 ,Open Area",MC_B30:"2022-04-28",A_OV_TOT:102,A_OV_COM:59,A_OV_REM:43,A_OV_PROG:57.84,A_OV_STATUS:"less than 70%",A_PG:57.84,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,ACT_TAG_VALID:56,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6800-6840-03",SUBSYSTEM_DESCR:"Fire Zone 6840-3 - Gas Turbine Generator Package 21-Z-861101",MC_B30:"2022-04-14",A_OV_TOT:25,A_OV_COM:0,A_OV_REM:25,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6840-04",SUBSYSTEM_DESCR:"Fire Zone 6840-4 - Gas Turbine Generator Package 21-Z-861201",MC_B30:"2022-04-14",A_OV_TOT:25,A_OV_COM:0,A_OV_REM:25,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6840-05",SUBSYSTEM_DESCR:"Fire Zone 6840-5 - Gas Turbine Generator Package 21-Z-861301",MC_B30:"2022-04-14",A_OV_TOT:25,A_OV_COM:0,A_OV_REM:25,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6840-06",SUBSYSTEM_DESCR:"Fire Zone 6840-6 - Gas Turbine Generator Package 21-Z-861401",MC_B30:"2022-04-14",A_OV_TOT:25,A_OV_COM:0,A_OV_REM:25,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6840-07",SUBSYSTEM_DESCR:"Fire Zone 6840-7 - Utility Module–MW05",MC_B30:"2022-04-28",A_OV_TOT:140,A_OV_COM:49,A_OV_REM:91,A_OV_PROG:35,A_OV_STATUS:"less than 70%",A_PG:35,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:79,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6840-08",SUBSYSTEM_DESCR:"Fire Zone 6840-8and9 -Pedestal Cranes ,WestandEast",MC_B30:"2022-06-09",A_OV_TOT:50,A_OV_COM:6,A_OV_REM:44,A_OV_PROG:12,A_OV_STATUS:"less than 70%",A_PG:12,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:34,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6841-01",SUBSYSTEM_DESCR:"Fire Zone 6841-0 - Reception Facilities and Production Separation Module - MW01",MC_B30:"2022-06-02",A_OV_TOT:381,A_OV_COM:65,A_OV_REM:316,A_OV_PROG:17.06,A_OV_STATUS:"less than 70%",A_PG:17.06,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:206,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6841-02",SUBSYSTEM_DESCR:"Fire Zone 6841-1 - Flash Gas Compression Module - ME01",MC_B30:"2022-05-12",A_OV_TOT:263,A_OV_COM:76,A_OV_REM:187,A_OV_PROG:28.89,A_OV_STATUS:"less than 70%",A_PG:28.89,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:142,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6841-03",SUBSYSTEM_DESCR:"Fire Zone 6841-2 - Gas Dewpointing andMEG and Methanol injection and Fuel gas Module - MW02",MC_B30:"2022-05-12",A_OV_TOT:357,A_OV_COM:84,A_OV_REM:273,A_OV_PROG:23.52,A_OV_STATUS:"less than 70%",A_PG:23.52,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:190,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6841-04",SUBSYSTEM_DESCR:"Fire Zone 6841-3 - Condensate stabilization and MEG regeneration and Produced Water treatment Module  - ME02",MC_B30:"2022-07-07",A_OV_TOT:277,A_OV_COM:41,A_OV_REM:236,A_OV_PROG:14.8,A_OV_STATUS:"less than 70%",A_PG:14.8,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P3",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:152,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6800-6841-07",SUBSYSTEM_DESCR:"Fire Zone 6841-6 - Aft Platform on Deck A",MC_B30:"2022-06-09",A_OV_TOT:59,A_OV_COM:7,A_OV_REM:52,A_OV_PROG:11.86,A_OV_STATUS:"less than 70%",A_PG:11.86,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,ACT_TAG_VALID:30,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6800-6841-08",SUBSYSTEM_DESCR:"Fire Zone 6841-7 - Piperack Between MW01 and ME01",MC_B30:"2022-07-07",A_OV_TOT:3,A_OV_COM:0,A_OV_REM:3,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6841-09",SUBSYSTEM_DESCR:"Fire Zone 6841-8 - Piperack Between MW02 and ME02",MC_B30:"2022-06-16",A_OV_TOT:3,A_OV_COM:0,A_OV_REM:3,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-01",SUBSYSTEM_DESCR:"Fire Zone 6842-0 - Cargo Tank Top ,Rich Meg, Methanol, Salty Rich MEG 2, Offspec Condensate, Slop, Wax Inhibitor, Produced Water Storage Tanks and Flare Facilities Area, Including Marine Piperack",MC_B30:"2022-04-21",A_OV_TOT:185,A_OV_COM:26,A_OV_REM:159,A_OV_PROG:14.05,A_OV_STATUS:"less than 70%",A_PG:14.05,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:97,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-02",SUBSYSTEM_DESCR:"Fire Zone 6842-1 - Cargo Tank Top ,Condensate Tank 1 and 2 and Closed Drain Facilities",MC_B30:"2022-04-21",A_OV_TOT:130,A_OV_COM:21,A_OV_REM:109,A_OV_PROG:16.15,A_OV_STATUS:"less than 70%",A_PG:16.15,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:70,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-03",SUBSYSTEM_DESCR:"Fire Zone 6842-2 - Cargo Tank Top ,Salty Rich MEG Tanks 3and4",MC_B30:"2022-04-21",A_OV_TOT:137,A_OV_COM:22,A_OV_REM:115,A_OV_PROG:16.05,A_OV_STATUS:"less than 70%",A_PG:16.05,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:74,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-04",SUBSYSTEM_DESCR:"Fire Zone 6842-3 - Cargo Tank Top ,Condensate Tank 3 and 4",MC_B30:"2022-04-21",A_OV_TOT:86,A_OV_COM:12,A_OV_REM:74,A_OV_PROG:13.95,A_OV_STATUS:"less than 70%",A_PG:13.95,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:48,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-05",SUBSYSTEM_DESCR:"Fire Zone 6842-4 - Cargo Tank Top ,Condensate Tank 5 and 6",MC_B30:"2022-04-21",A_OV_TOT:99,A_OV_COM:17,A_OV_REM:82,A_OV_PROG:17.17,A_OV_STATUS:"less than 70%",A_PG:17.17,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:54,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-06",SUBSYSTEM_DESCR:"Fire Zone 6842-5 - Cargo Tank Top ,Condensate Tank 7 and 8",MC_B30:"2022-04-21",A_OV_TOT:157,A_OV_COM:17,A_OV_REM:140,A_OV_PROG:10.82,A_OV_STATUS:"less than 70%",A_PG:10.82,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:90,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-07",SUBSYSTEM_DESCR:"Fire Zone 6842-6 - Cargo Tank Top ,Lean MEG Storage Tanks",MC_B30:"2022-04-21",A_OV_TOT:110,A_OV_COM:8,A_OV_REM:102,A_OV_PROG:7.27,A_OV_STATUS:"less than 70%",A_PG:7.27,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:56,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-08",SUBSYSTEM_DESCR:"Fire Zone 6842-7 - Fire Water Pump Enclosure 611101",MC_B30:"2022-06-09",A_OV_STATUS:"NA",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-09",SUBSYSTEM_DESCR:"Fire Zone 6842-8 - Fire Water Pump Enclosure 611201",MC_B30:"2022-06-09",A_OV_STATUS:"NA",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-10",SUBSYSTEM_DESCR:"Fire Zone 6842-9 - Fire Water Pump Enclosure 611301",MC_B30:"2022-05-05",A_OV_STATUS:"NA",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-11",SUBSYSTEM_DESCR:"Fire Zone 6843-0 - West Riser Balcony Area",MC_B30:"2022-05-26",A_OV_TOT:25,A_OV_COM:4,A_OV_REM:21,A_OV_PROG:16,A_OV_STATUS:"less than 70%",A_PG:16,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-13",SUBSYSTEM_DESCR:"Fire Zone 6843-3 - Emergency Diesel Generators",MC_B30:"2022-05-05",A_OV_STATUS:"NA",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1,COMMENTS:"Y"},
    {SUBSYSTEM:"21-6800-6842-16",SUBSYSTEM_DESCR:"Fire Zone 6850 - General Topsides Area",MC_B30:"2022-07-21",A_OV_TOT:32,A_OV_COM:2,A_OV_REM:30,A_OV_PROG:6.25,A_OV_STATUS:"less than 70%",A_PG:6.25,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6844-01",SUBSYSTEM_DESCR:"Fire and Gas Detecion - Machinery Space",MC_B30:"2022-03-10",A_OV_TOT:73,A_OV_COM:73,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-27",MC_APPROVED:"2022-03-08",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:6,PA_OV_COM:6,PA_OV_REM:0,PB1_OV_TOT:6,PB1_OV_COM:5,PB1_OV_REM:1,PB2_OV_TOT:35,PB2_OV_COM:0,PB2_OV_REM:35,ACT_TAG_VALID:39,PUNCH_NUMBER_VALID:47,PUNCH_TAG_VALID:26},
    {SUBSYSTEM:"21-6800-6850-01",SUBSYSTEM_DESCR:"Fire and Gas Detecion - ME05",MC_B30:"2022-04-21",A_OV_TOT:80,A_OV_COM:69,A_OV_REM:11,A_OV_PROG:86.25,A_OV_STATUS:"greater than 80%",A_PG:86.25,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,PB2_OV_TOT:4,PB2_OV_COM:0,PB2_OV_REM:4,ACT_TAG_VALID:40,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:4,COMMENTS:"Y"},
    {SUBSYSTEM:"21-6800-6854-01",SUBSYSTEM_DESCR:"Fire Zone 6854 - Transit Refuge Area",MC_B30:"2022-06-30",A_OV_TOT:24,A_OV_COM:6,A_OV_REM:18,A_OV_PROG:25,A_OV_STATUS:"less than 70%",A_PG:25,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8000-8010-01",SUBSYSTEM_DESCR:"Potable Water Maker",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:167,A_OV_COM:137,A_OV_REM:30,A_OV_PROG:82.03,A_OV_STATUS:"greater than 80%",A_PG:82.03,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:31,PA_OV_COM:25,PA_OV_REM:6,PB1_OV_TOT:27,PB1_OV_COM:16,PB1_OV_REM:11,PB2_OV_TOT:28,PB2_OV_COM:6,PB2_OV_REM:22,ACT_TAG_VALID:81,PUNCH_NUMBER_VALID:86,PUNCH_TAG_VALID:16},
    {SUBSYSTEM:"21-8000-8010-02",SUBSYSTEM_DESCR:"Chlorination Package",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:22,A_OV_COM:22,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-25",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:13,PA_OV_COM:13,PA_OV_REM:0,PB1_OV_TOT:7,PB1_OV_COM:6,PB1_OV_REM:1,PB2_OV_TOT:9,PB2_OV_COM:3,PB2_OV_REM:6,ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:29,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-8000-8011-01",SUBSYSTEM_DESCR:"Potable Water Tanks and Pumps",LOSH:"Y",MC_B30:"2022-03-24",A_OV_TOT:220,A_OV_COM:214,A_OV_REM:6,A_OV_PROG:97.27,A_OV_STATUS:"greater than 95%",A_PG:97.27,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:23,PA_OV_COM:19,PA_OV_REM:4,PB1_OV_TOT:26,PB1_OV_COM:18,PB1_OV_REM:8,PB2_OV_TOT:34,PB2_OV_COM:14,PB2_OV_REM:20,ACT_TAG_VALID:58,PUNCH_NUMBER_VALID:83,PUNCH_TAG_VALID:26},
    {SUBSYSTEM:"21-8000-8011-02",SUBSYSTEM_DESCR:"Fresh Water Bunkering Header",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:29,A_OV_COM:11,A_OV_REM:18,A_OV_PROG:37.93,A_OV_STATUS:"less than 70%",A_PG:37.93,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8000-8012-01",SUBSYSTEM_DESCR:"Rehardener and UV Sterilizer",LOSH:"Y",MC_B30:"2022-02-10",A_OV_TOT:29,A_OV_COM:28,A_OV_REM:1,A_OV_PROG:96.55,A_OV_STATUS:"greater than 95%",A_PG:96.55,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-22",MC_APPROVED:"2022-02-07",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:5,PA_OV_COM:5,PA_OV_REM:0,PB1_OV_TOT:11,PB1_OV_COM:9,PB1_OV_REM:2,PB2_OV_TOT:9,PB2_OV_COM:7,PB2_OV_REM:2,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:25,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-8000-8012-02",SUBSYSTEM_DESCR:"Main Distribution and Potable Water Cooler",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:65,A_OV_COM:61,A_OV_REM:4,A_OV_PROG:93.84,A_OV_STATUS:"greater than 90%",A_PG:93.84,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-28",MC_APPROVED:"2022-02-28",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:15,PA_OV_COM:15,PA_OV_REM:0,PB1_OV_TOT:20,PB1_OV_COM:12,PB1_OV_REM:8,PB2_OV_TOT:15,PB2_OV_COM:7,PB2_OV_REM:8,ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:50,PUNCH_TAG_VALID:9},
    {SUBSYSTEM:"21-8000-8012-03",SUBSYSTEM_DESCR:"Cold Potable Water Distribution to HULL",LOSH:"Y",MC_B30:"2022-04-21",A_OV_TOT:152,A_OV_COM:125,A_OV_REM:27,A_OV_PROG:82.23,A_OV_STATUS:"greater than 80%",A_PG:82.23,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:3,PB1_OV_COM:2,PB1_OV_REM:1,PB2_OV_TOT:14,PB2_OV_COM:1,PB2_OV_REM:13,ACT_TAG_VALID:38,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:15},
    {SUBSYSTEM:"21-8000-8012-04",SUBSYSTEM_DESCR:"Cold Potable Water Distribution to LQ",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:18,A_OV_COM:17,A_OV_REM:1,A_OV_PROG:94.44,A_OV_STATUS:"greater than 90%",A_PG:94.44,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-15",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PB1_OV_TOT:4,PB1_OV_COM:3,PB1_OV_REM:1,PB2_OV_TOT:5,PB2_OV_COM:2,PB2_OV_REM:3,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-8000-8012-05",SUBSYSTEM_DESCR:"Cold Potable Water Distribution to Topsides ,Safety Showers and Eye Baths",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:131,A_OV_COM:35,A_OV_REM:96,A_OV_PROG:26.71,A_OV_STATUS:"less than 70%",A_PG:26.71,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:26,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8000-8013-01",SUBSYSTEM_DESCR:"Hot Water Calorifiers",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:95,A_OV_COM:70,A_OV_REM:25,A_OV_PROG:73.68,A_OV_STATUS:"NA",A_PG:73.68,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:24,PA_OV_COM:19,PA_OV_REM:5,PB1_OV_TOT:7,PB1_OV_COM:3,PB1_OV_REM:4,PB2_OV_TOT:7,PB2_OV_COM:3,PB2_OV_REM:4,ACT_TAG_VALID:31,PUNCH_NUMBER_VALID:38,PUNCH_TAG_VALID:7},
    {SUBSYSTEM:"21-8000-8013-02",SUBSYSTEM_DESCR:"Hot Water Distribution and Recirculation Pumps",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:226,A_OV_COM:172,A_OV_REM:54,A_OV_PROG:76.1,A_OV_STATUS:"NA",A_PG:76.1,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:12,PA_OV_COM:4,PA_OV_REM:8,PB1_OV_TOT:3,PB1_OV_COM:3,PB1_OV_REM:0,PB2_OV_TOT:6,PB2_OV_COM:3,PB2_OV_REM:3,ACT_TAG_VALID:63,PUNCH_NUMBER_VALID:21,PUNCH_TAG_VALID:15},
    {SUBSYSTEM:"21-8000-8014-01",SUBSYSTEM_DESCR:"Fresh Service Water Tank and Pumps",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-24",A_OV_TOT:189,A_OV_COM:188,A_OV_REM:1,A_OV_PROG:99.47,A_OV_STATUS:"greater than 95%",A_PG:99.47,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-11",MC_APPROVED:"2022-03-19",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:21,PA_OV_COM:21,PA_OV_REM:0,PB1_OV_TOT:31,PB1_OV_COM:29,PB1_OV_REM:2,PB2_OV_TOT:37,PB2_OV_COM:18,PB2_OV_REM:19,ACT_TAG_VALID:63,PUNCH_NUMBER_VALID:89,PUNCH_TAG_VALID:25},
    {SUBSYSTEM:"21-8000-8014-02",SUBSYSTEM_DESCR:"Fresh Service Water Distribution to HULL",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:16,A_OV_COM:16,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-28",MC_APPROVED:"2022-02-28",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:6,PA_OV_COM:6,PA_OV_REM:0,PB1_OV_TOT:15,PB1_OV_COM:14,PB1_OV_REM:1,PB2_OV_TOT:15,PB2_OV_COM:3,PB2_OV_REM:12,ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:36,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-8000-8014-03",SUBSYSTEM_DESCR:"Fresh Service Water Distribution to Topsides",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:144,A_OV_COM:29,A_OV_REM:115,A_OV_PROG:20.13,A_OV_STATUS:"less than 70%",A_PG:20.13,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:32,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8000-8014-04",SUBSYSTEM_DESCR:"Fresh Service Water Distribution to Water Mist System 21-SWM-613001",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-01-06",A_OV_TOT:13,A_OV_COM:13,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-12-23",WD_ACTUAL:"2021-12-24",MC_APPROVED:"2021-12-31",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8000-8014-05",SUBSYSTEM_DESCR:"Fresh Service Water Distribution to LQ",LOSH:"Y",MC_B30:"2022-02-17",A_OV_TOT:67,A_OV_COM:67,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-13",MC_APPROVED:"2022-02-15",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:3,PA_OV_REM:0,PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,PB2_OV_TOT:16,PB2_OV_COM:0,PB2_OV_REM:16,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:21,PUNCH_TAG_VALID:10},
    {SUBSYSTEM:"21-8020-8020-01",SUBSYSTEM_DESCR:"Marine Sea Water Sea Suction Tube and Filter 21-F-802001",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:33,A_OV_COM:33,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-14",MC_APPROVED:"2022-03-07",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P3",PA_OV_TOT:6,PA_OV_COM:6,PA_OV_REM:0,PB1_OV_TOT:18,PB1_OV_COM:18,PB1_OV_REM:0,PB2_OV_TOT:14,PB2_OV_COM:7,PB2_OV_REM:7,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:38,PUNCH_TAG_VALID:10},
    {SUBSYSTEM:"21-8020-8020-02",SUBSYSTEM_DESCR:"Marine Sea Water Sea Suction Tube and Filter 21-F-802002",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:32,A_OV_COM:32,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-14",MC_APPROVED:"2022-03-07",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P3",PA_OV_TOT:9,PA_OV_COM:9,PA_OV_REM:0,PB1_OV_TOT:8,PB1_OV_COM:8,PB1_OV_REM:0,PB2_OV_TOT:15,PB2_OV_COM:11,PB2_OV_REM:4,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:32,PUNCH_TAG_VALID:11},
    {SUBSYSTEM:"21-8020-8020-03",SUBSYSTEM_DESCR:"Marine Sea Water Main Distribution Header",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:36,A_OV_COM:36,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-15",MC_APPROVED:"2022-02-26",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1A",PA_OV_TOT:3,PA_OV_COM:3,PA_OV_REM:0,PB1_OV_TOT:18,PB1_OV_COM:18,PB1_OV_REM:0,PB2_OV_TOT:25,PB2_OV_COM:5,PB2_OV_REM:20,ACT_TAG_VALID:21,PUNCH_NUMBER_VALID:46,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-8020-8021-01",SUBSYSTEM_DESCR:"IGG Scrubber Cooling Sea Water Pumps - 21-P802003AandB",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:77,A_OV_COM:73,A_OV_REM:4,A_OV_PROG:94.8,A_OV_STATUS:"greater than 90%",A_PG:94.8,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-11",MC_APPROVED:"2022-03-12",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:7,PA_OV_COM:7,PA_OV_REM:0,PB1_OV_TOT:25,PB1_OV_COM:15,PB1_OV_REM:10,PB2_OV_TOT:13,PB2_OV_COM:7,PB2_OV_REM:6,ACT_TAG_VALID:38,PUNCH_NUMBER_VALID:45,PUNCH_TAG_VALID:14},
    {SUBSYSTEM:"21-8020-8021-02",SUBSYSTEM_DESCR:"IGG Scrubber Cooling Sea Water Distribution",LOSH:"Y",MC_B30:"2022-06-16",A_OV_TOT:29,A_OV_COM:11,A_OV_REM:18,A_OV_PROG:37.93,A_OV_STATUS:"less than 70%",A_PG:37.93,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8020-8023-01",SUBSYSTEM_DESCR:"IGG Deck Seal Sea Water Pumps - 21-P802002AandB",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:80,A_OV_COM:77,A_OV_REM:3,A_OV_PROG:96.25,A_OV_STATUS:"greater than 95%",A_PG:96.25,RFI_ISSUED:"Done",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,PB1_OV_TOT:12,PB1_OV_COM:10,PB1_OV_REM:2,PB2_OV_TOT:11,PB2_OV_COM:3,PB2_OV_REM:8,ACT_TAG_VALID:34,PUNCH_NUMBER_VALID:25,PUNCH_TAG_VALID:13},
    {SUBSYSTEM:"21-8020-8023-02",SUBSYSTEM_DESCR:"IGG Deck Seal Sea Water Distribution",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:33,A_OV_COM:14,A_OV_REM:19,A_OV_PROG:42.42,A_OV_STATUS:"less than 70%",A_PG:42.42,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8020-8024-01",SUBSYSTEM_DESCR:"HULL Sea Water Cooling Pumps - 21-P-802004AandB",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:75,A_OV_COM:75,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-07",MC_APPROVED:"2022-03-28",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:9,PA_OV_COM:9,PA_OV_REM:0,PB1_OV_TOT:43,PB1_OV_COM:35,PB1_OV_REM:8,PB2_OV_TOT:33,PB2_OV_COM:17,PB2_OV_REM:16,ACT_TAG_VALID:36,PUNCH_NUMBER_VALID:85,PUNCH_TAG_VALID:25},
    {SUBSYSTEM:"21-8020-8024-02",SUBSYSTEM_DESCR:"HULL Sea Water Cooling Header and Cooling Water Exchangers - 21-H-805001AandB",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:51,A_OV_COM:51,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-15",MC_APPROVED:"2022-03-31",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:6,PA_OV_COM:6,PA_OV_REM:0,PB1_OV_TOT:13,PB1_OV_COM:11,PB1_OV_REM:2,PB2_OV_TOT:25,PB2_OV_COM:11,PB2_OV_REM:14,ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:44,PUNCH_TAG_VALID:17},
    {SUBSYSTEM:"21-8020-8025-01",SUBSYSTEM_DESCR:"HULL Service Sea Water Pumps - 21-P-802005AandB",LOSH:"Y",MC_B30:"2022-03-24",A_OV_TOT:97,A_OV_COM:97,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-04-15",MC_APPROVED:"2022-03-12",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:14,PA_OV_COM:14,PA_OV_REM:0,PB1_OV_TOT:38,PB1_OV_COM:26,PB1_OV_REM:12,PB2_OV_TOT:37,PB2_OV_COM:11,PB2_OV_REM:26,ACT_TAG_VALID:46,PUNCH_NUMBER_VALID:89,PUNCH_TAG_VALID:25},
    {SUBSYSTEM:"21-8020-8026-01",SUBSYSTEM_DESCR:"HULL Service Sea Water to HVAC Cooling",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:80,A_OV_COM:60,A_OV_REM:20,A_OV_PROG:75,A_OV_STATUS:"NA",A_PG:75,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:15,PB1_OV_COM:12,PB1_OV_REM:3,PB2_OV_TOT:14,PB2_OV_COM:1,PB2_OV_REM:13,ACT_TAG_VALID:28,PUNCH_NUMBER_VALID:29,PUNCH_TAG_VALID:11},
    {SUBSYSTEM:"21-8020-8027-01",SUBSYSTEM_DESCR:"HULL Service Sea Water Booster Pumps - 21-P-802008AandB",LOSH:"Y",MC_B30:"2022-03-24",A_OV_TOT:111,A_OV_COM:110,A_OV_REM:1,A_OV_PROG:99.09,A_OV_STATUS:"greater than 95%",A_PG:99.09,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:20,PA_OV_COM:12,PA_OV_REM:8,PB1_OV_TOT:28,PB1_OV_COM:15,PB1_OV_REM:13,PB2_OV_TOT:28,PB2_OV_COM:5,PB2_OV_REM:23,ACT_TAG_VALID:40,PUNCH_NUMBER_VALID:76,PUNCH_TAG_VALID:17},
    {SUBSYSTEM:"21-8020-8027-02",SUBSYSTEM_DESCR:"HULL Service Sea Water to Electrochlorination Package",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:12,A_OV_COM:6,A_OV_REM:6,A_OV_PROG:50,A_OV_STATUS:"less than 70%",A_PG:50,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",PB1_OV_TOT:5,PB1_OV_COM:5,PB1_OV_REM:0,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8020-8027-03",SUBSYSTEM_DESCR:"HULL Service Sea Water to Potable Water Package",LOSH:"Y",MC_B30:"2022-03-24",A_OV_TOT:12,A_OV_COM:11,A_OV_REM:1,A_OV_PROG:91.66,A_OV_STATUS:"greater than 90%",A_PG:91.66,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:5,PB1_OV_COM:4,PB1_OV_REM:1,PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8020-8029-01",SUBSYSTEM_DESCR:"KSG Cooling Sea Water Pumps 21-P-802009AandB and Distribution",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:97,A_OV_COM:97,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-16",MC_APPROVED:"2022-02-25",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1A",PA_OV_TOT:10,PA_OV_COM:10,PA_OV_REM:0,PB1_OV_TOT:46,PB1_OV_COM:46,PB1_OV_REM:0,PB2_OV_TOT:66,PB2_OV_COM:24,PB2_OV_REM:42,ACT_TAG_VALID:49,PUNCH_NUMBER_VALID:122,PUNCH_TAG_VALID:31},
    {SUBSYSTEM:"21-8040-8040-01",SUBSYSTEM_DESCR:"Heating Medium ,Hot Water Expansion Tank",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:131,A_OV_COM:47,A_OV_REM:84,A_OV_PROG:35.87,A_OV_STATUS:"less than 70%",A_PG:35.87,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,PB2_OV_TOT:4,PB2_OV_COM:0,PB2_OV_REM:4,ACT_TAG_VALID:51,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-8040-8040-02",SUBSYSTEM_DESCR:"Heating Medium ,Hot Water Pumps and Loop and Distribution to Exchangers",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:767,A_OV_COM:280,A_OV_REM:487,A_OV_PROG:36.5,A_OV_STATUS:"less than 70%",A_PG:36.5,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:280,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-8050-8051-01",SUBSYSTEM_DESCR:"Marine Cooling Medium Pumps - 21-P805002AandB",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-24",A_OV_TOT:49,A_OV_COM:45,A_OV_REM:4,A_OV_PROG:91.83,A_OV_STATUS:"greater than 90%",A_PG:91.83,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-22",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:19,PA_OV_COM:15,PA_OV_REM:4,PB1_OV_TOT:20,PB1_OV_COM:15,PB1_OV_REM:5,PB2_OV_TOT:21,PB2_OV_COM:8,PB2_OV_REM:13,ACT_TAG_VALID:23,PUNCH_NUMBER_VALID:60,PUNCH_TAG_VALID:14},
    {SUBSYSTEM:"21-8050-8051-02",SUBSYSTEM_DESCR:"Marine Cooling Medium Expansion Tank",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-24",A_OV_TOT:59,A_OV_COM:59,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-23",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:7,PA_OV_COM:5,PA_OV_REM:2,PB1_OV_TOT:20,PB1_OV_COM:6,PB1_OV_REM:14,PB2_OV_TOT:11,PB2_OV_COM:1,PB2_OV_REM:10,ACT_TAG_VALID:19,PUNCH_NUMBER_VALID:38,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-8050-8051-03",SUBSYSTEM_DESCR:"Marine Cooling Medium Distribution",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-24",A_OV_TOT:133,A_OV_COM:133,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-25",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:7,PA_OV_COM:7,PA_OV_REM:0,PB1_OV_TOT:18,PB1_OV_COM:13,PB1_OV_REM:5,PB2_OV_TOT:34,PB2_OV_COM:12,PB2_OV_REM:22,ACT_TAG_VALID:41,PUNCH_NUMBER_VALID:59,PUNCH_TAG_VALID:19},
    {SUBSYSTEM:"21-8050-8052-01",SUBSYSTEM_DESCR:"Topisdes Cooling Medium Pumps - 21-P805001AandB",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:155,A_OV_COM:34,A_OV_REM:121,A_OV_PROG:21.93,A_OV_STATUS:"less than 70%",A_PG:21.93,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:9,PA_OV_COM:6,PA_OV_REM:3,PB1_OV_TOT:3,PB1_OV_COM:1,PB1_OV_REM:2,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:85,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-8050-8052-02",SUBSYSTEM_DESCR:"Start-up Cooling Medium Pumps - 21-P805003",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:57,A_OV_COM:21,A_OV_REM:36,A_OV_PROG:36.84,A_OV_STATUS:"less than 70%",A_PG:36.84,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:23,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8050-8052-03",SUBSYSTEM_DESCR:"Topsides Cooling Medium Expansion Tank",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:118,A_OV_COM:36,A_OV_REM:82,A_OV_PROG:30.5,A_OV_STATUS:"less than 70%",A_PG:30.5,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:49,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8050-8052-04",SUBSYSTEM_DESCR:"Topsides Cooling Medium Distribution and Exchangers",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:538,A_OV_COM:166,A_OV_REM:372,A_OV_PROG:30.85,A_OV_STATUS:"less than 70%",A_PG:30.85,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P1",PB2_OV_TOT:9,PB2_OV_COM:0,PB2_OV_REM:9,ACT_TAG_VALID:188,PUNCH_NUMBER_VALID:9,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-8060-8061-01",SUBSYSTEM_DESCR:"Diesel Bunker Header",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-10",A_OV_TOT:38,A_OV_COM:36,A_OV_REM:2,A_OV_PROG:94.73,A_OV_STATUS:"greater than 90%",A_PG:94.73,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-07",MC_APPROVED:"2022-03-18",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1",PA_OV_TOT:18,PA_OV_COM:18,PA_OV_REM:0,PB1_OV_TOT:2,PB1_OV_COM:2,PB1_OV_REM:0,PB2_OV_TOT:51,PB2_OV_COM:1,PB2_OV_REM:50,ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:71,PUNCH_TAG_VALID:11},
    {SUBSYSTEM:"21-8060-8061-02",SUBSYSTEM_DESCR:"Diesel Storage Tanks - 21-T-806001and2 and Purifier Pumps - 21-P-806002AandB",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:214,A_OV_COM:214,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-12",MC_APPROVED:"2022-03-01",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:12,PA_OV_COM:12,PA_OV_REM:0,PB1_OV_TOT:64,PB1_OV_COM:63,PB1_OV_REM:1,PB2_OV_TOT:108,PB2_OV_COM:34,PB2_OV_REM:74,ACT_TAG_VALID:74,PUNCH_NUMBER_VALID:184,PUNCH_TAG_VALID:49},
    {SUBSYSTEM:"21-8060-8061-03",SUBSYSTEM_DESCR:"Diesel Supply and Return ,Spill Back Headers to Service Tanks - 21-T-806003and4",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:63,A_OV_COM:48,A_OV_REM:15,A_OV_PROG:76.19,A_OV_STATUS:"NA",A_PG:76.19,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-04",MC_APPROVED:"2022-02-25",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1",PB1_OV_TOT:2,PB1_OV_COM:2,PB1_OV_REM:0,PB2_OV_TOT:13,PB2_OV_COM:5,PB2_OV_REM:8,ACT_TAG_VALID:13,PUNCH_NUMBER_VALID:15,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-8060-8062-01",SUBSYSTEM_DESCR:"Diesel Service Tanks - 21-T-806003and4 and Supply Pumps - 21-P-806001AandB",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:269,A_OV_COM:266,A_OV_REM:3,A_OV_PROG:98.88,A_OV_STATUS:"greater than 95%",A_PG:98.88,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-12",MC_APPROVED:"2022-02-28",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:22,PA_OV_COM:21,PA_OV_REM:1,PB1_OV_TOT:81,PB1_OV_COM:81,PB1_OV_REM:0,PB2_OV_TOT:107,PB2_OV_COM:50,PB2_OV_REM:57,ACT_TAG_VALID:93,PUNCH_NUMBER_VALID:210,PUNCH_TAG_VALID:70},
    {SUBSYSTEM:"21-8060-8062-02",SUBSYSTEM_DESCR:"Purifier Package",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-24",A_OV_TOT:28,A_OV_COM:28,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2022-01-06",WD_ACTUAL:"2022-01-07",MC_APPROVED:"2022-02-23",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1",PA_OV_TOT:24,PA_OV_COM:24,PA_OV_REM:0,PB1_OV_TOT:13,PB1_OV_COM:13,PB1_OV_REM:0,PB2_OV_TOT:17,PB2_OV_COM:12,PB2_OV_REM:5,ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:54,PUNCH_TAG_VALID:18},
    {SUBSYSTEM:"21-8060-8063-01",SUBSYSTEM_DESCR:"Diesel Distribution Header to Users",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:254,A_OV_COM:116,A_OV_REM:138,A_OV_PROG:45.66,A_OV_STATUS:"less than 70%",A_PG:45.66,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,ACT_TAG_VALID:73,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8090-8090-01",SUBSYSTEM_DESCR:"Sewage Treatment Package",MC_B30:"2022-05-05",A_OV_TOT:20,A_OV_COM:17,A_OV_REM:3,A_OV_PROG:85,A_OV_STATUS:"greater than 80%",A_PG:85,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:15,PA_OV_COM:14,PA_OV_REM:1,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,PB2_OV_TOT:9,PB2_OV_COM:1,PB2_OV_REM:8,ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:27,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-8090-8090-02",SUBSYSTEM_DESCR:"Sewage Collection and Discharge Headers",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:369,A_OV_COM:281,A_OV_REM:88,A_OV_PROG:76.15,A_OV_STATUS:"NA",A_PG:76.15,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P3",PB1_OV_TOT:4,PB1_OV_COM:1,PB1_OV_REM:3,PB2_OV_TOT:8,PB2_OV_COM:0,PB2_OV_REM:8,ACT_TAG_VALID:71,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:10},
    {SUBSYSTEM:"21-8100-8110-01",SUBSYSTEM_DESCR:"HULL Plant Air Distribution",LOSH:"Y",MC_B30:"2022-02-03",A_OV_TOT:101,A_OV_COM:89,A_OV_REM:12,A_OV_PROG:88.11,A_OV_STATUS:"greater than 80%",A_PG:88.11,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-12",MC_APPROVED:"2022-01-28",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P3",PB1_OV_TOT:3,PB1_OV_COM:3,PB1_OV_REM:0,PB2_OV_TOT:29,PB2_OV_COM:0,PB2_OV_REM:29,ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:32,PUNCH_TAG_VALID:8},
    {SUBSYSTEM:"21-8100-8111-01",SUBSYSTEM_DESCR:"Topsides Plant Air Distribution",LOSH:"Y",MC_B30:"2022-07-28",A_OV_TOT:97,A_OV_COM:18,A_OV_REM:79,A_OV_PROG:18.55,A_OV_STATUS:"less than 70%",A_PG:18.55,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8100-8120-01",SUBSYSTEM_DESCR:"HULL Air Compressor 21-Z-811002",BATCH:"Incentive 1",LOSH:"Y",MC_B30:"2022-02-24",A_OV_TOT:53,A_OV_COM:53,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2022-01-06",WD_ACTUAL:"2022-01-07",MC_APPROVED:"2022-02-19",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:14,PA_OV_COM:14,PA_OV_REM:0,PB1_OV_TOT:27,PB1_OV_COM:21,PB1_OV_REM:6,PB2_OV_TOT:9,PB2_OV_COM:4,PB2_OV_REM:5,PB3_OV_TOT:2,PB3_OV_COM:0,PB3_OV_REM:2,ACT_TAG_VALID:33,PUNCH_NUMBER_VALID:52,PUNCH_TAG_VALID:18},
    {SUBSYSTEM:"21-8100-8120-02",SUBSYSTEM_DESCR:"HULL Air Drier 21-Z-812020",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:29,A_OV_COM:29,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-12-09",WD_ACTUAL:"2021-12-10",MC_APPROVED:"2022-02-25",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:8,PA_OV_COM:8,PA_OV_REM:0,PB1_OV_TOT:15,PB1_OV_COM:14,PB1_OV_REM:1,PB2_OV_TOT:10,PB2_OV_COM:9,PB2_OV_REM:1,ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:33,PUNCH_TAG_VALID:15},
    {SUBSYSTEM:"21-8100-8120-03",SUBSYSTEM_DESCR:"HULL Air Receiver 21-V-812002, Main header and Controls",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-24",A_OV_TOT:64,A_OV_COM:64,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2022-01-07",WD_ACTUAL:"2022-01-10",MC_APPROVED:"2022-02-19",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1A",PA_OV_TOT:5,PA_OV_COM:5,PA_OV_REM:0,PB1_OV_TOT:15,PB1_OV_COM:15,PB1_OV_REM:0,PB2_OV_TOT:6,PB2_OV_COM:5,PB2_OV_REM:1,ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:26,PUNCH_TAG_VALID:11},
    {SUBSYSTEM:"21-8100-8121-01",SUBSYSTEM_DESCR:"HULL Instrument Air Distribution",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:405,A_OV_COM:351,A_OV_REM:54,A_OV_PROG:86.66,A_OV_STATUS:"greater than 80%",A_PG:86.66,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-08",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P1A",PA_OV_TOT:12,PA_OV_COM:12,PA_OV_REM:0,PB1_OV_TOT:24,PB1_OV_COM:24,PB1_OV_REM:0,PB2_OV_TOT:118,PB2_OV_COM:10,PB2_OV_REM:108,ACT_TAG_VALID:95,PUNCH_NUMBER_VALID:154,PUNCH_TAG_VALID:47},
    {SUBSYSTEM:"21-8100-8122-01",SUBSYSTEM_DESCR:"Topside Air Compressor 21-Z-811101",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:75,A_OV_COM:26,A_OV_REM:49,A_OV_PROG:34.66,A_OV_STATUS:"less than 70%",A_PG:34.66,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:3,PB1_OV_COM:1,PB1_OV_REM:2,PB2_OV_TOT:7,PB2_OV_COM:0,PB2_OV_REM:7,ACT_TAG_VALID:51,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8100-8122-02",SUBSYSTEM_DESCR:"Topside Air Compressor 21-Z-811201",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:53,A_OV_COM:19,A_OV_REM:34,A_OV_PROG:35.84,A_OV_STATUS:"less than 70%",A_PG:35.84,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:4,PA_OV_COM:1,PA_OV_REM:3,PB1_OV_TOT:5,PB1_OV_COM:1,PB1_OV_REM:4,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:29,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8100-8122-03",SUBSYSTEM_DESCR:"Topside Air Compressor 21-Z-811301",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:52,A_OV_COM:20,A_OV_REM:32,A_OV_PROG:38.46,A_OV_STATUS:"less than 70%",A_PG:38.46,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:28,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8100-8122-04",SUBSYSTEM_DESCR:"Topside Air Driers 21-Z-812001",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:96,A_OV_COM:21,A_OV_REM:75,A_OV_PROG:21.87,A_OV_STATUS:"less than 70%",A_PG:21.87,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PB1_OV_TOT:7,PB1_OV_COM:0,PB1_OV_REM:7,ACT_TAG_VALID:59,PUNCH_NUMBER_VALID:9,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8100-8122-05",SUBSYSTEM_DESCR:"Topside Air Receiver 21-V-812001, Main header and Controls",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:104,A_OV_COM:52,A_OV_REM:52,A_OV_PROG:50,A_OV_STATUS:"less than 70%",A_PG:50,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P1",PB2_OV_TOT:10,PB2_OV_COM:0,PB2_OV_REM:10,ACT_TAG_VALID:38,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-8100-8123-01",SUBSYSTEM_DESCR:"Topsides Instrument Air Distribution",LOSH:"Y",MC_B30:"2022-06-16",A_OV_TOT:402,A_OV_COM:103,A_OV_REM:299,A_OV_PROG:25.62,A_OV_STATUS:"less than 70%",A_PG:25.62,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:110,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8130-8130-01",SUBSYSTEM_DESCR:"Fuel Gas KO Drum",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:195,A_OV_COM:51,A_OV_REM:144,A_OV_PROG:26.15,A_OV_STATUS:"less than 70%",A_PG:26.15,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P3",PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:73,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8130-8130-02",SUBSYSTEM_DESCR:"Fuel Gas Superheaters",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:119,A_OV_COM:29,A_OV_REM:90,A_OV_PROG:24.36,A_OV_STATUS:"less than 70%",A_PG:24.36,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:58,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8130-8131-01",SUBSYSTEM_DESCR:"Fuel Gas Blanketing Distribution in HULL",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:160,A_OV_COM:27,A_OV_REM:133,A_OV_PROG:16.87,A_OV_STATUS:"less than 70%",A_PG:16.87,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:6,PA_OV_COM:0,PA_OV_REM:6,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:68,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-8130-8131-02",SUBSYSTEM_DESCR:"Fuel Gas Filters and Main Header",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:96,A_OV_COM:30,A_OV_REM:66,A_OV_PROG:31.25,A_OV_STATUS:"less than 70%",A_PG:31.25,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:31,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8130-8131-03",SUBSYSTEM_DESCR:"LP Fuel Gas Distribution Topsides",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:286,A_OV_COM:78,A_OV_REM:208,A_OV_PROG:27.27,A_OV_STATUS:"less than 70%",A_PG:27.27,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P3",PB2_OV_TOT:6,PB2_OV_COM:0,PB2_OV_REM:6,ACT_TAG_VALID:103,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-8130-8131-04",SUBSYSTEM_DESCR:"Fuel Gas Distribution to GTGs",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:105,A_OV_COM:24,A_OV_REM:81,A_OV_PROG:22.85,A_OV_STATUS:"less than 70%",A_PG:22.85,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:39,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8150-8150-01",SUBSYSTEM_DESCR:"Nitrogen Generator and Receiver",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:99,A_OV_COM:43,A_OV_REM:56,A_OV_PROG:43.43,A_OV_STATUS:"less than 70%",A_PG:43.43,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:2,PA_OV_REM:1,PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,PB2_OV_TOT:8,PB2_OV_COM:0,PB2_OV_REM:8,ACT_TAG_VALID:45,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-8150-8150-02",SUBSYSTEM_DESCR:"Topside Nitrogen Distribution",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:336,A_OV_COM:83,A_OV_REM:253,A_OV_PROG:24.7,A_OV_STATUS:"less than 70%",A_PG:24.7,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:7,PA_OV_COM:0,PA_OV_REM:7,PB2_OV_TOT:5,PB2_OV_COM:0,PB2_OV_REM:5,ACT_TAG_VALID:91,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8160-8160-01",SUBSYSTEM_DESCR:"Inert Gas Generator",LOSH:"Y",MC_B30:"2022-07-07",A_OV_TOT:231,A_OV_COM:16,A_OV_REM:215,A_OV_PROG:6.92,A_OV_STATUS:"less than 70%",A_PG:6.92,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:9,PA_OV_COM:1,PA_OV_REM:8,PB1_OV_TOT:5,PB1_OV_COM:1,PB1_OV_REM:4,PB2_OV_TOT:4,PB2_OV_COM:0,PB2_OV_REM:4,ACT_TAG_VALID:163,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-8160-8160-03",SUBSYSTEM_DESCR:"IGG Header",LOSH:"Y",MC_B30:"2022-06-30",A_OV_TOT:138,A_OV_COM:28,A_OV_REM:110,A_OV_PROG:20.28,A_OV_STATUS:"less than 70%",A_PG:20.28,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:6,PA_OV_COM:0,PA_OV_REM:6,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:56,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-8300-8310-01",SUBSYSTEM_DESCR:"Corrosion Inhibitor Tank and Pumps",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:56,A_OV_COM:15,A_OV_REM:41,A_OV_PROG:26.78,A_OV_STATUS:"less than 70%",A_PG:26.78,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:7,PA_OV_COM:5,PA_OV_REM:2,PB1_OV_TOT:5,PB1_OV_COM:4,PB1_OV_REM:1,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:29,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8300-8310-02",SUBSYSTEM_DESCR:"Corrosion Inhibitor Distribution",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:31,A_OV_COM:11,A_OV_REM:20,A_OV_PROG:35.48,A_OV_STATUS:"less than 70%",A_PG:35.48,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8320-01",SUBSYSTEM_DESCR:"Scale Inhibitor Tank and Pumps Topsides",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:38,A_OV_COM:16,A_OV_REM:22,A_OV_PROG:42.1,A_OV_STATUS:"less than 70%",A_PG:42.1,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8320-02",SUBSYSTEM_DESCR:"Scale Inhibitor Distribution Subsea",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:36,A_OV_COM:6,A_OV_REM:30,A_OV_PROG:16.66,A_OV_STATUS:"less than 70%",A_PG:16.66,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8320-03",SUBSYSTEM_DESCR:"Scale Inhibitor Distribution Topsides",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:55,A_OV_COM:9,A_OV_REM:46,A_OV_PROG:16.36,A_OV_STATUS:"less than 70%",A_PG:16.36,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8320-04",SUBSYSTEM_DESCR:"Scale Inhibitor Tank and Pumps Subsea",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:31,A_OV_COM:5,A_OV_REM:26,A_OV_PROG:16.12,A_OV_STATUS:"less than 70%",A_PG:16.12,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,ACT_TAG_VALID:23,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8300-8330-01",SUBSYSTEM_DESCR:"Wax Inhibitor Loading Header",LOSH:"Y",MC_B30:"2022-06-16",A_OV_TOT:35,A_OV_COM:6,A_OV_REM:29,A_OV_PROG:17.14,A_OV_STATUS:"less than 70%",A_PG:17.14,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:19,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8330-02",SUBSYSTEM_DESCR:"Wax Inhibitor Storage and Pumps",LOSH:"Y",MC_B30:"2022-06-16",A_OV_TOT:130,A_OV_COM:55,A_OV_REM:75,A_OV_PROG:42.3,A_OV_STATUS:"less than 70%",A_PG:42.3,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PB1_OV_TOT:8,PB1_OV_COM:8,PB1_OV_REM:0,PB2_OV_TOT:5,PB2_OV_COM:1,PB2_OV_REM:4,ACT_TAG_VALID:43,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8300-8331-01",SUBSYSTEM_DESCR:"Wax Inhibitor Filtration",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:51,A_OV_COM:14,A_OV_REM:37,A_OV_PROG:27.45,A_OV_STATUS:"less than 70%",A_PG:27.45,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8331-02",SUBSYSTEM_DESCR:"Wax Inhibitor Break Tank and Injection Pumps",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:285,A_OV_COM:108,A_OV_REM:177,A_OV_PROG:37.89,A_OV_STATUS:"less than 70%",A_PG:37.89,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PB2_OV_TOT:5,PB2_OV_COM:0,PB2_OV_REM:5,ACT_TAG_VALID:99,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-8300-8332-01",SUBSYSTEM_DESCR:"Wax Inhibitor Injection Subsea Phase 1",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:30,A_OV_COM:3,A_OV_REM:27,A_OV_PROG:10,A_OV_STATUS:"less than 70%",A_PG:10,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8340-01",SUBSYSTEM_DESCR:"Biocide Tank and Pumps",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:72,A_OV_COM:29,A_OV_REM:43,A_OV_PROG:40.27,A_OV_STATUS:"less than 70%",A_PG:40.27,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:14,PA_OV_COM:13,PA_OV_REM:1,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,PB2_OV_TOT:5,PB2_OV_COM:0,PB2_OV_REM:5,ACT_TAG_VALID:35,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-8300-8340-02",SUBSYSTEM_DESCR:"Biocide Distribution HULL and Topsides",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:111,A_OV_COM:32,A_OV_REM:79,A_OV_PROG:28.82,A_OV_STATUS:"less than 70%",A_PG:28.82,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:31,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8350-01",SUBSYSTEM_DESCR:"Antifoam Tank and Pumps",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:44,A_OV_COM:10,A_OV_REM:34,A_OV_PROG:22.72,A_OV_STATUS:"less than 70%",A_PG:22.72,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:28,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8350-02",SUBSYSTEM_DESCR:"Antifoam Distribution",LOSH:"Y",MC_B30:"2022-06-16",A_OV_TOT:37,A_OV_COM:8,A_OV_REM:29,A_OV_PROG:21.62,A_OV_STATUS:"less than 70%",A_PG:21.62,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8360-01",SUBSYSTEM_DESCR:"Emulsion Breaker Tank and Pumps",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:50,A_OV_COM:23,A_OV_REM:27,A_OV_PROG:46,A_OV_STATUS:"less than 70%",A_PG:46,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PB2_OV_TOT:4,PB2_OV_COM:0,PB2_OV_REM:4,ACT_TAG_VALID:26,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8300-8360-02",SUBSYSTEM_DESCR:"Emulsion Breaker Distribution",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:37,A_OV_COM:6,A_OV_REM:31,A_OV_PROG:16.21,A_OV_STATUS:"less than 70%",A_PG:16.21,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8370-01",SUBSYSTEM_DESCR:"MEG PH Stabilizer Tank and Pumps",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:35,A_OV_COM:17,A_OV_REM:18,A_OV_PROG:48.57,A_OV_STATUS:"less than 70%",A_PG:48.57,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8300-8370-02",SUBSYSTEM_DESCR:"MEG PH Stabilizer Distribution",LOSH:"Y",MC_B30:"2022-06-16",A_OV_TOT:19,A_OV_COM:6,A_OV_REM:13,A_OV_PROG:31.57,A_OV_STATUS:"less than 70%",A_PG:31.57,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8375-01",SUBSYSTEM_DESCR:"MEG Antifoam Tank and Pumps",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:36,A_OV_COM:16,A_OV_REM:20,A_OV_PROG:44.44,A_OV_STATUS:"less than 70%",A_PG:44.44,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8300-8375-02",SUBSYSTEM_DESCR:"MEG Antifoam Distribution",LOSH:"Y",MC_B30:"2022-06-16",A_OV_TOT:37,A_OV_COM:8,A_OV_REM:29,A_OV_PROG:21.62,A_OV_STATUS:"less than 70%",A_PG:21.62,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8380-01",SUBSYSTEM_DESCR:"Spare and Future Tanks",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:4,A_OV_COM:0,A_OV_REM:4,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8400-8410-01",SUBSYSTEM_DESCR:"O2 Scavanger Tank and Pumps",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:43,A_OV_COM:22,A_OV_REM:21,A_OV_PROG:51.16,A_OV_STATUS:"less than 70%",A_PG:51.16,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PB2_OV_TOT:5,PB2_OV_COM:0,PB2_OV_REM:5,ACT_TAG_VALID:19,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8400-8410-02",SUBSYSTEM_DESCR:"O2 Scavanger Distribution HULL and Topsides",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:34,A_OV_COM:6,A_OV_REM:28,A_OV_PROG:17.64,A_OV_STATUS:"less than 70%",A_PG:17.64,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8600-8611-01",SUBSYSTEM_DESCR:"GTG 1 and WHRU1",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:641,A_OV_COM:143,A_OV_REM:498,A_OV_PROG:22.3,A_OV_STATUS:"less than 70%",A_PG:22.3,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:5,PA_OV_COM:3,PA_OV_REM:2,PB1_OV_TOT:12,PB1_OV_COM:2,PB1_OV_REM:10,PB2_OV_TOT:4,PB2_OV_COM:1,PB2_OV_REM:3,ACT_TAG_VALID:446,PUNCH_NUMBER_VALID:21,PUNCH_TAG_VALID:7},
    {SUBSYSTEM:"21-8600-8612-01",SUBSYSTEM_DESCR:"GTG 2 and WHRU2",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:639,A_OV_COM:154,A_OV_REM:485,A_OV_PROG:24.1,A_OV_STATUS:"less than 70%",A_PG:24.1,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,PB1_OV_TOT:2,PB1_OV_COM:2,PB1_OV_REM:0,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:441,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-8600-8613-01",SUBSYSTEM_DESCR:"GTG 3 and WHRU3",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:647,A_OV_COM:162,A_OV_REM:485,A_OV_PROG:25.03,A_OV_STATUS:"less than 70%",A_PG:25.03,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,PB1_OV_TOT:2,PB1_OV_COM:2,PB1_OV_REM:0,PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:443,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-8600-8614-01",SUBSYSTEM_DESCR:"GTG 4 and WHRU4",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:630,A_OV_COM:154,A_OV_REM:476,A_OV_PROG:24.44,A_OV_STATUS:"less than 70%",A_PG:24.44,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,PB1_OV_TOT:2,PB1_OV_COM:2,PB1_OV_REM:0,ACT_TAG_VALID:437,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-8700-8760-01",SUBSYSTEM_DESCR:"Topside Normal 690 V Swicthboard 21-ESB-876001",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:162,A_OV_COM:156,A_OV_REM:6,A_OV_PROG:96.29,A_OV_STATUS:"greater than 95%",A_PG:96.29,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:14,PB1_OV_COM:8,PB1_OV_REM:6,PB2_OV_TOT:4,PB2_OV_COM:4,PB2_OV_REM:0,ACT_TAG_VALID:162,PUNCH_NUMBER_VALID:19,PUNCH_TAG_VALID:8},
    {SUBSYSTEM:"21-8700-8770-01",SUBSYSTEM_DESCR:"Topsides Main Normal HV Switchboard 6.6kV 21-ESB-877001",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:105,A_OV_COM:64,A_OV_REM:41,A_OV_PROG:60.95,A_OV_STATUS:"less than 70%",A_PG:60.95,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:10,PA_OV_COM:8,PA_OV_REM:2,PB1_OV_TOT:11,PB1_OV_COM:2,PB1_OV_REM:9,ACT_TAG_VALID:105,PUNCH_NUMBER_VALID:21,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-8780-8780-01",SUBSYSTEM_DESCR:"Earthing HULL",BATCH:"Incentive 2",MC_B30:"2022-05-05",A_OV_TOT:28,A_OV_COM:4,A_OV_REM:24,A_OV_PROG:14.28,A_OV_STATUS:"less than 70%",A_PG:14.28,RFI_ISSUED:"2021-09-23",WD_ACTUAL:"2021-09-24",MC_APPROVED:"2022-02-11",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"N",PRIORITY:"P3",PA_OV_TOT:5,PA_OV_COM:5,PA_OV_REM:0,PB1_OV_TOT:17,PB1_OV_COM:17,PB1_OV_REM:0,ACT_TAG_VALID:28,PUNCH_NUMBER_VALID:22,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8780-8780-02",SUBSYSTEM_DESCR:"Earthing LQ",BATCH:"Incentive 2",MC_B30:"2022-05-26",A_OV_TOT:12,A_OV_COM:3,A_OV_REM:9,A_OV_PROG:25,A_OV_STATUS:"less than 70%",A_PG:25,RFI_ISSUED:"2021-10-22",WD_ACTUAL:"2022-03-30",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P3",PA_OV_TOT:9,PA_OV_COM:9,PA_OV_REM:0,PB1_OV_TOT:9,PB1_OV_COM:7,PB1_OV_REM:2,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8780-8780-03",SUBSYSTEM_DESCR:"Earthing Topsides",BATCH:"Incentive 2",MC_B30:"2022-02-17",A_OV_TOT:56,A_OV_COM:5,A_OV_REM:51,A_OV_PROG:8.92,A_OV_STATUS:"less than 70%",A_PG:8.92,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-24",MC_APPROVED:"2022-02-17",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:6,PA_OV_COM:5,PA_OV_REM:1,PB1_OV_TOT:10,PB1_OV_COM:5,PB1_OV_REM:5,ACT_TAG_VALID:56,PUNCH_NUMBER_VALID:16,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-8800-8800-01",SUBSYSTEM_DESCR:"Ses Water Lift Pump A",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:85,A_OV_COM:16,A_OV_REM:69,A_OV_PROG:18.82,A_OV_STATUS:"less than 70%",A_PG:18.82,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:35,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-8800-8800-02",SUBSYSTEM_DESCR:"Ses Water Lift Pump B",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:85,A_OV_COM:16,A_OV_REM:69,A_OV_PROG:18.82,A_OV_STATUS:"less than 70%",A_PG:18.82,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:35,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8800-8800-03",SUBSYSTEM_DESCR:"Ses Water Lift Pump C",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:92,A_OV_COM:17,A_OV_REM:75,A_OV_PROG:18.47,A_OV_STATUS:"less than 70%",A_PG:18.47,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:36,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8800-8800-04",SUBSYSTEM_DESCR:"Sea Water Filter and Distribution Header",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:137,A_OV_COM:25,A_OV_REM:112,A_OV_PROG:18.24,A_OV_STATUS:"less than 70%",A_PG:18.24,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:56,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8800-8800-05",SUBSYSTEM_DESCR:"Sea Water Cooling Supply to Exchangers and Discharge Header",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:145,A_OV_COM:70,A_OV_REM:75,A_OV_PROG:48.27,A_OV_STATUS:"less than 70%",A_PG:48.27,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P3",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:37,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8800-8805-01",SUBSYSTEM_DESCR:"Electrochlorination Unit",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:157,A_OV_COM:48,A_OV_REM:109,A_OV_PROG:30.57,A_OV_STATUS:"less than 70%",A_PG:30.57,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:7,PA_OV_COM:4,PA_OV_REM:3,PB1_OV_TOT:10,PB1_OV_COM:4,PB1_OV_REM:6,PB2_OV_TOT:6,PB2_OV_COM:0,PB2_OV_REM:6,ACT_TAG_VALID:116,PUNCH_NUMBER_VALID:23,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-8800-8805-02",SUBSYSTEM_DESCR:"Hypochlorite Distribution Topsides",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:130,A_OV_COM:50,A_OV_REM:80,A_OV_PROG:38.46,A_OV_STATUS:"less than 70%",A_PG:38.46,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P3",PB1_OV_TOT:16,PB1_OV_COM:13,PB1_OV_REM:3,PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:50,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:7},
    {SUBSYSTEM:"21-9130-9130-01",SUBSYSTEM_DESCR:"ME01 Area Completion",LOSH:"Y",MC_B30:"2022-08-11",A_OV_TOT:45,A_OV_COM:10,A_OV_REM:35,A_OV_PROG:22.22,A_OV_STATUS:"less than 70%",A_PG:22.22,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:19,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9130-9130-02",SUBSYSTEM_DESCR:"ME02 Area Completion",LOSH:"Y",MC_B30:"2022-08-11",A_OV_TOT:23,A_OV_COM:7,A_OV_REM:16,A_OV_PROG:30.43,A_OV_STATUS:"less than 70%",A_PG:30.43,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9130-9130-03",SUBSYSTEM_DESCR:"ME03 Area Completion",MC_B30:"2022-07-14",A_OV_STATUS:"NA",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9130-9130-04",SUBSYSTEM_DESCR:"ME04 Area Completion",LOSH:"Y",MC_B30:"2022-07-21",A_OV_TOT:20,A_OV_COM:5,A_OV_REM:15,A_OV_PROG:25,A_OV_STATUS:"less than 70%",A_PG:25,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9130-9130-05",SUBSYSTEM_DESCR:"ME05 Area Completion",LOSH:"Y",MC_B30:"2022-07-21",A_OV_TOT:46,A_OV_COM:11,A_OV_REM:35,A_OV_PROG:23.91,A_OV_STATUS:"less than 70%",A_PG:23.91,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9130-9130-06",SUBSYSTEM_DESCR:"MW01 Area Completion",LOSH:"Y",MC_B30:"2022-08-11",A_OV_TOT:57,A_OV_COM:12,A_OV_REM:45,A_OV_PROG:21.05,A_OV_STATUS:"less than 70%",A_PG:21.05,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9130-9130-07",SUBSYSTEM_DESCR:"MW02 Area Completion",LOSH:"Y",MC_B30:"2022-08-11",A_OV_TOT:44,A_OV_COM:11,A_OV_REM:33,A_OV_PROG:25,A_OV_STATUS:"less than 70%",A_PG:25,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9130-9130-08",SUBSYSTEM_DESCR:"MW03 Area Completion",LOSH:"Y",MC_B30:"2022-07-14",A_OV_STATUS:"NA",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9130-9130-09",SUBSYSTEM_DESCR:"MW04 Area Completion",LOSH:"Y",MC_B30:"2022-07-21",A_OV_TOT:21,A_OV_COM:5,A_OV_REM:16,A_OV_PROG:23.8,A_OV_STATUS:"less than 70%",A_PG:23.8,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9130-9130-10",SUBSYSTEM_DESCR:"MW05 Area Completion",LOSH:"Y",MC_B30:"2022-07-28",A_OV_TOT:29,A_OV_COM:12,A_OV_REM:17,A_OV_PROG:41.37,A_OV_STATUS:"less than 70%",A_PG:41.37,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9130-9130-11",SUBSYSTEM_DESCR:"A Deck Laydown Area Completion",MC_B30:"2022-07-28",A_OV_TOT:16,A_OV_COM:0,A_OV_REM:16,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9130-9130-12",SUBSYSTEM_DESCR:"KO Drum Area Completion",LOSH:"Y",MC_B30:"2022-07-28",A_OV_TOT:5,A_OV_COM:0,A_OV_REM:5,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9130-9130-13",SUBSYSTEM_DESCR:"Offloading Area Completion",LOSH:"Y",MC_B30:"2022-07-14",A_OV_STATUS:"NA",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9130-9130-14",SUBSYSTEM_DESCR:"Flare Area Completion",LOSH:"Y",MC_B30:"2022-08-04",A_OV_TOT:21,A_OV_COM:1,A_OV_REM:20,A_OV_PROG:4.76,A_OV_STATUS:"less than 70%",A_PG:4.76,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9130-9130-15",SUBSYSTEM_DESCR:"Central Pipe Rack Area Completion",LOSH:"Y",MC_B30:"2022-07-14",A_OV_TOT:73,A_OV_COM:22,A_OV_REM:51,A_OV_PROG:30.13,A_OV_STATUS:"less than 70%",A_PG:30.13,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:45,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9130-9130-16",SUBSYSTEM_DESCR:"Safe Haven Area Completion",MC_B30:"2022-06-16",A_OV_TOT:2,A_OV_COM:0,A_OV_REM:2,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9220-9220-01",SUBSYSTEM_DESCR:"LQ Main Deck and Mezzanine",LOSH:"Y",MC_B30:"2022-06-16",A_OV_TOT:149,A_OV_COM:59,A_OV_REM:90,A_OV_PROG:39.59,A_OV_STATUS:"less than 70%",A_PG:39.59,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:40,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9220-9220-02",SUBSYSTEM_DESCR:"LQ A Deck - Area Completion",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:212,A_OV_COM:100,A_OV_REM:112,A_OV_PROG:47.16,A_OV_STATUS:"less than 70%",A_PG:47.16,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:59,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9220-9220-03",SUBSYSTEM_DESCR:"LQ Deck 1 - Area Completion",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:167,A_OV_COM:94,A_OV_REM:73,A_OV_PROG:56.28,A_OV_STATUS:"less than 70%",A_PG:56.28,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,ACT_TAG_VALID:41,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-9220-9220-04",SUBSYSTEM_DESCR:"LQ Deck 2 - Area Completion",LOSH:"Y",MC_B30:"2022-08-25",A_OV_TOT:207,A_OV_COM:106,A_OV_REM:101,A_OV_PROG:51.2,A_OV_STATUS:"less than 70%",A_PG:51.2,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:50,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9220-9220-05",SUBSYSTEM_DESCR:"LQ Deck 3 - Area Completion",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:253,A_OV_COM:155,A_OV_REM:98,A_OV_PROG:61.26,A_OV_STATUS:"less than 70%",A_PG:61.26,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:13,PA_OV_COM:10,PA_OV_REM:3,PB1_OV_TOT:10,PB1_OV_COM:3,PB1_OV_REM:7,ACT_TAG_VALID:71,PUNCH_NUMBER_VALID:23,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-9220-9220-06",SUBSYSTEM_DESCR:"LQ Deck 4 - Area Completion",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:257,A_OV_COM:163,A_OV_REM:94,A_OV_PROG:63.42,A_OV_STATUS:"less than 70%",A_PG:63.42,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:10,PA_OV_COM:4,PA_OV_REM:6,PB1_OV_TOT:13,PB1_OV_COM:9,PB1_OV_REM:4,ACT_TAG_VALID:72,PUNCH_NUMBER_VALID:23,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-9220-9220-07",SUBSYSTEM_DESCR:"LQ Deck 5 - Area Completion",LOSH:"Y",MC_B30:"2022-08-11",A_OV_TOT:146,A_OV_COM:82,A_OV_REM:64,A_OV_PROG:56.16,A_OV_STATUS:"less than 70%",A_PG:56.16,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:35,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9220-9220-09",SUBSYSTEM_DESCR:"LQ Roof Deck and External  - Area Completion",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:187,A_OV_COM:165,A_OV_REM:22,A_OV_PROG:88.23,A_OV_STATUS:"greater than 80%",A_PG:88.23,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:54,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9220-9220-11",SUBSYSTEM_DESCR:"Helideck - Area Completion",LOSH:"Y",MC_B30:"2022-07-28",A_OV_TOT:6,A_OV_COM:4,A_OV_REM:2,A_OV_PROG:66.66,A_OV_STATUS:"less than 70%",A_PG:66.66,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P3",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-9220-9224-01",SUBSYSTEM_DESCR:"Entertainment System",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:756,A_OV_COM:468,A_OV_REM:288,A_OV_PROG:61.9,A_OV_STATUS:"less than 70%",A_PG:61.9,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:5,PB1_OV_COM:3,PB1_OV_REM:2,PB2_OV_TOT:5,PB2_OV_COM:1,PB2_OV_REM:4,ACT_TAG_VALID:756,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-9310-9310-01",SUBSYSTEM_DESCR:"HULL Main Deck Area Completion",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:258,A_OV_COM:234,A_OV_REM:24,A_OV_PROG:90.69,A_OV_STATUS:"greater than 90%",A_PG:90.69,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:86,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9310-9310-02",SUBSYSTEM_DESCR:"Machinery Space Area Completion",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:205,A_OV_COM:137,A_OV_REM:68,A_OV_PROG:66.82,A_OV_STATUS:"less than 70%",A_PG:66.82,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:4,PA_OV_COM:3,PA_OV_REM:1,ACT_TAG_VALID:52,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9310-9310-03",SUBSYSTEM_DESCR:"HULL Forward Area Completion",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:729,A_OV_COM:591,A_OV_REM:138,A_OV_PROG:81.06,A_OV_STATUS:"greater than 80%",A_PG:81.06,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:233,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9310-9310-04",SUBSYSTEM_DESCR:"HULL After Area Completion",MC_B30:"2022-06-02",A_OV_TOT:599,A_OV_COM:558,A_OV_REM:41,A_OV_PROG:93.15,A_OV_STATUS:"greater than 90%",A_PG:93.15,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:192,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9310-9310-05",SUBSYSTEM_DESCR:"Riser Balcony Area Completion",LOSH:"Y",MC_B30:"2022-06-16",A_OV_TOT:341,A_OV_COM:337,A_OV_REM:4,A_OV_PROG:98.82,A_OV_STATUS:"greater than 95%",A_PG:98.82,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:98,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9330-9330-01",SUBSYSTEM_DESCR:"Main Ballast Tanks WBE1 to 5, APE 1, APW 1 and WBW 1 to 5",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:191,A_OV_COM:106,A_OV_REM:85,A_OV_PROG:55.49,A_OV_STATUS:"less than 70%",A_PG:55.49,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1A",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:55,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-9330-9330-02",SUBSYSTEM_DESCR:"Main Ballast Ring Main",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:317,A_OV_COM:122,A_OV_REM:195,A_OV_PROG:38.48,A_OV_STATUS:"less than 70%",A_PG:38.48,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1A",ACT_TAG_VALID:172,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9330-9330-03",SUBSYSTEM_DESCR:"Ballast Pump 21-P-935001 A",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:114,A_OV_COM:33,A_OV_REM:81,A_OV_PROG:28.94,A_OV_STATUS:"less than 70%",A_PG:28.94,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1A",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:57,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-9330-9330-04",SUBSYSTEM_DESCR:"Ballast Pump 21-P-935001 B",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:110,A_OV_COM:32,A_OV_REM:78,A_OV_PROG:29.09,A_OV_STATUS:"less than 70%",A_PG:29.09,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1A",ACT_TAG_VALID:55,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9330-9330-05",SUBSYSTEM_DESCR:"Ballast Pump 21-P-935001 C",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:109,A_OV_COM:36,A_OV_REM:73,A_OV_PROG:33.02,A_OV_STATUS:"less than 70%",A_PG:33.02,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1A",ACT_TAG_VALID:54,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9330-9330-06",SUBSYSTEM_DESCR:"Ballast Pump 21-P-935001 D",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:110,A_OV_COM:37,A_OV_REM:73,A_OV_PROG:33.63,A_OV_STATUS:"less than 70%",A_PG:33.63,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1A",ACT_TAG_VALID:55,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9330-9333-01",SUBSYSTEM_DESCR:"Cargo and Ballast Pumps HPU and UCP",BATCH:"Incentive 2",MC_B30:"2022-03-17",A_OV_TOT:759,A_OV_COM:447,A_OV_REM:312,A_OV_PROG:58.89,A_OV_STATUS:"less than 70%",A_PG:58.89,RFI_ISSUED:"Done",WD_ACTUAL:"2022-04-10",MC_APPROVED:"2022-04-12",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"A",PRIORITY:"P1A",PA_OV_TOT:59,PA_OV_COM:50,PA_OV_REM:9,PB1_OV_TOT:99,PB1_OV_COM:85,PB1_OV_REM:14,PB2_OV_TOT:40,PB2_OV_COM:2,PB2_OV_REM:38,ACT_TAG_VALID:310,PUNCH_NUMBER_VALID:198,PUNCH_TAG_VALID:60},
    {SUBSYSTEM:"21-9330-9334-01",SUBSYSTEM_DESCR:"HULL Valves HPU",BATCH:"Incentive 2",MC_B30:"2022-03-03",A_OV_TOT:103,A_OV_COM:54,A_OV_REM:49,A_OV_PROG:52.42,A_OV_STATUS:"less than 70%",A_PG:52.42,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-04",MC_APPROVED:"2022-02-25",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1A",PA_OV_TOT:39,PA_OV_COM:39,PA_OV_REM:0,PB1_OV_TOT:39,PB1_OV_COM:38,PB1_OV_REM:1,PB2_OV_TOT:17,PB2_OV_COM:15,PB2_OV_REM:2,ACT_TAG_VALID:88,PUNCH_NUMBER_VALID:95,PUNCH_TAG_VALID:20},
    {SUBSYSTEM:"21-9340-9341-01",SUBSYSTEM_DESCR:"Forward Mooring System and HPU",MC_B30:"2022-06-09",A_OV_TOT:93,A_OV_COM:5,A_OV_REM:88,A_OV_PROG:5.37,A_OV_STATUS:"less than 70%",A_PG:5.37,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:2,PA_OV_REM:1,PB1_OV_TOT:5,PB1_OV_COM:5,PB1_OV_REM:0,PB2_OV_TOT:6,PB2_OV_COM:0,PB2_OV_REM:6,ACT_TAG_VALID:54,PUNCH_NUMBER_VALID:14,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-9340-9341-02",SUBSYSTEM_DESCR:"Forward Mooring Fairleads, Sheaves and Accessories Mounted on HULL",MC_B30:"2022-05-12",A_OV_TOT:150,A_OV_COM:133,A_OV_REM:17,A_OV_PROG:88.66,A_OV_STATUS:"greater than 80%",A_PG:88.66,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:77,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-9340-9343-01",SUBSYSTEM_DESCR:"Aft Mooring System and HPU",MC_B30:"2022-06-09",A_OV_TOT:100,A_OV_COM:6,A_OV_REM:94,A_OV_PROG:6,A_OV_STATUS:"less than 70%",A_PG:6,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PB1_OV_TOT:4,PB1_OV_COM:4,PB1_OV_REM:0,PB2_OV_TOT:4,PB2_OV_COM:1,PB2_OV_REM:3,ACT_TAG_VALID:61,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-9340-9343-02",SUBSYSTEM_DESCR:"Aft Mooring Fairleads, Sheaves and Accessories Mounted on HULL",MC_B30:"2022-05-12",A_OV_TOT:141,A_OV_COM:130,A_OV_REM:11,A_OV_PROG:92.19,A_OV_STATUS:"greater than 90%",A_PG:92.19,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:6,PA_OV_COM:5,PA_OV_REM:1,PB1_OV_TOT:2,PB1_OV_COM:1,PB1_OV_REM:1,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:70,PUNCH_NUMBER_VALID:9,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-9350-9350-01",SUBSYSTEM_DESCR:"MS Ballast Tanks WBW6,7 and WBE6,7 and FPE1 andFPW1",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:96,A_OV_COM:70,A_OV_REM:26,A_OV_PROG:72.91,A_OV_STATUS:"NA",A_PG:72.91,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-12",MC_APPROVED:"2022-02-26",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1A",PA_OV_TOT:8,PA_OV_COM:8,PA_OV_REM:0,PB1_OV_TOT:9,PB1_OV_COM:9,PB1_OV_REM:0,PB2_OV_TOT:12,PB2_OV_COM:4,PB2_OV_REM:8,ACT_TAG_VALID:27,PUNCH_NUMBER_VALID:29,PUNCH_TAG_VALID:9},
    {SUBSYSTEM:"21-9350-9350-02",SUBSYSTEM_DESCR:"MS Ballast Ring Main",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:66,A_OV_COM:64,A_OV_REM:2,A_OV_PROG:96.96,A_OV_STATUS:"greater than 95%",A_PG:96.96,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-16",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P1A",PA_OV_TOT:3,PA_OV_COM:3,PA_OV_REM:0,PB1_OV_TOT:38,PB1_OV_COM:38,PB1_OV_REM:0,PB2_OV_TOT:26,PB2_OV_COM:11,PB2_OV_REM:15,PB3_OV_TOT:1,PB3_OV_COM:1,PB3_OV_REM:0,ACT_TAG_VALID:35,PUNCH_NUMBER_VALID:68,PUNCH_TAG_VALID:16},
    {SUBSYSTEM:"21-9350-9350-04",SUBSYSTEM_DESCR:"MS Ballast Pump 21-P-935002A",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:105,A_OV_COM:101,A_OV_REM:4,A_OV_PROG:96.19,A_OV_STATUS:"greater than 95%",A_PG:96.19,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-16",MC_APPROVED:"2022-03-11",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:11,PA_OV_COM:11,PA_OV_REM:0,PB1_OV_TOT:43,PB1_OV_COM:39,PB1_OV_REM:4,PB2_OV_TOT:38,PB2_OV_COM:11,PB2_OV_REM:27,ACT_TAG_VALID:57,PUNCH_NUMBER_VALID:92,PUNCH_TAG_VALID:21},
    {SUBSYSTEM:"21-9350-9350-05",SUBSYSTEM_DESCR:"MS Ballast Pump 21-P-935002B",LOSH:"Y",MC_B30:"2022-03-10",A_OV_TOT:88,A_OV_COM:84,A_OV_REM:4,A_OV_PROG:95.45,A_OV_STATUS:"greater than 95%",A_PG:95.45,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-16",MC_APPROVED:"2022-03-11",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:8,PA_OV_COM:8,PA_OV_REM:0,PB1_OV_TOT:39,PB1_OV_COM:35,PB1_OV_REM:4,PB2_OV_TOT:32,PB2_OV_COM:9,PB2_OV_REM:23,ACT_TAG_VALID:46,PUNCH_NUMBER_VALID:79,PUNCH_TAG_VALID:20},
    {SUBSYSTEM:"21-9370-9370-01",SUBSYSTEM_DESCR:"Slops Tank 21-T-937001 and Pumps 21-P-937001and3",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:302,A_OV_COM:122,A_OV_REM:180,A_OV_PROG:40.39,A_OV_STATUS:"less than 70%",A_PG:40.39,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:122,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9370-9371-01",SUBSYSTEM_DESCR:"Slops Tank 21-T-937002 and Pumps 21-P-937002and4",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:333,A_OV_COM:152,A_OV_REM:181,A_OV_PROG:45.64,A_OV_STATUS:"less than 70%",A_PG:45.64,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:126,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9370-9372-01",SUBSYSTEM_DESCR:"Hazardous Open Drain Header to Slops Tanks",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:609,A_OV_COM:253,A_OV_REM:356,A_OV_PROG:41.54,A_OV_STATUS:"less than 70%",A_PG:41.54,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P3",PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:104,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9370-9372-02",SUBSYSTEM_DESCR:"Non Hazardous Open Drain Header to Slops Tanks",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:348,A_OV_COM:177,A_OV_REM:171,A_OV_PROG:50.86,A_OV_STATUS:"less than 70%",A_PG:50.86,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:58,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9370-9372-04",SUBSYSTEM_DESCR:"Slops Heaters 21-H-937001 AandB",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:108,A_OV_COM:36,A_OV_REM:72,A_OV_PROG:33.33,A_OV_STATUS:"less than 70%",A_PG:33.33,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:33,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9370-9372-07",SUBSYSTEM_DESCR:"Slops Water to Export Line Flushing",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:152,A_OV_COM:34,A_OV_REM:118,A_OV_PROG:22.36,A_OV_STATUS:"less than 70%",A_PG:22.36,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:76,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9370-9372-08",SUBSYSTEM_DESCR:"COW Hot Sea Water Filter 21-F-937001",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:42,A_OV_COM:19,A_OV_REM:23,A_OV_PROG:45.23,A_OV_STATUS:"less than 70%",A_PG:45.23,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9370-9372-09",SUBSYSTEM_DESCR:"Slops Polishing and ODMS ,Oil Discharge Monitoring Units",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:145,A_OV_COM:17,A_OV_REM:128,A_OV_PROG:11.72,A_OV_STATUS:"less than 70%",A_PG:11.72,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:17,PA_OV_COM:14,PA_OV_REM:3,PB1_OV_TOT:24,PB1_OV_COM:14,PB1_OV_REM:10,ACT_TAG_VALID:101,PUNCH_NUMBER_VALID:41,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-9370-9372-10",SUBSYSTEM_DESCR:"Treated Slops Header to Overboard",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:29,A_OV_COM:9,A_OV_REM:20,A_OV_PROG:31.03,A_OV_STATUS:"less than 70%",A_PG:31.03,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P3",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9370-9372-11",SUBSYSTEM_DESCR:"LQ Open Drain Collection",LOSH:"Y",MC_B30:"2022-07-07",A_OV_TOT:82,A_OV_COM:54,A_OV_REM:28,A_OV_PROG:65.85,A_OV_STATUS:"less than 70%",A_PG:65.85,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:13,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9370-9372-12",SUBSYSTEM_DESCR:"HULL Open Drain Collection",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:24,A_OV_COM:11,A_OV_REM:13,A_OV_PROG:45.83,A_OV_STATUS:"less than 70%",A_PG:45.83,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9380-9380-01",SUBSYSTEM_DESCR:"Daily bilge Tank 21-T-938001 and Pumps 21-P-938001AandB",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:120,A_OV_COM:109,A_OV_REM:11,A_OV_PROG:90.83,A_OV_STATUS:"greater than 90%",A_PG:90.83,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:10,PA_OV_COM:10,PA_OV_REM:0,PB1_OV_TOT:20,PB1_OV_COM:9,PB1_OV_REM:11,PB2_OV_TOT:17,PB2_OV_COM:8,PB2_OV_REM:9,ACT_TAG_VALID:42,PUNCH_NUMBER_VALID:47,PUNCH_TAG_VALID:16},
    {SUBSYSTEM:"21-9380-9380-02",SUBSYSTEM_DESCR:"Daily Bilge Wells and Headers to Daily Bilge Tank",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:255,A_OV_COM:243,A_OV_REM:12,A_OV_PROG:95.29,A_OV_STATUS:"greater than 95%",A_PG:95.29,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-30",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:4,PA_OV_COM:2,PA_OV_REM:2,PB1_OV_TOT:10,PB1_OV_COM:5,PB1_OV_REM:5,PB2_OV_TOT:27,PB2_OV_COM:7,PB2_OV_REM:20,ACT_TAG_VALID:69,PUNCH_NUMBER_VALID:41,PUNCH_TAG_VALID:13},
    {SUBSYSTEM:"21-9380-9380-03",SUBSYSTEM_DESCR:"Bilge Water from MS Bilge Collection Header",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:88,A_OV_COM:81,A_OV_REM:7,A_OV_PROG:92.04,A_OV_STATUS:"greater than 90%",A_PG:92.04,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PB1_OV_TOT:3,PB1_OV_COM:3,PB1_OV_REM:0,PB2_OV_TOT:24,PB2_OV_COM:0,PB2_OV_REM:24,ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:29,PUNCH_TAG_VALID:12},
    {SUBSYSTEM:"21-9380-9380-04",SUBSYSTEM_DESCR:"Sludge from Diesel Purifier",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2021-12-23",A_OV_TOT:7,A_OV_COM:7,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-12-09",WD_ACTUAL:"2021-12-10",MC_APPROVED:"2021-12-23",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9380-9380-05",SUBSYSTEM_DESCR:"Diesel Oil from Diesel Storage Tanks",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-01-13",A_OV_TOT:7,A_OV_COM:7,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-12-30",WD_ACTUAL:"2021-12-31",MC_APPROVED:"2022-01-10",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P2",PB1_OV_TOT:2,PB1_OV_COM:2,PB1_OV_REM:0,PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-9380-9380-06",SUBSYSTEM_DESCR:"Daily Bilge for CFC8 and Pump 21-P-938003",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:61,A_OV_COM:28,A_OV_REM:33,A_OV_PROG:45.9,A_OV_STATUS:"less than 70%",A_PG:45.9,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9380-9380-07",SUBSYSTEM_DESCR:"Daily Bilge for CFC5 and CFC6 and Pump 21-P-938004",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:92,A_OV_COM:40,A_OV_REM:52,A_OV_PROG:43.47,A_OV_STATUS:"less than 70%",A_PG:43.47,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:38,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9380-9380-08",SUBSYSTEM_DESCR:"Daily Bilge for CFC7 and Pump 21-P-938005",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:61,A_OV_COM:34,A_OV_REM:27,A_OV_PROG:55.73,A_OV_STATUS:"less than 70%",A_PG:55.73,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9380-9380-09",SUBSYSTEM_DESCR:"Daily Bilge for CFC1 and Pump 21-P-938006",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:57,A_OV_COM:37,A_OV_REM:20,A_OV_PROG:64.91,A_OV_STATUS:"less than 70%",A_PG:64.91,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9380-9381-01",SUBSYSTEM_DESCR:"Main bilge Pumps 21-P-938002AandB and Headers",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:95,A_OV_COM:72,A_OV_REM:23,A_OV_PROG:75.78,A_OV_STATUS:"NA",A_PG:75.78,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:10,PA_OV_COM:7,PA_OV_REM:3,PB1_OV_TOT:13,PB1_OV_COM:9,PB1_OV_REM:4,PB2_OV_TOT:4,PB2_OV_COM:0,PB2_OV_REM:4,ACT_TAG_VALID:43,PUNCH_NUMBER_VALID:27,PUNCH_TAG_VALID:14},
    {SUBSYSTEM:"21-9380-9381-02",SUBSYSTEM_DESCR:"Main bilge Water Header to Overboard",LOSH:"Y",MC_B30:"2022-02-17",A_OV_TOT:6,A_OV_COM:6,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-13",MC_APPROVED:"2022-02-16",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9380-9382-01",SUBSYSTEM_DESCR:"Emergency Bilge well ,SB middle and header",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:17,A_OV_COM:17,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-10",MC_APPROVED:"2022-04-15",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:7,PB1_OV_COM:4,PB1_OV_REM:3,PB2_OV_TOT:4,PB2_OV_COM:0,PB2_OV_REM:4,ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-9390-9390-01",SUBSYSTEM_DESCR:"Bunker Station and HPU",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:30,A_OV_COM:9,A_OV_REM:21,A_OV_PROG:30,A_OV_STATUS:"less than 70%",A_PG:30,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:4,PA_OV_COM:1,PA_OV_REM:3,PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-9500-9514-01",SUBSYSTEM_DESCR:"Telephone System - LQ",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:140,A_OV_COM:22,A_OV_REM:118,A_OV_PROG:15.71,A_OV_STATUS:"less than 70%",A_PG:15.71,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:9,PB1_OV_COM:9,PB1_OV_REM:0,PB2_OV_TOT:3,PB2_OV_COM:1,PB2_OV_REM:2,ACT_TAG_VALID:136,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:8},
    {SUBSYSTEM:"21-9500-9514-02",SUBSYSTEM_DESCR:"Telephone System - Machinery Space and Topsides",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:160,A_OV_COM:7,A_OV_REM:153,A_OV_PROG:4.37,A_OV_STATUS:"less than 70%",A_PG:4.37,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:123,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9500-9515-01",SUBSYSTEM_DESCR:"Hot line Telephones",LOSH:"Y",MC_B30:"2022-06-23",A_OV_TOT:15,A_OV_COM:2,A_OV_REM:13,A_OV_PROG:13.33,A_OV_STATUS:"less than 70%",A_PG:13.33,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P1",PB1_OV_TOT:4,PB1_OV_COM:4,PB1_OV_REM:0,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-9500-9525-01",SUBSYSTEM_DESCR:"Microwave Line of Sight",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:20,A_OV_COM:8,A_OV_REM:12,A_OV_PROG:40,A_OV_STATUS:"less than 70%",A_PG:40,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",PB1_OV_TOT:3,PB1_OV_COM:3,PB1_OV_REM:0,ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-9500-9527-01",SUBSYSTEM_DESCR:"INMARSAT Terminal and Portable Sat Phones",LOSH:"Y",MC_B30:"2022-06-23",A_OV_TOT:14,A_OV_COM:3,A_OV_REM:11,A_OV_PROG:21.42,A_OV_STATUS:"less than 70%",A_PG:21.42,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9500-9534-01",SUBSYSTEM_DESCR:"Structured Cabling System - LQ Deck-A",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:345,A_OV_COM:190,A_OV_REM:155,A_OV_PROG:55.07,A_OV_STATUS:"less than 70%",A_PG:55.07,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P1",PB1_OV_TOT:13,PB1_OV_COM:13,PB1_OV_REM:0,PB2_OV_TOT:9,PB2_OV_COM:8,PB2_OV_REM:1,ACT_TAG_VALID:345,PUNCH_NUMBER_VALID:22,PUNCH_TAG_VALID:14},
    {SUBSYSTEM:"21-9500-9534-02",SUBSYSTEM_DESCR:"Structured Cabling System - LQ Levels 1,2 and 3",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:308,A_OV_COM:157,A_OV_REM:151,A_OV_PROG:50.97,A_OV_STATUS:"less than 70%",A_PG:50.97,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P1",PA_OV_TOT:30,PA_OV_COM:30,PA_OV_REM:0,PB1_OV_TOT:12,PB1_OV_COM:12,PB1_OV_REM:0,PB2_OV_TOT:2,PB2_OV_COM:1,PB2_OV_REM:1,ACT_TAG_VALID:308,PUNCH_NUMBER_VALID:44,PUNCH_TAG_VALID:37},
    {SUBSYSTEM:"21-9500-9534-03",SUBSYSTEM_DESCR:"Structured Cabling System - LQ Levels 4 and 5",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:249,A_OV_COM:133,A_OV_REM:116,A_OV_PROG:53.41,A_OV_STATUS:"less than 70%",A_PG:53.41,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:50,PA_OV_COM:50,PA_OV_REM:0,PB1_OV_TOT:21,PB1_OV_COM:14,PB1_OV_REM:7,PB2_OV_TOT:27,PB2_OV_COM:26,PB2_OV_REM:1,ACT_TAG_VALID:249,PUNCH_NUMBER_VALID:98,PUNCH_TAG_VALID:47},
    {SUBSYSTEM:"21-9500-9534-04",SUBSYSTEM_DESCR:"Structured Cabling System - Machinery Space",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:20,A_OV_COM:0,A_OV_REM:20,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9500-9534-05",SUBSYSTEM_DESCR:"Structured Cabling System - ME05",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:16,A_OV_COM:0,A_OV_REM:16,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9500-9535-01",SUBSYSTEM_DESCR:"FPSO Fibre Optic Cable",MC_B30:"2022-03-17",A_OV_TOT:11,A_OV_COM:11,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-19",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"B2",PRIORITY:"P1",PA_OV_TOT:6,PA_OV_COM:6,PA_OV_REM:0,PB1_OV_TOT:5,PB1_OV_COM:5,PB1_OV_REM:0,PB2_OV_TOT:9,PB2_OV_COM:7,PB2_OV_REM:2,ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-9500-9535-02",SUBSYSTEM_DESCR:"Subsea FO Termination Unit Cables and Junction Box",LOSH:"Y",MC_B30:"2022-05-26",A_OV_TOT:4,A_OV_COM:0,A_OV_REM:4,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9500-9536-01",SUBSYSTEM_DESCR:"LANandWAN ,Data Network Infrastructure",LOSH:"Y",MC_B30:"2022-03-31",A_OV_TOT:520,A_OV_COM:270,A_OV_REM:250,A_OV_PROG:51.92,A_OV_STATUS:"less than 70%",A_PG:51.92,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:23,PA_OV_COM:23,PA_OV_REM:0,PB1_OV_TOT:23,PB1_OV_COM:21,PB1_OV_REM:2,PB2_OV_TOT:41,PB2_OV_COM:27,PB2_OV_REM:14,ACT_TAG_VALID:468,PUNCH_NUMBER_VALID:87,PUNCH_TAG_VALID:25},
    {SUBSYSTEM:"21-9500-9539-01",SUBSYSTEM_DESCR:"Video Conferencing and Collaboration System",LOSH:"Y",MC_B30:"2022-06-02",A_OV_TOT:74,A_OV_COM:1,A_OV_REM:73,A_OV_PROG:1.35,A_OV_STATUS:"less than 70%",A_PG:1.35,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:74,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9500-9544-01",SUBSYSTEM_DESCR:"Marine and Security CCTV - LQ",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:174,A_OV_COM:45,A_OV_REM:129,A_OV_PROG:25.86,A_OV_STATUS:"less than 70%",A_PG:25.86,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PB1_OV_TOT:11,PB1_OV_COM:11,PB1_OV_REM:0,PB2_OV_TOT:7,PB2_OV_COM:0,PB2_OV_REM:7,ACT_TAG_VALID:139,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:12},
    {SUBSYSTEM:"21-9500-9544-02",SUBSYSTEM_DESCR:"Marine and Security CCTV - Topside HULL Deck",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:234,A_OV_COM:0,A_OV_REM:234,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:166,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9500-9554-01",SUBSYSTEM_DESCR:"Meterological METOCEAN System",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:66,A_OV_COM:5,A_OV_REM:61,A_OV_PROG:7.57,A_OV_STATUS:"less than 70%",A_PG:7.57,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P1",PB1_OV_TOT:2,PB1_OV_COM:2,PB1_OV_REM:0,PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:54,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9500-9555-01",SUBSYSTEM_DESCR:"Berthing Aids and Approach System",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:56,A_OV_COM:6,A_OV_REM:50,A_OV_PROG:10.71,A_OV_STATUS:"less than 70%",A_PG:10.71,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:3,PB1_OV_COM:2,PB1_OV_REM:1,PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:44,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9500-9557-01",SUBSYSTEM_DESCR:"Global Maritime Distress and Safety System",LOSH:"Y",MC_B30:"2022-06-09",A_OV_TOT:65,A_OV_COM:12,A_OV_REM:53,A_OV_PROG:18.46,A_OV_STATUS:"less than 70%",A_PG:18.46,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",PB1_OV_TOT:3,PB1_OV_COM:3,PB1_OV_REM:0,ACT_TAG_VALID:65,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-9500-9564-01",SUBSYSTEM_DESCR:"Access Control System and Emergency Mustering System",LOSH:"Y",MC_B30:"2022-05-05",A_OV_TOT:656,A_OV_COM:357,A_OV_REM:299,A_OV_PROG:54.42,A_OV_STATUS:"less than 70%",A_PG:54.42,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:30,PA_OV_COM:30,PA_OV_REM:0,PB1_OV_TOT:33,PB1_OV_COM:32,PB1_OV_REM:1,PB2_OV_TOT:20,PB2_OV_COM:9,PB2_OV_REM:11,ACT_TAG_VALID:647,PUNCH_NUMBER_VALID:83,PUNCH_TAG_VALID:45},
    {SUBSYSTEM:"21-9500-9565-01",SUBSYSTEM_DESCR:"Ship Security Alert System ,SSAS",LOSH:"Y",MC_B30:"2022-06-23",A_OV_TOT:14,A_OV_COM:8,A_OV_REM:6,A_OV_PROG:57.14,A_OV_STATUS:"less than 70%",A_PG:57.14,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9500-9570-01",SUBSYSTEM_DESCR:"Gyrocompass",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:13,A_OV_COM:9,A_OV_REM:4,A_OV_PROG:69.23,A_OV_STATUS:"less than 70%",A_PG:69.23,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P1",PA_OV_TOT:10,PA_OV_COM:10,PA_OV_REM:0,PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,PB2_OV_TOT:8,PB2_OV_COM:7,PB2_OV_REM:1,ACT_TAG_VALID:13,PUNCH_NUMBER_VALID:19,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-9500-9570-02",SUBSYSTEM_DESCR:"D-GPS System",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:10,A_OV_COM:4,A_OV_REM:6,A_OV_PROG:40,A_OV_STATUS:"less than 70%",A_PG:40,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9500-9570-03",SUBSYSTEM_DESCR:"Hand held satellite phone",LOSH:"Y",MC_B30:"2022-06-23",A_OV_TOT:10,A_OV_COM:1,A_OV_REM:9,A_OV_PROG:10,A_OV_STATUS:"less than 70%",A_PG:10,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9500-9570-04",SUBSYSTEM_DESCR:"Video Wall System",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:39,A_OV_COM:0,A_OV_REM:39,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"B2",PRIORITY:"P1",PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:39,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9500-9574-01",SUBSYSTEM_DESCR:"Network Management System",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:12,A_OV_COM:12,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-24",MC_APPROVED:"2022-03-31",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1",PA_OV_TOT:15,PA_OV_COM:15,PA_OV_REM:0,PB1_OV_TOT:2,PB1_OV_COM:2,PB1_OV_REM:0,PB2_OV_TOT:18,PB2_OV_COM:10,PB2_OV_REM:8,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:35,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-9500-9575-01",SUBSYSTEM_DESCR:"Digital Voice Recorder",LOSH:"Y",MC_B30:"2022-04-14",A_OV_TOT:14,A_OV_COM:14,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-23",MC_APPROVED:"2022-03-31",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:8,PA_OV_COM:8,PA_OV_REM:0,PB1_OV_TOT:6,PB1_OV_COM:4,PB1_OV_REM:2,PB2_OV_TOT:12,PB2_OV_COM:10,PB2_OV_REM:2,ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:26,PUNCH_TAG_VALID:8},
    {SUBSYSTEM:"21-9810-9811-01",SUBSYSTEM_DESCR:"FPSO West Crane",MC_B30:"2022-06-09",A_OV_TOT:226,A_OV_COM:169,A_OV_REM:57,A_OV_PROG:74.77,A_OV_STATUS:"NA",A_PG:74.77,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:31,PA_OV_COM:2,PA_OV_REM:29,PB1_OV_TOT:25,PB1_OV_COM:13,PB1_OV_REM:12,PB2_OV_TOT:5,PB2_OV_COM:0,PB2_OV_REM:5,ACT_TAG_VALID:222,PUNCH_NUMBER_VALID:61,PUNCH_TAG_VALID:30},
    {SUBSYSTEM:"21-9810-9812-01",SUBSYSTEM_DESCR:"FPSO East Crane",MC_B30:"2022-06-09",A_OV_TOT:224,A_OV_COM:136,A_OV_REM:88,A_OV_PROG:60.71,A_OV_STATUS:"less than 70%",A_PG:60.71,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:25,PA_OV_COM:0,PA_OV_REM:25,PB1_OV_TOT:17,PB1_OV_COM:9,PB1_OV_REM:8,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:220,PUNCH_NUMBER_VALID:43,PUNCH_TAG_VALID:31},
    {SUBSYSTEM:"21-9820-9822-01",SUBSYSTEM_DESCR:"LQ Personnel Lift",MC_B30:"2022-05-19",A_OV_TOT:94,A_OV_COM:0,A_OV_REM:94,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:85,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9820-9823-01",SUBSYSTEM_DESCR:"LQ Goods and Material Lift",MC_B30:"2022-06-02",A_OV_TOT:98,A_OV_COM:2,A_OV_REM:96,A_OV_PROG:2.04,A_OV_STATUS:"less than 70%",A_PG:2.04,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:4,PA_OV_COM:4,PA_OV_REM:0,ACT_TAG_VALID:89,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9820-9824-01",SUBSYSTEM_DESCR:"Riser and Umbilical Pull in Winch",MC_B30:"2022-05-26",A_OV_TOT:18,A_OV_COM:0,A_OV_REM:18,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:5,PA_OV_COM:1,PA_OV_REM:4,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-9820-9824-02",SUBSYSTEM_DESCR:"Miscellaneous Lifting Beams, Davits, Hoist and Padeyes HULL",MC_B30:"2022-07-28",A_OV_TOT:364,A_OV_COM:66,A_OV_REM:298,A_OV_PROG:18.13,A_OV_STATUS:"less than 70%",A_PG:18.13,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:188,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9820-9824-03",SUBSYSTEM_DESCR:"Miscellaneous Lifting Beams, Davits, Hoist and Padeyes LQ",MC_B30:"2022-07-28",A_OV_TOT:28,A_OV_COM:5,A_OV_REM:23,A_OV_PROG:17.85,A_OV_STATUS:"less than 70%",A_PG:17.85,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:13,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9820-9824-04",SUBSYSTEM_DESCR:"Miscellaneous Lifting Beams, Davits, Hoist and Padeyes Topsides",MC_B30:"2022-06-23",A_OV_TOT:905,A_OV_COM:182,A_OV_REM:723,A_OV_PROG:20.11,A_OV_STATUS:"less than 70%",A_PG:20.11,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:473,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9840-9841-01",SUBSYSTEM_DESCR:"Workshops",MC_B30:"2022-06-09",A_OV_TOT:13,A_OV_COM:4,A_OV_REM:9,A_OV_PROG:30.76,A_OV_STATUS:"less than 70%",A_PG:30.76,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-9840-9842-01",SUBSYSTEM_DESCR:"Laboratory",MC_B30:"2022-06-02",A_OV_TOT:32,A_OV_COM:0,A_OV_REM:32,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9840-9843-01",SUBSYSTEM_DESCR:"Medical Suite",MC_B30:"2022-06-23",A_OV_TOT:4,A_OV_COM:1,A_OV_REM:3,A_OV_PROG:25,A_OV_STATUS:"less than 70%",A_PG:25,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9840-9844-01",SUBSYSTEM_DESCR:"Galley",MC_B30:"2022-06-02",A_OV_TOT:65,A_OV_COM:26,A_OV_REM:39,A_OV_PROG:40,A_OV_STATUS:"less than 70%",A_PG:40,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:11,PA_OV_COM:11,PA_OV_REM:0,PB1_OV_TOT:2,PB1_OV_COM:2,PB1_OV_REM:0,ACT_TAG_VALID:56,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-9840-9845-01",SUBSYSTEM_DESCR:"Laundry",MC_B30:"2022-05-12",A_OV_TOT:22,A_OV_COM:15,A_OV_REM:7,A_OV_PROG:68.18,A_OV_STATUS:"less than 70%",A_PG:68.18,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:19,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9840-9846-01",SUBSYSTEM_DESCR:"Hospital and Galley Call Alarm System",MC_B30:"2022-05-26",A_OV_TOT:38,A_OV_COM:20,A_OV_REM:18,A_OV_PROG:52.63,A_OV_STATUS:"less than 70%",A_PG:52.63,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:38,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9921-9921-01",SUBSYSTEM_DESCR:"LQ Emergency Pressurization Fans",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:103,A_OV_COM:81,A_OV_REM:22,A_OV_PROG:78.64,A_OV_STATUS:"NA",A_PG:78.64,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P3",PA_OV_TOT:5,PA_OV_COM:5,PA_OV_REM:0,PB1_OV_TOT:13,PB1_OV_COM:11,PB1_OV_REM:2,PB2_OV_TOT:10,PB2_OV_COM:1,PB2_OV_REM:9,ACT_TAG_VALID:72,PUNCH_NUMBER_VALID:28,PUNCH_TAG_VALID:10},
    {SUBSYSTEM:"21-9921-9921-02",SUBSYSTEM_DESCR:"LQ Battery Exhaust Fans, Ducting and Dampers",LOSH:"Y",MC_B30:"2022-03-24",A_OV_TOT:101,A_OV_COM:97,A_OV_REM:4,A_OV_PROG:96.03,A_OV_STATUS:"greater than 95%",A_PG:96.03,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-09",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:17,PA_OV_COM:16,PA_OV_REM:1,PB1_OV_TOT:34,PB1_OV_COM:33,PB1_OV_REM:1,PB2_OV_TOT:21,PB2_OV_COM:6,PB2_OV_REM:15,ACT_TAG_VALID:80,PUNCH_NUMBER_VALID:72,PUNCH_TAG_VALID:34},
    {SUBSYSTEM:"21-9921-9921-03",SUBSYSTEM_DESCR:"LQ Extracts, Ducting and Damper",LOSH:"Y",MC_B30:"2022-03-24",A_OV_TOT:261,A_OV_COM:200,A_OV_REM:61,A_OV_PROG:76.62,A_OV_STATUS:"NA",A_PG:76.62,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:5,PA_OV_COM:5,PA_OV_REM:0,PB1_OV_TOT:34,PB1_OV_COM:32,PB1_OV_REM:2,PB2_OV_TOT:10,PB2_OV_COM:1,PB2_OV_REM:9,ACT_TAG_VALID:207,PUNCH_NUMBER_VALID:49,PUNCH_TAG_VALID:26},
    {SUBSYSTEM:"21-9921-9921-04",SUBSYSTEM_DESCR:"LQ Emergency SWB Room ACUsandACCUs and UCPs",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-24",A_OV_TOT:82,A_OV_COM:82,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-16",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:39,PA_OV_COM:39,PA_OV_REM:0,PB1_OV_TOT:49,PB1_OV_COM:36,PB1_OV_REM:13,PB2_OV_TOT:38,PB2_OV_COM:5,PB2_OV_REM:33,ACT_TAG_VALID:41,PUNCH_NUMBER_VALID:126,PUNCH_TAG_VALID:30},
    {SUBSYSTEM:"21-9921-9921-05",SUBSYSTEM_DESCR:"LQ CCR ACUsandACCUs and UCPs",LOSH:"Y",MC_B30:"2022-03-10",A_OV_TOT:82,A_OV_COM:82,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-28",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:13,PA_OV_COM:13,PA_OV_REM:0,PB1_OV_TOT:19,PB1_OV_COM:11,PB1_OV_REM:8,PB2_OV_TOT:42,PB2_OV_COM:6,PB2_OV_REM:36,ACT_TAG_VALID:39,PUNCH_NUMBER_VALID:74,PUNCH_TAG_VALID:21},
    {SUBSYSTEM:"21-9921-9921-06",SUBSYSTEM_DESCR:"LQ CER ACUsandACCUs and UCPs",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-24",A_OV_TOT:82,A_OV_COM:82,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-05",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:23,PA_OV_COM:23,PA_OV_REM:0,PB1_OV_TOT:57,PB1_OV_COM:45,PB1_OV_REM:12,PB2_OV_TOT:46,PB2_OV_COM:9,PB2_OV_REM:37,ACT_TAG_VALID:43,PUNCH_NUMBER_VALID:126,PUNCH_TAG_VALID:28},
    {SUBSYSTEM:"21-9921-9921-07",SUBSYSTEM_DESCR:"LQ Secondary ICC ACUsandACCUs and UCPs",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:73,A_OV_COM:73,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-05",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:10,PA_OV_COM:10,PA_OV_REM:0,PB1_OV_TOT:18,PB1_OV_COM:15,PB1_OV_REM:3,PB2_OV_TOT:29,PB2_OV_COM:6,PB2_OV_REM:23,ACT_TAG_VALID:48,PUNCH_NUMBER_VALID:57,PUNCH_TAG_VALID:16},
    {SUBSYSTEM:"21-9921-9921-08",SUBSYSTEM_DESCR:"LQ Dining Room and Secondary Muster ACUsandACCUs and UCPs",LOSH:"Y",MC_B30:"2022-03-10",A_OV_TOT:76,A_OV_COM:76,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-28",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:18,PA_OV_COM:18,PA_OV_REM:0,PB1_OV_TOT:43,PB1_OV_COM:33,PB1_OV_REM:10,PB2_OV_TOT:38,PB2_OV_COM:4,PB2_OV_REM:34,ACT_TAG_VALID:36,PUNCH_NUMBER_VALID:99,PUNCH_TAG_VALID:26},
    {SUBSYSTEM:"21-9921-9921-09",SUBSYSTEM_DESCR:"LQ Telecom Room ACUsandACCUs and UCPs",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:41,A_OV_COM:41,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-01",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"B2",PRIORITY:"P1",PA_OV_TOT:11,PA_OV_COM:11,PA_OV_REM:0,PB1_OV_TOT:4,PB1_OV_COM:4,PB1_OV_REM:0,PB2_OV_TOT:25,PB2_OV_COM:1,PB2_OV_REM:24,ACT_TAG_VALID:19,PUNCH_NUMBER_VALID:40,PUNCH_TAG_VALID:11},
    {SUBSYSTEM:"21-9921-9922-01",SUBSYSTEM_DESCR:"LQ HVAC UCPs, EDBs",MC_B30:"2022-03-10",A_OV_TOT:55,A_OV_COM:55,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-28",MC_APPROVED:"2022-03-08",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:16,PA_OV_COM:16,PA_OV_REM:0,PB1_OV_TOT:43,PB1_OV_COM:39,PB1_OV_REM:4,PB2_OV_TOT:11,PB2_OV_COM:3,PB2_OV_REM:8,ACT_TAG_VALID:55,PUNCH_NUMBER_VALID:70,PUNCH_TAG_VALID:11},
    {SUBSYSTEM:"21-9921-9922-02",SUBSYSTEM_DESCR:"LQ HVAC AHUs, Ducting and Dampers",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:2066,A_OV_COM:1870,A_OV_REM:196,A_OV_PROG:90.51,A_OV_STATUS:"greater than 90%",A_PG:90.51,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:37,PA_OV_COM:29,PA_OV_REM:8,PB1_OV_TOT:134,PB1_OV_COM:129,PB1_OV_REM:5,PB2_OV_TOT:46,PB2_OV_COM:25,PB2_OV_REM:21,ACT_TAG_VALID:1869,PUNCH_NUMBER_VALID:217,PUNCH_TAG_VALID:128},
    {SUBSYSTEM:"21-9921-9922-03",SUBSYSTEM_DESCR:"LQ HVAC Extracts, Ducting and Dampers",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:594,A_OV_COM:567,A_OV_REM:27,A_OV_PROG:95.45,A_OV_STATUS:"greater than 95%",A_PG:95.45,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:9,PA_OV_COM:7,PA_OV_REM:2,PB1_OV_TOT:39,PB1_OV_COM:39,PB1_OV_REM:0,PB2_OV_TOT:9,PB2_OV_COM:5,PB2_OV_REM:4,ACT_TAG_VALID:570,PUNCH_NUMBER_VALID:57,PUNCH_TAG_VALID:29},
    {SUBSYSTEM:"21-9921-9922-04",SUBSYSTEM_DESCR:"LQ Galley AHUs, Ducting and Dampers",LOSH:"Y",MC_B30:"2022-04-21",A_OV_TOT:216,A_OV_COM:149,A_OV_REM:67,A_OV_PROG:68.98,A_OV_STATUS:"less than 70%",A_PG:68.98,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:5,PA_OV_COM:5,PA_OV_REM:0,PB1_OV_TOT:23,PB1_OV_COM:21,PB1_OV_REM:2,PB2_OV_TOT:6,PB2_OV_COM:1,PB2_OV_REM:5,ACT_TAG_VALID:183,PUNCH_NUMBER_VALID:34,PUNCH_TAG_VALID:19},
    {SUBSYSTEM:"21-9921-9922-05",SUBSYSTEM_DESCR:"LQ General Exhaust Air, Ducting and Dampers",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:167,A_OV_COM:154,A_OV_REM:13,A_OV_PROG:92.21,A_OV_STATUS:"greater than 90%",A_PG:92.21,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PB1_OV_TOT:27,PB1_OV_COM:26,PB1_OV_REM:1,PB2_OV_TOT:4,PB2_OV_COM:2,PB2_OV_REM:2,ACT_TAG_VALID:151,PUNCH_NUMBER_VALID:33,PUNCH_TAG_VALID:22},
    {SUBSYSTEM:"21-9921-9922-06",SUBSYSTEM_DESCR:"LQ Galley Hood Exhaust Fans, Ducting and Dampers",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:255,A_OV_COM:184,A_OV_REM:71,A_OV_PROG:72.15,A_OV_STATUS:"NA",A_PG:72.15,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:13,PA_OV_COM:10,PA_OV_REM:3,PB1_OV_TOT:24,PB1_OV_COM:23,PB1_OV_REM:1,PB2_OV_TOT:9,PB2_OV_COM:4,PB2_OV_REM:5,ACT_TAG_VALID:185,PUNCH_NUMBER_VALID:46,PUNCH_TAG_VALID:23},
    {SUBSYSTEM:"21-9921-9922-07",SUBSYSTEM_DESCR:"LQ Toilet Exhaust Fans, Ducting and Dampers",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:201,A_OV_COM:190,A_OV_REM:11,A_OV_PROG:94.52,A_OV_STATUS:"greater than 90%",A_PG:94.52,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:4,PA_OV_COM:3,PA_OV_REM:1,PB1_OV_TOT:27,PB1_OV_COM:26,PB1_OV_REM:1,PB2_OV_TOT:12,PB2_OV_COM:3,PB2_OV_REM:9,ACT_TAG_VALID:187,PUNCH_NUMBER_VALID:43,PUNCH_TAG_VALID:20},
    {SUBSYSTEM:"21-9921-9922-08",SUBSYSTEM_DESCR:"LQ Laundry Exhaust Fans, Ducting and Dampers",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:94,A_OV_COM:68,A_OV_REM:26,A_OV_PROG:72.34,A_OV_STATUS:"NA",A_PG:72.34,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PB1_OV_TOT:22,PB1_OV_COM:22,PB1_OV_REM:0,PB2_OV_TOT:4,PB2_OV_COM:2,PB2_OV_REM:2,ACT_TAG_VALID:66,PUNCH_NUMBER_VALID:26,PUNCH_TAG_VALID:10},
    {SUBSYSTEM:"21-9921-9922-09",SUBSYSTEM_DESCR:"LQ Sick Bay Exhaust Fans, Ducting and Dampers",MC_B30:"2022-05-19",A_OV_TOT:81,A_OV_COM:69,A_OV_REM:12,A_OV_PROG:85.18,A_OV_STATUS:"greater than 80%",A_PG:85.18,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-29",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:11,PA_OV_COM:7,PA_OV_REM:4,PB1_OV_TOT:25,PB1_OV_COM:24,PB1_OV_REM:1,PB2_OV_TOT:25,PB2_OV_COM:11,PB2_OV_REM:14,ACT_TAG_VALID:63,PUNCH_NUMBER_VALID:61,PUNCH_TAG_VALID:18},
    {SUBSYSTEM:"21-9921-9922-11",SUBSYSTEM_DESCR:"LQ Laboratory Exhaust Fans, Ducting and Dampers",LOSH:"Y",MC_B30:"2022-05-19",A_OV_TOT:86,A_OV_COM:81,A_OV_REM:5,A_OV_PROG:94.18,A_OV_STATUS:"greater than 90%",A_PG:94.18,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:4,PA_OV_COM:2,PA_OV_REM:2,PB1_OV_TOT:20,PB1_OV_COM:16,PB1_OV_REM:4,PB2_OV_TOT:7,PB2_OV_COM:1,PB2_OV_REM:6,ACT_TAG_VALID:64,PUNCH_NUMBER_VALID:31,PUNCH_TAG_VALID:16},
    {SUBSYSTEM:"21-9921-9922-12",SUBSYSTEM_DESCR:"LQ Provision Plant Exhaust Fans, Ducting and Dampers",LOSH:"Y",MC_B30:"2022-04-28",A_OV_TOT:53,A_OV_COM:53,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-23",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"B2",PRIORITY:"P2",PA_OV_TOT:5,PA_OV_COM:5,PA_OV_REM:0,PB1_OV_TOT:10,PB1_OV_COM:10,PB1_OV_REM:0,PB2_OV_TOT:8,PB2_OV_COM:3,PB2_OV_REM:5,ACT_TAG_VALID:47,PUNCH_NUMBER_VALID:23,PUNCH_TAG_VALID:11},
    {SUBSYSTEM:"21-9921-9922-13",SUBSYSTEM_DESCR:"HULL Purge",LOSH:"Y",MC_B30:"2022-05-12",A_OV_TOT:24,A_OV_COM:24,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-22",MC_APPROVED:"2022-03-31",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:4,PA_OV_COM:4,PA_OV_REM:0,PB1_OV_TOT:8,PB1_OV_COM:7,PB1_OV_REM:1,PB2_OV_TOT:7,PB2_OV_COM:3,PB2_OV_REM:4,PB3_OV_TOT:1,PB3_OV_COM:1,PB3_OV_REM:0,ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:10},
    {SUBSYSTEM:"21-9921-9922-14",SUBSYSTEM_DESCR:"LQ Paint Store Supply Fans, Ducting and Dampers",LOSH:"Y",MC_B30:"2022-06-23",A_OV_TOT:133,A_OV_COM:93,A_OV_REM:40,A_OV_PROG:69.92,A_OV_STATUS:"less than 70%",A_PG:69.92,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:8,PA_OV_COM:3,PA_OV_REM:5,PB1_OV_TOT:14,PB1_OV_COM:7,PB1_OV_REM:7,PB2_OV_TOT:5,PB2_OV_COM:3,PB2_OV_REM:2,ACT_TAG_VALID:88,PUNCH_NUMBER_VALID:27,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-9921-9922-15",SUBSYSTEM_DESCR:"LQ Dining Room Exhaust Fan, Ducting and Dampers",LOSH:"Y",MC_B30:"2022-03-24",A_OV_TOT:77,A_OV_COM:77,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-01",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:26,PB1_OV_COM:24,PB1_OV_REM:2,PB2_OV_TOT:12,PB2_OV_COM:4,PB2_OV_REM:8,ACT_TAG_VALID:62,PUNCH_NUMBER_VALID:38,PUNCH_TAG_VALID:20},
    {SUBSYSTEM:"21-9921-9927-01",SUBSYSTEM_DESCR:"Cool Store System in Galley",MC_B30:"2022-05-19",A_OV_TOT:185,A_OV_COM:94,A_OV_REM:91,A_OV_PROG:50.81,A_OV_STATUS:"less than 70%",A_PG:50.81,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:10,PA_OV_COM:8,PA_OV_REM:2,PB1_OV_TOT:29,PB1_OV_COM:26,PB1_OV_REM:3,PB2_OV_TOT:17,PB2_OV_COM:2,PB2_OV_REM:15,ACT_TAG_VALID:144,PUNCH_NUMBER_VALID:56,PUNCH_TAG_VALID:26},
    {SUBSYSTEM:"21-9950-9950-01",SUBSYSTEM_DESCR:"Tertiary Refuge Air Intake, Ducting and Dampers",LOSH:"Y",MC_B30:"2022-07-14",A_OV_TOT:97,A_OV_COM:4,A_OV_REM:93,A_OV_PROG:4.12,A_OV_STATUS:"less than 70%",A_PG:4.12,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:75,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9950-9950-02",SUBSYSTEM_DESCR:"Tertiary Refuge ACUsandACCUs and UCPs",LOSH:"Y",MC_B30:"2022-07-21",A_OV_TOT:142,A_OV_COM:12,A_OV_REM:130,A_OV_PROG:8.45,A_OV_STATUS:"less than 70%",A_PG:8.45,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:92,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9970-9970-01",SUBSYSTEM_DESCR:"HULL Emergency Pressurisation Fans",LOSH:"Y",MC_B30:"2022-03-24",A_OV_TOT:99,A_OV_COM:85,A_OV_REM:14,A_OV_PROG:85.85,A_OV_STATUS:"greater than 80%",A_PG:85.85,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P3",PA_OV_TOT:3,PA_OV_COM:3,PA_OV_REM:0,PB1_OV_TOT:26,PB1_OV_COM:24,PB1_OV_REM:2,PB2_OV_TOT:12,PB2_OV_COM:3,PB2_OV_REM:9,ACT_TAG_VALID:79,PUNCH_NUMBER_VALID:41,PUNCH_TAG_VALID:23},
    {SUBSYSTEM:"21-9970-9971-01",SUBSYSTEM_DESCR:"HULL HVAC UCPs, EDBs",BATCH:"Incentive 2",MC_B30:"2022-01-27",A_OV_TOT:26,A_OV_COM:26,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-15",MC_APPROVED:"2022-01-24",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:16,PA_OV_COM:16,PA_OV_REM:0,PB1_OV_TOT:17,PB1_OV_COM:16,PB1_OV_REM:1,PB2_OV_TOT:11,PB2_OV_COM:4,PB2_OV_REM:7,ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:44,PUNCH_TAG_VALID:8},
    {SUBSYSTEM:"21-9970-9971-02",SUBSYSTEM_DESCR:"HULL Machinery Space Supply Fans , Ducting and Dampers",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:167,A_OV_COM:161,A_OV_REM:6,A_OV_PROG:96.4,A_OV_STATUS:"greater than 95%",A_PG:96.4,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-23",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P3",PA_OV_TOT:8,PA_OV_COM:8,PA_OV_REM:0,PB1_OV_TOT:35,PB1_OV_COM:32,PB1_OV_REM:3,PB2_OV_TOT:16,PB2_OV_COM:3,PB2_OV_REM:13,ACT_TAG_VALID:153,PUNCH_NUMBER_VALID:59,PUNCH_TAG_VALID:28},
    {SUBSYSTEM:"21-9970-9971-03",SUBSYSTEM_DESCR:"HULL Machinery Space Exhaust Fans , Ducting and Dampers",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:169,A_OV_COM:167,A_OV_REM:2,A_OV_PROG:98.81,A_OV_STATUS:"greater than 95%",A_PG:98.81,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-02",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P3",PA_OV_TOT:11,PA_OV_COM:11,PA_OV_REM:0,PB1_OV_TOT:47,PB1_OV_COM:43,PB1_OV_REM:4,PB2_OV_TOT:19,PB2_OV_COM:4,PB2_OV_REM:15,ACT_TAG_VALID:160,PUNCH_NUMBER_VALID:77,PUNCH_TAG_VALID:30},
    {SUBSYSTEM:"21-9970-9971-04",SUBSYSTEM_DESCR:"KSG Room Supply Fans, Ducting and Dampers",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:248,A_OV_COM:248,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-01-19",MC_APPROVED:"2022-03-21",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1",PA_OV_TOT:7,PA_OV_COM:7,PA_OV_REM:0,PB1_OV_TOT:64,PB1_OV_COM:64,PB1_OV_REM:0,PB2_OV_TOT:64,PB2_OV_COM:11,PB2_OV_REM:53,ACT_TAG_VALID:209,PUNCH_NUMBER_VALID:135,PUNCH_TAG_VALID:58},
    {SUBSYSTEM:"21-9970-9971-05",SUBSYSTEM_DESCR:"KSG Room Exhaust Fans, Ducting and Dampers",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-02-03",A_OV_TOT:115,A_OV_COM:115,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2022-01-07",WD_ACTUAL:"2022-01-10",MC_APPROVED:"2022-01-29",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1",PA_OV_TOT:7,PA_OV_COM:7,PA_OV_REM:0,PB1_OV_TOT:34,PB1_OV_COM:34,PB1_OV_REM:0,PB2_OV_TOT:12,PB2_OV_COM:4,PB2_OV_REM:8,ACT_TAG_VALID:108,PUNCH_NUMBER_VALID:53,PUNCH_TAG_VALID:26},
    {SUBSYSTEM:"21-9970-9971-06",SUBSYSTEM_DESCR:"HULL Chilled Water System and UCP",LOSH:"Y",MC_B30:"2022-03-24",A_OV_TOT:556,A_OV_COM:415,A_OV_REM:141,A_OV_PROG:74.64,A_OV_STATUS:"NA",A_PG:74.64,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P3",PA_OV_TOT:15,PA_OV_COM:15,PA_OV_REM:0,PB1_OV_TOT:18,PB1_OV_COM:14,PB1_OV_REM:4,PB2_OV_TOT:32,PB2_OV_COM:9,PB2_OV_REM:23,ACT_TAG_VALID:276,PUNCH_NUMBER_VALID:65,PUNCH_TAG_VALID:38},
    {SUBSYSTEM:"21-9970-9971-07",SUBSYSTEM_DESCR:"HULL Chiller Exhaust Fans, Ducting and Dampers",LOSH:"Y",MC_B30:"2022-03-24",A_OV_TOT:74,A_OV_COM:74,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-17",MC_APPROVED:"2022-03-31",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P3",PA_OV_TOT:11,PA_OV_COM:11,PA_OV_REM:0,PB1_OV_TOT:12,PB1_OV_COM:7,PB1_OV_REM:5,PB2_OV_TOT:13,PB2_OV_COM:1,PB2_OV_REM:12,ACT_TAG_VALID:71,PUNCH_NUMBER_VALID:36,PUNCH_TAG_VALID:16},
    {SUBSYSTEM:"21-9970-9971-08",SUBSYSTEM_DESCR:"HULL Machinery Space AHU Air Supply",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:472,A_OV_COM:442,A_OV_REM:30,A_OV_PROG:93.64,A_OV_STATUS:"greater than 90%",A_PG:93.64,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:18,PA_OV_COM:17,PA_OV_REM:1,PB1_OV_TOT:86,PB1_OV_COM:77,PB1_OV_REM:9,PB2_OV_TOT:27,PB2_OV_COM:5,PB2_OV_REM:22,ACT_TAG_VALID:422,PUNCH_NUMBER_VALID:131,PUNCH_TAG_VALID:64},
    {SUBSYSTEM:"21-9970-9971-09",SUBSYSTEM_DESCR:"HULL Machinery Space AHU Recirculation Air",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:216,A_OV_COM:216,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-10",MC_APPROVED:"2022-03-17",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P3",PA_OV_TOT:3,PA_OV_COM:3,PA_OV_REM:0,PB1_OV_TOT:40,PB1_OV_COM:37,PB1_OV_REM:3,PB2_OV_TOT:9,PB2_OV_COM:2,PB2_OV_REM:7,ACT_TAG_VALID:205,PUNCH_NUMBER_VALID:52,PUNCH_TAG_VALID:22},
    {SUBSYSTEM:"21-9970-9971-10",SUBSYSTEM_DESCR:"HULL Diesel Oil Pump Room Supply Fans, Ducting and Dampers",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:91,A_OV_COM:91,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-12",MC_APPROVED:"2022-03-25",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:7,PA_OV_COM:7,PA_OV_REM:0,PB1_OV_TOT:38,PB1_OV_COM:33,PB1_OV_REM:5,PB2_OV_TOT:18,PB2_OV_COM:3,PB2_OV_REM:15,ACT_TAG_VALID:81,PUNCH_NUMBER_VALID:63,PUNCH_TAG_VALID:25},
    {SUBSYSTEM:"21-9970-9971-11",SUBSYSTEM_DESCR:"HULL Diesel Oil Pump Room Exhaust Fans, Ducting and Dampers",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-03-03",A_OV_TOT:77,A_OV_COM:77,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-02-17",MC_APPROVED:"2022-02-28",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1",PA_OV_TOT:6,PA_OV_COM:6,PA_OV_REM:0,PB1_OV_TOT:36,PB1_OV_COM:36,PB1_OV_REM:0,PB2_OV_TOT:12,PB2_OV_COM:3,PB2_OV_REM:9,ACT_TAG_VALID:72,PUNCH_NUMBER_VALID:54,PUNCH_TAG_VALID:21},
    {SUBSYSTEM:"21-9970-9971-12",SUBSYSTEM_DESCR:"HULL Machinery Space HPU AHU, Ducting and Damper",LOSH:"Y",MC_B30:"2022-03-17",A_OV_TOT:139,A_OV_COM:139,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-03-17",MC_APPROVED:"2022-03-28",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:19,PA_OV_COM:19,PA_OV_REM:0,PB1_OV_TOT:25,PB1_OV_COM:16,PB1_OV_REM:9,PB2_OV_TOT:14,PB2_OV_COM:1,PB2_OV_REM:13,ACT_TAG_VALID:134,PUNCH_NUMBER_VALID:58,PUNCH_TAG_VALID:28},
    {SUBSYSTEM:"21-9976-9976-01",SUBSYSTEM_DESCR:"Topsides Pressurisation Fans",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:101,A_OV_COM:26,A_OV_REM:75,A_OV_PROG:25.74,A_OV_STATUS:"less than 70%",A_PG:25.74,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:5,PA_OV_COM:2,PA_OV_REM:3,PB1_OV_TOT:9,PB1_OV_COM:3,PB1_OV_REM:6,PB2_OV_TOT:4,PB2_OV_COM:1,PB2_OV_REM:3,ACT_TAG_VALID:72,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:7},
    {SUBSYSTEM:"21-9976-9976-02",SUBSYSTEM_DESCR:"Topsides Battery Exhaust Fans, Ducting and Dampers",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:79,A_OV_COM:22,A_OV_REM:57,A_OV_PROG:27.84,A_OV_STATUS:"less than 70%",A_PG:27.84,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:3,PA_OV_REM:0,PB1_OV_TOT:8,PB1_OV_COM:3,PB1_OV_REM:5,PB2_OV_TOT:6,PB2_OV_COM:2,PB2_OV_REM:4,ACT_TAG_VALID:57,PUNCH_NUMBER_VALID:17,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-9976-9976-03",SUBSYSTEM_DESCR:"Emergency Room ACUsandACCUs and UCPs",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:88,A_OV_COM:46,A_OV_REM:42,A_OV_PROG:52.27,A_OV_STATUS:"less than 70%",A_PG:52.27,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:11,PA_OV_COM:6,PA_OV_REM:5,PB1_OV_TOT:7,PB1_OV_COM:1,PB1_OV_REM:6,PB2_OV_TOT:6,PB2_OV_COM:0,PB2_OV_REM:6,ACT_TAG_VALID:53,PUNCH_NUMBER_VALID:24,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-9976-9977-01",SUBSYSTEM_DESCR:"Topsides HVAC UCPs, EDBs",BATCH:"Incentive 2",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:29,A_OV_COM:12,A_OV_REM:17,A_OV_PROG:41.37,A_OV_STATUS:"less than 70%",A_PG:41.37,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:8,PB1_OV_COM:2,PB1_OV_REM:6,PB2_OV_TOT:3,PB2_OV_COM:1,PB2_OV_REM:2,ACT_TAG_VALID:29,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-9976-9977-02",SUBSYSTEM_DESCR:"Topsides HVAC AHUs, Ducting and Dampers",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:475,A_OV_COM:255,A_OV_REM:220,A_OV_PROG:53.68,A_OV_STATUS:"less than 70%",A_PG:53.68,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:8,PA_OV_COM:8,PA_OV_REM:0,PB1_OV_TOT:4,PB1_OV_COM:4,PB1_OV_REM:0,ACT_TAG_VALID:393,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:7},
    {SUBSYSTEM:"21-9976-9977-03",SUBSYSTEM_DESCR:"Topsides HVAC Extracts, Ducting and Dampers",LOSH:"Y",MC_B30:"2022-04-07",A_OV_TOT:165,A_OV_COM:90,A_OV_REM:75,A_OV_PROG:54.54,A_OV_STATUS:"less than 70%",A_PG:54.54,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:4,PA_OV_COM:4,PA_OV_REM:0,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,PB2_OV_TOT:3,PB2_OV_COM:0,PB2_OV_REM:3,ACT_TAG_VALID:135,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:4}
    ],
  Queries: {
    SQL1: [
      { DESC: 'DataSource SPCS Data 03-Mar-20' }
    ],
    SQL2: [
      {CDATE:"2022-06-30T00:00:00"}
    ],
    SQL3: [
      {MC_PLAN:"2021-08-13",A_T:1552,A_A:555,A_C:482,A_O:997,A_PG:35.76,A_CUM:555,A_CUM_PG:0.69,PLAN_CUM:68693.57333048206,ACTUAL_CUM:68857,PA_O:18,PB1_O:97,PB2_O:19,PB3_O:0},
      {MC_PLAN:"2021-08-20",A_T:1119,A_A:102,A_C:102,A_O:1017,A_PG:9.12,A_CUM:657,A_CUM_PG:0.82,PLAN_CUM:67918.92722081483,ACTUAL_CUM:68741,PA_O:5,PB1_O:17,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-08-27",A_T:715,A_A:93,A_C:10,A_O:622,A_PG:13.01,A_CUM:750,A_CUM_PG:0.94,PLAN_CUM:67240.46292932943,ACTUAL_CUM:69748,PA_O:4,PB1_O:29,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-09-03",A_T:6242,A_A:0,A_C:0,A_O:6242,A_PG:0,A_CUM:750,A_CUM_PG:0.94,PLAN_CUM:66318.65409772044,ACTUAL_CUM:69904,PA_O:6,PB1_O:14,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-09-10",A_T:1556,A_A:87,A_C:19,A_O:1469,A_PG:5.59,A_CUM:837,A_CUM_PG:1.04,PLAN_CUM:65233.2176593381,PA_O:12,PB1_O:17,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-09-17",A_T:1580,A_A:48,A_C:0,A_O:1532,A_PG:3.04,A_CUM:885,A_CUM_PG:1.1,PLAN_CUM:63979.15028084907,PA_O:11,PB1_O:18,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-09-24",A_T:1124,A_A:98,A_C:23,A_O:1026,A_PG:8.72,A_CUM:983,A_CUM_PG:1.23,PLAN_CUM:62637.78967802465,PA_O:5,PB1_O:0,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-10-01",A_T:1245,A_A:70,A_C:20,A_O:1175,A_PG:5.62,A_CUM:1053,A_CUM_PG:1.31,PLAN_CUM:61174.48720221619,PA_O:8,PB1_O:0,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-10-08",A_T:423,A_A:79,A_C:26,A_O:344,A_PG:18.68,A_CUM:1132,A_CUM_PG:1.41,PLAN_CUM:59747.8308802539,PA_O:16,PB1_O:2,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-10-15",A_T:973,A_A:51,A_C:16,A_O:922,A_PG:5.24,A_CUM:1183,A_CUM_PG:1.48,PLAN_CUM:58060.89324121612,PA_O:25,PB1_O:9,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-10-22",A_T:1031,A_A:120,A_C:66,A_O:911,A_PG:11.64,A_CUM:1303,A_CUM_PG:1.63,PLAN_CUM:56248.08186559112,PA_O:18,PB1_O:0,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-10-29",A_T:3245,A_A:63,A_C:25,A_O:3182,A_PG:1.94,A_CUM:1366,A_CUM_PG:1.7,PLAN_CUM:54322.08696824535,PA_O:9,PB1_O:1,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-11-05",A_T:2238,A_A:138,A_C:19,A_O:2100,A_PG:6.17,A_CUM:1504,A_CUM_PG:1.88,PLAN_CUM:52329.74427595451,PA_O:30,PB1_O:7,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-11-12",A_T:1838,A_A:449,A_C:0,A_O:1389,A_PG:24.43,A_CUM:1953,A_CUM_PG:2.44,PLAN_CUM:50230.04388524004,PA_O:1,PB1_O:0,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-11-19",A_T:4904,A_A:535,A_C:57,A_O:4369,A_PG:10.91,A_CUM:2488,A_CUM_PG:3.1,PLAN_CUM:47971.9976513864,PA_O:65,PB1_O:1,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-11-26",A_T:5082,A_A:957,A_C:58,A_O:4125,A_PG:18.83,A_CUM:3445,A_CUM_PG:4.3,PLAN_CUM:45613.47224030309,PA_O:50,PB1_O:0,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-12-03",A_T:1723,A_A:161,A_C:0,A_O:1562,A_PG:9.34,A_CUM:3606,A_CUM_PG:4.5,PLAN_CUM:43362.54333082551,PA_O:14,PB1_O:4,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-12-10",A_T:2281,A_A:313,A_C:14,A_O:1968,A_PG:13.72,A_CUM:3919,A_CUM_PG:4.89,PLAN_CUM:41171.62579251709,PA_O:18,PB1_O:0,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-12-17",A_T:4857,A_A:714,A_C:90,A_O:4143,A_PG:14.7,A_CUM:4633,A_CUM_PG:5.78,PLAN_CUM:39052.72167713079,PA_O:9,PB1_O:3,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-12-24",A_T:1246,A_A:98,A_C:0,A_O:1148,A_PG:7.87,A_CUM:4731,A_CUM_PG:5.9,PLAN_CUM:37024.87512187446,PA_O:3,PB1_O:0,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-12-31",A_T:2719,A_A:394,A_C:24,A_O:2325,A_PG:14.49,A_CUM:5125,A_CUM_PG:6.4,PLAN_CUM:35173.14904014867,PA_O:24,PB1_O:10,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-01-07",A_T:1144,A_A:294,A_C:199,A_O:850,A_PG:25.7,A_CUM:5419,A_CUM_PG:6.76,PLAN_CUM:33502.62818507096,PA_O:0,PB1_O:8,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-01-14",A_T:1986,A_A:184,A_C:33,A_O:1802,A_PG:9.26,A_CUM:5603,A_CUM_PG:6.99,PLAN_CUM:31955.06175501426},
      {MC_PLAN:"2022-01-21",A_T:421,A_A:222,A_C:3,A_O:199,A_PG:52.73,A_CUM:5825,A_CUM_PG:7.27,PLAN_CUM:30639.13179909264,PA_O:11,PB1_O:0,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-01-28",A_T:465,A_A:58,A_C:11,A_O:407,A_PG:12.47,A_CUM:5883,A_CUM_PG:7.34,PLAN_CUM:29360.39992929543,PA_O:3,PB1_O:1,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-02-04",A_T:6,A_A:2,A_C:0,A_O:4,A_PG:33.33,A_CUM:5885,A_CUM_PG:7.34,PLAN_CUM:28285.14796386291,PA_O:0,PB1_O:1,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-02-11",A_T:1544,A_A:121,A_C:2,A_O:1423,A_PG:7.84,A_CUM:6006,A_CUM_PG:7.5,PLAN_CUM:27168.02656112659,PA_O:9,PB1_O:2,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-02-18",A_T:1792,A_A:189,A_C:4,A_O:1603,A_PG:10.55,A_CUM:6195,A_CUM_PG:7.73,PLAN_CUM:25944.39224863975,PA_O:9,PB1_O:3,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-02-25",A_T:1171,A_A:116,A_C:27,A_O:1055,A_PG:9.91,A_CUM:6311,A_CUM_PG:7.88,PLAN_CUM:24634.19008777003,PA_O:1,PB1_O:0,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-03-04",A_T:443,A_A:57,A_C:0,A_O:386,A_PG:12.87,A_CUM:6368,A_CUM_PG:7.95,PLAN_CUM:23287.55225129644,PA_O:0,PB1_O:0,PB2_O:0,PB3_O:0}
    ],
    SQL4: [
      {CUTOFF:"2018-06-22",WK_NO:"W2018_25"},
      {CUTOFF:"2018-06-29",WK_NO:"W2018_26"},
      {CUTOFF:"2018-07-06",WK_NO:"W2018_27"},
      {CUTOFF:"2018-07-13",WK_NO:"W2018_28"},
      {CUTOFF:"2018-07-20",WK_NO:"W2018_29"},
      {CUTOFF:"2018-07-27",WK_NO:"W2018_30"},
      {CUTOFF:"2018-08-03",WK_NO:"W2018_31"},
      {CUTOFF:"2018-08-10",WK_NO:"W2018_32"},
      {CUTOFF:"2018-08-17",WK_NO:"W2018_33"},
      {CUTOFF:"2018-08-24",WK_NO:"W2018_34"},
      {CUTOFF:"2018-08-31",WK_NO:"W2018_35"},
      {CUTOFF:"2018-09-07",WK_NO:"W2018_36"},
      {CUTOFF:"2018-09-14",WK_NO:"W2018_37"},
      {CUTOFF:"2018-09-21",WK_NO:"W2018_38"},
      {CUTOFF:"2018-09-28",WK_NO:"W2018_39"},
      {CUTOFF:"2018-10-05",WK_NO:"W2018_40"},
      {CUTOFF:"2018-10-12",WK_NO:"W2018_41"},
      {CUTOFF:"2018-10-19",WK_NO:"W2018_42"},
      {CUTOFF:"2018-10-26",WK_NO:"W2018_43"},
      {CUTOFF:"2018-11-02",WK_NO:"W2018_44"},
      {CUTOFF:"2018-11-09",WK_NO:"W2018_45"},
      {CUTOFF:"2018-11-16",WK_NO:"W2018_46"},
      {CUTOFF:"2018-11-23",WK_NO:"W2018_47"},
      {CUTOFF:"2018-11-30",WK_NO:"W2018_48"},
      {CUTOFF:"2018-12-07",WK_NO:"W2018_49"},
      {CUTOFF:"2018-12-14",WK_NO:"W2018_50"},
      {CUTOFF:"2018-12-21",WK_NO:"W2018_51"},
      {CUTOFF:"2018-12-28",WK_NO:"W2018_52"},
      {CUTOFF:"2019-01-04",WK_NO:"W2019_01"},
      {CUTOFF:"2019-01-11",WK_NO:"W2019_02"},
      {CUTOFF:"2019-01-18",WK_NO:"W2019_03"},
      {CUTOFF:"2019-01-25",WK_NO:"W2019_04"},
      {CUTOFF:"2019-02-01",WK_NO:"W2019_05"},
      {CUTOFF:"2019-02-08",WK_NO:"W2019_06"},
      {CUTOFF:"2019-02-15",WK_NO:"W2019_07"},
      {CUTOFF:"2019-02-22",WK_NO:"W2019_08"},
      {CUTOFF:"2019-03-01",WK_NO:"W2019_09"},
      {CUTOFF:"2019-03-08",WK_NO:"W2019_10"},
      {CUTOFF:"2019-03-15",WK_NO:"W2019_11"},
      {CUTOFF:"2019-03-22",WK_NO:"W2019_12"},
      {CUTOFF:"2019-03-29",WK_NO:"W2019_13"},
      {CUTOFF:"2019-04-05",WK_NO:"W2019_14"},
      {CUTOFF:"2019-04-12",WK_NO:"W2019_15"},
      {CUTOFF:"2019-04-19",WK_NO:"W2019_16"},
      {CUTOFF:"2019-04-26",WK_NO:"W2019_17"},
      {CUTOFF:"2019-05-03",WK_NO:"W2019_18"},
      {CUTOFF:"2019-05-10",WK_NO:"W2019_19"},
      {CUTOFF:"2019-05-17",WK_NO:"W2019_20"},
      {CUTOFF:"2019-05-24",WK_NO:"W2019_21"},
      {CUTOFF:"2019-05-31",WK_NO:"W2019_22"},
      {CUTOFF:"2019-06-07",WK_NO:"W2019_23"},
      {CUTOFF:"2019-06-14",WK_NO:"W2019_24"},
      {CUTOFF:"2019-06-21",WK_NO:"W2019_25"},
      {CUTOFF:"2019-06-28",WK_NO:"W2019_26"},
      {CUTOFF:"2019-07-05",WK_NO:"W2019_27"},
      {CUTOFF:"2019-07-12",WK_NO:"W2019_28"},
      {CUTOFF:"2019-07-19",WK_NO:"W2019_29"},
      {CUTOFF:"2019-07-26",WK_NO:"W2019_30"},
      {CUTOFF:"2019-08-02",WK_NO:"W2019_31"},
      {CUTOFF:"2019-08-09",WK_NO:"W2019_32"},
      {CUTOFF:"2019-08-16",WK_NO:"W2019_33"},
      {CUTOFF:"2019-08-23",WK_NO:"W2019_34"},
      {CUTOFF:"2019-08-30",WK_NO:"W2019_35"},
      {CUTOFF:"2019-09-06",WK_NO:"W2019_36"},
      {CUTOFF:"2019-09-13",WK_NO:"W2019_37"},
      {CUTOFF:"2019-09-20",WK_NO:"W2019_38"},
      {CUTOFF:"2019-09-27",WK_NO:"W2019_39"},
      {CUTOFF:"2019-10-04",WK_NO:"W2019_40"},
      {CUTOFF:"2019-10-11",WK_NO:"W2019_41"},
      {CUTOFF:"2019-10-18",WK_NO:"W2019_42"},
      {CUTOFF:"2019-10-25",WK_NO:"W2019_43"},
      {CUTOFF:"2019-11-01",WK_NO:"W2019_44"},
      {CUTOFF:"2019-11-08",WK_NO:"W2019_45"},
      {CUTOFF:"2019-11-15",WK_NO:"W2019_46"},
      {CUTOFF:"2019-11-22",WK_NO:"W2019_47"},
      {CUTOFF:"2019-11-29",WK_NO:"W2019_48"},
      {CUTOFF:"2019-12-06",WK_NO:"W2019_49"},
      {CUTOFF:"2019-12-13",WK_NO:"W2019_50"},
      {CUTOFF:"2019-12-20",WK_NO:"W2019_51"},
      {CUTOFF:"2019-12-27",WK_NO:"W2019_52"},
      {CUTOFF:"2020-01-03",WK_NO:"W2020_01"},
      {CUTOFF:"2020-01-10",WK_NO:"W2020_02"},
      {CUTOFF:"2020-01-17",WK_NO:"W2020_03"},
      {CUTOFF:"2020-01-24",WK_NO:"W2020_04"},
      {CUTOFF:"2020-01-31",WK_NO:"W2020_05"},
      {CUTOFF:"2020-02-07",WK_NO:"W2020_06"},
      {CUTOFF:"2020-02-14",WK_NO:"W2020_07"},
      {CUTOFF:"2020-02-21",WK_NO:"W2020_08"},
      {CUTOFF:"2020-02-28",WK_NO:"W2020_09"},
      {CUTOFF:"2020-03-06",WK_NO:"W2020_10"},
      {CUTOFF:"2020-03-13",WK_NO:"W2020_11"},
      {CUTOFF:"2020-03-20",WK_NO:"W2020_12"},
      {CUTOFF:"2020-03-27",WK_NO:"W2020_13"},
      {CUTOFF:"2020-04-03",WK_NO:"W2020_14"},
      {CUTOFF:"2020-04-10",WK_NO:"W2020_15"},
      {CUTOFF:"2020-04-17",WK_NO:"W2020_16"},
      {CUTOFF:"2020-04-24",WK_NO:"W2020_17"},
      {CUTOFF:"2020-05-01",WK_NO:"W2020_18"},
      {CUTOFF:"2020-05-08",WK_NO:"W2020_19"},
      {CUTOFF:"2020-05-15",WK_NO:"W2020_20"},
      {CUTOFF:"2020-05-22",WK_NO:"W2020_21"},
      {CUTOFF:"2020-05-29",WK_NO:"W2020_22"},
      {CUTOFF:"2020-06-05",WK_NO:"W2020_23"},
      {CUTOFF:"2020-06-12",WK_NO:"W2020_24"},
      {CUTOFF:"2020-06-19",WK_NO:"W2020_25"},
      {CUTOFF:"2020-06-26",WK_NO:"W2020_26"},
      {CUTOFF:"2020-07-03",WK_NO:"W2020_27"},
      {CUTOFF:"2020-07-10",WK_NO:"W2020_28"},
      {CUTOFF:"2020-07-17",WK_NO:"W2020_29"},
      {CUTOFF:"2020-07-24",WK_NO:"W2020_30"},
      {CUTOFF:"2020-07-31",WK_NO:"W2020_31"},
      {CUTOFF:"2020-08-07",WK_NO:"W2020_32"},
      {CUTOFF:"2020-08-14",WK_NO:"W2020_33"},
      {CUTOFF:"2020-08-21",WK_NO:"W2020_34"},
      {CUTOFF:"2020-08-28",WK_NO:"W2020_35"},
      {CUTOFF:"2020-09-04",WK_NO:"W2020_36"},
      {CUTOFF:"2020-09-11",WK_NO:"W2020_37"},
      {CUTOFF:"2020-09-18",WK_NO:"W2020_38"},
      {CUTOFF:"2020-09-25",WK_NO:"W2020_39"},
      {CUTOFF:"2020-10-02",WK_NO:"W2020_40"},
      {CUTOFF:"2020-10-09",WK_NO:"W2020_41"},
      {CUTOFF:"2020-10-16",WK_NO:"W2020_42"},
      {CUTOFF:"2020-10-23",WK_NO:"W2020_43"},
      {CUTOFF:"2020-10-30",WK_NO:"W2020_44"},
      {CUTOFF:"2020-11-06",WK_NO:"W2020_45"},
      {CUTOFF:"2020-11-13",WK_NO:"W2020_46"},
      {CUTOFF:"2020-11-20",WK_NO:"W2020_47"},
      {CUTOFF:"2020-11-27",WK_NO:"W2020_48"},
      {CUTOFF:"2020-12-04",WK_NO:"W2020_49"},
      {CUTOFF:"2020-12-11",WK_NO:"W2020_50"},
      {CUTOFF:"2020-12-18",WK_NO:"W2020_51"},
      {CUTOFF:"2020-12-25",WK_NO:"W2020_52"},
      {CUTOFF:"2021-01-01",WK_NO:"W2020_53"},
      {CUTOFF:"2021-01-08",WK_NO:"W2021_01"},
      {CUTOFF:"2021-01-15",WK_NO:"W2021_02"},
      {CUTOFF:"2021-01-22",WK_NO:"W2021_03"},
      {CUTOFF:"2021-01-29",WK_NO:"W2021_04"},
      {CUTOFF:"2021-02-05",WK_NO:"W2021_05"},
      {CUTOFF:"2021-02-12",WK_NO:"W2021_06"},
      {CUTOFF:"2021-02-19",WK_NO:"W2021_07"},
      {CUTOFF:"2021-02-26",WK_NO:"W2021_08"},
      {CUTOFF:"2021-03-05",WK_NO:"W2021_09"},
      {CUTOFF:"2021-03-12",WK_NO:"W2021_10"},
      {CUTOFF:"2021-03-19",WK_NO:"W2021_11"},
      {CUTOFF:"2021-03-26",WK_NO:"W2021_12"},
      {CUTOFF:"2021-04-02",WK_NO:"W2021_13"},
      {CUTOFF:"2021-04-09",WK_NO:"W2021_14"},
      {CUTOFF:"2021-04-16",WK_NO:"W2021_15"},
      {CUTOFF:"2021-04-23",WK_NO:"W2021_16"},
      {CUTOFF:"2021-04-30",WK_NO:"W2021_17"},
      {CUTOFF:"2021-05-07",WK_NO:"W2021_18"},
      {CUTOFF:"2021-05-14",WK_NO:"W2021_19"},
      {CUTOFF:"2021-05-21",WK_NO:"W2021_20"},
      {CUTOFF:"2021-05-28",WK_NO:"W2021_21"},
      {CUTOFF:"2021-06-04",WK_NO:"W2021_22"},
      {CUTOFF:"2021-06-11",WK_NO:"W2021_23"},
      {CUTOFF:"2021-06-18",WK_NO:"W2021_24"},
      {CUTOFF:"2021-06-25",WK_NO:"W2021_25"},
      {CUTOFF:"2021-07-02",WK_NO:"W2021_26"},
      {CUTOFF:"2021-07-09",WK_NO:"W2021_27"},
      {CUTOFF:"2021-07-16",WK_NO:"W2021_28"},
      {CUTOFF:"2021-07-23",WK_NO:"W2021_29"},
      {CUTOFF:"2021-07-30",WK_NO:"W2021_30"},
      {CUTOFF:"2021-08-06",WK_NO:"W2021_31"},
      {CUTOFF:"2021-08-13",WK_NO:"W2021_32"},
      {CUTOFF:"2021-08-20",WK_NO:"W2021_33"},
      {CUTOFF:"2021-08-27",WK_NO:"W2021_34"},
      {CUTOFF:"2021-09-03",WK_NO:"W2021_35"},
      {CUTOFF:"2021-09-10",WK_NO:"W2021_36"},
      {CUTOFF:"2021-09-17",WK_NO:"W2021_37"},
      {CUTOFF:"2021-09-24",WK_NO:"W2021_38"},
      {CUTOFF:"2021-10-01",WK_NO:"W2021_39"},
      {CUTOFF:"2021-10-08",WK_NO:"W2021_40"},
      {CUTOFF:"2021-10-15",WK_NO:"W2021_41"},
      {CUTOFF:"2021-10-22",WK_NO:"W2021_42"},
      {CUTOFF:"2021-10-29",WK_NO:"W2021_43"},
      {CUTOFF:"2021-11-05",WK_NO:"W2021_44"},
      {CUTOFF:"2021-11-12",WK_NO:"W2021_45"},
      {CUTOFF:"2021-11-19",WK_NO:"W2021_46"},
      {CUTOFF:"2021-11-26",WK_NO:"W2021_47"},
      {CUTOFF:"2021-12-03",WK_NO:"W2021_48"},
      {CUTOFF:"2021-12-10",WK_NO:"W2021_49"},
      {CUTOFF:"2021-12-17",WK_NO:"W2021_50"},
      {CUTOFF:"2021-12-24",WK_NO:"W2021_51"},
      {CUTOFF:"2021-12-31",WK_NO:"W2021_52"},
      {CUTOFF:"2022-01-07",WK_NO:"W2022_01"},
      {CUTOFF:"2022-01-14",WK_NO:"W2022_02"},
      {CUTOFF:"2022-01-21",WK_NO:"W2022_03"},
      {CUTOFF:"2022-01-28",WK_NO:"W2022_04"},
      {CUTOFF:"2022-02-04",WK_NO:"W2022_05"},
      {CUTOFF:"2022-02-11",WK_NO:"W2022_06"},
      {CUTOFF:"2022-02-18",WK_NO:"W2022_07"},
      {CUTOFF:"2022-02-25",WK_NO:"W2022_08"},
      {CUTOFF:"2022-03-04",WK_NO:"W2022_09"},
      {CUTOFF:"2022-03-11",WK_NO:"W2022_10"},
      {CUTOFF:"2022-03-18",WK_NO:"W2022_11"},
      {CUTOFF:"2022-03-25",WK_NO:"W2022_12"},
      {CUTOFF:"2022-04-01",WK_NO:"W2022_13"},
      {CUTOFF:"2022-04-08",WK_NO:"W2022_14"},
      {CUTOFF:"2022-04-15",WK_NO:"W2022_15"}
    ],
    SQL5: [
      {TOTAL:561,NOTSTARTED:61,INPROGRESS:366,AITR_COMPLETED:20,PARTIAL_MC_COMPLETE:30,FULL_MC_COMPLETE:84}
    ],
    SQL6: [
      {CUTOFF:"2021-07-30",WK_NO:"W2021_30",TOTAL:15342,REMAIN:16199,WKS_TREND:10000},
      {CUTOFF:"2021-08-06",WK_NO:"W2021_31",TOTAL:14223,REMAIN:16047,WKS_TREND:10000},
      {CUTOFF:"2021-09-03",WK_NO:"W2021_35",TOTAL:3762,WKS_TREND:10000},
      {CUTOFF:"2021-09-10",WK_NO:"W2021_36",TOTAL:2638,WKS_TREND:10000},
      {CUTOFF:"2021-10-01",WK_NO:"W2021_39",TOTAL:0,WKS_TREND:10000},
      {CUTOFF:"2021-08-13",WK_NO:"W2021_32",TOTAL:13115,REMAIN:15886,WKS_TREND:10000},
      {CUTOFF:"2021-08-20",WK_NO:"W2021_33",TOTAL:6861,REMAIN:6861,WKS_TREND:10000},
      {CUTOFF:"2021-08-27",WK_NO:"W2021_34",TOTAL:5320,REMAIN:15724,WKS_TREND:10000},
      {CUTOFF:"2021-09-17",WK_NO:"W2021_37",TOTAL:1393,WKS_TREND:10000},
      {CUTOFF:"2021-09-24",WK_NO:"W2021_38",TOTAL:974,WKS_TREND:10000}
    ]
  }
}