import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 530,
      CanvasHeight: 180,
      CanvasBorderWeight: 0.3,
      CanvasBorderColor: 'Transparent',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 310,
      CanvasChartHeight: 270,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: 'Transparent',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },

    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },

    DataItems: [
      {ITEM:"Structure",RT_FILMS_STR_JOINT_PIPE:13.37,RT_TEST_LEN:9.14,RT_REPAIR_LEN:26254972,RT_REPAIR_RATE: 0.12},
      {ITEM:"Pipe",RT_FILMS_STR_JOINT_PIPE:2752,RT_TEST_LEN:0,RT_REPAIR_LEN:34,RT_REPAIR_RATE: 1.24}
      ],

    Queries: {
    }
}