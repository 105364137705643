import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 580,
      CanvasHeight: 380,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#e0e0e0',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 380,
      CanvasChartHeight: 280,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: '', x:20, y: 10, font: 'roboto', class: 'regular', size: 12, color: '#424242', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: -50, y: 20, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'start' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: -30,
          y: 20,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'start'
        },
      ]
    },
    Queries: {
      SQL1: [
        {TOTAL:16937,COMPLETE:5073,REMAIN:11864,PROG:30}
      ],
      SQL2:  [
        {WK_NO:"W2022_11"}
      ],
    },
    DataItems: [
      {CUTOFF:"2022-01-20",WK_NO:"W2022_03",WEEKLY:187,REMAIN:12779},
      {CUTOFF:"2022-01-27",WK_NO:"W2022_04",WEEKLY:622,REMAIN:12157},
      {CUTOFF:"2022-02-03",WK_NO:"W2022_05",WEEKLY:95,REMAIN:12062},
      {CUTOFF:"2022-02-10",WK_NO:"W2022_06",WEEKLY:198,REMAIN:11864},
      {CUTOFF:"2022-02-17",WK_NO:"W2022_07",WEEKLY:0,REMAIN:11864,WKS_TREND:11864},
      {CUTOFF:"2022-02-24",WK_NO:"W2022_08",WEEKLY:0,WKS_TREND:11626,'4WKS':238},
      {CUTOFF:"2022-03-03",WK_NO:"W2022_09",WEEKLY:0,WKS_TREND:11388,'4WKS':238},
      {CUTOFF:"2022-03-10",WK_NO:"W2022_10",WEEKLY:0,WKS_TREND:11150,'4WKS':238},
      {CUTOFF:"2022-03-17",WK_NO:"W2022_11",WEEKLY:0,WKS_TREND:10912,'4WKS':238}
],
}