import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 1700,
    CanvasHeight: 600,
    CanvasBorderWeight: 0.1,
    CanvasBorderColor: '#e0e0e0',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.2,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 380,
    CanvasChartHeight: 280,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  Text: {
    Text: [
      { text: '', x:20, y: 10, font: 'roboto', class: 'regular', size: 12, color: '#424242', align: 'start' },
    ],
    Filter: [
      { name: 'MOD'   , x: -50, y: 20, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'start' },
    ],
    Sql: [
      {
        query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
        text: 'Data Source',
        x: -30,
        y: 20,
        font: 'roboto',
        style: 'regular',
        size: 11,
        color: '#44A9DF',
        align: 'start'
      },
    ]
  },
  Queries: {
    SQL1: [
      {CUTOFF:"2022-01-07",WK_NO:"W2022_01",PSR_PLAN_CUM:4026,PSR_PLAN_WK:128,PSR_FORECAST_CUM:4220,PSR_FORECAST_WK:164,PSR_ACTUAL_CUM:3783,PSR_ACTUAL_WK:29},
      {CUTOFF:"2022-01-14",WK_NO:"W2022_02",PSR_PLAN_CUM:4289,PSR_PLAN_WK:263,PSR_FORECAST_CUM:4247,PSR_FORECAST_WK:27,PSR_ACTUAL_CUM:3856,PSR_ACTUAL_WK:73},
      {CUTOFF:"2022-01-21",WK_NO:"W2022_03",PSR_PLAN_CUM:4362,PSR_PLAN_WK:73,PSR_FORECAST_CUM:4351,PSR_FORECAST_WK:104,PSR_ACTUAL_CUM:4333,PSR_ACTUAL_WK:477},
      // {CUTOFF:"2022-01-28",WK_NO:"W2022_04",PSR_PLAN_CUM:4687,PSR_PLAN_WK:325,PSR_FORECAST_CUM:4596,PSR_FORECAST_WK:245,PSR_ACTUAL_CUM:4465,PSR_ACTUAL_WK:132},
      // {CUTOFF:"2022-02-04",WK_NO:"W2022_05",PSR_PLAN_CUM:4801,PSR_PLAN_WK:114,PSR_FORECAST_CUM:4673,PSR_FORECAST_WK:77,PSR_ACTUAL_CUM:4492,PSR_ACTUAL_WK:27},
      // {CUTOFF:"2022-02-11",WK_NO:"W2022_06",PSR_PLAN_CUM:5610,PSR_PLAN_WK:809,PSR_FORECAST_CUM:5427,PSR_FORECAST_WK:754,PSR_ACTUAL_CUM:4846,PSR_ACTUAL_WK:354},
      // {CUTOFF:"2022-02-18",WK_NO:"W2022_07",PSR_PLAN_CUM:6152,PSR_PLAN_WK:542,PSR_FORECAST_CUM:5858,PSR_FORECAST_WK:431,PSR_ACTUAL_CUM:5061,PSR_ACTUAL_WK:215},
      // {CUTOFF:"2022-02-25",WK_NO:"W2022_08",PSR_PLAN_CUM:6489,PSR_PLAN_WK:337,PSR_FORECAST_CUM:6165,PSR_FORECAST_WK:307,PSR_ACTUAL_CUM:5398,PSR_ACTUAL_WK:337},
      // {CUTOFF:"2022-03-04",WK_NO:"W2022_09",PSR_PLAN_CUM:6817,PSR_PLAN_WK:328,PSR_FORECAST_CUM:6473,PSR_FORECAST_WK:308,PSR_ACTUAL_CUM:5592,PSR_ACTUAL_WK:194},
      // {CUTOFF:"2022-03-11",WK_NO:"W2022_10",PSR_PLAN_CUM:7426,PSR_PLAN_WK:609,PSR_FORECAST_CUM:6961,PSR_FORECAST_WK:488,PSR_ACTUAL_CUM:6150,PSR_ACTUAL_WK:558},
      // {CUTOFF:"2022-03-18",WK_NO:"W2022_11",PSR_PLAN_CUM:7991,PSR_PLAN_WK:565,PSR_FORECAST_CUM:7570,PSR_FORECAST_WK:609,PSR_ACTUAL_CUM:6401,PSR_ACTUAL_WK:251},
      // {CUTOFF:"2022-03-25",WK_NO:"W2022_12",PSR_PLAN_CUM:8495,PSR_PLAN_WK:504,PSR_FORECAST_CUM:8169,PSR_FORECAST_WK:599,PSR_ACTUAL_CUM:7117,PSR_ACTUAL_WK:716},
      // {CUTOFF:"2022-04-01",WK_NO:"W2022_13",PSR_PLAN_CUM:9272,PSR_PLAN_WK:777,PSR_FORECAST_CUM:8870,PSR_FORECAST_WK:701,PSR_ACTUAL_CUM:7892,PSR_ACTUAL_WK:775},
      // {CUTOFF:"2022-04-08",WK_NO:"W2022_14",PSR_PLAN_CUM:9593,PSR_PLAN_WK:321,PSR_FORECAST_CUM:9318,PSR_FORECAST_WK:448,PSR_ACTUAL_CUM:8318,PSR_ACTUAL_WK:426},
      // {CUTOFF:"2022-04-15",WK_NO:"W2022_15",PSR_PLAN_CUM:11432,PSR_PLAN_WK:1839,PSR_FORECAST_CUM:11198,PSR_FORECAST_WK:1880,PSR_ACTUAL_CUM:9232,PSR_ACTUAL_WK:914},
      // {CUTOFF:"2022-04-22",WK_NO:"W2022_16",PSR_PLAN_CUM:11798,PSR_PLAN_WK:366,PSR_FORECAST_CUM:11807,PSR_FORECAST_WK:609,PSR_ACTUAL_CUM:11048,PSR_ACTUAL_WK:1816},
      // {CUTOFF:"2022-04-29",WK_NO:"W2022_17",PSR_PLAN_CUM:14547,PSR_PLAN_WK:2749,PSR_FORECAST_CUM:13681,PSR_FORECAST_WK:1874,PSR_ACTUAL_CUM:12658,PSR_ACTUAL_WK:1610},
      // {CUTOFF:"2022-05-06",WK_NO:"W2022_18",PSR_PLAN_CUM:16654,PSR_PLAN_WK:2107,PSR_FORECAST_CUM:15753,PSR_FORECAST_WK:2072,PSR_ACTUAL_CUM:13208,PSR_ACTUAL_WK:550},
      // {CUTOFF:"2022-05-13",WK_NO:"W2022_19",PSR_PLAN_CUM:16825,PSR_PLAN_WK:171,PSR_FORECAST_CUM:16485,PSR_FORECAST_WK:732,PSR_ACTUAL_CUM:14551,PSR_ACTUAL_WK:1343},
      // {CUTOFF:"2022-05-20",WK_NO:"W2022_20",PSR_PLAN_CUM:17237,PSR_PLAN_WK:412,PSR_FORECAST_CUM:17246,PSR_FORECAST_WK:761,PSR_ACTUAL_CUM:15181,PSR_ACTUAL_WK:630},
      // {CUTOFF:"2022-05-27",WK_NO:"W2022_21",PSR_PLAN_CUM:17894,PSR_PLAN_WK:657,PSR_FORECAST_CUM:17966,PSR_FORECAST_WK:720,PSR_ACTUAL_CUM:15501,PSR_ACTUAL_WK:320},
      // {CUTOFF:"2022-06-03",WK_NO:"W2022_22",PSR_PLAN_CUM:18802,PSR_PLAN_WK:908,PSR_FORECAST_CUM:19115,PSR_FORECAST_WK:1149,PSR_ACTUAL_CUM:15848,PSR_ACTUAL_WK:347},
      // {CUTOFF:"2022-06-10",WK_NO:"W2022_23",PSR_PLAN_CUM:20035,PSR_PLAN_WK:1233,PSR_FORECAST_CUM:19848,PSR_FORECAST_WK:733,PSR_ACTUAL_CUM:16606,PSR_ACTUAL_WK:758},
      // {CUTOFF:"2022-06-17",WK_NO:"W2022_24",PSR_PLAN_CUM:21290,PSR_PLAN_WK:1255,PSR_FORECAST_CUM:20849,PSR_FORECAST_WK:1001,PSR_ACTUAL_CUM:16905,PSR_ACTUAL_WK:299},
      // {CUTOFF:"2022-06-24",WK_NO:"W2022_25",PSR_PLAN_CUM:21722,PSR_PLAN_WK:432,PSR_FORECAST_CUM:22303,PSR_FORECAST_WK:1454},
      // {CUTOFF:"2022-07-01",WK_NO:"W2022_26",PSR_PLAN_CUM:22493,PSR_PLAN_WK:771,PSR_FORECAST_CUM:23829,PSR_FORECAST_WK:1526},
      // {CUTOFF:"2022-07-08",WK_NO:"W2022_27",PSR_PLAN_CUM:23118,PSR_PLAN_WK:625,PSR_FORECAST_CUM:25222,PSR_FORECAST_WK:1393},
      // {CUTOFF:"2022-07-15",WK_NO:"W2022_28",PSR_PLAN_CUM:23624,PSR_PLAN_WK:506,PSR_FORECAST_CUM:26182,PSR_FORECAST_WK:960},
      // {CUTOFF:"2022-07-22",WK_NO:"W2022_29",PSR_PLAN_CUM:24749,PSR_PLAN_WK:1125,PSR_FORECAST_CUM:26840,PSR_FORECAST_WK:658},
      // {CUTOFF:"2022-07-29",WK_NO:"W2022_30",PSR_PLAN_CUM:26058,PSR_PLAN_WK:1309,PSR_FORECAST_CUM:27483,PSR_FORECAST_WK:643},
      // {CUTOFF:"2022-08-05",WK_NO:"W2022_31",PSR_PLAN_CUM:27010,PSR_PLAN_WK:952,PSR_FORECAST_CUM:29147,PSR_FORECAST_WK:1664},
      // {CUTOFF:"2022-08-12",WK_NO:"W2022_32",PSR_PLAN_CUM:28526,PSR_PLAN_WK:1516,PSR_FORECAST_CUM:30375,PSR_FORECAST_WK:1228},
      // {CUTOFF:"2022-08-19",WK_NO:"W2022_33",PSR_PLAN_CUM:29896,PSR_PLAN_WK:1370,PSR_FORECAST_CUM:31390,PSR_FORECAST_WK:1015},
      // {CUTOFF:"2022-08-26",WK_NO:"W2022_34",PSR_PLAN_CUM:30767,PSR_PLAN_WK:871,PSR_FORECAST_CUM:33027,PSR_FORECAST_WK:1637},
      // {CUTOFF:"2022-09-02",WK_NO:"W2022_35",PSR_PLAN_CUM:32620,PSR_PLAN_WK:1853,PSR_FORECAST_CUM:34159,PSR_FORECAST_WK:1132},
      // {CUTOFF:"2022-09-09",WK_NO:"W2022_36",PSR_PLAN_CUM:35373,PSR_PLAN_WK:2753,PSR_FORECAST_CUM:35546,PSR_FORECAST_WK:1387},
      // {CUTOFF:"2022-09-16",WK_NO:"W2022_37",PSR_PLAN_CUM:36081,PSR_PLAN_WK:708,PSR_FORECAST_CUM:37293,PSR_FORECAST_WK:1747},
      // {CUTOFF:"2022-09-23",WK_NO:"W2022_38",PSR_PLAN_CUM:37319,PSR_PLAN_WK:1238,PSR_FORECAST_CUM:38410,PSR_FORECAST_WK:1117},
      // {CUTOFF:"2022-09-30",WK_NO:"W2022_39",PSR_PLAN_CUM:38367,PSR_PLAN_WK:1048,PSR_FORECAST_CUM:39631,PSR_FORECAST_WK:1221},
      // {CUTOFF:"2022-10-07",WK_NO:"W2022_40",PSR_PLAN_CUM:39405,PSR_PLAN_WK:1038,PSR_FORECAST_CUM:40655,PSR_FORECAST_WK:1024},
      // {CUTOFF:"2022-10-14",WK_NO:"W2022_41",PSR_PLAN_CUM:39957,PSR_PLAN_WK:552,PSR_FORECAST_CUM:41238,PSR_FORECAST_WK:583},
      // {CUTOFF:"2022-10-21",WK_NO:"W2022_42",PSR_PLAN_CUM:41116,PSR_PLAN_WK:1159,PSR_FORECAST_CUM:41877,PSR_FORECAST_WK:639},
      // {CUTOFF:"2022-10-28",WK_NO:"W2022_43",PSR_PLAN_CUM:41568,PSR_PLAN_WK:452,PSR_FORECAST_CUM:42640,PSR_FORECAST_WK:763},
      // {CUTOFF:"2022-11-04",WK_NO:"W2022_44",PSR_PLAN_CUM:43083,PSR_PLAN_WK:1515,PSR_FORECAST_CUM:43269,PSR_FORECAST_WK:629},
      // {CUTOFF:"2022-11-11",WK_NO:"W2022_45",PSR_PLAN_CUM:44027,PSR_PLAN_WK:944,PSR_FORECAST_CUM:44056,PSR_FORECAST_WK:787},
      // {CUTOFF:"2022-11-18",WK_NO:"W2022_46",PSR_PLAN_CUM:44542,PSR_PLAN_WK:515,PSR_FORECAST_CUM:44443,PSR_FORECAST_WK:387},
      // {CUTOFF:"2022-11-25",WK_NO:"W2022_47",PSR_PLAN_CUM:44983,PSR_PLAN_WK:441,PSR_FORECAST_CUM:45124,PSR_FORECAST_WK:681},
      // {CUTOFF:"2022-12-02",WK_NO:"W2022_48",PSR_PLAN_CUM:45152,PSR_PLAN_WK:169,PSR_FORECAST_CUM:45355,PSR_FORECAST_WK:231},
      // {CUTOFF:"2022-12-09",WK_NO:"W2022_49",PSR_PLAN_CUM:45564,PSR_PLAN_WK:412,PSR_FORECAST_CUM:45517,PSR_FORECAST_WK:162},
      // {CUTOFF:"2022-12-16",WK_NO:"W2022_50",PSR_PLAN_CUM:45888,PSR_PLAN_WK:324,PSR_FORECAST_CUM:45616,PSR_FORECAST_WK:99},
      // {CUTOFF:"2022-12-23",WK_NO:"W2022_51",PSR_PLAN_CUM:46091,PSR_PLAN_WK:203,PSR_FORECAST_CUM:45768,PSR_FORECAST_WK:152},
      // {CUTOFF:"2022-12-30",WK_NO:"W2022_52",PSR_PLAN_CUM:46272,PSR_PLAN_WK:181,PSR_FORECAST_CUM:45865,PSR_FORECAST_WK:97},
      // {CUTOFF:"2023-01-06",WK_NO:"W2023_01",PSR_PLAN_CUM:46516,PSR_PLAN_WK:244,PSR_FORECAST_CUM:46081,PSR_FORECAST_WK:216},
      // {CUTOFF:"2023-01-13",WK_NO:"W2023_02",PSR_PLAN_CUM:46723,PSR_PLAN_WK:207,PSR_FORECAST_CUM:46257,PSR_FORECAST_WK:176},
      // {CUTOFF:"2023-01-20",WK_NO:"W2023_03",PSR_PLAN_CUM:47035,PSR_PLAN_WK:312,PSR_FORECAST_CUM:46550,PSR_FORECAST_WK:293},
      // {CUTOFF:"2023-01-27",WK_NO:"W2023_04",PSR_PLAN_CUM:47219,PSR_PLAN_WK:184,PSR_FORECAST_CUM:46691,PSR_FORECAST_WK:141},
      // {CUTOFF:"2023-02-03",WK_NO:"W2023_05",PSR_PLAN_CUM:47279,PSR_PLAN_WK:60,PSR_FORECAST_CUM:46848,PSR_FORECAST_WK:157},
      // {CUTOFF:"2023-02-10",WK_NO:"W2023_06",PSR_PLAN_CUM:47465,PSR_PLAN_WK:186,PSR_FORECAST_CUM:47056,PSR_FORECAST_WK:208},
      // {CUTOFF:"2023-02-17",WK_NO:"W2023_07",PSR_PLAN_CUM:47849,PSR_PLAN_WK:384,PSR_FORECAST_CUM:47358,PSR_FORECAST_WK:302},
      // {CUTOFF:"2023-02-24",WK_NO:"W2023_08",PSR_PLAN_CUM:48304,PSR_PLAN_WK:455,PSR_FORECAST_CUM:47726,PSR_FORECAST_WK:368},
      // {CUTOFF:"2023-03-03",WK_NO:"W2023_09",PSR_PLAN_CUM:48575,PSR_PLAN_WK:271,PSR_FORECAST_CUM:48240,PSR_FORECAST_WK:514},
      // {CUTOFF:"2023-03-10",WK_NO:"W2023_10",PSR_PLAN_CUM:48636,PSR_PLAN_WK:61,PSR_FORECAST_CUM:48746,PSR_FORECAST_WK:506},
      // {CUTOFF:"2023-03-17",WK_NO:"W2023_11",PSR_PLAN_CUM:49146,PSR_PLAN_WK:510,PSR_FORECAST_CUM:49382,PSR_FORECAST_WK:636},
      // {CUTOFF:"2023-03-24",WK_NO:"W2023_12",PSR_PLAN_CUM:49840,PSR_PLAN_WK:694,PSR_FORECAST_CUM:49825,PSR_FORECAST_WK:443},
      // {CUTOFF:"2023-03-31",WK_NO:"W2023_13",PSR_PLAN_CUM:50439,PSR_PLAN_WK:599,PSR_FORECAST_CUM:50245,PSR_FORECAST_WK:420},
      // {CUTOFF:"2023-04-07",WK_NO:"W2023_14",PSR_PLAN_CUM:50806,PSR_PLAN_WK:367,PSR_FORECAST_CUM:50464,PSR_FORECAST_WK:219},
      // {CUTOFF:"2023-04-14",WK_NO:"W2023_15",PSR_PLAN_CUM:51020,PSR_PLAN_WK:214,PSR_FORECAST_CUM:50563,PSR_FORECAST_WK:99},
      // {CUTOFF:"2023-04-21",WK_NO:"W2023_16",PSR_PLAN_CUM:51549,PSR_PLAN_WK:529,PSR_FORECAST_CUM:50767,PSR_FORECAST_WK:204},
      // {CUTOFF:"2023-04-28",WK_NO:"W2023_17",PSR_PLAN_CUM:52046,PSR_PLAN_WK:497,PSR_FORECAST_CUM:51155,PSR_FORECAST_WK:388},
      // {CUTOFF:"2023-05-05",WK_NO:"W2023_18",PSR_PLAN_CUM:52578,PSR_PLAN_WK:532,PSR_FORECAST_CUM:51400,PSR_FORECAST_WK:245},
      // {CUTOFF:"2023-05-12",WK_NO:"W2023_19",PSR_PLAN_CUM:53051,PSR_PLAN_WK:473,PSR_FORECAST_CUM:51583,PSR_FORECAST_WK:183},
      // {CUTOFF:"2023-05-19",WK_NO:"W2023_20",PSR_PLAN_CUM:53473,PSR_PLAN_WK:422,PSR_FORECAST_CUM:51959,PSR_FORECAST_WK:376},
      // {CUTOFF:"2023-05-26",WK_NO:"W2023_21",PSR_PLAN_CUM:53747,PSR_PLAN_WK:274,PSR_FORECAST_CUM:52041,PSR_FORECAST_WK:82},
      // {CUTOFF:"2023-06-02",WK_NO:"W2023_22",PSR_PLAN_CUM:53963,PSR_PLAN_WK:216,PSR_FORECAST_CUM:52193,PSR_FORECAST_WK:152},
      // {CUTOFF:"2023-06-09",WK_NO:"W2023_23",PSR_PLAN_CUM:54086,PSR_PLAN_WK:123,PSR_FORECAST_CUM:52309,PSR_FORECAST_WK:116},
      // {CUTOFF:"2023-06-16",WK_NO:"W2023_24",PSR_PLAN_CUM:54353,PSR_PLAN_WK:267,PSR_FORECAST_CUM:52436,PSR_FORECAST_WK:127},
      // {CUTOFF:"2023-06-23",WK_NO:"W2023_25",PSR_PLAN_CUM:54527,PSR_PLAN_WK:174,PSR_FORECAST_CUM:52697,PSR_FORECAST_WK:261},
      // {CUTOFF:"2023-06-30",WK_NO:"W2023_26",PSR_PLAN_CUM:54714,PSR_PLAN_WK:187,PSR_FORECAST_CUM:52836,PSR_FORECAST_WK:139},
      // {CUTOFF:"2023-07-07",WK_NO:"W2023_27",PSR_PLAN_CUM:54813,PSR_PLAN_WK:99,PSR_FORECAST_CUM:53020,PSR_FORECAST_WK:184},
      // {CUTOFF:"2023-07-14",WK_NO:"W2023_28",PSR_PLAN_CUM:54958,PSR_PLAN_WK:145,PSR_FORECAST_CUM:53100,PSR_FORECAST_WK:80},
      // {CUTOFF:"2023-07-21",WK_NO:"W2023_29",PSR_PLAN_CUM:55031,PSR_PLAN_WK:73,PSR_FORECAST_CUM:53160,PSR_FORECAST_WK:60},
      // {CUTOFF:"2023-07-28",WK_NO:"W2023_30",PSR_PLAN_CUM:55144,PSR_PLAN_WK:113,PSR_FORECAST_CUM:53199,PSR_FORECAST_WK:39},
      // {CUTOFF:"2023-08-04",WK_NO:"W2023_31",PSR_PLAN_CUM:55214,PSR_PLAN_WK:70,PSR_FORECAST_CUM:53274,PSR_FORECAST_WK:75},
      // {CUTOFF:"2023-08-11",WK_NO:"W2023_32",PSR_PLAN_CUM:55398,PSR_PLAN_WK:184,PSR_FORECAST_CUM:53309,PSR_FORECAST_WK:35},
      // {CUTOFF:"2023-08-18",WK_NO:"W2023_33",PSR_PLAN_CUM:55533,PSR_PLAN_WK:135,PSR_FORECAST_CUM:53347,PSR_FORECAST_WK:38},
      // {CUTOFF:"2023-08-25",WK_NO:"W2023_34",PSR_PLAN_CUM:55569,PSR_PLAN_WK:36,PSR_FORECAST_CUM:53402,PSR_FORECAST_WK:55},
      // {CUTOFF:"2023-09-01",WK_NO:"W2023_35",PSR_PLAN_CUM:55655,PSR_PLAN_WK:86,PSR_FORECAST_CUM:53574,PSR_FORECAST_WK:172},
      // {CUTOFF:"2023-09-08",WK_NO:"W2023_36",PSR_PLAN_CUM:55682,PSR_PLAN_WK:27,PSR_FORECAST_CUM:53653,PSR_FORECAST_WK:79},
      // {CUTOFF:"2023-09-15",WK_NO:"W2023_37",PSR_PLAN_CUM:55776,PSR_PLAN_WK:94,PSR_FORECAST_CUM:53758,PSR_FORECAST_WK:105},
      // {CUTOFF:"2023-09-22",WK_NO:"W2023_38",PSR_PLAN_CUM:55791,PSR_PLAN_WK:15,PSR_FORECAST_CUM:53782,PSR_FORECAST_WK:24},
      // {CUTOFF:"2023-09-29",WK_NO:"W2023_39",PSR_PLAN_CUM:55842,PSR_PLAN_WK:51,PSR_FORECAST_CUM:53791,PSR_FORECAST_WK:9},
      // {CUTOFF:"2023-10-06",WK_NO:"W2023_40",PSR_PLAN_CUM:55862,PSR_PLAN_WK:20,PSR_FORECAST_CUM:53797,PSR_FORECAST_WK:6},
      // {CUTOFF:"2023-10-13",WK_NO:"W2023_41",PSR_PLAN_CUM:55885,PSR_PLAN_WK:23,PSR_FORECAST_CUM:53812,PSR_FORECAST_WK:15},
      // {CUTOFF:"2023-10-20",WK_NO:"W2023_42",PSR_PLAN_CUM:55905,PSR_PLAN_WK:20,PSR_FORECAST_CUM:53816,PSR_FORECAST_WK:4},
      // {CUTOFF:"2023-10-27",WK_NO:"W2023_43",PSR_PLAN_CUM:55921,PSR_PLAN_WK:16,PSR_FORECAST_CUM:53822,PSR_FORECAST_WK:6},
      // {CUTOFF:"2023-11-03",WK_NO:"W2023_44",PSR_PLAN_CUM:55931,PSR_PLAN_WK:10,PSR_FORECAST_CUM:53824,PSR_FORECAST_WK:2},
      // {CUTOFF:"2023-11-10",WK_NO:"W2023_45",PSR_PLAN_CUM:55931,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53824,PSR_FORECAST_WK:0},
      // {CUTOFF:"2023-11-17",WK_NO:"W2023_46",PSR_PLAN_CUM:55931,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53829,PSR_FORECAST_WK:5},
      // {CUTOFF:"2023-11-24",WK_NO:"W2023_47",PSR_PLAN_CUM:55935,PSR_PLAN_WK:4,PSR_FORECAST_CUM:53841,PSR_FORECAST_WK:12},
      // {CUTOFF:"2023-12-01",WK_NO:"W2023_48",PSR_PLAN_CUM:55935,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53841,PSR_FORECAST_WK:0},
      // {CUTOFF:"2023-12-08",WK_NO:"W2023_49",PSR_PLAN_CUM:55935,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53849,PSR_FORECAST_WK:8},
      // {CUTOFF:"2023-12-15",WK_NO:"W2023_50",PSR_PLAN_CUM:55935,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53849,PSR_FORECAST_WK:0},
      // {CUTOFF:"2023-12-22",WK_NO:"W2023_51",PSR_PLAN_CUM:55947,PSR_PLAN_WK:12,PSR_FORECAST_CUM:53861,PSR_FORECAST_WK:12},
      // {CUTOFF:"2023-12-29",WK_NO:"W2023_52",PSR_PLAN_CUM:55957,PSR_PLAN_WK:10,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:4},
      // {CUTOFF:"2024-01-05",WK_NO:"W2024_01",PSR_PLAN_CUM:55957,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      // {CUTOFF:"2024-01-12",WK_NO:"W2024_02",PSR_PLAN_CUM:55957,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      // {CUTOFF:"2024-01-19",WK_NO:"W2024_03",PSR_PLAN_CUM:55957,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      // {CUTOFF:"2024-01-26",WK_NO:"W2024_04",PSR_PLAN_CUM:55957,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      // {CUTOFF:"2024-02-02",WK_NO:"W2024_05",PSR_PLAN_CUM:55957,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      // {CUTOFF:"2024-02-09",WK_NO:"W2024_06",PSR_PLAN_CUM:55957,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      // {CUTOFF:"2024-02-16",WK_NO:"W2024_07",PSR_PLAN_CUM:55957,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      // {CUTOFF:"2024-02-23",WK_NO:"W2024_08",PSR_PLAN_CUM:55963,PSR_PLAN_WK:6,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      // {CUTOFF:"2024-03-01",WK_NO:"W2024_09",PSR_PLAN_CUM:55965,PSR_PLAN_WK:2,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      // {CUTOFF:"2024-03-08",WK_NO:"W2024_10",PSR_PLAN_CUM:55965,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      // {CUTOFF:"2024-03-15",WK_NO:"W2024_11",PSR_PLAN_CUM:55965,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      // {CUTOFF:"2024-03-22",WK_NO:"W2024_12",PSR_PLAN_CUM:55965,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      // {CUTOFF:"2024-03-29",WK_NO:"W2024_13",PSR_PLAN_CUM:55967,PSR_PLAN_WK:2,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      // {CUTOFF:"2024-04-05",WK_NO:"W2024_14",PSR_PLAN_CUM:55969,PSR_PLAN_WK:2,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      // {CUTOFF:"2024-04-12",WK_NO:"W2024_15",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      // {CUTOFF:"2024-04-19",WK_NO:"W2024_16",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      // {CUTOFF:"2024-04-26",WK_NO:"W2024_17",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      // {CUTOFF:"2024-05-03",WK_NO:"W2024_18",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      // {CUTOFF:"2024-05-10",WK_NO:"W2024_19",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      // {CUTOFF:"2024-05-17",WK_NO:"W2024_20",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      // {CUTOFF:"2024-05-24",WK_NO:"W2024_21",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      // {CUTOFF:"2024-05-31",WK_NO:"W2024_22",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      // {CUTOFF:"2024-06-07",WK_NO:"W2024_23",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      // {CUTOFF:"2024-06-14",WK_NO:"W2024_24",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      // {CUTOFF:"2024-06-21",WK_NO:"W2024_25",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      // {CUTOFF:"2024-06-28",WK_NO:"W2024_26",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      // {CUTOFF:"2024-07-05",WK_NO:"W2024_27",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      // {CUTOFF:"2024-07-12",WK_NO:"W2024_28",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      // {CUTOFF:"2024-07-19",WK_NO:"W2024_29",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      // {CUTOFF:"2024-07-26",WK_NO:"W2024_30",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      // {CUTOFF:"2024-08-02",WK_NO:"W2024_31",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      // {CUTOFF:"2024-08-09",WK_NO:"W2024_32",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      // {CUTOFF:"2024-08-16",WK_NO:"W2024_33",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      // {CUTOFF:"2024-08-23",WK_NO:"W2024_34",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      // {CUTOFF:"2024-08-30",WK_NO:"W2024_35",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      // {CUTOFF:"2024-09-06",WK_NO:"W2024_36",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      {CUTOFF:"2024-09-13",WK_NO:"W2024_37",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      {CUTOFF:"2024-09-20",WK_NO:"W2024_38",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      {CUTOFF:"2024-09-27",WK_NO:"W2024_39",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      {CUTOFF:"2024-10-04",WK_NO:"W2024_40",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      {CUTOFF:"2024-10-11",WK_NO:"W2024_41",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      {CUTOFF:"2024-10-18",WK_NO:"W2024_42",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      {CUTOFF:"2024-10-25",WK_NO:"W2024_43",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      {CUTOFF:"2024-11-01",WK_NO:"W2024_44",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      {CUTOFF:"2024-11-08",WK_NO:"W2024_45",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      {CUTOFF:"2024-11-15",WK_NO:"W2024_46",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      {CUTOFF:"2024-11-22",WK_NO:"W2024_47",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      {CUTOFF:"2024-11-29",WK_NO:"W2024_48",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      {CUTOFF:"2024-12-06",WK_NO:"W2024_49",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      {CUTOFF:"2024-12-13",WK_NO:"W2024_50",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      {CUTOFF:"2024-12-20",WK_NO:"W2024_51",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0},
      {CUTOFF:"2024-12-27",WK_NO:"W2024_52",PSR_PLAN_CUM:55969,PSR_PLAN_WK:0,PSR_FORECAST_CUM:53865,PSR_FORECAST_WK:0}
    ],
    SQL2:  [
      {WK_NO:"W2022_23"}
    ],
  },
  DataItems: [

    {CUTOFF:"2022-01-07",WK_NO:"W2022_01",PSR_PLAN_CUM:874,PSR_PLAN_WK:33,PSR_FORECAST_CUM:924,PSR_FORECAST_WK:31,PSR_ACTUAL_CUM:1019,PSR_ACTUAL_WK:11},
    {CUTOFF:"2022-01-14",WK_NO:"W2022_02",PSR_PLAN_CUM:910,PSR_PLAN_WK:36,PSR_FORECAST_CUM:938,PSR_FORECAST_WK:14,PSR_ACTUAL_CUM:1038,PSR_ACTUAL_WK:19},
    {CUTOFF:"2022-01-21",WK_NO:"W2022_03",PSR_PLAN_CUM:928,PSR_PLAN_WK:18,PSR_FORECAST_CUM:956,PSR_FORECAST_WK:18,PSR_ACTUAL_CUM:1087,PSR_ACTUAL_WK:49},
    {CUTOFF:"2022-01-28",WK_NO:"W2022_04",PSR_PLAN_CUM:964,PSR_PLAN_WK:36,PSR_FORECAST_CUM:993,PSR_FORECAST_WK:37,PSR_ACTUAL_CUM:1129,PSR_ACTUAL_WK:42},
    {CUTOFF:"2022-02-04",WK_NO:"W2022_05",PSR_PLAN_CUM:993,PSR_PLAN_WK:29,PSR_FORECAST_CUM:1020,PSR_FORECAST_WK:27,PSR_ACTUAL_CUM:1140,PSR_ACTUAL_WK:11},
    {CUTOFF:"2022-02-11",WK_NO:"W2022_06",PSR_PLAN_CUM:1094,PSR_PLAN_WK:101,PSR_FORECAST_CUM:1105,PSR_FORECAST_WK:85,PSR_ACTUAL_CUM:1196,PSR_ACTUAL_WK:56},
    {CUTOFF:"2022-02-18",WK_NO:"W2022_07",PSR_PLAN_CUM:1204,PSR_PLAN_WK:110,PSR_FORECAST_CUM:1194,PSR_FORECAST_WK:89,PSR_ACTUAL_CUM:1234,PSR_ACTUAL_WK:38},
    {CUTOFF:"2022-02-25",WK_NO:"W2022_08",PSR_PLAN_CUM:1291,PSR_PLAN_WK:87,PSR_FORECAST_CUM:1263,PSR_FORECAST_WK:69,PSR_ACTUAL_CUM:1313,PSR_ACTUAL_WK:79},
    {CUTOFF:"2022-03-04",WK_NO:"W2022_09",PSR_PLAN_CUM:1364,PSR_PLAN_WK:73,PSR_FORECAST_CUM:1342,PSR_FORECAST_WK:79,PSR_ACTUAL_CUM:1381,PSR_ACTUAL_WK:68},
    {CUTOFF:"2022-03-11",WK_NO:"W2022_10",PSR_PLAN_CUM:1494,PSR_PLAN_WK:130,PSR_FORECAST_CUM:1465,PSR_FORECAST_WK:123,PSR_ACTUAL_CUM:1498,PSR_ACTUAL_WK:117},
    {CUTOFF:"2022-03-18",WK_NO:"W2022_11",PSR_PLAN_CUM:1593,PSR_PLAN_WK:99,PSR_FORECAST_CUM:1554,PSR_FORECAST_WK:89,PSR_ACTUAL_CUM:1547,PSR_ACTUAL_WK:49},
    {CUTOFF:"2022-03-25",WK_NO:"W2022_12",PSR_PLAN_CUM:1694,PSR_PLAN_WK:101,PSR_FORECAST_CUM:1657,PSR_FORECAST_WK:103,PSR_ACTUAL_CUM:1666,PSR_ACTUAL_WK:119},
    {CUTOFF:"2022-04-01",WK_NO:"W2022_13",PSR_PLAN_CUM:1863,PSR_PLAN_WK:169,PSR_FORECAST_CUM:1829,PSR_FORECAST_WK:172,PSR_ACTUAL_CUM:1806,PSR_ACTUAL_WK:140},
    {CUTOFF:"2022-04-08",WK_NO:"W2022_14",PSR_PLAN_CUM:1952,PSR_PLAN_WK:89,PSR_FORECAST_CUM:1908,PSR_FORECAST_WK:79,PSR_ACTUAL_CUM:1870,PSR_ACTUAL_WK:64},
    {CUTOFF:"2022-04-15",WK_NO:"W2022_15",PSR_PLAN_CUM:2193,PSR_PLAN_WK:241,PSR_FORECAST_CUM:2151,PSR_FORECAST_WK:243,PSR_ACTUAL_CUM:2024,PSR_ACTUAL_WK:154},
    {CUTOFF:"2022-04-22",WK_NO:"W2022_16",PSR_PLAN_CUM:2286,PSR_PLAN_WK:93,PSR_FORECAST_CUM:2242,PSR_FORECAST_WK:91,PSR_ACTUAL_CUM:2199,PSR_ACTUAL_WK:175},
    {CUTOFF:"2022-04-29",WK_NO:"W2022_17",PSR_PLAN_CUM:2647,PSR_PLAN_WK:361,PSR_FORECAST_CUM:2473,PSR_FORECAST_WK:231,PSR_ACTUAL_CUM:2409,PSR_ACTUAL_WK:210},
    {CUTOFF:"2022-05-06",WK_NO:"W2022_18",PSR_PLAN_CUM:3007,PSR_PLAN_WK:360,PSR_FORECAST_CUM:2776,PSR_FORECAST_WK:303,PSR_ACTUAL_CUM:2507,PSR_ACTUAL_WK:98},
    {CUTOFF:"2022-05-13",WK_NO:"W2022_19",PSR_PLAN_CUM:3328,PSR_PLAN_WK:321,PSR_FORECAST_CUM:3000,PSR_FORECAST_WK:224,PSR_ACTUAL_CUM:2886,PSR_ACTUAL_WK:379},
    {CUTOFF:"2022-05-20",WK_NO:"W2022_20",PSR_PLAN_CUM:3671,PSR_PLAN_WK:343,PSR_FORECAST_CUM:3742,PSR_FORECAST_WK:742,PSR_ACTUAL_CUM:3209,PSR_ACTUAL_WK:323},
    {CUTOFF:"2022-05-27",WK_NO:"W2022_21",PSR_PLAN_CUM:3885,PSR_PLAN_WK:214,PSR_FORECAST_CUM:4094,PSR_FORECAST_WK:352,PSR_ACTUAL_CUM:3436,PSR_ACTUAL_WK:227},
    {CUTOFF:"2022-06-03",WK_NO:"W2022_22",PSR_PLAN_CUM:4141,PSR_PLAN_WK:256,PSR_FORECAST_CUM:4436,PSR_FORECAST_WK:342,PSR_ACTUAL_CUM:3543,PSR_ACTUAL_WK:107},
    {CUTOFF:"2022-06-10",WK_NO:"W2022_23",PSR_PLAN_CUM:4524,PSR_PLAN_WK:383,PSR_FORECAST_CUM:4606,PSR_FORECAST_WK:170,PSR_ACTUAL_CUM:3660,PSR_ACTUAL_WK:117},
    {CUTOFF:"2022-06-17",WK_NO:"W2022_24",PSR_PLAN_CUM:4766,PSR_PLAN_WK:242,PSR_FORECAST_CUM:4895,PSR_FORECAST_WK:289,PSR_ACTUAL_CUM:3768,PSR_ACTUAL_WK:108},
    {CUTOFF:"2022-06-24",WK_NO:"W2022_25",PSR_PLAN_CUM:4903,PSR_PLAN_WK:137,PSR_FORECAST_CUM:5446,PSR_FORECAST_WK:551},
    {CUTOFF:"2022-07-01",WK_NO:"W2022_26",PSR_PLAN_CUM:5230,PSR_PLAN_WK:327,PSR_FORECAST_CUM:5816,PSR_FORECAST_WK:370},
    {CUTOFF:"2022-07-08",WK_NO:"W2022_27",PSR_PLAN_CUM:5437,PSR_PLAN_WK:207,PSR_FORECAST_CUM:6100,PSR_FORECAST_WK:284},
    {CUTOFF:"2022-07-15",WK_NO:"W2022_28",PSR_PLAN_CUM:5533,PSR_PLAN_WK:96,PSR_FORECAST_CUM:6383,PSR_FORECAST_WK:283},
    {CUTOFF:"2022-07-22",WK_NO:"W2022_29",PSR_PLAN_CUM:5800,PSR_PLAN_WK:267,PSR_FORECAST_CUM:6548,PSR_FORECAST_WK:165},
    {CUTOFF:"2022-07-29",WK_NO:"W2022_30",PSR_PLAN_CUM:6107,PSR_PLAN_WK:307,PSR_FORECAST_CUM:6984,PSR_FORECAST_WK:436},
    {CUTOFF:"2022-08-05",WK_NO:"W2022_31",PSR_PLAN_CUM:6495,PSR_PLAN_WK:388,PSR_FORECAST_CUM:7357,PSR_FORECAST_WK:373},
    {CUTOFF:"2022-08-12",WK_NO:"W2022_32",PSR_PLAN_CUM:6831,PSR_PLAN_WK:336,PSR_FORECAST_CUM:7776,PSR_FORECAST_WK:419},
    {CUTOFF:"2022-08-19",WK_NO:"W2022_33",PSR_PLAN_CUM:7008,PSR_PLAN_WK:177,PSR_FORECAST_CUM:8094,PSR_FORECAST_WK:318},
    {CUTOFF:"2022-08-26",WK_NO:"W2022_34",PSR_PLAN_CUM:7317,PSR_PLAN_WK:309,PSR_FORECAST_CUM:8430,PSR_FORECAST_WK:336},
    {CUTOFF:"2022-09-02",WK_NO:"W2022_35",PSR_PLAN_CUM:7500,PSR_PLAN_WK:183,PSR_FORECAST_CUM:8761,PSR_FORECAST_WK:331},
    {CUTOFF:"2022-09-09",WK_NO:"W2022_36",PSR_PLAN_CUM:7910,PSR_PLAN_WK:410,PSR_FORECAST_CUM:9326,PSR_FORECAST_WK:565},
    {CUTOFF:"2022-09-16",WK_NO:"W2022_37",PSR_PLAN_CUM:8216,PSR_PLAN_WK:306,PSR_FORECAST_CUM:9854,PSR_FORECAST_WK:528},
    {CUTOFF:"2022-09-23",WK_NO:"W2022_38",PSR_PLAN_CUM:8566,PSR_PLAN_WK:350,PSR_FORECAST_CUM:10228,PSR_FORECAST_WK:374},
    {CUTOFF:"2022-09-30",WK_NO:"W2022_39",PSR_PLAN_CUM:8796,PSR_PLAN_WK:230,PSR_FORECAST_CUM:10734,PSR_FORECAST_WK:506},
    {CUTOFF:"2022-10-07",WK_NO:"W2022_40",PSR_PLAN_CUM:9032,PSR_PLAN_WK:236,PSR_FORECAST_CUM:10906,PSR_FORECAST_WK:172},
    {CUTOFF:"2022-10-14",WK_NO:"W2022_41",PSR_PLAN_CUM:9121,PSR_PLAN_WK:89,PSR_FORECAST_CUM:11027,PSR_FORECAST_WK:121},
    {CUTOFF:"2022-10-21",WK_NO:"W2022_42",PSR_PLAN_CUM:9333,PSR_PLAN_WK:212,PSR_FORECAST_CUM:11160,PSR_FORECAST_WK:133},
    {CUTOFF:"2022-10-28",WK_NO:"W2022_43",PSR_PLAN_CUM:9410,PSR_PLAN_WK:77,PSR_FORECAST_CUM:11440,PSR_FORECAST_WK:280},
    {CUTOFF:"2022-11-04",WK_NO:"W2022_44",PSR_PLAN_CUM:9667,PSR_PLAN_WK:257,PSR_FORECAST_CUM:11629,PSR_FORECAST_WK:189},
    {CUTOFF:"2022-11-11",WK_NO:"W2022_45",PSR_PLAN_CUM:9901,PSR_PLAN_WK:234,PSR_FORECAST_CUM:11872,PSR_FORECAST_WK:243},
    {CUTOFF:"2022-11-18",WK_NO:"W2022_46",PSR_PLAN_CUM:9979,PSR_PLAN_WK:78,PSR_FORECAST_CUM:12043,PSR_FORECAST_WK:171},
    {CUTOFF:"2022-11-25",WK_NO:"W2022_47",PSR_PLAN_CUM:10052,PSR_PLAN_WK:73,PSR_FORECAST_CUM:12133,PSR_FORECAST_WK:90},
    {CUTOFF:"2022-12-02",WK_NO:"W2022_48",PSR_PLAN_CUM:10209,PSR_PLAN_WK:157,PSR_FORECAST_CUM:12199,PSR_FORECAST_WK:66},
    {CUTOFF:"2022-12-09",WK_NO:"W2022_49",PSR_PLAN_CUM:10520,PSR_PLAN_WK:311,PSR_FORECAST_CUM:12515,PSR_FORECAST_WK:316},
    {CUTOFF:"2022-12-16",WK_NO:"W2022_50",PSR_PLAN_CUM:10711,PSR_PLAN_WK:191,PSR_FORECAST_CUM:12749,PSR_FORECAST_WK:234},
    {CUTOFF:"2022-12-23",WK_NO:"W2022_51",PSR_PLAN_CUM:10737,PSR_PLAN_WK:26,PSR_FORECAST_CUM:12903,PSR_FORECAST_WK:154},
    {CUTOFF:"2022-12-30",WK_NO:"W2022_52",PSR_PLAN_CUM:10793,PSR_PLAN_WK:56,PSR_FORECAST_CUM:12959,PSR_FORECAST_WK:56},
    {CUTOFF:"2023-01-06",WK_NO:"W2023_01",PSR_PLAN_CUM:10822,PSR_PLAN_WK:29,PSR_FORECAST_CUM:12995,PSR_FORECAST_WK:36},
    {CUTOFF:"2023-01-13",WK_NO:"W2023_02",PSR_PLAN_CUM:10849,PSR_PLAN_WK:27,PSR_FORECAST_CUM:13030,PSR_FORECAST_WK:35},
    {CUTOFF:"2023-01-20",WK_NO:"W2023_03",PSR_PLAN_CUM:11020,PSR_PLAN_WK:171,PSR_FORECAST_CUM:13104,PSR_FORECAST_WK:74},
    {CUTOFF:"2023-01-27",WK_NO:"W2023_04",PSR_PLAN_CUM:11066,PSR_PLAN_WK:46,PSR_FORECAST_CUM:13238,PSR_FORECAST_WK:134},
    {CUTOFF:"2023-02-03",WK_NO:"W2023_05",PSR_PLAN_CUM:11097,PSR_PLAN_WK:31,PSR_FORECAST_CUM:13290,PSR_FORECAST_WK:52},
    {CUTOFF:"2023-02-10",WK_NO:"W2023_06",PSR_PLAN_CUM:11186,PSR_PLAN_WK:89,PSR_FORECAST_CUM:13329,PSR_FORECAST_WK:39},
    {CUTOFF:"2023-02-17",WK_NO:"W2023_07",PSR_PLAN_CUM:11265,PSR_PLAN_WK:79,PSR_FORECAST_CUM:13435,PSR_FORECAST_WK:106},
    {CUTOFF:"2023-02-24",WK_NO:"W2023_08",PSR_PLAN_CUM:11339,PSR_PLAN_WK:74,PSR_FORECAST_CUM:13499,PSR_FORECAST_WK:64},
    {CUTOFF:"2023-03-03",WK_NO:"W2023_09",PSR_PLAN_CUM:11486,PSR_PLAN_WK:147,PSR_FORECAST_CUM:13602,PSR_FORECAST_WK:103},
    {CUTOFF:"2023-03-10",WK_NO:"W2023_10",PSR_PLAN_CUM:11514,PSR_PLAN_WK:28,PSR_FORECAST_CUM:13793,PSR_FORECAST_WK:191},
    {CUTOFF:"2023-03-17",WK_NO:"W2023_11",PSR_PLAN_CUM:11593,PSR_PLAN_WK:79,PSR_FORECAST_CUM:13887,PSR_FORECAST_WK:94},
    {CUTOFF:"2023-03-24",WK_NO:"W2023_12",PSR_PLAN_CUM:11723,PSR_PLAN_WK:130,PSR_FORECAST_CUM:13988,PSR_FORECAST_WK:101},
    {CUTOFF:"2023-03-31",WK_NO:"W2023_13",PSR_PLAN_CUM:11827,PSR_PLAN_WK:104,PSR_FORECAST_CUM:14052,PSR_FORECAST_WK:64},
    {CUTOFF:"2023-04-07",WK_NO:"W2023_14",PSR_PLAN_CUM:11933,PSR_PLAN_WK:106,PSR_FORECAST_CUM:14091,PSR_FORECAST_WK:39},
    {CUTOFF:"2023-04-14",WK_NO:"W2023_15",PSR_PLAN_CUM:11987,PSR_PLAN_WK:54,PSR_FORECAST_CUM:14141,PSR_FORECAST_WK:50},
    {CUTOFF:"2023-04-21",WK_NO:"W2023_16",PSR_PLAN_CUM:12097,PSR_PLAN_WK:110,PSR_FORECAST_CUM:14225,PSR_FORECAST_WK:84},
    {CUTOFF:"2023-04-28",WK_NO:"W2023_17",PSR_PLAN_CUM:12172,PSR_PLAN_WK:75,PSR_FORECAST_CUM:14277,PSR_FORECAST_WK:52},
    {CUTOFF:"2023-05-05",WK_NO:"W2023_18",PSR_PLAN_CUM:12244,PSR_PLAN_WK:72,PSR_FORECAST_CUM:14333,PSR_FORECAST_WK:56},
    {CUTOFF:"2023-05-12",WK_NO:"W2023_19",PSR_PLAN_CUM:12350,PSR_PLAN_WK:106,PSR_FORECAST_CUM:14404,PSR_FORECAST_WK:71},
    {CUTOFF:"2023-05-19",WK_NO:"W2023_20",PSR_PLAN_CUM:12422,PSR_PLAN_WK:72,PSR_FORECAST_CUM:14513,PSR_FORECAST_WK:109},
    {CUTOFF:"2023-05-26",WK_NO:"W2023_21",PSR_PLAN_CUM:12480,PSR_PLAN_WK:58,PSR_FORECAST_CUM:14546,PSR_FORECAST_WK:33},
    {CUTOFF:"2023-06-02",WK_NO:"W2023_22",PSR_PLAN_CUM:12528,PSR_PLAN_WK:48,PSR_FORECAST_CUM:14581,PSR_FORECAST_WK:35},
    {CUTOFF:"2023-06-09",WK_NO:"W2023_23",PSR_PLAN_CUM:12557,PSR_PLAN_WK:29,PSR_FORECAST_CUM:14671,PSR_FORECAST_WK:90},
    {CUTOFF:"2023-06-16",WK_NO:"W2023_24",PSR_PLAN_CUM:12744,PSR_PLAN_WK:187,PSR_FORECAST_CUM:14770,PSR_FORECAST_WK:99},
    {CUTOFF:"2023-06-23",WK_NO:"W2023_25",PSR_PLAN_CUM:12794,PSR_PLAN_WK:50,PSR_FORECAST_CUM:14847,PSR_FORECAST_WK:77},
    {CUTOFF:"2023-06-30",WK_NO:"W2023_26",PSR_PLAN_CUM:12868,PSR_PLAN_WK:74,PSR_FORECAST_CUM:14914,PSR_FORECAST_WK:67},
    {CUTOFF:"2023-07-07",WK_NO:"W2023_27",PSR_PLAN_CUM:12899,PSR_PLAN_WK:31,PSR_FORECAST_CUM:14934,PSR_FORECAST_WK:20},
    {CUTOFF:"2023-07-14",WK_NO:"W2023_28",PSR_PLAN_CUM:12941,PSR_PLAN_WK:42,PSR_FORECAST_CUM:14981,PSR_FORECAST_WK:47},
    {CUTOFF:"2023-07-21",WK_NO:"W2023_29",PSR_PLAN_CUM:12967,PSR_PLAN_WK:26,PSR_FORECAST_CUM:15018,PSR_FORECAST_WK:37},
    {CUTOFF:"2023-07-28",WK_NO:"W2023_30",PSR_PLAN_CUM:12991,PSR_PLAN_WK:24,PSR_FORECAST_CUM:15035,PSR_FORECAST_WK:17},
    {CUTOFF:"2023-08-04",WK_NO:"W2023_31",PSR_PLAN_CUM:13030,PSR_PLAN_WK:39,PSR_FORECAST_CUM:15096,PSR_FORECAST_WK:61},
    {CUTOFF:"2023-08-11",WK_NO:"W2023_32",PSR_PLAN_CUM:13089,PSR_PLAN_WK:59,PSR_FORECAST_CUM:15141,PSR_FORECAST_WK:45},
    {CUTOFF:"2023-08-18",WK_NO:"W2023_33",PSR_PLAN_CUM:13117,PSR_PLAN_WK:28,PSR_FORECAST_CUM:15169,PSR_FORECAST_WK:28},
    {CUTOFF:"2023-08-25",WK_NO:"W2023_34",PSR_PLAN_CUM:13133,PSR_PLAN_WK:16,PSR_FORECAST_CUM:15187,PSR_FORECAST_WK:18},
    {CUTOFF:"2023-09-01",WK_NO:"W2023_35",PSR_PLAN_CUM:13218,PSR_PLAN_WK:85,PSR_FORECAST_CUM:15324,PSR_FORECAST_WK:137},
    {CUTOFF:"2023-09-08",WK_NO:"W2023_36",PSR_PLAN_CUM:13245,PSR_PLAN_WK:27,PSR_FORECAST_CUM:15373,PSR_FORECAST_WK:49},
    {CUTOFF:"2023-09-15",WK_NO:"W2023_37",PSR_PLAN_CUM:13263,PSR_PLAN_WK:18,PSR_FORECAST_CUM:15394,PSR_FORECAST_WK:21},
    {CUTOFF:"2023-09-22",WK_NO:"W2023_38",PSR_PLAN_CUM:13273,PSR_PLAN_WK:10,PSR_FORECAST_CUM:15409,PSR_FORECAST_WK:15},
    {CUTOFF:"2023-09-29",WK_NO:"W2023_39",PSR_PLAN_CUM:13316,PSR_PLAN_WK:43,PSR_FORECAST_CUM:15419,PSR_FORECAST_WK:10},
    {CUTOFF:"2023-10-06",WK_NO:"W2023_40",PSR_PLAN_CUM:13332,PSR_PLAN_WK:16,PSR_FORECAST_CUM:15437,PSR_FORECAST_WK:18},
    {CUTOFF:"2023-10-13",WK_NO:"W2023_41",PSR_PLAN_CUM:13337,PSR_PLAN_WK:5,PSR_FORECAST_CUM:15500,PSR_FORECAST_WK:63},
    {CUTOFF:"2023-10-20",WK_NO:"W2023_42",PSR_PLAN_CUM:13345,PSR_PLAN_WK:8,PSR_FORECAST_CUM:15517,PSR_FORECAST_WK:17},
    {CUTOFF:"2023-10-27",WK_NO:"W2023_43",PSR_PLAN_CUM:13352,PSR_PLAN_WK:7,PSR_FORECAST_CUM:15529,PSR_FORECAST_WK:12},
    {CUTOFF:"2023-11-03",WK_NO:"W2023_44",PSR_PLAN_CUM:13355,PSR_PLAN_WK:3,PSR_FORECAST_CUM:15531,PSR_FORECAST_WK:2},
    {CUTOFF:"2023-11-10",WK_NO:"W2023_45",PSR_PLAN_CUM:13355,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15603,PSR_FORECAST_WK:72},
    {CUTOFF:"2023-11-17",WK_NO:"W2023_46",PSR_PLAN_CUM:13355,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15625,PSR_FORECAST_WK:22},
    {CUTOFF:"2023-11-24",WK_NO:"W2023_47",PSR_PLAN_CUM:13357,PSR_PLAN_WK:2,PSR_FORECAST_CUM:15628,PSR_FORECAST_WK:3},
    {CUTOFF:"2023-12-01",WK_NO:"W2023_48",PSR_PLAN_CUM:13357,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15628,PSR_FORECAST_WK:0},
    {CUTOFF:"2023-12-08",WK_NO:"W2023_49",PSR_PLAN_CUM:13357,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15630,PSR_FORECAST_WK:2},
    {CUTOFF:"2023-12-15",WK_NO:"W2023_50",PSR_PLAN_CUM:13357,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15637,PSR_FORECAST_WK:7},
    {CUTOFF:"2023-12-22",WK_NO:"W2023_51",PSR_PLAN_CUM:13360,PSR_PLAN_WK:3,PSR_FORECAST_CUM:15640,PSR_FORECAST_WK:3},
    {CUTOFF:"2023-12-29",WK_NO:"W2023_52",PSR_PLAN_CUM:13362,PSR_PLAN_WK:2,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:1},
    {CUTOFF:"2024-01-05",WK_NO:"W2024_01",PSR_PLAN_CUM:13362,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-01-12",WK_NO:"W2024_02",PSR_PLAN_CUM:13362,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-01-19",WK_NO:"W2024_03",PSR_PLAN_CUM:13362,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-01-26",WK_NO:"W2024_04",PSR_PLAN_CUM:13362,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-02-02",WK_NO:"W2024_05",PSR_PLAN_CUM:13362,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-02-09",WK_NO:"W2024_06",PSR_PLAN_CUM:13362,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-02-16",WK_NO:"W2024_07",PSR_PLAN_CUM:13363,PSR_PLAN_WK:1,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-02-23",WK_NO:"W2024_08",PSR_PLAN_CUM:13365,PSR_PLAN_WK:2,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-03-01",WK_NO:"W2024_09",PSR_PLAN_CUM:13366,PSR_PLAN_WK:1,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-03-08",WK_NO:"W2024_10",PSR_PLAN_CUM:13366,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-03-15",WK_NO:"W2024_11",PSR_PLAN_CUM:13366,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-03-22",WK_NO:"W2024_12",PSR_PLAN_CUM:13366,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-03-29",WK_NO:"W2024_13",PSR_PLAN_CUM:13367,PSR_PLAN_WK:1,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-04-05",WK_NO:"W2024_14",PSR_PLAN_CUM:13368,PSR_PLAN_WK:1,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-04-12",WK_NO:"W2024_15",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-04-19",WK_NO:"W2024_16",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-04-26",WK_NO:"W2024_17",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-05-03",WK_NO:"W2024_18",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-05-10",WK_NO:"W2024_19",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-05-17",WK_NO:"W2024_20",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-05-24",WK_NO:"W2024_21",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-05-31",WK_NO:"W2024_22",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-06-07",WK_NO:"W2024_23",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-06-14",WK_NO:"W2024_24",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-06-21",WK_NO:"W2024_25",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-06-28",WK_NO:"W2024_26",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-07-05",WK_NO:"W2024_27",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-07-12",WK_NO:"W2024_28",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-07-19",WK_NO:"W2024_29",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-07-26",WK_NO:"W2024_30",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-08-02",WK_NO:"W2024_31",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-08-09",WK_NO:"W2024_32",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-08-16",WK_NO:"W2024_33",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-08-23",WK_NO:"W2024_34",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-08-30",WK_NO:"W2024_35",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-09-06",WK_NO:"W2024_36",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-09-13",WK_NO:"W2024_37",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-09-20",WK_NO:"W2024_38",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-09-27",WK_NO:"W2024_39",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-10-04",WK_NO:"W2024_40",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-10-11",WK_NO:"W2024_41",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-10-18",WK_NO:"W2024_42",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-10-25",WK_NO:"W2024_43",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-11-01",WK_NO:"W2024_44",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-11-08",WK_NO:"W2024_45",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-11-15",WK_NO:"W2024_46",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-11-22",WK_NO:"W2024_47",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-11-29",WK_NO:"W2024_48",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-12-06",WK_NO:"W2024_49",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-12-13",WK_NO:"W2024_50",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-12-20",WK_NO:"W2024_51",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0},
    {CUTOFF:"2024-12-27",WK_NO:"W2024_52",PSR_PLAN_CUM:13368,PSR_PLAN_WK:0,PSR_FORECAST_CUM:15641,PSR_FORECAST_WK:0}
],
}