import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1600,
      CanvasHeight: 500,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#e0e0e0',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 380,
      CanvasChartHeight: 280,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Support Fabrication', x:20, y: -20, font: 'roboto', class: 'regular', size: 12, color: '#424242', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: -50, y: -20, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'start' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: -30,
          y: -20,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'start'
        },
      ]
    },
    Queries: {
      // SQL1: [
      //   {PLAN:100,ACTUAL:99.99,DELTA:-0.01000000000000512}
      // ],
    },
    DataItems: [

      {CUTOFF:"2022-12-09",WK_NO:"W2022_49",DELIVERY_PLAN_WK:2,DELIVERY_ACTUAL_WK:0,LIFTING_PLAN_WK:1,LIFTING_ACTUAL_WK:1,DELIVERY_PLAN_CUM:8,DELIVERY_ACTUAL_CUM:6,LIFTING_PLAN_CUM:1,LIFTING_ACTUAL_CUM:1},
      {CUTOFF:"2022-12-16",WK_NO:"W2022_50",DELIVERY_PLAN_WK:2,DELIVERY_ACTUAL_WK:3,LIFTING_PLAN_WK:1,LIFTING_ACTUAL_WK:1,DELIVERY_PLAN_CUM:10,DELIVERY_ACTUAL_CUM:9,LIFTING_PLAN_CUM:2,LIFTING_ACTUAL_CUM:2},
      {CUTOFF:"2022-12-23",WK_NO:"W2022_51",DELIVERY_PLAN_WK:0,DELIVERY_ACTUAL_WK:0,LIFTING_PLAN_WK:1,LIFTING_ACTUAL_WK:1,DELIVERY_PLAN_CUM:10,DELIVERY_ACTUAL_CUM:9,LIFTING_PLAN_CUM:3,LIFTING_ACTUAL_CUM:3},
      {CUTOFF:"2022-12-30",WK_NO:"W2022_52",DELIVERY_PLAN_WK:5,DELIVERY_ACTUAL_WK:2,LIFTING_PLAN_WK:1,LIFTING_ACTUAL_WK:1,DELIVERY_PLAN_CUM:15,DELIVERY_ACTUAL_CUM:11,LIFTING_PLAN_CUM:4,LIFTING_ACTUAL_CUM:4},
      {CUTOFF:"2023-01-06",WK_NO:"W2023_01",DELIVERY_PLAN_WK:9,DELIVERY_ACTUAL_WK:2,LIFTING_PLAN_WK:1,LIFTING_ACTUAL_WK:1,DELIVERY_PLAN_CUM:24,DELIVERY_ACTUAL_CUM:13,LIFTING_PLAN_CUM:5,LIFTING_ACTUAL_CUM:5},
      {CUTOFF:"2023-01-13",WK_NO:"W2023_02",DELIVERY_PLAN_WK:5,DELIVERY_ACTUAL_WK:2,LIFTING_PLAN_WK:2,LIFTING_ACTUAL_WK:4,DELIVERY_PLAN_CUM:29,DELIVERY_ACTUAL_CUM:15,LIFTING_PLAN_CUM:7,LIFTING_ACTUAL_CUM:9},
      {CUTOFF:"2023-01-20",WK_NO:"W2023_03",DELIVERY_PLAN_WK:3,DELIVERY_ACTUAL_WK:6,LIFTING_PLAN_WK:2,LIFTING_ACTUAL_WK:0,DELIVERY_PLAN_CUM:32,DELIVERY_ACTUAL_CUM:21,LIFTING_PLAN_CUM:9,LIFTING_ACTUAL_CUM:9},
      {CUTOFF:"2023-01-27",WK_NO:"W2023_04",DELIVERY_PLAN_WK:0,DELIVERY_ACTUAL_WK:0,LIFTING_PLAN_WK:0,LIFTING_ACTUAL_WK:0,DELIVERY_PLAN_CUM:32,DELIVERY_ACTUAL_CUM:21,LIFTING_PLAN_CUM:9,LIFTING_ACTUAL_CUM:9},
      {CUTOFF:"2023-02-03",WK_NO:"W2023_05",DELIVERY_PLAN_WK:40,DELIVERY_ACTUAL_WK:4,LIFTING_PLAN_WK:0,LIFTING_ACTUAL_WK:0,DELIVERY_PLAN_CUM:72,DELIVERY_ACTUAL_CUM:25,LIFTING_PLAN_CUM:9,LIFTING_ACTUAL_CUM:9},
      {CUTOFF:"2023-02-10",WK_NO:"W2023_06",DELIVERY_PLAN_WK:3,DELIVERY_ACTUAL_WK:0,LIFTING_PLAN_WK:51,LIFTING_ACTUAL_WK:1,DELIVERY_PLAN_CUM:75,DELIVERY_ACTUAL_CUM:25,LIFTING_PLAN_CUM:60,LIFTING_ACTUAL_CUM:10},
      {CUTOFF:"2023-02-17",WK_NO:"W2023_07",DELIVERY_PLAN_WK:4,LIFTING_PLAN_WK:2,DELIVERY_PLAN_CUM:79,LIFTING_PLAN_CUM:62},
      {CUTOFF:"2023-02-24",WK_NO:"W2023_08",DELIVERY_PLAN_WK:2,LIFTING_PLAN_WK:2,DELIVERY_PLAN_CUM:81,LIFTING_PLAN_CUM:64},
      {CUTOFF:"2023-03-03",WK_NO:"W2023_09",DELIVERY_PLAN_WK:12,LIFTING_PLAN_WK:4,DELIVERY_PLAN_CUM:93,LIFTING_PLAN_CUM:68},
      {CUTOFF:"2023-03-10",WK_NO:"W2023_10",DELIVERY_PLAN_WK:16,LIFTING_PLAN_WK:19,DELIVERY_PLAN_CUM:109,LIFTING_PLAN_CUM:87},
      {CUTOFF:"2023-03-17",WK_NO:"W2023_11",DELIVERY_PLAN_WK:5,LIFTING_PLAN_WK:13,DELIVERY_PLAN_CUM:114,LIFTING_PLAN_CUM:100},
      {CUTOFF:"2023-03-24",WK_NO:"W2023_12",DELIVERY_PLAN_WK:7,LIFTING_PLAN_WK:5,DELIVERY_PLAN_CUM:121,LIFTING_PLAN_CUM:105},
      {CUTOFF:"2023-03-31",WK_NO:"W2023_13",DELIVERY_PLAN_WK:11,LIFTING_PLAN_WK:6,DELIVERY_PLAN_CUM:132,LIFTING_PLAN_CUM:111},
      {CUTOFF:"2023-04-07",WK_NO:"W2023_14",DELIVERY_PLAN_WK:2,LIFTING_PLAN_WK:12,DELIVERY_PLAN_CUM:134,LIFTING_PLAN_CUM:123},
      {CUTOFF:"2023-04-14",WK_NO:"W2023_15",DELIVERY_PLAN_WK:2,LIFTING_PLAN_WK:20,DELIVERY_PLAN_CUM:136,LIFTING_PLAN_CUM:143},
      {CUTOFF:"2023-04-21",WK_NO:"W2023_16",DELIVERY_PLAN_WK:88,LIFTING_PLAN_WK:28,DELIVERY_PLAN_CUM:224,LIFTING_PLAN_CUM:171},
      {CUTOFF:"2023-04-28",WK_NO:"W2023_17",DELIVERY_PLAN_WK:4,LIFTING_PLAN_WK:15,DELIVERY_PLAN_CUM:228,LIFTING_PLAN_CUM:186},
      {CUTOFF:"2023-05-05",WK_NO:"W2023_18",DELIVERY_PLAN_WK:23,LIFTING_PLAN_WK:11,DELIVERY_PLAN_CUM:251,LIFTING_PLAN_CUM:197},
      {CUTOFF:"2023-05-12",WK_NO:"W2023_19",DELIVERY_PLAN_WK:12,LIFTING_PLAN_WK:19,DELIVERY_PLAN_CUM:263,LIFTING_PLAN_CUM:216},
      {CUTOFF:"2023-05-19",WK_NO:"W2023_20",DELIVERY_PLAN_WK:68,LIFTING_PLAN_WK:21,DELIVERY_PLAN_CUM:331,LIFTING_PLAN_CUM:237},
      {CUTOFF:"2023-05-26",WK_NO:"W2023_21",DELIVERY_PLAN_WK:8,LIFTING_PLAN_WK:71,DELIVERY_PLAN_CUM:339,LIFTING_PLAN_CUM:308},
      {CUTOFF:"2023-06-02",WK_NO:"W2023_22",DELIVERY_PLAN_WK:34,LIFTING_PLAN_WK:40,DELIVERY_PLAN_CUM:373,LIFTING_PLAN_CUM:348},
      {CUTOFF:"2023-06-09",WK_NO:"W2023_23",DELIVERY_PLAN_WK:4,LIFTING_PLAN_WK:32,DELIVERY_PLAN_CUM:377,LIFTING_PLAN_CUM:380},
      {CUTOFF:"2023-06-16",WK_NO:"W2023_24",DELIVERY_PLAN_WK:14,LIFTING_PLAN_WK:29,DELIVERY_PLAN_CUM:391,LIFTING_PLAN_CUM:409},
      {CUTOFF:"2023-06-23",WK_NO:"W2023_25",DELIVERY_PLAN_WK:7,LIFTING_PLAN_WK:15,DELIVERY_PLAN_CUM:398,LIFTING_PLAN_CUM:424},
      {CUTOFF:"2023-06-30",WK_NO:"W2023_26",DELIVERY_PLAN_WK:40,LIFTING_PLAN_WK:14,DELIVERY_PLAN_CUM:438,LIFTING_PLAN_CUM:438},
      {CUTOFF:"2023-07-07",WK_NO:"W2023_27",DELIVERY_PLAN_WK:0,LIFTING_PLAN_WK:15,DELIVERY_PLAN_CUM:438,LIFTING_PLAN_CUM:453},
      {CUTOFF:"2023-07-14",WK_NO:"W2023_28",DELIVERY_PLAN_WK:0,LIFTING_PLAN_WK:15,DELIVERY_PLAN_CUM:438,LIFTING_PLAN_CUM:468},
      {CUTOFF:"2023-07-21",WK_NO:"W2023_29",DELIVERY_PLAN_WK:14,LIFTING_PLAN_WK:6,DELIVERY_PLAN_CUM:452,LIFTING_PLAN_CUM:474},
      {CUTOFF:"2023-07-28",WK_NO:"W2023_30",DELIVERY_PLAN_WK:0,LIFTING_PLAN_WK:8,DELIVERY_PLAN_CUM:452,LIFTING_PLAN_CUM:482},
      {CUTOFF:"2023-08-04",WK_NO:"W2023_31",DELIVERY_PLAN_WK:17,LIFTING_PLAN_WK:2,DELIVERY_PLAN_CUM:469,LIFTING_PLAN_CUM:484},
      {CUTOFF:"2023-08-11",WK_NO:"W2023_32",DELIVERY_PLAN_WK:0,LIFTING_PLAN_WK:0,DELIVERY_PLAN_CUM:469,LIFTING_PLAN_CUM:484},
      {CUTOFF:"2023-08-18",WK_NO:"W2023_33",DELIVERY_PLAN_WK:0,LIFTING_PLAN_WK:0,DELIVERY_PLAN_CUM:469,LIFTING_PLAN_CUM:484},
      {CUTOFF:"2023-08-25",WK_NO:"W2023_34",DELIVERY_PLAN_WK:3,LIFTING_PLAN_WK:1,DELIVERY_PLAN_CUM:472,LIFTING_PLAN_CUM:485},
      {CUTOFF:"2023-09-01",WK_NO:"W2023_35",DELIVERY_PLAN_WK:3,LIFTING_PLAN_WK:12,DELIVERY_PLAN_CUM:475,LIFTING_PLAN_CUM:497},
      {CUTOFF:"2023-09-08",WK_NO:"W2023_36",DELIVERY_PLAN_WK:0,LIFTING_PLAN_WK:8,DELIVERY_PLAN_CUM:475,LIFTING_PLAN_CUM:505},
      {CUTOFF:"2023-09-15",WK_NO:"W2023_37",DELIVERY_PLAN_WK:5,LIFTING_PLAN_WK:0,DELIVERY_PLAN_CUM:480,LIFTING_PLAN_CUM:505},
      {CUTOFF:"2023-09-22",WK_NO:"W2023_38",DELIVERY_PLAN_WK:2,LIFTING_PLAN_WK:1,DELIVERY_PLAN_CUM:482,LIFTING_PLAN_CUM:506},
      {CUTOFF:"2023-09-29",WK_NO:"W2023_39",DELIVERY_PLAN_WK:0,LIFTING_PLAN_WK:2,DELIVERY_PLAN_CUM:482,LIFTING_PLAN_CUM:508},
      {CUTOFF:"2023-10-06",WK_NO:"W2023_40",DELIVERY_PLAN_WK:0,LIFTING_PLAN_WK:0,DELIVERY_PLAN_CUM:482,LIFTING_PLAN_CUM:508},
      {CUTOFF:"2023-10-13",WK_NO:"W2023_41",DELIVERY_PLAN_WK:0,LIFTING_PLAN_WK:0,DELIVERY_PLAN_CUM:482,LIFTING_PLAN_CUM:508},
      {CUTOFF:"2023-10-20",WK_NO:"W2023_42",DELIVERY_PLAN_WK:0,LIFTING_PLAN_WK:0,DELIVERY_PLAN_CUM:482,LIFTING_PLAN_CUM:508},
      {CUTOFF:"2023-10-27",WK_NO:"W2023_43",DELIVERY_PLAN_WK:0,LIFTING_PLAN_WK:0,DELIVERY_PLAN_CUM:482,LIFTING_PLAN_CUM:508},
      {CUTOFF:"2023-11-03",WK_NO:"W2023_44",DELIVERY_PLAN_WK:2,LIFTING_PLAN_WK:3,DELIVERY_PLAN_CUM:484,LIFTING_PLAN_CUM:511},
      {CUTOFF:"2023-11-10",WK_NO:"W2023_45",DELIVERY_PLAN_WK:0,LIFTING_PLAN_WK:5,DELIVERY_PLAN_CUM:484,LIFTING_PLAN_CUM:516},
      {CUTOFF:"2023-11-17",WK_NO:"W2023_46",DELIVERY_PLAN_WK:0,LIFTING_PLAN_WK:3,DELIVERY_PLAN_CUM:484,LIFTING_PLAN_CUM:519}
    ],
}