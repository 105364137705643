import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1010,
      CanvasHeight: 500,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     

    DataItems: [
      { CDATE: '2019-08-16', ACTIVE: 12463, RAISED: 1099, CLOSED: 476, },
      { CDATE: '2019-08-23', ACTIVE: 13289, RAISED: 1561, CLOSED: 735, },
      { CDATE: '2019-08-30', ACTIVE: 13724, RAISED: 783, CLOSED: 348, },
      { CDATE: '2019-09-06', ACTIVE: 13013, RAISED: 386, CLOSED: 1097, },
      { CDATE: '2019-09-13', ACTIVE: 13046, RAISED: 300, CLOSED: 267, },
      { CDATE: '2019-09-20', ACTIVE: 12917, RAISED: 590, CLOSED: 719, },
      { CDATE: '2019-09-27', ACTIVE: 18110, RAISED: 6304, CLOSED: 1111, },
      { CDATE: '2019-10-04', ACTIVE: 17865, RAISED: 1363, CLOSED: 1608, },
      { CDATE: '2019-10-11', ACTIVE: 17562, RAISED: 815, CLOSED: 1118, },
      { CDATE: '2019-10-18', ACTIVE: 17346, RAISED: 1419, CLOSED: 1635, },
      { CDATE: '2019-10-25', ACTIVE: 17315, RAISED: 1307, CLOSED: 1338, },
      { CDATE: '2019-11-01', ACTIVE: 17371, RAISED: 1755, CLOSED: 1699, },
      { CDATE: '2019-11-08', ACTIVE: 17063, RAISED: 1172, CLOSED: 1480, },
      { CDATE: '2019-11-15', ACTIVE: 17313, RAISED: 1466, CLOSED: 1216, },
      { CDATE: '2019-11-22', ACTIVE: 16412, RAISED: 634, CLOSED: 1535, },
      { CDATE: '2019-11-29', ACTIVE: 15250, RAISED: 798, CLOSED: 1960, },
      { CDATE: '2019-12-06', ACTIVE: 14391, RAISED: 1038, CLOSED: 1897, },
      { CDATE: '2019-12-13', ACTIVE: 13316, RAISED: 916, CLOSED: 1991, },
      { CDATE: '2019-12-20', ACTIVE: 11810, RAISED: 1395, CLOSED: 2901, },
      { CDATE: '2019-12-27', ACTIVE: 12408, RAISED: 1951, CLOSED: 1353, },
      { CDATE: '2020-01-03', ACTIVE: 11987, RAISED: 474, CLOSED: 895, },
      { CDATE: '2020-01-10', ACTIVE: 11417, RAISED: 804, CLOSED: 1374, },
      { CDATE: '2020-01-17', ACTIVE: 11057, RAISED: 1049, CLOSED: 1409, },
      { CDATE: '2020-01-24', ACTIVE: 11028, RAISED: 1107, CLOSED: 1136, },
      { CDATE: '2020-01-31', ACTIVE: 10699, RAISED: 580, CLOSED: 909, },
      { CDATE: '2020-02-07', ACTIVE: 10905, RAISED: 1393, CLOSED: 1187, },
      { CDATE: '2020-02-14', ACTIVE: 10828, RAISED: 1337, CLOSED: 1414, },
      { CDATE: '2020-02-21', ACTIVE: 9691, RAISED: 1320, CLOSED: 2457, },
      { CDATE: '2020-02-28', ACTIVE: 9706, RAISED: 1256, CLOSED: 1241, },
      { CDATE: '2020-03-06', ACTIVE: 9804, RAISED: 140, CLOSED: 42, },
      
    ],

    Queries: {
      SQL1: [
              { LINK1: 'PI', DISC: 'Piping', TOTAL: 1465, ACTUAL: 1204, REMAIN: 261, PROG: 82.18, },
            ],

  }
}