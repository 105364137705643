import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 250,
      CanvasHeight: 300,
      CanvasBorderWeight: 0.5,
      CanvasBorderColor: '#757575',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: '', x:20, y: 10, font: 'roboto', class: 'regular', size: 12, color: '#424242', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: -50, y: 20, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'start' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: -30,
          y: 20,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'start'
        },
      ]
    },
    Queries: {
      SQL1: [
      ],
    },

    DataItems: [
      {TYPE:"A",LINK1:"ELE",DISC:"Electrical",TOTAL:16967,ACTUAL:15614,REMAIN:1353,PROG:92.03},
      {TYPE:"A",LINK1:"HVAC",DISC:"HVAC",TOTAL:2998,ACTUAL:2991,REMAIN:7,PROG:99.77},
      {TYPE:"A",LINK1:"INT",DISC:"Instrument",TOTAL:36413,ACTUAL:33568,REMAIN:2845,PROG:92.19},
      {TYPE:"A",LINK1:"MEC",DISC:"Mechanical",TOTAL:4786,ACTUAL:3770,REMAIN:1016,PROG:78.77},
      {TYPE:"A",LINK1:"OTH",DISC:"Others",TOTAL:7504,ACTUAL:6170,REMAIN:1334,PROG:82.22},
      {TYPE:"A",LINK1:"PIP",DISC:"Piping",TOTAL:15275,ACTUAL:14178,REMAIN:1097,PROG:92.82},
      {TYPE:"A",LINK1:"TEL",DISC:"Telecom",TOTAL:3141,ACTUAL:2929,REMAIN:212,PROG:93.25},
      {TYPE:"A",DISC:"Overall",TOTAL:87084,ACTUAL:79220,REMAIN:7864,PROG:90.97},
      // {TYPE:"B",LINK1:"ELE",DISC:"Electrical",TOTAL:6537,ACTUAL:4037,REMAIN:2500,PROG:61.76},
      // {TYPE:"B",LINK1:"HVAC",DISC:"HVAC",TOTAL:918,ACTUAL:891,REMAIN:27,PROG:97.06},
      // {TYPE:"B",LINK1:"INT",DISC:"Instrument",TOTAL:10838,ACTUAL:3972,REMAIN:6866,PROG:36.65},
      // {TYPE:"B",LINK1:"MEC",DISC:"Mechanical",TOTAL:1141,ACTUAL:383,REMAIN:758,PROG:33.57},
      // {TYPE:"B",LINK1:"PIP",DISC:"Piping",TOTAL:493,ACTUAL:25,REMAIN:468,PROG:5.07},
      // {TYPE:"B",DISC:"Overall",TOTAL:19927,ACTUAL:9308,REMAIN:10619,PROG:46.71}
    ],
}