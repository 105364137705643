import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 610,
      CanvasHeight: 260,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 610,
      CanvasChartHeight: 260,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },
    DataItems:  [
      {WK_NO:"W2019_50",REPAIR_RATE_STR:0,REPAIR_RATE_PIPE:0},
      {WK_NO:"W2019_51",REPAIR_RATE_STR:0.12,REPAIR_RATE_PIPE:0},
      {WK_NO:"W2019_52",REPAIR_RATE_STR:0.07,REPAIR_RATE_PIPE:0},
      {WK_NO:"W2020_01",REPAIR_RATE_STR:0.31,REPAIR_RATE_PIPE:0},
      {WK_NO:"W2020_02",REPAIR_RATE_STR:0,REPAIR_RATE_PIPE:0},
      {WK_NO:"W2020_03",REPAIR_RATE_STR:0,REPAIR_RATE_PIPE:0},
      {WK_NO:"W2020_04",REPAIR_RATE_STR:0,REPAIR_RATE_PIPE:0},
      {WK_NO:"W2020_05",REPAIR_RATE_STR:0,REPAIR_RATE_PIPE:0},
      {WK_NO:"W2020_06",REPAIR_RATE_STR:0,REPAIR_RATE_PIPE:0},
      {WK_NO:"W2020_07",REPAIR_RATE_STR:0,REPAIR_RATE_PIPE:0},
      {WK_NO:"W2020_08",REPAIR_RATE_STR:0,REPAIR_RATE_PIPE:0},
      {WK_NO:"W2020_09",REPAIR_RATE_STR:0,REPAIR_RATE_PIPE:0},
      {WK_NO:"W2020_10",REPAIR_RATE_STR:0,REPAIR_RATE_PIPE:0},
      {WK_NO:"W2020_11",REPAIR_RATE_STR:0.2,REPAIR_RATE_PIPE:1.23},
      {WK_NO:"W2020_12",REPAIR_RATE_STR:0.02,REPAIR_RATE_PIPE:0},
      {WK_NO:"W2020_13",REPAIR_RATE_STR:0.1,REPAIR_RATE_PIPE:1.55},
      {WK_NO:"W2020_14",REPAIR_RATE_STR:0.38,REPAIR_RATE_PIPE:1.16},
      {WK_NO:"W2020_15",REPAIR_RATE_STR:0.1,REPAIR_RATE_PIPE:4},
      {WK_NO:"W2020_16",REPAIR_RATE_STR:0.06,REPAIR_RATE_PIPE:5.56},
      {WK_NO:"W2020_17",REPAIR_RATE_STR:0.3,REPAIR_RATE_PIPE:0},
      {WK_NO:"W2020_18",REPAIR_RATE_STR:0,REPAIR_RATE_PIPE:0},
      {WK_NO:"W2020_19",REPAIR_RATE_STR:0.3,REPAIR_RATE_PIPE:0},
      {WK_NO:"W2020_20",REPAIR_RATE_STR:0,REPAIR_RATE_PIPE:0},
      {WK_NO:"W2020_21",REPAIR_RATE_STR:0.84,REPAIR_RATE_PIPE:0},
      {WK_NO:"W2020_22",REPAIR_RATE_STR:0,REPAIR_RATE_PIPE:0},
      {WK_NO:"W2020_23",REPAIR_RATE_STR:0.67,REPAIR_RATE_PIPE:0},
      {WK_NO:"W2020_24",REPAIR_RATE_STR:0,REPAIR_RATE_PIPE:0},
      {WK_NO:"W2020_25",REPAIR_RATE_STR:0,REPAIR_RATE_PIPE:14.29},
      {WK_NO:"W2020_26",REPAIR_RATE_STR:0.31,REPAIR_RATE_PIPE:2.04},
      {WK_NO:"W2020_27",REPAIR_RATE_STR:0,REPAIR_RATE_PIPE:0}
      ],
}