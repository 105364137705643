import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 945,
      CanvasHeight: 635,
      CanvasBorderWeight: 0.3,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 310,
      CanvasChartHeight: 270,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: 'Transparent',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },

    Text: {
      Text: [
        { text: 'OGM Status', x: 20, y: 10, font: 'roboto', class: 'regular', size: 13, color: '#000', align: 'start', direction: 'Horizontal' },
      ],
      Filter: [],
      Sql: []
    },

    DataItems: [
      {DISC_DESCR:"Structure",TARGET_PROG:35.6,PLAN_PROG:99.17,ACTUAL_PROG:91.16,ACTUAL_CERT_PROG:53.12,DELTA:-8.01,DISC:"36"},
      {DISC_DESCR:"Piping",TARGET_PROG:10.59,PLAN_PROG:81.39,ACTUAL_PROG:51.76,ACTUAL_CERT_PROG:19.17,DELTA:-29.62,DISC:"13"},
      {DISC_DESCR:"Electrical",TARGET_PROG:1.39,PLAN_PROG:61.32,ACTUAL_PROG:11.58,ACTUAL_CERT_PROG:0,DELTA:-49.73,DISC:"16"},
      {DISC_DESCR:"Instrument",TARGET_PROG:0,PLAN_PROG:20.61,ACTUAL_PROG:6.26,ACTUAL_CERT_PROG:2.3,DELTA:-14.35,DISC:"15"},
      {DISC_DESCR:"Mechanical",TARGET_PROG:24.27,PLAN_PROG:96.31,ACTUAL_PROG:63.32,ACTUAL_CERT_PROG:10.51,DELTA:-32.98,DISC:"68"},
      {DISC_DESCR:"Painting",TARGET_PROG:9.36,PLAN_PROG:62.8,ACTUAL_PROG:20.63,ACTUAL_CERT_PROG:16.1,DELTA:-42.16,DISC:"23"},
      {DISC_DESCR:"Insulation",TARGET_PROG:0,PLAN_PROG:0,ACTUAL_PROG:7.29,ACTUAL_CERT_PROG:0,DELTA:7.29,DISC:"22"},
      {DISC_DESCR:"Fire & Safety",TARGET_PROG:0,PLAN_PROG:0,ACTUAL_PROG:0,ACTUAL_CERT_PROG:0,DELTA:0,DISC:"61"}
      ],
    Queries: {
      SQL1: [
        {DISC:"36",GWBS_LV2:"HULL-COMMON",WC_DESCRIPTION:"BLOCK FABRICATION",WCCODE:"36110",UOM:"TONS",INITIALQTY:1745,TARGET_QTY:1689.2,TARGET_PROG:96.79,ACTUAL_CUM_QTY:1745,ACTUAL_CUM_PROG:100,ACTUAL_CERT_PROG:98.28,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:-55.8,REMAIN_PROG:3.21},
        {DISC:"36",GWBS_LV2:"HULL-COMMON",WC_DESCRIPTION:"MEGABLOCK ERECTION",WCCODE:"36130",UOM:"TONS",INITIALQTY:1745,TARGET_QTY:33.5,TARGET_PROG:1.92,ACTUAL_CUM_QTY:1650.1,ACTUAL_CUM_PROG:94.56,ACTUAL_CERT_PROG:87.18,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:-1616.6,REMAIN_PROG:92.64},
        {DISC:"36",GWBS_LV2:"HULL-COMMON",WC_DESCRIPTION:"HULL MATING ACTIVITIES",WCCODE:"36140",UOM:"TONS",INITIALQTY:1,TARGET_QTY:0,TARGET_PROG:0,ACTUAL_CUM_QTY:1,ACTUAL_CUM_PROG:100,ACTUAL_CERT_PROG:15,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:-1,REMAIN_PROG:100},
        {DISC:"36",GWBS_LV2:"HULL-COMMON",WC_DESCRIPTION:"STEEL AREA OUTFITTINGS INSTALLATION - PLATFORMS",WCCODE:"36180",UOM:"KG",INITIALQTY:226985,TARGET_QTY:94539.3,TARGET_PROG:41.65,ACTUAL_CUM_QTY:163134.1,ACTUAL_CUM_PROG:71.87,ACTUAL_CERT_PROG:0,ACTUAL_WEEK_QTY:6945.7,ACTUAL_WEEK_PROG:3.05,REMAIN_QTY:-68594.9,REMAIN_PROG:30.22},
        {DISC:"36",GWBS_LV2:"HULL-COMMON",WC_DESCRIPTION:"STEEL AREA OUTFITTINGS FABRICATION - PLATFORMS",WCCODE:"36185",UOM:"KG",INITIALQTY:226985,TARGET_QTY:64350.2,TARGET_PROG:28.34,ACTUAL_CUM_QTY:218949.7,ACTUAL_CUM_PROG:96.46,ACTUAL_CERT_PROG:0,ACTUAL_WEEK_QTY:1134.9,ACTUAL_WEEK_PROG:0.5,REMAIN_QTY:-154599.5,REMAIN_PROG:68.12},
        {DISC:"36",GWBS_LV2:"HULL-COMMON",WC_DESCRIPTION:"AREA OUTFITTING - TANK TESTING",WCCODE:"36190",UOM:"U",INITIALQTY:61,TARGET_QTY:0,TARGET_PROG:0,ACTUAL_CUM_QTY:52.5,ACTUAL_CUM_PROG:86.05,ACTUAL_CERT_PROG:39.67,ACTUAL_WEEK_QTY:1.2,ACTUAL_WEEK_PROG:1.91,REMAIN_QTY:-52.5,REMAIN_PROG:86.05},
        {DISC:"13",GWBS_LV2:"HULL-COMMON",WC_DESCRIPTION:"BLOCK PIPING SUPPORT FABRICATION",WCCODE:"13110",UOM:"KG",INITIALQTY:98406.76,TARGET_QTY:19415.7,TARGET_PROG:19.73,ACTUAL_CUM_QTY:97777,ACTUAL_CUM_PROG:99.35,ACTUAL_CERT_PROG:0,ACTUAL_WEEK_QTY:580.6,ACTUAL_WEEK_PROG:0.59,REMAIN_QTY:-78361.3,REMAIN_PROG:79.62},
        {DISC:"13",GWBS_LV2:"HULL-COMMON",WC_DESCRIPTION:"BLOCK PIPING SUPPORT INSTALLATION",WCCODE:"13120",UOM:"KG",INITIALQTY:98406.76,TARGET_QTY:18589,TARGET_PROG:18.89,ACTUAL_CUM_QTY:71403.9,ACTUAL_CUM_PROG:72.56,ACTUAL_CERT_PROG:2.89,ACTUAL_WEEK_QTY:2775.1,ACTUAL_WEEK_PROG:2.82,REMAIN_QTY:-52814.9,REMAIN_PROG:53.67},
        {DISC:"13",GWBS_LV2:"HULL-COMMON",WC_DESCRIPTION:"AREA PIPING FABRICATION",WCCODE:"13310",UOM:"DIA",INITIALQTY:77000,TARGET_QTY:17063.2,TARGET_PROG:22.16,ACTUAL_CUM_QTY:74358.9,ACTUAL_CUM_PROG:96.57,ACTUAL_CERT_PROG:67.12,ACTUAL_WEEK_QTY:2279.2,ACTUAL_WEEK_PROG:2.96,REMAIN_QTY:-57295.7,REMAIN_PROG:74.41},
        {DISC:"13",GWBS_LV2:"HULL-COMMON",WC_DESCRIPTION:"AREA PIPING INSTALLATION",WCCODE:"13320",UOM:"DIA",INITIALQTY:38500,TARGET_QTY:1370.6,TARGET_PROG:3.56,ACTUAL_CUM_QTY:9848.3,ACTUAL_CUM_PROG:25.57,ACTUAL_CERT_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:-8477.7,REMAIN_PROG:22.01},
        {DISC:"13",GWBS_LV2:"HULL-COMMON",WC_DESCRIPTION:"PRESSURE TEST",WCCODE:"13900",UOM:"U",INITIALQTY:1124,TARGET_QTY:0,TARGET_PROG:0,ACTUAL_CUM_QTY:16.2,ACTUAL_CUM_PROG:1.43,ACTUAL_CERT_PROG:1.11,ACTUAL_WEEK_QTY:0.6,ACTUAL_WEEK_PROG:0.05,REMAIN_QTY:-16.2,REMAIN_PROG:1.43},
        {DISC:"16",GWBS_LV2:"HULL-COMMON",WC_DESCRIPTION:"GROUNDING AND LIGHTNING SYSTEM",WCCODE:"16100",UOM:"U",INITIALQTY:56,TARGET_QTY:0,TARGET_PROG:0,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_CERT_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:0,REMAIN_PROG:0},
        {DISC:"16",GWBS_LV2:"HULL-COMMON",WC_DESCRIPTION:"INDOOR- ELECTRICAL CABLES",WCCODE:"16250",UOM:"LM",INITIALQTY:7650,TARGET_QTY:0,TARGET_PROG:0,ACTUAL_CUM_QTY:30.6,ACTUAL_CUM_PROG:0.4,ACTUAL_CERT_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:-30.6,REMAIN_PROG:0.4},
        {DISC:"16",GWBS_LV2:"HULL-COMMON",WC_DESCRIPTION:"INDOOR- ELECTRICAL CABLES CONNECTIONS",WCCODE:"16260",UOM:"U",INITIALQTY:1600,TARGET_QTY:0,TARGET_PROG:0,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_CERT_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:0,REMAIN_PROG:0},
        {DISC:"16",GWBS_LV2:"HULL-COMMON",WC_DESCRIPTION:"ELECTRICAL COMPONENTS (PANELS, JUNCTION BOXES)",WCCODE:"16340",UOM:"U",INITIALQTY:87,TARGET_QTY:0,TARGET_PROG:0,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_CERT_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:0,REMAIN_PROG:0},
        {DISC:"16",GWBS_LV2:"HULL-COMMON",WC_DESCRIPTION:"EICT AREA OUTFITTINGS INSTALLATION - CABLE TRAY",WCCODE:"16430",UOM:"LM",INITIALQTY:6300,TARGET_QTY:0,TARGET_PROG:0,ACTUAL_CUM_QTY:238.8,ACTUAL_CUM_PROG:3.78,ACTUAL_CERT_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:-238.8,REMAIN_PROG:3.78},
        {DISC:"16",GWBS_LV2:"HULL-COMMON",WC_DESCRIPTION:"EICT AREA OUTFITTINGS FABRICATION - SUPPORT",WCCODE:"16450",UOM:"KG",INITIALQTY:33100,TARGET_QTY:14822.2,TARGET_PROG:44.78,ACTUAL_CUM_QTY:20439.2,ACTUAL_CUM_PROG:61.74,ACTUAL_CERT_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:-5617.1,REMAIN_PROG:16.96},
        {DISC:"16",GWBS_LV2:"HULL-COMMON",WC_DESCRIPTION:"EICT AREA OUTFITTINGS INSTALLATION - SUPPORT",WCCODE:"16460",UOM:"KG",INITIALQTY:33900,TARGET_QTY:0,TARGET_PROG:0,ACTUAL_CUM_QTY:12166.7,ACTUAL_CUM_PROG:35.89,ACTUAL_CERT_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:-12166.7,REMAIN_PROG:35.89},
        {DISC:"16",GWBS_LV2:"HULL-COMMON",WC_DESCRIPTION:"LIGHTING SYSTEM",WCCODE:"16800",UOM:"U",INITIALQTY:39,TARGET_QTY:0,TARGET_PROG:0,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_CERT_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:0,REMAIN_PROG:0},
        {DISC:"15",GWBS_LV2:"HULL-COMMON",WC_DESCRIPTION:"INDOOR - INSTRUMENT CABLES & MULTIINSTRUMENT CABLES",WCCODE:"15120",UOM:"LM",INITIALQTY:62364.75,TARGET_QTY:0,TARGET_PROG:0,ACTUAL_CUM_QTY:2070.5,ACTUAL_CUM_PROG:3.31,ACTUAL_CERT_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:-2070.5,REMAIN_PROG:3.31},
        {DISC:"15",GWBS_LV2:"HULL-COMMON",WC_DESCRIPTION:"INDOOR - INSTRUMENT CABLES CONNECTIONS",WCCODE:"15130",UOM:"U",INITIALQTY:5800,TARGET_QTY:0,TARGET_PROG:0,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_CERT_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:0,REMAIN_PROG:0},
        {DISC:"15",GWBS_LV2:"HULL-COMMON",WC_DESCRIPTION:"INSTRUMENT COMPONENTS (PANELS, JUNCTION BOXES)",WCCODE:"15240",UOM:"U",INITIALQTY:232,TARGET_QTY:0,TARGET_PROG:0,ACTUAL_CUM_QTY:1.1,ACTUAL_CUM_PROG:0.47,ACTUAL_CERT_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0.01,REMAIN_QTY:-1.1,REMAIN_PROG:0.47},
        {DISC:"15",GWBS_LV2:"HULL-COMMON",WC_DESCRIPTION:"FIELD INSTRUMENTATION WORKS",WCCODE:"15310",UOM:"U",INITIALQTY:1019,TARGET_QTY:0,TARGET_PROG:0,ACTUAL_CUM_QTY:101.4,ACTUAL_CUM_PROG:9.94,ACTUAL_CERT_PROG:4.44,ACTUAL_WEEK_QTY:23.7,ACTUAL_WEEK_PROG:2.32,REMAIN_QTY:-101.4,REMAIN_PROG:9.94},
        {DISC:"15",GWBS_LV2:"HULL-COMMON",WC_DESCRIPTION:"FIELD ON-LINE INSTRUMENTATION WORKS",WCCODE:"15350",UOM:"U",INITIALQTY:192,TARGET_QTY:0,TARGET_PROG:0,ACTUAL_CUM_QTY:0.5,ACTUAL_CUM_PROG:0.26,ACTUAL_CERT_PROG:0.26,ACTUAL_WEEK_QTY:0.5,ACTUAL_WEEK_PROG:0.26,REMAIN_QTY:-0.5,REMAIN_PROG:0.26},
        {DISC:"15",GWBS_LV2:"HULL-COMMON",WC_DESCRIPTION:"ANALYZERS INSTALLATION",WCCODE:"15600",UOM:"U",INITIALQTY:7,TARGET_QTY:0,TARGET_PROG:0,ACTUAL_CUM_QTY:0.6,ACTUAL_CUM_PROG:7.95,ACTUAL_CERT_PROG:7.13,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:-0.6,REMAIN_PROG:7.95},
        {DISC:"68",GWBS_LV2:"HULL-COMMON",WC_DESCRIPTION:"VERTICAL AND HORIZONTAL VESSELS/EXCHANGERS",WCCODE:"68210",UOM:"U",INITIALQTY:4,TARGET_QTY:0,TARGET_PROG:0,ACTUAL_CUM_QTY:1.1,ACTUAL_CUM_PROG:27.38,ACTUAL_CERT_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:-1.1,REMAIN_PROG:27.38},
        {DISC:"68",GWBS_LV2:"HULL-COMMON",WC_DESCRIPTION:"MACHINERY ERECTION - ROTATING",WCCODE:"68500",UOM:"U",INITIALQTY:49,TARGET_QTY:0,TARGET_PROG:0,ACTUAL_CUM_QTY:15.2,ACTUAL_CUM_PROG:31.08,ACTUAL_CERT_PROG:0,ACTUAL_WEEK_QTY:5.2,ACTUAL_WEEK_PROG:10.62,REMAIN_QTY:-15.2,REMAIN_PROG:31.08},
        {DISC:"68",GWBS_LV2:"HULL-COMMON",WC_DESCRIPTION:"SKID, PACKAGES & MISC.",WCCODE:"68810",UOM:"U",INITIALQTY:162,TARGET_QTY:54,TARGET_PROG:33.32,ACTUAL_CUM_QTY:125.4,ACTUAL_CUM_PROG:77.42,ACTUAL_CERT_PROG:14.43,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:-71.5,REMAIN_PROG:44.1},
        {DISC:"68",GWBS_LV2:"HULL-COMMON",WC_DESCRIPTION:"CRANES",WCCODE:"68840",UOM:"U",INITIALQTY:29,TARGET_QTY:0,TARGET_PROG:0,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_CERT_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:0,REMAIN_PROG:0},
        {DISC:"23",GWBS_LV2:"HULL-COMMON",WC_DESCRIPTION:"STEEL STRUCTURE PAINTING",WCCODE:"23110",UOM:"SQM",INITIALQTY:16015,TARGET_QTY:8585.6,TARGET_PROG:53.6,ACTUAL_CUM_QTY:15004.5,ACTUAL_CUM_PROG:93.68,ACTUAL_CERT_PROG:92.87,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:-6418.8,REMAIN_PROG:40.08}
        ]
    }
}