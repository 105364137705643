import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1600,
      CanvasHeight: 1600,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1787,
      CanvasChartHeight: 1050,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Note', x: 1350, y: -20, font: 'roboto', class: 'regular', size: 11, color: '#44A9DF', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: 1350, y: -30, font: 'roboto', class: 'regular', size: 11, color: '#757575', align: 'start' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: 1350,
          y: -50,
          font: 'roboto',
          style: 'regular',
          size: 10,
          color: '#757575',
          align: 'start'
        },
      ]
    },
    Queries: {
      /**Total Interface Point by Status**/
      SQL1:[
        {TOTAL:69,NEW:10,DELETED:30,INPROGRESS:9,DELIVERED:3,CLOSED:57,REJECTED:8,RESUBMITTED:0,OVERDUE_IP:0,CRITICAL_IP:10}
      ],
      /**Total Interface Point by Contractor**/
      SQL2:[
        {REQUESTER:"E2",TOTAL:76},
        {REQUESTER:"EM",TOTAL:5},
        {REQUESTER:"GC",TOTAL:4},
        {REQUESTER:"GF",TOTAL:2},
        {REQUESTER:"HQ",TOTAL:38}
        // {CONTRACTOR_DESCR:"E2-SEI",TOTAL:46},
        // {CONTRACTOR_DESCR:"HQ-HQGD",TOTAL:23}
      ],
      /**Total IQ by Status**/
      SQL3:[
        {TOTAL:18,NEW:3,DELETED:0,INPROGRESS:0,DELIVERED:4,CLOSED:11,REJECTED:0,RESUBMITTED:0,OVERDUE_IP:9,CRITICAL_IP:1}
      ],
      /**Total IQ by Requester**/
      SQL4:[
        {REQUESTER:"E2",TOTAL:5},
        {REQUESTER:"E7",TOTAL:6},
        {REQUESTER:"GC",TOTAL:8},
        {REQUESTER:"GF",TOTAL:2},
        {REQUESTER:"HQ",TOTAL:21},
        {REQUESTER:"HS",TOTAL:2},
        {REQUESTER:"W1",TOTAL:4}
        // {CONTRACTOR_DESCR:"E2-SEI",TOTAL:2},
        // {CONTRACTOR_DESCR:"E7-COOEC",TOTAL:5},
        // {CONTRACTOR_DESCR:"HQ-HQGD",TOTAL:10},
        // {CONTRACTOR_DESCR:"W1-SHDI",TOTAL:1}
      ],
    },
    DataItems: [
      {NO:1,CATEGORY:"Contractor",CODE:"E2",CONTRACTOR_DESCR:"E2-SEI",CONTRACTOR_DESCR2:"SEI(E2)",CONTRACTOR_AREA:"HCC Main Plant",CONTRACTOR_SCOPE:"EPC",IP_TOTAL:70,IQR_TOTAL:7},
      {NO:2,CATEGORY:"Contractor",CODE:"E2_2",CONTRACTOR_DESCR:"E2-SEI",CONTRACTOR_DESCR2:"SEI(E2)",CONTRACTOR_AREA:"B+ Area",CONTRACTOR_SCOPE:"EPC"},
      {NO:3,CATEGORY:"Contractor",CODE:"HZ",CONTRACTOR_DESCR:"HZ-HZED",CONTRACTOR_DESCR2:"HZED(HZ)",CONTRACTOR_AREA:"Main substation",CONTRACTOR_SCOPE:"FSR/BED"},
      {NO:4,CATEGORY:"Contractor",CODE:"HAINAN",CONTRACTOR_DESCR:"HaiNan",CONTRACTOR_DESCR2:"HaiNan",CONTRACTOR_AREA:"Main Substation",CONTRACTOR_SCOPE:"EPC"},
      {NO:5,CATEGORY:"Contractor",CODE:"MT",CONTRACTOR_DESCR:"MT-Meryer",CONTRACTOR_DESCR2:"Meryer(MT)",CONTRACTOR_AREA:"DTC Pilot Plant",CONTRACTOR_SCOPE:"EPC"},
      {NO:6,CATEGORY:"Contractor",CODE:"HS",CONTRACTOR_DESCR:"HS-HAISUM",CONTRACTOR_DESCR2:"HAISUM(HS)",CONTRACTOR_AREA:"DTC Infrastructure",CONTRACTOR_SCOPE:"E+P&C",IQR_TOTAL:2},
      {NO:7,CATEGORY:"Contractor",CODE:"HQ",CONTRACTOR_DESCR:"HQ-HQGD",CONTRACTOR_DESCR2:"HQGD(HQ)",CONTRACTOR_AREA:"QuanMei Terminal",CONTRACTOR_SCOPE:"EPC",IP_TOTAL:38,IQR_TOTAL:22},
      {NO:8,CATEGORY:"Contractor",CODE:"W1",CONTRACTOR_DESCR:"W1-SHDI",CONTRACTOR_DESCR2:"SHDI(W1)",CONTRACTOR_AREA:"QuanMei Jetty",CONTRACTOR_SCOPE:"E",IQR_TOTAL:4},
      {NO:9,CATEGORY:"Contractor",CODE:"GC",CONTRACTOR_DESCR:"GC-GPEC+CPOE",CONTRACTOR_DESCR2:"GPEC+CPOE(GC)",CONTRACTOR_AREA:"Naphtha Pipeline",CONTRACTOR_SCOPE:"EPC",IP_TOTAL:2,IQR_TOTAL:4},
      {NO:10,CATEGORY:"Contractor",CODE:"GF",CONTRACTOR_DESCR:"GF-GPEC+FHDI",CONTRACTOR_DESCR2:"GPEC+FHDI(GF)",CONTRACTOR_AREA:"Naphtha Pipeline",CONTRACTOR_SCOPE:"EPC",IP_TOTAL:1,IQR_TOTAL:1},
      {NO:11,CATEGORY:"Contractor",CODE:"C4_CP6",CONTRACTOR_DESCR:"C4&CP6",CONTRACTOR_DESCR2:"C4&CP6",CONTRACTOR_AREA:"QuanMei Jetty",CONTRACTOR_SCOPE:"Jetty P&C"},
      {NO:12,CATEGORY:"Operator_JV",CODE:"VC1",CONTRACTOR_DESCR:"VC-Vopak",CONTRACTOR_DESCR2:"Vopak(VC)",CONTRACTOR_AREA:"Terminal Operator"},
      {NO:13,CATEGORY:"Operator_JV",CODE:"VC2",CONTRACTOR_DESCR:"VC-Vopak",CONTRACTOR_DESCR2:"Vopak(VC)",CONTRACTOR_AREA:"Jetty Operator"},
      {NO:14,CATEGORY:"Operator_JV",CODE:"JOVO",CONTRACTOR_DESCR:"JOVO-LNG",CONTRACTOR_DESCR2:"JOVO LNG",CONTRACTOR_AREA:"Terminal"},
      {NO:15,CATEGORY:"Third Parties",CODE:"DP",CONTRACTOR_DESCR:"DP-DPRC",CONTRACTOR_DESCR2:"DPRC(DP)",CONTRACTOR_AREA:"Public Piperack"},
      {NO:16,CATEGORY:"Third Parties",CODE:"HZ3RD",CONTRACTOR_DESCR:"HZ-HZED(3rd)",CONTRACTOR_DESCR2:"HZED(HZ)",CONTRACTOR_AREA:"ZhongQu/ChongWen"},
      {NO:17,CATEGORY:"Third Parties",CODE:"DG",CONTRACTOR_DESCR:"DG-YiYuan",CONTRACTOR_DESCR2:"YiYuan(DZ)",CONTRACTOR_AREA:"Industrial Water"},
      {NO:18,CATEGORY:"Third Parties",CODE:"GO",CONTRACTOR_DESCR:"GO-GEG Cogen",CONTRACTOR_DESCR2:"GEG Cogen(GO)",CONTRACTOR_AREA:"SHP Steam, Tail Gas,",CONTRACTOR_SCOPE:"Condensate"},
      {NO:19,CATEGORY:"Third Parties",CODE:"GN",CONTRACTOR_DESCR:"GN-GEG NG",CONTRACTOR_DESCR2:"GEG NG(GN)",CONTRACTOR_AREA:"Nature Gas"},
      {NO:20,CATEGORY:"Third Parties",CODE:"LD",CONTRACTOR_DESCR:"LD-Linde",CONTRACTOR_DESCR2:"Linde(LD)",CONTRACTOR_AREA:"HP Nitrogen,",CONTRACTOR_SCOPE:"LP Nitrogen"},
      {NO:21,CATEGORY:"Third Parties",CODE:"QY",CONTRACTOR_DESCR:"QY-QingYuan",CONTRACTOR_DESCR2:"QingYuan(QY)",CONTRACTOR_AREA:"CW Blowdown, ",CONTRACTOR_SCOPE:"Process WW, etc."},
      {NO:22,CATEGORY:"Third Parties",CODE:"HD",CONTRACTOR_DESCR:"HD-HuaDe",CONTRACTOR_DESCR2:"HuaDe(HD)",CONTRACTOR_AREA:"MBZ Terminal"},
      {NO:23,CATEGORY:"Third Parties",CODE:"CS",CONTRACTOR_DESCR:"CS-CSPC",CONTRACTOR_DESCR2:"CSPC(CS)",CONTRACTOR_AREA:"Mix C4,H2,",CONTRACTOR_SCOPE:"Ethylene, etc."},
      {NO:24,CATEGORY:"Third Parties",CODE:"PG_1",CONTRACTOR_DESCR:"Power Grid",CONTRACTOR_DESCR2:"Power Grid",CONTRACTOR_AREA:"(ChongWen SS)",CONTRACTOR_SCOPE:"220kV"},
      {NO:25,CATEGORY:"Third Parties",CODE:"PG_2",CONTRACTOR_DESCR:"Power Grid",CONTRACTOR_DESCR2:"Power Grid",CONTRACTOR_AREA:"(ZhongQu SS)",CONTRACTOR_SCOPE:"220kV"},
      {NO:26,CATEGORY:"Third Parties",CODE:"CRG",CONTRACTOR_DESCR:"CRG",CONTRACTOR_DESCR2:"CRG",CONTRACTOR_AREA:"Town Gas"},
      {NO:27,CATEGORY:"Third Parties",CODE:"DIP",CONTRACTOR_DESCR:"DIP",CONTRACTOR_DESCR2:"DIP",CONTRACTOR_AREA:"Clean Storm Water"},
      {NO:1,CATEGORY:"InterfaceGate",CODE:"IG-E2-001",LOCATIONS:"South BL gate of Main Plant",COORDINATION:"X:2514432.060 Y:558266.500",REMARKS:"Interface between SEI and HQGD/GEG Cogen/Linde/QingYuan"},
      {NO:2,CATEGORY:"InterfaceGate",CODE:"IG-E2-002",LOCATIONS:"Southeast BL gate with DYW Piperack",COORDINATION:"X: 2514631.000 Y:559036.526",REMARKS:"Interface Between SEI and GEG Cogen for Condensate"},
      {NO:3,CATEGORY:"InterfaceGate",CODE:"IG-E2-003",LOCATIONS:"Northeast BL gate of Main Plant",COORDINATION:"X:2516212.863 Y:558931.304",REMARKS:"Reserved for Phase 2"},
      {NO:4,CATEGORY:"InterfaceGate",CODE:"IG-E2-004",LOCATIONS:"North BL gate of Main Plant",COORDINATION:"X: 2516231.384  Y: 557698.700  ",REMARKS:"Interface between SEI and HZED for 220kV power supply & Telecom FO"},
      {NO:5,CATEGORY:"InterfaceGate",CODE:"IG-E2-005",LOCATIONS:"North BL gate of Main Plant",COORDINATION:"X:2516331.800 Y:557665.000",REMARKS:"Interface between SEI and Yiyuan for Industry Water supply"},
      {NO:6,CATEGORY:"InterfaceGate",CODE:"IG-E2-006",LOCATIONS:"West BL gate of Main Plant",COORDINATION:"X:2515504.900 Y:556970.500   ",REMARKS:"Interface between SEI’s expansion in B+ area with buildings already handed-over to HCCP"},
      {NO:7,CATEGORY:"InterfaceGate",CODE:"IG-E2-007",LOCATIONS:"Northeast BL of GEG Cogen Plant (Plot D)",COORDINATION:"X:2514899.067 Y:559436.000",REMARKS:"Interface between SEI and GEG Cogen for Tail gas, GEG NG for Natural Gas"},
      {NO:8,CATEGORY:"InterfaceGate",CODE:"IG-E2-008",LOCATIONS:"South east corner of Main Plant",COORDINATION:"X:2514432.760 Y:558879.123",REMARKS:"IP for clean storm water discharge, box culvert"},
      {NO:9,CATEGORY:"InterfaceGate",CODE:"IG-E2-009",LOCATIONS:"North west corner of Main Plant",COORDINATION:"X: 2516329.000 Y: 557336.500",REMARKS:"Interface between SEI and DTC"},
      {NO:10,CATEGORY:"InterfaceGate",CODE:"IG-E2-010",LOCATIONS:"West BL gate of Main Plant",COORDINATION:"X:2515847.400 Y:557056.800",REMARKS:"Town gas tie-in point"},
      {NO:11,CATEGORY:"InterfaceGate",CODE:"IG-HQ-001",LOCATIONS:"DPRC Piperack at the east side of QM terminal",COORDINATION:"X:2511394.604 Y:559097.104",REMARKS:"Reserved"},
      {NO:12,CATEGORY:"InterfaceGate",CODE:"IG-HQ-002",LOCATIONS:"Pigging station in QM terminal",COORDINATION:"X:2511080.375 Y:559011.816",REMARKS:"Naphtha pipeline to QM terminal"},
      {NO:13,CATEGORY:"InterfaceGate",CODE:"IG-W1-001",LOCATIONS:"Terminal Project Liquid Products Jetty",COORDINATION:"X:2510481.162 Y:559150.209",REMARKS:"Interface between Terminal and Jetty"},
      {NO:14,CATEGORY:"InterfaceGate",CODE:"IG-GC-001",LOCATIONS:"MBZ trestle",COORDINATION:"X:2508319.56 Y:566113.43",REMARKS:"Interface between subsea pipeline and MBZ"},
      {NO:15,CATEGORY:"InterfaceGate",CODE:"IG-GF-001",LOCATIONS:"MBZ HuaDe Jetty",COORDINATION:"X:2508013.500 Y:567195.963",REMARKS:"Naphtha Pipeline unloading at HUADE Jetty"},
      {NO:16,CATEGORY:"InterfaceGate",CODE:"IG-E2-011",LOCATIONS:"DPRC Piperack at the eastward of Main Plant",REMARKS:"IP for CSPC / SEI"},
      {NO:17,CATEGORY:"InterfaceGate",CODE:"IG-CS-001",LOCATIONS:"CSPC plot fence line",REMARKS:"IP for CSPC / EM at CSPC plot fence line"},
      {NO:18,CATEGORY:"InterfaceGate",CODE:"IG-GC-002",LOCATIONS:"Naphtha Pipeline Landing Point",REMARKS:"IP for FO tie-in between GPEC-COPE and HuaDe at landing point"}
    ],
    }