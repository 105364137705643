export default {
  Canvas: {
    CanvasWidth: 2170,
    CanvasHeight: 1400,
    CanvasBorderWeight: 0.1,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 0,
    CanvasChartHeight: 0,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  Text: {
    Text: [
      { text: 'Note', x: 400, y: -50, font: 'roboto', class: 'regular', size: 11, color: '#44A9DF', align: 'start' },
    ],
    Filter: [
      { name: 'MOD'   , x: 320, y: -50, font: 'roboto', class: 'regular', size: 11, color: '#44A9DF', align: 'start' },
    ],
    Sql: [
      {
        query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
        text: 'DataSource',
        x: 320,
        y: -30,
        font: 'roboto',
        style: 'regular',
        size: 10,
        color: '#757575',
        align: 'start'
      },
    ]
  },
DataItems: [
  {DISC_DESCR:"Electrical",AREA:"FL01",ITEM:"Cable Pulling",UNIT:"LM",C2_QTY:3549,ACTUAL:0,ACTUAL_PROG:0,ACTUAL_TM:0,REMAIN:3549},
  {DISC_DESCR:"Electrical",AREA:"HL03",ITEM:"Cable Pulling",UNIT:"LM",PLAN:106824,PLAN_PROG:100,C2_QTY:106824,ACTUAL:83016,ACTUAL_PROG:77.71,ACTUAL_TW:2855,DELTA:-23808,REMAIN:23808},
  {DISC_DESCR:"Electrical",AREA:"HULL",ITEM:"Cable Pulling",UNIT:"LM",PLAN:109602,PLAN_PROG:97.96,C2_QTY:111884,ACTUAL:84147,ACTUAL_PROG:75.2,ACTUAL_TW:2855,DELTA:-25455,REMAIN:27737},
  {DISC_DESCR:"Electrical",AREA:"HULL & LQ",ITEM:"Cable Pulling",UNIT:"LM",PLAN:285252,PLAN_PROG:96.92,C2_QTY:294298,ACTUAL:254869,ACTUAL_PROG:86.6,ACTUAL_TW:2857,DELTA:-30384,REMAIN:39429},
  {DISC_DESCR:"Electrical",AREA:"HULL-COMMON",ITEM:"Cable Pulling",UNIT:"LM",PLAN:2778,PLAN_PROG:54.9,C2_QTY:5061,ACTUAL:1131,ACTUAL_PROG:22.34,ACTUAL_TW:0,ACTUAL_TM:653,DELTA:-1647,REMAIN:3930},
  {DISC_DESCR:"Electrical",AREA:"HULL-FOR",ITEM:"Cable Pulling",UNIT:"LM",PLAN:106824,PLAN_PROG:100,C2_QTY:106824,ACTUAL:83016,ACTUAL_PROG:77.71,ACTUAL_TW:2855,DELTA:-23808,REMAIN:23808},
  {DISC_DESCR:"Electrical",AREA:"IG90",ITEM:"Cable Pulling",UNIT:"LM",PLAN:60845,PLAN_PROG:40.51,C2_QTY:150178,ACTUAL:56033,ACTUAL_PROG:37.31,ACTUAL_TW:20099,ACTUAL_TM:19742,DELTA:-4812,REMAIN:94145},
  {DISC_DESCR:"Electrical",AREA:"INTEGRATION",ITEM:"Cable Pulling",UNIT:"LM",PLAN:60845,PLAN_PROG:40.51,C2_QTY:150178,ACTUAL:56033,ACTUAL_PROG:37.31,ACTUAL_TW:20099,ACTUAL_TM:19742,DELTA:-4812,REMAIN:94145},
  {DISC_DESCR:"Electrical",AREA:"KO01",ITEM:"Cable Pulling",UNIT:"LM",PLAN:677,PLAN_PROG:10.67,C2_QTY:6341,ACTUAL:0,ACTUAL_PROG:0,ACTUAL_TW:0,ACTUAL_TM:0,DELTA:-677,REMAIN:6341},
  {DISC_DESCR:"Electrical",AREA:"LQ",ITEM:"Cable Pulling",UNIT:"LM",PLAN:175650,PLAN_PROG:96.29,C2_QTY:182414,ACTUAL:170722,ACTUAL_PROG:93.59,ACTUAL_TW:3,ACTUAL_TM:3360,DELTA:-4929,REMAIN:11692},
  {DISC_DESCR:"Electrical",AREA:"LQ00",ITEM:"Cable Pulling",UNIT:"LM",PLAN:175650,PLAN_PROG:96.29,C2_QTY:182414,ACTUAL:170722,ACTUAL_PROG:93.59,ACTUAL_TW:3,ACTUAL_TM:3360,DELTA:-4929,REMAIN:11692},
  {DISC_DESCR:"Electrical",AREA:"M000",ITEM:"Cable Pulling",UNIT:"LM",PLAN:1128,PLAN_PROG:3.2,C2_QTY:35127,ACTUAL:82,ACTUAL_PROG:0.23,ACTUAL_TW:0,ACTUAL_TM:82,DELTA:-1045,REMAIN:35045},
  {DISC_DESCR:"Electrical",AREA:"MD01-2",ITEM:"Cable Pulling",UNIT:"LM",PLAN:2778,PLAN_PROG:54.9,C2_QTY:5061,ACTUAL:1131,ACTUAL_PROG:22.34,ACTUAL_TW:0,ACTUAL_TM:653,DELTA:-1647,REMAIN:3930},
  {DISC_DESCR:"Electrical",AREA:"ME01",ITEM:"Cable Pulling",UNIT:"LM",PLAN:9895,PLAN_PROG:99.61,C2_QTY:9933,ACTUAL:7017,ACTUAL_PROG:70.64,ACTUAL_TM:2845,DELTA:-2878,REMAIN:2916},
  {DISC_DESCR:"Electrical",AREA:"ME02",ITEM:"Cable Pulling",UNIT:"LM",PLAN:10610,PLAN_PROG:96.42,C2_QTY:11003,ACTUAL:6612,ACTUAL_PROG:60.09,ACTUAL_TW:503,ACTUAL_TM:1183,DELTA:-3998,REMAIN:4391},
  {DISC_DESCR:"Electrical",AREA:"ME04",ITEM:"Cable Pulling",UNIT:"LM",PLAN:7305,PLAN_PROG:100,C2_QTY:7305,ACTUAL:7219,ACTUAL_PROG:98.82,ACTUAL_TW:27,ACTUAL_TM:27,DELTA:-86,REMAIN:86},
  {DISC_DESCR:"Electrical",AREA:"ME05",ITEM:"Cable Pulling",UNIT:"LM",PLAN:38662,PLAN_PROG:100,C2_QTY:38662,ACTUAL:34531,ACTUAL_PROG:89.31,ACTUAL_TW:1384,DELTA:-4132,REMAIN:4132},
  {DISC_DESCR:"Electrical",AREA:"MW01",ITEM:"Cable Pulling",UNIT:"LM",PLAN:9328,PLAN_PROG:95.43,C2_QTY:9775,ACTUAL:6408,ACTUAL_PROG:65.55,DELTA:-2920,REMAIN:3367},
  {DISC_DESCR:"Electrical",AREA:"MW02",ITEM:"Cable Pulling",UNIT:"LM",PLAN:12501,PLAN_PROG:95.97,C2_QTY:13026,ACTUAL:10714,ACTUAL_PROG:82.25,ACTUAL_TM:4682,DELTA:-1787,REMAIN:2312},
  {DISC_DESCR:"Electrical",AREA:"MW04",ITEM:"Cable Pulling",UNIT:"LM",PLAN:7436,PLAN_PROG:100,C2_QTY:7436,ACTUAL:7189,ACTUAL_PROG:96.67,DELTA:-247,REMAIN:247},
  {DISC_DESCR:"Electrical",AREA:"MW05",ITEM:"Cable Pulling",UNIT:"LM",PLAN:13071,PLAN_PROG:99.7,C2_QTY:13110,ACTUAL:12980,ACTUAL_PROG:99,DELTA:-91,REMAIN:130},
  {DISC_DESCR:"Electrical",AREA:"TOPSIDES",ITEM:"Cable Pulling",UNIT:"LM",PLAN:110612,PLAN_PROG:71.24,C2_QTY:155265,ACTUAL:92751,ACTUAL_PROG:59.73,ACTUAL_TM:4699,DELTA:-17861,REMAIN:62514},
  {DISC_DESCR:"Electrical",AREA:"TOPSIDES COMMON",ITEM:"Cable Pulling",UNIT:"LM",PLAN:677,PLAN_PROG:6.84,C2_QTY:9890,ACTUAL:0,ACTUAL_PROG:0,ACTUAL_TW:0,ACTUAL_TM:0,DELTA:-677,REMAIN:9890},
  {DISC_DESCR:"Electrical",AREA:"TOPSIDES MODULES",ITEM:"Cable Pulling",UNIT:"LM",PLAN:109935,PLAN_PROG:75.62,C2_QTY:145375,ACTUAL:92751,ACTUAL_PROG:63.8,ACTUAL_TM:4699,DELTA:-17184,REMAIN:52624},
  {DISC_DESCR:"Electrical",AREA:"TOPSIDES MODULES",ITEM:"Cable Termination",UNIT:"U",PLAN:8704,PLAN_PROG:78.19,C2_QTY:11132,ACTUAL:5084,ACTUAL_PROG:45.66,ACTUAL_TW:190,ACTUAL_TM:800,DELTA:-3620,REMAIN:6048},
  {DISC_DESCR:"Electrical",AREA:"TOPSIDES COMMON",ITEM:"Cable Termination",UNIT:"U",PLAN:81,PLAN_PROG:7.62,C2_QTY:1058,ACTUAL:0,ACTUAL_PROG:0,ACTUAL_TW:0,ACTUAL_TM:0,DELTA:-81,REMAIN:1058},
  {DISC_DESCR:"Electrical",AREA:"TOPSIDES",ITEM:"Cable Termination",UNIT:"U",PLAN:8785,PLAN_PROG:72.06,C2_QTY:12190,ACTUAL:5084,ACTUAL_PROG:41.7,ACTUAL_TW:190,ACTUAL_TM:800,DELTA:-3701,REMAIN:7106},
  {DISC_DESCR:"Electrical",AREA:"MW05",ITEM:"Cable Termination",UNIT:"U",PLAN:1030,PLAN_PROG:99.83,C2_QTY:1032,ACTUAL:938,ACTUAL_PROG:90.9,DELTA:-92,REMAIN:94},
  {DISC_DESCR:"Electrical",AREA:"MW04",ITEM:"Cable Termination",UNIT:"U",PLAN:552,PLAN_PROG:100,C2_QTY:552,ACTUAL:458,ACTUAL_PROG:82.89,ACTUAL_TW:65,ACTUAL_TM:102,DELTA:-94,REMAIN:94},
  {DISC_DESCR:"Electrical",AREA:"MW02",ITEM:"Cable Termination",UNIT:"U",PLAN:1037,PLAN_PROG:95.97,C2_QTY:1080,ACTUAL:692,ACTUAL_PROG:64.02,ACTUAL_TM:247,DELTA:-345,REMAIN:389},
  {DISC_DESCR:"Electrical",AREA:"MW01",ITEM:"Cable Termination",UNIT:"U",PLAN:1055,PLAN_PROG:95.77,C2_QTY:1102,ACTUAL:423,ACTUAL_PROG:38.39,ACTUAL_TM:58,DELTA:-632,REMAIN:679},
  {DISC_DESCR:"Electrical",AREA:"ME05",ITEM:"Cable Termination",UNIT:"U",PLAN:2730,PLAN_PROG:100,C2_QTY:2730,ACTUAL:1465,ACTUAL_PROG:53.66,ACTUAL_TW:70,ACTUAL_TM:91,DELTA:-1265,REMAIN:1265},
  {DISC_DESCR:"Electrical",AREA:"ME04",ITEM:"Cable Termination",UNIT:"U",PLAN:532,PLAN_PROG:100,C2_QTY:532,ACTUAL:456,ACTUAL_PROG:85.75,ACTUAL_TW:27,ACTUAL_TM:136,DELTA:-76,REMAIN:76},
  {DISC_DESCR:"Electrical",AREA:"ME02",ITEM:"Cable Termination",UNIT:"U",PLAN:853,PLAN_PROG:96.44,C2_QTY:884,ACTUAL:326,ACTUAL_PROG:36.85,ACTUAL_TW:81,ACTUAL_TM:115,DELTA:-527,REMAIN:558},
  {DISC_DESCR:"Electrical",AREA:"ME01",ITEM:"Cable Termination",UNIT:"U",PLAN:842,PLAN_PROG:99.95,C2_QTY:842,ACTUAL:327,ACTUAL_PROG:38.77,ACTUAL_TW:26,ACTUAL_TM:86,DELTA:-515,REMAIN:516},
  {DISC_DESCR:"Electrical",AREA:"MD01-2",ITEM:"Cable Termination",UNIT:"U",PLAN:7,PLAN_PROG:11.52,C2_QTY:62,ACTUAL:0,ACTUAL_PROG:0,ACTUAL_TW:0,ACTUAL_TM:0,DELTA:-7,REMAIN:62},
  {DISC_DESCR:"Electrical",AREA:"M000",ITEM:"Cable Termination",UNIT:"U",PLAN:74,PLAN_PROG:3.1,C2_QTY:2378,ACTUAL:0,ACTUAL_PROG:0,ACTUAL_TW:0,ACTUAL_TM:0,DELTA:-74,REMAIN:2378},
  {DISC_DESCR:"Electrical",AREA:"LQ00",ITEM:"Cable Termination",UNIT:"U",PLAN:7783,PLAN_PROG:86.13,C2_QTY:9036,ACTUAL:3659,ACTUAL_PROG:40.48,ACTUAL_TW:314,ACTUAL_TM:366,DELTA:-4125,REMAIN:5378},
  {DISC_DESCR:"Electrical",AREA:"LQ",ITEM:"Cable Termination",UNIT:"U",PLAN:7783,PLAN_PROG:86.13,C2_QTY:9036,ACTUAL:3659,ACTUAL_PROG:40.48,ACTUAL_TW:314,ACTUAL_TM:366,DELTA:-4125,REMAIN:5378},
  {DISC_DESCR:"Electrical",AREA:"KO01",ITEM:"Cable Termination",UNIT:"U",PLAN:81,PLAN_PROG:10.66,C2_QTY:756,ACTUAL:0,ACTUAL_PROG:0,ACTUAL_TW:0,ACTUAL_TM:0,DELTA:-81,REMAIN:756},
  {DISC_DESCR:"Electrical",AREA:"INTEGRATION",ITEM:"Cable Termination",UNIT:"U",PLAN:657,PLAN_PROG:24.63,C2_QTY:2666,ACTUAL:0,ACTUAL_PROG:0,ACTUAL_TW:0,ACTUAL_TM:0,DELTA:-657,REMAIN:2666},
  {DISC_DESCR:"Electrical",AREA:"IG90",ITEM:"Cable Termination",UNIT:"U",PLAN:657,PLAN_PROG:24.63,C2_QTY:2666,ACTUAL:0,ACTUAL_PROG:0,ACTUAL_TW:0,ACTUAL_TM:0,DELTA:-657,REMAIN:2666},
  {DISC_DESCR:"Electrical",AREA:"HULL-FOR",ITEM:"Cable Termination",UNIT:"U",PLAN:3790,PLAN_PROG:95.18,C2_QTY:3982,ACTUAL:3118,ACTUAL_PROG:78.29,ACTUAL_TW:180,ACTUAL_TM:238,DELTA:-673,REMAIN:864},
  {DISC_DESCR:"Electrical",AREA:"HULL-COMMON",ITEM:"Cable Termination",UNIT:"U",PLAN:7,PLAN_PROG:11.52,C2_QTY:62,ACTUAL:0,ACTUAL_PROG:0,ACTUAL_TW:0,ACTUAL_TM:0,DELTA:-7,REMAIN:62},
  {DISC_DESCR:"Electrical",AREA:"HULL & LQ",ITEM:"Cable Termination",UNIT:"U",PLAN:11581,PLAN_PROG:88.53,C2_QTY:13080,ACTUAL:6776,ACTUAL_PROG:51.8,ACTUAL_TW:494,ACTUAL_TM:604,DELTA:-4805,REMAIN:6304},
  {DISC_DESCR:"Electrical",AREA:"HULL",ITEM:"Cable Termination",UNIT:"U",PLAN:3797,PLAN_PROG:93.9,C2_QTY:4044,ACTUAL:3118,ACTUAL_PROG:77.09,ACTUAL_TW:180,ACTUAL_TM:238,DELTA:-680,REMAIN:926},
  {DISC_DESCR:"Electrical",AREA:"HL03",ITEM:"Cable Termination",UNIT:"U",PLAN:3790,PLAN_PROG:95.18,C2_QTY:3982,ACTUAL:3118,ACTUAL_PROG:78.29,ACTUAL_TW:180,ACTUAL_TM:238,DELTA:-673,REMAIN:864},
  {DISC_DESCR:"Electrical",AREA:"FL01",ITEM:"Cable Termination",UNIT:"U",C2_QTY:302,ACTUAL:0,ACTUAL_PROG:0,ACTUAL_TM:0,REMAIN:302},
  {DISC_DESCR:"Instrument",AREA:"FL01",ITEM:"Cable Pulling",UNIT:"LM",C2_QTY:1791,ACTUAL:0,ACTUAL_PROG:0,ACTUAL_TW:0,ACTUAL_TM:0,REMAIN:1791},
  {DISC_DESCR:"Instrument",AREA:"HL03",ITEM:"Cable Pulling",UNIT:"LM",PLAN:80795,PLAN_PROG:100,C2_QTY:80795,ACTUAL:65920,ACTUAL_PROG:81.58,ACTUAL_TW:1083,ACTUAL_TM:5269,DELTA:-14875,REMAIN:14875},
  {DISC_DESCR:"Instrument",AREA:"HULL",ITEM:"Cable Pulling",UNIT:"LM",PLAN:115274,PLAN_PROG:74.12,C2_QTY:155506,ACTUAL:74205,ACTUAL_PROG:47.71,ACTUAL_TW:2608,ACTUAL_TM:7637,DELTA:-41070,REMAIN:81302},
  {DISC_DESCR:"Instrument",AREA:"HULL & LQ",ITEM:"Cable Pulling",UNIT:"LM",PLAN:254546,PLAN_PROG:84.32,C2_QTY:301847,ACTUAL:188597,ACTUAL_PROG:62.48,ACTUAL_TW:4363,ACTUAL_TM:12174,DELTA:-65949,REMAIN:113250},
  {DISC_DESCR:"Instrument",AREA:"HULL-COMMON",ITEM:"Cable Pulling",UNIT:"LM",PLAN:34479,PLAN_PROG:46.15,C2_QTY:74711,ACTUAL:8285,ACTUAL_PROG:11.08,ACTUAL_TW:1525,ACTUAL_TM:2369,DELTA:-26195,REMAIN:66427},
  {DISC_DESCR:"Instrument",AREA:"HULL-FOR",ITEM:"Cable Pulling",UNIT:"LM",PLAN:80795,PLAN_PROG:100,C2_QTY:80795,ACTUAL:65920,ACTUAL_PROG:81.58,ACTUAL_TW:1083,ACTUAL_TM:5269,DELTA:-14875,REMAIN:14875},
  {DISC_DESCR:"Instrument",AREA:"IG90",ITEM:"Cable Pulling",UNIT:"LM",PLAN:88841,PLAN_PROG:33.42,C2_QTY:265755,ACTUAL:47583,ACTUAL_PROG:17.9,ACTUAL_TW:17440,ACTUAL_TM:30285,DELTA:-41258,REMAIN:218172},
  {DISC_DESCR:"Instrument",AREA:"INTEGRATION",ITEM:"Cable Pulling",UNIT:"LM",PLAN:88841,PLAN_PROG:33.42,C2_QTY:265755,ACTUAL:47583,ACTUAL_PROG:17.9,ACTUAL_TW:17440,ACTUAL_TM:30285,DELTA:-41258,REMAIN:218172},
  {DISC_DESCR:"Instrument",AREA:"KO01",ITEM:"Cable Pulling",UNIT:"LM",PLAN:11,PLAN_PROG:18.88,C2_QTY:60,ACTUAL:0,ACTUAL_PROG:0,ACTUAL_TW:0,DELTA:-11,REMAIN:60},
  {DISC_DESCR:"Instrument",AREA:"LQ",ITEM:"Cable Pulling",UNIT:"LM",PLAN:139271,PLAN_PROG:95.16,C2_QTY:146341,ACTUAL:114393,ACTUAL_PROG:78.16,ACTUAL_TW:1755,ACTUAL_TM:4537,DELTA:-24879,REMAIN:31948},
  {DISC_DESCR:"Instrument",AREA:"LQ00",ITEM:"Cable Pulling",UNIT:"LM",PLAN:139271,PLAN_PROG:95.16,C2_QTY:146341,ACTUAL:114393,ACTUAL_PROG:78.16,ACTUAL_TW:1755,ACTUAL_TM:4537,DELTA:-24879,REMAIN:31948},
  {DISC_DESCR:"Instrument",AREA:"M000",ITEM:"Cable Pulling",UNIT:"LM",PLAN:23,PLAN_PROG:0.22,C2_QTY:10173,ACTUAL:0,ACTUAL_PROG:0,ACTUAL_TW:0,ACTUAL_TM:0,DELTA:-23,REMAIN:10173},
  {DISC_DESCR:"Instrument",AREA:"MD01-2",ITEM:"Cable Pulling",UNIT:"LM",PLAN:34479,PLAN_PROG:46.15,C2_QTY:74711,ACTUAL:8285,ACTUAL_PROG:11.08,ACTUAL_TW:1525,ACTUAL_TM:2369,DELTA:-26195,REMAIN:66427},
  {DISC_DESCR:"Instrument",AREA:"ME01",ITEM:"Cable Pulling",UNIT:"LM",PLAN:16974,PLAN_PROG:100,C2_QTY:16974,ACTUAL:7630,ACTUAL_PROG:44.95,ACTUAL_TW:4519,ACTUAL_TM:7630,DELTA:-9344,REMAIN:9344},
  {DISC_DESCR:"Instrument",AREA:"ME02",ITEM:"Cable Pulling",UNIT:"LM",PLAN:10968,PLAN_PROG:69.73,C2_QTY:15729,ACTUAL:9350,ACTUAL_PROG:59.44,ACTUAL_TW:2465,ACTUAL_TM:5368,DELTA:-1619,REMAIN:6379},
  {DISC_DESCR:"Instrument",AREA:"ME04",ITEM:"Cable Pulling",UNIT:"LM",PLAN:5986,PLAN_PROG:100,C2_QTY:5986,ACTUAL:2934,ACTUAL_PROG:49.01,ACTUAL_TW:0,ACTUAL_TM:4,DELTA:-3052,REMAIN:3052},
  {DISC_DESCR:"Instrument",AREA:"ME05",ITEM:"Cable Pulling",UNIT:"LM",PLAN:24408,PLAN_PROG:100,C2_QTY:24408,ACTUAL:13671,ACTUAL_PROG:56.01,ACTUAL_TW:664,DELTA:-10736,REMAIN:10736},
  {DISC_DESCR:"Instrument",AREA:"MW01",ITEM:"Cable Pulling",UNIT:"LM",PLAN:7400,PLAN_PROG:97.64,C2_QTY:7579,ACTUAL:4632,ACTUAL_PROG:61.1,ACTUAL_TW:1234,ACTUAL_TM:4381,DELTA:-2769,REMAIN:2948},
  {DISC_DESCR:"Instrument",AREA:"MW02",ITEM:"Cable Pulling",UNIT:"LM",PLAN:15177,PLAN_PROG:72.3,C2_QTY:20991,ACTUAL:18572,ACTUAL_PROG:88.47,ACTUAL_TW:10557,ACTUAL_TM:18572,DELTA:3395,REMAIN:2419},
  {DISC_DESCR:"Instrument",AREA:"MW04",ITEM:"Cable Pulling",UNIT:"LM",PLAN:4945,PLAN_PROG:100,C2_QTY:4945,ACTUAL:2275,ACTUAL_PROG:46,ACTUAL_TW:0,ACTUAL_TM:0,DELTA:-2670,REMAIN:2670},
  {DISC_DESCR:"Instrument",AREA:"MW05",ITEM:"Cable Pulling",UNIT:"LM",PLAN:14360,PLAN_PROG:100,C2_QTY:14360,ACTUAL:10805,ACTUAL_PROG:75.24,ACTUAL_TW:0,ACTUAL_TM:0,DELTA:-3556,REMAIN:3556},
  {DISC_DESCR:"Instrument",AREA:"TOPSIDES",ITEM:"Cable Pulling",UNIT:"LM",PLAN:100251,PLAN_PROG:81.5,C2_QTY:122994,ACTUAL:69868,ACTUAL_PROG:56.8,ACTUAL_TW:19438,ACTUAL_TM:34388,DELTA:-30384,REMAIN:53126},
  {DISC_DESCR:"Instrument",AREA:"TOPSIDES COMMON",ITEM:"Cable Pulling",UNIT:"LM",PLAN:11,PLAN_PROG:0.61,C2_QTY:1851,ACTUAL:0,ACTUAL_PROG:0,ACTUAL_TW:0,DELTA:-11,REMAIN:1851},
  {DISC_DESCR:"Instrument",AREA:"TOPSIDES MODULES",ITEM:"Cable Pulling",UNIT:"LM",PLAN:100240,PLAN_PROG:82.74,C2_QTY:121143,ACTUAL:69868,ACTUAL_PROG:57.67,ACTUAL_TW:19438,ACTUAL_TM:34722,DELTA:-30372,REMAIN:51275},
  {DISC_DESCR:"Instrument",AREA:"TOPSIDES MODULES",ITEM:"Cable Termination",UNIT:"U",PLAN:5264,PLAN_PROG:80.16,C2_QTY:6566,ACTUAL:748,ACTUAL_PROG:11.39,ACTUAL_TW:214,ACTUAL_TM:614,DELTA:-4516,REMAIN:5818},
  {DISC_DESCR:"Instrument",AREA:"TOPSIDES COMMON",ITEM:"Cable Termination",UNIT:"U",C2_QTY:126,ACTUAL:0,ACTUAL_PROG:0,ACTUAL_TW:0,ACTUAL_TM:0,REMAIN:126},
  {DISC_DESCR:"Instrument",AREA:"TOPSIDES",ITEM:"Cable Termination",UNIT:"U",PLAN:5264,PLAN_PROG:78.66,C2_QTY:6692,ACTUAL:748,ACTUAL_PROG:11.17,ACTUAL_TW:214,ACTUAL_TM:614,DELTA:-4516,REMAIN:5944},
  {DISC_DESCR:"Instrument",AREA:"MW05",ITEM:"Cable Termination",UNIT:"U",PLAN:676,PLAN_PROG:100,C2_QTY:676,ACTUAL:0,ACTUAL_PROG:0,ACTUAL_TW:0,ACTUAL_TM:0,DELTA:-676,REMAIN:676},
  {DISC_DESCR:"Instrument",AREA:"MW04",ITEM:"Cable Termination",UNIT:"U",PLAN:238,PLAN_PROG:100,C2_QTY:238,ACTUAL:8,ACTUAL_PROG:3.36,ACTUAL_TW:0,ACTUAL_TM:0,DELTA:-230,REMAIN:230},
  {DISC_DESCR:"Instrument",AREA:"MW02",ITEM:"Cable Termination",UNIT:"U",PLAN:487,PLAN_PROG:56.52,C2_QTY:862,ACTUAL:182,ACTUAL_PROG:21.11,ACTUAL_TW:0,ACTUAL_TM:182,DELTA:-305,REMAIN:680},
  {DISC_DESCR:"Instrument",AREA:"MW01",ITEM:"Cable Termination",UNIT:"U",PLAN:676,PLAN_PROG:100,C2_QTY:676,ACTUAL:112,ACTUAL_PROG:16.56,ACTUAL_TW:0,ACTUAL_TM:112,DELTA:-564,REMAIN:564},
  {DISC_DESCR:"Instrument",AREA:"ME05",ITEM:"Cable Termination",UNIT:"U",PLAN:1926,PLAN_PROG:100,C2_QTY:1926,ACTUAL:442,ACTUAL_PROG:22.94,ACTUAL_TW:214,ACTUAL_TM:316,DELTA:-1484,REMAIN:1484},
  {DISC_DESCR:"Instrument",AREA:"ME04",ITEM:"Cable Termination",UNIT:"U",PLAN:262,PLAN_PROG:100,C2_QTY:262,ACTUAL:0,ACTUAL_PROG:0,ACTUAL_TW:0,ACTUAL_TM:0,DELTA:-262,REMAIN:262},
  {DISC_DESCR:"Instrument",AREA:"ME02",ITEM:"Cable Termination",UNIT:"U",PLAN:375,PLAN_PROG:56.09,C2_QTY:668,ACTUAL:0,ACTUAL_PROG:0,ACTUAL_TW:0,ACTUAL_TM:0,DELTA:-375,REMAIN:668},
  {DISC_DESCR:"Instrument",AREA:"ME01",ITEM:"Cable Termination",UNIT:"U",PLAN:624,PLAN_PROG:100,C2_QTY:624,ACTUAL:0,ACTUAL_PROG:0,ACTUAL_TW:0,ACTUAL_TM:0,DELTA:-624,REMAIN:624},
  {DISC_DESCR:"Instrument",AREA:"MD01-2",ITEM:"Cable Termination",UNIT:"U",PLAN:466,PLAN_PROG:27.37,C2_QTY:1702,ACTUAL:0,ACTUAL_PROG:0,ACTUAL_TW:0,DELTA:-466,REMAIN:1702},
  {DISC_DESCR:"Instrument",AREA:"M000",ITEM:"Cable Termination",UNIT:"U",PLAN:0,PLAN_PROG:0,C2_QTY:634,ACTUAL:4,ACTUAL_PROG:0.63,ACTUAL_TW:0,ACTUAL_TM:4,DELTA:4,REMAIN:630},
  {DISC_DESCR:"Instrument",AREA:"LQ00",ITEM:"Cable Termination",UNIT:"U",PLAN:7152,PLAN_PROG:78.35,C2_QTY:9128,ACTUAL:3338,ACTUAL_PROG:36.56,ACTUAL_TW:332,ACTUAL_TM:710,DELTA:-3814,REMAIN:5790},
  {DISC_DESCR:"Instrument",AREA:"LQ",ITEM:"Cable Termination",UNIT:"U",PLAN:7152,PLAN_PROG:78.35,C2_QTY:9128,ACTUAL:3338,ACTUAL_PROG:36.56,ACTUAL_TW:332,ACTUAL_TM:710,DELTA:-3814,REMAIN:5790},
  {DISC_DESCR:"Instrument",AREA:"KO01",ITEM:"Cable Termination",UNIT:"U",C2_QTY:4,ACTUAL:0,ACTUAL_PROG:0,ACTUAL_TW:0,ACTUAL_TM:0,REMAIN:4},
  {DISC_DESCR:"Instrument",AREA:"INTEGRATION",ITEM:"Cable Termination",UNIT:"U",PLAN:883,PLAN_PROG:20.45,C2_QTY:4318,ACTUAL:0,ACTUAL_PROG:0,ACTUAL_TW:0,ACTUAL_TM:0,DELTA:-883,REMAIN:4318},
  {DISC_DESCR:"Instrument",AREA:"IG90",ITEM:"Cable Termination",UNIT:"U",PLAN:883,PLAN_PROG:20.45,C2_QTY:4318,ACTUAL:0,ACTUAL_PROG:0,ACTUAL_TW:0,ACTUAL_TM:0,DELTA:-883,REMAIN:4318},
  {DISC_DESCR:"Instrument",AREA:"HULL-FOR",ITEM:"Cable Termination",UNIT:"U",PLAN:2535,PLAN_PROG:73.86,C2_QTY:3432,ACTUAL:2108,ACTUAL_PROG:61.42,ACTUAL_TW:276,ACTUAL_TM:426,DELTA:-427,REMAIN:1324},
  {DISC_DESCR:"Instrument",AREA:"HULL-COMMON",ITEM:"Cable Termination",UNIT:"U",PLAN:466,PLAN_PROG:27.37,C2_QTY:1702,ACTUAL:0,ACTUAL_PROG:0,ACTUAL_TW:0,DELTA:-466,REMAIN:1702},
  {DISC_DESCR:"Instrument",AREA:"HULL & LQ",ITEM:"Cable Termination",UNIT:"U",PLAN:10153,PLAN_PROG:71.18,C2_QTY:14262,ACTUAL:5446,ACTUAL_PROG:38.18,ACTUAL_TW:608,ACTUAL_TM:1130,DELTA:-4707,REMAIN:8816},
  {DISC_DESCR:"Instrument",AREA:"HULL",ITEM:"Cable Termination",UNIT:"U",PLAN:3001,PLAN_PROG:58.45,C2_QTY:5134,ACTUAL:2108,ACTUAL_PROG:41.05,ACTUAL_TW:276,ACTUAL_TM:420,DELTA:-893,REMAIN:3026},
  {DISC_DESCR:"Instrument",AREA:"HL03",ITEM:"Cable Termination",UNIT:"U",PLAN:2535,PLAN_PROG:73.86,C2_QTY:3432,ACTUAL:2108,ACTUAL_PROG:61.42,ACTUAL_TW:276,ACTUAL_TM:426,DELTA:-427,REMAIN:1324},
  {DISC_DESCR:"Instrument",AREA:"FL01",ITEM:"Cable Termination",UNIT:"U",C2_QTY:122,ACTUAL:0,ACTUAL_PROG:0,ACTUAL_TW:0,ACTUAL_TM:0,REMAIN:122},
  {DISC_DESCR:"Total",AREA:"ME05",ITEM:"Cable Pulling",UNIT:"LM",PLAN:63070,PLAN_PROG:100,C2_QTY:63070,ACTUAL:48202,ACTUAL_PROG:76.42,ACTUAL_TW:2048,DELTA:-14868,REMAIN:14868},
  {DISC_DESCR:"Total",AREA:"MW01",ITEM:"Cable Pulling",UNIT:"LM",PLAN:16728,PLAN_PROG:96.39,C2_QTY:17354,ACTUAL:11040,ACTUAL_PROG:63.61,ACTUAL_TM:4049,DELTA:-5689,REMAIN:6315},
  {DISC_DESCR:"Total",AREA:"MW02",ITEM:"Cable Pulling",UNIT:"LM",PLAN:27678,PLAN_PROG:81.36,C2_QTY:34017,ACTUAL:29286,ACTUAL_PROG:86.09,ACTUAL_TW:10166,ACTUAL_TM:23254,DELTA:1608,REMAIN:4731},
  {DISC_DESCR:"Total",AREA:"MW04",ITEM:"Cable Pulling",UNIT:"LM",PLAN:12381,PLAN_PROG:100,C2_QTY:12381,ACTUAL:9464,ACTUAL_PROG:76.43,DELTA:-2917,REMAIN:2917},
  {DISC_DESCR:"Total",AREA:"MW05",ITEM:"Cable Pulling",UNIT:"LM",PLAN:27431,PLAN_PROG:99.85,C2_QTY:27470,ACTUAL:23785,ACTUAL_PROG:86.58,DELTA:-3647,REMAIN:3686},
  {DISC_DESCR:"Total",AREA:"TOPSIDES",ITEM:"Cable Pulling",UNIT:"LM",PLAN:210863,PLAN_PROG:75.77,C2_QTY:278259,ACTUAL:162619,ACTUAL_PROG:58.44,ACTUAL_TW:15210,ACTUAL_TM:39087,DELTA:-48245,REMAIN:115640},
  {DISC_DESCR:"Total",AREA:"TOPSIDES COMMON",ITEM:"Cable Pulling",UNIT:"LM",PLAN:688,PLAN_PROG:5.85,C2_QTY:11741,ACTUAL:0,ACTUAL_PROG:0,ACTUAL_TW:0,DELTA:-688,REMAIN:11741},
  {DISC_DESCR:"Total",AREA:"TOPSIDES MODULES",ITEM:"Cable Pulling",UNIT:"LM",PLAN:210175,PLAN_PROG:78.85,C2_QTY:266518,ACTUAL:162619,ACTUAL_PROG:61.01,ACTUAL_TW:15210,ACTUAL_TM:39421,DELTA:-47556,REMAIN:103899},
  {DISC_DESCR:"Total",AREA:"FL01",ITEM:"Cable Pulling",UNIT:"LM",C2_QTY:5340,ACTUAL:0,ACTUAL_PROG:0,ACTUAL_TW:0,ACTUAL_TM:0,REMAIN:5340},
  {DISC_DESCR:"Total",AREA:"HL03",ITEM:"Cable Pulling",UNIT:"LM",PLAN:187619,PLAN_PROG:100,C2_QTY:187619,ACTUAL:148936,ACTUAL_PROG:79.38,ACTUAL_TW:3938,DELTA:-38683,REMAIN:38683},
  {DISC_DESCR:"Total",AREA:"HULL",ITEM:"Cable Pulling",UNIT:"LM",PLAN:224876,PLAN_PROG:84.1,C2_QTY:267390,ACTUAL:158352,ACTUAL_PROG:59.22,ACTUAL_TW:5463,ACTUAL_TM:1068,DELTA:-66525,REMAIN:109039},
  {DISC_DESCR:"Total",AREA:"HULL & LQ",ITEM:"Cable Pulling",UNIT:"LM",PLAN:539798,PLAN_PROG:90.54,C2_QTY:596145,ACTUAL:443466,ACTUAL_PROG:74.38,ACTUAL_TW:7220,ACTUAL_TM:8965,DELTA:-96333,REMAIN:152679},
  {DISC_DESCR:"Total",AREA:"HULL-COMMON",ITEM:"Cable Pulling",UNIT:"LM",PLAN:37257,PLAN_PROG:46.7,C2_QTY:79772,ACTUAL:9416,ACTUAL_PROG:11.8,ACTUAL_TW:1525,ACTUAL_TM:3022,DELTA:-27842,REMAIN:70357},
  {DISC_DESCR:"Total",AREA:"HULL-FOR",ITEM:"Cable Pulling",UNIT:"LM",PLAN:187619,PLAN_PROG:100,C2_QTY:187619,ACTUAL:148936,ACTUAL_PROG:79.38,ACTUAL_TW:3938,DELTA:-38683,REMAIN:38683},
  {DISC_DESCR:"Total",AREA:"IG90",ITEM:"Cable Pulling",UNIT:"LM",PLAN:149686,PLAN_PROG:35.98,C2_QTY:415933,ACTUAL:103616,ACTUAL_PROG:24.91,ACTUAL_TW:37539,ACTUAL_TM:50027,DELTA:-46070,REMAIN:312317},
  {DISC_DESCR:"Total",AREA:"INTEGRATION",ITEM:"Cable Pulling",UNIT:"LM",PLAN:149686,PLAN_PROG:35.98,C2_QTY:415933,ACTUAL:103616,ACTUAL_PROG:24.91,ACTUAL_TW:37539,ACTUAL_TM:50027,DELTA:-46070,REMAIN:312317},
  {DISC_DESCR:"Total",AREA:"KO01",ITEM:"Cable Pulling",UNIT:"LM",PLAN:688,PLAN_PROG:10.74,C2_QTY:6401,ACTUAL:0,ACTUAL_PROG:0,ACTUAL_TW:0,DELTA:-688,REMAIN:6401},
  {DISC_DESCR:"Total",AREA:"LQ",ITEM:"Cable Pulling",UNIT:"LM",PLAN:314921,PLAN_PROG:95.79,C2_QTY:328755,ACTUAL:285115,ACTUAL_PROG:86.72,ACTUAL_TW:1758,ACTUAL_TM:7897,DELTA:-29808,REMAIN:43640},
  {DISC_DESCR:"Total",AREA:"LQ00",ITEM:"Cable Pulling",UNIT:"LM",PLAN:314921,PLAN_PROG:95.79,C2_QTY:328755,ACTUAL:285115,ACTUAL_PROG:86.72,ACTUAL_TW:1758,ACTUAL_TM:7897,DELTA:-29808,REMAIN:43640},
  {DISC_DESCR:"Total",AREA:"M000",ITEM:"Cable Pulling",UNIT:"LM",PLAN:1151,PLAN_PROG:2.54,C2_QTY:45300,ACTUAL:82,ACTUAL_PROG:0.18,ACTUAL_TW:0,ACTUAL_TM:82,DELTA:-1068,REMAIN:45218},
  {DISC_DESCR:"Total",AREA:"MD01-2",ITEM:"Cable Pulling",UNIT:"LM",PLAN:37257,PLAN_PROG:46.7,C2_QTY:79772,ACTUAL:9416,ACTUAL_PROG:11.8,ACTUAL_TW:1525,ACTUAL_TM:3022,DELTA:-27842,REMAIN:70357},
  {DISC_DESCR:"Total",AREA:"ME01",ITEM:"Cable Pulling",UNIT:"LM",PLAN:26869,PLAN_PROG:99.85,C2_QTY:26907,ACTUAL:14647,ACTUAL_PROG:54.43,ACTUAL_TW:3032,ACTUAL_TM:10475,DELTA:-12222,REMAIN:12260},
  {DISC_DESCR:"Total",AREA:"ME02",ITEM:"Cable Pulling",UNIT:"LM",PLAN:21578,PLAN_PROG:80.71,C2_QTY:26732,ACTUAL:15962,ACTUAL_PROG:59.71,ACTUAL_TW:2968,ACTUAL_TM:6551,DELTA:-5617,REMAIN:10770},
  {DISC_DESCR:"Total",AREA:"ME04",ITEM:"Cable Pulling",UNIT:"LM",PLAN:13291,PLAN_PROG:100,C2_QTY:13291,ACTUAL:10153,ACTUAL_PROG:76.39,ACTUAL_TW:27,ACTUAL_TM:31,DELTA:-3138,REMAIN:3138},
  {DISC_DESCR:"Total",AREA:"ME04",ITEM:"Cable Termination",UNIT:"U",PLAN:794,PLAN_PROG:100,C2_QTY:794,ACTUAL:456,ACTUAL_PROG:57.43,ACTUAL_TW:27,ACTUAL_TM:136,DELTA:-338,REMAIN:338},
  {DISC_DESCR:"Total",AREA:"ME02",ITEM:"Cable Termination",UNIT:"U",PLAN:1228,PLAN_PROG:79.12,C2_QTY:1552,ACTUAL:326,ACTUAL_PROG:21,ACTUAL_TW:81,ACTUAL_TM:115,DELTA:-902,REMAIN:1226},
  {DISC_DESCR:"Total",AREA:"ME01",ITEM:"Cable Termination",UNIT:"U",PLAN:1466,PLAN_PROG:100,C2_QTY:1466,ACTUAL:327,ACTUAL_PROG:22.3,ACTUAL_TW:26,ACTUAL_TM:86,DELTA:-1139,REMAIN:1140},
  {DISC_DESCR:"Total",AREA:"MD01-2",ITEM:"Cable Termination",UNIT:"U",PLAN:473,PLAN_PROG:26.81,C2_QTY:1764,ACTUAL:0,ACTUAL_PROG:0,ACTUAL_TW:0,DELTA:-473,REMAIN:1764},
  {DISC_DESCR:"Total",AREA:"M000",ITEM:"Cable Termination",UNIT:"U",PLAN:74,PLAN_PROG:2.45,C2_QTY:3012,ACTUAL:4,ACTUAL_PROG:0.13,ACTUAL_TW:0,ACTUAL_TM:4,DELTA:-70,REMAIN:3008},
  {DISC_DESCR:"Total",AREA:"LQ00",ITEM:"Cable Termination",UNIT:"U",PLAN:14935,PLAN_PROG:82.22,C2_QTY:18164,ACTUAL:6997,ACTUAL_PROG:38.52,ACTUAL_TW:646,ACTUAL_TM:1076,DELTA:-7939,REMAIN:11168},
  {DISC_DESCR:"Total",AREA:"LQ",ITEM:"Cable Termination",UNIT:"U",PLAN:14935,PLAN_PROG:82.22,C2_QTY:18164,ACTUAL:6997,ACTUAL_PROG:38.52,ACTUAL_TW:646,ACTUAL_TM:1076,DELTA:-7939,REMAIN:11168},
  {DISC_DESCR:"Total",AREA:"KO01",ITEM:"Cable Termination",UNIT:"U",PLAN:81,PLAN_PROG:10.65,C2_QTY:760,ACTUAL:0,ACTUAL_PROG:0,ACTUAL_TW:0,ACTUAL_TM:0,DELTA:-81,REMAIN:760},
  {DISC_DESCR:"Total",AREA:"INTEGRATION",ITEM:"Cable Termination",UNIT:"U",PLAN:1540,PLAN_PROG:22.05,C2_QTY:6984,ACTUAL:0,ACTUAL_PROG:0,ACTUAL_TW:0,ACTUAL_TM:0,DELTA:-1540,REMAIN:6984},
  {DISC_DESCR:"Total",AREA:"IG90",ITEM:"Cable Termination",UNIT:"U",PLAN:1540,PLAN_PROG:22.05,C2_QTY:6984,ACTUAL:0,ACTUAL_PROG:0,ACTUAL_TW:0,ACTUAL_TM:0,DELTA:-1540,REMAIN:6984},
  {DISC_DESCR:"Total",AREA:"HULL-FOR",ITEM:"Cable Termination",UNIT:"U",PLAN:6325,PLAN_PROG:85.31,C2_QTY:7414,ACTUAL:5226,ACTUAL_PROG:70.48,ACTUAL_TW:456,ACTUAL_TM:664,DELTA:-1100,REMAIN:2188},
  {DISC_DESCR:"Total",AREA:"HULL-COMMON",ITEM:"Cable Termination",UNIT:"U",PLAN:473,PLAN_PROG:26.81,C2_QTY:1764,ACTUAL:0,ACTUAL_PROG:0,ACTUAL_TW:0,DELTA:-473,REMAIN:1764},
  {DISC_DESCR:"Total",AREA:"HULL & LQ",ITEM:"Cable Termination",UNIT:"U",PLAN:21734,PLAN_PROG:79.48,C2_QTY:27342,ACTUAL:12222,ACTUAL_PROG:44.7,ACTUAL_TW:1102,ACTUAL_TM:1734,DELTA:-9512,REMAIN:15120},
  {DISC_DESCR:"Total",AREA:"HULL",ITEM:"Cable Termination",UNIT:"U",PLAN:6798,PLAN_PROG:74.06,C2_QTY:9178,ACTUAL:5226,ACTUAL_PROG:56.94,ACTUAL_TW:456,ACTUAL_TM:658,DELTA:-1573,REMAIN:3952},
  {DISC_DESCR:"Total",AREA:"HL03",ITEM:"Cable Termination",UNIT:"U",PLAN:6325,PLAN_PROG:85.31,C2_QTY:7414,ACTUAL:5226,ACTUAL_PROG:70.48,ACTUAL_TW:456,ACTUAL_TM:664,DELTA:-1100,REMAIN:2188},
  {DISC_DESCR:"Total",AREA:"FL01",ITEM:"Cable Termination",UNIT:"U",C2_QTY:424,ACTUAL:0,ACTUAL_PROG:0,ACTUAL_TW:0,ACTUAL_TM:0,REMAIN:424},
  {DISC_DESCR:"Total",AREA:"TOPSIDES MODULES",ITEM:"Cable Termination",UNIT:"U",PLAN:13968,PLAN_PROG:78.92,C2_QTY:17698,ACTUAL:5832,ACTUAL_PROG:32.95,ACTUAL_TW:404,ACTUAL_TM:1414,DELTA:-8136,REMAIN:11866},
  {DISC_DESCR:"Total",AREA:"TOPSIDES COMMON",ITEM:"Cable Termination",UNIT:"U",PLAN:81,PLAN_PROG:6.84,C2_QTY:1184,ACTUAL:0,ACTUAL_PROG:0,ACTUAL_TW:0,ACTUAL_TM:0,DELTA:-81,REMAIN:1184},
  {DISC_DESCR:"Total",AREA:"TOPSIDES",ITEM:"Cable Termination",UNIT:"U",PLAN:14049,PLAN_PROG:74.4,C2_QTY:18882,ACTUAL:5832,ACTUAL_PROG:30.88,ACTUAL_TW:404,ACTUAL_TM:1414,DELTA:-8217,REMAIN:13050},
  {DISC_DESCR:"Total",AREA:"MW05",ITEM:"Cable Termination",UNIT:"U",PLAN:1706,PLAN_PROG:99.88,C2_QTY:1708,ACTUAL:938,ACTUAL_PROG:54.91,DELTA:-768,REMAIN:770},
  {DISC_DESCR:"Total",AREA:"MW04",ITEM:"Cable Termination",UNIT:"U",PLAN:790,PLAN_PROG:100,C2_QTY:790,ACTUAL:466,ACTUAL_PROG:58.98,ACTUAL_TW:65,ACTUAL_TM:102,DELTA:-324,REMAIN:324},
  {DISC_DESCR:"Total",AREA:"MW02",ITEM:"Cable Termination",UNIT:"U",PLAN:1524,PLAN_PROG:78.47,C2_QTY:1942,ACTUAL:874,ACTUAL_PROG:45,ACTUAL_TM:429,DELTA:-650,REMAIN:1069},
  {DISC_DESCR:"Total",AREA:"MW01",ITEM:"Cable Termination",UNIT:"U",PLAN:1731,PLAN_PROG:97.35,C2_QTY:1778,ACTUAL:535,ACTUAL_PROG:30.08,ACTUAL_TM:170,DELTA:-1196,REMAIN:1243},
  {DISC_DESCR:"Total",AREA:"ME05",ITEM:"Cable Termination",UNIT:"U",PLAN:4656,PLAN_PROG:100,C2_QTY:4656,ACTUAL:1907,ACTUAL_PROG:40.95,ACTUAL_TW:284,ACTUAL_TM:407,DELTA:-2749,REMAIN:2749}
],
  Queries: {
    SQL1: [
      {DISC_DESCR:"Total",ITEM:"Cable Pulling",TOTAL:900346,COMPLETED:709702,REMAIN:190648},
      {DISC_DESCR:"Total",ITEM:"Cable Termination",TOTAL:37322,COMPLETED:18055,REMAIN:19269},
      {DISC_DESCR:"Electrical",ITEM:"Cable Pulling",TOTAL:456709,COMPLETED:403653,REMAIN:53057},
      {DISC_DESCR:"Electrical",ITEM:"Cable Termination",TOTAL:21022,COMPLETED:11861,REMAIN:9163},
      {DISC_DESCR:"Instrument",ITEM:"Cable Pulling",TOTAL:443637,COMPLETED:306049,REMAIN:137591},
      {DISC_DESCR:"Instrument",ITEM:"Cable Termination",TOTAL:16300,COMPLETED:6194,REMAIN:10106}
    ],
  },   
}