import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 760,
      CanvasHeight: 800,
      CanvasBorderWeight: 0.3,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 310,
      CanvasChartHeight: 270,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: 'Transparent',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },

    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },
    
    DataItems: [
      {DISC:"Piping",ACTIVITY:"Structure Prefabrication Structure Prefabrication Structure Blasting and Painting (including Support)",UNIT:"T",TOTAL_QTY:22585,TARGET_QTY:22585,ACTUAL_QTY:0,REMAIN_QTY:22585},
      {DISC:"Piping",ACTIVITY:"Structure Blasting and Painting (including Support)",UNIT:"T",TOTAL_QTY:22585,TARGET_QTY:22585,ACTUAL_QTY:0,REMAIN_QTY:22585},
      {DISC:"Piping",ACTIVITY:"Structure Blasting and Painting (including Support)",UNIT:"T",TOTAL_QTY:22585,TARGET_QTY:22585,ACTUAL_QTY:0,REMAIN_QTY:22585},
      {DISC:"Electrical",ACTIVITY:"Structure Blasting and Painting (including Support)",UNIT:"T",TOTAL_QTY:22585,TARGET_QTY:22585,ACTUAL_QTY:0,REMAIN_QTY:22585},
      {DISC:"Prior Utilities module lifting",ACTIVITY:"Structure Blasting and Painting (including Support)",UNIT:"T",TOTAL_QTY:22585,TARGET_QTY:22585,ACTUAL_QTY:0,REMAIN_QTY:22585},
      {DISC:"Bulidings Works",ACTIVITY:"Structure Blasting and Painting (including Support)",UNIT:"T",TOTAL_QTY:22585,TARGET_QTY:22585,ACTUAL_QTY:0,REMAIN_QTY:22585},
      {DISC:"Bulidings Works",ACTIVITY:"Structure Blasting and Painting (including Support)",UNIT:"T",TOTAL_QTY:22585,TARGET_QTY:22585,ACTUAL_QTY:0,REMAIN_QTY:22585},
      {DISC:"Bulidings Works",ACTIVITY:"Structure Blasting and Painting (including Support)",UNIT:"T",TOTAL_QTY:22585,TARGET_QTY:22585,ACTUAL_QTY:0,REMAIN_QTY:22585},
      {DISC:"Bulidings Works",ACTIVITY:"Structure Blasting and Painting (including Support)",UNIT:"T",TOTAL_QTY:22585,TARGET_QTY:22585,ACTUAL_QTY:0,REMAIN_QTY:22585},
      {DISC:"Bulidings Works",ACTIVITY:"Structure Blasting and Painting (including Support)",UNIT:"T",TOTAL_QTY:22585,TARGET_QTY:22585,ACTUAL_QTY:0,REMAIN_QTY:22585},
      {DISC:"Bulidings Works",ACTIVITY:"Structure Blasting and Painting (including Support)",UNIT:"T",TOTAL_QTY:22585,TARGET_QTY:22585,ACTUAL_QTY:0,REMAIN_QTY:22585},
      {DISC:"Bulidings Works",ACTIVITY:"Structure Blasting and Painting (including Support)",UNIT:"T",TOTAL_QTY:22585,TARGET_QTY:22585,ACTUAL_QTY:0,REMAIN_QTY:22585},
      {DISC:"Bulidings Works",ACTIVITY:"Structure Blasting and Painting (including Support)",UNIT:"T",TOTAL_QTY:22585,TARGET_QTY:22585,ACTUAL_QTY:0,REMAIN_QTY:22585},
      {DISC:"HVAC",ACTIVITY:"Structure Blasting and Painting (including Support)",UNIT:"T",TOTAL_QTY:22585,TARGET_QTY:22585,ACTUAL_QTY:0,REMAIN_QTY:22585},
      {DISC:"Structure",ACTIVITY:"Structure Blasting and Painting (including Support)",UNIT:"T",TOTAL_QTY:22585,TARGET_QTY:22585,ACTUAL_QTY:0,REMAIN_QTY:22585},
      {DISC:"Structure",ACTIVITY:"Structure Blasting and Painting (including Support)",UNIT:"T",TOTAL_QTY:22585,TARGET_QTY:22585,ACTUAL_QTY:0,REMAIN_QTY:22585},
      {DISC:"Structure",ACTIVITY:"Structure Blasting and Painting (including Support)",UNIT:"T",TOTAL_QTY:22585,TARGET_QTY:22585,ACTUAL_QTY:0,REMAIN_QTY:22585},
      {DISC:"Structure",ACTIVITY:"Structure Blasting and Painting (including Support)",UNIT:"T",TOTAL_QTY:22585,TARGET_QTY:22585,ACTUAL_QTY:0,REMAIN_QTY:22585},
      {DISC:"Structure",ACTIVITY:"Structure Blasting and Painting (including Support)",UNIT:"T",TOTAL_QTY:22585,TARGET_QTY:22585,ACTUAL_QTY:0,REMAIN_QTY:22585},
      {DISC:"Prior LQ Lifting",ACTIVITY:"Structure Blasting and Painting (including Support)",UNIT:"T",TOTAL_QTY:22585,TARGET_QTY:22585,ACTUAL_QTY:0,REMAIN_QTY:22585},
      {DISC:"Structure",ACTIVITY:"Structure Blasting and Painting (including Support)",UNIT:"T",TOTAL_QTY:22585,TARGET_QTY:22585,ACTUAL_QTY:0,REMAIN_QTY:22585},
      {DISC:"Structure",ACTIVITY:"Structure Blasting and Painting (including Support)",UNIT:"T",TOTAL_QTY:22585,TARGET_QTY:22585,ACTUAL_QTY:0,REMAIN_QTY:22585},
      {DISC:"Structure",ACTIVITY:"Structure Blasting and Painting (including Support)",UNIT:"T",TOTAL_QTY:22585,TARGET_QTY:22585,ACTUAL_QTY:0,REMAIN_QTY:22585},
      {DISC:"Structure",ACTIVITY:"Structure Blasting and Painting (including Support)",UNIT:"T",TOTAL_QTY:22585,TARGET_QTY:22585,ACTUAL_QTY:0,REMAIN_QTY:22585},
      {DISC:"Structure",ACTIVITY:"Structure Blasting and Painting (including Support) ",UNIT:"T",TOTAL_QTY:22585,TARGET_QTY:22585,ACTUAL_QTY:0,REMAIN_QTY:22585},
      {DISC:"Structure",ACTIVITY:"Structure Blasting and Painting (including Support) ",UNIT:"T",TOTAL_QTY:22585,TARGET_QTY:22585,ACTUAL_QTY:0,REMAIN_QTY:22585},
      {DISC:"Quayside Work（before mating)",ACTIVITY:"Structure Blasting and Painting (including Support) ",UNIT:"T",TOTAL_QTY:22585,TARGET_QTY:22585,ACTUAL_QTY:0,REMAIN_QTY:22585},
      {DISC:"Coating",ACTIVITY:"Structure Blasting and Painting (including Support) Structure Prefabrication",UNIT:"T",TOTAL_QTY:22585,TARGET_QTY:22585,ACTUAL_QTY:0,REMAIN_QTY:22585},

    ],
}