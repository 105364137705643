import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 450,
      CanvasHeight: 120,
      CanvasBorderWeight: 1,
      CanvasBorderColor: '#ccc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 350,
      CanvasChartHeight: 530,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: 'Transparent',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },

    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },

    DataItems: [
      {DISC:"O",ACTUAL:985},
      {DISC:"W",ACTUAL:688},
      {DISC:"H",ACTUAL:6274},
      {DISC:"E",ACTUAL:188},
      {DISC:"A",ACTUAL:79},
      {DISC:"P",ACTUAL:1135},
      {DISC:"L",ACTUAL:0},
      {DISC:"C",ACTUAL:822},
      {DISC:"M",ACTUAL:13},
      {DISC:"V",ACTUAL:64}
    ],
}