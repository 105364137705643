import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1540,
      CanvasHeight: 440,
      CanvasBorderWeight: 1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 300,
      CanvasChartHeight: 300,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#fff',
      CanvasChartFillColor: '#fff',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },


    DataItems: [
      { PHASE: 'ENG', TOTAL: 99.9, WEEK: 1.0},
      { PHASE: 'PUR', TOTAL: 52.4, WEEK: -42.2},
      { PHASE: 'FAB', TOTAL: 89.6, WEEK: 2.30},
      { PHASE: 'A1', TOTAL: 63.1, WEEK: 2.4},
      { PHASE: 'B1', TOTAL: 53.4, WEEK: 2.5},
    ]
}