import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 960,
      CanvasHeight: 790,
      CanvasBorderWeight: 0.3,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 310,
      CanvasChartHeight: 270,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: 'Transparent',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },

    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },

    DataItems: [
      {DISC_DESCR:"Structure",TARGET_PROG:100,PLAN_PROG:96.86,ACTUAL_PROG:90.16,ACTUAL_CERT_PROG:58.13,DELTA:-6.69,DISC:"36",GWBS_LV3:"MW05"},
      {DISC_DESCR:"Piping",TARGET_PROG:86.58,PLAN_PROG:82.07,ACTUAL_PROG:51.04,ACTUAL_CERT_PROG:32.54,DELTA:-31.03,DISC:"13",GWBS_LV3:"MW05"},
      {DISC_DESCR:"Electrical",TARGET_PROG:100,PLAN_PROG:61.95,ACTUAL_PROG:16.87,ACTUAL_CERT_PROG:0,DELTA:-45.08,DISC:"16",GWBS_LV3:"MW05"},
      {DISC_DESCR:"Instrument",TARGET_PROG:94.11,PLAN_PROG:24.53,ACTUAL_PROG:0,ACTUAL_CERT_PROG:0,DELTA:-24.53,DISC:"15",GWBS_LV3:"MW05"},
      {DISC_DESCR:"Mechanical",TARGET_PROG:100,PLAN_PROG:99.62,ACTUAL_PROG:86.07,ACTUAL_CERT_PROG:19.78,DELTA:-13.54,DISC:"68",GWBS_LV3:"MW05"},
      {DISC_DESCR:"Painting",TARGET_PROG:96.51,PLAN_PROG:92.65,ACTUAL_PROG:64.98,ACTUAL_CERT_PROG:28.69,DELTA:-27.66,DISC:"23",GWBS_LV3:"MW05"},
      {DISC_DESCR:"Insulation",TARGET_PROG:46.75,PLAN_PROG:0.35,ACTUAL_PROG:0,ACTUAL_CERT_PROG:0,DELTA:-0.35,DISC:"22",GWBS_LV3:"MW05"},
      {DISC_DESCR:"Fire & Safety",TARGET_PROG:8.2,PLAN_PROG:3.17,ACTUAL_PROG:0,ACTUAL_CERT_PROG:0,DELTA:-3.17,DISC:"61",GWBS_LV3:"MW05"}
      ],
    Queries: {
      SQL1: [
        {COMMODITY:"Equipment",PLAN_PROG:11.11,ACTUAL_CUM_PROG:0,ACTUAL_CERT_PROG:0,DELTA:0.11,DISC:"22"},
        {COMMODITY:"Fire Protection System",PLAN_PROG:7.73,ACTUAL_CUM_PROG:0,ACTUAL_CERT_PROG:0,DELTA:0.07,DISC:"61"},
        {COMMODITY:"Prefabrication",PLAN_PROG:100,ACTUAL_CUM_PROG:98.15,ACTUAL_CERT_PROG:94.76,DELTA:0.01,DISC:"36"},
        {COMMODITY:"Steel Structure",PLAN_PROG:88.49,ACTUAL_CUM_PROG:81.07,ACTUAL_CERT_PROG:44.18,DELTA:0.07,DISC:"23"},
        {COMMODITY:"Support",PLAN_PROG:100,ACTUAL_CUM_PROG:65.33,ACTUAL_CERT_PROG:0,DELTA:0.34,DISC:"13"},
        {COMMODITY:"Support",PLAN_PROG:98.01,ACTUAL_CUM_PROG:59.46,ACTUAL_CERT_PROG:0,DELTA:0.38,DISC:"16"},
        {COMMODITY:"Vessels & Exchangers",PLAN_PROG:100,ACTUAL_CUM_PROG:98.32,ACTUAL_CERT_PROG:26.7,DELTA:0.01,DISC:"68"},
        {COMMODITY:"Assembly&Erection",PLAN_PROG:96.2,ACTUAL_CUM_PROG:92.54,ACTUAL_CERT_PROG:43.21,DELTA:0.03,DISC:"36"},
        {COMMODITY:"Cable Pulling",PLAN_PROG:38.1,ACTUAL_CUM_PROG:0,ACTUAL_CERT_PROG:0,DELTA:0.38,DISC:"15"},
        {COMMODITY:"Cable Tray",PLAN_PROG:78.78,ACTUAL_CUM_PROG:0,ACTUAL_CERT_PROG:0,DELTA:0.78,DISC:"16"},
        {COMMODITY:"Machinery Erection - Rotating",PLAN_PROG:100,ACTUAL_CUM_PROG:99,ACTUAL_CERT_PROG:0,DELTA:0.01,DISC:"68"},
        {COMMODITY:"Piping",PLAN_PROG:0,ACTUAL_CUM_PROG:0,ACTUAL_CERT_PROG:0,DELTA:0,DISC:"22"},
        {COMMODITY:"Piping",PLAN_PROG:99.32,ACTUAL_CUM_PROG:39.18,ACTUAL_CERT_PROG:3.87,DELTA:0.6,DISC:"23"},
        {COMMODITY:"Safety Equipment",PLAN_PROG:0,ACTUAL_CUM_PROG:0,ACTUAL_CERT_PROG:0,DELTA:0,DISC:"61"},
        {COMMODITY:"Spool",PLAN_PROG:86.09,ACTUAL_CUM_PROG:53.79,ACTUAL_CERT_PROG:38.13,DELTA:0.32,DISC:"13"},
        {COMMODITY:"Cable Pulling",PLAN_PROG:48.89,ACTUAL_CUM_PROG:0,ACTUAL_CERT_PROG:0,DELTA:0.48,DISC:"16"},
        {COMMODITY:"Cable Termination",PLAN_PROG:16.14,ACTUAL_CUM_PROG:0,ACTUAL_CERT_PROG:0,DELTA:0.16,DISC:"15"},
        {COMMODITY:"Pressure Test",PLAN_PROG:10.81,ACTUAL_CUM_PROG:0,ACTUAL_CERT_PROG:0,DELTA:0.1,DISC:"13"},
        {COMMODITY:"Skid, Packages & Misc.",PLAN_PROG:100,ACTUAL_CUM_PROG:85.15,ACTUAL_CERT_PROG:36.6,DELTA:0.14,DISC:"68"},
        {COMMODITY:"Tertiary",PLAN_PROG:86.71,ACTUAL_CUM_PROG:30.8,ACTUAL_CERT_PROG:0,DELTA:0.55,DISC:"36"},
        {COMMODITY:"Cable Termination",PLAN_PROG:14.55,ACTUAL_CUM_PROG:0,ACTUAL_CERT_PROG:0,DELTA:0.14,DISC:"16"},
        {COMMODITY:"Field Instrumentation",PLAN_PROG:8.97,ACTUAL_CUM_PROG:0,ACTUAL_CERT_PROG:0,DELTA:0.08,DISC:"15"}
        ],
      SQL2:  [
        {WC_DESCRIPTION:"ANALYZERS INSTALLATION",UOM:"U",INITIALQTY:12,TARGET_QTY:12,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,ACTUAL_CERT_PROG:0,REMAIN_QTY:12,REMAIN_PROG:-100,DISC:"15",WCCODE:"15600",GWBS_LV3:"MW05"},
        {WC_DESCRIPTION:"ELECTRICAL COMPONENTS (PANELS, JUNCTION BOXES)",UOM:"U",INITIALQTY:358,TARGET_QTY:358,TARGET_PROG:100,ACTUAL_CUM_QTY:136.1,ACTUAL_CUM_PROG:38.02,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,ACTUAL_CERT_PROG:0,REMAIN_QTY:221.9,REMAIN_PROG:-61.98,DISC:"16",WCCODE:"16340",GWBS_LV3:"MW05"},
        {WC_DESCRIPTION:"GROUNDING AND LIGHTNING SYSTEM",UOM:"U",INITIALQTY:5590,TARGET_QTY:5590,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,ACTUAL_CERT_PROG:0,REMAIN_QTY:5590,REMAIN_PROG:-100,DISC:"16",WCCODE:"16100",GWBS_LV3:"MW05"},
        {WC_DESCRIPTION:"HEAVY LIFTS IN ONE PIECE",UOM:"U",INITIALQTY:3,TARGET_QTY:3,TARGET_PROG:100,ACTUAL_CUM_QTY:2,ACTUAL_CUM_PROG:66.67,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,ACTUAL_CERT_PROG:0,REMAIN_QTY:1,REMAIN_PROG:-33.33,DISC:"68",WCCODE:"68110",GWBS_LV3:"MW05"},
        {WC_DESCRIPTION:"INSTRUMENT COMPONENTS (PANELS, JUNCTION BOXES)",UOM:"U",INITIALQTY:23,TARGET_QTY:23,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,ACTUAL_CERT_PROG:0,REMAIN_QTY:23,REMAIN_PROG:-100,DISC:"15",WCCODE:"15240",GWBS_LV3:"MW05"},
        {WC_DESCRIPTION:"LIGHTING SYSTEM",UOM:"U",INITIALQTY:6665,TARGET_QTY:6665,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,ACTUAL_CERT_PROG:0,REMAIN_QTY:6665,REMAIN_PROG:-100,DISC:"16",WCCODE:"16800",GWBS_LV3:"MW05"},
        {WC_DESCRIPTION:"TELECOMMUNICATION EQUIPMENT INSTALLATION",UOM:"U",INITIALQTY:93,TARGET_QTY:93,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,ACTUAL_CERT_PROG:0,REMAIN_QTY:93,REMAIN_PROG:-100,DISC:"15",WCCODE:"15700",GWBS_LV3:"MW05"},
        {WC_DESCRIPTION:"TRAYS & INTERNALS INSTALLATION",UOM:"U",INITIALQTY:3,TARGET_QTY:3,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,ACTUAL_CERT_PROG:0,REMAIN_QTY:3,REMAIN_PROG:-100,DISC:"68",WCCODE:"68610",GWBS_LV3:"MW05"},
        {WC_DESCRIPTION:"AREA PAINTING",UOM:"SQM",INITIALQTY:586.1,TARGET_QTY:419.4,TARGET_PROG:71.56,ACTUAL_CUM_QTY:64.5,ACTUAL_CUM_PROG:11,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,ACTUAL_CERT_PROG:0,REMAIN_QTY:354.9,REMAIN_PROG:-60.56,DISC:"23",COMMODITY:"Steel Structure",WCCODE:"23120",GWBS_LV3:"MW05"},
        {WC_DESCRIPTION:"EICT AREA OUTFITTINGS FABRICATION - SUPPORT",UOM:"KG",INITIALQTY:23601,TARGET_QTY:23601,TARGET_PROG:100,ACTUAL_CUM_QTY:20816.1,ACTUAL_CUM_PROG:88.19,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,ACTUAL_CERT_PROG:0,REMAIN_QTY:2784.9,REMAIN_PROG:-11.81,DISC:"16",COMMODITY:"Support",WCCODE:"16450",GWBS_LV3:"MW05"},
        {WC_DESCRIPTION:"EICT AREA OUTFITTINGS INSTALLATION - SUPPORT",UOM:"KG",INITIALQTY:23597,TARGET_QTY:23597,TARGET_PROG:100,ACTUAL_CUM_QTY:10333.1,ACTUAL_CUM_PROG:43.78,ACTUAL_WEEK_QTY:828.3,ACTUAL_WEEK_PROG:3.5,ACTUAL_CERT_PROG:0,REMAIN_QTY:13263.9,REMAIN_PROG:-56.22,DISC:"16",COMMODITY:"Support",WCCODE:"16460",GWBS_LV3:"MW05"},
        {WC_DESCRIPTION:"EQUIPMENT INSULATION WORKS",UOM:"SQM",INITIALQTY:65,TARGET_QTY:65,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,ACTUAL_CERT_PROG:0,REMAIN_QTY:65,REMAIN_PROG:-100,DISC:"22",COMMODITY:"Equipment",WCCODE:"22110",GWBS_LV3:"MW05"},
        {WC_DESCRIPTION:"FIRE PROTECTION SYSTEM",UOM:"U",INITIALQTY:22.88,TARGET_QTY:4.6,TARGET_PROG:20,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,ACTUAL_CERT_PROG:0,REMAIN_QTY:4.6,REMAIN_PROG:-20,DISC:"61",COMMODITY:"Fire Protection System",WCCODE:"61100",GWBS_LV3:"MW05"},
        {WC_DESCRIPTION:"STEEL STRUCTURE PAINTING",UOM:"SQM",INITIALQTY:17607.4,TARGET_QTY:17607.4,TARGET_PROG:100,ACTUAL_CUM_QTY:17343.3,ACTUAL_CUM_PROG:98.5,ACTUAL_WEEK_QTY:697.3,ACTUAL_WEEK_PROG:3.96,ACTUAL_CERT_PROG:55.17,REMAIN_QTY:264.1,REMAIN_PROG:-1.5,DISC:"23",COMMODITY:"Steel Structure",WCCODE:"23110",GWBS_LV3:"MW05"},
        {WC_DESCRIPTION:"TOPSIDES PIPING SUPPORT FABRICATION",UOM:"KG",INITIALQTY:27968,TARGET_QTY:27968,TARGET_PROG:100,ACTUAL_CUM_QTY:25512.4,ACTUAL_CUM_PROG:91.21,ACTUAL_WEEK_QTY:1591.4,ACTUAL_WEEK_PROG:5.68,ACTUAL_CERT_PROG:0,REMAIN_QTY:2455.6,REMAIN_PROG:-8.79,DISC:"13",COMMODITY:"Support",WCCODE:"13810",GWBS_LV3:"MW05"},
        {WC_DESCRIPTION:"TOPSIDES PIPING SUPPORT INSTALLATION",UOM:"KG",INITIALQTY:27968,TARGET_QTY:27968,TARGET_PROG:100,ACTUAL_CUM_QTY:10001.4,ACTUAL_CUM_PROG:35.75,ACTUAL_WEEK_QTY:170.6,ACTUAL_WEEK_PROG:0.6,ACTUAL_CERT_PROG:0,REMAIN_QTY:17966.6,REMAIN_PROG:-64.25,DISC:"13",COMMODITY:"Support",WCCODE:"13820",GWBS_LV3:"MW05"},
        {WC_DESCRIPTION:"TOPSIDES PREFABRICATION PRIMARY SECONDARY",UOM:"TONS",INITIALQTY:847.7,TARGET_QTY:847.7,TARGET_PROG:100,ACTUAL_CUM_QTY:832,ACTUAL_CUM_PROG:98.15,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,ACTUAL_CERT_PROG:94.76,REMAIN_QTY:15.7,REMAIN_PROG:-1.85,DISC:"36",COMMODITY:"Prefabrication",WCCODE:"36210",GWBS_LV3:"MW05"},
        {WC_DESCRIPTION:"VERTICAL AND HORIZONTAL VESSELS/EXCHANGERS",UOM:"U",INITIALQTY:10,TARGET_QTY:10,TARGET_PROG:100,ACTUAL_CUM_QTY:9.8,ACTUAL_CUM_PROG:98.32,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,ACTUAL_CERT_PROG:26.7,REMAIN_QTY:0.2,REMAIN_PROG:-1.68,DISC:"68",COMMODITY:"Vessels & Exchangers",WCCODE:"68210",GWBS_LV3:"MW05"},
        {WC_DESCRIPTION:"EICT AREA OUTFITTINGS INSTALLATION - CABLE TRAY",UOM:"LM",INITIALQTY:3637,TARGET_QTY:3637,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,ACTUAL_CERT_PROG:0,REMAIN_QTY:3637,REMAIN_PROG:-100,DISC:"16",COMMODITY:"Cable Tray",WCCODE:"16430",GWBS_LV3:"MW05"},
        {WC_DESCRIPTION:"INDOOR - INSTRUMENT CABLES & MULTIINSTRUMENT CABLES",UOM:"LM",INITIALQTY:11515,TARGET_QTY:11515,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,ACTUAL_CERT_PROG:0,REMAIN_QTY:11515,REMAIN_PROG:-100,DISC:"15",COMMODITY:"Cable Pulling",WCCODE:"15120",GWBS_LV3:"MW05"},
        {WC_DESCRIPTION:"MACHINERY ERECTION - ROTATING",UOM:"U",INITIALQTY:5,TARGET_QTY:5,TARGET_PROG:100,ACTUAL_CUM_QTY:5,ACTUAL_CUM_PROG:99,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,ACTUAL_CERT_PROG:0,REMAIN_QTY:0.1,REMAIN_PROG:-1,DISC:"68",COMMODITY:"Machinery Erection - Rotating",WCCODE:"68500",GWBS_LV3:"MW05"},
        {WC_DESCRIPTION:"PIPING INSULATION WORKS",UOM:"SQM",INITIALQTY:1791.24,TARGET_QTY:806.4,TARGET_PROG:45.01,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,ACTUAL_CERT_PROG:0,REMAIN_QTY:806.4,REMAIN_PROG:-45.01,DISC:"22",COMMODITY:"Piping",WCCODE:"22210",GWBS_LV3:"MW05"},
        {WC_DESCRIPTION:"PIPING PAINTING",UOM:"SQM",INITIALQTY:3808.7,TARGET_QTY:3808.7,TARGET_PROG:100,ACTUAL_CUM_QTY:1066.8,ACTUAL_CUM_PROG:28,ACTUAL_WEEK_QTY:115.4,ACTUAL_WEEK_PROG:3.02,ACTUAL_CERT_PROG:5.6,REMAIN_QTY:2741.9,REMAIN_PROG:-72,DISC:"23",COMMODITY:"Piping",WCCODE:"23200",GWBS_LV3:"MW05"},
        {WC_DESCRIPTION:"PIPING SUPPORT PAINTING",UOM:"SQM",INITIALQTY:1700.2,TARGET_QTY:1700.2,TARGET_PROG:100,ACTUAL_CUM_QTY:1092,ACTUAL_CUM_PROG:64.23,ACTUAL_WEEK_QTY:79.7,ACTUAL_WEEK_PROG:4.68,ACTUAL_CERT_PROG:0,REMAIN_QTY:608.2,REMAIN_PROG:-35.77,DISC:"23",COMMODITY:"Piping",WCCODE:"23210",GWBS_LV3:"MW05"},
        {WC_DESCRIPTION:"SAFETY EQUIPMENT",UOM:"U",INITIALQTY:121,TARGET_QTY:0,TARGET_PROG:0,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,ACTUAL_CERT_PROG:0,REMAIN_QTY:0,REMAIN_PROG:0,DISC:"61",COMMODITY:"Safety Equipment",WCCODE:"61200",GWBS_LV3:"MW05"},
        {WC_DESCRIPTION:"TOPSIDES ASSEMBLY/ERECTION PRIMARY SECONDARY",UOM:"TONS",INITIALQTY:858.92,TARGET_QTY:858.9,TARGET_PROG:100,ACTUAL_CUM_QTY:794.9,ACTUAL_CUM_PROG:92.54,ACTUAL_WEEK_QTY:23.4,ACTUAL_WEEK_PROG:2.72,ACTUAL_CERT_PROG:43.21,REMAIN_QTY:64,REMAIN_PROG:-7.46,DISC:"36",COMMODITY:"Assembly&Erection",WCCODE:"36220",GWBS_LV3:"MW05"},
        {WC_DESCRIPTION:"TOPSIDES PIPING FABRICATION",UOM:"DIA",INITIALQTY:19695,TARGET_QTY:19695,TARGET_PROG:100,ACTUAL_CUM_QTY:15236.1,ACTUAL_CUM_PROG:77.36,ACTUAL_WEEK_QTY:553.4,ACTUAL_WEEK_PROG:2.81,ACTUAL_CERT_PROG:61.99,REMAIN_QTY:4458.9,REMAIN_PROG:-22.64,DISC:"13",COMMODITY:"Spool",WCCODE:"13410",GWBS_LV3:"MW05"},
        {WC_DESCRIPTION:"TOPSIDES PIPING INSTALLATION",UOM:"DIA",INITIALQTY:5411,TARGET_QTY:4088.6,TARGET_PROG:75.56,ACTUAL_CUM_QTY:871.7,ACTUAL_CUM_PROG:16.1,ACTUAL_WEEK_QTY:273.8,ACTUAL_WEEK_PROG:5.05,ACTUAL_CERT_PROG:0,REMAIN_QTY:3216.8,REMAIN_PROG:-59.46,DISC:"13",COMMODITY:"Spool",WCCODE:"13420",GWBS_LV3:"MW05"},
        {WC_DESCRIPTION:"INDOOR - INSTRUMENT CABLES CONNECTIONS",UOM:"U",INITIALQTY:9398,TARGET_QTY:9398,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,ACTUAL_CERT_PROG:0,REMAIN_QTY:9398,REMAIN_PROG:-100,DISC:"15",COMMODITY:"Cable Termination",WCCODE:"15130",GWBS_LV3:"MW05"},
        {WC_DESCRIPTION:"INDOOR- ELECTRICAL CABLES",UOM:"LM",INITIALQTY:179,TARGET_QTY:179,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,ACTUAL_CERT_PROG:0,REMAIN_QTY:179,REMAIN_PROG:-100,DISC:"16",COMMODITY:"Cable Pulling",WCCODE:"16250",GWBS_LV3:"MW05"}
        ]
    }
}