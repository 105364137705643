import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 960,
      CanvasHeight: 450,
      CanvasBorderWeight: 0.3,
      CanvasBorderColor: 'Transparent',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 310,
      CanvasChartHeight: 270,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: 'Transparent',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },

    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },

    DataItems: [
      {COMMODITY:"Support",PLAN_PROG:97.52,ACTUAL_CUM_PROG:74.8,ACTUAL_CERT_PROG:23.48,DELTA:-22.72,DISC:"13"},
      {COMMODITY:"Spool",PLAN_PROG:76.79,ACTUAL_CUM_PROG:45.29,ACTUAL_CERT_PROG:24.33,DELTA:-31.5,DISC:"13"},
      {COMMODITY:"Pressure Test",PLAN_PROG:23.13,ACTUAL_CUM_PROG:1.78,ACTUAL_CERT_PROG:1.2,DELTA:-21.34,DISC:"13"}
    ],

    Queries: {
      SQL1: [
        {WC_DESCRIPTION:"BLOCK PIPING SUPPORT FABRICATION",UOM:"KG",INITIALQTY:213367.59,TARGET_QTY:134376.5,TARGET_PROG:77.76,ACTUAL_CUM_QTY:208137.8,ACTUAL_CUM_PROG:96.81,ACTUAL_CERT_PROG:42.6,ACTUAL_WEEK_QTY:5474.8,ACTUAL_WEEK_PROG:3.34,REMAIN_QTY:-73761.3,REMAIN_PROG:19.05,DISC:"13",COMMODITY:"Support"},
        {WC_DESCRIPTION:"BLOCK PIPING SUPPORT INSTALLATION",UOM:"KG",INITIALQTY:213367.59,TARGET_QTY:132712,TARGET_PROG:77.34,ACTUAL_CUM_QTY:181764.7,ACTUAL_CUM_PROG:89.53,ACTUAL_CERT_PROG:40.65,ACTUAL_WEEK_QTY:7900.4,ACTUAL_WEEK_PROG:4.05,REMAIN_QTY:-49052.8,REMAIN_PROG:12.19,DISC:"13",COMMODITY:"Support"},
        {WC_DESCRIPTION:"TOPSIDES PIPING SUPPORT FABRICATION",UOM:"KG",INITIALQTY:380184,TARGET_QTY:336455,TARGET_PROG:87.78,ACTUAL_CUM_QTY:301883.2,ACTUAL_CUM_PROG:77.34,ACTUAL_CERT_PROG:0,ACTUAL_WEEK_QTY:16781.4,ACTUAL_WEEK_PROG:3.84,REMAIN_QTY:71786.5,REMAIN_PROG:-10.44,DISC:"13",COMMODITY:"Support"},
        {WC_DESCRIPTION:"TOPSIDES PIPING SUPPORT INSTALLATION",UOM:"KG",INITIALQTY:394184,TARGET_QTY:330249.1,TARGET_PROG:83.65,ACTUAL_CUM_QTY:81770.8,ACTUAL_CUM_PROG:22.59,ACTUAL_CERT_PROG:0,ACTUAL_WEEK_QTY:3991,ACTUAL_WEEK_PROG:1.33,REMAIN_QTY:248647.9,REMAIN_PROG:-61.06,DISC:"13",COMMODITY:"Support"},
        {WC_DESCRIPTION:"AREA PIPING FABRICATION",UOM:"DIA",INITIALQTY:146812.75,TARGET_QTY:77089.2,TARGET_PROG:54.22,ACTUAL_CUM_QTY:135219.6,ACTUAL_CUM_PROG:91.37,ACTUAL_CERT_PROG:73.82,ACTUAL_WEEK_QTY:2557,ACTUAL_WEEK_PROG:1.64,REMAIN_QTY:-58130.2,REMAIN_PROG:37.15,DISC:"13",COMMODITY:"Spool"},
        {WC_DESCRIPTION:"AREA PIPING INSTALLATION",UOM:"DIA",INITIALQTY:64834.75,TARGET_QTY:18805.7,TARGET_PROG:34.17,ACTUAL_CUM_QTY:28255.8,ACTUAL_CUM_PROG:47.13,ACTUAL_CERT_PROG:0,ACTUAL_WEEK_QTY:228.3,ACTUAL_WEEK_PROG:0.35,REMAIN_QTY:-9450.199999999999,REMAIN_PROG:12.96,DISC:"13",COMMODITY:"Spool"},
        {WC_DESCRIPTION:"TOPSIDES PIPING FABRICATION",UOM:"DIA",INITIALQTY:206815,TARGET_QTY:170824.4,TARGET_PROG:80.81,ACTUAL_CUM_QTY:129777.5,ACTUAL_CUM_PROG:61.64,ACTUAL_CERT_PROG:46.14,ACTUAL_WEEK_QTY:5967.8,ACTUAL_WEEK_PROG:2.85,REMAIN_QTY:59738.3,REMAIN_PROG:-19.17,DISC:"13",COMMODITY:"Spool"},
        {WC_DESCRIPTION:"TOPSIDES PIPING INSTALLATION",UOM:"DIA",INITIALQTY:67781.25,TARGET_QTY:39481.3,TARGET_PROG:54.93,ACTUAL_CUM_QTY:5550.4,ACTUAL_CUM_PROG:7.86,ACTUAL_CERT_PROG:0,ACTUAL_WEEK_QTY:964.7,ACTUAL_WEEK_PROG:1.26,REMAIN_QTY:33930.7,REMAIN_PROG:-47.07,DISC:"13",COMMODITY:"Spool"},
        {WC_DESCRIPTION:"PRESSURE TEST",UOM:"U",INITIALQTY:11302.2,TARGET_QTY:2048.9,TARGET_PROG:17.52,ACTUAL_CUM_QTY:111.6,ACTUAL_CUM_PROG:1.78,ACTUAL_CERT_PROG:1.2,ACTUAL_WEEK_QTY:11.6,ACTUAL_WEEK_PROG:0.16,REMAIN_QTY:1937.3,REMAIN_PROG:-15.74,DISC:"13",COMMODITY:"Pressure Test"}
      ],
      SQL2: [
        {DISC:"13",PLAN_PROG:76.24,ACTUAL_CUM_PROG:46.7,ACTUAL_CERT_PROG:22.66,DELTA:-29.54}
      ]
    }
}