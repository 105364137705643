import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1160,
      CanvasHeight: 420,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 380,
      CanvasChartHeight: 280,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Weight : ', x:240, y: -10, font: 'roboto', class: 'regular', size: 11, color: '#BCBCBC', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: 350, y: -10, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'end' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: 350,
          y: -10,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'end'
        },
      ]
    },
    Queries: {
      SQL1:  [
        {PACKAGE:"P2",WK_NO:"2024-06-05",DESC:"MC"},

      ],
    },
    DataItems: [
      {PACKAGE:"P1",WK_NO:"2022-03-04",PILING_PLAN_CUM:0,PILING_ACT_CUM:0,UG_PLAN_CUM:0,UG_ACT_CUM:0},
      {PACKAGE:"P1",WK_NO:"2022-03-11",PILING_PLAN_CUM:0,PILING_ACT_CUM:0,UG_PLAN_CUM:0,UG_ACT_CUM:0},
      {PACKAGE:"P1",WK_NO:"2022-03-18",PILING_PLAN_CUM:0,PILING_ACT_CUM:0,UG_PLAN_CUM:0,UG_ACT_CUM:0},
      {PACKAGE:"P1",WK_NO:"2022-03-25",PILING_PLAN_CUM:0,PILING_ACT_CUM:0,UG_PLAN_CUM:0,UG_ACT_CUM:0},
      {PACKAGE:"P1",WK_NO:"2022-04-01",PILING_PLAN_CUM:0,PILING_ACT_CUM:21.07,UG_PLAN_CUM:0,UG_ACT_CUM:0},
      {PACKAGE:"P1",WK_NO:"2022-04-08",PILING_PLAN_CUM:3.4,PILING_ACT_CUM:22.68,UG_PLAN_CUM:0,UG_ACT_CUM:0},
      {PACKAGE:"P1",WK_NO:"2022-04-15",PILING_PLAN_CUM:7.35,PILING_ACT_CUM:27.15,UG_PLAN_CUM:0,UG_ACT_CUM:0},
      {PACKAGE:"P1",WK_NO:"2022-04-22",PILING_PLAN_CUM:12.68,PILING_ACT_CUM:30.39,UG_PLAN_CUM:0,UG_ACT_CUM:0},
      {PACKAGE:"P1",WK_NO:"2022-04-29",PILING_PLAN_CUM:18.01,PILING_ACT_CUM:35.45,UG_PLAN_CUM:0,UG_ACT_CUM:0},
      {PACKAGE:"P1",WK_NO:"2022-05-06",PILING_PLAN_CUM:23.62,PILING_ACT_CUM:40.97,UG_PLAN_CUM:0,UG_ACT_CUM:0},
      {PACKAGE:"P1",WK_NO:"2022-05-13",PILING_PLAN_CUM:29.26,PILING_ACT_CUM:48.52,UG_PLAN_CUM:0,UG_ACT_CUM:0},
      {PACKAGE:"P1",WK_NO:"2022-05-20",PILING_PLAN_CUM:34.93,PILING_ACT_CUM:51.05,UG_PLAN_CUM:0,UG_ACT_CUM:0},
      {PACKAGE:"P1",WK_NO:"2022-05-27",PILING_PLAN_CUM:40.58,PILING_ACT_CUM:56.19,UG_PLAN_CUM:0,UG_ACT_CUM:0},
      {PACKAGE:"P1",WK_NO:"2022-06-03",PILING_PLAN_CUM:46.22,PILING_ACT_CUM:66.7,UG_PLAN_CUM:0,UG_ACT_CUM:0},
      {PACKAGE:"P1",WK_NO:"2022-06-10",PILING_PLAN_CUM:51.89,PILING_ACT_CUM:69,UG_PLAN_CUM:0,UG_ACT_CUM:0},
      {PACKAGE:"P1",WK_NO:"2022-06-17",PILING_PLAN_CUM:57.54,PILING_ACT_CUM:74.76,UG_PLAN_CUM:0,UG_ACT_CUM:0},
      {PACKAGE:"P1",WK_NO:"2022-06-24",PILING_PLAN_CUM:63.2,PILING_ACT_CUM:77.97,UG_PLAN_CUM:0,UG_ACT_CUM:0},
      {PACKAGE:"P1",WK_NO:"2022-07-01",PILING_PLAN_CUM:68.85,PILING_ACT_CUM:81.97,UG_PLAN_CUM:0,UG_ACT_CUM:0},
      {PACKAGE:"P1",WK_NO:"2022-07-08",PILING_PLAN_CUM:74.51,PILING_ACT_CUM:84.24,UG_PLAN_CUM:0,UG_ACT_CUM:0},
      {PACKAGE:"P1",WK_NO:"2022-07-15",PILING_PLAN_CUM:80.16,PILING_ACT_CUM:88.2,UG_PLAN_CUM:0,UG_ACT_CUM:0},
      {PACKAGE:"P1",WK_NO:"2022-07-22",PILING_PLAN_CUM:85.83,PILING_ACT_CUM:94.26,UG_PLAN_CUM:0,UG_ACT_CUM:0},
      {PACKAGE:"P1",WK_NO:"2022-07-29",PILING_PLAN_CUM:91.47,PILING_ACT_CUM:94.76,UG_PLAN_CUM:0,UG_ACT_CUM:0},
      {PACKAGE:"P1",WK_NO:"2022-08-05",PILING_PLAN_CUM:94.69,PILING_ACT_CUM:94.76,UG_PLAN_CUM:0,UG_ACT_CUM:0},
      {PACKAGE:"P1",WK_NO:"2022-08-12",PILING_PLAN_CUM:96.67,PILING_ACT_CUM:96.56,UG_PLAN_CUM:0,UG_ACT_CUM:0},
      {PACKAGE:"P1",WK_NO:"2022-08-19",PILING_PLAN_CUM:97.01,PILING_ACT_CUM:98,UG_PLAN_CUM:0.03,UG_ACT_CUM:1.5},
      {PACKAGE:"P1",WK_NO:"2022-08-26",PILING_PLAN_CUM:97.34,PILING_ACT_CUM:98.84,UG_PLAN_CUM:1.4,UG_ACT_CUM:2.73},
      {PACKAGE:"P1",WK_NO:"2022-09-02",PILING_PLAN_CUM:97.66,PILING_ACT_CUM:99.48,UG_PLAN_CUM:2.76,UG_ACT_CUM:5.48},
      {PACKAGE:"P1",WK_NO:"2022-09-09",PILING_PLAN_CUM:98,PILING_ACT_CUM:100,UG_PLAN_CUM:7.02,UG_ACT_CUM:9.74},
      {PACKAGE:"P1",WK_NO:"2022-09-16",PILING_PLAN_CUM:98.34,PILING_ACT_CUM:100,UG_PLAN_CUM:11.27,UG_ACT_CUM:14},
      {PACKAGE:"P1",WK_NO:"2022-09-23",PILING_PLAN_CUM:98.67,PILING_ACT_CUM:100,UG_PLAN_CUM:15.53,UG_ACT_CUM:18.25},
      {PACKAGE:"P1",WK_NO:"2022-09-30",PILING_PLAN_CUM:99.01,PILING_ACT_CUM:100,UG_PLAN_CUM:17.3,UG_ACT_CUM:21.63},
      {PACKAGE:"P1",WK_NO:"2022-10-07",PILING_PLAN_CUM:99.33,PILING_ACT_CUM:100,UG_PLAN_CUM:18.9,UG_ACT_CUM:23.07},
      {PACKAGE:"P1",WK_NO:"2022-10-14",PILING_PLAN_CUM:99.66,PILING_ACT_CUM:100,UG_PLAN_CUM:21,UG_ACT_CUM:30.1},
      {PACKAGE:"P1",WK_NO:"2022-10-21",PILING_PLAN_CUM:100,PILING_ACT_CUM:100,UG_PLAN_CUM:22.95,UG_ACT_CUM:30.1},
      {PACKAGE:"P1",WK_NO:"2022-10-28",PILING_PLAN_CUM:100,PILING_ACT_CUM:100,UG_PLAN_CUM:25.34,UG_ACT_CUM:30.36},
      {PACKAGE:"P1",WK_NO:"2022-11-04",PILING_PLAN_CUM:100,PILING_ACT_CUM:100,UG_PLAN_CUM:28.98,UG_ACT_CUM:30.78},
      {PACKAGE:"P1",WK_NO:"2022-11-11",PILING_PLAN_CUM:100,PILING_ACT_CUM:100,UG_PLAN_CUM:32.59,UG_ACT_CUM:30.78},
      {PACKAGE:"P1",WK_NO:"2022-11-18",PILING_PLAN_CUM:100,PILING_ACT_CUM:100,UG_PLAN_CUM:36.98,UG_ACT_CUM:32.64},
      {PACKAGE:"P1",WK_NO:"2022-11-25",PILING_PLAN_CUM:100,PILING_ACT_CUM:100,UG_PLAN_CUM:40.59,UG_ACT_CUM:35.07},
      {PACKAGE:"P1",WK_NO:"2022-12-02",PILING_PLAN_CUM:100,PILING_ACT_CUM:100,UG_PLAN_CUM:48.3,UG_ACT_CUM:44.87},
      {PACKAGE:"P1",WK_NO:"2022-12-09",PILING_PLAN_CUM:100,PILING_ACT_CUM:100,UG_PLAN_CUM:54.54,UG_ACT_CUM:49.7},
      {PACKAGE:"P1",WK_NO:"2022-12-16",PILING_PLAN_CUM:100,PILING_ACT_CUM:100,UG_PLAN_CUM:60.53,UG_ACT_CUM:55.99},
      {PACKAGE:"P1",WK_NO:"2022-12-23",PILING_PLAN_CUM:100,PILING_ACT_CUM:100,UG_PLAN_CUM:66.16,UG_ACT_CUM:60.56},
      {PACKAGE:"P1",WK_NO:"2022-12-30",PILING_PLAN_CUM:100,PILING_ACT_CUM:100,UG_PLAN_CUM:73.76,UG_ACT_CUM:63.39},
      {PACKAGE:"P1",WK_NO:"2023-01-06",PILING_PLAN_CUM:100,PILING_ACT_CUM:100,UG_PLAN_CUM:80.64,UG_ACT_CUM:66.15},
      {PACKAGE:"P1",WK_NO:"2023-01-13",PILING_PLAN_CUM:100,PILING_ACT_CUM:100,UG_PLAN_CUM:84.34,UG_ACT_CUM:69.77},
      {PACKAGE:"P1",WK_NO:"2023-01-20",PILING_PLAN_CUM:100,PILING_ACT_CUM:100,UG_PLAN_CUM:87.13,UG_ACT_CUM:70.28},
      {PACKAGE:"P1",WK_NO:"2023-01-27",PILING_PLAN_CUM:100,PILING_ACT_CUM:100,UG_PLAN_CUM:91.56,UG_ACT_CUM:71.36},
      {PACKAGE:"P1",WK_NO:"2023-02-03",PILING_PLAN_CUM:100,PILING_ACT_CUM:100,UG_PLAN_CUM:93.98,UG_ACT_CUM:73.17},
      {PACKAGE:"P1",WK_NO:"2023-02-10",PILING_PLAN_CUM:100,PILING_ACT_CUM:100,UG_PLAN_CUM:96.27,UG_ACT_CUM:78.66},
      {PACKAGE:"P1",WK_NO:"2023-02-17",UG_PLAN_CUM:98.36},
      {PACKAGE:"P1",WK_NO:"2023-02-24",UG_PLAN_CUM:100},
      {PACKAGE:"P1",WK_NO:"2023-03-03",UG_PLAN_CUM:100},
      {PACKAGE:"P1",WK_NO:"2023-03-10",UG_PLAN_CUM:100},
      {PACKAGE:"P1",WK_NO:"2023-03-17",UG_PLAN_CUM:100},
      {PACKAGE:"P1",WK_NO:"2023-03-24",UG_PLAN_CUM:100},
      {PACKAGE:"P1",WK_NO:"2023-03-31",UG_PLAN_CUM:100},
      {PACKAGE:"P1",WK_NO:"2023-04-07",UG_PLAN_CUM:100},
      {PACKAGE:"P1",WK_NO:"2023-04-14",UG_PLAN_CUM:100},
      {PACKAGE:"P1",WK_NO:"2023-04-21",UG_PLAN_CUM:100},
      {PACKAGE:"P1",WK_NO:"2023-04-28",UG_PLAN_CUM:100},
      {PACKAGE:"P1",WK_NO:"2023-05-05",UG_PLAN_CUM:100},
      {PACKAGE:"P1",WK_NO:"2023-05-12",UG_PLAN_CUM:100},
      {PACKAGE:"P1",WK_NO:"2023-05-19",UG_PLAN_CUM:100},
      {PACKAGE:"P1",WK_NO:"2023-05-26",UG_PLAN_CUM:100},
      {PACKAGE:"P1",WK_NO:"2023-06-02",UG_PLAN_CUM:100},
      {PACKAGE:"P1",WK_NO:"2023-06-09",UG_PLAN_CUM:100},
      {PACKAGE:"P1",WK_NO:"2023-06-16",UG_PLAN_CUM:100},
      {PACKAGE:"P1",WK_NO:"2023-06-23",UG_PLAN_CUM:100},
      {PACKAGE:"P1",WK_NO:"2023-06-30",UG_PLAN_CUM:100},
      {PACKAGE:"P1",WK_NO:"2023-07-07"},
      {PACKAGE:"P1",WK_NO:"2023-07-14",UG_PLAN_CUM:100},
      {PACKAGE:"P1",WK_NO:"2023-07-21",UG_PLAN_CUM:100},
      {PACKAGE:"P1",WK_NO:"2023-07-28",UG_PLAN_CUM:100},
      {PACKAGE:"P1",WK_NO:"2023-08-04",UG_PLAN_CUM:100},
      {PACKAGE:"P1",WK_NO:"2023-08-11"},
      {PACKAGE:"P1",WK_NO:"2023-08-18"},
      {PACKAGE:"P1",WK_NO:"2023-08-25"},
      {PACKAGE:"P1",WK_NO:"2023-09-01"},
      {PACKAGE:"P1",WK_NO:"2023-09-08"},
      {PACKAGE:"P1",WK_NO:"2023-09-15"},
      {PACKAGE:"P1",WK_NO:"2023-09-22"},
      {PACKAGE:"P1",WK_NO:"2023-09-29"}
    ],
}