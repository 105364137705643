import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 720,
      CanvasHeight: 2200,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 380,
      CanvasChartHeight: 280,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Weight : ', x:240, y: 220, font: 'roboto', class: 'regular', size: 11, color: '#BCBCBC', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: 350, y: 200, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'end' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: 350,
          y: 220,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'end'
        },
      ]
    },
    DataItems: 
    [
      {REQ_DESCR:"Filter",ROS_ETA_PLAN:-196,ROS_ETA_FORECAST:-196},
      {REQ_DESCR:"Hose Reel and Hydrant",ROS_ETA_PLAN:-184,ROS_ETA_FORECAST:-94},
      {REQ_DESCR:"Control Valve",ROS_ETA_PLAN:-118,ROS_ETA_FORECAST:-118},
      {REQ_DESCR:"Watertight and Weather Tight Doors  ",ROS_ETA_PLAN:-87,ROS_ETA_FORECAST:-87},
      {REQ_DESCR:"Master Key",ROS_ETA_PLAN:-61,ROS_ETA_FORECAST:-61},
      {REQ_DESCR:"Fire & Gas detector for Hull and LQ",ROS_ETA_PLAN:-59,ROS_ETA_FORECAST:-59},
      {REQ_DESCR:"Material handling equipment",ROS_ETA_PLAN:-50,ROS_ETA_FORECAST:-50},
      {REQ_DESCR:"Hull Pumps (Non API - Vertical type) 1st batch",ROS_ETA_PLAN:-34,ROS_ETA_FORECAST:-34},
      {REQ_DESCR:"Goods Lift",ROS_ETA_PLAN:-24,ROS_ETA_FORECAST:-24},
      {REQ_DESCR:"Hull API Pumps",ROS_ETA_PLAN:-24,ROS_ETA_FORECAST:-24},
      {REQ_DESCR:"Hull Pumps (Non API - Horizontal type)",ROS_ETA_PLAN:-23,ROS_ETA_FORECAST:-23},
      {REQ_DESCR:"Sewage treatment package",ROS_ETA_PLAN:-23,ROS_ETA_FORECAST:-23},
      {REQ_DESCR:"HPU and Hydraulic Actuator Valves - Valve for ballast system",ROS_ETA_PLAN:-20,ROS_ETA_FORECAST:-20},
      {REQ_DESCR:"GYM Equipment",ROS_ETA_PLAN:-16,ROS_ETA_FORECAST:-16},
      {REQ_DESCR:"Helideck Package",ROS_ETA_PLAN:-11,ROS_ETA_FORECAST:-11},
      {REQ_DESCR:"Anti-Piracy Barrier",ROS_ETA_PLAN:-6,ROS_ETA_FORECAST:-6},
      {REQ_DESCR:"Fresh and potable water package",ROS_ETA_PLAN:-4,ROS_ETA_FORECAST:0,ETA_ACTUAL:"2020-10-13"},
      {REQ_DESCR:"Instrument Air Compressor and Dryer Package (HULL & LQ) - Dryer",ROS_ETA_PLAN:-1,ROS_ETA_FORECAST:-1},
      {REQ_DESCR:"Freefall Lifeboat - Lifeboat",ROS_ETA_PLAN:-1,ROS_ETA_FORECAST:-1},
      {REQ_DESCR:"Marine Evacuation System and Liferaft",ROS_ETA_PLAN:0,ROS_ETA_FORECAST:0},
      {REQ_DESCR:"Freefall Lifeboat - Davit",ROS_ETA_PLAN:0,ROS_ETA_FORECAST:0},
      {REQ_DESCR:"Towing Equipment",ROS_ETA_PLAN:0,ROS_ETA_FORECAST:0},
      {REQ_DESCR:"Crew Transfer Basket",ROS_ETA_PLAN:0,ROS_ETA_FORECAST:0},
      {REQ_DESCR:"Key service generator",ROS_ETA_PLAN:0,ROS_ETA_FORECAST:0,ETA_ACTUAL:"2020-07-21"},
      {REQ_DESCR:"Personnel Lift",ROS_ETA_PLAN:0,ROS_ETA_FORECAST:0},
      {REQ_DESCR:"Snuffing System",ROS_ETA_PLAN:0,ROS_ETA_FORECAST:0},
      {REQ_DESCR:"Calorifier Package",ROS_ETA_PLAN:0,ROS_ETA_FORECAST:0,ETA_ACTUAL:"2020-10-02"},
      {REQ_DESCR:"Exchanger",ROS_ETA_PLAN:0,ROS_ETA_FORECAST:0,ETA_ACTUAL:"2020-07-20"},
      {REQ_DESCR:"Air Receiver",ROS_ETA_PLAN:0,ROS_ETA_FORECAST:0,ETA_ACTUAL:"2020-07-09"},
      {REQ_DESCR:"Pneumatic Diaphragm Pumps",ROS_ETA_PLAN:0,ROS_ETA_FORECAST:0},
      {REQ_DESCR:"Screw Pumps",ROS_ETA_PLAN:0,ROS_ETA_FORECAST:0},
      {REQ_DESCR:"Actuated Valve",ROS_ETA_PLAN:0,ROS_ETA_FORECAST:0},
      {REQ_DESCR:"Pressure Safety Relief Valve",ROS_ETA_PLAN:0,ROS_ETA_FORECAST:0},
      {REQ_DESCR:"Lighting Fixtures",ROS_ETA_PLAN:0,ROS_ETA_FORECAST:0,ETA_ACTUAL:"2020-08-21"},
      {REQ_DESCR:"Diesel Oil Purifier Package",ROS_ETA_PLAN:0,ROS_ETA_FORECAST:0,ETA_ACTUAL:"2020-07-09"},
      {REQ_DESCR:"Foam Package",ROS_ETA_PLAN:0,ROS_ETA_FORECAST:0,ETA_ACTUAL:"2020-09-22"},
      {REQ_DESCR:"Slops Polishing Unit and Oil Discharge",ROS_ETA_PLAN:0,ROS_ETA_FORECAST:0},
      {REQ_DESCR:"Cargo Pump Package (Including Ballast pump,Other hydraulic pumps)",ROS_ETA_PLAN:0,ROS_ETA_FORECAST:0,ETA_ACTUAL:"2020-07-07"},
      {REQ_DESCR:"Hull Pumps (Non API - Vertical type) 2nd batch",ROS_ETA_PLAN:0,ROS_ETA_FORECAST:0},
      {REQ_DESCR:"Mooring Fairlead Bracket",ROS_ETA_PLAN:0,ROS_ETA_FORECAST:0,ETA_ACTUAL:"2020-07-30"},
      {REQ_DESCR:"Cargo Pump Package - HPU",ROS_ETA_PLAN:0,ROS_ETA_FORECAST:0,ETA_ACTUAL:"2020-06-30"},
      {REQ_DESCR:"HPU and Hydraulic Actuator Valves - Valve for ballast system (2nd Batch)",ROS_ETA_PLAN:0,ROS_ETA_FORECAST:0},
      {REQ_DESCR:"HPU and Hydraulic Actuator Valves - HPU",ROS_ETA_PLAN:0,ROS_ETA_FORECAST:0},
      {REQ_DESCR:"Pressure Safety Relief Valve (2nd Batch)",ROS_ETA_PLAN:0,ROS_ETA_FORECAST:0},
      {REQ_DESCR:"Prefabricated Cabins",ROS_ETA_PLAN:0,ROS_ETA_FORECAST:0},
      {REQ_DESCR:"Sacrificial Anode (1st Batch)",ROS_ETA_PLAN:0,ROS_ETA_FORECAST:0,ETA_ACTUAL:"2019-07-11"},
      {REQ_DESCR:"400/230v Power Distribution Boards",ROS_ETA_PLAN:1,ROS_ETA_FORECAST:1},
      {REQ_DESCR:"Cold Stores",ROS_ETA_PLAN:1,ROS_ETA_FORECAST:1},
      {REQ_DESCR:"Instrument Air Compressor and Dryer Package (HULL & LQ) - Air Compressor",ROS_ETA_PLAN:2,ROS_ETA_FORECAST:-3},
      {REQ_DESCR:"Mooring Fairlead and Chain Stopper",ROS_ETA_PLAN:8,ROS_ETA_FORECAST:-1},
      {REQ_DESCR:"Lifesaving, Fire Fighting Equipment",ROS_ETA_PLAN:10,ROS_ETA_FORECAST:10},
      {REQ_DESCR:"Hospital and Galley Call Alarm",ROS_ETA_PLAN:19,ROS_ETA_FORECAST:19},
      {REQ_DESCR:"Water Mist system",ROS_ETA_PLAN:21,ROS_ETA_FORECAST:20},
      {REQ_DESCR:"Workshop Equipment Package",ROS_ETA_PLAN:37,ROS_ETA_FORECAST:37},
      {REQ_DESCR:"HVAC package (in LQ)",ROS_ETA_PLAN:51,ROS_ETA_FORECAST:51,ETA_ACTUAL:"2020-10-14"},
      {REQ_DESCR:"Medical Equipment",ROS_ETA_PLAN:54,ROS_ETA_FORECAST:54},
      {REQ_DESCR:"HVAC package (in Hull Machanical Space)",ROS_ETA_PLAN:55,ROS_ETA_FORECAST:55,ETA_ACTUAL:"2020-09-24"},
      {REQ_DESCR:"Tank Washing machine",ROS_ETA_PLAN:96,ROS_ETA_FORECAST:96},
      {REQ_DESCR:"Laundry Equipment",ROS_ETA_PLAN:97,ROS_ETA_FORECAST:90,ETA_ACTUAL:"2020-10-17"},
      {REQ_DESCR:"Tank Gauging System",ROS_ETA_PLAN:126,ROS_ETA_FORECAST:126},
      {REQ_DESCR:"Gas Sampling System",ROS_ETA_PLAN:129,ROS_ETA_FORECAST:129},
      {REQ_DESCR:"Garbage Compactor",ROS_ETA_PLAN:131,ROS_ETA_FORECAST:131},
      {REQ_DESCR:"NAVIDS & Aircraft Systems - LQ Helideck",ROS_ETA_PLAN:136,ROS_ETA_FORECAST:136},
      {REQ_DESCR:"Galley Equipment",ROS_ETA_PLAN:300,ROS_ETA_FORECAST:400,ETA_ACTUAL:"2020-10-17"}
    ]
}