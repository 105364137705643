import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 250,
      CanvasHeight: 130,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 110,
      CanvasChartHeight: 130,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

    DataItems: [
      {PLAN:18.98,ACTUAL:15.93,DELTA:-3.05,TEXT1:"Lifting Plan",TEXT1_DATE:"14-Oct-21",TEXT2:"OGM Goal",TEXT2_PROG:88.3}
    ],
}