import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 2300,
    CanvasHeight: 980,
    CanvasBorderWeight: 0.05,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.1,

    CanvasChartX: 50,
    CanvasChartY: 50,
    CanvasChartWidth: 480,
    CanvasChartHeight: 250,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  DataItems:  [
    {SUBSYSTEM:"21-1001-1001-01",SUBSYSTEM_DESCR:"Annulus Riser vent Monitoring System",LOSH:"Y",MC_B29:"2022-04-28",A_OV_TOT:160,A_OV_COM:15,A_OV_REM:145,A_OV_PROG:9.37,A_OV_STATUS:"less than 70%",A_PG:9.37,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:33,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-1010-1011-01",SUBSYSTEM_DESCR:"Flowline 1 and Receiver",LOSH:"Y",MC_B29:"2022-04-28",A_OV_TOT:387,A_OV_COM:33,A_OV_REM:354,A_OV_PROG:8.52,A_OV_STATUS:"less than 70%",A_PG:8.52,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,ACT_TAG_VALID:195,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-1010-1012-01",SUBSYSTEM_DESCR:"Flowline 2 and Receiver",LOSH:"Y",MC_B29:"2022-04-28",A_OV_TOT:376,A_OV_COM:32,A_OV_REM:344,A_OV_PROG:8.51,A_OV_STATUS:"less than 70%",A_PG:8.51,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:190,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-1090-1090-01",SUBSYSTEM_DESCR:"Production Control Umbilical",MC_B29:"2022-04-28",A_OV_TOT:9,A_OV_COM:0,A_OV_REM:9,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-1510-1510-01",SUBSYSTEM_DESCR:"MEG Riser and Pipeline System to DC1",LOSH:"Y",MC_B29:"2022-04-28",A_OV_TOT:33,A_OV_COM:1,A_OV_REM:32,A_OV_PROG:3.03,A_OV_STATUS:"less than 70%",A_PG:3.03,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-1600-1611-01",SUBSYSTEM_DESCR:"Gas Export Riser 1",LOSH:"Y",MC_B29:"2022-04-28",A_OV_TOT:65,A_OV_COM:6,A_OV_REM:59,A_OV_PROG:9.23,A_OV_STATUS:"less than 70%",A_PG:9.23,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:32,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-1600-1612-01",SUBSYSTEM_DESCR:"Gas Export Riser 2",LOSH:"Y",MC_B29:"2022-04-28",A_OV_TOT:65,A_OV_COM:6,A_OV_REM:59,A_OV_PROG:9.23,A_OV_STATUS:"less than 70%",A_PG:9.23,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:32,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-1911-1911-01",SUBSYSTEM_DESCR:"Hydraulic HPUs, RIOs and TUTUs",LOSH:"Y",MC_B29:"2022-04-28",A_OV_TOT:77,A_OV_COM:7,A_OV_REM:70,A_OV_PROG:9.09,A_OV_STATUS:"less than 70%",A_PG:9.09,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:49,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-1931-1931-01",SUBSYSTEM_DESCR:"Subsea Control System ,MCS, SSIV, SPCU, EPU's, HVE JB's, LVE JB's and Subsea ICSS",MC_B29:"2022-03-17",A_OV_TOT:94,A_OV_COM:0,A_OV_REM:94,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:11,PB1_OV_COM:0,PB1_OV_REM:11,ACT_TAG_VALID:92,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-2200-2131-01",SUBSYSTEM_DESCR:"Slug Catcher 1 and Liquid Heater 1",LOSH:"Y",MC_B29:"2022-05-12",A_OV_TOT:532,A_OV_COM:114,A_OV_REM:418,A_OV_PROG:21.42,A_OV_STATUS:"less than 70%",A_PG:21.42,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P3",PB1_OV_TOT:8,PB1_OV_COM:0,PB1_OV_REM:8,ACT_TAG_VALID:231,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2200-2132-01",SUBSYSTEM_DESCR:"Slug Catcher 2 and Liquid heater 2",LOSH:"Y",MC_B29:"2022-05-12",A_OV_TOT:527,A_OV_COM:113,A_OV_REM:414,A_OV_PROG:21.44,A_OV_STATUS:"less than 70%",A_PG:21.44,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P3",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:227,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2200-2221-01",SUBSYSTEM_DESCR:"MP Separator",LOSH:"Y",MC_B29:"2022-07-14",A_OV_TOT:309,A_OV_COM:71,A_OV_REM:238,A_OV_PROG:22.97,A_OV_STATUS:"less than 70%",A_PG:22.97,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,ACT_TAG_VALID:122,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2200-2230-01",SUBSYSTEM_DESCR:"LP Separator",LOSH:"Y",MC_B29:"2022-06-02",A_OV_TOT:264,A_OV_COM:70,A_OV_REM:194,A_OV_PROG:26.51,A_OV_STATUS:"less than 70%",A_PG:26.51,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:90,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2200-2230-02",SUBSYSTEM_DESCR:"Interstage Heaters",LOSH:"Y",MC_B29:"2022-06-16",A_OV_TOT:116,A_OV_COM:40,A_OV_REM:76,A_OV_PROG:34.48,A_OV_STATUS:"less than 70%",A_PG:34.48,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:28,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2200-2240-01",SUBSYSTEM_DESCR:"LLP separator, Condensate Coolers and Condensate Coalescer Pumps",LOSH:"Y",MC_B29:"2022-05-12",A_OV_TOT:419,A_OV_COM:94,A_OV_REM:325,A_OV_PROG:22.43,A_OV_STATUS:"less than 70%",A_PG:22.43,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:7,PA_OV_COM:1,PA_OV_REM:6,ACT_TAG_VALID:158,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-2200-2240-03",SUBSYSTEM_DESCR:"Condensate Coalescer Filters",LOSH:"Y",MC_B29:"2022-05-12",A_OV_TOT:124,A_OV_COM:43,A_OV_REM:81,A_OV_PROG:34.67,A_OV_STATUS:"less than 70%",A_PG:34.67,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:33,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2200-2240-04",SUBSYSTEM_DESCR:"Condensate Coalescer Package",LOSH:"Y",MC_B29:"2022-05-12",A_OV_TOT:194,A_OV_COM:41,A_OV_REM:153,A_OV_PROG:21.13,A_OV_STATUS:"less than 70%",A_PG:21.13,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PB1_OV_TOT:3,PB1_OV_COM:3,PB1_OV_REM:0,ACT_TAG_VALID:59,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2200-2470-01",SUBSYSTEM_DESCR:"Warm Condensate Heater, Offspec Condensate Pump and Distribution",LOSH:"Y",MC_B29:"2022-07-14",A_OV_TOT:282,A_OV_COM:32,A_OV_REM:250,A_OV_PROG:11.34,A_OV_STATUS:"less than 70%",A_PG:11.34,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:135,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2400-2440-01",SUBSYSTEM_DESCR:"Condensate Metering",LOSH:"Y",MC_B29:"2022-06-09",A_OV_TOT:117,A_OV_COM:5,A_OV_REM:112,A_OV_PROG:4.27,A_OV_STATUS:"less than 70%",A_PG:4.27,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:6,PA_OV_COM:1,PA_OV_REM:5,PB1_OV_TOT:33,PB1_OV_COM:1,PB1_OV_REM:32,ACT_TAG_VALID:71,PUNCH_NUMBER_VALID:39,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-2400-2450-01",SUBSYSTEM_DESCR:"CFW1 Offspec Condensate Tank and Pumps ,Incl Washing Machine",LOSH:"Y",MC_B29:"2022-04-07",A_OV_TOT:336,A_OV_COM:107,A_OV_REM:229,A_OV_PROG:31.84,A_OV_STATUS:"less than 70%",A_PG:31.84,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:5,PB1_OV_COM:0,PB1_OV_REM:5,ACT_TAG_VALID:93,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-2400-2460-01",SUBSYSTEM_DESCR:"Condensate Offloading",LOSH:"Y",MC_B29:"2022-06-09",A_OV_TOT:168,A_OV_COM:11,A_OV_REM:157,A_OV_PROG:6.54,A_OV_STATUS:"less than 70%",A_PG:6.54,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:9,PB1_OV_COM:3,PB1_OV_REM:6,ACT_TAG_VALID:85,PUNCH_NUMBER_VALID:9,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-2400-9360-01",SUBSYSTEM_DESCR:"Cargo Oil Washing Header and Distribution",LOSH:"Y",MC_B29:"2022-03-24",A_OV_TOT:59,A_OV_COM:8,A_OV_REM:51,A_OV_PROG:13.55,A_OV_STATUS:"less than 70%",A_PG:13.55,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-2400-9360-02",SUBSYSTEM_DESCR:"Condensate ImportandLoading Header",LOSH:"Y",MC_B29:"2022-03-24",A_OV_TOT:72,A_OV_COM:0,A_OV_REM:72,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,ACT_TAG_VALID:26,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-2400-9360-03",SUBSYSTEM_DESCR:"Condensate Transfer Header",LOSH:"Y",MC_B29:"2022-03-24",A_OV_TOT:277,A_OV_COM:30,A_OV_REM:247,A_OV_PROG:10.83,A_OV_STATUS:"less than 70%",A_PG:10.83,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,ACT_TAG_VALID:113,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-2400-9360-04",SUBSYSTEM_DESCR:"Condensate ExportandOffloading Header",LOSH:"Y",MC_B29:"2022-03-24",A_OV_TOT:194,A_OV_COM:28,A_OV_REM:166,A_OV_PROG:14.43,A_OV_STATUS:"less than 70%",A_PG:14.43,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:60,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2400-9361-01",SUBSYSTEM_DESCR:"COW1 Tank and Pumps ,Incl Washing Machine",LOSH:"Y",MC_B29:"2022-04-21",A_OV_TOT:178,A_OV_COM:74,A_OV_REM:104,A_OV_PROG:41.57,A_OV_STATUS:"less than 70%",A_PG:41.57,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,PB3_OV_TOT:6,PB3_OV_COM:0,PB3_OV_REM:6,ACT_TAG_VALID:49,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-2400-9362-01",SUBSYSTEM_DESCR:"COW2 Tank and Pumps ,Incl Washing Machine",LOSH:"Y",MC_B29:"2022-04-21",A_OV_TOT:172,A_OV_COM:72,A_OV_REM:100,A_OV_PROG:41.86,A_OV_STATUS:"less than 70%",A_PG:41.86,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B3",PRIORITY:"P2",PB3_OV_TOT:1,PB3_OV_COM:0,PB3_OV_REM:1,ACT_TAG_VALID:48,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-2400-9363-01",SUBSYSTEM_DESCR:"COW3 Tank and Pumps ,Incl Washing Machine",LOSH:"Y",MC_B29:"2022-03-24",A_OV_TOT:177,A_OV_COM:70,A_OV_REM:107,A_OV_PROG:39.54,A_OV_STATUS:"less than 70%",A_PG:39.54,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:0,PA_OV_REM:3,PB3_OV_TOT:7,PB3_OV_COM:0,PB3_OV_REM:7,ACT_TAG_VALID:49,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2400-9364-01",SUBSYSTEM_DESCR:"COW4 Tank and Pumps ,Incl Washing Machine",LOSH:"Y",MC_B29:"2022-03-24",A_OV_TOT:171,A_OV_COM:76,A_OV_REM:95,A_OV_PROG:44.44,A_OV_STATUS:"less than 70%",A_PG:44.44,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:6,PA_OV_COM:0,PA_OV_REM:6,PB3_OV_TOT:8,PB3_OV_COM:0,PB3_OV_REM:8,ACT_TAG_VALID:48,PUNCH_NUMBER_VALID:14,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-2400-9365-01",SUBSYSTEM_DESCR:"COE1 Tank and Pumps ,Incl Washing Machine",LOSH:"Y",MC_B29:"2022-03-24",A_OV_TOT:164,A_OV_COM:68,A_OV_REM:96,A_OV_PROG:41.46,A_OV_STATUS:"less than 70%",A_PG:41.46,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:47,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1}
  ],
  Queries: {
    SQL1: [
      { DESC: 'DataSource SPCS Data 03-Mar-20' }
    ],
    SQL2: [
      { CDATE: '2021-08-20' }
    ],
    SQL3: [
      {MC_PLAN:"2021-08-13",A_T:1552,A_A:555,A_C:482,A_O:997,A_PG:35.76,A_CUM:555,A_CUM_PG:0.69,PLAN_CUM:68693.57333048206,ACTUAL_CUM:68857,PA_O:18,PB1_O:97,PB2_O:19,PB3_O:0},
      {MC_PLAN:"2021-08-20",A_T:1119,A_A:102,A_C:102,A_O:1017,A_PG:9.12,A_CUM:657,A_CUM_PG:0.82,PLAN_CUM:67918.92722081483,ACTUAL_CUM:68741,PA_O:5,PB1_O:17,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-08-27",A_T:715,A_A:93,A_C:10,A_O:622,A_PG:13.01,A_CUM:750,A_CUM_PG:0.94,PLAN_CUM:67240.46292932943,ACTUAL_CUM:69748,PA_O:4,PB1_O:29,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-09-03",A_T:6242,A_A:0,A_C:0,A_O:6242,A_PG:0,A_CUM:750,A_CUM_PG:0.94,PLAN_CUM:66318.65409772044,ACTUAL_CUM:69904,PA_O:6,PB1_O:14,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-09-10",A_T:1556,A_A:87,A_C:19,A_O:1469,A_PG:5.59,A_CUM:837,A_CUM_PG:1.04,PLAN_CUM:65233.2176593381,PA_O:12,PB1_O:17,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-09-17",A_T:1580,A_A:48,A_C:0,A_O:1532,A_PG:3.04,A_CUM:885,A_CUM_PG:1.1,PLAN_CUM:63979.15028084907,PA_O:11,PB1_O:18,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-09-24",A_T:1124,A_A:98,A_C:23,A_O:1026,A_PG:8.72,A_CUM:983,A_CUM_PG:1.23,PLAN_CUM:62637.78967802465,PA_O:5,PB1_O:0,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-10-01",A_T:1245,A_A:70,A_C:20,A_O:1175,A_PG:5.62,A_CUM:1053,A_CUM_PG:1.31,PLAN_CUM:61174.48720221619,PA_O:8,PB1_O:0,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-10-08",A_T:423,A_A:79,A_C:26,A_O:344,A_PG:18.68,A_CUM:1132,A_CUM_PG:1.41,PLAN_CUM:59747.8308802539,PA_O:16,PB1_O:2,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-10-15",A_T:973,A_A:51,A_C:16,A_O:922,A_PG:5.24,A_CUM:1183,A_CUM_PG:1.48,PLAN_CUM:58060.89324121612,PA_O:25,PB1_O:9,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-10-22",A_T:1031,A_A:120,A_C:66,A_O:911,A_PG:11.64,A_CUM:1303,A_CUM_PG:1.63,PLAN_CUM:56248.08186559112,PA_O:18,PB1_O:0,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-10-29",A_T:3245,A_A:63,A_C:25,A_O:3182,A_PG:1.94,A_CUM:1366,A_CUM_PG:1.7,PLAN_CUM:54322.08696824535,PA_O:9,PB1_O:1,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-11-05",A_T:2238,A_A:138,A_C:19,A_O:2100,A_PG:6.17,A_CUM:1504,A_CUM_PG:1.88,PLAN_CUM:52329.74427595451,PA_O:30,PB1_O:7,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-11-12",A_T:1838,A_A:449,A_C:0,A_O:1389,A_PG:24.43,A_CUM:1953,A_CUM_PG:2.44,PLAN_CUM:50230.04388524004,PA_O:1,PB1_O:0,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-11-19",A_T:4904,A_A:535,A_C:57,A_O:4369,A_PG:10.91,A_CUM:2488,A_CUM_PG:3.1,PLAN_CUM:47971.9976513864,PA_O:65,PB1_O:1,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-11-26",A_T:5082,A_A:957,A_C:58,A_O:4125,A_PG:18.83,A_CUM:3445,A_CUM_PG:4.3,PLAN_CUM:45613.47224030309,PA_O:50,PB1_O:0,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-12-03",A_T:1723,A_A:161,A_C:0,A_O:1562,A_PG:9.34,A_CUM:3606,A_CUM_PG:4.5,PLAN_CUM:43362.54333082551,PA_O:14,PB1_O:4,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-12-10",A_T:2281,A_A:313,A_C:14,A_O:1968,A_PG:13.72,A_CUM:3919,A_CUM_PG:4.89,PLAN_CUM:41171.62579251709,PA_O:18,PB1_O:0,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-12-17",A_T:4857,A_A:714,A_C:90,A_O:4143,A_PG:14.7,A_CUM:4633,A_CUM_PG:5.78,PLAN_CUM:39052.72167713079,PA_O:9,PB1_O:3,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-12-24",A_T:1246,A_A:98,A_C:0,A_O:1148,A_PG:7.87,A_CUM:4731,A_CUM_PG:5.9,PLAN_CUM:37024.87512187446,PA_O:3,PB1_O:0,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-12-31",A_T:2719,A_A:394,A_C:24,A_O:2325,A_PG:14.49,A_CUM:5125,A_CUM_PG:6.4,PLAN_CUM:35173.14904014867,PA_O:24,PB1_O:10,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-01-07",A_T:1144,A_A:294,A_C:199,A_O:850,A_PG:25.7,A_CUM:5419,A_CUM_PG:6.76,PLAN_CUM:33502.62818507096,PA_O:0,PB1_O:8,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-01-14",A_T:1986,A_A:184,A_C:33,A_O:1802,A_PG:9.26,A_CUM:5603,A_CUM_PG:6.99,PLAN_CUM:31955.06175501426},
      {MC_PLAN:"2022-01-21",A_T:421,A_A:222,A_C:3,A_O:199,A_PG:52.73,A_CUM:5825,A_CUM_PG:7.27,PLAN_CUM:30639.13179909264,PA_O:11,PB1_O:0,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-01-28",A_T:465,A_A:58,A_C:11,A_O:407,A_PG:12.47,A_CUM:5883,A_CUM_PG:7.34,PLAN_CUM:29360.39992929543,PA_O:3,PB1_O:1,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-02-04",A_T:6,A_A:2,A_C:0,A_O:4,A_PG:33.33,A_CUM:5885,A_CUM_PG:7.34,PLAN_CUM:28285.14796386291,PA_O:0,PB1_O:1,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-02-11",A_T:1544,A_A:121,A_C:2,A_O:1423,A_PG:7.84,A_CUM:6006,A_CUM_PG:7.5,PLAN_CUM:27168.02656112659,PA_O:9,PB1_O:2,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-02-18",A_T:1792,A_A:189,A_C:4,A_O:1603,A_PG:10.55,A_CUM:6195,A_CUM_PG:7.73,PLAN_CUM:25944.39224863975,PA_O:9,PB1_O:3,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-02-25",A_T:1171,A_A:116,A_C:27,A_O:1055,A_PG:9.91,A_CUM:6311,A_CUM_PG:7.88,PLAN_CUM:24634.19008777003,PA_O:1,PB1_O:0,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-03-04",A_T:443,A_A:57,A_C:0,A_O:386,A_PG:12.87,A_CUM:6368,A_CUM_PG:7.95,PLAN_CUM:23287.55225129644,PA_O:0,PB1_O:0,PB2_O:0,PB3_O:0}
    ],
    SQL4: [
      {CUTOFF:"2018-06-22",WK_NO:"W2018_25"},
      {CUTOFF:"2018-06-29",WK_NO:"W2018_26"},
      {CUTOFF:"2018-07-06",WK_NO:"W2018_27"},
      {CUTOFF:"2018-07-13",WK_NO:"W2018_28"},
      {CUTOFF:"2018-07-20",WK_NO:"W2018_29"},
      {CUTOFF:"2018-07-27",WK_NO:"W2018_30"},
      {CUTOFF:"2018-08-03",WK_NO:"W2018_31"},
      {CUTOFF:"2018-08-10",WK_NO:"W2018_32"},
      {CUTOFF:"2018-08-17",WK_NO:"W2018_33"},
      {CUTOFF:"2018-08-24",WK_NO:"W2018_34"},
      {CUTOFF:"2018-08-31",WK_NO:"W2018_35"},
      {CUTOFF:"2018-09-07",WK_NO:"W2018_36"},
      {CUTOFF:"2018-09-14",WK_NO:"W2018_37"},
      {CUTOFF:"2018-09-21",WK_NO:"W2018_38"},
      {CUTOFF:"2018-09-28",WK_NO:"W2018_39"},
      {CUTOFF:"2018-10-05",WK_NO:"W2018_40"},
      {CUTOFF:"2018-10-12",WK_NO:"W2018_41"},
      {CUTOFF:"2018-10-19",WK_NO:"W2018_42"},
      {CUTOFF:"2018-10-26",WK_NO:"W2018_43"},
      {CUTOFF:"2018-11-02",WK_NO:"W2018_44"},
      {CUTOFF:"2018-11-09",WK_NO:"W2018_45"},
      {CUTOFF:"2018-11-16",WK_NO:"W2018_46"},
      {CUTOFF:"2018-11-23",WK_NO:"W2018_47"},
      {CUTOFF:"2018-11-30",WK_NO:"W2018_48"},
      {CUTOFF:"2018-12-07",WK_NO:"W2018_49"},
      {CUTOFF:"2018-12-14",WK_NO:"W2018_50"},
      {CUTOFF:"2018-12-21",WK_NO:"W2018_51"},
      {CUTOFF:"2018-12-28",WK_NO:"W2018_52"},
      {CUTOFF:"2019-01-04",WK_NO:"W2019_01"},
      {CUTOFF:"2019-01-11",WK_NO:"W2019_02"},
      {CUTOFF:"2019-01-18",WK_NO:"W2019_03"},
      {CUTOFF:"2019-01-25",WK_NO:"W2019_04"},
      {CUTOFF:"2019-02-01",WK_NO:"W2019_05"},
      {CUTOFF:"2019-02-08",WK_NO:"W2019_06"},
      {CUTOFF:"2019-02-15",WK_NO:"W2019_07"},
      {CUTOFF:"2019-02-22",WK_NO:"W2019_08"},
      {CUTOFF:"2019-03-01",WK_NO:"W2019_09"},
      {CUTOFF:"2019-03-08",WK_NO:"W2019_10"},
      {CUTOFF:"2019-03-15",WK_NO:"W2019_11"},
      {CUTOFF:"2019-03-22",WK_NO:"W2019_12"},
      {CUTOFF:"2019-03-29",WK_NO:"W2019_13"},
      {CUTOFF:"2019-04-05",WK_NO:"W2019_14"},
      {CUTOFF:"2019-04-12",WK_NO:"W2019_15"},
      {CUTOFF:"2019-04-19",WK_NO:"W2019_16"},
      {CUTOFF:"2019-04-26",WK_NO:"W2019_17"},
      {CUTOFF:"2019-05-03",WK_NO:"W2019_18"},
      {CUTOFF:"2019-05-10",WK_NO:"W2019_19"},
      {CUTOFF:"2019-05-17",WK_NO:"W2019_20"},
      {CUTOFF:"2019-05-24",WK_NO:"W2019_21"},
      {CUTOFF:"2019-05-31",WK_NO:"W2019_22"},
      {CUTOFF:"2019-06-07",WK_NO:"W2019_23"},
      {CUTOFF:"2019-06-14",WK_NO:"W2019_24"},
      {CUTOFF:"2019-06-21",WK_NO:"W2019_25"},
      {CUTOFF:"2019-06-28",WK_NO:"W2019_26"},
      {CUTOFF:"2019-07-05",WK_NO:"W2019_27"},
      {CUTOFF:"2019-07-12",WK_NO:"W2019_28"},
      {CUTOFF:"2019-07-19",WK_NO:"W2019_29"},
      {CUTOFF:"2019-07-26",WK_NO:"W2019_30"},
      {CUTOFF:"2019-08-02",WK_NO:"W2019_31"},
      {CUTOFF:"2019-08-09",WK_NO:"W2019_32"},
      {CUTOFF:"2019-08-16",WK_NO:"W2019_33"},
      {CUTOFF:"2019-08-23",WK_NO:"W2019_34"},
      {CUTOFF:"2019-08-30",WK_NO:"W2019_35"},
      {CUTOFF:"2019-09-06",WK_NO:"W2019_36"},
      {CUTOFF:"2019-09-13",WK_NO:"W2019_37"},
      {CUTOFF:"2019-09-20",WK_NO:"W2019_38"},
      {CUTOFF:"2019-09-27",WK_NO:"W2019_39"},
      {CUTOFF:"2019-10-04",WK_NO:"W2019_40"},
      {CUTOFF:"2019-10-11",WK_NO:"W2019_41"},
      {CUTOFF:"2019-10-18",WK_NO:"W2019_42"},
      {CUTOFF:"2019-10-25",WK_NO:"W2019_43"},
      {CUTOFF:"2019-11-01",WK_NO:"W2019_44"},
      {CUTOFF:"2019-11-08",WK_NO:"W2019_45"},
      {CUTOFF:"2019-11-15",WK_NO:"W2019_46"},
      {CUTOFF:"2019-11-22",WK_NO:"W2019_47"},
      {CUTOFF:"2019-11-29",WK_NO:"W2019_48"},
      {CUTOFF:"2019-12-06",WK_NO:"W2019_49"},
      {CUTOFF:"2019-12-13",WK_NO:"W2019_50"},
      {CUTOFF:"2019-12-20",WK_NO:"W2019_51"},
      {CUTOFF:"2019-12-27",WK_NO:"W2019_52"},
      {CUTOFF:"2020-01-03",WK_NO:"W2020_01"},
      {CUTOFF:"2020-01-10",WK_NO:"W2020_02"},
      {CUTOFF:"2020-01-17",WK_NO:"W2020_03"},
      {CUTOFF:"2020-01-24",WK_NO:"W2020_04"},
      {CUTOFF:"2020-01-31",WK_NO:"W2020_05"},
      {CUTOFF:"2020-02-07",WK_NO:"W2020_06"},
      {CUTOFF:"2020-02-14",WK_NO:"W2020_07"},
      {CUTOFF:"2020-02-21",WK_NO:"W2020_08"},
      {CUTOFF:"2020-02-28",WK_NO:"W2020_09"},
      {CUTOFF:"2020-03-06",WK_NO:"W2020_10"},
      {CUTOFF:"2020-03-13",WK_NO:"W2020_11"},
      {CUTOFF:"2020-03-20",WK_NO:"W2020_12"},
      {CUTOFF:"2020-03-27",WK_NO:"W2020_13"},
      {CUTOFF:"2020-04-03",WK_NO:"W2020_14"},
      {CUTOFF:"2020-04-10",WK_NO:"W2020_15"},
      {CUTOFF:"2020-04-17",WK_NO:"W2020_16"},
      {CUTOFF:"2020-04-24",WK_NO:"W2020_17"},
      {CUTOFF:"2020-05-01",WK_NO:"W2020_18"},
      {CUTOFF:"2020-05-08",WK_NO:"W2020_19"},
      {CUTOFF:"2020-05-15",WK_NO:"W2020_20"},
      {CUTOFF:"2020-05-22",WK_NO:"W2020_21"},
      {CUTOFF:"2020-05-29",WK_NO:"W2020_22"},
      {CUTOFF:"2020-06-05",WK_NO:"W2020_23"},
      {CUTOFF:"2020-06-12",WK_NO:"W2020_24"},
      {CUTOFF:"2020-06-19",WK_NO:"W2020_25"},
      {CUTOFF:"2020-06-26",WK_NO:"W2020_26"},
      {CUTOFF:"2020-07-03",WK_NO:"W2020_27"},
      {CUTOFF:"2020-07-10",WK_NO:"W2020_28"},
      {CUTOFF:"2020-07-17",WK_NO:"W2020_29"},
      {CUTOFF:"2020-07-24",WK_NO:"W2020_30"},
      {CUTOFF:"2020-07-31",WK_NO:"W2020_31"},
      {CUTOFF:"2020-08-07",WK_NO:"W2020_32"},
      {CUTOFF:"2020-08-14",WK_NO:"W2020_33"},
      {CUTOFF:"2020-08-21",WK_NO:"W2020_34"},
      {CUTOFF:"2020-08-28",WK_NO:"W2020_35"},
      {CUTOFF:"2020-09-04",WK_NO:"W2020_36"},
      {CUTOFF:"2020-09-11",WK_NO:"W2020_37"},
      {CUTOFF:"2020-09-18",WK_NO:"W2020_38"},
      {CUTOFF:"2020-09-25",WK_NO:"W2020_39"},
      {CUTOFF:"2020-10-02",WK_NO:"W2020_40"},
      {CUTOFF:"2020-10-09",WK_NO:"W2020_41"},
      {CUTOFF:"2020-10-16",WK_NO:"W2020_42"},
      {CUTOFF:"2020-10-23",WK_NO:"W2020_43"},
      {CUTOFF:"2020-10-30",WK_NO:"W2020_44"},
      {CUTOFF:"2020-11-06",WK_NO:"W2020_45"},
      {CUTOFF:"2020-11-13",WK_NO:"W2020_46"},
      {CUTOFF:"2020-11-20",WK_NO:"W2020_47"},
      {CUTOFF:"2020-11-27",WK_NO:"W2020_48"},
      {CUTOFF:"2020-12-04",WK_NO:"W2020_49"},
      {CUTOFF:"2020-12-11",WK_NO:"W2020_50"},
      {CUTOFF:"2020-12-18",WK_NO:"W2020_51"},
      {CUTOFF:"2020-12-25",WK_NO:"W2020_52"},
      {CUTOFF:"2021-01-01",WK_NO:"W2020_53"},
      {CUTOFF:"2021-01-08",WK_NO:"W2021_01"},
      {CUTOFF:"2021-01-15",WK_NO:"W2021_02"},
      {CUTOFF:"2021-01-22",WK_NO:"W2021_03"},
      {CUTOFF:"2021-01-29",WK_NO:"W2021_04"},
      {CUTOFF:"2021-02-05",WK_NO:"W2021_05"},
      {CUTOFF:"2021-02-12",WK_NO:"W2021_06"},
      {CUTOFF:"2021-02-19",WK_NO:"W2021_07"},
      {CUTOFF:"2021-02-26",WK_NO:"W2021_08"},
      {CUTOFF:"2021-03-05",WK_NO:"W2021_09"},
      {CUTOFF:"2021-03-12",WK_NO:"W2021_10"},
      {CUTOFF:"2021-03-19",WK_NO:"W2021_11"},
      {CUTOFF:"2021-03-26",WK_NO:"W2021_12"},
      {CUTOFF:"2021-04-02",WK_NO:"W2021_13"},
      {CUTOFF:"2021-04-09",WK_NO:"W2021_14"},
      {CUTOFF:"2021-04-16",WK_NO:"W2021_15"},
      {CUTOFF:"2021-04-23",WK_NO:"W2021_16"},
      {CUTOFF:"2021-04-30",WK_NO:"W2021_17"},
      {CUTOFF:"2021-05-07",WK_NO:"W2021_18"},
      {CUTOFF:"2021-05-14",WK_NO:"W2021_19"},
      {CUTOFF:"2021-05-21",WK_NO:"W2021_20"},
      {CUTOFF:"2021-05-28",WK_NO:"W2021_21"},
      {CUTOFF:"2021-06-04",WK_NO:"W2021_22"},
      {CUTOFF:"2021-06-11",WK_NO:"W2021_23"},
      {CUTOFF:"2021-06-18",WK_NO:"W2021_24"},
      {CUTOFF:"2021-06-25",WK_NO:"W2021_25"},
      {CUTOFF:"2021-07-02",WK_NO:"W2021_26"},
      {CUTOFF:"2021-07-09",WK_NO:"W2021_27"},
      {CUTOFF:"2021-07-16",WK_NO:"W2021_28"},
      {CUTOFF:"2021-07-23",WK_NO:"W2021_29"},
      {CUTOFF:"2021-07-30",WK_NO:"W2021_30"},
      {CUTOFF:"2021-08-06",WK_NO:"W2021_31"},
      {CUTOFF:"2021-08-13",WK_NO:"W2021_32"},
      {CUTOFF:"2021-08-20",WK_NO:"W2021_33"},
      {CUTOFF:"2021-08-27",WK_NO:"W2021_34"},
      {CUTOFF:"2021-09-03",WK_NO:"W2021_35"},
      {CUTOFF:"2021-09-10",WK_NO:"W2021_36"},
      {CUTOFF:"2021-09-17",WK_NO:"W2021_37"},
      {CUTOFF:"2021-09-24",WK_NO:"W2021_38"},
      {CUTOFF:"2021-10-01",WK_NO:"W2021_39"},
      {CUTOFF:"2021-10-08",WK_NO:"W2021_40"},
      {CUTOFF:"2021-10-15",WK_NO:"W2021_41"},
      {CUTOFF:"2021-10-22",WK_NO:"W2021_42"},
      {CUTOFF:"2021-10-29",WK_NO:"W2021_43"},
      {CUTOFF:"2021-11-05",WK_NO:"W2021_44"},
      {CUTOFF:"2021-11-12",WK_NO:"W2021_45"},
      {CUTOFF:"2021-11-19",WK_NO:"W2021_46"},
      {CUTOFF:"2021-11-26",WK_NO:"W2021_47"},
      {CUTOFF:"2021-12-03",WK_NO:"W2021_48"},
      {CUTOFF:"2021-12-10",WK_NO:"W2021_49"},
      {CUTOFF:"2021-12-17",WK_NO:"W2021_50"},
      {CUTOFF:"2021-12-24",WK_NO:"W2021_51"},
      {CUTOFF:"2021-12-31",WK_NO:"W2021_52"},
      {CUTOFF:"2022-01-07",WK_NO:"W2022_01"},
      {CUTOFF:"2022-01-14",WK_NO:"W2022_02"},
      {CUTOFF:"2022-01-21",WK_NO:"W2022_03"},
      {CUTOFF:"2022-01-28",WK_NO:"W2022_04"},
      {CUTOFF:"2022-02-04",WK_NO:"W2022_05"},
      {CUTOFF:"2022-02-11",WK_NO:"W2022_06"},
      {CUTOFF:"2022-02-18",WK_NO:"W2022_07"},
      {CUTOFF:"2022-02-25",WK_NO:"W2022_08"},
      {CUTOFF:"2022-03-04",WK_NO:"W2022_09"},
      {CUTOFF:"2022-03-11",WK_NO:"W2022_10"},
      {CUTOFF:"2022-03-18",WK_NO:"W2022_11"},
      {CUTOFF:"2022-03-25",WK_NO:"W2022_12"},
      {CUTOFF:"2022-04-01",WK_NO:"W2022_13"},
      {CUTOFF:"2022-04-08",WK_NO:"W2022_14"},
      {CUTOFF:"2022-04-15",WK_NO:"W2022_15"}
    ],
    SQL5: [
      {TOTAL:577,NOTSTARTED:100,INPROGRESS:220,AITR_COMPLETED:170,RFI_ISSUED:299, WD_COMPLETED:200,MC_COMPLETED:250,MC_APPROVED:190,MC_REJECTED:300}
    ],
    SQL6: [
      {CUTOFF:"2021-07-30",WK_NO:"W2021_30",TOTAL:15342,REMAIN:16199,WKS_TREND:10000},
      {CUTOFF:"2021-08-06",WK_NO:"W2021_31",TOTAL:14223,REMAIN:16047,WKS_TREND:10000},
      {CUTOFF:"2021-09-03",WK_NO:"W2021_35",TOTAL:3762,WKS_TREND:10000},
      {CUTOFF:"2021-09-10",WK_NO:"W2021_36",TOTAL:2638,WKS_TREND:10000},
      {CUTOFF:"2021-10-01",WK_NO:"W2021_39",TOTAL:0,WKS_TREND:10000},
      {CUTOFF:"2021-08-13",WK_NO:"W2021_32",TOTAL:13115,REMAIN:15886,WKS_TREND:10000},
      {CUTOFF:"2021-08-20",WK_NO:"W2021_33",TOTAL:6861,REMAIN:6861,WKS_TREND:10000},
      {CUTOFF:"2021-08-27",WK_NO:"W2021_34",TOTAL:5320,REMAIN:15724,WKS_TREND:10000},
      {CUTOFF:"2021-09-17",WK_NO:"W2021_37",TOTAL:1393,WKS_TREND:10000},
      {CUTOFF:"2021-09-24",WK_NO:"W2021_38",TOTAL:974,WKS_TREND:10000}
    ]
  }
}