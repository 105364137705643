import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 850,
    CanvasHeight: 300,
    CanvasBorderWeight: 0.1,
    CanvasBorderColor: '#e0e0e0',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.2,

    CanvasChartAreaAllowed: 'Y',
    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 800,
    CanvasChartHeight: 200,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0.02,
    CanvasChartSpace: 20,

    CanvasFontFamily: 'roboto'
  },
  Text: {
    Text: [
      { text: '', x:20, y: 10, font: 'roboto', class: 'regular', size: 12, color: '#424242', align: 'start' },
    ],
    Filter: [
      { name: 'MOD'   , x: -50, y: 20, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'start' },
    ],
    Sql: [
      {
        query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
        text: 'Data Source',
        x: -30,
        y: 20,
        font: 'roboto',
        style: 'regular',
        size: 11,
        color: '#44A9DF',
        align: 'start'
      },
    ]
  },
  Queries: {
    SQL1: [
      // {TOTAL:18567,COMPLETE:6091,REMAIN:12476,PROG:32.8}
    ],
    SQL2:  [
      {WK_NO:"W2022_23"}
    ],
  },
  DataItems: [

    // { title: 'Thistle', value1: 75.4, value2: 81.5, value3: 41.5 },
    // { title: 'Purple', value1: 45.7, value2: 12.5, value3: 64.5 },
    // { title: 'Indigo.', value1: 20.1, value2: 34.5, value3: 23.5 },
    // { title: 'LightBlue', value1: 60.5, value2: 7.5, value3: 41.5 },
    // { title: 'SkyBlue1.', value1: 100, value2: 65.5, value3: 72.5 },
    // { title: 'SkyBlue2.', value1: 100, value2: 65.5, value3: 72.5 },
    // { title: 'SkyBlue3.', value1: 100, value2: 65.5, value3: 72.5 },
    // { title: 'SkyBlue4.', value1: 100, value2: 65.5, value3: 72.5 },
    // { title: 'SkyBlue5.', value1: 100, value2: 65.5, value3: 72.5 },
    // { title: 'SkyBlue6.', value1: 100, value2: 65.5, value3: 72.5 },
    // { title: 'SkyBlue7.', value1: 100, value2: 65.5, value3: 72.5 },
    // { title: 'SkyBlue8.', value1: 100, value2: 65.5, value3: 72.5 },
    // { title: 'SteelBlue.', value1: 75.4, value2: 15.5, value3: 75.5 }

    {WORK_TYPE_CODE:"PP",COMPLETED:685,INPROGRESS:213,NODATA:668},
    {WORK_TYPE_CODE:"PPA",COMPLETED:577,INPROGRESS:323,NODATA:666},
    {WORK_TYPE_CODE:"MR",COMPLETED:377,INPROGRESS:454,NODATA:735},
    {WORK_TYPE_CODE:"RFQ",COMPLETED:683,INPROGRESS:387,NODATA:496},
    {WORK_TYPE_CODE:"BC",COMPLETED:802,INPROGRESS:407,NODATA:357},
    {WORK_TYPE_CODE:"TBE",COMPLETED:533,INPROGRESS:536,NODATA:497},
    {WORK_TYPE_CODE:"CBE",COMPLETED:431,INPROGRESS:560,NODATA:575},
    {WORK_TYPE_CODE:"AR",COMPLETED:374,INPROGRESS:644,NODATA:548},
    {WORK_TYPE_CODE:"ARA",COMPLETED:63,INPROGRESS:712,NODATA:791},
    {WORK_TYPE_CODE:"LOA",COMPLETED:398,INPROGRESS:649,NODATA:519},
    {WORK_TYPE_CODE:"PO",COMPLETED:374,INPROGRESS:693,NODATA:499},
    {WORK_TYPE_CODE:"MDI",COMPLETED:264,INPROGRESS:739,NODATA:563},
    {WORK_TYPE_CODE:"MO",COMPLETED:0,INPROGRESS:231,NODATA:1335},
    {WORK_TYPE_CODE:"MMR",COMPLETED:0,INPROGRESS:262,NODATA:1304},
    {WORK_TYPE_CODE:"FI",COMPLETED:0,INPROGRESS:792,NODATA:774},
    {WORK_TYPE_CODE:"EW",COMPLETED:276,INPROGRESS:792,NODATA:498},
    {WORK_TYPE_CODE:"SA",COMPLETED:0,INPROGRESS:355,NODATA:1211},
    {WORK_TYPE_CODE:"CC",COMPLETED:0,INPROGRESS:355,NODATA:1211},
    {WORK_TYPE_CODE:"DJS",COMPLETED:272,INPROGRESS:798,NODATA:496}

  ],
}