import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 450,
      CanvasHeight: 350,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },
    DataItems:   [
      {NO:1,TT_NO:"TT-001",QCF:"P01A",QCF_DESCR:"Pre-Test Line Walk",TOTAL:8,COMPLETED:8,REMAIN:0,PROG:100},
      {NO:2,TT_NO:"TT-001",QCF:"P03A",QCF_DESCR:"Piping Pressure Test Checksheet",TOTAL:8,COMPLETED:6,REMAIN:2,PROG:75},
      {NO:3,TT_NO:"TT-001",QCF:"IC01A",QCF_DESCR:"Piping / Equipment Internal Cleaning Inspection",TOTAL:8,COMPLETED:6,REMAIN:2,PROG:75},
      {NO:4,TT_NO:"TT-001",QCF:"P05A",QCF_DESCR:"Test Pack Reinstatement",TOTAL:8,COMPLETED:4,REMAIN:4,PROG:50},
      {NO:5,TT_NO:"TT-001",QCF:"P06A",QCF_DESCR:"Joint Integrity Certificate",TOTAL:8,COMPLETED:4,REMAIN:4,PROG:50},
      {NO:6,TT_NO:"TT-001",QCF:"PK03A",QCF_DESCR:"Mechanical Package Installation Report",TOTAL:1,COMPLETED:1,REMAIN:0,PROG:100},
      {NO:7,TT_NO:"TT-001",QCF:"I02A",QCF_DESCR:"Instrument Calibration",TOTAL:42,COMPLETED:17,REMAIN:25,PROG:40.5},
      {NO:8,TT_NO:"TT-001",QCF:"I09A",QCF_DESCR:"Instrument Pressure / Leak Test Report",TOTAL:8,COMPLETED:3,REMAIN:5,PROG:37.5},
      {NO:9,TT_NO:"TT-001",QCF:"E18A",QCF_DESCR:"Ex Equipment Inspection Report",TOTAL:73,COMPLETED:17,REMAIN:56,PROG:23.3}
    ],
}