
export default {
  Canvas: {
    CanvasWidth: 1600,
    CanvasHeight: 1120,
    CanvasBorderWeight: 0.2,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',//0E1B2B
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 760,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  Queries: {
    SQL1:  []
  },
  
  DataItems: [
    { step: 1,  sn: 1, group: 'Pre-Comm', cat: 1, name: 'Main Substation',  },
    { step: 2,  sn: 1, group: 'Pre-Comm', cat: 2, name: 'Piperack No.1 - Required MC for Power',  },
    { step: 2,  sn: 2, group: 'Pre-Comm', cat: 2, name: 'Piperack No.2 - Required MC for Power',  },
    { step: 2,  sn: 3, group: 'Pre-Comm', cat: 1, name: 'Industrial Water & Fire Water Station 1',  },
    { step: 2,  sn: 4, group: 'Pre-Comm', cat: 1, name: 'Central Control Room',  },
    { step: 3,  sn: 1, group: 'Pre-Comm', cat: 1, name: 'Area B+ Substation',  },
    { step: 3,  sn: 2, group: 'Pre-Comm', cat: 1, name: 'Air Compression Substation (94SS05) ',  },
    { step: 3,  sn: 3, group: 'Pre-Comm', cat: 1, name: 'Cooling Water Substation 1 (91SS12)',  },
    { step: 3,  sn: 4, group: 'Pre-Comm', cat: 2, name: 'Piperack No.4 - Required MC for Power',  },
    { step: 3,  sn: 5, group: 'Pre-Comm', cat: 2, name: 'Piperack No.5- Required MC for Power',  },
    { step: 3,  sn: 6, group: 'Pre-Comm', cat: 2, name: 'Piperack No.7 - Required MC for Power',  },
    { step: 4,  sn: 1, group: 'Pre-Comm', cat: 1, name: 'Boiler Unit Substation (81SS12A) ',  },
    { step: 4,  sn: 2, group: 'Pre-Comm', cat: 1, name: 'Cooling Water Substation 2 (91SS13)',  },
    { step: 4,  sn: 3, group: 'Pre-Comm', cat: 1, name: 'Waste Water Treatment ) Substation ( 97SS10) ',  },
    { step: 4,  sn: 4, group: 'Pre-Comm', cat: 1, name: 'U/G Fiber Optic Cable Connected  CCB to RIE 6',  },
    { step: 5,  sn: 1, group: 'Pre-Comm', cat: 1, name: 'Air Compressor Station',  },
    { step: 5,  sn: 2, group: 'Pre-Comm', cat: 1, name: 'RIE 6 (Boiler / DW / CW / IA) ',  },
    { step: 5,  sn: 3, group: 'Pre-Comm', cat: 1, name: 'Waste Water Treatment Plant',  },
    { step: 5,  sn: 4, group: 'Pre-Comm', cat: 1, name: 'Tank Farm Substation 1 (70SS09) ',  },
    { step: 5,  sn: 5, group: 'Pre-Comm', cat: 2, name: 'U/G Fiber Optic Cable Connected  CCB to RIE 7',  },
    { step: 5,  sn: 6, group: 'Pre-Comm', cat: 1, name: 'U/G Town Gas To Admin Bldg.',  },
    { step: 6,  sn: 1, group: 'Pre-Comm', cat: 1, name: 'Demin Water Unit ',  },
    { step: 6,  sn: 2, group: 'Pre-Comm', cat: 1, name: 'Piperack No.6 - Required MC by Power ',  },
    { step: 6,  sn: 3, group: 'Pre-Comm', cat: 1, name: 'RIE 7 (Tank Farm /Flare/WWT) ',  },
    { step: 7,  sn: 1, group: 'Pre-Comm', cat: 1, name: 'Boiler Feed water / Deaerator (1st Boiler)',  },
    { step: 7,  sn: 2, group: 'Pre-Comm', cat: 1, name: '1st Boiler',  },
    { step: 7,  sn: 3, group: 'Pre-Comm', cat: 1, name: 'Cooling Water System  No.1',  },
    { step: 7,  sn: 4, group: 'Pre-Comm', cat: 1, name: 'Industrial & Fire Water Station 2',  },
    { step: 7,  sn: 5, group: 'Pre-Comm', cat: 1, name: 'Flare System Substation (90SS09B)',  },
    { step: 7,  sn: 6, group: 'Pre-Comm', cat: 1, name: 'GEG Steam Available)',  },
    { step: 7,  sn: 7, group: 'Pre-Comm', cat: 1, name: 'N2 Available)',  },
    { step: 8,  sn: 1, group: 'Pre-Comm', cat: 1, name: 'Cooling Water System 2',  },
    { step: 8,  sn: 2, group: 'Pre-Comm', cat: 1, name: 'Elevated Flare',  },
    { step: 8,  sn: 3, group: 'Pre-Comm', cat: 1, name: 'Tank Farm Substation 2',  },
    { step: 8,  sn: 4, group: 'Pre-Comm', cat: 3, name: 'Early Turnover for CW Flushing/Passivation and Instrument Air Commissioning',  },
    { step: 8,  sn: 5, group: 'Pre-Comm', cat: 3, name: 'Cooling Water System No.1  Flushing - OR   ',  },
    { step: 9,  sn: 1, group: 'Pre-Comm', cat: 1, name: 'All On-Plot Tanks required for GPPE SU are Ready For Use',  },
    { step: 9,  sn: 2, group: 'Pre-Comm', cat: 1, name: 'CA1 / CA2 / Butene-1 / Hexene-1',  },
    { step: 9,  sn: 3, group: 'Pre-Comm', cat: 1, name: 'C3 Spheres',  },
    { step: 9,  sn: 4, group: 'Pre-Comm', cat: 1, name: 'All On-Plot Tanks required for BD/GHU SU are Ready For Use',  },
    { step: 9,  sn: 5, group: 'Pre-Comm', cat: 1, name: 'Ground Flare',  },
    { step: 9,  sn: 6, group: 'Pre-Comm', cat: 1, name: 'Cooling Water System No.1  CW Passivation',  },
    { step: 9,  sn: 7, group: 'Pre-Comm', cat: 1, name: 'Natural Gas To Boilers',  },
    { step: 9,  sn: 8, group: 'Pre-Comm', cat: 3, name: 'Co-Product Early Turnover for CW Flushing/Passivation ',  },
    { step: 9,  sn: 9, group: 'Pre-Comm', cat: 3, name: 'Cooling Water System No.2  Flushing - Coproduct  ',  },
    { step: 10, sn: 1, group: 'Pre-Comm', cat: 1, name: 'OR Substation 1 (25SS02)',  },
    { step: 10, sn: 2, group: 'Pre-Comm', cat: 1, name: 'U/G Fiber Optic Cable Pulled CCB to RIE 1',  },
    { step: 10, sn: 3, group: 'Pre-Comm', cat: 3, name: 'Cooling Water System No.2  Passivation - Co-Product',  },
    { step: 11, sn: 1, group: 'Pre-Comm', cat: 1, name: 'All On-Plot Tanks required for OR SU  Ready',  },
    { step: 11, sn: 2, group: 'Pre-Comm', cat: 1, name: 'RIE 1',  },
    { step: 11, sn: 3, group: 'Pre-Comm', cat: 1, name: 'OR Substation 2 (25SS02A)',  },
    { step: 11, sn: 4, group: 'Pre-Comm', cat: 1, name: 'Co-product Substation (36SS03)',  },
    { step: 11, sn: 5, group: 'Pre-Comm', cat: 1, name: 'U/G Fiber Optic  Cable Pulled CCB to RIE 2',  },
    { step: 12, sn: 1, group: 'Pre-Comm', cat: 1, name: 'RIE 2',  },

  ],
}