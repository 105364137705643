import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 1542,
    CanvasHeight: 1000,
    CanvasBorderWeight: 0.5,
    CanvasBorderColor: '#f5f5f5',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.2,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 860,
    CanvasChartHeight: 310,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: 'Transparent',
    CanvasChartFillColor: 'Transparent',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },

  // ----------------------------------------------- Filter Text
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },
  // -----------------------------------------------------------

  DataItems: [
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"36210",NO:1,DISC_DESCR:"Structure",UOM:"TONS",STAGE:"2",STEP:"10",ITEM_SEQ:"1",WCCODE_DESCR:"Topsides Prefabrication",PLAN_START_WK:"W2020_02",PLAN_FINISH_WK:"W2021_28",PLAN_START_DATE:"2020-01-10",PLAN_FINISH_DATE:"2021-07-16",FORECAST_START_WK:"W2020_02",FORECAST_FINISH_WK:"W2021_28",FORECAST_START_DATE:"2020-01-10",FORECAST_FINISH_DATE:"2021-07-16",ACTUAL_START_WK:"W2020_11",ACTUAL_START_DATE:"2020-03-13",INITIALQTY:1125.38,INITIALMANHOURS:71436,PLAN_VALUE:68985.7452,ACTUAL_VALUE:64828.17,PLAN_PROG:96.57,ACTUAL_PROG:90.75,PLAN_QTY:1086.8,ACTUAL_QTY:1021.3,REMAIN_QTY:104.1,C2_QTY:1120.4,EQV_QTY:1069.6},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"36220",NO:1,DISC_DESCR:"Structure",UOM:"TONS",STAGE:"2",STEP:"20",ITEM_SEQ:"2",WCCODE_DESCR:"Topsides Assembly/Erection",PLAN_START_WK:"W2020_33",PLAN_FINISH_WK:"W2021_42",PLAN_START_DATE:"2020-08-14",PLAN_FINISH_DATE:"2021-10-22",FORECAST_START_WK:"W2020_33",FORECAST_FINISH_WK:"W2021_42",FORECAST_START_DATE:"2020-08-14",FORECAST_FINISH_DATE:"2021-10-22",ACTUAL_START_WK:"W2020_29",ACTUAL_START_DATE:"2020-07-17",INITIALQTY:1134.38,INITIALMANHOURS:113908,PLAN_VALUE:90602.4232,ACTUAL_VALUE:82925.024,PLAN_PROG:79.54,ACTUAL_PROG:72.8,PLAN_QTY:902.3,ACTUAL_QTY:825.8,REMAIN_QTY:308.6,C2_QTY:1128.7,EQV_QTY:1012.5},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"36230",NO:1,DISC_DESCR:"Structure",UOM:"TONS",STAGE:"2",STEP:"30",ITEM_SEQ:"3",WCCODE_DESCR:"Topsides Tertiary",PLAN_START_WK:"W2021_16",PLAN_FINISH_WK:"W2021_38",PLAN_START_DATE:"2021-04-23",PLAN_FINISH_DATE:"2021-09-24",FORECAST_START_WK:"W2021_16",FORECAST_FINISH_WK:"W2021_38",FORECAST_START_DATE:"2021-04-23",FORECAST_FINISH_DATE:"2021-09-24",ACTUAL_START_WK:"W2021_09",ACTUAL_START_DATE:"2021-03-05",INITIALQTY:994.2,INITIALMANHOURS:28897,PLAN_VALUE:12648.2169,ACTUAL_VALUE:1427.5118,PLAN_PROG:43.76,ACTUAL_PROG:4.93,PLAN_QTY:435.1,ACTUAL_QTY:49,REMAIN_QTY:945.2,C2_QTY:34.3,EQV_QTY:9.4},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"13810",NO:2,DISC_DESCR:"Piping",UOM:"KG",STAGE:"8",STEP:"10",ITEM_SEQ:"1",WCCODE_DESCR:"Topsides Piping Support Fabrication",PLAN_START_WK:"W2020_53",PLAN_FINISH_WK:"W2021_18",PLAN_START_DATE:"2021-01-01",PLAN_FINISH_DATE:"2021-05-07",FORECAST_START_WK:"W2020_53",FORECAST_FINISH_WK:"W2021_18",FORECAST_START_DATE:"2021-01-01",FORECAST_FINISH_DATE:"2021-05-07",ACTUAL_START_WK:"W2021_01",ACTUAL_START_DATE:"2021-01-08",INITIALQTY:64274,INITIALMANHOURS:9862,PLAN_VALUE:9862,ACTUAL_VALUE:4788.9872,PLAN_PROG:100,ACTUAL_PROG:48.55,PLAN_QTY:64274,ACTUAL_QTY:31205,REMAIN_QTY:33069,C2_QTY:57270.8,EQV_QTY:32153.4},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"13820",NO:2,DISC_DESCR:"Piping",UOM:"KG",STAGE:"8",STEP:"20",ITEM_SEQ:"1",WCCODE_DESCR:"Topsides Piping Support Installation",PLAN_START_WK:"W2021_01",PLAN_FINISH_WK:"W2021_29",PLAN_START_DATE:"2021-01-08",PLAN_FINISH_DATE:"2021-07-23",FORECAST_START_WK:"W2021_01",FORECAST_FINISH_WK:"W2021_29",FORECAST_START_DATE:"2021-01-08",FORECAST_FINISH_DATE:"2021-07-23",ACTUAL_START_WK:"W2021_15",ACTUAL_START_DATE:"2021-04-16",INITIALQTY:64274,INITIALMANHOURS:8629,PLAN_VALUE:7872.2367,ACTUAL_VALUE:639.4089,PLAN_PROG:91.23,ACTUAL_PROG:7.41,PLAN_QTY:58637.2,ACTUAL_QTY:4762.7,REMAIN_QTY:59511.3,C2_QTY:113400.5,EQV_QTY:0},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"13410",NO:2,DISC_DESCR:"Piping",UOM:"DIA",STAGE:"4",STEP:"10",ITEM_SEQ:"2",WCCODE_DESCR:"Topsides Piping Fabrication",PLAN_START_WK:"W2021_04",PLAN_FINISH_WK:"W2021_37",PLAN_START_DATE:"2021-01-29",PLAN_FINISH_DATE:"2021-09-17",FORECAST_START_WK:"W2021_04",FORECAST_FINISH_WK:"W2021_37",FORECAST_START_DATE:"2021-01-29",FORECAST_FINISH_DATE:"2021-09-17",ACTUAL_START_WK:"W2021_07",ACTUAL_START_DATE:"2021-02-19",INITIALQTY:28669,INITIALMANHOURS:101627,PLAN_VALUE:62927.4384,ACTUAL_VALUE:34451.553,PLAN_PROG:61.91,ACTUAL_PROG:33.9,PLAN_QTY:17749,ACTUAL_QTY:9718.8,REMAIN_QTY:18950.2,C2_QTY:29771.7,EQV_QTY:10123.4},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"13420",NO:2,DISC_DESCR:"Piping",UOM:"DIA",STAGE:"4",STEP:"20",ITEM_SEQ:"2",WCCODE_DESCR:"Topsides Piping Installation",PLAN_START_WK:"W2021_11",PLAN_FINISH_WK:"W2022_04",PLAN_START_DATE:"2021-03-19",PLAN_FINISH_DATE:"2022-01-28",FORECAST_START_WK:"W2021_11",FORECAST_FINISH_WK:"W2022_04",FORECAST_START_DATE:"2021-03-19",FORECAST_FINISH_DATE:"2022-01-28",ACTUAL_START_WK:"W2021_18",ACTUAL_START_DATE:"2021-05-07",INITIALQTY:7592,INITIALMANHOURS:60128,PLAN_VALUE:20954.608,ACTUAL_VALUE:487.0368,PLAN_PROG:34.84,ACTUAL_PROG:0.81,PLAN_QTY:2645.1,ACTUAL_QTY:61.5,REMAIN_QTY:7530.5,C2_QTY:8358,EQV_QTY:86.8},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"13900",NO:2,DISC_DESCR:"Piping",UOM:"U",STAGE:"9",STEP:"00",ITEM_SEQ:"3",WCCODE_DESCR:"Pressure Test",PLAN_START_WK:"W2021_19",PLAN_FINISH_WK:"W2022_12",PLAN_START_DATE:"2021-05-14",PLAN_FINISH_DATE:"2022-03-25",FORECAST_START_WK:"W2021_19",FORECAST_FINISH_WK:"W2022_12",FORECAST_START_DATE:"2021-05-14",FORECAST_FINISH_DATE:"2022-03-25",INITIALQTY:1083,INITIALMANHOURS:14364,PLAN_VALUE:425.1744,ACTUAL_VALUE:0,PLAN_PROG:2.95,ACTUAL_PROG:0,PLAN_QTY:31.9,ACTUAL_QTY:0,REMAIN_QTY:1083,C2_QTY:10,EQV_QTY:0},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"16100",NO:3,DISC_DESCR:"Electrical",UOM:"U",STAGE:"1",STEP:"00",WCCODE_DESCR:"Grounding And Lightning System",PLAN_START_WK:"W2021_22",PLAN_FINISH_WK:"W2021_37",PLAN_START_DATE:"2021-06-04",PLAN_FINISH_DATE:"2021-09-17",FORECAST_START_WK:"W2021_22",FORECAST_FINISH_WK:"W2021_37",FORECAST_START_DATE:"2021-06-04",FORECAST_FINISH_DATE:"2021-09-17",INITIALQTY:8668,INITIALMANHOURS:9291,PLAN_VALUE:15.7947,ACTUAL_VALUE:0,PLAN_PROG:0.17,ACTUAL_PROG:0,PLAN_QTY:14.7,ACTUAL_QTY:0,REMAIN_QTY:8668,C2_QTY:6,EQV_QTY:0},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"16340",NO:3,DISC_DESCR:"Electrical",UOM:"U",STAGE:"3",STEP:"40",WCCODE_DESCR:"Power System Components - ( Panels, Junction Boxes)",PLAN_START_WK:"W2021_15",PLAN_FINISH_WK:"W2021_34",PLAN_START_DATE:"2021-04-16",PLAN_FINISH_DATE:"2021-08-27",FORECAST_START_WK:"W2021_15",FORECAST_FINISH_WK:"W2021_34",FORECAST_START_DATE:"2021-04-16",FORECAST_FINISH_DATE:"2021-08-27",INITIALQTY:537,INITIALMANHOURS:7848,PLAN_VALUE:3626.5608,ACTUAL_VALUE:0,PLAN_PROG:46.21,ACTUAL_PROG:0,PLAN_QTY:248.1,ACTUAL_QTY:0,REMAIN_QTY:537,C2_QTY:431,EQV_QTY:0},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"16800",NO:3,DISC_DESCR:"Electrical",UOM:"U",STAGE:"8",STEP:"00",WCCODE_DESCR:"Lighting System",PLAN_START_WK:"W2021_20",PLAN_FINISH_WK:"W2021_38",PLAN_START_DATE:"2021-05-21",PLAN_FINISH_DATE:"2021-09-24",FORECAST_START_WK:"W2021_20",FORECAST_FINISH_WK:"W2021_38",FORECAST_START_DATE:"2021-05-21",FORECAST_FINISH_DATE:"2021-09-24",INITIALQTY:9333,INITIALMANHOURS:10510,PLAN_VALUE:16.816,ACTUAL_VALUE:0,PLAN_PROG:0.15,ACTUAL_PROG:0,PLAN_QTY:14,ACTUAL_QTY:0,REMAIN_QTY:9333,C2_QTY:29,EQV_QTY:0},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"16500",NO:3,DISC_DESCR:"Electrical",UOM:"U",STAGE:"5",STEP:"00",WCCODE_DESCR:"Electrical Heat Tracing System",PLAN_START_WK:"W2021_22",PLAN_FINISH_WK:"W2022_13",PLAN_START_DATE:"2021-06-04",PLAN_FINISH_DATE:"2022-04-01",FORECAST_START_WK:"W2021_22",FORECAST_FINISH_WK:"W2022_13",FORECAST_START_DATE:"2021-06-04",FORECAST_FINISH_DATE:"2022-04-01",INITIALQTY:19532,INITIALMANHOURS:7279,PLAN_VALUE:288.9763,ACTUAL_VALUE:0,PLAN_PROG:3.96,ACTUAL_PROG:0,PLAN_QTY:773.5,ACTUAL_QTY:0,REMAIN_QTY:19532,C2_QTY:383,EQV_QTY:0},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"16460",NO:3,DISC_DESCR:"Electrical",UOM:"KG",STAGE:"4",STEP:"60",ITEM_SEQ:"1",WCCODE_DESCR:"Eict Mega Block Outfittings Installation",PLAN_START_WK:"W2021_12",PLAN_FINISH_WK:"W2021_37",PLAN_START_DATE:"2021-03-26",PLAN_FINISH_DATE:"2021-09-17",FORECAST_START_WK:"W2021_12",FORECAST_FINISH_WK:"W2021_37",FORECAST_START_DATE:"2021-03-26",FORECAST_FINISH_DATE:"2021-09-17",ACTUAL_START_WK:"W2021_24",ACTUAL_START_DATE:"2021-06-18",INITIALQTY:22720,INITIALMANHOURS:3022,PLAN_VALUE:2358.9732,ACTUAL_VALUE:74.039,PLAN_PROG:78.06,ACTUAL_PROG:2.45,PLAN_QTY:17735.2,ACTUAL_QTY:556.6,REMAIN_QTY:22163.4,C2_QTY:22720,EQV_QTY:556.6},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"16450",NO:3,DISC_DESCR:"Electrical",UOM:"KG",STAGE:"4",STEP:"50",ITEM_SEQ:"1",WCCODE_DESCR:"Eict Mega Block Outfittings Fabrication",PLAN_START_WK:"W2021_08",PLAN_FINISH_WK:"W2021_34",PLAN_START_DATE:"2021-02-26",PLAN_FINISH_DATE:"2021-08-27",FORECAST_START_WK:"W2021_08",FORECAST_FINISH_WK:"W2021_34",FORECAST_START_DATE:"2021-02-26",FORECAST_FINISH_DATE:"2021-08-27",ACTUAL_START_WK:"W2021_13",ACTUAL_START_DATE:"2021-04-02",INITIALQTY:22720,INITIALMANHOURS:1360,PLAN_VALUE:1294.992,ACTUAL_VALUE:515.304,PLAN_PROG:95.21,ACTUAL_PROG:37.88,PLAN_QTY:21631.7,ACTUAL_QTY:8606.3,REMAIN_QTY:14113.7,C2_QTY:22720,EQV_QTY:8608},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"16430",NO:3,DISC_DESCR:"Electrical",UOM:"LM",STAGE:"4",STEP:"30",ITEM_SEQ:"2",WCCODE_DESCR:"Eict Area Outfittings Installation",PLAN_START_WK:"W2021_20",PLAN_FINISH_WK:"W2021_35",PLAN_START_DATE:"2021-05-21",PLAN_FINISH_DATE:"2021-09-03",FORECAST_START_WK:"W2021_20",FORECAST_FINISH_WK:"W2021_35",FORECAST_START_DATE:"2021-05-21",FORECAST_FINISH_DATE:"2021-09-03",INITIALQTY:5065,INITIALMANHOURS:5065,PLAN_VALUE:750.633,ACTUAL_VALUE:0,PLAN_PROG:14.82,ACTUAL_PROG:0,PLAN_QTY:750.6,ACTUAL_QTY:0,REMAIN_QTY:5065,C2_QTY:5065,EQV_QTY:0},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"16320",NO:3,DISC_DESCR:"Electrical",UOM:"LM",STAGE:"3",STEP:"20",ITEM_SEQ:"3",WCCODE_DESCR:"Power System - Cables",PLAN_START_WK:"W2021_27",PLAN_FINISH_WK:"W2021_35",PLAN_START_DATE:"2021-07-09",PLAN_FINISH_DATE:"2021-09-03",FORECAST_START_WK:"W2021_27",FORECAST_FINISH_WK:"W2021_35",FORECAST_START_DATE:"2021-07-09",FORECAST_FINISH_DATE:"2021-09-03",INITIALQTY:252,INITIALMANHOURS:76,PLAN_VALUE:0,ACTUAL_VALUE:0,PLAN_PROG:0,ACTUAL_PROG:0,PLAN_QTY:0,ACTUAL_QTY:0,REMAIN_QTY:252,C2_QTY:17470,EQV_QTY:0},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"16330",NO:3,DISC_DESCR:"Electrical",UOM:"U",STAGE:"3",STEP:"30",ITEM_SEQ:"4",WCCODE_DESCR:"Power System - Cables Connections",PLAN_START_WK:"W2021_30",PLAN_FINISH_WK:"W2021_38",PLAN_START_DATE:"2021-07-30",PLAN_FINISH_DATE:"2021-09-24",FORECAST_START_WK:"W2021_30",FORECAST_FINISH_WK:"W2021_38",FORECAST_START_DATE:"2021-07-30",FORECAST_FINISH_DATE:"2021-09-24",INITIALQTY:202,INITIALMANHOURS:303,PLAN_VALUE:0,ACTUAL_VALUE:0,PLAN_PROG:0,ACTUAL_PROG:0,PLAN_QTY:0,ACTUAL_QTY:0,REMAIN_QTY:202,C2_QTY:216,EQV_QTY:0},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"16220",NO:3,DISC_DESCR:"Electrical",UOM:"U",STAGE:"2",STEP:"20",ITEM_SEQ:"5",WCCODE_DESCR:"Substation/Electrical Equipment",PLAN_START_WK:"W2021_19",PLAN_FINISH_WK:"W2021_20",PLAN_START_DATE:"2021-05-14",PLAN_FINISH_DATE:"2021-05-21",FORECAST_START_WK:"W2021_19",FORECAST_FINISH_WK:"W2021_20",FORECAST_START_DATE:"2021-05-14",FORECAST_FINISH_DATE:"2021-05-21",INITIALQTY:1,INITIALMANHOURS:400,PLAN_VALUE:400,ACTUAL_VALUE:0,PLAN_PROG:100,ACTUAL_PROG:0,PLAN_QTY:1,ACTUAL_QTY:0,REMAIN_QTY:1,C2_QTY:1,EQV_QTY:0},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"15600",NO:4,DISC_DESCR:"Instrument",UOM:"U",STAGE:"6",STEP:"00",WCCODE_DESCR:"Analyzers Installation",PLAN_START_WK:"W2021_20",PLAN_FINISH_WK:"W2021_32",PLAN_START_DATE:"2021-05-21",PLAN_FINISH_DATE:"2021-08-13",FORECAST_START_WK:"W2021_20",FORECAST_FINISH_WK:"W2021_32",FORECAST_START_DATE:"2021-05-21",FORECAST_FINISH_DATE:"2021-08-13",INITIALQTY:14,INITIALMANHOURS:56,PLAN_VALUE:20.6024,ACTUAL_VALUE:0,PLAN_PROG:36.78,ACTUAL_PROG:0,PLAN_QTY:5.1,ACTUAL_QTY:0,REMAIN_QTY:14,C2_QTY:12,EQV_QTY:0},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"15700",NO:4,DISC_DESCR:"Instrument",UOM:"U",STAGE:"7",STEP:"00",WCCODE_DESCR:"Telecommunication Systems Installation Works",PLAN_START_WK:"W2021_20",PLAN_FINISH_WK:"W2021_32",PLAN_START_DATE:"2021-05-21",PLAN_FINISH_DATE:"2021-08-13",FORECAST_START_WK:"W2021_20",FORECAST_FINISH_WK:"W2021_32",FORECAST_START_DATE:"2021-05-21",FORECAST_FINISH_DATE:"2021-08-13",INITIALQTY:94,INITIALMANHOURS:94,PLAN_VALUE:34.6014,ACTUAL_VALUE:0,PLAN_PROG:36.8,ACTUAL_PROG:0,PLAN_QTY:34.6,ACTUAL_QTY:0,REMAIN_QTY:94,C2_QTY:113,EQV_QTY:0},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"15220",NO:4,DISC_DESCR:"Instrument",UOM:"LM",STAGE:"2",STEP:"20",ITEM_SEQ:"2",WCCODE_DESCR:"Instrument Cables",PLAN_START_WK:"W2021_29",PLAN_FINISH_WK:"W2021_36",PLAN_START_DATE:"2021-07-23",PLAN_FINISH_DATE:"2021-09-10",FORECAST_START_WK:"W2021_29",FORECAST_FINISH_WK:"W2021_36",FORECAST_START_DATE:"2021-07-23",FORECAST_FINISH_DATE:"2021-09-10",INITIALQTY:19110,INITIALMANHOURS:5733,PLAN_VALUE:0,ACTUAL_VALUE:0,PLAN_PROG:0,ACTUAL_PROG:0,PLAN_QTY:0,ACTUAL_QTY:0,REMAIN_QTY:19110,C2_QTY:30210,EQV_QTY:0},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"15230",NO:4,DISC_DESCR:"Instrument",UOM:"U",STAGE:"2",STEP:"30",ITEM_SEQ:"3",WCCODE_DESCR:"Instrument Cables - Connections",PLAN_START_WK:"W2021_30",PLAN_FINISH_WK:"W2021_39",PLAN_START_DATE:"2021-07-30",PLAN_FINISH_DATE:"2021-10-01",FORECAST_START_WK:"W2021_30",FORECAST_FINISH_WK:"W2021_39",FORECAST_START_DATE:"2021-07-30",FORECAST_FINISH_DATE:"2021-10-01",INITIALQTY:15645,INITIALMANHOURS:17210,PLAN_VALUE:0,ACTUAL_VALUE:0,PLAN_PROG:0,ACTUAL_PROG:0,PLAN_QTY:0,ACTUAL_QTY:0,REMAIN_QTY:15645,C2_QTY:808,EQV_QTY:0},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"15310",NO:4,DISC_DESCR:"Instrument",UOM:"U",STAGE:"3",STEP:"10",ITEM_SEQ:"4",WCCODE_DESCR:"Field Instrumentation Works",PLAN_START_WK:"W2021_20",PLAN_FINISH_WK:"W2022_11",PLAN_START_DATE:"2021-05-21",PLAN_FINISH_DATE:"2022-03-18",FORECAST_START_WK:"W2021_20",FORECAST_FINISH_WK:"W2022_11",FORECAST_START_DATE:"2021-05-21",FORECAST_FINISH_DATE:"2022-03-18",ACTUAL_START_WK:"W2021_16",ACTUAL_START_DATE:"2021-04-23",INITIALQTY:954,INITIALMANHOURS:1378,PLAN_VALUE:64.766,ACTUAL_VALUE:0.5512,PLAN_PROG:4.7,ACTUAL_PROG:0.04,PLAN_QTY:44.8,ACTUAL_QTY:0.4,REMAIN_QTY:953.6,C2_QTY:199,EQV_QTY:0.6000000000000001},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"15350",NO:4,DISC_DESCR:"Instrument",UOM:"U",STAGE:"3",STEP:"50",ITEM_SEQ:"4",WCCODE_DESCR:"Field On-Line Instrumentation Works",PLAN_START_WK:"W2021_21",PLAN_FINISH_WK:"W2022_11",PLAN_START_DATE:"2021-05-28",PLAN_FINISH_DATE:"2022-03-18",FORECAST_START_WK:"W2021_21",FORECAST_FINISH_WK:"W2022_11",FORECAST_START_DATE:"2021-05-28",FORECAST_FINISH_DATE:"2022-03-18",INITIALQTY:2217,INITIALMANHOURS:1325,PLAN_VALUE:37.7625,ACTUAL_VALUE:0,PLAN_PROG:2.85,ACTUAL_PROG:0,PLAN_QTY:63.2,ACTUAL_QTY:0,REMAIN_QTY:2217,C2_QTY:286,EQV_QTY:1},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"15240",NO:4,DISC_DESCR:"Instrument",UOM:"U",STAGE:"2",STEP:"40",ITEM_SEQ:"5",WCCODE_DESCR:"Junction Box And Loc. Panels Installation",PLAN_START_WK:"W2021_24",PLAN_FINISH_WK:"W2021_32",PLAN_START_DATE:"2021-06-18",PLAN_FINISH_DATE:"2021-08-13",FORECAST_START_WK:"W2021_24",FORECAST_FINISH_WK:"W2021_32",FORECAST_START_DATE:"2021-06-18",FORECAST_FINISH_DATE:"2021-08-13",INITIALQTY:42,INITIALMANHOURS:504,PLAN_VALUE:50.4,ACTUAL_VALUE:0,PLAN_PROG:10,ACTUAL_PROG:0,PLAN_QTY:4.2,ACTUAL_QTY:0,REMAIN_QTY:42,C2_QTY:115,EQV_QTY:0},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"68110",NO:7,DISC_DESCR:"Mechanical",UOM:"U",STAGE:"1",STEP:"10",WCCODE_DESCR:"Heavy Lifts In One Piece",PLAN_START_WK:"W2021_11",PLAN_FINISH_WK:"W2021_24",PLAN_START_DATE:"2021-03-19",PLAN_FINISH_DATE:"2021-06-18",FORECAST_START_WK:"W2021_11",FORECAST_FINISH_WK:"W2021_24",FORECAST_START_DATE:"2021-03-19",FORECAST_FINISH_DATE:"2021-06-18",ACTUAL_START_WK:"W2021_20",ACTUAL_START_DATE:"2021-05-21",INITIALQTY:3,INITIALMANHOURS:1800,PLAN_VALUE:1800,ACTUAL_VALUE:1200.06,PLAN_PROG:100,ACTUAL_PROG:66.67,PLAN_QTY:3,ACTUAL_QTY:2,REMAIN_QTY:1,C2_QTY:3,EQV_QTY:2},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"68610",NO:7,DISC_DESCR:"Mechanical",UOM:"U",STAGE:"6",STEP:"10",WCCODE_DESCR:"Trays & Internals Installation",PLAN_START_WK:"W2021_11",PLAN_FINISH_WK:"W2021_29",PLAN_START_DATE:"2021-03-19",PLAN_FINISH_DATE:"2021-07-23",FORECAST_START_WK:"W2021_11",FORECAST_FINISH_WK:"W2021_29",FORECAST_START_DATE:"2021-03-19",FORECAST_FINISH_DATE:"2021-07-23",INITIALQTY:3,INITIALMANHOURS:270,PLAN_VALUE:209.412,ACTUAL_VALUE:0,PLAN_PROG:77.56,ACTUAL_PROG:0,PLAN_QTY:2.3,ACTUAL_QTY:0,REMAIN_QTY:3},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"68210",NO:7,DISC_DESCR:"Mechanical",UOM:"U",STAGE:"2",STEP:"10",ITEM_SEQ:"1",WCCODE_DESCR:"Vertical And Horizontal Vessels/Exchangers",PLAN_START_WK:"W2021_12",PLAN_FINISH_WK:"W2021_26",PLAN_START_DATE:"2021-03-26",PLAN_FINISH_DATE:"2021-07-02",FORECAST_START_WK:"W2021_12",FORECAST_FINISH_WK:"W2021_26",FORECAST_START_DATE:"2021-03-26",FORECAST_FINISH_DATE:"2021-07-02",ACTUAL_START_WK:"W2021_14",ACTUAL_START_DATE:"2021-04-09",INITIALQTY:7,INITIALMANHOURS:2800,PLAN_VALUE:2133.32,ACTUAL_VALUE:1539.72,PLAN_PROG:76.18,ACTUAL_PROG:54.99,PLAN_QTY:5.3,ACTUAL_QTY:3.8,REMAIN_QTY:3.2,C2_QTY:7,EQV_QTY:3.9},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"68500",NO:7,DISC_DESCR:"Mechanical",UOM:"U",STAGE:"5",STEP:"00",ITEM_SEQ:"2",WCCODE_DESCR:"Machinery Erection - Rotating",PLAN_START_WK:"W2021_13",PLAN_FINISH_WK:"W2021_16",PLAN_START_DATE:"2021-04-02",PLAN_FINISH_DATE:"2021-04-23",FORECAST_START_WK:"W2021_13",FORECAST_FINISH_WK:"W2021_16",FORECAST_START_DATE:"2021-04-02",FORECAST_FINISH_DATE:"2021-04-23",ACTUAL_START_WK:"W2021_20",ACTUAL_START_DATE:"2021-05-21",INITIALQTY:2,INITIALMANHOURS:1600,PLAN_VALUE:1600,ACTUAL_VALUE:1584,PLAN_PROG:100,ACTUAL_PROG:99,PLAN_QTY:2,ACTUAL_QTY:2,REMAIN_QTY:0,C2_QTY:2,EQV_QTY:1.8},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"68810",NO:7,DISC_DESCR:"Mechanical",UOM:"U",STAGE:"8",STEP:"10",ITEM_SEQ:"3",WCCODE_DESCR:"Skid, Packages & Misc.",PLAN_START_WK:"W2021_12",PLAN_FINISH_WK:"W2021_13",PLAN_START_DATE:"2021-03-26",PLAN_FINISH_DATE:"2021-04-02",FORECAST_START_WK:"W2021_12",FORECAST_FINISH_WK:"W2021_13",FORECAST_START_DATE:"2021-03-26",FORECAST_FINISH_DATE:"2021-04-02",ACTUAL_START_WK:"W2021_15",ACTUAL_FINISH_WK:"W2021_20",ACTUAL_START_DATE:"2021-04-16",ACTUAL_FINISH_DATE:"2021-05-21",INITIALQTY:1,INITIALMANHOURS:600,PLAN_VALUE:600,ACTUAL_VALUE:600,PLAN_PROG:100,ACTUAL_PROG:100,PLAN_QTY:1,ACTUAL_QTY:1,REMAIN_QTY:0,C2_QTY:3,EQV_QTY:2.8},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"23110",NO:8,DISC_DESCR:"Painting",UOM:"SQM",STAGE:"1",STEP:"10",ITEM_SEQ:"1",WCCODE_DESCR:"Steel Struct. Painting",PLAN_START_WK:"W2020_52",PLAN_FINISH_WK:"W2021_32",PLAN_START_DATE:"2020-12-25",PLAN_FINISH_DATE:"2021-08-13",FORECAST_START_WK:"W2020_52",FORECAST_FINISH_WK:"W2021_32",FORECAST_START_DATE:"2020-12-25",FORECAST_FINISH_DATE:"2021-08-13",ACTUAL_START_WK:"W2020_48",ACTUAL_START_DATE:"2020-11-27",INITIALQTY:23810.67,INITIALMANHOURS:41500,PLAN_VALUE:27381.7,ACTUAL_VALUE:31473.6,PLAN_PROG:65.98,ACTUAL_PROG:75.84,PLAN_QTY:15710.3,ACTUAL_QTY:18058,REMAIN_QTY:5752.7,C2_QTY:23611.7,EQV_QTY:19578.8},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"23120",NO:8,DISC_DESCR:"Painting",UOM:"SQM",STAGE:"1",STEP:"20",ITEM_SEQ:"1",WCCODE_DESCR:"Area Painting",PLAN_START_WK:"W2021_24",PLAN_FINISH_WK:"W2022_14",PLAN_START_DATE:"2021-06-18",PLAN_FINISH_DATE:"2022-04-08",FORECAST_START_WK:"W2021_24",FORECAST_FINISH_WK:"W2022_14",FORECAST_START_DATE:"2021-06-18",FORECAST_FINISH_DATE:"2022-04-08",ACTUAL_START_WK:"W2021_24",ACTUAL_START_DATE:"2021-06-18",INITIALQTY:1384.8,INITIALMANHOURS:12463,PLAN_VALUE:535.909,ACTUAL_VALUE:124.63,PLAN_PROG:4.29,ACTUAL_PROG:1,PLAN_QTY:59.4,ACTUAL_QTY:13.8,REMAIN_QTY:1371,C2_QTY:1384.7,EQV_QTY:13.8},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"23210",NO:8,DISC_DESCR:"Painting",UOM:"SQM",STAGE:"2",STEP:"10",ITEM_SEQ:"2",WCCODE_DESCR:"Piping Support Painting",PLAN_START_WK:"W2021_03",PLAN_FINISH_WK:"W2021_36",PLAN_START_DATE:"2021-01-22",PLAN_FINISH_DATE:"2021-09-10",FORECAST_START_WK:"W2021_03",FORECAST_FINISH_WK:"W2021_36",FORECAST_START_DATE:"2021-01-22",FORECAST_FINISH_DATE:"2021-09-10",ACTUAL_START_WK:"W2021_13",ACTUAL_START_DATE:"2021-04-02",INITIALQTY:3003.1,INITIALMANHOURS:9010,PLAN_VALUE:8792.859,ACTUAL_VALUE:1250.588,PLAN_PROG:97.59,ACTUAL_PROG:13.88,PLAN_QTY:2930.7,ACTUAL_QTY:416.8,REMAIN_QTY:2586.3,C2_QTY:3003,EQV_QTY:703.5},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"23200",NO:8,DISC_DESCR:"Painting",UOM:"SQM",STAGE:"2",STEP:"00",ITEM_SEQ:"2",WCCODE_DESCR:"Piping Painting",PLAN_START_WK:"W2021_10",PLAN_FINISH_WK:"W2021_39",PLAN_START_DATE:"2021-03-12",PLAN_FINISH_DATE:"2021-10-01",FORECAST_START_WK:"W2021_10",FORECAST_FINISH_WK:"W2021_39",FORECAST_START_DATE:"2021-03-12",FORECAST_FINISH_DATE:"2021-10-01",ACTUAL_START_WK:"W2021_16",ACTUAL_START_DATE:"2021-04-23",INITIALQTY:4404.1,INITIALMANHOURS:13211,PLAN_VALUE:7095.6281,ACTUAL_VALUE:1510.0173,PLAN_PROG:53.71,ACTUAL_PROG:11.42,PLAN_QTY:2365.4,ACTUAL_QTY:502.9,REMAIN_QTY:3901.2,C2_QTY:3052,EQV_QTY:575.7},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"23500",NO:8,DISC_DESCR:"Painting",UOM:"SQM",STAGE:"5",STEP:"00",ITEM_SEQ:"3",WCCODE_DESCR:"Passive Fire Protection",PLAN_START_WK:"W2021_01",PLAN_FINISH_WK:"W2021_25",PLAN_START_DATE:"2021-01-08",PLAN_FINISH_DATE:"2021-06-25",FORECAST_START_WK:"W2021_01",FORECAST_FINISH_WK:"W2021_25",FORECAST_START_DATE:"2021-01-08",FORECAST_FINISH_DATE:"2021-06-25",INITIALQTY:3780,INITIALMANHOURS:34024,PLAN_VALUE:32962.4512,ACTUAL_VALUE:0,PLAN_PROG:96.88,ACTUAL_PROG:0,PLAN_QTY:3662.1,ACTUAL_QTY:0,REMAIN_QTY:3780,C2_QTY:704.8,EQV_QTY:0},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"22110",NO:9,DISC_DESCR:"Insulation",UOM:"SQM",STAGE:"1",STEP:"10",ITEM_SEQ:"1",WCCODE_DESCR:"Equipment Insulation Works",PLAN_START_WK:"W2021_32",PLAN_FINISH_WK:"W2021_37",PLAN_START_DATE:"2021-08-13",PLAN_FINISH_DATE:"2021-09-17",FORECAST_START_WK:"W2021_32",FORECAST_FINISH_WK:"W2021_37",FORECAST_START_DATE:"2021-08-13",FORECAST_FINISH_DATE:"2021-09-17",INITIALQTY:459,INITIALMANHOURS:3119,PLAN_VALUE:0,ACTUAL_VALUE:0,PLAN_PROG:0,ACTUAL_PROG:0,PLAN_QTY:0,ACTUAL_QTY:0,REMAIN_QTY:459,C2_QTY:4,EQV_QTY:0},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"22210",NO:9,DISC_DESCR:"Insulation",UOM:"SQM",STAGE:"2",STEP:"10",ITEM_SEQ:"2",WCCODE_DESCR:"Piping Insulation Works",PLAN_START_WK:"W2022_08",PLAN_FINISH_WK:"W2022_13",PLAN_START_DATE:"2022-02-25",PLAN_FINISH_DATE:"2022-04-01",FORECAST_START_WK:"W2022_08",FORECAST_FINISH_WK:"W2022_13",FORECAST_START_DATE:"2022-02-25",FORECAST_FINISH_DATE:"2022-04-01",INITIALQTY:3567.3,INITIALMANHOURS:22564,PLAN_VALUE:0,ACTUAL_VALUE:0,PLAN_PROG:0,ACTUAL_PROG:0,PLAN_QTY:0,ACTUAL_QTY:0,REMAIN_QTY:3567.3},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"61100",NO:10,DISC_DESCR:"Fire & Safety",UOM:"U",STAGE:"1",STEP:"00",ITEM_SEQ:"1",WCCODE_DESCR:"Fire Protection System",PLAN_START_WK:"W2021_29",PLAN_FINISH_WK:"W2022_18",PLAN_START_DATE:"2021-07-23",PLAN_FINISH_DATE:"2022-05-06",FORECAST_START_WK:"W2021_29",FORECAST_FINISH_WK:"W2022_18",FORECAST_START_DATE:"2021-07-23",FORECAST_FINISH_DATE:"2022-05-06",INITIALQTY:24.41,INITIALMANHOURS:320,PLAN_VALUE:0,ACTUAL_VALUE:0,PLAN_PROG:0,ACTUAL_PROG:0,PLAN_QTY:0,ACTUAL_QTY:0,REMAIN_QTY:24.4,C2_QTY:18,EQV_QTY:0},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",WCCODE:"61200",NO:10,DISC_DESCR:"Fire & Safety",UOM:"U",STAGE:"2",STEP:"00",ITEM_SEQ:"2",WCCODE_DESCR:"Safety Equipment",PLAN_START_WK:"W2022_16",PLAN_FINISH_WK:"W2022_20",PLAN_START_DATE:"2022-04-22",PLAN_FINISH_DATE:"2022-05-20",FORECAST_START_WK:"W2022_16",FORECAST_FINISH_WK:"W2022_20",FORECAST_START_DATE:"2022-04-22",FORECAST_FINISH_DATE:"2022-05-20",INITIALQTY:129,INITIALMANHOURS:435,PLAN_VALUE:0,ACTUAL_VALUE:0,PLAN_PROG:0,ACTUAL_PROG:0,PLAN_QTY:0,ACTUAL_QTY:0,REMAIN_QTY:129,C2_QTY:77,EQV_QTY:0}
  ]
}