import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 2300,
      CanvasHeight: 10000,
      CanvasBorderWeight: 0,
      CanvasBorderColor: 'Transparent',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 860,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: 'Transparent',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },



    DataItems: [
      { MOD: '42M057', DESC: 'OGP 2nd Stage WSG - Train 1', OV_M_PP: 98.4, OV_A_PP: 97.8, ST010_ESD: '2018-01-25', ST010_EFD: '2018-12-12', ST010_P: 100, ST010_ASD: '2017-11-09', ST010_AFD: '2018-12-12', ST010_A: 100, ST030_ESD: '2018-02-01', ST030_EFD: '2018-12-27', ST030_P: 100, ST030_ASD: '2018-02-01', ST030_AFD: '2018-12-27', ST030_A: 100, ST080_ESD: '2018-06-18', ST080_EFD: '2019-05-16', ST080_P: 100, ST080_ASD: '2018-06-18', ST080_AFD: '2019-05-24', ST080_A: 100, PI030_ESD: '2018-06-25', PI030_EFD: '2019-09-02', PI030_P: 100, PI030_ASD: '2018-06-25', PI030_AFD: '2019-11-15', PI030_A: 100, EI060_ESD: '2018-12-21', EI060_EFD: '2019-10-02', EI060_P: 100, EI060_ASD: '2018-12-21', EI060_AFD: '2019-11-15', EI060_A: 100, MC_ESD: '2018-05-28', MC_EFD: '2019-10-31', MC_P: 100, MC_ASD: '2018-05-28', MC_AFD: '2019-12-13', MC_A: 100, OT090_ESD: '2020-01-04', OT090_EFD: '2020-01-04', OT090_P: 0, OT090_ASD: 'NULL', OT090_AFD: 'NULL', OT090_A: 0, A1_T: 11942, A1_A: 11394, A1_O: 408, A1_PG: 96.5, B1_T: 1379, B1_A: 1185, B1_O: 185, B1_PG: 86.5, PA: 19, PB: 54, },
      { MOD: '42M065', DESC: 'OGP Mercaptan Extraction', OV_M_PP: 94.4, OV_A_PP: 91.1, ST010_ESD: '2018-04-26', ST010_EFD: '2019-03-01', ST010_P: 100, ST010_ASD: '2017-10-27', ST010_AFD: '2019-01-05', ST010_A: 100, ST030_ESD: '2018-05-08', ST030_EFD: '2019-01-25', ST030_P: 100, ST030_ASD: '2018-05-08', ST030_AFD: '2019-01-25', ST030_A: 100, ST080_ESD: '2018-08-24', ST080_EFD: '2019-05-20', ST080_P: 100, ST080_ASD: '2018-08-31', ST080_AFD: '2019-05-31', ST080_A: 100, PI030_ESD: '2018-09-29', PI030_EFD: '2019-10-14', PI030_P: 100, PI030_ASD: '2018-09-29', PI030_AFD: '2019-11-29', PI030_A: 100, EI060_ESD: '2019-03-06', EI060_EFD: '2019-11-11', EI060_P: 100, EI060_ASD: '2019-03-06', EI060_AFD: '2019-11-29', EI060_A: 100, MC_ESD: '2018-08-16', MC_EFD: '2019-12-09', MC_P: 100, MC_ASD: '2018-08-16', MC_AFD: 'NULL', MC_A: 86, OT090_ESD: '2020-01-08', OT090_EFD: '2020-01-08', OT090_P: 0, OT090_ASD: 'NULL', OT090_AFD: 'NULL', OT090_A: 0, A1_T: 17816, A1_A: 14406, A1_O: 3103, A1_PG: 82.3, B1_T: 1474, B1_A: 1146, B1_O: 328, B1_PG: 77.7, PA: 237, PB: 295, },
      { MOD: '42M078', DESC: 'Common Regen Gas Compressor', OV_M_PP: 97, OV_A_PP: 95.2, ST010_ESD: '2018-04-26', ST010_EFD: '2018-12-19', ST010_P: 100, ST010_ASD: '2017-11-13', ST010_AFD: '2018-12-19', ST010_A: 100, ST030_ESD: '2018-05-09', ST030_EFD: '2019-01-22', ST030_P: 100, ST030_ASD: '2018-05-09', ST030_AFD: '2019-01-22', ST030_A: 100, ST080_ESD: '2018-08-21', ST080_EFD: '2019-05-17', ST080_P: 100, ST080_ASD: '2018-08-21', ST080_AFD: '2019-05-20', ST080_A: 100, PI030_ESD: '2018-09-26', PI030_EFD: '2019-09-19', PI030_P: 100, PI030_ASD: '2018-09-26', PI030_AFD: '2019-11-01', PI030_A: 100, EI060_ESD: '2019-04-01', EI060_EFD: '2019-10-18', EI060_P: 100, EI060_ASD: '2019-04-18', EI060_AFD: '2019-10-18', EI060_A: 100, MC_ESD: '2018-07-23', MC_EFD: '2019-11-15', MC_P: 100, MC_ASD: '2018-07-23', MC_AFD: '2019-12-13', MC_A: 100, OT090_ESD: '2020-01-08', OT090_EFD: '2020-01-08', OT090_P: 0, OT090_ASD: 'NULL', OT090_AFD: 'NULL', OT090_A: 0, A1_T: 11683, A1_A: 11398, A1_O: 33, A1_PG: 99.7, B1_T: 1583, B1_A: 1423, B1_O: 160, B1_PG: 89.9, PA: 19, PB: 56, },
      { MOD: '41M008', DESC: 'KTL 1 Gas Air Coolers', OV_M_PP: 90.3, OV_A_PP: 89.7, ST010_ESD: '2018-11-09', ST010_EFD: '2019-03-11', ST010_P: 100, ST010_ASD: '2018-11-09', ST010_AFD: '2019-03-11', ST010_A: 100, ST030_ESD: '2018-11-20', ST030_EFD: '2019-04-24', ST030_P: 100, ST030_ASD: '2018-11-20', ST030_AFD: '2019-04-19', ST030_A: 100, ST080_ESD: '2019-03-28', ST080_EFD: '2019-07-08', ST080_P: 100, ST080_ASD: '2019-03-28', ST080_AFD: '2019-07-17', ST080_A: 100, PI030_ESD: '2019-03-27', PI030_EFD: '2019-09-26', PI030_P: 100, PI030_ASD: '2019-03-27', PI030_AFD: '2019-11-22', PI030_A: 100, EI060_ESD: '2019-07-15', EI060_EFD: '2019-10-25', EI060_P: 100, EI060_ASD: '2019-07-11', EI060_AFD: '2019-10-22', EI060_A: 100, MC_ESD: '2019-02-12', MC_EFD: '2019-11-22', MC_P: 100, MC_ASD: '2019-02-12', MC_AFD: 'NULL', MC_A: 95.5, OT090_ESD: '2020-01-13', OT090_EFD: '2020-01-13', OT090_P: 0, OT090_ASD: 'NULL', OT090_AFD: 'NULL', OT090_A: 0, A1_T: 4723, A1_A: 4426, A1_O: 206, A1_PG: 95.6, B1_T: 83, B1_A: 46, B1_O: 37, B1_PG: 55.4, PA: 127, PB: 104, },
      { MOD: '41M010', DESC: 'Gas Air Coolers Train 5', OV_M_PP: 88.7, OV_A_PP: 85.8, ST010_ESD: '2018-10-24', ST010_EFD: '2019-02-19', ST010_P: 100, ST010_ASD: '2018-10-24', ST010_AFD: '2019-01-18', ST010_A: 100, ST030_ESD: '2018-10-31', ST030_EFD: '2019-02-21', ST030_P: 100, ST030_ASD: '2018-10-31', ST030_AFD: '2019-02-21', ST030_A: 100, ST080_ESD: '2019-02-28', ST080_EFD: '2019-05-31', ST080_P: 100, ST080_ASD: '2019-03-01', ST080_AFD: '2019-06-14', ST080_A: 100, PI030_ESD: '2019-03-11', PI030_EFD: '2019-10-04', PI030_P: 100, PI030_ASD: '2019-03-11', PI030_AFD: 'NULL', PI030_A: 99.9, EI060_ESD: '2019-06-03', EI060_EFD: '2019-11-01', EI060_P: 100, EI060_ASD: '2019-07-11', EI060_AFD: '2019-11-19', EI060_A: 100, MC_ESD: '2018-12-26', MC_EFD: '2019-11-29', MC_P: 100, MC_ASD: '2018-12-26', MC_AFD: 'NULL', MC_A: 87.7, OT090_ESD: '2020-01-20', OT090_EFD: '2020-01-20', OT090_P: 0, OT090_ASD: 'NULL', OT090_AFD: 'NULL', OT090_A: 0, A1_T: 3639, A1_A: 2402, A1_O: 1175, A1_PG: 67.2, B1_T: 83, B1_A: 37, B1_O: 46, B1_PG: 44.6, PA: 96, PB: 41, },
      { MOD: '42M075', DESC: 'OGP HP Sour Gas Dehydration - Train 2', OV_M_PP: 99, OV_A_PP: 96.2, ST010_ESD: '2018-05-23', ST010_EFD: '2019-02-12', ST010_P: 100, ST010_ASD: '2018-05-18', ST010_AFD: '2019-02-12', ST010_A: 100, ST030_ESD: '2018-06-01', ST030_EFD: '2019-03-07', ST030_P: 100, ST030_ASD: '2018-05-29', ST030_AFD: '2019-03-07', ST030_A: 100, ST080_ESD: '2018-10-16', ST080_EFD: '2019-05-10', ST080_P: 100, ST080_ASD: '2018-10-16', ST080_AFD: '2019-05-24', ST080_A: 100, PI030_ESD: '2018-10-11', PI030_EFD: '2019-09-10', PI030_P: 100, PI030_ASD: '2018-10-11', PI030_AFD: '2019-12-05', PI030_A: 100, EI060_ESD: '2019-02-18', EI060_EFD: '2019-10-11', EI060_P: 100, EI060_ASD: '2019-02-18', EI060_AFD: 'NULL', EI060_A: 100, MC_ESD: '2018-07-25', MC_EFD: '2019-11-08', MC_P: 100, MC_ASD: '2018-07-25', MC_AFD: 'NULL', MC_A: 99, OT090_ESD: '2020-01-25', OT090_EFD: '2020-01-25', OT090_P: 0, OT090_ASD: 'NULL', OT090_AFD: 'NULL', OT090_A: 0, A1_T: 14322, A1_A: 12837, A1_O: 1313, A1_PG: 90.7, B1_T: 191, B1_A: 92, B1_O: 99, B1_PG: 48.2, PA: 223, PB: 249, },
      { MOD: '41M011', DESC: 'PBF Common Cooling', OV_M_PP: 83.7, OV_A_PP: 83, ST010_ESD: '2018-10-22', ST010_EFD: '2019-02-12', ST010_P: 100, ST010_ASD: '2018-10-22', ST010_AFD: '2019-02-12', ST010_A: 100, ST030_ESD: '2018-10-25', ST030_EFD: '2019-02-23', ST030_P: 100, ST030_ASD: '2018-10-25', ST030_AFD: '2019-02-23', ST030_A: 100, ST080_ESD: '2019-02-21', ST080_EFD: '2019-06-03', ST080_P: 100, ST080_ASD: '2019-02-21', ST080_AFD: '2019-06-14', ST080_A: 100, PI030_ESD: '2019-02-28', PI030_EFD: '2019-11-01', PI030_P: 100, PI030_ASD: '2019-02-28', PI030_AFD: 'NULL', PI030_A: 99.7, EI060_ESD: '2019-06-10', EI060_EFD: '2019-11-29', EI060_P: 100, EI060_ASD: '2019-07-11', EI060_AFD: '2019-11-15', EI060_A: 100, MC_ESD: '2018-12-24', MC_EFD: '2019-12-30', MC_P: 94.9, MC_ASD: '2018-12-24', MC_AFD: 'NULL', MC_A: 76.9, OT090_ESD: '2020-02-04', OT090_EFD: '2020-02-04', OT090_P: 0, OT090_ASD: 'NULL', OT090_AFD: 'NULL', OT090_A: 0, A1_T: 3170, A1_A: 1547, A1_O: 1623, A1_PG: 48.8, B1_T: 48, B1_A: 9, B1_O: 39, B1_PG: 18.8, PA: 108, PB: 38, },
      { MOD: '42M054', DESC: 'OGP 1st Stage WSG - Train 1', OV_M_PP: 89.6, OV_A_PP: 85, ST010_ESD: '2018-06-13', ST010_EFD: '2019-03-29', ST010_P: 100, ST010_ASD: '2018-05-14', ST010_AFD: '2019-04-01', ST010_A: 100, ST030_ESD: '2018-06-28', ST030_EFD: '2019-05-03', ST030_P: 100, ST030_ASD: '2018-06-27', ST030_AFD: '2019-04-19', ST030_A: 100, ST080_ESD: '2018-10-20', ST080_EFD: '2019-07-08', ST080_P: 100, ST080_ASD: '2018-10-19', ST080_AFD: '2019-07-11', ST080_A: 100, PI030_ESD: '2018-11-08', PI030_EFD: '2019-10-29', PI030_P: 100, PI030_ASD: '2018-11-08', PI030_AFD: 'NULL', PI030_A: 99.8, EI060_ESD: '2019-03-08', EI060_EFD: '2019-11-26', EI060_P: 100, EI060_ASD: '2019-03-08', EI060_AFD: 'NULL', EI060_A: 99.8, MC_ESD: '2018-10-22', MC_EFD: '2019-12-24', MC_P: 100, MC_ASD: '2018-10-22', MC_AFD: 'NULL', MC_A: 74.5, OT090_ESD: '2020-02-16', OT090_EFD: '2020-02-16', OT090_P: 0, OT090_ASD: 'NULL', OT090_AFD: 'NULL', OT090_A: 0, A1_T: 9929, A1_A: 5661, A1_O: 4139, A1_PG: 57.8, B1_T: 1194, B1_A: 993, B1_O: 201, B1_PG: 83.2, PA: 281, PB: 429, },
      { MOD: '42M056', DESC: 'OGP 2nd Stage WSG - Train 2', OV_M_PP: 92.9, OV_A_PP: 89.6, ST010_ESD: '2018-05-23', ST010_EFD: '2019-01-16', ST010_P: 100, ST010_ASD: '2018-05-14', ST010_AFD: '2019-01-16', ST010_A: 100, ST030_ESD: '2018-05-30', ST030_EFD: '2019-02-18', ST030_P: 100, ST030_ASD: '2018-05-29', ST030_AFD: '2019-02-18', ST030_A: 100, ST080_ESD: '2018-09-18', ST080_EFD: '2019-06-12', ST080_P: 100, ST080_ASD: '2018-09-18', ST080_AFD: '2019-06-14', ST080_A: 100, PI030_ESD: '2018-10-20', PI030_EFD: '2019-10-21', PI030_P: 100, PI030_ASD: '2018-10-20', PI030_AFD: 'NULL', PI030_A: 99.9, EI060_ESD: '2019-01-19', EI060_EFD: '2019-11-18', EI060_P: 100, EI060_ASD: '2019-01-19', EI060_AFD: 'NULL', EI060_A: 100, MC_ESD: '2018-09-01', MC_EFD: '2019-12-16', MC_P: 100, MC_ASD: '2018-09-01', MC_AFD: 'NULL', MC_A: 81.3, OT090_ESD: '2020-02-16', OT090_EFD: '2020-02-22', OT090_P: 0, OT090_ASD: 'NULL', OT090_AFD: 'NULL', OT090_A: 0, A1_T: 11569, A1_A: 8199, A1_O: 3222, A1_PG: 71.8, B1_T: 1372, B1_A: 1122, B1_O: 250, B1_PG: 81.8, PA: 376, PB: 325, },
      { MOD: '42M055', DESC: 'OGP 2nd Stage WSG - Train 3', OV_M_PP: 90.9, OV_A_PP: 89.7, ST010_ESD: '2018-04-05', ST010_EFD: '2018-12-19', ST010_P: 100, ST010_ASD: '2018-03-23', ST010_AFD: '2018-12-19', ST010_A: 100, ST030_ESD: '2018-04-16', ST030_EFD: '2019-01-29', ST030_P: 100, ST030_ASD: '2018-04-17', ST030_AFD: '2019-01-29', ST030_A: 100, ST080_ESD: '2018-08-01', ST080_EFD: '2019-06-04', ST080_P: 100, ST080_ASD: '2018-08-01', ST080_AFD: '2019-06-21', ST080_A: 100, PI030_ESD: '2018-09-01', PI030_EFD: '2019-11-06', PI030_P: 100, PI030_ASD: '2018-09-01', PI030_AFD: 'NULL', PI030_A: 99.9, EI060_ESD: '2018-12-28', EI060_EFD: '2019-12-04', EI060_P: 99.2, EI060_ASD: '2018-12-28', EI060_AFD: '2019-11-29', EI060_A: 100, MC_ESD: '2018-08-27', MC_EFD: '2020-01-06', MC_P: 99.1, MC_ASD: '2018-08-27', MC_AFD: 'NULL', MC_A: 74.4, OT090_ESD: '2020-02-24', OT090_EFD: '2020-02-24', OT090_P: 0, OT090_ASD: 'NULL', OT090_AFD: 'NULL', OT090_A: 0, A1_T: 11598, A1_A: 7573, A1_O: 4021, A1_PG: 65.3, B1_T: 1395, B1_A: 1132, B1_O: 262, B1_PG: 81.2, PA: 275, PB: 192, },
      { MOD: '41M009', DESC: 'KTL 2 Gas Air Coolers', OV_M_PP: 71.4, OV_A_PP: 79.6, ST010_ESD: '2018-12-27', ST010_EFD: '2019-06-28', ST010_P: 100, ST010_ASD: '2018-12-27', ST010_AFD: '2019-05-03', ST010_A: 100, ST030_ESD: '2019-01-17', ST030_EFD: '2019-07-09', ST030_P: 100, ST030_ASD: '2019-01-17', ST030_AFD: '2019-06-27', ST030_A: 100, ST080_ESD: '2019-05-17', ST080_EFD: '2019-09-10', ST080_P: 100, ST080_ASD: '2019-05-24', ST080_AFD: '2019-09-27', ST080_A: 100, PI030_ESD: '2019-05-08', PI030_EFD: '2019-11-26', PI030_P: 99.6, PI030_ASD: '2019-06-10', PI030_AFD: '2019-12-06', PI030_A: 100, EI060_ESD: '2019-09-16', EI060_EFD: '2019-12-24', EI060_P: 68.7, EI060_ASD: '2019-10-01', EI060_AFD: '2019-11-29', EI060_A: 100, MC_ESD: '2019-03-22', MC_EFD: '2020-01-29', MC_P: 94, MC_ASD: '2019-03-22', MC_AFD: 'NULL', MC_A: 69.2, OT090_ESD: '2020-02-28', OT090_EFD: '2020-02-28', OT090_P: 0, OT090_ASD: 'NULL', OT090_AFD: 'NULL', OT090_A: 0, A1_T: 4746, A1_A: 1405, A1_O: 3341, A1_PG: 29.6, B1_T: 78, B1_A: 0, B1_O: 78, B1_PG: 0, PA: 420, PB: 65, },
      { MOD: '47M265', DESC: 'Discharge Coolers - Train 2', OV_M_PP: 65.4, OV_A_PP: 72.8, ST010_ESD: '2019-02-28', ST010_EFD: '2019-06-24', ST010_P: 100, ST010_ASD: '2019-03-05', ST010_AFD: '2019-05-24', ST010_A: 100, ST030_ESD: '2019-03-11', ST030_EFD: '2019-07-03', ST030_P: 100, ST030_ASD: '2019-03-11', ST030_AFD: '2019-06-14', ST030_A: 100, ST080_ESD: '2019-07-22', ST080_EFD: '2019-09-11', ST080_P: 100, ST080_ASD: '2019-08-09', ST080_AFD: '2019-09-27', ST080_A: 100, PI030_ESD: '2019-07-02', PI030_EFD: '2019-12-23', PI030_P: 89, PI030_ASD: '2019-08-28', PI030_AFD: 'NULL', PI030_A: 85.5, EI060_ESD: '2019-11-11', EI060_EFD: '2020-01-23', EI060_P: 9.7, EI060_ASD: '2019-10-22', EI060_AFD: 'NULL', EI060_A: 0.9, MC_ESD: '2019-08-20', MC_EFD: '2020-02-25', MC_P: 66, MC_ASD: '2019-09-02', MC_AFD: 'NULL', MC_A: 26.7, OT090_ESD: '2020-03-12', OT090_EFD: '2020-03-12', OT090_P: 0, OT090_ASD: 'NULL', OT090_AFD: 'NULL', OT090_A: 0, A1_T: 1414, A1_A: 140, A1_O: 1274, A1_PG: 9.9, B1_T: 36, B1_A: 0, B1_O: 36, B1_PG: 0, PA: 110, PB: 12, },
      { MOD: '41M801', DESC: 'ASD / Harmonic Filter Substation (41-SU-3303)', OV_M_PP: 76.2, OV_A_PP: 81.1, ST010_ESD: '2018-09-14', ST010_EFD: '2019-03-20', ST010_P: 100, ST010_ASD: '2018-09-17', ST010_AFD: '2019-03-20', ST010_A: 100, ST030_ESD: '2018-09-29', ST030_EFD: '2019-03-21', ST030_P: 100, ST030_ASD: '2018-09-29', ST030_AFD: '2019-03-21', ST030_A: 100, ST080_ESD: '2019-01-26', ST080_EFD: '2019-07-26', ST080_P: 100, ST080_ASD: '2019-01-26', ST080_AFD: '2019-08-17', ST080_A: 100, PI030_ESD: '2019-02-22', PI030_EFD: '2019-12-03', PI030_P: 99.7, PI030_ASD: '2019-02-22', PI030_AFD: 'NULL', PI030_A: 97, EI060_ESD: '2019-06-10', EI060_EFD: '2020-01-03', EI060_P: 82.9, EI060_ASD: '2019-06-15', EI060_AFD: 'NULL', EI060_A: 90.2, MC_ESD: '2018-11-27', MC_EFD: '2020-02-05', MC_P: 88.9, MC_ASD: '2018-11-27', MC_AFD: 'NULL', MC_A: 64.9, OT090_ESD: '2020-03-13', OT090_EFD: '2020-03-13', OT090_P: 0, OT090_ASD: 'NULL', OT090_AFD: 'NULL', OT090_A: 0, A1_T: 6442, A1_A: 2375, A1_O: 4049, A1_PG: 37, B1_T: 1338, B1_A: 489, B1_O: 843, B1_PG: 36.7, PA: 397, PB: 368, },
      { MOD: '41M007', DESC: 'SGP Gas Air Coolers', OV_M_PP: 63.4, OV_A_PP: 69, ST010_ESD: '2019-01-19', ST010_EFD: '2019-07-05', ST010_P: 100, ST010_ASD: '2019-01-19', ST010_AFD: '2019-05-16', ST010_A: 100, ST030_ESD: '2019-01-31', ST030_EFD: '2019-07-16', ST030_P: 100, ST030_ASD: '2019-01-31', ST030_AFD: '2019-06-27', ST030_A: 100, ST080_ESD: '2019-06-14', ST080_EFD: '2019-10-01', ST080_P: 100, ST080_ASD: '2019-06-13', ST080_AFD: '2019-10-13', ST080_A: 100, PI030_ESD: '2019-05-28', PI030_EFD: '2019-12-27', PI030_P: 91.7, PI030_ASD: '2019-06-25', PI030_AFD: 'NULL', PI030_A: 91.3, EI060_ESD: '2019-10-07', EI060_EFD: '2020-01-31', EI060_P: 26.8, EI060_ASD: '2019-10-21', EI060_AFD: 'NULL', EI060_A: 92.9, MC_ESD: '2019-08-30', MC_EFD: '2020-02-28', MC_P: 62.8, MC_ASD: '2019-04-17', MC_AFD: 'NULL', MC_A: 44.6, OT090_ESD: '2020-04-02', OT090_EFD: '2020-04-02', OT090_P: 0, OT090_ASD: 'NULL', OT090_AFD: 'NULL', OT090_A: 0, A1_T: 4681, A1_A: 780, A1_O: 3901, A1_PG: 16.7, B1_T: 69, B1_A: 0, B1_O: 69, B1_PG: 0, PA: 205, PB: 46, },
      { MOD: '47M266', DESC: 'Discharge Coolers - Train 2', OV_M_PP: 64.2, OV_A_PP: 68.3, ST010_ESD: '2019-01-30', ST010_EFD: '2019-05-10', ST010_P: 100, ST010_ASD: '2019-01-30', ST010_AFD: '2019-04-12', ST010_A: 100, ST030_ESD: '2019-02-12', ST030_EFD: '2019-05-21', ST030_P: 100, ST030_ASD: '2019-02-12', ST030_AFD: '2019-05-09', ST030_A: 100, ST080_ESD: '2019-06-12', ST080_EFD: '2019-07-25', ST080_P: 100, ST080_ASD: '2019-06-12', ST080_AFD: '2019-08-23', ST080_A: 100, PI030_ESD: '2019-04-24', PI030_EFD: '2020-01-08', PI030_P: 89.3, PI030_ASD: '2019-06-24', PI030_AFD: '2019-11-26', PI030_A: 100, EI060_ESD: '2019-10-07', EI060_EFD: '2020-02-10', EI060_P: 23.4, EI060_ASD: '2019-11-27', EI060_AFD: 'NULL', EI060_A: 86, MC_ESD: '2019-06-26', MC_EFD: '2020-03-09', MC_P: 72.7, MC_ASD: '2019-04-08', MC_AFD: 'NULL', MC_A: 36.9, OT090_ESD: '2020-04-14', OT090_EFD: '2020-04-14', OT090_P: 0, OT090_ASD: 'NULL', OT090_AFD: 'NULL', OT090_A: 0, A1_T: 1530, A1_A: 183, A1_O: 1347, A1_PG: 12, B1_T: 39, B1_A: 0, B1_O: 39, B1_PG: 0, PA: 82, PB: 21, },
      { MOD: '41M006', DESC: 'PBF Compressor - Train 1', OV_M_PP: 70.3, OV_A_PP: 75.5, ST010_ESD: '2018-08-24', ST010_EFD: '2019-05-28', ST010_P: 100, ST010_ASD: '2018-08-16', ST010_AFD: '2019-04-25', ST010_A: 100, ST030_ESD: '2018-08-31', ST030_EFD: '2019-06-07', ST030_P: 100, ST030_ASD: '2018-08-31', ST030_AFD: '2019-05-31', ST030_A: 100, ST080_ESD: '2018-12-10', ST080_EFD: '2019-08-19', ST080_P: 100, ST080_ASD: '2018-12-10', ST080_AFD: '2019-08-30', ST080_A: 100, PI030_ESD: '2018-12-14', PI030_EFD: '2019-12-13', PI030_P: 98.2, PI030_ASD: '2018-12-14', PI030_AFD: 'NULL', PI030_A: 94.6, EI060_ESD: '2019-05-27', EI060_EFD: '2020-01-15', EI060_P: 77, EI060_ASD: '2019-06-08', EI060_AFD: 'NULL', EI060_A: 88.6, MC_ESD: '2018-10-31', MC_EFD: '2020-02-17', MC_P: 87.8, MC_ASD: '2018-10-31', MC_AFD: 'NULL', MC_A: 48.7, OT090_ESD: '2020-04-22', OT090_EFD: '2020-04-22', OT090_P: 0, OT090_ASD: 'NULL', OT090_AFD: 'NULL', OT090_A: 0, A1_T: 12485, A1_A: 3568, A1_O: 8917, A1_PG: 28.6, B1_T: 1543, B1_A: 731, B1_O: 812, B1_PG: 47.4, PA: 456, PB: 282, },
      { MOD: '47M268', DESC: 'ASD/Harmonic Filter Substation 47-SU-3312', OV_M_PP: 55.5, OV_A_PP: 60.8, ST010_ESD: '2019-03-07', ST010_EFD: '2019-05-28', ST010_P: 100, ST010_ASD: '2019-03-07', ST010_AFD: '2019-05-09', ST010_A: 100, ST030_ESD: '2019-03-13', ST030_EFD: '2019-06-07', ST030_P: 100, ST030_ASD: '2019-03-21', ST030_AFD: '2019-06-18', ST030_A: 100, ST080_ESD: '2019-07-09', ST080_EFD: '2019-08-26', ST080_P: 77.1, ST080_ASD: '2019-08-13', ST080_AFD: '2019-10-04', ST080_A: 100, PI030_ESD: '2019-06-25', PI030_EFD: '2020-01-14', PI030_P: 77.7, PI030_ASD: '2019-07-26', PI030_AFD: 'NULL', PI030_A: 75.2, EI060_ESD: '2019-10-14', EI060_EFD: '2020-02-14', EI060_P: 18.5, EI060_ASD: '2019-11-15', EI060_AFD: 'NULL', EI060_A: 58.9, MC_ESD: '2019-07-23', MC_EFD: '2020-03-13', MC_P: 50.2, MC_ASD: '2019-06-17', MC_AFD: 'NULL', MC_A: 23.7, OT090_ESD: '2020-04-29', OT090_EFD: '2020-04-29', OT090_P: 0, OT090_ASD: 'NULL', OT090_AFD: 'NULL', OT090_A: 0, A1_T: 2171, A1_A: 213, A1_O: 1958, A1_PG: 9.8, B1_T: 657, B1_A: 0, B1_O: 657, B1_PG: 0, PA: 130, PB: 72, },
      { MOD: '47M267', DESC: 'Injection Chiller & Seal Gas - Train 2', OV_M_PP: 54, OV_A_PP: 63.8, ST010_ESD: '2018-12-27', ST010_EFD: '2019-09-06', ST010_P: 100, ST010_ASD: '2018-12-28', ST010_AFD: '2019-08-19', ST010_A: 100, ST030_ESD: '2019-01-08', ST030_EFD: '2019-09-19', ST030_P: 100, ST030_ASD: '2019-01-08', ST030_AFD: '2019-08-30', ST030_A: 100, ST080_ESD: '2019-04-12', ST080_EFD: '2019-11-11', ST080_P: 100, ST080_ASD: '2019-05-03', ST080_AFD: '2019-11-28', ST080_A: 100, PI030_ESD: '2019-04-12', PI030_EFD: '2020-02-24', PI030_P: 79.9, PI030_ASD: '2019-05-08', PI030_AFD: 'NULL', PI030_A: 70.5, EI060_ESD: '2019-12-02', EI060_EFD: '2020-03-23', EI060_P: 0, EI060_ASD: '2019-08-20', EI060_AFD: 'NULL', EI060_A: 69.5, MC_ESD: '2019-02-25', MC_EFD: '2020-04-21', MC_P: 48.8, MC_ASD: '2019-02-25', MC_AFD: 'NULL', MC_A: 15.3, OT090_ESD: '2020-06-04', OT090_EFD: '2020-06-04', OT090_P: 0, OT090_ASD: 'NULL', OT090_AFD: 'NULL', OT090_A: 0, A1_T: 4631, A1_A: 363, A1_O: 4257, A1_PG: 7.9, B1_T: 1043, B1_A: 0, B1_O: 1043, B1_PG: 0, PA: 323, PB: 240, },
      { MOD: '41M001', DESC: 'PBF Compressor - Train 4', OV_M_PP: 60.7, OV_A_PP: 67.3, ST010_ESD: '2018-10-09', ST010_EFD: '2019-07-03', ST010_P: 100, ST010_ASD: '2018-10-10', ST010_AFD: '2019-05-14', ST010_A: 100, ST030_ESD: '2018-10-17', ST030_EFD: '2019-07-12', ST030_P: 100, ST030_ASD: '2018-10-17', ST030_AFD: '2019-06-12', ST030_A: 100, ST080_ESD: '2019-01-22', ST080_EFD: '2019-09-27', ST080_P: 100, ST080_ASD: '2019-01-25', ST080_AFD: '2019-10-11', ST080_A: 100, PI030_ESD: '2019-02-15', PI030_EFD: '2020-02-07', PI030_P: 86.3, PI030_ASD: '2019-02-15', PI030_AFD: 'NULL', PI030_A: 92.7, EI060_ESD: '2019-07-08', EI060_EFD: '2020-03-06', EI060_P: 37.3, EI060_ASD: '2019-07-24', EI060_AFD: 'NULL', EI060_A: 65.3, MC_ESD: '2018-12-14', MC_EFD: '2020-04-03', MC_P: 75.1, MC_ASD: '2018-12-14', MC_AFD: 'NULL', MC_A: 40.9, OT090_ESD: '2020-06-09', OT090_EFD: '2020-06-09', OT090_P: 0, OT090_ASD: 'NULL', OT090_AFD: 'NULL', OT090_A: 0, A1_T: 12426, A1_A: 1610, A1_O: 10816, A1_PG: 13, B1_T: 1483, B1_A: 80, B1_O: 1403, B1_PG: 5.4, PA: 452, PB: 217, },
      { MOD: '41M004', DESC: 'PBF Compressor - Train 2', OV_M_PP: 57, OV_A_PP: 61.3, ST010_ESD: '2018-11-05', ST010_EFD: '2019-09-10', ST010_P: 100, ST010_ASD: '2018-11-05', ST010_AFD: '2019-06-18', ST010_A: 100, ST030_ESD: '2018-11-23', ST030_EFD: '2019-09-23', ST030_P: 100, ST030_ASD: '2018-11-21', ST030_AFD: '2019-08-30', ST030_A: 100, ST080_ESD: '2019-03-11', ST080_EFD: '2019-11-11', ST080_P: 99.1, ST080_ASD: '2019-03-15', ST080_AFD: '2019-11-21', ST080_A: 100, PI030_ESD: '2019-03-23', PI030_EFD: '2020-02-17', PI030_P: 85, PI030_ASD: '2019-03-23', PI030_AFD: 'NULL', PI030_A: 79.2, EI060_ESD: '2019-09-02', EI060_EFD: '2020-03-16', EI060_P: 23.3, EI060_ASD: '2019-09-17', EI060_AFD: 'NULL', EI060_A: 29.2, MC_ESD: '2019-01-15', MC_EFD: '2020-04-13', MC_P: 61.2, MC_ASD: '2019-01-15', MC_AFD: 'NULL', MC_A: 22.9, OT090_ESD: '2020-06-18', OT090_EFD: '2020-06-18', OT090_P: 0, OT090_ASD: 'NULL', OT090_AFD: 'NULL', OT090_A: 0, A1_T: 12902, A1_A: 748, A1_O: 12154, A1_PG: 5.8, B1_T: 1497, B1_A: 20, B1_O: 1477, B1_PG: 1.3, PA: 505, PB: 249, },
      { MOD: '41M003', DESC: 'PBF Compressor - Train 3', OV_M_PP: 59.6, OV_A_PP: 63.1, ST010_ESD: '2018-10-22', ST010_EFD: '2019-08-12', ST010_P: 100, ST010_ASD: '2018-10-22', ST010_AFD: '2019-06-18', ST010_A: 100, ST030_ESD: '2018-11-02', ST030_EFD: '2019-08-22', ST030_P: 100, ST030_ASD: '2018-11-02', ST030_AFD: '2019-08-14', ST030_A: 100, ST080_ESD: '2019-02-01', ST080_EFD: '2019-10-14', ST080_P: 100, ST080_ASD: '2019-02-15', ST080_AFD: '2019-11-09', ST080_A: 100, PI030_ESD: '2019-02-17', PI030_EFD: '2020-03-05', PI030_P: 80.5, PI030_ASD: '2019-02-17', PI030_AFD: 'NULL', PI030_A: 71, EI060_ESD: '2019-07-24', EI060_EFD: '2020-04-02', EI060_P: 24.9, EI060_ASD: '2019-07-19', EI060_AFD: 'NULL', EI060_A: 37.6, MC_ESD: '2018-12-28', MC_EFD: '2020-05-06', MC_P: 64.4, MC_ASD: '2018-12-28', MC_AFD: 'NULL', MC_A: 22.5, OT090_ESD: '2020-06-27', OT090_EFD: '2020-06-27', OT090_P: 0, OT090_ASD: 'NULL', OT090_AFD: 'NULL', OT090_A: 0, A1_T: 12858, A1_A: 788, A1_O: 12060, A1_PG: 6.1, B1_T: 1505, B1_A: 0, B1_O: 1505, B1_PG: 0, PA: 422, PB: 245, },
      { MOD: '47M262', DESC: 'Injection Compression Stage 2 & 3 - Train 2', OV_M_PP: 57, OV_A_PP: 64.7, ST010_ESD: '2018-11-29', ST010_EFD: '2019-09-02', ST010_P: 100, ST010_ASD: '2018-12-06', ST010_AFD: '2019-07-05', ST010_A: 100, ST030_ESD: '2018-12-18', ST030_EFD: '2019-09-11', ST030_P: 100, ST030_ASD: '2018-12-17', ST030_AFD: '2019-08-23', ST030_A: 100, ST080_ESD: '2019-04-05', ST080_EFD: '2019-11-18', ST080_P: 100, ST080_ASD: '2019-04-12', ST080_AFD: '2019-11-29', ST080_A: 100, PI030_ESD: '2019-04-05', PI030_EFD: '2020-03-13', PI030_P: 76.2, PI030_ASD: '2019-04-26', PI030_AFD: 'NULL', PI030_A: 77.1, EI060_ESD: '2019-09-02', EI060_EFD: '2020-04-10', EI060_P: 18, EI060_ASD: '2019-09-09', EI060_AFD: 'NULL', EI060_A: 47, MC_ESD: '2019-02-15', MC_EFD: '2020-05-14', MC_P: 96.6, MC_ASD: '2019-02-15', MC_AFD: 'NULL', MC_A: 31.4, OT090_ESD: '2020-07-01', OT090_EFD: '2020-07-01', OT090_P: 0, OT090_ASD: 'NULL', OT090_AFD: 'NULL', OT090_A: 0, A1_T: 10290, A1_A: 941, A1_O: 9327, A1_PG: 9.2, B1_T: 1218, B1_A: 0, B1_O: 1218, B1_PG: 0, PA: 397, PB: 197, },
      { MOD: '47M261', DESC: 'Injection Compression Stage 1 - Train 2', OV_M_PP: 59.8, OV_A_PP: 66.7, ST010_ESD: '2018-11-02', ST010_EFD: '2019-08-21', ST010_P: 100, ST010_ASD: '2018-11-02', ST010_AFD: '2019-06-25', ST010_A: 100, ST030_ESD: '2018-11-14', ST030_EFD: '2019-08-30', ST030_P: 100, ST030_ASD: '2018-11-14', ST030_AFD: '2019-07-26', ST030_A: 100, ST080_ESD: '2019-03-04', ST080_EFD: '2019-10-25', ST080_P: 100, ST080_ASD: '2019-03-08', ST080_AFD: '2019-11-29', ST080_A: 100, PI030_ESD: '2019-03-26', PI030_EFD: '2020-03-02', PI030_P: 82.3, PI030_ASD: '2019-03-26', PI030_AFD: 'NULL', PI030_A: 84.7, EI060_ESD: '2019-08-19', EI060_EFD: '2020-03-30', EI060_P: 23.6, EI060_ASD: '2019-08-13', EI060_AFD: 'NULL', EI060_A: 50.1, MC_ESD: '2019-02-11', MC_EFD: '2020-04-28', MC_P: 48.4, MC_ASD: '2019-02-11', MC_AFD: 'NULL', MC_A: 29.2, OT090_ESD: '2020-07-02', OT090_EFD: '2020-07-02', OT090_P: 0, OT090_ASD: 'NULL', OT090_AFD: 'NULL', OT090_A: 0, A1_T: 9557, A1_A: 855, A1_O: 8691, A1_PG: 9, B1_T: 1219, B1_A: 0, B1_O: 1219, B1_PG: 0, PA: 397, PB: 200, },
      { MOD: '47M263', DESC: 'Major Valves & Header - Train 2', OV_M_PP: 54.1, OV_A_PP: 57.5, ST010_ESD: '2019-02-25', ST010_EFD: '2019-07-05', ST010_P: 100, ST010_ASD: '2019-02-27', ST010_AFD: '2019-07-26', ST010_A: 100, ST030_ESD: '2019-03-07', ST030_EFD: '2019-07-16', ST030_P: 100, ST030_ASD: '2019-03-07', ST030_AFD: '2019-08-14', ST030_A: 100, ST080_ESD: '2019-07-05', ST080_EFD: '2019-11-01', ST080_P: 100, ST080_ASD: '2019-07-22', ST080_AFD: '2019-11-22', ST080_A: 100, PI030_ESD: '2019-06-21', PI030_EFD: '2020-03-23', PI030_P: 59.9, PI030_ASD: '2019-07-23', PI030_AFD: 'NULL', PI030_A: 63, EI060_ESD: '2019-11-04', EI060_EFD: '2020-04-21', EI060_P: 4.2, EI060_ASD: '2019-11-20', EI060_AFD: 'NULL', EI060_A: 29.3, MC_ESD: '2019-08-30', MC_EFD: '2020-05-22', MC_P: 18.1, MC_ASD: '2019-08-26', MC_AFD: 'NULL', MC_A: 3.7, OT090_ESD: '2020-07-05', OT090_EFD: '2020-07-05', OT090_P: 0, OT090_ASD: 'NULL', OT090_AFD: 'NULL', OT090_A: 0, A1_T: 6261, A1_A: 65, A1_O: 6196, A1_PG: 1, B1_T: 126, B1_A: 0, B1_O: 126, B1_PG: 0, PA: 304, PB: 67, },
    ],
}