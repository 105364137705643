import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 550,
      CanvasHeight: 310,
      CanvasBorderWeight: 0.3,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 310,
      CanvasChartHeight: 270,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: 'Transparent',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },

    Text: {
      Text: [
        { text: 'Outfitting Commodities Status', x: 20, y: 10, font: 'roboto', class: 'regular', size: 13, color: '#000', align: 'start', direction: 'Horizontal' },
      ],
      Filter: [],
      Sql: []
    },

    DataItems: [
      // {DISC_DESCR:"Piping",ITEM:"test",UNIT:"T",TOTAL:22585,PLAN:22585,ACTUAL:22585,DELTA:22585},
      // {DISC_DESCR:"Structure",ITEM:"Structure Prefabrication",UNIT:"TON",TOTAL:9,PLAN:null,ACTUAL:9,DELTA:-5},
      // {DISC_DESCR:"Piping",ITEM:"test",UNIT:"T",TOTAL:22585,PLAN:22585,ACTUAL:22585,DELTA:22585},
      // {DISC_DESCR:"Piping",ITEM:"test",UNIT:"T",TOTAL:22585,PLAN:22585,ACTUAL:0,DELTA:22585},
      // {DISC_DESCR:"Piping",ITEM:"test",UNIT:"T",TOTAL:22585,PLAN:22585,ACTUAL:22585,DELTA:22585},
      // {DISC_DESCR:"Piping",ITEM:"test",UNIT:"T",TOTAL:22585,PLAN:22585,ACTUAL:22585,DELTA:0},
      // {DISC_DESCR:"Piping",ITEM:"test",UNIT:"T",TOTAL:22585,PLAN:22585,ACTUAL:22585,DELTA:0},
      // {DISC_DESCR:"Piping",ITEM:"test",UNIT:"T",TOTAL:22585,PLAN:22585,ACTUAL:0,DELTA:22585},
      // {DISC_DESCR:"Piping",ITEM:"test",UNIT:"T",TOTAL:22585,PLAN:22585,ACTUAL:0,DELTA:22585},
      // {DISC_DESCR:"Piping",ITEM:"test",UNIT:"T",TOTAL:22585,PLAN:22585,ACTUAL:22585,DELTA:-1},
      // {DISC_DESCR:"Piping",ITEM:"test",UNIT:"T",TOTAL:22585,PLAN:22585,ACTUAL:22585,DELTA:22585},
      // {DISC_DESCR:"Piping",ITEM:"test",UNIT:"T",TOTAL:22585,PLAN:22585,ACTUAL:22585,DELTA:22585},
      // {DISC_DESCR:"Piping",ITEM:"test",UNIT:"T",TOTAL:22585,PLAN:22585,ACTUAL:22585,DELTA:22585},

      {DISC_DESCR:"Piping",ITEM:"Spool",UNIT:"DIA",TOTAL:16729,PLAN:16814,ACTUAL:9182,ACTUAL_TW:776,ACTUAL_TM:3506,DELTA:-7632},
      {DISC_DESCR:"Electrical",ITEM:"Support",UNIT:"KG",TOTAL:252290,PLAN:274320,ACTUAL:142229,ACTUAL_TW:15200,ACTUAL_TM:58115,DELTA:-132091},
      {DISC_DESCR:"Electrical",ITEM:"Cable Tray",UNIT:"LM",TOTAL:158060,PLAN:158060,ACTUAL:25989,ACTUAL_TW:2069,ACTUAL_TM:6481,DELTA:-132071},
      {DISC_DESCR:"Electrical",ITEM:"Cable Pulling",UNIT:"LM",TOTAL:115239,PLAN:74103,ACTUAL:50685,ACTUAL_TW:7493,ACTUAL_TM:15970,DELTA:-23419},
      {DISC_DESCR:"Electrical",ITEM:"Cable Termination",UNIT:"U",TOTAL:28096,PLAN:5650,ACTUAL:1819,ACTUAL_TW:461,ACTUAL_TM:1105,DELTA:-3831},
      {DISC_DESCR:"Instrument",ITEM:"Cable Pulling",UNIT:"LM",TOTAL:147517,PLAN:202096,ACTUAL:20698,ACTUAL_TW:3201,ACTUAL_TM:9996,DELTA:-181397},
      {DISC_DESCR:"Instrument",ITEM:"Cable Termination",UNIT:"U",TOTAL:75995,PLAN:9962,ACTUAL:158,ACTUAL_TW:0,ACTUAL_TM:130,DELTA:-9804},
      {DISC_DESCR:"HVAC",ITEM:"Duct",UNIT:"SQM",TOTAL:1912,PLAN:1912,ACTUAL:1912,ACTUAL_TW:0,ACTUAL_TM:0,DELTA:0},
      {DISC_DESCR:"Architecture",ITEM:"Wall & Ceiling",UNIT:"SQM",TOTAL:1152,PLAN:1152,ACTUAL:1071,ACTUAL_TW:0,ACTUAL_TM:-52,DELTA:-81}

    ],
}