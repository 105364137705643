import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 280,
      CanvasHeight: 170,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Summary', x:10, y: 0, font: 'roboto', class: 'regular', size: 12, color: '#424242', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: -50, y: 20, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'start' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: -30,
          y: 20,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'start'
        },
      ]
    },
    Queries: {
      SQL1: [
        {title:"Pressure Test",value1:100},
        {title:"A TIR",value1:14.4},
        {title:"Loops",value1:100},
        {title:"B TIR",value1:93.4},
        {title:"C TIR",value1:46.7}
      ],
    },
    DataItems: [
      {TITLE:"Pressure Test",SEQ:1,TOTAL:3,COMPLETED:0,REMAIN:3,PROG:100},
      {TITLE:"A TIR",SEQ:2,TOTAL:250,COMPLETED:214,REMAIN:36,PROG:14.4},
      {TITLE:"Loops",SEQ:3,TOTAL:100,COMPLETED:0,REMAIN:100,PROG:100},
      {TITLE:"B TIR",SEQ:4,TOTAL:121,COMPLETED:8,REMAIN:113,PROG:93.4},
      {TITLE:"C TIR",SEQ:5,TOTAL:15,COMPLETED:8,REMAIN:7,PROG:46.7}
],
}