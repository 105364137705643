import System_ChartOptions_Data from      '../../09_Projects/TCO/00_System/ChartOptions/includes/primitives/Data'
import System_Dropdown_Data from          '../../09_Projects/TCO/00_System/Dropdown/includes/primitives/Data'
import System_SubMenu_01_Data from        '../../09_Projects/TCO/00_System/SubMenu_01/includes/primitives/Data'

// Common
import CMN_HistoryTable_Data from         '../../01_Charts/99_Common/HistoryTable/includes/primitives/Data'
import CMN_TBD_Data from                  '../../01_Charts/99_Common/TBD/includes/primitives/Data'
import CMN_Up2UText_Data from             '../../01_Charts/99_Common/Up2UText/includes/primitives/Data'
import CMN_3DViewerEmbedded_Data from     '../../01_Charts/99_Common/3DViewerEmbedded/includes/primitives/Data'

//HomePage
import Home_ProjectProgress_Data from '../../09_Projects/Tortue/00_Homepage/OverallProgress/includes/primitives/Data'
import Home_KeyMilestone_Data from    '../../09_Projects/Tortue/00_Homepage/KeyMilestone/includes/primitives/Data'
import Home_Observation_Data from     '../../09_Projects/Tortue/00_Homepage/Observation/includes/primitives/Data'
import Home_OverallSummary_Data from '../../09_Projects/Tortue/00_Homepage/OverallSummary/includes/primitives/Data'
import Home_OncomingSailaway_Data from '../../09_Projects/Tortue/00_Homepage/OncomingSailaway/includes/primitives/Data'
import Home_Yard_Data from                   '../../09_Projects/Tortue/00_Homepage/Yard/includes/primitives/Data'
import Home_DataSource_Data from             '../../09_Projects/Tortue/00_Homepage/DataSource/includes/primitives/Data'
import Home_Milestones_Data from '../../09_Projects/Tortue/00_Homepage/MilestoneImg/includes/primitives/Data'
import Home_Milestones_R_Data from '../../09_Projects/Tortue/00_Homepage/Milestone/includes/primitives/Data'

import Home_MC_Pie_Data from '../../09_Projects/Tortue/00_Homepage/Pie/includes/primitives/Data'
import Home_MC_SummaryProg_Data from '../../09_Projects/Tortue/00_Homepage/SummaryProg/includes/primitives/Data'
import Home_MC_ITR_Data from '../../09_Projects/Tortue/00_Homepage/MC_ITRStatus/includes/primitives/Data'
import Home_MC_Punch_Data from '../../09_Projects/Tortue/00_Homepage/MC_PunchTrends/includes/primitives/Data'
import Home_MoreButton_Data from '../../09_Projects/Tortue/00_Homepage/MoreButton/includes/primitives/Data'
import Home_PriorityChart_Data from '../../09_Projects/Tortue/00_Homepage/PriorityChart/includes/primitives/Data'
import Home_TubeChart_Data from '../../09_Projects/Tortue/00_Homepage/TubeChart/includes/primitives/Data'
import Home_WorkForceTable_Data from '../../09_Projects/Tortue/00_Homepage/WorkForceTable/includes/primitives/Data'

import Home_performanceLineChart_Data from '../../09_Projects/Tortue/00_Homepage/performanceLineChart/includes/primitives/Data'
import Home_performanceBarChart_ITR_Data from '../../09_Projects/Tortue/00_Homepage/performanceBarChart_ITR/includes/primitives/Data'
import Home_performanceLineChart_punch_Data from '../../09_Projects/Tortue/00_Homepage/performanceLineChart_punch/includes/primitives/Data'
import Home_performanceBarChart_punch_Data from '../../09_Projects/Tortue/00_Homepage/performanceBarChart_punch/includes/primitives/Data'
import Home_performanceCompletionChart_Data from '../../09_Projects/Tortue/00_Homepage/performanceCompletionChart/includes/primitives/Data'
import Home_mcskyline_performance_Data from '../../09_Projects/Tortue/00_Homepage/MC_Skyline_performance/includes/primitives/Data'

import Home_performanceLineChart_B_Data from '../../09_Projects/Tortue/00_Homepage/performanceLineChart_B/includes/primitives/Data'
import Home_performanceBarChart_BITR_Data from '../../09_Projects/Tortue/00_Homepage/performanceBarChart_BITR/includes/primitives/Data'
import Home_performanceLineChart_Bpunch_Data from '../../09_Projects/Tortue/00_Homepage/performanceLineChart_Bpunch/includes/primitives/Data'
import Home_performanceBarChart_Bpunch_Data from '../../09_Projects/Tortue/00_Homepage/performanceBarChart_Bpunch/includes/primitives/Data'
import Home_performanceCompletionChart_B_Data from '../../09_Projects/Tortue/00_Homepage/performanceCompletionChart_B/includes/primitives/Data'

import Home_ProceduresGauge_Data from '../../09_Projects/Tortue/00_Homepage/ProceduresGauge/includes/primitives/Data'
import Home_ProceduresGauge_Daily_Data from '../../09_Projects/Tortue/00_Homepage/ProceduresGauge_Daily/includes/primitives/Data'
import Home_SummaryTable_Data from '../../09_Projects/Tortue/00_Homepage/SummaryTable/includes/primitives/Data'

// HSSE
import HSSE_SafetyPyramid_Data from '../../09_Projects/Tortue/01_HSSE/Pyramid/includes/primitives/Data'
import HSSE_LSR_Data from '../../09_Projects/Tortue/01_HSSE/LSR_IMG/includes/primitives/Data'
import HSSE_TSC_Data from '../../09_Projects/Tortue/01_HSSE/tortueSafetyCharter/includes/primitives/Data'
import HSSE_Dashboard_Data from '../../09_Projects/Tortue/01_HSSE/HSSEDashboard/includes/primitives/Data'

//Planning
import Plan_ConstructionBlockSkyline_Data from '../../09_Projects/Tortue/01_Planning/ConstructionBlockSkyline/includes/primitives/Data'
import Plan_ProgressSummaryTable_Data from '../../09_Projects/Tortue/01_Planning/ProgressSummaryTable/includes/primitives/Data'
import Plan_ModuleSummarySchedule_Data from '../../09_Projects/Tortue/01_Planning/ModuleSummarySchedule/includes/primitives/Data'
import Plan_CommoditySummarySchedule_Data from '../../09_Projects/Tortue/01_Planning/CommoditySummarySchedule/includes/primitives/Data'
import Plan_SummaryByArea_Data from '../../09_Projects/Tortue/01_Planning/SummaryByArea/includes/primitives/Data'
import Plan_ModuleKeyMilestones_Data from '../../09_Projects/Tortue/01_Planning/SummaryByArea/includes/primitives/Data'
import TopsidesGanttChart_Data from '../../09_Projects/Tortue/01_Planning/TopsidesGanttChart/includes/primitives/Data'
import Plan_FilterPhase_Data from '../../09_Projects/Tortue/01_Planning/FilterPhase/includes/primitives/Data'
import Plan_SummaryByOutfittingCommodity_Data from '../../09_Projects/Tortue/01_Planning/SummaryByOutfittingCommodity/includes/primitives/Data'
import Plan_SpoolSummary_Data from '../../09_Projects/Tortue/01_Planning/SpoolSummary/includes/primitives/Data'

//Engineering
import Eng_CMDRStatus_Data from '../../09_Projects/Tortue/02_Engineering/CMDRStatus/includes/primitives/Data'
import Eng_IFCDrawingList_Data from '../../09_Projects/Tortue/02_Engineering/IFCDrawingList/includes/primitives/Data'
import Eng_TotalPlanActualRemainL_Data from '../../09_Projects/Tortue/02_Engineering/TotalPlanActualRemainL/includes/primitives/Data'
import Eng_TotalPlanActualRemainM_Data from '../../09_Projects/Tortue/02_Engineering/TotalPlanActualRemainM/includes/primitives/Data'
import Eng_TotalPlanActualRemainS_Data from '../../09_Projects/Tortue/02_Engineering/TotalPlanActualRemainS/includes/primitives/Data'
import Eng_CDREHistoryChart_Data from '../../09_Projects/Tortue/02_Engineering/CDREHistoryChart/includes/primitives/Data'
import Eng_TQHistoryChart_Data from '../../09_Projects/Tortue/02_Engineering/TQHistoryChart/includes/primitives/Data'
import Eng_SDIDashboard_Data from '../../09_Projects/Tortue/02_Engineering/SDIDashboard/includes/primitives/Data'

// Procurement
import Proc_EquipmentLayout_Data from '../../09_Projects/DEMO/05_Procurement/Equipment_Layout/includes/primitives/Data'
import Proc_EquipmentTable_Data from '../../09_Projects/Tortue/03_Procurement/EquipmentTable/includes/primitives/Data'
import Proc_Equipment3DLayout_Data from '../../09_Projects/Tortue/03_Procurement/Equipment3DLayout/includes/primitives/Data'
import Proc_EquipmentRoom_Data from '../../09_Projects/Tortue/03_Procurement/EquipmentRoom/includes/primitives/Data'
import Proc_ProgressBar_Data from '../../09_Projects/Tortue/03_Procurement/progressBar/includes/primitives/Data'
import Proc_Lookahead_Data from '../../09_Projects/Tortue/03_Procurement/lookahead/includes/primitives/Data'
import Proc_OWL_Data from '../../09_Projects/Tortue/03_Procurement/owl/includes/primitives/Data'
import Proc_OverallScurve_Data from '../../09_Projects/Tortue/03_Procurement/Overall_Scurve/includes/primitives/Data'
import Proc_FATScurve_Data from '../../09_Projects/Tortue/03_Procurement/FAT_Scurve/includes/primitives/Data'
import Proc_IRNScurve_Data from '../../09_Projects/Tortue/03_Procurement/FAT_Scurve/includes/primitives/Data'
import Proc_ETAScurve_Data from '../../09_Projects/Tortue/03_Procurement/FAT_Scurve/includes/primitives/Data'
import Proc_IRNLookahead_Data from '../../09_Projects/Tortue/03_Procurement/lookahead/includes/primitives/Data'
import Proc_ETALookahead_Data from '../../09_Projects/Tortue/03_Procurement/lookahead/includes/primitives/Data'
import Proc_EquipmentHullCommon_Data from '../../09_Projects/Tortue/03_Procurement/lookahead/includes/primitives/Data'

// Construction
import Con_BlockDivision_Data from          '../../09_Projects/Tortue/04_Construction/BlockDivision/includes/primitives/Data'
import Con_BlockSummary_Data from           '../../09_Projects/Tortue/04_Construction/BlockSummary/includes/primitives/Data'
import Con_ActivityGanttChart_Data from     '../../09_Projects/TCO/07_Construction/ActivityGanttChart/includes/primitives/Data'
import Con_ActivityGanttChart_R1_Data from     '../../09_Projects/Tortue/04_Construction/ActivityGanttChart_R1/includes/primitives/Data'
import Con_ActivityGanttChartLegend_Data from     '../../09_Projects/Tortue/04_Construction/ActivityGanttChartLegend/includes/primitives/Data'
import Con_MeasurementBlock_Data from       '../../09_Projects/Tortue/04_Construction/MeasurementBlock/includes/primitives/Data'
import Con_ShopDrawingActivities_Data from  '../../09_Projects/Tortue/04_Construction/ShopDrawingActivities/includes/primitives/Data'
import Con_ShopDrawingStatus_Data from  '../../09_Projects/Tortue/04_Construction/ShopDrawingStatus/includes/primitives/Data'
import Con_ShopDrawingStatusOther_Data from  '../../09_Projects/Tortue/04_Construction/ShopDrawingStatusOther/includes/primitives/Data'
import Con_OutfittingStatus_Data from       '../../09_Projects/Tortue/04_Construction/OutfittingStatus/includes/primitives/Data'
import Con_OutfittingStatusNew_Data from       '../../09_Projects/Tortue/04_Construction/OutfittingStatus_new/includes/primitives/Data'
import Con_Outfitting_Data from             '../../09_Projects/Tortue/04_Construction/Outfitting/includes/primitives/Data'
import Con_ErectionTimeline_Data from       '../../09_Projects/Tortue/04_Construction/ErectionTimeline/includes/primitives/Data'
import Con_OverallDashboard_Data from       '../../09_Projects/Tortue/04_Construction/DashboardOverall/includes/primitives/Data'
import Con_OverallDashboardTopside_Data from       '../../09_Projects/Tortue/04_Construction/DashboardOverallTopside/includes/primitives/Data'
import Con_OverallDashboardTopside_L_Data from       '../../09_Projects/Tortue/04_Construction/DashboardOverallTopside_L/includes/primitives/Data'
import Con_OverallDashboardTopside_S_Data from       '../../09_Projects/Tortue/04_Construction/DashboardOverallTopside_S/includes/primitives/Data'
import Con_OverallDashboardHull_Data from       '../../09_Projects/Tortue/04_Construction/DashboardOverallHull/includes/primitives/Data'
import Con_OverallDashboardHull_L_Data from       '../../09_Projects/Tortue/04_Construction/DashboardOverallHull/includes/primitives/Data'
import Con_OverallDashboardHull_S_Data from       '../../09_Projects/Tortue/04_Construction/DashboardOverallHull_S/includes/primitives/Data'
import Con_OverallDashboardModules_S_Data from       '../../09_Projects/Tortue/04_Construction/DashboardOverallModules_S/includes/primitives/Data'
import Con_OverallDashboardModule_S_Data from       '../../09_Projects/Tortue/04_Construction/DashboardOverallModule_S/includes/primitives/Data'
import Con_OverallDashboardCommon_S_Data from       '../../09_Projects/Tortue/04_Construction/DashboardOverallCommon_S/includes/primitives/Data'
import Con_OverallDashboardLQ_S_Data from       '../../09_Projects/Tortue/04_Construction/DashboardOverallLQ_S/includes/primitives/Data'
import Con_OverallDashboardLQ_Data from       '../../09_Projects/Tortue/04_Construction/DashboardOverallLQ/includes/primitives/Data'
import Con_ProgressSummaryOverall_Data from       '../../09_Projects/Tortue/04_Construction/ProgressSummaryOverall/includes/primitives/Data'
import Con_ProgressSummaryTopside_Data from       '../../09_Projects/Tortue/04_Construction/ProgressSummaryTopside/includes/primitives/Data'
import Con_ProgressSummaryHull_Data from       '../../09_Projects/Tortue/04_Construction/ProgressSummaryHull/includes/primitives/Data'
import Con_OutfittingCommoditiesStatus_Data from       '../../09_Projects/Tortue/04_Construction/OutfittingCommoditiesStatus/includes/primitives/Data'
import Con_OutfittingCommoditiesStatusL_Data from       '../../09_Projects/Tortue/04_Construction/OutfittingCommStatus_L/includes/primitives/Data'
import Con_OgmDashboardHull_Data from      '../../09_Projects/Tortue/04_Construction/OgmDashboardHull/includes/primitives/Data'
import Con_OgmDashboardTopside_Data from      '../../09_Projects/Tortue/04_Construction/OgmDashboardTopside/includes/primitives/Data'
import Con_SimpleText_Data from      '../../09_Projects/Tortue/04_Construction/SimpleText/includes/primitives/Data'
import Con_OgmDisc_Data from      '../../09_Projects/Tortue/04_Construction/OgmDisc/includes/primitives/Data'
import Con_OutfittingCommStatus_Data from      '../../09_Projects/Tortue/04_Construction/OutfittingCommStatus/includes/primitives/Data'
import Con_OutfittingCommStatus_S_Data from      '../../09_Projects/Tortue/04_Construction/OutfittingCommStatus_S/includes/primitives/Data'
import Con_SpoolSummarySize_Data from      '../../09_Projects/Tortue/04_Construction/SpoolSummarySize/includes/primitives/Data'
import Con_SpoolSummarySize_S_Data from      '../../09_Projects/Tortue/04_Construction/SpoolSummarySize_S/includes/primitives/Data'
import Con_SpoolSummarySize_L_Data from      '../../09_Projects/Tortue/04_Construction/SpoolSummarySize_L/includes/primitives/Data'
import Con_SpoolSummaryArea_Data from      '../../09_Projects/Tortue/04_Construction/SpoolSummaryArea/includes/primitives/Data'
import Con_AreaDashboard_Hull_AFT_Data from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L2_Hull/includes/primitives/Data'
import Con_AreaDashboard_Hull_FOR_Data from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L2_HullFOR/includes/primitives/Data'
import Con_AreaDashboard_Module_Data from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L2_Module/includes/primitives/Data'
import Con_AreaDashboard_Common_Data from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L2_Common/includes/primitives/Data'
import Con_AreaDashboard_L3_ME01_Data from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L3_ME04/includes/primitives/Data'
import Con_AreaDashboard_L3_ME02_Data from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L3_ME02/includes/primitives/Data'
import Con_AreaDashboard_L3_ME04_Data from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L3_ME04/includes/primitives/Data'
import Con_AreaDashboard_L3_ME05_Data from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L3_ME05/includes/primitives/Data'
import Con_AreaDashboard_L3_MW01_Data from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L3_MW01/includes/primitives/Data'
import Con_AreaDashboard_L3_MW02_Data from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L3_MW02/includes/primitives/Data'
import Con_AreaDashboard_L3_MW04_Data from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L3_MW04/includes/primitives/Data'
import Con_AreaDashboard_L3_MW05_Data from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L3_MW05/includes/primitives/Data'
import Con_AreaDashboard_L3_Common_Data from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L3_Common/includes/primitives/Data'
import Con_MoreBtn_Data from      '../../09_Projects/Tortue/04_Construction/MoreBtn/includes/primitives/Data'
import Con_AreaDashboardBarchart_Data from      '../../09_Projects/Tortue/04_Construction/AreaDashboardBarchart/includes/primitives/Data'
import Con_LQCOM_OGM_Data from      '../../09_Projects/Tortue/04_Construction/LQCOM_OGM/includes/primitives/Data'
import Con_LQCOM_ImgModal_Data from      '../../09_Projects/Tortue/04_Construction/LQCOM_ImgModal/includes/primitives/Data'
import Con_LQCOM_OGM_Modal_Data from      '../../09_Projects/Tortue/04_Construction/LQCOM_OGM_Modal/includes/primitives/Data'
import Con_LQCOM_Level_Data from      '../../09_Projects/Tortue/04_Construction/LQCOM_Level/includes/primitives/Data'
import Con_LQCOM_OvBlockBar_Data from      '../../09_Projects/Tortue/04_Construction/LQCOM_OvBlockBar/includes/primitives/Data'
import Con_LQCOM_OvEqBar_Data from      '../../09_Projects/Tortue/04_Construction/LQCOM_OvEqBar/includes/primitives/Data'
import Con_LQCOM_OVIMG_Data from      '../../09_Projects/Tortue/04_Construction/LQCOM_OVIMG/includes/primitives/Data'
import Con_HULLFORCOM_OVIMG_Data from      '../../09_Projects/Tortue/04_Construction/HULLFORCOM_OVIMG/includes/primitives/Data'
import Con_HULLAFTCOM_OVIMG_Data from      '../../09_Projects/Tortue/04_Construction/HULLAFTCOM_OVIMG/includes/primitives/Data'
import Con_LQCOM_Shop_Data from      '../../09_Projects/Tortue/04_Construction/LQCOM_Shop/includes/primitives/Data'
import Con_LQCOM_Shop_Modal_Data from      '../../09_Projects/Tortue/04_Construction/LQCOM_Shop_Modal/includes/primitives/Data'
import Con_LQCOM_ProgChart_Data from      '../../09_Projects/Tortue/04_Construction/LQCOM_ProgChart/includes/primitives/Data'
import Con_SimpleRect_Data from      '../../09_Projects/Tortue/04_Construction/SimpleRect/includes/primitives/Data'
import Con_ImagesBox_Data from              '../../09_Projects/Tortue/04_Construction/ImagesBox/includes/primitives/Data'
import Con_ImagesBox_6_Data from              '../../09_Projects/Tortue/04_Construction/ImagesBox_6/includes/primitives/Data'
import Con_ImagesBox_8_Data from              '../../09_Projects/Tortue/04_Construction/ImagesBox_8/includes/primitives/Data'
import Con_ModuleSummary_Data from              '../../09_Projects/Tortue/04_Construction/ModuleSummary/includes/primitives/Data'
import Con_PipingItem_Data from             '../../09_Projects/Tortue/04_Construction/PipingItem/includes/primitives/Data'
import Con_PipingOverall_Data from          '../../09_Projects/Tortue/04_Construction/PipingOverall/includes/primitives/Data'
import Con_DiscStatusTrackingIcon_Data from '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon/includes/primitives/Data'
import Con_DiscStatusTrackingIcon_architecture_Data from '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon_architecture/includes/primitives/Data'
import Con_DiscStatusTrackingIcon_electrical_Data from '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon_architecture/includes/primitives/Data'
import Con_DiscStatusTrackingIcon_hvac_Data from '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon_hvac/includes/primitives/Data'
import Con_DiscStatusTrackingIcon_instrument_Data from '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon_instrument/includes/primitives/Data'
import Con_DiscStatusTrackingIcon_insulation_Data from '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon_insulation/includes/primitives/Data'
import Con_DiscStatusTrackingIcon_mechanical_Data from '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon_mechanical/includes/primitives/Data'
import Con_DiscStatusTrackingIcon_painting_Data from '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon_painting/includes/primitives/Data'
import Con_DiscStatusTrackingIcon_safety_Data from '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon_safety/includes/primitives/Data'
import Con_DiscStatusTrackingIcon_structure_Data from '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon_structure/includes/primitives/Data'
import Con_DiscItemSummary_Data from '../../09_Projects/Tortue/04_Construction/DiscItemSummary/includes/primitives/Data'
import Con_DiscItemSummaryModal_Data from '../../09_Projects/Tortue/04_Construction/DiscItemSummaryModal/includes/primitives/Data'
import Con_DiscStatusSummaryTopsides_Data from '../../09_Projects/Tortue/04_Construction/DiscStatusSummaryTopsides/includes/primitives/Data'
import Con_TopsideLevel_Data from '../../09_Projects/Tortue/04_Construction/TopsideLevel/includes/primitives/Data'
import Con_TopsideModule_ME01_Data from '../../09_Projects/Tortue/04_Construction/TopsideModules_ME01/includes/primitives/Data'
import Con_BlockTable_Data from '../../09_Projects/Tortue/04_Construction/BlockTable/includes/primitives/Data'
import Con_HullCommonDashboard_Data from '../../09_Projects/Tortue/04_Construction/HullCommonDashboard/includes/primitives/Data'
import Con_HullCommonDivision_Data from '../../09_Projects/Tortue/04_Construction/HullCommonDivision/includes/primitives/Data'
import Con_CommoditySummary_Data from '../../09_Projects/Tortue/04_Construction/CommoditySummary/includes/primitives/Data'
import Con_CommoditySummaryHull_Data from '../../09_Projects/Tortue/04_Construction/CommoditySummaryHull/includes/primitives/Data'
import Con_CommoditySummaryTable_Data from '../../09_Projects/Tortue/04_Construction/CommoditySummaryTable/includes/primitives/Data'
import Con_DiscIconStatusChart_Data from '../../09_Projects/Tortue/04_Construction/DiscIconStatusChart/includes/primitives/Data'
import Con_TopsideModulesChartAll_Data from '../../09_Projects/Tortue/04_Construction/TopsideModulesChartAll/includes/primitives/Data'
import Con_IntegrationSummaryTablee_Data from '../../09_Projects/Tortue/04_Construction/IntegrationSummaryTablee/includes/primitives/Data'
import Con_IntegrationSummaryPie_Data from '../../09_Projects/Tortue/04_Construction/IntegrationSummaryPie/includes/primitives/Data'
import Con_PunchTable_Data from '../../09_Projects/Tortue/04_Construction/PunchTable/includes/primitives/Data'
import Con_DisciplineItem_Data from '../../09_Projects/Tortue/04_Construction/DisciplineItem/includes/primitives/Data'
import Con_WorkforceChart_Data from '../../09_Projects/Tortue/04_Construction/WorkforceChart/includes/primitives/Data'
import Con_DisciplineItemSchedule_Data from '../../09_Projects/Tortue/04_Construction/DisciplineItemSchedule/includes/primitives/Data'

// Quality Control
import QC_WeldingRepairTrendChart_Data from    '../../09_Projects/Tortue/06_QualityControl/WeldingRepairTrendChart/includes/primitives/Data'
import QC_WeldingRepairSummaryUT_Data from    '../../09_Projects/Tortue/06_QualityControl/WeldingRepairSummaryUT/includes/primitives/Data'
import QC_WeldingRepairSummaryRT_Data from    '../../09_Projects/Tortue/06_QualityControl/WeldingRepairSummaryRT/includes/primitives/Data'
import QC_WeldingRepairSummaryPAUT_Data from    '../../09_Projects/Tortue/06_QualityControl/WeldingRepairSummaryPAUT/includes/primitives/Data'
import QC_WeldingRepairSummaryMT_Data from    '../../09_Projects/Tortue/06_QualityControl/WeldingRepairSummaryMT/includes/primitives/Data'
import QC_3DBlockDivision_Data from    '../../09_Projects/Tortue/06_QualityControl/3DBlockDivision/includes/primitives/Data'
import QC_DashboardBarchart_Data from    '../../09_Projects/Tortue/06_QualityControl/DashboardBarchart/includes/primitives/Data'

// System Completion
import SC_MCSkyline_Data from               '../../09_Projects/Tortue/09_SystemCompletion/MC_Skyline/includes/primitives/Data'
import SC_MCSkylineRev_Data from               '../../09_Projects/Tortue/09_SystemCompletion/MC_Skyline_Rev/includes/primitives/Data'
import SC_MCSkylineB32_Data from               '../../09_Projects/Tortue/09_SystemCompletion/MC_Skyline_B32/includes/primitives/Data'
import SC_WDSkyline_Data from               '../../09_Projects/Tortue/09_SystemCompletion/WD_Skyline/includes/primitives/Data'
import SC_WDSkylineRev_Data from               '../../09_Projects/Tortue/09_SystemCompletion/WD_Skyline/includes/primitives/Data'
import SC_WD_Skyline_B32_Data from               '../../09_Projects/Tortue/09_SystemCompletion/WD_Skyline_B32/includes/primitives/Data'
import SC_ElectricalSkyline_Data from               '../../09_Projects/Tortue/09_SystemCompletion/ElectricalSkyline/includes/primitives/Data'
import SC_RfdcSkyline_Data from               '../../09_Projects/Tortue/09_SystemCompletion/RFDC_Skyline/includes/primitives/Data'
import SC_RfdcSummary_Data from               '../../09_Projects/Tortue/09_SystemCompletion/RFDC_Summary/includes/primitives/Data'
import SC_RfdcDonut_Data from               '../../09_Projects/Tortue/09_SystemCompletion/RFDC_Donut/includes/primitives/Data'
import SC_WeeklySummaryDonut_Data from               '../../09_Projects/Tortue/09_SystemCompletion/WeeklySummaryDonut/includes/primitives/Data'
import SC_RfdcAssociated_Data from               '../../09_Projects/Tortue/09_SystemCompletion/RFDC_Associated/includes/primitives/Data'
import SC_WeeklyReport_Data from               '../../09_Projects/Tortue/09_SystemCompletion/WeeklyReport/includes/primitives/Data'
import SC_PipingTestpackSkyline_Data from               '../../09_Projects/Tortue/09_SystemCompletion/Piping_Testpack_Skyline/includes/primitives/Data'
import SC_MCSkyline_Home_Data from               '../../09_Projects/Tortue/09_SystemCompletion/MC_Skyline_Home/includes/primitives/Data'
import SC_TestpackList_Data from               '../../09_Projects/Tortue/09_SystemCompletion/TestpackList/includes/primitives/Data'
import SC_TestpackSummary_Data from               '../../09_Projects/Tortue/09_SystemCompletion/TestpackSummary/includes/primitives/Data'
import SC_TestpackQCFSummary_Data from               '../../09_Projects/Tortue/09_SystemCompletion/TestpackQCFSummary/includes/primitives/Data'
import SC_TestpackDownload_Data from               '../../09_Projects/Tortue/09_SystemCompletion/downloadTestpack/includes/primitives/Data'
import SC_OverallMCDashboard_Data from               '../../09_Projects/Tortue/09_SystemCompletion/OverallMCDashboard/includes/primitives/Data'
import SC_OvImage_Data from               '../../09_Projects/Tortue/09_SystemCompletion/OverallImage/includes/primitives/Data'
import SC_TopsideSummary_Data from               '../../09_Projects/Tortue/09_SystemCompletion/TopsideSummary/includes/primitives/Data'
import SC_PunchDiscipline_Data from               '../../09_Projects/Tortue/09_SystemCompletion/PunchDiscipline/includes/primitives/Data'
import SC_PunchStatus_Data from               '../../09_Projects/Tortue/09_SystemCompletion/PunchStatus/includes/primitives/Data'
import SC_PunchTable_Data from               '../../09_Projects/Tortue/09_SystemCompletion/PunchTable/includes/primitives/Data'
import SC_PunchDiscRows_Data from               '../../09_Projects/Tortue/09_SystemCompletion/PunchDisciplineRows/includes/primitives/Data'
import SC_PunchElectrical_Data from               '../../09_Projects/Tortue/09_SystemCompletion/punch_electrical/includes/primitives/Data'
import SC_PunchMecandpip_Data from               '../../09_Projects/Tortue/09_SystemCompletion/punch_mepi/includes/primitives/Data'
import SC_PunchOverall_Data from               '../../09_Projects/Tortue/09_SystemCompletion/punch_overall/includes/primitives/Data'
import SC_OnlyPieChart_Data from               '../../09_Projects/Tortue/09_SystemCompletion/MC_OnlyPieChart/includes/primitives/Data'
import SC_MC_PieChart_Data from               '../../09_Projects/Tortue/09_SystemCompletion/MC_PieChart/includes/primitives/Data'
import SC_MC_OnlyPiePunchChart_Data from               '../../09_Projects/Tortue/09_SystemCompletion/MC_OnlyPiePunchChart/includes/primitives/Data'
import SC_HomeOverallTable_Data from               '../../09_Projects/Tortue/09_SystemCompletion/HomeOverallTable/includes/primitives/Data'
import SC_SkylineLegend_Data from               '../../09_Projects/Tortue/09_SystemCompletion/SkylineLegend/includes/primitives/Data'
import SC_MC_SysStatus_Data from               '../../09_Projects/Tortue/09_SystemCompletion/MC_SysStatus/includes/primitives/Data'
import SC_MC_SysStatus_Home_Data from               '../../09_Projects/Tortue/09_SystemCompletion/MC_SysStatus_Home/includes/primitives/Data'
import SC_MC_Equipment_Comm_Data from               '../../09_Projects/Tortue/09_SystemCompletion/EquipmentRoom_Commencement/includes/primitives/Data'
import SC_MC_Equipment_Pack_Data from               '../../09_Projects/Tortue/09_SystemCompletion/EquipmentRoom_Package/includes/primitives/Data'
import SC_MC_SatCompletion_Data from               '../../09_Projects/Tortue/09_SystemCompletion/SatCompletion/includes/primitives/Data'
import SC_MC_LoopTestSkyline_Data from               '../../09_Projects/Tortue/09_SystemCompletion/LoopTestSkyline/includes/primitives/Data'
import SC_MC_KsgSkyline_Data from               '../../09_Projects/Tortue/09_SystemCompletion/KsgSkyline/includes/primitives/Data'
import SC_MC_SoftwareSkyline_Data from               '../../09_Projects/Tortue/09_SystemCompletion/SoftwareSkyline/includes/primitives/Data'
import SC_ICFTestSkyline_Data from               '../../09_Projects/Tortue/09_SystemCompletion/ICFTestSkyline/includes/primitives/Data'
import SC_QCFSummary_Data from               '../../09_Projects/Tortue/09_SystemCompletion/QCFSummary/includes/primitives/Data'
import SC_QCFSummary_A_Data from               '../../09_Projects/Tortue/09_SystemCompletion/QCFSummary_A/includes/primitives/Data'
import SC_QCFTable_Data from               '../../09_Projects/Tortue/09_SystemCompletion/QCFTable/includes/primitives/Data'
import SC_QCFSummary_ICFTEST_Data from               '../../09_Projects/Tortue/09_SystemCompletion/QCFSummary_ICFTEST/includes/primitives/Data'
import SC_QCFTable_ICFTEST_Data from               '../../09_Projects/Tortue/09_SystemCompletion/QCFTable_ICFTEST/includes/primitives/Data'
import SC_QCFSummary_A_ICFTEST_Data from               '../../09_Projects/Tortue/09_SystemCompletion/QCFSummary_A_ICFTEST/includes/primitives/Data'
import SC_QCFTable_A_ICFTEST_Data from               '../../09_Projects/Tortue/09_SystemCompletion/QCFTable_A_ICFTEST/includes/primitives/Data'
import SC_LeakTestSkyline_Data from               '../../09_Projects/Tortue/09_SystemCompletion/LeakTestSkyline/includes/primitives/Data'
import SC_QCFSummary_CYBER_Data from               '../../09_Projects/Tortue/09_SystemCompletion/QCFSummary_CYBER/includes/primitives/Data'
import SC_QCFTable_CYBER_Data from               '../../09_Projects/Tortue/09_SystemCompletion/QCFTable_CYBER/includes/primitives/Data'
import SC_QCFSummary_A_CYBER_Data from               '../../09_Projects/Tortue/09_SystemCompletion/QCFSummary_CYBER/includes/primitives/Data'
import SC_QCFTable_A_CYBER_Data from               '../../09_Projects/Tortue/09_SystemCompletion/QCFTable_CYBER/includes/primitives/Data'
import SC_CyberSecuritySkyline_Data from               '../../09_Projects/Tortue/09_SystemCompletion/CyberSecuritySkyline/includes/primitives/Data'
import SC_PccSkyline_Data from               '../../09_Projects/Tortue/09_SystemCompletion/PccSkyline/includes/primitives/Data'
import SC_QCFSummary_TELECOMS_Data from               '../../09_Projects/Tortue/09_SystemCompletion/QCFSummary_TELECOMS/includes/primitives/Data'
import SC_QCFTable_TELECOMS_Data from               '../../09_Projects/Tortue/09_SystemCompletion/QCFTable_TELECOMS/includes/primitives/Data'
import SC_TelecomsSkyline_Data from               '../../09_Projects/Tortue/09_SystemCompletion/TelecomsSkyline/includes/primitives/Data'
import SC_QCFSummary_SOFTWARE_Data from               '../../09_Projects/Tortue/09_SystemCompletion/QCFSummary_SOFTWARE/includes/primitives/Data'
import SC_QCFTable_SOFTWARE_Data from               '../../09_Projects/Tortue/09_SystemCompletion/QCFTable_SOFTWARE/includes/primitives/Data'
import SC_RFSU_IMAGE_Data from               '../../09_Projects/Tortue/09_SystemCompletion/RFSU_Image/includes/primitives/Data'
import SC_RFSU_Skyline_Data from               '../../09_Projects/Tortue/09_SystemCompletion/RFSU_Skyline/includes/primitives/Data'

import SC_QCFSummary_B_SOFTWARE_Data from               '../../09_Projects/Tortue/09_SystemCompletion/QCFSummary_B_SOFTWARE/includes/primitives/Data'
import SC_QCFTable_B_SOFTWARE_Data from               '../../09_Projects/Tortue/09_SystemCompletion/QCFTable_B_SOFTWARE/includes/primitives/Data'
import SC_WeektReportTable_Data from               '../../09_Projects/Tortue/09_SystemCompletion/WeektReportTable/includes/primitives/Data'
import SC_WeeklyReportTable_MC_Data from               '../../09_Projects/Tortue/09_SystemCompletion/WeeklyReportTable_MC/includes/primitives/Data'
import SC_WeeklyReportTableChart_Data from               '../../09_Projects/Tortue/09_SystemCompletion/WeeklyReportTableChart/includes/primitives/Data'
import SC_QCFSummary_KSGA_Data from               '../../09_Projects/Tortue/09_SystemCompletion/QCFSummary_KSGA/includes/primitives/Data'
import SC_QCFTable_KSGA_Data from               '../../09_Projects/Tortue/09_SystemCompletion/QCFTable_KSGA/includes/primitives/Data'
import SC_QCFSummary_PCCA_Data from      '../../09_Projects/Tortue/09_SystemCompletion/QCFSummary_PCC_A/includes/primitives/Data'
import SC_QCFSummary_PCCB_Data from      '../../09_Projects/Tortue/09_SystemCompletion/QCFSummary_PCC_B/includes/primitives/Data'
import SC_QCFTable_PCCA_Data from      '../../09_Projects/Tortue/09_SystemCompletion/QCFTable_PCC_A/includes/primitives/Data'
import SC_QCFTable_PCCB_Data from      '../../09_Projects/Tortue/09_SystemCompletion/QCFTable_PCC_B/includes/primitives/Data'
import SC_Sys_MC_ITRB_MePi_Data from               '../../09_Projects/Tortue/09_SystemCompletion/Sys_MC_ITRB/includes/primitives/Data'
import SC_Disc_Package_Data from               '../../09_Projects/Tortue/09_SystemCompletion/Disc_Package/includes/primitives/Data'
import SC_WD_durationTable_Data from               '../../09_Projects/Tortue/09_SystemCompletion/WD_durationTable/includes/primitives/Data'
import SC_ProgressCurve_Data from               '../../09_Projects/Tortue/09_SystemCompletion/ProgressCurve/includes/primitives/Data'
import SC_ProgressCurveOverall_Data from               '../../09_Projects/Tortue/09_SystemCompletion/ProgressCurveOverall/includes/primitives/Data'
import SC_MonthBarChart_Data from               '../../09_Projects/Tortue/09_SystemCompletion/MonthBarChart/includes/primitives/Data'
import SC_WeeklyReportTable_WD_Data from               '../../09_Projects/Tortue/09_SystemCompletion/WeeklyReportTable_WD/includes/primitives/Data'
import SC_MC_DB_ITRStatus_New_Data from               '../../09_Projects/Tortue/09_SystemCompletion/MC_DB_ITRStatus_New/includes/primitives/Data'
import SC_MC_DB_ITRStatus_Boundary_Data from               '../../09_Projects/Tortue/09_SystemCompletion/MC_DB_ITRStatus_Boundary/includes/primitives/Data'
import SC_PackageChart_Data from               '../../09_Projects/Tortue/09_SystemCompletion/PackageChart/includes/primitives/Data'
import SC_CategoryChart_Data from               '../../09_Projects/Tortue/09_SystemCompletion/CategoryChart/includes/primitives/Data'
import SC_BarTitle_Data from               '../../09_Projects/Tortue/09_SystemCompletion/BarTitle/includes/primitives/Data'
import SC_LQPieChart_Data from               '../../09_Projects/Tortue/09_SystemCompletion/LQPieChart/includes/primitives/Data'
import SC_LQTable_Data from               '../../09_Projects/Tortue/09_SystemCompletion/LQTable/includes/primitives/Data'
import SC_LQRoom_Data from               '../../09_Projects/Tortue/09_SystemCompletion/LQRoom/includes/primitives/Data'




import SC_CommissioningSkyline_Data from    '../../09_Projects/MD2/09_SystemCompletion/CommissioningSkyline/includes/primitives/Data'
  
import SC_MC_DB_SysStatus_Data from         '../../09_Projects/MD2/09_SystemCompletion/MC_DB_SysStatus/includes/primitives/Data'
import SC_MC_DB_ITRStatus_Data from         '../../09_Projects/MD2/09_SystemCompletion/MC_DB_ITRStatus/includes/primitives/Data'
import SC_MC_DB_ITRStatus_B_Data from         '../../09_Projects/MD2/09_SystemCompletion/MC_DB_ITRStatus_B/includes/primitives/Data'
import SC_MC_DB_PunchTrends_Data from       '../../09_Projects/MD2/09_SystemCompletion/MC_DB_PunchTrends/includes/primitives/Data'
import SC_MC_DB_PunchTrends_NoChart_Data from       '../../09_Projects/MD2/09_SystemCompletion/MC_DB_PunchTrends_noChart/includes/primitives/Data'
  
import SC_Table_Lookahead_Data from         '../../09_Projects/MD2/09_SystemCompletion/Table_Lookahead/includes/primitives/Data'
import SC_Table_MostOpen_Data from          '../../09_Projects/MD2/09_SystemCompletion/Table_MostOpen/includes/primitives/Data'
import SC_Table_PassedPunch_Data from       '../../09_Projects/MD2/09_SystemCompletion/Table_PassedPunch/includes/primitives/Data'
  
import SC_Disc_DB_ITRStatus_Data from       '../../09_Projects/MD2/09_SystemCompletion/Disc_DB_ITRStatus/includes/primitives/Data'
import SC_Disc_DB_Package_Data from         '../../09_Projects/MD2/09_SystemCompletion/Disc_DB_Package/includes/primitives/Data'
  
import SC_SChart_ITR_Data from              '../../09_Projects/MD2/09_SystemCompletion/SChart_ITR/includes/primitives/Data'
import SC_SChart_Punch_Data from            '../../09_Projects/MD2/09_SystemCompletion/SChart_Punch/includes/primitives/Data'
import SC_Chart_Punch_Data from             '../../09_Projects/MD2/09_SystemCompletion/Chart_Punch/includes/primitives/Data'
import SC_Punch_Disc_Data from              '../../09_Projects/MD2/09_SystemCompletion/Punch_Discipline/includes/primitives/Data'
  
import SC_Sys_MC_Overall_Data from          '../../09_Projects/MD2/09_SystemCompletion/Sys_MC_Overall/includes/primitives/Data'
import SC_Sys_MC_Overall_No_Desc_Data from          '../../09_Projects/MD2/09_SystemCompletion/Sys_MC_Overall_No_Desc/includes/primitives/Data'
import SC_Sys_MC_Overall_B2_Data from          '../../09_Projects/MD2/09_SystemCompletion/Sys_MC_Overall_B2/includes/primitives/Data'
import SC_Sys_MC_Overall_AllPunch_Data from          '../../09_Projects/MD2/09_SystemCompletion/Sys_MC_Overall_AllPunch/includes/primitives/Data'
import SC_Sys_DownloadBtn_Data from          '../../09_Projects/MD2/09_SystemCompletion/Sys_DownloadBtn/includes/primitives/Data'
import SC_Sys_MC_ITRA_Data from             '../../09_Projects/MD2/09_SystemCompletion/Sys_MC_ITRA/includes/primitives/Data'
import SC_Sys_MC_ITRB_Data from             '../../09_Projects/MD2/09_SystemCompletion/Sys_MC_ITRB/includes/primitives/Data'
import SC_Sys_MC_PunchChart_Data from       '../../09_Projects/MD2/09_SystemCompletion/Sys_MC_PunchChart/includes/primitives/Data'
import SC_Sys_MC_PunchDisc_Data from        '../../09_Projects/MD2/09_SystemCompletion/Sys_MC_PunchDisc/includes/primitives/Data'
import SC_Sys_SC_Overall_Data from          '../../09_Projects/MD2/09_SystemCompletion/Sys_SC_Overall/includes/primitives/Data'
import SC_Sys_SC_ITRB_Data from             '../../09_Projects/MD2/09_SystemCompletion/Sys_SC_ITRB/includes/primitives/Data'
import SC_Sys_SC_Boundary_Data from         '../../09_Projects/MD2/09_SystemCompletion/Sys_SC_Boundary/includes/primitives/Data'
  
import SC_Reg_COISkyline_Data from          '../../09_Projects/MD2/11_Regulatory/COI_Skyline/includes/primitives/Data'
  
// Commissioning  
import COM_OverallSummary_Data from         '../../09_Projects/MD2/10_Commissioning/OverallSummary/includes/primitives/Data'
import COM_Procedures_Data from             '../../09_Projects/MD2/10_Commissioning/Procedures/includes/primitives/Data'
import COM_ITR_B_Data from                  '../../09_Projects/MD2/10_Commissioning/ITR_B/includes/primitives/Data'
import COM_Discipline_Data from             '../../09_Projects/MD2/10_Commissioning/Discipline/includes/primitives/Data'
  
// Commissioning  
import RGT_COI_Skyline_Data from            '../../09_Projects/MD2/11_Regulatory/COI_Skyline/includes/primitives/Data'
import RGT_Milestones_Data from             '../../09_Projects/MD2/11_Regulatory/Milestones/includes/primitives/Data'
import RGT_OverallSummary_Data from         '../../09_Projects/MD2/11_Regulatory/OverallSummary/includes/primitives/Data'
import RGT_RTP_Data from                    '../../09_Projects/MD2/11_Regulatory/RTP/includes/primitives/Data'
import RGT_USCG_Data from                   '../../09_Projects/MD2/11_Regulatory/USCG/includes/primitives/Data'

/* 
  ### Chart Customized - From the TCO Project ### 
*/
//HomePage
import Home_ModuleStatus_Data from '../../09_Projects/TCO/00_Homepage/ModuleStatus/includes/primitives/Data'
import Home_OverallProgress_Data from '../../09_Projects/TCO/00_Homepage/OverallProgress/includes/primitives/Data'
import Home_SailawayStatus_Data from '../../09_Projects/TCO/00_Homepage/SailawayStatus/includes/primitives/Data'

//Planning
import Const_Milestones_Data from '../../09_Projects/TCO/03_Planning/Milestones/includes/primitives/Data'





// China1
import CH_Project_visionwheel_Data from '../../09_Projects/Tortue/01_HSSE/visionWheel/includes/primitives/Data'
import CH_Project_yardDashbaord_Data from '../../09_Projects/Tortue/01_HSSE/yardDashboard/includes/primitives/Data'

//01_Project
import CH_Project_vision_Data from '../../09_Projects/China1/01_Project/vision/includes/primitives/Data'
import CH_TaskSchedule_Data from '../../09_Projects/China1/03_Planning/TaskScheduleImage/includes/primitives/Data'
import CH_LevelSchedule_Data from '../../09_Projects/China1/03_Planning/level1scheduleImage/includes/primitives/Data'
import CH_Level2Schedule_Data from '../../09_Projects/China1/03_Planning/Level2Schedule/includes/primitives/Data'
import CH_Level2SchedulePackage_Data from '../../09_Projects/China1/03_Planning/Level2SchedulePackage/includes/primitives/Data'
import CH_LeadershipTeam_Data from '../../09_Projects/China1/01_Project/leadershipTeam/includes/primitives/Data'
import CH_ImageSVGDownload_Data from '../../09_Projects/China1/01_Project/ImageSVGDownload/includes/primitives/Data'
import CH_ImageSVGDownload_GPPE_Data from '../../09_Projects/China1/01_Project/ImageSVGDownload_GPPE/includes/primitives/Data'
import CH_ImageSVGDownload_LDPE_Data from '../../09_Projects/China1/01_Project/ImageSVGDownload_LDPE/includes/primitives/Data'
import CH_ImageSVGDownload_P3_Data from '../../09_Projects/China1/01_Project/ImageSVGDownload_P3/includes/primitives/Data'
import CH_ImageSVGDownload_PP_Data from '../../09_Projects/China1/01_Project/ImageSVGDownload_PP/includes/primitives/Data'

//00_Home
import CH_Project_yard_Data from '../../09_Projects/China1/00_Home/Yard/includes/primitives/Data'
import CH_Project_SiteDashboard_Data from '../../09_Projects/China1/00_Home/SiteDashboard/includes/primitives/Data'
import CH_MonthlyChart_Data from '../../09_Projects/China1/00_Home/MonthlyChart/includes/primitives/Data'
import CH_MonthlyChart2_Data from '../../09_Projects/China1/00_Home/MonthlyChart2/includes/primitives/Data'
import CH_MonthlyChart3_Data from '../../09_Projects/China1/00_Home/MonthlyChart3/includes/primitives/Data'
import CH_MonthlyChartLegend4_Data from '../../09_Projects/China1/00_Home/MonthlyChartLegend4/includes/primitives/Data'
import CH_SSHETable_Data from '../../09_Projects/China1/00_Home/SSHETable/includes/primitives/Data'
import CH_PTD_Data from '../../09_Projects/China1/00_Home/PTD/includes/primitives/Data'
import CH_MOVE_BTN_Data from '../../09_Projects/China1/00_Home/MoveButton/includes/primitives/Data'
import CH_InterfacePieChart_Data from '../../09_Projects/China1/00_Home/InterfacePieChart/includes/primitives/Data'
import CH_HomePsrStackChart_Data from '../../09_Projects/China1/00_Home/HomePsrStackChart/includes/primitives/Data'
import CH_NormalTable_Data from '../../09_Projects/China1/00_Home/NormalTable/includes/primitives/Data'
import CH_ProgTable_Data from '../../09_Projects/China1/00_Home/ProgTable/includes/primitives/Data'
import CH_Pyramid_Data from '../../09_Projects/China1/00_Home/Pyramid/includes/primitives/Data'
import CH_SWSTable_Data from '../../09_Projects/China1/00_Home/SWSTable/includes/primitives/Data'
import CH_ScurveChart_Data from '../../09_Projects/China1/00_Home/ScurveChart/includes/primitives/Data'
import CH_FreeText_Data from '../../09_Projects/China1/00_Home/FreeText/includes/primitives/Data'
import CH_FreeText2_Data from '../../09_Projects/China1/00_Home/FreeText2/includes/primitives/Data'
import CH_FreeText_2_Data from '../../09_Projects/China1/00_Home/FreeText_2/includes/primitives/Data'
import CH_FreeText2_2_Data from '../../09_Projects/China1/00_Home/FreeText2_2/includes/primitives/Data'
import CH_NormalNoData_Data from '../../09_Projects/China1/00_Home/NormalNoData/includes/primitives/Data'
import CH_ProgNoData_Data from '../../09_Projects/China1/00_Home/ProgNoData/includes/primitives/Data'


//02_Engineering
import CH_Interface_Data from '../../09_Projects/China1/02_Engineering/Interface/includes/primitives/Data'
import CH_InterfaceTable_Data from '../../09_Projects/China1/02_Engineering/InterfaceTable/includes/primitives/Data'
import CH_summaryTable_Data from '../../09_Projects/China1/02_Engineering/summaryTable/includes/primitives/Data'
import CH_summaryTable_impact_Data from '../../09_Projects/China1/02_Engineering/summaryTable_impact/includes/primitives/Data'
import CH_summaryTable_progress_Data from '../../09_Projects/China1/02_Engineering/summaryTable_progress/includes/primitives/Data'
import CH_summaryTable_received_Data from '../../09_Projects/China1/02_Engineering/summaryTable_received/includes/primitives/Data'
import CH_summaryTable_responded_Data from '../../09_Projects/China1/02_Engineering/summaryTable_responded/includes/primitives/Data'
import CH_InterfaceInfo_Data from '../../09_Projects/China1/02_Engineering/InterfaceInfo/includes/primitives/Data'
import CH_AreaInfo_Data from '../../09_Projects/China1/02_Engineering/AreaInfo/includes/primitives/Data'
import CH_AreaTable_Data from '../../09_Projects/China1/02_Engineering/AreaTable/includes/primitives/Data'
import CH_InterfaceRelationMap_Data from '../../09_Projects/China1/02_Engineering/InterfaceRelationMap/includes/primitives/Data'
import CH_InterfaceTeam_Data from '../../09_Projects/China1/02_Engineering/InterfaceTeam/includes/primitives/Data'
import CH_TQSummayChart_Data from '../../09_Projects/China1/02_Engineering/TQSummayChart/includes/primitives/Data'
import CH_TQWKEndingChart_Data from '../../09_Projects/China1/02_Engineering/TQWKEndingChart/includes/primitives/Data'
import CH_TQSummaryTable1_Data from '../../09_Projects/China1/02_Engineering/TQSummaryTable1/includes/primitives/Data'
import CH_TQSummaryTable2_Data from '../../09_Projects/China1/02_Engineering/TQSummaryTable2/includes/primitives/Data'
import CH_TQSummaryBox_Data from '../../09_Projects/China1/02_Engineering/TQSummaryBox/includes/primitives/Data'
import CH_TQSummaryTable1_Origin_Data from '../../09_Projects/China1/02_Engineering/TQSummaryTable1_Origin/includes/primitives/Data'
import CH_TQSummaryTable2_Origin_Data from '../../09_Projects/China1/02_Engineering/TQSummaryTable2_Origin/includes/primitives/Data'
import CH_TQSummaryTable1_Package_Data from '../../09_Projects/China1/02_Engineering/TQSummaryTable1_Package/includes/primitives/Data'
import CH_TQSummaryTable2_Package_Data from '../../09_Projects/China1/02_Engineering/TQSummaryTable2_Package/includes/primitives/Data'
import CH_DEVSummaryBox_Data from '../../09_Projects/China1/02_Engineering/DEVSummaryBox/includes/primitives/Data'
import CH_summaryTableDEV_Data from '../../09_Projects/China1/02_Engineering/summaryTableDEV/includes/primitives/Data'
import CH_summaryTableDEV_impact_Data from '../../09_Projects/China1/02_Engineering/summaryTableDEV_impact/includes/primitives/Data'
import CH_summaryTableDEV_progress_Data from '../../09_Projects/China1/02_Engineering/summaryTableDEV_progress/includes/primitives/Data'
import CH_summaryTableDEV_received_Data from '../../09_Projects/China1/02_Engineering/summaryTableDEV_received/includes/primitives/Data'
import CH_summaryTableDEV_responded_Data from '../../09_Projects/China1/02_Engineering/summaryTableDEV_responded/includes/primitives/Data'
import CH_DEVSummaryTable1_Disc_Data from '../../09_Projects/China1/02_Engineering/DEVSummaryTable1_Disc/includes/primitives/Data'
import CH_DEVSummaryTable2_Disc_Data from '../../09_Projects/China1/02_Engineering/DEVSummaryTable2_Disc/includes/primitives/Data'
import CH_DEVSummaryTable1_Origin_Data from '../../09_Projects/China1/02_Engineering/DEVSummaryTable1_Origin/includes/primitives/Data'
import CH_DEVSummaryTable2_Origin_Data from '../../09_Projects/China1/02_Engineering/DEVSummaryTable2_Origin/includes/primitives/Data'
import CH_DEVSummaryTable1_Package_Data from '../../09_Projects/China1/02_Engineering/DEVSummaryTable1_Package/includes/primitives/Data'
import CH_DEVSummaryTable2_Package_Data from '../../09_Projects/China1/02_Engineering/DEVSummaryTable2_Package/includes/primitives/Data'
import CH_SatusTable_Data from '../../09_Projects/China1/02_Engineering/SatusTable/includes/primitives/Data'
import CH_MDRCategoryChart_Data from '../../09_Projects/China1/02_Engineering/MDRCategoryChart/includes/primitives/Data'
import CH_MDRStackChart_Data from '../../09_Projects/China1/02_Engineering/MDRStackChart/includes/primitives/Data'
import CH_MDRSummaryTable_Data from '../../09_Projects/China1/02_Engineering/MDRSummaryTable/includes/primitives/Data'
import CH_MDRSummaryTable_Aconex_Data from '../../09_Projects/China1/02_Engineering/MDRSummaryTable_Aconex/includes/primitives/Data'
import CH_OverdueBarChart_Data from '../../09_Projects/China1/02_Engineering/OverdueBarChart/includes/primitives/Data'
import CH_ReviewBarChart_Data from '../../09_Projects/China1/02_Engineering/ReviewBarChart/includes/primitives/Data'
import CH_SupplierStackChart_Data from '../../09_Projects/China1/02_Engineering/SupplierStackChart/includes/primitives/Data'
import CH_DocumentStackChart_Data from '../../09_Projects/China1/02_Engineering/DocumentStackChart/includes/primitives/Data'
import CH_DocumentStepStackChart_Data from '../../09_Projects/China1/02_Engineering/DocumentStepStackChart/includes/primitives/Data'
import CH_DocumentSummaryTable_Data from '../../09_Projects/China1/02_Engineering/DocumentSummaryTable/includes/primitives/Data'
import CH_ColumnBarChart_Data from '../../09_Projects/China1/02_Engineering/ColumnBarChart/includes/primitives/Data'
import CH_OneBarChart_Data from '../../09_Projects/China1/02_Engineering/OneBarChart/includes/primitives/Data'
import CH_ColumnBarOneColor_Data from '../../09_Projects/China1/02_Engineering/ColumnBarOneColor/includes/primitives/Data'
import CH_MDRCategoryChart_Disc_Data from '../../09_Projects/China1/02_Engineering/MDRCategoryChart_Disc/includes/primitives/Data'
import CH_MDRCategoryChart_Sub_Data from '../../09_Projects/China1/02_Engineering/MDRCategoryChart_Sub/includes/primitives/Data'
import CH_LSSummaryBox_Data from '../../09_Projects/China1/02_Engineering/LSSummaryBox/includes/primitives/Data'
import CH_LSSummaryTable_Data from '../../09_Projects/China1/02_Engineering/LSSummaryTable/includes/primitives/Data'
import CH_LSSummaryTable_Disc_Data from '../../09_Projects/China1/02_Engineering/LSSummaryTable_Disc/includes/primitives/Data'
import CH_CategoryChart_ShortScroll_Data from '../../09_Projects/China1/02_Engineering/CategoryChart_ShortScroll/includes/primitives/Data'


//03_Planning
import CH_LMS_Skyline_Data from '../../09_Projects/China1/03_Planning/LeaderMilestone/includes/primitives/Data'
import CH_LMS_GanttChart_Data from '../../09_Projects/China1/03_Planning/LMSGanttChart/includes/primitives/Data'
import CH_TeamOrganization_Data from '../../09_Projects/China1/03_Planning/TeamOrganization/includes/primitives/Data'
import CH_PackageTable_Data from '../../09_Projects/China1/03_Planning/PackageTable/includes/primitives/Data'
import CH_SummaryTable_Data from '../../09_Projects/China1/03_Planning/SummaryTable/includes/primitives/Data'
import CH_ImagePageSvg_Data from '../../09_Projects/China1/03_Planning/ImagePageSvg/includes/primitives/Data'
import CH_IcircleChart_Data from '../../09_Projects/China1/03_Planning/icircleChart/includes/primitives/Data'

//04_Construction
import CH_Project_PillingTable_Data from '../../09_Projects/China1/04_Construction/PillingTable/includes/primitives/Data'
import CH_Project_CivilTable_Data from '../../09_Projects/China1/04_Construction/CivilTable/includes/primitives/Data'
import CH_Project_StatusComboChart_Data from '../../09_Projects/China1/04_Construction/StatusComboChart/includes/primitives/Data'
import CH_HeavyLiftingProgram_Data from '../../09_Projects/China1/04_Construction/HeavyLiftingProgram/includes/primitives/Data'
import CH_TextSummaryBox_Data from '../../09_Projects/China1/04_Construction/TextSummaryBox/includes/primitives/Data'
import CH_HComboChart_Data from '../../09_Projects/China1/04_Construction/HComboChart/includes/primitives/Data'
import CH_HSummaryTable_Data from '../../09_Projects/China1/04_Construction/HSummaryTable/includes/primitives/Data'
import CH_HTable_Data from '../../09_Projects/China1/04_Construction/HTable/includes/primitives/Data'
import CH_HTable_Dressup_Data from '../../09_Projects/China1/04_Construction/HTable_Dressup/includes/primitives/Data'
import CH_HTable_Lifting_Data from '../../09_Projects/China1/04_Construction/HTable_Lifting/includes/primitives/Data'
import CH_HeavyLiftSkyline_Data from '../../09_Projects/China1/04_Construction/HeavyLiftSkyline/includes/primitives/Data'
import CH_HStackChart_Data from '../../09_Projects/China1/04_Construction/HStackChart/includes/primitives/Data'
import CH_PunchTable_Data from '../../09_Projects/China1/04_Construction/PunchTable/includes/primitives/Data'
import CH_MajorStatus_Data from '../../09_Projects/China1/04_Construction/MajorStatus/includes/primitives/Data'
import CH_PhaseSummary_Data from '../../09_Projects/China1/04_Construction/PhaseSummary/includes/primitives/Data'

//05_Procurement
import CH_Project_PsrComboChart_Data from '../../09_Projects/China1/05_Procurement/PsrComboChart/includes/primitives/Data'
import CH_Project_GanttChart_Data from '../../09_Projects/China1/05_Procurement/GanttChart/includes/primitives/Data'
import CH_Project_PsrComboChart_DJS_Data from '../../09_Projects/China1/05_Procurement/PsrComboChart_DJS/includes/primitives/Data'
import CH_LOA_Data from '../../09_Projects/China1/05_Procurement/LoaPage/includes/primitives/Data'
import CH_Project_PsrComboChart_MR_Data from '../../09_Projects/China1/05_Procurement/PsrComboChart_MR/includes/primitives/Data'
import CH_Project_PsrBarChart_Data from '../../09_Projects/China1/05_Procurement/PsrBarChart/includes/primitives/Data'
import CH_Project_PsrStackChart_Data from '../../09_Projects/China1/05_Procurement/PsrStackChart/includes/primitives/Data'
import CH_Project_RosTable_Data from '../../09_Projects/China1/05_Procurement/RosTable/includes/primitives/Data'
import CH_EquipmentLayout_Data from '../../09_Projects/China1/05_Procurement/EquipmentLayout_V1/includes/primitives/Data'
import CH_progressBarScroll_Data from '../../09_Projects/China1/05_Procurement/progressBarScroll/includes/primitives/Data'

  //06_QualityControl
import CH_JianLiVisualMap_Data from '../../09_Projects/China1/06_QualityControl/JianLiVisualMap/includes/primitives/Data'
import CH_JianLiVisualMap_R_Data from '../../09_Projects/China1/06_QualityControl/JianLiVisualMap_R/includes/primitives/Data'
import CH_SiteMapIamge_Data from '../../09_Projects/China1/06_QualityControl/SiteMapIamge/includes/primitives/Data'
import CH_QCDashboardTable_Data from '../../09_Projects/China1/06_QualityControl/QCDashboardTable/includes/primitives/Data'
import CH_QCDashboardTable_Con_Data from '../../09_Projects/China1/06_QualityControl/QCDashboardTable_Con/includes/primitives/Data'
import CH_QCDashboardTable_Field_Data from '../../09_Projects/China1/06_QualityControl/QCDashboardTable_Field/includes/primitives/Data'
import CH_QCDashboardTable_Weld_Data from '../../09_Projects/China1/06_QualityControl/QCDashboardTable_Weld/includes/primitives/Data'
import CH_QCDashboradStackChart_Data from '../../09_Projects/China1/06_QualityControl/QCDashboradStackChart/includes/primitives/Data'



//07_SystemCompletion
import CH_SCDMRTable_Data from '../../09_Projects/China1/07_SystemCompletion/DMRTable/includes/primitives/Data'
import CH_SCMapKeyplan_Data from '../../09_Projects/China1/07_SystemCompletion/Map/includes/primitives/Data'
import CH_SCPunchDiscipline_Data from '../../09_Projects/China1/07_SystemCompletion/PunchDiscipline/includes/primitives/Data'
import CH_SCPunchStatus_Data from '../../09_Projects/China1/07_SystemCompletion/PunchStatus/includes/primitives/Data'
import CH_SCMC_SysStatus_Data from  '../../09_Projects/China1/07_SystemCompletion/MC_SysStatus/includes/primitives/Data'
import CH_SCMC_SysStatus_Home_Data from '../../09_Projects/China1/07_SystemCompletion/MC_SysStatus_Home/includes/primitives/Data'
import CH_SCMC_DB_ITRStatus_Data from  '../../09_Projects/China1/07_SystemCompletion/MC_DB_ITRStatus/includes/primitives/Data'
import CH_SCMC_DB_ITRStatus_B_Data from '../../09_Projects/China1/07_SystemCompletion/MC_DB_ITRStatus_B/includes/primitives/Data'
import CH_SCMC_DB_ITRStatus_UD_Data from '../../09_Projects/China1/07_SystemCompletion/MC_DB_ITRStatus_UD/includes/primitives/Data'
import CH_SC_Sys_SC_ITRB_Data from '../../09_Projects/China1/07_SystemCompletion/Sys_SC_ITRB/includes/primitives/Data'

export default {
  // Common
  CMN_HistoryTable_Data,  
  CMN_TBD_Data,
  CMN_Up2UText_Data,
  CMN_3DViewerEmbedded_Data,

  // System
  System_ChartOptions_Data,
  System_Dropdown_Data,
  System_SubMenu_01_Data,

  //Homepage
  Home_ProjectProgress_Data,
  Home_KeyMilestone_Data,
  Home_Observation_Data,
  Home_DataSource_Data,
  Home_Milestones_Data,
  Home_Milestones_R_Data,

  Home_MC_Pie_Data,
  Home_MC_SummaryProg_Data,
  Home_MC_ITR_Data,
  Home_MC_Punch_Data,
  Home_MoreButton_Data,
  Home_PriorityChart_Data,
  Home_TubeChart_Data,
  Home_WorkForceTable_Data,

  Home_performanceLineChart_Data,
  Home_performanceBarChart_ITR_Data,
  Home_performanceLineChart_punch_Data,
  Home_performanceBarChart_punch_Data,
  Home_performanceCompletionChart_Data,

  Home_performanceLineChart_B_Data, 
  Home_performanceBarChart_BITR_Data,
  Home_performanceLineChart_Bpunch_Data,
  Home_performanceBarChart_Bpunch_Data,
  Home_performanceCompletionChart_B_Data,

  Home_mcskyline_performance_Data,
  Home_ProceduresGauge_Data,
  Home_ProceduresGauge_Daily_Data,
  Home_SummaryTable_Data,
  
  // HSSE
  HSSE_SafetyPyramid_Data,
  HSSE_LSR_Data,
  HSSE_TSC_Data,
  HSSE_Dashboard_Data,
  
  //Planning
  Plan_ProgressSummaryTable_Data,
  Plan_ModuleSummarySchedule_Data,
  Plan_CommoditySummarySchedule_Data,
  Plan_SummaryByArea_Data,
  Plan_ModuleKeyMilestones_Data,
  TopsidesGanttChart_Data,
  Plan_FilterPhase_Data,
  Plan_SummaryByOutfittingCommodity_Data,
  Plan_SpoolSummary_Data,

  //Engineering
  Eng_CMDRStatus_Data,
  Eng_IFCDrawingList_Data,
  Eng_TotalPlanActualRemainL_Data,
  Eng_TotalPlanActualRemainM_Data,
  Eng_TotalPlanActualRemainS_Data,
  Eng_CDREHistoryChart_Data,
  Eng_TQHistoryChart_Data,
  Eng_SDIDashboard_Data,

  // Procurement
  Proc_EquipmentLayout_Data,
  Proc_EquipmentTable_Data,
  Proc_Equipment3DLayout_Data,
  Proc_EquipmentRoom_Data,
  Proc_ProgressBar_Data,
  Proc_Lookahead_Data,
  Proc_OWL_Data,
  Proc_OverallScurve_Data,
  Proc_FATScurve_Data,
  Proc_IRNScurve_Data,
  Proc_ETAScurve_Data,
  Proc_IRNLookahead_Data,
  Proc_ETALookahead_Data,
  Proc_EquipmentHullCommon_Data,

  // Construction
  Con_BlockDivision_Data,
  Con_BlockSummary_Data,
  Con_ActivityGanttChart_Data,
  Con_ActivityGanttChart_R1_Data,
  Con_ActivityGanttChartLegend_Data,
  Con_MeasurementBlock_Data,
  Con_ShopDrawingActivities_Data,
  Con_ShopDrawingStatus_Data,
  Con_ShopDrawingStatusOther_Data,
  Con_OutfittingStatus_Data,
  Con_OutfittingStatusNew_Data,
  Con_Outfitting_Data,
  Con_ErectionTimeline_Data,
  Con_OverallDashboard_Data,
  Con_OverallDashboardTopside_Data,
  Con_OverallDashboardTopside_L_Data,
  Con_OverallDashboardTopside_S_Data,
  Con_OverallDashboardHull_Data,
  Con_OverallDashboardHull_L_Data,
  Con_OverallDashboardHull_S_Data,
  Con_OverallDashboardModules_S_Data,
  Con_OverallDashboardLQ_S_Data,
  Con_OverallDashboardLQ_Data,
  Con_ProgressSummaryOverall_Data,
  Con_ProgressSummaryTopside_Data,
  Con_ProgressSummaryHull_Data,
  Con_OutfittingCommoditiesStatus_Data,
  Con_OutfittingCommoditiesStatusL_Data,
  Con_OgmDisc_Data,
  Con_OgmDashboardHull_Data,
  Con_OgmDashboardTopside_Data,
  Con_SimpleText_Data,
  Con_OutfittingCommStatus_Data,
  Con_OutfittingCommStatus_S_Data,
  Con_SpoolSummarySize_Data,
  Con_SpoolSummarySize_S_Data,
  Con_SpoolSummarySize_L_Data,
  Con_SpoolSummaryArea_Data,

  Con_AreaDashboard_Hull_AFT_Data,
  Con_AreaDashboard_Hull_FOR_Data,

  Con_AreaDashboard_L3_ME01_Data,
  Con_AreaDashboard_L3_ME02_Data,
  Con_AreaDashboard_L3_ME04_Data,
  Con_AreaDashboard_L3_ME05_Data,

  Con_AreaDashboard_L3_MW01_Data,
  Con_AreaDashboard_L3_MW02_Data,
  Con_AreaDashboard_L3_MW04_Data,
  Con_AreaDashboard_L3_MW05_Data,

  Con_MoreBtn_Data,
  Con_AreaDashboardBarchart_Data,

  Con_LQCOM_OGM_Data,
  Con_LQCOM_OGM_Modal_Data,
  Con_LQCOM_ImgModal_Data,
  Con_LQCOM_Level_Data,
  Con_LQCOM_OvBlockBar_Data,
  Con_LQCOM_OvEqBar_Data,
  Con_HULLFORCOM_OVIMG_Data,
  Con_LQCOM_OVIMG_Data,
  Con_HULLAFTCOM_OVIMG_Data,
  Con_LQCOM_Shop_Data,
  Con_LQCOM_Shop_Modal_Data,
  Con_LQCOM_ProgChart_Data,

  Con_SimpleRect_Data,

  Con_ImagesBox_Data,   
  Con_ImagesBox_6_Data,
  Con_ImagesBox_8_Data,
  Con_ModuleSummary_Data,

  Con_PipingItem_Data,   
  Con_PipingOverall_Data,
  Con_DiscStatusTrackingIcon_Data,
  Con_DiscStatusTrackingIcon_architecture_Data,
  Con_DiscStatusTrackingIcon_electrical_Data,
  Con_DiscStatusTrackingIcon_hvac_Data,
  Con_DiscStatusTrackingIcon_instrument_Data,
  Con_DiscStatusTrackingIcon_insulation_Data,
  Con_DiscStatusTrackingIcon_mechanical_Data,
  Con_DiscStatusTrackingIcon_painting_Data,
  Con_DiscStatusTrackingIcon_safety_Data,
  Con_DiscStatusTrackingIcon_structure_Data,
  Con_DiscItemSummary_Data,
  Con_DiscItemSummaryModal_Data,
  Con_DiscStatusSummaryTopsides_Data,
  Con_TopsideLevel_Data,
  Con_TopsideModule_ME01_Data,
  Con_BlockTable_Data,
  Con_HullCommonDashboard_Data,
  Con_HullCommonDivision_Data,
  Con_AreaDashboard_Module_Data,
  CH_TaskSchedule_Data,
  CH_LevelSchedule_Data,
  Con_AreaDashboard_Common_Data,
  Con_AreaDashboard_L3_Common_Data,
  Con_OverallDashboardModule_S_Data,
  Con_OverallDashboardCommon_S_Data,
  Con_CommoditySummary_Data,
  Con_CommoditySummaryTable_Data,
  Con_CommoditySummaryHull_Data,
  Con_DiscIconStatusChart_Data,
  Con_TopsideModulesChartAll_Data,
  Con_IntegrationSummaryTablee_Data,
  Con_IntegrationSummaryPie_Data,
  Con_PunchTable_Data,
  Con_DisciplineItem_Data,
  Con_WorkforceChart_Data,
  Con_DisciplineItemSchedule_Data,
  
  // Quality Control
  QC_WeldingRepairTrendChart_Data,
  QC_WeldingRepairSummaryUT_Data,
  QC_WeldingRepairSummaryRT_Data,
  QC_WeldingRepairSummaryPAUT_Data,
  QC_WeldingRepairSummaryMT_Data,
  QC_3DBlockDivision_Data,
  QC_DashboardBarchart_Data,

  // System Completion
  SC_MCSkyline_Data,
  SC_MCSkylineRev_Data,
  SC_MCSkylineB32_Data,
  SC_WDSkyline_Data,
  SC_WDSkylineRev_Data,
  SC_WD_Skyline_B32_Data,
  SC_ElectricalSkyline_Data,
  SC_RfdcSkyline_Data,
  SC_RfdcSummary_Data,
  SC_RfdcDonut_Data,
  SC_WeeklySummaryDonut_Data,
  SC_RfdcAssociated_Data,
  SC_WeeklyReport_Data,
  SC_PipingTestpackSkyline_Data,
  SC_MCSkyline_Home_Data,
  SC_TestpackList_Data,
  SC_TestpackSummary_Data,
  SC_TestpackQCFSummary_Data,
  SC_TestpackDownload_Data,
  SC_OverallMCDashboard_Data,
  SC_OvImage_Data,
  SC_TopsideSummary_Data,
  SC_PunchDiscipline_Data,
  SC_PunchStatus_Data,
  SC_PunchTable_Data,
  SC_PunchDiscRows_Data,
  SC_PunchElectrical_Data,
  SC_PunchMecandpip_Data,
  SC_PunchOverall_Data,
  SC_OnlyPieChart_Data,
  SC_MC_PieChart_Data,
  SC_MC_OnlyPiePunchChart_Data,
  SC_HomeOverallTable_Data,
  SC_SkylineLegend_Data,
  SC_MC_SysStatus_Data,
  SC_MC_SysStatus_Home_Data,
  SC_MC_Equipment_Comm_Data,
  SC_MC_Equipment_Pack_Data,
  SC_MC_SatCompletion_Data,
  SC_MC_LoopTestSkyline_Data,
  SC_MC_KsgSkyline_Data,
  SC_MC_SoftwareSkyline_Data,
  SC_ICFTestSkyline_Data,
  SC_QCFSummary_Data,
  SC_QCFSummary_A_Data,
  SC_QCFTable_Data,
  SC_QCFSummary_ICFTEST_Data,
  SC_QCFTable_ICFTEST_Data,
  SC_QCFSummary_A_ICFTEST_Data,
  SC_QCFTable_A_ICFTEST_Data,
  SC_LeakTestSkyline_Data,
  SC_QCFSummary_CYBER_Data,
  SC_QCFTable_CYBER_Data,
  SC_QCFSummary_A_CYBER_Data,
  SC_QCFTable_A_CYBER_Data,
  SC_CyberSecuritySkyline_Data,
  SC_PccSkyline_Data,
  SC_QCFSummary_TELECOMS_Data,
  SC_QCFTable_TELECOMS_Data,
  SC_TelecomsSkyline_Data,
  SC_QCFSummary_SOFTWARE_Data,
  SC_RFSU_IMAGE_Data,
  SC_RFSU_Skyline_Data,
  SC_QCFSummary_B_SOFTWARE_Data,
  SC_QCFTable_B_SOFTWARE_Data,
  SC_WeektReportTable_Data,
  SC_WeeklyReportTable_MC_Data,
  SC_WeeklyReportTableChart_Data,
  SC_QCFSummary_KSGA_Data,
  SC_QCFTable_KSGA_Data,
  SC_QCFSummary_PCCA_Data,
  SC_QCFSummary_PCCB_Data,
  SC_QCFTable_PCCA_Data,
  SC_QCFTable_PCCB_Data,
  SC_Sys_MC_ITRB_MePi_Data,
  SC_Disc_Package_Data,
  SC_WD_durationTable_Data,
  SC_ProgressCurve_Data,  
  SC_ProgressCurveOverall_Data,
  SC_MonthBarChart_Data,
  SC_WeeklyReportTable_WD_Data,
  SC_MC_DB_ITRStatus_New_Data,
  SC_MC_DB_ITRStatus_Boundary_Data,
  SC_PackageChart_Data,       
  SC_CategoryChart_Data,
  SC_BarTitle_Data,
  SC_LQPieChart_Data,
  SC_LQTable_Data,
  SC_LQRoom_Data,

  

  SC_CommissioningSkyline_Data,
  SC_MC_DB_SysStatus_Data,
  SC_MC_DB_ITRStatus_Data,
  SC_MC_DB_ITRStatus_B_Data,
  SC_MC_DB_PunchTrends_Data,
  SC_MC_DB_PunchTrends_NoChart_Data,

  SC_Table_Lookahead_Data,
  SC_Table_MostOpen_Data,
  SC_Table_PassedPunch_Data,

  SC_Disc_DB_ITRStatus_Data,
  SC_Disc_DB_Package_Data,

  SC_SChart_ITR_Data,
  SC_SChart_Punch_Data,
  SC_Chart_Punch_Data,
  SC_Punch_Disc_Data,

  SC_Sys_MC_Overall_Data,
  SC_Sys_MC_Overall_No_Desc_Data,
  SC_Sys_MC_Overall_B2_Data,
  SC_Sys_MC_Overall_AllPunch_Data,
  SC_Sys_DownloadBtn_Data,
  SC_Sys_MC_ITRA_Data,
  SC_Sys_MC_ITRB_Data,
  SC_Sys_MC_PunchChart_Data,
  SC_Sys_MC_PunchDisc_Data,
  
  SC_Sys_SC_Overall_Data,
  SC_Sys_SC_ITRB_Data,
  SC_Sys_SC_Boundary_Data,

  SC_Reg_COISkyline_Data,

  // Commissioning
  COM_OverallSummary_Data,
  COM_Procedures_Data,
  COM_ITR_B_Data,
  COM_Discipline_Data,

  // Regulatory
  RGT_COI_Skyline_Data,
  RGT_Milestones_Data,
  RGT_OverallSummary_Data,
  RGT_RTP_Data,
  RGT_USCG_Data, 

  // ### For the Tortue Project
  // Planning
  Plan_ConstructionBlockSkyline_Data,

  // ### From the TCO Project
  //Homepage
  Home_ModuleStatus_Data,
  Home_OncomingSailaway_Data,
  Home_OverallProgress_Data,
  Home_OverallSummary_Data,
  Home_SailawayStatus_Data,
  Home_Yard_Data,
  //Planning
  Const_Milestones_Data,

  //------------------------------------------------------------- China1 Project
  //01_Project
  CH_Project_visionwheel_Data,
  CH_Project_yardDashbaord_Data,
  CH_LeadershipTeam_Data,
  CH_ImageSVGDownload_Data,
  CH_ImageSVGDownload_GPPE_Data,
  CH_ImageSVGDownload_LDPE_Data,
  CH_ImageSVGDownload_P3_Data,
  CH_ImageSVGDownload_PP_Data,


  

  //00_Home
  CH_Project_vision_Data,
  CH_Project_yard_Data,
  CH_MonthlyChart_Data,
  CH_MonthlyChart2_Data,
  CH_MonthlyChart3_Data,
  CH_MonthlyChartLegend4_Data,
  CH_SSHETable_Data,
  CH_PTD_Data,
  CH_MOVE_BTN_Data,
  CH_InterfacePieChart_Data,
  CH_HomePsrStackChart_Data,
  CH_NormalTable_Data,
  CH_ProgTable_Data,
  CH_Pyramid_Data,
  CH_SWSTable_Data,
  CH_ScurveChart_Data,
  CH_FreeText_Data,
  CH_FreeText2_Data,
  CH_FreeText_2_Data,
  CH_FreeText2_2_Data,
  CH_NormalNoData_Data,
  CH_ProgNoData_Data,

  //02_Engineering
  CH_Interface_Data,
  CH_InterfaceTable_Data,
  CH_summaryTable_Data,
  CH_summaryTable_impact_Data,

  CH_summaryTable_progress_Data,
  CH_summaryTable_received_Data,
  CH_summaryTable_responded_Data,
  CH_InterfaceInfo_Data,
  CH_AreaInfo_Data,
  CH_AreaTable_Data,
  CH_InterfaceRelationMap_Data,
  CH_InterfaceTeam_Data,
  CH_TQSummayChart_Data,
  CH_TQWKEndingChart_Data,
  CH_TQSummaryTable1_Data,
  CH_TQSummaryTable2_Data,
  CH_TQSummaryBox_Data,
  CH_TQSummaryTable1_Origin_Data,
  CH_TQSummaryTable2_Origin_Data, 
  CH_TQSummaryTable1_Package_Data,
  CH_TQSummaryTable2_Package_Data,
  CH_DEVSummaryBox_Data,
  CH_summaryTableDEV_Data,
  CH_summaryTableDEV_impact_Data,
  CH_summaryTableDEV_progress_Data,
  CH_summaryTableDEV_received_Data,
  CH_summaryTableDEV_responded_Data,
  CH_DEVSummaryTable1_Disc_Data,
  CH_DEVSummaryTable2_Disc_Data,
  CH_DEVSummaryTable1_Origin_Data,
  CH_DEVSummaryTable2_Origin_Data, 
  CH_DEVSummaryTable1_Package_Data,
  CH_DEVSummaryTable2_Package_Data,
  CH_SatusTable_Data,
  CH_MDRCategoryChart_Data,
  CH_MDRStackChart_Data,
  CH_MDRSummaryTable_Data,
  CH_MDRSummaryTable_Aconex_Data,
  CH_OverdueBarChart_Data,
  CH_ReviewBarChart_Data,
  CH_SupplierStackChart_Data,
  CH_DocumentStackChart_Data,
  CH_DocumentStepStackChart_Data,
  CH_DocumentSummaryTable_Data,
  CH_ColumnBarChart_Data,
  CH_OneBarChart_Data,
  CH_ColumnBarOneColor_Data,
  CH_MDRCategoryChart_Disc_Data,
  CH_MDRCategoryChart_Sub_Data,
  CH_LSSummaryBox_Data,
  CH_LSSummaryTable_Data,
  CH_LSSummaryTable_Disc_Data,
  CH_CategoryChart_ShortScroll_Data,

  //03_Planning
  CH_Project_SiteDashboard_Data,
  CH_LMS_Skyline_Data,
  CH_LMS_GanttChart_Data,
  CH_TeamOrganization_Data,
  CH_PackageTable_Data,
  CH_SummaryTable_Data,
  CH_ImagePageSvg_Data,
  CH_Level2Schedule_Data,
  CH_Level2SchedulePackage_Data,
  CH_IcircleChart_Data,
  
  //04_Construction
  CH_Project_PillingTable_Data,
  CH_Project_CivilTable_Data,
  CH_Project_StatusComboChart_Data,
  CH_HeavyLiftingProgram_Data,
  CH_TextSummaryBox_Data,
  CH_HComboChart_Data,
  CH_HSummaryTable_Data,
  CH_HTable_Data,
  CH_HTable_Dressup_Data,
  CH_HTable_Lifting_Data,
  CH_HeavyLiftSkyline_Data,
  CH_HStackChart_Data,
  CH_PunchTable_Data,
  CH_MajorStatus_Data,
  CH_PhaseSummary_Data,
  
  //05_Procurement
  CH_Project_PsrComboChart_Data,
  CH_Project_GanttChart_Data,
  CH_LOA_Data,
  CH_Project_PsrComboChart_DJS_Data,
  CH_Project_PsrBarChart_Data,
  CH_Project_PsrStackChart_Data,
  CH_Project_RosTable_Data,
  CH_Project_PsrComboChart_MR_Data,
  CH_EquipmentLayout_Data,
  CH_progressBarScroll_Data,

  
  
  //06_QualityControl
  CH_JianLiVisualMap_Data,
  CH_JianLiVisualMap_R_Data,
  CH_SiteMapIamge_Data,
  CH_QCDashboardTable_Data,
  CH_QCDashboardTable_Con_Data,
  CH_QCDashboardTable_Field_Data,
  CH_QCDashboardTable_Weld_Data, 
  CH_QCDashboradStackChart_Data, 




//07_SystemCompletion
  CH_SCDMRTable_Data,
  CH_SCMapKeyplan_Data,
  CH_SCPunchDiscipline_Data,
  CH_SCPunchStatus_Data,
  CH_SCMC_SysStatus_Data, 
  CH_SCMC_SysStatus_Home_Data,
  CH_SCMC_DB_ITRStatus_Data,
  CH_SCMC_DB_ITRStatus_B_Data,
  CH_SCMC_DB_ITRStatus_UD_Data,
  CH_SC_Sys_SC_ITRB_Data,
}
