import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1000,
      CanvasHeight: 400,
      CanvasBorderWeight: 0.5,
      CanvasBorderColor: '#757575',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Weight : ', x:0, y: 0, font: 'roboto', class: 'regular', size: 11, color: '#BCBCBC', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: 0, y: 0, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'end' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: 0,
          y: 0,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'end'
        },
      ]
    },

    DataItems: [
      {PRIORITY:"P1A",CDATE:"2021-12-23",WK_NO:"W2021_51",A_OV_COM_WK:181},
      {PRIORITY:"P1B",CDATE:"2021-12-23",WK_NO:"W2021_51",A_OV_COM_WK:31},
      {PRIORITY:"P1",CDATE:"2021-12-23",WK_NO:"W2021_51",A_OV_COM_WK:1096},
      {PRIORITY:"P2",CDATE:"2021-12-23",WK_NO:"W2021_51",A_OV_COM_WK:672},
      {PRIORITY:"P3",CDATE:"2021-12-23",WK_NO:"W2021_51",A_OV_COM_WK:624},
      {PRIORITY:"P1A",CDATE:"2021-12-30",WK_NO:"W2021_52",A_OV_COM_WK:164},
      {PRIORITY:"P1B",CDATE:"2021-12-30",WK_NO:"W2021_52",A_OV_COM_WK:126},
      {PRIORITY:"P1",CDATE:"2021-12-30",WK_NO:"W2021_52",A_OV_COM_WK:1101},
      {PRIORITY:"P2",CDATE:"2021-12-30",WK_NO:"W2021_52",A_OV_COM_WK:908},
      {PRIORITY:"P3",CDATE:"2021-12-30",WK_NO:"W2021_52",A_OV_COM_WK:603},
      {PRIORITY:"P1A",CDATE:"2022-01-06",WK_NO:"W2022_01",A_OV_COM_WK:66},
      {PRIORITY:"P1B",CDATE:"2022-01-06",WK_NO:"W2022_01",A_OV_COM_WK:58},
      {PRIORITY:"P1",CDATE:"2022-01-06",WK_NO:"W2022_01",A_OV_COM_WK:469},
      {PRIORITY:"P2",CDATE:"2022-01-06",WK_NO:"W2022_01",A_OV_COM_WK:441},
      {PRIORITY:"P3",CDATE:"2022-01-06",WK_NO:"W2022_01",A_OV_COM_WK:115},
      {PRIORITY:"P1A",CDATE:"2022-01-13",WK_NO:"W2022_02",A_OV_COM_WK:0},
      {PRIORITY:"P1B",CDATE:"2022-01-13",WK_NO:"W2022_02",A_OV_COM_WK:24},
      {PRIORITY:"P1",CDATE:"2022-01-13",WK_NO:"W2022_02",A_OV_COM_WK:0},
      {PRIORITY:"P2",CDATE:"2022-01-13",WK_NO:"W2022_02",A_OV_COM_WK:0},
      {PRIORITY:"P3",CDATE:"2022-01-13",WK_NO:"W2022_02",A_OV_COM_WK:0},
      {PRIORITY:"P1A",CDATE:"2022-01-20",WK_NO:"W2022_03",A_OV_COM_WK:100},
      {PRIORITY:"P1B",CDATE:"2022-01-20",WK_NO:"W2022_03",A_OV_COM_WK:36},
      {PRIORITY:"P1",CDATE:"2022-01-20",WK_NO:"W2022_03",A_OV_COM_WK:532},
      {PRIORITY:"P2",CDATE:"2022-01-20",WK_NO:"W2022_03",A_OV_COM_WK:477},
      {PRIORITY:"P3",CDATE:"2022-01-20",WK_NO:"W2022_03",A_OV_COM_WK:334},
      {PRIORITY:"P1A",CDATE:"2022-01-27",WK_NO:"W2022_04",A_OV_COM_WK:30},
      {PRIORITY:"P1B",CDATE:"2022-01-27",WK_NO:"W2022_04",A_OV_COM_WK:37},
      {PRIORITY:"P1",CDATE:"2022-01-27",WK_NO:"W2022_04",A_OV_COM_WK:140},
      {PRIORITY:"P2",CDATE:"2022-01-27",WK_NO:"W2022_04",A_OV_COM_WK:112},
      {PRIORITY:"P3",CDATE:"2022-01-27",WK_NO:"W2022_04",A_OV_COM_WK:108}
    ],
}