import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 1050,
    CanvasHeight: 350,
    CanvasBorderWeight: 0.3,
    CanvasBorderColor: 'Transparent',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.2,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 310,
    CanvasChartHeight: 270,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: 'Transparent',
    CanvasChartFillColor: 'Transparent',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },

    // ----------------------------------------------- NOTE
  Note: {
    NoteDisplay: 'N',
    NoteX: 50,
    NoteY: 420,
    NoteAlign: 'start',
    NoteTitleText: 'Notes.',
    NoteTitleFont: 'roboto',
    NoteTitleStyle: 'regular',
    NoteTitleSize: 11,
    NoteTitleColor: '#757575',

    NoteContentsText: 'Note Contents Here!',
    NoteContentsFont: 'roboto',
    NoteContentsStyle: 'regular',
    NoteContentsSize: 10,
    NoteContentsColor: '#BDBCBC',
  },

  DataItems: [
    {DISC:"Structure Works",ACTIVITY:"Structure Prefabrication",UOM:"Ton",INITIALQTY:7041.84,OGM:8318.400000000001,CURRENT_QTY:3325,PLAN_QTY:1154.14,ACTUAL_QTY:2382.5,PLAN_PROG:34.71,ACTUAL_PROG:71.66,OGM_DELTA:-5935.900000000002, OGM_DELTA_PROG:-100, PLAN_DELTA:1228.36, PLAN_DELTA_PROG:100},
    {DISC:"Structure Works",ACTIVITY:"Structure Preassembly",UOM:"Ton",OGM:6836.299999999999,CURRENT_QTY:2844,ACTUAL_QTY:510.3000000000001,PLAN_PROG:100,ACTUAL_PROG:17.94,OGM_DELTA:-6325.999999999999, OGM_DELTA_PROG:100, PLAN_DELTA_PROG:-100},
    {DISC:"Structure Works",ACTIVITY:"Structure Erection",UOM:"Ton",OGM:8314.399999999998, PLAN_DELTA_PROG:80},
    {DISC:"Structure Works",ACTIVITY:"Structure Integration",UOM:"Ton",INTEGRATION:116, PLAN_DELTA_PROG:60},
    {DISC:"Piping Works",ACTIVITY:"Piping Spool Prefabrication",UOM:"DIA",INITIALQTY:198018,OGM:229472,INTEGRATION:21253,CURRENT_QTY:10621,PLAN_QTY:0,PLAN_PROG:0, PLAN_DELTA_PROG:50},
    {DISC:"Piping Works",ACTIVITY:"Metal Piping Spool Installation",UOM:"DIA",INITIALQTY:60877.7,OGM:58036,INTEGRATION:8751,CURRENT_QTY:2438,PLAN_QTY:0,PLAN_PROG:0, PLAN_DELTA_PROG:10},
    {DISC:"Piping Works",ACTIVITY:"GRP Piping Spool Installation",UOM:"Meter",OGM:1303, PLAN_DELTA_PROG:1},
    {DISC:"Piping Works",ACTIVITY:"Piping Pressure Test",UOM:"Test Pack",INITIALQTY:5432.8,OGM:1608.5,INTEGRATION:162,PLAN_PROG:0},
    {DISC:"EIT Works",ACTIVITY:"EIT Cable Tray & Cable Ladder Installation",UOM:"Meter",INITIALQTY:30115,OGM:34770,INTEGRATION:172,PLAN_PROG:0},
    {DISC:"EIT Works",ACTIVITY:"Electrical Cable Pulling",UOM:"Meter",INITIALQTY:1399,OGM:77699,INTEGRATION:287500,PLAN_PROG:0},
    {DISC:"EIT Works",ACTIVITY:"Instrument Cable Pulling",UOM:"Meter",INITIALQTY:86485,OGM:91731,INTEGRATION:287500,PLAN_PROG:0},
    {DISC:"Mechanical Works",ACTIVITY:"Mechanical Equipment Installation",UOM:"Set",OGM:150},
    {DISC:"Painting Works",ACTIVITY:"Structure Blasting and Painting (including Support)",UOM:"Sqm",INITIALQTY:102162.5,OGM:139632.1,CURRENT_QTY:39414,PLAN_QTY:0,PLAN_PROG:0},
    {DISC:"Painting Works",ACTIVITY:"Piping Spool Painting",UOM:"Sqm",INITIALQTY:22307.6,OGM:27388,INTEGRATION:387,CURRENT_QTY:798,PLAN_QTY:0,PLAN_PROG:0},
    {DISC:"Insulation Works",ACTIVITY:"Piping Insulation Installation",UOM:"Elm",INITIALQTY:18065.5,OGM:19527.7,INTEGRATION:451,PLAN_PROG:0},
    {DISC:"Insulation Works",ACTIVITY:"Equipment Insulation Installation",UOM:"Sqm",INITIALQTY:2724.8,OGM:2724.8,PLAN_PROG:0},
    {DISC:"Insulation Works",ACTIVITY:"Equipment Insulation Installation",UOM:"Sqm",INITIALQTY:2724.8,OGM:2724.8,PLAN_PROG:0, OGM_DELTA_PROG:-10}
    ]
}