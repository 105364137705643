import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 420,
      CanvasHeight: 220,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Weight : ', x:240, y: -20, font: 'roboto', class: 'regular', size: 11, color: '#BCBCBC', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: 240, y: -20, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'end' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: 240,
          y: -20,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'end'
        },
      ]
    },
    Queries: {
      SQL1: [
      ]
      
    },
    DataItems:  [
      {WK_NO:"W2023_09",CUTOFF:"2023-03-03",CRANE_MAIN:"4000",MAIN_CRANE_SEQ:1,LIFTING_PLAN:0},
      {WK_NO:"W2023_09",CUTOFF:"2023-03-03",CRANE_MAIN:"1600",MAIN_CRANE_SEQ:2,LIFTING_PLAN:1},
      {WK_NO:"W2023_09",CUTOFF:"2023-03-03",CRANE_MAIN:"1250",MAIN_CRANE_SEQ:3,LIFTING_PLAN:0},
      {WK_NO:"W2023_09",CUTOFF:"2023-03-03",CRANE_MAIN:"800",MAIN_CRANE_SEQ:4,LIFTING_PLAN:2},
      {WK_NO:"W2023_09",CUTOFF:"2023-03-03",CRANE_MAIN:"750",MAIN_CRANE_SEQ:5,LIFTING_PLAN:0},
      {WK_NO:"W2023_09",CUTOFF:"2023-03-03",CRANE_MAIN:"400",MAIN_CRANE_SEQ:6,LIFTING_PLAN:1},
      {WK_NO:"W2023_09",CUTOFF:"2023-03-03",CRANE_MAIN:"280",MAIN_CRANE_SEQ:7,LIFTING_PLAN:0},
      {WK_NO:"W2023_09",CUTOFF:"2023-03-03",CRANE_MAIN:"NA",MAIN_CRANE_SEQ:9,LIFTING_PLAN:0},
      {WK_NO:"W2023_09",CUTOFF:"2023-03-03",CRANE_MAIN:"Total",MAIN_CRANE_SEQ:10,LIFTING_PLAN:4},
      {WK_NO:"W2023_10",CUTOFF:"2023-03-10",CRANE_MAIN:"4000",MAIN_CRANE_SEQ:1,LIFTING_PLAN:0},
      {WK_NO:"W2023_10",CUTOFF:"2023-03-10",CRANE_MAIN:"1600",MAIN_CRANE_SEQ:2,LIFTING_PLAN:1},
      {WK_NO:"W2023_10",CUTOFF:"2023-03-10",CRANE_MAIN:"1250",MAIN_CRANE_SEQ:3,LIFTING_PLAN:0},
      {WK_NO:"W2023_10",CUTOFF:"2023-03-10",CRANE_MAIN:"800",MAIN_CRANE_SEQ:4,LIFTING_PLAN:0},
      {WK_NO:"W2023_10",CUTOFF:"2023-03-10",CRANE_MAIN:"750",MAIN_CRANE_SEQ:5,LIFTING_PLAN:0},
      {WK_NO:"W2023_10",CUTOFF:"2023-03-10",CRANE_MAIN:"400",MAIN_CRANE_SEQ:6,LIFTING_PLAN:8},
      {WK_NO:"W2023_10",CUTOFF:"2023-03-10",CRANE_MAIN:"280",MAIN_CRANE_SEQ:7,LIFTING_PLAN:0},
      {WK_NO:"W2023_10",CUTOFF:"2023-03-10",CRANE_MAIN:"NA",MAIN_CRANE_SEQ:9,LIFTING_PLAN:10},
      {WK_NO:"W2023_10",CUTOFF:"2023-03-10",CRANE_MAIN:"Total",MAIN_CRANE_SEQ:10,LIFTING_PLAN:19},
      {WK_NO:"W2023_11",CUTOFF:"2023-03-17",CRANE_MAIN:"4000",MAIN_CRANE_SEQ:1,LIFTING_PLAN:0},
      {WK_NO:"W2023_11",CUTOFF:"2023-03-17",CRANE_MAIN:"1600",MAIN_CRANE_SEQ:2,LIFTING_PLAN:4},
      {WK_NO:"W2023_11",CUTOFF:"2023-03-17",CRANE_MAIN:"1250",MAIN_CRANE_SEQ:3,LIFTING_PLAN:0},
      {WK_NO:"W2023_11",CUTOFF:"2023-03-17",CRANE_MAIN:"800",MAIN_CRANE_SEQ:4,LIFTING_PLAN:0},
      {WK_NO:"W2023_11",CUTOFF:"2023-03-17",CRANE_MAIN:"750",MAIN_CRANE_SEQ:5,LIFTING_PLAN:0},
      {WK_NO:"W2023_11",CUTOFF:"2023-03-17",CRANE_MAIN:"400",MAIN_CRANE_SEQ:6,LIFTING_PLAN:2},
      {WK_NO:"W2023_11",CUTOFF:"2023-03-17",CRANE_MAIN:"280",MAIN_CRANE_SEQ:7,LIFTING_PLAN:0},
      {WK_NO:"W2023_11",CUTOFF:"2023-03-17",CRANE_MAIN:"NA",MAIN_CRANE_SEQ:9,LIFTING_PLAN:5},
      {WK_NO:"W2023_11",CUTOFF:"2023-03-17",CRANE_MAIN:"Total",MAIN_CRANE_SEQ:10,LIFTING_PLAN:11},
      {WK_NO:"W2023_12",CUTOFF:"2023-03-24",CRANE_MAIN:"4000",MAIN_CRANE_SEQ:1,LIFTING_PLAN:0},
      {WK_NO:"W2023_12",CUTOFF:"2023-03-24",CRANE_MAIN:"1600",MAIN_CRANE_SEQ:2,LIFTING_PLAN:2},
      {WK_NO:"W2023_12",CUTOFF:"2023-03-24",CRANE_MAIN:"1250",MAIN_CRANE_SEQ:3,LIFTING_PLAN:0},
      {WK_NO:"W2023_12",CUTOFF:"2023-03-24",CRANE_MAIN:"800",MAIN_CRANE_SEQ:4,LIFTING_PLAN:2},
      {WK_NO:"W2023_12",CUTOFF:"2023-03-24",CRANE_MAIN:"750",MAIN_CRANE_SEQ:5,LIFTING_PLAN:0},
      {WK_NO:"W2023_12",CUTOFF:"2023-03-24",CRANE_MAIN:"400",MAIN_CRANE_SEQ:6,LIFTING_PLAN:1},
      {WK_NO:"W2023_12",CUTOFF:"2023-03-24",CRANE_MAIN:"280",MAIN_CRANE_SEQ:7,LIFTING_PLAN:0},
      {WK_NO:"W2023_12",CUTOFF:"2023-03-24",CRANE_MAIN:"NA",MAIN_CRANE_SEQ:9,LIFTING_PLAN:0},
      {WK_NO:"W2023_12",CUTOFF:"2023-03-24",CRANE_MAIN:"Total",MAIN_CRANE_SEQ:10,LIFTING_PLAN:5},
      {WK_NO:"W2023_13",CUTOFF:"2023-03-31",CRANE_MAIN:"4000",MAIN_CRANE_SEQ:1,LIFTING_PLAN:0},
      {WK_NO:"W2023_13",CUTOFF:"2023-03-31",CRANE_MAIN:"1600",MAIN_CRANE_SEQ:2,LIFTING_PLAN:0},
      {WK_NO:"W2023_13",CUTOFF:"2023-03-31",CRANE_MAIN:"1250",MAIN_CRANE_SEQ:3,LIFTING_PLAN:0},
      {WK_NO:"W2023_13",CUTOFF:"2023-03-31",CRANE_MAIN:"800",MAIN_CRANE_SEQ:4,LIFTING_PLAN:2},
      {WK_NO:"W2023_13",CUTOFF:"2023-03-31",CRANE_MAIN:"750",MAIN_CRANE_SEQ:5,LIFTING_PLAN:0},
      {WK_NO:"W2023_13",CUTOFF:"2023-03-31",CRANE_MAIN:"400",MAIN_CRANE_SEQ:6,LIFTING_PLAN:4},
      {WK_NO:"W2023_13",CUTOFF:"2023-03-31",CRANE_MAIN:"280",MAIN_CRANE_SEQ:7,LIFTING_PLAN:0},
      {WK_NO:"W2023_13",CUTOFF:"2023-03-31",CRANE_MAIN:"NA",MAIN_CRANE_SEQ:9,LIFTING_PLAN:0},
      {WK_NO:"W2023_13",CUTOFF:"2023-03-31",CRANE_MAIN:"Total",MAIN_CRANE_SEQ:10,LIFTING_PLAN:6},
      {WK_NO:"W2023_14",CUTOFF:"2023-04-07",CRANE_MAIN:"4000",MAIN_CRANE_SEQ:1,LIFTING_PLAN:0},
      {WK_NO:"W2023_14",CUTOFF:"2023-04-07",CRANE_MAIN:"1600",MAIN_CRANE_SEQ:2,LIFTING_PLAN:1},
      {WK_NO:"W2023_14",CUTOFF:"2023-04-07",CRANE_MAIN:"1250",MAIN_CRANE_SEQ:3,LIFTING_PLAN:0},
      {WK_NO:"W2023_14",CUTOFF:"2023-04-07",CRANE_MAIN:"800",MAIN_CRANE_SEQ:4,LIFTING_PLAN:2},
      {WK_NO:"W2023_14",CUTOFF:"2023-04-07",CRANE_MAIN:"750",MAIN_CRANE_SEQ:5,LIFTING_PLAN:0},
      {WK_NO:"W2023_14",CUTOFF:"2023-04-07",CRANE_MAIN:"400",MAIN_CRANE_SEQ:6,LIFTING_PLAN:8},
      {WK_NO:"W2023_14",CUTOFF:"2023-04-07",CRANE_MAIN:"280",MAIN_CRANE_SEQ:7,LIFTING_PLAN:1},
      {WK_NO:"W2023_14",CUTOFF:"2023-04-07",CRANE_MAIN:"NA",MAIN_CRANE_SEQ:9,LIFTING_PLAN:0},
      {WK_NO:"W2023_14",CUTOFF:"2023-04-07",CRANE_MAIN:"Total",MAIN_CRANE_SEQ:10,LIFTING_PLAN:12},
      {WK_NO:"W2023_15",CUTOFF:"2023-04-14",CRANE_MAIN:"4000",MAIN_CRANE_SEQ:1,LIFTING_PLAN:0},
      {WK_NO:"W2023_15",CUTOFF:"2023-04-14",CRANE_MAIN:"1600",MAIN_CRANE_SEQ:2,LIFTING_PLAN:0},
      {WK_NO:"W2023_15",CUTOFF:"2023-04-14",CRANE_MAIN:"1250",MAIN_CRANE_SEQ:3,LIFTING_PLAN:0},
      {WK_NO:"W2023_15",CUTOFF:"2023-04-14",CRANE_MAIN:"800",MAIN_CRANE_SEQ:4,LIFTING_PLAN:2},
      {WK_NO:"W2023_15",CUTOFF:"2023-04-14",CRANE_MAIN:"750",MAIN_CRANE_SEQ:5,LIFTING_PLAN:0},
      {WK_NO:"W2023_15",CUTOFF:"2023-04-14",CRANE_MAIN:"400",MAIN_CRANE_SEQ:6,LIFTING_PLAN:10},
      {WK_NO:"W2023_15",CUTOFF:"2023-04-14",CRANE_MAIN:"280",MAIN_CRANE_SEQ:7,LIFTING_PLAN:0},
      {WK_NO:"W2023_15",CUTOFF:"2023-04-14",CRANE_MAIN:"NA",MAIN_CRANE_SEQ:9,LIFTING_PLAN:10},
      {WK_NO:"W2023_15",CUTOFF:"2023-04-14",CRANE_MAIN:"Total",MAIN_CRANE_SEQ:10,LIFTING_PLAN:22},
      {WK_NO:"W2023_16",CUTOFF:"2023-04-21",CRANE_MAIN:"4000",MAIN_CRANE_SEQ:1,LIFTING_PLAN:0},
      {WK_NO:"W2023_16",CUTOFF:"2023-04-21",CRANE_MAIN:"1600",MAIN_CRANE_SEQ:2,LIFTING_PLAN:1},
      {WK_NO:"W2023_16",CUTOFF:"2023-04-21",CRANE_MAIN:"1250",MAIN_CRANE_SEQ:3,LIFTING_PLAN:0},
      {WK_NO:"W2023_16",CUTOFF:"2023-04-21",CRANE_MAIN:"800",MAIN_CRANE_SEQ:4,LIFTING_PLAN:8},
      {WK_NO:"W2023_16",CUTOFF:"2023-04-21",CRANE_MAIN:"750",MAIN_CRANE_SEQ:5,LIFTING_PLAN:0},
      {WK_NO:"W2023_16",CUTOFF:"2023-04-21",CRANE_MAIN:"400",MAIN_CRANE_SEQ:6,LIFTING_PLAN:9},
      {WK_NO:"W2023_16",CUTOFF:"2023-04-21",CRANE_MAIN:"280",MAIN_CRANE_SEQ:7,LIFTING_PLAN:9},
      {WK_NO:"W2023_16",CUTOFF:"2023-04-21",CRANE_MAIN:"NA",MAIN_CRANE_SEQ:9,LIFTING_PLAN:0},
      {WK_NO:"W2023_16",CUTOFF:"2023-04-21",CRANE_MAIN:"Total",MAIN_CRANE_SEQ:10,LIFTING_PLAN:27}
    ],
}