import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 540,
      CanvasHeight: 200,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },
    DataItems:  [
      {QCF:"F01B",QCF_DESCR:"Gas Detection Loop Check",GENERAL_DESIGN_SCOPE:"COS",TOTAL:93,COMPLETED:0,REMAIN:93,PROG:0},
      {QCF:"F02B",QCF_DESCR:"Fire Detector Mac Device Loop Test",GENERAL_DESIGN_SCOPE:"COS",TOTAL:701,COMPLETED:0,REMAIN:701,PROG:0},
      {QCF:"I01B",QCF_DESCR:"Instrument Loop Function Check",GENERAL_DESIGN_SCOPE:"COS",TOTAL:1127,COMPLETED:0,REMAIN:1127,PROG:0},
      {QCF:"I06B",QCF_DESCR:"Motor Control And Remote Io Loops",GENERAL_DESIGN_SCOPE:"COS",TOTAL:84,COMPLETED:0,REMAIN:84,PROG:0}
      ],
}