import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 860,
      CanvasHeight: 310,
      CanvasBorderWeight: 0,
      CanvasBorderColor: 'Transparent',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 860,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: 'Transparent',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },

    DataItems: [
      // {activity:"SteelCutting",planStart:"2019-10-03",planFinish:"2019-11-29",actualStart:"2019-10-03",actualFinish:"2019-11-29",actualProg:100,CUTOFF:"2020-05-08"},
      // {activity:"Assembly",planStart:"2020-07-16",planFinish:"2020-08-10",actualProg:100,CUTOFF:"2020-05-08"},
      // {activity:"Painting",planStart:"2020-06-29",planFinish:"2020-07-08",actualProg:100,CUTOFF:"2020-05-08"},
      // {activity:"Erection",planStart:"2020-06-28",planFinish:"2020-06-28",actualProg:100,CUTOFF:"2020-05-08"},

      
      // {activity:"531-Fabrication",planStart:"2019-11-22",planFinish:"2020-05-01",actualStart:"2019-11-22",actualFinish:"2020-05-01",planProg:100,actualProg:100},
      // {activity:"531-Fabrication",planStart:"2019-11-22",planFinish:"2020-05-01",actualStart:"2019-11-22",actualFinish:"2020-05-01",planProg:100,actualProg:100},
      // {activity:"519-Fabrication",planStart:"2019-11-01",planFinish:"2020-03-27",actualStart:"2019-11-01",actualFinish:"2020-03-27",planProg:100,actualProg:100},
      // {activity:"519-Painting",planStart:"2020-05-15",planFinish:"2020-05-29",actualStart:"2020-05-15",actualFinish:"2020-05-29",planProg:100,actualProg:100},
      // {activity:"51I-Painting",planStart:"2020-07-03",planFinish:"2020-07-10",planProg:100,actualProg:0},
      // {activity:"531-Painting",planStart:"2020-05-15",planFinish:"2020-05-29",actualStart:"2020-05-15",actualFinish:"2020-05-29",planProg:100,actualProg:100},
      // {activity:"51I-Erection",planStart:"2020-07-17",planFinish:"2020-08-14",planProg:100,actualProg:0},

      // {activity:"test-data",planStart:"2021-03-17",planFinish:"2021-03-23",planProg:100,actualProg:0},

      // {activity:"test-data222",planStart:"2021-02-01",planFinish:"2021-03-01",planProg:100,actualProg:0},
      // {activity:"test-data222",planStart:"2021-02-01",planFinish:"2021-03-01",planProg:100,actualProg:0},

      // {activity:"test2-data",planStart:"2021-06-17",planFinish:"2021-06-23",planProg:100,actualProg:0},
      
      // {activity:"615-Fabrication",planStart:"2019-10-04",planFinish:"2019-11-29",actualStart:"2019-10-04",actualFinish:"2019-11-29",planProg:100,actualProg:100},
      // {activity:"615-Painting",planStart:"2020-07-03",planFinish:"2020-07-10",actualStart:"2020-07-03",actualFinish:"2020-07-10",planProg:100,actualProg:100}
      


      // {activity:"61A-3.Erection",planStart:"2020-10-30",planFinish:"2020-11-28",actualStart:"2020-10-30",planProg:100,actualProg:54.89},
      // {activity:"603-1.Fabrication",planStart:"2019-09-19",planFinish:"2019-11-26",actualStart:"2019-09-19",planProg:100,actualProg:60},
      // {activity:"623-2.Painting",planStart:"2020-05-26",planFinish:"2020-05-28",actualStart:"2020-05-26",actualFinish:"2020-06-19",planProg:100,actualProg:100},
      // {activity:"61A-2.Painting",planStart:"2020-10-23",planFinish:"2020-10-29",actualStart:"2020-10-23",actualFinish:"2020-10-29",planProg:100,actualProg:100},
      // {activity:"603-2.Painting",planStart:"2020-05-21",planFinish:"2020-05-21",actualStart:"2020-05-21",actualFinish:"2020-06-01",planProg:100,actualProg:100},
      // {activity:"623-1.Fabrication",planStart:"2019-09-19",planFinish:"2019-11-06",actualStart:"2019-09-19",actualFinish:"2019-11-06",planProg:100,actualProg:100},

      // {activity:"531-Fabrication",planStart:"2019-11-22",planFinish:"2020-05-01",actualStart:"2019-11-22",actualFinish:"2020-05-01",planProg:100,actualProg:100},
      // {activity:"519-Fabrication",planStart:"2019-11-01",planFinish:"2020-03-27",actualStart:"2019-11-01",actualFinish:"2020-03-27",planProg:100,actualProg:100},
      // {activity:"519-Painting",planStart:"2020-05-15",planFinish:"2020-05-29",actualStart:"2020-05-15",actualFinish:"2020-05-29",planProg:100,actualProg:100},
      // {activity:"51I-Painting",planStart:"2020-07-03",planFinish:"2020-07-10",planProg:100,actualProg:0},
      // {activity:"531-Painting",planStart:"2020-05-15",planFinish:"2020-05-29",actualStart:"2020-05-15",actualFinish:"2020-05-29",planProg:100,actualProg:100},
      // {activity:"51I-Erection",planStart:"2020-07-17",planFinish:"2020-08-14",planProg:100,actualProg:0},
      
      // {activity:"615-Fabrication",planStart:"2019-10-04",planFinish:"2019-11-29",actualStart:"2019-10-04",actualFinish:"2019-11-29",planProg:100,actualProg:100},


    
      // {activity:"ME05-5.Module Assembly",planStart:"2020-10-21",planFinish:"2020-11-20",actualStart:"2020-10-21",actualFinish:"2020-11-20",planProg:100,actualProg:0},
      // {activity:"ME05-3.Painting",planProg:100,actualProg:0},
      // {activity:"ME05-4.Assembly A/P",planStart:"2020-10-21",planFinish:"2020-11-18",planProg:100,actualProg:0},
      // {activity:"E5C-2.Assembly B/P",planStart:"2020-01-27",planFinish:"2020-02-24",actualStart:"2020-01-27",planProg:100,actualProg:95},
      // {activity:"E5A1-2.Assembly B/P",planStart:"2019-12-30",planFinish:"2020-01-27",actualStart:"2019-12-30",actualFinish:"2020-01-27",planProg:100,actualProg:100},
      // {activity:"E5B-2.Assembly B/P",planStart:"2020-01-20",planFinish:"2020-02-17",actualStart:"2020-01-20",planProg:100,actualProg:96.8},
      {activity:"E5E-2.Assembly B/P",planStart:"2020-02-10",planFinish:"2020-03-09",actualStart:"2020-02-10",planProg:100,actualProg:18.5},
      {activity:"E5A2-2.Assembly B/P",planStart:"2020-01-13",planFinish:"2020-02-10",actualStart:"2020-01-13",actualFinish:"2020-02-10",planProg:100,actualProg:100},
      {activity:"E5D-2.Assembly B/P",planStart:"2020-02-03",planFinish:"2020-03-02",actualStart:"2020-02-03",planProg:100,actualProg:73},
      {activity:"E5E-1.Fabrication",planStart:"2020-01-27",planFinish:"2020-04-20",actualStart:"2020-01-27",planProg:100,actualProg:61.4},
      {activity:"E5D-1.Fabrication",planStart:"2020-01-20",planFinish:"2020-04-13",actualStart:"2020-01-20",planProg:100,actualProg:71},
      {activity:"E5C-1.Fabrication",planStart:"2020-01-13",planFinish:"2020-04-06",actualStart:"2020-01-13",planProg:100,actualProg:91.7},
      {activity:"E5B-1.Fabrication",planStart:"2020-01-06",planFinish:"2020-03-30",actualStart:"2020-01-06",planProg:100,actualProg:78.2},
      {activity:"E5A2-1.Fabrication",planStart:"2019-12-30",planFinish:"2020-03-23",actualStart:"2019-12-30",planProg:100,actualProg:99.3},
      {activity:"E5A1-1.Fabrication",planStart:"2019-12-16",planFinish:"2020-03-09",actualStart:"2019-12-16",actualFinish:"2020-03-09",planProg:100,actualProg:100}


      // {activity:"W1D-5.Module Assembly",planStart:"2021-05-31",planFinish:"2021-06-25",actualStart:"2021-05-31",actualFinish:"2021-06-25",planProg:100,actualProg:0},
      // {activity:"W1D-3.Painting",planStart:"2021-03-24",planFinish:"2021-07-28",planProg:100},
      // {activity:"W1D-4.Assembly After Painting",planStart:"2021-04-19",planFinish:"2021-08-13",planProg:100,actualProg:0},
      // {activity:"W1D-2.Assembly Before Painting",planStart:"2020-12-14",planFinish:"2021-03-23",actualStart:"2020-12-14",planProg:100,actualProg:66.42},
      // {activity:"W1D-1.Fabrication",planStart:"2020-04-13",planFinish:"2021-05-01",actualStart:"2020-04-13",actualFinish:"2020-10-22",planProg:100,actualProg:100}


    ],
}
