import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 640,
      CanvasHeight: 350,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        // { text: 'Weight : ', x:240, y: 0, font: 'roboto', class: 'regular', size: 11, color: '#BCBCBC', align: 'start' },
      ],
      Filter: [
        // { name: 'MOD'   , x: 240, y: 0, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'end' },
      ],
      Sql: [
        // {
        //   query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
        //   text: 'Data Source',
        //   x: 240,
        //   y: 0,
        //   font: 'roboto',
        //   style: 'regular',
        //   size: 11,
        //   color: '#44A9DF',
        //   align: 'end'
        // },
      ]
    },
    DataItems: [
      {GWBS_LV3:"RCxx",PLAN:66.82,ACTUAL:49.86,DELTA:-16.96,PLAN_WK:2.89,ACTUAL_WK:1.38,DELTA_WK:-1.51}
    ],
    Queries: {
      SQL1: [
        {Lv3:"ME01",PLAN:66.82,ACTUAL:49.86,DELTA:-16.96,TEXT1:"Lifting Plan",TEXT1_DATE:"30-Nov-21",TEXT2:"OGM Goal",TEXT2_PROG:90.14}
      ],
      SQL2:[
        {Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME01",Lv4:"E1A",BLOCK:"E1A1",AREA:"Flash Gas Compression",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME01",Lv4:"E1A",BLOCK:"E1A2",AREA:"Flash Gas Compression",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME01",Lv4:"E1B",BLOCK:"E1B1",AREA:"Flash Gas Compression",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME01",Lv4:"E1B",BLOCK:"E1B2",AREA:"Flash Gas Compression",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME01",Lv4:"E1M1",BLOCK:"E1M1",AREA:"Flash Gas Compression",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME01",Lv4:"E1C",BLOCK:"E1C1",AREA:"Flash Gas Compression",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME01",Lv4:"E1C",BLOCK:"E1C2",AREA:"Flash Gas Compression",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME01",Lv4:"E1ST",BLOCK:"E1ST",AREA:"Flash Gas Compression",TYPE:"UNIT",STATUS:"ASSEMBLY",EREC:"Y"},
        {Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME01",Lv4:"E1ST1",BLOCK:"E1ST1",AREA:"Flash Gas Compression",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"}
      ],
      SQL3:[
        {BLOCK:"E1A",GWBS_LEVEL:"ME01DA",LV2:"MODULES",GWBS_LV3:"ME01",PO_TOTAL:14,PO_ACTUAL:14,FAT_TOTAL:14,FAT_ACTUAL:14,IRN_TOTAL:14,IRN_ACTUAL:14,ONSITE_TOTAL:14,ONSITE_ACTUAL:13,INST_TOTAL:14,INST_ACTUAL:8,NOT_PO_CNT:0,ETA_PAST_FROM_ROS:1,ETA_PAST_FROM_UPPERBLOCK:0},
        {BLOCK:"E1B",GWBS_LEVEL:"ME01DB",LV2:"MODULES",GWBS_LV3:"ME01",PO_TOTAL:12,PO_ACTUAL:12,FAT_TOTAL:12,FAT_ACTUAL:12,IRN_TOTAL:12,IRN_ACTUAL:12,ONSITE_TOTAL:12,ONSITE_ACTUAL:12,INST_TOTAL:12,INST_ACTUAL:5,NOT_PO_CNT:0,ETA_PAST_FROM_ROS:0,ETA_PAST_FROM_UPPERBLOCK:0},
        {BLOCK:"E1C",GWBS_LEVEL:"ME01DC",LV2:"MODULES",GWBS_LV3:"ME01",PO_TOTAL:4,PO_ACTUAL:4,FAT_TOTAL:4,FAT_ACTUAL:4,IRN_TOTAL:4,IRN_ACTUAL:4,ONSITE_TOTAL:4,ONSITE_ACTUAL:4,INST_TOTAL:4,INST_ACTUAL:0,NOT_PO_CNT:0,ETA_PAST_FROM_ROS:0,ETA_PAST_FROM_UPPERBLOCK:0}
      ]
    }

}