import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 330,
      CanvasHeight: 330,
      CanvasBorderWeight: 0.5,
      CanvasBorderColor: '#757575',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     

    DataItems: [
      { NO: 1, SUBSYSTEM: '9560-01', MC_PLAN: '2020-02-07', PA_TOTAL: 47, PA_ACTUAL: 37, PA_REMAIN: 10, PB_TOTAL: 157, PB_ACTUAL: 100, PB_REMAIN: 57, },
{ NO: 2, SUBSYSTEM: '8740-03', MC_PLAN: '2020-02-07', PA_TOTAL: 37, PA_ACTUAL: 18, PA_REMAIN: 19, PB_TOTAL: 69, PB_ACTUAL: 41, PB_REMAIN: 28, },
{ NO: 3, SUBSYSTEM: '8790-01', MC_PLAN: '2020-01-31', PA_TOTAL: 45, PA_ACTUAL: 39, PA_REMAIN: 6, PB_TOTAL: 58, PB_ACTUAL: 25, PB_REMAIN: 33, },
{ NO: 4, SUBSYSTEM: '6020-05', MC_PLAN: '2020-01-17', PA_TOTAL: 9, PA_ACTUAL: 9, PA_REMAIN: 0, PB_TOTAL: 79, PB_ACTUAL: 42, PB_REMAIN: 37, },
{ NO: 5, SUBSYSTEM: '4650-01', MC_PLAN: '2020-02-21', PA_TOTAL: 28, PA_ACTUAL: 22, PA_REMAIN: 6, PB_TOTAL: 91, PB_ACTUAL: 61, PB_REMAIN: 30, },
{ NO: 6, SUBSYSTEM: '9380-04', MC_PLAN: '2020-01-17', PA_TOTAL: 172, PA_ACTUAL: 167, PA_REMAIN: 5, PB_TOTAL: 427, PB_ACTUAL: 398, PB_REMAIN: 29, },
{ NO: 7, SUBSYSTEM: '6070-17', MC_PLAN: '2020-02-21', PA_TOTAL: 5, PA_ACTUAL: 2, PA_REMAIN: 3, PB_TOTAL: 30, PB_ACTUAL: 1, PB_REMAIN: 29, },
{ NO: 8, SUBSYSTEM: '6010-03', MC_PLAN: '2020-02-07', PA_TOTAL: 41, PA_ACTUAL: 41, PA_REMAIN: 0, PB_TOTAL: 75, PB_ACTUAL: 45, PB_REMAIN: 30, },
{ NO: 9, SUBSYSTEM: '6210-08', MC_PLAN: '2020-02-07', PA_TOTAL: 51, PA_ACTUAL: 51, PA_REMAIN: 0, PB_TOTAL: 166, PB_ACTUAL: 137, PB_REMAIN: 29, },
{ NO: 10, SUBSYSTEM: '6720-43', MC_PLAN: '2020-02-14', PA_TOTAL: 14, PA_ACTUAL: 12, PA_REMAIN: 2, PB_TOTAL: 43, PB_ACTUAL: 16, PB_REMAIN: 27, },
{ NO: 11, SUBSYSTEM: '9380-02', MC_PLAN: '2020-01-17', PA_TOTAL: 181, PA_ACTUAL: 178, PA_REMAIN: 3, PB_TOTAL: 542, PB_ACTUAL: 516, PB_REMAIN: 26, },
{ NO: 12, SUBSYSTEM: '6820-24', MC_PLAN: '2020-02-07', PA_TOTAL: 10, PA_ACTUAL: 10, PA_REMAIN: 0, PB_TOTAL: 68, PB_ACTUAL: 40, PB_REMAIN: 28, },
{ NO: 13, SUBSYSTEM: '6070-03', MC_PLAN: '2020-02-28', PA_TOTAL: 20, PA_ACTUAL: 20, PA_REMAIN: 0, PB_TOTAL: 61, PB_ACTUAL: 33, PB_REMAIN: 28, },
{ NO: 14, SUBSYSTEM: '9255-01', MC_PLAN: '2020-02-14', PA_TOTAL: 84, PA_ACTUAL: 78, PA_REMAIN: 6, PB_TOTAL: 95, PB_ACTUAL: 74, PB_REMAIN: 21, },
{ NO: 15, SUBSYSTEM: '8120-01', MC_PLAN: '2020-02-21', PA_TOTAL: 46, PA_ACTUAL: 33, PA_REMAIN: 13, PB_TOTAL: 40, PB_ACTUAL: 28, PB_REMAIN: 12, },

    ],
}