import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1400,
      CanvasHeight: 200,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },
    DataItems:  [
      {LS_SUMMARY:"LS",ISSUED:1427,APPROVED:1055,UNDER_REVIEW:198,REJECTED:115,CANCELLED:59},
      {LS_SUMMARY:"TYPE III",ISSUED:7,APPROVED:5,UNDER_REVIEW:2,REJECTED:0,CANCELLED:0},
      {LS_SUMMARY:"TYPE I, II",ISSUED:1420,APPROVED:1050,UNDER_REVIEW:196,REJECTED:115,CANCELLED:59},
      {LS_SUMMARY:"NA",ISSUED:29,APPROVED:1,UNDER_REVIEW:17,REJECTED:8,CANCELLED:3}
    ],
}