import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 2300,
    CanvasHeight: 980,
    CanvasBorderWeight: 0.05,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.1,

    CanvasChartX: 50,
    CanvasChartY: 50,
    CanvasChartWidth: 480,
    CanvasChartHeight: 250,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  DataItems:  [
    {NO:1,PACKAGE:"P1",AREA:"OR",TAG_NO:"000000",TAG_DESCR:"PRIMARY FRACTIONATOR",TAG_TYPE:"V",DELIVERY_PLAN:"2022-10-31",DELIVERY_FORECAST:"2022-10-31",DELIVERY_ACTUAL:"2022-10-28",DRESSUP_REQ:"Y",DRESSUP_PROG:80,LIFTING_PLAN:"2022-12-03",LIFTING_PLAN_WK:"09-Dec-22",LIFTING_FORECAST:"2022-12-03",LIFTING_ACTUAL:"2022-12-03",STATUS:"Delivered",LOCATION:"急冷地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ11/13.5×48.75",WEIGHT_UNIT:1000,WEIGHT_ATT:1000,WEIGHT_DWG:955.2,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"NBTY",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计 Lifting lugs design done",DRAWING:"已有制造图 Shop assembly dwg IFC",CRANE_MAIN_FILTER:"4,000",CRANE_MAIN:"4000",CRANE_TAIL:"1600t Crane",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"SLT",MR_NO:"75231-P1-1279-EQ-R-E-LL03",DESCRIPTION:"PRIMARY FRACTIONATOR KCS KCS 316316L SS 14 507",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-10",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-10",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-15",LOA_ISSUE_PLAN:"2021-10-30",LOA_ISSUE_FORECAST:"2021-10-19",LOA_ISSUE_ACTUAL:"2021-10-14",PO_ISSUE_PLAN:"2021-12-11",PO_ISSUE_FORECAST:"2021-11-30",PO_ISSUE_ACTUAL:"2021-11-08",DELIVERY_TO_JOB_SITE_PLAN:"2022-11-15",DELIVERY_TO_JOB_SITE_ACTUAL:"2022-10-28",RAS_ROS:"2022-11-05"},
    {NO:1,PACKAGE:"P1",AREA:"OR",TAG_NO:"2521T001",TAG_DESCR:"PRIMARY FRACTIONATOR",TAG_TYPE:"V",DELIVERY_PLAN:"2022-10-31",DELIVERY_FORECAST:"2022-10-31",DELIVERY_ACTUAL:"2022-10-28",DRESSUP_REQ:"Y",DRESSUP_PROG:80,LIFTING_PLAN:"2022-12-03",LIFTING_PLAN_WK:"09-Dec-22",LIFTING_FORECAST:"2022-12-03",LIFTING_ACTUAL:"2022-12-03",STATUS:"To go",LOCATION:"急冷地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ11/13.5×48.75",WEIGHT_UNIT:1000,WEIGHT_ATT:1000,WEIGHT_DWG:955.2,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"NBTY",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计 Lifting lugs design done",DRAWING:"已有制造图 Shop assembly dwg IFC",CRANE_MAIN_FILTER:"4,000",CRANE_MAIN:"4000",CRANE_TAIL:"1600t Crane",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"SLT",MR_NO:"75231-P1-1279-EQ-R-E-LL03",DESCRIPTION:"PRIMARY FRACTIONATOR KCS KCS 316316L SS 14 507",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-10",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-10",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-15",LOA_ISSUE_PLAN:"2021-10-30",LOA_ISSUE_FORECAST:"2021-10-19",LOA_ISSUE_ACTUAL:"2021-10-14",PO_ISSUE_PLAN:"2021-12-11",PO_ISSUE_FORECAST:"2021-11-30",PO_ISSUE_ACTUAL:"2021-11-08",DELIVERY_TO_JOB_SITE_PLAN:"2022-11-15",DELIVERY_TO_JOB_SITE_ACTUAL:"2022-10-28",RAS_ROS:"2022-11-05"},
    {NO:2,PACKAGE:"P1",AREA:"OR",TAG_NO:"2522T001",TAG_DESCR:"QUENCH WATER TOWER",TAG_TYPE:"V",DELIVERY_PLAN:"2022-11-02",DELIVERY_FORECAST:"2022-11-02",DELIVERY_ACTUAL:"2022-11-15",DRESSUP_REQ:"Y",DRESSUP_PROG:100,LIFTING_PLAN:"2022-12-11",LIFTING_PLAN_WK:"16-Dec-22",LIFTING_FORECAST:"2022-12-11",LIFTING_ACTUAL:"2022-12-11",STATUS:"Complete",LOCATION:"急冷地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅8.8/11.3×62.85",WEIGHT_UNIT:1000,WEIGHT_ATT:1000,WEIGHT_DWG:995.01,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"NBTY",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计 Lifting lugs design done",DRAWING:"已有制造图 Shop assembly dwg IFC",CRANE_MAIN_FILTER:"4,000",CRANE_MAIN:"4000",CRANE_TAIL:"1600t Crane",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"SLT",MR_NO:"75231-P1-1279-EQ-R-E-LL03",DESCRIPTION:"QUENCH WATER TOWER KCS KCS 113 404",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-10",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-10",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-15",LOA_ISSUE_PLAN:"2021-10-30",LOA_ISSUE_FORECAST:"2021-10-19",LOA_ISSUE_ACTUAL:"2021-10-14",PO_ISSUE_PLAN:"2021-12-11",PO_ISSUE_FORECAST:"2021-11-30",PO_ISSUE_ACTUAL:"2021-11-08",DELIVERY_TO_JOB_SITE_PLAN:"2022-11-15",DELIVERY_TO_JOB_SITE_ACTUAL:"2022-11-15",RAS_ROS:"2022-11-05"},
    {NO:3,PACKAGE:"P1",AREA:"OR",TAG_NO:"2554T002",TAG_DESCR:"C3 RECTIFIER",TAG_TYPE:"V",DELIVERY_PLAN:"2022-11-07",DELIVERY_FORECAST:"2022-11-07",DELIVERY_ACTUAL:"2022-11-08",DRESSUP_REQ:"Y",DRESSUP_PROG:100,LIFTING_PLAN:"2022-12-19",LIFTING_PLAN_WK:"23-Dec-22",LIFTING_FORECAST:"2022-12-19",LIFTING_ACTUAL:"2022-12-19",STATUS:"Complete",LOCATION:"热区地面",SHIPMENT_NO:"TBD",DIMENSION_M:"Φ8.7×103.2",WEIGHT_UNIT:1730,WEIGHT_ATT:1730,WEIGHT_DWG:1540,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"NBTY",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计 Lifting lugs design done",DRAWING:"已有制造图 Shop assembly dwg IFC",CRANE_MAIN_FILTER:"1,600",CRANE_MAIN:"4000",CRANE_TAIL:"1600t Crane",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"SLT",MR_NO:"75231-P1-1279-EQ-R-E-LL05",DESCRIPTION:"C3 RECTIFIER 2 LTCS CS87 8742",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-20",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-08-02",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-08-02",LOA_ISSUE_PLAN:"2021-10-30",LOA_ISSUE_FORECAST:"2021-10-19",LOA_ISSUE_ACTUAL:"2021-10-14",PO_ISSUE_PLAN:"2021-12-11",PO_ISSUE_FORECAST:"2021-11-30",PO_ISSUE_ACTUAL:"2021-11-08",DELIVERY_TO_JOB_SITE_PLAN:"2022-11-15",DELIVERY_TO_JOB_SITE_ACTUAL:"2022-11-15",RAS_ROS:"2022-11-05"},
    // {NO:4,PACKAGE:"P1",AREA:"OR",TAG_NO:"2554T001",TAG_DESCR:"C3 STRIPPER",TAG_TYPE:"V",DELIVERY_PLAN:"2022-11-08",DELIVERY_FORECAST:"2022-11-08",DELIVERY_ACTUAL:"2022-11-09",DRESSUP_REQ:"Y",DRESSUP_PROG:100,LIFTING_PLAN:"2022-12-25",LIFTING_PLAN_WK:"30-Dec-22",LIFTING_FORECAST:"2022-12-25",LIFTING_ACTUAL:"2022-12-24",STATUS:"Complete",LOCATION:"热区地面",SHIPMENT_NO:"TBD",DIMENSION_M:"Φ7.5×62.3",WEIGHT_UNIT:875,WEIGHT_ATT:875,WEIGHT_DWG:780,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"NBTY",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计 Lifting lugs design done",DRAWING:"已有制造图 Shop assembly dwg IFC",CRANE_MAIN_FILTER:"4,000",CRANE_MAIN:"4000",CRANE_TAIL:"1600t Crane",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"SLT",MR_NO:"75231-P1-1279-EQ-R-E-LL05",DESCRIPTION:"C3 STRIPPERC3 1 LTCS CS75 46",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-20",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-08-02",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-08-02",LOA_ISSUE_PLAN:"2021-10-30",LOA_ISSUE_FORECAST:"2021-10-19",LOA_ISSUE_ACTUAL:"2021-10-14",PO_ISSUE_PLAN:"2021-12-11",PO_ISSUE_FORECAST:"2021-11-30",PO_ISSUE_ACTUAL:"2021-11-08",DELIVERY_TO_JOB_SITE_PLAN:"2022-11-15",DELIVERY_TO_JOB_SITE_ACTUAL:"2022-11-15",RAS_ROS:"2022-11-05"},
    // {NO:5,PACKAGE:"P1",AREA:"OR",TAG_NO:"2545T001",TAG_DESCR:"C2 SPLITTER",TAG_TYPE:"V",DELIVERY_PLAN:"2022-11-13",DELIVERY_FORECAST:"2022-11-13",DELIVERY_ACTUAL:"2022-11-14",DRESSUP_REQ:"Y",DRESSUP_PROG:100,LIFTING_PLAN:"2023-01-04",LIFTING_PLAN_WK:"06-Jan-23",LIFTING_FORECAST:"2023-01-04",LIFTING_ACTUAL:"2023-01-02",STATUS:"Complete",LOCATION:"冷区地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅6.0×86.48",WEIGHT_UNIT:510,WEIGHT_ATT:510,WEIGHT_DWG:550,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"TJHY",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计 Lifting lugs design done",DRAWING:"已有制造图 Shop assembly dwg IFC、管口方位图",CRANE_MAIN_FILTER:"4,000",CRANE_MAIN:"4000",CRANE_TAIL:"1600t Crane",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"SLT",MR_NO:"75231-P1-1279-EQ-R-E-LL04",DESCRIPTION:"C2 Splitter 304LSS 304LSS 6 6972",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-20",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-08-02",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-08-02",LOA_ISSUE_PLAN:"2021-10-30",LOA_ISSUE_FORECAST:"2021-10-22",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2021-12-11",PO_ISSUE_FORECAST:"2021-12-03",PO_ISSUE_ACTUAL:"2021-11-08",DELIVERY_TO_JOB_SITE_PLAN:"2022-10-31",DELIVERY_TO_JOB_SITE_ACTUAL:"2022-11-15",RAS_ROS:"2022-11-05"},
    // {NO:6,PACKAGE:"P1",AREA:"OR",TAG_NO:"2540T001",TAG_DESCR:"DEMETHANIZER PREFRACTIONATOR",TAG_TYPE:"V",DELIVERY_PLAN:"2022-11-11",DELIVERY_FORECAST:"2022-11-11",DELIVERY_ACTUAL:"2022-11-18",DRESSUP_REQ:"N",DRESSUP_PROG:100,LIFTING_PLAN:"2023-01-12",LIFTING_PLAN_WK:"13-Jan-23",LIFTING_FORECAST:"2023-01-12",LIFTING_ACTUAL:"2023-01-08",STATUS:"Complete",LOCATION:"冷区地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅4.4/5.2×58.9+∅4.45×13.1",WEIGHT_UNIT:840,WEIGHT_ATT:840,WEIGHT_DWG:840,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"TJHY",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计 Lifting lugs design done",DRAWING:"已有制造图 Shop assembly dwg IFC",CRANE_MAIN_FILTER:"4,000",CRANE_MAIN:"4000",CRANE_TAIL:"1600t Crane",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"SLT",MR_NO:"75231-P1-1279-EQ-R-E-LL04",DESCRIPTION:"Demethanizer PrefractionatorSS 304LSS 304LSS 52 4865",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-20",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-08-02",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-08-02",LOA_ISSUE_PLAN:"2021-10-30",LOA_ISSUE_FORECAST:"2021-10-22",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2021-12-11",PO_ISSUE_FORECAST:"2021-12-03",PO_ISSUE_ACTUAL:"2021-11-08",DELIVERY_TO_JOB_SITE_PLAN:"2022-10-31",DELIVERY_TO_JOB_SITE_ACTUAL:"2022-11-15",RAS_ROS:"2022-11-05"},
    // {NO:7,PACKAGE:"P2",AREA:"PP",TAG_NO:"6230R011",TAG_DESCR:"First Loop Reactor",TAG_TYPE:"V",DELIVERY_PLAN:"2022-12-16",DELIVERY_FORECAST:"2022-12-16",DELIVERY_ACTUAL:"2022-12-24",DRESSUP_REQ:"N",DRESSUP_PROG:100,LIFTING_PLAN:"2023-01-12",LIFTING_PLAN_WK:"13-Jan-23",LIFTING_FORECAST:"2023-01-12",LIFTING_ACTUAL:"2023-01-10",STATUS:"Complete",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"4.2×φ0.72×43.32",WEIGHT_UNIT:286,WEIGHT_ATT:286,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"茂重 Guangdong Maozhong",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计 Lifting lugs design done",DRAWING:"有初版总装配图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P2-1219-EQ-R-E-LL02",DESCRIPTION:"Loop Reactor",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-05-12",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-09-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-09-26",LOA_ISSUE_PLAN:"2021-08-31",LOA_ISSUE_FORECAST:"2021-12-23",LOA_ISSUE_ACTUAL:"2021-12-24",PO_ISSUE_PLAN:"2021-10-12",PO_ISSUE_FORECAST:"2022-02-15",PO_ISSUE_ACTUAL:"2022-02-18",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-06",DELIVERY_TO_JOB_SITE_ACTUAL:"2022-12-24",RAS_ROS:"2023-06-28"},
    // {NO:8,PACKAGE:"P2",AREA:"PP",TAG_NO:"6230R012",TAG_DESCR:"Second Loop Reactor",TAG_TYPE:"V",DELIVERY_PLAN:"2022-12-30",DELIVERY_FORECAST:"2022-12-30",DELIVERY_ACTUAL:"2022-12-30",DRESSUP_REQ:"N",DRESSUP_PROG:100,LIFTING_PLAN:"2023-01-14",LIFTING_PLAN_WK:"20-Jan-23",LIFTING_FORECAST:"2023-01-14",LIFTING_ACTUAL:"2023-01-12",STATUS:"Complete",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"4.2×φ0.72×43.32",WEIGHT_UNIT:286,WEIGHT_ATT:286,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"茂重 Guangdong Maozhong",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计 Lifting lugs design done",DRAWING:"有初版总装配图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P2-1219-EQ-R-E-LL02",DESCRIPTION:"Loop Reactor",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-05-12",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-09-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-09-26",LOA_ISSUE_PLAN:"2021-08-31",LOA_ISSUE_FORECAST:"2021-12-23",LOA_ISSUE_ACTUAL:"2021-12-24",PO_ISSUE_PLAN:"2021-10-12",PO_ISSUE_FORECAST:"2022-02-15",PO_ISSUE_ACTUAL:"2022-02-18",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-06",DELIVERY_TO_JOB_SITE_ACTUAL:"2023-01-12",RAS_ROS:"2023-06-28"},
    // {NO:9,PACKAGE:"P1",AREA:"OR",TAG_NO:"2536T001",TAG_DESCR:"HP DEPROPANIZER",TAG_TYPE:"V",DELIVERY_PLAN:"2022-12-09",DELIVERY_FORECAST:"2022-12-09",DELIVERY_ACTUAL:"2022-12-14",DRESSUP_REQ:"Y",DRESSUP_PROG:100,LIFTING_PLAN:"2023-01-14",LIFTING_PLAN_WK:"20-Jan-23",LIFTING_FORECAST:"2023-01-14",LIFTING_ACTUAL:"2023-01-13",STATUS:"Complete",LOCATION:"压缩地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅4.9/4.0×60.4",WEIGHT_UNIT:405,WEIGHT_ATT:405,WEIGHT_DWG:363,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"NBTY",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计 Lifting lugs design done",DRAWING:"已有制造图 Shop assembly dwg IFC",CRANE_MAIN_FILTER:"1,600",CRANE_MAIN:"1600",CRANE_TAIL:"400t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"SLT",MR_NO:"75231-P1-1279-EQ-R-E-SL06",DESCRIPTION:"HP DEPROPANIZER 1600 LTCS 49 4425 TOP cold BTM hot",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-09-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-09-28",LOA_ISSUE_PLAN:"2022-01-30",LOA_ISSUE_FORECAST:"2021-12-24",LOA_ISSUE_ACTUAL:"2021-12-23",PO_ISSUE_PLAN:"2022-03-13",PO_ISSUE_FORECAST:"2022-02-14",PO_ISSUE_ACTUAL:"2021-12-24",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2022-10-20"},
    // {NO:10,PACKAGE:"P1",AREA:"BD",TAG_NO:"3011T021",TAG_DESCR:"MAIN WASHER",TAG_TYPE:"V",DELIVERY_PLAN:"2022-12-28",DELIVERY_FORECAST:"2022-12-28",DELIVERY_ACTUAL:"2022-12-29",DRESSUP_REQ:"Y",DRESSUP_PROG:100,LIFTING_PLAN:"2023-02-07",LIFTING_PLAN_WK:"10-Feb-23",LIFTING_FORECAST:"2023-02-07",LIFTING_ACTUAL:"2023-02-10",STATUS:"Complete",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ4.4×75.8",WEIGHT_UNIT:229.33,WEIGHT_ATT:229.33,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"QDRK",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计 Lifting lugs design done",DRAWING:"已有初版制造图、管口方位图",CRANE_MAIN_FILTER:"1,600",CRANE_MAIN:"1600",CRANE_TAIL:"400t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"SLT",MR_NO:"75231-P1-1277-EQ-R-E-0101",DESCRIPTION:"MAIN WASHER 1600",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-23",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-23",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-03-05",LOA_ISSUE_FORECAST:"2022-03-31",LOA_ISSUE_ACTUAL:"2022-03-23",PO_ISSUE_PLAN:"2022-04-16",PO_ISSUE_FORECAST:"2022-05-12",PO_ISSUE_ACTUAL:"2022-04-13",DELIVERY_TO_JOB_SITE_PLAN:"2022-11-07",RAS_ROS:"2023-01-30"},
    // {NO:11,PACKAGE:"P1",AREA:"OR",TAG_NO:"STACK",TAG_DESCR:"Stack",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",SHIPMENT_NO:"TBD",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"NA"},
    // {NO:12,PACKAGE:"P1",AREA:"OR",TAG_NO:"STACK",TAG_DESCR:"Stack",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",SHIPMENT_NO:"TBD",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"NA"},
    // {NO:13,PACKAGE:"P1",AREA:"OR",TAG_NO:"STACK",TAG_DESCR:"Stack",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",SHIPMENT_NO:"TBD",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"NA"},
    // {NO:14,PACKAGE:"P1",AREA:"OR",TAG_NO:"STACK",TAG_DESCR:"Stack",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",SHIPMENT_NO:"TBD",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"NA"},
    // {NO:15,PACKAGE:"P1",AREA:"OR",TAG_NO:"STACK",TAG_DESCR:"Stack",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",SHIPMENT_NO:"TBD",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"NA"},
    // {NO:16,PACKAGE:"P1",AREA:"OR",TAG_NO:"STACK",TAG_DESCR:"Stack",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",SHIPMENT_NO:"TBD",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"NA"},
    // {NO:17,PACKAGE:"P1",AREA:"OR",TAG_NO:"STACK",TAG_DESCR:"Stack",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",SHIPMENT_NO:"TBD",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"NA"},
    // {NO:18,PACKAGE:"P1",AREA:"OR",TAG_NO:"STACK",TAG_DESCR:"Stack",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",SHIPMENT_NO:"TBD",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"NA"},
    // {NO:19,PACKAGE:"P1",AREA:"OR",TAG_NO:"STACK",TAG_DESCR:"Stack",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",SHIPMENT_NO:"TBD",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"NA"},
    // {NO:20,PACKAGE:"P1",AREA:"OR",TAG_NO:"STACK",TAG_DESCR:"Stack",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",SHIPMENT_NO:"TBD",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"NA"},
    // {NO:21,PACKAGE:"P1",AREA:"OR",TAG_NO:"1014E111",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:22,PACKAGE:"P1",AREA:"OR",TAG_NO:"1014E121",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:23,PACKAGE:"P1",AREA:"OR",TAG_NO:"1014E131",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:24,PACKAGE:"P1",AREA:"OR",TAG_NO:"1014E141",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:25,PACKAGE:"P1",AREA:"OR",TAG_NO:"1014E151",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:26,PACKAGE:"P1",AREA:"OR",TAG_NO:"1014E161",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:27,PACKAGE:"P1",AREA:"OR",TAG_NO:"1014E171",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:28,PACKAGE:"P1",AREA:"OR",TAG_NO:"1014E181",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:29,PACKAGE:"P1",AREA:"OR",TAG_NO:"1015E111",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:30,PACKAGE:"P1",AREA:"OR",TAG_NO:"1015E121",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:31,PACKAGE:"P1",AREA:"OR",TAG_NO:"1015E131",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:32,PACKAGE:"P1",AREA:"OR",TAG_NO:"1015E141",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:33,PACKAGE:"P1",AREA:"OR",TAG_NO:"1015E151",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:34,PACKAGE:"P1",AREA:"OR",TAG_NO:"1015E161",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:35,PACKAGE:"P1",AREA:"OR",TAG_NO:"1015E171",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:36,PACKAGE:"P1",AREA:"OR",TAG_NO:"1015E181",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:37,PACKAGE:"P1",AREA:"OR",TAG_NO:"1018E111",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:38,PACKAGE:"P1",AREA:"OR",TAG_NO:"1018E121",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:39,PACKAGE:"P1",AREA:"OR",TAG_NO:"1018E131",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:40,PACKAGE:"P1",AREA:"OR",TAG_NO:"1018E141",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:41,PACKAGE:"P1",AREA:"OR",TAG_NO:"1018E151",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:42,PACKAGE:"P1",AREA:"OR",TAG_NO:"1018E161",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:43,PACKAGE:"P1",AREA:"OR",TAG_NO:"1018E171",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:44,PACKAGE:"P1",AREA:"OR",TAG_NO:"1018E181",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:45,PACKAGE:"P1",AREA:"OR",TAG_NO:"1019E111",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:46,PACKAGE:"P1",AREA:"OR",TAG_NO:"1019E121",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:47,PACKAGE:"P1",AREA:"OR",TAG_NO:"1019E131",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:48,PACKAGE:"P1",AREA:"OR",TAG_NO:"1019E141",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:49,PACKAGE:"P1",AREA:"OR",TAG_NO:"1019E151",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:50,PACKAGE:"P1",AREA:"OR",TAG_NO:"1019E161",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:51,PACKAGE:"P1",AREA:"OR",TAG_NO:"1019E171",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:52,PACKAGE:"P1",AREA:"OR",TAG_NO:"1019E181",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:53,PACKAGE:"P1",AREA:"OR",TAG_NO:"1020E111",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:54,PACKAGE:"P1",AREA:"OR",TAG_NO:"1020E121",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:55,PACKAGE:"P1",AREA:"OR",TAG_NO:"1020E131",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:56,PACKAGE:"P1",AREA:"OR",TAG_NO:"1020E141",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:57,PACKAGE:"P1",AREA:"OR",TAG_NO:"1020E151",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:58,PACKAGE:"P1",AREA:"OR",TAG_NO:"1020E161",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:59,PACKAGE:"P1",AREA:"OR",TAG_NO:"1020E171",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"1600",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:60,PACKAGE:"P1",AREA:"OR",TAG_NO:"1020E181",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-02-09",LIFTING_PLAN_WK:"10-Feb-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"4000",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:61,PACKAGE:"P1",AREA:"BD",TAG_NO:"3011T022",TAG_DESCR:"RECTIFIER/AFTERWA SHER",TAG_TYPE:"V",DELIVERY_PLAN:"2022-12-29",DELIVERY_FORECAST:"2022-12-29",DELIVERY_ACTUAL:"2022-12-30",DRESSUP_REQ:"Y",DRESSUP_PROG:100,LIFTING_PLAN:"2023-02-12",LIFTING_PLAN_WK:"17-Feb-23",LIFTING_FORECAST:"2023-02-12",LIFTING_ACTUAL:"2023-02-28",STATUS:"Complete",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ1.8/5.1×86.25",WEIGHT_UNIT:315,WEIGHT_ATT:315,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"QDRK",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计 Lifting lugs design done",DRAWING:"已有初版制造图、管口方位图",CRANE_MAIN_FILTER:"1,600",CRANE_MAIN:"1600",CRANE_TAIL:"400t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"SLT",MR_NO:"75231-P1-1277-EQ-R-E-0101",DESCRIPTION:"RECTIFIERAFTERWASHER 1600",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-23",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-23",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-03-05",LOA_ISSUE_FORECAST:"2022-03-31",LOA_ISSUE_ACTUAL:"2022-03-23",PO_ISSUE_PLAN:"2022-04-16",PO_ISSUE_FORECAST:"2022-05-12",PO_ISSUE_ACTUAL:"2022-04-13",DELIVERY_TO_JOB_SITE_PLAN:"2022-11-07",RAS_ROS:"2023-01-30"},
    // {NO:62,PACKAGE:"P1",AREA:"BD",TAG_NO:"3012T034",TAG_DESCR:"COOLING COLUMN",TAG_TYPE:"V",DELIVERY_PLAN:"2023-02-16",DELIVERY_FORECAST:"2023-02-16",DRESSUP_REQ:"Y",DRESSUP_PROG:0,LIFTING_PLAN:"2023-02-17",LIFTING_PLAN_WK:"17-Feb-23",LIFTING_FORECAST:"2023-02-17",STATUS:"Complete",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ3.4×20.59",WEIGHT_UNIT:34,WEIGHT_ATT:34,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"QDRK",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计",DRAWING:"有装配图和管口方位图",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1277-EQ-R-E-0102",DESCRIPTION:"COOLING COLUMN",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-11-18",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-11-18",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-08",LOA_ISSUE_PLAN:"2022-03-05",LOA_ISSUE_FORECAST:"2022-03-21",LOA_ISSUE_ACTUAL:"2022-03-23",PO_ISSUE_PLAN:"2022-04-16",PO_ISSUE_FORECAST:"2022-05-02",PO_ISSUE_ACTUAL:"2022-04-13",DELIVERY_TO_JOB_SITE_PLAN:"2023-12-21",RAS_ROS:"2023-02-15"},
    // {NO:63,PACKAGE:"P1",AREA:"OR",TAG_NO:"2522T051",TAG_DESCR:"DISTILLATE STRIPPER",TAG_TYPE:"V",DELIVERY_PLAN:"2023-02-16",DELIVERY_FORECAST:"2023-02-16",DRESSUP_REQ:"Y",DRESSUP_PROG:0,LIFTING_PLAN:"2023-02-22",LIFTING_PLAN_WK:"24-Feb-23",LIFTING_FORECAST:"2023-02-22",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.7×31.5",WEIGHT_UNIT:41,WEIGHT_ATT:41,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"QDRK",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0101",DESCRIPTION:"DISTILLATE STRIPPER KCS 27 233",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-24",LOA_ISSUE_PLAN:"2022-02-28",LOA_ISSUE_FORECAST:"2022-03-11",LOA_ISSUE_ACTUAL:"2022-03-23",PO_ISSUE_PLAN:"2022-04-11",PO_ISSUE_FORECAST:"2022-04-22",PO_ISSUE_ACTUAL:"2022-04-13",DELIVERY_TO_JOB_SITE_PLAN:"2022-11-02",RAS_ROS:"2023-02-15"},
    // {NO:64,PACKAGE:"P1",AREA:"BD",TAG_NO:"3016T001",TAG_DESCR:"WATER WASH COLUMN",TAG_TYPE:"V",DELIVERY_PLAN:"2023-02-13",DELIVERY_FORECAST:"2023-02-13",DRESSUP_REQ:"Y",DRESSUP_PROG:0,LIFTING_PLAN:"2023-02-23",LIFTING_PLAN_WK:"24-Feb-23",LIFTING_FORECAST:"2023-02-23",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ1.8×34.75",WEIGHT_UNIT:39.5,WEIGHT_ATT:39.5,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"QDRK",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计",DRAWING:"有装配图和管口方位图",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1277-EQ-R-E-0102",DESCRIPTION:"water wash columnC4",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-11-18",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-11-18",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-08",LOA_ISSUE_PLAN:"2022-03-05",LOA_ISSUE_FORECAST:"2022-03-21",LOA_ISSUE_ACTUAL:"2022-03-23",PO_ISSUE_PLAN:"2022-04-16",PO_ISSUE_FORECAST:"2022-05-02",PO_ISSUE_ACTUAL:"2022-04-13",DELIVERY_TO_JOB_SITE_PLAN:"2023-12-24",RAS_ROS:"2023-02-15"},
    // {NO:65,PACKAGE:"P1",AREA:"OR",TAG_NO:"2534T031",TAG_DESCR:"CAUSTIC TOWER",TAG_TYPE:"V",DELIVERY_PLAN:"2022-12-08",DELIVERY_FORECAST:"2022-12-08",DELIVERY_ACTUAL:"2022-12-12",DRESSUP_REQ:"Y",DRESSUP_PROG:90,LIFTING_PLAN:"2023-02-26",LIFTING_PLAN_WK:"03-Mar-23",LIFTING_FORECAST:"2023-02-26",STATUS:"Delivered",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅4.8×64.6",WEIGHT_UNIT:420,WEIGHT_ATT:420,WEIGHT_DWG:420,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"NBTY",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计 Lifting lugs design done",DRAWING:"已有制造图 Shop assembly dwg IFC",CRANE_MAIN_FILTER:"1,600",CRANE_MAIN:"1600",CRANE_TAIL:"400t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"SLT",MR_NO:"75231-P1-1279-EQ-R-E-LL03",DESCRIPTION:"CAUSTIC TOWER1600 KCS KCS 48 5445",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-10",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-10",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-15",LOA_ISSUE_PLAN:"2021-10-30",LOA_ISSUE_FORECAST:"2021-10-19",LOA_ISSUE_ACTUAL:"2021-10-14",PO_ISSUE_PLAN:"2021-12-11",PO_ISSUE_FORECAST:"2021-11-30",PO_ISSUE_ACTUAL:"2021-11-08",DELIVERY_TO_JOB_SITE_PLAN:"2022-11-15",RAS_ROS:"2022-12-10"},
    // {NO:66,PACKAGE:"P1",AREA:"BD",TAG_NO:"3012T031",TAG_DESCR:"DEGASSER",TAG_TYPE:"V",DELIVERY_PLAN:"2023-02-08",DELIVERY_FORECAST:"2023-02-08",DRESSUP_REQ:"Y",DRESSUP_PROG:0,LIFTING_PLAN:"2023-02-27",LIFTING_PLAN_WK:"03-Mar-23",LIFTING_FORECAST:"2023-02-27",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ3.7×64.1",WEIGHT_UNIT:169.875,WEIGHT_ATT:169.875,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"QDRK",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计 Lifting lugs design done",DRAWING:"有制造图、管口方位图 shop assembly dwg IFC",CRANE_MAIN_FILTER:"800",CRANE_MAIN:"800",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1277-EQ-R-E-0102",DESCRIPTION:"DEGASSER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-11-18",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-11-18",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-08",LOA_ISSUE_PLAN:"2022-03-05",LOA_ISSUE_FORECAST:"2022-03-21",LOA_ISSUE_ACTUAL:"2022-03-23",PO_ISSUE_PLAN:"2022-04-16",PO_ISSUE_FORECAST:"2022-05-02",PO_ISSUE_ACTUAL:"2022-04-13",DELIVERY_TO_JOB_SITE_PLAN:"2022-11-07",RAS_ROS:"2023-02-15"},
    // {NO:67,PACKAGE:"P1",AREA:"BD",TAG_NO:"3013T041",TAG_DESCR:"PROPYNE COLUMN",TAG_TYPE:"V",DELIVERY_PLAN:"2023-02-13",DELIVERY_FORECAST:"2023-02-13",DRESSUP_REQ:"Y",DRESSUP_PROG:0,LIFTING_PLAN:"2023-02-28",LIFTING_PLAN_WK:"03-Mar-23",LIFTING_FORECAST:"2023-02-28",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.3×41.625",WEIGHT_UNIT:44,WEIGHT_ATT:44,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"QDRK",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计",DRAWING:"有装配图和管口方位图",CRANE_MAIN_FILTER:"800",CRANE_MAIN:"800",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1277-EQ-R-E-0102",DESCRIPTION:"PROPYNE COLUMN",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-11-18",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-11-18",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-08",LOA_ISSUE_PLAN:"2022-03-05",LOA_ISSUE_FORECAST:"2022-03-21",LOA_ISSUE_ACTUAL:"2022-03-23",PO_ISSUE_PLAN:"2022-04-16",PO_ISSUE_FORECAST:"2022-05-02",PO_ISSUE_ACTUAL:"2022-04-13",DELIVERY_TO_JOB_SITE_PLAN:"2023-12-22",RAS_ROS:"2023-02-15"},
    // {NO:68,PACKAGE:"P1",AREA:"OR",TAG_NO:"2555T001",TAG_DESCR:"DEBUTANIZER",TAG_TYPE:"V",DELIVERY_PLAN:"2023-02-08",DELIVERY_FORECAST:"2023-02-08",DRESSUP_REQ:"Y",DRESSUP_PROG:0,LIFTING_PLAN:"2023-03-03",LIFTING_PLAN_WK:"03-Mar-23",LIFTING_FORECAST:"2023-03-03",STATUS:"To go",LOCATION:"热区地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅3.5×48.3",WEIGHT_UNIT:96,WEIGHT_ATT:96,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"QDRK",DRAWING:"有工程图 Engineering dwg issued",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-2",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0101",DESCRIPTION:"DEBUTANIZER KCS 35 3445",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-24",LOA_ISSUE_PLAN:"2022-02-28",LOA_ISSUE_FORECAST:"2022-03-11",LOA_ISSUE_ACTUAL:"2022-03-23",PO_ISSUE_PLAN:"2022-04-11",PO_ISSUE_FORECAST:"2022-04-22",PO_ISSUE_ACTUAL:"2022-04-13",DELIVERY_TO_JOB_SITE_PLAN:"2022-11-02",RAS_ROS:"2023-02-15"},
    // {NO:69,PACKAGE:"P1",AREA:"OR",TAG_NO:"2555T051",TAG_DESCR:"C4-CAUSTIC LIQUID/LIQUID",TAG_TYPE:"V",DELIVERY_PLAN:"2023-02-08",DELIVERY_FORECAST:"2023-02-08",DRESSUP_REQ:"Y",DRESSUP_PROG:0,LIFTING_PLAN:"2023-03-05",LIFTING_PLAN_WK:"10-Mar-23",LIFTING_FORECAST:"2023-03-05",STATUS:"To go",LOCATION:"压缩地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.7×40.75",WEIGHT_UNIT:88,WEIGHT_ATT:88,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"QDRK",DRAWING:"有工程图 Engineering dwg issued",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-2",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0101",DESCRIPTION:"C4CAUSTIC LIQUIDLIQUID EXTRACTOR KCS 27 275",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-24",LOA_ISSUE_PLAN:"2022-02-28",LOA_ISSUE_FORECAST:"2022-03-11",LOA_ISSUE_ACTUAL:"2022-03-23",PO_ISSUE_PLAN:"2022-04-11",PO_ISSUE_FORECAST:"2022-04-22",PO_ISSUE_ACTUAL:"2022-04-13",DELIVERY_TO_JOB_SITE_PLAN:"2022-11-02",RAS_ROS:"2023-02-15"},
    // {NO:70,PACKAGE:"P1",AREA:"OR",TAG_NO:"2536T051",TAG_DESCR:"LP DEPROPANIZER",TAG_TYPE:"V",DELIVERY_PLAN:"2022-12-10",DELIVERY_FORECAST:"2022-12-10",DELIVERY_ACTUAL:"2022-12-15",DRESSUP_REQ:"Y",DRESSUP_PROG:0,LIFTING_PLAN:"2023-03-05",LIFTING_PLAN_WK:"10-Mar-23",LIFTING_FORECAST:"2023-03-05",STATUS:"Delivered",LOCATION:"冷区地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅3.8/4.4×71.2",WEIGHT_UNIT:220,WEIGHT_ATT:220,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"NBTY",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计 Lifting lugs design done",DRAWING:"已有初版制造图",CRANE_MAIN_FILTER:"1,600",CRANE_MAIN:"1600",CRANE_TAIL:"400t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"SLT",MR_NO:"75231-P1-1279-EQ-R-E-SL12",DESCRIPTION:"LP DEPROPANIZER 1600 LTCS 44 552 TOP cold BTM hot",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-23",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-23",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-05",LOA_ISSUE_PLAN:"2022-02-18",LOA_ISSUE_FORECAST:"2022-02-03",LOA_ISSUE_ACTUAL:"2022-02-14",PO_ISSUE_PLAN:"2022-04-01",PO_ISSUE_FORECAST:"2022-03-17",PO_ISSUE_ACTUAL:"2022-03-28",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2022-12-15"},
    // {NO:71,PACKAGE:"P2",AREA:"LDPE",TAG_NO:"5360BN701",TAG_DESCR:"Blending and bagging Silo ",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-05",DELIVERY_FORECAST:"2023-01-05",DELIVERY_ACTUAL:"2023-02-03",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-03-07",LIFTING_PLAN_WK:"10-Mar-23",LIFTING_FORECAST:"2023-03-07",STATUS:"Delivered",LOCATION:"框架 EL+15.0",SHIPMENT_NO:"TBD",DIMENSION_M:"φ7.0×40.0",WEIGHT_UNIT:45,WEIGHT_ATT:45,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DESIGN_OF_LIFTING_LUG:"厂家自设",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others"},
    // {NO:72,PACKAGE:"P2",AREA:"LDPE",TAG_NO:"5380BN801A",TAG_DESCR:"Pellet Degassing Silo",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-05",DELIVERY_FORECAST:"2023-01-05",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-03-10",LIFTING_PLAN_WK:"10-Mar-23",LIFTING_FORECAST:"2023-03-10",STATUS:"To go",LOCATION:"框架 EL+15.0",SHIPMENT_NO:"TBD",DIMENSION_M:"φ7.0×39.35",WEIGHT_UNIT:44,WEIGHT_ATT:264,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DESIGN_OF_LIFTING_LUG:"厂家自设",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others"},
    // {NO:73,PACKAGE:"P2",AREA:"LDPE",TAG_NO:"5380BN801B",TAG_DESCR:"Pellet Degassing Silo",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-05",DELIVERY_FORECAST:"2023-01-05",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-03-10",LIFTING_PLAN_WK:"10-Mar-23",LIFTING_FORECAST:"2023-03-10",STATUS:"To go",LOCATION:"框架 EL+15.0",SHIPMENT_NO:"TBD",DIMENSION_M:"φ7.0×39.35",WEIGHT_UNIT:44,WEIGHT_ATT:264,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DESIGN_OF_LIFTING_LUG:"厂家自设",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others"},
    // {NO:74,PACKAGE:"P2",AREA:"LDPE",TAG_NO:"5380BN801C",TAG_DESCR:"Pellet Degassing Silo",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-05",DELIVERY_FORECAST:"2023-01-05",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-03-10",LIFTING_PLAN_WK:"10-Mar-23",LIFTING_FORECAST:"2023-03-10",STATUS:"To go",LOCATION:"框架 EL+15.0",SHIPMENT_NO:"TBD",DIMENSION_M:"φ7.0×39.35",WEIGHT_UNIT:44,WEIGHT_ATT:264,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DESIGN_OF_LIFTING_LUG:"厂家自设",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others"},
    // {NO:75,PACKAGE:"P2",AREA:"LDPE",TAG_NO:"5380BN801D",TAG_DESCR:"Pellet Degassing Silo",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-05",DELIVERY_FORECAST:"2023-01-05",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-03-10",LIFTING_PLAN_WK:"10-Mar-23",LIFTING_FORECAST:"2023-03-10",STATUS:"To go",LOCATION:"框架 EL+15.0",SHIPMENT_NO:"TBD",DIMENSION_M:"φ7.0×39.35",WEIGHT_UNIT:44,WEIGHT_ATT:264,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DESIGN_OF_LIFTING_LUG:"厂家自设",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others"},
    // {NO:76,PACKAGE:"P2",AREA:"LDPE",TAG_NO:"5380BN801E",TAG_DESCR:"Pellet Degassing Silo",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-05",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-03-10",LIFTING_PLAN_WK:"10-Mar-23",STATUS:"To go",LOCATION:"框架 EL+15.0",SHIPMENT_NO:"TBD",DIMENSION_M:"φ7.0×39.35",WEIGHT_UNIT:44,WEIGHT_ATT:264,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DESIGN_OF_LIFTING_LUG:"厂家自设",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others"},
    // {NO:77,PACKAGE:"P2",AREA:"LDPE",TAG_NO:"5380BN801F",TAG_DESCR:"Pellet Degassing Silo",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-05",DELIVERY_FORECAST:"2023-01-05",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-03-10",LIFTING_PLAN_WK:"10-Mar-23",LIFTING_FORECAST:"2023-03-10",STATUS:"To go",LOCATION:"框架 EL+15.0",SHIPMENT_NO:"TBD",DIMENSION_M:"φ7.0×39.35",WEIGHT_UNIT:44,WEIGHT_ATT:264,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DESIGN_OF_LIFTING_LUG:"厂家自设",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others"},
    // {NO:78,PACKAGE:"P1",AREA:"OR",TAG_NO:"1019HV1002",TAG_DESCR:"Cleaning Valve",TAG_TYPE:"V",DELIVERY_PLAN:"2023-02-28",DELIVERY_FORECAST:"2023-02-28",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-03-10",LIFTING_PLAN_WK:"10-Mar-23",STATUS:"To go",LOCATION:"炉前管廊",SHIPMENT_NO:"TBD",DIMENSION_M:"26寸",WEIGHT_UNIT:6,WEIGHT_ATT:60,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"中国香港嘉明工程有限公司",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:79,PACKAGE:"P1",AREA:"OR",TAG_NO:"1020HV1002",TAG_DESCR:"Cleaning Valve",TAG_TYPE:"V",DELIVERY_PLAN:"2023-02-28",DELIVERY_FORECAST:"2023-02-28",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-03-10",LIFTING_PLAN_WK:"10-Mar-23",STATUS:"To go",LOCATION:"炉前管廊",SHIPMENT_NO:"TBD",DIMENSION_M:"26寸",WEIGHT_UNIT:6,WEIGHT_ATT:60,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"中国香港嘉明工程有限公司",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:80,PACKAGE:"P1",AREA:"OR",TAG_NO:"1017HV1002",TAG_DESCR:"Cleaning Valve",TAG_TYPE:"V",DELIVERY_PLAN:"2023-02-28",DELIVERY_FORECAST:"2023-02-28",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-03-10",LIFTING_PLAN_WK:"10-Mar-23",STATUS:"To go",LOCATION:"炉前管廊",SHIPMENT_NO:"TBD",DIMENSION_M:"26寸",WEIGHT_UNIT:6,WEIGHT_ATT:60,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"中国香港嘉明工程有限公司",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:81,PACKAGE:"P1",AREA:"OR",TAG_NO:"1018HV1002",TAG_DESCR:"Cleaning Valve",TAG_TYPE:"V",DELIVERY_PLAN:"2023-02-28",DELIVERY_FORECAST:"2023-02-28",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-03-10",LIFTING_PLAN_WK:"10-Mar-23",STATUS:"To go",LOCATION:"炉前管廊",SHIPMENT_NO:"TBD",DIMENSION_M:"26寸",WEIGHT_UNIT:6,WEIGHT_ATT:60,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"中国香港嘉明工程有限公司",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:82,PACKAGE:"P1",AREA:"OR",TAG_NO:"1013HV1002",TAG_DESCR:"Cleaning Valve",TAG_TYPE:"V",DELIVERY_PLAN:"2023-02-28",DELIVERY_FORECAST:"2023-02-28",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-03-10",LIFTING_PLAN_WK:"10-Mar-23",STATUS:"To go",LOCATION:"炉前管廊",SHIPMENT_NO:"TBD",DIMENSION_M:"26寸",WEIGHT_UNIT:6,WEIGHT_ATT:60,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"中国香港嘉明工程有限公司",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:83,PACKAGE:"P1",AREA:"OR",TAG_NO:"1014HV1002",TAG_DESCR:"Cleaning Valve",TAG_TYPE:"V",DELIVERY_PLAN:"2023-02-28",DELIVERY_FORECAST:"2023-02-28",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-03-10",LIFTING_PLAN_WK:"10-Mar-23",STATUS:"To go",LOCATION:"炉前管廊",SHIPMENT_NO:"TBD",DIMENSION_M:"26寸",WEIGHT_UNIT:6,WEIGHT_ATT:60,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"中国香港嘉明工程有限公司",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:84,PACKAGE:"P1",AREA:"OR",TAG_NO:"1015HV1002",TAG_DESCR:"Cleaning Valve",TAG_TYPE:"V",DELIVERY_PLAN:"2023-02-28",DELIVERY_FORECAST:"2023-02-28",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-03-10",LIFTING_PLAN_WK:"10-Mar-23",STATUS:"To go",LOCATION:"炉前管廊",SHIPMENT_NO:"TBD",DIMENSION_M:"26寸",WEIGHT_UNIT:6,WEIGHT_ATT:60,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"中国香港嘉明工程有限公司",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:85,PACKAGE:"P1",AREA:"OR",TAG_NO:"1016HV1002",TAG_DESCR:"Cleaning Valve",TAG_TYPE:"V",DELIVERY_PLAN:"2023-02-28",DELIVERY_FORECAST:"2023-02-28",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-03-10",LIFTING_PLAN_WK:"10-Mar-23",STATUS:"To go",LOCATION:"炉前管廊",SHIPMENT_NO:"TBD",DIMENSION_M:"26寸",WEIGHT_UNIT:6,WEIGHT_ATT:60,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"中国香港嘉明工程有限公司",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:86,PACKAGE:"P1",AREA:"OR",TAG_NO:"1011HV1002",TAG_DESCR:"Cleaning Valve",TAG_TYPE:"V",DELIVERY_PLAN:"2023-02-28",DELIVERY_FORECAST:"2023-02-28",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-03-10",LIFTING_PLAN_WK:"10-Mar-23",STATUS:"To go",LOCATION:"炉前管廊",SHIPMENT_NO:"TBD",DIMENSION_M:"26寸",WEIGHT_UNIT:6,WEIGHT_ATT:60,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"中国香港嘉明工程有限公司",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:87,PACKAGE:"P1",AREA:"OR",TAG_NO:"1012HV1002",TAG_DESCR:"Cleaning Valve",TAG_TYPE:"V",DELIVERY_PLAN:"2023-02-28",DELIVERY_FORECAST:"2023-02-28",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-03-10",LIFTING_PLAN_WK:"10-Mar-23",STATUS:"To go",LOCATION:"炉前管廊",SHIPMENT_NO:"TBD",DIMENSION_M:"26寸",WEIGHT_UNIT:6,WEIGHT_ATT:60,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"中国香港嘉明工程有限公司",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:88,PACKAGE:"P2",AREA:"GPPE",TAG_NO:"5230R001",TAG_DESCR:"REACTOR",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-12",DELIVERY_FORECAST:"2023-03-12",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-03-13",LIFTING_PLAN_WK:"17-Mar-23",LIFTING_FORECAST:"2023-03-13",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ5.182/8.240×42.899",WEIGHT_UNIT:465,WEIGHT_ATT:465,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"张化机 Zhangjiagang Chemical Machinery",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计 Lifting lugs design done",DRAWING:"已有装配图  Shop assembly dwg IFC",CRANE_MAIN_FILTER:"1,600",CRANE_MAIN:"1600",CRANE_TAIL:"400t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"SLT",MR_NO:"75231-P2-1213-EQ-R-E-SL01",DESCRIPTION:"Reactor",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-10",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-10",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-10-26",LOA_ISSUE_PLAN:"2022-04-05",LOA_ISSUE_FORECAST:"2022-03-05",LOA_ISSUE_ACTUAL:"2022-03-08",PO_ISSUE_PLAN:"2022-05-17",PO_ISSUE_FORECAST:"2022-04-27",PO_ISSUE_ACTUAL:"2022-04-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-07",RAS_ROS:"2023-06-01"},
    // {NO:89,PACKAGE:"P2",AREA:"LDPE",TAG_NO:"5380BN900",TAG_DESCR:"Pellet Elutriator Reception Hopper (Line A)",TAG_TYPE:"V",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-03-15",LIFTING_PLAN_WK:"17-Mar-23",STATUS:"To go",SHIPMENT_NO:"TBD",WEIGHT_UNIT:45,WEIGHT_ATT:45,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"TBD",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:90,PACKAGE:"P2",AREA:"GPPE",TAG_NO:"5240D001",TAG_DESCR:"PURGE BIN",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-10",DELIVERY_FORECAST:"2023-03-10",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-03-15",LIFTING_PLAN_WK:"17-Mar-23",LIFTING_FORECAST:"2023-03-15",STATUS:"To go",LOCATION:"框架",SHIPMENT_NO:"TBD",DIMENSION_M:"φ5.70/4.30×36.759",WEIGHT_UNIT:210,WEIGHT_ATT:210,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"张化机 Zhangjiagang Chemical Machinery",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计 Lifting lugs design done",DRAWING:"有制造图、管口方位图 shop assembly dwg IFC",CRANE_MAIN_FILTER:"1,600",CRANE_MAIN:"1600",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"SLT",MR_NO:"75231-P2-1213-EQ-R-E-SL02",DESCRIPTION:"Purge Bin",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-29",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-10-15",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-10-14",LOA_ISSUE_PLAN:"2021-12-23",LOA_ISSUE_FORECAST:"2021-12-20",LOA_ISSUE_ACTUAL:"2021-12-22",PO_ISSUE_PLAN:"2022-02-03",PO_ISSUE_FORECAST:"2022-02-15",PO_ISSUE_ACTUAL:"2022-02-18",DELIVERY_TO_JOB_SITE_PLAN:"2023-01-06",RAS_ROS:"2023-04-15"},
    // {NO:91,PACKAGE:"P2",AREA:"LDPE",TAG_NO:"5380BN700",TAG_DESCR:"Extruder Pellet Hopper",TAG_TYPE:"V",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-03-15",LIFTING_PLAN_WK:"17-Mar-23",STATUS:"To go",SHIPMENT_NO:"TBD",WEIGHT_UNIT:45,WEIGHT_ATT:45,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"TBD",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:92,PACKAGE:"P2",AREA:"LDPE",TAG_NO:"5380BN910",TAG_DESCR:"Pellet Elutriator Reception Hopper (Line B)",TAG_TYPE:"V",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-03-15",LIFTING_PLAN_WK:"17-Mar-23",STATUS:"To go",SHIPMENT_NO:"TBD",WEIGHT_UNIT:45,WEIGHT_ATT:45,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"TBD",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:93,PACKAGE:"P2",AREA:"PP",TAG_NO:"6130R011",TAG_DESCR:"First Loop Reactor",TAG_TYPE:"V",DELIVERY_PLAN:"2023-02-20",DELIVERY_FORECAST:"2023-02-20",DELIVERY_ACTUAL:"2023-02-28",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-03-15",LIFTING_PLAN_WK:"17-Mar-23",LIFTING_FORECAST:"2023-03-15",STATUS:"Delivered",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"4.2×φ0.72×43.32",WEIGHT_UNIT:204,WEIGHT_ATT:204,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"茂重 Guangdong Maozhong",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计 Lifting lugs design done",DRAWING:"有初版总装配图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P2-1219-EQ-R-E-LL01",DESCRIPTION:"Loop Reactor",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-05-12",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-09-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-09-26",LOA_ISSUE_PLAN:"2021-08-31",LOA_ISSUE_FORECAST:"2021-12-23",LOA_ISSUE_ACTUAL:"2021-12-24",PO_ISSUE_PLAN:"2021-10-12",PO_ISSUE_FORECAST:"2022-02-15",PO_ISSUE_ACTUAL:"2022-02-18",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-06",DELIVERY_TO_JOB_SITE_ACTUAL:"2023-02-09",RAS_ROS:"2023-06-28"},
    // {NO:94,PACKAGE:"P1",AREA:"OR",TAG_NO:"3410T001",TAG_DESCR:"C4 SPLITTER",TAG_TYPE:"V",DELIVERY_PLAN:"2022-12-25",DELIVERY_FORECAST:"2022-12-25",DRESSUP_REQ:"Y",DRESSUP_PROG:0,LIFTING_PLAN:"2023-03-15",LIFTING_PLAN_WK:"17-Mar-23",LIFTING_FORECAST:"2023-03-15",STATUS:"To go",LOCATION:"热区地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ4.1×90.1",WEIGHT_UNIT:355,WEIGHT_ATT:355,WEIGHT_DWG:281.32,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"QDRK",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计 Lifting lugs design done",DRAWING:"已有初版制造图、管口方位图",CRANE_MAIN_FILTER:"1,600",CRANE_MAIN:"1600",CRANE_TAIL:"400t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"SLT",MR_NO:"75231-P1-1279-EQ-R-E-SL13",DESCRIPTION:"C4 SPLITTER 1600 KCS 41 799",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-10-19",LOA_ISSUE_PLAN:"2022-02-28",LOA_ISSUE_FORECAST:"2022-03-29",LOA_ISSUE_ACTUAL:"2022-03-23",PO_ISSUE_PLAN:"2022-04-11",PO_ISSUE_FORECAST:"2022-05-10",PO_ISSUE_ACTUAL:"2022-04-13",DELIVERY_TO_JOB_SITE_PLAN:"2022-11-02",RAS_ROS:"2023-01-30"},
    // {NO:95,PACKAGE:"P1",AREA:"OR",TAG_NO:"2560D040",TAG_DESCR:"C3 REFRIG 4TH STAGE SUCTION DRUM",TAG_TYPE:"V",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-03-15",LIFTING_PLAN_WK:"17-Mar-23",STATUS:"To go",LOCATION:"冷区地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅4.8×21.1",WEIGHT_UNIT:100,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"Himile",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0301",DESCRIPTION:"C3 REFRIG 4TH STAGE SUCTION DRUM",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-07",LOA_ISSUE_PLAN:"2022-08-22",LOA_ISSUE_FORECAST:"2022-08-18",LOA_ISSUE_ACTUAL:"2022-08-17",PO_ISSUE_PLAN:"2022-10-03",PO_ISSUE_FORECAST:"2022-09-29",PO_ISSUE_ACTUAL:"2022-09-20",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-26",RAS_ROS:"2023-05-01"},
    // {NO:96,PACKAGE:"P1",AREA:"OR",TAG_NO:"2560D020",TAG_DESCR:"C3 REFRIG 2ND STAGE SUCTION DRUM",TAG_TYPE:"V",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-03-15",LIFTING_PLAN_WK:"17-Mar-23",STATUS:"To go",LOCATION:"冷区地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅3.7×16.05",WEIGHT_UNIT:66,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"Himile",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0301",DESCRIPTION:"C3 REFRIG 2ND STAGE SUCTION DRUM",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-07",LOA_ISSUE_PLAN:"2022-08-22",LOA_ISSUE_FORECAST:"2022-08-18",LOA_ISSUE_ACTUAL:"2022-08-17",PO_ISSUE_PLAN:"2022-10-03",PO_ISSUE_FORECAST:"2022-09-29",PO_ISSUE_ACTUAL:"2022-09-20",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-26",RAS_ROS:"2023-05-01"},
    // {NO:97,PACKAGE:"P1",AREA:"OR",TAG_NO:"3411T001",TAG_DESCR:"BUTENE-1 SPLITTER",TAG_TYPE:"V",DELIVERY_PLAN:"2022-12-26",DELIVERY_FORECAST:"2022-12-26",DRESSUP_REQ:"Y",DRESSUP_PROG:0,LIFTING_PLAN:"2023-03-17",LIFTING_PLAN_WK:"17-Mar-23",LIFTING_FORECAST:"2023-03-17",STATUS:"To go",LOCATION:"热区地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ3.6×88.1",WEIGHT_UNIT:325,WEIGHT_ATT:325,WEIGHT_DWG:252.06,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"QDRK",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计 Lifting lugs design done",DRAWING:"已有初版制造图、管口方位图",CRANE_MAIN_FILTER:"1,600",CRANE_MAIN:"1600",CRANE_TAIL:"400t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"SLT",MR_NO:"75231-P1-1279-EQ-R-E-SL13",DESCRIPTION:"BUTENE1 SPLITTER 1 1600 KCS 36 788",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-10-19",LOA_ISSUE_PLAN:"2022-02-28",LOA_ISSUE_FORECAST:"2022-03-29",LOA_ISSUE_ACTUAL:"2022-03-23",PO_ISSUE_PLAN:"2022-04-11",PO_ISSUE_FORECAST:"2022-05-10",PO_ISSUE_ACTUAL:"2022-04-13",DELIVERY_TO_JOB_SITE_PLAN:"2022-11-02",RAS_ROS:"2023-01-30"},
    // {NO:98,PACKAGE:"P2",AREA:"PP",TAG_NO:"6130R012",TAG_DESCR:"Second Loop Reactor",TAG_TYPE:"V",DELIVERY_PLAN:"2023-02-21",DELIVERY_FORECAST:"2023-02-21",DELIVERY_ACTUAL:"2023-02-23",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-03-17",LIFTING_PLAN_WK:"17-Mar-23",LIFTING_FORECAST:"2023-03-17",STATUS:"Delivered",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"4.2×φ0.72×43.32",WEIGHT_UNIT:204,WEIGHT_ATT:204,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"茂重 Guangdong Maozhong",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计 Lifting lugs design done",DRAWING:"有初版总装配图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P2-1219-EQ-R-E-LL01",DESCRIPTION:"Loop Reactor",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-05-12",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-09-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-09-26",LOA_ISSUE_PLAN:"2021-08-31",LOA_ISSUE_FORECAST:"2021-12-23",LOA_ISSUE_ACTUAL:"2021-12-24",PO_ISSUE_PLAN:"2021-10-12",PO_ISSUE_FORECAST:"2022-02-15",PO_ISSUE_ACTUAL:"2022-02-18",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-06",DELIVERY_TO_JOB_SITE_ACTUAL:"2022-01-02",RAS_ROS:"2023-06-28"},
    // {NO:99,PACKAGE:"P1",AREA:"OR",TAG_NO:"2526T001",TAG_DESCR:"PROCESS WATER STRIIPER",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-03",DELIVERY_FORECAST:"2023-03-03",DRESSUP_REQ:"Y",DRESSUP_PROG:0,LIFTING_PLAN:"2023-03-18",LIFTING_PLAN_WK:"24-Mar-23",LIFTING_FORECAST:"2023-03-18",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ3.5×22.675",WEIGHT_UNIT:46.2,WEIGHT_ATT:46.2,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"QDRK",DRAWING:"有工程图",CRANE_MAIN_FILTER:"800",CRANE_MAIN:"800",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0101",DESCRIPTION:"PROCESS WATER STRIPPER KCS 35 141",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-24",LOA_ISSUE_PLAN:"2022-02-28",LOA_ISSUE_FORECAST:"2022-03-11",LOA_ISSUE_ACTUAL:"2022-03-23",PO_ISSUE_PLAN:"2022-04-11",PO_ISSUE_FORECAST:"2022-04-22",PO_ISSUE_ACTUAL:"2022-04-13",DELIVERY_TO_JOB_SITE_PLAN:"2022-11-02",RAS_ROS:"2023-02-15"},
    // {NO:100,PACKAGE:"P2",AREA:"GPPE",TAG_NO:"5130R001",TAG_DESCR:"REACTOR",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-13",DELIVERY_FORECAST:"2023-03-13",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-03-19",LIFTING_PLAN_WK:"24-Mar-23",LIFTING_FORECAST:"2023-03-19",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ5.182/8.240×42.899",WEIGHT_UNIT:465,WEIGHT_ATT:465,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"张化机 Zhangjiagang Chemical Machinery",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计 Lifting lugs design done",DRAWING:"已有装配图  Shop assembly dwg IFC",CRANE_MAIN_FILTER:"1,600",CRANE_MAIN:"1600",CRANE_TAIL:"400t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"SLT",MR_NO:"75231-P2-1213-EQ-R-E-SL01",DESCRIPTION:"Reactor",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-10",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-10",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-10-26",LOA_ISSUE_PLAN:"2022-04-05",LOA_ISSUE_FORECAST:"2022-03-05",LOA_ISSUE_ACTUAL:"2022-03-08",PO_ISSUE_PLAN:"2022-05-17",PO_ISSUE_FORECAST:"2022-04-27",PO_ISSUE_ACTUAL:"2022-04-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-07",RAS_ROS:"2023-06-01"},
    // {NO:101,PACKAGE:"P1",AREA:"BD",TAG_NO:"3013T045",TAG_DESCR:"BUTADIENE COLUMN",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-03",DELIVERY_FORECAST:"2023-03-03",DRESSUP_REQ:"Y",LIFTING_PLAN:"2023-03-19",LIFTING_PLAN_WK:"24-Mar-23",LIFTING_FORECAST:"2023-03-19",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ3.5×45.6",WEIGHT_UNIT:79.7,WEIGHT_ATT:79.7,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"QDRK",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计",DRAWING:"有装配图和管口方位图",CRANE_MAIN_FILTER:"800",CRANE_MAIN:"800",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1277-EQ-R-E-0102",DESCRIPTION:"BUTADIENE COLUMN",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-11-18",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-11-18",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-08",LOA_ISSUE_PLAN:"2022-03-05",LOA_ISSUE_FORECAST:"2022-03-21",LOA_ISSUE_ACTUAL:"2022-03-23",PO_ISSUE_PLAN:"2022-04-16",PO_ISSUE_FORECAST:"2022-05-02",PO_ISSUE_ACTUAL:"2022-04-13",DELIVERY_TO_JOB_SITE_PLAN:"2023-12-23",RAS_ROS:"2023-02-15"},
    // {NO:102,PACKAGE:"P2",AREA:"GPPE",TAG_NO:"5140D001",TAG_DESCR:"PURGE BIN",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-11",DELIVERY_FORECAST:"2023-03-11",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-03-21",LIFTING_PLAN_WK:"24-Mar-23",LIFTING_FORECAST:"2023-03-21",STATUS:"To go",LOCATION:"框架",SHIPMENT_NO:"TBD",DIMENSION_M:"φ5.70/4.30×36.759",WEIGHT_UNIT:210,WEIGHT_ATT:210,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"张化机 Zhangjiagang Chemical Machinery",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计 Lifting lugs design done",DRAWING:"有制造图、管口方位图 shop assembly dwg IFC",CRANE_MAIN_FILTER:"1,600",CRANE_MAIN:"1600",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"SLT",MR_NO:"75231-P2-1213-EQ-R-E-SL02",DESCRIPTION:"Purge Bin",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-29",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-10-15",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-10-14",LOA_ISSUE_PLAN:"2021-12-23",LOA_ISSUE_FORECAST:"2021-12-20",LOA_ISSUE_ACTUAL:"2021-12-22",PO_ISSUE_PLAN:"2022-02-03",PO_ISSUE_FORECAST:"2022-02-15",PO_ISSUE_ACTUAL:"2022-02-18",DELIVERY_TO_JOB_SITE_PLAN:"2023-01-06",RAS_ROS:"2023-04-15"},
    // {NO:103,PACKAGE:"P1",AREA:"DIB",TAG_NO:"3222T001",TAG_DESCR:"DEBUTANIZER COLUMN",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-08",DELIVERY_FORECAST:"2023-03-08",DRESSUP_REQ:"Y",LIFTING_PLAN:"2023-03-24",LIFTING_PLAN_WK:"24-Mar-23",LIFTING_FORECAST:"2023-03-24",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ1.4/2.1×31.25",WEIGHT_UNIT:29,WEIGHT_ATT:29,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"QDRK",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计",DRAWING:"有装配图和管口方位图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"Truck Mounted Crane",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1362-EQ-R-E-0102",DESCRIPTION:"DEBUTANIZER COLUMN",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-01-08",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-01-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-28",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-19",LOA_ISSUE_ACTUAL:"2022-05-16",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-30",PO_ISSUE_ACTUAL:"2022-08-01",DELIVERY_TO_JOB_SITE_PLAN:"2023-01-02",RAS_ROS:"2023-03-15"},
    // {NO:104,PACKAGE:"P1",AREA:"DIB",TAG_NO:"3223T002",TAG_DESCR:"CD REACTION COLUMN II",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-08",DELIVERY_FORECAST:"2023-03-08",DRESSUP_REQ:"Y",LIFTING_PLAN:"2023-03-26",LIFTING_PLAN_WK:"31-Mar-23",LIFTING_FORECAST:"2023-03-26",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.1×30.775",WEIGHT_UNIT:38.5,WEIGHT_ATT:38.5,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"QDRK",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计",DRAWING:"有装配图和管口方位图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"Truck Mounted Crane",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1362-EQ-R-E-0101",DESCRIPTION:"CD REACTION COLUMN",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-01-08",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-01-08",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-01-08",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-19",LOA_ISSUE_ACTUAL:"2022-05-16",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-30",PO_ISSUE_ACTUAL:"2022-08-01",DELIVERY_TO_JOB_SITE_PLAN:"2023-01-02",RAS_ROS:"2023-02-28"},
    // {NO:105,PACKAGE:"P1",AREA:"GHU",TAG_NO:"3620T001",TAG_DESCR:"HD1 DEPENTANIZER",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-08",DELIVERY_FORECAST:"2023-03-08",DRESSUP_REQ:"Y",DRESSUP_PROG:0,LIFTING_PLAN:"2023-03-26",LIFTING_PLAN_WK:"31-Mar-23",LIFTING_FORECAST:"2023-03-26",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ3.2/φ3.9x56.1",WEIGHT_UNIT:130,WEIGHT_ATT:130,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"QDRK",DRAWING:"有制造图、管口方位图 shop assembly dwg IFC",CRANE_MAIN_FILTER:"800",CRANE_MAIN:"800",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1278-EQ-R-E-0101",DESCRIPTION:"HD1 HOT SEPARATOR",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-03",LOA_ISSUE_PLAN:"2022-03-05",LOA_ISSUE_FORECAST:"2022-03-21",LOA_ISSUE_ACTUAL:"2022-03-23",PO_ISSUE_PLAN:"2022-04-16",PO_ISSUE_FORECAST:"2022-05-02",PO_ISSUE_ACTUAL:"2022-04-13",DELIVERY_TO_JOB_SITE_PLAN:"2022-11-07",RAS_ROS:"2023-03-15"},
    // {NO:106,PACKAGE:"P1",AREA:"DIB",TAG_NO:"3223T001",TAG_DESCR:"CD REACTION COLUMN I",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-08",DELIVERY_FORECAST:"2023-03-08",DRESSUP_REQ:"Y",DRESSUP_PROG:0,LIFTING_PLAN:"2023-03-28",LIFTING_PLAN_WK:"31-Mar-23",LIFTING_FORECAST:"2023-03-28",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.1×56.35",WEIGHT_UNIT:106.5,WEIGHT_ATT:106.5,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"QDRK",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计 Lifting lugs design done",DRAWING:"有工程图 Engineering dwg issued",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-2",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1362-EQ-R-E-0101",DESCRIPTION:"CD REACTION COLUMN",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-01-08",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-01-08",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-01-08",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-19",LOA_ISSUE_ACTUAL:"2022-05-16",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-30",PO_ISSUE_ACTUAL:"2022-08-01",DELIVERY_TO_JOB_SITE_PLAN:"2023-01-02",RAS_ROS:"2023-02-28"},
    // {NO:107,PACKAGE:"P1",AREA:"GHU",TAG_NO:"3640T001",TAG_DESCR:"HD2 DEPENTANIZER",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-08",DELIVERY_FORECAST:"2023-03-08",DRESSUP_REQ:"Y",DRESSUP_PROG:0,LIFTING_PLAN:"2023-03-29",LIFTING_PLAN_WK:"31-Mar-23",LIFTING_FORECAST:"2023-03-29",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ3.3/φ4.0x54.9",WEIGHT_UNIT:134,WEIGHT_ATT:134,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"QDRK",DRAWING:"有制造图、管口方位图 shop assembly dwg IFC",CRANE_MAIN_FILTER:"800",CRANE_MAIN:"800",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1278-EQ-R-E-0101",DESCRIPTION:"HD1 DEPENTANIZER REFLUX DRUM",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-03",LOA_ISSUE_PLAN:"2022-03-05",LOA_ISSUE_FORECAST:"2022-03-21",LOA_ISSUE_ACTUAL:"2022-03-23",PO_ISSUE_PLAN:"2022-04-16",PO_ISSUE_FORECAST:"2022-05-02",PO_ISSUE_ACTUAL:"2022-04-13",DELIVERY_TO_JOB_SITE_PLAN:"2022-11-07",RAS_ROS:"2023-03-15"},
    // {NO:108,PACKAGE:"P1",AREA:"DIB",TAG_NO:"3230T002",TAG_DESCR:"METHANOL RECOVERY COLUMN",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-18",DELIVERY_FORECAST:"2023-03-18",DRESSUP_REQ:"Y",LIFTING_PLAN:"2023-03-30",LIFTING_PLAN_WK:"31-Mar-23",LIFTING_FORECAST:"2023-03-30",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ1.0×37.7",WEIGHT_UNIT:38,WEIGHT_ATT:38,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"QDRK",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计",DRAWING:"有装配图和管口方位图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"Truck Mounted Crane",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1362-EQ-R-E-0102",DESCRIPTION:"METHANOL RECOVERY COLUMN",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-01-08",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-01-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-28",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-19",LOA_ISSUE_ACTUAL:"2022-05-16",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-30",PO_ISSUE_ACTUAL:"2022-08-01",DELIVERY_TO_JOB_SITE_PLAN:"2023-01-02",RAS_ROS:"2023-03-15"},
    // {NO:109,PACKAGE:"P1",AREA:"DIB",TAG_NO:"3240T001",TAG_DESCR:"MTBE/DIB SPLITTER",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-18",DELIVERY_FORECAST:"2023-03-18",DRESSUP_REQ:"Y",LIFTING_PLAN:"2023-03-31",LIFTING_PLAN_WK:"31-Mar-23",LIFTING_FORECAST:"2023-03-31",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ1.5/2.1×45.975",WEIGHT_UNIT:54,WEIGHT_ATT:54,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"QDRK",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计",DRAWING:"有装配图和管口方位图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"Truck Mounted Crane",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1362-EQ-R-E-0102",DESCRIPTION:"MTBEDIB SPLITTERMTBEDIB",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-01-08",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-01-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-28",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-19",LOA_ISSUE_ACTUAL:"2022-05-16",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-30",PO_ISSUE_ACTUAL:"2022-08-01",DELIVERY_TO_JOB_SITE_PLAN:"2023-01-02",RAS_ROS:"2023-03-15"},
    // {NO:110,PACKAGE:"P1",AREA:"GHU",TAG_NO:"3640T011",TAG_DESCR:"DETOLUENIZER",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-18",DELIVERY_FORECAST:"2023-03-18",DRESSUP_REQ:"Y",LIFTING_PLAN:"2023-04-01",LIFTING_PLAN_WK:"07-Apr-23",LIFTING_FORECAST:"2023-04-01",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ3.1×39.3",WEIGHT_UNIT:67,WEIGHT_ATT:67,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"QDRK",DRAWING:"有工程图",CRANE_MAIN_FILTER:"800",CRANE_MAIN:"800",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1278-EQ-R-E-0101",DESCRIPTION:"RERUN TOWER REFLUX DRUM",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-03",LOA_ISSUE_PLAN:"2022-03-05",LOA_ISSUE_FORECAST:"2022-03-21",LOA_ISSUE_ACTUAL:"2022-03-23",PO_ISSUE_PLAN:"2022-04-16",PO_ISSUE_FORECAST:"2022-05-02",PO_ISSUE_ACTUAL:"2022-04-13",DELIVERY_TO_JOB_SITE_PLAN:"2022-11-07",RAS_ROS:"2023-03-15"},
    // {NO:111,PACKAGE:"P1",AREA:"DIB",TAG_NO:"3250T001",TAG_DESCR:"ISO-OCTENE COLUMN",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-18",DELIVERY_FORECAST:"2023-03-18",DRESSUP_REQ:"Y",LIFTING_PLAN:"2023-04-02",LIFTING_PLAN_WK:"07-Apr-23",LIFTING_FORECAST:"2023-04-02",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ1.6×35.5",WEIGHT_UNIT:32,WEIGHT_ATT:32,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"QDRK",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计",DRAWING:"有装配图和管口方位图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"Truck Mounted Crane",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1362-EQ-R-E-0102",DESCRIPTION:"ISOOCTENE COLUMN",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-01-08",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-01-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-28",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-19",LOA_ISSUE_ACTUAL:"2022-05-16",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-30",PO_ISSUE_ACTUAL:"2022-08-01",DELIVERY_TO_JOB_SITE_PLAN:"2023-01-02",RAS_ROS:"2023-03-15"},
    // {NO:112,PACKAGE:"P1",AREA:"OR",TAG_NO:"2540T051",TAG_DESCR:"DEMETHANIZER",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-12",DELIVERY_FORECAST:"2023-01-12",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-02",LIFTING_PLAN_WK:"07-Apr-23",LIFTING_FORECAST:"2023-04-02",STATUS:"To go",LOCATION:"冷区地面",SHIPMENT_NO:"TBD",DIMENSION_M:"Φ3.4×79.6",WEIGHT_UNIT:480,WEIGHT_ATT:480,WEIGHT_DWG:470,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"TJHY",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计 Lifting lugs design done",DRAWING:"已有初版制造图、管口方位图",CRANE_MAIN_FILTER:"1,600",CRANE_MAIN:"1600",CRANE_TAIL:"400t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"SLT",MR_NO:"75231-P1-1279-EQ-R-E-SL05",DESCRIPTION:"DEMETHANIZER 1600 304L SS 34 5035",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-23",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-23",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-08",LOA_ISSUE_PLAN:"2022-02-18",LOA_ISSUE_FORECAST:"2022-02-03",LOA_ISSUE_ACTUAL:"2022-02-14",PO_ISSUE_PLAN:"2022-04-01",PO_ISSUE_FORECAST:"2022-03-17",PO_ISSUE_ACTUAL:"2022-03-18",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2022-12-10"},
    // {NO:113,PACKAGE:"P1",AREA:"OR",TAG_NO:"2573R001A",TAG_DESCR:"WET AIR OXIDATION REACTOR",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-01",DELIVERY_FORECAST:"2023-05-01",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-04",LIFTING_PLAN_WK:"07-Apr-23",LIFTING_FORECAST:"2023-04-04",STATUS:"To go",LOCATION:"废碱地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅3.0×26.25",WEIGHT_UNIT:75,WEIGHT_ATT:75,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"无锡化工 Wuxi Petrochemical",DRAWING:"有工程图 Engineering dwg issued，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-2",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-SL02",DESCRIPTION:"WET AIR OXIDATION REACTORWAO Alloy600",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-12-23",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-02-18",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-02-07",LOA_ISSUE_PLAN:"2022-04-23",LOA_ISSUE_FORECAST:"2022-07-01",LOA_ISSUE_ACTUAL:"2022-06-28",PO_ISSUE_PLAN:"2022-06-04",PO_ISSUE_FORECAST:"2022-08-12",PO_ISSUE_ACTUAL:"2022-08-12",DELIVERY_TO_JOB_SITE_PLAN:"2022-12-26",RAS_ROS:"2023-04-01"},
    // {NO:114,PACKAGE:"P1",AREA:"OR",TAG_NO:"2573R001B",TAG_DESCR:"WET AIR OXIDATION REACTOR",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-01",DELIVERY_FORECAST:"2023-05-01",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-04",LIFTING_PLAN_WK:"07-Apr-23",LIFTING_FORECAST:"2023-04-04",STATUS:"To go",LOCATION:"废碱地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅3.0×26.25",WEIGHT_UNIT:75,WEIGHT_ATT:75,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"无锡化工 Wuxi Petrochemical",DRAWING:"有工程图 Engineering dwg issued，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-2",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-SL02",DESCRIPTION:"WET AIR OXIDATION REACTORWAO Alloy600",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-12-23",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-02-18",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-02-07",LOA_ISSUE_PLAN:"2022-04-23",LOA_ISSUE_FORECAST:"2022-07-01",LOA_ISSUE_ACTUAL:"2022-06-28",PO_ISSUE_PLAN:"2022-06-04",PO_ISSUE_FORECAST:"2022-08-12",PO_ISSUE_ACTUAL:"2022-08-12",DELIVERY_TO_JOB_SITE_PLAN:"2022-12-26",RAS_ROS:"2023-04-01"},
    // {NO:115,PACKAGE:"P1",AREA:"GHU",TAG_NO:"3620T011",TAG_DESCR:"RERUN TOWER",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-18",DELIVERY_FORECAST:"2023-03-18",DRESSUP_REQ:"Y",DRESSUP_PROG:0,LIFTING_PLAN:"2023-04-04",LIFTING_PLAN_WK:"07-Apr-23",LIFTING_FORECAST:"2023-04-04",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ4.2x40.06",WEIGHT_UNIT:105,WEIGHT_ATT:105,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"QDRK",DRAWING:"有制造图、管口方位图 shop assembly dwg IFC",CRANE_MAIN_FILTER:"800",CRANE_MAIN:"800",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1278-EQ-R-E-0101",DESCRIPTION:"HD1 COLD SEPARATOR",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-03",LOA_ISSUE_PLAN:"2022-03-05",LOA_ISSUE_FORECAST:"2022-03-21",LOA_ISSUE_ACTUAL:"2022-03-23",PO_ISSUE_PLAN:"2022-04-16",PO_ISSUE_FORECAST:"2022-05-02",PO_ISSUE_ACTUAL:"2022-04-13",DELIVERY_TO_JOB_SITE_PLAN:"2022-11-07",RAS_ROS:"2023-03-15"},
    // {NO:116,PACKAGE:"P1",AREA:"OR",TAG_NO:"2526E010A",TAG_DESCR:"PROCESS WATER STRIPPER REBOILER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-06",LIFTING_PLAN_WK:"07-Apr-23",LIFTING_FORECAST:"2023-04-06",STATUS:"To go",LOCATION:"急冷地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.3×11.639",WEIGHT_UNIT:95,WEIGHT_ATT:95,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"湖北长江 Hubei Changjiang",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"有工程图 Engineering dwg issued，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0410",DESCRIPTION:"PROCESS WATER STRIPPER REBOILER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-19",RAS_ROS:"2023-04-15"},
    // {NO:117,PACKAGE:"P1",AREA:"OR",TAG_NO:"2526E010B",TAG_DESCR:"PROCESS WATER STRIPPER REBOILER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-06",LIFTING_PLAN_WK:"07-Apr-23",LIFTING_FORECAST:"2023-04-06",STATUS:"To go",LOCATION:"急冷地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.3×11.639",WEIGHT_UNIT:95,WEIGHT_ATT:95,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"湖北长江 Hubei Changjiang",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"有工程图 Engineering dwg issued，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0410",DESCRIPTION:"PROCESS WATER STRIPPER REBOILER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-19",RAS_ROS:"2023-04-15"},
    // {NO:118,PACKAGE:"P1",AREA:"OR",TAG_NO:"2535E005",TAG_DESCR:"GAS DRYER FEED CHILLER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-06",LIFTING_PLAN_WK:"07-Apr-23",LIFTING_FORECAST:"2023-04-06",STATUS:"To go",LOCATION:"压缩 地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅3.400X1.4000",WEIGHT_UNIT:58,WEIGHT_ATT:58,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"北京广厦",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0405",DESCRIPTION:"GAS DRYER FEED CHILLERx000D",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-03-08",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-03-08",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-01-29",LOA_ISSUE_PLAN:"2022-06-29",LOA_ISSUE_FORECAST:"2022-06-23",LOA_ISSUE_ACTUAL:"2022-06-21",PO_ISSUE_PLAN:"2022-08-10",PO_ISSUE_FORECAST:"2022-08-04",PO_ISSUE_ACTUAL:"2022-07-27",DELIVERY_TO_JOB_SITE_PLAN:"2023-02-27",RAS_ROS:"2023-04-05"},
    // {NO:119,PACKAGE:"P1",AREA:"OR",TAG_NO:"2526E060A",TAG_DESCR:"DSG STEAM REBOILERS",TAG_TYPE:"V",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-07",LIFTING_PLAN_WK:"07-Apr-23",LIFTING_FORECAST:"2023-04-07",STATUS:"To go",LOCATION:"急冷构2F",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.9×11.365",WEIGHT_UNIT:156,WEIGHT_ATT:156,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"湖北长江 Hubei Changjiang",DRAWING:"有工程图 Engineering dwg issued，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-2",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0410",DESCRIPTION:"DSG STEAM REBOILERS",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-19",RAS_ROS:"2023-04-15"},
    // {NO:120,PACKAGE:"P1",AREA:"OR",TAG_NO:"2526E060B",TAG_DESCR:"DSG STEAM REBOILERS",TAG_TYPE:"V",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-07",LIFTING_PLAN_WK:"07-Apr-23",LIFTING_FORECAST:"2023-04-07",STATUS:"To go",LOCATION:"急冷构2F",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.9×11.365",WEIGHT_UNIT:156,WEIGHT_ATT:156,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"湖北长江 Hubei Changjiang",DRAWING:"有工程图 Engineering dwg issued，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-2",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0410",DESCRIPTION:"DSG STEAM REBOILERS",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-19",RAS_ROS:"2023-04-15"},
    // {NO:121,PACKAGE:"P1",AREA:"OR",TAG_NO:"2526E060C",TAG_DESCR:"DSG STEAM REBOILERS",TAG_TYPE:"V",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-07",LIFTING_PLAN_WK:"07-Apr-23",LIFTING_FORECAST:"2023-04-07",STATUS:"To go",LOCATION:"急冷构2F",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.9×11.365",WEIGHT_UNIT:156,WEIGHT_ATT:156,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"湖北长江 Hubei Changjiang",DRAWING:"有工程图 Engineering dwg issued，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-2",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0410",DESCRIPTION:"DSG STEAM REBOILERS",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-19",RAS_ROS:"2023-04-15"},
    // {NO:122,PACKAGE:"P1",AREA:"OR",TAG_NO:"2526E060D",TAG_DESCR:"DSG STEAM REBOILERS",TAG_TYPE:"V",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-08",LIFTING_PLAN_WK:"14-Apr-23",LIFTING_FORECAST:"2023-04-08",STATUS:"To go",LOCATION:"急冷构2F",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.9×11.365",WEIGHT_UNIT:156,WEIGHT_ATT:156,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"湖北长江 Hubei Changjiang",DRAWING:"有工程图 Engineering dwg issued，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-2",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0410",DESCRIPTION:"DSG STEAM REBOILERS",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-19",RAS_ROS:"2023-04-15"},
    // {NO:123,PACKAGE:"P1",AREA:"OR",TAG_NO:"2526E060E",TAG_DESCR:"DSG STEAM REBOILERS",TAG_TYPE:"V",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-08",LIFTING_PLAN_WK:"14-Apr-23",LIFTING_FORECAST:"2023-04-08",STATUS:"To go",LOCATION:"急冷构2F",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.9×11.365",WEIGHT_UNIT:156,WEIGHT_ATT:156,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"湖北长江 Hubei Changjiang",DRAWING:"有工程图 Engineering dwg issued，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-2",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0410",DESCRIPTION:"DSG STEAM REBOILERS",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-19",RAS_ROS:"2023-04-15"},
    // {NO:124,PACKAGE:"P1",AREA:"OR",TAG_NO:"2526E060F",TAG_DESCR:"DSG STEAM REBOILERS",TAG_TYPE:"V",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-08",LIFTING_PLAN_WK:"14-Apr-23",LIFTING_FORECAST:"2023-04-08",STATUS:"To go",LOCATION:"急冷构2F",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.9×11.365",WEIGHT_UNIT:156,WEIGHT_ATT:156,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"湖北长江 Hubei Changjiang",DRAWING:"有工程图 Engineering dwg issued，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-2",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0410",DESCRIPTION:"DSG STEAM REBOILERS",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-19",RAS_ROS:"2023-04-15"},
    // {NO:125,PACKAGE:"P1",AREA:"OR",TAG_NO:"1020HV1001",TAG_DESCR:"Pyrolysis gas valve",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-29",DELIVERY_FORECAST:"2023-03-29",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-08",LIFTING_PLAN_WK:"14-Apr-23",STATUS:"To go",LOCATION:"炉前管廊",SHIPMENT_NO:"TBD",DIMENSION_M:"40寸",WEIGHT_UNIT:12,WEIGHT_ATT:120,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"中国香港嘉明工程有限公司",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:126,PACKAGE:"P1",AREA:"OR",TAG_NO:"1013HV1001",TAG_DESCR:"Pyrolysis gas valve",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-29",DELIVERY_FORECAST:"2023-03-29",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-08",LIFTING_PLAN_WK:"14-Apr-23",STATUS:"To go",LOCATION:"炉前管廊",SHIPMENT_NO:"TBD",DIMENSION_M:"40寸",WEIGHT_UNIT:12,WEIGHT_ATT:120,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"中国香港嘉明工程有限公司",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:127,PACKAGE:"P1",AREA:"OR",TAG_NO:"1012HV1001",TAG_DESCR:"Pyrolysis gas valve",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-29",DELIVERY_FORECAST:"2023-03-29",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-08",LIFTING_PLAN_WK:"14-Apr-23",STATUS:"To go",LOCATION:"炉前管廊",SHIPMENT_NO:"TBD",DIMENSION_M:"40寸",WEIGHT_UNIT:12,WEIGHT_ATT:120,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"中国香港嘉明工程有限公司",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:128,PACKAGE:"P1",AREA:"OR",TAG_NO:"1011HV1001",TAG_DESCR:"Pyrolysis gas valve",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-29",DELIVERY_FORECAST:"2023-03-29",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-08",LIFTING_PLAN_WK:"14-Apr-23",STATUS:"To go",LOCATION:"炉前管廊",SHIPMENT_NO:"TBD",DIMENSION_M:"40寸",WEIGHT_UNIT:12,WEIGHT_ATT:120,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"中国香港嘉明工程有限公司",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:129,PACKAGE:"P1",AREA:"OR",TAG_NO:"1017HV1001",TAG_DESCR:"Pyrolysis gas valve",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-29",DELIVERY_FORECAST:"2023-03-29",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-08",LIFTING_PLAN_WK:"14-Apr-23",STATUS:"To go",LOCATION:"炉前管廊",SHIPMENT_NO:"TBD",DIMENSION_M:"40寸",WEIGHT_UNIT:12,WEIGHT_ATT:120,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"中国香港嘉明工程有限公司",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:130,PACKAGE:"P1",AREA:"OR",TAG_NO:"1016HV1001",TAG_DESCR:"Pyrolysis gas valve",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-29",DELIVERY_FORECAST:"2023-03-29",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-08",LIFTING_PLAN_WK:"14-Apr-23",STATUS:"To go",LOCATION:"炉前管廊",SHIPMENT_NO:"TBD",DIMENSION_M:"40寸",WEIGHT_UNIT:12,WEIGHT_ATT:120,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"中国香港嘉明工程有限公司",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:131,PACKAGE:"P1",AREA:"OR",TAG_NO:"1015HV1001",TAG_DESCR:"Pyrolysis gas valve",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-29",DELIVERY_FORECAST:"2023-03-29",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-08",LIFTING_PLAN_WK:"14-Apr-23",STATUS:"To go",LOCATION:"炉前管廊",SHIPMENT_NO:"TBD",DIMENSION_M:"40寸",WEIGHT_UNIT:12,WEIGHT_ATT:120,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"中国香港嘉明工程有限公司",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:132,PACKAGE:"P1",AREA:"OR",TAG_NO:"1014HV1001",TAG_DESCR:"Pyrolysis gas valve",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-29",DELIVERY_FORECAST:"2023-03-29",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-08",LIFTING_PLAN_WK:"14-Apr-23",STATUS:"To go",LOCATION:"炉前管廊",SHIPMENT_NO:"TBD",DIMENSION_M:"40寸",WEIGHT_UNIT:12,WEIGHT_ATT:120,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"中国香港嘉明工程有限公司",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:133,PACKAGE:"P1",AREA:"OR",TAG_NO:"1019HV1001",TAG_DESCR:"Pyrolysis gas valve",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-29",DELIVERY_FORECAST:"2023-03-29",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-08",LIFTING_PLAN_WK:"14-Apr-23",STATUS:"To go",LOCATION:"炉前管廊",SHIPMENT_NO:"TBD",DIMENSION_M:"40寸",WEIGHT_UNIT:12,WEIGHT_ATT:120,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"中国香港嘉明工程有限公司",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:134,PACKAGE:"P1",AREA:"OR",TAG_NO:"1018HV1001",TAG_DESCR:"Pyrolysis gas valve",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-29",DELIVERY_FORECAST:"2023-03-29",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-08",LIFTING_PLAN_WK:"14-Apr-23",STATUS:"To go",LOCATION:"炉前管廊",SHIPMENT_NO:"TBD",DIMENSION_M:"40寸",WEIGHT_UNIT:12,WEIGHT_ATT:120,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"中国香港嘉明工程有限公司",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:135,PACKAGE:"P2",AREA:"GPPE",TAG_NO:"5130E001",TAG_DESCR:"Recycle gas cooler",TAG_TYPE:"H",DELIVERY_PLAN:"2023-03-20",DELIVERY_FORECAST:"2023-03-20",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-08",LIFTING_PLAN_WK:"14-Apr-23",LIFTING_FORECAST:"2023-04-08",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.4x23.408",WEIGHT_UNIT:137,WEIGHT_ATT:137,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"茂重 Guangdong Maozhong",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"有工程图 Engineering dwg issued",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P2-1213-EQ-R-E-SL03",DESCRIPTION:"Cycle Gas Cooler",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-10",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-10",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-10-26",LOA_ISSUE_PLAN:"2022-03-28",LOA_ISSUE_FORECAST:"2022-04-17",LOA_ISSUE_ACTUAL:"2022-04-18",PO_ISSUE_PLAN:"2022-05-09",PO_ISSUE_FORECAST:"2022-05-30",PO_ISSUE_ACTUAL:"2022-06-08",DELIVERY_TO_JOB_SITE_PLAN:"2023-02-28",RAS_ROS:"2023-06-01"},
    // {NO:136,PACKAGE:"P1",AREA:"GHU",TAG_NO:"3610R001",TAG_DESCR:"HD1 REACTORS",TAG_TYPE:"V",DELIVERY_PLAN:"2023-04-08",DELIVERY_FORECAST:"2023-04-08",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-09",LIFTING_PLAN_WK:"14-Apr-23",LIFTING_FORECAST:"2023-04-09",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ3.3×31.845",WEIGHT_UNIT:165,WEIGHT_ATT:165,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"张化机 Zhangjiagang Chemical Machinery",DRAWING:"有工程图 Engineering dwg issued",CRANE_MAIN_FILTER:"800",CRANE_MAIN:"800",CRANE_TAIL:"280t Crane-2",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1278-EQ-R-E-0201",DESCRIPTION:"HD1 REACTOR",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-03-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-03-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-02-07",LOA_ISSUE_PLAN:"2022-07-29",LOA_ISSUE_FORECAST:"2022-07-31",LOA_ISSUE_ACTUAL:"2022-07-31",PO_ISSUE_PLAN:"2022-09-09",PO_ISSUE_FORECAST:"2022-09-11",PO_ISSUE_ACTUAL:"2022-08-26",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-02",RAS_ROS:"2023-04-21"},
    // {NO:137,PACKAGE:"P2",AREA:"GPPE",TAG_NO:"5230E001",TAG_DESCR:"Recycle gas cooler",TAG_TYPE:"H",DELIVERY_PLAN:"2023-03-20",DELIVERY_FORECAST:"2023-03-20",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-09",LIFTING_PLAN_WK:"14-Apr-23",LIFTING_FORECAST:"2023-04-09",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.4x23.408",WEIGHT_UNIT:137,WEIGHT_ATT:137,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"茂重 Guangdong Maozhong",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"有工程图 Engineering dwg issued",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P2-1213-EQ-R-E-SL03",DESCRIPTION:"Cycle Gas Cooler",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-10",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-10",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-10-26",LOA_ISSUE_PLAN:"2022-03-28",LOA_ISSUE_FORECAST:"2022-04-17",LOA_ISSUE_ACTUAL:"2022-04-18",PO_ISSUE_PLAN:"2022-05-09",PO_ISSUE_FORECAST:"2022-05-30",PO_ISSUE_ACTUAL:"2022-06-08",DELIVERY_TO_JOB_SITE_PLAN:"2023-02-28",RAS_ROS:"2023-06-01"},
    // {NO:138,PACKAGE:"P1",AREA:"GHU",TAG_NO:"3630R001",TAG_DESCR:"HD2 REACTOR",TAG_TYPE:"V",DELIVERY_PLAN:"2023-04-08",DELIVERY_FORECAST:"2023-04-08",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-10",LIFTING_PLAN_WK:"14-Apr-23",LIFTING_FORECAST:"2023-04-10",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ3.6×26.89",WEIGHT_UNIT:195,WEIGHT_ATT:195,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"张化机 Zhangjiagang Chemical Machinery",DRAWING:"有工程图 Engineering dwg issued",CRANE_MAIN_FILTER:"800",CRANE_MAIN:"800",CRANE_TAIL:"280t Crane-2",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1278-EQ-R-E-0201",DESCRIPTION:"HD2 REACTOR",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-03-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-03-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-02-07",LOA_ISSUE_PLAN:"2022-07-29",LOA_ISSUE_FORECAST:"2022-07-31",LOA_ISSUE_ACTUAL:"2022-07-31",PO_ISSUE_PLAN:"2022-09-09",PO_ISSUE_FORECAST:"2022-09-11",PO_ISSUE_ACTUAL:"2022-08-26",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-02",RAS_ROS:"2023-04-21"},
    // {NO:139,PACKAGE:"P1",AREA:"OR",TAG_NO:"2536E005A",TAG_DESCR:"HP DEPROP FEED / OVERHEAD EXCHANGER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-12",LIFTING_PLAN_WK:"14-Apr-23",LIFTING_FORECAST:"2023-04-12",STATUS:"To go",LOCATION:"3B",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.4×15.681",WEIGHT_UNIT:114,WEIGHT_ATT:114,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"无锡鼎邦 Wuxi Dingbang",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"有工程图 Engineering dwg issued，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0408",DESCRIPTION:"HP DEPROP FEED OVERHEAD EXCHANGER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-05",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-16",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-05",RAS_ROS:"2023-04-15"},
    // {NO:140,PACKAGE:"P1",AREA:"OR",TAG_NO:"2536E005B",TAG_DESCR:"HP DEPROP FEED / OVERHEAD EXCHANGER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-12",LIFTING_PLAN_WK:"14-Apr-23",LIFTING_FORECAST:"2023-04-12",STATUS:"To go",LOCATION:"3B",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.4×15.681",WEIGHT_UNIT:114,WEIGHT_ATT:114,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"无锡鼎邦 Wuxi Dingbang",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"有工程图 Engineering dwg issued，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0408",DESCRIPTION:"HP DEPROP FEED OVERHEAD EXCHANGER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-05",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-16",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-05",RAS_ROS:"2023-04-15"},
    // {NO:141,PACKAGE:"P1",AREA:"Boiler",TAG_NO:"8110B001A",TAG_DESCR:"HRA",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-13",LIFTING_PLAN_WK:"14-Apr-23",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"15.5x8.35x5.97",WEIGHT_UNIT:190,WEIGHT_ATT:190,LIFTING_CATEGORY:"Over 80t",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-2",TRANSPORT_COMPANY:"Others",REMARKS:"Identified Tag No"},
    // {NO:142,PACKAGE:"P1",AREA:"Boiler",TAG_NO:"8110B001B",TAG_DESCR:"HRA",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-13",LIFTING_PLAN_WK:"14-Apr-23",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"15.5x8.35x5.97",WEIGHT_UNIT:190,WEIGHT_ATT:190,LIFTING_CATEGORY:"Over 80t",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-2",TRANSPORT_COMPANY:"Others",REMARKS:"Identified Tag No"},
    // {NO:143,PACKAGE:"P1",AREA:"Boiler",TAG_NO:"8110B001C",TAG_DESCR:"HRA",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-13",LIFTING_PLAN_WK:"14-Apr-23",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"15.5x8.35x5.97",WEIGHT_UNIT:190,WEIGHT_ATT:190,LIFTING_CATEGORY:"Over 80t",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-2",TRANSPORT_COMPANY:"Others",REMARKS:"Identified Tag No"},
    // {NO:144,PACKAGE:"P1",AREA:"OR",TAG_NO:"2537E002",TAG_DESCR:"C2 HYDROGENATION FEED COOLER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-15",LIFTING_PLAN_WK:"21-Apr-23",LIFTING_FORECAST:"2023-04-15",STATUS:"To go",LOCATION:"地面 3B",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.1×12.143",WEIGHT_UNIT:90,WEIGHT_ATT:90,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"湖北长江 Hubei Changjiang",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"有工程图 Engineering dwg issued",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0410",DESCRIPTION:"C2 HYDROGENATION FEED COOLER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-19",RAS_ROS:"2023-04-15"},
    // {NO:145,PACKAGE:"P1",AREA:"OR",TAG_NO:"2544T001",TAG_DESCR:"DEETHANIZER",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-14",DELIVERY_FORECAST:"2023-01-14",DRESSUP_REQ:"Y",DRESSUP_PROG:0,LIFTING_PLAN:"2023-04-15",LIFTING_PLAN_WK:"21-Apr-23",LIFTING_FORECAST:"2023-04-15",STATUS:"To go",LOCATION:"冷区地面",SHIPMENT_NO:"TBD",DIMENSION_M:"Φ3.8/3.1×53.5",WEIGHT_UNIT:125,WEIGHT_ATT:125,WEIGHT_DWG:125,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"TJHY",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计 Lifting lugs design done",DRAWING:"有制造图、管口方位图 shop assembly dwg IFC",CRANE_MAIN_FILTER:"800",CRANE_MAIN:"800",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"SLT",MR_NO:"75231-P1-1279-EQ-R-E-SL05",DESCRIPTION:"DEETHANIZER 304L SS 38 43",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-23",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-23",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-08",LOA_ISSUE_PLAN:"2022-02-18",LOA_ISSUE_FORECAST:"2022-02-03",LOA_ISSUE_ACTUAL:"2022-02-14",PO_ISSUE_PLAN:"2022-04-01",PO_ISSUE_FORECAST:"2022-03-17",PO_ISSUE_ACTUAL:"2022-03-18",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-02-15"},
    // {NO:146,PACKAGE:"P1",AREA:"OR",TAG_NO:"2555E015",TAG_DESCR:"DEBUTANIZER CONDENSER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-15",LIFTING_PLAN_WK:"21-Apr-23",LIFTING_FORECAST:"2023-04-15",STATUS:"To go",LOCATION:"5E 地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.4x12.06",WEIGHT_UNIT:99,WEIGHT_ATT:99,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"湖北长江 Hubei Changjiang",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"无图 No engineering dwg，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0410",DESCRIPTION:"DEBUTANIZER CONDENSER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-19",RAS_ROS:"2023-04-15"},
    // {NO:147,PACKAGE:"P1",AREA:"OR",TAG_NO:"2554E015D",TAG_DESCR:"C3 RECTIFIER CONDENSERS",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-16",LIFTING_PLAN_WK:"21-Apr-23",LIFTING_FORECAST:"2023-04-16",STATUS:"To go",LOCATION:"热区地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.2x17",WEIGHT_UNIT:88,WEIGHT_ATT:88,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"无锡鼎邦 Wuxi Dingbang",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"无图 No engineering dwg，无最新到货时间",CRANE_MAIN_FILTER:"800",CRANE_MAIN:"800",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans"},
    // {NO:148,PACKAGE:"P1",AREA:"OR",TAG_NO:"2537E005",TAG_DESCR:"C2 HYDROG FEED HEATER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-16",LIFTING_PLAN_WK:"21-Apr-23",LIFTING_FORECAST:"2023-04-16",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅1.9×15.523",WEIGHT_UNIT:92,WEIGHT_ATT:92,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"湖北长江 Hubei Changjiang",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"有工程图 Engineering dwg issued",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0410",DESCRIPTION:"C2 HYDROG FEED HEATER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-19",RAS_ROS:"2023-04-15"},
    // {NO:149,PACKAGE:"P2",AREA:"PP",TAG_NO:"6180BN001",TAG_DESCR:"Pellet Purge Silo",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-05",DELIVERY_FORECAST:"2023-01-05",DELIVERY_ACTUAL:"2023-01-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-16",LIFTING_PLAN_WK:"21-Apr-23",LIFTING_FORECAST:"2023-04-16",STATUS:"Delivered",LOCATION:"框架 EL+11.5",SHIPMENT_NO:"TBD",DIMENSION_M:"φ7.5×35.25",WEIGHT_UNIT:38,WEIGHT_ATT:76,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DESIGN_OF_LIFTING_LUG:"厂家自设",DRAWING:"有工程图",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",TRANSPORT_COMPANY:"Others"},
    // {NO:150,PACKAGE:"P2",AREA:"PP",TAG_NO:"6180BN002",TAG_DESCR:"Pellet Purge Silo",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-05",DELIVERY_FORECAST:"2023-01-05",DELIVERY_ACTUAL:"2023-01-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-16",LIFTING_PLAN_WK:"21-Apr-23",LIFTING_FORECAST:"2023-04-16",STATUS:"Delivered",LOCATION:"框架 EL+11.5",SHIPMENT_NO:"TBD",DIMENSION_M:"φ7.5×35.25",WEIGHT_UNIT:38,WEIGHT_ATT:76,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DESIGN_OF_LIFTING_LUG:"厂家自设",DRAWING:"有工程图",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",TRANSPORT_COMPANY:"Others"},
    // {NO:151,PACKAGE:"P2",AREA:"PP",TAG_NO:"6280BN003",TAG_DESCR:"Pellet Blender",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-09",DELIVERY_FORECAST:"2023-01-09",DELIVERY_ACTUAL:"2023-01-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-17",LIFTING_PLAN_WK:"21-Apr-23",LIFTING_FORECAST:"2023-04-17",STATUS:"Delivered",LOCATION:"框架 EL+11.5",SHIPMENT_NO:"TBD",DIMENSION_M:"φ5.0×21.3",WEIGHT_UNIT:21,WEIGHT_ATT:84,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DESIGN_OF_LIFTING_LUG:"厂家自设",DRAWING:"有工程图",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1219-EQ-R-E-SL07",DESCRIPTION:"Pellet Blender",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-25",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",DELIVERY_TO_JOB_SITE_ACTUAL:"2023-02-03",RAS_ROS:"2023-07-30"},
    // {NO:152,PACKAGE:"P2",AREA:"PP",TAG_NO:"6280BN004",TAG_DESCR:"Pellet Blender",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-09",DELIVERY_FORECAST:"2023-01-09",DELIVERY_ACTUAL:"2023-01-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-17",LIFTING_PLAN_WK:"21-Apr-23",LIFTING_FORECAST:"2023-04-17",STATUS:"Delivered",LOCATION:"框架 EL+11.5",SHIPMENT_NO:"TBD",DIMENSION_M:"φ5.0×21.3",WEIGHT_UNIT:21,WEIGHT_ATT:84,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DESIGN_OF_LIFTING_LUG:"厂家自设",DRAWING:"有工程图",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1219-EQ-R-E-SL07",DESCRIPTION:"Pellet Blender",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-25",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",DELIVERY_TO_JOB_SITE_ACTUAL:"2023-02-03",RAS_ROS:"2023-07-30"},
    // {NO:153,PACKAGE:"P2",AREA:"PP",TAG_NO:"6280BN005",TAG_DESCR:"Pellet Blender",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-09",DELIVERY_FORECAST:"2023-01-09",DELIVERY_ACTUAL:"2023-01-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-17",LIFTING_PLAN_WK:"21-Apr-23",LIFTING_FORECAST:"2023-04-17",STATUS:"Delivered",LOCATION:"框架 EL+11.5",SHIPMENT_NO:"TBD",DIMENSION_M:"φ5.0×21.3",WEIGHT_UNIT:21,WEIGHT_ATT:84,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DESIGN_OF_LIFTING_LUG:"厂家自设",DRAWING:"有工程图",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1219-EQ-R-E-SL07",DESCRIPTION:"Pellet Blender",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-25",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",DELIVERY_TO_JOB_SITE_ACTUAL:"2023-02-03",RAS_ROS:"2023-07-30"},
    // {NO:154,PACKAGE:"P2",AREA:"PP",TAG_NO:"6280BN006",TAG_DESCR:"Pellet Blender",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-09",DELIVERY_FORECAST:"2023-01-09",DELIVERY_ACTUAL:"2023-01-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-17",LIFTING_PLAN_WK:"21-Apr-23",LIFTING_FORECAST:"2023-04-17",STATUS:"Delivered",LOCATION:"框架 EL+11.5",SHIPMENT_NO:"TBD",DIMENSION_M:"φ5.0×21.3",WEIGHT_UNIT:21,WEIGHT_ATT:84,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DESIGN_OF_LIFTING_LUG:"厂家自设",DRAWING:"有工程图",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1219-EQ-R-E-SL07",DESCRIPTION:"Pellet Blender",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-25",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",DELIVERY_TO_JOB_SITE_ACTUAL:"2023-02-03",RAS_ROS:"2023-07-30"},
    // {NO:155,PACKAGE:"P1",AREA:"OR",TAG_NO:"3410E010",TAG_DESCR:"C4 SPLITTER REBOILER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-17",LIFTING_PLAN_WK:"21-Apr-23",LIFTING_FORECAST:"2023-04-17",STATUS:"To go",LOCATION:"热区地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.3x12.127",WEIGHT_UNIT:93.5,WEIGHT_ATT:93.5,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"湖北长江 Hubei Changjiang",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"有工程图 Engineering dwg issued",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0410",DESCRIPTION:"C4 SPLITTER REBOILER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-19",RAS_ROS:"2023-04-15"},
    // {NO:156,PACKAGE:"P1",AREA:"OR",TAG_NO:"2537E010",TAG_DESCR:"C2 HYDROG 1ST STAGE INTERCOOLER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-17",LIFTING_PLAN_WK:"21-Apr-23",LIFTING_FORECAST:"2023-04-17",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.1×10.803",WEIGHT_UNIT:97,WEIGHT_ATT:97,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"湖北长江 Hubei Changjiang",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"有工程图 Engineering dwg issued",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0410",DESCRIPTION:"C2 HYDROG 1ST STAGE INTERCOOLER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-19",RAS_ROS:"2023-04-15"},
    // {NO:157,PACKAGE:"P1",AREA:"OR",TAG_NO:"2537E020A",TAG_DESCR:"C2 HYDROG AFTERCOOLER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-17",LIFTING_PLAN_WK:"21-Apr-23",LIFTING_FORECAST:"2023-04-17",STATUS:"To go",LOCATION:"压缩地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅1.9×10.155",WEIGHT_UNIT:78,WEIGHT_ATT:156,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"湖北长江",DRAWING:"有工程图",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0410",DESCRIPTION:"C2 HYDROG AFTERCOOLER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-19",RAS_ROS:"2023-04-15"},
    // {NO:158,PACKAGE:"P1",AREA:"OR",TAG_NO:"2537E020B",TAG_DESCR:"C2 HYDROG AFTERCOOLER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-17",LIFTING_PLAN_WK:"21-Apr-23",LIFTING_FORECAST:"2023-04-17",STATUS:"To go",LOCATION:"压缩地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅1.9×10.155",WEIGHT_UNIT:78,WEIGHT_ATT:156,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"湖北长江",DRAWING:"有工程图",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0410",DESCRIPTION:"C2 HYDROG AFTERCOOLER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-19",RAS_ROS:"2023-04-15"},
    // {NO:159,PACKAGE:"P1",AREA:"OR",TAG_NO:"2554E015E",TAG_DESCR:"C3 RECTIFIER CONDENSERS",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-17",LIFTING_PLAN_WK:"21-Apr-23",LIFTING_FORECAST:"2023-04-17",STATUS:"To go",LOCATION:"热区地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.2x18",WEIGHT_UNIT:88,WEIGHT_ATT:88,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"无锡鼎邦 Wuxi Dingbang",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"无图 No engineering dwg，无最新到货时间",CRANE_MAIN_FILTER:"800",CRANE_MAIN:"800",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans"},
    // {NO:160,PACKAGE:"P1",AREA:"OR",TAG_NO:"2554E015F",TAG_DESCR:"C3 RECTIFIER CONDENSERS",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-17",LIFTING_PLAN_WK:"21-Apr-23",LIFTING_FORECAST:"2023-04-17",STATUS:"To go",LOCATION:"热区地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.2x19",WEIGHT_UNIT:88,WEIGHT_ATT:88,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"无锡鼎邦 Wuxi Dingbang",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"无图 No engineering dwg，无最新到货时间",CRANE_MAIN_FILTER:"800",CRANE_MAIN:"800",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans"},
    // {NO:161,PACKAGE:"P1",AREA:"OR",TAG_NO:"2554E012A",TAG_DESCR:"C3 RECTIFIER REBOILER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-18",LIFTING_PLAN_WK:"21-Apr-23",LIFTING_FORECAST:"2023-04-18",STATUS:"To go",LOCATION:"热区地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.3×11.907",WEIGHT_UNIT:98,WEIGHT_ATT:98,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"无锡鼎邦 Wuxi Dingbang",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"有工程图 Engineering dwg issued，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0408",DESCRIPTION:"C3 RECTIFIER REBOILER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-19",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-30",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-19",RAS_ROS:"2023-04-15"},
    // {NO:162,PACKAGE:"P1",AREA:"OR",TAG_NO:"2554E012B",TAG_DESCR:"C3 RECTIFIER REBOILER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-18",LIFTING_PLAN_WK:"21-Apr-23",LIFTING_FORECAST:"2023-04-18",STATUS:"To go",LOCATION:"热区地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.3×11.907",WEIGHT_UNIT:98,WEIGHT_ATT:98,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"无锡鼎邦 Wuxi Dingbang",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"有工程图 Engineering dwg issued，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0408",DESCRIPTION:"C3 RECTIFIER REBOILER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-19",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-30",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-19",RAS_ROS:"2023-04-15"},
    // {NO:163,PACKAGE:"P1",AREA:"OR",TAG_NO:"2537E015",TAG_DESCR:"C2 HYDROG 2ND STAGE INTERCOOLER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-18",LIFTING_PLAN_WK:"21-Apr-23",LIFTING_FORECAST:"2023-04-18",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.1×10.809",WEIGHT_UNIT:98,WEIGHT_ATT:98,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"湖北长江 Hubei Changjiang",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"有工程图 Engineering dwg issued",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0410",DESCRIPTION:"C2 HYDROG 2ND STAGE INTERCOOLER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-19",RAS_ROS:"2023-04-15"},
    // {NO:164,PACKAGE:"P1",AREA:"OR",TAG_NO:"2560E005A",TAG_DESCR:"C3 REFRIGERANT CONDENSER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-15",DELIVERY_FORECAST:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-19",LIFTING_PLAN_WK:"21-Apr-23",LIFTING_FORECAST:"2023-04-19",STATUS:"To go",LOCATION:"冷区地面",SHIPMENT_NO:"TBD",WEIGHT_UNIT:115,WEIGHT_ATT:115,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"无锡化工 Wuxi Petrochemical",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"无图 No engineering dwg，无最新到货时间",CRANE_MAIN_FILTER:"800",CRANE_MAIN:"800",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0404",DESCRIPTION:"C3 REFRIGERANT CONDENSERx000D",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-03-08",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-03-08",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-01-29",LOA_ISSUE_PLAN:"2022-06-29",LOA_ISSUE_FORECAST:"2022-06-23",LOA_ISSUE_ACTUAL:"2022-06-17",PO_ISSUE_PLAN:"2022-08-10",PO_ISSUE_FORECAST:"2022-08-04",PO_ISSUE_ACTUAL:"2022-07-27",DELIVERY_TO_JOB_SITE_PLAN:"2023-02-27",RAS_ROS:"2023-04-15"},
    // {NO:165,PACKAGE:"P1",AREA:"OR",TAG_NO:"2560E005B",TAG_DESCR:"C3 REFRIGERANT CONDENSER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-15",DELIVERY_FORECAST:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-19",LIFTING_PLAN_WK:"21-Apr-23",LIFTING_FORECAST:"2023-04-19",STATUS:"To go",LOCATION:"冷区地面",SHIPMENT_NO:"TBD",WEIGHT_UNIT:115,WEIGHT_ATT:115,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"无锡化工 Wuxi Petrochemical",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"无图 No engineering dwg，无最新到货时间",CRANE_MAIN_FILTER:"800",CRANE_MAIN:"800",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0404",DESCRIPTION:"C3 REFRIGERANT CONDENSERx000D",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-03-08",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-03-08",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-01-29",LOA_ISSUE_PLAN:"2022-06-29",LOA_ISSUE_FORECAST:"2022-06-23",LOA_ISSUE_ACTUAL:"2022-06-17",PO_ISSUE_PLAN:"2022-08-10",PO_ISSUE_FORECAST:"2022-08-04",PO_ISSUE_ACTUAL:"2022-07-27",DELIVERY_TO_JOB_SITE_PLAN:"2023-02-27",RAS_ROS:"2023-04-15"},
    // {NO:166,PACKAGE:"P2",AREA:"GPPE",TAG_NO:"5010DR009",TAG_DESCR:"Ethylene CO Removal Vessel",TAG_TYPE:"V",DELIVERY_PLAN:"2023-04-18",DELIVERY_FORECAST:"2023-04-18",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-19",LIFTING_PLAN_WK:"21-Apr-23",LIFTING_FORECAST:"2023-04-19",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ3.0×12.1245",WEIGHT_UNIT:56,WEIGHT_ATT:56,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"山东豪迈",DRAWING:"有工程图",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",CRANE_TAIL:"80t Truck Monted Crane",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P2-1213-EQ-R-E-0501",DESCRIPTION:"Ethylene CO Removal Vessel",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-15",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-15",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-04-01",LOA_ISSUE_PLAN:"2022-09-04",LOA_ISSUE_FORECAST:"2022-08-22",LOA_ISSUE_ACTUAL:"2022-08-19",PO_ISSUE_PLAN:"2022-10-16",PO_ISSUE_FORECAST:"2022-09-30",PO_ISSUE_ACTUAL:"2022-09-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-05-02",RAS_ROS:"2023-04-18"},
    // {NO:167,PACKAGE:"P1",AREA:"Boiler",TAG_NO:"8310D001",TAG_DESCR:"Deaerator",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-19",LIFTING_PLAN_WK:"21-Apr-23",LIFTING_FORECAST:"2023-04-19",STATUS:"To go",LOCATION:"框架",SHIPMENT_NO:"TBD",DIMENSION_M:"φ4.0x26.0",WEIGHT_UNIT:108,WEIGHT_ATT:108,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"青岛昌隆 Qingdao Changlong",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others"},
    // {NO:168,PACKAGE:"P1",AREA:"OR",TAG_NO:"2560E005C",TAG_DESCR:"C3 REFRIGERANT CONDENSER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-15",DELIVERY_FORECAST:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-20",LIFTING_PLAN_WK:"21-Apr-23",LIFTING_FORECAST:"2023-04-20",STATUS:"To go",LOCATION:"冷区地面",SHIPMENT_NO:"TBD",WEIGHT_UNIT:115,WEIGHT_ATT:115,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"无锡化工 Wuxi Petrochemical",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"无图 No engineering dwg，无最新到货时间",CRANE_MAIN_FILTER:"800",CRANE_MAIN:"800",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0404",DESCRIPTION:"C3 REFRIGERANT CONDENSERx000D",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-03-08",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-03-08",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-01-29",LOA_ISSUE_PLAN:"2022-06-29",LOA_ISSUE_FORECAST:"2022-06-23",LOA_ISSUE_ACTUAL:"2022-06-17",PO_ISSUE_PLAN:"2022-08-10",PO_ISSUE_FORECAST:"2022-08-04",PO_ISSUE_ACTUAL:"2022-07-27",DELIVERY_TO_JOB_SITE_PLAN:"2023-02-27",RAS_ROS:"2023-04-15"},
    // {NO:169,PACKAGE:"P1",AREA:"OR",TAG_NO:"2560E005D",TAG_DESCR:"C3 REFRIGERANT CONDENSER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-15",DELIVERY_FORECAST:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-20",LIFTING_PLAN_WK:"21-Apr-23",LIFTING_FORECAST:"2023-04-20",STATUS:"To go",LOCATION:"冷区地面",SHIPMENT_NO:"TBD",WEIGHT_UNIT:115,WEIGHT_ATT:115,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"无锡化工 Wuxi Petrochemical",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"无图 No engineering dwg，无最新到货时间",CRANE_MAIN_FILTER:"800",CRANE_MAIN:"800",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0404",DESCRIPTION:"C3 REFRIGERANT CONDENSERx000D",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-03-08",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-03-08",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-01-29",LOA_ISSUE_PLAN:"2022-06-29",LOA_ISSUE_FORECAST:"2022-06-23",LOA_ISSUE_ACTUAL:"2022-06-17",PO_ISSUE_PLAN:"2022-08-10",PO_ISSUE_FORECAST:"2022-08-04",PO_ISSUE_ACTUAL:"2022-07-27",DELIVERY_TO_JOB_SITE_PLAN:"2023-02-27",RAS_ROS:"2023-04-15"},
    // {NO:170,PACKAGE:"P1",AREA:"OR",TAG_NO:"2540T030",TAG_DESCR:"RESIDUE GAS RECTIFIER",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-14",DELIVERY_FORECAST:"2023-01-14",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-20",LIFTING_PLAN_WK:"21-Apr-23",LIFTING_FORECAST:"2023-04-20",STATUS:"To go",LOCATION:"冷区地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.1×36.55",WEIGHT_UNIT:90,WEIGHT_ATT:90,WEIGHT_DWG:90.3,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"TJHY",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计 Lifting lugs design done",DRAWING:"有制造图、管口方位图 shop assembly dwg IFC",CRANE_MAIN_FILTER:"1,600",CRANE_MAIN:"1600",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"SLT",MR_NO:"75231-P1-1279-EQ-R-E-SL05",DESCRIPTION:"RESIDUE GAS RECTIFIER 304L SS 21 172",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-23",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-23",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-08",LOA_ISSUE_PLAN:"2022-02-18",LOA_ISSUE_FORECAST:"2022-02-03",LOA_ISSUE_ACTUAL:"2022-02-14",PO_ISSUE_PLAN:"2022-04-01",PO_ISSUE_FORECAST:"2022-03-17",PO_ISSUE_ACTUAL:"2022-03-18",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-02-15"},
    // {NO:171,PACKAGE:"P1",AREA:"OR",TAG_NO:"2554E010A",TAG_DESCR:"C3 STRIPPER QW REBOILERS",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-23",LIFTING_PLAN_WK:"28-Apr-23",LIFTING_FORECAST:"2023-04-23",STATUS:"To go",LOCATION:"热区地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.3x13.011",WEIGHT_UNIT:97,WEIGHT_ATT:97,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"无锡鼎邦 Wuxi Dingbang",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"有工程图 Engineering dwg issued，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0408",DESCRIPTION:"C3 STRIPPER QW REBOILERS",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-19",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-30",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-19",RAS_ROS:"2023-04-15"},
    // {NO:172,PACKAGE:"P1",AREA:"OR",TAG_NO:"2554E010B",TAG_DESCR:"C3 STRIPPER QW REBOILERS",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-23",LIFTING_PLAN_WK:"28-Apr-23",LIFTING_FORECAST:"2023-04-23",STATUS:"To go",LOCATION:"热区地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.3x13.012",WEIGHT_UNIT:97,WEIGHT_ATT:97,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"无锡鼎邦 Wuxi Dingbang",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"有工程图 Engineering dwg issued，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0408",DESCRIPTION:"C3 STRIPPER QW REBOILERS",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-19",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-30",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-19",RAS_ROS:"2023-04-15"},
    // {NO:173,PACKAGE:"P1",AREA:"OR",TAG_NO:"2537R001A",TAG_DESCR:"C2 HYDROG",TAG_TYPE:"V",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-23",LIFTING_PLAN_WK:"28-Apr-23",LIFTING_FORECAST:"2023-04-23",STATUS:"To go",LOCATION:"压缩地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅4.4×18",WEIGHT_UNIT:139,WEIGHT_ATT:139,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"航天晨光 HTCG",DRAWING:"有工程图 Engineering dwg issued",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-SL08",DESCRIPTION:"C2 HYDROG REACTORSC2 kCS 44 76",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-03-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-03-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-02-07",LOA_ISSUE_PLAN:"2022-07-24",LOA_ISSUE_FORECAST:"2022-08-07",LOA_ISSUE_ACTUAL:"2022-08-10",PO_ISSUE_PLAN:"2022-09-04",PO_ISSUE_FORECAST:"2022-09-28",PO_ISSUE_ACTUAL:"2022-09-28",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-28",RAS_ROS:"2023-04-30"},
    // {NO:174,PACKAGE:"P2",AREA:"GPPE",TAG_NO:"5010DR007A",TAG_DESCR:"Ethylene Purification Vessel",TAG_TYPE:"V",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-23",LIFTING_PLAN_WK:"28-Apr-23",LIFTING_FORECAST:"2023-04-23",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ3.0x18.4755",WEIGHT_UNIT:83,WEIGHT_ATT:166,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"ZEECO (中国) ",DRAWING:"有工程图 Engineering dwg issued",CRANE_MAIN_FILTER:"800",CRANE_MAIN:"800",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P2-1213-EQ-R-E-0501",DESCRIPTION:"Ethylene Purification Vessel",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-15",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-15",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-04-01",LOA_ISSUE_PLAN:"2022-09-04",LOA_ISSUE_FORECAST:"2022-08-22",LOA_ISSUE_ACTUAL:"2022-08-19",PO_ISSUE_PLAN:"2022-10-16",PO_ISSUE_FORECAST:"2022-09-30",PO_ISSUE_ACTUAL:"2022-09-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-05-02",RAS_ROS:"2023-04-18"},
    // {NO:175,PACKAGE:"P2",AREA:"GPPE",TAG_NO:"5010DR007B",TAG_DESCR:"Ethylene Purification Vessel",TAG_TYPE:"V",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-23",LIFTING_PLAN_WK:"28-Apr-23",LIFTING_FORECAST:"2023-04-23",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ3.0x18.4755",WEIGHT_UNIT:83,WEIGHT_ATT:166,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"ZEECO (中国) ",DRAWING:"有工程图 Engineering dwg issued",CRANE_MAIN_FILTER:"800",CRANE_MAIN:"800",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P2-1213-EQ-R-E-0501",DESCRIPTION:"Ethylene Purification Vessel",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-15",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-15",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-04-01",LOA_ISSUE_PLAN:"2022-09-04",LOA_ISSUE_FORECAST:"2022-08-22",LOA_ISSUE_ACTUAL:"2022-08-19",PO_ISSUE_PLAN:"2022-10-16",PO_ISSUE_FORECAST:"2022-09-30",PO_ISSUE_ACTUAL:"2022-09-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-05-02",RAS_ROS:"2023-04-18"},
    // {NO:176,PACKAGE:"P1",AREA:"OR",TAG_NO:"2537R001B",TAG_DESCR:"C2 HYDROG",TAG_TYPE:"V",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-24",LIFTING_PLAN_WK:"28-Apr-23",LIFTING_FORECAST:"2023-04-24",STATUS:"To go",LOCATION:"压缩地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅4.4×19",WEIGHT_UNIT:139,WEIGHT_ATT:139,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"航天晨光 HTCG",DRAWING:"有工程图 Engineering dwg issued",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-SL08",DESCRIPTION:"C2 HYDROG REACTORSC2 kCS 44 76",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-03-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-03-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-02-07",LOA_ISSUE_PLAN:"2022-07-24",LOA_ISSUE_FORECAST:"2022-08-07",LOA_ISSUE_ACTUAL:"2022-08-10",PO_ISSUE_PLAN:"2022-09-04",PO_ISSUE_FORECAST:"2022-09-28",PO_ISSUE_ACTUAL:"2022-09-28",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-28",RAS_ROS:"2023-04-30"},
    // {NO:177,PACKAGE:"P1",AREA:"OR",TAG_NO:"2537R001C",TAG_DESCR:"C2 HYDROG",TAG_TYPE:"V",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-24",LIFTING_PLAN_WK:"28-Apr-23",LIFTING_FORECAST:"2023-04-24",STATUS:"To go",LOCATION:"压缩地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅4.4×20",WEIGHT_UNIT:139,WEIGHT_ATT:139,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"航天晨光 HTCG",DRAWING:"有工程图 Engineering dwg issued",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-SL08",DESCRIPTION:"C2 HYDROG REACTORSC2 kCS 44 76",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-03-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-03-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-02-07",LOA_ISSUE_PLAN:"2022-07-24",LOA_ISSUE_FORECAST:"2022-08-07",LOA_ISSUE_ACTUAL:"2022-08-10",PO_ISSUE_PLAN:"2022-09-04",PO_ISSUE_FORECAST:"2022-09-28",PO_ISSUE_ACTUAL:"2022-09-28",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-28",RAS_ROS:"2023-04-30"},
    // {NO:178,PACKAGE:"P1",AREA:"OR",TAG_NO:"2554E010C",TAG_DESCR:"C3 STRIPPER QW REBOILERS",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-24",LIFTING_PLAN_WK:"28-Apr-23",LIFTING_FORECAST:"2023-04-24",STATUS:"To go",LOCATION:"热区地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.3x13.013",WEIGHT_UNIT:97,WEIGHT_ATT:97,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"无锡鼎邦 Wuxi Dingbang",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"有工程图 Engineering dwg issued，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0408",DESCRIPTION:"C3 STRIPPER QW REBOILERS",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-19",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-30",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-19",RAS_ROS:"2023-04-15"},
    // {NO:179,PACKAGE:"P1",AREA:"OR",TAG_NO:"2554E010D",TAG_DESCR:"C3 STRIPPER QW REBOILERS",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-24",LIFTING_PLAN_WK:"28-Apr-23",LIFTING_FORECAST:"2023-04-24",STATUS:"To go",LOCATION:"热区地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.3x13.014",WEIGHT_UNIT:97,WEIGHT_ATT:97,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"无锡鼎邦 Wuxi Dingbang",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"有工程图 Engineering dwg issued，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0408",DESCRIPTION:"C3 STRIPPER QW REBOILERS",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-19",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-30",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-19",RAS_ROS:"2023-04-15"},
    // {NO:180,PACKAGE:"P1",AREA:"OR",TAG_NO:"2554E011",TAG_DESCR:"C3 STRIPPER STEAM REBOILER",TAG_TYPE:"V",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-25",LIFTING_PLAN_WK:"28-Apr-23",LIFTING_FORECAST:"2023-04-25",STATUS:"To go",LOCATION:"热区5B",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.4×14.10",WEIGHT_UNIT:92,WEIGHT_ATT:92,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"无锡鼎邦 Wuxi Dingbang",DRAWING:"有工程图 Engineering dwg issued，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-2",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0408",DESCRIPTION:"C3 STRIPPER STEAM REBOILER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-19",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-30",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-19",RAS_ROS:"2023-04-15"},
    // {NO:181,PACKAGE:"P1",AREA:"OR",TAG_NO:"2522E020",TAG_DESCR:"PRIMARY QUENCH WATER TRIM COOLER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-27",LIFTING_PLAN_WK:"28-Apr-23",LIFTING_FORECAST:"2023-04-20",STATUS:"To go",LOCATION:"急冷地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅1.8x11.1255",WEIGHT_UNIT:54,WEIGHT_ATT:54,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"山东美陵",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"有工程图 Engineering dwg issued，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0409",DESCRIPTION:"PRIMARY QUENCH WATER TRIM COOLER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-18",RAS_ROS:"2023-04-15"},
    // {NO:182,PACKAGE:"P1",AREA:"OR",TAG_NO:"2522E020",TAG_DESCR:"QW TRIM COOLER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-27",LIFTING_PLAN_WK:"28-Apr-23",LIFTING_FORECAST:"2023-04-20",STATUS:"To go",LOCATION:"急冷地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ1.8×11.125",WEIGHT_UNIT:54,WEIGHT_ATT:54,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"山东美陵",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0409",DESCRIPTION:"PRIMARY QUENCH WATER TRIM COOLER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-18",RAS_ROS:"2023-04-15"},
    // {NO:183,PACKAGE:"P1",AREA:"OR",TAG_NO:"2521E012A",TAG_DESCR:"VLP STEAM GENERATOR",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-28",LIFTING_PLAN_WK:"28-Apr-23",LIFTING_FORECAST:"2023-04-28",STATUS:"To go",LOCATION:"急冷地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅3.3x15.745",WEIGHT_UNIT:175,WEIGHT_ATT:175,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"湖北长江 Hubei Changjiang",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"有工程图 Engineering dwg issued，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0410",DESCRIPTION:"VLP STEAM GENERATOR",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-19",RAS_ROS:"2023-04-15"},
    // {NO:184,PACKAGE:"P1",AREA:"OR",TAG_NO:"2521E012B",TAG_DESCR:"VLP STEAM GENERATOR",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-28",LIFTING_PLAN_WK:"28-Apr-23",LIFTING_FORECAST:"2023-04-28",STATUS:"To go",LOCATION:"急冷地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅3.3x15.746",WEIGHT_UNIT:175,WEIGHT_ATT:175,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"湖北长江 Hubei Changjiang",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"有工程图 Engineering dwg issued，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0410",DESCRIPTION:"VLP STEAM GENERATOR",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-19",RAS_ROS:"2023-04-15"},
    // {NO:185,PACKAGE:"P2",AREA:"PP",TAG_NO:"6130R024",TAG_DESCR:"1st Gas Phase Reactor",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-30",DELIVERY_FORECAST:"2023-05-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-28",LIFTING_PLAN_WK:"28-Apr-23",LIFTING_FORECAST:"2023-06-07",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ4.0/ 5.2×22.525",WEIGHT_UNIT:110,WEIGHT_ATT:110,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"兰石 Lanzhou Petrochemical",DRAWING:"有工程图 Engineering dwg issued",CRANE_MAIN_FILTER:"800",CRANE_MAIN:"800",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P2-1219-EQ-R-E-SL01",DESCRIPTION:"1st Gas Phase Reactor",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-12-02",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-12-02",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-02",LOA_ISSUE_PLAN:"2022-05-15",LOA_ISSUE_FORECAST:"2022-05-10",LOA_ISSUE_ACTUAL:"2022-05-07",PO_ISSUE_PLAN:"2022-06-26",PO_ISSUE_FORECAST:"2022-06-18",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-17",RAS_ROS:"2023-07-30"},
    // {NO:186,PACKAGE:"P2",AREA:"PP",TAG_NO:"6130R025",TAG_DESCR:"2nd Gas Phase Reactor",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-30",DELIVERY_FORECAST:"2023-05-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-28",LIFTING_PLAN_WK:"28-Apr-23",LIFTING_FORECAST:"2023-06-07",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ4.0/ 5.2×22.525",WEIGHT_UNIT:110,WEIGHT_ATT:110,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"兰石 Lanzhou Petrochemical",DRAWING:"有工程图 Engineering dwg issued",CRANE_MAIN_FILTER:"800",CRANE_MAIN:"800",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P2-1219-EQ-R-E-SL01",DESCRIPTION:"2nd Gas Phase Reactor",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-12-02",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-12-02",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-02",LOA_ISSUE_PLAN:"2022-05-15",LOA_ISSUE_FORECAST:"2022-05-10",LOA_ISSUE_ACTUAL:"2022-05-07",PO_ISSUE_PLAN:"2022-06-26",PO_ISSUE_FORECAST:"2022-06-18",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-17",RAS_ROS:"2023-07-30"},
    // {NO:187,PACKAGE:"P1",AREA:"OR",TAG_NO:"2521E012C",TAG_DESCR:"VLP STEAM GENERATOR",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-29",LIFTING_PLAN_WK:"05-May-23",LIFTING_FORECAST:"2023-04-29",STATUS:"To go",LOCATION:"急冷地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅3.3x15.747",WEIGHT_UNIT:175,WEIGHT_ATT:175,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"湖北长江 Hubei Changjiang",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"有工程图 Engineering dwg issued，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0410",DESCRIPTION:"VLP STEAM GENERATOR",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-19",RAS_ROS:"2023-04-15"},
    // {NO:188,PACKAGE:"P1",AREA:"OR",TAG_NO:"2521E012D",TAG_DESCR:"VLP STEAM GENERATOR",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-29",LIFTING_PLAN_WK:"05-May-23",LIFTING_FORECAST:"2023-04-29",STATUS:"To go",LOCATION:"急冷地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅3.3x15.748",WEIGHT_UNIT:175,WEIGHT_ATT:175,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"湖北长江 Hubei Changjiang",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"有工程图 Engineering dwg issued，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0410",DESCRIPTION:"VLP STEAM GENERATOR",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-19",RAS_ROS:"2023-04-15"},
    // {NO:189,PACKAGE:"P1",AREA:"OR",TAG_NO:"2536E016",TAG_DESCR:"HP DEPROPANIZER REFLUX CHILLER NO. 1",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-15",DELIVERY_FORECAST:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-29",LIFTING_PLAN_WK:"05-May-23",LIFTING_FORECAST:"2023-04-29",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.600X12.000",WEIGHT_UNIT:70,WEIGHT_ATT:70,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"北京广厦",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0406",DESCRIPTION:"HP DEPROPANIZER REFLUX CHILLER NO 1x000D",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-03-08",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-03-08",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-01-29",LOA_ISSUE_PLAN:"2022-06-29",LOA_ISSUE_FORECAST:"2022-06-23",LOA_ISSUE_ACTUAL:"2022-06-21",PO_ISSUE_PLAN:"2022-08-10",PO_ISSUE_FORECAST:"2022-08-04",PO_ISSUE_ACTUAL:"2022-07-27",DELIVERY_TO_JOB_SITE_PLAN:"2023-02-27",RAS_ROS:"2023-04-15"},
    // {NO:190,PACKAGE:"P1",AREA:"OR",TAG_NO:"2536E017",TAG_DESCR:"HP DEPROPANIZER REFLUX CHILLER NO.2",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-15",DELIVERY_FORECAST:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-30",LIFTING_PLAN_WK:"05-May-23",LIFTING_FORECAST:"2023-04-30",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.6x14",WEIGHT_UNIT:80,WEIGHT_ATT:80,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"北京广厦 Beijing Guangsha",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"无图 No engineering dwg，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0406",DESCRIPTION:"HP DEPROPANIZER REFLUX CHILLER NO 2x000D",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-03-08",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-03-08",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-01-29",LOA_ISSUE_PLAN:"2022-06-29",LOA_ISSUE_FORECAST:"2022-06-23",LOA_ISSUE_ACTUAL:"2022-06-21",PO_ISSUE_PLAN:"2022-08-10",PO_ISSUE_FORECAST:"2022-08-04",PO_ISSUE_ACTUAL:"2022-07-27",DELIVERY_TO_JOB_SITE_PLAN:"2023-02-27",RAS_ROS:"2023-04-15"},
    // {NO:191,PACKAGE:"P1",AREA:"OR",TAG_NO:"2536E019",TAG_DESCR:"HP DEPROPANIZER CONDENSER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-15",DELIVERY_FORECAST:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-30",LIFTING_PLAN_WK:"05-May-23",LIFTING_FORECAST:"2023-04-30",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅3x14",WEIGHT_UNIT:107,WEIGHT_ATT:107,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"北京广厦 Beijing Guangsha",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"无图 No engineering dwg，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0405",DESCRIPTION:"HP DEPROPANIZER CONDENSERx000D",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-03-08",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-03-08",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-01-29",LOA_ISSUE_PLAN:"2022-06-29",LOA_ISSUE_FORECAST:"2022-06-23",LOA_ISSUE_ACTUAL:"2022-06-21",PO_ISSUE_PLAN:"2022-08-10",PO_ISSUE_FORECAST:"2022-08-04",PO_ISSUE_ACTUAL:"2022-07-27",DELIVERY_TO_JOB_SITE_PLAN:"2023-02-27",RAS_ROS:"2023-04-05"},
    // {NO:192,PACKAGE:"P1",AREA:"OR",TAG_NO:"2540E065",TAG_DESCR:"DEMETHANIZER CONDENSER",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-14",DELIVERY_FORECAST:"2023-03-14",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-04-30",LIFTING_PLAN_WK:"05-May-23",LIFTING_FORECAST:"2023-04-30",STATUS:"To go",LOCATION:"2540T051顶部",SHIPMENT_NO:"TBD",WEIGHT_ATT:0,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"杭州中泰",DESIGN_OF_LIFTING_LUG:"厂家已完成吊耳图设计",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"80t Truck Monted Crane",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0402",DESCRIPTION:"DEMETHANIZER CONDENSER 1600T CIV",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-18",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-18",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-18",LOA_ISSUE_PLAN:"2022-03-10",LOA_ISSUE_FORECAST:"2022-03-18",LOA_ISSUE_ACTUAL:"2022-03-16",PO_ISSUE_PLAN:"2022-04-21",PO_ISSUE_FORECAST:"2022-04-29",PO_ISSUE_ACTUAL:"2022-04-02",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-27",RAS_ROS:"2023-04-30"},
    // {NO:193,PACKAGE:"P1",AREA:"OR",TAG_NO:"2536E065",TAG_DESCR:"LP DEPROPANIZER CONDENSER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-15",DELIVERY_FORECAST:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-02",LIFTING_PLAN_WK:"05-May-23",LIFTING_FORECAST:"2023-05-02",STATUS:"To go",LOCATION:"冷区地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅1.9/3.0×12",WEIGHT_UNIT:96,WEIGHT_ATT:96,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"北京广厦 Beijing Guangsha",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"无图 No engineering dwg，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0406",DESCRIPTION:"LP DEPROPANIZER CONDENSERx000D",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-03-08",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-03-08",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-01-29",LOA_ISSUE_PLAN:"2022-06-29",LOA_ISSUE_FORECAST:"2022-06-23",LOA_ISSUE_ACTUAL:"2022-06-21",PO_ISSUE_PLAN:"2022-08-10",PO_ISSUE_FORECAST:"2022-08-04",PO_ISSUE_ACTUAL:"2022-07-27",DELIVERY_TO_JOB_SITE_PLAN:"2023-02-27",RAS_ROS:"2023-04-15"},
    // {NO:194,PACKAGE:"P1",AREA:"OR",TAG_NO:"2535DR010A",TAG_DESCR:"CRACKED GAS DRYERS",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-01",DELIVERY_FORECAST:"2023-05-01",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-04",LIFTING_PLAN_WK:"05-May-23",LIFTING_FORECAST:"2023-05-04",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅5.9×16.73",WEIGHT_UNIT:200,WEIGHT_ATT:200,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"Himile",DRAWING:"有工程图 Engineering dwg issued",CRANE_MAIN_FILTER:"800",CRANE_MAIN:"800",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans"},
    // {NO:195,PACKAGE:"P1",AREA:"OR",TAG_NO:"2535DR010B",TAG_DESCR:"CRACKED GAS DRYERS",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-01",DELIVERY_FORECAST:"2023-05-01",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-05",LIFTING_PLAN_WK:"05-May-23",LIFTING_FORECAST:"2023-05-05",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅5.9×16.74",WEIGHT_UNIT:200,WEIGHT_ATT:200,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"Himile",DRAWING:"有工程图 Engineering dwg issued",CRANE_MAIN_FILTER:"800",CRANE_MAIN:"800",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans"},
    // {NO:196,PACKAGE:"P1",AREA:"OR",TAG_NO:"2535DR010C",TAG_DESCR:"CRACKED GAS DRYERS",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-01",DELIVERY_FORECAST:"2023-05-01",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-05",LIFTING_PLAN_WK:"05-May-23",LIFTING_FORECAST:"2023-05-05",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅5.9×16.75",WEIGHT_UNIT:200,WEIGHT_ATT:200,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"Himile",DRAWING:"有工程图 Engineering dwg issued",CRANE_MAIN_FILTER:"800",CRANE_MAIN:"800",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans"},
    // {NO:197,PACKAGE:"P1",AREA:"OR",TAG_NO:"2560D005",TAG_DESCR:"PROPYLENE REFRIGERANT RECEIVER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-05",DELIVERY_FORECAST:"2023-05-05",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-05",LIFTING_PLAN_WK:"05-May-23",LIFTING_FORECAST:"2023-05-05",STATUS:"To go",LOCATION:"冷区4C框架",SHIPMENT_NO:"TBD",DIMENSION_M:"φ3.8×12.932",WEIGHT_UNIT:55,WEIGHT_ATT:55,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"天津瀚洋",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0302",DESCRIPTION:"PROPYLENE REFRIGERANT RECEIVER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-07",LOA_ISSUE_PLAN:"2022-08-22",LOA_ISSUE_FORECAST:"2022-08-18",LOA_ISSUE_ACTUAL:"2022-08-17",PO_ISSUE_PLAN:"2022-10-03",PO_ISSUE_FORECAST:"2022-09-29",PO_ISSUE_ACTUAL:"2022-09-20",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-22",RAS_ROS:"2023-05-01"},
    // {NO:198,PACKAGE:"P1",AREA:"OR",TAG_NO:"2530D020",TAG_DESCR:"CGC 2ND STAGE SUCTION DRUM",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-01",DELIVERY_FORECAST:"2023-05-01",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-06",LIFTING_PLAN_WK:"12-May-23",LIFTING_FORECAST:"2023-05-06",STATUS:"To go",LOCATION:"压缩地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅7.1×22.3",WEIGHT_UNIT:105,WEIGHT_ATT:105,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"山东豪迈 Shandong Himile",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"有工程图 Engineering dwg issued，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-2",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0301",DESCRIPTION:"CG 2ND STAGE SUCTION DRUM",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-07",LOA_ISSUE_PLAN:"2022-08-22",LOA_ISSUE_FORECAST:"2022-08-18",LOA_ISSUE_ACTUAL:"2022-08-17",PO_ISSUE_PLAN:"2022-10-03",PO_ISSUE_FORECAST:"2022-09-29",PO_ISSUE_ACTUAL:"2022-09-20",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-26",RAS_ROS:"2023-05-01"},
    // {NO:199,PACKAGE:"P1",AREA:"OR",TAG_NO:"2580D002",TAG_DESCR:"LLP Steam Flash Drum",TAG_TYPE:"V",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-07",LIFTING_PLAN_WK:"12-May-23",LIFTING_FORECAST:"2023-05-07",STATUS:"To go",LOCATION:"压缩地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ4.6x8",WEIGHT_UNIT:200,WEIGHT_ATT:200,LIFTING_CATEGORY:"Over 80t",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"800",CRANE_MAIN:"800",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans"},
    // {NO:200,PACKAGE:"P1",AREA:"OR",TAG_NO:"2590D005",TAG_DESCR:"WARM FLARE KNOCK-OUT DRUM",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-09",DELIVERY_FORECAST:"2023-05-09",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-07",LIFTING_PLAN_WK:"12-May-23",LIFTING_FORECAST:"2023-05-07",STATUS:"To go",LOCATION:"急冷地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅6.75×22.8",WEIGHT_UNIT:135,WEIGHT_ATT:135,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"Himile",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"有工程图 Engineering dwg issued",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0301",DESCRIPTION:"WARM FLARE KNOCKOUT DRUM",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-07",LOA_ISSUE_PLAN:"2022-08-22",LOA_ISSUE_FORECAST:"2022-08-18",LOA_ISSUE_ACTUAL:"2022-08-17",PO_ISSUE_PLAN:"2022-10-03",PO_ISSUE_FORECAST:"2022-09-29",PO_ISSUE_ACTUAL:"2022-09-20",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-26",RAS_ROS:"2023-05-01"},
    // {NO:201,PACKAGE:"P2",AREA:"LDPE",TAG_NO:"5380BN911A",TAG_DESCR:"Blending Silo (Line B)",TAG_TYPE:"V",DELIVERY_PLAN:"2023-04-30",DELIVERY_FORECAST:"2023-04-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-08",LIFTING_PLAN_WK:"12-May-23",LIFTING_FORECAST:"2023-05-08",STATUS:"To go",LOCATION:"1#成品包装库",SHIPMENT_NO:"TBD",WEIGHT_UNIT:45,WEIGHT_ATT:135,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:202,PACKAGE:"P2",AREA:"LDPE",TAG_NO:"5380BN911B",TAG_DESCR:"Blending Silo (Line B)",TAG_TYPE:"V",DELIVERY_PLAN:"2023-04-30",DELIVERY_FORECAST:"2023-04-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-08",LIFTING_PLAN_WK:"12-May-23",LIFTING_FORECAST:"2023-05-08",STATUS:"To go",LOCATION:"1#成品包装库",SHIPMENT_NO:"TBD",WEIGHT_UNIT:45,WEIGHT_ATT:135,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:203,PACKAGE:"P2",AREA:"LDPE",TAG_NO:"5380BN911C",TAG_DESCR:"Blending Silo (Line B)",TAG_TYPE:"V",DELIVERY_PLAN:"2023-04-30",DELIVERY_FORECAST:"2023-04-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-08",LIFTING_PLAN_WK:"12-May-23",LIFTING_FORECAST:"2023-05-08",STATUS:"To go",LOCATION:"1#成品包装库",SHIPMENT_NO:"TBD",WEIGHT_UNIT:45,WEIGHT_ATT:135,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:204,PACKAGE:"P1",AREA:"OR",TAG_NO:"2526D060",TAG_DESCR:"DILUTION STEAM GENERATOR",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-01",DELIVERY_FORECAST:"2023-05-01",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-08",LIFTING_PLAN_WK:"12-May-23",LIFTING_FORECAST:"2023-05-08",STATUS:"To go",LOCATION:"急冷地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅4.6X16.85",WEIGHT_UNIT:86,WEIGHT_ATT:86,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"山东豪迈 Shandong Himile",DRAWING:"有工程图 Engineering dwg issued，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-2",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0301",DESCRIPTION:"DILUTION STEAM GENERATOR",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-07",LOA_ISSUE_PLAN:"2022-08-22",LOA_ISSUE_FORECAST:"2022-08-18",LOA_ISSUE_ACTUAL:"2022-08-17",PO_ISSUE_PLAN:"2022-10-03",PO_ISSUE_FORECAST:"2022-09-29",PO_ISSUE_ACTUAL:"2022-09-20",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-26",RAS_ROS:"2023-05-01"},
    // {NO:205,PACKAGE:"P1",AREA:"OR",TAG_NO:"2530D030",TAG_DESCR:"CG 3RD STAGE SUCTION DRUM",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-08",DELIVERY_FORECAST:"2023-05-08",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-09",LIFTING_PLAN_WK:"12-May-23",LIFTING_FORECAST:"2023-05-09",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ4.3×15.95",WEIGHT_UNIT:78,WEIGHT_ATT:78,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"Himile",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"Truck Mounted Crane",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0301",DESCRIPTION:"CG 3RD STAGE SUCTION DRUM",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-07",LOA_ISSUE_PLAN:"2022-08-22",LOA_ISSUE_FORECAST:"2022-08-18",LOA_ISSUE_ACTUAL:"2022-08-17",PO_ISSUE_PLAN:"2022-10-03",PO_ISSUE_FORECAST:"2022-09-29",PO_ISSUE_ACTUAL:"2022-09-20",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-26",RAS_ROS:"2023-05-01"},
    // {NO:206,PACKAGE:"P1",AREA:"OR",TAG_NO:"2530D040",TAG_DESCR:"CG 4TH STAGE SUCTION DRUM",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-08",DELIVERY_FORECAST:"2023-05-08",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-09",LIFTING_PLAN_WK:"12-May-23",LIFTING_FORECAST:"2023-05-09",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ5.1×13.15",WEIGHT_UNIT:60,WEIGHT_ATT:60,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"Himile",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"Truck Mounted Crane",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0301",DESCRIPTION:"CG 4TH STAGE SUCTION DRUM",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-07",LOA_ISSUE_PLAN:"2022-08-22",LOA_ISSUE_FORECAST:"2022-08-18",LOA_ISSUE_ACTUAL:"2022-08-17",PO_ISSUE_PLAN:"2022-10-03",PO_ISSUE_FORECAST:"2022-09-29",PO_ISSUE_ACTUAL:"2022-09-20",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-26",RAS_ROS:"2023-05-01"},
    // {NO:207,PACKAGE:"P1",AREA:"OR",TAG_NO:"2565D030",TAG_DESCR:"C2R 3RD STAGE SUCTION RUM",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-09",DELIVERY_FORECAST:"2023-05-09",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-09",LIFTING_PLAN_WK:"12-May-23",LIFTING_FORECAST:"2023-05-09",STATUS:"To go",LOCATION:"冷区地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅6×7.55",WEIGHT_UNIT:83,WEIGHT_ATT:83,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"Himile",DRAWING:"有工程图 Engineering dwg issued",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-2",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0301",DESCRIPTION:"C2R 3RD STAGE SUCTION DRUM",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-07",LOA_ISSUE_PLAN:"2022-08-22",LOA_ISSUE_FORECAST:"2022-08-18",LOA_ISSUE_ACTUAL:"2022-08-17",PO_ISSUE_PLAN:"2022-10-03",PO_ISSUE_FORECAST:"2022-09-29",PO_ISSUE_ACTUAL:"2022-09-20",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-26",RAS_ROS:"2023-05-01"},
    // {NO:208,PACKAGE:"P1",AREA:"OR",TAG_NO:"2554D015",TAG_DESCR:"C3 RECTIFIER REFLUX DRUM",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-09",DELIVERY_FORECAST:"2023-05-09",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-09",LIFTING_PLAN_WK:"12-May-23",LIFTING_FORECAST:"2023-05-09",STATUS:"To go",LOCATION:"热区 5F",SHIPMENT_NO:"TBD",DIMENSION_M:"∅5.5×17.617",WEIGHT_UNIT:122,WEIGHT_ATT:122,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"Himile",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"有工程图 Engineering dwg issued",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0301",DESCRIPTION:"C3 RECTIFIER REFLUX DRUM",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-07",LOA_ISSUE_PLAN:"2022-08-22",LOA_ISSUE_FORECAST:"2022-08-18",LOA_ISSUE_ACTUAL:"2022-08-17",PO_ISSUE_PLAN:"2022-10-03",PO_ISSUE_FORECAST:"2022-09-29",PO_ISSUE_ACTUAL:"2022-09-20",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-26",RAS_ROS:"2023-05-01"},
    // {NO:209,PACKAGE:"P2",AREA:"LDPE",TAG_NO:"5380BN901A",TAG_DESCR:"Blending Silo (Line A)",TAG_TYPE:"V",DELIVERY_PLAN:"2023-04-30",DELIVERY_FORECAST:"2023-04-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-09",LIFTING_PLAN_WK:"12-May-23",LIFTING_FORECAST:"2023-05-09",STATUS:"To go",LOCATION:"1#成品包装库",SHIPMENT_NO:"TBD",WEIGHT_UNIT:45,WEIGHT_ATT:90,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:210,PACKAGE:"P2",AREA:"LDPE",TAG_NO:"5380BN901B",TAG_DESCR:"Blending Silo (Line A)",TAG_TYPE:"V",DELIVERY_PLAN:"2023-04-30",DELIVERY_FORECAST:"2023-04-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-09",LIFTING_PLAN_WK:"12-May-23",LIFTING_FORECAST:"2023-05-09",STATUS:"To go",LOCATION:"1#成品包装库",SHIPMENT_NO:"TBD",WEIGHT_UNIT:45,WEIGHT_ATT:90,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:211,PACKAGE:"P1",AREA:"OR",TAG_NO:"2590D001",TAG_DESCR:"COLD FLARE KNOCK-OUT RUM",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-09",DELIVERY_FORECAST:"2023-05-09",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-09",LIFTING_PLAN_WK:"12-May-23",LIFTING_FORECAST:"2023-05-09",STATUS:"To go",LOCATION:"热区地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅6.75×22.65",WEIGHT_UNIT:125,WEIGHT_ATT:125,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"Himile",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"有工程图 Engineering dwg issued",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0301",DESCRIPTION:"COLD FLARE KNOCKOUT DRUM",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-07",LOA_ISSUE_PLAN:"2022-08-22",LOA_ISSUE_FORECAST:"2022-08-18",LOA_ISSUE_ACTUAL:"2022-08-17",PO_ISSUE_PLAN:"2022-10-03",PO_ISSUE_FORECAST:"2022-09-29",PO_ISSUE_ACTUAL:"2022-09-20",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-26",RAS_ROS:"2023-05-01"},
    // {NO:212,PACKAGE:"P1",AREA:"OR",TAG_NO:"2565D005",TAG_DESCR:"C2R SURGE DRUM",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-09",DELIVERY_FORECAST:"2023-05-09",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-10",LIFTING_PLAN_WK:"12-May-23",LIFTING_FORECAST:"2023-05-10",STATUS:"To go",LOCATION:"冷区4K",SHIPMENT_NO:"TBD",DIMENSION_M:"∅4.2×14.364",WEIGHT_UNIT:65,WEIGHT_ATT:65,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"天津瀚洋 Tianjin HY",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"有工程图 Engineering dwg issued",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0302",DESCRIPTION:"C2R SURGE DRUM",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-07",LOA_ISSUE_PLAN:"2022-08-22",LOA_ISSUE_FORECAST:"2022-08-18",LOA_ISSUE_ACTUAL:"2022-08-17",PO_ISSUE_PLAN:"2022-10-03",PO_ISSUE_FORECAST:"2022-09-29",PO_ISSUE_ACTUAL:"2022-09-20",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-22",RAS_ROS:"2023-05-01"},
    // {NO:213,PACKAGE:"P1",AREA:"OR",TAG_NO:"2537DR050",TAG_DESCR:"CRACKED GAS SECONDARY DRYER",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-09",DELIVERY_FORECAST:"2023-05-09",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-10",LIFTING_PLAN_WK:"12-May-23",LIFTING_FORECAST:"2023-05-10",STATUS:"To go",LOCATION:"压缩地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅6.3×23.2",WEIGHT_UNIT:240,WEIGHT_ATT:240,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"Himile",DRAWING:"有工程图 Engineering dwg issued",CRANE_MAIN_FILTER:"800",CRANE_MAIN:"800",CRANE_TAIL:"280t Crane-2",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans"},
    // {NO:214,PACKAGE:"P1",AREA:"OR",TAG_NO:"2530D045",TAG_DESCR:"CG 4TH STAGE DISCHARGE DRUM",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-08",DELIVERY_FORECAST:"2023-05-08",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-10",LIFTING_PLAN_WK:"12-May-23",LIFTING_FORECAST:"2023-05-10",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ4.3×11.4",WEIGHT_UNIT:59,WEIGHT_ATT:59,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"Himile",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"Truck Mounted Crane",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0303",DESCRIPTION:"CG 4TH STAGE DISCHARGE DRUM",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-07",LOA_ISSUE_PLAN:"2022-08-22",LOA_ISSUE_FORECAST:"2022-08-18",LOA_ISSUE_ACTUAL:"2022-08-17",PO_ISSUE_PLAN:"2022-10-03",PO_ISSUE_FORECAST:"2022-09-29",PO_ISSUE_ACTUAL:"2022-09-20",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-23",RAS_ROS:"2023-05-01"},
    // {NO:215,PACKAGE:"P1",AREA:"OR",TAG_NO:"2535D005",TAG_DESCR:"GAS DRYER FEED KO DRUM",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-01",DELIVERY_FORECAST:"2023-05-01",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-10",LIFTING_PLAN_WK:"12-May-23",LIFTING_FORECAST:"2023-05-10",STATUS:"To go",LOCATION:"热区地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅4.1×19.67",WEIGHT_UNIT:87,WEIGHT_ATT:87,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"北京精诚",DRAWING:"有工程图 Engineering dwg issued，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-2",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0304",DESCRIPTION:"GAS DRYER FEED KO DRUM",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-09",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-08-17",LOA_ISSUE_ACTUAL:"2022-08-13",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-09-28",PO_ISSUE_ACTUAL:"2022-08-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-15",RAS_ROS:"2023-05-01"},
    // {NO:216,PACKAGE:"P1",AREA:"OR",TAG_NO:"2560D030",TAG_DESCR:"C3 REFRIG 3RD STAGE SUCTION DRUM",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-10",DELIVERY_FORECAST:"2023-05-10",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-11",LIFTING_PLAN_WK:"12-May-23",LIFTING_FORECAST:"2023-05-11",STATUS:"To go",LOCATION:"冷区地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅3.9×21.0",WEIGHT_UNIT:86.5,WEIGHT_ATT:186.5,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"Himile",DRAWING:"有工程图 Engineering dwg issued",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-2",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0301",DESCRIPTION:"C3 REFRIG 3RD STAGE SUCTION DRUM",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-07",LOA_ISSUE_PLAN:"2022-08-22",LOA_ISSUE_FORECAST:"2022-08-18",LOA_ISSUE_ACTUAL:"2022-08-17",PO_ISSUE_PLAN:"2022-10-03",PO_ISSUE_FORECAST:"2022-09-29",PO_ISSUE_ACTUAL:"2022-09-20",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-26",RAS_ROS:"2023-05-01"},
    // {NO:217,PACKAGE:"P1",AREA:"OR",TAG_NO:"2560D010",TAG_DESCR:"C3 REFRIG 1ST STAGE SUCTION DRUM",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-10",DELIVERY_FORECAST:"2023-05-10",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-13",LIFTING_PLAN_WK:"19-May-23",LIFTING_FORECAST:"2023-05-13",STATUS:"To go",LOCATION:"冷区地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅5.4×21.4",WEIGHT_UNIT:104,WEIGHT_ATT:170,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"Himile",DRAWING:"有工程图 Engineering dwg issued",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-2",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0301",DESCRIPTION:"C3 REFRIG 1ST STAGE SUCTION DRUM",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-07",LOA_ISSUE_PLAN:"2022-08-22",LOA_ISSUE_FORECAST:"2022-08-18",LOA_ISSUE_ACTUAL:"2022-08-17",PO_ISSUE_PLAN:"2022-10-03",PO_ISSUE_FORECAST:"2022-09-29",PO_ISSUE_ACTUAL:"2022-09-20",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-26",RAS_ROS:"2023-05-01"},
    // {NO:218,PACKAGE:"P1",AREA:"OR",TAG_NO:"2536E011A",TAG_DESCR:"HP DEPROPANIZER STEAM REBOILER",TAG_TYPE:"V",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-13",LIFTING_PLAN_WK:"19-May-23",LIFTING_FORECAST:"2023-05-13",STATUS:"To go",LOCATION:"3B框架 +8.5m",SHIPMENT_NO:"TBD",DIMENSION_M:"φ1.6×10.404",WEIGHT_UNIT:42,WEIGHT_ATT:84,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"无锡鼎邦",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"Truck Mounted Crane",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0408",DESCRIPTION:"HP DEPROPANIZER STEAM REBOILER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-05",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-16",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-05",RAS_ROS:"2023-04-15"},
    // {NO:219,PACKAGE:"P1",AREA:"OR",TAG_NO:"2536E011B",TAG_DESCR:"HP DEPROPANIZER STEAM REBOILER",TAG_TYPE:"V",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-13",LIFTING_PLAN_WK:"19-May-23",LIFTING_FORECAST:"2023-05-13",STATUS:"To go",LOCATION:"3B框架 +8.5m",SHIPMENT_NO:"TBD",DIMENSION_M:"φ1.6×10.404",WEIGHT_UNIT:42,WEIGHT_ATT:84,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"无锡鼎邦",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"Truck Mounted Crane",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0408",DESCRIPTION:"HP DEPROPANIZER STEAM REBOILER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-05",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-16",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-05",RAS_ROS:"2023-04-15"},
    // {NO:220,PACKAGE:"P2",AREA:"PP",TAG_NO:"6280BN007",TAG_DESCR:"Scrap Pellet Silo",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-30",DELIVERY_FORECAST:"2023-05-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-13",LIFTING_PLAN_WK:"19-May-23",LIFTING_FORECAST:"2023-05-13",STATUS:"To go",LOCATION:"框架 EL+49.8",SHIPMENT_NO:"TBD",DIMENSION_M:"φ6.0×24.2",WEIGHT_UNIT:28,WEIGHT_ATT:28,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1219-EQ-R-E-SL06",DESCRIPTION:"Scrap Pellet Silo",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-25",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",RAS_ROS:"2023-07-30"},
    // {NO:221,PACKAGE:"P2",AREA:"PP",TAG_NO:"6180BN007",TAG_DESCR:"Scrap Pellet Silo",TAG_TYPE:"V",DELIVERY_PLAN:"2023-07-20",DELIVERY_FORECAST:"2023-07-20",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-13",LIFTING_PLAN_WK:"19-May-23",LIFTING_FORECAST:"2023-05-13",STATUS:"To go",LOCATION:"框架 EL+49.8",SHIPMENT_NO:"TBD",DIMENSION_M:"φ6.0×24.2",WEIGHT_UNIT:28,WEIGHT_ATT:28,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1219-EQ-R-E-SL06",DESCRIPTION:"Scrap Pellet Silo",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-25",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",RAS_ROS:"2023-07-30"},
    // {NO:222,PACKAGE:"P2",AREA:"PP",TAG_NO:"6180BN013",TAG_DESCR:"Prime Pellet Silo",TAG_TYPE:"V",DELIVERY_PLAN:"2023-07-20",DELIVERY_FORECAST:"2023-07-20",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-13",LIFTING_PLAN_WK:"19-May-23",LIFTING_FORECAST:"2023-05-13",STATUS:"To go",LOCATION:"框架 EL+49.8",SHIPMENT_NO:"TBD",DIMENSION_M:"φ6.0×24.2",WEIGHT_UNIT:28,WEIGHT_ATT:28,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1219-EQ-R-E-SL06",DESCRIPTION:"Prime Pellet Silo",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-25",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",RAS_ROS:"2023-07-30"},
    // {NO:223,PACKAGE:"P2",AREA:"PP",TAG_NO:"6180BN012",TAG_DESCR:"Prime Pellet Silo",TAG_TYPE:"V",DELIVERY_PLAN:"2023-07-20",DELIVERY_FORECAST:"2023-07-20",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-14",LIFTING_PLAN_WK:"19-May-23",LIFTING_FORECAST:"2023-05-14",STATUS:"To go",LOCATION:"框架 EL+49.8",SHIPMENT_NO:"TBD",DIMENSION_M:"φ6.0×24.2",WEIGHT_UNIT:28,WEIGHT_ATT:28,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1219-EQ-R-E-SL06",DESCRIPTION:"Prime Pellet Silo",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-25",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",RAS_ROS:"2023-07-30"},
    // {NO:224,PACKAGE:"P2",AREA:"PP",TAG_NO:"6280BN012",TAG_DESCR:"Prime Pellet Silo",TAG_TYPE:"V",DELIVERY_PLAN:"2023-07-20",DELIVERY_FORECAST:"2023-07-20",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-14",LIFTING_PLAN_WK:"19-May-23",LIFTING_FORECAST:"2023-05-14",STATUS:"To go",LOCATION:"框架 EL+49.8",SHIPMENT_NO:"TBD",DIMENSION_M:"φ6.0×24.2",WEIGHT_UNIT:28,WEIGHT_ATT:28,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1219-EQ-R-E-SL06",DESCRIPTION:"Prime Pellet Silo",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-25",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",RAS_ROS:"2023-07-30"},
    // {NO:225,PACKAGE:"P1",AREA:"OR",TAG_NO:"2535E055",TAG_DESCR:"REGENERATION GAS EFFLUENT COOLER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-15",LIFTING_PLAN_WK:"19-May-23",LIFTING_FORECAST:"2023-05-15",STATUS:"To go",LOCATION:"3E",SHIPMENT_NO:"TBD",DIMENSION_M:"φ1.8×11.338",WEIGHT_UNIT:53,WEIGHT_ATT:53,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"山东美陵",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0409",DESCRIPTION:"REGENERATION GAS EFFLUENT COOLER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-18",RAS_ROS:"2023-04-15"},
    // {NO:226,PACKAGE:"P1",AREA:"OR",TAG_NO:"2554E015A",TAG_DESCR:"C3 RECTIFIER CONDENSERS",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-15",DELIVERY_FORECAST:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-15",LIFTING_PLAN_WK:"19-May-23",LIFTING_FORECAST:"2023-05-15",STATUS:"To go",LOCATION:"热区地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.2x14",WEIGHT_UNIT:88,WEIGHT_ATT:88,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"无锡鼎邦 Wuxi Dingbang",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"无图 No engineering dwg，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0404",DESCRIPTION:"C3 RECTIFIER CONDENSERSx000D",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-03-08",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-03-08",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-01-29",LOA_ISSUE_PLAN:"2022-06-29",LOA_ISSUE_FORECAST:"2022-06-23",LOA_ISSUE_ACTUAL:"2022-06-17",PO_ISSUE_PLAN:"2022-08-10",PO_ISSUE_FORECAST:"2022-08-04",PO_ISSUE_ACTUAL:"2022-07-27",DELIVERY_TO_JOB_SITE_PLAN:"2023-02-27",RAS_ROS:"2023-04-15"},
    // {NO:227,PACKAGE:"P1",AREA:"OR",TAG_NO:"2554E015B",TAG_DESCR:"C3 RECTIFIER CONDENSERS",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-15",DELIVERY_FORECAST:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-16",LIFTING_PLAN_WK:"19-May-23",LIFTING_FORECAST:"2023-05-16",STATUS:"To go",LOCATION:"热区地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.2x15",WEIGHT_UNIT:88,WEIGHT_ATT:88,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"无锡鼎邦 Wuxi Dingbang",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"无图 No engineering dwg，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0404",DESCRIPTION:"C3 RECTIFIER CONDENSERSx000D",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-03-08",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-03-08",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-01-29",LOA_ISSUE_PLAN:"2022-06-29",LOA_ISSUE_FORECAST:"2022-06-23",LOA_ISSUE_ACTUAL:"2022-06-17",PO_ISSUE_PLAN:"2022-08-10",PO_ISSUE_FORECAST:"2022-08-04",PO_ISSUE_ACTUAL:"2022-07-27",DELIVERY_TO_JOB_SITE_PLAN:"2023-02-27",RAS_ROS:"2023-04-15"},
    // {NO:228,PACKAGE:"P1",AREA:"OR",TAG_NO:"2554E015C",TAG_DESCR:"C3 RECTIFIER CONDENSERS",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-15",DELIVERY_FORECAST:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-16",LIFTING_PLAN_WK:"19-May-23",LIFTING_FORECAST:"2023-05-16",STATUS:"To go",LOCATION:"热区地面",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.2x16",WEIGHT_UNIT:88,WEIGHT_ATT:88,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"无锡鼎邦 Wuxi Dingbang",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"无图 No engineering dwg，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0404",DESCRIPTION:"C3 RECTIFIER CONDENSERSx000D",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-03-08",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-03-08",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-01-29",LOA_ISSUE_PLAN:"2022-06-29",LOA_ISSUE_FORECAST:"2022-06-23",LOA_ISSUE_ACTUAL:"2022-06-17",PO_ISSUE_PLAN:"2022-08-10",PO_ISSUE_FORECAST:"2022-08-04",PO_ISSUE_ACTUAL:"2022-07-27",DELIVERY_TO_JOB_SITE_PLAN:"2023-02-27",RAS_ROS:"2023-04-15"},
    // {NO:229,PACKAGE:"P1",AREA:"OR",TAG_NO:"2536D015",TAG_DESCR:"HP DEPROPANIZER REFLUX DRUM",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-05",DELIVERY_FORECAST:"2023-05-05",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-17",LIFTING_PLAN_WK:"19-May-23",LIFTING_FORECAST:"2023-05-17",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ3.2×16.6",WEIGHT_UNIT:63,WEIGHT_ATT:63,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"天津瀚洋",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0302",DESCRIPTION:"HP DEPROPANIZER REFLUX DRUM",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-07",LOA_ISSUE_PLAN:"2022-08-22",LOA_ISSUE_FORECAST:"2022-08-18",LOA_ISSUE_ACTUAL:"2022-08-17",PO_ISSUE_PLAN:"2022-10-03",PO_ISSUE_FORECAST:"2022-09-29",PO_ISSUE_ACTUAL:"2022-09-20",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-22",RAS_ROS:"2023-05-01"},
    // {NO:230,PACKAGE:"P1",AREA:"BTX",TAG_NO:"3820T010",TAG_DESCR:"BENZENE COLUMN",TAG_TYPE:"V",DELIVERY_PLAN:"2023-04-24",DELIVERY_FORECAST:"2023-04-24",DRESSUP_REQ:"Y",DRESSUP_PROG:0,LIFTING_PLAN:"2023-05-17",LIFTING_PLAN_WK:"19-May-23",LIFTING_FORECAST:"2023-05-17",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.8x61.7",WEIGHT_UNIT:125,WEIGHT_ATT:125,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"QDRK",DRAWING:"有工程图 Engineering dwg issued",CRANE_MAIN_FILTER:"800",CRANE_MAIN:"800",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1113-EQ-R-E-0101",DESCRIPTION:"BENZENE COLUMN",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-12-16",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-12-16",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-15",LOA_ISSUE_PLAN:"2022-04-07",LOA_ISSUE_FORECAST:"2022-05-19",LOA_ISSUE_ACTUAL:"2022-05-16",PO_ISSUE_PLAN:"2022-05-19",PO_ISSUE_FORECAST:"2022-06-30",PO_ISSUE_ACTUAL:"2022-08-01",DELIVERY_TO_JOB_SITE_PLAN:"2022-12-10",RAS_ROS:"2023-02-15"},
    // {NO:231,PACKAGE:"P1",AREA:"OR",TAG_NO:"3411E010",TAG_DESCR:"BUTENE-1 SPLITTER REBOILER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-18",LIFTING_PLAN_WK:"19-May-23",LIFTING_FORECAST:"2023-05-18",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.2×9.857",WEIGHT_UNIT:68.8,WEIGHT_ATT:68.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"湖北长江",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0410",DESCRIPTION:"BUTENE1 SPLITTER REBOILERx000D",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-06-24",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-08-05",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-19",RAS_ROS:"2023-04-15"},
    // {NO:232,PACKAGE:"P1",AREA:"OR",TAG_NO:"3410E015",TAG_DESCR:"C4 SPLITTER CONDENSER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-18",LIFTING_PLAN_WK:"19-May-23",LIFTING_FORECAST:"2023-05-18",STATUS:"To go",LOCATION:"5A地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.0×11.711",WEIGHT_UNIT:52,WEIGHT_ATT:52,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"湖北长江",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0410",DESCRIPTION:"C4 SPLITTER CONDENSERx000D",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-19",RAS_ROS:"2023-04-15"},
    // {NO:233,PACKAGE:"P1",AREA:"BD",TAG_NO:"3013E045A",TAG_DESCR:"BUTADIENE COLUMN CONDENSER",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-17",DELIVERY_FORECAST:"2023-05-17",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-18",LIFTING_PLAN_WK:"19-May-23",LIFTING_FORECAST:"2023-05-18",STATUS:"To go",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.1×18.016",WEIGHT_UNIT:87.3,WEIGHT_ATT:174.6,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"大庆石油 Daqing Petroleum",DESIGN_OF_LIFTING_LUG:"厂家有吊耳待确定 Manuf. designed. to be confirmed.",DRAWING:"有初版装配图 shop assembly dwg IFR",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-2",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1277-EQ-R-E-SL01",DESCRIPTION:"BUTADIENE COLUMN CONDENSER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-12-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-12-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-17",LOA_ISSUE_PLAN:"2022-04-28",LOA_ISSUE_FORECAST:"2022-04-03",LOA_ISSUE_ACTUAL:"2022-04-11",PO_ISSUE_PLAN:"2022-06-09",PO_ISSUE_FORECAST:"2022-06-02",PO_ISSUE_ACTUAL:"2022-05-20",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-05-17"},
    // {NO:234,PACKAGE:"P1",AREA:"BD",TAG_NO:"3013E045B",TAG_DESCR:"BUTADIENE COLUMN CONDENSER",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-17",DELIVERY_FORECAST:"2023-05-17",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-18",LIFTING_PLAN_WK:"19-May-23",LIFTING_FORECAST:"2023-05-18",STATUS:"To go",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.1×18.016",WEIGHT_UNIT:87.3,WEIGHT_ATT:174.6,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"大庆石油 Daqing Petroleum",DESIGN_OF_LIFTING_LUG:"厂家有吊耳待确定 Manuf. designed. to be confirmed.",DRAWING:"有初版装配图 shop assembly dwg IFR",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-2",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1277-EQ-R-E-SL01",DESCRIPTION:"BUTADIENE COLUMN CONDENSER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-12-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-12-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-17",LOA_ISSUE_PLAN:"2022-04-28",LOA_ISSUE_FORECAST:"2022-04-03",LOA_ISSUE_ACTUAL:"2022-04-11",PO_ISSUE_PLAN:"2022-06-09",PO_ISSUE_FORECAST:"2022-06-02",PO_ISSUE_ACTUAL:"2022-05-20",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-05-17"},
    // {NO:235,PACKAGE:"P1",AREA:"DIB",TAG_NO:"3297D001",TAG_DESCR:"DIB SLOPS/KONCK OUT DRUM",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-01",DELIVERY_FORECAST:"2023-05-01",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-19",LIFTING_PLAN_WK:"19-May-23",LIFTING_FORECAST:"2023-05-19",STATUS:"To go",LOCATION:"1E框架",SHIPMENT_NO:"TBD",DIMENSION_M:"φ5.0×12.5(T.T)",WEIGHT_UNIT:55,WEIGHT_ATT:55,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"天津瀚洋",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1362-EQ-R-E-0301",DESCRIPTION:"CD REACTION COLUMN REBOILER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-01-26",LOA_ISSUE_PLAN:"2022-08-13",LOA_ISSUE_FORECAST:"2022-08-18",LOA_ISSUE_ACTUAL:"2022-08-16",PO_ISSUE_PLAN:"2022-09-24",PO_ISSUE_FORECAST:"2022-09-29",PO_ISSUE_ACTUAL:"2022-09-20",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-17",RAS_ROS:"2023-05-15"},
    // {NO:236,PACKAGE:"P1",AREA:"OR",TAG_NO:"2580PA001",TAG_DESCR:"Deaerator",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-01",DELIVERY_FORECAST:"2023-05-01",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-19",LIFTING_PLAN_WK:"19-May-23",LIFTING_FORECAST:"2023-05-19",STATUS:"To go",LOCATION:"蒸汽框架",SHIPMENT_NO:"TBD",DIMENSION_M:"φ4.2x25",WEIGHT_UNIT:80,WEIGHT_ATT:80,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"青岛畅隆 Qingdao Changlong",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-TM-R-E-0901",DESCRIPTION:"DEAERATOR",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-05-08",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-05-08",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-03",LOA_ISSUE_PLAN:"2022-08-26",LOA_ISSUE_FORECAST:"2022-06-30",LOA_ISSUE_ACTUAL:"2022-06-29",PO_ISSUE_PLAN:"2022-10-07",PO_ISSUE_FORECAST:"2022-08-11",PO_ISSUE_ACTUAL:"2022-08-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-31",RAS_ROS:"2023-05-30"},
    // {NO:237,PACKAGE:"P1",AREA:"OR",TAG_NO:"2560E051",TAG_DESCR:"C3R COMPRESSOR SURFACE CONDENSER",TAG_TYPE:"H",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-19",LIFTING_PLAN_WK:"19-May-23",LIFTING_FORECAST:"2023-05-19",STATUS:"To go",LOCATION:"4B结构",SHIPMENT_NO:"TBD",LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"TBD",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-2",TRANSPORT_COMPANY:"Others"},
    // {NO:238,PACKAGE:"P1",AREA:"OR",TAG_NO:"2565E051",TAG_DESCR:"C2R COMPRESSOR SURFACE CONDENSER",TAG_TYPE:"H",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-20",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-05-20",STATUS:"To go",LOCATION:"4B结构",SHIPMENT_NO:"TBD",LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"TBD",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-2",TRANSPORT_COMPANY:"Others"},
    // {NO:239,PACKAGE:"P1",AREA:"OR",TAG_NO:"2540E015",TAG_DESCR:"DEMETH PREFRAC CONDENSER",TAG_TYPE:"V",DELIVERY_PLAN:"2023-01-15",DELIVERY_FORECAST:"2023-01-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-20",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-05-20",STATUS:"To go",LOCATION:"2540T001顶部",SHIPMENT_NO:"TBD",WEIGHT_ATT:0,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"杭氧",DESIGN_OF_LIFTING_LUG:"厂家已完成吊耳图设计",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"Truck Mounted Crane",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-LL02",DESCRIPTION:"CIK",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-08-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-09-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-09-26",LOA_ISSUE_PLAN:"2022-01-04",LOA_ISSUE_FORECAST:"2021-12-26",LOA_ISSUE_ACTUAL:"2021-12-24",PO_ISSUE_PLAN:"2022-02-15",PO_ISSUE_FORECAST:"2022-02-06",PO_ISSUE_ACTUAL:"2022-01-15",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-07",RAS_ROS:"2023-05-30"},
    // {NO:240,PACKAGE:"P1",AREA:"OR",TAG_NO:"1011F001",TAG_DESCR:"Convection module 1",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-19",DELIVERY_FORECAST:"2023-05-26",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-20",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-06-14",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:133,WEIGHT_ATT:133,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"宁波连通 NBLT",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:241,PACKAGE:"P1",AREA:"OR",TAG_NO:"1011F001",TAG_DESCR:"Convection module 2",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-19",DELIVERY_FORECAST:"2023-05-26",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-20",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-06-14",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:119,WEIGHT_ATT:119,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"宁波连通 NBLT",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:242,PACKAGE:"P1",AREA:"OR",TAG_NO:"1011F001",TAG_DESCR:"Convection module 3",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-19",DELIVERY_FORECAST:"2023-05-26",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-20",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-06-14",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:128,WEIGHT_ATT:128,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"宁波连通 NBLT",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:243,PACKAGE:"P1",AREA:"OR",TAG_NO:"1011F001",TAG_DESCR:"Convection module 4",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-19",DELIVERY_FORECAST:"2023-05-26",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-20",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-06-14",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:81,WEIGHT_ATT:81,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"宁波连通 NBLT",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:244,PACKAGE:"P1",AREA:"OR",TAG_NO:"1011F001",TAG_DESCR:"Convection module 5",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-19",DELIVERY_FORECAST:"2023-05-26",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-20",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-06-14",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:55,WEIGHT_ATT:55,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"宁波连通 NBLT",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:245,PACKAGE:"P1",AREA:"OR",TAG_NO:"1011F001",TAG_DESCR:"Convection module 6",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-19",DELIVERY_FORECAST:"2023-05-26",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-20",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-06-14",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:159,WEIGHT_ATT:159,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"宁波连通 NBLT",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:246,PACKAGE:"P1",AREA:"OR",TAG_NO:"1011F001",TAG_DESCR:"Convection module 7",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-19",DELIVERY_FORECAST:"2023-05-26",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-20",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-06-14",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:177,WEIGHT_ATT:177,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"宁波连通 NBLT",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:247,PACKAGE:"P1",AREA:"BTX",TAG_NO:"3820T020",TAG_DESCR:"TOLUENE COLUMN",TAG_TYPE:"V",DELIVERY_PLAN:"2023-04-24",DELIVERY_FORECAST:"2023-04-24",DRESSUP_REQ:"Y",DRESSUP_PROG:0,LIFTING_PLAN:"2023-05-20",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-05-20",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2x56.3",WEIGHT_UNIT:105,WEIGHT_ATT:105,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"QDRK",DRAWING:"有工程图 Engineering dwg issued",CRANE_MAIN_FILTER:"800",CRANE_MAIN:"800",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1113-EQ-R-E-0101",DESCRIPTION:"TOLUENE COLUMN",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-12-16",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-12-16",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-15",LOA_ISSUE_PLAN:"2022-04-07",LOA_ISSUE_FORECAST:"2022-05-19",LOA_ISSUE_ACTUAL:"2022-05-16",PO_ISSUE_PLAN:"2022-05-19",PO_ISSUE_FORECAST:"2022-06-30",PO_ISSUE_ACTUAL:"2022-08-01",DELIVERY_TO_JOB_SITE_PLAN:"2022-12-10",RAS_ROS:"2023-02-15"},
    // {NO:248,PACKAGE:"P1",AREA:"OR",TAG_NO:"2535E053",TAG_DESCR:"REGENERATION GAS FEED HEATER",TAG_TYPE:"V",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-21",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-05-21",STATUS:"To go",LOCATION:"3E",SHIPMENT_NO:"TBD",DIMENSION_M:"φ1.6×7.705",WEIGHT_UNIT:41,WEIGHT_ATT:41,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"无锡鼎邦换",DRAWING:"有工程图",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0408",DESCRIPTION:"REGENERATION GAS FEED HEATER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-05",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-16",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-05",RAS_ROS:"2023-04-15"},
    // {NO:249,PACKAGE:"P1",AREA:"OR",TAG_NO:"2530E051",TAG_DESCR:"CGC SURFACE CONDENSER",TAG_TYPE:"H",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-21",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-05-21",STATUS:"To go",LOCATION:"3A结构",SHIPMENT_NO:"TBD",LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"TBD",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-2",TRANSPORT_COMPANY:"Others"},
    // {NO:250,PACKAGE:"P1",AREA:"OR",TAG_NO:"2534E035",TAG_DESCR:"CAUSTIC TOWER FEED HEATER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-22",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-05-22",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ1.8×11.274",WEIGHT_UNIT:47.5,WEIGHT_ATT:47.5,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:" 江南锅炉",DRAWING:"有工程图",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0407",DESCRIPTION:"CAUSTIC TOWER FEED HEATER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-05",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-05",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-14",LOA_ISSUE_PLAN:"2022-08-19",LOA_ISSUE_FORECAST:"2022-07-14",LOA_ISSUE_ACTUAL:"2022-07-08",PO_ISSUE_PLAN:"2022-09-30",PO_ISSUE_FORECAST:"2022-08-25",PO_ISSUE_ACTUAL:"2022-08-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-23",RAS_ROS:"2023-04-15"},
    // {NO:251,PACKAGE:"P1",AREA:"OR",TAG_NO:"1012F001",TAG_DESCR:"Convection module 1",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-15",DELIVERY_FORECAST:"2023-05-17",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-22",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-06-16",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:133,WEIGHT_ATT:133,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"茂重 Guangdong Maozhong",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:252,PACKAGE:"P1",AREA:"OR",TAG_NO:"1012F001",TAG_DESCR:"Convection module 2",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-15",DELIVERY_FORECAST:"2023-05-17",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-22",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-06-16",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:166,WEIGHT_ATT:166,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"茂重 Guangdong Maozhong",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:253,PACKAGE:"P1",AREA:"OR",TAG_NO:"1012F001",TAG_DESCR:"Convection module 3",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-15",DELIVERY_FORECAST:"2023-05-17",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-22",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-06-16",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:114,WEIGHT_ATT:114,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"茂重 Guangdong Maozhong",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:254,PACKAGE:"P1",AREA:"OR",TAG_NO:"1012F001",TAG_DESCR:"Convection module 4",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-15",DELIVERY_FORECAST:"2023-05-17",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-22",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-06-16",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:53,WEIGHT_ATT:53,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"茂重 Guangdong Maozhong",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:255,PACKAGE:"P1",AREA:"OR",TAG_NO:"1012F001",TAG_DESCR:"Convection module 5",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-15",DELIVERY_FORECAST:"2023-05-17",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-22",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-06-16",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:142,WEIGHT_ATT:142,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"茂重 Guangdong Maozhong",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:256,PACKAGE:"P1",AREA:"OR",TAG_NO:"1012F001",TAG_DESCR:"Convection module 6",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-15",DELIVERY_FORECAST:"2023-05-17",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-22",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-06-16",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:155,WEIGHT_ATT:155,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"茂重 Guangdong Maozhong",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:257,PACKAGE:"P1",AREA:"OR",TAG_NO:"2530E040A",TAG_DESCR:"CG 4TH STAGE AFTERCOOLER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-23",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-05-23",STATUS:"To go",LOCATION:"压缩地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ1.9×9.763",WEIGHT_UNIT:60,WEIGHT_ATT:240,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:" 江南锅炉",DRAWING:"有工程图",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0407",DESCRIPTION:"CG 4TH STAGE AFTERCOOLER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-05",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-05",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-14",LOA_ISSUE_PLAN:"2022-08-19",LOA_ISSUE_FORECAST:"2022-07-14",LOA_ISSUE_ACTUAL:"2022-07-08",PO_ISSUE_PLAN:"2022-09-30",PO_ISSUE_FORECAST:"2022-08-25",PO_ISSUE_ACTUAL:"2022-08-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-23",RAS_ROS:"2023-04-15"},
    // {NO:258,PACKAGE:"P1",AREA:"OR",TAG_NO:"2530E040B",TAG_DESCR:"CG 4TH STAGE AFTERCOOLER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-23",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-05-23",STATUS:"To go",LOCATION:"压缩地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ1.9×9.763",WEIGHT_UNIT:60,WEIGHT_ATT:240,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:" 江南锅炉",DRAWING:"有工程图",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0407",DESCRIPTION:"CG 4TH STAGE AFTERCOOLER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-05",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-05",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-14",LOA_ISSUE_PLAN:"2022-08-19",LOA_ISSUE_FORECAST:"2022-07-14",LOA_ISSUE_ACTUAL:"2022-07-08",PO_ISSUE_PLAN:"2022-09-30",PO_ISSUE_FORECAST:"2022-08-25",PO_ISSUE_ACTUAL:"2022-08-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-23",RAS_ROS:"2023-04-15"},
    // {NO:259,PACKAGE:"P1",AREA:"OR",TAG_NO:"2530E040C",TAG_DESCR:"CG 4TH STAGE AFTERCOOLER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-23",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-05-23",STATUS:"To go",LOCATION:"压缩地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ1.9×9.763",WEIGHT_UNIT:60,WEIGHT_ATT:240,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:" 江南锅炉",DRAWING:"有工程图",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0407",DESCRIPTION:"CG 4TH STAGE AFTERCOOLER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-05",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-05",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-14",LOA_ISSUE_PLAN:"2022-08-19",LOA_ISSUE_FORECAST:"2022-07-14",LOA_ISSUE_ACTUAL:"2022-07-08",PO_ISSUE_PLAN:"2022-09-30",PO_ISSUE_FORECAST:"2022-08-25",PO_ISSUE_ACTUAL:"2022-08-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-23",RAS_ROS:"2023-04-15"},
    // {NO:260,PACKAGE:"P1",AREA:"OR",TAG_NO:"2530E040D",TAG_DESCR:"CG 4TH STAGE AFTERCOOLER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-23",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-05-23",STATUS:"To go",LOCATION:"压缩地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ1.9×9.763",WEIGHT_UNIT:60,WEIGHT_ATT:240,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:" 江南锅炉",DRAWING:"有工程图",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0407",DESCRIPTION:"CG 4TH STAGE AFTERCOOLER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-05",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-05",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-14",LOA_ISSUE_PLAN:"2022-08-19",LOA_ISSUE_FORECAST:"2022-07-14",LOA_ISSUE_ACTUAL:"2022-07-08",PO_ISSUE_PLAN:"2022-09-30",PO_ISSUE_FORECAST:"2022-08-25",PO_ISSUE_ACTUAL:"2022-08-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-23",RAS_ROS:"2023-04-15"},
    // {NO:261,PACKAGE:"P1",AREA:"OR",TAG_NO:"2535DR060",TAG_DESCR:"MERCURY ADSORBER",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-09",DELIVERY_FORECAST:"2023-05-09",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-05-25",STATUS:"To go",LOCATION:"压缩地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ5.7×16.96",WEIGHT_UNIT:71,WEIGHT_ATT:71,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"Himile",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others"},
    // {NO:262,PACKAGE:"P1",AREA:"OR",TAG_NO:"2530E030A",TAG_DESCR:"CGC 3st STAGE AFTERCOOLERS",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-05-25",STATUS:"To go",LOCATION:"压缩地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.1×9.289",WEIGHT_UNIT:58,WEIGHT_ATT:232,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:" 江南锅炉",DRAWING:"有工程图",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0407",DESCRIPTION:"CG 3RD STAGE AFTERCOOLERS",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-05",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-05",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-14",LOA_ISSUE_PLAN:"2022-08-19",LOA_ISSUE_FORECAST:"2022-07-14",LOA_ISSUE_ACTUAL:"2022-07-08",PO_ISSUE_PLAN:"2022-09-30",PO_ISSUE_FORECAST:"2022-08-25",PO_ISSUE_ACTUAL:"2022-08-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-23",RAS_ROS:"2023-04-15"},
    // {NO:263,PACKAGE:"P1",AREA:"OR",TAG_NO:"2530E030B",TAG_DESCR:"CGC 3st STAGE AFTERCOOLERS",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-05-25",STATUS:"To go",LOCATION:"压缩地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.1×9.289",WEIGHT_UNIT:58,WEIGHT_ATT:232,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:" 江南锅炉",DRAWING:"有工程图",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0407",DESCRIPTION:"CG 3RD STAGE AFTERCOOLERS",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-05",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-05",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-14",LOA_ISSUE_PLAN:"2022-08-19",LOA_ISSUE_FORECAST:"2022-07-14",LOA_ISSUE_ACTUAL:"2022-07-08",PO_ISSUE_PLAN:"2022-09-30",PO_ISSUE_FORECAST:"2022-08-25",PO_ISSUE_ACTUAL:"2022-08-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-23",RAS_ROS:"2023-04-15"},
    // {NO:264,PACKAGE:"P1",AREA:"OR",TAG_NO:"2530E030C",TAG_DESCR:"CGC 3st STAGE AFTERCOOLERS",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-05-25",STATUS:"To go",LOCATION:"压缩地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.1×9.289",WEIGHT_UNIT:58,WEIGHT_ATT:232,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:" 江南锅炉",DRAWING:"有工程图",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0407",DESCRIPTION:"CG 3RD STAGE AFTERCOOLERS",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-05",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-05",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-14",LOA_ISSUE_PLAN:"2022-08-19",LOA_ISSUE_FORECAST:"2022-07-14",LOA_ISSUE_ACTUAL:"2022-07-08",PO_ISSUE_PLAN:"2022-09-30",PO_ISSUE_FORECAST:"2022-08-25",PO_ISSUE_ACTUAL:"2022-08-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-23",RAS_ROS:"2023-04-15"},
    // {NO:265,PACKAGE:"P1",AREA:"OR",TAG_NO:"2530E030D",TAG_DESCR:"CGC 3st STAGE AFTERCOOLERS",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-05-25",STATUS:"To go",LOCATION:"压缩地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.1×9.289",WEIGHT_UNIT:58,WEIGHT_ATT:232,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:" 江南锅炉",DRAWING:"有工程图",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0407",DESCRIPTION:"CG 3RD STAGE AFTERCOOLERS",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-05",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-05",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-14",LOA_ISSUE_PLAN:"2022-08-19",LOA_ISSUE_FORECAST:"2022-07-14",LOA_ISSUE_ACTUAL:"2022-07-08",PO_ISSUE_PLAN:"2022-09-30",PO_ISSUE_FORECAST:"2022-08-25",PO_ISSUE_ACTUAL:"2022-08-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-23",RAS_ROS:"2023-04-15"},
    // {NO:266,PACKAGE:"P1",AREA:"OR",TAG_NO:"2540E010",TAG_DESCR:"DEMETH PREFRAC REBOILER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-05-25",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ1.9×10.337",WEIGHT_UNIT:55,WEIGHT_ATT:55,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"无锡鼎邦",DRAWING:"有工程图",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0408",DESCRIPTION:"DEMETH PREFRAC REBOILER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-05",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-16",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-05",RAS_ROS:"2023-04-15"},
    // {NO:267,PACKAGE:"P1",AREA:"OR",TAG_NO:"1012E111",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:268,PACKAGE:"P1",AREA:"OR",TAG_NO:"1012E121",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:269,PACKAGE:"P1",AREA:"OR",TAG_NO:"1012E131",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:270,PACKAGE:"P1",AREA:"OR",TAG_NO:"1012E141",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:271,PACKAGE:"P1",AREA:"OR",TAG_NO:"1012E151",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:272,PACKAGE:"P1",AREA:"OR",TAG_NO:"1012E161",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:273,PACKAGE:"P1",AREA:"OR",TAG_NO:"1012E171",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:274,PACKAGE:"P1",AREA:"OR",TAG_NO:"1012E181",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:275,PACKAGE:"P1",AREA:"OR",TAG_NO:"1011E111",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.72×0.65",WEIGHT_UNIT:39.5,WEIGHT_ATT:316,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:276,PACKAGE:"P1",AREA:"OR",TAG_NO:"1011E121",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.72×0.65",WEIGHT_UNIT:39.5,WEIGHT_ATT:316,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:277,PACKAGE:"P1",AREA:"OR",TAG_NO:"1011E131",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.72×0.65",WEIGHT_UNIT:39.5,WEIGHT_ATT:316,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:278,PACKAGE:"P1",AREA:"OR",TAG_NO:"1011E141",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.72×0.65",WEIGHT_UNIT:39.5,WEIGHT_ATT:316,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:279,PACKAGE:"P1",AREA:"OR",TAG_NO:"1011E151",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.72×0.65",WEIGHT_UNIT:39.5,WEIGHT_ATT:316,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:280,PACKAGE:"P1",AREA:"OR",TAG_NO:"1011E161",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.72×0.65",WEIGHT_UNIT:39.5,WEIGHT_ATT:316,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:281,PACKAGE:"P1",AREA:"OR",TAG_NO:"1011E171",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.72×0.65",WEIGHT_UNIT:39.5,WEIGHT_ATT:316,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:282,PACKAGE:"P1",AREA:"OR",TAG_NO:"1011E181",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.72×0.65",WEIGHT_UNIT:39.5,WEIGHT_ATT:316,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:283,PACKAGE:"P1",AREA:"OR",TAG_NO:"1013E111",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:284,PACKAGE:"P1",AREA:"OR",TAG_NO:"1013E121",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:285,PACKAGE:"P1",AREA:"OR",TAG_NO:"1013E131",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:286,PACKAGE:"P1",AREA:"OR",TAG_NO:"1013E141",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:287,PACKAGE:"P1",AREA:"OR",TAG_NO:"1013E151",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:288,PACKAGE:"P1",AREA:"OR",TAG_NO:"1013E161",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:289,PACKAGE:"P1",AREA:"OR",TAG_NO:"1013E171",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:290,PACKAGE:"P1",AREA:"OR",TAG_NO:"1013E181",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-04",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-16",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:291,PACKAGE:"P1",AREA:"OR",TAG_NO:"1013F001",TAG_DESCR:"Convection module 1",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-13",DELIVERY_FORECAST:"2023-05-26",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-06-18",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:133,WEIGHT_ATT:133,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"茂重 Guangdong Maozhong",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:292,PACKAGE:"P1",AREA:"OR",TAG_NO:"1013F001",TAG_DESCR:"Convection module 2",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-13",DELIVERY_FORECAST:"2023-05-26",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-06-18",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:166,WEIGHT_ATT:166,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"茂重 Guangdong Maozhong",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:293,PACKAGE:"P1",AREA:"OR",TAG_NO:"1013F001",TAG_DESCR:"Convection module 3",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-13",DELIVERY_FORECAST:"2023-05-26",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-06-18",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:114,WEIGHT_ATT:114,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"茂重 Guangdong Maozhong",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:294,PACKAGE:"P1",AREA:"OR",TAG_NO:"1013F001",TAG_DESCR:"Convection module 4",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-13",DELIVERY_FORECAST:"2023-05-26",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-06-18",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:53,WEIGHT_ATT:53,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"茂重 Guangdong Maozhong",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:295,PACKAGE:"P1",AREA:"OR",TAG_NO:"1013F001",TAG_DESCR:"Convection module 5",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-13",DELIVERY_FORECAST:"2023-05-26",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-06-18",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:142,WEIGHT_ATT:142,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"茂重 Guangdong Maozhong",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:296,PACKAGE:"P1",AREA:"OR",TAG_NO:"1013F001",TAG_DESCR:"Convection module 6",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-13",DELIVERY_FORECAST:"2023-05-26",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-06-18",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:155,WEIGHT_ATT:155,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"茂重 Guangdong Maozhong",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:297,PACKAGE:"P1",AREA:"OR",TAG_NO:"1016E111",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:298,PACKAGE:"P1",AREA:"OR",TAG_NO:"1016E121",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:299,PACKAGE:"P1",AREA:"OR",TAG_NO:"1016E131",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:300,PACKAGE:"P1",AREA:"OR",TAG_NO:"1016E141",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:301,PACKAGE:"P1",AREA:"OR",TAG_NO:"1016E151",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:302,PACKAGE:"P1",AREA:"OR",TAG_NO:"1016E161",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:303,PACKAGE:"P1",AREA:"OR",TAG_NO:"1016E171",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:304,PACKAGE:"P1",AREA:"OR",TAG_NO:"1016E181",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:305,PACKAGE:"P1",AREA:"OR",TAG_NO:"1017E111",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:306,PACKAGE:"P1",AREA:"OR",TAG_NO:"1017E121",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:307,PACKAGE:"P1",AREA:"OR",TAG_NO:"1017E131",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:308,PACKAGE:"P1",AREA:"OR",TAG_NO:"1017E141",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:309,PACKAGE:"P1",AREA:"OR",TAG_NO:"1017E151",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:310,PACKAGE:"P1",AREA:"OR",TAG_NO:"1017E161",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:311,PACKAGE:"P1",AREA:"OR",TAG_NO:"1017E171",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:312,PACKAGE:"P1",AREA:"OR",TAG_NO:"1017E181",TAG_DESCR:"Transfer line Exchanger",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"26.493（T.T）×4.733×0.65",WEIGHT_UNIT:39.6,WEIGHT_ATT:316.8,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"ARVOS",DRAWING:"初版图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-LL01",DESCRIPTION:"Primary TLEs DoublePipe Exchangers",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-07-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-07-01",LOA_ISSUE_PLAN:"2021-11-29",LOA_ISSUE_FORECAST:"2021-11-02",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2022-01-10",PO_ISSUE_FORECAST:"2021-12-14",PO_ISSUE_ACTUAL:"2021-10-29",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-12",RAS_ROS:"2023-08-31"},
    // {NO:313,PACKAGE:"P1",AREA:"BTX",TAG_NO:"3810T010",TAG_DESCR:"EXTRACTIVE DISTILLATION COLUMN",TAG_TYPE:"V",DELIVERY_PLAN:"2023-04-24",DELIVERY_FORECAST:"2023-04-24",DRESSUP_REQ:"Y",DRESSUP_PROG:0,LIFTING_PLAN:"2023-05-25",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-05-25",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ4.1/3.6/1.5x60.95",WEIGHT_UNIT:148,WEIGHT_ATT:148,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"QDRK",DRAWING:"有工程图 Engineering dwg issued",CRANE_MAIN_FILTER:"800",CRANE_MAIN:"800",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1113-EQ-R-E-0101",DESCRIPTION:"EXTRACTIVE DISTILLATION COLUMN",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-12-16",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-12-16",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-15",LOA_ISSUE_PLAN:"2022-04-07",LOA_ISSUE_FORECAST:"2022-05-19",LOA_ISSUE_ACTUAL:"2022-05-16",PO_ISSUE_PLAN:"2022-05-19",PO_ISSUE_FORECAST:"2022-06-30",PO_ISSUE_ACTUAL:"2022-08-01",DELIVERY_TO_JOB_SITE_PLAN:"2022-12-10",RAS_ROS:"2023-02-15"},
    // {NO:314,PACKAGE:"P1",AREA:"OR",TAG_NO:"1014F001",TAG_DESCR:"Convection module 1",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-30",DELIVERY_FORECAST:"2023-06-12",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-26",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-06-21",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:133,WEIGHT_ATT:133,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"宁波连通 NBLT",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:315,PACKAGE:"P1",AREA:"OR",TAG_NO:"1014F001",TAG_DESCR:"Convection module 2",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-30",DELIVERY_FORECAST:"2023-06-12",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-26",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-06-21",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:166,WEIGHT_ATT:166,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"宁波连通 NBLT",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:316,PACKAGE:"P1",AREA:"OR",TAG_NO:"1014F001",TAG_DESCR:"Convection module 3",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-30",DELIVERY_FORECAST:"2023-06-12",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-26",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-06-21",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:114,WEIGHT_ATT:114,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"宁波连通 NBLT",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:317,PACKAGE:"P1",AREA:"OR",TAG_NO:"1014F001",TAG_DESCR:"Convection module 4",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-30",DELIVERY_FORECAST:"2023-06-12",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-26",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-06-21",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:53,WEIGHT_ATT:53,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"宁波连通 NBLT",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:318,PACKAGE:"P1",AREA:"OR",TAG_NO:"1014F001",TAG_DESCR:"Convection module 5",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-30",DELIVERY_FORECAST:"2023-06-12",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-26",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-06-21",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:142,WEIGHT_ATT:142,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"宁波连通 NBLT",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:319,PACKAGE:"P1",AREA:"OR",TAG_NO:"1014F001",TAG_DESCR:"Convection module 6",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-30",DELIVERY_FORECAST:"2023-06-12",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-26",LIFTING_PLAN_WK:"26-May-23",LIFTING_FORECAST:"2023-06-21",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:155,WEIGHT_ATT:155,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"宁波连通 NBLT",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:320,PACKAGE:"P1",AREA:"OR",TAG_NO:"2554E016",TAG_DESCR:"C3 RECTIFIER VENT CONDENSER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-27",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-05-27",STATUS:"To go",LOCATION:"5F框架 EL+19.5",SHIPMENT_NO:"TBD",DIMENSION_M:"φ1.3×8.893",WEIGHT_UNIT:25,WEIGHT_ATT:25,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"无锡鼎邦",DRAWING:"有工程图",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0408",DESCRIPTION:"C3 RECTIFIER VENT CONDENSER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-05",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-16",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-05",RAS_ROS:"2023-04-15"},
    // {NO:321,PACKAGE:"P1",AREA:"OR",TAG_NO:"2530E020A",TAG_DESCR:"CGC 2ND STAGE AFTERCOOLERS",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-27",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-05-27",STATUS:"To go",LOCATION:"压缩地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.1×9.36",WEIGHT_UNIT:52.5,WEIGHT_ATT:210,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:" 江南锅炉",DRAWING:"有工程图",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0407",DESCRIPTION:"CG 2ND STAGE AFTERCOOLERS",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-05",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-05",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-14",LOA_ISSUE_PLAN:"2022-08-19",LOA_ISSUE_FORECAST:"2022-07-14",LOA_ISSUE_ACTUAL:"2022-07-08",PO_ISSUE_PLAN:"2022-09-30",PO_ISSUE_FORECAST:"2022-08-25",PO_ISSUE_ACTUAL:"2022-08-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-23",RAS_ROS:"2023-04-15"},
    // {NO:322,PACKAGE:"P1",AREA:"OR",TAG_NO:"2530E020B",TAG_DESCR:"CGC 2ND STAGE AFTERCOOLERS",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-27",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-05-27",STATUS:"To go",LOCATION:"压缩地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.1×9.36",WEIGHT_UNIT:52.5,WEIGHT_ATT:210,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:" 江南锅炉",DRAWING:"有工程图",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0407",DESCRIPTION:"CG 2ND STAGE AFTERCOOLERS",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-05",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-05",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-14",LOA_ISSUE_PLAN:"2022-08-19",LOA_ISSUE_FORECAST:"2022-07-14",LOA_ISSUE_ACTUAL:"2022-07-08",PO_ISSUE_PLAN:"2022-09-30",PO_ISSUE_FORECAST:"2022-08-25",PO_ISSUE_ACTUAL:"2022-08-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-23",RAS_ROS:"2023-04-15"},
    // {NO:323,PACKAGE:"P1",AREA:"OR",TAG_NO:"2530E020C",TAG_DESCR:"CGC 2ND STAGE AFTERCOOLERS",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-27",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-05-27",STATUS:"To go",LOCATION:"压缩地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.1×9.36",WEIGHT_UNIT:52.5,WEIGHT_ATT:210,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:" 江南锅炉",DRAWING:"有工程图",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0407",DESCRIPTION:"CG 2ND STAGE AFTERCOOLERS",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-05",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-05",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-14",LOA_ISSUE_PLAN:"2022-08-19",LOA_ISSUE_FORECAST:"2022-07-14",LOA_ISSUE_ACTUAL:"2022-07-08",PO_ISSUE_PLAN:"2022-09-30",PO_ISSUE_FORECAST:"2022-08-25",PO_ISSUE_ACTUAL:"2022-08-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-23",RAS_ROS:"2023-04-15"},
    // {NO:324,PACKAGE:"P1",AREA:"OR",TAG_NO:"2530E020D",TAG_DESCR:"CGC 2ND STAGE AFTERCOOLERS",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-27",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-05-27",STATUS:"To go",LOCATION:"压缩地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.1×9.36",WEIGHT_UNIT:52.5,WEIGHT_ATT:210,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:" 江南锅炉",DRAWING:"有工程图",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0407",DESCRIPTION:"CG 2ND STAGE AFTERCOOLERS",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-05",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-05",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-14",LOA_ISSUE_PLAN:"2022-08-19",LOA_ISSUE_FORECAST:"2022-07-14",LOA_ISSUE_ACTUAL:"2022-07-08",PO_ISSUE_PLAN:"2022-09-30",PO_ISSUE_FORECAST:"2022-08-25",PO_ISSUE_ACTUAL:"2022-08-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-23",RAS_ROS:"2023-04-15"},
    // {NO:325,PACKAGE:"P1",AREA:"OR",TAG_NO:"1015F001",TAG_DESCR:"Convection module 1",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-31",DELIVERY_FORECAST:"2023-06-11",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-28",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-06-23",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:133,WEIGHT_ATT:133,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"宁波连通 NBLT",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:326,PACKAGE:"P1",AREA:"OR",TAG_NO:"1015F001",TAG_DESCR:"Convection module 2",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-31",DELIVERY_FORECAST:"2023-06-11",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-28",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-06-23",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:166,WEIGHT_ATT:166,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"宁波连通 NBLT",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:327,PACKAGE:"P1",AREA:"OR",TAG_NO:"1015F001",TAG_DESCR:"Convection module 3",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-31",DELIVERY_FORECAST:"2023-06-11",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-28",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-06-23",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:114,WEIGHT_ATT:114,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"宁波连通 NBLT",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:328,PACKAGE:"P1",AREA:"OR",TAG_NO:"1015F001",TAG_DESCR:"Convection module 4",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-31",DELIVERY_FORECAST:"2023-06-11",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-28",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-06-23",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:53,WEIGHT_ATT:53,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"宁波连通 NBLT",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:329,PACKAGE:"P1",AREA:"OR",TAG_NO:"1015F001",TAG_DESCR:"Convection module 5",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-31",DELIVERY_FORECAST:"2023-06-11",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-28",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-06-23",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:142,WEIGHT_ATT:142,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"宁波连通 NBLT",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:330,PACKAGE:"P1",AREA:"OR",TAG_NO:"1015F001",TAG_DESCR:"Convection module 6",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-31",DELIVERY_FORECAST:"2023-06-11",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-28",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-06-23",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:155,WEIGHT_ATT:155,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"宁波连通 NBLT",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:331,PACKAGE:"P1",AREA:"BTX",TAG_NO:"3810T020",TAG_DESCR:"RECOVERY COLUMN",TAG_TYPE:"V",DELIVERY_PLAN:"2023-04-24",DELIVERY_FORECAST:"2023-04-24",DRESSUP_REQ:"Y",DRESSUP_PROG:0,LIFTING_PLAN:"2023-05-28",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-05-28",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ4.2x39.5545",WEIGHT_UNIT:120,WEIGHT_ATT:120,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"QDRK",DRAWING:"有工程图 Engineering dwg issued",CRANE_MAIN_FILTER:"800",CRANE_MAIN:"800",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1113-EQ-R-E-0101",DESCRIPTION:"RECOVERY COLUMN",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-12-16",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-12-16",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-15",LOA_ISSUE_PLAN:"2022-04-07",LOA_ISSUE_FORECAST:"2022-05-19",LOA_ISSUE_ACTUAL:"2022-05-16",PO_ISSUE_PLAN:"2022-05-19",PO_ISSUE_FORECAST:"2022-06-30",PO_ISSUE_ACTUAL:"2022-08-01",DELIVERY_TO_JOB_SITE_PLAN:"2022-12-10",RAS_ROS:"2023-02-15"},
    // {NO:332,PACKAGE:"P1",AREA:"OR",TAG_NO:"1016F001",TAG_DESCR:"Convection module 1",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-04",DELIVERY_FORECAST:"2023-06-10",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-29",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-06-25",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:133,WEIGHT_ATT:133,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"宁波连通 NBLT",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:333,PACKAGE:"P1",AREA:"OR",TAG_NO:"1016F001",TAG_DESCR:"Convection module 2",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-04",DELIVERY_FORECAST:"2023-06-10",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-29",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-06-25",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:166,WEIGHT_ATT:166,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"宁波连通 NBLT",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:334,PACKAGE:"P1",AREA:"OR",TAG_NO:"1016F001",TAG_DESCR:"Convection module 3",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-04",DELIVERY_FORECAST:"2023-06-10",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-29",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-06-25",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:114,WEIGHT_ATT:114,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"宁波连通 NBLT",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:335,PACKAGE:"P1",AREA:"OR",TAG_NO:"1016F001",TAG_DESCR:"Convection module 4",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-04",DELIVERY_FORECAST:"2023-06-10",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-29",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-06-25",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:53,WEIGHT_ATT:53,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"宁波连通 NBLT",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:336,PACKAGE:"P1",AREA:"OR",TAG_NO:"1016F001",TAG_DESCR:"Convection module 5",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-04",DELIVERY_FORECAST:"2023-06-10",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-29",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-06-25",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:142,WEIGHT_ATT:142,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"宁波连通 NBLT",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:337,PACKAGE:"P1",AREA:"OR",TAG_NO:"1016F001",TAG_DESCR:"Convection module 6",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-04",DELIVERY_FORECAST:"2023-06-10",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-29",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-06-25",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:155,WEIGHT_ATT:155,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"宁波连通 NBLT",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:338,PACKAGE:"P1",AREA:"OR",TAG_NO:"2530E010A",TAG_DESCR:"CGC 1ST STAGE AFTERCOOLERS",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-29",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-05-29",STATUS:"To go",LOCATION:"压缩地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.300X7.500",WEIGHT_UNIT:69,WEIGHT_ATT:276,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:" 江南锅炉",DRAWING:"有工程图",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0407",DESCRIPTION:"CG 1ST STAGE AFTERCOOLERS",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-05",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-05",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-14",LOA_ISSUE_PLAN:"2022-08-19",LOA_ISSUE_FORECAST:"2022-07-14",LOA_ISSUE_ACTUAL:"2022-07-08",PO_ISSUE_PLAN:"2022-09-30",PO_ISSUE_FORECAST:"2022-08-25",PO_ISSUE_ACTUAL:"2022-08-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-23",RAS_ROS:"2023-04-15"},
    // {NO:339,PACKAGE:"P1",AREA:"OR",TAG_NO:"2530E010B",TAG_DESCR:"CGC 1ST STAGE AFTERCOOLERS",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-29",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-05-29",STATUS:"To go",LOCATION:"压缩地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.300X7.500",WEIGHT_UNIT:69,WEIGHT_ATT:276,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:" 江南锅炉",DRAWING:"有工程图",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0407",DESCRIPTION:"CG 1ST STAGE AFTERCOOLERS",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-05",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-05",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-14",LOA_ISSUE_PLAN:"2022-08-19",LOA_ISSUE_FORECAST:"2022-07-14",LOA_ISSUE_ACTUAL:"2022-07-08",PO_ISSUE_PLAN:"2022-09-30",PO_ISSUE_FORECAST:"2022-08-25",PO_ISSUE_ACTUAL:"2022-08-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-23",RAS_ROS:"2023-04-15"},
    // {NO:340,PACKAGE:"P1",AREA:"OR",TAG_NO:"2530E010C",TAG_DESCR:"CGC 1ST STAGE AFTERCOOLERS",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-29",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-05-29",STATUS:"To go",LOCATION:"压缩地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.300X7.500",WEIGHT_UNIT:69,WEIGHT_ATT:276,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:" 江南锅炉",DRAWING:"有工程图",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0407",DESCRIPTION:"CG 1ST STAGE AFTERCOOLERS",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-05",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-05",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-14",LOA_ISSUE_PLAN:"2022-08-19",LOA_ISSUE_FORECAST:"2022-07-14",LOA_ISSUE_ACTUAL:"2022-07-08",PO_ISSUE_PLAN:"2022-09-30",PO_ISSUE_FORECAST:"2022-08-25",PO_ISSUE_ACTUAL:"2022-08-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-23",RAS_ROS:"2023-04-15"},
    // {NO:341,PACKAGE:"P1",AREA:"OR",TAG_NO:"2530E010D",TAG_DESCR:"CGC 1ST STAGE AFTERCOOLERS",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-29",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-05-29",STATUS:"To go",LOCATION:"压缩地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.300X7.500",WEIGHT_UNIT:69,WEIGHT_ATT:276,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:" 江南锅炉",DRAWING:"有工程图",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0407",DESCRIPTION:"CG 1ST STAGE AFTERCOOLERS",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-05",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-05",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-14",LOA_ISSUE_PLAN:"2022-08-19",LOA_ISSUE_FORECAST:"2022-07-14",LOA_ISSUE_ACTUAL:"2022-07-08",PO_ISSUE_PLAN:"2022-09-30",PO_ISSUE_FORECAST:"2022-08-25",PO_ISSUE_ACTUAL:"2022-08-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-23",RAS_ROS:"2023-04-15"},
    // {NO:342,PACKAGE:"P1",AREA:"OR",TAG_NO:"COLDBOX1",TAG_DESCR:"COLD BOX 1",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DELIVERY_FORECAST:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-30",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-05-30",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"7.7x4.0x31.76",WEIGHT_UNIT:243,WEIGHT_ATT:243,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"杭州制氧 Hangzhou Oxygen.",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计 Lifting lugs design done",DRAWING:"有初版吊耳图 Lifting lugs dwg IFR",CRANE_MAIN_FILTER:"800",CRANE_MAIN:"800",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans"},
    // {NO:343,PACKAGE:"P1",AREA:"OR",TAG_NO:"COLDBOX2",TAG_DESCR:"COLD BOX 2",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DELIVERY_FORECAST:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-30",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-05-30",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"4.8x4.1x23.4",WEIGHT_UNIT:115,WEIGHT_ATT:115,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"杭州制氧 Hangzhou Oxygen.",DESIGN_OF_LIFTING_LUG:"已完成吊耳设计 Lifting lugs design done",DRAWING:"有初版吊耳图 Lifting lugs dwg IFR",CRANE_MAIN_FILTER:"800",CRANE_MAIN:"800",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans"},
    // {NO:344,PACKAGE:"P1",AREA:"OR",TAG_NO:"2522E061A",TAG_DESCR:"DISTILLATE STRIPPER REBOILER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-31",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-05-31",STATUS:"To go",LOCATION:"2B框架 EL+6.0m",SHIPMENT_NO:"TBD",DIMENSION_M:"φ1.9×9.705",WEIGHT_UNIT:60,WEIGHT_ATT:120,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"山东美陵",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0409",DESCRIPTION:"DISTILLATE STRIPPER REBOILER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-18",RAS_ROS:"2023-04-15"},
    // {NO:345,PACKAGE:"P1",AREA:"OR",TAG_NO:"2522E061B",TAG_DESCR:"DISTILLATE STRIPPER REBOILER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-31",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-05-31",STATUS:"To go",LOCATION:"2B框架 EL+6.0m",SHIPMENT_NO:"TBD",DIMENSION_M:"φ1.9×9.705",WEIGHT_UNIT:60,WEIGHT_ATT:120,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"山东美陵",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0409",DESCRIPTION:"DISTILLATE STRIPPER REBOILER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-18",RAS_ROS:"2023-04-15"},
    // {NO:346,PACKAGE:"P1",AREA:"OR",TAG_NO:"1017F001",TAG_DESCR:"Convection module 1",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-15",DELIVERY_FORECAST:"2023-06-21",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-31",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-06-27",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:133,WEIGHT_ATT:133,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"宁波连通 NBLT",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:347,PACKAGE:"P1",AREA:"OR",TAG_NO:"1017F001",TAG_DESCR:"Convection module 2",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-15",DELIVERY_FORECAST:"2023-06-21",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-31",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-06-27",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:166,WEIGHT_ATT:166,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"宁波连通 NBLT",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:348,PACKAGE:"P1",AREA:"OR",TAG_NO:"1017F001",TAG_DESCR:"Convection module 3",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-15",DELIVERY_FORECAST:"2023-06-21",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-31",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-06-27",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:114,WEIGHT_ATT:114,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"宁波连通 NBLT",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:349,PACKAGE:"P1",AREA:"OR",TAG_NO:"1017F001",TAG_DESCR:"Convection module 4",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-15",DELIVERY_FORECAST:"2023-06-21",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-31",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-06-27",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:53,WEIGHT_ATT:53,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"宁波连通 NBLT",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:350,PACKAGE:"P1",AREA:"OR",TAG_NO:"1017F001",TAG_DESCR:"Convection module 5",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-15",DELIVERY_FORECAST:"2023-06-21",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-31",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-06-27",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:142,WEIGHT_ATT:142,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"宁波连通 NBLT",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:351,PACKAGE:"P1",AREA:"OR",TAG_NO:"1017F001",TAG_DESCR:"Convection module 6",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-15",DELIVERY_FORECAST:"2023-06-21",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-05-31",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-06-27",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:155,WEIGHT_ATT:155,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"宁波连通 NBLT",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:352,PACKAGE:"P1",AREA:"OR",TAG_NO:"1018F001",TAG_DESCR:"Convection module 1",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-14",DELIVERY_FORECAST:"2023-06-20",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-01",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-06-30",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:133,WEIGHT_ATT:133,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"茂重 Guangdong Maozhong",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:353,PACKAGE:"P1",AREA:"OR",TAG_NO:"1018F001",TAG_DESCR:"Convection module 2",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-14",DELIVERY_FORECAST:"2023-06-20",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-01",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-06-30",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:166,WEIGHT_ATT:166,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"茂重 Guangdong Maozhong",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:354,PACKAGE:"P1",AREA:"OR",TAG_NO:"1018F001",TAG_DESCR:"Convection module 3",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-14",DELIVERY_FORECAST:"2023-06-20",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-01",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-06-30",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:114,WEIGHT_ATT:114,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"茂重 Guangdong Maozhong",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:355,PACKAGE:"P1",AREA:"OR",TAG_NO:"1018F001",TAG_DESCR:"Convection module 4",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-14",DELIVERY_FORECAST:"2023-06-20",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-01",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-06-30",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:53,WEIGHT_ATT:53,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"茂重 Guangdong Maozhong",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:356,PACKAGE:"P1",AREA:"OR",TAG_NO:"1018F001",TAG_DESCR:"Convection module 5",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-14",DELIVERY_FORECAST:"2023-06-20",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-01",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-06-30",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:142,WEIGHT_ATT:142,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"茂重 Guangdong Maozhong",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:357,PACKAGE:"P1",AREA:"OR",TAG_NO:"1018F001",TAG_DESCR:"Convection module 6",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-14",DELIVERY_FORECAST:"2023-06-20",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-01",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-06-30",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:155,WEIGHT_ATT:155,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"茂重 Guangdong Maozhong",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:358,PACKAGE:"P1",AREA:"GHU",TAG_NO:"3630E001A",TAG_DESCR:"HD2 FEED/EFFLUENT EXCHANGERS",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-30",DELIVERY_FORECAST:"2023-06-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-01",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-06-01",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",WEIGHT_UNIT:36,WEIGHT_ATT:108,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"无锡化工",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1278-EQ-R-E-0402",DESCRIPTION:"ABC",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-04-20",LOA_ISSUE_PLAN:"2022-07-07",LOA_ISSUE_FORECAST:"2022-08-28",LOA_ISSUE_ACTUAL:"2022-08-29",PO_ISSUE_PLAN:"2022-08-18",PO_ISSUE_FORECAST:"2022-10-09",PO_ISSUE_ACTUAL:"2022-09-16",DELIVERY_TO_JOB_SITE_PLAN:"2022-07-14",RAS_ROS:"2023-05-31"},
    // {NO:359,PACKAGE:"P1",AREA:"GHU",TAG_NO:"3630E001B",TAG_DESCR:"HD2 FEED/EFFLUENT EXCHANGERS",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-30",DELIVERY_FORECAST:"2023-06-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-01",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-06-01",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",WEIGHT_UNIT:36,WEIGHT_ATT:108,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"无锡化工",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1278-EQ-R-E-0402",DESCRIPTION:"ABC",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-04-20",LOA_ISSUE_PLAN:"2022-07-07",LOA_ISSUE_FORECAST:"2022-08-28",LOA_ISSUE_ACTUAL:"2022-08-29",PO_ISSUE_PLAN:"2022-08-18",PO_ISSUE_FORECAST:"2022-10-09",PO_ISSUE_ACTUAL:"2022-09-16",DELIVERY_TO_JOB_SITE_PLAN:"2022-07-14",RAS_ROS:"2023-05-31"},
    // {NO:360,PACKAGE:"P1",AREA:"GHU",TAG_NO:"3630E001C",TAG_DESCR:"HD2 FEED/EFFLUENT EXCHANGERS",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-30",DELIVERY_FORECAST:"2023-06-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-01",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-06-01",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",WEIGHT_UNIT:36,WEIGHT_ATT:108,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"无锡化工",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1278-EQ-R-E-0402",DESCRIPTION:"ABC",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-04-20",LOA_ISSUE_PLAN:"2022-07-07",LOA_ISSUE_FORECAST:"2022-08-28",LOA_ISSUE_ACTUAL:"2022-08-29",PO_ISSUE_PLAN:"2022-08-18",PO_ISSUE_FORECAST:"2022-10-09",PO_ISSUE_ACTUAL:"2022-09-16",DELIVERY_TO_JOB_SITE_PLAN:"2022-07-14",RAS_ROS:"2023-05-31"},
    // {NO:361,PACKAGE:"P1",AREA:"BD",TAG_NO:"3012C031",TAG_DESCR:"Recycle Gas Compressor",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-01",DELIVERY_FORECAST:"2023-06-01",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-01",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-06-01",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"4×9",WEIGHT_UNIT:180,WEIGHT_ATT:180,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"KOBELCO",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1277-RE-R-E-LL01",DESCRIPTION:"Recycle Gas Compressor",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-07-23",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-09-15",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-09-14",LOA_ISSUE_PLAN:"2022-01-04",LOA_ISSUE_FORECAST:"2021-12-31",LOA_ISSUE_ACTUAL:"2021-12-30",PO_ISSUE_PLAN:"2022-02-15",PO_ISSUE_FORECAST:"2022-01-28",PO_ISSUE_ACTUAL:"2022-02-22",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-04",RAS_ROS:"2023-06-01"},
    // {NO:362,PACKAGE:"P2",AREA:"PP",TAG_NO:"6130E024",TAG_DESCR:"1st Gas Phase Reactor Cooler",TAG_TYPE:"V",DELIVERY_PLAN:"2023-06-30",DELIVERY_FORECAST:"2023-06-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-01",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-06-01",STATUS:"To go",LOCATION:"反应框架 EL+16.0",SHIPMENT_NO:"TBD",DIMENSION_M:"φ1.8×15.375",WEIGHT_UNIT:60,WEIGHT_ATT:60,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"江南锅炉",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t-1",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1219-EQ-R-E-SL04",DESCRIPTION:"2nd Gas Phase Reactor Cooler",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-12-02",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-12-02",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-02",LOA_ISSUE_PLAN:"2022-04-28",LOA_ISSUE_FORECAST:"2022-04-19",LOA_ISSUE_ACTUAL:"2022-04-18",PO_ISSUE_PLAN:"2022-06-09",PO_ISSUE_FORECAST:"2022-05-30",PO_ISSUE_ACTUAL:"2022-06-08",DELIVERY_TO_JOB_SITE_PLAN:"2023-07-10",RAS_ROS:"2023-07-30"},
    // {NO:363,PACKAGE:"P2",AREA:"PP",TAG_NO:"6130E025",TAG_DESCR:"2nd Gas Phase Reactor Cooler",TAG_TYPE:"V",DELIVERY_PLAN:"2023-06-30",DELIVERY_FORECAST:"2023-06-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-01",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-06-01",STATUS:"To go",LOCATION:"反应框架 EL+16.0",SHIPMENT_NO:"TBD",DIMENSION_M:"φ1.8×15.375",WEIGHT_UNIT:60,WEIGHT_ATT:60,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"江南锅炉",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t-1",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1219-EQ-R-E-SL04",DESCRIPTION:"2nd Gas Phase Reactor Cooler",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-12-02",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-12-02",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-02",LOA_ISSUE_PLAN:"2022-04-28",LOA_ISSUE_FORECAST:"2022-04-19",LOA_ISSUE_ACTUAL:"2022-04-18",PO_ISSUE_PLAN:"2022-06-09",PO_ISSUE_FORECAST:"2022-05-30",PO_ISSUE_ACTUAL:"2022-06-08",DELIVERY_TO_JOB_SITE_PLAN:"2023-07-10",RAS_ROS:"2023-07-30"},
    // {NO:364,PACKAGE:"P2",AREA:"PP",TAG_NO:"6150E041",TAG_DESCR:"Propylene Condenser",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-30",DELIVERY_FORECAST:"2023-05-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-02",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-06-02",STATUS:"To go",LOCATION:"反应框架 EL+16.0",SHIPMENT_NO:"TBD",DIMENSION_M:"φ1.5×9.217",WEIGHT_UNIT:35,WEIGHT_ATT:35,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"耐尔能源",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t-1",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1219-EQ-R-E-0403",DESCRIPTION:"Propylene Condenser",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-04-01",LOA_ISSUE_PLAN:"2022-09-19",LOA_ISSUE_FORECAST:"2022-08-29",LOA_ISSUE_ACTUAL:"2022-08-26",PO_ISSUE_PLAN:"2022-10-31",PO_ISSUE_FORECAST:"2022-10-07",PO_ISSUE_ACTUAL:"2022-09-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-05-17",RAS_ROS:"2023-05-30"},
    // {NO:365,PACKAGE:"P2",AREA:"PP",TAG_NO:"6250E041",TAG_DESCR:"Propylene Condenser",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-30",DELIVERY_FORECAST:"2023-05-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-02",LIFTING_PLAN_WK:"02-Jun-23",LIFTING_FORECAST:"2023-06-02",STATUS:"To go",LOCATION:"框架 EL+11.2",SHIPMENT_NO:"TBD",DIMENSION_M:"φ1.6×9.406",WEIGHT_UNIT:35,WEIGHT_ATT:35,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"耐尔能源",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t-1",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1219-EQ-R-E-0403",DESCRIPTION:"Propylene Condenser",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-04-01",LOA_ISSUE_PLAN:"2022-09-19",LOA_ISSUE_FORECAST:"2022-08-29",LOA_ISSUE_ACTUAL:"2022-08-26",PO_ISSUE_PLAN:"2022-10-31",PO_ISSUE_FORECAST:"2022-10-07",PO_ISSUE_ACTUAL:"2022-09-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-05-17",RAS_ROS:"2023-05-30"},
    // {NO:366,PACKAGE:"P2",AREA:"PP",TAG_NO:"6190D004",TAG_DESCR:"High Pressure Blowdown Vessel",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-01",DELIVERY_FORECAST:"2023-05-01",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-03",LIFTING_PLAN_WK:"09-Jun-23",LIFTING_FORECAST:"2023-06-03",STATUS:"To go",LOCATION:"121917框架 EL+10.5",SHIPMENT_NO:"TBD",DIMENSION_M:"φ4.0×14.215",WEIGHT_UNIT:75,WEIGHT_ATT:75,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"张化机",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t-1",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1219-EQ-R-E-0301",DESCRIPTION:"High Pressure Blowdown Vessel",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-04-19",LOA_ISSUE_PLAN:"2022-09-09",LOA_ISSUE_FORECAST:"2022-08-22",LOA_ISSUE_ACTUAL:"2022-08-19",PO_ISSUE_PLAN:"2022-10-21",PO_ISSUE_FORECAST:"2022-09-30",PO_ISSUE_ACTUAL:"2022-09-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-05-07"},
    // {NO:367,PACKAGE:"P2",AREA:"PP",TAG_NO:"6190D005",TAG_DESCR:"First Low Pressure Blowdown Vessel",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-30",DELIVERY_FORECAST:"2023-05-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-03",LIFTING_PLAN_WK:"09-Jun-23",LIFTING_FORECAST:"2023-06-03",STATUS:"To go",LOCATION:"121917框架 EL+10.5",SHIPMENT_NO:"TBD",DIMENSION_M:"φ4.0×14.215",WEIGHT_UNIT:40,WEIGHT_ATT:40,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"兰石",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t-1",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1219-EQ-R-E-0301",DESCRIPTION:"First Low Pressure Blowdown Vessel",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-04-19",LOA_ISSUE_PLAN:"2022-09-09",LOA_ISSUE_FORECAST:"2022-08-22",LOA_ISSUE_ACTUAL:"2022-08-19",PO_ISSUE_PLAN:"2022-10-21",PO_ISSUE_FORECAST:"2022-09-30",PO_ISSUE_ACTUAL:"2022-09-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-05-07"},
    // {NO:368,PACKAGE:"P1",AREA:"BD",TAG_NO:"3011E021A",TAG_DESCR:"MAIN WASHER CONDENSER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-31",DELIVERY_FORECAST:"2023-05-31",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-03",LIFTING_PLAN_WK:"09-Jun-23",LIFTING_FORECAST:"2023-06-03",STATUS:"To go",LOCATION:"B2框架 EL+14.0",SHIPMENT_NO:"TBD",DIMENSION_M:"φ1.7×10.051",WEIGHT_UNIT:41,WEIGHT_ATT:82,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"江阴金童",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1277-EQ-R-E-0401",DESCRIPTION:"MAIN WASHER CONDENSER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-02-21",LOA_ISSUE_PLAN:"2022-08-26",LOA_ISSUE_FORECAST:"2022-08-13",LOA_ISSUE_ACTUAL:"2022-08-13",PO_ISSUE_PLAN:"2022-10-07",PO_ISSUE_FORECAST:"2022-09-24",PO_ISSUE_ACTUAL:"2022-09-15",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-30",RAS_ROS:"2023-05-31"},
    // {NO:369,PACKAGE:"P1",AREA:"BD",TAG_NO:"3011E021B",TAG_DESCR:"MAIN WASHER CONDENSER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-31",DELIVERY_FORECAST:"2023-05-31",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-03",LIFTING_PLAN_WK:"09-Jun-23",LIFTING_FORECAST:"2023-06-03",STATUS:"To go",LOCATION:"B2框架 EL+14.0",SHIPMENT_NO:"TBD",DIMENSION_M:"φ1.7×10.051",WEIGHT_UNIT:41,WEIGHT_ATT:82,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"江阴金童",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1277-EQ-R-E-0401",DESCRIPTION:"MAIN WASHER CONDENSER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-02-21",LOA_ISSUE_PLAN:"2022-08-26",LOA_ISSUE_FORECAST:"2022-08-13",LOA_ISSUE_ACTUAL:"2022-08-13",PO_ISSUE_PLAN:"2022-10-07",PO_ISSUE_FORECAST:"2022-09-24",PO_ISSUE_ACTUAL:"2022-09-15",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-30",RAS_ROS:"2023-05-31"},
    // {NO:370,PACKAGE:"P1",AREA:"OR",TAG_NO:"1019F001",TAG_DESCR:"Convection module 1",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-23",DELIVERY_FORECAST:"2023-06-29",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-03",LIFTING_PLAN_WK:"09-Jun-23",LIFTING_FORECAST:"2023-07-02",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:133,WEIGHT_ATT:133,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"茂重 Guangdong Maozhong",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:371,PACKAGE:"P1",AREA:"OR",TAG_NO:"1019F001",TAG_DESCR:"Convection module 2",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-23",DELIVERY_FORECAST:"2023-06-29",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-03",LIFTING_PLAN_WK:"09-Jun-23",LIFTING_FORECAST:"2023-07-02",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:166,WEIGHT_ATT:166,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"茂重 Guangdong Maozhong",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:372,PACKAGE:"P1",AREA:"OR",TAG_NO:"1019F001",TAG_DESCR:"Convection module 3",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-23",DELIVERY_FORECAST:"2023-06-29",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-03",LIFTING_PLAN_WK:"09-Jun-23",LIFTING_FORECAST:"2023-07-02",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:114,WEIGHT_ATT:114,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"茂重 Guangdong Maozhong",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:373,PACKAGE:"P1",AREA:"OR",TAG_NO:"1019F001",TAG_DESCR:"Convection module 4",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-23",DELIVERY_FORECAST:"2023-06-29",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-03",LIFTING_PLAN_WK:"09-Jun-23",LIFTING_FORECAST:"2023-07-02",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:53,WEIGHT_ATT:53,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"茂重 Guangdong Maozhong",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:374,PACKAGE:"P1",AREA:"OR",TAG_NO:"1019F001",TAG_DESCR:"Convection module 5",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-23",DELIVERY_FORECAST:"2023-06-29",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-03",LIFTING_PLAN_WK:"09-Jun-23",LIFTING_FORECAST:"2023-07-02",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:142,WEIGHT_ATT:142,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"茂重 Guangdong Maozhong",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:375,PACKAGE:"P1",AREA:"OR",TAG_NO:"1019F001",TAG_DESCR:"Convection module 6",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-23",DELIVERY_FORECAST:"2023-06-29",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-03",LIFTING_PLAN_WK:"09-Jun-23",LIFTING_FORECAST:"2023-07-02",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:155,WEIGHT_ATT:155,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"茂重 Guangdong Maozhong",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:376,PACKAGE:"P1",AREA:"OR",TAG_NO:"1020F001",TAG_DESCR:"Convection module 1",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-24",DELIVERY_FORECAST:"2023-06-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-04",LIFTING_PLAN_WK:"09-Jun-23",LIFTING_FORECAST:"2023-07-05",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:133,WEIGHT_ATT:133,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"茂重 Guangdong Maozhong",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:377,PACKAGE:"P1",AREA:"OR",TAG_NO:"1020F001",TAG_DESCR:"Convection module 2",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-24",DELIVERY_FORECAST:"2023-06-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-04",LIFTING_PLAN_WK:"09-Jun-23",LIFTING_FORECAST:"2023-07-05",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:166,WEIGHT_ATT:166,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"茂重 Guangdong Maozhong",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:378,PACKAGE:"P1",AREA:"OR",TAG_NO:"1020F001",TAG_DESCR:"Convection module 3",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-24",DELIVERY_FORECAST:"2023-06-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-04",LIFTING_PLAN_WK:"09-Jun-23",LIFTING_FORECAST:"2023-07-05",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:114,WEIGHT_ATT:114,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"茂重 Guangdong Maozhong",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:379,PACKAGE:"P1",AREA:"OR",TAG_NO:"1020F001",TAG_DESCR:"Convection module 4",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-24",DELIVERY_FORECAST:"2023-06-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-04",LIFTING_PLAN_WK:"09-Jun-23",LIFTING_FORECAST:"2023-07-05",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:53,WEIGHT_ATT:53,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"茂重 Guangdong Maozhong",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:380,PACKAGE:"P1",AREA:"OR",TAG_NO:"1020F001",TAG_DESCR:"Convection module 5",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-24",DELIVERY_FORECAST:"2023-06-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-04",LIFTING_PLAN_WK:"09-Jun-23",LIFTING_FORECAST:"2023-07-05",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:142,WEIGHT_ATT:142,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"茂重 Guangdong Maozhong",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:381,PACKAGE:"P1",AREA:"OR",TAG_NO:"1020F001",TAG_DESCR:"Convection module 6",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-24",DELIVERY_FORECAST:"2023-06-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-04",LIFTING_PLAN_WK:"09-Jun-23",LIFTING_FORECAST:"2023-07-05",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",WEIGHT_UNIT:155,WEIGHT_ATT:155,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"茂重 Guangdong Maozhong",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"已有总图 Vendor dwg issued",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-FU-R-E-SL02",DESCRIPTION:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-09-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-09",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-02",LOA_ISSUE_PLAN:"2022-01-16",LOA_ISSUE_FORECAST:"2022-03-04",LOA_ISSUE_ACTUAL:"2022-03-10",PO_ISSUE_PLAN:"2022-02-27",PO_ISSUE_FORECAST:"2022-05-25",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-28"},
    // {NO:382,PACKAGE:"P1",AREA:"OR",TAG_NO:"2536E061A",TAG_DESCR:"LP DEPROPANIZER STEAM REBOILER",TAG_TYPE:"V",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-04",LIFTING_PLAN_WK:"09-Jun-23",LIFTING_FORECAST:"2023-06-04",STATUS:"To go",LOCATION:"4G",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.0×10.853",WEIGHT_UNIT:48.5,WEIGHT_ATT:97,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"无锡鼎邦",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0408",DESCRIPTION:"LP DEPROPANIZER STEAM REBOILER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-05",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-16",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-05",RAS_ROS:"2023-04-15"},
    // {NO:383,PACKAGE:"P1",AREA:"OR",TAG_NO:"2536E061B",TAG_DESCR:"LP DEPROPANIZER STEAM REBOILER",TAG_TYPE:"V",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-04",LIFTING_PLAN_WK:"09-Jun-23",LIFTING_FORECAST:"2023-06-04",STATUS:"To go",LOCATION:"4G",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.0×10.853",WEIGHT_UNIT:48.5,WEIGHT_ATT:97,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"无锡鼎邦",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0408",DESCRIPTION:"LP DEPROPANIZER STEAM REBOILER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-05",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-16",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-05",RAS_ROS:"2023-04-15"},
    // {NO:384,PACKAGE:"P1",AREA:"GHU",TAG_NO:"3630E001D",TAG_DESCR:"HD2 FEED/EFFLUENT EXCHANGERS",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-30",DELIVERY_FORECAST:"2023-06-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-04",LIFTING_PLAN_WK:"09-Jun-23",LIFTING_FORECAST:"2023-06-04",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",WEIGHT_UNIT:36,WEIGHT_ATT:72,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"无锡化工",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1278-EQ-R-E-0402",DESCRIPTION:"CrMoHD2 FEEDEFFLUENT EXCHANGERS DE",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-04-20",LOA_ISSUE_PLAN:"2022-08-21",LOA_ISSUE_FORECAST:"2022-08-31",LOA_ISSUE_ACTUAL:"2022-08-29",PO_ISSUE_PLAN:"2022-10-02",PO_ISSUE_FORECAST:"2022-10-12",PO_ISSUE_ACTUAL:"2022-09-16",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-25",RAS_ROS:"2023-05-31"},
    // {NO:385,PACKAGE:"P1",AREA:"GHU",TAG_NO:"3630E001E",TAG_DESCR:"HD2 FEED/EFFLUENT EXCHANGERS",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-30",DELIVERY_FORECAST:"2023-06-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-04",LIFTING_PLAN_WK:"09-Jun-23",LIFTING_FORECAST:"2023-06-04",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",WEIGHT_UNIT:36,WEIGHT_ATT:72,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"无锡化工",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1278-EQ-R-E-0402",DESCRIPTION:"CrMoHD2 FEEDEFFLUENT EXCHANGERS DE",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-04-20",LOA_ISSUE_PLAN:"2022-08-21",LOA_ISSUE_FORECAST:"2022-08-31",LOA_ISSUE_ACTUAL:"2022-08-29",PO_ISSUE_PLAN:"2022-10-02",PO_ISSUE_FORECAST:"2022-10-12",PO_ISSUE_ACTUAL:"2022-09-16",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-25",RAS_ROS:"2023-05-31"},
    // {NO:386,PACKAGE:"P1",AREA:"BD",TAG_NO:"3012E033",TAG_DESCR:"DEGASSER REBOILER",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-31",DELIVERY_FORECAST:"2023-05-31",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-05",LIFTING_PLAN_WK:"09-Jun-23",LIFTING_FORECAST:"2023-06-05",STATUS:"To go",LOCATION:"B3框架",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.0×10.392",WEIGHT_UNIT:58,WEIGHT_ATT:58,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"江阴金童",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1277-EQ-R-E-0401",DESCRIPTION:"SHU 2nd stage vent condenser",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-02-21",LOA_ISSUE_PLAN:"2022-08-26",LOA_ISSUE_FORECAST:"2022-08-13",LOA_ISSUE_ACTUAL:"2022-08-13",PO_ISSUE_PLAN:"2022-10-07",PO_ISSUE_FORECAST:"2022-09-24",PO_ISSUE_ACTUAL:"2022-09-15",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-30",RAS_ROS:"2023-05-31"},
    // {NO:387,PACKAGE:"P2",AREA:"PP",TAG_NO:"6290D004A",TAG_DESCR:"High Pressure Discharge Tank",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-01",DELIVERY_FORECAST:"2023-05-01",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-05",LIFTING_PLAN_WK:"09-Jun-23",LIFTING_FORECAST:"2023-06-05",STATUS:"To go",LOCATION:"121927框架 EL+10.5",SHIPMENT_NO:"TBD",DIMENSION_M:"φ4.0×14.215",WEIGHT_UNIT:75,WEIGHT_ATT:150,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"兰石",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t-1",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1219-EQ-R-E-0301",DESCRIPTION:"High Pressure Blowdown Vessel",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-04-19",LOA_ISSUE_PLAN:"2022-09-09",LOA_ISSUE_FORECAST:"2022-08-22",LOA_ISSUE_ACTUAL:"2022-08-19",PO_ISSUE_PLAN:"2022-10-21",PO_ISSUE_FORECAST:"2022-09-30",PO_ISSUE_ACTUAL:"2022-09-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-05-07"},
    // {NO:388,PACKAGE:"P2",AREA:"PP",TAG_NO:"6290D004B",TAG_DESCR:"High Pressure Discharge Tank",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-01",DELIVERY_FORECAST:"2023-05-01",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-05",LIFTING_PLAN_WK:"09-Jun-23",LIFTING_FORECAST:"2023-06-05",STATUS:"To go",LOCATION:"121927框架 EL+10.5",SHIPMENT_NO:"TBD",DIMENSION_M:"φ4.0×14.215",WEIGHT_UNIT:75,WEIGHT_ATT:150,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"兰石",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t-1",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1219-EQ-R-E-0301",DESCRIPTION:"High Pressure Blowdown Vessel",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-04-19",LOA_ISSUE_PLAN:"2022-09-09",LOA_ISSUE_FORECAST:"2022-08-22",LOA_ISSUE_ACTUAL:"2022-08-19",PO_ISSUE_PLAN:"2022-10-21",PO_ISSUE_FORECAST:"2022-09-30",PO_ISSUE_ACTUAL:"2022-09-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-05-07"},
    // {NO:389,PACKAGE:"P2",AREA:"PP",TAG_NO:"6290D005",TAG_DESCR:"First Low Pressure Blowdown Vessel",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-01",DELIVERY_FORECAST:"2023-05-01",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-06",LIFTING_PLAN_WK:"09-Jun-23",LIFTING_FORECAST:"2023-06-06",STATUS:"To go",LOCATION:"121927框架 EL+10.5",SHIPMENT_NO:"TBD",DIMENSION_M:"φ4.0×14.215",WEIGHT_UNIT:40,WEIGHT_ATT:40,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"兰石",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t-1",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1219-EQ-R-E-0301",DESCRIPTION:"First Low Pressure Blowdown Vessel",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-04-19",LOA_ISSUE_PLAN:"2022-09-09",LOA_ISSUE_FORECAST:"2022-08-22",LOA_ISSUE_ACTUAL:"2022-08-19",PO_ISSUE_PLAN:"2022-10-21",PO_ISSUE_FORECAST:"2022-09-30",PO_ISSUE_ACTUAL:"2022-09-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-05-07"},
    // {NO:390,PACKAGE:"P1",AREA:"BD",TAG_NO:"3013E046",TAG_DESCR:"BUTADIENE COLUMN REBOILER",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-31",DELIVERY_FORECAST:"2023-05-31",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-06",LIFTING_PLAN_WK:"09-Jun-23",LIFTING_FORECAST:"2023-06-06",STATUS:"To go",LOCATION:"B4框架 EL+7",SHIPMENT_NO:"TBD",WEIGHT_UNIT:65,WEIGHT_ATT:65,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"江阴金童",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1277-EQ-R-E-0401",DESCRIPTION:"BUTADIENE COLUMN REBOILER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-02-21",LOA_ISSUE_PLAN:"2022-08-26",LOA_ISSUE_FORECAST:"2022-08-13",LOA_ISSUE_ACTUAL:"2022-08-13",PO_ISSUE_PLAN:"2022-10-07",PO_ISSUE_FORECAST:"2022-09-24",PO_ISSUE_ACTUAL:"2022-09-15",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-30",RAS_ROS:"2023-05-31"},
    // {NO:391,PACKAGE:"P1",AREA:"OR",TAG_NO:"2552E001",TAG_DESCR:"C3 HYDROGENATION AFTERCOOLER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-06",LIFTING_PLAN_WK:"09-Jun-23",LIFTING_FORECAST:"2023-06-06",STATUS:"To go",LOCATION:"地面5E",SHIPMENT_NO:"TBD",DIMENSION_M:"φ1.7×10.258",WEIGHT_UNIT:60,WEIGHT_ATT:60,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"无锡鼎邦",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0408",DESCRIPTION:"C3 HYDROGENATION AFTERCOOLER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-19",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-30",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-19",RAS_ROS:"2023-04-15"},
    // {NO:392,PACKAGE:"P2",AREA:"PP",TAG_NO:"6260D051",TAG_DESCR:"Granules Purge Drum",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-30",DELIVERY_FORECAST:"2023-05-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-07",LIFTING_PLAN_WK:"09-Jun-23",LIFTING_FORECAST:"2023-06-07",STATUS:"To go",LOCATION:"121924框架 EL+17.5",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.8×14.3",WEIGHT_UNIT:43,WEIGHT_ATT:43,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"兰石",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t-1",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1219-EQ-R-E-SL01",DESCRIPTION:"Granules Purge Drum",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-12-02",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-12-02",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-02",LOA_ISSUE_PLAN:"2022-05-15",LOA_ISSUE_FORECAST:"2022-05-10",LOA_ISSUE_ACTUAL:"2022-05-07",PO_ISSUE_PLAN:"2022-06-26",PO_ISSUE_FORECAST:"2022-06-18",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-17",RAS_ROS:"2023-07-30"},
    // {NO:393,PACKAGE:"P1",AREA:"OR",TAG_NO:"2555E010A",TAG_DESCR:"DEBUTANIZER REBOILERS",TAG_TYPE:"V",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-08",LIFTING_PLAN_WK:"09-Jun-23",LIFTING_FORECAST:"2023-06-08",STATUS:"To go",LOCATION:"5E EL+8.0",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.2×10.863",WEIGHT_UNIT:60,WEIGHT_ATT:120,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"山东美陵",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0409",DESCRIPTION:"DEBUTANIZER REBOILERS",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-18",RAS_ROS:"2023-04-15"},
    // {NO:394,PACKAGE:"P1",AREA:"OR",TAG_NO:"2555E010B",TAG_DESCR:"DEBUTANIZER REBOILERS",TAG_TYPE:"V",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-08",LIFTING_PLAN_WK:"09-Jun-23",LIFTING_FORECAST:"2023-06-08",STATUS:"To go",LOCATION:"5E EL+8.0",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.2×10.863",WEIGHT_UNIT:60,WEIGHT_ATT:120,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"山东美陵",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0409",DESCRIPTION:"DEBUTANIZER REBOILERS",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-18",RAS_ROS:"2023-04-15"},
    // {NO:395,PACKAGE:"P1",AREA:"OR",TAG_NO:"2565E003",TAG_DESCR:"ETHYLENE RUNDOWN CONDENSER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-09",LIFTING_PLAN_WK:"09-Jun-23",LIFTING_FORECAST:"2023-06-09",STATUS:"To go",LOCATION:"4A EL+11.0",SHIPMENT_NO:"TBD",WEIGHT_UNIT:70,WEIGHT_ATT:70,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:" 江南锅炉",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0405",DESCRIPTION:"ETHYLENE RUNDOWN CONDENSERx000D",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-03-08",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-03-08",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-01-29",LOA_ISSUE_PLAN:"2022-06-29",LOA_ISSUE_FORECAST:"2022-06-23",LOA_ISSUE_ACTUAL:"2022-06-21",PO_ISSUE_PLAN:"2022-08-10",PO_ISSUE_FORECAST:"2022-08-04",PO_ISSUE_ACTUAL:"2022-07-27",DELIVERY_TO_JOB_SITE_PLAN:"2023-02-27",RAS_ROS:"2023-04-05"},
    // {NO:396,PACKAGE:"P1",AREA:"OR",TAG_NO:"2522E016A",TAG_DESCR:"SECONDARY QUENCH WATER COOLERS",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-09",LIFTING_PLAN_WK:"09-Jun-23",LIFTING_FORECAST:"2023-06-09",STATUS:"To go",LOCATION:"急冷地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.200×11.164",WEIGHT_UNIT:79,WEIGHT_ATT:316,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"山东美陵",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0409",DESCRIPTION:"SECONDARY QUENCH WATER COOLERS",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-18",RAS_ROS:"2023-04-15"},
    // {NO:397,PACKAGE:"P1",AREA:"OR",TAG_NO:"2522E016B",TAG_DESCR:"SECONDARY QUENCH WATER COOLERS",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-09",LIFTING_PLAN_WK:"09-Jun-23",LIFTING_FORECAST:"2023-06-09",STATUS:"To go",LOCATION:"急冷地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.200×11.164",WEIGHT_UNIT:79,WEIGHT_ATT:316,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"山东美陵",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0409",DESCRIPTION:"SECONDARY QUENCH WATER COOLERS",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-18",RAS_ROS:"2023-04-15"},
    // {NO:398,PACKAGE:"P1",AREA:"OR",TAG_NO:"2522E016C",TAG_DESCR:"SECONDARY QUENCH WATER COOLERS",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-09",LIFTING_PLAN_WK:"09-Jun-23",LIFTING_FORECAST:"2023-06-09",STATUS:"To go",LOCATION:"急冷地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.200×11.164",WEIGHT_UNIT:79,WEIGHT_ATT:316,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"山东美陵",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0409",DESCRIPTION:"SECONDARY QUENCH WATER COOLERS",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-18",RAS_ROS:"2023-04-15"},
    // {NO:399,PACKAGE:"P1",AREA:"OR",TAG_NO:"2522E016D",TAG_DESCR:"SECONDARY QUENCH WATER COOLERS",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-09",LIFTING_PLAN_WK:"09-Jun-23",LIFTING_FORECAST:"2023-06-09",STATUS:"To go",LOCATION:"急冷地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.200×11.164",WEIGHT_UNIT:79,WEIGHT_ATT:316,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"山东美陵",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0409",DESCRIPTION:"SECONDARY QUENCH WATER COOLERS",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-18",RAS_ROS:"2023-04-15"},
    // {NO:400,PACKAGE:"P1",AREA:"DIB",TAG_NO:"3223R001",TAG_DESCR:"SECONDARY REACTOR",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-31",DELIVERY_FORECAST:"2023-05-31",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-09",LIFTING_PLAN_WK:"09-Jun-23",LIFTING_FORECAST:"2023-06-09",STATUS:"To go",LOCATION:"1D框架 EL+10",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.2×7.2(T.T)",WEIGHT_UNIT:65,WEIGHT_ATT:65,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:" 江南锅炉",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-2",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1362-EQ-R-E-0201",DESCRIPTION:"SECONDARY REACTOR",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-03-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-03-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-18",LOA_ISSUE_PLAN:"2022-07-24",LOA_ISSUE_FORECAST:"2022-07-31",LOA_ISSUE_ACTUAL:"2022-08-03",PO_ISSUE_PLAN:"2022-09-04",PO_ISSUE_FORECAST:"2022-09-11",PO_ISSUE_ACTUAL:"2022-08-26",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-28",RAS_ROS:"2023-05-31"},
    // {NO:401,PACKAGE:"P1",AREA:"DIB",TAG_NO:"3221R001",TAG_DESCR:"PRIMARY REACTOR",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-31",DELIVERY_FORECAST:"2023-05-31",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-09",LIFTING_PLAN_WK:"09-Jun-23",LIFTING_FORECAST:"2023-06-09",STATUS:"To go",LOCATION:"1D框架 EL+10",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.2×7.2(T.T)",WEIGHT_UNIT:65,WEIGHT_ATT:65,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:" 江南锅炉",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-2",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1362-EQ-R-E-0201",DESCRIPTION:"PRIMARY REACTOR",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-03-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-03-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-18",LOA_ISSUE_PLAN:"2022-07-24",LOA_ISSUE_FORECAST:"2022-07-31",LOA_ISSUE_ACTUAL:"2022-08-03",PO_ISSUE_PLAN:"2022-09-04",PO_ISSUE_FORECAST:"2022-09-11",PO_ISSUE_ACTUAL:"2022-08-26",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-28",RAS_ROS:"2023-05-31"},
    // {NO:402,PACKAGE:"P1",AREA:"OR",TAG_NO:"2590E002",TAG_DESCR:"COLD FLARE SUPERHEATER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-11",LIFTING_PLAN_WK:"16-Jun-23",LIFTING_FORECAST:"2023-06-11",STATUS:"To go",LOCATION:"5C EL+17.0",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.6×9.031",WEIGHT_UNIT:43.7,WEIGHT_ATT:43.7,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:" 江南锅炉",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0407",DESCRIPTION:"COLD FLARE SUPERHEATER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-05",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-05",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-14",LOA_ISSUE_PLAN:"2022-08-19",LOA_ISSUE_FORECAST:"2022-07-14",LOA_ISSUE_ACTUAL:"2022-07-08",PO_ISSUE_PLAN:"2022-09-30",PO_ISSUE_FORECAST:"2022-08-25",PO_ISSUE_ACTUAL:"2022-08-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-23",RAS_ROS:"2023-04-15"},
    // {NO:403,PACKAGE:"P1",AREA:"GHU",TAG_NO:"3610D002",TAG_DESCR:"HD1 HOT SEPARATOR",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-30",DELIVERY_FORECAST:"2023-05-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-11",LIFTING_PLAN_WK:"16-Jun-23",LIFTING_FORECAST:"2023-06-11",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ3.1×20.13",WEIGHT_UNIT:80,WEIGHT_ATT:80,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"天津瀚洋",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1278-EQ-R-E-0301",DESCRIPTION:"HD1 HOT SEPARATOR",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-11",LOA_ISSUE_PLAN:"2022-08-22",LOA_ISSUE_FORECAST:"2022-08-18",LOA_ISSUE_ACTUAL:"2022-08-16",PO_ISSUE_PLAN:"2022-10-03",PO_ISSUE_FORECAST:"2022-09-29",PO_ISSUE_ACTUAL:"2022-09-20",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-26",RAS_ROS:"2023-06-15"},
    // {NO:404,PACKAGE:"P1",AREA:"GHU",TAG_NO:"3610E001",TAG_DESCR:"HD1 FEED/EFFLUENT EXCHANGER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-12",LIFTING_PLAN_WK:"16-Jun-23",LIFTING_FORECAST:"2023-06-12",STATUS:"To go",LOCATION:"ST13 地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ1.6×11.491",WEIGHT_UNIT:53,WEIGHT_ATT:53,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"耐尔能源",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1278-EQ-R-E-0403",DESCRIPTION:"CS HD1 FEEDEFFLUENT EXCHANGER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-25",LOA_ISSUE_PLAN:"2022-08-21",LOA_ISSUE_FORECAST:"2022-07-10",LOA_ISSUE_ACTUAL:"2022-07-20",PO_ISSUE_PLAN:"2022-10-02",PO_ISSUE_FORECAST:"2022-08-21",PO_ISSUE_ACTUAL:"2022-08-26",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-25",RAS_ROS:"2023-05-23"},
    // {NO:405,PACKAGE:"P1",AREA:"GHU",TAG_NO:"3640E012",TAG_DESCR:"DETOLUENIZER REBOILER",TAG_TYPE:"V",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-13",LIFTING_PLAN_WK:"16-Jun-23",LIFTING_FORECAST:"2023-06-13",STATUS:"To go",LOCATION:"ST15框架",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.1×9.763",WEIGHT_UNIT:72,WEIGHT_ATT:72,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"耐尔能源",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-2",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1278-EQ-R-E-0403",DESCRIPTION:"DETOLUENIZER REBOILER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-25",LOA_ISSUE_PLAN:"2022-08-21",LOA_ISSUE_FORECAST:"2022-07-10",LOA_ISSUE_ACTUAL:"2022-07-20",PO_ISSUE_PLAN:"2022-10-02",PO_ISSUE_FORECAST:"2022-08-21",PO_ISSUE_ACTUAL:"2022-08-26",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-25",RAS_ROS:"2023-05-23"},
    // {NO:406,PACKAGE:"P1",AREA:"OR",TAG_NO:"2580E003",TAG_DESCR:"LLP Polluted Condensate Exchanger",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-13",LIFTING_PLAN_WK:"16-Jun-23",LIFTING_FORECAST:"2023-06-13",STATUS:"To go",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.0×12.851",WEIGHT_UNIT:60,WEIGHT_ATT:60,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"湖北长江",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-EQ-R-E-0410",DESCRIPTION:"LLP Polluted Condensate Exchanger",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-06-25",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-08-06",PO_ISSUE_ACTUAL:"2022-08-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-19",RAS_ROS:"2023-04-15"},
    // {NO:407,PACKAGE:"P2",AREA:"GPPE",TAG_NO:"5280BN007",TAG_DESCR:"Prime Pellet Silo",TAG_TYPE:"V",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-13",LIFTING_PLAN_WK:"16-Jun-23",LIFTING_FORECAST:"2023-06-13",STATUS:"To go",LOCATION:"结构",SHIPMENT_NO:"TBD",DIMENSION_M:"φ6.0x24.5",WEIGHT_UNIT:20,WEIGHT_ATT:120,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1213-EQ-R-E-SL06",DESCRIPTION:"Scrap Pellet Silo",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-16",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",RAS_ROS:"2023-07-30"},
    // {NO:408,PACKAGE:"P2",AREA:"GPPE",TAG_NO:"5280BN007",TAG_DESCR:"Scrap Pellet Silo",TAG_TYPE:"V",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-13",LIFTING_PLAN_WK:"16-Jun-23",LIFTING_FORECAST:"2023-06-13",STATUS:"To go",LOCATION:"结构",SHIPMENT_NO:"TBD",DIMENSION_M:"φ6.0x24.5",WEIGHT_UNIT:20,WEIGHT_ATT:20,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1213-EQ-R-E-SL06",DESCRIPTION:"Scrap Pellet Silo",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-16",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",RAS_ROS:"2023-07-30"},
    // {NO:409,PACKAGE:"P2",AREA:"GPPE",TAG_NO:"5280BN008",TAG_DESCR:"Sub-Prime Pellet Silo",TAG_TYPE:"V",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-13",LIFTING_PLAN_WK:"16-Jun-23",LIFTING_FORECAST:"2023-06-13",STATUS:"To go",LOCATION:"结构",SHIPMENT_NO:"TBD",DIMENSION_M:"φ6.0x24.5",WEIGHT_UNIT:20,WEIGHT_ATT:20,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1213-EQ-R-E-SL06",DESCRIPTION:"SubPrime Pellet Silo",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-16",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",RAS_ROS:"2023-07-30"},
    // {NO:410,PACKAGE:"P2",AREA:"GPPE",TAG_NO:"5280BN008",TAG_DESCR:"Prime Pellet Silo",TAG_TYPE:"V",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-13",LIFTING_PLAN_WK:"16-Jun-23",LIFTING_FORECAST:"2023-06-13",STATUS:"To go",LOCATION:"结构",SHIPMENT_NO:"TBD",DIMENSION_M:"φ6.0x24.5",WEIGHT_UNIT:20,WEIGHT_ATT:120,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1213-EQ-R-E-SL06",DESCRIPTION:"SubPrime Pellet Silo",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-16",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",RAS_ROS:"2023-07-30"},
    // {NO:411,PACKAGE:"P2",AREA:"GPPE",TAG_NO:"5280BN001",TAG_DESCR:"Prime Pellet Silo",TAG_TYPE:"V",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-14",LIFTING_PLAN_WK:"16-Jun-23",LIFTING_FORECAST:"2023-06-14",STATUS:"To go",LOCATION:"结构",SHIPMENT_NO:"TBD",DIMENSION_M:"φ6.0x24.5",WEIGHT_UNIT:20,WEIGHT_ATT:120,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1213-EQ-R-E-SL06",DESCRIPTION:"Prime Pellet Silo",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-16",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",RAS_ROS:"2023-07-30"},
    // {NO:412,PACKAGE:"P2",AREA:"GPPE",TAG_NO:"5280BN002",TAG_DESCR:"Prime Pellet Silo",TAG_TYPE:"V",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-14",LIFTING_PLAN_WK:"16-Jun-23",LIFTING_FORECAST:"2023-06-14",STATUS:"To go",LOCATION:"结构",SHIPMENT_NO:"TBD",DIMENSION_M:"φ6.0x24.5",WEIGHT_UNIT:20,WEIGHT_ATT:120,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1213-EQ-R-E-SL06",DESCRIPTION:"Prime Pellet Silo",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-16",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",RAS_ROS:"2023-07-30"},
    // {NO:413,PACKAGE:"P2",AREA:"GPPE",TAG_NO:"5280BN003",TAG_DESCR:"Prime Pellet Silo",TAG_TYPE:"V",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-14",LIFTING_PLAN_WK:"16-Jun-23",LIFTING_FORECAST:"2023-06-14",STATUS:"To go",LOCATION:"结构",SHIPMENT_NO:"TBD",DIMENSION_M:"φ6.0x24.5",WEIGHT_UNIT:20,WEIGHT_ATT:120,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1213-EQ-R-E-SL06",DESCRIPTION:"Prime Pellet Silo",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-16",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",RAS_ROS:"2023-07-30"},
    // {NO:414,PACKAGE:"P2",AREA:"GPPE",TAG_NO:"5280BN004",TAG_DESCR:"Prime Pellet Silo",TAG_TYPE:"V",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-14",LIFTING_PLAN_WK:"16-Jun-23",LIFTING_FORECAST:"2023-06-14",STATUS:"To go",LOCATION:"结构",SHIPMENT_NO:"TBD",DIMENSION_M:"φ6.0x24.5",WEIGHT_UNIT:20,WEIGHT_ATT:120,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1213-EQ-R-E-SL06",DESCRIPTION:"Prime Pellet Silo",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-16",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",RAS_ROS:"2023-07-30"},
    // {NO:415,PACKAGE:"P2",AREA:"GPPE",TAG_NO:"5180BN001",TAG_DESCR:"Prime Pellet Silo",TAG_TYPE:"V",DELIVERY_PLAN:"2023-07-30",DELIVERY_FORECAST:"2023-07-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-15",LIFTING_PLAN_WK:"16-Jun-23",LIFTING_FORECAST:"2023-06-15",STATUS:"To go",LOCATION:"结构",SHIPMENT_NO:"TBD",DIMENSION_M:"φ6.0x24.5",WEIGHT_UNIT:20,WEIGHT_ATT:120,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1213-EQ-R-E-SL06",DESCRIPTION:"Prime Pellet Silo",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-16",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",RAS_ROS:"2023-02-15"},
    // {NO:416,PACKAGE:"P2",AREA:"GPPE",TAG_NO:"5180BN002",TAG_DESCR:"Prime Pellet Silo",TAG_TYPE:"V",DELIVERY_PLAN:"2023-07-30",DELIVERY_FORECAST:"2023-07-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-15",LIFTING_PLAN_WK:"16-Jun-23",LIFTING_FORECAST:"2023-06-15",STATUS:"To go",LOCATION:"结构",SHIPMENT_NO:"TBD",DIMENSION_M:"φ6.0x24.5",WEIGHT_UNIT:20,WEIGHT_ATT:120,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1213-EQ-R-E-SL06",DESCRIPTION:"Prime Pellet Silo",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-16",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",RAS_ROS:"2023-02-15"},
    // {NO:417,PACKAGE:"P2",AREA:"GPPE",TAG_NO:"5180BN003",TAG_DESCR:"Prime Pellet Silo",TAG_TYPE:"V",DELIVERY_PLAN:"2023-07-30",DELIVERY_FORECAST:"2023-07-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-15",LIFTING_PLAN_WK:"16-Jun-23",LIFTING_FORECAST:"2023-06-15",STATUS:"To go",LOCATION:"结构",SHIPMENT_NO:"TBD",DIMENSION_M:"φ6.0x24.5",WEIGHT_UNIT:20,WEIGHT_ATT:120,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1213-EQ-R-E-SL06",DESCRIPTION:"Prime Pellet Silo",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-16",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",RAS_ROS:"2023-02-15"},
    // {NO:418,PACKAGE:"P2",AREA:"GPPE",TAG_NO:"5180BN004",TAG_DESCR:"Prime Pellet Silo",TAG_TYPE:"V",DELIVERY_PLAN:"2023-07-30",DELIVERY_FORECAST:"2023-07-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-15",LIFTING_PLAN_WK:"16-Jun-23",LIFTING_FORECAST:"2023-06-15",STATUS:"To go",LOCATION:"结构",SHIPMENT_NO:"TBD",DIMENSION_M:"φ6.0x24.5",WEIGHT_UNIT:20,WEIGHT_ATT:120,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1213-EQ-R-E-SL06",DESCRIPTION:"Prime Pellet Silo",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-16",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",RAS_ROS:"2023-02-15"},
    // {NO:419,PACKAGE:"P2",AREA:"GPPE",TAG_NO:"5180BN005",TAG_DESCR:"Prime Pellet Silo",TAG_TYPE:"V",DELIVERY_PLAN:"2023-07-30",DELIVERY_FORECAST:"2023-07-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-15",LIFTING_PLAN_WK:"16-Jun-23",LIFTING_FORECAST:"2023-06-15",STATUS:"To go",LOCATION:"结构",SHIPMENT_NO:"TBD",DIMENSION_M:"φ6.0x24.5",WEIGHT_UNIT:20,WEIGHT_ATT:120,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1213-EQ-R-E-SL06",DESCRIPTION:"Prime Pellet Silo",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-16",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",RAS_ROS:"2023-02-15"},
    // {NO:420,PACKAGE:"P2",AREA:"GPPE",TAG_NO:"5180BN006",TAG_DESCR:"Prime Pellet Silo",TAG_TYPE:"V",DELIVERY_PLAN:"2023-07-30",DELIVERY_FORECAST:"2023-07-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-15",LIFTING_PLAN_WK:"16-Jun-23",LIFTING_FORECAST:"2023-06-15",STATUS:"To go",LOCATION:"结构",SHIPMENT_NO:"TBD",DIMENSION_M:"φ6.0x24.5",WEIGHT_UNIT:20,WEIGHT_ATT:120,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1213-EQ-R-E-SL06",DESCRIPTION:"Prime Pellet Silo",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-16",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",RAS_ROS:"2023-02-15"},
    // {NO:421,PACKAGE:"P1",AREA:"GHU",TAG_NO:"3620E013A",TAG_DESCR:"RERUN TOWER REBOILERS",TAG_TYPE:"V",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-15",LIFTING_PLAN_WK:"16-Jun-23",LIFTING_FORECAST:"2023-06-15",STATUS:"To go",LOCATION:"ST2  EL+7.5",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.8x11.653",WEIGHT_UNIT:152,WEIGHT_ATT:304,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"耐尔能源",DRAWING:"有工程图 Engineering dwg issued，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1278-EQ-R-E-0403",DESCRIPTION:"RERUN TOWER REBOILERS",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-25",LOA_ISSUE_PLAN:"2022-08-21",LOA_ISSUE_FORECAST:"2022-07-10",LOA_ISSUE_ACTUAL:"2022-07-20",PO_ISSUE_PLAN:"2022-10-02",PO_ISSUE_FORECAST:"2022-08-21",PO_ISSUE_ACTUAL:"2022-08-26",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-25",RAS_ROS:"2023-05-23"},
    // {NO:422,PACKAGE:"P1",AREA:"GHU",TAG_NO:"3620E013B",TAG_DESCR:"RERUN TOWER REBOILERS",TAG_TYPE:"V",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-15",LIFTING_PLAN_WK:"16-Jun-23",LIFTING_FORECAST:"2023-06-15",STATUS:"To go",LOCATION:"ST2  EL+7.5",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.8x11.653",WEIGHT_UNIT:152,WEIGHT_ATT:304,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"耐尔能源",DRAWING:"有工程图 Engineering dwg issued，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-1",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1278-EQ-R-E-0403",DESCRIPTION:"RERUN TOWER REBOILERS",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-25",LOA_ISSUE_PLAN:"2022-08-21",LOA_ISSUE_FORECAST:"2022-07-10",LOA_ISSUE_ACTUAL:"2022-07-20",PO_ISSUE_PLAN:"2022-10-02",PO_ISSUE_FORECAST:"2022-08-21",PO_ISSUE_ACTUAL:"2022-08-26",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-25",RAS_ROS:"2023-05-23"},
    // {NO:423,PACKAGE:"P2",AREA:"PP",TAG_NO:"6140T003",TAG_DESCR:"Deethenizer",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-23",DELIVERY_FORECAST:"2023-05-23",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-16",LIFTING_PLAN_WK:"16-Jun-23",LIFTING_FORECAST:"2023-06-16",STATUS:"To go",LOCATION:"反应框架框架 EL+21.6",SHIPMENT_NO:"TBD",DIMENSION_M:"φ1.2×28.623",WEIGHT_UNIT:24.5,WEIGHT_ATT:24.5,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"耐尔能源",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1219-EQ-R-E-0101",DESCRIPTION:"Deethenizer",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-04-01",LOA_ISSUE_PLAN:"2022-09-19",LOA_ISSUE_FORECAST:"2022-08-29",LOA_ISSUE_ACTUAL:"2022-08-26",PO_ISSUE_PLAN:"2022-10-31",PO_ISSUE_FORECAST:"2022-10-07",PO_ISSUE_ACTUAL:"2022-09-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-05-17",RAS_ROS:"2023-05-23"},
    // {NO:424,PACKAGE:"P1",AREA:"OR",TAG_NO:"2521E025A",TAG_DESCR:"QO TRIM COOLER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-16",LIFTING_PLAN_WK:"16-Jun-23",LIFTING_FORECAST:"2023-06-16",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.3×12.024",WEIGHT_UNIT:98,WEIGHT_ATT:98,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"山东美陵",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"有工程图 Engineering dwg issued，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0409",DESCRIPTION:"QO TRIM COOLER 6 CS",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-06-24",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-08-05",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-18",RAS_ROS:"2023-04-15"},
    // {NO:425,PACKAGE:"P1",AREA:"OR",TAG_NO:"2521E025B",TAG_DESCR:"QO TRIM COOLER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-16",LIFTING_PLAN_WK:"16-Jun-23",LIFTING_FORECAST:"2023-06-16",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.3×12.025",WEIGHT_UNIT:98,WEIGHT_ATT:98,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"山东美陵",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"有工程图 Engineering dwg issued，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0409",DESCRIPTION:"QO TRIM COOLER 6 CS",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-06-24",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-08-05",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-18",RAS_ROS:"2023-04-15"},
    // {NO:426,PACKAGE:"P1",AREA:"OR",TAG_NO:"2521E025C",TAG_DESCR:"QO TRIM COOLER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-04-15",DELIVERY_FORECAST:"2023-04-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-17",LIFTING_PLAN_WK:"23-Jun-23",LIFTING_FORECAST:"2023-06-17",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.3×12.026",WEIGHT_UNIT:98,WEIGHT_ATT:98,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"山东美陵",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"有工程图 Engineering dwg issued，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-0409",DESCRIPTION:"QO TRIM COOLER 6 CS",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-14",LOA_ISSUE_FORECAST:"2022-06-24",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-25",PO_ISSUE_FORECAST:"2022-08-05",PO_ISSUE_ACTUAL:"2022-07-05",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-18",RAS_ROS:"2023-04-15"},
    // {NO:427,PACKAGE:"P2",AREA:"PP",TAG_NO:"6130D042",TAG_DESCR:"Vent Surge Drum",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-30",DELIVERY_FORECAST:"2023-05-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-18",LIFTING_PLAN_WK:"23-Jun-23",LIFTING_FORECAST:"2023-06-18",STATUS:"To go",LOCATION:"反应框架框架 EL+21.6",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.2×12.664",WEIGHT_UNIT:23,WEIGHT_ATT:23,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"无锡双雄",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1219-EQ-R-E-0302",DESCRIPTION:"Vent Surge Drum",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-04-18",LOA_ISSUE_PLAN:"2022-09-19",LOA_ISSUE_FORECAST:"2022-07-30",LOA_ISSUE_ACTUAL:"2022-07-27",PO_ISSUE_PLAN:"2022-10-31",PO_ISSUE_FORECAST:"2022-09-07",PO_ISSUE_ACTUAL:"2022-09-18",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-16",RAS_ROS:"2023-05-30"},
    // {NO:428,PACKAGE:"P1",AREA:"GHU",TAG_NO:"3697D001",TAG_DESCR:"GHU FLARE KO DRUM",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-30",DELIVERY_FORECAST:"2023-05-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-18",LIFTING_PLAN_WK:"23-Jun-23",LIFTING_FORECAST:"2023-06-18",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ4.9×14.78",WEIGHT_UNIT:65,WEIGHT_ATT:65,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"天津瀚洋",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1278-EQ-R-E-0301",DESCRIPTION:"GHU FLARE KO DRUM",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-11",LOA_ISSUE_PLAN:"2022-08-22",LOA_ISSUE_FORECAST:"2022-08-18",LOA_ISSUE_ACTUAL:"2022-08-16",PO_ISSUE_PLAN:"2022-10-03",PO_ISSUE_FORECAST:"2022-09-29",PO_ISSUE_ACTUAL:"2022-09-20",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-26",RAS_ROS:"2023-06-15"},
    // {NO:429,PACKAGE:"P1",AREA:"BD",TAG_NO:"3015D055",TAG_DESCR:"BD UNIT FLARE K.O DRUM",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-05",DELIVERY_FORECAST:"2023-05-05",DRESSUP_REQ:"N",DRESSUP_PROG:0,LIFTING_PLAN:"2023-06-18",LIFTING_PLAN_WK:"23-Jun-23",LIFTING_FORECAST:"2023-06-18",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ5.9×14.575",WEIGHT_UNIT:52,WEIGHT_ATT:52,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"天津瀚洋",DRAWING:"有工程图",CRANE_MAIN_FILTER:"280",CRANE_MAIN:"280",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1277-EQ-R-E-0301",DESCRIPTION:"BD UNIT FLARE KO DRUM",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-02",LOA_ISSUE_PLAN:"2022-08-22",LOA_ISSUE_FORECAST:"2022-08-18",LOA_ISSUE_ACTUAL:"2022-08-16",PO_ISSUE_PLAN:"2022-10-03",PO_ISSUE_FORECAST:"2022-09-29",PO_ISSUE_ACTUAL:"2022-09-20",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-26",RAS_ROS:"2023-06-15"},
    // {NO:430,PACKAGE:"P2",AREA:"PP",TAG_NO:"6160D061",TAG_DESCR:"Steamer",TAG_TYPE:"V",DELIVERY_PLAN:"2023-07-30",DELIVERY_FORECAST:"2023-07-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-19",LIFTING_PLAN_WK:"23-Jun-23",LIFTING_FORECAST:"2023-06-19",STATUS:"To go",LOCATION:"反应框架 EL+26",SHIPMENT_NO:"TBD",DIMENSION_M:"φ3.6×8.76(T.T)",WEIGHT_UNIT:32,WEIGHT_ATT:32,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"天华",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1219-EQ-R-E-SL08",DESCRIPTION:"Steamer",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-02-15",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-02-15",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-01-05",LOA_ISSUE_PLAN:"2022-08-06",LOA_ISSUE_FORECAST:"2022-06-15",LOA_ISSUE_ACTUAL:"2022-06-15",PO_ISSUE_PLAN:"2022-09-17",PO_ISSUE_FORECAST:"2022-07-27",PO_ISSUE_ACTUAL:"2022-08-22",DELIVERY_TO_JOB_SITE_PLAN:"2023-08-08",RAS_ROS:"2023-07-30"},
    // {NO:431,PACKAGE:"P2",AREA:"LDPE",TAG_NO:"5300D901",TAG_DESCR:"Dry Flare Knockout Drum",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-20",DELIVERY_FORECAST:"2023-06-20",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-21",LIFTING_PLAN_WK:"23-Jun-23",LIFTING_FORECAST:"2023-06-21",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ5100×20400",WEIGHT_UNIT:91.3,WEIGHT_ATT:91.3,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"张化机 Zhangjiagang Chemical Machinery",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P2-1214-EQ-R-E-SL01",DESCRIPTION:"WET FLARE KNOCKOUT DRUM I",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-15",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-15",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-04-01",LOA_ISSUE_PLAN:"2022-09-04",LOA_ISSUE_FORECAST:"2022-08-22",LOA_ISSUE_ACTUAL:"2022-08-19",PO_ISSUE_PLAN:"2022-10-16",PO_ISSUE_FORECAST:"2022-09-30",PO_ISSUE_ACTUAL:"2022-09-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-05-02",RAS_ROS:"2023-06-20"},
    // {NO:432,PACKAGE:"P2",AREA:"LDPE",TAG_NO:"5340D450",TAG_DESCR:"Utility Water Expansion Drum",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-30",DELIVERY_FORECAST:"2023-05-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-23",LIFTING_PLAN_WK:"23-Jun-23",LIFTING_FORECAST:"2023-06-23",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ3.5×8.2(T.T)",WEIGHT_UNIT:65,WEIGHT_ATT:65,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"山东豪迈",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1214-EQ-R-E-0301",DESCRIPTION:"UTILITY WATER EXPANSION DRUM",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-04-18",LOA_ISSUE_PLAN:"2022-09-19",LOA_ISSUE_FORECAST:"2022-07-30",LOA_ISSUE_ACTUAL:"2022-07-28",PO_ISSUE_PLAN:"2022-10-31",PO_ISSUE_FORECAST:"2022-09-08",PO_ISSUE_ACTUAL:"2022-09-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-05-17",RAS_ROS:"2023-06-20"},
    // {NO:433,PACKAGE:"P2",AREA:"PP",TAG_NO:"6160BN002A",TAG_DESCR:"Granules Feed Bin",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-30",DELIVERY_FORECAST:"2023-05-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-23",LIFTING_PLAN_WK:"23-Jun-23",LIFTING_FORECAST:"2023-06-23",STATUS:"To go",LOCATION:"框架 EL+49.8",SHIPMENT_NO:"TBD",DIMENSION_M:"φ6.0×25.45",WEIGHT_UNIT:28,WEIGHT_ATT:56,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1219-EQ-R-E-SL06",DESCRIPTION:"Granules Feed Bin",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-25",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",RAS_ROS:"2023-07-30"},
    // {NO:434,PACKAGE:"P2",AREA:"PP",TAG_NO:"6160BN002B",TAG_DESCR:"Granules Feed Bin",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-30",DELIVERY_FORECAST:"2023-05-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-23",LIFTING_PLAN_WK:"23-Jun-23",LIFTING_FORECAST:"2023-06-23",STATUS:"To go",LOCATION:"框架 EL+49.8",SHIPMENT_NO:"TBD",DIMENSION_M:"φ6.0×25.45",WEIGHT_UNIT:28,WEIGHT_ATT:56,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1219-EQ-R-E-SL06",DESCRIPTION:"Granules Feed Bin",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-25",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",RAS_ROS:"2023-07-30"},
    // {NO:435,PACKAGE:"P2",AREA:"PP",TAG_NO:"6260BN002A",TAG_DESCR:"Granules Feed Bin",TAG_TYPE:"V",DELIVERY_PLAN:"2023-07-20",DELIVERY_FORECAST:"2023-07-20",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-24",LIFTING_PLAN_WK:"30-Jun-23",LIFTING_FORECAST:"2023-06-24",STATUS:"To go",LOCATION:"框架 EL+49.8",SHIPMENT_NO:"TBD",DIMENSION_M:"φ6.0×25.45",WEIGHT_UNIT:28,WEIGHT_ATT:56,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1219-EQ-R-E-SL06",DESCRIPTION:"Granules Feed Bin",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-25",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",RAS_ROS:"2023-07-30"},
    // {NO:436,PACKAGE:"P2",AREA:"PP",TAG_NO:"6260BN002B",TAG_DESCR:"Granules Feed Bin",TAG_TYPE:"V",DELIVERY_PLAN:"2023-07-20",DELIVERY_FORECAST:"2023-07-20",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-24",LIFTING_PLAN_WK:"30-Jun-23",LIFTING_FORECAST:"2023-06-24",STATUS:"To go",LOCATION:"框架 EL+49.8",SHIPMENT_NO:"TBD",DIMENSION_M:"φ6.0×25.45",WEIGHT_UNIT:28,WEIGHT_ATT:56,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1219-EQ-R-E-SL06",DESCRIPTION:"Granules Feed Bin",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-25",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",RAS_ROS:"2023-07-30"},
    // {NO:437,PACKAGE:"P1",AREA:"BD",TAG_NO:"3011E024",TAG_DESCR:"AFTERWASHER CONDENSER",TAG_TYPE:"V",DELIVERY_PLAN:"2023-04-01",DELIVERY_FORECAST:"2023-04-01",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-28",LIFTING_PLAN_WK:"30-Jun-23",LIFTING_FORECAST:"2023-06-28",STATUS:"To go",LOCATION:"B2框架 EL+26.0",SHIPMENT_NO:"TBD",WEIGHT_UNIT:61,WEIGHT_ATT:61,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"大庆石油",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1277-EQ-R-E-SL01",DESCRIPTION:"AFTERWASHER CONDENSER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-12-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-12-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-17",LOA_ISSUE_PLAN:"2022-04-28",LOA_ISSUE_FORECAST:"2022-04-03",LOA_ISSUE_ACTUAL:"2022-04-11",PO_ISSUE_PLAN:"2022-06-09",PO_ISSUE_FORECAST:"2022-06-02",PO_ISSUE_ACTUAL:"2022-05-20",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-05-17"},
    // {NO:438,PACKAGE:"P2",AREA:"GPPE",TAG_NO:"5090D064",TAG_DESCR:"HP Flare Knockout Drum",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-02",DELIVERY_FORECAST:"2023-05-02",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-28",LIFTING_PLAN_WK:"30-Jun-23",LIFTING_FORECAST:"2023-06-28",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ4.5x13.045",WEIGHT_UNIT:50,WEIGHT_ATT:50,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"山东豪迈",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P2-1213-EQ-R-E-0301",DESCRIPTION:"HP Flare Powder Separator and Seal Drum",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-04-27",LOA_ISSUE_PLAN:"2022-09-09",LOA_ISSUE_FORECAST:"2022-08-22",LOA_ISSUE_ACTUAL:"2022-08-19",PO_ISSUE_PLAN:"2022-10-21",PO_ISSUE_FORECAST:"2022-09-30",PO_ISSUE_ACTUAL:"2022-09-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-05-07",RAS_ROS:"2023-05-15"},
    // {NO:439,PACKAGE:"P1",AREA:"BTX",TAG_NO:"3820D000A",TAG_DESCR:"CLAY TREATERS",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-30",DELIVERY_FORECAST:"2023-05-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-29",LIFTING_PLAN_WK:"30-Jun-23",LIFTING_FORECAST:"2023-06-29",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.8×19.2",WEIGHT_UNIT:60,WEIGHT_ATT:120,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"天津瀚洋",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t-1",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1113-EQ-R-E-0301",DESCRIPTION:"CLAY TREATERS",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-16",LOA_ISSUE_PLAN:"2022-08-22",LOA_ISSUE_FORECAST:"2022-08-18",LOA_ISSUE_ACTUAL:"2022-08-16",PO_ISSUE_PLAN:"2022-10-03",PO_ISSUE_FORECAST:"2022-09-29",PO_ISSUE_ACTUAL:"2022-09-20",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-26",RAS_ROS:"2023-06-30"},
    // {NO:440,PACKAGE:"P1",AREA:"BTX",TAG_NO:"3820D000B",TAG_DESCR:"CLAY TREATERS",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-30",DELIVERY_FORECAST:"2023-05-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-29",LIFTING_PLAN_WK:"30-Jun-23",LIFTING_FORECAST:"2023-06-29",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ2.8×19.2",WEIGHT_UNIT:60,WEIGHT_ATT:120,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"天津瀚洋",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t-1",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1113-EQ-R-E-0301",DESCRIPTION:"CLAY TREATERS",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-16",LOA_ISSUE_PLAN:"2022-08-22",LOA_ISSUE_FORECAST:"2022-08-18",LOA_ISSUE_ACTUAL:"2022-08-16",PO_ISSUE_PLAN:"2022-10-03",PO_ISSUE_FORECAST:"2022-09-29",PO_ISSUE_ACTUAL:"2022-09-20",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-26",RAS_ROS:"2023-06-30"},
    // {NO:441,PACKAGE:"P2",AREA:"GPPE",TAG_NO:"5090D061",TAG_DESCR:"LP Flare Knockout Drum",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-30",DELIVERY_FORECAST:"2023-05-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-30",LIFTING_PLAN_WK:"30-Jun-23",LIFTING_FORECAST:"2023-06-30",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ4.1x12.875",WEIGHT_UNIT:35,WEIGHT_ATT:35,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"山东豪迈",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P2-1213-EQ-R-E-0302",DESCRIPTION:"LP Flare Knockout Drum",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-04-18",LOA_ISSUE_PLAN:"2022-09-19",LOA_ISSUE_FORECAST:"2022-07-30",LOA_ISSUE_ACTUAL:"2022-07-28",PO_ISSUE_PLAN:"2022-10-31",PO_ISSUE_FORECAST:"2022-09-08",PO_ISSUE_ACTUAL:"2022-09-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-05-17",RAS_ROS:"2023-06-20"},
    // {NO:442,PACKAGE:"P1",AREA:"BD",TAG_NO:"3013E041",TAG_DESCR:"PROPYNE COLUMN CONDENSER",TAG_TYPE:"V",DELIVERY_PLAN:"2023-04-01",DELIVERY_FORECAST:"2023-04-01",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-06-30",LIFTING_PLAN_WK:"30-Jun-23",LIFTING_FORECAST:"2023-06-30",STATUS:"To go",LOCATION:"B4框架 EL+29.4",SHIPMENT_NO:"TBD",WEIGHT_UNIT:35,WEIGHT_ATT:35,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"大庆石油",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1277-EQ-R-E-SL01",DESCRIPTION:"PROPYNE COLUMN CONDENSER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-12-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-12-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-17",LOA_ISSUE_PLAN:"2022-04-28",LOA_ISSUE_FORECAST:"2022-04-03",LOA_ISSUE_ACTUAL:"2022-04-11",PO_ISSUE_PLAN:"2022-06-09",PO_ISSUE_FORECAST:"2022-06-02",PO_ISSUE_ACTUAL:"2022-05-20",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-05-17"},
    // {NO:443,PACKAGE:"P1",AREA:"BTX",TAG_NO:"3810D020",TAG_DESCR:"RECOVERY COLUMN RECEIVER",TAG_TYPE:"H",DELIVERY_PLAN:"2023-05-30",DELIVERY_FORECAST:"2023-05-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-01",LIFTING_PLAN_WK:"07-Jul-23",LIFTING_FORECAST:"2023-07-01",STATUS:"To go",LOCATION:"T1框架",SHIPMENT_NO:"TBD",DIMENSION_M:"φ4.4×15.828",WEIGHT_UNIT:63,WEIGHT_ATT:63,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"天津瀚洋",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1113-EQ-R-E-0301",DESCRIPTION:"RECOVERY COLUMN RECEIVER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-28",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-03-16",LOA_ISSUE_PLAN:"2022-08-22",LOA_ISSUE_FORECAST:"2022-08-18",LOA_ISSUE_ACTUAL:"2022-08-16",PO_ISSUE_PLAN:"2022-10-03",PO_ISSUE_FORECAST:"2022-09-29",PO_ISSUE_ACTUAL:"2022-09-20",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-26",RAS_ROS:"2023-06-30"},
    // {NO:444,PACKAGE:"P2",AREA:"GPPE",TAG_NO:"5150D002",TAG_DESCR:"Conveying Gas Surge Drum",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-30",DELIVERY_FORECAST:"2023-05-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-02",LIFTING_PLAN_WK:"07-Jul-23",LIFTING_FORECAST:"2023-07-02",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ4.3x12.05",WEIGHT_UNIT:35,WEIGHT_ATT:35,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"山东豪迈",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P2-1213-EQ-R-E-0302",DESCRIPTION:"Conveying Gas Surge Drum",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-04-18",LOA_ISSUE_PLAN:"2022-09-19",LOA_ISSUE_FORECAST:"2022-07-30",LOA_ISSUE_ACTUAL:"2022-07-28",PO_ISSUE_PLAN:"2022-10-31",PO_ISSUE_FORECAST:"2022-09-08",PO_ISSUE_ACTUAL:"2022-09-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-05-17",RAS_ROS:"2023-06-20"},
    // {NO:445,PACKAGE:"P1",AREA:"BTX",TAG_NO:"3810E012",TAG_DESCR:"EXTRACTIVE DISTILLATION COLUMN REBOILER",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-15",DELIVERY_FORECAST:"2023-05-15",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-03",LIFTING_PLAN_WK:"07-Jul-23",LIFTING_FORECAST:"2023-07-03",STATUS:"To go",LOCATION:"框架",SHIPMENT_NO:"TBD",DIMENSION_M:"φ1.7×8.54",WEIGHT_UNIT:37,WEIGHT_ATT:37,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:" 江南锅炉",DRAWING:"有工程图",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t-1",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1113-EQ-R-E-0402",DESCRIPTION:"EXTRACTIVE DISTILLATION COLUMN REBOILER",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-13",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-13",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-02-14",LOA_ISSUE_PLAN:"2022-08-11",LOA_ISSUE_FORECAST:"2022-07-06",LOA_ISSUE_ACTUAL:"2022-06-30",PO_ISSUE_PLAN:"2022-09-22",PO_ISSUE_FORECAST:"2022-08-17",PO_ISSUE_ACTUAL:"2022-08-17",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-15",RAS_ROS:"2023-05-28"},
    // {NO:446,PACKAGE:"P2",AREA:"LDPE",TAG_NO:"5340D400",TAG_DESCR:"HP SEPARATE",TAG_TYPE:"V",DELIVERY_PLAN:"2023-06-30",DELIVERY_FORECAST:"2023-06-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-04",LIFTING_PLAN_WK:"07-Jul-23",LIFTING_FORECAST:"2023-07-04",STATUS:"To go",LOCATION:"框架",SHIPMENT_NO:"TBD",DIMENSION_M:"φ3500×8000",WEIGHT_UNIT:125,WEIGHT_ATT:125,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"VDL KTI",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",CRANE_TAIL:"280t Crane-2",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1214-EQ-R-E-LL01",DESCRIPTION:"High Pressure VesselsGroup 3HP Sep EV SepGroup2 IP KO Drums",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-08-01",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-06-21",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-06-21",LOA_ISSUE_PLAN:"2021-12-25",LOA_ISSUE_FORECAST:"2021-12-14",LOA_ISSUE_ACTUAL:"2021-12-10",PO_ISSUE_PLAN:"2022-02-05",PO_ISSUE_FORECAST:"2022-01-21",PO_ISSUE_ACTUAL:"2021-12-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-09-07",RAS_ROS:"2023-06-30"},
    // {NO:447,PACKAGE:"P2",AREA:"LDPE",TAG_NO:"5340D401",TAG_DESCR:"Reaction tank",TAG_TYPE:"V",DELIVERY_PLAN:"2023-06-30",DELIVERY_FORECAST:"2023-06-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-05",LIFTING_PLAN_WK:"07-Jul-23",LIFTING_FORECAST:"2023-07-03",STATUS:"To go",LOCATION:"框架",SHIPMENT_NO:"TBD",WEIGHT_UNIT:125,WEIGHT_ATT:125,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"VDL KTI",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1214-EQ-R-E-LL01",DESCRIPTION:"High Pressure VesselsGroup 3HP Sep EV SepGroup2 IP KO Drums",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-08-01",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-06-21",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-06-21",LOA_ISSUE_PLAN:"2021-12-25",LOA_ISSUE_FORECAST:"2021-12-14",LOA_ISSUE_ACTUAL:"2021-12-10",PO_ISSUE_PLAN:"2022-02-05",PO_ISSUE_FORECAST:"2022-01-21",PO_ISSUE_ACTUAL:"2021-12-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-09-07",RAS_ROS:"2023-06-30"},
    // {NO:448,PACKAGE:"P2",AREA:"LDPE",TAG_NO:"5340D401",TAG_DESCR:"Reaction tank steel structure",TAG_TYPE:"V",DELIVERY_PLAN:"2023-06-30",DELIVERY_FORECAST:"2023-06-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-05",LIFTING_PLAN_WK:"07-Jul-23",LIFTING_FORECAST:"2023-07-03",STATUS:"To go",LOCATION:"框架",SHIPMENT_NO:"TBD",WEIGHT_UNIT:120,WEIGHT_ATT:120,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"VDL KTI",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1214-EQ-R-E-LL01",DESCRIPTION:"High Pressure VesselsGroup 3HP Sep EV SepGroup2 IP KO Drums",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-08-01",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-06-21",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-06-21",LOA_ISSUE_PLAN:"2021-12-25",LOA_ISSUE_FORECAST:"2021-12-14",LOA_ISSUE_ACTUAL:"2021-12-10",PO_ISSUE_PLAN:"2022-02-05",PO_ISSUE_FORECAST:"2022-01-21",PO_ISSUE_ACTUAL:"2021-12-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-09-07",RAS_ROS:"2023-06-30"},
    // {NO:449,PACKAGE:"P2",AREA:"GPPE",TAG_NO:"5250D002",TAG_DESCR:"Conveying Gas Surge Drum",TAG_TYPE:"V",DELIVERY_PLAN:"2023-05-30",DELIVERY_FORECAST:"2023-05-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-05",LIFTING_PLAN_WK:"07-Jul-23",LIFTING_FORECAST:"2023-07-05",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",DIMENSION_M:"φ4.3x12.05",WEIGHT_UNIT:35,WEIGHT_ATT:35,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"山东豪迈",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P2-1213-EQ-R-E-0302",DESCRIPTION:"Conveying Gas Surge Drum",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-04-18",LOA_ISSUE_PLAN:"2022-09-19",LOA_ISSUE_FORECAST:"2022-07-30",LOA_ISSUE_ACTUAL:"2022-07-28",PO_ISSUE_PLAN:"2022-10-31",PO_ISSUE_FORECAST:"2022-09-08",PO_ISSUE_ACTUAL:"2022-09-30",DELIVERY_TO_JOB_SITE_PLAN:"2023-05-17",RAS_ROS:"2023-06-20"},
    // {NO:450,PACKAGE:"P3",AREA:"Flare",TAG_NO:"9001D001A",TAG_DESCR:"KO Drum for Common Elevated HP Flare",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-30",DELIVERY_FORECAST:"2023-06-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-05",LIFTING_PLAN_WK:"07-Jul-23",LIFTING_FORECAST:"2023-07-05",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",WEIGHT_UNIT:85,WEIGHT_ATT:170,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"TBD",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans"},
    // {NO:451,PACKAGE:"P3",AREA:"Flare",TAG_NO:"9001D001B",TAG_DESCR:"KO Drum for Common Elevated HP Flare",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-30",DELIVERY_FORECAST:"2023-06-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-05",LIFTING_PLAN_WK:"07-Jul-23",LIFTING_FORECAST:"2023-07-05",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",WEIGHT_UNIT:85,WEIGHT_ATT:170,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"TBD",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans"},
    // {NO:452,PACKAGE:"P3",AREA:"Flare",TAG_NO:"9001D002A",TAG_DESCR:"Water Seal Drum for Common Elevated HP Flare",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-30",DELIVERY_FORECAST:"2023-06-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-07",LIFTING_PLAN_WK:"07-Jul-23",LIFTING_FORECAST:"2023-07-07",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",WEIGHT_UNIT:85,WEIGHT_ATT:170,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"TBD",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans"},
    // {NO:453,PACKAGE:"P3",AREA:"Flare",TAG_NO:"9001D002B",TAG_DESCR:"Water Seal Drum for Common Elevated HP Flare",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-30",DELIVERY_FORECAST:"2023-06-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-07",LIFTING_PLAN_WK:"07-Jul-23",LIFTING_FORECAST:"2023-07-07",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",WEIGHT_UNIT:85,WEIGHT_ATT:170,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"TBD",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans"},
    // {NO:454,PACKAGE:"P3",AREA:"Flare",TAG_NO:"9002D001A",TAG_DESCR:" KO Drum for Common Ground  olefin HP Flare ",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-30",DELIVERY_FORECAST:"2023-06-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-09",LIFTING_PLAN_WK:"14-Jul-23",LIFTING_FORECAST:"2023-07-09",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",WEIGHT_UNIT:85,WEIGHT_ATT:170,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"TBD",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans"},
    // {NO:455,PACKAGE:"P3",AREA:"Flare",TAG_NO:"9002D001B",TAG_DESCR:" KO Drum for Common Ground  olefin HP Flare ",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-30",DELIVERY_FORECAST:"2023-06-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-09",LIFTING_PLAN_WK:"14-Jul-23",LIFTING_FORECAST:"2023-07-09",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",WEIGHT_UNIT:85,WEIGHT_ATT:170,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"TBD",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans"},
    // {NO:456,PACKAGE:"P1",AREA:"OR",TAG_NO:"1020FAN311",TAG_DESCR:"Induced Draft Fan风机",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-30",DELIVERY_FORECAST:"2023-06-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-10",LIFTING_PLAN_WK:"14-Jul-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"9500×4800",WEIGHT_UNIT:27,WEIGHT_ATT:270,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"BOLDROCCHI S.r.l.",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:457,PACKAGE:"P1",AREA:"OR",TAG_NO:"1019FAN311",TAG_DESCR:"Induced Draft Fan风机",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-30",DELIVERY_FORECAST:"2023-06-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-10",LIFTING_PLAN_WK:"14-Jul-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"9500×4800",WEIGHT_UNIT:27,WEIGHT_ATT:270,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"BOLDROCCHI S.r.l.",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:458,PACKAGE:"P1",AREA:"OR",TAG_NO:"1018FAN311",TAG_DESCR:"Induced Draft Fan风机",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-30",DELIVERY_FORECAST:"2023-06-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-10",LIFTING_PLAN_WK:"14-Jul-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"9500×4800",WEIGHT_UNIT:27,WEIGHT_ATT:270,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"BOLDROCCHI S.r.l.",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:459,PACKAGE:"P1",AREA:"OR",TAG_NO:"1017FAN311",TAG_DESCR:"Induced Draft Fan风机",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-30",DELIVERY_FORECAST:"2023-06-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-10",LIFTING_PLAN_WK:"14-Jul-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"9500×4800",WEIGHT_UNIT:27,WEIGHT_ATT:270,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"BOLDROCCHI S.r.l.",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:460,PACKAGE:"P1",AREA:"OR",TAG_NO:"1016FAN311",TAG_DESCR:"Induced Draft Fan风机",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-30",DELIVERY_FORECAST:"2023-06-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-10",LIFTING_PLAN_WK:"14-Jul-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"9500×4800",WEIGHT_UNIT:27,WEIGHT_ATT:270,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"BOLDROCCHI S.r.l.",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:461,PACKAGE:"P1",AREA:"OR",TAG_NO:"1015FAN311",TAG_DESCR:"Induced Draft Fan风机",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-30",DELIVERY_FORECAST:"2023-06-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-10",LIFTING_PLAN_WK:"14-Jul-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"9500×4800",WEIGHT_UNIT:27,WEIGHT_ATT:270,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"BOLDROCCHI S.r.l.",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:462,PACKAGE:"P1",AREA:"OR",TAG_NO:"1014FAN311",TAG_DESCR:"Induced Draft Fan风机",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-30",DELIVERY_FORECAST:"2023-06-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-10",LIFTING_PLAN_WK:"14-Jul-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"9500×4800",WEIGHT_UNIT:27,WEIGHT_ATT:270,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"BOLDROCCHI S.r.l.",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:463,PACKAGE:"P1",AREA:"OR",TAG_NO:"1013FAN311",TAG_DESCR:"Induced Draft Fan风机",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-30",DELIVERY_FORECAST:"2023-06-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-10",LIFTING_PLAN_WK:"14-Jul-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"9500×4800",WEIGHT_UNIT:27,WEIGHT_ATT:270,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"BOLDROCCHI S.r.l.",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:464,PACKAGE:"P1",AREA:"OR",TAG_NO:"1011FAN311",TAG_DESCR:"Induced Draft Fan风机",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-30",DELIVERY_FORECAST:"2023-06-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-10",LIFTING_PLAN_WK:"14-Jul-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"9500×4800",WEIGHT_UNIT:27,WEIGHT_ATT:270,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"BOLDROCCHI S.r.l.",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:465,PACKAGE:"P1",AREA:"OR",TAG_NO:"1012FAN311",TAG_DESCR:"Induced Draft Fan风机",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-30",DELIVERY_FORECAST:"2023-06-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-10",LIFTING_PLAN_WK:"14-Jul-23",STATUS:"To go",LOCATION:"炉区结构",SHIPMENT_NO:"TBD",DIMENSION_M:"9500×4800",WEIGHT_UNIT:27,WEIGHT_ATT:270,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"BOLDROCCHI S.r.l.",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:466,PACKAGE:"P3",AREA:"Flare",TAG_NO:"9002D002A",TAG_DESCR:"Water Seal Drum for Common Ground  olefin HP Flare",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-30",DELIVERY_FORECAST:"2023-06-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-11",LIFTING_PLAN_WK:"14-Jul-23",LIFTING_FORECAST:"2023-07-11",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",WEIGHT_UNIT:85,WEIGHT_ATT:170,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"TBD",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans"},
    // {NO:467,PACKAGE:"P3",AREA:"Flare",TAG_NO:"9002D002B",TAG_DESCR:"Water Seal Drum for Common Ground  olefin HP Flare",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-30",DELIVERY_FORECAST:"2023-06-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-11",LIFTING_PLAN_WK:"14-Jul-23",LIFTING_FORECAST:"2023-07-11",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",WEIGHT_UNIT:85,WEIGHT_ATT:170,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"TBD",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans"},
    // {NO:468,PACKAGE:"P3",AREA:"Flare",TAG_NO:"9002D003",TAG_DESCR:"KO Drum for Common Ground  polyolefin  HP Flare ",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-30",DELIVERY_FORECAST:"2023-06-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-13",LIFTING_PLAN_WK:"14-Jul-23",LIFTING_FORECAST:"2023-07-13",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",WEIGHT_UNIT:85,WEIGHT_ATT:85,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"TBD",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P3-1789-EQ-R-E-0301",DESCRIPTION:"KO Drum for Common Ground polyolefin HP Flare",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-07-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-08-29",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-08-30",LOA_ISSUE_PLAN:"2022-11-01",LOA_ISSUE_FORECAST:"2022-12-07",LOA_ISSUE_ACTUAL:"2022-12-02",PO_ISSUE_PLAN:"2022-12-13",PO_ISSUE_FORECAST:"2023-01-18",PO_ISSUE_ACTUAL:"2023-01-18",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-05",DELIVERY_TO_JOB_SITE_FORECAST:"2023-05-06",RAS_ROS:"2023-06-30"},
    // {NO:469,PACKAGE:"P3",AREA:"Flare",TAG_NO:"9002D004",TAG_DESCR:"Water Seal Drum for Common Ground  polyolefin HP Flare ",TAG_TYPE:"H",DELIVERY_PLAN:"2023-06-30",DELIVERY_FORECAST:"2023-06-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-15",LIFTING_PLAN_WK:"21-Jul-23",LIFTING_FORECAST:"2023-07-15",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",WEIGHT_UNIT:85,WEIGHT_ATT:85,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"TBD",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT:"Sea  transportation",TRANSPORT_COMPANY:"Sinotrans"},
    // {NO:470,PACKAGE:"TBD",AREA:"TBD",TAG_NO:"CGC",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-15",LIFTING_PLAN_WK:"21-Jul-23",STATUS:"To go",SHIPMENT_NO:"TBD",DIMENSION_M:"14000×5000×4500",WEIGHT_UNIT:150,CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:471,PACKAGE:"P2",AREA:"PP",TAG_NO:"6280BN009",TAG_DESCR:"Prime Pellet Silo",TAG_TYPE:"V",DELIVERY_PLAN:"2023-07-20",DELIVERY_FORECAST:"2023-07-20",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-20",LIFTING_PLAN_WK:"21-Jul-23",LIFTING_FORECAST:"2023-07-20",STATUS:"To go",LOCATION:"框架 EL+49.8",SHIPMENT_NO:"TBD",DIMENSION_M:"φ6.0×24.2",WEIGHT_UNIT:28,WEIGHT_ATT:28,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1219-EQ-R-E-SL06",DESCRIPTION:"Prime Pellet Silo",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-25",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",RAS_ROS:"2023-07-30"},
    // {NO:472,PACKAGE:"P2",AREA:"PP",TAG_NO:"6180BN011",TAG_DESCR:"Prime Pellet Silo",TAG_TYPE:"V",DELIVERY_PLAN:"2023-07-20",DELIVERY_FORECAST:"2023-07-20",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-20",LIFTING_PLAN_WK:"21-Jul-23",LIFTING_FORECAST:"2023-07-20",STATUS:"To go",LOCATION:"框架 EL+49.8",SHIPMENT_NO:"TBD",DIMENSION_M:"φ6.0×24.2",WEIGHT_UNIT:28,WEIGHT_ATT:28,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1219-EQ-R-E-SL06",DESCRIPTION:"Prime Pellet Silo",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-25",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",RAS_ROS:"2023-07-30"},
    // {NO:473,PACKAGE:"P2",AREA:"PP",TAG_NO:"6280BN010",TAG_DESCR:"Prime Pellet Silo",TAG_TYPE:"V",DELIVERY_PLAN:"2023-07-20",DELIVERY_FORECAST:"2023-07-20",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-21",LIFTING_PLAN_WK:"21-Jul-23",LIFTING_FORECAST:"2023-07-21",STATUS:"To go",LOCATION:"框架 EL+49.8",SHIPMENT_NO:"TBD",DIMENSION_M:"φ6.0×24.2",WEIGHT_UNIT:28,WEIGHT_ATT:28,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1219-EQ-R-E-SL06",DESCRIPTION:"Prime Pellet Silo",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-25",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",RAS_ROS:"2023-07-30"},
    // {NO:474,PACKAGE:"P2",AREA:"PP",TAG_NO:"6280BN011",TAG_DESCR:"Prime Pellet Silo",TAG_TYPE:"V",DELIVERY_PLAN:"2023-07-20",DELIVERY_FORECAST:"2023-07-20",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-21",LIFTING_PLAN_WK:"21-Jul-23",LIFTING_FORECAST:"2023-07-21",STATUS:"To go",LOCATION:"框架 EL+49.8",SHIPMENT_NO:"TBD",DIMENSION_M:"φ6.0×24.2",WEIGHT_UNIT:28,WEIGHT_ATT:28,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1219-EQ-R-E-SL06",DESCRIPTION:"Prime Pellet Silo",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-25",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",RAS_ROS:"2023-07-30"},
    // {NO:475,PACKAGE:"P2",AREA:"PP",TAG_NO:"6180BN008",TAG_DESCR:"Prime Pellet Silo",TAG_TYPE:"V",DELIVERY_PLAN:"2023-07-20",DELIVERY_FORECAST:"2023-07-20",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-22",LIFTING_PLAN_WK:"28-Jul-23",LIFTING_FORECAST:"2023-07-22",STATUS:"To go",LOCATION:"框架 EL+49.8",SHIPMENT_NO:"TBD",DIMENSION_M:"φ6.0×24.2",WEIGHT_UNIT:28,WEIGHT_ATT:28,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1219-EQ-R-E-SL06",DESCRIPTION:"Prime Pellet Silo",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-25",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",RAS_ROS:"2023-07-30"},
    // {NO:476,PACKAGE:"P2",AREA:"PP",TAG_NO:"6180BN009",TAG_DESCR:"Prime Pellet Silo",TAG_TYPE:"V",DELIVERY_PLAN:"2023-07-20",DELIVERY_FORECAST:"2023-07-20",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-22",LIFTING_PLAN_WK:"28-Jul-23",LIFTING_FORECAST:"2023-07-22",STATUS:"To go",LOCATION:"框架 EL+49.8",SHIPMENT_NO:"TBD",DIMENSION_M:"φ6.0×24.2",WEIGHT_UNIT:28,WEIGHT_ATT:28,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1219-EQ-R-E-SL06",DESCRIPTION:"Prime Pellet Silo",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-20",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-20",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-25",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",RAS_ROS:"2023-07-30"},
    // {NO:477,PACKAGE:"P2",AREA:"PP",TAG_NO:"6180BN010",TAG_DESCR:"Prime Pellet Silo",TAG_TYPE:"V",DELIVERY_PLAN:"2023-07-20",DELIVERY_FORECAST:"2023-07-20",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-22",LIFTING_PLAN_WK:"28-Jul-23",LIFTING_FORECAST:"2023-07-22",STATUS:"To go",LOCATION:"框架 EL+49.8",SHIPMENT_NO:"TBD",DIMENSION_M:"φ6.0×24.2",WEIGHT_UNIT:28,WEIGHT_ATT:28,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1219-EQ-R-E-SL06",DESCRIPTION:"Prime Pellet Silo",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-20",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-20",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-25",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",RAS_ROS:"2023-07-30"},
    // {NO:478,PACKAGE:"P2",AREA:"PP",TAG_NO:"6280BN008",TAG_DESCR:"Prime Pellet Silo",TAG_TYPE:"V",DELIVERY_PLAN:"2023-07-20",DELIVERY_FORECAST:"2023-07-20",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-22",LIFTING_PLAN_WK:"28-Jul-23",LIFTING_FORECAST:"2023-07-22",STATUS:"To go",LOCATION:"框架 EL+49.8",SHIPMENT_NO:"TBD",DIMENSION_M:"φ6.0×24.2",WEIGHT_UNIT:28,WEIGHT_ATT:28,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1219-EQ-R-E-SL06",DESCRIPTION:"Prime Pellet Silo",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-11-25",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-25",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",RAS_ROS:"2023-07-30"},
    // {NO:479,PACKAGE:"P2",AREA:"GPPE",TAG_NO:"5180BN008",TAG_DESCR:"Sub-Prime Pellet Silo",TAG_TYPE:"V",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-24",LIFTING_PLAN_WK:"28-Jul-23",LIFTING_FORECAST:"2023-07-24",STATUS:"To go",LOCATION:"结构",SHIPMENT_NO:"TBD",DIMENSION_M:"φ6.0x24.5",WEIGHT_UNIT:20,WEIGHT_ATT:20,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1213-EQ-R-E-SL06",DESCRIPTION:"SubPrime Pellet Silo",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-16",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",RAS_ROS:"2023-02-15"},
    // {NO:480,PACKAGE:"P2",AREA:"GPPE",TAG_NO:"5180BN007",TAG_DESCR:"Scrap Pellet Silo",TAG_TYPE:"V",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-26",LIFTING_PLAN_WK:"28-Jul-23",LIFTING_FORECAST:"2023-07-26",STATUS:"To go",LOCATION:"结构",SHIPMENT_NO:"TBD",DIMENSION_M:"φ6.0x24.5",WEIGHT_UNIT:20,WEIGHT_ATT:20,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1213-EQ-R-E-SL06",DESCRIPTION:"Scrap Pellet Silo",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-16",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",RAS_ROS:"2023-02-15"},
    // {NO:481,PACKAGE:"P2",AREA:"GPPE",TAG_NO:"5260BN001",TAG_DESCR:"Granules Feed Bin",TAG_TYPE:"V",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-27",LIFTING_PLAN_WK:"28-Jul-23",LIFTING_FORECAST:"2023-07-27",STATUS:"To go",LOCATION:"结构",SHIPMENT_NO:"TBD",DIMENSION_M:"φ5.5x29.07",WEIGHT_UNIT:20,WEIGHT_ATT:20,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1213-EQ-R-E-SL06",DESCRIPTION:"Granules Feed Bin",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-16",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",RAS_ROS:"2023-07-30"},
    // {NO:482,PACKAGE:"P2",AREA:"GPPE",TAG_NO:"5160BN001",TAG_DESCR:"Granules Feed Bin",TAG_TYPE:"V",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-27",LIFTING_PLAN_WK:"28-Jul-23",LIFTING_FORECAST:"2023-07-27",STATUS:"To go",LOCATION:"结构",SHIPMENT_NO:"TBD",DIMENSION_M:"φ5.5x29.07",WEIGHT_UNIT:20,WEIGHT_ATT:20,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1213-EQ-R-E-SL06",DESCRIPTION:"Granules Feed Bin",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-16",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",RAS_ROS:"2023-02-15"},
    // {NO:483,PACKAGE:"P2",AREA:"GPPE",TAG_NO:"5140BN001",TAG_DESCR:"Seed Bed Bin",TAG_TYPE:"V",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-29",LIFTING_PLAN_WK:"04-Aug-23",LIFTING_FORECAST:"2023-07-29",STATUS:"To go",LOCATION:"结构 EL+13.0",SHIPMENT_NO:"TBD",DIMENSION_M:"φ6.0x33.040",WEIGHT_UNIT:28,WEIGHT_ATT:28,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1213-EQ-R-E-SL06",DESCRIPTION:"Seed Bed Bin",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-16",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",RAS_ROS:"2023-02-15"},
    // {NO:484,PACKAGE:"P2",AREA:"GPPE",TAG_NO:"5240BN001",TAG_DESCR:"Seed Bed Bin",TAG_TYPE:"V",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-07-29",LIFTING_PLAN_WK:"04-Aug-23",LIFTING_FORECAST:"2023-07-29",STATUS:"To go",LOCATION:"结构 EL+13.0",SHIPMENT_NO:"TBD",DIMENSION_M:"φ6.0x33.040",WEIGHT_UNIT:28,WEIGHT_ATT:28,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"泽普林",DRAWING:"有工程图",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P2-1213-EQ-R-E-SL06",DESCRIPTION:"Seed Bed Bin",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-12-30",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-12-16",LOA_ISSUE_PLAN:"2022-04-30",LOA_ISSUE_FORECAST:"2022-05-15",LOA_ISSUE_ACTUAL:"2022-05-10",PO_ISSUE_PLAN:"2022-06-11",PO_ISSUE_FORECAST:"2022-06-21",PO_ISSUE_ACTUAL:"2022-06-21",DELIVERY_TO_JOB_SITE_PLAN:"2023-03-03",RAS_ROS:"2023-07-30"},
    // {NO:485,PACKAGE:"P1",AREA:"OR",TAG_NO:"1011E101",TAG_DESCR:"SECONDARY TLE FOR RG FURNACE",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-14",DELIVERY_FORECAST:"2023-03-14",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-08-20",LIFTING_PLAN_WK:"25-Aug-23",LIFTING_FORECAST:"2023-08-20",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",DIMENSION_M:"∅1.7×9.2",WEIGHT_UNIT:54.773,WEIGHT_ATT:54.773,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"茂重 Guangdong Maozhong",DRAWING:"有初版装配图 shop assembly dwg IFR",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-LL11",DESCRIPTION:"Secondary TLE",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-12-12",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-12-12",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-11-15",LOA_ISSUE_PLAN:"2022-04-11",LOA_ISSUE_FORECAST:"2022-03-29",LOA_ISSUE_ACTUAL:"2022-03-16",PO_ISSUE_PLAN:"2022-05-23",PO_ISSUE_FORECAST:"2022-05-10",PO_ISSUE_ACTUAL:"2022-04-13",DELIVERY_TO_JOB_SITE_PLAN:"2023-04-13",RAS_ROS:"2023-07-30"},
    // {NO:486,PACKAGE:"P1",AREA:"OR",TAG_NO:"1011D201",TAG_DESCR:"STEAM DRUM FOR FURNACE",TAG_TYPE:"H",DELIVERY_PLAN:"2023-07-30",DELIVERY_FORECAST:"2023-07-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-08-27",LIFTING_PLAN_WK:"01-Sep-23",LIFTING_FORECAST:"2023-08-27",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.102×16.629",WEIGHT_UNIT:90,WEIGHT_ATT:90,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"南京化工 Nanjing Petrochemical",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"初版图 Vendor dwg IFR",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-SL10",DESCRIPTION:"Steam Drums",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-05-18",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-05-18",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-04-22",LOA_ISSUE_PLAN:"2022-08-28",LOA_ISSUE_FORECAST:"2022-06-30",LOA_ISSUE_ACTUAL:"2022-06-29",PO_ISSUE_PLAN:"2022-10-09",PO_ISSUE_FORECAST:"2022-08-11",PO_ISSUE_ACTUAL:"2022-08-18",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-30"},
    // {NO:487,PACKAGE:"P1",AREA:"OR",TAG_NO:"1012D201",TAG_DESCR:"STEAM DRUM FOR FURNACE",TAG_TYPE:"H",DELIVERY_PLAN:"2023-07-30",DELIVERY_FORECAST:"2023-07-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-08-27",LIFTING_PLAN_WK:"01-Sep-23",LIFTING_FORECAST:"2023-08-27",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.324×16.58",WEIGHT_UNIT:106,WEIGHT_ATT:106,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"南京化工 Nanjing Petrochemical",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"初版图 Vendor dwg IFR",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-SL10",DESCRIPTION:"Steam Drums",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-05-18",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-05-18",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-04-22",LOA_ISSUE_PLAN:"2022-08-28",LOA_ISSUE_FORECAST:"2022-06-30",LOA_ISSUE_ACTUAL:"2022-06-29",PO_ISSUE_PLAN:"2022-10-09",PO_ISSUE_FORECAST:"2022-08-11",PO_ISSUE_ACTUAL:"2022-08-18",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-30"},
    // {NO:488,PACKAGE:"P1",AREA:"OR",TAG_NO:"1013D201",TAG_DESCR:"STEAM DRUM FOR FURNACE",TAG_TYPE:"H",DELIVERY_PLAN:"2023-07-30",DELIVERY_FORECAST:"2023-07-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-08-28",LIFTING_PLAN_WK:"01-Sep-23",LIFTING_FORECAST:"2023-08-28",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.324×16.58",WEIGHT_UNIT:106,WEIGHT_ATT:106,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"南京化工 Nanjing Petrochemical",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"初版图 Vendor dwg IFR",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-SL10",DESCRIPTION:"Steam Drums",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-05-18",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-05-18",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-04-22",LOA_ISSUE_PLAN:"2022-08-28",LOA_ISSUE_FORECAST:"2022-06-30",LOA_ISSUE_ACTUAL:"2022-06-29",PO_ISSUE_PLAN:"2022-10-09",PO_ISSUE_FORECAST:"2022-08-11",PO_ISSUE_ACTUAL:"2022-08-18",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-30"},
    // {NO:489,PACKAGE:"P1",AREA:"OR",TAG_NO:"1014D201",TAG_DESCR:"STEAM DRUM FOR FURNACE",TAG_TYPE:"H",DELIVERY_PLAN:"2023-07-30",DELIVERY_FORECAST:"2023-07-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-08-28",LIFTING_PLAN_WK:"01-Sep-23",LIFTING_FORECAST:"2023-08-28",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.324×16.58",WEIGHT_UNIT:106,WEIGHT_ATT:106,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"南京化工 Nanjing Petrochemical",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"初版图 Vendor dwg IFR",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-SL10",DESCRIPTION:"Steam Drums",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-05-18",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-05-18",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-04-22",LOA_ISSUE_PLAN:"2022-08-28",LOA_ISSUE_FORECAST:"2022-06-30",LOA_ISSUE_ACTUAL:"2022-06-29",PO_ISSUE_PLAN:"2022-10-09",PO_ISSUE_FORECAST:"2022-08-11",PO_ISSUE_ACTUAL:"2022-08-18",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-30"},
    // {NO:490,PACKAGE:"P1",AREA:"OR",TAG_NO:"1015D201",TAG_DESCR:"STEAM DRUM FOR FURNACE",TAG_TYPE:"H",DELIVERY_PLAN:"2023-07-30",DELIVERY_FORECAST:"2023-07-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-08-29",LIFTING_PLAN_WK:"01-Sep-23",LIFTING_FORECAST:"2023-08-29",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.324×16.58",WEIGHT_UNIT:106,WEIGHT_ATT:106,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"南京化工 Nanjing Petrochemical",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"初版图 Vendor dwg IFR",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-SL10",DESCRIPTION:"Steam Drums",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-05-18",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-05-18",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-04-22",LOA_ISSUE_PLAN:"2022-08-28",LOA_ISSUE_FORECAST:"2022-06-30",LOA_ISSUE_ACTUAL:"2022-06-29",PO_ISSUE_PLAN:"2022-10-09",PO_ISSUE_FORECAST:"2022-08-11",PO_ISSUE_ACTUAL:"2022-08-18",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-30"},
    // {NO:491,PACKAGE:"P1",AREA:"OR",TAG_NO:"1016D201",TAG_DESCR:"STEAM DRUM FOR FURNACE",TAG_TYPE:"H",DELIVERY_PLAN:"2023-07-30",DELIVERY_FORECAST:"2023-07-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-08-29",LIFTING_PLAN_WK:"01-Sep-23",LIFTING_FORECAST:"2023-08-29",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.324×16.58",WEIGHT_UNIT:106,WEIGHT_ATT:106,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"南京化工 Nanjing Petrochemical",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"初版图 Vendor dwg IFR",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-SL10",DESCRIPTION:"Steam Drums",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-05-18",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-05-18",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-04-22",LOA_ISSUE_PLAN:"2022-08-28",LOA_ISSUE_FORECAST:"2022-06-30",LOA_ISSUE_ACTUAL:"2022-06-29",PO_ISSUE_PLAN:"2022-10-09",PO_ISSUE_FORECAST:"2022-08-11",PO_ISSUE_ACTUAL:"2022-08-18",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-30"},
    // {NO:492,PACKAGE:"P1",AREA:"OR",TAG_NO:"1017D201",TAG_DESCR:"STEAM DRUM FOR FURNACE",TAG_TYPE:"H",DELIVERY_PLAN:"2023-07-30",DELIVERY_FORECAST:"2023-07-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-08-30",LIFTING_PLAN_WK:"01-Sep-23",LIFTING_FORECAST:"2023-08-30",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.324×16.58",WEIGHT_UNIT:106,WEIGHT_ATT:106,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"南京化工 Nanjing Petrochemical",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"初版图 Vendor dwg IFR",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-SL10",DESCRIPTION:"Steam Drums",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-05-18",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-05-18",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-04-22",LOA_ISSUE_PLAN:"2022-08-28",LOA_ISSUE_FORECAST:"2022-06-30",LOA_ISSUE_ACTUAL:"2022-06-29",PO_ISSUE_PLAN:"2022-10-09",PO_ISSUE_FORECAST:"2022-08-11",PO_ISSUE_ACTUAL:"2022-08-18",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-30"},
    // {NO:493,PACKAGE:"P1",AREA:"OR",TAG_NO:"1018D201",TAG_DESCR:"STEAM DRUM FOR FURNACE",TAG_TYPE:"H",DELIVERY_PLAN:"2023-07-30",DELIVERY_FORECAST:"2023-07-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-08-30",LIFTING_PLAN_WK:"01-Sep-23",LIFTING_FORECAST:"2023-08-30",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.324×16.58",WEIGHT_UNIT:106,WEIGHT_ATT:106,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"南京化工 Nanjing Petrochemical",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"初版图 Vendor dwg IFR",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-SL10",DESCRIPTION:"Steam Drums",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-05-18",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-05-18",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-04-22",LOA_ISSUE_PLAN:"2022-08-28",LOA_ISSUE_FORECAST:"2022-06-30",LOA_ISSUE_ACTUAL:"2022-06-29",PO_ISSUE_PLAN:"2022-10-09",PO_ISSUE_FORECAST:"2022-08-11",PO_ISSUE_ACTUAL:"2022-08-18",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-30"},
    // {NO:494,PACKAGE:"P1",AREA:"OR",TAG_NO:"1019D201",TAG_DESCR:"STEAM DRUM FOR FURNACE",TAG_TYPE:"H",DELIVERY_PLAN:"2023-07-30",DELIVERY_FORECAST:"2023-07-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-08-31",LIFTING_PLAN_WK:"01-Sep-23",LIFTING_FORECAST:"2023-08-31",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.324×16.58",WEIGHT_UNIT:106,WEIGHT_ATT:106,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"南京化工 Nanjing Petrochemical",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"初版图 Vendor dwg IFR",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-SL10",DESCRIPTION:"Steam Drums",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-05-18",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-05-18",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-04-22",LOA_ISSUE_PLAN:"2022-08-28",LOA_ISSUE_FORECAST:"2022-06-30",LOA_ISSUE_ACTUAL:"2022-06-29",PO_ISSUE_PLAN:"2022-10-09",PO_ISSUE_FORECAST:"2022-08-11",PO_ISSUE_ACTUAL:"2022-08-18",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-30"},
    // {NO:495,PACKAGE:"P1",AREA:"OR",TAG_NO:"1020D201",TAG_DESCR:"STEAM DRUM FOR FURNACE",TAG_TYPE:"H",DELIVERY_PLAN:"2023-07-30",DELIVERY_FORECAST:"2023-07-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-08-31",LIFTING_PLAN_WK:"01-Sep-23",LIFTING_FORECAST:"2023-08-31",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.324×16.58",WEIGHT_UNIT:106,WEIGHT_ATT:106,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"南京化工 Nanjing Petrochemical",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"初版图 Vendor dwg IFR",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-SL10",DESCRIPTION:"Steam Drums",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-05-18",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-05-18",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-04-22",LOA_ISSUE_PLAN:"2022-08-28",LOA_ISSUE_FORECAST:"2022-06-30",LOA_ISSUE_ACTUAL:"2022-06-29",PO_ISSUE_PLAN:"2022-10-09",PO_ISSUE_FORECAST:"2022-08-11",PO_ISSUE_ACTUAL:"2022-08-18",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-04-30"},
    // {NO:496,PACKAGE:"P1",AREA:"OR",TAG_NO:"1012D101",TAG_DESCR:"COKE CANS FOR FURNACE",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-09",DELIVERY_FORECAST:"2023-03-09",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-09-01",LIFTING_PLAN_WK:"01-Sep-23",LIFTING_FORECAST:"2023-09-01",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.3×14",WEIGHT_UNIT:100,WEIGHT_ATT:100,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"洛阳卓达 Luoyang Zhuoda",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-SL09",DESCRIPTION:"Decoke Drums",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-02-26",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-02-26",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-01-29",LOA_ISSUE_PLAN:"2022-07-01",LOA_ISSUE_FORECAST:"2022-08-13",LOA_ISSUE_ACTUAL:"2022-08-17",PO_ISSUE_PLAN:"2022-08-12",PO_ISSUE_FORECAST:"2022-09-24",PO_ISSUE_ACTUAL:"2022-09-15",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-09-30"},
    // {NO:497,PACKAGE:"P1",AREA:"OR",TAG_NO:"1011D101",TAG_DESCR:"COKE CANS FOR FURNACE",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-09",DELIVERY_FORECAST:"2023-03-09",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-09-01",LIFTING_PLAN_WK:"01-Sep-23",LIFTING_FORECAST:"2023-09-01",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.3×14",WEIGHT_UNIT:100,WEIGHT_ATT:100,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"洛阳卓达 Luoyang Zhuoda",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-SL09",DESCRIPTION:"Decoke Drums",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-02-26",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-02-26",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-01-29",LOA_ISSUE_PLAN:"2022-07-01",LOA_ISSUE_FORECAST:"2022-08-13",LOA_ISSUE_ACTUAL:"2022-08-17",PO_ISSUE_PLAN:"2022-08-12",PO_ISSUE_FORECAST:"2022-09-24",PO_ISSUE_ACTUAL:"2022-09-15",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-09-30"},
    // {NO:498,PACKAGE:"P1",AREA:"OR",TAG_NO:"1013D101",TAG_DESCR:"COKE CANS FOR FURNACE",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-09",DELIVERY_FORECAST:"2023-03-09",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-09-02",LIFTING_PLAN_WK:"08-Sep-23",LIFTING_FORECAST:"2023-09-02",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.3×14",WEIGHT_UNIT:100,WEIGHT_ATT:100,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"洛阳卓达 Luoyang Zhuoda",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-SL09",DESCRIPTION:"Decoke Drums",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-02-26",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-02-26",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-01-29",LOA_ISSUE_PLAN:"2022-07-01",LOA_ISSUE_FORECAST:"2022-08-13",LOA_ISSUE_ACTUAL:"2022-08-17",PO_ISSUE_PLAN:"2022-08-12",PO_ISSUE_FORECAST:"2022-09-24",PO_ISSUE_ACTUAL:"2022-09-15",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-09-30"},
    // {NO:499,PACKAGE:"P1",AREA:"OR",TAG_NO:"1014D101",TAG_DESCR:"COKE CANS FOR FURNACE",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-09",DELIVERY_FORECAST:"2023-03-09",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-09-02",LIFTING_PLAN_WK:"08-Sep-23",LIFTING_FORECAST:"2023-09-02",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.3×14",WEIGHT_UNIT:100,WEIGHT_ATT:100,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"洛阳卓达 Luoyang Zhuoda",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-SL09",DESCRIPTION:"Decoke Drums",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-02-26",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-02-26",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-01-29",LOA_ISSUE_PLAN:"2022-07-01",LOA_ISSUE_FORECAST:"2022-08-13",LOA_ISSUE_ACTUAL:"2022-08-17",PO_ISSUE_PLAN:"2022-08-12",PO_ISSUE_FORECAST:"2022-09-24",PO_ISSUE_ACTUAL:"2022-09-15",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-09-30"},
    // {NO:500,PACKAGE:"P1",AREA:"OR",TAG_NO:"1015D101",TAG_DESCR:"COKE CANS FOR FURNACE",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-09",DELIVERY_FORECAST:"2023-03-09",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-09-03",LIFTING_PLAN_WK:"08-Sep-23",LIFTING_FORECAST:"2023-09-03",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.3×14",WEIGHT_UNIT:100,WEIGHT_ATT:100,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"洛阳卓达 Luoyang Zhuoda",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-SL09",DESCRIPTION:"Decoke Drums",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-02-26",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-02-26",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-01-29",LOA_ISSUE_PLAN:"2022-07-01",LOA_ISSUE_FORECAST:"2022-08-13",LOA_ISSUE_ACTUAL:"2022-08-17",PO_ISSUE_PLAN:"2022-08-12",PO_ISSUE_FORECAST:"2022-09-24",PO_ISSUE_ACTUAL:"2022-09-15",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-09-30"},
    // {NO:501,PACKAGE:"P1",AREA:"OR",TAG_NO:"1016D101",TAG_DESCR:"COKE CANS FOR FURNACE",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-09",DELIVERY_FORECAST:"2023-03-09",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-09-03",LIFTING_PLAN_WK:"08-Sep-23",LIFTING_FORECAST:"2023-09-03",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.3×14",WEIGHT_UNIT:100,WEIGHT_ATT:100,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"洛阳卓达 Luoyang Zhuoda",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-SL09",DESCRIPTION:"Decoke Drums",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-02-26",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-02-26",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-01-29",LOA_ISSUE_PLAN:"2022-07-01",LOA_ISSUE_FORECAST:"2022-08-13",LOA_ISSUE_ACTUAL:"2022-08-17",PO_ISSUE_PLAN:"2022-08-12",PO_ISSUE_FORECAST:"2022-09-24",PO_ISSUE_ACTUAL:"2022-09-15",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-09-30"},
    // {NO:502,PACKAGE:"P1",AREA:"OR",TAG_NO:"1017D101",TAG_DESCR:"COKE CANS FOR FURNACE",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-09",DELIVERY_FORECAST:"2023-03-09",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-09-04",LIFTING_PLAN_WK:"08-Sep-23",LIFTING_FORECAST:"2023-09-04",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.3×14",WEIGHT_UNIT:100,WEIGHT_ATT:100,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"洛阳卓达 Luoyang Zhuoda",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-SL09",DESCRIPTION:"Decoke Drums",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-02-26",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-02-26",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-01-29",LOA_ISSUE_PLAN:"2022-07-01",LOA_ISSUE_FORECAST:"2022-08-13",LOA_ISSUE_ACTUAL:"2022-08-17",PO_ISSUE_PLAN:"2022-08-12",PO_ISSUE_FORECAST:"2022-09-24",PO_ISSUE_ACTUAL:"2022-09-15",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-09-30"},
    // {NO:503,PACKAGE:"P1",AREA:"OR",TAG_NO:"1018D101",TAG_DESCR:"COKE CANS FOR FURNACE",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-09",DELIVERY_FORECAST:"2023-03-09",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-09-04",LIFTING_PLAN_WK:"08-Sep-23",LIFTING_FORECAST:"2023-09-04",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.3×14",WEIGHT_UNIT:100,WEIGHT_ATT:100,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"洛阳卓达 Luoyang Zhuoda",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-SL09",DESCRIPTION:"Decoke Drums",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-02-26",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-02-26",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-01-29",LOA_ISSUE_PLAN:"2022-07-01",LOA_ISSUE_FORECAST:"2022-08-13",LOA_ISSUE_ACTUAL:"2022-08-17",PO_ISSUE_PLAN:"2022-08-12",PO_ISSUE_FORECAST:"2022-09-24",PO_ISSUE_ACTUAL:"2022-09-15",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-09-30"},
    // {NO:504,PACKAGE:"P1",AREA:"OR",TAG_NO:"1019D101",TAG_DESCR:"COKE CANS FOR FURNACE",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-09",DELIVERY_FORECAST:"2023-03-09",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-09-05",LIFTING_PLAN_WK:"08-Sep-23",LIFTING_FORECAST:"2023-09-05",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.3×14",WEIGHT_UNIT:100,WEIGHT_ATT:100,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"洛阳卓达 Luoyang Zhuoda",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-SL09",DESCRIPTION:"Decoke Drums",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-02-26",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-02-26",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-01-29",LOA_ISSUE_PLAN:"2022-07-01",LOA_ISSUE_FORECAST:"2022-08-13",LOA_ISSUE_ACTUAL:"2022-08-17",PO_ISSUE_PLAN:"2022-08-12",PO_ISSUE_FORECAST:"2022-09-24",PO_ISSUE_ACTUAL:"2022-09-15",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-09-30"},
    // {NO:505,PACKAGE:"P1",AREA:"OR",TAG_NO:"1020D101",TAG_DESCR:"COKE CANS FOR FURNACE",TAG_TYPE:"V",DELIVERY_PLAN:"2023-03-09",DELIVERY_FORECAST:"2023-03-09",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-09-05",LIFTING_PLAN_WK:"08-Sep-23",LIFTING_FORECAST:"2023-09-05",STATUS:"To go",LOCATION:"炉区结构 Furnace",SHIPMENT_NO:"TBD",DIMENSION_M:"∅2.3×14",WEIGHT_UNIT:100,WEIGHT_ATT:100,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"洛阳卓达 Luoyang Zhuoda",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Sinotrans",MR_NO:"75231-P1-1279-EQ-R-E-SL09",DESCRIPTION:"Decoke Drums",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-02-26",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-02-26",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-01-29",LOA_ISSUE_PLAN:"2022-07-01",LOA_ISSUE_FORECAST:"2022-08-13",LOA_ISSUE_ACTUAL:"2022-08-17",PO_ISSUE_PLAN:"2022-08-12",PO_ISSUE_FORECAST:"2022-09-24",PO_ISSUE_ACTUAL:"2022-09-15",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-24",RAS_ROS:"2023-09-30"},
    // {NO:506,PACKAGE:"P2",AREA:"LDPE",TAG_NO:"5340C100",TAG_DESCR:"PRIMARY COMPRESSOR-Crankcase",TAG_TYPE:"H",DELIVERY_PLAN:"2023-08-31",DELIVERY_FORECAST:"2023-08-31",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-09-10",LIFTING_PLAN_WK:"15-Sep-23",LIFTING_FORECAST:"2023-11-19",STATUS:"To go",LOCATION:"地面框架",SHIPMENT_NO:"TBD",WEIGHT_UNIT:80,WEIGHT_ATT:80,LIFTING_CATEGORY:"Over 80t",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT_COMPANY:"Others"},
    // {NO:507,PACKAGE:"P2",AREA:"LDPE",TAG_NO:"5340C100",TAG_DESCR:"PRIMARY COMPRESSOR-Motor",TAG_TYPE:"H",DELIVERY_PLAN:"2023-08-31",DELIVERY_FORECAST:"2023-08-31",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-09-10",LIFTING_PLAN_WK:"15-Sep-23",LIFTING_FORECAST:"2023-11-19",STATUS:"To go",LOCATION:"地面框架",SHIPMENT_NO:"TBD",WEIGHT_UNIT:100,WEIGHT_ATT:100,LIFTING_CATEGORY:"Over 80t",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT_COMPANY:"Others"},
    // {NO:508,PACKAGE:"P1",AREA:"OR",TAG_NO:"CGCCOMPRESSORCRANE",TAG_DESCR:"CGC COMPRESSOR CRANE",TAG_TYPE:"H",DELIVERY_PLAN:"2023-09-12",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-09-22",LIFTING_PLAN_WK:"22-Sep-23",STATUS:"To go",LOCATION:"压缩机厂房",SHIPMENT_NO:"TBD",DIMENSION_M:"L=16500",WEIGHT_UNIT:40,WEIGHT_ATT:40,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"河南卫华",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:509,PACKAGE:"P1",AREA:"OR",TAG_NO:"PRC&ERCCOMPRESSORCRANE",TAG_DESCR:"PRC&ERC COMPRESSOR CRANE",TAG_TYPE:"H",DELIVERY_PLAN:"2023-09-13",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-09-23",LIFTING_PLAN_WK:"29-Sep-23",STATUS:"To go",LOCATION:"压缩机厂房",SHIPMENT_NO:"TBD",DIMENSION_M:"L=16500",WEIGHT_UNIT:40,WEIGHT_ATT:40,LIFTING_CATEGORY:"Less 80t",MANUFACTURE:"河南卫华",CRANE_MAIN_FILTER:"Less then 280",CRANE_MAIN:"NA",TRANSPORT_COMPANY:"Others"},
    // {NO:510,PACKAGE:"P3",AREA:"Flare",TAG_NO:"9001FLARE001",TAG_DESCR:"Flare",TAG_TYPE:"V",DELIVERY_PLAN:"2023-08-30",DELIVERY_FORECAST:"2023-08-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-09-25",LIFTING_PLAN_WK:"29-Sep-23",LIFTING_FORECAST:"2023-09-25",STATUS:"To go",LOCATION:"地面",SHIPMENT_NO:"TBD",WEIGHT_UNIT:450,WEIGHT_ATT:450,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"TBD",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"1,200",CRANE_MAIN:"1250",TRANSPORT:"Land transportation",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P3-1786-ST-R-E-0001",DESCRIPTION:"Elevated Flare",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2022-04-29",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2022-04-29",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2022-05-05",LOA_ISSUE_PLAN:"2022-09-19",LOA_ISSUE_FORECAST:"2022-09-11",LOA_ISSUE_ACTUAL:"2022-09-07",PO_ISSUE_PLAN:"2022-10-31",PO_ISSUE_FORECAST:"2022-10-23",PO_ISSUE_ACTUAL:"2022-10-12",DELIVERY_TO_JOB_SITE_PLAN:"2023-06-23",DELIVERY_TO_JOB_SITE_FORECAST:"2023-06-11",RAS_ROS:"2023-09-30"},
    // {NO:511,PACKAGE:"P2",AREA:"LDPE",TAG_NO:"5340C150",TAG_DESCR:"SECONDARY COMPRESSOR-Crank case",TAG_TYPE:"H",DELIVERY_PLAN:"2023-09-20",DELIVERY_FORECAST:"2023-09-20",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-09-30",LIFTING_PLAN_WK:"06-Oct-23",LIFTING_FORECAST:"2023-11-15",STATUS:"To go",LOCATION:"地面框架",SHIPMENT_NO:"TBD",WEIGHT_UNIT:160,WEIGHT_ATT:160,LIFTING_CATEGORY:"Over 80t",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT_COMPANY:"Others"},
    // {NO:512,PACKAGE:"P2",AREA:"LDPE",TAG_NO:"5340C150",TAG_DESCR:"SECONDARY COMPRESSOR-Motor",TAG_TYPE:"H",DELIVERY_PLAN:"2023-09-20",DELIVERY_FORECAST:"2023-09-20",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-09-30",LIFTING_PLAN_WK:"06-Oct-23",LIFTING_FORECAST:"2023-11-15",STATUS:"To go",LOCATION:"地面框架",SHIPMENT_NO:"TBD",WEIGHT_UNIT:100,WEIGHT_ATT:100,LIFTING_CATEGORY:"Over 80t",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT_COMPANY:"Others"},
    // {NO:513,PACKAGE:"P1",AREA:"OR",TAG_NO:"2530C001",TAG_DESCR:"CGC COMPRESSOR HP CASING",TAG_TYPE:"H",DELIVERY_PLAN:"2023-08-21",DELIVERY_FORECAST:"2023-09-14",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-11-01",LIFTING_PLAN_WK:"03-Nov-23",LIFTING_FORECAST:"2023-11-05",STATUS:"To go",LOCATION:"压缩3A",SHIPMENT_NO:"TBD",WEIGHT_UNIT:130,WEIGHT_ATT:130,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"Elliott",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-RE-R-E-UL01",DESCRIPTION:"CRACKED GAS COMPRESSOR",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-04-25",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-05-14",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-05-14",LOA_ISSUE_PLAN:"2021-10-30",LOA_ISSUE_FORECAST:"2021-10-31",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2021-12-11",PO_ISSUE_FORECAST:"2021-12-10",PO_ISSUE_ACTUAL:"2021-12-17",DELIVERY_TO_JOB_SITE_PLAN:"2023-10-11",RAS_ROS:"2023-10-30"},
    // {NO:514,PACKAGE:"P1",AREA:"OR",TAG_NO:"2530C001",TAG_DESCR:"CGC COMPRESSOR LP CASING",TAG_TYPE:"H",DELIVERY_PLAN:"2023-08-21",DELIVERY_FORECAST:"2023-09-14",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-11-01",LIFTING_PLAN_WK:"03-Nov-23",LIFTING_FORECAST:"2023-11-05",STATUS:"To go",LOCATION:"压缩3A",SHIPMENT_NO:"TBD",WEIGHT_UNIT:180,WEIGHT_ATT:180,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"Elliott",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-RE-R-E-UL01",DESCRIPTION:"CRACKED GAS COMPRESSOR",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-04-25",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-05-14",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-05-14",LOA_ISSUE_PLAN:"2021-10-30",LOA_ISSUE_FORECAST:"2021-10-31",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2021-12-11",PO_ISSUE_FORECAST:"2021-12-10",PO_ISSUE_ACTUAL:"2021-12-17",DELIVERY_TO_JOB_SITE_PLAN:"2023-10-11",RAS_ROS:"2023-10-30"},
    // {NO:515,PACKAGE:"P1",AREA:"OR",TAG_NO:"2530C001",TAG_DESCR:"CGC COMPRESSOR MP CASING",TAG_TYPE:"H",DELIVERY_PLAN:"2023-08-21",DELIVERY_FORECAST:"2023-09-14",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-11-01",LIFTING_PLAN_WK:"03-Nov-23",LIFTING_FORECAST:"2023-11-05",STATUS:"To go",LOCATION:"压缩3A",SHIPMENT_NO:"TBD",WEIGHT_UNIT:180,WEIGHT_ATT:180,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"Elliott",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-RE-R-E-UL01",DESCRIPTION:"CRACKED GAS COMPRESSOR",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-04-25",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-05-14",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-05-14",LOA_ISSUE_PLAN:"2021-10-30",LOA_ISSUE_FORECAST:"2021-10-31",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2021-12-11",PO_ISSUE_FORECAST:"2021-12-10",PO_ISSUE_ACTUAL:"2021-12-17",DELIVERY_TO_JOB_SITE_PLAN:"2023-10-11",RAS_ROS:"2023-10-30"},
    // {NO:516,PACKAGE:"P1",AREA:"OR",TAG_NO:"2565ST001",TAG_DESCR:"ETHYLENE REFRIG/HEAT PUMP TURBINE",DELIVERY_PLAN:"2023-10-30",DELIVERY_FORECAST:"2023-10-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-11-03",LIFTING_PLAN_WK:"03-Nov-23",LIFTING_FORECAST:"2023-11-03",STATUS:"To go",SHIPMENT_NO:"TBD",WEIGHT_UNIT:120,WEIGHT_ATT:120,LIFTING_CATEGORY:"Over 80t",DRAWING:"无图 No engineering dwg，无最新到货时间",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others"},
    // {NO:517,PACKAGE:"P1",AREA:"OR",TAG_NO:"2560C001",TAG_DESCR:"PROPYLENE REFRIG COMPRESSOR",TAG_TYPE:"H",DELIVERY_PLAN:"2023-08-21",DELIVERY_FORECAST:"2023-09-14",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-11-05",LIFTING_PLAN_WK:"10-Nov-23",LIFTING_FORECAST:"2023-11-07",STATUS:"To go",LOCATION:"冷区地面",SHIPMENT_NO:"TBD",WEIGHT_UNIT:180,WEIGHT_ATT:180,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"Elliott",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-RE-R-E-UL01",DESCRIPTION:"PROPYLENE REFRIG COMPRESSOR",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-04-25",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-05-14",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-05-14",LOA_ISSUE_PLAN:"2021-10-30",LOA_ISSUE_FORECAST:"2021-10-31",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2021-12-11",PO_ISSUE_FORECAST:"2021-12-10",PO_ISSUE_ACTUAL:"2021-12-17",DELIVERY_TO_JOB_SITE_PLAN:"2023-10-11",RAS_ROS:"2023-10-30"},
    // {NO:518,PACKAGE:"P1",AREA:"OR",TAG_NO:"2560C001",TAG_DESCR:"PROPYLENE REFRIG. STEAM TURBINE",TAG_TYPE:"H",DELIVERY_PLAN:"2023-08-21",DELIVERY_FORECAST:"2023-09-14",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-11-05",LIFTING_PLAN_WK:"10-Nov-23",LIFTING_FORECAST:"2023-11-07",STATUS:"To go",LOCATION:"冷区地面",SHIPMENT_NO:"TBD",WEIGHT_UNIT:180,WEIGHT_ATT:180,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"Elliott",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-RE-R-E-UL01",DESCRIPTION:"PROPYLENE REFRIG COMPRESSOR",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-04-25",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-05-14",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-05-14",LOA_ISSUE_PLAN:"2021-10-30",LOA_ISSUE_FORECAST:"2021-10-31",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2021-12-11",PO_ISSUE_FORECAST:"2021-12-10",PO_ISSUE_ACTUAL:"2021-12-17",DELIVERY_TO_JOB_SITE_PLAN:"2023-10-11",RAS_ROS:"2023-10-30"},
    // {NO:519,PACKAGE:"P1",AREA:"OR",TAG_NO:"2530ST001",TAG_DESCR:"CGC COMPRESSOR STEAM TURBINE",DELIVERY_PLAN:"2023-10-30",DELIVERY_FORECAST:"2023-10-30",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-11-06",LIFTING_PLAN_WK:"10-Nov-23",LIFTING_FORECAST:"2023-11-06",STATUS:"To go",SHIPMENT_NO:"TBD",WEIGHT_UNIT:180,WEIGHT_ATT:180,LIFTING_CATEGORY:"Over 80t",DESIGN_OF_LIFTING_LUG:"厂家设计 Manuf.'s scope",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"750",CRANE_MAIN:"750",TRANSPORT_COMPANY:"Others"},
    // {NO:520,PACKAGE:"P1",AREA:"OR",TAG_NO:"2565C001",TAG_DESCR:"ETHYLENE REFRIG/HEAT PUMP COMPRESSOR LP CASING",TAG_TYPE:"H",DELIVERY_PLAN:"2023-08-21",DELIVERY_FORECAST:"2023-08-21",DRESSUP_REQ:"N",LIFTING_PLAN:"2023-11-07",LIFTING_PLAN_WK:"10-Nov-23",LIFTING_FORECAST:"2023-11-07",STATUS:"To go",LOCATION:"冷区地面",SHIPMENT_NO:"TBD",WEIGHT_UNIT:120,WEIGHT_ATT:120,LIFTING_CATEGORY:"Over 80t",MANUFACTURE:"Elliott",DESIGN_OF_LIFTING_LUG:"无需吊耳 No need lifting lug",DRAWING:"无图 No engineering dwg",CRANE_MAIN_FILTER:"400",CRANE_MAIN:"400",TRANSPORT_COMPANY:"Others",MR_NO:"75231-P1-1279-RE-R-E-UL01",DESCRIPTION:"ETHYLENE REFRIGHEAT PUMP COMPRESSOR",MR_RECEIVED_BY_SEI_PROCUREMENT_PLAN:"2021-04-25",MR_RECEIVED_BY_SEI_PROCUREMENT_FORECAST:"2021-05-14",MR_RECEIVED_BY_SEI_PROCUREMENT_ACTUAL:"2021-05-14",LOA_ISSUE_PLAN:"2021-10-30",LOA_ISSUE_FORECAST:"2021-10-31",LOA_ISSUE_ACTUAL:"2021-10-29",PO_ISSUE_PLAN:"2021-12-11",PO_ISSUE_FORECAST:"2021-12-10",PO_ISSUE_ACTUAL:"2021-12-17",DELIVERY_TO_JOB_SITE_PLAN:"2023-10-11",RAS_ROS:"2023-10-30"}
  ],
  Queries: {
    SQL1: [
      { DESC: 'DataSource SPCS Data 03-Mar-20' }
    ],
    SQL2: [
      { CDATE: '2021-08-20' }
    ],
    SQL3: [
      {CUTOFF:"2022-12-09",WK_NO:"W2022_49",DELIVERY_PLAN_WK:2,DELIVERY_ACTUAL_WK:0,LIFTING_PLAN_WK:1,LIFTING_ACTUAL_WK:1,DELIVERY_PLAN_CUM:8,DELIVERY_ACTUAL_CUM:6,LIFTING_PLAN_CUM:1,LIFTING_ACTUAL_CUM:1},
      {CUTOFF:"2022-12-16",WK_NO:"W2022_50",DELIVERY_PLAN_WK:2,DELIVERY_ACTUAL_WK:3,LIFTING_PLAN_WK:1,LIFTING_ACTUAL_WK:1,DELIVERY_PLAN_CUM:10,DELIVERY_ACTUAL_CUM:9,LIFTING_PLAN_CUM:2,LIFTING_ACTUAL_CUM:2},
      {CUTOFF:"2022-12-23",WK_NO:"W2022_51",DELIVERY_PLAN_WK:0,DELIVERY_ACTUAL_WK:0,LIFTING_PLAN_WK:1,LIFTING_ACTUAL_WK:1,DELIVERY_PLAN_CUM:10,DELIVERY_ACTUAL_CUM:9,LIFTING_PLAN_CUM:3,LIFTING_ACTUAL_CUM:3},
      {CUTOFF:"2022-12-30",WK_NO:"W2022_52",DELIVERY_PLAN_WK:5,DELIVERY_ACTUAL_WK:4,LIFTING_PLAN_WK:1,LIFTING_ACTUAL_WK:1,DELIVERY_PLAN_CUM:15,DELIVERY_ACTUAL_CUM:13,LIFTING_PLAN_CUM:4,LIFTING_ACTUAL_CUM:4},
      {CUTOFF:"2023-01-06",WK_NO:"W2023_01",DELIVERY_PLAN_WK:9,DELIVERY_ACTUAL_WK:0,LIFTING_PLAN_WK:1,LIFTING_ACTUAL_WK:1,DELIVERY_PLAN_CUM:24,DELIVERY_ACTUAL_CUM:13,LIFTING_PLAN_CUM:5,LIFTING_ACTUAL_CUM:5},
      {CUTOFF:"2023-01-13",WK_NO:"W2023_02",DELIVERY_PLAN_WK:5,DELIVERY_ACTUAL_WK:0,LIFTING_PLAN_WK:2,LIFTING_ACTUAL_WK:4,DELIVERY_PLAN_CUM:29,DELIVERY_ACTUAL_CUM:13,LIFTING_PLAN_CUM:7,LIFTING_ACTUAL_CUM:9},
      {CUTOFF:"2023-01-20",WK_NO:"W2023_03",DELIVERY_PLAN_WK:3,DELIVERY_ACTUAL_WK:6,LIFTING_PLAN_WK:2,LIFTING_ACTUAL_WK:0,DELIVERY_PLAN_CUM:32,DELIVERY_ACTUAL_CUM:19,LIFTING_PLAN_CUM:9,LIFTING_ACTUAL_CUM:9},
      {CUTOFF:"2023-01-27",WK_NO:"W2023_04",DELIVERY_PLAN_WK:0,DELIVERY_ACTUAL_WK:0,LIFTING_PLAN_WK:0,LIFTING_ACTUAL_WK:0,DELIVERY_PLAN_CUM:32,DELIVERY_ACTUAL_CUM:19,LIFTING_PLAN_CUM:9,LIFTING_ACTUAL_CUM:9},
      {CUTOFF:"2023-02-03",WK_NO:"W2023_05",DELIVERY_PLAN_WK:40,DELIVERY_ACTUAL_WK:1,LIFTING_PLAN_WK:0,LIFTING_ACTUAL_WK:0,DELIVERY_PLAN_CUM:72,DELIVERY_ACTUAL_CUM:20,LIFTING_PLAN_CUM:9,LIFTING_ACTUAL_CUM:9},
      {CUTOFF:"2023-02-10",WK_NO:"W2023_06",DELIVERY_PLAN_WK:3,DELIVERY_ACTUAL_WK:0,LIFTING_PLAN_WK:51,LIFTING_ACTUAL_WK:1,DELIVERY_PLAN_CUM:75,DELIVERY_ACTUAL_CUM:20,LIFTING_PLAN_CUM:60,LIFTING_ACTUAL_CUM:10},
      {CUTOFF:"2023-02-17",WK_NO:"W2023_07",DELIVERY_PLAN_WK:4,DELIVERY_ACTUAL_WK:0,LIFTING_PLAN_WK:2,LIFTING_ACTUAL_WK:0,DELIVERY_PLAN_CUM:79,DELIVERY_ACTUAL_CUM:20,LIFTING_PLAN_CUM:62,LIFTING_ACTUAL_CUM:10},
      {CUTOFF:"2023-02-24",WK_NO:"W2023_08",DELIVERY_PLAN_WK:2,DELIVERY_ACTUAL_WK:1,LIFTING_PLAN_WK:2,LIFTING_ACTUAL_WK:0,DELIVERY_PLAN_CUM:81,DELIVERY_ACTUAL_CUM:21,LIFTING_PLAN_CUM:64,LIFTING_ACTUAL_CUM:10},
      {CUTOFF:"2023-03-03",WK_NO:"W2023_09",DELIVERY_PLAN_WK:12,LIFTING_PLAN_WK:4,DELIVERY_PLAN_CUM:93,LIFTING_PLAN_CUM:68},
      {CUTOFF:"2023-03-10",WK_NO:"W2023_10",DELIVERY_PLAN_WK:16,LIFTING_PLAN_WK:19,DELIVERY_PLAN_CUM:109,LIFTING_PLAN_CUM:87},
      {CUTOFF:"2023-03-17",WK_NO:"W2023_11",DELIVERY_PLAN_WK:5,LIFTING_PLAN_WK:11,DELIVERY_PLAN_CUM:114,LIFTING_PLAN_CUM:98},
      {CUTOFF:"2023-03-24",WK_NO:"W2023_12",DELIVERY_PLAN_WK:7,LIFTING_PLAN_WK:5,DELIVERY_PLAN_CUM:121,LIFTING_PLAN_CUM:103},
      {CUTOFF:"2023-03-31",WK_NO:"W2023_13",DELIVERY_PLAN_WK:13,LIFTING_PLAN_WK:6,DELIVERY_PLAN_CUM:134,LIFTING_PLAN_CUM:109},
      {CUTOFF:"2023-04-07",WK_NO:"W2023_14",DELIVERY_PLAN_WK:2,LIFTING_PLAN_WK:12,DELIVERY_PLAN_CUM:136,LIFTING_PLAN_CUM:121},
      {CUTOFF:"2023-04-14",WK_NO:"W2023_15",DELIVERY_PLAN_WK:2,LIFTING_PLAN_WK:22,DELIVERY_PLAN_CUM:138,LIFTING_PLAN_CUM:143},
      {CUTOFF:"2023-04-21",WK_NO:"W2023_16",DELIVERY_PLAN_WK:88,LIFTING_PLAN_WK:27,DELIVERY_PLAN_CUM:226,LIFTING_PLAN_CUM:170},
      {CUTOFF:"2023-04-28",WK_NO:"W2023_17",DELIVERY_PLAN_WK:4,LIFTING_PLAN_WK:16,DELIVERY_PLAN_CUM:230,LIFTING_PLAN_CUM:186},
      {CUTOFF:"2023-05-05",WK_NO:"W2023_18",DELIVERY_PLAN_WK:23,LIFTING_PLAN_WK:11,DELIVERY_PLAN_CUM:253,LIFTING_PLAN_CUM:197},
      {CUTOFF:"2023-05-12",WK_NO:"W2023_19",DELIVERY_PLAN_WK:12,LIFTING_PLAN_WK:19,DELIVERY_PLAN_CUM:265,LIFTING_PLAN_CUM:216},
      {CUTOFF:"2023-05-19",WK_NO:"W2023_20",DELIVERY_PLAN_WK:75,LIFTING_PLAN_WK:21,DELIVERY_PLAN_CUM:340,LIFTING_PLAN_CUM:237},
      {CUTOFF:"2023-05-26",WK_NO:"W2023_21",DELIVERY_PLAN_WK:1,LIFTING_PLAN_WK:82,DELIVERY_PLAN_CUM:341,LIFTING_PLAN_CUM:319},
      {CUTOFF:"2023-06-02",WK_NO:"W2023_22",DELIVERY_PLAN_WK:38,LIFTING_PLAN_WK:46,DELIVERY_PLAN_CUM:379,LIFTING_PLAN_CUM:365},
      {CUTOFF:"2023-06-09",WK_NO:"W2023_23",DELIVERY_PLAN_WK:6,LIFTING_PLAN_WK:36,DELIVERY_PLAN_CUM:385,LIFTING_PLAN_CUM:401},
      {CUTOFF:"2023-06-16",WK_NO:"W2023_24",DELIVERY_PLAN_WK:12,LIFTING_PLAN_WK:24,DELIVERY_PLAN_CUM:397,LIFTING_PLAN_CUM:425},
      {CUTOFF:"2023-06-23",WK_NO:"W2023_25",DELIVERY_PLAN_WK:7,LIFTING_PLAN_WK:9,DELIVERY_PLAN_CUM:404,LIFTING_PLAN_CUM:434},
      {CUTOFF:"2023-06-30",WK_NO:"W2023_26",DELIVERY_PLAN_WK:36,LIFTING_PLAN_WK:8,DELIVERY_PLAN_CUM:440,LIFTING_PLAN_CUM:442},
      {CUTOFF:"2023-07-07",WK_NO:"W2023_27",DELIVERY_PLAN_WK:0,LIFTING_PLAN_WK:11,DELIVERY_PLAN_CUM:440,LIFTING_PLAN_CUM:453},
      {CUTOFF:"2023-07-14",WK_NO:"W2023_28",DELIVERY_PLAN_WK:0,LIFTING_PLAN_WK:15,DELIVERY_PLAN_CUM:440,LIFTING_PLAN_CUM:468},
      {CUTOFF:"2023-07-21",WK_NO:"W2023_29",DELIVERY_PLAN_WK:14,LIFTING_PLAN_WK:6,DELIVERY_PLAN_CUM:454,LIFTING_PLAN_CUM:474},
      {CUTOFF:"2023-07-28",WK_NO:"W2023_30",DELIVERY_PLAN_WK:0,LIFTING_PLAN_WK:8,DELIVERY_PLAN_CUM:454,LIFTING_PLAN_CUM:482},
      {CUTOFF:"2023-08-04",WK_NO:"W2023_31",DELIVERY_PLAN_WK:17,LIFTING_PLAN_WK:2,DELIVERY_PLAN_CUM:471,LIFTING_PLAN_CUM:484},
      {CUTOFF:"2023-08-11",WK_NO:"W2023_32",DELIVERY_PLAN_WK:0,LIFTING_PLAN_WK:0,DELIVERY_PLAN_CUM:471,LIFTING_PLAN_CUM:484},
      {CUTOFF:"2023-08-18",WK_NO:"W2023_33",DELIVERY_PLAN_WK:0,LIFTING_PLAN_WK:0,DELIVERY_PLAN_CUM:471,LIFTING_PLAN_CUM:484},
      {CUTOFF:"2023-08-25",WK_NO:"W2023_34",DELIVERY_PLAN_WK:6,LIFTING_PLAN_WK:1,DELIVERY_PLAN_CUM:477,LIFTING_PLAN_CUM:485},
      {CUTOFF:"2023-09-01",WK_NO:"W2023_35",DELIVERY_PLAN_WK:3,LIFTING_PLAN_WK:12,DELIVERY_PLAN_CUM:480,LIFTING_PLAN_CUM:497},
      {CUTOFF:"2023-09-08",WK_NO:"W2023_36",DELIVERY_PLAN_WK:0,LIFTING_PLAN_WK:8,DELIVERY_PLAN_CUM:480,LIFTING_PLAN_CUM:505},
      {CUTOFF:"2023-09-15",WK_NO:"W2023_37",DELIVERY_PLAN_WK:2,LIFTING_PLAN_WK:2,DELIVERY_PLAN_CUM:482,LIFTING_PLAN_CUM:507},
      {CUTOFF:"2023-09-22",WK_NO:"W2023_38",DELIVERY_PLAN_WK:2,LIFTING_PLAN_WK:1,DELIVERY_PLAN_CUM:484,LIFTING_PLAN_CUM:508},
      {CUTOFF:"2023-09-29",WK_NO:"W2023_39",DELIVERY_PLAN_WK:0,LIFTING_PLAN_WK:2,DELIVERY_PLAN_CUM:484,LIFTING_PLAN_CUM:510},
      {CUTOFF:"2023-10-06",WK_NO:"W2023_40",DELIVERY_PLAN_WK:0,LIFTING_PLAN_WK:2,DELIVERY_PLAN_CUM:484,LIFTING_PLAN_CUM:512},
      {CUTOFF:"2023-10-13",WK_NO:"W2023_41",DELIVERY_PLAN_WK:0,LIFTING_PLAN_WK:0,DELIVERY_PLAN_CUM:484,LIFTING_PLAN_CUM:512},
      {CUTOFF:"2023-10-20",WK_NO:"W2023_42",DELIVERY_PLAN_WK:0,LIFTING_PLAN_WK:0,DELIVERY_PLAN_CUM:484,LIFTING_PLAN_CUM:512},
      {CUTOFF:"2023-10-27",WK_NO:"W2023_43",DELIVERY_PLAN_WK:0,LIFTING_PLAN_WK:0,DELIVERY_PLAN_CUM:484,LIFTING_PLAN_CUM:512},
      {CUTOFF:"2023-11-03",WK_NO:"W2023_44",DELIVERY_PLAN_WK:2,LIFTING_PLAN_WK:4,DELIVERY_PLAN_CUM:486,LIFTING_PLAN_CUM:516},
      {CUTOFF:"2023-11-10",WK_NO:"W2023_45",DELIVERY_PLAN_WK:0,LIFTING_PLAN_WK:4,DELIVERY_PLAN_CUM:486,LIFTING_PLAN_CUM:520}
      ],
    SQL4: [
      {DD_MMM_YY:"07-Jan-22",MM_DD:"01-07",CUTOFF:"2022-01-07",WK_NO:"W2022_01"},
      {DD_MMM_YY:"14-Jan-22",MM_DD:"01-14",CUTOFF:"2022-01-14",WK_NO:"W2022_02"},
      {DD_MMM_YY:"21-Jan-22",MM_DD:"01-21",CUTOFF:"2022-01-21",WK_NO:"W2022_03"},
      {DD_MMM_YY:"28-Jan-22",MM_DD:"01-28",CUTOFF:"2022-01-28",WK_NO:"W2022_04"},
      {DD_MMM_YY:"04-Feb-22",MM_DD:"02-04",CUTOFF:"2022-02-04",WK_NO:"W2022_05"},
      {DD_MMM_YY:"11-Feb-22",MM_DD:"02-11",CUTOFF:"2022-02-11",WK_NO:"W2022_06"},
      {DD_MMM_YY:"18-Feb-22",MM_DD:"02-18",CUTOFF:"2022-02-18",WK_NO:"W2022_07"},
      {DD_MMM_YY:"25-Feb-22",MM_DD:"02-25",CUTOFF:"2022-02-25",WK_NO:"W2022_08"},
      {DD_MMM_YY:"04-Mar-22",MM_DD:"03-04",CUTOFF:"2022-03-04",WK_NO:"W2022_09"},
      {DD_MMM_YY:"11-Mar-22",MM_DD:"03-11",CUTOFF:"2022-03-11",WK_NO:"W2022_10"},
      {DD_MMM_YY:"18-Mar-22",MM_DD:"03-18",CUTOFF:"2022-03-18",WK_NO:"W2022_11"},
      {DD_MMM_YY:"25-Mar-22",MM_DD:"03-25",CUTOFF:"2022-03-25",WK_NO:"W2022_12"},
      {DD_MMM_YY:"01-Apr-22",MM_DD:"04-01",CUTOFF:"2022-04-01",WK_NO:"W2022_13"},
      {DD_MMM_YY:"08-Apr-22",MM_DD:"04-08",CUTOFF:"2022-04-08",WK_NO:"W2022_14"},
      {DD_MMM_YY:"15-Apr-22",MM_DD:"04-15",CUTOFF:"2022-04-15",WK_NO:"W2022_15"},
      {DD_MMM_YY:"22-Apr-22",MM_DD:"04-22",CUTOFF:"2022-04-22",WK_NO:"W2022_16"},
      {DD_MMM_YY:"29-Apr-22",MM_DD:"04-29",CUTOFF:"2022-04-29",WK_NO:"W2022_17"},
      {DD_MMM_YY:"06-May-22",MM_DD:"05-06",CUTOFF:"2022-05-06",WK_NO:"W2022_18"},
      {DD_MMM_YY:"13-May-22",MM_DD:"05-13",CUTOFF:"2022-05-13",WK_NO:"W2022_19"},
      {DD_MMM_YY:"20-May-22",MM_DD:"05-20",CUTOFF:"2022-05-20",WK_NO:"W2022_20"},
      {DD_MMM_YY:"27-May-22",MM_DD:"05-27",CUTOFF:"2022-05-27",WK_NO:"W2022_21"},
      {DD_MMM_YY:"03-Jun-22",MM_DD:"06-03",CUTOFF:"2022-06-03",WK_NO:"W2022_22"},
      {DD_MMM_YY:"10-Jun-22",MM_DD:"06-10",CUTOFF:"2022-06-10",WK_NO:"W2022_23"},
      {DD_MMM_YY:"17-Jun-22",MM_DD:"06-17",CUTOFF:"2022-06-17",WK_NO:"W2022_24"},
      {DD_MMM_YY:"24-Jun-22",MM_DD:"06-24",CUTOFF:"2022-06-24",WK_NO:"W2022_25"},
      {DD_MMM_YY:"01-Jul-22",MM_DD:"07-01",CUTOFF:"2022-07-01",WK_NO:"W2022_26"},
      {DD_MMM_YY:"08-Jul-22",MM_DD:"07-08",CUTOFF:"2022-07-08",WK_NO:"W2022_27"},
      {DD_MMM_YY:"15-Jul-22",MM_DD:"07-15",CUTOFF:"2022-07-15",WK_NO:"W2022_28"},
      {DD_MMM_YY:"22-Jul-22",MM_DD:"07-22",CUTOFF:"2022-07-22",WK_NO:"W2022_29"},
      {DD_MMM_YY:"29-Jul-22",MM_DD:"07-29",CUTOFF:"2022-07-29",WK_NO:"W2022_30"},
      {DD_MMM_YY:"05-Aug-22",MM_DD:"08-05",CUTOFF:"2022-08-05",WK_NO:"W2022_31"},
      {DD_MMM_YY:"12-Aug-22",MM_DD:"08-12",CUTOFF:"2022-08-12",WK_NO:"W2022_32"},
      {DD_MMM_YY:"19-Aug-22",MM_DD:"08-19",CUTOFF:"2022-08-19",WK_NO:"W2022_33"},
      {DD_MMM_YY:"26-Aug-22",MM_DD:"08-26",CUTOFF:"2022-08-26",WK_NO:"W2022_34"},
      {DD_MMM_YY:"02-Sep-22",MM_DD:"09-02",CUTOFF:"2022-09-02",WK_NO:"W2022_35"},
      {DD_MMM_YY:"09-Sep-22",MM_DD:"09-09",CUTOFF:"2022-09-09",WK_NO:"W2022_36"},
      {DD_MMM_YY:"16-Sep-22",MM_DD:"09-16",CUTOFF:"2022-09-16",WK_NO:"W2022_37"},
      {DD_MMM_YY:"23-Sep-22",MM_DD:"09-23",CUTOFF:"2022-09-23",WK_NO:"W2022_38"},
      {DD_MMM_YY:"30-Sep-22",MM_DD:"09-30",CUTOFF:"2022-09-30",WK_NO:"W2022_39"},
      {DD_MMM_YY:"07-Oct-22",MM_DD:"10-07",CUTOFF:"2022-10-07",WK_NO:"W2022_40"},
      {DD_MMM_YY:"14-Oct-22",MM_DD:"10-14",CUTOFF:"2022-10-14",WK_NO:"W2022_41"},
      {DD_MMM_YY:"21-Oct-22",MM_DD:"10-21",CUTOFF:"2022-10-21",WK_NO:"W2022_42"},
      {DD_MMM_YY:"28-Oct-22",MM_DD:"10-28",CUTOFF:"2022-10-28",WK_NO:"W2022_43"},
      {DD_MMM_YY:"04-Nov-22",MM_DD:"11-04",CUTOFF:"2022-11-04",WK_NO:"W2022_44"},
      {DD_MMM_YY:"11-Nov-22",MM_DD:"11-11",CUTOFF:"2022-11-11",WK_NO:"W2022_45"},
      {DD_MMM_YY:"18-Nov-22",MM_DD:"11-18",CUTOFF:"2022-11-18",WK_NO:"W2022_46"},
      {DD_MMM_YY:"25-Nov-22",MM_DD:"11-25",CUTOFF:"2022-11-25",WK_NO:"W2022_47"},
      {DD_MMM_YY:"02-Dec-22",MM_DD:"12-02",CUTOFF:"2022-12-02",WK_NO:"W2022_48"},
      {DD_MMM_YY:"09-Dec-22",MM_DD:"12-09",CUTOFF:"2022-12-09",WK_NO:"W2022_49"},
      {DD_MMM_YY:"16-Dec-22",MM_DD:"12-16",CUTOFF:"2022-12-16",WK_NO:"W2022_50"},
      {DD_MMM_YY:"23-Dec-22",MM_DD:"12-23",CUTOFF:"2022-12-23",WK_NO:"W2022_51"},
      {DD_MMM_YY:"30-Dec-22",MM_DD:"12-30",CUTOFF:"2022-12-30",WK_NO:"W2022_52"},
      {DD_MMM_YY:"06-Jan-23",MM_DD:"01-06",CUTOFF:"2023-01-06",WK_NO:"W2023_01"},
      {DD_MMM_YY:"13-Jan-23",MM_DD:"01-13",CUTOFF:"2023-01-13",WK_NO:"W2023_02"},
      {DD_MMM_YY:"20-Jan-23",MM_DD:"01-20",CUTOFF:"2023-01-20",WK_NO:"W2023_03"},
      {DD_MMM_YY:"27-Jan-23",MM_DD:"01-27",CUTOFF:"2023-01-27",WK_NO:"W2023_04"},
      {DD_MMM_YY:"03-Feb-23",MM_DD:"02-03",CUTOFF:"2023-02-03",WK_NO:"W2023_05"},
      {DD_MMM_YY:"10-Feb-23",MM_DD:"02-10",CUTOFF:"2023-02-10",WK_NO:"W2023_06"},
      {DD_MMM_YY:"17-Feb-23",MM_DD:"02-17",CUTOFF:"2023-02-17",WK_NO:"W2023_07"},
      {DD_MMM_YY:"24-Feb-23",MM_DD:"02-24",CUTOFF:"2023-02-24",WK_NO:"W2023_08"},
      {DD_MMM_YY:"03-Mar-23",MM_DD:"03-03",CUTOFF:"2023-03-03",WK_NO:"W2023_09"},
      {DD_MMM_YY:"10-Mar-23",MM_DD:"03-10",CUTOFF:"2023-03-10",WK_NO:"W2023_10"},
      {DD_MMM_YY:"17-Mar-23",MM_DD:"03-17",CUTOFF:"2023-03-17",WK_NO:"W2023_11"},
      {DD_MMM_YY:"24-Mar-23",MM_DD:"03-24",CUTOFF:"2023-03-24",WK_NO:"W2023_12"},
      {DD_MMM_YY:"31-Mar-23",MM_DD:"03-31",CUTOFF:"2023-03-31",WK_NO:"W2023_13"},
      {DD_MMM_YY:"07-Apr-23",MM_DD:"04-07",CUTOFF:"2023-04-07",WK_NO:"W2023_14"},
      {DD_MMM_YY:"14-Apr-23",MM_DD:"04-14",CUTOFF:"2023-04-14",WK_NO:"W2023_15"},
      {DD_MMM_YY:"21-Apr-23",MM_DD:"04-21",CUTOFF:"2023-04-21",WK_NO:"W2023_16"},
      {DD_MMM_YY:"28-Apr-23",MM_DD:"04-28",CUTOFF:"2023-04-28",WK_NO:"W2023_17"},
      {DD_MMM_YY:"05-May-23",MM_DD:"05-05",CUTOFF:"2023-05-05",WK_NO:"W2023_18"},
      {DD_MMM_YY:"12-May-23",MM_DD:"05-12",CUTOFF:"2023-05-12",WK_NO:"W2023_19"},
      {DD_MMM_YY:"19-May-23",MM_DD:"05-19",CUTOFF:"2023-05-19",WK_NO:"W2023_20"},
      {DD_MMM_YY:"26-May-23",MM_DD:"05-26",CUTOFF:"2023-05-26",WK_NO:"W2023_21"},
      {DD_MMM_YY:"02-Jun-23",MM_DD:"06-02",CUTOFF:"2023-06-02",WK_NO:"W2023_22"},
      {DD_MMM_YY:"09-Jun-23",MM_DD:"06-09",CUTOFF:"2023-06-09",WK_NO:"W2023_23"},
      {DD_MMM_YY:"16-Jun-23",MM_DD:"06-16",CUTOFF:"2023-06-16",WK_NO:"W2023_24"},
      {DD_MMM_YY:"23-Jun-23",MM_DD:"06-23",CUTOFF:"2023-06-23",WK_NO:"W2023_25"},
      {DD_MMM_YY:"30-Jun-23",MM_DD:"06-30",CUTOFF:"2023-06-30",WK_NO:"W2023_26"},
      {DD_MMM_YY:"07-Jul-23",MM_DD:"07-07",CUTOFF:"2023-07-07",WK_NO:"W2023_27"},
      {DD_MMM_YY:"14-Jul-23",MM_DD:"07-14",CUTOFF:"2023-07-14",WK_NO:"W2023_28"},
      {DD_MMM_YY:"21-Jul-23",MM_DD:"07-21",CUTOFF:"2023-07-21",WK_NO:"W2023_29"},
      {DD_MMM_YY:"28-Jul-23",MM_DD:"07-28",CUTOFF:"2023-07-28",WK_NO:"W2023_30"},
      {DD_MMM_YY:"04-Aug-23",MM_DD:"08-04",CUTOFF:"2023-08-04",WK_NO:"W2023_31"},
      {DD_MMM_YY:"11-Aug-23",MM_DD:"08-11",CUTOFF:"2023-08-11",WK_NO:"W2023_32"},
      {DD_MMM_YY:"18-Aug-23",MM_DD:"08-18",CUTOFF:"2023-08-18",WK_NO:"W2023_33"},
      {DD_MMM_YY:"25-Aug-23",MM_DD:"08-25",CUTOFF:"2023-08-25",WK_NO:"W2023_34"},
      {DD_MMM_YY:"01-Sep-23",MM_DD:"09-01",CUTOFF:"2023-09-01",WK_NO:"W2023_35"},
      {DD_MMM_YY:"08-Sep-23",MM_DD:"09-08",CUTOFF:"2023-09-08",WK_NO:"W2023_36"},
      {DD_MMM_YY:"15-Sep-23",MM_DD:"09-15",CUTOFF:"2023-09-15",WK_NO:"W2023_37"},
      {DD_MMM_YY:"22-Sep-23",MM_DD:"09-22",CUTOFF:"2023-09-22",WK_NO:"W2023_38"},
      {DD_MMM_YY:"29-Sep-23",MM_DD:"09-29",CUTOFF:"2023-09-29",WK_NO:"W2023_39"},
      {DD_MMM_YY:"06-Oct-23",MM_DD:"10-06",CUTOFF:"2023-10-06",WK_NO:"W2023_40"},
      {DD_MMM_YY:"13-Oct-23",MM_DD:"10-13",CUTOFF:"2023-10-13",WK_NO:"W2023_41"},
      {DD_MMM_YY:"20-Oct-23",MM_DD:"10-20",CUTOFF:"2023-10-20",WK_NO:"W2023_42"},
      {DD_MMM_YY:"27-Oct-23",MM_DD:"10-27",CUTOFF:"2023-10-27",WK_NO:"W2023_43"},
      {DD_MMM_YY:"03-Nov-23",MM_DD:"11-03",CUTOFF:"2023-11-03",WK_NO:"W2023_44"},
      {DD_MMM_YY:"10-Nov-23",MM_DD:"11-10",CUTOFF:"2023-11-10",WK_NO:"W2023_45"},
      {DD_MMM_YY:"17-Nov-23",MM_DD:"11-17",CUTOFF:"2023-11-17",WK_NO:"W2023_46"},
      {DD_MMM_YY:"24-Nov-23",MM_DD:"11-24",CUTOFF:"2023-11-24",WK_NO:"W2023_47"},
      {DD_MMM_YY:"01-Dec-23",MM_DD:"12-01",CUTOFF:"2023-12-01",WK_NO:"W2023_48"},
      {DD_MMM_YY:"08-Dec-23",MM_DD:"12-08",CUTOFF:"2023-12-08",WK_NO:"W2023_49"},
      {DD_MMM_YY:"15-Dec-23",MM_DD:"12-15",CUTOFF:"2023-12-15",WK_NO:"W2023_50"},
      {DD_MMM_YY:"22-Dec-23",MM_DD:"12-22",CUTOFF:"2023-12-22",WK_NO:"W2023_51"},
      {DD_MMM_YY:"29-Dec-23",MM_DD:"12-29",CUTOFF:"2023-12-29",WK_NO:"W2023_52"},
      {DD_MMM_YY:"05-Jan-24",MM_DD:"01-05",CUTOFF:"2024-01-05",WK_NO:"W2024_01"},
      {DD_MMM_YY:"12-Jan-24",MM_DD:"01-12",CUTOFF:"2024-01-12",WK_NO:"W2024_02"},
      {DD_MMM_YY:"19-Jan-24",MM_DD:"01-19",CUTOFF:"2024-01-19",WK_NO:"W2024_03"},
      {DD_MMM_YY:"26-Jan-24",MM_DD:"01-26",CUTOFF:"2024-01-26",WK_NO:"W2024_04"},
      {DD_MMM_YY:"02-Feb-24",MM_DD:"02-02",CUTOFF:"2024-02-02",WK_NO:"W2024_05"},
      {DD_MMM_YY:"09-Feb-24",MM_DD:"02-09",CUTOFF:"2024-02-09",WK_NO:"W2024_06"},
      {DD_MMM_YY:"16-Feb-24",MM_DD:"02-16",CUTOFF:"2024-02-16",WK_NO:"W2024_07"},
      {DD_MMM_YY:"23-Feb-24",MM_DD:"02-23",CUTOFF:"2024-02-23",WK_NO:"W2024_08"},
      {DD_MMM_YY:"01-Mar-24",MM_DD:"03-01",CUTOFF:"2024-03-01",WK_NO:"W2024_09"},
      {DD_MMM_YY:"08-Mar-24",MM_DD:"03-08",CUTOFF:"2024-03-08",WK_NO:"W2024_10"},
      {DD_MMM_YY:"15-Mar-24",MM_DD:"03-15",CUTOFF:"2024-03-15",WK_NO:"W2024_11"},
      {DD_MMM_YY:"22-Mar-24",MM_DD:"03-22",CUTOFF:"2024-03-22",WK_NO:"W2024_12"},
      {DD_MMM_YY:"29-Mar-24",MM_DD:"03-29",CUTOFF:"2024-03-29",WK_NO:"W2024_13"},
      {DD_MMM_YY:"05-Apr-24",MM_DD:"04-05",CUTOFF:"2024-04-05",WK_NO:"W2024_14"},
      {DD_MMM_YY:"12-Apr-24",MM_DD:"04-12",CUTOFF:"2024-04-12",WK_NO:"W2024_15"},
      {DD_MMM_YY:"19-Apr-24",MM_DD:"04-19",CUTOFF:"2024-04-19",WK_NO:"W2024_16"},
      {DD_MMM_YY:"26-Apr-24",MM_DD:"04-26",CUTOFF:"2024-04-26",WK_NO:"W2024_17"},
      {DD_MMM_YY:"03-May-24",MM_DD:"05-03",CUTOFF:"2024-05-03",WK_NO:"W2024_18"},
      {DD_MMM_YY:"10-May-24",MM_DD:"05-10",CUTOFF:"2024-05-10",WK_NO:"W2024_19"},
      {DD_MMM_YY:"17-May-24",MM_DD:"05-17",CUTOFF:"2024-05-17",WK_NO:"W2024_20"},
      {DD_MMM_YY:"24-May-24",MM_DD:"05-24",CUTOFF:"2024-05-24",WK_NO:"W2024_21"},
      {DD_MMM_YY:"31-May-24",MM_DD:"05-31",CUTOFF:"2024-05-31",WK_NO:"W2024_22"},
      {DD_MMM_YY:"07-Jun-24",MM_DD:"06-07",CUTOFF:"2024-06-07",WK_NO:"W2024_23"},
      {DD_MMM_YY:"14-Jun-24",MM_DD:"06-14",CUTOFF:"2024-06-14",WK_NO:"W2024_24"},
      {DD_MMM_YY:"21-Jun-24",MM_DD:"06-21",CUTOFF:"2024-06-21",WK_NO:"W2024_25"},
      {DD_MMM_YY:"28-Jun-24",MM_DD:"06-28",CUTOFF:"2024-06-28",WK_NO:"W2024_26"},
      {DD_MMM_YY:"05-Jul-24",MM_DD:"07-05",CUTOFF:"2024-07-05",WK_NO:"W2024_27"},
      {DD_MMM_YY:"12-Jul-24",MM_DD:"07-12",CUTOFF:"2024-07-12",WK_NO:"W2024_28"},
      {DD_MMM_YY:"19-Jul-24",MM_DD:"07-19",CUTOFF:"2024-07-19",WK_NO:"W2024_29"},
      {DD_MMM_YY:"26-Jul-24",MM_DD:"07-26",CUTOFF:"2024-07-26",WK_NO:"W2024_30"},
      {DD_MMM_YY:"02-Aug-24",MM_DD:"08-02",CUTOFF:"2024-08-02",WK_NO:"W2024_31"},
      {DD_MMM_YY:"09-Aug-24",MM_DD:"08-09",CUTOFF:"2024-08-09",WK_NO:"W2024_32"},
      {DD_MMM_YY:"16-Aug-24",MM_DD:"08-16",CUTOFF:"2024-08-16",WK_NO:"W2024_33"},
      {DD_MMM_YY:"23-Aug-24",MM_DD:"08-23",CUTOFF:"2024-08-23",WK_NO:"W2024_34"},
      {DD_MMM_YY:"30-Aug-24",MM_DD:"08-30",CUTOFF:"2024-08-30",WK_NO:"W2024_35"},
      {DD_MMM_YY:"06-Sep-24",MM_DD:"09-06",CUTOFF:"2024-09-06",WK_NO:"W2024_36"},
      {DD_MMM_YY:"13-Sep-24",MM_DD:"09-13",CUTOFF:"2024-09-13",WK_NO:"W2024_37"},
      {DD_MMM_YY:"20-Sep-24",MM_DD:"09-20",CUTOFF:"2024-09-20",WK_NO:"W2024_38"},
      {DD_MMM_YY:"27-Sep-24",MM_DD:"09-27",CUTOFF:"2024-09-27",WK_NO:"W2024_39"},
      {DD_MMM_YY:"04-Oct-24",MM_DD:"10-04",CUTOFF:"2024-10-04",WK_NO:"W2024_40"},
      {DD_MMM_YY:"11-Oct-24",MM_DD:"10-11",CUTOFF:"2024-10-11",WK_NO:"W2024_41"},
      {DD_MMM_YY:"18-Oct-24",MM_DD:"10-18",CUTOFF:"2024-10-18",WK_NO:"W2024_42"},
      {DD_MMM_YY:"25-Oct-24",MM_DD:"10-25",CUTOFF:"2024-10-25",WK_NO:"W2024_43"},
      {DD_MMM_YY:"01-Nov-24",MM_DD:"11-01",CUTOFF:"2024-11-01",WK_NO:"W2024_44"},
      {DD_MMM_YY:"08-Nov-24",MM_DD:"11-08",CUTOFF:"2024-11-08",WK_NO:"W2024_45"},
      {DD_MMM_YY:"15-Nov-24",MM_DD:"11-15",CUTOFF:"2024-11-15",WK_NO:"W2024_46"},
      {DD_MMM_YY:"22-Nov-24",MM_DD:"11-22",CUTOFF:"2024-11-22",WK_NO:"W2024_47"},
      {DD_MMM_YY:"29-Nov-24",MM_DD:"11-29",CUTOFF:"2024-11-29",WK_NO:"W2024_48"},
      {DD_MMM_YY:"06-Dec-24",MM_DD:"12-06",CUTOFF:"2024-12-06",WK_NO:"W2024_49"},
      {DD_MMM_YY:"13-Dec-24",MM_DD:"12-13",CUTOFF:"2024-12-13",WK_NO:"W2024_50"},
      {DD_MMM_YY:"20-Dec-24",MM_DD:"12-20",CUTOFF:"2024-12-20",WK_NO:"W2024_51"},
      {DD_MMM_YY:"27-Dec-24",MM_DD:"12-27",CUTOFF:"2024-12-27",WK_NO:"W2024_52"},
      {DD_MMM_YY:"03-Jan-25",MM_DD:"01-03",CUTOFF:"2025-01-03",WK_NO:"W2024_53"}
    ],
    SQL5: [
      {TOTAL:520,TOGO:497,DELIVERED:11,DRESSUP:0,LIFTING:12}
    ]
  }
}