import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 390,
      CanvasHeight: 250,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Support Fabrication', x:20, y: -20, font: 'roboto', class: 'regular', size: 12, color: '#424242', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: -50, y: -20, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'start' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: -30,
          y: -20,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'start'
        },
      ]
    },
    Queries: {
      // SQL1: [
      //   {ORIGINATING_COMPANY:"E2 - Sinopec Engineering Incorporation",ISSUED:124,RESPONDED:104,OPEN:15,CANCELLED:5},
      //   {ORIGINATING_COMPANY:"EM - ExxonMobil Chemical",ISSUED:230,RESPONDED:179,OPEN:46,CANCELLED:5},
      //   {ORIGINATING_COMPANY:"Total",ISSUED:354,RESPONDED:283,OPEN:61,CANCELLED:10}
      // ],
    },
    DataItems:    [
      // {DISC:"B-Business and General",ISSUED:225,RESPONDED:187,OPEN:31,CANCELLED:7},
      // {DISC:"D-Mechanical and Static Equipment",ISSUED:10,RESPONDED:7,OPEN:2,CANCELLED:1},
      // {DISC:"E-Electrical",ISSUED:2,RESPONDED:2,OPEN:0,CANCELLED:0},
      // {DISC:"I-Instrumentation and Metering",ISSUED:24,RESPONDED:19,OPEN:5,CANCELLED:0},
      // {DISC:"K-Construction, Transportation, and Logistics",ISSUED:1,RESPONDED:1,OPEN:0,CANCELLED:0},
      // {DISC:"L-Piping and General Layout",ISSUED:8,RESPONDED:8,OPEN:0,CANCELLED:0},
      // {DISC:"M-Rotating Machinery",ISSUED:4,RESPONDED:3,OPEN:1,CANCELLED:0},
      // {DISC:"P-Process",ISSUED:34,RESPONDED:18,OPEN:15,CANCELLED:1},
      // {DISC:"Q-Quality",ISSUED:3,RESPONDED:1,OPEN:2,CANCELLED:0},
      // {DISC:"R-Regulatory, Environmental, and Socioeconomic",ISSUED:5,RESPONDED:4,OPEN:1,CANCELLED:0},
      // {DISC:"T-Telecommunications",ISSUED:1,RESPONDED:1,OPEN:0,CANCELLED:0},
      // {DISC:"V-Contracting and Procurement",ISSUED:36,RESPONDED:31,OPEN:4,CANCELLED:1},
      // {DISC:"X-Materials, Corrosion, and Flow Assurance",ISSUED:1,RESPONDED:1,OPEN:0,CANCELLED:0},
      // {DISC:"Total",ISSUED:354,RESPONDED:283,OPEN:61,CANCELLED:10}

      {CRANE_MAIN:"4000",MAIN_CRANE_SEQ:1,P1:6,P2:0,P3:0,TOTAL:6,DRESSUP_REQ:5},
      {CRANE_MAIN:"1600",MAIN_CRANE_SEQ:2,P1:9,P2:4,P3:0,TOTAL:13,DRESSUP_REQ:7},
      {CRANE_MAIN:"1250",MAIN_CRANE_SEQ:3,P1:0,P2:0,P3:1,TOTAL:1,DRESSUP_REQ:0},
      {CRANE_MAIN:"800",MAIN_CRANE_SEQ:4,P1:29,P2:4,P3:0,TOTAL:33,DRESSUP_REQ:13},
      {CRANE_MAIN:"750",MAIN_CRANE_SEQ:5,P1:88,P2:4,P3:0,TOTAL:92,DRESSUP_REQ:0},
      {CRANE_MAIN:"400",MAIN_CRANE_SEQ:6,P1:120,P2:31,P3:10,TOTAL:161,DRESSUP_REQ:9},
      {CRANE_MAIN:"280",MAIN_CRANE_SEQ:8,P1:31,P2:7,P3:0,TOTAL:38,DRESSUP_REQ:2},
      {CRANE_MAIN:"NA",MAIN_CRANE_SEQ:8,P1:124,P2:51,P3:0,TOTAL:176,DRESSUP_REQ:0},
      {CRANE_MAIN:"TOTAL",MAIN_CRANE_SEQ:100,P1:407,P2:101,P3:11,TOTAL:520,DRESSUP_REQ:36}

    ],
}