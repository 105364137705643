import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 600,
      CanvasHeight: 380,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     

    DataItems: [
      { TYPE: 'CERT', DATE: null         , DISC: 'WD'            , TOTAL: 775,   ACTUAL: 173,  REMAIN: 602,  PROG: 22.32, },
      { TYPE: 'CERT', DATE: null         , DISC: 'MCC'           , TOTAL: 775,   ACTUAL: 158,  REMAIN: 617,  PROG: 20.39, },
      { TYPE: 'DISC', DATE: null         , DISC: 'Mechanical'    , TOTAL: 80,    ACTUAL: 5,    REMAIN: 75,   PROG: 6.25, },
      { TYPE: 'DISC', DATE: null         , DISC: 'Telecom'       , TOTAL: 90,    ACTUAL: 12,   REMAIN: 78,   PROG: 13.33, },
      { TYPE: 'DISC', DATE: null         , DISC: 'Instrument'    , TOTAL: 63,    ACTUAL: 13,   REMAIN: 50,   PROG: 20.63, },
      { TYPE: 'DISC', DATE: null         , DISC: 'ICSS'          , TOTAL: 46,    ACTUAL: 34,   REMAIN: 12,   PROG: 73.91, },
      { TYPE: 'DISC', DATE: null         , DISC: 'Marine'        , TOTAL: 48,    ACTUAL: 15,   REMAIN: 33,   PROG: 31.25, },
      { TYPE: 'DISC', DATE: null         , DISC: 'Process'       , TOTAL: 92,    ACTUAL: 0,    REMAIN: 92,   PROG: 0, },
      { TYPE: 'DISC', DATE: null         , DISC: 'HVAC'          , TOTAL: 60,    ACTUAL: 0,    REMAIN: 60,   PROG: 0, },
      { TYPE: 'DISC', DATE: null         , DISC: 'Utility'       , TOTAL: 24,    ACTUAL: 0,    REMAIN: 24,   PROG: 0, },
      { TYPE: 'DISC', DATE: null         , DISC: 'Electrical'    , TOTAL: 202,   ACTUAL: 79,   REMAIN: 123,  PROG: 39.11, },
      { TYPE: 'DISC', DATE: null         , DISC: 'Structure'     , TOTAL: 70,    ACTUAL: 0,    REMAIN: 70,   PROG: 0, },
      { TYPE: 'WEEK', DATE: '2020-01-15' , DISC: 'LWK'           , TOTAL: null,  ACTUAL: 13,   REMAIN: null, PROG: null, },
      { TYPE: 'WEEK', DATE: '2020-01-15' , DISC: 'SAT'           , TOTAL: null,  ACTUAL: 0,    REMAIN: null, PROG: null, },
      { TYPE: 'WEEK', DATE: '2020-01-15' , DISC: 'SUN'           , TOTAL: null,  ACTUAL: 0,    REMAIN: null, PROG: null, },
      { TYPE: 'WEEK', DATE: '2020-01-15' , DISC: 'MON'           , TOTAL: null,  ACTUAL: 0,    REMAIN: null, PROG: null, },
      { TYPE: 'WEEK', DATE: '2020-01-15' , DISC: 'TUE'           , TOTAL: null,  ACTUAL: 2,    REMAIN: null, PROG: null, },
      { TYPE: 'WEEK', DATE: '2020-01-15' , DISC: 'WEN'           , TOTAL: null,  ACTUAL: 0,    REMAIN: null, PROG: null, },
      { TYPE: 'WEEK', DATE: '2020-01-15' , DISC: 'TUE'           , TOTAL: null,  ACTUAL: 0,    REMAIN: null, PROG: null, },
      { TYPE: 'WEEK', DATE: '2020-01-15' , DISC: 'FRI'           , TOTAL: null,  ACTUAL: 0,    REMAIN: null, PROG: null, },
      { TYPE: 'WEEK', DATE: '2020-01-15' , DISC: 'TWK'           , TOTAL: null,  ACTUAL: 2,    REMAIN: null, PROG: null, },
    ],
}