import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 2300,
    CanvasHeight: 980,
    CanvasBorderWeight: 0.05,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 50,
    CanvasChartY: 50,
    CanvasChartWidth: 480,
    CanvasChartHeight: 250,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  DataItems: [
    {SH:"21-1001",SH_DESCR:"Annulus Riser vent Monitoring System",RFSU:"21-1001-1001",RFSU_DESCR:"Annulus Riser vent Monitoring System",RFSU_PLAN:"2022-10-06",A_PG:71.97,B_PG:0,RFSU_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:6,B_OV_COM:0,B_OV_REM:6,B_OV_PROG:0,B_OV_STATUS:"less than 50%",PA_OV_TOT:6,PA_OV_REM:6},
    {SH:"21-1010",SH_DESCR:"Production flowlines & receiving facilities",RFSU:"21-1010-1011",RFSU_DESCR:"Flowline 1 & receiver",RFSU_PLAN:"2022-10-06",A_PG:49.89,B_PG:0.97,RFSU_STATUS:"In progress",PUNCH_STATUS:"A",B_OV_TOT:103,B_OV_COM:1,B_OV_REM:102,B_OV_PROG:0.97,B_OV_STATUS:"less than 50%",PA_OV_TOT:15,PA_OV_REM:6},
    {SH:"21-1010",SH_DESCR:"Production flowlines & receiving facilities",RFSU:"21-1010-1012",RFSU_DESCR:"Flowline 2 & receiver",RFSU_PLAN:"2022-10-06",A_PG:46.89,B_PG:0,RFSU_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:98,B_OV_COM:0,B_OV_REM:98,B_OV_PROG:0,B_OV_STATUS:"less than 50%",PA_OV_TOT:6,PA_OV_REM:4},
    {SH:"21-1090",SH_DESCR:"Production control umbilical",RFSU:"21-1090-1090",RFSU_DESCR:"Production control umbilical",RFSU_PLAN:"2022-08-25",A_PG:22.22,B_PG:0,RFSU_STATUS:"Not Started",PUNCH_STATUS:"N",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%"},
    {SH:"21-1510",SH_DESCR:"MEG Riser and Pipeline System to DC1",RFSU:"21-1510-1510",RFSU_DESCR:"MEG Riser and Pipeline System to DC1",RFSU_PLAN:"2022-09-29",A_PG:50,B_PG:0,RFSU_STATUS:"Not Started",PUNCH_STATUS:"B2",B_OV_TOT:7,B_OV_COM:0,B_OV_REM:7,B_OV_PROG:0,B_OV_STATUS:"less than 50%"},
    {SH:"21-1600",SH_DESCR:"Gas export risers",RFSU:"21-1600-1611",RFSU_DESCR:"Gas export riser #1",RFSU_PLAN:"2022-10-06",A_PG:60.46,B_PG:0,RFSU_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:19,B_OV_COM:0,B_OV_REM:19,B_OV_PROG:0,B_OV_STATUS:"less than 50%",PA_OV_TOT:1,PA_OV_REM:1},
    {SH:"21-1600",SH_DESCR:"Gas export risers",RFSU:"21-1600-1612",RFSU_DESCR:"Gas export riser #2",RFSU_PLAN:"2022-10-06",A_PG:63.74,B_PG:0,RFSU_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:19,B_OV_COM:0,B_OV_REM:19,B_OV_PROG:0,B_OV_STATUS:"less than 50%",PA_OV_TOT:2,PA_OV_REM:1},
    {SH:"21-1911",SH_DESCR:"Hydraulic Systems FPSO (Subsea HPU)",RFSU:"21-1911-1911",RFSU_DESCR:"Hydraulic HPUs, RIOs and TUTUs",RFSU_PLAN:"2022-10-06",A_PG:49.54,B_PG:0,RFSU_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:10,B_OV_COM:0,B_OV_REM:10,B_OV_PROG:0,B_OV_STATUS:"less than 50%",PA_OV_TOT:4,PA_OV_REM:2},
    {SH:"21-1931",SH_DESCR:"Subsea Controls (including MCS|EPU|etc) FPSO",RFSU:"21-1931-1931",RFSU_DESCR:"Subsea Control System (MCS), SSIV SPCU, EPUs, HVE JBs & LVE JBs",RFSU_PLAN:"2022-09-01",A_PG:55.8,B_PG:0,RFSU_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:19,B_OV_COM:0,B_OV_REM:19,B_OV_PROG:0,B_OV_STATUS:"less than 50%",PA_OV_TOT:15,PA_OV_REM:8,PB1_OV_TOT:7,PB1_OV_REM:0,BITR_PUNCHB1_REM:19},
    {SH:"21-2200",SH_DESCR:"Produced Condensate handling",RFSU:"21-2200-2131",RFSU_DESCR:"Slug catcher 1 & Liq htr 1",RFSU_PLAN:"2022-09-22",A_PG:88.94,B_PG:2,RFSU_STATUS:"In progress",PUNCH_STATUS:"A",B_OV_TOT:100,B_OV_COM:2,B_OV_REM:98,B_OV_PROG:2,B_OV_STATUS:"less than 50%",PA_OV_TOT:26,PA_OV_REM:2,PB1_OV_TOT:7,PB1_OV_REM:6,BITR_PUNCHB1_REM:104},
    {SH:"21-2200",SH_DESCR:"Produced Condensate handling",RFSU:"21-2200-2132",RFSU_DESCR:"Slug catcher 2 & Liq htr 2",RFSU_PLAN:"2022-09-22",A_PG:89.22,B_PG:0,RFSU_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:89,B_OV_COM:0,B_OV_REM:89,B_OV_PROG:0,B_OV_STATUS:"less than 50%",PA_OV_TOT:29,PA_OV_REM:2,PB1_OV_TOT:2,PB1_OV_REM:1,BITR_PUNCHB1_REM:90},
    {SH:"21-2200",SH_DESCR:"Produced Condensate handling",RFSU:"21-2200-2221",RFSU_DESCR:"MP separator",RFSU_PLAN:"2022-09-22",A_PG:92,B_PG:1.92,RFSU_STATUS:"In progress",PUNCH_STATUS:"B2",B_OV_TOT:52,B_OV_COM:1,B_OV_REM:51,B_OV_PROG:1.92,B_OV_STATUS:"less than 50%",PA_OV_TOT:13,PA_OV_REM:0},
    {SH:"21-2200",SH_DESCR:"Produced Condensate handling",RFSU:"21-2200-2230",RFSU_DESCR:"LP separator and interstage heaters",RFSU_PLAN:"2022-09-08",A_PG:96.27,B_PG:4.34,RFSU_STATUS:"In progress",PUNCH_STATUS:"B2",B_OV_TOT:46,B_OV_COM:2,B_OV_REM:44,B_OV_PROG:4.34,B_OV_STATUS:"less than 50%",PA_OV_TOT:23,PA_OV_REM:0},
    {SH:"21-2200",SH_DESCR:"Produced Condensate handling",RFSU:"21-2200-2240",RFSU_DESCR:"LLP separator, condensate treatment & coalescer|recycle pumps",RFSU_PLAN:"2022-09-15",A_PG:94,B_PG:3.38,RFSU_STATUS:"In progress",PUNCH_STATUS:"A",B_OV_TOT:118,B_OV_COM:4,B_OV_REM:114,B_OV_PROG:3.38,B_OV_STATUS:"less than 50%",PA_OV_TOT:60,PA_OV_REM:13,PB1_OV_TOT:9,PB1_OV_REM:1,BITR_PUNCHB1_REM:115},
    {SH:"21-2200",SH_DESCR:"Produced Condensate handling",RFSU:"21-2200-2470",RFSU_DESCR:"Warm condensate heater & distribution & offspec condensate pump",RFSU_PLAN:"2022-09-22",A_PG:88.62,B_PG:1.06,RFSU_STATUS:"In progress",PUNCH_STATUS:"A",B_OV_TOT:94,B_OV_COM:1,B_OV_REM:93,B_OV_PROG:1.06,B_OV_STATUS:"less than 50%",PA_OV_TOT:44,PA_OV_REM:4,PB1_OV_TOT:2,PB1_OV_REM:1,BITR_PUNCHB1_REM:94},
    {SH:"21-2400",SH_DESCR:"Condensate Storage & Export",RFSU:"21-2400-2440",RFSU_DESCR:"Condensate metering",RFSU_PLAN:"2022-09-08",A_PG:75.77,B_PG:1.14,RFSU_STATUS:"In progress",PUNCH_STATUS:"A",B_OV_TOT:87,B_OV_COM:1,B_OV_REM:86,B_OV_PROG:1.14,B_OV_STATUS:"less than 50%",PA_OV_TOT:19,PA_OV_REM:7,PB1_OV_TOT:18,PB1_OV_REM:11,BITR_PUNCHB1_REM:97,PUNCHB2B3_REM:33},
    {SH:"21-2400",SH_DESCR:"Condensate Storage & Export",RFSU:"21-2400-2450",RFSU_DESCR:"CFW1 Off-spec condensate tank & pumps (incl washing machine)",RFSU_PLAN:"2022-09-22",A_PG:98.86,B_PG:35.89,RFSU_STATUS:"In progress",PUNCH_STATUS:"A",B_OV_TOT:39,B_OV_COM:14,B_OV_REM:25,B_OV_PROG:35.89,B_OV_STATUS:"less than 50%",PA_OV_TOT:64,PA_OV_REM:1,PB1_OV_TOT:10,PB1_OV_REM:7,BITR_PUNCHB1_REM:32},
    {SH:"21-2400",SH_DESCR:"Condensate Storage & Export",RFSU:"21-2400-2460",RFSU_DESCR:"Condensate Offloading",RFSU_PLAN:"2022-09-29",A_PG:66.32,B_PG:0,RFSU_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:89,B_OV_COM:0,B_OV_REM:89,B_OV_PROG:0,B_OV_STATUS:"less than 50%",PA_OV_TOT:9,PA_OV_REM:3,PB1_OV_TOT:7,PB1_OV_REM:2,BITR_PUNCHB1_REM:91},
    {SH:"21-2400",SH_DESCR:"Condensate Storage & Export",RFSU:"21-2400-9360",RFSU_DESCR:"Condensate tanks interconnecting headers",RFSU_PLAN:"2022-10-06",A_PG:96.74,B_PG:11.45,RFSU_STATUS:"In progress",PUNCH_STATUS:"A",B_OV_TOT:96,B_OV_COM:11,B_OV_REM:85,B_OV_PROG:11.45,B_OV_STATUS:"less than 50%",PA_OV_TOT:156,PA_OV_REM:2},
    {SH:"21-2400",SH_DESCR:"Condensate Storage & Export",RFSU:"21-2400-9361",RFSU_DESCR:"COW1 tank & pumps (incl washing machine)",RFSU_PLAN:"2022-09-15",A_PG:99.5,B_PG:39.13,RFSU_STATUS:"In progress",PUNCH_STATUS:"A",B_OV_TOT:23,B_OV_COM:9,B_OV_REM:14,B_OV_PROG:39.13,B_OV_STATUS:"less than 50%",PA_OV_TOT:31,PA_OV_REM:1,PB1_OV_TOT:5,PB1_OV_REM:3,BITR_PUNCHB1_REM:17,PUNCHB2B3_REM:51},
    {SH:"21-2400",SH_DESCR:"Condensate Storage & Export",RFSU:"21-2400-9362",RFSU_DESCR:"COW2 tank & pumps (incl washing machine)",RFSU_PLAN:"2022-09-08",A_PG:99.5,B_PG:36,RFSU_STATUS:"In progress",PUNCH_STATUS:"B1",B_OV_TOT:25,B_OV_COM:9,B_OV_REM:16,B_OV_PROG:36,B_OV_STATUS:"less than 50%",PA_OV_TOT:25,PA_OV_REM:0,PB1_OV_TOT:16,PB1_OV_REM:3,BITR_PUNCHB1_REM:19,PUNCHB2B3_REM:35},
    {SH:"21-2400",SH_DESCR:"Condensate Storage & Export",RFSU:"21-2400-9363",RFSU_DESCR:"COW3 tank & pumps (incl washing machine)",RFSU_PLAN:"2022-09-01",A_PG:99.5,B_PG:37.5,RFSU_STATUS:"In progress",PUNCH_STATUS:"B1",B_OV_TOT:24,B_OV_COM:9,B_OV_REM:15,B_OV_PROG:37.5,B_OV_STATUS:"less than 50%",PA_OV_TOT:23,PA_OV_REM:0,PB1_OV_TOT:3,PB1_OV_REM:3,BITR_PUNCHB1_REM:18,PUNCHB2B3_REM:51},
    {SH:"21-2400",SH_DESCR:"Condensate Storage & Export",RFSU:"21-2400-9364",RFSU_DESCR:"COW4 tank & pumps (incl washing machine)",RFSU_PLAN:"2022-09-01",A_PG:99.48,B_PG:37.5,RFSU_STATUS:"In progress",PUNCH_STATUS:"A",B_OV_TOT:24,B_OV_COM:9,B_OV_REM:15,B_OV_PROG:37.5,B_OV_STATUS:"less than 50%",PA_OV_TOT:32,PA_OV_REM:1,PB1_OV_TOT:3,PB1_OV_REM:3,BITR_PUNCHB1_REM:18,PUNCHB2B3_REM:56},
    {SH:"21-2400",SH_DESCR:"Condensate Storage & Export",RFSU:"21-2400-9365",RFSU_DESCR:"COE1 tank & pumps (incl washing machine)",RFSU_PLAN:"2022-09-22",A_PG:99.46,B_PG:37.5,RFSU_STATUS:"In progress",PUNCH_STATUS:"B1",B_OV_TOT:24,B_OV_COM:9,B_OV_REM:15,B_OV_PROG:37.5,B_OV_STATUS:"less than 50%",PA_OV_TOT:25,PA_OV_REM:0,PB1_OV_TOT:8,PB1_OV_REM:3,BITR_PUNCHB1_REM:18},
    {SH:"21-2400",SH_DESCR:"Condensate Storage & Export",RFSU:"21-2400-9366",RFSU_DESCR:"COE2 tank & pumps (incl washing machine)",RFSU_PLAN:"2022-09-08",A_PG:98.9,B_PG:40,RFSU_STATUS:"In progress",PUNCH_STATUS:"B1",B_OV_TOT:25,B_OV_COM:10,B_OV_REM:15,B_OV_PROG:40,B_OV_STATUS:"less than 50%",PA_OV_TOT:41,PA_OV_REM:0,PB1_OV_TOT:4,PB1_OV_REM:4,BITR_PUNCHB1_REM:19},
    {SH:"21-2400",SH_DESCR:"Condensate Storage & Export",RFSU:"21-2400-9367",RFSU_DESCR:"COE3 tank & pumps (incl washing machine)",RFSU_PLAN:"2022-09-01",A_PG:100,B_PG:41.66,RFSU_STATUS:"In progress",PUNCH_STATUS:"A",B_OV_TOT:24,B_OV_COM:10,B_OV_REM:14,B_OV_PROG:41.66,B_OV_STATUS:"less than 50%",PA_OV_TOT:18,PA_OV_REM:1,PB1_OV_TOT:5,PB1_OV_REM:3,BITR_PUNCHB1_REM:17},
    {SH:"21-2400",SH_DESCR:"Condensate Storage & Export",RFSU:"21-2400-9368",RFSU_DESCR:"COE4 tank & pumps (incl washing machine)",RFSU_PLAN:"2022-09-01",A_PG:100,B_PG:41.66,RFSU_STATUS:"In progress",PUNCH_STATUS:"A",B_OV_TOT:24,B_OV_COM:10,B_OV_REM:14,B_OV_PROG:41.66,B_OV_STATUS:"less than 50%",PA_OV_TOT:33,PA_OV_REM:1,PB1_OV_TOT:5,PB1_OV_REM:3,BITR_PUNCHB1_REM:17},
    {SH:"21-2500",SH_DESCR:"MEG |Recovery and Injection",RFSU:"21-2500-2510",RFSU_DESCR:"MEG collection, flash drum, coolers & tanks interconnecting headers",RFSU_PLAN:"2022-10-06",A_PG:94.89,B_PG:6.77,RFSU_STATUS:"In progress",PUNCH_STATUS:"A",B_OV_TOT:59,B_OV_COM:4,B_OV_REM:55,B_OV_PROG:6.77,B_OV_STATUS:"less than 50%",PA_OV_TOT:48,PA_OV_REM:3,PB1_OV_TOT:1,PB1_OV_REM:0,BITR_PUNCHB1_REM:55},
    {SH:"21-2500",SH_DESCR:"MEG |Recovery and Injection",RFSU:"21-2500-2511",RFSU_DESCR:"MRW1 Rich MEG storage & pumps",RFSU_PLAN:"2022-09-01",A_PG:99.63,B_PG:12.24,RFSU_STATUS:"In progress",PUNCH_STATUS:"A",B_OV_TOT:49,B_OV_COM:6,B_OV_REM:43,B_OV_PROG:12.24,B_OV_STATUS:"less than 50%",PA_OV_TOT:45,PA_OV_REM:3,PB1_OV_TOT:8,PB1_OV_REM:3,BITR_PUNCHB1_REM:46},
    {SH:"21-2500",SH_DESCR:"MEG |Recovery and Injection",RFSU:"21-2500-2512",RFSU_DESCR:"MSE1 salty rich MEG storage & pumps",RFSU_PLAN:"2022-09-22",A_PG:100,B_PG:42.85,RFSU_STATUS:"In progress",PUNCH_STATUS:"B1",B_OV_TOT:28,B_OV_COM:12,B_OV_REM:16,B_OV_PROG:42.85,B_OV_STATUS:"less than 50%",PA_OV_TOT:38,PA_OV_REM:0,PB1_OV_TOT:7,PB1_OV_REM:4,BITR_PUNCHB1_REM:20}
  ],
  Queries: {
    SQL1: [
      { DESC: 'DataSource SPCS Data 03-Mar-20' }
    ],
    SQL2: [
      { CDATE: '2021-11-25' }
    ],
    // Data table & Line chart Data
    SQL3:   [
      {RFSU_PLAN:"2021-11-25",B_T:313,B_A:210,B_O:103,B_PG:67.09,PA_O:0,PB1_O:1,PB2_O:5},
      {RFSU_PLAN:"2021-12-02"},
      {RFSU_PLAN:"2021-12-09"},
      {RFSU_PLAN:"2021-12-16"},
      {RFSU_PLAN:"2021-12-23"},
      {RFSU_PLAN:"2021-12-30"},
      {RFSU_PLAN:"2022-01-06"},
      {RFSU_PLAN:"2022-01-13"},
      {RFSU_PLAN:"2022-01-20"},
      {RFSU_PLAN:"2022-01-27"},
      {RFSU_PLAN:"2022-02-03"},
      {RFSU_PLAN:"2022-02-10"},
      {RFSU_PLAN:"2022-02-17"},
      {RFSU_PLAN:"2022-02-24"},
      {RFSU_PLAN:"2022-03-03",B_T:12,B_A:9,B_O:3,B_PG:75,PA_O:0,PB1_O:1,PB2_O:14},
      {RFSU_PLAN:"2022-03-10"},
      {RFSU_PLAN:"2022-03-17",B_T:9,B_A:4,B_O:5,B_PG:44.44,PA_O:1,PB1_O:0,PB2_O:139},
      {RFSU_PLAN:"2022-03-24",B_T:116,B_A:70,B_O:46,B_PG:60.34,PA_O:0,PB1_O:0,PB2_O:8},
      {RFSU_PLAN:"2022-03-31",B_T:26,B_A:26,B_O:0,B_PG:100,PA_O:0,PB1_O:0,PB2_O:21},
      {RFSU_PLAN:"2022-04-07"},
      {RFSU_PLAN:"2022-04-14"},
      {RFSU_PLAN:"2022-04-21"},
      {RFSU_PLAN:"2022-04-28",B_T:47,B_A:42,B_O:5,B_PG:89.36,PA_O:0,PB1_O:0,PB2_O:15},
      {RFSU_PLAN:"2022-05-05",B_T:40,B_A:28,B_O:12,B_PG:70,PA_O:0,PB1_O:2,PB2_O:14,PB3_O:0},
      {RFSU_PLAN:"2022-05-12",B_T:196,B_A:190,B_O:6,B_PG:96.94,PA_O:0,PB1_O:0,PB2_O:38},
      {RFSU_PLAN:"2022-05-19",B_T:1952,B_A:1493,B_O:459,B_PG:76.49,PA_O:0,PB1_O:8,PB2_O:21},
      {RFSU_PLAN:"2022-05-26",B_T:386,B_A:339,B_O:47,B_PG:87.82,PA_O:0,PB1_O:1,PB2_O:219},
      {RFSU_PLAN:"2022-06-02",B_T:166,B_A:159,B_O:7,B_PG:95.78,PA_O:0,PB1_O:3,PB2_O:66},
      {RFSU_PLAN:"2022-06-09",B_T:294,B_A:216,B_O:78,B_PG:73.47,PA_O:5,PB1_O:1,PB2_O:22},
      {RFSU_PLAN:"2022-06-16",B_T:378,B_A:347,B_O:31,B_PG:91.8,PA_O:0,PB1_O:8,PB2_O:159,PB3_O:1},
      {RFSU_PLAN:"2022-06-23",B_T:819,B_A:555,B_O:264,B_PG:67.77,PA_O:0,PB1_O:0,PB2_O:72},
      {RFSU_PLAN:"2022-06-30",B_T:58,B_A:55,B_O:3,B_PG:94.83,PA_O:0,PB1_O:0,PB2_O:18},
      {RFSU_PLAN:"2022-07-07",B_T:1620,B_A:1487,B_O:133,B_PG:91.79,PA_O:11,PB1_O:9,PB2_O:276,PB3_O:2},
      {RFSU_PLAN:"2022-07-14",B_T:291,B_A:149,B_O:142,B_PG:51.2,PA_O:10,PB1_O:119,PB2_O:494,PB3_O:1},
      {RFSU_PLAN:"2022-07-21",B_T:192,B_A:41,B_O:151,B_PG:21.35,PA_O:17,PB1_O:4,PB2_O:197},
      {RFSU_PLAN:"2022-07-28",B_T:128,B_A:0,B_O:128,B_PG:0,PA_O:10,PB1_O:1,PB2_O:86},
      {RFSU_PLAN:"2022-08-04",B_T:883,B_A:626,B_O:257,B_PG:70.89,PA_O:13,PB1_O:15,PB2_O:411},
      {RFSU_PLAN:"2022-08-11",B_T:1246,B_A:991,B_O:255,B_PG:79.53,PA_O:33,PB1_O:36,PB2_O:517},
      {RFSU_PLAN:"2022-08-18",B_T:954,B_A:108,B_O:846,B_PG:11.32,PA_O:34,PB1_O:20,PB2_O:544},
      {RFSU_PLAN:"2022-08-25",B_T:699,B_A:269,B_O:430,B_PG:38.48,PA_O:38,PB1_O:8,PB2_O:579,PB3_O:3},
      {RFSU_PLAN:"2022-09-01",B_T:2612,B_A:306,B_O:2306,B_PG:11.72,PA_O:219,PB1_O:111,PB2_O:3028,PB3_O:23},
      {RFSU_PLAN:"2022-09-08",B_T:1662,B_A:372,B_O:1290,B_PG:22.38,PA_O:95,PB1_O:20,PB2_O:913,PB3_O:2},
      {RFSU_PLAN:"2022-09-15",B_T:3567,B_A:1052,B_O:2515,B_PG:29.49,PA_O:116,PB1_O:48,PB2_O:1243,PB3_O:0},
      {RFSU_PLAN:"2022-09-22",B_T:29,B_A:0,B_O:29,B_PG:0,PA_O:0,PB1_O:1,PB2_O:22},
      {RFSU_PLAN:"2022-09-29",B_T:841,B_A:51,B_O:790,B_PG:6.06,PA_O:20,PB1_O:36,PB2_O:221},
      {RFSU_PLAN:"2022-10-06"}
      ],
    SQL4: [
      {TOTAL:221,NOT_STARTED:43,IN_PROGRESS:105,BITR_COMPLETED:10,CTPSAT_COMPLETED:61,WD_COMPLETE:2,RFSU_COMPLETE:0}
    ],
    SQL5: [
      {CUTOFF:"2018-06-21",WK_NO:"W2018_25"},
      {CUTOFF:"2018-06-28",WK_NO:"W2018_26"},
      {CUTOFF:"2018-07-05",WK_NO:"W2018_27"},
      {CUTOFF:"2018-07-12",WK_NO:"W2018_28"},
      {CUTOFF:"2018-07-19",WK_NO:"W2018_29"},
      {CUTOFF:"2018-07-26",WK_NO:"W2018_30"},
      {CUTOFF:"2018-08-02",WK_NO:"W2018_31"},
      {CUTOFF:"2018-08-09",WK_NO:"W2018_32"},
      {CUTOFF:"2018-08-16",WK_NO:"W2018_33"},
      {CUTOFF:"2018-08-23",WK_NO:"W2018_34"},
      {CUTOFF:"2018-08-30",WK_NO:"W2018_35"},
      {CUTOFF:"2018-09-06",WK_NO:"W2018_36"},
      {CUTOFF:"2018-09-13",WK_NO:"W2018_37"},
      {CUTOFF:"2018-09-20",WK_NO:"W2018_38"},
      {CUTOFF:"2018-09-27",WK_NO:"W2018_39"},
      {CUTOFF:"2018-10-04",WK_NO:"W2018_40"},
      {CUTOFF:"2018-10-11",WK_NO:"W2018_41"},
      {CUTOFF:"2018-10-18",WK_NO:"W2018_42"},
      {CUTOFF:"2018-10-25",WK_NO:"W2018_43"},
      {CUTOFF:"2018-11-01",WK_NO:"W2018_44"},
      {CUTOFF:"2018-11-08",WK_NO:"W2018_45"},
      {CUTOFF:"2018-11-15",WK_NO:"W2018_46"},
      {CUTOFF:"2018-11-22",WK_NO:"W2018_47"},
      {CUTOFF:"2018-11-29",WK_NO:"W2018_48"},
      {CUTOFF:"2018-12-06",WK_NO:"W2018_49"},
      {CUTOFF:"2018-12-13",WK_NO:"W2018_50"},
      {CUTOFF:"2018-12-20",WK_NO:"W2018_51"},
      {CUTOFF:"2018-12-27",WK_NO:"W2018_52"},
      {CUTOFF:"2019-01-03",WK_NO:"W2019_01"},
      {CUTOFF:"2019-01-10",WK_NO:"W2019_02"},
      {CUTOFF:"2019-01-17",WK_NO:"W2019_03"},
      {CUTOFF:"2019-01-24",WK_NO:"W2019_04"},
      {CUTOFF:"2019-01-31",WK_NO:"W2019_05"},
      {CUTOFF:"2019-02-07",WK_NO:"W2019_06"},
      {CUTOFF:"2019-02-14",WK_NO:"W2019_07"},
      {CUTOFF:"2019-02-21",WK_NO:"W2019_08"},
      {CUTOFF:"2019-02-28",WK_NO:"W2019_09"},
      {CUTOFF:"2019-03-07",WK_NO:"W2019_10"},
      {CUTOFF:"2019-03-14",WK_NO:"W2019_11"},
      {CUTOFF:"2019-03-21",WK_NO:"W2019_12"},
      {CUTOFF:"2019-03-28",WK_NO:"W2019_13"},
      {CUTOFF:"2019-04-04",WK_NO:"W2019_14"},
      {CUTOFF:"2019-04-11",WK_NO:"W2019_15"},
      {CUTOFF:"2019-04-18",WK_NO:"W2019_16"},
      {CUTOFF:"2019-04-25",WK_NO:"W2019_17"},
      {CUTOFF:"2019-05-02",WK_NO:"W2019_18"},
      {CUTOFF:"2019-05-09",WK_NO:"W2019_19"},
      {CUTOFF:"2019-05-16",WK_NO:"W2019_20"},
      {CUTOFF:"2019-05-23",WK_NO:"W2019_21"},
      {CUTOFF:"2019-05-30",WK_NO:"W2019_22"},
      {CUTOFF:"2019-06-06",WK_NO:"W2019_23"},
      {CUTOFF:"2019-06-13",WK_NO:"W2019_24"},
      {CUTOFF:"2019-06-20",WK_NO:"W2019_25"},
      {CUTOFF:"2019-06-27",WK_NO:"W2019_26"},
      {CUTOFF:"2019-07-04",WK_NO:"W2019_27"},
      {CUTOFF:"2019-07-11",WK_NO:"W2019_28"},
      {CUTOFF:"2019-07-18",WK_NO:"W2019_29"},
      {CUTOFF:"2019-07-25",WK_NO:"W2019_30"},
      {CUTOFF:"2019-08-01",WK_NO:"W2019_31"},
      {CUTOFF:"2019-08-08",WK_NO:"W2019_32"},
      {CUTOFF:"2019-08-15",WK_NO:"W2019_33"},
      {CUTOFF:"2019-08-22",WK_NO:"W2019_34"},
      {CUTOFF:"2019-08-29",WK_NO:"W2019_35"},
      {CUTOFF:"2019-09-05",WK_NO:"W2019_36"},
      {CUTOFF:"2019-09-12",WK_NO:"W2019_37"},
      {CUTOFF:"2019-09-19",WK_NO:"W2019_38"},
      {CUTOFF:"2019-09-26",WK_NO:"W2019_39"},
      {CUTOFF:"2019-10-03",WK_NO:"W2019_40"},
      {CUTOFF:"2019-10-10",WK_NO:"W2019_41"},
      {CUTOFF:"2019-10-17",WK_NO:"W2019_42"},
      {CUTOFF:"2019-10-24",WK_NO:"W2019_43"},
      {CUTOFF:"2019-10-31",WK_NO:"W2019_44"},
      {CUTOFF:"2019-11-07",WK_NO:"W2019_45"},
      {CUTOFF:"2019-11-14",WK_NO:"W2019_46"},
      {CUTOFF:"2019-11-21",WK_NO:"W2019_47"},
      {CUTOFF:"2019-11-28",WK_NO:"W2019_48"},
      {CUTOFF:"2019-12-05",WK_NO:"W2019_49"},
      {CUTOFF:"2019-12-12",WK_NO:"W2019_50"},
      {CUTOFF:"2019-12-19",WK_NO:"W2019_51"},
      {CUTOFF:"2019-12-26",WK_NO:"W2019_52"},
      {CUTOFF:"2020-01-02",WK_NO:"W2020_01"},
      {CUTOFF:"2020-01-09",WK_NO:"W2020_02"},
      {CUTOFF:"2020-01-16",WK_NO:"W2020_03"},
      {CUTOFF:"2020-01-23",WK_NO:"W2020_04"},
      {CUTOFF:"2020-01-30",WK_NO:"W2020_05"},
      {CUTOFF:"2020-02-06",WK_NO:"W2020_06"},
      {CUTOFF:"2020-02-13",WK_NO:"W2020_07"},
      {CUTOFF:"2020-02-20",WK_NO:"W2020_08"},
      {CUTOFF:"2020-02-27",WK_NO:"W2020_09"},
      {CUTOFF:"2020-03-05",WK_NO:"W2020_10"},
      {CUTOFF:"2020-03-12",WK_NO:"W2020_11"},
      {CUTOFF:"2020-03-19",WK_NO:"W2020_12"},
      {CUTOFF:"2020-03-26",WK_NO:"W2020_13"},
      {CUTOFF:"2020-04-02",WK_NO:"W2020_14"},
      {CUTOFF:"2020-04-09",WK_NO:"W2020_15"},
      {CUTOFF:"2020-04-16",WK_NO:"W2020_16"},
      {CUTOFF:"2020-04-23",WK_NO:"W2020_17"},
      {CUTOFF:"2020-04-30",WK_NO:"W2020_18"},
      {CUTOFF:"2020-05-07",WK_NO:"W2020_19"},
      {CUTOFF:"2020-05-14",WK_NO:"W2020_20"},
      {CUTOFF:"2020-05-21",WK_NO:"W2020_21"},
      {CUTOFF:"2020-05-28",WK_NO:"W2020_22"},
      {CUTOFF:"2020-06-04",WK_NO:"W2020_23"},
      {CUTOFF:"2020-06-11",WK_NO:"W2020_24"},
      {CUTOFF:"2020-06-18",WK_NO:"W2020_25"},
      {CUTOFF:"2020-06-25",WK_NO:"W2020_26"},
      {CUTOFF:"2020-07-02",WK_NO:"W2020_27"},
      {CUTOFF:"2020-07-09",WK_NO:"W2020_28"},
      {CUTOFF:"2020-07-16",WK_NO:"W2020_29"},
      {CUTOFF:"2020-07-23",WK_NO:"W2020_30"},
      {CUTOFF:"2020-07-30",WK_NO:"W2020_31"},
      {CUTOFF:"2020-08-06",WK_NO:"W2020_32"},
      {CUTOFF:"2020-08-13",WK_NO:"W2020_33"},
      {CUTOFF:"2020-08-20",WK_NO:"W2020_34"},
      {CUTOFF:"2020-08-27",WK_NO:"W2020_35"},
      {CUTOFF:"2020-09-03",WK_NO:"W2020_36"},
      {CUTOFF:"2020-09-10",WK_NO:"W2020_37"},
      {CUTOFF:"2020-09-17",WK_NO:"W2020_38"},
      {CUTOFF:"2020-09-24",WK_NO:"W2020_39"},
      {CUTOFF:"2020-10-01",WK_NO:"W2020_40"},
      {CUTOFF:"2020-10-08",WK_NO:"W2020_41"},
      {CUTOFF:"2020-10-15",WK_NO:"W2020_42"},
      {CUTOFF:"2020-10-22",WK_NO:"W2020_43"},
      {CUTOFF:"2020-10-29",WK_NO:"W2020_44"},
      {CUTOFF:"2020-11-05",WK_NO:"W2020_45"},
      {CUTOFF:"2020-11-12",WK_NO:"W2020_46"},
      {CUTOFF:"2020-11-19",WK_NO:"W2020_47"},
      {CUTOFF:"2020-11-26",WK_NO:"W2020_48"},
      {CUTOFF:"2020-12-03",WK_NO:"W2020_49"},
      {CUTOFF:"2020-12-10",WK_NO:"W2020_50"},
      {CUTOFF:"2020-12-17",WK_NO:"W2020_51"},
      {CUTOFF:"2020-12-24",WK_NO:"W2020_52"},
      {CUTOFF:"2020-12-31",WK_NO:"W2020_53"},
      {CUTOFF:"2021-01-07",WK_NO:"W2021_01"},
      {CUTOFF:"2021-01-14",WK_NO:"W2021_02"},
      {CUTOFF:"2021-01-21",WK_NO:"W2021_03"},
      {CUTOFF:"2021-01-28",WK_NO:"W2021_04"},
      {CUTOFF:"2021-02-04",WK_NO:"W2021_05"},
      {CUTOFF:"2021-02-11",WK_NO:"W2021_06"},
      {CUTOFF:"2021-02-18",WK_NO:"W2021_07"},
      {CUTOFF:"2021-02-25",WK_NO:"W2021_08"},
      {CUTOFF:"2021-03-04",WK_NO:"W2021_09"},
      {CUTOFF:"2021-03-11",WK_NO:"W2021_10"},
      {CUTOFF:"2021-03-18",WK_NO:"W2021_11"},
      {CUTOFF:"2021-03-25",WK_NO:"W2021_12"},
      {CUTOFF:"2021-04-01",WK_NO:"W2021_13"},
      {CUTOFF:"2021-04-08",WK_NO:"W2021_14"},
      {CUTOFF:"2021-04-15",WK_NO:"W2021_15"},
      {CUTOFF:"2021-04-22",WK_NO:"W2021_16"},
      {CUTOFF:"2021-04-29",WK_NO:"W2021_17"},
      {CUTOFF:"2021-05-06",WK_NO:"W2021_18"},
      {CUTOFF:"2021-05-13",WK_NO:"W2021_19"},
      {CUTOFF:"2021-05-20",WK_NO:"W2021_20"},
      {CUTOFF:"2021-05-27",WK_NO:"W2021_21"},
      {CUTOFF:"2021-06-03",WK_NO:"W2021_22"},
      {CUTOFF:"2021-06-10",WK_NO:"W2021_23"},
      {CUTOFF:"2021-06-17",WK_NO:"W2021_24"},
      {CUTOFF:"2021-06-24",WK_NO:"W2021_25"},
      {CUTOFF:"2021-07-01",WK_NO:"W2021_26"},
      {CUTOFF:"2021-07-08",WK_NO:"W2021_27"},
      {CUTOFF:"2021-07-15",WK_NO:"W2021_28"},
      {CUTOFF:"2021-07-22",WK_NO:"W2021_29"},
      {CUTOFF:"2021-07-29",WK_NO:"W2021_30"},
      {CUTOFF:"2021-08-05",WK_NO:"W2021_31"},
      {CUTOFF:"2021-08-12",WK_NO:"W2021_32"},
      {CUTOFF:"2021-08-19",WK_NO:"W2021_33"},
      {CUTOFF:"2021-08-26",WK_NO:"W2021_34"},
      {CUTOFF:"2021-09-02",WK_NO:"W2021_35"},
      {CUTOFF:"2021-09-09",WK_NO:"W2021_36"},
      {CUTOFF:"2021-09-16",WK_NO:"W2021_37"},
      {CUTOFF:"2021-09-23",WK_NO:"W2021_38"},
      {CUTOFF:"2021-09-30",WK_NO:"W2021_39"},
      {CUTOFF:"2021-10-07",WK_NO:"W2021_40"},
      {CUTOFF:"2021-10-14",WK_NO:"W2021_41"},
      {CUTOFF:"2021-10-21",WK_NO:"W2021_42"},
      {CUTOFF:"2021-10-28",WK_NO:"W2021_43"},
      {CUTOFF:"2021-11-04",WK_NO:"W2021_44"},
      {CUTOFF:"2021-11-11",WK_NO:"W2021_45"},
      {CUTOFF:"2021-11-18",WK_NO:"W2021_46"},
      {CUTOFF:"2021-11-25",WK_NO:"W2021_47"},
      {CUTOFF:"2021-12-02",WK_NO:"W2021_48"},
      {CUTOFF:"2021-12-09",WK_NO:"W2021_49"},
      {CUTOFF:"2021-12-16",WK_NO:"W2021_50"},
      {CUTOFF:"2021-12-23",WK_NO:"W2021_51"},
      {CUTOFF:"2021-12-30",WK_NO:"W2021_52"},
      {CUTOFF:"2022-01-06",WK_NO:"W2022_01"},
      {CUTOFF:"2022-01-13",WK_NO:"W2022_02"},
      {CUTOFF:"2022-01-20",WK_NO:"W2022_03"},
      {CUTOFF:"2022-01-27",WK_NO:"W2022_04"},
      {CUTOFF:"2022-02-03",WK_NO:"W2022_05"},
      {CUTOFF:"2022-02-10",WK_NO:"W2022_06"},
      {CUTOFF:"2022-02-17",WK_NO:"W2022_07"},
      {CUTOFF:"2022-02-24",WK_NO:"W2022_08"},
      {CUTOFF:"2022-03-03",WK_NO:"W2022_09"},
      {CUTOFF:"2022-03-10",WK_NO:"W2022_10"},
      {CUTOFF:"2022-03-17",WK_NO:"W2022_11"},
      {CUTOFF:"2022-03-24",WK_NO:"W2022_12"},
      {CUTOFF:"2022-03-31",WK_NO:"W2022_13"},
      {CUTOFF:"2022-04-07",WK_NO:"W2022_14"},
      {CUTOFF:"2022-04-14",WK_NO:"W2022_15"},
      {CUTOFF:"2022-04-21",WK_NO:"W2022_16"},
      {CUTOFF:"2022-04-28",WK_NO:"W2022_17"},
      {CUTOFF:"2022-05-05",WK_NO:"W2022_18"},
      {CUTOFF:"2022-05-12",WK_NO:"W2022_19"},
      {CUTOFF:"2022-05-19",WK_NO:"W2022_20"},
      {CUTOFF:"2022-05-26",WK_NO:"W2022_21"},
      {CUTOFF:"2022-06-02",WK_NO:"W2022_22"},
      {CUTOFF:"2022-06-09",WK_NO:"W2022_23"},
      {CUTOFF:"2022-06-16",WK_NO:"W2022_24"},
      {CUTOFF:"2022-06-23",WK_NO:"W2022_25"},
      {CUTOFF:"2022-06-30",WK_NO:"W2022_26"},
      {CUTOFF:"2022-07-07",WK_NO:"W2022_27"},
      {CUTOFF:"2022-07-14",WK_NO:"W2022_28"},
      {CUTOFF:"2022-07-21",WK_NO:"W2022_29"},
      {CUTOFF:"2022-07-28",WK_NO:"W2022_30"},
      {CUTOFF:"2022-08-04",WK_NO:"W2022_31"},
      {CUTOFF:"2022-08-11",WK_NO:"W2022_32"},
      {CUTOFF:"2022-08-18",WK_NO:"W2022_33"},
      {CUTOFF:"2022-08-25",WK_NO:"W2022_34"},
      {CUTOFF:"2022-09-01",WK_NO:"W2022_35"},
      {CUTOFF:"2022-09-08",WK_NO:"W2022_36"},
      {CUTOFF:"2022-09-15",WK_NO:"W2022_37"},
      {CUTOFF:"2022-09-22",WK_NO:"W2022_38"},
      {CUTOFF:"2022-09-29",WK_NO:"W2022_39"},
      {CUTOFF:"2022-10-06",WK_NO:"W2022_40"},
      {CUTOFF:"2022-10-13",WK_NO:"W2022_41"},
      {CUTOFF:"2022-10-20",WK_NO:"W2022_42"},
      {CUTOFF:"2022-10-27",WK_NO:"W2022_43"},
      {CUTOFF:"2022-11-03",WK_NO:"W2022_44"},
      {CUTOFF:"2022-11-10",WK_NO:"W2022_45"},
      {CUTOFF:"2022-11-17",WK_NO:"W2022_46"},
      {CUTOFF:"2022-11-24",WK_NO:"W2022_47"},
      {CUTOFF:"2022-12-01",WK_NO:"W2022_48"},
      {CUTOFF:"2022-12-08",WK_NO:"W2022_49"},
      {CUTOFF:"2022-12-15",WK_NO:"W2022_50"},
      {CUTOFF:"2022-12-22",WK_NO:"W2022_51"},
      {CUTOFF:"2022-12-29",WK_NO:"W2022_52"},
      {CUTOFF:"2023-01-05",WK_NO:"W2023_01"},
      {CUTOFF:"2023-01-12",WK_NO:"W2023_02"},
      {CUTOFF:"2023-01-19",WK_NO:"W2023_03"},
      {CUTOFF:"2023-01-26",WK_NO:"W2023_04"},
      {CUTOFF:"2023-02-02",WK_NO:"W2023_05"},
      {CUTOFF:"2023-02-09",WK_NO:"W2023_06"},
      {CUTOFF:"2023-02-16",WK_NO:"W2023_07"},
      {CUTOFF:"2023-02-23",WK_NO:"W2023_08"},
      {CUTOFF:"2023-03-02",WK_NO:"W2023_09"},
      {CUTOFF:"2023-03-09",WK_NO:"W2023_10"},
      {CUTOFF:"2023-03-16",WK_NO:"W2023_11"},
      {CUTOFF:"2023-03-23",WK_NO:"W2023_12"},
      {CUTOFF:"2023-03-30",WK_NO:"W2023_13"}
      ],
  }
}