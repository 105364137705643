import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 200,
      CanvasHeight: 800,
      CanvasBorderWeight: 0.5,
      CanvasBorderColor: '#ccc',
      CanvasFillColor: '#fff',
      CanvasOpacity: 1,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },


    DataItems: [
      {CATEGORY:"A",DISC:"ELE",TOTAL:460,ACTUAL:183,REMAIN:277,PROG:39.78},
      {CATEGORY:"A",DISC:"HVAC",TOTAL:62,ACTUAL:1,REMAIN:61,PROG:1.61},
      {CATEGORY:"A",DISC:"INT",TOTAL:262,ACTUAL:81,REMAIN:181,PROG:30.92},
      {CATEGORY:"A",DISC:"MEC",TOTAL:276,ACTUAL:100,REMAIN:176,PROG:36.23},
      {CATEGORY:"A",DISC:"OTH",TOTAL:2,ACTUAL:0,REMAIN:2,PROG:0},
      {CATEGORY:"A",DISC:"PIP",TOTAL:2,ACTUAL:0,REMAIN:2,PROG:0},
      {CATEGORY:"A",DISC:"Overall",TOTAL:1064,ACTUAL:365,REMAIN:699,PROG:34.3},
      {CATEGORY:"B1",DISC:"ELE",TOTAL:878,ACTUAL:133,REMAIN:745,PROG:15.15},
      {CATEGORY:"B1",DISC:"HVAC",TOTAL:7,ACTUAL:0,REMAIN:7,PROG:0},
      {CATEGORY:"B1",DISC:"INT",TOTAL:169,ACTUAL:12,REMAIN:157,PROG:7.1},
      {CATEGORY:"B1",DISC:"MEC",TOTAL:45,ACTUAL:3,REMAIN:42,PROG:6.67},
      {CATEGORY:"B1",DISC:"OTH",TOTAL:1,ACTUAL:0,REMAIN:1,PROG:0},
      {CATEGORY:"B1",DISC:"Overall",TOTAL:1100,ACTUAL:148,REMAIN:952,PROG:13.45},
      {CATEGORY:"B2",DISC:"ELE",TOTAL:22,ACTUAL:2,REMAIN:20,PROG:9.09},
      {CATEGORY:"B2",DISC:"Overall",TOTAL:22,ACTUAL:2,REMAIN:20,PROG:9.09},
      {CATEGORY:"B3",DISC:"INT",TOTAL:2,ACTUAL:0,REMAIN:2,PROG:0},
      {CATEGORY:"B3",DISC:"Overall",TOTAL:2,ACTUAL:0,REMAIN:2,PROG:0}
    ],
}