import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 400,
    CanvasHeight: 380,
    CanvasBorderWeight: 0.1,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 760,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },

  Queries: {
    // filter
    SQL1:  [
      {
        FILTERSTRING: "OFF",
        TITLE_A: "WD",
        TITLE_B: "TO",
        TITLE_A1: "Total",
        TITLE_A2: "Complete",
        TITLE_A3: "Remain",
        TITLE_B1: "Total",
        TITLE_B2: "Approved",
        TITLE_B3: "Remain",
        LIST_A1 : "Plan",
        LIST_A2 : "Complete",
        LIST_A3 : "Remain",
        LIST_B1 : "Plan",
        LIST_B2 : "Full TO Complete",
        LIST_B3 : "Partial TO Complete",
        LIST_B4 : "Remain",
        FILTER_A1: " [CATEGORY]= ''WD'' ",
        FILTER_A2: " [CATEGORY]= ''WD'' AND [STATUS_CODE] = ''C'' ",
        FILTER_A3: " [CATEGORY]= ''WD'' AND [STATUS_CODE] != ''C'' ",
        FILTER_B1: " [CATEGORY]= ''TO'' ",
        FILTER_B2: " [CATEGORY]= ''TO'' AND [STATUS_CODE] = ''C'' ",
        FILTER_B3: " [CATEGORY]= ''TO'' AND [STATUS_CODE] != ''C'' ",
      },
    ],
  },


  DataItems: [
    {TYPE:"CERT",DISC:"WD",TOTAL:561,PLAN:203,ACTUAL:26,REMAIN:535,PROG:4.63},
    {TYPE:"CERT",DISC:"TO",TOTAL:561,PLAN:167,ACTUAL:0,APPROVED:4,ACTUAL_PARTIALLY:0,APPROVED_PARTIALLY:6,REMAIN:551,PROG:1.78},
    {TYPE:"WEEK",DATE:"2021-11-25",DISC:"LWK",ACTUAL:1},
    {TYPE:"WEEK",DATE:"2021-11-26",DISC:"SAT",ACTUAL:0},
    {TYPE:"WEEK",DATE:"2021-11-27",DISC:"SUN",ACTUAL:0},
    {TYPE:"WEEK",DATE:"2021-11-28",DISC:"MON",ACTUAL:0},
    {TYPE:"WEEK",DATE:"2021-11-29",DISC:"TUE",ACTUAL:0},
    {TYPE:"WEEK",DATE:"2021-11-30",DISC:"WEN",ACTUAL:0},
    {TYPE:"WEEK",DATE:"2021-12-01",DISC:"TUE",ACTUAL:0},
    {TYPE:"WEEK",DATE:"2021-12-02",DISC:"FRI",ACTUAL:0},
    {TYPE:"WEEK",DATE:"2021-12-02",DISC:"TWK",ACTUAL:0}
  ],
}