import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 330,
      CanvasHeight: 500,
      CanvasBorderWeight: 0.5,
      CanvasBorderColor: '#757575',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
     

    DataItems: [
      { NO: 1, SUBSYSTEM: '4730-03', MC_PLAN: '2020-07-17', PA_TOTAL: 660, PA_ACTUAL: 357, PA_REMAIN: 303, PB_TOTAL: 353, PB_ACTUAL: 258, PB_REMAIN: 95, },
{ NO: 2, SUBSYSTEM: '4620-03', MC_PLAN: '2020-05-29', PA_TOTAL: 931, PA_ACTUAL: 649, PA_REMAIN: 282, PB_TOTAL: 226, PB_ACTUAL: 86, PB_REMAIN: 140, },
{ NO: 3, SUBSYSTEM: '4730-02', MC_PLAN: '2020-07-17', PA_TOTAL: 659, PA_ACTUAL: 383, PA_REMAIN: 276, PB_TOTAL: 376, PB_ACTUAL: 276, PB_REMAIN: 100, },
{ NO: 4, SUBSYSTEM: '2400-05', MC_PLAN: '2020-06-12', PA_TOTAL: 537, PA_ACTUAL: 357, PA_REMAIN: 180, PB_TOTAL: 68, PB_ACTUAL: 14, PB_REMAIN: 54, },
{ NO: 5, SUBSYSTEM: '2400-06', MC_PLAN: '2020-06-12', PA_TOTAL: 517, PA_ACTUAL: 355, PA_REMAIN: 162, PB_TOTAL: 52, PB_ACTUAL: 7, PB_REMAIN: 45, },
{ NO: 6, SUBSYSTEM: '2400-07', MC_PLAN: '2020-06-12', PA_TOTAL: 519, PA_ACTUAL: 357, PA_REMAIN: 162, PB_TOTAL: 61, PB_ACTUAL: 16, PB_REMAIN: 45, },
{ NO: 7, SUBSYSTEM: '8002-03', MC_PLAN: '2020-06-19', PA_TOTAL: 120, PA_ACTUAL: 2, PA_REMAIN: 118, PB_TOTAL: 61, PB_ACTUAL: 2, PB_REMAIN: 59, },
{ NO: 8, SUBSYSTEM: '8002-02', MC_PLAN: '2020-06-26', PA_TOTAL: 125, PA_ACTUAL: 18, PA_REMAIN: 107, PB_TOTAL: 54, PB_ACTUAL: 4, PB_REMAIN: 50, },
{ NO: 9, SUBSYSTEM: '3000-01', MC_PLAN: '2020-04-24', PA_TOTAL: 211, PA_ACTUAL: 141, PA_REMAIN: 70, PB_TOTAL: 133, PB_ACTUAL: 50, PB_REMAIN: 83, },
{ NO: 10, SUBSYSTEM: '9973-02', MC_PLAN: '2020-03-27', PA_TOTAL: 179, PA_ACTUAL: 117, PA_REMAIN: 62, PB_TOTAL: 109, PB_ACTUAL: 84, PB_REMAIN: 25, },
{ NO: 11, SUBSYSTEM: '9250-02', MC_PLAN: '2020-05-15', PA_TOTAL: 342, PA_ACTUAL: 282, PA_REMAIN: 60, PB_TOTAL: 402, PB_ACTUAL: 269, PB_REMAIN: 133, },
{ NO: 12, SUBSYSTEM: '3000-02', MC_PLAN: '2020-04-24', PA_TOTAL: 204, PA_ACTUAL: 145, PA_REMAIN: 59, PB_TOTAL: 134, PB_ACTUAL: 59, PB_REMAIN: 75, },
{ NO: 13, SUBSYSTEM: '6210-02', MC_PLAN: '2020-06-05', PA_TOTAL: 347, PA_ACTUAL: 293, PA_REMAIN: 54, PB_TOTAL: 199, PB_ACTUAL: 141, PB_REMAIN: 58, },
{ NO: 14, SUBSYSTEM: '9973-01', MC_PLAN: '2020-04-03', PA_TOTAL: 160, PA_ACTUAL: 107, PA_REMAIN: 53, PB_TOTAL: 118, PB_ACTUAL: 87, PB_REMAIN: 31, },
{ NO: 15, SUBSYSTEM: '8500-01', MC_PLAN: '2020-06-05', PA_TOTAL: 117, PA_ACTUAL: 72, PA_REMAIN: 45, PB_TOTAL: 111, PB_ACTUAL: 95, PB_REMAIN: 16, },
{ NO: 16, SUBSYSTEM: '4730-03', MC_PLAN: '2020-07-17', PA_TOTAL: 660, PA_ACTUAL: 357, PA_REMAIN: 303, PB_TOTAL: 353, PB_ACTUAL: 258, PB_REMAIN: 95, },
{ NO: 17, SUBSYSTEM: '4620-03', MC_PLAN: '2020-05-29', PA_TOTAL: 931, PA_ACTUAL: 649, PA_REMAIN: 282, PB_TOTAL: 226, PB_ACTUAL: 86, PB_REMAIN: 140, },
{ NO: 18, SUBSYSTEM: '4730-02', MC_PLAN: '2020-07-17', PA_TOTAL: 659, PA_ACTUAL: 383, PA_REMAIN: 276, PB_TOTAL: 376, PB_ACTUAL: 276, PB_REMAIN: 100, },
{ NO: 19, SUBSYSTEM: '2400-05', MC_PLAN: '2020-06-12', PA_TOTAL: 537, PA_ACTUAL: 357, PA_REMAIN: 180, PB_TOTAL: 68, PB_ACTUAL: 14, PB_REMAIN: 54, },
{ NO: 20, SUBSYSTEM: '2400-06', MC_PLAN: '2020-06-12', PA_TOTAL: 517, PA_ACTUAL: 355, PA_REMAIN: 162, PB_TOTAL: 52, PB_ACTUAL: 7, PB_REMAIN: 45, },
{ NO: 21, SUBSYSTEM: '2400-07', MC_PLAN: '2020-06-12', PA_TOTAL: 519, PA_ACTUAL: 357, PA_REMAIN: 162, PB_TOTAL: 61, PB_ACTUAL: 16, PB_REMAIN: 45, },
{ NO: 22, SUBSYSTEM: '8002-03', MC_PLAN: '2020-06-19', PA_TOTAL: 120, PA_ACTUAL: 2, PA_REMAIN: 118, PB_TOTAL: 61, PB_ACTUAL: 2, PB_REMAIN: 59, },
{ NO: 23, SUBSYSTEM: '8002-02', MC_PLAN: '2020-06-26', PA_TOTAL: 125, PA_ACTUAL: 18, PA_REMAIN: 107, PB_TOTAL: 54, PB_ACTUAL: 4, PB_REMAIN: 50, },
{ NO: 24, SUBSYSTEM: '3000-01', MC_PLAN: '2020-04-24', PA_TOTAL: 211, PA_ACTUAL: 141, PA_REMAIN: 70, PB_TOTAL: 133, PB_ACTUAL: 50, PB_REMAIN: 83, },
{ NO: 25, SUBSYSTEM: '9973-02', MC_PLAN: '2020-03-27', PA_TOTAL: 179, PA_ACTUAL: 117, PA_REMAIN: 62, PB_TOTAL: 109, PB_ACTUAL: 84, PB_REMAIN: 25, },


    ],
}