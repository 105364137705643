import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 650,
      CanvasHeight: 230,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Support Fabrication', x:20, y: -20, font: 'roboto', class: 'regular', size: 12, color: '#424242', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: -50, y: -20, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'start' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: -30,
          y: -20,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'start'
        },
      ]
    },
    Queries: {
      // SQL1: [
      // ],
    },
    DataItems:    [
      {PACKAGE:"P1",ISSUED:467,APPROVED:279,UNDER_REVIEW:77,REJECTED:96,CANCELLED:15},
      {PACKAGE:"P2",ISSUED:302,APPROVED:230,UNDER_REVIEW:46,REJECTED:20,CANCELLED:6},
      {PACKAGE:"P3",ISSUED:687,APPROVED:547,UNDER_REVIEW:92,REJECTED:7,CANCELLED:41},
      {PACKAGE:"Total",ISSUED:1456,APPROVED:1056,UNDER_REVIEW:215,REJECTED:123,CANCELLED:62}

    ],
}