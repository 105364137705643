import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 430,
      CanvasHeight: 350,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Weight : ', x:240, y: 0, font: 'roboto', class: 'regular', size: 11, color: '#BCBCBC', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: 240, y: 0, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'end' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: 240,
          y: 0,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'end'
        },
      ]
    },
    DataItems: [

      {NO:1,TYPE:"MC",CATEGORY:"RFI",TOTAL:564,ACTUAL:10,REMAIN:554,PROG:1.8},
      {NO:2,TYPE:"MC",CATEGORY:"WD",TOTAL:564,ACTUAL:10,REMAIN:554,PROG:1.8},
      {NO:3.1,TYPE:"MC",CATEGORY:"MC Completed Partially",TOTAL:564,ACTUAL:1,REMAIN:563,PROG:0.2},
      {NO:3.2,TYPE:"MC",CATEGORY:"MC Completed",TOTAL:564,ACTUAL:0,REMAIN:564,PROG:0},
      {NO:3.3,TYPE:"MC",CATEGORY:"MC Approved Partially",TOTAL:564,ACTUAL:2,REMAIN:562,PROG:0.4},
      {NO:3.4,TYPE:"MC",CATEGORY:"MC Approved",TOTAL:564,ACTUAL:0,REMAIN:564,PROG:0},
      {NO:4,TYPE:"Comm.",CATEGORY:"RFDC",TOTAL:209,ACTUAL:0,REMAIN:209,PROG:0},
      {NO:5,TYPE:"Comm.",CATEGORY:"CTP",TOTAL:213,ACTUAL:24,REMAIN:189,PROG:11.3},
      {NO:6,TYPE:"RFSU",CATEGORY:"WD",TOTAL:564,ACTUAL:0,REMAIN:564,PROG:0},
      {NO:7,TYPE:"RFSU",CATEGORY:"Cert.",TOTAL:564,ACTUAL:0,REMAIN:564,PROG:0},
      {NO:8,TYPE:"Checksheet",CATEGORY:"Const. Vendor",TOTAL:6623,ACTUAL:5030,REMAIN:1593,PROG:75.9},
      {NO:9,TYPE:"Checksheet",CATEGORY:"Const. QCF",TOTAL:234173,ACTUAL:158610,REMAIN:75563,PROG:67.7},
      {NO:10,TYPE:"Checksheet",CATEGORY:"A ITR",TOTAL:84726,ACTUAL:17492,REMAIN:67234,PROG:20.6},
      {NO:11,TYPE:"Checksheet",CATEGORY:"B ITR",TOTAL:16100,ACTUAL:68,REMAIN:16032,PROG:0.4},
      {NO:12,TYPE:"Punch",CATEGORY:"PUNCH A",TOTAL:756,ACTUAL:152,REMAIN:604,PROG:20.1},
      {NO:13,TYPE:"Punch",CATEGORY:"PUNCH B1",TOTAL:771,ACTUAL:82,REMAIN:689,PROG:10.6},
      {NO:14,TYPE:"Punch",CATEGORY:"PUNCH B2",TOTAL:34,ACTUAL:0,REMAIN:34,PROG:0},
      {NO:15,TYPE:"Punch",CATEGORY:"PUNCH B3",TOTAL:1,ACTUAL:0,REMAIN:1,PROG:0}

      // {TYPE:"Certificate",CATEGORY:"RFI",TOTAL:3107,COMPLETED:110,REMAIN:3107,PROG:100},
      // {TYPE:"Certificate",CATEGORY:"WD",TOTAL:1642,COMPLETED:0,REMAIN:1642,PROG:70},
      // {TYPE:"Certificate",CATEGORY:"MC",TOTAL:19500,COMPLETED:0,REMAIN:19500,PROG:30},
      // {TYPE:"Certificate",CATEGORY:"RFDC",TOTAL:152,COMPLETED:0,REMAIN:152,PROG:0},
      // {TYPE:"Certificate",CATEGORY:"CTP",TOTAL:3107,COMPLETED:110,REMAIN:3107,PROG:11.3},
      // {TYPE:"Certificate",CATEGORY:"RFSU WD",TOTAL:1642,COMPLETED:0,REMAIN:1642,PROG:70},
      // {TYPE:"Certificate",CATEGORY:"RFSU",TOTAL:19500,COMPLETED:0,REMAIN:19500,PROG:30},
      // {TYPE:"Certificate",CATEGORY:"RFSU1",TOTAL:19500,COMPLETED:0,REMAIN:19500,PROG:30},
      // {TYPE:"Certificate",CATEGORY:"RFSU2",TOTAL:19500,COMPLETED:0,REMAIN:19500,PROG:30},
      // {TYPE:"Certificate",CATEGORY:"RFSU3",TOTAL:19500,COMPLETED:0,REMAIN:19500,PROG:30},
      // {TYPE:"Checksheet",CATEGORY:"Const. Vendor",TOTAL:152,COMPLETED:0,REMAIN:152,PROG:72.1},
      // {TYPE:"Checksheet",CATEGORY:"Const. QCF",TOTAL:205327,COMPLETED:144427,REMAIN:60900,PROG:70.3},
      // {TYPE:"Checksheet",CATEGORY:"A ITR",TOTAL:83971,COMPLETED:16776,REMAIN:67195,PROG:20},
      // {TYPE:"Checksheet",CATEGORY:"B ITR",TOTAL:16066,COMPLETED:0,REMAIN:1642,PROG:0.1},
      // {TYPE:"Punch"     ,CATEGORY:"PUNCH A",TOTAL:19500,COMPLETED:0,REMAIN:19500,PROG:18.9},
      // {TYPE:"Punch"     ,CATEGORY:"PUNCH B1",TOTAL:152,COMPLETED:0,REMAIN:152,PROG:27.1},
      // {TYPE:"Punch"     ,CATEGORY:"PUNCH B2",TOTAL:3107,COMPLETED:110,REMAIN:3107,PROG:42.4},
      // {TYPE:"Punch"     ,CATEGORY:"PUNCH B3",TOTAL:1642,COMPLETED:0,REMAIN:1642,PROG:0},
    ],
}