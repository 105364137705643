import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 200,
      CanvasHeight: 200,
      CanvasBorderWeight: 0.5,
      CanvasBorderColor: '#ccc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Summary', x:10, y: 0, font: 'roboto', class: 'regular', size: 12, color: '#424242', align: 'start' },
      ],
      Filter: [],
      Sql: []
    },

    Queries: {
      SQL1:  [
        {
          FILTERSTRING: "OFF",
          TITLE: "A Check Sheets",
          TITLE1: "Total",
          TITLE2: "Closed",
          TITLE3: "Open",
          FILTER1: " [CATEGORY] = ''A''",
          FILTER2: " [CATEGORY] = ''A'' AND [STATUS_CODE] = ''C''",
          FILTER3: " [CATEGORY] = ''A'' AND [STATUS_CODE] != ''C''",
        },
      ],
    },

    DataItems: [
      {CATEGORY:"A",DISC:"E",     DISC_SHORT: "E.ELE",         TOTAL:220,ACTUAL:59,REMAIN:161,PROG:26.82},
      {CATEGORY:"A",DISC:"H",     DISC_SHORT: "H.HVA",         TOTAL:60,ACTUAL:1,REMAIN:59,PROG:1.67},
      {CATEGORY:"A",DISC:"I",     DISC_SHORT: "I.INS",         TOTAL:152,ACTUAL:35,REMAIN:117,PROG:23.03},
      {CATEGORY:"A",DISC:"M",     DISC_SHORT: "M.ROT",         TOTAL:278,ACTUAL:38,REMAIN:240,PROG:13.67},
      {CATEGORY:"A",DISC:"P",     DISC_SHORT: "P.PRO",         TOTAL:1,ACTUAL:0,REMAIN:1,PROG:0},
      {CATEGORY:"A",DISC:"Overall",DISC_SHORT: "Overall",      TOTAL:713,ACTUAL:133,REMAIN:580,PROG:18.65},
    ],
}