import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 2300,
    CanvasHeight: 980,
    CanvasBorderWeight: 0.05,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.1,

    CanvasChartX: 50,
    CanvasChartY: 50,
    CanvasChartWidth: 480,
    CanvasChartHeight: 250,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  DataItems:   [
    {SUBSYSTEM:"21-2400-2440-01",SUBSYSTEM_DESCR:"Condensate Metering",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-06-09",A_OV_TOT:38,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:38,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:6,PA_OV_REM:5,PB1_OV_TOT:21,PB1_OV_REM:20},
    {SUBSYSTEM:"21-2400-2460-01",SUBSYSTEM_DESCR:"Condensate Offloading",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and No MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-06-09",A_OV_TOT:50,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:50,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PB1_OV_TOT:6,PB1_OV_REM:6},
    {SUBSYSTEM:"21-3040-3040-01",SUBSYSTEM_DESCR:"HPandMP Gas Compressors, UCP, VSD and Electrical ControlandSwitchboards",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-04-07",A_OV_TOT:84,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:84,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:34,PA_OV_REM:21,PB1_OV_TOT:5,PB1_OV_REM:5},
    {SUBSYSTEM:"21-3040-3041-01",SUBSYSTEM_DESCR:"LPandLLP Gas Compressors , UCP, VSD and Electrical ControlandSwitchboards",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-04-07",A_OV_TOT:55,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:55,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:7,PA_OV_REM:7,PB1_OV_TOT:13,PB1_OV_REM:12},
    {SUBSYSTEM:"21-3330-3330-03",SUBSYSTEM_DESCR:"Turbo Expander, Recompressor",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-12",A_OV_TOT:86,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:86,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:1,PB1_OV_TOT:4,PB1_OV_REM:4},
    {SUBSYSTEM:"21-6010-6011-01",SUBSYSTEM_DESCR:"SIS Marshalling - Machinery Space ,HER",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Complete",PUNCH_STATUS:"A",MC_PLAN:"2021-10-21",A_OV_TOT:43,A_OV_COM:43,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:4,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:38,PA_OV_REM:8,PB1_OV_TOT:14,PB1_OV_REM:2},
    {SUBSYSTEM:"21-6010-6011-02",SUBSYSTEM_DESCR:"SIS Marshalling - LQ ,CER",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Complete",PUNCH_STATUS:"A",MC_PLAN:"2021-10-28",A_OV_TOT:14,A_OV_COM:14,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:24,PA_OV_REM:1,PB1_OV_TOT:5,PB1_OV_REM:1},
    {SUBSYSTEM:"21-6010-6011-03",SUBSYSTEM_DESCR:"SIS Marshalling - Topsides ,ME05 and LER",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2021-11-18",A_OV_TOT:22,A_OV_COM:22,A_OV_COM_LW:0,A_OV_COM_TW:2,A_OV_COM_CLIENT:0,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:4,PA_OV_REM:0,PB1_OV_TOT:15,PB1_OV_REM:15},
    {SUBSYSTEM:"21-6020-6021-01",SUBSYSTEM_DESCR:"PAS Marshalling - Machinery Space ,HER",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Complete",PUNCH_STATUS:"A",MC_PLAN:"2021-09-30",A_OV_TOT:46,A_OV_COM:46,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:4,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0,PA_OV_TOT:10,PA_OV_REM:4,PB1_OV_TOT:20,PB1_OV_REM:2},
    {SUBSYSTEM:"21-6020-6021-02",SUBSYSTEM_DESCR:"PAS Marshalling - LQ ,CER",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2021-10-28",A_OV_TOT:20,A_OV_COM:15,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:5,A_OV_PROG:75,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0,PA_OV_TOT:9,PA_OV_REM:1,PB1_OV_TOT:10,PB1_OV_REM:1},
    {SUBSYSTEM:"21-6020-6021-03",SUBSYSTEM_DESCR:"PAS Marshalling - Topsides ,ME05 and LER",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2021-11-18",A_OV_TOT:15,A_OV_COM:15,A_OV_COM_LW:0,A_OV_COM_TW:1,A_OV_COM_CLIENT:0,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:0,PB1_OV_TOT:29,PB1_OV_REM:29},
    {SUBSYSTEM:"21-6030-6031-01",SUBSYSTEM_DESCR:"Electrical Data Monitoring and Constrol System ,EDMCS and Power Management System ,PMS",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2021-12-30",A_OV_TOT:115,A_OV_COM:68,A_OV_COM_LW:22,A_OV_COM_TW:6,A_OV_COM_CLIENT:33,A_OV_REM:47,A_OV_PROG:59.13,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0,PA_OV_TOT:12,PA_OV_REM:1,PB1_OV_TOT:6,PB1_OV_REM:2},
    {SUBSYSTEM:"21-6030-6032-01",SUBSYSTEM_DESCR:"ICSS Interface Servers, Workstations and Network Switches CER",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Partial MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2021-11-04",A_OV_TOT:213,A_OV_COM:202,A_OV_COM_LW:3,A_OV_COM_TW:0,A_OV_COM_CLIENT:7,A_OV_REM:11,A_OV_PROG:94.83,B_OV_TOT:13,B_OV_COM:1,B_OV_COM_CLIENT:0,B_OV_REM:12,B_OV_PROG:7.69,PA_OV_TOT:28,PA_OV_REM:0,PB1_OV_TOT:184,PB1_OV_REM:143,PB2_OV_TOT:1,PB2_OV_REM:1},
    {SUBSYSTEM:"21-6030-6032-02",SUBSYSTEM_DESCR:"ICSS Interface Servers, Workstations and Network Switches LER",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2021-11-18",A_OV_TOT:190,A_OV_COM:10,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:180,A_OV_PROG:5.26,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PB1_OV_TOT:16,PB1_OV_REM:16},
    {SUBSYSTEM:"21-6030-6032-03",SUBSYSTEM_DESCR:"ICSS Interface Servers, Workstations and Network Switches HER",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Partial MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2021-10-21",A_OV_TOT:133,A_OV_COM:116,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:5,A_OV_REM:17,A_OV_PROG:87.21,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:3,PA_OV_REM:0,PB1_OV_TOT:61,PB1_OV_REM:55,PB2_OV_TOT:1,PB2_OV_REM:1},
    {SUBSYSTEM:"21-6030-6032-04",SUBSYSTEM_DESCR:"ICSS Workstations and Consoles - CCR",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2021-11-11",A_OV_TOT:66,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:66,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0},
    {SUBSYSTEM:"21-6030-6032-05",SUBSYSTEM_DESCR:"ICSS Workstations and Consoles - Safe HavenandSOP",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-03-31",A_OV_TOT:5,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:5,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0},
    {SUBSYSTEM:"21-6030-6034-01",SUBSYSTEM_DESCR:"Machinery Protection System ,MPS",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and No MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2021-12-09",A_OV_TOT:22,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:22,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PB1_OV_TOT:1,PB1_OV_REM:1},
    {SUBSYSTEM:"21-6030-6037-01",SUBSYSTEM_DESCR:"Tank Gauging System Cabinets and Workstations",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2021-10-21",A_OV_TOT:15,A_OV_COM:8,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:2,A_OV_REM:7,A_OV_PROG:53.33,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PB1_OV_TOT:13,PB1_OV_REM:10},
    {SUBSYSTEM:"21-6030-6038-01",SUBSYSTEM_DESCR:"Cargo Loading Computer and Workstation",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-02-24",A_OV_TOT:3,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:3,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0},
    {SUBSYSTEM:"21-6030-6041-01",SUBSYSTEM_DESCR:"PEMS System",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and No MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2021-11-04",A_OV_TOT:3,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:3,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PB1_OV_TOT:5,PB1_OV_REM:5},
    {SUBSYSTEM:"21-6030-6042-01",SUBSYSTEM_DESCR:"Corrosion and Erosion Monitoring",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and No MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-02-10",A_OV_TOT:2,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:2,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PB1_OV_TOT:2,PB1_OV_REM:2},
    {SUBSYSTEM:"21-6035-6035-01",SUBSYSTEM_DESCR:"Process CCTV Cameras, Consoles and Screens",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2021-11-11",A_OV_TOT:59,A_OV_COM:8,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:51,A_OV_PROG:13.55,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,PB1_OV_TOT:5,PB1_OV_REM:0},
    {SUBSYSTEM:"21-6070-6071-01",SUBSYSTEM_DESCR:"FGS Marshalling - Machinery Space ,HER",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Complete",PUNCH_STATUS:"A",MC_PLAN:"2021-10-21",A_OV_TOT:14,A_OV_COM:14,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:10,PA_OV_REM:2,PB1_OV_TOT:10,PB1_OV_REM:0},
    {SUBSYSTEM:"21-6070-6071-02",SUBSYSTEM_DESCR:"FGS Marshalling - LQ ,CER",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Complete",PUNCH_STATUS:"A",MC_PLAN:"2021-10-28",A_OV_TOT:27,A_OV_COM:27,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:2,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:24,PA_OV_REM:3,PB1_OV_TOT:13,PB1_OV_REM:1},
    {SUBSYSTEM:"21-6070-6071-03",SUBSYSTEM_DESCR:"FGS Marshalling - Topsides ,ME05 and LER",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2021-11-18",A_OV_TOT:8,A_OV_COM:7,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:1,A_OV_PROG:87.5,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:0,PB1_OV_TOT:6,PB1_OV_REM:6},
    {SUBSYSTEM:"21-6100-6111-01",SUBSYSTEM_DESCR:"Fire Water Pump A ,21-SPF-611101",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and No MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-31",A_OV_TOT:28,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:28,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:0,PB1_OV_TOT:4,PB1_OV_REM:4},
    {SUBSYSTEM:"21-6100-6111-02",SUBSYSTEM_DESCR:"Fire Water Pump B ,21-SPF-611201",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and No MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-31",A_OV_TOT:25,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:25,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PB1_OV_TOT:2,PB1_OV_REM:1},
    {SUBSYSTEM:"21-6100-6111-03",SUBSYSTEM_DESCR:"Fire Water Pump C ,21-SPF-611301",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-03-31",A_OV_TOT:27,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:27,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:3,PA_OV_REM:3,PB1_OV_TOT:1,PB1_OV_REM:1},
    {SUBSYSTEM:"21-6200-6210-01",SUBSYSTEM_DESCR:"PAGA System Cabinets and Central Equipment",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2021-12-02",A_OV_TOT:38,A_OV_COM:4,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:34,A_OV_PROG:10.52,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,PB1_OV_TOT:6,PB1_OV_REM:0},
    {SUBSYSTEM:"21-6200-6224-01",SUBSYSTEM_DESCR:"Digital UHF Network",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-01-13",A_OV_TOT:143,A_OV_COM:6,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:137,A_OV_PROG:4.19,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:0},
    {SUBSYSTEM:"21-6500-6550-01",SUBSYSTEM_DESCR:"RADAR Vessel Early Warning System",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2021-12-16",A_OV_TOT:22,A_OV_COM:5,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:17,A_OV_PROG:22.72,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:0,PB1_OV_TOT:1,PB1_OV_REM:1},
    {SUBSYSTEM:"21-6600-6610-01",SUBSYSTEM_DESCR:"Emergency Generator 21-G-661001",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2021-11-04",A_OV_TOT:107,A_OV_COM:40,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:17,A_OV_REM:67,A_OV_PROG:37.38,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:17,PA_OV_REM:15,PB1_OV_TOT:28,PB1_OV_REM:16},
    {SUBSYSTEM:"21-6600-6660-01",SUBSYSTEM_DESCR:"Emergency 690 V Switchboard 21-ESB-666001",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Partial MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2021-09-30",A_OV_TOT:130,A_OV_COM:118,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:115,A_OV_REM:12,A_OV_PROG:90.76,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:10,PA_OV_REM:0,PB1_OV_TOT:9,PB1_OV_REM:2,PB2_OV_TOT:37,PB2_OV_REM:9},
    {SUBSYSTEM:"21-6600-6670-01",SUBSYSTEM_DESCR:"Key service Generator 21-G-667001",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2021-11-18",A_OV_TOT:59,A_OV_COM:47,A_OV_COM_LW:2,A_OV_COM_TW:0,A_OV_COM_CLIENT:26,A_OV_REM:12,A_OV_PROG:79.66,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:50,PA_OV_REM:20,PB1_OV_TOT:37,PB1_OV_REM:19,PB2_OV_TOT:28,PB2_OV_REM:28,PB3_OV_TOT:1,PB3_OV_REM:1},
    {SUBSYSTEM:"21-6600-6670-02",SUBSYSTEM_DESCR:"Key service Generator 21-G-667002",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2021-11-18",A_OV_TOT:53,A_OV_COM:43,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:26,A_OV_REM:10,A_OV_PROG:81.13,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:16,PA_OV_REM:14,PB1_OV_TOT:33,PB1_OV_REM:19,PB2_OV_TOT:6,PB2_OV_REM:6,PB3_OV_TOT:4,PB3_OV_REM:4},
    {SUBSYSTEM:"21-6600-6673-01",SUBSYSTEM_DESCR:"Essential 6.6 kV Switchboard 21-ESB-667301",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Partial MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2021-10-14",A_OV_TOT:248,A_OV_COM:157,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:149,A_OV_REM:91,A_OV_PROG:63.3,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:11,PA_OV_REM:0,PB1_OV_TOT:42,PB1_OV_REM:20,PB3_OV_TOT:3,PB3_OV_REM:3},
    {SUBSYSTEM:"21-6600-6674-01",SUBSYSTEM_DESCR:"HULL Normal and Essential 690 V Swtchboard 21-ESB-667401",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Partial MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2021-09-23",A_OV_TOT:189,A_OV_COM:183,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:181,A_OV_REM:6,A_OV_PROG:96.82,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:9,PA_OV_REM:0,PB1_OV_TOT:10,PB1_OV_REM:1,PB2_OV_TOT:34,PB2_OV_REM:4,PB3_OV_TOT:3,PB3_OV_REM:3},
    {SUBSYSTEM:"21-6600-6674-02",SUBSYSTEM_DESCR:"Topside Essential 690 V Switchboard 21-ESB-667402",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2021-10-28",A_OV_TOT:97,A_OV_COM:61,A_OV_COM_LW:0,A_OV_COM_TW:3,A_OV_COM_CLIENT:0,A_OV_REM:36,A_OV_PROG:62.88,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:3,PA_OV_REM:3,PB1_OV_TOT:51,PB1_OV_REM:36},
    {SUBSYSTEM:"21-6600-6675-01",SUBSYSTEM_DESCR:"HULL Normal and Essential 400 V Switchboard 21-ESB-667601",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Partial MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2021-09-23",A_OV_TOT:23,A_OV_COM:21,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:20,A_OV_REM:2,A_OV_PROG:91.3,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:8,PA_OV_REM:0,PB1_OV_TOT:9,PB1_OV_REM:1,PB2_OV_TOT:22,PB2_OV_REM:3,PB3_OV_TOT:1,PB3_OV_REM:1},
    {SUBSYSTEM:"21-6625-8725-01",SUBSYSTEM_DESCR:"Normal and Essential Heat Trace 400V Topsides 21-EDB-667605",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and No MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2021-11-11",A_OV_TOT:1,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:1,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PB1_OV_TOT:1,PB1_OV_REM:1},
    {SUBSYSTEM:"21-8000-8010-01",SUBSYSTEM_DESCR:"Potable Water Maker",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2021-11-04",A_OV_TOT:41,A_OV_COM:27,A_OV_COM_LW:2,A_OV_COM_TW:3,A_OV_COM_CLIENT:6,A_OV_REM:14,A_OV_PROG:65.85,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:30,PA_OV_REM:15,PB1_OV_TOT:24,PB1_OV_REM:21},
    {SUBSYSTEM:"21-8000-8013-01",SUBSYSTEM_DESCR:"Hot Water Calorifiers",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-01-27",A_OV_TOT:13,A_OV_COM:7,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:4,A_OV_REM:6,A_OV_PROG:53.84,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:24,PA_OV_REM:7,PB1_OV_TOT:4,PB1_OV_REM:2},
    {SUBSYSTEM:"21-8090-8090-01",SUBSYSTEM_DESCR:"Sewage Treatment Package",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2021-12-23",A_OV_TOT:8,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:8,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:16,PA_OV_REM:2,PB1_OV_TOT:3,PB1_OV_REM:3},
    {SUBSYSTEM:"21-8100-8120-01",SUBSYSTEM_DESCR:"HULL Air Compressor 21-Z-811002",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Complete",PUNCH_STATUS:"A",MC_PLAN:"2021-10-21",A_OV_TOT:23,A_OV_COM:23,A_OV_COM_LW:8,A_OV_COM_TW:0,A_OV_COM_CLIENT:9,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:11,PA_OV_REM:1,PB1_OV_TOT:23,PB1_OV_REM:17},
    {SUBSYSTEM:"21-8100-8122-01",SUBSYSTEM_DESCR:"Topside Air Compressor 21-Z-811101",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-02-17",A_OV_TOT:35,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:35,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:1,PB1_OV_TOT:8,PB1_OV_REM:8,PB3_OV_TOT:1,PB3_OV_REM:1},
    {SUBSYSTEM:"21-8160-8160-01",SUBSYSTEM_DESCR:"Inert Gas Generator",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-06-09",A_OV_TOT:54,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:54,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:5,PA_OV_REM:4,PB1_OV_TOT:6,PB1_OV_REM:6},
    {SUBSYSTEM:"21-8600-8611-01",SUBSYSTEM_DESCR:"GTG 1 and WHRU1",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and No MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-02-17",A_OV_TOT:186,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:186,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:0,PB1_OV_TOT:10,PB1_OV_REM:9},
    {SUBSYSTEM:"21-8600-8612-01",SUBSYSTEM_DESCR:"GTG 2 and WHRU2",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-02-17",A_OV_TOT:182,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:182,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PB1_OV_TOT:1,PB1_OV_REM:0},
    {SUBSYSTEM:"21-8600-8613-01",SUBSYSTEM_DESCR:"GTG 3 and WHRU3",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and No MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2021-12-30",A_OV_TOT:182,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:182,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PB1_OV_TOT:3,PB1_OV_REM:2},
    {SUBSYSTEM:"21-8600-8614-01",SUBSYSTEM_DESCR:"GTG 4 and WHRU4",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2021-12-30",A_OV_TOT:181,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:181,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PB1_OV_TOT:1,PB1_OV_REM:0},
    {SUBSYSTEM:"21-8700-8760-01",SUBSYSTEM_DESCR:"Topside Normal 690 V Swicthboard 21-ESB-876001",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2021-11-04",A_OV_TOT:68,A_OV_COM:48,A_OV_COM_LW:0,A_OV_COM_TW:5,A_OV_COM_CLIENT:0,A_OV_REM:20,A_OV_PROG:70.58,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PB1_OV_TOT:9,PB1_OV_REM:5},
    {SUBSYSTEM:"21-8700-8770-01",SUBSYSTEM_DESCR:"Topsides Main Normal HV Switchboard 6.6kV 21-ESB-877001",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2021-11-04",A_OV_TOT:75,A_OV_COM:9,A_OV_COM_LW:0,A_OV_COM_TW:9,A_OV_COM_CLIENT:0,A_OV_REM:66,A_OV_PROG:12,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:8,PA_OV_REM:2,PB1_OV_TOT:10,PB1_OV_REM:8},
    {SUBSYSTEM:"21-8800-8805-01",SUBSYSTEM_DESCR:"Electrochlorination Unit",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-04-14",A_OV_TOT:73,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:73,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:6,PA_OV_REM:2,PB1_OV_TOT:9,PB1_OV_REM:5},
    {SUBSYSTEM:"21-9220-9224-01",SUBSYSTEM_DESCR:"Entertainment System",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2021-12-16",A_OV_TOT:290,A_OV_COM:5,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:285,A_OV_PROG:1.72,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PB1_OV_TOT:2,PB1_OV_REM:0},
    {SUBSYSTEM:"21-9330-9333-01",SUBSYSTEM_DESCR:"Cargo and Ballast Pumps HPU and UCP",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2021-12-09",A_OV_TOT:157,A_OV_COM:105,A_OV_COM_LW:10,A_OV_COM_TW:23,A_OV_COM_CLIENT:29,A_OV_REM:52,A_OV_PROG:66.87,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,PA_OV_TOT:30,PA_OV_REM:18,PB1_OV_TOT:44,PB1_OV_REM:32},
    {SUBSYSTEM:"21-9340-9341-01",SUBSYSTEM_DESCR:"Forward Mooring System and HPU",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-04-21",A_OV_TOT:19,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:19,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:1,PB1_OV_TOT:4,PB1_OV_REM:2},
    {SUBSYSTEM:"21-9340-9343-01",SUBSYSTEM_DESCR:"Aft Mooring System and HPU",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and No MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-04-21",A_OV_TOT:23,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:23,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PB1_OV_TOT:6,PB1_OV_REM:3},
    {SUBSYSTEM:"21-9500-9514-01",SUBSYSTEM_DESCR:"Telephone System - LQ",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2021-12-30",A_OV_TOT:27,A_OV_COM:4,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:23,A_OV_PROG:14.81,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:0,PB1_OV_TOT:5,PB1_OV_REM:1},
    {SUBSYSTEM:"21-9500-9515-01",SUBSYSTEM_DESCR:"Hot line Telephones",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2021-12-30",A_OV_TOT:6,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:6,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0},
    {SUBSYSTEM:"21-9500-9525-01",SUBSYSTEM_DESCR:"Microwave Line of Sight",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-01-13",A_OV_TOT:13,A_OV_COM:1,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:12,A_OV_PROG:7.69,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PB1_OV_TOT:1,PB1_OV_REM:0},
    {SUBSYSTEM:"21-9500-9536-01",SUBSYSTEM_DESCR:"LANandWAN ,Data Network Infrastructure",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2021-12-23",A_OV_TOT:165,A_OV_COM:34,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:131,A_OV_PROG:20.6,B_OV_TOT:14,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:14,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:0,PB1_OV_TOT:8,PB1_OV_REM:2},
    {SUBSYSTEM:"21-9500-9539-01",SUBSYSTEM_DESCR:"Video Conferencing and Collaboration System",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-01-06",A_OV_TOT:39,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:39,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0},
    {SUBSYSTEM:"21-9500-9544-01",SUBSYSTEM_DESCR:"Marine and Security CCTV - LQ",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2021-12-16",A_OV_TOT:67,A_OV_COM:23,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:44,A_OV_PROG:34.32,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:0,PB1_OV_TOT:7,PB1_OV_REM:0},
    {SUBSYSTEM:"21-9500-9554-01",SUBSYSTEM_DESCR:"Meterological METOCEAN System",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2021-12-16",A_OV_TOT:28,A_OV_COM:2,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:26,A_OV_PROG:7.14,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0},
    {SUBSYSTEM:"21-9500-9555-01",SUBSYSTEM_DESCR:"Berthing Aids and Approach System",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2021-12-16",A_OV_TOT:21,A_OV_COM:2,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:19,A_OV_PROG:9.52,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PB1_OV_TOT:2,PB1_OV_REM:2},
    {SUBSYSTEM:"21-9500-9564-01",SUBSYSTEM_DESCR:"Access Control System and Emergency Mustering System",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2021-12-16",A_OV_TOT:343,A_OV_COM:66,A_OV_COM_LW:28,A_OV_COM_TW:9,A_OV_COM_CLIENT:2,A_OV_REM:277,A_OV_PROG:19.24,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0,PA_OV_TOT:8,PA_OV_REM:4,PB1_OV_TOT:14,PB1_OV_REM:5},
    {SUBSYSTEM:"21-9500-9570-04",SUBSYSTEM_DESCR:"Video Wall System",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-01-13",A_OV_TOT:23,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:23,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0},
    {SUBSYSTEM:"21-9500-9574-01",SUBSYSTEM_DESCR:"Network Management System",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-01-13",A_OV_TOT:10,A_OV_COM:1,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:9,A_OV_PROG:10,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0},
    {SUBSYSTEM:"21-9500-9575-01",SUBSYSTEM_DESCR:"Digital Voice Recorder",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-01-13",A_OV_TOT:13,A_OV_COM:1,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:12,A_OV_PROG:7.69,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0},
    {SUBSYSTEM:"21-9810-9811-01",SUBSYSTEM_DESCR:"FPSO West Crane",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-04-21",A_OV_TOT:39,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:39,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:32,PA_OV_REM:32,PB1_OV_TOT:26,PB1_OV_REM:26},
    {SUBSYSTEM:"21-9810-9812-01",SUBSYSTEM_DESCR:"FPSO East Crane",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-04-21",A_OV_TOT:39,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:39,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:26,PA_OV_REM:26,PB1_OV_TOT:17,PB1_OV_REM:17},
    {SUBSYSTEM:"21-9820-9824-01",SUBSYSTEM_DESCR:"Riser and Umbilical Pull in Winch",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-04-28",A_OV_TOT:2,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:2,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:1},
    {SUBSYSTEM:"21-9921-9921-04",SUBSYSTEM_DESCR:"LQ Emergency SWB Room ACUsandACCUs and UCPs",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2021-09-30",A_OV_TOT:17,A_OV_COM:13,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:3,A_OV_REM:4,A_OV_PROG:76.47,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:4,PA_OV_REM:4,PB1_OV_TOT:12,PB1_OV_REM:5},
    {SUBSYSTEM:"21-9921-9921-05",SUBSYSTEM_DESCR:"LQ CCR ACUsandACCUs and UCPs",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-01-27",A_OV_TOT:17,A_OV_COM:10,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:7,A_OV_PROG:58.82,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:4,PA_OV_REM:4,PB1_OV_TOT:4,PB1_OV_REM:3},
    {SUBSYSTEM:"21-9921-9921-06",SUBSYSTEM_DESCR:"LQ CER ACUsandACCUs and UCPs",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2021-10-21",A_OV_TOT:18,A_OV_COM:10,A_OV_COM_LW:2,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:8,A_OV_PROG:55.55,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:4,PA_OV_REM:4,PB1_OV_TOT:15,PB1_OV_REM:8},
    {SUBSYSTEM:"21-9921-9921-07",SUBSYSTEM_DESCR:"LQ Secondary ICC ACUsandACCUs and UCPs",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2021-12-30",A_OV_TOT:26,A_OV_COM:14,A_OV_COM_LW:2,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:12,A_OV_PROG:53.84,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PB1_OV_TOT:5,PB1_OV_REM:0},
    {SUBSYSTEM:"21-9921-9921-08",SUBSYSTEM_DESCR:"LQ Dining Room and Secondary Muster ACUsandACCUs and UCPs",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2021-12-23",A_OV_TOT:20,A_OV_COM:11,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:9,A_OV_PROG:55,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:1,PB1_OV_TOT:12,PB1_OV_REM:2},
    {SUBSYSTEM:"21-9921-9921-09",SUBSYSTEM_DESCR:"LQ Telecom Room ACUsandACCUs and UCPs",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-01-13",A_OV_TOT:10,A_OV_COM:2,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:8,A_OV_PROG:20,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:3,PA_OV_REM:2},
    {SUBSYSTEM:"21-9921-9922-01",SUBSYSTEM_DESCR:"LQ HVAC UCPs, EDBs",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2021-12-16",A_OV_TOT:117,A_OV_COM:85,A_OV_COM_LW:15,A_OV_COM_TW:2,A_OV_COM_CLIENT:25,A_OV_REM:32,A_OV_PROG:72.64,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:5,PA_OV_REM:1,PB1_OV_TOT:28,PB1_OV_REM:9},
    {SUBSYSTEM:"21-9921-9922-02",SUBSYSTEM_DESCR:"LQ HVAC AHUs, Ducting and Dampers",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2021-12-16",A_OV_TOT:635,A_OV_COM:340,A_OV_COM_LW:28,A_OV_COM_TW:14,A_OV_COM_CLIENT:115,A_OV_REM:295,A_OV_PROG:53.54,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:39,PA_OV_REM:32,PB1_OV_TOT:99,PB1_OV_REM:48},
    {SUBSYSTEM:"21-9921-9922-06",SUBSYSTEM_DESCR:"LQ Galley Hood Exhaust Fans, Ducting and Dampers",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-01-06",A_OV_TOT:92,A_OV_COM:64,A_OV_COM_LW:1,A_OV_COM_TW:15,A_OV_COM_CLIENT:19,A_OV_REM:28,A_OV_PROG:69.56,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:8,PA_OV_REM:5,PB1_OV_TOT:14,PB1_OV_REM:4},
    {SUBSYSTEM:"21-9921-9922-12",SUBSYSTEM_DESCR:"LQ Provision Plant Exhaust Fans, Ducting and Dampers",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2021-12-16",A_OV_TOT:18,A_OV_COM:9,A_OV_COM_LW:0,A_OV_COM_TW:1,A_OV_COM_CLIENT:2,A_OV_REM:9,A_OV_PROG:50,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:1,PB1_OV_TOT:2,PB1_OV_REM:0},
    {SUBSYSTEM:"21-9921-9927-01",SUBSYSTEM_DESCR:"Cool Store System in Galley",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2021-12-16",A_OV_TOT:77,A_OV_COM:3,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:74,A_OV_PROG:3.89,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:8,PA_OV_REM:8,PB1_OV_TOT:4,PB1_OV_REM:0},
    {SUBSYSTEM:"21-9950-9950-02",SUBSYSTEM_DESCR:"Tertiary Refuge ACUsandACCUs and UCPs",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-03-31",A_OV_TOT:48,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:48,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0},
    {SUBSYSTEM:"21-9970-9971-01",SUBSYSTEM_DESCR:"HULL HVAC UCPs, EDBs",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2021-10-21",A_OV_TOT:80,A_OV_COM:67,A_OV_COM_LW:17,A_OV_COM_TW:9,A_OV_COM_CLIENT:16,A_OV_REM:13,A_OV_PROG:83.75,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:6,PA_OV_REM:6,PB1_OV_TOT:16,PB1_OV_REM:11},
    {SUBSYSTEM:"21-9970-9971-06",SUBSYSTEM_DESCR:"HULL Chilled Water System and UCP",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and Cybersecurity Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2021-12-23",A_OV_TOT:107,A_OV_COM:28,A_OV_COM_LW:5,A_OV_COM_TW:3,A_OV_COM_CLIENT:0,A_OV_REM:79,A_OV_PROG:26.16,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:19,PA_OV_REM:14,PB1_OV_TOT:6,PB1_OV_REM:2},
    {SUBSYSTEM:"21-9976-9976-03",SUBSYSTEM_DESCR:"Emergency Room ACUsandACCUs and UCPs",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2021-12-09",A_OV_TOT:19,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:19,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:5,PA_OV_REM:1,PB1_OV_TOT:2,PB1_OV_REM:1},
    {SUBSYSTEM:"21-9976-9977-01",SUBSYSTEM_DESCR:"Topsides HVAC UCPs, EDBs",SUBSYSTEM_STATUS:"Subsystems with Cybersecurity and No MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2021-12-09",A_OV_TOT:26,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:26,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PB1_OV_TOT:5,PB1_OV_REM:5}
    
    // {NO:260,SUBSYSTEM:"21-1001-1001-01",SUBSYSTEM_DESCR:"Annulus Riser vent Monitoring System",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-28",A_OV_TOT:3,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:3,A_OV_PROG:100,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0},
    // {NO:261,SUBSYSTEM:"21-1010-1011-01",SUBSYSTEM_DESCR:"Flowline 1 & Receiver",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"A",MC_PLAN:"2022-04-28",A_OV_TOT:193,A_OV_COM:8,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:3,A_OV_REM:185,A_OV_PROG:100,B_OV_TOT:34,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:34,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:2},
    // {NO:262,SUBSYSTEM:"21-1010-1012-01",SUBSYSTEM_DESCR:"Flowline 2 & Receiver",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"N",MC_PLAN:"2022-04-28",A_OV_TOT:192,A_OV_COM:8,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:4,A_OV_REM:184,A_OV_PROG:100,B_OV_TOT:34,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:34,B_OV_PROG:0},
    // {NO:263,SUBSYSTEM:"21-1510-1510-01",SUBSYSTEM_DESCR:"MEG Riser & Pipeline System to DC1",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-28",A_OV_TOT:9,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:9,A_OV_PROG:100,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0},
    // {NO:264,SUBSYSTEM:"21-1600-1611-01",SUBSYSTEM_DESCR:"Gas Export Riser 1",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"N",MC_PLAN:"2022-04-28",A_OV_TOT:39,A_OV_COM:3,A_OV_COM_LW:2,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:36,A_OV_PROG:100,B_OV_TOT:9,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:9,B_OV_PROG:0},
    // {NO:265,SUBSYSTEM:"21-1600-1612-01",SUBSYSTEM_DESCR:"Gas Export Riser 2",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"N",MC_PLAN:"2022-04-28",A_OV_TOT:39,A_OV_COM:3,A_OV_COM_LW:2,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:36,A_OV_PROG:100,B_OV_TOT:9,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:9,B_OV_PROG:0},
    // {NO:266,SUBSYSTEM:"21-1911-1911-01",SUBSYSTEM_DESCR:"Hydraulic HPUs, RIOs & TUTUs",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"N",MC_PLAN:"2022-04-28",A_OV_TOT:38,A_OV_COM:1,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:37,A_OV_PROG:2.63,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0},
    // {NO:233,SUBSYSTEM:"21-1931-1931-01",SUBSYSTEM_DESCR:"Subsea Control System (MCS), SSIV SPCU, EPUs, HVE JBs & LVE JBs",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-17",A_OV_TOT:50,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:50,A_OV_PROG:100,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0,PB1_OV_TOT:11,PB1_OV_REM:11},
    // {NO:269,SUBSYSTEM:"21-2200-2131-01",SUBSYSTEM_DESCR:"Slug Catcher 1 & Liquid Heater 1",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"B1",MC_PLAN:"2022-05-05",A_OV_TOT:258,A_OV_COM:24,A_OV_COM_LW:2,A_OV_COM_TW:1,A_OV_COM_CLIENT:2,A_OV_REM:234,A_OV_PROG:9.3,B_OV_TOT:45,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:45,B_OV_PROG:0,PB1_OV_TOT:1,PB1_OV_REM:1},
    // {NO:270,SUBSYSTEM:"21-2200-2132-01",SUBSYSTEM_DESCR:"Slug Catcher 2 & Liquid heater 2",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"B1",MC_PLAN:"2022-05-05",A_OV_TOT:261,A_OV_COM:22,A_OV_COM_LW:2,A_OV_COM_TW:2,A_OV_COM_CLIENT:2,A_OV_REM:239,A_OV_PROG:8.42,B_OV_TOT:44,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:44,B_OV_PROG:0,PB1_OV_TOT:1,PB1_OV_REM:1},
    // {NO:328,SUBSYSTEM:"21-2200-2221-01",SUBSYSTEM_DESCR:"MP Separator",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"A",MC_PLAN:"2022-07-14",A_OV_TOT:152,A_OV_COM:26,A_OV_COM_LW:7,A_OV_COM_TW:2,A_OV_COM_CLIENT:3,A_OV_REM:126,A_OV_PROG:17.1,B_OV_TOT:32,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:32,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:1},
    // {NO:302,SUBSYSTEM:"21-2200-2230-01",SUBSYSTEM_DESCR:"LP Separator",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"A",MC_PLAN:"2022-06-02",A_OV_TOT:118,A_OV_COM:20,A_OV_COM_LW:6,A_OV_COM_TW:0,A_OV_COM_CLIENT:4,A_OV_REM:98,A_OV_PROG:16.94,B_OV_TOT:26,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:26,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:1},
    // {NO:320,SUBSYSTEM:"21-2200-2230-02",SUBSYSTEM_DESCR:"Interstage Heaters",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",MC_PLAN:"2022-06-16",A_OV_TOT:20,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:20,A_OV_PROG:0,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0},
    // {NO:276,SUBSYSTEM:"21-2200-2240-01",SUBSYSTEM_DESCR:"LLP separator, Condensate Coolers & Condensate Coalescer Pumps",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"A",MC_PLAN:"2022-05-12",A_OV_TOT:210,A_OV_COM:17,A_OV_COM_LW:2,A_OV_COM_TW:5,A_OV_COM_CLIENT:0,A_OV_REM:193,A_OV_PROG:8.09,B_OV_TOT:43,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:43,B_OV_PROG:0,PA_OV_TOT:3,PA_OV_REM:2},
    // {NO:277,SUBSYSTEM:"21-2200-2240-03",SUBSYSTEM_DESCR:"Condensate Coalescer Filters",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-12",A_OV_TOT:18,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:18,A_OV_PROG:0,B_OV_TOT:12,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:12,B_OV_PROG:0},
    // {NO:278,SUBSYSTEM:"21-2200-2240-04",SUBSYSTEM_DESCR:"Condensate Coalescer Package",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"N",MC_PLAN:"2022-05-12",A_OV_TOT:74,A_OV_COM:2,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:72,A_OV_PROG:2.7,B_OV_TOT:17,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:17,B_OV_PROG:0,PB1_OV_TOT:3,PB1_OV_REM:0},
    // {NO:329,SUBSYSTEM:"21-2200-2470-01",SUBSYSTEM_DESCR:"Warm Condensate Heater, Offspec Condensate Pump & Distribution",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"A",MC_PLAN:"2022-07-14",A_OV_TOT:98,A_OV_COM:5,A_OV_COM_LW:2,A_OV_COM_TW:1,A_OV_COM_CLIENT:1,A_OV_REM:93,A_OV_PROG:5.1,B_OV_TOT:43,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:43,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:1,PB1_OV_TOT:1,PB1_OV_REM:1},
    // {NO:212,SUBSYSTEM:"21-2400-2450-01",SUBSYSTEM_DESCR:"CFW1 Offspec Condensate Tank & Pumps (Incl Washing Machine)",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"A",MC_PLAN:"2022-02-24",A_OV_TOT:115,A_OV_COM:6,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:109,A_OV_PROG:5.21,B_OV_TOT:23,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:23,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:1},
    // {NO:179,SUBSYSTEM:"21-2400-9360-01",SUBSYSTEM_DESCR:"Cargo Oil Washing Header & Distribution",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",MC_PLAN:"2022-01-27",A_OV_TOT:41,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:41,A_OV_PROG:0,B_OV_TOT:8,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:8,B_OV_PROG:0},
    // {NO:180,SUBSYSTEM:"21-2400-9360-02",SUBSYSTEM_DESCR:"Condensate Import/Loading Header",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",MC_PLAN:"2022-01-27",A_OV_TOT:53,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:53,A_OV_PROG:0,B_OV_TOT:11,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:11,B_OV_PROG:0},
    // {NO:181,SUBSYSTEM:"21-2400-9360-03",SUBSYSTEM_DESCR:"Condensate Transfer Header",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",MC_PLAN:"2022-01-27",A_OV_TOT:238,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:238,A_OV_PROG:0,B_OV_TOT:54,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:54,B_OV_PROG:0},
    // {NO:182,SUBSYSTEM:"21-2400-9360-04",SUBSYSTEM_DESCR:"Condensate Export/Offloading Header",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",MC_PLAN:"2022-01-27",A_OV_TOT:111,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:111,A_OV_PROG:0,B_OV_TOT:22,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:22,B_OV_PROG:0},
    // {NO:62,SUBSYSTEM:"21-2400-9361-01",SUBSYSTEM_DESCR:"COW1 Tank & Pumps (Incl Washing Machine)",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"N",MC_PLAN:"2021-11-25",A_OV_TOT:52,A_OV_COM:5,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:47,A_OV_PROG:9.61,B_OV_TOT:15,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:15,B_OV_PROG:0},
    // {NO:63,SUBSYSTEM:"21-2400-9362-01",SUBSYSTEM_DESCR:"COW2 Tank & Pumps (Incl Washing Machine)",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"N",MC_PLAN:"2021-11-25",A_OV_TOT:52,A_OV_COM:5,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:47,A_OV_PROG:9.61,B_OV_TOT:15,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:15,B_OV_PROG:0},
    // {NO:64,SUBSYSTEM:"21-2400-9363-01",SUBSYSTEM_DESCR:"COW3 Tank & Pumps (Incl Washing Machine)",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"N",MC_PLAN:"2021-11-25",A_OV_TOT:51,A_OV_COM:5,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:46,A_OV_PROG:9.8,B_OV_TOT:15,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:15,B_OV_PROG:0},
    // {NO:65,SUBSYSTEM:"21-2400-9364-01",SUBSYSTEM_DESCR:"COW4 Tank & Pumps (Incl Washing Machine)",SUBSYSTEM_STATUS:"MC Completed",PUNCH_STATUS:"N",MC_PLAN:"2021-11-25",A_OV_TOT:51,A_OV_COM:5,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:46,A_OV_PROG:100,B_OV_TOT:15,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:0,B_OV_PROG:100},
    // {NO:183,SUBSYSTEM:"21-2400-9365-01",SUBSYSTEM_DESCR:"COE1 Tank & Pumps (Incl Washing Machine)",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"N",MC_PLAN:"2022-01-27",A_OV_TOT:51,A_OV_COM:5,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:46,A_OV_PROG:9.8,B_OV_TOT:15,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:15,B_OV_PROG:0},
    // {NO:184,SUBSYSTEM:"21-2400-9366-01",SUBSYSTEM_DESCR:"COE2 Tank & Pumps (Incl Washing Machine)",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"N",MC_PLAN:"2022-01-27",A_OV_TOT:50,A_OV_COM:5,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:45,A_OV_PROG:10,B_OV_TOT:15,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:15,B_OV_PROG:0},
    // {NO:185,SUBSYSTEM:"21-2400-9367-01",SUBSYSTEM_DESCR:"COE3 Tank & Pumps (Incl Washing Machine)",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"N",MC_PLAN:"2022-01-27",A_OV_TOT:51,A_OV_COM:5,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:46,A_OV_PROG:9.8,B_OV_TOT:15,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:15,B_OV_PROG:0},
    // {NO:186,SUBSYSTEM:"21-2400-9368-01",SUBSYSTEM_DESCR:"COE4 Tank & Pumps (Incl Washing Machine)",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"N",MC_PLAN:"2022-01-27",A_OV_TOT:52,A_OV_COM:5,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:47,A_OV_PROG:9.61,B_OV_TOT:15,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:15,B_OV_PROG:0}
    ],
  Queries: {
    SQL1: [
      { DESC: 'DataSource SPCS Data 03-Mar-20' }
    ],
    SQL2: [
      { CDATE: '2021-10-21' }
    ],
    SQL3: [
      {MC_PLAN:"2021-09-23",A_T:212,A_A:204,A_O:8,B_T:2,B_A:0,B_O:2},
      {MC_PLAN:"2021-09-30",A_T:193,A_A:177,A_O:16,B_T:5,B_A:0,B_O:5},
      {MC_PLAN:"2021-10-07"},
      {MC_PLAN:"2021-10-14",A_T:248,A_A:157,A_O:91,B_T:1,B_A:0,B_O:1},
      {MC_PLAN:"2021-10-21",A_T:326,A_A:281,A_O:45,B_T:7,B_A:0,B_O:7},
      {MC_PLAN:"2021-10-28",A_T:158,A_A:117,A_O:41,B_T:6,B_A:0,B_O:6},
      {MC_PLAN:"2021-11-04",A_T:507,A_A:326,A_O:181,B_T:18,B_A:1,B_O:17},
      {MC_PLAN:"2021-11-11",A_T:126,A_A:8,A_O:118,B_T:4,B_A:0,B_O:4},
      {MC_PLAN:"2021-11-18",A_T:347,A_A:144,A_O:203,B_T:8,B_A:0,B_O:8},
      {MC_PLAN:"2021-11-25"},
      {MC_PLAN:"2021-12-02",A_T:38,A_A:4,A_O:34,B_T:2,B_A:0,B_O:2},
      {MC_PLAN:"2021-12-09",A_T:224,A_A:105,A_O:119,B_T:5,B_A:0,B_O:5},
      {MC_PLAN:"2021-12-16",A_T:1618,A_A:540,A_O:1078,B_T:16,B_A:0,B_O:16},
      {MC_PLAN:"2021-12-23",A_T:300,A_A:73,A_O:227,B_T:17,B_A:0,B_O:17},
      {MC_PLAN:"2021-12-30",A_T:537,A_A:86,A_O:451,B_T:9,B_A:0,B_O:9},
      {MC_PLAN:"2022-01-06",A_T:131,A_A:64,A_O:67,B_T:2,B_A:0,B_O:2},
      {MC_PLAN:"2022-01-13",A_T:212,A_A:11,A_O:201,B_T:7,B_A:0,B_O:7},
      {MC_PLAN:"2022-01-20"},
      {MC_PLAN:"2022-01-27",A_T:30,A_A:17,A_O:13,B_T:2,B_A:0,B_O:2},
      {MC_PLAN:"2022-02-03"},
      {MC_PLAN:"2022-02-10",A_T:2,A_A:0,A_O:2,B_T:1,B_A:0,B_O:1},
      {MC_PLAN:"2022-02-17",A_T:403,A_A:0,A_O:403,B_T:3,B_A:0,B_O:3},
      {MC_PLAN:"2022-02-24",A_T:3,A_A:0,A_O:3,B_T:1,B_A:0,B_O:1},
      {MC_PLAN:"2022-03-03"},
      {MC_PLAN:"2022-03-10"},
      {MC_PLAN:"2022-03-17"},
      {MC_PLAN:"2022-03-24"},
      {MC_PLAN:"2022-03-31",A_T:133,A_A:0,A_O:133,B_T:5,B_A:0,B_O:5},
      {MC_PLAN:"2022-04-07",A_T:139,A_A:0,A_O:139,B_T:2,B_A:0,B_O:2},
      {MC_PLAN:"2022-04-14",A_T:73,A_A:0,A_O:73,B_T:1,B_A:0,B_O:1}
    ],
    SQL4: [
      {CUTOFF:"2018-06-21",WK_NO:"W2018_25"},
      {CUTOFF:"2018-06-28",WK_NO:"W2018_26"},
      {CUTOFF:"2018-07-05",WK_NO:"W2018_27"},
      {CUTOFF:"2018-07-12",WK_NO:"W2018_28"},
      {CUTOFF:"2018-07-19",WK_NO:"W2018_29"},
      {CUTOFF:"2018-07-26",WK_NO:"W2018_30"},
      {CUTOFF:"2018-08-02",WK_NO:"W2018_31"},
      {CUTOFF:"2018-08-09",WK_NO:"W2018_32"},
      {CUTOFF:"2018-08-16",WK_NO:"W2018_33"},
      {CUTOFF:"2018-08-23",WK_NO:"W2018_34"},
      {CUTOFF:"2018-08-30",WK_NO:"W2018_35"},
      {CUTOFF:"2018-09-06",WK_NO:"W2018_36"},
      {CUTOFF:"2018-09-13",WK_NO:"W2018_37"},
      {CUTOFF:"2018-09-20",WK_NO:"W2018_38"},
      {CUTOFF:"2018-09-27",WK_NO:"W2018_39"},
      {CUTOFF:"2018-10-04",WK_NO:"W2018_40"},
      {CUTOFF:"2018-10-11",WK_NO:"W2018_41"},
      {CUTOFF:"2018-10-18",WK_NO:"W2018_42"},
      {CUTOFF:"2018-10-25",WK_NO:"W2018_43"},
      {CUTOFF:"2018-11-01",WK_NO:"W2018_44"},
      {CUTOFF:"2018-11-08",WK_NO:"W2018_45"},
      {CUTOFF:"2018-11-15",WK_NO:"W2018_46"},
      {CUTOFF:"2018-11-22",WK_NO:"W2018_47"},
      {CUTOFF:"2018-11-29",WK_NO:"W2018_48"},
      {CUTOFF:"2018-12-06",WK_NO:"W2018_49"},
      {CUTOFF:"2018-12-13",WK_NO:"W2018_50"},
      {CUTOFF:"2018-12-20",WK_NO:"W2018_51"},
      {CUTOFF:"2018-12-27",WK_NO:"W2018_52"},
      {CUTOFF:"2019-01-03",WK_NO:"W2019_01"},
      {CUTOFF:"2019-01-10",WK_NO:"W2019_02"},
      {CUTOFF:"2019-01-17",WK_NO:"W2019_03"},
      {CUTOFF:"2019-01-24",WK_NO:"W2019_04"},
      {CUTOFF:"2019-01-31",WK_NO:"W2019_05"},
      {CUTOFF:"2019-02-07",WK_NO:"W2019_06"},
      {CUTOFF:"2019-02-14",WK_NO:"W2019_07"},
      {CUTOFF:"2019-02-21",WK_NO:"W2019_08"},
      {CUTOFF:"2019-02-28",WK_NO:"W2019_09"},
      {CUTOFF:"2019-03-07",WK_NO:"W2019_10"},
      {CUTOFF:"2019-03-14",WK_NO:"W2019_11"},
      {CUTOFF:"2019-03-21",WK_NO:"W2019_12"},
      {CUTOFF:"2019-03-28",WK_NO:"W2019_13"},
      {CUTOFF:"2019-04-04",WK_NO:"W2019_14"},
      {CUTOFF:"2019-04-11",WK_NO:"W2019_15"},
      {CUTOFF:"2019-04-18",WK_NO:"W2019_16"},
      {CUTOFF:"2019-04-25",WK_NO:"W2019_17"},
      {CUTOFF:"2019-05-02",WK_NO:"W2019_18"},
      {CUTOFF:"2019-05-09",WK_NO:"W2019_19"},
      {CUTOFF:"2019-05-16",WK_NO:"W2019_20"},
      {CUTOFF:"2019-05-23",WK_NO:"W2019_21"},
      {CUTOFF:"2019-05-30",WK_NO:"W2019_22"},
      {CUTOFF:"2019-06-06",WK_NO:"W2019_23"},
      {CUTOFF:"2019-06-13",WK_NO:"W2019_24"},
      {CUTOFF:"2019-06-20",WK_NO:"W2019_25"},
      {CUTOFF:"2019-06-27",WK_NO:"W2019_26"},
      {CUTOFF:"2019-07-04",WK_NO:"W2019_27"},
      {CUTOFF:"2019-07-11",WK_NO:"W2019_28"},
      {CUTOFF:"2019-07-18",WK_NO:"W2019_29"},
      {CUTOFF:"2019-07-25",WK_NO:"W2019_30"},
      {CUTOFF:"2019-08-01",WK_NO:"W2019_31"},
      {CUTOFF:"2019-08-08",WK_NO:"W2019_32"},
      {CUTOFF:"2019-08-15",WK_NO:"W2019_33"},
      {CUTOFF:"2019-08-22",WK_NO:"W2019_34"},
      {CUTOFF:"2019-08-29",WK_NO:"W2019_35"},
      {CUTOFF:"2019-09-05",WK_NO:"W2019_36"},
      {CUTOFF:"2019-09-12",WK_NO:"W2019_37"},
      {CUTOFF:"2019-09-19",WK_NO:"W2019_38"},
      {CUTOFF:"2019-09-26",WK_NO:"W2019_39"},
      {CUTOFF:"2019-10-03",WK_NO:"W2019_40"},
      {CUTOFF:"2019-10-10",WK_NO:"W2019_41"},
      {CUTOFF:"2019-10-17",WK_NO:"W2019_42"},
      {CUTOFF:"2019-10-24",WK_NO:"W2019_43"},
      {CUTOFF:"2019-10-31",WK_NO:"W2019_44"},
      {CUTOFF:"2019-11-07",WK_NO:"W2019_45"},
      {CUTOFF:"2019-11-14",WK_NO:"W2019_46"},
      {CUTOFF:"2019-11-21",WK_NO:"W2019_47"},
      {CUTOFF:"2019-11-28",WK_NO:"W2019_48"},
      {CUTOFF:"2019-12-05",WK_NO:"W2019_49"},
      {CUTOFF:"2019-12-12",WK_NO:"W2019_50"},
      {CUTOFF:"2019-12-19",WK_NO:"W2019_51"},
      {CUTOFF:"2019-12-26",WK_NO:"W2019_52"},
      {CUTOFF:"2020-01-02",WK_NO:"W2020_01"},
      {CUTOFF:"2020-01-09",WK_NO:"W2020_02"},
      {CUTOFF:"2020-01-16",WK_NO:"W2020_03"},
      {CUTOFF:"2020-01-23",WK_NO:"W2020_04"},
      {CUTOFF:"2020-01-30",WK_NO:"W2020_05"},
      {CUTOFF:"2020-02-06",WK_NO:"W2020_06"},
      {CUTOFF:"2020-02-13",WK_NO:"W2020_07"},
      {CUTOFF:"2020-02-20",WK_NO:"W2020_08"},
      {CUTOFF:"2020-02-27",WK_NO:"W2020_09"},
      {CUTOFF:"2020-03-05",WK_NO:"W2020_10"},
      {CUTOFF:"2020-03-12",WK_NO:"W2020_11"},
      {CUTOFF:"2020-03-19",WK_NO:"W2020_12"},
      {CUTOFF:"2020-03-26",WK_NO:"W2020_13"},
      {CUTOFF:"2020-04-02",WK_NO:"W2020_14"},
      {CUTOFF:"2020-04-09",WK_NO:"W2020_15"},
      {CUTOFF:"2020-04-16",WK_NO:"W2020_16"},
      {CUTOFF:"2020-04-23",WK_NO:"W2020_17"},
      {CUTOFF:"2020-04-30",WK_NO:"W2020_18"},
      {CUTOFF:"2020-05-07",WK_NO:"W2020_19"},
      {CUTOFF:"2020-05-14",WK_NO:"W2020_20"},
      {CUTOFF:"2020-05-21",WK_NO:"W2020_21"},
      {CUTOFF:"2020-05-28",WK_NO:"W2020_22"},
      {CUTOFF:"2020-06-04",WK_NO:"W2020_23"},
      {CUTOFF:"2020-06-11",WK_NO:"W2020_24"},
      {CUTOFF:"2020-06-18",WK_NO:"W2020_25"},
      {CUTOFF:"2020-06-25",WK_NO:"W2020_26"},
      {CUTOFF:"2020-07-02",WK_NO:"W2020_27"},
      {CUTOFF:"2020-07-09",WK_NO:"W2020_28"},
      {CUTOFF:"2020-07-16",WK_NO:"W2020_29"},
      {CUTOFF:"2020-07-23",WK_NO:"W2020_30"},
      {CUTOFF:"2020-07-30",WK_NO:"W2020_31"},
      {CUTOFF:"2020-08-06",WK_NO:"W2020_32"},
      {CUTOFF:"2020-08-13",WK_NO:"W2020_33"},
      {CUTOFF:"2020-08-20",WK_NO:"W2020_34"},
      {CUTOFF:"2020-08-27",WK_NO:"W2020_35"},
      {CUTOFF:"2020-09-03",WK_NO:"W2020_36"},
      {CUTOFF:"2020-09-10",WK_NO:"W2020_37"},
      {CUTOFF:"2020-09-17",WK_NO:"W2020_38"},
      {CUTOFF:"2020-09-24",WK_NO:"W2020_39"},
      {CUTOFF:"2020-10-01",WK_NO:"W2020_40"},
      {CUTOFF:"2020-10-08",WK_NO:"W2020_41"},
      {CUTOFF:"2020-10-15",WK_NO:"W2020_42"},
      {CUTOFF:"2020-10-22",WK_NO:"W2020_43"},
      {CUTOFF:"2020-10-29",WK_NO:"W2020_44"},
      {CUTOFF:"2020-11-05",WK_NO:"W2020_45"},
      {CUTOFF:"2020-11-12",WK_NO:"W2020_46"},
      {CUTOFF:"2020-11-19",WK_NO:"W2020_47"},
      {CUTOFF:"2020-11-26",WK_NO:"W2020_48"},
      {CUTOFF:"2020-12-03",WK_NO:"W2020_49"},
      {CUTOFF:"2020-12-10",WK_NO:"W2020_50"},
      {CUTOFF:"2020-12-17",WK_NO:"W2020_51"},
      {CUTOFF:"2020-12-24",WK_NO:"W2020_52"},
      {CUTOFF:"2020-12-31",WK_NO:"W2020_53"},
      {CUTOFF:"2021-01-07",WK_NO:"W2021_01"},
      {CUTOFF:"2021-01-14",WK_NO:"W2021_02"},
      {CUTOFF:"2021-01-21",WK_NO:"W2021_03"},
      {CUTOFF:"2021-01-28",WK_NO:"W2021_04"},
      {CUTOFF:"2021-02-04",WK_NO:"W2021_05"},
      {CUTOFF:"2021-02-11",WK_NO:"W2021_06"},
      {CUTOFF:"2021-02-18",WK_NO:"W2021_07"},
      {CUTOFF:"2021-02-25",WK_NO:"W2021_08"},
      {CUTOFF:"2021-03-04",WK_NO:"W2021_09"},
      {CUTOFF:"2021-03-11",WK_NO:"W2021_10"},
      {CUTOFF:"2021-03-18",WK_NO:"W2021_11"},
      {CUTOFF:"2021-03-25",WK_NO:"W2021_12"},
      {CUTOFF:"2021-04-01",WK_NO:"W2021_13"},
      {CUTOFF:"2021-04-08",WK_NO:"W2021_14"},
      {CUTOFF:"2021-04-15",WK_NO:"W2021_15"},
      {CUTOFF:"2021-04-22",WK_NO:"W2021_16"},
      {CUTOFF:"2021-04-29",WK_NO:"W2021_17"},
      {CUTOFF:"2021-05-06",WK_NO:"W2021_18"},
      {CUTOFF:"2021-05-13",WK_NO:"W2021_19"},
      {CUTOFF:"2021-05-20",WK_NO:"W2021_20"},
      {CUTOFF:"2021-05-27",WK_NO:"W2021_21"},
      {CUTOFF:"2021-06-03",WK_NO:"W2021_22"},
      {CUTOFF:"2021-06-10",WK_NO:"W2021_23"},
      {CUTOFF:"2021-06-17",WK_NO:"W2021_24"},
      {CUTOFF:"2021-06-24",WK_NO:"W2021_25"},
      {CUTOFF:"2021-07-01",WK_NO:"W2021_26"},
      {CUTOFF:"2021-07-08",WK_NO:"W2021_27"},
      {CUTOFF:"2021-07-15",WK_NO:"W2021_28"},
      {CUTOFF:"2021-07-22",WK_NO:"W2021_29"},
      {CUTOFF:"2021-07-29",WK_NO:"W2021_30"},
      {CUTOFF:"2021-08-05",WK_NO:"W2021_31"},
      {CUTOFF:"2021-08-12",WK_NO:"W2021_32"},
      {CUTOFF:"2021-08-19",WK_NO:"W2021_33"},
      {CUTOFF:"2021-08-26",WK_NO:"W2021_34"},
      {CUTOFF:"2021-09-02",WK_NO:"W2021_35"},
      {CUTOFF:"2021-09-09",WK_NO:"W2021_36"},
      {CUTOFF:"2021-09-16",WK_NO:"W2021_37"},
      {CUTOFF:"2021-09-23",WK_NO:"W2021_38"},
      {CUTOFF:"2021-09-30",WK_NO:"W2021_39"},
      {CUTOFF:"2021-10-07",WK_NO:"W2021_40"},
      {CUTOFF:"2021-10-14",WK_NO:"W2021_41"},
      {CUTOFF:"2021-10-21",WK_NO:"W2021_42"},
      {CUTOFF:"2021-10-28",WK_NO:"W2021_43"},
      {CUTOFF:"2021-11-04",WK_NO:"W2021_44"},
      {CUTOFF:"2021-11-11",WK_NO:"W2021_45"},
      {CUTOFF:"2021-11-18",WK_NO:"W2021_46"},
      {CUTOFF:"2021-11-25",WK_NO:"W2021_47"},
      {CUTOFF:"2021-12-02",WK_NO:"W2021_48"},
      {CUTOFF:"2021-12-09",WK_NO:"W2021_49"},
      {CUTOFF:"2021-12-16",WK_NO:"W2021_50"},
      {CUTOFF:"2021-12-23",WK_NO:"W2021_51"},
      {CUTOFF:"2021-12-30",WK_NO:"W2021_52"},
      {CUTOFF:"2022-01-06",WK_NO:"W2022_01"},
      {CUTOFF:"2022-01-13",WK_NO:"W2022_02"},
      {CUTOFF:"2022-01-20",WK_NO:"W2022_03"},
      {CUTOFF:"2022-01-27",WK_NO:"W2022_04"},
      {CUTOFF:"2022-02-03",WK_NO:"W2022_05"},
      {CUTOFF:"2022-02-10",WK_NO:"W2022_06"},
      {CUTOFF:"2022-02-17",WK_NO:"W2022_07"},
      {CUTOFF:"2022-02-24",WK_NO:"W2022_08"},
      {CUTOFF:"2022-03-03",WK_NO:"W2022_09"},
      {CUTOFF:"2022-03-10",WK_NO:"W2022_10"},
      {CUTOFF:"2022-03-17",WK_NO:"W2022_11"},
      {CUTOFF:"2022-03-24",WK_NO:"W2022_12"},
      {CUTOFF:"2022-03-31",WK_NO:"W2022_13"},
      {CUTOFF:"2022-04-07",WK_NO:"W2022_14"},
      {CUTOFF:"2022-04-14",WK_NO:"W2022_15"},
      {CUTOFF:"2022-04-21",WK_NO:"W2022_16"},
      {CUTOFF:"2022-04-28",WK_NO:"W2022_17"},
      {CUTOFF:"2022-05-05",WK_NO:"W2022_18"},
      {CUTOFF:"2022-05-12",WK_NO:"W2022_19"},
      {CUTOFF:"2022-05-19",WK_NO:"W2022_20"},
      {CUTOFF:"2022-05-26",WK_NO:"W2022_21"},
      {CUTOFF:"2022-06-02",WK_NO:"W2022_22"},
      {CUTOFF:"2022-06-09",WK_NO:"W2022_23"},
      {CUTOFF:"2022-06-16",WK_NO:"W2022_24"},
      {CUTOFF:"2022-06-23",WK_NO:"W2022_25"},
      {CUTOFF:"2022-06-30",WK_NO:"W2022_26"},
      {CUTOFF:"2022-07-07",WK_NO:"W2022_27"},
      {CUTOFF:"2022-07-14",WK_NO:"W2022_28"},
      {CUTOFF:"2022-07-21",WK_NO:"W2022_29"},
      {CUTOFF:"2022-07-28",WK_NO:"W2022_30"},
      {CUTOFF:"2022-08-04",WK_NO:"W2022_31"},
      {CUTOFF:"2022-08-11",WK_NO:"W2022_32"},
      {CUTOFF:"2022-08-18",WK_NO:"W2022_33"},
      {CUTOFF:"2022-08-25",WK_NO:"W2022_34"},
      {CUTOFF:"2022-09-01",WK_NO:"W2022_35"},
      {CUTOFF:"2022-09-08",WK_NO:"W2022_36"},
      {CUTOFF:"2022-09-15",WK_NO:"W2022_37"},
      {CUTOFF:"2022-09-22",WK_NO:"W2022_38"},
      {CUTOFF:"2022-09-29",WK_NO:"W2022_39"},
      {CUTOFF:"2022-10-06",WK_NO:"W2022_40"},
      {CUTOFF:"2022-10-13",WK_NO:"W2022_41"},
      {CUTOFF:"2022-10-20",WK_NO:"W2022_42"},
      {CUTOFF:"2022-10-27",WK_NO:"W2022_43"},
      {CUTOFF:"2022-11-03",WK_NO:"W2022_44"},
      {CUTOFF:"2022-11-10",WK_NO:"W2022_45"},
      {CUTOFF:"2022-11-17",WK_NO:"W2022_46"},
      {CUTOFF:"2022-11-24",WK_NO:"W2022_47"},
      {CUTOFF:"2022-12-01",WK_NO:"W2022_48"},
      {CUTOFF:"2022-12-08",WK_NO:"W2022_49"},
      {CUTOFF:"2022-12-15",WK_NO:"W2022_50"},
      {CUTOFF:"2022-12-22",WK_NO:"W2022_51"},
      {CUTOFF:"2022-12-29",WK_NO:"W2022_52"}
    ],
    SQL5: [
      // {TOTAL:334,NOTSTARTED:141,APROGRESS:191,ACOMPLETED:2,BPROGRESS:0,LOOPTEST:0, MCCOMPLETED: 1},
      {TOTAL:89,NOTSTARTED:34,APROGRESS:41,ACOMPLETED:8,PAR_CYBERSECURITY:6,CYBERSECURITY:0}
    ],
    SQL6: [ // week date
      {TYPE:"WEEK",DATE:"2022-03-24",DISC:"LWK",ACTUAL:81},
      {TYPE:"WEEK",DATE:"2022-03-25",DISC:"FRI",ACTUAL:16},
      {TYPE:"WEEK",DATE:"2022-03-26",DISC:"SAT",ACTUAL:0},
      {TYPE:"WEEK",DATE:"2022-03-27",DISC:"SUN",ACTUAL:7},
      {TYPE:"WEEK",DATE:"2022-03-28",DISC:"MON",ACTUAL:4},
      {TYPE:"WEEK",DATE:"2022-03-29",DISC:"TUE",ACTUAL:0},
      {TYPE:"WEEK",DATE:"2022-03-30",DISC:"WED",ACTUAL:0},
      {TYPE:"WEEK",DATE:"2022-03-31",DISC:"THU",ACTUAL:0},
      {TYPE:"WEEK",DATE:"2022-03-31",DISC:"TWK",ACTUAL:27}
    ],
    SQL7: [ // week data
      {DATE:"2021-12-02",CUTOFF:"2021-12-02",LAST_WK:0,SAT:0,SUN:0,MON:0,TUE:0,WEN:0,THU:0,FRI:0,THIS_WK:0}
    ]
  }
}