import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 720,
      CanvasHeight: 2200,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 380,
      CanvasChartHeight: 280,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Weight : ', x:240, y: 220, font: 'roboto', class: 'regular', size: 11, color: '#BCBCBC', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: 350, y: 200, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'end' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: 350,
          y: 220,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'end'
        },
      ]
    },
    DataItems: 
    [
      {MR_NO:"75231-P3-1806-IN-R-E-5116",REQ_DESCR:"75231-P3-1806-IN-R-E-5116 (pH Analyzer Conductivity Analyze)",ROS_ETA_FORECAST:-177,ROS_ETA_PLAN:-177},
      {MR_NO:"75231-P3-1824-IN-R-E-5116",REQ_DESCR:"75231-P3-1824-IN-R-E-5116 (pH Analyzer Conductivity Analyze)",ROS_ETA_FORECAST:-177,ROS_ETA_PLAN:-177},
      {MR_NO:"75231-P3-1708-IN-R-E-SL09",REQ_DESCR:"75231-P3-1708-IN-R-E-SL09 (OnLine Mogas Blending Analyzer S)",ROS_ETA_FORECAST:-168,ROS_ETA_PLAN:-168},
      {MR_NO:"75231-P3-1247-IN-R-E-5113",REQ_DESCR:"75231-P3-1247-IN-R-E-5113 (Electrical Chemical Oxygen Analy)",ROS_ETA_FORECAST:-161,ROS_ETA_PLAN:-161},
      {MR_NO:"75231-P3-1700-IN-R-E-5001",REQ_DESCR:"75231-P3-1700-IN-R-E-5001 (DCS)",ROS_ETA_FORECAST:-153,ROS_ETA_PLAN:-153},
      {MR_NO:"75231-P3-1680-IN-R-E-5115",REQ_DESCR:"75231-P3-1680-IN-R-E-5115 (TDL Analyzer)",ROS_ETA_FORECAST:-148,ROS_ETA_PLAN:-148},
      {MR_NO:"75231-P3-1771-IN-R-E-5115",REQ_DESCR:"75231-P3-1771-IN-R-E-5115 (TDL Analyzer)",ROS_ETA_FORECAST:-148,ROS_ETA_PLAN:-148},
      {MR_NO:"75231-P3-1708-IN-R-E-5115",REQ_DESCR:"75231-P3-1708-IN-R-E-5115 (TDL Analyzer)",ROS_ETA_FORECAST:-148,ROS_ETA_PLAN:-148},
      {MR_NO:"75231-P3-1788-IN-R-E-5115",REQ_DESCR:"75231-P3-1788-IN-R-E-5115 (TDL Analyzer)",ROS_ETA_FORECAST:-148,ROS_ETA_PLAN:-148},
      {MR_NO:"75231-P3-1824-IN-R-E-5115",REQ_DESCR:"75231-P3-1824-IN-R-E-5115 (TDL Analyzer)",ROS_ETA_FORECAST:-148,ROS_ETA_PLAN:-148},
      {MR_NO:"75231-P3-2771-IN-R-E-5115",REQ_DESCR:"75231-P3-2771-IN-R-E-5115 (TDL Analyzer)",ROS_ETA_FORECAST:-148,ROS_ETA_PLAN:-148},
      {MR_NO:"75231-P3-1788-IN-R-E-SL01",REQ_DESCR:"75231-P3-1788-IN-R-E-SL01 (Process Analyzer System and Anal)",ROS_ETA_FORECAST:-146,ROS_ETA_PLAN:-146},
      {MR_NO:"75231-P3-1701-IN-R-E-SL07",REQ_DESCR:"75231-P3-1701-IN-R-E-SL07 (Special service Pneumatic OnOff )",ROS_ETA_FORECAST:-123,ROS_ETA_PLAN:-123},
      {MR_NO:"75231-P3-1790-IN-R-E-SL02",REQ_DESCR:"75231-P3-1790-IN-R-E-SL02 (Pressure reducing desuperheater )",ROS_ETA_FORECAST:-113,ROS_ETA_PLAN:-113},
      {MR_NO:"75231-P3-1701-IN-R-E-5709",REQ_DESCR:"75231-P3-1701-IN-R-E-5709 (Critical Butterfly ValvePneu Ono)",ROS_ETA_FORECAST:-86,ROS_ETA_PLAN:-86},
      {MR_NO:"75231-P3-1700-IN-R-E-5012",REQ_DESCR:"75231-P3-1700-IN-R-E-5012 (GDS)",ROS_ETA_FORECAST:-83,ROS_ETA_PLAN:-83},
      {MR_NO:"75231-P3-1806-IN-R-E-5121",REQ_DESCR:"75231-P3-1806-IN-R-E-5121 (Ammonia Analyzer)",ROS_ETA_FORECAST:-76,ROS_ETA_PLAN:-76},
      {MR_NO:"75231-P3-1810-IN-R-E-5120",REQ_DESCR:"75231-P3-1810-IN-R-E-5120 (Residual Chlorine Analyzer)",ROS_ETA_FORECAST:-76,ROS_ETA_PLAN:-76},
      {MR_NO:"75231-P3-1520-AN-R-E-8008",REQ_DESCR:"75231-P3-1520-AN-R-E-8008 (Press COLLIN)",ROS_ETA_FORECAST:-75,ROS_ETA_PLAN:-75},
      {MR_NO:"75231-P3-1810-EQ-R-E-0502",REQ_DESCR:"75231-P3-1810-EQ-R-E-0502 (steel latticed shell roof of 749)",ROS_ETA_FORECAST:-73,ROS_ETA_PLAN:-73},
      {MR_NO:"75231-P3-3320-EQ-R-E-0502",REQ_DESCR:"75231-P3-3320-EQ-R-E-0502 (steel latticed shell roof of 700)",ROS_ETA_FORECAST:-73,ROS_ETA_PLAN:-73},
      {MR_NO:"75231-P3-1520-AN-R-E-8007",REQ_DESCR:"75231-P3-1520-AN-R-E-8007 (OCS Gel count type FSA100 with c)",ROS_ETA_FORECAST:-72,ROS_ETA_PLAN:-72},
      {MR_NO:"75231-P3-1520-AN-R-E-8001",REQ_DESCR:"75231-P3-1520-AN-R-E-8001 (PELLET QUALITY ANALYZER)",ROS_ETA_FORECAST:-72,ROS_ETA_PLAN:-72},
      {MR_NO:"75231-P3-1786-IN-R-E-5126",REQ_DESCR:"75231-P3-1786-IN-R-E-5126 (Wobbi Index Analyzer)",ROS_ETA_FORECAST:-72,ROS_ETA_PLAN:-72},
      {MR_NO:"75231-P3-1789-IN-R-E-5126",REQ_DESCR:"75231-P3-1789-IN-R-E-5126 (Wobbi Index Analyzer)",ROS_ETA_FORECAST:-72,ROS_ETA_PLAN:-72},
      {MR_NO:"75231-P3-1701-IN-R-E-5113",REQ_DESCR:"75231-P3-1701-IN-R-E-5113 (Electrical Chemical Oxygen Analy)",ROS_ETA_FORECAST:-66,ROS_ETA_PLAN:-66},
      {MR_NO:"75231-P3-1700-IN-R-E-5011",REQ_DESCR:"75231-P3-1700-IN-R-E-5011 (SIS)",ROS_ETA_FORECAST:-63,ROS_ETA_PLAN:-63},
      {MR_NO:"75231-P3-1701-IN-R-E-5704",REQ_DESCR:"75231-P3-1701-IN-R-E-5704 (Cryogenic Control Valve)",ROS_ETA_FORECAST:-56,ROS_ETA_PLAN:-56},
      {MR_NO:"75231-P3-1810-WS-R-M-3312-0",REQ_DESCR:"75231-P3-1810-WS-R-M-3312-0 (Special ValveNonSTD Gate Valve)",ROS_ETA_FORECAST:-54,ROS_ETA_PLAN:-54,ETA_ACTUAL:"2023-02-12"},
      {MR_NO:"75231-P3-2810-WS-R-M-3312-0",REQ_DESCR:"75231-P3-2810-WS-R-M-3312-0 (Special ValveNonSTD Gate Valve)",ROS_ETA_FORECAST:-54,ROS_ETA_PLAN:-54,ETA_ACTUAL:"2023-02-12"},
      {MR_NO:"75231-P3-1786-IN-R-E-5315",REQ_DESCR:"75231-P3-1786-IN-R-E-5315 (Severe ServseUltrasonic Flowmete)",ROS_ETA_FORECAST:-44,ROS_ETA_PLAN:-44},
      {MR_NO:"75231-P3-1789-IN-R-E-5315",REQ_DESCR:"75231-P3-1789-IN-R-E-5315 (Severe ServseUltrasonic Flowmete)",ROS_ETA_FORECAST:-44,ROS_ETA_PLAN:-44},
      {MR_NO:"75231-P3-1703-IN-R-E-5724",REQ_DESCR:"75231-P3-1703-IN-R-E-5724 (Pneumatic OnOff Gate Valve)",ROS_ETA_FORECAST:-41,ROS_ETA_PLAN:-41},
      {MR_NO:"75231-P3-1771-IN-R-E-5724",REQ_DESCR:"75231-P3-1771-IN-R-E-5724 (Pneumatic OnOff Gate Valve)",ROS_ETA_FORECAST:-41,ROS_ETA_PLAN:-41},
      {MR_NO:"75231-P3-1708-IN-R-E-5724",REQ_DESCR:"75231-P3-1708-IN-R-E-5724 (Pneumatic OnOff Gate Valve)",ROS_ETA_FORECAST:-41,ROS_ETA_PLAN:-41},
      {MR_NO:"75231-P3-1680-IN-R-E-5724",REQ_DESCR:"75231-P3-1680-IN-R-E-5724 (Pneumatic OnOff Gate Valve)",ROS_ETA_FORECAST:-41,ROS_ETA_PLAN:-41},
      {MR_NO:"75231-P3-1247-IN-R-E-5724",REQ_DESCR:"75231-P3-1247-IN-R-E-5724 (Pneumatic OnOff Gate Valve)",ROS_ETA_FORECAST:-41,ROS_ETA_PLAN:-41},
      {MR_NO:"75231-P3-2703-IN-R-E-5724",REQ_DESCR:"75231-P3-2703-IN-R-E-5724 (Pneumatic OnOff Gate Valve)",ROS_ETA_FORECAST:-41,ROS_ETA_PLAN:-41},
      {MR_NO:"75231-P3-2701-IN-R-E-5724",REQ_DESCR:"75231-P3-2701-IN-R-E-5724 (Pneumatic OnOff Gate Valve)",ROS_ETA_FORECAST:-41,ROS_ETA_PLAN:-41},
      {MR_NO:"75231-P3-2771-IN-R-E-5724",REQ_DESCR:"75231-P3-2771-IN-R-E-5724 (Pneumatic OnOff Gate Valve)",ROS_ETA_FORECAST:-41,ROS_ETA_PLAN:-41},
      {MR_NO:"75231-P3-3771-IN-R-E-5724",REQ_DESCR:"75231-P3-3771-IN-R-E-5724 (Pneumatic OnOff Gate Valve)",ROS_ETA_FORECAST:-41,ROS_ETA_PLAN:-41},
      {MR_NO:"75231-P3-3701-IN-R-E-5724",REQ_DESCR:"75231-P3-3701-IN-R-E-5724 (Pneumatic OnOff Gate Valve)",ROS_ETA_FORECAST:-41,ROS_ETA_PLAN:-41},
      {MR_NO:"75231-P3-1790-ST-R-M-2714",REQ_DESCR:"75231-P3-1790-ST-R-M-2714 (YTYPE STOP VALVE IMPORT)",ROS_ETA_FORECAST:-40,ROS_ETA_PLAN:-40},
      {MR_NO:"75231-P3-1520-AN-R-E-8006",REQ_DESCR:"75231-P3-1520-AN-R-E-8006 (LAB EXTRUDER WITH PELLETER)",ROS_ETA_FORECAST:-39,ROS_ETA_PLAN:-39},
      {MR_NO:"75231-P3-1520-AN-R-E-8002",REQ_DESCR:"75231-P3-1520-AN-R-E-8002 (AUTOMATIC POLYOLEFIN XYLENE SOLU)",ROS_ETA_FORECAST:-36,ROS_ETA_PLAN:-36},
      {MR_NO:"75231-P3-1520-AN-R-E-8005",REQ_DESCR:"75231-P3-1520-AN-R-E-8005 (Small Extruder Screen)",ROS_ETA_FORECAST:-36,ROS_ETA_PLAN:-36},
      {MR_NO:"75231-P3-1700-IN-R-E-5154",REQ_DESCR:"75231-P3-1700-IN-R-E-5154 (Benzene Toxic Gas Detector)",ROS_ETA_FORECAST:-36,ROS_ETA_PLAN:-36},
      {MR_NO:"75231-P3-1520-IN-R-E-5154",REQ_DESCR:"75231-P3-1520-IN-R-E-5154 (Benzene Toxic Gas Detector)",ROS_ETA_FORECAST:-36,ROS_ETA_PLAN:-36},
      {MR_NO:"75231-P3-1771-IN-R-E-5154",REQ_DESCR:"75231-P3-1771-IN-R-E-5154 (Benzene Toxic Gas Detector)",ROS_ETA_FORECAST:-36,ROS_ETA_PLAN:-36},
      {MR_NO:"75231-P3-1780-IN-R-E-5154",REQ_DESCR:"75231-P3-1780-IN-R-E-5154 (Benzene Toxic Gas Detector)",ROS_ETA_FORECAST:-36,ROS_ETA_PLAN:-36},
      {MR_NO:"75231-P3-1703-IN-R-E-5154",REQ_DESCR:"75231-P3-1703-IN-R-E-5154 (Benzene Toxic Gas Detector)",ROS_ETA_FORECAST:-36,ROS_ETA_PLAN:-36},
      {MR_NO:"75231-P3-1708-IN-R-E-5154",REQ_DESCR:"75231-P3-1708-IN-R-E-5154 (Benzene Toxic Gas Detector)",ROS_ETA_FORECAST:-36,ROS_ETA_PLAN:-36},
      {MR_NO:"75231-P3-2771-IN-R-E-5154",REQ_DESCR:"75231-P3-2771-IN-R-E-5154 (Benzene Toxic Gas Detector)",ROS_ETA_FORECAST:-36,ROS_ETA_PLAN:-36},
      {MR_NO:"75231-P3-2802-IN-R-E-5319",REQ_DESCR:"75231-P3-2802-IN-R-E-5319 (Ultrasonic Flowmeter)",ROS_ETA_FORECAST:-33,ROS_ETA_PLAN:-33},
      {MR_NO:"75231-P3-1853-IN-R-E-5319",REQ_DESCR:"75231-P3-1853-IN-R-E-5319 (Ultrasonic Flowmeter)",ROS_ETA_FORECAST:-33,ROS_ETA_PLAN:-33},
      {MR_NO:"75231-P3-1802-IN-R-E-5319",REQ_DESCR:"75231-P3-1802-IN-R-E-5319 (Ultrasonic Flowmeter)",ROS_ETA_FORECAST:-33,ROS_ETA_PLAN:-33},
      {MR_NO:"75231-P3-1520-AN-R-E-8010",REQ_DESCR:"75231-P3-1520-AN-R-E-8010 (WAUKESHA CFR)",ROS_ETA_FORECAST:-33,ROS_ETA_PLAN:-33},
      {MR_NO:"75231-P3-1810-IN-R-E-5319",REQ_DESCR:"75231-P3-1810-IN-R-E-5319 (Ultrasonic Flowmeter)",ROS_ETA_FORECAST:-33,ROS_ETA_PLAN:-33},
      {MR_NO:"75231-P3-1802-IN-R-E-SL01",REQ_DESCR:"75231-P3-1802-IN-R-E-SL01 (Process Analyzer System and Anal)",ROS_ETA_FORECAST:-25,ROS_ETA_PLAN:-25},
      {MR_NO:"75231-P3-2802-IN-R-E-SL01",REQ_DESCR:"75231-P3-2802-IN-R-E-SL01 (Process Analyzer System and Anal)",ROS_ETA_FORECAST:-25,ROS_ETA_PLAN:-25},
      {MR_NO:"5231-P3-1520-AN-R-E-8004",REQ_DESCR:"5231-P3-1520-AN-R-E-8004 (INJECTIONMOLDING MACHINE WITH MO)",ROS_ETA_FORECAST:-22,ROS_ETA_PLAN:-22},
      {MR_NO:"75231-P3-1520-IN-R-E-5702",REQ_DESCR:"75231-P3-1520-IN-R-E-5702 (Severe Service Globe Control Val)",ROS_ETA_FORECAST:-19,ROS_ETA_PLAN:-19},
      {MR_NO:"75231-P3-1790-IN-R-E-5702",REQ_DESCR:"75231-P3-1790-IN-R-E-5702 (Severe Service Globe Control Val)",ROS_ETA_FORECAST:-19,ROS_ETA_PLAN:-19},
      {MR_NO:"75231-P3-1796-IN-R-E-5131",REQ_DESCR:"75231-P3-1796-IN-R-E-5131 (Continuous Emission Monitoring S)",ROS_ETA_FORECAST:-14,ROS_ETA_PLAN:-14},
      {MR_NO:"75231-P3-1802-IN-R-E-5701",REQ_DESCR:"75231-P3-1802-IN-R-E-5701 (General Service Globe Control Va)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-1802-IN-R-E-5710",REQ_DESCR:"75231-P3-1802-IN-R-E-5710 (Butterfly ValvePneu OnoffMotor O)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-1802-IN-R-E-5728",REQ_DESCR:"75231-P3-1802-IN-R-E-5728 (Motor Operated Gate Valve)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-1789-IN-R-E-5701",REQ_DESCR:"75231-P3-1789-IN-R-E-5701 (General Service Globe Control Va)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-1789-IN-R-E-5710",REQ_DESCR:"75231-P3-1789-IN-R-E-5710 (Butterfly ValvePneu OnoffMotor O)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-1789-IN-R-E-5721",REQ_DESCR:"75231-P3-1789-IN-R-E-5721 (General service Pneumatic OnOff )",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-1788-IN-R-E-5710",REQ_DESCR:"75231-P3-1788-IN-R-E-5710 (Butterfly ValvePneu OnoffMotor O)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-1790-IN-R-E-5701",REQ_DESCR:"75231-P3-1790-IN-R-E-5701 (General Service Globe Control Va)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-1786-IN-R-E-5701",REQ_DESCR:"75231-P3-1786-IN-R-E-5701 (General Service Globe Control Va)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-1786-IN-R-E-5721",REQ_DESCR:"75231-P3-1786-IN-R-E-5721 (General service Pneumatic OnOff )",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-1703-IN-R-E-5701",REQ_DESCR:"75231-P3-1703-IN-R-E-5701 (General Service Globe Control Va)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-1701-IN-R-E-5712",REQ_DESCR:"75231-P3-1701-IN-R-E-5712 (Butterfly Control Valve)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-1771-IN-R-E-5728",REQ_DESCR:"75231-P3-1771-IN-R-E-5728 (Motor Operated Gate Valve)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-1771-IN-R-E-5701",REQ_DESCR:"75231-P3-1771-IN-R-E-5701 (General Service Globe Control Va)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-1771-IN-R-E-5721",REQ_DESCR:"75231-P3-1771-IN-R-E-5721 (General service Pneumatic OnOff )",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-1708-IN-R-E-5728",REQ_DESCR:"75231-P3-1708-IN-R-E-5728 (Motor Operated Gate Valve)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-1708-IN-R-E-5701",REQ_DESCR:"75231-P3-1708-IN-R-E-5701 (General Service Globe Control Va)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-1708-IN-R-E-5712",REQ_DESCR:"75231-P3-1708-IN-R-E-5712 (Butterfly Control Valve)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-1708-IN-R-E-5721",REQ_DESCR:"75231-P3-1708-IN-R-E-5721 (General service Pneumatic OnOff )",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-1680-IN-R-E-5728",REQ_DESCR:"75231-P3-1680-IN-R-E-5728 (Motor Operated Gate Valve)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-1680-IN-R-E-5701",REQ_DESCR:"75231-P3-1680-IN-R-E-5701 (General Service Globe Control Va)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-1680-IN-R-E-5710",REQ_DESCR:"75231-P3-1680-IN-R-E-5710 (Butterfly ValvePneu OnoffMotor O)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-1680-IN-R-E-5712",REQ_DESCR:"75231-P3-1680-IN-R-E-5712 (Butterfly Control Valve)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-1247-IN-R-E-5701",REQ_DESCR:"75231-P3-1247-IN-R-E-5701 (General Service Globe Control Va)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-1806-IN-R-E-5710",REQ_DESCR:"75231-P3-1806-IN-R-E-5710 (Butterfly ValvePneu OnoffMotor O)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-1804-IN-R-E-5728",REQ_DESCR:"75231-P3-1804-IN-R-E-5728 (Motor Operated Gate Valve)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-1810-IN-R-E-5701",REQ_DESCR:"75231-P3-1810-IN-R-E-5701 (General Service Globe Control Va)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-1810-IN-R-E-5712",REQ_DESCR:"75231-P3-1810-IN-R-E-5712 (Butterfly Control Valve)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-1813-IN-R-E-5728",REQ_DESCR:"75231-P3-1813-IN-R-E-5728 (Motor Operated Gate Valve)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-2703-IN-R-E-5701",REQ_DESCR:"75231-P3-2703-IN-R-E-5701 (General Service Globe Control Va)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-2771-IN-R-E-5701",REQ_DESCR:"75231-P3-2771-IN-R-E-5701 (General Service Globe Control Va)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-2771-IN-R-E-5721",REQ_DESCR:"75231-P3-2771-IN-R-E-5721 (General service Pneumatic OnOff )",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-2802-IN-R-E-5701",REQ_DESCR:"75231-P3-2802-IN-R-E-5701 (General Service Globe Control Va)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-2802-IN-R-E-5728",REQ_DESCR:"75231-P3-2802-IN-R-E-5728 (Motor Operated Gate Valve)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-2771-IN-R-E-5728",REQ_DESCR:"75231-P3-2771-IN-R-E-5728 (Motor Operated Gate Valve)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-2701-IN-R-E-5701",REQ_DESCR:"75231-P3-2701-IN-R-E-5701 (General Service Globe Control Va)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-2701-IN-R-E-5721",REQ_DESCR:"75231-P3-2701-IN-R-E-5721 (General service Pneumatic OnOff )",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-1853-IN-R-E-5701",REQ_DESCR:"75231-P3-1853-IN-R-E-5701 (General Service Globe Control Va)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-1824-IN-R-E-5701",REQ_DESCR:"75231-P3-1824-IN-R-E-5701 (General Service Globe Control Va)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-1824-IN-R-E-5710",REQ_DESCR:"75231-P3-1824-IN-R-E-5710 (Butterfly ValvePneu OnoffMotor O)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-1824-IN-R-E-5721",REQ_DESCR:"75231-P3-1824-IN-R-E-5721 (General service Pneumatic OnOff )",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-2810-IN-R-E-5701",REQ_DESCR:"75231-P3-2810-IN-R-E-5701 (General Service Globe Control Va)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-2810-IN-R-E-5728",REQ_DESCR:"75231-P3-2810-IN-R-E-5728 (Motor Operated Gate Valve)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-3771-IN-R-E-5701",REQ_DESCR:"75231-P3-3771-IN-R-E-5701 (General Service Globe Control Va)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-3810-IN-R-E-5728",REQ_DESCR:"75231-P3-3810-IN-R-E-5728 (Motor Operated Gate Valve)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-3701-IN-R-E-5701",REQ_DESCR:"75231-P3-3701-IN-R-E-5701 (General Service Globe Control Va)",ROS_ETA_FORECAST:-9,ROS_ETA_PLAN:-9},
      {MR_NO:"75231-P3-1701-ST-R-M-2065",REQ_DESCR:"75231-P3-1701-ST-R-M-2065 (SAFETY VALVEIMPORT)",ROS_ETA_FORECAST:-8,ROS_ETA_PLAN:-8},
      {MR_NO:"75231-P3-1503-WS-R-M-3311-0",REQ_DESCR:"75231-P3-1503-WS-R-M-3311-0 (Special ValveMOV)",ROS_ETA_FORECAST:-6,ROS_ETA_PLAN:-6},
      {MR_NO:"75231-P3-1861-IN-R-E-5001",REQ_DESCR:"75231-P3-1861-IN-R-E-5001 (DCS)",ROS_ETA_FORECAST:-5,ROS_ETA_PLAN:-5},
      {MR_NO:"75231-P3-1802-WS-R-M-3002",REQ_DESCR:"75231-P3-1802-WS-R-M-3002 (CS SAW SPS Pipe,CS SAWSPS Pipe)",ROS_ETA_FORECAST:0,ROS_ETA_PLAN:0,ETA_ACTUAL:"2022-11-04"},
      {MR_NO:"75231-P3-1510-WS-R-M-2402",REQ_DESCR:"75231-P3-1510-WS-R-M-2402 (Forged Fitting)",ROS_ETA_FORECAST:1,ROS_ETA_PLAN:1,ETA_ACTUAL:"2022-10-26"},
      {MR_NO:"75231-P3-0000-IN-R-E-5001",REQ_DESCR:"75231-P3-0000-IN-R-E-5001 (DISTRIBUTED CONTROL SYSTEM DCS)",ROS_ETA_FORECAST:1,ROS_ETA_PLAN:1},
      {MR_NO:"75231-P3-0000-IN-R-E-5002",REQ_DESCR:"75231-P3-0000-IN-R-E-5002 (SAFETY INSTRUMENT SYSTEMSIS and )",ROS_ETA_FORECAST:1,ROS_ETA_PLAN:1},
      {MR_NO:"75231-P3-1520-AN-R-E-8012-0",REQ_DESCR:"75231-P3-1520-AN-R-E-8012-0 (IC)",ROS_ETA_FORECAST:1,ROS_ETA_PLAN:1},
      {MR_NO:"75231-P3-1520-AN-R-E-8013-0",REQ_DESCR:"75231-P3-1520-AN-R-E-8013-0 (Spectrometer)",ROS_ETA_FORECAST:1,ROS_ETA_PLAN:1},
      {MR_NO:"75231-P3-1520-AN-R-E-8009-0",REQ_DESCR:"75231-P3-1520-AN-R-E-8009-0 (Polymer sample preparation equip)",ROS_ETA_FORECAST:1,ROS_ETA_PLAN:1},
      {MR_NO:"75231-P3-1520-AN-R-E-8003-0",REQ_DESCR:"75231-P3-1520-AN-R-E-8003-0 (Polymer physical property test i)",ROS_ETA_FORECAST:1,ROS_ETA_PLAN:1},
      {MR_NO:"75231-P3-1520-AN-R-E-8015-0",REQ_DESCR:"75231-P3-1520-AN-R-E-8015-0 (Optical colorimeter)",ROS_ETA_FORECAST:1,ROS_ETA_PLAN:1},
      {MR_NO:"75231-P3-1520-AN-R-E-8016-0",REQ_DESCR:"75231-P3-1520-AN-R-E-8016-0 (Elemental analysis instrument)",ROS_ETA_FORECAST:1,ROS_ETA_PLAN:1},
      {MR_NO:"75231-P3-1520-AN-R-E-8017-0",REQ_DESCR:"75231-P3-1520-AN-R-E-8017-0 (Balance)",ROS_ETA_FORECAST:1,ROS_ETA_PLAN:1},
      {MR_NO:"75231-P3-1520-AN-R-E-8018-0",REQ_DESCR:"75231-P3-1520-AN-R-E-8018-0 (Oil analysis instrument)",ROS_ETA_FORECAST:1,ROS_ETA_PLAN:1},
      {MR_NO:"75231-P3-1520-AN-R-E-8019-0",REQ_DESCR:"75231-P3-1520-AN-R-E-8019-0 (Portable instrument)",ROS_ETA_FORECAST:1,ROS_ETA_PLAN:1},
      {MR_NO:"75231-P3-1520-AN-R-E-8020-0",REQ_DESCR:"75231-P3-1520-AN-R-E-8020-0 (Water analysis instrument)",ROS_ETA_FORECAST:1,ROS_ETA_PLAN:1},
      {MR_NO:"75231-P3-1520-AN-R-E-8021-0",REQ_DESCR:"75231-P3-1520-AN-R-E-8021-0 (Others1)",ROS_ETA_FORECAST:1,ROS_ETA_PLAN:1},
      {MR_NO:"75231-P3-1520-EP-R-E-8202-0",REQ_DESCR:"75231-P3-1520-EP-R-E-8202-0 (Ion Chromatograph)",ROS_ETA_FORECAST:1,ROS_ETA_PLAN:1},
      {MR_NO:"75231-P3-1520-EP-R-E-8203-0",REQ_DESCR:"75231-P3-1520-EP-R-E-8203-0 (Spectrophotometer)",ROS_ETA_FORECAST:1,ROS_ETA_PLAN:1},
      {MR_NO:"75231-P3-1520-EP-R-E-8204-0",REQ_DESCR:"75231-P3-1520-EP-R-E-8204-0 (Automatic electrometric Titrator)",ROS_ETA_FORECAST:1,ROS_ETA_PLAN:1},
      {MR_NO:"75231-P3-1520-EP-R-E-8205",REQ_DESCR:"75231-P3-1520-EP-R-E-8205 (Total Organic Carbon Analyzer)",ROS_ETA_FORECAST:1,ROS_ETA_PLAN:1},
      {MR_NO:"75231-P3-1520-EP-R-E-8206-0",REQ_DESCR:"75231-P3-1520-EP-R-E-8206-0 (Analytic Balance)",ROS_ETA_FORECAST:1,ROS_ETA_PLAN:1},
      {MR_NO:"75231-P3-1520-EP-R-E-8207-0",REQ_DESCR:"75231-P3-1520-EP-R-E-8207-0 (GasFlue Gas nalyzer and sampler1)",ROS_ETA_FORECAST:1,ROS_ETA_PLAN:1},
      {MR_NO:"75231-P3-1520-EP-R-E-8208-0",REQ_DESCR:"75231-P3-1520-EP-R-E-8208-0 (GasFlue Gas nalyzer and sampler1)",ROS_ETA_FORECAST:1,ROS_ETA_PLAN:1},
      {MR_NO:"75231-P3-1520-EP-R-E-8209-0",REQ_DESCR:"75231-P3-1520-EP-R-E-8209-0 (LDAR leak detection)",ROS_ETA_FORECAST:1,ROS_ETA_PLAN:1},
      {MR_NO:"75231-P3-1520-EP-R-E-8210-0",REQ_DESCR:"75231-P3-1520-EP-R-E-8210-0 (Water analysis instrument 1)",ROS_ETA_FORECAST:1,ROS_ETA_PLAN:1},
      {MR_NO:"75231-P3-1520-EP-R-E-8211-0",REQ_DESCR:"75231-P3-1520-EP-R-E-8211-0 (Water analysis instrument 2)",ROS_ETA_FORECAST:1,ROS_ETA_PLAN:1},
      {MR_NO:"75231-P3-1520-EP-R-E-8212-0",REQ_DESCR:"75231-P3-1520-EP-R-E-8212-0 (Noise anaylsis)",ROS_ETA_FORECAST:1,ROS_ETA_PLAN:1},
      {MR_NO:"75231-P3-1520-EP-R-E-8213-0",REQ_DESCR:"75231-P3-1520-EP-R-E-8213-0 (Others1)",ROS_ETA_FORECAST:1,ROS_ETA_PLAN:1},
      {MR_NO:"75231-P3-1520-EP-R-E-8214-0",REQ_DESCR:"75231-P3-1520-EP-R-E-8214-0 (Others2)",ROS_ETA_FORECAST:1,ROS_ETA_PLAN:1},
      {MR_NO:"75231-P3-1802-WS-R-M-3314",REQ_DESCR:"75231-P3-1802-WS-R-M-3314 (Special ValvePipeline SelfRelian)",ROS_ETA_FORECAST:2,ROS_ETA_PLAN:2},
      {MR_NO:"75231-P3-1810-WS-R-M-3314",REQ_DESCR:"75231-P3-1810-WS-R-M-3314 (Special ValvePipeline SelfRelian)",ROS_ETA_FORECAST:2,ROS_ETA_PLAN:2},
      {MR_NO:"75231-P3-1806-WS-R-M-3314",REQ_DESCR:"75231-P3-1806-WS-R-M-3314 (Special ValvePipeline SelfRelian)",ROS_ETA_FORECAST:2,ROS_ETA_PLAN:2},
      {MR_NO:"75231-P3-2802-WS-R-M-3314",REQ_DESCR:"75231-P3-2802-WS-R-M-3314 (Special ValvePipeline SelfRelian)",ROS_ETA_FORECAST:2,ROS_ETA_PLAN:2},
      {MR_NO:"75231-P3-2810-WS-R-M-3314",REQ_DESCR:"75231-P3-2810-WS-R-M-3314 (Special ValvePipeline SelfRelian)",ROS_ETA_FORECAST:2,ROS_ETA_PLAN:2},
      {MR_NO:"75231-P3-3771-IN-R-E-5731",REQ_DESCR:"75231-P3-3771-IN-R-E-5731 (Pressure Regulator)",ROS_ETA_FORECAST:4,ROS_ETA_PLAN:4},
      {MR_NO:"75231-P3-2771-IN-R-E-5731",REQ_DESCR:"75231-P3-2771-IN-R-E-5731 (Pressure Regulator)",ROS_ETA_FORECAST:4,ROS_ETA_PLAN:4},
      {MR_NO:"75231-P3-1680-IN-R-E-5731",REQ_DESCR:"75231-P3-1680-IN-R-E-5731 (Pressure Regulator)",ROS_ETA_FORECAST:4,ROS_ETA_PLAN:4},
      {MR_NO:"75231-P3-1668-IN-R-E-5731",REQ_DESCR:"75231-P3-1668-IN-R-E-5731 (Pressure Regulator)",ROS_ETA_FORECAST:4,ROS_ETA_PLAN:4},
      {MR_NO:"75231-P3-1708-IN-R-E-5731",REQ_DESCR:"75231-P3-1708-IN-R-E-5731 (Pressure Regulator)",ROS_ETA_FORECAST:4,ROS_ETA_PLAN:4},
      {MR_NO:"75231-P3-1771-IN-R-E-5731",REQ_DESCR:"75231-P3-1771-IN-R-E-5731 (Pressure Regulator)",ROS_ETA_FORECAST:4,ROS_ETA_PLAN:4},
      {MR_NO:"75231-P3-1796-IN-R-E-5132",REQ_DESCR:"75231-P3-1796-IN-R-E-5132 (AMBIENT AIR QUALITY CONTINUOUS M)",ROS_ETA_FORECAST:10,ROS_ETA_PLAN:10},
      {MR_NO:"75231-P3-2703-IN-R-E-5303-0",REQ_DESCR:"75231-P3-2703-IN-R-E-5303-0 (Wedge Flowmeter)",ROS_ETA_FORECAST:10,ROS_ETA_PLAN:10},
      {MR_NO:"75231-P3-1520-AN-R-E-8014-0",REQ_DESCR:"75231-P3-1520-AN-R-E-8014-0 (Automatic electrometric Titrator)",ROS_ETA_FORECAST:11,ROS_ETA_PLAN:11},
      {MR_NO:"75231-P3-1810-WS-R-M-2703-1",REQ_DESCR:"75231-P3-1810-WS-R-M-2703-1 (FORGED VALVESPN 800Lb PN 800Lb)",ROS_ETA_FORECAST:12,ROS_ETA_PLAN:12},
      {MR_NO:"75231-P3-1806-WS-R-M-2703-0",REQ_DESCR:"75231-P3-1806-WS-R-M-2703-0 (FORGED VALVESPN 800Lb PN 800Lb)",ROS_ETA_FORECAST:12,ROS_ETA_PLAN:12},
      {MR_NO:"75231-P3-2810-WS-R-M-2703-0",REQ_DESCR:"75231-P3-2810-WS-R-M-2703-0 (FORGED VALVESPN 800Lb PN 800Lb)",ROS_ETA_FORECAST:12,ROS_ETA_PLAN:12},
      {MR_NO:"75231-P3-3000-WS-R-M-2703-4",REQ_DESCR:"75231-P3-3000-WS-R-M-2703-4 (FORGED VALVESPN 800Lb PN 800Lb)",ROS_ETA_FORECAST:12,ROS_ETA_PLAN:12},
      {MR_NO:"75231-P3-1806-WS-R-M-2502-0",REQ_DESCR:"75231-P3-1806-WS-R-M-2502-0 (GASKET)",ROS_ETA_FORECAST:19,ROS_ETA_PLAN:19},
      {MR_NO:"75231-P3-1810-WS-R-M-2502-1",REQ_DESCR:"75231-P3-1810-WS-R-M-2502-1 (GASKET)",ROS_ETA_FORECAST:19,ROS_ETA_PLAN:19},
      {MR_NO:"75231-P3-1520-AN-R-E-8023-0",REQ_DESCR:"75231-P3-1520-AN-R-E-8023-0 (Laboratory Furniture and tube)",ROS_ETA_FORECAST:24,ROS_ETA_PLAN:24},
      {MR_NO:"75231-P3-1810-WS-R-M-2603-1",REQ_DESCR:"75231-P3-1810-WS-R-M-2603-1 (FLANGE MPLP CSSSAS  350 DN 600)",ROS_ETA_FORECAST:28,ROS_ETA_PLAN:28},
      {MR_NO:"75231-P3-1810-WS-R-M-2601-1",REQ_DESCR:"75231-P3-1810-WS-R-M-2601-1 (FLANGE HP CSSSAS 900LB 1500LB250)",ROS_ETA_FORECAST:28,ROS_ETA_PLAN:28},
      {MR_NO:"75231-P3-1810-WS-R-M-2705-1",REQ_DESCR:"75231-P3-1810-WS-R-M-2705-1 (CS CAST VALVESGATEGLOBECHECKPN 6)",ROS_ETA_FORECAST:28,ROS_ETA_PLAN:28},
      {MR_NO:"75231-P3-1806-WS-R-M-2601-1",REQ_DESCR:"75231-P3-1806-WS-R-M-2601-1 (FLANGE HP CSSSAS 900LB 1500LB250)",ROS_ETA_FORECAST:28,ROS_ETA_PLAN:28},
      {MR_NO:"75231-P3-2810-WS-R-M-2601-1",REQ_DESCR:"75231-P3-2810-WS-R-M-2601-1 (FLANGE HP CSSSAS 900LB 1500LB250)",ROS_ETA_FORECAST:28,ROS_ETA_PLAN:28},
      {MR_NO:"75231-P3-2810-WS-R-M-2603-1",REQ_DESCR:"75231-P3-2810-WS-R-M-2603-1 (FLANGE MPLP CSSSAS  350 DN 600)",ROS_ETA_FORECAST:28,ROS_ETA_PLAN:28},
      {MR_NO:"75231-P3-1700-IN-R-E-5415-0",REQ_DESCR:"75231-P3-1700-IN-R-E-5415-0 (Servo Level Instrument)",ROS_ETA_FORECAST:32,ROS_ETA_PLAN:32},
      {MR_NO:"75231-P3-1802-WS-R-M-3003-2",REQ_DESCR:"75231-P3-1802-WS-R-M-3003-2 (CS DSAWLGS L245 PSL1 Pipe L245)",ROS_ETA_FORECAST:38,ROS_ETA_PLAN:38,ETA_ACTUAL:"2023-01-13"},
      {MR_NO:"75231-P3-2802-WS-R-M-3003-2",REQ_DESCR:"75231-P3-2802-WS-R-M-3003-2 (CS DSAWLGS L245 PSL1 Pipe L245)",ROS_ETA_FORECAST:38,ROS_ETA_PLAN:38,ETA_ACTUAL:"2023-01-13"},
      {MR_NO:"75231-P3-2810-WS-R-M-3201-0",REQ_DESCR:"75231-P3-2810-WS-R-M-3201-0 (FLANGE MPLP CSSSAS)",ROS_ETA_FORECAST:39,ROS_ETA_PLAN:39},
      {MR_NO:"75231-P3-1810-WS-R-M-3201-0",REQ_DESCR:"75231-P3-1810-WS-R-M-3201-0 (FLANGE MPLP CSSSAS)",ROS_ETA_FORECAST:39,ROS_ETA_PLAN:39},
      {MR_NO:"75231-P3-3000-WS-R-M-3312",REQ_DESCR:"75231-P3-3000-WS-R-M-3312 (Special ValveNonSTD Gate Valve)",ROS_ETA_FORECAST:50,ROS_ETA_PLAN:50},
      {MR_NO:"75231-P3-2810-WS-R-M-2505-1",REQ_DESCR:"75231-P3-2810-WS-R-M-2505-1 (Bolts Nuts M33)",ROS_ETA_FORECAST:53,ROS_ETA_PLAN:53,ETA_ACTUAL:"2023-01-13"},
      {MR_NO:"75231-P3-2810-WS-R-M-2501-1",REQ_DESCR:"75231-P3-2810-WS-R-M-2501-1 (GASKET)",ROS_ETA_FORECAST:53,ROS_ETA_PLAN:53,ETA_ACTUAL:"2023-01-13"},
      {MR_NO:"75231-P3-1510-WS-R-M-3001",REQ_DESCR:"75231-P3-1510-WS-R-M-3001 (CS Seamless Pipe,CS SMLS Pipe)",ROS_ETA_FORECAST:53,ROS_ETA_PLAN:53,ETA_ACTUAL:"2022-07-11"},
      {MR_NO:"75231-P3-1810-WS-R-M-3101-2",REQ_DESCR:"75231-P3-1810-WS-R-M-3101-2 (CS Seamless Fitting)",ROS_ETA_FORECAST:65,ROS_ETA_PLAN:65,ETA_ACTUAL:"2023-01-13"},
      {MR_NO:"75231-P3-1802-WS-R-M-3102-4",REQ_DESCR:"75231-P3-1802-WS-R-M-3102-4 (CS Welded Fitting)",ROS_ETA_FORECAST:65,ROS_ETA_PLAN:65,ETA_ACTUAL:"2023-01-13"},
      {MR_NO:"75231-P3-2802-WS-R-M-3102-4",REQ_DESCR:"75231-P3-2802-WS-R-M-3102-4 (CS Welded Fitting)",ROS_ETA_FORECAST:65,ROS_ETA_PLAN:65,ETA_ACTUAL:"2023-01-13"},
      {MR_NO:"75231-P3-2802-WS-R-M-3101-1",REQ_DESCR:"75231-P3-2802-WS-R-M-3101-1 (CS Seamless Fitting)",ROS_ETA_FORECAST:65,ROS_ETA_PLAN:65,ETA_ACTUAL:"2023-01-13"},
      {MR_NO:"75231-P3-2802-WS-R-M-3001-1",REQ_DESCR:"75231-P3-2802-WS-R-M-3001-1 (CS SMLS Pipe)",ROS_ETA_FORECAST:65,ROS_ETA_PLAN:65,ETA_ACTUAL:"2023-01-13"},
      {MR_NO:"75231-P3-2810-WS-R-M-2602-1",REQ_DESCR:"75231-P3-2810-WS-R-M-2602-1 (FLANGE MPLP CSSSAS  DN 300)",ROS_ETA_FORECAST:65,ROS_ETA_PLAN:65,ETA_ACTUAL:"2023-01-13"},
      {MR_NO:"75231-P3-2810-WS-R-M-3101-2",REQ_DESCR:"75231-P3-2810-WS-R-M-3101-2 (CS Seamless Fitting)",ROS_ETA_FORECAST:65,ROS_ETA_PLAN:65,ETA_ACTUAL:"2023-01-13"},
      {MR_NO:"75231-P3-1861-IN-R-E-5012",REQ_DESCR:"75231-P3-1861-IN-R-E-5012 (GDS)",ROS_ETA_FORECAST:65,ROS_ETA_PLAN:65},
      {MR_NO:"75231-P3-3000-WS-R-M-3102-4",REQ_DESCR:"75231-P3-3000-WS-R-M-3102-4 (CS Welded Fitting)",ROS_ETA_FORECAST:65,ROS_ETA_PLAN:65,ETA_ACTUAL:"2023-01-13"},
      {MR_NO:"75231-P3-3771-IN-R-E-5301",REQ_DESCR:"75231-P3-3771-IN-R-E-5301 (General Service Throttling Devic)",ROS_ETA_FORECAST:66,ROS_ETA_PLAN:66},
      {MR_NO:"75231-P3-3701-IN-R-E-5301",REQ_DESCR:"75231-P3-3701-IN-R-E-5301 (General Service Throttling Devic)",ROS_ETA_FORECAST:66,ROS_ETA_PLAN:66},
      {MR_NO:"75231-P3-2701-IN-R-E-5301",REQ_DESCR:"75231-P3-2701-IN-R-E-5301 (General Service Throttling Devic)",ROS_ETA_FORECAST:66,ROS_ETA_PLAN:66},
      {MR_NO:"75231-P3-2703-IN-R-E-5301",REQ_DESCR:"75231-P3-2703-IN-R-E-5301 (General Service Throttling Devic)",ROS_ETA_FORECAST:66,ROS_ETA_PLAN:66},
      {MR_NO:"75231-P3-2771-IN-R-E-5301",REQ_DESCR:"75231-P3-2771-IN-R-E-5301 (General Service Throttling Devic)",ROS_ETA_FORECAST:66,ROS_ETA_PLAN:66},
      {MR_NO:"75231-P3-1824-IN-R-E-5301",REQ_DESCR:"75231-P3-1824-IN-R-E-5301 (General Service Throttling Devic)",ROS_ETA_FORECAST:66,ROS_ETA_PLAN:66},
      {MR_NO:"75231-P3-1247-IN-R-E-5301",REQ_DESCR:"75231-P3-1247-IN-R-E-5301 (General Service Throttling Devic)",ROS_ETA_FORECAST:66,ROS_ETA_PLAN:66},
      {MR_NO:"75231-P3-1680-IN-R-E-5301",REQ_DESCR:"75231-P3-1680-IN-R-E-5301 (General Service Throttling Devic)",ROS_ETA_FORECAST:66,ROS_ETA_PLAN:66},
      {MR_NO:"75231-P3-1701-IN-R-E-5301",REQ_DESCR:"75231-P3-1701-IN-R-E-5301 (General Service Throttling Devic)",ROS_ETA_FORECAST:66,ROS_ETA_PLAN:66},
      {MR_NO:"75231-P3-1703-IN-R-E-5301",REQ_DESCR:"75231-P3-1703-IN-R-E-5301 (General Service Throttling Devic)",ROS_ETA_FORECAST:66,ROS_ETA_PLAN:66},
      {MR_NO:"75231-P3-1708-IN-R-E-5301",REQ_DESCR:"75231-P3-1708-IN-R-E-5301 (General Service Throttling Devic)",ROS_ETA_FORECAST:66,ROS_ETA_PLAN:66},
      {MR_NO:"75231-P3-1786-IN-R-E-5301",REQ_DESCR:"75231-P3-1786-IN-R-E-5301 (General Service Throttling Devic)",ROS_ETA_FORECAST:66,ROS_ETA_PLAN:66},
      {MR_NO:"75231-P3-1771-IN-R-E-5301",REQ_DESCR:"75231-P3-1771-IN-R-E-5301 (General Service Throttling Devic)",ROS_ETA_FORECAST:66,ROS_ETA_PLAN:66},
      {MR_NO:"75231-P3-1802-IN-R-E-5301",REQ_DESCR:"75231-P3-1802-IN-R-E-5301 (General Service Throttling Devic)",ROS_ETA_FORECAST:66,ROS_ETA_PLAN:66},
      {MR_NO:"75231-P3-1789-IN-R-E-5301",REQ_DESCR:"75231-P3-1789-IN-R-E-5301 (General Service Throttling Devic)",ROS_ETA_FORECAST:66,ROS_ETA_PLAN:66},
      {MR_NO:"75231-P3-1790-IN-R-E-5301",REQ_DESCR:"75231-P3-1790-IN-R-E-5301 (General Service Throttling Devic)",ROS_ETA_FORECAST:66,ROS_ETA_PLAN:66},
      {MR_NO:"75231-P3-3000-ST-R-M-2301",REQ_DESCR:"75231-P3-3000-ST-R-M-2301 (PIPE CS SEAMLESS DN 100)",ROS_ETA_FORECAST:76,ROS_ETA_PLAN:76},
      {MR_NO:"75231-P3-1861-IN-R-E-5011",REQ_DESCR:"75231-P3-1861-IN-R-E-5011 (SIS)",ROS_ETA_FORECAST:85,ROS_ETA_PLAN:85},
      {MR_NO:"75231-P3-2810-WS-R-M-3003-0",REQ_DESCR:"75231-P3-2810-WS-R-M-3003-0 (CS DSAWLGS L245 PSL1 Pipe L245)",ROS_ETA_FORECAST:86,ROS_ETA_PLAN:86},
      {MR_NO:"75231-P3-2802-WS-R-M-3003-1",REQ_DESCR:"75231-P3-2802-WS-R-M-3003-1 (CS DSAWLGS L245 PSL1 Pipe L245)",ROS_ETA_FORECAST:86,ROS_ETA_PLAN:86},
      {MR_NO:"75231-P3-1802-WS-R-M-3003-1",REQ_DESCR:"75231-P3-1802-WS-R-M-3003-1 (CS DSAWLGS L245 PSL1 Pipe L245)",ROS_ETA_FORECAST:86,ROS_ETA_PLAN:86},
      {MR_NO:"75231-P3-1806-WS-R-M-3003-1",REQ_DESCR:"75231-P3-1806-WS-R-M-3003-1 (CS DSAWLGS L245 PSL1 Pipe L245)",ROS_ETA_FORECAST:86,ROS_ETA_PLAN:86},
      {MR_NO:"75231-P3-1810-WS-R-M-3003-0",REQ_DESCR:"75231-P3-1810-WS-R-M-3003-0 (CS DSAWLGS L245 PSL1 Pipe L245)",ROS_ETA_FORECAST:86,ROS_ETA_PLAN:86},
      {MR_NO:"75231-P3-1791-ST-R-M-2301-0",REQ_DESCR:"75231-P3-1791-ST-R-M-2301-0 (PIPE CS SEAMLESS DN 100)",ROS_ETA_FORECAST:88,ROS_ETA_PLAN:88},
      {MR_NO:"75231-P3-1824-EP-R-M-3201",REQ_DESCR:"75231-P3-1824-EP-R-M-3201 (FLANGE MPLP CSSSAS)",ROS_ETA_FORECAST:88,ROS_ETA_PLAN:88},
      {MR_NO:"75231-P3-1824-EP-R-M-3312-0",REQ_DESCR:"75231-P3-1824-EP-R-M-3312-0 (Special ValveNonSTD Gate Valve)",ROS_ETA_FORECAST:88,ROS_ETA_PLAN:88},
      {MR_NO:"75231-P3-3000-ST-R-M-2301-1",REQ_DESCR:"75231-P3-3000-ST-R-M-2301-1 (PIPE CS SEAMLESS DN 100)",ROS_ETA_FORECAST:88,ROS_ETA_PLAN:88},
      {MR_NO:"75231-P3-2810-WS-R-M-3401-1",REQ_DESCR:"75231-P3-2810-WS-R-M-3401-1 (GASKET)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-3000-WS-R-M-2402-6",REQ_DESCR:"75231-P3-3000-WS-R-M-2402-6 (Forged Fitting)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-3000-WS-R-M-3401-4",REQ_DESCR:"75231-P3-3000-WS-R-M-3401-4 (GASKET)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-3000-WS-R-M-3102-6",REQ_DESCR:"75231-P3-3000-WS-R-M-3102-6 (CS Welded Fitting)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-3000-WS-R-M-3201-4",REQ_DESCR:"75231-P3-3000-WS-R-M-3201-4 (FLANGE MPLP CSSSAS)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-3000-WS-R-M-3101-10",REQ_DESCR:"75231-P3-3000-WS-R-M-3101-10 (CS Seamless Fitting)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-3000-WS-R-M-2602-5",REQ_DESCR:"75231-P3-3000-WS-R-M-2602-5 (FLANGE MPLP CSSSAS  DN 300)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-3000-WS-R-M-2501-5",REQ_DESCR:"75231-P3-3000-WS-R-M-2501-5 (GASKET)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-3000-WS-R-M-2505-6",REQ_DESCR:"75231-P3-3000-WS-R-M-2505-6 (Bolts Nuts M33)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-3000-WS-R-M-2704-3",REQ_DESCR:"75231-P3-3000-WS-R-M-2704-3 (VALVE CS CAST PN 600Lb DN 300)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-3000-WS-R-M-3001-11",REQ_DESCR:"75231-P3-3000-WS-R-M-3001-11 (CS SMLS Pipe)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-3000-WS-R-M-3002-4",REQ_DESCR:"75231-P3-3000-WS-R-M-3002-4 (CS SAW SPS Pipe Q235B)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-3000-WS-R-M-3002-5",REQ_DESCR:"75231-P3-3000-WS-R-M-3002-5 (CS SAW SPS Pipe Q235B)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-2810-WS-R-M-2501-2",REQ_DESCR:"75231-P3-2810-WS-R-M-2501-2 (GASKET)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-2810-WS-R-M-3101-3",REQ_DESCR:"75231-P3-2810-WS-R-M-3101-3 (CS Seamless Fitting)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-2810-WS-R-M-3101-4",REQ_DESCR:"75231-P3-2810-WS-R-M-3101-4 (CS Seamless Fitting)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-2810-WS-R-M-3201-1",REQ_DESCR:"75231-P3-2810-WS-R-M-3201-1 (FLANGE MPLP CSSSAS)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-2810-WS-R-M-3102-2",REQ_DESCR:"75231-P3-2810-WS-R-M-3102-2 (CS Welded Fitting)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-2810-WS-R-M-2602-3",REQ_DESCR:"75231-P3-2810-WS-R-M-2602-3 (FLANGE MPLP CSSSAS  DN 300)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-2810-WS-R-M-2505-2",REQ_DESCR:"75231-P3-2810-WS-R-M-2505-2 (Bolts Nuts M33)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-2810-WS-R-M-3001-3",REQ_DESCR:"75231-P3-2810-WS-R-M-3001-3 (CS SMLS Pipe)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-2810-WS-R-M-2704-2",REQ_DESCR:"75231-P3-2810-WS-R-M-2704-2 (VALVE CS CAST PN 600Lb DN 300)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-1810-WS-R-M-3001-2",REQ_DESCR:"75231-P3-1810-WS-R-M-3001-2 (CS SMLS Pipe)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-1810-WS-R-M-3001-3",REQ_DESCR:"75231-P3-1810-WS-R-M-3001-3 (CS SMLS Pipe)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-1810-WS-R-M-3101-3",REQ_DESCR:"75231-P3-1810-WS-R-M-3101-3 (CS Seamless Fitting)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-1810-WS-R-M-3101-4",REQ_DESCR:"75231-P3-1810-WS-R-M-3101-4 (CS Seamless Fitting)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-1810-WS-R-M-3201-1",REQ_DESCR:"75231-P3-1810-WS-R-M-3201-1 (FLANGE MPLP CSSSAS)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-1810-WS-R-M-3102-2",REQ_DESCR:"75231-P3-1810-WS-R-M-3102-2 (CS Welded Fitting)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-1810-WS-R-M-3401-1",REQ_DESCR:"75231-P3-1810-WS-R-M-3401-1 (GASKET)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-1810-WS-R-M-2704-2",REQ_DESCR:"75231-P3-1810-WS-R-M-2704-2 (VALVE CS CAST PN 600Lb DN 300)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-1810-WS-R-M-2602-1",REQ_DESCR:"75231-P3-1810-WS-R-M-2602-1 (FLANGE MPLP CSSSAS  DN 300)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-1810-WS-R-M-2505-1",REQ_DESCR:"75231-P3-1810-WS-R-M-2505-1 (Bolts Nuts M33)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-1810-WS-R-M-2402-1",REQ_DESCR:"75231-P3-1810-WS-R-M-2402-1 (Forged Fitting)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-1810-WS-R-M-2501-1",REQ_DESCR:"75231-P3-1810-WS-R-M-2501-1 (GASKET)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-1806-WS-R-M-3101-1",REQ_DESCR:"75231-P3-1806-WS-R-M-3101-1 (CS Seamless Fitting)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-1806-WS-R-M-3102-2",REQ_DESCR:"75231-P3-1806-WS-R-M-3102-2 (CS Welded Fitting)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-1806-WS-R-M-3201-0",REQ_DESCR:"75231-P3-1806-WS-R-M-3201-0 (FLANGE MPLP CSSSAS)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-1806-WS-R-M-2704-1",REQ_DESCR:"75231-P3-1806-WS-R-M-2704-1 (VALVE CS CAST PN 600Lb DN 300)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-1806-WS-R-M-3001-1",REQ_DESCR:"75231-P3-1806-WS-R-M-3001-1 (CS SMLS Pipe)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-1806-WS-R-M-2602-1",REQ_DESCR:"75231-P3-1806-WS-R-M-2602-1 (FLANGE MPLP CSSSAS  DN 300)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-1806-WS-R-M-2505-1",REQ_DESCR:"75231-P3-1806-WS-R-M-2505-1 (Bolts Nuts M33)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-1806-WS-R-M-2501-1",REQ_DESCR:"75231-P3-1806-WS-R-M-2501-1 (GASKET)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-1806-WS-R-M-2402-0",REQ_DESCR:"75231-P3-1806-WS-R-M-2402-0 (Forged Fitting)",ROS_ETA_FORECAST:89,ROS_ETA_PLAN:89},
      {MR_NO:"75231-P3-1802-IN-R-E-5311",REQ_DESCR:"75231-P3-1802-IN-R-E-5311 (Vortex Flowmeter)",ROS_ETA_FORECAST:90,ROS_ETA_PLAN:90},
      {MR_NO:"75231-P3-1790-IN-R-E-5311",REQ_DESCR:"75231-P3-1790-IN-R-E-5311 (Vortex Flowmeter)",ROS_ETA_FORECAST:90,ROS_ETA_PLAN:90},
      {MR_NO:"75231-P3-1789-IN-R-E-5311",REQ_DESCR:"75231-P3-1789-IN-R-E-5311 (Vortex Flowmeter)",ROS_ETA_FORECAST:90,ROS_ETA_PLAN:90},
      {MR_NO:"75231-P3-1788-IN-R-E-5311",REQ_DESCR:"75231-P3-1788-IN-R-E-5311 (Vortex Flowmeter)",ROS_ETA_FORECAST:90,ROS_ETA_PLAN:90},
      {MR_NO:"75231-P3-1701-IN-R-E-5311",REQ_DESCR:"75231-P3-1701-IN-R-E-5311 (Vortex Flowmeter)",ROS_ETA_FORECAST:90,ROS_ETA_PLAN:90},
      {MR_NO:"75231-P3-2810-WS-R-M-2402-1",REQ_DESCR:"75231-P3-2810-WS-R-M-2402-1 (Forged Fitting)",ROS_ETA_FORECAST:90,ROS_ETA_PLAN:90},
      {MR_NO:"75231-P3-1853-IN-R-E-5311",REQ_DESCR:"75231-P3-1853-IN-R-E-5311 (Vortex Flowmeter)",ROS_ETA_FORECAST:90,ROS_ETA_PLAN:90},
      {MR_NO:"75231-P3-2802-IN-R-E-5311",REQ_DESCR:"75231-P3-2802-IN-R-E-5311 (Vortex Flowmeter)",ROS_ETA_FORECAST:90,ROS_ETA_PLAN:90},
      {MR_NO:"75231-P3-2802-IN-R-E-5312",REQ_DESCR:"75231-P3-2802-IN-R-E-5312 (Magnetic Flowmeter)",ROS_ETA_FORECAST:92,ROS_ETA_PLAN:92},
      {MR_NO:"75231-P3-1853-IN-R-E-5312",REQ_DESCR:"75231-P3-1853-IN-R-E-5312 (Magnetic Flowmeter)",ROS_ETA_FORECAST:92,ROS_ETA_PLAN:92},
      {MR_NO:"75231-P3-1824-IN-R-E-5312",REQ_DESCR:"75231-P3-1824-IN-R-E-5312 (Magnetic Flowmeter)",ROS_ETA_FORECAST:92,ROS_ETA_PLAN:92},
      {MR_NO:"75231-P3-2810-IN-R-E-5312",REQ_DESCR:"75231-P3-2810-IN-R-E-5312 (Magnetic Flowmeter)",ROS_ETA_FORECAST:92,ROS_ETA_PLAN:92},
      {MR_NO:"75231-P3-3810-IN-R-E-5312",REQ_DESCR:"75231-P3-3810-IN-R-E-5312 (Magnetic Flowmeter)",ROS_ETA_FORECAST:92,ROS_ETA_PLAN:92},
      {MR_NO:"75231-P3-3000-ST-R-M-2705",REQ_DESCR:"75231-P3-3000-ST-R-M-2705 (CS CAST VALVESGATEGLOBECHECKPN 6)",ROS_ETA_FORECAST:92,ROS_ETA_PLAN:92},
      {MR_NO:"75231-P3-3000-ST-R-M-2708",REQ_DESCR:"75231-P3-3000-ST-R-M-2708 (LT VALVE)",ROS_ETA_FORECAST:92,ROS_ETA_PLAN:92},
      {MR_NO:"75231-P3-3000-ST-R-M-2711",REQ_DESCR:"75231-P3-3000-ST-R-M-2711 (BUTTERFLY VALVE)",ROS_ETA_FORECAST:92,ROS_ETA_PLAN:92},
      {MR_NO:"75231-P3-3000-ST-R-M-2506",REQ_DESCR:"75231-P3-3000-ST-R-M-2506 (BOLTS NUTS  M33)",ROS_ETA_FORECAST:92,ROS_ETA_PLAN:92,ETA_ACTUAL:"2023-02-02"},
      {MR_NO:"75231-P3-3000-ST-R-M-2604",REQ_DESCR:"75231-P3-3000-ST-R-M-2604 (LT CSSSAS FLANGE)",ROS_ETA_FORECAST:92,ROS_ETA_PLAN:92},
      {MR_NO:"75231-P3-2810-WS-R-M-3401-0",REQ_DESCR:"75231-P3-2810-WS-R-M-3401-0 (GASKET)",ROS_ETA_FORECAST:92,ROS_ETA_PLAN:92,ETA_ACTUAL:"2023-01-14"},
      {MR_NO:"75231-P3-1789-IN-R-E-5312",REQ_DESCR:"75231-P3-1789-IN-R-E-5312 (Magnetic Flowmeter)",ROS_ETA_FORECAST:92,ROS_ETA_PLAN:92},
      {MR_NO:"75231-P3-1802-IN-R-E-5312",REQ_DESCR:"75231-P3-1802-IN-R-E-5312 (Magnetic Flowmeter)",ROS_ETA_FORECAST:92,ROS_ETA_PLAN:92},
      {MR_NO:"75231-P3-1806-IN-R-E-5312",REQ_DESCR:"75231-P3-1806-IN-R-E-5312 (Magnetic Flowmeter)",ROS_ETA_FORECAST:92,ROS_ETA_PLAN:92},
      {MR_NO:"75231-P3-1804-IN-R-E-5312",REQ_DESCR:"75231-P3-1804-IN-R-E-5312 (Magnetic Flowmeter)",ROS_ETA_FORECAST:92,ROS_ETA_PLAN:92},
      {MR_NO:"75231-P3-1802-WS-R-M-2409-0",REQ_DESCR:"75231-P3-1802-WS-R-M-2409-0 (FITTING AS Seamless Welded)",ROS_ETA_FORECAST:92,ROS_ETA_PLAN:92},
      {MR_NO:"75231-P3-1810-WS-R-M-2502-0",REQ_DESCR:"75231-P3-1810-WS-R-M-2502-0 (GASKET)",ROS_ETA_FORECAST:92,ROS_ETA_PLAN:92,ETA_ACTUAL:"2023-01-14"},
      {MR_NO:"75231-P3-1810-IN-R-E-5312",REQ_DESCR:"75231-P3-1810-IN-R-E-5312 (Magnetic Flowmeter)",ROS_ETA_FORECAST:92,ROS_ETA_PLAN:92},
      {MR_NO:"75231-P3-1812-IN-R-E-5312",REQ_DESCR:"75231-P3-1812-IN-R-E-5312 (Magnetic Flowmeter)",ROS_ETA_FORECAST:92,ROS_ETA_PLAN:92},
      {MR_NO:"75231-P3-1810-WS-R-M-3401-0",REQ_DESCR:"75231-P3-1810-WS-R-M-3401-0 (GASKET)",ROS_ETA_FORECAST:92,ROS_ETA_PLAN:92,ETA_ACTUAL:"2023-01-14"},
      {MR_NO:"75231-P3-1791-ST-R-M-2302-0",REQ_DESCR:"75231-P3-1791-ST-R-M-2302-0 (PIPE CS SEAMLESS 150 DN 250)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95},
      {MR_NO:"75231-P3-1791-ST-R-M-2303-0",REQ_DESCR:"75231-P3-1791-ST-R-M-2303-0 (PIPE CS SEAMLESS DN 300)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95},
      {MR_NO:"75231-P3-1791-ST-R-M-2402-0",REQ_DESCR:"75231-P3-1791-ST-R-M-2402-0 (FITTING FORGED)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95},
      {MR_NO:"75231-P3-1791-ST-R-M-2403-0",REQ_DESCR:"75231-P3-1791-ST-R-M-2403-0 (FITTING CS SEAMLESS DN 300)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95,ETA_ACTUAL:"2022-12-27"},
      {MR_NO:"75231-P3-1791-ST-R-M-2404-0",REQ_DESCR:"75231-P3-1791-ST-R-M-2404-0 (FITTING CS SEAMLESS 350 DN 600)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95},
      {MR_NO:"75231-P3-1790-ST-R-M-2315-0",REQ_DESCR:"75231-P3-1790-ST-R-M-2315-0 (PIPE SHP P91 P91)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95},
      {MR_NO:"75231-P3-1790-ST-R-M-2401",REQ_DESCR:"75231-P3-1790-ST-R-M-2401 (FITTING HP CS)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95},
      {MR_NO:"75231-P3-3000-ST-R-M-2309-1",REQ_DESCR:"75231-P3-3000-ST-R-M-2309-1 (PIPE SS SEAMLESS)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95},
      {MR_NO:"75231-P3-3000-ST-R-M-2305-1",REQ_DESCR:"75231-P3-3000-ST-R-M-2305-1 (PIPE CS WELDED DN 1400)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95},
      {MR_NO:"75231-P3-3000-ST-R-M-2306-1",REQ_DESCR:"75231-P3-3000-ST-R-M-2306-1 (PIPE CS WELDED DN1600)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95},
      {MR_NO:"75231-P3-3000-ST-R-M-2307-1",REQ_DESCR:"75231-P3-3000-ST-R-M-2307-1 (PIPE LT CS WELDED)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95},
      {MR_NO:"75231-P3-3000-ST-R-M-2302",REQ_DESCR:"75231-P3-3000-ST-R-M-2302 (PIPE CS SEAMLESS 150 DN 250)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95},
      {MR_NO:"75231-P3-3000-ST-R-M-2302-1",REQ_DESCR:"75231-P3-3000-ST-R-M-2302-1 (PIPE CS SEAMLESS 150 DN 250)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95},
      {MR_NO:"75231-P3-3000-ST-R-M-2303",REQ_DESCR:"75231-P3-3000-ST-R-M-2303 (PIPE CS SEAMLESS DN 300)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95},
      {MR_NO:"75231-P3-3000-ST-R-M-2303-1",REQ_DESCR:"75231-P3-3000-ST-R-M-2303-1 (PIPE CS SEAMLESS DN 300)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95},
      {MR_NO:"75231-P3-3000-ST-R-M-2304",REQ_DESCR:"75231-P3-3000-ST-R-M-2304 (PIPE LTCS SEAMLESS)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95},
      {MR_NO:"75231-P3-3000-ST-R-M-2304-1",REQ_DESCR:"75231-P3-3000-ST-R-M-2304-1 (PIPE LTCS SEAMLESS)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95},
      {MR_NO:"75231-P3-3000-ST-R-M-2310-1",REQ_DESCR:"75231-P3-3000-ST-R-M-2310-1 (PIPE SS WELDED)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95},
      {MR_NO:"75231-P3-3000-ST-R-M-2311",REQ_DESCR:"75231-P3-3000-ST-R-M-2311 (Pipe LTSS Welded)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95},
      {MR_NO:"75231-P3-3000-ST-R-M-2311-1",REQ_DESCR:"75231-P3-3000-ST-R-M-2311-1 (Pipe LTSS Welded)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95},
      {MR_NO:"75231-P3-3000-ST-R-M-2312-1",REQ_DESCR:"75231-P3-3000-ST-R-M-2312-1 (PIPE AS SEAMLESS)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95},
      {MR_NO:"75231-P3-3000-ST-R-M-2315-1",REQ_DESCR:"75231-P3-3000-ST-R-M-2315-1 (PIPE SHP P91 P91)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95},
      {MR_NO:"75231-P3-3000-ST-R-M-2317-1",REQ_DESCR:"75231-P3-3000-ST-R-M-2317-1 (PIPE CS WELDED DN1450 DN1500 DN1)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95},
      {MR_NO:"75231-P3-3000-ST-R-M-2401-1",REQ_DESCR:"75231-P3-3000-ST-R-M-2401-1 (FITTING HP CS)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95},
      {MR_NO:"75231-P3-3000-ST-R-M-2402-1",REQ_DESCR:"75231-P3-3000-ST-R-M-2402-1 (FITTING FORGED)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95,ETA_ACTUAL:"2023-01-30"},
      {MR_NO:"75231-P3-3000-ST-R-M-2403-1",REQ_DESCR:"75231-P3-3000-ST-R-M-2403-1 (FITTING CS SEAMLESS DN 300)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95},
      {MR_NO:"75231-P3-3000-ST-R-M-2404-1",REQ_DESCR:"75231-P3-3000-ST-R-M-2404-1 (FITTING CS SEAMLESS 350 DN 600)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95},
      {MR_NO:"75231-P3-3000-ST-R-M-2405-1",REQ_DESCR:"75231-P3-3000-ST-R-M-2405-1 (FITTING CS WELDED)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95},
      {MR_NO:"75231-P3-3000-ST-R-M-2602-1",REQ_DESCR:"75231-P3-3000-ST-R-M-2602-1 (FLANGE MPLP CSSSAS  DN 300)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95},
      {MR_NO:"75231-P3-3000-ST-R-M-2505-1",REQ_DESCR:"75231-P3-3000-ST-R-M-2505-1 (BOLTS NUTS M33)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95},
      {MR_NO:"75231-P3-3000-ST-R-M-2406-1",REQ_DESCR:"75231-P3-3000-ST-R-M-2406-1 (LT CS FITTING SEAMLESS WELDED)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95},
      {MR_NO:"75231-P3-3000-ST-R-M-2407-1",REQ_DESCR:"75231-P3-3000-ST-R-M-2407-1 (FITTING SS WELDEDSEAMLESS)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95},
      {MR_NO:"75231-P3-3000-ST-R-M-2408-1",REQ_DESCR:"75231-P3-3000-ST-R-M-2408-1 (LT SS FITTING SEAMLESS WELDED)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95},
      {MR_NO:"75231-P3-3000-ST-R-M-2409-1",REQ_DESCR:"75231-P3-3000-ST-R-M-2409-1 (FITTING AS SEAMLESS WELDED)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95},
      {MR_NO:"75231-P3-3000-ST-R-M-2501-1",REQ_DESCR:"75231-P3-3000-ST-R-M-2501-1 (GASKET)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95},
      {MR_NO:"75231-P3-3000-ST-R-M-2703-1",REQ_DESCR:"75231-P3-3000-ST-R-M-2703-1 (FORGED VALVESPN 800Lb PN 800Lb)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95},
      {MR_NO:"75231-P3-3000-ST-R-M-2603-1",REQ_DESCR:"75231-P3-3000-ST-R-M-2603-1 (FLANGE MPLP CSSSAS  350 DN 600)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95},
      {MR_NO:"75231-P3-3000-ST-R-M-2704-1",REQ_DESCR:"75231-P3-3000-ST-R-M-2704-1 (CS CAST VALVESGATEGLOBECHECK PN )",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95},
      {MR_NO:"75231-P3-3000-WS-R-M-3005-3",REQ_DESCR:"75231-P3-3000-WS-R-M-3005-3 (Ductile iron pipe)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95,ETA_ACTUAL:"2023-02-22"},
      {MR_NO:"75231-P3-3000-WS-R-M-2502-0",REQ_DESCR:"75231-P3-3000-WS-R-M-2502-0 (GASKET)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95},
      {MR_NO:"75231-P3-3000-WS-R-M-2602-3",REQ_DESCR:"75231-P3-3000-WS-R-M-2602-3 (FLANGE  DN 300)",ROS_ETA_FORECAST:95,ROS_ETA_PLAN:95,ETA_ACTUAL:"2023-01-18"},
      {MR_NO:"75231-P3-0000-CV-R-M-1010A-0",REQ_DESCR:"75231-P3-0000-CV-R-M-1010A-0 (Steel grid)",ROS_ETA_FORECAST:97,ROS_ETA_PLAN:97,ETA_ACTUAL:"2022-11-02"},
      {MR_NO:"75231-P3-1810-WS-R-M-2501-0",REQ_DESCR:"75231-P3-1810-WS-R-M-2501-0 (GASKET)",ROS_ETA_FORECAST:101,ROS_ETA_PLAN:101,ETA_ACTUAL:"2023-01-14"},
      {MR_NO:"75231-P3-1810-WS-R-M-2505-0",REQ_DESCR:"75231-P3-1810-WS-R-M-2505-0 (Bolts Nuts M33)",ROS_ETA_FORECAST:101,ROS_ETA_PLAN:101},
      {MR_NO:"75231-P3-1802-WS-R-M-2501-0",REQ_DESCR:"75231-P3-1802-WS-R-M-2501-0 (GASKET)",ROS_ETA_FORECAST:101,ROS_ETA_PLAN:101,ETA_ACTUAL:"2023-01-14"},
      {MR_NO:"75231-P3-1802-WS-R-M-2505-0",REQ_DESCR:"75231-P3-1802-WS-R-M-2505-0 (Bolts Nuts M33)",ROS_ETA_FORECAST:101,ROS_ETA_PLAN:101},
      {MR_NO:"75231-P3-1806-WS-R-M-2501-0",REQ_DESCR:"75231-P3-1806-WS-R-M-2501-0 (GASKET)",ROS_ETA_FORECAST:101,ROS_ETA_PLAN:101,ETA_ACTUAL:"2023-01-14"},
      {MR_NO:"75231-P3-1806-WS-R-M-2505-0",REQ_DESCR:"75231-P3-1806-WS-R-M-2505-0 (Bolts Nuts M33)",ROS_ETA_FORECAST:101,ROS_ETA_PLAN:101},
      {MR_NO:"75231-P3-2810-WS-R-M-2501-0",REQ_DESCR:"75231-P3-2810-WS-R-M-2501-0 (GASKET)",ROS_ETA_FORECAST:101,ROS_ETA_PLAN:101,ETA_ACTUAL:"2023-01-14"},
      {MR_NO:"75231-P3-2810-WS-R-M-2505-0",REQ_DESCR:"75231-P3-2810-WS-R-M-2505-0 (Bolts Nuts M33)",ROS_ETA_FORECAST:101,ROS_ETA_PLAN:101},
      {MR_NO:"75231-P3-2802-WS-R-M-2501-0",REQ_DESCR:"75231-P3-2802-WS-R-M-2501-0 (GASKET)",ROS_ETA_FORECAST:101,ROS_ETA_PLAN:101,ETA_ACTUAL:"2023-01-14"},
      {MR_NO:"75231-P3-2802-WS-R-M-2505-0",REQ_DESCR:"75231-P3-2802-WS-R-M-2505-0 (Bolts Nuts M33)",ROS_ETA_FORECAST:101,ROS_ETA_PLAN:101},
      {MR_NO:"75231-P3-3000-ST-R-M-2704",REQ_DESCR:"75231-P3-3000-ST-R-M-2704 (CS CAST VALVESGATEGLOBECHECK PN )",ROS_ETA_FORECAST:104,ROS_ETA_PLAN:104},
      {MR_NO:"75231-P3-3000-ST-R-M-2505",REQ_DESCR:"75231-P3-3000-ST-R-M-2505 (BOLTS NUTS M33)",ROS_ETA_FORECAST:104,ROS_ETA_PLAN:104,ETA_ACTUAL:"2023-02-02"},
      {MR_NO:"75231-P3-3000-ST-R-M-2501",REQ_DESCR:"75231-P3-3000-ST-R-M-2501 (GASKET)",ROS_ETA_FORECAST:104,ROS_ETA_PLAN:104},
      {MR_NO:"75231-P3-3000-ST-R-M-2408",REQ_DESCR:"75231-P3-3000-ST-R-M-2408 (LT SS FITTING SEAMLESS WELDED)",ROS_ETA_FORECAST:104,ROS_ETA_PLAN:104},
      {MR_NO:"75231-P3-3000-ST-R-M-2407",REQ_DESCR:"75231-P3-3000-ST-R-M-2407 (FITTING SS WELDEDSEAMLESS)",ROS_ETA_FORECAST:104,ROS_ETA_PLAN:104},
      {MR_NO:"75231-P3-3000-ST-R-M-2602",REQ_DESCR:"75231-P3-3000-ST-R-M-2602 (FLANGE MPLP CSSSAS  DN 300)",ROS_ETA_FORECAST:104,ROS_ETA_PLAN:104},
      {MR_NO:"75231-P3-3000-ST-R-M-2603",REQ_DESCR:"75231-P3-3000-ST-R-M-2603 (FLANGE MPLP CSSSAS  350 DN 600)",ROS_ETA_FORECAST:104,ROS_ETA_PLAN:104},
      {MR_NO:"75231-P3-3000-ST-R-M-2703",REQ_DESCR:"75231-P3-3000-ST-R-M-2703 (FORGED VALVESPN 800Lb PN 800Lb)",ROS_ETA_FORECAST:104,ROS_ETA_PLAN:104},
      {MR_NO:"75231-P3-3000-ST-R-M-2406",REQ_DESCR:"75231-P3-3000-ST-R-M-2406 (LT CS FITTING SEAMLESS WELDED)",ROS_ETA_FORECAST:104,ROS_ETA_PLAN:104},
      {MR_NO:"75231-P3-3000-ST-R-M-2405",REQ_DESCR:"75231-P3-3000-ST-R-M-2405 (FITTING CS WELDED)",ROS_ETA_FORECAST:104,ROS_ETA_PLAN:104},
      {MR_NO:"75231-P3-3000-ST-R-M-2404",REQ_DESCR:"75231-P3-3000-ST-R-M-2404 (FITTING CS SEAMLESS 350 DN 600)",ROS_ETA_FORECAST:104,ROS_ETA_PLAN:104},
      {MR_NO:"75231-P3-3000-ST-R-M-2403",REQ_DESCR:"75231-P3-3000-ST-R-M-2403 (FITTING CS SEAMLESS DN 300)",ROS_ETA_FORECAST:104,ROS_ETA_PLAN:104,ETA_ACTUAL:"2022-12-22"},
      {MR_NO:"75231-P3-3000-ST-R-M-2402",REQ_DESCR:"75231-P3-3000-ST-R-M-2402 (FITTING FORGED)",ROS_ETA_FORECAST:104,ROS_ETA_PLAN:104},
      {MR_NO:"75231-P3-3000-ST-R-M-2305",REQ_DESCR:"75231-P3-3000-ST-R-M-2305 (PIPE CS WELDED DN 1400)",ROS_ETA_FORECAST:104,ROS_ETA_PLAN:104},
      {MR_NO:"75231-P3-3000-ST-R-M-2309",REQ_DESCR:"75231-P3-3000-ST-R-M-2309 (PIPE SS SEAMLESS)",ROS_ETA_FORECAST:104,ROS_ETA_PLAN:104},
      {MR_NO:"75231-P3-3000-ST-R-M-2310",REQ_DESCR:"75231-P3-3000-ST-R-M-2310 (PIPE SS WELDED)",ROS_ETA_FORECAST:104,ROS_ETA_PLAN:104},
      {MR_NO:"75231-P3-1802-WS-R-M-3005-1",REQ_DESCR:"75231-P3-1802-WS-R-M-3005-1 (Ductile iron pipe)",ROS_ETA_FORECAST:104,ROS_ETA_PLAN:104,ETA_ACTUAL:"2023-02-22"},
      {MR_NO:"75231-P3-1810-WS-R-M-3002-1",REQ_DESCR:"75231-P3-1810-WS-R-M-3002-1 (CS SAWSPS Pipe)",ROS_ETA_FORECAST:105,ROS_ETA_PLAN:105},
      {MR_NO:"75231-P3-2810-WS-R-M-3002-1",REQ_DESCR:"75231-P3-2810-WS-R-M-3002-1 (CS SAWSPS Pipe)",ROS_ETA_FORECAST:105,ROS_ETA_PLAN:105},
      {MR_NO:"75231-P3-1806-IN-R-E-5118",REQ_DESCR:"75231-P3-1806-IN-R-E-5118 (Total Organic Carbon Analyzer)",ROS_ETA_FORECAST:107,ROS_ETA_PLAN:107},
      {MR_NO:"75231-P3-1804-IN-R-E-5118",REQ_DESCR:"75231-P3-1804-IN-R-E-5118 (Total Organic Carbon Analyzer)",ROS_ETA_FORECAST:107,ROS_ETA_PLAN:107},
      {MR_NO:"75231-P3-1700-IN-R-E-5413A-0",REQ_DESCR:"75231-P3-1700-IN-R-E-5413A-0 (RadarTGS System)",ROS_ETA_FORECAST:107,ROS_ETA_PLAN:107},
      {MR_NO:"75231-P3-1789-IN-R-E-5118",REQ_DESCR:"75231-P3-1789-IN-R-E-5118 (Total Organic Carbon Analyzer)",ROS_ETA_FORECAST:107,ROS_ETA_PLAN:107},
      {MR_NO:"75231-P3-1802-WS-R-M-3102-3",REQ_DESCR:"75231-P3-1802-WS-R-M-3102-3 (CS Welded Fitting)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113,ETA_ACTUAL:"2023-02-14"},
      {MR_NO:"75231-P3-1802-WS-R-M-2601-0",REQ_DESCR:"75231-P3-1802-WS-R-M-2601-0 (FLANGE HP CSSSAS 900LB 1500LB250)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113},
      {MR_NO:"75231-P3-1802-WS-R-M-2602-0",REQ_DESCR:"75231-P3-1802-WS-R-M-2602-0 (FLANGE MPLP CSSSAS  DN 300)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113,ETA_ACTUAL:"2023-01-17"},
      {MR_NO:"75231-P3-1802-WS-R-M-2603-0",REQ_DESCR:"75231-P3-1802-WS-R-M-2603-0 (FLANGE MPLP CSSSAS  350 DN 600)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113},
      {MR_NO:"75231-P3-1802-WS-R-M-2703-0",REQ_DESCR:"75231-P3-1802-WS-R-M-2703-0 (FORGED VALVESPN 800LbPN 800Lb)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113},
      {MR_NO:"75231-P3-1806-WS-R-M-2601-0",REQ_DESCR:"75231-P3-1806-WS-R-M-2601-0 (FLANGE HP CSSSAS 900LB 1500LB250)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113},
      {MR_NO:"75231-P3-1806-WS-R-M-2603-0",REQ_DESCR:"75231-P3-1806-WS-R-M-2603-0 (FLANGE MPLP CSSSAS  350 DN 600)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113},
      {MR_NO:"75231-P3-1806-WS-R-M-2602-0",REQ_DESCR:"75231-P3-1806-WS-R-M-2602-0 (FLANGE MPLP CSSSAS  DN 300)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113,ETA_ACTUAL:"2023-01-17"},
      {MR_NO:"75231-P3-1806-WS-R-M-3001-0",REQ_DESCR:"75231-P3-1806-WS-R-M-3001-0 (CS SMLS Pipe)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113,ETA_ACTUAL:"2023-02-12"},
      {MR_NO:"75231-P3-1806-WS-R-M-2705-0",REQ_DESCR:"75231-P3-1806-WS-R-M-2705-0 (CS CAST VALVESGATEGLOBECHECKPN 6)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113},
      {MR_NO:"75231-P3-1806-WS-R-M-2704-0",REQ_DESCR:"75231-P3-1806-WS-R-M-2704-0 (CS CAST VALVESGATEGLOBECHECK PN )",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113},
      {MR_NO:"75231-P3-1806-WS-R-M-3101-0",REQ_DESCR:"75231-P3-1806-WS-R-M-3101-0 (CS Seamless Fitting)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113,ETA_ACTUAL:"2023-01-12"},
      {MR_NO:"75231-P3-1810-WS-R-M-3101-1",REQ_DESCR:"75231-P3-1810-WS-R-M-3101-1 (CS Seamless Fitting)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113,ETA_ACTUAL:"2023-01-13"},
      {MR_NO:"75231-P3-1810-WS-R-M-3005-1",REQ_DESCR:"75231-P3-1810-WS-R-M-3005-1 (Ductile iron pipe)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113,ETA_ACTUAL:"2023-02-22"},
      {MR_NO:"75231-P3-1810-WS-R-M-3102-1",REQ_DESCR:"75231-P3-1810-WS-R-M-3102-1 (CS Welded Fitting)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113,ETA_ACTUAL:"2023-02-14"},
      {MR_NO:"75231-P3-1810-WS-R-M-2601-0",REQ_DESCR:"75231-P3-1810-WS-R-M-2601-0 (FLANGE HP CSSSAS 900LB 1500LB250)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113},
      {MR_NO:"75231-P3-1806-WS-R-M-3102-1",REQ_DESCR:"75231-P3-1806-WS-R-M-3102-1 (CS Welded Fitting)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113,ETA_ACTUAL:"2023-02-14"},
      {MR_NO:"75231-P3-1810-WS-R-M-2603-0",REQ_DESCR:"75231-P3-1810-WS-R-M-2603-0 (FLANGE MPLP CSSSAS  350 DN 600)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113},
      {MR_NO:"75231-P3-1810-WS-R-M-2602-0",REQ_DESCR:"75231-P3-1810-WS-R-M-2602-0 (FLANGE MPLP CSSSAS  DN 300)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113,ETA_ACTUAL:"2023-01-17"},
      {MR_NO:"75231-P3-1810-WS-R-M-2703-0",REQ_DESCR:"75231-P3-1810-WS-R-M-2703-0 (FORGED VALVESPN 800LbPN 800Lb)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113},
      {MR_NO:"75231-P3-1810-WS-R-M-2704-0",REQ_DESCR:"75231-P3-1810-WS-R-M-2704-0 (CS CAST VALVESGATEGLOBECHECK PN )",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113},
      {MR_NO:"75231-P3-1810-WS-R-M-2705-0",REQ_DESCR:"75231-P3-1810-WS-R-M-2705-0 (CS CAST VALVESGATEGLOBECHECKPN 6)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113},
      {MR_NO:"75231-P3-1810-WS-R-M-2707-0",REQ_DESCR:"75231-P3-1810-WS-R-M-2707-0 (LARGER SIZE CAST VALVESGATEGLOBE)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113,ETA_ACTUAL:"2022-11-02"},
      {MR_NO:"75231-P3-1810-WS-R-M-3001-1",REQ_DESCR:"75231-P3-1810-WS-R-M-3001-1 (CS SMLS Pipe)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113,ETA_ACTUAL:"2023-01-02"},
      {MR_NO:"75231-P3-2810-WS-R-M-3101-1",REQ_DESCR:"75231-P3-2810-WS-R-M-3101-1 (CS Seamless Fitting)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113,ETA_ACTUAL:"2023-01-12"},
      {MR_NO:"75231-P3-2810-WS-R-M-3102-1",REQ_DESCR:"75231-P3-2810-WS-R-M-3102-1 (CS Welded Fitting)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113,ETA_ACTUAL:"2023-02-14"},
      {MR_NO:"75231-P3-2810-WS-R-M-3001-1",REQ_DESCR:"75231-P3-2810-WS-R-M-3001-1 (CS SMLS Pipe)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113,ETA_ACTUAL:"2023-02-01"},
      {MR_NO:"75231-P3-2810-WS-R-M-2601-0",REQ_DESCR:"75231-P3-2810-WS-R-M-2601-0 (FLANGE HP CSSSAS 900LB 1500LB250)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113},
      {MR_NO:"75231-P3-2810-WS-R-M-2602-0",REQ_DESCR:"75231-P3-2810-WS-R-M-2602-0 (FLANGE MPLP CSSSAS  DN 300)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113,ETA_ACTUAL:"2023-01-17"},
      {MR_NO:"75231-P3-2810-WS-R-M-2603-0",REQ_DESCR:"75231-P3-2810-WS-R-M-2603-0 (FLANGE MPLP CSSSAS  350 DN 600)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113},
      {MR_NO:"75231-P3-2810-WS-R-M-2704-0",REQ_DESCR:"75231-P3-2810-WS-R-M-2704-0 (CS CAST VALVESGATEGLOBECHECK PN )",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113},
      {MR_NO:"75231-P3-2802-WS-R-M-2601-0",REQ_DESCR:"75231-P3-2802-WS-R-M-2601-0 (FLANGE HP CSSSAS 900LB 1500LB250)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113},
      {MR_NO:"75231-P3-2802-WS-R-M-2602-0",REQ_DESCR:"75231-P3-2802-WS-R-M-2602-0 (FLANGE MPLP CSSSAS  DN 300)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113,ETA_ACTUAL:"2023-01-17"},
      {MR_NO:"75231-P3-2802-WS-R-M-2603-0",REQ_DESCR:"75231-P3-2802-WS-R-M-2603-0 (FLANGE MPLP CSSSAS  350 DN 600)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113},
      {MR_NO:"75231-P3-2802-WS-R-M-2703-0",REQ_DESCR:"75231-P3-2802-WS-R-M-2703-0 (FORGED VALVESPN 800LbPN 800Lb)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113},
      {MR_NO:"75231-P3-2802-WS-R-M-3001-0",REQ_DESCR:"75231-P3-2802-WS-R-M-3001-0 (CS SMLS Pipe)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113,ETA_ACTUAL:"2023-02-01"},
      {MR_NO:"75231-P3-2802-WS-R-M-3005-1",REQ_DESCR:"75231-P3-2802-WS-R-M-3005-1 (Ductile iron pipe)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113,ETA_ACTUAL:"2023-02-22"},
      {MR_NO:"75231-P3-2802-WS-R-M-3101-0",REQ_DESCR:"75231-P3-2802-WS-R-M-3101-0 (CS Seamless Fitting)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113,ETA_ACTUAL:"2023-01-12"},
      {MR_NO:"75231-P3-2802-WS-R-M-3102-3",REQ_DESCR:"75231-P3-2802-WS-R-M-3102-3 (CS Welded Fitting)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113,ETA_ACTUAL:"2023-02-14"},
      {MR_NO:"75231-P3-1824-EP-R-M-2505-1",REQ_DESCR:"75231-P3-1824-EP-R-M-2505-1 (Bolts Nuts M33)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113},
      {MR_NO:"75231-P3-1824-EP-R-M-2501-1",REQ_DESCR:"75231-P3-1824-EP-R-M-2501-1 (GASKET)",ROS_ETA_FORECAST:113,ROS_ETA_PLAN:113},
      {MR_NO:"75231-P3-1510-WS-R-M-2704",REQ_DESCR:"75231-P3-1510-WS-R-M-2704 (CS CAST VALVESGATEGLOBECHECK PN )",ROS_ETA_FORECAST:122,ROS_ETA_PLAN:122},
      {MR_NO:"75231-P3-1824-EP-R-M-2501",REQ_DESCR:"75231-P3-1824-EP-R-M-2501 (GASKET)",ROS_ETA_FORECAST:124,ROS_ETA_PLAN:124},
      {MR_NO:"75231-P3-1824-EP-R-M-2505",REQ_DESCR:"75231-P3-1824-EP-R-M-2505 (Bolts Nuts M33)",ROS_ETA_FORECAST:124,ROS_ETA_PLAN:124,ETA_ACTUAL:"2023-02-02"},
      {MR_NO:"75231-P3-3000-WS-R-M-2501-0",REQ_DESCR:"75231-P3-3000-WS-R-M-2501-0 (GASKET)",ROS_ETA_FORECAST:124,ROS_ETA_PLAN:124},
      {MR_NO:"75231-P3-3000-WS-R-M-2501-1",REQ_DESCR:"75231-P3-3000-WS-R-M-2501-1 (GASKET)",ROS_ETA_FORECAST:124,ROS_ETA_PLAN:124},
      {MR_NO:"75231-P3-3000-WS-R-M-2501-2",REQ_DESCR:"75231-P3-3000-WS-R-M-2501-2 (GASKET)",ROS_ETA_FORECAST:124,ROS_ETA_PLAN:124},
      {MR_NO:"75231-P3-3000-WS-R-M-2505-0",REQ_DESCR:"75231-P3-3000-WS-R-M-2505-0 (Bolts Nuts M33)",ROS_ETA_FORECAST:124,ROS_ETA_PLAN:124,ETA_ACTUAL:"2023-02-02"},
      {MR_NO:"75231-P3-3000-WS-R-M-2505-1",REQ_DESCR:"75231-P3-3000-WS-R-M-2505-1 (Bolts Nuts M33)",ROS_ETA_FORECAST:124,ROS_ETA_PLAN:124,ETA_ACTUAL:"2023-02-02"},
      {MR_NO:"75231-P3-3000-WS-R-M-2505-2",REQ_DESCR:"75231-P3-3000-WS-R-M-2505-2 (Bolts Nuts M33)",ROS_ETA_FORECAST:124,ROS_ETA_PLAN:124,ETA_ACTUAL:"2023-02-02"},
      {MR_NO:"75231-P3-3000-WS-R-M-2505-3",REQ_DESCR:"75231-P3-3000-WS-R-M-2505-3 (Bolts Nuts M33)",ROS_ETA_FORECAST:124,ROS_ETA_PLAN:124,ETA_ACTUAL:"2023-02-02"},
      {MR_NO:"75231-P3-3000-WS-R-M-2601-0",REQ_DESCR:"75231-P3-3000-WS-R-M-2601-0 (FLANGE HP CSSSAS 900LB 1500LB250)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-3000-WS-R-M-2703-0",REQ_DESCR:"75231-P3-3000-WS-R-M-2703-0 (FORGED VALVESPN 800LbPN 800Lb)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-3000-WS-R-M-2703-1",REQ_DESCR:"75231-P3-3000-WS-R-M-2703-1 (FORGED VALVESPN 800LbPN 800Lb)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-3000-WS-R-M-2705-0",REQ_DESCR:"75231-P3-3000-WS-R-M-2705-0 (CS CAST VALVESGATEGLOBECHECKPN 6)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-3000-WS-R-M-2707-0",REQ_DESCR:"75231-P3-3000-WS-R-M-2707-0 (LARGER SIZE CAST VALVESGATEGLOBE)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-3810-IN-R-E-5511-0",REQ_DESCR:"75231-P3-3810-IN-R-E-5511-0 (PressureDiff Pressure Transmitte)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-3771-IN-R-E-5313-0",REQ_DESCR:"75231-P3-3771-IN-R-E-5313-0 (Coriolis Mass Flowmeter)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-3701-IN-R-E-5313-0",REQ_DESCR:"75231-P3-3701-IN-R-E-5313-0 (Coriolis Mass Flowmeter)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-1824-EP-R-M-3001-1",REQ_DESCR:"75231-P3-1824-EP-R-M-3001-1 (CS SMLS Pipe)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125,ETA_ACTUAL:"2022-12-18"},
      {MR_NO:"75231-P3-1824-EP-R-M-3005-1",REQ_DESCR:"75231-P3-1824-EP-R-M-3005-1 (Ductile iron pipe)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125,ETA_ACTUAL:"2023-02-22"},
      {MR_NO:"75231-P3-1824-EP-R-M-3101-1",REQ_DESCR:"75231-P3-1824-EP-R-M-3101-1 (CS Seamless Fitting)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125,ETA_ACTUAL:"2023-01-12"},
      {MR_NO:"75231-P3-1824-EP-R-M-3102-0",REQ_DESCR:"75231-P3-1824-EP-R-M-3102-0 (CS Welded Fitting)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125,ETA_ACTUAL:"2022-12-21"},
      {MR_NO:"75231-P3-1824-EP-R-M-2602-0",REQ_DESCR:"75231-P3-1824-EP-R-M-2602-0 (FLANGE MPLP CSSSAS  DN 300)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125,ETA_ACTUAL:"2023-02-12"},
      {MR_NO:"75231-P3-1824-EP-R-M-2603-0",REQ_DESCR:"75231-P3-1824-EP-R-M-2603-0 (FLANGE MPLP CSSSAS  350 DN 600)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-1824-EP-R-M-2703-0",REQ_DESCR:"75231-P3-1824-EP-R-M-2703-0 (FORGED VALVESPN 800LbPN 800Lb)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-1824-EP-R-M-2703-1",REQ_DESCR:"75231-P3-1824-EP-R-M-2703-1 (FORGED VALVESPN 800LbPN 800Lb)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-1824-EP-R-M-2704-0",REQ_DESCR:"75231-P3-1824-EP-R-M-2704-0 (CS CAST VALVESGATEGLOBECHECK PN )",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-1824-EP-R-M-2705-0",REQ_DESCR:"75231-P3-1824-EP-R-M-2705-0 (CS CAST VALVESGATEGLOBECHECKPN 6)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-1824-EP-R-M-2706-0",REQ_DESCR:"75231-P3-1824-EP-R-M-2706-0 (ALLOY AND SS CAST VALVESGATEGLOB)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-1824-EP-R-M-2710-0",REQ_DESCR:"75231-P3-1824-EP-R-M-2710-0 (BALL VALVE)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-1824-EP-R-M-3312-1",REQ_DESCR:"75231-P3-1824-EP-R-M-3312-1 (Special ValveNonSTD Gate Valve)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125,ETA_ACTUAL:"2023-02-12"},
      {MR_NO:"75231-P3-1824-EP-R-M-3201-1",REQ_DESCR:"75231-P3-1824-EP-R-M-3201-1 (FLANGE MPLP CSSSAS)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-2802-IN-R-E-5413-0",REQ_DESCR:"75231-P3-2802-IN-R-E-5413-0 (Radar Level Instrument)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-2802-IN-R-E-5511-0",REQ_DESCR:"75231-P3-2802-IN-R-E-5511-0 (PressureDiff Pressure Transmitte)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-2802-IN-R-E-5612-0",REQ_DESCR:"75231-P3-2802-IN-R-E-5612-0 (Separated Temperature Transmitte)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-2771-IN-R-E-5313-0",REQ_DESCR:"75231-P3-2771-IN-R-E-5313-0 (Coriolis Mass Flowmeter)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-2810-IN-R-E-5413-0",REQ_DESCR:"75231-P3-2810-IN-R-E-5413-0 (Radar Level Instrument)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-2810-IN-R-E-5511-0",REQ_DESCR:"75231-P3-2810-IN-R-E-5511-0 (PressureDiff Pressure Transmitte)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-1824-EP-R-M-2407-0",REQ_DESCR:"75231-P3-1824-EP-R-M-2407-0 (FITTING SS WELDEDSEAMLESS)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-1824-EP-R-M-2309-0",REQ_DESCR:"75231-P3-1824-EP-R-M-2309-0 (PIPE SS SEAMLESS)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-1824-EP-R-M-2310-0",REQ_DESCR:"75231-P3-1824-EP-R-M-2310-0 (PIPE SS WELDED)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-1812-IN-R-E-5413-0",REQ_DESCR:"75231-P3-1812-IN-R-E-5413-0 (Radar Level Instrument)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-1812-IN-R-E-5511-0",REQ_DESCR:"75231-P3-1812-IN-R-E-5511-0 (PressureDiff Pressure Transmitte)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-1810-IN-R-E-5413-0",REQ_DESCR:"75231-P3-1810-IN-R-E-5413-0 (Radar Level Instrument)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-1810-IN-R-E-5511-0",REQ_DESCR:"75231-P3-1810-IN-R-E-5511-0 (PressureDiff Pressure Transmitte)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-1806-IN-R-E-5413-0",REQ_DESCR:"75231-P3-1806-IN-R-E-5413-0 (Radar Level Instrument)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-1806-IN-R-E-5511-0",REQ_DESCR:"75231-P3-1806-IN-R-E-5511-0 (PressureDiff Pressure Transmitte)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-1804-IN-R-E-5413-0",REQ_DESCR:"75231-P3-1804-IN-R-E-5413-0 (Radar Level Instrument)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-1804-IN-R-E-5511-0",REQ_DESCR:"75231-P3-1804-IN-R-E-5511-0 (PressureDiff Pressure Transmitte)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-1680-IN-R-E-5313-0",REQ_DESCR:"75231-P3-1680-IN-R-E-5313-0 (Coriolis Mass Flowmeter)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-1790-IN-R-E-5313-0",REQ_DESCR:"75231-P3-1790-IN-R-E-5313-0 (Coriolis Mass Flowmeter)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-1802-IN-R-E-5413-0",REQ_DESCR:"75231-P3-1802-IN-R-E-5413-0 (Radar Level Instrument)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-1802-IN-R-E-5511-0",REQ_DESCR:"75231-P3-1802-IN-R-E-5511-0 (PressureDiff Pressure Transmitte)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-1802-IN-R-E-5612-0",REQ_DESCR:"75231-P3-1802-IN-R-E-5612-0 (Separated Temperature Transmitte)",ROS_ETA_FORECAST:125,ROS_ETA_PLAN:125},
      {MR_NO:"75231-P3-1520-TC-R-E-6002-1",REQ_DESCR:"75231-P3-1520-TC-R-E-6002-1 (Security system)",ROS_ETA_FORECAST:126,ROS_ETA_PLAN:126},
      {MR_NO:"75231-P3-1522-TC-R-E-6002-1",REQ_DESCR:"75231-P3-1522-TC-R-E-6002-1 (Security system)",ROS_ETA_FORECAST:126,ROS_ETA_PLAN:126},
      {MR_NO:"75231-P3-3000-WS-R-M-3101-9",REQ_DESCR:"75231-P3-3000-WS-R-M-3101-9 (CS Seamless Fitting)",ROS_ETA_FORECAST:126,ROS_ETA_PLAN:126},
      {MR_NO:"75231-P3-3000-WS-R-M-3001-10",REQ_DESCR:"75231-P3-3000-WS-R-M-3001-10 (CS Seamless Pipe)",ROS_ETA_FORECAST:126,ROS_ETA_PLAN:126},
      {MR_NO:"75231-P3-3000-WS-R-M-2505-5",REQ_DESCR:"75231-P3-3000-WS-R-M-2505-5 (Bolts Nuts M33)",ROS_ETA_FORECAST:126,ROS_ETA_PLAN:126},
      {MR_NO:"75231-P3-3000-WS-R-M-2402-5",REQ_DESCR:"75231-P3-3000-WS-R-M-2402-5 (Forged Fitting)",ROS_ETA_FORECAST:126,ROS_ETA_PLAN:126},
      {MR_NO:"75231-P3-3000-WS-R-M-2309-0",REQ_DESCR:"75231-P3-3000-WS-R-M-2309-0 (PIPE SS SEAMLESS)",ROS_ETA_FORECAST:126,ROS_ETA_PLAN:126},
      {MR_NO:"75231-P3-3000-WS-R-M-2501-4",REQ_DESCR:"75231-P3-3000-WS-R-M-2501-4 (GASKET)",ROS_ETA_FORECAST:127,ROS_ETA_PLAN:127,ETA_ACTUAL:"2023-01-13"},
      {MR_NO:"75231-P3-3000-WS-R-M-2703-3",REQ_DESCR:"75231-P3-3000-WS-R-M-2703-3 (Forged Valve)",ROS_ETA_FORECAST:127,ROS_ETA_PLAN:127,ETA_ACTUAL:"2023-01-13"},
      {MR_NO:"75231-P3-3000-WS-R-M-3002-3",REQ_DESCR:"75231-P3-3000-WS-R-M-3002-3 (CS SAWSPS Pipe)",ROS_ETA_FORECAST:127,ROS_ETA_PLAN:127},
      {MR_NO:"75231-P3-3000-WS-R-M-2704-2",REQ_DESCR:"75231-P3-3000-WS-R-M-2704-2 (Cast Valve CS)",ROS_ETA_FORECAST:127,ROS_ETA_PLAN:127,ETA_ACTUAL:"2023-01-13"},
      {MR_NO:"75231-P3-3000-WS-R-M-3201-3",REQ_DESCR:"75231-P3-3000-WS-R-M-3201-3 (FLANGE MPLP CSSSAS)",ROS_ETA_FORECAST:127,ROS_ETA_PLAN:127,ETA_ACTUAL:"2023-01-13"},
      {MR_NO:"75231-P3-3000-WS-R-M-3312-3",REQ_DESCR:"75231-P3-3000-WS-R-M-3312-3 (Special ValveNonSTD Gate Valve)",ROS_ETA_FORECAST:127,ROS_ETA_PLAN:127,ETA_ACTUAL:"2023-01-13"},
      {MR_NO:"75231-P3-3000-WS-R-M-3401-3",REQ_DESCR:"75231-P3-3000-WS-R-M-3401-3 (GASKET)",ROS_ETA_FORECAST:127,ROS_ETA_PLAN:127,ETA_ACTUAL:"2023-01-13"},
      {MR_NO:"75231-P3-2802-WS-R-M-2402-1",REQ_DESCR:"75231-P3-2802-WS-R-M-2402-1 (Forged Fitting)",ROS_ETA_FORECAST:127,ROS_ETA_PLAN:127,ETA_ACTUAL:"2023-01-13"},
      {MR_NO:"75231-P3-1540-TC-R-E-6002-0",REQ_DESCR:"75231-P3-1540-TC-R-E-6002-0 (Security system)",ROS_ETA_FORECAST:127,ROS_ETA_PLAN:127},
      {MR_NO:"75231-P3-0000-CV-R-M-1020",REQ_DESCR:"75231-P3-0000-CV-R-M-1020 (POWER ACTUATED STUD FASTENER WIT)",ROS_ETA_FORECAST:127,ROS_ETA_PLAN:127},
      {MR_NO:"75231-P3-0000-CV-R-M-1000A-0",REQ_DESCR:"75231-P3-0000-CV-R-M-1000A-0 (Steel Structure)",ROS_ETA_FORECAST:127,ROS_ETA_PLAN:127},
      {MR_NO:"75231-P3-0000-CV-R-M-1000C-0",REQ_DESCR:"75231-P3-0000-CV-R-M-1000C-0 (Steel Structure)",ROS_ETA_FORECAST:127,ROS_ETA_PLAN:127},
      {MR_NO:"75231-P3-0000-CV-R-M-1000D-0",REQ_DESCR:"75231-P3-0000-CV-R-M-1000D-0 (Steel Structure)",ROS_ETA_FORECAST:127,ROS_ETA_PLAN:127},
      {MR_NO:"75231-P3-0000-CV-R-M-1000E-0",REQ_DESCR:"75231-P3-0000-CV-R-M-1000E-0 (Steel Structure)",ROS_ETA_FORECAST:127,ROS_ETA_PLAN:127,ETA_ACTUAL:"2022-11-02"},
      {MR_NO:"75231-P3-1824-EP-R-M-2402-2",REQ_DESCR:"75231-P3-1824-EP-R-M-2402-2 (Forged Fitting)",ROS_ETA_FORECAST:127,ROS_ETA_PLAN:127,ETA_ACTUAL:"2023-01-13"},
      {MR_NO:"75231-P3-3000-WS-R-M-3102-3",REQ_DESCR:"75231-P3-3000-WS-R-M-3102-3 (CS Welded Fitting)",ROS_ETA_FORECAST:135,ROS_ETA_PLAN:135},
      {MR_NO:"75231-P3-3000-WS-R-M-2602-2",REQ_DESCR:"75231-P3-3000-WS-R-M-2602-2 (FLANGE MPLP CSSSAS  DN 300)",ROS_ETA_FORECAST:135,ROS_ETA_PLAN:135,ETA_ACTUAL:"2022-11-08"},
      {MR_NO:"75231-P3-1824-TC-R-E-6002-0",REQ_DESCR:"75231-P3-1824-TC-R-E-6002-0 (Security system)",ROS_ETA_FORECAST:140,ROS_ETA_PLAN:140},
      {MR_NO:"75231-P3-2802-WS-R-M-3311-0",REQ_DESCR:"75231-P3-2802-WS-R-M-3311-0 (Special ValveMOV)",ROS_ETA_FORECAST:140,ROS_ETA_PLAN:140},
      {MR_NO:"75231-P3-2810-WS-R-M-3311-0",REQ_DESCR:"75231-P3-2810-WS-R-M-3311-0 (Special ValveMOV)",ROS_ETA_FORECAST:140,ROS_ETA_PLAN:140},
      {MR_NO:"75231-P3-1810-WS-R-M-3311-0",REQ_DESCR:"75231-P3-1810-WS-R-M-3311-0 (Special ValveMOV)",ROS_ETA_FORECAST:140,ROS_ETA_PLAN:140},
      {MR_NO:"75231-P3-1802-WS-R-M-3311-0",REQ_DESCR:"75231-P3-1802-WS-R-M-3311-0 (Special ValveMOV)",ROS_ETA_FORECAST:140,ROS_ETA_PLAN:140},
      {MR_NO:"75231-P3-3000-WS-R-M-3201",REQ_DESCR:"75231-P3-3000-WS-R-M-3201 (FLANGE MPLP CSSSAS)",ROS_ETA_FORECAST:143,ROS_ETA_PLAN:143},
      {MR_NO:"75231-P3-3000-WS-R-M-2704-1",REQ_DESCR:"75231-P3-3000-WS-R-M-2704-1 (Cast Valve CS)",ROS_ETA_FORECAST:146,ROS_ETA_PLAN:146},
      {MR_NO:"75231-P3-2810-WS-R-M-2704-1",REQ_DESCR:"75231-P3-2810-WS-R-M-2704-1 (Cast Valve CS)",ROS_ETA_FORECAST:146,ROS_ETA_PLAN:146},
      {MR_NO:"75231-P3-2802-WS-R-M-2704-0",REQ_DESCR:"75231-P3-2802-WS-R-M-2704-0 (Cast Valve CS)",ROS_ETA_FORECAST:146,ROS_ETA_PLAN:146},
      {MR_NO:"75231-P3-1802-WS-R-M-2704-0",REQ_DESCR:"75231-P3-1802-WS-R-M-2704-0 (Cast Valve CS)",ROS_ETA_FORECAST:146,ROS_ETA_PLAN:146},
      {MR_NO:"75231-P3-1810-WS-R-M-2704-1",REQ_DESCR:"75231-P3-1810-WS-R-M-2704-1 (Cast Valve CS)",ROS_ETA_FORECAST:146,ROS_ETA_PLAN:146},
      {MR_NO:"75231-P3-1523-TC-R-E-6002-0",REQ_DESCR:"75231-P3-1523-TC-R-E-6002-0 (Security system)",ROS_ETA_FORECAST:146,ROS_ETA_PLAN:146},
      {MR_NO:"75231-P3-1700-TC-R-E-6002-0",REQ_DESCR:"75231-P3-1700-TC-R-E-6002-0 (Security system)",ROS_ETA_FORECAST:146,ROS_ETA_PLAN:146},
      {MR_NO:"75231-P3-1812-TC-R-E-6002-0",REQ_DESCR:"75231-P3-1812-TC-R-E-6002-0 (Security system)",ROS_ETA_FORECAST:153,ROS_ETA_PLAN:153},
      {MR_NO:"75231-P3-1802-TC-R-E-6002-0",REQ_DESCR:"75231-P3-1802-TC-R-E-6002-0 (Security system)",ROS_ETA_FORECAST:153,ROS_ETA_PLAN:153},
      {MR_NO:"75231-P3-1831-TC-R-E-6002-0",REQ_DESCR:"75231-P3-1831-TC-R-E-6002-0 (Security system)",ROS_ETA_FORECAST:153,ROS_ETA_PLAN:153},
      {MR_NO:"75231-P3-1853-TC-R-E-6002-0",REQ_DESCR:"75231-P3-1853-TC-R-E-6002-0 (Security system)",ROS_ETA_FORECAST:153,ROS_ETA_PLAN:153},
      {MR_NO:"75231-P3-1860-TC-R-E-6002-0",REQ_DESCR:"75231-P3-1860-TC-R-E-6002-0 (Security system)",ROS_ETA_FORECAST:153,ROS_ETA_PLAN:153},
      {MR_NO:"75231-P3-2802-TC-R-E-6002-0",REQ_DESCR:"75231-P3-2802-TC-R-E-6002-0 (Security system)",ROS_ETA_FORECAST:153,ROS_ETA_PLAN:153},
      {MR_NO:"75231-P3-2770-TC-R-E-6002-0",REQ_DESCR:"75231-P3-2770-TC-R-E-6002-0 (Security system)",ROS_ETA_FORECAST:153,ROS_ETA_PLAN:153},
      {MR_NO:"75231-P3-1510-WS-R-M-3312",REQ_DESCR:"75231-P3-1510-WS-R-M-3312 (Special ValveNonSTD Gate Valve)",ROS_ETA_FORECAST:154,ROS_ETA_PLAN:154},
      {MR_NO:"75231-P3-3000-WS-R-M-3001-8",REQ_DESCR:"75231-P3-3000-WS-R-M-3001-8 (CS Seamless Pipe)",ROS_ETA_FORECAST:155,ROS_ETA_PLAN:155,ETA_ACTUAL:"2023-01-13"},
      {MR_NO:"75231-P3-3000-WS-R-M-3101-8",REQ_DESCR:"75231-P3-3000-WS-R-M-3101-8 (CS Seamless Fitting)",ROS_ETA_FORECAST:155,ROS_ETA_PLAN:155,ETA_ACTUAL:"2023-01-13"},
      {MR_NO:"75231-P3-3000-WS-R-M-2402-4",REQ_DESCR:"75231-P3-3000-WS-R-M-2402-4 (Forged Fitting)",ROS_ETA_FORECAST:155,ROS_ETA_PLAN:155,ETA_ACTUAL:"2023-01-13"},
      {MR_NO:"75231-P3-2810-WS-R-M-2711-1",REQ_DESCR:"75231-P3-2810-WS-R-M-2711-1 (Butterfly Valve)",ROS_ETA_FORECAST:156,ROS_ETA_PLAN:156},
      {MR_NO:"75231-P3-2810-WS-R-M-3312-1",REQ_DESCR:"75231-P3-2810-WS-R-M-3312-1 (Special ValveNonSTD Gate Valve)",ROS_ETA_FORECAST:156,ROS_ETA_PLAN:156},
      {MR_NO:"75231-P3-2810-WS-R-M-3003-1",REQ_DESCR:"75231-P3-2810-WS-R-M-3003-1 (CS DSAWLGS L245 PSL1 Pipe L245)",ROS_ETA_FORECAST:156,ROS_ETA_PLAN:156},
      {MR_NO:"75231-P3-1806-WS-R-M-3003-2",REQ_DESCR:"75231-P3-1806-WS-R-M-3003-2 (CS DSAWLGS L245 PSL1 Pipe L245)",ROS_ETA_FORECAST:156,ROS_ETA_PLAN:156},
      {MR_NO:"75231-P3-1806-WS-R-M-2711-1",REQ_DESCR:"75231-P3-1806-WS-R-M-2711-1 (Butterfly Valve)",ROS_ETA_FORECAST:156,ROS_ETA_PLAN:156},
      {MR_NO:"75231-P3-1810-WS-R-M-3312-1",REQ_DESCR:"75231-P3-1810-WS-R-M-3312-1 (Special ValveNonSTD Gate Valve)",ROS_ETA_FORECAST:156,ROS_ETA_PLAN:156},
      {MR_NO:"75231-P3-1810-WS-R-M-3003-1",REQ_DESCR:"75231-P3-1810-WS-R-M-3003-1 (CS DSAWLGS L245 PSL1 Pipe L245)",ROS_ETA_FORECAST:156,ROS_ETA_PLAN:156},
      {MR_NO:"75231-P3-1810-WS-R-M-2711-1",REQ_DESCR:"75231-P3-1810-WS-R-M-2711-1 (Butterfly Valve)",ROS_ETA_FORECAST:156,ROS_ETA_PLAN:156},
      {MR_NO:"75231-P3-3000-WS-R-M-2505-4",REQ_DESCR:"75231-P3-3000-WS-R-M-2505-4 (Bolts Nuts M33)",ROS_ETA_FORECAST:165,ROS_ETA_PLAN:165},
      {MR_NO:"75231-P3-3000-WS-R-M-2704-0",REQ_DESCR:"75231-P3-3000-WS-R-M-2704-0 (Cast Valve CS)",ROS_ETA_FORECAST:166,ROS_ETA_PLAN:166},
      {MR_NO:"75231-P3-3000-WS-R-M-2703-2",REQ_DESCR:"75231-P3-3000-WS-R-M-2703-2 (Forged Valve)",ROS_ETA_FORECAST:166,ROS_ETA_PLAN:166},
      {MR_NO:"75231-P3-3000-WS-R-M-3201-2",REQ_DESCR:"75231-P3-3000-WS-R-M-3201-2 (FLANGE MPLP CSSSAS)",ROS_ETA_FORECAST:166,ROS_ETA_PLAN:166},
      {MR_NO:"75231-P3-3000-WS-R-M-3312-2",REQ_DESCR:"75231-P3-3000-WS-R-M-3312-2 (Special ValveNonSTD Gate Valve)",ROS_ETA_FORECAST:166,ROS_ETA_PLAN:166,ETA_ACTUAL:"2023-02-12"},
      {MR_NO:"75231-P3-3000-WS-R-M-3401-2",REQ_DESCR:"75231-P3-3000-WS-R-M-3401-2 (GASKET)",ROS_ETA_FORECAST:166,ROS_ETA_PLAN:166},
      {MR_NO:"75231-P3-3000-WS-R-M-2501-3",REQ_DESCR:"75231-P3-3000-WS-R-M-2501-3 (GASKET)",ROS_ETA_FORECAST:166,ROS_ETA_PLAN:166},
      {MR_NO:"75231-P3-2810-WS-R-M-2402-0",REQ_DESCR:"75231-P3-2810-WS-R-M-2402-0 (Forged Fitting)",ROS_ETA_FORECAST:175,ROS_ETA_PLAN:175,ETA_ACTUAL:"2022-11-02"},
      {MR_NO:"75231-P3-2802-WS-R-M-2402-0",REQ_DESCR:"75231-P3-2802-WS-R-M-2402-0 (Forged Fitting)",ROS_ETA_FORECAST:175,ROS_ETA_PLAN:175,ETA_ACTUAL:"2022-11-02"},
      {MR_NO:"75231-P3-1810-WS-R-M-2402-0",REQ_DESCR:"75231-P3-1810-WS-R-M-2402-0 (Forged Fitting)",ROS_ETA_FORECAST:175,ROS_ETA_PLAN:175,ETA_ACTUAL:"2022-11-02"},
      {MR_NO:"75231-P3-1802-WS-R-M-2402-0",REQ_DESCR:"75231-P3-1802-WS-R-M-2402-0 (Forged Fitting)",ROS_ETA_FORECAST:175,ROS_ETA_PLAN:175,ETA_ACTUAL:"2022-11-02"},
      {MR_NO:"75231-P3-1802-WS-R-M-3102-2",REQ_DESCR:"75231-P3-1802-WS-R-M-3102-2 (CS Welded Fitting)",ROS_ETA_FORECAST:175,ROS_ETA_PLAN:175,ETA_ACTUAL:"2022-12-21"},
      {MR_NO:"75231-P3-1510-WS-R-M-3101",REQ_DESCR:"75231-P3-1510-WS-R-M-3101 (CS Seamless Fitting)",ROS_ETA_FORECAST:186,ROS_ETA_PLAN:186,ETA_ACTUAL:"2022-08-31"},
      {MR_NO:"75231-P3-1510-WS-R-M-3101",REQ_DESCR:"75231-P3-1510-WS-R-M-3101 (CS Seamless Fitting)",ROS_ETA_FORECAST:186,ROS_ETA_PLAN:186,ETA_ACTUAL:"2022-11-02"},
      {MR_NO:"75231-P3-1824-EP-R-M-2402-1",REQ_DESCR:"75231-P3-1824-EP-R-M-2402-1 (Forged Fitting)",ROS_ETA_FORECAST:187,ROS_ETA_PLAN:187,ETA_ACTUAL:"2023-01-13"},
      {MR_NO:"75231-P3-3000-WS-R-M-2402-3",REQ_DESCR:"75231-P3-3000-WS-R-M-2402-3 (Forged Fitting)",ROS_ETA_FORECAST:194,ROS_ETA_PLAN:194,ETA_ACTUAL:"2023-01-13"},
      {MR_NO:"75231-P3-3000-WS-R-M-3101-7",REQ_DESCR:"75231-P3-3000-WS-R-M-3101-7 (CS Seamless Fitting)",ROS_ETA_FORECAST:194,ROS_ETA_PLAN:194,ETA_ACTUAL:"2023-01-12"},
      {MR_NO:"75231-P3-3000-WS-R-M-3001-7",REQ_DESCR:"75231-P3-3000-WS-R-M-3001-7 (CS Seamless Pipe)",ROS_ETA_FORECAST:194,ROS_ETA_PLAN:194,ETA_ACTUAL:"2023-02-01"},
      {MR_NO:"75231-P3-3000-WS-R-M-3401-0",REQ_DESCR:"75231-P3-3000-WS-R-M-3401-0 (GASKET)",ROS_ETA_FORECAST:198,ROS_ETA_PLAN:198},
      {MR_NO:"75231-P3-3000-WS-R-M-3401-1",REQ_DESCR:"75231-P3-3000-WS-R-M-3401-1 (GASKET)",ROS_ETA_FORECAST:198,ROS_ETA_PLAN:198},
      {MR_NO:"75231-P3-2802-WS-R-M-3003",REQ_DESCR:"75231-P3-2802-WS-R-M-3003 (CS DSAWLGS L245 PSL1 Pipe L245)",ROS_ETA_FORECAST:198,ROS_ETA_PLAN:198},
      {MR_NO:"75231-P3-1802-WS-R-M-3003",REQ_DESCR:"75231-P3-1802-WS-R-M-3003 (CS DSAWLGS L245 PSL1 Pipe L245)",ROS_ETA_FORECAST:198,ROS_ETA_PLAN:198},
      {MR_NO:"75231-P3-1824-EP-R-M-2402",REQ_DESCR:"75231-P3-1824-EP-R-M-2402 (Forged Fitting)",ROS_ETA_FORECAST:202,ROS_ETA_PLAN:202,ETA_ACTUAL:"2022-12-05"},
      {MR_NO:"75231-P3-1802-WS-R-M-3101-1",REQ_DESCR:"75231-P3-1802-WS-R-M-3101-1 (CS Seamless Fitting)",ROS_ETA_FORECAST:203,ROS_ETA_PLAN:203,ETA_ACTUAL:"2023-01-12"},
      {MR_NO:"75231-P3-3000-WS-R-M-2603",REQ_DESCR:"75231-P3-3000-WS-R-M-2603 (FLANGE MPLP CSSSAS  350 DN 600)",ROS_ETA_FORECAST:209,ROS_ETA_PLAN:209},
      {MR_NO:"75231-P3-1802-WS-R-M-3005",REQ_DESCR:"75231-P3-1802-WS-R-M-3005 (Ductile iron pipe)",ROS_ETA_FORECAST:216,ROS_ETA_PLAN:216,ETA_ACTUAL:"2022-08-28"},
      {MR_NO:"75231-P3-3000-WS-R-M-2602",REQ_DESCR:"75231-P3-3000-WS-R-M-2602 (FLANGE MPLP CSSSAS  DN 300)",ROS_ETA_FORECAST:217,ROS_ETA_PLAN:217,ETA_ACTUAL:"2022-11-08"},
      {MR_NO:"75231-P3-2802-WS-R-M-3005",REQ_DESCR:"75231-P3-2802-WS-R-M-3005 (Ductile iron pipe)",ROS_ETA_FORECAST:225,ROS_ETA_PLAN:225,ETA_ACTUAL:"2022-08-28"},
      {MR_NO:"75231-P3-2810-WS-R-M-3005",REQ_DESCR:"75231-P3-2810-WS-R-M-3005 (Ductile iron pipe)",ROS_ETA_FORECAST:225,ROS_ETA_PLAN:225,ETA_ACTUAL:"2022-08-28"},
      {MR_NO:"75231-P3-1802-WS-R-M-3001",REQ_DESCR:"75231-P3-1802-WS-R-M-3001 (CS SMLS Pipe)",ROS_ETA_FORECAST:225,ROS_ETA_PLAN:225,ETA_ACTUAL:"2023-02-01"},
      {MR_NO:"75231-P3-1802-WS-R-M-3102-1",REQ_DESCR:"75231-P3-1802-WS-R-M-3102-1 (CS Welded Fitting)",ROS_ETA_FORECAST:225,ROS_ETA_PLAN:225,ETA_ACTUAL:"2022-11-08"},
      {MR_NO:"75231-P3-1806-WS-R-M-3005",REQ_DESCR:"75231-P3-1806-WS-R-M-3005 (Ductile iron pipe)",ROS_ETA_FORECAST:225,ROS_ETA_PLAN:225,ETA_ACTUAL:"2022-09-08"},
      {MR_NO:"75231-P3-1810-WS-R-M-3005",REQ_DESCR:"75231-P3-1810-WS-R-M-3005 (Ductile iron pipe)",ROS_ETA_FORECAST:225,ROS_ETA_PLAN:225,ETA_ACTUAL:"2022-09-01"},
      {MR_NO:"75231-P3-1708-IN-R-E-5313",REQ_DESCR:"75231-P3-1708-IN-R-E-5313 (Coriolis Mass Flowmeter)",ROS_ETA_FORECAST:237,ROS_ETA_PLAN:237},
      {MR_NO:"75231-P3-1810-WS-R-M-2711-0",REQ_DESCR:"75231-P3-1810-WS-R-M-2711-0 (Butterfly Valve)",ROS_ETA_FORECAST:241,ROS_ETA_PLAN:241},
      {MR_NO:"75231-P3-1806-WS-R-M-2711-0",REQ_DESCR:"75231-P3-1806-WS-R-M-2711-0 (Butterfly Valve)",ROS_ETA_FORECAST:241,ROS_ETA_PLAN:241},
      {MR_NO:"75231-P3-1802-WS-R-M-2711-0",REQ_DESCR:"75231-P3-1802-WS-R-M-2711-0 (Butterfly Valve)",ROS_ETA_FORECAST:241,ROS_ETA_PLAN:241},
      {MR_NO:"75231-P3-2810-WS-R-M-2711-0",REQ_DESCR:"75231-P3-2810-WS-R-M-2711-0 (Butterfly Valve)",ROS_ETA_FORECAST:241,ROS_ETA_PLAN:241},
      {MR_NO:"75231-P3-2802-WS-R-M-2711-0",REQ_DESCR:"75231-P3-2802-WS-R-M-2711-0 (Butterfly Valve)",ROS_ETA_FORECAST:241,ROS_ETA_PLAN:241},
      {MR_NO:"75231-P3-1510-WS-R-M-2501",REQ_DESCR:"75231-P3-1510-WS-R-M-2501 (GASKET)",ROS_ETA_FORECAST:247,ROS_ETA_PLAN:247,ETA_ACTUAL:"2022-10-26"},
      {MR_NO:"75231-P3-1510-WS-R-M-2505",REQ_DESCR:"75231-P3-1510-WS-R-M-2505 (Bolts Nuts)",ROS_ETA_FORECAST:247,ROS_ETA_PLAN:247,ETA_ACTUAL:"2023-02-02"},
      {MR_NO:"75231-P3-1510-WS-R-M-2602",REQ_DESCR:"75231-P3-1510-WS-R-M-2602 (FLANGE MPLP CSSSAS  DN 300)",ROS_ETA_FORECAST:247,ROS_ETA_PLAN:247,ETA_ACTUAL:"2022-11-08"},
      {MR_NO:"75231-P3-1510-WS-R-M-2703",REQ_DESCR:"75231-P3-1510-WS-R-M-2703 (Forged Valve)",ROS_ETA_FORECAST:247,ROS_ETA_PLAN:247,ETA_ACTUAL:"2022-11-03"},
      {MR_NO:"75231-P3-1824-EP-R-M-3001-0",REQ_DESCR:"75231-P3-1824-EP-R-M-3001-0 (CS SMLS Pipe)",ROS_ETA_FORECAST:255,ROS_ETA_PLAN:255,ETA_ACTUAL:"2022-10-25"},
      {MR_NO:"75231-P3-1824-EP-R-M-3101-0",REQ_DESCR:"75231-P3-1824-EP-R-M-3101-0 (CS Seamless Fitting)",ROS_ETA_FORECAST:255,ROS_ETA_PLAN:255,ETA_ACTUAL:"2022-09-25"},
      {MR_NO:"75231-P3-1824-EP-R-M-3005-0",REQ_DESCR:"75231-P3-1824-EP-R-M-3005-0 (Ductile iron pipe)",ROS_ETA_FORECAST:255,ROS_ETA_PLAN:255,ETA_ACTUAL:"2022-09-28"},
      {MR_NO:"75231-P3-3000-WS-R-M-3003",REQ_DESCR:"75231-P3-3000-WS-R-M-3003 (CS DSAWLGS L245 PSL1 Pipe L245)",ROS_ETA_FORECAST:272,ROS_ETA_PLAN:272,ETA_ACTUAL:"2022-08-20"},
      {MR_NO:"75231-P3-3000-WS-R-M-3002",REQ_DESCR:"75231-P3-3000-WS-R-M-3002 (CS SAWSPS Pipe)",ROS_ETA_FORECAST:291,ROS_ETA_PLAN:291,ETA_ACTUAL:"2022-10-16"},
      {MR_NO:"75231-P3-3000-WS-R-M-3005",REQ_DESCR:"75231-P3-3000-WS-R-M-3005 (Ductile iron pipe)",ROS_ETA_FORECAST:299,ROS_ETA_PLAN:299,ETA_ACTUAL:"2022-10-26"},
      {MR_NO:"75231-P3-3000-WS-R-M-3001",REQ_DESCR:"75231-P3-3000-WS-R-M-3001 (CS CAST VALVESGATEGLOBECHECKPN 6)",ROS_ETA_FORECAST:299,ROS_ETA_PLAN:299,ETA_ACTUAL:"2023-02-01"},
      {MR_NO:"75231-P3-3000-WS-R-M-3101",REQ_DESCR:"75231-P3-3000-WS-R-M-3101 (CS Seamless Fitting)",ROS_ETA_FORECAST:299,ROS_ETA_PLAN:299,ETA_ACTUAL:"2022-11-29"},
      {MR_NO:"75231-P3-3000-WS-R-M-3102",REQ_DESCR:"75231-P3-3000-WS-R-M-3102 (CS Welded Fitting)",ROS_ETA_FORECAST:299,ROS_ETA_PLAN:299,ETA_ACTUAL:"2022-12-21"},
      {MR_NO:"75231-P3-1806-WS-R-M-3003",REQ_DESCR:"75231-P3-1806-WS-R-M-3003 (CS DSAWLGS L245 PSL1 Pipe L245)",ROS_ETA_FORECAST:305,ROS_ETA_PLAN:305,ETA_ACTUAL:"2022-07-22"},
      {MR_NO:"75231-P3-1810-WS-R-M-3002",REQ_DESCR:"75231-P3-1810-WS-R-M-3002 (CS SAWSPS Pipe)",ROS_ETA_FORECAST:313,ROS_ETA_PLAN:313,ETA_ACTUAL:"2022-07-20"},
      {MR_NO:"75231-P3-2810-WS-R-M-3002",REQ_DESCR:"75231-P3-2810-WS-R-M-3002 (CS SAWSPS Pipe)",ROS_ETA_FORECAST:313,ROS_ETA_PLAN:313,ETA_ACTUAL:"2022-07-20"},
      {MR_NO:"75231-P3-1802-WS-R-M-3101",REQ_DESCR:"75231-P3-1802-WS-R-M-3101 (CS Seamless Fitting)",ROS_ETA_FORECAST:315,ROS_ETA_PLAN:315,ETA_ACTUAL:"2022-08-31"},
      {MR_NO:"75231-P3-1810-WS-R-M-3101",REQ_DESCR:"75231-P3-1810-WS-R-M-3101 (CS Seamless Fitting)",ROS_ETA_FORECAST:321,ROS_ETA_PLAN:321,ETA_ACTUAL:"2022-06-28"},
      {MR_NO:"75231-P3-1810-WS-R-M-3102",REQ_DESCR:"75231-P3-1810-WS-R-M-3102 (CS Welded Fitting)",ROS_ETA_FORECAST:321,ROS_ETA_PLAN:321,ETA_ACTUAL:"2022-09-15"},
      {MR_NO:"75231-P3-1810-WS-R-M-3001",REQ_DESCR:"75231-P3-1810-WS-R-M-3001 (CS SMLS Pipe)",ROS_ETA_FORECAST:321,ROS_ETA_PLAN:321,ETA_ACTUAL:"2022-07-28"},
      {MR_NO:"75231-P3-2810-WS-R-M-3001",REQ_DESCR:"75231-P3-2810-WS-R-M-3001 (CS SMLS Pipe)",ROS_ETA_FORECAST:321,ROS_ETA_PLAN:321,ETA_ACTUAL:"2022-07-28"},
      {MR_NO:"75231-P3-2810-WS-R-M-3101",REQ_DESCR:"75231-P3-2810-WS-R-M-3101 (CS Seamless Fitting)",ROS_ETA_FORECAST:321,ROS_ETA_PLAN:321,ETA_ACTUAL:"2022-06-28"},
      {MR_NO:"75231-P3-2810-WS-R-M-3102",REQ_DESCR:"75231-P3-2810-WS-R-M-3102 (CS Welded Fitting)",ROS_ETA_FORECAST:321,ROS_ETA_PLAN:321,ETA_ACTUAL:"2022-09-15"},
      {MR_NO:"75231-P3-1806-WS-R-M-3002",REQ_DESCR:"75231-P3-1806-WS-R-M-3002 (CS SAWSPS Pipe)",ROS_ETA_FORECAST:324,ROS_ETA_PLAN:324,ETA_ACTUAL:"2022-07-14"},
      {MR_NO:"75231-P3-1806-WS-R-M-3102",REQ_DESCR:"75231-P3-1806-WS-R-M-3102 (CS Welded Fitting)",ROS_ETA_FORECAST:332,ROS_ETA_PLAN:332,ETA_ACTUAL:"2022-09-20"},
      {MR_NO:"75231-P3-2802-WS-R-M-3002",REQ_DESCR:"75231-P3-2802-WS-R-M-3002 (CS SAWSPS Pipe)",ROS_ETA_FORECAST:334,ROS_ETA_PLAN:334,ETA_ACTUAL:"2022-11-04"},
      {MR_NO:"75231-P3-2802-WS-R-M-3102",REQ_DESCR:"75231-P3-2802-WS-R-M-3102 (CS Welded Fitting)",ROS_ETA_FORECAST:342,ROS_ETA_PLAN:342,ETA_ACTUAL:"2022-11-15"},
      {MR_NO:"75231-P3-1802-WS-R-M-3102",REQ_DESCR:"75231-P3-1802-WS-R-M-3102 (CS Welded Fitting)",ROS_ETA_FORECAST:342,ROS_ETA_PLAN:342,ETA_ACTUAL:"2022-09-10"},
      {MR_NO:"75231-P3-3000-WS-R-M-2402",REQ_DESCR:"75231-P3-3000-WS-R-M-2402 (Forged Fitting)",ROS_ETA_FORECAST:361,ROS_ETA_PLAN:361,ETA_ACTUAL:"2022-12-05"},
      {MR_NO:"75231-P3-1510-WS-R-M-3005",REQ_DESCR:"75231-P3-1510-WS-R-M-3005 (Ductile iron pipe)",ROS_ETA_FORECAST:419,ROS_ETA_PLAN:419,ETA_ACTUAL:"2022-10-26"}
    ]
}