import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 780,
    CanvasHeight: 250,
    CanvasBorderWeight: 0.1,
    CanvasBorderColor: '#e0e0e0',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.2,

    CanvasChartAreaAllowed: 'Y',
    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 760,
    CanvasChartHeight: 200,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0.02,
    CanvasChartSpace: 20,

    CanvasFontFamily: 'roboto'
  },
  Text: {
    Text: [
      { text: '', x:20, y: 10, font: 'roboto', class: 'regular', size: 12, color: '#424242', align: 'start' },
    ],
    Filter: [
      { name: 'MOD'   , x: -50, y: 20, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'start' },
    ],
    Sql: [
      {
        query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
        text: 'Data Source',
        x: -30,
        y: 20,
        font: 'roboto',
        style: 'regular',
        size: 11,
        color: '#44A9DF',
        align: 'start'
      },
    ]
  },
  Queries: {
    SQL1: [
      // {TOTAL:18567,COMPLETE:6091,REMAIN:12476,PROG:32.8}
    ],
    SQL2:  [
      {WK_NO:"W2022_23"}
    ],
  },
  DataItems: [

    {ISSUED_MDR_REV:"10",P1:0,P2:0,P3:534},
    {ISSUED_MDR_REV:"11",P1:4779,P2:2363,P3:4439},
    {ISSUED_MDR_REV:"12",P1:4886,P2:2425,P3:4479},
    {ISSUED_MDR_REV:"13",P1:4922,P2:2425,P3:4509},
    {ISSUED_MDR_REV:"14",P1:5658,P2:2164,P3:4592},
    {ISSUED_MDR_REV:"15",P1:0,P2:0,P3:4088}
  ],
}