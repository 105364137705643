import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 860,
    CanvasHeight: 980,
    CanvasBorderWeight: 0.1,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.2,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 0,
    CanvasChartHeight: 0,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },

  DataItems:  [
    {GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",DISC_SEQ:1,DISC:"Structure",ITEM:"Area Outfitting",WC:"Fabrication",ITEM_SEQ:"1",BLOCK:"WB04",WCCODE:"36185",TOTAL:2565.1,COM:1026,REM:1539.1,PROG:39.99},
    {GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",DISC_SEQ:1,DISC:"Structure",ITEM:"Area Outfitting",WC:"Installation",ITEM_SEQ:"1",BLOCK:"WB04",WCCODE:"36180",TOTAL:2565.1,COM:974.7,REM:1590.4,PROG:37.99},
    {GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",DISC_SEQ:2,DISC:"Piping",ITEM:"Support",WC:"Fabrication",ITEM_SEQ:"1",BLOCK:"WB04",WCCODE:"13110",TOTAL:0,COM:0,REM:0,PROG:0},
    {GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",DISC_SEQ:3,DISC:"Electrical",ITEM:"Cable Tray",WC:"Installation",ITEM_SEQ:"4",BLOCK:"WB04",WCCODE:"16430",TOTAL:457.1,COM:0,REM:457.1,PROG:0},
    {GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",DISC_SEQ:2,DISC:"Piping",ITEM:"Support",WC:"Installation",ITEM_SEQ:"1",BLOCK:"WB04",WCCODE:"13120",TOTAL:0,COM:0,REM:0,PROG:0},
    {GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",DISC_SEQ:2,DISC:"Piping",ITEM:"Spool",WC:"Fabrication",ITEM_SEQ:"3",BLOCK:"WB04",WCCODE:"13310",TOTAL:521.5,COM:26.5,REM:495,PROG:5.08},
    {GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",DISC_SEQ:3,DISC:"Electrical",ITEM:"Support",WC:"Fabrication",ITEM_SEQ:"4",BLOCK:"WB04",WCCODE:"16450",TOTAL:217,COM:143.2,REM:73.80000000000001,PROG:65.99},
    {GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",DISC_SEQ:3,DISC:"Electrical",ITEM:"Support",WC:"Installation",ITEM_SEQ:"4",BLOCK:"WB04",WCCODE:"16460",TOTAL:217,COM:0,REM:217,PROG:0},
    {GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",DISC_SEQ:2,DISC:"Piping",ITEM:"Spool",WC:"Installation",ITEM_SEQ:"3",BLOCK:"WB04",WCCODE:"13320",TOTAL:168,COM:21.3,REM:146.7,PROG:12.67}
    ],
}