import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 500,
      CanvasHeight: 1100,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: '', x:10, y: 0, font: 'roboto', class: 'regular', size: 12, color: '#424242', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: -50, y: -30, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'start' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: -30,
          y: -30,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'start'
        },
      ]
    },
    Queries: {
      SQL1: [
      ],
    },
    DataItems: [
      {DESCRIPTION:"A-Architectural",TOTAL:327,ISSUED:179,REMAIN:148,PROG:54.74},
      {DESCRIPTION:"B-Business and General",TOTAL:68,ISSUED:55,REMAIN:13,PROG:80.88},
      {DESCRIPTION:"C-Civil and Infrastructure",TOTAL:4,ISSUED:2,REMAIN:2,PROG:50},
      {DESCRIPTION:"D-Mechanical and Static Equipment",TOTAL:1003,ISSUED:245,REMAIN:758,PROG:24.43},
      {DESCRIPTION:"E-Electrical",TOTAL:1211,ISSUED:354,REMAIN:857,PROG:29.23},
      {DESCRIPTION:"F-Risk and Loss Prevention",TOTAL:712,ISSUED:347,REMAIN:365,PROG:48.74},
      {DESCRIPTION:"H-HVAC",TOTAL:242,ISSUED:45,REMAIN:197,PROG:18.6},
      {DESCRIPTION:"I-Instrumentation and Metering",TOTAL:1775,ISSUED:1133,REMAIN:642,PROG:63.83},
      {DESCRIPTION:"K-Construction, Transportation, and Logistics",TOTAL:12,ISSUED:12,REMAIN:0,PROG:100},
      {DESCRIPTION:"L-Piping and General Layout",TOTAL:2332,ISSUED:526,REMAIN:1806,PROG:22.56},
      {DESCRIPTION:"M-Rotating Machinery",TOTAL:240,ISSUED:203,REMAIN:37,PROG:84.58},
      {DESCRIPTION:"NA",TOTAL:213,ISSUED:0,REMAIN:213,PROG:0},
      {DESCRIPTION:"N-Structural (includes Weight Control)",TOTAL:1725,ISSUED:856,REMAIN:869,PROG:49.62},
      {DESCRIPTION:"P-Process",TOTAL:3257,ISSUED:2923,REMAIN:334,PROG:89.75},
      {DESCRIPTION:"Q-Quality",TOTAL:10,ISSUED:7,REMAIN:3,PROG:70},
      {DESCRIPTION:"R-Regulatory, Environmental, and Socioeconomic",TOTAL:3,ISSUED:3,REMAIN:0,PROG:100},
      {DESCRIPTION:"S-Safety, Health, and Security",TOTAL:35,ISSUED:30,REMAIN:5,PROG:85.71},
      {DESCRIPTION:"T-Telecommunications",TOTAL:171,ISSUED:16,REMAIN:155,PROG:9.36},
      {DESCRIPTION:"V-Contracting and Procurement",TOTAL:309,ISSUED:199,REMAIN:110,PROG:64.4},
      {DESCRIPTION:"X-Materials, Corrosion, and Flow Assurance",TOTAL:84,ISSUED:83,REMAIN:1,PROG:98.81},
      {DESCRIPTION:"Y-Pipelines, Umbilicals, Risers, and Flowlines",TOTAL:1,ISSUED:0,REMAIN:1,PROG:0}
  ],
}