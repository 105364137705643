import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 250,
      CanvasHeight: 250,
      CanvasBorderWeight: 0.3,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 310,
      CanvasChartHeight: 270,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: 'Transparent',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },

    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },

    DataItems:  [
      {GWBS_LEVEL:"DECK A",LV2:"LQ",LV4:"LQ01",PO_TOTAL:14,PO_ACTUAL:12,FAT_TOTAL:14,FAT_ACTUAL:11,IRN_TOTAL:14,IRN_ACTUAL:11,ONSITE_TOTAL:14,ONSITE_ACTUAL:11,INST_TOTAL:14,INST_ACTUAL:0,NOT_PO_CNT:2,ETA_PAST_FROM_ROS:1,ETA_PAST_FROM_UPPERBLOCK:0},
      {GWBS_LEVEL:"LV1",LV2:"LQ",LV4:"LQ02",PO_TOTAL:27,PO_ACTUAL:10,FAT_TOTAL:27,FAT_ACTUAL:3,IRN_TOTAL:27,IRN_ACTUAL:3,ONSITE_TOTAL:27,ONSITE_ACTUAL:2,INST_TOTAL:27,INST_ACTUAL:0,NOT_PO_CNT:17,ETA_PAST_FROM_ROS:6,ETA_PAST_FROM_UPPERBLOCK:0},
      {GWBS_LEVEL:"LV2",LV2:"LQ",LV4:"LQ03",PO_TOTAL:22,PO_ACTUAL:18,FAT_TOTAL:22,FAT_ACTUAL:18,IRN_TOTAL:22,IRN_ACTUAL:18,ONSITE_TOTAL:22,ONSITE_ACTUAL:17,INST_TOTAL:22,INST_ACTUAL:0,NOT_PO_CNT:4,ETA_PAST_FROM_ROS:0,ETA_PAST_FROM_UPPERBLOCK:0},
      {GWBS_LEVEL:"LV3",LV2:"LQ",LV4:"LQ04",PO_TOTAL:7,PO_ACTUAL:5,FAT_TOTAL:7,FAT_ACTUAL:5,IRN_TOTAL:7,IRN_ACTUAL:5,ONSITE_TOTAL:7,ONSITE_ACTUAL:5,INST_TOTAL:7,INST_ACTUAL:0,NOT_PO_CNT:2,ETA_PAST_FROM_ROS:0,ETA_PAST_FROM_UPPERBLOCK:0},
      {GWBS_LEVEL:"LV4",LV2:"LQ",LV4:"LQ05",PO_TOTAL:1,PO_ACTUAL:0,FAT_TOTAL:1,FAT_ACTUAL:0,IRN_TOTAL:1,IRN_ACTUAL:0,ONSITE_TOTAL:1,ONSITE_ACTUAL:0,INST_TOTAL:1,INST_ACTUAL:0,NOT_PO_CNT:1,ETA_PAST_FROM_ROS:0,ETA_PAST_FROM_UPPERBLOCK:0},
      {GWBS_LEVEL:"LV5",LV2:"LQ",LV4:"LQ06",PO_TOTAL:22,PO_ACTUAL:14,FAT_TOTAL:22,FAT_ACTUAL:14,IRN_TOTAL:22,IRN_ACTUAL:14,ONSITE_TOTAL:22,ONSITE_ACTUAL:10,INST_TOTAL:22,INST_ACTUAL:0,NOT_PO_CNT:8,ETA_PAST_FROM_ROS:2,ETA_PAST_FROM_UPPERBLOCK:0},
      {GWBS_LEVEL:"ROOF",LV2:"LQ",LV4:"LQ07",PO_TOTAL:5,PO_ACTUAL:5,FAT_TOTAL:5,FAT_ACTUAL:5,IRN_TOTAL:5,IRN_ACTUAL:5,ONSITE_TOTAL:5,ONSITE_ACTUAL:5,INST_TOTAL:5,INST_ACTUAL:0,NOT_PO_CNT:0,ETA_PAST_FROM_ROS:0,ETA_PAST_FROM_UPPERBLOCK:0}
    ],

    Queries: {
      SQL1:  []
    }
}