import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 960,
      CanvasHeight: 150,
      CanvasBorderWeight: 0.3,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 960,
      CanvasChartHeight: 150,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: 'Transparent',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },

    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },

    DataItems: [


      {DISC_DESCR:"Structure",TARGET_PROG:100,PLAN_PROG:57.61,ACTUAL_PROG:88.79,DELTA:31.17,DISC:"36"},
      {DISC_DESCR:"Piping",TARGET_PROG:97.18,PLAN_PROG:5.87,ACTUAL_PROG:6.37,DELTA:-0.49,DISC:"13"},
      {DISC_DESCR:"Electrical",TARGET_PROG:100,PLAN_PROG:7.94,ACTUAL_PROG:17.57,DELTA:9.62,DISC:"16"},
      {DISC_DESCR:"Painting",TARGET_PROG:100,PLAN_PROG:26.58,ACTUAL_PROG:0,DELTA:12.24,DISC:"23"},

      {DISC_DESCR:"Structure",TARGET_PROG:97.04,PLAN_PROG:96.61,ACTUAL_PROG:98.63,DELTA:2.01,DISC:"36"},
      {DISC_DESCR:"Piping",TARGET_PROG:74.49,PLAN_PROG:68.94,ACTUAL_PROG:27.92,DELTA:-41.02,DISC:"13"},
      {DISC_DESCR:"Piping",TARGET_PROG:74.49,PLAN_PROG:68.94,ACTUAL_PROG:27.92,DELTA:-41.02,DISC:"13"},
      {DISC_DESCR:"Piping",TARGET_PROG:74.49,PLAN_PROG:68.94,ACTUAL_PROG:27.92,DELTA:-41.02,DISC:"13"},
      
    ],

    Queries: {
      SQL1: [
        {DISC:"13",WC_DESCRIPTION:"Area Piping Fabrication",UOM:"DIA",INITIALQTY:10.7,TARGET_QTY:10.7,TARGET_PROG:100,ACTUAL_CUM_QTY:789,ACTUAL_CUM_PROG:100,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:-778.3,REMAIN_PROG:0},
        {DISC:"23",WC_DESCRIPTION:"Hull Area Painting",UOM:"SQM",ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:1041},
        {DISC:"23",WC_DESCRIPTION:"Piping Painting",UOM:"SQM",INITIALQTY:323.17,TARGET_QTY:323.17,TARGET_PROG:100,ACTUAL_CUM_QTY:83.76,ACTUAL_CUM_PROG:25.91,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:239.41,REMAIN_PROG:74.09},
        {DISC:"36",WC_DESCRIPTION:"Block Fabrication",UOM:"TONS",INITIALQTY:62.17,TARGET_QTY:62.17,TARGET_PROG:100,ACTUAL_CUM_QTY:61.55,ACTUAL_CUM_PROG:98.99,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:0.62,REMAIN_PROG:1.01},
        {DISC:"36",WC_DESCRIPTION:"Area Outfitting",UOM:"KG",INITIALQTY:11503.18,TARGET_QTY:11503.18,TARGET_PROG:100,ACTUAL_CUM_QTY:11500,ACTUAL_CUM_PROG:99.97,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:3.18,REMAIN_PROG:0.03},
        {DISC:"36",WC_DESCRIPTION:"Steel Pre-Outfitting Fabrication",UOM:"KG",INITIALQTY:8176.46,TARGET_QTY:8176.46,TARGET_PROG:100,ACTUAL_CUM_QTY:8180.73,ACTUAL_CUM_PROG:100,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:-4.27,REMAIN_PROG:0},
        {DISC:"16",WC_DESCRIPTION:"Eict Area Outfittings Installation",UOM:"LM",INITIALQTY:300,TARGET_QTY:300,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:300,REMAIN_PROG:100},
        {DISC:"16",WC_DESCRIPTION:"Eict Mega Block Outfittings Fabrication",UOM:"KG",INITIALQTY:997.15,TARGET_QTY:997.15,TARGET_PROG:100,ACTUAL_CUM_QTY:600,ACTUAL_CUM_PROG:60.17,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:397.15,REMAIN_PROG:39.82},
        {DISC:"23",WC_DESCRIPTION:"Steel Struct. Painting",UOM:"SQM",INITIALQTY:1500.12,TARGET_QTY:1500.12,TARGET_PROG:100,ACTUAL_CUM_QTY:624.6,ACTUAL_CUM_PROG:41.63,ACTUAL_WEEK_QTY:624.6,ACTUAL_WEEK_PROG:41.63,REMAIN_QTY:875.52,REMAIN_PROG:58.36},
        {DISC:"16",WC_DESCRIPTION:"Eict Mega Block Outfittings Installation",UOM:"KG",INITIALQTY:1000,TARGET_QTY:1000,TARGET_PROG:100,ACTUAL_CUM_QTY:200,ACTUAL_CUM_PROG:20,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:800,REMAIN_PROG:80},
        {DISC:"36",WC_DESCRIPTION:"Steel Pre-Outfitting Installation",UOM:"KG",INITIALQTY:8181.4,TARGET_QTY:8181.4,TARGET_PROG:100,ACTUAL_CUM_QTY:8180.73,ACTUAL_CUM_PROG:99.99,ACTUAL_WEEK_QTY:327.23,ACTUAL_WEEK_PROG:3.99,REMAIN_QTY:0.67,REMAIN_PROG:0.01},
        {DISC:"13",WC_DESCRIPTION:"Area Piping Installation",UOM:"DIA",INITIALQTY:440.31,TARGET_QTY:427.14,TARGET_PROG:97.01,ACTUAL_CUM_QTY:2.48,ACTUAL_CUM_PROG:0.56,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:424.67,REMAIN_PROG:96.45},
        {DISC:"36",WC_DESCRIPTION:"Area Outfitting",UOM:"KG",INITIALQTY:11498.28,TARGET_QTY:11498.28,TARGET_PROG:100,ACTUAL_CUM_QTY:10580,ACTUAL_CUM_PROG:92.01,ACTUAL_WEEK_QTY:1035,ACTUAL_WEEK_PROG:9,REMAIN_QTY:918.28,REMAIN_PROG:7.98},
        {DISC:"13",WC_DESCRIPTION:"Block Piping Installation",UOM:"KG",INITIALQTY:1791.4,TARGET_QTY:1791.4,TARGET_PROG:100,ACTUAL_CUM_QTY:1778.96,ACTUAL_CUM_PROG:99.3,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:12.44,REMAIN_PROG:0.7},
        {DISC:"13",WC_DESCRIPTION:"Block Piping Fabrication",UOM:"KG",INITIALQTY:1784.54,TARGET_QTY:1784.54,TARGET_PROG:100,ACTUAL_CUM_QTY:1778.96,ACTUAL_CUM_PROG:99.68,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:5.58,REMAIN_PROG:0.31},
        {DISC:"36",WC_DESCRIPTION:"Megablock Erection",UOM:"TONS",INITIALQTY:62.21,TARGET_QTY:62.21,TARGET_PROG:100,ACTUAL_CUM_QTY:0,ACTUAL_CUM_PROG:0,ACTUAL_WEEK_QTY:0,ACTUAL_WEEK_PROG:0,REMAIN_QTY:62.21,REMAIN_PROG:100}
      ]
    }
}