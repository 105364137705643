import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1780,
      CanvasHeight: 2500,
      CanvasBorderWeight: 0.3,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 310,
      CanvasChartHeight: 270,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: '#fff',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },

    Text: {
      Text: [
        { text: '', x:20, y: 10, font: 'roboto', class: 'regular', size: 12, color: '#424242', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: -50, y: 20, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'start' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: -30,
          y: 20,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'start'
        },
      ]
    },
    Queries: {
      SQL1: [
        {NO:1,PLAN_FLOAT_STATUS:"1-2 Weeks",VALUE:1},
        {NO:2,PLAN_FLOAT_STATUS:"> 4 Weeks",VALUE:2}
      ],
      SQL2: [
        {NO:1,FORECAST_FLOAT_STATUS:"< 1 Week",VALUE:5},
        {NO:2,FORECAST_FLOAT_STATUS:"1-2 Weeks",VALUE:25},
        {NO:3,FORECAST_FLOAT_STATUS:"2-4 Weeks",VALUE:16},
        {NO:4,FORECAST_FLOAT_STATUS:"> 4 Weeks",VALUE:38}
      ],
      SQL3: [
        {text:"Datasource :  Procurement Status Report 2022-06-24"}
      ]
    },
    DataItems: [
      
      {NO:1,PROCESS_UNIT:"OR",MR_NO:"75231-P1-1848-TM-R-E-0903",MR_DESCR:"SILENCER",SEQ_NO:11,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:0,QTY:11,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:5,TBE_FLOAT:-6,CBE_FLOAT:-6,AR_FLOAT:-6,ARA_FLOAT:-6,LOA_FLOAT:-6,PO_FLOAT:-6,MDI_FLOAT:-6,FI_FLOAT:0,EW_FLOAT:-6,PP_STATUS:"On track",PPA_STATUS:"On track",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"On track",TBE_STATUS:"Behind plan",CBE_STATUS:"Behind plan",AR_STATUS:"Behind plan",ARA_STATUS:"Behind plan",LOA_STATUS:"Behind plan",PO_STATUS:"Behind plan",MDI_STATUS:"Behind plan",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"Behind plan",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-05-02",FLOATING_DAYS:2,FLOAT_STATUS:"Float<7"},
      {NO:2,PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0301",MR_DESCR:"C2R 3RD STAGE SUCTION DRUM,C3 RECTIFIER REFLUX DRUM,C3 REFRIG 1ST STAGE SUCTION DRUM,C3 REFRIG 2ND STAGE SUCTION DRUM,C3 REFRIG 3RD STAGE SUCTION DRUM,C3 REFRIG 4TH STAGE SUCTION DRUM,CG 2ND STAGE SUCTION DRUM,CG 3RD STAGE SUCTION DRUM,CG 4TH STAGE SUCTION DRUM,COLD FLARE KNOCKOUT DRUM,DILUTION STEAM GENERATOR,WARM FLARE KNOCKOUT DRUM",SEQ_NO:11,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"IV - Non-Critical",TAG_TOTAL:12,QTY:12,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:4,TBE_FLOAT:10,CBE_FLOAT:28,AR_FLOAT:48,ARA_FLOAT:51,LOA_FLOAT:51,PO_FLOAT:51,MDI_FLOAT:51,FI_FLOAT:0,EW_FLOAT:51,PP_STATUS:"Complete",PPA_STATUS:"On track",MR_STATUS:"On track",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-05-01",FLOATING_DAYS:5,FLOAT_STATUS:"Float<7"},
      {NO:3,PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0302",MR_DESCR:"C2 REFRIG 4TH STAGE DRUM,C2R 1ST STAGE SUCTION DRUM,C2R 2ND STAGE SUCTION DRUM,C2R BLOWDOWN DRUM,C2R SURGE DRUM,C3 HYDROGENATION RECYCLE DRUM,C3R BLOWDOWN DRUM,DEETHANIZER REFLUX DRUM,DEMETH PREFRAC FEED SEPARATOR NO 1,DEMETH PREFRAC FEED SEPARATOR NO 2,DEMETH PREFRAC FEED SEPARATOR NO 3,EXPANDER DISCHARGE DRUM,EXPANDER SUCTION DRUM,HP DEPROPANIZER REFLUX DRUM,LP DEPROPANIZER REFLUX DRUM,PROPYLENE REFRIGERANT RECEIVER",SEQ_NO:11,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"IV - Non-Critical",TAG_TOTAL:16,QTY:16,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:4,TBE_FLOAT:10,CBE_FLOAT:28,AR_FLOAT:48,ARA_FLOAT:51,LOA_FLOAT:51,PO_FLOAT:51,MDI_FLOAT:51,FI_FLOAT:0,EW_FLOAT:51,PP_STATUS:"Complete",PPA_STATUS:"On track",MR_STATUS:"On track",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-05-01",FLOATING_DAYS:9,FLOAT_STATUS:"Float>=7<30"},
      {NO:4,PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0407",MR_DESCR:"CAUSTIC TOWER FEED HEATER,CG 1ST STAGE AFTERCOOLERS,CG 2ND STAGE AFTERCOOLERS,CG 3RD STAGE AFTERCOOLERS,CG 4TH STAGE AFTERCOOLER,COLD FLARE BLOWDOWN VAPORIZER,COLD FLARE SUPERHEATER,METHANOL VAPORIZER,RAW PYGAS COOLER",SEQ_NO:11,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"II - Medium",TAG_TOTAL:21,QTY:69,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:6,TBE_FLOAT:15,CBE_FLOAT:31,AR_FLOAT:42,ARA_FLOAT:42,LOA_FLOAT:42,PO_FLOAT:42,MDI_FLOAT:42,FI_FLOAT:0,EW_FLOAT:42,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"On track",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-04-15",FLOATING_DAYS:-8,FLOAT_STATUS:"Float<0"},
      {NO:5,PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0408",MR_DESCR:"C3 HYDROGENATION AFTERCOOLER,C3 RECTIFIER REBOILER,C3 RECTIFIER VENT CONDENSER,C3 STRIPPER QW REBOILERS,DEETHANIZER REBOILER,DEMETH PREFRAC REBOILER,ETHYLENE PRODUCT COOLER,HP DEPROP FEED OVERHEAD EXCHANGER,HP DEPROPANIZER BOTTOMS COOLER,HP DEPROPANIZER REFLUX CHILLER NO 3,HP DEPROPANIZER STEAM REBOILER,LP DEPROPANIZER STEAM REBOILER,LPG SUPERHEATER,LPG VAPORIZER,PROPYLENE PRODUCT COOLER,RECYCLE PROPANE REBOILER,REGENERATION GAS FEED HEATER",SEQ_NO:11,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"III",TAG_TOTAL:25,QTY:47,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:9,TBE_FLOAT:30,CBE_FLOAT:39,AR_FLOAT:50,ARA_FLOAT:55,LOA_FLOAT:55,PO_FLOAT:55,MDI_FLOAT:55,FI_FLOAT:0,EW_FLOAT:55,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"On track",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-04-15",FLOATING_DAYS:-4,FLOAT_STATUS:"Float<0"},
      {NO:6,PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0409",MR_DESCR:"DEBUTANIZER REBOILERS,DISTILLATE STRIPPER REBOILER,DSG BLOWDOWN PRECOOLER,DSG BLOWDOWN WATER COOLER,FO PRODUCT COOLER,PRIMARY QUENCH WATER TRIM COOLER,PROCESS WATER PURGE CONDENSER,QO TRIM COOLER,REGENERATION GAS EFFLUENT COOLER,RESIDUE GAS COOLER,SECONDARY QUENCH WATER COOLERS,STRIPPED QUENCH OIL COOLER,WAO WASH WATER CIRCULATING COOLER,WARM BLOWDOWN COOLER,WASTE WATERLPS EXCHANGER",SEQ_NO:11,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"IV - Non-Critical",TAG_TOTAL:23,QTY:47,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:4,TBE_FLOAT:25,CBE_FLOAT:34,AR_FLOAT:45,ARA_FLOAT:50,LOA_FLOAT:50,PO_FLOAT:50,MDI_FLOAT:50,FI_FLOAT:0,EW_FLOAT:50,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"On track",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-04-15",FLOATING_DAYS:-3,FLOAT_STATUS:"Float<0"},
      {NO:7,PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0410",MR_DESCR:"BUTENE1 PRODUCT COOLER,BUTENE1 SPLITTER CONDENSER,BUTENE1 SPLITTER REBOILERx000D,C2 HYDROG 1ST STAGE INTERCOOLER,C2 HYDROG 2ND STAGE INTERCOOLER,C2 HYDROG AFTERCOOLER,C2 HYDROG FEED HEATER,C2 HYDROG THIRD STAGE HEATER,C2 HYDROGENATION FEED COOLER,C4 SPLITTER CONDENSERx000D,C4 SPLITTER REBOILER,CONTAMINATED PROCESS WATER COOLER,DEBUTANIZER CONDENSER,DILUTION STEAM SUPERHEATER,DSG FEED HEATER,DSG STEAM REBOILERS,FRN NAPHTHA HEATER,FUEL GAS COOLER,IMPORT QUENCH OIL HEATER,LLP Polluted Condensate Exchanger,LVN NAPHTHA HEATER,PROCESS WATER STRIPPER FEED HEATER,PROCESS WATER STRIPPER REBOILER,RAFF3 PRODUCT COOLER,RECYCLE GAS FEED SUPERHEATER,REGENERATION GAS FEEDEFFLUENT EXCH,Vent Steam Exchanger,VLP STEAM GENERATOR,WASH WATER COOLER",SEQ_NO:11,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"IV - Non-Critical",TAG_TOTAL:39,QTY:85,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:4,TBE_FLOAT:25,CBE_FLOAT:34,AR_FLOAT:45,ARA_FLOAT:50,LOA_FLOAT:50,PO_FLOAT:50,MDI_FLOAT:50,FI_FLOAT:0,EW_FLOAT:50,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"On track",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-04-15",FLOATING_DAYS:27,FLOAT_STATUS:"Float>=7<30"},
      {NO:8,PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-LL01",MR_DESCR:"Primary TLEs DoublePipe Exchangers",SEQ_NO:11,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"I",TAG_TOTAL:80,QTY:640,PP_FLOAT:0,PPA_FLOAT:22,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:-1,TBE_FLOAT:8,CBE_FLOAT:16,AR_FLOAT:21,ARA_FLOAT:25,LOA_FLOAT:25,PO_FLOAT:25,MDI_FLOAT:31,FI_FLOAT:0,EW_FLOAT:31,SA_FLOAT:31,CC_FLOAT:31,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"On track",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"On track",LOA_STATUS:"Complete",PO_STATUS:"Complete",MDI_STATUS:"Complete",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"No schedule",RAS_ROS:"2023-08-31",FLOATING_DAYS:19,FLOAT_STATUS:"Float>=7<30"},
      {NO:9,PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-LL03",MR_DESCR:"CAUSTIC TOWER1600 KCS KCS 48 5445,PRIMARY FRACTIONATOR KCS KCS 316316L SS 14 507,QUENCH WATER TOWER KCS KCS 113 404",SEQ_NO:11,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"I",TAG_TOTAL:3,QTY:3,PP_FLOAT:0,PPA_FLOAT:-1,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:-7,TBE_FLOAT:-18,CBE_FLOAT:-14,AR_FLOAT:6,ARA_FLOAT:8,LOA_FLOAT:11,PO_FLOAT:11,MDI_FLOAT:16,FI_FLOAT:0,EW_FLOAT:16,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"On track",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"On track",LOA_STATUS:"Complete",PO_STATUS:"Complete",MDI_STATUS:"Complete",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2022-12-10",FLOATING_DAYS:25,FLOAT_STATUS:"Float>=7<30"},
      {NO:10,PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-LL04",MR_DESCR:"C2 Splitter 304LSS 304LSS 6 6972,DEMETH PREFRAC REFLUX DRUM,Demethanizer PrefractionatorSS 304LSS 304LSS 52 48",SEQ_NO:11,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"II",TAG_TOTAL:3,QTY:3,PP_FLOAT:0,PPA_FLOAT:9,MR_FLOAT:-13,RFQ_FLOAT:-13,BC_FLOAT:-13,TBE_FLOAT:-1,CBE_FLOAT:6,AR_FLOAT:7,ARA_FLOAT:5,LOA_FLOAT:8,PO_FLOAT:8,MDI_FLOAT:1,FI_FLOAT:0,EW_FLOAT:1,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Behind plan",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"On track",LOA_STATUS:"Complete",PO_STATUS:"Complete",MDI_STATUS:"Complete",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2022-11-05",FLOATING_DAYS:5,FLOAT_STATUS:"Float<7"},
      {NO:11,PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-LL05",MR_DESCR:"C3 RECTIFIER 2 LTCS CS87 8742,C3 STRIPPERC3 1 LTCS CS75 46",SEQ_NO:11,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"I",TAG_TOTAL:2,QTY:2,PP_FLOAT:0,PPA_FLOAT:9,MR_FLOAT:-13,RFQ_FLOAT:-13,BC_FLOAT:-13,TBE_FLOAT:-1,CBE_FLOAT:6,AR_FLOAT:7,ARA_FLOAT:8,LOA_FLOAT:11,PO_FLOAT:11,MDI_FLOAT:16,FI_FLOAT:0,EW_FLOAT:16,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Behind plan",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"On track",LOA_STATUS:"Complete",PO_STATUS:"Complete",MDI_STATUS:"Complete",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2022-11-05",FLOATING_DAYS:-10,FLOAT_STATUS:"Float<0"},
      {NO:12,PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL05",MR_DESCR:"DEETHANIZER 304L SS 38 43,DEMETHANIZER 1600 304L SS 34 5035,DEMETHANIZER REFLUX DRUM1600 304L SS 21 33 304L SS,RESIDUE GAS RECTIFIER 304L SS 21 172",SEQ_NO:11,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"II",TAG_TOTAL:5,QTY:7,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:13,TBE_FLOAT:-49,CBE_FLOAT:-36,AR_FLOAT:-34,ARA_FLOAT:-23,LOA_FLOAT:15,PO_FLOAT:15,MDI_FLOAT:4,FI_FLOAT:0,EW_FLOAT:4,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"On track",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"Behind plan",LOA_STATUS:"Complete",PO_STATUS:"Complete",MDI_STATUS:"Complete",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-02-15",FLOATING_DAYS:-129,FLOAT_STATUS:"Float<0"},
      {NO:13,PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL06",MR_DESCR:"HP DEPROPANIZER 1600 LTCS 49 4425 TOP cold BTM hot",SEQ_NO:11,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"II",TAG_TOTAL:1,QTY:1,PP_FLOAT:0,PPA_FLOAT:-20,MR_FLOAT:-17,RFQ_FLOAT:-10,BC_FLOAT:-21,TBE_FLOAT:-11,CBE_FLOAT:-2,AR_FLOAT:9,ARA_FLOAT:16,LOA_FLOAT:37,PO_FLOAT:27,MDI_FLOAT:38,FI_FLOAT:0,EW_FLOAT:38,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Behind plan",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"On track",LOA_STATUS:"Complete",PO_STATUS:"Complete",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2022-10-20",FLOATING_DAYS:-247,FLOAT_STATUS:"Float<0"},
      {NO:14,PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL10",MR_DESCR:"Steam Drums",SEQ_NO:11,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"II",TAG_TOTAL:10,QTY:42,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:25,TBE_FLOAT:26,CBE_FLOAT:19,AR_FLOAT:40,ARA_FLOAT:40,LOA_FLOAT:40,PO_FLOAT:40,MDI_FLOAT:66,FI_FLOAT:0,EW_FLOAT:40,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"On track",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-04-30",FLOATING_DAYS:-55,FLOAT_STATUS:"Float<0"},
      {NO:15,PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL11",MR_DESCR:"ARSINE REMOVAL BED,CG LIQUID DRYERS,CRACKED GAS DRYERS,CRACKED GAS SECONDARY DRYER,MERCURY ADSORBER",SEQ_NO:11,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"III - Low",TAG_TOTAL:8,QTY:16,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:4,TBE_FLOAT:-4,CBE_FLOAT:14,AR_FLOAT:34,ARA_FLOAT:37,LOA_FLOAT:37,PO_FLOAT:37,MDI_FLOAT:63,FI_FLOAT:0,EW_FLOAT:37,PP_STATUS:"Complete",PPA_STATUS:"On track",MR_STATUS:"On track",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Behind plan",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-04-01",FLOATING_DAYS:-84,FLOAT_STATUS:"Float<0"},
      {NO:16,PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL12",MR_DESCR:"LP DEPROPANIZER 1600 LTCS 44 552 TOP cold BTM hot,RECYCLE PROPANE HEAVIES REMOVAL TOWER LTCS 1 1215",SEQ_NO:11,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"II",TAG_TOTAL:2,QTY:2,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:-2,TBE_FLOAT:-11,CBE_FLOAT:13,AR_FLOAT:20,ARA_FLOAT:31,LOA_FLOAT:15,PO_FLOAT:15,MDI_FLOAT:4,FI_FLOAT:0,EW_FLOAT:4,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"On track",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"On track",LOA_STATUS:"Complete",PO_STATUS:"Complete",MDI_STATUS:"Complete",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-02-15",FLOATING_DAYS:-129,FLOAT_STATUS:"Float<0"},
      {NO:17,PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-FF-R-E-3801",MR_DESCR:"Monitor",SEQ_NO:11,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:0,QTY:0,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:-7,RFQ_FLOAT:0,BC_FLOAT:11,TBE_FLOAT:-160,CBE_FLOAT:-149,AR_FLOAT:-149,ARA_FLOAT:-149,LOA_FLOAT:-137,PO_FLOAT:-137,MDI_FLOAT:-111,FI_FLOAT:-137,EW_FLOAT:-137,SA_FLOAT:-137,CC_FLOAT:-137,PP_STATUS:"On track",PPA_STATUS:"On track",MR_STATUS:"Behind plan",RFQ_STATUS:"On track",BC_STATUS:"On track",TBE_STATUS:"Behind plan",CBE_STATUS:"Behind plan",AR_STATUS:"Behind plan",ARA_STATUS:"Behind plan",LOA_STATUS:"Behind plan",PO_STATUS:"Behind plan",MDI_STATUS:"Behind plan",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"Behind plan",EW_STATUS:"Behind plan",SA_STATUS:"Behind plan",CC_STATUS:"Behind plan",DJS_STATUS:"No schedule",RAS_ROS:"2023-08-10",FLOATING_DAYS:-105,FLOAT_STATUS:"Float<0"},
      {NO:18,PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-FU-R-E-SL02",MR_DESCR:"Convection Section ModuleRadiant Steel Structure f",SEQ_NO:11,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"II",TAG_TOTAL:10,QTY:100,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:-42,RFQ_FLOAT:-46,BC_FLOAT:-3,TBE_FLOAT:2,CBE_FLOAT:-57,AR_FLOAT:-57,ARA_FLOAT:-47,LOA_FLOAT:-47,PO_FLOAT:-87,MDI_FLOAT:-87,FI_FLOAT:0,EW_FLOAT:-53,SA_FLOAT:-53,CC_FLOAT:-62,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Behind plan",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"Behind plan",LOA_STATUS:"Complete",PO_STATUS:"Behind plan",MDI_STATUS:"Behind plan",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"Behind plan",SA_STATUS:"Behind plan",CC_STATUS:"Behind plan",DJS_STATUS:"No schedule",RAS_ROS:"2023-04-28",FLOATING_DAYS:-57,FLOAT_STATUS:"Float<0"},
      {NO:19,PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-FU-R-E-SL04",MR_DESCR:"Ethylene Furnace Refractory for 12F1001 13F1001 18",SEQ_NO:11,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"I",TAG_TOTAL:0,QTY:5,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:4,TBE_FLOAT:-7,CBE_FLOAT:-6,AR_FLOAT:15,ARA_FLOAT:29,LOA_FLOAT:29,PO_FLOAT:29,MDI_FLOAT:29,FI_FLOAT:0,EW_FLOAT:30,SA_FLOAT:30,CC_FLOAT:21,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"On track",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"On track",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"No schedule",RAS_ROS:"2023-06-01",FLOATING_DAYS:-23,FLOAT_STATUS:"Float<0"},
      {NO:20,PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-FU-R-E-SL05",MR_DESCR:"Ethylene Furnace Refractory for 11F1001 14F1001 15",SEQ_NO:11,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"I",TAG_TOTAL:0,QTY:5,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:4,TBE_FLOAT:-7,CBE_FLOAT:-6,AR_FLOAT:15,ARA_FLOAT:29,LOA_FLOAT:29,PO_FLOAT:29,MDI_FLOAT:29,FI_FLOAT:0,EW_FLOAT:30,SA_FLOAT:30,CC_FLOAT:21,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"On track",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"On track",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"No schedule",RAS_ROS:"2023-06-01",FLOATING_DAYS:-23,FLOAT_STATUS:"Float<0"},
      {NO:21,PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-LG-R-E-SL01",MR_DESCR:"Selective Catalytic Reduction Beds SCR",SEQ_NO:11,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"II",TAG_TOTAL:4,QTY:4,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:-1,RFQ_FLOAT:-22,BC_FLOAT:-22,TBE_FLOAT:-57,CBE_FLOAT:-62,AR_FLOAT:-105,ARA_FLOAT:-115,LOA_FLOAT:-102,PO_FLOAT:-102,MDI_FLOAT:-102,FI_FLOAT:0,EW_FLOAT:-100,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Behind plan",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"Behind plan",LOA_STATUS:"Complete",PO_STATUS:"Behind plan",MDI_STATUS:"Behind plan",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"Behind plan",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-03-31",FLOATING_DAYS:-85,FLOAT_STATUS:"Float<0"},
      {NO:22,PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-UL01",MR_DESCR:"CRACKED GAS COMPRESSOR,ETHYLENE REFRIGHEAT PUMP COMPRESSOR,PROPYLENE REFRIG COMPRESSOR",SEQ_NO:11,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"I",TAG_TOTAL:3,QTY:3,PP_FLOAT:-18,PPA_FLOAT:-18,MR_FLOAT:-19,RFQ_FLOAT:-18,BC_FLOAT:-11,TBE_FLOAT:0,CBE_FLOAT:-3,AR_FLOAT:-14,ARA_FLOAT:-8,LOA_FLOAT:-1,PO_FLOAT:1,MDI_FLOAT:1,FI_FLOAT:0,EW_FLOAT:1,SA_FLOAT:1,CC_FLOAT:1,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Behind plan",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"Behind plan",LOA_STATUS:"Complete",PO_STATUS:"Complete",MDI_STATUS:"Complete",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"No schedule",RAS_ROS:"2023-10-30",FLOATING_DAYS:19,FLOAT_STATUS:"Float>=7<30"},
      {NO:23,PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-TM-R-E-0904",MR_DESCR:"AUTOCIRC VALVE",SEQ_NO:11,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"III - Low",TAG_TOTAL:3,QTY:9,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:5,TBE_FLOAT:-6,CBE_FLOAT:-6,AR_FLOAT:-6,ARA_FLOAT:-6,LOA_FLOAT:-6,PO_FLOAT:-6,MDI_FLOAT:-6,FI_FLOAT:0,EW_FLOAT:-6,PP_STATUS:"On track",PPA_STATUS:"On track",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"On track",TBE_STATUS:"Behind plan",CBE_STATUS:"Behind plan",AR_STATUS:"Behind plan",ARA_STATUS:"Behind plan",LOA_STATUS:"Behind plan",PO_STATUS:"Behind plan",MDI_STATUS:"Behind plan",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"Behind plan",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-04-30",FLOATING_DAYS:0,FLOAT_STATUS:"Float<7"},
      {NO:24,PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-TM-R-E-0905",MR_DESCR:"SILENCER",SEQ_NO:11,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"IV - Non-Critical",TAG_TOTAL:0,QTY:27,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:5,TBE_FLOAT:-6,CBE_FLOAT:-6,AR_FLOAT:-6,ARA_FLOAT:-6,LOA_FLOAT:-6,PO_FLOAT:-6,MDI_FLOAT:-6,FI_FLOAT:0,EW_FLOAT:-6,PP_STATUS:"On track",PPA_STATUS:"On track",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"On track",TBE_STATUS:"Behind plan",CBE_STATUS:"Behind plan",AR_STATUS:"Behind plan",ARA_STATUS:"Behind plan",LOA_STATUS:"Behind plan",PO_STATUS:"Behind plan",MDI_STATUS:"Behind plan",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"Behind plan",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-05-01",FLOATING_DAYS:1,FLOAT_STATUS:"Float<7"},
      {NO:25,PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0102",MR_DESCR:"ACETYLENE WASHER,BUTADIENE COLUMN,COOLING COLUMN,DEGASSER,PROPYNE COLUMN,water wash columnC4",SEQ_NO:12,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"IV",TAG_TOTAL:6,QTY:6,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:339,TBE_FLOAT:-5,CBE_FLOAT:-5,AR_FLOAT:-6,ARA_FLOAT:-1,LOA_FLOAT:-16,PO_FLOAT:-16,MDI_FLOAT:-16,FI_FLOAT:0,EW_FLOAT:-18,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"On track",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"Behind plan",LOA_STATUS:"Complete",PO_STATUS:"Complete",MDI_STATUS:"Behind plan",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"Behind plan",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-02-15",FLOATING_DAYS:-312,FLOAT_STATUS:"Float<0"},
      {NO:26,PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-SL01",MR_DESCR:"AFTERWASHER CONDENSER,BUTADIENE COLUMN CONDENSER,PROPYNE COLUMN CONDENSER",SEQ_NO:12,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"III",TAG_TOTAL:4,QTY:6,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:11,TBE_FLOAT:6,CBE_FLOAT:6,AR_FLOAT:7,ARA_FLOAT:25,LOA_FLOAT:25,PO_FLOAT:7,MDI_FLOAT:7,FI_FLOAT:0,EW_FLOAT:17,SA_FLOAT:17,CC_FLOAT:8,PP_STATUS:"Complete",PPA_STATUS:"On track",MR_STATUS:"On track",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"On track",LOA_STATUS:"Complete",PO_STATUS:"Complete",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"No schedule",RAS_ROS:"2023-05-17",FLOATING_DAYS:-38,FLOAT_STATUS:"Float<0"},
      {NO:27,PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-LL01",MR_DESCR:"Recycle Gas Compressor",SEQ_NO:12,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"II",TAG_TOTAL:1,QTY:1,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:-54,RFQ_FLOAT:-47,BC_FLOAT:-40,TBE_FLOAT:-15,CBE_FLOAT:-10,AR_FLOAT:-10,ARA_FLOAT:-11,LOA_FLOAT:4,PO_FLOAT:18,MDI_FLOAT:5,FI_FLOAT:5,EW_FLOAT:5,SA_FLOAT:5,CC_FLOAT:5,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Behind plan",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"Behind plan",LOA_STATUS:"Complete",PO_STATUS:"Complete",MDI_STATUS:"Complete",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"No schedule",RAS_ROS:"2023-06-01",FLOATING_DAYS:-3,FLOAT_STATUS:"Float<0"},
      {NO:28,PROCESS_UNIT:"BU",MR_NO:"75231-P1-1848-IN-R-E-5131",MR_DESCR:"Continuous Emission Monitoring System CEMS CEMS",SEQ_NO:12,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"III - Low",TAG_TOTAL:0,QTY:2,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:-1,TBE_FLOAT:4,CBE_FLOAT:0,AR_FLOAT:0,ARA_FLOAT:0,LOA_FLOAT:0,PO_FLOAT:0,MDI_FLOAT:0,FI_FLOAT:0,EW_FLOAT:0,PP_STATUS:"Complete",PPA_STATUS:"On track",MR_STATUS:"On track",RFQ_STATUS:"Complete",BC_STATUS:"Behind plan",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-06-30",FLOATING_DAYS:-19,FLOAT_STATUS:"Float<0"},
      {NO:29,PROCESS_UNIT:"BU",MR_NO:"75231-P1-1848-IN-R-E-5710",MR_DESCR:"General Butterfly ValvePneu OnoffMotor Operated fo",SEQ_NO:12,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"III-Low",TAG_TOTAL:0,QTY:1,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:-29,TBE_FLOAT:-24,CBE_FLOAT:0,AR_FLOAT:0,ARA_FLOAT:0,LOA_FLOAT:0,PO_FLOAT:0,MDI_FLOAT:0,FI_FLOAT:0,EW_FLOAT:0,PP_STATUS:"Complete",PPA_STATUS:"On track",MR_STATUS:"On track",RFQ_STATUS:"Complete",BC_STATUS:"Behind plan",TBE_STATUS:"Behind plan",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-06-30",FLOATING_DAYS:11,FLOAT_STATUS:"Float>=7<30"},
      {NO:30,PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0301",MR_DESCR:"CD REACTION COLUMN REBOILER,CD REACTION COLUMN TRIM CONDENSER,DEBUTANIZER COLUMN CONDENSER,DEBUTANIZER COLUMN FEEDBOTTOMS EXCHANGER,DEBUTANIZER COLUMN REBOILER,DIBMTBE SECONDARY REACTOR FEED HEATERCOOLER,DIBMTBE UNIT FEED HEATER,METHANOL RECOVERY COLUMN FEEDBOTTOM EXCHANGER,MTBE PRODUCT COOLER",SEQ_NO:13,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"IV",TAG_TOTAL:9,QTY:9,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:4,TBE_FLOAT:1,CBE_FLOAT:19,AR_FLOAT:39,ARA_FLOAT:42,LOA_FLOAT:42,PO_FLOAT:42,MDI_FLOAT:42,FI_FLOAT:0,EW_FLOAT:42,PP_STATUS:"Complete",PPA_STATUS:"On track",MR_STATUS:"On track",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-05-15",FLOATING_DAYS:28,FLOAT_STATUS:"Float>=7<30"},
      {NO:31,PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0402",MR_DESCR:"DIBMTBE REACTOR COOLERDIBMTBE",SEQ_NO:13,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"III ",TAG_TOTAL:1,QTY:1,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:24,TBE_FLOAT:21,CBE_FLOAT:22,AR_FLOAT:22,ARA_FLOAT:22,LOA_FLOAT:22,PO_FLOAT:22,MDI_FLOAT:22,FI_FLOAT:0,EW_FLOAT:22,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"On track",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-04-30",FLOATING_DAYS:15,FLOAT_STATUS:"Float>=7<30"},
      {NO:32,PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0403",MR_DESCR:"C12 PRODUCT TRIM COOLER,CD REACTION COLUMN REBOILER,CD REACTION COLUMN TRIM CONDENSER,CS DIBMTBE UNIT FEED HEATER,DEBUTANIZER COLUMN CONDENSER,DEBUTANIZER COLUMN FEEDBOTTOMS EXCHANGER,DEBUTANIZER COLUMN REBOILER,DIBMTBE SECONDARY REACTOR FEED HEATERCOOLER,ISOOCTENE COLUMN CONDENSER,ISOOCTENE COLUMN REBOILER,METHANOL RECOVERY COLUMN FEEDBOTTOM EXCHANGER,METHANOL RECOVERY COLUMN REBOILER,MTBE PRODUCT COOLER,MTBEDIB SPLITTER CONDENSER,MTBEDIB SPLITTER REBOILER,RECYCLE WATER COOLER,WARM BLOWDOWN COOLER",SEQ_NO:13,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"IV",TAG_TOTAL:17,QTY:17,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:9,TBE_FLOAT:26,CBE_FLOAT:31,AR_FLOAT:49,ARA_FLOAT:52,LOA_FLOAT:52,PO_FLOAT:52,MDI_FLOAT:52,FI_FLOAT:0,EW_FLOAT:52,PP_STATUS:"Complete",PPA_STATUS:"On track",MR_STATUS:"On track",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-05-15",FLOATING_DAYS:15,FLOAT_STATUS:"Float>=7<30"},
      {NO:33,PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0404",MR_DESCR:"METHANOL RECOVERY COLUMN CONDENSER",SEQ_NO:13,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"III - Low",TAG_TOTAL:1,QTY:1,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:21,TBE_FLOAT:22,CBE_FLOAT:37,AR_FLOAT:55,ARA_FLOAT:55,LOA_FLOAT:55,PO_FLOAT:55,MDI_FLOAT:55,FI_FLOAT:0,EW_FLOAT:55,PP_STATUS:"On track",PPA_STATUS:"On track",MR_STATUS:"On track",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-05-15",FLOATING_DAYS:15,FLOAT_STATUS:"Float>=7<30"},
      {NO:34,PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0201",MR_DESCR:"HD1 REACTOR,HD2 REACTOR",SEQ_NO:14,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"II",TAG_TOTAL:2,QTY:2,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:22,TBE_FLOAT:-18,CBE_FLOAT:-18,AR_FLOAT:-18,ARA_FLOAT:-18,LOA_FLOAT:-18,PO_FLOAT:-18,MDI_FLOAT:-18,FI_FLOAT:0,EW_FLOAT:-18,PP_STATUS:"On track",PPA_STATUS:"Complete",MR_STATUS:"On track",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Behind plan",CBE_STATUS:"Behind plan",AR_STATUS:"Behind plan",ARA_STATUS:"Behind plan",LOA_STATUS:"Behind plan",PO_STATUS:"Behind plan",MDI_STATUS:"Behind plan",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"Behind plan",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-04-21",FLOATING_DAYS:19,FLOAT_STATUS:"Float>=7<30"},
      {NO:35,PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-IN-R-E-5701",MR_DESCR:"General Service Globe Control Valve",SEQ_NO:14,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"III - Low",TAG_TOTAL:0,QTY:78,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:-13,TBE_FLOAT:-8,CBE_FLOAT:-8,AR_FLOAT:-8,ARA_FLOAT:-8,LOA_FLOAT:-8,PO_FLOAT:-8,MDI_FLOAT:-8,FI_FLOAT:0,EW_FLOAT:-8,PP_STATUS:"On track",PPA_STATUS:"On track",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"Behind plan",TBE_STATUS:"Behind plan",CBE_STATUS:"Behind plan",AR_STATUS:"Behind plan",ARA_STATUS:"Behind plan",LOA_STATUS:"Behind plan",PO_STATUS:"Behind plan",MDI_STATUS:"Behind plan",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"Behind plan",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-11-30",FLOATING_DAYS:-18,FLOAT_STATUS:"Float<0"},
      {NO:36,PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-IN-R-E-5701",MR_DESCR:"General Service Globe Control Valve",SEQ_NO:15,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"III - Low",TAG_TOTAL:0,QTY:31,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:-13,TBE_FLOAT:-8,CBE_FLOAT:-8,AR_FLOAT:-8,ARA_FLOAT:-8,LOA_FLOAT:-8,PO_FLOAT:-8,MDI_FLOAT:-8,FI_FLOAT:0,EW_FLOAT:-8,PP_STATUS:"On track",PPA_STATUS:"On track",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"Behind plan",TBE_STATUS:"Behind plan",CBE_STATUS:"Behind plan",AR_STATUS:"Behind plan",ARA_STATUS:"Behind plan",LOA_STATUS:"Behind plan",PO_STATUS:"Behind plan",MDI_STATUS:"Behind plan",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"Behind plan",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-11-30",FLOATING_DAYS:-18,FLOAT_STATUS:"Float<0"},
      {NO:37,PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-EQ-R-E-0301",MR_DESCR:"Boiler Fuel Gas KO Drum,Continous Blowdown Drum,Intermittent Blowdown Drum",SEQ_NO:16,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"IV",TAG_TOTAL:3,QTY:3,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:4,TBE_FLOAT:1,CBE_FLOAT:19,AR_FLOAT:39,ARA_FLOAT:42,LOA_FLOAT:42,PO_FLOAT:42,MDI_FLOAT:42,FI_FLOAT:0,EW_FLOAT:42,PP_STATUS:"Complete",PPA_STATUS:"On track",MR_STATUS:"On track",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-05-01",FLOATING_DAYS:14,FLOAT_STATUS:"Float>=7<30"},
      {NO:38,PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-RE-R-E-SL01",MR_DESCR:"HP BFW PUMP,HP BFW PUMP TURBINE,SHP BFW PUMP,SHP BFW PUMP TURBINE",SEQ_NO:16,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"II",TAG_TOTAL:9,QTY:10,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:-12,TBE_FLOAT:-44,CBE_FLOAT:-45,AR_FLOAT:-46,ARA_FLOAT:-56,LOA_FLOAT:-56,PO_FLOAT:-56,MDI_FLOAT:-56,FI_FLOAT:0,EW_FLOAT:-56,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"On track",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Behind plan",ARA_STATUS:"Behind plan",LOA_STATUS:"Behind plan",PO_STATUS:"Behind plan",MDI_STATUS:"Behind plan",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"Behind plan",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-06-20",FLOATING_DAYS:-24,FLOAT_STATUS:"Float<0"},
      {NO:39,PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-0901",MR_DESCR:"DEAERATOR PACKAGE",SEQ_NO:16,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"II - Medium",TAG_TOTAL:1,QTY:1,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:16,TBE_FLOAT:30,CBE_FLOAT:37,AR_FLOAT:55,ARA_FLOAT:57,LOA_FLOAT:57,PO_FLOAT:57,MDI_FLOAT:57,FI_FLOAT:0,EW_FLOAT:57,PP_STATUS:"On track",PPA_STATUS:"Complete",MR_STATUS:"On track",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-04-15",FLOATING_DAYS:15,FLOAT_STATUS:"Float>=7<30"},
      {NO:40,PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1843-TM-R-E-0901",MR_DESCR:"CHEMICAL WATER TREATMENT PACKAGE",SEQ_NO:16,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"IV ",TAG_TOTAL:1,QTY:1,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:8,TBE_FLOAT:25,CBE_FLOAT:21,AR_FLOAT:18,ARA_FLOAT:21,LOA_FLOAT:21,PO_FLOAT:21,MDI_FLOAT:21,FI_FLOAT:0,EW_FLOAT:21,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"On track",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-02-15",FLOATING_DAYS:17,FLOAT_STATUS:"Float>=7<30"},
      {NO:41,PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1843-TM-R-E-0902",MR_DESCR:"CONDENSATE WATER TREATMENT PACKAGE",SEQ_NO:16,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"IV - Non-Critical",TAG_TOTAL:1,QTY:1,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:8,TBE_FLOAT:25,CBE_FLOAT:25,AR_FLOAT:25,ARA_FLOAT:25,LOA_FLOAT:25,PO_FLOAT:25,MDI_FLOAT:25,FI_FLOAT:0,EW_FLOAT:25,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"On track",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-02-15",FLOATING_DAYS:17,FLOAT_STATUS:"Float>=7<30"},
      {NO:42,PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1843-TM-R-E-0903",MR_DESCR:"AMMONIA ADDING PACKAGE",SEQ_NO:16,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"IV - Non-Critical",TAG_TOTAL:1,QTY:1,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:0,TBE_FLOAT:4,CBE_FLOAT:4,AR_FLOAT:4,ARA_FLOAT:4,LOA_FLOAT:4,PO_FLOAT:4,MDI_FLOAT:4,FI_FLOAT:0,EW_FLOAT:4,PP_STATUS:"On track",PPA_STATUS:"On track",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"On track",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-02-15",FLOATING_DAYS:-69,FLOAT_STATUS:"Float<0"},
      {NO:43,PROCESS_UNIT:"DW",MR_NO:"75231-P1-1843-TM-R-E-0904",MR_DESCR:"Demin Water Exchanger,Plate Heat Exchanger Package,Process Condensate Exchanger",SEQ_NO:17,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"IV - Non-Critical",TAG_TOTAL:5,QTY:1,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:19,TBE_FLOAT:16,CBE_FLOAT:14,AR_FLOAT:14,ARA_FLOAT:14,LOA_FLOAT:14,PO_FLOAT:14,MDI_FLOAT:14,FI_FLOAT:0,EW_FLOAT:14,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"On track",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-04-01",FLOATING_DAYS:-9,FLOAT_STATUS:"Float<0"},
      {NO:44,PROCESS_UNIT:"DW",MR_NO:"75231-P1-1843-EQ-R-E-0301",MR_DESCR:"Continous Blowdown Drum,Intermittent Blowdown Drum",SEQ_NO:17,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"IV",TAG_TOTAL:2,QTY:2,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:4,TBE_FLOAT:10,CBE_FLOAT:28,AR_FLOAT:48,ARA_FLOAT:51,LOA_FLOAT:51,PO_FLOAT:51,MDI_FLOAT:51,FI_FLOAT:0,EW_FLOAT:51,PP_STATUS:"Complete",PPA_STATUS:"On track",MR_STATUS:"On track",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-05-15",FLOATING_DAYS:19,FLOAT_STATUS:"Float>=7<30"},
      {NO:45,PROCESS_UNIT:"DW",MR_NO:"75231-P1-1843-RE-R-E-SL01",MR_DESCR:"Demin Water hot Feed Pump Turbine,Demin Waterhot Feed Pump",SEQ_NO:17,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"III",TAG_TOTAL:5,QTY:9,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:-12,TBE_FLOAT:-44,CBE_FLOAT:-45,AR_FLOAT:-46,ARA_FLOAT:-56,LOA_FLOAT:-56,PO_FLOAT:-56,MDI_FLOAT:-56,FI_FLOAT:0,EW_FLOAT:-56,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"On track",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Behind plan",ARA_STATUS:"Behind plan",LOA_STATUS:"Behind plan",PO_STATUS:"Behind plan",MDI_STATUS:"Behind plan",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"Behind plan",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-03-30",FLOATING_DAYS:-106,FLOAT_STATUS:"Float<0"},
      {NO:46,PROCESS_UNIT:"DWU",MR_NO:"75231-P1-1843-IN-R-E-5710",MR_DESCR:"General Butterfly ValvePneu OnoffMotor Operated fo",SEQ_NO:17,PSRNUM:"P1_PSR",CRITICALITY_RATING_LEVEL:"III-Low",TAG_TOTAL:0,QTY:6,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:-29,TBE_FLOAT:-24,CBE_FLOAT:0,AR_FLOAT:0,ARA_FLOAT:0,LOA_FLOAT:0,PO_FLOAT:0,MDI_FLOAT:0,FI_FLOAT:0,EW_FLOAT:0,PP_STATUS:"Complete",PPA_STATUS:"On track",MR_STATUS:"On track",RFQ_STATUS:"Complete",BC_STATUS:"Behind plan",TBE_STATUS:"Behind plan",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-06-30",FLOATING_DAYS:11,FLOAT_STATUS:"Float>=7<30"},
      {NO:47,PROCESS_UNIT:"GPPE",MR_NO:"75231-P2-1213-EQ-R-E-0301",MR_DESCR:"HP Flare Powder Separator and Seal Drum,Product Blow Tank 1,Product Blow Tank 2,Product Chamber 1,Product Chamber 2",SEQ_NO:21,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:9,QTY:9,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:3,BC_FLOAT:2,TBE_FLOAT:14,CBE_FLOAT:14,AR_FLOAT:14,ARA_FLOAT:14,LOA_FLOAT:14,PO_FLOAT:14,MDI_FLOAT:14,FI_FLOAT:14,EW_FLOAT:14,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-05-15",FLOATING_DAYS:8,FLOAT_STATUS:"Float>=7<30"},
      {NO:48,PROCESS_UNIT:"GPPE",MR_NO:"75231-P2-1213-EQ-R-E-0501",MR_DESCR:"Butene Dryer,CA1 Dryer,CA2 Dryer,Ethylene CO Removal Vessel,Ethylene Deoxo Vessel,Ethylene Purification Vessel,Hexene Dryer,HP Nitrogen Deoxo Vessel,HP Nitrogen Dryer,Nitrogen Deoxo Vessel,Nitrogen Dryer",SEQ_NO:21,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:15,QTY:23,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:14,BC_FLOAT:14,TBE_FLOAT:17,CBE_FLOAT:17,AR_FLOAT:17,ARA_FLOAT:17,LOA_FLOAT:17,PO_FLOAT:17,MDI_FLOAT:17,FI_FLOAT:17,EW_FLOAT:17,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-04-18",FLOATING_DAYS:-14,FLOAT_STATUS:"Float<0"},
      {NO:49,PROCESS_UNIT:"GPPE",MR_NO:"75231-P2-1213-EQ-R-E-SL04",MR_DESCR:"Catalyst Feeder",SEQ_NO:21,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:6,QTY:6,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:6,BC_FLOAT:7,TBE_FLOAT:11,CBE_FLOAT:11,AR_FLOAT:16,ARA_FLOAT:-5,LOA_FLOAT:-16,PO_FLOAT:-12,MDI_FLOAT:-12,FI_FLOAT:0,EW_FLOAT:0,SA_FLOAT:0,CC_FLOAT:0,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"Complete",LOA_STATUS:"Complete",PO_STATUS:"Complete",MDI_STATUS:"Behind plan",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"No schedule",RAS_ROS:"2023-07-06",FLOATING_DAYS:17,FLOAT_STATUS:"Float>=7<30"},
      {NO:50,PROCESS_UNIT:"GPPE",MR_NO:"75231-P2-1213-EQ-R-E-SL05",MR_DESCR:"Recovered Liquids Column,Vent Column",SEQ_NO:21,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:3,QTY:3,PP_FLOAT:0,PPA_FLOAT:2,MR_FLOAT:0,RFQ_FLOAT:7,BC_FLOAT:6,TBE_FLOAT:6,CBE_FLOAT:6,AR_FLOAT:10,ARA_FLOAT:10,LOA_FLOAT:10,PO_FLOAT:10,MDI_FLOAT:10,FI_FLOAT:10,EW_FLOAT:10,SA_FLOAT:10,CC_FLOAT:10,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"No schedule",RAS_ROS:"2023-05-25",FLOATING_DAYS:20,FLOAT_STATUS:"Float>=7<30"},
      {NO:51,PROCESS_UNIT:"GPPE",MR_NO:"75231-P2-1213-MH-R-E-0703",MR_DESCR:"CA Slurry Feed Container Weigh Scale,Catalyst Cylinder Weigh Scale",SEQ_NO:21,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:6,QTY:10,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:-61,RFQ_FLOAT:-61,BC_FLOAT:-61,TBE_FLOAT:-61,CBE_FLOAT:-61,AR_FLOAT:-61,ARA_FLOAT:-61,LOA_FLOAT:-61,PO_FLOAT:-61,MDI_FLOAT:-61,FI_FLOAT:-61,EW_FLOAT:-61,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Behind plan",RFQ_STATUS:"Behind plan",BC_STATUS:"Behind plan",TBE_STATUS:"Behind plan",CBE_STATUS:"Behind plan",AR_STATUS:"Behind plan",ARA_STATUS:"Behind plan",LOA_STATUS:"Behind plan",PO_STATUS:"Behind plan",MDI_STATUS:"Behind plan",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"Behind plan",EW_STATUS:"Behind plan",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-05-30",FLOATING_DAYS:21,FLOAT_STATUS:"Float>=7<30"},
      {NO:52,PROCESS_UNIT:"GPPE",MR_NO:"75231-P2-1213-MH-R-E-LL01",MR_DESCR:"Delumper",SEQ_NO:21,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:2,QTY:2,PP_FLOAT:0,PPA_FLOAT:7,MR_FLOAT:1,RFQ_FLOAT:-1,BC_FLOAT:-8,TBE_FLOAT:-8,CBE_FLOAT:-10,AR_FLOAT:-8,ARA_FLOAT:2,LOA_FLOAT:1,PO_FLOAT:-12,MDI_FLOAT:-1,MO_FLOAT:0,MMR_FLOAT:0,FI_FLOAT:-1,EW_FLOAT:-1,SA_FLOAT:-1,CC_FLOAT:-1,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"Complete",LOA_STATUS:"Complete",PO_STATUS:"Complete",MDI_STATUS:"Behind plan",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"Behind plan",EW_STATUS:"Behind plan",SA_STATUS:"Behind plan",CC_STATUS:"Behind plan",DJS_STATUS:"No schedule",RAS_ROS:"2023-05-30",FLOATING_DAYS:7,FLOAT_STATUS:"Float>=7<30"},
      {NO:53,PROCESS_UNIT:"GPPE",MR_NO:"75231-P2-1213-MH-R-E-SL02",MR_DESCR:"51 Area LossInWeight Feeders Package,52 Area LossInWeight Feeders Package,Pellet Mass Flow Meter,Solid Additives Package",SEQ_NO:21,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:5,QTY:5,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:1,BC_FLOAT:-34,TBE_FLOAT:-32,CBE_FLOAT:-27,AR_FLOAT:-81,ARA_FLOAT:-81,LOA_FLOAT:-65,PO_FLOAT:-65,MDI_FLOAT:-65,FI_FLOAT:-65,EW_FLOAT:-65,SA_FLOAT:-65,CC_FLOAT:-65,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Behind plan",ARA_STATUS:"Behind plan",LOA_STATUS:"Behind plan",PO_STATUS:"Behind plan",MDI_STATUS:"Behind plan",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"Behind plan",EW_STATUS:"Behind plan",SA_STATUS:"Behind plan",CC_STATUS:"Behind plan",DJS_STATUS:"No schedule",RAS_ROS:"2023-06-26",FLOATING_DAYS:-19,FLOAT_STATUS:"Float<0"},
      {NO:54,PROCESS_UNIT:"GPPE",MR_NO:"75231-P2-1213-MH-R-E-SL03",MR_DESCR:"Extruder Feed Conveyor",SEQ_NO:21,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:4,QTY:4,PP_FLOAT:0,PPA_FLOAT:7,MR_FLOAT:0,RFQ_FLOAT:25,BC_FLOAT:7,TBE_FLOAT:8,CBE_FLOAT:-43,AR_FLOAT:-40,ARA_FLOAT:-32,LOA_FLOAT:-42,PO_FLOAT:-38,MDI_FLOAT:-38,FI_FLOAT:-42,EW_FLOAT:-42,SA_FLOAT:-42,CC_FLOAT:-42,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"Complete",LOA_STATUS:"Complete",PO_STATUS:"Behind plan",MDI_STATUS:"Behind plan",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"Behind plan",EW_STATUS:"Behind plan",SA_STATUS:"Behind plan",CC_STATUS:"Behind plan",DJS_STATUS:"No schedule",RAS_ROS:"2023-06-28",FLOATING_DAYS:-122,FLOAT_STATUS:"Float<0"},
      {NO:55,PROCESS_UNIT:"GPPE",MR_NO:"75231-P2-1213-MH-R-E-SL05",MR_DESCR:"Pellet Classifier",SEQ_NO:21,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:4,QTY:4,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:18,BC_FLOAT:23,TBE_FLOAT:30,CBE_FLOAT:58,AR_FLOAT:50,ARA_FLOAT:48,LOA_FLOAT:29,PO_FLOAT:42,MDI_FLOAT:29,FI_FLOAT:29,EW_FLOAT:29,SA_FLOAT:29,CC_FLOAT:29,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"Complete",LOA_STATUS:"Complete",PO_STATUS:"Complete",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"No schedule",RAS_ROS:"2023-07-10",FLOATING_DAYS:4,FLOAT_STATUS:"Float<7"},
      {NO:56,PROCESS_UNIT:"GPPE",MR_NO:"75231-P2-1213-PE-R-E-0901",MR_DESCR:"Recovery Membrane Package",SEQ_NO:21,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:2,QTY:2,PP_FLOAT:0,PPA_FLOAT:2,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:6,TBE_FLOAT:29,CBE_FLOAT:29,AR_FLOAT:29,ARA_FLOAT:50,LOA_FLOAT:50,PO_FLOAT:50,MDI_FLOAT:50,FI_FLOAT:50,EW_FLOAT:50,SA_FLOAT:50,CC_FLOAT:50,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"No schedule",RAS_ROS:"2023-07-30",FLOATING_DAYS:-13,FLOAT_STATUS:"Float<0"},
      {NO:57,PROCESS_UNIT:"GPPE",MR_NO:"75231-P2-1213-RE-R-E-0622",MR_DESCR:"LP Steam Condensate PumpRE 9",SEQ_NO:21,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:1,QTY:1,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:21,BC_FLOAT:19,TBE_FLOAT:37,CBE_FLOAT:34,AR_FLOAT:35,ARA_FLOAT:57,LOA_FLOAT:57,PO_FLOAT:57,MDI_FLOAT:57,FI_FLOAT:57,EW_FLOAT:57,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-05-30",FLOATING_DAYS:26,FLOAT_STATUS:"Float>=7<30"},
      {NO:58,PROCESS_UNIT:"GPPE",MR_NO:"75231-P2-1213-WS-R-M-3001",MR_DESCR:"CS SMLS Pipe 20 Galv DN 600",SEQ_NO:21,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"IV - Non-Critical",TAG_TOTAL:0,QTY:2590.8,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:0,TBE_FLOAT:0,CBE_FLOAT:0,AR_FLOAT:0,ARA_FLOAT:0,LOA_FLOAT:0,PO_FLOAT:0,FI_FLOAT:0,EW_FLOAT:0,DJS_FLOAT:0,PP_STATUS:"On track",PPA_STATUS:"On track",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"Complete",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"On track",RAS_ROS:"2022-09-01",FLOATING_DAYS:-165,FLOAT_STATUS:"Float<0"},
      {NO:59,PROCESS_UNIT:"GPPE",MR_NO:"75231-P2-1213-WS-R-M-3002",MR_DESCR:"CS SAW SPS Pipe Q235B DN450DN2200",SEQ_NO:21,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"IV - Non-Critical",TAG_TOTAL:0,QTY:1333.136,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:0,TBE_FLOAT:0,CBE_FLOAT:0,AR_FLOAT:0,ARA_FLOAT:0,LOA_FLOAT:0,PO_FLOAT:0,FI_FLOAT:0,EW_FLOAT:0,DJS_FLOAT:0,PP_STATUS:"On track",PPA_STATUS:"On track",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"Complete",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"On track",RAS_ROS:"2022-09-15",FLOATING_DAYS:-151,FLOAT_STATUS:"Float<0"},
      {NO:60,PROCESS_UNIT:"GPPE",MR_NO:"75231-P2-1213-WS-R-M-3003",MR_DESCR:"CS DSAWLGS L245 PSL1 Pipe L245 DN700DN1400 2400",SEQ_NO:21,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:0,QTY:27.136,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:0,TBE_FLOAT:0,CBE_FLOAT:0,AR_FLOAT:0,ARA_FLOAT:0,LOA_FLOAT:0,PO_FLOAT:0,FI_FLOAT:0,EW_FLOAT:0,DJS_FLOAT:0,PP_STATUS:"On track",PPA_STATUS:"On track",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"Complete",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"On track",RAS_ROS:"2022-09-01",FLOATING_DAYS:-165,FLOAT_STATUS:"Float<0"},
      {NO:61,PROCESS_UNIT:"LDPE",MR_NO:"75231-P2-1214-EQ-R-E-0401",MR_DESCR:"FLARE LINE HEATER,MAKEUP ETHYLENE CHILLED COOLER,PURGE GAS COOLER,PURGE GAS TRIM COOLER",SEQ_NO:22,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:5,QTY:7,PP_FLOAT:0,PPA_FLOAT:-3,MR_FLOAT:0,RFQ_FLOAT:22,BC_FLOAT:20,TBE_FLOAT:32,CBE_FLOAT:32,AR_FLOAT:32,ARA_FLOAT:32,LOA_FLOAT:32,PO_FLOAT:32,MDI_FLOAT:32,FI_FLOAT:32,EW_FLOAT:32,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-06-15",FLOATING_DAYS:29,FLOAT_STATUS:"Float>=7<30"},
      {NO:62,PROCESS_UNIT:"LDPE",MR_NO:"75231-P2-1214-EQ-R-E-LL01",MR_DESCR:"Heat ExchangersGroup1IP Exchanger,High Pressure VesselsGroup 3HP Sep EV SepGroup2 IP",SEQ_NO:22,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:17,QTY:31,PP_FLOAT:6,PPA_FLOAT:8,MR_FLOAT:41,RFQ_FLOAT:21,BC_FLOAT:1,TBE_FLOAT:1,CBE_FLOAT:-2,AR_FLOAT:5,ARA_FLOAT:16,LOA_FLOAT:11,PO_FLOAT:15,MDI_FLOAT:15,MO_FLOAT:0,MMR_FLOAT:0,FI_FLOAT:15,EW_FLOAT:15,SA_FLOAT:15,CC_FLOAT:15,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"Complete",LOA_STATUS:"Complete",PO_STATUS:"Complete",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"No schedule",RAS_ROS:"2023-06-30",FLOATING_DAYS:21,FLOAT_STATUS:"Float>=7<30"},
      {NO:63,PROCESS_UNIT:"LDPE",MR_NO:"75231-P2-1214-MP-R-M-LL01",MR_DESCR:"HP Piping Materials Intermediate Pressure PipingVa",SEQ_NO:22,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:9,QTY:9,PP_FLOAT:30,PPA_FLOAT:30,MR_FLOAT:31,RFQ_FLOAT:47,BC_FLOAT:1,TBE_FLOAT:16,CBE_FLOAT:20,AR_FLOAT:21,ARA_FLOAT:32,LOA_FLOAT:28,PO_FLOAT:21,MDI_FLOAT:31,MO_FLOAT:0,MMR_FLOAT:0,FI_FLOAT:0,EW_FLOAT:0,SA_FLOAT:0,CC_FLOAT:0,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"Complete",LOA_STATUS:"Complete",PO_STATUS:"Complete",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"No schedule",RAS_ROS:"2023-10-30",FLOATING_DAYS:10,FLOAT_STATUS:"Float>=7<30"},
      {NO:64,PROCESS_UNIT:"LDPE",MR_NO:"75231-P2-1214-RE-R-E-0621",MR_DESCR:"HP PRODUCT COOLER WATER CIRCULATION PUMP,HP PRODUCT COOLER WATER CIRCULATION PUMP SPARE,HP RECYCLE HOT WATER CIRCULATION PUMP 1,HP RECYCLE HOT WATER CIRCULATION PUMP 2,REACTOR JACKET WATER CIRCULATION PUMP 1,REACTOR JACKET WATER CIRCULATION PUMP 2,REACTOR JACKET WATER CIRCULATION PUMP 3,REACTOR JACKET WATER CIRCULATION PUMP 4,REACTOR JACKET WATER CIRCULATION PUMP SPARE,VENT STACK WATER RECUPERATION PUMP",SEQ_NO:22,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:10,QTY:10,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:21,BC_FLOAT:19,TBE_FLOAT:37,CBE_FLOAT:34,AR_FLOAT:44,ARA_FLOAT:66,LOA_FLOAT:66,PO_FLOAT:66,MDI_FLOAT:66,FI_FLOAT:66,EW_FLOAT:66,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-05-30",FLOATING_DAYS:28,FLOAT_STATUS:"Float>=7<30"},
      {NO:65,PROCESS_UNIT:"LDPE",MR_NO:"75231-P2-1214-RE-R-E-LL01",MR_DESCR:"HP Pumps Group 1 modifier pumps",SEQ_NO:22,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:2,QTY:4,PP_FLOAT:0,PPA_FLOAT:8,MR_FLOAT:23,RFQ_FLOAT:21,BC_FLOAT:21,TBE_FLOAT:1,CBE_FLOAT:0,AR_FLOAT:-1,ARA_FLOAT:10,LOA_FLOAT:8,PO_FLOAT:5,MDI_FLOAT:13,MO_FLOAT:0,MMR_FLOAT:0,FI_FLOAT:0,EW_FLOAT:0,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"Complete",LOA_STATUS:"Complete",PO_STATUS:"Complete",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-08-28",FLOATING_DAYS:0,FLOAT_STATUS:"Float<7"},
      {NO:66,PROCESS_UNIT:"LDPE",MR_NO:"75231-P2-1214-RE-R-E-UL01",MR_DESCR:"PurgePrimary Compressor",SEQ_NO:22,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:3,QTY:3,PP_FLOAT:-9,PPA_FLOAT:-10,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:0,TBE_FLOAT:-20,CBE_FLOAT:-20,AR_FLOAT:-47,ARA_FLOAT:-40,LOA_FLOAT:-40,PO_FLOAT:-41,MMR_FLOAT:0,FI_FLOAT:0,EW_FLOAT:0,SA_FLOAT:0,CC_FLOAT:0,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"Complete",LOA_STATUS:"Complete",PO_STATUS:"Complete",MDI_STATUS:"Complete",MO_STATUS:"No schedule",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"No schedule",RAS_ROS:"2023-08-31",FLOATING_DAYS:13,FLOAT_STATUS:"Float>=7<30"},
      {NO:67,PROCESS_UNIT:"LDPE",MR_NO:"75231-P2-1214-RE-R-E-UL02",MR_DESCR:"Secondary Compressor",SEQ_NO:22,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:1,QTY:1,PP_FLOAT:-9,PPA_FLOAT:-10,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:0,TBE_FLOAT:-20,CBE_FLOAT:-20,AR_FLOAT:-47,ARA_FLOAT:-40,LOA_FLOAT:-40,PO_FLOAT:-41,MMR_FLOAT:0,FI_FLOAT:0,EW_FLOAT:0,SA_FLOAT:0,CC_FLOAT:0,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"Complete",LOA_STATUS:"Complete",PO_STATUS:"Complete",MDI_STATUS:"Complete",MO_STATUS:"No schedule",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"No schedule",RAS_ROS:"2023-09-20",FLOATING_DAYS:3,FLOAT_STATUS:"Float<7"},
      {NO:68,PROCESS_UNIT:"PP",MR_NO:"75231-P2-1219-EQ-R-E-0101",MR_DESCR:"Deethenizer,Deethenizer Overhead Condenser,Granule Dryer Scrubber Tower,Steamer Off Gas Scrubber",SEQ_NO:23,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:4,QTY:4,PP_FLOAT:0,PPA_FLOAT:-3,MR_FLOAT:0,RFQ_FLOAT:29,BC_FLOAT:29,TBE_FLOAT:32,CBE_FLOAT:32,AR_FLOAT:32,ARA_FLOAT:32,LOA_FLOAT:32,PO_FLOAT:32,MDI_FLOAT:32,FI_FLOAT:32,EW_FLOAT:32,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-05-23",FLOATING_DAYS:6,FLOAT_STATUS:"Float<7"},
      {NO:69,PROCESS_UNIT:"PP",MR_NO:"75231-P2-1219-EQ-R-E-0302",MR_DESCR:"1st Gas Phase Reactor Water Expansion Drum,2nd Gas Phase Reactor Water Expansion Drum,61C4045 Suction Knockout Drum,61C4065 Suction Knockout Drum,61DC3040 Blowback Gas Drum,62C4045 Suction Knockout Drum,Atmer Storage Drum,Chilled Water Expansion Drum,Chilled Water Reserve Drum,Exhaust Oil Drum,Granules Purge Drum OH KO Drum,High Pressure Blowback Gas Drum,Hydraulic Oil Surge Drum,IA Accumulator,Low Pressure Blowback Gas Drum,N2O2 Drum,Oil Drawoff Blow Pot,Oil Storage Drum,OilWater Separator,Peroxide Tank,Pressurization Oil Surge Drum,Propylene Dryer Regeneration Gas KO Drum,Reactor Jacket Water Surge Drum,TEAl Seal Oil Pot,Treated Oil Storage Drum,Vent Surge Drum,Waste Oil Collector,Waste Water Collector,WasteOil Separator,Water Collection Tank",SEQ_NO:23,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:51,QTY:55,PP_FLOAT:0,PPA_FLOAT:-9,MR_FLOAT:0,RFQ_FLOAT:12,BC_FLOAT:12,TBE_FLOAT:35,CBE_FLOAT:38,AR_FLOAT:38,ARA_FLOAT:38,LOA_FLOAT:38,PO_FLOAT:38,MDI_FLOAT:38,FI_FLOAT:38,EW_FLOAT:38,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-05-30",FLOATING_DAYS:13,FLOAT_STATUS:"Float>=7<30"},
      {NO:70,PROCESS_UNIT:"PP",MR_NO:"75231-P2-1219-EQ-R-E-0304",MR_DESCR:"Granules Fluid Bed Dryer",SEQ_NO:23,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:1,QTY:1,PP_FLOAT:0,PPA_FLOAT:2,MR_FLOAT:0,RFQ_FLOAT:7,BC_FLOAT:2,TBE_FLOAT:-10,CBE_FLOAT:-3,AR_FLOAT:-2,ARA_FLOAT:12,LOA_FLOAT:12,PO_FLOAT:12,MDI_FLOAT:12,FI_FLOAT:12,EW_FLOAT:12,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"Complete",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-05-25",FLOATING_DAYS:-35,FLOAT_STATUS:"Float<0"},
      {NO:71,PROCESS_UNIT:"PP",MR_NO:"75231-P2-1219-EQ-R-E-0305",MR_DESCR:"Catalyst Dispersion Drum,Catalyst Dispersion Drums,Catalyst Mixer",SEQ_NO:23,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:8,QTY:16,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:29,BC_FLOAT:29,TBE_FLOAT:32,CBE_FLOAT:32,AR_FLOAT:32,ARA_FLOAT:32,LOA_FLOAT:32,PO_FLOAT:32,MDI_FLOAT:32,FI_FLOAT:32,EW_FLOAT:32,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-05-30",FLOATING_DAYS:13,FLOAT_STATUS:"Float>=7<30"},
      {NO:72,PROCESS_UNIT:"PP",MR_NO:"75231-P2-1219-EQ-R-E-0403",MR_DESCR:"Deethenizer Reboiler,Dry Gas Cooler,Prepoly Reactor Feed C3 Kettle Chiller,Propylene Condenser,Propylene Feed Drum Vaporizer",SEQ_NO:23,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:12,QTY:12,PP_FLOAT:0,PPA_FLOAT:-3,MR_FLOAT:0,RFQ_FLOAT:29,BC_FLOAT:29,TBE_FLOAT:32,CBE_FLOAT:32,AR_FLOAT:32,ARA_FLOAT:32,LOA_FLOAT:32,PO_FLOAT:32,MDI_FLOAT:32,FI_FLOAT:32,EW_FLOAT:32,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-05-30",FLOATING_DAYS:13,FLOAT_STATUS:"Float>=7<30"},
      {NO:73,PROCESS_UNIT:"PP",MR_NO:"75231-P2-1219-EQ-R-E-0502",MR_DESCR:"61DC3040 Feed Dust Collector Filter,62P5042 Guard Filter,Extruder Feed Conveyor Dust Collector,Low Pressure Dust Collector,Purge Drum OH Dust Collector",SEQ_NO:23,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:7,QTY:7,PP_FLOAT:0,PPA_FLOAT:2,MR_FLOAT:0,RFQ_FLOAT:7,BC_FLOAT:0,TBE_FLOAT:-3,CBE_FLOAT:-32,AR_FLOAT:-18,ARA_FLOAT:-3,LOA_FLOAT:-3,PO_FLOAT:-3,MDI_FLOAT:-3,FI_FLOAT:-3,EW_FLOAT:-3,SA_FLOAT:-3,CC_FLOAT:-3,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"Behind plan",LOA_STATUS:"Behind plan",PO_STATUS:"Behind plan",MDI_STATUS:"Behind plan",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"Behind plan",EW_STATUS:"Behind plan",SA_STATUS:"Behind plan",CC_STATUS:"Behind plan",DJS_STATUS:"No schedule",RAS_ROS:"2023-05-25",FLOATING_DAYS:0,FLOAT_STATUS:"Float<7"},
      {NO:74,PROCESS_UNIT:"PP",MR_NO:"75231-P2-1219-EQ-R-E-0503",MR_DESCR:"6130E005 COOLING WATER filter,6130E008 COOLING WATER filter,6130E026 COOLING WATER filter,6130E027 COOLING WATER filter,6230E005 COOLING WATER filter,6230E008 COOLING WATER filter,Atmer Filter,Donor Loading Filter,Dryer Granules Hopper,Dryer Granules Hopper Vent Filter,Ethylene Feed Filter,Flushing Oil Filter,HPDC Guard Filter,Hydrogen Guard Filter,Hydrogen Safety Filter,LP N2 filter,LPDC Guard Filter,Metering Oil Filter,Nitrogen Filter,Oil Filter,Oil Grease Filter,Peroxide filter,Pressurization Oil Filter,Propylene Guard Filter,Purge Drum Dry N2 Filter,Purge Drum Wet N2 Filter,Waste Water Filter",SEQ_NO:23,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:44,QTY:70,PP_FLOAT:0,PPA_FLOAT:-9,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:0,TBE_FLOAT:34,CBE_FLOAT:65,AR_FLOAT:65,ARA_FLOAT:65,LOA_FLOAT:65,PO_FLOAT:65,MDI_FLOAT:65,FI_FLOAT:65,EW_FLOAT:65,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-06-20",FLOATING_DAYS:14,FLOAT_STATUS:"Float>=7<30"},
      {NO:75,PROCESS_UNIT:"PP",MR_NO:"75231-P2-1219-EQ-R-E-SL03",MR_DESCR:"Precontacting Pot,Precontacting Pot Mixer",SEQ_NO:23,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:2,QTY:2,PP_FLOAT:-21,PPA_FLOAT:-27,MR_FLOAT:0,RFQ_FLOAT:41,BC_FLOAT:39,TBE_FLOAT:34,CBE_FLOAT:37,AR_FLOAT:37,ARA_FLOAT:37,LOA_FLOAT:37,PO_FLOAT:37,MDI_FLOAT:37,FI_FLOAT:37,EW_FLOAT:37,SA_FLOAT:37,CC_FLOAT:37,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"No schedule",RAS_ROS:"2023-07-30",FLOATING_DAYS:-23,FLOAT_STATUS:"Float<0"},
      {NO:76,PROCESS_UNIT:"PP",MR_NO:"75231-P2-1219-EQ-R-E-SL04",MR_DESCR:"1st Gas Phase Reactor Cooler,2nd Gas Phase Reactor Cooler",SEQ_NO:23,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:4,QTY:4,PP_FLOAT:0,PPA_FLOAT:1,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:-4,TBE_FLOAT:14,CBE_FLOAT:21,AR_FLOAT:11,ARA_FLOAT:11,LOA_FLOAT:9,PO_FLOAT:10,MDI_FLOAT:10,FI_FLOAT:10,EW_FLOAT:10,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"Complete",LOA_STATUS:"Complete",PO_STATUS:"Complete",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-07-30",FLOATING_DAYS:20,FLOAT_STATUS:"Float>=7<30"},
      {NO:77,PROCESS_UNIT:"PP",MR_NO:"75231-P2-1219-EQ-R-E-SL08",MR_DESCR:"Steamer,Steamer Mixer",SEQ_NO:23,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:2,QTY:2,PP_FLOAT:-21,PPA_FLOAT:-27,MR_FLOAT:0,RFQ_FLOAT:41,BC_FLOAT:39,TBE_FLOAT:34,CBE_FLOAT:37,AR_FLOAT:37,ARA_FLOAT:52,LOA_FLOAT:52,PO_FLOAT:52,MDI_FLOAT:52,FI_FLOAT:52,EW_FLOAT:52,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"Complete",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-07-30",FLOATING_DAYS:-9,FLOAT_STATUS:"Float<0"},
      {NO:78,PROCESS_UNIT:"PP",MR_NO:"75231-P2-1219-EQ-R-E-SL09",MR_DESCR:"GPR DISTRIBUTION PLATE",SEQ_NO:23,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:2,QTY:4,PP_FLOAT:0,PPA_FLOAT:2,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:1,TBE_FLOAT:20,CBE_FLOAT:20,AR_FLOAT:20,ARA_FLOAT:20,LOA_FLOAT:20,PO_FLOAT:20,MDI_FLOAT:20,FI_FLOAT:20,EW_FLOAT:20,SA_FLOAT:20,CC_FLOAT:20,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"No schedule",RAS_ROS:"2023-06-30",FLOATING_DAYS:19,FLOAT_STATUS:"Float>=7<30"},
      {NO:79,PROCESS_UNIT:"PP",MR_NO:"75231-P2-1219-IN-R-E-5118",MR_DESCR:"Total Organic Carbon Analyzer TOC",SEQ_NO:23,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:4,QTY:16,PP_FLOAT:0,PPA_FLOAT:-9,MR_FLOAT:0,RFQ_FLOAT:12,BC_FLOAT:-20,TBE_FLOAT:-20,CBE_FLOAT:-20,AR_FLOAT:-20,ARA_FLOAT:-20,LOA_FLOAT:-20,PO_FLOAT:-17,MDI_FLOAT:-20,FI_FLOAT:-20,EW_FLOAT:-20,SA_FLOAT:-20,CC_FLOAT:-20,DJS_FLOAT:-20,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Behind plan",TBE_STATUS:"Behind plan",CBE_STATUS:"Behind plan",AR_STATUS:"Behind plan",ARA_STATUS:"Behind plan",LOA_STATUS:"Behind plan",PO_STATUS:"Behind plan",MDI_STATUS:"Behind plan",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"Behind plan",EW_STATUS:"Behind plan",SA_STATUS:"Behind plan",CC_STATUS:"Behind plan",DJS_STATUS:"Behind plan",RAS_ROS:"2022-08-31",FLOATING_DAYS:-71,FLOAT_STATUS:"Float<0"},
      {NO:80,PROCESS_UNIT:"PP",MR_NO:"75231-P2-1219-MH-R-E-SL01",MR_DESCR:"61 Area LossInWeight Feeders Package,62 Area LossInWeight Feeders Package,Solid Additives Package",SEQ_NO:23,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:3,QTY:3,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:20,BC_FLOAT:21,TBE_FLOAT:-32,CBE_FLOAT:-27,AR_FLOAT:-81,ARA_FLOAT:-81,LOA_FLOAT:-65,PO_FLOAT:-65,MDI_FLOAT:-65,FI_FLOAT:-65,EW_FLOAT:-65,SA_FLOAT:-65,CC_FLOAT:-65,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Behind plan",ARA_STATUS:"Behind plan",LOA_STATUS:"Behind plan",PO_STATUS:"Behind plan",MDI_STATUS:"Behind plan",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"Behind plan",EW_STATUS:"Behind plan",SA_STATUS:"Behind plan",CC_STATUS:"Behind plan",DJS_STATUS:"No schedule",RAS_ROS:"2023-07-31",FLOATING_DAYS:16,FLOAT_STATUS:"Float>=7<30"},
      {NO:81,PROCESS_UNIT:"PP",MR_NO:"75231-P2-1219-MH-R-E-SL02",MR_DESCR:"Extruder Feed Conveyor",SEQ_NO:23,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:4,QTY:4,PP_FLOAT:0,PPA_FLOAT:-1,MR_FLOAT:0,RFQ_FLOAT:5,BC_FLOAT:-26,TBE_FLOAT:-25,CBE_FLOAT:-76,AR_FLOAT:-73,ARA_FLOAT:-65,LOA_FLOAT:-42,PO_FLOAT:-38,MDI_FLOAT:-38,FI_FLOAT:-42,EW_FLOAT:-42,SA_FLOAT:-42,CC_FLOAT:-42,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"Complete",LOA_STATUS:"Complete",PO_STATUS:"Behind plan",MDI_STATUS:"Behind plan",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"Behind plan",EW_STATUS:"Behind plan",SA_STATUS:"Behind plan",CC_STATUS:"Behind plan",DJS_STATUS:"No schedule",RAS_ROS:"2023-07-31",FLOATING_DAYS:-89,FLOAT_STATUS:"Float<0"},
      {NO:82,PROCESS_UNIT:"PP",MR_NO:"75231-P2-1219-MH-R-E-SL04",MR_DESCR:"Pellet Classifier",SEQ_NO:23,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:2,QTY:2,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:18,BC_FLOAT:23,TBE_FLOAT:30,CBE_FLOAT:58,AR_FLOAT:50,ARA_FLOAT:48,LOA_FLOAT:29,PO_FLOAT:42,MDI_FLOAT:29,FI_FLOAT:29,EW_FLOAT:29,SA_FLOAT:29,CC_FLOAT:29,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"Complete",LOA_STATUS:"Complete",PO_STATUS:"Complete",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"No schedule",RAS_ROS:"2023-07-10",FLOATING_DAYS:4,FLOAT_STATUS:"Float<7"},
      {NO:83,PROCESS_UNIT:"PP",MR_NO:"75231-P2-1219-RE-R-E-0622",MR_DESCR:"Pellet Water Sump Pump,Storm Water Sump Pump",SEQ_NO:23,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:4,QTY:8,PP_FLOAT:0,PPA_FLOAT:3,MR_FLOAT:0,RFQ_FLOAT:21,BC_FLOAT:22,TBE_FLOAT:30,CBE_FLOAT:26,AR_FLOAT:26,ARA_FLOAT:26,LOA_FLOAT:26,PO_FLOAT:26,MDI_FLOAT:26,FI_FLOAT:26,EW_FLOAT:26,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-05-30",FLOATING_DAYS:17,FLOAT_STATUS:"Float>=7<30"},
      {NO:84,PROCESS_UNIT:"PP",MR_NO:"75231-P2-1219-RE-R-E-0630",MR_DESCR:"Peroxide Pump",SEQ_NO:23,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:3,QTY:3,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:21,BC_FLOAT:19,TBE_FLOAT:30,CBE_FLOAT:30,AR_FLOAT:30,ARA_FLOAT:30,LOA_FLOAT:30,PO_FLOAT:30,MDI_FLOAT:30,FI_FLOAT:30,EW_FLOAT:30,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-05-30",FLOATING_DAYS:27,FLOAT_STATUS:"Float>=7<30"},
      {NO:85,PROCESS_UNIT:"PP",MR_NO:"75231-P2-1219-RE-R-E-0660",MR_DESCR:"Atmer Drum Pump,Donor Drum Pump,Oil Drum Pump",SEQ_NO:23,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:6,QTY:18,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:21,BC_FLOAT:19,TBE_FLOAT:30,CBE_FLOAT:30,AR_FLOAT:30,ARA_FLOAT:30,LOA_FLOAT:30,PO_FLOAT:30,MDI_FLOAT:30,FI_FLOAT:30,EW_FLOAT:30,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-05-30",FLOATING_DAYS:18,FLOAT_STATUS:"Float>=7<30"},
      {NO:86,PROCESS_UNIT:"PP",MR_NO:"75231-P2-1219-RE-R-E-0680",MR_DESCR:"Atmer Loading Pump,Flushing Oil Pump,Grease Loading Pump,Treated Oil Transfer Pump",SEQ_NO:23,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:5,QTY:7,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:21,BC_FLOAT:19,TBE_FLOAT:30,CBE_FLOAT:30,AR_FLOAT:30,ARA_FLOAT:30,LOA_FLOAT:30,PO_FLOAT:30,MDI_FLOAT:30,FI_FLOAT:30,EW_FLOAT:30,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-05-30",FLOATING_DAYS:27,FLOAT_STATUS:"Float>=7<30"},
      {NO:87,PROCESS_UNIT:"PP",MR_NO:"75231-P2-1219-RE-R-E-SL05",MR_DESCR:"Hydrogen Compressor Package",SEQ_NO:23,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:2,QTY:4,PP_FLOAT:0,PPA_FLOAT:-1,MR_FLOAT:0,RFQ_FLOAT:14,BC_FLOAT:-46,TBE_FLOAT:-30,CBE_FLOAT:-31,AR_FLOAT:-38,ARA_FLOAT:-21,LOA_FLOAT:354,PO_FLOAT:354,MDI_FLOAT:354,FI_FLOAT:354,EW_FLOAT:354,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"Complete",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-08-30",FLOATING_DAYS:-216,FLOAT_STATUS:"Float<0"},
      {NO:88,PROCESS_UNIT:"PBL",MR_NO:"75231-P2-1528-MH-R-E-0701",MR_DESCR:"Warehouse ASRS System",SEQ_NO:24,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:2,QTY:4,PP_FLOAT:-2,PPA_FLOAT:-17,MR_FLOAT:-16,RFQ_FLOAT:-9,BC_FLOAT:-9,TBE_FLOAT:-19,CBE_FLOAT:1,AR_FLOAT:-1,ARA_FLOAT:11,LOA_FLOAT:12,PO_FLOAT:-31,MDI_FLOAT:17,FI_FLOAT:0,EW_FLOAT:0,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"Complete",LOA_STATUS:"Complete",PO_STATUS:"Complete",MDI_STATUS:"On track",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"No schedule",RAS_ROS:"2023-03-25",FLOATING_DAYS:15,FLOAT_STATUS:"Float>=7<30"},
      {NO:89,PROCESS_UNIT:"PBL",MR_NO:"75231-P2-1528-WS-R-E-3906",MR_DESCR:"Rain Water Siphon Drainage",SEQ_NO:24,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:1,QTY:1,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:4,BC_FLOAT:5,TBE_FLOAT:15,CBE_FLOAT:15,AR_FLOAT:15,ARA_FLOAT:15,LOA_FLOAT:15,PO_FLOAT:15,FI_FLOAT:15,EW_FLOAT:15,DJS_FLOAT:15,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"On track",RAS_ROS:"2023-02-14",FLOATING_DAYS:1,FLOAT_STATUS:"Float>=7<30"},
      {NO:90,PROCESS_UNIT:"PBL",MR_NO:"75231-P2-1529-WS-R-E-3906",MR_DESCR:"Rain Water Siphon Drainage",SEQ_NO:24,PSRNUM:"P2_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:1,QTY:1,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:4,BC_FLOAT:5,TBE_FLOAT:15,CBE_FLOAT:15,AR_FLOAT:15,ARA_FLOAT:15,LOA_FLOAT:15,PO_FLOAT:15,FI_FLOAT:15,EW_FLOAT:15,DJS_FLOAT:15,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"On track",RAS_ROS:"2023-02-14",FLOATING_DAYS:1,FLOAT_STATUS:"Float>=7<30"},
      {NO:91,PROCESS_UNIT:"Air Compression Station",MR_NO:"75231-P3-1853-RE-R-E-0601",MR_DESCR:"Air compressor DA,Air compressor PA",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"III",TAG_TOTAL:6,QTY:20,PP_FLOAT:0,PPA_FLOAT:-1,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:0,TBE_FLOAT:-6,CBE_FLOAT:-4,AR_FLOAT:0,ARA_FLOAT:0,LOA_FLOAT:0,PO_FLOAT:-17,MDI_FLOAT:0,MO_FLOAT:-38,MMR_FLOAT:-38,FI_FLOAT:0,EW_FLOAT:0,SA_FLOAT:0,CC_FLOAT:0,DJS_FLOAT:0,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"Complete",LOA_STATUS:"Complete",PO_STATUS:"Complete",MDI_STATUS:"Complete",MO_STATUS:"Behind plan",MMR_STATUS:"Behind plan",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-05-25",FLOATING_DAYS:23,FLOAT_STATUS:"Float>=7<30"},
      {NO:92,PROCESS_UNIT:"Air Compression Station",MR_NO:"75231-P3-1853-RE-R-E-0602",MR_DESCR:"Instrument air booster compressor",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"III",TAG_TOTAL:2,QTY:4,PP_FLOAT:0,PPA_FLOAT:-1,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:-18,TBE_FLOAT:-5,CBE_FLOAT:-2,AR_FLOAT:2,ARA_FLOAT:0,LOA_FLOAT:1,PO_FLOAT:-5,MDI_FLOAT:2,MO_FLOAT:4,MMR_FLOAT:4,FI_FLOAT:0,EW_FLOAT:0,SA_FLOAT:0,CC_FLOAT:0,DJS_FLOAT:0,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"Complete",LOA_STATUS:"Complete",PO_STATUS:"Complete",MDI_STATUS:"Complete",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-05-25",FLOATING_DAYS:23,FLOAT_STATUS:"Float>=7<30"},
      {NO:93,PROCESS_UNIT:"Atmospheric Tank Farm Common Area",MR_NO:"75231-P3-3320-EQ-R-E-0501",MR_DESCR:"Alkylate Tank,Benzene Tank,BTHC Tank,C6C7 NA Raff Tank,DIB Tank,HDBSCN Tank,Methanol Tank,Mogas Tank,MTBE Tank,Naphtha Tank,Raw Pygas Tank,Slop Oil Tank,Toluene Tank",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"IV",TAG_TOTAL:26,QTY:74,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:0,TBE_FLOAT:0,CBE_FLOAT:0,AR_FLOAT:0,ARA_FLOAT:0,LOA_FLOAT:0,PO_FLOAT:0,MDI_FLOAT:0,MO_FLOAT:0,MMR_FLOAT:0,FI_FLOAT:0,EW_FLOAT:0,SA_FLOAT:0,CC_FLOAT:0,DJS_FLOAT:0,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"On track",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-06-30",FLOATING_DAYS:3,FLOAT_STATUS:"Float<7"},
      {NO:94,PROCESS_UNIT:"Atmospheric Tank Farm Common Area",MR_NO:"75231-P3-3320-RE-R-E-0650",MR_DESCR:"Benzene transfer pump,BTHC Feed Pump,C6C7 NA Raff Blending Pump,HDBSCN Blending Pump,HDBSCN Transfer Pump,Raw Pygas Feed Pump",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"IV",TAG_TOTAL:13,QTY:25,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:24,BC_FLOAT:24,TBE_FLOAT:24,CBE_FLOAT:15,AR_FLOAT:15,ARA_FLOAT:15,LOA_FLOAT:15,PO_FLOAT:15,MDI_FLOAT:15,MO_FLOAT:15,MMR_FLOAT:15,FI_FLOAT:0,EW_FLOAT:15,SA_FLOAT:15,CC_FLOAT:15,DJS_FLOAT:15,PP_STATUS:"Complete",PPA_STATUS:"On track",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-04-30",FLOATING_DAYS:12,FLOAT_STATUS:"Float>=7<30"},
      {NO:95,PROCESS_UNIT:"Atmospheric Tank Farm Common Area",MR_NO:"75231-P3-3320-ST-R-E-0002",MR_DESCR:"Vapor Gas Recovery Unit",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"IV",TAG_TOTAL:1,QTY:1,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:0,TBE_FLOAT:0,CBE_FLOAT:0,AR_FLOAT:0,ARA_FLOAT:0,LOA_FLOAT:0,PO_FLOAT:0,MDI_FLOAT:-28,MO_FLOAT:-28,MMR_FLOAT:-28,FI_FLOAT:0,EW_FLOAT:0,SA_FLOAT:0,CC_FLOAT:0,DJS_FLOAT:0,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"On track",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"Behind plan",MO_STATUS:"Behind plan",MMR_STATUS:"Behind plan",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-06-30",FLOATING_DAYS:-83,FLOAT_STATUS:"Float<0"},
      {NO:96,PROCESS_UNIT:"Common Area of Management Facilities B",MR_NO:"75231-P3-1510-WS-R-M-2402",MR_DESCR:"Forged Fitting",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:0,QTY:199,MR_FLOAT:0,RFQ_FLOAT:0,LOA_FLOAT:90,PO_FLOAT:90,EW_FLOAT:90,DJS_FLOAT:90,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"No schedule",TBE_STATUS:"No schedule",CBE_STATUS:"No schedule",AR_STATUS:"No schedule",ARA_STATUS:"No schedule",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"No schedule",EW_STATUS:"Complete",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"Complete",RAS_ROS:"2022-07-20",FLOATING_DAYS:-93,FLOAT_STATUS:"Float<0"},
      {NO:97,PROCESS_UNIT:"Common Area of Management Facilities B",MR_NO:"75231-P3-1510-WS-R-M-2501",MR_DESCR:"GASKET",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:0,QTY:28,MR_FLOAT:0,RFQ_FLOAT:0,LOA_FLOAT:1,PO_FLOAT:1,EW_FLOAT:1,DJS_FLOAT:1,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"No schedule",TBE_STATUS:"No schedule",CBE_STATUS:"No schedule",AR_STATUS:"No schedule",ARA_STATUS:"No schedule",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"No schedule",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"On track",RAS_ROS:"2022-06-30",FLOATING_DAYS:-11,FLOAT_STATUS:"Float<0"},
      {NO:98,PROCESS_UNIT:"Common Area of Management Facilities B",MR_NO:"75231-P3-1510-WS-R-M-2505",MR_DESCR:"Bolts Nuts",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:0,QTY:320,MR_FLOAT:0,RFQ_FLOAT:0,LOA_FLOAT:1,PO_FLOAT:1,EW_FLOAT:1,DJS_FLOAT:1,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"No schedule",TBE_STATUS:"No schedule",CBE_STATUS:"No schedule",AR_STATUS:"No schedule",ARA_STATUS:"No schedule",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"No schedule",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"On track",RAS_ROS:"2022-06-30",FLOATING_DAYS:-11,FLOAT_STATUS:"Float<0"},
      {NO:99,PROCESS_UNIT:"Common Area of Management Facilities B",MR_NO:"75231-P3-1510-WS-R-M-2602",MR_DESCR:"FLANGE MPLP CSSSAS  DN 300",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:0,QTY:28,MR_FLOAT:0,RFQ_FLOAT:0,LOA_FLOAT:1,PO_FLOAT:1,EW_FLOAT:1,DJS_FLOAT:1,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"No schedule",TBE_STATUS:"No schedule",CBE_STATUS:"No schedule",AR_STATUS:"No schedule",ARA_STATUS:"No schedule",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"No schedule",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"On track",RAS_ROS:"2022-06-30",FLOATING_DAYS:-11,FLOAT_STATUS:"Float<0"},
      {NO:100,PROCESS_UNIT:"Common Area of Management Facilities B",MR_NO:"75231-P3-1510-WS-R-M-2703",MR_DESCR:"Forged Valve",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:0,QTY:13,MR_FLOAT:0,RFQ_FLOAT:0,LOA_FLOAT:1,PO_FLOAT:1,EW_FLOAT:1,DJS_FLOAT:1,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"No schedule",TBE_STATUS:"No schedule",CBE_STATUS:"No schedule",AR_STATUS:"No schedule",ARA_STATUS:"No schedule",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"No schedule",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"On track",RAS_ROS:"2022-06-30",FLOATING_DAYS:-11,FLOAT_STATUS:"Float<0"},
      {NO:101,PROCESS_UNIT:"Common Area of Management Facilities B",MR_NO:"75231-P3-1510-WS-R-M-2704",MR_DESCR:"Cast Valve CS",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:0,QTY:1,MR_FLOAT:0,RFQ_FLOAT:0,LOA_FLOAT:0,PO_FLOAT:0,EW_FLOAT:0,DJS_FLOAT:0,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"On track",BC_STATUS:"No schedule",TBE_STATUS:"No schedule",CBE_STATUS:"No schedule",AR_STATUS:"No schedule",ARA_STATUS:"No schedule",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"No schedule",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"On track",RAS_ROS:"2022-06-30",FLOATING_DAYS:-11,FLOAT_STATUS:"Float<0"},
      {NO:102,PROCESS_UNIT:"Common Area of Management Facilities B",MR_NO:"75231-P3-1510-WS-R-M-3001",MR_DESCR:"CS Seamless Pipe,CS SMLS Pipe",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:0,QTY:5997.6,MR_FLOAT:0,RFQ_FLOAT:0,LOA_FLOAT:90,PO_FLOAT:90,EW_FLOAT:90,DJS_FLOAT:90,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"No schedule",TBE_STATUS:"No schedule",CBE_STATUS:"No schedule",AR_STATUS:"No schedule",ARA_STATUS:"No schedule",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"No schedule",EW_STATUS:"Complete",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"Complete",RAS_ROS:"2022-07-20",FLOATING_DAYS:-93,FLOAT_STATUS:"Float<0"},
      {NO:103,PROCESS_UNIT:"Common Area of Management Facilities B",MR_NO:"75231-P3-1510-WS-R-M-3005",MR_DESCR:"DI Pipe,Ductile iron pipe",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:0,QTY:2356.8,MR_FLOAT:0,RFQ_FLOAT:0,LOA_FLOAT:90,PO_FLOAT:90,EW_FLOAT:90,DJS_FLOAT:90,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"No schedule",TBE_STATUS:"No schedule",CBE_STATUS:"No schedule",AR_STATUS:"No schedule",ARA_STATUS:"No schedule",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"No schedule",EW_STATUS:"Complete",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"Complete",RAS_ROS:"2022-07-20",FLOATING_DAYS:-93,FLOAT_STATUS:"Float<0"},
      {NO:104,PROCESS_UNIT:"Common Area of Management Facilities B",MR_NO:"75231-P3-1510-WS-R-M-3101",MR_DESCR:"CS Seamless Fitting",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:0,QTY:383,MR_FLOAT:0,RFQ_FLOAT:0,LOA_FLOAT:90,PO_FLOAT:90,EW_FLOAT:90,DJS_FLOAT:49,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"No schedule",TBE_STATUS:"No schedule",CBE_STATUS:"No schedule",AR_STATUS:"No schedule",ARA_STATUS:"No schedule",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"No schedule",EW_STATUS:"Complete",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"Complete",RAS_ROS:"2022-07-20",FLOATING_DAYS:-93,FLOAT_STATUS:"Float<0"},
      {NO:105,PROCESS_UNIT:"Common Area of Management Facilities B",MR_NO:"75231-P3-1510-WS-R-M-3102",MR_DESCR:"CS Seamless Fitting",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:0,QTY:2,MR_FLOAT:0,RFQ_FLOAT:0,LOA_FLOAT:90,PO_FLOAT:90,EW_FLOAT:90,DJS_FLOAT:90,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"No schedule",TBE_STATUS:"No schedule",CBE_STATUS:"No schedule",AR_STATUS:"No schedule",ARA_STATUS:"No schedule",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"No schedule",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"On track",RAS_ROS:"2022-06-30",FLOATING_DAYS:-102,FLOAT_STATUS:"Float<0"},
      {NO:106,PROCESS_UNIT:"Common Area of Management Facilities B",MR_NO:"75231-P3-1510-WS-R-M-3312",MR_DESCR:"Special ValveNonSTD Gate Valve",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:0,QTY:13,MR_FLOAT:0,RFQ_FLOAT:0,LOA_FLOAT:1,PO_FLOAT:1,EW_FLOAT:1,DJS_FLOAT:1,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"No schedule",TBE_STATUS:"No schedule",CBE_STATUS:"No schedule",AR_STATUS:"No schedule",ARA_STATUS:"No schedule",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"No schedule",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"On track",RAS_ROS:"2022-06-30",FLOATING_DAYS:-11,FLOAT_STATUS:"Float<0"},
      {NO:107,PROCESS_UNIT:"Control Center Building",MR_NO:"75231-P3-0000-IN-R-E-5001",MR_DESCR:"DISTRIBUTED CONTROL SYSTEM DCS",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"II",TAG_TOTAL:1,QTY:1,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:3,BC_FLOAT:3,TBE_FLOAT:22,CBE_FLOAT:21,AR_FLOAT:8,ARA_FLOAT:8,LOA_FLOAT:8,PO_FLOAT:8,MDI_FLOAT:9,MO_FLOAT:9,MMR_FLOAT:9,FI_FLOAT:0,EW_FLOAT:9,SA_FLOAT:9,CC_FLOAT:9,DJS_FLOAT:9,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"Complete",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-04-25",FLOATING_DAYS:-75,FLOAT_STATUS:"Float<0"},
      {NO:108,PROCESS_UNIT:"Control Center Building",MR_NO:"75231-P3-0000-IN-R-E-5002",MR_DESCR:"SAFETY INSTRUMENT SYSTEMSIS and Gas Detection Syst",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"II",TAG_TOTAL:1,QTY:1,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:3,BC_FLOAT:3,TBE_FLOAT:20,CBE_FLOAT:17,AR_FLOAT:8,ARA_FLOAT:8,LOA_FLOAT:0,PO_FLOAT:8,MDI_FLOAT:9,MO_FLOAT:9,MMR_FLOAT:9,FI_FLOAT:0,EW_FLOAT:9,SA_FLOAT:9,CC_FLOAT:9,DJS_FLOAT:9,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"Complete",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-04-25",FLOATING_DAYS:15,FLOAT_STATUS:"Float>=7<30"},
      {NO:109,PROCESS_UNIT:"Cooling Water System 1",MR_NO:"75231-P3-1802-IN-R-E-5011",MR_DESCR:"SIS",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"II",TAG_TOTAL:1,QTY:1,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:30,TBE_FLOAT:30,CBE_FLOAT:30,AR_FLOAT:30,ARA_FLOAT:30,LOA_FLOAT:90,PO_FLOAT:30,MDI_FLOAT:30,MO_FLOAT:30,MMR_FLOAT:30,FI_FLOAT:0,EW_FLOAT:30,SA_FLOAT:30,CC_FLOAT:30,DJS_FLOAT:30,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"On track",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-08-08",FLOATING_DAYS:14},
      {NO:110,PROCESS_UNIT:"Cooling Water System 1",MR_NO:"75231-P3-1802-IN-R-E-SL01",MR_DESCR:"Process Analyzer System and Analyzer Shelter integ",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"III",TAG_TOTAL:1,QTY:1,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:36,BC_FLOAT:36,TBE_FLOAT:36,CBE_FLOAT:36,AR_FLOAT:36,ARA_FLOAT:36,LOA_FLOAT:26,PO_FLOAT:36,MDI_FLOAT:36,MO_FLOAT:36,MMR_FLOAT:36,FI_FLOAT:0,EW_FLOAT:36,SA_FLOAT:36,CC_FLOAT:36,DJS_FLOAT:36,PP_STATUS:"Complete",PPA_STATUS:"On track",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"On track",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-09-30",FLOATING_DAYS:15},
      {NO:111,PROCESS_UNIT:"Cooling Water System 1",MR_NO:"75231-P3-1802-RE-R-E-0622",MR_DESCR:"cooling water pump",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"III",TAG_TOTAL:15,QTY:131,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:6,TBE_FLOAT:8,CBE_FLOAT:3,AR_FLOAT:-6,ARA_FLOAT:-6,LOA_FLOAT:-6,PO_FLOAT:-6,MDI_FLOAT:-6,MO_FLOAT:-6,MMR_FLOAT:-6,FI_FLOAT:0,EW_FLOAT:-6,SA_FLOAT:-6,CC_FLOAT:-6,DJS_FLOAT:-6,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Behind plan",ARA_STATUS:"Behind plan",LOA_STATUS:"Behind plan",PO_STATUS:"Behind plan",MDI_STATUS:"Behind plan",MO_STATUS:"Behind plan",MMR_STATUS:"Behind plan",FI_STATUS:"On track",EW_STATUS:"Behind plan",SA_STATUS:"Behind plan",CC_STATUS:"Behind plan",DJS_STATUS:"Behind plan",RAS_ROS:"2023-04-30",FLOATING_DAYS:-8,FLOAT_STATUS:"Float<0"},
      {NO:112,PROCESS_UNIT:"Cooling Water System 1",MR_NO:"75231-P3-1802-WS-R-E-3901",MR_DESCR:"Filter Package",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"IV",TAG_TOTAL:1,QTY:1,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:20,BC_FLOAT:14,TBE_FLOAT:27,CBE_FLOAT:27,AR_FLOAT:27,ARA_FLOAT:27,LOA_FLOAT:27,PO_FLOAT:27,MDI_FLOAT:27,MO_FLOAT:27,MMR_FLOAT:27,FI_FLOAT:0,EW_FLOAT:27,SA_FLOAT:27,CC_FLOAT:27,DJS_FLOAT:27,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-04-30",FLOATING_DAYS:7},
      {NO:113,PROCESS_UNIT:"Cooling Water System 1",MR_NO:"75231-P3-1802-WS-R-M-3002",MR_DESCR:"CS SAW SPS Pipe",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:0,QTY:2051.948,MR_FLOAT:0,RFQ_FLOAT:0,LOA_FLOAT:0,PO_FLOAT:0,EW_FLOAT:0,DJS_FLOAT:0,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"No schedule",TBE_STATUS:"No schedule",CBE_STATUS:"No schedule",AR_STATUS:"No schedule",ARA_STATUS:"No schedule",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"No schedule",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"On track",RAS_ROS:"2022-07-30",FLOATING_DAYS:11,FLOAT_STATUS:"Float>=7<30"},
      {NO:114,PROCESS_UNIT:"Cooling Water System 1",MR_NO:"75231-P3-1802-WS-R-M-3102",MR_DESCR:"CS Welded Fitting",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:0,QTY:47,MR_FLOAT:0,RFQ_FLOAT:0,LOA_FLOAT:0,PO_FLOAT:0,EW_FLOAT:0,DJS_FLOAT:0,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"No schedule",TBE_STATUS:"No schedule",CBE_STATUS:"No schedule",AR_STATUS:"No schedule",ARA_STATUS:"No schedule",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"No schedule",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"On track",RAS_ROS:"2022-07-30",FLOATING_DAYS:11,FLOAT_STATUS:"Float>=7<30"},
      {NO:115,PROCESS_UNIT:"Cooling Water System 2",MR_NO:"75231-P3-2802-IN-R-E-SL01",MR_DESCR:"Process Analyzer System and Analyzer Shelter integ",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"III",TAG_TOTAL:1,QTY:1,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:36,BC_FLOAT:36,TBE_FLOAT:36,CBE_FLOAT:36,AR_FLOAT:36,ARA_FLOAT:36,LOA_FLOAT:26,PO_FLOAT:36,MDI_FLOAT:36,MO_FLOAT:36,MMR_FLOAT:36,FI_FLOAT:0,EW_FLOAT:36,SA_FLOAT:36,CC_FLOAT:36,DJS_FLOAT:36,PP_STATUS:"Complete",PPA_STATUS:"On track",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"On track",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-09-30",FLOATING_DAYS:15},
      {NO:116,PROCESS_UNIT:"Cooling Water System 2",MR_NO:"75231-P3-2802-RE-R-E-0622",MR_DESCR:"cooling water pump",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"III",TAG_TOTAL:8,QTY:64,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:6,TBE_FLOAT:8,CBE_FLOAT:3,AR_FLOAT:-6,ARA_FLOAT:-6,LOA_FLOAT:-6,PO_FLOAT:-6,MDI_FLOAT:-6,MO_FLOAT:-6,MMR_FLOAT:-6,FI_FLOAT:0,EW_FLOAT:-6,SA_FLOAT:-6,CC_FLOAT:-6,DJS_FLOAT:-6,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Behind plan",ARA_STATUS:"Behind plan",LOA_STATUS:"Behind plan",PO_STATUS:"Behind plan",MDI_STATUS:"Behind plan",MO_STATUS:"Behind plan",MMR_STATUS:"Behind plan",FI_STATUS:"On track",EW_STATUS:"Behind plan",SA_STATUS:"Behind plan",CC_STATUS:"Behind plan",DJS_STATUS:"Behind plan",RAS_ROS:"2023-04-30",FLOATING_DAYS:22,FLOAT_STATUS:"Float>=7<30"},
      {NO:117,PROCESS_UNIT:"Cooling Water System 2",MR_NO:"75231-P3-2802-WS-R-M-3002",MR_DESCR:"CS SAW SPS Pipe",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:0,QTY:1605.3,MR_FLOAT:0,RFQ_FLOAT:0,LOA_FLOAT:0,PO_FLOAT:0,EW_FLOAT:0,DJS_FLOAT:0,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"No schedule",TBE_STATUS:"No schedule",CBE_STATUS:"No schedule",AR_STATUS:"No schedule",ARA_STATUS:"No schedule",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"No schedule",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"On track",RAS_ROS:"2022-07-30",FLOATING_DAYS:11,FLOAT_STATUS:"Float>=7<30"},
      {NO:118,PROCESS_UNIT:"Cooling Water System 2",MR_NO:"75231-P3-2802-WS-R-M-3102",MR_DESCR:"CS Welded Fitting",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:0,QTY:30,MR_FLOAT:0,RFQ_FLOAT:0,LOA_FLOAT:0,PO_FLOAT:0,EW_FLOAT:0,DJS_FLOAT:0,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"No schedule",TBE_STATUS:"No schedule",CBE_STATUS:"No schedule",AR_STATUS:"No schedule",ARA_STATUS:"No schedule",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"No schedule",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"On track",RAS_ROS:"2022-07-30",FLOATING_DAYS:11,FLOAT_STATUS:"Float>=7<30"},
      {NO:119,PROCESS_UNIT:"Elevated Flare",MR_NO:"75231-P3-1786-IN-R-E-5011",MR_DESCR:"SIS",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"II",TAG_TOTAL:1,QTY:1,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:30,TBE_FLOAT:30,CBE_FLOAT:30,AR_FLOAT:30,ARA_FLOAT:30,LOA_FLOAT:90,PO_FLOAT:30,MDI_FLOAT:30,MO_FLOAT:30,MMR_FLOAT:30,FI_FLOAT:0,EW_FLOAT:30,SA_FLOAT:30,CC_FLOAT:30,DJS_FLOAT:30,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"On track",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-10-29",FLOATING_DAYS:26},
      {NO:120,PROCESS_UNIT:"Ethylene Cryogenic Tank Farm",MR_NO:"75231-P3-1701-IN-R-E-5011",MR_DESCR:"SIS",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"II",TAG_TOTAL:1,QTY:1,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:30,TBE_FLOAT:30,CBE_FLOAT:30,AR_FLOAT:30,ARA_FLOAT:30,LOA_FLOAT:90,PO_FLOAT:30,MDI_FLOAT:30,MO_FLOAT:30,MMR_FLOAT:30,FI_FLOAT:0,EW_FLOAT:30,SA_FLOAT:30,CC_FLOAT:30,DJS_FLOAT:30,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"On track",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-10-29",FLOATING_DAYS:26},
      {NO:121,PROCESS_UNIT:"Firewater Pump Station",MR_NO:"75231-P3-3810-EQ-R-E-1006",MR_DESCR:"Firefighting Water Storage Tank",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"IV",TAG_TOTAL:2,QTY:4,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:0,TBE_FLOAT:0,CBE_FLOAT:0,AR_FLOAT:0,ARA_FLOAT:0,LOA_FLOAT:0,PO_FLOAT:0,MDI_FLOAT:0,MO_FLOAT:0,MMR_FLOAT:0,FI_FLOAT:0,EW_FLOAT:0,SA_FLOAT:0,CC_FLOAT:0,DJS_FLOAT:0,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"On track",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-01-15",FLOATING_DAYS:-37,FLOAT_STATUS:"Float<0"},
      {NO:122,PROCESS_UNIT:"Firewater Pump Station B",MR_NO:"75231-P3-3810-FF-R-E-0001",MR_DESCR:"Fire Water Pump,Sprinkler Fire Water Pump",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"III",TAG_TOTAL:4,QTY:8,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:17,BC_FLOAT:17,TBE_FLOAT:18,CBE_FLOAT:18,AR_FLOAT:18,ARA_FLOAT:18,LOA_FLOAT:18,PO_FLOAT:18,MDI_FLOAT:18,MO_FLOAT:18,MMR_FLOAT:18,FI_FLOAT:0,EW_FLOAT:18,SA_FLOAT:18,CC_FLOAT:18,DJS_FLOAT:18,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-03-30",FLOATING_DAYS:-54,FLOAT_STATUS:"Float<0"},
      {NO:123,PROCESS_UNIT:"Flare Gas Recovery Unit",MR_NO:"75231-P3-1788-IN-R-E-5011",MR_DESCR:"SIS",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"II",TAG_TOTAL:1,QTY:1,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:30,TBE_FLOAT:30,CBE_FLOAT:30,AR_FLOAT:30,ARA_FLOAT:30,LOA_FLOAT:90,PO_FLOAT:30,MDI_FLOAT:30,MO_FLOAT:30,MMR_FLOAT:30,FI_FLOAT:0,EW_FLOAT:30,SA_FLOAT:30,CC_FLOAT:30,DJS_FLOAT:30,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"On track",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-10-29",FLOATING_DAYS:26},
      {NO:124,PROCESS_UNIT:"Ground Flare",MR_NO:"75231-P3-1788-RE-R-E-SL03",MR_DESCR:"Flare Gas Recovery Compressor",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"III",TAG_TOTAL:3,QTY:9,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:8,BC_FLOAT:6,TBE_FLOAT:8,CBE_FLOAT:8,AR_FLOAT:8,ARA_FLOAT:8,LOA_FLOAT:8,PO_FLOAT:8,MDI_FLOAT:8,MO_FLOAT:8,MMR_FLOAT:8,FI_FLOAT:0,EW_FLOAT:8,SA_FLOAT:8,CC_FLOAT:8,DJS_FLOAT:8,PP_STATUS:"Complete",PPA_STATUS:"On track",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-05-30",FLOATING_DAYS:0,FLOAT_STATUS:"Float>=7<30"},
      {NO:125,PROCESS_UNIT:"Ground Flare",MR_NO:"75231-P3-1789-IN-R-E-5011",MR_DESCR:"SIS",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"II",TAG_TOTAL:1,QTY:1,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:30,TBE_FLOAT:30,CBE_FLOAT:30,AR_FLOAT:30,ARA_FLOAT:30,LOA_FLOAT:90,PO_FLOAT:30,MDI_FLOAT:30,MO_FLOAT:30,MMR_FLOAT:30,FI_FLOAT:0,EW_FLOAT:30,SA_FLOAT:30,CC_FLOAT:30,DJS_FLOAT:30,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"On track",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-10-29",FLOATING_DAYS:26},
      {NO:126,PROCESS_UNIT:"Ground Flare",MR_NO:"75231-P3-1788-EQ-R-E-0301",MR_DESCR:"FGR Compressor Suction KO Drum",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"IV",TAG_TOTAL:1,QTY:1,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:0,TBE_FLOAT:0,CBE_FLOAT:0,AR_FLOAT:0,ARA_FLOAT:0,LOA_FLOAT:0,PO_FLOAT:0,MDI_FLOAT:0,MO_FLOAT:0,MMR_FLOAT:0,FI_FLOAT:0,EW_FLOAT:0,SA_FLOAT:0,CC_FLOAT:0,DJS_FLOAT:0,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"On track",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-04-30",FLOATING_DAYS:-23,FLOAT_STATUS:"Float<0"},
      {NO:127,PROCESS_UNIT:"Henxene1 Tank Farm",MR_NO:"75231-P3-3701-IN-R-E-5011",MR_DESCR:"SIS",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"II",TAG_TOTAL:1,QTY:1,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:30,TBE_FLOAT:30,CBE_FLOAT:30,AR_FLOAT:30,ARA_FLOAT:30,LOA_FLOAT:90,PO_FLOAT:30,MDI_FLOAT:30,MO_FLOAT:30,MMR_FLOAT:30,FI_FLOAT:0,EW_FLOAT:30,SA_FLOAT:30,CC_FLOAT:30,DJS_FLOAT:30,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"On track",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-10-29",FLOATING_DAYS:26},
      {NO:128,PROCESS_UNIT:"Intermediate Tank Farm 1",MR_NO:"75231-P3-1771-IN-R-E-5011",MR_DESCR:"SIS",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"II",TAG_TOTAL:1,QTY:1,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:30,TBE_FLOAT:30,CBE_FLOAT:30,AR_FLOAT:30,ARA_FLOAT:30,LOA_FLOAT:90,PO_FLOAT:30,MDI_FLOAT:30,MO_FLOAT:30,MMR_FLOAT:30,FI_FLOAT:0,EW_FLOAT:30,SA_FLOAT:30,CC_FLOAT:30,DJS_FLOAT:30,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"On track",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-10-29",FLOATING_DAYS:26},
      {NO:129,PROCESS_UNIT:"Intermediate Tank Farm 2",MR_NO:"75231-P3-2771-IN-R-E-5011",MR_DESCR:"SIS",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"II",TAG_TOTAL:1,QTY:1,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:30,TBE_FLOAT:30,CBE_FLOAT:30,AR_FLOAT:30,ARA_FLOAT:30,LOA_FLOAT:90,PO_FLOAT:30,MDI_FLOAT:30,MO_FLOAT:30,MMR_FLOAT:30,FI_FLOAT:0,EW_FLOAT:30,SA_FLOAT:30,CC_FLOAT:30,DJS_FLOAT:30,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"On track",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-10-29",FLOATING_DAYS:26},
      {NO:130,PROCESS_UNIT:"Intermediate Tank Farm 3",MR_NO:"75231-P3-3771-IN-R-E-5011",MR_DESCR:"SIS",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"II",TAG_TOTAL:1,QTY:1,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:30,TBE_FLOAT:30,CBE_FLOAT:30,AR_FLOAT:30,ARA_FLOAT:30,LOA_FLOAT:90,PO_FLOAT:30,MDI_FLOAT:30,MO_FLOAT:30,MMR_FLOAT:30,FI_FLOAT:0,EW_FLOAT:30,SA_FLOAT:30,CC_FLOAT:30,DJS_FLOAT:30,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"On track",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-10-29",FLOATING_DAYS:26},
      {NO:131,PROCESS_UNIT:"LoadingUnloading Station",MR_NO:"75231-P3-1780-GL-R-E-0001",MR_DESCR:"Truck Scale",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"IV",TAG_TOTAL:2,QTY:4,MR_FLOAT:0,RFQ_FLOAT:-17,BC_FLOAT:-17,TBE_FLOAT:-17,CBE_FLOAT:-17,AR_FLOAT:-17,ARA_FLOAT:-17,LOA_FLOAT:-17,PO_FLOAT:-17,MDI_FLOAT:-17,MO_FLOAT:-17,MMR_FLOAT:-17,FI_FLOAT:0,EW_FLOAT:-17,SA_FLOAT:-17,CC_FLOAT:-17,DJS_FLOAT:-17,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"Behind plan",BC_STATUS:"Behind plan",TBE_STATUS:"Behind plan",CBE_STATUS:"Behind plan",AR_STATUS:"Behind plan",ARA_STATUS:"Behind plan",LOA_STATUS:"Behind plan",PO_STATUS:"Behind plan",MDI_STATUS:"Behind plan",MO_STATUS:"Behind plan",MMR_STATUS:"Behind plan",FI_STATUS:"On track",EW_STATUS:"Behind plan",SA_STATUS:"Behind plan",CC_STATUS:"Behind plan",DJS_STATUS:"Behind plan",RAS_ROS:"2023-04-30",FLOATING_DAYS:17,FLOAT_STATUS:"Float<7"},
      {NO:132,PROCESS_UNIT:"LP Tank Farm",MR_NO:"75231-P3-1700-RE-R-E-SL01",MR_DESCR:"Ethylene HP external pump,Ethylene LP innertank pump",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"II",TAG_TOTAL:4,QTY:8,PP_FLOAT:0,PPA_FLOAT:-6,MR_FLOAT:-16,RFQ_FLOAT:-17,BC_FLOAT:-20,TBE_FLOAT:-8,CBE_FLOAT:-8,AR_FLOAT:2,ARA_FLOAT:1,LOA_FLOAT:1,PO_FLOAT:-17,MDI_FLOAT:2,MO_FLOAT:15,MMR_FLOAT:15,FI_FLOAT:0,EW_FLOAT:0,SA_FLOAT:0,CC_FLOAT:0,DJS_FLOAT:0,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"Complete",LOA_STATUS:"Complete",PO_STATUS:"Complete",MDI_STATUS:"Complete",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-12-25",FLOATING_DAYS:-3,FLOAT_STATUS:"Float<0"},
      {NO:133,PROCESS_UNIT:"Mogas Tank Farm",MR_NO:"75231-P3-1708-IN-R-E-5011",MR_DESCR:"SIS",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"II",TAG_TOTAL:1,QTY:1,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:30,TBE_FLOAT:30,CBE_FLOAT:30,AR_FLOAT:30,ARA_FLOAT:30,LOA_FLOAT:90,PO_FLOAT:30,MDI_FLOAT:30,MO_FLOAT:30,MMR_FLOAT:30,FI_FLOAT:0,EW_FLOAT:30,SA_FLOAT:30,CC_FLOAT:30,DJS_FLOAT:30,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"On track",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-10-29",FLOATING_DAYS:26},
      {NO:134,PROCESS_UNIT:"Mogas Tank Farm",MR_NO:"75231-P3-3320-RE-R-E-0630",MR_DESCR:"HDBSCN Feed Pump,Methanol Feed Pump",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"IV",TAG_TOTAL:3,QTY:6,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:25,BC_FLOAT:13,TBE_FLOAT:7,CBE_FLOAT:7,AR_FLOAT:7,ARA_FLOAT:7,LOA_FLOAT:7,PO_FLOAT:7,MDI_FLOAT:7,MO_FLOAT:7,MMR_FLOAT:7,FI_FLOAT:0,EW_FLOAT:7,SA_FLOAT:7,CC_FLOAT:7,DJS_FLOAT:7,PP_STATUS:"Complete",PPA_STATUS:"On track",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-04-30",FLOATING_DAYS:25},
      {NO:135,PROCESS_UNIT:"Naphtha Tank Farm",MR_NO:"75231-P3-1680-IN-R-E-5011",MR_DESCR:"SIS",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"II",TAG_TOTAL:1,QTY:1,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:30,TBE_FLOAT:30,CBE_FLOAT:30,AR_FLOAT:30,ARA_FLOAT:30,LOA_FLOAT:90,PO_FLOAT:30,MDI_FLOAT:30,MO_FLOAT:30,MMR_FLOAT:30,FI_FLOAT:0,EW_FLOAT:30,SA_FLOAT:30,CC_FLOAT:30,DJS_FLOAT:30,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"On track",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-10-29",FLOATING_DAYS:26},
      {NO:136,PROCESS_UNIT:"Offspec Ethylene Tank Farm",MR_NO:"75231-P3-2701-IN-R-E-5011",MR_DESCR:"SIS",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"II",TAG_TOTAL:1,QTY:1,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:30,TBE_FLOAT:30,CBE_FLOAT:30,AR_FLOAT:30,ARA_FLOAT:30,LOA_FLOAT:90,PO_FLOAT:30,MDI_FLOAT:30,MO_FLOAT:30,MMR_FLOAT:30,FI_FLOAT:0,EW_FLOAT:30,SA_FLOAT:30,CC_FLOAT:30,DJS_FLOAT:30,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"On track",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-10-29",FLOATING_DAYS:26},
      {NO:137,PROCESS_UNIT:"Overall",MR_NO:"75231-P3-3000-WS-R-M-2402",MR_DESCR:"Forged Fitting",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:0,QTY:24,MR_FLOAT:0,RFQ_FLOAT:3,LOA_FLOAT:93,PO_FLOAT:93,EW_FLOAT:93,DJS_FLOAT:93,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"No schedule",TBE_STATUS:"No schedule",CBE_STATUS:"No schedule",AR_STATUS:"No schedule",ARA_STATUS:"No schedule",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"No schedule",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"On track",RAS_ROS:"2022-08-20",FLOATING_DAYS:-89,FLOAT_STATUS:"Float<7"},
      {NO:138,PROCESS_UNIT:"Overall",MR_NO:"75231-P3-3000-WS-R-M-3001",MR_DESCR:"CS Seamless Pipe",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:0,QTY:5490,MR_FLOAT:0,RFQ_FLOAT:3,LOA_FLOAT:33,PO_FLOAT:33,EW_FLOAT:33,DJS_FLOAT:33,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"No schedule",TBE_STATUS:"No schedule",CBE_STATUS:"No schedule",AR_STATUS:"No schedule",ARA_STATUS:"No schedule",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"No schedule",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"On track",RAS_ROS:"2022-08-20",FLOATING_DAYS:-29,FLOAT_STATUS:"Float<7"},
      {NO:139,PROCESS_UNIT:"Overall",MR_NO:"75231-P3-3000-WS-R-M-3002",MR_DESCR:"CS SAWSPS Pipe",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:0,QTY:2103,MR_FLOAT:0,RFQ_FLOAT:3,LOA_FLOAT:93,PO_FLOAT:93,EW_FLOAT:93,DJS_FLOAT:93,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"No schedule",TBE_STATUS:"No schedule",CBE_STATUS:"No schedule",AR_STATUS:"No schedule",ARA_STATUS:"No schedule",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"No schedule",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"On track",RAS_ROS:"2022-08-20",FLOATING_DAYS:-89,FLOAT_STATUS:"Float<7"},
      {NO:140,PROCESS_UNIT:"Overall",MR_NO:"75231-P3-3000-WS-R-M-3003",MR_DESCR:"CS DSAWLGS L245 PSL1 Pipe L245",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:0,QTY:63.3,MR_FLOAT:0,RFQ_FLOAT:3,LOA_FLOAT:93,PO_FLOAT:93,EW_FLOAT:93,DJS_FLOAT:93,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"No schedule",TBE_STATUS:"No schedule",CBE_STATUS:"No schedule",AR_STATUS:"No schedule",ARA_STATUS:"No schedule",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"No schedule",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"On track",RAS_ROS:"2022-08-20",FLOATING_DAYS:-89,FLOAT_STATUS:"Float<7"},
      {NO:141,PROCESS_UNIT:"Overall",MR_NO:"75231-P3-3000-WS-R-M-3005",MR_DESCR:"Ductile iron pipe",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:0,QTY:2816,MR_FLOAT:0,RFQ_FLOAT:3,LOA_FLOAT:93,PO_FLOAT:93,EW_FLOAT:93,DJS_FLOAT:93,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"No schedule",TBE_STATUS:"No schedule",CBE_STATUS:"No schedule",AR_STATUS:"No schedule",ARA_STATUS:"No schedule",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"No schedule",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"On track",RAS_ROS:"2022-08-20",FLOATING_DAYS:-89,FLOAT_STATUS:"Float<7"},
      {NO:142,PROCESS_UNIT:"Overall",MR_NO:"75231-P3-3000-WS-R-M-3101",MR_DESCR:"CS Seamless Fitting",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:0,QTY:194,MR_FLOAT:0,RFQ_FLOAT:3,LOA_FLOAT:93,PO_FLOAT:93,EW_FLOAT:93,DJS_FLOAT:93,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"No schedule",TBE_STATUS:"No schedule",CBE_STATUS:"No schedule",AR_STATUS:"No schedule",ARA_STATUS:"No schedule",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"No schedule",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"On track",RAS_ROS:"2022-08-20",FLOATING_DAYS:-89,FLOAT_STATUS:"Float<7"},
      {NO:143,PROCESS_UNIT:"Overall",MR_NO:"75231-P3-3000-WS-R-M-3102",MR_DESCR:"CS Welded Fitting",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:0,QTY:105,MR_FLOAT:0,RFQ_FLOAT:3,LOA_FLOAT:93,PO_FLOAT:93,EW_FLOAT:93,DJS_FLOAT:93,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"No schedule",TBE_STATUS:"No schedule",CBE_STATUS:"No schedule",AR_STATUS:"No schedule",ARA_STATUS:"No schedule",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"No schedule",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"On track",RAS_ROS:"2022-08-20",FLOATING_DAYS:-89,FLOAT_STATUS:"Float<7"},
      {NO:144,PROCESS_UNIT:"Overall Instrument System",MR_NO:"75231-P3-1861-IN-R-E-5001",MR_DESCR:"DCS",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"II",TAG_TOTAL:1,QTY:1,MR_FLOAT:0,RFQ_FLOAT:7,BC_FLOAT:8,TBE_FLOAT:8,CBE_FLOAT:8,AR_FLOAT:8,ARA_FLOAT:8,LOA_FLOAT:8,PO_FLOAT:8,MDI_FLOAT:8,MO_FLOAT:8,MMR_FLOAT:8,FI_FLOAT:0,EW_FLOAT:8,SA_FLOAT:8,CC_FLOAT:8,DJS_FLOAT:8,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"Complete",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-05-15",FLOATING_DAYS:-41,FLOAT_STATUS:"Float<0"},
      {NO:145,PROCESS_UNIT:"Overall Instrument System",MR_NO:"75231-P3-1861-IN-R-E-5011",MR_DESCR:"SIS",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"II",TAG_TOTAL:1,QTY:1,MR_FLOAT:0,RFQ_FLOAT:7,BC_FLOAT:38,TBE_FLOAT:38,CBE_FLOAT:38,AR_FLOAT:38,ARA_FLOAT:38,LOA_FLOAT:98,PO_FLOAT:38,MDI_FLOAT:38,MO_FLOAT:38,MMR_FLOAT:38,FI_FLOAT:0,EW_FLOAT:38,SA_FLOAT:38,CC_FLOAT:38,DJS_FLOAT:38,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"Complete",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-05-15",FLOATING_DAYS:-71,FLOAT_STATUS:"Float<0"},
      {NO:146,PROCESS_UNIT:"Overall Instrument System",MR_NO:"75231-P3-1861-IN-R-E-5012",MR_DESCR:"GDS",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"II",TAG_TOTAL:1,QTY:1,MR_FLOAT:0,RFQ_FLOAT:7,BC_FLOAT:38,TBE_FLOAT:38,CBE_FLOAT:38,AR_FLOAT:38,ARA_FLOAT:38,LOA_FLOAT:98,PO_FLOAT:38,MDI_FLOAT:38,MO_FLOAT:38,MMR_FLOAT:38,FI_FLOAT:0,EW_FLOAT:38,SA_FLOAT:38,CC_FLOAT:38,DJS_FLOAT:38,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"Complete",ARA_STATUS:"Complete",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-05-15",FLOATING_DAYS:-71,FLOAT_STATUS:"Float<0"},
      {NO:147,PROCESS_UNIT:"Overall Interconnecting Pipe AG",MR_NO:"75231-P3-1790-IN-R-E-5011",MR_DESCR:"SIS",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"II",TAG_TOTAL:1,QTY:1,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:30,TBE_FLOAT:30,CBE_FLOAT:30,AR_FLOAT:30,ARA_FLOAT:30,LOA_FLOAT:90,PO_FLOAT:30,MDI_FLOAT:30,MO_FLOAT:30,MMR_FLOAT:30,FI_FLOAT:0,EW_FLOAT:30,SA_FLOAT:30,CC_FLOAT:30,DJS_FLOAT:30,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"On track",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-10-29",FLOATING_DAYS:26},
      {NO:148,PROCESS_UNIT:"Pressurized Tank Farm",MR_NO:"75231-P3-3310-EQ-R-E-0401",MR_DESCR:"BD Product Heat Exchanger",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"IV",TAG_TOTAL:3,QTY:9,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:0,TBE_FLOAT:0,CBE_FLOAT:0,AR_FLOAT:0,ARA_FLOAT:0,LOA_FLOAT:0,PO_FLOAT:0,MDI_FLOAT:0,MO_FLOAT:0,MMR_FLOAT:0,FI_FLOAT:0,EW_FLOAT:0,SA_FLOAT:0,CC_FLOAT:0,DJS_FLOAT:0,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"On track",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-06-30",FLOATING_DAYS:6,FLOAT_STATUS:"Float<7"},
      {NO:149,PROCESS_UNIT:"Pressurized Tank Farm",MR_NO:"75231-P3-3310-EQ-R-E-0402",MR_DESCR:"Raw Pygas Cooler",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"IV",TAG_TOTAL:1,QTY:1,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:0,TBE_FLOAT:0,CBE_FLOAT:0,AR_FLOAT:0,ARA_FLOAT:0,LOA_FLOAT:0,PO_FLOAT:0,MDI_FLOAT:0,MO_FLOAT:0,MMR_FLOAT:0,FI_FLOAT:0,EW_FLOAT:0,SA_FLOAT:0,CC_FLOAT:0,DJS_FLOAT:0,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"On track",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-06-30",FLOATING_DAYS:6,FLOAT_STATUS:"Float<7"},
      {NO:150,PROCESS_UNIT:"Pressurized Tank Farm",MR_NO:"75231-P3-3310-EQ-R-E-0403",MR_DESCR:"Offspec Ethylene Vaporizer",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"III",TAG_TOTAL:2,QTY:4,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:0,TBE_FLOAT:0,CBE_FLOAT:0,AR_FLOAT:0,ARA_FLOAT:0,LOA_FLOAT:0,PO_FLOAT:0,MDI_FLOAT:0,MO_FLOAT:0,MMR_FLOAT:0,FI_FLOAT:0,EW_FLOAT:0,SA_FLOAT:0,CC_FLOAT:0,DJS_FLOAT:0,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"On track",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-06-30",FLOATING_DAYS:6,FLOAT_STATUS:"Float<7"},
      {NO:151,PROCESS_UNIT:"Pressurized Tank Farm 1",MR_NO:"75231-P3-1703-IN-R-E-5011",MR_DESCR:"SIS",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"II",TAG_TOTAL:1,QTY:1,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:30,TBE_FLOAT:30,CBE_FLOAT:30,AR_FLOAT:30,ARA_FLOAT:30,LOA_FLOAT:90,PO_FLOAT:30,MDI_FLOAT:30,MO_FLOAT:30,MMR_FLOAT:30,FI_FLOAT:0,EW_FLOAT:30,SA_FLOAT:30,CC_FLOAT:30,DJS_FLOAT:30,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"On track",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-10-29",FLOATING_DAYS:26},
      {NO:152,PROCESS_UNIT:"Pressurized Tank Farm 2",MR_NO:"75231-P3-2703-IN-R-E-5011",MR_DESCR:"SIS",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"II",TAG_TOTAL:1,QTY:1,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:30,TBE_FLOAT:30,CBE_FLOAT:30,AR_FLOAT:30,ARA_FLOAT:30,LOA_FLOAT:90,PO_FLOAT:30,MDI_FLOAT:30,MO_FLOAT:30,MMR_FLOAT:30,FI_FLOAT:0,EW_FLOAT:30,SA_FLOAT:30,CC_FLOAT:30,DJS_FLOAT:30,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"On track",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-10-29",FLOATING_DAYS:26},
      {NO:153,PROCESS_UNIT:"Pressurized Tank Farm Common Area",MR_NO:"75231-P3-3310-EQ-R-E-1006",MR_DESCR:"Glycol Water Tank",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"II",TAG_TOTAL:1,QTY:1,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:0,TBE_FLOAT:0,CBE_FLOAT:0,AR_FLOAT:0,ARA_FLOAT:0,LOA_FLOAT:0,PO_FLOAT:0,MDI_FLOAT:0,MO_FLOAT:0,MMR_FLOAT:0,FI_FLOAT:0,EW_FLOAT:0,SA_FLOAT:0,CC_FLOAT:0,DJS_FLOAT:0,PP_STATUS:"Complete",PPA_STATUS:"On track",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"On track",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-02-15",FLOATING_DAYS:-36,FLOAT_STATUS:"Float<0"},
      {NO:154,PROCESS_UNIT:"Pressurized Tank Farm Common Area",MR_NO:"75231-P3-3310-RE-R-E-0630",MR_DESCR:"CA1 Feed Pump,CA2 Feed Pump,Liquid Fuel Feed Pump",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"IV",TAG_TOTAL:6,QTY:10,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:25,BC_FLOAT:13,TBE_FLOAT:7,CBE_FLOAT:7,AR_FLOAT:7,ARA_FLOAT:7,LOA_FLOAT:7,PO_FLOAT:7,MDI_FLOAT:7,MO_FLOAT:7,MMR_FLOAT:7,FI_FLOAT:0,EW_FLOAT:7,SA_FLOAT:7,CC_FLOAT:7,DJS_FLOAT:7,PP_STATUS:"Complete",PPA_STATUS:"On track",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-04-30",FLOATING_DAYS:25},
      {NO:155,PROCESS_UNIT:"Pressurized Tank Farm Common Area",MR_NO:"75231-P3-3310-ST-R-E-0003",MR_DESCR:"Liquid Fuel Sphere Water Drawoff Equipment",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"IV",TAG_TOTAL:6,QTY:36,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:0,TBE_FLOAT:0,CBE_FLOAT:0,AR_FLOAT:0,ARA_FLOAT:0,LOA_FLOAT:0,PO_FLOAT:0,MDI_FLOAT:-28,MO_FLOAT:-28,MMR_FLOAT:-28,FI_FLOAT:0,EW_FLOAT:0,SA_FLOAT:0,CC_FLOAT:0,DJS_FLOAT:0,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"On track",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"Behind plan",MO_STATUS:"Behind plan",MMR_STATUS:"Behind plan",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-05-30",FLOATING_DAYS:-49,FLOAT_STATUS:"Float<0"},
      {NO:156,PROCESS_UNIT:"Pressurized Tank Farm Common Area",MR_NO:"75231-P3-3310-ST-R-E-0004",MR_DESCR:"LLP STEAM CONDENSATE Collection PACKAGE",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"IV",TAG_TOTAL:1,QTY:1,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:0,TBE_FLOAT:0,CBE_FLOAT:0,AR_FLOAT:0,ARA_FLOAT:0,LOA_FLOAT:0,PO_FLOAT:0,MDI_FLOAT:-28,MO_FLOAT:-28,MMR_FLOAT:-28,FI_FLOAT:0,EW_FLOAT:0,SA_FLOAT:0,CC_FLOAT:0,DJS_FLOAT:0,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"On track",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"Behind plan",MO_STATUS:"Behind plan",MMR_STATUS:"Behind plan",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-06-30",FLOATING_DAYS:-68,FLOAT_STATUS:"Float<0"},
      {NO:157,PROCESS_UNIT:"Pressurized Tank Farm Common Area",MR_NO:"75231-P3-3310-ST-R-E-0005",MR_DESCR:"Offspec Ethylene Mixer",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"IV",TAG_TOTAL:1,QTY:1,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:0,TBE_FLOAT:0,CBE_FLOAT:0,AR_FLOAT:0,ARA_FLOAT:0,LOA_FLOAT:0,PO_FLOAT:0,MDI_FLOAT:-28,MO_FLOAT:-28,MMR_FLOAT:-28,FI_FLOAT:0,EW_FLOAT:0,SA_FLOAT:0,CC_FLOAT:0,DJS_FLOAT:0,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"On track",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"Behind plan",MO_STATUS:"Behind plan",MMR_STATUS:"Behind plan",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-06-30",FLOATING_DAYS:-8,FLOAT_STATUS:"Float<0"},
      {NO:158,PROCESS_UNIT:"Pressurized Tank Farm Common Area",MR_NO:"75231-P3-1700-EQ-R-E-SL04",MR_DESCR:"Shell Roof for Cryogenic Ethylene Tank",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"II",TAG_TOTAL:1,QTY:1,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:0,TBE_FLOAT:0,CBE_FLOAT:0,AR_FLOAT:0,ARA_FLOAT:0,LOA_FLOAT:0,PO_FLOAT:0,MDI_FLOAT:0,MO_FLOAT:0,MMR_FLOAT:0,FI_FLOAT:0,EW_FLOAT:0,SA_FLOAT:0,CC_FLOAT:0,DJS_FLOAT:0,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"On track",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-03-10",FLOATING_DAYS:1,FLOAT_STATUS:"Float<7"},
      {NO:159,PROCESS_UNIT:"Pressurized Tank Farm Common Area",MR_NO:"75231-P3-1700-EQ-R-E-SL05",MR_DESCR:"SAW SMAWl 9Ni",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"II",TAG_TOTAL:1,QTY:1,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:0,TBE_FLOAT:0,CBE_FLOAT:0,AR_FLOAT:0,ARA_FLOAT:0,LOA_FLOAT:0,PO_FLOAT:0,MDI_FLOAT:0,MO_FLOAT:0,MMR_FLOAT:0,FI_FLOAT:0,EW_FLOAT:0,SA_FLOAT:0,CC_FLOAT:0,DJS_FLOAT:0,PP_STATUS:"Complete",PPA_STATUS:"On track",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"On track",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-03-30",FLOATING_DAYS:21,FLOAT_STATUS:"Float>=7<30"},
      {NO:160,PROCESS_UNIT:"Pressurized Tank Farm Common Area",MR_NO:"75231-P3-3320-ST-R-E-0003",MR_DESCR:"Raw Pygas Tank Water Drawoff Equipment",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"IV",TAG_TOTAL:6,QTY:36,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:0,TBE_FLOAT:0,CBE_FLOAT:0,AR_FLOAT:0,ARA_FLOAT:0,LOA_FLOAT:0,PO_FLOAT:0,MDI_FLOAT:-28,MO_FLOAT:-28,MMR_FLOAT:-28,FI_FLOAT:0,EW_FLOAT:0,SA_FLOAT:0,CC_FLOAT:0,DJS_FLOAT:0,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"On track",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"Behind plan",MO_STATUS:"Behind plan",MMR_STATUS:"Behind plan",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-05-30",FLOATING_DAYS:-49,FLOAT_STATUS:"Float<0"},
      {NO:161,PROCESS_UNIT:"Pressurized Tank Farm Common Area",MR_NO:"75231-P3-3320-ST-R-E-0005",MR_DESCR:"Mogas Blending Mixer",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"IV",TAG_TOTAL:1,QTY:1,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:0,TBE_FLOAT:0,CBE_FLOAT:0,AR_FLOAT:0,ARA_FLOAT:0,LOA_FLOAT:0,PO_FLOAT:0,MDI_FLOAT:-28,MO_FLOAT:-28,MMR_FLOAT:-28,FI_FLOAT:0,EW_FLOAT:0,SA_FLOAT:0,CC_FLOAT:0,DJS_FLOAT:0,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"On track",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"Behind plan",MO_STATUS:"Behind plan",MMR_STATUS:"Behind plan",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-06-30",FLOATING_DAYS:-68,FLOAT_STATUS:"Float<0"},
      {NO:162,PROCESS_UNIT:"Storm Water Pump Station and Accident Buffer Basin",MR_NO:"75231-P3-1806-WS-R-M-3002",MR_DESCR:"CS SAWSPS Pipe",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:0,QTY:299,MR_FLOAT:0,RFQ_FLOAT:0,LOA_FLOAT:1,PO_FLOAT:1,EW_FLOAT:1,DJS_FLOAT:1,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"No schedule",TBE_STATUS:"No schedule",CBE_STATUS:"No schedule",AR_STATUS:"No schedule",ARA_STATUS:"No schedule",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"No schedule",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"On track",RAS_ROS:"2022-07-20",FLOATING_DAYS:-10,FLOAT_STATUS:"Float<0"},
      {NO:163,PROCESS_UNIT:"Storm Water Pump Station and Accident Buffer Basin",MR_NO:"75231-P3-1806-WS-R-M-3003",MR_DESCR:"CS DSAWLGS L245 PSL1 Pipe L245",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:0,QTY:62,MR_FLOAT:0,RFQ_FLOAT:0,LOA_FLOAT:1,PO_FLOAT:1,EW_FLOAT:1,DJS_FLOAT:1,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"No schedule",TBE_STATUS:"No schedule",CBE_STATUS:"No schedule",AR_STATUS:"No schedule",ARA_STATUS:"No schedule",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"No schedule",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"On track",RAS_ROS:"2022-07-20",FLOATING_DAYS:-10,FLOAT_STATUS:"Float<0"},
      {NO:164,PROCESS_UNIT:"Storm Water Pump Station and Accident Buffer Basin",MR_NO:"75231-P3-1806-WS-R-M-3102",MR_DESCR:"CS Welded Fitting",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:0,QTY:37,MR_FLOAT:0,RFQ_FLOAT:0,LOA_FLOAT:1,PO_FLOAT:1,EW_FLOAT:1,DJS_FLOAT:1,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"No schedule",TBE_STATUS:"No schedule",CBE_STATUS:"No schedule",AR_STATUS:"No schedule",ARA_STATUS:"No schedule",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"No schedule",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"On track",RAS_ROS:"2022-07-20",FLOATING_DAYS:-10,FLOAT_STATUS:"Float<0"},
      {NO:165,PROCESS_UNIT:"Transformer",MR_NO:"75231-P3-1830-EE-R-E-4002",MR_DESCR:"220kV Transformer",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"II",TAG_TOTAL:4,QTY:16,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:-7,BC_FLOAT:3,TBE_FLOAT:-23,CBE_FLOAT:-23,AR_FLOAT:-23,ARA_FLOAT:-23,LOA_FLOAT:-14,PO_FLOAT:-14,MDI_FLOAT:-14,MO_FLOAT:-14,MMR_FLOAT:-14,FI_FLOAT:0,EW_FLOAT:-14,SA_FLOAT:-14,CC_FLOAT:-14,DJS_FLOAT:-14,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Behind plan",CBE_STATUS:"Behind plan",AR_STATUS:"Behind plan",ARA_STATUS:"Behind plan",LOA_STATUS:"Behind plan",PO_STATUS:"Behind plan",MDI_STATUS:"Behind plan",MO_STATUS:"Behind plan",MMR_STATUS:"Behind plan",FI_STATUS:"On track",EW_STATUS:"Behind plan",SA_STATUS:"Behind plan",CC_STATUS:"Behind plan",DJS_STATUS:"Behind plan",RAS_ROS:"2023-03-30",FLOATING_DAYS:14,FLOAT_STATUS:"Float<7"},
      {NO:166,PROCESS_UNIT:"Truck Scale1",MR_NO:"75231-P3-2527-GL-R-E-0001",MR_DESCR:"Truck Scale",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"IV",TAG_TOTAL:3,QTY:9,MR_FLOAT:0,RFQ_FLOAT:-17,BC_FLOAT:-17,TBE_FLOAT:-17,CBE_FLOAT:-17,AR_FLOAT:-17,ARA_FLOAT:-17,LOA_FLOAT:-17,PO_FLOAT:-17,MDI_FLOAT:-17,MO_FLOAT:-17,MMR_FLOAT:-17,FI_FLOAT:0,EW_FLOAT:-17,SA_FLOAT:-17,CC_FLOAT:-17,DJS_FLOAT:-17,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"Behind plan",BC_STATUS:"Behind plan",TBE_STATUS:"Behind plan",CBE_STATUS:"Behind plan",AR_STATUS:"Behind plan",ARA_STATUS:"Behind plan",LOA_STATUS:"Behind plan",PO_STATUS:"Behind plan",MDI_STATUS:"Behind plan",MO_STATUS:"Behind plan",MMR_STATUS:"Behind plan",FI_STATUS:"On track",EW_STATUS:"Behind plan",SA_STATUS:"Behind plan",CC_STATUS:"Behind plan",DJS_STATUS:"Behind plan",RAS_ROS:"2023-04-30",FLOATING_DAYS:17,FLOAT_STATUS:"Float<7"},
      {NO:167,PROCESS_UNIT:"Wastewater PreTreatment Unit",MR_NO:"75231-P3-1824-EP-R-E-0001",MR_DESCR:"Centrifuge and PAM dosing System",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"IV",TAG_TOTAL:1,QTY:1,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:-8,BC_FLOAT:-8,TBE_FLOAT:-8,CBE_FLOAT:-8,AR_FLOAT:-8,ARA_FLOAT:-8,LOA_FLOAT:-8,PO_FLOAT:-8,MDI_FLOAT:-36,MO_FLOAT:-36,MMR_FLOAT:-36,FI_FLOAT:0,EW_FLOAT:-8,SA_FLOAT:-8,CC_FLOAT:-8,DJS_FLOAT:-8,PP_STATUS:"Complete",PPA_STATUS:"On track",MR_STATUS:"Complete",RFQ_STATUS:"Behind plan",BC_STATUS:"Behind plan",TBE_STATUS:"Behind plan",CBE_STATUS:"Behind plan",AR_STATUS:"Behind plan",ARA_STATUS:"Behind plan",LOA_STATUS:"Behind plan",PO_STATUS:"Behind plan",MDI_STATUS:"Behind plan",MO_STATUS:"Behind plan",MMR_STATUS:"Behind plan",FI_STATUS:"On track",EW_STATUS:"Behind plan",SA_STATUS:"Behind plan",CC_STATUS:"Behind plan",DJS_STATUS:"Behind plan",RAS_ROS:"2023-06-30",FLOATING_DAYS:-11,FLOAT_STATUS:"Float<0"},
      {NO:168,PROCESS_UNIT:"Wastewater PreTreatment Unit",MR_NO:"75231-P3-1824-RE-R-E-0601",MR_DESCR:"High Concentration VOCs Waste Gas Blower,Low Concentration VOCs Waste Gas Blower",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"IV",TAG_TOTAL:4,QTY:8,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:19,BC_FLOAT:12,TBE_FLOAT:16,CBE_FLOAT:16,AR_FLOAT:16,ARA_FLOAT:16,LOA_FLOAT:16,PO_FLOAT:16,MDI_FLOAT:16,MO_FLOAT:16,MMR_FLOAT:16,FI_FLOAT:0,EW_FLOAT:16,SA_FLOAT:16,CC_FLOAT:16,DJS_FLOAT:16,PP_STATUS:"Complete",PPA_STATUS:"On track",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-04-30",FLOATING_DAYS:24},
      {NO:169,PROCESS_UNIT:"Wastewater PreTreatment Unit",MR_NO:"75231-P3-1824-RE-R-E-0622",MR_DESCR:"Brine Discharge Pump,First Flush Transfer Pump,Oily Wastewater Discharge Pump,Slop Oil Lift Pump,Slop Oil Transfer Pump",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"IV",TAG_TOTAL:10,QTY:20,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:29,BC_FLOAT:29,TBE_FLOAT:34,CBE_FLOAT:19,AR_FLOAT:23,ARA_FLOAT:23,LOA_FLOAT:23,PO_FLOAT:23,MDI_FLOAT:23,MO_FLOAT:23,MMR_FLOAT:23,FI_FLOAT:0,EW_FLOAT:23,SA_FLOAT:23,CC_FLOAT:23,DJS_FLOAT:23,PP_STATUS:"Complete",PPA_STATUS:"On track",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-04-30",FLOATING_DAYS:25},
      {NO:170,PROCESS_UNIT:"Wastewater PreTreatment Unit",MR_NO:"75231-P3-1824-RE-R-E-0680",MR_DESCR:"Oily sludge Lift Pump,Oily Wastewater Lift Pump",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"IV",TAG_TOTAL:4,QTY:8,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:29,BC_FLOAT:29,TBE_FLOAT:34,CBE_FLOAT:19,AR_FLOAT:23,ARA_FLOAT:23,LOA_FLOAT:23,PO_FLOAT:23,MDI_FLOAT:23,MO_FLOAT:23,MMR_FLOAT:23,FI_FLOAT:0,EW_FLOAT:23,SA_FLOAT:23,CC_FLOAT:23,DJS_FLOAT:23,PP_STATUS:"Complete",PPA_STATUS:"On track",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-04-30",FLOATING_DAYS:25},
      {NO:171,PROCESS_UNIT:"Water and Firewater Pump Station 1",MR_NO:"75231-P3-2810-WS-R-M-3001",MR_DESCR:"CS Seamless Pipe",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:0,QTY:167,MR_FLOAT:0,RFQ_FLOAT:0,LOA_FLOAT:0,PO_FLOAT:0,EW_FLOAT:0,DJS_FLOAT:0,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"No schedule",TBE_STATUS:"No schedule",CBE_STATUS:"No schedule",AR_STATUS:"No schedule",ARA_STATUS:"No schedule",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"No schedule",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"On track",RAS_ROS:"2022-07-30",FLOATING_DAYS:-10,FLOAT_STATUS:"Float<0"},
      {NO:172,PROCESS_UNIT:"Water and Firewater Pump Station 1",MR_NO:"75231-P3-2810-WS-R-M-3002",MR_DESCR:"CS SAWSPS Pipe",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:0,QTY:95,MR_FLOAT:0,RFQ_FLOAT:0,LOA_FLOAT:0,PO_FLOAT:0,EW_FLOAT:0,DJS_FLOAT:0,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"No schedule",TBE_STATUS:"No schedule",CBE_STATUS:"No schedule",AR_STATUS:"No schedule",ARA_STATUS:"No schedule",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"No schedule",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"On track",RAS_ROS:"2022-07-30",FLOATING_DAYS:-10,FLOAT_STATUS:"Float<0"},
      {NO:173,PROCESS_UNIT:"Water and Firewater Pump Station 1",MR_NO:"75231-P3-2810-WS-R-M-3101",MR_DESCR:"CS Seamless Fitting",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:0,QTY:15,MR_FLOAT:0,RFQ_FLOAT:0,LOA_FLOAT:0,PO_FLOAT:0,EW_FLOAT:0,DJS_FLOAT:0,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"No schedule",TBE_STATUS:"No schedule",CBE_STATUS:"No schedule",AR_STATUS:"No schedule",ARA_STATUS:"No schedule",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"No schedule",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"On track",RAS_ROS:"2022-07-30",FLOATING_DAYS:-10,FLOAT_STATUS:"Float<0"},
      {NO:174,PROCESS_UNIT:"Water and Firewater Pump Station 1",MR_NO:"75231-P3-2810-WS-R-M-3102",MR_DESCR:"CS Welded Fitting",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:0,QTY:11,MR_FLOAT:0,RFQ_FLOAT:0,LOA_FLOAT:0,PO_FLOAT:0,EW_FLOAT:0,DJS_FLOAT:0,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"No schedule",TBE_STATUS:"No schedule",CBE_STATUS:"No schedule",AR_STATUS:"No schedule",ARA_STATUS:"No schedule",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"No schedule",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"On track",RAS_ROS:"2022-07-30",FLOATING_DAYS:-10,FLOAT_STATUS:"Float<0"},
      {NO:175,PROCESS_UNIT:"Water and Firewater Pump Station 1",MR_NO:"75231-P3-1810-EQ-R-E-1006",MR_DESCR:"potable water tank",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"IV",TAG_TOTAL:1,QTY:1,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:0,TBE_FLOAT:0,CBE_FLOAT:0,AR_FLOAT:0,ARA_FLOAT:0,LOA_FLOAT:0,PO_FLOAT:0,MDI_FLOAT:0,MO_FLOAT:0,MMR_FLOAT:0,FI_FLOAT:0,EW_FLOAT:0,SA_FLOAT:0,CC_FLOAT:0,DJS_FLOAT:0,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"On track",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-04-20",FLOATING_DAYS:-3,FLOAT_STATUS:"Float<0"},
      {NO:176,PROCESS_UNIT:"Water and Firewater Pump Station 1",MR_NO:"75231-P3-1810-FF-R-E-0001",MR_DESCR:"Fire water pumpDiesel,Fire water pumpELE,Pressure stabilizing pump ELE",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"III",TAG_TOTAL:8,QTY:22,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:20,BC_FLOAT:17,TBE_FLOAT:18,CBE_FLOAT:18,AR_FLOAT:18,ARA_FLOAT:18,LOA_FLOAT:18,PO_FLOAT:18,MDI_FLOAT:18,MO_FLOAT:18,MMR_FLOAT:18,FI_FLOAT:0,EW_FLOAT:18,SA_FLOAT:18,CC_FLOAT:18,DJS_FLOAT:18,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-03-30",FLOATING_DAYS:-54,FLOAT_STATUS:"Float<0"},
      {NO:177,PROCESS_UNIT:"Water and Firewater Pump Station 1",MR_NO:"75231-P3-1810-RE-R-E-0622A",MR_DESCR:"potable water pump",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"IV",TAG_TOTAL:2,QTY:4,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:29,BC_FLOAT:29,TBE_FLOAT:34,CBE_FLOAT:19,AR_FLOAT:23,ARA_FLOAT:23,LOA_FLOAT:23,PO_FLOAT:23,MDI_FLOAT:23,MO_FLOAT:23,MMR_FLOAT:23,FI_FLOAT:0,EW_FLOAT:23,SA_FLOAT:23,CC_FLOAT:23,DJS_FLOAT:23,PP_STATUS:"Complete",PPA_STATUS:"On track",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"Complete",CBE_STATUS:"Complete",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-04-30",FLOATING_DAYS:25},
      {NO:178,PROCESS_UNIT:"Water and Firewater Pump Station 1",MR_NO:"75231-P3-1810-WS-R-M-3001",MR_DESCR:"CS Seamless Pipe",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:0,QTY:123,MR_FLOAT:0,RFQ_FLOAT:0,LOA_FLOAT:0,PO_FLOAT:0,EW_FLOAT:0,DJS_FLOAT:0,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"No schedule",TBE_STATUS:"No schedule",CBE_STATUS:"No schedule",AR_STATUS:"No schedule",ARA_STATUS:"No schedule",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"No schedule",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"On track",RAS_ROS:"2022-07-30",FLOATING_DAYS:-10,FLOAT_STATUS:"Float<0"},
      {NO:179,PROCESS_UNIT:"Water and Firewater Pump Station 1",MR_NO:"75231-P3-1810-WS-R-M-3002",MR_DESCR:"CS SAWSPS Pipe",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:0,QTY:199,MR_FLOAT:0,RFQ_FLOAT:0,LOA_FLOAT:0,PO_FLOAT:0,EW_FLOAT:0,DJS_FLOAT:0,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"No schedule",TBE_STATUS:"No schedule",CBE_STATUS:"No schedule",AR_STATUS:"No schedule",ARA_STATUS:"No schedule",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"No schedule",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"On track",RAS_ROS:"2022-07-30",FLOATING_DAYS:-10,FLOAT_STATUS:"Float<0"},
      {NO:180,PROCESS_UNIT:"Water and Firewater Pump Station 1",MR_NO:"75231-P3-1810-WS-R-M-3101",MR_DESCR:"CS Seamless Fitting",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:0,QTY:8,MR_FLOAT:0,RFQ_FLOAT:0,LOA_FLOAT:0,PO_FLOAT:0,EW_FLOAT:0,DJS_FLOAT:0,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"No schedule",TBE_STATUS:"No schedule",CBE_STATUS:"No schedule",AR_STATUS:"No schedule",ARA_STATUS:"No schedule",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"No schedule",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"On track",RAS_ROS:"2022-07-30",FLOATING_DAYS:-10,FLOAT_STATUS:"Float<0"},
      {NO:181,PROCESS_UNIT:"Water and Firewater Pump Station 1",MR_NO:"75231-P3-1810-WS-R-M-3102",MR_DESCR:"CS Welded Fitting",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"NA",TAG_TOTAL:0,QTY:25,MR_FLOAT:0,RFQ_FLOAT:0,LOA_FLOAT:0,PO_FLOAT:0,EW_FLOAT:0,DJS_FLOAT:0,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"No schedule",TBE_STATUS:"No schedule",CBE_STATUS:"No schedule",AR_STATUS:"No schedule",ARA_STATUS:"No schedule",LOA_STATUS:"Complete",PO_STATUS:"On track",MDI_STATUS:"No schedule",MO_STATUS:"No schedule",MMR_STATUS:"No schedule",FI_STATUS:"No schedule",EW_STATUS:"On track",SA_STATUS:"No schedule",CC_STATUS:"No schedule",DJS_STATUS:"On track",RAS_ROS:"2022-07-30",FLOATING_DAYS:-10,FLOAT_STATUS:"Float<0"},
      {NO:182,PROCESS_UNIT:"Water and Firewater Pump Station 2",MR_NO:"75231-P3-2810-EQ-R-E-1006",MR_DESCR:"Fire Water Storage Tank",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"IV",TAG_TOTAL:2,QTY:4,MR_FLOAT:0,RFQ_FLOAT:0,BC_FLOAT:0,TBE_FLOAT:0,CBE_FLOAT:0,AR_FLOAT:0,ARA_FLOAT:0,LOA_FLOAT:0,PO_FLOAT:0,MDI_FLOAT:0,MO_FLOAT:0,MMR_FLOAT:0,FI_FLOAT:0,EW_FLOAT:0,SA_FLOAT:0,CC_FLOAT:0,DJS_FLOAT:0,PP_STATUS:"No schedule",PPA_STATUS:"No schedule",MR_STATUS:"On track",RFQ_STATUS:"On track",BC_STATUS:"On track",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-01-15",FLOATING_DAYS:-37,FLOAT_STATUS:"Float<0"},
      {NO:183,PROCESS_UNIT:"Water and Firewater Pump Station 2",MR_NO:"75231-P3-2810-FF-R-E-0001",MR_DESCR:"Fire water pump Diesel,Fire water pump ELE,FOAM FIRE WAER DISCHARGE PUMP,Pressure stabilizing pump ELE",PSRNUM:"P3_PSR",CRITICALITY_RATING_LEVEL:"III",TAG_TOTAL:10,QTY:26,PP_FLOAT:0,PPA_FLOAT:0,MR_FLOAT:0,RFQ_FLOAT:20,BC_FLOAT:17,TBE_FLOAT:18,CBE_FLOAT:18,AR_FLOAT:18,ARA_FLOAT:18,LOA_FLOAT:18,PO_FLOAT:18,MDI_FLOAT:18,MO_FLOAT:18,MMR_FLOAT:18,FI_FLOAT:0,EW_FLOAT:18,SA_FLOAT:18,CC_FLOAT:18,DJS_FLOAT:18,PP_STATUS:"Complete",PPA_STATUS:"Complete",MR_STATUS:"Complete",RFQ_STATUS:"Complete",BC_STATUS:"Complete",TBE_STATUS:"On track",CBE_STATUS:"On track",AR_STATUS:"On track",ARA_STATUS:"On track",LOA_STATUS:"On track",PO_STATUS:"On track",MDI_STATUS:"On track",MO_STATUS:"On track",MMR_STATUS:"On track",FI_STATUS:"On track",EW_STATUS:"On track",SA_STATUS:"On track",CC_STATUS:"On track",DJS_STATUS:"On track",RAS_ROS:"2023-03-30",FLOATING_DAYS:-54,FLOAT_STATUS:"Float<0"}

    ]
}