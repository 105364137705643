import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 920,
      CanvasHeight: 50,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },
    // Queries: {
    //   SQL1: [
        
    //   ]
    // },
    DataItems:  [
      // {IG_NO:"IG-E2-001",LOCATIONS:"South BL gate of  Main Plant",COORDINATION:"X: 2514432.060     Y: 558266.500  ",REMARKS:"IP with HQGD/SHDI/GEG SHP steam/NG"},
      {TOTAL:23,NEW:0,DELETED:0,INPROGRESS:0,DELIVERED:0,CLOSED:23,REJECTED:0,RESUBMITTED:0,OVERDUE_IP:0,CRITICAL_IP:0}
    
    ],
}