/* 
  ### Chart Default Data ### 
  Predefined component's behaviors for the Filter & Action
*/

//00_Home
  import CHV2_LUN_Popup_Data               from '../10_China1/SystemCompletion/Lun_Popup/includes/primitives/data'
//01.Project
  import CHV2_InformationGuide_Data        from '../10_China1/Project/Information_Guide/includes/primitives/data'
  import CHV2_InformationGuideV2_Data      from '../10_China1/Project/InformationGuide_v2/includes/primitives/data'
  import CHV2_Construction_Photo_Data      from '../10_China1/Project/Construction_Photo/includes/primitives/data'
//02_Engineering
  import CHV2_AreaSummary_Data             from '../10_China1/Engineering/Area_Summary/Progress/includes/primitives/data'
  import CHV2_AreaSummaryV1_Data           from '../10_China1/Engineering/Area_Summary_v1/Progress/includes/primitives/data'
//03.Planning
  import CHV2_Level1ScheduleNew_Data       from '../10_China1/Planning/Level1ScheduleNew/includes/primitives/Data'
//04_Construction
//05_Procurement
//06_QualityControl
//08.SystemCompletion
  import CHV2_UtilityCommissioning_Data    from '../10_China1/SystemCompletion/UtilityCommissioning/includes/primitives/data'
  import CHV2_UtilityCommissioningV2_Data  from '../10_China1/SystemCompletion/UtilityCommissioningV2/includes/primitives/data'
  import CHV2_CsuSequence_P1_Data          from '../10_China1/SystemCompletion/Startup_Sequence/CSU_P1/includes/primitives/data'
  import CHV2_CsuSequence_P2_GPPE_Data     from '../10_China1/SystemCompletion/Startup_Sequence/CSU_P2_GPPE/includes/primitives/data'
  import CHV2_CsuSequence_P2_LDPE_Data     from '../10_China1/SystemCompletion/Startup_Sequence/CSU_P2_LDPE/includes/primitives/data'
  import CHV2_CsuSequence_P2_PP_Data       from '../10_China1/SystemCompletion/Startup_Sequence/CSU_P2_PP/includes/primitives/data'
  import CHV2_CsuSequence_P3_Data          from '../10_China1/SystemCompletion/Startup_Sequence/CSU_P3/includes/primitives/data'
  import CHV2_LiveUpdateNotice_Data        from '../10_China1/SystemCompletion/LiveUpdateNotice/includes/primitives/data'
  import CHV2_Owner_Overall_Status_Data    from '../10_China1/SystemCompletion/OwnerOverallStatus/includes/primitives/data'
  import CHV2_SkylineSubsys_Data           from '../10_China1/SystemCompletion/SkylineSubsys/includes/primitives/data'
  import CHV2_SkylineNew_Data              from '../10_China1/SystemCompletion/SkylineNew/includes/primitives/data'
  import CHV2_SkylineSubsys2_Data           from '../10_China1/SystemCompletion/SkylineSubsys2/includes/primitives/data'
  import CHV2_SkylineNew2_Data              from '../10_China1/SystemCompletion/SkylineNew2/includes/primitives/data'

//09.Temp

export default {

  //00_Home
    CHV2_LiveUpdateNotice_Data,
    CHV2_LUN_Popup_Data,
    
  //01.Project
    CHV2_InformationGuide_Data,
    CHV2_InformationGuideV2_Data,
    CHV2_Construction_Photo_Data,

  //02_Engineering
    CHV2_AreaSummary_Data,
    CHV2_AreaSummaryV1_Data,

  //03.Planning
    CHV2_Level1ScheduleNew_Data,

  //04_Construction
  //05_Procurement
  //06_QualityControl

  //08.SystemCompletion
    CHV2_UtilityCommissioning_Data,
    CHV2_UtilityCommissioningV2_Data,
    CHV2_CsuSequence_P1_Data,
    CHV2_CsuSequence_P2_GPPE_Data,
    CHV2_CsuSequence_P2_LDPE_Data,
    CHV2_CsuSequence_P2_PP_Data,
    CHV2_CsuSequence_P3_Data,
    CHV2_Owner_Overall_Status_Data,
    CHV2_SkylineSubsys_Data,
    CHV2_SkylineNew_Data,
    CHV2_SkylineSubsys2_Data,
    CHV2_SkylineNew2_Data,
  //09.Temp
}
