import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 900,
      CanvasHeight: 350,
      CanvasBorderWeight: 0.3,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 310,
      CanvasChartHeight: 270,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: 'Transparent',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },

    DataItems: [
      // { DISC: 'Structure',  OUTFIT: 'Steel Outfitting',   ESTIMATE:756,   CUR_QTY: 1042,  MTL_ACT: 1038,  MTL_PRO: 99.6,  INSTALL: 935,   INS_PRO: 20.22, },
      // { DISC: 'Structure',  OUTFIT: 'Grating',            ESTIMATE:244,   CUR_QTY: 4420,  MTL_ACT: 4414,  MTL_PRO: 99.9,  INSTALL: 3264,  INS_PRO: 15.55, },
      // { DISC: 'Piping',     OUTFIT: 'Support',            ESTIMATE:1803,  CUR_QTY: 1797,  MTL_ACT: 1797,  MTL_PRO: 100,   INSTALL: 1572,  INS_PRO: 13.22, },
      // { DISC: 'Piping',     OUTFIT: 'Spool',              ESTIMATE:2760,  CUR_QTY: 3019,  MTL_ACT: 3010,  MTL_PRO: 99.7,  INSTALL: 2643,  INS_PRO: 12, },
      // { DISC: 'E&I',        OUTFIT: 'Support',            ESTIMATE:11230, CUR_QTY: 11370, MTL_ACT: 11369, MTL_PRO: 20.22,   INSTALL: 10572, INS_PRO: 8, },
      // { DISC: 'E&I',        OUTFIT: 'Cable Tray',         ESTIMATE:6594,  CUR_QTY: 8378,  MTL_ACT: 8374,  MTL_PRO: 100,   INSTALL: 7704,  INS_PRO: 9, },
      // { DISC: 'HVAC',       OUTFIT: 'Duct Support',       ESTIMATE:539,   CUR_QTY: 698,   MTL_ACT: 698,   MTL_PRO: 100,   INSTALL: 667,   INS_PRO: 10, },
      // { DISC: 'HVAC',       OUTFIT: 'Duct',               ESTIMATE:1484,  CUR_QTY: 903,   MTL_ACT: 903,   MTL_PRO: 12.22,   INSTALL: 821,   INS_PRO: 10.43, },
      // { DISC: 'Mechanical', OUTFIT: 'Seat',               ESTIMATE:40,    CUR_QTY: 210,   MTL_ACT: 209,   MTL_PRO: 0,  INSTALL: 22,    INS_PRO: 0, },    
      {DISC:"Piping",OUTFIT:"Support",ESTMATE:73983.99000000003,CUR_QTY:73983.99000000003,FABRICATION:68521.28000000003,FAB_PRO:92.61,INSTALL:65195.8,INS_PRO:88.12},
      {DISC:"Piping",OUTFIT:"Spool",ESTMATE:16467.5,CUR_QTY:16467.5,FABRICATION:9833.95,FAB_PRO:59.71,INSTALL:2473.18,INS_PRO:15.01},
      {DISC:"Piping",OUTFIT:"Spool",ESTMATE:16467.5,CUR_QTY:16467.5,FABRICATION:9833.95,FAB_PRO:59.71,INSTALL:2473.18,INS_PRO:15.01},
      {DISC:"Piping",OUTFIT:"Spool",ESTMATE:16467.5,CUR_QTY:16467.5,FABRICATION:9833.95,FAB_PRO:59.71,INSTALL:2473.18,INS_PRO:15.01},
      {DISC:"Piping",OUTFIT:"Spool",ESTMATE:16467.5,CUR_QTY:16467.5,FABRICATION:9833.95,FAB_PRO:59.71,INSTALL:2473.18,INS_PRO:15.01},
      {DISC:"Electrical",OUTFIT:"Support",ESTMATE:104172.85,CUR_QTY:104172.85,FABRICATION:102280.63,FAB_PRO:98.18,INSTALL:92858.01,INS_PRO:89.13},
      {DISC:"Electrical",OUTFIT:"Cable Tray",ESTMATE:12440.86,CUR_QTY:12440.86,FABRICATION:0,FAB_PRO:0,INSTALL:1297.76,INS_PRO:10.43},
      {DISC:"Electrical",OUTFIT:"Cable Tray",ESTMATE:12440.86,CUR_QTY:12440.86,FABRICATION:0,FAB_PRO:0,INSTALL:1297.76,INS_PRO:10.43},
      {DISC:"Bulidings Works",OUTFIT:"Pins",ESTMATE:36562.62,CUR_QTY:36562.62,FABRICATION:0,FAB_PRO:0,INSTALL:34823.82,INS_PRO:95.24},
      {DISC:"Bulidings Works",OUTFIT:"Support",ESTMATE:36562.62,CUR_QTY:36562.62,FABRICATION:0,FAB_PRO:0,INSTALL:34823.82,INS_PRO:95.24},
      {DISC:"Bulidings Works",OUTFIT:"Support",ESTMATE:36562.62,CUR_QTY:36562.62,FABRICATION:0,FAB_PRO:0,INSTALL:34823.82,INS_PRO:95.24},
      {DISC:"HVAC",OUTFIT:"Pre-Outfitting",ESTMATE:101927.964,CUR_QTY:101927.964,FABRICATION:71957.10999999999,FAB_PRO:70.59,INSTALL:62941.71000000002,INS_PRO:61.75},
      {DISC:"Structure",OUTFIT:"Pre-Outfitting",ESTMATE:958441.9320000003,CUR_QTY:958441.9320000003,FABRICATION:921413.7400000002,FAB_PRO:96.06,INSTALL:881343.5500000003,INS_PRO:91.95}
    ],
}