import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 700,
      CanvasHeight: 280,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Support Fabrication', x:20, y: -20, font: 'roboto', class: 'regular', size: 12, color: '#424242', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: -50, y: -20, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'start' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: -30,
          y: -20,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'start'
        },
      ]
    },
    Queries: {
      // SQL1: [
      // ],
    },
    DataItems:    [
      {DISC_NAME:"C-Civil and Infrastructure",ISSUED:680,APPROVED:481,UNDER_REVIEW:120,REJECTED:54,CANCELLED:25},
      {DISC_NAME:"D-Mechanical and Static Equipment",ISSUED:69,APPROVED:29,UNDER_REVIEW:23,REJECTED:15,CANCELLED:2},
      {DISC_NAME:"E-Electrical",ISSUED:30,APPROVED:14,UNDER_REVIEW:6,REJECTED:10,CANCELLED:0},
      {DISC_NAME:"K-Construction, Transportation, and Logistics",ISSUED:391,APPROVED:327,UNDER_REVIEW:22,REJECTED:12,CANCELLED:30},
      {DISC_NAME:"L-Piping and General Layout",ISSUED:212,APPROVED:146,UNDER_REVIEW:34,REJECTED:27,CANCELLED:5},
      {DISC_NAME:"NA",ISSUED:3,APPROVED:3,UNDER_REVIEW:0,REJECTED:0,CANCELLED:0},
      {DISC_NAME:"N-Structural (includes Weight Control)",ISSUED:51,APPROVED:39,UNDER_REVIEW:7,REJECTED:5,CANCELLED:0},
      {DISC_NAME:"T-Telecommunications",ISSUED:20,APPROVED:17,UNDER_REVIEW:3,REJECTED:0,CANCELLED:0},
      {DISC_NAME:"Total",ISSUED:1456,APPROVED:1056,UNDER_REVIEW:215,REJECTED:123,CANCELLED:62}
    ],
}