import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1200,
      CanvasHeight: 140,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Weight : ', x:740, y: 0, font: 'roboto', class: 'regular', size: 11, color: '#BCBCBC', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: 740, y: 0, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'end' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: 740,
          y: 0,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'end'
        },
      ]
    },
    DataItems: [
      {DISC_DESCR:"Piping",ITEM:"Support",UOM:"KG",TOTAL:15871,COMPLETED:208,REMAIN:15664,PROG:1.3},
      {DISC_DESCR:"Piping",ITEM:"Spool",UOM:"DIA",TOTAL:1276,COMPLETED:15,REMAIN:1261,PROG:1.1},
      {DISC_DESCR:"Electrical",ITEM:"Cable Termination",UOM:"U",TOTAL:2194,COMPLETED:2,REMAIN:2192,PROG:0},
      {DISC_DESCR:"Electrical",ITEM:"Cable Pulling",UOM:"LM",TOTAL:166840,COMPLETED:1280,REMAIN:165560,PROG:0.7},
      {DISC_DESCR:"Instrument",ITEM:"Tubing",UOM:"LM",TOTAL:0.1,COMPLETED:0,REMAIN:0.1,PROG:0},
      {DISC_DESCR:"Instrument",ITEM:"Cable Termination",UOM:"U",TOTAL:1262,COMPLETED:0,REMAIN:1262,PROG:0},
      {DISC_DESCR:"Instrument",ITEM:"Cable Pulling",UOM:"LM",TOTAL:87824,COMPLETED:0,REMAIN:87824,PROG:0}
    ],
}