import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1040,
      CanvasHeight: 400,
      CanvasBorderWeight: 0.3,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 310,
      CanvasChartHeight: 270,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },

    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },

    DataItems: [
      {NO:1,PACKAGE:"P1",AREA:"OR",MILESTONES_DESCRIPTION:"Structure Foundation Drawing LAST batch IFC (ST-2B,2C,2D,2E,4D,4F,4G,5G)",MS_OWNER:"Xue dangchen",FORECAST_DATE:"2022-12-15",CONSTRUCTION_REQUIRED_DATE:"2022-12-15",STATUS:"On/Ahead Plan"},
      {NO:2,PACKAGE:"P1",AREA:"OR",MILESTONES_DESCRIPTION:"Substation Concrete Structure Drawing IFC",MS_OWNER:"Xue dangchen",BASELINE_DATE:"2022-09-29",FORECAST_DATE:"2022-12-15",CONSTRUCTION_REQUIRED_DATE:"2022-12-15",STATUS:"Delay not Pass Plan"},
      {NO:3,PACKAGE:"P1",AREA:"GHU",MILESTONES_DESCRIPTION:"Complete All Foundation IFC Drawings",MS_OWNER:"Wang Haijing",BASELINE_DATE:"2022-10-08",FORECAST_DATE:"2022-12-15",CONSTRUCTION_REQUIRED_DATE:"2022-12-15",STATUS:"Delay not Pass Plan"},
      {NO:4,PACKAGE:"P1",AREA:"GHU",MILESTONES_DESCRIPTION:"Complete All UG Branch Piping Layout Drawings",MS_OWNER:"Wang Haijing",FORECAST_DATE:"2022-12-15",CONSTRUCTION_REQUIRED_DATE:"2022-12-15",STATUS:"On/Ahead Plan"},
      {NO:5,PACKAGE:"P1",AREA:"GHU",MILESTONES_DESCRIPTION:"Complete All UG Branch Piping Isometric Drawings ",MS_OWNER:"Wang Haijing",BASELINE_DATE:"2023-08-31",FORECAST_DATE:"2022-12-15",CONSTRUCTION_REQUIRED_DATE:"2022-12-15",STATUS:"On/Ahead Plan"},
      {NO:6,PACKAGE:"P1",AREA:"GHU",MILESTONES_DESCRIPTION:"Requisition - Off-line Instrument",MS_OWNER:"Wang Haijing",BASELINE_DATE:"2022-07-07",FORECAST_DATE:"2022-12-15",STATUS:"Delay not Pass Plan"},
      {NO:7,PACKAGE:"P1",AREA:"GHU",MILESTONES_DESCRIPTION:"Main Piperack Steel Structure IFC Drawing",MS_OWNER:"Wang Haijing",BASELINE_DATE:"2022-11-18",FORECAST_DATE:"2022-12-15",CONSTRUCTION_REQUIRED_DATE:"2022-12-15",STATUS:"Delay not Pass Plan"},
      {NO:8,PACKAGE:"P1",AREA:"GHU",MILESTONES_DESCRIPTION:"First batch Frame Structure-Steel Structure Drawing IFC",MS_OWNER:"TBD",BASELINE_DATE:"2022-11-18",FORECAST_DATE:"2022-12-15",CONSTRUCTION_REQUIRED_DATE:"2022-12-15",STATUS:"Delay not Pass Plan"},
      {NO:9,PACKAGE:"P1",AREA:"BD",MILESTONES_DESCRIPTION:"Requisition - Off-line Instrument (last batch)",MS_OWNER:"Wang Chengcheng",BASELINE_DATE:"2022-08-23",FORECAST_DATE:"2022-12-15",STATUS:"Delay not Pass Plan"},
      {NO:10,PACKAGE:"P1",AREA:"BD",MILESTONES_DESCRIPTION:"Piperack Steel Structure Drawing IFC",MS_OWNER:"TBD",BASELINE_DATE:"2022-11-29",FORECAST_DATE:"2022-12-15",CONSTRUCTION_REQUIRED_DATE:"2022-12-30",STATUS:"Delay not Pass Plan"},
      {NO:11,PACKAGE:"P1",AREA:"BTX",MILESTONES_DESCRIPTION:"Complete All Foundation IFC Drawings",MS_OWNER:"Wang Haijing",BASELINE_DATE:"2022-10-14",FORECAST_DATE:"2022-12-15",CONSTRUCTION_REQUIRED_DATE:"2022-12-15",STATUS:"Delay not Pass Plan"},
      {NO:12,PACKAGE:"P1",AREA:"BTX",MILESTONES_DESCRIPTION:"Complete All UG Branch Piping Layout Drawings",MS_OWNER:"Wang Haijing",FORECAST_DATE:"2022-12-15",CONSTRUCTION_REQUIRED_DATE:"2022-12-15",STATUS:"On/Ahead Plan"},
      {NO:13,PACKAGE:"P1",AREA:"BTX",MILESTONES_DESCRIPTION:"Complete All UG Branch Piping Isometric Drawings",MS_OWNER:"Wang Haijing",BASELINE_DATE:"2023-08-31",FORECAST_DATE:"2022-12-15",CONSTRUCTION_REQUIRED_DATE:"2022-12-15",STATUS:"On/Ahead Plan"},
      {NO:14,PACKAGE:"P1",AREA:"BTX",MILESTONES_DESCRIPTION:"Requisition - Off-line Instrument",MS_OWNER:"Wang Haijing",BASELINE_DATE:"2022-07-07",FORECAST_DATE:"2022-12-15",STATUS:"Delay not Pass Plan"},
      {NO:15,PACKAGE:"P1",AREA:"BTX",MILESTONES_DESCRIPTION:"Main Piperack Steel Structure IFC Drawing",MS_OWNER:"Wang Haijing",BASELINE_DATE:"2022-11-18",FORECAST_DATE:"2022-12-15",CONSTRUCTION_REQUIRED_DATE:"2022-12-15",STATUS:"Delay not Pass Plan"},
      {NO:16,PACKAGE:"P1",AREA:"DIB",MILESTONES_DESCRIPTION:"Requisition - Off-line Instrument (last batch)",MS_OWNER:"Wang Chengcheng",BASELINE_DATE:"2022-08-23",FORECAST_DATE:"2022-12-15",STATUS:"Delay not Pass Plan"},
      {NO:17,PACKAGE:"P1",AREA:"DIB",MILESTONES_DESCRIPTION:"Piperack Steel Structure Drawing IFC",MS_OWNER:"TBD",BASELINE_DATE:"2022-11-18",FORECAST_DATE:"2022-12-15",CONSTRUCTION_REQUIRED_DATE:"2022-12-30",STATUS:"Delay not Pass Plan"},
      {NO:18,PACKAGE:"P1",AREA:"BO",MILESTONES_DESCRIPTION:"Ground Rotating Equipment Foundation Drawing IFC",MS_OWNER:"Wang Haijing",BASELINE_DATE:"2022-08-23",FORECAST_DATE:"2022-12-15",CONSTRUCTION_REQUIRED_DATE:"2022-12-30",STATUS:"Delay not Pass Plan"},
      {NO:19,PACKAGE:"P1",AREA:"DEM",MILESTONES_DESCRIPTION:"Demin.Water Rain Shed Concrete Part Construction Drawing IFC",MS_OWNER:"TBD",FORECAST_DATE:"2022-12-15",CONSTRUCTION_REQUIRED_DATE:"2022-12-15",STATUS:"On/Ahead Plan"},
      {NO:20,PACKAGE:"P3",AREA:"Elevated Flare System ",MILESTONES_DESCRIPTION:"EFS Ground Flare Foundation Construction Start 基础施工开始",MS_OWNER:"Jiangwei",BASELINE_DATE:"2022-12-02",FORECAST_DATE:"2022-12-15",STATUS:"On/Ahead Plan"}


    ]
}