import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 840,
    CanvasHeight: 500,
    CanvasBorderWeight: 0.3,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.2,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 310,
    CanvasChartHeight: 270,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: 'Transparent',
    CanvasChartFillColor: 'Transparent',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  Text: {
    Text: [],
    Filter: [],
    Sql: [
      {
        query: "SELECT 'Datasource: Material Plan 04-Oct-20' TITLE", 
        text: 'Datasource: Material Plan 04-Oct-20',
        x: 10,
        y: 10,
        font: 'roboto',
        style: 'regular',
        size: 12,
        color: '#757575',
        align: 'start'
      },
    ]
  },
  DataItems: [
    {NO:"1",DISC:"M",TAG:"21-Z-809001",TAG_DESCR:"SEWAGE TREATMENT PACKAGE",PO_ACTUAL:"2020-09-01",FAT_ACTUAL:"2020-09-01",ETA_ACTUAL:"2020-09-01",ROS:"2020-09-01",UPPERBLOCK_ERECTION:"2020-09-01",LIFTING:"2020-09-01",INSTALL:"2020-09-01"},
    {NO:"2",DISC:"M",TAG:"21-P-809001",TAG_DESCR:"VACUUM PUMP UNIT",PO_ACTUAL:"2020-09-01",FAT_ACTUAL:"2020-09-01",ETA_ACTUAL:"2020-09-01",ROS:"2020-09-01",UPPERBLOCK_ERECTION:"2020-09-01",LIFTING:"2020-09-01",INSTALL:"2020-09-01"},
    {NO:"3",DISC:"M",TAG:"21-WEDL-983001",TAG_DESCR:"SLUDGE TRANSFER PUMP SLUDGE TRANSFER PUMP SLUDGE TRANSFER PUMP",PO_ACTUAL:"2020-09-01",FAT_ACTUAL:"2020-09-01",ETA_ACTUAL:"2020-09-01",ROS:"2020-09-01",UPPERBLOCK_ERECTION:"2020-09-01",LIFTING:"2020-09-01",INSTALL:"2020-09-01"}
  ],
  Queries: {
    SQL1: [
      {TOTAL:3,PO:0,FAT:0,ETA:0,LIFTING:0,INSTALL:0}
    ]
  }
}