import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1610,
      CanvasHeight: 850,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1610,
      CanvasChartHeight: 850,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },

    DataItems: [
      {GWBS_LV3:"ME01",BLOCK:"E1A",PLAN:46.17,ACTUAL:50.53,DELTA:4.36},
      {GWBS_LV3:"ME01",BLOCK:"E1B",PLAN:22.37,ACTUAL:21.29,DELTA:-1.07},
      {GWBS_LV3:"ME01",BLOCK:"E1C",PLAN:17.5,ACTUAL:21.78,DELTA:4.28},
      {GWBS_LV3:"ME01",BLOCK:"E1D",PLAN:11.21,ACTUAL:0,DELTA:-11.21}
      //실제로는 BLOCK칼럼이 없음
      // {GWBS_LV3:"ME01",GWBS_LEVEL:"E1A",PLAN:46.17,ACTUAL:50.53,DELTA:4.36}, 
      // {GWBS_LV3:"ME01",GWBS_LEVEL:"E1B",PLAN:22.37,ACTUAL:21.29,DELTA:-1.07},
      // {GWBS_LV3:"ME01",GWBS_LEVEL:"E1C",PLAN:17.5,ACTUAL:21.78,DELTA:4.28},
      // {GWBS_LV3:"ME01",GWBS_LEVEL:"E1D",PLAN:11.21,ACTUAL:0,DELTA:-11.21}
    ],

    Queries: {
      SQL1:  [
        {GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA",BLOCK:"E1A",PO_TOTAL:14,PO_ACTUAL:14,FAT_TOTAL:14,FAT_ACTUAL:7,IRN_TOTAL:14,IRN_ACTUAL:7,ONSITE_TOTAL:14,ONSITE_ACTUAL:2,INST_TOTAL:14,INST_ACTUAL:0,NOT_PO_CNT:0,ETA_PAST_FROM_ROS:11,ETA_PAST_FROM_UPPERBLOCK:0},
        {GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB",BLOCK:"E1B",PO_TOTAL:12,PO_ACTUAL:12,FAT_TOTAL:12,FAT_ACTUAL:5,IRN_TOTAL:12,IRN_ACTUAL:5,ONSITE_TOTAL:12,ONSITE_ACTUAL:3,INST_TOTAL:12,INST_ACTUAL:0,NOT_PO_CNT:0,ETA_PAST_FROM_ROS:8,ETA_PAST_FROM_UPPERBLOCK:0},
        {GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DC",BLOCK:"E1C",PO_TOTAL:4,PO_ACTUAL:4,FAT_TOTAL:4,FAT_ACTUAL:0,IRN_TOTAL:4,IRN_ACTUAL:0,ONSITE_TOTAL:4,ONSITE_ACTUAL:0,INST_TOTAL:4,INST_ACTUAL:0,NOT_PO_CNT:0,ETA_PAST_FROM_ROS:4,ETA_PAST_FROM_UPPERBLOCK:0}
      ],
      SQL2: [
        {GWBS_LV3:"ME01",GWBS_LEVEL:"E1A",TOTAL:73,PLAN:73,ACTUAL:57,REMAIN:16,PLAN_PROG:100,ACTUAL_PROG:78.08},
        {GWBS_LV3:"ME01",GWBS_LEVEL:"E1B",TOTAL:88,PLAN:86,ACTUAL:61,REMAIN:27,PLAN_PROG:97.72,ACTUAL_PROG:69.31},
        {GWBS_LV3:"ME01",GWBS_LEVEL:"E1C",TOTAL:55,PLAN:54,ACTUAL:35,REMAIN:20,PLAN_PROG:98.18,ACTUAL_PROG:63.63},
        {GWBS_LV3:"ME01",GWBS_LEVEL:"E1D",TOTAL:25,PLAN:24,ACTUAL:2,REMAIN:23,PLAN_PROG:96,ACTUAL_PROG:8}
        // {GWBS_LV3:"ME01",GWBS_LEVEL:"E1A",BLOCK:"E1A",TOTAL:12,PLAN:12,ACTUAL:1,REMAIN:11,PLAN_PROG:100,ACTUAL_PROG:8.33},
        // {GWBS_LV3:"ME01",GWBS_LEVEL:"E1A",BLOCK:"E1A1",TOTAL:29,PLAN:29,ACTUAL:27,REMAIN:2,PLAN_PROG:100,ACTUAL_PROG:93.1},
        // {GWBS_LV3:"ME01",GWBS_LEVEL:"E1A",BLOCK:"E1A2",TOTAL:32,PLAN:32,ACTUAL:29,REMAIN:3,PLAN_PROG:100,ACTUAL_PROG:90.62},
        // {GWBS_LV3:"ME01",GWBS_LEVEL:"E1B",BLOCK:"E1B",TOTAL:13,PLAN:12,ACTUAL:1,REMAIN:12,PLAN_PROG:92.3,ACTUAL_PROG:7.69},
        // {GWBS_LV3:"ME01",GWBS_LEVEL:"E1B",BLOCK:"E1B1",TOTAL:25,PLAN:25,ACTUAL:22,REMAIN:3,PLAN_PROG:100,ACTUAL_PROG:88},
        // {GWBS_LV3:"ME01",GWBS_LEVEL:"E1B",BLOCK:"E1B2",TOTAL:27,PLAN:27,ACTUAL:24,REMAIN:3,PLAN_PROG:100,ACTUAL_PROG:88.88},
        // {GWBS_LV3:"ME01",GWBS_LEVEL:"E1C",BLOCK:"E1C",TOTAL:13,PLAN:12,ACTUAL:1,REMAIN:12,PLAN_PROG:92.3,ACTUAL_PROG:7.69},
        // {GWBS_LV3:"ME01",GWBS_LEVEL:"E1C",BLOCK:"E1C1",TOTAL:21,PLAN:21,ACTUAL:17,REMAIN:4,PLAN_PROG:100,ACTUAL_PROG:80.95},
        // {GWBS_LV3:"ME01",GWBS_LEVEL:"E1C",BLOCK:"E1C2",TOTAL:21,PLAN:21,ACTUAL:17,REMAIN:4,PLAN_PROG:100,ACTUAL_PROG:80.95},
        // {GWBS_LV3:"ME01",GWBS_LEVEL:"E1D",BLOCK:"E1D",TOTAL:25,PLAN:24,ACTUAL:2,REMAIN:23,PLAN_PROG:96,ACTUAL_PROG:8},
        // {GWBS_LV3:"ME01",GWBS_LEVEL:"E1B",BLOCK:"E1M1",TOTAL:23,PLAN:22,ACTUAL:14,REMAIN:9,PLAN_PROG:95.65,ACTUAL_PROG:60.86}
      ],
      SQL3:[
        {NO:1,GWBS_LV3:"ME01",BLOCK:"E1A",ITEM_SEQ:1,ITEM:"Pre-Fabrication",PLAN_SD:"2020-03-29",PLAN_FD:"2021-03-17",PLAN_PROG:100,ACTUAL_SD:"2020-03-29",ACTUAL_PROG:99.73,DELTA:-0.26},
        {NO:2,GWBS_LV3:"ME01",BLOCK:"E1A",ITEM_SEQ:2,ITEM:"Block Assembly BP",PLAN_SD:"2020-07-29",PLAN_FD:"2021-01-15",PLAN_PROG:100,ACTUAL_SD:"2020-07-29",ACTUAL_PROG:99.64,DELTA:-0.35},
        {NO:3,GWBS_LV3:"ME01",BLOCK:"E1A",ITEM_SEQ:3,ITEM:"Block Painting",PLAN_SD:"2021-01-16",PLAN_FD:"2021-02-23",PLAN_PROG:75.41,ACTUAL_SD:"2020-11-24",ACTUAL_PROG:66.95,DELTA:-8.45},
        {NO:4,GWBS_LV3:"ME01",BLOCK:"E1A",ITEM_SEQ:4,ITEM:"Block Assembly AP",PLAN_SD:"2021-03-22",PLAN_FD:"2021-04-06"},
        {NO:5,GWBS_LV3:"ME01",BLOCK:"E1A",ITEM_SEQ:5,ITEM:"Module Assembly",PLAN_SD:"2021-02-24",PLAN_FD:"2021-02-24",PLAN_PROG:0,ACTUAL_PROG:0,DELTA:0},
        {NO:6,GWBS_LV3:"ME01",BLOCK:"E1B",ITEM_SEQ:1,ITEM:"Pre-Fabrication",PLAN_SD:"2020-03-29",PLAN_FD:"2021-04-01",PLAN_PROG:100,ACTUAL_SD:"2020-03-29",ACTUAL_FD:"2020-08-19",ACTUAL_PROG:100,DELTA:0},
        {NO:7,GWBS_LV3:"ME01",BLOCK:"E1B",ITEM_SEQ:2,ITEM:"Block Assembly BP",PLAN_SD:"2020-08-21",PLAN_FD:"2021-01-25",PLAN_PROG:100,ACTUAL_SD:"2020-08-21",ACTUAL_PROG:95.66,DELTA:-4.34},
        {NO:8,GWBS_LV3:"ME01",BLOCK:"E1B",ITEM_SEQ:3,ITEM:"Block Painting",PLAN_SD:"2021-01-26",PLAN_FD:"2021-03-04",PLAN_PROG:44.46,ACTUAL_SD:"2020-11-24",ACTUAL_PROG:11.39,DELTA:-33.07},
        {NO:9,GWBS_LV3:"ME01",BLOCK:"E1B",ITEM_SEQ:4,ITEM:"Block Assembly AP",PLAN_SD:"2021-03-05",PLAN_FD:"2021-03-20"},
        {NO:10,GWBS_LV3:"ME01",BLOCK:"E1B",ITEM_SEQ:5,ITEM:"Module Assembly",PLAN_SD:"2021-04-21",PLAN_FD:"2021-04-22",PLAN_PROG:0,ACTUAL_PROG:0,DELTA:0},
        {NO:11,GWBS_LV3:"ME01",BLOCK:"E1C",ITEM_SEQ:1,ITEM:"Pre-Fabrication",PLAN_SD:"2020-03-31",PLAN_FD:"2021-06-28",PLAN_PROG:62.84,ACTUAL_SD:"2020-03-31",ACTUAL_PROG:98.72,DELTA:35.87},
        {NO:12,GWBS_LV3:"ME01",BLOCK:"E1C",ITEM_SEQ:2,ITEM:"Block Assembly BP",PLAN_SD:"2020-09-29",PLAN_FD:"2021-04-13",PLAN_PROG:74.38,ACTUAL_SD:"2020-09-29",ACTUAL_PROG:79.25,DELTA:4.87},
        {NO:13,GWBS_LV3:"ME01",BLOCK:"E1C",ITEM_SEQ:3,ITEM:"Block Painting",PLAN_SD:"2021-04-14",PLAN_FD:"2021-05-07",PLAN_PROG:0,ACTUAL_PROG:0,DELTA:0},
        {NO:14,GWBS_LV3:"ME01",BLOCK:"E1C",ITEM_SEQ:4,ITEM:"Block Assembly AP",PLAN_SD:"2021-05-08",PLAN_FD:"2021-05-24"},
        {NO:15,GWBS_LV3:"ME01",BLOCK:"E1C",ITEM_SEQ:5,ITEM:"Module Assembly",PLAN_SD:"2021-06-10",PLAN_FD:"2021-06-11",PLAN_PROG:0,ACTUAL_PROG:0,DELTA:0},
        {NO:16,GWBS_LV3:"ME01",BLOCK:"E1D",ITEM_SEQ:1,ITEM:"Pre-Fabrication",PLAN_SD:"2021-01-13",PLAN_FD:"2021-06-23",PLAN_PROG:38.18},
        {NO:17,GWBS_LV3:"ME01",BLOCK:"E1D",ITEM_SEQ:2,ITEM:"Block Assembly BP",PLAN_SD:"2021-01-13",PLAN_FD:"2021-04-17",PLAN_PROG:25.91},
        {NO:18,GWBS_LV3:"ME01",BLOCK:"E1D",ITEM_SEQ:3,ITEM:"Block Painting",PLAN_SD:"2021-04-19",PLAN_FD:"2021-07-09",PLAN_PROG:0},
        {NO:19,GWBS_LV3:"ME01",BLOCK:"E1D",ITEM_SEQ:4,ITEM:"Block Assembly AP",PLAN_SD:"2021-04-15",PLAN_FD:"2021-08-10"},
        {NO:20,GWBS_LV3:"ME01",BLOCK:"E1D",ITEM_SEQ:5,ITEM:"Module Assembly",PLAN_SD:"2021-06-30",PLAN_FD:"2021-07-23",PLAN_PROG:0,ACTUAL_PROG:0,DELTA:0}
      ]
    }

}