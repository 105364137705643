import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 700,
      CanvasHeight: 300,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },
    DataItems: [
      {DISC:"B-Business and General",JS_MODIFIED:67,SUBJECT_TO_CHANGE:81,DEVIATION:1},
      {DISC:"D-Mechanical and Static Equipment",JS_MODIFIED:0,SUBJECT_TO_CHANGE:0,DEVIATION:0},
      {DISC:"E-Electrical",JS_MODIFIED:0,SUBJECT_TO_CHANGE:1,DEVIATION:0},
      {DISC:"I-Instrumentation and Metering",JS_MODIFIED:0,SUBJECT_TO_CHANGE:6,DEVIATION:0},
      {DISC:"K-Construction, Transportation, and Logistics",JS_MODIFIED:0,SUBJECT_TO_CHANGE:0,DEVIATION:0},
      {DISC:"L-Piping and General Layout",JS_MODIFIED:0,SUBJECT_TO_CHANGE:0,DEVIATION:0},
      {DISC:"M-Rotating Machinery",JS_MODIFIED:0,SUBJECT_TO_CHANGE:1,DEVIATION:0},
      {DISC:"P-Process",JS_MODIFIED:0,SUBJECT_TO_CHANGE:6,DEVIATION:0},
      {DISC:"Q-Quality",JS_MODIFIED:0,SUBJECT_TO_CHANGE:0,DEVIATION:0},
      {DISC:"R-Regulatory, Environmental, and Socioeconomic",JS_MODIFIED:1,SUBJECT_TO_CHANGE:4,DEVIATION:0},
      {DISC:"T-Telecommunications",JS_MODIFIED:0,SUBJECT_TO_CHANGE:0,DEVIATION:0},
      {DISC:"V-Contracting and Procurement",JS_MODIFIED:0,SUBJECT_TO_CHANGE:0,DEVIATION:0},
      {DISC:"X-Materials, Corrosion, and Flow Assurance",JS_MODIFIED:0,SUBJECT_TO_CHANGE:0,DEVIATION:0},
      {DISC:"Total",JS_MODIFIED:68,SUBJECT_TO_CHANGE:99,DEVIATION:1}
    ],
}