import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1787,
      CanvasHeight: 1050,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1787,
      CanvasChartHeight: 1050,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Note', x: 1350, y: 20, font: 'roboto', class: 'regular', size: 11, color: '#44A9DF', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: 1350, y: 30, font: 'roboto', class: 'regular', size: 11, color: '#757575', align: 'start' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: 32,
          y: 100,
          font: 'roboto',
          style: 'regular',
          size: 10,
          color: '#757575',
          align: 'start'
        },
      ]
    },
    DataItems: [
    {NO:442,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",Lv4:"MD01-2",BLOCK:"010",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:455,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",Lv4:"MD01-2",BLOCK:"020",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:460,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",Lv4:"MD01-2",BLOCK:"021",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:456,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",Lv4:"MD01-2",BLOCK:"030",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:457,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",Lv4:"MD01-2",BLOCK:"031",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:461,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",Lv4:"MD01-2",BLOCK:"038",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:462,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",Lv4:"MD01-2",BLOCK:"039",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:463,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",Lv4:"MD01-2",BLOCK:"040",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:473,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",Lv4:"MD01-2",BLOCK:"081",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:474,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",Lv4:"MD01-2",BLOCK:"082",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:477,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",Lv4:"MD01-2",BLOCK:"083",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:478,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",Lv4:"MD01-2",BLOCK:"084",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:479,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",Lv4:"MD01-2",BLOCK:"085",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:480,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",Lv4:"MD01-2",BLOCK:"086",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:488,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",Lv4:"MD01-2",BLOCK:"089",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:489,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",Lv4:"MD01-2",BLOCK:"090",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:475,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",Lv4:"MD01-2",BLOCK:"091",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:476,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",Lv4:"MD01-2",BLOCK:"092",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:481,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",Lv4:"MD01-2",BLOCK:"093",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:482,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",Lv4:"MD01-2",BLOCK:"094",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:483,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",Lv4:"MD01-2",BLOCK:"095",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:484,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",Lv4:"MD01-2",BLOCK:"096",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:485,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",Lv4:"MD01-2",BLOCK:"139",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y",},
    {NO:486,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"VS04-5",Lv4:"MD01-2",BLOCK:"721",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:487,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"VS04-5",Lv4:"MD01-2",BLOCK:"731",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:490,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",Lv4:"MD01-2",BLOCK:"740",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:491,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",Lv4:"MD01-2",BLOCK:"741",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:492,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",Lv4:"MD01-2",BLOCK:"742",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:493,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",Lv4:"MD01-2",BLOCK:"743",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:494,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",Lv4:"MD01-2",BLOCK:"744",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:495,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",Lv4:"MD01-2",BLOCK:"745",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:496,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",Lv4:"MD01-2",BLOCK:"746",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:497,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",Lv4:"MD01-2",BLOCK:"747",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:498,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",Lv4:"MD01-2",BLOCK:"748",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:499,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",Lv4:"MD01-2",BLOCK:"749",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:500,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",Lv4:"MD01-2",BLOCK:"750",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:501,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",Lv4:"MD01-2",BLOCK:"751",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:502,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",Lv4:"MD01-2",BLOCK:"752",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:503,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",Lv4:"MD01-2",BLOCK:"753",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:504,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",Lv4:"MD01-2",BLOCK:"754",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:505,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",Lv4:"MD01-2",BLOCK:"755",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:506,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",Lv4:"MD01-2",BLOCK:"756",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:507,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",Lv4:"MD01-2",BLOCK:"757",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:508,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",Lv4:"MD01-2",BLOCK:"758",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:509,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",Lv4:"MD01-2",BLOCK:"759",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:510,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",Lv4:"MD01-2",BLOCK:"760",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:511,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",Lv4:"MD01-2",BLOCK:"761",TYPE:"UNIT",STATUS:"PAINTING",EREC:"Y",},
    {NO:512,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",Lv4:"MD01-2",BLOCK:"762",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:513,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",Lv4:"MD01-2",BLOCK:"763",TYPE:"UNIT",STATUS:"PAINTING",EREC:"Y",},
    {NO:514,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",Lv4:"MD01-2",BLOCK:"764",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:515,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",Lv4:"MD01-2",BLOCK:"765",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:516,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",Lv4:"MD01-2",BLOCK:"766",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:517,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",Lv4:"MD01-2",BLOCK:"767",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:518,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",Lv4:"MD01-2",BLOCK:"768",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:519,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",Lv4:"MD01-2",BLOCK:"769",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:520,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",Lv4:"MD01-2",BLOCK:"770",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:521,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",Lv4:"MD01-2",BLOCK:"771",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:522,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",Lv4:"MD01-2",BLOCK:"772",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:523,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",Lv4:"MD01-2",BLOCK:"773",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:524,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",Lv4:"MD01-2",BLOCK:"774",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:525,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",Lv4:"MD01-2",BLOCK:"775",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:526,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",Lv4:"MD01-2",BLOCK:"776",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:527,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",Lv4:"MD01-2",BLOCK:"777",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:528,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",Lv4:"MD01-2",BLOCK:"778",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:529,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",Lv4:"MD01-2",BLOCK:"779",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:530,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"MD00a",Lv4:"MD01-2",BLOCK:"780",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:531,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"MD00a",Lv4:"MD01-2",BLOCK:"781",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:532,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"MD00a",Lv4:"MD01-2",BLOCK:"782",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:533,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"MD00a",Lv4:"MD01-2",BLOCK:"783",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:534,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"MD00a",Lv4:"MD01-2",BLOCK:"784",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:535,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"MD00a",Lv4:"MD01-2",BLOCK:"785",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:536,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"MD00f",Lv4:"MD01-2",BLOCK:"786",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:537,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"MD00f",Lv4:"MD01-2",BLOCK:"787",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:538,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"MD00f",Lv4:"MD01-2",BLOCK:"788",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:539,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"MD00f",Lv4:"MD01-2",BLOCK:"789",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:540,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"MD00f",Lv4:"MD01-2",BLOCK:"790",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",},
    {NO:541,GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",GWBS_LV3:"MD01-2",Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"MD00f",Lv4:"MD01-2",BLOCK:"791",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y",}
    ],
}