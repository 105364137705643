import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1150,
      CanvasHeight: 1200,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Weight : ', x:240, y: 0, font: 'roboto', class: 'regular', size: 11, color: '#BCBCBC', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: 240, y: 0, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'end' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: 240,
          y: 0,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'end'
        },
      ]
    },
    DataItems:  [

      {LEVEL:"DECK_A",LEVEL_DESCR:"Level A",TOTAL:76,COMPLETED:6,REMAIN:66,PROG:17.9},
      {LEVEL:"HULL_LEVEL",LEVEL_DESCR:"Level Hull deck",TOTAL:64,COMPLETED:6,REMAIN:52,PROG:89.4},
      {LEVEL:"LEVEL1",LEVEL_DESCR:"Level 1",TOTAL:64,COMPLETED:10,REMAIN:46,PROG:35.6},
      {LEVEL:"LEVEL2",LEVEL_DESCR:"Level 2",TOTAL:78,COMPLETED:2,REMAIN:70,PROG:32.6},
      {LEVEL:"LEVEL3",LEVEL_DESCR:"Level 3",TOTAL:124,COMPLETED:114,REMAIN:8,PROG:91.9},
      {LEVEL:"LEVEL4",LEVEL_DESCR:"Level 4",TOTAL:124,COMPLETED:114,REMAIN:8,PROG:91.9},
      {LEVEL:"LEVEL5",LEVEL_DESCR:"Level 5",TOTAL:50,COMPLETED:4,REMAIN:42,PROG:60},
      {LEVEL:"LEVEL6",LEVEL_DESCR:"Level 6",TOTAL:6,COMPLETED:2,REMAIN:4,PROG:23.3},
      {LEVEL:"OVERALL",LEVEL_DESCR:"Overall",TOTAL:586,COMPLETED:258,REMAIN:296,PROG:54}
    ],
}