import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 2900,
    CanvasHeight: 1030,
    CanvasBorderWeight: 0.05,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.1,

    CanvasChartX: 50,
    CanvasChartY: 50,
    CanvasChartWidth: 480,
    CanvasChartHeight: 250,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  DataItems: [
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2019-12-27",ITEM_NO:"A.1",STATUS:"Completed "},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2019-12-27",ITEM_NO:"A.24",STATUS:"Completed "},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2019-12-27",ITEM_NO:"A.25",STATUS:"Completed "},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2019-12-27",ITEM_NO:"A.27",STATUS:"Completed "},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2019-12-27",ITEM_NO:"A.37",STATUS:"Completed "},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2019-12-27",ITEM_NO:"A.40",STATUS:"Completed "},
    {TOPIC:"C. GENERAL CONSTRUCTION (Structure & Marine)",ACT_PLAN:"2019-12-27",ITEM_NO:"C.2",STATUS:"Completed "},
    {TOPIC:"C. GENERAL CONSTRUCTION (Structure & Marine)",ACT_PLAN:"2019-12-27",ITEM_NO:"C.6",STATUS:"Completed "},
    {TOPIC:"J. MARINE SYSTEMS & EQUIPMENT",ACT_PLAN:"2019-12-27",ITEM_NO:"J.7",STATUS:"Completed "},
    {TOPIC:"K. AUXILIARY / MACHINERY SYSTEMS & EQUIPMENT",ACT_PLAN:"2019-12-27",ITEM_NO:"K.4",STATUS:"Completed "},
    {TOPIC:"N. MARKINGS & SIGNAGE",ACT_PLAN:"2019-12-27",ITEM_NO:"N.11",STATUS:"Completed "},
    {TOPIC:"N. MARKINGS & SIGNAGE",ACT_PLAN:"2019-12-27",ITEM_NO:"N.6",STATUS:"Completed "},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2020-01-03",ITEM_NO:"A.26",STATUS:"IR"},
    {TOPIC:"B. LOGBOOK ENTRIES, DRILLS, AND RECORDS",ACT_PLAN:"2020-01-03",ITEM_NO:"B.5",STATUS:"IR"},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2020-02-07",ITEM_NO:"A.23",STATUS:"IR"},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2020-02-07",ITEM_NO:"A.31",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE SUPRESSION",ACT_PLAN:"2020-02-07",ITEM_NO:"E.3.20",STATUS:"IR"},
    {TOPIC:"M. ELECTRICAL SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-02-07",ITEM_NO:"M.27",STATUS:"IR"},
    {TOPIC:"O. POLLUTION PREVENTION SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-02-07",ITEM_NO:"O.2",STATUS:"IR"},
    {TOPIC:"P. HAZARDOUS AREAS",ACT_PLAN:"2020-02-07",ITEM_NO:"P.2",STATUS:"IR"},
    {TOPIC:"K. AUXILIARY / MACHINERY SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-02-21",ITEM_NO:"K.9",STATUS:"IR"},
    {TOPIC:"M. ELECTRICAL SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-02-21",ITEM_NO:"M.2",STATUS:"IR"},
    {TOPIC:"M. ELECTRICAL SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-02-21",ITEM_NO:"M.3",STATUS:"IR"},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2020-03-06",ITEM_NO:"A.19",STATUS:"IR"},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2020-03-06",ITEM_NO:"A.29",STATUS:"IR"},
    {TOPIC:"C. GENERAL CONSTRUCTION (Structure & Marine)",ACT_PLAN:"2020-03-06",ITEM_NO:"C.4",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (WINCHES & RELEASE GEAR TESTS)",ACT_PLAN:"2020-03-06",ITEM_NO:"D.3.1",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (WINCHES & RELEASE GEAR TESTS)",ACT_PLAN:"2020-03-06",ITEM_NO:"D.3.2",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (WINCHES & RELEASE GEAR TESTS)",ACT_PLAN:"2020-03-06",ITEM_NO:"D.3.3",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (WINCHES & RELEASE GEAR TESTS)",ACT_PLAN:"2020-03-06",ITEM_NO:"D.3.4",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (WINCHES & RELEASE GEAR TESTS)",ACT_PLAN:"2020-03-06",ITEM_NO:"D.3.5",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (WINCHES & RELEASE GEAR TESTS)",ACT_PLAN:"2020-03-06",ITEM_NO:"D.3.6",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (WINCHES & RELEASE GEAR TESTS)",ACT_PLAN:"2020-03-06",ITEM_NO:"D.3.7",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (WINCHES & RELEASE GEAR TESTS)",ACT_PLAN:"2020-03-06",ITEM_NO:"D.3.8",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE MAIN",ACT_PLAN:"2020-03-06",ITEM_NO:"E.2.7",STATUS:"IR"},
    {TOPIC:"G. WORKPLACE SAFETY & HEALTH",ACT_PLAN:"2020-03-06",ITEM_NO:"G.3",STATUS:"IR"},
    {TOPIC:"H. ACCESS & EGRESS",ACT_PLAN:"2020-03-06",ITEM_NO:"H.2",STATUS:"IR"},
    {TOPIC:"H. ACCESS & EGRESS",ACT_PLAN:"2020-03-06",ITEM_NO:"H.5",STATUS:"IR"},
    {TOPIC:"J. MARINE SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-03-06",ITEM_NO:"J.1",STATUS:"IR"},
    {TOPIC:"J. MARINE SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-03-06",ITEM_NO:"J.13",STATUS:"IR"},
    {TOPIC:"J. MARINE SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-03-06",ITEM_NO:"J.2",STATUS:"IR"},
    {TOPIC:"J. MARINE SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-03-06",ITEM_NO:"J.3",STATUS:"IR"},
    {TOPIC:"K. AUXILIARY / MACHINERY SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-03-06",ITEM_NO:"K.5",STATUS:"IR"},
    {TOPIC:"K. AUXILIARY / MACHINERY SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-03-06",ITEM_NO:"K.6",STATUS:"IR"},
    {TOPIC:"K. AUXILIARY / MACHINERY SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-03-06",ITEM_NO:"K.7",STATUS:"IR"},
    {TOPIC:"M. ELECTRICAL SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-03-06",ITEM_NO:"M.6",STATUS:"IR"},
    {TOPIC:"O. POLLUTION PREVENTION SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-03-06",ITEM_NO:"O.4",STATUS:"IR"},
    {TOPIC:"P. HAZARDOUS AREAS",ACT_PLAN:"2020-03-06",ITEM_NO:"P.1",STATUS:"IR"},
    {TOPIC:"P. HAZARDOUS AREAS",ACT_PLAN:"2020-03-06",ITEM_NO:"P.3",STATUS:"IR"},
    {TOPIC:"Q. HELICOPTER FACILITIES",ACT_PLAN:"2020-03-06",ITEM_NO:"Q.1",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE SUPRESSION",ACT_PLAN:"2020-03-20",ITEM_NO:"E.3.7",STATUS:"IR"},
    {TOPIC:"M. ELECTRICAL SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-03-20",ITEM_NO:"M.13",STATUS:"IR"},
    {TOPIC:"M. ELECTRICAL SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-03-20",ITEM_NO:"M.20",STATUS:"IR"},
    {TOPIC:"J. MARINE SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-03-27",ITEM_NO:"J.10",STATUS:"IR"},
    {TOPIC:"M. ELECTRICAL SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-03-27",ITEM_NO:"M.11",STATUS:"IR"},
    {TOPIC:"M. ELECTRICAL SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-03-27",ITEM_NO:"M.12",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE SUPRESSION",ACT_PLAN:"2020-04-03",ITEM_NO:"E.3.8",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, PFP/SFP",ACT_PLAN:"2020-04-03",ITEM_NO:"E.4.9",STATUS:"IR"},
    {TOPIC:"J. MARINE SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-04-03",ITEM_NO:"J.12",STATUS:"IR"},
    {TOPIC:"J. MARINE SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-04-03",ITEM_NO:"J.14",STATUS:"IR"},
    {TOPIC:"K. AUXILIARY / MACHINERY SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-04-03",ITEM_NO:"K.2",STATUS:"IR"},
    {TOPIC:"M. ELECTRICAL SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-04-03",ITEM_NO:"M.14",STATUS:"IR"},
    {TOPIC:"M. ELECTRICAL SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-04-03",ITEM_NO:"M.21",STATUS:"IR"},
    {TOPIC:"M. ELECTRICAL SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-04-03",ITEM_NO:"M.23",STATUS:"IR"},
    {TOPIC:"M. ELECTRICAL SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-04-03",ITEM_NO:"M.24",STATUS:"IR"},
    {TOPIC:"O. POLLUTION PREVENTION SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-04-03",ITEM_NO:"O.1",STATUS:"IR"},
    {TOPIC:"Q. HELICOPTER FACILITIES",ACT_PLAN:"2020-04-03",ITEM_NO:"Q.2",STATUS:"IR"},
    {TOPIC:"F. NAVIGATION EQUIPMENT",ACT_PLAN:"2020-04-10",ITEM_NO:"F.3",STATUS:"IR"},
    {TOPIC:"J. MARINE SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-04-10",ITEM_NO:"J.4",STATUS:"IR"},
    {TOPIC:"J. MARINE SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-04-10",ITEM_NO:"J.5",STATUS:"IR"},
    {TOPIC:"M. ELECTRICAL SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-04-10",ITEM_NO:"M.1",STATUS:"IR"},
    {TOPIC:"M. ELECTRICAL SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-04-10",ITEM_NO:"M.9",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE SUPRESSION",ACT_PLAN:"2020-04-24",ITEM_NO:"E.3.9",STATUS:"IR"},
    {TOPIC:"F. NAVIGATION EQUIPMENT",ACT_PLAN:"2020-04-24",ITEM_NO:"F.1",STATUS:"IR"},
    {TOPIC:"M. ELECTRICAL SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-04-24",ITEM_NO:"M.10",STATUS:"IR"},
    {TOPIC:"M. ELECTRICAL SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-04-24",ITEM_NO:"M.15",STATUS:"IR"},
    {TOPIC:"M. ELECTRICAL SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-04-24",ITEM_NO:"M.22",STATUS:"IR"},
    {TOPIC:"M. ELECTRICAL SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-04-24",ITEM_NO:"M.7",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE MAIN",ACT_PLAN:"2020-05-01",ITEM_NO:"E.2.1",STATUS:"IR"},
    {TOPIC:"I. ACCOMMODATIONS",ACT_PLAN:"2020-05-01",ITEM_NO:"I.1",STATUS:"IR"},
    {TOPIC:"J. MARINE SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-05-01",ITEM_NO:"J.8",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (Capacity & Equipment)",ACT_PLAN:"2020-05-08",ITEM_NO:"D.1.11",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (WINCHES & RELEASE GEAR TESTS)",ACT_PLAN:"2020-05-08",ITEM_NO:"D.3.10",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (WINCHES & RELEASE GEAR TESTS)",ACT_PLAN:"2020-05-08",ITEM_NO:"D.3.9",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE SUPRESSION",ACT_PLAN:"2020-05-15",ITEM_NO:"E.3.10",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE SUPRESSION",ACT_PLAN:"2020-05-15",ITEM_NO:"E.3.11",STATUS:"IR"},
    {TOPIC:"M. ELECTRICAL SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-05-22",ITEM_NO:"M.4",STATUS:"IR"},
    {TOPIC:"M. ELECTRICAL SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-05-22",ITEM_NO:"M.5",STATUS:"IR"},
    {TOPIC:"C. GENERAL CONSTRUCTION (Structure & Marine)",ACT_PLAN:"2020-06-05",ITEM_NO:"C.8",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE DETECTION ",ACT_PLAN:"2020-06-05",ITEM_NO:"E.1.7",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE MAIN",ACT_PLAN:"2020-06-05",ITEM_NO:"E.2.3",STATUS:"IR"},
    {TOPIC:"K. AUXILIARY / MACHINERY SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-06-05",ITEM_NO:"K.8",STATUS:"IR"},
    {TOPIC:"HVAC",ACT_PLAN:"2020-06-05",ITEM_NO:"L.3",STATUS:"IR"},
    {TOPIC:"M. ELECTRICAL SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-06-05",ITEM_NO:"M.28",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE SUPRESSION",ACT_PLAN:"2020-06-19",ITEM_NO:"E.3.12",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE SUPRESSION",ACT_PLAN:"2020-06-19",ITEM_NO:"E.3.16",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE SUPRESSION",ACT_PLAN:"2020-06-19",ITEM_NO:"E.3.3",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE SUPRESSION",ACT_PLAN:"2020-06-19",ITEM_NO:"E.3.4",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE SUPRESSION",ACT_PLAN:"2020-06-19",ITEM_NO:"E.3.5",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE SUPRESSION",ACT_PLAN:"2020-06-19",ITEM_NO:"E.3.6",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, PFP/SFP",ACT_PLAN:"2020-06-19",ITEM_NO:"E.4.5",STATUS:"IR"},
    {TOPIC:"J. MARINE SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-06-19",ITEM_NO:"J.11",STATUS:"IR"},
    {TOPIC:"J. MARINE SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-06-19",ITEM_NO:"J.8.1",STATUS:"IR"},
    {TOPIC:"J. MARINE SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-06-19",ITEM_NO:"J.9",STATUS:"IR"},
    {TOPIC:"O. POLLUTION PREVENTION SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-06-19",ITEM_NO:"O.3",STATUS:"IR"},
    {TOPIC:"O. POLLUTION PREVENTION SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-06-19",ITEM_NO:"O.5",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE SUPRESSION",ACT_PLAN:"2020-06-26",ITEM_NO:"E.3.15",STATUS:"IR"},
    {TOPIC:"F. NAVIGATION EQUIPMENT",ACT_PLAN:"2020-06-26",ITEM_NO:"F.2",STATUS:"IR"},
    {TOPIC:"HVAC",ACT_PLAN:"2020-06-26",ITEM_NO:"L.2",STATUS:"IR"},
    {TOPIC:"Q. HELICOPTER FACILITIES",ACT_PLAN:"2020-06-26",ITEM_NO:"Q.3",STATUS:"IR"},
    {TOPIC:"Q. HELICOPTER FACILITIES",ACT_PLAN:"2020-06-26",ITEM_NO:"Q.4",STATUS:"IR"},
    {TOPIC:"Q. HELICOPTER FACILITIES",ACT_PLAN:"2020-06-26",ITEM_NO:"Q.5",STATUS:"IR"},
    {TOPIC:"Q. HELICOPTER FACILITIES",ACT_PLAN:"2020-06-26",ITEM_NO:"Q.7",STATUS:"IR"},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2020-07-03",ITEM_NO:"A.30",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (Capacity & Equipment)",ACT_PLAN:"2020-07-03",ITEM_NO:"D.1.1.1",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (Capacity & Equipment)",ACT_PLAN:"2020-07-03",ITEM_NO:"D.1.2.1",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE DETECTION ",ACT_PLAN:"2020-07-03",ITEM_NO:"E.1.2",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE DETECTION ",ACT_PLAN:"2020-07-03",ITEM_NO:"E.1.4",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE DETECTION ",ACT_PLAN:"2020-07-03",ITEM_NO:"E.1.6",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE MAIN",ACT_PLAN:"2020-07-03",ITEM_NO:"E.2.4",STATUS:"IR"},
    {TOPIC:"G. WORKPLACE SAFETY & HEALTH",ACT_PLAN:"2020-07-03",ITEM_NO:"G.1",STATUS:"IR"},
    {TOPIC:"H. ACCESS & EGRESS",ACT_PLAN:"2020-07-03",ITEM_NO:"H.1",STATUS:"IR"},
    {TOPIC:"H. ACCESS & EGRESS",ACT_PLAN:"2020-07-03",ITEM_NO:"H.6",STATUS:"IR"},
    {TOPIC:"J. MARINE SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-07-03",ITEM_NO:"J.16",STATUS:"IR"},
    {TOPIC:"K. AUXILIARY / MACHINERY SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-07-03",ITEM_NO:"K.3",STATUS:"IR"},
    {TOPIC:"HVAC",ACT_PLAN:"2020-07-03",ITEM_NO:"L.1",STATUS:"IR"},
    {TOPIC:"HVAC",ACT_PLAN:"2020-07-03",ITEM_NO:"L.5",STATUS:"IR"},
    {TOPIC:"M. ELECTRICAL SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-07-03",ITEM_NO:"M.16",STATUS:"IR"},
    {TOPIC:"M. ELECTRICAL SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-07-03",ITEM_NO:"M.17",STATUS:"IR"},
    {TOPIC:"M. ELECTRICAL SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-07-03",ITEM_NO:"M.18",STATUS:"IR"},
    {TOPIC:"M. ELECTRICAL SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-07-03",ITEM_NO:"M.19",STATUS:"IR"},
    {TOPIC:"M. ELECTRICAL SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-07-03",ITEM_NO:"M.25",STATUS:"IR"},
    {TOPIC:"M. ELECTRICAL SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-07-03",ITEM_NO:"M.26",STATUS:"IR"},
    {TOPIC:"M. ELECTRICAL SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-07-03",ITEM_NO:"M.8",STATUS:"IR"},
    {TOPIC:"Q. HELICOPTER FACILITIES",ACT_PLAN:"2020-07-03",ITEM_NO:"Q.6",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE MAIN",ACT_PLAN:"2020-07-10",ITEM_NO:"E.2.8",STATUS:"IR"},
    {TOPIC:"H. ACCESS & EGRESS",ACT_PLAN:"2020-07-10",ITEM_NO:"H.3",STATUS:"IR"},
    {TOPIC:"P. HAZARDOUS AREAS",ACT_PLAN:"2020-07-10",ITEM_NO:"P.4",STATUS:"IR"},
    {TOPIC:"B. LOGBOOK ENTRIES, DRILLS, AND RECORDS",ACT_PLAN:"2020-07-17",ITEM_NO:"B.6",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE MAIN",ACT_PLAN:"2020-07-17",ITEM_NO:"E.2.5",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE MAIN",ACT_PLAN:"2020-07-17",ITEM_NO:"E.2.6",STATUS:"IR"},
    {TOPIC:"I. ACCOMMODATIONS",ACT_PLAN:"2020-07-17",ITEM_NO:"I.7",STATUS:"IR"},
    {TOPIC:"I. ACCOMMODATIONS",ACT_PLAN:"2020-07-17",ITEM_NO:"I.8",STATUS:"IR"},
    {TOPIC:"I. ACCOMMODATIONS",ACT_PLAN:"2020-07-17",ITEM_NO:"I.9",STATUS:"IR"},
    {TOPIC:"J. MARINE SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-07-17",ITEM_NO:"J.15",STATUS:"IR"},
    {TOPIC:"J. MARINE SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-07-17",ITEM_NO:"J.6",STATUS:"IR"},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2020-08-07",ITEM_NO:"A.17",STATUS:"IR"},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2020-08-07",ITEM_NO:"A.18",STATUS:"Completed "},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2020-08-07",ITEM_NO:"A.20",STATUS:"IR"},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2020-08-07",ITEM_NO:"A.4",STATUS:"IR"},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2020-08-07",ITEM_NO:"A.7",STATUS:"IR"},
    {TOPIC:"C. GENERAL CONSTRUCTION (Structure & Marine)",ACT_PLAN:"2020-08-07",ITEM_NO:"C.5",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, PFP/SFP",ACT_PLAN:"2020-08-07",ITEM_NO:"E.4.6",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, PFP/SFP",ACT_PLAN:"2020-08-07",ITEM_NO:"E.4.7",STATUS:"IR"},
    {TOPIC:"K. AUXILIARY / MACHINERY SYSTEMS & EQUIPMENT",ACT_PLAN:"2020-08-07",ITEM_NO:"K.5.1",STATUS:"IR"},
    {TOPIC:"C. GENERAL CONSTRUCTION (Structure & Marine)",ACT_PLAN:"2020-08-21",ITEM_NO:"C.1",STATUS:"IR"},
    {TOPIC:"C. GENERAL CONSTRUCTION (Structure & Marine)",ACT_PLAN:"2020-08-21",ITEM_NO:"C.7",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE MAIN",ACT_PLAN:"2020-08-21",ITEM_NO:"E.2.2",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE SUPRESSION",ACT_PLAN:"2020-08-21",ITEM_NO:"E.3.18.1",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, PFP/SFP",ACT_PLAN:"2020-08-21",ITEM_NO:"E.4.1",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, PFP/SFP",ACT_PLAN:"2020-08-21",ITEM_NO:"E.4.3",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, PFP/SFP",ACT_PLAN:"2020-08-21",ITEM_NO:"E.4.4",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, PFP/SFP",ACT_PLAN:"2020-08-21",ITEM_NO:"E.4.8",STATUS:"IR"},
    {TOPIC:"N. MARKINGS & SIGNAGE",ACT_PLAN:"2020-08-21",ITEM_NO:"N.20",STATUS:"IR"},
    {TOPIC:"N. MARKINGS & SIGNAGE",ACT_PLAN:"2020-08-21",ITEM_NO:"N.21",STATUS:"IR"},
    {TOPIC:"C. GENERAL CONSTRUCTION (Structure & Marine)",ACT_PLAN:"2020-09-04",ITEM_NO:"C.3",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (Capacity & Equipment)",ACT_PLAN:"2020-09-04",ITEM_NO:"D.1.13.1",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (Capacity & Equipment)",ACT_PLAN:"2020-09-04",ITEM_NO:"D.1.5.1",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (Capacity & Equipment)",ACT_PLAN:"2020-09-04",ITEM_NO:"D.1.6.1",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (Capacity & Equipment)",ACT_PLAN:"2020-09-04",ITEM_NO:"D.1.7.1",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE DETECTION ",ACT_PLAN:"2020-09-04",ITEM_NO:"E.1.1",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE DETECTION ",ACT_PLAN:"2020-09-04",ITEM_NO:"E.1.3",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE DETECTION ",ACT_PLAN:"2020-09-04",ITEM_NO:"E.1.5",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE SUPRESSION",ACT_PLAN:"2020-09-04",ITEM_NO:"E.3.17",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE SUPRESSION",ACT_PLAN:"2020-09-04",ITEM_NO:"E.3.19.1",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, PFP/SFP",ACT_PLAN:"2020-09-04",ITEM_NO:"E.4.2",STATUS:"IR"},
    {TOPIC:"H. ACCESS & EGRESS",ACT_PLAN:"2020-09-04",ITEM_NO:"H.7.1",STATUS:"IR"},
    {TOPIC:"I. ACCOMMODATIONS",ACT_PLAN:"2020-09-04",ITEM_NO:"I.2",STATUS:"IR"},
    {TOPIC:"I. ACCOMMODATIONS",ACT_PLAN:"2020-09-04",ITEM_NO:"I.3",STATUS:"IR"},
    {TOPIC:"I. ACCOMMODATIONS",ACT_PLAN:"2020-09-04",ITEM_NO:"I.5",STATUS:"IR"},
    {TOPIC:"N. MARKINGS & SIGNAGE",ACT_PLAN:"2020-09-04",ITEM_NO:"N.1",STATUS:"IR"},
    {TOPIC:"N. MARKINGS & SIGNAGE",ACT_PLAN:"2020-09-04",ITEM_NO:"N.10",STATUS:"IR"},
    {TOPIC:"N. MARKINGS & SIGNAGE",ACT_PLAN:"2020-09-04",ITEM_NO:"N.12",STATUS:"IR"},
    {TOPIC:"N. MARKINGS & SIGNAGE",ACT_PLAN:"2020-09-04",ITEM_NO:"N.13",STATUS:"IR"},
    {TOPIC:"N. MARKINGS & SIGNAGE",ACT_PLAN:"2020-09-04",ITEM_NO:"N.14",STATUS:"IR"},
    {TOPIC:"N. MARKINGS & SIGNAGE",ACT_PLAN:"2020-09-04",ITEM_NO:"N.15",STATUS:"IR"},
    {TOPIC:"N. MARKINGS & SIGNAGE",ACT_PLAN:"2020-09-04",ITEM_NO:"N.16",STATUS:"IR"},
    {TOPIC:"N. MARKINGS & SIGNAGE",ACT_PLAN:"2020-09-04",ITEM_NO:"N.17",STATUS:"IR"},
    {TOPIC:"N. MARKINGS & SIGNAGE",ACT_PLAN:"2020-09-04",ITEM_NO:"N.18",STATUS:"IR"},
    {TOPIC:"N. MARKINGS & SIGNAGE",ACT_PLAN:"2020-09-04",ITEM_NO:"N.19",STATUS:"IR"},
    {TOPIC:"N. MARKINGS & SIGNAGE",ACT_PLAN:"2020-09-04",ITEM_NO:"N.2",STATUS:"IR"},
    {TOPIC:"N. MARKINGS & SIGNAGE",ACT_PLAN:"2020-09-04",ITEM_NO:"N.3",STATUS:"IR"},
    {TOPIC:"N. MARKINGS & SIGNAGE",ACT_PLAN:"2020-09-04",ITEM_NO:"N.4",STATUS:"IR"},
    {TOPIC:"N. MARKINGS & SIGNAGE",ACT_PLAN:"2020-09-04",ITEM_NO:"N.5",STATUS:"IR"},
    {TOPIC:"N. MARKINGS & SIGNAGE",ACT_PLAN:"2020-09-04",ITEM_NO:"N.7",STATUS:"IR"},
    {TOPIC:"N. MARKINGS & SIGNAGE",ACT_PLAN:"2020-09-04",ITEM_NO:"N.8",STATUS:"IR"},
    {TOPIC:"N. MARKINGS & SIGNAGE",ACT_PLAN:"2020-09-04",ITEM_NO:"N.9",STATUS:"IR"},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2021-01-01",ITEM_NO:"A.10",STATUS:"IR"},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2021-01-01",ITEM_NO:"A.11",STATUS:"IR"},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2021-01-01",ITEM_NO:"A.12",STATUS:"IR"},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2021-01-01",ITEM_NO:"A.13",STATUS:"IR"},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2021-01-01",ITEM_NO:"A.16",STATUS:"IR"},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2021-01-01",ITEM_NO:"A.22",STATUS:"IR"},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2021-01-01",ITEM_NO:"A.28",STATUS:"IR"},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2021-01-01",ITEM_NO:"A.32",STATUS:"IR"},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2021-01-01",ITEM_NO:"A.33",STATUS:"IR"},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2021-01-01",ITEM_NO:"A.34",STATUS:"IR"},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2021-01-01",ITEM_NO:"A.35",STATUS:"IR"},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2021-01-01",ITEM_NO:"A.36",STATUS:"IR"},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2021-01-01",ITEM_NO:"A.38",STATUS:"IR"},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2021-01-01",ITEM_NO:"A.39",STATUS:"IR"},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2021-01-01",ITEM_NO:"A.41",STATUS:"IR"},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2021-01-01",ITEM_NO:"A.6",STATUS:"IR"},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2021-01-01",ITEM_NO:"A.8",STATUS:"IR"},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2021-01-01",ITEM_NO:"A.9",STATUS:"IR"},
    {TOPIC:"B. LOGBOOK ENTRIES, DRILLS, AND RECORDS",ACT_PLAN:"2021-01-01",ITEM_NO:"B.1",STATUS:"IR"},
    {TOPIC:"B. LOGBOOK ENTRIES, DRILLS, AND RECORDS",ACT_PLAN:"2021-01-01",ITEM_NO:"B.2",STATUS:"IR"},
    {TOPIC:"B. LOGBOOK ENTRIES, DRILLS, AND RECORDS",ACT_PLAN:"2021-01-01",ITEM_NO:"B.3",STATUS:"IR"},
    {TOPIC:"B. LOGBOOK ENTRIES, DRILLS, AND RECORDS",ACT_PLAN:"2021-01-01",ITEM_NO:"B.4",STATUS:"IR"},
    {TOPIC:"B. LOGBOOK ENTRIES, DRILLS, AND RECORDS",ACT_PLAN:"2021-01-01",ITEM_NO:"B.7",STATUS:"IR"},
    {TOPIC:"B. LOGBOOK ENTRIES, DRILLS, AND RECORDS",ACT_PLAN:"2021-01-01",ITEM_NO:"B.8",STATUS:"IR"},
    {TOPIC:"B. LOGBOOK ENTRIES, DRILLS, AND RECORDS",ACT_PLAN:"2021-01-01",ITEM_NO:"B.9",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (Capacity & Equipment)",ACT_PLAN:"2021-01-08",ITEM_NO:"D.1.1",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (Capacity & Equipment)",ACT_PLAN:"2021-01-08",ITEM_NO:"D.1.10",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (Capacity & Equipment)",ACT_PLAN:"2021-01-08",ITEM_NO:"D.1.12",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (Capacity & Equipment)",ACT_PLAN:"2021-01-08",ITEM_NO:"D.1.13",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (Capacity & Equipment)",ACT_PLAN:"2021-01-08",ITEM_NO:"D.1.2",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (Capacity & Equipment)",ACT_PLAN:"2021-01-08",ITEM_NO:"D.1.3",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (Capacity & Equipment)",ACT_PLAN:"2021-01-08",ITEM_NO:"D.1.4",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (Capacity & Equipment)",ACT_PLAN:"2021-01-08",ITEM_NO:"D.1.5",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (Capacity & Equipment)",ACT_PLAN:"2021-01-08",ITEM_NO:"D.1.6",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (Capacity & Equipment)",ACT_PLAN:"2021-01-08",ITEM_NO:"D.1.7",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (Capacity & Equipment)",ACT_PLAN:"2021-01-08",ITEM_NO:"D.1.8",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (Capacity & Equipment)",ACT_PLAN:"2021-01-08",ITEM_NO:"D.1.9",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (Survival Craft Testing)",ACT_PLAN:"2021-01-08",ITEM_NO:"D.2.1",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (Survival Craft Testing)",ACT_PLAN:"2021-01-08",ITEM_NO:"D.2.10",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (Survival Craft Testing)",ACT_PLAN:"2021-01-08",ITEM_NO:"D.2.11",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (Survival Craft Testing)",ACT_PLAN:"2021-01-08",ITEM_NO:"D.2.12",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (Survival Craft Testing)",ACT_PLAN:"2021-01-08",ITEM_NO:"D.2.2",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (Survival Craft Testing)",ACT_PLAN:"2021-01-08",ITEM_NO:"D.2.3",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (Survival Craft Testing)",ACT_PLAN:"2021-01-08",ITEM_NO:"D.2.4",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (Survival Craft Testing)",ACT_PLAN:"2021-01-08",ITEM_NO:"D.2.5",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (Survival Craft Testing)",ACT_PLAN:"2021-01-08",ITEM_NO:"D.2.6",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (Survival Craft Testing)",ACT_PLAN:"2021-01-08",ITEM_NO:"D.2.7",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (Survival Craft Testing)",ACT_PLAN:"2021-01-08",ITEM_NO:"D.2.8",STATUS:"IR"},
    {TOPIC:"D. LIFESAVING EQUIPMENT (Survival Craft Testing)",ACT_PLAN:"2021-01-08",ITEM_NO:"D.2.9",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE MAIN",ACT_PLAN:"2021-01-08",ITEM_NO:"E.2.1.1",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE SUPRESSION",ACT_PLAN:"2021-01-08",ITEM_NO:"E.3.1",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE SUPRESSION",ACT_PLAN:"2021-01-08",ITEM_NO:"E.3.18",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE SUPRESSION",ACT_PLAN:"2021-01-08",ITEM_NO:"E.3.19",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE SUPRESSION",ACT_PLAN:"2021-01-08",ITEM_NO:"E.3.21",STATUS:"IR"},
    {TOPIC:"G. WORKPLACE SAFETY & HEALTH",ACT_PLAN:"2021-01-08",ITEM_NO:"G.2",STATUS:"IR"},
    {TOPIC:"G. WORKPLACE SAFETY & HEALTH",ACT_PLAN:"2021-01-08",ITEM_NO:"G.4",STATUS:"IR"},
    {TOPIC:"G. WORKPLACE SAFETY & HEALTH",ACT_PLAN:"2021-01-08",ITEM_NO:"G.5",STATUS:"IR"},
    {TOPIC:"G. WORKPLACE SAFETY & HEALTH",ACT_PLAN:"2021-01-08",ITEM_NO:"G.6",STATUS:"IR"},
    {TOPIC:"G. WORKPLACE SAFETY & HEALTH",ACT_PLAN:"2021-01-08",ITEM_NO:"G.7",STATUS:"IR"},
    {TOPIC:"G. WORKPLACE SAFETY & HEALTH",ACT_PLAN:"2021-01-08",ITEM_NO:"G.8",STATUS:"IR"},
    {TOPIC:"H. ACCESS & EGRESS",ACT_PLAN:"2021-01-08",ITEM_NO:"H.4",STATUS:"IR"},
    {TOPIC:"H. ACCESS & EGRESS",ACT_PLAN:"2021-01-08",ITEM_NO:"H.7",STATUS:"IR"},
    {TOPIC:"I. ACCOMMODATIONS",ACT_PLAN:"2021-01-08",ITEM_NO:"I.4",STATUS:"IR"},
    {TOPIC:"I. ACCOMMODATIONS",ACT_PLAN:"2021-01-08",ITEM_NO:"I.6",STATUS:"IR"},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2021-03-05",ITEM_NO:"A.14",STATUS:"IR"},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2021-03-05",ITEM_NO:"A.15",STATUS:"IR"},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2021-03-05",ITEM_NO:"A.2",STATUS:"IR"},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2021-03-05",ITEM_NO:"A.21",STATUS:"IR"},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2021-03-05",ITEM_NO:"A.3",STATUS:"IR"},
    {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2021-03-05",ITEM_NO:"A.5",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE SUPRESSION",ACT_PLAN:"2021-03-05",ITEM_NO:"E.3.13",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE SUPRESSION",ACT_PLAN:"2021-03-05",ITEM_NO:"E.3.14",STATUS:"IR"},
    {TOPIC:"E. FIRE PROTECTION SYSTEMS & EQUIPMENT, FIRE SUPRESSION",ACT_PLAN:"2021-03-05",ITEM_NO:"E.3.2",STATUS:"IR"}
  ],
  Queries: {
    SQL1: [
      { DESC: 'DataSource SPCS Data 30-Mar-20' }
    ],
    SQL2: [
      { CDATE: '2020-03-02' }
    ],
    SQL3: [
      {ACT_PLAN:"2019-12-27",A_CUM:12,A_C:12},
      {ACT_PLAN:"2020-01-03",A_CUM:14,A_C:0},
      {ACT_PLAN:"2020-01-10",A_CUM:14,A_C:0},
      {ACT_PLAN:"2020-01-17",A_CUM:14,A_C:0},
      {ACT_PLAN:"2020-01-24",A_CUM:14,A_C:0},
      {ACT_PLAN:"2020-01-31",A_CUM:14,A_C:0},
      {ACT_PLAN:"2020-02-07",A_CUM:20,A_C:0},
      {ACT_PLAN:"2020-02-14",A_CUM:20,A_C:0},
      {ACT_PLAN:"2020-02-21",A_CUM:23,A_C:0},
      {ACT_PLAN:"2020-02-28",A_CUM:23,A_C:0},
      {ACT_PLAN:"2020-03-06",A_CUM:50,A_C:0},
      {ACT_PLAN:"2020-03-13",A_CUM:50,A_C:0},
      {ACT_PLAN:"2020-03-20",A_CUM:53,A_C:0},
      {ACT_PLAN:"2020-03-27",A_CUM:56,A_C:0},
      {ACT_PLAN:"2020-04-03",A_CUM:67,A_C:0},
      {ACT_PLAN:"2020-04-10",A_CUM:72,A_C:0},
      {ACT_PLAN:"2020-04-17",A_CUM:72,A_C:0},
      {ACT_PLAN:"2020-04-24",A_CUM:78,A_C:0},
      {ACT_PLAN:"2020-05-01",A_CUM:81,A_C:0},
      {ACT_PLAN:"2020-05-08",A_CUM:84,A_C:0},
      {ACT_PLAN:"2020-05-15",A_CUM:86,A_C:0},
      {ACT_PLAN:"2020-05-22",A_CUM:88,A_C:0},
      {ACT_PLAN:"2020-05-29",A_CUM:88,A_C:0},
      {ACT_PLAN:"2020-06-05",A_CUM:94,A_C:0},
      {ACT_PLAN:"2020-06-12",A_CUM:94,A_C:0},
      {ACT_PLAN:"2020-06-19",A_CUM:106,A_C:0},
      {ACT_PLAN:"2020-06-26",A_CUM:113,A_C:0},
      {ACT_PLAN:"2020-07-03",A_CUM:135,A_C:0},
      {ACT_PLAN:"2020-07-10",A_CUM:138,A_C:0},
      {ACT_PLAN:"2020-07-17",A_CUM:146,A_C:0},
      {ACT_PLAN:"2020-07-24",A_CUM:146,A_C:0},
      {ACT_PLAN:"2020-07-31",A_CUM:146,A_C:0},
      {ACT_PLAN:"2020-08-07",A_CUM:155,A_C:1},
      {ACT_PLAN:"2020-08-14",A_CUM:155,A_C:0},
      {ACT_PLAN:"2020-08-21",A_CUM:165,A_C:0},
      {ACT_PLAN:"2020-08-28",A_CUM:165,A_C:0},
      {ACT_PLAN:"2020-09-04",A_CUM:197,A_C:0},
      {ACT_PLAN:"2020-09-11",A_CUM:197,A_C:0},
      {ACT_PLAN:"2020-09-18",A_CUM:197,A_C:0},
      {ACT_PLAN:"2020-09-25",A_CUM:197,A_C:0},
      {ACT_PLAN:"2020-10-02",A_CUM:197,A_C:0},
      {ACT_PLAN:"2020-10-09",A_CUM:197,A_C:0},
      {ACT_PLAN:"2020-10-16",A_CUM:197,A_C:0},
      {ACT_PLAN:"2020-10-23",A_CUM:197,A_C:0},
      {ACT_PLAN:"2020-10-30",A_CUM:197,A_C:0},
      {ACT_PLAN:"2020-11-06",A_CUM:197,A_C:0},
      {ACT_PLAN:"2020-11-13",A_CUM:197,A_C:0},
      {ACT_PLAN:"2020-11-20",A_CUM:197,A_C:0},
      {ACT_PLAN:"2020-11-27",A_CUM:197,A_C:0},
      {ACT_PLAN:"2020-12-04",A_CUM:197,A_C:0},
      {ACT_PLAN:"2020-12-11",A_CUM:197,A_C:0},
      {ACT_PLAN:"2020-12-18",A_CUM:197,A_C:0},
      {ACT_PLAN:"2020-12-25",A_CUM:197,A_C:0},
      {ACT_PLAN:"2021-01-01",A_CUM:222,A_C:0},
      {ACT_PLAN:"2021-01-08",A_CUM:261,A_C:0},
      {ACT_PLAN:"2021-01-15",A_CUM:261,A_C:0},
      {ACT_PLAN:"2021-01-22",A_CUM:261,A_C:0},
      {ACT_PLAN:"2021-01-29",A_CUM:261,A_C:0},
      {ACT_PLAN:"2021-02-05",A_CUM:261,A_C:0},
      {ACT_PLAN:"2021-02-12",A_CUM:261,A_C:0},
      {ACT_PLAN:"2021-02-19",A_CUM:261,A_C:0},
      {ACT_PLAN:"2021-02-26",A_CUM:261,A_C:0},
      {ACT_PLAN:"2021-03-05",A_CUM:270,A_C:0}
    ]
  }
}