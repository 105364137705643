import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 560,
      CanvasHeight: 330,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     

    DataItems: [
      { CDATE: '2019-12-20', ACTIVE: 11810, RAISED: 1395, CLOSED: 2901, },
      { CDATE: '2019-12-27', ACTIVE: 12408, RAISED: 1951, CLOSED: 1353, },
      { CDATE: '2020-01-03', ACTIVE: 11987, RAISED: 474, CLOSED: 895, },
      { CDATE: '2020-01-10', ACTIVE: 11417, RAISED: 804, CLOSED: 1374, },
      { CDATE: '2020-01-17', ACTIVE: 11057, RAISED: 1049, CLOSED: 1409, },
      { CDATE: '2020-01-24', ACTIVE: 11028, RAISED: 1107, CLOSED: 1136, },
      { CDATE: '2020-01-31', ACTIVE: 10699, RAISED: 580, CLOSED: 909, },
      { CDATE: '2020-02-07', ACTIVE: 10905, RAISED: 1393, CLOSED: 1187, },
      { CDATE: '2020-02-14', ACTIVE: 10828, RAISED: 1337, CLOSED: 1414, },
      { CDATE: '2020-02-21', ACTIVE: 9691, RAISED: 1320, CLOSED: 2457, },
      { CDATE: '2020-02-28', ACTIVE: 9706, RAISED: 1256, CLOSED: 1241, },
      { CDATE: '2020-03-06', ACTIVE: 9804, RAISED: 140, CLOSED: 42, },
    ],


    Queries: {
      SQL1: [
              { TYPE: 'A', LINK1: 'PI', DISC: 'Piping', TOTAL: 35215, ACTUAL: 21704, REMAIN: 13511, PROG: 61.63, },
      ],       
      
      
      
    },
}