import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1120,
      CanvasHeight: 1120,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1787,
      CanvasChartHeight: 1050,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Note', x: 1120, y: -20, font: 'roboto', class: 'regular', size: 11, color: '#44A9DF', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: 1120, y: -30, font: 'roboto', class: 'regular', size: 11, color: '#757575', align: 'start' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: 1120,
          y: -50,
          font: 'roboto',
          style: 'regular',
          size: 10,
          color: '#757575',
          align: 'start'
        },
      ]
    },
    Queries: {
      SQL1:[
        {BID_SECTION_PACK:"S1",QUALITY_STATION_AREA:"Area1",JIANLI_SUPERVISION_WORK_PACKAGE:"WP1",AWARD_SUBCONTRACTOR_CODE:"STCC"},
        {BID_SECTION_PACK:"S10",QUALITY_STATION_AREA:"Area4",JIANLI_SUPERVISION_WORK_PACKAGE:"WP6",AWARD_SUBCONTRACTOR_CODE:""},
        {BID_SECTION_PACK:"S11",QUALITY_STATION_AREA:"Area4",JIANLI_SUPERVISION_WORK_PACKAGE:"WP6",AWARD_SUBCONTRACTOR_CODE:"HZDJ"},
        {BID_SECTION_PACK:"S12",QUALITY_STATION_AREA:"Area1",JIANLI_SUPERVISION_WORK_PACKAGE:"WP5",AWARD_SUBCONTRACTOR_CODE:"SFCC"},
        {BID_SECTION_PACK:"S13",QUALITY_STATION_AREA:"Area1",JIANLI_SUPERVISION_WORK_PACKAGE:"WP8",AWARD_SUBCONTRACTOR_CODE:"SFCC"},
        {BID_SECTION_PACK:"S14",QUALITY_STATION_AREA:"Area1",JIANLI_SUPERVISION_WORK_PACKAGE:"WP8",AWARD_SUBCONTRACTOR_CODE:"CC7"},
        {BID_SECTION_PACK:"S15",QUALITY_STATION_AREA:"Area1",JIANLI_SUPERVISION_WORK_PACKAGE:"WP8",AWARD_SUBCONTRACTOR_CODE:"WD"},
        {BID_SECTION_PACK:"S16",QUALITY_STATION_AREA:"Area1",JIANLI_SUPERVISION_WORK_PACKAGE:"WP8",AWARD_SUBCONTRACTOR_CODE:"SYPEC"},
        {BID_SECTION_PACK:"S17",QUALITY_STATION_AREA:"Area1",JIANLI_SUPERVISION_WORK_PACKAGE:"WP8",AWARD_SUBCONTRACTOR_CODE:"CNCEC"},
        {BID_SECTION_PACK:"S18",QUALITY_STATION_AREA:"Area4",JIANLI_SUPERVISION_WORK_PACKAGE:"WP7",AWARD_SUBCONTRACTOR_CODE:"SFCC"},
        {BID_SECTION_PACK:"S19",QUALITY_STATION_AREA:"Area4",JIANLI_SUPERVISION_WORK_PACKAGE:"WP4",AWARD_SUBCONTRACTOR_CODE:"SPC"},
        {BID_SECTION_PACK:"S19",QUALITY_STATION_AREA:"Area4",JIANLI_SUPERVISION_WORK_PACKAGE:"WP6",AWARD_SUBCONTRACTOR_CODE:"SPC"},
        {BID_SECTION_PACK:"S2",QUALITY_STATION_AREA:"Area1",JIANLI_SUPERVISION_WORK_PACKAGE:"WP2",AWARD_SUBCONTRACTOR_CODE:"SNEI"},
        {BID_SECTION_PACK:"S20",QUALITY_STATION_AREA:"Area4",JIANLI_SUPERVISION_WORK_PACKAGE:"WP6",AWARD_SUBCONTRACTOR_CODE:"SHL"},
        {BID_SECTION_PACK:"S21",QUALITY_STATION_AREA:"Area4",JIANLI_SUPERVISION_WORK_PACKAGE:"WP6",AWARD_SUBCONTRACTOR_CODE:"GDMY"},
        {BID_SECTION_PACK:"S3",QUALITY_STATION_AREA:"Area1",JIANLI_SUPERVISION_WORK_PACKAGE:"WP3",AWARD_SUBCONTRACTOR_CODE:"SNEI"},
        {BID_SECTION_PACK:"S4",QUALITY_STATION_AREA:"Area1",JIANLI_SUPERVISION_WORK_PACKAGE:"WP4",AWARD_SUBCONTRACTOR_CODE:"SFCC"},
        {BID_SECTION_PACK:"S5",QUALITY_STATION_AREA:"Area1",JIANLI_SUPERVISION_WORK_PACKAGE:"WP4",AWARD_SUBCONTRACTOR_CODE:"FCC"},
        {BID_SECTION_PACK:"S6",QUALITY_STATION_AREA:"Area2",JIANLI_SUPERVISION_WORK_PACKAGE:"WP6",AWARD_SUBCONTRACTOR_CODE:"GDHJ"},
        {BID_SECTION_PACK:"S7",QUALITY_STATION_AREA:"Area2",JIANLI_SUPERVISION_WORK_PACKAGE:"WP6",AWARD_SUBCONTRACTOR_CODE:"ZGLY"},
        {BID_SECTION_PACK:"S8",QUALITY_STATION_AREA:"Area4",JIANLI_SUPERVISION_WORK_PACKAGE:"WP6",AWARD_SUBCONTRACTOR_CODE:""},
        {BID_SECTION_PACK:"S9",QUALITY_STATION_AREA:"Area3",JIANLI_SUPERVISION_WORK_PACKAGE:"WP7",AWARD_SUBCONTRACTOR_CODE:"HNEDI"}
      ],
    },
    
    DataItems: [
      {  
        Subcontract: [
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 01",CONTENT_OF_BID_SECTION:"P1",CODE:"S1",BID_SECTION_DESCR:"Olefins Unit£¨include  Furnaces Area)",UNIT:127900,LOA_PLAN:"2022-04-06",CONTRACT_SIGN_PLAN:"2022-04-20",COMMENCE_SITEWORK_PLAN:"2022-04-20",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",CONTRACT_SIGN_STATUS:"COMPLETE",COMMENCE_SITEWORK_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Sinopec Tenth Construction Company (STCC)",AWARD_SUBCONTRACTOR_CODE:"STCC",CONSTRUCTION_PACKAGE:"P1",DESCRIPTION_EN:"Olefins Unit（include  Furnaces Area)",UNIT_NO_SEI_CODE:"127900",UNIT_NO_EM:"25,10"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 02",CONTENT_OF_BID_SECTION:"P1",CODE:"S2",BID_SECTION_DESCR:"Butadiene Unit",UNIT:127700,LOA_PLAN:"2022-05-26",CONTRACT_SIGN_PLAN:"2022-06-17",COMMENCE_SITEWORK_PLAN:"2022-06-17",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Sinopec Nanjing Engineering Co. Ltd (SNEI)",AWARD_SUBCONTRACTOR_CODE:"SNEI",CONSTRUCTION_PACKAGE:"P1",DESCRIPTION_EN:"Butadiene Unit",UNIT_NO_SEI_CODE:"127700",UNIT_NO_EM:"30"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 02",CONTENT_OF_BID_SECTION:"P1",CODE:"S2",BID_SECTION_DESCR:"Di-Isobutylene Unit",UNIT:136200,LOA_PLAN:"2022-05-26",CONTRACT_SIGN_PLAN:"2022-06-17",COMMENCE_SITEWORK_PLAN:"2022-06-17",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Sinopec Nanjing Engineering Co. Ltd (SNEI)",AWARD_SUBCONTRACTOR_CODE:"SNEI",CONSTRUCTION_PACKAGE:"P1",DESCRIPTION_EN:"Di-Isobutylene Unit",UNIT_NO_SEI_CODE:"136200",UNIT_NO_EM:"32"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 02",CONTENT_OF_BID_SECTION:"P1",CODE:"S2",BID_SECTION_DESCR:"Gasoline Hydrotreating Unit",UNIT:127800,LOA_PLAN:"2022-05-26",CONTRACT_SIGN_PLAN:"2022-06-17",COMMENCE_SITEWORK_PLAN:"2022-06-17",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Sinopec Nanjing Engineering Co. Ltd (SNEI)",AWARD_SUBCONTRACTOR_CODE:"SNEI",CONSTRUCTION_PACKAGE:"P1",DESCRIPTION_EN:"Gasoline Hydrotreating Unit",UNIT_NO_SEI_CODE:"127800",UNIT_NO_EM:"36"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 02",CONTENT_OF_BID_SECTION:"P1",CODE:"S8",BID_SECTION_DESCR:"Benzene Toluene Unit",UNIT:111300,LOA_PLAN:"2022-05-26",CONTRACT_SIGN_PLAN:"2022-06-17",COMMENCE_SITEWORK_PLAN:"2022-06-17",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Sinopec Nanjing Engineering Co. Ltd (SNEI)",AWARD_SUBCONTRACTOR_CODE:"SNEI",CONSTRUCTION_PACKAGE:"P1",DESCRIPTION_EN:"Benzene Toluene Unit",UNIT_NO_SEI_CODE:"111300",UNIT_NO_EM:"38"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 02",CONTENT_OF_BID_SECTION:"P1",CODE:"S21",BID_SECTION_DESCR:"Boiler Unit",UNIT:184800,LOA_PLAN:"2022-05-26",CONTRACT_SIGN_PLAN:"2022-06-17",COMMENCE_SITEWORK_PLAN:"2022-06-17",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Sinopec Nanjing Engineering Co. Ltd (SNEI)",AWARD_SUBCONTRACTOR_CODE:"SNEI",CONSTRUCTION_PACKAGE:"P1",DESCRIPTION_EN:"Boiler Unit",UNIT_NO_SEI_CODE:"184800",UNIT_NO_EM:"81"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 02",CONTENT_OF_BID_SECTION:"P1",CODE:"S20",BID_SECTION_DESCR:"Demineralized Water Unit",UNIT:184300,LOA_PLAN:"2022-05-26",CONTRACT_SIGN_PLAN:"2022-06-17",COMMENCE_SITEWORK_PLAN:"2022-06-17",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Sinopec Nanjing Engineering Co. Ltd (SNEI)",AWARD_SUBCONTRACTOR_CODE:"SNEI",CONSTRUCTION_PACKAGE:"P1",DESCRIPTION_EN:"Demineralized Water Unit",UNIT_NO_SEI_CODE:"184300",UNIT_NO_EM:"87"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 03",CONTENT_OF_BID_SECTION:"P2",CODE:"S3",BID_SECTION_DESCR:"Low Density Polyethylene Unit",UNIT:121400,LOA_PLAN:"2022-05-26",CONTRACT_SIGN_PLAN:"2022-06-17",COMMENCE_SITEWORK_PLAN:"2022-06-17",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Sinopec Nanjing Engineering Co. Ltd (SNEI)",AWARD_SUBCONTRACTOR_CODE:"SNEI",CONSTRUCTION_PACKAGE:"P2",DESCRIPTION_EN:"Low Density Polyethylene Unit",UNIT_NO_SEI_CODE:"121400",UNIT_NO_EM:"53"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 04",CONTENT_OF_BID_SECTION:"P2",CODE:"S4",BID_SECTION_DESCR:"Polypropylene Unit",UNIT:121900,LOA_PLAN:"2022-05-06",CONTRACT_SIGN_PLAN:"2022-06-06",COMMENCE_SITEWORK_PLAN:"2022-06-06",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Sinopec Fifth Construction Company (SFCC)",AWARD_SUBCONTRACTOR_CODE:"SFCC",CONSTRUCTION_PACKAGE:"P2",DESCRIPTION_EN:"Polypropylene Unit",UNIT_NO_SEI_CODE:"121900",UNIT_NO_EM:"60,61,62"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 04",CONTENT_OF_BID_SECTION:"P2",CODE:"S4",BID_SECTION_DESCR:"Truck Scale1",UNIT:252701,LOA_PLAN:"2022-05-06",CONTRACT_SIGN_PLAN:"2022-06-06",COMMENCE_SITEWORK_PLAN:"2022-06-06",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Sinopec Fifth Construction Company (SFCC)",AWARD_SUBCONTRACTOR_CODE:"SFCC",CONSTRUCTION_PACKAGE:"P2",DESCRIPTION_EN:"Truck Scale1",UNIT_NO_SEI_CODE:"252701",UNIT_NO_EM:"58"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 04",CONTENT_OF_BID_SECTION:"P2",CODE:"S4",BID_SECTION_DESCR:"PBL Battery Limit",UNIT:152800,LOA_PLAN:"2022-05-06",CONTRACT_SIGN_PLAN:"2022-06-06",COMMENCE_SITEWORK_PLAN:"2022-06-06",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Sinopec Fifth Construction Company (SFCC)",AWARD_SUBCONTRACTOR_CODE:"SFCC",CONSTRUCTION_PACKAGE:"P2",DESCRIPTION_EN:"PBL Battery Limit",UNIT_NO_SEI_CODE:"152800",UNIT_NO_EM:"64"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 04",CONTENT_OF_BID_SECTION:"P2",CODE:"S4",BID_SECTION_DESCR:"No.1 Warehouse",UNIT:152801,LOA_PLAN:"2022-05-06",CONTRACT_SIGN_PLAN:"2022-06-06",COMMENCE_SITEWORK_PLAN:"2022-06-06",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Sinopec Fifth Construction Company (SFCC)",AWARD_SUBCONTRACTOR_CODE:"SFCC",CONSTRUCTION_PACKAGE:"P2",DESCRIPTION_EN:"No.1 Warehouse",UNIT_NO_SEI_CODE:"152801",UNIT_NO_EM:"64"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 04",CONTENT_OF_BID_SECTION:"P2",CODE:"S4",BID_SECTION_DESCR:"No.1 Package Plant",UNIT:152803,LOA_PLAN:"2022-05-06",CONTRACT_SIGN_PLAN:"2022-06-06",COMMENCE_SITEWORK_PLAN:"2022-06-06",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Sinopec Fifth Construction Company (SFCC)",AWARD_SUBCONTRACTOR_CODE:"SFCC",CONSTRUCTION_PACKAGE:"P2",DESCRIPTION_EN:"No.1 Package Plant",UNIT_NO_SEI_CODE:"152803",UNIT_NO_EM:"64"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 05",CONTENT_OF_BID_SECTION:"P2",CODE:"S5",BID_SECTION_DESCR:"Gas Phase Polyethylene Unit",UNIT:121300,LOA_PLAN:"2022-05-05",CONTRACT_SIGN_PLAN:"2022-05-30",COMMENCE_SITEWORK_PLAN:"2022-05-30",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Sinopec Fourth Construction Company (FCC)",AWARD_SUBCONTRACTOR_CODE:"FCC",CONSTRUCTION_PACKAGE:"P2",DESCRIPTION_EN:"Gas Phase Polyethylene Unit",UNIT_NO_SEI_CODE:"121300",UNIT_NO_EM:"50,51,52"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 05",CONTENT_OF_BID_SECTION:"P2",CODE:"S5",BID_SECTION_DESCR:"Truck Scale2",UNIT:252702,LOA_PLAN:"2022-05-05",CONTRACT_SIGN_PLAN:"2022-05-30",COMMENCE_SITEWORK_PLAN:"2022-05-30",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Sinopec Fourth Construction Company (FCC)",AWARD_SUBCONTRACTOR_CODE:"FCC",CONSTRUCTION_PACKAGE:"P2",DESCRIPTION_EN:"Truck Scale2",UNIT_NO_SEI_CODE:"252702",UNIT_NO_EM:"58"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 05",CONTENT_OF_BID_SECTION:"P2",CODE:"S5",BID_SECTION_DESCR:"No.2 Warehouse",UNIT:152802,LOA_PLAN:"2022-05-05",CONTRACT_SIGN_PLAN:"2022-05-30",COMMENCE_SITEWORK_PLAN:"2022-05-30",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Sinopec Fourth Construction Company (FCC)",AWARD_SUBCONTRACTOR_CODE:"FCC",CONSTRUCTION_PACKAGE:"P2",DESCRIPTION_EN:"No.2 Warehouse",UNIT_NO_SEI_CODE:"152802",UNIT_NO_EM:"64"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 05",CONTENT_OF_BID_SECTION:"P2",CODE:"S5",BID_SECTION_DESCR:"No.2 Package Plant",UNIT:152804,LOA_PLAN:"2022-05-05",CONTRACT_SIGN_PLAN:"2022-05-30",COMMENCE_SITEWORK_PLAN:"2022-05-30",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Sinopec Fourth Construction Company (FCC)",AWARD_SUBCONTRACTOR_CODE:"FCC",CONSTRUCTION_PACKAGE:"P2",DESCRIPTION_EN:"No.2 Package Plant",UNIT_NO_SEI_CODE:"152804",UNIT_NO_EM:"64"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 05",CONTENT_OF_BID_SECTION:"P2",CODE:"S5",BID_SECTION_DESCR:"Auxiliary Facility",UNIT:152805,LOA_PLAN:"2022-05-05",CONTRACT_SIGN_PLAN:"2022-05-30",COMMENCE_SITEWORK_PLAN:"2022-05-30",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Sinopec Fourth Construction Company (FCC)",AWARD_SUBCONTRACTOR_CODE:"FCC",CONSTRUCTION_PACKAGE:"P2",DESCRIPTION_EN:"Auxiliary Facility",UNIT_NO_SEI_CODE:"152805",UNIT_NO_EM:"64"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 05",CONTENT_OF_BID_SECTION:"P2",CODE:"S5",BID_SECTION_DESCR:"Main plant Dispatch Area",UNIT:152700,LOA_PLAN:"2022-05-05",CONTRACT_SIGN_PLAN:"2022-05-30",COMMENCE_SITEWORK_PLAN:"2022-05-30",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Sinopec Fourth Construction Company (FCC)",AWARD_SUBCONTRACTOR_CODE:"FCC",CONSTRUCTION_PACKAGE:"P2",DESCRIPTION_EN:"Main plant Dispatch Area",UNIT_NO_SEI_CODE:"152700",UNIT_NO_EM:"58"},
          {CONSTRUCTION_PACKAGE:"P3",DESCRIPTION_EN:"Common Area of Management Facilities（B+）",UNIT_NO_SEI_CODE:"151000",UNIT_NO_EM:"58"},
          {CONSTRUCTION_PACKAGE:"P3",DESCRIPTION_EN:"Area B+ Substation",UNIT_NO_SEI_CODE:"183100",UNIT_NO_EM:"98"},
          {CONSTRUCTION_PACKAGE:"P3",DESCRIPTION_EN:"Firewater Pump Station（B+）",UNIT_NO_SEI_CODE:"381000",UNIT_NO_EM:"99"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 06",CONTENT_OF_BID_SECTION:"P3",CODE:"S6",BID_SECTION_DESCR:"Control Center Building",UNIT:186000,LOA_PLAN:"2022-06-16",CONTRACT_SIGN_PLAN:"2022-07-15",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"BEHINDPLAN",AWARD_BID_APPROVAL_STATUS:"BEHINDPLAN",LOA_STATUS:"BEHINDPLAN",CONTRACT_SIGN_STATUS:"BEHINDPLAN",AWARD_SUBCONTRACTOR:"",CONSTRUCTION_PACKAGE:"P3",DESCRIPTION_EN:"Control Center Building",UNIT_NO_SEI_CODE:"186000",UNIT_NO_EM:"98"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 06",CONTENT_OF_BID_SECTION:"P3",CODE:"S6",BID_SECTION_DESCR:"Central Laboratory and Environmental Monitoring Station",UNIT:152000,LOA_PLAN:"2022-06-16",CONTRACT_SIGN_PLAN:"2022-07-15",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"BEHINDPLAN",AWARD_BID_APPROVAL_STATUS:"BEHINDPLAN",LOA_STATUS:"BEHINDPLAN",CONTRACT_SIGN_STATUS:"BEHINDPLAN",AWARD_SUBCONTRACTOR:"",CONSTRUCTION_PACKAGE:"P3",DESCRIPTION_EN:"Central Laboratory and Environmental Monitoring Station ",UNIT_NO_SEI_CODE:"152000",UNIT_NO_EM:"98"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 06",CONTENT_OF_BID_SECTION:"P3",CODE:"S6",BID_SECTION_DESCR:"Waste Water Pump Station 1",UNIT:180401,LOA_PLAN:"2022-06-16",CONTRACT_SIGN_PLAN:"2022-07-15",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"BEHINDPLAN",AWARD_BID_APPROVAL_STATUS:"BEHINDPLAN",LOA_STATUS:"BEHINDPLAN",CONTRACT_SIGN_STATUS:"BEHINDPLAN",AWARD_SUBCONTRACTOR:"",CONSTRUCTION_PACKAGE:"P3",DESCRIPTION_EN:"Waste Water Pump Station 1",UNIT_NO_SEI_CODE:"180401",UNIT_NO_EM:"57"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 06",CONTENT_OF_BID_SECTION:"P3",CODE:"S6",BID_SECTION_DESCR:"Environmental Monitoring Sub-station",UNIT:152000,LOA_PLAN:"2022-06-16",CONTRACT_SIGN_PLAN:"2022-07-15",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"BEHINDPLAN",AWARD_BID_APPROVAL_STATUS:"BEHINDPLAN",LOA_STATUS:"BEHINDPLAN",CONTRACT_SIGN_STATUS:"BEHINDPLAN",AWARD_SUBCONTRACTOR:"",CONSTRUCTION_PACKAGE:"P3",DESCRIPTION_EN:"Environmental Monitoring Sub-station",UNIT_NO_SEI_CODE:"152000",UNIT_NO_EM:"98"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 07",CONTENT_OF_BID_SECTION:"P3",CODE:"S7",BID_SECTION_DESCR:"Administration Office Building B (including canteen & locker room)",UNIT:154000,LOA_PLAN:"2022-06-16",CONTRACT_SIGN_PLAN:"2022-07-15",COMMENCE_SITEWORK_PLAN:"2022-08-15",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"BEHINDPLAN",AWARD_BID_APPROVAL_STATUS:"BEHINDPLAN",LOA_STATUS:"BEHINDPLAN",CONTRACT_SIGN_STATUS:"BEHINDPLAN",COMMENCE_SITEWORK_STATUS:"BEHINDPLAN",AWARD_SUBCONTRACTOR:"",CONSTRUCTION_PACKAGE:"P3",DESCRIPTION_EN:"Administration Office Building B (including canteen & locker room)",UNIT_NO_SEI_CODE:"154000",UNIT_NO_EM:"98"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 07",CONTENT_OF_BID_SECTION:"P3",CODE:"S7",BID_SECTION_DESCR:"Connecting Corridor & Overhead Bridge",UNIT:154000,LOA_PLAN:"2022-06-16",CONTRACT_SIGN_PLAN:"2022-07-15",COMMENCE_SITEWORK_PLAN:"2022-08-15",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"BEHINDPLAN",AWARD_BID_APPROVAL_STATUS:"BEHINDPLAN",LOA_STATUS:"BEHINDPLAN",CONTRACT_SIGN_STATUS:"BEHINDPLAN",COMMENCE_SITEWORK_STATUS:"BEHINDPLAN",AWARD_SUBCONTRACTOR:"",CONSTRUCTION_PACKAGE:"P3",DESCRIPTION_EN:"Connecting Corridor & Overhead Bridge",UNIT_NO_SEI_CODE:"154000",UNIT_NO_EM:"98"},
          {CONSTRUCTION_PACKAGE:"P3",DESCRIPTION_EN:"High Quality Decoration of Buildings",UNIT_NO_EM:"0"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 09",CONTENT_OF_BID_SECTION:"P3",CODE:"S9",BID_SECTION_DESCR:"220KV Main Substation",UNIT:183000,LOA_PLAN:"2022-03-28",CONTRACT_SIGN_PLAN:"2022-03-28",COMMENCE_SITEWORK_PLAN:"2022-03-28",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",CONTRACT_SIGN_STATUS:"COMPLETE",COMMENCE_SITEWORK_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Hainan Electric (HNEDI)",AWARD_SUBCONTRACTOR_CODE:"HNEDI",CONSTRUCTION_PACKAGE:"P3",DESCRIPTION_EN:"220KV Main Substation",UNIT_NO_SEI_CODE:"183000",UNIT_NO_EM:"78"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 10",CONTENT_OF_BID_SECTION:"P3",CODE:"S10",BID_SECTION_DESCR:"Factory Greening",UNIT:150200,LOA_PLAN:"2023-04-30",CONTRACT_SIGN_PLAN:"2023-05-30",ISSUE_ITB_ACTUAL:"On Track",ISSUE_ITB_STATUS:"ONTRACK",LOA_STATUS:"ONTRACK",CONTRACT_SIGN_STATUS:"BEHINDPLAN",AWARD_SUBCONTRACTOR:"",CONSTRUCTION_PACKAGE:"P3",DESCRIPTION_EN:"Factory Greening",UNIT_NO_SEI_CODE:"150200",UNIT_NO_EM:"58"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 11",CONTENT_OF_BID_SECTION:"P3",CODE:"S11",BID_SECTION_DESCR:"Chemical Warehouses",UNIT:152400,LOA_PLAN:"2022-06-20",CONTRACT_SIGN_PLAN:"2022-07-20",COMMENCE_SITEWORK_PLAN:"2022-08-30",ISSUE_ITB_ACTUAL:"No Data",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Huizhou Dong Jiang (HZDJ)",AWARD_SUBCONTRACTOR_CODE:"HZDJ",CONSTRUCTION_PACKAGE:"P3",DESCRIPTION_EN:"Chemical Warehouses",UNIT_NO_SEI_CODE:"152400",UNIT_NO_EM:"72"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 11",CONTENT_OF_BID_SECTION:"P3",CODE:"S11",BID_SECTION_DESCR:"Truck Scale3",UNIT:252703,LOA_PLAN:"2022-06-20",CONTRACT_SIGN_PLAN:"2022-07-20",COMMENCE_SITEWORK_PLAN:"2022-08-30",ISSUE_ITB_ACTUAL:"No Data",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Huizhou Dong Jiang (HZDJ)",AWARD_SUBCONTRACTOR_CODE:"HZDJ",CONSTRUCTION_PACKAGE:"P3",DESCRIPTION_EN:"Truck Scale3",UNIT_NO_SEI_CODE:"252703",UNIT_NO_EM:"58"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 11",CONTENT_OF_BID_SECTION:"P3",CODE:"S11",BID_SECTION_DESCR:"Maintenance Workshop",UNIT:152200,LOA_PLAN:"2022-06-20",CONTRACT_SIGN_PLAN:"2022-07-20",COMMENCE_SITEWORK_PLAN:"2022-08-30",ISSUE_ITB_ACTUAL:"No Data",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Huizhou Dong Jiang (HZDJ)",AWARD_SUBCONTRACTOR_CODE:"HZDJ",CONSTRUCTION_PACKAGE:"P3",DESCRIPTION_EN:"Maintenance Workshop",UNIT_NO_SEI_CODE:"152200",UNIT_NO_EM:"85"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 11",CONTENT_OF_BID_SECTION:"P3",CODE:"S11",BID_SECTION_DESCR:"Warehouse Management Room and Spare Parts Warehouse",UNIT:152300,LOA_PLAN:"2022-06-20",CONTRACT_SIGN_PLAN:"2022-07-20",COMMENCE_SITEWORK_PLAN:"2022-08-30",ISSUE_ITB_ACTUAL:"No Data",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Huizhou Dong Jiang (HZDJ)",AWARD_SUBCONTRACTOR_CODE:"HZDJ",CONSTRUCTION_PACKAGE:"P3",DESCRIPTION_EN:"Warehouse Management Room and Spare Parts Warehouse",UNIT_NO_SEI_CODE:"152300",UNIT_NO_EM:"85"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 11",CONTENT_OF_BID_SECTION:"P3",CODE:"S11",BID_SECTION_DESCR:"FF & Gas Protection Station",UNIT:181200,LOA_PLAN:"2022-06-20",CONTRACT_SIGN_PLAN:"2022-07-20",COMMENCE_SITEWORK_PLAN:"2022-08-30",ISSUE_ITB_ACTUAL:"No Data",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Huizhou Dong Jiang (HZDJ)",AWARD_SUBCONTRACTOR_CODE:"HZDJ",CONSTRUCTION_PACKAGE:"P3",DESCRIPTION_EN:"FF & Gas Protection Station",UNIT_NO_SEI_CODE:"181200",UNIT_NO_EM:"79"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 11",CONTENT_OF_BID_SECTION:"P3",CODE:"S11",BID_SECTION_DESCR:"Hazardous waste",UNIT:182200,LOA_PLAN:"2022-06-20",CONTRACT_SIGN_PLAN:"2022-07-20",COMMENCE_SITEWORK_PLAN:"2022-08-30",ISSUE_ITB_ACTUAL:"No Data",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Huizhou Dong Jiang (HZDJ)",AWARD_SUBCONTRACTOR_CODE:"HZDJ",CONSTRUCTION_PACKAGE:"P3",DESCRIPTION_EN:"Hazardous waste",UNIT_NO_SEI_CODE:"182200",UNIT_NO_EM:"72"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 12",CONTENT_OF_BID_SECTION:"P3",CODE:"S12",BID_SECTION_DESCR:"Cooling Water System 2",UNIT:280200,LOA_PLAN:"2022-06-11",CONTRACT_SIGN_PLAN:"2022-07-01",COMMENCE_SITEWORK_PLAN:"2022-07-01",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Sinopec Fifth Construction Company (SFCC)",AWARD_SUBCONTRACTOR_CODE:"SFCC",CONSTRUCTION_PACKAGE:"P3",DESCRIPTION_EN:"Cooling Water System 2",UNIT_NO_SEI_CODE:"280200",UNIT_NO_EM:"92"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 12",CONTENT_OF_BID_SECTION:"P3",CODE:"S12",BID_SECTION_DESCR:"Air Compression Station",UNIT:185300,LOA_PLAN:"2022-06-11",CONTRACT_SIGN_PLAN:"2022-07-01",COMMENCE_SITEWORK_PLAN:"2022-07-01",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Sinopec Fifth Construction Company (SFCC)",AWARD_SUBCONTRACTOR_CODE:"SFCC",CONSTRUCTION_PACKAGE:"P3",DESCRIPTION_EN:"Air Compression Station",UNIT_NO_SEI_CODE:"185300",UNIT_NO_EM:"94"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 12",CONTENT_OF_BID_SECTION:"P3",CODE:"S12",BID_SECTION_DESCR:"Water and Firewater Pump Station 1",UNIT:181000,LOA_PLAN:"2022-06-11",CONTRACT_SIGN_PLAN:"2022-07-01",COMMENCE_SITEWORK_PLAN:"2022-07-01",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Sinopec Fifth Construction Company (SFCC)",AWARD_SUBCONTRACTOR_CODE:"SFCC",CONSTRUCTION_PACKAGE:"P3",DESCRIPTION_EN:"Water and Firewater Pump Station 1",UNIT_NO_SEI_CODE:"181000",UNIT_NO_EM:"74"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 12",CONTENT_OF_BID_SECTION:"P3",CODE:"S12",BID_SECTION_DESCR:"Cooling Water System 1",UNIT:180200,LOA_PLAN:"2022-06-11",CONTRACT_SIGN_PLAN:"2022-07-01",COMMENCE_SITEWORK_PLAN:"2022-07-01",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Sinopec Fifth Construction Company (SFCC)",AWARD_SUBCONTRACTOR_CODE:"SFCC",CONSTRUCTION_PACKAGE:"P3",DESCRIPTION_EN:"Cooling Water System 1",UNIT_NO_SEI_CODE:"180200",UNIT_NO_EM:"91"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 13",CONTENT_OF_BID_SECTION:"P1",CODE:"S13",BID_SECTION_DESCR:"Ethylene Cryogenic Tank Farm",UNIT:170100,LOA_PLAN:"2022-06-12",CONTRACT_SIGN_PLAN:"2022-06-30",COMMENCE_SITEWORK_PLAN:"2022-06-30",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Sinopec Fifth Construction Company (SFCC)",AWARD_SUBCONTRACTOR_CODE:"SFCC",CONSTRUCTION_PACKAGE:"P1",DESCRIPTION_EN:"Ethylene Cryogenic Tank Farm",UNIT_NO_SEI_CODE:"170100",UNIT_NO_EM:"70"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 13",CONTENT_OF_BID_SECTION:"P1",CODE:"S13",BID_SECTION_DESCR:"Foam Station 1",UNIT:181301,LOA_PLAN:"2022-06-12",CONTRACT_SIGN_PLAN:"2022-06-30",COMMENCE_SITEWORK_PLAN:"2022-06-30",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Sinopec Fifth Construction Company (SFCC)",AWARD_SUBCONTRACTOR_CODE:"SFCC",CONSTRUCTION_PACKAGE:"P1",DESCRIPTION_EN:"Foam Station 1",UNIT_NO_SEI_CODE:"181301",UNIT_NO_EM:"71"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 13",CONTENT_OF_BID_SECTION:"P1",CODE:"S13",BID_SECTION_DESCR:"Pressurized Tank Farm 1",UNIT:170300,LOA_PLAN:"2022-06-12",CONTRACT_SIGN_PLAN:"2022-06-30",COMMENCE_SITEWORK_PLAN:"2022-06-30",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Sinopec Fifth Construction Company (SFCC)",AWARD_SUBCONTRACTOR_CODE:"SFCC",CONSTRUCTION_PACKAGE:"P1",DESCRIPTION_EN:"Pressurized Tank Farm 1",UNIT_NO_SEI_CODE:"170300",UNIT_NO_EM:"70"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 13",CONTENT_OF_BID_SECTION:"P1",CODE:"S13",BID_SECTION_DESCR:"Pressurized Tank Farm 2",UNIT:270300,LOA_PLAN:"2022-06-12",CONTRACT_SIGN_PLAN:"2022-06-30",COMMENCE_SITEWORK_PLAN:"2022-06-30",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Sinopec Fifth Construction Company (SFCC)",AWARD_SUBCONTRACTOR_CODE:"SFCC",CONSTRUCTION_PACKAGE:"P1",DESCRIPTION_EN:"Pressurized Tank Farm 2",UNIT_NO_SEI_CODE:"270300",UNIT_NO_EM:"70"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 13",CONTENT_OF_BID_SECTION:"P1",CODE:"S13",BID_SECTION_DESCR:"Pressurized Tank Farm Common Area",UNIT:170000,LOA_PLAN:"2022-06-12",CONTRACT_SIGN_PLAN:"2022-06-30",COMMENCE_SITEWORK_PLAN:"2022-06-30",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Sinopec Fifth Construction Company (SFCC)",AWARD_SUBCONTRACTOR_CODE:"SFCC",CONSTRUCTION_PACKAGE:"P1",DESCRIPTION_EN:"Pressurized Tank Farm Common Area",UNIT_NO_SEI_CODE:"170000",UNIT_NO_EM:"70"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 13",CONTENT_OF_BID_SECTION:"P1",CODE:"S13",BID_SECTION_DESCR:"Offspec Ethylene Tank Farm",UNIT:270100,LOA_PLAN:"2022-06-12",CONTRACT_SIGN_PLAN:"2022-06-30",COMMENCE_SITEWORK_PLAN:"2022-06-30",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Sinopec Fifth Construction Company (SFCC)",AWARD_SUBCONTRACTOR_CODE:"SFCC",CONSTRUCTION_PACKAGE:"P1",DESCRIPTION_EN:"Offspec Ethylene Tank Farm",UNIT_NO_SEI_CODE:"270100",UNIT_NO_EM:"70"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 13",CONTENT_OF_BID_SECTION:"P1",CODE:"S13",BID_SECTION_DESCR:"Waste Water Pump Station 2",UNIT:180402,LOA_PLAN:"2022-06-12",CONTRACT_SIGN_PLAN:"2022-06-30",COMMENCE_SITEWORK_PLAN:"2022-06-30",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Sinopec Fifth Construction Company (SFCC)",AWARD_SUBCONTRACTOR_CODE:"SFCC",CONSTRUCTION_PACKAGE:"P1",DESCRIPTION_EN:"Waste Water Pump Station 2",UNIT_NO_SEI_CODE:"180402",UNIT_NO_EM:"57"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 13",CONTENT_OF_BID_SECTION:"P1",CODE:"S13",BID_SECTION_DESCR:"Propylene Tank Farm",UNIT:124700,LOA_PLAN:"2022-06-12",CONTRACT_SIGN_PLAN:"2022-06-30",COMMENCE_SITEWORK_PLAN:"2022-06-30",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Sinopec Fifth Construction Company (SFCC)",AWARD_SUBCONTRACTOR_CODE:"SFCC",CONSTRUCTION_PACKAGE:"P1",DESCRIPTION_EN:"Propylene Tank Farm",UNIT_NO_SEI_CODE:"124700",UNIT_NO_EM:"70"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 13",CONTENT_OF_BID_SECTION:"P1",CODE:"S13",BID_SECTION_DESCR:"Henxene-1 Tank Farm",UNIT:370100,LOA_PLAN:"2022-06-12",CONTRACT_SIGN_PLAN:"2022-06-30",COMMENCE_SITEWORK_PLAN:"2022-06-30",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Sinopec Fifth Construction Company (SFCC)",AWARD_SUBCONTRACTOR_CODE:"SFCC",CONSTRUCTION_PACKAGE:"P1",DESCRIPTION_EN:"Henxene-1 Tank Farm",UNIT_NO_SEI_CODE:"370100",UNIT_NO_EM:"70"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 14",CONTENT_OF_BID_SECTION:"P1",CODE:"S14",BID_SECTION_DESCR:"Atmospheric Tank Farm Common Area",UNIT:277000,LOA_PLAN:"2022-06-27",CONTRACT_SIGN_PLAN:"2022-07-15",COMMENCE_SITEWORK_PLAN:"2022-07-15",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"China Chemical Construction No. 7 Company (CC7)",AWARD_SUBCONTRACTOR_CODE:"CC7",CONSTRUCTION_PACKAGE:"P1",DESCRIPTION_EN:"Atmospheric Tank Farm Common Area",UNIT_NO_SEI_CODE:"277000",UNIT_NO_EM:"70"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 14",CONTENT_OF_BID_SECTION:"P1",CODE:"S14",BID_SECTION_DESCR:"Intermediate Tank Farm 1",UNIT:177100,LOA_PLAN:"2022-06-27",CONTRACT_SIGN_PLAN:"2022-07-15",COMMENCE_SITEWORK_PLAN:"2022-07-15",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"China Chemical Construction No. 7 Company (CC7)",AWARD_SUBCONTRACTOR_CODE:"CC7",CONSTRUCTION_PACKAGE:"P1",DESCRIPTION_EN:"Intermediate Tank Farm 1",UNIT_NO_SEI_CODE:"177100",UNIT_NO_EM:"70"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 14",CONTENT_OF_BID_SECTION:"P1",CODE:"S14",BID_SECTION_DESCR:"Naphtha Tank Farm",UNIT:168000,LOA_PLAN:"2022-06-27",CONTRACT_SIGN_PLAN:"2022-07-15",COMMENCE_SITEWORK_PLAN:"2022-07-15",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"China Chemical Construction No. 7 Company (CC7)",AWARD_SUBCONTRACTOR_CODE:"CC7",CONSTRUCTION_PACKAGE:"P1",DESCRIPTION_EN:"Naphtha Tank Farm",UNIT_NO_SEI_CODE:"168000",UNIT_NO_EM:"70"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 14",CONTENT_OF_BID_SECTION:"P1",CODE:"S14",BID_SECTION_DESCR:"Vapor Gas Recovery Unit",UNIT:179600,LOA_PLAN:"2022-06-27",CONTRACT_SIGN_PLAN:"2022-07-15",COMMENCE_SITEWORK_PLAN:"2022-07-15",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"China Chemical Construction No. 7 Company (CC7)",AWARD_SUBCONTRACTOR_CODE:"CC7",CONSTRUCTION_PACKAGE:"P1",DESCRIPTION_EN:"Vapor Gas Recovery Unit",UNIT_NO_SEI_CODE:"179600",UNIT_NO_EM:"89"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 14",CONTENT_OF_BID_SECTION:"P1",CODE:"S14",BID_SECTION_DESCR:"Foam Station 2",UNIT:181302,LOA_PLAN:"2022-06-27",CONTRACT_SIGN_PLAN:"2022-07-15",COMMENCE_SITEWORK_PLAN:"2022-07-15",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"China Chemical Construction No. 7 Company (CC7)",AWARD_SUBCONTRACTOR_CODE:"CC7",CONSTRUCTION_PACKAGE:"P1",DESCRIPTION_EN:"Foam Station 2",UNIT_NO_SEI_CODE:"181302",UNIT_NO_EM:"71"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 14",CONTENT_OF_BID_SECTION:"P1",CODE:"S14",BID_SECTION_DESCR:"Foam Station 3",UNIT:181303,LOA_PLAN:"2022-06-27",CONTRACT_SIGN_PLAN:"2022-07-15",COMMENCE_SITEWORK_PLAN:"2022-07-15",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"China Chemical Construction No. 7 Company (CC7)",AWARD_SUBCONTRACTOR_CODE:"CC7",CONSTRUCTION_PACKAGE:"P1",DESCRIPTION_EN:"Foam Station 3",UNIT_NO_SEI_CODE:"181303",UNIT_NO_EM:"71"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 14",CONTENT_OF_BID_SECTION:"P1",CODE:"S14",BID_SECTION_DESCR:"Foam Station 4",UNIT:181304,LOA_PLAN:"2022-06-27",CONTRACT_SIGN_PLAN:"2022-07-15",COMMENCE_SITEWORK_PLAN:"2022-07-15",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"China Chemical Construction No. 7 Company (CC7)",AWARD_SUBCONTRACTOR_CODE:"CC7",CONSTRUCTION_PACKAGE:"P1",DESCRIPTION_EN:"Foam Station 4",UNIT_NO_SEI_CODE:"181304",UNIT_NO_EM:"71"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 14",CONTENT_OF_BID_SECTION:"P1",CODE:"S14",BID_SECTION_DESCR:"Waste Water Pump Station 3",UNIT:180403,LOA_PLAN:"2022-06-27",CONTRACT_SIGN_PLAN:"2022-07-15",COMMENCE_SITEWORK_PLAN:"2022-07-15",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"China Chemical Construction No. 7 Company (CC7)",AWARD_SUBCONTRACTOR_CODE:"CC7",CONSTRUCTION_PACKAGE:"P1",DESCRIPTION_EN:"Waste Water Pump Station 3",UNIT_NO_SEI_CODE:"180403",UNIT_NO_EM:"57"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 15",CONTENT_OF_BID_SECTION:"P1",CODE:"S15",BID_SECTION_DESCR:"Mogas Tank Farm",UNIT:170800,LOA_PLAN:"2022-06-27",CONTRACT_SIGN_PLAN:"2022-07-15",COMMENCE_SITEWORK_PLAN:"2022-07-15",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Jiangsu Weida Construction Group Co. Ltd. (WD)",AWARD_SUBCONTRACTOR_CODE:"WD",CONSTRUCTION_PACKAGE:"P1",DESCRIPTION_EN:"Mogas Tank Farm",UNIT_NO_SEI_CODE:"170800",UNIT_NO_EM:"70"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 15",CONTENT_OF_BID_SECTION:"P1",CODE:"S15",BID_SECTION_DESCR:"Intermediate Tank Farm 2",UNIT:277100,LOA_PLAN:"2022-06-27",CONTRACT_SIGN_PLAN:"2022-07-15",COMMENCE_SITEWORK_PLAN:"2022-07-15",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Jiangsu Weida Construction Group Co. Ltd. (WD)",AWARD_SUBCONTRACTOR_CODE:"WD",CONSTRUCTION_PACKAGE:"P1",DESCRIPTION_EN:"Intermediate Tank Farm 2",UNIT_NO_SEI_CODE:"277100",UNIT_NO_EM:"70"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 15",CONTENT_OF_BID_SECTION:"P1",CODE:"S15",BID_SECTION_DESCR:"Intermediate Tank Farm 3",UNIT:377100,LOA_PLAN:"2022-06-27",CONTRACT_SIGN_PLAN:"2022-07-15",COMMENCE_SITEWORK_PLAN:"2022-07-15",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Jiangsu Weida Construction Group Co. Ltd. (WD)",AWARD_SUBCONTRACTOR_CODE:"WD",CONSTRUCTION_PACKAGE:"P1",DESCRIPTION_EN:"Intermediate Tank Farm 3",UNIT_NO_SEI_CODE:"377100",UNIT_NO_EM:"70"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 16",CONTENT_OF_BID_SECTION:"P3",CODE:"S16",BID_SECTION_DESCR:"Ground Flare",UNIT:178900,LOA_PLAN:"2022-07-12",CONTRACT_SIGN_PLAN:"2022-08-01",COMMENCE_SITEWORK_PLAN:"2022-07-28",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Shandong ShengYue Petrochemical Engineering Construction Co. Ltd. (SYPEC)",AWARD_SUBCONTRACTOR_CODE:"SYPEC",CONSTRUCTION_PACKAGE:"P3",DESCRIPTION_EN:"Ground Flare",UNIT_NO_SEI_CODE:"178900",UNIT_NO_EM:"90"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 16",CONTENT_OF_BID_SECTION:"P3",CODE:"S16",BID_SECTION_DESCR:"Elevated Flare",UNIT:178600,LOA_PLAN:"2022-07-12",CONTRACT_SIGN_PLAN:"2022-08-01",COMMENCE_SITEWORK_PLAN:"2022-07-28",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Shandong ShengYue Petrochemical Engineering Construction Co. Ltd. (SYPEC)",AWARD_SUBCONTRACTOR_CODE:"SYPEC",CONSTRUCTION_PACKAGE:"P3",DESCRIPTION_EN:"Elevated Flare",UNIT_NO_SEI_CODE:"178600",UNIT_NO_EM:"90"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 16",CONTENT_OF_BID_SECTION:"P3",CODE:"S16",BID_SECTION_DESCR:"Flare Gas Recovery Unit",UNIT:178800,LOA_PLAN:"2022-07-12",CONTRACT_SIGN_PLAN:"2022-08-01",COMMENCE_SITEWORK_PLAN:"2022-07-28",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Shandong ShengYue Petrochemical Engineering Construction Co. Ltd. (SYPEC)",AWARD_SUBCONTRACTOR_CODE:"SYPEC",CONSTRUCTION_PACKAGE:"P3",DESCRIPTION_EN:"Flare Gas Recovery Unit",UNIT_NO_SEI_CODE:"178800",UNIT_NO_EM:"90"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 17",CONTENT_OF_BID_SECTION:"P3",CODE:"S17",BID_SECTION_DESCR:"Wastewater Pre-Treatment Unit",UNIT:182400,LOA_PLAN:"2022-07-04",CONTRACT_SIGN_PLAN:"2022-07-27",COMMENCE_SITEWORK_PLAN:"2022-07-27",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"China National Chemical Engineering Co. Ltd. 13th Company (CNCEC)",AWARD_SUBCONTRACTOR_CODE:"CNCEC",CONSTRUCTION_PACKAGE:"P3",DESCRIPTION_EN:"Wastewater Pre-Treatment Unit",UNIT_NO_SEI_CODE:"182400",UNIT_NO_EM:"97"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 17",CONTENT_OF_BID_SECTION:"P3",CODE:"S17",BID_SECTION_DESCR:"Water and Firewater Pump Station 2",UNIT:281000,LOA_PLAN:"2022-07-04",CONTRACT_SIGN_PLAN:"2022-07-27",COMMENCE_SITEWORK_PLAN:"2022-07-27",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"China National Chemical Engineering Co. Ltd. 13th Company (CNCEC)",AWARD_SUBCONTRACTOR_CODE:"CNCEC",CONSTRUCTION_PACKAGE:"P3",DESCRIPTION_EN:"Water and Firewater Pump Station 2",UNIT_NO_SEI_CODE:"281000",UNIT_NO_EM:"76"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 17",CONTENT_OF_BID_SECTION:"P3",CODE:"S17",BID_SECTION_DESCR:"Ammonia Water Tank Farm",UNIT:166800,LOA_PLAN:"2022-07-04",CONTRACT_SIGN_PLAN:"2022-07-27",COMMENCE_SITEWORK_PLAN:"2022-07-27",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"China National Chemical Engineering Co. Ltd. 13th Company (CNCEC)",AWARD_SUBCONTRACTOR_CODE:"CNCEC",CONSTRUCTION_PACKAGE:"P3",DESCRIPTION_EN:"Ammonia Water Tank Farm",UNIT_NO_SEI_CODE:"166800",UNIT_NO_EM:"70"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 17",CONTENT_OF_BID_SECTION:"P3",CODE:"S17",BID_SECTION_DESCR:"Loading/Unloading Station",UNIT:178000,LOA_PLAN:"2022-07-04",CONTRACT_SIGN_PLAN:"2022-07-27",COMMENCE_SITEWORK_PLAN:"2022-07-27",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"China National Chemical Engineering Co. Ltd. 13th Company (CNCEC)",AWARD_SUBCONTRACTOR_CODE:"CNCEC",CONSTRUCTION_PACKAGE:"P3",DESCRIPTION_EN:"Loading/Unloading Station",UNIT_NO_SEI_CODE:"178000",UNIT_NO_EM:"89"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 17",CONTENT_OF_BID_SECTION:"P3",CODE:"S17",BID_SECTION_DESCR:"Storm Water Pump Station and Accident Buffer Basin",UNIT:180600,LOA_PLAN:"2022-07-04",CONTRACT_SIGN_PLAN:"2022-07-27",COMMENCE_SITEWORK_PLAN:"2022-07-27",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"China National Chemical Engineering Co. Ltd. 13th Company (CNCEC)",AWARD_SUBCONTRACTOR_CODE:"CNCEC",CONSTRUCTION_PACKAGE:"P3",DESCRIPTION_EN:"Storm Water Pump Station and Accident Buffer Basin",UNIT_NO_SEI_CODE:"180600",UNIT_NO_EM:"77"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 18",CONTENT_OF_BID_SECTION:"P3",CODE:"S18",BID_SECTION_DESCR:"Overall Interconnecting Pipe (A.G.)1",UNIT:179000,LOA_PLAN:"2022-07-12",CONTRACT_SIGN_PLAN:"2022-07-27",COMMENCE_SITEWORK_PLAN:"2022-07-27",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"BEHINDPLAN",AWARD_BID_APPROVAL_STATUS:"BEHINDPLAN",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Sinopec Fifth Construction Company (SFCC)",AWARD_SUBCONTRACTOR_CODE:"SFCC",CONSTRUCTION_PACKAGE:"P3",DESCRIPTION_EN:"Overall Interconnecting Pipe (A.G.)1",UNIT_NO_SEI_CODE:"179000",UNIT_NO_EM:"66"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 18",CONTENT_OF_BID_SECTION:"P3",CODE:"S18",BID_SECTION_DESCR:"Overall Electrial System",UNIT:183300,LOA_PLAN:"2022-07-12",CONTRACT_SIGN_PLAN:"2022-07-27",COMMENCE_SITEWORK_PLAN:"2022-07-27",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"BEHINDPLAN",AWARD_BID_APPROVAL_STATUS:"BEHINDPLAN",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Sinopec Fifth Construction Company (SFCC)",AWARD_SUBCONTRACTOR_CODE:"SFCC",CONSTRUCTION_PACKAGE:"P3",DESCRIPTION_EN:"Overall Electrial System",UNIT_NO_SEI_CODE:"183300",UNIT_NO_EM:"67"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 18",CONTENT_OF_BID_SECTION:"P3",CODE:"S18",BID_SECTION_DESCR:"Overall Electrical Monitoring and Control System",UNIT:183500,LOA_PLAN:"2022-07-12",CONTRACT_SIGN_PLAN:"2022-07-27",COMMENCE_SITEWORK_PLAN:"2022-07-27",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"BEHINDPLAN",AWARD_BID_APPROVAL_STATUS:"BEHINDPLAN",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Sinopec Fifth Construction Company (SFCC)",AWARD_SUBCONTRACTOR_CODE:"SFCC",CONSTRUCTION_PACKAGE:"P3",DESCRIPTION_EN:"Overall Electrical Monitoring and Control System",UNIT_NO_SEI_CODE:"183500",UNIT_NO_EM:"67"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 18",CONTENT_OF_BID_SECTION:"P3",CODE:"S18",BID_SECTION_DESCR:"Overall Telecom System",UNIT:187500,LOA_PLAN:"2022-07-12",CONTRACT_SIGN_PLAN:"2022-07-27",COMMENCE_SITEWORK_PLAN:"2022-07-27",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"BEHINDPLAN",AWARD_BID_APPROVAL_STATUS:"BEHINDPLAN",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Sinopec Fifth Construction Company (SFCC)",AWARD_SUBCONTRACTOR_CODE:"SFCC",CONSTRUCTION_PACKAGE:"P3",DESCRIPTION_EN:"Overall Telecom System",UNIT_NO_SEI_CODE:"187500",UNIT_NO_EM:"68"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 18",CONTENT_OF_BID_SECTION:"P3",CODE:"S18",BID_SECTION_DESCR:"Overall Instrument System",UNIT:186100,LOA_PLAN:"2022-07-12",CONTRACT_SIGN_PLAN:"2022-07-27",COMMENCE_SITEWORK_PLAN:"2022-07-27",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"BEHINDPLAN",AWARD_BID_APPROVAL_STATUS:"BEHINDPLAN",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Sinopec Fifth Construction Company (SFCC)",AWARD_SUBCONTRACTOR_CODE:"SFCC",CONSTRUCTION_PACKAGE:"P3",DESCRIPTION_EN:"Overall Instrument System",UNIT_NO_SEI_CODE:"186100",UNIT_NO_EM:"69"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 19",CONTENT_OF_BID_SECTION:"P3",CODE:"S19",BID_SECTION_DESCR:"Overall Interconnecting Pipe (A.G.) 2",UNIT:179100,LOA_PLAN:"2022-07-12",CONTRACT_SIGN_PLAN:"2022-07-27",COMMENCE_SITEWORK_PLAN:"2022-07-27",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Shanxi Petroleum (SPC)",AWARD_SUBCONTRACTOR_CODE:"SPC",CONSTRUCTION_PACKAGE:"P3",DESCRIPTION_EN:"Overall Interconnecting Pipe (A.G.) 2",UNIT_NO_SEI_CODE:"179100",UNIT_NO_EM:"66"},
          {CATEGORY:"Civil and Installation",BID_SECTION_NO:"Bid Section 19",CONTENT_OF_BID_SECTION:"P3",CODE:"S19",BID_SECTION_DESCR:"Eastward Pipeline",UNIT:190400,LOA_PLAN:"2022-07-12",CONTRACT_SIGN_PLAN:"2022-07-27",COMMENCE_SITEWORK_PLAN:"2022-07-27",ISSUE_ITB_ACTUAL:"Complete",ISSUE_ITB_STATUS:"COMPLETE",BID_EVALUATION_STATUS:"COMPLETE",AWARD_BID_APPROVAL_STATUS:"COMPLETE",LOA_STATUS:"COMPLETE",AWARD_SUBCONTRACTOR:"Shanxi Petroleum (SPC)",AWARD_SUBCONTRACTOR_CODE:"SPC",CONSTRUCTION_PACKAGE:"P3",DESCRIPTION_EN:"Eastward Pipeline   ",UNIT_NO_SEI_CODE:"190400",UNIT_NO_EM:"56"},
          {CONSTRUCTION_PACKAGE:"Overall",DESCRIPTION_EN:"Heavy lifting"},
          {CONSTRUCTION_PACKAGE:"CO-TCF",DESCRIPTION_EN:"CO-Site Temporary Construction Facilities(TCF) extension,including office building and membrane structure corridor"}
        ],
        SubconExecution:[
          {CODE:"CC7"},
          {CODE:"CNCEC"},
          {CODE:"FCC"},
          {CODE:"GDHJ"},
          {CODE:"HNEDI"},
          {CODE:"HZDJ"},
          {CODE:"NA"},
          {CODE:"SFCC"},
          {CODE:"SLT"},
          {CODE:"SNEI"},
          {CODE:"SPC"},
          {CODE:"STCC"},
          {CODE:"SYPEC"},
          {CODE:"WDECE"},
          {CODE:"ZGLY"}
        ],
        QualityStation: [
          {CODE:"Area1",PILING:" GDPQS",OTHERS:" PPQS",UNIT_NO_SEI_CODE:"127900",DESCRIPTION_EN:"Olefins Unit（include  Furnaces Area)",EXECUTION_MANAGER:"Olefins Recovery烯烃回收25-Steve Qin, Furnace 裂解炉10-Fournier Nicholas Michael"},
          {CODE:"Area1",PILING:" GDPQS",OTHERS:" PPQS",UNIT_NO_SEI_CODE:"127700",DESCRIPTION_EN:"Butadiene Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"Area1",PILING:" GDPQS",OTHERS:" PPQS",UNIT_NO_SEI_CODE:"127700",DESCRIPTION_EN:"Butadiene Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"Area1",PILING:" GDPQS",OTHERS:" PPQS",UNIT_NO_SEI_CODE:"127700",DESCRIPTION_EN:"Butadiene Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"Area1",PILING:" GDPQS",OTHERS:" PPQS",UNIT_NO_SEI_CODE:"127700",DESCRIPTION_EN:"Butadiene Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"Area1",PILING:" GDPQS",OTHERS:" PPQS",UNIT_NO_SEI_CODE:"127700",DESCRIPTION_EN:"Butadiene Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"Area1",PILING:" GDPQS",OTHERS:" PPQS",UNIT_NO_SEI_CODE:"127700",DESCRIPTION_EN:"Butadiene Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"Area2",PILING:" GDPQS",OTHERS:" PPQS",UNIT_NO_SEI_CODE:"136200",DESCRIPTION_EN:"Di-Isobutylene Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"Area2",PILING:" GDPQS",OTHERS:" PPQS",UNIT_NO_SEI_CODE:"136200",DESCRIPTION_EN:"Di-Isobutylene Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"Area2",PILING:" GDPQS",OTHERS:" PPQS",UNIT_NO_SEI_CODE:"136200",DESCRIPTION_EN:"Di-Isobutylene Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"Area2",PILING:" GDPQS",OTHERS:" PPQS",UNIT_NO_SEI_CODE:"136200",DESCRIPTION_EN:"Di-Isobutylene Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"Area2",PILING:" GDPQS",OTHERS:" PPQS",UNIT_NO_SEI_CODE:"136200",DESCRIPTION_EN:"Di-Isobutylene Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"Area2",PILING:" GDPQS",OTHERS:" PPQS",UNIT_NO_SEI_CODE:"136200",DESCRIPTION_EN:"Di-Isobutylene Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"Area3",PILING:" GDPQS",OTHERS:" PPQS",UNIT_NO_SEI_CODE:"127800",DESCRIPTION_EN:"Gasoline Hydrotreating Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"Area3",PILING:" GDPQS",OTHERS:" PPQS",UNIT_NO_SEI_CODE:"127800",DESCRIPTION_EN:"Gasoline Hydrotreating Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"Area3",PILING:" GDPQS",OTHERS:" PPQS",UNIT_NO_SEI_CODE:"127800",DESCRIPTION_EN:"Gasoline Hydrotreating Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"Area3",PILING:" GDPQS",OTHERS:" PPQS",UNIT_NO_SEI_CODE:"127800",DESCRIPTION_EN:"Gasoline Hydrotreating Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"Area3",PILING:" GDPQS",OTHERS:" PPQS",UNIT_NO_SEI_CODE:"127800",DESCRIPTION_EN:"Gasoline Hydrotreating Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"Area3",PILING:" GDPQS",OTHERS:" PPQS",UNIT_NO_SEI_CODE:"127800",DESCRIPTION_EN:"Gasoline Hydrotreating Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"Area3",PILING:" GDPQS",OTHERS:" PPQS",UNIT_NO_SEI_CODE:"111300",DESCRIPTION_EN:"Benzene Toluene Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"Area4",PILING:" GDPQS",OTHERS:" PPQS",UNIT_NO_SEI_CODE:"111300",DESCRIPTION_EN:"Benzene Toluene Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"Area4",PILING:" GDPQS",OTHERS:" PPQS",UNIT_NO_SEI_CODE:"111300",DESCRIPTION_EN:"Benzene Toluene Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"Area4",PILING:" GDPQS",OTHERS:" PPQS",UNIT_NO_SEI_CODE:"111300",DESCRIPTION_EN:"Benzene Toluene Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"Area4",PILING:" GDPQS",OTHERS:" PPQS",UNIT_NO_SEI_CODE:"111300",DESCRIPTION_EN:"Benzene Toluene Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"Area4",PILING:" GDPQS",OTHERS:" PPQS",UNIT_NO_SEI_CODE:"111300",DESCRIPTION_EN:"Benzene Toluene Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"Area4",PILING:" GDPQS",OTHERS:" PPQS",UNIT_NO_SEI_CODE:"184800",DESCRIPTION_EN:"Boiler Unit",EXECUTION_MANAGER:"Fournier Nicholas Michael"},
          {CODE:"Area4",PILING:" GDPQS",OTHERS:" PPQS",UNIT_NO_SEI_CODE:"184800",DESCRIPTION_EN:"Boiler Unit",EXECUTION_MANAGER:"Fournier Nicholas Michael"},
          {CODE:"Area4",PILING:" GDPQS",OTHERS:" PPQS",UNIT_NO_SEI_CODE:"184800",DESCRIPTION_EN:"Boiler Unit",EXECUTION_MANAGER:"Fournier Nicholas Michael"},
          {CODE:"Area4",PILING:" GDPQS",OTHERS:" PPQS",UNIT_NO_SEI_CODE:"184800",DESCRIPTION_EN:"Boiler Unit",EXECUTION_MANAGER:"Fournier Nicholas Michael"},
          {CODE:"Area4",PILING:" GDPQS",OTHERS:" PPQS",UNIT_NO_SEI_CODE:"184800",DESCRIPTION_EN:"Boiler Unit",EXECUTION_MANAGER:"Fournier Nicholas Michael"}
        ],
        Supervision: [
          {CODE:"WP1",JL_COMPANY:"Nanjing Yangzi Petrochemical Engineering Surveillance Co.,Ltd",CSE:"  Suo Qingkai",UNIT_NO_SEI_CODE:"127900",DESCRIPTION_EN:"Olefins UnitincludeFurnaces Area)",EXECUTION_MANAGER:"Olefins Recovery"},
          {CODE:"WP1",JL_COMPANY:"Beijign Huaxia Supervision Co., Ltd.",CSE:" Xue Guangzeng",UNIT_NO_SEI_CODE:"127700",DESCRIPTION_EN:"Butadiene Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"WP1",JL_COMPANY:"Beijign Huaxia Supervision Co., Ltd.",CSE:" Xue Guangzeng",UNIT_NO_SEI_CODE:"127700",DESCRIPTION_EN:"Butadiene Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"WP1",JL_COMPANY:"Beijign Huaxia Supervision Co., Ltd.",CSE:" Xue Guangzeng",UNIT_NO_SEI_CODE:"127700",DESCRIPTION_EN:"Butadiene Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"WP1",JL_COMPANY:"Beijign Huaxia Supervision Co., Ltd.",CSE:" Xue Guangzeng",UNIT_NO_SEI_CODE:"127700",DESCRIPTION_EN:"Butadiene Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"WP1",JL_COMPANY:"Beijign Huaxia Supervision Co., Ltd.",CSE:" Xue Guangzeng",UNIT_NO_SEI_CODE:"127700",DESCRIPTION_EN:"Butadiene Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"WP1",JL_COMPANY:"Beijign Huaxia Supervision Co., Ltd.",CSE:" Xue Guangzeng",UNIT_NO_SEI_CODE:"127700",DESCRIPTION_EN:"Butadiene Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"WP1",JL_COMPANY:"Beijign Huaxia Supervision Co., Ltd.",CSE:" Xue Guangzeng",UNIT_NO_SEI_CODE:"136200",DESCRIPTION_EN:"Di-Isobutylene Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"WP1",JL_COMPANY:"Beijign Huaxia Supervision Co., Ltd.",CSE:" Xue Guangzeng",UNIT_NO_SEI_CODE:"136200",DESCRIPTION_EN:"Di-Isobutylene Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"WP1",JL_COMPANY:"Beijign Huaxia Supervision Co., Ltd.",CSE:" Xue Guangzeng",UNIT_NO_SEI_CODE:"136200",DESCRIPTION_EN:"Di-Isobutylene Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"WP1",JL_COMPANY:"Beijign Huaxia Supervision Co., Ltd.",CSE:" Xue Guangzeng",UNIT_NO_SEI_CODE:"136200",DESCRIPTION_EN:"Di-Isobutylene Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"WP1",JL_COMPANY:"Beijign Huaxia Supervision Co., Ltd.",CSE:" Xue Guangzeng",UNIT_NO_SEI_CODE:"136200",DESCRIPTION_EN:"Di-Isobutylene Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"WP1",JL_COMPANY:"Beijign Huaxia Supervision Co., Ltd.",CSE:" Xue Guangzeng",UNIT_NO_SEI_CODE:"136200",DESCRIPTION_EN:"Di-Isobutylene Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"WP1",JL_COMPANY:"Beijign Huaxia Supervision Co., Ltd.",CSE:" Xue Guangzeng",UNIT_NO_SEI_CODE:"127800",DESCRIPTION_EN:"Gasoline Hydrotreating Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"WP2",JL_COMPANY:"Beijign Huaxia Supervision Co., Ltd.",CSE:" Xue Guangzeng",UNIT_NO_SEI_CODE:"127800",DESCRIPTION_EN:"Gasoline Hydrotreating Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"WP2",JL_COMPANY:"Beijign Huaxia Supervision Co., Ltd.",CSE:" Xue Guangzeng",UNIT_NO_SEI_CODE:"127800",DESCRIPTION_EN:"Gasoline Hydrotreating Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"WP2",JL_COMPANY:"Beijign Huaxia Supervision Co., Ltd.",CSE:" Xue Guangzeng",UNIT_NO_SEI_CODE:"127800",DESCRIPTION_EN:"Gasoline Hydrotreating Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"WP2",JL_COMPANY:"Beijign Huaxia Supervision Co., Ltd.",CSE:" Xue Guangzeng",UNIT_NO_SEI_CODE:"127800",DESCRIPTION_EN:"Gasoline Hydrotreating Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"WP2",JL_COMPANY:"Beijign Huaxia Supervision Co., Ltd.",CSE:" Xue Guangzeng",UNIT_NO_SEI_CODE:"127800",DESCRIPTION_EN:"Gasoline Hydrotreating Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"WP2",JL_COMPANY:"Beijign Huaxia Supervision Co., Ltd.",CSE:" Xue Guangzeng",UNIT_NO_SEI_CODE:"111300",DESCRIPTION_EN:"Benzene Toluene Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"WP3",JL_COMPANY:"Beijign Huaxia Supervision Co., Ltd.",CSE:" Xue Guangzeng",UNIT_NO_SEI_CODE:"111300",DESCRIPTION_EN:"Benzene Toluene Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"WP3",JL_COMPANY:"Beijign Huaxia Supervision Co., Ltd.",CSE:" Xue Guangzeng",UNIT_NO_SEI_CODE:"111300",DESCRIPTION_EN:"Benzene Toluene Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"WP3",JL_COMPANY:"Beijign Huaxia Supervision Co., Ltd.",CSE:" Xue Guangzeng",UNIT_NO_SEI_CODE:"111300",DESCRIPTION_EN:"Benzene Toluene Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"WP3",JL_COMPANY:"Beijign Huaxia Supervision Co., Ltd.",CSE:" Xue Guangzeng",UNIT_NO_SEI_CODE:"111300",DESCRIPTION_EN:"Benzene Toluene Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"WP4",JL_COMPANY:"Beijign Huaxia Supervision Co., Ltd.",CSE:" Xue Guangzeng",UNIT_NO_SEI_CODE:"111300",DESCRIPTION_EN:"Benzene Toluene Unit",EXECUTION_MANAGER:"Jafari Hamid"},
          {CODE:"WP4",JL_COMPANY:"Beijign Huaxia Supervision Co., Ltd.",CSE:" Xue Guangzeng",UNIT_NO_SEI_CODE:"184800",DESCRIPTION_EN:"Boiler Unit",EXECUTION_MANAGER:"Fournier Nicholas Michael"},
          {CODE:"WP4",JL_COMPANY:"Beijign Huaxia Supervision Co., Ltd.",CSE:" Xue Guangzeng",UNIT_NO_SEI_CODE:"184800",DESCRIPTION_EN:"Boiler Unit",EXECUTION_MANAGER:"Fournier Nicholas Michael"},
          {CODE:"WP4",JL_COMPANY:"Beijign Huaxia Supervision Co., Ltd.",CSE:" Xue Guangzeng",UNIT_NO_SEI_CODE:"184800",DESCRIPTION_EN:"Boiler Unit",EXECUTION_MANAGER:"Fournier Nicholas Michael"},
          {CODE:"WP4",JL_COMPANY:"Beijign Huaxia Supervision Co., Ltd.",CSE:" Xue Guangzeng",UNIT_NO_SEI_CODE:"184800",DESCRIPTION_EN:"Boiler Unit",EXECUTION_MANAGER:"Fournier Nicholas Michael"},
          {CODE:"WP5",JL_COMPANY:"Beijign Huaxia Supervision Co., Ltd.",CSE:" Xue Guangzeng",UNIT_NO_SEI_CODE:"184800",DESCRIPTION_EN:"Boiler Unit",EXECUTION_MANAGER:"Fournier Nicholas Michael"},
          {CODE:"WP5",JL_COMPANY:"Beijign Huaxia Supervision Co., Ltd.",CSE:" Xue Guangzeng",UNIT_NO_SEI_CODE:"184800",DESCRIPTION_EN:"Boiler Unit",EXECUTION_MANAGER:"Fournier Nicholas Michael"},
          {CODE:"WP5",JL_COMPANY:"Beijign Huaxia Supervision Co., Ltd.",CSE:" Xue Guangzeng",UNIT_NO_SEI_CODE:"184300",DESCRIPTION_EN:"Demineralized Water Unit",EXECUTION_MANAGER:"Fournier Nicholas Michael"},
          {CODE:"WP5",JL_COMPANY:"Beijign Huaxia Supervision Co., Ltd.",CSE:" Xue Guangzeng",UNIT_NO_SEI_CODE:"184300",DESCRIPTION_EN:"Demineralized Water Unit",EXECUTION_MANAGER:"Fournier Nicholas Michael"},
          {CODE:"WP5",JL_COMPANY:"Beijign Huaxia Supervision Co., Ltd.",CSE:" Xue Guangzeng",UNIT_NO_SEI_CODE:"184300",DESCRIPTION_EN:"Demineralized Water Unit",EXECUTION_MANAGER:"Fournier Nicholas Michael"},
          {CODE:"WP5",JL_COMPANY:"Beijign Huaxia Supervision Co., Ltd.",CSE:" Xue Guangzeng",UNIT_NO_SEI_CODE:"184300",DESCRIPTION_EN:"Demineralized Water Unit",EXECUTION_MANAGER:"Fournier Nicholas Michael"},
          {CODE:"WP5",JL_COMPANY:"Beijign Huaxia Supervision Co., Ltd.",CSE:" Xue Guangzeng",UNIT_NO_SEI_CODE:"184300",DESCRIPTION_EN:"Demineralized Water Unit",EXECUTION_MANAGER:"Fournier Nicholas Michael"},
          {CODE:"WP5",JL_COMPANY:"Beijign Huaxia Supervision Co., Ltd.",CSE:" Xue Guangzeng",UNIT_NO_SEI_CODE:"184300",DESCRIPTION_EN:"Demineralized Water Unit",EXECUTION_MANAGER:"Fournier Nicholas Michael"},
          {CODE:"WP6",JL_COMPANY:"Guangdong Gosun Engineering Management Co., Ltd.",CSE:" Liu Jingyi",UNIT_NO_SEI_CODE:"121400",DESCRIPTION_EN:"Low Density Polyethylene Unit",EXECUTION_MANAGER:"Quek SHU YAN"},
          {CODE:"WP6",JL_COMPANY:"Guangdong Gosun Engineering Management Co., Ltd.",CSE:" Liu Jingyi",UNIT_NO_SEI_CODE:"121300",DESCRIPTION_EN:"Gas Phase Polyethylene Unit",EXECUTION_MANAGER:"Syhlonyk, Daylen Ward"},
          {CODE:"WP6",JL_COMPANY:"Guangdong Gosun Engineering Management Co., Ltd.",CSE:" Liu Jingyi",UNIT_NO_SEI_CODE:"121300",DESCRIPTION_EN:"Gas Phase Polyethylene Unit",EXECUTION_MANAGER:"Syhlonyk, Daylen Ward"},
          {CODE:"WP7",JL_COMPANY:"Guangdong Gosun Engineering Management Co., Ltd.",CSE:" Liu Jingyi",UNIT_NO_SEI_CODE:"121300",DESCRIPTION_EN:"Gas Phase Polyethylene Unit",EXECUTION_MANAGER:"Syhlonyk, Daylen Ward"},
          {CODE:"WP7",JL_COMPANY:"Guangdong Gosun Engineering Management Co., Ltd.",CSE:" Liu Jingyi",UNIT_NO_SEI_CODE:"121300",DESCRIPTION_EN:"Gas Phase Polyethylene Unit",EXECUTION_MANAGER:"Syhlonyk, Daylen Ward"},
          {CODE:"WP7",JL_COMPANY:"Guangdong Gosun Engineering Management Co., Ltd.",CSE:" Liu Jingyi",UNIT_NO_SEI_CODE:"121300",DESCRIPTION_EN:"Gas Phase Polyethylene Unit",EXECUTION_MANAGER:"Syhlonyk, Daylen Ward"},
          {CODE:"WP7",JL_COMPANY:"Guangdong Gosun Engineering Management Co., Ltd.",CSE:" Liu Jingyi",UNIT_NO_SEI_CODE:"121300",DESCRIPTION_EN:"Gas Phase Polyethylene Unit",EXECUTION_MANAGER:"Syhlonyk, Daylen Ward"},
          {CODE:"WP8",JL_COMPANY:"Guangdong Gosun Engineering Management Co., Ltd.",CSE:" Liu Jingyi",UNIT_NO_SEI_CODE:"121400",DESCRIPTION_EN:"Low Density Polyethylene Unit",EXECUTION_MANAGER:"Quek SHU YAN"},
          {CODE:"WP8",JL_COMPANY:"Guangdong Gosun Engineering Management Co., Ltd.",CSE:" Liu Jingyi",UNIT_NO_SEI_CODE:"121300",DESCRIPTION_EN:"Gas Phase Polyethylene Unit",EXECUTION_MANAGER:"Syhlonyk, Daylen Ward"},
          {CODE:"WP8",JL_COMPANY:"Guangdong Gosun Engineering Management Co., Ltd.",CSE:" Liu Jingyi",UNIT_NO_SEI_CODE:"121300",DESCRIPTION_EN:"Gas Phase Polyethylene Unit",EXECUTION_MANAGER:"Syhlonyk, Daylen Ward"},
          {CODE:"WP8",JL_COMPANY:"Guangdong Gosun Engineering Management Co., Ltd.",CSE:" Liu Jingyi",UNIT_NO_SEI_CODE:"121300",DESCRIPTION_EN:"Gas Phase Polyethylene Unit",EXECUTION_MANAGER:"Syhlonyk, Daylen Ward"},
          {CODE:"WP8",JL_COMPANY:"Guangdong Gosun Engineering Management Co., Ltd.",CSE:" Liu Jingyi",UNIT_NO_SEI_CODE:"121300",DESCRIPTION_EN:"Gas Phase Polyethylene Unit",EXECUTION_MANAGER:"Syhlonyk, Daylen Ward"},
          {CODE:"WP8",JL_COMPANY:"Guangdong Gosun Engineering Management Co., Ltd.",CSE:" Liu Jingyi",UNIT_NO_SEI_CODE:"121300",DESCRIPTION_EN:"Gas Phase Polyethylene Unit",EXECUTION_MANAGER:"Syhlonyk, Daylen Ward"}
        ],
      }
    ]
}