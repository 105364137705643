/* 
  ### Customized Chart Components ### 
  Predefined component's behaviors for the Dashboard
*/

// Summaries
  import J2_Cube_A_Data                   from '../09_Components/Summaries/Cube_A/includes/primitives/data'
  import J2_Cube_B_Data                   from '../09_Components/Summaries/Cube_B/includes/primitives/data'
  import J2_Cube_C_Data                   from '../09_Components/Summaries/Cube_C/includes/primitives/data'
  import J2_Cube_BIND_Data                from '../09_Components/Summaries/Cube_BIND/includes/primitives/data'
  import J2_Sentence_A_Data               from '../09_Components/Summaries/Sentence_A/includes/primitives/data'
  import J2_Week_Performance_Data         from '../09_Components/Summaries/Week_Performance/includes/primitives/data'
  import J2_Week_Performance_UNIT_Data    from '../09_Components/Summaries/Week_Performance_UNIT/includes/primitives/data'
    
// Data Tables    
  import J2_DataTable_A_Data              from '../09_Components/DataTables/DataTable_A/includes/primitives/data'
  import J2_DataTable_B_Data              from '../09_Components/DataTables/DataTable_B/includes/primitives/data'
    
// Table Charts    
  import J2_Punch_Data                    from '../09_Components/TableCharts/Punch/includes/primitives/data'
  import J2_Progress_Data                 from '../09_Components/TableCharts/Progress/includes/primitives/data'
    
// Charts    
  import J2_Tube_A_Data                   from '../09_Components/CustomCharts/Tube/Normal/includes/primitives/data'
  import J2_Tube_UNIT_Data                from '../09_Components/CustomCharts/Tube_UNIT/Normal/includes/primitives/data'
  import J2_Gauge_Normal_Data             from '../09_Components/CustomCharts/Gauge/Normal/includes/primitives/data'
  import J2_Skyline_Month_A_Data          from '../09_Components/CustomCharts/Skyline/Month_A/includes/primitives/data'
    
// Pyramid Chart    
  import J2_Pyramid_Data                  from '../09_Components/Safety/Pyramid_R1/includes/primitives/data'

export default {
  // Summaries
    J2_Cube_A_Data,
    J2_Cube_B_Data,
    J2_Cube_C_Data,
    J2_Cube_BIND_Data,
    J2_Sentence_A_Data,
    J2_Week_Performance_Data,
    J2_Week_Performance_UNIT_Data,

  // Data Tables
    J2_DataTable_A_Data,
    J2_DataTable_B_Data,

  // Charts
    J2_Punch_Data,
    J2_Progress_Data,

  // Charts
    J2_Tube_A_Data,
    J2_Tube_UNIT_Data,
    J2_Gauge_Normal_Data,
    J2_Skyline_Month_A_Data,

  // Pyramid Chart
    J2_Pyramid_Data
}
