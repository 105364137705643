import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 330,
      CanvasHeight: 330,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     

    DataItems: [
                { CDATE: '2019-12-20', DISC: 'PI', ACTIVE: 944,   RAISED: 151,  CLOSED: 209,},
                { CDATE: '2019-12-27', DISC: 'PI', ACTIVE: 942,   RAISED: 69,   CLOSED: 71, },
                { CDATE: '2020-01-03', DISC: 'PI', ACTIVE: 951,   RAISED: 74,   CLOSED: 65, },
                { CDATE: '2020-01-10', DISC: 'PI', ACTIVE: 945,   RAISED: 82,   CLOSED: 88, },
                { CDATE: '2020-01-17', DISC: 'PI', ACTIVE: 958,   RAISED: 156,  CLOSED: 143,},
                { CDATE: '2020-01-24', DISC: 'PI', ACTIVE: 1003,  RAISED: 166,  CLOSED: 121,},
                { CDATE: '2020-01-31', DISC: 'PI', ACTIVE: 989,   RAISED: 61,   CLOSED: 75, },
                { CDATE: '2020-02-07', DISC: 'PI', ACTIVE: 1062,  RAISED: 179,  CLOSED: 106,},
              ],

    Queries: {
      SQL1: [
              { LINK1: 'PI', DISC: 'Piping', TOTAL: 1465, ACTUAL: 1204, REMAIN: 261, PROG: 82.18, },
            ],

  }
}