import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 1542,
    CanvasHeight: 4600,
    CanvasBorderWeight: 0.5,
    CanvasBorderColor: '#f5f5f5',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.2,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 860,
    CanvasChartHeight: 310,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: 'Transparent',
    CanvasChartFillColor: 'Transparent',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },

  // ----------------------------------------------- Filter Text
  Text: {
    Text: [
      { text: 'Module', x: 118, y: 50, font: 'roboto', class: 'regular', size: 20, color: '#44A9DF', align: 'start' },
    ],
    Filter: [
      { name: 'MOD'   , x: 40, y: 50, font: 'roboto', class: 'regular', size: 20, color: '#44A9DF', align: 'start' },
    ],
    Sql: [
      {
        query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
        text: 'PBF COMPESSOR AIR COOLER - TRAIN 4',
        x: 40,
        y: 67,
        font: 'roboto',
        style: 'regular',
        size: 14,
        color: '#757575',
        align: 'start'
      },
    ]
  },
  // -----------------------------------------------------------

  DataItems: [    
    {NO:61,FILTER:"TOPSIDES",MODULE:"ME04",MODULE_DESCR:"Power Generation",WCCODE:"16430",WCDESCRIPTION:"Cable Tray",CON_PROG:97.94,MC_PROG:38.4,PUNCH:4,PLAN_SD:"2021-03-31",PLAN_FD:"2021-06-26",PLAN_PROG:100,ACTUAL_SD:"2021-08-13",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:29,FILTER:"TOPSIDES",MODULE:"ME04",MODULE_DESCR:"Power Generation",WCCODE:"23110",WCDESCRIPTION:"Structure Painting",CON_PROG:97.94,MC_PROG:38.4,PUNCH:4,PLAN_SD:"2020-11-28",PLAN_FD:"2021-06-28",PLAN_PROG:100,ACTUAL_SD:"2020-10-30",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:3,FILTER:"TOPSIDES",MODULE:"ME04",MODULE_DESCR:"Power Generation",WCCODE:"36210",WCDESCRIPTION:"Block Pre-Fabrication",CON_PROG:97.94,MC_PROG:38.4,PUNCH:4,PLAN_SD:"2020-01-20",PLAN_FD:"2021-05-18",PLAN_PROG:100,ACTUAL_SD:"2020-03-13",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:41,FILTER:"TOPSIDES",MODULE:"ME04",MODULE_DESCR:"Power Generation",WCCODE:"13420",WCDESCRIPTION:"Piping Installation",CON_PROG:97.94,MC_PROG:38.4,PUNCH:4,PLAN_SD:"2021-03-02",PLAN_FD:"2021-09-13",PLAN_PROG:100,ACTUAL_SD:"2021-05-14",ACTUAL_PROG:98.14,CUTOFF:"2022-02-18"},
    {NO:44,FILTER:"TOPSIDES",MODULE:"ME04",MODULE_DESCR:"Power Generation",WCCODE:"13900",WCDESCRIPTION:"Hydro Test",CON_PROG:97.94,MC_PROG:38.4,PUNCH:4,PLAN_SD:"2021-04-21",PLAN_FD:"2021-10-29",PLAN_PROG:100,ACTUAL_SD:"2021-08-27",ACTUAL_PROG:82.48,CUTOFF:"2022-02-18"},
    {NO:14,FILTER:"TOPSIDES",MODULE:"ME04",MODULE_DESCR:"Power Generation",WCCODE:"36220",WCDESCRIPTION:"Block Assembly",CON_PROG:97.94,MC_PROG:38.4,PUNCH:4,PLAN_SD:"2020-07-01",PLAN_FD:"2021-07-01",PLAN_PROG:100,ACTUAL_SD:"2020-07-03",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},

    {NO:21,FILTER:"HULL & LQ",MODULE:"HULL-AFT",MODULE_DESCR:"HULL-AFT",WCCODE:"36130",WCDESCRIPTION:"Block Erection",CON_PROG:98.81,MC_PROG:50.5,PUNCH:41,PLAN_SD:"2019-12-14",PLAN_FD:"2021-05-11",PLAN_PROG:100,ACTUAL_SD:"2019-12-20",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:17,FILTER:"HULL & LQ",MODULE:"HULL-AFT",MODULE_DESCR:"HULL-AFT",WCCODE:"36110",WCDESCRIPTION:"Block Pre-Fabrication",CON_PROG:98.81,MC_PROG:50.5,PUNCH:41,PLAN_SD:"2020-01-15",PLAN_FD:"2021-02-26",PLAN_PROG:100,ACTUAL_SD:"2019-05-31",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:25,FILTER:"HULL & LQ",MODULE:"HULL-AFT",MODULE_DESCR:"HULL-AFT",WCCODE:"23110",WCDESCRIPTION:"Structure Painting",CON_PROG:98.81,MC_PROG:50.5,PUNCH:41,PLAN_SD:"2020-11-27",PLAN_FD:"2021-03-19",PLAN_PROG:100,ACTUAL_SD:"2019-08-30",ACTUAL_PROG:98.72,CUTOFF:"2022-02-18"},
    {NO:20,FILTER:"HULL & LQ",MODULE:"HULL-AFT",MODULE_DESCR:"HULL-AFT",WCCODE:"36120",WCDESCRIPTION:"Block Assembly",CON_PROG:98.81,MC_PROG:50.5,PUNCH:41,PLAN_SD:"2020-12-11",PLAN_FD:"2021-01-04",PLAN_PROG:100,ACTUAL_SD:"2019-09-27",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:51,FILTER:"HULL & LQ",MODULE:"HULL-AFT",MODULE_DESCR:"HULL-AFT",WCCODE:"13900",WCDESCRIPTION:"Hydro Test",CON_PROG:98.81,MC_PROG:50.5,PUNCH:41,PLAN_SD:"2021-07-04",PLAN_FD:"2021-12-18",PLAN_PROG:100,ACTUAL_SD:"2021-04-09",ACTUAL_PROG:68.79,CUTOFF:"2022-02-18"},
    {NO:34,FILTER:"HULL & LQ",MODULE:"HULL-AFT",MODULE_DESCR:"HULL-AFT",WCCODE:"13320",WCDESCRIPTION:"Piping Installation",CON_PROG:98.81,MC_PROG:50.5,PUNCH:41,PLAN_SD:"2020-01-20",PLAN_FD:"2021-06-07",PLAN_PROG:100,ACTUAL_SD:"2020-01-10",ACTUAL_PROG:97.68,CUTOFF:"2022-02-18"},
    {NO:54,FILTER:"HULL & LQ",MODULE:"HULL-AFT",MODULE_DESCR:"HULL-AFT",WCCODE:"16430",WCDESCRIPTION:"Cable Tray",CON_PROG:98.81,MC_PROG:50.5,PUNCH:41,PLAN_SD:"2020-05-18",PLAN_FD:"2021-02-07",PLAN_PROG:100,ACTUAL_SD:"2020-05-08",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:22,FILTER:"HULL & LQ",MODULE:"HULL-FOR",MODULE_DESCR:"HULL-FOR",WCCODE:"36130",WCDESCRIPTION:"Block Erection",CON_PROG:99.03,MC_PROG:71.3,PUNCH:755,PLAN_SD:"2019-12-15",PLAN_FD:"2021-01-01",PLAN_PROG:100,ACTUAL_SD:"2019-12-20",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:52,FILTER:"HULL & LQ",MODULE:"HULL-FOR",MODULE_DESCR:"HULL-FOR",WCCODE:"13900",WCDESCRIPTION:"Hydro Test",CON_PROG:99.03,MC_PROG:71.3,PUNCH:755,PLAN_SD:"2021-03-01",PLAN_FD:"2022-02-09",PLAN_PROG:97.89,ACTUAL_SD:"2020-12-18",ACTUAL_PROG:76.01,CUTOFF:"2022-02-18"},
    {NO:18,FILTER:"HULL & LQ",MODULE:"HULL-FOR",MODULE_DESCR:"HULL-FOR",WCCODE:"36110",WCDESCRIPTION:"Block Pre-Fabrication",CON_PROG:99.03,MC_PROG:71.3,PUNCH:755,PLAN_SD:"2019-04-25",PLAN_FD:"2020-10-10",PLAN_PROG:100,ACTUAL_SD:"2019-05-10",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:26,FILTER:"HULL & LQ",MODULE:"HULL-FOR",MODULE_DESCR:"HULL-FOR",WCCODE:"23110",WCDESCRIPTION:"Structure Painting",CON_PROG:99.03,MC_PROG:71.3,PUNCH:755,PLAN_SD:"2019-07-24",PLAN_FD:"2020-11-30",PLAN_PROG:100,ACTUAL_SD:"2019-08-16",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:33,FILTER:"HULL & LQ",MODULE:"HULL-FOR",MODULE_DESCR:"HULL-FOR",WCCODE:"13320",WCDESCRIPTION:"Piping Installation",CON_PROG:99.03,MC_PROG:71.3,PUNCH:755,PLAN_SD:"2020-01-20",PLAN_FD:"2021-10-24",PLAN_PROG:100,ACTUAL_SD:"2020-01-10",ACTUAL_PROG:99.35,CUTOFF:"2022-02-18"},
    {NO:53,FILTER:"HULL & LQ",MODULE:"HULL-FOR",MODULE_DESCR:"HULL-FOR",WCCODE:"16430",WCDESCRIPTION:"Cable Tray",CON_PROG:99.03,MC_PROG:71.3,PUNCH:755,PLAN_SD:"2020-05-25",PLAN_FD:"2021-02-28",PLAN_PROG:100,ACTUAL_SD:"2020-05-15",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:19,FILTER:"HULL & LQ",MODULE:"HULL-FOR",MODULE_DESCR:"HULL-FOR",WCCODE:"36120",WCDESCRIPTION:"Block Assembly",CON_PROG:99.03,MC_PROG:71.3,PUNCH:755,PLAN_SD:"2019-09-09",PLAN_FD:"2020-12-09",PLAN_PROG:100,ACTUAL_SD:"2019-09-13",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:63,FILTER:"HULL & LQ",MODULE:"LQ",MODULE_DESCR:"LQ",WCCODE:"36110",WCDESCRIPTION:"Block Pre-Fabrication",CON_PROG:95,MC_PROG:56.4,PUNCH:826,PLAN_SD:"2019-12-20",PLAN_FD:"2020-12-18",PLAN_PROG:100,ACTUAL_SD:"2019-08-30",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:67,FILTER:"HULL & LQ",MODULE:"LQ",MODULE_DESCR:"LQ",WCCODE:"13900",WCDESCRIPTION:"Hydro Test",CON_PROG:95,MC_PROG:56.4,PUNCH:826,PLAN_SD:"2020-11-20",PLAN_FD:"2022-04-29",PLAN_PROG:85.93,ACTUAL_SD:"2020-12-11",ACTUAL_PROG:80.25,CUTOFF:"2022-02-18"},
    {NO:65,FILTER:"HULL & LQ",MODULE:"LQ",MODULE_DESCR:"LQ",WCCODE:"23110",WCDESCRIPTION:"Structure Painting",CON_PROG:95,MC_PROG:56.4,PUNCH:826,PLAN_SD:"2019-10-16",PLAN_FD:"2020-12-06",PLAN_PROG:100,ACTUAL_SD:"2019-10-18",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:68,FILTER:"HULL & LQ",MODULE:"LQ",MODULE_DESCR:"LQ",WCCODE:"16430",WCDESCRIPTION:"Cable Tray",CON_PROG:95,MC_PROG:56.4,PUNCH:826,PLAN_SD:"2021-07-20",PLAN_FD:"2021-08-18",PLAN_PROG:100,ACTUAL_SD:"2020-09-04",ACTUAL_PROG:98.66,CUTOFF:"2022-02-18"},
    {NO:66,FILTER:"HULL & LQ",MODULE:"LQ",MODULE_DESCR:"LQ",WCCODE:"13320",WCDESCRIPTION:"Piping Installation",CON_PROG:95,MC_PROG:56.4,PUNCH:826,PLAN_SD:"2020-06-10",PLAN_FD:"2021-08-18",PLAN_PROG:100,ACTUAL_SD:"2020-06-19",ACTUAL_PROG:99.99,CUTOFF:"2022-02-18"},
    {NO:64,FILTER:"HULL & LQ",MODULE:"LQ",MODULE_DESCR:"LQ",WCCODE:"36130",WCDESCRIPTION:"Block Erection",CON_PROG:95,MC_PROG:56.4,PUNCH:826,PLAN_SD:"2019-11-27",PLAN_FD:"2021-05-04",PLAN_PROG:100,ACTUAL_SD:"2019-12-20",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:27,FILTER:"TOPSIDES",MODULE:"ME01",MODULE_DESCR:"Flash Gas Compression",WCCODE:"23110",WCDESCRIPTION:"Structure Painting",CON_PROG:95.19,MC_PROG:21,PUNCH:56,PLAN_SD:"2021-01-16",PLAN_FD:"2021-08-30",PLAN_PROG:100,ACTUAL_SD:"2020-11-27",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:39,FILTER:"TOPSIDES",MODULE:"ME01",MODULE_DESCR:"Flash Gas Compression",WCCODE:"13420",WCDESCRIPTION:"Piping Installation",CON_PROG:95.19,MC_PROG:21,PUNCH:56,PLAN_SD:"2021-03-05",PLAN_FD:"2022-01-12",PLAN_PROG:100,ACTUAL_SD:"2021-04-02",ACTUAL_PROG:96.84,CUTOFF:"2022-02-18"},
    {NO:46,FILTER:"TOPSIDES",MODULE:"ME01",MODULE_DESCR:"Flash Gas Compression",WCCODE:"13900",WCDESCRIPTION:"Hydro Test",CON_PROG:95.19,MC_PROG:21,PUNCH:56,PLAN_SD:"2021-05-13",PLAN_FD:"2022-03-10",PLAN_PROG:37.53,ACTUAL_SD:"2021-10-08",ACTUAL_PROG:63.53,CUTOFF:"2022-02-18"},
    {NO:59,FILTER:"TOPSIDES",MODULE:"ME01",MODULE_DESCR:"Flash Gas Compression",WCCODE:"16430",WCDESCRIPTION:"Cable Tray",CON_PROG:95.19,MC_PROG:21,PUNCH:56,PLAN_SD:"2021-06-02",PLAN_FD:"2021-08-18",PLAN_PROG:100,ACTUAL_SD:"2021-10-15",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:1,FILTER:"TOPSIDES",MODULE:"ME01",MODULE_DESCR:"Flash Gas Compression",WCCODE:"36210",WCDESCRIPTION:"Block Pre-Fabrication",CON_PROG:95.19,MC_PROG:21,PUNCH:56,PLAN_SD:"2020-03-29",PLAN_FD:"2021-07-15",PLAN_PROG:100,ACTUAL_SD:"2020-03-27",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:16,FILTER:"TOPSIDES",MODULE:"ME01",MODULE_DESCR:"Flash Gas Compression",WCCODE:"36220",WCDESCRIPTION:"Block Assembly",CON_PROG:95.19,MC_PROG:21,PUNCH:56,PLAN_SD:"2020-07-29",PLAN_FD:"2021-10-14",PLAN_PROG:100,ACTUAL_SD:"2020-07-31",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:40,FILTER:"TOPSIDES",MODULE:"ME02",MODULE_DESCR:"Meg & Fuel Gas",WCCODE:"13420",WCDESCRIPTION:"Piping Installation",CON_PROG:93.92,MC_PROG:20.6,PUNCH:28,PLAN_SD:"2021-03-10",PLAN_FD:"2022-01-08",PLAN_PROG:100,ACTUAL_SD:"2021-04-09",ACTUAL_PROG:97.23,CUTOFF:"2022-02-18"},
    {NO:60,FILTER:"TOPSIDES",MODULE:"ME02",MODULE_DESCR:"Meg & Fuel Gas",WCCODE:"16430",WCDESCRIPTION:"Cable Tray",CON_PROG:93.92,MC_PROG:20.6,PUNCH:28,PLAN_SD:"2021-05-19",PLAN_FD:"2021-08-20",PLAN_PROG:100,ACTUAL_SD:"2021-10-08",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:2,FILTER:"TOPSIDES",MODULE:"ME02",MODULE_DESCR:"Meg & Fuel Gas",WCCODE:"36210",WCDESCRIPTION:"Block Pre-Fabrication",CON_PROG:93.92,MC_PROG:20.6,PUNCH:28,PLAN_SD:"2020-03-20",PLAN_FD:"2021-07-15",PLAN_PROG:100,ACTUAL_SD:"2020-03-20",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:28,FILTER:"TOPSIDES",MODULE:"ME02",MODULE_DESCR:"Meg & Fuel Gas",WCCODE:"23110",WCDESCRIPTION:"Structure Painting",CON_PROG:93.92,MC_PROG:20.6,PUNCH:28,PLAN_SD:"2020-12-23",PLAN_FD:"2021-08-10",PLAN_PROG:100,ACTUAL_SD:"2020-11-27",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:15,FILTER:"TOPSIDES",MODULE:"ME02",MODULE_DESCR:"Meg & Fuel Gas",WCCODE:"36220",WCDESCRIPTION:"Block Assembly",CON_PROG:93.92,MC_PROG:20.6,PUNCH:28,PLAN_SD:"2020-07-15",PLAN_FD:"2021-10-14",PLAN_PROG:100,ACTUAL_SD:"2020-07-17",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:45,FILTER:"TOPSIDES",MODULE:"ME02",MODULE_DESCR:"Meg & Fuel Gas",WCCODE:"13900",WCDESCRIPTION:"Hydro Test",CON_PROG:93.92,MC_PROG:20.6,PUNCH:28,PLAN_SD:"2021-05-10",PLAN_FD:"2022-03-09",PLAN_PROG:38.32,ACTUAL_SD:"2021-10-22",ACTUAL_PROG:60.18,CUTOFF:"2022-02-18"},
    {NO:30,FILTER:"TOPSIDES",MODULE:"ME05",MODULE_DESCR:"E&I Building",WCCODE:"23110",WCDESCRIPTION:"Structure Painting",CON_PROG:98.7,MC_PROG:33.6,PUNCH:160,PLAN_SD:"2020-10-17",PLAN_FD:"2021-06-05",PLAN_PROG:100,ACTUAL_SD:"2020-10-30",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:62,FILTER:"TOPSIDES",MODULE:"ME05",MODULE_DESCR:"E&I Building",WCCODE:"16430",WCDESCRIPTION:"Cable Tray",CON_PROG:98.7,MC_PROG:33.6,PUNCH:160,PLAN_SD:"2021-03-23",PLAN_FD:"2021-06-17",PLAN_PROG:100,ACTUAL_SD:"2021-06-25",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:43,FILTER:"TOPSIDES",MODULE:"ME05",MODULE_DESCR:"E&I Building",WCCODE:"13900",WCDESCRIPTION:"Hydro Test",CON_PROG:98.7,MC_PROG:33.6,PUNCH:160,PLAN_SD:"2021-05-17",PLAN_FD:"2021-10-09",PLAN_PROG:100,ACTUAL_SD:"2021-08-27",ACTUAL_PROG:79.19,CUTOFF:"2022-02-18"},
    {NO:42,FILTER:"TOPSIDES",MODULE:"ME05",MODULE_DESCR:"E&I Building",WCCODE:"13420",WCDESCRIPTION:"Piping Installation",CON_PROG:98.7,MC_PROG:33.6,PUNCH:160,PLAN_SD:"2021-03-08",PLAN_FD:"2021-09-07",PLAN_PROG:100,ACTUAL_SD:"2021-05-28",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:4,FILTER:"TOPSIDES",MODULE:"ME05",MODULE_DESCR:"E&I Building",WCCODE:"36210",WCDESCRIPTION:"Block Pre-Fabrication",CON_PROG:98.7,MC_PROG:33.6,PUNCH:160,PLAN_SD:"2019-12-23",PLAN_FD:"2021-05-06",PLAN_PROG:100,ACTUAL_SD:"2020-03-13",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:13,FILTER:"TOPSIDES",MODULE:"ME05",MODULE_DESCR:"E&I Building",WCCODE:"36220",WCDESCRIPTION:"Block Assembly",CON_PROG:98.7,MC_PROG:33.6,PUNCH:160,PLAN_SD:"2020-05-26",PLAN_FD:"2021-06-30",PLAN_PROG:100,ACTUAL_SD:"2020-06-19",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:48,FILTER:"TOPSIDES",MODULE:"MW01",MODULE_DESCR:"Reception Facilities & Production Separation",WCCODE:"13900",WCDESCRIPTION:"Hydro Test",CON_PROG:92.49,MC_PROG:17.7,PUNCH:24,PLAN_SD:"2021-05-11",PLAN_FD:"2022-03-21",PLAN_PROG:37.53,ACTUAL_SD:"2021-09-24",ACTUAL_PROG:51.95,CUTOFF:"2022-02-18"},
    {NO:5,FILTER:"TOPSIDES",MODULE:"MW01",MODULE_DESCR:"Reception Facilities & Production Separation",WCCODE:"36210",WCDESCRIPTION:"Block Pre-Fabrication",CON_PROG:92.49,MC_PROG:17.7,PUNCH:24,PLAN_SD:"2020-02-25",PLAN_FD:"2021-07-12",PLAN_PROG:100,ACTUAL_SD:"2020-03-13",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:31,FILTER:"TOPSIDES",MODULE:"MW01",MODULE_DESCR:"Reception Facilities & Production Separation",WCCODE:"23110",WCDESCRIPTION:"Structure Painting",CON_PROG:92.49,MC_PROG:17.7,PUNCH:24,PLAN_SD:"2020-12-24",PLAN_FD:"2021-08-13",PLAN_PROG:100,ACTUAL_SD:"2020-11-27",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:37,FILTER:"TOPSIDES",MODULE:"MW01",MODULE_DESCR:"Reception Facilities & Production Separation",WCCODE:"13420",WCDESCRIPTION:"Piping Installation",CON_PROG:92.49,MC_PROG:17.7,PUNCH:24,PLAN_SD:"2021-03-17",PLAN_FD:"2022-01-25",PLAN_PROG:100,ACTUAL_SD:"2021-05-07",ACTUAL_PROG:96.56,CUTOFF:"2022-02-18"},
    {NO:57,FILTER:"TOPSIDES",MODULE:"MW01",MODULE_DESCR:"Reception Facilities & Production Separation",WCCODE:"16430",WCDESCRIPTION:"Cable Tray",CON_PROG:92.49,MC_PROG:17.7,PUNCH:24,PLAN_SD:"2021-05-17",PLAN_FD:"2021-08-30",PLAN_PROG:100,ACTUAL_SD:"2021-10-22",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:12,FILTER:"TOPSIDES",MODULE:"MW01",MODULE_DESCR:"Reception Facilities & Production Separation",WCCODE:"36220",WCDESCRIPTION:"Block Assembly",CON_PROG:92.49,MC_PROG:17.7,PUNCH:24,PLAN_SD:"2020-07-15",PLAN_FD:"2021-10-15",PLAN_PROG:100,ACTUAL_SD:"2020-07-17",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:47,FILTER:"TOPSIDES",MODULE:"MW02",MODULE_DESCR:"Hc Dewpointing & Condensate Stabilisation",WCCODE:"13900",WCDESCRIPTION:"Hydro Test",CON_PROG:93.47,MC_PROG:21.6,PUNCH:7,PLAN_SD:"2021-05-17",PLAN_FD:"2022-03-21",PLAN_PROG:37.53,ACTUAL_SD:"2021-09-24",ACTUAL_PROG:58.09,CUTOFF:"2022-02-18"},
    {NO:11,FILTER:"TOPSIDES",MODULE:"MW02",MODULE_DESCR:"Hc Dewpointing & Condensate Stabilisation",WCCODE:"36220",WCDESCRIPTION:"Block Assembly",CON_PROG:93.47,MC_PROG:21.6,PUNCH:7,PLAN_SD:"2020-08-06",PLAN_FD:"2021-10-15",PLAN_PROG:100,ACTUAL_SD:"2020-08-07",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:38,FILTER:"TOPSIDES",MODULE:"MW02",MODULE_DESCR:"Hc Dewpointing & Condensate Stabilisation",WCCODE:"13420",WCDESCRIPTION:"Piping Installation",CON_PROG:93.47,MC_PROG:21.6,PUNCH:7,PLAN_SD:"2021-03-17",PLAN_FD:"2022-01-25",PLAN_PROG:99.95,ACTUAL_SD:"2021-04-02",ACTUAL_PROG:97.4,CUTOFF:"2022-02-18"},
    {NO:6,FILTER:"TOPSIDES",MODULE:"MW02",MODULE_DESCR:"Hc Dewpointing & Condensate Stabilisation",WCCODE:"36210",WCDESCRIPTION:"Block Pre-Fabrication",CON_PROG:93.47,MC_PROG:21.6,PUNCH:7,PLAN_SD:"2020-03-17",PLAN_FD:"2021-07-08",PLAN_PROG:100,ACTUAL_SD:"2020-03-20",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:32,FILTER:"TOPSIDES",MODULE:"MW02",MODULE_DESCR:"Hc Dewpointing & Condensate Stabilisation",WCCODE:"23110",WCDESCRIPTION:"Structure Painting",CON_PROG:93.47,MC_PROG:21.6,PUNCH:7,PLAN_SD:"2020-12-29",PLAN_FD:"2021-08-30",PLAN_PROG:100,ACTUAL_SD:"2020-11-27",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:58,FILTER:"TOPSIDES",MODULE:"MW02",MODULE_DESCR:"Hc Dewpointing & Condensate Stabilisation",WCCODE:"16430",WCDESCRIPTION:"Cable Tray",CON_PROG:93.47,MC_PROG:21.6,PUNCH:7,PLAN_SD:"2021-06-01",PLAN_FD:"2021-08-30",PLAN_PROG:100,ACTUAL_SD:"2021-10-15",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:7,FILTER:"TOPSIDES",MODULE:"MW04",MODULE_DESCR:"Power Generation",WCCODE:"36210",WCDESCRIPTION:"Block Pre-Fabrication",CON_PROG:98.11,MC_PROG:40.8,PUNCH:6,PLAN_SD:"2020-02-16",PLAN_FD:"2021-05-22",PLAN_PROG:100,ACTUAL_SD:"2020-03-13",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:23,FILTER:"TOPSIDES",MODULE:"MW04",MODULE_DESCR:"Power Generation",WCCODE:"23110",WCDESCRIPTION:"Structure Painting",CON_PROG:98.11,MC_PROG:40.8,PUNCH:6,PLAN_SD:"2020-11-24",PLAN_FD:"2021-06-23",PLAN_PROG:100,ACTUAL_SD:"2020-11-13",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:36,FILTER:"TOPSIDES",MODULE:"MW04",MODULE_DESCR:"Power Generation",WCCODE:"13420",WCDESCRIPTION:"Piping Installation",CON_PROG:98.11,MC_PROG:40.8,PUNCH:6,PLAN_SD:"2021-03-02",PLAN_FD:"2021-09-16",PLAN_PROG:100,ACTUAL_SD:"2021-05-07",ACTUAL_PROG:97.87,CUTOFF:"2022-02-18"},
    {NO:49,FILTER:"TOPSIDES",MODULE:"MW04",MODULE_DESCR:"Power Generation",WCCODE:"13900",WCDESCRIPTION:"Hydro Test",CON_PROG:98.11,MC_PROG:40.8,PUNCH:6,PLAN_SD:"2021-04-29",PLAN_FD:"2021-11-04",PLAN_PROG:100,ACTUAL_SD:"2021-08-27",ACTUAL_PROG:81.8,CUTOFF:"2022-02-18"},
    {NO:10,FILTER:"TOPSIDES",MODULE:"MW04",MODULE_DESCR:"Power Generation",WCCODE:"36220",WCDESCRIPTION:"Block Assembly",CON_PROG:98.11,MC_PROG:40.8,PUNCH:6,PLAN_SD:"2020-06-30",PLAN_FD:"2021-07-01",PLAN_PROG:100,ACTUAL_SD:"2020-07-10",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:56,FILTER:"TOPSIDES",MODULE:"MW04",MODULE_DESCR:"Power Generation",WCCODE:"16430",WCDESCRIPTION:"Cable Tray",CON_PROG:98.11,MC_PROG:40.8,PUNCH:6,PLAN_SD:"2021-03-30",PLAN_FD:"2021-06-21",PLAN_PROG:100,ACTUAL_SD:"2021-08-20",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:50,FILTER:"TOPSIDES",MODULE:"MW05",MODULE_DESCR:"Utility",WCCODE:"13900",WCDESCRIPTION:"Hydro Test",CON_PROG:95.12,MC_PROG:22.9,PUNCH:58,PLAN_SD:"2021-06-01",PLAN_FD:"2022-03-08",PLAN_PROG:98.42,ACTUAL_SD:"2021-09-24",ACTUAL_PROG:76.31,CUTOFF:"2022-02-18"},
    {NO:8,FILTER:"TOPSIDES",MODULE:"MW05",MODULE_DESCR:"Utility",WCCODE:"36210",WCDESCRIPTION:"Block Pre-Fabrication",CON_PROG:95.12,MC_PROG:22.9,PUNCH:58,PLAN_SD:"2020-01-15",PLAN_FD:"2021-07-16",PLAN_PROG:100,ACTUAL_SD:"2020-03-13",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:24,FILTER:"TOPSIDES",MODULE:"MW05",MODULE_DESCR:"Utility",WCCODE:"23110",WCDESCRIPTION:"Structure Painting",CON_PROG:95.12,MC_PROG:22.9,PUNCH:58,PLAN_SD:"2020-12-16",PLAN_FD:"2021-08-13",PLAN_PROG:100,ACTUAL_SD:"2021-01-01",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:9,FILTER:"TOPSIDES",MODULE:"MW05",MODULE_DESCR:"Utility",WCCODE:"36220",WCDESCRIPTION:"Block Assembly",CON_PROG:95.12,MC_PROG:22.9,PUNCH:58,PLAN_SD:"2020-07-01",PLAN_FD:"2021-09-15",PLAN_PROG:100,ACTUAL_SD:"2020-07-03",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:55,FILTER:"TOPSIDES",MODULE:"MW05",MODULE_DESCR:"Utility",WCCODE:"16430",WCDESCRIPTION:"Cable Tray",CON_PROG:95.12,MC_PROG:22.9,PUNCH:58,PLAN_SD:"2021-05-31",PLAN_FD:"2021-09-01",PLAN_PROG:100,ACTUAL_SD:"2021-09-03",ACTUAL_PROG:100,CUTOFF:"2022-02-18"},
    {NO:35,FILTER:"TOPSIDES",MODULE:"MW05",MODULE_DESCR:"Utility",WCCODE:"13420",WCDESCRIPTION:"Piping Installation",CON_PROG:95.12,MC_PROG:22.9,PUNCH:58,PLAN_SD:"2021-03-02",PLAN_FD:"2021-12-25",PLAN_PROG:100,ACTUAL_SD:"2021-04-02",ACTUAL_PROG:98.59,CUTOFF:"2022-02-18"}
    ]
}