export default {
  Canvas: {
    CanvasWidth: 1510,
    CanvasHeight: 1110,
    CanvasBorderWeight: 0.1,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 0,
    CanvasChartHeight: 0,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  Text: {
    Text: [
      { text: 'Note', x: 400, y: 50, font: 'roboto', class: 'regular', size: 11, color: '#44A9DF', align: 'start' },
    ],
    Filter: [
      { name: 'MOD'   , x: 320, y: 50, font: 'roboto', class: 'regular', size: 11, color: '#44A9DF', align: 'start' },
    ],
    Sql: [
      {
        query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
        text: 'DataSource',
        x: 50,
        y: 150,
        font: 'roboto',
        style: 'regular',
        size: 10,
        color: '#757575',
        align: 'start'
      },
    ]
  },
DataItems: [
    {GWBS_LV1:"HULL & LQ",GWBS_LV2:"GENERAL",AREA:"GENERAL",TOTAL:1109,PLAN:1101,PLAN_PROG:99.27,ACTUAL:1071,ACTUAL_PROG:96.57,DELTA:-2.7,STR_TOTAL:628,STR_ACTUAL:623,PIP_TOTAL:130,PIP_ACTUAL:123,EIT_TOTAL:133,EIT_ACTUAL:111,HV_TOTAL:11,HV_ACTUAL:11,PAT_TOTAL:24,PAT_ACTUAL:23,OTH_TOTAL:183,OTH_ACTUAL:180},
    {GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",AREA:"HULL-AFT",AREA_DESCR:"Hull AFT SHIP",TOTAL:3070,PLAN:3070,PLAN_PROG:100,ACTUAL:3061,ACTUAL_PROG:99.7,DELTA:-0.29,STR_TOTAL:2184,STR_ACTUAL:2184,PIP_TOTAL:324,PIP_ACTUAL:319,EIT_TOTAL:15,EIT_ACTUAL:11,HV_TOTAL:0,HV_ACTUAL:0,PAT_TOTAL:281,PAT_ACTUAL:281,OTH_TOTAL:266,OTH_ACTUAL:266},
    {GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",AREA:"RB01-02",AREA_DESCR:"Riser Balcony & Protection",TOTAL:624,PLAN:624,PLAN_PROG:100,ACTUAL:585,ACTUAL_PROG:93.75,DELTA:-6.25,STR_TOTAL:521,STR_ACTUAL:482,PIP_TOTAL:1,PIP_ACTUAL:1,EIT_TOTAL:0,EIT_ACTUAL:0,HV_TOTAL:3,HV_ACTUAL:3,PAT_TOTAL:87,PAT_ACTUAL:87,OTH_TOTAL:12,OTH_ACTUAL:12},
    {GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-COMMON",AREA:"MD01-2",AREA_DESCR:"Main Deck",TOTAL:534,PLAN:534,PLAN_PROG:100,ACTUAL:523,ACTUAL_PROG:97.94,DELTA:-2.05,STR_TOTAL:460,STR_ACTUAL:449,PIP_TOTAL:0,PIP_ACTUAL:0,EIT_TOTAL:0,EIT_ACTUAL:0,HV_TOTAL:0,HV_ACTUAL:0,PAT_TOTAL:56,PAT_ACTUAL:56,OTH_TOTAL:18,OTH_ACTUAL:18},
    {GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",AREA:"HULL-FOR",AREA_DESCR:"Hull FOR SHIP",TOTAL:4424,PLAN:4414,PLAN_PROG:99.77,ACTUAL:4400,ACTUAL_PROG:99.45,DELTA:-0.31,STR_TOTAL:2704,STR_ACTUAL:2691,PIP_TOTAL:770,PIP_ACTUAL:767,EIT_TOTAL:234,EIT_ACTUAL:226,HV_TOTAL:35,HV_ACTUAL:35,PAT_TOTAL:345,PAT_ACTUAL:345,OTH_TOTAL:336,OTH_ACTUAL:336},
    {GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",AREA:"GENERAL",TOTAL:244,PLAN:243,PLAN_PROG:99.59,ACTUAL:243,ACTUAL_PROG:99.59,DELTA:0,STR_TOTAL:34,STR_ACTUAL:34,PIP_TOTAL:101,PIP_ACTUAL:101,EIT_TOTAL:55,EIT_ACTUAL:54,HV_TOTAL:48,HV_ACTUAL:48,PAT_TOTAL:0,PAT_ACTUAL:0,OTH_TOTAL:6,OTH_ACTUAL:6},
    {GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",AREA:"HD01",AREA_DESCR:"Helideck",TOTAL:15,PLAN:15,PLAN_PROG:100,ACTUAL:14,ACTUAL_PROG:93.33,DELTA:-6.66,STR_TOTAL:10,STR_ACTUAL:10,PIP_TOTAL:0,PIP_ACTUAL:0,EIT_TOTAL:3,EIT_ACTUAL:2,HV_TOTAL:0,HV_ACTUAL:0,PAT_TOTAL:1,PAT_ACTUAL:1,OTH_TOTAL:1,OTH_ACTUAL:1},
    {GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",AREA:"LQ",AREA_DESCR:"Living Quarter",TOTAL:825,PLAN:812,PLAN_PROG:98.42,ACTUAL:789,ACTUAL_PROG:95.63,DELTA:-2.78,STR_TOTAL:585,STR_ACTUAL:580,PIP_TOTAL:11,PIP_ACTUAL:1,EIT_TOTAL:41,EIT_ACTUAL:29,HV_TOTAL:2,HV_ACTUAL:2,PAT_TOTAL:100,PAT_ACTUAL:100,OTH_TOTAL:86,OTH_ACTUAL:77},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"GENERAL",AREA:"GENERAL",TOTAL:358,PLAN:310,PLAN_PROG:86.59,ACTUAL:30,ACTUAL_PROG:8.37,DELTA:-78.21,STR_TOTAL:224,STR_ACTUAL:30,PIP_TOTAL:0,PIP_ACTUAL:0,EIT_TOTAL:113,EIT_ACTUAL:0,HV_TOTAL:0,HV_ACTUAL:0,PAT_TOTAL:19,PAT_ACTUAL:0,OTH_TOTAL:2,OTH_ACTUAL:0},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES COMMON",AREA:"KO01",AREA_DESCR:"Flare KO Drum",TOTAL:46,PLAN:46,PLAN_PROG:100,ACTUAL:37,ACTUAL_PROG:80.43,DELTA:-19.56,STR_TOTAL:44,STR_ACTUAL:35,PIP_TOTAL:0,PIP_ACTUAL:0,EIT_TOTAL:0,EIT_ACTUAL:0,HV_TOTAL:0,HV_ACTUAL:0,PAT_TOTAL:2,PAT_ACTUAL:2,OTH_TOTAL:0,OTH_ACTUAL:0},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES COMMON",AREA:"LD00DA",TOTAL:62,PLAN:59,PLAN_PROG:95.16,ACTUAL:23,ACTUAL_PROG:37.09,DELTA:-58.06,STR_TOTAL:59,STR_ACTUAL:23,PIP_TOTAL:0,PIP_ACTUAL:0,EIT_TOTAL:0,EIT_ACTUAL:0,HV_TOTAL:0,HV_ACTUAL:0,PAT_TOTAL:3,PAT_ACTUAL:0,OTH_TOTAL:0,OTH_ACTUAL:0},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES COMMON",AREA:"RC01",AREA_DESCR:"Piperack01",TOTAL:41,PLAN:41,PLAN_PROG:100,ACTUAL:37,ACTUAL_PROG:90.24,DELTA:-9.75,STR_TOTAL:37,STR_ACTUAL:33,PIP_TOTAL:0,PIP_ACTUAL:0,EIT_TOTAL:0,EIT_ACTUAL:0,HV_TOTAL:0,HV_ACTUAL:0,PAT_TOTAL:4,PAT_ACTUAL:4,OTH_TOTAL:0,OTH_ACTUAL:0},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES COMMON",AREA:"RC02",AREA_DESCR:"Piperack02",TOTAL:40,PLAN:40,PLAN_PROG:100,ACTUAL:36,ACTUAL_PROG:90,DELTA:-10,STR_TOTAL:37,STR_ACTUAL:33,PIP_TOTAL:0,PIP_ACTUAL:0,EIT_TOTAL:0,EIT_ACTUAL:0,HV_TOTAL:0,HV_ACTUAL:0,PAT_TOTAL:3,PAT_ACTUAL:3,OTH_TOTAL:0,OTH_ACTUAL:0},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES COMMON",AREA:"RC03",AREA_DESCR:"Piperack03",TOTAL:80,PLAN:80,PLAN_PROG:100,ACTUAL:66,ACTUAL_PROG:82.5,DELTA:-17.5,STR_TOTAL:72,STR_ACTUAL:59,PIP_TOTAL:0,PIP_ACTUAL:0,EIT_TOTAL:0,EIT_ACTUAL:0,HV_TOTAL:0,HV_ACTUAL:0,PAT_TOTAL:6,PAT_ACTUAL:5,OTH_TOTAL:2,OTH_ACTUAL:2},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES COMMON",AREA:"RC04",AREA_DESCR:"Piperack04",TOTAL:49,PLAN:49,PLAN_PROG:100,ACTUAL:45,ACTUAL_PROG:91.83,DELTA:-8.16,STR_TOTAL:46,STR_ACTUAL:42,PIP_TOTAL:0,PIP_ACTUAL:0,EIT_TOTAL:0,EIT_ACTUAL:0,HV_TOTAL:0,HV_ACTUAL:0,PAT_TOTAL:3,PAT_ACTUAL:3,OTH_TOTAL:0,OTH_ACTUAL:0},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES COMMON",AREA:"RC05",AREA_DESCR:"Piperack05",TOTAL:45,PLAN:45,PLAN_PROG:100,ACTUAL:40,ACTUAL_PROG:88.88,DELTA:-11.11,STR_TOTAL:41,STR_ACTUAL:37,PIP_TOTAL:0,PIP_ACTUAL:0,EIT_TOTAL:0,EIT_ACTUAL:0,HV_TOTAL:0,HV_ACTUAL:0,PAT_TOTAL:4,PAT_ACTUAL:3,OTH_TOTAL:0,OTH_ACTUAL:0},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",AREA:"ME01",AREA_DESCR:"Flash Gas Comp.",TOTAL:238,PLAN:234,PLAN_PROG:98.31,ACTUAL:149,ACTUAL_PROG:62.6,DELTA:-35.71,STR_TOTAL:187,STR_ACTUAL:134,PIP_TOTAL:0,PIP_ACTUAL:0,EIT_TOTAL:32,EIT_ACTUAL:0,HV_TOTAL:0,HV_ACTUAL:0,PAT_TOTAL:12,PAT_ACTUAL:8,OTH_TOTAL:7,OTH_ACTUAL:7},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",AREA:"ME02",AREA_DESCR:"MEG & Fule Gas",TOTAL:186,PLAN:180,PLAN_PROG:96.77,ACTUAL:112,ACTUAL_PROG:60.21,DELTA:-36.55,STR_TOTAL:140,STR_ACTUAL:102,PIP_TOTAL:0,PIP_ACTUAL:0,EIT_TOTAL:32,EIT_ACTUAL:0,HV_TOTAL:0,HV_ACTUAL:0,PAT_TOTAL:11,PAT_ACTUAL:7,OTH_TOTAL:3,OTH_ACTUAL:3},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",AREA:"ME04",AREA_DESCR:"Power Gen.",TOTAL:122,PLAN:122,PLAN_PROG:100,ACTUAL:111,ACTUAL_PROG:90.98,DELTA:-9.01,STR_TOTAL:98,STR_ACTUAL:89,PIP_TOTAL:0,PIP_ACTUAL:0,EIT_TOTAL:16,EIT_ACTUAL:14,HV_TOTAL:0,HV_ACTUAL:0,PAT_TOTAL:5,PAT_ACTUAL:5,OTH_TOTAL:3,OTH_ACTUAL:3},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",AREA:"ME05",AREA_DESCR:"E/I Bldg.",TOTAL:219,PLAN:219,PLAN_PROG:100,ACTUAL:186,ACTUAL_PROG:84.93,DELTA:-15.06,STR_TOTAL:140,STR_ACTUAL:123,PIP_TOTAL:0,PIP_ACTUAL:0,EIT_TOTAL:40,EIT_ACTUAL:26,HV_TOTAL:18,HV_ACTUAL:16,PAT_TOTAL:11,PAT_ACTUAL:11,OTH_TOTAL:10,OTH_ACTUAL:10},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",AREA:"MW01",AREA_DESCR:"Recpt. Facil. & Prod. Sepn.",TOTAL:266,PLAN:262,PLAN_PROG:98.49,ACTUAL:189,ACTUAL_PROG:71.05,DELTA:-27.44,STR_TOTAL:207,STR_ACTUAL:154,PIP_TOTAL:0,PIP_ACTUAL:0,EIT_TOTAL:40,EIT_ACTUAL:17,HV_TOTAL:0,HV_ACTUAL:0,PAT_TOTAL:13,PAT_ACTUAL:12,OTH_TOTAL:6,OTH_ACTUAL:6},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",AREA:"MW02",AREA_DESCR:"HC Dewpt. & Conden. Stabil.",TOTAL:244,PLAN:241,PLAN_PROG:98.77,ACTUAL:172,ACTUAL_PROG:70.49,DELTA:-28.27,STR_TOTAL:193,STR_ACTUAL:141,PIP_TOTAL:0,PIP_ACTUAL:0,EIT_TOTAL:32,EIT_ACTUAL:12,HV_TOTAL:0,HV_ACTUAL:0,PAT_TOTAL:12,PAT_ACTUAL:12,OTH_TOTAL:7,OTH_ACTUAL:7},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",AREA:"MW04",AREA_DESCR:"Power Gen.",TOTAL:121,PLAN:121,PLAN_PROG:100,ACTUAL:106,ACTUAL_PROG:87.6,DELTA:-12.39,STR_TOTAL:97,STR_ACTUAL:87,PIP_TOTAL:0,PIP_ACTUAL:0,EIT_TOTAL:16,EIT_ACTUAL:11,HV_TOTAL:0,HV_ACTUAL:0,PAT_TOTAL:5,PAT_ACTUAL:5,OTH_TOTAL:3,OTH_ACTUAL:3},
    {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",AREA:"MW05",AREA_DESCR:"Utility",TOTAL:252,PLAN:250,PLAN_PROG:99.2,ACTUAL:205,ACTUAL_PROG:81.34,DELTA:-17.85,STR_TOTAL:205,STR_ACTUAL:167,PIP_TOTAL:0,PIP_ACTUAL:0,EIT_TOTAL:32,EIT_ACTUAL:23,HV_TOTAL:0,HV_ACTUAL:0,PAT_TOTAL:10,PAT_ACTUAL:10,OTH_TOTAL:5,OTH_ACTUAL:5}
    ],    
}