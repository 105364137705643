import __C from '../../../../../../includes/primitives/_constant_'

import MasterProgress from './MasterProgress'
import AreaProgress from './AreaProgress'


export default {
    Canvas: {
      CanvasWidth: 1500,
      CanvasHeight: 1050,
      CanvasBorderWeight: 0.2,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 1,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

    Queries: {
      // Status of Blocks
      MasterProg: MasterProgress,
      AreaProg: AreaProgress,
      SQL1: 
      [
        {NO:1,Lv1:"TOPSIDE",Lv2:"COMMON",Lv3:"FL01"},
        {NO:2,Lv1:"TOPSIDE",Lv2:"COMMON",Lv3:"KO01"},
        {NO:3,Lv1:"TOPSIDE",Lv2:"COMMON",Lv3:"RC01",TOTAL_WELDED_LENGTH:2673411,INSPECTION_LENGTH:1146336.8,DEFECT_LENGTH:42.5,DEFECT_RATE:0},
        {NO:4,Lv1:"TOPSIDE",Lv2:"COMMON",Lv3:"RC02",TOTAL_WELDED_LENGTH:2079880,INSPECTION_LENGTH:966717.6,DEFECT_LENGTH:0,DEFECT_RATE:0},
        {NO:5,Lv1:"TOPSIDE",Lv2:"COMMON",Lv3:"RC03",TOTAL_WELDED_LENGTH:1656860,INSPECTION_LENGTH:655904.1,DEFECT_LENGTH:0,DEFECT_RATE:0},
        {NO:6,Lv1:"TOPSIDE",Lv2:"COMMON",Lv3:"RC04",TOTAL_WELDED_LENGTH:4073544,INSPECTION_LENGTH:1464912.8,DEFECT_LENGTH:138.5,DEFECT_RATE:0},
        {NO:7,Lv1:"TOPSIDE",Lv2:"COMMON",Lv3:"RC05",TOTAL_WELDED_LENGTH:3252427,INSPECTION_LENGTH:1332048.6,DEFECT_LENGTH:1815,DEFECT_RATE:0.13},
        {NO:8,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME01",TOTAL_WELDED_LENGTH:13757481,INSPECTION_LENGTH:3601311.5,DEFECT_LENGTH:1840,DEFECT_RATE:0.05},
        {NO:9,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME02",TOTAL_WELDED_LENGTH:10579461,INSPECTION_LENGTH:3053627.5,DEFECT_LENGTH:474,DEFECT_RATE:0.01},
        {NO:10,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME04"},
        {NO:11,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME05",TOTAL_WELDED_LENGTH:4992262.5,INSPECTION_LENGTH:1706831.3,DEFECT_LENGTH:2096,DEFECT_RATE:0.12},
        {NO:12,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW01",TOTAL_WELDED_LENGTH:15128154.5,INSPECTION_LENGTH:4948760,DEFECT_LENGTH:1388,DEFECT_RATE:0.02},
        {NO:13,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW02",TOTAL_WELDED_LENGTH:14555541.3,INSPECTION_LENGTH:4924382,DEFECT_LENGTH:2161.5,DEFECT_RATE:0.04},
        {NO:14,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW04"},
        {NO:15,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW05"},
        {NO:16,Lv1:"TOPSIDE",Lv2:"COMMON",Lv3:"OVERALL",TOTAL_WELDED_LENGTH:13736122,INSPECTION_LENGTH:5565919.9,DEFECT_LENGTH:1996,DEFECT_RATE:0.03},
        {NO:17,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"OVERALL",TOTAL_WELDED_LENGTH:59012900.3,INSPECTION_LENGTH:18234912.3,DEFECT_LENGTH:7959.5,DEFECT_RATE:0.04}
        ]
    },
    Text: {
      Text: [
        { text: 'Note', x: 1350, y: 20, font: 'roboto', class: 'regular', size: 11, color: '#44A9DF', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: 1350, y: 30, font: 'roboto', class: 'regular', size: 11, color: '#757575', align: 'start' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: 32,
          y: 100,
          font: 'roboto',
          style: 'regular',
          size: 10,
          color: '#757575',
          align: 'start'
        },
      ]
    },

    DataItems: [
      // {NO:1,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"20C",BLOCK:"203",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:2,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"20C",BLOCK:"204",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:3,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"20C",BLOCK:"20C",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:4,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"20D",BLOCK:"213",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:5,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"20D",BLOCK:"214",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:6,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"20D",BLOCK:"20D",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:7,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"20B",BLOCK:"211",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:8,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"20B",BLOCK:"212",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:9,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"20B",BLOCK:"20B",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:10,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"20E",BLOCK:"205",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:11,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"20E",BLOCK:"206",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:12,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"20E",BLOCK:"20E",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:13,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"20A",BLOCK:"201",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:14,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"20A",BLOCK:"202",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:15,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"20A",BLOCK:"20A",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:16,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"20F",BLOCK:"215",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:17,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"20F",BLOCK:"216",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:18,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"20F",BLOCK:"20F",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:19,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"21C",BLOCK:"223",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:20,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"21C",BLOCK:"224",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:21,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"21C",BLOCK:"21C",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:22,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"22C",BLOCK:"233",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:23,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"22C",BLOCK:"234",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:24,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"22C",BLOCK:"22C",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:25,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"303",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:26,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"304",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:27,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"313",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:28,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"314",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:29,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"301",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:30,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"302",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:31,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"311",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:32,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"312",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:33,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"21E",BLOCK:"225",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:34,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"21E",BLOCK:"226",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:35,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"21E",BLOCK:"21E",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:36,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"22E",BLOCK:"235",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:37,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"22E",BLOCK:"236",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:38,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"22E",BLOCK:"22E",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:39,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"21A",BLOCK:"221",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:40,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"21A",BLOCK:"222",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:41,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"21A",BLOCK:"21A",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:42,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"22A",BLOCK:"231",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:43,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"22A",BLOCK:"232",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:44,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"22A",BLOCK:"22A",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:45,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"305",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:46,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"306",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:47,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"315",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:48,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"316",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:49,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"143",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:50,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"153",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:51,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"101",AREA:"AFT ship area",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:52,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"14A",BLOCK:"142",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:53,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"14A",BLOCK:"122",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:54,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"14A",BLOCK:"14A",AREA:"Cargo tank",TYPE:"MEGA",EREC:"Y"},
      // {NO:55,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"15A",BLOCK:"152",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:56,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"15A",BLOCK:"132",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:57,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"15A",BLOCK:"15A",AREA:"Cargo tank",TYPE:"MEGA",EREC:"Y"},
      // {NO:58,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"13A",BLOCK:"131",AREA:"AFT ship area",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:59,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"13A",BLOCK:"151",AREA:"AFT ship area",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:60,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"13A",BLOCK:"13A",AREA:"AFT ship area",TYPE:"MEGA",EREC:"Y"},
      // {NO:61,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"12A",BLOCK:"141",AREA:"AFT ship area",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:62,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"12A",BLOCK:"121",AREA:"AFT ship area",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:63,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"12A",BLOCK:"12A",AREA:"AFT ship area",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:64,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"80C",BLOCK:"803",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:65,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"80C",BLOCK:"823",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:66,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"80C",BLOCK:"80C",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:67,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"80D",BLOCK:"804",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:68,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"80D",BLOCK:"824",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:69,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"80D",BLOCK:"80D",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:70,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"80B",BLOCK:"802",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:71,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"80B",BLOCK:"822",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:72,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"80B",BLOCK:"80B",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:73,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"80E",BLOCK:"805",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:74,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"80E",BLOCK:"825",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:75,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"80E",BLOCK:"80E",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:76,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"80A",BLOCK:"801",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:77,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"80A",BLOCK:"821",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:78,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"80A",BLOCK:"80A",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:79,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"80F",BLOCK:"806",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:80,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"80F",BLOCK:"826",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:81,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"80F",BLOCK:"80F",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:82,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"10B",BLOCK:"102",AREA:"AFT ship area",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:83,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"10B",BLOCK:"104",AREA:"AFT ship area",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:84,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"10B",BLOCK:"10B",AREA:"AFT ship area",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:85,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"103",AREA:"AFT ship area",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:86,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"842",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:87,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"852",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:88,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"843",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:89,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"853",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:90,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"841",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:91,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"851",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:92,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"844",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:93,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"854",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:94,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"123",AREA:"AFT ship area",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:95,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"133",AREA:"AFT ship area",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:96,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"882",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:97,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"892",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:98,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"883",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:99,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"893",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:100,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"881",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:101,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"891",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:102,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"884",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:103,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"894",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:104,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"126",AREA:"AFT ship area",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:105,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"136",AREA:"AFT ship area",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:106,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"125",AREA:"AFT ship area",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:107,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"135",AREA:"AFT ship area",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:108,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"124",AREA:"AFT ship area",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:109,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"134",AREA:"AFT ship area",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:110,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"30C",BLOCK:"323",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:111,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"30C",BLOCK:"324",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:112,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"30C",BLOCK:"30C",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:113,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"31C",BLOCK:"333",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:114,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"31C",BLOCK:"334",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:115,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"31C",BLOCK:"31C",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:116,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"30A",BLOCK:"321",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:117,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"30A",BLOCK:"322",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:118,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"30A",BLOCK:"30A",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:119,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"31A",BLOCK:"331",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:120,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"31A",BLOCK:"332",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:121,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"31A",BLOCK:"31A",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:122,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"30E",BLOCK:"325",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:123,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"30E",BLOCK:"326",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:124,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"30E",BLOCK:"30E",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:125,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"31E",BLOCK:"335",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:126,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"31E",BLOCK:"336",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:127,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"31E",BLOCK:"31E",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:128,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"50C",BLOCK:"503",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:129,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"50C",BLOCK:"504",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:130,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"50C",BLOCK:"50C",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:131,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"51C",BLOCK:"513",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:132,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"51C",BLOCK:"514",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:133,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"51C",BLOCK:"51C",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:134,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"50A",BLOCK:"501",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:135,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"50A",BLOCK:"502",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:136,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"50A",BLOCK:"50A",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:137,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"51A",BLOCK:"511",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:138,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"51A",BLOCK:"512",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:139,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"51A",BLOCK:"51A",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:140,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"50E",BLOCK:"505",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:141,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"50E",BLOCK:"506",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:142,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"50E",BLOCK:"50E",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:143,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"51E",BLOCK:"515",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:144,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"51E",BLOCK:"516",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:145,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"51E",BLOCK:"51E",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:146,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"12C",BLOCK:"145",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:147,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"12C",BLOCK:"147",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:148,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"12C",BLOCK:"12C",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:149,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"13C",BLOCK:"155",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:150,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"13C",BLOCK:"157",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:151,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"13C",BLOCK:"13C",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:152,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"14C",BLOCK:"146",AREA:"AFT ship area",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:153,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"14C",BLOCK:"144",AREA:"AFT ship area",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:154,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"14C",BLOCK:"14C",AREA:"AFT ship area",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:155,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"15C",BLOCK:"156",AREA:"AFT ship area",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:156,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"15C",BLOCK:"154",AREA:"AFT ship area",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:157,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"15C",BLOCK:"15C",AREA:"AFT ship area",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:158,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"180",AREA:"AFT ship area",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:159,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"190",AREA:"AFT ship area",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:160,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"40C",BLOCK:"403",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:161,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"40C",BLOCK:"423",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:162,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"40C",BLOCK:"433",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:163,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"40C",BLOCK:"40C",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:164,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"40D",BLOCK:"404",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:165,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"40D",BLOCK:"424",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:166,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"40D",BLOCK:"434",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:167,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"40D",BLOCK:"40D",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:168,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"40B",BLOCK:"402",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:169,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"40B",BLOCK:"422",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:170,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"40B",BLOCK:"432",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:171,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"40B",BLOCK:"40B",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:172,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"40E",BLOCK:"405",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:173,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"40E",BLOCK:"425",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:174,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"40E",BLOCK:"435",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:175,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"40E",BLOCK:"40E",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:176,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"40A",BLOCK:"401",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:177,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"40A",BLOCK:"421",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:178,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"40A",BLOCK:"431",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:179,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"40A",BLOCK:"40A",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:180,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"40F",BLOCK:"406",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:181,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"40F",BLOCK:"426",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:182,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"40F",BLOCK:"436",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:183,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"40F",BLOCK:"40F",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:184,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"10C",BLOCK:"106",AREA:"AFT ship area",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:185,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"10C",BLOCK:"128",AREA:"AFT ship area",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:186,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"10C",BLOCK:"138",AREA:"AFT ship area",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:187,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"10C",BLOCK:"10C",AREA:"AFT ship area",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:188,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"11C",BLOCK:"105",AREA:"AFT ship area",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:189,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"11C",BLOCK:"127",AREA:"AFT ship area",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:190,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"11C",BLOCK:"137",AREA:"AFT ship area",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:191,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",MBLOCK:"11C",BLOCK:"11C",AREA:"AFT ship area",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:192,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"20J",BLOCK:"209",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:193,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"20J",BLOCK:"241",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:194,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"20J",BLOCK:"20J",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:195,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"20I",BLOCK:"251",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:196,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"20I",BLOCK:"219",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:197,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"20I",BLOCK:"20I",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:198,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"20K",BLOCK:"242",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:199,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"20K",BLOCK:"243",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:200,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"20K",BLOCK:"20K",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:201,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"20H",BLOCK:"217",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:202,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"20H",BLOCK:"218",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:203,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"20H",BLOCK:"20H",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:204,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"20L",BLOCK:"252",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:205,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"20L",BLOCK:"253",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:206,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"20L",BLOCK:"20L",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:207,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"20G",BLOCK:"207",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:208,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"20G",BLOCK:"208",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:209,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"20G",BLOCK:"20G",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:210,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"21J",BLOCK:"261",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:211,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"21J",BLOCK:"341",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:212,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"21J",BLOCK:"21J",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:213,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"21I",BLOCK:"229",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:214,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"21I",BLOCK:"309",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:215,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"21I",BLOCK:"866",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:216,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"21I",BLOCK:"21I",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:217,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"21K",BLOCK:"262",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:218,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"21K",BLOCK:"342",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:219,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"21K",BLOCK:"867",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:220,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"21K",BLOCK:"21K",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:221,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"21H",BLOCK:"228",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:222,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"21H",BLOCK:"308",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:223,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"21H",BLOCK:"865",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:224,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"21H",BLOCK:"21H",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:225,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"21L",BLOCK:"263",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:226,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"21L",BLOCK:"343",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:227,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"21L",BLOCK:"21L",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:228,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"21G",BLOCK:"227",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:229,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"21G",BLOCK:"307",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:230,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"21G",BLOCK:"21G",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:231,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"22J",BLOCK:"271",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:232,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"22J",BLOCK:"351",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:233,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"22J",BLOCK:"22J",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:234,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"22I",BLOCK:"239",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:235,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"22I",BLOCK:"319",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:236,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"22I",BLOCK:"876",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:237,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"22I",BLOCK:"22I",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:238,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"22K",BLOCK:"272",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:239,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"22K",BLOCK:"352",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:240,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"22K",BLOCK:"877",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:241,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"22K",BLOCK:"22K",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:242,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"22H",BLOCK:"238",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:243,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"22H",BLOCK:"318",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:244,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"22H",BLOCK:"875",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:245,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"22H",BLOCK:"22H",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:246,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"22L",BLOCK:"273",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:247,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"22L",BLOCK:"353",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:248,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"22L",BLOCK:"22L",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:249,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"22G",BLOCK:"237",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:250,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"22G",BLOCK:"317",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:251,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"22G",BLOCK:"22G",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:252,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"80J",BLOCK:"811",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:253,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"80J",BLOCK:"831",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:254,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"80J",BLOCK:"80J",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:255,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"80I",BLOCK:"809",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:256,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"80I",BLOCK:"829",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:257,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"80I",BLOCK:"80I",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:258,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"80K",BLOCK:"812",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:259,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"80K",BLOCK:"832",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:260,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"80K",BLOCK:"80K",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:261,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"80H",BLOCK:"808",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:262,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"80H",BLOCK:"828",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:263,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"80H",BLOCK:"80H",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:264,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"80L",BLOCK:"813",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:265,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"80L",BLOCK:"833",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:266,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"80L",BLOCK:"80L",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:267,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"80G",BLOCK:"807",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:268,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"80G",BLOCK:"827",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:269,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"80G",BLOCK:"80G",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:270,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"846",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:271,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"856",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:272,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"847",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:273,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"857",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:274,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"845",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:275,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"855",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:276,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"886",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:277,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"896",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:278,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"887",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:279,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"897",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:280,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"885",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:281,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"895",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:282,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"30I",BLOCK:"361",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:283,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"30I",BLOCK:"329",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:284,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"30I",BLOCK:"30I",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:285,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"31I",BLOCK:"371",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:286,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"31I",BLOCK:"339",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:287,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"31I",BLOCK:"31I",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:288,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"30G",BLOCK:"328",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:289,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"30G",BLOCK:"327",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:290,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"30G",BLOCK:"30G",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:291,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"31G",BLOCK:"338",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:292,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"31G",BLOCK:"337",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:293,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"31G",BLOCK:"31G",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:294,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"30K",BLOCK:"362",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:295,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"30K",BLOCK:"363",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:296,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"30K",BLOCK:"30K",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:297,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"31K",BLOCK:"372",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:298,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"31K",BLOCK:"373",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:299,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"31K",BLOCK:"31K",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:300,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"50I",BLOCK:"521",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:301,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"50I",BLOCK:"509",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:302,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"50I",BLOCK:"50I",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:303,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"51I",BLOCK:"531",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:304,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"51I",BLOCK:"519",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:305,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"51I",BLOCK:"51I",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:306,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"50G",BLOCK:"508",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:307,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"50G",BLOCK:"507",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:308,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"50G",BLOCK:"50G",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:309,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"51G",BLOCK:"518",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:310,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"51G",BLOCK:"517",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:311,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"51G",BLOCK:"51G",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:312,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"50K",BLOCK:"522",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:313,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"50K",BLOCK:"523",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:314,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"50K",BLOCK:"50K",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:315,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"51K",BLOCK:"532",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:316,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"51K",BLOCK:"533",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:317,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"51K",BLOCK:"51K",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:318,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"40J",BLOCK:"411",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:319,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"40J",BLOCK:"451",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:320,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"40J",BLOCK:"441",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:321,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"40J",BLOCK:"40J",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:322,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"40I",BLOCK:"409",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:323,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"40I",BLOCK:"429",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:324,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"40I",BLOCK:"439",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:325,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"40I",BLOCK:"40I",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:326,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"40K",BLOCK:"412",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:327,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"40K",BLOCK:"442",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:328,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"40K",BLOCK:"452",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:329,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"40K",BLOCK:"40K",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:330,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"40H",BLOCK:"408",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:331,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"40H",BLOCK:"428",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:332,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"40H",BLOCK:"438",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:333,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"40H",BLOCK:"40H",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:334,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"40L",BLOCK:"413",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:335,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"40L",BLOCK:"443",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:336,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"40L",BLOCK:"453",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:337,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"40L",BLOCK:"40L",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:338,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"40G",BLOCK:"407",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:339,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"40G",BLOCK:"437",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:340,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"40G",BLOCK:"427",AREA:"Cargo tank",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:341,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"40G",BLOCK:"40G",AREA:"Cargo tank",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:342,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"291",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:343,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"294",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:344,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"281",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:345,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"284",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:346,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"292",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:347,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"295",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:348,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"282",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:349,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"285",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:350,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"293",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:351,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"296",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:352,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"283",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:353,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"286",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:354,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"280",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:355,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"290",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:356,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"26F",BLOCK:"848",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:357,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"26F",BLOCK:"868",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:358,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"26F",BLOCK:"878",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:359,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"26F",BLOCK:"26F",AREA:"Machinery Space",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:360,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"27F",BLOCK:"858",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:361,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"27F",BLOCK:"888",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:362,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"27F",BLOCK:"898",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:363,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"27F",BLOCK:"27F",AREA:"Machinery Space",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:364,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"287",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:365,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"288",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:366,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"297",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:367,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"298",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:368,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"684",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:369,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"685",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:370,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"694",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:371,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"695",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:372,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"63F",BLOCK:"289",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:373,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"63F",BLOCK:"686",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:374,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"63F",BLOCK:"63F",AREA:"Machinery Space",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:375,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"64F",BLOCK:"299",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:376,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"64F",BLOCK:"696",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:377,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"64F",BLOCK:"64F",AREA:"Machinery Space",TYPE:"MEGA",EREC:"Y"},
      // {NO:378,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"607",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:379,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"627",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:380,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"637",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:381,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"66F",BLOCK:"621",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:382,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"66F",BLOCK:"601",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:383,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"66F",BLOCK:"66F",AREA:"Machinery Space",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:384,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"67F",BLOCK:"622",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:385,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"67F",BLOCK:"602",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:386,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"67F",BLOCK:"67F",AREA:"Machinery Space",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:387,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"68F",BLOCK:"631",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:388,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"68F",BLOCK:"611",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:389,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"68F",BLOCK:"68F",AREA:"Machinery Space",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:390,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"69F",BLOCK:"632",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:391,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"69F",BLOCK:"612",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:392,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"69F",BLOCK:"69F",AREA:"Machinery Space",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:393,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"61A",BLOCK:"623",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:394,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"61A",BLOCK:"603",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:395,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"61A",BLOCK:"61A",AREA:"Machinery Space",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:396,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"62A",BLOCK:"624",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:397,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"62A",BLOCK:"604",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:398,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"62A",BLOCK:"62A",AREA:"Machinery Space",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:399,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"63A",BLOCK:"633",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:400,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"63A",BLOCK:"613",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:401,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"63A",BLOCK:"63A",AREA:"Machinery Space",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:402,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"64A",BLOCK:"634",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:403,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"64A",BLOCK:"614",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:404,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"64A",BLOCK:"64A",AREA:"Machinery Space",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:405,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"65A",BLOCK:"625",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:406,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"65A",BLOCK:"605",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:407,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"65A",BLOCK:"65A",AREA:"Machinery Space",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:408,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"66A",BLOCK:"626",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:409,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"66A",BLOCK:"606",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:410,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"66A",BLOCK:"66A",AREA:"Machinery Space",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:411,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"67A",BLOCK:"635",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:412,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"67A",BLOCK:"615",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:413,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"67A",BLOCK:"67A",AREA:"Machinery Space",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:414,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"68A",BLOCK:"636",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:415,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"68A",BLOCK:"616",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:416,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"68A",BLOCK:"68A",AREA:"Machinery Space",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:417,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"69A",BLOCK:"617",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:418,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"69A",BLOCK:"681",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:419,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"69A",BLOCK:"691",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:420,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"69A",BLOCK:"69A",AREA:"Machinery Space",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:421,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"61B",BLOCK:"618",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:422,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"61B",BLOCK:"682",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:423,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"61B",BLOCK:"692",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:424,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"61B",BLOCK:"61B",AREA:"Machinery Space",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:425,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"62B",BLOCK:"619",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:426,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"62B",BLOCK:"683",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:427,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"62B",BLOCK:"693",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"PAINTING"},
      // {NO:428,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",MBLOCK:"62B",BLOCK:"62B",AREA:"Machinery Space",TYPE:"MEGA",STATUS:"ERECTION",EREC:"Y"},
      // {NO:429,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"687",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:430,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"688",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:431,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"697",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:432,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"698",AREA:"Machinery Space",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:433,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"001",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:434,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"002",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:435,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"003",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:436,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"004",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:437,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"005",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:438,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"006",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:439,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"007",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:440,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"008",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:441,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"009",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:442,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"010",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:443,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"027",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:444,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"028",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:445,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"029",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:446,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"011",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:447,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"012",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:448,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"013",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:449,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"014",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:450,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"015",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:451,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"016",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:452,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"017",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:453,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"018",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:454,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"019",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:455,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"020",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:456,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"030",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:457,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"031",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:458,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"036",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:459,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"037",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:460,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"021",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:461,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"038",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:462,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"039",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:463,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"040",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:464,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"022",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:465,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"023",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:466,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"024",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:467,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"025",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:468,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"026",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:469,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"032",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:470,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"033",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:471,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"034",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:472,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"035",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:473,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"081",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:474,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"082",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:475,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"091",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:476,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"092",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:477,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"083",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:478,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"084",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:479,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"085",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:480,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"086",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:481,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"093",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:482,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"094",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:483,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"095",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:484,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"096",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:485,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"139",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
      // {NO:486,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"VS04-5",BLOCK:"721",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:487,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"VS04-5",BLOCK:"731",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:488,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"089",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:489,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"090",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:490,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"740",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:491,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"741",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:492,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"742",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:493,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"743",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:494,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"744",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:495,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"745",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:496,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"746",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:497,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"747",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:498,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"748",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:499,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"749",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:500,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"750",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:501,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"751",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:502,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"752",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:503,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"753",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:504,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"754",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:505,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"755",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:506,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"756",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:507,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"757",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:508,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"758",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:509,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"759",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:510,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"760",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:511,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"761",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:512,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"762",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:513,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"763",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:514,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"764",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:515,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"765",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:516,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"766",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:517,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"767",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:518,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"768",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:519,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"769",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:520,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"770",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:521,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"771",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:522,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"772",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:523,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"773",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:524,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"HL02",BLOCK:"774",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:525,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"775",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:526,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"776",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:527,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"777",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:528,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"778",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:529,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"HL03",BLOCK:"779",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:530,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"MD00a",BLOCK:"780",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:531,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"MD00a",BLOCK:"781",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:532,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"MD00a",BLOCK:"782",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:533,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"MD00a",BLOCK:"783",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:534,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"MD00a",BLOCK:"784",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:535,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"MD00a",BLOCK:"785",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:536,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"MD00f",BLOCK:"786",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:537,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"MD00f",BLOCK:"787",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:538,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"MD00f",BLOCK:"788",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:539,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"MD00f",BLOCK:"789",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:540,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"MD00f",BLOCK:"790",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:541,Lv1:"HULL",Lv2:"HULL-FOR",Lv3:"MD00f",BLOCK:"791",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:542,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"0001",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:543,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"0002",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:544,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"0003",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:545,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"0004",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:546,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"0005",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:547,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"0006",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:548,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"0007",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:549,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"0008",TYPE:"UNIT",STATUS:"PAINTING",EREC:"Y"},
      // {NO:550,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"0009",TYPE:"UNIT",STATUS:"PAINTING",EREC:"Y"},
      // {NO:551,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"0010",TYPE:"UNIT",STATUS:"PAINTING",EREC:"Y"},
      // {NO:552,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"0011",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:553,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"0012",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:554,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"0013",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:555,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"0014",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:556,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"0015",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:557,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"0016",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:558,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"051",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:559,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"052",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:560,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"053",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:561,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"054",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:562,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"055",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:563,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"056",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:564,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"057",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:565,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"058",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:566,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"070",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:567,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"071",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:568,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"072",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:569,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"073",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:570,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"074",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:571,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"075",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:572,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"076",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:573,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"077",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:574,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"078",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:575,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"079",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:576,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"097",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:577,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"098",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:578,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"041",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:579,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"042",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:580,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"043",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:581,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"044",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:582,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"045",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:583,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"046",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:584,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"047",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:585,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"048",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:586,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"049",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:587,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"060",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:588,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"061",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:589,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"062",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:590,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"063",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:591,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"064",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:592,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"065",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:593,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"066",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:594,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"067",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:595,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"068",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:596,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"069",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:597,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"080",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:598,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"087",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:599,Lv1:"HULL",Lv2:"HULL-AFT",Lv3:"RB01-02",BLOCK:"088",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:600,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"901",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:601,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"902",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:602,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"903",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:603,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"904",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:604,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"905",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:605,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"906",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:606,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"907",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:607,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"908",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:608,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"909",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:609,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"991",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:610,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"992",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:611,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"993",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:612,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"994",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:613,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"911",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:614,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"912",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:615,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"913",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:616,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"914",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:617,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"915",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:618,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"916",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:619,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"917",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:620,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"918",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:621,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"921",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:622,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"922",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:623,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"923",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:624,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"924",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:625,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"925",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:626,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"926",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:627,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"927",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:628,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"928",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:629,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"995",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:630,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"996",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:631,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"931",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:632,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"932",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:633,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"933",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:634,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"934",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:635,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"935",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:636,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"936",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:637,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"937",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:638,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"938",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:639,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"997",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:640,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"941",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:641,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"942",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:642,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"943",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:643,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"944",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:644,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"945",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:645,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"946",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:646,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"947",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:647,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"948",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:648,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"951",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:649,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"952",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:650,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"953",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:651,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"954",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:652,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"955",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:653,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"956",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:654,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"957",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:655,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"958",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:656,Lv1:"HULL",Lv2:"LQ",Lv3:"HD01",BLOCK:"959",AREA:"Helideck",TYPE:"UNIT",STATUS:"PAINTING",EREC:"Y"},
      // {NO:657,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"961",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:658,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"962",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:659,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"963",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:660,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"964",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:661,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"965",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:662,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"966",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:663,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"971",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:664,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"972",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:665,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"973",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"PAINTING",EREC:"Y"},
      // {NO:666,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"1901",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:667,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"1902",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:668,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"1903",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:669,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"1904",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:670,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"1905",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:671,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"1906",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:672,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"1907",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:673,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"1908",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:674,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"1909",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:675,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"2901",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:676,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"2902",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:677,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"2903",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:678,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"2904",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:679,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"2905",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:680,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"2906",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:681,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"2907",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"PAINTING",EREC:"Y"},
      // {NO:682,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"2908",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:683,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"3903",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:684,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"3904",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:685,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"3905",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:686,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"3906",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:687,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"3907",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:688,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"3908",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:689,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"4904",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:690,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"4905",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:691,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"4907",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:692,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"4908",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:693,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"5904",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:694,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"5905",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:695,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"5907",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:696,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"6907",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:697,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"7907",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:698,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"8907",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:699,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"9907",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      // {NO:700,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"MS1",AREA:"Living Quarters",TYPE:"UNIT",EREC:"Y"},
      // {NO:701,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"MS2",AREA:"Living Quarters",TYPE:"UNIT",EREC:"Y"},
      {NO:702,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME01",BLOCK:"E1A1",AREA:"Flash Gas Compression",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      {NO:703,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME01",BLOCK:"E1A2",AREA:"Flash Gas Compression",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      {NO:704,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME01",BLOCK:"E1B1",AREA:"Flash Gas Compression",TYPE:"UNIT",STATUS:"PAINTING",EREC:"Y"},
      {NO:705,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME01",BLOCK:"E1B2",AREA:"Flash Gas Compression",TYPE:"UNIT",STATUS:"PAINTING",EREC:"Y"},
      {NO:706,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME01",BLOCK:"E1M1",AREA:"Flash Gas Compression",TYPE:"UNIT",STATUS:"PAINTING",EREC:"Y"},
      {NO:707,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME01",BLOCK:"E1C1",AREA:"Flash Gas Compression",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
      {NO:708,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME01",BLOCK:"E1C2",AREA:"Flash Gas Compression",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
      {NO:709,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME01",BLOCK:"E1D",AREA:"Flash Gas Compression",TYPE:"UNIT",EREC:"Y"},
      {NO:710,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME01",BLOCK:"E1ST",AREA:"Flash Gas Compression",TYPE:"UNIT",STATUS:"PAINTING",EREC:"Y"},
      {NO:711,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME01",BLOCK:"E1ST1",AREA:"Flash Gas Compression",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
      {NO:712,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME02",BLOCK:"E2A1",AREA:"Meg & Fuel Gas",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      {NO:713,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME02",BLOCK:"E2A2",AREA:"Meg & Fuel Gas",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      {NO:714,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME02",BLOCK:"E2M1",AREA:"Meg & Fuel Gas",TYPE:"UNIT",STATUS:"PAINTING",EREC:"Y"},
      {NO:715,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME02",BLOCK:"E2B",AREA:"Meg & Fuel Gas",TYPE:"UNIT",STATUS:"PAINTING",EREC:"Y"},
      {NO:716,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME02",BLOCK:"E2C",AREA:"Meg & Fuel Gas",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
      {NO:717,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME02",BLOCK:"E2D",AREA:"Meg & Fuel Gas",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
      {NO:718,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME02",BLOCK:"E2ST",AREA:"Meg & Fuel Gas",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
      {NO:719,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME04",BLOCK:"E4A1",AREA:"Power Generation",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      {NO:720,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME04",BLOCK:"E4A2",AREA:"Power Generation",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      {NO:721,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME04",BLOCK:"E4B",AREA:"Power Generation",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      {NO:722,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME04",BLOCK:"E4C",AREA:"Power Generation",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
      {NO:723,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME04",BLOCK:"E4ST",AREA:"Power Generation",TYPE:"UNIT",STATUS:"PAINTING",EREC:"Y"},
      {NO:724,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME04",BLOCK:"E4SU",AREA:"Power Generation",TYPE:"UNIT",EREC:"Y"},
      {NO:725,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME04",BLOCK:"E4ST1",AREA:"Power Generation",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
      {NO:726,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME05",BLOCK:"E5A1",AREA:"E/I Building",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      {NO:727,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME05",BLOCK:"E5A2",AREA:"E/I Building",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      {NO:728,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME05",BLOCK:"E5B",AREA:"E/I Building",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      {NO:729,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME05",BLOCK:"E5C",AREA:"E/I Building",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      {NO:730,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME05",BLOCK:"E5D",AREA:"E/I Building",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      {NO:731,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME05",BLOCK:"E5E",AREA:"E/I Building",TYPE:"UNIT",STATUS:"PAINTING",EREC:"Y"},
      {NO:732,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"ME05",BLOCK:"E5ST",AREA:"E/I Building",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
      {NO:733,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW01",BLOCK:"W1A1",AREA:"Reception Facilities & Production Separation",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      {NO:734,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW01",BLOCK:"W1A2",AREA:"Reception Facilities & Production Separation",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      {NO:735,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW01",BLOCK:"W1M1",AREA:"Reception Facilities & Production Separation",TYPE:"UNIT",STATUS:"PAINTING",EREC:"Y"},
      {NO:736,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW01",BLOCK:"W1M2",AREA:"Reception Facilities & Production Separation",TYPE:"UNIT",EREC:"Y"},
      {NO:737,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW01",BLOCK:"W1B1",AREA:"Reception Facilities & Production Separation",TYPE:"UNIT",STATUS:"PAINTING",EREC:"Y"},
      {NO:738,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW01",BLOCK:"W1B2",AREA:"Reception Facilities & Production Separation",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
      {NO:739,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW01",BLOCK:"W1C1",AREA:"Reception Facilities & Production Separation",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
      {NO:740,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW01",BLOCK:"W1C2",AREA:"Reception Facilities & Production Separation",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
      {NO:741,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW01",BLOCK:"W1D",AREA:"Reception Facilities & Production Separation",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
      {NO:742,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW01",BLOCK:"W1E",AREA:"Reception Facilities & Production Separation",TYPE:"UNIT",STATUS:"FABSTART",EREC:"Y"},
      {NO:743,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW01",BLOCK:"W1ST",AREA:"Reception Facilities & Production Separation",TYPE:"UNIT",STATUS:"PAINTING",EREC:"Y"},
      {NO:744,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW01",BLOCK:"W1ST1",AREA:"Reception Facilities & Production Separation",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
      {NO:745,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW02",BLOCK:"W2A1",AREA:"Hc Dewpointing & Condensate Stabilisation",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      {NO:746,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW02",BLOCK:"W2A2",AREA:"Hc Dewpointing & Condensate Stabilisation",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      {NO:747,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW02",BLOCK:"W2M1",AREA:"Hc Dewpointing & Condensate Stabilisation",TYPE:"UNIT",STATUS:"PAINTING",EREC:"Y"},
      {NO:748,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW02",BLOCK:"W2M2",AREA:"Hc Dewpointing & Condensate Stabilisation",TYPE:"UNIT",EREC:"Y"},
      {NO:749,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW02",BLOCK:"W2B1",AREA:"Hc Dewpointing & Condensate Stabilisation",TYPE:"UNIT",STATUS:"PAINTING",EREC:"Y"},
      {NO:750,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW02",BLOCK:"W2B2",AREA:"Hc Dewpointing & Condensate Stabilisation",TYPE:"UNIT",STATUS:"PAINTING",EREC:"Y"},
      {NO:751,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW02",BLOCK:"W2C1",AREA:"Hc Dewpointing & Condensate Stabilisation",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
      {NO:752,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW02",BLOCK:"W2C2",AREA:"Hc Dewpointing & Condensate Stabilisation",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
      {NO:753,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW02",BLOCK:"W2D",AREA:"Hc Dewpointing & Condensate Stabilisation",TYPE:"UNIT",STATUS:"PAINTING",EREC:"Y"},
      {NO:754,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW02",BLOCK:"W2ST",AREA:"Hc Dewpointing & Condensate Stabilisation",TYPE:"UNIT",STATUS:"PAINTING",EREC:"Y"},
      {NO:755,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW02",BLOCK:"W2ST1",AREA:"Hc Dewpointing & Condensate Stabilisation",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
      {NO:756,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW04",BLOCK:"W4A1",AREA:"Power Generation",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      {NO:757,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW04",BLOCK:"W4A2",AREA:"Power Generation",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      {NO:758,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW04",BLOCK:"W4B",AREA:"Power Generation",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      {NO:759,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW04",BLOCK:"W4C",AREA:"Power Generation",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
      {NO:760,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW04",BLOCK:"W4ST",AREA:"Power Generation",TYPE:"UNIT",STATUS:"PAINTING",EREC:"Y"},
      {NO:761,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW04",BLOCK:"W4SU",AREA:"Power Generation",TYPE:"UNIT",EREC:"Y"},
      {NO:762,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW04",BLOCK:"W4ST1",AREA:"Power Generation",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
      {NO:763,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW05",BLOCK:"W5A1",AREA:"Utility",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      {NO:764,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW05",BLOCK:"W5A2",AREA:"Utility",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      {NO:765,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW05",BLOCK:"W5M1",AREA:"Utility",TYPE:"UNIT",STATUS:"PAINTING",EREC:"Y"},
      {NO:766,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW05",BLOCK:"W5B1",AREA:"Utility",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      {NO:767,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW05",BLOCK:"W5B2",AREA:"Utility",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      {NO:768,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW05",BLOCK:"W5C1",AREA:"Utility",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      {NO:769,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW05",BLOCK:"W5C2",AREA:"Utility",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      {NO:770,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW05",BLOCK:"W5D",AREA:"Utility",TYPE:"UNIT",STATUS:"PAINTING",EREC:"Y"},
      {NO:771,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW05",BLOCK:"W5ST",AREA:"Utility",TYPE:"UNIT",STATUS:"PAINTING",EREC:"Y"},
      {NO:772,Lv1:"TOPSIDE",Lv2:"MODULES",Lv3:"MW05",BLOCK:"W5ST1",AREA:"Utility",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
      {NO:773,Lv1:"TOPSIDE",Lv2:"COMMON",Lv3:"LD00DA",BLOCK:"LDA1",AREA:"Laydown Deck",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
      {NO:774,Lv1:"TOPSIDE",Lv2:"COMMON",Lv3:"LD00DA",BLOCK:"LDA2",AREA:"Laydown Deck",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
      {NO:775,Lv1:"TOPSIDE",Lv2:"COMMON",Lv3:"LD00DA",BLOCK:"LDA3",AREA:"Laydown Deck",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
      {NO:776,Lv1:"TOPSIDE",Lv2:"COMMON",Lv3:"TSRC01",BLOCK:"RC11",AREA:"Topsides Piperack",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      {NO:777,Lv1:"TOPSIDE",Lv2:"COMMON",Lv3:"TSRC01",BLOCK:"RC12",AREA:"Topsides Piperack",TYPE:"UNIT",STATUS:"PAINTING",EREC:"Y"},
      {NO:778,Lv1:"TOPSIDE",Lv2:"COMMON",Lv3:"TSRC02",BLOCK:"RC21",AREA:"Topsides Piperack",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
      {NO:779,Lv1:"TOPSIDE",Lv2:"COMMON",Lv3:"TSRC02",BLOCK:"RC22",AREA:"Topsides Piperack",TYPE:"UNIT",STATUS:"PAINTING",EREC:"Y"},
      {NO:780,Lv1:"TOPSIDE",Lv2:"COMMON",Lv3:"TSRC03",BLOCK:"RC31",AREA:"Topsides Piperack",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      {NO:781,Lv1:"TOPSIDE",Lv2:"COMMON",Lv3:"TSRC03",BLOCK:"RC32",AREA:"Topsides Piperack",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      {NO:782,Lv1:"TOPSIDE",Lv2:"COMMON",Lv3:"TSRC03",BLOCK:"RC33",AREA:"Topsides Piperack",TYPE:"UNIT",STATUS:"ASSEMBLY",EREC:"Y"},
      {NO:783,Lv1:"TOPSIDE",Lv2:"COMMON",Lv3:"TSRC03",BLOCK:"RC34",AREA:"Topsides Piperack",TYPE:"UNIT",STATUS:"ASSEMBLY",EREC:"Y"},
      {NO:784,Lv1:"TOPSIDE",Lv2:"COMMON",Lv3:"TSRC04",BLOCK:"RC41",AREA:"Topsides Piperack",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      {NO:785,Lv1:"TOPSIDE",Lv2:"COMMON",Lv3:"TSRC04",BLOCK:"RC42",AREA:"Topsides Piperack",TYPE:"UNIT",STATUS:"ASSEMBLY",EREC:"Y"},
      {NO:786,Lv1:"TOPSIDE",Lv2:"COMMON",Lv3:"TSRC05",BLOCK:"RC51",AREA:"Topsides Piperack",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
      {NO:787,Lv1:"TOPSIDE",Lv2:"COMMON",Lv3:"TSRC05",BLOCK:"RC52",AREA:"Topsides Piperack",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
      {NO:788,Lv1:"TOPSIDE",Lv2:"COMMON",Lv3:"FL01",BLOCK:"FL1A",AREA:"Flare Stack",TYPE:"UNIT",STATUS:"PAINTING",EREC:"Y"},
      {NO:789,Lv1:"TOPSIDE",Lv2:"COMMON",Lv3:"FL01",BLOCK:"FL1B",AREA:"Flare Stack",TYPE:"UNIT",EREC:"Y"},
      {NO:790,Lv1:"TOPSIDE",Lv2:"COMMON",Lv3:"FL01",BLOCK:"FL1C",AREA:"Flare Stack",TYPE:"UNIT",EREC:"Y"},
      {NO:791,Lv1:"TOPSIDE",Lv2:"COMMON",Lv3:"FL01",BLOCK:"PSV",AREA:"Flare Stack",TYPE:"UNIT",EREC:"Y"},
      {NO:792,Lv1:"TOPSIDE",Lv2:"COMMON",Lv3:"FR01",BLOCK:"FR1",AREA:"Flare Piperack",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
      {NO:793,Lv1:"TOPSIDE",Lv2:"COMMON",Lv3:"KO01",BLOCK:"K1C1",AREA:"Flare KO Drum",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
      {NO:794,Lv1:"TOPSIDE",Lv2:"COMMON",Lv3:"KO01",BLOCK:"K1C2",AREA:"Flare KO Drum",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
      {NO:795,Lv1:"TOPSIDE",Lv2:"COMMON",Lv3:"KO01",BLOCK:"K1C3",AREA:"Flare KO Drum",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"}
    ],
}