import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 530,
      CanvasHeight: 390,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: '', x:10, y: 0, font: 'roboto', class: 'regular', size: 12, color: '#424242', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: -50, y: -30, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'start' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: -30,
          y: -30,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'start'
        },
      ]
    },
    Queries: {
      SQL1: [
      ],
    },
    DataItems: [
      {DESCRIPTION:"AFD-Air Flow Diagram",TOTAL:15,ISSUED:6,REMAIN:9,PROG:40},
      {DESCRIPTION:"BID-Bid Evaluation",TOTAL:196,ISSUED:123,REMAIN:73,PROG:62.76},
      {DESCRIPTION:"BLK-Block Diagram",TOTAL:105,ISSUED:14,REMAIN:91,PROG:13.33},
      {DESCRIPTION:"CAB-Cable",TOTAL:106,ISSUED:0,REMAIN:106,PROG:0},
      {DESCRIPTION:"CAL-Calculation",TOTAL:110,ISSUED:45,REMAIN:65,PROG:40.91},
      {DESCRIPTION:"CEM-Cause and Effect Drawings",TOTAL:8,ISSUED:7,REMAIN:1,PROG:87.5},
      {DESCRIPTION:"CEP-Construction Execution Plan/Construction Plan",TOTAL:1,ISSUED:1,REMAIN:0,PROG:100},
      {DESCRIPTION:"CER-Certification",TOTAL:1,ISSUED:1,REMAIN:0,PROG:100},
      {DESCRIPTION:"CNO-Change Order/Notices",TOTAL:4,ISSUED:4,REMAIN:0,PROG:100},
      {DESCRIPTION:"COM-Electrical Communications Details",TOTAL:1,ISSUED:1,REMAIN:0,PROG:100},
      {DESCRIPTION:"CPT-Conceptual Design",TOTAL:2,ISSUED:2,REMAIN:0,PROG:100},
      {DESCRIPTION:"DCC-Document Control Procedures",TOTAL:1,ISSUED:1,REMAIN:0,PROG:100},
      {DESCRIPTION:"DES-Project Design Criteria/Philosophy",TOTAL:300,ISSUED:179,REMAIN:121,PROG:59.67},
      {DESCRIPTION:"DET-Detail",TOTAL:328,ISSUED:93,REMAIN:235,PROG:28.35},
      {DESCRIPTION:"DEV-Specification Deviation",TOTAL:10,ISSUED:7,REMAIN:3,PROG:70},
      {DESCRIPTION:"DID-HVAC Ducting and Instrumentation Diagrams",TOTAL:2,ISSUED:0,REMAIN:2,PROG:0},
      {DESCRIPTION:"DWG-Drawing",TOTAL:1429,ISSUED:715,REMAIN:714,PROG:50.03},
      {DESCRIPTION:"ECI-Electrical Interconnection Diagrams and Details",TOTAL:20,ISSUED:0,REMAIN:20,PROG:0},
      {DESCRIPTION:"ECP-CableTray/BusDuct/CableWay/RacewayPlans",TOTAL:76,ISSUED:20,REMAIN:56,PROG:26.32},
      {DESCRIPTION:"EQL-Equipment Arrangement Drawings",TOTAL:326,ISSUED:291,REMAIN:35,PROG:89.26},
      {DESCRIPTION:"FAT-Factory/Field Acceptance Test Procedures",TOTAL:2,ISSUED:0,REMAIN:2,PROG:0},
      {DESCRIPTION:"FND-Foundation Design",TOTAL:828,ISSUED:736,REMAIN:92,PROG:88.89},
      {DESCRIPTION:"GND-Grounding Details",TOTAL:165,ISSUED:110,REMAIN:55,PROG:66.67},
      {DESCRIPTION:"HAC-Hazardous Area Compliance",TOTAL:36,ISSUED:23,REMAIN:13,PROG:63.89},
      {DESCRIPTION:"HAZ-Hazard and Operability Studies",TOTAL:12,ISSUED:11,REMAIN:1,PROG:91.67},
      {DESCRIPTION:"ICD-Instrument Cable Tray Plan and Details",TOTAL:40,ISSUED:32,REMAIN:8,PROG:80},
      {DESCRIPTION:"IIN-Instrument Index",TOTAL:76,ISSUED:70,REMAIN:6,PROG:92.11},
      {DESCRIPTION:"ILD-Instrument Loop Drawings (Instrumentation/Telecom/HVAC)",TOTAL:65,ISSUED:0,REMAIN:65,PROG:0},
      {DESCRIPTION:"IMP-Information Management Plan",TOTAL:1,ISSUED:1,REMAIN:0,PROG:100},
      {DESCRIPTION:"ISO-Isometric",TOTAL:38,ISSUED:0,REMAIN:38,PROG:0},
      {DESCRIPTION:"ISR-Industry Standards/Specifications/Recommended Practices",TOTAL:10,ISSUED:10,REMAIN:0,PROG:100},
      {DESCRIPTION:"ITP-Inspection and Test Plan",TOTAL:15,ISSUED:3,REMAIN:12,PROG:20},
      {DESCRIPTION:"LAY-Outline Drawings/Layout Drawings/Floor Plans",TOTAL:573,ISSUED:374,REMAIN:199,PROG:65.27},
      {DESCRIPTION:"LAY-Outline/Layout/Floor Plans/General Arrangements",TOTAL:192,ISSUED:0,REMAIN:192,PROG:0},
      {DESCRIPTION:"LGT-Lighting Plans and Electrical Lighting Details",TOTAL:149,ISSUED:65,REMAIN:84,PROG:43.62},
      {DESCRIPTION:"LLI-Line List",TOTAL:72,ISSUED:51,REMAIN:21,PROG:70.83},
      {DESCRIPTION:"LSC-Electrical Load List",TOTAL:26,ISSUED:13,REMAIN:13,PROG:50},
      {DESCRIPTION:"LST-List",TOTAL:261,ISSUED:140,REMAIN:121,PROG:53.64},
      {DESCRIPTION:"MAN-Manual",TOTAL:2,ISSUED:0,REMAIN:2,PROG:0},
      {DESCRIPTION:"MAP-Maps",TOTAL:39,ISSUED:38,REMAIN:1,PROG:97.44},
      {DESCRIPTION:"MDR-Master Document Register/Supplier MDR",TOTAL:4,ISSUED:4,REMAIN:0,PROG:100},
      {DESCRIPTION:"MEL-Master Equipment List",TOTAL:172,ISSUED:68,REMAIN:104,PROG:39.53},
      {DESCRIPTION:"MOB-Contractor Mobilization/Demob Authorization",TOTAL:1,ISSUED:1,REMAIN:0,PROG:100},
      {DESCRIPTION:"MOC-Management of Change",TOTAL:1,ISSUED:1,REMAIN:0,PROG:100},
      {DESCRIPTION:"MTO-Bill of Materials/Assemblies and Components",TOTAL:131,ISSUED:10,REMAIN:121,PROG:7.63},
      {DESCRIPTION:"NA",TOTAL:222,ISSUED:0,REMAIN:222,PROG:0},
      {DESCRIPTION:"NAR-Control Narratives",TOTAL:1,ISSUED:1,REMAIN:0,PROG:100},
      {DESCRIPTION:"NET-System Network Arrangement Diagram",TOTAL:1,ISSUED:0,REMAIN:1,PROG:0},
      {DESCRIPTION:"ORG-Organization/Mob and Demob/Functional Engagement",TOTAL:1,ISSUED:1,REMAIN:0,PROG:100},
      {DESCRIPTION:"PDS-Project Design Specification",TOTAL:1083,ISSUED:980,REMAIN:103,PROG:90.49},
      {DESCRIPTION:"PEL-Piping Elevations and Sections",TOTAL:1,ISSUED:1,REMAIN:0,PROG:100},
      {DESCRIPTION:"PFD-Process Flow Diagrams",TOTAL:254,ISSUED:254,REMAIN:0,PROG:100},
      {DESCRIPTION:"PGA-Piping Schematics and Arrangement",TOTAL:1249,ISSUED:1,REMAIN:1248,PROG:0.08},
      {DESCRIPTION:"PID-Piping and Instrumentation Diagrams",TOTAL:2185,ISSUED:2002,REMAIN:183,PROG:91.62},
      {DESCRIPTION:"PIL-Piles",TOTAL:223,ISSUED:180,REMAIN:43,PROG:80.72},
      {DESCRIPTION:"PLN-Plan",TOTAL:127,ISSUED:106,REMAIN:21,PROG:83.46},
      {DESCRIPTION:"POW-Power System",TOTAL:82,ISSUED:6,REMAIN:76,PROG:7.32},
      {DESCRIPTION:"PPP-Project Plan/Early Project Plan/Road Map",TOTAL:3,ISSUED:1,REMAIN:2,PROG:33.33},
      {DESCRIPTION:"PRI-Primary Structural Steel Sections and Details",TOTAL:5,ISSUED:2,REMAIN:3,PROG:40},
      {DESCRIPTION:"PRO-Procedure",TOTAL:33,ISSUED:30,REMAIN:3,PROG:90.91},
      {DESCRIPTION:"PSD-Pipe Support and Details",TOTAL:8,ISSUED:0,REMAIN:8,PROG:0},
      {DESCRIPTION:"QAC-Quality Control and Assurance Management",TOTAL:1,ISSUED:0,REMAIN:1,PROG:0},
      {DESCRIPTION:"RAK-Risk Assessment",TOTAL:3,ISSUED:2,REMAIN:1,PROG:66.67},
      {DESCRIPTION:"RCC-Concrete/Masonry",TOTAL:229,ISSUED:200,REMAIN:29,PROG:87.34},
      {DESCRIPTION:"REQ-Requisitions",TOTAL:591,ISSUED:483,REMAIN:108,PROG:81.73},
      {DESCRIPTION:"RLY-Relay Settings",TOTAL:20,ISSUED:0,REMAIN:20,PROG:0},
      {DESCRIPTION:"RPT-Report/Study",TOTAL:4,ISSUED:4,REMAIN:0,PROG:100},
      {DESCRIPTION:"RRR-Project Roles and Responsibilities",TOTAL:1,ISSUED:0,REMAIN:1,PROG:0},
      {DESCRIPTION:"SAT-Site Acceptance Test",TOTAL:1,ISSUED:0,REMAIN:1,PROG:0},
      {DESCRIPTION:"SCH-Schedule",TOTAL:18,ISSUED:10,REMAIN:8,PROG:55.56},
      {DESCRIPTION:"SEL-Safety Equipment List",TOTAL:78,ISSUED:33,REMAIN:45,PROG:42.31},
      {DESCRIPTION:"SFP-Steel Framing Plan",TOTAL:1158,ISSUED:700,REMAIN:458,PROG:60.45},
      {DESCRIPTION:"SLD-One-Line/Single-Line Diagram/Drawings",TOTAL:269,ISSUED:36,REMAIN:233,PROG:13.38},
      {DESCRIPTION:"SPC-Specialty Items Index",TOTAL:8,ISSUED:7,REMAIN:1,PROG:87.5},
      {DESCRIPTION:"STL-Structural Steel",TOTAL:5,ISSUED:3,REMAIN:2,PROG:60},
      {DESCRIPTION:"STS-Stress Analysis",TOTAL:9,ISSUED:0,REMAIN:9,PROG:0},
      {DESCRIPTION:"SYM-Legends and Symbols",TOTAL:65,ISSUED:59,REMAIN:6,PROG:90.77},
      {DESCRIPTION:"TDS-Technical Data Sheet",TOTAL:921,ISSUED:716,REMAIN:205,PROG:77.74},
      {DESCRIPTION:"TQM-Technology Qualification",TOTAL:79,ISSUED:6,REMAIN:73,PROG:7.59},
      {DESCRIPTION:"TQS-Technical Queries",TOTAL:8,ISSUED:8,REMAIN:0,PROG:100},
      {DESCRIPTION:"UFD-Utility Flow Diagrams",TOTAL:13,ISSUED:13,REMAIN:0,PROG:100},
      {DESCRIPTION:"VSL-Approved Vendors/Suppliers List",TOTAL:92,ISSUED:1,REMAIN:91,PROG:1.09},
      {DESCRIPTION:"WDS-Wiring Diagrams",TOTAL:70,ISSUED:0,REMAIN:70,PROG:0},
      {DESCRIPTION:"WPS-Weld Procedure Specifications",TOTAL:3,ISSUED:0,REMAIN:3,PROG:0},
      {DESCRIPTION:"ZZZ-Administration-Other",TOTAL:1,ISSUED:1,REMAIN:0,PROG:100}
],
}