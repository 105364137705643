import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 600,
    CanvasHeight: 280,
    CanvasBorderWeight: 0.3,
    CanvasBorderColor: 'Transparent',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.2,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 310,
    CanvasChartHeight: 270,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: 'Transparent',
    CanvasChartFillColor: 'Transparent',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },

    // ----------------------------------------------- NOTE
  Note: {
    NoteDisplay: 'N',
    NoteX: 50,
    NoteY: 420,
    NoteAlign: 'start',
    NoteTitleText: 'Notes.',
    NoteTitleFont: 'roboto',
    NoteTitleStyle: 'regular',
    NoteTitleSize: 11,
    NoteTitleColor: '#757575',

    NoteContentsText: 'Note Contents Here!',
    NoteContentsFont: 'roboto',
    NoteContentsStyle: 'regular',
    NoteContentsSize: 10,
    NoteContentsColor: '#BDBCBC',
  },

  DataItems: [
    {PHASE_SEQ:5,PHASE:"5.Construction - Topsides",DISC:"ARCHITECTURE",PLAN_A18_WK:0,PLAN_A24_WK:0,ACTUAL_WK:0,ACTUAL_CERT_WK:0,VAR_WK:0,PRE_PLAN_CUM:0,PLAN_CUM:0,ACTUAL_CUM:0,ACTUAL_CERT_CUM:0,VAR_CUM:0},
    {PHASE_SEQ:5,PHASE:"5.Construction - Topsides",DISC:"ELECTRICAL",PLAN_A18_WK:0,PLAN_A24_WK:1.6,ACTUAL_WK:0,ACTUAL_CERT_WK:0,VAR_WK:-1.6,PRE_PLAN_CUM:0,PLAN_CUM:24.8,ACTUAL_CUM:0,ACTUAL_CERT_CUM:0,VAR_CUM:-24.8},
    {PHASE_SEQ:5,PHASE:"5.Construction - Topsides",DISC:"FIRE & SAFETY",PLAN_A18_WK:0,PLAN_A24_WK:0,ACTUAL_WK:0,ACTUAL_CERT_WK:0,VAR_WK:0,PRE_PLAN_CUM:0,PLAN_CUM:0,ACTUAL_CUM:0,ACTUAL_CERT_CUM:0,VAR_CUM:0},
    {PHASE_SEQ:5,PHASE:"5.Construction - Topsides",DISC:"HVAC",PLAN_A18_WK:0,PLAN_A24_WK:17.3,ACTUAL_WK:0,ACTUAL_CERT_WK:0,VAR_WK:-17.3,PRE_PLAN_CUM:0,PLAN_CUM:23.5,ACTUAL_CUM:0,ACTUAL_CERT_CUM:0,VAR_CUM:-23.5},
    {PHASE_SEQ:5,PHASE:"5.Construction - Topsides",DISC:"INSTRUMENT",PLAN_A18_WK:0,PLAN_A24_WK:0,ACTUAL_WK:0,ACTUAL_CERT_WK:0,VAR_WK:0,PRE_PLAN_CUM:0,PLAN_CUM:0,ACTUAL_CUM:0,ACTUAL_CERT_CUM:0,VAR_CUM:0},
    {PHASE_SEQ:5,PHASE:"5.Construction - Topsides",DISC:"INSULATION",PLAN_A18_WK:0,PLAN_A24_WK:0,ACTUAL_WK:0,ACTUAL_CERT_WK:0,VAR_WK:0,PRE_PLAN_CUM:0,PLAN_CUM:0,ACTUAL_CUM:0,ACTUAL_CERT_CUM:0,VAR_CUM:0},
    {PHASE_SEQ:5,PHASE:"5.Construction - Topsides",DISC:"MECHANICAL",PLAN_A18_WK:0,PLAN_A24_WK:0,ACTUAL_WK:0,ACTUAL_CERT_WK:0,VAR_WK:0,PRE_PLAN_CUM:0,PLAN_CUM:0,ACTUAL_CUM:0,ACTUAL_CERT_CUM:0,VAR_CUM:0},
    {PHASE_SEQ:5,PHASE:"5.Construction - Topsides",DISC:"PAINTING",PLAN_A18_WK:0,PLAN_A24_WK:0.1,ACTUAL_WK:0,ACTUAL_CERT_WK:0,VAR_WK:-0.1,PRE_PLAN_CUM:0,PLAN_CUM:0.7,ACTUAL_CUM:0,ACTUAL_CERT_CUM:0,VAR_CUM:-0.7},
    {PHASE_SEQ:5,PHASE:"5.Construction - Topsides",DISC:"PIPING",PLAN_A18_WK:0,PLAN_A24_WK:0.6,ACTUAL_WK:0,ACTUAL_CERT_WK:0,VAR_WK:-0.6,PRE_PLAN_CUM:0,PLAN_CUM:1.9,ACTUAL_CUM:0,ACTUAL_CERT_CUM:0,VAR_CUM:-1.9},
    {PHASE_SEQ:5,PHASE:"5.Construction - Topsides",DISC:"STRUCTURE",PLAN_A18_WK:0,PLAN_A24_WK:1.1,ACTUAL_WK:0.8,ACTUAL_CERT_WK:0,VAR_WK:-0.3,PRE_PLAN_CUM:0,PLAN_CUM:53.6,ACTUAL_CUM:14,ACTUAL_CERT_CUM:5.7,VAR_CUM:-39.6},
    {PHASE_SEQ:5,PHASE:"5.Construction - Topsides",DISC:"Total",PLAN_A18_WK:0,PLAN_A24_WK:1,ACTUAL_WK:0.2,ACTUAL_CERT_WK:0,VAR_WK:-0.8,PRE_PLAN_CUM:0,PLAN_CUM:15.8,ACTUAL_CUM:3.5,ACTUAL_CERT_CUM:1.4,VAR_CUM:-12.3}
  ]
}