import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 580,
      CanvasHeight: 150,
      CanvasBorderWeight: 0.5,
      CanvasBorderColor: '#ccc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Weight : ', x:20, y: 0, font: 'roboto', class: 'regular', size: 11, color: '#BCBCBC', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: 20, y: 0, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'end' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: 20,
          y: 0,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'end'
        },
      ]
    },
    DataItems: [
      {NAME:"Daily",SUMMARY:"Ready for Dynamic Commissioning - RFDC Total",PLANNED:220,ACTUAL_TODAY:220,DAILY_CHANGE: 2},
      {NAME:"Daily",SUMMARY:"Ready for Dynamic Commissioning - RFDC Complete(Full)",PLANNED:49,ACTUAL_TODAY:0,DAILY_CHANGE: -2},
      {NAME:"Daily",SUMMARY:"Ready for Dynamic Commissioning - RFDC Complete(Partial)",PLANNED:0,ACTUAL_TODAY:0,DAILY_CHANGE:3 },
    ],
}