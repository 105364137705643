import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 670,
    CanvasHeight: 195,
    CanvasBorderWeight: 0.3,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.2,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 310,
    CanvasChartHeight: 270,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: 'Transparent',
    CanvasChartFillColor: 'Transparent',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  Text: {
    Text: [],
    Filter: [],
    Sql: [
      {
        query: "SELECT 'Datasource: Shop Drawing Index 04-Oct-20' TITLE", 
        text: 'Datasource: Shop Drawing Index 04-Oct-20',
        x: 10,
        y: 10,
        font: 'roboto',
        style: 'regular',
        size: 12,
        color: '#757575',
        align: 'start'
      },
    ]
  },
  DataItems: [
    {GWBS_LV2:"HULL-FOR",TOTAL:4450,PLAN:4431,ACTUAL:4383,REMAIN:67,PLAN_PROG:99.57,ACTUAL_PROG:98.49}
  ]
}