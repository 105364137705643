import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 2300,
    CanvasHeight: 980,
    CanvasBorderWeight: 0.05,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.1,

    CanvasChartX: 50,
    CanvasChartY: 50,
    CanvasChartWidth: 480,
    CanvasChartHeight: 250,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  DataItems:   [
    {SUBSYSTEM:"21-1001-1001-01",SUBSYSTEM_DESCR:"Annulus Riser vent Monitoring System",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-07-28",A_OV_TOT:12,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:12,A_OV_PROG:0,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-1010-1011-01",SUBSYSTEM_DESCR:"Flowline 1 and Receiver",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-07-07",A_OV_TOT:213,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:213,A_OV_PROG:0,B_OV_TOT:37,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:37,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:1,ACT_TAG_VALID:216,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-1010-1012-01",SUBSYSTEM_DESCR:"Flowline 2 and Receiver",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-07-07",A_OV_TOT:210,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:210,A_OV_PROG:0,B_OV_TOT:36,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:36,B_OV_PROG:0,ACT_TAG_VALID:211,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-1510-1510-01",SUBSYSTEM_DESCR:"MEG Riser and Pipeline System to DC1",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-26",A_OV_TOT:8,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:8,A_OV_PROG:0,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-1600-1611-01",SUBSYSTEM_DESCR:"Gas Export Riser 1",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-26",A_OV_TOT:41,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:41,A_OV_PROG:0,B_OV_TOT:9,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:9,B_OV_PROG:0,ACT_TAG_VALID:40,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-1600-1612-01",SUBSYSTEM_DESCR:"Gas Export Riser 2",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-26",A_OV_TOT:41,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:41,A_OV_PROG:0,B_OV_TOT:10,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:10,B_OV_PROG:0,ACT_TAG_VALID:41,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-1911-1911-01",SUBSYSTEM_DESCR:"Hydraulic HPUs, RIOs and TUTUs",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-06-23",A_OV_TOT:88,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:88,A_OV_PROG:0,B_OV_TOT:4,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:4,B_OV_PROG:0,ACT_TAG_VALID:89,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-1931-1931-01",SUBSYSTEM_DESCR:"Subsea Control System ,MCS, SSIV SPCU, EPUs, HVE JBs and LVE JBs and Subsea ICSS",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-06-30",A_OV_TOT:76,A_OV_COM:15,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:61,A_OV_PROG:19.73,B_OV_TOT:14,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:14,B_OV_PROG:0,PB1_OV_TOT:7,PB1_OV_REM:6,PB2_OV_TOT:13,PB2_OV_REM:13,PREVENT_DC_REM:20,ACT_TAG_VALID:78,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-2200-2131-01",SUBSYSTEM_DESCR:"Slug Catcher 1 and Liquid Heater 1",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-04-28",A_OV_TOT:282,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:282,A_OV_PROG:0,B_OV_TOT:49,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:49,B_OV_PROG:0,PB1_OV_TOT:6,PB1_OV_REM:5,PB2_OV_TOT:2,PB2_OV_REM:2,PREVENT_DC_REM:54,ACT_TAG_VALID:280,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2200-2132-01",SUBSYSTEM_DESCR:"Slug Catcher 2 and Liquid heater 2",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-07-07",A_OV_TOT:270,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:270,A_OV_PROG:0,B_OV_TOT:46,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:46,B_OV_PROG:0,PB1_OV_TOT:1,PB1_OV_REM:0,PREVENT_DC_REM:46,ACT_TAG_VALID:265,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2200-2221-01",SUBSYSTEM_DESCR:"MP Separator",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-07-14",A_OV_TOT:144,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:144,A_OV_PROG:0,B_OV_TOT:35,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:35,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:0,ACT_TAG_VALID:139,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2200-2230-01",SUBSYSTEM_DESCR:"LP Separator",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-06-02",A_OV_TOT:103,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:103,A_OV_PROG:0,B_OV_TOT:28,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:28,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:1,ACT_TAG_VALID:100,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2200-2230-02",SUBSYSTEM_DESCR:"Interstage Heaters",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-28",A_OV_TOT:15,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:15,A_OV_PROG:0,B_OV_TOT:4,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:4,B_OV_PROG:0,ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2200-2240-01",SUBSYSTEM_DESCR:"LLP separator, Condensate Coolers and Condensate Coalescer Pumps",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-12",A_OV_TOT:183,A_OV_COM:2,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:181,A_OV_PROG:1.09,B_OV_TOT:46,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:46,B_OV_PROG:0,PA_OV_TOT:3,PA_OV_REM:3,ACT_TAG_VALID:178,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-2200-2240-03",SUBSYSTEM_DESCR:"Condensate Coalescer Filters",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-12",A_OV_TOT:44,A_OV_COM:1,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:43,A_OV_PROG:2.27,B_OV_TOT:13,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:13,B_OV_PROG:0,PA_OV_TOT:4,PA_OV_REM:3,ACT_TAG_VALID:41,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2200-2240-04",SUBSYSTEM_DESCR:"Condensate Coalescer Package",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-05-12",A_OV_TOT:65,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:65,A_OV_PROG:0,B_OV_TOT:22,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:22,B_OV_PROG:0,PB1_OV_TOT:4,PB1_OV_REM:1,PREVENT_DC_REM:23,ACT_TAG_VALID:63,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-2200-2470-01",SUBSYSTEM_DESCR:"Warm Condensate Heater, Offspec Condensate Pump and Distribution",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-28",A_OV_TOT:135,A_OV_COM:7,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:128,A_OV_PROG:5.18,B_OV_TOT:46,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:46,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:0,PB1_OV_TOT:1,PB1_OV_REM:0,PREVENT_DC_REM:46,ACT_TAG_VALID:156,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2400-2440-01",SUBSYSTEM_DESCR:"Condensate Metering",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-06-16",A_OV_TOT:147,A_OV_COM:74,A_OV_COM_LW:4,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:73,A_OV_PROG:50.34,B_OV_TOT:78,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:78,B_OV_PROG:0,PA_OV_TOT:7,PA_OV_REM:4,PB1_OV_TOT:18,PB1_OV_REM:12,PB2_OV_TOT:16,PB2_OV_REM:15,PREVENT_DC_REM:90,ACT_TAG_VALID:206,PUNCH_NUMBER_VALID:41,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-2400-2450-01",SUBSYSTEM_DESCR:"CFW1 Offspec Condensate Tank and Pumps ,Incl Washing Machine",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-06-02",A_OV_TOT:143,A_OV_COM:7,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:5,A_OV_REM:136,A_OV_PROG:4.89,B_OV_TOT:35,B_OV_COM:1,B_OV_COM_CLIENT:0,B_OV_REM:34,B_OV_PROG:2.85,PA_OV_TOT:1,PA_OV_REM:1,PB1_OV_TOT:3,PB1_OV_REM:1,PB2_OV_TOT:2,PB2_OV_REM:1,PREVENT_DC_REM:35,ACT_TAG_VALID:134,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-2400-2460-01",SUBSYSTEM_DESCR:"Condensate Offloading",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-06-16",A_OV_TOT:173,A_OV_COM:75,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:98,A_OV_PROG:43.35,B_OV_TOT:75,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:75,B_OV_PROG:0,PB1_OV_TOT:7,PB1_OV_REM:2,PB2_OV_TOT:3,PB2_OV_REM:3,PREVENT_DC_REM:77,ACT_TAG_VALID:228,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-2400-9360-01",SUBSYSTEM_DESCR:"Cargo Oil Washing Header and Distribution",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-12",A_OV_TOT:7,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:7,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:2,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-2400-9360-02",SUBSYSTEM_DESCR:"Condensate ImportandLoading Header",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-26",A_OV_TOT:59,A_OV_COM:3,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:56,A_OV_PROG:5.08,B_OV_TOT:10,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:10,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:2,ACT_TAG_VALID:51,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-2400-9360-03",SUBSYSTEM_DESCR:"Condensate Transfer Header",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-26",A_OV_TOT:275,A_OV_COM:15,A_OV_COM_LW:5,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:260,A_OV_PROG:5.45,B_OV_TOT:47,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:47,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:2,ACT_TAG_VALID:234,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-2400-9360-04",SUBSYSTEM_DESCR:"Condensate ExportandOffloading Header",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-26",A_OV_TOT:123,A_OV_COM:4,A_OV_COM_LW:2,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:119,A_OV_PROG:3.25,B_OV_TOT:21,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:21,B_OV_PROG:0,ACT_TAG_VALID:103,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2400-9361-01",SUBSYSTEM_DESCR:"COW1 Tank and Pumps ,Incl Washing Machine",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B2",MC_PLAN:"2022-05-19",A_OV_TOT:65,A_OV_COM:7,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:6,A_OV_REM:58,A_OV_PROG:10.76,B_OV_TOT:16,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:16,B_OV_PROG:0,PB2_OV_TOT:1,PB2_OV_REM:1,PB3_OV_TOT:6,PB3_OV_REM:6,RFDC_REM:7,ACT_TAG_VALID:65,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-2400-9362-01",SUBSYSTEM_DESCR:"COW2 Tank and Pumps ,Incl Washing Machine",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B3",MC_PLAN:"2022-05-26",A_OV_TOT:65,A_OV_COM:14,A_OV_COM_LW:3,A_OV_COM_TW:0,A_OV_COM_CLIENT:7,A_OV_REM:51,A_OV_PROG:21.53,B_OV_TOT:16,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:16,B_OV_PROG:0,PB3_OV_TOT:1,PB3_OV_REM:1,ACT_TAG_VALID:65,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-2400-9363-01",SUBSYSTEM_DESCR:"COW3 Tank and Pumps ,Incl Washing Machine",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-19",A_OV_TOT:64,A_OV_COM:15,A_OV_COM_LW:5,A_OV_COM_TW:0,A_OV_COM_CLIENT:8,A_OV_REM:49,A_OV_PROG:23.43,B_OV_TOT:16,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:16,B_OV_PROG:0,PA_OV_TOT:4,PA_OV_REM:4,PB2_OV_TOT:1,PB2_OV_REM:1,PB3_OV_TOT:7,PB3_OV_REM:7,RFDC_REM:8,ACT_TAG_VALID:65,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-2400-9364-01",SUBSYSTEM_DESCR:"COW4 Tank and Pumps ,Incl Washing Machine",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-26",A_OV_TOT:66,A_OV_COM:16,A_OV_COM_LW:6,A_OV_COM_TW:0,A_OV_COM_CLIENT:8,A_OV_REM:50,A_OV_PROG:24.24,B_OV_TOT:16,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:16,B_OV_PROG:0,PA_OV_TOT:6,PA_OV_REM:6,PB2_OV_TOT:1,PB2_OV_REM:1,PB3_OV_TOT:8,PB3_OV_REM:8,RFDC_REM:9,ACT_TAG_VALID:67,PUNCH_NUMBER_VALID:15,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-2400-9365-01",SUBSYSTEM_DESCR:"COE1 Tank and Pumps ,Incl Washing Machine",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-26",A_OV_TOT:64,A_OV_COM:6,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:5,A_OV_REM:58,A_OV_PROG:9.37,B_OV_TOT:16,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:16,B_OV_PROG:0,ACT_TAG_VALID:64,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2400-9366-01",SUBSYSTEM_DESCR:"COE2 Tank and Pumps ,Incl Washing Machine",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-26",A_OV_TOT:64,A_OV_COM:14,A_OV_COM_LW:7,A_OV_COM_TW:0,A_OV_COM_CLIENT:5,A_OV_REM:50,A_OV_PROG:21.87,B_OV_TOT:16,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:16,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:2,PB2_OV_TOT:8,PB2_OV_REM:8,ACT_TAG_VALID:64,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-2400-9367-01",SUBSYSTEM_DESCR:"COE3 Tank and Pumps ,Incl Washing Machine",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-26",A_OV_TOT:63,A_OV_COM:16,A_OV_COM_LW:3,A_OV_COM_TW:0,A_OV_COM_CLIENT:6,A_OV_REM:47,A_OV_PROG:25.39,B_OV_TOT:16,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:16,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:2,PB2_OV_TOT:5,PB2_OV_REM:5,ACT_TAG_VALID:63,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-2400-9368-01",SUBSYSTEM_DESCR:"COE4 Tank and Pumps ,Incl Washing Machine",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-26",A_OV_TOT:65,A_OV_COM:15,A_OV_COM_LW:2,A_OV_COM_TW:0,A_OV_COM_CLIENT:6,A_OV_REM:50,A_OV_PROG:23.07,B_OV_TOT:16,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:16,B_OV_PROG:0,PA_OV_TOT:7,PA_OV_REM:7,PB2_OV_TOT:5,PB2_OV_REM:5,ACT_TAG_VALID:65,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-2500-2510-01",SUBSYSTEM_DESCR:"Rich MEG Collection, Flash Drum and Coolers",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-06-02",A_OV_TOT:37,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:37,A_OV_PROG:0,B_OV_TOT:12,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:12,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:1,PB1_OV_TOT:1,PB1_OV_REM:0,PREVENT_DC_REM:12,ACT_TAG_VALID:39,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-2500-2510-02",SUBSYSTEM_DESCR:"MEG Transfer Header",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-12",A_OV_TOT:44,A_OV_COM:1,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:43,A_OV_PROG:2.27,B_OV_TOT:7,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:7,B_OV_PROG:0,ACT_TAG_VALID:37,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2500-2510-04",SUBSYSTEM_DESCR:"Salty Rich MEG Import Header",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-06-02",A_OV_TOT:22,A_OV_COM:1,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:21,A_OV_PROG:4.54,B_OV_TOT:4,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:4,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:1,ACT_TAG_VALID:19,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2500-2510-05",SUBSYSTEM_DESCR:"Salty Rich MEG Export Header",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-06-02",A_OV_TOT:39,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:39,A_OV_PROG:0,B_OV_TOT:7,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:7,B_OV_PROG:0,ACT_TAG_VALID:32,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2500-2510-06",SUBSYSTEM_DESCR:"Rich MEG Header to MEG Regeneration",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-06-02",A_OV_TOT:10,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:10,A_OV_PROG:0,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2500-2511-01",SUBSYSTEM_DESCR:"MRW1 Rich MEG Storage and Pumps",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-26",A_OV_TOT:95,A_OV_COM:8,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:5,A_OV_REM:87,A_OV_PROG:8.42,B_OV_TOT:35,B_OV_COM:2,B_OV_COM_CLIENT:0,B_OV_REM:33,B_OV_PROG:5.71,PA_OV_TOT:1,PA_OV_REM:1,PB1_OV_TOT:5,PB1_OV_REM:0,PREVENT_DC_REM:33,ACT_TAG_VALID:100,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-2500-2512-01",SUBSYSTEM_DESCR:"MSE1 Salty Rich MEG Storage and Pumps",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-05-19",A_OV_TOT:63,A_OV_COM:6,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:5,A_OV_REM:57,A_OV_PROG:9.52,B_OV_TOT:19,B_OV_COM:2,B_OV_COM_CLIENT:0,B_OV_REM:17,B_OV_PROG:10.52,PB1_OV_TOT:4,PB1_OV_REM:1,PB2_OV_TOT:1,PB2_OV_REM:1,PREVENT_DC_REM:18,ACT_TAG_VALID:66,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2500-2513-01",SUBSYSTEM_DESCR:"MSW2 Salty rich MEG storage and pumps",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-19",A_OV_TOT:48,A_OV_COM:13,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:7,A_OV_REM:35,A_OV_PROG:27.08,B_OV_TOT:15,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:15,B_OV_PROG:0,PA_OV_TOT:9,PA_OV_REM:9,PB3_OV_TOT:3,PB3_OV_REM:3,ACT_TAG_VALID:50,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-2500-2514-01",SUBSYSTEM_DESCR:"MSE2 Salty Rich MEG Storage and Pumps",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-26",A_OV_TOT:49,A_OV_COM:8,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:6,A_OV_REM:41,A_OV_PROG:16.32,B_OV_TOT:15,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:15,B_OV_PROG:0,ACT_TAG_VALID:51,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2500-2530-01",SUBSYSTEM_DESCR:"MEG Regeneration",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-26",A_OV_TOT:308,A_OV_COM:157,A_OV_COM_LW:8,A_OV_COM_TW:0,A_OV_COM_CLIENT:28,A_OV_REM:151,A_OV_PROG:50.97,B_OV_TOT:106,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:106,B_OV_PROG:0,PA_OV_TOT:12,PA_OV_REM:11,PB1_OV_TOT:13,PB1_OV_REM:9,PB2_OV_TOT:2,PB2_OV_REM:2,PREVENT_DC_REM:115,ACT_TAG_VALID:389,PUNCH_NUMBER_VALID:27,PUNCH_TAG_VALID:14},
    {SUBSYSTEM:"21-2500-2530-02",SUBSYSTEM_DESCR:"Lean MEG from MEG Regeneration to Storage",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-12",A_OV_TOT:12,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:12,A_OV_PROG:0,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2500-2551-01",SUBSYSTEM_DESCR:"MLW1 Lean MEG Storage and Pumps",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-05",A_OV_TOT:107,A_OV_COM:14,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:6,A_OV_REM:93,A_OV_PROG:13.08,B_OV_TOT:28,B_OV_COM:1,B_OV_COM_CLIENT:0,B_OV_REM:27,B_OV_PROG:3.57,PA_OV_TOT:11,PA_OV_REM:11,PB1_OV_TOT:4,PB1_OV_REM:0,PB2_OV_TOT:6,PB2_OV_REM:6,PREVENT_DC_REM:27,ACT_TAG_VALID:104,PUNCH_NUMBER_VALID:21,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2500-2552-01",SUBSYSTEM_DESCR:"MLE1 Lean MEG Storage and Pumps",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-05",A_OV_TOT:104,A_OV_COM:15,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:4,A_OV_REM:89,A_OV_PROG:14.42,B_OV_TOT:27,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:27,B_OV_PROG:0,PA_OV_TOT:9,PA_OV_REM:9,PB2_OV_TOT:11,PB2_OV_REM:11,ACT_TAG_VALID:99,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-2500-2553-01",SUBSYSTEM_DESCR:"Lean MEG Filters and Break Tank",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-19",A_OV_TOT:71,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:71,A_OV_PROG:0,B_OV_TOT:19,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:19,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:1,ACT_TAG_VALID:66,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2500-2553-02",SUBSYSTEM_DESCR:"Topsides Lean MEG Pumps and Distribution",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-19",A_OV_TOT:136,A_OV_COM:8,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:128,A_OV_PROG:5.88,B_OV_TOT:38,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:38,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:0,ACT_TAG_VALID:136,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2500-2553-03",SUBSYSTEM_DESCR:"Subsea Lean MEG Pumps and Distribution",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-06-23",A_OV_TOT:237,A_OV_COM:74,A_OV_COM_LW:3,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:163,A_OV_PROG:31.22,B_OV_TOT:71,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:71,B_OV_PROG:0,PA_OV_TOT:21,PA_OV_REM:0,PB1_OV_TOT:3,PB1_OV_REM:2,PREVENT_DC_REM:73,ACT_TAG_VALID:273,PUNCH_NUMBER_VALID:24,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-2500-2562-01",SUBSYSTEM_DESCR:"MEG Transfer from Bunkering Station",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B2",MC_PLAN:"2022-05-05",A_OV_TOT:11,A_OV_COM:1,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:10,A_OV_PROG:9.09,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,PB2_OV_TOT:1,PB2_OV_REM:1,ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2600-2630-01",SUBSYSTEM_DESCR:"Methanol Storage and Pumps",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B2",MC_PLAN:"2022-06-16",A_OV_TOT:45,A_OV_COM:4,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:2,A_OV_REM:41,A_OV_PROG:8.88,B_OV_TOT:20,B_OV_COM:2,B_OV_COM_CLIENT:0,B_OV_REM:18,B_OV_PROG:10,PB1_OV_TOT:7,PB1_OV_REM:0,PB2_OV_TOT:1,PB2_OV_REM:1,PREVENT_DC_REM:18,ACT_TAG_VALID:49,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2600-2640-01",SUBSYSTEM_DESCR:"Methanol Filters and Break Tank",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-19",A_OV_TOT:57,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:57,A_OV_PROG:0,B_OV_TOT:15,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:15,B_OV_PROG:0,ACT_TAG_VALID:51,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2600-2640-02",SUBSYSTEM_DESCR:"Methanol HP Pumps",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-19",A_OV_TOT:94,A_OV_COM:16,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:78,A_OV_PROG:17.02,B_OV_TOT:28,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:28,B_OV_PROG:0,ACT_TAG_VALID:102,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2600-2640-03",SUBSYSTEM_DESCR:"Methanol MP Pumps",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-19",A_OV_TOT:85,A_OV_COM:8,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:77,A_OV_PROG:9.41,B_OV_TOT:22,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:22,B_OV_PROG:0,ACT_TAG_VALID:84,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2600-2640-04",SUBSYSTEM_DESCR:"Methanol HP Header",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-06-02",A_OV_TOT:6,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:6,A_OV_PROG:0,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2600-2640-05",SUBSYSTEM_DESCR:"Methanol MP Header",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-06-02",A_OV_TOT:99,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:99,A_OV_PROG:0,B_OV_TOT:26,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:26,B_OV_PROG:0,ACT_TAG_VALID:88,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2600-2641-01",SUBSYSTEM_DESCR:"Methanol Subsea Pumps",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-05-19",A_OV_TOT:88,A_OV_COM:17,A_OV_COM_LW:3,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:71,A_OV_PROG:19.31,B_OV_TOT:22,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:22,B_OV_PROG:0,PB1_OV_TOT:1,PB1_OV_REM:1,PREVENT_DC_REM:23,ACT_TAG_VALID:90,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2600-2641-02",SUBSYSTEM_DESCR:"Methanol Subsea Distribution",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-19",A_OV_TOT:10,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:10,A_OV_PROG:0,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0,ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2600-8510-01",SUBSYSTEM_DESCR:"Methanol Loading Header",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-07-07",A_OV_TOT:17,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:17,A_OV_PROG:0,B_OV_TOT:5,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:5,B_OV_PROG:0,ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-3040-3040-01",SUBSYSTEM_DESCR:"HPandMP Gas Compressors, UCP, VSD and Electrical ControlandSwitchboards",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-12",A_OV_TOT:356,A_OV_COM:204,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:152,A_OV_PROG:57.3,B_OV_TOT:200,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:200,B_OV_PROG:0,PA_OV_TOT:33,PA_OV_REM:16,PB1_OV_TOT:3,PB1_OV_REM:3,PB2_OV_TOT:7,PB2_OV_REM:7,PREVENT_DC_REM:203,ACT_TAG_VALID:532,PUNCH_NUMBER_VALID:43,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-3040-3040-02",SUBSYSTEM_DESCR:"HPandMP Gas Compressors Train Scrubbers, Aftercoolers and Process Lines",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-06-16",A_OV_TOT:211,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:211,A_OV_PROG:0,B_OV_TOT:50,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:50,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:1,ACT_TAG_VALID:193,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-3040-3041-01",SUBSYSTEM_DESCR:"LPandLLP Gas Compressors , UCP, VSD and Electrical ControlandSwitchboards",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-12",A_OV_TOT:255,A_OV_COM:28,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:227,A_OV_PROG:10.98,B_OV_TOT:137,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:137,B_OV_PROG:0,PA_OV_TOT:5,PA_OV_REM:5,PB1_OV_TOT:13,PB1_OV_REM:11,PB2_OV_TOT:12,PB2_OV_REM:11,PREVENT_DC_REM:148,ACT_TAG_VALID:367,PUNCH_NUMBER_VALID:30,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-3040-3041-02",SUBSYSTEM_DESCR:"LPandLLP Gas Compressors Train Scrubbers, Aftercoolers and Process Lines",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-06-23",A_OV_TOT:163,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:163,A_OV_PROG:0,B_OV_TOT:38,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:38,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:1,PB1_OV_TOT:1,PB1_OV_REM:1,PREVENT_DC_REM:39,ACT_TAG_VALID:147,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-3330-3330-01",SUBSYSTEM_DESCR:"Process Gas, GasandGas Exchanger, TEX Scrubber and JT Valve",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-05-26",A_OV_TOT:210,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:210,A_OV_PROG:0,B_OV_TOT:44,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:44,B_OV_PROG:0,PB1_OV_TOT:1,PB1_OV_REM:1,PREVENT_DC_REM:45,ACT_TAG_VALID:204,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-3330-3330-02",SUBSYSTEM_DESCR:"Gas Coalescer and LT Separator",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-19",A_OV_TOT:303,A_OV_COM:2,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:301,A_OV_PROG:0.66,B_OV_TOT:63,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:63,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:1,PB1_OV_TOT:2,PB1_OV_REM:2,PREVENT_DC_REM:65,ACT_TAG_VALID:289,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-3330-3330-03",SUBSYSTEM_DESCR:"Turbo Expander, Recompressor",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-19",A_OV_TOT:305,A_OV_COM:49,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:256,A_OV_PROG:16.06,B_OV_TOT:113,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:113,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:1,PB1_OV_TOT:6,PB1_OV_REM:5,PB2_OV_TOT:3,PB2_OV_REM:3,PREVENT_DC_REM:118,ACT_TAG_VALID:363,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-3330-3410-01",SUBSYSTEM_DESCR:"Gas Export Heaters",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-06-09",A_OV_TOT:37,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:37,A_OV_PROG:0,B_OV_TOT:9,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:9,B_OV_PROG:0,ACT_TAG_VALID:32,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-3330-3410-02",SUBSYSTEM_DESCR:"Export Gas Metering and Export Risers",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-06-23",A_OV_TOT:91,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:91,A_OV_PROG:0,B_OV_TOT:29,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:29,B_OV_PROG:0,PB1_OV_TOT:4,PB1_OV_REM:3,PREVENT_DC_REM:32,ACT_TAG_VALID:86,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-3330-3410-03",SUBSYSTEM_DESCR:"Repressurisation Heater and Header",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-07-14",A_OV_TOT:56,A_OV_COM:3,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:53,A_OV_PROG:5.35,B_OV_TOT:19,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:19,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:0,ACT_TAG_VALID:61,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-4000-4030-01",SUBSYSTEM_DESCR:"Produced Water Offspec Storage Tank and Pumps",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-06-16",A_OV_TOT:114,A_OV_COM:8,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:5,A_OV_REM:106,A_OV_PROG:7.01,B_OV_TOT:33,B_OV_COM:1,B_OV_COM_CLIENT:0,B_OV_REM:32,B_OV_PROG:3.03,PA_OV_TOT:7,PA_OV_REM:7,PB1_OV_TOT:9,PB1_OV_REM:2,PB2_OV_TOT:3,PB2_OV_REM:3,PREVENT_DC_REM:34,ACT_TAG_VALID:112,PUNCH_NUMBER_VALID:19,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-4000-4030-02",SUBSYSTEM_DESCR:"Produced Water Offspec Header to Storage Tank",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-06-23",A_OV_TOT:5,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:5,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-4000-4030-03",SUBSYSTEM_DESCR:"Produced Water Offspec Header to Produced Water Treatment and Bunkering Station",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-06-23",A_OV_TOT:11,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:11,A_OV_PROG:0,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-4000-4130-01",SUBSYSTEM_DESCR:"Deoiling Package ,Hydrocyclone + CFU",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-06-02",A_OV_TOT:106,A_OV_COM:20,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:86,A_OV_PROG:18.86,B_OV_TOT:36,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:36,B_OV_PROG:0,PA_OV_TOT:10,PA_OV_REM:4,PB1_OV_TOT:3,PB1_OV_REM:3,PREVENT_DC_REM:39,ACT_TAG_VALID:126,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-4000-4130-02",SUBSYSTEM_DESCR:"Flocculent Injection Package",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-06-16",A_OV_TOT:50,A_OV_COM:20,A_OV_COM_LW:2,A_OV_COM_TW:0,A_OV_COM_CLIENT:2,A_OV_REM:30,A_OV_PROG:40,B_OV_TOT:13,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:13,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:0,PB1_OV_TOT:5,PB1_OV_REM:3,PB2_OV_TOT:1,PB2_OV_REM:1,PREVENT_DC_REM:16,ACT_TAG_VALID:60,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-4000-4130-03",SUBSYSTEM_DESCR:"Demulsifier Injection Package",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-06-16",A_OV_TOT:24,A_OV_COM:14,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:2,A_OV_REM:10,A_OV_PROG:58.33,B_OV_TOT:11,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:11,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:1,PB1_OV_TOT:3,PB1_OV_REM:3,PREVENT_DC_REM:14,ACT_TAG_VALID:35,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-4000-4130-04",SUBSYSTEM_DESCR:"Produced Water Polishing Package",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-06-23",A_OV_TOT:21,A_OV_COM:8,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:13,A_OV_PROG:38.09,B_OV_TOT:11,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:11,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:1,ACT_TAG_VALID:32,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-4000-4150-01",SUBSYSTEM_DESCR:"Mercury Removal Package",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-06-16",A_OV_TOT:19,A_OV_COM:6,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:13,A_OV_PROG:31.57,B_OV_TOT:7,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:7,B_OV_PROG:0,PA_OV_TOT:3,PA_OV_REM:1,PB1_OV_TOT:2,PB1_OV_REM:2,PREVENT_DC_REM:9,ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-4000-4150-02",SUBSYSTEM_DESCR:"Treated Water Header and Discharge Caisson",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-06-23",A_OV_TOT:3,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:3,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5000-5010-01",SUBSYSTEM_DESCR:"HP Flare KO Drum and Pumps",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-26",A_OV_TOT:79,A_OV_COM:6,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:73,A_OV_PROG:7.59,B_OV_TOT:26,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:26,B_OV_PROG:0,ACT_TAG_VALID:90,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5000-5020-01",SUBSYSTEM_DESCR:"HP Flare Ignition, Metering and Tip",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-06-09",A_OV_TOT:40,A_OV_COM:15,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:25,A_OV_PROG:37.5,B_OV_TOT:22,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:22,B_OV_PROG:0,ACT_TAG_VALID:54,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5000-5030-01",SUBSYSTEM_DESCR:"Cold and Dry HP Flare Header",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-26",A_OV_TOT:26,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:26,A_OV_PROG:0,B_OV_TOT:10,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:10,B_OV_PROG:0,ACT_TAG_VALID:28,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5000-5030-03",SUBSYSTEM_DESCR:"HP liquid Flare Header",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-26",A_OV_TOT:51,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:51,A_OV_PROG:0,B_OV_TOT:12,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:12,B_OV_PROG:0,ACT_TAG_VALID:52,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5200-4340-01",SUBSYSTEM_DESCR:"Closed Drain Drum and Pumps",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-06-09",A_OV_TOT:65,A_OV_COM:5,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:60,A_OV_PROG:7.69,B_OV_TOT:23,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:23,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:1,ACT_TAG_VALID:73,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-5200-5210-01",SUBSYSTEM_DESCR:"LP Flare KO Drum and Pumps",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-26",A_OV_TOT:53,A_OV_COM:4,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:49,A_OV_PROG:7.54,B_OV_TOT:16,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:16,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:0,ACT_TAG_VALID:54,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-5200-5220-01",SUBSYSTEM_DESCR:"LP Flare Metering and Tip",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-06-09",A_OV_TOT:15,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:15,A_OV_PROG:0,B_OV_TOT:9,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:9,B_OV_PROG:0,ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5200-5230-01",SUBSYSTEM_DESCR:"LP Flare Header",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-26",A_OV_TOT:27,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:27,A_OV_PROG:0,B_OV_TOT:5,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:5,B_OV_PROG:0,ACT_TAG_VALID:26,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5400-5410-01",SUBSYSTEM_DESCR:"Vent Headers, Boom and Liquid Seal Systems",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-26",A_OV_TOT:49,A_OV_COM:11,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:38,A_OV_PROG:22.44,B_OV_TOT:15,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:15,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:0,ACT_TAG_VALID:49,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-5400-5410-02",SUBSYSTEM_DESCR:"CO2 Snuffing System",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-07-21",A_OV_TOT:3,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:3,A_OV_PROG:0,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5700-5710-01",SUBSYSTEM_DESCR:"Flare Gas Vapour Recovery Skid",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-06-09",A_OV_TOT:41,A_OV_COM:2,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:39,A_OV_PROG:4.87,B_OV_TOT:21,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:21,B_OV_PROG:0,PA_OV_TOT:7,PA_OV_REM:4,PB1_OV_TOT:1,PB1_OV_REM:1,PREVENT_DC_REM:22,ACT_TAG_VALID:57,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-5700-5730-01",SUBSYSTEM_DESCR:"HULL Tank Vapour Recovery Skid",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-06-16",A_OV_TOT:73,A_OV_COM:41,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:32,A_OV_PROG:56.16,B_OV_TOT:40,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:40,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:2,PB1_OV_TOT:2,PB1_OV_REM:1,PREVENT_DC_REM:41,ACT_TAG_VALID:107,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6010-6011-01",SUBSYSTEM_DESCR:"SIS Marshalling - Machinery Space ,HER",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-02-17",A_OV_TOT:43,A_OV_COM:43,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:4,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:9,B_OV_COM:9,B_OV_COM_CLIENT:0,B_OV_REM:0,B_OV_PROG:100,PA_OV_TOT:43,PA_OV_REM:0,PB1_OV_TOT:20,PB1_OV_REM:5,PREVENT_DC_REM:5,ACT_TAG_VALID:48,PUNCH_NUMBER_VALID:63,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-6010-6011-02",SUBSYSTEM_DESCR:"SIS Marshalling - LQ ,CER",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-02-17",A_OV_TOT:14,A_OV_COM:14,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:8,B_OV_COM:3,B_OV_COM_CLIENT:0,B_OV_REM:5,B_OV_PROG:37.5,PA_OV_TOT:26,PA_OV_REM:0,PB1_OV_TOT:6,PB1_OV_REM:1,PREVENT_DC_REM:6,ACT_TAG_VALID:21,PUNCH_NUMBER_VALID:32,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6010-6011-03",SUBSYSTEM_DESCR:"SIS Marshalling - Topsides ,ME05 and LER",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-02-24",A_OV_TOT:22,A_OV_COM:22,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:2,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:5,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:5,B_OV_PROG:0,PA_OV_TOT:12,PA_OV_REM:0,PB1_OV_TOT:35,PB1_OV_REM:13,PREVENT_DC_REM:18,ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:47,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-6010-6011-04",SUBSYSTEM_DESCR:"CAP Panel in CCR",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-31",A_OV_TOT:23,A_OV_COM:23,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:3,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:112,B_OV_COM:67,B_OV_COM_CLIENT:0,B_OV_REM:45,B_OV_PROG:59.82,PB1_OV_TOT:11,PB1_OV_REM:6,PB2_OV_TOT:2,PB2_OV_REM:1,PREVENT_DC_REM:51,ACT_TAG_VALID:133,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-6010-6011-08",SUBSYSTEM_DESCR:"SIS Field Push Buttons",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-19",A_OV_TOT:4,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:4,A_OV_PROG:0,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6010-6012-01",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables ME01",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-21",A_OV_TOT:8,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:8,A_OV_PROG:0,B_OV_TOT:4,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:4,B_OV_PROG:0,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6010-6012-02",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables MW01",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-05",A_OV_TOT:12,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:12,A_OV_PROG:0,B_OV_TOT:6,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:6,B_OV_PROG:0,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6010-6012-03",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables ME02",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-05",A_OV_TOT:14,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:14,A_OV_PROG:0,B_OV_TOT:6,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:6,B_OV_PROG:0,ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6010-6012-04",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables MW02",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-12",A_OV_TOT:16,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:16,A_OV_PROG:0,B_OV_TOT:8,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:8,B_OV_PROG:0,ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6010-6012-05",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables OFL00",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-06-09",A_OV_TOT:4,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:4,A_OV_PROG:0,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6010-6012-06",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables MW04",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-05",A_OV_TOT:8,A_OV_COM:2,A_OV_COM_LW:2,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:6,A_OV_PROG:25,B_OV_TOT:4,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:4,B_OV_PROG:0,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6010-6012-07",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables MW05",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-03-31",A_OV_TOT:4,A_OV_COM:2,A_OV_COM_LW:2,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:2,A_OV_PROG:50,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6010-6012-08",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables ME04",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-21",A_OV_TOT:4,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:4,A_OV_PROG:0,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6010-6012-09",SUBSYSTEM_DESCR:"SIF's for 1st Gas Start-up",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Complete",PUNCH_STATUS:"N",MC_PLAN:"2022-06-16",A_OV_PROG:0,B_OV_TOT:143,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:143,B_OV_PROG:0,ACT_TAG_VALID:143,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6010-6012-10",SUBSYSTEM_DESCR:"SIF's Post 1st Gas Start-up",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Complete",PUNCH_STATUS:"N",MC_PLAN:"2022-06-16",A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6020-6021-01",SUBSYSTEM_DESCR:"PAS Marshalling - Machinery Space ,HER",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B2",MC_PLAN:"2022-02-17",A_OV_TOT:48,A_OV_COM:48,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:4,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:9,B_OV_COM:9,B_OV_COM_CLIENT:0,B_OV_REM:0,B_OV_PROG:100,PA_OV_TOT:20,PA_OV_REM:0,PB1_OV_TOT:19,PB1_OV_REM:0,PB2_OV_TOT:7,PB2_OV_REM:7,PREVENT_DC_REM:0,ACT_TAG_VALID:53,PUNCH_NUMBER_VALID:46,PUNCH_TAG_VALID:9},
    {SUBSYSTEM:"21-6020-6021-02",SUBSYSTEM_DESCR:"PAS Marshalling - LQ ,CER",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-02-17",A_OV_TOT:15,A_OV_COM:15,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:4,B_OV_COM:4,B_OV_COM_CLIENT:0,B_OV_REM:0,B_OV_PROG:100,PA_OV_TOT:11,PA_OV_REM:0,PB1_OV_TOT:11,PB1_OV_REM:1,PB2_OV_TOT:1,PB2_OV_REM:1,PREVENT_DC_REM:1,ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:23,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6020-6021-03",SUBSYSTEM_DESCR:"PAS Marshalling - Topsides ,ME05 and LER",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-02-24",A_OV_TOT:15,A_OV_COM:15,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0,PA_OV_TOT:8,PA_OV_REM:0,PB1_OV_TOT:35,PB1_OV_REM:11,PREVENT_DC_REM:14,ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:43,PUNCH_TAG_VALID:9},
    {SUBSYSTEM:"21-6020-6022-01",SUBSYSTEM_DESCR:"PAS Remote IOs,incl Junction Box and Multicore Cables ME01",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-21",A_OV_TOT:14,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:14,A_OV_PROG:0,B_OV_TOT:6,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:6,B_OV_PROG:0,PB1_OV_TOT:1,PB1_OV_REM:0,PREVENT_DC_REM:6,ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6020-6022-02",SUBSYSTEM_DESCR:"PAS Remote IOs ,incl Junction Box and Multicore Cables MW01",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-05",A_OV_TOT:20,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:20,A_OV_PROG:0,B_OV_TOT:10,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:10,B_OV_PROG:0,ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6020-6022-03",SUBSYSTEM_DESCR:"PAS Remote IOs ,incl Junction Box and Multicore Cables ME02",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-05",A_OV_TOT:16,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:16,A_OV_PROG:0,B_OV_TOT:8,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:8,B_OV_PROG:0,ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6020-6022-04",SUBSYSTEM_DESCR:"PAS Remote IOs ,incl Junction Box and Multicore Cables MW02",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-12",A_OV_TOT:20,A_OV_COM:1,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:19,A_OV_PROG:5,B_OV_TOT:10,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:10,B_OV_PROG:0,ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6020-6022-05",SUBSYSTEM_DESCR:"PAS Remote IOs ,incl Junction Box and Multicore Cables ME04",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-21",A_OV_TOT:9,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:9,A_OV_PROG:0,B_OV_TOT:4,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:4,B_OV_PROG:0,ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6020-6022-06",SUBSYSTEM_DESCR:"PAS Remote IOs ,incl Junction Box and Multicore Cables MW04",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-05",A_OV_TOT:4,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:4,A_OV_PROG:0,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6020-6022-07",SUBSYSTEM_DESCR:"PAS Remote IOs ,incl Junction Box and Multicore Cables MW05",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-03-31",A_OV_TOT:22,A_OV_COM:10,A_OV_COM_LW:10,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:12,A_OV_PROG:45.45,B_OV_TOT:10,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:10,B_OV_PROG:0,ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6020-6022-08",SUBSYSTEM_DESCR:"PAS Remote IOs ,incl Junction Box and Multicore Cables OFL00",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-06-09",A_OV_TOT:8,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:8,A_OV_PROG:0,B_OV_TOT:4,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:4,B_OV_PROG:0,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6030-6031-01",SUBSYSTEM_DESCR:"Electrical Data Monitoring and Constrol System ,EDMCS and Power Management System ,PMS",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-03-31",A_OV_TOT:131,A_OV_COM:95,A_OV_COM_LW:4,A_OV_COM_TW:0,A_OV_COM_CLIENT:34,A_OV_REM:36,A_OV_PROG:72.51,B_OV_TOT:8,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:8,B_OV_PROG:0,PA_OV_TOT:14,PA_OV_REM:3,PB1_OV_TOT:14,PB1_OV_REM:4,PB2_OV_TOT:1,PB2_OV_REM:1,PREVENT_DC_REM:12,ACT_TAG_VALID:132,PUNCH_NUMBER_VALID:29,PUNCH_TAG_VALID:15},
    {SUBSYSTEM:"21-6030-6032-01",SUBSYSTEM_DESCR:"ICSS Interface Servers, Workstations and Network Switches CER",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Partial MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2021-12-02",A_OV_TOT:233,A_OV_COM:228,A_OV_COM_LW:13,A_OV_COM_TW:0,A_OV_COM_CLIENT:9,A_OV_REM:5,A_OV_PROG:97.85,B_OV_TOT:35,B_OV_COM:22,B_OV_COM_CLIENT:0,B_OV_REM:13,B_OV_PROG:62.85,PA_OV_TOT:32,PA_OV_REM:0,PB1_OV_TOT:162,PB1_OV_REM:79,PB2_OV_TOT:30,PB2_OV_REM:30,PREVENT_DC_REM:92,ACT_TAG_VALID:257,PUNCH_NUMBER_VALID:224,PUNCH_TAG_VALID:19},
    {SUBSYSTEM:"21-6030-6032-02",SUBSYSTEM_DESCR:"ICSS Interface Servers, Workstations and Network Switches LER",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-03-31",A_OV_TOT:183,A_OV_COM:168,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:15,A_OV_PROG:91.8,B_OV_TOT:21,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:21,B_OV_PROG:0,PA_OV_TOT:3,PA_OV_REM:3,PB1_OV_TOT:34,PB1_OV_REM:16,PB2_OV_TOT:21,PB2_OV_REM:21,PREVENT_DC_REM:37,ACT_TAG_VALID:194,PUNCH_NUMBER_VALID:58,PUNCH_TAG_VALID:11},
    {SUBSYSTEM:"21-6030-6032-03",SUBSYSTEM_DESCR:"ICSS Interface Servers, Workstations and Network Switches HER",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Partial MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2021-12-09",A_OV_TOT:136,A_OV_COM:131,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:5,A_OV_REM:5,A_OV_PROG:96.32,B_OV_TOT:17,B_OV_COM:14,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:82.35,PA_OV_TOT:6,PA_OV_REM:0,PB1_OV_TOT:63,PB1_OV_REM:39,PB2_OV_TOT:18,PB2_OV_REM:16,PREVENT_DC_REM:42,ACT_TAG_VALID:144,PUNCH_NUMBER_VALID:87,PUNCH_TAG_VALID:15},
    {SUBSYSTEM:"21-6030-6032-04",SUBSYSTEM_DESCR:"ICSS Workstations and Consoles - CCR",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-03-24",A_OV_TOT:58,A_OV_COM:54,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:23,A_OV_REM:4,A_OV_PROG:93.1,B_OV_TOT:14,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:14,B_OV_PROG:0,PA_OV_TOT:4,PA_OV_REM:2,PB1_OV_TOT:10,PB1_OV_REM:7,PB2_OV_TOT:3,PB2_OV_REM:3,PREVENT_DC_REM:21,ACT_TAG_VALID:60,PUNCH_NUMBER_VALID:17,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-6030-6032-05",SUBSYSTEM_DESCR:"ICSS Workstations and Consoles - Safe HavenandSOP",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-07-21",A_OV_TOT:9,A_OV_COM:4,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:2,A_OV_REM:5,A_OV_PROG:44.44,B_OV_TOT:5,B_OV_COM:2,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:40,PB1_OV_TOT:1,PB1_OV_REM:0,PREVENT_DC_REM:3,ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6030-6034-01",SUBSYSTEM_DESCR:"Machinery Protection System ,MPS",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-04-07",A_OV_TOT:22,A_OV_COM:10,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:12,A_OV_PROG:45.45,B_OV_TOT:15,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:15,B_OV_PROG:0,PB1_OV_TOT:2,PB1_OV_REM:2,PB2_OV_TOT:2,PB2_OV_REM:2,PREVENT_DC_REM:17,ACT_TAG_VALID:31,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6030-6037-01",SUBSYSTEM_DESCR:"Tank Gauging System Cabinets and Workstations",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-01-27",A_OV_TOT:24,A_OV_COM:24,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:11,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:13,B_OV_COM:5,B_OV_COM_CLIENT:0,B_OV_REM:8,B_OV_PROG:38.46,PA_OV_TOT:11,PA_OV_REM:0,PB1_OV_TOT:27,PB1_OV_REM:4,PB2_OV_TOT:16,PB2_OV_REM:11,PREVENT_DC_REM:12,ACT_TAG_VALID:29,PUNCH_NUMBER_VALID:54,PUNCH_TAG_VALID:16},
    {SUBSYSTEM:"21-6030-6038-01",SUBSYSTEM_DESCR:"Cargo Loading Computer and Workstation",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B2",MC_PLAN:"2022-04-07",A_OV_TOT:7,A_OV_COM:1,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:6,A_OV_PROG:14.28,B_OV_TOT:6,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:6,B_OV_PROG:0,PB2_OV_TOT:1,PB2_OV_REM:1,ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6030-6039-01",SUBSYSTEM_DESCR:"BallastandVoid Gas Sampling",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-04-28",A_OV_TOT:51,A_OV_COM:9,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:42,A_OV_PROG:17.64,B_OV_TOT:73,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:73,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:0,PB1_OV_TOT:5,PB1_OV_REM:4,PREVENT_DC_REM:77,ACT_TAG_VALID:120,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6030-6040-01",SUBSYSTEM_DESCR:"IRP and Interface Instrumentation Cable - ME05 ,HV and LV Room",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-03-31",A_OV_TOT:85,A_OV_COM:39,A_OV_COM_LW:6,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:46,A_OV_PROG:45.88,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0,PA_OV_TOT:4,PA_OV_REM:1,PB1_OV_TOT:5,PB1_OV_REM:4,PB2_OV_TOT:1,PB2_OV_REM:1,PREVENT_DC_REM:7,ACT_TAG_VALID:85,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:7},
    {SUBSYSTEM:"21-6030-6040-02",SUBSYSTEM_DESCR:"IRP and Interface Instrumentation Cable - LQ",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2021-12-30",A_OV_TOT:71,A_OV_COM:71,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:31,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:2,B_OV_COM:2,B_OV_COM_CLIENT:0,B_OV_REM:0,B_OV_PROG:100,PA_OV_TOT:21,PA_OV_REM:0,PB1_OV_TOT:32,PB1_OV_REM:3,PB2_OV_TOT:2,PB2_OV_REM:0,PB3_OV_TOT:1,PB3_OV_REM:0,PREVENT_DC_REM:3,RFDC_REM:0,ACT_TAG_VALID:71,PUNCH_NUMBER_VALID:56,PUNCH_TAG_VALID:18},
    {SUBSYSTEM:"21-6030-6040-03",SUBSYSTEM_DESCR:"IRP and Interface Instrumentation Cable - MS03",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"N",MC_PLAN:"2022-01-27",A_OV_TOT:181,A_OV_COM:181,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:60,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:3,B_OV_COM:3,B_OV_COM_CLIENT:0,B_OV_REM:0,B_OV_PROG:100,PA_OV_TOT:16,PA_OV_REM:0,PB1_OV_TOT:45,PB1_OV_REM:0,PB2_OV_TOT:6,PB2_OV_REM:0,PREVENT_DC_REM:0,ACT_TAG_VALID:181,PUNCH_NUMBER_VALID:67,PUNCH_TAG_VALID:21},
    {SUBSYSTEM:"21-6030-6041-01",SUBSYSTEM_DESCR:"PEMS System",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-04-07",A_OV_TOT:3,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:3,A_OV_PROG:0,B_OV_TOT:4,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:4,B_OV_PROG:0,PB1_OV_TOT:5,PB1_OV_REM:4,PB2_OV_TOT:5,PB2_OV_REM:5,PREVENT_DC_REM:8,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6030-6042-01",SUBSYSTEM_DESCR:"Corrosion and Erosion Monitoring",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-04-07",A_OV_TOT:62,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:62,A_OV_PROG:0,B_OV_TOT:13,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:13,B_OV_PROG:0,PB1_OV_TOT:2,PB1_OV_REM:1,PB2_OV_TOT:4,PB2_OV_REM:3,PREVENT_DC_REM:14,ACT_TAG_VALID:44,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6030-6043-01",SUBSYSTEM_DESCR:"FOPPs and Associated Fiber Cables to LER",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-03-31",A_OV_TOT:16,A_OV_COM:6,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:10,A_OV_PROG:37.5,B_OV_TOT:6,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:6,B_OV_PROG:0,ACT_TAG_VALID:21,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6030-6043-02",SUBSYSTEM_DESCR:"FOPPs and Associated Fiber Cables to CER",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-10",A_OV_TOT:9,A_OV_COM:9,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:4,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:4,B_OV_PROG:0,PA_OV_TOT:6,PA_OV_REM:0,PB1_OV_TOT:13,PB1_OV_REM:3,PB2_OV_TOT:2,PB2_OV_REM:0,PREVENT_DC_REM:7,ACT_TAG_VALID:13,PUNCH_NUMBER_VALID:21,PUNCH_TAG_VALID:8},
    {SUBSYSTEM:"21-6030-6043-03",SUBSYSTEM_DESCR:"FOPPs and Associated Fiber Cables to HER",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"N",MC_PLAN:"2022-03-03",A_OV_TOT:4,A_OV_COM:4,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:2,B_OV_COM:2,B_OV_COM_CLIENT:0,B_OV_REM:0,B_OV_PROG:100,PA_OV_TOT:2,PA_OV_REM:0,PB1_OV_TOT:18,PB1_OV_REM:0,PREVENT_DC_REM:0,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:8},
    {SUBSYSTEM:"21-6035-6035-01",SUBSYSTEM_DESCR:"Process CCTV Cameras, Consoles and Screens",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-05",A_OV_TOT:159,A_OV_COM:15,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:144,A_OV_PROG:9.43,B_OV_TOT:16,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:16,B_OV_PROG:0,PB1_OV_TOT:5,PB1_OV_REM:0,PREVENT_DC_REM:16,ACT_TAG_VALID:141,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-6070-6071-01",SUBSYSTEM_DESCR:"FGS Marshalling - Machinery Space ,HER",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-02-17",A_OV_TOT:14,A_OV_COM:14,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:7,B_OV_COM:3,B_OV_COM_CLIENT:0,B_OV_REM:4,B_OV_PROG:42.85,PA_OV_TOT:12,PA_OV_REM:0,PB1_OV_TOT:11,PB1_OV_REM:1,PREVENT_DC_REM:5,ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:23,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6070-6071-02",SUBSYSTEM_DESCR:"FGS Marshalling - LQ ,CER",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B2",MC_PLAN:"2022-02-17",A_OV_TOT:30,A_OV_COM:30,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:2,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:5,B_OV_COM:5,B_OV_COM_CLIENT:0,B_OV_REM:0,B_OV_PROG:100,PA_OV_TOT:26,PA_OV_REM:0,PB1_OV_TOT:14,PB1_OV_REM:0,PB2_OV_TOT:2,PB2_OV_REM:2,PREVENT_DC_REM:0,ACT_TAG_VALID:33,PUNCH_NUMBER_VALID:42,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6070-6071-03",SUBSYSTEM_DESCR:"FGS Marshalling - Topsides ,ME05 and LER",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-02-24",A_OV_TOT:8,A_OV_COM:8,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0,PA_OV_TOT:6,PA_OV_REM:0,PB1_OV_TOT:15,PB1_OV_REM:5,PREVENT_DC_REM:8,ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:21,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6070-6072-01",SUBSYSTEM_DESCR:"Fire Addressable Panels - LQ ,CER and LQ Fire and Gas Addressable Loop-1",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Partial MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-02-03",A_OV_TOT:288,A_OV_COM:275,A_OV_COM_LW:49,A_OV_COM_TW:0,A_OV_COM_CLIENT:124,A_OV_REM:13,A_OV_PROG:95.48,B_OV_TOT:106,B_OV_COM:10,B_OV_COM_CLIENT:0,B_OV_REM:96,B_OV_PROG:9.43,PA_OV_TOT:10,PA_OV_REM:0,PB1_OV_TOT:46,PB1_OV_REM:2,PB2_OV_TOT:20,PB2_OV_REM:18,PB3_OV_TOT:1,PB3_OV_REM:1,PREVENT_DC_REM:98,RFDC_REM:19,ACT_TAG_VALID:373,PUNCH_NUMBER_VALID:77,PUNCH_TAG_VALID:36},
    {SUBSYSTEM:"21-6070-6072-02",SUBSYSTEM_DESCR:"LQ Fire and Gas Addressable Loop-2",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-05",A_OV_TOT:266,A_OV_COM:260,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:120,A_OV_REM:6,A_OV_PROG:97.74,B_OV_TOT:101,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:101,B_OV_PROG:0,PA_OV_TOT:19,PA_OV_REM:6,PB1_OV_TOT:46,PB1_OV_REM:9,PB2_OV_TOT:28,PB2_OV_REM:28,PREVENT_DC_REM:110,ACT_TAG_VALID:354,PUNCH_NUMBER_VALID:93,PUNCH_TAG_VALID:50},
    {SUBSYSTEM:"21-6070-6072-03",SUBSYSTEM_DESCR:"LQ Fire and Gas Addressable Loop-3",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-05-05",A_OV_TOT:203,A_OV_COM:188,A_OV_COM_LW:18,A_OV_COM_TW:0,A_OV_COM_CLIENT:129,A_OV_REM:15,A_OV_PROG:92.61,B_OV_TOT:73,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:73,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:0,PB1_OV_TOT:14,PB1_OV_REM:2,PB2_OV_TOT:8,PB2_OV_REM:8,PREVENT_DC_REM:75,ACT_TAG_VALID:276,PUNCH_NUMBER_VALID:24,PUNCH_TAG_VALID:12},
    {SUBSYSTEM:"21-6070-6072-04",SUBSYSTEM_DESCR:"LQ Fire and Gas Addressable Loop-4",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-03-24",A_OV_TOT:189,A_OV_COM:177,A_OV_COM_LW:12,A_OV_COM_TW:0,A_OV_COM_CLIENT:79,A_OV_REM:12,A_OV_PROG:93.65,B_OV_TOT:70,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:70,B_OV_PROG:0,PA_OV_TOT:33,PA_OV_REM:29,PB1_OV_TOT:48,PB1_OV_REM:10,PB2_OV_TOT:17,PB2_OV_REM:16,PREVENT_DC_REM:80,ACT_TAG_VALID:256,PUNCH_NUMBER_VALID:98,PUNCH_TAG_VALID:60},
    {SUBSYSTEM:"21-6070-6072-05",SUBSYSTEM_DESCR:"LQ Fire and Gas Addressable Loop-5",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-04-14",A_OV_TOT:235,A_OV_COM:232,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:118,A_OV_REM:3,A_OV_PROG:98.72,B_OV_TOT:85,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:85,B_OV_PROG:0,PA_OV_TOT:11,PA_OV_REM:1,PB1_OV_TOT:28,PB1_OV_REM:2,PB2_OV_TOT:21,PB2_OV_REM:20,PREVENT_DC_REM:87,ACT_TAG_VALID:295,PUNCH_NUMBER_VALID:60,PUNCH_TAG_VALID:34},
    {SUBSYSTEM:"21-6070-6072-06",SUBSYSTEM_DESCR:"LQ Fire and Gas Addressable Loop-6",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-04-14",A_OV_TOT:236,A_OV_COM:235,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:140,A_OV_REM:1,A_OV_PROG:99.57,B_OV_TOT:80,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:80,B_OV_PROG:0,PA_OV_TOT:22,PA_OV_REM:0,PB1_OV_TOT:34,PB1_OV_REM:1,PB2_OV_TOT:36,PB2_OV_REM:15,PREVENT_DC_REM:81,ACT_TAG_VALID:298,PUNCH_NUMBER_VALID:92,PUNCH_TAG_VALID:44},
    {SUBSYSTEM:"21-6070-6072-08",SUBSYSTEM_DESCR:"Fire Addressable Panels - Machinery Space ,HER and Machinery Space Fire and Gas Addressable Loop-1",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Partial MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-01-27",A_OV_TOT:205,A_OV_COM:177,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:75,A_OV_REM:28,A_OV_PROG:86.34,B_OV_TOT:105,B_OV_COM:95,B_OV_COM_CLIENT:0,B_OV_REM:10,B_OV_PROG:90.47,PA_OV_TOT:4,PA_OV_REM:0,PB1_OV_TOT:36,PB1_OV_REM:3,PB2_OV_TOT:12,PB2_OV_REM:10,PREVENT_DC_REM:13,ACT_TAG_VALID:295,PUNCH_NUMBER_VALID:52,PUNCH_TAG_VALID:24},
    {SUBSYSTEM:"21-6070-6072-09",SUBSYSTEM_DESCR:"Machinery Space Fire and Gas  Addressable Loop-2",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-01-06",A_OV_TOT:181,A_OV_COM:181,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:90,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:77,B_OV_COM:76,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:98.7,PA_OV_TOT:2,PA_OV_REM:0,PB1_OV_TOT:35,PB1_OV_REM:1,PB2_OV_TOT:3,PB2_OV_REM:1,PREVENT_DC_REM:2,ACT_TAG_VALID:258,PUNCH_NUMBER_VALID:40,PUNCH_TAG_VALID:17},
    {SUBSYSTEM:"21-6070-6072-10",SUBSYSTEM_DESCR:"Machinery Space Fire and Gas  Addressable Loop-3",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B2",MC_PLAN:"2021-12-23",A_OV_TOT:50,A_OV_COM:50,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:25,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:20,B_OV_COM:20,B_OV_COM_CLIENT:0,B_OV_REM:0,B_OV_PROG:100,PA_OV_TOT:2,PA_OV_REM:0,PB1_OV_TOT:7,PB1_OV_REM:0,PB2_OV_TOT:5,PB2_OV_REM:5,PREVENT_DC_REM:0,ACT_TAG_VALID:64,PUNCH_NUMBER_VALID:14,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-6070-6072-11",SUBSYSTEM_DESCR:"Fire Addressable Panels  and - Addressable Fire and Gas Detectors - Topsides ME05",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-04-07",A_OV_TOT:246,A_OV_COM:171,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:70,A_OV_REM:75,A_OV_PROG:69.51,B_OV_TOT:85,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:85,B_OV_PROG:0,PA_OV_TOT:4,PA_OV_REM:4,PB1_OV_TOT:11,PB1_OV_REM:10,PB2_OV_TOT:4,PB2_OV_REM:4,PREVENT_DC_REM:95,ACT_TAG_VALID:288,PUNCH_NUMBER_VALID:19,PUNCH_TAG_VALID:12},
    {SUBSYSTEM:"21-6070-6073-01",SUBSYSTEM_DESCR:"HSSD - Machinery Space ,HER",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-10",A_OV_TOT:8,A_OV_COM:8,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:4,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:4,B_OV_COM:4,B_OV_COM_CLIENT:0,B_OV_REM:0,B_OV_PROG:100,PA_OV_TOT:8,PA_OV_REM:0,PB1_OV_TOT:12,PB1_OV_REM:1,PB2_OV_TOT:5,PB2_OV_REM:5,PREVENT_DC_REM:1,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:25,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6070-6073-02",SUBSYSTEM_DESCR:"HSSD - Machinery Space ,L3 Electrical room",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B2",MC_PLAN:"2022-03-10",A_OV_TOT:16,A_OV_COM:16,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:8,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:8,B_OV_COM:8,B_OV_COM_CLIENT:0,B_OV_REM:0,B_OV_PROG:100,PA_OV_TOT:16,PA_OV_REM:0,PB1_OV_TOT:13,PB1_OV_REM:0,PB2_OV_TOT:8,PB2_OV_REM:8,PREVENT_DC_REM:0,ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:37,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-6070-6073-03",SUBSYSTEM_DESCR:"HSSD - LQ ,HULL Level Electrical rooms",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-10",A_OV_TOT:8,A_OV_COM:8,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:4,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:4,B_OV_COM:4,B_OV_COM_CLIENT:0,B_OV_REM:0,B_OV_PROG:100,PA_OV_TOT:12,PA_OV_REM:0,PB1_OV_TOT:22,PB1_OV_REM:1,PB2_OV_TOT:6,PB2_OV_REM:6,PREVENT_DC_REM:1,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:40,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6070-6073-04",SUBSYSTEM_DESCR:"HSSD - LQ ,CCR",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B2",MC_PLAN:"2022-03-10",A_OV_TOT:4,A_OV_COM:4,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:2,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:2,B_OV_COM:2,B_OV_COM_CLIENT:0,B_OV_REM:0,B_OV_PROG:100,PA_OV_TOT:5,PA_OV_REM:0,PB1_OV_TOT:15,PB1_OV_REM:0,PB2_OV_TOT:4,PB2_OV_REM:4,PREVENT_DC_REM:0,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:24,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6070-6073-05",SUBSYSTEM_DESCR:"HSSD - LQ ,CER",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B2",MC_PLAN:"2022-03-10",A_OV_TOT:12,A_OV_COM:12,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:6,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:6,B_OV_COM:6,B_OV_COM_CLIENT:0,B_OV_REM:0,B_OV_PROG:100,PA_OV_TOT:27,PA_OV_REM:0,PB1_OV_TOT:23,PB1_OV_REM:0,PB2_OV_TOT:9,PB2_OV_REM:8,PREVENT_DC_REM:0,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:59,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-6070-6073-06",SUBSYSTEM_DESCR:"HSSD - LQ ,L5 telecom and UPS rooms",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B2",MC_PLAN:"2022-03-10",A_OV_TOT:8,A_OV_COM:8,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:4,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:4,B_OV_COM:4,B_OV_COM_CLIENT:0,B_OV_REM:0,B_OV_PROG:100,PA_OV_TOT:14,PA_OV_REM:0,PB1_OV_TOT:19,PB1_OV_REM:0,PB2_OV_TOT:6,PB2_OV_REM:6,PREVENT_DC_REM:0,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:39,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-6070-6073-07",SUBSYSTEM_DESCR:"HSSD - Topsides ,ME05 and LER",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-07",A_OV_TOT:36,A_OV_COM:7,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:29,A_OV_PROG:19.44,B_OV_TOT:18,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:18,B_OV_PROG:0,PB1_OV_TOT:1,PB1_OV_REM:0,PREVENT_DC_REM:18,ACT_TAG_VALID:36,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6070-6074-01",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables ME01",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-28",A_OV_TOT:16,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:16,A_OV_PROG:0,B_OV_TOT:8,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:8,B_OV_PROG:0,PB1_OV_TOT:3,PB1_OV_REM:0,PREVENT_DC_REM:8,ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6070-6074-02",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables MW01",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-05",A_OV_TOT:16,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:16,A_OV_PROG:0,B_OV_TOT:8,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:8,B_OV_PROG:0,ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6070-6074-03",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables ME02",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-05",A_OV_TOT:12,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:12,A_OV_PROG:0,B_OV_TOT:6,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:6,B_OV_PROG:0,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6070-6074-04",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables MW02",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-12",A_OV_TOT:16,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:16,A_OV_PROG:0,B_OV_TOT:8,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:8,B_OV_PROG:0,ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6070-6074-05",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables ME04",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-21",A_OV_TOT:8,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:8,A_OV_PROG:0,B_OV_TOT:4,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:4,B_OV_PROG:0,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6070-6074-06",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables MW04",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-05",A_OV_TOT:12,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:12,A_OV_PROG:0,B_OV_TOT:6,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:6,B_OV_PROG:0,PB1_OV_TOT:1,PB1_OV_REM:0,PREVENT_DC_REM:6,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6070-6074-07",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables MW05",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-03-31",A_OV_TOT:14,A_OV_COM:4,A_OV_COM_LW:2,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:10,A_OV_PROG:28.57,B_OV_TOT:4,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:4,B_OV_PROG:0,ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6100-6110-01",SUBSYSTEM_DESCR:"Topsides Fire Water Ring Main, Hydrant and Jockey Pumps",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-04-28",A_OV_TOT:22,A_OV_COM:12,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:4,A_OV_REM:10,A_OV_PROG:54.54,B_OV_TOT:9,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:9,B_OV_PROG:0,PA_OV_TOT:8,PA_OV_REM:1,PB1_OV_TOT:15,PB1_OV_REM:1,PB2_OV_TOT:5,PB2_OV_REM:3,PREVENT_DC_REM:10,ACT_TAG_VALID:27,PUNCH_NUMBER_VALID:28,PUNCH_TAG_VALID:10},
    {SUBSYSTEM:"21-6100-6110-02",SUBSYSTEM_DESCR:"Accommodation Firewater and Hydrants",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-26",A_OV_TOT:5,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:5,A_OV_PROG:0,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6100-6111-01",SUBSYSTEM_DESCR:"Fire Water Pump A ,21-SPF-611101",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-05-26",A_OV_TOT:104,A_OV_COM:39,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:65,A_OV_PROG:37.5,B_OV_TOT:115,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:115,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:0,PB1_OV_TOT:8,PB1_OV_REM:4,PB2_OV_TOT:3,PB2_OV_REM:3,PREVENT_DC_REM:119,ACT_TAG_VALID:202,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-6100-6111-02",SUBSYSTEM_DESCR:"Fire Water Pump B ,21-SPF-611201",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-05-26",A_OV_TOT:111,A_OV_COM:37,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:74,A_OV_PROG:33.33,B_OV_TOT:119,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:119,B_OV_PROG:0,PB1_OV_TOT:3,PB1_OV_REM:1,PREVENT_DC_REM:120,ACT_TAG_VALID:214,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6100-6111-03",SUBSYSTEM_DESCR:"Fire Water Pump C ,21-SPF-611301",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-07-07",A_OV_TOT:101,A_OV_COM:37,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:64,A_OV_PROG:36.63,B_OV_TOT:116,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:116,B_OV_PROG:0,PA_OV_TOT:4,PA_OV_REM:2,PB1_OV_TOT:2,PB1_OV_REM:1,PREVENT_DC_REM:117,ACT_TAG_VALID:201,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6100-6121-01",SUBSYSTEM_DESCR:"HULL Water Mist System",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-03",A_OV_TOT:53,A_OV_COM:53,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:20,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:78,B_OV_COM:61,B_OV_COM_CLIENT:0,B_OV_REM:17,B_OV_PROG:78.2,PA_OV_TOT:36,PA_OV_REM:0,PB1_OV_TOT:17,PB1_OV_REM:1,PB2_OV_TOT:49,PB2_OV_REM:39,PREVENT_DC_REM:18,ACT_TAG_VALID:130,PUNCH_NUMBER_VALID:102,PUNCH_TAG_VALID:48},
    {SUBSYSTEM:"21-6100-6140-01",SUBSYSTEM_DESCR:"Foam Package",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-04-07",A_OV_TOT:14,A_OV_COM:12,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:5,A_OV_REM:2,A_OV_PROG:85.71,B_OV_TOT:6,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:6,B_OV_PROG:0,PA_OV_TOT:15,PA_OV_REM:1,PB1_OV_TOT:11,PB1_OV_REM:0,PB2_OV_TOT:3,PB2_OV_REM:3,PREVENT_DC_REM:6,ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:29,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-6100-6140-03",SUBSYSTEM_DESCR:"Helideck Foam Package",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-07-14",A_OV_TOT:22,A_OV_COM:4,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:18,A_OV_PROG:18.18,B_OV_TOT:10,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:10,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:2,ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6100-6150-01",SUBSYSTEM_DESCR:"Topside Modules Deluged Systems",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-07-07",A_OV_TOT:218,A_OV_COM:110,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:108,A_OV_PROG:50.45,B_OV_TOT:84,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:84,B_OV_PROG:0,PA_OV_TOT:12,PA_OV_REM:4,PB1_OV_TOT:15,PB1_OV_REM:15,PREVENT_DC_REM:99,ACT_TAG_VALID:258,PUNCH_NUMBER_VALID:27,PUNCH_TAG_VALID:22},
    {SUBSYSTEM:"21-6100-6150-03",SUBSYSTEM_DESCR:"HULL Deck Deluged Systems",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-07-07",A_OV_TOT:149,A_OV_COM:86,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:2,A_OV_REM:63,A_OV_PROG:57.71,B_OV_TOT:54,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:54,B_OV_PROG:0,PA_OV_TOT:8,PA_OV_REM:4,PB1_OV_TOT:23,PB1_OV_REM:23,PREVENT_DC_REM:77,ACT_TAG_VALID:172,PUNCH_NUMBER_VALID:31,PUNCH_TAG_VALID:16},
    {SUBSYSTEM:"21-6200-6210-01",SUBSYSTEM_DESCR:"PAGA System Cabinets and Central Equipment",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-17",A_OV_TOT:40,A_OV_COM:40,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:9,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:18,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:18,B_OV_PROG:0,PA_OV_TOT:35,PA_OV_REM:0,PB1_OV_TOT:20,PB1_OV_REM:9,PB2_OV_TOT:27,PB2_OV_REM:20,PREVENT_DC_REM:27,ACT_TAG_VALID:48,PUNCH_NUMBER_VALID:82,PUNCH_TAG_VALID:30},
    {SUBSYSTEM:"21-6200-6224-01",SUBSYSTEM_DESCR:"Digital UHF Network",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-12",A_OV_TOT:136,A_OV_COM:37,A_OV_COM_LW:2,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:99,A_OV_PROG:27.2,B_OV_TOT:14,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:14,B_OV_PROG:0,PA_OV_TOT:3,PA_OV_REM:1,PB1_OV_TOT:2,PB1_OV_REM:0,PB2_OV_TOT:4,PB2_OV_REM:4,PREVENT_DC_REM:14,ACT_TAG_VALID:137,PUNCH_NUMBER_VALID:9,PUNCH_TAG_VALID:8},
    {SUBSYSTEM:"21-6200-6225-01",SUBSYSTEM_DESCR:"VHF ,AM Aeronautical Radio",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B2",MC_PLAN:"2022-06-23",A_OV_TOT:11,A_OV_COM:7,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:4,A_OV_PROG:63.63,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,PB1_OV_TOT:2,PB1_OV_REM:0,PB2_OV_TOT:1,PB2_OV_REM:1,PREVENT_DC_REM:2,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6500-6510-01",SUBSYSTEM_DESCR:"Navigation Lights Control Panel and Associated Lanterns andFoghorns Circuits",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-12",A_OV_TOT:60,A_OV_COM:4,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:56,A_OV_PROG:6.66,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0,PA_OV_TOT:11,PA_OV_REM:2,ACT_TAG_VALID:59,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-6500-6550-01",SUBSYSTEM_DESCR:"RADAR Vessel Early Warning System",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B2",MC_PLAN:"2022-05-05",A_OV_TOT:22,A_OV_COM:11,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:11,A_OV_PROG:50,B_OV_TOT:8,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:8,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:0,PB1_OV_TOT:2,PB1_OV_REM:0,PB2_OV_TOT:4,PB2_OV_REM:4,PREVENT_DC_REM:8,ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6500-6556-01",SUBSYSTEM_DESCR:"Automatic Identification System",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Complete",PUNCH_STATUS:"N",MC_PLAN:"2022-06-23",A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6600-6610-01",SUBSYSTEM_DESCR:"Emergency Generator 21-G-661001",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-12",A_OV_TOT:201,A_OV_COM:126,A_OV_COM_LW:10,A_OV_COM_TW:0,A_OV_COM_CLIENT:44,A_OV_REM:75,A_OV_PROG:62.68,B_OV_TOT:158,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:158,B_OV_PROG:0,PA_OV_TOT:26,PA_OV_REM:12,PB1_OV_TOT:36,PB1_OV_REM:13,PB2_OV_TOT:8,PB2_OV_REM:8,PREVENT_DC_REM:171,ACT_TAG_VALID:353,PUNCH_NUMBER_VALID:70,PUNCH_TAG_VALID:15},
    {SUBSYSTEM:"21-6600-6640-01",SUBSYSTEM_DESCR:"LQandHULL Emergency Lighting and small power 400V board 21-EDB-662401 ,in LQ",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B2",MC_PLAN:"2021-12-02",A_OV_TOT:13,A_OV_COM:13,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:13,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:11,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:11,B_OV_PROG:0,PA_OV_TOT:16,PA_OV_REM:0,PB1_OV_TOT:7,PB1_OV_REM:0,PB2_OV_TOT:2,PB2_OV_REM:1,PREVENT_DC_REM:11,ACT_TAG_VALID:24,PUNCH_NUMBER_VALID:25,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6600-6640-03",SUBSYSTEM_DESCR:"Sub Distribution Auxiliary Panel 01 ,ROOM: LH16 21-EDB-667671",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B2",MC_PLAN:"2021-12-30",A_OV_TOT:3,A_OV_COM:3,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0,PA_OV_TOT:4,PA_OV_REM:0,PB1_OV_TOT:6,PB1_OV_REM:0,PB2_OV_TOT:4,PB2_OV_REM:4,PREVENT_DC_REM:3,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:14,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6600-6640-04",SUBSYSTEM_DESCR:"Sub Distribution Auxiliary Panel 03 ,ROOM: M10621-EDB-662433",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B2",MC_PLAN:"2021-12-02",A_OV_TOT:3,A_OV_COM:3,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:5,PA_OV_REM:0,PB1_OV_TOT:6,PB1_OV_REM:0,PB2_OV_TOT:2,PB2_OV_REM:1,PREVENT_DC_REM:1,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6600-6660-01",SUBSYSTEM_DESCR:"Emergency 690 V Switchboard 21-ESB-666001",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Partial MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2021-11-04",A_OV_TOT:132,A_OV_COM:124,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:117,A_OV_REM:8,A_OV_PROG:93.93,B_OV_TOT:3,B_OV_COM:2,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:66.66,PA_OV_TOT:10,PA_OV_REM:0,PB1_OV_TOT:11,PB1_OV_REM:4,PB2_OV_TOT:41,PB2_OV_REM:8,PREVENT_DC_REM:5,ACT_TAG_VALID:132,PUNCH_NUMBER_VALID:62,PUNCH_TAG_VALID:19},
    {SUBSYSTEM:"21-6600-6670-01",SUBSYSTEM_DESCR:"Key service Generator 21-G-667001",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-02-17",A_OV_TOT:83,A_OV_COM:83,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:53,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:78,B_OV_COM:76,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:97.43,PA_OV_TOT:68,PA_OV_REM:0,PB1_OV_TOT:82,PB1_OV_REM:2,PB2_OV_TOT:51,PB2_OV_REM:19,PREVENT_DC_REM:4,ACT_TAG_VALID:154,PUNCH_NUMBER_VALID:201,PUNCH_TAG_VALID:34},
    {SUBSYSTEM:"21-6600-6670-02",SUBSYSTEM_DESCR:"Key service Generator 21-G-667002",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B2",MC_PLAN:"2022-02-17",A_OV_TOT:77,A_OV_COM:77,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:53,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:78,B_OV_COM:76,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:97.43,PA_OV_TOT:45,PA_OV_REM:0,PB1_OV_TOT:70,PB1_OV_REM:0,PB2_OV_TOT:26,PB2_OV_REM:15,PREVENT_DC_REM:2,ACT_TAG_VALID:148,PUNCH_NUMBER_VALID:141,PUNCH_TAG_VALID:24},
    {SUBSYSTEM:"21-6600-6673-01",SUBSYSTEM_DESCR:"Essential 6.6 kV Switchboard 21-ESB-667301",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2021-11-25",A_OV_TOT:250,A_OV_COM:211,A_OV_COM_LW:4,A_OV_COM_TW:0,A_OV_COM_CLIENT:199,A_OV_REM:39,A_OV_PROG:84.39,B_OV_TOT:4,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:4,B_OV_PROG:0,PA_OV_TOT:20,PA_OV_REM:0,PB1_OV_TOT:36,PB1_OV_REM:1,PB2_OV_TOT:11,PB2_OV_REM:10,PREVENT_DC_REM:5,ACT_TAG_VALID:252,PUNCH_NUMBER_VALID:67,PUNCH_TAG_VALID:20},
    {SUBSYSTEM:"21-6600-6674-01",SUBSYSTEM_DESCR:"HULL Normal and Essential 690 V Swtchboard 21-ESB-667401",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Partial MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2021-09-30",A_OV_TOT:190,A_OV_COM:188,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:182,A_OV_REM:2,A_OV_PROG:98.94,B_OV_TOT:3,B_OV_COM:2,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:66.66,PA_OV_TOT:9,PA_OV_REM:0,PB1_OV_TOT:12,PB1_OV_REM:1,PB2_OV_TOT:44,PB2_OV_REM:7, RFDC_REM: 0, ACT_TAG_VALID:190,PUNCH_NUMBER_VALID:65,PUNCH_TAG_VALID:11},
    {SUBSYSTEM:"21-6600-6674-02",SUBSYSTEM_DESCR:"Topside Essential 690 V Switchboard 21-ESB-667402",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-31",A_OV_TOT:104,A_OV_COM:92,A_OV_COM_LW:3,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:12,A_OV_PROG:88.46,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0,PA_OV_TOT:3,PA_OV_REM:0,PB1_OV_TOT:52,PB1_OV_REM:26,PB2_OV_TOT:5,PB2_OV_REM:0,PREVENT_DC_REM:29,ACT_TAG_VALID:104,PUNCH_NUMBER_VALID:60,PUNCH_TAG_VALID:15},
    {SUBSYSTEM:"21-6600-6675-01",SUBSYSTEM_DESCR:"HULL Normal and Essential 400 V Switchboard 21-ESB-667601",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Partial MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2021-10-21",A_OV_TOT:27,A_OV_COM:23,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:20,A_OV_REM:4,A_OV_PROG:85.18,B_OV_TOT:3,B_OV_COM:1,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:33.33,PA_OV_TOT:8,PA_OV_REM:0,PB1_OV_TOT:9,PB1_OV_REM:1,PB2_OV_TOT:26,PB2_OV_REM:5,PREVENT_DC_REM:3,ACT_TAG_VALID:27,PUNCH_NUMBER_VALID:43,PUNCH_TAG_VALID:9},
    {SUBSYSTEM:"21-6600-6675-02",SUBSYSTEM_DESCR:"Topsides Normal and Essential lighting and Small Power 400V 21-EDB-667602",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-04-07",A_OV_TOT:189,A_OV_COM:31,A_OV_COM_LW:2,A_OV_COM_TW:0,A_OV_COM_CLIENT:12,A_OV_REM:158,A_OV_PROG:16.4,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:0,PB1_OV_TOT:3,PB1_OV_REM:1,PB2_OV_TOT:1,PB2_OV_REM:1,PREVENT_DC_REM:2,ACT_TAG_VALID:143,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6600-6675-03",SUBSYSTEM_DESCR:"Topsides Normal and Essential lighting and Small Power 400V 21-EDB-667603",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-04-07",A_OV_TOT:2789,A_OV_COM:1318,A_OV_COM_LW:366,A_OV_COM_TW:0,A_OV_COM_CLIENT:1009,A_OV_REM:1471,A_OV_PROG:47.25,B_OV_TOT:26,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:26,B_OV_PROG:0,PA_OV_TOT:4,PA_OV_REM:4,PB1_OV_TOT:20,PB1_OV_REM:15,PB2_OV_TOT:23,PB2_OV_REM:23,PREVENT_DC_REM:41,ACT_TAG_VALID:2777,PUNCH_NUMBER_VALID:47,PUNCH_TAG_VALID:37},
    {SUBSYSTEM:"21-6600-6675-04",SUBSYSTEM_DESCR:"Topsides MOV and Convenience Outlets 400V 21-EDB-667604",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-04-07",A_OV_TOT:225,A_OV_COM:29,A_OV_COM_LW:2,A_OV_COM_TW:0,A_OV_COM_CLIENT:27,A_OV_REM:196,A_OV_PROG:12.88,B_OV_TOT:7,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:7,B_OV_PROG:0,PA_OV_TOT:11,PA_OV_REM:11,PB2_OV_TOT:1,PB2_OV_REM:1,ACT_TAG_VALID:219,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6600-6675-05",SUBSYSTEM_DESCR:"Topsides Misc Feeders and Auxiliaries 400V 21-EDB-667606",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-04-07",A_OV_TOT:30,A_OV_COM:1,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:29,A_OV_PROG:3.33,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0,PA_OV_TOT:16,PA_OV_REM:10,PB1_OV_TOT:2,PB1_OV_REM:1,PREVENT_DC_REM:4,ACT_TAG_VALID:33,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6600-6676-06",SUBSYSTEM_DESCR:"Normal Lighting Panel 05 ,ROOM: LH05 21-ELP-667660",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-03-31",A_OV_TOT:64,A_OV_COM:56,A_OV_COM_LW:22,A_OV_COM_TW:0,A_OV_COM_CLIENT:27,A_OV_REM:8,A_OV_PROG:87.5,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:6,PA_OV_REM:5,PB1_OV_TOT:8,PB1_OV_REM:1,PB2_OV_TOT:9,PB2_OV_REM:4,PREVENT_DC_REM:2,ACT_TAG_VALID:65,PUNCH_NUMBER_VALID:23,PUNCH_TAG_VALID:11},
    {SUBSYSTEM:"21-6600-6676-07",SUBSYSTEM_DESCR:"Normal Lighting Panel 06 ,ROOM: LA17 21-ELP-667661",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B2",MC_PLAN:"2022-03-31",A_OV_TOT:71,A_OV_COM:41,A_OV_COM_LW:7,A_OV_COM_TW:0,A_OV_COM_CLIENT:24,A_OV_REM:30,A_OV_PROG:57.74,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:3,PA_OV_REM:0,PB1_OV_TOT:5,PB1_OV_REM:0,PB2_OV_TOT:3,PB2_OV_REM:1,PREVENT_DC_REM:1,ACT_TAG_VALID:72,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-6600-6676-12",SUBSYSTEM_DESCR:"Normal Lighting Panel 11 ,ROOM: L591 21-ELP-667666",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-10",A_OV_TOT:74,A_OV_COM:74,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:58,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:1,B_OV_COM:1,B_OV_COM_CLIENT:0,B_OV_REM:0,B_OV_PROG:100,PA_OV_TOT:7,PA_OV_REM:0,PB1_OV_TOT:18,PB1_OV_REM:6,PB2_OV_TOT:6,PB2_OV_REM:5,PREVENT_DC_REM:6,ACT_TAG_VALID:75,PUNCH_NUMBER_VALID:31,PUNCH_TAG_VALID:11},
    {SUBSYSTEM:"21-6600-6676-14",SUBSYSTEM_DESCR:"LQ Laboratory Distribution Board ,ROOM: LA04 21-ELP-667675",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"N",MC_PLAN:"2021-12-09",A_OV_TOT:4,A_OV_COM:4,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:4,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:13,PA_OV_REM:0,PB1_OV_TOT:1,PB1_OV_REM:0,PB2_OV_TOT:3,PB2_OV_REM:0,PREVENT_DC_REM:1,ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:17,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6625-8725-01",SUBSYSTEM_DESCR:"Normal and Essential Heat Trace 400V Topsides 21-EDB-667605",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B2",MC_PLAN:"2022-04-28",A_OV_TOT:2,A_OV_COM:1,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:1,A_OV_PROG:50,B_OV_TOT:4,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:4,B_OV_PROG:0,PB2_OV_TOT:3,PB2_OV_REM:3,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6700-6701-01",SUBSYSTEM_DESCR:"Main LQ UPS System ,Incl Batteries, Breakers and Subdistribution Board",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"N",MC_PLAN:"2021-11-04",A_OV_TOT:40,A_OV_COM:33,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:31,A_OV_REM:7,A_OV_PROG:82.5,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,PA_OV_TOT:31,PA_OV_REM:0,PB1_OV_TOT:54,PB1_OV_REM:0,PB2_OV_TOT:3,PB2_OV_REM:0,PREVENT_DC_REM:2,ACT_TAG_VALID:40,PUNCH_NUMBER_VALID:88,PUNCH_TAG_VALID:18},
    {SUBSYSTEM:"21-6700-6701-02",SUBSYSTEM_DESCR:"LQ Telecom UPS System B for HULL and LQ ,Incl Batteries, Breakers and Subdistribution Board",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Partial MC Complete",PUNCH_STATUS:"B2",MC_PLAN:"2021-12-02",A_OV_TOT:16,A_OV_COM:13,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:12,A_OV_REM:3,A_OV_PROG:81.25,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:5,PA_OV_REM:0,PB1_OV_TOT:23,PB1_OV_REM:0,PB2_OV_TOT:3,PB2_OV_REM:1,PREVENT_DC_REM:1,ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:31,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-6700-6701-03",SUBSYSTEM_DESCR:"LQ Telecom UPS System A for Topsides ,Incl Batteries, Breakers and Subdistribution Board",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-17",A_OV_TOT:16,A_OV_COM:13,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:4,A_OV_REM:3,A_OV_PROG:81.25,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:10,PA_OV_REM:0,PB1_OV_TOT:23,PB1_OV_REM:5,PB2_OV_TOT:1,PB2_OV_REM:0,PB3_OV_TOT:3,PB3_OV_REM:1,PREVENT_DC_REM:6,RFDC_REM:1,ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:37,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-6700-6702-01",SUBSYSTEM_DESCR:"Topside Main UPS System ,Incl Batteries, Breakers and Subdistribution Board",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-02-10",A_OV_TOT:39,A_OV_COM:39,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:12,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:10,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:10,B_OV_PROG:0,PA_OV_TOT:5,PA_OV_REM:0,PB1_OV_TOT:43,PB1_OV_REM:17,PB2_OV_TOT:5,PB2_OV_REM:1,PREVENT_DC_REM:27,ACT_TAG_VALID:47,PUNCH_NUMBER_VALID:53,PUNCH_TAG_VALID:10},
    {SUBSYSTEM:"21-6700-6702-02",SUBSYSTEM_DESCR:"Subsea UPS System ,Incl Batteries, Breakers, Voltage Stabilisers and Subdistribution Board",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-02-10",A_OV_TOT:28,A_OV_COM:28,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:12,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,PA_OV_TOT:4,PA_OV_REM:0,PB1_OV_TOT:37,PB1_OV_REM:12,PB2_OV_TOT:3,PB2_OV_REM:1,PREVENT_DC_REM:14,ACT_TAG_VALID:28,PUNCH_NUMBER_VALID:44,PUNCH_TAG_VALID:8},
    {SUBSYSTEM:"21-6800-6830-01",SUBSYSTEM_DESCR:"Fire and Gas Detecion - LQ",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-04-21",A_OV_TOT:222,A_OV_COM:197,A_OV_COM_LW:13,A_OV_COM_TW:0,A_OV_COM_CLIENT:118,A_OV_REM:25,A_OV_PROG:88.73,B_OV_TOT:75,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:75,B_OV_PROG:0,PB1_OV_TOT:9,PB1_OV_REM:1,PB2_OV_TOT:11,PB2_OV_REM:11,PREVENT_DC_REM:76,ACT_TAG_VALID:223,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:13},
    {SUBSYSTEM:"21-6800-6830-02",SUBSYSTEM_DESCR:"Magnetic Door Holder  - LQ",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-06-30",A_OV_TOT:148,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:148,A_OV_PROG:0,B_OV_TOT:37,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:37,B_OV_PROG:0,ACT_TAG_VALID:185,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6840-01",SUBSYSTEM_DESCR:"Fire Zone 6840-1 - Power Generation Module-MW04 ,Open Area",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-04-28",A_OV_TOT:78,A_OV_COM:71,A_OV_COM_LW:6,A_OV_COM_TW:0,A_OV_COM_CLIENT:40,A_OV_REM:7,A_OV_PROG:91.02,B_OV_TOT:20,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:20,B_OV_PROG:0,PB1_OV_TOT:12,PB1_OV_REM:12,PREVENT_DC_REM:32,ACT_TAG_VALID:74,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-6800-6840-02",SUBSYSTEM_DESCR:"Fire Zone 6840-2 - Power Generation Module- ME04 ,Open Area",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-04-28",A_OV_TOT:81,A_OV_COM:32,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:14,A_OV_REM:49,A_OV_PROG:39.5,B_OV_TOT:20,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:20,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:2,PB1_OV_TOT:2,PB1_OV_REM:2,PREVENT_DC_REM:22,ACT_TAG_VALID:76,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6800-6840-03",SUBSYSTEM_DESCR:"Fire Zone 6840-3 - Gas Turbine Generator Package 21-Z-861101",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-14",A_OV_TOT:25,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:25,A_OV_PROG:0,B_OV_TOT:23,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:23,B_OV_PROG:0,ACT_TAG_VALID:48,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6840-04",SUBSYSTEM_DESCR:"Fire Zone 6840-4 - Gas Turbine Generator Package 21-Z-861201",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-14",A_OV_TOT:25,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:25,A_OV_PROG:0,B_OV_TOT:23,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:23,B_OV_PROG:0,ACT_TAG_VALID:48,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6840-05",SUBSYSTEM_DESCR:"Fire Zone 6840-5 - Gas Turbine Generator Package 21-Z-861301",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-14",A_OV_TOT:25,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:25,A_OV_PROG:0,B_OV_TOT:23,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:23,B_OV_PROG:0,ACT_TAG_VALID:48,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6840-06",SUBSYSTEM_DESCR:"Fire Zone 6840-6 - Gas Turbine Generator Package 21-Z-861401",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-14",A_OV_TOT:25,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:25,A_OV_PROG:0,B_OV_TOT:23,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:23,B_OV_PROG:0,ACT_TAG_VALID:48,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6840-07",SUBSYSTEM_DESCR:"Fire Zone 6840-7 - Utility Module–MW05",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-28",A_OV_TOT:114,A_OV_COM:30,A_OV_COM_LW:30,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:84,A_OV_PROG:26.31,B_OV_TOT:32,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:32,B_OV_PROG:0,ACT_TAG_VALID:111,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6840-08",SUBSYSTEM_DESCR:"Fire Zone 6840-8and9 -Pedestal Cranes ,WestandEast",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-06-09",A_OV_TOT:42,A_OV_COM:6,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:6,A_OV_REM:36,A_OV_PROG:14.28,B_OV_TOT:14,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:14,B_OV_PROG:0,ACT_TAG_VALID:48,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6841-01",SUBSYSTEM_DESCR:"Fire Zone 6841-0 - Reception Facilities and Production Separation Module - MW01",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-06-02",A_OV_TOT:316,A_OV_COM:6,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:310,A_OV_PROG:1.89,B_OV_TOT:75,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:75,B_OV_PROG:0,ACT_TAG_VALID:298,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6841-02",SUBSYSTEM_DESCR:"Fire Zone 6841-1 - Flash Gas Compression Module - ME01",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-12",A_OV_TOT:209,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:209,A_OV_PROG:0,B_OV_TOT:52,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:52,B_OV_PROG:0,ACT_TAG_VALID:197,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6841-03",SUBSYSTEM_DESCR:"Fire Zone 6841-2 - Gas Dewpointing andMEG and Methanol injection and Fuel gas Module - MW02",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-12",A_OV_TOT:281,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:281,A_OV_PROG:0,B_OV_TOT:75,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:75,B_OV_PROG:0,ACT_TAG_VALID:268,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6841-04",SUBSYSTEM_DESCR:"Fire Zone 6841-3 - Condensate stabilization and MEG regeneration and Produced Water treatment Module  - ME02",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-07-07",A_OV_TOT:226,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:226,A_OV_PROG:0,B_OV_TOT:52,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:52,B_OV_PROG:0,PB1_OV_TOT:1,PB1_OV_REM:1,PREVENT_DC_REM:53,ACT_TAG_VALID:212,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6800-6841-07",SUBSYSTEM_DESCR:"Fire Zone 6841-6 - Aft Platform on Deck A",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-06-09",A_OV_TOT:45,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:45,A_OV_PROG:0,B_OV_TOT:12,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:12,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:0,ACT_TAG_VALID:42,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6800-6841-08",SUBSYSTEM_DESCR:"Fire Zone 6841-7 - Piperack Between MW01 and ME01",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-07-07",A_OV_TOT:3,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:3,A_OV_PROG:0,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6841-09",SUBSYSTEM_DESCR:"Fire Zone 6841-8 - Piperack Between MW02 and ME02",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-06-16",A_OV_TOT:3,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:3,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-01",SUBSYSTEM_DESCR:"Fire Zone 6842-0 - Cargo Tank Top ,Rich Meg, Methanol, Salty Rich MEG 2, Offspec Condensate, Slop, Wax Inhibitor, Produced Water Storage Tanks and Flare Facilities Area, Including Marine Piperack",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-21",A_OV_TOT:143,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:143,A_OV_PROG:0,B_OV_TOT:40,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:40,B_OV_PROG:0,ACT_TAG_VALID:137,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-02",SUBSYSTEM_DESCR:"Fire Zone 6842-1 - Cargo Tank Top ,Condensate Tank 1 and 2 and Closed Drain Facilities",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-21",A_OV_TOT:101,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:101,A_OV_PROG:0,B_OV_TOT:23,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:23,B_OV_PROG:0,ACT_TAG_VALID:93,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-03",SUBSYSTEM_DESCR:"Fire Zone 6842-2 - Cargo Tank Top ,Salty Rich MEG Tanks 3and4",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-21",A_OV_TOT:107,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:107,A_OV_PROG:0,B_OV_TOT:25,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:25,B_OV_PROG:0,ACT_TAG_VALID:99,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-04",SUBSYSTEM_DESCR:"Fire Zone 6842-3 - Cargo Tank Top ,Condensate Tank 3 and 4",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-21",A_OV_TOT:68,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:68,A_OV_PROG:0,B_OV_TOT:16,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:16,B_OV_PROG:0,ACT_TAG_VALID:64,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-05",SUBSYSTEM_DESCR:"Fire Zone 6842-4 - Cargo Tank Top ,Condensate Tank 5 and 6",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-21",A_OV_TOT:77,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:77,A_OV_PROG:0,B_OV_TOT:18,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:18,B_OV_PROG:0,ACT_TAG_VALID:72,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-06",SUBSYSTEM_DESCR:"Fire Zone 6842-5 - Cargo Tank Top ,Condensate Tank 7 and 8",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-21",A_OV_TOT:135,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:135,A_OV_PROG:0,B_OV_TOT:20,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:20,B_OV_PROG:0,ACT_TAG_VALID:112,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-07",SUBSYSTEM_DESCR:"Fire Zone 6842-6 - Cargo Tank Top ,Lean MEG Storage Tanks",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-21",A_OV_TOT:84,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:84,A_OV_PROG:0,B_OV_TOT:19,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:19,B_OV_PROG:0,ACT_TAG_VALID:75,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-08",SUBSYSTEM_DESCR:"Fire Zone 6842-7 - Fire Water Pump Enclosure 611101",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Complete",PUNCH_STATUS:"N",MC_PLAN:"2022-06-09",A_OV_TOT:14,A_OV_COM:14,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:15,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:15,B_OV_PROG:0,ACT_TAG_VALID:29,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-09",SUBSYSTEM_DESCR:"Fire Zone 6842-8 - Fire Water Pump Enclosure 611201",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Complete",PUNCH_STATUS:"N",MC_PLAN:"2022-06-09",A_OV_TOT:14,A_OV_COM:14,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:14,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:14,B_OV_PROG:0,ACT_TAG_VALID:28,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-10",SUBSYSTEM_DESCR:"Fire Zone 6842-9 - Fire Water Pump Enclosure 611301",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Complete",PUNCH_STATUS:"N",MC_PLAN:"2022-05-05",A_OV_TOT:14,A_OV_COM:14,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:14,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:14,B_OV_PROG:0,ACT_TAG_VALID:28,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-11",SUBSYSTEM_DESCR:"Fire Zone 6843-0 - West Riser Balcony Area",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-26",A_OV_TOT:19,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:19,A_OV_PROG:0,B_OV_TOT:4,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:4,B_OV_PROG:0,ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-13",SUBSYSTEM_DESCR:"Fire Zone 6843-3 - Emergency Diesel Generators",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-05",A_OV_TOT:3,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:3,A_OV_PROG:0,B_OV_TOT:12,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:12,B_OV_PROG:0,ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-16",SUBSYSTEM_DESCR:"Fire Zone 6850 - General Topsides Area",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-07-21",A_OV_TOT:30,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:30,A_OV_PROG:0,B_OV_TOT:10,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:10,B_OV_PROG:0,ACT_TAG_VALID:30,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6844-01",SUBSYSTEM_DESCR:"Fire and Gas Detecion - Machinery Space",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-10",A_OV_TOT:58,A_OV_COM:58,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:38,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:15,B_OV_COM:10,B_OV_COM_CLIENT:0,B_OV_REM:5,B_OV_PROG:66.66,PA_OV_TOT:6,PA_OV_REM:0,PB1_OV_TOT:6,PB1_OV_REM:1,PB2_OV_TOT:35,PB2_OV_REM:35,PREVENT_DC_REM:6,ACT_TAG_VALID:54,PUNCH_NUMBER_VALID:47,PUNCH_TAG_VALID:26},
    {SUBSYSTEM:"21-6800-6850-01",SUBSYSTEM_DESCR:"Fire and Gas Detecion - ME05",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-04-21",A_OV_TOT:60,A_OV_COM:51,A_OV_COM_LW:45,A_OV_COM_TW:0,A_OV_COM_CLIENT:34,A_OV_REM:9,A_OV_PROG:85,B_OV_TOT:23,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:23,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:1,PB1_OV_TOT:1,PB1_OV_REM:1,PB2_OV_TOT:4,PB2_OV_REM:4,PREVENT_DC_REM:24,ACT_TAG_VALID:63,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-6800-6854-01",SUBSYSTEM_DESCR:"Fire Zone 6854 - Transit Refuge Area",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-06-30",A_OV_TOT:18,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:18,A_OV_PROG:0,B_OV_TOT:6,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:6,B_OV_PROG:0,ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8000-8010-01",SUBSYSTEM_DESCR:"Potable Water Maker",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-26",A_OV_TOT:50,A_OV_COM:46,A_OV_COM_LW:3,A_OV_COM_TW:0,A_OV_COM_CLIENT:12,A_OV_REM:4,A_OV_PROG:92,B_OV_TOT:100,B_OV_COM:4,B_OV_COM_CLIENT:0,B_OV_REM:96,B_OV_PROG:4,PA_OV_TOT:31,PA_OV_REM:6,PB1_OV_TOT:27,PB1_OV_REM:12,PB2_OV_TOT:24,PB2_OV_REM:19,PREVENT_DC_REM:108,ACT_TAG_VALID:141,PUNCH_NUMBER_VALID:82,PUNCH_TAG_VALID:13},
    {SUBSYSTEM:"21-8000-8010-02",SUBSYSTEM_DESCR:"Chlorination Package",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-05-19",A_OV_TOT:6,A_OV_COM:6,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:2,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0,PA_OV_TOT:13,PA_OV_REM:0,PB1_OV_TOT:7,PB1_OV_REM:2,PB2_OV_TOT:9,PB2_OV_REM:6,PREVENT_DC_REM:5,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:29,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-8000-8011-01",SUBSYSTEM_DESCR:"Potable Water Tanks and Pumps",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-03-24",A_OV_TOT:38,A_OV_COM:37,A_OV_COM_LW:5,A_OV_COM_TW:0,A_OV_COM_CLIENT:13,A_OV_REM:1,A_OV_PROG:97.36,B_OV_TOT:11,B_OV_COM:2,B_OV_COM_CLIENT:0,B_OV_REM:9,B_OV_PROG:18.18,PA_OV_TOT:20,PA_OV_REM:5,PB1_OV_TOT:24,PB1_OV_REM:15,PB2_OV_TOT:19,PB2_OV_REM:7,PREVENT_DC_REM:24,ACT_TAG_VALID:37,PUNCH_NUMBER_VALID:63,PUNCH_TAG_VALID:18},
    {SUBSYSTEM:"21-8000-8011-02",SUBSYSTEM_DESCR:"Fresh Water Bunkering Header",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-05",A_OV_TOT:10,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:10,A_OV_PROG:0,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8000-8012-01",SUBSYSTEM_DESCR:"Rehardener and UV Sterilizer",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-02-10",A_OV_TOT:12,A_OV_COM:12,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:3,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:13,B_OV_COM:12,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:92.3,PA_OV_TOT:5,PA_OV_REM:0,PB1_OV_TOT:11,PB1_OV_REM:2,PB2_OV_TOT:9,PB2_OV_REM:2,PREVENT_DC_REM:3,ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:25,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-8000-8012-02",SUBSYSTEM_DESCR:"Main Distribution and Potable Water Cooler",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-03",A_OV_TOT:15,A_OV_COM:15,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:6,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:4,B_OV_COM:4,B_OV_COM_CLIENT:0,B_OV_REM:0,B_OV_PROG:100,PA_OV_TOT:15,PA_OV_REM:0,PB1_OV_TOT:20,PB1_OV_REM:9,PB2_OV_TOT:15,PB2_OV_REM:8,PREVENT_DC_REM:9,ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:50,PUNCH_TAG_VALID:9},
    {SUBSYSTEM:"21-8000-8012-03",SUBSYSTEM_DESCR:"Cold Potable Water Distribution to HULL",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-04-21",A_OV_TOT:7,A_OV_COM:6,A_OV_COM_LW:2,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:1,A_OV_PROG:85.71,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:0,PB1_OV_TOT:3,PB1_OV_REM:1,PB2_OV_TOT:2,PB2_OV_REM:2,PREVENT_DC_REM:4,ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-8000-8012-04",SUBSYSTEM_DESCR:"Cold Potable Water Distribution to LQ",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-04-14",A_OV_TOT:7,A_OV_COM:7,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:4,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:4,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:0,PB1_OV_TOT:4,PB1_OV_REM:1,PB2_OV_TOT:5,PB2_OV_REM:4,PREVENT_DC_REM:5,ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-8000-8013-01",SUBSYSTEM_DESCR:"Hot Water Calorifiers",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-19",A_OV_TOT:17,A_OV_COM:16,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:4,A_OV_REM:1,A_OV_PROG:94.11,B_OV_TOT:13,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:13,B_OV_PROG:0,PA_OV_TOT:24,PA_OV_REM:5,PB1_OV_TOT:5,PB1_OV_REM:2,PB2_OV_TOT:6,PB2_OV_REM:3,PREVENT_DC_REM:15,ACT_TAG_VALID:23,PUNCH_NUMBER_VALID:35,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-8000-8013-02",SUBSYSTEM_DESCR:"Hot Water Distribution and Recirculation Pumps",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-19",A_OV_TOT:25,A_OV_COM:24,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:10,A_OV_REM:1,A_OV_PROG:96,B_OV_TOT:9,B_OV_COM:2,B_OV_COM_CLIENT:0,B_OV_REM:7,B_OV_PROG:22.22,PA_OV_TOT:4,PA_OV_REM:1,PB1_OV_TOT:3,PB1_OV_REM:0,PB2_OV_TOT:5,PB2_OV_REM:2,PREVENT_DC_REM:7,ACT_TAG_VALID:28,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-8000-8014-01",SUBSYSTEM_DESCR:"Fresh Service Water Tank and Pumps",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-24",A_OV_TOT:39,A_OV_COM:39,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:11,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:10,B_OV_COM:10,B_OV_COM_CLIENT:0,B_OV_REM:0,B_OV_PROG:100,PA_OV_TOT:21,PA_OV_REM:0,PB1_OV_TOT:31,PB1_OV_REM:9,PB2_OV_TOT:33,PB2_OV_REM:15,PREVENT_DC_REM:9,ACT_TAG_VALID:42,PUNCH_NUMBER_VALID:85,PUNCH_TAG_VALID:23},
    {SUBSYSTEM:"21-8000-8014-02",SUBSYSTEM_DESCR:"Fresh Service Water Distribution to HULL",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-03",A_OV_TOT:5,A_OV_COM:5,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:2,B_OV_COM:2,B_OV_COM_CLIENT:0,B_OV_REM:0,B_OV_PROG:100,PA_OV_TOT:6,PA_OV_REM:0,PB1_OV_TOT:15,PB1_OV_REM:1,PB2_OV_TOT:13,PB2_OV_REM:11,PREVENT_DC_REM:1,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:34,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-8000-8014-03",SUBSYSTEM_DESCR:"Fresh Service Water Distribution to Topsides",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-19",A_OV_TOT:14,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:14,A_OV_PROG:0,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0,ACT_TAG_VALID:13,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8020-8020-01",SUBSYSTEM_DESCR:"Marine Sea Water Sea Suction Tube and Filter 21-F-802001",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B2",MC_PLAN:"2022-03-03",A_OV_TOT:11,A_OV_COM:11,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:4,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:3,B_OV_COM:2,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:66.66,PA_OV_TOT:6,PA_OV_REM:0,PB1_OV_TOT:18,PB1_OV_REM:0,PB2_OV_TOT:10,PB2_OV_REM:3,PREVENT_DC_REM:1,ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:34,PUNCH_TAG_VALID:8},
    {SUBSYSTEM:"21-8020-8020-02",SUBSYSTEM_DESCR:"Marine Sea Water Sea Suction Tube and Filter 21-F-802002",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B2",MC_PLAN:"2022-03-03",A_OV_TOT:10,A_OV_COM:10,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:3,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:3,B_OV_COM:3,B_OV_COM_CLIENT:0,B_OV_REM:0,B_OV_PROG:100,PA_OV_TOT:9,PA_OV_REM:0,PB1_OV_TOT:8,PB1_OV_REM:0,PB2_OV_TOT:11,PB2_OV_REM:1,PREVENT_DC_REM:0,ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:28,PUNCH_TAG_VALID:9},
    {SUBSYSTEM:"21-8020-8020-03",SUBSYSTEM_DESCR:"Marine Sea Water Main Distribution Header",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B2",MC_PLAN:"2022-03-03",A_OV_TOT:20,A_OV_COM:20,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:5,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:5,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:5,B_OV_PROG:0,PA_OV_TOT:3,PA_OV_REM:0,PB1_OV_TOT:18,PB1_OV_REM:0,PB2_OV_TOT:23,PB2_OV_REM:18,PREVENT_DC_REM:5,ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:44,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-8020-8021-01",SUBSYSTEM_DESCR:"IGG Scrubber Cooling Sea Water Pumps - 21-P802003AandB",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Partial MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-04-14",A_OV_TOT:34,A_OV_COM:32,A_OV_COM_LW:6,A_OV_COM_TW:0,A_OV_COM_CLIENT:8,A_OV_REM:2,A_OV_PROG:94.11,B_OV_TOT:10,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:10,B_OV_PROG:0,PA_OV_TOT:7,PA_OV_REM:0,PB1_OV_TOT:25,PB1_OV_REM:16,PB2_OV_TOT:13,PB2_OV_REM:6,PREVENT_DC_REM:26,ACT_TAG_VALID:36,PUNCH_NUMBER_VALID:45,PUNCH_TAG_VALID:14},
    {SUBSYSTEM:"21-8020-8023-01",SUBSYSTEM_DESCR:"IGG Deck Seal Sea Water Pumps - 21-P802002AandB",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-04-14",A_OV_TOT:30,A_OV_COM:30,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:10,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:11,B_OV_COM:2,B_OV_COM_CLIENT:0,B_OV_REM:9,B_OV_PROG:18.18,PA_OV_TOT:1,PA_OV_REM:0,PB1_OV_TOT:12,PB1_OV_REM:2,PB2_OV_TOT:8,PB2_OV_REM:5,PREVENT_DC_REM:11,ACT_TAG_VALID:33,PUNCH_NUMBER_VALID:21,PUNCH_TAG_VALID:12},
    {SUBSYSTEM:"21-8020-8024-01",SUBSYSTEM_DESCR:"HULL Sea Water Cooling Pumps - 21-P-802004AandB",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-31",A_OV_TOT:32,A_OV_COM:32,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:10,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:18,B_OV_COM:14,B_OV_COM_CLIENT:0,B_OV_REM:4,B_OV_PROG:77.77,PA_OV_TOT:9,PA_OV_REM:0,PB1_OV_TOT:43,PB1_OV_REM:10,PB2_OV_TOT:31,PB2_OV_REM:14,PREVENT_DC_REM:14,ACT_TAG_VALID:42,PUNCH_NUMBER_VALID:83,PUNCH_TAG_VALID:23},
    {SUBSYSTEM:"21-8020-8024-02",SUBSYSTEM_DESCR:"HULL Sea Water Cooling Header and Cooling Water Exchangers - 21-H-805001AandB",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-03",A_OV_TOT:16,A_OV_COM:16,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:5,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:5,B_OV_COM:3,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:60,PA_OV_TOT:6,PA_OV_REM:0,PB1_OV_TOT:12,PB1_OV_REM:3,PB2_OV_TOT:21,PB2_OV_REM:12,PREVENT_DC_REM:5,ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:39,PUNCH_TAG_VALID:15},
    {SUBSYSTEM:"21-8020-8025-01",SUBSYSTEM_DESCR:"HULL Service Sea Water Pumps - 21-P-802005AandB",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Partial MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-24",A_OV_TOT:41,A_OV_COM:41,A_OV_COM_LW:3,A_OV_COM_TW:0,A_OV_COM_CLIENT:11,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:13,B_OV_COM:2,B_OV_COM_CLIENT:0,B_OV_REM:11,B_OV_PROG:15.38,PA_OV_TOT:14,PA_OV_REM:0,PB1_OV_TOT:38,PB1_OV_REM:15,PB2_OV_TOT:27,PB2_OV_REM:16,PREVENT_DC_REM:26,ACT_TAG_VALID:45,PUNCH_NUMBER_VALID:79,PUNCH_TAG_VALID:20},
    {SUBSYSTEM:"21-8020-8026-01",SUBSYSTEM_DESCR:"HULL Service Sea Water to HVAC Cooling",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-05-19",A_OV_TOT:23,A_OV_COM:14,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:4,A_OV_REM:9,A_OV_PROG:60.86,B_OV_TOT:7,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:7,B_OV_PROG:0,PB1_OV_TOT:15,PB1_OV_REM:3,PB2_OV_TOT:2,PB2_OV_REM:1,PREVENT_DC_REM:10,ACT_TAG_VALID:24,PUNCH_NUMBER_VALID:17,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-8020-8027-01",SUBSYSTEM_DESCR:"HULL Service Sea Water Booster Pumps - 21-P-802008AandB",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Complete",PUNCH_STATUS:"A",MC_PLAN:"2022-03-24",A_OV_TOT:32,A_OV_COM:32,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:12,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:11,B_OV_COM:2,B_OV_COM_CLIENT:0,B_OV_REM:9,B_OV_PROG:18.18,PA_OV_TOT:20,PA_OV_REM:11,PB1_OV_TOT:28,PB1_OV_REM:13,PB2_OV_TOT:14,PB2_OV_REM:10,PREVENT_DC_REM:22,ACT_TAG_VALID:35,PUNCH_NUMBER_VALID:62,PUNCH_TAG_VALID:10},
    {SUBSYSTEM:"21-8020-8027-02",SUBSYSTEM_DESCR:"HULL Service Sea Water to Electrochlorination Package",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Complete",PUNCH_STATUS:"N",MC_PLAN:"2022-05-12",A_OV_TOT:3,A_OV_COM:3,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,PB1_OV_TOT:5,PB1_OV_REM:0,PREVENT_DC_REM:2,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8020-8027-03",SUBSYSTEM_DESCR:"HULL Service Sea Water to Potable Water Package",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-24",A_OV_TOT:3,A_OV_COM:3,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PB1_OV_TOT:5,PB1_OV_REM:1,PREVENT_DC_REM:2,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8020-8029-01",SUBSYSTEM_DESCR:"KSG Cooling Sea Water Pumps 21-P-802009AandB and Distribution",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B2",MC_PLAN:"2022-03-03",A_OV_TOT:32,A_OV_COM:32,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:11,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:11,B_OV_COM:11,B_OV_COM_CLIENT:0,B_OV_REM:0,B_OV_PROG:100,PA_OV_TOT:10,PA_OV_REM:0,PB1_OV_TOT:46,PB1_OV_REM:0,PB2_OV_TOT:64,PB2_OV_REM:40,PREVENT_DC_REM:0,ACT_TAG_VALID:35,PUNCH_NUMBER_VALID:120,PUNCH_TAG_VALID:30},
    {SUBSYSTEM:"21-8040-8040-01",SUBSYSTEM_DESCR:"Heating Medium ,Hot Water Expansion Tank",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-05-05",A_OV_TOT:62,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:62,A_OV_PROG:0,B_OV_TOT:17,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:17,B_OV_PROG:0,PB1_OV_TOT:1,PB1_OV_REM:1,PREVENT_DC_REM:18,ACT_TAG_VALID:56,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8040-8040-02",SUBSYSTEM_DESCR:"Heating Medium ,Hot Water Pumps and Loop and Distribution to Exchangers",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-05",A_OV_TOT:296,A_OV_COM:37,A_OV_COM_LW:15,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:259,A_OV_PROG:12.5,B_OV_TOT:74,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:74,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:2,ACT_TAG_VALID:278,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8050-8051-01",SUBSYSTEM_DESCR:"Marine Cooling Medium Pumps - 21-P805002AandB",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Complete",PUNCH_STATUS:"A",MC_PLAN:"2022-03-24",A_OV_TOT:18,A_OV_COM:18,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:8,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:8,B_OV_COM:3,B_OV_COM_CLIENT:0,B_OV_REM:5,B_OV_PROG:37.5,PA_OV_TOT:19,PA_OV_REM:6,PB1_OV_TOT:20,PB1_OV_REM:6,PB2_OV_TOT:18,PB2_OV_REM:14,PREVENT_DC_REM:11,ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:57,PUNCH_TAG_VALID:14},
    {SUBSYSTEM:"21-8050-8051-02",SUBSYSTEM_DESCR:"Marine Cooling Medium Expansion Tank",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Complete",PUNCH_STATUS:"A",MC_PLAN:"2022-03-24",A_OV_TOT:13,A_OV_COM:13,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:4,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:4,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:4,B_OV_PROG:0,PA_OV_TOT:7,PA_OV_REM:2,PB1_OV_TOT:13,PB1_OV_REM:10,PB2_OV_TOT:10,PB2_OV_REM:9,PREVENT_DC_REM:14,ACT_TAG_VALID:13,PUNCH_NUMBER_VALID:30,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-8050-8051-03",SUBSYSTEM_DESCR:"Marine Cooling Medium Distribution",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-24",A_OV_TOT:23,A_OV_COM:23,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:6,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:8,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:8,B_OV_PROG:0,PA_OV_TOT:7,PA_OV_REM:0,PB1_OV_TOT:16,PB1_OV_REM:4,PB2_OV_TOT:27,PB2_OV_REM:16,PREVENT_DC_REM:12,ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:50,PUNCH_TAG_VALID:14},
    {SUBSYSTEM:"21-8050-8052-01",SUBSYSTEM_DESCR:"Topisdes Cooling Medium Pumps - 21-P805001AandB",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-03-31",A_OV_TOT:88,A_OV_COM:6,A_OV_COM_LW:2,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:82,A_OV_PROG:6.81,B_OV_TOT:32,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:32,B_OV_PROG:0,PA_OV_TOT:9,PA_OV_REM:3,PB1_OV_TOT:3,PB1_OV_REM:2,PB2_OV_TOT:1,PB2_OV_REM:1,PREVENT_DC_REM:34,ACT_TAG_VALID:102,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-8050-8052-02",SUBSYSTEM_DESCR:"Start-up Cooling Medium Pumps - 21-P805003",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-03-31",A_OV_TOT:24,A_OV_COM:2,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:22,A_OV_PROG:8.33,B_OV_TOT:6,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:6,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:1,ACT_TAG_VALID:23,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8050-8052-03",SUBSYSTEM_DESCR:"Topsides Cooling Medium Expansion Tank",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-31",A_OV_TOT:56,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:56,A_OV_PROG:0,B_OV_TOT:15,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:15,B_OV_PROG:0,PB1_OV_TOT:1,PB1_OV_REM:1,PREVENT_DC_REM:16,ACT_TAG_VALID:52,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8050-8052-04",SUBSYSTEM_DESCR:"Topsides Cooling Medium Distribution and Exchangers",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-07",A_OV_TOT:174,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:174,A_OV_PROG:0,B_OV_TOT:41,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:41,B_OV_PROG:0,ACT_TAG_VALID:161,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8060-8061-01",SUBSYSTEM_DESCR:"Diesel Bunker Header",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Partial MC Complete",PUNCH_STATUS:"B2",MC_PLAN:"2022-03-10",A_OV_TOT:14,A_OV_COM:13,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:6,A_OV_REM:1,A_OV_PROG:92.85,B_OV_TOT:3,B_OV_COM:2,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:66.66,PA_OV_TOT:18,PA_OV_REM:0,PB1_OV_TOT:2,PB1_OV_REM:0,PB2_OV_TOT:49,PB2_OV_REM:48,PREVENT_DC_REM:1,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:69,PUNCH_TAG_VALID:11},
    {SUBSYSTEM:"21-8060-8061-02",SUBSYSTEM_DESCR:"Diesel Storage Tanks - 21-T-806001and2 and Purifier Pumps - 21-P-806002AandB",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-03",A_OV_TOT:76,A_OV_COM:76,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:35,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:17,B_OV_COM:16,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:94.11,PA_OV_TOT:12,PA_OV_REM:0,PB1_OV_TOT:64,PB1_OV_REM:1,PB2_OV_TOT:79,PB2_OV_REM:45,PREVENT_DC_REM:2,ACT_TAG_VALID:66,PUNCH_NUMBER_VALID:155,PUNCH_TAG_VALID:38},
    {SUBSYSTEM:"21-8060-8062-01",SUBSYSTEM_DESCR:"Diesel Service Tanks - 21-T-806003and4 and Supply Pumps - 21-P-806001AandB",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Partial MC Complete",PUNCH_STATUS:"A",MC_PLAN:"2022-03-03",A_OV_TOT:85,A_OV_COM:84,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:35,A_OV_REM:1,A_OV_PROG:98.82,B_OV_TOT:28,B_OV_COM:23,B_OV_COM_CLIENT:0,B_OV_REM:5,B_OV_PROG:82.14,PA_OV_TOT:22,PA_OV_REM:1,PB1_OV_TOT:81,PB1_OV_REM:0,PB2_OV_TOT:87,PB2_OV_REM:40,PREVENT_DC_REM:5,ACT_TAG_VALID:85,PUNCH_NUMBER_VALID:190,PUNCH_TAG_VALID:60},
    {SUBSYSTEM:"21-8060-8062-02",SUBSYSTEM_DESCR:"Purifier Package",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B2",MC_PLAN:"2022-02-24",A_OV_TOT:6,A_OV_COM:6,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:2,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:13,B_OV_COM:12,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:92.3,PA_OV_TOT:24,PA_OV_REM:0,PB1_OV_TOT:13,PB1_OV_REM:0,PB2_OV_TOT:17,PB2_OV_REM:5,PREVENT_DC_REM:1,ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:54,PUNCH_TAG_VALID:18},
    {SUBSYSTEM:"21-8060-8063-01",SUBSYSTEM_DESCR:"Diesel Distribution Header to Users",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-31",A_OV_TOT:40,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:40,A_OV_PROG:0,B_OV_TOT:8,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:8,B_OV_PROG:0,PB1_OV_TOT:2,PB1_OV_REM:2,PREVENT_DC_REM:10,ACT_TAG_VALID:40,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8090-8090-01",SUBSYSTEM_DESCR:"Sewage Treatment Package",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-05-05",A_OV_TOT:9,A_OV_COM:8,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:2,A_OV_REM:1,A_OV_PROG:88.88,B_OV_TOT:31,B_OV_COM:1,B_OV_COM_CLIENT:0,B_OV_REM:30,B_OV_PROG:3.22,PA_OV_TOT:14,PA_OV_REM:0,PB1_OV_TOT:2,PB1_OV_REM:2,PB2_OV_TOT:8,PB2_OV_REM:7,PREVENT_DC_REM:32,ACT_TAG_VALID:37,PUNCH_NUMBER_VALID:24,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-8090-8090-02",SUBSYSTEM_DESCR:"Sewage Collection and Discharge Headers",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-05-05",A_OV_TOT:15,A_OV_COM:3,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:12,A_OV_PROG:20,B_OV_TOT:5,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:5,B_OV_PROG:0,PB1_OV_TOT:4,PB1_OV_REM:3,PREVENT_DC_REM:8,ACT_TAG_VALID:19,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8100-8120-01",SUBSYSTEM_DESCR:"HULL Air Compressor 21-Z-811002",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-02-24",A_OV_TOT:27,A_OV_COM:27,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:10,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:52,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:52,B_OV_PROG:0,PA_OV_TOT:14,PA_OV_REM:0,PB1_OV_TOT:27,PB1_OV_REM:6,PB2_OV_TOT:9,PB2_OV_REM:5,PB3_OV_TOT:2,PB3_OV_REM:2,PREVENT_DC_REM:58,RFDC_REM:7,ACT_TAG_VALID:72,PUNCH_NUMBER_VALID:52,PUNCH_TAG_VALID:18},
    {SUBSYSTEM:"21-8100-8120-02",SUBSYSTEM_DESCR:"HULL Air Drier 21-Z-812020",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-03",A_OV_TOT:7,A_OV_COM:7,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:10,B_OV_COM:9,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:90,PA_OV_TOT:8,PA_OV_REM:0,PB1_OV_TOT:15,PB1_OV_REM:3,PB2_OV_TOT:10,PB2_OV_REM:2,PREVENT_DC_REM:4,ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:33,PUNCH_TAG_VALID:15},
    {SUBSYSTEM:"21-8100-8120-03",SUBSYSTEM_DESCR:"HULL Air Receiver 21-V-812002, Main header and Controls",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B2",MC_PLAN:"2022-02-24",A_OV_TOT:15,A_OV_COM:15,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:5,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:8,B_OV_COM:7,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:87.5,PA_OV_TOT:5,PA_OV_REM:0,PB1_OV_TOT:15,PB1_OV_REM:0,PB2_OV_TOT:6,PB2_OV_REM:1,PREVENT_DC_REM:1,ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:26,PUNCH_TAG_VALID:11},
    {SUBSYSTEM:"21-8100-8121-01",SUBSYSTEM_DESCR:"HULL Instrument Air Distribution",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B2",MC_PLAN:"2022-03-03",A_OV_TOT:10,A_OV_COM:7,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:2,A_OV_REM:3,A_OV_PROG:70,B_OV_TOT:5,B_OV_COM:3,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:60,PA_OV_TOT:12,PA_OV_REM:0,PB1_OV_TOT:24,PB1_OV_REM:0,PB2_OV_TOT:48,PB2_OV_REM:40,PREVENT_DC_REM:2,ACT_TAG_VALID:13,PUNCH_NUMBER_VALID:84,PUNCH_TAG_VALID:15},
    {SUBSYSTEM:"21-8100-8122-01",SUBSYSTEM_DESCR:"Topside Air Compressor 21-Z-811101",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-03-31",A_OV_TOT:100,A_OV_COM:75,A_OV_COM_LW:6,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:25,A_OV_PROG:75,B_OV_TOT:60,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:60,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:1,PB1_OV_TOT:3,PB1_OV_REM:2,PB2_OV_TOT:7,PB2_OV_REM:7,PREVENT_DC_REM:62,ACT_TAG_VALID:151,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8100-8122-02",SUBSYSTEM_DESCR:"Topside Air Compressor 21-Z-811201",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-03-31",A_OV_TOT:78,A_OV_COM:68,A_OV_COM_LW:5,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:10,A_OV_PROG:87.17,B_OV_TOT:54,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:54,B_OV_PROG:0,PA_OV_TOT:4,PA_OV_REM:3,PB1_OV_TOT:5,PB1_OV_REM:4,PB2_OV_TOT:1,PB2_OV_REM:1,PREVENT_DC_REM:58,ACT_TAG_VALID:129,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8100-8122-03",SUBSYSTEM_DESCR:"Topside Air Compressor 21-Z-811301",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-03-31",A_OV_TOT:77,A_OV_COM:69,A_OV_COM_LW:6,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:8,A_OV_PROG:89.61,B_OV_TOT:54,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:54,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:1,PB1_OV_TOT:1,PB1_OV_REM:1,PB2_OV_TOT:1,PB2_OV_REM:1,PREVENT_DC_REM:55,ACT_TAG_VALID:128,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8100-8122-04",SUBSYSTEM_DESCR:"Topside Air Driers 21-Z-812001",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-03-31",A_OV_TOT:92,A_OV_COM:51,A_OV_COM_LW:2,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:41,A_OV_PROG:55.43,B_OV_TOT:30,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:30,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:2,PB1_OV_TOT:7,PB1_OV_REM:7,PREVENT_DC_REM:37,ACT_TAG_VALID:117,PUNCH_NUMBER_VALID:9,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8100-8122-05",SUBSYSTEM_DESCR:"Topside Air Receiver 21-V-812001, Main header and Controls",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-03-31",A_OV_TOT:35,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:35,A_OV_PROG:0,B_OV_TOT:10,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:10,B_OV_PROG:0,ACT_TAG_VALID:34,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8100-8123-01",SUBSYSTEM_DESCR:"Topsides Instrument Air Distribution",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-06-16",A_OV_TOT:5,A_OV_COM:1,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:4,A_OV_PROG:20,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8130-8130-01",SUBSYSTEM_DESCR:"Fuel Gas KO Drum",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-19",A_OV_TOT:78,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:78,A_OV_PROG:0,B_OV_TOT:19,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:19,B_OV_PROG:0,ACT_TAG_VALID:72,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8130-8130-02",SUBSYSTEM_DESCR:"Fuel Gas Superheaters",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-26",A_OV_TOT:58,A_OV_COM:6,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:52,A_OV_PROG:10.34,B_OV_TOT:13,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:13,B_OV_PROG:0,ACT_TAG_VALID:62,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8130-8131-01",SUBSYSTEM_DESCR:"Fuel Gas Blanketing Distribution in HULL",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-04-28",A_OV_TOT:94,A_OV_COM:2,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:92,A_OV_PROG:2.12,B_OV_TOT:20,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:20,B_OV_PROG:0,PA_OV_TOT:6,PA_OV_REM:6,PB2_OV_TOT:1,PB2_OV_REM:1,ACT_TAG_VALID:76,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-8130-8131-02",SUBSYSTEM_DESCR:"Fuel Gas Filters and Main Header",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-06-02",A_OV_TOT:26,A_OV_COM:2,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:24,A_OV_PROG:7.69,B_OV_TOT:12,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:12,B_OV_PROG:0,ACT_TAG_VALID:29,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8130-8131-03",SUBSYSTEM_DESCR:"LP Fuel Gas Distribution Topsides",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-06-02",A_OV_TOT:104,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:104,A_OV_PROG:0,B_OV_TOT:21,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:21,B_OV_PROG:0,ACT_TAG_VALID:95,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8130-8131-04",SUBSYSTEM_DESCR:"Fuel Gas Distribution to GTGs",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-06-02",A_OV_TOT:41,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:41,A_OV_PROG:0,B_OV_TOT:9,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:9,B_OV_PROG:0,ACT_TAG_VALID:36,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8150-8150-01",SUBSYSTEM_DESCR:"Nitrogen Generator and Receiver",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-04-07",A_OV_TOT:55,A_OV_COM:26,A_OV_COM_LW:2,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:29,A_OV_PROG:47.27,B_OV_TOT:30,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:30,B_OV_PROG:0,PA_OV_TOT:3,PA_OV_REM:1,PB1_OV_TOT:1,PB1_OV_REM:0,PREVENT_DC_REM:30,ACT_TAG_VALID:71,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8150-8150-02",SUBSYSTEM_DESCR:"Topside Nitrogen Distribution",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-19",A_OV_TOT:57,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:57,A_OV_PROG:0,B_OV_TOT:13,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:13,B_OV_PROG:0,PA_OV_TOT:7,PA_OV_REM:7,PB2_OV_TOT:1,PB2_OV_REM:1,ACT_TAG_VALID:50,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-8160-8160-01",SUBSYSTEM_DESCR:"Inert Gas Generator",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-07-07",A_OV_TOT:174,A_OV_COM:76,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:98,A_OV_PROG:43.67,B_OV_TOT:71,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:71,B_OV_PROG:0,PA_OV_TOT:9,PA_OV_REM:8,PB1_OV_TOT:5,PB1_OV_REM:4,PB2_OV_TOT:4,PB2_OV_REM:4,PREVENT_DC_REM:75,ACT_TAG_VALID:227,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-8160-8160-03",SUBSYSTEM_DESCR:"IGG Header",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-06-30",A_OV_TOT:75,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:75,A_OV_PROG:0,B_OV_TOT:18,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:18,B_OV_PROG:0,PA_OV_TOT:6,PA_OV_REM:6,PB2_OV_TOT:1,PB2_OV_REM:1,ACT_TAG_VALID:62,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-8300-8310-01",SUBSYSTEM_DESCR:"Corrosion Inhibitor Tank and Pumps",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-04-14",A_OV_TOT:59,A_OV_COM:36,A_OV_COM_LW:4,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:23,A_OV_PROG:61.01,B_OV_TOT:24,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:24,B_OV_PROG:0,PA_OV_TOT:7,PA_OV_REM:2,PB1_OV_TOT:5,PB1_OV_REM:3,PB2_OV_TOT:1,PB2_OV_REM:1,PREVENT_DC_REM:27,ACT_TAG_VALID:79,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8300-8320-01",SUBSYSTEM_DESCR:"Scale Inhibitor Tank and Pumps Topsides",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-14",A_OV_TOT:38,A_OV_COM:31,A_OV_COM_LW:5,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:7,A_OV_PROG:81.57,B_OV_TOT:35,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:35,B_OV_PROG:0,ACT_TAG_VALID:71,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8320-02",SUBSYSTEM_DESCR:"Scale Inhibitor Distribution Subsea",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-06-09",A_OV_TOT:4,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:4,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8320-04",SUBSYSTEM_DESCR:"Scale Inhibitor Tank and Pumps Subsea",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-04-14",A_OV_TOT:37,A_OV_COM:24,A_OV_COM_LW:5,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:13,A_OV_PROG:64.86,B_OV_TOT:19,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:19,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:1,ACT_TAG_VALID:54,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8300-8330-01",SUBSYSTEM_DESCR:"Wax Inhibitor Loading Header",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-06-16",A_OV_TOT:20,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:20,A_OV_PROG:0,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0,ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8330-02",SUBSYSTEM_DESCR:"Wax Inhibitor Storage and Pumps",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B2",MC_PLAN:"2022-06-16",A_OV_TOT:45,A_OV_COM:4,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:2,A_OV_REM:41,A_OV_PROG:8.88,B_OV_TOT:18,B_OV_COM:2,B_OV_COM_CLIENT:0,B_OV_REM:16,B_OV_PROG:11.11,PB1_OV_TOT:8,PB1_OV_REM:0,PB2_OV_TOT:5,PB2_OV_REM:4,PREVENT_DC_REM:16,ACT_TAG_VALID:47,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8300-8331-01",SUBSYSTEM_DESCR:"Wax Inhibitor Filtration",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-06-09",A_OV_TOT:12,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:12,A_OV_PROG:0,B_OV_TOT:4,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:4,B_OV_PROG:0,ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8331-02",SUBSYSTEM_DESCR:"Wax Inhibitor Break Tank and Injection Pumps",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-05",A_OV_TOT:103,A_OV_COM:8,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:95,A_OV_PROG:7.76,B_OV_TOT:28,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:28,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:0,ACT_TAG_VALID:99,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8300-8332-01",SUBSYSTEM_DESCR:"Wax Inhibitor Injection Subsea Phase 1",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-19",A_OV_TOT:9,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:9,A_OV_PROG:0,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8340-01",SUBSYSTEM_DESCR:"Biocide Tank and Pumps",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-04-14",A_OV_TOT:45,A_OV_COM:31,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:2,A_OV_REM:14,A_OV_PROG:68.88,B_OV_TOT:22,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:22,B_OV_PROG:0,PA_OV_TOT:14,PA_OV_REM:4,PB1_OV_TOT:1,PB1_OV_REM:1,PREVENT_DC_REM:23,ACT_TAG_VALID:63,PUNCH_NUMBER_VALID:15,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8300-8340-02",SUBSYSTEM_DESCR:"Biocide Distribution HULL and Topsides",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-06-09",A_OV_TOT:42,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:42,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,ACT_TAG_VALID:23,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8350-01",SUBSYSTEM_DESCR:"Antifoam Tank and Pumps",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-14",A_OV_TOT:43,A_OV_COM:27,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:2,A_OV_REM:16,A_OV_PROG:62.79,B_OV_TOT:25,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:25,B_OV_PROG:0,ACT_TAG_VALID:64,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8360-01",SUBSYSTEM_DESCR:"Emulsion Breaker Tank and Pumps",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-14",A_OV_TOT:42,A_OV_COM:29,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:2,A_OV_REM:13,A_OV_PROG:69.04,B_OV_TOT:24,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:24,B_OV_PROG:0,ACT_TAG_VALID:64,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8370-01",SUBSYSTEM_DESCR:"MEG PH Stabilizer Tank and Pumps",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B2",MC_PLAN:"2022-04-14",A_OV_TOT:43,A_OV_COM:31,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:4,A_OV_REM:12,A_OV_PROG:72.09,B_OV_TOT:16,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:16,B_OV_PROG:0,PB2_OV_TOT:1,PB2_OV_REM:1,ACT_TAG_VALID:55,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8300-8375-01",SUBSYSTEM_DESCR:"MEG Antifoam Tank and Pumps",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Complete",PUNCH_STATUS:"N",MC_PLAN:"2022-04-14",A_OV_TOT:17,A_OV_COM:17,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:17,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:17,B_OV_PROG:0,ACT_TAG_VALID:34,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8380-01",SUBSYSTEM_DESCR:"Spare and Future Tanks",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Complete",PUNCH_STATUS:"N",MC_PLAN:"2022-06-09",A_OV_TOT:4,A_OV_COM:4,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8400-8410-01",SUBSYSTEM_DESCR:"O2 Scavanger Tank and Pumps",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-14",A_OV_TOT:30,A_OV_COM:23,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:2,A_OV_REM:7,A_OV_PROG:76.66,B_OV_TOT:22,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:22,B_OV_PROG:0,ACT_TAG_VALID:50,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8400-8410-02",SUBSYSTEM_DESCR:"O2 Scavanger Distribution HULL and Topsides",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-19",A_OV_TOT:11,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:11,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8600-8611-01",SUBSYSTEM_DESCR:"GTG 1 and WHRU1",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-03-31",A_OV_TOT:424,A_OV_COM:20,A_OV_COM_LW:3,A_OV_COM_TW:0,A_OV_COM_CLIENT:3,A_OV_REM:404,A_OV_PROG:4.71,B_OV_TOT:204,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:204,B_OV_PROG:0,PA_OV_TOT:5,PA_OV_REM:2,PB1_OV_TOT:12,PB1_OV_REM:10,PB2_OV_TOT:3,PB2_OV_REM:3,PREVENT_DC_REM:214,ACT_TAG_VALID:606,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-8600-8612-01",SUBSYSTEM_DESCR:"GTG 2 and WHRU2",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-03-31",A_OV_TOT:420,A_OV_COM:28,A_OV_COM_LW:6,A_OV_COM_TW:0,A_OV_COM_CLIENT:5,A_OV_REM:392,A_OV_PROG:6.66,B_OV_TOT:206,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:206,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:1,PB1_OV_TOT:2,PB1_OV_REM:0,PREVENT_DC_REM:206,ACT_TAG_VALID:603,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-8600-8613-01",SUBSYSTEM_DESCR:"GTG 3 and WHRU3",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-04-28",A_OV_TOT:422,A_OV_COM:29,A_OV_COM_LW:2,A_OV_COM_TW:0,A_OV_COM_CLIENT:6,A_OV_REM:393,A_OV_PROG:6.87,B_OV_TOT:205,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:205,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:1,PB1_OV_TOT:2,PB1_OV_REM:0,PB2_OV_TOT:2,PB2_OV_REM:2,PREVENT_DC_REM:205,ACT_TAG_VALID:604,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-8600-8614-01",SUBSYSTEM_DESCR:"GTG 4 and WHRU4",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-04-28",A_OV_TOT:418,A_OV_COM:26,A_OV_COM_LW:3,A_OV_COM_TW:0,A_OV_COM_CLIENT:6,A_OV_REM:392,A_OV_PROG:6.22,B_OV_TOT:205,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:205,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:1,PB1_OV_TOT:2,PB1_OV_REM:0,PREVENT_DC_REM:205,ACT_TAG_VALID:600,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-8700-8760-01",SUBSYSTEM_DESCR:"Topside Normal 690 V Swicthboard 21-ESB-876001",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-03-31",A_OV_TOT:80,A_OV_COM:70,A_OV_COM_LW:3,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:10,A_OV_PROG:87.5,B_OV_TOT:6,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:6,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:1,PB1_OV_TOT:14,PB1_OV_REM:6,PB2_OV_TOT:4,PB2_OV_REM:0,PREVENT_DC_REM:12,ACT_TAG_VALID:83,PUNCH_NUMBER_VALID:19,PUNCH_TAG_VALID:8},
    {SUBSYSTEM:"21-8700-8770-01",SUBSYSTEM_DESCR:"Topsides Main Normal HV Switchboard 6.6kV 21-ESB-877001",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-04-07",A_OV_TOT:79,A_OV_COM:45,A_OV_COM_LW:4,A_OV_COM_TW:0,A_OV_COM_CLIENT:24,A_OV_REM:34,A_OV_PROG:56.96,B_OV_TOT:4,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:4,B_OV_PROG:0,PA_OV_TOT:10,PA_OV_REM:4,PB1_OV_TOT:11,PB1_OV_REM:9,PREVENT_DC_REM:13,ACT_TAG_VALID:81,PUNCH_NUMBER_VALID:21,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-8800-8800-01",SUBSYSTEM_DESCR:"Ses Water Lift Pump A",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-06-09",A_OV_TOT:29,A_OV_COM:4,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:25,A_OV_PROG:13.79,B_OV_TOT:8,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:8,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:1,ACT_TAG_VALID:32,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-8800-8800-02",SUBSYSTEM_DESCR:"Ses Water Lift Pump B",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-06-09",A_OV_TOT:29,A_OV_COM:4,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:25,A_OV_PROG:13.79,B_OV_TOT:8,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:8,B_OV_PROG:0,ACT_TAG_VALID:32,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8800-8800-03",SUBSYSTEM_DESCR:"Ses Water Lift Pump C",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-06-09",A_OV_TOT:29,A_OV_COM:4,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:25,A_OV_PROG:13.79,B_OV_TOT:8,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:8,B_OV_PROG:0,ACT_TAG_VALID:32,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8800-8800-04",SUBSYSTEM_DESCR:"Sea Water Filter and Distribution Header",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-06-02",A_OV_TOT:59,A_OV_COM:9,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:50,A_OV_PROG:15.25,B_OV_TOT:17,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:17,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:1,PB1_OV_TOT:1,PB1_OV_REM:1,PREVENT_DC_REM:18,ACT_TAG_VALID:60,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8800-8800-05",SUBSYSTEM_DESCR:"Sea Water Cooling Supply to Exchangers and Discharge Header",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-05-12",A_OV_TOT:23,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:23,A_OV_PROG:0,B_OV_TOT:6,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:6,B_OV_PROG:0,PB1_OV_TOT:1,PB1_OV_REM:1,PREVENT_DC_REM:7,ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8800-8805-01",SUBSYSTEM_DESCR:"Electrochlorination Unit",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-12",A_OV_TOT:159,A_OV_COM:86,A_OV_COM_LW:15,A_OV_COM_TW:0,A_OV_COM_CLIENT:24,A_OV_REM:73,A_OV_PROG:54.08,B_OV_TOT:60,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:60,B_OV_PROG:0,PA_OV_TOT:7,PA_OV_REM:3,PB1_OV_TOT:10,PB1_OV_REM:6,PB2_OV_TOT:4,PB2_OV_REM:4,PREVENT_DC_REM:66,ACT_TAG_VALID:203,PUNCH_NUMBER_VALID:21,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-8800-8805-02",SUBSYSTEM_DESCR:"Hypochlorite Distribution Topsides",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-05-12",A_OV_TOT:50,A_OV_COM:12,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:2,A_OV_REM:38,A_OV_PROG:24,B_OV_TOT:12,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:12,B_OV_PROG:0,PB1_OV_TOT:16,PB1_OV_REM:3,PB2_OV_TOT:2,PB2_OV_REM:2,PREVENT_DC_REM:15,ACT_TAG_VALID:49,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:7},
    {SUBSYSTEM:"21-9220-9224-01",SUBSYSTEM_DESCR:"Entertainment System",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-05-19",A_OV_TOT:300,A_OV_COM:201,A_OV_COM_LW:166,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:99,A_OV_PROG:67,B_OV_TOT:4,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:4,B_OV_PROG:0,PB1_OV_TOT:5,PB1_OV_REM:2,PB2_OV_TOT:5,PB2_OV_REM:5,PREVENT_DC_REM:6,ACT_TAG_VALID:301,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-9330-9330-01",SUBSYSTEM_DESCR:"Main Ballast Tanks WBE1 to 5, APE 1, APW 1 and WBW 1 to 5",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-12",A_OV_TOT:44,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:44,A_OV_PROG:0,B_OV_TOT:16,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:16,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:1,ACT_TAG_VALID:44,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-9330-9330-02",SUBSYSTEM_DESCR:"Main Ballast Ring Main",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-28",A_OV_TOT:170,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:170,A_OV_PROG:0,B_OV_TOT:35,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:35,B_OV_PROG:0,ACT_TAG_VALID:170,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9330-9330-03",SUBSYSTEM_DESCR:"Ballast Pump 21-P-935001 A",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-04-28",A_OV_TOT:62,A_OV_COM:3,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:59,A_OV_PROG:4.83,B_OV_TOT:16,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:16,B_OV_PROG:0,PB1_OV_TOT:1,PB1_OV_REM:1,PREVENT_DC_REM:17,ACT_TAG_VALID:64,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-9330-9330-04",SUBSYSTEM_DESCR:"Ballast Pump 21-P-935001 B",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-28",A_OV_TOT:59,A_OV_COM:4,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:55,A_OV_PROG:6.77,B_OV_TOT:15,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:15,B_OV_PROG:0,ACT_TAG_VALID:61,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9330-9330-05",SUBSYSTEM_DESCR:"Ballast Pump 21-P-935001 C",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-28",A_OV_TOT:58,A_OV_COM:4,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:54,A_OV_PROG:6.89,B_OV_TOT:15,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:15,B_OV_PROG:0,ACT_TAG_VALID:60,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9330-9330-06",SUBSYSTEM_DESCR:"Ballast Pump 21-P-935001 D",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-28",A_OV_TOT:59,A_OV_COM:3,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:56,A_OV_PROG:5.08,B_OV_TOT:15,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:15,B_OV_PROG:0,ACT_TAG_VALID:61,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9330-9333-01",SUBSYSTEM_DESCR:"Cargo and Ballast Pumps HPU and UCP",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-03-17",A_OV_TOT:194,A_OV_COM:183,A_OV_COM_LW:5,A_OV_COM_TW:0,A_OV_COM_CLIENT:71,A_OV_REM:11,A_OV_PROG:94.32,B_OV_TOT:173,B_OV_COM:6,B_OV_COM_CLIENT:0,B_OV_REM:167,B_OV_PROG:3.46,PA_OV_TOT:46,PA_OV_REM:6,PB1_OV_TOT:91,PB1_OV_REM:18,PB2_OV_TOT:31,PB2_OV_REM:29,PREVENT_DC_REM:185,ACT_TAG_VALID:353,PUNCH_NUMBER_VALID:168,PUNCH_TAG_VALID:60},
    {SUBSYSTEM:"21-9330-9334-01",SUBSYSTEM_DESCR:"HULL Valves HPU",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Partial MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-03",A_OV_TOT:78,A_OV_COM:28,A_OV_COM_LW:8,A_OV_COM_TW:0,A_OV_COM_CLIENT:5,A_OV_REM:50,A_OV_PROG:35.89,B_OV_TOT:33,B_OV_COM:32,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:96.96,PA_OV_TOT:39,PA_OV_REM:0,PB1_OV_TOT:39,PB1_OV_REM:1,PB2_OV_TOT:17,PB2_OV_REM:3,PREVENT_DC_REM:2,ACT_TAG_VALID:109,PUNCH_NUMBER_VALID:95,PUNCH_TAG_VALID:20},
    {SUBSYSTEM:"21-9340-9341-01",SUBSYSTEM_DESCR:"Forward Mooring System and HPU",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-06-09",A_OV_TOT:139,A_OV_COM:90,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:49,A_OV_PROG:64.74,B_OV_TOT:180,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:180,B_OV_PROG:0,PA_OV_TOT:3,PA_OV_REM:2,PB1_OV_TOT:5,PB1_OV_REM:0,PB2_OV_TOT:6,PB2_OV_REM:6,PREVENT_DC_REM:180,ACT_TAG_VALID:315,PUNCH_NUMBER_VALID:14,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-9340-9341-02",SUBSYSTEM_DESCR:"Forward Mooring Fairleads, Sheaves and Accessories Mounted on HULL",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-12",A_OV_TOT:49,A_OV_COM:33,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:16,A_OV_PROG:67.34,B_OV_TOT:24,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:24,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:0,PB1_OV_TOT:1,PB1_OV_REM:0,PREVENT_DC_REM:24,ACT_TAG_VALID:72,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-9340-9343-01",SUBSYSTEM_DESCR:"Aft Mooring System and HPU",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B2",MC_PLAN:"2022-06-09",A_OV_TOT:143,A_OV_COM:64,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:79,A_OV_PROG:44.75,B_OV_TOT:180,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:180,B_OV_PROG:0,PB1_OV_TOT:4,PB1_OV_REM:0,PB2_OV_TOT:4,PB2_OV_REM:3,PREVENT_DC_REM:180,ACT_TAG_VALID:319,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-9340-9343-02",SUBSYSTEM_DESCR:"Aft Mooring Fairleads, Sheaves and Accessories Mounted on HULL",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-12",A_OV_TOT:44,A_OV_COM:33,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:11,A_OV_PROG:75,B_OV_TOT:24,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:24,B_OV_PROG:0,PA_OV_TOT:7,PA_OV_REM:2,PB1_OV_TOT:2,PB1_OV_REM:1,PREVENT_DC_REM:25,ACT_TAG_VALID:67,PUNCH_NUMBER_VALID:9,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-9350-9350-01",SUBSYSTEM_DESCR:"MS Ballast Tanks WBW6,7 and WBE6,7 and FPE1 andFPW1",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Partial MC Complete",PUNCH_STATUS:"B2",MC_PLAN:"2022-03-03",A_OV_TOT:24,A_OV_COM:6,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:2,A_OV_REM:18,A_OV_PROG:25,B_OV_TOT:8,B_OV_COM:2,B_OV_COM_CLIENT:0,B_OV_REM:6,B_OV_PROG:25,PA_OV_TOT:8,PA_OV_REM:0,PB1_OV_TOT:9,PB1_OV_REM:0,PB2_OV_TOT:8,PB2_OV_REM:4,PREVENT_DC_REM:6,ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:25,PUNCH_TAG_VALID:7},
    {SUBSYSTEM:"21-9350-9350-02",SUBSYSTEM_DESCR:"MS Ballast Ring Main",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B2",MC_PLAN:"2022-03-03",A_OV_TOT:39,A_OV_COM:37,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:11,A_OV_REM:2,A_OV_PROG:94.87,B_OV_TOT:13,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:13,B_OV_PROG:0,PA_OV_TOT:3,PA_OV_REM:0,PB1_OV_TOT:38,PB1_OV_REM:0,PB2_OV_TOT:23,PB2_OV_REM:15,PB3_OV_TOT:1,PB3_OV_REM:0,PREVENT_DC_REM:13,RFDC_REM:15,ACT_TAG_VALID:39,PUNCH_NUMBER_VALID:65,PUNCH_TAG_VALID:15},
    {SUBSYSTEM:"21-9350-9350-04",SUBSYSTEM_DESCR:"MS Ballast Pump 21-P-935002A",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Partial MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-03",A_OV_TOT:53,A_OV_COM:50,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:13,A_OV_REM:3,A_OV_PROG:94.33,B_OV_TOT:17,B_OV_COM:5,B_OV_COM_CLIENT:0,B_OV_REM:12,B_OV_PROG:29.41,PA_OV_TOT:11,PA_OV_REM:0,PB1_OV_TOT:43,PB1_OV_REM:4,PB2_OV_TOT:32,PB2_OV_REM:23,PREVENT_DC_REM:16,ACT_TAG_VALID:59,PUNCH_NUMBER_VALID:86,PUNCH_TAG_VALID:18},
    {SUBSYSTEM:"21-9350-9350-05",SUBSYSTEM_DESCR:"MS Ballast Pump 21-P-935002B",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Partial MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-10",A_OV_TOT:43,A_OV_COM:40,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:11,A_OV_REM:3,A_OV_PROG:93.02,B_OV_TOT:16,B_OV_COM:5,B_OV_COM_CLIENT:0,B_OV_REM:11,B_OV_PROG:31.25,PA_OV_TOT:8,PA_OV_REM:0,PB1_OV_TOT:39,PB1_OV_REM:4,PB2_OV_TOT:27,PB2_OV_REM:21,PREVENT_DC_REM:15,ACT_TAG_VALID:50,PUNCH_NUMBER_VALID:74,PUNCH_TAG_VALID:18},
    {SUBSYSTEM:"21-9370-9370-01",SUBSYSTEM_DESCR:"Slops Tank 21-T-937001 and Pumps 21-P-937001and3",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-12",A_OV_TOT:134,A_OV_COM:9,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:6,A_OV_REM:125,A_OV_PROG:6.71,B_OV_TOT:29,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:29,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:1,ACT_TAG_VALID:129,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9370-9371-01",SUBSYSTEM_DESCR:"Slops Tank 21-T-937002 and Pumps 21-P-937002and4",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-28",A_OV_TOT:137,A_OV_COM:12,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:10,A_OV_REM:125,A_OV_PROG:8.75,B_OV_TOT:29,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:29,B_OV_PROG:0,ACT_TAG_VALID:133,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9370-9372-04",SUBSYSTEM_DESCR:"Slops Heaters 21-H-937001 AandB",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-05",A_OV_TOT:34,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:34,A_OV_PROG:0,B_OV_TOT:8,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:8,B_OV_PROG:0,ACT_TAG_VALID:26,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9370-9372-07",SUBSYSTEM_DESCR:"Slops Water to Export Line Flushing",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-05",A_OV_TOT:92,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:92,A_OV_PROG:0,B_OV_TOT:14,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:14,B_OV_PROG:0,ACT_TAG_VALID:80,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9370-9372-08",SUBSYSTEM_DESCR:"COW Hot Sea Water Filter 21-F-937001",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-05",A_OV_TOT:4,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:4,A_OV_PROG:0,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9370-9372-09",SUBSYSTEM_DESCR:"Slops Polishing and ODMS ,Oil Discharge Monitoring Units",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-04-28",A_OV_TOT:99,A_OV_COM:2,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:97,A_OV_PROG:2.02,B_OV_TOT:44,B_OV_COM:3,B_OV_COM_CLIENT:0,B_OV_REM:41,B_OV_PROG:6.81,PA_OV_TOT:17,PA_OV_REM:5,PB1_OV_TOT:24,PB1_OV_REM:10,PREVENT_DC_REM:51,ACT_TAG_VALID:133,PUNCH_NUMBER_VALID:41,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-9370-9372-10",SUBSYSTEM_DESCR:"Treated Slops Header to Overboard",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-04-28",A_OV_TOT:13,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:13,A_OV_PROG:0,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0,PB1_OV_TOT:1,PB1_OV_REM:1,PREVENT_DC_REM:4,ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9380-9380-01",SUBSYSTEM_DESCR:"Daily bilge Tank 21-T-938001 and Pumps 21-P-938001AandB",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-17",A_OV_TOT:21,A_OV_COM:15,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:7,A_OV_REM:6,A_OV_PROG:71.42,B_OV_TOT:12,B_OV_COM:6,B_OV_COM_CLIENT:0,B_OV_REM:6,B_OV_PROG:50,PA_OV_TOT:10,PA_OV_REM:0,PB1_OV_TOT:20,PB1_OV_REM:11,PB2_OV_TOT:13,PB2_OV_REM:11,PREVENT_DC_REM:17,ACT_TAG_VALID:29,PUNCH_NUMBER_VALID:43,PUNCH_TAG_VALID:15},
    {SUBSYSTEM:"21-9380-9380-02",SUBSYSTEM_DESCR:"Daily Bilge Wells and Headers to Daily Bilge Tank",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Complete",PUNCH_STATUS:"A",MC_PLAN:"2022-03-17",A_OV_TOT:42,A_OV_COM:42,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:8,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:17,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:17,B_OV_PROG:0,PA_OV_TOT:5,PA_OV_REM:3,PB1_OV_TOT:9,PB1_OV_REM:4,PB2_OV_TOT:20,PB2_OV_REM:20,PREVENT_DC_REM:21,ACT_TAG_VALID:51,PUNCH_NUMBER_VALID:34,PUNCH_TAG_VALID:9},
    {SUBSYSTEM:"21-9380-9380-03",SUBSYSTEM_DESCR:"Bilge Water from MS Bilge Collection Header",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Complete",PUNCH_STATUS:"N",MC_PLAN:"2022-03-17",A_OV_TOT:4,A_OV_COM:4,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:0,PB1_OV_TOT:3,PB1_OV_REM:0,PREVENT_DC_REM:2,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-9380-9380-06",SUBSYSTEM_DESCR:"Daily Bilge for CFC8 and Pump 21-P-938003",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-04-28",A_OV_TOT:20,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:20,A_OV_PROG:0,B_OV_TOT:4,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:4,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:2,ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9380-9380-07",SUBSYSTEM_DESCR:"Daily Bilge for CFC5 and CFC6 and Pump 21-P-938004",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-19",A_OV_TOT:40,A_OV_COM:2,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:38,A_OV_PROG:5,B_OV_TOT:8,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:8,B_OV_PROG:0,ACT_TAG_VALID:36,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9380-9380-08",SUBSYSTEM_DESCR:"Daily Bilge for CFC7 and Pump 21-P-938005",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-19",A_OV_TOT:20,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:20,A_OV_PROG:0,B_OV_TOT:4,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:4,B_OV_PROG:0,ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9380-9380-09",SUBSYSTEM_DESCR:"Daily Bilge for CFC1 and Pump 21-P-938006",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-19",A_OV_TOT:11,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:11,A_OV_PROG:0,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:0,ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9380-9381-01",SUBSYSTEM_DESCR:"Main bilge Pumps 21-P-938002AandB and Headers",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-19",A_OV_TOT:27,A_OV_COM:18,A_OV_COM_LW:4,A_OV_COM_TW:0,A_OV_COM_CLIENT:5,A_OV_REM:9,A_OV_PROG:66.66,B_OV_TOT:13,B_OV_COM:2,B_OV_COM_CLIENT:0,B_OV_REM:11,B_OV_PROG:15.38,PA_OV_TOT:9,PA_OV_REM:2,PB1_OV_TOT:12,PB1_OV_REM:3,PB2_OV_TOT:4,PB2_OV_REM:4,PREVENT_DC_REM:14,ACT_TAG_VALID:35,PUNCH_NUMBER_VALID:25,PUNCH_TAG_VALID:14},
    {SUBSYSTEM:"21-9380-9382-01",SUBSYSTEM_DESCR:"Emergency Bilge well ,SB middle and header",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-05-19",A_OV_TOT:3,A_OV_COM:3,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:0,PB1_OV_TOT:7,PB1_OV_REM:3,PB2_OV_TOT:4,PB2_OV_REM:4,PREVENT_DC_REM:4,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-9390-9390-01",SUBSYSTEM_DESCR:"Bunker Station and HPU",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-06-09",A_OV_TOT:23,A_OV_COM:9,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:14,A_OV_PROG:39.13,B_OV_TOT:12,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:12,B_OV_PROG:0,PA_OV_TOT:4,PA_OV_REM:3,PB1_OV_TOT:1,PB1_OV_REM:0,PREVENT_DC_REM:12,ACT_TAG_VALID:31,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-9500-9514-01",SUBSYSTEM_DESCR:"Telephone System - LQ",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-04-28",A_OV_TOT:30,A_OV_COM:20,A_OV_COM_LW:4,A_OV_COM_TW:0,A_OV_COM_CLIENT:2,A_OV_REM:10,A_OV_PROG:66.66,B_OV_TOT:5,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:5,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:0,PB1_OV_TOT:9,PB1_OV_REM:1,PB2_OV_TOT:3,PB2_OV_REM:2,PREVENT_DC_REM:6,ACT_TAG_VALID:31,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:8},
    {SUBSYSTEM:"21-9500-9515-01",SUBSYSTEM_DESCR:"Hot line Telephones",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"B2",MC_PLAN:"2022-06-23",A_OV_TOT:6,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:6,A_OV_PROG:0,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,PB1_OV_TOT:4,PB1_OV_REM:0,PB2_OV_TOT:1,PB2_OV_REM:1,PREVENT_DC_REM:2,ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-9500-9525-01",SUBSYSTEM_DESCR:"Microwave Line of Sight",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-12",A_OV_TOT:13,A_OV_COM:8,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:5,A_OV_PROG:61.53,B_OV_TOT:4,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:4,B_OV_PROG:0,PB1_OV_TOT:3,PB1_OV_REM:0,PREVENT_DC_REM:4,ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-9500-9535-01",SUBSYSTEM_DESCR:"FPSO Fibre Optic Cable",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-17",A_OV_TOT:6,A_OV_COM:6,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:2,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,PA_OV_TOT:6,PA_OV_REM:0,PB1_OV_TOT:5,PB1_OV_REM:3,PB2_OV_TOT:9,PB2_OV_REM:6,PREVENT_DC_REM:5,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-9500-9536-01",SUBSYSTEM_DESCR:"LANandWAN ,Data Network Infrastructure",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-03-31",A_OV_TOT:240,A_OV_COM:127,A_OV_COM_LW:20,A_OV_COM_TW:0,A_OV_COM_CLIENT:7,A_OV_REM:113,A_OV_PROG:52.91,B_OV_TOT:44,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:44,B_OV_PROG:0,PA_OV_TOT:22,PA_OV_REM:2,PB1_OV_TOT:23,PB1_OV_REM:2,PB2_OV_TOT:41,PB2_OV_REM:25,PREVENT_DC_REM:46,ACT_TAG_VALID:242,PUNCH_NUMBER_VALID:86,PUNCH_TAG_VALID:25},
    {SUBSYSTEM:"21-9500-9539-01",SUBSYSTEM_DESCR:"Video Conferencing and Collaboration System",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-06-02",A_OV_TOT:38,A_OV_COM:1,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:37,A_OV_PROG:2.63,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,ACT_TAG_VALID:39,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9500-9544-01",SUBSYSTEM_DESCR:"Marine and Security CCTV - LQ",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-05-05",A_OV_TOT:114,A_OV_COM:45,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:2,A_OV_REM:69,A_OV_PROG:39.47,B_OV_TOT:10,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:10,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:0,PB1_OV_TOT:11,PB1_OV_REM:1,PB2_OV_TOT:7,PB2_OV_REM:7,PREVENT_DC_REM:11,ACT_TAG_VALID:104,PUNCH_NUMBER_VALID:19,PUNCH_TAG_VALID:12},
    {SUBSYSTEM:"21-9500-9554-01",SUBSYSTEM_DESCR:"Meterological METOCEAN System",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B2",MC_PLAN:"2022-05-12",A_OV_TOT:48,A_OV_COM:5,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:43,A_OV_PROG:10.41,B_OV_TOT:7,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:7,B_OV_PROG:0,PB1_OV_TOT:2,PB1_OV_REM:0,PB2_OV_TOT:3,PB2_OV_REM:3,PREVENT_DC_REM:7,ACT_TAG_VALID:42,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9500-9555-01",SUBSYSTEM_DESCR:"Berthing Aids and Approach System",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-05-19",A_OV_TOT:40,A_OV_COM:6,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:34,A_OV_PROG:15,B_OV_TOT:5,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:5,B_OV_PROG:0,PB1_OV_TOT:3,PB1_OV_REM:1,PB2_OV_TOT:3,PB2_OV_REM:3,PREVENT_DC_REM:6,ACT_TAG_VALID:33,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9500-9557-01",SUBSYSTEM_DESCR:"Global Maritime Distress and Safety System",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-06-09",A_OV_TOT:16,A_OV_COM:12,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:4,A_OV_PROG:75,B_OV_TOT:4,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:4,B_OV_PROG:0,PB1_OV_TOT:3,PB1_OV_REM:0,PREVENT_DC_REM:4,ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-9500-9564-01",SUBSYSTEM_DESCR:"Access Control System and Emergency Mustering System",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-05",A_OV_TOT:391,A_OV_COM:202,A_OV_COM_LW:42,A_OV_COM_TW:0,A_OV_COM_CLIENT:8,A_OV_REM:189,A_OV_PROG:51.66,B_OV_TOT:13,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:13,B_OV_PROG:0,PA_OV_TOT:29,PA_OV_REM:1,PB1_OV_TOT:32,PB1_OV_REM:6,PB2_OV_TOT:20,PB2_OV_REM:19,PREVENT_DC_REM:19,ACT_TAG_VALID:394,PUNCH_NUMBER_VALID:81,PUNCH_TAG_VALID:45},
    {SUBSYSTEM:"21-9500-9565-01",SUBSYSTEM_DESCR:"Ship Security Alert System ,SSAS",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-06-23",A_OV_TOT:10,A_OV_COM:8,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:2,A_OV_PROG:80,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:0,PB1_OV_TOT:1,PB1_OV_REM:0,PREVENT_DC_REM:3,ACT_TAG_VALID:13,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9500-9570-01",SUBSYSTEM_DESCR:"Gyrocompass",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-04-14",A_OV_TOT:13,A_OV_COM:8,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:5,A_OV_PROG:61.53,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0,PA_OV_TOT:10,PA_OV_REM:8,PB1_OV_TOT:1,PB1_OV_REM:0,PB2_OV_TOT:8,PB2_OV_REM:8,PREVENT_DC_REM:3,ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:19,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-9500-9570-03",SUBSYSTEM_DESCR:"Hand held satellite phone",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Complete",PUNCH_STATUS:"N",MC_PLAN:"2022-06-23",A_OV_TOT:1,A_OV_COM:1,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9500-9570-04",SUBSYSTEM_DESCR:"Video Wall System",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"B2",MC_PLAN:"2022-04-14",A_OV_TOT:23,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:23,A_OV_PROG:0,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,PB2_OV_TOT:1,PB2_OV_REM:1,ACT_TAG_VALID:24,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9500-9574-01",SUBSYSTEM_DESCR:"Network Management System",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B2",MC_PLAN:"2022-03-17",A_OV_TOT:11,A_OV_COM:11,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:2,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:30,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:30,B_OV_PROG:0,PA_OV_TOT:15,PA_OV_REM:0,PB1_OV_TOT:2,PB1_OV_REM:0,PB2_OV_TOT:18,PB2_OV_REM:12,PREVENT_DC_REM:30,ACT_TAG_VALID:35,PUNCH_NUMBER_VALID:35,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-9500-9575-01",SUBSYSTEM_DESCR:"Digital Voice Recorder",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-04-14",A_OV_TOT:13,A_OV_COM:13,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0,PA_OV_TOT:6,PA_OV_REM:0,PB1_OV_TOT:4,PB1_OV_REM:1,PB2_OV_TOT:12,PB2_OV_REM:6,PREVENT_DC_REM:4,ACT_TAG_VALID:13,PUNCH_NUMBER_VALID:22,PUNCH_TAG_VALID:8},
    {SUBSYSTEM:"21-9810-9811-01",SUBSYSTEM_DESCR:"FPSO West Crane",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-06-09",A_OV_TOT:224,A_OV_COM:171,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:139,A_OV_REM:53,A_OV_PROG:76.33,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,PA_OV_TOT:31,PA_OV_REM:29,PB1_OV_TOT:25,PB1_OV_REM:12,PB2_OV_TOT:5,PB2_OV_REM:5,PREVENT_DC_REM:14,ACT_TAG_VALID:222,PUNCH_NUMBER_VALID:61,PUNCH_TAG_VALID:30},
    {SUBSYSTEM:"21-9810-9812-01",SUBSYSTEM_DESCR:"FPSO East Crane",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-06-09",A_OV_TOT:222,A_OV_COM:138,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:136,A_OV_REM:84,A_OV_PROG:62.16,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,PA_OV_TOT:25,PA_OV_REM:25,PB1_OV_TOT:17,PB1_OV_REM:8,PB2_OV_TOT:1,PB2_OV_REM:1,PREVENT_DC_REM:10,ACT_TAG_VALID:220,PUNCH_NUMBER_VALID:43,PUNCH_TAG_VALID:31},
    {SUBSYSTEM:"21-9820-9822-01",SUBSYSTEM_DESCR:"LQ Personnel Lift",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-05-19",A_OV_TOT:73,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:73,A_OV_PROG:0,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:0,PB1_OV_TOT:1,PB1_OV_REM:1,PREVENT_DC_REM:3,ACT_TAG_VALID:74,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9820-9823-01",SUBSYSTEM_DESCR:"LQ Goods and Material Lift",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-06-02",A_OV_TOT:76,A_OV_COM:2,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:74,A_OV_PROG:2.63,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,PA_OV_TOT:4,PA_OV_REM:0,ACT_TAG_VALID:77,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9820-9824-01",SUBSYSTEM_DESCR:"Riser and Umbilical Pull in Winch",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-26",A_OV_TOT:66,A_OV_COM:56,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:10,A_OV_PROG:84.84,B_OV_TOT:68,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:68,B_OV_PROG:0,PA_OV_TOT:5,PA_OV_REM:4,PB2_OV_TOT:1,PB2_OV_REM:1,ACT_TAG_VALID:128,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-9840-9844-01",SUBSYSTEM_DESCR:"Galley",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-06-02",A_OV_TOT:55,A_OV_COM:23,A_OV_COM_LW:2,A_OV_COM_TW:0,A_OV_COM_CLIENT:2,A_OV_REM:32,A_OV_PROG:41.81,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0,PA_OV_TOT:11,PA_OV_REM:0,PB1_OV_TOT:2,PB1_OV_REM:0,PREVENT_DC_REM:3,ACT_TAG_VALID:58,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-9840-9846-01",SUBSYSTEM_DESCR:"Hospital and Galley Call Alarm System",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B2",MC_PLAN:"2022-05-26",A_OV_TOT:32,A_OV_COM:13,A_OV_COM_LW:6,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:19,A_OV_PROG:40.62,B_OV_TOT:5,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:5,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:0,PB2_OV_TOT:1,PB2_OV_REM:1,ACT_TAG_VALID:36,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9921-9921-01",SUBSYSTEM_DESCR:"LQ Emergency Pressurization Fans",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-05-19",A_OV_TOT:87,A_OV_COM:67,A_OV_COM_LW:11,A_OV_COM_TW:0,A_OV_COM_CLIENT:17,A_OV_REM:20,A_OV_PROG:77.01,B_OV_TOT:19,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:19,B_OV_PROG:0,PA_OV_TOT:5,PA_OV_REM:0,PB1_OV_TOT:13,PB1_OV_REM:2,PB2_OV_TOT:10,PB2_OV_REM:9,PREVENT_DC_REM:21,ACT_TAG_VALID:84,PUNCH_NUMBER_VALID:28,PUNCH_TAG_VALID:10},
    {SUBSYSTEM:"21-9921-9921-02",SUBSYSTEM_DESCR:"LQ Battery Exhaust Fans, Ducting and Dampers",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-03-24",A_OV_TOT:82,A_OV_COM:79,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:37,A_OV_REM:3,A_OV_PROG:96.34,B_OV_TOT:11,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:11,B_OV_PROG:0,PA_OV_TOT:17,PA_OV_REM:2,PB1_OV_TOT:34,PB1_OV_REM:3,PB2_OV_TOT:21,PB2_OV_REM:15,PREVENT_DC_REM:14,ACT_TAG_VALID:76,PUNCH_NUMBER_VALID:72,PUNCH_TAG_VALID:34},
    {SUBSYSTEM:"21-9921-9921-03",SUBSYSTEM_DESCR:"LQ Extracts, Ducting and Damper",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-03-24",A_OV_TOT:232,A_OV_COM:170,A_OV_COM_LW:45,A_OV_COM_TW:0,A_OV_COM_CLIENT:47,A_OV_REM:62,A_OV_PROG:73.27,B_OV_TOT:24,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:24,B_OV_PROG:0,PA_OV_TOT:5,PA_OV_REM:1,PB1_OV_TOT:32,PB1_OV_REM:1,PB2_OV_TOT:8,PB2_OV_REM:7,PREVENT_DC_REM:25,ACT_TAG_VALID:200,PUNCH_NUMBER_VALID:45,PUNCH_TAG_VALID:25},
    {SUBSYSTEM:"21-9921-9921-04",SUBSYSTEM_DESCR:"LQ Emergency SWB Room ACUsandACCUs and UCPs",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-24",A_OV_TOT:25,A_OV_COM:25,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:7,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:35,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:35,B_OV_PROG:0,PA_OV_TOT:39,PA_OV_REM:0,PB1_OV_TOT:49,PB1_OV_REM:13,PB2_OV_TOT:13,PB2_OV_REM:10,PREVENT_DC_REM:48,ACT_TAG_VALID:50,PUNCH_NUMBER_VALID:101,PUNCH_TAG_VALID:25},
    {SUBSYSTEM:"21-9921-9921-05",SUBSYSTEM_DESCR:"LQ CCR ACUsandACCUs and UCPs",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-10",A_OV_TOT:30,A_OV_COM:30,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:9,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:51,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:51,B_OV_PROG:0,PA_OV_TOT:13,PA_OV_REM:0,PB1_OV_TOT:19,PB1_OV_REM:8,PB2_OV_TOT:12,PB2_OV_REM:6,PREVENT_DC_REM:59,ACT_TAG_VALID:70,PUNCH_NUMBER_VALID:44,PUNCH_TAG_VALID:16},
    {SUBSYSTEM:"21-9921-9921-06",SUBSYSTEM_DESCR:"LQ CER ACUsandACCUs and UCPs",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-24",A_OV_TOT:34,A_OV_COM:34,A_OV_COM_LW:2,A_OV_COM_TW:0,A_OV_COM_CLIENT:12,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:44,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:44,B_OV_PROG:0,PA_OV_TOT:23,PA_OV_REM:0,PB1_OV_TOT:57,PB1_OV_REM:12,PB2_OV_TOT:16,PB2_OV_REM:7,PREVENT_DC_REM:56,ACT_TAG_VALID:66,PUNCH_NUMBER_VALID:96,PUNCH_TAG_VALID:23},
    {SUBSYSTEM:"21-9921-9921-07",SUBSYSTEM_DESCR:"LQ Secondary ICC ACUsandACCUs and UCPs",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-05-19",A_OV_TOT:38,A_OV_COM:38,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:10,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:25,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:25,B_OV_PROG:0,PA_OV_TOT:10,PA_OV_REM:0,PB1_OV_TOT:18,PB1_OV_REM:3,PB2_OV_TOT:23,PB2_OV_REM:17,PREVENT_DC_REM:28,ACT_TAG_VALID:56,PUNCH_NUMBER_VALID:51,PUNCH_TAG_VALID:15},
    {SUBSYSTEM:"21-9921-9921-08",SUBSYSTEM_DESCR:"LQ Dining Room and Secondary Muster ACUsandACCUs and UCPs",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-10",A_OV_TOT:27,A_OV_COM:27,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:7,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:42,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:42,B_OV_PROG:0,PA_OV_TOT:18,PA_OV_REM:0,PB1_OV_TOT:43,PB1_OV_REM:10,PB2_OV_TOT:8,PB2_OV_REM:4,PREVENT_DC_REM:52,ACT_TAG_VALID:57,PUNCH_NUMBER_VALID:69,PUNCH_TAG_VALID:21},
    {SUBSYSTEM:"21-9921-9921-09",SUBSYSTEM_DESCR:"LQ Telecom Room ACUsandACCUs and UCPs",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Complete",PUNCH_STATUS:"B2",MC_PLAN:"2022-03-17",A_OV_TOT:14,A_OV_COM:14,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:4,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:22,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:22,B_OV_PROG:0,PA_OV_TOT:11,PA_OV_REM:0,PB1_OV_TOT:4,PB1_OV_REM:0,PB2_OV_TOT:22,PB2_OV_REM:21,PREVENT_DC_REM:22,ACT_TAG_VALID:29,PUNCH_NUMBER_VALID:37,PUNCH_TAG_VALID:10},
    {SUBSYSTEM:"21-9921-9922-01",SUBSYSTEM_DESCR:"LQ HVAC UCPs, EDBs",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-10",A_OV_TOT:55,A_OV_COM:55,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:22,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:74,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:74,B_OV_PROG:0,PA_OV_TOT:16,PA_OV_REM:0,PB1_OV_TOT:43,PB1_OV_REM:4,PB2_OV_TOT:11,PB2_OV_REM:8,PREVENT_DC_REM:78,ACT_TAG_VALID:125,PUNCH_NUMBER_VALID:70,PUNCH_TAG_VALID:11},
    {SUBSYSTEM:"21-9921-9922-02",SUBSYSTEM_DESCR:"LQ HVAC AHUs, Ducting and Dampers",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-04-28",A_OV_TOT:1523,A_OV_COM:1361,A_OV_COM_LW:94,A_OV_COM_TW:0,A_OV_COM_CLIENT:335,A_OV_REM:162,A_OV_PROG:89.36,B_OV_TOT:143,B_OV_COM:1,B_OV_COM_CLIENT:0,B_OV_REM:142,B_OV_PROG:0.69,PA_OV_TOT:37,PA_OV_REM:11,PB1_OV_TOT:134,PB1_OV_REM:13,PB2_OV_TOT:46,PB2_OV_REM:22,PREVENT_DC_REM:155,ACT_TAG_VALID:1554,PUNCH_NUMBER_VALID:217,PUNCH_TAG_VALID:128},
    {SUBSYSTEM:"21-9921-9922-03",SUBSYSTEM_DESCR:"LQ HVAC Extracts, Ducting and Dampers",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-04-28",A_OV_TOT:294,A_OV_COM:283,A_OV_COM_LW:13,A_OV_COM_TW:0,A_OV_COM_CLIENT:51,A_OV_REM:11,A_OV_PROG:96.25,B_OV_TOT:17,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:17,B_OV_PROG:0,PA_OV_TOT:9,PA_OV_REM:4,PB1_OV_TOT:39,PB1_OV_REM:1,PB2_OV_TOT:9,PB2_OV_REM:4,PREVENT_DC_REM:18,ACT_TAG_VALID:291,PUNCH_NUMBER_VALID:57,PUNCH_TAG_VALID:29},
    {SUBSYSTEM:"21-9921-9922-04",SUBSYSTEM_DESCR:"LQ Galley AHUs, Ducting and Dampers",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-04-21",A_OV_TOT:176,A_OV_COM:129,A_OV_COM_LW:28,A_OV_COM_TW:0,A_OV_COM_CLIENT:38,A_OV_REM:47,A_OV_PROG:73.29,B_OV_TOT:33,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:33,B_OV_PROG:0,PA_OV_TOT:5,PA_OV_REM:0,PB1_OV_TOT:23,PB1_OV_REM:2,PB2_OV_TOT:6,PB2_OV_REM:5,PREVENT_DC_REM:35,ACT_TAG_VALID:184,PUNCH_NUMBER_VALID:34,PUNCH_TAG_VALID:19},
    {SUBSYSTEM:"21-9921-9922-05",SUBSYSTEM_DESCR:"LQ General Exhaust Air, Ducting and Dampers",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-05-19",A_OV_TOT:112,A_OV_COM:106,A_OV_COM_LW:11,A_OV_COM_TW:0,A_OV_COM_CLIENT:22,A_OV_REM:6,A_OV_PROG:94.64,B_OV_TOT:13,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:13,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:0,PB1_OV_TOT:27,PB1_OV_REM:3,PB2_OV_TOT:4,PB2_OV_REM:2,PREVENT_DC_REM:16,ACT_TAG_VALID:114,PUNCH_NUMBER_VALID:33,PUNCH_TAG_VALID:22},
    {SUBSYSTEM:"21-9921-9922-06",SUBSYSTEM_DESCR:"LQ Galley Hood Exhaust Fans, Ducting and Dampers",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-19",A_OV_TOT:207,A_OV_COM:140,A_OV_COM_LW:14,A_OV_COM_TW:0,A_OV_COM_CLIENT:41,A_OV_REM:67,A_OV_PROG:67.63,B_OV_TOT:38,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:38,B_OV_PROG:0,PA_OV_TOT:13,PA_OV_REM:3,PB1_OV_TOT:24,PB1_OV_REM:1,PB2_OV_TOT:9,PB2_OV_REM:5,PREVENT_DC_REM:39,ACT_TAG_VALID:198,PUNCH_NUMBER_VALID:46,PUNCH_TAG_VALID:23},
    {SUBSYSTEM:"21-9921-9922-07",SUBSYSTEM_DESCR:"LQ Toilet Exhaust Fans, Ducting and Dampers",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-12",A_OV_TOT:140,A_OV_COM:127,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:27,A_OV_REM:13,A_OV_PROG:90.71,B_OV_TOT:13,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:13,B_OV_PROG:0,PA_OV_TOT:4,PA_OV_REM:2,PB1_OV_TOT:27,PB1_OV_REM:7,PB2_OV_TOT:11,PB2_OV_REM:8,PREVENT_DC_REM:20,ACT_TAG_VALID:142,PUNCH_NUMBER_VALID:42,PUNCH_TAG_VALID:19},
    {SUBSYSTEM:"21-9921-9922-08",SUBSYSTEM_DESCR:"LQ Laundry Exhaust Fans, Ducting and Dampers",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-04-28",A_OV_TOT:69,A_OV_COM:51,A_OV_COM_LW:5,A_OV_COM_TW:0,A_OV_COM_CLIENT:13,A_OV_REM:18,A_OV_PROG:73.91,B_OV_TOT:10,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:10,B_OV_PROG:0,PB1_OV_TOT:22,PB1_OV_REM:1,PB2_OV_TOT:4,PB2_OV_REM:2,PREVENT_DC_REM:11,ACT_TAG_VALID:66,PUNCH_NUMBER_VALID:26,PUNCH_TAG_VALID:10},
    {SUBSYSTEM:"21-9921-9922-09",SUBSYSTEM_DESCR:"LQ Sick Bay Exhaust Fans, Ducting and Dampers",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-19",A_OV_TOT:61,A_OV_COM:49,A_OV_COM_LW:2,A_OV_COM_TW:0,A_OV_COM_CLIENT:17,A_OV_REM:12,A_OV_PROG:80.32,B_OV_TOT:10,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:10,B_OV_PROG:0,PA_OV_TOT:11,PA_OV_REM:8,PB1_OV_TOT:25,PB1_OV_REM:21,PB2_OV_TOT:25,PB2_OV_REM:21,PREVENT_DC_REM:31,ACT_TAG_VALID:58,PUNCH_NUMBER_VALID:61,PUNCH_TAG_VALID:18},
    {SUBSYSTEM:"21-9921-9922-11",SUBSYSTEM_DESCR:"LQ Laboratory Exhaust Fans, Ducting and Dampers",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-19",A_OV_TOT:75,A_OV_COM:65,A_OV_COM_LW:12,A_OV_COM_TW:0,A_OV_COM_CLIENT:19,A_OV_REM:10,A_OV_PROG:86.66,B_OV_TOT:10,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:10,B_OV_PROG:0,PA_OV_TOT:4,PA_OV_REM:3,PB1_OV_TOT:20,PB1_OV_REM:5,PB2_OV_TOT:7,PB2_OV_REM:7,PREVENT_DC_REM:15,ACT_TAG_VALID:69,PUNCH_NUMBER_VALID:31,PUNCH_TAG_VALID:16},
    {SUBSYSTEM:"21-9921-9922-12",SUBSYSTEM_DESCR:"LQ Provision Plant Exhaust Fans, Ducting and Dampers",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B2",MC_PLAN:"2022-04-28",A_OV_TOT:46,A_OV_COM:45,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:15,A_OV_REM:1,A_OV_PROG:97.82,B_OV_TOT:10,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:10,B_OV_PROG:0,PA_OV_TOT:5,PA_OV_REM:0,PB1_OV_TOT:10,PB1_OV_REM:0,PB2_OV_TOT:5,PB2_OV_REM:3,PREVENT_DC_REM:10,ACT_TAG_VALID:50,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:11},
    {SUBSYSTEM:"21-9921-9922-13",SUBSYSTEM_DESCR:"HULL Purge",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-05-12",A_OV_TOT:18,A_OV_COM:18,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:6,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:5,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:5,B_OV_PROG:0,PA_OV_TOT:4,PA_OV_REM:0,PB1_OV_TOT:8,PB1_OV_REM:1,PB2_OV_TOT:7,PB2_OV_REM:4,PB3_OV_TOT:1,PB3_OV_REM:0,PREVENT_DC_REM:6,RFDC_REM:4,ACT_TAG_VALID:21,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:10},
    {SUBSYSTEM:"21-9921-9922-14",SUBSYSTEM_DESCR:"LQ Paint Store Supply Fans, Ducting and Dampers",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-06-23",A_OV_TOT:112,A_OV_COM:82,A_OV_COM_LW:28,A_OV_COM_TW:0,A_OV_COM_CLIENT:39,A_OV_REM:30,A_OV_PROG:73.21,B_OV_TOT:20,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:20,B_OV_PROG:0,PA_OV_TOT:8,PA_OV_REM:5,PB1_OV_TOT:6,PB1_OV_REM:3,PB2_OV_TOT:3,PB2_OV_REM:1,PREVENT_DC_REM:23,ACT_TAG_VALID:98,PUNCH_NUMBER_VALID:17,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-9921-9922-15",SUBSYSTEM_DESCR:"LQ Dining Room Exhaust Fan, Ducting and Dampers",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-24",A_OV_TOT:61,A_OV_COM:60,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:29,A_OV_REM:1,A_OV_PROG:98.36,B_OV_TOT:10,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:10,B_OV_PROG:0,PB1_OV_TOT:26,PB1_OV_REM:3,PB2_OV_TOT:12,PB2_OV_REM:8,PREVENT_DC_REM:13,ACT_TAG_VALID:59,PUNCH_NUMBER_VALID:38,PUNCH_TAG_VALID:20},
    {SUBSYSTEM:"21-9921-9927-01",SUBSYSTEM_DESCR:"Cool Store System in Galley",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-05-19",A_OV_TOT:120,A_OV_COM:69,A_OV_COM_LW:9,A_OV_COM_TW:0,A_OV_COM_CLIENT:4,A_OV_REM:51,A_OV_PROG:57.49,B_OV_TOT:43,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:43,B_OV_PROG:0,PA_OV_TOT:10,PA_OV_REM:2,PB1_OV_TOT:29,PB1_OV_REM:3,PB2_OV_TOT:17,PB2_OV_REM:15,PREVENT_DC_REM:46,ACT_TAG_VALID:157,PUNCH_NUMBER_VALID:56,PUNCH_TAG_VALID:26},
    {SUBSYSTEM:"21-9950-9950-01",SUBSYSTEM_DESCR:"Tertiary Refuge Air Intake, Ducting and Dampers",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-07-14",A_OV_TOT:76,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:76,A_OV_PROG:0,B_OV_TOT:9,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:9,B_OV_PROG:0,ACT_TAG_VALID:68,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9950-9950-02",SUBSYSTEM_DESCR:"Tertiary Refuge ACUsandACCUs and UCPs",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-07-21",A_OV_TOT:82,A_OV_COM:6,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:76,A_OV_PROG:7.31,B_OV_TOT:45,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:45,B_OV_PROG:0,ACT_TAG_VALID:119,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9970-9970-01",SUBSYSTEM_DESCR:"HULL Emergency Pressurisation Fans",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-24",A_OV_TOT:84,A_OV_COM:69,A_OV_COM_LW:2,A_OV_COM_TW:0,A_OV_COM_CLIENT:22,A_OV_REM:15,A_OV_PROG:82.14,B_OV_TOT:14,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:14,B_OV_PROG:0,PA_OV_TOT:3,PA_OV_REM:0,PB1_OV_TOT:26,PB1_OV_REM:2,PB2_OV_TOT:12,PB2_OV_REM:9,PREVENT_DC_REM:16,ACT_TAG_VALID:80,PUNCH_NUMBER_VALID:41,PUNCH_TAG_VALID:23},
    {SUBSYSTEM:"21-9970-9971-01",SUBSYSTEM_DESCR:"HULL HVAC UCPs, EDBs",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-01-27",A_OV_TOT:25,A_OV_COM:25,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:16,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:60,B_OV_COM:44,B_OV_COM_CLIENT:0,B_OV_REM:16,B_OV_PROG:73.33,PA_OV_TOT:16,PA_OV_REM:0,PB1_OV_TOT:17,PB1_OV_REM:1,PB2_OV_TOT:11,PB2_OV_REM:7,PREVENT_DC_REM:17,ACT_TAG_VALID:81,PUNCH_NUMBER_VALID:44,PUNCH_TAG_VALID:8},
    {SUBSYSTEM:"21-9970-9971-02",SUBSYSTEM_DESCR:"HULL Machinery Space Supply Fans , Ducting and Dampers",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-17",A_OV_TOT:117,A_OV_COM:111,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:30,A_OV_REM:6,A_OV_PROG:94.87,B_OV_TOT:14,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:14,B_OV_PROG:0,PA_OV_TOT:8,PA_OV_REM:0,PB1_OV_TOT:35,PB1_OV_REM:3,PB2_OV_TOT:16,PB2_OV_REM:14,PREVENT_DC_REM:17,ACT_TAG_VALID:122,PUNCH_NUMBER_VALID:59,PUNCH_TAG_VALID:28},
    {SUBSYSTEM:"21-9970-9971-03",SUBSYSTEM_DESCR:"HULL Machinery Space Exhaust Fans , Ducting and Dampers",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-17",A_OV_TOT:124,A_OV_COM:124,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:30,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:13,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:13,B_OV_PROG:0,PA_OV_TOT:11,PA_OV_REM:0,PB1_OV_TOT:47,PB1_OV_REM:6,PB2_OV_TOT:19,PB2_OV_REM:15,PREVENT_DC_REM:19,ACT_TAG_VALID:131,PUNCH_NUMBER_VALID:77,PUNCH_TAG_VALID:30},
    {SUBSYSTEM:"21-9970-9971-04",SUBSYSTEM_DESCR:"KSG Room Supply Fans, Ducting and Dampers",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B2",MC_PLAN:"2022-03-03",A_OV_TOT:200,A_OV_COM:200,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:87,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:32,B_OV_COM:32,B_OV_COM_CLIENT:0,B_OV_REM:0,B_OV_PROG:100,PA_OV_TOT:7,PA_OV_REM:0,PB1_OV_TOT:64,PB1_OV_REM:0,PB2_OV_TOT:64,PB2_OV_REM:53,PREVENT_DC_REM:0,ACT_TAG_VALID:205,PUNCH_NUMBER_VALID:135,PUNCH_TAG_VALID:58},
    {SUBSYSTEM:"21-9970-9971-05",SUBSYSTEM_DESCR:"KSG Room Exhaust Fans, Ducting and Dampers",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B2",MC_PLAN:"2022-02-03",A_OV_TOT:91,A_OV_COM:91,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:39,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:12,B_OV_COM:12,B_OV_COM_CLIENT:0,B_OV_REM:0,B_OV_PROG:100,PA_OV_TOT:7,PA_OV_REM:0,PB1_OV_TOT:34,PB1_OV_REM:0,PB2_OV_TOT:12,PB2_OV_REM:8,PREVENT_DC_REM:0,ACT_TAG_VALID:96,PUNCH_NUMBER_VALID:53,PUNCH_TAG_VALID:26},
    {SUBSYSTEM:"21-9970-9971-06",SUBSYSTEM_DESCR:"HULL Chilled Water System and UCP",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-24",A_OV_TOT:192,A_OV_COM:163,A_OV_COM_LW:33,A_OV_COM_TW:0,A_OV_COM_CLIENT:20,A_OV_REM:29,A_OV_PROG:84.89,B_OV_TOT:150,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:150,B_OV_PROG:0,PA_OV_TOT:15,PA_OV_REM:0,PB1_OV_TOT:18,PB1_OV_REM:7,PB2_OV_TOT:13,PB2_OV_REM:6,PREVENT_DC_REM:157,ACT_TAG_VALID:325,PUNCH_NUMBER_VALID:46,PUNCH_TAG_VALID:21},
    {SUBSYSTEM:"21-9970-9971-07",SUBSYSTEM_DESCR:"HULL Chiller Exhaust Fans, Ducting and Dampers",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-24",A_OV_TOT:58,A_OV_COM:58,A_OV_COM_LW:2,A_OV_COM_TW:0,A_OV_COM_CLIENT:14,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:10,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:10,B_OV_PROG:0,PA_OV_TOT:11,PA_OV_REM:0,PB1_OV_TOT:12,PB1_OV_REM:5,PB2_OV_TOT:13,PB2_OV_REM:12,PREVENT_DC_REM:15,ACT_TAG_VALID:65,PUNCH_NUMBER_VALID:36,PUNCH_TAG_VALID:16},
    {SUBSYSTEM:"21-9970-9971-08",SUBSYSTEM_DESCR:"HULL Machinery Space AHU Air Supply",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-17",A_OV_TOT:344,A_OV_COM:326,A_OV_COM_LW:8,A_OV_COM_TW:0,A_OV_COM_CLIENT:66,A_OV_REM:18,A_OV_PROG:94.76,B_OV_TOT:49,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:49,B_OV_PROG:0,PA_OV_TOT:17,PA_OV_REM:0,PB1_OV_TOT:80,PB1_OV_REM:4,PB2_OV_TOT:22,PB2_OV_REM:19,PREVENT_DC_REM:53,ACT_TAG_VALID:373,PUNCH_NUMBER_VALID:119,PUNCH_TAG_VALID:62},
    {SUBSYSTEM:"21-9970-9971-09",SUBSYSTEM_DESCR:"HULL Machinery Space AHU Recirculation Air",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-17",A_OV_TOT:161,A_OV_COM:161,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:36,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:11,B_OV_COM:11,B_OV_COM_CLIENT:0,B_OV_REM:0,B_OV_PROG:100,PA_OV_TOT:3,PA_OV_REM:0,PB1_OV_TOT:40,PB1_OV_REM:3,PB2_OV_TOT:9,PB2_OV_REM:8,PREVENT_DC_REM:3,ACT_TAG_VALID:162,PUNCH_NUMBER_VALID:52,PUNCH_TAG_VALID:22},
    {SUBSYSTEM:"21-9970-9971-10",SUBSYSTEM_DESCR:"HULL Diesel Oil Pump Room Supply Fans, Ducting and Dampers",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-17",A_OV_TOT:77,A_OV_COM:77,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:34,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:14,B_OV_COM:14,B_OV_COM_CLIENT:0,B_OV_REM:0,B_OV_PROG:100,PA_OV_TOT:7,PA_OV_REM:0,PB1_OV_TOT:38,PB1_OV_REM:5,PB2_OV_TOT:18,PB2_OV_REM:17,PREVENT_DC_REM:5,ACT_TAG_VALID:80,PUNCH_NUMBER_VALID:63,PUNCH_TAG_VALID:25},
    {SUBSYSTEM:"21-9970-9971-11",SUBSYSTEM_DESCR:"HULL Diesel Oil Pump Room Exhaust Fans, Ducting and Dampers",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B2",MC_PLAN:"2022-03-03",A_OV_TOT:66,A_OV_COM:66,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:28,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:12,B_OV_COM:12,B_OV_COM_CLIENT:0,B_OV_REM:0,B_OV_PROG:100,PA_OV_TOT:6,PA_OV_REM:0,PB1_OV_TOT:36,PB1_OV_REM:0,PB2_OV_TOT:12,PB2_OV_REM:9,PREVENT_DC_REM:0,ACT_TAG_VALID:71,PUNCH_NUMBER_VALID:54,PUNCH_TAG_VALID:21},
    {SUBSYSTEM:"21-9970-9971-12",SUBSYSTEM_DESCR:"HULL Machinery Space HPU AHU, Ducting and Damper",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and Full MC Complete",PUNCH_STATUS:"B1",MC_PLAN:"2022-03-17",A_OV_TOT:103,A_OV_COM:103,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:40,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:18,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:18,B_OV_PROG:0,PA_OV_TOT:19,PA_OV_REM:0,PB1_OV_TOT:25,PB1_OV_REM:12,PB2_OV_TOT:14,PB2_OV_REM:13,PREVENT_DC_REM:30,ACT_TAG_VALID:117,PUNCH_NUMBER_VALID:58,PUNCH_TAG_VALID:28},
    {SUBSYSTEM:"21-9976-9976-01",SUBSYSTEM_DESCR:"Topsides Pressurisation Fans",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-04-07",A_OV_TOT:82,A_OV_COM:16,A_OV_COM_LW:4,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:66,A_OV_PROG:19.51,B_OV_TOT:15,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:15,B_OV_PROG:0,PA_OV_TOT:5,PA_OV_REM:3,PB1_OV_TOT:9,PB1_OV_REM:6,PB2_OV_TOT:4,PB2_OV_REM:3,PREVENT_DC_REM:21,ACT_TAG_VALID:76,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:7},
    {SUBSYSTEM:"21-9976-9976-02",SUBSYSTEM_DESCR:"Topsides Battery Exhaust Fans, Ducting and Dampers",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-04-07",A_OV_TOT:62,A_OV_COM:8,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:54,A_OV_PROG:12.9,B_OV_TOT:10,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:10,B_OV_PROG:0,PA_OV_TOT:3,PA_OV_REM:0,PB1_OV_TOT:8,PB1_OV_REM:5,PB2_OV_TOT:6,PB2_OV_REM:4,PREVENT_DC_REM:15,ACT_TAG_VALID:55,PUNCH_NUMBER_VALID:17,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-9976-9976-03",SUBSYSTEM_DESCR:"Emergency Room ACUsandACCUs and UCPs",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-04-07",A_OV_TOT:45,A_OV_COM:15,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:30,A_OV_PROG:33.33,B_OV_TOT:23,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:23,B_OV_PROG:0,PA_OV_TOT:11,PA_OV_REM:5,PB1_OV_TOT:7,PB1_OV_REM:6,PB2_OV_TOT:6,PB2_OV_REM:6,PREVENT_DC_REM:29,ACT_TAG_VALID:51,PUNCH_NUMBER_VALID:24,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-9976-9977-01",SUBSYSTEM_DESCR:"Topsides HVAC UCPs, EDBs",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-04-07",A_OV_TOT:28,A_OV_COM:5,A_OV_COM_LW:4,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:23,A_OV_PROG:17.85,B_OV_TOT:50,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:50,B_OV_PROG:0,PB1_OV_TOT:8,PB1_OV_REM:7,PB2_OV_TOT:3,PB2_OV_REM:2,PREVENT_DC_REM:57,ACT_TAG_VALID:74,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-9976-9977-02",SUBSYSTEM_DESCR:"Topsides HVAC AHUs, Ducting and Dampers",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-04-07",A_OV_TOT:304,A_OV_COM:78,A_OV_COM_LW:22,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:226,A_OV_PROG:25.65,B_OV_TOT:57,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:57,B_OV_PROG:0,PA_OV_TOT:8,PA_OV_REM:0,PB1_OV_TOT:4,PB1_OV_REM:0,PREVENT_DC_REM:57,ACT_TAG_VALID:313,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:7},
    {SUBSYSTEM:"21-9976-9977-03",SUBSYSTEM_DESCR:"Topsides HVAC Extracts, Ducting and Dampers",SUBSYSTEM_STATUS:"Subsystems with PCC I&C Tests and PCC I&C Tests Prerequisite MC Started",PUNCH_STATUS:"B1",MC_PLAN:"2022-04-07",A_OV_TOT:140,A_OV_COM:60,A_OV_COM_LW:20,A_OV_COM_TW:0,A_OV_COM_CLIENT:10,A_OV_REM:80,A_OV_PROG:42.85,B_OV_TOT:11,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:11,B_OV_PROG:0,PA_OV_TOT:4,PA_OV_REM:0,PB1_OV_TOT:1,PB1_OV_REM:1,PB2_OV_TOT:3,PB2_OV_REM:3,PREVENT_DC_REM:12,ACT_TAG_VALID:121,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:4}
    ],
  Queries: {
    SQL1: [
      { DESC: 'DataSource SPCS Data 03-Mar-20' }
    ],
    SQL2: [
      { CDATE: '2021-10-21' }
    ],
    SQL3: [
      {MC_PLAN:"2021-09-23",A_T:212,A_A:204,A_O:8,B_T:2,B_A:0,B_O:2},
      {MC_PLAN:"2021-09-30",A_T:193,A_A:177,A_O:16,B_T:5,B_A:0,B_O:5},
      {MC_PLAN:"2021-10-07"},
      {MC_PLAN:"2021-10-14",A_T:248,A_A:157,A_O:91,B_T:1,B_A:0,B_O:1},
      {MC_PLAN:"2021-10-21",A_T:326,A_A:281,A_O:45,B_T:7,B_A:0,B_O:7},
      {MC_PLAN:"2021-10-28",A_T:158,A_A:117,A_O:41,B_T:6,B_A:0,B_O:6},
      {MC_PLAN:"2021-11-04",A_T:507,A_A:326,A_O:181,B_T:18,B_A:1,B_O:17},
      {MC_PLAN:"2021-11-11",A_T:126,A_A:8,A_O:118,B_T:4,B_A:0,B_O:4},
      {MC_PLAN:"2021-11-18",A_T:347,A_A:144,A_O:203,B_T:8,B_A:0,B_O:8},
      {MC_PLAN:"2021-11-25"},
      {MC_PLAN:"2021-12-02",A_T:38,A_A:4,A_O:34,B_T:2,B_A:0,B_O:2},
      {MC_PLAN:"2021-12-09",A_T:224,A_A:105,A_O:119,B_T:5,B_A:0,B_O:5},
      {MC_PLAN:"2021-12-16",A_T:1618,A_A:540,A_O:1078,B_T:16,B_A:0,B_O:16},
      {MC_PLAN:"2021-12-23",A_T:300,A_A:73,A_O:227,B_T:17,B_A:0,B_O:17},
      {MC_PLAN:"2021-12-30",A_T:537,A_A:86,A_O:451,B_T:9,B_A:0,B_O:9},
      {MC_PLAN:"2022-01-06",A_T:131,A_A:64,A_O:67,B_T:2,B_A:0,B_O:2},
      {MC_PLAN:"2022-01-13",A_T:212,A_A:11,A_O:201,B_T:7,B_A:0,B_O:7},
      {MC_PLAN:"2022-01-20"},
      {MC_PLAN:"2022-01-27",A_T:30,A_A:17,A_O:13,B_T:2,B_A:0,B_O:2},
      {MC_PLAN:"2022-02-03"},
      {MC_PLAN:"2022-02-10",A_T:2,A_A:0,A_O:2,B_T:1,B_A:0,B_O:1},
      {MC_PLAN:"2022-02-17",A_T:403,A_A:0,A_O:403,B_T:3,B_A:0,B_O:3},
      {MC_PLAN:"2022-02-24",A_T:3,A_A:0,A_O:3,B_T:1,B_A:0,B_O:1},
      {MC_PLAN:"2022-03-03"},
      {MC_PLAN:"2022-03-10"},
      {MC_PLAN:"2022-03-17"},
      {MC_PLAN:"2022-03-24"},
      {MC_PLAN:"2022-03-31",A_T:133,A_A:0,A_O:133,B_T:5,B_A:0,B_O:5},
      {MC_PLAN:"2022-04-07",A_T:139,A_A:0,A_O:139,B_T:2,B_A:0,B_O:2},
      {MC_PLAN:"2022-04-14",A_T:73,A_A:0,A_O:73,B_T:1,B_A:0,B_O:1}
    ],
    SQL4: [
      {CUTOFF:"2018-06-21",WK_NO:"W2018_25"},
      {CUTOFF:"2018-06-28",WK_NO:"W2018_26"},
      {CUTOFF:"2018-07-05",WK_NO:"W2018_27"},
      {CUTOFF:"2018-07-12",WK_NO:"W2018_28"},
      {CUTOFF:"2018-07-19",WK_NO:"W2018_29"},
      {CUTOFF:"2018-07-26",WK_NO:"W2018_30"},
      {CUTOFF:"2018-08-02",WK_NO:"W2018_31"},
      {CUTOFF:"2018-08-09",WK_NO:"W2018_32"},
      {CUTOFF:"2018-08-16",WK_NO:"W2018_33"},
      {CUTOFF:"2018-08-23",WK_NO:"W2018_34"},
      {CUTOFF:"2018-08-30",WK_NO:"W2018_35"},
      {CUTOFF:"2018-09-06",WK_NO:"W2018_36"},
      {CUTOFF:"2018-09-13",WK_NO:"W2018_37"},
      {CUTOFF:"2018-09-20",WK_NO:"W2018_38"},
      {CUTOFF:"2018-09-27",WK_NO:"W2018_39"},
      {CUTOFF:"2018-10-04",WK_NO:"W2018_40"},
      {CUTOFF:"2018-10-11",WK_NO:"W2018_41"},
      {CUTOFF:"2018-10-18",WK_NO:"W2018_42"},
      {CUTOFF:"2018-10-25",WK_NO:"W2018_43"},
      {CUTOFF:"2018-11-01",WK_NO:"W2018_44"},
      {CUTOFF:"2018-11-08",WK_NO:"W2018_45"},
      {CUTOFF:"2018-11-15",WK_NO:"W2018_46"},
      {CUTOFF:"2018-11-22",WK_NO:"W2018_47"},
      {CUTOFF:"2018-11-29",WK_NO:"W2018_48"},
      {CUTOFF:"2018-12-06",WK_NO:"W2018_49"},
      {CUTOFF:"2018-12-13",WK_NO:"W2018_50"},
      {CUTOFF:"2018-12-20",WK_NO:"W2018_51"},
      {CUTOFF:"2018-12-27",WK_NO:"W2018_52"},
      {CUTOFF:"2019-01-03",WK_NO:"W2019_01"},
      {CUTOFF:"2019-01-10",WK_NO:"W2019_02"},
      {CUTOFF:"2019-01-17",WK_NO:"W2019_03"},
      {CUTOFF:"2019-01-24",WK_NO:"W2019_04"},
      {CUTOFF:"2019-01-31",WK_NO:"W2019_05"},
      {CUTOFF:"2019-02-07",WK_NO:"W2019_06"},
      {CUTOFF:"2019-02-14",WK_NO:"W2019_07"},
      {CUTOFF:"2019-02-21",WK_NO:"W2019_08"},
      {CUTOFF:"2019-02-28",WK_NO:"W2019_09"},
      {CUTOFF:"2019-03-07",WK_NO:"W2019_10"},
      {CUTOFF:"2019-03-14",WK_NO:"W2019_11"},
      {CUTOFF:"2019-03-21",WK_NO:"W2019_12"},
      {CUTOFF:"2019-03-28",WK_NO:"W2019_13"},
      {CUTOFF:"2019-04-04",WK_NO:"W2019_14"},
      {CUTOFF:"2019-04-11",WK_NO:"W2019_15"},
      {CUTOFF:"2019-04-18",WK_NO:"W2019_16"},
      {CUTOFF:"2019-04-25",WK_NO:"W2019_17"},
      {CUTOFF:"2019-05-02",WK_NO:"W2019_18"},
      {CUTOFF:"2019-05-09",WK_NO:"W2019_19"},
      {CUTOFF:"2019-05-16",WK_NO:"W2019_20"},
      {CUTOFF:"2019-05-23",WK_NO:"W2019_21"},
      {CUTOFF:"2019-05-30",WK_NO:"W2019_22"},
      {CUTOFF:"2019-06-06",WK_NO:"W2019_23"},
      {CUTOFF:"2019-06-13",WK_NO:"W2019_24"},
      {CUTOFF:"2019-06-20",WK_NO:"W2019_25"},
      {CUTOFF:"2019-06-27",WK_NO:"W2019_26"},
      {CUTOFF:"2019-07-04",WK_NO:"W2019_27"},
      {CUTOFF:"2019-07-11",WK_NO:"W2019_28"},
      {CUTOFF:"2019-07-18",WK_NO:"W2019_29"},
      {CUTOFF:"2019-07-25",WK_NO:"W2019_30"},
      {CUTOFF:"2019-08-01",WK_NO:"W2019_31"},
      {CUTOFF:"2019-08-08",WK_NO:"W2019_32"},
      {CUTOFF:"2019-08-15",WK_NO:"W2019_33"},
      {CUTOFF:"2019-08-22",WK_NO:"W2019_34"},
      {CUTOFF:"2019-08-29",WK_NO:"W2019_35"},
      {CUTOFF:"2019-09-05",WK_NO:"W2019_36"},
      {CUTOFF:"2019-09-12",WK_NO:"W2019_37"},
      {CUTOFF:"2019-09-19",WK_NO:"W2019_38"},
      {CUTOFF:"2019-09-26",WK_NO:"W2019_39"},
      {CUTOFF:"2019-10-03",WK_NO:"W2019_40"},
      {CUTOFF:"2019-10-10",WK_NO:"W2019_41"},
      {CUTOFF:"2019-10-17",WK_NO:"W2019_42"},
      {CUTOFF:"2019-10-24",WK_NO:"W2019_43"},
      {CUTOFF:"2019-10-31",WK_NO:"W2019_44"},
      {CUTOFF:"2019-11-07",WK_NO:"W2019_45"},
      {CUTOFF:"2019-11-14",WK_NO:"W2019_46"},
      {CUTOFF:"2019-11-21",WK_NO:"W2019_47"},
      {CUTOFF:"2019-11-28",WK_NO:"W2019_48"},
      {CUTOFF:"2019-12-05",WK_NO:"W2019_49"},
      {CUTOFF:"2019-12-12",WK_NO:"W2019_50"},
      {CUTOFF:"2019-12-19",WK_NO:"W2019_51"},
      {CUTOFF:"2019-12-26",WK_NO:"W2019_52"},
      {CUTOFF:"2020-01-02",WK_NO:"W2020_01"},
      {CUTOFF:"2020-01-09",WK_NO:"W2020_02"},
      {CUTOFF:"2020-01-16",WK_NO:"W2020_03"},
      {CUTOFF:"2020-01-23",WK_NO:"W2020_04"},
      {CUTOFF:"2020-01-30",WK_NO:"W2020_05"},
      {CUTOFF:"2020-02-06",WK_NO:"W2020_06"},
      {CUTOFF:"2020-02-13",WK_NO:"W2020_07"},
      {CUTOFF:"2020-02-20",WK_NO:"W2020_08"},
      {CUTOFF:"2020-02-27",WK_NO:"W2020_09"},
      {CUTOFF:"2020-03-05",WK_NO:"W2020_10"},
      {CUTOFF:"2020-03-12",WK_NO:"W2020_11"},
      {CUTOFF:"2020-03-19",WK_NO:"W2020_12"},
      {CUTOFF:"2020-03-26",WK_NO:"W2020_13"},
      {CUTOFF:"2020-04-02",WK_NO:"W2020_14"},
      {CUTOFF:"2020-04-09",WK_NO:"W2020_15"},
      {CUTOFF:"2020-04-16",WK_NO:"W2020_16"},
      {CUTOFF:"2020-04-23",WK_NO:"W2020_17"},
      {CUTOFF:"2020-04-30",WK_NO:"W2020_18"},
      {CUTOFF:"2020-05-07",WK_NO:"W2020_19"},
      {CUTOFF:"2020-05-14",WK_NO:"W2020_20"},
      {CUTOFF:"2020-05-21",WK_NO:"W2020_21"},
      {CUTOFF:"2020-05-28",WK_NO:"W2020_22"},
      {CUTOFF:"2020-06-04",WK_NO:"W2020_23"},
      {CUTOFF:"2020-06-11",WK_NO:"W2020_24"},
      {CUTOFF:"2020-06-18",WK_NO:"W2020_25"},
      {CUTOFF:"2020-06-25",WK_NO:"W2020_26"},
      {CUTOFF:"2020-07-02",WK_NO:"W2020_27"},
      {CUTOFF:"2020-07-09",WK_NO:"W2020_28"},
      {CUTOFF:"2020-07-16",WK_NO:"W2020_29"},
      {CUTOFF:"2020-07-23",WK_NO:"W2020_30"},
      {CUTOFF:"2020-07-30",WK_NO:"W2020_31"},
      {CUTOFF:"2020-08-06",WK_NO:"W2020_32"},
      {CUTOFF:"2020-08-13",WK_NO:"W2020_33"},
      {CUTOFF:"2020-08-20",WK_NO:"W2020_34"},
      {CUTOFF:"2020-08-27",WK_NO:"W2020_35"},
      {CUTOFF:"2020-09-03",WK_NO:"W2020_36"},
      {CUTOFF:"2020-09-10",WK_NO:"W2020_37"},
      {CUTOFF:"2020-09-17",WK_NO:"W2020_38"},
      {CUTOFF:"2020-09-24",WK_NO:"W2020_39"},
      {CUTOFF:"2020-10-01",WK_NO:"W2020_40"},
      {CUTOFF:"2020-10-08",WK_NO:"W2020_41"},
      {CUTOFF:"2020-10-15",WK_NO:"W2020_42"},
      {CUTOFF:"2020-10-22",WK_NO:"W2020_43"},
      {CUTOFF:"2020-10-29",WK_NO:"W2020_44"},
      {CUTOFF:"2020-11-05",WK_NO:"W2020_45"},
      {CUTOFF:"2020-11-12",WK_NO:"W2020_46"},
      {CUTOFF:"2020-11-19",WK_NO:"W2020_47"},
      {CUTOFF:"2020-11-26",WK_NO:"W2020_48"},
      {CUTOFF:"2020-12-03",WK_NO:"W2020_49"},
      {CUTOFF:"2020-12-10",WK_NO:"W2020_50"},
      {CUTOFF:"2020-12-17",WK_NO:"W2020_51"},
      {CUTOFF:"2020-12-24",WK_NO:"W2020_52"},
      {CUTOFF:"2020-12-31",WK_NO:"W2020_53"},
      {CUTOFF:"2021-01-07",WK_NO:"W2021_01"},
      {CUTOFF:"2021-01-14",WK_NO:"W2021_02"},
      {CUTOFF:"2021-01-21",WK_NO:"W2021_03"},
      {CUTOFF:"2021-01-28",WK_NO:"W2021_04"},
      {CUTOFF:"2021-02-04",WK_NO:"W2021_05"},
      {CUTOFF:"2021-02-11",WK_NO:"W2021_06"},
      {CUTOFF:"2021-02-18",WK_NO:"W2021_07"},
      {CUTOFF:"2021-02-25",WK_NO:"W2021_08"},
      {CUTOFF:"2021-03-04",WK_NO:"W2021_09"},
      {CUTOFF:"2021-03-11",WK_NO:"W2021_10"},
      {CUTOFF:"2021-03-18",WK_NO:"W2021_11"},
      {CUTOFF:"2021-03-25",WK_NO:"W2021_12"},
      {CUTOFF:"2021-04-01",WK_NO:"W2021_13"},
      {CUTOFF:"2021-04-08",WK_NO:"W2021_14"},
      {CUTOFF:"2021-04-15",WK_NO:"W2021_15"},
      {CUTOFF:"2021-04-22",WK_NO:"W2021_16"},
      {CUTOFF:"2021-04-29",WK_NO:"W2021_17"},
      {CUTOFF:"2021-05-06",WK_NO:"W2021_18"},
      {CUTOFF:"2021-05-13",WK_NO:"W2021_19"},
      {CUTOFF:"2021-05-20",WK_NO:"W2021_20"},
      {CUTOFF:"2021-05-27",WK_NO:"W2021_21"},
      {CUTOFF:"2021-06-03",WK_NO:"W2021_22"},
      {CUTOFF:"2021-06-10",WK_NO:"W2021_23"},
      {CUTOFF:"2021-06-17",WK_NO:"W2021_24"},
      {CUTOFF:"2021-06-24",WK_NO:"W2021_25"},
      {CUTOFF:"2021-07-01",WK_NO:"W2021_26"},
      {CUTOFF:"2021-07-08",WK_NO:"W2021_27"},
      {CUTOFF:"2021-07-15",WK_NO:"W2021_28"},
      {CUTOFF:"2021-07-22",WK_NO:"W2021_29"},
      {CUTOFF:"2021-07-29",WK_NO:"W2021_30"},
      {CUTOFF:"2021-08-05",WK_NO:"W2021_31"},
      {CUTOFF:"2021-08-12",WK_NO:"W2021_32"},
      {CUTOFF:"2021-08-19",WK_NO:"W2021_33"},
      {CUTOFF:"2021-08-26",WK_NO:"W2021_34"},
      {CUTOFF:"2021-09-02",WK_NO:"W2021_35"},
      {CUTOFF:"2021-09-09",WK_NO:"W2021_36"},
      {CUTOFF:"2021-09-16",WK_NO:"W2021_37"},
      {CUTOFF:"2021-09-23",WK_NO:"W2021_38"},
      {CUTOFF:"2021-09-30",WK_NO:"W2021_39"},
      {CUTOFF:"2021-10-07",WK_NO:"W2021_40"},
      {CUTOFF:"2021-10-14",WK_NO:"W2021_41"},
      {CUTOFF:"2021-10-21",WK_NO:"W2021_42"},
      {CUTOFF:"2021-10-28",WK_NO:"W2021_43"},
      {CUTOFF:"2021-11-04",WK_NO:"W2021_44"},
      {CUTOFF:"2021-11-11",WK_NO:"W2021_45"},
      {CUTOFF:"2021-11-18",WK_NO:"W2021_46"},
      {CUTOFF:"2021-11-25",WK_NO:"W2021_47"},
      {CUTOFF:"2021-12-02",WK_NO:"W2021_48"},
      {CUTOFF:"2021-12-09",WK_NO:"W2021_49"},
      {CUTOFF:"2021-12-16",WK_NO:"W2021_50"},
      {CUTOFF:"2021-12-23",WK_NO:"W2021_51"},
      {CUTOFF:"2021-12-30",WK_NO:"W2021_52"},
      {CUTOFF:"2022-01-06",WK_NO:"W2022_01"},
      {CUTOFF:"2022-01-13",WK_NO:"W2022_02"},
      {CUTOFF:"2022-01-20",WK_NO:"W2022_03"},
      {CUTOFF:"2022-01-27",WK_NO:"W2022_04"},
      {CUTOFF:"2022-02-03",WK_NO:"W2022_05"},
      {CUTOFF:"2022-02-10",WK_NO:"W2022_06"},
      {CUTOFF:"2022-02-17",WK_NO:"W2022_07"},
      {CUTOFF:"2022-02-24",WK_NO:"W2022_08"},
      {CUTOFF:"2022-03-03",WK_NO:"W2022_09"},
      {CUTOFF:"2022-03-10",WK_NO:"W2022_10"},
      {CUTOFF:"2022-03-17",WK_NO:"W2022_11"},
      {CUTOFF:"2022-03-24",WK_NO:"W2022_12"},
      {CUTOFF:"2022-03-31",WK_NO:"W2022_13"},
      {CUTOFF:"2022-04-07",WK_NO:"W2022_14"},
      {CUTOFF:"2022-04-14",WK_NO:"W2022_15"},
      {CUTOFF:"2022-04-21",WK_NO:"W2022_16"},
      {CUTOFF:"2022-04-28",WK_NO:"W2022_17"},
      {CUTOFF:"2022-05-05",WK_NO:"W2022_18"},
      {CUTOFF:"2022-05-12",WK_NO:"W2022_19"},
      {CUTOFF:"2022-05-19",WK_NO:"W2022_20"},
      {CUTOFF:"2022-05-26",WK_NO:"W2022_21"},
      {CUTOFF:"2022-06-02",WK_NO:"W2022_22"},
      {CUTOFF:"2022-06-09",WK_NO:"W2022_23"},
      {CUTOFF:"2022-06-16",WK_NO:"W2022_24"},
      {CUTOFF:"2022-06-23",WK_NO:"W2022_25"},
      {CUTOFF:"2022-06-30",WK_NO:"W2022_26"},
      {CUTOFF:"2022-07-07",WK_NO:"W2022_27"},
      {CUTOFF:"2022-07-14",WK_NO:"W2022_28"},
      {CUTOFF:"2022-07-21",WK_NO:"W2022_29"},
      {CUTOFF:"2022-07-28",WK_NO:"W2022_30"},
      {CUTOFF:"2022-08-04",WK_NO:"W2022_31"},
      {CUTOFF:"2022-08-11",WK_NO:"W2022_32"},
      {CUTOFF:"2022-08-18",WK_NO:"W2022_33"},
      {CUTOFF:"2022-08-25",WK_NO:"W2022_34"},
      {CUTOFF:"2022-09-01",WK_NO:"W2022_35"},
      {CUTOFF:"2022-09-08",WK_NO:"W2022_36"},
      {CUTOFF:"2022-09-15",WK_NO:"W2022_37"},
      {CUTOFF:"2022-09-22",WK_NO:"W2022_38"},
      {CUTOFF:"2022-09-29",WK_NO:"W2022_39"},
      {CUTOFF:"2022-10-06",WK_NO:"W2022_40"},
      {CUTOFF:"2022-10-13",WK_NO:"W2022_41"},
      {CUTOFF:"2022-10-20",WK_NO:"W2022_42"},
      {CUTOFF:"2022-10-27",WK_NO:"W2022_43"},
      {CUTOFF:"2022-11-03",WK_NO:"W2022_44"},
      {CUTOFF:"2022-11-10",WK_NO:"W2022_45"},
      {CUTOFF:"2022-11-17",WK_NO:"W2022_46"},
      {CUTOFF:"2022-11-24",WK_NO:"W2022_47"},
      {CUTOFF:"2022-12-01",WK_NO:"W2022_48"},
      {CUTOFF:"2022-12-08",WK_NO:"W2022_49"},
      {CUTOFF:"2022-12-15",WK_NO:"W2022_50"},
      {CUTOFF:"2022-12-22",WK_NO:"W2022_51"},
      {CUTOFF:"2022-12-29",WK_NO:"W2022_52"}
    ],
    SQL5: [
      // {TOTAL:334,NOTSTARTED:141,APROGRESS:191,ACOMPLETED:2,BPROGRESS:0,LOOPTEST:0, MCCOMPLETED: 1},
      {TOTAL:89,NOTSTARTED:34,APROGRESS:41,ACOMPLETED:8,PAR_CYBERSECURITY:6,CYBERSECURITY:0}
    ],
    SQL6: [
      {'Total Loops':9417,'ICFT based on Loop Test Prerequisites':304,'ICFT available based on Partial MC Completed':157,'ICFT available based on Full MC Completed':298,'Incomplete ICFT':8615,'Complete ICFT':802}
      ],
    SQL7: [ // week data
      {DATE:"2021-12-02",CUTOFF:"2021-12-02",LAST_WK:0,SAT:0,SUN:0,MON:0,TUE:0,WEN:0,THU:0,FRI:0,THIS_WK:0}
    ]
  }
}