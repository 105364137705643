import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 725,
      CanvasHeight: 300,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#e0e0e0',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 725,
      CanvasChartHeight: 300,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Support Fabrication', x:20, y: 10, font: 'roboto', class: 'regular', size: 12, color: '#424242', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: -50, y: 20, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'start' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: -30,
          y: 20,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'start'
        },
      ]
    },
    Queries: {
      SQL1: [
      ]
    },
    DataItems: [
      { TITLE: 'COSCO 1Quay', DATAURL: '', DIMENTION: '8272,3782', PATH: '/shared/gallery/photo/photo-gallery-1604561073.jpeg' },
      { TITLE: 'COSCO 2Quay', DATAURL: '', DIMENTION: '8272,3782', PATH: '/shared/gallery/photo/photo-gallery-1604561073.jpeg' },
      { TITLE: 'COSCO3 Quay', DATAURL: '', DIMENTION: '8272,3782', PATH: '/shared/gallery/photo/photo-gallery-1604561073.jpeg' },
      { TITLE: 'COSCO4 Quay', DATAURL: '', DIMENTION: '8272,3782', PATH: '/shared/gallery/photo/photo-gallery-1604561073.jpeg' },
      { TITLE: 'COSCO5 Quay', DATAURL: '', DIMENTION: '8272,3782', PATH: '/shared/gallery/photo/photo-gallery-1604561073.jpeg' },
      { TITLE: 'COSCO6 Quay', DATAURL: '', DIMENTION: '8272,3782', PATH: '/shared/gallery/photo/photo-gallery-1604561073.jpeg' },
    ],
}