
export default {
  Canvas: {
    CanvasWidth: 1600,
    CanvasHeight: 1120,
    CanvasBorderWeight: 0.2,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',//0E1B2B
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 760,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },
  
  aaa:[['a','a'],['a','a']],
  DataItems: [
    { STEP: 1,    SN: 1,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: '',                                               DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Main Substation', },
    { STEP: 2,    SN: 1,  CAT: 'B',   ANI: 'Y',   STORY: 'text-flow',                                            NO: 7,    DURATION: '500-50',                                         DELAY: '0-1000',                                      GROUP: 'Pre-Com',   TITLE: 'Main Substation 220 KV Power Available from Chongwon', },
    { STEP: 2,    SN: 2,  CAT: 'A',   ANI: 'Y',   STORY: 'text-flow',                                            NO: 7,    DURATION: '500-50',                                         DELAY: '0-1000',                                      GROUP: 'Interface', TITLE: 'U/G Municipal Water Pipeline RFU', },
    { STEP: 3,    SN: 1,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Industrial Water & Fire Water Station 1', },
    { STEP: 3,    SN: 2,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Area B+ Substation', },
    { STEP: 3,    SN: 3,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Air Compression Substation (94SS05) ', },
    { STEP: 3,    SN: 4,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Cooling Water Substation 1 (91SS12)', },
    { STEP: 3,    SN: 5,  CAT: 'B',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Piperack No.1 - Required MC for Power', },
    { STEP: 3,    SN: 6,  CAT: 'B',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Piperack No.2 - Required MC for Power', },
    { STEP: 3,    SN: 7,  CAT: 'A',   ANI: 'Y',   STORY: 'text-pulling',                                         NO: 25,   DURATION: '1500-500',                                       DELAY: '0-50',                                        GROUP: 'Pre-Com',   TITLE: 'U/G Fiber Optic Cable Connected CCB to RIE 6', },
    { STEP: 3,    SN: 8,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'RIE 6 (Boiler / DW / CW / IA) - On Temp PWR ', },
    { STEP: 4,    SN: 1,  CAT: 'B',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Boiler Area Internal PR (Part of Piperack No.3) - Link Cable CW1 Sub to Boiler Sub ', },
    { STEP: 4,    SN: 2,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Boiler Unit Substation (81SS12A) ', },
    { STEP: 4,    SN: 3,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Cooling Water Substation 2 (91SS13)', },
    { STEP: 4,    SN: 4,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Waste Water Treatment Substation ( 97SS10) ', },
    { STEP: 4,    SN: 5,  CAT: 'B',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Piperack No.4 - Required MC for Power', },
    { STEP: 4,    SN: 6,  CAT: 'B',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Piperack No.5 - Required MC for Power', },
    { STEP: 4,    SN: 7,  CAT: 'B',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Piperack No.7 - Required MC for Power', },
    { STEP: 4,    SN: 8,  CAT: 'A',   ANI: 'Y',   STORY: 'text-flow-icon',                                       NO: 29,   DURATION: '500-25-1000',                                    DELAY: '0-0-1300',                                    GROUP: 'Interface', TITLE: 'U/G Town Gas To Admin Bldg.', },
    { STEP: 5,    SN: 1,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Central Control Room', },
    { STEP: 5,    SN: 2,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Air Compressor Station', },
    { STEP: 5,    SN: 3,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Waste Water Treatment Plant', },
    { STEP: 5,    SN: 4,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Tank Farm Substation 1 (70SS09)', },
    { STEP: 5,    SN: 5,  CAT: 'B',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Piperack No.3 - Required MC for Power', },
    { STEP: 5,    SN: 6,  CAT: 'B',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'PE Internal PR - Main Sub to GPPE Sub', },
    { STEP: 5,    SN: 7,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'GPPE: Substations (50SS04)', },
    { STEP: 5,    SN: 8,  CAT: 'B',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'PE Internal PR - Main Sub to PP Sub', },
    { STEP: 5,    SN: 9,  CAT: 'B',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'PP: Substations (60SS07)', },
    { STEP: 5,    SN: 10, CAT: 'B',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'PE Internal PR - Main Sub to LDPE Sub', },
    { STEP: 5,    SN: 11, CAT: 'B',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'LDPE: Substation (53SS06) ', },
    { STEP: 5,    SN: 12, CAT: 'B',   ANI: 'Y',   STORY: 'pulling-text',                                         NO: 25,   DURATION: '500-1000',                                       DELAY: '75-500',                                      GROUP: 'Pre-Com',   TITLE: 'GPPE: FO Cable CCB to RIE 5', },
    { STEP: 5,    SN: 13, CAT: 'B',   ANI: 'Y',   STORY: 'pulling-text',                                         NO: 25,   DURATION: '500-1000',                                       DELAY: '75-500',                                      GROUP: 'Pre-Com',   TITLE: 'GPPE: FO Cable CCB to RIE 4', },
    { STEP: 5,    SN: 14, CAT: 'B',   ANI: 'Y',   STORY: 'pulling-text',                                         NO: 25,   DURATION: '500-1000',                                       DELAY: '75-500',                                      GROUP: 'Pre-Com',   TITLE: 'GPPE: FO Cable CCB to RIE 3', },
    { STEP: 6,    SN: 1,  CAT: 'C',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Demin Water Unit ', },
    { STEP: 6,    SN: 2,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Piperack No.6 - Required MC by Power ', },
    { STEP: 6,    SN: 3,  CAT: 'B',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'RIE 7- On Temp PWR', },
    { STEP: 6,    SN: 4,  CAT: 'B',   ANI: 'Y',   STORY: 'pulling-text',                                         NO: 25,   DURATION: '500-1000',                                       DELAY: '75-500',                                      GROUP: 'Pre-Com',   TITLE: 'U/G Fiber Optic Cable Connected CCB to RIE 7', },
    { STEP: 6,    SN: 5,  CAT: 'B',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'RIE 5 (GPPE)', },
    { STEP: 6,    SN: 6,  CAT: 'B',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'RIE 4 (PP)', },
    { STEP: 6,    SN: 7,  CAT: 'B',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'RIE 3 (LDPE)', },
    { STEP: 6,    SN: 8,  CAT: 'B',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Piperack GPPE Sub to PBL Packing Facility 2', },
    { STEP: 6,    SN: 9,  CAT: 'B',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Piperack PP Sub to PBL Packing Facility 1', },
    { STEP: 6,    SN: 10, CAT: 'B',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Piperack LDPE Sub to PBL Packing Facility 1', },
    { STEP: 6,    SN: 11, CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'PBL MCC Rooms Packing (2) and WH (3)', },
    { STEP: 6,    SN: 12, CAT: 'B',   ANI: 'Y',   STORY: 'pulling-text',                                         NO: 25,   DURATION: '500-1000',                                       DELAY: '75-500',                                      GROUP: 'Pre-Com',   TITLE: 'U/G Control FO Cable Connected RIE 3 or 4 or 5 to PBL Central PLC Room', },
    { STEP: 6,    SN: 13, CAT: 'B',   ANI: 'Y',   STORY: 'text-pulling3',                                        NO: null, DURATION: '2000-1500',                                      DELAY: '0-0',                                         GROUP: 'Pre-Com',   TITLE: 'U/G Fire Alarm and Telecom Loops FO Cable Connected CCB to two PBL Warehouses', },
    { STEP: 7,    SN: 1,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: '1st Boiler', },
    { STEP: 7,    SN: 2,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Boiler Feed Water / Deaerator (1st Boiler)', },
    { STEP: 7,    SN: 3,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Cooling Water System No.1', },
    { STEP: 7,    SN: 4,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Industrial & Fire Water Station 2', },
    { STEP: 7,    SN: 5,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Flare System Substation (90SS09B)', },
    { STEP: 7,    SN: 6,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Unloading/Loading Truck Station', },
    { STEP: 7,    SN: 7,  CAT: 'A',   ANI: 'Y',   STORY: 'line-arrow-icon-text',                                 NO: null, DURATION: '5000-500-1000-1000',                             DELAY: '0-2200-2400-2500',                            GROUP: 'Interface', TITLE: 'Steam Blow (GEG) - Deaerator & PR Head', },
    { STEP: 7,    SN: 8,  CAT: 'A',   ANI: 'Y',   STORY: 'line-arrow-icon-text',                                 NO: null, DURATION: '6000-500-1000-100',                              DELAY: '0-1700-2000-2000',                            GROUP: 'Interface', TITLE: 'Steam Blow (GEG) - Elevated Flare PR & Header', },
    { STEP: 7,    SN: 9,  CAT: 'A',   ANI: 'Y',   STORY: 'line-arrow-icon-text',                                 NO: null, DURATION: '8000-500-1000-1000',                             DELAY: '0-1700-2000-2000',                            GROUP: 'Interface', TITLE: 'Linda N2 Available (WWT First User)', },
    { STEP: 7,    SN: 10, CAT: 'A',   ANI: 'Y',   STORY: 'truk-text',                                            NO: null, DURATION: '3000-500',                                       DELAY: '0-2500',                                      GROUP: 'Interface', TITLE: 'Acid and Caustic (Truck-loading) for DWU', },
    { STEP: 7,    SN: 11, CAT: 'A',   ANI: 'Y',   STORY: 'truk-text',                                            NO: null, DURATION: '3000-500',                                       DELAY: '0-2500',                                      GROUP: 'Interface', TITLE: 'Ammonia (Truck-Loading) for Boiler Area WW and WWTP)', },
    { STEP: 7,    SN: 12, CAT: 'A',   ANI: 'Y',   STORY: 'truk-text',                                            NO: null, DURATION: '3000-500',                                       DELAY: '0-2500',                                      GROUP: 'Interface', TITLE: 'Contingency Plan for Slop Oil', },
    { STEP: 8,    SN: 1,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Cooling Water System 2', },
    { STEP: 8,    SN: 2,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Elevated Flare', },
    { STEP: 8,    SN: 3,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Tank Farm Substation 2', },
    { STEP: 8,    SN: 4,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Tank Farm - Ethylene Tanks', },
    { STEP: 8,    SN: 5,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Tank Farm - Ammonia Tank (Boiler and WWTP Use First)', },
    { STEP: 8,    SN: 6,  CAT: 'D',   ANI: 'Y',   STORY: 'water-text',                                           NO: 7,    DURATION: '1000-1000',                                      DELAY: '150-1000',                                    GROUP: 'Pre-Com',   TITLE: 'Cooling Water System No.1 Flushing - OR', },
    { STEP: 8,    SN: 7,  CAT: 'A',   ANI: 'Y',   STORY: 'line-arrow-icon-text',                                 NO: null, DURATION: '5000-500-1000-1000',                             DELAY: '0-3800-4000-4000',                            GROUP: 'Interface', TITLE: 'Town Gas to Elevated Flare', },
    { STEP: 8,    SN: 8,  CAT: 'A',   ANI: 'Y',   STORY: 'line-arrow-icon-text',                                 NO: null, DURATION: '5500-500-1000-1000',                             DELAY: '0-2500-2500-3000',                            GROUP: 'Interface', TITLE: 'Natural Gas To Boilers', },
    { STEP: 9,    SN: 1,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Ground Flare', },
    { STEP: 9,    SN: 2,  CAT: 'B',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Tank Farm - C3 Spheres', },
    { STEP: 9,    SN: 3,  CAT: 'B',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Tank Farm - Pressurized Tank Farm I (Need CA1/CA2/Butene-1 Tanks GPPE1)', },
    { STEP: 9,    SN: 4,  CAT: 'B',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Tank Farm - Intermediate Tank Farm II (Need Hexene-1 for GPPE1 and Slop Oil Tanks early)', },
    { STEP: 9,    SN: 5,  CAT: 'B',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Tank Farm - Pressurized Tank Farm II (Need C4s to BD and GHU)', },
    { STEP: 9,    SN: 6,  CAT: 'D',   ANI: 'Y',   STORY: 'water-text',                                           NO: 7,    DURATION: '1000-1000',                                      DELAY: '100-1000',                                    GROUP: 'Pre-Com',   TITLE: 'Cooling Water System No.1 Passivation - OR', },
    { STEP: 9,    SN: 7,  CAT: 'D',   ANI: 'Y',   STORY: 'water-text',                                           NO: 9,    DURATION: '1000-1000',                                      DELAY: '100-1000',                                    GROUP: 'Pre-Com',   TITLE: 'Cooling Water System No.2 Flushing - Polymers', },
    { STEP: 9,    SN: 8,  CAT: 'D',   ANI: 'Y',   STORY: 'water-text',                                           NO: 5,    DURATION: '1000-1000',                                      DELAY: '100-1000',                                    GROUP: 'Pre-Com',   TITLE: 'Cooling Water System No.2 Flushing - Co-product', },
    { STEP: 10,   SN: 1,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'OR Substation 1 (25SS02)', },
    { STEP: 10,   SN: 2,  CAT: 'A',   ANI: 'Y',   STORY: 'pulling-text',                                         NO: null, DURATION: '500-1000',                                       DELAY: '50-1000',                                     GROUP: 'Pre-Com',   TITLE: 'U/G Fiber Optic Cable Pulled CCB to RIE 1', },
    { STEP: 10,   SN: 3,  CAT: 'A',   ANI: 'Y',   STORY: 'pulling-text',                                         NO: null, DURATION: '500-1000',                                       DELAY: '50-1000',                                     GROUP: 'Pre-Com',   TITLE: 'U/G Fiber Optic Cable Pulled CCB to RIE 2', },
    { STEP: 10,   SN: 4,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Tank Farm - Intermediate Tank Farm I (Need SCN for GHU and BTHC for BTX )', },
    { STEP: 10,   SN: 5,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Tank Farm - Intermediate Tank Farm III (Need Quench Oil to OR first)', },
    { STEP: 10,   SN: 6,  CAT: 'D',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'GPPE 1 - Regeneration of Purification (Dryer) Loading', },
    { STEP: 10,   SN: 7,  CAT: 'D',   ANI: 'Y',   STORY: 'line-icon-text-arrow/line/line/line/line',             NO: null, DURATION: '5000-1000-1000-500/4000/4000/4000/4000',         DELAY: '0-2500-1500-2500/2400/2400/2400/2400',        GROUP: 'Pre-Com',   TITLE: 'Steam Blow (GEG) - Olefins CGC & Headers', },
    { STEP: 10,   SN: 8,  CAT: 'D',   ANI: 'Y',   STORY: 'water-text',                                           NO: 9,    DURATION: '1000-1000',                                      DELAY: '200-1000',                                    GROUP: 'Pre-Com',   TITLE: 'Cooling Water System No.2 Passivation - Polymers', },
    { STEP: 10,   SN: 9,  CAT: 'D',   ANI: 'Y',   STORY: 'water-text',                                           NO: 5,    DURATION: '1000-1000',                                      DELAY: '200-1000',                                    GROUP: 'Pre-Com',   TITLE: 'Cooling Water System No.2 Passivation - Co-Product', },
    { STEP: 11,   SN: 1,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Tank Farm - Naphtha Tank Farm', },
    { STEP: 11,   SN: 2,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Tank Farm - Mogas Tank Farm', },
    { STEP: 11,   SN: 3,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Tank Farm - Off-Spec Tank Farm', },
    { STEP: 11,   SN: 4,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'RIE 1 (Olefins)', },
    { STEP: 11,   SN: 5,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'RIE 2 (Co-Product)', },
    { STEP: 11,   SN: 6,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'OR Substation 2 (25SS02A)', },
    { STEP: 11,   SN: 7,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Co-product Substation (36SS03)', },
    { STEP: 11,   SN: 8,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'GPPE 1 - Extruder Test Run', },
    { STEP: 11,   SN: 9,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'LDPE - Extruder Test Run', },
    { STEP: 11,   SN: 10, CAT: 'D',   ANI: 'Y',   STORY: 'line-icon-text-arrow/line/line/line/line',             NO: null, DURATION: '7000-1000-1000-500/3000/3000/3000/3000',         DELAY: '0-2500-2500-2500/2400/2400/2400/2400',        GROUP: 'Pre-Com',   TITLE: 'Steam Blow (Boilers)- Olefins CGC & PR Headers', },
    { STEP: 11,   SN: 11, CAT: 'D',   ANI: 'Y',   STORY: 'line-arrow-icon-text',                                 NO: null, DURATION: '8000-500-1000-1000',                             DELAY: '0-1700-2000-1700',                            GROUP: 'Pre-Com',   TITLE: 'Steam Blow (Boilers)-Co-Prod GHU & PR Headers', },
    { STEP: 11,   SN: 12, CAT: 'A',   ANI: 'Y',   STORY: 'text-line-arrow-icon',                                 NO: null, DURATION: '1000-5000-500-1000',                             DELAY: '0-0-2300-2500',                               GROUP: 'Interface', TITLE: 'GPPE 1 - Regeneration of Purification - Linda N2 Loading', },
    { STEP: 11,   SN: 13, CAT: 'A',   ANI: 'Y',   STORY: 'truk-line-arrow-icon-text',                            NO: null, DURATION: '3000-5000-500-1000-1000',                        DELAY: '0-2500-3500-4000-4500',                       GROUP: 'Interface', TITLE: 'PP - H2 Feed System Commissioning, Truckload Load H2 for GPPE Purification', },
    { STEP: 11,   SN: 14, CAT: 'A',   ANI: 'Y',   STORY: 'text-line-arrow-icon',                                 NO: null, DURATION: '500-5000-500-1000',                              DELAY: '0-0-2700-3000',                               GROUP: 'Interface', TITLE: 'LDPE - Primary Compressor Run-In With Linda N2', },
    { STEP: 12,   SN: 1,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'OR - CGC Turbine Solo Run', },
    { STEP: 12,   SN: 2,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'GPPE 2 - Extruder Test Run', },
    { STEP: 12,   SN: 3,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'PP 2 - Extruder Test Run', },
    { STEP: 12,   SN: 4,  CAT: 'D',   ANI: 'Y',   STORY: 'line-arrow-icon-text',                                 NO: null, DURATION: '7000-500-1000-1000',                             DELAY: '0-1700-2000-2500',                            GROUP: 'Pre-Com',   TITLE: 'Steam Blow (Boilers) - Co-Product BD Unit & PR Headers', },
    { STEP: 12,   SN: 5,  CAT: 'A',   ANI: 'Y',   STORY: 'line-arrow-icon-text',                                 NO: null, DURATION: '6000-500-1000-1000',                             DELAY: '0-2200-2700-3200',                            GROUP: 'Pre-Com',   TITLE: 'Steam Blow (Boilers) - GPPE HP (Extruders and Reactor Coolers) & PR Headers', },
    { STEP: 12,   SN: 6,  CAT: 'A',   ANI: 'Y',   STORY: 'line-arrow-icon-text',                                 NO: null, DURATION: '7000-500-1000-1000',                             DELAY: '0-2700-3000-3500',                            GROUP: 'Pre-Com',   TITLE: 'Steam Blow - GPPE LP (Extruders / Additive) & PR Headers', },
    { STEP: 12,   SN: 7,  CAT: 'A',   ANI: 'Y',   STORY: 'text-line-arrow-icon',                                 NO: null, DURATION: '500-6000-500-1000',                              DELAY: '0-0-2900-3300',                               GROUP: 'Interface', TITLE: 'GPPE 1 - Linda N2 for Cycle Gas Compressor N2 Run', },
    { STEP: 12,   SN: 8,  CAT: 'A',   ANI: 'Y',   STORY: 'text-line-arrow-icon',                                 NO: null, DURATION: '500-7000-500-1000',                              DELAY: '0-0-2200-2500',                               GROUP: 'Milestone', TITLE: 'Tank Farm - C2 (from CSPC) TK Precooling Start', },
    { STEP: 13,   SN: 1,  CAT: 'D',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'OR - Start CGC Air Blow and/or Dry-out', },
    { STEP: 13,   SN: 2,  CAT: 'D',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Co-Product - BD Unit Passivation', },
    { STEP: 13,   SN: 3,  CAT: 'D',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Co-Product - GHU Reactor Activation', },
    { STEP: 13,   SN: 4,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'PP 1 - Extruder Test Run', },
    { STEP: 13,   SN: 5,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'PP 2 - C3 Refrigeration Compressor Run', },
    { STEP: 13,   SN: 6,  CAT: 'A',   ANI: 'Y',   STORY: 'line-arrow-icon-text',                                 NO: null, DURATION: '7000-500-1000-500',                              DELAY: '0-2200-3000-3500',                            GROUP: 'Pre-Com',   TITLE: 'Steam Blow - PP LP (Extruders) & Headers', },
    { STEP: 13,   SN: 7,  CAT: 'A',   ANI: 'Y',   STORY: 'line-arrow-icon-text',                                 NO: null, DURATION: '8000-500-1000-500',                              DELAY: '0-2200-3000-3500',                            GROUP: 'Pre-Com',   TITLE: 'Steam Blow - LDPE HP Extrusion, HP Recycle Reactor Bay, Utility ) & Header', },
    { STEP: 13,   SN: 8,  CAT: 'A',   ANI: 'Y',   STORY: 'icon-text',                                            NO: null, DURATION: '1000-1000',                                      DELAY: '0-500',                                       GROUP: 'Interface', TITLE: 'GPPE 1 - Vent Gas Recovery Compressor N2 Run', },
    { STEP: 13,   SN: 9,  CAT: 'A',   ANI: 'Y',   STORY: 'icon-text',                                            NO: null, DURATION: '1000-1000',                                      DELAY: '0-500',                                       GROUP: 'Interface', TITLE: 'GPPE 2 - Cycle Gas Compressor N2 Run', },
    { STEP: 13,   SN: 10, CAT: 'A',   ANI: 'Y',   STORY: 'icon-text',                                            NO: null, DURATION: '1000-1000',                                      DELAY: '0-500',                                       GROUP: 'Interface', TITLE: 'LDPE - HP N2 Blow', },
    { STEP: 13,   SN: 11, CAT: 'A',   ANI: 'Y',   STORY: 'text-line-arrow-icon',                                 NO: null, DURATION: '1000-7000-500-1000',                             DELAY: '0-0-2300-2700',                               GROUP: 'Milestone', TITLE: 'GPPE 1 - Start Ethylene Introduction', },
    { STEP: 13,   SN: 12, CAT: 'A',   ANI: 'Y',   STORY: 'icon-text',                                            NO: null, DURATION: '1000-1000',                                      DELAY: '0-500',                                       GROUP: 'Milestone', TITLE: 'GPPE 1 - Start ReactorWall Treat & Re-Instate', },
    { STEP: 13,   SN: 13, CAT: 'A',   ANI: 'Y',   STORY: 'text-line-arrow-icon',                                 NO: null, DURATION: '1000-8000-500-1000',                             DELAY: '0-0-2000-2200',                               GROUP: 'Milestone', TITLE: 'TF - Start C3 Tanks Inventory from CSPC', },
    { STEP: 13,   SN: 14, CAT: 'A',   ANI: 'Y',   STORY: 'text-line-arrow-icon',                                 NO: null, DURATION: '1000-7000-500-1000',                             DELAY: '0-0-2000-2200',                               GROUP: 'Milestone', TITLE: 'TF - Start C4s Tanks Inventory from CSPC', },
    { STEP: 14,   SN: 1,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'OR - C2 & C3 Turbine Solo Run', },
    { STEP: 14,   SN: 2,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'OR - Start 1st Furnace Steam Drum Chemical Cleaning', },
    { STEP: 14,   SN: 3,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Co-Product - SHU Reactor Activation', },
    { STEP: 14,   SN: 4,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'GPPE 2 - Vent Gas Recovery Compressor Run', },
    { STEP: 14,   SN: 5,  CAT: 'A',   ANI: 'Y',   STORY: 'line-arrow-icon-text',                                 NO: null, DURATION: '7000-500-1000-1000',                             DELAY: '0-2300-2500-3000',                            GROUP: 'Pre-Com',   TITLE: 'Steam Blow - LDPE LP (Extrusion, Purge Gas, Compressor, Oil Storage) & Headers', },
    { STEP: 14,   SN: 6,  CAT: 'A',   ANI: 'Y',   STORY: 'text-line-arrow-icon/line-arrow',                      NO: null, DURATION: '1000-6000-500-1000/7000-500',                    DELAY: '0-0-1600-1700/3000-5500',                     GROUP: 'Interface', TITLE: 'OR - Quench Oil (CNOOC/QM Terminal)', },
    { STEP: 14,   SN: 7,  CAT: 'A',   ANI: 'Y',   STORY: 'text-line-arrow',                                      NO: null, DURATION: '1000-6000-50',                                   DELAY: '0-0-3000',                                    GROUP: 'Interface', TITLE: 'OR - Wash Oil for Quench Oil Sys', },
    { STEP: 14,   SN: 8,  CAT: 'A',   ANI: 'Y',   STORY: 'text-line-arrow',                                      NO: null, DURATION: '1000-6000-50',                                   DELAY: '0-0-3000',                                    GROUP: 'Milestone', TITLE: 'LDPE - Second Phase Compressors Run-in with Propylene', },
    { STEP: 14,   SN: 9,  CAT: 'A',   ANI: 'Y',   STORY: 'text-line-arrow-icon',                                 NO: null, DURATION: '1000-6000-500-1000',                             DELAY: '0-0-2300-3000',                               GROUP: 'Milestone', TITLE: 'GPPE 1 - GPPE CA1/CA2/Butene-1/Hexene-1 Inventory (CSPC)', },
    { STEP: 14,   SN: 10, CAT: 'A',   ANI: 'Y',   STORY: 'text-line-arrow-icon',                                 NO: null, DURATION: '1000-7000-500-1000',                             DELAY: '0-0-2600-3000',                               GROUP: 'Milestone', TITLE: 'Co-Product - BD Raw C4 (CSPC) Inventory', },
    { STEP: 15,   SN: 1,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'OR - Last Furnace Dry-out', },
    { STEP: 15,   SN: 2,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Co-Product - Cold Box Reinstatement', },
    { STEP: 15,   SN: 3,  CAT: 'A',   ANI: '',    STORY: '',                                                     NO: null, DURATION: 'null',                                           DELAY: 'null',                                        GROUP: 'Pre-Com',   TITLE: 'Co-Product - Sulfolane Solvent system water flushing', },
    { STEP: 15,   SN: 4,  CAT: 'A',   ANI: 'Y',   STORY: 'line-arrow-icon-text',                                 NO: null, DURATION: '5000-500-1000-500',                              DELAY: '0-2000-2000-2500',                            GROUP: 'Milestone', TITLE: 'OR - H2 from CSPC to OR for C3 Hydrogenation', },
    { STEP: 15,   SN: 5,  CAT: 'A',   ANI: 'Y',   STORY: 'line-arrow-icon-text',                                 NO: null, DURATION: '5000-500-1000-500',                              DELAY: '0-2000-2000-2500',                            GROUP: 'Milestone', TITLE: 'Co-Product - H2 from CSPC to GHU for Reactor Catalyst Conditioning', },
    { STEP: 15,   SN: 6,  CAT: 'A',   ANI: 'Y',   STORY: 'line-arrow-icon-text',                                 NO: null, DURATION: '5000-500-1000-500',                              DELAY: '0-2000-2000-2500',                            GROUP: 'Milestone', TITLE: 'Co-Product - H2 from CSPC to SHU Reactor Catalyst Conditioning', },
    { STEP: 15,   SN: 7,  CAT: 'A',   ANI: 'Y',   STORY: 'text-line-arrow-icon/line-arrow',                      NO: null, DURATION: '500-6000-500-1000/8000-500',                     DELAY: '0-0-2000-2300/3000-7000',                     GROUP: 'Milestone', TITLE: 'Co-Product - SCN TKs and GHU Inventory (Buy from xxx -TK-GHU)', },
    { STEP: 15,   SN: 8,  CAT: 'A',   ANI: 'Y',   STORY: 'text-line-arrow-icon',                                 NO: null, DURATION: '500-7000-500-1000',                              DELAY: '0-0-2500-2800',                               GROUP: 'Milestone', TITLE: 'Co-Product - DIB C4 Inventory (CSPC -Tank - DIB Unit)', },
    { STEP: 15,   SN: 9,  CAT: 'A',   ANI: 'Y',   STORY: 'line-arrow-icon-text',                                 NO: null, DURATION: '11000-500-1000-500',                             DELAY: '0-3000-3200-3500',                            GROUP: 'Milestone', TITLE: 'GPPE 1 - Started-Up', },
    { STEP: 15,   SN: 10, CAT: 'A',   ANI: 'Y',   STORY: 'text-line-arrow-icon',                                 NO: null, DURATION: '500-6000-500-1000',                              DELAY: '0-0-2500-3000',                               GROUP: 'Milestone', TITLE: 'PP2 - Start Propylene Introduction', },
    { STEP: 15,   SN: 11, CAT: 'A',   ANI: 'Y',   STORY: 'text-line-arrow-icon',                                 NO: null, DURATION: '500-6000-500-1000',                              DELAY: '0-0-2400-3000',                               GROUP: 'Milestone', TITLE: 'LDPE - Start Ethylene Introduction', },
    { STEP: 15,   SN: 12, CAT: 'A',   ANI: 'Y',   STORY: 'text-line-arrow',                                      NO: null, DURATION: '500-6000-500',                                   DELAY: '0-0-2700',                                    GROUP: 'Milestone', TITLE: 'LDPE - Start Propylene Introduction', },
    { STEP: 16,   SN: 1,  CAT: 'A',   ANI: 'Y',   STORY: 'text-line-arrow-icon',                                 NO: null, DURATION: '1000-9000-500-1000',                             DELAY: '0-0-2000-2300',                               GROUP: 'Milestone', TITLE: 'OR - Introduce Ethylene for Refrigeration', },
    { STEP: 16,   SN: 2,  CAT: 'A',   ANI: 'Y',   STORY: 'text-line-arrow-icon',                                 NO: null, DURATION: '1000-9000-500-1000',                             DELAY: '0-0-2500-2900',                               GROUP: 'Milestone', TITLE: 'OR - Introduce Propylene for Refrigeration', },
    { STEP: 16,   SN: 3,  CAT: 'A',   ANI: 'Y',   STORY: 'text-line-arrow-icon/line-arrow-icon',                 NO: null, DURATION: '1000-7000-500-1000/7000-500-1000',               DELAY: '0-0-2300-2500/3000-5300-5500',                GROUP: 'Milestone', TITLE: 'OF - Naphtha from XXX-Jetty-TK-OF', },
    { STEP: 16,   SN: 4,  CAT: 'A',   ANI: 'Y',   STORY: 'truk-line-arrow-text',                                 NO: null, DURATION: '2000-7000-500-1000',                             DELAY: '0-2000-4200-4500',                            GROUP: 'Milestone', TITLE: 'OF - Ammonia from Truck-TK-OF', },
    { STEP: 16,   SN: 5,  CAT: 'A',   ANI: 'Y',   STORY: 'text-line-arrow-icon/line-arrow-icon',                 NO: null, DURATION: '1000-6500-500-1000/6000-500-1000',               DELAY: '0-0-2000-2300/3000-5700-6700',                GROUP: 'Milestone', TITLE: 'Co-Product - Methanol Jetty-TK-OR', },
    { STEP: 16,   SN: 6,  CAT: 'A',   ANI: 'Y',   STORY: 'text-line-arrow-icon',                                 NO: null, DURATION: '1000-5000-500-1000',                             DELAY: '0-0-2300-5000',                               GROUP: 'Milestone', TITLE: 'Co-Product - Methanol Jetty-TK- DIB', },
    { STEP: 16,   SN: 7,  CAT: 'A',   ANI: 'Y',   STORY: 'line-arrow-text/line-arrow-text',                      NO: null, DURATION: '6000-500-1000/6000-500-1000',                    DELAY: '0-2000-2300/3000-4300-4500',                  GROUP: 'Milestone', TITLE: 'Co-Product - GHU Started-Up', },
    { STEP: 16,   SN: 8,  CAT: 'A',   ANI: 'Y',   STORY: 'text-line-arrow/text',                                 NO: null, DURATION: '1000-10000-500/1000',                            DELAY: '0-0-2000/2300',                               GROUP: 'Milestone', TITLE: 'Co-Product - SHU Started-Up', },
    { STEP: 16,   SN: 9,  CAT: 'A',   ANI: 'Y',   STORY: 'line-arrow-text/line-arrow-text',                      NO: null, DURATION: '7000-500-1000/6000-500-1000',                    DELAY: '0-2300-2500/3000-4500-4700',                  GROUP: 'Milestone', TITLE: 'Co-Product - DIB Started-Up', },
    { STEP: 16,   SN: 10, CAT: 'A',   ANI: 'Y',   STORY: 'text-line-arrow-icon/line-arrow-text/line-arrow-text', NO: null, DURATION: '1000-5000-500-1000/5000-500-1000/5000-500-1000', DELAY: '0-0-3000-3000/4500-6500-7000/7000-7500-7500', GROUP: 'Milestone', TITLE: 'Co-Product - BTX Started-Up', },
    { STEP: 16,   SN: 11, CAT: 'A',   ANI: 'Y',   STORY: 'line-arrow-text',                                      NO: null, DURATION: '11000-500-1000',                                 DELAY: '0-3200-3500',                                 GROUP: 'Milestone', TITLE: 'GPPE 2 - Started-Up', },
    { STEP: 16,   SN: 12, CAT: 'A',   ANI: 'Y',   STORY: 'line-arrow-text',                                      NO: null, DURATION: '11000-500-1000',                                 DELAY: '0-3200-3500',                                 GROUP: 'Milestone', TITLE: 'PP 2 - Started-Up', },
    { STEP: 17,   SN: 1,  CAT: 'A',   ANI: 'Y',   STORY: 'line-arrow-text',                                      NO: null, DURATION: '7000-500-1000',                                  DELAY: '0-1800-2000',                                 GROUP: 'Milestone', TITLE: 'OR  -  Start up: C4 from Quench Water Tower Bottom to GHU', },
    { STEP: 17,   SN: 2,  CAT: 'A',   ANI: 'Y',   STORY: 'line-arrow-text',                                      NO: null, DURATION: '7000-500-1000',                                  DELAY: '0-2100-2300',                                 GROUP: 'Milestone', TITLE: 'OR  -  Start up: C4 from Caustic Extractor to BD Unit', },
    { STEP: 17,   SN: 3,  CAT: 'A',   ANI: 'Y',   STORY: 'line-arrow-text',                                      NO: null, DURATION: '7000-500-1000',                                  DELAY: '0-2500-3000',                                 GROUP: 'Milestone', TITLE: 'OR  -  Start up: C2 from C2 Splitter To Tank', },
    { STEP: 17,   SN: 4,  CAT: 'A',   ANI: 'Y',   STORY: 'line-arrow-text',                                      NO: null, DURATION: '7000-500-1000',                                  DELAY: '0-2600-3000',                                 GROUP: 'Milestone', TITLE: 'OR  -  Start up: C3 from C3 Rectifier To Tank', },
    { STEP: 17,   SN: 5,  CAT: 'A',   ANI: 'Y',   STORY: 'line-arrow-icon-text',                                 NO: null, DURATION: '9000-500-1000-1000',                             DELAY: '0-3000-2500-2500',                            GROUP: 'Milestone', TITLE: 'LDPE - Started-Up', },
    { STEP: 17,   SN: 6,  CAT: 'A',   ANI: 'Y',   STORY: 'line-arrow-icon-text',                                 NO: null, DURATION: '9000-500-1000-1000',                             DELAY: '0-2800-1000-2700',                            GROUP: 'Milestone', TITLE: 'PP 1 - Started-Up', },

  ],
}