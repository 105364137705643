import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 550,
      CanvasHeight: 270,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },
    DataItems:  [
      {QCF:"AC01A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"HVAC Ducting Installation Record",TOTAL:2,COMPLETED:2,REMAIN:0,PROG:100},
      {QCF:"CL3A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Cold Insulation Summary Report",TOTAL:5,COMPLETED:4,REMAIN:1,PROG:80},
      {QCF:"CM06A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"STATIC EQUIPMENT /SKID POSITIONING CHECK",TOTAL:16,COMPLETED:14,REMAIN:2,PROG:87.5},
      {QCF:"CP2DA",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Painting Final Inspection",TOTAL:112,COMPLETED:40,REMAIN:72,PROG:35.71},
      {QCF:"CR01A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Centrif. And Rotary Pumps Alignment Report Without Reducer",TOTAL:28,COMPLETED:28,REMAIN:0,PROG:100},
      {QCF:"CR02A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Machinery Shaft PRE-ALIGNMENT EPORT",TOTAL:11,COMPLETED:11,REMAIN:0,PROG:100},
      {QCF:"CR02BA",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Machinery Shaft Final Alignment",TOTAL:11,COMPLETED:9,REMAIN:2,PROG:81.81},
      {QCF:"E02A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Cables Installation & Connection",TOTAL:1830,COMPLETED:1776,REMAIN:54,PROG:97.04},
      {QCF:"E03A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Switchboard Installation",TOTAL:3,COMPLETED:3,REMAIN:0,PROG:100},
      {QCF:"E04A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Distribution Board Installation",TOTAL:18,COMPLETED:14,REMAIN:4,PROG:77.77},
      {QCF:"E05A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Switchboard Bus Duct Installation",TOTAL:1,COMPLETED:1,REMAIN:0,PROG:100},
      {QCF:"E06A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Electrical Motors Installation",TOTAL:19,COMPLETED:19,REMAIN:0,PROG:100},
      {QCF:"E08A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Local Control Station Installation",TOTAL:18,COMPLETED:18,REMAIN:0,PROG:100},
      {QCF:"E10A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Generator / Alternator Completion",TOTAL:2,COMPLETED:2,REMAIN:0,PROG:100},
      {QCF:"E12A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Lighting Installation",TOTAL:116,COMPLETED:69,REMAIN:47,PROG:59.48},
      {QCF:"E13A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Lv Feeder Starter Installation",TOTAL:2,COMPLETED:2,REMAIN:0,PROG:100},
      {QCF:"E15A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Lv/Mv Circuit Breaker Installation",TOTAL:299,COMPLETED:299,REMAIN:0,PROG:100},
      {QCF:"E16A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Power Transformer Installation",TOTAL:2,COMPLETED:2,REMAIN:0,PROG:100},
      {QCF:"E18A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Ex Equipment Inspection Report",TOTAL:254,COMPLETED:45,REMAIN:209,PROG:17.71},
      {QCF:"E19A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Charger/Inverter/Ups Installation",TOTAL:2,COMPLETED:2,REMAIN:0,PROG:100},
      {QCF:"E20A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Batteries Installation",TOTAL:2,COMPLETED:2,REMAIN:0,PROG:100},
      {QCF:"E21A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Heaters Installation",TOTAL:2,COMPLETED:2,REMAIN:0,PROG:100},
      {QCF:"E22A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Neutral Earthing Resistor Installation",TOTAL:2,COMPLETED:2,REMAIN:0,PROG:100},
      {QCF:"E25A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Variable Speed Drive / Power Electric Panels Installation",TOTAL:4,COMPLETED:2,REMAIN:2,PROG:50},
      {QCF:"E30A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Earthing Installation and Lightning Protection Systems",TOTAL:14,COMPLETED:4,REMAIN:10,PROG:28.57},
      {QCF:"E31A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Junction Box Installation & Connection",TOTAL:102,COMPLETED:93,REMAIN:9,PROG:91.17},
      {QCF:"HV01A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"HVAC Manual Damper Installation Record",TOTAL:50,COMPLETED:50,REMAIN:0,PROG:100},
      {QCF:"HV04A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"HVAC Fan Installation Record",TOTAL:5,COMPLETED:5,REMAIN:0,PROG:100},
      {QCF:"HV06A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"HVAC Sound Attenuator Installation Record",TOTAL:5,COMPLETED:5,REMAIN:0,PROG:100},
      {QCF:"HV09A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Air Tightness Test Record",TOTAL:3,COMPLETED:3,REMAIN:0,PROG:100},
      {QCF:"HV11A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"HVAC Filters",TOTAL:2,COMPLETED:2,REMAIN:0,PROG:100},
      {QCF:"HV13A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Supply/Exhaust Diffuser/Grille/Mesh",TOTAL:22,COMPLETED:22,REMAIN:0,PROG:100},
      {QCF:"HV14A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Moisture Eliminator/Weather Louvre",TOTAL:3,COMPLETED:3,REMAIN:0,PROG:100},
      {QCF:"I01A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Instrument Installation",TOTAL:73,COMPLETED:66,REMAIN:7,PROG:90.41},
      {QCF:"I02A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Instrument Calibration",TOTAL:63,COMPLETED:62,REMAIN:1,PROG:98.41},
      {QCF:"I03A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Control Panel Installation",TOTAL:37,COMPLETED:36,REMAIN:1,PROG:97.29},
      {QCF:"I04A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Actuated Control Valve And Ancillaries Installation",TOTAL:38,COMPLETED:21,REMAIN:17,PROG:55.26},
      {QCF:"I05A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Orifice Plate Dimension Check Report",TOTAL:3,COMPLETED:3,REMAIN:0,PROG:100},
      {QCF:"I06-1A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Installation of Safety Critical Element ( PSV / NRV )",TOTAL:8,COMPLETED:6,REMAIN:2,PROG:75},
      {QCF:"I06-2A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Calibration of Safety Critical Element ( PSV / NRV )",TOTAL:21,COMPLETED:20,REMAIN:1,PROG:95.23},
      {QCF:"I08A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Pressure, Temperature & Level Gauge Installation",TOTAL:1,COMPLETED:1,REMAIN:0,PROG:100},
      {QCF:"I099-1A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Control Valve Calibration",TOTAL:2,COMPLETED:2,REMAIN:0,PROG:100},
      {QCF:"I099-2A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"On Off Valve Calibration",TOTAL:34,COMPLETED:32,REMAIN:2,PROG:94.11},
      {QCF:"I09A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Instrument Pressure / Leak Test Report",TOTAL:89,COMPLETED:82,REMAIN:7,PROG:92.13},
      {QCF:"IC01A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Piping / Equipment Internal Cleaning Inspection",TOTAL:113,COMPLETED:111,REMAIN:2,PROG:98.23},
      {QCF:"ICF008A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Fiber Optic Patch Panel Installation",TOTAL:9,COMPLETED:7,REMAIN:2,PROG:77.77},
      {QCF:"LT02A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Tank Air Test",TOTAL:7,COMPLETED:7,REMAIN:0,PROG:100},
      {QCF:"M01A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Vessel/Column/Filter/Tank Closure",TOTAL:3,COMPLETED:2,REMAIN:1,PROG:66.66},
      {QCF:"M02A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Pumps Installation Summary Report",TOTAL:6,COMPLETED:6,REMAIN:0,PROG:100},
      {QCF:"M04A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Inline Strainer / Filter",TOTAL:9,COMPLETED:9,REMAIN:0,PROG:100},
      {QCF:"M05A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Diesel Engine Installation Check",TOTAL:2,COMPLETED:2,REMAIN:0,PROG:100},
      {QCF:"M12A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Miscellaneous Equipment Installation Check",TOTAL:12,COMPLETED:12,REMAIN:0,PROG:100},
      {QCF:"M26A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Equipment Flange Alignment Check",TOTAL:29,COMPLETED:28,REMAIN:1,PROG:96.55},
      {QCF:"M28A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Mechanical Silencer Installation Check",TOTAL:2,COMPLETED:2,REMAIN:0,PROG:100},
      {QCF:"P01A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Pre-Test Line Walk",TOTAL:113,COMPLETED:112,REMAIN:1,PROG:99.11},
      {QCF:"P03A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Piping Pressure Test Checksheet",TOTAL:113,COMPLETED:109,REMAIN:4,PROG:96.46},
      {QCF:"P05A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Test Pack Reinstatement",TOTAL:112,COMPLETED:97,REMAIN:15,PROG:86.6},
      {QCF:"P06A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Joint Integrity Certificate",TOTAL:112,COMPLETED:98,REMAIN:14,PROG:87.5},
      {QCF:"P10A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Gross Air Leak Test",TOTAL:14,COMPLETED:8,REMAIN:6,PROG:57.14},
      {QCF:"PK01A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Electrical Package Installation Report",TOTAL:5,COMPLETED:5,REMAIN:0,PROG:100},
      {QCF:"PK02A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Instrumentation Package Installation Report",TOTAL:5,COMPLETED:5,REMAIN:0,PROG:100},
      {QCF:"PK03A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Mechanical Package Installation Report",TOTAL:5,COMPLETED:5,REMAIN:0,PROG:100},
      {QCF:"PK04A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Piping Package Installation Report",TOTAL:5,COMPLETED:5,REMAIN:0,PROG:100},
      {QCF:"S05A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Structural Tank Completion",TOTAL:7,COMPLETED:5,REMAIN:2,PROG:71.42},
      {QCF:"S06A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Structural Tank Hydrotest",TOTAL:7,COMPLETED:7,REMAIN:0,PROG:100},
      {QCF:"T09A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Fiber Optic Cables Installation",TOTAL:110,COMPLETED:89,REMAIN:21,PROG:80.9},
      {QCF:"W110A",KSG_PHASE:"A",CATEGORY:"A",QCF_DESCR:"Non-Metallic Piping Joints Report Check",TOTAL:28,COMPLETED:28,REMAIN:0,PROG:100}
      ],
}