import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 350,
      CanvasHeight: 200,
      CanvasBorderWeight: 0.3,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 310,
      CanvasChartHeight: 270,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: 'Transparent',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },
    Queries: {
      SQL1:  [
        {DISC_DESCR:"Total",OPEN:1812}
      ],
    },
    DataItems: [
      // {SIZE:"LB",TOTAL:"test",FAB:"T",DEL:22585,INST:22585,REM:22585},
      // {SIZE:"SB",TOTAL:"test",FAB:"T",DEL:22585,INST:22585,REM:22585},
      // {SIZE:"Size To be Defined",TOTAL:"test",FAB:"T",DEL:22585,INST:22585,REM:22585},
      // {SIZE:"Total ISO REGISTERED",TOTAL:"test",FAB:"T",DEL:22585,INST:22585,REM:22585},

      {DISC:"ELE",DISC_DESCR:"Electrical",TOTAL:1775,OPEN:938,CLOSED:837,PROG:47.2,VOL:51.8},
      {DISC:"MEC",DISC_DESCR:"Mechanical",TOTAL:348,OPEN:193,CLOSED:155,PROG:44.5,VOL:10.7},
      {DISC:"INT",DISC_DESCR:"Instrumemt",TOTAL:692,OPEN:471,CLOSED:221,PROG:31.9,VOL:26},
      {DISC:"HVAC",DISC_DESCR:"HVAC",TOTAL:199,OPEN:192,CLOSED:7,PROG:3.5,VOL:10.6},
      {DISC:"OTH",DISC_DESCR:"Other",TOTAL:8,OPEN:8,CLOSED:0,PROG:0,VOL:0.4},
      {DISC:"PIP",DISC_DESCR:"Piping",TOTAL:10,OPEN:10,CLOSED:0,PROG:0,VOL:0.6}
    ],
}