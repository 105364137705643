import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 600,
      CanvasHeight: 250,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     

    DataItems: [
      { TYPE: 'OVERALL',  TOTAL: 1444,    ACTUAL: 210,    REMAIN: 1234,   PROG: 14.54, },
      { TYPE: 'QUARTERS', TOTAL: 43742,   ACTUAL: 25361,  REMAIN: 18381,  PROG: 57.98, },
      { TYPE: 'SHI',      TOTAL: 43,      ACTUAL: 25   ,  REMAIN: 18,     PROG: 57,    },
    ],

    


}