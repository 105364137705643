import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 570,
      CanvasHeight: 300,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

    DataItems: [
      {NO:1,QCF:"P01A",QCF_DESCR:"Pre-Test Line Walk(P01A)",TOTAL:2856,ACTUAL:2092,REMAIN:764,PROG:73.2},
      {NO:2,QCF:"P03A",QCF_DESCR:"Piping Pressure Test Checksheet(P03A)",TOTAL:2856,ACTUAL:1359,REMAIN:1497,PROG:47.6},
      {NO:3,QCF:"IC01A",QCF_DESCR:"Piping / Equipment Internal Cleaning Inspection(IC01A)",TOTAL:2856,ACTUAL:1341,REMAIN:1515,PROG:47},
      {NO:4,QCF:"P05A",QCF_DESCR:"Test Pack Reinstatement(P05A)",TOTAL:2856,ACTUAL:752,REMAIN:2104,PROG:26.3},
      {NO:5,QCF:"P06A",QCF_DESCR:"Joint Integrity Certificate(P06A)",TOTAL:2856,ACTUAL:752,REMAIN:2104,PROG:26.3},
      {NO:6,QCF:"P10A",QCF_DESCR:"Gross Air Leak Test(P10A)",TOTAL:200,ACTUAL:22,REMAIN:178,PROG:11},
      {NO:7,QCF:"P11A",QCF_DESCR:"CRITICAL NON RETURN VALVE INSPECTION - CONSTRUCTION(P11A)",TOTAL:16,ACTUAL:0,REMAIN:16,PROG:0},
      {NO:8,QCF:"M04A",QCF_DESCR:"Inline Strainer / Filter(M04A)",TOTAL:57,ACTUAL:4,REMAIN:53,PROG:7},
      {NO:9,QCF:"W110A",QCF_DESCR:"Non-Metallic Piping Joints Report Check(W110A)",TOTAL:70,ACTUAL:56,REMAIN:14,PROG:80}],
}