import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 400,
      CanvasHeight: 300,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },
    DataItems:    [
      {ACONEX_LATEST_CALC_REVIEW_STATUS:"1 - Reviewed w/ No Comments",ACONEX:593},
      {ACONEX_LATEST_CALC_REVIEW_STATUS:"2 - Reviewed w/ Minor Comments - Revise & Issue",ACONEX:1576},
      {ACONEX_LATEST_CALC_REVIEW_STATUS:"3 - Reviewed w/ Major Comments - Revise & Resubmit",ACONEX:210},
      {ACONEX_LATEST_CALC_REVIEW_STATUS:"4 - Not Accepted - Revise & Resubmit",ACONEX:239},
      {ACONEX_LATEST_CALC_REVIEW_STATUS:"5 - Not Reviewed - Information Only",ACONEX:1430},
      {ACONEX_LATEST_CALC_REVIEW_STATUS:"A - Reviewed w/ No Comments",ACONEX:6},
      {ACONEX_LATEST_CALC_REVIEW_STATUS:"B - Reviewed & Responded",ACONEX:121},
      {ACONEX_LATEST_CALC_REVIEW_STATUS:"C - Company Responded & Contractor to Respond",ACONEX:2},
      {ACONEX_LATEST_CALC_REVIEW_STATUS:"Review in Progress",ACONEX:280},
      {ACONEX_LATEST_CALC_REVIEW_STATUS:"Terminated",ACONEX:77},
      {ACONEX_LATEST_CALC_REVIEW_STATUS:"TOTAL",ACONEX:4534}
    ],
}