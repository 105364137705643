import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 620,
    CanvasHeight: 320,
    CanvasBorderWeight: 0.1,
    CanvasBorderColor: '#e0e0e0',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.2,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 380,
    CanvasChartHeight: 280,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  Text: {
    Text: [
      { text: '', x:20, y: 10, font: 'roboto', class: 'regular', size: 12, color: '#424242', align: 'start' },
    ],
    Filter: [
      { name: 'MOD'   , x: -50, y: 20, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'start' },
    ],
    Sql: [
      {
        query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
        text: 'Data Source',
        x: -30,
        y: 20,
        font: 'roboto',
        style: 'regular',
        size: 11,
        color: '#44A9DF',
        align: 'start'
      },
    ]
  },
  Queries: {
    SQL1: [
      {TOTAL:18567,COMPLETE:6091,REMAIN:12476,PROG:32.8}
    ],
    SQL2:  [
      {WK_NO:"W2022_11"}
    ],
  },
  DataItems: [
    {WK_NO:"W2021_19",CUTOFF:"2021-05-13",PLAN:0,ACTUAL:0,REMAIN:0},
    {WK_NO:"W2021_20",CUTOFF:"2021-05-20",PLAN:0,ACTUAL:0,REMAIN:0},
    {WK_NO:"W2021_21",CUTOFF:"2021-05-27",PLAN:1,ACTUAL:0,REMAIN:1},
    {WK_NO:"W2021_22",CUTOFF:"2021-06-03",PLAN:1,ACTUAL:0,REMAIN:1},
    {WK_NO:"W2021_23",CUTOFF:"2021-06-10",PLAN:1,ACTUAL:0,REMAIN:1},
    {WK_NO:"W2021_24",CUTOFF:"2021-06-17",PLAN:1,ACTUAL:0,REMAIN:1},
    {WK_NO:"W2021_25",CUTOFF:"2021-06-24",PLAN:1,ACTUAL:0,REMAIN:1},
    {WK_NO:"W2021_26",CUTOFF:"2021-07-01",PLAN:1,ACTUAL:0,REMAIN:1},
    {WK_NO:"W2021_27",CUTOFF:"2021-07-08",PLAN:1,ACTUAL:0,REMAIN:1},
    {WK_NO:"W2021_28",CUTOFF:"2021-07-15",PLAN:1,ACTUAL:0,REMAIN:1},
    {WK_NO:"W2021_29",CUTOFF:"2021-07-22",PLAN:1,ACTUAL:0,REMAIN:1},
    {WK_NO:"W2021_30",CUTOFF:"2021-07-29",PLAN:1,ACTUAL:0,REMAIN:1},
    {WK_NO:"W2021_31",CUTOFF:"2021-08-05",PLAN:1,ACTUAL:0,REMAIN:1},
    {WK_NO:"W2021_32",CUTOFF:"2021-08-12",PLAN:1,ACTUAL:0,REMAIN:1},
    {WK_NO:"W2021_33",CUTOFF:"2021-08-19",PLAN:1,ACTUAL:0,REMAIN:1},
    {WK_NO:"W2021_34",CUTOFF:"2021-08-26",PLAN:1,ACTUAL:0,REMAIN:1},
    {WK_NO:"W2021_35",CUTOFF:"2021-09-02",PLAN:1,ACTUAL:0,REMAIN:1},
    {WK_NO:"W2021_36",CUTOFF:"2021-09-09",PLAN:1,ACTUAL:0,REMAIN:1},
    {WK_NO:"W2021_37",CUTOFF:"2021-09-16",PLAN:1,ACTUAL:0,REMAIN:1},
    {WK_NO:"W2021_38",CUTOFF:"2021-09-23",PLAN:1,ACTUAL:0,REMAIN:1},
    {WK_NO:"W2021_39",CUTOFF:"2021-09-30",PLAN:1,ACTUAL:0,REMAIN:1},
    {WK_NO:"W2021_40",CUTOFF:"2021-10-07",PLAN:1,ACTUAL:0,REMAIN:1},
    {WK_NO:"W2021_41",CUTOFF:"2021-10-14",PLAN:1,ACTUAL:0,REMAIN:1},
    {WK_NO:"W2021_42",CUTOFF:"2021-10-21",PLAN:2,ACTUAL:0,REMAIN:2},
    {WK_NO:"W2021_43",CUTOFF:"2021-10-28",PLAN:2,ACTUAL:0,REMAIN:2},
    {WK_NO:"W2021_44",CUTOFF:"2021-11-04",PLAN:2,ACTUAL:0,REMAIN:2},
    {WK_NO:"W2021_45",CUTOFF:"2021-11-11",PLAN:3,ACTUAL:0,REMAIN:3},
    {WK_NO:"W2021_46",CUTOFF:"2021-11-18",PLAN:3,ACTUAL:0,REMAIN:3},
    {WK_NO:"W2021_47",CUTOFF:"2021-11-25",PLAN:4,ACTUAL:0,REMAIN:4},
    {WK_NO:"W2021_48",CUTOFF:"2021-12-02",PLAN:5,ACTUAL:0,REMAIN:5}
  ],
}