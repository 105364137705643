import __C from '../../../../../../includes/primitives/_constant_'

import MasterProgress from './MasterProgress'
import AreaProgress from './AreaProgress'


export default {
    Canvas: {
      CanvasWidth: 1500,
      CanvasHeight: 1050,
      CanvasBorderWeight: 0.2,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 1,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

    Queries: {
      MasterProg: MasterProgress, // Status of Blocks
      AreaProg  : AreaProgress,
      SQL1:  [ // Block code
        { BLOCK: '0001', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '0002', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '0003', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '0004', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '0005', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '0006', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '0007', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '0008', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '0009', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '001', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '0010', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '0011', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '0012', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '0013', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '0014', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '0015', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '0016', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '002', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '0020', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '003', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '004', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '005', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '006', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '007', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '008', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '009', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '010', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '011', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '012', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '013', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '014', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '015', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '016', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '017', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '018', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '019', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '020', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '021', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '022', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '023', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '024', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '025', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '026', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '027', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '028', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '029', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '030', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '031', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '032', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '033', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '034', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '035', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '036', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '037', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '038', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '039', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '040', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '041', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '042', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '043', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '044', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '045', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '046', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '047', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '048', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '049', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '051', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '052', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '053', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '054', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '055', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '056', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '057', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '058', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '060', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '061', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '062', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '063', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '064', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '065', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '066', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '067', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '068', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '069', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '070', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '071', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '072', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '073', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '074', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '075', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '076', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '077', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '078', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '079', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '080', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '081', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '082', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '083', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '084', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '085', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '086', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '087', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '088', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '089', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '090', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '091', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '092', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '093', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '094', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '095', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '096', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '097', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '098', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '101', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '103', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '10B', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '10C', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '11C', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '123', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '124', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '125', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '126', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '12A', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '12C', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '133', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '134', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '135', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '136', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '139', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '13A', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '13C', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '143', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '14A', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '14C', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '153', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '15A', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '15C', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '180', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '190', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '1901', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '1902', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '1903', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '1904', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '1905', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '1906', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '1907', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '1908', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '1909', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '20A', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '20B', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '20C', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '20D', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '20E', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '20F', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '20G', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '20H', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '20I', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '20J', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '20K', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '20L', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '21A', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '21C', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '21E', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '21G', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '21H', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '21I', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '21J', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '21K', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '21L', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '21V', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '22A', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '22C', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '22E', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '22G', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '22H', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '22I', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '22J', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '22K', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '22L', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '22V', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '23V', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '24V', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '25V', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '26F', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '26V', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '27F', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '280', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '28F', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '290', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '2901', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '2902', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '2903', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '2904', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '2905', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '2906', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '2907', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '2908', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '29F', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '301', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '302', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '303', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '304', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '305', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '306', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '30A', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '30C', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '30E', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '30G', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '30I', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '30K', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '311', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '312', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '313', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '314', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '315', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '316', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '31A', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '31C', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '31E', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '31G', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '31I', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '31K', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '3903', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '3904', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '3905', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '3906', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '3907', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '3908', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '40A', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '40B', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '40C', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '40D', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '40E', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '40F', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '40G', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '40H', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '40I', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '40J', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '40K', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '40L', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '4904', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '4905', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '4907', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '4908', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '50A', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '50C', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '50E', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '50G', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '50I', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '50K', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '51A', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '51C', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '51E', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '51G', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '51I', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '51K', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '5904', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '5905', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '5907', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '61A', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '61B', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '61F', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '62A', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '62B', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '62F', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '63A', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '63B', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '63F', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '64A', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '64B', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '64F', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '65A', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '65F', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '66A', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '66F', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '67A', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '67F', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '68A', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '68F', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '6907', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '69A', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '69F', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: '721', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '731', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '740', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '741', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '742', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '743', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '744', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '745', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '746', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '747', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '748', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '749', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '750', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '751', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '752', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '753', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '754', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '755', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '756', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '757', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '758', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '759', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '760', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '761', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '762', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '763', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '764', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '765', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '766', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '767', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '768', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '769', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '770', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '771', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '772', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '773', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '774', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '775', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '776', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '777', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '778', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '779', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '780', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '781', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '782', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '783', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '784', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '785', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '786', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '787', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '788', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '789', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '790', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '7907', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '791', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '80A', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '80B', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '80C', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '80D', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '80E', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '80F', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '80G', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '80H', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '80I', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '80J', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '80K', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '80L', BLOCK_TYPE: 'MEGA', STATUS: 'EREC', },
        { BLOCK: '841', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '842', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '843', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '844', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '845', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '846', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '847', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '851', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '852', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '853', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '854', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '855', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '856', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '857', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '881', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '882', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '883', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '884', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '885', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '886', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '887', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '8907', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '891', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '892', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '893', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '894', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '895', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '896', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '897', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '901', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '902', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '903', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '904', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '905', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '906', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '907', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '908', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '909', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '911', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '912', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '913', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '914', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '915', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '916', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '917', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '918', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '921', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '922', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '923', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '924', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '925', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '926', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '927', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '928', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '931', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '932', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '933', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '934', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '935', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '936', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '937', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '938', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '941', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '942', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '943', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '944', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '945', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '946', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '947', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '948', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '951', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '952', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '953', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '954', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '955', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '956', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '957', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '958', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '959', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '961', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '962', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '963', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '964', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '965', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '966', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '971', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '972', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '973', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: '9907', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '991', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '992', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '993', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '994', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '995', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '996', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: '997', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: 'E1D', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: 'E1M1', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: 'E2D', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: 'E2M1', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: 'E5A1', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: 'E5A2', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: 'E5B', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: 'E5C', BLOCK_TYPE: 'UNIT', STATUS: 'EREC', },
        { BLOCK: 'E5D', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: 'E5E', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: 'LDA1', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: 'LDA2', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: 'LDA3', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: 'ME01DA', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'ME01DB', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'ME01DC', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'ME02DA', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'ME02DB', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'ME02DC', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'ME04DA', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'ME04DB', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'MS1', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: 'MS2', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: 'MW01DA', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'MW01DB', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'MW01DC', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'MW01DD', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'MW02DA', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'MW02DB', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'MW02DC', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'MW02DD', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'MW04DA', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'MW04DD', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'MW05DA', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'MW05DB', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'MW05DC', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'TSRC01', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'TSRC02', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'TSRC03', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'TSRC04', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'TSRC05', BLOCK_TYPE: 'MEGA', STATUS: 'NULL', },
        { BLOCK: 'W1E', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: 'W2M1', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', },
        { BLOCK: 'W5M1', BLOCK_TYPE: 'UNIT', STATUS: 'NULL', }
        
      ],
      SQL2: //Status barchart
        [
        { LV1: 'HULL', LV2: 'HULL-AFT', LV3: 'AFT', LV4: 'AFT', PO_TOTAL: 73, PO_ACTUAL: 73, ONSITE_TOTAL: 73, ONSITE_ACTUAL: 48, INST_TOTAL: 48, INST_ACTUAL: 39, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 12, ETA_PAST_FROM_UPPERBLOCK: 9, },
        { LV1: 'HULL', LV2: 'HULL-FOR', LV3: 'FOR', LV4: 'FOR', PO_TOTAL: 66, PO_ACTUAL: 60, ONSITE_TOTAL: 60, ONSITE_ACTUAL: 57, INST_TOTAL: 57, INST_ACTUAL: 46, NOT_PO_CNT: 6, ETA_PAST_FROM_ROS: 0, ETA_PAST_FROM_UPPERBLOCK: 0, },
        { LV1: 'HULL', LV2: 'HULL-FOR', LV3: 'MS', LV4: 'MS01', PO_TOTAL: 56, PO_ACTUAL: 56, ONSITE_TOTAL: 56, ONSITE_ACTUAL: 52, INST_TOTAL: 52, INST_ACTUAL: 43, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 2, ETA_PAST_FROM_UPPERBLOCK: 0, },
        { LV1: 'HULL', LV2: 'HULL-FOR', LV3: 'MS', LV4: 'MS02', PO_TOTAL: 11, PO_ACTUAL: 10, ONSITE_TOTAL: 10, ONSITE_ACTUAL: 7, INST_TOTAL: 7, INST_ACTUAL: 5, NOT_PO_CNT: 1, ETA_PAST_FROM_ROS: 0, ETA_PAST_FROM_UPPERBLOCK: 0, },
        { LV1: 'HULL', LV2: 'HULL-FOR', LV3: 'MS', LV4: 'MS03', PO_TOTAL: 34, PO_ACTUAL: 34, ONSITE_TOTAL: 34, ONSITE_ACTUAL: 23, INST_TOTAL: 23, INST_ACTUAL: 14, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 6, ETA_PAST_FROM_UPPERBLOCK: 4, },
        { LV1: 'HULL', LV2: 'HULL-FOR', LV3: 'MS', LV4: 'MS04', PO_TOTAL: 29, PO_ACTUAL: 29, ONSITE_TOTAL: 29, ONSITE_ACTUAL: 25, INST_TOTAL: 25, INST_ACTUAL: 14, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 1, ETA_PAST_FROM_UPPERBLOCK: 2, },
        { LV1: 'HULL', LV2: 'LQ', LV3: 'LQ01', LV4: 'LQ01', PO_TOTAL: 14, PO_ACTUAL: 14, ONSITE_TOTAL: 14, ONSITE_ACTUAL: 12, INST_TOTAL: 12, INST_ACTUAL: 9, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 2, ETA_PAST_FROM_UPPERBLOCK: 2, },
        { LV1: 'HULL', LV2: 'LQ', LV3: 'LQ02', LV4: 'LQ02', PO_TOTAL: 27, PO_ACTUAL: 27, ONSITE_TOTAL: 27, ONSITE_ACTUAL: 23, INST_TOTAL: 23, INST_ACTUAL: 20, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 1, ETA_PAST_FROM_UPPERBLOCK: 1, },
        { LV1: 'HULL', LV2: 'LQ', LV3: 'LQ03', LV4: 'LQ03', PO_TOTAL: 22, PO_ACTUAL: 19, ONSITE_TOTAL: 19, ONSITE_ACTUAL: 19, INST_TOTAL: 19, INST_ACTUAL: 13, NOT_PO_CNT: 3, ETA_PAST_FROM_ROS: 0, ETA_PAST_FROM_UPPERBLOCK: 0, },
        { LV1: 'HULL', LV2: 'LQ', LV3: 'LQ04', LV4: 'LQ04', PO_TOTAL: 7, PO_ACTUAL: 7, ONSITE_TOTAL: 7, ONSITE_ACTUAL: 4, INST_TOTAL: 4, INST_ACTUAL: 4, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 2, ETA_PAST_FROM_UPPERBLOCK: 2, },
        { LV1: 'HULL', LV2: 'LQ', LV3: 'LQ05', LV4: 'LQ05', PO_TOTAL: 1, PO_ACTUAL: 1, ONSITE_TOTAL: 1, ONSITE_ACTUAL: 1, INST_TOTAL: 1, INST_ACTUAL: 1, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 0, ETA_PAST_FROM_UPPERBLOCK: 0, },
        { LV1: 'HULL', LV2: 'LQ', LV3: 'LQ06', LV4: 'LQ06', PO_TOTAL: 22, PO_ACTUAL: 22, ONSITE_TOTAL: 22, ONSITE_ACTUAL: 16, INST_TOTAL: 16, INST_ACTUAL: 22, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 4, ETA_PAST_FROM_UPPERBLOCK: 1, },
        { LV1: 'HULL', LV2: 'LQ', LV3: 'LQ07', LV4: 'LQ07', PO_TOTAL: 5, PO_ACTUAL: 5, ONSITE_TOTAL: 5, ONSITE_ACTUAL: 5, INST_TOTAL: 5, INST_ACTUAL: 5, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 0, ETA_PAST_FROM_UPPERBLOCK: 0, },
        { LV1: 'TOPSIDES', LV2: 'COMMON', LV3: 'FL01', LV4: 'FL01', PO_TOTAL: 5, PO_ACTUAL: 5, ONSITE_TOTAL: 5, ONSITE_ACTUAL: 5, INST_TOTAL: 5, INST_ACTUAL: 4, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 0, ETA_PAST_FROM_UPPERBLOCK: 0, },
        { LV1: 'TOPSIDES', LV2: 'COMMON', LV3: 'KO01', LV4: 'KO01', PO_TOTAL: 8, PO_ACTUAL: 8, ONSITE_TOTAL: 8, ONSITE_ACTUAL: 8, INST_TOTAL: 8, INST_ACTUAL: 3, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 0, ETA_PAST_FROM_UPPERBLOCK: 0, },
        { LV1: 'TOPSIDES', LV2: 'COMMON', LV3: 'LD00DA', LV4: 'LD00DA', PO_TOTAL: 19, PO_ACTUAL: 19, ONSITE_TOTAL: 19, ONSITE_ACTUAL: 19, INST_TOTAL: 19, INST_ACTUAL: 9, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 0, ETA_PAST_FROM_UPPERBLOCK: 0, },
        { LV1: 'TOPSIDES', LV2: 'MODULES', LV3: 'ME01', LV4: 'ME01DA', PO_TOTAL: 14, PO_ACTUAL: 14, ONSITE_TOTAL: 14, ONSITE_ACTUAL: 14, INST_TOTAL: 14, INST_ACTUAL: 12, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 0, ETA_PAST_FROM_UPPERBLOCK: 0, },
        { LV1: 'TOPSIDES', LV2: 'MODULES', LV3: 'ME01', LV4: 'ME01DB', PO_TOTAL: 12, PO_ACTUAL: 12, ONSITE_TOTAL: 12, ONSITE_ACTUAL: 12, INST_TOTAL: 12, INST_ACTUAL: 9, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 0, ETA_PAST_FROM_UPPERBLOCK: 0, },
        { LV1: 'TOPSIDES', LV2: 'MODULES', LV3: 'ME01', LV4: 'ME01DC', PO_TOTAL: 4, PO_ACTUAL: 4, ONSITE_TOTAL: 4, ONSITE_ACTUAL: 4, INST_TOTAL: 4, INST_ACTUAL: 3, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 0, ETA_PAST_FROM_UPPERBLOCK: 0, },
        { LV1: 'TOPSIDES', LV2: 'MODULES', LV3: 'ME02', LV4: 'ME02DA', PO_TOTAL: 20, PO_ACTUAL: 20, ONSITE_TOTAL: 20, ONSITE_ACTUAL: 20, INST_TOTAL: 20, INST_ACTUAL: 9, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 0, ETA_PAST_FROM_UPPERBLOCK: 0, },
        { LV1: 'TOPSIDES', LV2: 'MODULES', LV3: 'ME02', LV4: 'ME02DB', PO_TOTAL: 11, PO_ACTUAL: 11, ONSITE_TOTAL: 11, ONSITE_ACTUAL: 11, INST_TOTAL: 11, INST_ACTUAL: 6, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 0, ETA_PAST_FROM_UPPERBLOCK: 0, },
        { LV1: 'TOPSIDES', LV2: 'MODULES', LV3: 'ME02', LV4: 'ME02DC', PO_TOTAL: 7, PO_ACTUAL: 7, ONSITE_TOTAL: 7, ONSITE_ACTUAL: 7, INST_TOTAL: 7, INST_ACTUAL: 5, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 0, ETA_PAST_FROM_UPPERBLOCK: 0, },
        { LV1: 'TOPSIDES', LV2: 'MODULES', LV3: 'ME04', LV4: 'ME04DA', PO_TOTAL: 17, PO_ACTUAL: 17, ONSITE_TOTAL: 17, ONSITE_ACTUAL: 17, INST_TOTAL: 17, INST_ACTUAL: 12, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 0, ETA_PAST_FROM_UPPERBLOCK: 0, },
        { LV1: 'TOPSIDES', LV2: 'MODULES', LV3: 'ME04', LV4: 'ME04DB', PO_TOTAL: 10, PO_ACTUAL: 10, ONSITE_TOTAL: 10, ONSITE_ACTUAL: 10, INST_TOTAL: 10, INST_ACTUAL: 7, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 0, ETA_PAST_FROM_UPPERBLOCK: 0, },
        { LV1: 'TOPSIDES', LV2: 'MODULES', LV3: 'ME05', LV4: 'ME05DA', PO_TOTAL: 32, PO_ACTUAL: 32, ONSITE_TOTAL: 32, ONSITE_ACTUAL: 28, INST_TOTAL: 28, INST_ACTUAL: 18, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 4, ETA_PAST_FROM_UPPERBLOCK: 4, },
        { LV1: 'TOPSIDES', LV2: 'MODULES', LV3: 'ME05', LV4: 'ME05DB', PO_TOTAL: 32, PO_ACTUAL: 32, ONSITE_TOTAL: 32, ONSITE_ACTUAL: 32, INST_TOTAL: 32, INST_ACTUAL: 20, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 0, ETA_PAST_FROM_UPPERBLOCK: 0, },
        { LV1: 'TOPSIDES', LV2: 'MODULES', LV3: 'ME05', LV4: 'ME05DC', PO_TOTAL: 44, PO_ACTUAL: 44, ONSITE_TOTAL: 44, ONSITE_ACTUAL: 37, INST_TOTAL: 37, INST_ACTUAL: 22, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 7, ETA_PAST_FROM_UPPERBLOCK: 3, },
        { LV1: 'TOPSIDES', LV2: 'MODULES', LV3: 'ME05', LV4: 'ME05DD', PO_TOTAL: 2, PO_ACTUAL: 2, ONSITE_TOTAL: 2, ONSITE_ACTUAL: 2, INST_TOTAL: 2, INST_ACTUAL: 2, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 0, ETA_PAST_FROM_UPPERBLOCK: 0, },
        { LV1: 'TOPSIDES', LV2: 'MODULES', LV3: 'ME05', LV4: 'ME05DE', PO_TOTAL: 6, PO_ACTUAL: 6, ONSITE_TOTAL: 6, ONSITE_ACTUAL: 6, INST_TOTAL: 6, INST_ACTUAL: 1, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 0, ETA_PAST_FROM_UPPERBLOCK: 0, },
        { LV1: 'TOPSIDES', LV2: 'MODULES', LV3: 'MW01', LV4: 'MW01DA', PO_TOTAL: 12, PO_ACTUAL: 12, ONSITE_TOTAL: 12, ONSITE_ACTUAL: 12, INST_TOTAL: 12, INST_ACTUAL: 8, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 0, ETA_PAST_FROM_UPPERBLOCK: 0, },
        { LV1: 'TOPSIDES', LV2: 'MODULES', LV3: 'MW01', LV4: 'MW01DB', PO_TOTAL: 6, PO_ACTUAL: 6, ONSITE_TOTAL: 6, ONSITE_ACTUAL: 6, INST_TOTAL: 6, INST_ACTUAL: 3, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 0, ETA_PAST_FROM_UPPERBLOCK: 0, },
        { LV1: 'TOPSIDES', LV2: 'MODULES', LV3: 'MW01', LV4: 'MW01DC', PO_TOTAL: 7, PO_ACTUAL: 7, ONSITE_TOTAL: 7, ONSITE_ACTUAL: 7, INST_TOTAL: 7, INST_ACTUAL: 4, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 0, ETA_PAST_FROM_UPPERBLOCK: 0, },
        { LV1: 'TOPSIDES', LV2: 'MODULES', LV3: 'MW01', LV4: 'MW01DD', PO_TOTAL: 4, PO_ACTUAL: 4, ONSITE_TOTAL: 4, ONSITE_ACTUAL: 4, INST_TOTAL: 4, INST_ACTUAL: 2, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 0, ETA_PAST_FROM_UPPERBLOCK: 0, },
        { LV1: 'TOPSIDES', LV2: 'MODULES', LV3: 'MW02', LV4: 'MW02DA', PO_TOTAL: 19, PO_ACTUAL: 19, ONSITE_TOTAL: 19, ONSITE_ACTUAL: 18, INST_TOTAL: 18, INST_ACTUAL: 10, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 1, ETA_PAST_FROM_UPPERBLOCK: 1, },
        { LV1: 'TOPSIDES', LV2: 'MODULES', LV3: 'MW02', LV4: 'MW02DB', PO_TOTAL: 6, PO_ACTUAL: 6, ONSITE_TOTAL: 6, ONSITE_ACTUAL: 0, INST_TOTAL: 0, INST_ACTUAL: 0, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 1, ETA_PAST_FROM_UPPERBLOCK: 1, },
        { LV1: 'TOPSIDES', LV2: 'MODULES', LV3: 'MW02', LV4: 'MW02DC', PO_TOTAL: 9, PO_ACTUAL: 9, ONSITE_TOTAL: 9, ONSITE_ACTUAL: 9, INST_TOTAL: 9, INST_ACTUAL: 7, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 0, ETA_PAST_FROM_UPPERBLOCK: 0, },
        { LV1: 'TOPSIDES', LV2: 'MODULES', LV3: 'MW02', LV4: 'MW02DD', PO_TOTAL: 4, PO_ACTUAL: 4, ONSITE_TOTAL: 4, ONSITE_ACTUAL: 3, INST_TOTAL: 3, INST_ACTUAL: 3, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 1, ETA_PAST_FROM_UPPERBLOCK: 1, },
        { LV1: 'TOPSIDES', LV2: 'MODULES', LV3: 'MW04', LV4: 'MW04DA', PO_TOTAL: 13, PO_ACTUAL: 13, ONSITE_TOTAL: 13, ONSITE_ACTUAL: 12, INST_TOTAL: 12, INST_ACTUAL: 8, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 1, ETA_PAST_FROM_UPPERBLOCK: 1, },
        { LV1: 'TOPSIDES', LV2: 'MODULES', LV3: 'MW04', LV4: 'MW04DB', PO_TOTAL: 10, PO_ACTUAL: 10, ONSITE_TOTAL: 10, ONSITE_ACTUAL: 10, INST_TOTAL: 10, INST_ACTUAL: 5, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 0, ETA_PAST_FROM_UPPERBLOCK: 0, },
        { LV1: 'TOPSIDES', LV2: 'MODULES', LV3: 'MW05', LV4: 'MW05DA', PO_TOTAL: 16, PO_ACTUAL: 16, ONSITE_TOTAL: 16, ONSITE_ACTUAL: 13, INST_TOTAL: 13, INST_ACTUAL: 9, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 3, ETA_PAST_FROM_UPPERBLOCK: 1, },
        { LV1: 'TOPSIDES', LV2: 'MODULES', LV3: 'MW05', LV4: 'MW05DB', PO_TOTAL: 12, PO_ACTUAL: 12, ONSITE_TOTAL: 12, ONSITE_ACTUAL: 12, INST_TOTAL: 12, INST_ACTUAL: 2, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 0, ETA_PAST_FROM_UPPERBLOCK: 0, },
        { LV1: 'TOPSIDES', LV2: 'MODULES', LV3: 'MW05', LV4: 'MW05DC', PO_TOTAL: 10, PO_ACTUAL: 10, ONSITE_TOTAL: 10, ONSITE_ACTUAL: 10, INST_TOTAL: 10, INST_ACTUAL: 8, NOT_PO_CNT: 0, ETA_PAST_FROM_ROS: 0, ETA_PAST_FROM_UPPERBLOCK: 0, },
        
      ],
    },

    Text: {
      Text: [
        { text: 'Note', x: 1350, y: 20, font: 'roboto', class: 'regular', size: 11, color: '#44A9DF', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: 1350, y: 30, font: 'roboto', class: 'regular', size: 11, color: '#757575', align: 'start' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: 32,
          y: 100,
          font: 'roboto',
          style: 'regular',
          size: 10,
          color: '#757575',
          align: 'start'
        },
      ]
    },

    DataItems: [
      {NO:323,TAG:"21-AB-992401",TAG_DESCR:"21-AB-992401",PARENT_TAG:"21-AB-992401",WEIGHT:370,SN:9,LV1:"HULL",LV2:"LQ",LV3:"LQ03",LV4:"LQ03",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV2"},
      {NO:325,TAG:"21-AB-992402",TAG_DESCR:"21-AB-992402",PARENT_TAG:"21-AB-992402",WEIGHT:50,SN:11,LV1:"HULL",LV2:"LQ",LV3:"LQ03",LV4:"LQ03",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV2"},
      {NO:324,TAG:"21-AB-992403",TAG_DESCR:"21-AB-992403",PARENT_TAG:"21-AB-992403",WEIGHT:50,SN:10,LV1:"HULL",LV2:"LQ",LV3:"LQ03",LV4:"LQ03",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV2"},
      {NO:333,TAG:"21-AR-992403",TAG_DESCR:"21-AR-992403",PARENT_TAG:"21-AR-992403",DIMENSION:"1180*658*883",WEIGHT:180,SN:19,LV1:"HULL",LV2:"LQ",LV3:"LQ03",LV4:"LQ03",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV2"},
      {NO:332,TAG:"21-AR-992405",TAG_DESCR:"21-AR-992405",PARENT_TAG:"21-AR-992405",SN:18,LV1:"HULL",LV2:"LQ",LV3:"LQ03",LV4:"LQ03",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV2"},
      {NO:326,TAG:"21-AR-992407",TAG_DESCR:"21-AR-992407",PARENT_TAG:"21-AR-992407",DIMENSION:"1180*658*883",WEIGHT:180,SN:12,LV1:"HULL",LV2:"LQ",LV3:"LQ03",LV4:"LQ03",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV2"},
      {NO:336,TAG:"21-AR-992415",TAG_DESCR:"21-AR-992415",PARENT_TAG:"21-AR-992415",SN:22,LV1:"HULL",LV2:"LQ",LV3:"LQ03",LV4:"LQ03",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV2"},
      {NO:334,TAG:"21-AR-992416",TAG_DESCR:"21-AR-992416",PARENT_TAG:"21-AR-992416",SN:20,LV1:"HULL",LV2:"LQ",LV3:"LQ03",LV4:"LQ03",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV2"},
      {NO:669,TAG:"21-AT-3330003",TAG_DESCR:"Betex Analyzer Transmitter",PARENT_TAG:"21-A-3330003",WEIGHT:0,SN:1,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-01-19",ETA_ACTUAL:"",ROS:"2020-11-15",ROS_ETA_DELTA:-65,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DC"},
      {NO:675,TAG:"21-AT-3330051",TAG_DESCR:"Betex Analyzer Transmitter",PARENT_TAG:"21-A-3330051",WEIGHT:0,SN:7,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-01-19",ETA_ACTUAL:"",ROS:"2020-11-15",ROS_ETA_DELTA:-65,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DC"},
      {NO:674,TAG:"21-AT-3330052",TAG_DESCR:"Analyzer Transmitter Generic",PARENT_TAG:"21-A-3330052",WEIGHT:0,SN:6,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-01-19",ETA_ACTUAL:"",ROS:"2020-11-15",ROS_ETA_DELTA:-65,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DC"},
      {NO:372,TAG:"21-AVH-543001",TAG_DESCR:"21-AVH-543001",PARENT_TAG:"21-AVH-543001",SN:1,POSITION:"R",LV1:"TOPSIDES",LV2:"COMMON",LV3:"FL01",LV4:"FL01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO"},
      {NO:373,TAG:"21-AVH-543002",TAG_DESCR:"21-AVH-543002",PARENT_TAG:"21-AVH-543002",SN:2,POSITION:"L",LV1:"TOPSIDES",LV2:"COMMON",LV3:"FL01",LV4:"FL01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO"},
      {NO:374,TAG:"21-AVH-543003",TAG_DESCR:"21-AVH-543003",PARENT_TAG:"21-AVH-543003",SN:3,POSITION:"L",LV1:"TOPSIDES",LV2:"COMMON",LV3:"FL01",LV4:"FL01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO"},
      {NO:565,TAG:"21-DSH-6800006",TAG_DESCR:"Hssd Panel",PARENT_TAG:"21-DSH-6800006",SN:3,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:566,TAG:"21-DSH-6800007",TAG_DESCR:"Hssd Panel",PARENT_TAG:"21-DSH-6800007",SN:4,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:567,TAG:"21-DSH-6800008",TAG_DESCR:"Hssd Panel",PARENT_TAG:"21-DSH-6800008",SN:5,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:104,TAG:"21-EBA-671001A",TAG_DESCR:"21-EBA-671001A",PARENT_TAG:"21-EBA-671001A",SN:31,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:106,TAG:"21-EBA-671001B",TAG_DESCR:"21-EBA-671001B",PARENT_TAG:"21-EBA-671001B",SN:33,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:105,TAG:"21-EBA-671003",TAG_DESCR:"21-EBA-671003",PARENT_TAG:"21-EBA-671003",WEIGHT:0,SN:32,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-16",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-62,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:365,TAG:"21-EBA-671004",TAG_DESCR:"21-EBA-671004",PARENT_TAG:"21-EBA-671004",WEIGHT:0,SN:21,LV1:"HULL",LV2:"LQ",LV3:"LQ06",LV4:"LQ06",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-16",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-62,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5"},
      {NO:107,TAG:"21-EBA-671005",TAG_DESCR:"21-EBA-671005",PARENT_TAG:"21-EBA-671005",SN:34,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:518,TAG:"21-EBA-671010",TAG_DESCR:"21-EBA-671010",PARENT_TAG:"21-EBA-671010",WEIGHT:0,SN:20,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-16",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-62,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L1"},
      {NO:511,TAG:"21-EBA-671011",TAG_DESCR:"21-EBA-671011",PARENT_TAG:"21-EBA-671011",WEIGHT:0,SN:13,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-16",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-62,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L1"},
      {NO:512,TAG:"21-EBA-671012",TAG_DESCR:"21-EBA-671012",PARENT_TAG:"21-EBA-671012",WEIGHT:0,SN:14,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-16",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-62,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L1"},
      {NO:517,TAG:"21-EBA-671013",TAG_DESCR:"21-EBA-671013",PARENT_TAG:"21-EBA-671013",WEIGHT:0,SN:19,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-16",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-62,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L1"},
      {NO:513,TAG:"21-EBA-861101",TAG_DESCR:"Nickel‐Cadmium",PARENT_TAG:"21-Z-861101",SN:15,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L1"},
      {NO:514,TAG:"21-EBA-861201",TAG_DESCR:"Nickel‐Cadmium",PARENT_TAG:"21-Z-861201",SN:16,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L1"},
      {NO:515,TAG:"21-EBA-861301",TAG_DESCR:"Nickel‐Cadmium",PARENT_TAG:"21-Z-861301",SN:17,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L1"},
      {NO:516,TAG:"21-EBA-861401",TAG_DESCR:"Nickel‐Cadmium",PARENT_TAG:"21-Z-861401",SN:18,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L1"},
      {NO:553,TAG:"21-EBC-861101",TAG_DESCR:"Battery Charger 120 Vdc Accessory",PARENT_TAG:"21-Z-861101",SN:23,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L2"},
      {NO:552,TAG:"21-EBC-861201",TAG_DESCR:"Battery Charger 120 Vdc Accessory",PARENT_TAG:"21-Z-861201",SN:22,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L2"},
      {NO:551,TAG:"21-EBC-861301",TAG_DESCR:"Battery Charger 120 Vdc Accessory",PARENT_TAG:"21-Z-861301",SN:21,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L2"},
      {NO:550,TAG:"21-EBC-861401",TAG_DESCR:"Battery Charger 120 Vdc Accessory",PARENT_TAG:"21-Z-861401",SN:20,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L2"},
      {NO:289,TAG:"21-ECP-605011",TAG_DESCR:"21-ECP-605011",PARENT_TAG:"21-ECP-605011",WEIGHT:0,SN:2,LV1:"HULL",LV2:"LQ",LV3:"LQ02",LV4:"LQ02",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-12-25",ETA_ACTUAL:"",ROS:"2020-10-09",ROS_ETA_DELTA:-77,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",ROOM:"CER"},
      {NO:363,TAG:"21-ECP-605023",TAG_DESCR:"21-ECP-605023",PARENT_TAG:"21-ECP-605023",WEIGHT:0,SN:19,LV1:"HULL",LV2:"LQ",LV3:"LQ06",LV4:"LQ06",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-12-25",ETA_ACTUAL:"",ROS:"2021-03-27",ROS_ETA_DELTA:92,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5"},
      {NO:539,TAG:"21-ECP-605041",TAG_DESCR:"21-ECP-605041",PARENT_TAG:"21-ECP-605041",WEIGHT:0,SN:9,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-12-25",ETA_ACTUAL:"",ROS:"2020-10-09",ROS_ETA_DELTA:-77,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L2"},
      {NO:536,TAG:"21-ECP-605042",TAG_DESCR:"21-ECP-605042",PARENT_TAG:"21-ECP-605042",WEIGHT:0,SN:6,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-12-25",ETA_ACTUAL:"",ROS:"2021-03-27",ROS_ETA_DELTA:92,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L2"},
      {NO:537,TAG:"21-ECP-605043",TAG_DESCR:"21-ECP-605043",PARENT_TAG:"21-ECP-605043",WEIGHT:0,SN:7,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-12-25",ETA_ACTUAL:"",ROS:"2020-10-09",ROS_ETA_DELTA:-77,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L2"},
      {NO:136,TAG:"21-ECP-661001",TAG_DESCR:"21-ECP-661001",PARENT_TAG:"21-ECP-661001",SN:63,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:127,TAG:"21-EDB-662401",TAG_DESCR:"21-EDB-662401",PARENT_TAG:"21-EDB-662401",WEIGHT:0,SN:54,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-12-25",ETA_ACTUAL:"",ROS:"2021-01-28",ROS_ETA_DELTA:34,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:554,TAG:"21-EDB-662402",TAG_DESCR:"21-EDB-662402",PARENT_TAG:"21-EDB-662402",SN:24,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L2"},
      {NO:118,TAG:"21-EDB-667671",TAG_DESCR:"21-EDB-667671",PARENT_TAG:"21-EDB-667671",SN:45,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:353,TAG:"21-EDB-992701",TAG_DESCR:"21-EDB-992701",PARENT_TAG:"21-EDB-992701",SN:9,LV1:"HULL",LV2:"LQ",LV3:"LQ06",LV4:"LQ06",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5"},
      {NO:44,TAG:"21-EEH-247001",TAG_DESCR:"Warm Condensate Heater",PARENT_TAG:"21-EEH-247001",WEIGHT:817,SN:44,POSITION:"L",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-09",ETA_ACTUAL:"",ROS:"2020-12-17",ROS_ETA_DELTA:38,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:647,TAG:"21-EEH-333001",TAG_DESCR:"Dew Point Liquid Heater",PARENT_TAG:"21-EEH-333001",WEIGHT:0,SN:4,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-09",ETA_ACTUAL:"",ROS:"2020-12-17",ROS_ETA_DELTA:38,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DA"},
      {NO:635,TAG:"21-EEH-345001",TAG_DESCR:"Repressurisation Heater",PARENT_TAG:"21-EEH-345001",WEIGHT:0,SN:3,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",LV4:"MW01DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-09",ETA_ACTUAL:"",ROS:"2020-12-17",ROS_ETA_DELTA:38,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBS_LEVEL:"MW01DC"},
      {NO:19,TAG:"21-EEH-434001",TAG_DESCR:"Closed Drains Knockout Drum Heater",PARENT_TAG:"21-EEH-434001",WEIGHT:3000,SN:19,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-09",ETA_ACTUAL:"",ROS:"2020-12-17",ROS_ETA_DELTA:38,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:11,TAG:"21-EEH-501001A",TAG_DESCR:"Hp Flare Knockout Drum Heater A",PARENT_TAG:"21-EEH-501001A",WEIGHT:3000,SN:11,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-09",ETA_ACTUAL:"",ROS:"2020-12-17",ROS_ETA_DELTA:38,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:7,TAG:"21-EEH-501001B",TAG_DESCR:"Hp Flare Knockout Drum Heater B",PARENT_TAG:"21-EEH-501001B",WEIGHT:3000,SN:7,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-09",ETA_ACTUAL:"",ROS:"2020-12-17",ROS_ETA_DELTA:38,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:440,TAG:"21-EEH-813001A",TAG_DESCR:"Fuel Gas Super Heater A",PARENT_TAG:"21-EEH-813001A",WEIGHT:0,SN:7,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-09",ETA_ACTUAL:"",ROS:"2020-12-17",ROS_ETA_DELTA:38,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DA"},
      {NO:441,TAG:"21-EEH-813001B",TAG_DESCR:"Fuel Gas Super Heater B",PARENT_TAG:"21-EEH-813001B",WEIGHT:0,SN:8,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-09",ETA_ACTUAL:"",ROS:"2020-12-17",ROS_ETA_DELTA:38,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DA"},
      {NO:499,TAG:"21-EER-861101",TAG_DESCR:"Earthing Resistor Main Generator",PARENT_TAG:"21-Z-861101",SN:1,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L1"},
      {NO:500,TAG:"21-EER-861201",TAG_DESCR:"Earthing Resistor Main Generator",PARENT_TAG:"21-Z-861201",SN:2,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L1"},
      {NO:501,TAG:"21-EER-861301",TAG_DESCR:"Earthing Resistor Main Generator",PARENT_TAG:"21-Z-861301",SN:3,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L1"},
      {NO:502,TAG:"21-EER-861401",TAG_DESCR:"Earthing Resistor Main Generator",PARENT_TAG:"21-Z-861401",SN:4,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L1"},
      {NO:525,TAG:"21-EFD-256001A",TAG_DESCR:"Subsea Lean Meg Vfd",PARENT_TAG:"21-P-256001A",SN:27,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L1"},
      {NO:526,TAG:"21-EFD-256001B",TAG_DESCR:"Subsea Lean Meg Vfd",PARENT_TAG:"21-P-256001B",SN:28,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L1"},
      {NO:524,TAG:"21-EFD-304003",TAG_DESCR:"21-EFD-304003",PARENT_TAG:"21-EFD-304003",SN:26,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L1"},
      {NO:540,TAG:"21-EIP-870001",TAG_DESCR:"21-EIP-870001",PARENT_TAG:"21-EIP-870001",SN:10,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L2"},
      {NO:124,TAG:"21-EIP-870002",TAG_DESCR:"21-EIP-870002",PARENT_TAG:"21-EIP-870002",SN:51,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:109,TAG:"21-EIS-671001",TAG_DESCR:"21-EIS-671001",PARENT_TAG:"21-EIS-671001",WEIGHT:0,SN:36,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-16",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-62,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:108,TAG:"21-EIS-671002",TAG_DESCR:"21-EIS-671002",PARENT_TAG:"21-EIS-671002",WEIGHT:0,SN:35,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-16",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-62,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:110,TAG:"21-EIS-671003",TAG_DESCR:"21-EIS-671003",PARENT_TAG:"21-EIS-671003",WEIGHT:0,SN:37,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-16",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-62,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:366,TAG:"21-EIS-671004",TAG_DESCR:"21-EIS-671004",PARENT_TAG:"21-EIS-671004",WEIGHT:0,SN:22,LV1:"HULL",LV2:"LQ",LV3:"LQ06",LV4:"LQ06",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-16",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-62,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5"},
      {NO:111,TAG:"21-EIS-671005",TAG_DESCR:"21-EIS-671005",PARENT_TAG:"21-EIS-671005",SN:38,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:507,TAG:"21-EIS-671010",TAG_DESCR:"21-EIS-671010",PARENT_TAG:"21-EIS-671010",WEIGHT:0,SN:9,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-16",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-62,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L1"},
      {NO:510,TAG:"21-EIS-671011",TAG_DESCR:"21-EIS-671011",PARENT_TAG:"21-EIS-671011",WEIGHT:0,SN:12,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-16",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-62,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L1"},
      {NO:509,TAG:"21-EIS-671012",TAG_DESCR:"21-EIS-671012",PARENT_TAG:"21-EIS-671012",WEIGHT:0,SN:11,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-16",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-62,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L1"},
      {NO:508,TAG:"21-EIS-671013",TAG_DESCR:"21-EIS-671013",PARENT_TAG:"21-EIS-671013",WEIGHT:0,SN:10,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-16",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-62,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L1"},
      {NO:527,TAG:"21-EIS-861101",TAG_DESCR:"21-EIS-861101",PARENT_TAG:"21-EIS-861101",SN:29,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L1"},
      {NO:530,TAG:"21-EIS-861201",TAG_DESCR:"21-EIS-861201",PARENT_TAG:"21-EIS-861201",SN:32,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L1"},
      {NO:529,TAG:"21-EIS-861301",TAG_DESCR:"21-EIS-861301",PARENT_TAG:"21-EIS-861301",SN:31,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L1"},
      {NO:528,TAG:"21-EIS-861401",TAG_DESCR:"21-EIS-861401",PARENT_TAG:"21-EIS-861401",SN:30,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L1"},
      {NO:315,TAG:"21-ELP-662461",TAG_DESCR:"Emergency Lighting Panel - 05",PARENT_TAG:"21-ELP-662461",SN:1,LV1:"HULL",LV2:"LQ",LV3:"LQ03",LV4:"LQ03",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV2"},
      {NO:337,TAG:"21-ELP-662462",TAG_DESCR:"Emergency Lighting Panel - 06",PARENT_TAG:"21-ELP-662462",SN:1,LV1:"HULL",LV2:"LQ",LV3:"LQ04",LV4:"LQ04",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV3"},
      {NO:354,TAG:"21-ELP-662463",TAG_DESCR:"Emergency Lighting Panel - 07",PARENT_TAG:"21-ELP-662463",SN:10,LV1:"HULL",LV2:"LQ",LV3:"LQ06",LV4:"LQ06",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5"},
      {NO:119,TAG:"21-ELP-667660",TAG_DESCR:"Normal Lighting Panel - 05",PARENT_TAG:"21-ELP-667660",SN:46,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:283,TAG:"21-ELP-667661",TAG_DESCR:"Normal Lighting Panel - 06",PARENT_TAG:"21-ELP-667661",SN:10,LV1:"HULL",LV2:"LQ",LV3:"LQ01",LV4:"LQ01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"DECK A"},
      {NO:312,TAG:"21-ELP-667662",TAG_DESCR:"Normal Lighting Panel - 07",PARENT_TAG:"21-ELP-667662",SN:25,LV1:"HULL",LV2:"LQ",LV3:"LQ02",LV4:"LQ02",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1"},
      {NO:317,TAG:"21-ELP-667663",TAG_DESCR:"Normal Lighting Panel - 08",PARENT_TAG:"21-ELP-667663",SN:3,LV1:"HULL",LV2:"LQ",LV3:"LQ03",LV4:"LQ03",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV2"},
      {NO:338,TAG:"21-ELP-667664",TAG_DESCR:"Normal Lighting Panel - 09",PARENT_TAG:"21-ELP-667664",SN:2,LV1:"HULL",LV2:"LQ",LV3:"LQ04",LV4:"LQ04",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV3"},
      {NO:344,TAG:"21-ELP-667665",TAG_DESCR:"Normal Lighting Panel - 10",PARENT_TAG:"21-ELP-667665",SN:1,LV1:"HULL",LV2:"LQ",LV3:"LQ05",LV4:"LQ05",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV4"},
      {NO:356,TAG:"21-ELP-667666-",TAG_DESCR:"21-ELP-667666-",PARENT_TAG:"21-ELP-667666-",SN:12,LV1:"HULL",LV2:"LQ",LV3:"LQ06",LV4:"LQ06",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",ROOM:"TER"},
      {NO:117,TAG:"21-ELP-667672",TAG_DESCR:"21-ELP-667672",PARENT_TAG:"21-ELP-667672",SN:44,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:314,TAG:"21-ELP-667674",TAG_DESCR:"21-ELP-667674",PARENT_TAG:"21-ELP-667674",SN:27,LV1:"HULL",LV2:"LQ",LV3:"LQ02",LV4:"LQ02",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1"},
      {NO:281,TAG:"21-ELP-667675",TAG_DESCR:"ELP-667675",PARENT_TAG:"ELP-667675",SN:8,LV1:"HULL",LV2:"LQ",LV3:"LQ01",LV4:"LQ01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"DECK A"},
      {NO:313,TAG:"21-ELP-667677",TAG_DESCR:"21-ELP-667677",PARENT_TAG:"21-ELP-667677",SN:26,LV1:"HULL",LV2:"LQ",LV3:"LQ02",LV4:"LQ02",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1"},
      {NO:318,TAG:"21-ELP-667678",TAG_DESCR:"21-ELP-667678",PARENT_TAG:"21-ELP-667678",SN:4,LV1:"HULL",LV2:"LQ",LV3:"LQ03",LV4:"LQ03",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV2"},
      {NO:559,TAG:"21-EMC-861109",TAG_DESCR:"Motor Contactor Relay, Scavenging Pump Supply",PARENT_TAG:"21-Z-861101",SN:29,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L2"},
      {NO:555,TAG:"21-EMC-861112",TAG_DESCR:"Motor Contactor Relay, Dc Backup Lube Pump",PARENT_TAG:"21-Z-861101",SN:25,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L2"},
      {NO:560,TAG:"21-EMC-861209",TAG_DESCR:"Motor Contactor Relay, Scavenging Pump Supply",PARENT_TAG:"21-Z-861201",SN:30,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L2"},
      {NO:556,TAG:"21-EMC-861212",TAG_DESCR:"Dc Backup Lube Pump Motor Contactor Relay",PARENT_TAG:"21-Z-861201",SN:26,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L2"},
      {NO:561,TAG:"21-EMC-861309",TAG_DESCR:"Motor Contactor Relay, Scavenging Pump Supply",PARENT_TAG:"21-Z-861301",SN:31,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L2"},
      {NO:557,TAG:"21-EMC-861312",TAG_DESCR:"Dc Backup Lube Pump Motor Contactor Relay",PARENT_TAG:"21-Z-861301",SN:27,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L2"},
      {NO:562,TAG:"21-EMC-861409",TAG_DESCR:"Motor Contactor Relay, Scavenging Pump Supply",PARENT_TAG:"21-Z-861401",SN:32,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L2"},
      {NO:558,TAG:"21-EMC-861412",TAG_DESCR:"Dc Backup Lube Pump Motor Contactor Relay",PARENT_TAG:"21-Z-861401",SN:28,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L2"},
      {NO:133,TAG:"21-ESB-666001",TAG_DESCR:"21-ESB-666001",PARENT_TAG:"21-ESB-666001",WEIGHT:0,SN:60,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-12-25",ETA_ACTUAL:"",ROS:"2021-01-28",ROS_ETA_DELTA:34,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:520,TAG:"21-ESB-877001A",TAG_DESCR:"21-ESB-877001A",SN:22,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L1"},
      {NO:519,TAG:"21-ESB-877001B",TAG_DESCR:"21-ESB-877001B",SN:21,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L1"},
      {NO:523,TAG:"21-ETP-253001",TAG_DESCR:"21-ETP-253001",PARENT_TAG:"21-ETP-253001",WEIGHT:0,SN:25,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-04-19",ETA_ACTUAL:"",ROS:"2020-10-04",ROS_ETA_DELTA:-197,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L1"},
      {NO:549,TAG:"21-ETP-304013",TAG_DESCR:"21-ETP-304013",SN:19,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L2"},
      {NO:548,TAG:"21-ETP-304018",TAG_DESCR:"21-ETP-304018",SN:18,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L2"},
      {NO:543,TAG:"21-ETP-333003",TAG_DESCR:"Expander-Compressor Seal Gas Heater Thyristor Panel",PARENT_TAG:"21-Z-333001",SN:13,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L2"},
      {NO:544,TAG:"21-ETP-XXX104",TAG_DESCR:"21-ETP-XXX104",PARENT_TAG:"21-ETP-XXX104",SN:14,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L2"},
      {NO:545,TAG:"21-ETP-XXX105",TAG_DESCR:"21-ETP-XXX105",PARENT_TAG:"21-ETP-XXX105",SN:15,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L2"},
      {NO:546,TAG:"21-ETP-XXX115",TAG_DESCR:"21-ETP-XXX115",PARENT_TAG:"21-ETP-XXX115",SN:16,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L2"},
      {NO:547,TAG:"21-ETP-XXX116",TAG_DESCR:"21-ETP-XXX116",PARENT_TAG:"21-ETP-XXX116",SN:17,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L2"},
      {NO:541,TAG:"21-ETP-XXXX98",TAG_DESCR:"21-ETP-XXXX98",PARENT_TAG:"21-ETP-XXXX98",SN:11,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L2"},
      {NO:542,TAG:"21-ETP-XXXX99",TAG_DESCR:"21-ETP-XXXX99",PARENT_TAG:"21-ETP-XXXX99",SN:12,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L2"},
      {NO:120,TAG:"21-ETR-666001A",TAG_DESCR:"21-ETR-666001A",PARENT_TAG:"21-ETR-666001A",WEIGHT:0,SN:47,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-09-23",ETA_ACTUAL:"",ROS:"2020-12-21",ROS_ETA_DELTA:89,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:121,TAG:"21-ETR-666001B",TAG_DESCR:"21-ETR-666001B",PARENT_TAG:"21-ETR-666001B",WEIGHT:0,SN:48,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-09-23",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-39,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:503,TAG:"21-ETR-667304A",TAG_DESCR:"21-ETR-667304A",PARENT_TAG:"21-ETR-667304A",WEIGHT:0,SN:5,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-09-23",ETA_ACTUAL:"",ROS:"2020-12-21",ROS_ETA_DELTA:89,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L1"},
      {NO:505,TAG:"21-ETR-667304B",TAG_DESCR:"21-ETR-667304B",PARENT_TAG:"21-ETR-667304B",WEIGHT:0,SN:7,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-09-23",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-39,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L1"},
      {NO:504,TAG:"21-ETR-877001A",TAG_DESCR:"21-ETR-877001A",PARENT_TAG:"21-ETR-877001A",WEIGHT:0,SN:6,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-09-23",ETA_ACTUAL:"",ROS:"2020-12-21",ROS_ETA_DELTA:89,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L1"},
      {NO:506,TAG:"21-ETR-877001B",TAG_DESCR:"21-ETR-877001B",PARENT_TAG:"21-ETR-877001B",WEIGHT:0,SN:8,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-09-23",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-39,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L1"},
      {NO:123,TAG:"21-EUP-671001",TAG_DESCR:"21-EUP-671001",PARENT_TAG:"21-EUP-671001",WEIGHT:0,SN:50,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-16",ETA_ACTUAL:"",ROS:"2020-10-22",ROS_ETA_DELTA:6,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:128,TAG:"21-EUP-671002",TAG_DESCR:"21-EUP-671002",PARENT_TAG:"21-EUP-671002",WEIGHT:0,SN:55,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-16",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-62,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:130,TAG:"21-EUP-671003",TAG_DESCR:"21-EUP-671003",PARENT_TAG:"21-EUP-671003",WEIGHT:0,SN:57,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-16",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-62,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:364,TAG:"21-EUP-671004",TAG_DESCR:"21-EUP-671004",PARENT_TAG:"21-EUP-671004",WEIGHT:0,SN:20,LV1:"HULL",LV2:"LQ",LV3:"LQ06",LV4:"LQ06",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-16",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-62,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5"},
      {NO:116,TAG:"21-EUP-671005",TAG_DESCR:"21-EUP-671005",PARENT_TAG:"21-EUP-671005",SN:43,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:532,TAG:"21-EUP-671010",TAG_DESCR:"21-EUP-671010",PARENT_TAG:"21-EUP-671010",WEIGHT:0,SN:2,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-16",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-62,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L2"},
      {NO:534,TAG:"21-EUP-671011",TAG_DESCR:"21-EUP-671011",PARENT_TAG:"21-EUP-671011",WEIGHT:0,SN:4,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-16",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-62,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L2"},
      {NO:533,TAG:"21-EUP-671012",TAG_DESCR:"21-EUP-671012",PARENT_TAG:"21-EUP-671012",WEIGHT:0,SN:3,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-16",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-62,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L2"},
      {NO:535,TAG:"21-EUP-671013",TAG_DESCR:"21-EUP-671013",PARENT_TAG:"21-EUP-671013",WEIGHT:0,SN:5,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-16",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-62,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L2"},
      {NO:437,TAG:"21-F-224001A",TAG_DESCR:"Condensate Coalescer Filter",PARENT_TAG:"21-Z-224001",WEIGHT:0,SN:4,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-04",ETA_ACTUAL:"",ROS:"2020-09-15",ROS_ETA_DELTA:-19,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DA"},
      {NO:436,TAG:"21-F-224001B",TAG_DESCR:"Condensate Coalescer Filter",PARENT_TAG:"21-Z-224001",WEIGHT:0,SN:3,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-04",ETA_ACTUAL:"",ROS:"2020-09-15",ROS_ETA_DELTA:-19,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DA"},
      {NO:86,TAG:"21-F-256001A",TAG_DESCR:"Filter: Lean Meg Filter A",PARENT_TAG:"21-F-256001A",WEIGHT:1500,SN:13,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-06",ETA_ACTUAL:"",ROS:"2020-10-23",ROS_ETA_DELTA:17,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03"},
      {NO:85,TAG:"21-F-256001B",TAG_DESCR:"Filter: Lean Meg Filter B",PARENT_TAG:"21-F-256001B",WEIGHT:1500,SN:12,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-06",ETA_ACTUAL:"",ROS:"2020-10-23",ROS_ETA_DELTA:17,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03"},
      {NO:711,TAG:"21-F-804001",TAG_DESCR:"Filter: Heating Medium Filter",PARENT_TAG:"21-F-804001",WEIGHT:0,SN:7,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-06",ETA_ACTUAL:"",ROS:"2020-10-23",ROS_ETA_DELTA:17,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DA"},
      {NO:712,TAG:"21-F-805001",TAG_DESCR:"Filter: Cooling Medium Filter",PARENT_TAG:"21-F-805001",WEIGHT:0,SN:8,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-06",ETA_ACTUAL:"",ROS:"2020-10-23",ROS_ETA_DELTA:17,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DA"},
      {NO:442,TAG:"21-F-813001A",TAG_DESCR:"Filter: Fuel Gas Filter A",PARENT_TAG:"21-F-813001A",WEIGHT:0,SN:9,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-06",ETA_ACTUAL:"",ROS:"2020-10-23",ROS_ETA_DELTA:17,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DA"},
      {NO:443,TAG:"21-F-813001B",TAG_DESCR:"Filter: Fuel Gas Filter B",PARENT_TAG:"21-F-813001B",WEIGHT:0,SN:10,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-06",ETA_ACTUAL:"",ROS:"2020-10-23",ROS_ETA_DELTA:17,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DA"},
      {NO:384,TAG:"21-F-833001",TAG_DESCR:"21-F-833001",WEIGHT:1500,SN:8,POSITION:"R",LV1:"TOPSIDES",LV2:"COMMON",LV3:"KO01",LV4:"KO01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-06",ETA_ACTUAL:"",ROS:"2020-10-23",ROS_ETA_DELTA:17,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO"},
      {NO:382,TAG:"21-F-851001A",TAG_DESCR:"21-F-851001A",WEIGHT:1500,SN:6,POSITION:"R",LV1:"TOPSIDES",LV2:"COMMON",LV3:"KO01",LV4:"KO01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-06",ETA_ACTUAL:"",ROS:"2020-10-23",ROS_ETA_DELTA:17,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO"},
      {NO:383,TAG:"21-F-851001B",TAG_DESCR:"21-F-851001B",WEIGHT:1500,SN:7,POSITION:"R",LV1:"TOPSIDES",LV2:"COMMON",LV3:"KO01",LV4:"KO01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-06",ETA_ACTUAL:"",ROS:"2020-10-23",ROS_ETA_DELTA:17,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO"},
      {NO:688,TAG:"21-F-861106",TAG_DESCR:"Filter Gas Fuel",PARENT_TAG:"21-Z-861101",SN:7,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW04",LV4:"MW04DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW04",GWBS_LEVEL:"MW04DA"},
      {NO:703,TAG:"21-F-861114",TAG_DESCR:"Filter: Lube Oil Vent Filter Separator",PARENT_TAG:"21-F-861114",SN:9,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW04",LV4:"MW04DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW04",GWBS_LEVEL:"MW04DB"},
      {NO:694,TAG:"21-F-861206",TAG_DESCR:"Filter Gas Fuel",PARENT_TAG:"21-Z-861201",SN:13,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW04",LV4:"MW04DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW04",GWBS_LEVEL:"MW04DA"},
      {NO:704,TAG:"21-F-861214",TAG_DESCR:"Filter: Lube Oil Vent Filter Separator",PARENT_TAG:"21-F-861214",SN:10,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW04",LV4:"MW04DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW04",GWBS_LEVEL:"MW04DB"},
      {NO:477,TAG:"21-F-861306",TAG_DESCR:"Filter Gas Fuel",PARENT_TAG:"21-Z-861301",SN:6,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",LV4:"ME04DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME04",GWBS_LEVEL:"ME04DA"},
      {NO:489,TAG:"21-F-861314",TAG_DESCR:"Filter: Lube Oil Vent Filter Separator",PARENT_TAG:"21-F-861314",SN:1,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",LV4:"ME04DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME04",GWBS_LEVEL:"ME04DB"},
      {NO:480,TAG:"21-F-861406",TAG_DESCR:"Filter Gas Fuel",PARENT_TAG:"21-Z-861401",SN:9,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",LV4:"ME04DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME04",GWBS_LEVEL:"ME04DA"},
      {NO:490,TAG:"21-F-861414",TAG_DESCR:"Filter: Lube Oil Vent Filter Separator",PARENT_TAG:"21-F-861414",SN:2,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",LV4:"ME04DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME04",GWBS_LEVEL:"ME04DB"},
      {NO:376,TAG:"21-FT-502001",TAG_DESCR:"21-FT-502001",PARENT_TAG:"21-FT-502001",WEIGHT:0,SN:5,POSITION:"L",LV1:"TOPSIDES",LV2:"COMMON",LV3:"FL01",LV4:"FL01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-13",ETA_ACTUAL:"",ROS:"2020-11-18",ROS_ETA_DELTA:36,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO"},
      {NO:375,TAG:"21-FT-522001",TAG_DESCR:"21-FT-522001",PARENT_TAG:"21-FT-522001",WEIGHT:0,SN:4,POSITION:"R",LV1:"TOPSIDES",LV2:"COMMON",LV3:"FL01",LV4:"FL01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-13",ETA_ACTUAL:"",ROS:"2020-11-18",ROS_ETA_DELTA:36,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO"},
      {NO:282,TAG:"21-GD-661001",TAG_DESCR:"Generator System: Diesel Engine",PARENT_TAG:"21-Z-661001",DIMENSION:"13000*4000*6000",WEIGHT:83000,SN:9,LV1:"HULL",LV2:"LQ",LV3:"LQ01",LV4:"LQ01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-02-16",ETA_ACTUAL:"",ROS:"2021-02-11",ROS_ETA_DELTA:-5,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"DECK A"},
      {NO:538,TAG:"21-ECP-861001",TAG_DESCR:"21-ECP-861001",PARENT_TAG:"21-ECP-861001",SN:8,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L2"},
      {NO:125,TAG:"21-ECP-861002",TAG_DESCR:"21-ECP-861002",PARENT_TAG:"21-ECP-861002",SN:52,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:126,TAG:"21-ECP-870003",TAG_DESCR:"21-ECP-870003",PARENT_TAG:"21-ECP-870003",SN:53,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:619,TAG:"21-H-222101",TAG_DESCR:"Heat Exchanger: Liquid Heater 1",PARENT_TAG:"21-H-222101",WEIGHT:0,SN:5,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",LV4:"MW01DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-02-17",ETA_ACTUAL:"",ROS:"2021-03-22",ROS_ETA_DELTA:33,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBS_LEVEL:"MW01DA"},
      {NO:618,TAG:"21-H-222201",TAG_DESCR:"Heat Exchanger: Liquid Heater 2",PARENT_TAG:"21-H-222201",WEIGHT:0,SN:4,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",LV4:"MW01DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-02-17",ETA_ACTUAL:"",ROS:"2021-03-22",ROS_ETA_DELTA:33,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBS_LEVEL:"MW01DA"},
      {NO:468,TAG:"21-H-223001A",TAG_DESCR:"Heat Exchanger: Interstage Heater A",PARENT_TAG:"21-H-223001A",WEIGHT:0,SN:4,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-02-12",ETA_ACTUAL:"",ROS:"2021-05-18",ROS_ETA_DELTA:95,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DC"},
      {NO:469,TAG:"21-H-223001B",TAG_DESCR:"Heat Exchanger: Interstage Heater B",PARENT_TAG:"21-H-223001B",WEIGHT:0,SN:5,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-02-12",ETA_ACTUAL:"",ROS:"2021-05-18",ROS_ETA_DELTA:95,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DC"},
      {NO:438,TAG:"21-H-224001A",TAG_DESCR:"Heat Exchanger: Condensate Cooler A",PARENT_TAG:"21-H-224001A",WEIGHT:0,SN:5,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-09",ETA_ACTUAL:"",ROS:"2020-11-10",ROS_ETA_DELTA:32,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DA"},
      {NO:439,TAG:"21-H-224001B",TAG_DESCR:"Heat Exchanger: Condensate Cooler B",PARENT_TAG:"21-H-224001B",WEIGHT:0,SN:6,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-09",ETA_ACTUAL:"",ROS:"2020-11-10",ROS_ETA_DELTA:32,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DA"},
      {NO:428,TAG:"21-H-304001",TAG_DESCR:"Heat Exchanger: Llp Flash Gas Compressor Aftercooler",PARENT_TAG:"21-H-304001",WEIGHT:0,SN:11,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",LV4:"ME01DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-12-24",ETA_ACTUAL:"",ROS:"2021-05-18",ROS_ETA_DELTA:145,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB"},
      {NO:429,TAG:"21-H-304002",TAG_DESCR:"Heat Exchanger: Lp Flash Gas Compressor Aftercooler",PARENT_TAG:"21-H-304002",WEIGHT:0,SN:12,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",LV4:"ME01DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-12-24",ETA_ACTUAL:"",ROS:"2021-05-18",ROS_ETA_DELTA:145,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB"},
      {NO:426,TAG:"21-H-304003",TAG_DESCR:"Heat Exchanger: Mp Flash Gas Compressor Aftercooler",PARENT_TAG:"21-H-304003",WEIGHT:0,SN:9,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",LV4:"ME01DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-12-24",ETA_ACTUAL:"",ROS:"2021-05-18",ROS_ETA_DELTA:145,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB"},
      {NO:427,TAG:"21-H-304004",TAG_DESCR:"Heat Exchanger: Hp Flash Gas Compressor Aftercooler",PARENT_TAG:"21-H-304004",WEIGHT:0,SN:10,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",LV4:"ME01DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-06-14",ETA_ACTUAL:"",ROS:"2021-03-31",ROS_ETA_DELTA:-75,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB"},
      {NO:666,TAG:"21-H-333001A",TAG_DESCR:"Heat Exchanger: Gas,Gas Exchanger A",PARENT_TAG:"21-H-333001",WEIGHT:0,SN:4,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-02-24",ETA_ACTUAL:"",ROS:"2021-03-20",ROS_ETA_DELTA:24,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DB"},
      {NO:665,TAG:"21-H-333001B",TAG_DESCR:"Heat Exchanger: Gas,Gas Exchanger B",PARENT_TAG:"21-H-333001",WEIGHT:0,SN:3,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-02-24",ETA_ACTUAL:"",ROS:"2021-03-20",ROS_ETA_DELTA:24,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DB"},
      {NO:630,TAG:"21-H-345001",TAG_DESCR:"Heat Exchanger: Gas Export Heaters",PARENT_TAG:"21-H-345001B",WEIGHT:0,SN:4,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",LV4:"MW01DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-02-24",ETA_ACTUAL:"",ROS:"2021-02-06",ROS_ETA_DELTA:-18,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBS_LEVEL:"MW01DB"},
      {NO:122,TAG:"21-HAC-992106",TAG_DESCR:"21-HAC-992106",PARENT_TAG:"21-HAC-992106",DIMENSION:"2200X1020X2200",WEIGHT:1250,SN:49,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2021-01-15",ETA_ACTUAL:"",ROS:"2021-01-15",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:132,TAG:"21-HAC-992107",TAG_DESCR:"21-HAC-992107",PARENT_TAG:"21-HAC-992107",DIMENSION:"2200X1020X2200",WEIGHT:1250,SN:59,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2021-01-15",ETA_ACTUAL:"",ROS:"2021-01-15",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:284,TAG:"21-HAC-992203",TAG_DESCR:"21-HAC-992203",PARENT_TAG:"21-HAC-992203",DIMENSION:"2200*1020*2200",WEIGHT:780,SN:11,LV1:"HULL",LV2:"LQ",LV3:"LQ01",LV4:"LQ01",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2021-01-15",ETA_ACTUAL:"",ROS:"2021-01-15",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"DECK A"},
      {NO:285,TAG:"21-HAC-992204",TAG_DESCR:"21-HAC-992204",PARENT_TAG:"21-HAC-992204",DIMENSION:"2200*1020*2200",WEIGHT:780,SN:12,LV1:"HULL",LV2:"LQ",LV3:"LQ01",LV4:"LQ01",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2021-01-15",ETA_ACTUAL:"",ROS:"2021-01-15",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"DECK A"},
      {NO:308,TAG:"21-HAC-992301",TAG_DESCR:"21-HAC-992301",PARENT_TAG:"21-HAC-992301",DIMENSION:"2200X1020X2200",WEIGHT:1250,SN:21,LV1:"HULL",LV2:"LQ",LV3:"LQ02",LV4:"LQ02",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2021-01-15",ETA_ACTUAL:"",ROS:"2021-01-15",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1"},
      {NO:309,TAG:"21-HAC-992302",TAG_DESCR:"21-HAC-992302",PARENT_TAG:"21-HAC-992302",DIMENSION:"2200X1020X2200",WEIGHT:1250,SN:22,LV1:"HULL",LV2:"LQ",LV3:"LQ02",LV4:"LQ02",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2021-01-15",ETA_ACTUAL:"",ROS:"2021-01-15",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1"},
      {NO:320,TAG:"21-HAC-992405",TAG_DESCR:"21-HAC-992405",PARENT_TAG:"21-HAC-992405",DIMENSION:"2200X1020X2200",WEIGHT:1350,SN:6,LV1:"HULL",LV2:"LQ",LV3:"LQ03",LV4:"LQ03",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2021-01-15",ETA_ACTUAL:"",ROS:"2021-01-15",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV2"},
      {NO:319,TAG:"21-HAC-992406",TAG_DESCR:"21-HAC-992406",PARENT_TAG:"21-HAC-992406",DIMENSION:"2200X1020X2200",WEIGHT:1350,SN:5,LV1:"HULL",LV2:"LQ",LV3:"LQ03",LV4:"LQ03",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2021-01-15",ETA_ACTUAL:"",ROS:"2021-01-15",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV2"},
      {NO:352,TAG:"21-HAC-992707",TAG_DESCR:"21-HAC-992707",PARENT_TAG:"21-HAC-992707",DIMENSION:"2100*980*710",WEIGHT:720,SN:8,LV1:"HULL",LV2:"LQ",LV3:"LQ06",LV4:"LQ06",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2021-01-15",ETA_ACTUAL:"",ROS:"2021-01-15",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5"},
      {NO:357,TAG:"21-HAC-992708",TAG_DESCR:"21-HAC-992708",PARENT_TAG:"21-HAC-992708",DIMENSION:"2960*1020*2200",WEIGHT:1500,SN:13,LV1:"HULL",LV2:"LQ",LV3:"LQ06",LV4:"LQ06",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2021-01-15",ETA_ACTUAL:"",ROS:"2021-01-15",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",ROOM:"TER"},
      {NO:367,TAG:"21-HAC-992813",TAG_DESCR:"21-HAC-992813",PARENT_TAG:"21-HAC-992813",SN:1,LV1:"HULL",LV2:"LQ",LV3:"LQ07",LV4:"LQ07",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"ROOF"},
      {NO:531,TAG:"21-HAC-997601",TAG_DESCR:"21-HAC-997601",PARENT_TAG:"21-HAC-997601",SN:1,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L2"},
      {NO:563,TAG:"21-HAC-997602",TAG_DESCR:"Hvac Acu & Accu Cabinet",PARENT_TAG:"21-HAC-997602",SN:1,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:102,TAG:"21-HAH-992101",TAG_DESCR:"21-HAH-992101",PARENT_TAG:"21-HAH-992101",DIMENSION:"5000*1750*1700",WEIGHT:5490,SN:29,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:103,TAG:"21-HAH-992102",TAG_DESCR:"21-HAH-992102",PARENT_TAG:"21-HAH-992102",DIMENSION:"5000*1750*1700",WEIGHT:5490,SN:30,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:112,TAG:"21-HAH-992103",TAG_DESCR:"21-HAH-992103",PARENT_TAG:"21-HAH-992103",DIMENSION:"6000*4000*2600",WEIGHT:14470,SN:39,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:113,TAG:"21-HAH-992104",TAG_DESCR:"21-HAH-992104",PARENT_TAG:"21-HAH-992104",DIMENSION:"6000*4000*2600",WEIGHT:14470,SN:40,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:114,TAG:"21-HAH-992105",TAG_DESCR:"21-HAH-992105",PARENT_TAG:"21-HAH-992105",DIMENSION:"6000*4000*2600",WEIGHT:7590,SN:41,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:607,TAG:"21-HAH-997801",TAG_DESCR:"21-HAH-997801",PARENT_TAG:"21-HAH-997801",SN:1,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DD",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L4"},
      {NO:608,TAG:"21-HAH-997802",TAG_DESCR:"21-HAH-997802",PARENT_TAG:"21-HAH-997802",SN:2,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DD",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L4"},
      {NO:274,TAG:"21-HCB-992213",TAG_DESCR:"21-HCB-992213",PARENT_TAG:"21-HCB-992213",DIMENSION:"2145*1510*1450",WEIGHT:816,SN:1,LV1:"HULL",LV2:"LQ",LV3:"LQ01",LV4:"LQ01",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"DECK A"},
      {NO:328,TAG:"21-HCB-992401",TAG_DESCR:"21-HCB-992401",PARENT_TAG:"21-HCB-992401",DIMENSION:"1900*1050*1400",WEIGHT:944,SN:14,LV1:"HULL",LV2:"LQ",LV3:"LQ03",LV4:"LQ03",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV2"},
      {NO:327,TAG:"21-HCB-992402",TAG_DESCR:"21-HCB-992402",PARENT_TAG:"21-HCB-992402",DIMENSION:"1900*1050*1400",WEIGHT:944,SN:13,LV1:"HULL",LV2:"LQ",LV3:"LQ03",LV4:"LQ03",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV2"},
      {NO:321,TAG:"21-HCB-992403",TAG_DESCR:"21-HCB-992403",PARENT_TAG:"21-HCB-992403",DIMENSION:"1900*1210*1100",WEIGHT:987,SN:7,LV1:"HULL",LV2:"LQ",LV3:"LQ03",LV4:"LQ03",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV2"},
      {NO:322,TAG:"21-HCB-992404",TAG_DESCR:"21-HCB-992404",PARENT_TAG:"21-HCB-992404",DIMENSION:"1900*1210*1100",WEIGHT:987,SN:8,LV1:"HULL",LV2:"LQ",LV3:"LQ03",LV4:"LQ03",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV2"},
      {NO:329,TAG:"21-HCB-992407",TAG_DESCR:"21-HCB-992407",PARENT_TAG:"21-HCB-992407",DIMENSION:"2400*1410*1250",WEIGHT:1208,SN:15,LV1:"HULL",LV2:"LQ",LV3:"LQ03",LV4:"LQ03",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV2"},
      {NO:330,TAG:"21-HCB-992408",TAG_DESCR:"21-HCB-992408",PARENT_TAG:"21-HCB-992408",DIMENSION:"2400*1410*1250",WEIGHT:1208,SN:16,LV1:"HULL",LV2:"LQ",LV3:"LQ03",LV4:"LQ03",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV2"},
      {NO:342,TAG:"21-HCB-992501",TAG_DESCR:"21-HCB-992501",PARENT_TAG:"21-HCB-992501",DIMENSION:"2900*1550*1500",WEIGHT:1831,SN:6,LV1:"HULL",LV2:"LQ",LV3:"LQ04",LV4:"LQ04",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV3"},
      {NO:343,TAG:"21-HCB-992502",TAG_DESCR:"21-HCB-992502",PARENT_TAG:"21-HCB-992502",DIMENSION:"2900*1550*1500",WEIGHT:1831,SN:7,LV1:"HULL",LV2:"LQ",LV3:"LQ04",LV4:"LQ04",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV3"},
      {NO:340,TAG:"21-HCB-992503",TAG_DESCR:"21-HCB-992503",PARENT_TAG:"21-HCB-992503",DIMENSION:"1900*1250*1250",WEIGHT:968,SN:4,LV1:"HULL",LV2:"LQ",LV3:"LQ04",LV4:"LQ04",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV3"},
      {NO:341,TAG:"21-HCB-992504",TAG_DESCR:"21-HCB-992504",PARENT_TAG:"21-HCB-992504",DIMENSION:"1900*1250*1250",WEIGHT:968,SN:5,LV1:"HULL",LV2:"LQ",LV3:"LQ04",LV4:"LQ04",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV3"},
      {NO:345,TAG:"21-HCB-992701",TAG_DESCR:"21-HCB-992701",PARENT_TAG:"21-HCB-992701",DIMENSION:"2750*2300*1450",WEIGHT:1522,SN:1,LV1:"HULL",LV2:"LQ",LV3:"LQ06",LV4:"LQ06",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5"},
      {NO:346,TAG:"21-HCB-992702",TAG_DESCR:"21-HCB-992702",PARENT_TAG:"21-HCB-992702",DIMENSION:"2750*2300*1450",WEIGHT:1522,SN:2,LV1:"HULL",LV2:"LQ",LV3:"LQ06",LV4:"LQ06",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5"},
      {NO:349,TAG:"21-HCB-992703",TAG_DESCR:"21-HCB-992703",PARENT_TAG:"21-HCB-992703",DIMENSION:"3000*225050*1650",WEIGHT:1779,SN:5,LV1:"HULL",LV2:"LQ",LV3:"LQ06",LV4:"LQ06",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5"},
      {NO:350,TAG:"21-HCB-992704",TAG_DESCR:"21-HCB-992704",PARENT_TAG:"21-HCB-992704",DIMENSION:"2250*1550*1650",WEIGHT:800,SN:6,LV1:"HULL",LV2:"LQ",LV3:"LQ06",LV4:"LQ06",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5"},
      {NO:348,TAG:"21-HCB-992705",TAG_DESCR:"21-HCB-992705",PARENT_TAG:"21-HCB-992705",DIMENSION:"3000*22500*1300",WEIGHT:1778,SN:4,LV1:"HULL",LV2:"LQ",LV3:"LQ06",LV4:"LQ06",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5"},
      {NO:347,TAG:"21-HCB-992706",TAG_DESCR:"21-HCB-992706",PARENT_TAG:"21-HCB-992706",DIMENSION:"2300*1600*1800",WEIGHT:1000,SN:3,LV1:"HULL",LV2:"LQ",LV3:"LQ06",LV4:"LQ06",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5"},
      {NO:368,TAG:"21-HCB-992803",TAG_DESCR:"21-HCB-992803",PARENT_TAG:"21-HCB-992803",DIMENSION:"2850*1600*1900",WEIGHT:1431,SN:2,LV1:"HULL",LV2:"LQ",LV3:"LQ07",LV4:"LQ07",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"ROOF"},
      {NO:369,TAG:"21-HCB-992804",TAG_DESCR:"21-HCB-992804",PARENT_TAG:"21-HCB-992804",DIMENSION:"1425*1600*1900",WEIGHT:1240,SN:3,LV1:"HULL",LV2:"LQ",LV3:"LQ07",LV4:"LQ07",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"ROOF"},
      {NO:370,TAG:"21-HCB-992805",TAG_DESCR:"21-HCB-992805",PARENT_TAG:"21-HCB-992805",DIMENSION:"3250*2000*2150",WEIGHT:1977,SN:4,LV1:"HULL",LV2:"LQ",LV3:"LQ07",LV4:"LQ07",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"ROOF"},
      {NO:371,TAG:"21-HCB-992806",TAG_DESCR:"21-HCB-992806",PARENT_TAG:"21-HCB-992806",DIMENSION:"1625*2000*2150",WEIGHT:1240,SN:5,LV1:"HULL",LV2:"LQ",LV3:"LQ07",LV4:"LQ07",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"ROOF"},
      {NO:339,TAG:"21-HCB-992808",TAG_DESCR:"21-HCB-992808",PARENT_TAG:"21-HCB-992808",SN:3,LV1:"HULL",LV2:"LQ",LV3:"LQ04",LV4:"LQ04",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV3"},
      {NO:725,TAG:"21-H-802001A",TAG_DESCR:"Heat Exchanger: Cooling Medium Heat Exchanger A",PARENT_TAG:"21-H-802001A",WEIGHT:0,SN:5,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-09",ETA_ACTUAL:"",ROS:"2020-11-10",ROS_ETA_DELTA:32,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DB"},
      {NO:726,TAG:"21-H-802001B",TAG_DESCR:"Heat Exchanger: Cooling Medium Heat Exchanger B",PARENT_TAG:"21-H-802001A",WEIGHT:0,SN:6,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-09",ETA_ACTUAL:"",ROS:"2020-11-10",ROS_ETA_DELTA:32,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DB"},
      {NO:727,TAG:"21-H-802001C",TAG_DESCR:"Heat Exchanger: Cooling Medium Heat Exchanger C",PARENT_TAG:"21-H-802001A",WEIGHT:0,SN:7,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-09",ETA_ACTUAL:"",ROS:"2020-11-10",ROS_ETA_DELTA:32,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DB"},
      {NO:714,TAG:"21-H-804001",TAG_DESCR:"Heat Exchanger: Heating Medium Trim Cooler",PARENT_TAG:"21-H-804001",WEIGHT:0,SN:10,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-01-18",ETA_ACTUAL:"",ROS:"2021-03-31",ROS_ETA_DELTA:72,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DA"},
      {NO:211,TAG:"21-HCB-997301",TAG_DESCR:"Diesel Oil Pump （Mdo Treatment）Room Exhaust Fan A ",PARENT_TAG:"21-HCB-997301",DIMENSION:"1950*1350*1700",WEIGHT:1526,SN:1,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-09-14",ETA_ACTUAL:"",ROS:"2020-09-14",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"3RD DECK",LOCATION:"MACHINERY SPACE",METHOD:"Y"},
      {NO:212,TAG:"21-HCB-997302",TAG_DESCR:"Diesel Oil Pump （Mdo Treatment）Room Exhaust Fan B",PARENT_TAG:"21-HCB-997302",DIMENSION:"1950*1350*1700",WEIGHT:1526,SN:2,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-09-14",ETA_ACTUAL:"",ROS:"2020-09-14",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"3RD DECK",LOCATION:"MACHINERY SPACE",METHOD:"Y"},
      {NO:225,TAG:"21-HCB-997303",TAG_DESCR:"Diesel Oil Pump（Mdo Treatment） Room Supply Fan A ",PARENT_TAG:"21-HCB-997303",DIMENSION:"1950*1350*1700",WEIGHT:1526,SN:15,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-09-14",ETA_ACTUAL:"",ROS:"2020-09-14",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"3RD DECK",LOCATION:"MACHINERY SPACE",METHOD:"Y"},
      {NO:226,TAG:"21-HCB-997304",TAG_DESCR:"Diesel Oil Pump（Mdo Treatment） Room Supply Fan B ",PARENT_TAG:"21-HCB-997304",DIMENSION:"1950*1350*1700",WEIGHT:1526,SN:16,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-09-14",ETA_ACTUAL:"",ROS:"2020-09-14",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"3RD DECK",LOCATION:"MACHINERY SPACE",METHOD:"Y"},
      {NO:613,TAG:"21-HCB-997801",TAG_DESCR:"21-HCB-997801",PARENT_TAG:"21-HCB-997801",SN:5,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DE",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L4"},
      {NO:614,TAG:"21-HCB-997802",TAG_DESCR:"21-HCB-997802",PARENT_TAG:"21-HCB-997802",SN:6,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DE",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L4"},
      {NO:611,TAG:"21-HCB-997803",TAG_DESCR:"21-HCB-997803",PARENT_TAG:"21-HCB-997803",SN:3,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DE",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L4"},
      {NO:612,TAG:"21-HCB-997804",TAG_DESCR:"21-HCB-997804",PARENT_TAG:"21-HCB-997804",SN:4,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DE",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L4"},
      {NO:135,TAG:"21-HCB-997901",TAG_DESCR:"21-HCB-997901",PARENT_TAG:"21-HCB-997901",DIMENSION:"1900*1300*2700",WEIGHT:1489,SN:62,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:134,TAG:"21-HCB-997902",TAG_DESCR:"21-HCB-997902",PARENT_TAG:"21-HCB-997902",DIMENSION:"1900*1300*2700",WEIGHT:2500,SN:61,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:287,TAG:"21-HCN-9922010",TAG_DESCR:"21-HCN-9922010",PARENT_TAG:"21-HCN-9922010",SN:14,LV1:"HULL",LV2:"LQ",LV3:"LQ01",LV4:"LQ01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"DECK A"},
      {NO:275,TAG:"21-HCN-992205",TAG_DESCR:"21-HCN-992205",PARENT_TAG:"21-HCN-992205",DIMENSION:"1100*980*1780",WEIGHT:780,SN:2,LV1:"HULL",LV2:"LQ",LV3:"LQ01",LV4:"LQ01",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"DECK A"},
      {NO:278,TAG:"21-HCN-992206",TAG_DESCR:"21-HCN-992206",PARENT_TAG:"21-HCN-992206",DIMENSION:"1100*980*1780",WEIGHT:780,SN:5,LV1:"HULL",LV2:"LQ",LV3:"LQ01",LV4:"LQ01",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"DECK A"},
      {NO:277,TAG:"21-HCN-992207",TAG_DESCR:"21-HCN-992207",PARENT_TAG:"21-HCN-992207",DIMENSION:"1450*1100*2050",WEIGHT:780,SN:4,LV1:"HULL",LV2:"LQ",LV3:"LQ01",LV4:"LQ01",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"DECK A"},
      {NO:280,TAG:"21-HCN-992208",TAG_DESCR:"21-HCN-992208",PARENT_TAG:"21-HCN-992208",DIMENSION:"1450*1100*2050",WEIGHT:780,SN:7,LV1:"HULL",LV2:"LQ",LV3:"LQ01",LV4:"LQ01",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"DECK A"},
      {NO:286,TAG:"21-HCN-992209",TAG_DESCR:"21-HCN-992209",PARENT_TAG:"21-HCN-992209",DIMENSION:"1800*1280*2150",WEIGHT:900,SN:13,LV1:"HULL",LV2:"LQ",LV3:"LQ01",LV4:"LQ01",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"DECK A"},
      {NO:276,TAG:"21-HCN-992211",TAG_DESCR:"21-HCN-992211",PARENT_TAG:"21-HCN-992211",DIMENSION:"1450*1100*2050",WEIGHT:780,SN:3,LV1:"HULL",LV2:"LQ",LV3:"LQ01",LV4:"LQ01",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"DECK A"},
      {NO:279,TAG:"21-HCN-992212",TAG_DESCR:"21-HCN-992212",PARENT_TAG:"21-HCN-992212",DIMENSION:"1450*1100*2050",WEIGHT:780,SN:6,LV1:"HULL",LV2:"LQ",LV3:"LQ01",LV4:"LQ01",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"DECK A"},
      {NO:358,TAG:"21-HCN-992709",TAG_DESCR:"21-HCN-992709",PARENT_TAG:"21-HCN-992709",DIMENSION:"1800X1280X2150",WEIGHT:440,SN:14,LV1:"HULL",LV2:"LQ",LV3:"LQ06",LV4:"LQ06",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-11-23",ETA_ACTUAL:"",ROS:"2020-11-23",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",ROOM:"TER"},
      {NO:609,TAG:"21-HCN-997801",TAG_DESCR:"21-HCN-997801",PARENT_TAG:"21-HCN-997801",SN:1,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DE",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L4"},
      {NO:610,TAG:"21-HCN-997802",TAG_DESCR:"21-HCN-997802",PARENT_TAG:"21-HCN-997802",SN:2,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DE",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L4"},
      {NO:351,TAG:"21-HEH-992701",TAG_DESCR:"21-HEH-992701",PARENT_TAG:"21-HEH-992701",SN:7,LV1:"HULL",LV2:"LQ",LV3:"LQ06",LV4:"LQ06",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5"},
      {NO:67,TAG:"21-HPU-891003",TAG_DESCR:"Hpu For Offloading Reel/Winches",PARENT_TAG:"21-HPU-891003",WEIGHT:5000,SN:67,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-07",ETA_ACTUAL:"",ROS:"2020-12-15",ROS_ETA_DELTA:38,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:97,TAG:"21-HPU-934001",TAG_DESCR:"Tensioning Unit Hpu - Fore",PARENT_TAG:"21-HPU-934001",WEIGHT:5000,SN:24,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-01-02",ETA_ACTUAL:"",ROS:"2020-12-15",ROS_ETA_DELTA:-18,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03"},
      {NO:8,TAG:"21-HPU-934002",TAG_DESCR:"Tensioning Unit Hpu - Aft",PARENT_TAG:"21-HPU-934002",WEIGHT:6000,SN:8,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-01-02",ETA_ACTUAL:"",ROS:"2020-12-15",ROS_ETA_DELTA:-18,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:422,TAG:"21-IAC-222001",TAG_DESCR:"Analyzer Cabinate",PARENT_TAG:"21-IAC-222001",WEIGHT:0,SN:5,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",LV4:"ME01DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-01-19",ETA_ACTUAL:"",ROS:"2020-11-15",ROS_ETA_DELTA:-65,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB"},
      {NO:458,TAG:"21-IAC-224001",TAG_DESCR:"Analyzer  Cabinate",PARENT_TAG:"21-IAC-224001",WEIGHT:0,SN:5,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-01-19",ETA_ACTUAL:"",ROS:"2020-11-15",ROS_ETA_DELTA:-65,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DB"},
      {NO:461,TAG:"21-IAC-253001",TAG_DESCR:"Analyzer  Cabinate",PARENT_TAG:"21-IAC-253001",WEIGHT:0,SN:8,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-01-19",ETA_ACTUAL:"",ROS:"2020-11-15",ROS_ETA_DELTA:-65,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DB"},
      {NO:462,TAG:"21-IAC-253002",TAG_DESCR:"Analyzer  Cabinate",PARENT_TAG:"21-IAC-253002",WEIGHT:0,SN:9,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-01-19",ETA_ACTUAL:"",ROS:"2020-11-15",ROS_ETA_DELTA:-65,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DB"},
      {NO:673,TAG:"21-IAC-333001",TAG_DESCR:"21-IAC-333001",PARENT_TAG:"21-IAC-333001",WEIGHT:0,SN:5,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-01-19",ETA_ACTUAL:"",ROS:"2020-11-15",ROS_ETA_DELTA:-65,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DC"},
      {NO:629,TAG:"21-IAC-345001",TAG_DESCR:"21-IAC-345001",PARENT_TAG:"21-IAC-345001",WEIGHT:0,SN:3,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",LV4:"MW01DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-01-19",ETA_ACTUAL:"",ROS:"2020-11-15",ROS_ETA_DELTA:-65,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBS_LEVEL:"MW01DB"},
      {NO:622,TAG:"21-IAC-345002",TAG_DESCR:"21-IAC-345002",PARENT_TAG:"21-IAC-345002",WEIGHT:0,SN:8,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",LV4:"MW01DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-01-19",ETA_ACTUAL:"",ROS:"2020-11-15",ROS_ETA_DELTA:-65,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBS_LEVEL:"MW01DA"},
      {NO:459,TAG:"21-IAC-413001",TAG_DESCR:"Analyzer  Cabinate",PARENT_TAG:"21-IAC-413001",WEIGHT:0,SN:6,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-01-19",ETA_ACTUAL:"",ROS:"2020-11-15",ROS_ETA_DELTA:-65,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DB"},
      {NO:420,TAG:"21-IAC-521001",TAG_DESCR:"Analyzer Cabinate",PARENT_TAG:"21-IAC-521001",WEIGHT:0,SN:3,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",LV4:"ME01DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-01-19",ETA_ACTUAL:"",ROS:"2020-11-15",ROS_ETA_DELTA:-65,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB"},
      {NO:564,TAG:"21-ICP-244060",TAG_DESCR:"Pack - Cond. Tandem Offload",PARENT_TAG:"21-ICP-244060",SN:2,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:596,TAG:"21-ICP-304030",TAG_DESCR:"Howden Ucp",PARENT_TAG:"21-Z-304001",SN:34,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:599,TAG:"21-ICP-304075",TAG_DESCR:"Pack - Mp Hp Flash Gas Comp.",PARENT_TAG:"21-ICP-304075",SN:37,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:583,TAG:"21-ICP-333040",TAG_DESCR:"Pack - Turbo Expander",PARENT_TAG:"21-ICP-333040",SN:21,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:572,TAG:"21-ICP-604201",TAG_DESCR:"Pack - Machine Monitoring System",PARENT_TAG:"21-ICP-604201",SN:10,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:606,TAG:"21-IDB-671411",TAG_DESCR:"21-IDB-671411",SN:44,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:605,TAG:"21-IDB-671412",TAG_DESCR:"21-IDB-671412",SN:43,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:601,TAG:"21-IDB-671413",TAG_DESCR:"21-IDB-671413",SN:39,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:600,TAG:"21-IDB-671414",TAG_DESCR:"21-IDB-671414",SN:38,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:604,TAG:"21-IDB-671415",TAG_DESCR:"21-IDB-671415",SN:42,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:603,TAG:"21-IDB-671417",TAG_DESCR:"21-IDB-671417",SN:41,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:602,TAG:"21-IDB-671418",TAG_DESCR:"21-IDB-671418",SN:40,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:129,TAG:"21-ILP-8910012",TAG_DESCR:"21-ILP-8910012",PARENT_TAG:"21-ILP-8910012",SN:56,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:297,TAG:"21-IMC-601101",TAG_DESCR:"21-IMC-601101",PARENT_TAG:"21-IMC-601101",WEIGHT:0,SN:10,LV1:"HULL",LV2:"LQ",LV3:"LQ02",LV4:"LQ02",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-08-25",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-10,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1"},
      {NO:298,TAG:"21-IMC-601102",TAG_DESCR:"21-IMC-601102",PARENT_TAG:"21-IMC-601102",SN:11,LV1:"HULL",LV2:"LQ",LV3:"LQ02",LV4:"LQ02",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1"},
      {NO:573,TAG:"21-IMC-601201",TAG_DESCR:"Sis Cabinet Cto",PARENT_TAG:"21-IMC-601201",WEIGHT:0,SN:11,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-08-25",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-10,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:585,TAG:"21-ICP-811110",TAG_DESCR:"Pack - Air Comp. Topside",PARENT_TAG:"21-ICP-811110",SN:23,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:597,TAG:"21-ICP-816010",TAG_DESCR:"Igg Unit Control Panel",PARENT_TAG:"21-Z-816001",SN:35,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:598,TAG:"21-ICP-997701",TAG_DESCR:"Hvac Ucp",PARENT_TAG:"21-ICP-997701",WEIGHT:0,SN:36,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-26",ETA_ACTUAL:"",ROS:"2049-01-01",ROS_ETA_DELTA:10294,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:571,TAG:"21-ICS-604201",TAG_DESCR:"Pack - Machine Monitoring System",PARENT_TAG:"21-ICS-604201",SN:9,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:294,TAG:"21-IMC-602101",TAG_DESCR:"21-IMC-602101",PARENT_TAG:"21-IMC-602101",SN:7,LV1:"HULL",LV2:"LQ",LV3:"LQ02",LV4:"LQ02",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",ROOM:"CER"},
      {NO:579,TAG:"21-IMC-602201",TAG_DESCR:"Pas Cabinet Cto",PARENT_TAG:"21-IMC-602201",SN:17,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:578,TAG:"21-IMC-602202",TAG_DESCR:"Pas Cabinet Cto",PARENT_TAG:"21-IMC-602202",WEIGHT:0,SN:16,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-08-25",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-10,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:300,TAG:"21-IMC-607101",TAG_DESCR:"21-IMC-607101",PARENT_TAG:"21-IMC-607101",WEIGHT:0,SN:13,LV1:"HULL",LV2:"LQ",LV3:"LQ02",LV4:"LQ02",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-08-25",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-10,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1"},
      {NO:299,TAG:"21-IMC-607102",TAG_DESCR:"21-IMC-607102",PARENT_TAG:"21-IMC-607102",SN:12,LV1:"HULL",LV2:"LQ",LV3:"LQ02",LV4:"LQ02",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1"},
      {NO:575,TAG:"21-IMC-607201",TAG_DESCR:"Fgs Cabinet Cto",PARENT_TAG:"21-IMC-607201",SN:13,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:296,TAG:"21-INC-602101",TAG_DESCR:"21-INC-602101",PARENT_TAG:"21-INC-602101",SN:9,LV1:"HULL",LV2:"LQ",LV3:"LQ02",LV4:"LQ02",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",ROOM:"CER"},
      {NO:580,TAG:"21-INC-602201",TAG_DESCR:"Pas Network Cabinet",PARENT_TAG:"21-INC-602201",WEIGHT:0,SN:18,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-08-25",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-10,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:590,TAG:"21-INC-602251",TAG_DESCR:"Subsea Network Cabinet",PARENT_TAG:"21-INC-602251",SN:28,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:295,TAG:"21-IPC-602101",TAG_DESCR:"21-IPC-602101",PARENT_TAG:"21-IPC-602101",WEIGHT:0,SN:8,LV1:"HULL",LV2:"LQ",LV3:"LQ02",LV4:"LQ02",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-08-25",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-10,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",ROOM:"CER"},
      {NO:311,TAG:"21-IPC-602102",TAG_DESCR:"21-IPC-602102",PARENT_TAG:"21-IPC-602102",SN:24,LV1:"HULL",LV2:"LQ",LV3:"LQ02",LV4:"LQ02",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1"},
      {NO:581,TAG:"21-IPC-602201",TAG_DESCR:"Pas Controller Cabinet",PARENT_TAG:"21-IPC-602201",WEIGHT:0,SN:19,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-08-25",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-10,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:582,TAG:"21-IPC-602202",TAG_DESCR:"Eoic And Controller Cabinet",PARENT_TAG:"21-IPC-602202",SN:20,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:589,TAG:"21-IPC-602251",TAG_DESCR:"Subsea Eioc & Controller Net. Cabinet",PARENT_TAG:"21-IPC-602251",SN:27,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:303,TAG:"21-IXC-603001",TAG_DESCR:"21-IXC-603001",PARENT_TAG:"21-IXC-603001",WEIGHT:0,SN:16,LV1:"HULL",LV2:"LQ",LV3:"LQ02",LV4:"LQ02",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-08-25",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-10,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1"},
      {NO:591,TAG:"21-IXC-603051",TAG_DESCR:"Subsea Server & Network Cabinet 1",PARENT_TAG:"21-IXC-603051",SN:29,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:592,TAG:"21-IXC-603052",TAG_DESCR:"Subsea Server & Network Cabinet 2",PARENT_TAG:"21-IXC-603052",SN:30,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:288,TAG:"21-IXC-603102",TAG_DESCR:"21-IXC-603102",PARENT_TAG:"21-IXC-603102",SN:1,LV1:"HULL",LV2:"LQ",LV3:"LQ02",LV4:"LQ02",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",ROOM:"CER"},
      {NO:290,TAG:"21-IXC-603501",TAG_DESCR:"21-IXC-603501",PARENT_TAG:"21-IXC-603501",WEIGHT:0,SN:3,LV1:"HULL",LV2:"LQ",LV3:"LQ02",LV4:"LQ02",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-08-25",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-10,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",ROOM:"CER"},
      {NO:291,TAG:"21-IXC-603502",TAG_DESCR:"21-IXC-603502",PARENT_TAG:"21-IXC-603502",SN:4,LV1:"HULL",LV2:"LQ",LV3:"LQ02",LV4:"LQ02",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",NOTE:"Exist same tag in ME05DC"},
      {NO:569,TAG:"21-IXC-603502",TAG_DESCR:"Cctv Encoder Cabinet (Process)",PARENT_TAG:"21-IXC-603502",SN:7,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3",NOTE:"Exist same tag in LQ02"},
      {NO:570,TAG:"21-IXC-603503",TAG_DESCR:"Cctv Encoder Cabinet (Flame Detector)",PARENT_TAG:"21-IXC-603503",SN:8,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:96,TAG:"21-LC-981001",TAG_DESCR:"Crane S-N 170841",PARENT_TAG:"21-LC-981001",DIMENSION:"23600*7300*8833",WEIGHT:126500,SN:23,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-02-02",ETA_ACTUAL:"",ROS:"2021-03-10",ROS_ETA_DELTA:36,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03"},
      {NO:137,TAG:"21-LC-981002",TAG_DESCR:"Crane S-N 170842",PARENT_TAG:"21-LC-981002",DIMENSION:"23600*7300*8833",WEIGHT:126500,SN:64,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-02-02",ETA_ACTUAL:"",ROS:"2021-03-10",ROS_ETA_DELTA:36,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03"},
      {NO:14,TAG:"21-LG-982001",TAG_DESCR:"Riser Pull-In System (Rps)",PARENT_TAG:"21-LG-982001",DIMENSION:"Hold",WEIGHT:45000,SN:14,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-12-05",ETA_ACTUAL:"",ROS:"2021-01-13",ROS_ETA_DELTA:39,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:101,TAG:"21-LW-934001",TAG_DESCR:"Forward Mooring Tensioning Unit - West",PARENT_TAG:"21-LW-934001",WEIGHT:10000,SN:28,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-01-02",ETA_ACTUAL:"",ROS:"2020-12-20",ROS_ETA_DELTA:-13,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03"},
      {NO:138,TAG:"21-LW-934002",TAG_DESCR:"Forward Mooring Tensioning Unit - East",PARENT_TAG:"21-LW-934002",WEIGHT:10000,SN:65,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-01-02",ETA_ACTUAL:"",ROS:"2020-12-20",ROS_ETA_DELTA:-13,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03"},
      {NO:131,TAG:"21-LW-934003",TAG_DESCR:"Odms Package",PARENT_TAG:"21-LW-934003",DIMENSION:"1900*1165*2200",WEIGHT:4500,SN:58,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"2019-11-21",PO_ACTUAL:"2019-11-21",IRN_PLAN:"",ETA_PLAN:"2020-10-20",ETA_ACTUAL:"",ROS:"2020-11-06",ROS_ETA_DELTA:17,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:5,TAG:"21-LW-934004",TAG_DESCR:"Aft Recovery Winch",PARENT_TAG:"21-LW-934004",DIMENSION:"1900*1165*2200",WEIGHT:4500,SN:5,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"2019-11-21",PO_ACTUAL:"2019-11-21",IRN_PLAN:"",ETA_PLAN:"2020-10-20",ETA_ACTUAL:"",ROS:"2020-11-06",ROS_ETA_DELTA:17,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:9,TAG:"21-LW-934005",TAG_DESCR:"Aft Mooring Tensioning Unit - West",PARENT_TAG:"21-LW-934005",WEIGHT:10000,SN:9,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-01-02",ETA_ACTUAL:"",ROS:"2021-02-27",ROS_ETA_DELTA:56,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:54,TAG:"21-LW-934006",TAG_DESCR:"Aft Mooring Tensioning Unit - East",PARENT_TAG:"21-LW-934006",WEIGHT:10000,SN:54,POSITION:"L",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-01-02",ETA_ACTUAL:"",ROS:"2021-02-27",ROS_ETA_DELTA:56,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:36,TAG:"21-P-224001A",TAG_DESCR:"Condensate Coalescer Pumps A",PARENT_TAG:"21-P-224001A",WEIGHT:2002,SN:36,POSITION:"L",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-02-22",ETA_ACTUAL:"",ROS:"2021-04-18",ROS_ETA_DELTA:55,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:37,TAG:"21-P-224001B",TAG_DESCR:"Condensate Coalescer Pumps B",PARENT_TAG:"21-P-224001B",WEIGHT:2002,SN:37,POSITION:"L",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-02-22",ETA_ACTUAL:"",ROS:"2021-04-18",ROS_ETA_DELTA:55,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:69,TAG:"21-P-247001",TAG_DESCR:"Off-Spec Condensate Pump",PARENT_TAG:"21-P-247001",DIMENSION:"L=29300",WEIGHT:777,SN:69,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-07-07",ETA_ACTUAL:"",ROS:"2021-04-19",ROS_ETA_DELTA:286,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:33,TAG:"21-P-247002A",TAG_DESCR:"Off-Spec Condensate Reprocessing And Recycling Pumps A",PARENT_TAG:"21-P-247002A",WEIGHT:1680,SN:33,POSITION:"L",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-02-16",ETA_ACTUAL:"",ROS:"2021-05-11",ROS_ETA_DELTA:84,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:34,TAG:"21-P-247002B",TAG_DESCR:"Off-Spec Condensate Reprocessing And Recycling Pumps B",PARENT_TAG:"21-P-247002B",WEIGHT:1680,SN:34,POSITION:"L",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-02-16",ETA_ACTUAL:"",ROS:"2021-05-11",ROS_ETA_DELTA:84,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:70,TAG:"21-P-247003",TAG_DESCR:"Condensate Pump",PARENT_TAG:"21-P-247003",DIMENSION:"L=29260",WEIGHT:2731,SN:70,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-07-07",ETA_ACTUAL:"",ROS:"2021-04-19",ROS_ETA_DELTA:286,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:72,TAG:"21-P-251101A",TAG_DESCR:"Rich Meg Transfer Pump",PARENT_TAG:"21-P-251101A",DIMENSION:"L=29165",WEIGHT:803,SN:72,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-07-07",ETA_ACTUAL:"",ROS:"2021-04-19",ROS_ETA_DELTA:286,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:73,TAG:"21-P-251101B",TAG_DESCR:"Rich Meg Transfer Pump",PARENT_TAG:"21-P-251101B",DIMENSION:"L=29165",WEIGHT:803,SN:73,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-07-07",ETA_ACTUAL:"",ROS:"2021-04-19",ROS_ETA_DELTA:286,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:6,TAG:"21-P-251102",TAG_DESCR:"Rich Meg Skimming Pump",PARENT_TAG:"21-P-251102",DIMENSION:"L=5200",WEIGHT:214,SN:6,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-07-07",ETA_ACTUAL:"",ROS:"2021-04-19",ROS_ETA_DELTA:286,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:65,TAG:"21-P-251201",TAG_DESCR:"Salty Rich Meg Pump",PARENT_TAG:"21-P-251201",DIMENSION:"L=29200",WEIGHT:2758,SN:65,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-07-07",ETA_ACTUAL:"",ROS:"2021-04-19",ROS_ETA_DELTA:286,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:22,TAG:"21-P-251301",TAG_DESCR:"Salty Rich Meg Pump",PARENT_TAG:"21-P-251301",DIMENSION:"L=29200",WEIGHT:2758,SN:22,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-07-07",ETA_ACTUAL:"",ROS:"2021-04-19",ROS_ETA_DELTA:286,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:43,TAG:"21-P-251401",TAG_DESCR:"Salty Rich Meg Pump",PARENT_TAG:"21-P-251401",DIMENSION:"L=29200",WEIGHT:2758,SN:43,POSITION:"L",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-07-07",ETA_ACTUAL:"",ROS:"2021-04-19",ROS_ETA_DELTA:286,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:87,TAG:"21-P-255101A",TAG_DESCR:"Lean Meg Transfer Pump",PARENT_TAG:"21-P-255101A",DIMENSION:"L=29250",WEIGHT:1073,SN:14,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-07-07",ETA_ACTUAL:"",ROS:"2021-04-19",ROS_ETA_DELTA:286,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03"},
      {NO:88,TAG:"21-P-255101B",TAG_DESCR:"Lean Meg Transfer Pump",PARENT_TAG:"21-P-255101B",DIMENSION:"L=29250",WEIGHT:1073,SN:15,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-07-07",ETA_ACTUAL:"",ROS:"2021-04-19",ROS_ETA_DELTA:286,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03"},
      {NO:89,TAG:"21-P-255201A",TAG_DESCR:"Lean Meg Transfer Pump",PARENT_TAG:"21-P-255201A",DIMENSION:"L=29250",WEIGHT:1073,SN:16,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-07-07",ETA_ACTUAL:"",ROS:"2021-04-19",ROS_ETA_DELTA:286,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03"},
      {NO:90,TAG:"21-P-255201B",TAG_DESCR:"Lean Meg Transfer Pump",PARENT_TAG:"21-P-255201B",DIMENSION:"L=29250",WEIGHT:1073,SN:17,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-07-07",ETA_ACTUAL:"",ROS:"2021-04-19",ROS_ETA_DELTA:286,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03"},
      {NO:623,TAG:"21-P-256001A",TAG_DESCR:"Subsea Lean Meg Injection Pump",PARENT_TAG:"21-P-256001A",WEIGHT:0,SN:9,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",LV4:"MW01DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-12-24",ETA_ACTUAL:"",ROS:"2021-01-12",ROS_ETA_DELTA:19,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBS_LEVEL:"MW01DA"},
      {NO:624,TAG:"21-P-256001B",TAG_DESCR:"Subsea Lean Meg Injection Pump",PARENT_TAG:"21-P-256001B",WEIGHT:0,SN:10,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",LV4:"MW01DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-12-24",ETA_ACTUAL:"",ROS:"2021-01-12",ROS_ETA_DELTA:19,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBS_LEVEL:"MW01DA"},
      {NO:652,TAG:"21-P-256003A/B",TAG_DESCR:"Topsides Lean Meg Injection Pump B",PARENT_TAG:"21-P-256003A/B",SN:9,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DA"},
      {NO:57,TAG:"21-P-403001",TAG_DESCR:"Off-Spec Produced Water Condensate Pump",PARENT_TAG:"21-P-403001",DIMENSION:"L=5200",WEIGHT:219,SN:57,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-07-07",ETA_ACTUAL:"",ROS:"2021-04-19",ROS_ETA_DELTA:286,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:59,TAG:"21-P-403002",TAG_DESCR:"Off-Spec Produced Water Pump",PARENT_TAG:"21-P-403002",DIMENSION:"L=29165",WEIGHT:811,SN:59,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-07-07",ETA_ACTUAL:"",ROS:"2021-04-19",ROS_ETA_DELTA:286,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:58,TAG:"21-P-403003",TAG_DESCR:"Low Shear Pump",PARENT_TAG:"21-P-403003",DIMENSION:"L=29165",WEIGHT:1127,SN:58,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-07-07",ETA_ACTUAL:"",ROS:"2021-04-19",ROS_ETA_DELTA:286,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:46,TAG:"21-P-434001A",TAG_DESCR:"Closed Drains Drum Pump A",PARENT_TAG:"21-P-434001A",WEIGHT:1182,SN:46,POSITION:"L",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-18",ETA_ACTUAL:"",ROS:"2021-01-24",ROS_ETA_DELTA:67,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:45,TAG:"21-P-434001B",TAG_DESCR:"Closed Drains Drum Pump B",PARENT_TAG:"21-P-434001B",WEIGHT:1182,SN:45,POSITION:"L",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-18",ETA_ACTUAL:"",ROS:"2021-01-24",ROS_ETA_DELTA:67,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:17,TAG:"21-P-501001A",TAG_DESCR:"Hp Flare Ko Drum Pump A",PARENT_TAG:"21-P-501001A",WEIGHT:1500,SN:17,POSITION:"L",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-18",ETA_ACTUAL:"",ROS:"2021-01-24",ROS_ETA_DELTA:67,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:16,TAG:"21-P-501001B",TAG_DESCR:"Hp Flare Ko Drum Pump B",PARENT_TAG:"21-P-501001B",WEIGHT:1500,SN:16,POSITION:"L",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-18",ETA_ACTUAL:"",ROS:"2021-01-24",ROS_ETA_DELTA:67,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:51,TAG:"21-P-521001A",TAG_DESCR:"Lp Flare Ko Drum Pump A",PARENT_TAG:"21-P-521001A",WEIGHT:750,SN:51,POSITION:"L",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-18",ETA_ACTUAL:"",ROS:"2021-01-24",ROS_ETA_DELTA:67,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:52,TAG:"21-P-521001B",TAG_DESCR:"Lp Flare Ko Drum Pump B",PARENT_TAG:"21-P-521001B",WEIGHT:750,SN:52,POSITION:"L",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-18",ETA_ACTUAL:"",ROS:"2021-01-24",ROS_ETA_DELTA:67,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:584,TAG:"21-ICP-802040",TAG_DESCR:"Unit Control Panel",PARENT_TAG:"21-Z-802002",SN:22,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:577,TAG:"21-INC-603001",TAG_DESCR:"Sis/Fgs Network Cabinet",PARENT_TAG:"21-INC-603001",SN:15,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3",NOTE:"Exist same tag in LQ02"},
      {NO:302,TAG:"21-INC-603001",TAG_DESCR:"21-INC-603001",PARENT_TAG:"21-INC-603001",SN:15,LV1:"HULL",LV2:"LQ",LV3:"LQ02",LV4:"LQ02",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",NOTE:"Exist same tag in ME05DC"},
      {NO:292,TAG:"21-IPP-603001",TAG_DESCR:"21-IPP-603001",PARENT_TAG:"21-IPP-603001",SN:5,LV1:"HULL",LV2:"LQ",LV3:"LQ02",LV4:"LQ02",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",ROOM:"CER"},
      {NO:293,TAG:"21-IPP-603002",TAG_DESCR:"21-IPP-603002",PARENT_TAG:"21-IPP-603002",SN:6,LV1:"HULL",LV2:"LQ",LV3:"LQ02",LV4:"LQ02",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1",ROOM:"CER"},
      {NO:593,TAG:"21-IPP-603003",TAG_DESCR:"Optic Fiber Cabinet",PARENT_TAG:"21-IPP-603003",SN:31,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:594,TAG:"21-IPP-603004",TAG_DESCR:"Optic Fiber Cabinet",PARENT_TAG:"21-IPP-603004",SN:32,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:310,TAG:"21-ISC-607101",TAG_DESCR:"21-ISC-607101",PARENT_TAG:"21-ISC-607101",SN:23,LV1:"HULL",LV2:"LQ",LV3:"LQ02",LV4:"LQ02",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1"},
      {NO:568,TAG:"21-ISC-607201",TAG_DESCR:"Afds Cabinets",PARENT_TAG:"21-ISC-607201",WEIGHT:0,SN:6,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-08-25",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-10,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:576,TAG:"21-ISC-607202",TAG_DESCR:"Fgs Controller Cabinet",PARENT_TAG:"21-ISC-607202",SN:14,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:82,TAG:"21-P-802001A",TAG_DESCR:"Seawater Lift Pump A",PARENT_TAG:"21-P-802001A",WEIGHT:12315,SN:9,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-29",ETA_ACTUAL:"",ROS:"2021-02-25",ROS_ETA_DELTA:88,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03"},
      {NO:83,TAG:"21-P-802001B",TAG_DESCR:"Seawater Lift Pump B",PARENT_TAG:"21-P-802001B",WEIGHT:12315,SN:10,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-29",ETA_ACTUAL:"",ROS:"2021-02-25",ROS_ETA_DELTA:88,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03"},
      {NO:84,TAG:"21-P-802001C",TAG_DESCR:"Seawater Lift Pump C",PARENT_TAG:"21-P-802001C",WEIGHT:12315,SN:11,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-29",ETA_ACTUAL:"",ROS:"2021-02-25",ROS_ETA_DELTA:88,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03"},
      {NO:574,TAG:"21-ISC-601201",TAG_DESCR:"Sis Controller Cabinet",PARENT_TAG:"21-ISC-601201",WEIGHT:0,SN:12,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-08-25",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-10,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:301,TAG:"21-ISC-603001",TAG_DESCR:"21-ISC-603001",PARENT_TAG:"21-ISC-603001",SN:14,LV1:"HULL",LV2:"LQ",LV3:"LQ02",LV4:"LQ02",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1"},
      {NO:30,TAG:"21-PV-813001",TAG_DESCR:"P.V. Breaker",PARENT_TAG:"21-Z-816001",WEIGHT:1500,SN:30,POSITION:"L",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-04-30",ETA_ACTUAL:"",ROS:"2020-12-15",ROS_ETA_DELTA:-136,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:31,TAG:"21-PV-816001",TAG_DESCR:"P.V. Breaker",PARENT_TAG:"21-Z-816001",WEIGHT:1500,SN:31,POSITION:"L",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-04-30",ETA_ACTUAL:"",ROS:"2020-12-15",ROS_ETA_DELTA:-136,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:379,TAG:"21-Q-816002",TAG_DESCR:"21-Q-816002",PARENT_TAG:"21-Q-816002",SN:3,POSITION:"L",LV1:"TOPSIDES",LV2:"COMMON",LV3:"KO01",LV4:"KO01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO"},
      {NO:355,TAG:"21-RAC-631091",TAG_DESCR:"21-RAC-631091",PARENT_TAG:"21-RAC-631091",SN:11,LV1:"HULL",LV2:"LQ",LV3:"LQ06",LV4:"LQ06",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5"},
      {NO:64,TAG:"21-RDD-634003",TAG_DESCR:"Marine Evacuation System",PARENT_TAG:"21-RDD-634003",DIMENSION:"5000*4939*2900",WEIGHT:10000,SN:64,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"2019-10-28",PO_ACTUAL:"2019-10-28",IRN_PLAN:"",ETA_PLAN:"2022-03-20",ETA_ACTUAL:"",ROS:"2022-03-25",ROS_ETA_DELTA:5,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:1,TAG:"21-RLR-633011",TAG_DESCR:"Life Raft",PARENT_TAG:"21-RLR-633011",DIMENSION:"1550*920*920",WEIGHT:160,SN:1,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"2019-10-28",PO_ACTUAL:"2019-10-28",IRN_PLAN:"",ETA_PLAN:"2022-03-20",ETA_ACTUAL:"",ROS:"2022-03-25",ROS_ETA_DELTA:5,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:615,TAG:"21-SHE-613020",TAG_DESCR:"21-SHE-613020",PARENT_TAG:"21-SHE-613020",SN:1,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",LV4:"MW01DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBS_LEVEL:"MW01DA"},
      {NO:625,TAG:"21-SHE-613021",TAG_DESCR:"21-SHE-613021",PARENT_TAG:"21-SHE-613021",SN:11,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",LV4:"MW01DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBS_LEVEL:"MW01DA"},
      {NO:645,TAG:"21-SHE-613022",TAG_DESCR:"21-SHE-613022",PARENT_TAG:"21-SHE-613022",SN:2,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DA"},
      {NO:661,TAG:"21-SHE-613023",TAG_DESCR:"21-SHE-613023",PARENT_TAG:"21-SHE-613023",SN:18,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DA"},
      {NO:683,TAG:"21-SHE-613024",TAG_DESCR:"21-SHE-613024",PARENT_TAG:"21-SHE-613024",SN:2,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW04",LV4:"MW04DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW04",GWBS_LEVEL:"MW04DA"},
      {NO:706,TAG:"21-SHE-613025",TAG_DESCR:"21-SHE-613025",PARENT_TAG:"21-SHE-613025",SN:2,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DA"},
      {NO:710,TAG:"21-SHE-613026",TAG_DESCR:"21-SHE-613026",PARENT_TAG:"21-SHE-613026",SN:6,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DA"},
      {NO:719,TAG:"21-SHE-613027",TAG_DESCR:"21-SHE-613027",PARENT_TAG:"21-SHE-613027",SN:15,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DA"},
      {NO:487,TAG:"21-SHE-613029",TAG_DESCR:"Hose Cabinet",PARENT_TAG:"21-SHE-613029",SN:16,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",LV4:"ME04DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME04",GWBS_LEVEL:"ME04DA"},
      {NO:473,TAG:"21-SHE-613030",TAG_DESCR:"Hose Cabinet",PARENT_TAG:"21-SHE-613030",SN:2,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",LV4:"ME04DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME04",GWBS_LEVEL:"ME04DA"},
      {NO:435,TAG:"21-SHE-613031",TAG_DESCR:"Hose Cabinate ",PARENT_TAG:"21-SHE-613031",SN:2,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DA"},
      {NO:452,TAG:"21-SHE-613032",TAG_DESCR:"Hose Cabinate ",PARENT_TAG:"21-SHE-613032",SN:19,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DA"},
      {NO:416,TAG:"21-SHE-613033",TAG_DESCR:"Hose Cabinet",PARENT_TAG:"21-SHE-613033",SN:13,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",LV4:"ME01DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA"},
      {NO:406,TAG:"21-SHE-613034",TAG_DESCR:"Hose Cabinet",PARENT_TAG:"21-SHE-613034",SN:3,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",LV4:"ME01DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA"},
      {NO:627,TAG:"21-SHE-613035",TAG_DESCR:"21-SHE-613035",PARENT_TAG:"21-SHE-613035",SN:1,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",LV4:"MW01DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBS_LEVEL:"MW01DB"},
      {NO:632,TAG:"21-SHE-613036",TAG_DESCR:"21-SHE-613036",PARENT_TAG:"21-SHE-613036",SN:6,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",LV4:"MW01DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBS_LEVEL:"MW01DB"},
      {NO:663,TAG:"21-SHE-613037",TAG_DESCR:"21-SHE-613037",PARENT_TAG:"21-SHE-613037",SN:1,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DB"},
      {NO:667,TAG:"21-SHE-613038",TAG_DESCR:"21-SHE-613038",PARENT_TAG:"21-SHE-613038",SN:5,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DB"},
      {NO:697,TAG:"21-SHE-613039",TAG_DESCR:"21-SHE-613039",PARENT_TAG:"21-SHE-613039",SN:3,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW04",LV4:"MW04DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW04",GWBS_LEVEL:"MW04DB"},
      {NO:701,TAG:"21-SHE-613040",TAG_DESCR:"21-SHE-613040",PARENT_TAG:"21-SHE-613040",SN:7,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW04",LV4:"MW04DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW04",GWBS_LEVEL:"MW04DB"},
      {NO:721,TAG:"21-SHE-613041",TAG_DESCR:"21-SHE-613041",PARENT_TAG:"21-SHE-613041",SN:1,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DB"},
      {NO:731,TAG:"21-SHE-613042",TAG_DESCR:"21-SHE-613042",PARENT_TAG:"21-SHE-613042",SN:11,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DB"},
      {NO:497,TAG:"21-SHE-613043",TAG_DESCR:"Hose Cabinet",PARENT_TAG:"21-SHE-613043",SN:9,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",LV4:"ME04DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME04",GWBS_LEVEL:"ME04DB"},
      {NO:491,TAG:"21-SHE-613044",TAG_DESCR:"Hose Cabinet",PARENT_TAG:"21-SHE-613044",SN:3,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",LV4:"ME04DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME04",GWBS_LEVEL:"ME04DB"},
      {NO:455,TAG:"21-SHE-613045",TAG_DESCR:"Hose Cabinate ",PARENT_TAG:"21-SHE-613045",SN:2,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DB"},
      {NO:463,TAG:"21-SHE-613046",TAG_DESCR:"Hose Cabinate ",PARENT_TAG:"21-SHE-613046",SN:10,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DB"},
      {NO:425,TAG:"21-SHE-613047",TAG_DESCR:"Hose Cabinet",PARENT_TAG:"21-SHE-613047",SN:8,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",LV4:"ME01DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB"},
      {NO:419,TAG:"21-SHE-613048",TAG_DESCR:"Hose Cabinet",PARENT_TAG:"21-SHE-613048",SN:2,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",LV4:"ME01DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB"},
      {NO:634,TAG:"21-SHE-613049",TAG_DESCR:"21-SHE-613049",PARENT_TAG:"21-SHE-613049",SN:2,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",LV4:"MW01DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBS_LEVEL:"MW01DC"},
      {NO:639,TAG:"21-SHE-613050",TAG_DESCR:"21-SHE-613050",PARENT_TAG:"21-SHE-613050",SN:7,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",LV4:"MW01DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBS_LEVEL:"MW01DC"},
      {NO:671,TAG:"21-SHE-613051",TAG_DESCR:"21-SHE-613051",PARENT_TAG:"21-SHE-613051",SN:3,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DC"},
      {NO:676,TAG:"21-SHE-613052",TAG_DESCR:"21-SHE-613052",PARENT_TAG:"21-SHE-613052",SN:8,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DC"},
      {NO:737,TAG:"21-SHE-613053",TAG_DESCR:"21-SHE-613053",PARENT_TAG:"21-SHE-613053",SN:5,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DC"},
      {NO:742,TAG:"21-SHE-613054",TAG_DESCR:"21-SHE-613054",PARENT_TAG:"21-SHE-613054",SN:10,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DC"},
      {NO:465,TAG:"21-SHE-613055",TAG_DESCR:"Hose Cabinate ",PARENT_TAG:"21-SHE-613055",SN:1,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DC"},
      {NO:471,TAG:"21-SHE-613056",TAG_DESCR:"Hose Cabinate ",PARENT_TAG:"21-SHE-613056",SN:7,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DC"},
      {NO:430,TAG:"21-SHE-613057",TAG_DESCR:"Hose Cabinet",PARENT_TAG:"21-SHE-613057",SN:1,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",LV4:"ME01DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DC"},
      {NO:432,TAG:"21-SHE-613058",TAG_DESCR:"Hose Cabinet",PARENT_TAG:"21-SHE-613058",SN:3,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",LV4:"ME01DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DC"},
      {NO:643,TAG:"21-SHE-613059",TAG_DESCR:"21-SHE-613059",PARENT_TAG:"21-SHE-613059",SN:4,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",LV4:"MW01DD",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBS_LEVEL:"MW01DD"},
      {NO:640,TAG:"21-SHE-613060",TAG_DESCR:"21-SHE-613060",PARENT_TAG:"21-SHE-613060",SN:1,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",LV4:"MW01DD",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBS_LEVEL:"MW01DD"},
      {NO:679,TAG:"21-SHE-613061",TAG_DESCR:"21-SHE-613061",PARENT_TAG:"21-SHE-613061",SN:2,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DD",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DD"},
      {NO:680,TAG:"21-SHE-613062",TAG_DESCR:"21-SHE-613062",PARENT_TAG:"21-SHE-613062",SN:3,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DD",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DD"},
      {NO:616,TAG:"21-SHY-613020",TAG_DESCR:"21-SHY-613020",PARENT_TAG:"21-SHY-613020",SN:2,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",LV4:"MW01DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBS_LEVEL:"MW01DA"},
      {NO:626,TAG:"21-SHY-613021",TAG_DESCR:"21-SHY-613021",PARENT_TAG:"21-SHY-613021",SN:12,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",LV4:"MW01DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBS_LEVEL:"MW01DA"},
      {NO:644,TAG:"21-SHY-613022",TAG_DESCR:"21-SHY-613022",PARENT_TAG:"21-SHY-613022",SN:1,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DA"},
      {NO:662,TAG:"21-SHY-613023",TAG_DESCR:"21-SHY-613023",PARENT_TAG:"21-SHY-613023",SN:19,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DA"},
      {NO:682,TAG:"21-SHY-613024",TAG_DESCR:"21-SHY-613024",PARENT_TAG:"21-SHY-613024",SN:1,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW04",LV4:"MW04DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW04",GWBS_LEVEL:"MW04DA"},
      {NO:705,TAG:"21-SHY-613025",TAG_DESCR:"21-SHY-613025",PARENT_TAG:"21-SHY-613025",SN:1,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DA"},
      {NO:709,TAG:"21-SHY-613026",TAG_DESCR:"21-SHY-613026",PARENT_TAG:"21-SHY-613026",SN:5,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DA"},
      {NO:720,TAG:"21-SHY-613027",TAG_DESCR:"21-SHY-613027",PARENT_TAG:"21-SHY-613027",SN:16,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DA"},
      {NO:488,TAG:"21-SHY-613029",TAG_DESCR:"Water Hydrant",PARENT_TAG:"21-SHY-613029",SN:17,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",LV4:"ME04DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME04",GWBS_LEVEL:"ME04DA"},
      {NO:472,TAG:"21-SHY-613030",TAG_DESCR:"Water Hydrant",PARENT_TAG:"21-SHY-613030",SN:1,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",LV4:"ME04DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME04",GWBS_LEVEL:"ME04DA"},
      {NO:434,TAG:"21-SHY-613031",TAG_DESCR:"Water  Hydrant",PARENT_TAG:"21-SHY-613031",SN:1,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DA"},
      {NO:453,TAG:"21-SHY-613032",TAG_DESCR:"Water  Hydrant",PARENT_TAG:"21-SHY-613032",SN:20,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DA"},
      {NO:417,TAG:"21-SHY-613033",TAG_DESCR:"Water Hydrant",PARENT_TAG:"21-SHY-613033",SN:14,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",LV4:"ME01DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA"},
      {NO:405,TAG:"21-SHY-613034",TAG_DESCR:"Water Hydrant",PARENT_TAG:"21-SHY-613034",SN:2,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",LV4:"ME01DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA"},
      {NO:628,TAG:"21-SHY-613035",TAG_DESCR:"21-SHY-613035",PARENT_TAG:"21-SHY-613035",SN:2,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",LV4:"MW01DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBS_LEVEL:"MW01DB"},
      {NO:631,TAG:"21-SHY-613036",TAG_DESCR:"21-SHY-613036",PARENT_TAG:"21-SHY-613036",SN:5,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",LV4:"MW01DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBS_LEVEL:"MW01DB"},
      {NO:664,TAG:"21-SHY-613037",TAG_DESCR:"21-SHY-613037",PARENT_TAG:"21-SHY-613037",SN:2,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DB"},
      {NO:668,TAG:"21-SHY-613038",TAG_DESCR:"21-SHY-613038",PARENT_TAG:"21-SHY-613038",SN:6,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DB"},
      {NO:698,TAG:"21-SHY-613039",TAG_DESCR:"21-SHY-613039",PARENT_TAG:"21-SHY-613039",SN:4,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW04",LV4:"MW04DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW04",GWBS_LEVEL:"MW04DB"},
      {NO:702,TAG:"21-SHY-613040",TAG_DESCR:"21-SHY-613040",PARENT_TAG:"21-SHY-613040",SN:8,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW04",LV4:"MW04DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW04",GWBS_LEVEL:"MW04DB"},
      {NO:722,TAG:"21-SHY-613041",TAG_DESCR:"21-SHY-613041",PARENT_TAG:"21-SHY-613041",SN:2,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DB"},
      {NO:732,TAG:"21-SHY-613042",TAG_DESCR:"21-SHY-613042",PARENT_TAG:"21-SHY-613042",SN:12,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DB"},
      {NO:498,TAG:"21-SHY-613043",TAG_DESCR:"Water  Hydrant",PARENT_TAG:"21-SHY-613043",SN:10,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",LV4:"ME04DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME04",GWBS_LEVEL:"ME04DB"},
      {NO:492,TAG:"21-SHY-613044",TAG_DESCR:"Water  Hydrant",PARENT_TAG:"21-SHY-613044",SN:4,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",LV4:"ME04DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME04",GWBS_LEVEL:"ME04DB"},
      {NO:454,TAG:"21-SHY-613045",TAG_DESCR:"Water  Hydrant",PARENT_TAG:"21-SHY-613045",SN:1,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DB"},
      {NO:464,TAG:"21-SHY-613046",TAG_DESCR:"Water  Hydrant",PARENT_TAG:"21-SHY-613046",SN:11,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DB"},
      {NO:424,TAG:"21-SHY-613047",TAG_DESCR:"Water Hydrant",PARENT_TAG:"21-SHY-613047",SN:7,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",LV4:"ME01DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB"},
      {NO:418,TAG:"21-SHY-613048",TAG_DESCR:"Water Hydrant",PARENT_TAG:"21-SHY-613048",SN:1,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",LV4:"ME01DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB"},
      {NO:633,TAG:"21-SHY-613049",TAG_DESCR:"21-SHY-613049",PARENT_TAG:"21-SHY-613049",SN:1,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",LV4:"MW01DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBS_LEVEL:"MW01DC"},
      {NO:638,TAG:"21-SHY-613050",TAG_DESCR:"21-SHY-613050",PARENT_TAG:"21-SHY-613050",SN:6,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",LV4:"MW01DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBS_LEVEL:"MW01DC"},
      {NO:670,TAG:"21-SHY-613051",TAG_DESCR:"21-SHY-613051",PARENT_TAG:"21-SHY-613051",SN:2,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DC"},
      {NO:677,TAG:"21-SHY-613052",TAG_DESCR:"21-SHY-613052",PARENT_TAG:"21-SHY-613052",SN:9,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DC"},
      {NO:738,TAG:"21-SHY-613053",TAG_DESCR:"21-SHY-613053",PARENT_TAG:"21-SHY-613053",SN:6,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DC"},
      {NO:741,TAG:"21-SHY-613054",TAG_DESCR:"21-SHY-613054",PARENT_TAG:"21-SHY-613054",SN:9,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DC"},
      {NO:466,TAG:"21-SHY-613055",TAG_DESCR:"Water  Hydrant",PARENT_TAG:"21-SHY-613055",SN:2,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DC"},
      {NO:470,TAG:"21-SHY-613056",TAG_DESCR:"Water  Hydrant",PARENT_TAG:"21-SHY-613056",SN:6,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DC"},
      {NO:431,TAG:"21-SHY-613057",TAG_DESCR:"Water Hydrant",PARENT_TAG:"21-SHY-613057",SN:2,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",LV4:"ME01DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DC"},
      {NO:433,TAG:"21-SHY-613058",TAG_DESCR:"Water Hydrant",PARENT_TAG:"21-SHY-613058",SN:4,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",LV4:"ME01DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DC"},
      {NO:642,TAG:"21-SHY-613059",TAG_DESCR:"21-SHY-613059",PARENT_TAG:"21-SHY-613059",SN:3,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",LV4:"MW01DD",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBS_LEVEL:"MW01DD"},
      {NO:641,TAG:"21-SHY-613060",TAG_DESCR:"21-SHY-613060",PARENT_TAG:"21-SHY-613060",SN:2,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",LV4:"MW01DD",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBS_LEVEL:"MW01DD"},
      {NO:678,TAG:"21-SHY-613061",TAG_DESCR:"21-SHY-613061",PARENT_TAG:"21-SHY-613061",SN:1,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DD",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DD"},
      {NO:681,TAG:"21-SHY-613062",TAG_DESCR:"21-SHY-613062",PARENT_TAG:"21-SHY-613062",SN:4,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DD",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DD"},
      {NO:716,TAG:"21-P-804001A",TAG_DESCR:"Heating Medium Pump A",PARENT_TAG:"21-P-804001A",WEIGHT:0,SN:12,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-15",ETA_ACTUAL:"",ROS:"2021-02-22",ROS_ETA_DELTA:99,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DA"},
      {NO:715,TAG:"21-P-804001B",TAG_DESCR:"Heating Medium Pump B",PARENT_TAG:"21-P-804001B",WEIGHT:0,SN:11,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-20",ETA_ACTUAL:"",ROS:"2021-02-22",ROS_ETA_DELTA:94,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DA"},
      {NO:718,TAG:"21-P-805001A",TAG_DESCR:"Cooling Medium Pump A",PARENT_TAG:"21-P-805001A",WEIGHT:0,SN:14,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-21",ETA_ACTUAL:"",ROS:"2021-03-15",ROS_ETA_DELTA:114,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DA"},
      {NO:717,TAG:"21-P-805001B",TAG_DESCR:"Cooling Medium Pump B",PARENT_TAG:"21-P-805001B",WEIGHT:0,SN:13,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-21",ETA_ACTUAL:"",ROS:"2021-03-15",ROS_ETA_DELTA:114,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DA"},
      {NO:713,TAG:"21-P-805003",TAG_DESCR:"Cooling Medium Start-Up Pump",PARENT_TAG:"21-P-805003",WEIGHT:0,SN:9,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-20",ETA_ACTUAL:"",ROS:"2021-02-17",ROS_ETA_DELTA:89,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DA"},
      {NO:56,TAG:"21-P-833001A",TAG_DESCR:"Wax Inhibitor Pump",PARENT_TAG:"21-P-833001A",DIMENSION:"L=12000",WEIGHT:398,SN:56,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-07-07",ETA_ACTUAL:"",ROS:"2021-04-19",ROS_ETA_DELTA:286,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:55,TAG:"21-P-833001B",TAG_DESCR:"Wax Inhibitor Pump",PARENT_TAG:"21-P-833001B",DIMENSION:"L=12000",WEIGHT:398,SN:55,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-07-07",ETA_ACTUAL:"",ROS:"2021-04-19",ROS_ETA_DELTA:286,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:659,TAG:"21-P-833002A",TAG_DESCR:"Subsea Wax Inhibitor Injection Pump",PARENT_TAG:"21-P-833002A",WEIGHT:0,SN:16,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-04",ETA_ACTUAL:"",ROS:"2021-01-21",ROS_ETA_DELTA:109,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DA"},
      {NO:658,TAG:"21-P-833002B",TAG_DESCR:"Subsea Wax Inhibitor Injection Pump",PARENT_TAG:"21-P-833002B",WEIGHT:0,SN:15,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-04",ETA_ACTUAL:"",ROS:"2021-01-21",ROS_ETA_DELTA:109,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DA"},
      {NO:653,TAG:"21-P-851001A",TAG_DESCR:"Subsea Methanol Inhibitor Injection Pump",PARENT_TAG:"21-P-851001A",WEIGHT:0,SN:10,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-04",ETA_ACTUAL:"",ROS:"2021-04-30",ROS_ETA_DELTA:208,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DA"},
      {NO:654,TAG:"21-P-851001B",TAG_DESCR:"Subsea Methanol Inhibitor Injection Pump",PARENT_TAG:"21-P-851001B",WEIGHT:0,SN:11,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-04",ETA_ACTUAL:"",ROS:"2021-04-30",ROS_ETA_DELTA:208,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DA"},
      {NO:649,TAG:"21-P-851003A/B",TAG_DESCR:"Topsides Methanol Mp Injection Pump A",PARENT_TAG:"21-P-851003A/B",SN:6,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DA"},
      {NO:63,TAG:"21-P-851004A",TAG_DESCR:"Methanol Transfer Pump",PARENT_TAG:"21-P-851004A",DIMENSION:"L=12150",WEIGHT:401,SN:63,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-07-07",ETA_ACTUAL:"",ROS:"2021-04-19",ROS_ETA_DELTA:286,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:62,TAG:"21-P-851004B",TAG_DESCR:"Methanol Transfer Pump",PARENT_TAG:"21-P-851004B",DIMENSION:"L=12150",WEIGHT:401,SN:62,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-07-07",ETA_ACTUAL:"",ROS:"2021-04-19",ROS_ETA_DELTA:286,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:650,TAG:"21-P-851005A/B",TAG_DESCR:"Topsides Methanol Hp Injection Pump A",PARENT_TAG:"21-P-851005A/B",SN:7,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DA"},
      {NO:13,TAG:"21-P-936101",TAG_DESCR:"Condensate Pump",PARENT_TAG:"21-P-936101",DIMENSION:"L=29220",WEIGHT:2728,SN:13,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-07-07",ETA_ACTUAL:"",ROS:"2021-04-19",ROS_ETA_DELTA:286,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:48,TAG:"21-P-936201",TAG_DESCR:"Condensate Pump",PARENT_TAG:"21-P-936201",DIMENSION:"L=29220",WEIGHT:2728,SN:48,POSITION:"L",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-07-07",ETA_ACTUAL:"",ROS:"2021-04-19",ROS_ETA_DELTA:286,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:32,TAG:"21-P-936301",TAG_DESCR:"Condensate Pump",PARENT_TAG:"21-P-936301",DIMENSION:"L=29220",WEIGHT:2728,SN:32,POSITION:"L",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-07-07",ETA_ACTUAL:"",ROS:"2021-04-19",ROS_ETA_DELTA:286,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:35,TAG:"21-P-936401",TAG_DESCR:"Condensate Pump",PARENT_TAG:"21-P-936401",DIMENSION:"L=29220",WEIGHT:2728,SN:35,POSITION:"L",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-07-07",ETA_ACTUAL:"",ROS:"2021-04-19",ROS_ETA_DELTA:286,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:77,TAG:"21-P-936501",TAG_DESCR:"Condensate Pump",PARENT_TAG:"21-P-936501",DIMENSION:"L=29220",WEIGHT:2728,SN:4,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-07-07",ETA_ACTUAL:"",ROS:"2021-04-19",ROS_ETA_DELTA:286,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:95,TAG:"21-P-936601",TAG_DESCR:"Condensate Pump",PARENT_TAG:"21-P-936601",DIMENSION:"L=29220",WEIGHT:2728,SN:22,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-07-07",ETA_ACTUAL:"",ROS:"2021-04-19",ROS_ETA_DELTA:286,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:80,TAG:"21-P-936701",TAG_DESCR:"Condensate Pump",PARENT_TAG:"21-P-936701",DIMENSION:"L=29220",WEIGHT:2728,SN:7,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-07-07",ETA_ACTUAL:"",ROS:"2021-04-19",ROS_ETA_DELTA:286,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03"},
      {NO:94,TAG:"21-P-936801",TAG_DESCR:"Condensate Pump",PARENT_TAG:"21-P-936801",DIMENSION:"L=29220",WEIGHT:2728,SN:21,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-07-07",ETA_ACTUAL:"",ROS:"2021-04-19",ROS_ETA_DELTA:286,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03"},
      {NO:68,TAG:"21-P-937001",TAG_DESCR:"Slop Water Transfert Pump",PARENT_TAG:"21-P-937001",DIMENSION:"L=23680",WEIGHT:1841,SN:68,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-07-07",ETA_ACTUAL:"",ROS:"2021-04-19",ROS_ETA_DELTA:286,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:66,TAG:"21-P-937002",TAG_DESCR:"Slop Water Transfert Pump",PARENT_TAG:"21-P-937002",DIMENSION:"L=23680",WEIGHT:1841,SN:66,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-07-07",ETA_ACTUAL:"",ROS:"2021-04-19",ROS_ETA_DELTA:286,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:71,TAG:"21-P-937003",TAG_DESCR:"Slop Skimming Pump",PARENT_TAG:"21-P-937003",DIMENSION:"L=18750",WEIGHT:563,SN:71,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-07-07",ETA_ACTUAL:"",ROS:"2021-04-19",ROS_ETA_DELTA:286,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:60,TAG:"21-P-937004",TAG_DESCR:"Slop Skimming Pump",PARENT_TAG:"21-P-937004",DIMENSION:"L=18750",WEIGHT:563,SN:60,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-07-07",ETA_ACTUAL:"",ROS:"2021-04-19",ROS_ETA_DELTA:286,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:685,TAG:"21-SWM-861106",TAG_DESCR:"Fire Suppression System ^H2O System Cabinet",PARENT_TAG:"21-Z-861101",SN:4,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW04",LV4:"MW04DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW04",GWBS_LEVEL:"MW04DA"},
      {NO:684,TAG:"21-SWM-861107",TAG_DESCR:"Fire Suppression System ^H2O 30Min Reserve System Cabinet",PARENT_TAG:"21-Z-861101",SN:3,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW04",LV4:"MW04DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW04",GWBS_LEVEL:"MW04DA"},
      {NO:692,TAG:"21-SWM-861206",TAG_DESCR:"Fire Suppression System ^H2O System Cabinet",PARENT_TAG:"21-Z-861201",SN:11,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW04",LV4:"MW04DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW04",GWBS_LEVEL:"MW04DA"},
      {NO:691,TAG:"21-SWM-861207",TAG_DESCR:"Fire Suppression System ^H2O 30Min Reserve System Cabinet",PARENT_TAG:"21-Z-861201",SN:10,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW04",LV4:"MW04DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW04",GWBS_LEVEL:"MW04DA"},
      {NO:475,TAG:"21-SWM-861306",TAG_DESCR:"Fire Suppression System ^H2O System Cabinet",PARENT_TAG:"21-Z-861301",SN:4,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",LV4:"ME04DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME04",GWBS_LEVEL:"ME04DA"},
      {NO:476,TAG:"21-SWM-861307",TAG_DESCR:"Fire Suppression System ^H2O 30Min Reserve System Cabinet",PARENT_TAG:"21-Z-861301",SN:5,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",LV4:"ME04DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME04",GWBS_LEVEL:"ME04DA"},
      {NO:486,TAG:"21-SWM-861406",TAG_DESCR:"Fire Suppression System ^H2O System Cabinet",PARENT_TAG:"21-Z-861401",SN:15,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",LV4:"ME04DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME04",GWBS_LEVEL:"ME04DA"},
      {NO:485,TAG:"21-SWM-861407",TAG_DESCR:"Fire Suppression System ^H2O 30Min Reserve System Cabinet",PARENT_TAG:"21-Z-861401",SN:14,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",LV4:"ME04DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME04",GWBS_LEVEL:"ME04DA"},
      {NO:335,TAG:"21-SWS-986001",TAG_DESCR:"SWS-986001",PARENT_TAG:"SWS-986001",DIMENSION:"HOLD",WEIGHT:1500,SN:21,LV1:"HULL",LV2:"LQ",LV3:"LQ03",LV4:"LQ03",PO_PLAN:"2020-02-26",PO_ACTUAL:"2020-02-26",IRN_PLAN:"",ETA_PLAN:"2020-10-11",ETA_ACTUAL:"",ROS:"2020-10-12",ROS_ETA_DELTA:1,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV2"},
      {NO:378,TAG:"21-T-611302",TAG_DESCR:"Fire Water Pump Header Tank",PARENT_TAG:"21-Z-611301",SN:2,POSITION:"L",LV1:"TOPSIDES",LV2:"COMMON",LV3:"KO01",LV4:"KO01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO"},
      {NO:474,TAG:"21-T-861101",TAG_DESCR:"Engine Cleaning System Tank",PARENT_TAG:"21-Z-861101",SN:3,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",LV4:"ME04DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME04",GWBS_LEVEL:"ME04DA"},
      {NO:316,TAG:"21-TSAP-9564101",TAG_DESCR:"21-TSAP-9564101",PARENT_TAG:"21-TSAP-9564101",SN:2,LV1:"HULL",LV2:"LQ",LV3:"LQ03",LV4:"LQ03",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV2"},
      {NO:359,TAG:"21-TUCP-9530001",TAG_DESCR:"21-TUCP-9530001",PARENT_TAG:"21-TUCP-9530001",SN:15,LV1:"HULL",LV2:"LQ",LV3:"LQ06",LV4:"LQ06",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",ROOM:"TER"},
      {NO:360,TAG:"21-TUCP-9530002",TAG_DESCR:"21-TUCP-9530002",PARENT_TAG:"21-TUCP-9530002",SN:16,LV1:"HULL",LV2:"LQ",LV3:"LQ06",LV4:"LQ06",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",ROOM:"TER"},
      {NO:361,TAG:"21-TUCP-9530003",TAG_DESCR:"21-TUCP-9530003",PARENT_TAG:"21-TUCP-9530003",SN:17,LV1:"HULL",LV2:"LQ",LV3:"LQ06",LV4:"LQ06",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",ROOM:"TER"},
      {NO:362,TAG:"21-TUCP-9530004",TAG_DESCR:"21-TUCP-9530004",PARENT_TAG:"21-TUCP-9530004",SN:18,LV1:"HULL",LV2:"LQ",LV3:"LQ06",LV4:"LQ06",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV5",ROOM:"TER"},
      {NO:304,TAG:"21-TUCP-9534002",TAG_DESCR:"21-TUCP-9534002",PARENT_TAG:"21-TUCP-9534002",SN:17,LV1:"HULL",LV2:"LQ",LV3:"LQ02",LV4:"LQ02",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1"},
      {NO:595,TAG:"21-TUCP-9534010",TAG_DESCR:"Tel Structured Cabling System Cabinet",PARENT_TAG:"21-TUCP-9534010",SN:33,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:305,TAG:"21-TUCP-9535002",TAG_DESCR:"21-TUCP-9535002",PARENT_TAG:"21-TUCP-9535002",SN:18,LV1:"HULL",LV2:"LQ",LV3:"LQ02",LV4:"LQ02",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1"},
      {NO:306,TAG:"21-TUCP-9544002",TAG_DESCR:"21-TUCP-9544002",PARENT_TAG:"21-TUCP-9544002",SN:19,LV1:"HULL",LV2:"LQ",LV3:"LQ02",LV4:"LQ02",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1"},
      {NO:307,TAG:"21-TUCP-9544003",TAG_DESCR:"21-TUCP-9544003",PARENT_TAG:"21-TUCP-9544003",SN:20,LV1:"HULL",LV2:"LQ",LV3:"LQ02",LV4:"LQ02",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV1"},
      {NO:588,TAG:"21-UCDU-193101",TAG_DESCR:"Ssiv Spcu Cabinet",PARENT_TAG:"21-UCDU-193101",SN:26,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:586,TAG:"21-UCP-193101",TAG_DESCR:"Mcs Cabinets",PARENT_TAG:"21-UCP-193101",SN:24,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:587,TAG:"21-UCP-193102",TAG_DESCR:"Mcs Cabinets",PARENT_TAG:"21-UCP-193102",SN:25,POSITION:"",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L3"},
      {NO:521,TAG:"21-UEPU-192101",TAG_DESCR:"21-UEPU-192101",PARENT_TAG:"21-UEPU-192101",SN:23,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L1"},
      {NO:522,TAG:"21-UEPU-192201",TAG_DESCR:"21-UEPU-192201",PARENT_TAG:"21-UEPU-192201",SN:24,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV4:"ME05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBS_LEVEL:"ME05L1"},
      {NO:24,TAG:"21-UHJB-191101",TAG_DESCR:"Subsea Hydraulic Junction Box: Phase 1 Topsides Umbilical Termination Unit (Tutu)",PARENT_TAG:"21-UHJB-191101",WEIGHT:1250,SN:24,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:27,TAG:"21-UHJB-191301",TAG_DESCR:"Tutu Phase2",PARENT_TAG:"21-UHJB-191301",WEIGHT:1250,SN:27,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:41,TAG:"21-UHJB-191302",TAG_DESCR:"Tutu-Ph2-3-Ssiv",PARENT_TAG:"21-UHJB-191302",WEIGHT:1250,SN:41,POSITION:"L",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:23,TAG:"21-UHJB-191303",TAG_DESCR:"Tutu Spare",PARENT_TAG:"21-UHJB-191303",WEIGHT:1250,SN:23,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:40,TAG:"21-UHJB-191304",TAG_DESCR:"Tutu_Ph_2-3-Chemical-Transfert",PARENT_TAG:"21-UHJB-191304",WEIGHT:1250,SN:40,POSITION:"L",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:26,TAG:"21-UHJB-191305",TAG_DESCR:"Tutu Future Subsea Infrstructure Interface",PARENT_TAG:"21-UHJB-191305",WEIGHT:1500,SN:26,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:28,TAG:"21-UHPU-191101",TAG_DESCR:"Subsea Hpu Ph1",PARENT_TAG:"21-UHPU-191101",WEIGHT:9000,SN:28,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:29,TAG:"21-UHPU-191301",TAG_DESCR:"Subsea Hpu Ph2",PARENT_TAG:"21-UHPU-191301",WEIGHT:9000,SN:29,POSITION:"L",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:78,TAG:"21-UHPU-191401",TAG_DESCR:"Subsea Hpu Ph3",PARENT_TAG:"21-UHPU-191401",WEIGHT:9000,SN:5,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:636,TAG:"21-V-101101",TAG_DESCR:"Vessel: Production Flowline Pig Receiver 1",PARENT_TAG:"21-V-101101",WEIGHT:0,SN:4,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",LV4:"MW01DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-24",ETA_ACTUAL:"",ROS:"2021-02-14",ROS_ETA_DELTA:82,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBS_LEVEL:"MW01DC"},
      {NO:637,TAG:"21-V-101201",TAG_DESCR:"Vessel: Production Flowline Pig Receiver 2",PARENT_TAG:"21-V-101201",WEIGHT:0,SN:5,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",LV4:"MW01DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-24",ETA_ACTUAL:"",ROS:"2021-02-14",ROS_ETA_DELTA:82,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBS_LEVEL:"MW01DC"},
      {NO:620,TAG:"21-V-213101",TAG_DESCR:"Vessel: Slug Catcher 1",PARENT_TAG:"21-V-213101",WEIGHT:0,SN:6,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",LV4:"MW01DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-02-16",ETA_ACTUAL:"",ROS:"2021-03-22",ROS_ETA_DELTA:34,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBS_LEVEL:"MW01DA"},
      {NO:621,TAG:"21-V-213201",TAG_DESCR:"Vessel: Slug Catcher 2",PARENT_TAG:"21-V-213201",WEIGHT:0,SN:7,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",LV4:"MW01DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-03-07",ETA_ACTUAL:"",ROS:"2021-03-22",ROS_ETA_DELTA:15,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBS_LEVEL:"MW01DA"},
      {NO:423,TAG:"21-V-222001",TAG_DESCR:"Vessel: Mp Separator",PARENT_TAG:"21-V-222001",WEIGHT:0,SN:6,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",LV4:"ME01DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-03-12",ETA_ACTUAL:"",ROS:"2021-03-30",ROS_ETA_DELTA:18,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB"},
      {NO:456,TAG:"21-V-223001",TAG_DESCR:"Vessel: Lp Separator",PARENT_TAG:"21-V-223001",WEIGHT:0,SN:3,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-01-04",ETA_ACTUAL:"",ROS:"2021-01-17",ROS_ETA_DELTA:13,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DB"},
      {NO:467,TAG:"21-V-224001",TAG_DESCR:"Vessel: Llp Separator",PARENT_TAG:"21-V-224001",WEIGHT:0,SN:3,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-01-04",ETA_ACTUAL:"",ROS:"2021-01-17",ROS_ETA_DELTA:13,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DC"},
      {NO:460,TAG:"21-V-224002",TAG_DESCR:"21-V-224002",WEIGHT:0,SN:7,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-04",ETA_ACTUAL:"",ROS:"2020-09-15",ROS_ETA_DELTA:-19,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DB"},
      {NO:445,TAG:"21-V-251001",TAG_DESCR:"Vessel: Rich Meg Flash Drum",PARENT_TAG:"21-V-251001",WEIGHT:0,SN:12,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-02-05",ETA_ACTUAL:"",ROS:"2021-03-22",ROS_ETA_DELTA:45,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DA"},
      {NO:648,TAG:"21-V-256001",TAG_DESCR:"Vessel: Lean Meg Break Drum",PARENT_TAG:"21-V-256001",WEIGHT:0,SN:5,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-12-12",ETA_ACTUAL:"",ROS:"2021-01-28",ROS_ETA_DELTA:47,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DA"},
      {NO:410,TAG:"21-V-304001",TAG_DESCR:"Vessel: Llp Flash Gas Compressor Suction Drum",PARENT_TAG:"21-V-304001",WEIGHT:0,SN:7,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",LV4:"ME01DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-12",ETA_ACTUAL:"",ROS:"2021-02-05",ROS_ETA_DELTA:85,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA"},
      {NO:414,TAG:"21-V-304002",TAG_DESCR:"Vessel: Lp Flash Gas Compressor Suction Drum",PARENT_TAG:"21-V-304002",WEIGHT:0,SN:11,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",LV4:"ME01DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-12",ETA_ACTUAL:"",ROS:"2021-02-05",ROS_ETA_DELTA:85,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA"},
      {NO:411,TAG:"21-V-304003",TAG_DESCR:"Vessel: Mp Flash Gas Compressor Suction Drum",PARENT_TAG:"21-V-304003",WEIGHT:0,SN:8,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",LV4:"ME01DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-12",ETA_ACTUAL:"",ROS:"2021-03-12",ROS_ETA_DELTA:120,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA"},
      {NO:413,TAG:"21-V-304004",TAG_DESCR:"Vessel: Hp Flash Gas Compressor Suction Drum",PARENT_TAG:"21-V-304004",WEIGHT:0,SN:10,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",LV4:"ME01DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-12",ETA_ACTUAL:"",ROS:"2021-02-05",ROS_ETA_DELTA:85,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA"},
      {NO:651,TAG:"21-V-333001",TAG_DESCR:"Vessel: Turbo-Expander Scrubber",PARENT_TAG:"21-V-333001",WEIGHT:0,SN:8,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-01-12",ETA_ACTUAL:"",ROS:"2021-02-09",ROS_ETA_DELTA:28,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DA"},
      {NO:646,TAG:"21-V-333002",TAG_DESCR:"Vessel: Low Temperature Separator",PARENT_TAG:"21-V-333002",WEIGHT:0,SN:3,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-01-12",ETA_ACTUAL:"",ROS:"2021-02-09",ROS_ETA_DELTA:28,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DA"},
      {NO:655,TAG:"21-V-333003",TAG_DESCR:"Vessel: Gas Coalescer",PARENT_TAG:"21-V-333003",WEIGHT:0,SN:12,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-14",ETA_ACTUAL:"",ROS:"2021-01-27",ROS_ETA_DELTA:105,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DA"},
      {NO:20,TAG:"21-V-434001",TAG_DESCR:"Vessel: Closed Drains Drum",PARENT_TAG:"21-V-434001",WEIGHT:12820,SN:20,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-02-01",ETA_ACTUAL:"",ROS:"2021-03-30",ROS_ETA_DELTA:57,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:10,TAG:"21-V-501001",TAG_DESCR:"Vessel: Hp Flare Ko Drum",PARENT_TAG:"21-V-501001",WEIGHT:87980,SN:10,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-04-16",ETA_ACTUAL:"",ROS:"2021-05-28",ROS_ETA_DELTA:42,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:50,TAG:"21-V-521001",TAG_DESCR:"Vessel: Lp Flare Ko Drum",PARENT_TAG:"21-V-521001",WEIGHT:23180,SN:50,POSITION:"L",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-01-19",ETA_ACTUAL:"",ROS:"2021-01-12",ROS_ETA_DELTA:-7,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:740,TAG:"21-V-804001",TAG_DESCR:"Vessel: Heating Medium Expansion Drum",PARENT_TAG:"21-V-804001",WEIGHT:0,SN:8,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-28",ETA_ACTUAL:"",ROS:"2021-01-12",ROS_ETA_DELTA:45,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DC"},
      {NO:729,TAG:"21-V-805001",TAG_DESCR:"Vessel: Cooling Medium Expansion Drum",PARENT_TAG:"21-V-805001",WEIGHT:0,SN:9,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-28",ETA_ACTUAL:"",ROS:"2021-01-28",ROS_ETA_DELTA:61,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DB"},
      {NO:730,TAG:"21-V-812001",TAG_DESCR:"Vessel: Instrument Air Receiver",PARENT_TAG:"21-V-812001",WEIGHT:0,SN:10,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-28",ETA_ACTUAL:"",ROS:"2021-01-12",ROS_ETA_DELTA:45,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DB"},
      {NO:617,TAG:"21-SVS-615001",TAG_DESCR:"Deluge Skid For Flare Facilities And Marine Piperack Area",PARENT_TAG:"21-SVS-615001",WEIGHT:0,SN:3,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",LV4:"MW01DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-09-23",ETA_ACTUAL:"",ROS:"2020-10-19",ROS_ETA_DELTA:26,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBS_LEVEL:"MW01DA"},
      {NO:407,TAG:"21-SVS-615002",TAG_DESCR:"Deluge Skid For Cargo Tank Flare Area",PARENT_TAG:"21-SVS-615002",WEIGHT:0,SN:4,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",LV4:"ME01DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-09-23",ETA_ACTUAL:"",ROS:"2020-10-19",ROS_ETA_DELTA:26,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA"},
      {NO:660,TAG:"21-SVS-615003",TAG_DESCR:"Deluge Skid For Salty Rich Meg Tanks 2 And 3",PARENT_TAG:"21-SVS-615003",WEIGHT:0,SN:17,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-09-23",ETA_ACTUAL:"",ROS:"2020-10-19",ROS_ETA_DELTA:26,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DA"},
      {NO:444,TAG:"21-SVS-615004",TAG_DESCR:"Deluge Skid For Condensate Tanks 1 And 2 And Closed Drain Facilities",PARENT_TAG:"21-SVS-615004",WEIGHT:0,SN:11,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-09-23",ETA_ACTUAL:"",ROS:"2020-10-19",ROS_ETA_DELTA:26,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DA"},
      {NO:483,TAG:"21-SVS-615005",TAG_DESCR:"Deluge Skid For Condensate Tanks 3 And 4",PARENT_TAG:"21-SVS-615005",WEIGHT:0,SN:12,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",LV4:"ME04DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-09-23",ETA_ACTUAL:"",ROS:"2020-10-19",ROS_ETA_DELTA:26,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME04",GWBS_LEVEL:"ME04DA"},
      {NO:484,TAG:"21-SVS-615006",TAG_DESCR:"Deluge Skid For Condensate Tanks 5 And 6",PARENT_TAG:"21-SVS-615006",WEIGHT:0,SN:13,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",LV4:"ME04DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-09-23",ETA_ACTUAL:"",ROS:"2020-10-19",ROS_ETA_DELTA:26,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME04",GWBS_LEVEL:"ME04DA"},
      {NO:693,TAG:"21-SVS-615008",TAG_DESCR:"Deluge Skid For Lean Meg Tanks",PARENT_TAG:"21-SVS-615008",WEIGHT:0,SN:12,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW04",LV4:"MW04DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-09-23",ETA_ACTUAL:"",ROS:"2020-10-19",ROS_ETA_DELTA:26,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW04",GWBS_LEVEL:"MW04DA"},
      {NO:408,TAG:"21-SVS-615009",TAG_DESCR:"Deluge Skid For West Riser Balcony",PARENT_TAG:"21-SVS-615009",WEIGHT:0,SN:5,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",LV4:"ME01DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-09-23",ETA_ACTUAL:"",ROS:"2020-10-19",ROS_ETA_DELTA:26,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA"},
      {NO:42,TAG:"21-SVS-615012",TAG_DESCR:"Deluge Skid For Module Me01",PARENT_TAG:"21-SVS-615012",DIMENSION:"3800*1400*1100",WEIGHT:1850,SN:42,POSITION:"L",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-09-23",ETA_ACTUAL:"",ROS:"2020-10-19",ROS_ETA_DELTA:26,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:25,TAG:"21-SVS-615014",TAG_DESCR:"Deluge Skid For Module Mw02",PARENT_TAG:"21-SVS-615014",DIMENSION:"3800*1400*1100",WEIGHT:1510,SN:25,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-09-23",ETA_ACTUAL:"",ROS:"2020-10-19",ROS_ETA_DELTA:26,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:38,TAG:"21-SVS-615015",TAG_DESCR:"Deluge Skid For Module Me02",PARENT_TAG:"21-SVS-615015",DIMENSION:"3800*1400*1100",WEIGHT:1230,SN:38,POSITION:"L",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-09-23",ETA_ACTUAL:"",ROS:"2020-10-19",ROS_ETA_DELTA:26,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:21,TAG:"21-SVS-615016",TAG_DESCR:"Deluge Skid For Piperack Tsrc01 Between Mw01 And Me01",PARENT_TAG:"21-SVS-615016",DIMENSION:"3800*1400*1100",WEIGHT:1230,SN:21,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-09-23",ETA_ACTUAL:"",ROS:"2020-10-19",ROS_ETA_DELTA:26,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:39,TAG:"21-SVS-615017",TAG_DESCR:"Deluge Skid For Piperack Tsrc02 Between Mw02 And Me02",PARENT_TAG:"21-SVS-615017",DIMENSION:"3800*1400*1100",WEIGHT:1230,SN:39,POSITION:"L",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-09-23",ETA_ACTUAL:"",ROS:"2020-10-19",ROS_ETA_DELTA:26,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:18,TAG:"21-SVS-615018",TAG_DESCR:"Deluge Skid For Aft Platform 21-Smo-613002",PARENT_TAG:"21-SVS-615018",DIMENSION:"3800*1400*1100",WEIGHT:1680,SN:18,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-09-23",ETA_ACTUAL:"",ROS:"2020-10-19",ROS_ETA_DELTA:26,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:47,TAG:"21-SVS-615019",TAG_DESCR:"Deluge Skid For Aft Platform 21-Smo-613001",PARENT_TAG:"21-SVS-615019",DIMENSION:"3800*1400*1100",WEIGHT:1120,SN:47,POSITION:"L",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-09-23",ETA_ACTUAL:"",ROS:"2020-10-19",ROS_ETA_DELTA:26,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:98,TAG:"21-SVS-615020",TAG_DESCR:"Deluge Skid For Laydown 21-Smo-613003",PARENT_TAG:"21-SVS-615020",DIMENSION:"3800*1400*1100",WEIGHT:1120,SN:25,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-09-23",ETA_ACTUAL:"",ROS:"2020-10-19",ROS_ETA_DELTA:26,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03"},
      {NO:93,TAG:"21-SVS-615021",TAG_DESCR:"Deluge Skid For Laydown 21-Smo-613004",PARENT_TAG:"21-SVS-615021",DIMENSION:"3800*1400*1100",WEIGHT:1120,SN:20,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-09-23",ETA_ACTUAL:"",ROS:"2020-10-19",ROS_ETA_DELTA:26,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03"},
      {NO:79,TAG:"21-SVS-615022",TAG_DESCR:"Deluge Skid For Piperack Tsrc03 Between Mw03 And Me03",PARENT_TAG:"21-SVS-615022",DIMENSION:"3800*1400*1100",WEIGHT:2250,SN:6,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-09-23",ETA_ACTUAL:"",ROS:"2020-10-19",ROS_ETA_DELTA:26,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03"},
      {NO:75,TAG:"21-SVS-615023",TAG_DESCR:"Deluge Skid",PARENT_TAG:"21-SVS-615023",DIMENSION:"3800*1400*1100",WEIGHT:3110,SN:2,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:74,TAG:"21-SVS-615024",TAG_DESCR:"Deluge Skid",PARENT_TAG:"21-SVS-615024",DIMENSION:"3800*1400*1100",WEIGHT:3110,SN:1,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:412,TAG:"21-SWM-304001",TAG_DESCR:"Water Mist Fire Suppression System",PARENT_TAG:"21-Z-304001",SN:9,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",LV4:"ME01DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA"},
      {NO:377,TAG:"21-SWM-6111301",TAG_DESCR:"21-SWM-6111301",PARENT_TAG:"21-SWM-6111301",SN:1,POSITION:"L",LV1:"TOPSIDES",LV2:"COMMON",LV3:"KO01",LV4:"KO01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO"},
      {NO:15,TAG:"21-P-935001A",TAG_DESCR:"Ballast Pump",PARENT_TAG:"21-P-935001A",DIMENSION:"L=32000",WEIGHT:2332,SN:15,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-07-07",ETA_ACTUAL:"",ROS:"2021-04-19",ROS_ETA_DELTA:286,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:81,TAG:"21-P-935001B",TAG_DESCR:"Ballast Pump",PARENT_TAG:"21-P-935001B",DIMENSION:"L=32000",WEIGHT:2332,SN:8,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-07-07",ETA_ACTUAL:"",ROS:"2021-04-19",ROS_ETA_DELTA:286,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03"},
      {NO:91,TAG:"21-P-935001C",TAG_DESCR:"Ballast Pump",PARENT_TAG:"21-P-935001C",DIMENSION:"L=32000",WEIGHT:2332,SN:18,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-07-07",ETA_ACTUAL:"",ROS:"2021-04-19",ROS_ETA_DELTA:286,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03"},
      {NO:49,TAG:"21-P-935001D",TAG_DESCR:"Ballast Pump",PARENT_TAG:"21-P-935001D",DIMENSION:"L=32000",WEIGHT:2332,SN:49,POSITION:"L",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-07-07",ETA_ACTUAL:"",ROS:"2021-04-19",ROS_ETA_DELTA:286,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:99,TAG:"21-SPF-611101",TAG_DESCR:"Diesel Driven Electrical Submersible Fire Water Pump",PARENT_TAG:"21-Z-611101",WEIGHT:18070,SN:26,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-12-14",ETA_ACTUAL:"",ROS:"2021-02-06",ROS_ETA_DELTA:54,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03"},
      {NO:139,TAG:"21-SPF-611201",TAG_DESCR:"Diesel Driven Electrical Submersible Fire Water Pump",PARENT_TAG:"21-Z-611201",WEIGHT:18070,SN:66,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-12-14",ETA_ACTUAL:"",ROS:"2021-02-06",ROS_ETA_DELTA:54,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03"},
      {NO:53,TAG:"21-SPF-611301",TAG_DESCR:"Diesel Driven Electrical Submersible Fire Water Pump",PARENT_TAG:"21-Z-611301",WEIGHT:18070,SN:53,POSITION:"L",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-12-14",ETA_ACTUAL:"",ROS:"2021-02-06",ROS_ETA_DELTA:54,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:451,TAG:"21-SSH-641009",TAG_DESCR:"21-SSH-641009",PARENT_TAG:"21-SSH-641009",SN:18,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DA"},
      {NO:446,TAG:"21-V-813001",TAG_DESCR:"Vessel: Fuel Gas Ko Drum",PARENT_TAG:"21-V-813001",WEIGHT:0,SN:13,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-12-07",ETA_ACTUAL:"",ROS:"2021-01-12",ROS_ETA_DELTA:36,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DA"},
      {NO:728,TAG:"21-V-815001",TAG_DESCR:"Nitrogen Generation Package",PARENT_TAG:"21-V-815001",WEIGHT:0,SN:8,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-28",ETA_ACTUAL:"",ROS:"2021-01-12",ROS_ETA_DELTA:45,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DB"},
      {NO:657,TAG:"21-V-833001",TAG_DESCR:"Vessel: Wax Inhibitor Break Drum",PARENT_TAG:"21-V-833001",WEIGHT:0,SN:14,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-28",ETA_ACTUAL:"",ROS:"2021-01-12",ROS_ETA_DELTA:45,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DA"},
      {NO:656,TAG:"21-V-851001",TAG_DESCR:"Vessel: Methanol Break Drum",PARENT_TAG:"21-V-851001",WEIGHT:0,SN:13,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-28",ETA_ACTUAL:"",ROS:"2021-01-12",ROS_ETA_DELTA:45,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DA"},
      {NO:380,TAG:"21-Z-244001_STRU",TAG_DESCR:"21-Z-244001_STRU",PARENT_TAG:"21-Z-244001_STRU",SN:4,POSITION:"R",LV1:"TOPSIDES",LV2:"COMMON",LV3:"KO01",LV4:"KO01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO"},
      {NO:2,TAG:"21-Z-244010",TAG_DESCR:"Equipment Package: Condensate Tandem Offloading Package",PARENT_TAG:"21-Z-244010",WEIGHT:230900,SN:2,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-07",ETA_ACTUAL:"",ROS:"2021-01-17",ROS_ETA_DELTA:71,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:447,TAG:"21-Z-253001",TAG_DESCR:"Meg Regeneration Package",PARENT_TAG:"21-Z-253001",WEIGHT:0,SN:14,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-04-19",ETA_ACTUAL:"",ROS:"2021-05-29",ROS_ETA_DELTA:40,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DA"},
      {NO:448,TAG:"21-Z-253002",TAG_DESCR:" N2 Bottle Skid",PARENT_TAG:"21-Z-253002",SN:15,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DA"},
      {NO:76,TAG:"21-Z-281001",TAG_DESCR:"Sand Separation Package ( Future)",PARENT_TAG:"21-Z-281001",WEIGHT:2500,SN:3,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:415,TAG:"21-Z-304001",TAG_DESCR:"Flash Gas Compressor Llp-Lp - Dry Screw Compressor",PARENT_TAG:"21-Z-304001",WEIGHT:0,SN:12,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",LV4:"ME01DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-01-19",ETA_ACTUAL:"",ROS:"2021-02-19",ROS_ETA_DELTA:31,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA"},
      {NO:409,TAG:"21-Z-304003",TAG_DESCR:"Equipment Package: Mp,Hp Flash Gas Compressor Package",PARENT_TAG:"21-Z-304003",WEIGHT:0,SN:6,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",LV4:"ME01DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-12-15",ETA_ACTUAL:"",ROS:"2021-02-07",ROS_ETA_DELTA:54,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA"},
      {NO:672,TAG:"21-Z-333001",TAG_DESCR:"Turbo Expander Recompressor Package",PARENT_TAG:"21-Z-333001",WEIGHT:0,SN:4,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV4:"MW02DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-18",ETA_ACTUAL:"",ROS:"2020-12-10",ROS_ETA_DELTA:22,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBS_LEVEL:"MW02DC"},
      {NO:449,TAG:"21-Z-413002",TAG_DESCR:"Deoiling Package",PARENT_TAG:"21-Z-413002",WEIGHT:0,SN:16,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-09",ETA_ACTUAL:"",ROS:"2020-12-10",ROS_ETA_DELTA:31,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DA"},
      {NO:457,TAG:"21-Z-413005",TAG_DESCR:"Produced Water Polishing Package",PARENT_TAG:"21-Z-413005",WEIGHT:0,SN:4,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-09",ETA_ACTUAL:"",ROS:"2020-12-10",ROS_ETA_DELTA:31,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DB"},
      {NO:450,TAG:"21-Z-415001",TAG_DESCR:"Mercury Removal Package",PARENT_TAG:"21-Z-415001",WEIGHT:0,SN:17,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV4:"ME02DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-09",ETA_ACTUAL:"",ROS:"2020-12-10",ROS_ETA_DELTA:31,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBS_LEVEL:"ME02DA"},
      {NO:12,TAG:"21-Z-503001",TAG_DESCR:"Hp And Lp Flare Pilot Ignition System",PARENT_TAG:"21-Z-503001",WEIGHT:1400,SN:12,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-13",ETA_ACTUAL:"",ROS:"2020-11-18",ROS_ETA_DELTA:36,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:421,TAG:"21-Z-572001",TAG_DESCR:"Lp Flare Vapor Recovery Package",PARENT_TAG:"21-Z-572001",WEIGHT:0,SN:4,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",LV4:"ME01DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-02-01",ETA_ACTUAL:"",ROS:"2021-04-01",ROS_ETA_DELTA:59,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DB"},
      {NO:404,TAG:"21-Z-572002",TAG_DESCR:"Hull Vapor Recovery Package",PARENT_TAG:"21-Z-572002",WEIGHT:0,SN:1,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",LV4:"ME01DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-02-01",ETA_ACTUAL:"",ROS:"2021-04-01",ROS_ETA_DELTA:59,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBS_LEVEL:"ME01DA"},
      {NO:100,TAG:"21-Z-611101",TAG_DESCR:"Fire Water Pump Package",PARENT_TAG:"21-Z-611101",WEIGHT:66436,SN:27,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03"},
      {NO:115,TAG:"21-Z-611201",TAG_DESCR:"Fire Water Pump Package",PARENT_TAG:"21-Z-611201",WEIGHT:66436,SN:42,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03"},
      {NO:61,TAG:"21-Z-611301",TAG_DESCR:"Fire Water Pump Package",PARENT_TAG:"21-Z-611301",WEIGHT:66440,SN:61,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:92,TAG:"21-Z-800001",TAG_DESCR:"Bunker Station",PARENT_TAG:"21-Z-800001",WEIGHT:17580,SN:19,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"DECKF",LV4:"FOR",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-11-08",ETA_ACTUAL:"",ROS:"2020-12-23",ROS_ETA_DELTA:45,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03"},
      {NO:708,TAG:"21-Z-802001",TAG_DESCR:"Sea Water Coarse Filter Package",PARENT_TAG:"21-Z-802001",WEIGHT:0,SN:4,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-09-26",ETA_ACTUAL:"",ROS:"2021-03-04",ROS_ETA_DELTA:159,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DA"},
      {NO:724,TAG:"21-Z-802002",TAG_DESCR:"Electrochlorination Package",PARENT_TAG:"21-Z-802002",WEIGHT:0,SN:4,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-23",ETA_ACTUAL:"",ROS:"2020-12-16",ROS_ETA_DELTA:54,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DB"},
      {NO:699,TAG:"21-Z-804101",TAG_DESCR:"Waste Heat Recovery Package 1",PARENT_TAG:"21-Z-804101",WEIGHT:0,SN:5,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW04",LV4:"MW04DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-09-17",ETA_ACTUAL:"",ROS:"2020-12-06",ROS_ETA_DELTA:80,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW04",GWBS_LEVEL:"MW04DB"},
      {NO:700,TAG:"21-Z-804201",TAG_DESCR:"Waste Heat Recovery Package 2",PARENT_TAG:"21-Z-804201",WEIGHT:0,SN:6,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW04",LV4:"MW04DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-09-17",ETA_ACTUAL:"",ROS:"2020-12-06",ROS_ETA_DELTA:80,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW04",GWBS_LEVEL:"MW04DB"},
      {NO:493,TAG:"21-Z-804301",TAG_DESCR:"Waste Heat Recovery Package 3",PARENT_TAG:"21-Z-804301",WEIGHT:0,SN:5,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",LV4:"ME04DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-09-17",ETA_ACTUAL:"",ROS:"2020-12-06",ROS_ETA_DELTA:80,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME04",GWBS_LEVEL:"ME04DA"},
      {NO:494,TAG:"21-Z-804401",TAG_DESCR:"Waste Heat Recovery Package 4",PARENT_TAG:"21-Z-804401",WEIGHT:0,SN:6,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",LV4:"ME04DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-09-17",ETA_ACTUAL:"",ROS:"2020-12-06",ROS_ETA_DELTA:80,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME04",GWBS_LEVEL:"ME04DA"},
      {NO:733,TAG:"21-Z-811101",TAG_DESCR:"21-Z-811101",PARENT_TAG:"21-Z-811101",WEIGHT:0,SN:1,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-12-11",ETA_ACTUAL:"",ROS:"2021-01-29",ROS_ETA_DELTA:49,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DC"},
      {NO:734,TAG:"21-Z-811201",TAG_DESCR:"21-Z-811201",PARENT_TAG:"21-Z-811201",WEIGHT:0,SN:2,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-12-11",ETA_ACTUAL:"",ROS:"2021-01-29",ROS_ETA_DELTA:49,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DC"},
      {NO:735,TAG:"21-Z-811301",TAG_DESCR:"21-Z-811301",PARENT_TAG:"21-Z-811301",WEIGHT:0,SN:3,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-12-11",ETA_ACTUAL:"",ROS:"2021-01-29",ROS_ETA_DELTA:49,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DC"},
      {NO:739,TAG:"21-Z-812001",TAG_DESCR:"Dryer Package",PARENT_TAG:"21-Z-812001",WEIGHT:0,SN:7,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-12-11",ETA_ACTUAL:"",ROS:"2021-01-29",ROS_ETA_DELTA:49,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DC"},
      {NO:736,TAG:"21-Z-815001",TAG_DESCR:"21-Z-815001",WEIGHT:0,SN:4,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DC",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-17",ETA_ACTUAL:"",ROS:"2021-01-09",ROS_ETA_DELTA:84,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DC"},
      {NO:381,TAG:"21-Z-816001",TAG_DESCR:"Inert Gas Generator Package",PARENT_TAG:"21-Z-816001",WEIGHT:44200,SN:5,POSITION:"L",LV1:"TOPSIDES",LV2:"COMMON",LV3:"KO01",LV4:"KO01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2021-04-30",ETA_ACTUAL:"",ROS:"2021-06-12",ROS_ETA_DELTA:43,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO"},
      {NO:707,TAG:"21-Z-830001",TAG_DESCR:"Production Chemical Injection Package",PARENT_TAG:"21-Z-830001",WEIGHT:0,SN:3,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-13",ETA_ACTUAL:"",ROS:"2021-01-16",ROS_ETA_DELTA:95,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DA"},
      {NO:723,TAG:"21-Z-830002",TAG_DESCR:"Process Chemical Injection Package",PARENT_TAG:"21-Z-830002",WEIGHT:0,SN:3,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV4:"MW05DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-10-20",ETA_ACTUAL:"",ROS:"2021-01-16",ROS_ETA_DELTA:88,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBS_LEVEL:"MW05DB"},
      {NO:686,TAG:"21-Z-861101",TAG_DESCR:"Gas Turbine Generator Package",PARENT_TAG:"21-Z-861101",WEIGHT:0,SN:5,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW04",LV4:"MW04DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-06-07",ETA_ACTUAL:"",ROS:"2020-09-27",ROS_ETA_DELTA:112,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW04",GWBS_LEVEL:"MW04DA"},
      {NO:695,TAG:"21-Z-861105",TAG_DESCR:"Duplex Lube Oil Cooler With Transfer Valve",PARENT_TAG:"21-Z-861101",SN:1,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW04",LV4:"MW04DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW04",GWBS_LEVEL:"MW04DB"},
      {NO:687,TAG:"21-Z-861107",TAG_DESCR:"Main Liquid Fuel Filter Assembly",PARENT_TAG:"21-Z-861101",SN:6,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW04",LV4:"MW04DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW04",GWBS_LEVEL:"MW04DA"},
      {NO:689,TAG:"21-Z-861201",TAG_DESCR:"Gas Turbine Generator Package",PARENT_TAG:"21-Z-861201",WEIGHT:0,SN:8,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW04",LV4:"MW04DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-06-07",ETA_ACTUAL:"",ROS:"2020-09-27",ROS_ETA_DELTA:112,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW04",GWBS_LEVEL:"MW04DA"},
      {NO:696,TAG:"21-Z-861205",TAG_DESCR:"Duplex Lube Oil Cooler With Transfer Valve",PARENT_TAG:"21-Z-861201",SN:2,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW04",LV4:"MW04DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW04",GWBS_LEVEL:"MW04DB"},
      {NO:690,TAG:"21-Z-861207",TAG_DESCR:"Main Liquid Fuel Filter Assembly",PARENT_TAG:"21-Z-861201",SN:9,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW04",LV4:"MW04DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW04",GWBS_LEVEL:"MW04DA"},
      {NO:478,TAG:"21-Z-861301",TAG_DESCR:"Gas Turbine Generator Package",PARENT_TAG:"21-Z-861301",WEIGHT:0,SN:7,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",LV4:"ME04DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-06-07",ETA_ACTUAL:"",ROS:"2020-09-27",ROS_ETA_DELTA:112,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME04",GWBS_LEVEL:"ME04DA"},
      {NO:496,TAG:"21-Z-861305",TAG_DESCR:"Duplex Lube Oil Cooler With Transfer Valve",PARENT_TAG:"21-Z-861301",SN:8,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",LV4:"ME04DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME04",GWBS_LEVEL:"ME04DB"},
      {NO:479,TAG:"21-Z-861307",TAG_DESCR:"Main Liquid Fuel Filter Assembly",PARENT_TAG:"21-Z-861301",SN:8,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",LV4:"ME04DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME04",GWBS_LEVEL:"ME04DA"},
      {NO:481,TAG:"21-Z-861401",TAG_DESCR:"Gas Turbine Generator Package",PARENT_TAG:"21-Z-861401",WEIGHT:0,SN:10,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",LV4:"ME04DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-06-07",ETA_ACTUAL:"",ROS:"2020-09-27",ROS_ETA_DELTA:112,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME04",GWBS_LEVEL:"ME04DA"},
      {NO:495,TAG:"21-Z-861405",TAG_DESCR:"Duplex Lube Oil Cooler With Transfer Valve",PARENT_TAG:"21-Z-861401",SN:7,POSITION:"R",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",LV4:"ME04DB",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME04",GWBS_LEVEL:"ME04DB"},
      {NO:482,TAG:"21-Z-861407",TAG_DESCR:"Main Liquid Fuel Filter Assembly",PARENT_TAG:"21-Z-861401",SN:11,POSITION:"L",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",LV4:"ME04DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME04",GWBS_LEVEL:"ME04DA"},
      {NO:3,TAG:"21-Z-937010",TAG_DESCR:"Slops Polishing Unit",PARENT_TAG:"21-Z-937010",DIMENSION:"5600*4350*2960",WEIGHT:11000,SN:3,POSITION:"R",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"2019-09-11",PO_ACTUAL:"2019-09-11",IRN_PLAN:"",ETA_PLAN:"2021-01-15",ETA_ACTUAL:"",ROS:"2021-01-16",ROS_ETA_DELTA:1,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:4,TAG:"21-Z-937015",TAG_DESCR:"Odms Package",PARENT_TAG:"21-Z-937010",SN:4,POSITION:"L",LV1:"HULL",LV2:"HULL-AFT",LV3:"DECKA",LV4:"AFT",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02"},
      {NO:331,TAG:"21-Z-992401",TAG_DESCR:"21-Z-992401",PARENT_TAG:"21-Z-992401",SN:17,LV1:"HULL",LV2:"LQ",LV3:"LQ03",LV4:"LQ03",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",GWBS_LEVEL:"LV2"},
      {NO:401,TAG:"EAST-ROV",TAG_DESCR:"East-Rov",SN:17,POSITION:"L",LV1:"TOPSIDES",LV2:"COMMON",LV3:"LD00DA",LV4:"LD00DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO"},
      {NO:385,TAG:"LD00DA/ME/TOTE-TANK/01",TAG_DESCR:"Ld00Da/Me/Tote-Tank/01",SN:1,POSITION:"R",LV1:"TOPSIDES",LV2:"COMMON",LV3:"LD00DA",LV4:"LD00DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO"},
      {NO:403,TAG:"LD00DA/PO/B/ME/Z/EAST_ROV_CABINET",TAG_DESCR:"Ld00Da/Po/B/Me/Z/East_Rov_Cabinet",SN:19,POSITION:"R",LV1:"TOPSIDES",LV2:"COMMON",LV3:"LD00DA",LV4:"LD00DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO"},
      {NO:402,TAG:"LD00DA/PO/B/ME/Z/EAST_ROV_HPU",TAG_DESCR:"Ld00Da/Po/B/Me/Z/East_Rov_Hpu",SN:18,POSITION:"L",LV1:"TOPSIDES",LV2:"COMMON",LV3:"LD00DA",LV4:"LD00DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO"},
      {NO:400,TAG:"LD00DA/PO/B/ME/Z/EAST_ROV_TRANSFO",TAG_DESCR:"Ld00Da/Po/B/Me/Z/East_Rov_Transfo",SN:16,POSITION:"L",LV1:"TOPSIDES",LV2:"COMMON",LV3:"LD00DA",LV4:"LD00DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO"},
      {NO:387,TAG:"LD00DA/PO/B/ME/Z/WEST_ROV_CABINET",TAG_DESCR:"Ld00Da/Po/B/Me/Z/West_Rov_Cabinet",SN:3,POSITION:"R",LV1:"TOPSIDES",LV2:"COMMON",LV3:"LD00DA",LV4:"LD00DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO"},
      {NO:390,TAG:"LD00DA/PO/B/ME/Z/WEST_ROV_HPU",TAG_DESCR:"Ld00Da/Po/B/Me/Z/West_Rov_Hpu",SN:6,POSITION:"L",LV1:"TOPSIDES",LV2:"COMMON",LV3:"LD00DA",LV4:"LD00DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO"},
      {NO:389,TAG:"LD00DA/PO/B/ME/Z/WEST_ROV_TRANSFO",TAG_DESCR:"Ld00Da/Po/B/Me/Z/West_Rov_Transfo",SN:5,POSITION:"L",LV1:"TOPSIDES",LV2:"COMMON",LV3:"LD00DA",LV4:"LD00DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO"},
      {NO:393,TAG:"NON_HAZARDOUS_WASTE_CONTAINER_10FT/01",TAG_DESCR:"Non_Hazardous_Waste_Container_10Ft/01",SN:9,POSITION:"L",LV1:"TOPSIDES",LV2:"COMMON",LV3:"LD00DA",LV4:"LD00DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO"},
      {NO:392,TAG:"NON_HAZARDOUS_WASTE_CONTAINER_10FT/02",TAG_DESCR:"Non_Hazardous_Waste_Container_10Ft/02",SN:8,POSITION:"R",LV1:"TOPSIDES",LV2:"COMMON",LV3:"LD00DA",LV4:"LD00DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO"},
      {NO:391,TAG:"NON_HAZARDOUS_WASTE_CONTAINER_20FT/01",TAG_DESCR:"Non_Hazardous_Waste_Container_20Ft/01",SN:7,POSITION:"L",LV1:"TOPSIDES",LV2:"COMMON",LV3:"LD00DA",LV4:"LD00DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO"},
      {NO:386,TAG:"NON_HAZARDOUS_WASTE_CONTAINER_20FT/02",TAG_DESCR:"Non_Hazardous_Waste_Container_20Ft/02",SN:2,POSITION:"R",LV1:"TOPSIDES",LV2:"COMMON",LV3:"LD00DA",LV4:"LD00DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO"},
      {NO:399,TAG:"SKIP01/GLASS(5.5M3)",TAG_DESCR:"Skip01/Glass(5.5M3)",SN:15,POSITION:"L",LV1:"TOPSIDES",LV2:"COMMON",LV3:"LD00DA",LV4:"LD00DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO"},
      {NO:395,TAG:"SKIP02/PLASTICS_(11M3)",TAG_DESCR:"Skip02/Plastics_(11M3)",SN:11,POSITION:"L",LV1:"TOPSIDES",LV2:"COMMON",LV3:"LD00DA",LV4:"LD00DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO"},
      {NO:394,TAG:"SKIP02/WOOD_(11M3)",TAG_DESCR:"Skip02/Wood_(11M3)",SN:10,POSITION:"L",LV1:"TOPSIDES",LV2:"COMMON",LV3:"LD00DA",LV4:"LD00DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO"},
      {NO:396,TAG:"SKIP04/OTHER_WASTES_(12M3)",TAG_DESCR:"Skip04/Other_Wastes_(12M3)",SN:12,POSITION:"L",LV1:"TOPSIDES",LV2:"COMMON",LV3:"LD00DA",LV4:"LD00DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO"},
      {NO:397,TAG:"SKIP05/METAL_(12M3)",TAG_DESCR:"Skip05/Metal_(12M3)",SN:13,POSITION:"L",LV1:"TOPSIDES",LV2:"COMMON",LV3:"LD00DA",LV4:"LD00DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO"},
      {NO:398,TAG:"SKIP05/PAPER_AND_CARDBOARD_(8M3)",TAG_DESCR:"Skip05/Paper_And_Cardboard_(8M3)",SN:14,POSITION:"L",LV1:"TOPSIDES",LV2:"COMMON",LV3:"LD00DA",LV4:"LD00DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO"},
      {NO:388,TAG:"WEST_ROV",TAG_DESCR:"West_Rov",SN:4,POSITION:"R",LV1:"TOPSIDES",LV2:"COMMON",LV3:"LD00DA",LV4:"LD00DA",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK_ERECTION:"",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO"},
      {NO:229,TAG:"21-P-802008A",TAG_DESCR:"Hull Service Seawater Booster Pump",PARENT_TAG:"21-P-802008A",DIMENSION:"1400*610*680",WEIGHT:482,SN:19,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"2019-10-31",PO_ACTUAL:"2019-10-31",IRN_PLAN:"",ETA_PLAN:"2020-12-15",ETA_ACTUAL:"",ROS:"2020-12-21",ROS_ETA_DELTA:6,UPPERBLOCK:"61A",UPPERBLOCK_ERECTION:"2020-07-31",ROS_ETA_UPPERBLOCK_DELTA:-137,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"3RD DECK",LOCATION:"MACHINERY SPACE"},
      {NO:230,TAG:"21-P-802008B",TAG_DESCR:"Hull Service Seawater Booster Pump",PARENT_TAG:"21-P-802008B",DIMENSION:"1400*610*680",WEIGHT:482,SN:20,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"2019-10-31",PO_ACTUAL:"2019-10-31",IRN_PLAN:"",ETA_PLAN:"2020-12-15",ETA_ACTUAL:"",ROS:"2020-12-21",ROS_ETA_DELTA:6,UPPERBLOCK:"61A",UPPERBLOCK_ERECTION:"2020-07-31",ROS_ETA_UPPERBLOCK_DELTA:-137,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"3RD DECK",LOCATION:"MACHINERY SPACE"},
      {NO:227,TAG:"21-HCB-997305",TAG_DESCR:"Machinery Space Supply Fan A ",PARENT_TAG:"21-HCB-997305",DIMENSION:"3700*2800*2450",WEIGHT:4480,SN:17,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-09-14",ETA_ACTUAL:"",ROS:"2020-09-14",ROS_ETA_DELTA:0,UPPERBLOCK:"61A",UPPERBLOCK_ERECTION:"2020-07-31",ROS_ETA_UPPERBLOCK_DELTA:-45,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"3RD DECK",LOCATION:"MACHINERY SPACE",METHOD:"Y"},
      {NO:228,TAG:"21-HCB-997306",TAG_DESCR:"Machinery Space Supply Fan B ",PARENT_TAG:"21-HCB-997306",DIMENSION:"3700*2600*2450",WEIGHT:4480,SN:18,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-09-14",ETA_ACTUAL:"",ROS:"2020-09-14",ROS_ETA_DELTA:0,UPPERBLOCK:"61A",UPPERBLOCK_ERECTION:"2020-07-31",ROS_ETA_UPPERBLOCK_DELTA:-45,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"3RD DECK",LOCATION:"MACHINERY SPACE",METHOD:"Y"},
      {NO:238,TAG:"21-ETR-667302A",TAG_DESCR:"Main Essential Transformer 6.6/0.72Kv 2500Kva",PARENT_TAG:"21-ETR-667302A",WEIGHT:0,SN:28,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-09-23",ETA_ACTUAL:"",ROS:"2020-12-21",ROS_ETA_DELTA:89,UPPERBLOCK:"61A",UPPERBLOCK_ERECTION:"2020-07-31",ROS_ETA_UPPERBLOCK_DELTA:-54,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"3RD DECK",LOCATION:"MACHINERY SPACE",ROOM:"M305 TRANSFORMER ROOM"},
      {NO:233,TAG:"21-ETR-667302B",TAG_DESCR:"Main Essential Transformer 6.6/0.72Kv 2500Kva",PARENT_TAG:"21-ETR-667302B",WEIGHT:0,SN:23,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-09-23",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-39,UPPERBLOCK:"61A",UPPERBLOCK_ERECTION:"2020-07-31",ROS_ETA_UPPERBLOCK_DELTA:-54,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"3RD DECK",LOCATION:"MACHINERY SPACE",ROOM:"M305 TRANSFORMER ROOM"},
      {NO:240,TAG:"21-ECP-667001",TAG_DESCR:"21-ECP-667001",PARENT_TAG:"21-ECP-667001",SN:30,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK:"61A",UPPERBLOCK_ERECTION:"2020-07-31",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"3RD DECK",LOCATION:"MACHINERY SPACE"},
      {NO:239,TAG:"21-ECP-667002",TAG_DESCR:"21-ECP-667002",PARENT_TAG:"21-ECP-667002",SN:29,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK:"61A",UPPERBLOCK_ERECTION:"2020-07-31",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"3RD DECK",LOCATION:"MACHINERY SPACE"},
      {NO:142,TAG:"21-HAH-997101",TAG_DESCR:"Hull Air Handling Unit A (1X100%)",PARENT_TAG:"21-HAH-997101",DIMENSION:"6200*4500*3600",WEIGHT:9140,SN:3,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-09-14",ETA_ACTUAL:"",ROS:"2020-09-14",ROS_ETA_DELTA:0,UPPERBLOCK:"61B",UPPERBLOCK_ERECTION:"2020-10-08",ROS_ETA_UPPERBLOCK_DELTA:24,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE",ROOM:"M102 AHU ROOM",METHOD:"Y"},
      {NO:145,TAG:"21-HAH-997102",TAG_DESCR:"Hull Air Handling Unit B (1X100%)",PARENT_TAG:"21-HAH-997102",DIMENSION:"6200*4500*3600",WEIGHT:9140,SN:6,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-09-14",ETA_ACTUAL:"",ROS:"2020-09-14",ROS_ETA_DELTA:0,UPPERBLOCK:"61B",UPPERBLOCK_ERECTION:"2020-10-08",ROS_ETA_UPPERBLOCK_DELTA:24,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE",ROOM:"M102 AHU ROOM",METHOD:"Y"},
      {NO:158,TAG:"21-HCB-997105",TAG_DESCR:"Machinery Space Exhaust Fan A ",PARENT_TAG:"21-HCB-997105",DIMENSION:"3150*2600*2600",WEIGHT:3191,SN:19,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-09-14",ETA_ACTUAL:"",ROS:"2020-09-14",ROS_ETA_DELTA:0,UPPERBLOCK:"61B",UPPERBLOCK_ERECTION:"2020-10-08",ROS_ETA_UPPERBLOCK_DELTA:24,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE",METHOD:"Y"},
      {NO:157,TAG:"21-HCB-997106",TAG_DESCR:"Machinery Space Exhaust Fan B ",PARENT_TAG:"21-HCB-997106",DIMENSION:"3150*2600*2600",WEIGHT:3191,SN:18,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-09-14",ETA_ACTUAL:"",ROS:"2020-09-14",ROS_ETA_DELTA:0,UPPERBLOCK:"61B",UPPERBLOCK_ERECTION:"2020-10-08",ROS_ETA_UPPERBLOCK_DELTA:24,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE",METHOD:"Y"},
      {NO:140,TAG:"21-HCB-997107",TAG_DESCR:"Generator Room Exhaust Fan A",PARENT_TAG:"21-HCB-997107",SN:1,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK:"61B",UPPERBLOCK_ERECTION:"2020-10-08",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE",NOTE:"UP",METHOD:"Y"},
      {NO:141,TAG:"21-HCB-997108",TAG_DESCR:"Generator Room Exhaust Fan B",PARENT_TAG:"21-HCB-997108",SN:2,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK:"61B",UPPERBLOCK_ERECTION:"2020-10-08",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE",NOTE:"DOWN",METHOD:"Y"},
      {NO:150,TAG:"21-HCB-997109",TAG_DESCR:"Generator Room Suplly Fan A",PARENT_TAG:"21-HCB-997109",SN:11,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK:"61B",UPPERBLOCK_ERECTION:"2020-10-08",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE",NOTE:"UP",METHOD:"Y"},
      {NO:151,TAG:"21-HCB-997110",TAG_DESCR:"Generator Room Suplly Fan B",PARENT_TAG:"21-HCB-997110",SN:12,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK:"61B",UPPERBLOCK_ERECTION:"2020-10-08",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE",NOTE:"DOWN",METHOD:"Y"},
      {NO:152,TAG:"21-HCB-997111",TAG_DESCR:"Generator Room Suplly Fan C",PARENT_TAG:"21-HCB-997111",DIMENSION:"2750*2000*2050",WEIGHT:1894,SN:13,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-09-14",ETA_ACTUAL:"",ROS:"2020-09-14",ROS_ETA_DELTA:0,UPPERBLOCK:"61B",UPPERBLOCK_ERECTION:"2020-10-08",ROS_ETA_UPPERBLOCK_DELTA:24,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE",METHOD:"Y"},
      {NO:143,TAG:"21-HCB-997112",TAG_DESCR:"Hull Emergency Pressurization Fan A",PARENT_TAG:"21-HCB-997112",SN:4,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK:"61B",UPPERBLOCK_ERECTION:"2020-10-08",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE",ROOM:"M103 SEWAGE TREATMENT ROOM",NOTE:"UP",METHOD:"Y"},
      {NO:144,TAG:"21-HCB-997113",TAG_DESCR:"Hull Emergency Pressurization Fan B",PARENT_TAG:"21-HCB-997113",SN:5,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK:"61B",UPPERBLOCK_ERECTION:"2020-10-08",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE",ROOM:"M103 SEWAGE TREATMENT ROOM",NOTE:"DOWN",METHOD:"Y"},
      {NO:149,TAG:"21-P-809001",TAG_DESCR:"Vacuum Pump Unit",PARENT_TAG:"21-P-809001",DIMENSION:"897*700*1252",WEIGHT:255,SN:10,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"2019-08-09",PO_ACTUAL:"2019-08-09",IRN_PLAN:"",ETA_PLAN:"2020-09-28",ETA_ACTUAL:"",ROS:"2020-11-05",ROS_ETA_DELTA:38,UPPERBLOCK:"61B",UPPERBLOCK_ERECTION:"2020-10-08",ROS_ETA_UPPERBLOCK_DELTA:10,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE",ROOM:"M103 SEWAGE TREATMENT ROOM",METHOD:"Y"},
      {NO:153,TAG:"21-P-809002",TAG_DESCR:"Sludge Transfer Pump",PARENT_TAG:"21-P-809002",DIMENSION:"400*230*440",WEIGHT:30,SN:14,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"2020-06-30",PO_ACTUAL:"2020-06-30",IRN_PLAN:"",ETA_PLAN:"2021-01-27",ETA_ACTUAL:"",ROS:"2021-01-30",ROS_ETA_DELTA:3,UPPERBLOCK:"61B",UPPERBLOCK_ERECTION:"2020-10-08",ROS_ETA_UPPERBLOCK_DELTA:-111,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE",ROOM:"M103 SEWAGE TREATMENT ROOM",METHOD:"Y"},
      {NO:148,TAG:"21-Z-809001",TAG_DESCR:"Sewage Water Treatment Unit",PARENT_TAG:"21-Z-809001",DIMENSION:"3825*2385*2590",WEIGHT:4500,SN:9,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"2019-08-09",PO_ACTUAL:"2019-08-09",IRN_PLAN:"",ETA_PLAN:"2020-09-28",ETA_ACTUAL:"",ROS:"2020-11-05",ROS_ETA_DELTA:38,UPPERBLOCK:"61B",UPPERBLOCK_ERECTION:"2020-10-08",ROS_ETA_UPPERBLOCK_DELTA:10,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE",ROOM:"M103 SEWAGE TREATMENT ROOM",METHOD:"Y"},
      {NO:236,TAG:"21-ETR-667303A",TAG_DESCR:"Emergency Transformer 6.6/0.72Kv 2000Kva",PARENT_TAG:"21-ETR-667303A",WEIGHT:0,SN:26,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-09-23",ETA_ACTUAL:"",ROS:"2020-12-21",ROS_ETA_DELTA:89,UPPERBLOCK:"62A",UPPERBLOCK_ERECTION:"2020-08-03",ROS_ETA_UPPERBLOCK_DELTA:-51,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"3RD DECK",LOCATION:"MACHINERY SPACE",ROOM:"M305 TRANSFORMER ROOM"},
      {NO:235,TAG:"21-ETR-667303B",TAG_DESCR:"Emergency Transformer 6.6/0.72Kv 2000Kva",PARENT_TAG:"21-ETR-667303B",WEIGHT:0,SN:25,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-09-23",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-39,UPPERBLOCK:"62A",UPPERBLOCK_ERECTION:"2020-08-03",ROS_ETA_UPPERBLOCK_DELTA:-51,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"3RD DECK",LOCATION:"MACHINERY SPACE",ROOM:"M305 TRANSFORMER ROOM"},
      {NO:237,TAG:"21-ETR-667301A",TAG_DESCR:"Normal / Essential Transformer 6.6/0.42Kv 2000Kva",PARENT_TAG:"21-ETR-667301A",WEIGHT:0,SN:27,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-09-23",ETA_ACTUAL:"",ROS:"2020-12-21",ROS_ETA_DELTA:89,UPPERBLOCK:"62A",UPPERBLOCK_ERECTION:"2020-08-03",ROS_ETA_UPPERBLOCK_DELTA:-51,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"3RD DECK",LOCATION:"MACHINERY SPACE",ROOM:"M305 TRANSFORMER ROOM"},
      {NO:234,TAG:"21-ETR-667301B",TAG_DESCR:"Normal / Essential Transformer 6.6/0.42Kv 2000Kva",PARENT_TAG:"21-ETR-667301B",WEIGHT:0,SN:24,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-09-23",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-39,UPPERBLOCK:"62A",UPPERBLOCK_ERECTION:"2020-08-03",ROS_ETA_UPPERBLOCK_DELTA:-51,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"3RD DECK",LOCATION:"MACHINERY SPACE",ROOM:"M305 TRANSFORMER ROOM"},
      {NO:241,TAG:"21-ESB-667301A",TAG_DESCR:"21-ESB-667301A",PARENT_TAG:"21-ESB-667301A",SN:31,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK:"62A",UPPERBLOCK_ERECTION:"2020-08-03",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"3RD DECK",LOCATION:"MACHINERY SPACE",ROOM:"M302 HV ROOM FALSE FLOOR"},
      {NO:242,TAG:"21-ESB-667301B",TAG_DESCR:"21-ESB-667301B",PARENT_TAG:"21-ESB-667301B",SN:32,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK:"62A",UPPERBLOCK_ERECTION:"2020-08-03",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"3RD DECK",LOCATION:"MACHINERY SPACE",ROOM:"M302 HV ROOM FALSE FLOOR"},
      {NO:243,TAG:"21-ESB-667401",TAG_DESCR:"Essential Lv Switchgear 0.69Kv",PARENT_TAG:"21-ESB-667401",WEIGHT:0,SN:33,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-12-25",ETA_ACTUAL:"",ROS:"2021-01-28",ROS_ETA_DELTA:34,UPPERBLOCK:"62A",UPPERBLOCK_ERECTION:"2020-08-03",ROS_ETA_UPPERBLOCK_DELTA:-144,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"3RD DECK",LOCATION:"MACHINERY SPACE",ROOM:"M303 LV ROOM FALSE FLOOR"},
      {NO:244,TAG:"21-ESB-667601",TAG_DESCR:"Normal / Essential Lv Distribution Board 0.4Kv",PARENT_TAG:"21-ESB-667601",WEIGHT:0,SN:34,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-12-25",ETA_ACTUAL:"",ROS:"2021-01-28",ROS_ETA_DELTA:34,UPPERBLOCK:"62A",UPPERBLOCK_ERECTION:"2020-08-03",ROS_ETA_UPPERBLOCK_DELTA:-144,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"3RD DECK",LOCATION:"MACHINERY SPACE",ROOM:"M303 LV ROOM FALSE FLOOR"},
      {NO:194,TAG:"21-ELP-667638",TAG_DESCR:"21-ELP-667638",SN:55,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK:"62B",UPPERBLOCK_ERECTION:"2020-10-10",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE"},
      {NO:177,TAG:"21-ECP-891070",TAG_DESCR:"21-ECP-891070",SN:38,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK:"62B",UPPERBLOCK_ERECTION:"2020-10-10",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE"},
      {NO:186,TAG:"21-IPC-602301",TAG_DESCR:"21-IPC-602301",WEIGHT:0,SN:47,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-08-25",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-10,UPPERBLOCK:"62B",UPPERBLOCK_ERECTION:"2020-10-10",ROS_ETA_UPPERBLOCK_DELTA:46,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE"},
      {NO:185,TAG:"21-IPC-602302",TAG_DESCR:"21-IPC-602302",SN:46,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK:"62B",UPPERBLOCK_ERECTION:"2020-10-10",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE"},
      {NO:184,TAG:"21-IPC-603006",TAG_DESCR:"21-IPC-603006",SN:45,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK:"62B",UPPERBLOCK_ERECTION:"2020-10-10",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE"},
      {NO:187,TAG:"21-INC-602301",TAG_DESCR:"21-INC-602301",WEIGHT:0,SN:48,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-08-25",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-10,UPPERBLOCK:"62B",UPPERBLOCK_ERECTION:"2020-10-10",ROS_ETA_UPPERBLOCK_DELTA:46,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE"},
      {NO:192,TAG:"21-IMC-607301",TAG_DESCR:"21-IMC-607301",WEIGHT:0,SN:53,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-08-25",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-10,UPPERBLOCK:"62B",UPPERBLOCK_ERECTION:"2020-10-10",ROS_ETA_UPPERBLOCK_DELTA:46,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE"},
      {NO:188,TAG:"21-IMC-602301",TAG_DESCR:"21-IMC-602301",SN:49,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK:"62B",UPPERBLOCK_ERECTION:"2020-10-10",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE"},
      {NO:189,TAG:"21-IMC-602302",TAG_DESCR:"21-IMC-602302",SN:50,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK:"62B",UPPERBLOCK_ERECTION:"2020-10-10",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE"},
      {NO:190,TAG:"21-IMC-602303",TAG_DESCR:"21-IMC-602303",SN:51,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK:"62B",UPPERBLOCK_ERECTION:"2020-10-10",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE"},
      {NO:191,TAG:"21-IMC-602304",TAG_DESCR:"21-IMC-602304",SN:52,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK:"62B",UPPERBLOCK_ERECTION:"2020-10-10",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE"},
      {NO:195,TAG:"21-IDB-661430",TAG_DESCR:"21-IDB-661430",SN:56,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK:"62B",UPPERBLOCK_ERECTION:"2020-10-10",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE"},
      {NO:178,TAG:"21-IMC-601301",TAG_DESCR:"21-IMC-601301",WEIGHT:0,SN:39,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-08-25",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-10,UPPERBLOCK:"62B",UPPERBLOCK_ERECTION:"2020-10-10",ROS_ETA_UPPERBLOCK_DELTA:46,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE"},
      {NO:179,TAG:"21-IMC-601302",TAG_DESCR:"21-IMC-601302",SN:40,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK:"62B",UPPERBLOCK_ERECTION:"2020-10-10",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE"},
      {NO:180,TAG:"21-IMC-601303",TAG_DESCR:"21-IMC-601303",SN:41,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK:"62B",UPPERBLOCK_ERECTION:"2020-10-10",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE"},
      {NO:175,TAG:"21-ICP-605101",TAG_DESCR:"21-ICP-605101",SN:36,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK:"62B",UPPERBLOCK_ERECTION:"2020-10-10",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE"},
      {NO:147,TAG:"21-Z-801002",TAG_DESCR:"Chlorination Package",PARENT_TAG:"21-Z-801002",DIMENSION:"900*800*1950",WEIGHT:225,SN:8,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"2019-09-10",PO_ACTUAL:"2019-09-10",IRN_PLAN:"",ETA_PLAN:"2020-10-17",ETA_ACTUAL:"",ROS:"2020-09-19",ROS_ETA_DELTA:-28,UPPERBLOCK:"62B",UPPERBLOCK_ERECTION:"2020-10-10",ROS_ETA_UPPERBLOCK_DELTA:-7,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE",ROOM:"M103 SEWAGE TREATMENT ROOM",METHOD:"Y"},
      {NO:181,TAG:"21-ISC-603002",TAG_DESCR:"21-ISC-603002",SN:42,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK:"62B",UPPERBLOCK_ERECTION:"2020-10-10",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE"},
      {NO:193,TAG:"21-ISC-607301",TAG_DESCR:"21-ISC-607301",WEIGHT:0,SN:54,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"2020-08-25",ETA_ACTUAL:"",ROS:"2020-08-15",ROS_ETA_DELTA:-10,UPPERBLOCK:"62B",UPPERBLOCK_ERECTION:"2020-10-10",ROS_ETA_UPPERBLOCK_DELTA:46,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE"},
      {NO:183,TAG:"21-IPP-603005",TAG_DESCR:"21-IPP-603005",SN:44,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK:"62B",UPPERBLOCK_ERECTION:"2020-10-10",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE"},
      {NO:182,TAG:"21-INC-603003",TAG_DESCR:"21-INC-603003",SN:43,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK:"62B",UPPERBLOCK_ERECTION:"2020-10-10",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE"},
      {NO:176,TAG:"21-ICP-811010",TAG_DESCR:"21-ICP-811010",SN:37,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK:"62B",UPPERBLOCK_ERECTION:"2020-10-10",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE"},
      {NO:146,TAG:"21-SSH-641014",TAG_DESCR:"21-SSH-641014",PARENT_TAG:"21-SSH-641014",SN:7,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK:"62B",UPPERBLOCK_ERECTION:"2020-10-10",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE"},
      {NO:224,TAG:"21-P-801002A",TAG_DESCR:"Fresh Service Water Pump",PARENT_TAG:"21-P-801002A",DIMENSION:"1250*540*585",WEIGHT:396,SN:14,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"2019-10-31",PO_ACTUAL:"2019-10-31",IRN_PLAN:"",ETA_PLAN:"2020-12-15",ETA_ACTUAL:"",ROS:"2020-12-21",ROS_ETA_DELTA:6,UPPERBLOCK:"63A",UPPERBLOCK_ERECTION:"2020-08-15",ROS_ETA_UPPERBLOCK_DELTA:-122,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"3RD DECK",LOCATION:"MACHINERY SPACE"},
      {NO:223,TAG:"21-P-801002B",TAG_DESCR:"Fresh Service Water Pump",PARENT_TAG:"21-P-801002B",DIMENSION:"1250*540*585",WEIGHT:396,SN:13,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"2019-10-31",PO_ACTUAL:"2019-10-31",IRN_PLAN:"",ETA_PLAN:"2020-12-15",ETA_ACTUAL:"",ROS:"2020-12-21",ROS_ETA_DELTA:6,UPPERBLOCK:"63A",UPPERBLOCK_ERECTION:"2020-08-15",ROS_ETA_UPPERBLOCK_DELTA:-122,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"3RD DECK",LOCATION:"MACHINERY SPACE"},
      {NO:221,TAG:"21-Z-801004",TAG_DESCR:"Uv Sterilizer Package",PARENT_TAG:"21-Z-801004",DIMENSION:"1600*680*1900",WEIGHT:330,SN:11,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"2019-09-10",PO_ACTUAL:"2019-09-10",IRN_PLAN:"",ETA_PLAN:"2020-10-17",ETA_ACTUAL:"",ROS:"2020-09-19",ROS_ETA_DELTA:-28,UPPERBLOCK:"63A",UPPERBLOCK_ERECTION:"2020-08-15",ROS_ETA_UPPERBLOCK_DELTA:-63,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"3RD DECK",LOCATION:"MACHINERY SPACE",METHOD:"Y"},
      {NO:213,TAG:"21-Z-801001",TAG_DESCR:"Potable Water Package",PARENT_TAG:"21-Z-801001",DIMENSION:"4500*2600*2434",WEIGHT:4450,SN:3,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"2019-09-10",PO_ACTUAL:"2019-09-10",IRN_PLAN:"",ETA_PLAN:"2020-10-17",ETA_ACTUAL:"",ROS:"2020-09-19",ROS_ETA_DELTA:-28,UPPERBLOCK:"63A",UPPERBLOCK_ERECTION:"2020-08-15",ROS_ETA_UPPERBLOCK_DELTA:-63,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"3RD DECK",LOCATION:"MACHINERY SPACE",METHOD:"Y"},
      {NO:231,TAG:"21-Z-614001",TAG_DESCR:"Foam System",PARENT_TAG:"21-Z-614001",DIMENSION:"5490*2400*3226",WEIGHT:6500,SN:21,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"2019-08-15",PO_ACTUAL:"2019-08-15",IRN_PLAN:"",ETA_PLAN:"2020-09-17",ETA_ACTUAL:"",ROS:"2020-09-17",ROS_ETA_DELTA:0,UPPERBLOCK:"63A",UPPERBLOCK_ERECTION:"2020-08-15",ROS_ETA_UPPERBLOCK_DELTA:-33,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"3RD DECK",LOCATION:"MACHINERY SPACE",METHOD:"Y"},
      {NO:232,TAG:"21-SWM-613001",TAG_DESCR:"Water Mist Skid/Generator",PARENT_TAG:"21-SWM-613001",DIMENSION:"3325*1495*2730",WEIGHT:4450,SN:22,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"2019-10-17",PO_ACTUAL:"2019-10-17",IRN_PLAN:"",ETA_PLAN:"2020-10-27",ETA_ACTUAL:"",ROS:"2020-11-05",ROS_ETA_DELTA:9,UPPERBLOCK:"63A",UPPERBLOCK_ERECTION:"2020-08-15",ROS_ETA_UPPERBLOCK_DELTA:-73,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"3RD DECK",LOCATION:"MACHINERY SPACE"},
      {NO:222,TAG:"21-H-801001",TAG_DESCR:"Potable Water Cooler",PARENT_TAG:"21-H-801001",DIMENSION:"600*320*832",WEIGHT:191,SN:12,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"2019-09-26",PO_ACTUAL:"2019-09-26",IRN_PLAN:"",ETA_PLAN:"2020-09-15",ETA_ACTUAL:"",ROS:"2021-01-06",ROS_ETA_DELTA:113,UPPERBLOCK:"63A",UPPERBLOCK_ERECTION:"2020-08-15",ROS_ETA_UPPERBLOCK_DELTA:-31,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"3RD DECK",LOCATION:"MACHINERY SPACE",METHOD:"Y"},
      {NO:220,TAG:"21-Z-801005",TAG_DESCR:"Calorifier Package",PARENT_TAG:"21-Z-801005",DIMENSION:"4900*2000*3374",WEIGHT:6500,SN:10,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"2019-09-06",PO_ACTUAL:"2019-09-06",IRN_PLAN:"",ETA_PLAN:"2020-09-09",ETA_ACTUAL:"",ROS:"2020-09-12",ROS_ETA_DELTA:3,UPPERBLOCK:"64A",UPPERBLOCK_ERECTION:"2020-08-18",ROS_ETA_UPPERBLOCK_DELTA:-22,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"3RD DECK",LOCATION:"MACHINERY SPACE",METHOD:"Y"},
      {NO:214,TAG:"21-Z-801003",TAG_DESCR:"Rehardening Package",PARENT_TAG:"21-Z-801003",DIMENSION:"680*920*2350",WEIGHT:170,SN:4,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"2019-09-10",PO_ACTUAL:"2019-09-10",IRN_PLAN:"",ETA_PLAN:"2020-10-17",ETA_ACTUAL:"",ROS:"2020-09-19",ROS_ETA_DELTA:-28,UPPERBLOCK:"64A",UPPERBLOCK_ERECTION:"2020-08-18",ROS_ETA_UPPERBLOCK_DELTA:-60,LIFTING:"",INSTALL:"",STATUS:"ROS_PAST",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"3RD DECK",LOCATION:"MACHINERY SPACE",METHOD:"Y"},
      {NO:215,TAG:"21-P-891001",TAG_DESCR:"Oil Filling And Transfer Pump",PARENT_TAG:"21-P-891001",DIMENSION:"475*259*674",WEIGHT:38,SN:5,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-06-30",ETA_ACTUAL:"",ROS:"2020-09-24",ROS_ETA_DELTA:86,UPPERBLOCK:"64A",UPPERBLOCK_ERECTION:"2020-08-18",ROS_ETA_UPPERBLOCK_DELTA:49,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"3RD DECK",LOCATION:"MACHINERY SPACE",METHOD:"Y"},
      {NO:216,TAG:"21-P-801003A",TAG_DESCR:"Hot Water Circulation Pump",PARENT_TAG:"21-P-801003A",DIMENSION:"900*390*440",WEIGHT:166,SN:6,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"2019-10-31",PO_ACTUAL:"2019-10-31",IRN_PLAN:"",ETA_PLAN:"2020-12-15",ETA_ACTUAL:"",ROS:"2020-12-21",ROS_ETA_DELTA:6,UPPERBLOCK:"64A",UPPERBLOCK_ERECTION:"2020-08-18",ROS_ETA_UPPERBLOCK_DELTA:-119,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"3RD DECK",LOCATION:"MACHINERY SPACE"},
      {NO:217,TAG:"21-P-801003B",TAG_DESCR:"Hot Water Circulation Pump",PARENT_TAG:"21-P-801003B",DIMENSION:"900*390*440",WEIGHT:166,SN:7,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"2019-10-31",PO_ACTUAL:"2019-10-31",IRN_PLAN:"",ETA_PLAN:"2020-12-15",ETA_ACTUAL:"",ROS:"2020-12-21",ROS_ETA_DELTA:6,UPPERBLOCK:"64A",UPPERBLOCK_ERECTION:"2020-08-18",ROS_ETA_UPPERBLOCK_DELTA:-119,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"3RD DECK",LOCATION:"MACHINERY SPACE"},
      {NO:218,TAG:"21-P-801001A",TAG_DESCR:"Potable Water Pump",PARENT_TAG:"21-P-801001A",DIMENSION:"1250*540*585",WEIGHT:396,SN:8,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"2019-10-31",PO_ACTUAL:"2019-10-31",IRN_PLAN:"",ETA_PLAN:"2020-12-15",ETA_ACTUAL:"",ROS:"2020-12-21",ROS_ETA_DELTA:6,UPPERBLOCK:"64A",UPPERBLOCK_ERECTION:"2020-08-18",ROS_ETA_UPPERBLOCK_DELTA:-119,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"3RD DECK",LOCATION:"MACHINERY SPACE"},
      {NO:219,TAG:"21-P-801001B",TAG_DESCR:"Potable Water Pump",PARENT_TAG:"21-P-801001B",DIMENSION:"1250*540*585",WEIGHT:396,SN:9,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS03",PO_PLAN:"2019-10-31",PO_ACTUAL:"2019-10-31",IRN_PLAN:"",ETA_PLAN:"2020-12-15",ETA_ACTUAL:"",ROS:"2020-12-21",ROS_ETA_DELTA:6,UPPERBLOCK:"64A",UPPERBLOCK_ERECTION:"2020-08-18",ROS_ETA_UPPERBLOCK_DELTA:-119,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"3RD DECK",LOCATION:"MACHINERY SPACE"},
      {NO:210,TAG:"21-V-812002",TAG_DESCR:"Hull Instrument Air Receiver",PARENT_TAG:"21-V-812002",DIMENSION:"φ3023*5356",WEIGHT:9580,SN:15,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS02",PO_PLAN:"2019-09-17",PO_ACTUAL:"2019-09-17",IRN_PLAN:"",ETA_PLAN:"2020-07-09",ETA_ACTUAL:"",ROS:"2020-11-05",ROS_ETA_DELTA:119,UPPERBLOCK:"65A",UPPERBLOCK_ERECTION:"2020-09-04",ROS_ETA_UPPERBLOCK_DELTA:57,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"2ND DECK",LOCATION:"MACHINERY SPACE",METHOD:"Y"},
      {NO:199,TAG:"21-Z-812020",TAG_DESCR:"AIR_RECEIVER",DIMENSION:"2400*2000*3160",WEIGHT:2750,SN:4,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS02",PO_PLAN:"2019-08-15",PO_ACTUAL:"2019-08-15",IRN_PLAN:"",ETA_PLAN:"2020-11-21",ETA_ACTUAL:"",ROS:"2020-11-22",ROS_ETA_DELTA:1,UPPERBLOCK:"65A",UPPERBLOCK_ERECTION:"2020-09-04",ROS_ETA_UPPERBLOCK_DELTA:-78,LIFTING:"",INSTALL:"",STATUS:"PO"},
      {NO:200,TAG:"21-SSH-641013",TAG_DESCR:"EYE-WASHER",SN:5,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS02",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK:"65A",UPPERBLOCK_ERECTION:"2020-09-04",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO"},
      {NO:197,TAG:"21-HAH-997201",SN:2,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS02",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK:"65A",UPPERBLOCK_ERECTION:"2020-09-04",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO"},
      {NO:196,TAG:"21-HAH-997202",SN:1,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS02",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK:"65A",UPPERBLOCK_ERECTION:"2020-09-04",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO"},
      {NO:198,TAG:"21-Z-811002",TAG_DESCR:"Air Compressor",PARENT_TAG:"21-Z-811002",DIMENSION:"5062*1871*2712",WEIGHT:4200,SN:3,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS02",PO_PLAN:"2019-08-15",PO_ACTUAL:"2019-08-15",IRN_PLAN:"",ETA_PLAN:"2020-10-24",ETA_ACTUAL:"",ROS:"2020-11-05",ROS_ETA_DELTA:12,UPPERBLOCK:"65A",UPPERBLOCK_ERECTION:"2020-09-04",ROS_ETA_UPPERBLOCK_DELTA:-50,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"2ND DECK",LOCATION:"MACHINERY SPACE",METHOD:"Y"},
      {NO:201,TAG:"21-HPU-891006",TAG_DESCR:"Auxillary Hydraulic Unit",PARENT_TAG:"21-HPU-891006",DIMENSION:"2639*1550*2159",WEIGHT:2100,SN:6,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS02",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-06-30",ETA_ACTUAL:"",ROS:"2020-09-24",ROS_ETA_DELTA:86,UPPERBLOCK:"66A",UPPERBLOCK_ERECTION:"2020-09-08",ROS_ETA_UPPERBLOCK_DELTA:70,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"2ND DECK",LOCATION:"MACHINERY SPACE",ROOM:"M202 HPU ROOM",METHOD:"Y"},
      {NO:202,TAG:"21-HPU-891001A",TAG_DESCR:"Hydraulic Power Unit A For Submerged Pumps",PARENT_TAG:"21-HPU-891001A",DIMENSION:"10204*3460*2535",WEIGHT:46000,SN:7,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS02",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-06-30",ETA_ACTUAL:"",ROS:"2020-09-24",ROS_ETA_DELTA:86,UPPERBLOCK:"66A",UPPERBLOCK_ERECTION:"2020-09-08",ROS_ETA_UPPERBLOCK_DELTA:70,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"2ND DECK",LOCATION:"MACHINERY SPACE",ROOM:"M202 HPU ROOM",METHOD:"Y"},
      {NO:203,TAG:"21-HPU-891001B",TAG_DESCR:"Hydraulic Power Unit B For Submerged Pumps",PARENT_TAG:"21-HPU-891001B",DIMENSION:"10204*3460*2535",WEIGHT:46000,SN:8,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS02",PO_PLAN:"2019-06-21",PO_ACTUAL:"2019-06-21",IRN_PLAN:"",ETA_PLAN:"2020-06-30",ETA_ACTUAL:"",ROS:"2020-09-24",ROS_ETA_DELTA:86,UPPERBLOCK:"66A",UPPERBLOCK_ERECTION:"2020-09-08",ROS_ETA_UPPERBLOCK_DELTA:70,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"2ND DECK",LOCATION:"MACHINERY SPACE",ROOM:"M202 HPU ROOM",METHOD:"Y"},
      {NO:265,TAG:"21-P-935002A",TAG_DESCR:"Machinery Space Ballast Pump",PARENT_TAG:"21-P-935002A",DIMENSION:"1000*1000*1940",WEIGHT:1383,SN:21,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS04",PO_PLAN:"2020-05-06",PO_ACTUAL:"2020-05-06",IRN_PLAN:"",ETA_PLAN:"2021-03-20",ETA_ACTUAL:"",ROS:"2021-03-29",ROS_ETA_DELTA:9,UPPERBLOCK:"66F",UPPERBLOCK_ERECTION:"2020-06-22",ROS_ETA_UPPERBLOCK_DELTA:-271,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"BOTTOM",LOCATION:"MACHINERY SPACE",ROOM:"MB01 WEST PUMP ROOM"},
      {NO:266,TAG:"21-P-935002B",TAG_DESCR:"Machinery Space Ballast Pump",PARENT_TAG:"21-P-935002B",DIMENSION:"1000*1000*1940",WEIGHT:1383,SN:22,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS04",PO_PLAN:"2020-05-06",PO_ACTUAL:"2020-05-06",IRN_PLAN:"",ETA_PLAN:"2021-03-20",ETA_ACTUAL:"",ROS:"2021-03-29",ROS_ETA_DELTA:9,UPPERBLOCK:"66F",UPPERBLOCK_ERECTION:"2020-06-22",ROS_ETA_UPPERBLOCK_DELTA:-271,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"BOTTOM",LOCATION:"MACHINERY SPACE",ROOM:"MB01 WEST PUMP ROOM"},
      {NO:263,TAG:"21-P-938001A",TAG_DESCR:"Daily Bilge Pump (Screw Type)",PARENT_TAG:"21-P-938001A",DIMENSION:"1600*280*350（hold）",WEIGHT:200,SN:19,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS04",PO_PLAN:"2020-06-30",PO_ACTUAL:"2020-06-30",IRN_PLAN:"",ETA_PLAN:"2021-03-12",ETA_ACTUAL:"",ROS:"2021-03-12",ROS_ETA_DELTA:0,UPPERBLOCK:"66F",UPPERBLOCK_ERECTION:"2020-06-22",ROS_ETA_UPPERBLOCK_DELTA:-263,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"BOTTOM",LOCATION:"MACHINERY SPACE",ROOM:"MB01 WEST PUMP ROOM"},
      {NO:264,TAG:"21-P-938001B",TAG_DESCR:"Daily Bilge Pump (Screw Type)",PARENT_TAG:"21-P-938001B",DIMENSION:"1600*280*350（hold）",WEIGHT:200,SN:20,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS04",PO_PLAN:"2020-06-30",PO_ACTUAL:"2020-06-30",IRN_PLAN:"",ETA_PLAN:"2021-03-12",ETA_ACTUAL:"",ROS:"2021-03-12",ROS_ETA_DELTA:0,UPPERBLOCK:"66F",UPPERBLOCK_ERECTION:"2020-06-22",ROS_ETA_UPPERBLOCK_DELTA:-263,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"BOTTOM",LOCATION:"MACHINERY SPACE",ROOM:"MB01 WEST PUMP ROOM"},
      {NO:261,TAG:"21-P-938002A",TAG_DESCR:"Main Bilge Pump",PARENT_TAG:"21-P-938002A",DIMENSION:"770*600*1277",WEIGHT:720,SN:17,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS04",PO_PLAN:"2020-05-06",PO_ACTUAL:"2020-05-06",IRN_PLAN:"",ETA_PLAN:"2021-03-20",ETA_ACTUAL:"",ROS:"2021-03-29",ROS_ETA_DELTA:9,UPPERBLOCK:"66F",UPPERBLOCK_ERECTION:"2020-06-22",ROS_ETA_UPPERBLOCK_DELTA:-271,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"BOTTOM",LOCATION:"MACHINERY SPACE",ROOM:"MB01 WEST PUMP ROOM"},
      {NO:262,TAG:"21-P-938002B",TAG_DESCR:"Main Bilge Pump",PARENT_TAG:"21-P-938002B",DIMENSION:"770*600*1277",WEIGHT:720,SN:18,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS04",PO_PLAN:"2020-05-06",PO_ACTUAL:"2020-05-06",IRN_PLAN:"",ETA_PLAN:"2021-03-20",ETA_ACTUAL:"",ROS:"2021-03-29",ROS_ETA_DELTA:9,UPPERBLOCK:"66F",UPPERBLOCK_ERECTION:"2020-06-22",ROS_ETA_UPPERBLOCK_DELTA:-271,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"BOTTOM",LOCATION:"MACHINERY SPACE",ROOM:"MB01 WEST PUMP ROOM"},
      {NO:259,TAG:"21-SPF-611001A",TAG_DESCR:"Safety Pump Firewater: Fire Water Jockey Pump",PARENT_TAG:"21-SPF-611001A",DIMENSION:"2000*915*870",WEIGHT:989,SN:15,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS04",PO_PLAN:"2019-09-26",PO_ACTUAL:"2019-09-26",IRN_PLAN:"",ETA_PLAN:"2020-10-19",ETA_ACTUAL:"",ROS:"2020-11-05",ROS_ETA_DELTA:17,UPPERBLOCK:"66F",UPPERBLOCK_ERECTION:"2020-06-22",ROS_ETA_UPPERBLOCK_DELTA:-119,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"BOTTOM",LOCATION:"MACHINERY SPACE",ROOM:"MB01 WEST PUMP ROOM"},
      {NO:260,TAG:"21-SPF-611001B",TAG_DESCR:"Safety Pump Firewater: Fire Water Jockey Pump",PARENT_TAG:"21-SPF-611001B",DIMENSION:"2000*915*870",WEIGHT:989,SN:16,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS04",PO_PLAN:"2019-09-26",PO_ACTUAL:"2019-09-26",IRN_PLAN:"",ETA_PLAN:"2020-10-19",ETA_ACTUAL:"",ROS:"2020-11-05",ROS_ETA_DELTA:17,UPPERBLOCK:"66F",UPPERBLOCK_ERECTION:"2020-06-22",ROS_ETA_UPPERBLOCK_DELTA:-119,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"BOTTOM",LOCATION:"MACHINERY SPACE",ROOM:"MB01 WEST PUMP ROOM"},
      {NO:208,TAG:"21-GD-667001",TAG_DESCR:"Key Service Generator",PARENT_TAG:"21-GD-667001",DIMENSION:"8859*2400*4705",WEIGHT:34458,SN:13,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS02",PO_PLAN:"2019-06-26",PO_ACTUAL:"2019-06-26",IRN_PLAN:"",ETA_PLAN:"2020-07-21",ETA_ACTUAL:"",ROS:"2020-10-12",ROS_ETA_DELTA:83,UPPERBLOCK:"67A",UPPERBLOCK_ERECTION:"2020-09-08",ROS_ETA_UPPERBLOCK_DELTA:49,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"2ND DECK",LOCATION:"MACHINERY SPACE",ROOM:"M204 ENGINE ROOM",METHOD:"Y"},
      {NO:205,TAG:"21-GD-667002",TAG_DESCR:"Key Service Generator",PARENT_TAG:"21-GD-667002",DIMENSION:"8859*2400*4705",WEIGHT:34458,SN:10,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS02",PO_PLAN:"2019-06-26",PO_ACTUAL:"2019-06-26",IRN_PLAN:"",ETA_PLAN:"2020-07-21",ETA_ACTUAL:"",ROS:"2020-10-12",ROS_ETA_DELTA:83,UPPERBLOCK:"67A",UPPERBLOCK_ERECTION:"2020-09-08",ROS_ETA_UPPERBLOCK_DELTA:49,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"2ND DECK",LOCATION:"MACHINERY SPACE",ROOM:"M204 ENGINE ROOM",METHOD:"Y"},
      {NO:209,TAG:"KSG SLIENCER1",TAG_DESCR:"Key Service GeneratorSilencer",PARENT_TAG:"21-GD-667001",SN:14,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS02",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK:"67A",UPPERBLOCK_ERECTION:"2020-09-08",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"2ND DECK",LOCATION:"MACHINERY SPACE"},
      {NO:206,TAG:"KSG SLIENCER2",TAG_DESCR:"Key Service GeneratorSilencer",PARENT_TAG:"21-GD-667002",SN:11,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS02",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK:"67A",UPPERBLOCK_ERECTION:"2020-09-08",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"2ND DECK",LOCATION:"MACHINERY SPACE"},
      {NO:207,TAG:"KSG-A-BATTERY-UNIT",TAG_DESCR:"Key Service Generatoratteries Pack",PARENT_TAG:"21-GD-667001",SN:12,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS02",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK:"67A",UPPERBLOCK_ERECTION:"2020-09-08",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"2ND DECK",LOCATION:"MACHINERY SPACE",ROOM:"M204 ENGINE ROOM"},
      {NO:204,TAG:"KSG-B-BATTERY-UNIT",TAG_DESCR:"Key Service GeneratoBatteries Pack",PARENT_TAG:"21-GD-667002",SN:9,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS02",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK:"67A",UPPERBLOCK_ERECTION:"2020-09-08",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"2ND DECK",LOCATION:"MACHINERY SPACE",ROOM:"M204 ENGINE ROOM"},
      {NO:245,TAG:"21-F-802001",TAG_DESCR:"Sea Suction Tube Filter",PARENT_TAG:"21-F-802001",WEIGHT:2005,SN:1,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS04",PO_PLAN:"2020-04-08",PO_ACTUAL:"2020-04-08",IRN_PLAN:"",ETA_PLAN:"2021-01-25",ETA_ACTUAL:"",ROS:"2021-01-27",ROS_ETA_DELTA:2,UPPERBLOCK:"67F",UPPERBLOCK_ERECTION:"2020-06-24",ROS_ETA_UPPERBLOCK_DELTA:-215,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"BOTTOM",LOCATION:"MACHINERY SPACE",ROOM:"MB01 WEST PUMP ROOM"},
      {NO:246,TAG:"21-P-802005A",TAG_DESCR:"Hull Service Sea Water Pump",PARENT_TAG:"21-P-802005A",DIMENSION:"1000*1000*2100",WEIGHT:1612,SN:2,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS04",PO_PLAN:"2019-12-20",PO_ACTUAL:"2019-12-20",IRN_PLAN:"",ETA_PLAN:"2021-01-08",ETA_ACTUAL:"",ROS:"2021-01-20",ROS_ETA_DELTA:12,UPPERBLOCK:"67F",UPPERBLOCK_ERECTION:"2020-06-24",ROS_ETA_UPPERBLOCK_DELTA:-198,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"BOTTOM",LOCATION:"MACHINERY SPACE",ROOM:"MB01 WEST PUMP ROOM"},
      {NO:247,TAG:"21-P-802005B",TAG_DESCR:"Hull Service Sea Water Pump",PARENT_TAG:"21-P-802005B",DIMENSION:"1000*1000*2100",WEIGHT:1612,SN:3,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS04",PO_PLAN:"2019-12-20",PO_ACTUAL:"2019-12-20",IRN_PLAN:"",ETA_PLAN:"2021-01-08",ETA_ACTUAL:"",ROS:"2021-01-20",ROS_ETA_DELTA:12,UPPERBLOCK:"67F",UPPERBLOCK_ERECTION:"2020-06-24",ROS_ETA_UPPERBLOCK_DELTA:-198,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"BOTTOM",LOCATION:"MACHINERY SPACE",ROOM:"MB01 WEST PUMP ROOM"},
      {NO:250,TAG:"21-P-802009A",TAG_DESCR:"Ksg Cooling Sea Water Pump",PARENT_TAG:"21-P-802009A",DIMENSION:"980*600*1234",WEIGHT:725,SN:6,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS04",PO_PLAN:"2019-12-20",PO_ACTUAL:"2019-12-20",IRN_PLAN:"",ETA_PLAN:"2021-01-08",ETA_ACTUAL:"",ROS:"2021-01-20",ROS_ETA_DELTA:12,UPPERBLOCK:"67F",UPPERBLOCK_ERECTION:"2020-06-24",ROS_ETA_UPPERBLOCK_DELTA:-198,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"BOTTOM",LOCATION:"MACHINERY SPACE",ROOM:"MB01 WEST PUMP ROOM"},
      {NO:251,TAG:"21-P-802009B",TAG_DESCR:"Ksg Cooling Sea Water Pump",PARENT_TAG:"21-P-802009B",DIMENSION:"980*600*1234",WEIGHT:725,SN:7,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS04",PO_PLAN:"2019-12-20",PO_ACTUAL:"2019-12-20",IRN_PLAN:"",ETA_PLAN:"2021-01-08",ETA_ACTUAL:"",ROS:"2021-01-20",ROS_ETA_DELTA:12,UPPERBLOCK:"67F",UPPERBLOCK_ERECTION:"2020-06-24",ROS_ETA_UPPERBLOCK_DELTA:-198,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"BOTTOM",LOCATION:"MACHINERY SPACE",ROOM:"MB01 WEST PUMP ROOM"},
      {NO:249,TAG:"21-P-802002A",TAG_DESCR:"Igg Deck Seal Seawater Pump",PARENT_TAG:"21-P-802002A",DIMENSION:"1000*450*504",WEIGHT:241,SN:5,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS04",PO_PLAN:"2019-10-31",PO_ACTUAL:"2019-10-31",IRN_PLAN:"",ETA_PLAN:"2020-12-15",ETA_ACTUAL:"",ROS:"2020-12-21",ROS_ETA_DELTA:6,UPPERBLOCK:"67F",UPPERBLOCK_ERECTION:"2020-06-24",ROS_ETA_UPPERBLOCK_DELTA:-174,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"BOTTOM",LOCATION:"MACHINERY SPACE",ROOM:"MB01 WEST PUMP ROOM"},
      {NO:248,TAG:"21-P-802002B",TAG_DESCR:"Igg Deck Seal Seawater Pump",PARENT_TAG:"21-P-802002B",DIMENSION:"1000*450*504",WEIGHT:241,SN:4,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS04",PO_PLAN:"2019-10-31",PO_ACTUAL:"2019-10-31",IRN_PLAN:"",ETA_PLAN:"2020-12-15",ETA_ACTUAL:"",ROS:"2020-12-21",ROS_ETA_DELTA:6,UPPERBLOCK:"67F",UPPERBLOCK_ERECTION:"2020-06-24",ROS_ETA_UPPERBLOCK_DELTA:-174,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"BOTTOM",LOCATION:"MACHINERY SPACE",ROOM:"MB01 WEST PUMP ROOM"},
      {NO:252,TAG:"21-P-802003A",TAG_DESCR:"Igg Scrubber Cooling Seawater Pump",PARENT_TAG:"21-P-802003A",DIMENSION:"1530*1530*3609",WEIGHT:6908,SN:8,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS04",PO_PLAN:"2019-12-20",PO_ACTUAL:"2019-12-20",IRN_PLAN:"",ETA_PLAN:"2021-01-08",ETA_ACTUAL:"",ROS:"2021-01-20",ROS_ETA_DELTA:12,UPPERBLOCK:"67F",UPPERBLOCK_ERECTION:"2020-06-24",ROS_ETA_UPPERBLOCK_DELTA:-198,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"BOTTOM",LOCATION:"MACHINERY SPACE",ROOM:"MB01 WEST PUMP ROOM"},
      {NO:253,TAG:"21-P-802003B",TAG_DESCR:"Igg Scrubber Cooling Seawater Pump",PARENT_TAG:"21-P-802003B",DIMENSION:"1530*1530*3609",WEIGHT:6908,SN:9,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS04",PO_PLAN:"2019-12-20",PO_ACTUAL:"2019-12-20",IRN_PLAN:"",ETA_PLAN:"2021-01-08",ETA_ACTUAL:"",ROS:"2021-01-20",ROS_ETA_DELTA:12,UPPERBLOCK:"67F",UPPERBLOCK_ERECTION:"2020-06-24",ROS_ETA_UPPERBLOCK_DELTA:-198,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"BOTTOM",LOCATION:"MACHINERY SPACE",ROOM:"MB01 WEST PUMP ROOM"},
      {NO:254,TAG:"21-P-802004A",TAG_DESCR:"Hull Sea Water Cooling Pump",PARENT_TAG:"21-P-802004A",DIMENSION:"1010*600*1531",WEIGHT:1077,SN:10,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS04",PO_PLAN:"2019-12-20",PO_ACTUAL:"2019-12-20",IRN_PLAN:"",ETA_PLAN:"2021-01-08",ETA_ACTUAL:"",ROS:"2021-01-20",ROS_ETA_DELTA:12,UPPERBLOCK:"68F",UPPERBLOCK_ERECTION:"2020-06-29",ROS_ETA_UPPERBLOCK_DELTA:-193,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"BOTTOM",LOCATION:"MACHINERY SPACE",ROOM:"MB02 EAST PUMP ROOM"},
      {NO:255,TAG:"21-P-802004B",TAG_DESCR:"Hull Sea Water Cooling Pump",PARENT_TAG:"21-P-802004B",DIMENSION:"1010*600*1531",WEIGHT:1077,SN:11,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS04",PO_PLAN:"2019-12-20",PO_ACTUAL:"2019-12-20",IRN_PLAN:"",ETA_PLAN:"2021-01-08",ETA_ACTUAL:"",ROS:"2021-01-20",ROS_ETA_DELTA:12,UPPERBLOCK:"68F",UPPERBLOCK_ERECTION:"2020-06-29",ROS_ETA_UPPERBLOCK_DELTA:-193,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"BOTTOM",LOCATION:"MACHINERY SPACE",ROOM:"MB02 EAST PUMP ROOM"},
      {NO:269,TAG:"21-P-806001A",TAG_DESCR:"Diesel Oil Supply Pump",PARENT_TAG:"21-P-806001A",DIMENSION:"1841*762*632",WEIGHT:541,SN:25,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS04",PO_PLAN:"2019-09-26",PO_ACTUAL:"2019-09-26",IRN_PLAN:"",ETA_PLAN:"2020-10-19",ETA_ACTUAL:"",ROS:"2020-11-05",ROS_ETA_DELTA:17,UPPERBLOCK:"68F",UPPERBLOCK_ERECTION:"2020-06-29",ROS_ETA_UPPERBLOCK_DELTA:-112,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"BOTTOM",LOCATION:"MACHINERY SPACE",ROOM:"MB02 EAST PUMP ROOM"},
      {NO:270,TAG:"21-P-806001B",TAG_DESCR:"Diesel Oil Supply Pump",PARENT_TAG:"21-P-806001B",DIMENSION:"1841*762*632",WEIGHT:541,SN:26,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS04",PO_PLAN:"2019-09-26",PO_ACTUAL:"2019-09-26",IRN_PLAN:"",ETA_PLAN:"2020-10-19",ETA_ACTUAL:"",ROS:"2020-11-05",ROS_ETA_DELTA:17,UPPERBLOCK:"68F",UPPERBLOCK_ERECTION:"2020-06-29",ROS_ETA_UPPERBLOCK_DELTA:-112,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"BOTTOM",LOCATION:"MACHINERY SPACE",ROOM:"MB02 EAST PUMP ROOM"},
      {NO:267,TAG:"21-P-806002A",TAG_DESCR:"Diesel Oil Purifier Pump",PARENT_TAG:"21-P-806002A",DIMENSION:"1841*762*625",WEIGHT:505,SN:23,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS04",PO_PLAN:"2019-09-26",PO_ACTUAL:"2019-09-26",IRN_PLAN:"",ETA_PLAN:"2020-10-19",ETA_ACTUAL:"",ROS:"2020-11-05",ROS_ETA_DELTA:17,UPPERBLOCK:"68F",UPPERBLOCK_ERECTION:"2020-06-29",ROS_ETA_UPPERBLOCK_DELTA:-112,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"BOTTOM",LOCATION:"MACHINERY SPACE",ROOM:"MB02 EAST PUMP ROOM"},
      {NO:268,TAG:"21-P-806002B",TAG_DESCR:"Diesel Oil Purifier Pump",PARENT_TAG:"21-P-806002B",DIMENSION:"1841*762*625",WEIGHT:505,SN:24,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS04",PO_PLAN:"2019-09-26",PO_ACTUAL:"2019-09-26",IRN_PLAN:"",ETA_PLAN:"2020-10-19",ETA_ACTUAL:"",ROS:"2020-11-05",ROS_ETA_DELTA:17,UPPERBLOCK:"68F",UPPERBLOCK_ERECTION:"2020-06-29",ROS_ETA_UPPERBLOCK_DELTA:-112,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"BOTTOM",LOCATION:"MACHINERY SPACE",ROOM:"MB02 EAST PUMP ROOM"},
      {NO:271,TAG:"21-Z-806010",TAG_DESCR:"Diesel Oil Purifier Package",PARENT_TAG:"21-Z-806010",DIMENSION:"4500*2500*2850",WEIGHT:4200,SN:27,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS04",PO_PLAN:"2019-09-06",PO_ACTUAL:"2019-09-06",IRN_PLAN:"",ETA_PLAN:"2020-07-09",ETA_ACTUAL:"",ROS:"2020-07-15",ROS_ETA_DELTA:6,UPPERBLOCK:"68F",UPPERBLOCK_ERECTION:"2020-06-29",ROS_ETA_UPPERBLOCK_DELTA:-10,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"BOTTOM",LOCATION:"MACHINERY SPACE",ROOM:"MB02 EAST PUMP ROOM",METHOD:"Y"},
      {NO:256,TAG:"21-F-802002",TAG_DESCR:"Sea Suction Tube Filter",PARENT_TAG:"21-F-802002",WEIGHT:2005,SN:12,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS04",PO_PLAN:"2020-04-08",PO_ACTUAL:"2020-04-08",IRN_PLAN:"",ETA_PLAN:"2021-01-25",ETA_ACTUAL:"",ROS:"2021-01-27",ROS_ETA_DELTA:2,UPPERBLOCK:"68F",UPPERBLOCK_ERECTION:"2020-06-29",ROS_ETA_UPPERBLOCK_DELTA:-210,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"BOTTOM",LOCATION:"MACHINERY SPACE",ROOM:"MB02 EAST PUMP ROOM"},
      {NO:273,TAG:"21-H-805001A",TAG_DESCR:"Hull Cooling Water Cooler",PARENT_TAG:"21-H-805001A",DIMENSION:"2185*1050*2302",WEIGHT:3229,SN:29,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS04",PO_PLAN:"2019-09-26",PO_ACTUAL:"2019-09-26",IRN_PLAN:"",ETA_PLAN:"2020-09-15",ETA_ACTUAL:"",ROS:"2021-01-06",ROS_ETA_DELTA:113,UPPERBLOCK:"68F",UPPERBLOCK_ERECTION:"2020-06-29",ROS_ETA_UPPERBLOCK_DELTA:-78,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"BOTTOM",LOCATION:"MACHINERY SPACE",ROOM:"MB02 EAST PUMP ROOM",METHOD:"Y"},
      {NO:272,TAG:"21-H-805001B",TAG_DESCR:"Hull Cooling Water Cooler",PARENT_TAG:"21-H-805001B",DIMENSION:"2185*1050*2302",WEIGHT:3229,SN:28,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS04",PO_PLAN:"2019-09-26",PO_ACTUAL:"2019-09-26",IRN_PLAN:"",ETA_PLAN:"2020-09-15",ETA_ACTUAL:"",ROS:"2021-01-06",ROS_ETA_DELTA:113,UPPERBLOCK:"68F",UPPERBLOCK_ERECTION:"2020-06-29",ROS_ETA_UPPERBLOCK_DELTA:-78,LIFTING:"",INSTALL:"",STATUS:"ETA",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"BOTTOM",LOCATION:"MACHINERY SPACE",ROOM:"MB02 EAST PUMP ROOM",METHOD:"Y"},
      {NO:160,TAG:"21-HCB-997103",TAG_DESCR:"Chiller Room Exhaust Fan A ",PARENT_TAG:"21-HCB-997103",DIMENSION:"2050*1200*1200",WEIGHT:1217,SN:21,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-09-14",ETA_ACTUAL:"",ROS:"2020-09-14",ROS_ETA_DELTA:0,UPPERBLOCK:"69A",UPPERBLOCK_ERECTION:"2020-10-05",ROS_ETA_UPPERBLOCK_DELTA:21,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE",METHOD:"Y"},
      {NO:159,TAG:"21-HCB-997104",TAG_DESCR:"Chiller Room Exhaust Fan B",PARENT_TAG:"21-HCB-997104",DIMENSION:"2050*1200*1200",WEIGHT:1217,SN:20,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-09-14",ETA_ACTUAL:"",ROS:"2020-09-14",ROS_ETA_DELTA:0,UPPERBLOCK:"69A",UPPERBLOCK_ERECTION:"2020-10-05",ROS_ETA_UPPERBLOCK_DELTA:21,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE",METHOD:"Y"},
      {NO:166,TAG:"21-HWP-997101",TAG_DESCR:"Chiller Water Pump A (1X50%)",PARENT_TAG:"21-HWP-997101",DIMENSION:"700*649*1267",WEIGHT:220,SN:27,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-09-14",ETA_ACTUAL:"",ROS:"2020-09-14",ROS_ETA_DELTA:0,UPPERBLOCK:"69A",UPPERBLOCK_ERECTION:"2020-10-05",ROS_ETA_UPPERBLOCK_DELTA:21,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE",ROOM:"M101 HVAC ROOM",METHOD:"Y"},
      {NO:167,TAG:"21-HWP-997102",TAG_DESCR:"Chiller Water Pump B (1X50%)",PARENT_TAG:"21-HWP-997102",DIMENSION:"700*649*1267",WEIGHT:220,SN:28,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-09-14",ETA_ACTUAL:"",ROS:"2020-09-14",ROS_ETA_DELTA:0,UPPERBLOCK:"69A",UPPERBLOCK_ERECTION:"2020-10-05",ROS_ETA_UPPERBLOCK_DELTA:21,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE",ROOM:"M101 HVAC ROOM",METHOD:"Y"},
      {NO:168,TAG:"21-HWP-997103",TAG_DESCR:"Chiller Water Pump C (1X50%)",PARENT_TAG:"21-HWP-997103",DIMENSION:"700*649*1267",WEIGHT:220,SN:29,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-09-14",ETA_ACTUAL:"",ROS:"2020-09-14",ROS_ETA_DELTA:0,UPPERBLOCK:"69A",UPPERBLOCK_ERECTION:"2020-10-05",ROS_ETA_UPPERBLOCK_DELTA:21,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE",ROOM:"M101 HVAC ROOM",METHOD:"Y"},
      {NO:161,TAG:"21-HCW-997101",TAG_DESCR:"Chilled Water Unit A (1X50%)",PARENT_TAG:"21-HCW-997101",DIMENSION:"5418*2320*3100",WEIGHT:12900,SN:22,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-09-14",ETA_ACTUAL:"",ROS:"2020-09-14",ROS_ETA_DELTA:0,UPPERBLOCK:"69A",UPPERBLOCK_ERECTION:"2020-10-05",ROS_ETA_UPPERBLOCK_DELTA:21,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE",ROOM:"M101 HVAC ROOM",METHOD:"Y"},
      {NO:162,TAG:"21-HCW-997102",TAG_DESCR:"Chilled Water Unit B (1X50%)",PARENT_TAG:"21-HCW-997102",DIMENSION:"5418*2320*3100",WEIGHT:12900,SN:23,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-09-14",ETA_ACTUAL:"",ROS:"2020-09-14",ROS_ETA_DELTA:0,UPPERBLOCK:"69A",UPPERBLOCK_ERECTION:"2020-10-05",ROS_ETA_UPPERBLOCK_DELTA:21,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE",ROOM:"M101 HVAC ROOM",METHOD:"Y"},
      {NO:163,TAG:"21-HCW-997103",TAG_DESCR:"Chilled Water Unit C (1X50%)",PARENT_TAG:"21-HCW-997103",DIMENSION:"5418*2320*3100",WEIGHT:12900,SN:24,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-09-14",ETA_ACTUAL:"",ROS:"2020-09-14",ROS_ETA_DELTA:0,UPPERBLOCK:"69A",UPPERBLOCK_ERECTION:"2020-10-05",ROS_ETA_UPPERBLOCK_DELTA:21,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE",ROOM:"M101 HVAC ROOM",METHOD:"Y"},
      {NO:164,TAG:"21-V-997101",TAG_DESCR:"Dosing Tank (1X100%)",PARENT_TAG:"21-V-997101",DIMENSION:"375*375*1076",WEIGHT:28,SN:25,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-09-14",ETA_ACTUAL:"",ROS:"2020-09-14",ROS_ETA_DELTA:0,UPPERBLOCK:"69A",UPPERBLOCK_ERECTION:"2020-10-05",ROS_ETA_UPPERBLOCK_DELTA:21,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE",ROOM:"M101 HVAC ROOM",METHOD:"Y"},
      {NO:165,TAG:"21-V-997102",TAG_DESCR:"Expansion Tank (1X100%)",PARENT_TAG:"21-V-997102",DIMENSION:"φ1200*2099",WEIGHT:630,SN:26,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-09-14",ETA_ACTUAL:"",ROS:"2020-09-14",ROS_ETA_DELTA:0,UPPERBLOCK:"69A",UPPERBLOCK_ERECTION:"2020-10-05",ROS_ETA_UPPERBLOCK_DELTA:21,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE",ROOM:"M101 HVAC ROOM",METHOD:"Y"},
      {NO:169,TAG:"21-V-997103",TAG_DESCR:"Air Separator (1X100%)",PARENT_TAG:"21-V-997103",DIMENSION:"φ1200*2100",WEIGHT:630,SN:30,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"2019-08-14",PO_ACTUAL:"2019-08-14",IRN_PLAN:"",ETA_PLAN:"2020-09-14",ETA_ACTUAL:"",ROS:"2020-09-14",ROS_ETA_DELTA:0,UPPERBLOCK:"69A",UPPERBLOCK_ERECTION:"2020-10-05",ROS_ETA_UPPERBLOCK_DELTA:21,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE",ROOM:"M101 HVAC ROOM",METHOD:"Y"},
      {NO:173,TAG:"21-WEBS-983001",TAG_DESCR:"Pipe Band Saw",PARENT_TAG:"21-WEBS-983001",DIMENSION:"2200*2100*1900",WEIGHT:200,SN:34,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"2020-02-26",PO_ACTUAL:"2020-02-26",IRN_PLAN:"",ETA_PLAN:"2020-12-15",ETA_ACTUAL:"",ROS:"2020-12-20",ROS_ETA_DELTA:5,UPPERBLOCK:"69A",UPPERBLOCK_ERECTION:"2020-10-05",ROS_ETA_UPPERBLOCK_DELTA:-71,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE",ROOM:"M104 MECH WORKSHOP"},
      {NO:155,TAG:"21-WEDL-983001",TAG_DESCR:"Drilling Machine Large",PARENT_TAG:"21-WEDL-983001",DIMENSION:"405*505*1800",WEIGHT:260,SN:16,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"2020-02-26",PO_ACTUAL:"2020-02-26",IRN_PLAN:"",ETA_PLAN:"2020-12-15",ETA_ACTUAL:"",ROS:"2020-12-20",ROS_ETA_DELTA:5,UPPERBLOCK:"69A",UPPERBLOCK_ERECTION:"2020-10-05",ROS_ETA_UPPERBLOCK_DELTA:-71,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE",ROOM:"M104 MECH WORKSHOP",METHOD:"Y"},
      {NO:154,TAG:"21-WEDL-983002",TAG_DESCR:"Drilling Machine Small",PARENT_TAG:"21-WEDL-983002",DIMENSION:"405*505*1800",WEIGHT:66,SN:15,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"2020-02-26",PO_ACTUAL:"2020-02-26",IRN_PLAN:"",ETA_PLAN:"2020-12-15",ETA_ACTUAL:"",ROS:"2020-12-20",ROS_ETA_DELTA:5,UPPERBLOCK:"69A",UPPERBLOCK_ERECTION:"2020-10-05",ROS_ETA_UPPERBLOCK_DELTA:-71,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE",ROOM:"M104 MECH WORKSHOP"},
      {NO:156,TAG:"21-WEGN-983001",TAG_DESCR:"Grinder Machine",PARENT_TAG:"21-WEGN-983001",DIMENSION:"410*900*500",WEIGHT:120,SN:17,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"2020-02-26",PO_ACTUAL:"2020-02-26",IRN_PLAN:"",ETA_PLAN:"2020-12-15",ETA_ACTUAL:"",ROS:"2020-12-20",ROS_ETA_DELTA:5,UPPERBLOCK:"69A",UPPERBLOCK_ERECTION:"2020-10-05",ROS_ETA_UPPERBLOCK_DELTA:-71,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE",ROOM:"M104 MECH WORKSHOP"},
      {NO:174,TAG:"21-WEHS-983001",TAG_DESCR:"Hacksaw",PARENT_TAG:"21-WEHS-983001",DIMENSION:"1250*360*850",WEIGHT:190,SN:35,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"2020-02-26",PO_ACTUAL:"2020-02-26",IRN_PLAN:"",ETA_PLAN:"2020-12-15",ETA_ACTUAL:"",ROS:"2020-12-20",ROS_ETA_DELTA:5,UPPERBLOCK:"69A",UPPERBLOCK_ERECTION:"2020-10-05",ROS_ETA_UPPERBLOCK_DELTA:-71,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE",ROOM:"M104 MECH WORKSHOP"},
      {NO:172,TAG:"FRAMO-REMOTE-CONTROL-VALVES-1",TAG_DESCR:"FRAMO-REMOTE-CONTROL-VALVES-1",PARENT_TAG:"FRAMO-REMOTE-CONTROL-VALVES-1",SN:33,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK:"69A",UPPERBLOCK_ERECTION:"2020-10-05",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE"},
      {NO:171,TAG:"FRAMO-REMOTE-CONTROL-VALVES-2",TAG_DESCR:"FRAMO-REMOTE-CONTROL-VALVES-2",PARENT_TAG:"FRAMO-REMOTE-CONTROL-VALVES-2",SN:32,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK:"69A",UPPERBLOCK_ERECTION:"2020-10-05",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE"},
      {NO:170,TAG:"FRAMO-REMOTE-CONTROL-VALVES-3",TAG_DESCR:"FRAMO-REMOTE-CONTROL-VALVES-3",PARENT_TAG:"FRAMO-REMOTE-CONTROL-VALVES-3",SN:31,POSITION:"R",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS01",PO_PLAN:"",PO_ACTUAL:"",IRN_PLAN:"",ETA_PLAN:"",ETA_ACTUAL:"",ROS:"",ROS_ETA_DELTA:0,UPPERBLOCK:"69A",UPPERBLOCK_ERECTION:"2020-10-05",ROS_ETA_UPPERBLOCK_DELTA:0,LIFTING:"",INSTALL:"",STATUS:"NOT_PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"1ST DECK",LOCATION:"MACHINERY SPACE"},
      {NO:257,TAG:"21-P-805002A",TAG_DESCR:"Hull Cooling Water Pump",PARENT_TAG:"21-P-805002A",DIMENSION:"1600*660*828",WEIGHT:696,SN:13,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS04",PO_PLAN:"2019-10-31",PO_ACTUAL:"2019-10-31",IRN_PLAN:"",ETA_PLAN:"2020-12-15",ETA_ACTUAL:"",ROS:"2020-12-21",ROS_ETA_DELTA:6,UPPERBLOCK:"69F",UPPERBLOCK_ERECTION:"2020-07-02",ROS_ETA_UPPERBLOCK_DELTA:-166,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"BOTTOM",LOCATION:"MACHINERY SPACE",ROOM:"MB02 EAST PUMP ROOM"},
      {NO:258,TAG:"21-P-805002B",TAG_DESCR:"Hull Cooling Water Pump",PARENT_TAG:"21-P-805002B",DIMENSION:"1600*660*828",WEIGHT:696,SN:14,POSITION:"L",LV1:"HULL",LV2:"HULL-FOR",LV3:"MS",LV4:"MS04",PO_PLAN:"2019-10-31",PO_ACTUAL:"2019-10-31",IRN_PLAN:"",ETA_PLAN:"2020-12-15",ETA_ACTUAL:"",ROS:"2020-12-21",ROS_ETA_DELTA:6,UPPERBLOCK:"69F",UPPERBLOCK_ERECTION:"2020-07-02",ROS_ETA_UPPERBLOCK_DELTA:-166,LIFTING:"",INSTALL:"",STATUS:"PO",GWBS_LV1:"HULL & LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",GWBS_LEVEL:"BOTTOM",LOCATION:"MACHINERY SPACE",ROOM:"MB02 EAST PUMP ROOM"}
      ],
}