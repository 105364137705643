import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1900,
      CanvasHeight: 800,
      CanvasBorderWeight: 0.3,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 310,
      CanvasChartHeight: 270,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: 'Transparent',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },

    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },
    Queries: {
      SQL1: [
        {CUTOFF:"2022-07-01"}
      ],
    },

    DataItems:    [
      {NO:1,EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-01",LMS_DESCR:"GEG Cogen Final Agreement Signed",PLAN:"2021-09-01",FORECAST:"2021-09-01",ACTUAL:"2021-10-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Final agreement signed",LOOKAHEAD:"NA",YEARS:2021},
      {NO:2,EM_LEAD:"Batey",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-02",LMS_DESCR:"Naphtha/LPG Sourcing Plan/Shipping Strategy refresh",PLAN:"2021-09-01",FORECAST:"2021-09-01",ACTUAL:"2021-09-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Report finalized and issued",LOOKAHEAD:"NA",YEARS:2021},
      {NO:3,EM_LEAD:"Stein",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-03",LMS_DESCR:"FID Achieved",PLAN:"2021-11-01",FORECAST:"2021-11-01",ACTUAL:"2021-11-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"FID approval receipt and funds in place",LOOKAHEAD:"NA",YEARS:2021},
      {NO:4,EM_LEAD:"Stein",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-04",LMS_DESCR:"Gate 3 Review",PLAN:"2021-11-01",FORECAST:"2021-11-01",ACTUAL:"2021-11-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Approval to progress",LOOKAHEAD:"NA",YEARS:2021},
      {NO:5,EM_LEAD:"Batey",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-05",LMS_DESCR:"Firm deal on LPG Terminal",PLAN:"2021-11-01",FORECAST:"2021-11-01",ACTUAL:"2021-11-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Documented agreement",LOOKAHEAD:"NA",YEARS:2021},
      // {NO:6,EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-06",LMS_DESCR:"CWAG WWTP Final Agreement Signed",PLAN:"2022-01-01",FORECAST:"2022-01-01",ACTUAL:"2022-01-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Final Agreement Signed / Documented",LOOKAHEAD:"NA",YEARS:2022},
      // {NO:7,EM_LEAD:"M. Lo",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-07",LMS_DESCR:"Define business model & scope of associated agreements",PLAN:"2022-01-01",FORECAST:"2022-01-01",ACTUAL:"2022-01-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Business model proposal reviewed and aligned with key stakeholders and steering committee types of agreements defined",LOOKAHEAD:"NA",YEARS:2022},
      // {NO:8,EM_LEAD:"M. Lo/EMIT",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-08",LMS_DESCR:"Financial & Business process system design complete cross-functional alignment",PLAN:"2022-08-01",FORECAST:"2022-08-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Business requirement & process defined and system design completed and aligned",LOOKAHEAD:"90D",YEARS:2022},
      // {NO:9,EM_LEAD:"W. Zhang",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-09",LMS_DESCR:"Business Readiness Cold Eyes Review 1",PLAN:"2022-12-01",FORECAST:"2022-12-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Review completed wit follow ups documented / planned",LOOKAHEAD:"NA",YEARS:2022},
      // {NO:10,EM_LEAD:"M. Lo/EMIT",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-10",LMS_DESCR:"Master Data Plan Defined",PLAN:"2023-07-01",FORECAST:"2023-07-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Define master data requirement timeline robust execution plan to ensure data integrity",LOOKAHEAD:"NA",YEARS:2023},
      // {NO:11,EM_LEAD:"M. Lo",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-11",LMS_DESCR:"Solids Mini URT complete",PLAN:"2023-08-01",FORECAST:"2023-08-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Early identification of process/system issues with gap closure plan",LOOKAHEAD:"NA",YEARS:2023},
      // {NO:12,EM_LEAD:"Batey",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-12",LMS_DESCR:"Feedstock Acquisition in Corporate Plan",PLAN:"2023-06-01",FORECAST:"2023-06-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented in Corp Plan",LOOKAHEAD:"NA",YEARS:2023},
      // {NO:13,EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-13",LMS_DESCR:"Product deployment plan  Corp Plan & S/U basis",PLAN:"2023-09-01",FORECAST:"2023-09-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented in Corp Plan",LOOKAHEAD:"NA",YEARS:2023},
      // {NO:14,EM_LEAD:"S. Shu",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-14",LMS_DESCR:"Polys raw matl supply plans in place / Catalyst & Product subn to PS&RA complete",PLAN:"2023-09-01",FORECAST:"2023-09-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Plans documented / in place",LOOKAHEAD:"NA",YEARS:2023},
      // {NO:15,EM_LEAD:"S. Shu",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-15",LMS_DESCR:"PE/PP product deployment plans complete",PLAN:"2023-09-01",FORECAST:"2023-09-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Plans documented / in place",LOOKAHEAD:"NA",YEARS:2023},
      // {NO:16,EM_LEAD:"Emmen",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-16",LMS_DESCR:"External relationship w/ Gov. agencies and neighbors established",PLAN:"2023-08-01",FORECAST:"2023-08-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Plans documented / in place",LOOKAHEAD:"NA",YEARS:2023},
      // {NO:17,EM_LEAD:"M. Lo",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-17",LMS_DESCR:"Business Process End-to-end user readiness testing Cold Eyes Review",PLAN:"2023-10-01",FORECAST:"2023-10-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Any potential gaps identified and closures plan in place to close the gaps",LOOKAHEAD:"NA",YEARS:2023},
      // {NO:18,EM_LEAD:"Batey",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-18",LMS_DESCR:"Acquisition / Trading organization in-place (fuels)",PLAN:"2023-09-01",FORECAST:"2023-09-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Plans documented / in place",LOOKAHEAD:"NA",YEARS:2023},
      // {NO:19,EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-20",LMS_DESCR:"PS&RA input for BC products",PLAN:"2023-10-01",FORECAST:"2023-10-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Plans documented / in place",LOOKAHEAD:"NA",YEARS:2023},
      // {NO:20,EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-21",LMS_DESCR:"Sign Grid power purchase agreement",PLAN:"2023-10-01",FORECAST:"2023-10-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Agreement documented / in place - For boiler plate pay whatever rate is",LOOKAHEAD:"NA",YEARS:2023},
      // {NO:21,EM_LEAD:"M. Lo",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-22",LMS_DESCR:"Solids End-to-end URT complete",PLAN:"2024-01-01",FORECAST:"2024-01-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Identification of any process/system issues and gap closure in place",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:22,EM_LEAD:"S. Shu",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-23",LMS_DESCR:"Cold pack test plan complete",PLAN:"2024-01-01",FORECAST:"2024-01-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Signed off and documented",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:23,EM_LEAD:"S. Shu",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-24",LMS_DESCR:"Detailed transition plan (WH early operation) Complete",PLAN:"2024-02-01",FORECAST:"2024-02-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Signed off and documented",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:24,EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-25",LMS_DESCR:"BC and Fuels Resource Plan Complete",PLAN:"2024-01-01",FORECAST:"2024-01-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Signed off and documented",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:25,EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-26",LMS_DESCR:"BC and Fuels Product testing plan (FET)",PLAN:"2024-01-01",FORECAST:"2024-01-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Signed off and documented",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:26,EM_LEAD:"M. Lo",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-28",LMS_DESCR:"Business Systems Go Live (Migration to production)",PLAN:"2024-05-01",FORECAST:"2024-05-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Successful execution of  end-to-end commercial transactions",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:27,EM_LEAD:"S. Shu",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-29",LMS_DESCR:"Customer level deployment plan including comms and product qualn plan",PLAN:"2024-08-01",FORECAST:"2024-08-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:28,EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-30",LMS_DESCR:"Commercial contingy playbook  Intern. comms/on-boarding complete",PLAN:"2024-07-01",FORECAST:"2024-07-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:29,EM_LEAD:"S. Shu",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-31",LMS_DESCR:"Commercial & Logistics contingency playbook complete",PLAN:"2024-08-01",FORECAST:"2024-08-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:30,EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-32",LMS_DESCR:"Liquids Command Center go-live",PLAN:"2024-10-01",FORECAST:"2024-10-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:31,EM_LEAD:"HT. Yeo",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-33",LMS_DESCR:"Mogas/FO export license and quota obtained",PLAN:"2024-11-01",FORECAST:"2024-11-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:32,EM_LEAD:"S. Shu",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-34",LMS_DESCR:"Polymers Command Center Go Live",PLAN:"2024-11-01",FORECAST:"2024-11-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:33,EM_LEAD:"S. Shu",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-35",LMS_DESCR:"Polymers Business Readiness - ready to start transition to base org.",PLAN:"2025-01-01",FORECAST:"2025-01-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented",LOOKAHEAD:"NA",YEARS:2025},
      // {NO:34,EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-36",LMS_DESCR:"Basic Chemicals / F&L Business RFSU",PLAN:"2025-01-01",FORECAST:"2025-01-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented",LOOKAHEAD:"NA",YEARS:2025},
      // {NO:35,EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-37",LMS_DESCR:"Waste water treatment commencement of construction",PLAN:"2022-06-01",FORECAST:"2022-06-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Commence WWT construction",LOOKAHEAD:"90D",YEARS:2022},
      // {NO:36,EM_LEAD:"L. Fung",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-38",LMS_DESCR:"LPG service agreement in place",PLAN:"2023-01-01",FORECAST:"2023-01-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Agreement signed / documented",LOOKAHEAD:"NA",YEARS:2023},
      // {NO:37,EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-39",LMS_DESCR:"Waste water treatment system MC (QY)",PLAN:"2023-09-01",FORECAST:"2023-09-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"WWT system turned over",LOOKAHEAD:"NA",YEARS:2023},
      // {NO:38,EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-40",LMS_DESCR:"Natural gas west link pipeline MC",PLAN:"2023-10-01",FORECAST:"2023-10-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"NG westlink pipeline turned over",LOOKAHEAD:"NA",YEARS:2023},
      // {NO:39,EM_LEAD:"W. Zhang",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-41",LMS_DESCR:"Business Readiness Cold Eyes Review 2",PLAN:"2023-10-01",FORECAST:"2023-10-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Review completed with follow ups documented / planned",LOOKAHEAD:"NA",YEARS:2023},
      // {NO:40,EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-42",LMS_DESCR:"GEG main plant MC",PLAN:"2023-11-01",FORECAST:"2023-11-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Main plant turned over",LOOKAHEAD:"NA",YEARS:2023},
      // {NO:41,EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-43",LMS_DESCR:"Industrial water Purchase Agreement in Place",PLAN:"2023-11-01",FORECAST:"2023-11-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Ready to supply water",LOOKAHEAD:"NA",YEARS:2023},
      // {NO:42,PACKAGE:"P3",EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-44",LMS_DESCR:"N2 RFU",PLAN:"2024-01-01",FORECAST:"2024-01-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Ready to supply N2",LOOKAHEAD:"NA",YEARS:2024,KMS_TOTAL:6,KMS_ACTUAL:0,KMS_REMAIN:6,KMS_PROG:0},
      // {NO:43,EM_LEAD:"M. Lo",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-45",LMS_DESCR:"Interaffiliate agreements finalized",PLAN:"2024-01-01",FORECAST:"2024-01-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Agreement signed / documented",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:44,EM_LEAD:"Batey",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-46",LMS_DESCR:"Feed purchase agreement in place",PLAN:"2024-03-01",FORECAST:"2024-03-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Agreement signed / documented",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:45,PACKAGE:"P3",EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-47",LMS_DESCR:"Towngas RFU",PLAN:"2024-03-01",FORECAST:"2024-03-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Ready to supply towngas",LOOKAHEAD:"NA",YEARS:2024,KMS_TOTAL:6,KMS_ACTUAL:0,KMS_REMAIN:6,KMS_PROG:0},
      // {NO:46,EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-48",LMS_DESCR:"Power purchase agreement (renewables or other alternatives)",PLAN:"2024-09-01",FORECAST:"2024-09-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Agreement documented / in place",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:47,EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-49",LMS_DESCR:"Naphtha import licence/quota obtained",PLAN:"2024-09-01",FORECAST:"2024-09-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:48,EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-50",LMS_DESCR:"H2 tube trailer RFU",PLAN:"2024-10-01",FORECAST:"2024-10-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Ready to supply H2",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:49,EM_LEAD:"Thompson",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-51",LMS_DESCR:"Co-product sales agreement in place",PLAN:"2025-01-01",FORECAST:"2025-01-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Agreement signed / documented",LOOKAHEAD:"NA",YEARS:2025},
      // {NO:50,EM_LEAD:"HT. Yeo",SEI_LEAD:"NA",FUNCTIONS:"Business",LMS_NO:"BUS-52",LMS_DESCR:"Fuels product sales agreement in place",PLAN:"2025-01-01",FORECAST:"2025-01-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Agreement signed / documented",LOOKAHEAD:"NA",YEARS:2025},
      // {NO:51,EM_LEAD:"XJ Li",SEI_LEAD:"NA",FUNCTIONS:"Commercial",LMS_NO:"CML-01",LMS_DESCR:"Chinese Government Engagement",PLAN:"2021-09-01",FORECAST:"2021-09-01",ACTUAL:"2021-09-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Documented engagement",LOOKAHEAD:"NA",YEARS:2021},
      // {NO:52,EM_LEAD:"XJ Li",SEI_LEAD:"NA",FUNCTIONS:"Commercial",LMS_NO:"CML-02",LMS_DESCR:"Final Investment Agreement with Government",PLAN:"2021-09-01",FORECAST:"2021-09-01",ACTUAL:"2021-09-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Documented agreement",LOOKAHEAD:"NA",YEARS:2021},
      // {NO:53,EM_LEAD:"K. Lin",SEI_LEAD:"NA",FUNCTIONS:"Commercial",LMS_NO:"CML-03",LMS_DESCR:"EIA for Base Plant Secured",PLAN:"2021-09-01",FORECAST:"2021-09-01",ACTUAL:"2021-09-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Receipt of approval letter from authorities",LOOKAHEAD:"NA",YEARS:2021},
      // {NO:54,EM_LEAD:"K. Lin",SEI_LEAD:"NA",FUNCTIONS:"Commercial",LMS_NO:"CML-04",LMS_DESCR:"EIA Approval secured",PLAN:"2021-09-01",FORECAST:"2021-09-01",ACTUAL:"2021-09-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Receipt of approval letter from authorities",LOOKAHEAD:"NA",YEARS:2021},
      // {NO:55,EM_LEAD:"K. Lin",SEI_LEAD:"NA",FUNCTIONS:"Commercial",LMS_NO:"CML-05",LMS_DESCR:"Construction Permit for Main Plant",PLAN:"2021-12-01",FORECAST:"2021-12-01",ACTUAL:"2021-12-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"1st construction permit issued to allow piling to start on main plot",LOOKAHEAD:"NA",YEARS:2021},
      // {NO:56,PACKAGE:"OP",EM_LEAD:"XJ Li",SEI_LEAD:"NA",FUNCTIONS:"Commercial",LMS_NO:"CML-06",LMS_DESCR:"Land for Liquid product Terminal turnover",PLAN:"2022-02-01",FORECAST:"2022-02-01",STATUS:"Delayed",DEFINITION_OF_SUCCESS:"Documented ownership transfer (Forecast Jun-22)",LOOKAHEAD:"NA",YEARS:2022,KMS_TOTAL:3,KMS_ACTUAL:3,KMS_REMAIN:0,KMS_PROG:100},
      // {NO:57,EM_LEAD:"M.Ren",SEI_LEAD:"NA",FUNCTIONS:"Commercial",LMS_NO:"CML-07",LMS_DESCR:"Alignment with government for tax incentive application",PLAN:"2024-10-01",FORECAST:"2024-10-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:58,EM_LEAD:"Mabry",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"CMP-01",LMS_DESCR:"EM Permit to Work system ready to go live",PLAN:"2023-08-01",FORECAST:"2023-08-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Working permitting program training complete aligned and approved",LOOKAHEAD:"NA",YEARS:2023},
      // {NO:59,EM_LEAD:"T Skeik",SEI_LEAD:"NA",FUNCTIONS:"Completions",LMS_NO:"CMP-02",LMS_DESCR:"CCR Ready to commence loop checking",PLAN:"2023-11-01",FORECAST:"2023-11-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"CCR handed over to Commissioning to support start of loop testing",LOOKAHEAD:"NA",YEARS:2023},
      // {NO:60,EM_LEAD:"T Skeik",SEI_LEAD:"NA",FUNCTIONS:"Completions",LMS_NO:"CMP-03",LMS_DESCR:"Main Substation Energized",PLAN:"2023-11-01",FORECAST:"2023-11-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Power on and ready for export",LOOKAHEAD:"NA",YEARS:2023},
      // {NO:61,EM_LEAD:"T Skeik",SEI_LEAD:"NA",FUNCTIONS:"Completions",LMS_NO:"CMP-04",LMS_DESCR:"Major utilities commissioned (support Boilers)",PLAN:"2024-05-01",FORECAST:"2024-05-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"All eqpt commissioned to provide streams for steam generation",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:62,EM_LEAD:"T Skeik",SEI_LEAD:"NA",FUNCTIONS:"Completions",LMS_NO:"CMP-06",LMS_DESCR:"1st Boiler Lit",PLAN:"2024-08-01",FORECAST:"2024-08-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"First boiler ready to energize",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:63,EM_LEAD:"T Skeik",SEI_LEAD:"NA",FUNCTIONS:"Completions",LMS_NO:"CMP-07",LMS_DESCR:"Begin critical path steam blowing campaign",PLAN:"2024-07-01",FORECAST:"2024-07-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"First steam blow path started",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:64,EM_LEAD:"T Skeik",SEI_LEAD:"NA",FUNCTIONS:"Completions",LMS_NO:"CMP-08",LMS_DESCR:"Finishing Facilities 1st RFSU",PLAN:"2024-11-01",FORECAST:"2024-11-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Finishing lines ready to test / prove out with granules for 3 extruders",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:65,EM_LEAD:"T Skeik",SEI_LEAD:"NA",FUNCTIONS:"Completions",LMS_NO:"CMP-09",LMS_DESCR:"(PGC) Run In & Commence Air Blowing and/or Dry Out",PLAN:"2024-11-01",FORECAST:"2024-11-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"PGC commissioned in service ready for air blows (if reqd)",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:66,EM_LEAD:"T Skeik",SEI_LEAD:"NA",FUNCTIONS:"Completions",LMS_NO:"CMP-10",LMS_DESCR:"All cracker and required co-product systems RFSU",PLAN:"2025-02-01",FORECAST:"2025-02-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Cracker & co-products units commissioned and sanctioned for Oil In",LOOKAHEAD:"NA",YEARS:2025},
      // {NO:67,PACKAGE:"P3",EM_LEAD:"T Skeik",SEI_LEAD:"NA",FUNCTIONS:"Completions",LMS_NO:"CMP-11",LMS_DESCR:"Industrial Water / Fire Water Available",PLAN:"2023-12-01",FORECAST:"2023-12-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"IW/FW facilities complete / tested / commissioned and ready for service",LOOKAHEAD:"NA",YEARS:2023,KMS_TOTAL:5,KMS_ACTUAL:0,KMS_REMAIN:5,KMS_PROG:0},
      // {NO:68,EM_LEAD:"T Skeik",SEI_LEAD:"NA",FUNCTIONS:"Completions",LMS_NO:"CMP-12",LMS_DESCR:"OR Steam blow complete for CGC Air Run",PLAN:"2024-09-01",FORECAST:"2024-09-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"All required for CGC air run steam blow completed - To Be Advised",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:69,PACKAGE:"P3",EM_LEAD:"T Skeik",SEI_LEAD:"NA",FUNCTIONS:"Completions",LMS_NO:"CMP-13",LMS_DESCR:"Cooling Water System in Service for Utilities & Olefins",PLAN:"2024-07-01",FORECAST:"2024-07-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"CWS commissioned and ready for use in Utilities/Olefins units",LOOKAHEAD:"NA",YEARS:2024,KMS_TOTAL:6,KMS_ACTUAL:0,KMS_REMAIN:6,KMS_PROG:0},
      // {NO:70,PACKAGE:"P3",EM_LEAD:"T Skeik",SEI_LEAD:"NA",FUNCTIONS:"Completions",LMS_NO:"CMP-14",LMS_DESCR:"Cooling Water System in Service for Polymers & Co-Prod",PLAN:"2024-08-01",FORECAST:"2024-08-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"CWS commissioned and ready for use in Polymers & Co-Products units",LOOKAHEAD:"NA",YEARS:2024,KMS_TOTAL:6,KMS_ACTUAL:0,KMS_REMAIN:6,KMS_PROG:0},
      // {NO:71,PACKAGE:"P1",EM_LEAD:"ODonnell",SEI_LEAD:"Hou",FUNCTIONS:"Construction",LMS_NO:"CON-01",LMS_DESCR:"Start main plant piling (Basin / Furnace Areas)",PLAN:"2021-12-01",FORECAST:"2021-12-01",ACTUAL:"2021-12-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Rig on site piles available first pile driven. (continuous pile program sustainable)",LOOKAHEAD:"NA",YEARS:2021,KMS_TOTAL:3,KMS_ACTUAL:3,KMS_REMAIN:0,KMS_PROG:100},
      // {NO:72,PACKAGE:"P2",EM_LEAD:"ODonnell",SEI_LEAD:"Bai",FUNCTIONS:"Construction",LMS_NO:"CON-03",LMS_DESCR:"Heavy Lifts Commenced",PLAN:"2022-12-01",FORECAST:"2022-12-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Commence Heavy Lift program",LOOKAHEAD:"NA",YEARS:2022,KMS_TOTAL:3,KMS_ACTUAL:0,KMS_REMAIN:3,KMS_PROG:0},
      // {NO:73,PACKAGE:"P2",EM_LEAD:"Ang",SEI_LEAD:"Bai",FUNCTIONS:"Engineering",LMS_NO:"ENG-01",LMS_DESCR:"All HAZOP Complete",PLAN:"2021-11-01",FORECAST:"2021-11-01",ACTUAL:"2021-11-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Reviews complete - resolution plan established",LOOKAHEAD:"NA",YEARS:2021,KMS_TOTAL:3,KMS_ACTUAL:3,KMS_REMAIN:0,KMS_PROG:100},
      // {NO:74,PACKAGE:"P1",EM_LEAD:"Ang",SEI_LEAD:"Hou",FUNCTIONS:"Engineering",LMS_NO:"ENG-02",LMS_DESCR:"Olefins 30% Model Review",PLAN:"2022-01-01",FORECAST:"2022-01-01",ACTUAL:"2022-01-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Review complete with definition per CP (documented follow up plan)",LOOKAHEAD:"NA",YEARS:2022,KMS_TOTAL:1,KMS_ACTUAL:1,KMS_REMAIN:0,KMS_PROG:100},
      // {NO:75,PACKAGE:"P2",EM_LEAD:"Ang",SEI_LEAD:"Bai",FUNCTIONS:"Engineering",LMS_NO:"ENG-03",LMS_DESCR:"BED Completed",PLAN:"2022-01-01",FORECAST:"2022-01-01",ACTUAL:"2022-01-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Review complete with definition per CP (documented follow up plan)",LOOKAHEAD:"NA",YEARS:2022,KMS_TOTAL:5,KMS_ACTUAL:5,KMS_REMAIN:0,KMS_PROG:100},
      // {NO:76,PACKAGE:"P1",EM_LEAD:"Ang",SEI_LEAD:"Hou",FUNCTIONS:"Engineering",LMS_NO:"ENG-04",LMS_DESCR:"Furnaces 30% Model Review",PLAN:"2022-01-01",FORECAST:"2022-01-01",ACTUAL:"2022-01-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Review complete with definition per CP (documented follow up plan)",LOOKAHEAD:"NA",YEARS:2022,KMS_TOTAL:1,KMS_ACTUAL:1,KMS_REMAIN:0,KMS_PROG:100},
      // {NO:77,PACKAGE:"P1",EM_LEAD:"Ang",SEI_LEAD:"Hou",FUNCTIONS:"Engineering",LMS_NO:"ENG-05",LMS_DESCR:"Co-Products 30% Model Review",PLAN:"2022-01-01",FORECAST:"2022-01-01",ACTUAL:"2022-01-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Review complete with definition per CP (documented follow up plan)",LOOKAHEAD:"NA",YEARS:2022,KMS_TOTAL:2,KMS_ACTUAL:2,KMS_REMAIN:0,KMS_PROG:100},
      // {NO:78,PACKAGE:"P2",EM_LEAD:"Ang",SEI_LEAD:"Ruan",FUNCTIONS:"Engineering",LMS_NO:"ENG-06",LMS_DESCR:"Polymers 30% Model Review",PLAN:"2022-01-01",FORECAST:"2022-01-01",ACTUAL:"2022-01-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Review complete with definition per CP (documented follow up plan)",LOOKAHEAD:"NA",YEARS:2022,KMS_TOTAL:5,KMS_ACTUAL:5,KMS_REMAIN:0,KMS_PROG:100},
      // {NO:79,PACKAGE:"P1",EM_LEAD:"Ang",SEI_LEAD:"Hou",FUNCTIONS:"Engineering",LMS_NO:"ENG-07",LMS_DESCR:"Furnaces 60% Model Review",PLAN:"2022-10-01",FORECAST:"2022-10-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Review complete with definition per CP (documented follow up plan)",LOOKAHEAD:"NA",YEARS:2022,KMS_TOTAL:1,KMS_ACTUAL:0,KMS_REMAIN:1,KMS_PROG:0},
      // {NO:80,PACKAGE:"P2",EM_LEAD:"Ang",SEI_LEAD:"Ruan",FUNCTIONS:"Engineering",LMS_NO:"ENG-08",LMS_DESCR:"Polymers 60% Model Reviews complete",PLAN:"2022-12-01",FORECAST:"2022-12-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Review complete with definition per CP (documented follow up plan)",LOOKAHEAD:"NA",YEARS:2022,KMS_TOTAL:16,KMS_ACTUAL:2,KMS_REMAIN:14,KMS_PROG:12.5},
      // {NO:81,EM_LEAD:"Ang",SEI_LEAD:"Hou",FUNCTIONS:"Engineering",LMS_NO:"ENG-09",LMS_DESCR:"Co-Products 60% Model Review",PLAN:"2022-12-01",FORECAST:"2022-12-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Review complete with definition per CP (documented follow up plan)",LOOKAHEAD:"NA",YEARS:2022},
      // {NO:82,EM_LEAD:"Ang",SEI_LEAD:"Bai",FUNCTIONS:"Engineering",LMS_NO:"ENG-10",LMS_DESCR:"60% Model Reviews Complete",PLAN:"2022-12-01",FORECAST:"2022-12-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Review complete with definition per CP (documented follow up plan)",LOOKAHEAD:"NA",YEARS:2022},
      // {NO:83,PACKAGE:"P1",EM_LEAD:"Ang",SEI_LEAD:"Hou",FUNCTIONS:"Engineering",LMS_NO:"ENG-11",LMS_DESCR:"OR 60% Model Review",PLAN:"2022-11-01",FORECAST:"2022-11-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Review complete with definition per CP (documented follow up plan)",LOOKAHEAD:"NA",YEARS:2022,KMS_TOTAL:4,KMS_ACTUAL:0,KMS_REMAIN:4,KMS_PROG:0},
      // {NO:84,PACKAGE:"P1",EM_LEAD:"Ang",SEI_LEAD:"Hou",FUNCTIONS:"Engineering",LMS_NO:"ENG-12",LMS_DESCR:"Furnaces 90% Model Review",PLAN:"2023-04-01",FORECAST:"2023-04-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Review complete with definition per CP (documented follow up plan)",LOOKAHEAD:"NA",YEARS:2023,KMS_TOTAL:2,KMS_ACTUAL:0,KMS_REMAIN:2,KMS_PROG:0},
      // {NO:85,PACKAGE:"P1",EM_LEAD:"Ang",SEI_LEAD:"Hou",FUNCTIONS:"Engineering",LMS_NO:"ENG-13",LMS_DESCR:"Co-Products 90% Model Review",PLAN:"2023-06-01",FORECAST:"2023-06-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Review complete with definition per CP (documented follow up plan)",LOOKAHEAD:"NA",YEARS:2023,KMS_TOTAL:2,KMS_ACTUAL:0,KMS_REMAIN:2,KMS_PROG:0},
      // {NO:86,PACKAGE:"P2",EM_LEAD:"Ang",SEI_LEAD:"Ruan",FUNCTIONS:"Engineering",LMS_NO:"ENG-14",LMS_DESCR:"Polymers 90% Model Review",PLAN:"2023-06-01",FORECAST:"2023-06-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Review complete with definition per CP (documented follow up plan)",LOOKAHEAD:"NA",YEARS:2023,KMS_TOTAL:5,KMS_ACTUAL:0,KMS_REMAIN:5,KMS_PROG:0},
      // {NO:87,PACKAGE:"P1",EM_LEAD:"Ang",SEI_LEAD:"Hou",FUNCTIONS:"Engineering",LMS_NO:"ENG-15",LMS_DESCR:"Olefins 90% Model Review",PLAN:"2023-05-01",FORECAST:"2023-05-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Review complete with definition per CP (documented follow up plan)",LOOKAHEAD:"NA",YEARS:2023,KMS_TOTAL:2,KMS_ACTUAL:0,KMS_REMAIN:2,KMS_PROG:0},
      // {NO:88,PACKAGE:"OP",EM_LEAD:"Atwood/Lin",SEI_LEAD:"NA",FUNCTIONS:"Infrastructure",LMS_NO:"INF-01",LMS_DESCR:"Nature Protection Zone Adjustment to Support Jetty Construction",PLAN:"2021-08-01",FORECAST:"2021-08-01",ACTUAL:"2021-08-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Government approval received",LOOKAHEAD:"NA",YEARS:2021},
      // {NO:89,PACKAGE:"OP",EM_LEAD:"Atwood",SEI_LEAD:"NA",FUNCTIONS:"Infrastructure",LMS_NO:"INF-02",LMS_DESCR:"MOF & HHR Ready for Operation",PLAN:"2022-11-01",FORECAST:"2022-11-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"MOF capable of off-loading vessels / equipment",LOOKAHEAD:"NA",YEARS:2022,KMS_TOTAL:10,KMS_ACTUAL:3,KMS_REMAIN:7,KMS_PROG:30},
      // {NO:90,PACKAGE:"P3",EM_LEAD:"Atwood",SEI_LEAD:"Niu",FUNCTIONS:"Infrastructure",LMS_NO:"INF-03",LMS_DESCR:"Control Room Building - Ready to install hardware",PLAN:"2023-05-01",FORECAST:"2023-05-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Access to commence installation",LOOKAHEAD:"NA",YEARS:2023,KMS_TOTAL:5,KMS_ACTUAL:1,KMS_REMAIN:4,KMS_PROG:20},
      // {NO:91,PACKAGE:"P3",EM_LEAD:"Atwood",SEI_LEAD:"Niu",FUNCTIONS:"Infrastructure",LMS_NO:"INF-05",LMS_DESCR:"Main Sub Station MC",PLAN:"2023-09-01",FORECAST:"2023-09-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Main Sub-Station Turned Over",LOOKAHEAD:"NA",YEARS:2023,KMS_TOTAL:5,KMS_ACTUAL:1,KMS_REMAIN:4,KMS_PROG:20},
      // {NO:92,PACKAGE:"P3",EM_LEAD:"Atwood",SEI_LEAD:"NA",FUNCTIONS:"Infrastructure",LMS_NO:"INF-06",LMS_DESCR:"220kV external power source available (for Main Sub)",PLAN:"2023-09-01",FORECAST:"2023-09-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Supply available to use",LOOKAHEAD:"NA",YEARS:2023,KMS_TOTAL:7,KMS_ACTUAL:0,KMS_REMAIN:7,KMS_PROG:0},
      // {NO:93,EM_LEAD:"Emmen",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"INF-08",LMS_DESCR:"Partial logistic & facilities delivered",PLAN:"2023-09-01",FORECAST:"2023-09-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Ready for Utilities commissioning",LOOKAHEAD:"NA",YEARS:2023},
      // {NO:94,PACKAGE:"P3",EM_LEAD:"Atwood",SEI_LEAD:"Niu",FUNCTIONS:"Infrastructure",LMS_NO:"INF-09",LMS_DESCR:"Admin complex ready for occupation by manufacturing",PLAN:"2024-01-01",FORECAST:"2024-01-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Complex Handed Over",LOOKAHEAD:"NA",YEARS:2024,KMS_TOTAL:7,KMS_ACTUAL:0,KMS_REMAIN:7,KMS_PROG:0},
      // {NO:95,PACKAGE:"P3",EM_LEAD:"Atwood",SEI_LEAD:"Niu",FUNCTIONS:"Infrastructure",LMS_NO:"INF-10",LMS_DESCR:"Air System MC (PA/IA)",PLAN:"2024-01-01",FORECAST:"2024-01-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"System Turned Over",LOOKAHEAD:"NA",YEARS:2024,KMS_TOTAL:5,KMS_ACTUAL:1,KMS_REMAIN:4,KMS_PROG:20},
      // {NO:96,PACKAGE:"P3",EM_LEAD:"Atwood",SEI_LEAD:"Niu",FUNCTIONS:"Infrastructure",LMS_NO:"INF-12",LMS_DESCR:"Control Room Building MC / Turned Over",PLAN:"2023-10-01",FORECAST:"2023-10-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"MC and handed over",LOOKAHEAD:"NA",YEARS:2023,KMS_TOTAL:4,KMS_ACTUAL:0,KMS_REMAIN:4,KMS_PROG:0},
      // {NO:97,PACKAGE:"P3",EM_LEAD:"T Skeik",SEI_LEAD:"Niu",FUNCTIONS:"Infrastructure",LMS_NO:"INF-13",LMS_DESCR:"Waste Water Treatment system RFSU",PLAN:"2024-03-01",FORECAST:"2024-03-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"QY and on-plot facs ready - Ready for CWS pre-com SEI MC Jan 2024",LOOKAHEAD:"NA",YEARS:2024,KMS_TOTAL:8,KMS_ACTUAL:0,KMS_REMAIN:8,KMS_PROG:0},
      // {NO:98,PACKAGE:"OP",EM_LEAD:"A. Ai",SEI_LEAD:"NA",FUNCTIONS:"Supply Chain",LMS_NO:"INF-15",LMS_DESCR:"Liquid Terminal / Liquid Jetty ready for material receipt",PLAN:"2024-09-01",FORECAST:"2024-09-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Certified for import use",LOOKAHEAD:"NA",YEARS:2024,KMS_TOTAL:20,KMS_ACTUAL:3,KMS_REMAIN:17,KMS_PROG:15},
      // {NO:99,PACKAGE:"P3",EM_LEAD:"T Skeik",SEI_LEAD:"NA",FUNCTIONS:"Infrastructure",LMS_NO:"INF-16",LMS_DESCR:"Flare system RFSU (Ground and Elevated)",PLAN:"2024-09-01",FORECAST:"2024-09-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Flare system ready to receive H/C SEI MC May 2024 (Elevated Mar-24 MC)",LOOKAHEAD:"NA",YEARS:2024,KMS_TOTAL:7,KMS_ACTUAL:0,KMS_REMAIN:7,KMS_PROG:0},
      // {NO:100,PACKAGE:"OP",EM_LEAD:"Atwood",SEI_LEAD:"NA",FUNCTIONS:"Infrastructure",LMS_NO:"INF-17",LMS_DESCR:"Naphtha Discharge Facilities / Pipeline RFSU",PLAN:"2024-09-01",FORECAST:"2024-09-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Facilities ready to supply/receive product",LOOKAHEAD:"NA",YEARS:2024,KMS_TOTAL:5,KMS_ACTUAL:1,KMS_REMAIN:4,KMS_PROG:20},
      // {NO:101,EM_LEAD:"Emmen",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-01",LMS_DESCR:"Completed Hydrocarbon introduction plans for cracker coproducts & pmers",PLAN:"2022-04-01",FORECAST:"2022-04-01",ACTUAL:"2022-04-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Documented Plans",LOOKAHEAD:"NA",YEARS:2022},
      // {NO:102,EM_LEAD:"Emmen",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-02",LMS_DESCR:"Develop plant testing scope plan resourcing duration and cost",PLAN:"2022-04-01",FORECAST:"2022-04-01",ACTUAL:"2022-04-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Documented Plans",LOOKAHEAD:"NA",YEARS:2022},
      // {NO:103,EM_LEAD:"Emmen",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-03",LMS_DESCR:"Mfg. Recruitment Plan - Progress Check Point",PLAN:"2023-02-01",FORECAST:"2023-02-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Check progress to ensure Mfg. is on plan",LOOKAHEAD:"NA",YEARS:2023},
      // {NO:104,EM_LEAD:"Emmen",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-04",LMS_DESCR:"Training of Culture / OE / Leadership programs on track as per competency plans",PLAN:"2023-07-01",FORECAST:"2023-07-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Verified competency plans on track",LOOKAHEAD:"NA",YEARS:2023},
      // {NO:105,EM_LEAD:"Emmen",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-05",LMS_DESCR:"Early Stand Up Complete (Ph 1)",PLAN:"2023-10-01",FORECAST:"2023-10-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Mfg. team trained and ready for field execution (Utilities)",LOOKAHEAD:"NA",YEARS:2023},
      // {NO:106,EM_LEAD:"Emmen",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-06",LMS_DESCR:"New Ops stand-up / OR (system) development/ deliverables meet Stage 3 / 4 Plan",PLAN:"2023-10-01",FORECAST:"2023-10-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Plans documented / in place",LOOKAHEAD:"NA",YEARS:2023},
      // {NO:107,EM_LEAD:"Emmen",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-07",LMS_DESCR:"O&M manuals spare parts & materials work procedures contractors services",PLAN:"2023-10-01",FORECAST:"2023-10-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Plans documented / in place",LOOKAHEAD:"NA",YEARS:2023},
      // {NO:108,EM_LEAD:"Emmen",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-08",LMS_DESCR:"Organization ready to comm start-up and operate O&U facilities",PLAN:"2023-12-01",FORECAST:"2023-12-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Mfg. team trained and ready for field execution",LOOKAHEAD:"NA",YEARS:2023},
      // {NO:109,EM_LEAD:"Mabry",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-09",LMS_DESCR:"Startup team competency has been formally assessed",PLAN:"2023-12-01",FORECAST:"2023-12-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Def - Mfg. assessment completed and documented",LOOKAHEAD:"NA",YEARS:2023},
      // {NO:110,EM_LEAD:"Emmen",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-10",LMS_DESCR:"New Ops stand-up & OR (system) development &deliverables meet Stg 4/5 Plan",PLAN:"2024-01-01",FORECAST:"2024-01-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Plans documented / in place",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:111,EM_LEAD:"Emmen",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-11",LMS_DESCR:"Global resource model deliver surge resource timely",PLAN:"2024-01-01",FORECAST:"2024-01-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Plans documented / in place",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:112,EM_LEAD:"Emmen",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-12",LMS_DESCR:"Maintenance & other non-process logistic & facilities delivered",PLAN:"2024-01-01",FORECAST:"2024-01-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:113,EM_LEAD:"Emmen",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-13",LMS_DESCR:"Plant operating & maintenance procedures ready",PLAN:"2024-02-01",FORECAST:"2024-02-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented - Need to advance to Jan 24",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:114,EM_LEAD:"Emmen",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-14",LMS_DESCR:"External interfaces w/Gov. agencies OBO Sites DYW Park vendors contractors established",PLAN:"2024-03-01",FORECAST:"2024-03-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:115,EM_LEAD:"Emmen",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-15",LMS_DESCR:"Stand-Up Complete (Ph 2)",PLAN:"2024-07-01",FORECAST:"2024-07-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Teams ready to support Com/SU",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:116,EM_LEAD:"Mabry",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-16",LMS_DESCR:"Organization is ready to start-up and operate Polymers facilities",PLAN:"2024-07-01",FORECAST:"2024-07-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Teams ready to support Com/SU - More Def",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:117,EM_LEAD:"Mabry",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-16A",LMS_DESCR:"Organization is ready to start-up and operate Olefins facilities",PLAN:"2024-09-01",FORECAST:"2024-09-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Teams ready to support Com/SU - More Def",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:118,EM_LEAD:"Mabry",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-17",LMS_DESCR:"Ethylene/propylene available for Polymers",PLAN:"2024-09-01",FORECAST:"2024-09-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"C2/C3 facilities sanctioned to supply product",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:119,EM_LEAD:"Emmen",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-18",LMS_DESCR:"Final Public Affairs plan has been completed for SU",PLAN:"2024-09-01",FORECAST:"2024-09-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:120,PACKAGE:"P2",EM_LEAD:"Mabry",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-19",LMS_DESCR:"Warehouse & 1st Packaging Lines Proved Out",PLAN:"2024-10-01",FORECAST:"2024-10-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Warehouse/packaging lines ready to receive product (3/5)",LOOKAHEAD:"NA",YEARS:2024,KMS_TOTAL:5,KMS_ACTUAL:0,KMS_REMAIN:5,KMS_PROG:0},
      // {NO:121,EM_LEAD:"Mabry",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-20",LMS_DESCR:"All Pckgng / W/H / Logistics Cold Pack / RM / product movemt testing complete",PLAN:"2024-12-01",FORECAST:"2024-12-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:122,EM_LEAD:"Mabry",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-21",LMS_DESCR:"Polymer Units (3 of 5) Proved Out for Cracker",PLAN:"2025-01-01",FORECAST:"2025-01-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"3 trains and lines ready for full rates from Cracker (2xPE/1xPP)",LOOKAHEAD:"NA",YEARS:2025},
      // {NO:123,EM_LEAD:"Mabry",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-22",LMS_DESCR:"Polymers (Plants & Logistics) (5 of 5) Proved Out for Cracker",PLAN:"2025-02-01",FORECAST:"2025-02-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"All trains and lines ready for full rates from Cracker",LOOKAHEAD:"NA",YEARS:2025},
      // {NO:124,EM_LEAD:"Mabry",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-23",LMS_DESCR:"Cracker Started Up & Declared On Spec",PLAN:"2025-02-01",FORECAST:"2025-02-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented",LOOKAHEAD:"NA",YEARS:2025},
      // {NO:125,EM_LEAD:"Emmen",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-24",LMS_DESCR:"Facilities Performance Tests Achieved",PLAN:"2025-07-01",FORECAST:"2025-07-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented",LOOKAHEAD:"NA",YEARS:2025},
      // {NO:126,EM_LEAD:"Mabry",SEI_LEAD:"NA",FUNCTIONS:"Mfg.",LMS_NO:"MFG-25",LMS_DESCR:"Polymer Units 1st Line SU (1st Pellets)",PLAN:"2024-12-01",FORECAST:"2024-12-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"1st Polymer Line Running (1st Pellets)",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:127,PACKAGE:"P1",EM_LEAD:"Webb",SEI_LEAD:"Hou",FUNCTIONS:"Olefins",LMS_NO:"OLE-01",LMS_DESCR:"Critical Furnace LOIs Issued (Radiant coils/TLEs)",PLAN:"2021-10-01",FORECAST:"2021-10-01",ACTUAL:"2021-10-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"LOIs issued",LOOKAHEAD:"NA",YEARS:2021,KMS_TOTAL:2,KMS_ACTUAL:2,KMS_REMAIN:0,KMS_PROG:100},
      // {NO:128,PACKAGE:"P1",EM_LEAD:"Webb",SEI_LEAD:"Hou",FUNCTIONS:"Olefins",LMS_NO:"OLE-02",LMS_DESCR:"PGC LOI Issued",PLAN:"2021-10-01",FORECAST:"2021-10-01",ACTUAL:"2021-10-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"LOI issued",LOOKAHEAD:"NA",YEARS:2021,KMS_TOTAL:1,KMS_ACTUAL:1,KMS_REMAIN:0,KMS_PROG:100},
      // {NO:129,PACKAGE:"P1",EM_LEAD:"Webb",SEI_LEAD:"Hou",FUNCTIONS:"Olefins",LMS_NO:"OLE-03",LMS_DESCR:"Boiler LOI issued",PLAN:"2022-01-01",FORECAST:"2022-01-01",ACTUAL:"2022-01-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"LOI issued",LOOKAHEAD:"NA",YEARS:2022,KMS_TOTAL:1,KMS_ACTUAL:1,KMS_REMAIN:0,KMS_PROG:100},
      // {NO:130,PACKAGE:"P1",EM_LEAD:"Webb",SEI_LEAD:"Hou",FUNCTIONS:"Olefins",LMS_NO:"OLE-04",LMS_DESCR:"LOIs for balance LLE",PLAN:"2022-01-01",FORECAST:"2022-01-01",ACTUAL:"2022-01-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"LOIs issued for schedule critical equipment - per list (at start of EPC)",LOOKAHEAD:"NA",YEARS:2022,KMS_TOTAL:5,KMS_ACTUAL:5,KMS_REMAIN:0,KMS_PROG:100},
      // {NO:131,PACKAGE:"P1",EM_LEAD:"Webb",SEI_LEAD:"Hou",FUNCTIONS:"Olefins",LMS_NO:"OLE-05",LMS_DESCR:"OF & Major Compressors U/G & Critical Foundations Complete",PLAN:"2022-12-01",FORECAST:"2022-12-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Achieve a productive & safe work area complete area handover below the paving cap Underground piping ISBL Olefins complete Piling complete site wide Critical path Foundations complete  furnaces list others",LOOKAHEAD:"NA",YEARS:2022,KMS_TOTAL:4,KMS_ACTUAL:0,KMS_REMAIN:4,KMS_PROG:0},
      // {NO:132,PACKAGE:"P1",EM_LEAD:"Webb",SEI_LEAD:"Hou",FUNCTIONS:"Olefins",LMS_NO:"OLE-06",LMS_DESCR:"Major Towers Set In-Place",PLAN:"2023-01-01",FORECAST:"2023-01-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"3 main towers set (PF C3 Rectifier Quench tower)",LOOKAHEAD:"NA",YEARS:2023,KMS_TOTAL:9,KMS_ACTUAL:3,KMS_REMAIN:6,KMS_PROG:33.3},
      // {NO:133,PACKAGE:"P1",EM_LEAD:"Webb",SEI_LEAD:"Hou",FUNCTIONS:"Olefins",LMS_NO:"OLE-07",LMS_DESCR:"Co-Products Out of the Ground - UG & Critical Foundations",PLAN:"2023-01-01",FORECAST:"2023-01-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Achieve a productive & safe work area complete area handover below the paving cap Underground piping ISBL Olefins complete Piling complete site wide Critical path Foundations complete  furnaces list others",LOOKAHEAD:"NA",YEARS:2023,KMS_TOTAL:5,KMS_ACTUAL:0,KMS_REMAIN:5,KMS_PROG:0},
      // {NO:134,PACKAGE:"P1",EM_LEAD:"Webb",SEI_LEAD:"Hou",FUNCTIONS:"Olefins",LMS_NO:"OLE-08",LMS_DESCR:"Furnace Radiant Erection (1st) - Olefins Furnace",PLAN:"2023-01-01",FORECAST:"2023-01-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"1st tier structural steel and panels erected",LOOKAHEAD:"NA",YEARS:2023,KMS_TOTAL:6,KMS_ACTUAL:0,KMS_REMAIN:6,KMS_PROG:0},
      // {NO:135,PACKAGE:"P1",EM_LEAD:"Webb",SEI_LEAD:"Hou",FUNCTIONS:"Olefins",LMS_NO:"OLE-09",LMS_DESCR:"Furnace Set 1st TLEs - OF (Delivered)",PLAN:"2023-07-01",FORECAST:"2023-07-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"1st set of TLE delivered to site",LOOKAHEAD:"NA",YEARS:2023,KMS_TOTAL:2,KMS_ACTUAL:0,KMS_REMAIN:2,KMS_PROG:0},
      // {NO:136,PACKAGE:"P1",EM_LEAD:"Webb",SEI_LEAD:"Hou",FUNCTIONS:"Olefins",LMS_NO:"OLE-11",LMS_DESCR:"CGC Set In-Place",PLAN:"2023-11-01",FORECAST:"2023-11-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"CGC on table top",LOOKAHEAD:"NA",YEARS:2023,KMS_TOTAL:6,KMS_ACTUAL:0,KMS_REMAIN:6,KMS_PROG:0},
      // {NO:137,PACKAGE:"P1",EM_LEAD:"Webb",SEI_LEAD:"Hou",FUNCTIONS:"Olefins",LMS_NO:"OLE-12",LMS_DESCR:"Co-Products Major equip. Setting",PLAN:"2024-03-01",FORECAST:"2024-03-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"All Co-Prod. Equipment set in place",LOOKAHEAD:"NA",YEARS:2024,KMS_TOTAL:5,KMS_ACTUAL:0,KMS_REMAIN:5,KMS_PROG:0},
      // {NO:138,PACKAGE:"P1",EM_LEAD:"Webb",SEI_LEAD:"Hou",FUNCTIONS:"Olefins",LMS_NO:"OLE-13",LMS_DESCR:"Last Boiler Mechanical Completion",PLAN:"2024-04-01",FORECAST:"2024-04-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"All Boiler components turned over to owner",LOOKAHEAD:"NA",YEARS:2024,KMS_TOTAL:4,KMS_ACTUAL:0,KMS_REMAIN:4,KMS_PROG:0},
      // {NO:139,PACKAGE:"P1",EM_LEAD:"Webb",SEI_LEAD:"Hou",FUNCTIONS:"Olefins",LMS_NO:"OLE-14",LMS_DESCR:"Compressor System Mechanical Completion",PLAN:"2024-09-01",FORECAST:"2024-09-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"All PGC components turned over to owner",LOOKAHEAD:"NA",YEARS:2024,KMS_TOTAL:2,KMS_ACTUAL:0,KMS_REMAIN:2,KMS_PROG:0},
      // {NO:140,PACKAGE:"P1",EM_LEAD:"Webb",SEI_LEAD:"Hou",FUNCTIONS:"Olefins",LMS_NO:"OLE-15",LMS_DESCR:"BD Mechanical Completion",PLAN:"2024-10-01",FORECAST:"2024-10-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"All BD unit components turned over to owner",LOOKAHEAD:"NA",YEARS:2024,KMS_TOTAL:5,KMS_ACTUAL:0,KMS_REMAIN:5,KMS_PROG:0},
      // {NO:141,PACKAGE:"P1",EM_LEAD:"Webb",SEI_LEAD:"NA",FUNCTIONS:"Olefins",LMS_NO:"OLE-16",LMS_DESCR:"GEG steam available at site",PLAN:"2024-03-01",FORECAST:"2024-03-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Commissioned - Ready for steam blows",LOOKAHEAD:"NA",YEARS:2024,KMS_TOTAL:2,KMS_ACTUAL:0,KMS_REMAIN:2,KMS_PROG:0},
      // {NO:142,PACKAGE:"P1",EM_LEAD:"Webb",SEI_LEAD:"Hou",FUNCTIONS:"Olefins",LMS_NO:"OLE-17",LMS_DESCR:"Last Olefins system (incl remaining Co-Products) Mechanical Completion",PLAN:"2024-11-01",FORECAST:"2024-11-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"All Olefins units turned over to owner",LOOKAHEAD:"NA",YEARS:2024,KMS_TOTAL:5,KMS_ACTUAL:0,KMS_REMAIN:5,KMS_PROG:0},
      // {NO:143,PACKAGE:"P2",EM_LEAD:"Fuller",SEI_LEAD:"Ruan",FUNCTIONS:"Polymers",LMS_NO:"POL-01",LMS_DESCR:"Polymers critical equipment LOIs placed",PLAN:"2021-10-01",FORECAST:"2021-10-01",ACTUAL:"2021-10-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Defined LOIs issued - LDPE HP Pipe GPPE/PP extruders GPPE compressors",LOOKAHEAD:"NA",YEARS:2021,KMS_TOTAL:3,KMS_ACTUAL:3,KMS_REMAIN:0,KMS_PROG:100},
      // {NO:144,PACKAGE:"P2",EM_LEAD:"Fuller",SEI_LEAD:"Ruan",FUNCTIONS:"Polymers",LMS_NO:"POL-04",LMS_DESCR:"W/H & Packaging facilities MC Ready for Commissioning",PLAN:"2024-03-01",FORECAST:"2024-03-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Applicable subsystems complete",LOOKAHEAD:"NA",YEARS:2024,KMS_TOTAL:21,KMS_ACTUAL:0,KMS_REMAIN:21,KMS_PROG:0},
      // {NO:145,PACKAGE:"P2",EM_LEAD:"Fuller",SEI_LEAD:"Ruan",FUNCTIONS:"Polymers",LMS_NO:"POL-05",LMS_DESCR:"Polymers Finishing Units 1st Mechanical Completion",PLAN:"2024-07-01",FORECAST:"2024-07-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"First priority Finishing Lines MC",LOOKAHEAD:"NA",YEARS:2024,KMS_TOTAL:9,KMS_ACTUAL:0,KMS_REMAIN:9,KMS_PROG:0},
      // {NO:146,PACKAGE:"P2",EM_LEAD:"Fuller",SEI_LEAD:"Ruan",FUNCTIONS:"Polymers",LMS_NO:"POL-06",LMS_DESCR:"Polymers Poly Units 1st Mechanical Completion",PLAN:"2024-08-01",FORECAST:"2024-08-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"First priority Poly Units MC",LOOKAHEAD:"NA",YEARS:2024,KMS_TOTAL:19,KMS_ACTUAL:0,KMS_REMAIN:19,KMS_PROG:0},
      // {NO:147,PACKAGE:"P2",EM_LEAD:"Fuller",SEI_LEAD:"Ruan",FUNCTIONS:"Polymers",LMS_NO:"POL-07",LMS_DESCR:"Polymers Facilities Final Mechanical Completion",PLAN:"2024-09-01",FORECAST:"2024-09-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"All Polymers units turned over to owner",LOOKAHEAD:"NA",YEARS:2024,KMS_TOTAL:10,KMS_ACTUAL:0,KMS_REMAIN:10,KMS_PROG:0},
      // {NO:148,EM_LEAD:"Fuller",SEI_LEAD:"Ruan",FUNCTIONS:"Polymers",LMS_NO:"POL-08",LMS_DESCR:"Finishing Building Ready for Extruder Installation - GPPE",PLAN:"2023-07-01",FORECAST:"2023-07-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Building established and prepared for the commencement of extruder equipment installation Installation methodology defined and all required procedures and work method statements approved for use",LOOKAHEAD:"NA",YEARS:2023},
      // {NO:149,EM_LEAD:"Fuller",SEI_LEAD:"Ruan",FUNCTIONS:"Polymers",LMS_NO:"POL-09",LMS_DESCR:"Finishing Building Ready for Extruder Installation - PP",PLAN:"2023-07-01",FORECAST:"2023-07-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Building established and prepared for the commencement of extruder equipment installation Installation methodology defined and all required procedures and work method statements approved for use",LOOKAHEAD:"NA",YEARS:2023},
      // {NO:150,EM_LEAD:"Fuller",SEI_LEAD:"Ruan",FUNCTIONS:"Polymers",LMS_NO:"POL-10",LMS_DESCR:"Reaction Area Ready for Frame Installation - LDPE",PLAN:"2023-04-01",FORECAST:"2023-04-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Reaction Area Ready for Frame Installation - Ready for equipment install",LOOKAHEAD:"NA",YEARS:2023},
      // {NO:151,EM_LEAD:"Fuller",SEI_LEAD:"Ruan",FUNCTIONS:"Polymers",LMS_NO:"POL-11",LMS_DESCR:"Commence Installation of ASRS Equipment - PBL",PLAN:"2023-07-01",FORECAST:"2023-07-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Warehouse erection progressed to allow for the commencement of sustained installation of ASRS equipment ASRS equipment delivery and receipt supports sustained installation of equipment and vendor reps available on site",LOOKAHEAD:"NA",YEARS:2023},
      // {NO:152,EM_LEAD:"Gan",SEI_LEAD:"NA",FUNCTIONS:"Supply Chain",LMS_NO:"SUP-01A",LMS_DESCR:"Final Jetty Service Agreement with Huade",PLAN:"2022-01-01",FORECAST:"2022-01-01",ACTUAL:"2022-01-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Agreement Signed / Documented",LOOKAHEAD:"NA",YEARS:2022},
      // {NO:153,EM_LEAD:"Gan",SEI_LEAD:"NA",FUNCTIONS:"Supply Chain",LMS_NO:"SUP-02",LMS_DESCR:"3PL JV governance structure & stewardship established/operational",PLAN:"2022-04-01",FORECAST:"2022-04-01",ACTUAL:"2022-04-01",STATUS:"Completed",DEFINITION_OF_SUCCESS:"Structure and stewardship in place",LOOKAHEAD:"NA",YEARS:2022},
      // {NO:154,EM_LEAD:"S. Sun",SEI_LEAD:"NA",FUNCTIONS:"Supply Chain",LMS_NO:"SUP-03",LMS_DESCR:"Warehouse/ASRS test plan complete",PLAN:"2023-05-01",FORECAST:"2023-05-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented Plans",LOOKAHEAD:"NA",YEARS:2023},
      // {NO:155,EM_LEAD:"Gan",SEI_LEAD:"NA",FUNCTIONS:"Supply Chain",LMS_NO:"SUP-04",LMS_DESCR:"Offsite storage basis  Corp Plan and S/U Basis",PLAN:"2023-09-01",FORECAST:"2023-09-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented in Corp Plan",LOOKAHEAD:"NA",YEARS:2023},
      // {NO:156,EM_LEAD:"Gan",SEI_LEAD:"NA",FUNCTIONS:"Supply Chain",LMS_NO:"SUP-05",LMS_DESCR:"3P logistics agreements in place (3P logistics)",PLAN:"2023-10-01",FORECAST:"2023-10-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Final Agreement Signed / Documented",LOOKAHEAD:"NA",YEARS:2023},
      // {NO:157,EM_LEAD:"Gan",SEI_LEAD:"NA",FUNCTIONS:"Supply Chain",LMS_NO:"SUP-06",LMS_DESCR:"SC integration and SC readiness validated",PLAN:"2024-07-01",FORECAST:"2024-07-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Ready for all liquids / polymer / RM logistics activities",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:158,EM_LEAD:"Gan",SEI_LEAD:"NA",FUNCTIONS:"Supply Chain",LMS_NO:"SUP-07",LMS_DESCR:"Shipping Logistics Contracts/Process in place",PLAN:"2024-05-01",FORECAST:"2024-05-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:159,EM_LEAD:"A. Ai",SEI_LEAD:"NA",FUNCTIONS:"Supply Chain",LMS_NO:"SUP-08",LMS_DESCR:"First Naphtha ships discharged safely",PLAN:"2024-12-01",FORECAST:"2024-12-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented",LOOKAHEAD:"NA",YEARS:2024},
      // {NO:160,EM_LEAD:"J. Ng",SEI_LEAD:"NA",FUNCTIONS:"Supply Chain",LMS_NO:"SUP-10",LMS_DESCR:"Outbound Logistics E2E Testing Complete",PLAN:"2024-12-01",FORECAST:"2024-12-01",STATUS:"To Go",DEFINITION_OF_SUCCESS:"Documented",LOOKAHEAD:"NA",YEARS:2024}
      ]
    
}