
export default {
  Canvas: {
    CanvasWidth: 1600,
    CanvasHeight: 1200,
    CanvasBorderWeight: 0.2,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 760,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  Queries: {
    SQL1:  [],
  },
  
  DataItems: [
    { no: 1,   system: 'KMS-TBD1', type: 'KEY_MILESTONE', name: 'LDPE: Substation Energized', },
    { no: 2,   system: '600',      type: 'BUILDING',      name: 'Substation - 53SS06', },
    { no: 3,   system: '412-1',    type: 'UTILITY',       name: 'Firewater System', },
    { no: 4,   system: 'KMS-TBD2', type: 'KEY_MILESTONE', name: 'LDPE: RIE Commissioned', },
    { no: 5,   system: '601',      type: 'BUILDING',      name: 'RIE-3', },
    { no: 6,   system: '738',      type: 'UTILITY',       name: 'Distributed Contro1 System (DCS)', },
    { no: 7,   system: '739',      type: 'UTILITY',       name: 'SIS/BMS/HIPPS/TMC/IE', },
    { no: 8,   system: 'FUL-843',  type: 'INTERFACES',    name: 'WWTP from P3', },
    { no: 9,   system: 'KM-P2-38', type: 'KEY_MILESTONE', name: 'LDPE: CW & IA commissioned', },
    { no: 10,  system: 'TEMP2',    type: 'TEMP',          name: 'TEMP: Hoses / JO for IA service flush', },
    { no: 11,  system: '403',      type: 'UTILITY',       name: 'Plant Air System', },
    { no: 12,  system: 'TEMP1',    type: 'TEMP',          name: 'TEMP: Potable Water', },
    { no: 13,  system: '404',      type: 'UTILITY',       name: 'Instrument Air System', },
    { no: 14,  system: '405',      type: 'UTILITY',       name: 'Cooling Water System', },
    { no: 15,  system: '436',      type: 'UTILITY',       name: 'Storm Water Pond', },
    { no: 16,  system: 'J-845',    type: 'BUILDING',      name: 'Operator Shelter', },
    { no: 17,  system: 'KO-744',   type: 'KEY_MILESTONE', name: 'Steam Blows Completed', },
    { no: 18,  system: '522',      type: 'UTILITY',       name: 'HP BFW', },
    { no: 19,  system: '506',      type: 'UTILITY',       name: 'HP Steam ', },
    { no: 20,  system: '507',      type: 'UTILITY',       name: 'MP Steam ', },
    { no: 21,  system: '508',      type: 'UTILITY',       name: 'LP Steam', },
    { no: 22,  system: 'KM-P2-49', type: 'KEY_MILESTONE', name: 'LDPE: Extruder Motor Solo Run completed', },
    { no: 23,  system: '264-2',    type: 'PROCESS',       name: 'Hot Melt Extruder', },
    { no: 24,  system: 'KM-P2-44', type: 'KEY_MILESTONE', name: 'LDPE: Hydraulic Oil system in', },
    { no: 25,  system: '281',      type: 'PROCESS',       name: 'Hydraulic Oil System', },
    { no: 26,  system: 'TEMP281',  type: 'TEMP',          name: 'Chem. Cleaning', },
    { no: 27,  system: '424',      type: 'UTILITY',       name: 'Potable Water', },
    { no: 28,  system: '504',      type: 'UTILITY',       name: 'Safety Showers and Eyewash Stations', },
    { no: 29,  system: 'PEF-124',  type: 'KEY_MILESTONE', name: 'Pellet Water Circulation', },
    { no: 30,  system: '274',      type: 'PROCESS',       name: 'Pellet Water & Drying system', },
    { no: 31,  system: '188',      type: 'UTILITY',       name: 'Demin Water', },
    { no: 32,  system: 'KMS-TBD4', type: 'KEY_MILESTONE', name: 'Chilled water system ready', },
    { no: 33,  system: '283',      type: 'PROCESS',       name: 'Chilled Water ', },
    { no: 34,  system: '283-1',    type: 'PROCESS',       name: 'Process Chiller', },
    { no: 35,  system: '513',      type: 'UTILITY',       name: 'Demin Water', },
    { no: 36,  system: 'KMS-TBD3', type: 'KEY_MILESTONE', name: 'P/P Compressor Motor Solo', },
    { no: 37,  system: 'KMS-TBD5', type: 'KEY_MILESTONE', name: 'Primary Compr No-Load Run', },
    { no: 38,  system: 'TEMP3',    type: 'TEMP',          name: 'Chem. Cleaning and/or Pigging', },
    { no: 39,  system: '279',      type: 'PROCESS',       name: 'Cooling Oil / Frame Oil', },
    { no: 40,  system: '277',      type: 'PROCESS',       name: 'Hyper 1st stage Lube Oil', },
    { no: 41,  system: 'KM-P2-47', type: 'KEY_MILESTONE', name: 'Pellet Conveying Test to Pack.', },
    { no: 42,  system: '275',      type: 'PROCESS',       name: 'LDPE Pellet Conveying', },
    { no: 43,  system: '288',      type: 'INTERFACES',    name: 'HP N2 (from P3)', },
    { no: 44,  system: 'PD-271',   type: 'INTERFACES',    name: 'WWTP ', },
    { no: 45,  system: 'KMS-TBD6', type: 'KEY_MILESTONE', name: 'P/P Compr Run In', },
    { no: 46,  system: '407',      type: 'UTILITY',       name: 'LP N2 Import / Distr.', },
    { no: 47,  system: '349',      type: 'UTILITY',       name: 'LDPE HPPN', },
    { no: 48,  system: 'TEMP4',    type: 'TEMP',          name: 'N2 Blow Vent to Atm.', },
    { no: 49,  system: '256',      type: 'PROCESS',       name: 'Liquid waste', },
    { no: 50,  system: '280',      type: 'PROCESS',       name: 'Waste Oil storage', },
    { no: 51,  system: '254',      type: 'PROCESS',       name: 'Primary Compressor ', },
    { no: 52,  system: '255',      type: 'PROCESS',       name: 'Purge gas & Compressor', },
    { no: 53,  system: 'TEMP5',    type: 'TEMP',          name: 'Bypass for P/P Compr Coolers', },
    { no: 54,  system: 'OBW-276',  type: 'KEY_MILESTONE', name: 'Extruder Test Run', },
    { no: 55,  system: 'VKE-982',  type: 'TEMP',          name: 'Fill LPS with Test Pellets', },
    { no: 56,  system: 'PDJ-162',  type: 'TEMP',          name: 'Test with imported pellets', },
    { no: 57,  system: 'PTO-113',  type: 'TEMP',          name: 'Test mult. times (reuse pellets)', },
    { no: 58,  system: '264',      type: 'PROCESS',       name: 'Hot Melt Extruder', },
    { no: 59,  system: '506-1',    type: 'UTILITY',       name: 'HP Steam ', },
    { no: 60,  system: '262',      type: 'PROCESS',       name: 'Low pressure separator', },
    { no: 61,  system: 'V-187',    type: 'KEY_MILESTONE', name: 'HP Blow', },
    { no: 62,  system: 'TEMP6',    type: 'TEMP',          name: 'Facilities for HP N2 Blow', },
    { no: 63,  system: 'XZ-313',   type: 'TEMP',          name: 'Special Piping Comp. (from vendor)', },
    { no: 64,  system: 'B-933',    type: 'UTILITY',       name: 'LP Nitrogen', },
    { no: 65,  system: 'I-432',    type: 'UTILITY',       name: 'LP/MP/HP Steam', },
    { no: 66,  system: '258',      type: 'PROCESS',       name: 'HP separator and recycle', },
    { no: 67,  system: '257-1',    type: 'PROCESS',       name: 'Secondary Comp. Intercooler', },
    { no: 68,  system: 'BJ-487',   type: 'TEMP',          name: 'Temporary Valve (UHDE)', },
    { no: 69,  system: '259',      type: 'PROCESS',       name: 'Reactor Feed', },
    { no: 70,  system: 'EYA-913',  type: 'KEY_MILESTONE', name: 'Additives Comm.', },
    { no: 71,  system: '272',      type: 'PROCESS',       name: 'Slip', },
    { no: 72,  system: '271',      type: 'PROCESS',       name: 'PEG', },
    { no: 73,  system: '270',      type: 'PROCESS',       name: 'Antioxidant', },
    { no: 74,  system: '269',      type: 'PROCESS',       name: 'Antiblock for LDPE', },
    { no: 75,  system: '268',      type: 'PROCESS',       name: 'SAE bin', },
    { no: 76,  system: '267',      type: 'PROCESS',       name: 'Master Batch Bag unloaading station', },
    { no: 77,  system: '506-2',    type: 'UTILITY',       name: 'HP Steam ', },
    { no: 78,  system: '273',      type: 'PROCESS',       name: 'Molten additive injection', },
    { no: 79,  system: '263',      type: 'PROCESS',       name: 'Side Arm Extruder', },
    { no: 80,  system: 'KM-P2-42', type: 'KEY_MILESTONE', name: 'LDPE: 2nd Compr Run-in (W/Primary)', },
    { no: 81,  system: '257',      type: 'PROCESS',       name: 'Secondary compressor', },
    { no: 82,  system: '278',      type: 'PROCESS',       name: 'Hyper 2st stage Lube Oil', },
    { no: 83,  system: '260-1',    type: 'PROCESS',       name: 'Emergency Vent Separator (Rxtr)', },
    { no: 84,  system: '406-1',    type: 'UTILITY',       name: 'Industrial Water System', },
    { no: 85,  system: 'TEMP7',    type: 'TEMP',          name: 'Special piping components', },
    { no: 86,  system: 'BEU-865',  type: 'KEY_MILESTONE', name: 'Reactor HP Leak Test', },
    { no: 87,  system: '261',      type: 'PROCESS',       name: 'Wax Blowdown', },
    { no: 88,  system: '522-1',    type: 'UTILITY',       name: 'HP BFW', },
    { no: 89,  system: '285',      type: 'PROCESS',       name: 'Jacket water', },
    { no: 90,  system: 'KM-P2-41', type: 'KEY_MILESTONE', name: 'LDPE: Ready for Ethylene Intro', },
    { no: 91,  system: '250',      type: 'PROCESS',       name: 'Ethylene (Feed)', },
    { no: 92,  system: '435',      type: 'UTILITY',       name: 'LP Flare (Elevated Flare)', },
    { no: 93,  system: '412',      type: 'UTILITY',       name: 'Firewater System', },
    { no: 94,  system: '406',      type: 'UTILITY',       name: 'Industrial Water System', },
    { no: 95,  system: 'KMS-TBD7', type: 'KEY_MILESTONE', name: 'Rxtr C2=HP Leak Test', },
    { no: 96,  system: '403-1',    type: 'UTILITY',       name: 'Plant Air System', },
    { no: 97,  system: 'PG-089',   type: 'KEY_MILESTONE', name: 'Initiator System', },
    { no: 98,  system: '287',      type: 'PROCESS',       name: 'Initiator waste', },
    { no: 99,  system: '266',      type: 'PROCESS',       name: 'Initiator solvent (IsopharH)', },
    { no: 100, system: '266-1',    type: 'PROCESS',       name: 'Initiator solvent (IsopharH) - Pumps', },
    { no: 101, system: '282',      type: 'PROCESS',       name: 'Deep Chilled Glycol water', },
    { no: 102, system: '282-1',    type: 'PROCESS',       name: 'Initiator Chiller', },
    { no: 103, system: 'KM-P2-51', type: 'KEY_MILESTONE', name: 'LDPE: Start up and Stabilize', },
    { no: 104, system: '286',      type: 'PROCESS',       name: 'LDPE Vent to RTO (Including RTO)', },
    { no: 105, system: '428',      type: 'PROCESS',       name: 'Natural Gas Import', },
    { no: 106, system: '284',      type: 'PROCESS',       name: 'Purge Air ', },
    { no: 107, system: '253',      type: 'PROCESS',       name: 'Propionaldehyde ', },
    { no: 108, system: '252',      type: 'PROCESS',       name: 'Initiator (Peroxide)', },


  ],
}