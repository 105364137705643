import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 600,
      CanvasHeight: 85,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },
    DataItems:  [
      {ITEM:"Piling",UOM:"PCS",TOTAL:1124,COMPLETED:0,PLAN_WK:34,ACTUAL_WK:204,DELTA_WK:136,PLAN_CUM:951,ACTUAL_CUM:1124,DELTA_CUM:173,PROG:100}
      // {ITEM:"Piling",ITEM_SEQ:1,SITE:"Main Plant",PACKAGE:"P1",PACKAGE_SEQ:1,AREA:"Furnace and Olefinovery",AREA_SEQ:1,UOM:"fcgc sgds zdhg d ",CUTOFF_DAY:"Friday",TOTAL:2223387,CDATE:"2022-07-29",COMPLETED:5445644,PLAN_CUM:1444416,PLAN_WK:144556,ACTUAL_CUM:4555445,ACTUAL_WK:4444532,REMAIN:2942,DELTA_WK:-33455,DELTA_CUM:472779,PROG:50}
    ],
}