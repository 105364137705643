import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 600,
      CanvasHeight: 380,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 380,
      CanvasChartHeight: 280,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Weight : ', x:240, y: 220, font: 'roboto', class: 'regular', size: 11, color: '#BCBCBC', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: 350, y: 200, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'end' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: 350,
          y: 220,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'end'
        },
      ]
    },
    DataItems: [
      {CUTOFF:"W45",WK_NO:"W2020_45",FAT_PLAN_WK:0,FAT_PLAN_CUM:32,FAT_FORECAST_WK:0,FAT_FORECAST_CUM:32},
      {CUTOFF:"W46",WK_NO:"W2020_46",FAT_PLAN_WK:0,FAT_PLAN_CUM:32,FAT_FORECAST_WK:1,FAT_FORECAST_CUM:33},
      {CUTOFF:"W47",WK_NO:"W2020_47",FAT_PLAN_WK:2,FAT_PLAN_CUM:34,FAT_FORECAST_WK:4,FAT_FORECAST_CUM:37},
      {CUTOFF:"W48",WK_NO:"W2020_48",FAT_PLAN_WK:1,FAT_PLAN_CUM:35,FAT_FORECAST_WK:1,FAT_FORECAST_CUM:38},
      {CUTOFF:"W49",WK_NO:"W2020_49",FAT_PLAN_WK:1,FAT_PLAN_CUM:36,FAT_FORECAST_WK:1,FAT_FORECAST_CUM:39},
      {CUTOFF:"W50",WK_NO:"W2020_50",FAT_PLAN_WK:1,FAT_PLAN_CUM:37,FAT_FORECAST_WK:0,FAT_FORECAST_CUM:39},
      {CUTOFF:"W51",WK_NO:"W2020_51",FAT_PLAN_WK:1,FAT_PLAN_CUM:38,FAT_FORECAST_WK:1,FAT_FORECAST_CUM:40},
      {CUTOFF:"W52",WK_NO:"W2020_52",FAT_PLAN_WK:2,FAT_PLAN_CUM:40,FAT_FORECAST_WK:1,FAT_FORECAST_CUM:41},
      {CUTOFF:"W01",WK_NO:"W2021_01",FAT_PLAN_WK:0,FAT_PLAN_CUM:40,FAT_FORECAST_WK:1,FAT_FORECAST_CUM:42},
      {CUTOFF:"W02",WK_NO:"W2021_02",FAT_PLAN_WK:0,FAT_PLAN_CUM:40,FAT_FORECAST_WK:0,FAT_FORECAST_CUM:42},
      {CUTOFF:"W03",WK_NO:"W2021_03",FAT_PLAN_WK:0,FAT_PLAN_CUM:40,FAT_FORECAST_WK:0,FAT_FORECAST_CUM:42},
      {CUTOFF:"W04",WK_NO:"W2021_04",FAT_PLAN_WK:2,FAT_PLAN_CUM:42,FAT_FORECAST_WK:3,FAT_FORECAST_CUM:45},
      {CUTOFF:"W05",WK_NO:"W2021_05",FAT_PLAN_WK:0,FAT_PLAN_CUM:42,FAT_FORECAST_WK:0,FAT_FORECAST_CUM:45}
    ],
}