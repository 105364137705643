import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 800,
    CanvasHeight: 300,
    CanvasBorderWeight: 0.1,
    CanvasBorderColor: '#e0e0e0',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.2,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 380,
    CanvasChartHeight: 200,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  Text: {
    Text: [
      { text: '', x:20, y: 10, font: 'roboto', class: 'regular', size: 12, color: '#424242', align: 'start' },
    ],
    Filter: [
      { name: 'MOD'   , x: -50, y: 20, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'start' },
    ],
    Sql: [
      {
        query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
        text: 'Data Source',
        x: -30,
        y: 20,
        font: 'roboto',
        style: 'regular',
        size: 11,
        color: '#44A9DF',
        align: 'start'
      },
    ]
  },
  Queries: {
    SQL1: [
      {CUTOFF:'2022-06-17'}
    ],
  },
  DataItems: [

    {CUTOFF:'2022-06-17',WORK_TYPE_CODE:"PP",PLAN:19,ACTUAL:0},
    {CUTOFF:'2022-06-17',WORK_TYPE_CODE:"PPA",PLAN:36,ACTUAL:1},
    {CUTOFF:'2022-06-17',WORK_TYPE_CODE:"MR",PLAN:9,ACTUAL:6},
    {CUTOFF:'2022-06-17',WORK_TYPE_CODE:"RFQ",PLAN:4,ACTUAL:4},
    {CUTOFF:'2022-06-17',WORK_TYPE_CODE:"BC",PLAN:59,ACTUAL:17},
    {CUTOFF:'2022-06-17',WORK_TYPE_CODE:"TBE",PLAN:52,ACTUAL:18},
    {CUTOFF:'2022-06-17',WORK_TYPE_CODE:"CBE",PLAN:3,ACTUAL:31},
    {CUTOFF:'2022-06-17',WORK_TYPE_CODE:"AR",PLAN:12,ACTUAL:5},
    {CUTOFF:'2022-06-17',WORK_TYPE_CODE:"ARA",PLAN:12,ACTUAL:5},
    {CUTOFF:'2022-06-17',WORK_TYPE_CODE:"LOA",PLAN:7,ACTUAL:21},
    {CUTOFF:'2022-06-17',WORK_TYPE_CODE:"PO",PLAN:9,ACTUAL:0},
    {CUTOFF:'2022-06-17',WORK_TYPE_CODE:"MDI",PLAN:0,ACTUAL:0},
    {CUTOFF:'2022-06-17',WORK_TYPE_CODE:"MO",PLAN:1,ACTUAL:0},
    {CUTOFF:'2022-06-17',WORK_TYPE_CODE:"FI",PLAN:0,ACTUAL:0},
    {CUTOFF:'2022-06-17',WORK_TYPE_CODE:"DJS",PLAN:19,ACTUAL:0}
  ],
}