import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 483,
      CanvasHeight: 300,
      CanvasBorderWeight: 0.3,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 310,
      CanvasChartHeight: 270,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: 'Transparent',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },

    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },

    DataItems: [
      {KEY_MS:"ICSS Cabinets Delivery for ME05 ICSS Cabinets Delivery for ME05",PLAN:"01-Mar-21",FORECAST:"01-Mar-21", ACTUAL: "01-Mar-21"},
      {KEY_MS:"AFT Hull part pit pf dry dock",PLAN:"01-Mar-21",FORECAST:"01-Mar-21", ACTUAL: "01-Mar-21" },
      {KEY_MS:"Hull Lanching (back along quay side)",PLAN:"01-Apr-21",FORECAST:"01-Apr-21", ACTUAL: "01-Mar-21"},
      {KEY_MS:"LQ Lifting",PLAN:"02-Apr-21",FORECAST:"02-Apr-21"},
      {KEY_MS:"Piles installation start",PLAN:"01-May-21",FORECAST:"01-May-21"},
      {KEY_MS:"Piperacks lifted onboard",PLAN:"15-Jun-21",FORECAST:"15-Jun-21"},
      {KEY_MS:"ME05 Lifting into Hull",PLAN:"30-Jun-21",FORECAST:"30-Jun-21"},
      {KEY_MS:"Utilities Module lifting into hull (1st Batch)",PLAN:"30-Jun-21",FORECAST:"30-Jun-21"},
      {KEY_MS:"Hull Start Commissioning",PLAN:"16-Aug-21",FORECAST:"16-Aug-21"},
      {KEY_MS:"LQ Start Commissioning",PLAN:"28-Aug-21",FORECAST:"28-Aug-21"}
      ],
}