import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1800,
      CanvasHeight: 600,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#e0e0e0',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 380,
      CanvasChartHeight: 280,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },
    Queries: {},
    DataItems: [
      {CDATE:"2022-03-01",ACTUAL_CUM:33262,ACTUAL_DAILY:249,PLAN_CUM:41364},
      {CDATE:"2022-03-02",ACTUAL_CUM:33478,ACTUAL_DAILY:216,PLAN_CUM:41364},
      {CDATE:"2022-03-03",ACTUAL_CUM:33700,ACTUAL_DAILY:222,PLAN_CUM:41364},
      {CDATE:"2022-03-04",ACTUAL_CUM:34250,ACTUAL_DAILY:550,PLAN_CUM:41364},
      {CDATE:"2022-03-05",ACTUAL_CUM:34484,ACTUAL_DAILY:234,PLAN_CUM:41364},
      {CDATE:"2022-03-07",ACTUAL_CUM:34685,ACTUAL_DAILY:201,PLAN_CUM:41364},
      {CDATE:"2022-03-10",WK_NO:"W2022_10",ACTUAL_DAILY:201,PLAN_CUM:43850},
      {CDATE:"2022-03-17",WK_NO:"W2022_11",ACTUAL_DAILY:201,PLAN_CUM:46373},
      {CDATE:"2022-03-24",WK_NO:"W2022_12",ACTUAL_DAILY:201,PLAN_CUM:48919},
      {CDATE:"2022-03-31",WK_NO:"W2022_13",PLAN_CUM:51472},
      {CDATE:"2022-04-07",WK_NO:"W2022_14",PLAN_CUM:54042},
      {CDATE:"2022-04-14",WK_NO:"W2022_15",PLAN_CUM:56546},
      {CDATE:"2022-04-21",WK_NO:"W2022_16",PLAN_CUM:58978},
      {CDATE:"2022-04-28",WK_NO:"W2022_17",PLAN_CUM:61143},
      {CDATE:"2022-05-05",WK_NO:"W2022_18",PLAN_CUM:63190},
      {CDATE:"2022-05-12",WK_NO:"W2022_19",PLAN_CUM:65221},
      {CDATE:"2022-05-19",WK_NO:"W2022_20",PLAN_CUM:67241},
      {CDATE:"2022-05-26",WK_NO:"W2022_21",PLAN_CUM:69100},
      {CDATE:"2022-06-02",WK_NO:"W2022_22",PLAN_CUM:70908},
      {CDATE:"2022-06-09",WK_NO:"W2022_23",PLAN_CUM:72596},
      {CDATE:"2022-06-16",WK_NO:"W2022_24",PLAN_CUM:74114},
      {CDATE:"2022-06-23",WK_NO:"W2022_25",PLAN_CUM:75532},
      {CDATE:"2022-06-30",WK_NO:"W2022_26",PLAN_CUM:76771},
      {CDATE:"2022-07-07",WK_NO:"W2022_27",PLAN_CUM:78063},
      {CDATE:"2022-07-14",WK_NO:"W2022_28",PLAN_CUM:79166},
      {CDATE:"2022-07-21",WK_NO:"W2022_29",PLAN_CUM:80108}
    ],
}