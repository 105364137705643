import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 1720,
    CanvasHeight: 450,
    CanvasBorderWeight: 0.1,
    CanvasBorderColor: '#e0e0e0',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.2,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 380,
    CanvasChartHeight: 280,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  Text: {
    Text: [
      { text: '', x:20, y: 10, font: 'roboto', class: 'regular', size: 12, color: '#424242', align: 'start' },
    ],
    Filter: [
      { name: 'MOD'   , x: -50, y: 20, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'start' },
    ],
    Sql: [
      {
        query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
        text: 'Data Source',
        x: -30,
        y: 20,
        font: 'roboto',
        style: 'regular',
        size: 11,
        color: '#44A9DF',
        align: 'start'
      },
    ]
  },
  Queries: {
    SQL1: [
      {TOTAL:18567,COMPLETE:6091,REMAIN:12476,PROG:32.8}
    ],
    SQL2:  [
      {WK_NO:"W2022_11"}
    ],
  },
  DataItems: [
    {WK_NO:"W2022_01",PLAN_CUM:18552,ACTUAL_CUM:15617,PLAN_WK:0,ACTUAL_WK:61},
    {WK_NO:"W2022_02",PLAN_CUM:18514,ACTUAL_CUM:15544,PLAN_WK:38,ACTUAL_WK:73},
    {WK_NO:"W2022_03",PLAN_CUM:18462,ACTUAL_CUM:15483,PLAN_WK:52,ACTUAL_WK:61},
    {WK_NO:"W2022_04",PLAN_CUM:18442,ACTUAL_CUM:15025,PLAN_WK:20,ACTUAL_WK:458},
    {WK_NO:"W2022_05",PLAN_CUM:18410,ACTUAL_CUM:14949,PLAN_WK:32,ACTUAL_WK:76},
    {WK_NO:"W2022_06",PLAN_CUM:18409,ACTUAL_CUM:14851,PLAN_WK:1,ACTUAL_WK:98},
    {WK_NO:"W2022_07",PLAN_CUM:18134,ACTUAL_CUM:14513,PLAN_WK:275,ACTUAL_WK:338},
    {WK_NO:"W2022_08",PLAN_CUM:18047,ACTUAL_CUM:13988,PLAN_WK:87,ACTUAL_WK:525},
    {WK_NO:"W2022_09",PLAN_CUM:17307,ACTUAL_CUM:13525,PLAN_WK:740,ACTUAL_WK:463},
    {WK_NO:"W2022_10",PLAN_CUM:17044,ACTUAL_CUM:13001,PLAN_WK:263,ACTUAL_WK:524},
    {WK_NO:"W2022_11",PLAN_CUM:16004,ACTUAL_CUM:12476,PLAN_WK:1040,ACTUAL_WK:525,WKS_TREND:12476},
    {WK_NO:"W2022_12",PLAN_CUM:9785,PLAN_WK:6219,WKS_TREND:11966.75},
    {WK_NO:"W2022_13",PLAN_CUM:9231,PLAN_WK:554,WKS_TREND:11457.5},
    {WK_NO:"W2022_14",PLAN_CUM:8881,PLAN_WK:350,WKS_TREND:10948.25},
    {WK_NO:"W2022_15",PLAN_CUM:7485,PLAN_WK:1396,WKS_TREND:10439},
    {WK_NO:"W2022_16",PLAN_CUM:7010,PLAN_WK:475,WKS_TREND:9929.75},
    {WK_NO:"W2022_17",PLAN_CUM:5669,PLAN_WK:1341,WKS_TREND:9420.5},
    {WK_NO:"W2022_18",PLAN_CUM:4393,PLAN_WK:1276,WKS_TREND:8911.25},
    {WK_NO:"W2022_19",PLAN_CUM:3271,PLAN_WK:1122,WKS_TREND:8402},
    {WK_NO:"W2022_20",PLAN_CUM:2873,PLAN_WK:398,WKS_TREND:7892.75},
    {WK_NO:"W2022_21",PLAN_CUM:2079,PLAN_WK:794,WKS_TREND:7383.5},
    {WK_NO:"W2022_22",PLAN_CUM:1643,PLAN_WK:436,WKS_TREND:6874.25},
    {WK_NO:"W2022_23",PLAN_CUM:842,PLAN_WK:801,WKS_TREND:6365},
    {WK_NO:"W2022_24",PLAN_CUM:815,PLAN_WK:27,WKS_TREND:5855.75},
    {WK_NO:"W2022_25",PLAN_CUM:105,PLAN_WK:710,WKS_TREND:5346.5},
    {WK_NO:"W2022_26",PLAN_CUM:17,PLAN_WK:88,WKS_TREND:4837.25},
    {WK_NO:"W2022_27",PLAN_CUM:3,PLAN_WK:14,WKS_TREND:4328},
    {WK_NO:"W2022_28",PLAN_CUM:1,PLAN_WK:2,WKS_TREND:3818.75},
    {WK_NO:"W2022_29",PLAN_CUM:0,PLAN_WK:1,WKS_TREND:3309.5},
    {WK_NO:"W2022_30",PLAN_CUM:0,PLAN_WK:0,WKS_TREND:2800.25},
    {WK_NO:"W2022_31",PLAN_CUM:0,PLAN_WK:0,WKS_TREND:2291},
    {WK_NO:"W2022_32",PLAN_CUM:0,PLAN_WK:0,WKS_TREND:1781.75},
    {WK_NO:"W2022_33",PLAN_CUM:0,PLAN_WK:0,WKS_TREND:1272.5},
    {WK_NO:"W2022_34",PLAN_CUM:0,PLAN_WK:0,WKS_TREND:763.25},
    {WK_NO:"W2022_35",PLAN_CUM:0,PLAN_WK:0,WKS_TREND:254},
    {WK_NO:"W2022_36",PLAN_CUM:0,PLAN_WK:0,WKS_TREND:0}
  ],
}