
export default {
  Canvas: {
    CanvasWidth: 401,
    CanvasHeight: 333,
    CanvasBorderWeight: 1,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 300,
    CanvasChartHeight: 300,
    CanvasChartBorderWeight: 1,
    CanvasChartBorderColor: '#ddd',
    CanvasChartFillColor: '#fff',
    CanvasChartOpacity: 1,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },

  DataItems: [],
  Queries: {
    SQL1: [
      { value: 0 },
      { value: 0 },
      { value: 0 },
      { value: 1 },
      { value: 1 },
      { value: 1 },
    ],
    SQL2: [
      { value: 0 },
      { value: 14 },
      { value: 2 },
      { value: 9 },
      { value: 8 },
      { value: 29 },
    ],
    SQL3: [
      { value: 'SAFE ACTS' },
      { value: 'UNSAFE ACTS' },
      { value: 'CONDITIONS' },
    ],
    SQL4: [
      { value: 553 },
      { value: 512 },
      { value: 296 },
    ],
  }
}