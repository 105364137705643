
export default {
  Canvas: {
    CanvasWidth: 1600,
    CanvasHeight: 1120,
    CanvasBorderWeight: 0.2,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',//0E1B2B
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 760,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#fff',
    CanvasChartOpacity: 1,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },

  
  DataItems: [
    { PKG: 'P1',  AREA: '111300', SEI: '111300', TOTAL: 536,   ACTUAL: 226,    REMAIN: 310,    PRO: 42.2, },
    { PKG: 'P1',  AREA: '111300', SEI: '111342', TOTAL: 10,    ACTUAL: 6,      REMAIN: 4,      PRO: 60, },
    { PKG: 'P1',  AREA: '111300', SEI: '111394', TOTAL: 30,    ACTUAL: 21,     REMAIN: 9,      PRO: 70, },
    { PKG: 'P1',  AREA: '127700', SEI: '127700', TOTAL: 661,   ACTUAL: 360,    REMAIN: 301,    PRO: 54.5, },
    { PKG: 'P1',  AREA: '127700', SEI: '127742', TOTAL: 23,    ACTUAL: 15,     REMAIN: 8,      PRO: 65.2, },
    { PKG: 'P1',  AREA: '127700', SEI: '127791', TOTAL: 183,   ACTUAL: 61,     REMAIN: 122,    PRO: 33.3, },
    { PKG: 'P1',  AREA: '127700', SEI: '127792', TOTAL: 75,    ACTUAL: 49,     REMAIN: 26,     PRO: 65.3, },
    { PKG: 'P1',  AREA: '127800', SEI: '127800', TOTAL: 551,   ACTUAL: 251,    REMAIN: 300,    PRO: 45.6, },
    { PKG: 'P1',  AREA: '127900', SEI: '127900', TOTAL: 1898,  ACTUAL: 855,    REMAIN: 1043,   PRO: 45, },
    { PKG: 'P1',  AREA: '127900', SEI: '127901', TOTAL: 1420,  ACTUAL: 415,    REMAIN: 1005,   PRO: 29.2, },
    { PKG: 'P1',  AREA: '127900', SEI: '127902', TOTAL: 259,   ACTUAL: 132,    REMAIN: 127,    PRO: 51, },
    { PKG: 'P1',  AREA: '127900', SEI: '127903', TOTAL: 241,   ACTUAL: 131,    REMAIN: 110,    PRO: 54.4, },
    { PKG: 'P1',  AREA: '127900', SEI: '127904', TOTAL: 240,   ACTUAL: 127,    REMAIN: 113,    PRO: 52.9, },
    { PKG: 'P1',  AREA: '127900', SEI: '127905', TOTAL: 249,   ACTUAL: 110,    REMAIN: 139,    PRO: 44.2, },
    { PKG: 'P1',  AREA: '127900', SEI: '127906', TOTAL: 3,     ACTUAL: 2,      REMAIN: 1,      PRO: 66.7, },
    { PKG: 'P1',  AREA: '127900', SEI: '127907', TOTAL: 56,    ACTUAL: 21,     REMAIN: 35,     PRO: 37.5, },
    { PKG: 'P1',  AREA: '127900', SEI: '127908', TOTAL: 35,    ACTUAL: 11,     REMAIN: 24,     PRO: 31.4, },
    { PKG: 'P1',  AREA: '127900', SEI: '127909', TOTAL: 16,    ACTUAL: 3,      REMAIN: 13,     PRO: 18.8, },
    { PKG: 'P1',  AREA: '127900', SEI: '127942', TOTAL: 39,    ACTUAL: 25,     REMAIN: 14,     PRO: 64.1, },
    { PKG: 'P1',  AREA: '127900', SEI: '127982', TOTAL: 41,    ACTUAL: 33,     REMAIN: 8,      PRO: 80.5, },
    { PKG: 'P1',  AREA: '127900', SEI: '127991', TOTAL: 205,   ACTUAL: 89,     REMAIN: 116,    PRO: 43.4, },
    { PKG: 'P1',  AREA: '127900', SEI: '127992', TOTAL: 55,    ACTUAL: 47,     REMAIN: 8,      PRO: 85.5, },
    { PKG: 'P1',  AREA: '127900', SEI: '127993', TOTAL: 12,    ACTUAL: 9,      REMAIN: 3,      PRO: 75, },
    { PKG: 'P1',  AREA: '136200', SEI: '136200', TOTAL: 487,   ACTUAL: 236,    REMAIN: 251,    PRO: 48.5, },
    { PKG: 'P1',  AREA: '170000', SEI: '124700', TOTAL: 100,   ACTUAL: 64,     REMAIN: 36,     PRO: 64, },
    { PKG: 'P1',  AREA: '170000', SEI: '170000', TOTAL: 199,   ACTUAL: 140,    REMAIN: 59,     PRO: 70.4, },
    { PKG: 'P1',  AREA: '170000', SEI: '170100', TOTAL: 203,   ACTUAL: 72,     REMAIN: 131,    PRO: 35.5, },
    { PKG: 'P1',  AREA: '170000', SEI: '170300', TOTAL: 130,   ACTUAL: 64,     REMAIN: 66,     PRO: 49.2, },
    { PKG: 'P1',  AREA: '170000', SEI: '270100', TOTAL: 71,    ACTUAL: 41,     REMAIN: 30,     PRO: 57.7, },
    { PKG: 'P1',  AREA: '170000', SEI: '270300', TOTAL: 134,   ACTUAL: 103,    REMAIN: 31,     PRO: 76.9, },
    { PKG: 'P1',  AREA: '170000', SEI: '370100', TOTAL: 35,    ACTUAL: 10,     REMAIN: 25,     PRO: 28.6, },
    { PKG: 'P1',  AREA: '184300', SEI: '184300', TOTAL: 302,   ACTUAL: 135,    REMAIN: 167,    PRO: 44.7, },
    { PKG: 'P1',  AREA: '184300', SEI: '184302', TOTAL: 32,    ACTUAL: 21,     REMAIN: 11,     PRO: 65.6, },
    { PKG: 'P1',  AREA: '184300', SEI: '184303', TOTAL: 13,    ACTUAL: 11,     REMAIN: 2,      PRO: 84.6, },
    { PKG: 'P1',  AREA: '184800', SEI: '184800', TOTAL: 363,   ACTUAL: 166,    REMAIN: 197,    PRO: 45.7, },
    { PKG: 'P1',  AREA: '184800', SEI: '184891', TOTAL: 92,    ACTUAL: 45,     REMAIN: 47,     PRO: 48.9, },
    { PKG: 'P1',  AREA: '184800', SEI: '184892', TOTAL: 61,    ACTUAL: 45,     REMAIN: 16,     PRO: 73.8, },
    { PKG: 'P1',  AREA: '277000', SEI: '168000', TOTAL: 97,    ACTUAL: 65,     REMAIN: 32,     PRO: 67, },
    { PKG: 'P1',  AREA: '277000', SEI: '177100', TOTAL: 121,   ACTUAL: 88,     REMAIN: 33,     PRO: 72.7, },
    { PKG: 'P1',  AREA: '277000', SEI: '179600', TOTAL: 30,    ACTUAL: 9,      REMAIN: 21,     PRO: 30, },
    { PKG: 'P1',  AREA: '277000', SEI: '277000', TOTAL: 132,   ACTUAL: 87,     REMAIN: 45,     PRO: 65.9, },
    { PKG: 'P1',  AREA: '277100', SEI: '170800', TOTAL: 130,   ACTUAL: 88,     REMAIN: 42,     PRO: 67.7, },
    { PKG: 'P1',  AREA: '277100', SEI: '277100', TOTAL: 114,   ACTUAL: 78,     REMAIN: 36,     PRO: 68.4, },
    { PKG: 'P1',  AREA: '277100', SEI: '377100', TOTAL: 114,   ACTUAL: 75,     REMAIN: 39,     PRO: 65.8, },
    { PKG: 'P2',  AREA: '121300', SEI: '121300', TOTAL: 916,   ACTUAL: 364,    REMAIN: 552,    PRO: 39.7, },
    { PKG: 'P2',  AREA: '121300', SEI: '121301', TOTAL: 137,   ACTUAL: 80,     REMAIN: 57,     PRO: 58.4, },
    { PKG: 'P2',  AREA: '121300', SEI: '121302', TOTAL: 43,    ACTUAL: 26,     REMAIN: 17,     PRO: 60.5, },
    { PKG: 'P2',  AREA: '121300', SEI: '121303', TOTAL: 29,    ACTUAL: 13,     REMAIN: 16,     PRO: 44.8, },
    { PKG: 'P2',  AREA: '121300', SEI: '121304', TOTAL: 103,   ACTUAL: 47,     REMAIN: 56,     PRO: 45.6, },
    { PKG: 'P2',  AREA: '121300', SEI: '121305', TOTAL: 15,    ACTUAL: 5,      REMAIN: 10,     PRO: 33.3, },
    { PKG: 'P2',  AREA: '121300', SEI: '121306', TOTAL: 35,    ACTUAL: 8,      REMAIN: 27,     PRO: 22.9, },
    { PKG: 'P2',  AREA: '121300', SEI: '121307', TOTAL: 16,    ACTUAL: 7,      REMAIN: 9,      PRO: 43.8, },
    { PKG: 'P2',  AREA: '121300', SEI: '121308', TOTAL: 35,    ACTUAL: 13,     REMAIN: 22,     PRO: 37.1, },
    { PKG: 'P2',  AREA: '121300', SEI: '121311', TOTAL: 117,   ACTUAL: 90,     REMAIN: 27,     PRO: 76.9, },
    { PKG: 'P2',  AREA: '121300', SEI: '121312', TOTAL: 58,    ACTUAL: 35,     REMAIN: 23,     PRO: 60.3, },
    { PKG: 'P2',  AREA: '121300', SEI: '121313', TOTAL: 18,    ACTUAL: 7,      REMAIN: 11,     PRO: 38.9, },
    { PKG: 'P2',  AREA: '121300', SEI: '121321', TOTAL: 112,   ACTUAL: 85,     REMAIN: 27,     PRO: 75.9, },
    { PKG: 'P2',  AREA: '121300', SEI: '121322', TOTAL: 26,    ACTUAL: 7,      REMAIN: 19,     PRO: 26.9, },
    { PKG: 'P2',  AREA: '121300', SEI: '121323', TOTAL: 19,    ACTUAL: 6,      REMAIN: 13,     PRO: 31.6, },
    { PKG: 'P2',  AREA: '121300', SEI: '121332', TOTAL: 59,    ACTUAL: 38,     REMAIN: 21,     PRO: 64.4, },
    { PKG: 'P2',  AREA: '121300', SEI: '121342', TOTAL: 26,    ACTUAL: 13,     REMAIN: 13,     PRO: 50, },
    { PKG: 'P2',  AREA: '121300', SEI: '121391', TOTAL: 133,   ACTUAL: 78,     REMAIN: 55,     PRO: 58.6, },
    { PKG: 'P2',  AREA: '121300', SEI: '121392', TOTAL: 97,    ACTUAL: 66,     REMAIN: 31,     PRO: 68, },
    { PKG: 'P2',  AREA: '121300', SEI: '152804', TOTAL: 35,    ACTUAL: 35,     REMAIN: 0,      PRO: 100, },
    { PKG: 'P2',  AREA: '121300', SEI: '152805', TOTAL: 9,     ACTUAL: 9,      REMAIN: 0,      PRO: 100, },
    { PKG: 'P2',  AREA: '121400', SEI: '121400', TOTAL: 862,   ACTUAL: 668,    REMAIN: 194,    PRO: 77.5, },
    { PKG: 'P2',  AREA: '121400', SEI: '121401', TOTAL: 32,    ACTUAL: 24,     REMAIN: 8,      PRO: 75, },
    { PKG: 'P2',  AREA: '121400', SEI: '121402', TOTAL: 85,    ACTUAL: 77,     REMAIN: 8,      PRO: 90.6, },
    { PKG: 'P2',  AREA: '121400', SEI: '121403', TOTAL: 17,    ACTUAL: 11,     REMAIN: 6,      PRO: 64.7, },
    { PKG: 'P2',  AREA: '121400', SEI: '121404', TOTAL: 25,    ACTUAL: 16,     REMAIN: 9,      PRO: 64, },
    { PKG: 'P2',  AREA: '121400', SEI: '121405', TOTAL: 46,    ACTUAL: 33,     REMAIN: 13,     PRO: 71.7, },
    { PKG: 'P2',  AREA: '121400', SEI: '121406', TOTAL: 6,     ACTUAL: 4,      REMAIN: 2,      PRO: 66.7, },
    { PKG: 'P2',  AREA: '121400', SEI: '121407', TOTAL: 17,    ACTUAL: 12,     REMAIN: 5,      PRO: 70.6, },
    { PKG: 'P2',  AREA: '121400', SEI: '121408', TOTAL: 9,     ACTUAL: 7,      REMAIN: 2,      PRO: 77.8, },
    { PKG: 'P2',  AREA: '121400', SEI: '121409', TOTAL: 9,     ACTUAL: 7,      REMAIN: 2,      PRO: 77.8, },
    { PKG: 'P2',  AREA: '121400', SEI: '121410', TOTAL: 15,    ACTUAL: 9,      REMAIN: 6,      PRO: 60, },
    { PKG: 'P2',  AREA: '121400', SEI: '121411', TOTAL: 10,    ACTUAL: 7,      REMAIN: 3,      PRO: 70, },
    { PKG: 'P2',  AREA: '121400', SEI: '121412', TOTAL: 9,     ACTUAL: 7,      REMAIN: 2,      PRO: 77.8, },
    { PKG: 'P2',  AREA: '121400', SEI: '121413', TOTAL: 68,    ACTUAL: 53,     REMAIN: 15,     PRO: 77.9, },
    { PKG: 'P2',  AREA: '121400', SEI: '121414', TOTAL: 13,    ACTUAL: 0,      REMAIN: 13,     PRO: 0, },
    { PKG: 'P2',  AREA: '121400', SEI: '121415', TOTAL: 13,    ACTUAL: 9,      REMAIN: 4,      PRO: 69.2, },
    { PKG: 'P2',  AREA: '121400', SEI: '121442', TOTAL: 10,    ACTUAL: 10,     REMAIN: 0,      PRO: 100, },
    { PKG: 'P2',  AREA: '121400', SEI: '121491', TOTAL: 105,   ACTUAL: 65,     REMAIN: 40,     PRO: 61.9, },
    { PKG: 'P2',  AREA: '121400', SEI: '121492', TOTAL: 79,    ACTUAL: 58,     REMAIN: 21,     PRO: 73.4, },
    { PKG: 'P2',  AREA: '121400', SEI: '152803', TOTAL: 53,    ACTUAL: 53,     REMAIN: 0,      PRO: 100, },
    { PKG: 'P2',  AREA: '121900', SEI: '121900', TOTAL: 1413,  ACTUAL: 491,    REMAIN: 922,    PRO: 34.7, },
    { PKG: 'P2',  AREA: '121900', SEI: '121901', TOTAL: 26,    ACTUAL: 23,     REMAIN: 3,      PRO: 88.5, },
    { PKG: 'P2',  AREA: '121900', SEI: '121902', TOTAL: 8,     ACTUAL: 3,      REMAIN: 5,      PRO: 37.5, },
    { PKG: 'P2',  AREA: '121900', SEI: '121903', TOTAL: 88,    ACTUAL: 40,     REMAIN: 48,     PRO: 45.5, },
    { PKG: 'P2',  AREA: '121900', SEI: '121904', TOTAL: 52,    ACTUAL: 35,     REMAIN: 17,     PRO: 67.3, },
    { PKG: 'P2',  AREA: '121900', SEI: '121905', TOTAL: 32,    ACTUAL: 15,     REMAIN: 17,     PRO: 46.9, },
    { PKG: 'P2',  AREA: '121900', SEI: '121906', TOTAL: 8,     ACTUAL: 5,      REMAIN: 3,      PRO: 62.5, },
    { PKG: 'P2',  AREA: '121900', SEI: '121907', TOTAL: 4,     ACTUAL: 1,      REMAIN: 3,      PRO: 25, },
    { PKG: 'P2',  AREA: '121900', SEI: '121908', TOTAL: 6,     ACTUAL: 3,      REMAIN: 3,      PRO: 50, },
    { PKG: 'P2',  AREA: '121900', SEI: '121909', TOTAL: 8,     ACTUAL: 5,      REMAIN: 3,      PRO: 62.5, },
    { PKG: 'P2',  AREA: '121900', SEI: '121910', TOTAL: 34,    ACTUAL: 20,     REMAIN: 14,     PRO: 58.8, },
    { PKG: 'P2',  AREA: '121900', SEI: '121911', TOTAL: 4,     ACTUAL: 3,      REMAIN: 1,      PRO: 75, },
    { PKG: 'P2',  AREA: '121900', SEI: '121912', TOTAL: 6,     ACTUAL: 5,      REMAIN: 1,      PRO: 83.3, },
    { PKG: 'P2',  AREA: '121900', SEI: '121913', TOTAL: 9,     ACTUAL: 8,      REMAIN: 1,      PRO: 88.9, },
    { PKG: 'P2',  AREA: '121900', SEI: '121914', TOTAL: 12,    ACTUAL: 11,     REMAIN: 1,      PRO: 91.7, },
    { PKG: 'P2',  AREA: '121900', SEI: '121915', TOTAL: 12,    ACTUAL: 11,     REMAIN: 1,      PRO: 91.7, },
    { PKG: 'P2',  AREA: '121900', SEI: '121916', TOTAL: 5,     ACTUAL: 4,      REMAIN: 1,      PRO: 80, },
    { PKG: 'P2',  AREA: '121900', SEI: '121917', TOTAL: 8,     ACTUAL: 7,      REMAIN: 1,      PRO: 87.5, },
    { PKG: 'P2',  AREA: '121900', SEI: '121920', TOTAL: 27,    ACTUAL: 16,     REMAIN: 11,     PRO: 59.3, },
    { PKG: 'P2',  AREA: '121900', SEI: '121921', TOTAL: 4,     ACTUAL: 3,      REMAIN: 1,      PRO: 75, },
    { PKG: 'P2',  AREA: '121900', SEI: '121922', TOTAL: 5,     ACTUAL: 4,      REMAIN: 1,      PRO: 80, },
    { PKG: 'P2',  AREA: '121900', SEI: '121923', TOTAL: 7,     ACTUAL: 6,      REMAIN: 1,      PRO: 85.7, },
    { PKG: 'P2',  AREA: '121900', SEI: '121924', TOTAL: 14,    ACTUAL: 13,     REMAIN: 1,      PRO: 92.9, },
    { PKG: 'P2',  AREA: '121900', SEI: '121925', TOTAL: 14,    ACTUAL: 13,     REMAIN: 1,      PRO: 92.9, },
    { PKG: 'P2',  AREA: '121900', SEI: '121926', TOTAL: 4,     ACTUAL: 3,      REMAIN: 1,      PRO: 75, },
    { PKG: 'P2',  AREA: '121900', SEI: '121927', TOTAL: 8,     ACTUAL: 7,      REMAIN: 1,      PRO: 87.5, },
    { PKG: 'P2',  AREA: '121900', SEI: '121931', TOTAL: 7,     ACTUAL: 5,      REMAIN: 2,      PRO: 71.4, },
    { PKG: 'P2',  AREA: '121900', SEI: '121932', TOTAL: 35,    ACTUAL: 24,     REMAIN: 11,     PRO: 68.6, },
    { PKG: 'P2',  AREA: '121900', SEI: '121942', TOTAL: 22,    ACTUAL: 12,     REMAIN: 10,     PRO: 54.5, },
    { PKG: 'P2',  AREA: '121900', SEI: '121991', TOTAL: 129,   ACTUAL: 94,     REMAIN: 35,     PRO: 72.9, },
    { PKG: 'P2',  AREA: '121900', SEI: '121992', TOTAL: 72,    ACTUAL: 39,     REMAIN: 33,     PRO: 54.2, },
    { PKG: 'P2',  AREA: '152801', SEI: '152801', TOTAL: 37,    ACTUAL: 37,     REMAIN: 0,      PRO: 100, },
    { PKG: 'P2',  AREA: '152802', SEI: '152802', TOTAL: 55,    ACTUAL: 55,     REMAIN: 0,      PRO: 100, },
    { PKG: 'P3',  AREA: '151000', SEI: '151000', TOTAL: 13,    ACTUAL: 7,      REMAIN: 6,      PRO: 53.8, },
    { PKG: 'P3',  AREA: '151000', SEI: '152000', TOTAL: 465,   ACTUAL: 396,    REMAIN: 69,     PRO: 85.2, },
    { PKG: 'P3',  AREA: '151000', SEI: '154000', TOTAL: 90,    ACTUAL: 45,     REMAIN: 45,     PRO: 50, },
    { PKG: 'P3',  AREA: '151000', SEI: '183100', TOTAL: 70,    ACTUAL: 34,     REMAIN: 36,     PRO: 48.6, },
    { PKG: 'P3',  AREA: '151000', SEI: '186000', TOTAL: 153,   ACTUAL: 121,    REMAIN: 32,     PRO: 79.1, },
    { PKG: 'P3',  AREA: '151000', SEI: '381006', TOTAL: 46,    ACTUAL: 26,     REMAIN: 20,     PRO: 56.5, },
    { PKG: 'P3',  AREA: '152400', SEI: '152200', TOTAL: 115,   ACTUAL: 100,    REMAIN: 15,     PRO: 87, },
    { PKG: 'P3',  AREA: '152400', SEI: '152300', TOTAL: 63,    ACTUAL: 40,     REMAIN: 23,     PRO: 63.5, },
    { PKG: 'P3',  AREA: '152400', SEI: '152400', TOTAL: 1,     ACTUAL: 1,      REMAIN: 0,      PRO: 100, },
    { PKG: 'P3',  AREA: '152400', SEI: '152405', TOTAL: 289,   ACTUAL: 219,    REMAIN: 70,     PRO: 75.8, },
    { PKG: 'P3',  AREA: '152400', SEI: '152408', TOTAL: 2,     ACTUAL: 1,      REMAIN: 1,      PRO: 50, },
    { PKG: 'P3',  AREA: '152400', SEI: '152492', TOTAL: 2,     ACTUAL: 2,      REMAIN: 0,      PRO: 100, },
    { PKG: 'P3',  AREA: '152400', SEI: '181200', TOTAL: 153,   ACTUAL: 112,    REMAIN: 41,     PRO: 73.2, },
    { PKG: 'P3',  AREA: '152400', SEI: '182200', TOTAL: 43,    ACTUAL: 25,     REMAIN: 18,     PRO: 58.1, },
    { PKG: 'P3',  AREA: '152400', SEI: '252703', TOTAL: 1,     ACTUAL: 0,      REMAIN: 1,      PRO: 0, },
    { PKG: 'P3',  AREA: '152700', SEI: '152700', TOTAL: 10,    ACTUAL: 1,      REMAIN: 9,      PRO: 10, },
    { PKG: 'P3',  AREA: '178000', SEI: '178000', TOTAL: 109,   ACTUAL: 59,     REMAIN: 50,     PRO: 54.1, },
    { PKG: 'P3',  AREA: '178900', SEI: '166800', TOTAL: 60,    ACTUAL: 28,     REMAIN: 32,     PRO: 46.7, },
    { PKG: 'P3',  AREA: '178900', SEI: '178600', TOTAL: 226,   ACTUAL: 164,    REMAIN: 62,     PRO: 72.6, },
    { PKG: 'P3',  AREA: '178900', SEI: '178900', TOTAL: 254,   ACTUAL: 126,    REMAIN: 128,    PRO: 49.6, },
    { PKG: 'P3',  AREA: '179000', SEI: '179000', TOTAL: 650,   ACTUAL: 363,    REMAIN: 287,    PRO: 55.8, },
    { PKG: 'P3',  AREA: '179100', SEI: '179100', TOTAL: 129,   ACTUAL: 121,    REMAIN: 8,      PRO: 93.8, },
    { PKG: 'P3',  AREA: '180200', SEI: '180200', TOTAL: 509,   ACTUAL: 407,    REMAIN: 102,    PRO: 80, },
    { PKG: 'P3',  AREA: '180292', SEI: '180292', TOTAL: 1,     ACTUAL: 1,      REMAIN: 0,      PRO: 100, },
    { PKG: 'P3',  AREA: '180600', SEI: '180600', TOTAL: 118,   ACTUAL: 77,     REMAIN: 41,     PRO: 65.3, },
    { PKG: 'P3',  AREA: '181000', SEI: '181000', TOTAL: 144,   ACTUAL: 100,    REMAIN: 44,     PRO: 69.4, },
    { PKG: 'P3',  AREA: '182400', SEI: '182400', TOTAL: 207,   ACTUAL: 142,    REMAIN: 65,     PRO: 68.6, },
    { PKG: 'P3',  AREA: '182400', SEI: '182492', TOTAL: 1,     ACTUAL: 0,      REMAIN: 1,      PRO: 0, },
    { PKG: 'P3',  AREA: '183000', SEI: '183000', TOTAL: 885,   ACTUAL: 544,    REMAIN: 341,    PRO: 61.5, },
    { PKG: 'P3',  AREA: '185300', SEI: '185300', TOTAL: 335,   ACTUAL: 230,    REMAIN: 105,    PRO: 68.7, },
    { PKG: 'P3',  AREA: '185300', SEI: '185394', TOTAL: 1,     ACTUAL: 1,      REMAIN: 0,      PRO: 100, },
    { PKG: 'P3',  AREA: '280200', SEI: '280200', TOTAL: 401,   ACTUAL: 318,    REMAIN: 83,     PRO: 79.3, },
    { PKG: 'P3',  AREA: '280200', SEI: '280294', TOTAL: 1,     ACTUAL: 1,      REMAIN: 0,      PRO: 100, },
  ],


  Queries: {
    SQL1:  [
      { PKG: 'P1',    CODE: 'P1-A', DISC: 'A-Architectural',                                TOTAL: 83,    ACTUAL: 58,     REMAIN: 25,     PRO: 69.88, },
      { PKG: 'P1',    CODE: 'P1-B', DISC: 'B-Business and General',                         TOTAL: 11,    ACTUAL: 8,      REMAIN: 3,      PRO: 72.73, },
      { PKG: 'P1',    CODE: 'P1-C', DISC: 'C-Civil and Infrastructure',                     TOTAL: 43,    ACTUAL: 0,      REMAIN: 43,     PRO: 0, },
      { PKG: 'P1',    CODE: 'P1-D', DISC: 'D-Mechanical and Static Equipment',              TOTAL: 1095,  ACTUAL: 240,    REMAIN: 855,    PRO: 21.92, },
      { PKG: 'P1',    CODE: 'P1-E', DISC: 'E-Electrical',                                   TOTAL: 223,   ACTUAL: 147,    REMAIN: 76,     PRO: 65.92, },
      { PKG: 'P1',    CODE: 'P1-F', DISC: 'F-Risk and Loss Prevention',                     TOTAL: 142,   ACTUAL: 88,     REMAIN: 54,     PRO: 61.97, },
      { PKG: 'P1',    CODE: 'P1-H', DISC: 'H-HVAC',                                         TOTAL: 37,    ACTUAL: 24,     REMAIN: 13,     PRO: 64.86, },
      { PKG: 'P1',    CODE: 'P1-I', DISC: 'I-Instrumentation and Metering',                 TOTAL: 599,   ACTUAL: 180,    REMAIN: 419,    PRO: 30.05, },
      { PKG: 'P1',    CODE: 'P1-K', DISC: 'K-Construction, Transportation, and Logistics',  TOTAL: 50,    ACTUAL: 0,      REMAIN: 50,     PRO: 0, },
      { PKG: 'P1',    CODE: 'P1-L', DISC: 'L-Piping and General Layout',                    TOTAL: 2261,  ACTUAL: 1093,   REMAIN: 1168,   PRO: 48.34, },
      { PKG: 'P1',    CODE: 'P1-M', DISC: 'M-Rotating Machinery',                           TOTAL: 89,    ACTUAL: 36,     REMAIN: 53,     PRO: 40.45, },
      { PKG: 'P1',    CODE: 'P1-N', DISC: 'N-Structural (includes Weight Control)',         TOTAL: 1477,  ACTUAL: 1262,   REMAIN: 215,    PRO: 85.44, },
      { PKG: 'P1',    CODE: 'P1-P', DISC: 'P-Process',                                      TOTAL: 1204,  ACTUAL: 167,    REMAIN: 1037,   PRO: 13.87, },
      { PKG: 'P1',    CODE: 'P1-Q', DISC: 'Q-Quality',                                      TOTAL: 125,   ACTUAL: 1,      REMAIN: 124,    PRO: 0.8, },
      { PKG: 'P1',    CODE: 'P1-S', DISC: 'S-Safety, Health, and Security',                 TOTAL: 31,    ACTUAL: 0,      REMAIN: 31,     PRO: 0, },
      { PKG: 'P1',    CODE: 'P1-T', DISC: 'T-Telecommunications',                           TOTAL: 20,    ACTUAL: 0,      REMAIN: 20,     PRO: 0, },
      { PKG: 'P1',    CODE: 'P1-V', DISC: 'V-Contracting and Procurement',                  TOTAL: 3,     ACTUAL: 2,      REMAIN: 1,      PRO: 66.67, },
      { PKG: 'P1',    CODE: 'P1-X', DISC: 'X-Materials, Corrosion, and Flow Assurance',     TOTAL: 15,    ACTUAL: 12,     REMAIN: 3,      PRO: 80, },

      { PKG: 'P2',    CODE: 'P2-A', DISC: 'A-Architectural',                                TOTAL: 163,   ACTUAL: 152,    REMAIN: 11,     PRO: 93.25, },
      { PKG: 'P2',    CODE: 'P2-B', DISC: 'B-Business and General',                         TOTAL: 20,    ACTUAL: 8,      REMAIN: 12,     PRO: 40, },
      { PKG: 'P2',    CODE: 'P2-C', DISC: 'C-Civil and Infrastructure',                     TOTAL: 79,    ACTUAL: 1,      REMAIN: 78,     PRO: 1.27, },
      { PKG: 'P2',    CODE: 'P2-D', DISC: 'D-Mechanical and Static Equipment',              TOTAL: 225,   ACTUAL: 122,    REMAIN: 103,    PRO: 54.22, },
      { PKG: 'P2',    CODE: 'P2-E', DISC: 'E-Electrical',                                   TOTAL: 402,   ACTUAL: 229,    REMAIN: 173,    PRO: 56.97, },
      { PKG: 'P2',    CODE: 'P2-F', DISC: 'F-Risk and Loss Prevention',                     TOTAL: 238,   ACTUAL: 41,     REMAIN: 197,    PRO: 17.23, },
      { PKG: 'P2',    CODE: 'P2-H', DISC: 'H-HVAC',                                         TOTAL: 67,    ACTUAL: 61,     REMAIN: 6,      PRO: 91.04, },
      { PKG: 'P2',    CODE: 'P2-I', DISC: 'I-Instrumentation and Metering',                 TOTAL: 268,   ACTUAL: 165,    REMAIN: 103,    PRO: 61.57, },
      { PKG: 'P2',    CODE: 'P2-K', DISC: 'K-Construction, Transportation, and Logistics',  TOTAL: 53,    ACTUAL: 0,      REMAIN: 53,     PRO: 0, },
      { PKG: 'P2',    CODE: 'P2-L', DISC: 'L-Piping and General Layout',                    TOTAL: 1518,  ACTUAL: 989,    REMAIN: 529,    PRO: 65.15, },
      { PKG: 'P2',    CODE: 'P2-M', DISC: 'M-Rotating Machinery',                           TOTAL: 105,   ACTUAL: 45,     REMAIN: 60,     PRO: 42.86, },
      { PKG: 'P2',    CODE: 'P2-N', DISC: 'N-Structural (includes Weight Control)',         TOTAL: 1149,  ACTUAL: 1129,   REMAIN: 20,     PRO: 98.26, },
      { PKG: 'P2',    CODE: 'P2-P', DISC: 'P-Process',                                      TOTAL: 1297,  ACTUAL: 254,    REMAIN: 1043,   PRO: 19.58, },
      { PKG: 'P2',    CODE: 'P2-Q', DISC: 'Q-Quality',                                      TOTAL: 249,   ACTUAL: 0,      REMAIN: 249,    PRO: 0, },
      { PKG: 'P2',    CODE: 'P2-S', DISC: 'S-Safety, Health, and Security',                 TOTAL: 55,    ACTUAL: 0,      REMAIN: 55,     PRO: 0, },
      { PKG: 'P2',    CODE: 'P2-T', DISC: 'T-Telecommunications',                           TOTAL: 61,    ACTUAL: 0,      REMAIN: 61,     PRO: 0, },
      { PKG: 'P2',    CODE: 'P2-V', DISC: 'V-Contracting and Procurement',                  TOTAL: 1,     ACTUAL: 1,      REMAIN: 0,      PRO: 100, },
      { PKG: 'P2',    CODE: 'P2-X', DISC: 'X-Materials, Corrosion, and Flow Assurance',     TOTAL: 47,    ACTUAL: 44,     REMAIN: 3,      PRO: 93.62, },

      { PKG: 'P3',    CODE: 'P3-A', DISC: 'A-Architectural',                                TOTAL: 387,   ACTUAL: 340,    REMAIN: 47,     PRO: 87.86, },
      { PKG: 'P3',    CODE: 'P3-B', DISC: 'B-Business and General',                         TOTAL: 282,   ACTUAL: 12,     REMAIN: 270,    PRO: 4.26, },
      { PKG: 'P3',    CODE: 'P3-C', DISC: 'C-Civil and Infrastructure',                     TOTAL: 176,   ACTUAL: 0,      REMAIN: 176,    PRO: 0, },
      { PKG: 'P3',    CODE: 'P3-D', DISC: 'D-Mechanical and Static Equipment',              TOTAL: 550,   ACTUAL: 414,    REMAIN: 136,    PRO: 75.27, },
      { PKG: 'P3',    CODE: 'P3-E', DISC: 'E-Electrical',                                   TOTAL: 1276,  ACTUAL: 558,    REMAIN: 718,    PRO: 43.73, },
      { PKG: 'P3',    CODE: 'P3-F', DISC: 'F-Risk and Loss Prevention',                     TOTAL: 576,   ACTUAL: 355,    REMAIN: 221,    PRO: 61.63, },
      { PKG: 'P3',    CODE: 'P3-H', DISC: 'H-HVAC',                                         TOTAL: 216,   ACTUAL: 141,    REMAIN: 75,     PRO: 65.28, },
      { PKG: 'P3',    CODE: 'P3-I', DISC: 'I-Instrumentation and Metering',                 TOTAL: 1077,  ACTUAL: 329,    REMAIN: 748,    PRO: 30.55, },
      { PKG: 'P3',    CODE: 'P3-K', DISC: 'K-Construction, Transportation, and Logistics',  TOTAL: 304,   ACTUAL: 0,      REMAIN: 304,    PRO: 0, },
      { PKG: 'P3',    CODE: 'P3-L', DISC: 'L-Piping and General Layout',                    TOTAL: 2210,  ACTUAL: 1755,   REMAIN: 455,    PRO: 79.41, },
      { PKG: 'P3',    CODE: 'P3-M', DISC: 'M-Rotating Machinery',                           TOTAL: 185,   ACTUAL: 145,    REMAIN: 40,     PRO: 78.38, },
      { PKG: 'P3',    CODE: 'P3-N', DISC: 'N-Structural (includes Weight Control)',         TOTAL: 1955,  ACTUAL: 1575,   REMAIN: 380,    PRO: 80.56, },
      { PKG: 'P3',    CODE: 'P3-P', DISC: 'P-Process',                                      TOTAL: 739,   ACTUAL: 410,    REMAIN: 329,    PRO: 55.48, },
      { PKG: 'P3',    CODE: 'P3-Q', DISC: 'Q-Quality',                                      TOTAL: 424,   ACTUAL: 0,      REMAIN: 424,    PRO: 0, },
      { PKG: 'P3',    CODE: 'P3-R', DISC: 'R-Regulatory, Environmental, and Socioeconomic', TOTAL: 6,     ACTUAL: 0,      REMAIN: 6,      PRO: 0, },
      { PKG: 'P3',    CODE: 'P3-S', DISC: 'S-Safety, Health, and Security',                 TOTAL: 88,    ACTUAL: 0,      REMAIN: 88,     PRO: 0, },
      { PKG: 'P3',    CODE: 'P3-T', DISC: 'T-Telecommunications',                           TOTAL: 347,   ACTUAL: 188,    REMAIN: 159,    PRO: 54.18, },
      { PKG: 'P3',    CODE: 'P3-V', DISC: 'V-Contracting and Procurement',                  TOTAL: 88,    ACTUAL: 22,     REMAIN: 66,     PRO: 25, },
      { PKG: 'P3',    CODE: 'P3-X', DISC: 'X-Materials, Corrosion, and Flow Assurance',     TOTAL: 1,     ACTUAL: 1,      REMAIN: 0,      PRO: 100, },
      { PKG: 'P3',    CODE: 'P3-Y', DISC: 'Y-Pipelines, Umbilicals, Risers, and Flowlines', TOTAL: 1,     ACTUAL: 0,      REMAIN: 1,      PRO: 0, },

      { PKG: 'TOTAL', CODE: 'TOT-A', DISC: 'A-Architectural',                                TOTAL: 633,   ACTUAL: 550,    REMAIN: 83,     PRO: 86.89, },
      { PKG: 'TOTAL', CODE: 'TOT-B', DISC: 'B-Business and General',                         TOTAL: 467,   ACTUAL: 42,     REMAIN: 425,    PRO: 8.99, },
      { PKG: 'TOTAL', CODE: 'TOT-C', DISC: 'C-Civil and Infrastructure',                     TOTAL: 317,   ACTUAL: 1,      REMAIN: 316,    PRO: 0.32, },
      { PKG: 'TOTAL', CODE: 'TOT-D', DISC: 'D-Mechanical and Static Equipment',              TOTAL: 1871,  ACTUAL: 777,    REMAIN: 1094,   PRO: 41.53, },
      { PKG: 'TOTAL', CODE: 'TOT-E', DISC: 'E-Electrical',                                   TOTAL: 1993,  ACTUAL: 993,    REMAIN: 1000,   PRO: 49.82, },
      { PKG: 'TOTAL', CODE: 'TOT-F', DISC: 'F-Risk and Loss Prevention',                     TOTAL: 973,   ACTUAL: 496,    REMAIN: 477,    PRO: 50.98, },
      { PKG: 'TOTAL', CODE: 'TOT-H', DISC: 'H-HVAC',                                         TOTAL: 327,   ACTUAL: 231,    REMAIN: 96,     PRO: 70.64, },
      { PKG: 'TOTAL', CODE: 'TOT-I', DISC: 'I-Instrumentation and Metering',                 TOTAL: 1994,  ACTUAL: 711,    REMAIN: 1283,   PRO: 35.66, },
      { PKG: 'TOTAL', CODE: 'TOT-K', DISC: 'K-Construction, Transportation, and Logistics',  TOTAL: 480,   ACTUAL: 0,      REMAIN: 480,    PRO: 0, },
      { PKG: 'TOTAL', CODE: 'TOT-L', DISC: 'L-Piping and General Layout',                    TOTAL: 6048,  ACTUAL: 3847,   REMAIN: 2201,   PRO: 63.61, },
      { PKG: 'TOTAL', CODE: 'TOT-M', DISC: 'M-Rotating Machinery',                           TOTAL: 437,   ACTUAL: 254,    REMAIN: 183,    PRO: 58.12, },
      { PKG: 'TOTAL', CODE: 'TOT-N', DISC: 'N-Structural (includes Weight Control)',         TOTAL: 4598,  ACTUAL: 3979,   REMAIN: 619,    PRO: 86.54, },
      { PKG: 'TOTAL', CODE: 'TOT-P', DISC: 'P-Process',                                      TOTAL: 3270,  ACTUAL: 845,    REMAIN: 2425,   PRO: 25.84, },
      { PKG: 'TOTAL', CODE: 'TOT-Q', DISC: 'Q-Quality',                                      TOTAL: 886,   ACTUAL: 6,      REMAIN: 880,    PRO: 0.68, },
      { PKG: 'TOTAL', CODE: 'TOT-R', DISC: 'R-Regulatory, Environmental, and Socioeconomic', TOTAL: 9,     ACTUAL: 0,      REMAIN: 9,      PRO: 0, },
      { PKG: 'TOTAL', CODE: 'TOT-S', DISC: 'S-Safety, Health, and Security',                 TOTAL: 360,   ACTUAL: 1,      REMAIN: 359,    PRO: 0.28, },
      { PKG: 'TOTAL', CODE: 'TOT-T', DISC: 'T-Telecommunications',                           TOTAL: 429,   ACTUAL: 188,    REMAIN: 241,    PRO: 43.82, },
      { PKG: 'TOTAL', CODE: 'TOT-V', DISC: 'V-Contracting and Procurement',                  TOTAL: 624,   ACTUAL: 299,    REMAIN: 325,    PRO: 47.92, },
      { PKG: 'TOTAL', CODE: 'TOT-X', DISC: 'X-Materials, Corrosion, and Flow Assurance',     TOTAL: 137,   ACTUAL: 112,    REMAIN: 25,     PRO: 81.75, },
      { PKG: 'TOTAL', CODE: 'TOT-Y', DISC: 'Y-Pipelines, Umbilicals, Risers, and Flowlines', TOTAL: 1,     ACTUAL: 0,      REMAIN: 1,      PRO: 0, },

      { PKG: 'TOTAL', CODE: 'TOT-Z', DISC: 'Y-Pipelines, Umbilicals, Risers, and Flowlines', TOTAL: 1,     ACTUAL: 0,      REMAIN: 1,      PRO: 1, },
    ],

    SQL2:  [
      {
        WIDTH: 325,
        
        LEV1_X: 30,
        LEV1_Y: 360,
        LEV1_TITLE: 'TITLE 1',

        LEV2_TITLE: 'TITLE 2',
        LEV2_X_P1: 300,   LEV2_Y_P1: 350,
        LEV2_X_P2: 20,    LEV2_Y_P2: 370,
        LEV2_X_P3: 1080,  LEV2_Y_P3: 70,
        
        LEV3_TITLE: 'TITLE 3',
      }
    ],
  },

  Text: {
    Text: [
      { text: 'Note', x: 1350, y: 20, font: 'roboto', class: 'regular', size: 11, color: '#44A9DF', align: 'start' },
      
    ],
    Filter: [
      { name: 'MOD', x: 1350, y: 30, font: 'roboto', class: 'regular', size: 11, color: '#757575', align: 'start' },
    ],
    Sql: [
      {
        query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
        text: 'Data Source',
        x: 41,
        y: 115,
        font: 'roboto',
        style: 'regular',
        size: 10,
        color: '#757575',
        align: 'start'
      }, 
    ]
  },
}