export default {
  Canvas: {
    CanvasWidth: 1600,
    CanvasHeight: 1120,
    CanvasBorderWeight: 0.2,
    CanvasBorderColor: "Transparent",
    CanvasFillColor: "#fff", //0E1B2B
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 760,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: "#757575",
    CanvasChartFillColor: "#757575",
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: "roboto",
  },

  Note: {
    NoteDisplay: "N",
    NoteX: 633,
    NoteY: 358,
    NoteAlign: "start",
    NoteTitleText: "Notes.",
    NoteTitleFont: "roboto",
    NoteTitleStyle: "regular",
    NoteTitleSize: 11,
    NoteTitleColor: "#757575",

    NoteContentsText: "Note Contents Here!",
    NoteContentsFont: "roboto",
    NoteContentsStyle: "regular",
    NoteContentsSize: 10,
    NoteContentsColor: "#BDBCBC",
  },

  Text: {
    Text: [],
    Filter: [],
    Sql: [],
  },

  Queries: {
    SQL1: [],
  },

  DataItems: [{
    seiCodeL1:[
      { NO: 1, CODE: "111300", PACKAGE: "P1", PNO: 1, NAME: "BTX - Benzene Toluene Unit", CNAME: "芳烃抽提装置", SEICODE_L1: "111300", DESCRIPTION: "111300 BTX - Benzene Toluene Unit" },
      { NO: 2, CODE: "127700", PACKAGE: "P1", PNO: 2, NAME: "BD - Butadiene Unit", CNAME: "丁二烯抽提装置", SEICODE_L1: "127700", DESCRIPTION: "127700 BD - Butadiene Unit" },
      { NO: 3, CODE: "127800", PACKAGE: "P1", PNO: 3, NAME: "GHU - Gasoline Hydrotreating Unit", CNAME: "裂解汽油加氢装置", SEICODE_L1: "127800", DESCRIPTION: "127800 GHU - Gasoline Hydrotreating Unit" },
      { NO: 4, CODE: "127900", PACKAGE: "P1", PNO: 4, NAME: "OR - Olefins Unit", CNAME: "乙烯装置", SEICODE_L1: "127900", DESCRIPTION: "127900 OR - Olefins Unit" },
      { NO: 5, CODE: "136200", PACKAGE: "P1", PNO: 5, NAME: "DIB - DI-Isobutylene Unit", CNAME: "装置", SEICODE_L1: "136200", DESCRIPTION: "136200 DIB - DI-Isobutylene Unit" },
      { NO: 6, CODE: "170000", PACKAGE: "P1", PNO: 6, NAME: "Pressurized Tank Farm Common Area", CNAME: "压力罐区街区", SEICODE_L1: "170000", DESCRIPTION: "170000 Pressurized Tank Farm Common Area" },
      { NO: 7, CODE: "170092", PACKAGE: "P1", PNO: 7, NAME: "Tank Farm 1st Substation", CNAME: "罐区第一变电所", SEICODE_L1: "170092", DESCRIPTION: "170092 Tank Farm 1st Substation" },
      { NO: 8, CODE: "184300", PACKAGE: "P1", PNO: 8, NAME: "Demineralized Water Unit", CNAME: "脱盐水站", SEICODE_L1: "184300", DESCRIPTION: "184300 Demineralized Water Unit" },
      { NO: 9, CODE: "184800", PACKAGE: "P1", PNO: 9, NAME: "Boiler Unit", CNAME: "锅炉装置", SEICODE_L1: "184800", DESCRIPTION: "184800 Boiler Unit" },
      { NO: 10, CODE: "277000", PACKAGE: "P1", PNO: 10, NAME: "Atmospheric Tank Farm Common Area", CNAME: "常压罐区街区", SEICODE_L1: "277000", DESCRIPTION: "277000 Atmospheric Tank Farm Common Area" },
      { NO: 11, CODE: "277100", PACKAGE: "P1", PNO: 11, NAME: "Intermediate Tank Farm 2", CNAME: "中间罐区2", SEICODE_L1: "277100", DESCRIPTION: "277100 Intermediate Tank Farm 2" },
      { NO: 12, CODE: "121300", PACKAGE: "P2", PNO: 1, NAME: "GPPE - Gas Phase Polyethylene Unit ", CNAME: "线性低密度聚乙烯装置", SEICODE_L1: "121300", DESCRIPTION: "121300 GPPE - Gas Phase Polyethylene Unit " },
      { NO: 13, CODE: "121400", PACKAGE: "P2", PNO: 2, NAME: "LDPE - Low Density Polyethylene Unit", CNAME: "高压低密度聚乙烯装置", SEICODE_L1: "121400", DESCRIPTION: "121400 LDPE - Low Density Polyethylene Unit" },
      { NO: 14, CODE: "121900", PACKAGE: "P2", PNO: 3, NAME: "PP - Polypropylene Unit", CNAME: "聚丙烯装置", SEICODE_L1: "121900", DESCRIPTION: "121900 PP - Polypropylene Unit" },
      { NO: 15, CODE: "152801", PACKAGE: "P2", PNO: 4, NAME: "No.1 Warehouse", CNAME: "1#立体仓库", SEICODE_L1: "152801", DESCRIPTION: "152801 No.1 Warehouse" },
      { NO: 16, CODE: "152802", PACKAGE: "P2", PNO: 5, NAME: "No.2 Warehouse", CNAME: "2#立体仓库", SEICODE_L1: "152802", DESCRIPTION: "152802 No.2 Warehouse" },
      { NO: 17, CODE: "151000", PACKAGE: "P3", PNO: 1, NAME: "Common Area of Management Facilities(B+)", CNAME: "厂前区街区", SEICODE_L1: "151000", DESCRIPTION: "151000 Common Area of Management Facilities(B+)" },
      { NO: 18, CODE: "152400", PACKAGE: "P3", PNO: 2, NAME: "Chemical Warehouses", CNAME: "化学品仓库", SEICODE_L1: "152400", DESCRIPTION: "152400 Chemical Warehouses" },
      { NO: 19, CODE: "152700", PACKAGE: "P3", PNO: 3, NAME: "Main Plant Dispatch Area", CNAME: "停车场", SEICODE_L1: "152700", DESCRIPTION: "152700 Main Plant Dispatch Area" },
      { NO: 20, CODE: "178000", PACKAGE: "P3", PNO: 4, NAME: "Loading/Unloading Station", CNAME: "汽车装卸设施", SEICODE_L1: "178000", DESCRIPTION: "178000 Loading/Unloading Station" },
      { NO: 21, CODE: "178900", PACKAGE: "P3", PNO: 5, NAME: "Ground Flare", CNAME: "地面火炬", SEICODE_L1: "178900", DESCRIPTION: "178900 Ground Flare" },
      { NO: 22, CODE: "180200", PACKAGE: "P3", PNO: 6, NAME: "Cooling Water System 1", CNAME: "第一循环水场", SEICODE_L1: "180200", DESCRIPTION: "180200 Cooling Water System 1" },
      { NO: 23, CODE: "180292", PACKAGE: "P3", PNO: 7, NAME: "Substation", CNAME: "变电所", SEICODE_L1: "180292", DESCRIPTION: "180292 Substation" },
      { NO: 24, CODE: "180600", PACKAGE: "P3", PNO: 8, NAME: "Storm Water Pump Station and Accident Buffer Basin", CNAME: "雨水泵站及事故水池", SEICODE_L1: "180600", DESCRIPTION: "180600 Storm Water Pump Station and Accident Buffer Basin" },
      { NO: 25, CODE: "181000", PACKAGE: "P3", PNO: 9, NAME: "Water and Firewater Pump Station 1", CNAME: "第一给水及消防泵站", SEICODE_L1: "181000", DESCRIPTION: "181000 Water and Firewater Pump Station 1" },
      { NO: 26, CODE: "182400", PACKAGE: "P3", PNO: 10, NAME: "Wastewater Pre-Treatment Unit", CNAME: "污水预处理场", SEICODE_L1: "182400", DESCRIPTION: "182400 Wastewater Pre-Treatment Unit" },
      { NO: 27, CODE: "183000", PACKAGE: "P3", PNO: 11, NAME: "Main Substation", CNAME: "总变电站", SEICODE_L1: "183000", DESCRIPTION: "183000 Main Substation" },
      { NO: 28, CODE: "185300", PACKAGE: "P3", PNO: 12, NAME: "Air Compression Station", CNAME: "空压站", SEICODE_L1: "185300", DESCRIPTION: "185300 Air Compression Station" },
      { NO: 29, CODE: "280200", PACKAGE: "P3", PNO: 13, NAME: "Cooling Water System 2", CNAME: "第二循环水场", SEICODE_L1: "280200", DESCRIPTION: "280200 Cooling Water System 2" },
      { NO: 30, CODE: "281000", PACKAGE: "P3", PNO: 14, NAME: "Water and Firewater Pump Station 2", CNAME: "第二给水及消防泵站", SEICODE_L1: "281000", DESCRIPTION: "281000 Water and Firewater Pump Station 2" },
      { NO: 31, CODE: "179001", PACKAGE: "P3", PNO: 15, NAME: "Pipe Galley #1", CNAME: "管道舱 #1", SEICODE_L1: "179001", DESCRIPTION: "179001 Pipe Galley #1" },
      { NO: 32, CODE: "179002", PACKAGE: "P3", PNO: 16, NAME: "Pipe Galley #2", CNAME: "管道舱 #2", SEICODE_L1: "179002", DESCRIPTION: "179002 Pipe Galley #2" },
      { NO: 33, CODE: "179003", PACKAGE: "P3", PNO: 17, NAME: "Pipe Galley #3", CNAME: "管道舱 #3", SEICODE_L1: "179003", DESCRIPTION: "179003 Pipe Galley #3" },
      { NO: 34, CODE: "179004", PACKAGE: "P3", PNO: 18, NAME: "Pipe Galley #4", CNAME: "管道舱 #4", SEICODE_L1: "179004", DESCRIPTION: "179004 Pipe Galley #4" },
      { NO: 35, CODE: "179105", PACKAGE: "P3", PNO: 19, NAME: "Pipe Galley #5", CNAME: "管道舱 #5", SEICODE_L1: "179105", DESCRIPTION: "179105 Pipe Galley #5" },
      { NO: 36, CODE: "179106", PACKAGE: "P3", PNO: 20, NAME: "Pipe Galley #6", CNAME: "管道舱 #6", SEICODE_L1: "179106", DESCRIPTION: "179106 Pipe Galley #6" },
      { NO: 37, CODE: "179107", PACKAGE: "P3", PNO: 21, NAME: "Pipe Galley #7", CNAME: "管道舱 #7", SEICODE_L1: "179107", DESCRIPTION: "179107 Pipe Galley #7" },
    ],
    conCode:[
      { SUB_CON: "SNEI", ENG: "Sinopec Nanjing Engineering Co. Ltd", CHN: "中石化南京工程有限公司" },
      { SUB_CON: "FCC", ENG: "Sinopec Fourth Construction Company", CHN: "中石化第四建设有限公司" },
      { SUB_CON: "SFCC", ENG: "Sinopec Fifth Construction Company", CHN: "中石化第五建设有限公司" },
      { SUB_CON: "HZDJ", ENG: "Huizhou Dong Jiang", CHN: "惠州市东江建筑安装工程有限公司" },
      { SUB_CON: "STCC", ENG: "Sinopec Tenth Construction Company", CHN: "中石化第十建设有限公司" },
      { SUB_CON: "GDHJ", ENG: "Guangdong Chemical Construction", CHN: "广东化建" },
      { SUB_CON: "ZGLY", ENG: "China Sixth Metallurgical Corporation", CHN: "中国六冶" },
      { SUB_CON: "CNCEC", ENG: "China National Chemical Engineering Co. Ltd. 13th Company", CHN: "十三化建" },
      { SUB_CON: "SYPEC", ENG: "Shandong ShengYue Petrochemical Engineering Construction Co. Ltd.", CHN: "山东胜越" },
      { SUB_CON: "HNEDI", ENG: "Hainan Electric", CHN: "中国电建集团海南电力设计研究院有限公司" },
      { SUB_CON: "CC7", ENG: "China Chemical Construction No. 7 Company", CHN: "七化建" },
      { SUB_CON: "WDECE", ENG: "Jiangsu Weida Construction Group Co. Ltd.", CHN: "威达建设" },
      { SUB_CON: "SPC", ENG: "Shanxi Petroleum", CHN: "陕西化建" },
      { SUB_CON: "BJHX", ENG: "Beijign Huaxia Supervision Co.", CHN: "北京华夏石化工程监理有限公司" },
      { SUB_CON: "Gosun", ENG: "Guangdong Gosun Engineering Management Co.", CHN: "广东国信工程监理集团有限公司" },
      { SUB_CON: "QYECC", ENG: "Qingdao Yueyang Engineering consultant Co.", CHN: "青岛越洋工程咨询有限公司" },
      { SUB_CON: "TJU", ENG: "Shanghai TJU Engineering Service Co.", CHN: "上海协同工程咨询有限公司" },
      { SUB_CON: "YPES", ENG: "Nanjing Yangzi Petrochemical Engineering Surveillance Co.", CHN: "南京扬子石化工程监理有限责任公司" },
      { SUB_CON: "JSS", ENG: "Shanghai Jinshen Engineering Project Management Co.", CHN: "上海金申工程建设监理有限公司" },
      { SUB_CON: "TJCD", ENG: "Tianjin Chenda Engineering Supervision Co.", CHN: "天津辰达工程监理有限公司" },
    ],
    areaInfo:[
      { DIR: "R", SN: 1, AREA: "111300", SEI: "111342", CC: "SNEI", JIANLI: "BJHX", SEI_ENG: "Sanitary Sewage Sump", SEI_CHI: "生活废水池" },
      { DIR: "R", SN: 2, AREA: "111300", SEI: "111394", CC: "SNEI", JIANLI: "BJHX", SEI_ENG: "Co-product Operator Shelter", SEI_CHI: "联合装置外操间" },
  
      { DIR: "R", SN: 1, AREA: "121300", SEI: "121301", CC: "FCC", JIANLI: "Gosun", SEI_ENG: "GPPE Interconnecting Pipe Rack", SEI_CHI: "GPPE街区管廊" },
      { DIR: "R", SN: 2, AREA: "121300", SEI: "121302", CC: "FCC", JIANLI: "Gosun", SEI_ENG: "GPPE Feed And Utility Area  A", SEI_CHI: "GPPE 进料和共用工程A区" },
      { DIR: "L", SN: 3, AREA: "121300", SEI: "121303", CC: "FCC", JIANLI: "Gosun", SEI_ENG: "GPPE Feed And Utility Area B", SEI_CHI: "GPPE 进料和共用工程B区" },
      { DIR: "R", SN: 4, AREA: "121300", SEI: "121304", CC: "FCC", JIANLI: "Gosun", SEI_ENG: "GPPE Extrusion Building", SEI_CHI: "GPPE 挤压造粒厂房" },
      { DIR: "L", SN: 5, AREA: "121300", SEI: "121305", CC: "FCC", JIANLI: "Gosun", SEI_ENG: "GPPE Thermal Oxidizer Blower Area", SEI_CHI: "GPPE 热氧化物风机单元" },
      { DIR: "L", SN: 6, AREA: "121300", SEI: "121306", CC: "FCC", JIANLI: "Gosun", SEI_ENG: "GPPE Pellet Dryers Classifiers And Surge Bin Area", SEI_CHI: "GPPE 粒料干燥及出料单元" },
      { DIR: "R", SN: 7, AREA: "121300", SEI: "121307", CC: "FCC", JIANLI: "Gosun", SEI_ENG: "GPPE Pellet Transfer Compressor Area", SEI_CHI: "GPPE 粒料输送风机单元" },
      { DIR: "L", SN: 8, AREA: "121300", SEI: "121308", CC: "FCC", JIANLI: "Gosun", SEI_ENG: "GPPE Elutriator And Pellet Silos Area", SEI_CHI: "GPPE 淘析和成品包装" },
      { DIR: "R", SN: 9, AREA: "121300", SEI: "121311", CC: "FCC", JIANLI: "Gosun", SEI_ENG: "GPPE Reaction And Purge Bin Area Of Line 1", SEI_CHI: "GPPE 1反应脱气单元" },
      { DIR: "R", SN: 10, AREA: "121300", SEI: "121312", CC: "FCC", JIANLI: "Gosun", SEI_ENG: "GPPE Vent Gas Recovery And Propylene Refrigeration Area Of Line 1", SEI_CHI: "GPPE 1线排放气回收及丙烯制冷单元" },
      { DIR: "R", SN: 11, AREA: "121300", SEI: "121313", CC: "FCC", JIANLI: "Gosun", SEI_ENG: "GPPE Granules Conveying System Of Line 1", SEI_CHI: "GPPE 1线粉料输送风机单元" },
      { DIR: "R", SN: 12, AREA: "121300", SEI: "121321", CC: "FCC", JIANLI: "Gosun", SEI_ENG: "GPPE Reaction And Purge Bin Area Of Line 2", SEI_CHI: "GPPE 2线反应脱气单元" },
      { DIR: "R", SN: 13, AREA: "121300", SEI: "121322", CC: "FCC", JIANLI: "Gosun", SEI_ENG: "GPPE Vent Gas Recovery And Propylene Refrigeration Area Of Line 2", SEI_CHI: "GPPE 2线排放气回收及丙烯制冷单元" },
      { DIR: "R", SN: 14, AREA: "121300", SEI: "121323", CC: "FCC", JIANLI: "Gosun", SEI_ENG: "GPPE Granules Conveying System Of Line 2", SEI_CHI: "GPPE 2线粉料输送风机单元" },
      { DIR: "L", SN: 15, AREA: "121300", SEI: "121332", CC: "FCC", JIANLI: "Gosun", SEI_ENG: "GPPE CHEMICAL STORAGE", SEI_CHI: "GPPE化学品库 " },
      { DIR: "R", SN: 16, AREA: "121300", SEI: "121342", CC: "FCC", JIANLI: "Gosun", SEI_ENG: "GPPE Waste Water/Storm Water Area", SEI_CHI: "GPPE 废水池" },
      { DIR: "L", SN: 17, AREA: "121300", SEI: "121391", CC: "FCC", JIANLI: "Gosun", SEI_ENG: "GPPE RIE 5", SEI_CHI: "GPPE 现场机柜室" },
      { DIR: "R", SN: 18, AREA: "121300", SEI: "121392", CC: "FCC", JIANLI: "Gosun", SEI_ENG: "GPPE Substation", SEI_CHI: "GPPE变电所" },
      { DIR: "L", SN: 19, AREA: "121300", SEI: "152804", CC: "FCC", JIANLI: "QYECC", SEI_ENG: "No.2 Package Plant", SEI_CHI: "2#成品包装" },
      { DIR: "L", SN: 20, AREA: "121300", SEI: "152805", CC: "FCC", JIANLI: "QYECC", SEI_ENG: "Auxiliary Facility", SEI_CHI: "辅助生产设施" },
      { DIR: "L", SN: 21, AREA: "121300", SEI: "252702", CC: "FCC", JIANLI: "QYECC", SEI_ENG: "Truck Scale2", SEI_CHI: "汽车衡12" },
  
      { DIR: "R", SN: 1, AREA: "121400", SEI: "121401", CC: "SNEI", JIANLI: "Gosun", SEI_ENG: "Compressor House", SEI_CHI: "压缩机厂房" },
      { DIR: "R", SN: 2, AREA: "121400", SEI: "121402", CC: "SNEI", JIANLI: "Gosun", SEI_ENG: "Reaction Area", SEI_CHI: "反应区" },
      { DIR: "R", SN: 3, AREA: "121400", SEI: "121403", CC: "SNEI", JIANLI: "Gosun", SEI_ENG: "Hp Recycle Area", SEI_CHI: "高压循环区" },
      { DIR: "R", SN: 4, AREA: "121400", SEI: "121404", CC: "SNEI", JIANLI: "Gosun", SEI_ENG: "Purge Gas Area", SEI_CHI: "排放气框架区" },
      { DIR: "R", SN: 5, AREA: "121400", SEI: "121405", CC: "SNEI", JIANLI: "Gosun", SEI_ENG: "Extrusion Area", SEI_CHI: "挤压造粒区" },
      { DIR: "L", SN: 6, AREA: "121400", SEI: "121406", CC: "SNEI", JIANLI: "Gosun", SEI_ENG: "Compressor Lubrication Cooling Oil", SEI_CHI: "压缩机油站区" },
      { DIR: "L", SN: 7, AREA: "121400", SEI: "121407", CC: "SNEI", JIANLI: "Gosun", SEI_ENG: "Initiator Mixing", SEI_CHI: "引发剂配置区" },
      { DIR: "R", SN: 8, AREA: "121400", SEI: "121408", CC: "SNEI", JIANLI: "Gosun", SEI_ENG: "Chilled Water", SEI_CHI: "冷冻水" },
      { DIR: "R", SN: 9, AREA: "121400", SEI: "121409", CC: "SNEI", JIANLI: "Gosun", SEI_ENG: "Flare Knock-Out", SEI_CHI: "火炬罐" },
      { DIR: "R", SN: 10, AREA: "121400", SEI: "121410", CC: "SNEI", JIANLI: "Gosun", SEI_ENG: "Utility Water Area", SEI_CHI: "公用工程区" },
      { DIR: "L", SN: 11, AREA: "121400", SEI: "121411", CC: "SNEI", JIANLI: "Gosun", SEI_ENG: "Modifier and Solvent System", SEI_CHI: "RTO炉" },
      { DIR: "L", SN: 12, AREA: "121400", SEI: "121412", CC: "SNEI", JIANLI: "Gosun", SEI_ENG: "Vent Gas Treatment Package ", SEI_CHI: "" },
      { DIR: "L", SN: 13, AREA: "121400", SEI: "121413", CC: "SNEI", JIANLI: "Gosun", SEI_ENG: "Pellet Degassing Silos", SEI_CHI: "脱气仓" },
      { DIR: "R", SN: 14, AREA: "121400", SEI: "121414", CC: "SNEI", JIANLI: "Gosun", SEI_ENG: "Blend And Bagging Silos", SEI_CHI: "掺混包装料仓" },
      { DIR: "R", SN: 15, AREA: "121400", SEI: "121415", CC: "SNEI", JIANLI: "Gosun", SEI_ENG: "At Line-LAB", SEI_CHI: "在线分析室" },
      { DIR: "R", SN: 16, AREA: "121400", SEI: "121442", CC: "SNEI", JIANLI: "Gosun", SEI_ENG: "Sump Area", SEI_CHI: "废水池" },
      { DIR: "R", SN: 17, AREA: "121400", SEI: "121491", CC: "SNEI", JIANLI: "Gosun", SEI_ENG: "LDPE RIE 3", SEI_CHI: "现场机柜室" },
      { DIR: "L", SN: 18, AREA: "121400", SEI: "121492", CC: "SNEI", JIANLI: "Gosun", SEI_ENG: "LDPE Substation", SEI_CHI: "LDPE变电所" },
      { DIR: "L", SN: 19, AREA: "121400", SEI: "152406", CC: "HZDJ", JIANLI: "TJU", SEI_ENG: "Chemical Warehouses 6", SEI_CHI: "危险化学品库6" },
      { DIR: "L", SN: 20, AREA: "121400", SEI: "152803", CC: "SFCC", JIANLI: "QYECC", SEI_ENG: "No.1 Package Plant", SEI_CHI: "1#成品包装" },
      { DIR: "L", SN: 21, AREA: "121400", SEI: "252701", CC: "SFCC", JIANLI: "QYECC", SEI_ENG: "Truck Scale1", SEI_CHI: "汽车衡1" },
  
      { DIR: "L", SN: 1, AREA: "121900", SEI: "121901", CC: "SFCC", JIANLI: "QYECC", SEI_ENG: "PP Battery limit pipe rack", SEI_CHI: "聚丙烯装置街区管廊" },
      { DIR: "R", SN: 2, AREA: "121900", SEI: "121902", CC: "SFCC", JIANLI: "QYECC", SEI_ENG: "PP Teal facility area", SEI_CHI: "PP烷基铝配制" },
      { DIR: "L", SN: 3, AREA: "121900", SEI: "121903", CC: "SFCC", JIANLI: "QYECC", SEI_ENG: "PP Granules transfer and extrusion building", SEI_CHI: "PP粉料输送和挤压造粒厂房" },
      { DIR: "L", SN: 4, AREA: "121900", SEI: "121904", CC: "SFCC", JIANLI: "QYECC", SEI_ENG: "PP Pellet silos area", SEI_CHI: "PP颗粒料仓" },
      { DIR: "L", SN: 5, AREA: "121900", SEI: "121905", CC: "SFCC", JIANLI: "QYECC", SEI_ENG: "PP Pellet elutriation and bagging area", SEI_CHI: "PP淘析和成品包装" },
      { DIR: "R", SN: 6, AREA: "121900", SEI: "121906", CC: "SFCC", JIANLI: "QYECC", SEI_ENG: "PP Hydrogen compressor unit", SEI_CHI: "PP氢气压缩机单元" },
      { DIR: "R", SN: 7, AREA: "121900", SEI: "121907", CC: "SFCC", JIANLI: "QYECC", SEI_ENG: "PP Oil/Grease storage and mixing, propylene dryer area", SEI_CHI: "PP油/脂储存和混合,及丙烯干燥单元" },
      { DIR: "R", SN: 8, AREA: "121900", SEI: "121908", CC: "SFCC", JIANLI: "QYECC", SEI_ENG: "PP Oil treatment, chilled water and steam condensate drum area", SEI_CHI: "PP油处理,冷冻水和蒸汽凝液单元" },
      { DIR: "L", SN: 9, AREA: "121900", SEI: "121909", CC: "SFCC", JIANLI: "QYECC", SEI_ENG: "PP Regenerative thermal oxidizer area", SEI_CHI: "PP RTO单元" },
      { DIR: "R", SN: 10, AREA: "121900", SEI: "121910", CC: "SFCC", JIANLI: "QYECC", SEI_ENG: "CPP-1 Polymerization area", SEI_CHI: "PP聚合区1线" },
      { DIR: "L", SN: 11, AREA: "121900", SEI: "121911", CC: "SFCC", JIANLI: "QYECC", SEI_ENG: "CPP-1 Propylene feed and steam condensate drum area", SEI_CHI: "PP聚合区1线丙烯进料和蒸汽凝液罐单元" },
      { DIR: "L", SN: 12, AREA: "121900", SEI: "121912", CC: "SFCC", JIANLI: "QYECC", SEI_ENG: "CPP-1 Catalyst preparation and metering area", SEI_CHI: "PP聚合区1线催化剂制备单元" },
      { DIR: "L", SN: 13, AREA: "121900", SEI: "121913", CC: "SFCC", JIANLI: "QYECC", SEI_ENG: "CPP-1 BULK POLYMERIZATION AREA", SEI_CHI: "PP聚合区1线反应单元" },
      { DIR: "L", SN: 14, AREA: "121900", SEI: "121914", CC: "SFCC", JIANLI: "QYECC", SEI_ENG: "CPP-1 Gas phase reactors, polymer degassing and granules drying area", SEI_CHI: "PP聚合区1线聚合干燥单元" },
      { DIR: "R", SN: 15, AREA: "121900", SEI: "121915", CC: "SFCC", JIANLI: "QYECC", SEI_ENG: "CPP-1 Polymerization area pipe rack", SEI_CHI: "PP聚合区1线管廊" },
      { DIR: "L", SN: 16, AREA: "121900", SEI: "121916", CC: "SFCC", JIANLI: "QYECC", SEI_ENG: "CPP-1 Teal scrubber and recycle gas compressor area", SEI_CHI: "PP聚合区1线TEAL洗涤和循环气压缩机单元" },
      { DIR: "L", SN: 17, AREA: "121900", SEI: "121917", CC: "SFCC", JIANLI: "QYECC", SEI_ENG: "CPP-1 Blowdown area", SEI_CHI: "PP聚合区1线火炬单元" },
      { DIR: "R", SN: 18, AREA: "121900", SEI: "121920", CC: "SFCC", JIANLI: "QYECC", SEI_ENG: "CPP-2 Polymerization area", SEI_CHI: "PP聚合区2线" },
      { DIR: "R", SN: 19, AREA: "121900", SEI: "121921", CC: "SFCC", JIANLI: "QYECC", SEI_ENG: "CPP-2 Propylene feed area", SEI_CHI: "PP聚合区2线丙烯进料单元" },
      { DIR: "R", SN: 20, AREA: "121900", SEI: "121922", CC: "SFCC", JIANLI: "QYECC", SEI_ENG: "CPP-2 Catalyst preparation and metering area", SEI_CHI: "PP聚合区2线催化剂制备单元" },
      { DIR: "R", SN: 21, AREA: "121900", SEI: "121923", CC: "SFCC", JIANLI: "QYECC", SEI_ENG: "CPP-2 Bulk polymerization area", SEI_CHI: "PP聚合区2线反应单元" },
      { DIR: "L", SN: 22, AREA: "121900", SEI: "121924", CC: "SFCC", JIANLI: "QYECC", SEI_ENG: "CPP-2 Polymer degassing and granules drying area", SEI_CHI: "PP聚合区2线汽蒸干燥单元" },
      { DIR: "R", SN: 23, AREA: "121900", SEI: "121925", CC: "SFCC", JIANLI: "QYECC", SEI_ENG: "CPP-2 polymerization area pipe rack", SEI_CHI: "PP聚合区2线管廊" },
      { DIR: "R", SN: 24, AREA: "121900", SEI: "121926", CC: "SFCC", JIANLI: "QYECC", SEI_ENG: "CPP-2 Teal scrubber and recycle gas comperssor area", SEI_CHI: "PP聚合区2线TEAL洗涤和循环气压缩机单元" },
      { DIR: "R", SN: 25, AREA: "121900", SEI: "121927", CC: "SFCC", JIANLI: "QYECC", SEI_ENG: "CPP-2 Blowdown and ethylene compressor area", SEI_CHI: "PP聚合区2线火炬和乙烯压缩机单元" },
      { DIR: "R", SN: 26, AREA: "121900", SEI: "121931", CC: "SFCC", JIANLI: "QYECC", SEI_ENG: "PP New lube/Waste oil drum storagE", SEI_CHI: "PP新鲜油/废油储存间" },
      { DIR: "L", SN: 27, AREA: "121900", SEI: "121932", CC: "SFCC", JIANLI: "QYECC", SEI_ENG: "PP Chemical storage", SEI_CHI: "化学品间" },
      { DIR: "L", SN: 28, AREA: "121900", SEI: "121942", CC: "SFCC", JIANLI: "QYECC", SEI_ENG: "PP Waste water storm water area", SEI_CHI: "PP废水池" },
      { DIR: "R", SN: 29, AREA: "121900", SEI: "121991", CC: "SFCC", JIANLI: "QYECC", SEI_ENG: "PP RIE 4", SEI_CHI: "PP 现场机柜室" },
      { DIR: "R", SN: 30, AREA: "121900", SEI: "121992", CC: "SFCC", JIANLI: "QYECC", SEI_ENG: "PP Substation", SEI_CHI: "PP 变电所" },
      { DIR: "L", SN: 31, AREA: "121900", SEI: "152803", CC: "SFCC", JIANLI: "QYECC", SEI_ENG: "No.1 Package Plant", SEI_CHI: "1#成品包装" },
  
      { DIR: "L", SN: 1, AREA: "127700", SEI: "127742", CC: "SNEI", JIANLI: "BJHX", SEI_ENG: "Co-product Waste Water Sump", SEI_CHI: "联合装置废水池" },
      { DIR: "L", SN: 2, AREA: "127700", SEI: "127791", CC: "SNEI", JIANLI: "BJHX", SEI_ENG: "RIE-2", SEI_CHI: "联合现场机柜室" },
      { DIR: "L", SN: 3, AREA: "127700", SEI: "127792", CC: "SNEI", JIANLI: "BJHX", SEI_ENG: "Co-product Substation", SEI_CHI: "联合变电所" },
  
      { DIR: "L", SN: 1, AREA: "127800", SEI: "127800", CC: "SNEI", JIANLI: "BJHX", SEI_ENG: "GHU ", SEI_CHI: "裂解汽油加氢装置" },
  
      { DIR: "R", SN: 1, AREA: "127900", SEI: "127901", CC: "STCC", JIANLI: "YPES", SEI_ENG: "Furnaces Area", SEI_CHI: "急冷区" },
      { DIR: "R", SN: 2, AREA: "127900", SEI: "127902", CC: "STCC", JIANLI: "YPES", SEI_ENG: "Quench Area", SEI_CHI: "急冷区" },
      { DIR: "L", SN: 3, AREA: "127900", SEI: "127903", CC: "STCC", JIANLI: "YPES", SEI_ENG: "Compression Area", SEI_CHI: "压缩区" },
      { DIR: "L", SN: 4, AREA: "127900", SEI: "127904", CC: "STCC", JIANLI: "YPES", SEI_ENG: "Cold Fraction Area", SEI_CHI: "冷区" },
      { DIR: "L", SN: 5, AREA: "127900", SEI: "127905", CC: "STCC", JIANLI: "YPES", SEI_ENG: "Hot Fraction Area", SEI_CHI: "热区" },
      { DIR: "L", SN: 6, AREA: "127900", SEI: "127906", CC: "STCC", JIANLI: "YPES", SEI_ENG: "PSA Area", SEI_CHI: "PSA单元" },
      { DIR: "L", SN: 7, AREA: "127900", SEI: "127907", CC: "STCC", JIANLI: "YPES", SEI_ENG: "Spent Caustic Area", SEI_CHI: "废碱氧化单元" },
      { DIR: "R", SN: 8, AREA: "127900", SEI: "127908", CC: "STCC", JIANLI: "YPES", SEI_ENG: "Steam & Condensate Area", SEI_CHI: "蒸汽及凝液单元" },
      { DIR: "R", SN: 9, AREA: "127900", SEI: "127909", CC: "STCC", JIANLI: "YPES", SEI_ENG: "Benzene Removal Area", SEI_CHI: "污水脱苯单元" },
      { DIR: "R", SN: 10, AREA: "127900", SEI: "127942", CC: "STCC", JIANLI: "YPES", SEI_ENG: "Waste Water Sump", SEI_CHI: "废水池" },
      { DIR: "R", SN: 11, AREA: "127900", SEI: "127982", CC: "STCC", JIANLI: "YPES", SEI_ENG: "OR 2nd Substation", SEI_CHI: "第二变电所" },
      { DIR: "L", SN: 12, AREA: "127900", SEI: "127991", CC: "STCC", JIANLI: "YPES", SEI_ENG: "RIE-1", SEI_CHI: "现场机柜室" },
      { DIR: "R", SN: 13, AREA: "127900", SEI: "127992", CC: "STCC", JIANLI: "YPES", SEI_ENG: "OR 1st Substation", SEI_CHI: "第一变电所" },
      { DIR: "R", SN: 14, AREA: "127900", SEI: "127993", CC: "STCC", JIANLI: "YPES", SEI_ENG: "Water Spray", SEI_CHI: "雨淋阀" },
  
      { DRI: "L", SN: 1, AREA: "136200", SEI: "136200", CC: "SNEI", JIANLI: "BJHX", SEI_ENG: "DIB ", SEI_CHI: "装置" },
  
      { DIR: "L", SN: 1, AREA: "151000", SEI: "152000", CC: "GDHJ", JIANLI: "TJU", SEI_ENG: "Central Laboratory and Environmental Monitoring Station", SEI_CHI: "中心化验室及环境监测站" },
      { DIR: "R", SN: 2, AREA: "151000", SEI: "154000", CC: "ZGLY", JIANLI: "TJU", SEI_ENG: "Administration Office Building B (including canteen & locker room)", SEI_CHI: "综合办公楼B包含餐厅和更衣室" },
      { DIR: "R", SN: 3, AREA: "151000", SEI: "180401", CC: "GDHJ", JIANLI: "TJU", SEI_ENG: "Waste Water Pump Station 1", SEI_CHI: "污水提升泵站1" },
      { DIR: "L", SN: 4, AREA: "151000", SEI: "183100", CC: "GDHJ", JIANLI: "TJU", SEI_ENG: "Area B+ Substation", SEI_CHI: "厂前区变电所" },
      { DIR: "R", SN: 5, AREA: "151000", SEI: "186000", CC: "GDHJ", JIANLI: "TJU", SEI_ENG: "Control Center Building", SEI_CHI: "中心控制室" },
      { DIR: "R", SN: 6, AREA: "151000", SEI: "187501", CC: "SFCC", JIANLI: "Gosun", SEI_ENG: "Telecom equipment room", SEI_CHI: "电信机柜间" },
      { DIR: "L", SN: 7, AREA: "151000", SEI: "381006", CC: "GDHJ", JIANLI: "TJU", SEI_ENG: "Firewater Pump Station (B+)", SEI_CHI: "厂前区消防水泵站" },
  
      { DIR: "L", SN: 1, AREA: "152400", SEI: "152200", CC: "HZDJ", JIANLI: "TJU", SEI_ENG: "Maintenance Workshop", SEI_CHI: "维修中心" },
      { DIR: "L", SN: 2, AREA: "152400", SEI: "152300", CC: "HZDJ", JIANLI: "TJU", SEI_ENG: "Warehouse Management Room and Spare Parts Warehouse", SEI_CHI: "全厂性仓库" },
      { DIR: "R", SN: 3, AREA: "152400", SEI: "152401", CC: "HZDJ", JIANLI: "TJU", SEI_ENG: "Chemical Warehouses 1", SEI_CHI: "危险化学品库1" },
      { DIR: "R", SN: 4, AREA: "152400", SEI: "152402", CC: "HZDJ", JIANLI: "TJU", SEI_ENG: "Chemical Warehouses 2", SEI_CHI: "危险化学品库2" },
      { DIR: "R", SN: 5, AREA: "152400", SEI: "152403", CC: "HZDJ", JIANLI: "TJU", SEI_ENG: "Chemical Warehouses 3", SEI_CHI: "危险化学品库3" },
      { DIR: "R", SN: 6, AREA: "152400", SEI: "152404", CC: "HZDJ", JIANLI: "TJU", SEI_ENG: "Chemical Warehouses 4", SEI_CHI: "危险化学品库4" },
      { DIR: "L", SN: 7, AREA: "152400", SEI: "152405", CC: "HZDJ", JIANLI: "TJU", SEI_ENG: "Chemical Warehouses 5", SEI_CHI: "危险化学品库5" },
      { DIR: "L", SN: 8, AREA: "152400", SEI: "152407", CC: "HZDJ", JIANLI: "TJU", SEI_ENG: "Chemical Warehouses 7", SEI_CHI: "危险化学品库1" },
      { DIR: "L", SN: 9, AREA: "152400", SEI: "152408", CC: "HZDJ", JIANLI: "TJU", SEI_ENG: "Chemical Warehouses 8", SEI_CHI: "危险化学品库8" },
      { DIR: "L", SN: 10, AREA: "152400", SEI: "152492", CC: "HZDJ", JIANLI: "TJU", SEI_ENG: "Substation", SEI_CHI: "配电间" },
      { DIR: "L", SN: 11, AREA: "152400", SEI: "180404", CC: "HZDJ", JIANLI: "TJU", SEI_ENG: "Waste Water Pump Station 4 For Chemical Warehouse", SEI_CHI: "污水提升泵站 4" },
      { DIR: "L", SN: 12, AREA: "152400", SEI: "181200", CC: "HZDJ", JIANLI: "TJU", SEI_ENG: "FF & Gas Protection Station", SEI_CHI: "消防及气防站" },
      { DIR: "R", SN: 13, AREA: "152400", SEI: "182200", CC: "HZDJ", JIANLI: "TJU", SEI_ENG: "Hazardous waste temporary warehouse ", SEI_CHI: "危废暂存库" },
      { DIR: "R", SN: 14, AREA: "152400", SEI: "252703", CC: "HZDJ", JIANLI: "TJU", SEI_ENG: "Truck Scale3", SEI_CHI: "汽车衡3" },
  
      { DIR: "L", SN: 2, AREA: "152700", SEI: "152700", CC: "FCC", JIANLI: "QYECC", SEI_ENG: "Main plant Dispatch Area", SEI_CHI: "停车场" },
  
      { DIR: "R", SN: 1, AREA: "152801", SEI: "152801", CC: "SFCC", JIANLI: "QYECC", SEI_ENG: "No.1 Warehouse", SEI_CHI: "1#立体仓库" },
  
      { DIR: "R", SN: 1, AREA: "152802", SEI: "152802", CC: "FCC", JIANLI: "QYECC", SEI_ENG: "No.2 Warehouse", SEI_CHI: "2#立体仓库" },
  
      { DIR: "L", SN: 1, AREA: "170000", SEI: "124700", CC: "SFCC", JIANLI: "JSS", SEI_ENG: "Propylene Tank Farm", SEI_CHI: "丙烯罐区" },
      { DIR: "L", SN: 2, AREA: "170000", SEI: "170100", CC: "SFCC", JIANLI: "JSS", SEI_ENG: "Ethylene Cryogenic Tank Farm", SEI_CHI: "乙烯低温罐区" },
      { DIR: "L", SN: 3, AREA: "170000", SEI: "170300", CC: "SFCC", JIANLI: "JSS", SEI_ENG: "Pressurized Tank Farm 1", SEI_CHI: "压力罐区1" },
      { DIR: "R", SN: 4, AREA: "170000", SEI: "180402", CC: "SFCC", JIANLI: "JSS", SEI_ENG: "Waste Water Pump Station 2", SEI_CHI: "污水提升泵站2" },
      { DIR: "L", SN: 5, AREA: "170000", SEI: "181301", CC: "SFCC", JIANLI: "JSS", SEI_ENG: "Foam Station 1", SEI_CHI: "泡沫站1" },
      { DIR: "R", SN: 6, AREA: "170000", SEI: "270100", CC: "SFCC", JIANLI: "JSS", SEI_ENG: "Offspec Ethylene Tank Farm", SEI_CHI: "不合格乙烯罐区" },
      { DIR: "R", SN: 7, AREA: "170000", SEI: "270300", CC: "SFCC", JIANLI: "JSS", SEI_ENG: "Pressurized Tank Farm 2", SEI_CHI: "压力罐区2" },
      { DIR: "L", SN: 8, AREA: "170000", SEI: "370100", CC: "SFCC", JIANLI: "JSS", SEI_ENG: "Hexene-1 Tank Farm", SEI_CHI: "己烯-1罐区" },
  
      { DIR: "L", SN: 1, AREA: "170092", SEI: "170091", CC: "SFCC", JIANLI: "JSS", SEI_ENG: "7RIE 7(W/OS)", SEI_CHI: "现场机柜室" },
      { DIR: "L", SN: 2, AREA: "170092", SEI: "170092", CC: "SFCC", JIANLI: "JSS", SEI_ENG: "Tank Farm 1st Substation", SEI_CHI: "罐区第一变电所" },
      { DIR: "L", SN: 1, AREA: "178000", SEI: "178000", CC: "CNCEC", JIANLI: "JSS", SEI_ENG: "Loading/Unloading Station", SEI_CHI: "汽车装卸设施" },
      { DIR: "L", SN: 2, AREA: "178000", SEI: "178001", CC: "CNCEC", JIANLI: "JSS", SEI_ENG: "Station Building", SEI_CHI: "站房" },
  
      { DIR: "R", SN: 1, AREA: "178900", SEI: "152000", CC: "GDHJ", JIANLI: "TJU", SEI_ENG: "Environmental Monitoring Sub-station", SEI_CHI: "环境监测子站" },
      { DIR: "R", SN: 2, AREA: "178900", SEI: "166800", CC: "CNCEC", JIANLI: "JSS", SEI_ENG: "Ammonia Water Tank Farm", SEI_CHI: "氨水罐区" },
      { DIR: "L", SN: 3, AREA: "178900", SEI: "178600", CC: "SYPEC", JIANLI: "JSS", SEI_ENG: "Elevated Flare", SEI_CHI: "高架火炬" },
      { DIR: "L", SN: 4, AREA: "178900", SEI: "178900", CC: "SYPEC", JIANLI: "JSS", SEI_ENG: "Ground Flare", SEI_CHI: "地面火炬" },
      { DIR: "L", SN: 5, AREA: "178900", SEI: "178992", CC: "SYPEC", JIANLI: "JSS", SEI_ENG: "Substation", SEI_CHI: "变电所" },
  
      { DIR: "L", SN: 1, AREA: "180200", SEI: "180200", CC: "SFCC", JIANLI: "TJCD", SEI_ENG: "Cooling Water System 1", SEI_CHI: "第一循环水场" },
  
      { DIR: "L", SN: 1, AREA: "180292", SEI: "180292", CC: "SFCC", JIANLI: "TJCD", SEI_ENG: "Substation", SEI_CHI: "变电所" },
      { DIR: "L", SN: 1, AREA: "180600", SEI: "180600", CC: "CNCEC", JIANLI: "JSS", SEI_ENG: "Storm Water Pump Station and Accident Buffer Basin", SEI_CHI: "雨水泵站及事故水池" },
      { DIR: "L", SN: 1, AREA: "181000", SEI: "181000", CC: "SFCC", JIANLI: "TJCD", SEI_ENG: "Water and Firewater Pump Station 1", SEI_CHI: "第一给水及消防泵站" },
      { DIR: "L", SN: 1, AREA: "182400", SEI: "182400", CC: "CNCEC", JIANLI: "JSS", SEI_ENG: "Wastewater Pre-Treatment Unit", SEI_CHI: "污水预处理场" },
      { DIR: "L", SN: 2, AREA: "182400", SEI: "182492", CC: "CNCEC", JIANLI: "JSS", SEI_ENG: "Substation", SEI_CHI: "变电所" },
      { DIR: "L", SN: 1, AREA: "183000", SEI: "183000", CC: "HNEDI", JIANLI: "Gosun", SEI_ENG: "Main Substation", SEI_CHI: "总变电站" },
  
      { DIR: "L", SN: 1, AREA: "184300", SEI: "184301", CC: "SNEI", JIANLI: "BJHX", SEI_ENG: "Chemical Water Treatment", SEI_CHI: "化学水处理单元" },
      { DIR: "L", SN: 2, AREA: "184300", SEI: "184302", CC: "SNEI", JIANLI: "BJHX", SEI_ENG: "Condensate Water Treatment", SEI_CHI: "凝结水单元" },
      { DIR: "L", SN: 3, AREA: "184300", SEI: "184303", CC: "SNEI", JIANLI: "BJHX", SEI_ENG: "Acid & Caustic Station", SEI_CHI: "酸碱站" },
  
      { DIR: "L", SN: 1, AREA: "184800", SEI: "184891", CC: "SNEI", JIANLI: "BJHX", SEI_ENG: "RIE-6", SEI_CHI: "联合现场机柜室" },
      { DIR: "L", SN: 2, AREA: "184800", SEI: "184892", CC: "SNEI", JIANLI: "BJHX", SEI_ENG: "Substation", SEI_CHI: "变电所" },
  
      { DIR: "L", SN: 1, AREA: "185300", SEI: "185392", CC: "SFCC", JIANLI: "TJCD", SEI_ENG: "Substation", SEI_CHI: "变电所" },
      { DIR: "L", SN: 2, AREA: "185300", SEI: "185394", CC: "SFCC", JIANLI: "TJCD", SEI_ENG: "Operator Shelter", SEI_CHI: "外操间" },
  
      { DIR: "L", SN: 1, AREA: "277000", SEI: "168000", CC: "CC7", JIANLI: "JSS", SEI_ENG: "Naphtha Tank Farm", SEI_CHI: "石脑油罐区" },
      { DIR: "L", SN: 2, AREA: "277000", SEI: "177100", CC: "CC7", JIANLI: "JSS", SEI_ENG: "Intermediate Tank Farm 1", SEI_CHI: "中间罐区1" },
      { DIR: "R", SN: 3, AREA: "277000", SEI: "179600", CC: "CC7", JIANLI: "JSS", SEI_ENG: "Vapor Gas Recovery Unit", SEI_CHI: "油气回收处理设施" },
      { DIR: "R", SN: 4, AREA: "277000", SEI: "180403", CC: "CC7", JIANLI: "JSS", SEI_ENG: "Waste Water Pump Station 3", SEI_CHI: "污水提升泵站3" },
      { DIR: "R", SN: 5, AREA: "277000", SEI: "181302", CC: "CC7", JIANLI: "JSS", SEI_ENG: "Foam Station 2", SEI_CHI: "泡沫站2" },
      { DIR: "R", SN: 6, AREA: "277000", SEI: "181303", CC: "CC7", JIANLI: "JSS", SEI_ENG: "Foam Station 3", SEI_CHI: "泡沫站3" },
      { DIR: "R", SN: 7, AREA: "277000", SEI: "181304", CC: "CC7", JIANLI: "JSS", SEI_ENG: "Foam Station 4", SEI_CHI: "泡沫站4" },
      { DIR: "L", SN: 8, AREA: "277000", SEI: "277082", CC: "CC7", JIANLI: "JSS", SEI_ENG: "Tank Farm 2nd Substation", SEI_CHI: "罐区第二变电所" },
  
      { DIR: "R", SN: 1, AREA: "277100", SEI: "170800", CC: "WDECE", JIANLI: "JSS", SEI_ENG: "Mogas Tank Farm", SEI_CHI: "汽油调和罐区" },
      { DIR: "R", SN: 2, AREA: "277100", SEI: "277100", CC: "WDECE", JIANLI: "JSS", SEI_ENG: "Intermediate Tank Farm 2", SEI_CHI: "中间罐区2" },
      { DIR: "L", SN: 3, AREA: "277100", SEI: "377100", CC: "WDECE", JIANLI: "JSS", SEI_ENG: "Intermediate Tank Farm 3", SEI_CHI: "中间罐区3" },
  
      { DIR: "L", SN: 1, AREA: "280200", SEI: "280292", CC: "SFCC", JIANLI: "TJCD", SEI_ENG: "Substation", SEI_CHI: "变电所" },
      { DIR: "L", SN: 2, AREA: "280200", SEI: "280294", CC: "SFCC", JIANLI: "TJCD", SEI_ENG: "Operator Shelter", SEI_CHI: "外操间" },
  
      { DIR: "L", SN: 1, AREA: "281000", SEI: "281000", CC: "CNCEC", JIANLI: "JSS", SEI_ENG: "Water and Firewater Pump Station 2", SEI_CHI: "第二给水及消防泵站" },
  
      { DIR: "L", SN: 1, AREA: "179001", SEI: "179001", CC: "SFCC", JIANLI: "Gosun", SEI_ENG: "Pipe Galley #1", SEI_CHI: "管道舱 #1" },
      { DIR: "L", SN: 1, AREA: "179002", SEI: "179002", CC: "SFCC", JIANLI: "Gosun", SEI_ENG: "Pipe Galley #2", SEI_CHI: "管道舱 #2" },
      { DIR: "L", SN: 1, AREA: "179003", SEI: "179003", CC: "SFCC", JIANLI: "Gosun", SEI_ENG: "Pipe Galley #3", SEI_CHI: "管道舱 #3" },
      { DIR: "L", SN: 1, AREA: "179004", SEI: "179004", CC: "SFCC", JIANLI: "Gosun", SEI_ENG: "Pipe Galley #4", SEI_CHI: "管道舱 #4" },
      { DIR: "L", SN: 1, AREA: "179105", SEI: "179105", CC: "SPC", JIANLI: "QYECC", SEI_ENG: "Pipe Galley #5", SEI_CHI: "管道舱 #5" },
      { DIR: "L", SN: 1, AREA: "179106", SEI: "179106", CC: "SPC", JIANLI: "QYECC", SEI_ENG: "Pipe Galley #6", SEI_CHI: "管道舱 #6" },
      { DIR: "L", SN: 1, AREA: "179107", SEI: "179107", CC: "SPC", JIANLI: "QYECC", SEI_ENG: "Pipe Galley #7", SEI_CHI: "管道舱 #7" },
    ]
  }
    
  ],
}