export default {
  Canvas: {
    CanvasWidth: 1850,
    CanvasHeight: 1350,
    CanvasBorderWeight: 0.1,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 0,
    CanvasChartHeight: 0,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  Text: {
    Text: [
      { text: 'Note', x: 400, y: -50, font: 'roboto', class: 'regular', size: 11, color: '#44A9DF', align: 'start' },
    ],
    Filter: [
      { name: 'MOD'   , x: 320, y: -50, font: 'roboto', class: 'regular', size: 11, color: '#44A9DF', align: 'start' },
    ],
    Sql: [
      {
        query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
        text: 'DataSource',
        x: 320,
        y: -30,
        font: 'roboto',
        style: 'regular',
        size: 10,
        color: '#757575',
        align: 'start'
      },
    ]
  },
DataItems: [

  {DISC_DESCR:"Piping",AREA:"GENERAL",ITEM:"Spool Fabricated",UNIT:"EA",TOTAL:36,REMAIN:7,ACTUAL_CUM:29,ACTUAL_LAST_WK:2,ACTUAL_THIS_WK:11,ACTUAL_LAST_MONTH:5,ACTUAL_THIS_MONTH:15},
  {DISC_DESCR:"Piping",AREA:"HL02",ITEM:"Spool Fabricated",UNIT:"EA",TOTAL:1132,PLAN_CUM:1132,DELTA:0,REMAIN:0,ACTUAL_CUM:1132,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:7,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"MD01-2",ITEM:"Spool Fabricated",UNIT:"EA",TOTAL:7825,PLAN_CUM:7825,DELTA:-60,REMAIN:60,ACTUAL_CUM:7765,ACTUAL_LAST_WK:4,ACTUAL_THIS_WK:30,ACTUAL_LAST_MONTH:69,ACTUAL_THIS_MONTH:54},
  {DISC_DESCR:"Piping",AREA:"HL03",ITEM:"Spool Fabricated",UNIT:"EA",TOTAL:6129,PLAN_CUM:6129,DELTA:-48,REMAIN:48,ACTUAL_CUM:6081,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:6,ACTUAL_LAST_MONTH:19,ACTUAL_THIS_MONTH:8},
  {DISC_DESCR:"Piping",AREA:"HD01",ITEM:"Spool Fabricated",UNIT:"EA",TOTAL:101,PLAN_CUM:101,DELTA:0,REMAIN:0,ACTUAL_CUM:101,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"LQ00",ITEM:"Spool Fabricated",UNIT:"EA",TOTAL:3252,PLAN_CUM:3252,DELTA:0,REMAIN:0,ACTUAL_CUM:3252,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:8,ACTUAL_THIS_MONTH:1},
  {DISC_DESCR:"Piping",AREA:"IG90",ITEM:"Spool Fabricated",UNIT:"EA",TOTAL:1309,PLAN_CUM:1258,DELTA:-6,REMAIN:57,ACTUAL_CUM:1252,ACTUAL_LAST_WK:2,ACTUAL_THIS_WK:1,ACTUAL_LAST_MONTH:79,ACTUAL_THIS_MONTH:30},
  {DISC_DESCR:"Piping",AREA:"FL01",ITEM:"Spool Fabricated",UNIT:"EA",TOTAL:559,PLAN_CUM:552,DELTA:-101,REMAIN:108,ACTUAL_CUM:451,ACTUAL_LAST_WK:19,ACTUAL_THIS_WK:3,ACTUAL_LAST_MONTH:115,ACTUAL_THIS_MONTH:89},
  {DISC_DESCR:"Piping",AREA:"KO01",ITEM:"Spool Fabricated",UNIT:"EA",TOTAL:1205,PLAN_CUM:1205,DELTA:-76,REMAIN:76,ACTUAL_CUM:1129,ACTUAL_LAST_WK:9,ACTUAL_THIS_WK:9,ACTUAL_LAST_MONTH:71,ACTUAL_THIS_MONTH:32},
  {DISC_DESCR:"Piping",AREA:"RCxx",ITEM:"Spool Fabricated",UNIT:"EA",TOTAL:1408,REMAIN:6,ACTUAL_CUM:1402,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"M000",ITEM:"Spool Fabricated",UNIT:"EA",TOTAL:1454,PLAN_CUM:1454,DELTA:-81,REMAIN:81,ACTUAL_CUM:1373,ACTUAL_LAST_WK:43,ACTUAL_THIS_WK:27,ACTUAL_LAST_MONTH:101,ACTUAL_THIS_MONTH:128},
  {DISC_DESCR:"Piping",AREA:"ME01",ITEM:"Spool Fabricated",UNIT:"EA",TOTAL:1944,PLAN_CUM:1944,DELTA:-25,REMAIN:25,ACTUAL_CUM:1919,ACTUAL_LAST_WK:5,ACTUAL_THIS_WK:1,ACTUAL_LAST_MONTH:48,ACTUAL_THIS_MONTH:6},
  {DISC_DESCR:"Piping",AREA:"ME02",ITEM:"Spool Fabricated",UNIT:"EA",TOTAL:2436,PLAN_CUM:2436,DELTA:-16,REMAIN:16,ACTUAL_CUM:2420,ACTUAL_LAST_WK:10,ACTUAL_THIS_WK:3,ACTUAL_LAST_MONTH:57,ACTUAL_THIS_MONTH:33},
  {DISC_DESCR:"Piping",AREA:"ME04",ITEM:"Spool Fabricated",UNIT:"EA",TOTAL:710,PLAN_CUM:710,DELTA:-3,REMAIN:3,ACTUAL_CUM:707,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:2},
  {DISC_DESCR:"Piping",AREA:"ME05",ITEM:"Spool Fabricated",UNIT:"EA",TOTAL:189,PLAN_CUM:189,DELTA:0,REMAIN:0,ACTUAL_CUM:189,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"MW01",ITEM:"Spool Fabricated",UNIT:"EA",TOTAL:2015,PLAN_CUM:2015,DELTA:-85,REMAIN:85,ACTUAL_CUM:1930,ACTUAL_LAST_WK:6,ACTUAL_THIS_WK:1,ACTUAL_LAST_MONTH:145,ACTUAL_THIS_MONTH:16},
  {DISC_DESCR:"Piping",AREA:"MW02",ITEM:"Spool Fabricated",UNIT:"EA",TOTAL:2719,PLAN_CUM:2719,DELTA:-73,REMAIN:73,ACTUAL_CUM:2646,ACTUAL_LAST_WK:3,ACTUAL_THIS_WK:1,ACTUAL_LAST_MONTH:75,ACTUAL_THIS_MONTH:6},
  {DISC_DESCR:"Piping",AREA:"MW04",ITEM:"Spool Fabricated",UNIT:"EA",TOTAL:689,PLAN_CUM:689,DELTA:-4,REMAIN:4,ACTUAL_CUM:685,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:2,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:3},
  {DISC_DESCR:"Piping",AREA:"MW05",ITEM:"Spool Fabricated",UNIT:"EA",TOTAL:1611,PLAN_CUM:1611,DELTA:-1,REMAIN:1,ACTUAL_CUM:1610,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:3,ACTUAL_LAST_MONTH:8,ACTUAL_THIS_MONTH:5},
  {DISC_DESCR:"Piping",AREA:"GENERAL",ITEM:"Spool Fabricated",UNIT:"EA",TOTAL:36,REMAIN:7,ACTUAL_CUM:29,ACTUAL_LAST_WK:2,ACTUAL_THIS_WK:11,ACTUAL_LAST_MONTH:5,ACTUAL_THIS_MONTH:15},
  {DISC_DESCR:"Piping",AREA:"HULL-AFT",ITEM:"Spool Fabricated",UNIT:"EA",TOTAL:1132,PLAN_CUM:1132,DELTA:0,REMAIN:0,ACTUAL_CUM:1132,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:7,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"HULL-COMMON",ITEM:"Spool Fabricated",UNIT:"EA",TOTAL:7825,PLAN_CUM:7825,DELTA:-60,REMAIN:60,ACTUAL_CUM:7765,ACTUAL_LAST_WK:4,ACTUAL_THIS_WK:30,ACTUAL_LAST_MONTH:69,ACTUAL_THIS_MONTH:54},
  {DISC_DESCR:"Piping",AREA:"HULL-FOR",ITEM:"Spool Fabricated",UNIT:"EA",TOTAL:6129,PLAN_CUM:6129,DELTA:-48,REMAIN:48,ACTUAL_CUM:6081,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:6,ACTUAL_LAST_MONTH:19,ACTUAL_THIS_MONTH:8},
  {DISC_DESCR:"Piping",AREA:"INTEGRATION",ITEM:"Spool Fabricated",UNIT:"EA",TOTAL:1309,PLAN_CUM:1258,DELTA:-6,REMAIN:57,ACTUAL_CUM:1252,ACTUAL_LAST_WK:2,ACTUAL_THIS_WK:1,ACTUAL_LAST_MONTH:79,ACTUAL_THIS_MONTH:30},
  {DISC_DESCR:"Piping",AREA:"LQ",ITEM:"Spool Fabricated",UNIT:"EA",TOTAL:3353,PLAN_CUM:3353,DELTA:0,REMAIN:0,ACTUAL_CUM:3353,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:8,ACTUAL_THIS_MONTH:1},
  {DISC_DESCR:"Piping",AREA:"TOPSIDES COMMON",ITEM:"Spool Fabricated",UNIT:"EA",TOTAL:3172,PLAN_CUM:1757,DELTA:1225,REMAIN:190,ACTUAL_CUM:2982,ACTUAL_LAST_WK:28,ACTUAL_THIS_WK:12,ACTUAL_LAST_MONTH:186,ACTUAL_THIS_MONTH:121},
  {DISC_DESCR:"Piping",AREA:"TOPSIDES MODULES",ITEM:"Spool Fabricated",UNIT:"EA",TOTAL:13767,PLAN_CUM:13767,DELTA:-288,REMAIN:288,ACTUAL_CUM:13479,ACTUAL_LAST_WK:67,ACTUAL_THIS_WK:38,ACTUAL_LAST_MONTH:434,ACTUAL_THIS_MONTH:199},
  {DISC_DESCR:"Piping",AREA:"HULL",ITEM:"Spool Fabricated",UNIT:"EA",TOTAL:15086,PLAN_CUM:15086,DELTA:-108,REMAIN:108,ACTUAL_CUM:14978,ACTUAL_LAST_WK:4,ACTUAL_THIS_WK:36,ACTUAL_LAST_MONTH:95,ACTUAL_THIS_MONTH:62},
  {DISC_DESCR:"Piping",AREA:"TOPSIDES",ITEM:"Spool Fabricated",UNIT:"EA",TOTAL:16939,PLAN_CUM:15524,DELTA:937,REMAIN:478,ACTUAL_CUM:16461,ACTUAL_LAST_WK:95,ACTUAL_THIS_WK:50,ACTUAL_LAST_MONTH:620,ACTUAL_THIS_MONTH:320},
  {DISC_DESCR:"Piping",AREA:"OVERALL",ITEM:"Spool Fabricated",UNIT:"EA",TOTAL:36723,PLAN_CUM:35221,DELTA:852,REMAIN:650,ACTUAL_CUM:36073,ACTUAL_LAST_WK:103,ACTUAL_THIS_WK:98,ACTUAL_LAST_MONTH:807,ACTUAL_THIS_MONTH:428},
  {DISC_DESCR:"Piping",AREA:"OVERALL",ITEM:"Spool Erected",UNIT:"EA",TOTAL:36723,PLAN_CUM:29222,DELTA:4599,REMAIN:2902,ACTUAL_CUM:33821,ACTUAL_LAST_WK:556,ACTUAL_THIS_WK:287,ACTUAL_LAST_MONTH:2790,ACTUAL_THIS_MONTH:1773},
  {DISC_DESCR:"Piping",AREA:"TOPSIDES",ITEM:"Spool Erected",UNIT:"EA",TOTAL:16939,PLAN_CUM:12630,DELTA:2336,REMAIN:1973,ACTUAL_CUM:14966,ACTUAL_LAST_WK:320,ACTUAL_THIS_WK:194,ACTUAL_LAST_MONTH:1869,ACTUAL_THIS_MONTH:968},
  {DISC_DESCR:"Piping",AREA:"HULL",ITEM:"Spool Erected",UNIT:"EA",TOTAL:15086,PLAN_CUM:12983,DELTA:1644,REMAIN:459,ACTUAL_CUM:14627,ACTUAL_LAST_WK:192,ACTUAL_THIS_WK:87,ACTUAL_LAST_MONTH:582,ACTUAL_THIS_MONTH:453},
  {DISC_DESCR:"Piping",AREA:"GENERAL",ITEM:"Spool Erected",UNIT:"EA",TOTAL:36,REMAIN:25,ACTUAL_CUM:11,ACTUAL_LAST_WK:5,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:6,ACTUAL_THIS_MONTH:5},
  {DISC_DESCR:"Piping",AREA:"HULL-AFT",ITEM:"Spool Erected",UNIT:"EA",TOTAL:1132,PLAN_CUM:1132,DELTA:0,REMAIN:0,ACTUAL_CUM:1132,ACTUAL_LAST_WK:1,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:18,ACTUAL_THIS_MONTH:1},
  {DISC_DESCR:"Piping",AREA:"HULL-COMMON",ITEM:"Spool Erected",UNIT:"EA",TOTAL:7825,PLAN_CUM:5722,DELTA:1705,REMAIN:398,ACTUAL_CUM:7427,ACTUAL_LAST_WK:183,ACTUAL_THIS_WK:81,ACTUAL_LAST_MONTH:460,ACTUAL_THIS_MONTH:429},
  {DISC_DESCR:"Piping",AREA:"HULL-FOR",ITEM:"Spool Erected",UNIT:"EA",TOTAL:6129,PLAN_CUM:6129,DELTA:-61,REMAIN:61,ACTUAL_CUM:6068,ACTUAL_LAST_WK:8,ACTUAL_THIS_WK:6,ACTUAL_LAST_MONTH:104,ACTUAL_THIS_MONTH:23},
  {DISC_DESCR:"Piping",AREA:"INTEGRATION",ITEM:"Spool Erected",UNIT:"EA",TOTAL:1309,PLAN_CUM:256,DELTA:621,REMAIN:432,ACTUAL_CUM:877,ACTUAL_LAST_WK:32,ACTUAL_THIS_WK:4,ACTUAL_LAST_MONTH:259,ACTUAL_THIS_MONTH:335},
  {DISC_DESCR:"Piping",AREA:"LQ",ITEM:"Spool Erected",UNIT:"EA",TOTAL:3353,PLAN_CUM:3353,DELTA:-13,REMAIN:13,ACTUAL_CUM:3340,ACTUAL_LAST_WK:7,ACTUAL_THIS_WK:2,ACTUAL_LAST_MONTH:74,ACTUAL_THIS_MONTH:12},
  {DISC_DESCR:"Piping",AREA:"TOPSIDES COMMON",ITEM:"Spool Erected",UNIT:"EA",TOTAL:3172,PLAN_CUM:679,DELTA:1333,REMAIN:1160,ACTUAL_CUM:2012,ACTUAL_LAST_WK:106,ACTUAL_THIS_WK:56,ACTUAL_LAST_MONTH:189,ACTUAL_THIS_MONTH:314},
  {DISC_DESCR:"Piping",AREA:"TOPSIDES MODULES",ITEM:"Spool Erected",UNIT:"EA",TOTAL:13767,PLAN_CUM:11951,DELTA:1003,REMAIN:813,ACTUAL_CUM:12954,ACTUAL_LAST_WK:214,ACTUAL_THIS_WK:138,ACTUAL_LAST_MONTH:1680,ACTUAL_THIS_MONTH:654},
  {DISC_DESCR:"Piping",AREA:"GENERAL",ITEM:"Spool Erected",UNIT:"EA",TOTAL:36,REMAIN:25,ACTUAL_CUM:11,ACTUAL_LAST_WK:5,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:6,ACTUAL_THIS_MONTH:5},
  {DISC_DESCR:"Piping",AREA:"HL02",ITEM:"Spool Erected",UNIT:"EA",TOTAL:1132,PLAN_CUM:1132,DELTA:0,REMAIN:0,ACTUAL_CUM:1132,ACTUAL_LAST_WK:1,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:18,ACTUAL_THIS_MONTH:1},
  {DISC_DESCR:"Piping",AREA:"MD01-2",ITEM:"Spool Erected",UNIT:"EA",TOTAL:7825,PLAN_CUM:5722,DELTA:1705,REMAIN:398,ACTUAL_CUM:7427,ACTUAL_LAST_WK:183,ACTUAL_THIS_WK:81,ACTUAL_LAST_MONTH:460,ACTUAL_THIS_MONTH:429},
  {DISC_DESCR:"Piping",AREA:"HL03",ITEM:"Spool Erected",UNIT:"EA",TOTAL:6129,PLAN_CUM:6129,DELTA:-61,REMAIN:61,ACTUAL_CUM:6068,ACTUAL_LAST_WK:8,ACTUAL_THIS_WK:6,ACTUAL_LAST_MONTH:104,ACTUAL_THIS_MONTH:23},
  {DISC_DESCR:"Piping",AREA:"HD01",ITEM:"Spool Erected",UNIT:"EA",TOTAL:101,PLAN_CUM:101,DELTA:0,REMAIN:0,ACTUAL_CUM:101,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"LQ00",ITEM:"Spool Erected",UNIT:"EA",TOTAL:3252,PLAN_CUM:3252,DELTA:-13,REMAIN:13,ACTUAL_CUM:3239,ACTUAL_LAST_WK:7,ACTUAL_THIS_WK:2,ACTUAL_LAST_MONTH:74,ACTUAL_THIS_MONTH:12},
  {DISC_DESCR:"Piping",AREA:"IG90",ITEM:"Spool Erected",UNIT:"EA",TOTAL:1309,PLAN_CUM:256,DELTA:621,REMAIN:432,ACTUAL_CUM:877,ACTUAL_LAST_WK:32,ACTUAL_THIS_WK:4,ACTUAL_LAST_MONTH:259,ACTUAL_THIS_MONTH:335},
  {DISC_DESCR:"Piping",AREA:"FL01",ITEM:"Spool Erected",UNIT:"EA",TOTAL:559,PLAN_CUM:143,DELTA:1,REMAIN:415,ACTUAL_CUM:144,ACTUAL_LAST_WK:36,ACTUAL_THIS_WK:12,ACTUAL_LAST_MONTH:61,ACTUAL_THIS_MONTH:68},
  {DISC_DESCR:"Piping",AREA:"KO01",ITEM:"Spool Erected",UNIT:"EA",TOTAL:1205,PLAN_CUM:536,DELTA:-65,REMAIN:734,ACTUAL_CUM:471,ACTUAL_LAST_WK:70,ACTUAL_THIS_WK:44,ACTUAL_LAST_MONTH:126,ACTUAL_THIS_MONTH:246},
  {DISC_DESCR:"Piping",AREA:"RCxx",ITEM:"Spool Erected",UNIT:"EA",TOTAL:1408,REMAIN:11,ACTUAL_CUM:1397,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:2,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"M000",ITEM:"Spool Erected",UNIT:"EA",TOTAL:1454,PLAN_CUM:706,DELTA:303,REMAIN:445,ACTUAL_CUM:1009,ACTUAL_LAST_WK:77,ACTUAL_THIS_WK:9,ACTUAL_LAST_MONTH:157,ACTUAL_THIS_MONTH:297},
  {DISC_DESCR:"Piping",AREA:"ME01",ITEM:"Spool Erected",UNIT:"EA",TOTAL:1944,PLAN_CUM:1709,DELTA:182,REMAIN:53,ACTUAL_CUM:1891,ACTUAL_LAST_WK:26,ACTUAL_THIS_WK:12,ACTUAL_LAST_MONTH:296,ACTUAL_THIS_MONTH:48},
  {DISC_DESCR:"Piping",AREA:"ME02",ITEM:"Spool Erected",UNIT:"EA",TOTAL:2436,PLAN_CUM:2192,DELTA:199,REMAIN:45,ACTUAL_CUM:2391,ACTUAL_LAST_WK:40,ACTUAL_THIS_WK:39,ACTUAL_LAST_MONTH:377,ACTUAL_THIS_MONTH:79},
  {DISC_DESCR:"Piping",AREA:"ME04",ITEM:"Spool Erected",UNIT:"EA",TOTAL:710,PLAN_CUM:710,DELTA:-6,REMAIN:6,ACTUAL_CUM:704,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:2,ACTUAL_LAST_MONTH:4,ACTUAL_THIS_MONTH:2},
  {DISC_DESCR:"Piping",AREA:"ME05",ITEM:"Spool Erected",UNIT:"EA",TOTAL:189,PLAN_CUM:189,DELTA:0,REMAIN:0,ACTUAL_CUM:189,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"MW01",ITEM:"Spool Erected",UNIT:"EA",TOTAL:2015,PLAN_CUM:1774,DELTA:95,REMAIN:146,ACTUAL_CUM:1869,ACTUAL_LAST_WK:49,ACTUAL_THIS_WK:54,ACTUAL_LAST_MONTH:428,ACTUAL_THIS_MONTH:163},
  {DISC_DESCR:"Piping",AREA:"MW02",ITEM:"Spool Erected",UNIT:"EA",TOTAL:2719,PLAN_CUM:2371,DELTA:250,REMAIN:98,ACTUAL_CUM:2621,ACTUAL_LAST_WK:22,ACTUAL_THIS_WK:15,ACTUAL_LAST_MONTH:412,ACTUAL_THIS_MONTH:58},
  {DISC_DESCR:"Piping",AREA:"MW04",ITEM:"Spool Erected",UNIT:"EA",TOTAL:689,PLAN_CUM:689,DELTA:-8,REMAIN:8,ACTUAL_CUM:681,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:1,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"MW05",ITEM:"Spool Erected",UNIT:"EA",TOTAL:1611,PLAN_CUM:1611,DELTA:-12,REMAIN:12,ACTUAL_CUM:1599,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:7,ACTUAL_LAST_MONTH:5,ACTUAL_THIS_MONTH:7},
  {DISC_DESCR:"Piping",AREA:"GENERAL",ITEM:"Spool Welded/Bolted",UNIT:"EA",TOTAL:36,REMAIN:33,ACTUAL_CUM:3,ACTUAL_LAST_WK:3,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:3},
  {DISC_DESCR:"Piping",AREA:"HL02",ITEM:"Spool Welded/Bolted",UNIT:"EA",TOTAL:1132,PLAN_CUM:1132,DELTA:-14,REMAIN:14,ACTUAL_CUM:1118,ACTUAL_LAST_WK:12,ACTUAL_THIS_WK:1,ACTUAL_LAST_MONTH:18,ACTUAL_THIS_MONTH:16},
  {DISC_DESCR:"Piping",AREA:"MD01-2",ITEM:"Spool Welded/Bolted",UNIT:"EA",TOTAL:7825,PLAN_CUM:5722,DELTA:972,REMAIN:1131,ACTUAL_CUM:6694,ACTUAL_LAST_WK:425,ACTUAL_THIS_WK:215,ACTUAL_LAST_MONTH:916,ACTUAL_THIS_MONTH:1305},
  {DISC_DESCR:"Piping",AREA:"HL03",ITEM:"Spool Welded/Bolted",UNIT:"EA",TOTAL:6129,PLAN_CUM:6129,DELTA:-148,REMAIN:148,ACTUAL_CUM:5981,ACTUAL_LAST_WK:8,ACTUAL_THIS_WK:14,ACTUAL_LAST_MONTH:220,ACTUAL_THIS_MONTH:87},
  {DISC_DESCR:"Piping",AREA:"HD01",ITEM:"Spool Welded/Bolted",UNIT:"EA",TOTAL:101,PLAN_CUM:101,DELTA:0,REMAIN:0,ACTUAL_CUM:101,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:3,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"LQ00",ITEM:"Spool Welded/Bolted",UNIT:"EA",TOTAL:3252,PLAN_CUM:3252,DELTA:-13,REMAIN:13,ACTUAL_CUM:3239,ACTUAL_LAST_WK:11,ACTUAL_THIS_WK:9,ACTUAL_LAST_MONTH:156,ACTUAL_THIS_MONTH:42},
  {DISC_DESCR:"Piping",AREA:"IG90",ITEM:"Spool Welded/Bolted",UNIT:"EA",TOTAL:1309,PLAN_CUM:256,DELTA:246,REMAIN:807,ACTUAL_CUM:502,ACTUAL_LAST_WK:76,ACTUAL_THIS_WK:64,ACTUAL_LAST_MONTH:203,ACTUAL_THIS_MONTH:289},
  {DISC_DESCR:"Piping",AREA:"FL01",ITEM:"Spool Welded/Bolted",UNIT:"EA",TOTAL:559,PLAN_CUM:143,DELTA:-71,REMAIN:487,ACTUAL_CUM:72,ACTUAL_LAST_WK:18,ACTUAL_THIS_WK:14,ACTUAL_LAST_MONTH:28,ACTUAL_THIS_MONTH:44},
  {DISC_DESCR:"Piping",AREA:"KO01",ITEM:"Spool Welded/Bolted",UNIT:"EA",TOTAL:1205,PLAN_CUM:536,DELTA:-394,REMAIN:1063,ACTUAL_CUM:142,ACTUAL_LAST_WK:27,ACTUAL_THIS_WK:46,ACTUAL_LAST_MONTH:14,ACTUAL_THIS_MONTH:100},
  {DISC_DESCR:"Piping",AREA:"RCxx",ITEM:"Spool Welded/Bolted",UNIT:"EA",TOTAL:1408,REMAIN:12,ACTUAL_CUM:1396,ACTUAL_LAST_WK:1,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:1,ACTUAL_THIS_MONTH:1},
  {DISC_DESCR:"Piping",AREA:"M000",ITEM:"Spool Welded/Bolted",UNIT:"EA",TOTAL:1454,PLAN_CUM:706,DELTA:-374,REMAIN:1122,ACTUAL_CUM:332,ACTUAL_LAST_WK:82,ACTUAL_THIS_WK:141,ACTUAL_LAST_MONTH:22,ACTUAL_THIS_MONTH:308},
  {DISC_DESCR:"Piping",AREA:"ME01",ITEM:"Spool Welded/Bolted",UNIT:"EA",TOTAL:1944,PLAN_CUM:1709,DELTA:166,REMAIN:69,ACTUAL_CUM:1875,ACTUAL_LAST_WK:21,ACTUAL_THIS_WK:32,ACTUAL_LAST_MONTH:429,ACTUAL_THIS_MONTH:78},
  {DISC_DESCR:"Piping",AREA:"ME02",ITEM:"Spool Welded/Bolted",UNIT:"EA",TOTAL:2436,PLAN_CUM:2192,DELTA:177,REMAIN:67,ACTUAL_CUM:2369,ACTUAL_LAST_WK:12,ACTUAL_THIS_WK:73,ACTUAL_LAST_MONTH:844,ACTUAL_THIS_MONTH:131},
  {DISC_DESCR:"Piping",AREA:"ME04",ITEM:"Spool Welded/Bolted",UNIT:"EA",TOTAL:710,PLAN_CUM:710,DELTA:-6,REMAIN:6,ACTUAL_CUM:704,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:2,ACTUAL_LAST_MONTH:4,ACTUAL_THIS_MONTH:2},
  {DISC_DESCR:"Piping",AREA:"ME05",ITEM:"Spool Welded/Bolted",UNIT:"EA",TOTAL:189,PLAN_CUM:189,DELTA:-1,REMAIN:1,ACTUAL_CUM:188,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:3,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"MW01",ITEM:"Spool Welded/Bolted",UNIT:"EA",TOTAL:2015,PLAN_CUM:1774,DELTA:40,REMAIN:201,ACTUAL_CUM:1814,ACTUAL_LAST_WK:56,ACTUAL_THIS_WK:130,ACTUAL_LAST_MONTH:586,ACTUAL_THIS_MONTH:231},
  {DISC_DESCR:"Piping",AREA:"MW02",ITEM:"Spool Welded/Bolted",UNIT:"EA",TOTAL:2719,PLAN_CUM:2371,DELTA:213,REMAIN:135,ACTUAL_CUM:2584,ACTUAL_LAST_WK:21,ACTUAL_THIS_WK:43,ACTUAL_LAST_MONTH:786,ACTUAL_THIS_MONTH:109},
  {DISC_DESCR:"Piping",AREA:"MW04",ITEM:"Spool Welded/Bolted",UNIT:"EA",TOTAL:689,PLAN_CUM:689,DELTA:-8,REMAIN:8,ACTUAL_CUM:681,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:3,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"MW05",ITEM:"Spool Welded/Bolted",UNIT:"EA",TOTAL:1611,PLAN_CUM:1611,DELTA:-13,REMAIN:13,ACTUAL_CUM:1598,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:7,ACTUAL_LAST_MONTH:12,ACTUAL_THIS_MONTH:10},
  {DISC_DESCR:"Piping",AREA:"GENERAL",ITEM:"Spool Welded/Bolted",UNIT:"EA",TOTAL:36,REMAIN:33,ACTUAL_CUM:3,ACTUAL_LAST_WK:3,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:3},
  {DISC_DESCR:"Piping",AREA:"HULL-AFT",ITEM:"Spool Welded/Bolted",UNIT:"EA",TOTAL:1132,PLAN_CUM:1132,DELTA:-14,REMAIN:14,ACTUAL_CUM:1118,ACTUAL_LAST_WK:12,ACTUAL_THIS_WK:1,ACTUAL_LAST_MONTH:18,ACTUAL_THIS_MONTH:16},
  {DISC_DESCR:"Piping",AREA:"HULL-COMMON",ITEM:"Spool Welded/Bolted",UNIT:"EA",TOTAL:7825,PLAN_CUM:5722,DELTA:972,REMAIN:1131,ACTUAL_CUM:6694,ACTUAL_LAST_WK:425,ACTUAL_THIS_WK:215,ACTUAL_LAST_MONTH:916,ACTUAL_THIS_MONTH:1305},
  {DISC_DESCR:"Piping",AREA:"HULL-FOR",ITEM:"Spool Welded/Bolted",UNIT:"EA",TOTAL:6129,PLAN_CUM:6129,DELTA:-148,REMAIN:148,ACTUAL_CUM:5981,ACTUAL_LAST_WK:8,ACTUAL_THIS_WK:14,ACTUAL_LAST_MONTH:220,ACTUAL_THIS_MONTH:87},
  {DISC_DESCR:"Piping",AREA:"INTEGRATION",ITEM:"Spool Welded/Bolted",UNIT:"EA",TOTAL:1309,PLAN_CUM:256,DELTA:246,REMAIN:807,ACTUAL_CUM:502,ACTUAL_LAST_WK:76,ACTUAL_THIS_WK:64,ACTUAL_LAST_MONTH:203,ACTUAL_THIS_MONTH:289},
  {DISC_DESCR:"Piping",AREA:"LQ",ITEM:"Spool Welded/Bolted",UNIT:"EA",TOTAL:3353,PLAN_CUM:3353,DELTA:-13,REMAIN:13,ACTUAL_CUM:3340,ACTUAL_LAST_WK:11,ACTUAL_THIS_WK:9,ACTUAL_LAST_MONTH:159,ACTUAL_THIS_MONTH:42},
  {DISC_DESCR:"Piping",AREA:"TOPSIDES COMMON",ITEM:"Spool Welded/Bolted",UNIT:"EA",TOTAL:3172,PLAN_CUM:679,DELTA:931,REMAIN:1562,ACTUAL_CUM:1610,ACTUAL_LAST_WK:46,ACTUAL_THIS_WK:60,ACTUAL_LAST_MONTH:43,ACTUAL_THIS_MONTH:145},
  {DISC_DESCR:"Piping",AREA:"TOPSIDES MODULES",ITEM:"Spool Welded/Bolted",UNIT:"EA",TOTAL:13767,PLAN_CUM:11951,DELTA:194,REMAIN:1622,ACTUAL_CUM:12145,ACTUAL_LAST_WK:192,ACTUAL_THIS_WK:428,ACTUAL_LAST_MONTH:2689,ACTUAL_THIS_MONTH:869},
  {DISC_DESCR:"Piping",AREA:"HULL",ITEM:"Spool Welded/Bolted",UNIT:"EA",TOTAL:15086,PLAN_CUM:12983,DELTA:810,REMAIN:1293,ACTUAL_CUM:13793,ACTUAL_LAST_WK:445,ACTUAL_THIS_WK:230,ACTUAL_LAST_MONTH:1154,ACTUAL_THIS_MONTH:1408},
  {DISC_DESCR:"Piping",AREA:"TOPSIDES",ITEM:"Spool Welded/Bolted",UNIT:"EA",TOTAL:16939,PLAN_CUM:12630,DELTA:1125,REMAIN:3184,ACTUAL_CUM:13755,ACTUAL_LAST_WK:238,ACTUAL_THIS_WK:488,ACTUAL_LAST_MONTH:2732,ACTUAL_THIS_MONTH:1014},
  {DISC_DESCR:"Piping",AREA:"OVERALL",ITEM:"Spool Welded/Bolted",UNIT:"EA",TOTAL:36723,PLAN_CUM:29222,DELTA:2171,REMAIN:5330,ACTUAL_CUM:31393,ACTUAL_LAST_WK:773,ACTUAL_THIS_WK:791,ACTUAL_LAST_MONTH:4248,ACTUAL_THIS_MONTH:2756},
  {DISC_DESCR:"Piping",AREA:"TOPSIDES",ITEM:"Spool Supported",UNIT:"EA",TOTAL:16939,PLAN_CUM:12630,DELTA:523,REMAIN:3786,ACTUAL_CUM:13153,ACTUAL_LAST_WK:155,ACTUAL_THIS_WK:411,ACTUAL_LAST_MONTH:3624,ACTUAL_THIS_MONTH:1117},
  {DISC_DESCR:"Piping",AREA:"OVERALL",ITEM:"Spool Supported",UNIT:"EA",TOTAL:36723,PLAN_CUM:29222,DELTA:-124,REMAIN:7625,ACTUAL_CUM:29098,ACTUAL_LAST_WK:536,ACTUAL_THIS_WK:745,ACTUAL_LAST_MONTH:4575,ACTUAL_THIS_MONTH:2349},
  {DISC_DESCR:"Piping",AREA:"HULL",ITEM:"Spool Supported",UNIT:"EA",TOTAL:15086,PLAN_CUM:12983,DELTA:-646,REMAIN:2749,ACTUAL_CUM:12337,ACTUAL_LAST_WK:283,ACTUAL_THIS_WK:318,ACTUAL_LAST_MONTH:668,ACTUAL_THIS_MONTH:1012},
  {DISC_DESCR:"Piping",AREA:"GENERAL",ITEM:"Spool Supported",UNIT:"EA",TOTAL:36,REMAIN:36,ACTUAL_CUM:0,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"HULL-AFT",ITEM:"Spool Supported",UNIT:"EA",TOTAL:1132,PLAN_CUM:1132,DELTA:-15,REMAIN:15,ACTUAL_CUM:1117,ACTUAL_LAST_WK:2,ACTUAL_THIS_WK:13,ACTUAL_LAST_MONTH:9,ACTUAL_THIS_MONTH:25},
  {DISC_DESCR:"Piping",AREA:"HULL-COMMON",ITEM:"Spool Supported",UNIT:"EA",TOTAL:7825,PLAN_CUM:5722,DELTA:-305,REMAIN:2408,ACTUAL_CUM:5417,ACTUAL_LAST_WK:267,ACTUAL_THIS_WK:276,ACTUAL_LAST_MONTH:438,ACTUAL_THIS_MONTH:883},
  {DISC_DESCR:"Piping",AREA:"HULL-FOR",ITEM:"Spool Supported",UNIT:"EA",TOTAL:6129,PLAN_CUM:6129,DELTA:-326,REMAIN:326,ACTUAL_CUM:5803,ACTUAL_LAST_WK:14,ACTUAL_THIS_WK:29,ACTUAL_LAST_MONTH:221,ACTUAL_THIS_MONTH:104},
  {DISC_DESCR:"Piping",AREA:"INTEGRATION",ITEM:"Spool Supported",UNIT:"EA",TOTAL:1309,PLAN_CUM:256,DELTA:118,REMAIN:935,ACTUAL_CUM:374,ACTUAL_LAST_WK:47,ACTUAL_THIS_WK:14,ACTUAL_LAST_MONTH:232,ACTUAL_THIS_MONTH:119},
  {DISC_DESCR:"Piping",AREA:"LQ",ITEM:"Spool Supported",UNIT:"EA",TOTAL:3353,PLAN_CUM:3353,DELTA:-119,REMAIN:119,ACTUAL_CUM:3234,ACTUAL_LAST_WK:51,ACTUAL_THIS_WK:2,ACTUAL_LAST_MONTH:51,ACTUAL_THIS_MONTH:101},
  {DISC_DESCR:"Piping",AREA:"TOPSIDES COMMON",ITEM:"Spool Supported",UNIT:"EA",TOTAL:3172,PLAN_CUM:679,DELTA:733,REMAIN:1760,ACTUAL_CUM:1412,ACTUAL_LAST_WK:16,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:11,ACTUAL_THIS_MONTH:16},
  {DISC_DESCR:"Piping",AREA:"TOPSIDES MODULES",ITEM:"Spool Supported",UNIT:"EA",TOTAL:13767,PLAN_CUM:11951,DELTA:-210,REMAIN:2026,ACTUAL_CUM:11741,ACTUAL_LAST_WK:139,ACTUAL_THIS_WK:411,ACTUAL_LAST_MONTH:3613,ACTUAL_THIS_MONTH:1101},
  {DISC_DESCR:"Piping",AREA:"GENERAL",ITEM:"Spool Supported",UNIT:"EA",TOTAL:36,REMAIN:36,ACTUAL_CUM:0,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"HL02",ITEM:"Spool Supported",UNIT:"EA",TOTAL:1132,PLAN_CUM:1132,DELTA:-15,REMAIN:15,ACTUAL_CUM:1117,ACTUAL_LAST_WK:2,ACTUAL_THIS_WK:13,ACTUAL_LAST_MONTH:9,ACTUAL_THIS_MONTH:25},
  {DISC_DESCR:"Piping",AREA:"MD01-2",ITEM:"Spool Supported",UNIT:"EA",TOTAL:7825,PLAN_CUM:5722,DELTA:-305,REMAIN:2408,ACTUAL_CUM:5417,ACTUAL_LAST_WK:267,ACTUAL_THIS_WK:276,ACTUAL_LAST_MONTH:438,ACTUAL_THIS_MONTH:883},
  {DISC_DESCR:"Piping",AREA:"HL03",ITEM:"Spool Supported",UNIT:"EA",TOTAL:6129,PLAN_CUM:6129,DELTA:-326,REMAIN:326,ACTUAL_CUM:5803,ACTUAL_LAST_WK:14,ACTUAL_THIS_WK:29,ACTUAL_LAST_MONTH:221,ACTUAL_THIS_MONTH:104},
  {DISC_DESCR:"Piping",AREA:"HD01",ITEM:"Spool Supported",UNIT:"EA",TOTAL:101,PLAN_CUM:101,DELTA:0,REMAIN:0,ACTUAL_CUM:101,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:6,ACTUAL_THIS_MONTH:1},
  {DISC_DESCR:"Piping",AREA:"LQ00",ITEM:"Spool Supported",UNIT:"EA",TOTAL:3252,PLAN_CUM:3252,DELTA:-119,REMAIN:119,ACTUAL_CUM:3133,ACTUAL_LAST_WK:51,ACTUAL_THIS_WK:2,ACTUAL_LAST_MONTH:45,ACTUAL_THIS_MONTH:100},
  {DISC_DESCR:"Piping",AREA:"IG90",ITEM:"Spool Supported",UNIT:"EA",TOTAL:1309,PLAN_CUM:256,DELTA:118,REMAIN:935,ACTUAL_CUM:374,ACTUAL_LAST_WK:47,ACTUAL_THIS_WK:14,ACTUAL_LAST_MONTH:232,ACTUAL_THIS_MONTH:119},
  {DISC_DESCR:"Piping",AREA:"FL01",ITEM:"Spool Supported",UNIT:"EA",TOTAL:559,PLAN_CUM:143,DELTA:-128,REMAIN:544,ACTUAL_CUM:15,ACTUAL_LAST_WK:15,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:15},
  {DISC_DESCR:"Piping",AREA:"KO01",ITEM:"Spool Supported",UNIT:"EA",TOTAL:1205,PLAN_CUM:536,DELTA:-535,REMAIN:1204,ACTUAL_CUM:1,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:1,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"RCxx",ITEM:"Spool Supported",UNIT:"EA",TOTAL:1408,REMAIN:12,ACTUAL_CUM:1396,ACTUAL_LAST_WK:1,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:10,ACTUAL_THIS_MONTH:1},
  {DISC_DESCR:"Piping",AREA:"M000",ITEM:"Spool Supported",UNIT:"EA",TOTAL:1454,PLAN_CUM:706,DELTA:-691,REMAIN:1439,ACTUAL_CUM:15,ACTUAL_LAST_WK:13,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:2,ACTUAL_THIS_MONTH:13},
  {DISC_DESCR:"Piping",AREA:"ME01",ITEM:"Spool Supported",UNIT:"EA",TOTAL:1944,PLAN_CUM:1709,DELTA:156,REMAIN:79,ACTUAL_CUM:1865,ACTUAL_LAST_WK:14,ACTUAL_THIS_WK:63,ACTUAL_LAST_MONTH:672,ACTUAL_THIS_MONTH:141},
  {DISC_DESCR:"Piping",AREA:"ME02",ITEM:"Spool Supported",UNIT:"EA",TOTAL:2436,PLAN_CUM:2192,DELTA:164,REMAIN:80,ACTUAL_CUM:2356,ACTUAL_LAST_WK:44,ACTUAL_THIS_WK:108,ACTUAL_LAST_MONTH:1072,ACTUAL_THIS_MONTH:355},
  {DISC_DESCR:"Piping",AREA:"ME04",ITEM:"Spool Supported",UNIT:"EA",TOTAL:710,PLAN_CUM:710,DELTA:-8,REMAIN:8,ACTUAL_CUM:702,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:6,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"ME05",ITEM:"Spool Supported",UNIT:"EA",TOTAL:189,PLAN_CUM:189,DELTA:-1,REMAIN:1,ACTUAL_CUM:188,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:4,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"MW01",ITEM:"Spool Supported",UNIT:"EA",TOTAL:2015,PLAN_CUM:1774,DELTA:-15,REMAIN:256,ACTUAL_CUM:1759,ACTUAL_LAST_WK:25,ACTUAL_THIS_WK:142,ACTUAL_LAST_MONTH:676,ACTUAL_THIS_MONTH:280},
  {DISC_DESCR:"Piping",AREA:"MW02",ITEM:"Spool Supported",UNIT:"EA",TOTAL:2719,PLAN_CUM:2371,DELTA:206,REMAIN:142,ACTUAL_CUM:2577,ACTUAL_LAST_WK:43,ACTUAL_THIS_WK:91,ACTUAL_LAST_MONTH:1163,ACTUAL_THIS_MONTH:302},
  {DISC_DESCR:"Piping",AREA:"MW04",ITEM:"Spool Supported",UNIT:"EA",TOTAL:689,PLAN_CUM:689,DELTA:-8,REMAIN:8,ACTUAL_CUM:681,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:6,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"MW05",ITEM:"Spool Supported",UNIT:"EA",TOTAL:1611,PLAN_CUM:1611,DELTA:-13,REMAIN:13,ACTUAL_CUM:1598,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:7,ACTUAL_LAST_MONTH:12,ACTUAL_THIS_MONTH:10},
  {DISC_DESCR:"Piping",AREA:"GENERAL",ITEM:"Spool RFT",UNIT:"EA",TOTAL:36,REMAIN:36,ACTUAL_CUM:0,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"HL02",ITEM:"Spool RFT",UNIT:"EA",TOTAL:1132,PLAN_CUM:1132,DELTA:-34,REMAIN:34,ACTUAL_CUM:1098,ACTUAL_LAST_WK:4,ACTUAL_THIS_WK:12,ACTUAL_LAST_MONTH:11,ACTUAL_THIS_MONTH:25},
  {DISC_DESCR:"Piping",AREA:"MD01-2",ITEM:"Spool RFT",UNIT:"EA",TOTAL:7825,PLAN_CUM:5722,DELTA:-909,REMAIN:3012,ACTUAL_CUM:4813,ACTUAL_LAST_WK:288,ACTUAL_THIS_WK:219,ACTUAL_LAST_MONTH:551,ACTUAL_THIS_MONTH:1019},
  {DISC_DESCR:"Piping",AREA:"HL03",ITEM:"Spool RFT",UNIT:"EA",TOTAL:6129,PLAN_CUM:6129,DELTA:-428,REMAIN:428,ACTUAL_CUM:5701,ACTUAL_LAST_WK:18,ACTUAL_THIS_WK:25,ACTUAL_LAST_MONTH:420,ACTUAL_THIS_MONTH:113},
  {DISC_DESCR:"Piping",AREA:"HD01",ITEM:"Spool RFT",UNIT:"EA",TOTAL:101,PLAN_CUM:101,DELTA:0,REMAIN:0,ACTUAL_CUM:101,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:21,ACTUAL_THIS_MONTH:2},
  {DISC_DESCR:"Piping",AREA:"LQ00",ITEM:"Spool RFT",UNIT:"EA",TOTAL:3252,PLAN_CUM:3252,DELTA:-296,REMAIN:296,ACTUAL_CUM:2956,ACTUAL_LAST_WK:66,ACTUAL_THIS_WK:3,ACTUAL_LAST_MONTH:128,ACTUAL_THIS_MONTH:139},
  {DISC_DESCR:"Piping",AREA:"IG90",ITEM:"Spool RFT",UNIT:"EA",TOTAL:1309,PLAN_CUM:256,DELTA:73,REMAIN:980,ACTUAL_CUM:329,ACTUAL_LAST_WK:54,ACTUAL_THIS_WK:13,ACTUAL_LAST_MONTH:178,ACTUAL_THIS_MONTH:149},
  {DISC_DESCR:"Piping",AREA:"FL01",ITEM:"Spool RFT",UNIT:"EA",TOTAL:559,PLAN_CUM:143,DELTA:-131,REMAIN:547,ACTUAL_CUM:12,ACTUAL_LAST_WK:12,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:12},
  {DISC_DESCR:"Piping",AREA:"KO01",ITEM:"Spool RFT",UNIT:"EA",TOTAL:1205,PLAN_CUM:536,DELTA:-535,REMAIN:1204,ACTUAL_CUM:1,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:1,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"RCxx",ITEM:"Spool RFT",UNIT:"EA",TOTAL:1408,REMAIN:515,ACTUAL_CUM:893,ACTUAL_LAST_WK:12,ACTUAL_THIS_WK:1,ACTUAL_LAST_MONTH:13,ACTUAL_THIS_MONTH:19},
  {DISC_DESCR:"Piping",AREA:"M000",ITEM:"Spool RFT",UNIT:"EA",TOTAL:1454,PLAN_CUM:706,DELTA:-695,REMAIN:1443,ACTUAL_CUM:11,ACTUAL_LAST_WK:9,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:2,ACTUAL_THIS_MONTH:9},
  {DISC_DESCR:"Piping",AREA:"ME01",ITEM:"Spool RFT",UNIT:"EA",TOTAL:1944,PLAN_CUM:1709,DELTA:-220,REMAIN:455,ACTUAL_CUM:1489,ACTUAL_LAST_WK:67,ACTUAL_THIS_WK:81,ACTUAL_LAST_MONTH:523,ACTUAL_THIS_MONTH:210},
  {DISC_DESCR:"Piping",AREA:"ME02",ITEM:"Spool RFT",UNIT:"EA",TOTAL:2436,PLAN_CUM:2192,DELTA:-316,REMAIN:560,ACTUAL_CUM:1876,ACTUAL_LAST_WK:115,ACTUAL_THIS_WK:126,ACTUAL_LAST_MONTH:684,ACTUAL_THIS_MONTH:486},
  {DISC_DESCR:"Piping",AREA:"ME04",ITEM:"Spool RFT",UNIT:"EA",TOTAL:710,PLAN_CUM:710,DELTA:-30,REMAIN:30,ACTUAL_CUM:680,ACTUAL_LAST_WK:11,ACTUAL_THIS_WK:27,ACTUAL_LAST_MONTH:59,ACTUAL_THIS_MONTH:50},
  {DISC_DESCR:"Piping",AREA:"ME05",ITEM:"Spool RFT",UNIT:"EA",TOTAL:189,PLAN_CUM:189,DELTA:-67,REMAIN:67,ACTUAL_CUM:122,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:4,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"MW01",ITEM:"Spool RFT",UNIT:"EA",TOTAL:2015,PLAN_CUM:1774,DELTA:-464,REMAIN:705,ACTUAL_CUM:1310,ACTUAL_LAST_WK:62,ACTUAL_THIS_WK:97,ACTUAL_LAST_MONTH:462,ACTUAL_THIS_MONTH:274},
  {DISC_DESCR:"Piping",AREA:"MW02",ITEM:"Spool RFT",UNIT:"EA",TOTAL:2719,PLAN_CUM:2371,DELTA:-157,REMAIN:505,ACTUAL_CUM:2214,ACTUAL_LAST_WK:63,ACTUAL_THIS_WK:81,ACTUAL_LAST_MONTH:1000,ACTUAL_THIS_MONTH:369},
  {DISC_DESCR:"Piping",AREA:"MW04",ITEM:"Spool RFT",UNIT:"EA",TOTAL:689,PLAN_CUM:689,DELTA:-94,REMAIN:94,ACTUAL_CUM:595,ACTUAL_LAST_WK:16,ACTUAL_THIS_WK:45,ACTUAL_LAST_MONTH:46,ACTUAL_THIS_MONTH:61},
  {DISC_DESCR:"Piping",AREA:"MW05",ITEM:"Spool RFT",UNIT:"EA",TOTAL:1611,PLAN_CUM:1611,DELTA:-261,REMAIN:261,ACTUAL_CUM:1350,ACTUAL_LAST_WK:21,ACTUAL_THIS_WK:5,ACTUAL_LAST_MONTH:116,ACTUAL_THIS_MONTH:72},
  {DISC_DESCR:"Piping",AREA:"GENERAL",ITEM:"Spool RFT",UNIT:"EA",TOTAL:36,REMAIN:36,ACTUAL_CUM:0,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"HULL-AFT",ITEM:"Spool RFT",UNIT:"EA",TOTAL:1132,PLAN_CUM:1132,DELTA:-34,REMAIN:34,ACTUAL_CUM:1098,ACTUAL_LAST_WK:4,ACTUAL_THIS_WK:12,ACTUAL_LAST_MONTH:11,ACTUAL_THIS_MONTH:25},
  {DISC_DESCR:"Piping",AREA:"HULL-COMMON",ITEM:"Spool RFT",UNIT:"EA",TOTAL:7825,PLAN_CUM:5722,DELTA:-909,REMAIN:3012,ACTUAL_CUM:4813,ACTUAL_LAST_WK:288,ACTUAL_THIS_WK:219,ACTUAL_LAST_MONTH:551,ACTUAL_THIS_MONTH:1019},
  {DISC_DESCR:"Piping",AREA:"HULL-FOR",ITEM:"Spool RFT",UNIT:"EA",TOTAL:6129,PLAN_CUM:6129,DELTA:-428,REMAIN:428,ACTUAL_CUM:5701,ACTUAL_LAST_WK:18,ACTUAL_THIS_WK:25,ACTUAL_LAST_MONTH:420,ACTUAL_THIS_MONTH:113},
  {DISC_DESCR:"Piping",AREA:"INTEGRATION",ITEM:"Spool RFT",UNIT:"EA",TOTAL:1309,PLAN_CUM:256,DELTA:73,REMAIN:980,ACTUAL_CUM:329,ACTUAL_LAST_WK:54,ACTUAL_THIS_WK:13,ACTUAL_LAST_MONTH:178,ACTUAL_THIS_MONTH:149},
  {DISC_DESCR:"Piping",AREA:"LQ",ITEM:"Spool RFT",UNIT:"EA",TOTAL:3353,PLAN_CUM:3353,DELTA:-296,REMAIN:296,ACTUAL_CUM:3057,ACTUAL_LAST_WK:66,ACTUAL_THIS_WK:3,ACTUAL_LAST_MONTH:149,ACTUAL_THIS_MONTH:141},
  {DISC_DESCR:"Piping",AREA:"TOPSIDES COMMON",ITEM:"Spool RFT",UNIT:"EA",TOTAL:3172,PLAN_CUM:679,DELTA:227,REMAIN:2266,ACTUAL_CUM:906,ACTUAL_LAST_WK:24,ACTUAL_THIS_WK:1,ACTUAL_LAST_MONTH:14,ACTUAL_THIS_MONTH:31},
  {DISC_DESCR:"Piping",AREA:"TOPSIDES MODULES",ITEM:"Spool RFT",UNIT:"EA",TOTAL:13767,PLAN_CUM:11951,DELTA:-2304,REMAIN:4120,ACTUAL_CUM:9647,ACTUAL_LAST_WK:364,ACTUAL_THIS_WK:462,ACTUAL_LAST_MONTH:2896,ACTUAL_THIS_MONTH:1531},
  {DISC_DESCR:"Piping",AREA:"HULL",ITEM:"Spool RFT",UNIT:"EA",TOTAL:15086,PLAN_CUM:12983,DELTA:-1371,REMAIN:3474,ACTUAL_CUM:11612,ACTUAL_LAST_WK:310,ACTUAL_THIS_WK:256,ACTUAL_LAST_MONTH:982,ACTUAL_THIS_MONTH:1157},
  {DISC_DESCR:"Piping",AREA:"TOPSIDES",ITEM:"Spool RFT",UNIT:"EA",TOTAL:16939,PLAN_CUM:12630,DELTA:-2077,REMAIN:6386,ACTUAL_CUM:10553,ACTUAL_LAST_WK:388,ACTUAL_THIS_WK:463,ACTUAL_LAST_MONTH:2910,ACTUAL_THIS_MONTH:1562},
  {DISC_DESCR:"Piping",AREA:"OVERALL",ITEM:"Spool RFT",UNIT:"EA",TOTAL:36723,PLAN_CUM:29222,DELTA:-3671,REMAIN:11172,ACTUAL_CUM:25551,ACTUAL_LAST_WK:818,ACTUAL_THIS_WK:735,ACTUAL_LAST_MONTH:4219,ACTUAL_THIS_MONTH:3009},
  {DISC_DESCR:"Piping",AREA:"TOPSIDES",ITEM:"Tested",UNIT:"EA",TOTAL:891,PLAN_CUM:308,DELTA:123,REMAIN:460,ACTUAL_CUM:431,ACTUAL_LAST_WK:13,ACTUAL_THIS_WK:53,ACTUAL_LAST_MONTH:103,ACTUAL_THIS_MONTH:95},
  {DISC_DESCR:"Piping",AREA:"HULL",ITEM:"Tested",UNIT:"EA",TOTAL:923,PLAN_CUM:460,DELTA:-34,REMAIN:497,ACTUAL_CUM:426,ACTUAL_LAST_WK:2,ACTUAL_THIS_WK:59,ACTUAL_LAST_MONTH:40,ACTUAL_THIS_MONTH:74},
  {DISC_DESCR:"Piping",AREA:"OVERALL",ITEM:"Tested",UNIT:"EA",TOTAL:2799,PLAN_CUM:910,DELTA:67,REMAIN:1822,ACTUAL_CUM:977,ACTUAL_LAST_WK:31,ACTUAL_THIS_WK:125,ACTUAL_LAST_MONTH:159,ACTUAL_THIS_MONTH:201},
  {DISC_DESCR:"Piping",AREA:"HL03",ITEM:"Tested",UNIT:"EA",TOTAL:2,REMAIN:2,ACTUAL_CUM:0,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"HULL-AFT",ITEM:"Tested",UNIT:"EA",TOTAL:88,PLAN_CUM:77,DELTA:-19,REMAIN:30,ACTUAL_CUM:58,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:6,ACTUAL_LAST_MONTH:5,ACTUAL_THIS_MONTH:6},
  {DISC_DESCR:"Piping",AREA:"HULL-COMMON",ITEM:"Tested",UNIT:"EA",TOTAL:395,PLAN_CUM:55,DELTA:34,REMAIN:306,ACTUAL_CUM:89,ACTUAL_LAST_WK:1,ACTUAL_THIS_WK:16,ACTUAL_LAST_MONTH:7,ACTUAL_THIS_MONTH:17},
  {DISC_DESCR:"Piping",AREA:"HULL-FOR",ITEM:"Tested",UNIT:"EA",TOTAL:436,PLAN_CUM:328,DELTA:-49,REMAIN:157,ACTUAL_CUM:279,ACTUAL_LAST_WK:1,ACTUAL_THIS_WK:37,ACTUAL_LAST_MONTH:28,ACTUAL_THIS_MONTH:51},
  {DISC_DESCR:"Piping",AREA:"INTEGRATION",ITEM:"Tested",UNIT:"EA",TOTAL:807,PLAN_CUM:32,DELTA:-27,REMAIN:802,ACTUAL_CUM:5,ACTUAL_LAST_WK:1,ACTUAL_THIS_WK:1,ACTUAL_LAST_MONTH:1,ACTUAL_THIS_MONTH:2},
  {DISC_DESCR:"Piping",AREA:"LQ",ITEM:"Tested",UNIT:"EA",TOTAL:157,PLAN_CUM:110,DELTA:5,REMAIN:42,ACTUAL_CUM:115,ACTUAL_LAST_WK:15,ACTUAL_THIS_WK:12,ACTUAL_LAST_MONTH:15,ACTUAL_THIS_MONTH:30},
  {DISC_DESCR:"Piping",AREA:"MD01-2",ITEM:"Tested",UNIT:"EA",TOTAL:2,REMAIN:2,ACTUAL_CUM:0,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"TBD",ITEM:"Tested",UNIT:"EA",TOTAL:21,REMAIN:21,ACTUAL_CUM:0,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"TOPSIDES COMMON",ITEM:"Tested",UNIT:"EA",TOTAL:91,PLAN_CUM:1,DELTA:-1,REMAIN:91,ACTUAL_CUM:0,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"TOPSIDES MODULES",ITEM:"Tested",UNIT:"EA",TOTAL:800,PLAN_CUM:307,DELTA:124,REMAIN:369,ACTUAL_CUM:431,ACTUAL_LAST_WK:13,ACTUAL_THIS_WK:53,ACTUAL_LAST_MONTH:103,ACTUAL_THIS_MONTH:95},
  {DISC_DESCR:"Piping",AREA:"MS00",ITEM:"Tested",UNIT:"EA",TOTAL:2,REMAIN:2,ACTUAL_CUM:0,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"HL02",ITEM:"Tested",UNIT:"EA",TOTAL:88,PLAN_CUM:77,DELTA:-19,REMAIN:30,ACTUAL_CUM:58,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:6,ACTUAL_LAST_MONTH:5,ACTUAL_THIS_MONTH:6},
  {DISC_DESCR:"Piping",AREA:"MD01-2",ITEM:"Tested",UNIT:"EA",TOTAL:395,PLAN_CUM:55,DELTA:34,REMAIN:306,ACTUAL_CUM:89,ACTUAL_LAST_WK:1,ACTUAL_THIS_WK:16,ACTUAL_LAST_MONTH:7,ACTUAL_THIS_MONTH:17},
  {DISC_DESCR:"Piping",AREA:"HL03",ITEM:"Tested",UNIT:"EA",TOTAL:436,PLAN_CUM:328,DELTA:-49,REMAIN:157,ACTUAL_CUM:279,ACTUAL_LAST_WK:1,ACTUAL_THIS_WK:37,ACTUAL_LAST_MONTH:28,ACTUAL_THIS_MONTH:51},
  {DISC_DESCR:"Piping",AREA:"HD01",ITEM:"Tested",UNIT:"EA",TOTAL:6,REMAIN:4,ACTUAL_CUM:2,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"LQ00",ITEM:"Tested",UNIT:"EA",TOTAL:151,PLAN_CUM:110,DELTA:3,REMAIN:38,ACTUAL_CUM:113,ACTUAL_LAST_WK:15,ACTUAL_THIS_WK:12,ACTUAL_LAST_MONTH:15,ACTUAL_THIS_MONTH:30},
  {DISC_DESCR:"Piping",AREA:"MD00",ITEM:"Tested",UNIT:"EA",TOTAL:2,REMAIN:2,ACTUAL_CUM:0,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"IG90",ITEM:"Tested",UNIT:"EA",TOTAL:807,PLAN_CUM:32,DELTA:-27,REMAIN:802,ACTUAL_CUM:5,ACTUAL_LAST_WK:1,ACTUAL_THIS_WK:1,ACTUAL_LAST_MONTH:1,ACTUAL_THIS_MONTH:2},
  {DISC_DESCR:"Piping",AREA:"CW",ITEM:"Tested",UNIT:"EA",TOTAL:20,REMAIN:20,ACTUAL_CUM:0,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"LD00",ITEM:"Tested",UNIT:"EA",TOTAL:1,REMAIN:1,ACTUAL_CUM:0,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"FL01",ITEM:"Tested",UNIT:"EA",TOTAL:29,PLAN_CUM:1,DELTA:-1,REMAIN:29,ACTUAL_CUM:0,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"KO01",ITEM:"Tested",UNIT:"EA",TOTAL:60,PLAN_CUM:0,DELTA:0,REMAIN:60,ACTUAL_CUM:0,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"RCxx",ITEM:"Tested",UNIT:"EA",TOTAL:2,REMAIN:2,ACTUAL_CUM:0,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"M000",ITEM:"Tested",UNIT:"EA",TOTAL:55,PLAN_CUM:0,DELTA:0,REMAIN:55,ACTUAL_CUM:0,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"ME01",ITEM:"Tested",UNIT:"EA",TOTAL:101,PLAN_CUM:25,DELTA:34,REMAIN:42,ACTUAL_CUM:59,ACTUAL_LAST_WK:3,ACTUAL_THIS_WK:6,ACTUAL_LAST_MONTH:25,ACTUAL_THIS_MONTH:16},
  {DISC_DESCR:"Piping",AREA:"ME02",ITEM:"Tested",UNIT:"EA",TOTAL:148,PLAN_CUM:36,DELTA:49,REMAIN:63,ACTUAL_CUM:85,ACTUAL_LAST_WK:7,ACTUAL_THIS_WK:15,ACTUAL_LAST_MONTH:36,ACTUAL_THIS_MONTH:32},
  {DISC_DESCR:"Piping",AREA:"ME04",ITEM:"Tested",UNIT:"EA",TOTAL:51,PLAN_CUM:51,DELTA:-13,REMAIN:13,ACTUAL_CUM:38,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:5,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:5},
  {DISC_DESCR:"Piping",AREA:"ME05",ITEM:"Tested",UNIT:"EA",TOTAL:5,PLAN_CUM:5,DELTA:-5,REMAIN:5,ACTUAL_CUM:0,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"MW01",ITEM:"Tested",UNIT:"EA",TOTAL:142,PLAN_CUM:34,DELTA:29,REMAIN:79,ACTUAL_CUM:63,ACTUAL_LAST_WK:1,ACTUAL_THIS_WK:9,ACTUAL_LAST_MONTH:22,ACTUAL_THIS_MONTH:14},
  {DISC_DESCR:"Piping",AREA:"MW02",ITEM:"Tested",UNIT:"EA",TOTAL:145,PLAN_CUM:35,DELTA:37,REMAIN:73,ACTUAL_CUM:72,ACTUAL_LAST_WK:2,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:19,ACTUAL_THIS_MONTH:8},
  {DISC_DESCR:"Piping",AREA:"MW04",ITEM:"Tested",UNIT:"EA",TOTAL:50,PLAN_CUM:50,DELTA:-21,REMAIN:21,ACTUAL_CUM:29,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"MW05",ITEM:"Tested",UNIT:"EA",TOTAL:103,PLAN_CUM:71,DELTA:14,REMAIN:18,ACTUAL_CUM:85,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:18,ACTUAL_LAST_MONTH:1,ACTUAL_THIS_MONTH:20},
  {DISC_DESCR:"Piping",AREA:"MS00",ITEM:"Reinstated",UNIT:"EA",TOTAL:2,REMAIN:2,ACTUAL_CUM:0,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"HL02",ITEM:"Reinstated",UNIT:"EA",TOTAL:88,PLAN_CUM:77,DELTA:-61,REMAIN:72,ACTUAL_CUM:16,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:6,ACTUAL_LAST_MONTH:2,ACTUAL_THIS_MONTH:8},
  {DISC_DESCR:"Piping",AREA:"MD01-2",ITEM:"Reinstated",UNIT:"EA",TOTAL:395,PLAN_CUM:55,DELTA:-20,REMAIN:360,ACTUAL_CUM:35,ACTUAL_LAST_WK:1,ACTUAL_THIS_WK:9,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:11},
  {DISC_DESCR:"Piping",AREA:"HL03",ITEM:"Reinstated",UNIT:"EA",TOTAL:436,PLAN_CUM:328,DELTA:-193,REMAIN:301,ACTUAL_CUM:135,ACTUAL_LAST_WK:5,ACTUAL_THIS_WK:24,ACTUAL_LAST_MONTH:7,ACTUAL_THIS_MONTH:51},
  {DISC_DESCR:"Piping",AREA:"HD01",ITEM:"Reinstated",UNIT:"EA",TOTAL:6,REMAIN:4,ACTUAL_CUM:2,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:2,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:2},
  {DISC_DESCR:"Piping",AREA:"LQ00",ITEM:"Reinstated",UNIT:"EA",TOTAL:151,PLAN_CUM:110,DELTA:-65,REMAIN:106,ACTUAL_CUM:45,ACTUAL_LAST_WK:2,ACTUAL_THIS_WK:19,ACTUAL_LAST_MONTH:1,ACTUAL_THIS_MONTH:24},
  {DISC_DESCR:"Piping",AREA:"MD00",ITEM:"Reinstated",UNIT:"EA",TOTAL:2,REMAIN:2,ACTUAL_CUM:0,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"IG90",ITEM:"Reinstated",UNIT:"EA",TOTAL:807,PLAN_CUM:32,DELTA:-30,REMAIN:805,ACTUAL_CUM:2,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:1,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"CW",ITEM:"Reinstated",UNIT:"EA",TOTAL:20,REMAIN:20,ACTUAL_CUM:0,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"LD00",ITEM:"Reinstated",UNIT:"EA",TOTAL:1,REMAIN:1,ACTUAL_CUM:0,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"FL01",ITEM:"Reinstated",UNIT:"EA",TOTAL:29,PLAN_CUM:1,DELTA:-1,REMAIN:29,ACTUAL_CUM:0,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"KO01",ITEM:"Reinstated",UNIT:"EA",TOTAL:60,PLAN_CUM:0,DELTA:0,REMAIN:60,ACTUAL_CUM:0,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"RCxx",ITEM:"Reinstated",UNIT:"EA",TOTAL:2,REMAIN:2,ACTUAL_CUM:0,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"M000",ITEM:"Reinstated",UNIT:"EA",TOTAL:55,PLAN_CUM:0,DELTA:0,REMAIN:55,ACTUAL_CUM:0,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"ME01",ITEM:"Reinstated",UNIT:"EA",TOTAL:101,PLAN_CUM:25,DELTA:-23,REMAIN:99,ACTUAL_CUM:2,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:2,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:2},
  {DISC_DESCR:"Piping",AREA:"ME02",ITEM:"Reinstated",UNIT:"EA",TOTAL:148,PLAN_CUM:36,DELTA:-36,REMAIN:148,ACTUAL_CUM:0,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"ME04",ITEM:"Reinstated",UNIT:"EA",TOTAL:51,PLAN_CUM:51,DELTA:-44,REMAIN:44,ACTUAL_CUM:7,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:2,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:2},
  {DISC_DESCR:"Piping",AREA:"ME05",ITEM:"Reinstated",UNIT:"EA",TOTAL:5,PLAN_CUM:5,DELTA:-5,REMAIN:5,ACTUAL_CUM:0,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"MW01",ITEM:"Reinstated",UNIT:"EA",TOTAL:142,PLAN_CUM:34,DELTA:-34,REMAIN:142,ACTUAL_CUM:0,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"MW02",ITEM:"Reinstated",UNIT:"EA",TOTAL:145,PLAN_CUM:35,DELTA:-35,REMAIN:145,ACTUAL_CUM:0,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"MW04",ITEM:"Reinstated",UNIT:"EA",TOTAL:50,PLAN_CUM:50,DELTA:-44,REMAIN:44,ACTUAL_CUM:6,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:1,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:1},
  {DISC_DESCR:"Piping",AREA:"MW05",ITEM:"Reinstated",UNIT:"EA",TOTAL:103,PLAN_CUM:71,DELTA:-38,REMAIN:70,ACTUAL_CUM:33,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:12,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:12},
  {DISC_DESCR:"Piping",AREA:"HL03",ITEM:"Reinstated",UNIT:"EA",TOTAL:2,REMAIN:2,ACTUAL_CUM:0,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"HULL-AFT",ITEM:"Reinstated",UNIT:"EA",TOTAL:88,PLAN_CUM:77,DELTA:-61,REMAIN:72,ACTUAL_CUM:16,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:6,ACTUAL_LAST_MONTH:2,ACTUAL_THIS_MONTH:8},
  {DISC_DESCR:"Piping",AREA:"HULL-COMMON",ITEM:"Reinstated",UNIT:"EA",TOTAL:395,PLAN_CUM:55,DELTA:-20,REMAIN:360,ACTUAL_CUM:35,ACTUAL_LAST_WK:1,ACTUAL_THIS_WK:9,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:11},
  {DISC_DESCR:"Piping",AREA:"HULL-FOR",ITEM:"Reinstated",UNIT:"EA",TOTAL:436,PLAN_CUM:328,DELTA:-193,REMAIN:301,ACTUAL_CUM:135,ACTUAL_LAST_WK:5,ACTUAL_THIS_WK:24,ACTUAL_LAST_MONTH:7,ACTUAL_THIS_MONTH:51},
  {DISC_DESCR:"Piping",AREA:"INTEGRATION",ITEM:"Reinstated",UNIT:"EA",TOTAL:807,PLAN_CUM:32,DELTA:-30,REMAIN:805,ACTUAL_CUM:2,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:1,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"LQ",ITEM:"Reinstated",UNIT:"EA",TOTAL:157,PLAN_CUM:110,DELTA:-63,REMAIN:110,ACTUAL_CUM:47,ACTUAL_LAST_WK:2,ACTUAL_THIS_WK:21,ACTUAL_LAST_MONTH:1,ACTUAL_THIS_MONTH:26},
  {DISC_DESCR:"Piping",AREA:"MD01-2",ITEM:"Reinstated",UNIT:"EA",TOTAL:2,REMAIN:2,ACTUAL_CUM:0,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"TBD",ITEM:"Reinstated",UNIT:"EA",TOTAL:21,REMAIN:21,ACTUAL_CUM:0,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"TOPSIDES COMMON",ITEM:"Reinstated",UNIT:"EA",TOTAL:91,PLAN_CUM:1,DELTA:-1,REMAIN:91,ACTUAL_CUM:0,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:0,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:0},
  {DISC_DESCR:"Piping",AREA:"TOPSIDES MODULES",ITEM:"Reinstated",UNIT:"EA",TOTAL:800,PLAN_CUM:307,DELTA:-259,REMAIN:752,ACTUAL_CUM:48,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:17,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:17},
  {DISC_DESCR:"Piping",AREA:"HULL",ITEM:"Reinstated",UNIT:"EA",TOTAL:923,PLAN_CUM:460,DELTA:-274,REMAIN:737,ACTUAL_CUM:186,ACTUAL_LAST_WK:6,ACTUAL_THIS_WK:39,ACTUAL_LAST_MONTH:9,ACTUAL_THIS_MONTH:70},
  {DISC_DESCR:"Piping",AREA:"TOPSIDES",ITEM:"Reinstated",UNIT:"EA",TOTAL:891,PLAN_CUM:308,DELTA:-260,REMAIN:843,ACTUAL_CUM:48,ACTUAL_LAST_WK:0,ACTUAL_THIS_WK:17,ACTUAL_LAST_MONTH:0,ACTUAL_THIS_MONTH:17},
  {DISC_DESCR:"Piping",AREA:"OVERALL",ITEM:"Reinstated",UNIT:"EA",TOTAL:2799,PLAN_CUM:910,DELTA:-627,REMAIN:2516,ACTUAL_CUM:283,ACTUAL_LAST_WK:8,ACTUAL_THIS_WK:77,ACTUAL_LAST_MONTH:11,ACTUAL_THIS_MONTH:113}],
  Queries: {
    SQL1: [
      {DISC_DESCR:"Piping",ITEM:"Spool Fabricated",TOTAL:36651,COMPLETED:34875,REMAIN:1776},
      {DISC_DESCR:"Piping",ITEM:"Spool Erected",TOTAL:36651,COMPLETED:30306,REMAIN:6345},
      {DISC_DESCR:"Piping",ITEM:"Spool Welded/Bolted",TOTAL:36651,COMPLETED:26326,REMAIN:10325},
      {DISC_DESCR:"Piping",ITEM:"Spool Supported",TOTAL:36651,COMPLETED:23987,REMAIN:12664},
      {DISC_DESCR:"Piping",ITEM:"Spool RFT",TOTAL:36651,COMPLETED:19746,REMAIN:16905},
      {DISC_DESCR:"Piping",ITEM:"Tested",TOTAL:2412,COMPLETED:437,REMAIN:1975},
      {DISC_DESCR:"Piping",ITEM:"Reinstated",TOTAL:2412,COMPLETED:22,REMAIN:2390}
    ],
  },   
}