import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 860,
      CanvasHeight: 300,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

    
    DataItems: [
      {title:"SH No.",value:"21-6600"},
      {title:"SH Description",value:"Emergency & Essential Power"},
      {title:"System No.",value:"21-6600-6676"},
      {title:"System Description",value:"Normal & Essential Lighting an"},
      {title:"Subsystem No.",value:"21-6600-6676-02"},
      {title:"Subsystem Description",value:"Normal Lighting Panel 02 (ROOM"},
      {title:"Priority",value:"P2"},
      {title:"Completion Lead",value:"F. Zhang"},
      {title:"Construction Lead",value:"C.Bosse / G.Lejosne"},
      {title:"Commissioning Lead",value:"Anis Ben Miled"},
      {title:"RFI",value:"2021-06-30"},
      {title:"WD Date",value:"2021-07-30"},
      {title:"WD Actual",value:"PENDING WALKDOWN"},
      {title:"MC Plan",value:"2021-08-13"},
      {title:"MC",value:"NOT STARTED"},
      {title:"MC Actual",value:"2021-05-30"},
      {title:"MC Approved",value:"2021-04-30"}
        
    ],
    

    Queries: {
      // ITR B Overall
      SQL1: [
        {TOTAL:57,ACTUAL:0,REMAIN:57,PROG:30}
      ],
      // Punch Status
      SQL2:  [
        {CAT:"B3",TOTAL:2,ACTUAL:0,REMAIN:2,PROG:0},
        {CAT:"A",TOTAL:1192,ACTUAL:652,REMAIN:540,PROG:54.69},
        {CAT:"B2",TOTAL:60,ACTUAL:23,REMAIN:37,PROG:38.33},
        {CAT:"B1",TOTAL:1580,ACTUAL:279,REMAIN:1301,PROG:17.65}
        ],
      // RFDC
      SQL3: [
        {TOTAL:24,ACTUAL:0,REMAIN:24,PROG:0}
      ],
      // CTP
      SQL4: [
        {TOTAL:24,ACTUAL:0,REMAIN:24,PROG:0}
      ]
    },
}