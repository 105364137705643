import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 600,
    CanvasHeight: 280,
    CanvasBorderWeight: 0.3,
    CanvasBorderColor: 'Transparent',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.2,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 310,
    CanvasChartHeight: 270,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: 'Transparent',
    CanvasChartFillColor: 'Transparent',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },

    // ----------------------------------------------- NOTE
  Note: {
    NoteDisplay: 'N',
    NoteX: 50,
    NoteY: 420,
    NoteAlign: 'start',
    NoteTitleText: 'Notes.',
    NoteTitleFont: 'roboto',
    NoteTitleStyle: 'regular',
    NoteTitleSize: 11,
    NoteTitleColor: '#757575',

    NoteContentsText: 'Note Contents Here!',
    NoteContentsFont: 'roboto',
    NoteContentsStyle: 'regular',
    NoteContentsSize: 10,
    NoteContentsColor: '#BDBCBC',
  },

  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  DataItems: [
    {PHASE_SEQ:4,PHASE:"6.Integration",DISC:"Electrical",PLAN_WK:2.45,ACTUAL_WK:0.79,ACTUAL_CERT_WK:3.63,VAR_WK:-1.65,PLAN_CUM:50.48,ACTUAL_CUM:50.4,ACTUAL_CERT_CUM:3.67,VAR_CUM:-0.08},
    {PHASE_SEQ:4,PHASE:"6.Integration",DISC:"Fire & Safety",PLAN_WK:0,ACTUAL_WK:0,ACTUAL_CERT_WK:0,VAR_WK:0,PLAN_CUM:0,ACTUAL_CUM:0,ACTUAL_CERT_CUM:0,VAR_CUM:0},
    {PHASE_SEQ:4,PHASE:"6.Integration",DISC:"Instrument",PLAN_WK:0.94,ACTUAL_WK:3.58,ACTUAL_CERT_WK:0.06,VAR_WK:2.64,PLAN_CUM:36.33,ACTUAL_CUM:37.25,ACTUAL_CERT_CUM:0.06,VAR_CUM:0.91},
    {PHASE_SEQ:4,PHASE:"6.Integration",DISC:"Insulation",PLAN_WK:0,ACTUAL_WK:0,ACTUAL_CERT_WK:0,VAR_WK:0,PLAN_CUM:0,ACTUAL_CUM:0,ACTUAL_CERT_CUM:0,VAR_CUM:0},
    {PHASE_SEQ:4,PHASE:"6.Integration",DISC:"Painting",PLAN_WK:1.01,ACTUAL_WK:2.47,ACTUAL_CERT_WK:0,VAR_WK:1.45,PLAN_CUM:15.61,ACTUAL_CUM:14.27,ACTUAL_CERT_CUM:1.33,VAR_CUM:-1.33},
    {PHASE_SEQ:4,PHASE:"6.Integration",DISC:"Piping",PLAN_WK:2.45,ACTUAL_WK:2.15,ACTUAL_CERT_WK:0.29,VAR_WK:-0.29,PLAN_CUM:48.95,ACTUAL_CUM:56.81,ACTUAL_CERT_CUM:23.57,VAR_CUM:7.85},
    {PHASE_SEQ:4,PHASE:"6.Integration",DISC:"Structure",PLAN_WK:1.85,ACTUAL_WK:0,ACTUAL_CERT_WK:0,VAR_WK:-1.85,PLAN_CUM:95.31,ACTUAL_CUM:79.18,ACTUAL_CERT_CUM:41.12,VAR_CUM:-16.13},
    {PHASE_SEQ:4,PHASE:"6.Integration",DISC:"Total",PLAN_WK:1.79,ACTUAL_WK:1.95,ACTUAL_CERT_WK:0.45,VAR_WK:0.15,PLAN_CUM:48.7,ACTUAL_CUM:48.48,ACTUAL_CERT_CUM:16.04,VAR_CUM:-0.21}
  ]
}