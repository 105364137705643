import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 860,
      CanvasHeight: 250,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     

    DataItems: [
      { title: 'System No.',            value: '1010', },
      { title: 'System Description',    value: 'Production Flowlines- FPU Components 1 & 2', },
      { title: 'Subsystem No.',         value: '1010-01', },
      { title: 'Subsystem Description', value: 'Production Flowlines 1', },
      { title: 'Discipline',            value: 'Process', },
      { title: 'System Owner',          value: 'David.Nealings@bp.com', },
      { title: 'Walkdown Date',         value: '2020-03-15', },
      { title: 'Walkdown Actual',       value: null, },
      { title: 'MC Date',               value: '2020-03-15', },
      { title: 'MC F/C',                value: '2020-03-15', },
      { title: 'MC Actual',             value: '2020-03-15', },
      { title: 'Commissioning Date',    value: '2020-03-15', },
      { title: 'COM F/C',               value: '2020-03-15', },
    ],


    Queries: {
      // ITR A Overall
      SQL1: [
              { TOTAL: 296, ACTUAL: 65, REMAIN: 231, PROG: 21.96, },
      ],

    },
}