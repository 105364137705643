import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 2300,
    CanvasHeight: 980,
    CanvasBorderWeight: 0.05,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.1,

    CanvasChartX: 50,
    CanvasChartY: 50,
    CanvasChartWidth: 480,
    CanvasChartHeight: 250,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  DataItems:  [
    {SUBSYSTEM:"21-1001-1001-01",SUBSYSTEM_DESCR:"Annulus Riser vent Monitoring System",BATCH:"Incentive 3",LOSH:"Y",MC_PLAN:"2022-08-04",A_OV_TOT:157,A_OV_COM:109,A_OV_REM:48,A_OV_PROG:69.42,A_OV_STATUS:"less than 70%",A_PG:69.42,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:6,PA_OV_COM:0,PA_OV_REM:6,PB2_OV_TOT:6,PB2_OV_COM:1,PB2_OV_REM:5,ACT_TAG_VALID:32,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:9},
    {SUBSYSTEM:"21-1010-1011-01",SUBSYSTEM_DESCR:"Flowline 1 and Receiver",BATCH:"Incentive 3",LOSH:"Y",MC_PLAN:"2022-08-04",A_OV_TOT:467,A_OV_COM:233,A_OV_REM:234,A_OV_PROG:49.89,A_OV_STATUS:"less than 70%",A_PG:49.89,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:14,PA_OV_COM:8,PA_OV_REM:6,PB2_OV_TOT:20,PB2_OV_COM:15,PB2_OV_REM:5,ACT_TAG_VALID:224,PUNCH_NUMBER_VALID:34,PUNCH_TAG_VALID:24,EXCEPTION_A_OV_REM:196},
    {SUBSYSTEM:"21-1010-1012-01",SUBSYSTEM_DESCR:"Flowline 2 and Receiver",BATCH:"Incentive 3",LOSH:"Y",MC_PLAN:"2022-08-04",A_OV_TOT:465,A_OV_COM:215,A_OV_REM:250,A_OV_PROG:46.23,A_OV_STATUS:"less than 70%",A_PG:46.23,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:5,PA_OV_COM:1,PA_OV_REM:4,PB2_OV_TOT:13,PB2_OV_COM:9,PB2_OV_REM:4,ACT_TAG_VALID:220,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:12,EXCEPTION_A_OV_REM:210},
    {SUBSYSTEM:"21-1090-1090-01",SUBSYSTEM_DESCR:"Production Control Umbilical",BATCH:"Incentive 3",MC_PLAN:"2022-08-04",A_OV_TOT:27,A_OV_COM:6,A_OV_REM:21,A_OV_PROG:22.22,A_OV_STATUS:"less than 70%",A_PG:22.22,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-1510-1510-01",SUBSYSTEM_DESCR:"MEG Riser and Pipeline System to DC1",BATCH:"Incentive 3",LOSH:"Y",MC_PLAN:"2022-08-04",A_OV_TOT:34,A_OV_COM:16,A_OV_REM:18,A_OV_PROG:47.05,A_OV_STATUS:"less than 70%",A_PG:47.05,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-1600-1611-01",SUBSYSTEM_DESCR:"Gas Export Riser 1",BATCH:"Incentive 3",LOSH:"Y",MC_PLAN:"2022-07-28",A_OV_TOT:86,A_OV_COM:51,A_OV_REM:35,A_OV_PROG:59.3,A_OV_STATUS:"less than 70%",A_PG:59.3,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PB2_OV_TOT:12,PB2_OV_COM:3,PB2_OV_REM:9,ACT_TAG_VALID:42,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-1600-1612-01",SUBSYSTEM_DESCR:"Gas Export Riser 2",BATCH:"Incentive 3",LOSH:"Y",MC_PLAN:"2022-07-28",A_OV_TOT:80,A_OV_COM:48,A_OV_REM:32,A_OV_PROG:60,A_OV_STATUS:"less than 70%",A_PG:60,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,PB2_OV_TOT:8,PB2_OV_COM:2,PB2_OV_REM:6,ACT_TAG_VALID:41,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-1911-1911-01",SUBSYSTEM_DESCR:"Hydraulic HPUs, RIOs and TUTUs",BATCH:"Incentive 3",LOSH:"Y",MC_PLAN:"2022-08-04",A_OV_TOT:111,A_OV_COM:42,A_OV_REM:69,A_OV_PROG:37.83,A_OV_STATUS:"less than 70%",A_PG:37.83,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PB2_OV_TOT:7,PB2_OV_COM:1,PB2_OV_REM:6,ACT_TAG_VALID:86,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-1931-1931-01",SUBSYSTEM_DESCR:"Subsea Control System ,MCS, SSIV SPCU, EPUs, HVE JBs and LVE JBs and Subsea ICSS",BATCH:"Incentive 3",MC_PLAN:"2022-07-07",A_OV_TOT:181,A_OV_COM:98,A_OV_REM:83,A_OV_PROG:54.14,A_OV_STATUS:"less than 70%",A_PG:54.14,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:15,PA_OV_COM:7,PA_OV_REM:8,PB1_OV_TOT:7,PB1_OV_COM:7,PB1_OV_REM:0,PB2_OV_TOT:27,PB2_OV_COM:7,PB2_OV_REM:20,ACT_TAG_VALID:180,PUNCH_NUMBER_VALID:49,PUNCH_TAG_VALID:13},
    {SUBSYSTEM:"21-2200-2131-01",SUBSYSTEM_DESCR:"Slug Catcher 1 and Liquid Heater 1",LOSH:"Y",MC_PLAN:"2022-06-02",A_OV_TOT:642,A_OV_COM:566,A_OV_REM:76,A_OV_PROG:88.16,A_OV_STATUS:"greater than 80%",A_PG:88.16,RFI_ISSUED:"Done",WD_ACTUAL:"2022-05-28",MC_APPROVED:"2022-05-31",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:25,PA_OV_COM:23,PA_OV_REM:2,PB1_OV_TOT:7,PB1_OV_COM:1,PB1_OV_REM:6,PB2_OV_TOT:92,PB2_OV_COM:47,PB2_OV_REM:45,ACT_TAG_VALID:287,PUNCH_NUMBER_VALID:124,PUNCH_TAG_VALID:44,EXCEPTION_A_OV_REM:56},
    {SUBSYSTEM:"21-2200-2132-01",SUBSYSTEM_DESCR:"Slug Catcher 2 and Liquid heater 2",LOSH:"Y",MC_PLAN:"2022-06-02",A_OV_TOT:629,A_OV_COM:558,A_OV_REM:71,A_OV_PROG:88.71,A_OV_STATUS:"greater than 80%",A_PG:88.71,RFI_ISSUED:"Done",WD_ACTUAL:"2022-05-28",MC_APPROVED:"2022-05-31",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:27,PA_OV_COM:25,PA_OV_REM:2,PB1_OV_TOT:2,PB1_OV_COM:1,PB1_OV_REM:1,PB2_OV_TOT:114,PB2_OV_COM:57,PB2_OV_REM:57,ACT_TAG_VALID:275,PUNCH_NUMBER_VALID:143,PUNCH_TAG_VALID:48,EXCEPTION_A_OV_REM:53},
    {SUBSYSTEM:"21-2200-2221-01",SUBSYSTEM_DESCR:"MP Separator",BATCH:"Incentive 3",LOSH:"Y",MC_PLAN:"2022-07-21",A_OV_TOT:350,A_OV_COM:317,A_OV_REM:33,A_OV_PROG:90.57,A_OV_STATUS:"greater than 90%",A_PG:90.57,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P2",PA_OV_TOT:13,PA_OV_COM:13,PA_OV_REM:0,PB2_OV_TOT:50,PB2_OV_COM:32,PB2_OV_REM:18,ACT_TAG_VALID:138,PUNCH_NUMBER_VALID:63,PUNCH_TAG_VALID:28},
    {SUBSYSTEM:"21-2200-2230-01",SUBSYSTEM_DESCR:"LP Separator",BATCH:"Incentive 3",LOSH:"Y",MC_PLAN:"2022-07-28",A_OV_TOT:274,A_OV_COM:251,A_OV_REM:23,A_OV_PROG:91.6,A_OV_STATUS:"greater than 90%",A_PG:91.6,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B2",PRIORITY:"P3",PA_OV_TOT:16,PA_OV_COM:16,PA_OV_REM:0,PB2_OV_TOT:44,PB2_OV_COM:35,PB2_OV_REM:9,ACT_TAG_VALID:103,PUNCH_NUMBER_VALID:60,PUNCH_TAG_VALID:30},
    {SUBSYSTEM:"21-2200-2230-02",SUBSYSTEM_DESCR:"Interstage Heaters",LOSH:"Y",MC_PLAN:"2022-06-09",A_OV_TOT:102,A_OV_COM:98,A_OV_REM:4,A_OV_PROG:96.07,A_OV_STATUS:"greater than 95%",A_PG:96.07,RFI_ISSUED:"Done",WD_ACTUAL:"2022-06-02",MC_APPROVED:"2022-06-08",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P3",PA_OV_TOT:7,PA_OV_COM:7,PA_OV_REM:0,PB2_OV_TOT:49,PB2_OV_COM:33,PB2_OV_REM:16,ACT_TAG_VALID:27,PUNCH_NUMBER_VALID:56,PUNCH_TAG_VALID:15,EXCEPTION_A_OV_REM:4},
    {SUBSYSTEM:"21-2200-2240-01",SUBSYSTEM_DESCR:"LLP separator, Condensate Coolers and Condensate Coalescer Pumps",BATCH:"Incentive 3",LOSH:"Y",MC_PLAN:"2022-07-21",A_OV_TOT:411,A_OV_COM:366,A_OV_REM:45,A_OV_PROG:89.05,A_OV_STATUS:"greater than 80%",A_PG:89.05,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:36,PA_OV_COM:22,PA_OV_REM:14,PB1_OV_TOT:3,PB1_OV_COM:3,PB1_OV_REM:0,PB2_OV_TOT:91,PB2_OV_COM:48,PB2_OV_REM:43,ACT_TAG_VALID:165,PUNCH_NUMBER_VALID:130,PUNCH_TAG_VALID:52},
    {SUBSYSTEM:"21-2200-2240-03",SUBSYSTEM_DESCR:"Condensate Coalescer Filters",BATCH:"Incentive 3",LOSH:"Y",MC_PLAN:"2022-07-21",A_OV_TOT:160,A_OV_COM:141,A_OV_REM:19,A_OV_PROG:88.12,A_OV_STATUS:"greater than 80%",A_PG:88.12,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:9,PA_OV_COM:5,PA_OV_REM:4,PB1_OV_TOT:2,PB1_OV_COM:1,PB1_OV_REM:1,PB2_OV_TOT:27,PB2_OV_COM:14,PB2_OV_REM:13,ACT_TAG_VALID:48,PUNCH_NUMBER_VALID:38,PUNCH_TAG_VALID:16},
    {SUBSYSTEM:"21-2200-2240-04",SUBSYSTEM_DESCR:"Condensate Coalescer Package",BATCH:"Incentive 3",LOSH:"Y",MC_PLAN:"2022-07-21",A_OV_TOT:163,A_OV_COM:150,A_OV_REM:13,A_OV_PROG:92.02,A_OV_STATUS:"greater than 90%",A_PG:92.02,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:8,PA_OV_COM:6,PA_OV_REM:2,PB1_OV_TOT:4,PB1_OV_COM:3,PB1_OV_REM:1,PB2_OV_TOT:24,PB2_OV_COM:14,PB2_OV_REM:10,ACT_TAG_VALID:56,PUNCH_NUMBER_VALID:36,PUNCH_TAG_VALID:18},
    {SUBSYSTEM:"21-2200-2470-01",SUBSYSTEM_DESCR:"Warm Condensate Heater, Offspec Condensate Pump and Distribution",LOSH:"Y",MC_PLAN:"2022-06-09",A_OV_TOT:334,A_OV_COM:286,A_OV_REM:48,A_OV_PROG:85.62,A_OV_STATUS:"greater than 80%",A_PG:85.62,RFI_ISSUED:"Done",WD_ACTUAL:"2022-06-02",MC_APPROVED:"2022-06-09",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:40,PA_OV_COM:36,PA_OV_REM:4,PB1_OV_TOT:2,PB1_OV_COM:1,PB1_OV_REM:1,PB2_OV_TOT:99,PB2_OV_COM:35,PB2_OV_REM:64,ACT_TAG_VALID:141,PUNCH_NUMBER_VALID:141,PUNCH_TAG_VALID:42,EXCEPTION_A_OV_REM:46},
    {SUBSYSTEM:"21-2400-2440-01",SUBSYSTEM_DESCR:"Condensate Metering",BATCH:"Incentive 3",LOSH:"Y",MC_PLAN:"2022-07-28",A_OV_TOT:158,A_OV_COM:119,A_OV_REM:39,A_OV_PROG:75.31,A_OV_STATUS:"NA",A_PG:75.31,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:17,PA_OV_COM:9,PA_OV_REM:8,PB1_OV_TOT:18,PB1_OV_COM:7,PB1_OV_REM:11,PB2_OV_TOT:43,PB2_OV_COM:17,PB2_OV_REM:26,PB3_OV_TOT:2,PB3_OV_COM:1,PB3_OV_REM:1,ACT_TAG_VALID:98,PUNCH_NUMBER_VALID:80,PUNCH_TAG_VALID:21,EXCEPTION_PA_OV_REM:1,EXCEPTION_A_OV_REM:5},
    {SUBSYSTEM:"21-2400-2450-01",SUBSYSTEM_DESCR:"CFW1 Offspec Condensate Tank and Pumps ,Incl Washing Machine",BATCH:"Incentive 3",LOSH:"Y",MC_PLAN:"2022-07-14",A_OV_TOT:352,A_OV_COM:344,A_OV_REM:8,A_OV_PROG:97.72,A_OV_STATUS:"greater than 95%",A_PG:97.72,RFI_ISSUED:"Done",WD_ACTUAL:"2022-07-11",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:66,PA_OV_COM:63,PA_OV_REM:3,PB1_OV_TOT:10,PB1_OV_COM:3,PB1_OV_REM:7,PB2_OV_TOT:153,PB2_OV_COM:71,PB2_OV_REM:82,ACT_TAG_VALID:133,PUNCH_NUMBER_VALID:229,PUNCH_TAG_VALID:44,EXCEPTION_PA_OV_REM:0,EXCEPTION_A_OV_REM:6},
    {SUBSYSTEM:"21-2400-2460-01",SUBSYSTEM_DESCR:"Condensate Offloading",BATCH:"Incentive 3",LOSH:"Y",MC_PLAN:"2022-07-07",A_OV_TOT:196,A_OV_COM:109,A_OV_REM:87,A_OV_PROG:55.61,A_OV_STATUS:"less than 70%",A_PG:55.61,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:7,PA_OV_COM:6,PA_OV_REM:1,PB1_OV_TOT:7,PB1_OV_COM:5,PB1_OV_REM:2,PB2_OV_TOT:14,PB2_OV_COM:5,PB2_OV_REM:9,ACT_TAG_VALID:110,PUNCH_NUMBER_VALID:28,PUNCH_TAG_VALID:10},
    {SUBSYSTEM:"21-2400-9360-01",SUBSYSTEM_DESCR:"Cargo Oil Washing Header and Distribution",LOSH:"Y",MC_PLAN:"2022-06-30",A_OV_TOT:34,A_OV_COM:34,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"2022-06-27",MC_APPROVED:"2022-07-08",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:30,PA_OV_COM:28,PA_OV_REM:2,PB2_OV_TOT:44,PB2_OV_COM:16,PB2_OV_REM:28,ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:74,PUNCH_TAG_VALID:10,EXCEPTION_PA_OV_REM:1,EXCEPTION_A_OV_REM:0},
    {SUBSYSTEM:"21-2400-9360-02",SUBSYSTEM_DESCR:"Condensate ImportandLoading Header",LOSH:"Y",MC_PLAN:"2022-06-30",A_OV_TOT:80,A_OV_COM:70,A_OV_REM:10,A_OV_PROG:87.5,A_OV_STATUS:"greater than 80%",A_PG:87.5,RFI_ISSUED:"Done",WD_ACTUAL:"2022-07-07",MC_APPROVED:"2022-07-19",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:13,PA_OV_COM:10,PA_OV_REM:3,PB2_OV_TOT:35,PB2_OV_COM:5,PB2_OV_REM:30,ACT_TAG_VALID:44,PUNCH_NUMBER_VALID:48,PUNCH_TAG_VALID:14,EXCEPTION_PA_OV_REM:2,EXCEPTION_A_OV_REM:10},
    {SUBSYSTEM:"21-2400-9360-03",SUBSYSTEM_DESCR:"Condensate Transfer Header",LOSH:"Y",MC_PLAN:"2022-06-30",A_OV_TOT:341,A_OV_COM:330,A_OV_REM:11,A_OV_PROG:96.77,A_OV_STATUS:"greater than 95%",A_PG:96.77,RFI_ISSUED:"Done",WD_ACTUAL:"2022-07-07",MC_APPROVED:"2022-07-19",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:71,PA_OV_COM:70,PA_OV_REM:1,PB2_OV_TOT:242,PB2_OV_COM:88,PB2_OV_REM:154,ACT_TAG_VALID:210,PUNCH_NUMBER_VALID:313,PUNCH_TAG_VALID:74,EXCEPTION_PA_OV_REM:0,EXCEPTION_A_OV_REM:11},
    {SUBSYSTEM:"21-2400-9360-04",SUBSYSTEM_DESCR:"Condensate ExportandOffloading Header",LOSH:"Y",MC_PLAN:"2022-06-30",A_OV_TOT:220,A_OV_COM:203,A_OV_REM:17,A_OV_PROG:92.27,A_OV_STATUS:"greater than 90%",A_PG:92.27,RFI_ISSUED:"Done",WD_ACTUAL:"2022-07-09",MC_APPROVED:"2022-07-20",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:44,PA_OV_COM:39,PA_OV_REM:5,PB2_OV_TOT:87,PB2_OV_COM:26,PB2_OV_REM:61,ACT_TAG_VALID:96,PUNCH_NUMBER_VALID:131,PUNCH_TAG_VALID:35,EXCEPTION_PA_OV_REM:4,EXCEPTION_A_OV_REM:17},
    {SUBSYSTEM:"21-2400-9361-01",SUBSYSTEM_DESCR:"COW1 Tank and Pumps ,Incl Washing Machine",LOSH:"Y",MC_PLAN:"2022-06-23",A_OV_TOT:202,A_OV_COM:201,A_OV_REM:1,A_OV_PROG:99.5,A_OV_STATUS:"greater than 95%",A_PG:99.5,RFI_ISSUED:"Done",WD_ACTUAL:"2022-06-29",MC_APPROVED:"2022-07-06",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:31,PA_OV_COM:28,PA_OV_REM:3,PB1_OV_TOT:5,PB1_OV_COM:2,PB1_OV_REM:3,PB2_OV_TOT:95,PB2_OV_COM:49,PB2_OV_REM:46,PB3_OV_TOT:6,PB3_OV_COM:0,PB3_OV_REM:6,ACT_TAG_VALID:73,PUNCH_NUMBER_VALID:137,PUNCH_TAG_VALID:34,EXCEPTION_PA_OV_REM:3,EXCEPTION_A_OV_REM:1},
    {SUBSYSTEM:"21-2400-9362-01",SUBSYSTEM_DESCR:"COW2 Tank and Pumps ,Incl Washing Machine",LOSH:"Y",MC_PLAN:"2022-06-23",A_OV_TOT:202,A_OV_COM:200,A_OV_REM:2,A_OV_PROG:99,A_OV_STATUS:"greater than 95%",A_PG:99,RFI_ISSUED:"Done",WD_ACTUAL:"2022-06-30",MC_APPROVED:"2022-07-06",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:25,PA_OV_COM:24,PA_OV_REM:1,PB1_OV_TOT:16,PB1_OV_COM:13,PB1_OV_REM:3,PB2_OV_TOT:74,PB2_OV_COM:40,PB2_OV_REM:34,PB3_OV_TOT:1,PB3_OV_COM:0,PB3_OV_REM:1,ACT_TAG_VALID:73,PUNCH_NUMBER_VALID:116,PUNCH_TAG_VALID:27,EXCEPTION_PA_OV_REM:1,EXCEPTION_A_OV_REM:2},
    {SUBSYSTEM:"21-2400-9363-01",SUBSYSTEM_DESCR:"COW3 Tank and Pumps ,Incl Washing Machine",LOSH:"Y",MC_PLAN:"2022-06-23",A_OV_TOT:201,A_OV_COM:198,A_OV_REM:3,A_OV_PROG:98.5,A_OV_STATUS:"greater than 95%",A_PG:98.5,RFI_ISSUED:"Done",WD_ACTUAL:"2022-06-30",MC_APPROVED:"2022-07-06",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:23,PA_OV_COM:23,PA_OV_REM:0,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,PB2_OV_TOT:77,PB2_OV_COM:35,PB2_OV_REM:42,PB3_OV_TOT:7,PB3_OV_COM:0,PB3_OV_REM:7,ACT_TAG_VALID:73,PUNCH_NUMBER_VALID:110,PUNCH_TAG_VALID:26,EXCEPTION_PA_OV_REM:0,EXCEPTION_A_OV_REM:3},
    {SUBSYSTEM:"21-2400-9364-01",SUBSYSTEM_DESCR:"COW4 Tank and Pumps ,Incl Washing Machine",LOSH:"Y",MC_PLAN:"2022-06-23",A_OV_TOT:195,A_OV_COM:192,A_OV_REM:3,A_OV_PROG:98.46,A_OV_STATUS:"greater than 95%",A_PG:98.46,RFI_ISSUED:"Done",WD_ACTUAL:"2022-07-04",MC_APPROVED:"2022-07-12",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:32,PA_OV_COM:31,PA_OV_REM:1,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,PB2_OV_TOT:79,PB2_OV_COM:32,PB2_OV_REM:47,PB3_OV_TOT:8,PB3_OV_COM:0,PB3_OV_REM:8,ACT_TAG_VALID:72,PUNCH_NUMBER_VALID:122,PUNCH_TAG_VALID:25,EXCEPTION_PA_OV_REM:1,EXCEPTION_A_OV_REM:3},
    {SUBSYSTEM:"21-2400-9365-01",SUBSYSTEM_DESCR:"COE1 Tank and Pumps ,Incl Washing Machine",LOSH:"Y",MC_PLAN:"2022-06-30",A_OV_TOT:188,A_OV_COM:187,A_OV_REM:1,A_OV_PROG:99.46,A_OV_STATUS:"greater than 95%",A_PG:99.46,RFI_ISSUED:"Done",WD_ACTUAL:"2022-06-30",MC_APPROVED:"2022-07-06",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:25,PA_OV_COM:25,PA_OV_REM:0,PB1_OV_TOT:8,PB1_OV_COM:5,PB1_OV_REM:3,PB2_OV_TOT:106,PB2_OV_COM:45,PB2_OV_REM:61,ACT_TAG_VALID:71,PUNCH_NUMBER_VALID:139,PUNCH_TAG_VALID:25,EXCEPTION_PA_OV_REM:0,EXCEPTION_A_OV_REM:1}
    ],
  Queries: {
    SQL1: [
      { DESC: 'DataSource SPCS Data 03-Mar-20' }
    ],
    SQL2: [
      { CDATE: '2021-08-20' }
    ],
    SQL3: [
      {MC_PLAN:"2021-08-13",A_T:1552,A_A:555,A_C:482,A_O:997,A_PG:35.76,A_CUM:555,A_CUM_PG:0.69,PLAN_CUM:68693.57333048206,ACTUAL_CUM:68857,PA_O:18,PB1_O:97,PB2_O:19,PB3_O:0},
      {MC_PLAN:"2021-08-20",A_T:1119,A_A:102,A_C:102,A_O:1017,A_PG:9.12,A_CUM:657,A_CUM_PG:0.82,PLAN_CUM:67918.92722081483,ACTUAL_CUM:68741,PA_O:5,PB1_O:17,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-08-27",A_T:715,A_A:93,A_C:10,A_O:622,A_PG:13.01,A_CUM:750,A_CUM_PG:0.94,PLAN_CUM:67240.46292932943,ACTUAL_CUM:69748,PA_O:4,PB1_O:29,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-09-03",A_T:6242,A_A:0,A_C:0,A_O:6242,A_PG:0,A_CUM:750,A_CUM_PG:0.94,PLAN_CUM:66318.65409772044,ACTUAL_CUM:69904,PA_O:6,PB1_O:14,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-09-10",A_T:1556,A_A:87,A_C:19,A_O:1469,A_PG:5.59,A_CUM:837,A_CUM_PG:1.04,PLAN_CUM:65233.2176593381,PA_O:12,PB1_O:17,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-09-17",A_T:1580,A_A:48,A_C:0,A_O:1532,A_PG:3.04,A_CUM:885,A_CUM_PG:1.1,PLAN_CUM:63979.15028084907,PA_O:11,PB1_O:18,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-09-24",A_T:1124,A_A:98,A_C:23,A_O:1026,A_PG:8.72,A_CUM:983,A_CUM_PG:1.23,PLAN_CUM:62637.78967802465,PA_O:5,PB1_O:0,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-10-01",A_T:1245,A_A:70,A_C:20,A_O:1175,A_PG:5.62,A_CUM:1053,A_CUM_PG:1.31,PLAN_CUM:61174.48720221619,PA_O:8,PB1_O:0,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-10-08",A_T:423,A_A:79,A_C:26,A_O:344,A_PG:18.68,A_CUM:1132,A_CUM_PG:1.41,PLAN_CUM:59747.8308802539,PA_O:16,PB1_O:2,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-10-15",A_T:973,A_A:51,A_C:16,A_O:922,A_PG:5.24,A_CUM:1183,A_CUM_PG:1.48,PLAN_CUM:58060.89324121612,PA_O:25,PB1_O:9,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-10-22",A_T:1031,A_A:120,A_C:66,A_O:911,A_PG:11.64,A_CUM:1303,A_CUM_PG:1.63,PLAN_CUM:56248.08186559112,PA_O:18,PB1_O:0,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-10-29",A_T:3245,A_A:63,A_C:25,A_O:3182,A_PG:1.94,A_CUM:1366,A_CUM_PG:1.7,PLAN_CUM:54322.08696824535,PA_O:9,PB1_O:1,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-11-05",A_T:2238,A_A:138,A_C:19,A_O:2100,A_PG:6.17,A_CUM:1504,A_CUM_PG:1.88,PLAN_CUM:52329.74427595451,PA_O:30,PB1_O:7,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-11-12",A_T:1838,A_A:449,A_C:0,A_O:1389,A_PG:24.43,A_CUM:1953,A_CUM_PG:2.44,PLAN_CUM:50230.04388524004,PA_O:1,PB1_O:0,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-11-19",A_T:4904,A_A:535,A_C:57,A_O:4369,A_PG:10.91,A_CUM:2488,A_CUM_PG:3.1,PLAN_CUM:47971.9976513864,PA_O:65,PB1_O:1,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-11-26",A_T:5082,A_A:957,A_C:58,A_O:4125,A_PG:18.83,A_CUM:3445,A_CUM_PG:4.3,PLAN_CUM:45613.47224030309,PA_O:50,PB1_O:0,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-12-03",A_T:1723,A_A:161,A_C:0,A_O:1562,A_PG:9.34,A_CUM:3606,A_CUM_PG:4.5,PLAN_CUM:43362.54333082551,PA_O:14,PB1_O:4,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-12-10",A_T:2281,A_A:313,A_C:14,A_O:1968,A_PG:13.72,A_CUM:3919,A_CUM_PG:4.89,PLAN_CUM:41171.62579251709,PA_O:18,PB1_O:0,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-12-17",A_T:4857,A_A:714,A_C:90,A_O:4143,A_PG:14.7,A_CUM:4633,A_CUM_PG:5.78,PLAN_CUM:39052.72167713079,PA_O:9,PB1_O:3,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-12-24",A_T:1246,A_A:98,A_C:0,A_O:1148,A_PG:7.87,A_CUM:4731,A_CUM_PG:5.9,PLAN_CUM:37024.87512187446,PA_O:3,PB1_O:0,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-12-31",A_T:2719,A_A:394,A_C:24,A_O:2325,A_PG:14.49,A_CUM:5125,A_CUM_PG:6.4,PLAN_CUM:35173.14904014867,PA_O:24,PB1_O:10,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-01-07",A_T:1144,A_A:294,A_C:199,A_O:850,A_PG:25.7,A_CUM:5419,A_CUM_PG:6.76,PLAN_CUM:33502.62818507096,PA_O:0,PB1_O:8,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-01-14",A_T:1986,A_A:184,A_C:33,A_O:1802,A_PG:9.26,A_CUM:5603,A_CUM_PG:6.99,PLAN_CUM:31955.06175501426},
      {MC_PLAN:"2022-01-21",A_T:421,A_A:222,A_C:3,A_O:199,A_PG:52.73,A_CUM:5825,A_CUM_PG:7.27,PLAN_CUM:30639.13179909264,PA_O:11,PB1_O:0,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-01-28",A_T:465,A_A:58,A_C:11,A_O:407,A_PG:12.47,A_CUM:5883,A_CUM_PG:7.34,PLAN_CUM:29360.39992929543,PA_O:3,PB1_O:1,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-02-04",A_T:6,A_A:2,A_C:0,A_O:4,A_PG:33.33,A_CUM:5885,A_CUM_PG:7.34,PLAN_CUM:28285.14796386291,PA_O:0,PB1_O:1,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-02-11",A_T:1544,A_A:121,A_C:2,A_O:1423,A_PG:7.84,A_CUM:6006,A_CUM_PG:7.5,PLAN_CUM:27168.02656112659,PA_O:9,PB1_O:2,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-02-18",A_T:1792,A_A:189,A_C:4,A_O:1603,A_PG:10.55,A_CUM:6195,A_CUM_PG:7.73,PLAN_CUM:25944.39224863975,PA_O:9,PB1_O:3,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-02-25",A_T:1171,A_A:116,A_C:27,A_O:1055,A_PG:9.91,A_CUM:6311,A_CUM_PG:7.88,PLAN_CUM:24634.19008777003,PA_O:1,PB1_O:0,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-03-04",A_T:443,A_A:57,A_C:0,A_O:386,A_PG:12.87,A_CUM:6368,A_CUM_PG:7.95,PLAN_CUM:23287.55225129644,PA_O:0,PB1_O:0,PB2_O:0,PB3_O:0}
    ],
    SQL4: [
      {CUTOFF:"2018-06-22",WK_NO:"W2018_25"},
      {CUTOFF:"2018-06-29",WK_NO:"W2018_26"},
      {CUTOFF:"2018-07-06",WK_NO:"W2018_27"},
      {CUTOFF:"2018-07-13",WK_NO:"W2018_28"},
      {CUTOFF:"2018-07-20",WK_NO:"W2018_29"},
      {CUTOFF:"2018-07-27",WK_NO:"W2018_30"},
      {CUTOFF:"2018-08-03",WK_NO:"W2018_31"},
      {CUTOFF:"2018-08-10",WK_NO:"W2018_32"},
      {CUTOFF:"2018-08-17",WK_NO:"W2018_33"},
      {CUTOFF:"2018-08-24",WK_NO:"W2018_34"},
      {CUTOFF:"2018-08-31",WK_NO:"W2018_35"},
      {CUTOFF:"2018-09-07",WK_NO:"W2018_36"},
      {CUTOFF:"2018-09-14",WK_NO:"W2018_37"},
      {CUTOFF:"2018-09-21",WK_NO:"W2018_38"},
      {CUTOFF:"2018-09-28",WK_NO:"W2018_39"},
      {CUTOFF:"2018-10-05",WK_NO:"W2018_40"},
      {CUTOFF:"2018-10-12",WK_NO:"W2018_41"},
      {CUTOFF:"2018-10-19",WK_NO:"W2018_42"},
      {CUTOFF:"2018-10-26",WK_NO:"W2018_43"},
      {CUTOFF:"2018-11-02",WK_NO:"W2018_44"},
      {CUTOFF:"2018-11-09",WK_NO:"W2018_45"},
      {CUTOFF:"2018-11-16",WK_NO:"W2018_46"},
      {CUTOFF:"2018-11-23",WK_NO:"W2018_47"},
      {CUTOFF:"2018-11-30",WK_NO:"W2018_48"},
      {CUTOFF:"2018-12-07",WK_NO:"W2018_49"},
      {CUTOFF:"2018-12-14",WK_NO:"W2018_50"},
      {CUTOFF:"2018-12-21",WK_NO:"W2018_51"},
      {CUTOFF:"2018-12-28",WK_NO:"W2018_52"},
      {CUTOFF:"2019-01-04",WK_NO:"W2019_01"},
      {CUTOFF:"2019-01-11",WK_NO:"W2019_02"},
      {CUTOFF:"2019-01-18",WK_NO:"W2019_03"},
      {CUTOFF:"2019-01-25",WK_NO:"W2019_04"},
      {CUTOFF:"2019-02-01",WK_NO:"W2019_05"},
      {CUTOFF:"2019-02-08",WK_NO:"W2019_06"},
      {CUTOFF:"2019-02-15",WK_NO:"W2019_07"},
      {CUTOFF:"2019-02-22",WK_NO:"W2019_08"},
      {CUTOFF:"2019-03-01",WK_NO:"W2019_09"},
      {CUTOFF:"2019-03-08",WK_NO:"W2019_10"},
      {CUTOFF:"2019-03-15",WK_NO:"W2019_11"},
      {CUTOFF:"2019-03-22",WK_NO:"W2019_12"},
      {CUTOFF:"2019-03-29",WK_NO:"W2019_13"},
      {CUTOFF:"2019-04-05",WK_NO:"W2019_14"},
      {CUTOFF:"2019-04-12",WK_NO:"W2019_15"},
      {CUTOFF:"2019-04-19",WK_NO:"W2019_16"},
      {CUTOFF:"2019-04-26",WK_NO:"W2019_17"},
      {CUTOFF:"2019-05-03",WK_NO:"W2019_18"},
      {CUTOFF:"2019-05-10",WK_NO:"W2019_19"},
      {CUTOFF:"2019-05-17",WK_NO:"W2019_20"},
      {CUTOFF:"2019-05-24",WK_NO:"W2019_21"},
      {CUTOFF:"2019-05-31",WK_NO:"W2019_22"},
      {CUTOFF:"2019-06-07",WK_NO:"W2019_23"},
      {CUTOFF:"2019-06-14",WK_NO:"W2019_24"},
      {CUTOFF:"2019-06-21",WK_NO:"W2019_25"},
      {CUTOFF:"2019-06-28",WK_NO:"W2019_26"},
      {CUTOFF:"2019-07-05",WK_NO:"W2019_27"},
      {CUTOFF:"2019-07-12",WK_NO:"W2019_28"},
      {CUTOFF:"2019-07-19",WK_NO:"W2019_29"},
      {CUTOFF:"2019-07-26",WK_NO:"W2019_30"},
      {CUTOFF:"2019-08-02",WK_NO:"W2019_31"},
      {CUTOFF:"2019-08-09",WK_NO:"W2019_32"},
      {CUTOFF:"2019-08-16",WK_NO:"W2019_33"},
      {CUTOFF:"2019-08-23",WK_NO:"W2019_34"},
      {CUTOFF:"2019-08-30",WK_NO:"W2019_35"},
      {CUTOFF:"2019-09-06",WK_NO:"W2019_36"},
      {CUTOFF:"2019-09-13",WK_NO:"W2019_37"},
      {CUTOFF:"2019-09-20",WK_NO:"W2019_38"},
      {CUTOFF:"2019-09-27",WK_NO:"W2019_39"},
      {CUTOFF:"2019-10-04",WK_NO:"W2019_40"},
      {CUTOFF:"2019-10-11",WK_NO:"W2019_41"},
      {CUTOFF:"2019-10-18",WK_NO:"W2019_42"},
      {CUTOFF:"2019-10-25",WK_NO:"W2019_43"},
      {CUTOFF:"2019-11-01",WK_NO:"W2019_44"},
      {CUTOFF:"2019-11-08",WK_NO:"W2019_45"},
      {CUTOFF:"2019-11-15",WK_NO:"W2019_46"},
      {CUTOFF:"2019-11-22",WK_NO:"W2019_47"},
      {CUTOFF:"2019-11-29",WK_NO:"W2019_48"},
      {CUTOFF:"2019-12-06",WK_NO:"W2019_49"},
      {CUTOFF:"2019-12-13",WK_NO:"W2019_50"},
      {CUTOFF:"2019-12-20",WK_NO:"W2019_51"},
      {CUTOFF:"2019-12-27",WK_NO:"W2019_52"},
      {CUTOFF:"2020-01-03",WK_NO:"W2020_01"},
      {CUTOFF:"2020-01-10",WK_NO:"W2020_02"},
      {CUTOFF:"2020-01-17",WK_NO:"W2020_03"},
      {CUTOFF:"2020-01-24",WK_NO:"W2020_04"},
      {CUTOFF:"2020-01-31",WK_NO:"W2020_05"},
      {CUTOFF:"2020-02-07",WK_NO:"W2020_06"},
      {CUTOFF:"2020-02-14",WK_NO:"W2020_07"},
      {CUTOFF:"2020-02-21",WK_NO:"W2020_08"},
      {CUTOFF:"2020-02-28",WK_NO:"W2020_09"},
      {CUTOFF:"2020-03-06",WK_NO:"W2020_10"},
      {CUTOFF:"2020-03-13",WK_NO:"W2020_11"},
      {CUTOFF:"2020-03-20",WK_NO:"W2020_12"},
      {CUTOFF:"2020-03-27",WK_NO:"W2020_13"},
      {CUTOFF:"2020-04-03",WK_NO:"W2020_14"},
      {CUTOFF:"2020-04-10",WK_NO:"W2020_15"},
      {CUTOFF:"2020-04-17",WK_NO:"W2020_16"},
      {CUTOFF:"2020-04-24",WK_NO:"W2020_17"},
      {CUTOFF:"2020-05-01",WK_NO:"W2020_18"},
      {CUTOFF:"2020-05-08",WK_NO:"W2020_19"},
      {CUTOFF:"2020-05-15",WK_NO:"W2020_20"},
      {CUTOFF:"2020-05-22",WK_NO:"W2020_21"},
      {CUTOFF:"2020-05-29",WK_NO:"W2020_22"},
      {CUTOFF:"2020-06-05",WK_NO:"W2020_23"},
      {CUTOFF:"2020-06-12",WK_NO:"W2020_24"},
      {CUTOFF:"2020-06-19",WK_NO:"W2020_25"},
      {CUTOFF:"2020-06-26",WK_NO:"W2020_26"},
      {CUTOFF:"2020-07-03",WK_NO:"W2020_27"},
      {CUTOFF:"2020-07-10",WK_NO:"W2020_28"},
      {CUTOFF:"2020-07-17",WK_NO:"W2020_29"},
      {CUTOFF:"2020-07-24",WK_NO:"W2020_30"},
      {CUTOFF:"2020-07-31",WK_NO:"W2020_31"},
      {CUTOFF:"2020-08-07",WK_NO:"W2020_32"},
      {CUTOFF:"2020-08-14",WK_NO:"W2020_33"},
      {CUTOFF:"2020-08-21",WK_NO:"W2020_34"},
      {CUTOFF:"2020-08-28",WK_NO:"W2020_35"},
      {CUTOFF:"2020-09-04",WK_NO:"W2020_36"},
      {CUTOFF:"2020-09-11",WK_NO:"W2020_37"},
      {CUTOFF:"2020-09-18",WK_NO:"W2020_38"},
      {CUTOFF:"2020-09-25",WK_NO:"W2020_39"},
      {CUTOFF:"2020-10-02",WK_NO:"W2020_40"},
      {CUTOFF:"2020-10-09",WK_NO:"W2020_41"},
      {CUTOFF:"2020-10-16",WK_NO:"W2020_42"},
      {CUTOFF:"2020-10-23",WK_NO:"W2020_43"},
      {CUTOFF:"2020-10-30",WK_NO:"W2020_44"},
      {CUTOFF:"2020-11-06",WK_NO:"W2020_45"},
      {CUTOFF:"2020-11-13",WK_NO:"W2020_46"},
      {CUTOFF:"2020-11-20",WK_NO:"W2020_47"},
      {CUTOFF:"2020-11-27",WK_NO:"W2020_48"},
      {CUTOFF:"2020-12-04",WK_NO:"W2020_49"},
      {CUTOFF:"2020-12-11",WK_NO:"W2020_50"},
      {CUTOFF:"2020-12-18",WK_NO:"W2020_51"},
      {CUTOFF:"2020-12-25",WK_NO:"W2020_52"},
      {CUTOFF:"2021-01-01",WK_NO:"W2020_53"},
      {CUTOFF:"2021-01-08",WK_NO:"W2021_01"},
      {CUTOFF:"2021-01-15",WK_NO:"W2021_02"},
      {CUTOFF:"2021-01-22",WK_NO:"W2021_03"},
      {CUTOFF:"2021-01-29",WK_NO:"W2021_04"},
      {CUTOFF:"2021-02-05",WK_NO:"W2021_05"},
      {CUTOFF:"2021-02-12",WK_NO:"W2021_06"},
      {CUTOFF:"2021-02-19",WK_NO:"W2021_07"},
      {CUTOFF:"2021-02-26",WK_NO:"W2021_08"},
      {CUTOFF:"2021-03-05",WK_NO:"W2021_09"},
      {CUTOFF:"2021-03-12",WK_NO:"W2021_10"},
      {CUTOFF:"2021-03-19",WK_NO:"W2021_11"},
      {CUTOFF:"2021-03-26",WK_NO:"W2021_12"},
      {CUTOFF:"2021-04-02",WK_NO:"W2021_13"},
      {CUTOFF:"2021-04-09",WK_NO:"W2021_14"},
      {CUTOFF:"2021-04-16",WK_NO:"W2021_15"},
      {CUTOFF:"2021-04-23",WK_NO:"W2021_16"},
      {CUTOFF:"2021-04-30",WK_NO:"W2021_17"},
      {CUTOFF:"2021-05-07",WK_NO:"W2021_18"},
      {CUTOFF:"2021-05-14",WK_NO:"W2021_19"},
      {CUTOFF:"2021-05-21",WK_NO:"W2021_20"},
      {CUTOFF:"2021-05-28",WK_NO:"W2021_21"},
      {CUTOFF:"2021-06-04",WK_NO:"W2021_22"},
      {CUTOFF:"2021-06-11",WK_NO:"W2021_23"},
      {CUTOFF:"2021-06-18",WK_NO:"W2021_24"},
      {CUTOFF:"2021-06-25",WK_NO:"W2021_25"},
      {CUTOFF:"2021-07-02",WK_NO:"W2021_26"},
      {CUTOFF:"2021-07-09",WK_NO:"W2021_27"},
      {CUTOFF:"2021-07-16",WK_NO:"W2021_28"},
      {CUTOFF:"2021-07-23",WK_NO:"W2021_29"},
      {CUTOFF:"2021-07-30",WK_NO:"W2021_30"},
      {CUTOFF:"2021-08-06",WK_NO:"W2021_31"},
      {CUTOFF:"2021-08-13",WK_NO:"W2021_32"},
      {CUTOFF:"2021-08-20",WK_NO:"W2021_33"},
      {CUTOFF:"2021-08-27",WK_NO:"W2021_34"},
      {CUTOFF:"2021-09-03",WK_NO:"W2021_35"},
      {CUTOFF:"2021-09-10",WK_NO:"W2021_36"},
      {CUTOFF:"2021-09-17",WK_NO:"W2021_37"},
      {CUTOFF:"2021-09-24",WK_NO:"W2021_38"},
      {CUTOFF:"2021-10-01",WK_NO:"W2021_39"},
      {CUTOFF:"2021-10-08",WK_NO:"W2021_40"},
      {CUTOFF:"2021-10-15",WK_NO:"W2021_41"},
      {CUTOFF:"2021-10-22",WK_NO:"W2021_42"},
      {CUTOFF:"2021-10-29",WK_NO:"W2021_43"},
      {CUTOFF:"2021-11-05",WK_NO:"W2021_44"},
      {CUTOFF:"2021-11-12",WK_NO:"W2021_45"},
      {CUTOFF:"2021-11-19",WK_NO:"W2021_46"},
      {CUTOFF:"2021-11-26",WK_NO:"W2021_47"},
      {CUTOFF:"2021-12-03",WK_NO:"W2021_48"},
      {CUTOFF:"2021-12-10",WK_NO:"W2021_49"},
      {CUTOFF:"2021-12-17",WK_NO:"W2021_50"},
      {CUTOFF:"2021-12-24",WK_NO:"W2021_51"},
      {CUTOFF:"2021-12-31",WK_NO:"W2021_52"},
      {CUTOFF:"2022-01-07",WK_NO:"W2022_01"},
      {CUTOFF:"2022-01-14",WK_NO:"W2022_02"},
      {CUTOFF:"2022-01-21",WK_NO:"W2022_03"},
      {CUTOFF:"2022-01-28",WK_NO:"W2022_04"},
      {CUTOFF:"2022-02-04",WK_NO:"W2022_05"},
      {CUTOFF:"2022-02-11",WK_NO:"W2022_06"},
      {CUTOFF:"2022-02-18",WK_NO:"W2022_07"},
      {CUTOFF:"2022-02-25",WK_NO:"W2022_08"},
      {CUTOFF:"2022-03-04",WK_NO:"W2022_09"},
      {CUTOFF:"2022-03-11",WK_NO:"W2022_10"},
      {CUTOFF:"2022-03-18",WK_NO:"W2022_11"},
      {CUTOFF:"2022-03-25",WK_NO:"W2022_12"},
      {CUTOFF:"2022-04-01",WK_NO:"W2022_13"},
      {CUTOFF:"2022-04-08",WK_NO:"W2022_14"},
      {CUTOFF:"2022-04-15",WK_NO:"W2022_15"}
    ],
    SQL5: [
      {TOTAL:577,NOTSTARTED:100,INPROGRESS:220,AITR_COMPLETED:170,RFI_ISSUED:299, WD_COMPLETED:200,MC_COMPLETED:250,MC_APPROVED:190,MC_REJECTED:300}
    ],
    SQL6: [
      {CUTOFF:"2021-07-30",WK_NO:"W2021_30",TOTAL:15342,REMAIN:16199,WKS_TREND:10000},
      {CUTOFF:"2021-08-06",WK_NO:"W2021_31",TOTAL:14223,REMAIN:16047,WKS_TREND:10000},
      {CUTOFF:"2021-09-03",WK_NO:"W2021_35",TOTAL:3762,WKS_TREND:10000},
      {CUTOFF:"2021-09-10",WK_NO:"W2021_36",TOTAL:2638,WKS_TREND:10000},
      {CUTOFF:"2021-10-01",WK_NO:"W2021_39",TOTAL:0,WKS_TREND:10000},
      {CUTOFF:"2021-08-13",WK_NO:"W2021_32",TOTAL:13115,REMAIN:15886,WKS_TREND:10000},
      {CUTOFF:"2021-08-20",WK_NO:"W2021_33",TOTAL:6861,REMAIN:6861,WKS_TREND:10000},
      {CUTOFF:"2021-08-27",WK_NO:"W2021_34",TOTAL:5320,REMAIN:15724,WKS_TREND:10000},
      {CUTOFF:"2021-09-17",WK_NO:"W2021_37",TOTAL:1393,WKS_TREND:10000},
      {CUTOFF:"2021-09-24",WK_NO:"W2021_38",TOTAL:974,WKS_TREND:10000}
    ]
  }
}