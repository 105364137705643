import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 530,
      CanvasHeight: 380,
      CanvasBorderWeight: 0.5,
      CanvasBorderColor: '#757575',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Queries: {
      SQL1: [
        {IMGNAME:"Topside.png"}
      ],
      SQL2: [
        {TYPE:"A",TOTAL:616,ACTUAL:22,REMAIN:594,PROG:3.57}
      ],
    },

    DataItems: [
      { TYPE: 'A',    DATE: null,     DISC: 'Architecture', TOTAL: 1444,    ACTUAL: 210,    REMAIN: 1234,   PROG: 14.54, },
      { TYPE: 'A',    DATE: null,     DISC: 'Electrical',   TOTAL: 43742,   ACTUAL: 25361,  REMAIN: 18381,  PROG: 57.98, },
      { TYPE: 'A',    DATE: null,     DISC: 'HVAC',         TOTAL: 6104,    ACTUAL: 4272,   REMAIN: 1832,   PROG: 69.99, },
      { TYPE: 'A',    DATE: null,     DISC: 'Instrument',   TOTAL: 54380,   ACTUAL: 32939,  REMAIN: 21441,  PROG: 60.57, },
      { TYPE: 'A',    DATE: null,     DISC: 'Mechanical',   TOTAL: 6512,    ACTUAL: 3533,   REMAIN: 2979,   PROG: 54.25, },
      { TYPE: 'A',    DATE: null,     DISC: 'PI',           TOTAL: 35215,   ACTUAL: 21704,  REMAIN: 13511,  PROG: 61.63, },
      { TYPE: 'A',    DATE: null,     DISC: 'Safety',       TOTAL: 915,     ACTUAL: 40,     REMAIN: 875,    PROG: 4.37, },
      { TYPE: 'A',    DATE: null,     DISC: 'Telecom',      TOTAL: 8391,    ACTUAL: 3566,   REMAIN: 4825,   PROG: 42.5, },
      { TYPE: 'A',    DATE: null,     DISC: 'Overall',      TOTAL: 156703,  ACTUAL: 91625,  REMAIN: 65078,  PROG: 58.47, },
      { TYPE: 'WEEK', DATE: '43889',  DISC: 'LWK',          TOTAL: null,    ACTUAL: 3426,   REMAIN: null,   PROG: null, },
      { TYPE: 'WEEK', DATE: '43890',  DISC: 'SAT',          TOTAL: null,    ACTUAL: 411,    REMAIN: null,   PROG: null, },
      { TYPE: 'WEEK', DATE: '43891',  DISC: 'SUN',          TOTAL: null,    ACTUAL: 95,     REMAIN: null,   PROG: null, },
      { TYPE: 'WEEK', DATE: '43892',  DISC: 'MON',          TOTAL: null,    ACTUAL: 0,      REMAIN: null,   PROG: null, },
      { TYPE: 'WEEK', DATE: '43893',  DISC: 'TUE',          TOTAL: null,    ACTUAL: 0,      REMAIN: null,   PROG: null, },
      { TYPE: 'WEEK', DATE: '43894',  DISC: 'WEN',          TOTAL: null,    ACTUAL: 0,      REMAIN: null,   PROG: null, },
      { TYPE: 'WEEK', DATE: '43895',  DISC: 'TUE',          TOTAL: null,    ACTUAL: 0,      REMAIN: null,   PROG: null, },
      { TYPE: 'WEEK', DATE: '43896',  DISC: 'FRI',          TOTAL: null,    ACTUAL: 0,      REMAIN: null,   PROG: null, },
      { TYPE: 'WEEK', DATE: '43896',  DISC: 'TWK',          TOTAL: null,    ACTUAL: 506,    REMAIN: null,   PROG: null, },

    ],
}