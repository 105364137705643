import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1320,
      CanvasHeight: 200,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 1,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 200,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: '#ffffff',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },

    Text: {
      Text: [
        // { text: 'Power Generation', x: 0, y: -20, font: 'roboto', class: 'regular', size: 14, color: '#01579B', align: 'start' },
      ],
      Filter: [],
      Sql: []
    },

    DataItems: [
      {DISC_DESCR:"Structure",TARGET_PROG:93.67,PLAN_PROG:99.13,ACTUAL_PROG:97.81,DELTA:-1.31,DISC:"36"},
      {DISC_DESCR:"Piping",TARGET_PROG:86.05,PLAN_PROG:92.52,ACTUAL_PROG:76.88,DELTA:-15.64,DISC:"13"},
      {DISC_DESCR:"Electrical",TARGET_PROG:53.8,PLAN_PROG:92.61,ACTUAL_PROG:60.96,DELTA:-31.65,DISC:"16"},
      {DISC_DESCR:"Instrument",TARGET_PROG:23.22,PLAN_PROG:91.86,ACTUAL_PROG:39.25,DELTA:-52.61,DISC:"15"},
      {DISC_DESCR:"HVAC",TARGET_PROG:79.15,PLAN_PROG:98.57,ACTUAL_PROG:83.6,DELTA:-14.97,DISC:"21"},
      {DISC_DESCR:"Architecture",TARGET_PROG:43.12,PLAN_PROG:78.4,ACTUAL_PROG:42.43,DELTA:-35.97,DISC:"20"},
      {DISC_DESCR:"Mechanical",TARGET_PROG:9.85,PLAN_PROG:79.43,ACTUAL_PROG:37.29,DELTA:-42.13,DISC:"68"},
      {DISC_DESCR:"Painting",TARGET_PROG:94.85,PLAN_PROG:99.93,ACTUAL_PROG:92.05,DELTA:-7.87,DISC:"23"},
      {DISC_DESCR:"Insulation",TARGET_PROG:0,PLAN_PROG:75.31,ACTUAL_PROG:0,DELTA:-75.31,DISC:"22"}
      ],
    Queries: {
      SQL1: [
        {NO:27,LV1:"HULL",LV2:"LQ",LV3:"OVERALL",PLAN:95.6,ACTUAL:93.79,CERT:72.94,DELTA:-1.81,PLAN_WK:3.55,ACTUAL_WK:0.92,CERT_WK:0,DELTA_WK:-2.63,TEXT1:"Undocking",TEXT1_DATE:"19-Feb-21",TEXT2:"OGM Goal",TEXT2_PROG:79.31}
      ]      
    }
}