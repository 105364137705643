import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 4020,
    CanvasHeight: 980,
    CanvasBorderWeight: 0.05,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 1,

    CanvasChartX: 50,
    CanvasChartY: 50,
    CanvasChartWidth: 480,
    CanvasChartHeight: 250,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  Text: {
    Text: [
      { text: 'Module', x: 450, y: 20, font: 'roboto', class: 'regular', size: 20, color: '#44A9DF', align: 'start' },
    ],
    Filter: [
      { name: 'MOD'   , x: 320, y: 20, font: 'roboto', class: 'regular', size: 20, color: '#44A9DF', align: 'start' },
    ],
    Sql: [
      {
        query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
        text: 'PBF COMPESSOR AIR COOLER - TRAIN 4',
        x: 320,
        y: 40,
        font: 'roboto',
        style: 'regular',
        size: 14,
        color: '#757575',
        align: 'start'
      },
    ]
  },
    // {TOPIC:"A. DOCUMENTS AND CERTIFICATES",ACT_PLAN:"2019-12-27",ITEM_NO:"A.1",STATUS:"Completed "},
    DataItems: [
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"217",date:"2019-04-26",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"207",date:"2019-05-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"208",date:"2019-06-07",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"219",date:"2019-06-07",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"241",date:"2019-06-07",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"242",date:"2019-06-07",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"218",date:"2019-06-07",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"251",date:"2019-06-07",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"215",date:"2019-06-14",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"216",date:"2019-06-14",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"328",date:"2019-08-02",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"821",date:"2019-08-02",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"339",date:"2019-08-02",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"212",date:"2019-08-02",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"221",date:"2019-08-02",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"225",date:"2019-08-02",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"231",date:"2019-08-02",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"329",date:"2019-08-02",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"201",date:"2019-08-02",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"235",date:"2019-08-02",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"211",date:"2019-08-02",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"363",date:"2019-08-02",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"338",date:"2019-08-02",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"373",date:"2019-08-02",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"327",date:"2019-08-02",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"224",date:"2019-08-09",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"288",date:"2019-09-06",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"122",date:"2019-08-23",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"101",date:"2019-08-23",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"141",date:"2019-08-23",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"151",date:"2019-08-23",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"121",date:"2019-08-23",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"131",date:"2019-08-23",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"292",date:"2019-08-23",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"282",date:"2019-08-23",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"285",date:"2019-08-23",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"295",date:"2019-08-23",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"856",date:"2019-08-23",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"843",date:"2019-08-23",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"903",date:"2019-08-23",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"853",date:"2019-08-23",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"152",date:"2019-08-23",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"123",date:"2019-08-23",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"133",date:"2019-08-23",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"124",date:"2019-08-23",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"135",date:"2019-08-23",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"125",date:"2019-08-23",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"283",date:"2019-08-23",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"293",date:"2019-08-30",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"134",date:"2019-08-30",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"911",date:"2019-08-30",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"912",date:"2019-08-30",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"904",date:"2019-08-30",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"991",date:"2019-08-30",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"916",date:"2019-08-30",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"993",date:"2019-08-30",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"847",date:"2019-08-30",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"845",date:"2019-08-30",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"857",date:"2019-08-30",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"855",date:"2019-08-30",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"202",date:"2019-08-30",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"914",date:"2019-08-30",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"901",date:"2019-08-30",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"902",date:"2019-08-30",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"209",date:"2019-05-24",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"252",date:"2019-05-24",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"206",date:"2019-05-31",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"205",date:"2019-05-31",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"806",date:"2019-07-12",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"832",date:"2019-07-12",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"825",date:"2019-07-12",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"809",date:"2019-07-12",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"829",date:"2019-07-12",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"803",date:"2019-07-12",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"808",date:"2019-07-19",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"811",date:"2019-07-19",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"813",date:"2019-07-19",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"831",date:"2019-07-19",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"823",date:"2019-07-19",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"826",date:"2019-07-19",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"807",date:"2019-07-19",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"827",date:"2019-07-19",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"804",date:"2019-07-19",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"844",date:"2019-10-04",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"854",date:"2019-10-04",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"203",date:"2019-06-14",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"213",date:"2019-06-14",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"261",date:"2019-06-14",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"272",date:"2019-06-14",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"271",date:"2019-06-21",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"341",date:"2019-06-21",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"351",date:"2019-06-21",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"227",date:"2019-06-21",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"343",date:"2019-06-21",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"353",date:"2019-06-21",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"237",date:"2019-06-21",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"229",date:"2019-06-21",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"307",date:"2019-06-21",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"239",date:"2019-06-21",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"317",date:"2019-06-21",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"243",date:"2019-06-28",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"253",date:"2019-06-28",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"309",date:"2019-06-28",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"319",date:"2019-06-28",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"228",date:"2019-06-28",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"238",date:"2019-06-28",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"214",date:"2019-06-28",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"204",date:"2019-06-28",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"308",date:"2019-06-28",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"318",date:"2019-06-28",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"263",date:"2019-06-28",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"273",date:"2019-06-28",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"342",date:"2019-07-05",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"226",date:"2019-07-05",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"236",date:"2019-07-05",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"352",date:"2019-07-05",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"262",date:"2019-07-05",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"812",date:"2019-07-12",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"805",date:"2019-07-12",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"234",date:"2019-08-09",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"294",date:"2019-08-09",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"284",date:"2019-08-09",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"337",date:"2019-08-16",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"866",date:"2019-08-16",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"876",date:"2019-08-16",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"865",date:"2019-08-16",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"875",date:"2019-08-16",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"867",date:"2019-08-16",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"877",date:"2019-08-16",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"132",date:"2019-08-16",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"281",date:"2019-08-16",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"852",date:"2019-08-16",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"842",date:"2019-08-16",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"102",date:"2019-08-16",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"291",date:"2019-08-16",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"884",date:"2019-09-06",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"298",date:"2019-09-06",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"921",date:"2019-09-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"922",date:"2019-09-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"923",date:"2019-09-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"927",date:"2019-09-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"286",date:"2019-09-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"296",date:"2019-09-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"881",date:"2019-09-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"891",date:"2019-09-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"627",date:"2019-09-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"637",date:"2019-09-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"299",date:"2019-09-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"607",date:"2019-09-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"931",date:"2019-09-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"924",date:"2019-09-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"885",date:"2019-09-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"898",date:"2019-09-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"143",date:"2019-09-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"153",date:"2019-09-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"935",date:"2019-09-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"145",date:"2019-09-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"155",date:"2019-09-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"936",date:"2019-09-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"895",date:"2019-09-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"602",date:"2019-09-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"612",date:"2019-09-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"938",date:"2019-09-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"633",date:"2019-09-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"634",date:"2019-09-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"613",date:"2019-09-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"623",date:"2019-09-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"603",date:"2019-09-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"604",date:"2019-09-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"622",date:"2019-09-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"632",date:"2019-09-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"614",date:"2019-09-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"624",date:"2019-09-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"907",date:"2019-08-30",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"908",date:"2019-08-30",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"994",date:"2019-08-30",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"846",date:"2019-08-30",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"297",date:"2019-08-30",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"287",date:"2019-08-30",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"887",date:"2019-08-30",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"886",date:"2019-08-30",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"858",date:"2019-08-30",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"913",date:"2019-08-30",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"915",date:"2019-08-30",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"917",date:"2019-08-30",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"896",date:"2019-08-30",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"918",date:"2019-09-06",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"909",date:"2019-09-06",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"992",date:"2019-09-06",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"142",date:"2019-09-06",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"822",date:"2019-07-19",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"824",date:"2019-07-19",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"802",date:"2019-07-26",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"233",date:"2019-07-26",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"828",date:"2019-07-26",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"801",date:"2019-07-26",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"223",date:"2019-07-26",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"222",date:"2019-07-26",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"232",date:"2019-07-26",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"833",date:"2019-07-26",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"371",date:"2019-07-26",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"361",date:"2019-07-26",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"626",date:"2019-10-04",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"636",date:"2019-10-04",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"615",date:"2019-10-04",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"882",date:"2019-10-04",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"635",date:"2019-10-04",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"625",date:"2019-10-11",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"841",date:"2019-10-11",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"104",date:"2019-10-11",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"893",date:"2019-10-11",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"883",date:"2019-10-11",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"892",date:"2019-10-11",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"942",date:"2019-10-11",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"301",date:"2019-10-11",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"315",date:"2019-10-11",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"311",date:"2019-10-11",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"851",date:"2019-10-11",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"946",date:"2019-10-11",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"947",date:"2019-10-11",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"695",date:"2019-10-25",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"681",date:"2019-11-29",PROG:94,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"691",date:"2019-11-29",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"280",date:"2019-12-06",PROG:77.47,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"290",date:"2019-12-06",PROG:46.6,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"511",date:"2019-12-06",PROG:94,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"515",date:"2019-12-06",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"144",date:"2019-12-06",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"154",date:"2019-12-06",PROG:94,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"HD01",Activity:"Fabrication",SCHEDULE:"Start Date",name:"959",date:"2019-12-06",PROG:92.29,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"963",date:"2019-12-06",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"514",date:"2019-12-06",PROG:94,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"962",date:"2019-12-06",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"513",date:"2019-12-06",PROG:94,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"848",date:"2019-09-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"878",date:"2019-09-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"888",date:"2019-09-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"897",date:"2019-09-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"289",date:"2019-09-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"868",date:"2019-09-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"894",date:"2019-09-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"518",date:"2019-11-08",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"522",date:"2019-11-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"532",date:"2019-11-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"953",date:"2019-11-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"509",date:"2019-11-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"519",date:"2019-11-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"304",date:"2019-11-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"314",date:"2019-11-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"905",date:"2019-11-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"906",date:"2019-11-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"324",date:"2019-11-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"334",date:"2019-11-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"925",date:"2019-11-29",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"926",date:"2019-11-29",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"325",date:"2019-11-29",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"335",date:"2019-11-29",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"306",date:"2019-09-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"316",date:"2019-09-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"103",date:"2019-09-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"933",date:"2019-09-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"937",date:"2019-09-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"621",date:"2019-09-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"932",date:"2019-09-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"934",date:"2019-09-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"313",date:"2019-09-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"631",date:"2019-10-04",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"303",date:"2019-10-04",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"945",date:"2019-10-04",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"362",date:"2019-07-26",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"372",date:"2019-07-26",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"517",date:"2019-10-25",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"147",date:"2019-10-25",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"157",date:"2019-10-25",PROG:99.8,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"685",date:"2019-10-25",PROG:99.8,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"686",date:"2019-10-25",PROG:99.8,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"696",date:"2019-10-25",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"611",date:"2019-10-11",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"312",date:"2019-10-18",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"302",date:"2019-10-18",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"305",date:"2019-10-18",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"944",date:"2019-10-18",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"948",date:"2019-10-18",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"951",date:"2019-10-18",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"508",date:"2019-10-18",PROG:94,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"507",date:"2019-10-18",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"523",date:"2019-10-18",PROG:82.18,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"533",date:"2019-10-18",PROG:94,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"955",date:"2019-11-08",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"957",date:"2019-11-08",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"954",date:"2019-11-08",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"956",date:"2019-11-08",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"952",date:"2019-11-08",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"958",date:"2019-11-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"521",date:"2019-11-15",PROG:94,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"531",date:"2019-11-15",PROG:99.8,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"126",date:"2019-11-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"136",date:"2019-11-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"321",date:"2019-11-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"331",date:"2019-11-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"322",date:"2019-11-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"332",date:"2019-11-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"326",date:"2019-11-29",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"336",date:"2019-11-29",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"928",date:"2019-11-29",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"683",date:"2019-11-29",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"693",date:"2019-11-29",PROG:99.8,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"512",date:"2019-11-29",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"684",date:"2019-11-29",PROG:94,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"694",date:"2019-11-29",PROG:94,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"972",date:"2019-12-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"973",date:"2019-12-27",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"687",date:"2019-12-27",PROG:92.83,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"688",date:"2019-12-27",PROG:92.83,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"971",date:"2019-12-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"697",date:"2019-12-27",PROG:99.8,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"698",date:"2019-12-27",PROG:92.83,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"128",date:"2019-12-27",PROG:99.8,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"138",date:"2019-12-27",PROG:92.83,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"995",date:"2019-10-04",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"996",date:"2019-10-04",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"601",date:"2019-10-04",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"605",date:"2019-10-04",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"606",date:"2019-10-04",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"616",date:"2019-10-04",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"941",date:"2019-10-04",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"943",date:"2019-10-04",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"964",date:"2019-12-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"740",date:"2020-02-21",PROG:26.85,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"760",date:"2020-02-21",PROG:26.85,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"741",date:"2020-02-21",PROG:31.59,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"742",date:"2020-02-21",PROG:31.59,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"743",date:"2020-02-21",PROG:26.85,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"744",date:"2020-02-21",PROG:50.17,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"750",date:"2020-02-21",PROG:39.9,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"751",date:"2020-02-21",PROG:50.17,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"752",date:"2020-02-21",PROG:39.9,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"753",date:"2020-02-21",PROG:50.17,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"754",date:"2020-02-21",PROG:26.85,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"761",date:"2020-02-21",PROG:15,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"762",date:"2020-02-21",PROG:26.85,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"763",date:"2020-02-21",PROG:15,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"764",date:"2020-02-21",PROG:39.9,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"770",date:"2020-02-21",PROG:39.9,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"771",date:"2020-02-21",PROG:15,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"772",date:"2020-02-21",PROG:39.9,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"773",date:"2020-02-21",PROG:15,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"774",date:"2020-02-21",PROG:39.9,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"432",date:"2020-01-10",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"146",date:"2020-01-10",PROG:92.83,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"156",date:"2020-01-10",PROG:92.83,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"127",date:"2020-01-10",PROG:88.09,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"137",date:"2020-01-10",PROG:94,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"323",date:"2019-11-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"333",date:"2019-11-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"502",date:"2020-02-07",PROG:84.55,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"503",date:"2020-02-07",PROG:73.87,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"504",date:"2020-02-14",PROG:73.87,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"617",date:"2020-01-03",PROG:94,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"618",date:"2020-01-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"682",date:"2019-11-29",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"692",date:"2019-11-29",PROG:94,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"961",date:"2019-11-29",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"401",date:"2020-01-17",PROG:63.22,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"428",date:"2019-12-27",PROG:94,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"427",date:"2019-12-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"429",date:"2019-12-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"439",date:"2019-12-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"441",date:"2019-12-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"451",date:"2019-12-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"442",date:"2020-01-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"452",date:"2020-01-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"965",date:"2019-12-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"966",date:"2019-12-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"506",date:"2020-02-21",PROG:83.35,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"516",date:"2020-02-21",PROG:92.83,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"411",date:"2020-01-10",PROG:92.83,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"412",date:"2020-01-10",PROG:94,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"413",date:"2020-01-17",PROG:90.46,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"105",date:"2020-01-17",PROG:88.09,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"619",date:"2020-01-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"421",date:"2020-01-03",PROG:99,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"431",date:"2020-01-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"423",date:"2020-01-03",PROG:85.81,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"433",date:"2020-01-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"180",date:"2020-01-03",PROG:67.96,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"190",date:"2020-01-03",PROG:67.96,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"424",date:"2020-01-03",PROG:94,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"434",date:"2020-01-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"505",date:"2020-02-14",PROG:84.55,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"402",date:"2020-01-17",PROG:88.09,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"403",date:"2020-01-17",PROG:78.61,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"501",date:"2020-01-17",PROG:70.33,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"405",date:"2020-01-17",PROG:69.13,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"404",date:"2020-01-17",PROG:69.13,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"409",date:"2020-01-24",PROG:85.72,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"408",date:"2020-01-24",PROG:88.09,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"407",date:"2020-01-24",PROG:94,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"406",date:"2020-01-24",PROG:73.87,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"443",date:"2020-01-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"453",date:"2020-01-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"438",date:"2019-12-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"437",date:"2019-12-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"1904",date:"2020-05-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"2904",date:"2020-05-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"5904",date:"2020-05-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"1908",date:"2020-05-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"4908",date:"2020-05-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"106",date:"2020-01-17",PROG:88.09,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"MD00f",Activity:"Fabrication",SCHEDULE:"Start Date",name:"HLRC07",date:"2020-04-17",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"MD00f",Activity:"Fabrication",SCHEDULE:"Start Date",name:"HLRC08",date:"2020-04-17",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"MD00f",Activity:"Fabrication",SCHEDULE:"Start Date",name:"HLRC09",date:"2020-04-17",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"MD00f",Activity:"Fabrication",SCHEDULE:"Start Date",name:"HLRC10",date:"2020-04-17",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"MD00f",Activity:"Fabrication",SCHEDULE:"Start Date",name:"HLRC11",date:"2020-04-17",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"MD00f",Activity:"Fabrication",SCHEDULE:"Start Date",name:"HLRC12",date:"2020-04-17",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"745",date:"2020-03-06",PROG:50.17,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"746",date:"2020-03-06",PROG:50.17,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"747",date:"2020-03-06",PROG:50.17,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"748",date:"2020-03-06",PROG:50.17,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"749",date:"2020-03-06",PROG:50.17,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"755",date:"2020-03-06",PROG:50.17,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"756",date:"2020-03-06",PROG:26.85,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"757",date:"2020-03-06",PROG:50.17,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"758",date:"2020-03-06",PROG:39.9,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"425",date:"2020-01-10",PROG:94,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"435",date:"2020-01-10",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"426",date:"2020-01-10",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"436",date:"2020-01-10",PROG:94,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Fabrication",SCHEDULE:"Start Date",name:"422",date:"2020-01-10",PROG:99.8,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"759",date:"2020-03-13",PROG:50.17,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"765",date:"2020-03-13",PROG:50.17,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"766",date:"2020-03-13",PROG:39.9,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"767",date:"2020-03-13",PROG:50.17,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"768",date:"2020-03-13",PROG:39.9,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"769",date:"2020-03-13",PROG:50.17,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"4905",date:"2020-05-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"1906",date:"2020-05-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"2906",date:"2020-05-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"6907",date:"2020-05-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"2908",date:"2020-05-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"1905",date:"2020-05-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"2905",date:"2020-05-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"1907",date:"2020-05-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"2907",date:"2020-05-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"3907",date:"2020-05-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"2901",date:"2020-05-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"3903",date:"2020-05-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"3905",date:"2020-05-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"4907",date:"2020-05-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"5907",date:"2020-05-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"MD00a",Activity:"Fabrication",SCHEDULE:"Start Date",name:"HLRC01",date:"2020-04-17",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"MD00a",Activity:"Fabrication",SCHEDULE:"Start Date",name:"HLRC02",date:"2020-04-17",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"MD00a",Activity:"Fabrication",SCHEDULE:"Start Date",name:"HLRC03",date:"2020-04-17",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"MD00a",Activity:"Fabrication",SCHEDULE:"Start Date",name:"HLRC04",date:"2020-04-17",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"MD00a",Activity:"Fabrication",SCHEDULE:"Start Date",name:"HLRC05",date:"2020-04-17",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"MD00a",Activity:"Fabrication",SCHEDULE:"Start Date",name:"HLRC06",date:"2020-04-17",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"778",date:"2020-03-20",PROG:39.9,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"779",date:"2020-03-20",PROG:15,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"775",date:"2020-03-20",PROG:15,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"776",date:"2020-03-20",PROG:39.9,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Fabrication",SCHEDULE:"Start Date",name:"777",date:"2020-03-20",PROG:15,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"1903",date:"2020-05-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"2903",date:"2020-05-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"7907",date:"2020-05-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"9907",date:"2020-05-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"3908",date:"2020-05-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"5905",date:"2020-05-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"3906",date:"2020-05-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"8907",date:"2020-05-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"1909",date:"2020-05-22",PROG:99.5,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"1901",date:"2020-05-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"1902",date:"2020-05-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"2902",date:"2020-05-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"3904",date:"2020-05-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Fabrication",SCHEDULE:"Start Date",name:"4904",date:"2020-05-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"21B",date:"2019-09-06",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"22B",date:"2019-09-06",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"21D",date:"2019-09-06",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"22D",date:"2019-09-06",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"21F",date:"2019-09-06",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"22F",date:"2019-09-06",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"10A",date:"2019-09-06",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"21V",date:"2019-09-06",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"22V",date:"2019-09-06",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"24V",date:"2019-09-06",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"23V",date:"2019-09-06",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"26V",date:"2019-09-06",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"25V",date:"2019-09-06",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"20L",date:"2019-12-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"20H",date:"2019-12-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"80G",date:"2020-02-21",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"285",date:"2020-02-21",PROG:99,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"281",date:"2020-01-10",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"295",date:"2020-02-28",PROG:51,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"286",date:"2020-02-28",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"80I",date:"2020-02-07",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"22I",date:"2020-01-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"21L",date:"2020-01-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"284",date:"2020-01-17",PROG:99,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"291",date:"2020-01-17",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"292",date:"2020-01-17",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"22J",date:"2019-12-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"21K",date:"2019-12-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"20J",date:"2019-12-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"20I",date:"2019-12-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"21J",date:"2019-12-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"91A",date:"2020-02-21",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"22G",date:"2020-01-10",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"21G",date:"2020-01-10",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"90A",date:"2020-01-17",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"296",date:"2020-02-28",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"26F",date:"2020-03-06",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"20C",date:"2020-01-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"21I",date:"2020-01-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"22L",date:"2020-01-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"20D",date:"2020-01-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"20E",date:"2020-01-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"21C",date:"2020-02-14",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"80L",date:"2020-02-14",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"293",date:"2020-02-14",PROG:35,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"21E",date:"2020-02-14",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"22E",date:"2020-02-14",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"90B",date:"2020-01-17",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"294",date:"2020-01-17",PROG:51,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"283",date:"2020-01-17",PROG:35,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"20A",date:"2020-01-24",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"22K",date:"2020-01-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"69F",date:"2020-04-03",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"20K",date:"2019-12-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"20G",date:"2019-12-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"21A",date:"2020-03-06",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"22A",date:"2020-03-06",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"847",date:"2020-03-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"857",date:"2020-03-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"80C",date:"2020-03-13",PROG:94,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"92B",date:"2020-03-13",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"91B",date:"2020-02-28",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"96B",date:"2020-05-15",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"282",date:"2020-01-17",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"20B",date:"2020-01-17",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"20F",date:"2020-01-17",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"304",date:"2020-04-17",PROG:55.00000000000001,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"314",date:"2020-04-17",PROG:43,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"92A",date:"2020-03-06",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"50G",date:"2020-06-12",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"51G",date:"2020-06-12",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"30A",date:"2020-06-12",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"22H",date:"2020-01-10",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"21H",date:"2020-01-10",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"80H",date:"2020-02-14",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"22C",date:"2020-02-14",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"80K",date:"2020-01-24",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"80J",date:"2020-02-07",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"841",date:"2020-05-01",PROG:53,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"887",date:"2020-04-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"897",date:"2020-04-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"94A",date:"2020-04-10",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"302",date:"2020-04-10",PROG:53,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"312",date:"2020-04-10",PROG:53,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"94B",date:"2020-04-10",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"27F",date:"2020-04-10",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"301",date:"2020-04-10",PROG:53,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"311",date:"2020-04-10",PROG:53,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"14A",date:"2020-03-13",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"10B",date:"2020-03-13",PROG:51,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"15A",date:"2020-03-13",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"29F",date:"2020-03-13",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"28F",date:"2020-03-13",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"971",date:"2020-05-15",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"972",date:"2020-05-15",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"973",date:"2020-05-15",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"65F",date:"2020-04-17",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"305",date:"2020-04-17",PROG:55.00000000000001,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"315",date:"2020-04-17",PROG:43,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"61F",date:"2020-04-24",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"62F",date:"2020-04-24",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"280",date:"2020-04-24",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"306",date:"2020-04-24",PROG:51,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"316",date:"2020-04-24",PROG:39,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"31A",date:"2020-06-12",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"30C",date:"2020-06-12",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"31C",date:"2020-06-12",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"30E",date:"2020-06-19",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"31E",date:"2020-06-19",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"12A",date:"2020-03-20",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"13A",date:"2020-03-20",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"846",date:"2020-03-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"856",date:"2020-03-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"80E",date:"2020-03-20",PROG:99,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"93B",date:"2020-03-20",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"882",date:"2020-05-29",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"892",date:"2020-05-29",PROG:53,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"180",date:"2020-05-29",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"63A",date:"2020-05-29",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"62A",date:"2020-05-29",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"190",date:"2020-05-29",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"883",date:"2020-05-29",PROG:55.00000000000001,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"893",date:"2020-05-29",PROG:72.5,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"64A",date:"2020-05-29",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"884",date:"2020-05-29",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"894",date:"2020-05-29",PROG:72.5,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"886",date:"2020-04-10",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"896",date:"2020-04-10",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"290",date:"2020-04-10",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"885",date:"2020-04-10",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"303",date:"2020-04-10",PROG:53,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"313",date:"2020-04-10",PROG:53,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"HD01",Activity:"Erection",SCHEDULE:"Start Date",name:"959",date:"2020-04-17",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"895",date:"2020-04-17",PROG:99,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"143",date:"2020-04-17",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"153",date:"2020-04-17",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"MD00a",Activity:"Erection",SCHEDULE:"Start Date",name:"HLRC05",date:"2020-10-02",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"95B",date:"2020-05-01",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"842",date:"2020-05-01",PROG:99,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"851",date:"2020-05-01",PROG:99,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"103",date:"2020-05-01",PROG:15,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"30G",date:"2020-05-01",PROG:53,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"852",date:"2020-05-01",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"31G",date:"2020-05-01",PROG:86.2,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"133",date:"2020-05-01",PROG:94,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"101",date:"2020-03-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"80D",date:"2020-03-20",PROG:94,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"93A",date:"2020-03-20",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"61A",date:"2020-05-22",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"881",date:"2020-05-22",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"891",date:"2020-05-22",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"30K",date:"2020-04-24",PROG:53,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"31K",date:"2020-04-24",PROG:99,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"63F",date:"2020-04-24",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"64F",date:"2020-04-24",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"95A",date:"2020-04-24",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"30I",date:"2020-05-01",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"31I",date:"2020-05-01",PROG:99,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"50E",date:"2020-08-21",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"80F",date:"2020-03-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"845",date:"2020-03-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"80A",date:"2020-03-27",PROG:88.15,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"855",date:"2020-03-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"66F",date:"2020-03-27",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"80B",date:"2020-03-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"67F",date:"2020-03-27",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"755",date:"2020-06-26",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"766",date:"2020-06-26",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"135",date:"2020-06-26",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"125",date:"2020-06-26",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"767",date:"2020-06-26",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"768",date:"2020-06-26",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"769",date:"2020-06-26",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"126",date:"2020-05-29",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"136",date:"2020-05-29",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"50K",date:"2020-06-05",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"51K",date:"2020-06-05",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"50I",date:"2020-06-05",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"51I",date:"2020-06-05",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"MD00a",Activity:"Erection",SCHEDULE:"Start Date",name:"HLRC03",date:"2020-10-09",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"MD00a",Activity:"Erection",SCHEDULE:"Start Date",name:"HLRC06",date:"2020-10-09",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"6907",date:"2020-10-09",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"7907",date:"2020-10-09",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"8907",date:"2020-10-09",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"9907",date:"2020-10-09",PROG:99,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"1908",date:"2020-10-09",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"MD00a",Activity:"Erection",SCHEDULE:"Start Date",name:"HLRC01",date:"2020-10-16",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"2908",date:"2020-10-16",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"3908",date:"2020-10-16",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"4908",date:"2020-10-16",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"1909",date:"2020-10-16",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"775",date:"2020-07-03",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"776",date:"2020-07-03",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"777",date:"2020-07-03",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"778",date:"2020-07-03",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"779",date:"2020-07-03",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"63B",date:"2020-07-10",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"64B",date:"2020-07-10",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"65A",date:"2020-07-10",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"123",date:"2020-05-08",PROG:23,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"843",date:"2020-05-08",PROG:99,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"853",date:"2020-05-08",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"844",date:"2020-05-08",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"854",date:"2020-05-08",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"68F",date:"2020-04-03",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"50A",date:"2020-07-24",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"51A",date:"2020-07-24",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"40L",date:"2020-07-24",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"12C",date:"2020-07-24",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"13C",date:"2020-07-24",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"124",date:"2020-07-31",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"134",date:"2020-07-31",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"40K",date:"2020-07-31",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"MD00f",Activity:"Erection",SCHEDULE:"Start Date",name:"HLRC09",date:"2020-08-21",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"MD00f",Activity:"Erection",SCHEDULE:"Start Date",name:"HLRC11",date:"2020-08-21",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"40H",date:"2020-08-21",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"MD00f",Activity:"Erection",SCHEDULE:"Start Date",name:"HLRC10",date:"2020-08-21",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"MD00f",Activity:"Erection",SCHEDULE:"Start Date",name:"HLRC12",date:"2020-08-21",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"51E",date:"2020-08-21",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"40G",date:"2020-08-21",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"765",date:"2020-08-28",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"40B",date:"2020-08-28",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"40C",date:"2020-08-28",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"40D",date:"2020-08-28",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"763",date:"2020-09-04",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"764",date:"2020-09-04",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"746",date:"2020-09-04",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"756",date:"2020-09-04",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"40E",date:"2020-09-04",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"773",date:"2020-09-04",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"774",date:"2020-09-04",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"966",date:"2020-11-13",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"67A",date:"2020-07-10",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"66A",date:"2020-07-10",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"68A",date:"2020-07-17",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"96A",date:"2020-05-15",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"40J",date:"2020-07-31",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"50C",date:"2020-07-31",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"51C",date:"2020-07-31",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"14C",date:"2020-07-31",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"40I",date:"2020-08-07",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"15C",date:"2020-08-07",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"69A",date:"2020-08-07",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"760",date:"2020-08-07",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"770",date:"2020-08-07",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"749",date:"2020-08-07",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"759",date:"2020-08-07",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"61B",date:"2020-08-14",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"MD00f",Activity:"Erection",SCHEDULE:"Start Date",name:"HLRC07",date:"2020-09-04",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"745",date:"2020-09-04",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"40F",date:"2020-09-04",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"1901",date:"2020-09-11",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"2901",date:"2020-09-11",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"1902",date:"2020-09-11",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"2902",date:"2020-09-11",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"1903",date:"2020-09-11",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"741",date:"2020-09-11",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"742",date:"2020-09-11",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"751",date:"2020-09-11",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"752",date:"2020-09-11",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"10C",date:"2020-09-11",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"2903",date:"2020-09-18",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"3903",date:"2020-09-18",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"1904",date:"2020-09-18",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"2904",date:"2020-09-18",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"3904",date:"2020-09-18",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"40A",date:"2020-09-18",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"743",date:"2020-09-18",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"753",date:"2020-09-18",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"11C",date:"2020-09-18",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"744",date:"2020-09-18",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"754",date:"2020-09-18",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"4904",date:"2020-09-25",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"5904",date:"2020-09-25",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"1905",date:"2020-09-25",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"2905",date:"2020-09-25",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"3905",date:"2020-09-25",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"MD00a",Activity:"Erection",SCHEDULE:"Start Date",name:"HLRC02",date:"2020-09-25",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"MD00a",Activity:"Erection",SCHEDULE:"Start Date",name:"HLRC04",date:"2020-09-25",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"748",date:"2020-08-14",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"758",date:"2020-08-14",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"62B",date:"2020-08-14",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"747",date:"2020-08-14",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Erection",SCHEDULE:"Start Date",name:"757",date:"2020-08-14",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"761",date:"2020-08-14",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"762",date:"2020-08-14",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"771",date:"2020-08-14",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"772",date:"2020-08-14",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"MD00f",Activity:"Erection",SCHEDULE:"Start Date",name:"HLRC08",date:"2020-08-21",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"4905",date:"2020-09-25",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"5905",date:"2020-09-25",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"1906",date:"2020-09-25",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"2906",date:"2020-09-25",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"3906",date:"2020-09-25",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"740",date:"2020-10-02",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Erection",SCHEDULE:"Start Date",name:"750",date:"2020-10-02",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"1907",date:"2020-10-02",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"2907",date:"2020-10-02",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"3907",date:"2020-10-02",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"4907",date:"2020-10-02",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Erection",SCHEDULE:"Start Date",name:"5907",date:"2020-10-02",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"101",date:"2019-12-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"102",date:"2019-12-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"103",date:"2020-04-10",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"104",date:"2019-12-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"105",date:"2020-07-31",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"106",date:"2020-07-24",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"10A",date:"2019-09-06",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"10B",date:"2020-02-21",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"10C",date:"2020-09-04",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"11C",date:"2020-09-04",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"121",date:"2019-12-06",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"122",date:"2019-11-29",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"123",date:"2020-04-17",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"124",date:"2020-06-19",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"125",date:"2020-06-05",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"126",date:"2020-05-08",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"127",date:"2020-07-31",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"128",date:"2020-07-31",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"12A",date:"2020-01-17",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"12C",date:"2020-07-17",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"131",date:"2019-12-06",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"132",date:"2019-12-06",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"133",date:"2020-04-17",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"134",date:"2020-06-19",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"135",date:"2020-06-05",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"136",date:"2020-05-08",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"137",date:"2020-07-31",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"138",date:"2020-07-31",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"13A",date:"2020-01-17",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"13C",date:"2020-07-10",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"141",date:"2019-12-06",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"142",date:"2019-12-06",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"143",date:"2020-03-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"144",date:"2020-06-05",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"145",date:"2020-06-12",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"146",date:"2020-06-12",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"147",date:"2020-05-22",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"14A",date:"2020-01-24",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"14C",date:"2020-07-24",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"151",date:"2019-12-06",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"152",date:"2019-12-06",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"153",date:"2020-03-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"154",date:"2020-06-19",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"155",date:"2020-05-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"156",date:"2020-06-12",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"157",date:"2020-05-29",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"15A",date:"2020-02-14",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"15C",date:"2020-07-31",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"180",date:"2020-04-24",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"190",date:"2020-05-01",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"201",date:"2019-11-08",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"202",date:"2019-11-08",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"203",date:"2019-09-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"204",date:"2019-09-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"205",date:"2019-09-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"206",date:"2019-09-06",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"20A",date:"2019-12-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"20B",date:"2019-12-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"20C",date:"2019-10-25",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"20D",date:"2019-11-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"20E",date:"2019-10-25",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"20F",date:"2019-11-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"211",date:"2019-10-25",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"212",date:"2019-11-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"213",date:"2019-08-30",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"214",date:"2019-09-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"215",date:"2019-08-16",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"216",date:"2019-08-30",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"21A",date:"2020-01-17",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"21B",date:"2019-09-06",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"21C",date:"2020-01-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"21D",date:"2019-09-06",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"21E",date:"2020-01-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"21F",date:"2019-09-06",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"221",date:"2019-11-29",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"222",date:"2019-11-29",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"223",date:"2019-11-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"224",date:"2019-11-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"225",date:"2019-11-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"226",date:"2019-10-11",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"22A",date:"2020-01-24",PROG:60,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"22B",date:"2019-09-06",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"22C",date:"2020-01-10",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"22D",date:"2019-09-06",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"22E",date:"2020-01-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"22F",date:"2019-09-06",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"231",date:"2019-11-29",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"232",date:"2019-11-29",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"233",date:"2019-11-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"234",date:"2019-11-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"235",date:"2019-11-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"236",date:"2019-10-11",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"301",date:"2020-03-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"302",date:"2020-03-06",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"303",date:"2020-03-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"304",date:"2020-03-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"305",date:"2020-03-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"306",date:"2020-04-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"30A",date:"2020-06-05",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"30C",date:"2020-06-05",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"30E",date:"2020-06-12",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"311",date:"2020-03-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"312",date:"2020-03-06",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"313",date:"2020-03-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"314",date:"2020-03-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"315",date:"2020-03-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"316",date:"2020-04-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"31A",date:"2020-06-05",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"31C",date:"2020-06-05",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"31E",date:"2020-06-05",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"321",date:"2020-04-17",PROG:60,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"322",date:"2020-04-24",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"323",date:"2020-05-01",PROG:60,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"324",date:"2020-05-01",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"325",date:"2020-05-01",PROG:60,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"326",date:"2020-05-01",PROG:30,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"331",date:"2020-04-24",PROG:60,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"332",date:"2020-04-24",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"333",date:"2020-04-24",PROG:60,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"334",date:"2020-04-24",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"335",date:"2020-05-01",PROG:60,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"336",date:"2020-05-01",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"401",date:"2020-07-10",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"402",date:"2020-07-10",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"403",date:"2020-07-17",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"404",date:"2020-07-17",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"405",date:"2020-07-24",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"406",date:"2020-07-24",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"40A",date:"2020-08-14",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"40B",date:"2020-08-21",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"40C",date:"2020-08-21",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"40D",date:"2020-08-21",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"40E",date:"2020-08-28",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"40F",date:"2020-08-28",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"421",date:"2020-07-10",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"422",date:"2020-07-10",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"423",date:"2020-07-17",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"424",date:"2020-07-17",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"425",date:"2020-07-24",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"426",date:"2020-07-24",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"431",date:"2020-07-10",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"432",date:"2020-07-17",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"433",date:"2020-07-17",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"434",date:"2020-07-17",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"435",date:"2020-07-24",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"436",date:"2020-07-24",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"501",date:"2020-05-29",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"502",date:"2020-06-12",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"503",date:"2020-06-12",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"504",date:"2020-06-19",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"505",date:"2020-07-03",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"506",date:"2020-07-03",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"50A",date:"2020-07-17",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"50C",date:"2020-07-24",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"50E",date:"2020-08-14",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"511",date:"2020-05-29",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"512",date:"2020-05-29",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"513",date:"2020-06-12",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"514",date:"2020-06-19",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"515",date:"2020-07-03",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"516",date:"2020-07-03",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"51A",date:"2020-07-17",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"51C",date:"2020-07-24",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"51E",date:"2020-08-14",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"740",date:"2020-05-08",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"741",date:"2020-05-08",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"742",date:"2020-05-08",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"743",date:"2020-05-08",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"744",date:"2020-05-08",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"750",date:"2020-05-08",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"751",date:"2020-05-08",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"752",date:"2020-05-08",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"753",date:"2020-05-08",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"754",date:"2020-05-08",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"760",date:"2020-05-08",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"761",date:"2020-05-08",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"762",date:"2020-05-08",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"763",date:"2020-05-08",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"764",date:"2020-05-08",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"770",date:"2020-05-08",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"771",date:"2020-05-08",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"772",date:"2020-05-08",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"773",date:"2020-05-08",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"774",date:"2020-05-08",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"801",date:"2019-12-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"802",date:"2019-12-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"803",date:"2019-12-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"804",date:"2019-10-04",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"805",date:"2019-12-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"806",date:"2019-12-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"80A",date:"2020-02-14",PROG:60,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"80B",date:"2020-02-14",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"80C",date:"2020-02-21",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"80D",date:"2020-02-28",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"80E",date:"2020-02-21",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"80F",date:"2020-02-28",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"821",date:"2019-12-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"822",date:"2019-12-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"823",date:"2019-12-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"824",date:"2019-10-04",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"825",date:"2019-12-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"826",date:"2019-12-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"841",date:"2020-04-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"842",date:"2020-04-10",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"843",date:"2020-04-17",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"844",date:"2020-04-17",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"851",date:"2020-04-10",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"852",date:"2020-04-10",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"853",date:"2020-04-17",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"854",date:"2020-04-17",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"881",date:"2020-04-24",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"882",date:"2020-04-24",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"883",date:"2020-05-08",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"884",date:"2020-05-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"891",date:"2019-10-25",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"892",date:"2020-05-01",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"893",date:"2020-05-08",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"HL02",Activity:"Painting",SCHEDULE:"Start Date",name:"894",date:"2020-05-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"MD00a",Activity:"Painting",SCHEDULE:"Start Date",name:"HLRC01",date:"2020-08-14",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"MD00a",Activity:"Painting",SCHEDULE:"Start Date",name:"HLRC02",date:"2020-08-14",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"MD00a",Activity:"Painting",SCHEDULE:"Start Date",name:"HLRC03",date:"2020-08-14",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"MD00a",Activity:"Painting",SCHEDULE:"Start Date",name:"HLRC04",date:"2020-08-14",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"MD00a",Activity:"Painting",SCHEDULE:"Start Date",name:"HLRC05",date:"2020-08-14",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-AFT",GWBS_LV3:"MD00a",Activity:"Painting",SCHEDULE:"Start Date",name:"HLRC06",date:"2020-08-14",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"207",date:"2019-08-16",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"208",date:"2019-08-16",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"209",date:"2019-08-30",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"20G",date:"2019-10-25",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"20H",date:"2019-10-25",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"20I",date:"2019-11-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"20J",date:"2019-10-25",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"20K",date:"2019-11-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"20L",date:"2019-11-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"217",date:"2019-07-26",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"218",date:"2019-08-23",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"219",date:"2019-09-06",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"21G",date:"2019-12-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"21H",date:"2019-12-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"21I",date:"2019-12-27",PROG:60,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"21J",date:"2019-11-29",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"21K",date:"2019-12-20",PROG:60,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"21L",date:"2019-12-06",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"21V",date:"2019-09-06",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"227",date:"2019-10-04",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"228",date:"2019-10-18",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"229",date:"2019-10-18",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"22G",date:"2019-12-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"22H",date:"2019-12-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"22I",date:"2019-12-27",PROG:60,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"22J",date:"2019-11-29",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"22K",date:"2019-12-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"22L",date:"2019-12-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"22V",date:"2019-09-06",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"237",date:"2019-09-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"238",date:"2019-10-04",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"239",date:"2019-10-04",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"23V",date:"2019-09-06",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"241",date:"2019-09-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"242",date:"2019-08-23",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"243",date:"2019-09-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"24V",date:"2019-09-06",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"251",date:"2019-09-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"252",date:"2019-08-23",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"253",date:"2019-09-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"25V",date:"2019-09-06",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"261",date:"2019-09-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"262",date:"2019-11-01",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"263",date:"2019-10-11",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"26F",date:"2020-02-28",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"26V",date:"2019-09-06",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"271",date:"2019-09-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"272",date:"2019-11-01",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"273",date:"2019-11-08",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"27F",date:"2020-03-20",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"280",date:"2020-04-10",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"281",date:"2019-12-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"282",date:"2020-01-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"283",date:"2020-01-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"284",date:"2019-12-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"285",date:"2020-02-07",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"286",date:"2020-01-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"287",date:"2020-01-10",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"288",date:"2020-01-10",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"289",date:"2020-02-28",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"28F",date:"2020-03-06",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"290",date:"2020-03-27",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"291",date:"2019-12-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"292",date:"2020-01-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"293",date:"2020-01-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"294",date:"2020-01-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"295",date:"2020-02-14",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"296",date:"2020-01-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"297",date:"2020-01-10",PROG:60,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"298",date:"2020-01-10",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"299",date:"2020-02-21",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"29F",date:"2020-02-28",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"307",date:"2019-10-18",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"308",date:"2019-10-25",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"309",date:"2019-11-08",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"30G",date:"2020-04-24",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"30I",date:"2020-04-24",PROG:60,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"30K",date:"2020-04-10",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"317",date:"2019-10-25",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"318",date:"2019-10-11",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"319",date:"2019-10-25",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"31G",date:"2020-04-24",PROG:60,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"31I",date:"2020-04-17",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"31K",date:"2020-04-10",PROG:60,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"327",date:"2020-03-06",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"328",date:"2020-03-06",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"329",date:"2020-02-28",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"337",date:"2020-03-06",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"338",date:"2020-03-06",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"339",date:"2020-02-28",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"341",date:"2019-09-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"342",date:"2019-11-01",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"343",date:"2019-10-04",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"351",date:"2019-09-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"352",date:"2019-10-18",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"353",date:"2019-10-04",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"361",date:"2020-03-06",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"362",date:"2020-02-14",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"363",date:"2020-02-14",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"371",date:"2020-02-28",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"372",date:"2020-02-14",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"373",date:"2020-02-14",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"407",date:"2020-07-03",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"408",date:"2020-07-03",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"409",date:"2020-05-08",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"40G",date:"2020-08-14",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"40H",date:"2020-08-14",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"40I",date:"2020-07-03",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"40J",date:"2020-07-10",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"40K",date:"2020-07-17",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"40L",date:"2020-07-17",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"411",date:"2020-05-29",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"412",date:"2020-06-05",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"413",date:"2020-06-05",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"427",date:"2020-07-10",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"428",date:"2020-07-03",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"429",date:"2020-05-22",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"437",date:"2020-07-10",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"438",date:"2020-07-03",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"439",date:"2020-05-22",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"441",date:"2020-05-29",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"442",date:"2020-05-15",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"443",date:"2020-06-05",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"451",date:"2020-05-29",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"452",date:"2020-05-22",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"453",date:"2020-06-05",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"507",date:"2020-04-10",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"508",date:"2020-04-10",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"509",date:"2020-04-03",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"50G",date:"2020-05-29",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"50I",date:"2020-05-22",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"50K",date:"2020-05-15",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"517",date:"2020-04-10",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"518",date:"2020-04-10",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"519",date:"2020-04-03",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"51G",date:"2020-05-29",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"51I",date:"2020-05-22",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"51K",date:"2020-05-22",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"521",date:"2020-04-03",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"522",date:"2020-03-27",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"523",date:"2020-03-27",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"531",date:"2020-04-03",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"532",date:"2020-03-27",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"533",date:"2020-04-03",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"601",date:"2020-01-17",PROG:60,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"602",date:"2020-01-17",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"603",date:"2020-02-14",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"604",date:"2020-03-20",PROG:60,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"605",date:"2020-05-15",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"606",date:"2020-05-15",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"607",date:"2020-02-07",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"611",date:"2020-01-24",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"612",date:"2020-01-24",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"613",date:"2020-03-20",PROG:60,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"614",date:"2020-03-27",PROG:60,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"615",date:"2020-05-15",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"616",date:"2020-05-22",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"617",date:"2020-06-19",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"618",date:"2020-06-26",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"619",date:"2020-06-26",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"61A",date:"2020-03-27",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"61B",date:"2020-08-07",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"61F",date:"2020-04-17",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"621",date:"2020-01-17",PROG:60,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"622",date:"2020-01-17",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"623",date:"2020-02-14",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"624",date:"2020-03-27",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"625",date:"2020-05-15",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"626",date:"2020-05-15",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"627",date:"2020-02-07",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"62A",date:"2020-05-15",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"62B",date:"2020-08-07",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"62F",date:"2020-04-17",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"631",date:"2020-01-17",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"632",date:"2020-01-24",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"633",date:"2020-03-27",PROG:60,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"634",date:"2020-03-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"635",date:"2020-05-22",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"636",date:"2020-05-22",PROG:60,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"637",date:"2020-02-07",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"63A",date:"2020-05-08",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"63B",date:"2020-07-03",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"63F",date:"2020-04-17",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"64A",date:"2020-05-15",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"64B",date:"2020-07-03",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"64F",date:"2020-04-10",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"65A",date:"2020-07-03",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"65F",date:"2020-03-27",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"66A",date:"2020-07-03",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"66F",date:"2020-03-20",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"67A",date:"2020-07-03",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"67F",date:"2020-03-06",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"681",date:"2020-06-19",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"682",date:"2020-06-26",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"683",date:"2020-06-26",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"684",date:"2020-03-06",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"685",date:"2020-02-28",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"686",date:"2020-03-13",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"687",date:"2020-05-08",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"688",date:"2020-05-08",PROG:30,Status:"INPROGRESS"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"68A",date:"2020-07-10",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"68F",date:"2020-03-06",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"691",date:"2020-06-26",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"692",date:"2020-06-26",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"693",date:"2020-06-26",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"694",date:"2020-02-28",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"695",date:"2020-02-21",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"696",date:"2020-03-13",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"697",date:"2020-05-08",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"698",date:"2020-05-15",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"69A",date:"2020-07-31",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"69F",date:"2020-03-20",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"745",date:"2020-06-12",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"746",date:"2020-05-29",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"747",date:"2020-05-29",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"748",date:"2020-05-29",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"749",date:"2020-05-29",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"755",date:"2020-06-05",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"756",date:"2020-06-05",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"757",date:"2020-06-05",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"758",date:"2020-06-05",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"759",date:"2020-06-05",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"765",date:"2020-08-07",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"766",date:"2020-06-05",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"767",date:"2020-06-05",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"768",date:"2020-06-05",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"769",date:"2020-06-05",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"775",date:"2020-06-12",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"776",date:"2020-06-12",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"777",date:"2020-06-12",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"778",date:"2020-06-12",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"779",date:"2020-06-12",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"807",date:"2019-11-29",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"808",date:"2019-11-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"809",date:"2019-11-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"80G",date:"2020-01-17",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"80H",date:"2020-01-17",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"80I",date:"2020-01-10",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"80J",date:"2020-01-10",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"80K",date:"2020-01-10",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"80L",date:"2020-01-24",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"811",date:"2019-11-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"812",date:"2019-11-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"813",date:"2019-10-04",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"827",date:"2019-11-29",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"828",date:"2019-11-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"829",date:"2019-11-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"831",date:"2019-11-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"832",date:"2019-11-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"833",date:"2019-10-04",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"845",date:"2020-02-21",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"846",date:"2020-02-28",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"847",date:"2019-12-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"848",date:"2019-12-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"855",date:"2020-02-21",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"856",date:"2020-02-28",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"857",date:"2019-12-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"858",date:"2020-01-10",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"865",date:"2019-10-25",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"866",date:"2019-10-18",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"867",date:"2019-10-25",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"868",date:"2020-01-10",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"875",date:"2019-10-18",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"876",date:"2019-10-18",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"877",date:"2019-10-18",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"878",date:"2020-01-10",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"885",date:"2020-03-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"886",date:"2020-02-21",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"887",date:"2020-02-21",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"888",date:"2020-01-17",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"895",date:"2020-03-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"896",date:"2020-02-21",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"897",date:"2020-02-21",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"HL03",Activity:"Painting",SCHEDULE:"Start Date",name:"898",date:"2020-01-17",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"MD00f",Activity:"Painting",SCHEDULE:"Start Date",name:"HLRC07",date:"2020-07-03",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"MD00f",Activity:"Painting",SCHEDULE:"Start Date",name:"HLRC08",date:"2020-07-03",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"MD00f",Activity:"Painting",SCHEDULE:"Start Date",name:"HLRC09",date:"2020-07-03",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"MD00f",Activity:"Painting",SCHEDULE:"Start Date",name:"HLRC10",date:"2020-07-03",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"MD00f",Activity:"Painting",SCHEDULE:"Start Date",name:"HLRC11",date:"2020-07-03",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"HULL-FOR",GWBS_LV3:"MD00f",Activity:"Painting",SCHEDULE:"Start Date",name:"HLRC12",date:"2020-07-03",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"HD01",Activity:"Painting",SCHEDULE:"Start Date",name:"959",date:"2020-03-20",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"1901",date:"2020-07-31",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"1902",date:"2020-07-31",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"1903",date:"2020-08-07",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"1904",date:"2020-07-31",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"1905",date:"2020-08-07",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"1906",date:"2020-08-07",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"1907",date:"2020-08-07",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"1908",date:"2020-07-31",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"1909",date:"2020-08-14",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"2901",date:"2020-08-07",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"2902",date:"2020-07-31",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"2903",date:"2020-08-07",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"2904",date:"2020-07-31",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"2905",date:"2020-08-07",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"2906",date:"2020-08-07",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"2907",date:"2020-08-07",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"2908",date:"2020-08-07",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"3903",date:"2020-08-07",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"3904",date:"2020-07-31",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"3905",date:"2020-08-07",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"3906",date:"2020-08-14",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"3907",date:"2020-08-07",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"3908",date:"2020-08-07",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"4904",date:"2020-07-31",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"4905",date:"2020-08-07",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"4907",date:"2020-08-07",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"4908",date:"2020-07-31",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"5904",date:"2020-07-31",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"5905",date:"2020-08-14",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"5907",date:"2020-08-07",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"6907",date:"2020-08-07",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"7907",date:"2020-08-07",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"8907",date:"2020-08-14",PROG:0,Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"901",date:"2019-11-08",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"902",date:"2019-11-08",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"903",date:"2019-11-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"904",date:"2019-11-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"905",date:"2020-01-17",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"906",date:"2020-01-17",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"907",date:"2019-11-15",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"908",date:"2019-11-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"909",date:"2019-11-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"90A",date:"2020-01-10",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"90B",date:"2020-01-10",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"911",date:"2019-12-06",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"912",date:"2019-12-06",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"913",date:"2019-12-06",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"914",date:"2019-12-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"915",date:"2019-12-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"916",date:"2019-12-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"917",date:"2019-12-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"918",date:"2019-12-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"91A",date:"2020-02-14",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"91B",date:"2020-02-14",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"921",date:"2019-12-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"922",date:"2019-12-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"923",date:"2019-12-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"924",date:"2019-12-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"925",date:"2020-01-17",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"926",date:"2020-01-24",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"927",date:"2019-12-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"928",date:"2020-01-24",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"92A",date:"2020-02-28",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"92B",date:"2020-02-28",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"931",date:"2020-01-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"932",date:"2020-01-10",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"933",date:"2020-01-10",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"934",date:"2020-01-10",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"935",date:"2020-02-07",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"936",date:"2020-01-10",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"937",date:"2020-02-14",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"938",date:"2020-02-14",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"93A",date:"2020-03-06",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"93B",date:"2020-03-13",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"941",date:"2020-02-14",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"942",date:"2020-02-21",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"943",date:"2020-02-21",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"944",date:"2020-02-21",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"945",date:"2020-02-28",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"946",date:"2020-02-28",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"947",date:"2020-02-28",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"948",date:"2020-03-06",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"94A",date:"2020-04-03",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"94B",date:"2020-04-03",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"951",date:"2020-03-06",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"952",date:"2020-03-06",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"953",date:"2020-03-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"954",date:"2020-03-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"955",date:"2020-03-13",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"956",date:"2020-03-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"957",date:"2020-03-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"958",date:"2020-03-20",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"95A",date:"2020-04-17",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"95B",date:"2020-04-24",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"961",date:"2020-03-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"962",date:"2020-04-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"963",date:"2020-04-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"964",date:"2020-04-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"965",date:"2020-04-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"966",date:"2020-03-27",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"96A",date:"2020-05-01",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"96B",date:"2020-05-08",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"971",date:"2020-04-10",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"972",date:"2020-04-10",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"973",date:"2020-04-17",Status:"NOTSTARTED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"9907",date:"2020-08-07",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"991",date:"2019-11-01",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"992",date:"2019-11-22",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"993",date:"2019-10-25",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"994",date:"2019-11-29",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"995",date:"2020-01-03",PROG:100,Status:"COMPLETED"},
      {GWBS_LV1:"HULL&LQ",GWBS_LV2:"LQ",GWBS_LV3:"LQ00",Activity:"Painting",SCHEDULE:"Start Date",name:"996",date:"2020-01-03",PROG:100,Status:"COMPLETED"}
  ],
  Queries: {
    SQL1: [
      { DESC: 'Plan is Baseline Rev. A24 as of 2020-01-01' }
    ],
    SQL2: [
      { DESC: 'Actual is Easy Plant Data  as of 2020-04-17' }
    ],
    SQL3: [],
    SQL4: [
      {TOTAL:483,NOTSTARTED:10,INPROGRESS:113,COMPLETED:360}
    ]
  }
}