import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 600,
      CanvasHeight: 380,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 380,
      CanvasChartHeight: 280,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Weight : ', x:240, y: 220, font: 'roboto', class: 'regular', size: 11, color: '#BCBCBC', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: 350, y: 200, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'end' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: 350,
          y: 220,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'end'
        },
      ]
    },
    DataItems: [
      {WK_NO:"W2019_25",FAT_PLAN_CUM:0,FAT_FORECAST_CUM:0,FAT_ACTUAL_CUM:0,IRN_PLAN_CUM:0,IRN_FORECAST_CUM:0,IRN_ACTUAL_CUM:0,ETA_PLAN_CUM:0,ETA_FORECAST_CUM:0,ETA_ACTUAL_CUM:0},
      {WK_NO:"W2019_26",FAT_PLAN_CUM:0,FAT_FORECAST_CUM:0,FAT_ACTUAL_CUM:0,IRN_PLAN_CUM:0,IRN_FORECAST_CUM:0,IRN_ACTUAL_CUM:0,ETA_PLAN_CUM:0,ETA_FORECAST_CUM:0,ETA_ACTUAL_CUM:0},
      {WK_NO:"W2019_27",FAT_PLAN_CUM:1,FAT_FORECAST_CUM:1,FAT_ACTUAL_CUM:1,IRN_PLAN_CUM:0,IRN_FORECAST_CUM:0,IRN_ACTUAL_CUM:0,ETA_PLAN_CUM:0,ETA_FORECAST_CUM:0,ETA_ACTUAL_CUM:0},
      {WK_NO:"W2019_28",FAT_PLAN_CUM:1,FAT_FORECAST_CUM:1,FAT_ACTUAL_CUM:1,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2019_29",FAT_PLAN_CUM:1,FAT_FORECAST_CUM:1,FAT_ACTUAL_CUM:1,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2019_30",FAT_PLAN_CUM:1,FAT_FORECAST_CUM:1,FAT_ACTUAL_CUM:1,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2019_31",FAT_PLAN_CUM:1,FAT_FORECAST_CUM:1,FAT_ACTUAL_CUM:1,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2019_32",FAT_PLAN_CUM:1,FAT_FORECAST_CUM:1,FAT_ACTUAL_CUM:1,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2019_33",FAT_PLAN_CUM:1,FAT_FORECAST_CUM:1,FAT_ACTUAL_CUM:1,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2019_34",FAT_PLAN_CUM:1,FAT_FORECAST_CUM:1,FAT_ACTUAL_CUM:1,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2019_35",FAT_PLAN_CUM:1,FAT_FORECAST_CUM:1,FAT_ACTUAL_CUM:1,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2019_36",FAT_PLAN_CUM:1,FAT_FORECAST_CUM:1,FAT_ACTUAL_CUM:1,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2019_37",FAT_PLAN_CUM:1,FAT_FORECAST_CUM:1,FAT_ACTUAL_CUM:1,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2019_38",FAT_PLAN_CUM:1,FAT_FORECAST_CUM:1,FAT_ACTUAL_CUM:1,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2019_39",FAT_PLAN_CUM:1,FAT_FORECAST_CUM:1,FAT_ACTUAL_CUM:1,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2019_40",FAT_PLAN_CUM:1,FAT_FORECAST_CUM:1,FAT_ACTUAL_CUM:1,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2019_41",FAT_PLAN_CUM:1,FAT_FORECAST_CUM:1,FAT_ACTUAL_CUM:1,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2019_42",FAT_PLAN_CUM:1,FAT_FORECAST_CUM:1,FAT_ACTUAL_CUM:1,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2019_43",FAT_PLAN_CUM:1,FAT_FORECAST_CUM:1,FAT_ACTUAL_CUM:1,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2019_44",FAT_PLAN_CUM:1,FAT_FORECAST_CUM:1,FAT_ACTUAL_CUM:1,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2019_45",FAT_PLAN_CUM:1,FAT_FORECAST_CUM:1,FAT_ACTUAL_CUM:1,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2019_46",FAT_PLAN_CUM:1,FAT_FORECAST_CUM:1,FAT_ACTUAL_CUM:1,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2019_47",FAT_PLAN_CUM:1,FAT_FORECAST_CUM:1,FAT_ACTUAL_CUM:1,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2019_48",FAT_PLAN_CUM:1,FAT_FORECAST_CUM:1,FAT_ACTUAL_CUM:1,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2019_49",FAT_PLAN_CUM:1,FAT_FORECAST_CUM:1,FAT_ACTUAL_CUM:1,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2019_50",FAT_PLAN_CUM:1,FAT_FORECAST_CUM:1,FAT_ACTUAL_CUM:1,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2019_51",FAT_PLAN_CUM:1,FAT_FORECAST_CUM:1,FAT_ACTUAL_CUM:1,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2019_52",FAT_PLAN_CUM:1,FAT_FORECAST_CUM:1,FAT_ACTUAL_CUM:1,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2020_01",FAT_PLAN_CUM:1,FAT_FORECAST_CUM:1,FAT_ACTUAL_CUM:1,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2020_02",FAT_PLAN_CUM:1,FAT_FORECAST_CUM:1,FAT_ACTUAL_CUM:1,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2020_03",FAT_PLAN_CUM:2,FAT_FORECAST_CUM:2,FAT_ACTUAL_CUM:2,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2020_04",FAT_PLAN_CUM:2,FAT_FORECAST_CUM:2,FAT_ACTUAL_CUM:2,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2020_05",FAT_PLAN_CUM:2,FAT_FORECAST_CUM:2,FAT_ACTUAL_CUM:2,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2020_06",FAT_PLAN_CUM:2,FAT_FORECAST_CUM:2,FAT_ACTUAL_CUM:2,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2020_07",FAT_PLAN_CUM:2,FAT_FORECAST_CUM:2,FAT_ACTUAL_CUM:2,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2020_08",FAT_PLAN_CUM:3,FAT_FORECAST_CUM:3,FAT_ACTUAL_CUM:3,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2020_09",FAT_PLAN_CUM:3,FAT_FORECAST_CUM:3,FAT_ACTUAL_CUM:3,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2020_10",FAT_PLAN_CUM:3,FAT_FORECAST_CUM:3,FAT_ACTUAL_CUM:5,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2020_11",FAT_PLAN_CUM:3,FAT_FORECAST_CUM:3,FAT_ACTUAL_CUM:5,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2020_12",FAT_PLAN_CUM:3,FAT_FORECAST_CUM:3,FAT_ACTUAL_CUM:5,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2020_13",FAT_PLAN_CUM:3,FAT_FORECAST_CUM:3,FAT_ACTUAL_CUM:5,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2020_14",FAT_PLAN_CUM:5,FAT_FORECAST_CUM:5,FAT_ACTUAL_CUM:7,IRN_PLAN_CUM:1,IRN_FORECAST_CUM:1,IRN_ACTUAL_CUM:1,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2020_15",FAT_PLAN_CUM:6,FAT_FORECAST_CUM:6,FAT_ACTUAL_CUM:8,IRN_PLAN_CUM:2,IRN_FORECAST_CUM:2,IRN_ACTUAL_CUM:2,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2020_16",FAT_PLAN_CUM:6,FAT_FORECAST_CUM:6,FAT_ACTUAL_CUM:8,IRN_PLAN_CUM:2,IRN_FORECAST_CUM:2,IRN_ACTUAL_CUM:2,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2020_17",FAT_PLAN_CUM:6,FAT_FORECAST_CUM:6,FAT_ACTUAL_CUM:8,IRN_PLAN_CUM:2,IRN_FORECAST_CUM:2,IRN_ACTUAL_CUM:2,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2020_18",FAT_PLAN_CUM:8,FAT_FORECAST_CUM:8,FAT_ACTUAL_CUM:8,IRN_PLAN_CUM:3,IRN_FORECAST_CUM:3,IRN_ACTUAL_CUM:2,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2020_19",FAT_PLAN_CUM:8,FAT_FORECAST_CUM:8,FAT_ACTUAL_CUM:8,IRN_PLAN_CUM:3,IRN_FORECAST_CUM:3,IRN_ACTUAL_CUM:2,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2020_20",FAT_PLAN_CUM:8,FAT_FORECAST_CUM:8,FAT_ACTUAL_CUM:8,IRN_PLAN_CUM:5,IRN_FORECAST_CUM:5,IRN_ACTUAL_CUM:4,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2020_21",FAT_PLAN_CUM:8,FAT_FORECAST_CUM:8,FAT_ACTUAL_CUM:8,IRN_PLAN_CUM:8,IRN_FORECAST_CUM:8,IRN_ACTUAL_CUM:8,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2020_22",FAT_PLAN_CUM:8,FAT_FORECAST_CUM:8,FAT_ACTUAL_CUM:8,IRN_PLAN_CUM:8,IRN_FORECAST_CUM:8,IRN_ACTUAL_CUM:8,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2020_23",FAT_PLAN_CUM:9,FAT_FORECAST_CUM:9,FAT_ACTUAL_CUM:9,IRN_PLAN_CUM:8,IRN_FORECAST_CUM:8,IRN_ACTUAL_CUM:8,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2020_24",FAT_PLAN_CUM:9,FAT_FORECAST_CUM:9,FAT_ACTUAL_CUM:9,IRN_PLAN_CUM:9,IRN_FORECAST_CUM:9,IRN_ACTUAL_CUM:9,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2020_25",FAT_PLAN_CUM:12,FAT_FORECAST_CUM:12,FAT_ACTUAL_CUM:12,IRN_PLAN_CUM:9,IRN_FORECAST_CUM:9,IRN_ACTUAL_CUM:9,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2020_26",FAT_PLAN_CUM:12,FAT_FORECAST_CUM:12,FAT_ACTUAL_CUM:12,IRN_PLAN_CUM:9,IRN_FORECAST_CUM:9,IRN_ACTUAL_CUM:9,ETA_PLAN_CUM:1,ETA_FORECAST_CUM:1,ETA_ACTUAL_CUM:1},
      {WK_NO:"W2020_27",FAT_PLAN_CUM:13,FAT_FORECAST_CUM:13,FAT_ACTUAL_CUM:13,IRN_PLAN_CUM:10,IRN_FORECAST_CUM:10,IRN_ACTUAL_CUM:9,ETA_PLAN_CUM:2,ETA_FORECAST_CUM:2,ETA_ACTUAL_CUM:2},
      {WK_NO:"W2020_28",FAT_PLAN_CUM:13,FAT_FORECAST_CUM:13,FAT_ACTUAL_CUM:13,IRN_PLAN_CUM:10,IRN_FORECAST_CUM:10,IRN_ACTUAL_CUM:10,ETA_PLAN_CUM:5,ETA_FORECAST_CUM:5,ETA_ACTUAL_CUM:5},
      {WK_NO:"W2020_29",FAT_PLAN_CUM:13,FAT_FORECAST_CUM:13,FAT_ACTUAL_CUM:13,IRN_PLAN_CUM:12,IRN_FORECAST_CUM:12,IRN_ACTUAL_CUM:12,ETA_PLAN_CUM:5,ETA_FORECAST_CUM:5,ETA_ACTUAL_CUM:5},
      {WK_NO:"W2020_30",FAT_PLAN_CUM:15,FAT_FORECAST_CUM:15,FAT_ACTUAL_CUM:15,IRN_PLAN_CUM:12,IRN_FORECAST_CUM:12,IRN_ACTUAL_CUM:12,ETA_PLAN_CUM:7,ETA_FORECAST_CUM:7,ETA_ACTUAL_CUM:7},
      {WK_NO:"W2020_31",FAT_PLAN_CUM:17,FAT_FORECAST_CUM:17,FAT_ACTUAL_CUM:17,IRN_PLAN_CUM:13,IRN_FORECAST_CUM:13,IRN_ACTUAL_CUM:13,ETA_PLAN_CUM:8,ETA_FORECAST_CUM:8,ETA_ACTUAL_CUM:8},
      {WK_NO:"W2020_32",FAT_PLAN_CUM:17,FAT_FORECAST_CUM:17,FAT_ACTUAL_CUM:17,IRN_PLAN_CUM:13,IRN_FORECAST_CUM:13,IRN_ACTUAL_CUM:13,ETA_PLAN_CUM:8,ETA_FORECAST_CUM:8,ETA_ACTUAL_CUM:8},
      {WK_NO:"W2020_33",FAT_PLAN_CUM:19,FAT_FORECAST_CUM:19,FAT_ACTUAL_CUM:19,IRN_PLAN_CUM:13,IRN_FORECAST_CUM:13,IRN_ACTUAL_CUM:13,ETA_PLAN_CUM:8,ETA_FORECAST_CUM:8,ETA_ACTUAL_CUM:8},
      {WK_NO:"W2020_34",FAT_PLAN_CUM:20,FAT_FORECAST_CUM:20,FAT_ACTUAL_CUM:20,IRN_PLAN_CUM:13,IRN_FORECAST_CUM:13,IRN_ACTUAL_CUM:13,ETA_PLAN_CUM:9,ETA_FORECAST_CUM:9,ETA_ACTUAL_CUM:9},
      {WK_NO:"W2020_35",FAT_PLAN_CUM:21,FAT_FORECAST_CUM:21,FAT_ACTUAL_CUM:21,IRN_PLAN_CUM:14,IRN_FORECAST_CUM:14,IRN_ACTUAL_CUM:14,ETA_PLAN_CUM:9,ETA_FORECAST_CUM:9,ETA_ACTUAL_CUM:9},
      {WK_NO:"W2020_36",FAT_PLAN_CUM:23,FAT_FORECAST_CUM:23,FAT_ACTUAL_CUM:23,IRN_PLAN_CUM:14,IRN_FORECAST_CUM:14,IRN_ACTUAL_CUM:16,ETA_PLAN_CUM:9,ETA_FORECAST_CUM:9,ETA_ACTUAL_CUM:9},
      {WK_NO:"W2020_37",FAT_PLAN_CUM:24,FAT_FORECAST_CUM:24,FAT_ACTUAL_CUM:24,IRN_PLAN_CUM:16,IRN_FORECAST_CUM:16,IRN_ACTUAL_CUM:18,ETA_PLAN_CUM:9,ETA_FORECAST_CUM:9,ETA_ACTUAL_CUM:9},
      {WK_NO:"W2020_38",FAT_PLAN_CUM:24,FAT_FORECAST_CUM:24,FAT_ACTUAL_CUM:24,IRN_PLAN_CUM:17,IRN_FORECAST_CUM:17,IRN_ACTUAL_CUM:20,ETA_PLAN_CUM:9,ETA_FORECAST_CUM:9,ETA_ACTUAL_CUM:9},
      {WK_NO:"W2020_39",FAT_PLAN_CUM:26,FAT_FORECAST_CUM:26,FAT_ACTUAL_CUM:26,IRN_PLAN_CUM:19,IRN_FORECAST_CUM:19,IRN_ACTUAL_CUM:22,ETA_PLAN_CUM:11,ETA_FORECAST_CUM:11,ETA_ACTUAL_CUM:11},
      {WK_NO:"W2020_40",FAT_PLAN_CUM:27,FAT_FORECAST_CUM:27,FAT_ACTUAL_CUM:27,IRN_PLAN_CUM:20,IRN_FORECAST_CUM:20,IRN_ACTUAL_CUM:23,ETA_PLAN_CUM:12,ETA_FORECAST_CUM:12,ETA_ACTUAL_CUM:12},
      {WK_NO:"W2020_41",FAT_PLAN_CUM:27,FAT_FORECAST_CUM:27,FAT_ACTUAL_CUM:27,IRN_PLAN_CUM:21,IRN_FORECAST_CUM:21,IRN_ACTUAL_CUM:25,ETA_PLAN_CUM:12,ETA_FORECAST_CUM:12,ETA_ACTUAL_CUM:12},
      {WK_NO:"W2020_42",FAT_PLAN_CUM:30,FAT_FORECAST_CUM:31,FAT_ACTUAL_CUM:31,IRN_PLAN_CUM:23,IRN_FORECAST_CUM:23,IRN_ACTUAL_CUM:28,ETA_PLAN_CUM:15,ETA_FORECAST_CUM:14,ETA_ACTUAL_CUM:14},
      {WK_NO:"W2020_43",FAT_PLAN_CUM:31,FAT_FORECAST_CUM:32,FAT_ACTUAL_CUM:32,IRN_PLAN_CUM:24,IRN_FORECAST_CUM:23,IRN_ACTUAL_CUM:28,ETA_PLAN_CUM:16,ETA_FORECAST_CUM:16,ETA_ACTUAL_CUM:16},
      {WK_NO:"W2020_44",FAT_PLAN_CUM:32,FAT_FORECAST_CUM:32,FAT_ACTUAL_CUM:32,IRN_PLAN_CUM:29,IRN_FORECAST_CUM:29,IRN_ACTUAL_CUM:30,ETA_PLAN_CUM:17,ETA_FORECAST_CUM:16,ETA_ACTUAL_CUM:16},
      {WK_NO:"W2020_45",FAT_PLAN_CUM:32,FAT_FORECAST_CUM:32,IRN_PLAN_CUM:30,IRN_FORECAST_CUM:30,ETA_PLAN_CUM:18,ETA_FORECAST_CUM:16},
      {WK_NO:"W2020_46",FAT_PLAN_CUM:32,FAT_FORECAST_CUM:33,IRN_PLAN_CUM:31,IRN_FORECAST_CUM:31,ETA_PLAN_CUM:18,ETA_FORECAST_CUM:18},
      {WK_NO:"W2020_47",FAT_PLAN_CUM:34,FAT_FORECAST_CUM:37,IRN_PLAN_CUM:31,IRN_FORECAST_CUM:31,ETA_PLAN_CUM:19,ETA_FORECAST_CUM:18},
      {WK_NO:"W2020_48",FAT_PLAN_CUM:35,FAT_FORECAST_CUM:38,IRN_PLAN_CUM:32,IRN_FORECAST_CUM:32,ETA_PLAN_CUM:20,ETA_FORECAST_CUM:20},
      {WK_NO:"W2020_49",FAT_PLAN_CUM:36,FAT_FORECAST_CUM:39,IRN_PLAN_CUM:33,IRN_FORECAST_CUM:33,ETA_PLAN_CUM:22,ETA_FORECAST_CUM:22},
      {WK_NO:"W2020_50",FAT_PLAN_CUM:37,FAT_FORECAST_CUM:39,IRN_PLAN_CUM:33,IRN_FORECAST_CUM:33,ETA_PLAN_CUM:24,ETA_FORECAST_CUM:24},
      {WK_NO:"W2020_51",FAT_PLAN_CUM:38,FAT_FORECAST_CUM:40,IRN_PLAN_CUM:35,IRN_FORECAST_CUM:35,ETA_PLAN_CUM:25,ETA_FORECAST_CUM:24},
      {WK_NO:"W2020_52",FAT_PLAN_CUM:40,FAT_FORECAST_CUM:41,IRN_PLAN_CUM:37,IRN_FORECAST_CUM:38,ETA_PLAN_CUM:25,ETA_FORECAST_CUM:24},
      {WK_NO:"W2021_01",FAT_PLAN_CUM:40,FAT_FORECAST_CUM:42,IRN_PLAN_CUM:39,IRN_FORECAST_CUM:39,ETA_PLAN_CUM:30,ETA_FORECAST_CUM:29},
      {WK_NO:"W2021_02",FAT_PLAN_CUM:40,FAT_FORECAST_CUM:42,IRN_PLAN_CUM:40,IRN_FORECAST_CUM:40,ETA_PLAN_CUM:32,ETA_FORECAST_CUM:34},
      {WK_NO:"W2021_03",FAT_PLAN_CUM:40,FAT_FORECAST_CUM:42,IRN_PLAN_CUM:40,IRN_FORECAST_CUM:41,ETA_PLAN_CUM:34,ETA_FORECAST_CUM:35},
      {WK_NO:"W2021_04",FAT_PLAN_CUM:42,FAT_FORECAST_CUM:45,IRN_PLAN_CUM:40,IRN_FORECAST_CUM:41,ETA_PLAN_CUM:34,ETA_FORECAST_CUM:35},
      {WK_NO:"W2021_05",FAT_PLAN_CUM:42,FAT_FORECAST_CUM:45,IRN_PLAN_CUM:40,IRN_FORECAST_CUM:41,ETA_PLAN_CUM:35,ETA_FORECAST_CUM:36},
      {WK_NO:"W2021_06",FAT_PLAN_CUM:42,FAT_FORECAST_CUM:45,IRN_PLAN_CUM:42,IRN_FORECAST_CUM:44,ETA_PLAN_CUM:36,ETA_FORECAST_CUM:37},
      {WK_NO:"W2021_07",FAT_PLAN_CUM:42,FAT_FORECAST_CUM:45,IRN_PLAN_CUM:42,IRN_FORECAST_CUM:44,ETA_PLAN_CUM:38,ETA_FORECAST_CUM:39},
      {WK_NO:"W2021_08",FAT_PLAN_CUM:44,FAT_FORECAST_CUM:47,IRN_PLAN_CUM:42,IRN_FORECAST_CUM:44,ETA_PLAN_CUM:38,ETA_FORECAST_CUM:39},
      {WK_NO:"W2021_09",FAT_PLAN_CUM:44,FAT_FORECAST_CUM:47,IRN_PLAN_CUM:42,IRN_FORECAST_CUM:44,ETA_PLAN_CUM:39,ETA_FORECAST_CUM:40},
      {WK_NO:"W2021_10",FAT_PLAN_CUM:44,FAT_FORECAST_CUM:47,IRN_PLAN_CUM:43,IRN_FORECAST_CUM:45,ETA_PLAN_CUM:40,ETA_FORECAST_CUM:40},
      {WK_NO:"W2021_11",FAT_PLAN_CUM:45,FAT_FORECAST_CUM:48,IRN_PLAN_CUM:43,IRN_FORECAST_CUM:45,ETA_PLAN_CUM:41,ETA_FORECAST_CUM:41},
      {WK_NO:"W2021_12",FAT_PLAN_CUM:45,FAT_FORECAST_CUM:48,IRN_PLAN_CUM:44,IRN_FORECAST_CUM:46,ETA_PLAN_CUM:41,ETA_FORECAST_CUM:41},
      {WK_NO:"W2021_13",FAT_PLAN_CUM:47,FAT_FORECAST_CUM:49,IRN_PLAN_CUM:44,IRN_FORECAST_CUM:46,ETA_PLAN_CUM:41,ETA_FORECAST_CUM:41},
      {WK_NO:"W2021_14",FAT_PLAN_CUM:48,FAT_FORECAST_CUM:49,IRN_PLAN_CUM:45,IRN_FORECAST_CUM:47,ETA_PLAN_CUM:41,ETA_FORECAST_CUM:41},
      {WK_NO:"W2021_15",FAT_PLAN_CUM:48,FAT_FORECAST_CUM:49,IRN_PLAN_CUM:47,IRN_FORECAST_CUM:49,ETA_PLAN_CUM:42,ETA_FORECAST_CUM:43},
      {WK_NO:"W2021_16",FAT_PLAN_CUM:49,FAT_FORECAST_CUM:50,IRN_PLAN_CUM:48,IRN_FORECAST_CUM:49,ETA_PLAN_CUM:42,ETA_FORECAST_CUM:43},
      {WK_NO:"W2021_17",FAT_PLAN_CUM:50,FAT_FORECAST_CUM:50,IRN_PLAN_CUM:48,IRN_FORECAST_CUM:49,ETA_PLAN_CUM:42,ETA_FORECAST_CUM:43},
      {WK_NO:"W2021_18",FAT_PLAN_CUM:50,FAT_FORECAST_CUM:50,IRN_PLAN_CUM:49,IRN_FORECAST_CUM:50,ETA_PLAN_CUM:45,ETA_FORECAST_CUM:46},
      {WK_NO:"W2021_19",FAT_PLAN_CUM:51,FAT_FORECAST_CUM:51,IRN_PLAN_CUM:50,IRN_FORECAST_CUM:50,ETA_PLAN_CUM:45,ETA_FORECAST_CUM:46},
      {WK_NO:"W2021_20",FAT_PLAN_CUM:51,FAT_FORECAST_CUM:51,IRN_PLAN_CUM:50,IRN_FORECAST_CUM:50,ETA_PLAN_CUM:45,ETA_FORECAST_CUM:46},
      {WK_NO:"W2021_21",FAT_PLAN_CUM:53,FAT_FORECAST_CUM:52,IRN_PLAN_CUM:51,IRN_FORECAST_CUM:51,ETA_PLAN_CUM:46,ETA_FORECAST_CUM:47},
      {WK_NO:"W2021_22",FAT_PLAN_CUM:56,FAT_FORECAST_CUM:56,IRN_PLAN_CUM:52,IRN_FORECAST_CUM:52,ETA_PLAN_CUM:47,ETA_FORECAST_CUM:48},
      {WK_NO:"W2021_23",FAT_PLAN_CUM:58,FAT_FORECAST_CUM:58,IRN_PLAN_CUM:53,IRN_FORECAST_CUM:52,ETA_PLAN_CUM:48,ETA_FORECAST_CUM:49},
      {WK_NO:"W2021_24",FAT_PLAN_CUM:58,FAT_FORECAST_CUM:58,IRN_PLAN_CUM:55,IRN_FORECAST_CUM:55,ETA_PLAN_CUM:48,ETA_FORECAST_CUM:49},
      {WK_NO:"W2021_25",FAT_PLAN_CUM:58,FAT_FORECAST_CUM:58,IRN_PLAN_CUM:57,IRN_FORECAST_CUM:57,ETA_PLAN_CUM:48,ETA_FORECAST_CUM:49},
      {WK_NO:"W2021_26",FAT_PLAN_CUM:58,FAT_FORECAST_CUM:58,IRN_PLAN_CUM:57,IRN_FORECAST_CUM:57,ETA_PLAN_CUM:50,ETA_FORECAST_CUM:51},
      {WK_NO:"W2021_27",FAT_PLAN_CUM:58,FAT_FORECAST_CUM:58,IRN_PLAN_CUM:57,IRN_FORECAST_CUM:57,ETA_PLAN_CUM:52,ETA_FORECAST_CUM:52},
      {WK_NO:"W2021_28",FAT_PLAN_CUM:60,FAT_FORECAST_CUM:60,IRN_PLAN_CUM:57,IRN_FORECAST_CUM:57,ETA_PLAN_CUM:53,ETA_FORECAST_CUM:53},
      {WK_NO:"W2021_29",FAT_PLAN_CUM:60,FAT_FORECAST_CUM:60,IRN_PLAN_CUM:57,IRN_FORECAST_CUM:57,ETA_PLAN_CUM:55,ETA_FORECAST_CUM:55},
      {WK_NO:"W2021_30",FAT_PLAN_CUM:60,FAT_FORECAST_CUM:60,IRN_PLAN_CUM:59,IRN_FORECAST_CUM:59,ETA_PLAN_CUM:56,ETA_FORECAST_CUM:55},
      {WK_NO:"W2021_31",FAT_PLAN_CUM:60,FAT_FORECAST_CUM:60,IRN_PLAN_CUM:59,IRN_FORECAST_CUM:59,ETA_PLAN_CUM:56,ETA_FORECAST_CUM:56},
      {WK_NO:"W2021_32",FAT_PLAN_CUM:60,FAT_FORECAST_CUM:60,IRN_PLAN_CUM:59,IRN_FORECAST_CUM:59,ETA_PLAN_CUM:56,ETA_FORECAST_CUM:56},
      {WK_NO:"W2021_33",FAT_PLAN_CUM:60,FAT_FORECAST_CUM:60,IRN_PLAN_CUM:59,IRN_FORECAST_CUM:59,ETA_PLAN_CUM:57,ETA_FORECAST_CUM:57},
      {WK_NO:"W2021_34",FAT_PLAN_CUM:60,FAT_FORECAST_CUM:60,IRN_PLAN_CUM:59,IRN_FORECAST_CUM:59,ETA_PLAN_CUM:57,ETA_FORECAST_CUM:57},
      {WK_NO:"W2021_35",FAT_PLAN_CUM:60,FAT_FORECAST_CUM:60,IRN_PLAN_CUM:59,IRN_FORECAST_CUM:59,ETA_PLAN_CUM:57,ETA_FORECAST_CUM:57},
      {WK_NO:"W2021_36",FAT_PLAN_CUM:60,FAT_FORECAST_CUM:60,IRN_PLAN_CUM:59,IRN_FORECAST_CUM:59,ETA_PLAN_CUM:58,ETA_FORECAST_CUM:58},
      {WK_NO:"W2021_37",FAT_PLAN_CUM:60,FAT_FORECAST_CUM:60,IRN_PLAN_CUM:59,IRN_FORECAST_CUM:59,ETA_PLAN_CUM:58,ETA_FORECAST_CUM:58},
      {WK_NO:"W2021_38",FAT_PLAN_CUM:60,FAT_FORECAST_CUM:60,IRN_PLAN_CUM:59,IRN_FORECAST_CUM:59,ETA_PLAN_CUM:59,ETA_FORECAST_CUM:59},
      {WK_NO:"W2021_39",FAT_PLAN_CUM:60,FAT_FORECAST_CUM:60,IRN_PLAN_CUM:59,IRN_FORECAST_CUM:59,ETA_PLAN_CUM:59,ETA_FORECAST_CUM:59},
      {WK_NO:"W2021_40",FAT_PLAN_CUM:60,FAT_FORECAST_CUM:60,IRN_PLAN_CUM:59,IRN_FORECAST_CUM:59,ETA_PLAN_CUM:59,ETA_FORECAST_CUM:59},
      {WK_NO:"W2021_41",FAT_PLAN_CUM:60,FAT_FORECAST_CUM:60,IRN_PLAN_CUM:59,IRN_FORECAST_CUM:59,ETA_PLAN_CUM:59,ETA_FORECAST_CUM:59},
      {WK_NO:"W2021_42",FAT_PLAN_CUM:60,FAT_FORECAST_CUM:60,IRN_PLAN_CUM:59,IRN_FORECAST_CUM:59,ETA_PLAN_CUM:59,ETA_FORECAST_CUM:59},
      {WK_NO:"W2021_43",FAT_PLAN_CUM:60,FAT_FORECAST_CUM:60,IRN_PLAN_CUM:59,IRN_FORECAST_CUM:59,ETA_PLAN_CUM:59,ETA_FORECAST_CUM:59},
      {WK_NO:"W2021_44",FAT_PLAN_CUM:60,FAT_FORECAST_CUM:60,IRN_PLAN_CUM:59,IRN_FORECAST_CUM:59,ETA_PLAN_CUM:59,ETA_FORECAST_CUM:59},
      {WK_NO:"W2021_45",FAT_PLAN_CUM:60,FAT_FORECAST_CUM:60,IRN_PLAN_CUM:59,IRN_FORECAST_CUM:59,ETA_PLAN_CUM:59,ETA_FORECAST_CUM:59},
      {WK_NO:"W2021_46",FAT_PLAN_CUM:60,FAT_FORECAST_CUM:60,IRN_PLAN_CUM:59,IRN_FORECAST_CUM:59,ETA_PLAN_CUM:59,ETA_FORECAST_CUM:59},
      {WK_NO:"W2021_47",FAT_PLAN_CUM:62,FAT_FORECAST_CUM:62,IRN_PLAN_CUM:59,IRN_FORECAST_CUM:59,ETA_PLAN_CUM:59,ETA_FORECAST_CUM:59},
      {WK_NO:"W2021_48",FAT_PLAN_CUM:62,FAT_FORECAST_CUM:62,IRN_PLAN_CUM:59,IRN_FORECAST_CUM:59,ETA_PLAN_CUM:59,ETA_FORECAST_CUM:59},
      {WK_NO:"W2021_49",FAT_PLAN_CUM:62,FAT_FORECAST_CUM:62,IRN_PLAN_CUM:59,IRN_FORECAST_CUM:59,ETA_PLAN_CUM:59,ETA_FORECAST_CUM:59},
      {WK_NO:"W2021_50",FAT_PLAN_CUM:62,FAT_FORECAST_CUM:62,IRN_PLAN_CUM:59,IRN_FORECAST_CUM:59,ETA_PLAN_CUM:59,ETA_FORECAST_CUM:59},
      {WK_NO:"W2021_51",FAT_PLAN_CUM:62,FAT_FORECAST_CUM:62,IRN_PLAN_CUM:60,IRN_FORECAST_CUM:60,ETA_PLAN_CUM:59,ETA_FORECAST_CUM:59},
      {WK_NO:"W2021_52",FAT_PLAN_CUM:64,FAT_FORECAST_CUM:64,IRN_PLAN_CUM:61,IRN_FORECAST_CUM:61,ETA_PLAN_CUM:59,ETA_FORECAST_CUM:59},
      {WK_NO:"W2021_53",FAT_PLAN_CUM:64,FAT_FORECAST_CUM:64,IRN_PLAN_CUM:61,IRN_FORECAST_CUM:61,ETA_PLAN_CUM:59,ETA_FORECAST_CUM:59},
      {WK_NO:"W2022_02",FAT_PLAN_CUM:64,FAT_FORECAST_CUM:64,IRN_PLAN_CUM:62,IRN_FORECAST_CUM:62,ETA_PLAN_CUM:59,ETA_FORECAST_CUM:59},
      {WK_NO:"W2022_03",FAT_PLAN_CUM:64,FAT_FORECAST_CUM:64,IRN_PLAN_CUM:62,IRN_FORECAST_CUM:62,ETA_PLAN_CUM:59,ETA_FORECAST_CUM:59},
      {WK_NO:"W2022_04",FAT_PLAN_CUM:64,FAT_FORECAST_CUM:64,IRN_PLAN_CUM:63,IRN_FORECAST_CUM:63,ETA_PLAN_CUM:59,ETA_FORECAST_CUM:59},
      {WK_NO:"W2022_05",FAT_PLAN_CUM:64,FAT_FORECAST_CUM:64,IRN_PLAN_CUM:63,IRN_FORECAST_CUM:63,ETA_PLAN_CUM:59,ETA_FORECAST_CUM:59},
      {WK_NO:"W2022_06",FAT_PLAN_CUM:64,FAT_FORECAST_CUM:64,IRN_PLAN_CUM:63,IRN_FORECAST_CUM:63,ETA_PLAN_CUM:59,ETA_FORECAST_CUM:59},
      {WK_NO:"W2022_07",FAT_PLAN_CUM:64,FAT_FORECAST_CUM:64,IRN_PLAN_CUM:63,IRN_FORECAST_CUM:63,ETA_PLAN_CUM:59,ETA_FORECAST_CUM:59},
      {WK_NO:"W2022_08",FAT_PLAN_CUM:64,FAT_FORECAST_CUM:64,IRN_PLAN_CUM:63,IRN_FORECAST_CUM:63,ETA_PLAN_CUM:61,ETA_FORECAST_CUM:61},
      {WK_NO:"W2022_09",FAT_PLAN_CUM:64,FAT_FORECAST_CUM:64,IRN_PLAN_CUM:64,IRN_FORECAST_CUM:64,ETA_PLAN_CUM:62,ETA_FORECAST_CUM:62},
      {WK_NO:"W2022_10",FAT_PLAN_CUM:64,FAT_FORECAST_CUM:64,IRN_PLAN_CUM:64,IRN_FORECAST_CUM:64,ETA_PLAN_CUM:62,ETA_FORECAST_CUM:62},
      {WK_NO:"W2022_11",FAT_PLAN_CUM:64,FAT_FORECAST_CUM:64,IRN_PLAN_CUM:64,IRN_FORECAST_CUM:64,ETA_PLAN_CUM:62,ETA_FORECAST_CUM:62},
      {WK_NO:"W2022_12",FAT_PLAN_CUM:64,FAT_FORECAST_CUM:64,IRN_PLAN_CUM:64,IRN_FORECAST_CUM:64,ETA_PLAN_CUM:63,ETA_FORECAST_CUM:63},
      {WK_NO:"W2022_13",FAT_PLAN_CUM:64,FAT_FORECAST_CUM:64,IRN_PLAN_CUM:64,IRN_FORECAST_CUM:64,ETA_PLAN_CUM:64,ETA_FORECAST_CUM:64},
      {WK_NO:"W2022_14",FAT_PLAN_CUM:64,FAT_FORECAST_CUM:64,IRN_PLAN_CUM:64,IRN_FORECAST_CUM:64,ETA_PLAN_CUM:64,ETA_FORECAST_CUM:64}
      ],
}