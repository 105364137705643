import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 400,
    CanvasHeight: 180,
    CanvasBorderWeight: 0.3,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.2,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 350,
    CanvasChartHeight: 270,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: 'Transparent',
    CanvasChartFillColor: 'Transparent',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  Text: {
    Text: [],
    Filter: [],
    Sql: [
      {
        query: "SELECT 'Datasource: Shop Drawing Index 04-Oct-20' TITLE", 
        text: 'Datasource: Shop Drawing Index 04-Oct-20',
        x: 10,
        y: 10,
        font: 'roboto',
        style: 'regular',
        size: 12,
        color: '#757575',
        align: 'start'
      },
    ]
  },
  DataItems: [
    {TOTAL:100,PLAN:70,Actual:60,REMAIN:20}
  ]
}