import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 600,
      CanvasHeight: 270,
      CanvasBorderWeight: 0.5,
      CanvasBorderColor: '#ccc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Weight : ', x:20, y: 0, font: 'roboto', class: 'regular', size: 11, color: '#BCBCBC', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: 20, y: 0, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'end' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: 20,
          y: 0,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'end'
        },
      ]
    },
    DataItems: [
      // {NO:"1",SUBSYSTEM:"21-9810-9811-01",MC_PLAN:"2022-04-18",A_OV_PROG:28.64,TOTAL:3107,COMPLETED:110,REMAIN:3107,PROG:100},
      // {NO:"2",SUBSYSTEM:"21-9810-9811-01",MC_PLAN:"2022-04-18",A_OV_PROG:8.64,TOTAL:1642,COMPLETED:0,REMAIN:1642,PROG:70},
      // {NO:"3",SUBSYSTEM:"21-9810-9811-01",MC_PLAN:"2022-04-18",A_OV_PROG:18.64,TOTAL:19500,COMPLETED:0,REMAIN:19500,PROG:30},
      // {NO:"4",SUBSYSTEM:"21-9810-9811-01",MC_PLAN:"2022-04-18",A_OV_PROG:28.64,TOTAL:152,COMPLETED:0,REMAIN:152,PROG:0}  
    
      {NO:1,SUBSYSTEM:"21-9810-9811-01",MC_PLAN:"2022-04-18",A_OV_PROG:28.64,PA_OV_TOT:32,PA_OV_REM:32,PB1_OV_TOT:12,PB1_OV_REM:12},
      {NO:2,SUBSYSTEM:"21-9921-9922-02",MC_PLAN:"2021-12-16",A_OV_PROG:9.88,PA_OV_TOT:30,PA_OV_REM:30},
      {NO:3,SUBSYSTEM:"21-3040-3040-01",MC_PLAN:"2022-04-01",A_OV_PROG:8.04,PA_OV_TOT:33,PA_OV_REM:29},
      {NO:4,SUBSYSTEM:"21-9810-9812-01",MC_PLAN:"2022-04-18",A_OV_PROG:28.04,PA_OV_TOT:26,PA_OV_REM:26,PB1_OV_TOT:5,PB1_OV_REM:5},
      {NO:5,SUBSYSTEM:"21-6600-6670-01",MC_PLAN:"2021-11-12",A_OV_PROG:27.04,PA_OV_TOT:29,PA_OV_REM:26,PB1_OV_TOT:2,PB1_OV_REM:2},
      {NO:6,SUBSYSTEM:"21-8000-8013-01",MC_PLAN:"2022-01-24",A_OV_PROG:9.37,PA_OV_TOT:21,PA_OV_REM:21,PB1_OV_TOT:1,PB1_OV_REM:1},
      {NO:7,SUBSYSTEM:"21-9970-9971-06",MC_PLAN:"2021-12-17",A_OV_PROG:27.02,PA_OV_TOT:17,PA_OV_REM:17},
      {NO:8,SUBSYSTEM:"21-8090-8090-01",MC_PLAN:"2021-12-17",A_OV_PROG:15.78,PA_OV_TOT:16,PA_OV_REM:16,PB1_OV_TOT:2,PB1_OV_REM:2},
      {NO:9,SUBSYSTEM:"21-9370-9372-09",MC_PLAN:"2021-12-27",A_OV_PROG:1.12,PA_OV_TOT:16,PA_OV_REM:16},
      {NO:10,SUBSYSTEM:"21-9330-9333-01",MC_PLAN:"2021-12-08",A_OV_PROG:4.2,PA_OV_TOT:15,PA_OV_REM:15,PB1_OV_TOT:3,PB1_OV_REM:3}],
}