import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 2300,
    CanvasHeight: 980,
    CanvasBorderWeight: 0.05,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.1,

    CanvasChartX: 50,
    CanvasChartY: 50,
    CanvasChartWidth: 480,
    CanvasChartHeight: 250,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  DataItems:  [
    {SUBSYSTEM:"21-1010-1011-01",SUBSYSTEM_DESCR:"Flowline 1 and Receiver",MC_PLAN:"2022-04-28",B_OV_TOT:43,B_OV_COM:0,B_OV_REM:43,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,ACT_TAG_VALID:43,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-1010-1012-01",SUBSYSTEM_DESCR:"Flowline 2 and Receiver",MC_PLAN:"2022-04-28",B_OV_TOT:41,B_OV_COM:0,B_OV_REM:41,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:41,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-1911-1911-01",SUBSYSTEM_DESCR:"Hydraulic HPUs, RIOs and TUTUs",MC_PLAN:"2022-04-28",B_OV_TOT:3,B_OV_COM:0,B_OV_REM:3,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-1931-1931-01",SUBSYSTEM_DESCR:"Subsea Control System ,MCS, SSIV, SPCU, EPU's, HVE JB's, LVE JB's and Subsea ICSS",MC_PLAN:"2022-03-17",B_OV_TOT:4,B_OV_COM:0,B_OV_REM:4,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:11,PB1_OV_COM:0,PB1_OV_REM:11,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-2200-2131-01",SUBSYSTEM_DESCR:"Slug Catcher 1 and Liquid Heater 1",MC_PLAN:"2022-05-05",B_OV_TOT:36,B_OV_COM:0,B_OV_REM:36,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P3",PB1_OV_TOT:8,PB1_OV_COM:0,PB1_OV_REM:8,ACT_TAG_VALID:36,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2200-2132-01",SUBSYSTEM_DESCR:"Slug Catcher 2 and Liquid heater 2",MC_PLAN:"2022-05-05",B_OV_TOT:34,B_OV_COM:0,B_OV_REM:34,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P3",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:34,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2200-2221-01",SUBSYSTEM_DESCR:"MP Separator",MC_PLAN:"2022-07-14",B_OV_TOT:8,B_OV_COM:0,B_OV_REM:8,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2200-2240-01",SUBSYSTEM_DESCR:"LLP separator, Condensate Coolers and Condensate Coalescer Pumps",MC_PLAN:"2022-05-12",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:1,PA_OV_REM:2,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-2200-2470-01",SUBSYSTEM_DESCR:"Warm Condensate Heater, Offspec Condensate Pump and Distribution",MC_PLAN:"2022-07-14",B_OV_TOT:6,B_OV_COM:0,B_OV_REM:6,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2400-2440-01",SUBSYSTEM_DESCR:"Condensate Metering",MC_PLAN:"2022-06-09",B_OV_TOT:11,B_OV_COM:0,B_OV_REM:11,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:6,PA_OV_COM:1,PA_OV_REM:5,PB1_OV_TOT:21,PB1_OV_COM:1,PB1_OV_REM:20,ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:27,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-2400-2460-01",SUBSYSTEM_DESCR:"Condensate Offloading",MC_PLAN:"2022-06-09",B_OV_TOT:4,B_OV_COM:0,B_OV_REM:4,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:6,PB1_OV_COM:0,PB1_OV_REM:6,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-2500-2530-01",SUBSYSTEM_DESCR:"MEG Regeneration",MC_PLAN:"2022-04-28",B_OV_TOT:12,B_OV_COM:0,B_OV_REM:12,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:9,PA_OV_COM:0,PA_OV_REM:9,PB1_OV_TOT:13,PB1_OV_COM:0,PB1_OV_REM:13,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:22,PUNCH_TAG_VALID:12},
    {SUBSYSTEM:"21-2500-2553-02",SUBSYSTEM_DESCR:"Topsides Lean MEG Pumps and Distribution",MC_PLAN:"2022-05-19",B_OV_TOT:4,B_OV_COM:0,B_OV_REM:4,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2500-2553-03",SUBSYSTEM_DESCR:"Subsea Lean MEG Pumps and Distribution",MC_PLAN:"2022-06-23",B_OV_TOT:12,B_OV_COM:0,B_OV_REM:12,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:21,PA_OV_COM:20,PA_OV_REM:1,PB1_OV_TOT:3,PB1_OV_COM:1,PB1_OV_REM:2,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:24,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-2600-2640-02",SUBSYSTEM_DESCR:"Methanol HP Pumps",MC_PLAN:"2022-05-19",B_OV_TOT:8,B_OV_COM:0,B_OV_REM:8,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2600-2640-03",SUBSYSTEM_DESCR:"Methanol MP Pumps",MC_PLAN:"2022-05-19",B_OV_TOT:3,B_OV_COM:0,B_OV_REM:3,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2600-2641-01",SUBSYSTEM_DESCR:"Methanol Subsea Pumps",MC_PLAN:"2022-05-12",B_OV_TOT:5,B_OV_COM:0,B_OV_REM:5,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-3040-3040-01",SUBSYSTEM_DESCR:"HPandMP Gas Compressors, UCP, VSD and Electrical ControlandSwitchboards",MC_PLAN:"2022-04-07",B_OV_TOT:13,B_OV_COM:0,B_OV_REM:13,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:34,PA_OV_COM:13,PA_OV_REM:21,PB1_OV_TOT:5,PB1_OV_COM:0,PB1_OV_REM:5,ACT_TAG_VALID:13,PUNCH_NUMBER_VALID:39,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-3040-3040-02",SUBSYSTEM_DESCR:"HPandMP Gas Compressors Train Scrubbers, Aftercoolers and Process Lines",MC_PLAN:"2022-07-07",B_OV_TOT:4,B_OV_COM:0,B_OV_REM:4,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-3040-3041-01",SUBSYSTEM_DESCR:"LPandLLP Gas Compressors , UCP, VSD and Electrical ControlandSwitchboards",MC_PLAN:"2022-04-07",B_OV_TOT:6,B_OV_COM:0,B_OV_REM:6,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:7,PA_OV_COM:0,PA_OV_REM:7,PB1_OV_TOT:13,PB1_OV_COM:1,PB1_OV_REM:12,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-3330-3330-01",SUBSYSTEM_DESCR:"Process Gas, GasandGas Exchanger, TEX Scrubber and JT Valve",MC_PLAN:"2022-05-26",B_OV_TOT:22,B_OV_COM:0,B_OV_REM:22,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-3330-3330-02",SUBSYSTEM_DESCR:"Gas Coalescer and LT Separator",MC_PLAN:"2022-05-12",B_OV_TOT:25,B_OV_COM:0,B_OV_REM:25,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-3330-3330-03",SUBSYSTEM_DESCR:"Turbo Expander, Recompressor",MC_PLAN:"2022-05-12",B_OV_TOT:30,B_OV_COM:0,B_OV_REM:30,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,PB1_OV_TOT:4,PB1_OV_COM:0,PB1_OV_REM:4,ACT_TAG_VALID:30,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-3330-3410-02",SUBSYSTEM_DESCR:"Export Gas Metering and Export Risers",MC_PLAN:"2022-05-26",B_OV_TOT:1,B_OV_COM:0,B_OV_REM:1,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-3330-3410-03",SUBSYSTEM_DESCR:"Repressurisation Heater and Header",MC_PLAN:"2022-07-14",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-4000-4130-01",SUBSYSTEM_DESCR:"Deoiling Package ,Hydrocyclone + CFU",MC_PLAN:"2022-06-02",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:3,PA_OV_COM:1,PA_OV_REM:2,PB1_OV_TOT:4,PB1_OV_COM:0,PB1_OV_REM:4,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-4000-4130-02",SUBSYSTEM_DESCR:"Flocculent Injection Package",MC_PLAN:"2022-05-26",B_OV_TOT:5,B_OV_COM:0,B_OV_REM:5,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:5,PB1_OV_COM:2,PB1_OV_REM:3,ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-4000-4130-03",SUBSYSTEM_DESCR:"Demulsifier Injection Package",MC_PLAN:"2022-05-26",B_OV_TOT:4,B_OV_COM:0,B_OV_REM:4,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-5000-5010-01",SUBSYSTEM_DESCR:"HP Flare KO Drum and Pumps",MC_PLAN:"2022-06-30",B_OV_TOT:4,B_OV_COM:0,B_OV_REM:4,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5000-5030-01",SUBSYSTEM_DESCR:"Cold and Dry HP Flare Header",MC_PLAN:"2022-08-04",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5000-5030-03",SUBSYSTEM_DESCR:"HP liquid Flare Header",MC_PLAN:"2022-08-04",B_OV_TOT:6,B_OV_COM:0,B_OV_REM:6,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5200-4340-01",SUBSYSTEM_DESCR:"Closed Drain Drum and Pumps",MC_PLAN:"2022-04-21",B_OV_TOT:5,B_OV_COM:0,B_OV_REM:5,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-5200-5210-01",SUBSYSTEM_DESCR:"LP Flare KO Drum and Pumps",MC_PLAN:"2022-06-09",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-5200-5230-01",SUBSYSTEM_DESCR:"LP Flare Header",MC_PLAN:"2022-08-04",B_OV_TOT:4,B_OV_COM:0,B_OV_REM:4,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5700-5710-01",SUBSYSTEM_DESCR:"Flare Gas Vapour Recovery Skid",MC_PLAN:"2022-06-09",B_OV_TOT:1,B_OV_COM:0,B_OV_REM:1,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:5,PA_OV_COM:2,PA_OV_REM:3,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-5700-5730-01",SUBSYSTEM_DESCR:"HULL Tank Vapour Recovery Skid",MC_PLAN:"2022-06-09",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6030-6031-01",SUBSYSTEM_DESCR:"Electrical Data Monitoring and Constrol System ,EDMCS and Power Management System ,PMS",MC_PLAN:"2021-12-30",B_OV_TOT:29,B_OV_COM:0,B_OV_REM:29,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:12,PA_OV_COM:10,PA_OV_REM:2,PB1_OV_TOT:6,PB1_OV_COM:0,PB1_OV_REM:6,ACT_TAG_VALID:29,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:10},
    {SUBSYSTEM:"21-6030-6036-01",SUBSYSTEM_DESCR:"PDBs Inside HER",BATCH:"Priority Batch",MC_PLAN:"2021-09-30",B_OV_TOT:114,B_OV_COM:0,B_OV_REM:114,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,RFI_ISSUED:"2021-09-27",WD_ACTUAL:"2021-09-29",PL_RECEIVED:"2021-09-29",MC_SUBMITTED:"2021-11-24",MC_APPROVED:"2021-11-25",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Belong to MC completed system",PUNCH_STATUS:"B1",PRIORITY:"P1A",PA_OV_TOT:17,PA_OV_COM:17,PA_OV_REM:0,PB1_OV_TOT:9,PB1_OV_COM:3,PB1_OV_REM:6,ACT_TAG_VALID:114,PUNCH_NUMBER_VALID:26,PUNCH_TAG_VALID:7},
    {SUBSYSTEM:"21-6030-6036-02",SUBSYSTEM_DESCR:"PDBs Inside CER",BATCH:"Priority Batch",MC_PLAN:"2021-10-14",B_OV_TOT:215,B_OV_COM:0,B_OV_REM:215,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,RFI_ISSUED:"2021-10-19",WD_ACTUAL:"2021-10-21",PL_RECEIVED:"2021-10-21",MC_SUBMITTED:"2021-11-24",MC_APPROVED:"2021-11-25",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Belong to MC completed system",PUNCH_STATUS:"A",PRIORITY:"P1A",PA_OV_TOT:23,PA_OV_COM:19,PA_OV_REM:4,PB1_OV_TOT:11,PB1_OV_COM:4,PB1_OV_REM:7,ACT_TAG_VALID:215,PUNCH_NUMBER_VALID:34,PUNCH_TAG_VALID:10},
    {SUBSYSTEM:"21-6030-6036-04",SUBSYSTEM_DESCR:"PDBs Inside ME05",MC_PLAN:"2021-11-18",B_OV_TOT:375,B_OV_COM:0,B_OV_REM:375,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:375,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6030-6036-05",SUBSYSTEM_DESCR:"PDBs Inside L5 Telecom Room",MC_PLAN:"2021-12-02",B_OV_TOT:109,B_OV_COM:0,B_OV_REM:109,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:109,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6100-6110-01",SUBSYSTEM_DESCR:"Topsides Fire Water Ring Main, Hydrant and Jockey Pumps",MC_PLAN:"2022-02-17",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:4,PA_OV_COM:1,PA_OV_REM:3,PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-6100-6111-01",SUBSYSTEM_DESCR:"Fire Water Pump A ,21-SPF-611101",MC_PLAN:"2022-03-31",B_OV_TOT:18,B_OV_COM:0,B_OV_REM:18,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6100-6111-02",SUBSYSTEM_DESCR:"Fire Water Pump B ,21-SPF-611201",MC_PLAN:"2022-03-31",B_OV_TOT:18,B_OV_COM:0,B_OV_REM:18,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6100-6111-03",SUBSYSTEM_DESCR:"Fire Water Pump C ,21-SPF-611301",MC_PLAN:"2022-03-31",B_OV_TOT:19,B_OV_COM:0,B_OV_REM:19,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:0,PA_OV_REM:3,ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6100-6121-01",SUBSYSTEM_DESCR:"HULL Water Mist System",MC_PLAN:"2021-11-11",B_OV_TOT:9,B_OV_COM:0,B_OV_REM:9,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:10,PA_OV_COM:6,PA_OV_REM:4,PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-6100-6140-01",SUBSYSTEM_DESCR:"Foam Package",MC_PLAN:"2021-11-25",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:11,PA_OV_COM:10,PA_OV_REM:1,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6100-6140-03",SUBSYSTEM_DESCR:"Helideck Foam Package",MC_PLAN:"2022-03-10",B_OV_TOT:3,B_OV_COM:0,B_OV_REM:3,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6300-6320-01",SUBSYSTEM_DESCR:"Lifeboats",MC_PLAN:"2022-04-07",B_OV_TOT:6,B_OV_COM:0,B_OV_REM:6,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6300-6330-01",SUBSYSTEM_DESCR:"Escape Chutes",MC_PLAN:"2022-04-21",B_OV_TOT:3,B_OV_COM:0,B_OV_REM:3,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6500-6510-01",SUBSYSTEM_DESCR:"Navigation Lights Control Panel and Associated Lanterns andFoghorns Circuits",MC_PLAN:"2021-11-11",B_OV_TOT:5,B_OV_COM:0,B_OV_REM:5,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:11,PA_OV_COM:9,PA_OV_REM:2,ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-6500-6520-02",SUBSYSTEM_DESCR:"Helideck Lights Control Panel and Associated Beacons",MC_PLAN:"2021-11-11",B_OV_TOT:13,B_OV_COM:0,B_OV_REM:13,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:14,PA_OV_COM:13,PA_OV_REM:1,ACT_TAG_VALID:13,PUNCH_NUMBER_VALID:14,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6600-6610-01",SUBSYSTEM_DESCR:"Emergency Generator 21-G-661001",BATCH:"Priority Batch",MC_PLAN:"2021-11-04",B_OV_TOT:24,B_OV_COM:0,B_OV_REM:24,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:5,PA_OV_COM:1,PA_OV_REM:4,PB1_OV_TOT:19,PB1_OV_COM:1,PB1_OV_REM:18,ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:24,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-6600-6624-01",SUBSYSTEM_DESCR:"LQ Emergency Lighting Panel and Circuits ,Room: LH91 21-ELP-662460",MC_PLAN:"2021-09-30",B_OV_TOT:31,B_OV_COM:0,B_OV_REM:31,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:31,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6600-6624-02",SUBSYSTEM_DESCR:"LQ Emergency Lighting Panel and Circuits ,Room: L224 21-ELP-662461",MC_PLAN:"2021-09-23",B_OV_TOT:31,B_OV_COM:0,B_OV_REM:31,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:6,PB1_OV_COM:1,PB1_OV_REM:5,ACT_TAG_VALID:31,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6600-6624-03",SUBSYSTEM_DESCR:"LQ Emergency Lighting Panel and Circuits ,Room: L320 21-ELP-662462",MC_PLAN:"2021-09-23",B_OV_TOT:21,B_OV_COM:0,B_OV_REM:21,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:2,PA_OV_REM:1,PB1_OV_TOT:4,PB1_OV_COM:1,PB1_OV_REM:3,ACT_TAG_VALID:21,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6600-6624-04",SUBSYSTEM_DESCR:"LQ Emergency Lighting Panel and Circuits ,Room: L501 21-ELP-662463",MC_PLAN:"2021-11-11",B_OV_TOT:30,B_OV_COM:0,B_OV_REM:30,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P2",PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:30,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6600-6624-05",SUBSYSTEM_DESCR:"HULL Emergency Lighting Panel and Circuits ,Room: M104 21-ELP-662401",MC_PLAN:"2021-09-23",B_OV_TOT:31,B_OV_COM:0,B_OV_REM:31,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:7,PA_OV_COM:7,PA_OV_REM:0,PB1_OV_TOT:48,PB1_OV_COM:5,PB1_OV_REM:43,ACT_TAG_VALID:31,PUNCH_NUMBER_VALID:55,PUNCH_TAG_VALID:19},
    {SUBSYSTEM:"21-6600-6640-01",SUBSYSTEM_DESCR:"LQandHULL Emergency Lighting and small power 400V board 21-EDB-662401 ,in LQ",MC_PLAN:"2021-09-23",B_OV_TOT:85,B_OV_COM:0,B_OV_REM:85,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,RFI_ISSUED:"2021-11-09",WD_ACTUAL:"2021-11-11",PL_RECEIVED:"2021-11-11",MC_SUBMITTED:"077333C-TEN-BP-TN-015",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B2",PRIORITY:"P1",PA_OV_TOT:16,PA_OV_COM:16,PA_OV_REM:0,PB1_OV_TOT:7,PB1_OV_COM:7,PB1_OV_REM:0,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:33,PUNCH_NUMBER_VALID:24,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6600-6640-02",SUBSYSTEM_DESCR:"Topsides Emergency Lighting and small power 400V board 21-EDB-662402 ,in LER",MC_PLAN:"2021-10-28",B_OV_TOT:49,B_OV_COM:0,B_OV_REM:49,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,ACT_TAG_VALID:49,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-6600-6640-03",SUBSYSTEM_DESCR:"Sub Distribution Auxiliary Panel 01 ,ROOM: LH16 21-EDB-667671",MC_PLAN:"2021-09-23",B_OV_TOT:11,B_OV_COM:0,B_OV_REM:11,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:2,PA_OV_REM:1,PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6600-6640-04",SUBSYSTEM_DESCR:"Sub Distribution Auxiliary Panel 03 ,ROOM: M10621-EDB-662433",MC_PLAN:"2021-09-30",B_OV_TOT:5,B_OV_COM:0,B_OV_REM:5,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,RFI_ISSUED:"2021-11-24",WD_ACTUAL:"2021-11-25",MC_SUBMITTED:"077333C-TEN-BP-TN-015",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:5,PA_OV_COM:5,PA_OV_REM:0,PB1_OV_TOT:7,PB1_OV_COM:5,PB1_OV_REM:2,ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6600-6640-05",SUBSYSTEM_DESCR:"Panel Control For External Lighting - 21-ECP-667680",MC_PLAN:"2021-09-30",B_OV_TOT:3,B_OV_COM:0,B_OV_REM:3,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6600-6660-01",SUBSYSTEM_DESCR:"Emergency 690 V Switchboard 21-ESB-666001",BATCH:"Priority Batch",MC_PLAN:"2021-09-30",B_OV_TOT:313,B_OV_COM:175,B_OV_REM:138,B_OV_PROG:55.91,B_OV_STATUS:"NA",B_PG:55.91,RFI_ISSUED:"2021-10-07",WD_ACTUAL:"2021-10-08",PL_RECEIVED:"2021-10-08",MC_SUBMITTED:"2021-10-27",MC_APPROVED:"2021-10-30",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Belong to MC completed system",PUNCH_STATUS:"B1",PRIORITY:"P1A",PA_OV_TOT:10,PA_OV_COM:10,PA_OV_REM:0,PB1_OV_TOT:8,PB1_OV_COM:7,PB1_OV_REM:1,PB2_OV_TOT:37,PB2_OV_COM:5,PB2_OV_REM:32,ACT_TAG_VALID:109,PUNCH_NUMBER_VALID:55,PUNCH_TAG_VALID:16},
    {SUBSYSTEM:"21-6600-6670-01",SUBSYSTEM_DESCR:"Key service Generator 21-G-667001",MC_PLAN:"2021-11-18",B_OV_TOT:20,B_OV_COM:0,B_OV_REM:20,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P1A",PA_OV_TOT:36,PA_OV_COM:18,PA_OV_REM:18,PB1_OV_TOT:23,PB1_OV_COM:1,PB1_OV_REM:22,ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:59,PUNCH_TAG_VALID:17},
    {SUBSYSTEM:"21-6600-6670-02",SUBSYSTEM_DESCR:"Key service Generator 21-G-667002",MC_PLAN:"2021-11-18",B_OV_TOT:20,B_OV_COM:0,B_OV_REM:20,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P1A",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PB1_OV_TOT:18,PB1_OV_COM:0,PB1_OV_REM:18,ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:10},
    {SUBSYSTEM:"21-6600-6673-01",SUBSYSTEM_DESCR:"Essential 6.6 kV Switchboard 21-ESB-667301",MC_PLAN:"2021-10-14",B_OV_TOT:112,B_OV_COM:0,B_OV_REM:112,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,RFI_ISSUED:"2021-11-06",WD_ACTUAL:"2021-11-09",PL_RECEIVED:"2021-11-09",MC_SUBMITTED:"2021-11-18",MC_APPROVED:"2021-11-20",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Belong to MC completed system",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:11,PA_OV_COM:11,PA_OV_REM:0,PB1_OV_TOT:41,PB1_OV_COM:8,PB1_OV_REM:33,ACT_TAG_VALID:54,PUNCH_NUMBER_VALID:52,PUNCH_TAG_VALID:16},
    {SUBSYSTEM:"21-6600-6674-01",SUBSYSTEM_DESCR:"HULL Normal and Essential 690 V Swtchboard 21-ESB-667401",BATCH:"Priority Batch",MC_PLAN:"2021-09-23",B_OV_TOT:389,B_OV_COM:219,B_OV_REM:170,B_OV_PROG:56.29,B_OV_STATUS:"NA",B_PG:56.29,RFI_ISSUED:"2021-08-18",WD_ACTUAL:"2021-08-19",PL_RECEIVED:"2021-08-19",MC_SUBMITTED:"2021-09-29",MC_APPROVED:"2021-09-29",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Belong to MC completed system",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:9,PA_OV_COM:9,PA_OV_REM:0,PB1_OV_TOT:31,PB1_OV_COM:9,PB1_OV_REM:22,PB2_OV_TOT:12,PB2_OV_COM:11,PB2_OV_REM:1,ACT_TAG_VALID:135,PUNCH_NUMBER_VALID:52,PUNCH_TAG_VALID:8},
    {SUBSYSTEM:"21-6600-6674-02",SUBSYSTEM_DESCR:"Topside Essential 690 V Switchboard 21-ESB-667402",MC_PLAN:"2021-10-28",B_OV_TOT:413,B_OV_COM:0,B_OV_REM:413,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:23,PB1_OV_COM:3,PB1_OV_REM:20,ACT_TAG_VALID:143,PUNCH_NUMBER_VALID:23,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6600-6675-01",SUBSYSTEM_DESCR:"HULL Normal and Essential 400 V Switchboard 21-ESB-667601",BATCH:"Priority Batch",MC_PLAN:"2021-09-23",B_OV_TOT:107,B_OV_COM:53,B_OV_REM:54,B_OV_PROG:49.53,B_OV_STATUS:"less than 50%",B_PG:49.53,RFI_ISSUED:"2021-08-18",WD_ACTUAL:"2021-08-19",PL_RECEIVED:"2021-08-19",MC_SUBMITTED:"2021-09-30",MC_APPROVED:"2021-10-20",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Belong to MC completed system",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:8,PA_OV_COM:8,PA_OV_REM:0,PB1_OV_TOT:23,PB1_OV_COM:8,PB1_OV_REM:15,PB2_OV_TOT:7,PB2_OV_COM:7,PB2_OV_REM:0,ACT_TAG_VALID:41,PUNCH_NUMBER_VALID:38,PUNCH_TAG_VALID:7},
    {SUBSYSTEM:"21-6600-6675-02",SUBSYSTEM_DESCR:"Topsides Normal and Essential lighting and Small Power 400V 21-EDB-667602",MC_PLAN:"2021-10-28",B_OV_TOT:52,B_OV_COM:0,B_OV_REM:52,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:2,PB1_OV_COM:2,PB1_OV_REM:0,ACT_TAG_VALID:28,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6600-6675-03",SUBSYSTEM_DESCR:"Topsides Normal and Essential lighting and Small Power 400V 21-EDB-667603",MC_PLAN:"2021-10-28",B_OV_TOT:108,B_OV_COM:0,B_OV_REM:108,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:16,PB1_OV_COM:0,PB1_OV_REM:16,ACT_TAG_VALID:108,PUNCH_NUMBER_VALID:16,PUNCH_TAG_VALID:10},
    {SUBSYSTEM:"21-6600-6675-04",SUBSYSTEM_DESCR:"Topsides MOV and Convenience Outlets 400V 21-EDB-667604",MC_PLAN:"2021-10-28",B_OV_TOT:28,B_OV_COM:0,B_OV_REM:28,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:28,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6600-6675-05",SUBSYSTEM_DESCR:"Topsides Misc Feeders and Auxiliaries 400V 21-EDB-667606",MC_PLAN:"2021-10-28",B_OV_TOT:26,B_OV_COM:0,B_OV_REM:26,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:26,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6600-6675-06",SUBSYSTEM_DESCR:"Sub Distribution Auxiliary Panel 02 ,ROOM: M303 21-EDB-667635",MC_PLAN:"2021-09-30",B_OV_TOT:11,B_OV_COM:0,B_OV_REM:11,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,RFI_ISSUED:"2021-08-25",WD_ACTUAL:"2021-08-27",PL_RECEIVED:"2021-08-27",MC_SUBMITTED:"2021-10-12",MC_APPROVED:"2021-10-28",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Belong to MC completed system",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:13,PA_OV_COM:13,PA_OV_REM:0,ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6600-6676-01",SUBSYSTEM_DESCR:"Normal Lighting Panel 01 ,ROOM: M103 21-ELP-667630",MC_PLAN:"2021-09-23",B_OV_TOT:47,B_OV_COM:0,B_OV_REM:47,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:39,PB1_OV_COM:14,PB1_OV_REM:25,ACT_TAG_VALID:47,PUNCH_NUMBER_VALID:39,PUNCH_TAG_VALID:16,COMMENTS:"Y"},
    {SUBSYSTEM:"21-6600-6676-02",SUBSYSTEM_DESCR:"Normal Lighting Panel 02 ,ROOM: M303 21-ELP-667631",MC_PLAN:"2021-09-30",B_OV_TOT:42,B_OV_COM:0,B_OV_REM:42,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,RFI_ISSUED:"2021-10-11",WD_ACTUAL:"2021-10-13",PL_RECEIVED:"2021-10-13",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:31,PA_OV_COM:29,PA_OV_REM:2,PB1_OV_TOT:14,PB1_OV_COM:10,PB1_OV_REM:4,ACT_TAG_VALID:42,PUNCH_NUMBER_VALID:45,PUNCH_TAG_VALID:21},
    {SUBSYSTEM:"21-6600-6676-03",SUBSYSTEM_DESCR:"Machinary Workshop Power Panel ,ROOM: M104 21-ELP-667636",MC_PLAN:"2021-09-30",B_OV_TOT:15,B_OV_COM:0,B_OV_REM:15,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P2",PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6600-6676-04",SUBSYSTEM_DESCR:"LQ Small Power for LQ Aux Eqt ,ROOM: LH05 21-ELP-667672",MC_PLAN:"2021-09-30",B_OV_TOT:58,B_OV_COM:0,B_OV_REM:58,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:3,PB1_OV_COM:1,PB1_OV_REM:2,ACT_TAG_VALID:58,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6600-6676-05",SUBSYSTEM_DESCR:"LQ Galley Panel 01 ,ROOM: L210 21-ELP-667673",MC_PLAN:"2021-10-21",B_OV_TOT:6,B_OV_COM:0,B_OV_REM:6,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,RFI_ISSUED:"2021-11-03",WD_ACTUAL:"2021-11-04",PL_RECEIVED:"2021-11-04",MC_SUBMITTED:"2021-11-12",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:14,PA_OV_COM:14,PA_OV_REM:0,PB1_OV_TOT:10,PB1_OV_COM:1,PB1_OV_REM:9,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:24,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6600-6676-06",SUBSYSTEM_DESCR:"Normal Lighting Panel 05 ,ROOM: LH05 21-ELP-667660",MC_PLAN:"2021-10-14",B_OV_TOT:47,B_OV_COM:0,B_OV_REM:47,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P2",PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:47,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6600-6676-07",SUBSYSTEM_DESCR:"Normal Lighting Panel 06 ,ROOM: LA17 21-ELP-667661",MC_PLAN:"2021-10-21",B_OV_TOT:67,B_OV_COM:0,B_OV_REM:67,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PB1_OV_TOT:2,PB1_OV_COM:1,PB1_OV_REM:1,ACT_TAG_VALID:67,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6600-6676-08",SUBSYSTEM_DESCR:"Normal Lighting Panel 07 ,ROOM: L112 21-ELP-667662",MC_PLAN:"2021-10-14",B_OV_TOT:51,B_OV_COM:0,B_OV_REM:51,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:4,PB1_OV_COM:1,PB1_OV_REM:3,ACT_TAG_VALID:51,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6600-6676-09",SUBSYSTEM_DESCR:"Normal Lighting Panel 08 ,ROOM: L225 21-ELP-667663",MC_PLAN:"2021-10-14",B_OV_TOT:57,B_OV_COM:0,B_OV_REM:57,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P2",PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:57,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6600-6676-10",SUBSYSTEM_DESCR:"Normal Lighting Panel 09 ,ROOM: L345 21-ELP-667664",MC_PLAN:"2021-10-14",B_OV_TOT:110,B_OV_COM:0,B_OV_REM:110,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PB1_OV_TOT:9,PB1_OV_COM:1,PB1_OV_REM:8,ACT_TAG_VALID:110,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-6600-6676-11",SUBSYSTEM_DESCR:"Normal Lighting Panel 10 ,ROOM: L445 21-ELP-667665",MC_PLAN:"2021-10-14",B_OV_TOT:111,B_OV_COM:0,B_OV_REM:111,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P2",PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:111,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6600-6676-12",SUBSYSTEM_DESCR:"Normal Lighting Panel 11 ,ROOM: L591 21-ELP-667666",MC_PLAN:"2021-10-14",B_OV_TOT:47,B_OV_COM:0,B_OV_REM:47,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:47,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6600-6676-13",SUBSYSTEM_DESCR:"LQ Laundry Panel 01 ,ROOM: L115 21-ELP-667674",MC_PLAN:"2021-11-04",B_OV_TOT:5,B_OV_COM:0,B_OV_REM:5,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,RFI_ISSUED:"2021-08-27",WD_ACTUAL:"2021-08-31",PL_RECEIVED:"2021-08-31",MC_SUBMITTED:"2021-10-12",MC_APPROVED:"2021-10-29",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Belong to MC completed system",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:7,PA_OV_COM:7,PA_OV_REM:0,ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6600-6676-14",SUBSYSTEM_DESCR:"LQ Laboratory Distribution Board ,ROOM: LA04 21-ELP-667675",MC_PLAN:"2021-10-21",B_OV_TOT:5,B_OV_COM:0,B_OV_REM:5,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,RFI_ISSUED:"2021-11-06",WD_ACTUAL:"2021-11-08",PL_RECEIVED:"2021-11-08",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:13,PA_OV_COM:13,PA_OV_REM:0,PB1_OV_TOT:4,PB1_OV_COM:1,PB1_OV_REM:3,ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:17,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6600-6676-15",SUBSYSTEM_DESCR:"LQ Laundry Panel 02 ,ROOM: L115 21-ELP-667677",MC_PLAN:"2021-11-04",B_OV_TOT:3,B_OV_COM:0,B_OV_REM:3,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,RFI_ISSUED:"2021-10-07",WD_ACTUAL:"2021-10-08",PL_RECEIVED:"2021-10-08",MC_SUBMITTED:"2021-10-20",MC_APPROVED:"2021-10-28",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Belong to MC completed system",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6600-6676-16",SUBSYSTEM_DESCR:"LQ Galley Panel 02 ,ROOM: L220 21-ELP-667678",MC_PLAN:"2021-10-28",B_OV_TOT:9,B_OV_COM:0,B_OV_REM:9,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,RFI_ISSUED:"2021-11-03",WD_ACTUAL:"2021-11-04",PL_RECEIVED:"2021-11-04",MC_SUBMITTED:"2021-11-12",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:12,PA_OV_COM:12,PA_OV_REM:0,PB1_OV_TOT:7,PB1_OV_COM:1,PB1_OV_REM:6,ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:19,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6625-8725-01",SUBSYSTEM_DESCR:"Normal and Essential Heat Trace 400V Topsides 21-EDB-667605",MC_PLAN:"2021-11-11",B_OV_TOT:3,B_OV_COM:0,B_OV_REM:3,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6700-6701-01",SUBSYSTEM_DESCR:"Main LQ UPS System ,Incl Batteries, Breakers and Subdistribution Board",BATCH:"Priority Batch",MC_PLAN:"2021-09-30",B_OV_TOT:10,B_OV_COM:1,B_OV_REM:9,B_OV_PROG:10,B_OV_STATUS:"less than 50%",B_PG:10,RFI_ISSUED:"2021-10-07",WD_ACTUAL:"2021-10-08",PL_RECEIVED:"2021-10-08",MC_SUBMITTED:"2021-10-27",MC_APPROVED:"2021-10-30",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Belong to MC completed system",PUNCH_STATUS:"B1",PRIORITY:"P1A",PA_OV_TOT:31,PA_OV_COM:31,PA_OV_REM:0,PB1_OV_TOT:53,PB1_OV_COM:30,PB1_OV_REM:23,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:85,PUNCH_TAG_VALID:17},
    {SUBSYSTEM:"21-6700-6701-02",SUBSYSTEM_DESCR:"LQ Telecom UPS System B for HULL and LQ ,Incl Batteries, Breakers and Subdistribution Board",MC_PLAN:"2021-09-30",B_OV_TOT:9,B_OV_COM:0,B_OV_REM:9,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,RFI_ISSUED:"2021-11-08",WD_ACTUAL:"2021-11-09",PL_RECEIVED:"2021-11-09",MC_SUBMITTED:"2021-11-25",MC_APPROVED:"2021-11-26",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Belong to MC completed system",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:5,PA_OV_COM:5,PA_OV_REM:0,PB1_OV_TOT:23,PB1_OV_COM:14,PB1_OV_REM:9,PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:30,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-6700-6701-03",SUBSYSTEM_DESCR:"LQ Telecom UPS System A for Topsides ,Incl Batteries, Breakers and Subdistribution Board",MC_PLAN:"2021-12-09",B_OV_TOT:9,B_OV_COM:0,B_OV_REM:9,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PB1_OV_TOT:13,PB1_OV_COM:12,PB1_OV_REM:1,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:15,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6700-6702-01",SUBSYSTEM_DESCR:"Topside Main UPS System ,Incl Batteries, Breakers and Subdistribution Board",MC_PLAN:"2021-11-04",B_OV_TOT:21,B_OV_COM:0,B_OV_REM:21,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:4,PA_OV_COM:0,PA_OV_REM:4,PB1_OV_TOT:8,PB1_OV_COM:6,PB1_OV_REM:2,ACT_TAG_VALID:19,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-6700-6702-02",SUBSYSTEM_DESCR:"Subsea UPS System ,Incl Batteries, Breakers, Voltage Stabilisers and Subdistribution Board",MC_PLAN:"2022-01-13",B_OV_TOT:10,B_OV_COM:0,B_OV_REM:10,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:4,PA_OV_COM:0,PA_OV_REM:4,PB1_OV_TOT:6,PB1_OV_COM:4,PB1_OV_REM:2,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-8000-8010-01",SUBSYSTEM_DESCR:"Potable Water Maker and Rehardener",MC_PLAN:"2021-11-04",B_OV_TOT:8,B_OV_COM:0,B_OV_REM:8,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:24,PA_OV_COM:15,PA_OV_REM:9,PB1_OV_TOT:17,PB1_OV_COM:2,PB1_OV_REM:15,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:41,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-8000-8010-02",SUBSYSTEM_DESCR:"Chlorination Package",MC_PLAN:"2022-01-27",B_OV_TOT:3,B_OV_COM:0,B_OV_REM:3,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8000-8011-01",SUBSYSTEM_DESCR:"Potable Water Tanks and Pumps",MC_PLAN:"2021-11-04",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:2,PA_OV_REM:1,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8000-8012-01",SUBSYSTEM_DESCR:"UV Sterilizer",MC_PLAN:"2021-12-23",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-8000-8013-01",SUBSYSTEM_DESCR:"Hot Water Calorifiers",MC_PLAN:"2022-01-27",B_OV_TOT:6,B_OV_COM:0,B_OV_REM:6,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:22,PA_OV_COM:15,PA_OV_REM:7,PB1_OV_TOT:4,PB1_OV_COM:0,PB1_OV_REM:4,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:26,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-8000-8013-02",SUBSYSTEM_DESCR:"Hot Water Distribution and Recirculation Pumps",MC_PLAN:"2022-01-27",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8000-8014-01",SUBSYSTEM_DESCR:"Fresh Service Water Tank and Pumps",MC_PLAN:"2021-11-11",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8020-8021-01",SUBSYSTEM_DESCR:"IGG Scrubber Cooling Sea Water Pumps - 21-P802003AandB",MC_PLAN:"2022-01-06",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8020-8023-01",SUBSYSTEM_DESCR:"IGG Deck Seal Sea Water Pumps - 21-P802002AandB",MC_PLAN:"2022-01-06",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8020-8024-01",SUBSYSTEM_DESCR:"HULL Sea Water Cooling Pumps - 21-P-802004AandB",MC_PLAN:"2021-10-28",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8020-8025-01",SUBSYSTEM_DESCR:"HULL Service Sea Water Pumps - 21-P-802005AandB",MC_PLAN:"2021-12-02",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:4,PA_OV_COM:1,PA_OV_REM:3,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-8020-8027-01",SUBSYSTEM_DESCR:"HULL Service Sea Water Booster Pumps - 21-P-802008AandB",MC_PLAN:"2021-11-04",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P1",PA_OV_TOT:4,PA_OV_COM:4,PA_OV_REM:0,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8020-8029-01",SUBSYSTEM_DESCR:"KSG Cooling Sea Water Pumps 21-P-802009AandB and Distribution",MC_PLAN:"2021-11-04",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P1A",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:4,PB1_OV_COM:0,PB1_OV_REM:4,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-8040-8040-01",SUBSYSTEM_DESCR:"Heating Medium ,Hot Water Expansion Tank",MC_PLAN:"2022-02-24",B_OV_TOT:1,B_OV_COM:0,B_OV_REM:1,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8040-8040-02",SUBSYSTEM_DESCR:"Heating Medium ,Hot Water Pumps and Loop and Distribution to Exchangers",MC_PLAN:"2022-02-24",B_OV_TOT:4,B_OV_COM:0,B_OV_REM:4,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:5,PA_OV_COM:4,PA_OV_REM:1,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-8050-8051-01",SUBSYSTEM_DESCR:"Marine Cooling Medium Pumps - 21-P805002AandB",MC_PLAN:"2021-10-21",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8050-8052-01",SUBSYSTEM_DESCR:"Topisdes Cooling Medium Pumps - 21-P805001AandB",MC_PLAN:"2022-02-24",B_OV_TOT:4,B_OV_COM:0,B_OV_REM:4,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:9,PA_OV_COM:5,PA_OV_REM:4,PB1_OV_TOT:3,PB1_OV_COM:1,PB1_OV_REM:2,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-8050-8052-02",SUBSYSTEM_DESCR:"Start-up Cooling Medium Pumps - 21-P805003",MC_PLAN:"2022-02-24",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8050-8052-03",SUBSYSTEM_DESCR:"Topsides Cooling Medium Expansion Tank",MC_PLAN:"2022-02-24",B_OV_TOT:1,B_OV_COM:0,B_OV_REM:1,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8050-8052-04",SUBSYSTEM_DESCR:"Topsides Cooling Medium Distribution and Exchangers",MC_PLAN:"2022-02-24",B_OV_TOT:1,B_OV_COM:0,B_OV_REM:1,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8060-8061-02",SUBSYSTEM_DESCR:"Diesel Storage Tanks - 21-T-806001and2 and Purifier Pumps - 21-P-806002AandB",MC_PLAN:"2021-10-28",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:5,PA_OV_COM:5,PA_OV_REM:0,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-8060-8062-01",SUBSYSTEM_DESCR:"Diesel Service Tanks - 21-T-806003and4 and Supply Pumps - 21-P-806001AandB",MC_PLAN:"2021-11-04",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-8090-8090-01",SUBSYSTEM_DESCR:"Sewage Treatment Package",MC_PLAN:"2021-12-23",B_OV_TOT:14,B_OV_COM:0,B_OV_REM:14,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:16,PA_OV_COM:11,PA_OV_REM:5,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:19,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8090-8090-02",SUBSYSTEM_DESCR:"Sewage Collection and Discharge Headers",MC_PLAN:"2021-12-23",B_OV_TOT:3,B_OV_COM:0,B_OV_REM:3,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8100-8120-01",SUBSYSTEM_DESCR:"HULL Air Compressor 21-Z-812010",BATCH:"Priority Batch",MC_PLAN:"2021-10-21",B_OV_TOT:5,B_OV_COM:0,B_OV_REM:5,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:9,PA_OV_COM:8,PA_OV_REM:1,PB1_OV_TOT:7,PB1_OV_COM:0,PB1_OV_REM:7,ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:16,PUNCH_TAG_VALID:7},
    {SUBSYSTEM:"21-8100-8122-01",SUBSYSTEM_DESCR:"Topside Air Compressor 21-Z-811101",MC_PLAN:"2022-02-17",B_OV_TOT:3,B_OV_COM:0,B_OV_REM:3,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:6,PB1_OV_COM:0,PB1_OV_REM:6,PB3_OV_TOT:1,PB3_OV_COM:0,PB3_OV_REM:1,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8100-8122-02",SUBSYSTEM_DESCR:"Topside Air Compressor 21-Z-811201",MC_PLAN:"2022-02-17",B_OV_TOT:3,B_OV_COM:0,B_OV_REM:3,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:5,PA_OV_COM:1,PA_OV_REM:4,PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8100-8122-03",SUBSYSTEM_DESCR:"Topside Air Compressor 21-Z-811301",MC_PLAN:"2022-02-17",B_OV_TOT:3,B_OV_COM:0,B_OV_REM:3,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8100-8122-04",SUBSYSTEM_DESCR:"Topside Air Driers 21-Z-812001",MC_PLAN:"2022-02-17",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:6,PB1_OV_COM:0,PB1_OV_REM:6,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8130-8130-02",SUBSYSTEM_DESCR:"Fuel Gas Superheaters",MC_PLAN:"2022-04-28",B_OV_TOT:4,B_OV_COM:0,B_OV_REM:4,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8150-8150-01",SUBSYSTEM_DESCR:"Nitrogen Generator and Receiver",MC_PLAN:"2022-03-03",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:1,PA_OV_REM:2,PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8160-8160-01",SUBSYSTEM_DESCR:"Inert Gas Generator",MC_PLAN:"2022-06-09",B_OV_TOT:4,B_OV_COM:0,B_OV_REM:4,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:5,PA_OV_COM:1,PA_OV_REM:4,PB1_OV_TOT:6,PB1_OV_COM:0,PB1_OV_REM:6,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-8300-8310-01",SUBSYSTEM_DESCR:"Corrosion Inhibitor Tank and Pumps",MC_PLAN:"2022-04-14",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:6,PA_OV_COM:3,PA_OV_REM:3,PB1_OV_TOT:4,PB1_OV_COM:0,PB1_OV_REM:4,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8300-8320-01",SUBSYSTEM_DESCR:"Scale Inhibitor Tank and Pumps Topsides",MC_PLAN:"2022-04-14",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8320-04",SUBSYSTEM_DESCR:"Scale Inhibitor Tank and Pumps Subsea",MC_PLAN:"2022-04-14",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8300-8331-02",SUBSYSTEM_DESCR:"Wax Inhibitor Break Tank and Injection Pumps",MC_PLAN:"2022-05-05",B_OV_TOT:4,B_OV_COM:0,B_OV_REM:4,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8300-8340-01",SUBSYSTEM_DESCR:"Biocide Tank and Pumps",MC_PLAN:"2022-04-14",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:14,PA_OV_COM:7,PA_OV_REM:7,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:15,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8300-8350-01",SUBSYSTEM_DESCR:"Antifoam Tank and Pumps",MC_PLAN:"2022-04-14",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8360-01",SUBSYSTEM_DESCR:"Emulsion Breaker Tank and Pumps",MC_PLAN:"2022-04-14",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8370-01",SUBSYSTEM_DESCR:"MEG PH Stabilizer Tank and Pumps",MC_PLAN:"2022-04-14",B_OV_TOT:4,B_OV_COM:0,B_OV_REM:4,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8400-8410-01",SUBSYSTEM_DESCR:"O2 Scavanger Tank and Pumps",MC_PLAN:"2022-04-14",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8600-8611-01",SUBSYSTEM_DESCR:"GTG1 and WHRU1",MC_PLAN:"2022-02-17",B_OV_TOT:38,B_OV_COM:0,B_OV_REM:38,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:10,PB1_OV_COM:0,PB1_OV_REM:10,ACT_TAG_VALID:33,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-8600-8612-01",SUBSYSTEM_DESCR:"GTG2 and WHRU2",MC_PLAN:"2022-02-17",B_OV_TOT:38,B_OV_COM:0,B_OV_REM:38,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:33,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8600-8613-01",SUBSYSTEM_DESCR:"GTG3 and WHRU3",MC_PLAN:"2021-12-30",B_OV_TOT:38,B_OV_COM:0,B_OV_REM:38,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,ACT_TAG_VALID:33,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8600-8614-01",SUBSYSTEM_DESCR:"GTG4 and WHRU4",MC_PLAN:"2021-12-30",B_OV_TOT:38,B_OV_COM:0,B_OV_REM:38,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:33,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8700-8760-01",SUBSYSTEM_DESCR:"Topside Normal 690 V Swicthboard 21-ESB-876001",MC_PLAN:"2021-11-04",B_OV_TOT:254,B_OV_COM:0,B_OV_REM:254,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:5,PB1_OV_COM:2,PB1_OV_REM:3,ACT_TAG_VALID:88,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8700-8770-01",SUBSYSTEM_DESCR:"Topsides Main Normal HV Switchboard 6.6kV 21-ESB-877001",MC_PLAN:"2021-11-04",B_OV_TOT:68,B_OV_COM:0,B_OV_REM:68,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:8,PA_OV_COM:6,PA_OV_REM:2,PB1_OV_TOT:10,PB1_OV_COM:2,PB1_OV_REM:8,ACT_TAG_VALID:30,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8800-8800-01",SUBSYSTEM_DESCR:"Ses Water Lift Pump A",MC_PLAN:"2022-04-14",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8800-8800-02",SUBSYSTEM_DESCR:"Ses Water Lift Pump B",MC_PLAN:"2022-04-14",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8800-8800-03",SUBSYSTEM_DESCR:"Ses Water Lift Pump C",MC_PLAN:"2022-04-14",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8800-8800-04",SUBSYSTEM_DESCR:"Sea Water Filter and Distribution Header",MC_PLAN:"2022-04-14",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8800-8805-01",SUBSYSTEM_DESCR:"Electrochlorination Unit",MC_PLAN:"2022-04-14",B_OV_TOT:9,B_OV_COM:0,B_OV_REM:9,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P3",PA_OV_TOT:4,PA_OV_COM:4,PA_OV_REM:0,PB1_OV_TOT:7,PB1_OV_COM:4,PB1_OV_REM:3,ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-9220-9220-04",SUBSYSTEM_DESCR:"LQ Deck 2 - Area Completion",MC_PLAN:"2022-03-03",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9330-9333-01",SUBSYSTEM_DESCR:"Cargo and Ballast Pumps HPU and UCP",MC_PLAN:"2021-12-09",B_OV_TOT:21,B_OV_COM:0,B_OV_REM:21,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P1A",PA_OV_TOT:21,PA_OV_COM:5,PA_OV_REM:16,PB1_OV_TOT:28,PB1_OV_COM:0,PB1_OV_REM:28,ACT_TAG_VALID:21,PUNCH_NUMBER_VALID:49,PUNCH_TAG_VALID:19},
    {SUBSYSTEM:"21-9330-9334-01",SUBSYSTEM_DESCR:"HULL Valves HPU",MC_PLAN:"2021-11-25",B_OV_TOT:6,B_OV_COM:0,B_OV_REM:6,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P1A",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-9340-9341-01",SUBSYSTEM_DESCR:"Forward Mooring System and HPU",MC_PLAN:"2022-04-21",B_OV_TOT:4,B_OV_COM:0,B_OV_REM:4,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:4,PB1_OV_COM:1,PB1_OV_REM:3,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-9340-9341-02",SUBSYSTEM_DESCR:"Forward Mooring Fairleads, Sheaves and Accessories Mounted on HULL",MC_PLAN:"2022-04-21",B_OV_TOT:1,B_OV_COM:0,B_OV_REM:1,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9340-9343-01",SUBSYSTEM_DESCR:"Aft Mooring System and HPU",MC_PLAN:"2022-04-21",B_OV_TOT:4,B_OV_COM:0,B_OV_REM:4,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:4,PB1_OV_COM:1,PB1_OV_REM:3,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-9340-9343-02",SUBSYSTEM_DESCR:"Aft Mooring Fairleads, Sheaves and Accessories Mounted on HULL",MC_PLAN:"2022-04-21",B_OV_TOT:1,B_OV_COM:0,B_OV_REM:1,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:8,PA_OV_COM:0,PA_OV_REM:8,ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9350-9350-04",SUBSYSTEM_DESCR:"MS Ballast Pump 21-P-935002A",MC_PLAN:"2021-12-09",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9370-9372-09",SUBSYSTEM_DESCR:"Slops Polishing and ODMS ,Oil Discharge Monitoring Units",MC_PLAN:"2021-12-30",B_OV_TOT:1,B_OV_COM:0,B_OV_REM:1,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:17,PA_OV_COM:7,PA_OV_REM:10,ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:17,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9380-9380-01",SUBSYSTEM_DESCR:"Daily bilge Tank 21-T-938001 and Pumps 21-P-938001AandB",MC_PLAN:"2022-01-20",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9380-9381-01",SUBSYSTEM_DESCR:"Main bilge Pumps 21-P-938002AandB and Headers",MC_PLAN:"2022-01-20",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-9390-9390-01",SUBSYSTEM_DESCR:"Bunker Station and HPU",MC_PLAN:"2022-04-21",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:4,PA_OV_COM:0,PA_OV_REM:4,PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-9810-9811-01",SUBSYSTEM_DESCR:"FPSO West Crane",MC_PLAN:"2022-04-21",B_OV_TOT:7,B_OV_COM:0,B_OV_REM:7,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:32,PA_OV_COM:0,PA_OV_REM:32,PB1_OV_TOT:26,PB1_OV_COM:0,PB1_OV_REM:26,ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:58,PUNCH_TAG_VALID:30},
    {SUBSYSTEM:"21-9810-9812-01",SUBSYSTEM_DESCR:"FPSO East Crane",MC_PLAN:"2022-04-21",B_OV_TOT:7,B_OV_COM:0,B_OV_REM:7,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:26,PA_OV_COM:0,PA_OV_REM:26,PB1_OV_TOT:17,PB1_OV_COM:0,PB1_OV_REM:17,ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:43,PUNCH_TAG_VALID:31},
    {SUBSYSTEM:"21-9820-9822-01",SUBSYSTEM_DESCR:"LQ Personnel Lift",MC_PLAN:"2022-03-03",B_OV_TOT:4,B_OV_COM:0,B_OV_REM:4,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-9820-9823-01",SUBSYSTEM_DESCR:"LQ Goods and Material Lift",MC_PLAN:"2022-02-17",B_OV_TOT:4,B_OV_COM:0,B_OV_REM:4,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:0,PA_OV_REM:3,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-9820-9824-01",SUBSYSTEM_DESCR:"Riser and Umbilical Pull in Winch",MC_PLAN:"2022-04-28",B_OV_TOT:3,B_OV_COM:0,B_OV_REM:3,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-9840-9841-01",SUBSYSTEM_DESCR:"Workshops",MC_PLAN:"2022-01-20",B_OV_TOT:5,B_OV_COM:0,B_OV_REM:5,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P3",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-9840-9842-01",SUBSYSTEM_DESCR:"Laboratory",MC_PLAN:"2022-01-20",B_OV_TOT:9,B_OV_COM:0,B_OV_REM:9,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9840-9844-01",SUBSYSTEM_DESCR:"Galley",MC_PLAN:"2022-03-03",B_OV_TOT:45,B_OV_COM:0,B_OV_REM:45,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:11,PA_OV_COM:0,PA_OV_REM:11,ACT_TAG_VALID:45,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9840-9845-01",SUBSYSTEM_DESCR:"Laundry",MC_PLAN:"2022-03-03",B_OV_TOT:12,B_OV_COM:0,B_OV_REM:12,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9921-9921-01",SUBSYSTEM_DESCR:"LQ Emergency Pressurization Fans",MC_PLAN:"2021-12-16",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-9921-9921-02",SUBSYSTEM_DESCR:"LQ Battery Exhaust Fans, Ducting and Dampers",MC_PLAN:"2021-12-23",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-9921-9921-04",SUBSYSTEM_DESCR:"LQ Emergency SWB Room ACUsandACCUs and UCPs",MC_PLAN:"2021-09-30",B_OV_TOT:4,B_OV_COM:0,B_OV_REM:4,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:100,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Exclusion MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9921-9921-05",SUBSYSTEM_DESCR:"LQ CCR ACUsandACCUs and UCPs",MC_PLAN:"2022-01-27",B_OV_TOT:4,B_OV_COM:0,B_OV_REM:4,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9921-9921-06",SUBSYSTEM_DESCR:"LQ CER ACUsandACCUs and UCPs",MC_PLAN:"2021-10-21",B_OV_TOT:4,B_OV_COM:0,B_OV_REM:4,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-9921-9921-07",SUBSYSTEM_DESCR:"LQ Secondary ICC ACUsandACCUs and UCPs",MC_PLAN:"2021-12-30",B_OV_TOT:4,B_OV_COM:0,B_OV_REM:4,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:6,PB1_OV_COM:0,PB1_OV_REM:6,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-9921-9921-08",SUBSYSTEM_DESCR:"LQ Dining Room and Secondary Muster ACUsandACCUs and UCPs",MC_PLAN:"2021-12-23",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:4,PB1_OV_COM:0,PB1_OV_REM:4,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-9921-9921-09",SUBSYSTEM_DESCR:"LQ Telecom Room ACUsandACCUs and UCPs",MC_PLAN:"2022-01-13",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9921-9922-01",SUBSYSTEM_DESCR:"LQ HVAC UCPs, EDBs, VSDs",MC_PLAN:"2021-12-16",B_OV_TOT:17,B_OV_COM:0,B_OV_REM:17,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:6,PA_OV_COM:2,PA_OV_REM:4,PB1_OV_TOT:27,PB1_OV_COM:5,PB1_OV_REM:22,ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:33,PUNCH_TAG_VALID:17},
    {SUBSYSTEM:"21-9921-9922-02",SUBSYSTEM_DESCR:"LQ HVAC AHUs, Ducting and Dampers",MC_PLAN:"2021-12-16",B_OV_TOT:19,B_OV_COM:0,B_OV_REM:19,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:38,PA_OV_COM:0,PA_OV_REM:38,PB1_OV_TOT:46,PB1_OV_COM:4,PB1_OV_REM:42,ACT_TAG_VALID:19,PUNCH_NUMBER_VALID:84,PUNCH_TAG_VALID:42},
    {SUBSYSTEM:"21-9921-9922-04",SUBSYSTEM_DESCR:"LQ Galley AHUs, Ducting and Dampers",MC_PLAN:"2021-12-23",B_OV_TOT:4,B_OV_COM:0,B_OV_REM:4,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:9,PA_OV_COM:0,PA_OV_REM:9,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-9921-9922-05",SUBSYSTEM_DESCR:"LQ General Exhaust Air, Ducting and Dampers",MC_PLAN:"2022-01-06",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:7,PB1_OV_COM:0,PB1_OV_REM:7,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-9921-9922-06",SUBSYSTEM_DESCR:"LQ Galley Hood Exhaust Fans, Ducting and Dampers and Galley extinguishing system",MC_PLAN:"2022-01-06",B_OV_TOT:4,B_OV_COM:0,B_OV_REM:4,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:0,PA_OV_REM:3,PB1_OV_TOT:4,PB1_OV_COM:0,PB1_OV_REM:4,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-9921-9922-07",SUBSYSTEM_DESCR:"LQ Toilet Exhaust Fans, Ducting and Dampers",MC_PLAN:"2021-12-16",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:0,PA_OV_REM:3,PB1_OV_TOT:4,PB1_OV_COM:0,PB1_OV_REM:4,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-9921-9922-08",SUBSYSTEM_DESCR:"LQ Laundry Exhaust Fans, Ducting and Dampers",MC_PLAN:"2021-12-16",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-9921-9922-09",SUBSYSTEM_DESCR:"LQ Sick Bay Exhaust Fans, Ducting and Dampers",MC_PLAN:"2022-01-06",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:3,PB1_OV_COM:1,PB1_OV_REM:2,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-9921-9922-11",SUBSYSTEM_DESCR:"LQ Laboratory Exhaust Fans, Ducting and Dampers",MC_PLAN:"2022-01-06",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:4,PB1_OV_COM:1,PB1_OV_REM:3,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-9921-9922-12",SUBSYSTEM_DESCR:"LQ Provision Plant Exhaust Fans, Ducting and Dampers",MC_PLAN:"2021-12-16",B_OV_TOT:3,B_OV_COM:0,B_OV_REM:3,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-9921-9922-13",SUBSYSTEM_DESCR:"HULL Purge",MC_PLAN:"2021-12-30",B_OV_TOT:1,B_OV_COM:0,B_OV_REM:1,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9921-9922-14",SUBSYSTEM_DESCR:"LQ Paint Store Supply Fans, Ducting and Dampers",MC_PLAN:"2022-02-24",B_OV_TOT:4,B_OV_COM:0,B_OV_REM:4,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:3,PA_OV_COM:0,PA_OV_REM:3,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9921-9922-15",SUBSYSTEM_DESCR:"LQ Dining Room Exhaust Fan, Ducting and Dampers",MC_PLAN:"2021-12-23",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9921-9927-01",SUBSYSTEM_DESCR:"Cool Store System in Galley",MC_PLAN:"2021-12-16",B_OV_TOT:7,B_OV_COM:0,B_OV_REM:7,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:8,PA_OV_COM:0,PA_OV_REM:8,PB1_OV_TOT:4,PB1_OV_COM:0,PB1_OV_REM:4,ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-9950-9950-02",SUBSYSTEM_DESCR:"Tertiary Refuge ACUsandACCUs and UCPs",MC_PLAN:"2022-03-31",B_OV_TOT:4,B_OV_COM:0,B_OV_REM:4,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9970-9970-01",SUBSYSTEM_DESCR:"HULL Emergency Pressurisation Fans",MC_PLAN:"2021-11-18",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,PB1_OV_TOT:5,PB1_OV_COM:0,PB1_OV_REM:5,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:7},
    {SUBSYSTEM:"21-9970-9971-01",SUBSYSTEM_DESCR:"HULL HVAC UCPs, EDBs, VSDs",MC_PLAN:"2021-10-21",B_OV_TOT:8,B_OV_COM:0,B_OV_REM:8,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:27,PB1_OV_COM:1,PB1_OV_REM:26,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:28,PUNCH_TAG_VALID:16},
    {SUBSYSTEM:"21-9970-9971-02",SUBSYSTEM_DESCR:"HULL Machinery Space Supply Fans , Ducting and Dampers",MC_PLAN:"2021-12-02",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-9970-9971-03",SUBSYSTEM_DESCR:"HULL Machinery Space Exhaust Fans , Ducting and Dampers",MC_PLAN:"2021-12-02",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:9,PA_OV_COM:0,PA_OV_REM:9,PB1_OV_TOT:5,PB1_OV_COM:0,PB1_OV_REM:5,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:14,PUNCH_TAG_VALID:7},
    {SUBSYSTEM:"21-9970-9971-04",SUBSYSTEM_DESCR:"KSG Room Supply Fans, Ducting and Dampers",MC_PLAN:"2021-11-11",B_OV_TOT:3,B_OV_COM:0,B_OV_REM:3,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:4,PB1_OV_COM:0,PB1_OV_REM:4,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-9970-9971-05",SUBSYSTEM_DESCR:"KSG Room Exhaust Fans, Ducting and Dampers",MC_PLAN:"2021-11-11",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-9970-9971-06",SUBSYSTEM_DESCR:"HULL Chilled Water System and UCP",MC_PLAN:"2021-12-23",B_OV_TOT:9,B_OV_COM:0,B_OV_REM:9,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:17,PA_OV_COM:0,PA_OV_REM:17,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:8},
    {SUBSYSTEM:"21-9970-9971-07",SUBSYSTEM_DESCR:"HULL Chiller Exhaust Fans, Ducting and Dampers",MC_PLAN:"2021-11-18",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9970-9971-08",SUBSYSTEM_DESCR:"HULL Machinery Space AHU Air Supply",MC_PLAN:"2021-11-18",B_OV_TOT:7,B_OV_COM:0,B_OV_REM:7,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:11,PA_OV_COM:3,PA_OV_REM:8,PB1_OV_TOT:7,PB1_OV_COM:0,PB1_OV_REM:7,ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-9970-9971-10",SUBSYSTEM_DESCR:"HULL Diesel Oil Pump Room Supply Fans, Ducting and Dampers",MC_PLAN:"2021-11-04",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:4,PB1_OV_COM:0,PB1_OV_REM:4,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-9970-9971-11",SUBSYSTEM_DESCR:"HULL Diesel Oil Pump Room Exhaust Fans, Ducting and Dampers",MC_PLAN:"2021-10-14",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:5,PB1_OV_COM:0,PB1_OV_REM:5,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-9970-9971-12",SUBSYSTEM_DESCR:"HULL Machinery Space HPU AHU, Ducting and Damper",MC_PLAN:"2021-11-18",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:5,PA_OV_COM:0,PA_OV_REM:5,PB1_OV_TOT:8,PB1_OV_COM:1,PB1_OV_REM:7,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:9},
    {SUBSYSTEM:"21-9976-9976-01",SUBSYSTEM_DESCR:"Topsides Emergency Pressurisation Fans",MC_PLAN:"2021-12-09",B_OV_TOT:3,B_OV_COM:0,B_OV_REM:3,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:4,PB1_OV_COM:3,PB1_OV_REM:1,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-9976-9976-02",SUBSYSTEM_DESCR:"Topsides Battery Exhaust Fans, Ducting and Dampers",MC_PLAN:"2021-12-09",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"N",PRIORITY:"P2",PB1_OV_TOT:3,PB1_OV_COM:3,PB1_OV_REM:0,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9976-9976-03",SUBSYSTEM_DESCR:"Emergency Room ACUsandACCUs and UCPs",MC_PLAN:"2021-12-09",B_OV_TOT:7,B_OV_COM:0,B_OV_REM:7,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:5,PA_OV_COM:3,PA_OV_REM:2,PB1_OV_TOT:2,PB1_OV_COM:1,PB1_OV_REM:1,ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-9976-9977-01",SUBSYSTEM_DESCR:"Topsides HVAC UCPs, EDBs, VSDs",MC_PLAN:"2021-12-09",B_OV_TOT:3,B_OV_COM:0,B_OV_REM:3,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:5,PB1_OV_COM:0,PB1_OV_REM:5,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9976-9977-02",SUBSYSTEM_DESCR:"Topsides HVAC AHUs, Ducting and Dampers",MC_PLAN:"2021-12-09",B_OV_TOT:6,B_OV_COM:0,B_OV_REM:6,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"Belong to MC not completed system",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:6,PA_OV_COM:5,PA_OV_REM:1,PB1_OV_TOT:4,PB1_OV_COM:2,PB1_OV_REM:2,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:5}
    ],
  Queries: {
    SQL1: [
      { DESC: 'DataSource SPCS Data 03-Mar-20' }
    ],
    SQL2: [
      { CDATE: '2021-11-25' }
    ],
    // Data table & Line chart Data
    SQL3:  [
      {MC_PLAN:"2021-09-23",B_T:722,B_A:272,B_O:450,B_PG:37.67,PA_O:2,PB1_O:113,PB2_O:2,PB3_O:0},
      {MC_PLAN:"2021-09-30",B_T:615,B_A:176,B_O:439,B_PG:28.62,PA_O:6,PB1_O:51,PB2_O:35,PB3_O:0},
      {MC_PLAN:"2021-10-07"},
      {MC_PLAN:"2021-10-14",B_T:752,B_A:0,B_O:752,B_PG:0,PA_O:4,PB1_O:56,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-10-21",B_T:97,B_A:0,B_O:97,B_PG:0,PA_O:11,PB1_O:46,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-10-28",B_T:689,B_A:0,B_O:689,B_PG:0,PA_O:3,PB1_O:44,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-11-04",B_T:393,B_A:0,B_O:393,B_PG:0,PA_O:20,PB1_O:60,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-11-11",B_T:67,B_A:0,B_O:67,B_PG:0,PA_O:8,PB1_O:9,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-11-18",B_T:428,B_A:0,B_O:428,B_PG:0,PA_O:34,PB1_O:59,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-11-25",B_T:8,B_A:0,B_O:8,B_PG:0,PA_O:1,PB1_O:1,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-12-02",B_T:115,B_A:0,B_O:115,B_PG:0,PA_O:12,PB1_O:7,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-12-09",B_T:53,B_A:0,B_O:53,B_PG:0,PA_O:21,PB1_O:38,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-12-16",B_T:52,B_A:0,B_O:52,B_PG:0,PA_O:56,PB1_O:75,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-12-23",B_T:38,B_A:0,B_O:38,B_PG:0,PA_O:34,PB1_O:12,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2021-12-30",B_T:111,B_A:0,B_O:111,B_PG:0,PA_O:12,PB1_O:16,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-01-06",B_T:14,B_A:0,B_O:14,B_PG:0,PA_O:6,PB1_O:16,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-01-13",B_T:12,B_A:0,B_O:12,B_PG:0,PA_O:6,PB1_O:2,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-01-20",B_T:18,B_A:0,B_O:18,B_PG:0,PA_O:1,PB1_O:3,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-01-27",B_T:15,B_A:0,B_O:15,B_PG:0,PA_O:8,PB1_O:6,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-02-03"},
      {MC_PLAN:"2022-02-10"},
      {MC_PLAN:"2022-02-17",B_T:93,B_A:0,B_O:93,B_PG:0,PA_O:10,PB1_O:27,PB2_O:0,PB3_O:1},
      {MC_PLAN:"2022-02-24",B_T:17,B_A:0,B_O:17,B_PG:0,PA_O:8,PB1_O:2,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-03-03",B_T:65,B_A:0,B_O:65,B_PG:0,PA_O:13,PB1_O:1,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-03-10",B_T:3,B_A:0,B_O:3,B_PG:0,PA_O:1,PB1_O:0,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-03-17",B_T:4,B_A:0,B_O:4,B_PG:0,PA_O:0,PB1_O:11,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-03-24"},
      {MC_PLAN:"2022-03-31",B_T:59,B_A:0,B_O:59,B_PG:0,PA_O:3,PB1_O:4,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-04-07",B_T:25,B_A:0,B_O:25,B_PG:0,PA_O:29,PB1_O:18,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-04-14",B_T:35,B_A:0,B_O:35,B_PG:0,PA_O:13,PB1_O:8,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-04-21",B_T:34,B_A:0,B_O:34,B_PG:0,PA_O:72,PB1_O:49,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-04-28",B_T:106,B_A:0,B_O:106,B_PG:0,PA_O:12,PB1_O:13,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-05-05",B_T:74,B_A:0,B_O:74,B_PG:0,PA_O:0,PB1_O:9,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-05-12",B_T:62,B_A:0,B_O:62,B_PG:0,PA_O:4,PB1_O:4,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-05-19",B_T:15,B_A:0,B_O:15,B_PG:0,PA_O:1,PB1_O:0,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-05-26",B_T:32,B_A:0,B_O:32,B_PG:0,PA_O:0,PB1_O:6,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-06-02",B_T:2,B_A:0,B_O:2,B_PG:0,PA_O:2,PB1_O:4,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-06-09",B_T:24,B_A:0,B_O:24,B_PG:0,PA_O:14,PB1_O:35,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-06-16"},
      {MC_PLAN:"2022-06-23",B_T:12,B_A:0,B_O:12,B_PG:0,PA_O:1,PB1_O:2,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-06-30",B_T:4,B_A:0,B_O:4,B_PG:0},
      {MC_PLAN:"2022-07-07",B_T:4,B_A:0,B_O:4,B_PG:0,PA_O:1,PB1_O:0,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-07-14",B_T:16,B_A:0,B_O:16,B_PG:0,PA_O:1,PB1_O:0,PB2_O:0,PB3_O:0},
      {MC_PLAN:"2022-07-21"},
      {MC_PLAN:"2022-07-28"},
      {MC_PLAN:"2022-08-04",B_T:12,B_A:0,B_O:12,B_PG:0},
      {MC_PLAN:"2022-08-11"}
      ],
    SQL4: [
      {BITR_TOTAL:4897,MC_NOT_COM_BITR:3451,MC_COM_BITR:871,BITR_COMPLETED:575,PARTIAL_MC_COMPLETE:11,FULL_MC_COMPLETE:10}
    ],
    SQL7: [
      {CUTOFF:"2018-06-22",WK_NO:"W2018_25"},
      {CUTOFF:"2018-06-29",WK_NO:"W2018_26"},
      {CUTOFF:"2018-07-06",WK_NO:"W2018_27"},
      {CUTOFF:"2018-07-13",WK_NO:"W2018_28"},
      {CUTOFF:"2018-07-20",WK_NO:"W2018_29"},
      {CUTOFF:"2018-07-27",WK_NO:"W2018_30"},
      {CUTOFF:"2018-08-03",WK_NO:"W2018_31"},
      {CUTOFF:"2018-08-10",WK_NO:"W2018_32"},
      {CUTOFF:"2018-08-17",WK_NO:"W2018_33"},
      {CUTOFF:"2018-08-24",WK_NO:"W2018_34"},
      {CUTOFF:"2018-08-31",WK_NO:"W2018_35"},
      {CUTOFF:"2018-09-07",WK_NO:"W2018_36"},
      {CUTOFF:"2018-09-14",WK_NO:"W2018_37"},
      {CUTOFF:"2018-09-21",WK_NO:"W2018_38"},
      {CUTOFF:"2018-09-28",WK_NO:"W2018_39"},
      {CUTOFF:"2018-10-05",WK_NO:"W2018_40"},
      {CUTOFF:"2018-10-12",WK_NO:"W2018_41"},
      {CUTOFF:"2018-10-19",WK_NO:"W2018_42"},
      {CUTOFF:"2018-10-26",WK_NO:"W2018_43"},
      {CUTOFF:"2018-11-02",WK_NO:"W2018_44"},
      {CUTOFF:"2018-11-09",WK_NO:"W2018_45"},
      {CUTOFF:"2018-11-16",WK_NO:"W2018_46"},
      {CUTOFF:"2018-11-23",WK_NO:"W2018_47"},
      {CUTOFF:"2018-11-30",WK_NO:"W2018_48"},
      {CUTOFF:"2018-12-07",WK_NO:"W2018_49"},
      {CUTOFF:"2018-12-14",WK_NO:"W2018_50"},
      {CUTOFF:"2018-12-21",WK_NO:"W2018_51"},
      {CUTOFF:"2018-12-28",WK_NO:"W2018_52"},
      {CUTOFF:"2019-01-04",WK_NO:"W2019_01"},
      {CUTOFF:"2019-01-11",WK_NO:"W2019_02"},
      {CUTOFF:"2019-01-18",WK_NO:"W2019_03"},
      {CUTOFF:"2019-01-25",WK_NO:"W2019_04"},
      {CUTOFF:"2019-02-01",WK_NO:"W2019_05"},
      {CUTOFF:"2019-02-08",WK_NO:"W2019_06"},
      {CUTOFF:"2019-02-15",WK_NO:"W2019_07"},
      {CUTOFF:"2019-02-22",WK_NO:"W2019_08"},
      {CUTOFF:"2019-03-01",WK_NO:"W2019_09"},
      {CUTOFF:"2019-03-08",WK_NO:"W2019_10"},
      {CUTOFF:"2019-03-15",WK_NO:"W2019_11"},
      {CUTOFF:"2019-03-22",WK_NO:"W2019_12"},
      {CUTOFF:"2019-03-29",WK_NO:"W2019_13"},
      {CUTOFF:"2019-04-05",WK_NO:"W2019_14"},
      {CUTOFF:"2019-04-12",WK_NO:"W2019_15"},
      {CUTOFF:"2019-04-19",WK_NO:"W2019_16"},
      {CUTOFF:"2019-04-26",WK_NO:"W2019_17"},
      {CUTOFF:"2019-05-03",WK_NO:"W2019_18"},
      {CUTOFF:"2019-05-10",WK_NO:"W2019_19"},
      {CUTOFF:"2019-05-17",WK_NO:"W2019_20"},
      {CUTOFF:"2019-05-24",WK_NO:"W2019_21"},
      {CUTOFF:"2019-05-31",WK_NO:"W2019_22"},
      {CUTOFF:"2019-06-07",WK_NO:"W2019_23"},
      {CUTOFF:"2019-06-14",WK_NO:"W2019_24"},
      {CUTOFF:"2019-06-21",WK_NO:"W2019_25"},
      {CUTOFF:"2019-06-28",WK_NO:"W2019_26"},
      {CUTOFF:"2019-07-05",WK_NO:"W2019_27"},
      {CUTOFF:"2019-07-12",WK_NO:"W2019_28"},
      {CUTOFF:"2019-07-19",WK_NO:"W2019_29"},
      {CUTOFF:"2019-07-26",WK_NO:"W2019_30"},
      {CUTOFF:"2019-08-02",WK_NO:"W2019_31"},
      {CUTOFF:"2019-08-09",WK_NO:"W2019_32"},
      {CUTOFF:"2019-08-16",WK_NO:"W2019_33"},
      {CUTOFF:"2019-08-23",WK_NO:"W2019_34"},
      {CUTOFF:"2019-08-30",WK_NO:"W2019_35"},
      {CUTOFF:"2019-09-06",WK_NO:"W2019_36"},
      {CUTOFF:"2019-09-13",WK_NO:"W2019_37"},
      {CUTOFF:"2019-09-20",WK_NO:"W2019_38"},
      {CUTOFF:"2019-09-27",WK_NO:"W2019_39"},
      {CUTOFF:"2019-10-04",WK_NO:"W2019_40"},
      {CUTOFF:"2019-10-11",WK_NO:"W2019_41"},
      {CUTOFF:"2019-10-18",WK_NO:"W2019_42"},
      {CUTOFF:"2019-10-25",WK_NO:"W2019_43"},
      {CUTOFF:"2019-11-01",WK_NO:"W2019_44"},
      {CUTOFF:"2019-11-08",WK_NO:"W2019_45"},
      {CUTOFF:"2019-11-15",WK_NO:"W2019_46"},
      {CUTOFF:"2019-11-22",WK_NO:"W2019_47"},
      {CUTOFF:"2019-11-29",WK_NO:"W2019_48"},
      {CUTOFF:"2019-12-06",WK_NO:"W2019_49"},
      {CUTOFF:"2019-12-13",WK_NO:"W2019_50"},
      {CUTOFF:"2019-12-20",WK_NO:"W2019_51"},
      {CUTOFF:"2019-12-27",WK_NO:"W2019_52"},
      {CUTOFF:"2020-01-03",WK_NO:"W2020_01"},
      {CUTOFF:"2020-01-10",WK_NO:"W2020_02"},
      {CUTOFF:"2020-01-17",WK_NO:"W2020_03"},
      {CUTOFF:"2020-01-24",WK_NO:"W2020_04"},
      {CUTOFF:"2020-01-31",WK_NO:"W2020_05"},
      {CUTOFF:"2020-02-07",WK_NO:"W2020_06"},
      {CUTOFF:"2020-02-14",WK_NO:"W2020_07"},
      {CUTOFF:"2020-02-21",WK_NO:"W2020_08"},
      {CUTOFF:"2020-02-28",WK_NO:"W2020_09"},
      {CUTOFF:"2020-03-06",WK_NO:"W2020_10"},
      {CUTOFF:"2020-03-13",WK_NO:"W2020_11"},
      {CUTOFF:"2020-03-20",WK_NO:"W2020_12"},
      {CUTOFF:"2020-03-27",WK_NO:"W2020_13"},
      {CUTOFF:"2020-04-03",WK_NO:"W2020_14"},
      {CUTOFF:"2020-04-10",WK_NO:"W2020_15"},
      {CUTOFF:"2020-04-17",WK_NO:"W2020_16"},
      {CUTOFF:"2020-04-24",WK_NO:"W2020_17"},
      {CUTOFF:"2020-05-01",WK_NO:"W2020_18"},
      {CUTOFF:"2020-05-08",WK_NO:"W2020_19"},
      {CUTOFF:"2020-05-15",WK_NO:"W2020_20"},
      {CUTOFF:"2020-05-22",WK_NO:"W2020_21"},
      {CUTOFF:"2020-05-29",WK_NO:"W2020_22"},
      {CUTOFF:"2020-06-05",WK_NO:"W2020_23"},
      {CUTOFF:"2020-06-12",WK_NO:"W2020_24"},
      {CUTOFF:"2020-06-19",WK_NO:"W2020_25"},
      {CUTOFF:"2020-06-26",WK_NO:"W2020_26"},
      {CUTOFF:"2020-07-03",WK_NO:"W2020_27"},
      {CUTOFF:"2020-07-10",WK_NO:"W2020_28"},
      {CUTOFF:"2020-07-17",WK_NO:"W2020_29"},
      {CUTOFF:"2020-07-24",WK_NO:"W2020_30"},
      {CUTOFF:"2020-07-31",WK_NO:"W2020_31"},
      {CUTOFF:"2020-08-07",WK_NO:"W2020_32"},
      {CUTOFF:"2020-08-14",WK_NO:"W2020_33"},
      {CUTOFF:"2020-08-21",WK_NO:"W2020_34"},
      {CUTOFF:"2020-08-28",WK_NO:"W2020_35"},
      {CUTOFF:"2020-09-04",WK_NO:"W2020_36"},
      {CUTOFF:"2020-09-11",WK_NO:"W2020_37"},
      {CUTOFF:"2020-09-18",WK_NO:"W2020_38"},
      {CUTOFF:"2020-09-25",WK_NO:"W2020_39"},
      {CUTOFF:"2020-10-02",WK_NO:"W2020_40"},
      {CUTOFF:"2020-10-09",WK_NO:"W2020_41"},
      {CUTOFF:"2020-10-16",WK_NO:"W2020_42"},
      {CUTOFF:"2020-10-23",WK_NO:"W2020_43"},
      {CUTOFF:"2020-10-30",WK_NO:"W2020_44"},
      {CUTOFF:"2020-11-06",WK_NO:"W2020_45"},
      {CUTOFF:"2020-11-13",WK_NO:"W2020_46"},
      {CUTOFF:"2020-11-20",WK_NO:"W2020_47"},
      {CUTOFF:"2020-11-27",WK_NO:"W2020_48"},
      {CUTOFF:"2020-12-04",WK_NO:"W2020_49"},
      {CUTOFF:"2020-12-11",WK_NO:"W2020_50"},
      {CUTOFF:"2020-12-18",WK_NO:"W2020_51"},
      {CUTOFF:"2020-12-25",WK_NO:"W2020_52"},
      {CUTOFF:"2021-01-01",WK_NO:"W2020_53"},
      {CUTOFF:"2021-01-08",WK_NO:"W2021_01"},
      {CUTOFF:"2021-01-15",WK_NO:"W2021_02"},
      {CUTOFF:"2021-01-22",WK_NO:"W2021_03"},
      {CUTOFF:"2021-01-29",WK_NO:"W2021_04"},
      {CUTOFF:"2021-02-05",WK_NO:"W2021_05"},
      {CUTOFF:"2021-02-12",WK_NO:"W2021_06"},
      {CUTOFF:"2021-02-19",WK_NO:"W2021_07"},
      {CUTOFF:"2021-02-26",WK_NO:"W2021_08"},
      {CUTOFF:"2021-03-05",WK_NO:"W2021_09"},
      {CUTOFF:"2021-03-12",WK_NO:"W2021_10"},
      {CUTOFF:"2021-03-19",WK_NO:"W2021_11"},
      {CUTOFF:"2021-03-26",WK_NO:"W2021_12"},
      {CUTOFF:"2021-04-02",WK_NO:"W2021_13"},
      {CUTOFF:"2021-04-09",WK_NO:"W2021_14"},
      {CUTOFF:"2021-04-16",WK_NO:"W2021_15"},
      {CUTOFF:"2021-04-23",WK_NO:"W2021_16"},
      {CUTOFF:"2021-04-30",WK_NO:"W2021_17"},
      {CUTOFF:"2021-05-07",WK_NO:"W2021_18"},
      {CUTOFF:"2021-05-14",WK_NO:"W2021_19"},
      {CUTOFF:"2021-05-21",WK_NO:"W2021_20"},
      {CUTOFF:"2021-05-28",WK_NO:"W2021_21"},
      {CUTOFF:"2021-06-04",WK_NO:"W2021_22"},
      {CUTOFF:"2021-06-11",WK_NO:"W2021_23"},
      {CUTOFF:"2021-06-18",WK_NO:"W2021_24"},
      {CUTOFF:"2021-06-25",WK_NO:"W2021_25"},
      {CUTOFF:"2021-07-02",WK_NO:"W2021_26"},
      {CUTOFF:"2021-07-09",WK_NO:"W2021_27"},
      {CUTOFF:"2021-07-16",WK_NO:"W2021_28"},
      {CUTOFF:"2021-07-23",WK_NO:"W2021_29"},
      {CUTOFF:"2021-07-30",WK_NO:"W2021_30"},
      {CUTOFF:"2021-08-06",WK_NO:"W2021_31"},
      {CUTOFF:"2021-08-13",WK_NO:"W2021_32"},
      {CUTOFF:"2021-08-20",WK_NO:"W2021_33"},
      {CUTOFF:"2021-08-27",WK_NO:"W2021_34"},
      {CUTOFF:"2021-09-03",WK_NO:"W2021_35"},
      {CUTOFF:"2021-09-10",WK_NO:"W2021_36"},
      {CUTOFF:"2021-09-17",WK_NO:"W2021_37"},
      {CUTOFF:"2021-09-24",WK_NO:"W2021_38"},
      {CUTOFF:"2021-10-01",WK_NO:"W2021_39"},
      {CUTOFF:"2021-10-08",WK_NO:"W2021_40"},
      {CUTOFF:"2021-10-15",WK_NO:"W2021_41"},
      {CUTOFF:"2021-10-22",WK_NO:"W2021_42"},
      {CUTOFF:"2021-10-29",WK_NO:"W2021_43"},
      {CUTOFF:"2021-11-05",WK_NO:"W2021_44"},
      {CUTOFF:"2021-11-12",WK_NO:"W2021_45"},
      {CUTOFF:"2021-11-19",WK_NO:"W2021_46"},
      {CUTOFF:"2021-11-26",WK_NO:"W2021_47"},
      {CUTOFF:"2021-12-03",WK_NO:"W2021_48"},
      {CUTOFF:"2021-12-10",WK_NO:"W2021_49"},
      {CUTOFF:"2021-12-17",WK_NO:"W2021_50"},
      {CUTOFF:"2021-12-24",WK_NO:"W2021_51"},
      {CUTOFF:"2021-12-31",WK_NO:"W2021_52"},
      {CUTOFF:"2022-01-07",WK_NO:"W2022_01"},
      {CUTOFF:"2022-01-14",WK_NO:"W2022_02"},
      {CUTOFF:"2022-01-21",WK_NO:"W2022_03"},
      {CUTOFF:"2022-01-28",WK_NO:"W2022_04"},
      {CUTOFF:"2022-02-04",WK_NO:"W2022_05"},
      {CUTOFF:"2022-02-11",WK_NO:"W2022_06"},
      {CUTOFF:"2022-02-18",WK_NO:"W2022_07"},
      {CUTOFF:"2022-02-25",WK_NO:"W2022_08"},
      {CUTOFF:"2022-03-04",WK_NO:"W2022_09"},
      {CUTOFF:"2022-03-11",WK_NO:"W2022_10"},
      {CUTOFF:"2022-03-18",WK_NO:"W2022_11"},
      {CUTOFF:"2022-03-25",WK_NO:"W2022_12"},
      {CUTOFF:"2022-04-01",WK_NO:"W2022_13"},
      {CUTOFF:"2022-04-08",WK_NO:"W2022_14"},
      {CUTOFF:"2022-04-15",WK_NO:"W2022_15"}
    ],
  }
}