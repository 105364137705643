import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1402,
      CanvasHeight: 2500,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Company Interface Management Team Org. Chart', x:20, y: 10, font: 'roboto', class: 'regular', size: 30, color: '#424242', align: 'start' },
      ],
      Filter: [],
      Sql: []
    },
    // Queries: {
    //   SQL1: [
        
    //   ]
    // },
    DataItems:  [

      { ITEM: 'Project Engineering Manager ', DESC: 'Interface management process owner', CONTRACT: 'Tiong-Peng ANG', },
      { ITEM: 'Interface Manager', DESC: 'Overall interface monitoring, recording and reporting, SEI interface coordination', CONTRACT: 'Kevin GUO', },
      { ITEM: 'DTC – Execution Manager', DESC: 'DTC Project', CONTRACT: 'Martin TAN', },
      { ITEM: 'DTC – Project Engineer', DESC: 'Interface related to HCCP Main Plant', CONTRACT: 'Isaac ZHOU', },
      { ITEM: 'DTC -- Project Engineer', DESC: 'Interface non-related to HCCP Main Plant', CONTRACT: 'Jennifer REN', },
      { ITEM: 'P1 – Engineering Manager', DESC: 'Olefins', CONTRACT: 'Kah-Huat PANG', },
      { ITEM: 'P1 – OR – Execution Manager', DESC: 'Olefins Recovery', CONTRACT: 'Steve QIN', },
      { ITEM: 'P1 – OR  3P – GEG Cogen', DESC: 'SHP Steam, Tail Gas, Condensate', CONTRACT: 'Bennett LI', },
      { ITEM: 'P1 – OR  3P – GEG NG', DESC: 'Nature Gas', CONTRACT: 'Bennett LI', },
      { ITEM: 'P3 – Engineering Manager', DESC: 'Infrastructure & Off Plots', CONTRACT: 'Joel BAIN', },
      { ITEM: 'P3 - Buildings & EIT - Execution Manager', DESC: 'Buildings & EIT', CONTRACT: 'Gus BECKER', },
      { ITEM: 'P3 – Infra 3P - HZED', DESC: '220kV power supply from Zhong Qu & Chong Wen Substation', CONTRACT: 'Jian Jun GUO ', },
      { ITEM: 'P3 - Eastward & Infra - Execution Manager', DESC: 'Eastward & Infrastructure', CONTRACT: 'Yang LIN', },
      { ITEM: 'P3 – Infra 3P Interconnects', DESC: 'Industrial Water / Town Gas / Clean Storm Water', CONTRACT: 'Jason ZHAO', },
      { ITEM: 'P3 – Eastward 3P – DPRC', DESC: 'DPRC – Dayawan Pipe Rack Company', CONTRACT: 'Jason ZHAO', },
      { ITEM: 'P3 – Eastward 3P – CSPC', DESC: 'Mix’s C4, H2, Ethylene, Propylene, Aromatics', CONTRACT: 'Jason ZHAO', },
      { ITEM: 'P3 – Eastward 3P – Linde', DESC: 'HP Nitrogen, LP Nitrogen', CONTRACT: 'Jason ZHAO', },
      { ITEM: 'P3 – Eastward 3P – QingYuan', DESC: 'CW Blowdown, Process WW, High Salinity WW, Recycle Water Return', CONTRACT: 'Charles CHEN', },
      { ITEM: 'P3 – Off Plot - Execution Manager', DESC: 'Off Plot', CONTRACT: 'Fei CHEN', },
      { ITEM: 'P3 – Offplot – Terminal', DESC: 'Quan Mei Terminal, Contractor: HQGD', CONTRACT: 'Jerel LOW', },
      { ITEM: 'P3 – Offplot – QM Jetty', DESC: 'QM Jetty, Contractor: SHDI', CONTRACT: 'Chengsheng LI', },
      { ITEM: 'P3 – Offplot – MBZ Island', DESC: 'MBZ Island, Contractor: GPEC+FHDI, 3rd Party: HuaDe', CONTRACT: 'Xinglu WANG', },
      { ITEM: 'P3 – Offplot – Area Engineering Manager', DESC: 'Naphtha Pipeline Project Contractors: GPEC+CPOE, GPEC+FHDI, 3rd Party: HuaDe', CONTRACT: 'Minghui YAO', },


    ],
}