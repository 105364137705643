import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 800,
      CanvasHeight: 1000,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },
    // Queries: {
    //   SQL1: [
    //     {IG_NO:"IG-E2-001",LOCATIONS:"South BL gate of  Main Plant",COORDINATION:"X: 2514432.060     Y: 558266.500  ",REMARKS:"IP with HQGD/SHDI/GEG SHP steam/NG"},
    //   ]
    // },
    DataItems:  [
      {NO:1,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-HQ-L-0001",INTERFACE_DESCR:"LSFO",SUPPLIER_OWNER:"Yang Zhitao",REQUESTER_OWNER:"CuiPeng",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:2,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-HQ-L-0002",INTERFACE_DESCR:"Benzene",SUPPLIER_OWNER:"Yang Zhitao",REQUESTER_OWNER:"CuiPeng",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:3,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-HQ-L-0003",INTERFACE_DESCR:"MTBEDIB MTBE",SUPPLIER_OWNER:"Yang Zhitao",REQUESTER_OWNER:"CuiPeng",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:4,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-HQ-L-0004",INTERFACE_DESCR:"HDBSCN",SUPPLIER_OWNER:"Yang Zhitao",REQUESTER_OWNER:"CuiPeng",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:5,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-HQ-L-0005",INTERFACE_DESCR:"Toluene",SUPPLIER_OWNER:"Yang Zhitao",REQUESTER_OWNER:"CuiPeng",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:6,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-HQ-L-0006",INTERFACE_DESCR:"Mogas",SUPPLIER_OWNER:"Yang Zhitao",REQUESTER_OWNER:"CuiPeng",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:7,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-HQ-L-0007",INTERFACE_DESCR:"Methanol",SUPPLIER_OWNER:"Yang Zhitao",REQUESTER_OWNER:"CuiPeng",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:8,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-HQ-L-0008",INTERFACE_DESCR:"Caustic 32  32",SUPPLIER_OWNER:"Yang Zhitao",REQUESTER_OWNER:"CuiPeng",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:9,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-HQ-L-0009",INTERFACE_DESCR:"Quench Oil",SUPPLIER_OWNER:"Yang Zhitao",REQUESTER_OWNER:"CuiPeng",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:10,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-HQ-L-0010",INTERFACE_DESCR:"Hexene1   1",SUPPLIER_OWNER:"Yang Zhitao",REQUESTER_OWNER:"CuiPeng",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:11,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-HQ-L-0011",INTERFACE_DESCR:"Alkylate",SUPPLIER_OWNER:"Yang Zhitao",REQUESTER_OWNER:"CuiPeng",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:12,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-HQ-L-0012",INTERFACE_DESCR:"Flush Mogas",SUPPLIER_OWNER:"Yang Zhitao",REQUESTER_OWNER:"CuiPeng",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:13,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-HQ-L-0013",INTERFACE_DESCR:"Naphtha",SUPPLIER_OWNER:"Yang Zhitao",REQUESTER_OWNER:"CuiPeng",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:14,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-HQ-L-0014",INTERFACE_DESCR:"Naphtha",SUPPLIER_OWNER:"Yang Zhitao",REQUESTER_OWNER:"CuiPeng",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:15,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-HQ-L-0015",INTERFACE_DESCR:"Nitrogen",SUPPLIER_OWNER:"Yang Zhitao",REQUESTER_OWNER:"CuiPeng",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:16,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-HQ-L-0016",INTERFACE_DESCR:"Waste Water",SUPPLIER_OWNER:"Yang Zhitao",REQUESTER_OWNER:"CuiPeng",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:17,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-HQ-L-0017",INTERFACE_DESCR:"Recoverable Oil",SUPPLIER_OWNER:"Yang Zhitao",REQUESTER_OWNER:"CuiPeng",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:18,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-W1-L-0001",INTERFACE_DESCR:"SCNBTHC",SUPPLIER_OWNER:"Song Longchao",REQUESTER_OWNER:"CuiPeng",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:19,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-W1-L-0002",INTERFACE_DESCR:"SCNBTHC",SUPPLIER_OWNER:"Song Longchao",REQUESTER_OWNER:"CuiPeng",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:20,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-W1-L-0003",INTERFACE_DESCR:"Ethylene",SUPPLIER_OWNER:"Song Longchao",REQUESTER_OWNER:"CuiPeng",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:21,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-W1-L-0004",INTERFACE_DESCR:"Ethylene",SUPPLIER_OWNER:"Song Longchao",REQUESTER_OWNER:"CuiPeng",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:22,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-W1-L-0005",INTERFACE_DESCR:"Propylene",SUPPLIER_OWNER:"Song Longchao",REQUESTER_OWNER:"CuiPeng",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:23,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-W1-L-0006",INTERFACE_DESCR:"Raw C4   4",SUPPLIER_OWNER:"Song Longchao",REQUESTER_OWNER:"CuiPeng",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:24,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-W1-L-0007",INTERFACE_DESCR:"Ethylen Vapor Return dedicated loading arm",SUPPLIER_OWNER:"Song Longchao",REQUESTER_OWNER:"CuiPeng",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:25,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-W1-L-0008",INTERFACE_DESCR:"Butadiene",SUPPLIER_OWNER:"Song Longchao",REQUESTER_OWNER:"CuiPeng",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:26,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-W1-L-0009",INTERFACE_DESCR:"Butadiene",SUPPLIER_OWNER:"Song Longchao",REQUESTER_OWNER:"CuiPeng",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:27,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-W1-L-0010",INTERFACE_DESCR:"Butadiene Vapor Return",SUPPLIER_OWNER:"Song Longchao",REQUESTER_OWNER:"CuiPeng",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:28,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-W1-L-0011",INTERFACE_DESCR:"Butene1  1",SUPPLIER_OWNER:"Song Longchao",REQUESTER_OWNER:"CuiPeng",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:29,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-W1-L-0012",INTERFACE_DESCR:"Butene1 Vapor Return    1",SUPPLIER_OWNER:"Song Longchao",REQUESTER_OWNER:"CuiPeng",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:30,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-W1-L-0013",INTERFACE_DESCR:"PropyleneRaw C4 Vapor Return 4",SUPPLIER_OWNER:"Song Longchao",REQUESTER_OWNER:"CuiPeng",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:31,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-HQ-W1-L-0001",INTERFACE_DESCR:"LSFO Component Flange connection",SUPPLIER_OWNER:"Song Longchao",REQUESTER_OWNER:"Yang Zhitao",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:32,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-HQ-W1-L-0002",INTERFACE_DESCR:"Benzene Flange connection",SUPPLIER_OWNER:"Song Longchao",REQUESTER_OWNER:"Yang Zhitao",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:33,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-HQ-W1-L-0003",INTERFACE_DESCR:"MTBEDIB HDBSCN Flange connection",SUPPLIER_OWNER:"Song Longchao",REQUESTER_OWNER:"Yang Zhitao",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:34,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-HQ-W1-L-0004",INTERFACE_DESCR:"Toluene Flange connection",SUPPLIER_OWNER:"Song Longchao",REQUESTER_OWNER:"Yang Zhitao",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:35,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-HQ-W1-L-0005",INTERFACE_DESCR:"Mogas Flange connection",SUPPLIER_OWNER:"Song Longchao",REQUESTER_OWNER:"Yang Zhitao",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:36,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-HQ-W1-L-0006",INTERFACE_DESCR:"Mogas Flange connection",SUPPLIER_OWNER:"Song Longchao",REQUESTER_OWNER:"Yang Zhitao",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:37,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-HQ-W1-L-0007",INTERFACE_DESCR:"Mogas Flange connection",SUPPLIER_OWNER:"Song Longchao",REQUESTER_OWNER:"Yang Zhitao",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:38,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-HQ-W1-L-0008",INTERFACE_DESCR:"Quench Oil Flange connection",SUPPLIER_OWNER:"Song Longchao",REQUESTER_OWNER:"Yang Zhitao",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:39,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-HQ-W1-L-0009",INTERFACE_DESCR:"Caustic 32 Flange connection",SUPPLIER_OWNER:"Song Longchao",REQUESTER_OWNER:"Yang Zhitao",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:40,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-HQ-W1-L-0010",INTERFACE_DESCR:"Hexene1 Flange connection",SUPPLIER_OWNER:"Song Longchao",REQUESTER_OWNER:"Yang Zhitao",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:41,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-HQ-W1-L-0011",INTERFACE_DESCR:"Methanol Flange connection",SUPPLIER_OWNER:"Song Longchao",REQUESTER_OWNER:"Yang Zhitao",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:42,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-HQ-W1-L-0012",INTERFACE_DESCR:"Alkylate Flange connection",SUPPLIER_OWNER:"Song Longchao",REQUESTER_OWNER:"Yang Zhitao",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:43,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-HQ-W1-L-0013",INTERFACE_DESCR:"VOCs Flange connection",SUPPLIER_OWNER:"Song Longchao",REQUESTER_OWNER:"Yang Zhitao",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:44,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-HQ-W1-L-0014",INTERFACE_DESCR:"Oil vapor Flange connection",SUPPLIER_OWNER:"Song Longchao",REQUESTER_OWNER:"Yang Zhitao",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:45,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-HQ-W1-L-0015",INTERFACE_DESCR:"Naphtha Flange connection",SUPPLIER_OWNER:"Song Longchao",REQUESTER_OWNER:"Yang Zhitao",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:46,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-HQ-W1-L-0016",INTERFACE_DESCR:"Purge Line Flange connection",SUPPLIER_OWNER:"Song Longchao",REQUESTER_OWNER:"Yang Zhitao",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:47,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-HQ-W1-L-0017",INTERFACE_DESCR:"Instrument Air Flange connection",SUPPLIER_OWNER:"Song Longchao",REQUESTER_OWNER:"Yang Zhitao",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:48,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-HQ-W1-L-0018",INTERFACE_DESCR:"Nitrogen Flange connection",SUPPLIER_OWNER:"Song Longchao",REQUESTER_OWNER:"Yang Zhitao",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:49,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-HQ-W1-L-0019",INTERFACE_DESCR:"Waste Water Flange connection",SUPPLIER_OWNER:"Song Longchao",REQUESTER_OWNER:"Yang Zhitao",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:50,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-HQ-W1-L-0020",INTERFACE_DESCR:"Sanitary Waste  Flange connection",SUPPLIER_OWNER:"Song Longchao",REQUESTER_OWNER:"Yang Zhitao",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:51,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-HQ-W1-L-0021",INTERFACE_DESCR:"Fire Water Flange connection",SUPPLIER_OWNER:"Song Longchao",REQUESTER_OWNER:"Yang Zhitao",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:52,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-HQ-W1-L-0022",INTERFACE_DESCR:"Fire Water Flange connection",SUPPLIER_OWNER:"Song Longchao",REQUESTER_OWNER:"Yang Zhitao",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:53,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-HQ-E2-I-0001",INTERFACE_DESCR:"FO cable size and DCS input requirement",SUPPLIER_OWNER:"Yang Zhitao",REQUESTER_OWNER:"CuiPeng",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:54,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-GC-L-0001",INTERFACE_DESCR:"SHP steam",SUPPLIER_OWNER:"GEG COGEN",REQUESTER_OWNER:"SEI",PRIORITY:"MEDIUM",STATUS:"Delivered"},
      {NO:55,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-GC-L-0002",INTERFACE_DESCR:"Tail gas",SUPPLIER_OWNER:"GEG COGEN",REQUESTER_OWNER:"SEI",PRIORITY:"LOW",STATUS:"Delivered"},
      {NO:56,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-GC-L-0003",INTERFACE_DESCR:"Condensate",SUPPLIER_OWNER:"GEG COGEN",REQUESTER_OWNER:"SEI",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:57,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-QY-L-0001",INTERFACE_DESCR:"Process WW",SUPPLIER_OWNER:"Qingyuan",REQUESTER_OWNER:"SEI",PRIORITY:"MEDIUM",STATUS:"Delivered"},
      {NO:58,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-QY-L-0002",INTERFACE_DESCR:"High salinity WWW",SUPPLIER_OWNER:"Qingyuan",REQUESTER_OWNER:"SEI",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:59,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-QY-L-0003",INTERFACE_DESCR:"Cooling water blowdown",SUPPLIER_OWNER:"Qingyuan",REQUESTER_OWNER:"SEI",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:60,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-QY-L-0004",INTERFACE_DESCR:"Return WW",SUPPLIER_OWNER:"Qingyuan",REQUESTER_OWNER:"SEI",PRIORITY:"LOW",STATUS:"Closed"},
      {NO:61,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-LD-L-0001",INTERFACE_DESCR:"HP N2",SUPPLIER_OWNER:"LINDE",REQUESTER_OWNER:"SEI",PRIORITY:"LOW",STATUS:"In Progress"},
      {NO:62,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-LD-L-0002",INTERFACE_DESCR:"LP N2",SUPPLIER_OWNER:"LINDE",REQUESTER_OWNER:"SEI",PRIORITY:"LOW",STATUS:"In Progress"},
      {NO:63,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-CS-L-0001",INTERFACE_DESCR:"H2",SUPPLIER_OWNER:"CSPC",REQUESTER_OWNER:"SEI",PRIORITY:"LOW",STATUS:"In Progress"},
      {NO:64,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-HR-L-0001",INTERFACE_DESCR:"Town Gas",SUPPLIER_OWNER:"CHINA GAS",REQUESTER_OWNER:"SEI",PRIORITY:"LOW",STATUS:"In Progress"},
      {NO:65,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-JV-L-0001",INTERFACE_DESCR:"LPG supply",SUPPLIER_OWNER:"JOVO",REQUESTER_OWNER:"SEI",PRIORITY:"LOW",STATUS:"In Progress"},
      {NO:66,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-GN-L-0001",INTERFACE_DESCR:"Nature Gas supply",SUPPLIER_OWNER:"GEG NG",REQUESTER_OWNER:"SEI",PRIORITY:"LOW",STATUS:"In Progress"},
      {NO:67,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-DG-L-0001",INTERFACE_DESCR:"Town Gas",SUPPLIER_OWNER:"DIP -China resourse",REQUESTER_OWNER:"SEI",PRIORITY:"LOW",STATUS:"In Progress"},
      {NO:68,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-DG-L-0002",INTERFACE_DESCR:"Clean storm water",SUPPLIER_OWNER:"DIP",REQUESTER_OWNER:"SEI",PRIORITY:"LOW",STATUS:"In Progress"},
      {NO:69,IG_NO:"IG-E2-001",INTERFACE_NO:"IP-E2-DG-L-0003",INTERFACE_DESCR:"Industrial water",SUPPLIER_OWNER:"DIP",REQUESTER_OWNER:"SEI",PRIORITY:"LOW",STATUS:"In Progress"}
 ],
}