
export default {
  Canvas: {
    CanvasWidth: 800,
    CanvasHeight: 300,
    CanvasBorderWeight: .5,
    CanvasBorderColor: '#E0E0DF',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 500,
    CanvasChartHeight: 400,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#000',
    CanvasChartOpacity: 1,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },

  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  DataItems: [
    { TOT: 426332, FAB_ACT: 419275, ERE_ACT: 221954, TEST_ACT: 70046, FLUSH_ACT: 61987, REIN_ACT: 10361, FAB_REM: 7057, ERE_REM: 204378, TEST_REM: 356286, FLUSH_REM: 364345, REIN_REM: 415971, ERE_4WA: 'TBC', ERE_3WA: 'TBC', ERE_2WA: 'TBC', ERE_1WA: 'TBC', ERE_TW: 'TBC', TEST_4WA: 5499, TEST_3WA: 4540, TEST_2WA: 7864, TEST_1WA: 1004, TEST_TW: 0, FLUSH_4WA: 5008, FLUSH_3WA: 3807, FLUSH_2WA: 6521, FLUSH_1WA: 2823, FLUSH_TW: 0, REIN_4WA: 91, REIN_3WA: 653, REIN_2WA: 282, REIN_1WA: 7025, REIN_TW: 0, },
  ],

  Queries: {

    SQL1: [
      {TITLE: 'Piping (Dia-inch)', COL_WIDTH: 240, CHILDREN: 'Total/Achieved/Remaining', DIVIDE: '', REF: 'TOT/ACT/REM', MERGE: 'Y/ / ', COLOR: '#333/#44A9DF/#F35E90', LINK: 'PIP'},
      {TITLE: 'Past Week Performance', COL_WIDTH: 240, CHILDREN: '-4/-3/-2/-1', DIVIDE: 'Y' ,REF: '4WA/3WA/2WA/1WA', MERGE: ' / / / ', COLOR: '#333/#333/#333/#333', LINK: 'PIP'},
      {TITLE: 'Current week', COL_WIDTH: 80, CHILDREN: '', DIVIDE: '', REF: 'TW', MERGE: '', COLOR: '#333', LINK: 'PIP'},
    ],

    SQL2: [
      {TITLE: 'Erected', COL: 'ERE',},
      {TITLE: 'Tested', COL: 'TEST',},
      {TITLE: 'Flushed', COL: 'FLUSH',},
      {TITLE: 'Reinstated', COL: 'REIN',},
    ],

    SQL3: [
      {
        TITLE_HEIGHT: 50,
        LINE_HEIGHT: 25,

        HEADER_FONT_SIZE: 13,
        BODY_FONT_SIZE: 11,

        CUTOFF: '2024-05-24',
      },
    ],

  },

}