import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 2000,
      CanvasHeight: 2000,
      CanvasBorderWeight: 0.3,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 310,
      CanvasChartHeight: 270,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: 'Transparent',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },

    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },

    DataItems: [
      // {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",WCCODE:"36210",NO:1,DISC_DESCR:"Structure",UOM:"TONS",STAGE:"2",STEP:"10",ITEM_SEQ:"1",WCCODE_DESCR:"Topsides Prefabrication",PLAN_START_WK:"W2020_02",PLAN_FINISH_WK:"W2021_28",PLAN_START_DATE:"2020-01-10",PLAN_FINISH_DATE:"2021-07-16",FORECAST_START_WK:"W2020_02",FORECAST_FINISH_WK:"W2021_28",FORECAST_START_DATE:"2020-01-10",FORECAST_FINISH_DATE:"2021-07-16",ACTUAL_START_WK:"W2020_13",ACTUAL_START_DATE:"2020-03-27",INITIALQTY:720.65,INITIALMANHOURS:44804,PLAN_VALUE:42716.1336,ACTUAL_VALUE:42639.9668,PLAN_PROG:95.34,ACTUAL_PROG:95.17,PLAN_QTY:687.1,ACTUAL_QTY:685.8,REMAIN_QTY:34.8,C2_QTY:698.1,EQV_QTY:688.9},
      // {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",WCCODE:"36220",NO:1,DISC_DESCR:"Structure",UOM:"TONS",STAGE:"2",STEP:"20",ITEM_SEQ:"2",WCCODE_DESCR:"Topsides Assembly/Erection",PLAN_START_WK:"W2020_32",PLAN_FINISH_WK:"W2021_41",PLAN_START_DATE:"2020-08-07",PLAN_FINISH_DATE:"2021-10-15",FORECAST_START_WK:"W2020_32",FORECAST_FINISH_WK:"W2021_41",FORECAST_START_DATE:"2020-08-07",FORECAST_FINISH_DATE:"2021-10-15",ACTUAL_START_WK:"W2020_31",ACTUAL_START_DATE:"2020-07-31",INITIALQTY:729.65,INITIALMANHOURS:71282,PLAN_VALUE:56319.9082,ACTUAL_VALUE:50581.7072,PLAN_PROG:79.01,ACTUAL_PROG:70.96,PLAN_QTY:576.5,ACTUAL_QTY:517.8,REMAIN_QTY:211.9,C2_QTY:704.9,EQV_QTY:669.5},
      // {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",WCCODE:"36230",NO:1,DISC_DESCR:"Structure",UOM:"TONS",STAGE:"2",STEP:"30",ITEM_SEQ:"3",WCCODE_DESCR:"Topsides Tertiary",PLAN_START_WK:"W2021_19",PLAN_FINISH_WK:"W2021_37",PLAN_START_DATE:"2021-05-14",PLAN_FINISH_DATE:"2021-09-17",FORECAST_START_WK:"W2021_19",FORECAST_FINISH_WK:"W2021_37",FORECAST_START_DATE:"2021-05-14",FORECAST_FINISH_DATE:"2021-09-17",ACTUAL_START_WK:"W2021_08",ACTUAL_START_DATE:"2021-02-26",INITIALQTY:1057.6,INITIALMANHOURS:10999,PLAN_VALUE:2608.9628,ACTUAL_VALUE:986.6103,PLAN_PROG:23.71,ACTUAL_PROG:8.97,PLAN_QTY:250.8,ACTUAL_QTY:94.9,REMAIN_QTY:962.7,C2_QTY:37.1,EQV_QTY:12.7},
      // {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",WCCODE:"13820",NO:2,DISC_DESCR:"Piping",UOM:"KG",STAGE:"8",STEP:"20",ITEM_SEQ:"1",WCCODE_DESCR:"Topsides Piping Support Installation",PLAN_START_WK:"W2021_05",PLAN_FINISH_WK:"W2021_30",PLAN_START_DATE:"2021-02-05",PLAN_FINISH_DATE:"2021-07-30",FORECAST_START_WK:"W2021_05",FORECAST_FINISH_WK:"W2021_30",FORECAST_START_DATE:"2021-02-05",FORECAST_FINISH_DATE:"2021-07-30",ACTUAL_START_WK:"W2021_15",ACTUAL_START_DATE:"2021-04-16",INITIALQTY:36548,INITIALMANHOURS:6416,PLAN_VALUE:5803.9136,ACTUAL_VALUE:1356.3424,PLAN_PROG:90.45,ACTUAL_PROG:21.14,PLAN_QTY:33057.7,ACTUAL_QTY:7726.2,REMAIN_QTY:28821.8,C2_QTY:37822.1,EQV_QTY:0},
      // {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",WCCODE:"13810",NO:2,DISC_DESCR:"Piping",UOM:"KG",STAGE:"8",STEP:"10",ITEM_SEQ:"1",WCCODE_DESCR:"Topsides Piping Support Fabrication",PLAN_START_WK:"W2021_01",PLAN_FINISH_WK:"W2021_21",PLAN_START_DATE:"2021-01-08",PLAN_FINISH_DATE:"2021-05-28",FORECAST_START_WK:"W2021_01",FORECAST_FINISH_WK:"W2021_21",FORECAST_START_DATE:"2021-01-08",FORECAST_FINISH_DATE:"2021-05-28",ACTUAL_START_WK:"W2021_01",ACTUAL_START_DATE:"2021-01-08",INITIALQTY:36548,INITIALMANHOURS:7333,PLAN_VALUE:7333,ACTUAL_VALUE:6278.5146,PLAN_PROG:100,ACTUAL_PROG:85.62,PLAN_QTY:36548,ACTUAL_QTY:31292.4,REMAIN_QTY:5255.6,C2_QTY:32565.8,EQV_QTY:31718},
      // {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",WCCODE:"13410",NO:2,DISC_DESCR:"Piping",UOM:"DIA",STAGE:"4",STEP:"10",ITEM_SEQ:"2",WCCODE_DESCR:"Topsides Piping Fabrication",PLAN_START_WK:"W2021_02",PLAN_FINISH_WK:"W2021_37",PLAN_START_DATE:"2021-01-15",PLAN_FINISH_DATE:"2021-09-17",FORECAST_START_WK:"W2021_02",FORECAST_FINISH_WK:"W2021_37",FORECAST_START_DATE:"2021-01-15",FORECAST_FINISH_DATE:"2021-09-17",ACTUAL_START_WK:"W2021_05",ACTUAL_START_DATE:"2021-02-05",INITIALQTY:21902,INITIALMANHOURS:62955,PLAN_VALUE:41399.208,ACTUAL_VALUE:30243.582,PLAN_PROG:65.75,ACTUAL_PROG:48.03,PLAN_QTY:14400.6,ACTUAL_QTY:10519.5,REMAIN_QTY:11382.5,C2_QTY:21812.5,EQV_QTY:11272.5},
      // {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",WCCODE:"13420",NO:2,DISC_DESCR:"Piping",UOM:"DIA",STAGE:"4",STEP:"20",ITEM_SEQ:"2",WCCODE_DESCR:"Topsides Piping Installation",PLAN_START_WK:"W2021_10",PLAN_FINISH_WK:"W2022_02",PLAN_START_DATE:"2021-03-12",PLAN_FINISH_DATE:"2022-01-14",FORECAST_START_WK:"W2021_10",FORECAST_FINISH_WK:"W2022_02",FORECAST_START_DATE:"2021-03-12",FORECAST_FINISH_DATE:"2022-01-14",ACTUAL_START_WK:"W2021_13",ACTUAL_START_DATE:"2021-04-02",INITIALQTY:5635,INITIALMANHOURS:48396,PLAN_VALUE:12858.8172,ACTUAL_VALUE:614.6292,PLAN_PROG:26.57,ACTUAL_PROG:1.27,PLAN_QTY:1497.2,ACTUAL_QTY:71.6,REMAIN_QTY:5563.4,C2_QTY:6477.7,EQV_QTY:152.5},
      // {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",WCCODE:"13900",NO:2,DISC_DESCR:"Piping",UOM:"U",STAGE:"9",STEP:"00",ITEM_SEQ:"3",WCCODE_DESCR:"Pressure Test",PLAN_START_WK:"W2021_19",PLAN_FINISH_WK:"W2022_10",PLAN_START_DATE:"2021-05-14",PLAN_FINISH_DATE:"2022-03-11",FORECAST_START_WK:"W2021_19",FORECAST_FINISH_WK:"W2022_10",FORECAST_START_DATE:"2021-05-14",FORECAST_FINISH_DATE:"2022-03-11",INITIALQTY:691,INITIALMANHOURS:9708,PLAN_VALUE:258.2328,ACTUAL_VALUE:0,PLAN_PROG:2.65,ACTUAL_PROG:0,PLAN_QTY:18.3,ACTUAL_QTY:0,REMAIN_QTY:691,C2_QTY:7,EQV_QTY:0},
      // {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",WCCODE:"16340",NO:3,DISC_DESCR:"Electrical",UOM:"U",STAGE:"3",STEP:"40",WCCODE_DESCR:"Power System Components - ( Panels, Junction Boxes)",PLAN_START_WK:"W2021_16",PLAN_FINISH_WK:"W2021_34",PLAN_START_DATE:"2021-04-23",PLAN_FINISH_DATE:"2021-08-27",FORECAST_START_WK:"W2021_16",FORECAST_FINISH_WK:"W2021_34",FORECAST_START_DATE:"2021-04-23",FORECAST_FINISH_DATE:"2021-08-27",INITIALQTY:398,INITIALMANHOURS:6000,PLAN_VALUE:2526.6,ACTUAL_VALUE:0,PLAN_PROG:42.1,ACTUAL_PROG:0,PLAN_QTY:167.6,ACTUAL_QTY:0,REMAIN_QTY:398,C2_QTY:313,EQV_QTY:0},
      // {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",WCCODE:"16100",NO:3,DISC_DESCR:"Electrical",UOM:"U",STAGE:"1",STEP:"00",WCCODE_DESCR:"Grounding And Lightning System",PLAN_START_WK:"W2021_22",PLAN_FINISH_WK:"W2021_38",PLAN_START_DATE:"2021-06-04",PLAN_FINISH_DATE:"2021-09-24",FORECAST_START_WK:"W2021_22",FORECAST_FINISH_WK:"W2021_38",FORECAST_START_DATE:"2021-06-04",FORECAST_FINISH_DATE:"2021-09-24",INITIALQTY:5966,INITIALMANHOURS:3603,PLAN_VALUE:9.7281,ACTUAL_VALUE:0,PLAN_PROG:0.26,ACTUAL_PROG:0,PLAN_QTY:15.5,ACTUAL_QTY:0,REMAIN_QTY:5966,C2_QTY:5,EQV_QTY:0},
      // {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",WCCODE:"16800",NO:3,DISC_DESCR:"Electrical",UOM:"U",STAGE:"8",STEP:"00",WCCODE_DESCR:"Lighting System",PLAN_START_WK:"W2021_20",PLAN_FINISH_WK:"W2021_38",PLAN_START_DATE:"2021-05-21",PLAN_FINISH_DATE:"2021-09-24",FORECAST_START_WK:"W2021_20",FORECAST_FINISH_WK:"W2021_38",FORECAST_START_DATE:"2021-05-21",FORECAST_FINISH_DATE:"2021-09-24",INITIALQTY:8122,INITIALMANHOURS:9164,PLAN_VALUE:32.074,ACTUAL_VALUE:0,PLAN_PROG:0.34,ACTUAL_PROG:0,PLAN_QTY:27.6,ACTUAL_QTY:0,REMAIN_QTY:8122,C2_QTY:21,EQV_QTY:0},
      // {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",WCCODE:"16500",NO:3,DISC_DESCR:"Electrical",UOM:"U",STAGE:"5",STEP:"00",WCCODE_DESCR:"Electrical Heat Tracing System",PLAN_START_WK:"W2021_20",PLAN_FINISH_WK:"W2022_11",PLAN_START_DATE:"2021-05-21",PLAN_FINISH_DATE:"2022-03-18",FORECAST_START_WK:"W2021_20",FORECAST_FINISH_WK:"W2022_11",FORECAST_START_DATE:"2021-05-21",FORECAST_FINISH_DATE:"2022-03-18",INITIALQTY:17875,INITIALMANHOURS:6661,PLAN_VALUE:245.1248,ACTUAL_VALUE:0,PLAN_PROG:3.67,ACTUAL_PROG:0,PLAN_QTY:656,ACTUAL_QTY:0,REMAIN_QTY:17875,C2_QTY:58,EQV_QTY:0},
      // {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",WCCODE:"16460",NO:3,DISC_DESCR:"Electrical",UOM:"KG",STAGE:"4",STEP:"60",ITEM_SEQ:"1",WCCODE_DESCR:"Eict Mega Block Outfittings Installation",PLAN_START_WK:"W2021_13",PLAN_FINISH_WK:"W2021_37",PLAN_START_DATE:"2021-04-02",PLAN_FINISH_DATE:"2021-09-17",FORECAST_START_WK:"W2021_13",FORECAST_FINISH_WK:"W2021_37",FORECAST_START_DATE:"2021-04-02",FORECAST_FINISH_DATE:"2021-09-17",ACTUAL_START_WK:"W2021_23",ACTUAL_START_DATE:"2021-06-11",INITIALQTY:17850,INITIALMANHOURS:2421,PLAN_VALUE:1644.3432,ACTUAL_VALUE:65.367,PLAN_PROG:67.92,ACTUAL_PROG:2.7,PLAN_QTY:12123.7,ACTUAL_QTY:481.9,REMAIN_QTY:17368,C2_QTY:17850,EQV_QTY:910.3},
      // {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",WCCODE:"16450",NO:3,DISC_DESCR:"Electrical",UOM:"KG",STAGE:"4",STEP:"50",ITEM_SEQ:"1",WCCODE_DESCR:"Eict Mega Block Outfittings Fabrication",PLAN_START_WK:"W2021_08",PLAN_FINISH_WK:"W2021_33",PLAN_START_DATE:"2021-02-26",PLAN_FINISH_DATE:"2021-08-20",FORECAST_START_WK:"W2021_08",FORECAST_FINISH_WK:"W2021_33",FORECAST_START_DATE:"2021-02-26",FORECAST_FINISH_DATE:"2021-08-20",ACTUAL_START_WK:"W2021_15",ACTUAL_START_DATE:"2021-04-16",INITIALQTY:17850,INITIALMANHOURS:1003,PLAN_VALUE:953.9533,ACTUAL_VALUE:805.7099,PLAN_PROG:95.1,ACTUAL_PROG:80.32,PLAN_QTY:16975.3,ACTUAL_QTY:14337.1,REMAIN_QTY:3512.9,C2_QTY:17850,EQV_QTY:14339.1},
      // {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",WCCODE:"16430",NO:3,DISC_DESCR:"Electrical",UOM:"LM",STAGE:"4",STEP:"30",ITEM_SEQ:"2",WCCODE_DESCR:"Eict Area Outfittings Installation",PLAN_START_WK:"W2021_22",PLAN_FINISH_WK:"W2021_33",PLAN_START_DATE:"2021-06-04",PLAN_FINISH_DATE:"2021-08-20",FORECAST_START_WK:"W2021_22",FORECAST_FINISH_WK:"W2021_33",FORECAST_START_DATE:"2021-06-04",FORECAST_FINISH_DATE:"2021-08-20",INITIALQTY:4013,INITIALMANHOURS:4013,PLAN_VALUE:290.5412,ACTUAL_VALUE:0,PLAN_PROG:7.24,ACTUAL_PROG:0,PLAN_QTY:290.5,ACTUAL_QTY:0,REMAIN_QTY:4013,C2_QTY:4013,EQV_QTY:0},
      // {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",WCCODE:"16320",NO:3,DISC_DESCR:"Electrical",UOM:"LM",STAGE:"3",STEP:"20",ITEM_SEQ:"3",WCCODE_DESCR:"Power System - Cables",PLAN_START_WK:"W2021_25",PLAN_FINISH_WK:"W2021_35",PLAN_START_DATE:"2021-06-25",PLAN_FINISH_DATE:"2021-09-03",FORECAST_START_WK:"W2021_25",FORECAST_FINISH_WK:"W2021_35",FORECAST_START_DATE:"2021-06-25",FORECAST_FINISH_DATE:"2021-09-03",INITIALQTY:219,INITIALMANHOURS:66,PLAN_VALUE:0,ACTUAL_VALUE:0,PLAN_PROG:0,ACTUAL_PROG:0,PLAN_QTY:0,ACTUAL_QTY:0,REMAIN_QTY:219,C2_QTY:1450,EQV_QTY:0},
      // {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",WCCODE:"16330",NO:3,DISC_DESCR:"Electrical",UOM:"U",STAGE:"3",STEP:"30",ITEM_SEQ:"4",WCCODE_DESCR:"Power System - Cables Connections",PLAN_START_WK:"W2021_31",PLAN_FINISH_WK:"W2021_38",PLAN_START_DATE:"2021-08-06",PLAN_FINISH_DATE:"2021-09-24",FORECAST_START_WK:"W2021_31",FORECAST_FINISH_WK:"W2021_38",FORECAST_START_DATE:"2021-08-06",FORECAST_FINISH_DATE:"2021-09-24",INITIALQTY:176,INITIALMANHOURS:263,PLAN_VALUE:0,ACTUAL_VALUE:0,PLAN_PROG:0,ACTUAL_PROG:0,PLAN_QTY:0,ACTUAL_QTY:0,REMAIN_QTY:176,C2_QTY:38,EQV_QTY:0},
      // {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",WCCODE:"15600",NO:4,DISC_DESCR:"Instrument",UOM:"U",STAGE:"6",STEP:"00",WCCODE_DESCR:"Analyzers Installation",PLAN_START_WK:"W2021_28",PLAN_FINISH_WK:"W2021_32",PLAN_START_DATE:"2021-07-16",PLAN_FINISH_DATE:"2021-08-13",FORECAST_START_WK:"W2021_28",FORECAST_FINISH_WK:"W2021_32",FORECAST_START_DATE:"2021-07-16",FORECAST_FINISH_DATE:"2021-08-13",INITIALQTY:6,INITIALMANHOURS:24,PLAN_VALUE:0,ACTUAL_VALUE:0,PLAN_PROG:0,ACTUAL_PROG:0,PLAN_QTY:0,ACTUAL_QTY:0,REMAIN_QTY:6,C2_QTY:4,EQV_QTY:0},
      // {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",WCCODE:"15700",NO:4,DISC_DESCR:"Instrument",UOM:"U",STAGE:"7",STEP:"00",WCCODE_DESCR:"Telecommunication Systems Installation Works",PLAN_START_WK:"W2021_29",PLAN_FINISH_WK:"W2021_32",PLAN_START_DATE:"2021-07-23",PLAN_FINISH_DATE:"2021-08-13",FORECAST_START_WK:"W2021_29",FORECAST_FINISH_WK:"W2021_32",FORECAST_START_DATE:"2021-07-23",FORECAST_FINISH_DATE:"2021-08-13",INITIALQTY:70,INITIALMANHOURS:70,PLAN_VALUE:0,ACTUAL_VALUE:0,PLAN_PROG:0,ACTUAL_PROG:0,PLAN_QTY:0,ACTUAL_QTY:0,REMAIN_QTY:70,C2_QTY:92,EQV_QTY:0},
      // {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",WCCODE:"15220",NO:4,DISC_DESCR:"Instrument",UOM:"LM",STAGE:"2",STEP:"20",ITEM_SEQ:"2",WCCODE_DESCR:"Instrument Cables",PLAN_START_WK:"W2021_29",PLAN_FINISH_WK:"W2021_36",PLAN_START_DATE:"2021-07-23",PLAN_FINISH_DATE:"2021-09-10",FORECAST_START_WK:"W2021_29",FORECAST_FINISH_WK:"W2021_36",FORECAST_START_DATE:"2021-07-23",FORECAST_FINISH_DATE:"2021-09-10",INITIALQTY:12775,INITIALMANHOURS:3833,PLAN_VALUE:0,ACTUAL_VALUE:0,PLAN_PROG:0,ACTUAL_PROG:0,PLAN_QTY:0,ACTUAL_QTY:0,REMAIN_QTY:12775,C2_QTY:27538,EQV_QTY:0},
      // {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",WCCODE:"15230",NO:4,DISC_DESCR:"Instrument",UOM:"U",STAGE:"2",STEP:"30",ITEM_SEQ:"3",WCCODE_DESCR:"Instrument Cables - Connections",PLAN_START_WK:"W2021_30",PLAN_FINISH_WK:"W2021_38",PLAN_START_DATE:"2021-07-30",PLAN_FINISH_DATE:"2021-09-24",FORECAST_START_WK:"W2021_30",FORECAST_FINISH_WK:"W2021_38",FORECAST_START_DATE:"2021-07-30",FORECAST_FINISH_DATE:"2021-09-24",INITIALQTY:10973,INITIALMANHOURS:12071,PLAN_VALUE:0,ACTUAL_VALUE:0,PLAN_PROG:0,ACTUAL_PROG:0,PLAN_QTY:0,ACTUAL_QTY:0,REMAIN_QTY:10973,C2_QTY:472,EQV_QTY:0},
      // {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",WCCODE:"15350",NO:4,DISC_DESCR:"Instrument",UOM:"U",STAGE:"3",STEP:"50",ITEM_SEQ:"4",WCCODE_DESCR:"Field On-Line Instrumentation Works",PLAN_START_WK:"W2021_16",PLAN_FINISH_WK:"W2022_10",PLAN_START_DATE:"2021-04-23",PLAN_FINISH_DATE:"2022-03-11",FORECAST_START_WK:"W2021_16",FORECAST_FINISH_WK:"W2022_10",FORECAST_START_DATE:"2021-04-23",FORECAST_FINISH_DATE:"2022-03-11",INITIALQTY:2405,INITIALMANHOURS:1180,PLAN_VALUE:25.016,ACTUAL_VALUE:0,PLAN_PROG:2.12,ACTUAL_PROG:0,PLAN_QTY:51,ACTUAL_QTY:0,REMAIN_QTY:2405,C2_QTY:166,EQV_QTY:3},
      // {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",WCCODE:"15310",NO:4,DISC_DESCR:"Instrument",UOM:"U",STAGE:"3",STEP:"10",ITEM_SEQ:"4",WCCODE_DESCR:"Field Instrumentation Works",PLAN_START_WK:"W2021_27",PLAN_FINISH_WK:"W2022_10",PLAN_START_DATE:"2021-07-09",PLAN_FINISH_DATE:"2022-03-11",FORECAST_START_WK:"W2021_27",FORECAST_FINISH_WK:"W2022_10",FORECAST_START_DATE:"2021-07-09",FORECAST_FINISH_DATE:"2022-03-11",ACTUAL_START_WK:"W2021_16",ACTUAL_START_DATE:"2021-04-23",INITIALQTY:707,INITIALMANHOURS:838,PLAN_VALUE:0,ACTUAL_VALUE:0.6704,PLAN_PROG:0,ACTUAL_PROG:0.08,PLAN_QTY:0,ACTUAL_QTY:0.6,REMAIN_QTY:706.4,C2_QTY:135,EQV_QTY:1},
      // {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",WCCODE:"15240",NO:4,DISC_DESCR:"Instrument",UOM:"U",STAGE:"2",STEP:"40",ITEM_SEQ:"5",WCCODE_DESCR:"Junction Box And Loc. Panels Installation",PLAN_START_WK:"W2021_25",PLAN_FINISH_WK:"W2021_32",PLAN_START_DATE:"2021-06-25",PLAN_FINISH_DATE:"2021-08-13",FORECAST_START_WK:"W2021_25",FORECAST_FINISH_WK:"W2021_32",FORECAST_START_DATE:"2021-06-25",FORECAST_FINISH_DATE:"2021-08-13",INITIALQTY:27,INITIALMANHOURS:324,PLAN_VALUE:0,ACTUAL_VALUE:0,PLAN_PROG:0,ACTUAL_PROG:0,PLAN_QTY:0,ACTUAL_QTY:0,REMAIN_QTY:27,C2_QTY:80,EQV_QTY:0},
      // {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",WCCODE:"68110",NO:7,DISC_DESCR:"Mechanical",UOM:"U",STAGE:"1",STEP:"10",WCCODE_DESCR:"Heavy Lifts In One Piece",PLAN_START_WK:"W2021_13",PLAN_FINISH_WK:"W2021_22",PLAN_START_DATE:"2021-04-02",PLAN_FINISH_DATE:"2021-06-04",FORECAST_START_WK:"W2021_13",FORECAST_FINISH_WK:"W2021_22",FORECAST_START_DATE:"2021-04-02",FORECAST_FINISH_DATE:"2021-06-04",ACTUAL_START_WK:"W2021_20",ACTUAL_FINISH_WK:"W2021_23",ACTUAL_START_DATE:"2021-05-21",ACTUAL_FINISH_DATE:"2021-06-11",INITIALQTY:2,INITIALMANHOURS:1200,PLAN_VALUE:1200,ACTUAL_VALUE:1200,PLAN_PROG:100,ACTUAL_PROG:100,PLAN_QTY:2,ACTUAL_QTY:2,REMAIN_QTY:0,C2_QTY:2,EQV_QTY:2},
      // {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",WCCODE:"68610",NO:7,DISC_DESCR:"Mechanical",UOM:"U",STAGE:"6",STEP:"10",WCCODE_DESCR:"Trays & Internals Installation",PLAN_START_WK:"W2021_24",PLAN_FINISH_WK:"W2021_33",PLAN_START_DATE:"2021-06-18",PLAN_FINISH_DATE:"2021-08-20",FORECAST_START_WK:"W2021_24",FORECAST_FINISH_WK:"W2021_33",FORECAST_START_DATE:"2021-06-18",FORECAST_FINISH_DATE:"2021-08-20",INITIALQTY:2,INITIALMANHOURS:350,PLAN_VALUE:17.185,ACTUAL_VALUE:0,PLAN_PROG:4.91,ACTUAL_PROG:0,PLAN_QTY:0.1,ACTUAL_QTY:0,REMAIN_QTY:2},
      // {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",WCCODE:"68210",NO:7,DISC_DESCR:"Mechanical",UOM:"U",STAGE:"2",STEP:"10",ITEM_SEQ:"1",WCCODE_DESCR:"Vertical And Horizontal Vessels/Exchangers",PLAN_START_WK:"W2021_15",PLAN_FINISH_WK:"W2021_29",PLAN_START_DATE:"2021-04-16",PLAN_FINISH_DATE:"2021-07-23",FORECAST_START_WK:"W2021_15",FORECAST_FINISH_WK:"W2021_29",FORECAST_START_DATE:"2021-04-16",FORECAST_FINISH_DATE:"2021-07-23",ACTUAL_START_WK:"W2021_14",ACTUAL_START_DATE:"2021-04-09",INITIALQTY:9,INITIALMANHOURS:3600,PLAN_VALUE:3061.08,ACTUAL_VALUE:1699.92,PLAN_PROG:85.03,ACTUAL_PROG:47.21,PLAN_QTY:7.7,ACTUAL_QTY:4.2,REMAIN_QTY:4.8,C2_QTY:10,EQV_QTY:7.600000000000001},
      // {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",WCCODE:"68810",NO:7,DISC_DESCR:"Mechanical",UOM:"U",STAGE:"8",STEP:"10",ITEM_SEQ:"3",WCCODE_DESCR:"Skid, Packages & Misc.",PLAN_START_WK:"W2021_15",PLAN_FINISH_WK:"W2021_25",PLAN_START_DATE:"2021-04-16",PLAN_FINISH_DATE:"2021-06-25",FORECAST_START_WK:"W2021_15",FORECAST_FINISH_WK:"W2021_25",FORECAST_START_DATE:"2021-04-16",FORECAST_FINISH_DATE:"2021-06-25",ACTUAL_START_WK:"W2021_14",ACTUAL_START_DATE:"2021-04-09",INITIALQTY:6,INITIALMANHOURS:4000,PLAN_VALUE:3877.2,ACTUAL_VALUE:1880,PLAN_PROG:96.92,ACTUAL_PROG:47,PLAN_QTY:5.8,ACTUAL_QTY:2.8,REMAIN_QTY:3.2,C2_QTY:7,EQV_QTY:5.7},
      // {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",WCCODE:"23120",NO:8,DISC_DESCR:"Painting",UOM:"SQM",STAGE:"1",STEP:"20",ITEM_SEQ:"1",WCCODE_DESCR:"Area Painting",PLAN_START_WK:"W2021_23",PLAN_FINISH_WK:"W2022_12",PLAN_START_DATE:"2021-06-11",PLAN_FINISH_DATE:"2022-03-25",FORECAST_START_WK:"W2021_23",FORECAST_FINISH_WK:"W2022_12",FORECAST_START_DATE:"2021-06-11",FORECAST_FINISH_DATE:"2022-03-25",ACTUAL_START_WK:"W2021_24",ACTUAL_START_DATE:"2021-06-18",INITIALQTY:762.3,INITIALMANHOURS:6860,PLAN_VALUE:395.136,ACTUAL_VALUE:68.6,PLAN_PROG:5.76,ACTUAL_PROG:0.99,PLAN_QTY:43.9,ACTUAL_QTY:7.5,REMAIN_QTY:754.8,C2_QTY:762.2,EQV_QTY:15.2},
      // {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",WCCODE:"23110",NO:8,DISC_DESCR:"Painting",UOM:"SQM",STAGE:"1",STEP:"10",ITEM_SEQ:"1",WCCODE_DESCR:"Steel Struct. Painting",PLAN_START_WK:"W2021_03",PLAN_FINISH_WK:"W2021_35",PLAN_START_DATE:"2021-01-22",PLAN_FINISH_DATE:"2021-09-03",FORECAST_START_WK:"W2021_03",FORECAST_FINISH_WK:"W2021_35",FORECAST_START_DATE:"2021-01-22",FORECAST_FINISH_DATE:"2021-09-03",ACTUAL_START_WK:"W2020_48",ACTUAL_START_DATE:"2020-11-27",INITIALQTY:15923.52,INITIALMANHOURS:23598,PLAN_VALUE:18073.7082,ACTUAL_VALUE:20539.6992,PLAN_PROG:76.59,ACTUAL_PROG:87.03,PLAN_QTY:12195.8,ACTUAL_QTY:13858.2,REMAIN_QTY:2065.3,C2_QTY:15554.3,EQV_QTY:13976.3},
      // {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",WCCODE:"23210",NO:8,DISC_DESCR:"Painting",UOM:"SQM",STAGE:"2",STEP:"10",ITEM_SEQ:"2",WCCODE_DESCR:"Piping Support Painting",PLAN_START_WK:"W2021_03",PLAN_FINISH_WK:"W2021_35",PLAN_START_DATE:"2021-01-22",PLAN_FINISH_DATE:"2021-09-03",FORECAST_START_WK:"W2021_03",FORECAST_FINISH_WK:"W2021_35",FORECAST_START_DATE:"2021-01-22",FORECAST_FINISH_DATE:"2021-09-03",ACTUAL_START_WK:"W2021_13",ACTUAL_START_DATE:"2021-04-02",INITIALQTY:2225.7,INITIALMANHOURS:6677,PLAN_VALUE:6536.783,ACTUAL_VALUE:2869.7746,PLAN_PROG:97.9,ACTUAL_PROG:42.98,PLAN_QTY:2179,ACTUAL_QTY:956.6,REMAIN_QTY:1269.1,C2_QTY:2192,EQV_QTY:1569.5},
      // {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",WCCODE:"23200",NO:8,DISC_DESCR:"Painting",UOM:"SQM",STAGE:"2",STEP:"00",ITEM_SEQ:"2",WCCODE_DESCR:"Piping Painting",PLAN_START_WK:"W2021_08",PLAN_FINISH_WK:"W2021_39",PLAN_START_DATE:"2021-02-26",PLAN_FINISH_DATE:"2021-10-01",FORECAST_START_WK:"W2021_08",FORECAST_FINISH_WK:"W2021_39",FORECAST_START_DATE:"2021-02-26",FORECAST_FINISH_DATE:"2021-10-01",ACTUAL_START_WK:"W2021_16",ACTUAL_START_DATE:"2021-04-23",INITIALQTY:3046.9,INITIALMANHOURS:9141,PLAN_VALUE:5344.7427,ACTUAL_VALUE:1346.4693,PLAN_PROG:58.46,ACTUAL_PROG:14.72,PLAN_QTY:1781.2,ACTUAL_QTY:448.5,REMAIN_QTY:2598.4,C2_QTY:1628.9,EQV_QTY:568.7},
      // {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",WCCODE:"23500",NO:8,DISC_DESCR:"Painting",UOM:"SQM",STAGE:"5",STEP:"00",ITEM_SEQ:"3",WCCODE_DESCR:"Passive Fire Protection",PLAN_START_WK:"W2021_04",PLAN_FINISH_WK:"W2021_18",PLAN_START_DATE:"2021-01-29",PLAN_FINISH_DATE:"2021-05-07",FORECAST_START_WK:"W2021_04",FORECAST_FINISH_WK:"W2021_18",FORECAST_START_DATE:"2021-01-29",FORECAST_FINISH_DATE:"2021-05-07",INITIALQTY:1576,INITIALMANHOURS:14184,PLAN_VALUE:14184,ACTUAL_VALUE:0,PLAN_PROG:100,ACTUAL_PROG:0,PLAN_QTY:1576,ACTUAL_QTY:0,REMAIN_QTY:1576,C2_QTY:54.7,EQV_QTY:0},
      // {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",WCCODE:"22110",NO:9,DISC_DESCR:"Insulation",UOM:"SQM",STAGE:"1",STEP:"10",ITEM_SEQ:"1",WCCODE_DESCR:"Equipment Insulation Works",PLAN_START_WK:"W2021_31",PLAN_FINISH_WK:"W2021_39",PLAN_START_DATE:"2021-08-06",PLAN_FINISH_DATE:"2021-10-01",FORECAST_START_WK:"W2021_31",FORECAST_FINISH_WK:"W2021_39",FORECAST_START_DATE:"2021-08-06",FORECAST_FINISH_DATE:"2021-10-01",INITIALQTY:314,INITIALMANHOURS:1509,PLAN_VALUE:0,ACTUAL_VALUE:0,PLAN_PROG:0,ACTUAL_PROG:0,PLAN_QTY:0,ACTUAL_QTY:0,REMAIN_QTY:314,C2_QTY:9,EQV_QTY:0},
      // {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",WCCODE:"22210",NO:9,DISC_DESCR:"Insulation",UOM:"SQM",STAGE:"2",STEP:"10",ITEM_SEQ:"2",WCCODE_DESCR:"Piping Insulation Works",PLAN_START_WK:"W2022_02",PLAN_FINISH_WK:"W2022_11",PLAN_START_DATE:"2022-01-14",PLAN_FINISH_DATE:"2022-03-18",FORECAST_START_WK:"W2022_02",FORECAST_FINISH_WK:"W2022_11",FORECAST_START_DATE:"2022-01-14",FORECAST_FINISH_DATE:"2022-03-18",INITIALQTY:3010.2,INITIALMANHOURS:15245,PLAN_VALUE:0,ACTUAL_VALUE:0,PLAN_PROG:0,ACTUAL_PROG:0,PLAN_QTY:0,ACTUAL_QTY:0,REMAIN_QTY:3010.2},
      // {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",WCCODE:"61100",NO:10,DISC_DESCR:"Fire & Safety",UOM:"U",STAGE:"1",STEP:"00",ITEM_SEQ:"1",WCCODE_DESCR:"Fire Protection System",PLAN_START_WK:"W2021_28",PLAN_FINISH_WK:"W2022_17",PLAN_START_DATE:"2021-07-16",PLAN_FINISH_DATE:"2022-04-29",FORECAST_START_WK:"W2021_28",FORECAST_FINISH_WK:"W2022_17",FORECAST_START_DATE:"2021-07-16",FORECAST_FINISH_DATE:"2022-04-29",INITIALQTY:18.31,INITIALMANHOURS:240,PLAN_VALUE:0,ACTUAL_VALUE:0,PLAN_PROG:0,ACTUAL_PROG:0,PLAN_QTY:0,ACTUAL_QTY:0,REMAIN_QTY:18.3,C2_QTY:18,EQV_QTY:0},
      // {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",WCCODE:"61200",NO:10,DISC_DESCR:"Fire & Safety",UOM:"U",STAGE:"2",STEP:"00",ITEM_SEQ:"2",WCCODE_DESCR:"Safety Equipment",PLAN_START_WK:"W2022_11",PLAN_FINISH_WK:"W2022_14",PLAN_START_DATE:"2022-03-18",PLAN_FINISH_DATE:"2022-04-08",FORECAST_START_WK:"W2022_11",FORECAST_FINISH_WK:"W2022_14",FORECAST_START_DATE:"2022-03-18",FORECAST_FINISH_DATE:"2022-04-08",INITIALQTY:93,INITIALMANHOURS:327,PLAN_VALUE:0,ACTUAL_VALUE:0,PLAN_PROG:0,ACTUAL_PROG:0,PLAN_QTY:0,ACTUAL_QTY:0,REMAIN_QTY:93,C2_QTY:70,EQV_QTY:0}
      {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES COMMON",GWBS_LV3:"KO01",GWBSCODE:"KO01",MODULE_DESCR:"KO01-Flare KO Drum",WCCODE:"15240",NO:4,DISC_DESCR:"Instrument",UOM:"U",STAGE:"2",STEP:"40",ITEM_SEQ:"5",WCCODE_DESCR:"Junction Box And Loc. Panels Installation",PLAN_START_WK:"W2021_34",PLAN_FINISH_WK:"W2021_39",PLAN_START_DATE:"2021-08-27",PLAN_FINISH_DATE:"2021-10-01",FORECAST_START_WK:"W2021_34",FORECAST_FINISH_WK:"W2021_39",FORECAST_START_DATE:"2021-08-27",FORECAST_FINISH_DATE:"2021-10-01",INITIALQTY:2,INITIALMANHOURS:24,PLAN_VALUE:0,ACTUAL_VALUE:0,PLAN_VALUE_PRE:0,ACTUAL_VALUE_PRE:0,PLAN_PROG:0,ACTUAL_PROG:0,PLAN_PROG_WK:0,ACTUAL_PROG_WK:0,PLAN_QTY:0,ACTUAL_QTY:0,PLAN_QTY_WK:0,ACTUAL_QTY_WK:0,REMAIN_QTY:2,C2_QTY:3,EQV_QTY:0},
      {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",MODULE_DESCR:"ME01-Flash Gas Comp.",WCCODE:"15240",NO:4,DISC_DESCR:"Instrument",UOM:"U",STAGE:"2",STEP:"40",ITEM_SEQ:"5",WCCODE_DESCR:"Junction Box And Loc. Panels Installation",PLAN_START_WK:"W2021_25",PLAN_FINISH_WK:"W2021_32",PLAN_START_DATE:"2021-06-25",PLAN_FINISH_DATE:"2021-08-13",FORECAST_START_WK:"W2021_25",FORECAST_FINISH_WK:"W2021_32",FORECAST_START_DATE:"2021-06-25",FORECAST_FINISH_DATE:"2021-08-13",ACTUAL_START_WK:"W2021_31",ACTUAL_START_DATE:"2021-08-06",INITIALQTY:27,INITIALMANHOURS:324,PLAN_VALUE:288.0036,ACTUAL_VALUE:11.988,PLAN_VALUE_PRE:244.8144,ACTUAL_VALUE_PRE:0,PLAN_PROG:88.89,ACTUAL_PROG:3.69,PLAN_PROG_WK:13.32,ACTUAL_PROG_WK:3.68,PLAN_QTY:24,ACTUAL_QTY:1,PLAN_QTY_WK:3.6,ACTUAL_QTY_WK:1,REMAIN_QTY:26,C2_QTY:83,EQV_QTY:1},
      {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME04",GWBSCODE:"ME04",MODULE_DESCR:"ME04-Power Gen.",WCCODE:"15240",NO:4,DISC_DESCR:"Instrument",UOM:"U",STAGE:"2",STEP:"40",ITEM_SEQ:"5",WCCODE_DESCR:"Junction Box And Loc. Panels Installation",PLAN_START_WK:"W2021_18",PLAN_FINISH_WK:"W2021_25",PLAN_START_DATE:"2021-05-07",PLAN_FINISH_DATE:"2021-06-25",FORECAST_START_WK:"W2021_18",FORECAST_FINISH_WK:"W2021_25",FORECAST_START_DATE:"2021-05-07",FORECAST_FINISH_DATE:"2021-06-25",INITIALQTY:14,INITIALMANHOURS:168,PLAN_VALUE:168,ACTUAL_VALUE:0,PLAN_VALUE_PRE:168,ACTUAL_VALUE_PRE:0,PLAN_PROG:100,ACTUAL_PROG:0,PLAN_PROG_WK:0,ACTUAL_PROG_WK:0,PLAN_QTY:14,ACTUAL_QTY:0,PLAN_QTY_WK:0,ACTUAL_QTY_WK:0,REMAIN_QTY:14,C2_QTY:33,EQV_QTY:0},
      {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",MODULE_DESCR:"MW01-Recpt. Facil. & Prod. Sepn.",WCCODE:"15240",NO:4,DISC_DESCR:"Instrument",UOM:"U",STAGE:"2",STEP:"40",ITEM_SEQ:"5",WCCODE_DESCR:"Junction Box And Loc. Panels Installation",PLAN_START_WK:"W2021_24",PLAN_FINISH_WK:"W2021_32",PLAN_START_DATE:"2021-06-18",PLAN_FINISH_DATE:"2021-08-13",FORECAST_START_WK:"W2021_24",FORECAST_FINISH_WK:"W2021_32",FORECAST_START_DATE:"2021-06-18",FORECAST_FINISH_DATE:"2021-08-13",INITIALQTY:42,INITIALMANHOURS:504,PLAN_VALUE:473.8104,ACTUAL_VALUE:0,PLAN_VALUE_PRE:413.28,ACTUAL_VALUE_PRE:0,PLAN_PROG:94.01,ACTUAL_PROG:0,PLAN_PROG_WK:12.01,ACTUAL_PROG_WK:0,PLAN_QTY:39.5,ACTUAL_QTY:0,PLAN_QTY_WK:5,ACTUAL_QTY_WK:0,REMAIN_QTY:42,C2_QTY:119,EQV_QTY:0},
      {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBSCODE:"MW02",MODULE_DESCR:"MW02-HC Dewpt. & Conden. Stabil.",WCCODE:"15240",NO:4,DISC_DESCR:"Instrument",UOM:"U",STAGE:"2",STEP:"40",ITEM_SEQ:"5",WCCODE_DESCR:"Junction Box And Loc. Panels Installation",PLAN_START_WK:"W2021_22",PLAN_FINISH_WK:"W2021_34",PLAN_START_DATE:"2021-06-04",PLAN_FINISH_DATE:"2021-08-27",FORECAST_START_WK:"W2021_22",FORECAST_FINISH_WK:"W2021_34",FORECAST_START_DATE:"2021-06-04",FORECAST_FINISH_DATE:"2021-08-27",INITIALQTY:29,INITIALMANHOURS:348,PLAN_VALUE:274.7112,ACTUAL_VALUE:0,PLAN_VALUE_PRE:247.2888,ACTUAL_VALUE_PRE:0,PLAN_PROG:78.94,ACTUAL_PROG:0,PLAN_PROG_WK:7.87,ACTUAL_PROG_WK:0,PLAN_QTY:22.9,ACTUAL_QTY:0,PLAN_QTY_WK:2.3,ACTUAL_QTY_WK:0,REMAIN_QTY:29,C2_QTY:98,EQV_QTY:0},
      {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW04",GWBSCODE:"MW04",MODULE_DESCR:"MW04-Power Gen.",WCCODE:"15240",NO:4,DISC_DESCR:"Instrument",UOM:"U",STAGE:"2",STEP:"40",ITEM_SEQ:"5",WCCODE_DESCR:"Junction Box And Loc. Panels Installation",PLAN_START_WK:"W2021_18",PLAN_FINISH_WK:"W2021_25",PLAN_START_DATE:"2021-05-07",PLAN_FINISH_DATE:"2021-06-25",FORECAST_START_WK:"W2021_18",FORECAST_FINISH_WK:"W2021_25",FORECAST_START_DATE:"2021-05-07",FORECAST_FINISH_DATE:"2021-06-25",INITIALQTY:13,INITIALMANHOURS:156,PLAN_VALUE:156,ACTUAL_VALUE:0,PLAN_VALUE_PRE:156,ACTUAL_VALUE_PRE:0,PLAN_PROG:100,ACTUAL_PROG:0,PLAN_PROG_WK:0,ACTUAL_PROG_WK:0,PLAN_QTY:13,ACTUAL_QTY:0,PLAN_QTY_WK:0,ACTUAL_QTY_WK:0,REMAIN_QTY:13,C2_QTY:33,EQV_QTY:0},
      {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBSCODE:"ME02",MODULE_DESCR:"ME02-MEG & Fule Gas",WCCODE:"15240",NO:4,DISC_DESCR:"Instrument",UOM:"U",STAGE:"2",STEP:"40",ITEM_SEQ:"5",WCCODE_DESCR:"Junction Box And Loc. Panels Installation",PLAN_START_WK:"W2021_24",PLAN_FINISH_WK:"W2021_34",PLAN_START_DATE:"2021-06-18",PLAN_FINISH_DATE:"2021-08-27",FORECAST_START_WK:"W2021_24",FORECAST_FINISH_WK:"W2021_34",FORECAST_START_DATE:"2021-06-18",FORECAST_FINISH_DATE:"2021-08-27",INITIALQTY:18,INITIALMANHOURS:216,PLAN_VALUE:166.4064,ACTUAL_VALUE:0,PLAN_VALUE_PRE:145.1952,ACTUAL_VALUE_PRE:0,PLAN_PROG:77.03,ACTUAL_PROG:0,PLAN_PROG_WK:9.82,ACTUAL_PROG_WK:0,PLAN_QTY:13.9,ACTUAL_QTY:0,PLAN_QTY_WK:1.8,ACTUAL_QTY_WK:0,REMAIN_QTY:18,C2_QTY:55,EQV_QTY:0},
      {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBSCODE:"MW05",MODULE_DESCR:"MW05-Utility",WCCODE:"15240",NO:4,DISC_DESCR:"Instrument",UOM:"U",STAGE:"2",STEP:"40",ITEM_SEQ:"5",WCCODE_DESCR:"Junction Box And Loc. Panels Installation",PLAN_START_WK:"W2021_24",PLAN_FINISH_WK:"W2021_34",PLAN_START_DATE:"2021-06-18",PLAN_FINISH_DATE:"2021-08-27",FORECAST_START_WK:"W2021_24",FORECAST_FINISH_WK:"W2021_34",FORECAST_START_DATE:"2021-06-18",FORECAST_FINISH_DATE:"2021-08-27",INITIALQTY:23,INITIALMANHOURS:276,PLAN_VALUE:212.7132,ACTUAL_VALUE:0,PLAN_VALUE_PRE:185.4996,ACTUAL_VALUE_PRE:0,PLAN_PROG:77.07,ACTUAL_PROG:0,PLAN_PROG_WK:9.86,ACTUAL_PROG_WK:0,PLAN_QTY:17.7,ACTUAL_QTY:0,PLAN_QTY_WK:2.3,ACTUAL_QTY_WK:0,REMAIN_QTY:23,C2_QTY:104,EQV_QTY:0},
      {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBSCODE:"MW05",MODULE_DESCR:"MW05-Utility",WCCODE:"15600",NO:4,DISC_DESCR:"Instrument",UOM:"U",STAGE:"6",STEP:"00",WCCODE_DESCR:"Analyzers Installation",PLAN_START_WK:"W2021_24",PLAN_FINISH_WK:"W2021_31",PLAN_START_DATE:"2021-06-18",PLAN_FINISH_DATE:"2021-08-06",FORECAST_START_WK:"W2021_24",FORECAST_FINISH_WK:"W2021_31",FORECAST_START_DATE:"2021-06-18",FORECAST_FINISH_DATE:"2021-08-06",INITIALQTY:12,INITIALMANHOURS:48,PLAN_VALUE:48,ACTUAL_VALUE:0,PLAN_VALUE_PRE:42.8016,ACTUAL_VALUE_PRE:0,PLAN_PROG:100,ACTUAL_PROG:0,PLAN_PROG_WK:10.82,ACTUAL_PROG_WK:0,PLAN_QTY:12,ACTUAL_QTY:0,PLAN_QTY_WK:1.3,ACTUAL_QTY_WK:0,REMAIN_QTY:12,C2_QTY:10,EQV_QTY:0},
      {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",MODULE_DESCR:"MW01-Recpt. Facil. & Prod. Sepn.",WCCODE:"15600",NO:4,DISC_DESCR:"Instrument",UOM:"U",STAGE:"6",STEP:"00",WCCODE_DESCR:"Analyzers Installation",PLAN_START_WK:"W2021_20",PLAN_FINISH_WK:"W2021_32",PLAN_START_DATE:"2021-05-21",PLAN_FINISH_DATE:"2021-08-13",FORECAST_START_WK:"W2021_20",FORECAST_FINISH_WK:"W2021_32",FORECAST_START_DATE:"2021-05-21",FORECAST_FINISH_DATE:"2021-08-13",INITIALQTY:14,INITIALMANHOURS:56,PLAN_VALUE:51.5984,ACTUAL_VALUE:0,PLAN_VALUE_PRE:47.2024,ACTUAL_VALUE_PRE:0,PLAN_PROG:92.14,ACTUAL_PROG:0,PLAN_PROG_WK:7.85,ACTUAL_PROG_WK:0,PLAN_QTY:12.9,ACTUAL_QTY:0,PLAN_QTY_WK:1.1,ACTUAL_QTY_WK:0,REMAIN_QTY:14,C2_QTY:12,EQV_QTY:0},
      {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBSCODE:"ME02",MODULE_DESCR:"ME02-MEG & Fule Gas",WCCODE:"15600",NO:4,DISC_DESCR:"Instrument",UOM:"U",STAGE:"6",STEP:"00",WCCODE_DESCR:"Analyzers Installation",PLAN_START_WK:"W2021_29",PLAN_FINISH_WK:"W2021_34",PLAN_START_DATE:"2021-07-23",PLAN_FINISH_DATE:"2021-08-27",FORECAST_START_WK:"W2021_29",FORECAST_FINISH_WK:"W2021_34",FORECAST_START_DATE:"2021-07-23",FORECAST_FINISH_DATE:"2021-08-27",INITIALQTY:24,INITIALMANHOURS:96,PLAN_VALUE:51.1968,ACTUAL_VALUE:0,PLAN_VALUE_PRE:31.9968,ACTUAL_VALUE_PRE:0,PLAN_PROG:53.32,ACTUAL_PROG:0,PLAN_PROG_WK:20,ACTUAL_PROG_WK:0,PLAN_QTY:12.8,ACTUAL_QTY:0,PLAN_QTY_WK:4.8,ACTUAL_QTY_WK:0,REMAIN_QTY:24,C2_QTY:21,EQV_QTY:0},
      {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBSCODE:"MW02",MODULE_DESCR:"MW02-HC Dewpt. & Conden. Stabil.",WCCODE:"15600",NO:4,DISC_DESCR:"Instrument",UOM:"U",STAGE:"6",STEP:"00",WCCODE_DESCR:"Analyzers Installation",PLAN_START_WK:"W2021_22",PLAN_FINISH_WK:"W2021_34",PLAN_START_DATE:"2021-06-04",PLAN_FINISH_DATE:"2021-08-27",FORECAST_START_WK:"W2021_22",FORECAST_FINISH_WK:"W2021_34",FORECAST_START_DATE:"2021-06-04",FORECAST_FINISH_DATE:"2021-08-27",INITIALQTY:8,INITIALMANHOURS:32,PLAN_VALUE:25.2992,ACTUAL_VALUE:0,PLAN_VALUE_PRE:22.7008,ACTUAL_VALUE_PRE:0,PLAN_PROG:79.06,ACTUAL_PROG:0,PLAN_PROG_WK:8.12,ACTUAL_PROG_WK:0,PLAN_QTY:6.3,ACTUAL_QTY:0,PLAN_QTY_WK:0.7,ACTUAL_QTY_WK:0,REMAIN_QTY:8,C2_QTY:7,EQV_QTY:0},
      {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES COMMON",GWBS_LV3:"KO01",GWBSCODE:"KO01",MODULE_DESCR:"KO01-Flare KO Drum",WCCODE:"15600",NO:4,DISC_DESCR:"Instrument",UOM:"U",STAGE:"6",STEP:"00",WCCODE_DESCR:"Analyzers Installation",PLAN_START_WK:"W2021_34",PLAN_FINISH_WK:"W2021_39",PLAN_START_DATE:"2021-08-27",PLAN_FINISH_DATE:"2021-10-01",FORECAST_START_WK:"W2021_34",FORECAST_FINISH_WK:"W2021_39",FORECAST_START_DATE:"2021-08-27",FORECAST_FINISH_DATE:"2021-10-01",INITIALQTY:1,INITIALMANHOURS:50,PLAN_VALUE:0,ACTUAL_VALUE:0,PLAN_VALUE_PRE:0,ACTUAL_VALUE_PRE:0,PLAN_PROG:0,ACTUAL_PROG:0,PLAN_PROG_WK:0,ACTUAL_PROG_WK:0,PLAN_QTY:0,ACTUAL_QTY:0,PLAN_QTY_WK:0,ACTUAL_QTY_WK:0,REMAIN_QTY:1,C2_QTY:4,EQV_QTY:0},
      {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",MODULE_DESCR:"ME01-Flash Gas Comp.",WCCODE:"15600",NO:4,DISC_DESCR:"Instrument",UOM:"U",STAGE:"6",STEP:"00",WCCODE_DESCR:"Analyzers Installation",PLAN_START_WK:"W2021_28",PLAN_FINISH_WK:"W2021_32",PLAN_START_DATE:"2021-07-16",PLAN_FINISH_DATE:"2021-08-13",FORECAST_START_WK:"W2021_28",FORECAST_FINISH_WK:"W2021_32",FORECAST_START_DATE:"2021-07-16",FORECAST_FINISH_DATE:"2021-08-13",INITIALQTY:6,INITIALMANHOURS:24,PLAN_VALUE:22.9008,ACTUAL_VALUE:0,PLAN_VALUE_PRE:16.0008,ACTUAL_VALUE_PRE:0,PLAN_PROG:95.42,ACTUAL_PROG:0,PLAN_PROG_WK:28.75,ACTUAL_PROG_WK:0,PLAN_QTY:5.7,ACTUAL_QTY:0,PLAN_QTY_WK:1.7,ACTUAL_QTY_WK:0,REMAIN_QTY:6,C2_QTY:5,EQV_QTY:0},
      {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW05",GWBSCODE:"MW05",MODULE_DESCR:"MW05-Utility",WCCODE:"16250",NO:3,DISC_DESCR:"Electrical",UOM:"LM",STAGE:"2",STEP:"50",ITEM_SEQ:"3",WCCODE_DESCR:"Cables Laying  Inside Ss & Buildings",PLAN_START_WK:"W2021_28",PLAN_FINISH_WK:"W2021_35",PLAN_START_DATE:"2021-07-16",PLAN_FINISH_DATE:"2021-09-03",FORECAST_START_WK:"W2021_28",FORECAST_FINISH_WK:"W2021_35",FORECAST_START_DATE:"2021-07-16",FORECAST_FINISH_DATE:"2021-09-03",INITIALQTY:179,INITIALMANHOURS:126,PLAN_VALUE:61.6014,ACTUAL_VALUE:0,PLAN_VALUE_PRE:44.8056,ACTUAL_VALUE_PRE:0,PLAN_PROG:48.89,ACTUAL_PROG:0,PLAN_PROG_WK:13.34,ACTUAL_PROG_WK:0,PLAN_QTY:87.5,ACTUAL_QTY:0,PLAN_QTY_WK:23.9,ACTUAL_QTY_WK:0,REMAIN_QTY:179,C2_QTY:50,EQV_QTY:0},
      {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES COMMON",GWBS_LV3:"KO01",GWBSCODE:"KO01",MODULE_DESCR:"KO01-Flare KO Drum",WCCODE:"16250",NO:3,DISC_DESCR:"Electrical",UOM:"LM",STAGE:"2",STEP:"50",ITEM_SEQ:"3",WCCODE_DESCR:"Cables Laying  Inside Ss & Buildings",PLAN_START_WK:"W2021_46",PLAN_FINISH_WK:"W2022_01",PLAN_START_DATE:"2021-11-19",PLAN_FINISH_DATE:"2022-01-07",FORECAST_START_WK:"W2021_46",FORECAST_FINISH_WK:"W2022_01",FORECAST_START_DATE:"2021-11-19",FORECAST_FINISH_DATE:"2022-01-07",INITIALQTY:600,INITIALMANHOURS:229,PLAN_VALUE:0,ACTUAL_VALUE:0,PLAN_VALUE_PRE:0,ACTUAL_VALUE_PRE:0,PLAN_PROG:0,ACTUAL_PROG:0,PLAN_PROG_WK:0,ACTUAL_PROG_WK:0,PLAN_QTY:0,ACTUAL_QTY:0,PLAN_QTY_WK:0,ACTUAL_QTY_WK:0,REMAIN_QTY:600},
      {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME04",GWBSCODE:"ME04",MODULE_DESCR:"ME04-Power Gen.",WCCODE:"16250",NO:3,DISC_DESCR:"Electrical",UOM:"LM",STAGE:"2",STEP:"50",ITEM_SEQ:"3",WCCODE_DESCR:"Cables Laying  Inside Ss & Buildings",PLAN_START_WK:"W2021_20",PLAN_FINISH_WK:"W2021_27",PLAN_START_DATE:"2021-05-21",PLAN_FINISH_DATE:"2021-07-09",FORECAST_START_WK:"W2021_20",FORECAST_FINISH_WK:"W2021_27",FORECAST_START_DATE:"2021-05-21",FORECAST_FINISH_DATE:"2021-07-09",INITIALQTY:142,INITIALMANHOURS:100,PLAN_VALUE:100,ACTUAL_VALUE:0,PLAN_VALUE_PRE:100,ACTUAL_VALUE_PRE:0,PLAN_PROG:100,ACTUAL_PROG:0,PLAN_PROG_WK:0,ACTUAL_PROG_WK:0,PLAN_QTY:142,ACTUAL_QTY:0,PLAN_QTY_WK:0,ACTUAL_QTY_WK:0,REMAIN_QTY:142,C2_QTY:1092.9,EQV_QTY:0},
      {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW02",GWBSCODE:"MW02",MODULE_DESCR:"MW02-HC Dewpt. & Conden. Stabil.",WCCODE:"16250",NO:3,DISC_DESCR:"Electrical",UOM:"LM",STAGE:"2",STEP:"50",ITEM_SEQ:"3",WCCODE_DESCR:"Cables Laying  Inside Ss & Buildings",PLAN_START_WK:"W2021_26",PLAN_FINISH_WK:"W2021_36",PLAN_START_DATE:"2021-07-02",PLAN_FINISH_DATE:"2021-09-10",FORECAST_START_WK:"W2021_26",FORECAST_FINISH_WK:"W2021_36",FORECAST_START_DATE:"2021-07-02",FORECAST_FINISH_DATE:"2021-09-10",INITIALQTY:254,INITIALMANHOURS:178,PLAN_VALUE:93.45,ACTUAL_VALUE:0,PLAN_VALUE_PRE:75.8814,ACTUAL_VALUE_PRE:0,PLAN_PROG:52.5,ACTUAL_PROG:0,PLAN_PROG_WK:9.86,ACTUAL_PROG_WK:0,PLAN_QTY:133.3,ACTUAL_QTY:0,PLAN_QTY_WK:25.1,ACTUAL_QTY_WK:0,REMAIN_QTY:254,C2_QTY:5812,EQV_QTY:0},
      {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME01",GWBSCODE:"ME01",MODULE_DESCR:"ME01-Flash Gas Comp.",WCCODE:"16250",NO:3,DISC_DESCR:"Electrical",UOM:"LM",STAGE:"2",STEP:"50",ITEM_SEQ:"3",WCCODE_DESCR:"Cables Laying  Inside Ss & Buildings",PLAN_START_WK:"W2021_25",PLAN_FINISH_WK:"W2021_35",PLAN_START_DATE:"2021-06-25",PLAN_FINISH_DATE:"2021-09-03",FORECAST_START_WK:"W2021_25",FORECAST_FINISH_WK:"W2021_35",FORECAST_START_DATE:"2021-06-25",FORECAST_FINISH_DATE:"2021-09-03",INITIALQTY:219,INITIALMANHOURS:154,PLAN_VALUE:105.9366,ACTUAL_VALUE:0,PLAN_VALUE_PRE:90.9986,ACTUAL_VALUE_PRE:0,PLAN_PROG:68.78,ACTUAL_PROG:0,PLAN_PROG_WK:9.7,ACTUAL_PROG_WK:0,PLAN_QTY:150.6,ACTUAL_QTY:0,PLAN_QTY_WK:21.2,ACTUAL_QTY_WK:0,REMAIN_QTY:219,C2_QTY:1450,EQV_QTY:0},
      {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW04",GWBSCODE:"MW04",MODULE_DESCR:"MW04-Power Gen.",WCCODE:"16250",NO:3,DISC_DESCR:"Electrical",UOM:"LM",STAGE:"2",STEP:"50",ITEM_SEQ:"3",WCCODE_DESCR:"Cables Laying  Inside Ss & Buildings",PLAN_START_WK:"W2021_19",PLAN_FINISH_WK:"W2021_27",PLAN_START_DATE:"2021-05-14",PLAN_FINISH_DATE:"2021-07-09",FORECAST_START_WK:"W2021_19",FORECAST_FINISH_WK:"W2021_27",FORECAST_START_DATE:"2021-05-14",FORECAST_FINISH_DATE:"2021-07-09",INITIALQTY:127,INITIALMANHOURS:89,PLAN_VALUE:89,ACTUAL_VALUE:0,PLAN_VALUE_PRE:89,ACTUAL_VALUE_PRE:0,PLAN_PROG:100,ACTUAL_PROG:0,PLAN_PROG_WK:0,ACTUAL_PROG_WK:0,PLAN_QTY:127,ACTUAL_QTY:0,PLAN_QTY_WK:0,ACTUAL_QTY_WK:0,REMAIN_QTY:127,C2_QTY:1616.3,EQV_QTY:0},
      {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBSCODE:"ME05",MODULE_DESCR:"ME05-E&I Bldg.",WCCODE:"16250",NO:3,DISC_DESCR:"Electrical",UOM:"LM",STAGE:"2",STEP:"50",ITEM_SEQ:"3",WCCODE_DESCR:"Cables Laying  Inside Ss & Buildings",PLAN_START_WK:"W2021_16",PLAN_FINISH_WK:"W2021_24",PLAN_START_DATE:"2021-04-23",PLAN_FINISH_DATE:"2021-06-18",FORECAST_START_WK:"W2021_16",FORECAST_FINISH_WK:"W2021_24",FORECAST_START_DATE:"2021-04-23",FORECAST_FINISH_DATE:"2021-06-18",INITIALQTY:460,INITIALMANHOURS:352,PLAN_VALUE:352,ACTUAL_VALUE:0,PLAN_VALUE_PRE:352,ACTUAL_VALUE_PRE:0,PLAN_PROG:100,ACTUAL_PROG:0,PLAN_PROG_WK:0,ACTUAL_PROG_WK:0,PLAN_QTY:460,ACTUAL_QTY:0,PLAN_QTY_WK:0,ACTUAL_QTY_WK:0,REMAIN_QTY:460,C2_QTY:144642.5,EQV_QTY:0},
      {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBSCODE:"ME02",MODULE_DESCR:"ME02-MEG & Fule Gas",WCCODE:"16250",NO:3,DISC_DESCR:"Electrical",UOM:"LM",STAGE:"2",STEP:"50",ITEM_SEQ:"3",WCCODE_DESCR:"Cables Laying  Inside Ss & Buildings",PLAN_START_WK:"W2021_25",PLAN_FINISH_WK:"W2021_35",PLAN_START_DATE:"2021-06-25",PLAN_FINISH_DATE:"2021-09-03",FORECAST_START_WK:"W2021_25",FORECAST_FINISH_WK:"W2021_35",FORECAST_START_DATE:"2021-06-25",FORECAST_FINISH_DATE:"2021-09-03",INITIALQTY:226,INITIALMANHOURS:158,PLAN_VALUE:103.6322,ACTUAL_VALUE:0,PLAN_VALUE_PRE:88.0692,ACTUAL_VALUE_PRE:0,PLAN_PROG:65.59,ACTUAL_PROG:0,PLAN_PROG_WK:9.85,ACTUAL_PROG_WK:0,PLAN_QTY:148.2,ACTUAL_QTY:0,PLAN_QTY_WK:22.3,ACTUAL_QTY_WK:0,REMAIN_QTY:226,C2_QTY:50,EQV_QTY:0},
      {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",MODULE_DESCR:"MW01-Recpt. Facil. & Prod. Sepn.",WCCODE:"16250",NO:3,DISC_DESCR:"Electrical",UOM:"LM",STAGE:"2",STEP:"50",ITEM_SEQ:"3",WCCODE_DESCR:"Cables Laying  Inside Ss & Buildings",PLAN_START_WK:"W2021_27",PLAN_FINISH_WK:"W2021_35",PLAN_START_DATE:"2021-07-09",PLAN_FINISH_DATE:"2021-09-03",FORECAST_START_WK:"W2021_27",FORECAST_FINISH_WK:"W2021_35",FORECAST_START_DATE:"2021-07-09",FORECAST_FINISH_DATE:"2021-09-03",INITIALQTY:252,INITIALMANHOURS:177,PLAN_VALUE:102.7131,ACTUAL_VALUE:0,PLAN_VALUE_PRE:81.5085,ACTUAL_VALUE_PRE:0,PLAN_PROG:58.03,ACTUAL_PROG:0,PLAN_PROG_WK:11.99,ACTUAL_PROG_WK:0,PLAN_QTY:146.2,ACTUAL_QTY:0,PLAN_QTY_WK:30.2,ACTUAL_QTY_WK:0,REMAIN_QTY:252,C2_QTY:17470,EQV_QTY:0},
      {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBSCODE:"ME05",MODULE_DESCR:"ME05-E&I Bldg.",WCCODE:"20440",NO:6,DISC_DESCR:"Architecture",UOM:"SQM",STAGE:"4",STEP:"40",ITEM_SEQ:"2",WCCODE_DESCR:"Wall And Ceiling Installation",PLAN_START_WK:"W2021_13",PLAN_FINISH_WK:"W2021_24",PLAN_START_DATE:"2021-04-02",PLAN_FINISH_DATE:"2021-06-18",FORECAST_START_WK:"W2021_13",FORECAST_FINISH_WK:"W2021_24",FORECAST_START_DATE:"2021-04-02",FORECAST_FINISH_DATE:"2021-06-18",ACTUAL_START_WK:"W2021_26",ACTUAL_START_DATE:"2021-07-02",INITIALQTY:1152,INITIALMANHOURS:2303,PLAN_VALUE:2303,ACTUAL_VALUE:2130.275,PLAN_VALUE_PRE:2303,ACTUAL_VALUE_PRE:1515.8346,PLAN_PROG:100,ACTUAL_PROG:92.5,PLAN_PROG_WK:0,ACTUAL_PROG_WK:26.68,PLAN_QTY:1152,ACTUAL_QTY:1065.6,PLAN_QTY_WK:0,ACTUAL_QTY_WK:307.4,REMAIN_QTY:86.4,C2_QTY:1152,EQV_QTY:1065.5},
      {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME05",GWBSCODE:"ME05",MODULE_DESCR:"ME05-E&I Bldg.",WCCODE:"20450",NO:6,DISC_DESCR:"Architecture",UOM:"U",STAGE:"4",STEP:"50",WCCODE_DESCR:"Door / Hatch / Window Installation",PLAN_START_WK:"W2021_22",PLAN_FINISH_WK:"W2021_25",PLAN_START_DATE:"2021-06-04",PLAN_FINISH_DATE:"2021-06-25",FORECAST_START_WK:"W2021_22",FORECAST_FINISH_WK:"W2021_25",FORECAST_START_DATE:"2021-06-04",FORECAST_FINISH_DATE:"2021-06-25",INITIALQTY:20,INITIALMANHOURS:320,PLAN_VALUE:320,ACTUAL_VALUE:0,PLAN_VALUE_PRE:320,ACTUAL_VALUE_PRE:0,PLAN_PROG:100,ACTUAL_PROG:0,PLAN_PROG_WK:0,ACTUAL_PROG_WK:0,PLAN_QTY:20,ACTUAL_QTY:0,PLAN_QTY_WK:0,ACTUAL_QTY_WK:0,REMAIN_QTY:20,C2_QTY:8,EQV_QTY:0},
      {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES COMMON",GWBS_LV3:"KO01",GWBSCODE:"KO01",MODULE_DESCR:"KO01-Flare KO Drum",WCCODE:"23110",NO:8,DISC_DESCR:"Painting",UOM:"SQM",STAGE:"1",STEP:"10",ITEM_SEQ:"1",WCCODE_DESCR:"Steel Struct. Painting",PLAN_START_WK:"W2021_29",PLAN_FINISH_WK:"W2022_08",PLAN_START_DATE:"2021-07-23",PLAN_FINISH_DATE:"2022-02-25",FORECAST_START_WK:"W2021_29",FORECAST_FINISH_WK:"W2022_08",FORECAST_START_DATE:"2021-07-23",FORECAST_FINISH_DATE:"2022-02-25",ACTUAL_START_WK:"W2021_19",ACTUAL_START_DATE:"2021-05-14",INITIALQTY:4188.21,INITIALMANHOURS:2613,PLAN_VALUE:1170.8853,ACTUAL_VALUE:1050.426,PLAN_VALUE_PRE:630.5169,ACTUAL_VALUE_PRE:636.5268,PLAN_PROG:44.8,ACTUAL_PROG:40.19,PLAN_PROG_WK:20.67,ACTUAL_PROG_WK:15.83,PLAN_QTY:1876.3,ACTUAL_QTY:1683.2,PLAN_QTY_WK:866.1,ACTUAL_QTY_WK:663.4,REMAIN_QTY:2505,C2_QTY:5637.9,EQV_QTY:1683.4},
      {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES COMMON",GWBS_LV3:"FL01",GWBSCODE:"FL01",MODULE_DESCR:"FL01-Flare Stack",WCCODE:"23110",NO:8,DISC_DESCR:"Painting",UOM:"SQM",STAGE:"1",STEP:"10",ITEM_SEQ:"1",WCCODE_DESCR:"Steel Struct. Painting",PLAN_START_WK:"W2021_11",PLAN_FINISH_WK:"W2021_34",PLAN_START_DATE:"2021-03-19",PLAN_FINISH_DATE:"2021-08-27",FORECAST_START_WK:"W2021_11",FORECAST_FINISH_WK:"W2021_34",FORECAST_START_DATE:"2021-03-19",FORECAST_FINISH_DATE:"2021-08-27",ACTUAL_START_WK:"W2021_23",ACTUAL_START_DATE:"2021-06-11",INITIALQTY:6458.3,INITIALMANHOURS:10787,PLAN_VALUE:10402.9828,ACTUAL_VALUE:4898.3767,PLAN_VALUE_PRE:10220.6825,ACTUAL_VALUE_PRE:4710.6829,PLAN_PROG:96.43,ACTUAL_PROG:45.41,PLAN_PROG_WK:1.68,ACTUAL_PROG_WK:1.75,PLAN_QTY:6227.7,ACTUAL_QTY:2932.7,PLAN_QTY_WK:108.5,ACTUAL_QTY_WK:113,REMAIN_QTY:3525.6,C2_QTY:5481.8,EQV_QTY:2932.8},
      {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"MW01",GWBSCODE:"MW01",MODULE_DESCR:"MW01-Recpt. Facil. & Prod. Sepn.",WCCODE:"23110",NO:8,DISC_DESCR:"Painting",UOM:"SQM",STAGE:"1",STEP:"10",ITEM_SEQ:"1",WCCODE_DESCR:"Steel Struct. Painting",PLAN_START_WK:"W2020_52",PLAN_FINISH_WK:"W2021_32",PLAN_START_DATE:"2020-12-25",PLAN_FINISH_DATE:"2021-08-13",FORECAST_START_WK:"W2020_52",FORECAST_FINISH_WK:"W2021_32",FORECAST_START_DATE:"2020-12-25",FORECAST_FINISH_DATE:"2021-08-13",ACTUAL_START_WK:"W2020_48",ACTUAL_START_DATE:"2020-11-27",INITIALQTY:23810.67,INITIALMANHOURS:41500,PLAN_VALUE:41055.95,ACTUAL_VALUE:37445.45,PLAN_VALUE_PRE:40238.4,ACTUAL_VALUE_PRE:36632.05,PLAN_PROG:98.92,ACTUAL_PROG:90.22,PLAN_PROG_WK:1.96,ACTUAL_PROG_WK:1.95,PLAN_QTY:23553.5,ACTUAL_QTY:21482,PLAN_QTY_WK:466.7,ACTUAL_QTY_WK:464.3,REMAIN_QTY:2328.7,C2_QTY:24732.2,EQV_QTY:21483.5},
      {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES MODULES",GWBS_LV3:"ME02",GWBSCODE:"ME02",MODULE_DESCR:"ME02-MEG & Fule Gas",WCCODE:"23110",NO:8,DISC_DESCR:"Painting",UOM:"SQM",STAGE:"1",STEP:"10",ITEM_SEQ:"1",WCCODE_DESCR:"Steel Struct. Painting",PLAN_START_WK:"W2020_52",PLAN_FINISH_WK:"W2021_32",PLAN_START_DATE:"2020-12-25",PLAN_FINISH_DATE:"2021-08-13",FORECAST_START_WK:"W2020_52",FORECAST_FINISH_WK:"W2021_32",FORECAST_START_DATE:"2020-12-25",FORECAST_FINISH_DATE:"2021-08-13",ACTUAL_START_WK:"W2020_48",ACTUAL_START_DATE:"2020-11-27",INITIALQTY:13210.95,INITIALMANHOURS:16046,PLAN_VALUE:15790.8686,ACTUAL_VALUE:15965.77,PLAN_VALUE_PRE:15364.045,ACTUAL_VALUE_PRE:15460.321,PLAN_PROG:98.4,ACTUAL_PROG:99.5,PLAN_PROG_WK:2.65,ACTUAL_PROG_WK:3.15,PLAN_QTY:12999.6,ACTUAL_QTY:13144.9,PLAN_QTY_WK:350.1,ACTUAL_QTY_WK:416.1,REMAIN_QTY:66.1,C2_QTY:13373,EQV_QTY:13144.5},
      {GWBS_LV1:"TOPSIDES",GWBS_LV2:"TOPSIDES COMMON",GWBS_LV3:"RCxx",GWBSCODE:"RCxx",MODULE_DESCR:"RCxx-Topsides Piperack",WCCODE:"23110",NO:8,DISC_DESCR:"Painting",UOM:"SQM",STAGE:"1",STEP:"10",ITEM_SEQ:"1",WCCODE_DESCR:"Steel Struct. Painting",PLAN_START_WK:"W2020_50",PLAN_FINISH_WK:"W2021_20",PLAN_START_DATE:"2020-12-11",PLAN_FINISH_DATE:"2021-05-21",FORECAST_START_WK:"W2020_50",FORECAST_FINISH_WK:"W2021_20",FORECAST_START_DATE:"2020-12-11",FORECAST_FINISH_DATE:"2021-05-21",ACTUAL_START_WK:"W2021_02",ACTUAL_START_DATE:"2021-01-15",INITIALQTY:19813.4,INITIALMANHOURS:18290,PLAN_VALUE:18290,ACTUAL_VALUE:17984.557,PLAN_VALUE_PRE:18290,ACTUAL_VALUE_PRE:17920.542,PLAN_PROG:100,ACTUAL_PROG:98.33,PLAN_PROG_WK:0,ACTUAL_PROG_WK:0.34,PLAN_QTY:19813.4,ACTUAL_QTY:19482.5,PLAN_QTY_WK:0,ACTUAL_QTY_WK:69.3,REMAIN_QTY:330.9,C2_QTY:19483.3,EQV_QTY:19483.3}
    ]
}