import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 4000,
    CanvasHeight: 2000,
    CanvasBorderWeight: 0.05,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.1,

    CanvasChartX: 50,
    CanvasChartY: 50,
    CanvasChartWidth: 480,
    CanvasChartHeight: 250,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  DataItems:  [
    {NO:1,LMS_NO:"BUS-01",LMS_DESCR:"GEG Cogen Final Agreement Signed",EM_LEAD:"Thompson",SEI_LEAD:"NA",PLAN:"2021-09-01",FORECAST:"2021-09-01",ACTUAL:"2021-09-01",STATUS:"To Go",FUNCTIONS:"Business",DEFINITION_OF_SUCCESS:"Final agreement signed",YEARS:2021,LOOKAHEAD:"NA"},
    {NO:2,LMS_NO:"BUS-02",LMS_DESCR:"Naphtha/LPG Sourcing Plan/Shipping Strategy refresh",EM_LEAD:"Batey",SEI_LEAD:"NA",PLAN:"2021-09-01",FORECAST:"2021-09-01",ACTUAL:"2021-09-01",STATUS:"To Go",FUNCTIONS:"Business",DEFINITION_OF_SUCCESS:"Report finalized and issued",YEARS:2021,LOOKAHEAD:"NA"},
    {NO:3,LMS_NO:"BUS-03",LMS_DESCR:"FID Achieved",EM_LEAD:"Stein",SEI_LEAD:"NA",PLAN:"2021-11-01",FORECAST:"2021-11-01",ACTUAL:"2021-11-01",STATUS:"To Go",FUNCTIONS:"Business",DEFINITION_OF_SUCCESS:"FID approval receipt and funds in place",YEARS:2021,LOOKAHEAD:"NA"},
    {NO:4,LMS_NO:"BUS-04",LMS_DESCR:"Gate 3 Review",EM_LEAD:"Stein",SEI_LEAD:"NA",PLAN:"2021-11-01",FORECAST:"2021-11-01",ACTUAL:"2021-11-01",STATUS:"To Go",FUNCTIONS:"Business",DEFINITION_OF_SUCCESS:"Approval to progress",YEARS:2021,LOOKAHEAD:"NA"},
    {NO:5,LMS_NO:"BUS-05",LMS_DESCR:"Firm deal on LPG Terminal",EM_LEAD:"Batey",SEI_LEAD:"NA",PLAN:"2021-11-01",FORECAST:"2021-11-01",ACTUAL:"2021-11-01",STATUS:"At Risk",FUNCTIONS:"Business",DEFINITION_OF_SUCCESS:"Documented agreement",YEARS:2021,LOOKAHEAD:"NA"},
    {NO:6,LMS_NO:"BUS-06",LMS_DESCR:"CWAG WWTP Final Agreement Signed",EM_LEAD:"Thompson",SEI_LEAD:"NA",PLAN:"2022-01-01",FORECAST:"2022-01-01",ACTUAL:"2022-01-01",STATUS:"Delayed",FUNCTIONS:"Business",DEFINITION_OF_SUCCESS:"Final Agreement Signed / Documented",YEARS:2022,LOOKAHEAD:"NA"},
    {NO:7,LMS_NO:"BUS-07",LMS_DESCR:"Define business model & scope of associated agreements",EM_LEAD:"M. Lo",SEI_LEAD:"NA",PLAN:"2022-01-01",FORECAST:"2022-01-01",ACTUAL:"2022-01-01",STATUS:"Completed",FUNCTIONS:"Business",DEFINITION_OF_SUCCESS:"Business model proposal reviewed and aligned with key stakeholders and steering committee types of agreements defined",YEARS:2022,LOOKAHEAD:"NA"},
    // {NO:8,LMS_NO:"BUS-08",LMS_DESCR:"Financial & Business process system design complete cross-functional alignment",EM_LEAD:"M. Lo/EMIT",SEI_LEAD:"NA",PLAN:"2022-08-01",FORECAST:"2022-08-01",STATUS:"To Go",FUNCTIONS:"Business",DEFINITION_OF_SUCCESS:"Business requirement & process defined and system design completed and aligned",YEARS:2022,LOOKAHEAD:"90D"},
    // {NO:9,LMS_NO:"BUS-09",LMS_DESCR:"Business Readiness Cold Eyes Review 1",EM_LEAD:"W. Zhang",SEI_LEAD:"NA",PLAN:"2022-12-01",FORECAST:"2022-12-01",STATUS:"To Go",FUNCTIONS:"Business",DEFINITION_OF_SUCCESS:"Review completed wit follow ups documented / planned",YEARS:2022,LOOKAHEAD:"NA"},
    // {NO:10,LMS_NO:"BUS-10",LMS_DESCR:"Master Data Plan Defined",EM_LEAD:"M. Lo/EMIT",SEI_LEAD:"NA",PLAN:"2023-07-01",FORECAST:"2023-07-01",STATUS:"To Go",FUNCTIONS:"Business",DEFINITION_OF_SUCCESS:"Define master data requirement timeline robust execution plan to ensure data integrity",YEARS:2023,LOOKAHEAD:"NA"},
    // {NO:11,LMS_NO:"BUS-11",LMS_DESCR:"Solids Mini URT complete",EM_LEAD:"M. Lo",SEI_LEAD:"NA",PLAN:"2023-08-01",FORECAST:"2023-08-01",STATUS:"To Go",FUNCTIONS:"Business",DEFINITION_OF_SUCCESS:"Early identification of process/system issues with gap closure plan",YEARS:2023,LOOKAHEAD:"NA"},
    // {NO:12,LMS_NO:"BUS-12",LMS_DESCR:"Feedstock Acquisition in Corporate Plan",EM_LEAD:"Batey",SEI_LEAD:"NA",PLAN:"2023-06-01",FORECAST:"2023-06-01",STATUS:"To Go",FUNCTIONS:"Business",DEFINITION_OF_SUCCESS:"Documented in Corp Plan",YEARS:2023,LOOKAHEAD:"NA"},
    // {NO:13,LMS_NO:"BUS-13",LMS_DESCR:"Product deployment plan  Corp Plan & S/U basis",EM_LEAD:"Thompson",SEI_LEAD:"NA",PLAN:"2023-09-01",FORECAST:"2023-09-01",STATUS:"To Go",FUNCTIONS:"Business",DEFINITION_OF_SUCCESS:"Documented in Corp Plan",YEARS:2023,LOOKAHEAD:"NA"},
    // {NO:14,LMS_NO:"BUS-14",LMS_DESCR:"Polys raw matl supply plans in place / Catalyst & Product subn to PS&RA complete",EM_LEAD:"S. Shu",SEI_LEAD:"NA",PLAN:"2023-09-01",FORECAST:"2023-09-01",STATUS:"To Go",FUNCTIONS:"Business",DEFINITION_OF_SUCCESS:"Plans documented / in place",YEARS:2023,LOOKAHEAD:"NA"},
    // {NO:15,LMS_NO:"BUS-15",LMS_DESCR:"PE/PP product deployment plans complete",EM_LEAD:"S. Shu",SEI_LEAD:"NA",PLAN:"2023-09-01",FORECAST:"2023-09-01",STATUS:"To Go",FUNCTIONS:"Business",DEFINITION_OF_SUCCESS:"Plans documented / in place",YEARS:2023,LOOKAHEAD:"NA"},
    // {NO:16,LMS_NO:"BUS-16",LMS_DESCR:"External relationship w/ Gov. agencies and neighbors established",EM_LEAD:"Emmen",SEI_LEAD:"NA",PLAN:"2023-08-01",FORECAST:"2023-08-01",STATUS:"To Go",FUNCTIONS:"Business",DEFINITION_OF_SUCCESS:"Plans documented / in place",YEARS:2023,LOOKAHEAD:"NA"},
    // {NO:17,LMS_NO:"BUS-17",LMS_DESCR:"Business Process End-to-end user readiness testing Cold Eyes Review",EM_LEAD:"M. Lo",SEI_LEAD:"NA",PLAN:"2023-10-01",FORECAST:"2023-10-01",STATUS:"To Go",FUNCTIONS:"Business",DEFINITION_OF_SUCCESS:"Any potential gaps identified and closures plan in place to close the gaps",YEARS:2023,LOOKAHEAD:"NA"},
    // {NO:18,LMS_NO:"BUS-18",LMS_DESCR:"Acquisition / Trading organization in-place (fuels)",EM_LEAD:"Batey",SEI_LEAD:"NA",PLAN:"2023-09-01",FORECAST:"2023-09-01",STATUS:"To Go",FUNCTIONS:"Business",DEFINITION_OF_SUCCESS:"Plans documented / in place",YEARS:2023,LOOKAHEAD:"NA"},
    // {NO:19,LMS_NO:"BUS-20",LMS_DESCR:"PS&RA input for BC products",EM_LEAD:"Thompson",SEI_LEAD:"NA",PLAN:"2023-10-01",FORECAST:"2023-10-01",STATUS:"To Go",FUNCTIONS:"Business",DEFINITION_OF_SUCCESS:"Plans documented / in place",YEARS:2023,LOOKAHEAD:"NA"},
    // {NO:20,LMS_NO:"BUS-21",LMS_DESCR:"Sign Grid power purchase agreement",EM_LEAD:"Thompson",SEI_LEAD:"NA",PLAN:"2023-10-01",FORECAST:"2023-10-01",STATUS:"To Go",FUNCTIONS:"Business",DEFINITION_OF_SUCCESS:"Agreement documented / in place - For boiler plate pay whatever rate is",YEARS:2023,LOOKAHEAD:"NA"},
    // {NO:21,LMS_NO:"BUS-22",LMS_DESCR:"Solids End-to-end URT complete",EM_LEAD:"M. Lo",SEI_LEAD:"NA",PLAN:"2024-01-01",FORECAST:"2024-01-01",STATUS:"To Go",FUNCTIONS:"Business",DEFINITION_OF_SUCCESS:"Identification of any process/system issues and gap closure in place",YEARS:2024,LOOKAHEAD:"NA"},
    // {NO:22,LMS_NO:"BUS-23",LMS_DESCR:"Cold pack test plan complete",EM_LEAD:"S. Shu",SEI_LEAD:"NA",PLAN:"2024-01-01",FORECAST:"2024-01-01",STATUS:"To Go",FUNCTIONS:"Business",DEFINITION_OF_SUCCESS:"Signed off and documented",YEARS:2024,LOOKAHEAD:"NA"},
    // {NO:23,LMS_NO:"BUS-24",LMS_DESCR:"Detailed transition plan (WH early operation) Complete",EM_LEAD:"S. Shu",SEI_LEAD:"NA",PLAN:"2024-02-01",FORECAST:"2024-02-01",STATUS:"To Go",FUNCTIONS:"Business",DEFINITION_OF_SUCCESS:"Signed off and documented",YEARS:2024,LOOKAHEAD:"NA"},
    // {NO:24,LMS_NO:"BUS-25",LMS_DESCR:"BC and Fuels Resource Plan Complete",EM_LEAD:"Thompson",SEI_LEAD:"NA",PLAN:"2024-01-01",FORECAST:"2024-01-01",STATUS:"To Go",FUNCTIONS:"Business",DEFINITION_OF_SUCCESS:"Signed off and documented",YEARS:2024,LOOKAHEAD:"NA"},
    // {NO:25,LMS_NO:"BUS-26",LMS_DESCR:"BC and Fuels Product testing plan (FET)",EM_LEAD:"Thompson",SEI_LEAD:"NA",PLAN:"2024-01-01",FORECAST:"2024-01-01",STATUS:"To Go",FUNCTIONS:"Business",DEFINITION_OF_SUCCESS:"Signed off and documented",YEARS:2024,LOOKAHEAD:"NA"},
    // {NO:26,LMS_NO:"BUS-28",LMS_DESCR:"Business Systems Go Live (Migration to production)",EM_LEAD:"M. Lo",SEI_LEAD:"NA",PLAN:"2024-05-01",FORECAST:"2024-05-01",STATUS:"To Go",FUNCTIONS:"Business",DEFINITION_OF_SUCCESS:"Successful execution of  end-to-end commercial transactions",YEARS:2024,LOOKAHEAD:"NA"},
    // {NO:27,LMS_NO:"BUS-29",LMS_DESCR:"Customer level deployment plan including comms and product qualn plan",EM_LEAD:"S. Shu",SEI_LEAD:"NA",PLAN:"2024-08-01",FORECAST:"2024-08-01",STATUS:"To Go",FUNCTIONS:"Business",DEFINITION_OF_SUCCESS:"Documented",YEARS:2024,LOOKAHEAD:"NA"},
    // {NO:28,LMS_NO:"BUS-30",LMS_DESCR:"Commercial contingy playbook  Intern. comms/on-boarding complete",EM_LEAD:"Thompson",SEI_LEAD:"NA",PLAN:"2024-07-01",FORECAST:"2024-07-01",STATUS:"To Go",FUNCTIONS:"Business",DEFINITION_OF_SUCCESS:"Documented",YEARS:2024,LOOKAHEAD:"NA"},
    // {NO:29,LMS_NO:"BUS-31",LMS_DESCR:"Commercial & Logistics contingency playbook complete",EM_LEAD:"S. Shu",SEI_LEAD:"NA",PLAN:"2024-08-01",FORECAST:"2024-08-01",STATUS:"To Go",FUNCTIONS:"Business",DEFINITION_OF_SUCCESS:"Documented",YEARS:2024,LOOKAHEAD:"NA"},
    // {NO:30,LMS_NO:"BUS-32",LMS_DESCR:"Liquids Command Center go-live",EM_LEAD:"Thompson",SEI_LEAD:"NA",PLAN:"2024-10-01",FORECAST:"2024-10-01",STATUS:"To Go",FUNCTIONS:"Business",DEFINITION_OF_SUCCESS:"Documented",YEARS:2024,LOOKAHEAD:"NA"}
    ],
  Queries: {
    SQL1: [
      { DESC: 'DataSource SPCS Data 03-Mar-20' }
    ],
    SQL2: [
      { CDATE: '2021-08-20' }
    ],
    SQL3: [
      {TOTAL:160,TOGO:130,ATRISK:52,DELAYED:1,COMPLETED:29}
    ],
  }
}