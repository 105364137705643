import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 340,
      CanvasHeight: 280,
      CanvasBorderWeight: 0.3,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 310,
      CanvasChartHeight: 270,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: 'Transparent',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },
    Queries: {
      SQL1:  [
        {DISC_DESCR:"Total",OPEN:1812}
      ],
    },
    DataItems: [
      {DISC_DESCR:"EIT",PLAN:75,ACTUAL:76},
      {DISC_DESCR:"PIPING",PLAN:156,ACTUAL:141},
      {DISC_DESCR:"TUBING",PLAN:42,ACTUAL:0},
      {DISC_DESCR:"HVAC",PLAN:26,ACTUAL:13},
      {DISC_DESCR:"STRUCTURE",PLAN:15,ACTUAL:28},
      {DISC_DESCR:"ARCHITECTURE",PLAN:46,ACTUAL:8},
      {DISC_DESCR:"PAINTING",PLAN:110,ACTUAL:90},
      {DISC_DESCR:"MECHANICAL",PLAN:10,ACTUAL:25},
      {DISC_DESCR:"STEEL-OUTFITTING",PLAN:0,ACTUAL:12},
      {DISC_DESCR:"SCAFFOLDING",PLAN:20,ACTUAL:25},
      {DISC_DESCR:"Overall",PLAN:500,ACTUAL:418}
    ],
}