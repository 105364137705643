import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1400,
      CanvasHeight: 2000,
      CanvasBorderWeight: 0.3,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 310,
      CanvasChartHeight: 270,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: 'Transparent',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },

    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },

    DataItems: [
      {NO:1,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P2",SUBSYSTEM:"21-6800-6840-08",SUBSYSTEM_DESCR:"Fire Zone 6840-8and9 -Pedestal Cranes ,WestandEast",WD_PLAN:"2022-07-16",WD_DELTA:-10,WD_TIMES:1,MC_REFORECAST:"2022-07-30",WD_ACTUAL_DATE:"2022-07-06",MC_APPROVED:"2022-07-08",WD_TO_MC_WK:0,WD_TO_MC_DAYS:2,BAR:2,MC_TARGET_WD_DATE:"2022-07-16",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:2,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P3",SUBSYSTEM:"21-6800-6841-08",SUBSYSTEM_DESCR:"Fire Zone 6841-7 - Piperack Between MW01 and ME01",WD_PLAN:"2022-07-16",WD_DELTA:-11,WD_TIMES:1,MC_REFORECAST:"2022-07-30",WD_ACTUAL_DATE:"2022-07-05",MC_APPROVED:"2022-07-08",WD_TO_MC_WK:0,WD_TO_MC_DAYS:3,BAR:3,MC_TARGET_WD_DATE:"2022-07-16",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:3,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P3",SUBSYSTEM:"21-4000-4030-03",SUBSYSTEM_DESCR:"Produced Water Offspec Header to Produced Water Treatment and Bunkering Station",WD_PLAN:"2022-06-30",WD_DELTA:4,WD_TIMES:1,MC_REFORECAST:"2022-07-14",WD_ACTUAL_DATE:"2022-07-04",MC_APPROVED:"2022-07-08",WD_TO_MC_WK:0,WD_TO_MC_DAYS:4,BAR:4,MC_TARGET_WD_DATE:"2022-06-30",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:4,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P2",SUBSYSTEM:"21-2500-2514-01",SUBSYSTEM_DESCR:"MSE2 Salty Rich MEG Storage and Pumps",WD_PLAN:"2022-06-16",WD_DELTA:15,WD_TIMES:2,MC_REFORECAST:"2022-06-30",WD_ACTUAL_DATE:"2022-07-01",MC_APPROVED:"2022-07-06",WD_TO_MC_WK:0,WD_TO_MC_DAYS:5,BAR:5,MC_TARGET_WD_DATE:"2022-06-16",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:5,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P2",SUBSYSTEM:"21-9340-9343-02",SUBSYSTEM_DESCR:"Aft Mooring Fairleads, Sheaves and Accessories Mounted on HULL",WD_PLAN:"2022-07-16",WD_DELTA:-15,WD_TIMES:1,MC_REFORECAST:"2022-07-30",WD_ACTUAL_DATE:"2022-07-01",MC_APPROVED:"2022-07-05",WD_TO_MC_WK:0,WD_TO_MC_DAYS:4,BAR:4,MC_TARGET_WD_DATE:"2022-07-16",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:6,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P3",SUBSYSTEM:"21-8300-8320-02",SUBSYSTEM_DESCR:"Scale Inhibitor Distribution Subsea",WD_PLAN:"2022-06-24",WD_DELTA:6,WD_TIMES:1,MC_REFORECAST:"2022-07-08",WD_ACTUAL_DATE:"2022-06-30",MC_APPROVED:"2022-07-07",WD_TO_MC_WK:1,WD_TO_MC_DAYS:7,BAR:7,MC_TARGET_WD_DATE:"2022-06-24",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:7,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P1",SUBSYSTEM:"21-6010-6012-04",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables MW02",WD_PLAN:"2022-06-17",WD_DELTA:13,WD_TIMES:1,MC_REFORECAST:"2022-07-01",WD_ACTUAL_DATE:"2022-06-30",MC_APPROVED:"2022-07-06",WD_TO_MC_WK:0,WD_TO_MC_DAYS:6,BAR:6,MC_TARGET_WD_DATE:"2022-06-17",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:8,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P2",SUBSYSTEM:"21-2400-9362-01",SUBSYSTEM_DESCR:"COW2 Tank and Pumps ,Incl Washing Machine",WD_PLAN:"2022-06-07",WD_DELTA:23,WD_TIMES:2,MC_REFORECAST:"2022-06-21",WD_ACTUAL_DATE:"2022-06-30",MC_APPROVED:"2022-07-06",WD_TO_MC_WK:0,WD_TO_MC_DAYS:6,BAR:6,MC_TARGET_WD_DATE:"2022-06-07",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:9,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P2",SUBSYSTEM:"21-2400-9363-01",SUBSYSTEM_DESCR:"COW3 Tank and Pumps ,Incl Washing Machine",WD_PLAN:"2022-06-07",WD_DELTA:23,WD_TIMES:3,MC_REFORECAST:"2022-06-21",WD_ACTUAL_DATE:"2022-06-30",MC_APPROVED:"2022-07-06",WD_TO_MC_WK:0,WD_TO_MC_DAYS:6,BAR:6,MC_TARGET_WD_DATE:"2022-06-07",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:10,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P2",SUBSYSTEM:"21-2400-9365-01",SUBSYSTEM_DESCR:"COE1 Tank and Pumps ,Incl Washing Machine",WD_PLAN:"2022-06-10",WD_DELTA:20,WD_TIMES:2,MC_REFORECAST:"2022-06-24",WD_ACTUAL_DATE:"2022-06-30",MC_APPROVED:"2022-07-06",WD_TO_MC_WK:0,WD_TO_MC_DAYS:6,BAR:6,MC_TARGET_WD_DATE:"2022-06-10",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:11,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P1",SUBSYSTEM:"21-6020-6022-02",SUBSYSTEM_DESCR:"PAS Remote IOs ,incl Junction Box and Multicore Cables MW01",WD_PLAN:"2022-06-17",WD_DELTA:13,WD_TIMES:1,MC_REFORECAST:"2022-07-01",WD_ACTUAL_DATE:"2022-06-30",MC_APPROVED:"2022-07-08",WD_TO_MC_WK:1,WD_TO_MC_DAYS:8,BAR:8,MC_TARGET_WD_DATE:"2022-06-17",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:12,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P1",SUBSYSTEM:"21-6070-6074-01",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables ME01",WD_PLAN:"2022-06-17",WD_DELTA:13,WD_TIMES:1,MC_REFORECAST:"2022-07-01",WD_ACTUAL_DATE:"2022-06-30",MC_APPROVED:"2022-07-08",WD_TO_MC_WK:1,WD_TO_MC_DAYS:8,BAR:8,MC_TARGET_WD_DATE:"2022-06-17",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:13,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P3",SUBSYSTEM:"21-6800-6841-09",SUBSYSTEM_DESCR:"Fire Zone 6841-8 - Piperack Between MW02 and ME02",WD_PLAN:"2022-07-28",WD_DELTA:-28,WD_TIMES:1,MC_REFORECAST:"2022-08-11",WD_ACTUAL_DATE:"2022-06-30",MC_APPROVED:"2022-07-05",WD_TO_MC_WK:0,WD_TO_MC_DAYS:5,BAR:5,MC_TARGET_WD_DATE:"2022-07-28",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:14,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P2",SUBSYSTEM:"21-2400-9361-01",SUBSYSTEM_DESCR:"COW1 Tank and Pumps ,Incl Washing Machine",WD_PLAN:"2022-06-07",WD_DELTA:22,WD_TIMES:2,MC_REFORECAST:"2022-06-21",WD_ACTUAL_DATE:"2022-06-29",MC_APPROVED:"2022-07-06",WD_TO_MC_WK:1,WD_TO_MC_DAYS:7,BAR:7,MC_TARGET_WD_DATE:"2022-06-07",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:15,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P3",SUBSYSTEM:"21-8300-8332-01",SUBSYSTEM_DESCR:"Wax Inhibitor Injection Subsea Phase 1",WD_PLAN:"2022-06-15",WD_DELTA:14,WD_TIMES:1,MC_REFORECAST:"2022-06-29",WD_ACTUAL_DATE:"2022-06-29",MC_APPROVED:"2022-07-05",WD_TO_MC_WK:0,WD_TO_MC_DAYS:6,BAR:6,MC_TARGET_WD_DATE:"2022-06-15",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:16,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P2",SUBSYSTEM:"21-9340-9341-02",SUBSYSTEM_DESCR:"Forward Mooring Fairleads, Sheaves and Accessories Mounted on HULL",WD_PLAN:"2022-07-16",WD_DELTA:-17,WD_TIMES:1,MC_REFORECAST:"2022-07-30",WD_ACTUAL_DATE:"2022-06-29",MC_APPROVED:"2022-07-04",WD_TO_MC_WK:0,WD_TO_MC_DAYS:5,BAR:5,MC_TARGET_WD_DATE:"2022-07-16",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:17,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P1",SUBSYSTEM:"21-6010-6012-01",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables ME01",WD_PLAN:"2022-06-17",WD_DELTA:11,WD_TIMES:1,MC_REFORECAST:"2022-07-01",WD_ACTUAL_DATE:"2022-06-28",MC_APPROVED:"2022-07-02",WD_TO_MC_WK:0,WD_TO_MC_DAYS:4,BAR:4,MC_TARGET_WD_DATE:"2022-06-17",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:18,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P1",SUBSYSTEM:"21-6010-6012-05",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables OFL00",WD_PLAN:"2022-07-24",WD_DELTA:-27,WD_TIMES:1,MC_REFORECAST:"2022-08-07",WD_ACTUAL_DATE:"2022-06-27",MC_APPROVED:"2022-07-06",WD_TO_MC_WK:1,WD_TO_MC_DAYS:9,BAR:9,MC_TARGET_WD_DATE:"2022-07-24",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:19,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P2",SUBSYSTEM:"21-2400-9360-01",SUBSYSTEM_DESCR:"Cargo Oil Washing Header and Distribution",WD_PLAN:"2022-06-16",WD_DELTA:11,WD_TIMES:2,MC_REFORECAST:"2022-06-30",WD_ACTUAL_DATE:"2022-06-27",MC_APPROVED:"2022-07-08",WD_TO_MC_WK:1,WD_TO_MC_DAYS:11,BAR:11,MC_TARGET_WD_DATE:"2022-06-16",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:20,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P1",SUBSYSTEM:"21-6020-6022-03",SUBSYSTEM_DESCR:"PAS Remote IOs ,incl Junction Box and Multicore Cables ME02",WD_PLAN:"2022-06-17",WD_DELTA:10,WD_TIMES:1,MC_REFORECAST:"2022-07-01",WD_ACTUAL_DATE:"2022-06-27",MC_APPROVED:"2022-07-02",WD_TO_MC_WK:0,WD_TO_MC_DAYS:5,BAR:5,MC_TARGET_WD_DATE:"2022-06-17",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:21,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P1",SUBSYSTEM:"21-6020-6022-04",SUBSYSTEM_DESCR:"PAS Remote IOs ,incl Junction Box and Multicore Cables MW02",WD_PLAN:"2022-06-17",WD_DELTA:10,WD_TIMES:1,MC_REFORECAST:"2022-07-01",WD_ACTUAL_DATE:"2022-06-27",MC_APPROVED:"2022-07-05",WD_TO_MC_WK:1,WD_TO_MC_DAYS:8,BAR:8,MC_TARGET_WD_DATE:"2022-06-17",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:22,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P1",SUBSYSTEM:"21-6020-6022-08",SUBSYSTEM_DESCR:"PAS Remote IOs ,incl Junction Box and Multicore Cables OFL00",WD_PLAN:"2022-07-24",WD_DELTA:-27,WD_TIMES:1,MC_REFORECAST:"2022-08-07",WD_ACTUAL_DATE:"2022-06-27",MC_APPROVED:"2022-07-06",WD_TO_MC_WK:1,WD_TO_MC_DAYS:9,BAR:9,MC_TARGET_WD_DATE:"2022-07-24",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:23,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P1",SUBSYSTEM:"21-6070-6074-03",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables ME02",WD_PLAN:"2022-06-17",WD_DELTA:10,WD_TIMES:1,MC_REFORECAST:"2022-07-01",WD_ACTUAL_DATE:"2022-06-27",MC_APPROVED:"2022-07-05",WD_TO_MC_WK:1,WD_TO_MC_DAYS:8,BAR:8,MC_TARGET_WD_DATE:"2022-06-17",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:24,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P2",SUBSYSTEM:"21-9810-9812-01",SUBSYSTEM_DESCR:"FPSO East Crane",WD_PLAN:"2022-06-17",WD_DELTA:10,WD_TIMES:1,MC_REFORECAST:"2022-07-01",WD_ACTUAL_DATE:"2022-06-27",MC_APPROVED:"2022-07-07",WD_TO_MC_WK:1,WD_TO_MC_DAYS:10,BAR:10,MC_TARGET_WD_DATE:"2022-06-17",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:25,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P2",SUBSYSTEM:"21-9810-9811-01",SUBSYSTEM_DESCR:"FPSO West Crane",WD_PLAN:"2022-06-17",WD_DELTA:7,WD_TIMES:1,MC_REFORECAST:"2022-07-01",WD_ACTUAL_DATE:"2022-06-24",MC_APPROVED:"2022-07-07",WD_TO_MC_WK:1,WD_TO_MC_DAYS:13,BAR:13,MC_TARGET_WD_DATE:"2022-06-17",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:26,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P3",SUBSYSTEM:"21-8130-8131-04",SUBSYSTEM_DESCR:"Fuel Gas Distribution to GTGs",WD_PLAN:"2022-06-30",WD_DELTA:-7,WD_TIMES:1,MC_REFORECAST:"2022-07-14",WD_ACTUAL_DATE:"2022-06-23",MC_APPROVED:"2022-07-08",WD_TO_MC_WK:2,WD_TO_MC_DAYS:15,BAR:15,MC_TARGET_WD_DATE:"2022-06-30",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:27,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P1",SUBSYSTEM:"21-6070-6074-02",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables MW01",WD_PLAN:"2022-06-17",WD_DELTA:6,WD_TIMES:1,MC_REFORECAST:"2022-07-01",WD_ACTUAL_DATE:"2022-06-23",MC_APPROVED:"2022-07-01",WD_TO_MC_WK:1,WD_TO_MC_DAYS:8,BAR:8,MC_TARGET_WD_DATE:"2022-06-17",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:28,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P3",SUBSYSTEM:"21-6800-6841-04",SUBSYSTEM_DESCR:"Fire Zone 6841-3 - Condensate stabilization and MEG regeneration and Produced Water treatment Module  - ME02",WD_PLAN:"2022-07-16",WD_DELTA:-23,WD_TIMES:1,MC_REFORECAST:"2022-07-30",WD_ACTUAL_DATE:"2022-06-23",MC_APPROVED:"2022-07-06",WD_TO_MC_WK:1,WD_TO_MC_DAYS:13,BAR:13,MC_TARGET_WD_DATE:"2022-07-16",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:29,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P1",SUBSYSTEM:"21-6010-6012-03",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables ME02",WD_PLAN:"2022-06-17",WD_DELTA:1,WD_TIMES:1,MC_REFORECAST:"2022-07-01",WD_ACTUAL_DATE:"2022-06-18",MC_APPROVED:"2022-07-01",WD_TO_MC_WK:1,WD_TO_MC_DAYS:13,BAR:13,MC_TARGET_WD_DATE:"2022-06-17",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:30,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P3",SUBSYSTEM:"21-2500-2562-01",SUBSYSTEM_DESCR:"MEG Transfer from Bunkering Station",WD_PLAN:"2022-06-23",WD_DELTA:-6,WD_TIMES:1,MC_REFORECAST:"2022-07-07",WD_ACTUAL_DATE:"2022-06-17",MC_APPROVED:"2022-07-01",WD_TO_MC_WK:2,WD_TO_MC_DAYS:14,BAR:14,MC_TARGET_WD_DATE:"2022-06-23",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:31,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P1",SUBSYSTEM:"21-8040-8040-01",SUBSYSTEM_DESCR:"Heating Medium ,Hot Water Expansion Tank",WD_PLAN:"2022-06-16",WD_DELTA:0,WD_TIMES:1,MC_REFORECAST:"2022-06-30",WD_ACTUAL_DATE:"2022-06-16",MC_APPROVED:"2022-07-05",WD_TO_MC_WK:2,WD_TO_MC_DAYS:19,BAR:19,MC_TARGET_WD_DATE:"2022-06-16",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:32,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P1",SUBSYSTEM:"21-9220-9224-01",SUBSYSTEM_DESCR:"Entertainment System",WD_PLAN:"2022-07-08",WD_DELTA:-24,WD_TIMES:1,MC_REFORECAST:"2022-07-22",WD_ACTUAL_DATE:"2022-06-14",MC_APPROVED:"2022-07-06",WD_TO_MC_WK:3,WD_TO_MC_DAYS:22,BAR:22,MC_TARGET_WD_DATE:"2022-07-08",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:33,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P2",SUBSYSTEM:"21-8300-8310-01",SUBSYSTEM_DESCR:"Corrosion Inhibitor Tank and Pumps",WD_PLAN:"2022-06-09",WD_DELTA:4,WD_TIMES:1,MC_REFORECAST:"2022-06-23",WD_ACTUAL_DATE:"2022-06-13",MC_APPROVED:"2022-07-04",WD_TO_MC_WK:3,WD_TO_MC_DAYS:21,BAR:21,MC_TARGET_WD_DATE:"2022-06-09",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:34,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P2",SUBSYSTEM:"21-8300-8320-04",SUBSYSTEM_DESCR:"Scale Inhibitor Tank and Pumps Subsea",WD_PLAN:"2022-06-09",WD_DELTA:2,WD_TIMES:1,MC_REFORECAST:"2022-06-23",WD_ACTUAL_DATE:"2022-06-11",MC_APPROVED:"2022-07-02",WD_TO_MC_WK:3,WD_TO_MC_DAYS:21,BAR:21,MC_TARGET_WD_DATE:"2022-06-09",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:35,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P2",SUBSYSTEM:"21-9370-9372-04",SUBSYSTEM_DESCR:"Slops Heaters 21-H-937001 AandB",WD_PLAN:"2022-06-07",WD_DELTA:3,WD_TIMES:1,MC_REFORECAST:"2022-06-21",WD_ACTUAL_DATE:"2022-06-10",MC_APPROVED:"2022-07-06",WD_TO_MC_WK:3,WD_TO_MC_DAYS:26,BAR:26,MC_TARGET_WD_DATE:"2022-06-07",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:36,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P2",SUBSYSTEM:"21-8300-8340-01",SUBSYSTEM_DESCR:"Biocide Tank and Pumps",WD_PLAN:"2022-06-09",WD_DELTA:-5,WD_TIMES:1,MC_REFORECAST:"2022-06-23",WD_ACTUAL_DATE:"2022-06-04",MC_APPROVED:"2022-07-04",WD_TO_MC_WK:4,WD_TO_MC_DAYS:30,BAR:30,MC_TARGET_WD_DATE:"2022-06-09",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:37,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P2",SUBSYSTEM:"21-9976-9976-01",SUBSYSTEM_DESCR:"Topsides Pressurisation Fans",WD_PLAN:"2022-06-24",WD_DELTA:-24,WD_TIMES:1,MC_REFORECAST:"2022-07-08",WD_ACTUAL_DATE:"2022-05-31",MC_APPROVED:"2022-07-01",WD_TO_MC_WK:4,WD_TO_MC_DAYS:31,BAR:31,MC_TARGET_WD_DATE:"2022-06-24",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:38,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P2",SUBSYSTEM:"21-9976-9977-02",SUBSYSTEM_DESCR:"Topsides HVAC AHUs, Ducting and Dampers",WD_PLAN:"2022-06-09",WD_DELTA:-9,WD_TIMES:1,MC_REFORECAST:"2022-06-23",WD_ACTUAL_DATE:"2022-05-31",MC_APPROVED:"2022-07-07",WD_TO_MC_WK:5,WD_TO_MC_DAYS:37,BAR:37,MC_TARGET_WD_DATE:"2022-06-09",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:39,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P3",SUBSYSTEM:"21-6800-6841-02",SUBSYSTEM_DESCR:"Fire Zone 6841-1 - Flash Gas Compression Module - ME01",WD_PLAN:"2022-06-30",WD_DELTA:-30,WD_TIMES:1,MC_REFORECAST:"2022-07-14",WD_ACTUAL_DATE:"2022-05-31",MC_APPROVED:"2022-07-05",WD_TO_MC_WK:5,WD_TO_MC_DAYS:35,BAR:35,MC_TARGET_WD_DATE:"2022-06-30",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:40,YEAR:2022,MON:7,MONTH:"July",PRIORITY:"P1B",SUBSYSTEM:"21-6030-6036-04",SUBSYSTEM_DESCR:"PDBs Inside ME05",WD_PLAN:"2022-04-14",WD_DELTA:-76,WD_TIMES:1,MC_REFORECAST:"2022-04-28",WD_ACTUAL_DATE:"2022-01-28",MC_APPROVED:"2022-07-06",WD_TO_MC_WK:22,WD_TO_MC_DAYS:159,BAR:159,MC_TARGET_WD_DATE:"2022-04-14",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:41,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P2",SUBSYSTEM:"21-8000-8013-01",SUBSYSTEM_DESCR:"Hot Water Calorifiers",WD_PLAN:"2022-06-09",WD_DELTA:29,WD_TIMES:2,MC_REFORECAST:"2022-06-23",WD_ACTUAL_DATE:"2022-07-08",MC_APPROVED:"2022-06-30",WD_TO_MC_WK:-1,WD_TO_MC_DAYS:-8,BAR:-8,MC_TARGET_WD_DATE:"2022-06-09",WD_ACTUAL:"DONE-NO PL"},
      {NO:42,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-6070-6074-04",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables MW02",WD_PLAN:"2022-06-17",WD_DELTA:5,WD_TIMES:1,MC_REFORECAST:"2022-07-01",WD_ACTUAL_DATE:"2022-06-22",MC_APPROVED:"2022-06-29",WD_TO_MC_WK:1,WD_TO_MC_DAYS:7,BAR:7,MC_TARGET_WD_DATE:"2022-06-17",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:43,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-6020-6022-01",SUBSYSTEM_DESCR:"PAS Remote IOs,incl Junction Box and Multicore Cables ME01",WD_PLAN:"2022-06-17",WD_DELTA:5,WD_TIMES:1,MC_REFORECAST:"2022-07-01",WD_ACTUAL_DATE:"2022-06-22",MC_APPROVED:"2022-06-29",WD_TO_MC_WK:1,WD_TO_MC_DAYS:7,BAR:7,MC_TARGET_WD_DATE:"2022-06-17",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:44,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-9500-9536-01",SUBSYSTEM_DESCR:"LANandWAN ,Data Network Infrastructure",WD_PLAN:"2022-05-03",WD_DELTA:48,WD_TIMES:2,MC_REFORECAST:"2022-05-17",WD_ACTUAL_DATE:"2022-06-20",MC_APPROVED:"2022-06-28",WD_TO_MC_WK:1,WD_TO_MC_DAYS:8,BAR:8,MC_TARGET_WD_DATE:"2022-05-03",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:45,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-6030-6042-01",SUBSYSTEM_DESCR:"Corrosion and Erosion Monitoring",WD_PLAN:"2022-07-08",WD_DELTA:-20,WD_TIMES:1,MC_REFORECAST:"2022-07-22",WD_ACTUAL_DATE:"2022-06-18",MC_APPROVED:"2022-06-29",WD_TO_MC_WK:1,WD_TO_MC_DAYS:11,BAR:11,MC_TARGET_WD_DATE:"2022-07-08",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:46,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P2",SUBSYSTEM:"21-6500-6520-02",SUBSYSTEM_DESCR:"Helideck Lights Control Panel and Associated Beacons",WD_PLAN:"2022-07-16",WD_DELTA:-29,WD_TIMES:1,MC_REFORECAST:"2022-07-30",WD_ACTUAL_DATE:"2022-06-17",MC_APPROVED:"2022-06-24",WD_TO_MC_WK:1,WD_TO_MC_DAYS:7,BAR:7,MC_TARGET_WD_DATE:"2022-07-16",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:47,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-9500-9535-02",SUBSYSTEM_DESCR:"Subsea FO Termination Unit Cables and Junction Box",WD_PLAN:"2022-06-17",WD_DELTA:0,WD_TIMES:1,MC_REFORECAST:"2022-07-01",WD_ACTUAL_DATE:"2022-06-17",MC_APPROVED:"2022-06-28",WD_TO_MC_WK:1,WD_TO_MC_DAYS:11,BAR:11,MC_TARGET_WD_DATE:"2022-06-17",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:48,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P3",SUBSYSTEM:"21-8300-8310-02",SUBSYSTEM_DESCR:"Corrosion Inhibitor Distribution",WD_PLAN:"2022-06-09",WD_DELTA:8,WD_TIMES:2,MC_REFORECAST:"2022-06-23",WD_ACTUAL_DATE:"2022-06-17",MC_APPROVED:"2022-06-27",WD_TO_MC_WK:1,WD_TO_MC_DAYS:10,BAR:10,MC_TARGET_WD_DATE:"2022-06-09",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:49,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P3",SUBSYSTEM:"21-6800-6854-01",SUBSYSTEM_DESCR:"Fire Zone 6854 - Transit Refuge Area",WD_PLAN:"2022-07-28",WD_DELTA:-42,WD_TIMES:1,MC_REFORECAST:"2022-08-11",WD_ACTUAL_DATE:"2022-06-16",MC_APPROVED:"2022-06-23",WD_TO_MC_WK:1,WD_TO_MC_DAYS:7,BAR:7,MC_TARGET_WD_DATE:"2022-07-28",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:50,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-6010-6012-02",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables MW01",WD_PLAN:"2022-06-17",WD_DELTA:-1,WD_TIMES:1,MC_REFORECAST:"2022-07-01",WD_ACTUAL_DATE:"2022-06-16",MC_APPROVED:"2022-06-22",WD_TO_MC_WK:0,WD_TO_MC_DAYS:6,BAR:6,MC_TARGET_WD_DATE:"2022-06-17",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:51,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P3",SUBSYSTEM:"21-9370-9372-01",SUBSYSTEM_DESCR:"Hazardous Open Drain Header to Slops Tanks",WD_PLAN:"2022-07-01",WD_DELTA:-16,WD_TIMES:1,MC_REFORECAST:"2022-07-15",WD_ACTUAL_DATE:"2022-06-15",MC_APPROVED:"2022-06-17",WD_TO_MC_WK:0,WD_TO_MC_DAYS:2,BAR:2,MC_TARGET_WD_DATE:"2022-07-01",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:52,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-9500-9570-04",SUBSYSTEM_DESCR:"Video Wall System",WD_PLAN:"2022-06-09",WD_DELTA:5,WD_TIMES:1,MC_REFORECAST:"2022-06-23",WD_ACTUAL_DATE:"2022-06-14",MC_APPROVED:"2022-06-28",WD_TO_MC_WK:2,WD_TO_MC_DAYS:14,BAR:14,MC_TARGET_WD_DATE:"2022-06-09",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:53,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P3",SUBSYSTEM:"21-8300-8350-02",SUBSYSTEM_DESCR:"Antifoam Distribution",WD_PLAN:"2022-06-30",WD_DELTA:-16,WD_TIMES:1,MC_REFORECAST:"2022-07-14",WD_ACTUAL_DATE:"2022-06-14",MC_APPROVED:"2022-06-29",WD_TO_MC_WK:2,WD_TO_MC_DAYS:15,BAR:15,MC_TARGET_WD_DATE:"2022-06-30",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:54,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P3",SUBSYSTEM:"21-8300-8360-02",SUBSYSTEM_DESCR:"Emulsion Breaker Distribution",WD_PLAN:"2022-06-30",WD_DELTA:-16,WD_TIMES:1,MC_REFORECAST:"2022-07-14",WD_ACTUAL_DATE:"2022-06-14",MC_APPROVED:"2022-06-29",WD_TO_MC_WK:2,WD_TO_MC_DAYS:15,BAR:15,MC_TARGET_WD_DATE:"2022-06-30",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:55,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-8060-8063-01",SUBSYSTEM_DESCR:"Diesel Distribution Header to Users",WD_PLAN:"2022-06-01",WD_DELTA:13,WD_TIMES:1,MC_REFORECAST:"2022-06-15",WD_ACTUAL_DATE:"2022-06-14",MC_APPROVED:"2022-06-28",WD_TO_MC_WK:2,WD_TO_MC_DAYS:14,BAR:14,MC_TARGET_WD_DATE:"2022-06-01",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:56,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P3",SUBSYSTEM:"21-8090-8090-02",SUBSYSTEM_DESCR:"Sewage Collection and Discharge Headers",WD_PLAN:"2022-06-02",WD_DELTA:12,WD_TIMES:1,MC_REFORECAST:"2022-06-16",WD_ACTUAL_DATE:"2022-06-14",MC_APPROVED:"2022-06-16",WD_TO_MC_WK:0,WD_TO_MC_DAYS:2,BAR:2,MC_TARGET_WD_DATE:"2022-06-02",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:57,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P3",SUBSYSTEM:"21-9370-9372-10",SUBSYSTEM_DESCR:"Treated Slops Header to Overboard",WD_PLAN:"2022-06-07",WD_DELTA:7,WD_TIMES:1,MC_REFORECAST:"2022-06-21",WD_ACTUAL_DATE:"2022-06-14",MC_APPROVED:"2022-06-28",WD_TO_MC_WK:2,WD_TO_MC_DAYS:14,BAR:14,MC_TARGET_WD_DATE:"2022-06-07",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:58,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P3",SUBSYSTEM:"21-8300-8375-02",SUBSYSTEM_DESCR:"MEG Antifoam Distribution",WD_PLAN:"2022-06-30",WD_DELTA:-16,WD_TIMES:1,MC_REFORECAST:"2022-07-14",WD_ACTUAL_DATE:"2022-06-14",MC_APPROVED:"2022-06-22",WD_TO_MC_WK:1,WD_TO_MC_DAYS:8,BAR:8,MC_TARGET_WD_DATE:"2022-06-30",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:59,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-4000-4130-03",SUBSYSTEM_DESCR:"Demulsifier Injection Package",WD_PLAN:"2022-07-14",WD_DELTA:-31,WD_TIMES:1,MC_REFORECAST:"2022-07-28",WD_ACTUAL_DATE:"2022-06-13",MC_APPROVED:"2022-06-28",WD_TO_MC_WK:2,WD_TO_MC_DAYS:15,BAR:15,MC_TARGET_WD_DATE:"2022-07-14",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:60,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P2",SUBSYSTEM:"21-8300-8320-01",SUBSYSTEM_DESCR:"Scale Inhibitor Tank and Pumps Topsides",WD_PLAN:"2022-06-09",WD_DELTA:2,WD_TIMES:1,MC_REFORECAST:"2022-06-23",WD_ACTUAL_DATE:"2022-06-11",MC_APPROVED:"2022-06-22",WD_TO_MC_WK:1,WD_TO_MC_DAYS:11,BAR:11,MC_TARGET_WD_DATE:"2022-06-09",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:61,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P3",SUBSYSTEM:"21-8300-8320-03",SUBSYSTEM_DESCR:"Scale Inhibitor Distribution Topsides",WD_PLAN:"2022-06-01",WD_DELTA:10,WD_TIMES:1,MC_REFORECAST:"2022-06-15",WD_ACTUAL_DATE:"2022-06-11",MC_APPROVED:"2022-06-15",WD_TO_MC_WK:0,WD_TO_MC_DAYS:4,BAR:4,MC_TARGET_WD_DATE:"2022-06-01",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:62,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P3",SUBSYSTEM:"21-8300-8370-02",SUBSYSTEM_DESCR:"MEG PH Stabilizer Distribution",WD_PLAN:"2022-06-01",WD_DELTA:9,WD_TIMES:1,MC_REFORECAST:"2022-06-15",WD_ACTUAL_DATE:"2022-06-10",MC_APPROVED:"2022-06-15",WD_TO_MC_WK:0,WD_TO_MC_DAYS:5,BAR:5,MC_TARGET_WD_DATE:"2022-06-01",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:63,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-9500-9554-01",SUBSYSTEM_DESCR:"Meterological METOCEAN System",WD_PLAN:"2022-06-17",WD_DELTA:-7,WD_TIMES:1,MC_REFORECAST:"2022-07-01",WD_ACTUAL_DATE:"2022-06-10",MC_APPROVED:"2022-06-29",WD_TO_MC_WK:2,WD_TO_MC_DAYS:19,BAR:19,MC_TARGET_WD_DATE:"2022-06-17",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:64,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P2",SUBSYSTEM:"21-8150-8150-02",SUBSYSTEM_DESCR:"Topside Nitrogen Distribution",WD_PLAN:"2022-06-16",WD_DELTA:-7,WD_TIMES:1,MC_REFORECAST:"2022-06-30",WD_ACTUAL_DATE:"2022-06-09",MC_APPROVED:"2022-06-29",WD_TO_MC_WK:2,WD_TO_MC_DAYS:20,BAR:20,MC_TARGET_WD_DATE:"2022-06-16",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:65,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-8050-8052-03",SUBSYSTEM_DESCR:"Topsides Cooling Medium Expansion Tank",WD_PLAN:"2022-06-02",WD_DELTA:7,WD_TIMES:1,MC_REFORECAST:"2022-06-16",WD_ACTUAL_DATE:"2022-06-09",MC_APPROVED:"2022-06-30",WD_TO_MC_WK:3,WD_TO_MC_DAYS:21,BAR:21,MC_TARGET_WD_DATE:"2022-06-02",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:66,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-6200-6227-01",SUBSYSTEM_DESCR:"Crane Radio System",WD_PLAN:"2022-06-17",WD_DELTA:-8,WD_TIMES:1,MC_REFORECAST:"2022-07-01",WD_ACTUAL_DATE:"2022-06-09",MC_APPROVED:"2022-06-23",WD_TO_MC_WK:2,WD_TO_MC_DAYS:14,BAR:14,MC_TARGET_WD_DATE:"2022-06-17",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:67,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-8050-8052-02",SUBSYSTEM_DESCR:"Start-up Cooling Medium Pumps - 21-P805003",WD_PLAN:"2022-06-02",WD_DELTA:6,WD_TIMES:1,MC_REFORECAST:"2022-06-16",WD_ACTUAL_DATE:"2022-06-08",MC_APPROVED:"2022-06-28",WD_TO_MC_WK:2,WD_TO_MC_DAYS:20,BAR:20,MC_TARGET_WD_DATE:"2022-06-02",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:68,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-9500-9525-01",SUBSYSTEM_DESCR:"Microwave Line of Sight",WD_PLAN:"2022-07-08",WD_DELTA:-30,WD_TIMES:1,MC_REFORECAST:"2022-07-22",WD_ACTUAL_DATE:"2022-06-08",MC_APPROVED:"2022-06-20",WD_TO_MC_WK:1,WD_TO_MC_DAYS:12,BAR:12,MC_TARGET_WD_DATE:"2022-07-08",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:69,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P3",SUBSYSTEM:"21-6800-6841-03",SUBSYSTEM_DESCR:"Fire Zone 6841-2 - Gas Dewpointing andMEG and Methanol injection and Fuel gas Module - MW02",WD_PLAN:"2022-07-14",WD_DELTA:-37,WD_TIMES:1,MC_REFORECAST:"2022-07-28",WD_ACTUAL_DATE:"2022-06-07",MC_APPROVED:"2022-06-30",WD_TO_MC_WK:3,WD_TO_MC_DAYS:23,BAR:23,MC_TARGET_WD_DATE:"2022-07-14",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:70,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-6800-6840-06",SUBSYSTEM_DESCR:"Fire Zone 6840-6 - Gas Turbine Generator Package 21-Z-861401",WD_PLAN:"2022-05-27",WD_DELTA:11,WD_TIMES:1,MC_REFORECAST:"2022-06-10",WD_ACTUAL_DATE:"2022-06-07",MC_APPROVED:"2022-06-10",WD_TO_MC_WK:0,WD_TO_MC_DAYS:3,BAR:3,MC_TARGET_WD_DATE:"2022-05-27",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:71,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-6800-6840-05",SUBSYSTEM_DESCR:"Fire Zone 6840-5 - Gas Turbine Generator Package 21-Z-861301",WD_PLAN:"2022-05-27",WD_DELTA:10,WD_TIMES:1,MC_REFORECAST:"2022-06-10",WD_ACTUAL_DATE:"2022-06-06",MC_APPROVED:"2022-06-10",WD_TO_MC_WK:0,WD_TO_MC_DAYS:4,BAR:4,MC_TARGET_WD_DATE:"2022-05-27",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:72,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-6200-6210-03",SUBSYSTEM_DESCR:"PAGA Field Equipment ME02andMW02",WD_PLAN:"2022-06-17",WD_DELTA:-11,WD_TIMES:1,MC_REFORECAST:"2022-07-01",WD_ACTUAL_DATE:"2022-06-06",MC_APPROVED:"2022-06-29",WD_TO_MC_WK:3,WD_TO_MC_DAYS:23,BAR:23,MC_TARGET_WD_DATE:"2022-06-17",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:73,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P3",SUBSYSTEM:"21-8300-8380-01",SUBSYSTEM_DESCR:"Spare and Future Tanks",WD_PLAN:"2022-06-01",WD_DELTA:3,WD_TIMES:1,MC_REFORECAST:"2022-06-15",WD_ACTUAL_DATE:"2022-06-04",MC_APPROVED:"2022-06-15",WD_TO_MC_WK:1,WD_TO_MC_DAYS:11,BAR:11,MC_TARGET_WD_DATE:"2022-06-01",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:74,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1A",SUBSYSTEM:"21-9330-9330-02",SUBSYSTEM_DESCR:"Main Ballast Ring Main",WD_PLAN:"2022-05-30",WD_DELTA:4,WD_TIMES:1,MC_REFORECAST:"2022-06-13",WD_ACTUAL_DATE:"2022-06-03",MC_APPROVED:"2022-06-29",WD_TO_MC_WK:3,WD_TO_MC_DAYS:26,BAR:26,MC_TARGET_WD_DATE:"2022-05-30",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:75,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P2",SUBSYSTEM:"21-9380-9380-07",SUBSYSTEM_DESCR:"Daily Bilge for CFC5 and CFC6 and Pump 21-P-938004",WD_PLAN:"2022-06-30",WD_DELTA:-27,WD_TIMES:1,MC_REFORECAST:"2022-07-14",WD_ACTUAL_DATE:"2022-06-03",MC_APPROVED:"2022-06-29",WD_TO_MC_WK:3,WD_TO_MC_DAYS:26,BAR:26,MC_TARGET_WD_DATE:"2022-06-30",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:76,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P2",SUBSYSTEM:"21-9380-9380-08",SUBSYSTEM_DESCR:"Daily Bilge for CFC7 and Pump 21-P-938005",WD_PLAN:"2022-06-30",WD_DELTA:-27,WD_TIMES:1,MC_REFORECAST:"2022-07-14",WD_ACTUAL_DATE:"2022-06-03",MC_APPROVED:"2022-06-28",WD_TO_MC_WK:3,WD_TO_MC_DAYS:25,BAR:25,MC_TARGET_WD_DATE:"2022-06-30",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:77,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P2",SUBSYSTEM:"21-6600-6640-05",SUBSYSTEM_DESCR:"Panel Control For External Lighting - 21-ECP-667680",WD_PLAN:"2022-05-26",WD_DELTA:8,WD_TIMES:1,MC_REFORECAST:"2022-06-09",WD_ACTUAL_DATE:"2022-06-03",MC_APPROVED:"2022-06-09",WD_TO_MC_WK:0,WD_TO_MC_DAYS:6,BAR:6,MC_TARGET_WD_DATE:"2022-05-26",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:78,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P2",SUBSYSTEM:"21-6300-6320-01",SUBSYSTEM_DESCR:"Lifeboats",WD_PLAN:"2022-07-16",WD_DELTA:-44,WD_TIMES:1,MC_REFORECAST:"2022-07-30",WD_ACTUAL_DATE:"2022-06-02",MC_APPROVED:"2022-06-24",WD_TO_MC_WK:3,WD_TO_MC_DAYS:22,BAR:22,MC_TARGET_WD_DATE:"2022-07-16",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:79,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P2",SUBSYSTEM:"21-6800-6842-13",SUBSYSTEM_DESCR:"Fire Zone 6843-3 - Emergency Diesel Generators",WD_PLAN:"2022-05-23",WD_DELTA:10,WD_TIMES:1,MC_REFORECAST:"2022-06-06",WD_ACTUAL_DATE:"2022-06-02",MC_APPROVED:"2022-06-06",WD_TO_MC_WK:0,WD_TO_MC_DAYS:4,BAR:4,MC_TARGET_WD_DATE:"2022-05-23",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:80,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P3",SUBSYSTEM:"21-2200-2230-02",SUBSYSTEM_DESCR:"Interstage Heaters",WD_PLAN:"2022-05-25",WD_DELTA:8,WD_TIMES:1,MC_REFORECAST:"2022-06-08",WD_ACTUAL_DATE:"2022-06-02",MC_APPROVED:"2022-06-08",WD_TO_MC_WK:0,WD_TO_MC_DAYS:6,BAR:6,MC_TARGET_WD_DATE:"2022-05-25",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:81,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P2",SUBSYSTEM:"21-2200-2470-01",SUBSYSTEM_DESCR:"Warm Condensate Heater, Offspec Condensate Pump and Distribution",WD_PLAN:"2022-05-26",WD_DELTA:7,WD_TIMES:1,MC_REFORECAST:"2022-06-09",WD_ACTUAL_DATE:"2022-06-02",MC_APPROVED:"2022-06-09",WD_TO_MC_WK:1,WD_TO_MC_DAYS:7,BAR:7,MC_TARGET_WD_DATE:"2022-05-26",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:82,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P2",SUBSYSTEM:"21-8400-8410-01",SUBSYSTEM_DESCR:"O2 Scavanger Tank and Pumps",WD_PLAN:"2022-06-02",WD_DELTA:0,WD_TIMES:1,MC_REFORECAST:"2022-06-16",WD_ACTUAL_DATE:"2022-06-02",MC_APPROVED:"2022-06-28",WD_TO_MC_WK:3,WD_TO_MC_DAYS:26,BAR:26,MC_TARGET_WD_DATE:"2022-06-02",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:83,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P2",SUBSYSTEM:"21-8300-8375-01",SUBSYSTEM_DESCR:"MEG Antifoam Tank and Pumps",WD_PLAN:"2022-06-09",WD_DELTA:-7,WD_TIMES:1,MC_REFORECAST:"2022-06-23",WD_ACTUAL_DATE:"2022-06-02",MC_APPROVED:"2022-06-27",WD_TO_MC_WK:3,WD_TO_MC_DAYS:25,BAR:25,MC_TARGET_WD_DATE:"2022-06-09",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:84,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P2",SUBSYSTEM:"21-8300-8370-01",SUBSYSTEM_DESCR:"MEG PH Stabilizer Tank and Pumps",WD_PLAN:"2022-06-02",WD_DELTA:0,WD_TIMES:1,MC_REFORECAST:"2022-06-16",WD_ACTUAL_DATE:"2022-06-02",MC_APPROVED:"2022-06-27",WD_TO_MC_WK:3,WD_TO_MC_DAYS:25,BAR:25,MC_TARGET_WD_DATE:"2022-06-02",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:85,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P2",SUBSYSTEM:"21-8300-8360-01",SUBSYSTEM_DESCR:"Emulsion Breaker Tank and Pumps",WD_PLAN:"2022-06-09",WD_DELTA:-7,WD_TIMES:1,MC_REFORECAST:"2022-06-23",WD_ACTUAL_DATE:"2022-06-02",MC_APPROVED:"2022-06-28",WD_TO_MC_WK:3,WD_TO_MC_DAYS:26,BAR:26,MC_TARGET_WD_DATE:"2022-06-09",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:86,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P2",SUBSYSTEM:"21-8300-8350-01",SUBSYSTEM_DESCR:"Antifoam Tank and Pumps",WD_PLAN:"2022-06-09",WD_DELTA:-7,WD_TIMES:1,MC_REFORECAST:"2022-06-23",WD_ACTUAL_DATE:"2022-06-02",MC_APPROVED:"2022-06-28",WD_TO_MC_WK:3,WD_TO_MC_DAYS:26,BAR:26,MC_TARGET_WD_DATE:"2022-06-09",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:87,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-9500-9555-01",SUBSYSTEM_DESCR:"Berthing Aids and Approach System",WD_PLAN:"2022-06-17",WD_DELTA:-15,WD_TIMES:1,MC_REFORECAST:"2022-07-01",WD_ACTUAL_DATE:"2022-06-02",MC_APPROVED:"2022-06-17",WD_TO_MC_WK:2,WD_TO_MC_DAYS:15,BAR:15,MC_TARGET_WD_DATE:"2022-06-17",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:88,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-9500-9570-03",SUBSYSTEM_DESCR:"Hand held satellite phone",WD_PLAN:"2022-07-07",WD_DELTA:-35,WD_TIMES:1,MC_REFORECAST:"2022-07-21",WD_ACTUAL_DATE:"2022-06-02",MC_APPROVED:"2022-06-21",WD_TO_MC_WK:2,WD_TO_MC_DAYS:19,BAR:19,MC_TARGET_WD_DATE:"2022-07-07",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:89,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-9976-9977-01",SUBSYSTEM_DESCR:"Topsides HVAC UCPs, EDBs",WD_PLAN:"2022-05-23",WD_DELTA:9,WD_TIMES:1,MC_REFORECAST:"2022-06-06",WD_ACTUAL_DATE:"2022-06-01",MC_APPROVED:"2022-06-06",WD_TO_MC_WK:0,WD_TO_MC_DAYS:5,BAR:5,MC_TARGET_WD_DATE:"2022-05-23",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:90,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P2",SUBSYSTEM:"21-8100-8122-04",SUBSYSTEM_DESCR:"Topside Air Driers 21-Z-812001",WD_PLAN:"2022-06-02",WD_DELTA:-1,WD_TIMES:1,MC_REFORECAST:"2022-06-16",WD_ACTUAL_DATE:"2022-06-01",MC_APPROVED:"2022-06-24",WD_TO_MC_WK:3,WD_TO_MC_DAYS:23,BAR:23,MC_TARGET_WD_DATE:"2022-06-02",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:91,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1A",SUBSYSTEM:"21-9330-9330-06",SUBSYSTEM_DESCR:"Ballast Pump 21-P-935001 D",WD_PLAN:"2022-06-01",WD_DELTA:0,WD_TIMES:1,MC_REFORECAST:"2022-06-15",WD_ACTUAL_DATE:"2022-06-01",MC_APPROVED:"2022-06-23",WD_TO_MC_WK:3,WD_TO_MC_DAYS:22,BAR:22,MC_TARGET_WD_DATE:"2022-06-01",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:92,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-9370-9372-08",SUBSYSTEM_DESCR:"COW Hot Sea Water Filter 21-F-937001",WD_PLAN:"2022-06-07",WD_DELTA:-6,WD_TIMES:1,MC_REFORECAST:"2022-06-21",WD_ACTUAL_DATE:"2022-06-01",MC_APPROVED:"2022-06-20",WD_TO_MC_WK:2,WD_TO_MC_DAYS:19,BAR:19,MC_TARGET_WD_DATE:"2022-06-07",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:93,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-6030-6039-01",SUBSYSTEM_DESCR:"BallastandVoid Gas Sampling",WD_PLAN:"2022-06-02",WD_DELTA:-1,WD_TIMES:1,MC_REFORECAST:"2022-06-16",WD_ACTUAL_DATE:"2022-06-01",MC_APPROVED:"2022-06-16",WD_TO_MC_WK:2,WD_TO_MC_DAYS:15,BAR:15,MC_TARGET_WD_DATE:"2022-06-02",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:94,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P3",SUBSYSTEM:"21-6800-6841-01",SUBSYSTEM_DESCR:"Fire Zone 6841-0 - Reception Facilities and Production Separation Module - MW01",WD_PLAN:"2022-06-23",WD_DELTA:-23,WD_TIMES:1,MC_REFORECAST:"2022-07-07",WD_ACTUAL_DATE:"2022-05-31",MC_APPROVED:"2022-06-30",WD_TO_MC_WK:4,WD_TO_MC_DAYS:30,BAR:30,MC_TARGET_WD_DATE:"2022-06-23",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:95,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-6200-6224-01",SUBSYSTEM_DESCR:"Digital UHF Network",WD_PLAN:"2022-06-09",WD_DELTA:-9,WD_TIMES:1,MC_REFORECAST:"2022-06-23",WD_ACTUAL_DATE:"2022-05-31",MC_APPROVED:"2022-06-15",WD_TO_MC_WK:2,WD_TO_MC_DAYS:15,BAR:15,MC_TARGET_WD_DATE:"2022-06-09",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:96,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1A",SUBSYSTEM:"21-9330-9334-01",SUBSYSTEM_DESCR:"HULL Valves HPU",WD_PLAN:"2022-02-11",WD_DELTA:109,WD_TIMES:2,MC_REFORECAST:"2022-02-25",WD_ACTUAL_DATE:"2022-05-31",MC_APPROVED:"2022-06-08",WD_TO_MC_WK:1,WD_TO_MC_DAYS:8,BAR:8,MC_TARGET_WD_DATE:"2022-02-11",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:97,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1A",SUBSYSTEM:"21-9330-9330-03",SUBSYSTEM_DESCR:"Ballast Pump 21-P-935001 A",WD_PLAN:"2022-06-01",WD_DELTA:-1,WD_TIMES:1,MC_REFORECAST:"2022-06-15",WD_ACTUAL_DATE:"2022-05-31",MC_APPROVED:"2022-06-20",WD_TO_MC_WK:2,WD_TO_MC_DAYS:20,BAR:20,MC_TARGET_WD_DATE:"2022-06-01",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:98,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1A",SUBSYSTEM:"21-9330-9330-05",SUBSYSTEM_DESCR:"Ballast Pump 21-P-935001 C",WD_PLAN:"2022-06-01",WD_DELTA:-2,WD_TIMES:1,MC_REFORECAST:"2022-06-15",WD_ACTUAL_DATE:"2022-05-30",MC_APPROVED:"2022-06-15",WD_TO_MC_WK:2,WD_TO_MC_DAYS:16,BAR:16,MC_TARGET_WD_DATE:"2022-06-01",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:99,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-8100-8122-05",SUBSYSTEM_DESCR:"Topside Air Receiver 21-V-812001, Main header and Controls",WD_PLAN:"2022-06-01",WD_DELTA:-2,WD_TIMES:1,MC_REFORECAST:"2022-06-15",WD_ACTUAL_DATE:"2022-05-30",MC_APPROVED:"2022-06-22",WD_TO_MC_WK:3,WD_TO_MC_DAYS:23,BAR:23,MC_TARGET_WD_DATE:"2022-06-01",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:100,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P2",SUBSYSTEM:"21-8150-8150-01",SUBSYSTEM_DESCR:"Nitrogen Generator and Receiver",WD_PLAN:"2022-06-01",WD_DELTA:-2,WD_TIMES:1,MC_REFORECAST:"2022-06-15",WD_ACTUAL_DATE:"2022-05-30",MC_APPROVED:"2022-06-24",WD_TO_MC_WK:3,WD_TO_MC_DAYS:25,BAR:25,MC_TARGET_WD_DATE:"2022-06-01",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:101,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-9976-9976-03",SUBSYSTEM_DESCR:"Emergency Room ACUsandACCUs and UCPs",WD_PLAN:"2022-06-10",WD_DELTA:-11,WD_TIMES:1,MC_REFORECAST:"2022-06-24",WD_ACTUAL_DATE:"2022-05-30",MC_APPROVED:"2022-06-23",WD_TO_MC_WK:3,WD_TO_MC_DAYS:24,BAR:24,MC_TARGET_WD_DATE:"2022-06-10",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:102,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-9500-9527-01",SUBSYSTEM_DESCR:"INMARSAT Terminal and Portable Sat Phones",WD_PLAN:"2022-08-04",WD_DELTA:-66,WD_TIMES:1,MC_REFORECAST:"2022-08-18",WD_ACTUAL_DATE:"2022-05-30",MC_APPROVED:"2022-06-21",WD_TO_MC_WK:3,WD_TO_MC_DAYS:22,BAR:22,MC_TARGET_WD_DATE:"2022-08-04",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:103,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P2",SUBSYSTEM:"21-8000-8010-01",SUBSYSTEM_DESCR:"Potable Water Maker",WD_PLAN:"2022-06-02",WD_DELTA:-3,WD_TIMES:1,MC_REFORECAST:"2022-06-16",WD_ACTUAL_DATE:"2022-05-30",MC_APPROVED:"2022-06-15",WD_TO_MC_WK:2,WD_TO_MC_DAYS:16,BAR:16,MC_TARGET_WD_DATE:"2022-06-02",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:104,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P2",SUBSYSTEM:"21-8100-8122-01",SUBSYSTEM_DESCR:"Topside Air Compressor 21-Z-811101",WD_PLAN:"2022-06-01",WD_DELTA:-4,WD_TIMES:1,MC_REFORECAST:"2022-06-15",WD_ACTUAL_DATE:"2022-05-28",MC_APPROVED:"2022-06-22",WD_TO_MC_WK:3,WD_TO_MC_DAYS:25,BAR:25,MC_TARGET_WD_DATE:"2022-06-01",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:105,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-6800-6840-02",SUBSYSTEM_DESCR:"Fire Zone 6840-2 - Power Generation Module- ME04 ,Open Area",WD_PLAN:"2022-05-30",WD_DELTA:-3,WD_TIMES:1,MC_REFORECAST:"2022-06-13",WD_ACTUAL_DATE:"2022-05-27",MC_APPROVED:"2022-06-13",WD_TO_MC_WK:2,WD_TO_MC_DAYS:17,BAR:17,MC_TARGET_WD_DATE:"2022-05-30",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:106,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-6800-6840-07",SUBSYSTEM_DESCR:"Fire Zone 6840-7 - Utility Module–MW05",WD_PLAN:"2022-06-02",WD_DELTA:-7,WD_TIMES:1,MC_REFORECAST:"2022-06-16",WD_ACTUAL_DATE:"2022-05-26",MC_APPROVED:"2022-06-10",WD_TO_MC_WK:2,WD_TO_MC_DAYS:15,BAR:15,MC_TARGET_WD_DATE:"2022-06-02",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:107,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-6200-6225-01",SUBSYSTEM_DESCR:"VHF ,AM Aeronautical Radio",WD_PLAN:"2022-05-19",WD_DELTA:7,WD_TIMES:1,MC_REFORECAST:"2022-06-02",WD_ACTUAL_DATE:"2022-05-26",MC_APPROVED:"2022-06-02",WD_TO_MC_WK:1,WD_TO_MC_DAYS:7,BAR:7,MC_TARGET_WD_DATE:"2022-05-19",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:108,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-6200-6228-01",SUBSYSTEM_DESCR:"VHF ,FM Marine Radio",WD_PLAN:"2022-07-28",WD_DELTA:-63,WD_TIMES:1,MC_REFORECAST:"2022-08-11",WD_ACTUAL_DATE:"2022-05-26",MC_APPROVED:"2022-06-24",WD_TO_MC_WK:4,WD_TO_MC_DAYS:29,BAR:29,MC_TARGET_WD_DATE:"2022-07-28",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:109,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P2",SUBSYSTEM:"21-8100-8122-02",SUBSYSTEM_DESCR:"Topside Air Compressor 21-Z-811201",WD_PLAN:"2022-06-01",WD_DELTA:-6,WD_TIMES:1,MC_REFORECAST:"2022-06-15",WD_ACTUAL_DATE:"2022-05-26",MC_APPROVED:"2022-06-22",WD_TO_MC_WK:3,WD_TO_MC_DAYS:27,BAR:27,MC_TARGET_WD_DATE:"2022-06-01",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:110,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P2",SUBSYSTEM:"21-8100-8122-03",SUBSYSTEM_DESCR:"Topside Air Compressor 21-Z-811301",WD_PLAN:"2022-06-01",WD_DELTA:-6,WD_TIMES:1,MC_REFORECAST:"2022-06-15",WD_ACTUAL_DATE:"2022-05-26",MC_APPROVED:"2022-06-22",WD_TO_MC_WK:3,WD_TO_MC_DAYS:27,BAR:27,MC_TARGET_WD_DATE:"2022-06-01",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:111,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1A",SUBSYSTEM:"21-9330-9330-04",SUBSYSTEM_DESCR:"Ballast Pump 21-P-935001 B",WD_PLAN:"2022-06-01",WD_DELTA:-6,WD_TIMES:1,MC_REFORECAST:"2022-06-15",WD_ACTUAL_DATE:"2022-05-26",MC_APPROVED:"2022-06-20",WD_TO_MC_WK:3,WD_TO_MC_DAYS:25,BAR:25,MC_TARGET_WD_DATE:"2022-06-01",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:112,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P2",SUBSYSTEM:"21-9976-9977-03",SUBSYSTEM_DESCR:"Topsides HVAC Extracts, Ducting and Dampers",WD_PLAN:"2022-05-26",WD_DELTA:0,WD_TIMES:1,MC_REFORECAST:"2022-06-09",WD_ACTUAL_DATE:"2022-05-26",MC_APPROVED:"2022-06-09",WD_TO_MC_WK:2,WD_TO_MC_DAYS:14,BAR:14,MC_TARGET_WD_DATE:"2022-05-26",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:113,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-8600-8612-01",SUBSYSTEM_DESCR:"GTG 2 and WHRU2",WD_PLAN:"2022-06-03",WD_DELTA:-9,WD_TIMES:1,MC_REFORECAST:"2022-06-17",WD_ACTUAL_DATE:"2022-05-25",MC_APPROVED:"2022-06-20",WD_TO_MC_WK:3,WD_TO_MC_DAYS:26,BAR:26,MC_TARGET_WD_DATE:"2022-06-03",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:114,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P3",SUBSYSTEM:"21-9370-9372-11",SUBSYSTEM_DESCR:"LQ Open Drain Collection",WD_PLAN:"2022-05-18",WD_DELTA:7,WD_TIMES:2,MC_REFORECAST:"2022-06-01",WD_ACTUAL_DATE:"2022-05-25",MC_APPROVED:"2022-06-01",WD_TO_MC_WK:1,WD_TO_MC_DAYS:7,BAR:7,MC_TARGET_WD_DATE:"2022-05-18",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:115,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P3",SUBSYSTEM:"21-6100-6140-03",SUBSYSTEM_DESCR:"Helideck Foam Package",WD_PLAN:"2022-06-09",WD_DELTA:-15,WD_TIMES:1,MC_REFORECAST:"2022-06-23",WD_ACTUAL_DATE:"2022-05-25",MC_APPROVED:"2022-06-27",WD_TO_MC_WK:4,WD_TO_MC_DAYS:33,BAR:33,MC_TARGET_WD_DATE:"2022-06-09",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:116,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-6800-6840-04",SUBSYSTEM_DESCR:"Fire Zone 6840-4 - Gas Turbine Generator Package 21-Z-861201",WD_PLAN:"2022-05-23",WD_DELTA:2,WD_TIMES:1,MC_REFORECAST:"2022-06-06",WD_ACTUAL_DATE:"2022-05-25",MC_APPROVED:"2022-06-06",WD_TO_MC_WK:1,WD_TO_MC_DAYS:12,BAR:12,MC_TARGET_WD_DATE:"2022-05-23",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:117,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-6800-6840-03",SUBSYSTEM_DESCR:"Fire Zone 6840-3 - Gas Turbine Generator Package 21-Z-861101",WD_PLAN:"2022-05-23",WD_DELTA:1,WD_TIMES:1,MC_REFORECAST:"2022-06-06",WD_ACTUAL_DATE:"2022-05-24",MC_APPROVED:"2022-06-06",WD_TO_MC_WK:1,WD_TO_MC_DAYS:13,BAR:13,MC_TARGET_WD_DATE:"2022-05-23",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:118,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-6200-6210-02",SUBSYSTEM_DESCR:"PAGA Field Equipment ME05andMW05",WD_PLAN:"2022-05-20",WD_DELTA:3,WD_TIMES:1,MC_REFORECAST:"2022-06-03",WD_ACTUAL_DATE:"2022-05-23",MC_APPROVED:"2022-06-03",WD_TO_MC_WK:1,WD_TO_MC_DAYS:11,BAR:11,MC_TARGET_WD_DATE:"2022-05-20",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:119,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-8600-8611-01",SUBSYSTEM_DESCR:"GTG 1 and WHRU1",WD_PLAN:"2022-06-02",WD_DELTA:-12,WD_TIMES:1,MC_REFORECAST:"2022-06-16",WD_ACTUAL_DATE:"2022-05-21",MC_APPROVED:"2022-06-18",WD_TO_MC_WK:4,WD_TO_MC_DAYS:28,BAR:28,MC_TARGET_WD_DATE:"2022-06-02",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:120,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1A",SUBSYSTEM:"21-9330-9330-01",SUBSYSTEM_DESCR:"Main Ballast Tanks WBE1 to 5, APE 1, APW 1 and WBW 1 to 5",WD_PLAN:"2022-05-06",WD_DELTA:14,WD_TIMES:1,MC_REFORECAST:"2022-05-20",WD_ACTUAL_DATE:"2022-05-20",MC_APPROVED:"2022-06-22",WD_TO_MC_WK:4,WD_TO_MC_DAYS:33,BAR:33,MC_TARGET_WD_DATE:"2022-05-06",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:121,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-6800-6840-01",SUBSYSTEM_DESCR:"Fire Zone 6840-1 - Power Generation Module-MW04 ,Open Area",WD_PLAN:"2022-05-24",WD_DELTA:-4,WD_TIMES:1,MC_REFORECAST:"2022-06-07",WD_ACTUAL_DATE:"2022-05-20",MC_APPROVED:"2022-06-07",WD_TO_MC_WK:2,WD_TO_MC_DAYS:18,BAR:18,MC_TARGET_WD_DATE:"2022-05-24",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:122,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-6200-6210-04",SUBSYSTEM_DESCR:"PAGA Field Equipment ME04andMW04",WD_PLAN:"2022-05-24",WD_DELTA:-4,WD_TIMES:1,MC_REFORECAST:"2022-06-07",WD_ACTUAL_DATE:"2022-05-20",MC_APPROVED:"2022-06-07",WD_TO_MC_WK:2,WD_TO_MC_DAYS:18,BAR:18,MC_TARGET_WD_DATE:"2022-05-24",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:123,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P2",SUBSYSTEM:"21-6100-6110-02",SUBSYSTEM_DESCR:"Accommodation Firewater and Hydrants",WD_PLAN:"2022-06-09",WD_DELTA:-21,WD_TIMES:1,MC_REFORECAST:"2022-06-23",WD_ACTUAL_DATE:"2022-05-19",MC_APPROVED:"2022-06-24",WD_TO_MC_WK:5,WD_TO_MC_DAYS:36,BAR:36,MC_TARGET_WD_DATE:"2022-06-09",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:124,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P2",SUBSYSTEM:"21-6800-6842-08",SUBSYSTEM_DESCR:"Fire Zone 6842-7 - Fire Water Pump Enclosure 611101",WD_PLAN:"2022-05-19",WD_DELTA:0,WD_TIMES:1,MC_REFORECAST:"2022-06-02",WD_ACTUAL_DATE:"2022-05-19",MC_APPROVED:"2022-06-02",WD_TO_MC_WK:2,WD_TO_MC_DAYS:14,BAR:14,MC_TARGET_WD_DATE:"2022-05-19",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:125,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P2",SUBSYSTEM:"21-6800-6842-09",SUBSYSTEM_DESCR:"Fire Zone 6842-8 - Fire Water Pump Enclosure 611201",WD_PLAN:"2022-05-19",WD_DELTA:0,WD_TIMES:1,MC_REFORECAST:"2022-06-02",WD_ACTUAL_DATE:"2022-05-19",MC_APPROVED:"2022-06-02",WD_TO_MC_WK:2,WD_TO_MC_DAYS:14,BAR:14,MC_TARGET_WD_DATE:"2022-05-19",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:126,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P2",SUBSYSTEM:"21-6800-6842-10",SUBSYSTEM_DESCR:"Fire Zone 6842-9 - Fire Water Pump Enclosure 611301",WD_PLAN:"2022-05-19",WD_DELTA:0,WD_TIMES:1,MC_REFORECAST:"2022-06-02",WD_ACTUAL_DATE:"2022-05-19",MC_APPROVED:"2022-06-02",WD_TO_MC_WK:2,WD_TO_MC_DAYS:14,BAR:14,MC_TARGET_WD_DATE:"2022-05-19",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:127,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P2",SUBSYSTEM:"21-9976-9976-02",SUBSYSTEM_DESCR:"Topsides Battery Exhaust Fans, Ducting and Dampers",WD_PLAN:"2022-05-26",WD_DELTA:-7,WD_TIMES:1,MC_REFORECAST:"2022-06-09",WD_ACTUAL_DATE:"2022-05-19",MC_APPROVED:"2022-06-09",WD_TO_MC_WK:3,WD_TO_MC_DAYS:21,BAR:21,MC_TARGET_WD_DATE:"2022-05-26",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:128,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-6070-6074-07",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables MW05",WD_PLAN:"2022-05-25",WD_DELTA:-8,WD_TIMES:1,MC_REFORECAST:"2022-06-08",WD_ACTUAL_DATE:"2022-05-17",MC_APPROVED:"2022-06-08",WD_TO_MC_WK:3,WD_TO_MC_DAYS:22,BAR:22,MC_TARGET_WD_DATE:"2022-05-25",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:129,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-6020-6022-07",SUBSYSTEM_DESCR:"PAS Remote IOs ,incl Junction Box and Multicore Cables MW05",WD_PLAN:"2022-05-26",WD_DELTA:-9,WD_TIMES:1,MC_REFORECAST:"2022-06-09",WD_ACTUAL_DATE:"2022-05-17",MC_APPROVED:"2022-06-09",WD_TO_MC_WK:3,WD_TO_MC_DAYS:23,BAR:23,MC_TARGET_WD_DATE:"2022-05-26",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:130,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-6070-6072-11",SUBSYSTEM_DESCR:"Fire Addressable Panels  and - Addressable Fire and Gas Detectors - Topsides ME05",WD_PLAN:"2022-05-19",WD_DELTA:-3,WD_TIMES:1,MC_REFORECAST:"2022-06-02",WD_ACTUAL_DATE:"2022-05-16",MC_APPROVED:"2022-06-02",WD_TO_MC_WK:2,WD_TO_MC_DAYS:17,BAR:17,MC_TARGET_WD_DATE:"2022-05-19",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:131,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-6070-6072-03",SUBSYSTEM_DESCR:"LQ Fire and Gas Addressable Loop-3",WD_PLAN:"2022-05-20",WD_DELTA:-7,WD_TIMES:1,MC_REFORECAST:"2022-06-03",WD_ACTUAL_DATE:"2022-05-13",MC_APPROVED:"2022-06-03",WD_TO_MC_WK:3,WD_TO_MC_DAYS:21,BAR:21,MC_TARGET_WD_DATE:"2022-05-20",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:132,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P2",SUBSYSTEM:"21-6625-8725-01",SUBSYSTEM_DESCR:"Normal and Essential Heat Trace 400V Topsides 21-EDB-667605",WD_PLAN:"2022-05-20",WD_DELTA:-7,WD_TIMES:1,MC_REFORECAST:"2022-06-03",WD_ACTUAL_DATE:"2022-05-13",MC_APPROVED:"2022-06-03",WD_TO_MC_WK:3,WD_TO_MC_DAYS:21,BAR:21,MC_TARGET_WD_DATE:"2022-05-20",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:133,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-6070-6074-05",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables ME04",WD_PLAN:"2022-05-24",WD_DELTA:-12,WD_TIMES:1,MC_REFORECAST:"2022-06-07",WD_ACTUAL_DATE:"2022-05-12",MC_APPROVED:"2022-06-07",WD_TO_MC_WK:3,WD_TO_MC_DAYS:26,BAR:26,MC_TARGET_WD_DATE:"2022-05-24",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:134,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-6070-6074-06",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables MW04",WD_PLAN:"2022-05-24",WD_DELTA:-12,WD_TIMES:1,MC_REFORECAST:"2022-06-07",WD_ACTUAL_DATE:"2022-05-12",MC_APPROVED:"2022-06-07",WD_TO_MC_WK:3,WD_TO_MC_DAYS:26,BAR:26,MC_TARGET_WD_DATE:"2022-05-24",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:135,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-6010-6012-07",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables MW05",WD_PLAN:"2022-05-20",WD_DELTA:-8,WD_TIMES:1,MC_REFORECAST:"2022-06-03",WD_ACTUAL_DATE:"2022-05-12",MC_APPROVED:"2022-06-03",WD_TO_MC_WK:3,WD_TO_MC_DAYS:22,BAR:22,MC_TARGET_WD_DATE:"2022-05-20",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:136,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P3",SUBSYSTEM:"21-9370-9372-12",SUBSYSTEM_DESCR:"HULL Open Drain Collection",WD_PLAN:"2022-05-20",WD_DELTA:-8,WD_TIMES:1,MC_REFORECAST:"2022-06-03",WD_ACTUAL_DATE:"2022-05-12",MC_APPROVED:"2022-06-03",WD_TO_MC_WK:3,WD_TO_MC_DAYS:22,BAR:22,MC_TARGET_WD_DATE:"2022-05-20",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:137,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P2",SUBSYSTEM:"21-9921-9927-01",SUBSYSTEM_DESCR:"Cool Store System in Galley",WD_PLAN:"2022-06-06",WD_DELTA:-26,WD_TIMES:1,MC_REFORECAST:"2022-06-20",WD_ACTUAL_DATE:"2022-05-11",MC_APPROVED:"2022-06-20",WD_TO_MC_WK:5,WD_TO_MC_DAYS:40,BAR:40,MC_TARGET_WD_DATE:"2022-06-06",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:138,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P2",SUBSYSTEM:"21-9380-9380-06",SUBSYSTEM_DESCR:"Daily Bilge for CFC8 and Pump 21-P-938003",WD_PLAN:"2022-07-08",WD_DELTA:-60,WD_TIMES:1,MC_REFORECAST:"2022-07-22",WD_ACTUAL_DATE:"2022-05-09",MC_APPROVED:"2022-06-28",WD_TO_MC_WK:7,WD_TO_MC_DAYS:50,BAR:50,MC_TARGET_WD_DATE:"2022-07-08",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:139,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P2",SUBSYSTEM:"21-9380-9380-09",SUBSYSTEM_DESCR:"Daily Bilge for CFC1 and Pump 21-P-938006",WD_PLAN:"2022-05-19",WD_DELTA:-10,WD_TIMES:1,MC_REFORECAST:"2022-06-02",WD_ACTUAL_DATE:"2022-05-09",MC_APPROVED:"2022-06-02",WD_TO_MC_WK:3,WD_TO_MC_DAYS:24,BAR:24,MC_TARGET_WD_DATE:"2022-05-19",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:140,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-8100-8123-01",SUBSYSTEM_DESCR:"Topsides Instrument Air Distribution",WD_PLAN:"2022-06-01",WD_DELTA:-25,WD_TIMES:1,MC_REFORECAST:"2022-06-15",WD_ACTUAL_DATE:"2022-05-07",MC_APPROVED:"2022-06-16",WD_TO_MC_WK:5,WD_TO_MC_DAYS:40,BAR:40,MC_TARGET_WD_DATE:"2022-06-01",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:141,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-6070-6073-07",SUBSYSTEM_DESCR:"HSSD - Topsides ,ME05 and LER",WD_PLAN:"2022-06-01",WD_DELTA:-28,WD_TIMES:1,MC_REFORECAST:"2022-06-15",WD_ACTUAL_DATE:"2022-05-04",MC_APPROVED:"2022-06-15",WD_TO_MC_WK:6,WD_TO_MC_DAYS:42,BAR:42,MC_TARGET_WD_DATE:"2022-06-01",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:142,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P2",SUBSYSTEM:"21-8000-8013-02",SUBSYSTEM_DESCR:"Hot Water Distribution and Recirculation Pumps",WD_PLAN:"2022-05-24",WD_DELTA:-20,WD_TIMES:1,MC_REFORECAST:"2022-06-07",WD_ACTUAL_DATE:"2022-05-04",MC_APPROVED:"2022-06-07",WD_TO_MC_WK:4,WD_TO_MC_DAYS:34,BAR:34,MC_TARGET_WD_DATE:"2022-05-24",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:143,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-6030-6038-01",SUBSYSTEM_DESCR:"Cargo Loading Computer and Workstation",WD_PLAN:"2022-06-01",WD_DELTA:-33,WD_TIMES:1,MC_REFORECAST:"2022-06-15",WD_ACTUAL_DATE:"2022-04-29",MC_APPROVED:"2022-06-15",WD_TO_MC_WK:6,WD_TO_MC_DAYS:47,BAR:47,MC_TARGET_WD_DATE:"2022-06-01",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:144,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1",SUBSYSTEM:"21-8700-8760-01",SUBSYSTEM_DESCR:"Topside Normal 690 V Swicthboard 21-ESB-876001",WD_PLAN:"2022-04-21",WD_DELTA:6,WD_TIMES:1,MC_REFORECAST:"2022-05-05",WD_ACTUAL_DATE:"2022-04-27",MC_APPROVED:"2022-06-16",WD_TO_MC_WK:7,WD_TO_MC_DAYS:50,BAR:50,MC_TARGET_WD_DATE:"2022-04-21",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:145,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P2",SUBSYSTEM:"21-6600-6624-05",SUBSYSTEM_DESCR:"HULL Emergency Lighting Panel and Circuits ,Room: M104 21-ELP-662401",WD_PLAN:"2022-03-14",WD_DELTA:8,WD_TIMES:1,MC_REFORECAST:"2022-03-28",WD_ACTUAL_DATE:"2022-03-22",MC_APPROVED:"2022-06-08",WD_TO_MC_WK:11,WD_TO_MC_DAYS:78,BAR:78,MC_TARGET_WD_DATE:"2022-03-14",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:146,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P2",SUBSYSTEM:"21-8020-8021-01",SUBSYSTEM_DESCR:"IGG Scrubber Cooling Sea Water Pumps - 21-P802003AandB",WD_PLAN:"2022-02-26",WD_DELTA:13,WD_TIMES:1,MC_REFORECAST:"2022-03-12",WD_ACTUAL_DATE:"2022-03-11",MC_APPROVED:"2022-06-21",WD_TO_MC_WK:14,WD_TO_MC_DAYS:102,BAR:102,MC_TARGET_WD_DATE:"2022-02-26",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:147,YEAR:2022,MON:6,MONTH:"June",PRIORITY:"P1A",SUBSYSTEM:"21-6030-6032-03",SUBSYSTEM_DESCR:"ICSS Interface Servers, Workstations and Network Switches HER",WD_PLAN:"2021-11-24",WD_DELTA:8,WD_TIMES:1,MC_REFORECAST:"2021-12-08",WD_ACTUAL_DATE:"2021-12-02",MC_APPROVED:"2022-06-13",WD_TO_MC_WK:27,WD_TO_MC_DAYS:193,BAR:193,MC_TARGET_WD_DATE:"2021-11-24",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:148,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1B",SUBSYSTEM:"21-6600-6640-02",SUBSYSTEM_DESCR:"Topsides Emergency Lighting and small power 400V board 21-EDB-662402 ,in LER",WD_PLAN:"2022-05-03",WD_DELTA:29,WD_TIMES:3,MC_REFORECAST:"2022-05-17",WD_ACTUAL_DATE:"2022-06-01",MC_APPROVED:"2022-05-17",WD_TO_MC_WK:-2,WD_TO_MC_DAYS:-15,BAR:-15,MC_TARGET_WD_DATE:"2022-05-03",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:149,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P2",SUBSYSTEM:"21-6600-6675-03",SUBSYSTEM_DESCR:"Topsides Normal and Essential lighting and Small Power 400V 21-EDB-667603",WD_PLAN:"2022-05-03",WD_DELTA:29,WD_TIMES:4,MC_REFORECAST:"2022-05-17",WD_ACTUAL_DATE:"2022-06-01",MC_APPROVED:"2022-05-17",WD_TO_MC_WK:-2,WD_TO_MC_DAYS:-15,BAR:-15,MC_TARGET_WD_DATE:"2022-05-03",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:150,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1",SUBSYSTEM:"21-6600-6675-04",SUBSYSTEM_DESCR:"Topsides MOV and Convenience Outlets 400V 21-EDB-667604",WD_PLAN:"2022-05-04",WD_DELTA:28,WD_TIMES:3,MC_REFORECAST:"2022-05-18",WD_ACTUAL_DATE:"2022-06-01",MC_APPROVED:"2022-05-18",WD_TO_MC_WK:-2,WD_TO_MC_DAYS:-14,BAR:-14,MC_TARGET_WD_DATE:"2022-05-04",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:151,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P3",SUBSYSTEM:"21-2200-2131-01",SUBSYSTEM_DESCR:"Slug Catcher 1 and Liquid Heater 1",WD_PLAN:"2022-05-17",WD_DELTA:11,WD_TIMES:1,MC_REFORECAST:"2022-05-31",WD_ACTUAL_DATE:"2022-05-28",MC_APPROVED:"2022-05-31",WD_TO_MC_WK:0,WD_TO_MC_DAYS:3,BAR:3,MC_TARGET_WD_DATE:"2022-05-17",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:152,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P3",SUBSYSTEM:"21-2200-2132-01",SUBSYSTEM_DESCR:"Slug Catcher 2 and Liquid heater 2",WD_PLAN:"2022-05-17",WD_DELTA:11,WD_TIMES:1,MC_REFORECAST:"2022-05-31",WD_ACTUAL_DATE:"2022-05-28",MC_APPROVED:"2022-05-31",WD_TO_MC_WK:0,WD_TO_MC_DAYS:3,BAR:3,MC_TARGET_WD_DATE:"2022-05-17",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:153,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1",SUBSYSTEM:"21-9500-9534-05",SUBSYSTEM_DESCR:"Structured Cabling System - ME05",WD_PLAN:"2022-05-16",WD_DELTA:9,WD_TIMES:1,MC_REFORECAST:"2022-05-30",WD_ACTUAL_DATE:"2022-05-25",MC_APPROVED:"2022-05-30",WD_TO_MC_WK:0,WD_TO_MC_DAYS:5,BAR:5,MC_TARGET_WD_DATE:"2022-05-16",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:154,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P2",SUBSYSTEM:"21-6600-6676-04",SUBSYSTEM_DESCR:"LQ Small Power for LQ Aux Eqt ,ROOM: LH05 21-ELP-667672",WD_PLAN:"2022-05-17",WD_DELTA:7,WD_TIMES:1,MC_REFORECAST:"2022-05-31",WD_ACTUAL_DATE:"2022-05-24",MC_APPROVED:"2022-05-31",WD_TO_MC_WK:1,WD_TO_MC_DAYS:7,BAR:7,MC_TARGET_WD_DATE:"2022-05-17",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:155,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1",SUBSYSTEM:"21-9500-9565-01",SUBSYSTEM_DESCR:"Ship Security Alert System ,SSAS",WD_PLAN:"2022-05-13",WD_DELTA:10,WD_TIMES:1,MC_REFORECAST:"2022-05-27",WD_ACTUAL_DATE:"2022-05-23",MC_APPROVED:"2022-05-27",WD_TO_MC_WK:0,WD_TO_MC_DAYS:4,BAR:4,MC_TARGET_WD_DATE:"2022-05-13",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:156,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1",SUBSYSTEM:"21-9500-9514-01",SUBSYSTEM_DESCR:"Telephone System - LQ",WD_PLAN:"2022-05-13",WD_DELTA:8,WD_TIMES:1,MC_REFORECAST:"2022-05-27",WD_ACTUAL_DATE:"2022-05-21",MC_APPROVED:"2022-05-27",WD_TO_MC_WK:0,WD_TO_MC_DAYS:6,BAR:6,MC_TARGET_WD_DATE:"2022-05-13",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:157,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1",SUBSYSTEM:"21-9500-9515-01",SUBSYSTEM_DESCR:"Hot line Telephones",WD_PLAN:"2022-05-13",WD_DELTA:6,WD_TIMES:1,MC_REFORECAST:"2022-05-27",WD_ACTUAL_DATE:"2022-05-19",MC_APPROVED:"2022-05-27",WD_TO_MC_WK:1,WD_TO_MC_DAYS:8,BAR:8,MC_TARGET_WD_DATE:"2022-05-13",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:158,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1B",SUBSYSTEM:"21-6030-6043-01",SUBSYSTEM_DESCR:"FOPPs and Associated Fiber Cables to LER",WD_PLAN:"2022-05-06",WD_DELTA:11,WD_TIMES:1,MC_REFORECAST:"2022-05-20",WD_ACTUAL_DATE:"2022-05-17",MC_APPROVED:"2022-05-20",WD_TO_MC_WK:0,WD_TO_MC_DAYS:3,BAR:3,MC_TARGET_WD_DATE:"2022-05-06",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:159,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P2",SUBSYSTEM:"21-9840-9846-01",SUBSYSTEM_DESCR:"Hospital and Galley Call Alarm System",WD_PLAN:"2022-05-10",WD_DELTA:6,WD_TIMES:1,MC_REFORECAST:"2022-05-24",WD_ACTUAL_DATE:"2022-05-16",MC_APPROVED:"2022-05-24",WD_TO_MC_WK:1,WD_TO_MC_DAYS:8,BAR:8,MC_TARGET_WD_DATE:"2022-05-10",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:160,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P2",SUBSYSTEM:"21-9921-9922-02",SUBSYSTEM_DESCR:"LQ HVAC AHUs, Ducting and Dampers",WD_PLAN:"2022-05-16",WD_DELTA:0,WD_TIMES:1,MC_REFORECAST:"2022-05-30",WD_ACTUAL_DATE:"2022-05-16",MC_APPROVED:"2022-05-30",WD_TO_MC_WK:2,WD_TO_MC_DAYS:14,BAR:14,MC_TARGET_WD_DATE:"2022-05-16",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:161,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P3",SUBSYSTEM:"21-8780-8780-03",SUBSYSTEM_DESCR:"Earthing Topsides",WD_PLAN:"2022-02-03",WD_DELTA:100,WD_TIMES:4,MC_REFORECAST:"2022-02-17",WD_ACTUAL_DATE:"2022-05-14",MC_APPROVED:"2022-05-20",WD_TO_MC_WK:0,WD_TO_MC_DAYS:6,BAR:6,MC_TARGET_WD_DATE:"2022-02-03",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:162,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1B",SUBSYSTEM:"21-6030-6031-01",SUBSYSTEM_DESCR:"Electrical Data Monitoring and Constrol System ,EDMCS and Power Management System ,PMS",WD_PLAN:"2022-05-11",WD_DELTA:3,WD_TIMES:1,MC_REFORECAST:"2022-05-25",WD_ACTUAL_DATE:"2022-05-14",MC_APPROVED:"2022-05-25",WD_TO_MC_WK:1,WD_TO_MC_DAYS:11,BAR:11,MC_TARGET_WD_DATE:"2022-05-11",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:163,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P3",SUBSYSTEM:"21-8000-8011-02",SUBSYSTEM_DESCR:"Fresh Water Bunkering Header",WD_PLAN:"2022-05-13",WD_DELTA:0,WD_TIMES:1,MC_REFORECAST:"2022-05-27",WD_ACTUAL_DATE:"2022-05-13",MC_APPROVED:"2022-05-27",WD_TO_MC_WK:2,WD_TO_MC_DAYS:14,BAR:14,MC_TARGET_WD_DATE:"2022-05-13",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:164,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1",SUBSYSTEM:"21-6500-6556-01",SUBSYSTEM_DESCR:"Automatic Identification System",WD_PLAN:"2022-05-12",WD_DELTA:0,WD_TIMES:1,MC_REFORECAST:"2022-05-26",WD_ACTUAL_DATE:"2022-05-12",MC_APPROVED:"2022-05-26",WD_TO_MC_WK:2,WD_TO_MC_DAYS:14,BAR:14,MC_TARGET_WD_DATE:"2022-05-12",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:165,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1",SUBSYSTEM:"21-6010-6012-08",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables ME04",WD_PLAN:"2022-05-09",WD_DELTA:3,WD_TIMES:1,MC_REFORECAST:"2022-05-23",WD_ACTUAL_DATE:"2022-05-12",MC_APPROVED:"2022-05-23",WD_TO_MC_WK:1,WD_TO_MC_DAYS:11,BAR:11,MC_TARGET_WD_DATE:"2022-05-09",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:166,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1",SUBSYSTEM:"21-6020-6022-05",SUBSYSTEM_DESCR:"PAS Remote IOs ,incl Junction Box and Multicore Cables ME04",WD_PLAN:"2022-05-09",WD_DELTA:3,WD_TIMES:1,MC_REFORECAST:"2022-05-23",WD_ACTUAL_DATE:"2022-05-12",MC_APPROVED:"2022-05-23",WD_TO_MC_WK:1,WD_TO_MC_DAYS:11,BAR:11,MC_TARGET_WD_DATE:"2022-05-09",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:167,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1",SUBSYSTEM:"21-6020-6022-06",SUBSYSTEM_DESCR:"PAS Remote IOs ,incl Junction Box and Multicore Cables MW04",WD_PLAN:"2022-05-09",WD_DELTA:3,WD_TIMES:1,MC_REFORECAST:"2022-05-23",WD_ACTUAL_DATE:"2022-05-12",MC_APPROVED:"2022-05-23",WD_TO_MC_WK:1,WD_TO_MC_DAYS:11,BAR:11,MC_TARGET_WD_DATE:"2022-05-09",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:168,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1",SUBSYSTEM:"21-6010-6012-06",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables MW04",WD_PLAN:"2022-05-09",WD_DELTA:3,WD_TIMES:1,MC_REFORECAST:"2022-05-23",WD_ACTUAL_DATE:"2022-05-12",MC_APPROVED:"2022-05-23",WD_TO_MC_WK:1,WD_TO_MC_DAYS:11,BAR:11,MC_TARGET_WD_DATE:"2022-05-09",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:169,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P2",SUBSYSTEM:"21-9921-9921-03",SUBSYSTEM_DESCR:"LQ Extracts, Ducting and Damper",WD_PLAN:"2022-05-16",WD_DELTA:-4,WD_TIMES:1,MC_REFORECAST:"2022-05-30",WD_ACTUAL_DATE:"2022-05-12",MC_APPROVED:"2022-05-30",WD_TO_MC_WK:2,WD_TO_MC_DAYS:18,BAR:18,MC_TARGET_WD_DATE:"2022-05-16",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:170,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P2",SUBSYSTEM:"21-9921-9922-04",SUBSYSTEM_DESCR:"LQ Galley AHUs, Ducting and Dampers",WD_PLAN:"2022-05-16",WD_DELTA:-5,WD_TIMES:1,MC_REFORECAST:"2022-05-30",WD_ACTUAL_DATE:"2022-05-11",MC_APPROVED:"2022-05-30",WD_TO_MC_WK:2,WD_TO_MC_DAYS:19,BAR:19,MC_TARGET_WD_DATE:"2022-05-16",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:171,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1",SUBSYSTEM:"21-9500-9570-02",SUBSYSTEM_DESCR:"D-GPS System",WD_PLAN:"2022-05-12",WD_DELTA:-1,WD_TIMES:1,MC_REFORECAST:"2022-05-26",WD_ACTUAL_DATE:"2022-05-11",MC_APPROVED:"2022-05-26",WD_TO_MC_WK:2,WD_TO_MC_DAYS:15,BAR:15,MC_TARGET_WD_DATE:"2022-05-12",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:172,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P3",SUBSYSTEM:"21-9970-9971-06",SUBSYSTEM_DESCR:"HULL Chilled Water System and UCP",WD_PLAN:"2022-05-02",WD_DELTA:9,WD_TIMES:2,MC_REFORECAST:"2022-05-16",WD_ACTUAL_DATE:"2022-05-11",MC_APPROVED:"2022-05-17",WD_TO_MC_WK:0,WD_TO_MC_DAYS:6,BAR:6,MC_TARGET_WD_DATE:"2022-05-02",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:173,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1",SUBSYSTEM:"21-9500-9534-01",SUBSYSTEM_DESCR:"Structured Cabling System - LQ Deck-A",WD_PLAN:"2022-05-03",WD_DELTA:7,WD_TIMES:2,MC_REFORECAST:"2022-05-17",WD_ACTUAL_DATE:"2022-05-10",MC_APPROVED:"2022-05-17",WD_TO_MC_WK:1,WD_TO_MC_DAYS:7,BAR:7,MC_TARGET_WD_DATE:"2022-05-03",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:174,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P2",SUBSYSTEM:"21-8090-8090-01",SUBSYSTEM_DESCR:"Sewage Treatment Package",WD_PLAN:"2022-05-17",WD_DELTA:-7,WD_TIMES:1,MC_REFORECAST:"2022-05-31",WD_ACTUAL_DATE:"2022-05-10",MC_APPROVED:"2022-05-31",WD_TO_MC_WK:3,WD_TO_MC_DAYS:21,BAR:21,MC_TARGET_WD_DATE:"2022-05-17",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:175,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1",SUBSYSTEM:"21-8700-8770-01",SUBSYSTEM_DESCR:"Topsides Main Normal HV Switchboard 6.6kV 21-ESB-877001",WD_PLAN:"2022-04-29",WD_DELTA:10,WD_TIMES:1,MC_REFORECAST:"2022-05-13",WD_ACTUAL_DATE:"2022-05-09",MC_APPROVED:"2022-05-13",WD_TO_MC_WK:0,WD_TO_MC_DAYS:4,BAR:4,MC_TARGET_WD_DATE:"2022-04-29",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:176,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1",SUBSYSTEM:"21-9500-9534-04",SUBSYSTEM_DESCR:"Structured Cabling System - Machinery Space",WD_PLAN:"2022-05-03",WD_DELTA:6,WD_TIMES:2,MC_REFORECAST:"2022-05-17",WD_ACTUAL_DATE:"2022-05-09",MC_APPROVED:"2022-05-17",WD_TO_MC_WK:1,WD_TO_MC_DAYS:8,BAR:8,MC_TARGET_WD_DATE:"2022-05-03",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:177,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1B",SUBSYSTEM:"21-6600-6674-02",SUBSYSTEM_DESCR:"Topside Essential 690 V Switchboard 21-ESB-667402",WD_PLAN:"2022-05-03",WD_DELTA:6,WD_TIMES:2,MC_REFORECAST:"2022-05-17",WD_ACTUAL_DATE:"2022-05-09",MC_APPROVED:"2022-05-17",WD_TO_MC_WK:1,WD_TO_MC_DAYS:8,BAR:8,MC_TARGET_WD_DATE:"2022-05-03",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:178,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P2",SUBSYSTEM:"21-6600-6675-02",SUBSYSTEM_DESCR:"Topsides Normal and Essential lighting and Small Power 400V 21-EDB-667602",WD_PLAN:"2022-05-03",WD_DELTA:4,WD_TIMES:1,MC_REFORECAST:"2022-05-17",WD_ACTUAL_DATE:"2022-05-07",MC_APPROVED:"2022-05-17",WD_TO_MC_WK:1,WD_TO_MC_DAYS:10,BAR:10,MC_TARGET_WD_DATE:"2022-05-03",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:179,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1",SUBSYSTEM:"21-9500-9534-02",SUBSYSTEM_DESCR:"Structured Cabling System - LQ Levels 1,2 and 3",WD_PLAN:"2022-05-03",WD_DELTA:3,WD_TIMES:1,MC_REFORECAST:"2022-05-17",WD_ACTUAL_DATE:"2022-05-06",MC_APPROVED:"2022-05-17",WD_TO_MC_WK:1,WD_TO_MC_DAYS:11,BAR:11,MC_TARGET_WD_DATE:"2022-05-03",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:180,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1",SUBSYSTEM:"21-9500-9534-03",SUBSYSTEM_DESCR:"Structured Cabling System - LQ Levels 4 and 5",WD_PLAN:"2022-05-03",WD_DELTA:2,WD_TIMES:1,MC_REFORECAST:"2022-05-17",WD_ACTUAL_DATE:"2022-05-05",MC_APPROVED:"2022-05-17",WD_TO_MC_WK:1,WD_TO_MC_DAYS:12,BAR:12,MC_TARGET_WD_DATE:"2022-05-03",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:181,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P3",SUBSYSTEM:"21-9921-9922-14",SUBSYSTEM_DESCR:"LQ Paint Store Supply Fans, Ducting and Dampers",WD_PLAN:"2022-05-04",WD_DELTA:1,WD_TIMES:1,MC_REFORECAST:"2022-05-18",WD_ACTUAL_DATE:"2022-05-05",MC_APPROVED:"2022-05-18",WD_TO_MC_WK:1,WD_TO_MC_DAYS:13,BAR:13,MC_TARGET_WD_DATE:"2022-05-04",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:182,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1",SUBSYSTEM:"21-6600-6675-05",SUBSYSTEM_DESCR:"Topsides Misc Feeders and Auxiliaries 400V 21-EDB-667606",WD_PLAN:"2022-05-03",WD_DELTA:2,WD_TIMES:1,MC_REFORECAST:"2022-05-17",WD_ACTUAL_DATE:"2022-05-05",MC_APPROVED:"2022-05-17",WD_TO_MC_WK:1,WD_TO_MC_DAYS:12,BAR:12,MC_TARGET_WD_DATE:"2022-05-03",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:183,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P2",SUBSYSTEM:"21-8000-8012-03",SUBSYSTEM_DESCR:"Cold Potable Water Distribution to HULL",WD_PLAN:"2022-05-17",WD_DELTA:-13,WD_TIMES:1,MC_REFORECAST:"2022-05-31",WD_ACTUAL_DATE:"2022-05-04",MC_APPROVED:"2022-05-31",WD_TO_MC_WK:3,WD_TO_MC_DAYS:27,BAR:27,MC_TARGET_WD_DATE:"2022-05-17",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:184,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P2",SUBSYSTEM:"21-9380-9381-01",SUBSYSTEM_DESCR:"Main bilge Pumps 21-P-938002AandB and Headers",WD_PLAN:"2022-05-03",WD_DELTA:1,WD_TIMES:1,MC_REFORECAST:"2022-05-17",WD_ACTUAL_DATE:"2022-05-04",MC_APPROVED:"2022-05-17",WD_TO_MC_WK:1,WD_TO_MC_DAYS:13,BAR:13,MC_TARGET_WD_DATE:"2022-05-03",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:185,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1",SUBSYSTEM:"21-6030-6041-01",SUBSYSTEM_DESCR:"PEMS System",WD_PLAN:"2022-04-26",WD_DELTA:6,WD_TIMES:1,MC_REFORECAST:"2022-05-10",WD_ACTUAL_DATE:"2022-05-02",MC_APPROVED:"2022-05-10",WD_TO_MC_WK:1,WD_TO_MC_DAYS:8,BAR:8,MC_TARGET_WD_DATE:"2022-04-26",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:186,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1",SUBSYSTEM:"21-6030-6034-01",SUBSYSTEM_DESCR:"Machinery Protection System ,MPS",WD_PLAN:"2022-04-27",WD_DELTA:3,WD_TIMES:1,MC_REFORECAST:"2022-05-11",WD_ACTUAL_DATE:"2022-04-30",MC_APPROVED:"2022-05-11",WD_TO_MC_WK:1,WD_TO_MC_DAYS:11,BAR:11,MC_TARGET_WD_DATE:"2022-04-27",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:187,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P2",SUBSYSTEM:"21-6100-6140-01",SUBSYSTEM_DESCR:"Foam Package",WD_PLAN:"2022-04-27",WD_DELTA:3,WD_TIMES:1,MC_REFORECAST:"2022-05-11",WD_ACTUAL_DATE:"2022-04-30",MC_APPROVED:"2022-05-11",WD_TO_MC_WK:1,WD_TO_MC_DAYS:11,BAR:11,MC_TARGET_WD_DATE:"2022-04-27",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:188,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P3",SUBSYSTEM:"21-9970-9970-01",SUBSYSTEM_DESCR:"HULL Emergency Pressurisation Fans",WD_PLAN:"2022-04-27",WD_DELTA:3,WD_TIMES:1,MC_REFORECAST:"2022-05-11",WD_ACTUAL_DATE:"2022-04-30",MC_APPROVED:"2022-05-11",WD_TO_MC_WK:1,WD_TO_MC_DAYS:11,BAR:11,MC_TARGET_WD_DATE:"2022-04-27",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:189,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P3",SUBSYSTEM:"21-9970-9971-08",SUBSYSTEM_DESCR:"HULL Machinery Space AHU Air Supply",WD_PLAN:"2022-04-27",WD_DELTA:3,WD_TIMES:1,MC_REFORECAST:"2022-05-11",WD_ACTUAL_DATE:"2022-04-30",MC_APPROVED:"2022-05-11",WD_TO_MC_WK:1,WD_TO_MC_DAYS:11,BAR:11,MC_TARGET_WD_DATE:"2022-04-27",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:190,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1",SUBSYSTEM:"21-8020-8027-03",SUBSYSTEM_DESCR:"HULL Service Sea Water to Potable Water Package",WD_PLAN:"2022-04-28",WD_DELTA:1,WD_TIMES:1,MC_REFORECAST:"2022-05-12",WD_ACTUAL_DATE:"2022-04-29",MC_APPROVED:"2022-05-12",WD_TO_MC_WK:1,WD_TO_MC_DAYS:13,BAR:13,MC_TARGET_WD_DATE:"2022-04-28",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:191,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1",SUBSYSTEM:"21-6030-6032-05",SUBSYSTEM_DESCR:"ICSS Workstations and Consoles - Safe HavenandSOP",WD_PLAN:"2022-05-04",WD_DELTA:-5,WD_TIMES:1,MC_REFORECAST:"2022-05-18",WD_ACTUAL_DATE:"2022-04-29",MC_APPROVED:"2022-05-18",WD_TO_MC_WK:2,WD_TO_MC_DAYS:19,BAR:19,MC_TARGET_WD_DATE:"2022-05-04",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:192,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1A",SUBSYSTEM:"21-6030-6032-01",SUBSYSTEM_DESCR:"ICSS Interface Servers, Workstations and Network Switches CER",WD_PLAN:"2021-11-13",WD_DELTA:166,WD_TIMES:2,MC_REFORECAST:"2021-11-27",WD_ACTUAL_DATE:"2022-04-28",MC_APPROVED:"2022-05-02",WD_TO_MC_WK:0,WD_TO_MC_DAYS:4,BAR:4,MC_TARGET_WD_DATE:"2021-11-13",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:193,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1A",SUBSYSTEM:"21-6030-6036-02",SUBSYSTEM_DESCR:"PDBs Inside CER",WD_PLAN:"2021-11-11",WD_DELTA:168,WD_TIMES:2,MC_REFORECAST:"2021-11-25",WD_ACTUAL_DATE:"2022-04-28",MC_APPROVED:"2022-05-03",WD_TO_MC_WK:0,WD_TO_MC_DAYS:5,BAR:5,MC_TARGET_WD_DATE:"2021-11-11",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:194,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1B",SUBSYSTEM:"21-6030-6040-01",SUBSYSTEM_DESCR:"IRP and Interface Instrumentation Cable - ME05 ,HV and LV Room",WD_PLAN:"2022-04-27",WD_DELTA:1,WD_TIMES:1,MC_REFORECAST:"2022-05-11",WD_ACTUAL_DATE:"2022-04-28",MC_APPROVED:"2022-05-11",WD_TO_MC_WK:1,WD_TO_MC_DAYS:13,BAR:13,MC_TARGET_WD_DATE:"2022-04-27",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:195,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P3",SUBSYSTEM:"21-9921-9921-01",SUBSYSTEM_DESCR:"LQ Emergency Pressurization Fans",WD_PLAN:"2022-04-28",WD_DELTA:0,WD_TIMES:1,MC_REFORECAST:"2022-05-12",WD_ACTUAL_DATE:"2022-04-28",MC_APPROVED:"2022-05-12",WD_TO_MC_WK:2,WD_TO_MC_DAYS:14,BAR:14,MC_TARGET_WD_DATE:"2022-04-28",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:196,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P2",SUBSYSTEM:"21-9921-9922-03",SUBSYSTEM_DESCR:"LQ HVAC Extracts, Ducting and Dampers",WD_PLAN:"2022-05-04",WD_DELTA:-7,WD_TIMES:1,MC_REFORECAST:"2022-05-18",WD_ACTUAL_DATE:"2022-04-27",MC_APPROVED:"2022-05-18",WD_TO_MC_WK:3,WD_TO_MC_DAYS:21,BAR:21,MC_TARGET_WD_DATE:"2022-05-04",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:197,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1",SUBSYSTEM:"21-6030-6035-01",SUBSYSTEM_DESCR:"Condition and Performance Monitoring System ,CPMS",WD_PLAN:"2022-04-27",WD_DELTA:0,WD_TIMES:1,MC_REFORECAST:"2022-05-11",WD_ACTUAL_DATE:"2022-04-27",MC_APPROVED:"2022-05-11",WD_TO_MC_WK:2,WD_TO_MC_DAYS:14,BAR:14,MC_TARGET_WD_DATE:"2022-04-27",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:198,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1B",SUBSYSTEM:"21-6030-6032-02",SUBSYSTEM_DESCR:"ICSS Interface Servers, Workstations and Network Switches LER",WD_PLAN:"2022-04-27",WD_DELTA:0,WD_TIMES:1,MC_REFORECAST:"2022-05-11",WD_ACTUAL_DATE:"2022-04-27",MC_APPROVED:"2022-05-11",WD_TO_MC_WK:2,WD_TO_MC_DAYS:14,BAR:14,MC_TARGET_WD_DATE:"2022-04-27",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:199,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1",SUBSYSTEM:"21-8020-8027-02",SUBSYSTEM_DESCR:"HULL Service Sea Water to Electrochlorination Package",WD_PLAN:"2022-04-25",WD_DELTA:1,WD_TIMES:1,MC_REFORECAST:"2022-05-09",WD_ACTUAL_DATE:"2022-04-26",MC_APPROVED:"2022-05-09",WD_TO_MC_WK:1,WD_TO_MC_DAYS:13,BAR:13,MC_TARGET_WD_DATE:"2022-04-25",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:200,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1",SUBSYSTEM:"21-6800-6850-01",SUBSYSTEM_DESCR:"Fire and Gas Detecion - ME05",WD_PLAN:"2022-04-20",WD_DELTA:6,WD_TIMES:1,MC_REFORECAST:"2022-05-04",WD_ACTUAL_DATE:"2022-04-26",MC_APPROVED:"2022-05-02",WD_TO_MC_WK:0,WD_TO_MC_DAYS:6,BAR:6,MC_TARGET_WD_DATE:"2022-04-20",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:201,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P2",SUBSYSTEM:"21-9921-9922-08",SUBSYSTEM_DESCR:"LQ Laundry Exhaust Fans, Ducting and Dampers",WD_PLAN:"2022-04-28",WD_DELTA:-3,WD_TIMES:1,MC_REFORECAST:"2022-05-12",WD_ACTUAL_DATE:"2022-04-25",MC_APPROVED:"2022-05-12",WD_TO_MC_WK:2,WD_TO_MC_DAYS:17,BAR:17,MC_TARGET_WD_DATE:"2022-04-28",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:202,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1A",SUBSYSTEM:"21-9330-9333-01",SUBSYSTEM_DESCR:"Cargo and Ballast Pumps HPU and UCP",WD_PLAN:"2022-03-29",WD_DELTA:24,WD_TIMES:2,MC_REFORECAST:"2022-04-12",WD_ACTUAL_DATE:"2022-04-22",MC_APPROVED:"2022-05-14",WD_TO_MC_WK:3,WD_TO_MC_DAYS:22,BAR:22,MC_TARGET_WD_DATE:"2022-03-29",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:203,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P2",SUBSYSTEM:"21-9921-9922-05",SUBSYSTEM_DESCR:"LQ General Exhaust Air, Ducting and Dampers",WD_PLAN:"2022-04-28",WD_DELTA:-7,WD_TIMES:1,MC_REFORECAST:"2022-05-12",WD_ACTUAL_DATE:"2022-04-21",MC_APPROVED:"2022-05-12",WD_TO_MC_WK:3,WD_TO_MC_DAYS:21,BAR:21,MC_TARGET_WD_DATE:"2022-04-28",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:204,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1",SUBSYSTEM:"21-8020-8026-01",SUBSYSTEM_DESCR:"HULL Service Sea Water to HVAC Cooling",WD_PLAN:"2022-04-26",WD_DELTA:-5,WD_TIMES:1,MC_REFORECAST:"2022-05-10",WD_ACTUAL_DATE:"2022-04-21",MC_APPROVED:"2022-05-10",WD_TO_MC_WK:2,WD_TO_MC_DAYS:19,BAR:19,MC_TARGET_WD_DATE:"2022-04-26",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:205,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1",SUBSYSTEM:"21-6800-6830-01",SUBSYSTEM_DESCR:"Fire and Gas Detecion - LQ",WD_PLAN:"2022-04-28",WD_DELTA:-7,WD_TIMES:1,MC_REFORECAST:"2022-05-12",WD_ACTUAL_DATE:"2022-04-21",MC_APPROVED:"2022-05-12",WD_TO_MC_WK:3,WD_TO_MC_DAYS:21,BAR:21,MC_TARGET_WD_DATE:"2022-04-28",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:206,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P2",SUBSYSTEM:"21-6600-6624-01",SUBSYSTEM_DESCR:"LQ Emergency Lighting Panel and Circuits ,Room: LH91 21-ELP-662460",WD_PLAN:"2022-04-19",WD_DELTA:1,WD_TIMES:1,MC_REFORECAST:"2022-05-03",WD_ACTUAL_DATE:"2022-04-20",MC_APPROVED:"2022-05-16",WD_TO_MC_WK:3,WD_TO_MC_DAYS:26,BAR:26,MC_TARGET_WD_DATE:"2022-04-19",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:207,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P2",SUBSYSTEM:"21-6600-6624-02",SUBSYSTEM_DESCR:"LQ Emergency Lighting Panel and Circuits ,Room: L224 21-ELP-662461",WD_PLAN:"2022-04-19",WD_DELTA:1,WD_TIMES:1,MC_REFORECAST:"2022-05-03",WD_ACTUAL_DATE:"2022-04-20",MC_APPROVED:"2022-05-16",WD_TO_MC_WK:3,WD_TO_MC_DAYS:26,BAR:26,MC_TARGET_WD_DATE:"2022-04-19",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:208,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P2",SUBSYSTEM:"21-9921-9922-07",SUBSYSTEM_DESCR:"LQ Toilet Exhaust Fans, Ducting and Dampers",WD_PLAN:"2022-04-28",WD_DELTA:-8,WD_TIMES:1,MC_REFORECAST:"2022-05-12",WD_ACTUAL_DATE:"2022-04-20",MC_APPROVED:"2022-05-12",WD_TO_MC_WK:3,WD_TO_MC_DAYS:22,BAR:22,MC_TARGET_WD_DATE:"2022-04-28",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:209,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P2",SUBSYSTEM:"21-9921-9922-11",SUBSYSTEM_DESCR:"LQ Laboratory Exhaust Fans, Ducting and Dampers",WD_PLAN:"2022-04-25",WD_DELTA:-5,WD_TIMES:1,MC_REFORECAST:"2022-05-09",WD_ACTUAL_DATE:"2022-04-20",MC_APPROVED:"2022-05-09",WD_TO_MC_WK:2,WD_TO_MC_DAYS:19,BAR:19,MC_TARGET_WD_DATE:"2022-04-25",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:210,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P2",SUBSYSTEM:"21-8000-8011-01",SUBSYSTEM_DESCR:"Potable Water Tanks and Pumps",WD_PLAN:"2022-04-27",WD_DELTA:-8,WD_TIMES:1,MC_REFORECAST:"2022-05-11",WD_ACTUAL_DATE:"2022-04-19",MC_APPROVED:"2022-05-11",WD_TO_MC_WK:3,WD_TO_MC_DAYS:22,BAR:22,MC_TARGET_WD_DATE:"2022-04-27",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:211,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P2",SUBSYSTEM:"21-9380-9380-03",SUBSYSTEM_DESCR:"Bilge Water from MS Bilge Collection Header",WD_PLAN:"2022-04-11",WD_DELTA:7,WD_TIMES:1,MC_REFORECAST:"2022-04-25",WD_ACTUAL_DATE:"2022-04-18",MC_APPROVED:"2022-05-09",WD_TO_MC_WK:3,WD_TO_MC_DAYS:21,BAR:21,MC_TARGET_WD_DATE:"2022-04-11",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:212,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1",SUBSYSTEM:"21-6200-6210-07",SUBSYSTEM_DESCR:"PAGA Field Equipment LQ HULL Deck A",WD_PLAN:"2022-05-17",WD_DELTA:-32,WD_TIMES:1,MC_REFORECAST:"2022-05-31",WD_ACTUAL_DATE:"2022-04-15",MC_APPROVED:"2022-05-31",WD_TO_MC_WK:6,WD_TO_MC_DAYS:46,BAR:46,MC_TARGET_WD_DATE:"2022-05-17",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:213,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P2",SUBSYSTEM:"21-6600-6676-07",SUBSYSTEM_DESCR:"Normal Lighting Panel 06 ,ROOM: LA17 21-ELP-667661",WD_PLAN:"2022-04-06",WD_DELTA:-6,WD_TIMES:1,MC_REFORECAST:"2022-04-20",WD_ACTUAL_DATE:"2022-03-31",MC_APPROVED:"2022-05-16",WD_TO_MC_WK:6,WD_TO_MC_DAYS:46,BAR:46,MC_TARGET_WD_DATE:"2022-04-06",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:214,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P2",SUBSYSTEM:"21-6600-6676-06",SUBSYSTEM_DESCR:"Normal Lighting Panel 05 ,ROOM: LH05 21-ELP-667660",WD_PLAN:"2022-04-06",WD_DELTA:-7,WD_TIMES:1,MC_REFORECAST:"2022-04-20",WD_ACTUAL_DATE:"2022-03-30",MC_APPROVED:"2022-05-16",WD_TO_MC_WK:6,WD_TO_MC_DAYS:47,BAR:47,MC_TARGET_WD_DATE:"2022-04-06",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:215,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1",SUBSYSTEM:"21-6030-6043-02",SUBSYSTEM_DESCR:"FOPPs and Associated Fiber Cables to CER",WD_PLAN:"2022-04-06",WD_DELTA:-13,WD_TIMES:1,MC_REFORECAST:"2022-04-20",WD_ACTUAL_DATE:"2022-03-24",MC_APPROVED:"2022-05-18",WD_TO_MC_WK:7,WD_TO_MC_DAYS:55,BAR:55,MC_TARGET_WD_DATE:"2022-04-06",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:216,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P3",SUBSYSTEM:"21-9970-9971-02",SUBSYSTEM_DESCR:"HULL Machinery Space Supply Fans , Ducting and Dampers",WD_PLAN:"2022-04-09",WD_DELTA:-17,WD_TIMES:1,MC_REFORECAST:"2022-04-23",WD_ACTUAL_DATE:"2022-03-23",MC_APPROVED:"2022-05-11",WD_TO_MC_WK:7,WD_TO_MC_DAYS:49,BAR:49,MC_TARGET_WD_DATE:"2022-04-09",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:217,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1",SUBSYSTEM:"21-8060-8061-01",SUBSYSTEM_DESCR:"Diesel Bunker Header",WD_PLAN:"2022-03-04",WD_DELTA:3,WD_TIMES:1,MC_REFORECAST:"2022-03-18",WD_ACTUAL_DATE:"2022-03-07",MC_APPROVED:"2022-05-16",WD_TO_MC_WK:10,WD_TO_MC_DAYS:70,BAR:70,MC_TARGET_WD_DATE:"2022-03-04",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:218,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P3",SUBSYSTEM:"21-9970-9971-03",SUBSYSTEM_DESCR:"HULL Machinery Space Exhaust Fans , Ducting and Dampers",WD_PLAN:"2022-04-28",WD_DELTA:-57,WD_TIMES:1,MC_REFORECAST:"2022-05-12",WD_ACTUAL_DATE:"2022-03-02",MC_APPROVED:"2022-05-12",WD_TO_MC_WK:10,WD_TO_MC_DAYS:71,BAR:71,MC_TARGET_WD_DATE:"2022-04-28",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:219,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1A",SUBSYSTEM:"21-9350-9350-02",SUBSYSTEM_DESCR:"MS Ballast Ring Main",WD_PLAN:"2022-02-14",WD_DELTA:2,WD_TIMES:1,MC_REFORECAST:"2022-02-28",WD_ACTUAL_DATE:"2022-02-16",MC_APPROVED:"2022-05-16",WD_TO_MC_WK:12,WD_TO_MC_DAYS:89,BAR:89,MC_TARGET_WD_DATE:"2022-02-14",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:220,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1",SUBSYSTEM:"21-9350-9350-04",SUBSYSTEM_DESCR:"MS Ballast Pump 21-P-935002A",WD_PLAN:"2022-02-25",WD_DELTA:-9,WD_TIMES:1,MC_REFORECAST:"2022-03-11",WD_ACTUAL_DATE:"2022-02-16",MC_APPROVED:"2022-05-16",WD_TO_MC_WK:12,WD_TO_MC_DAYS:89,BAR:89,MC_TARGET_WD_DATE:"2022-02-25",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:221,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1",SUBSYSTEM:"21-9350-9350-05",SUBSYSTEM_DESCR:"MS Ballast Pump 21-P-935002B",WD_PLAN:"2022-02-25",WD_DELTA:-9,WD_TIMES:1,MC_REFORECAST:"2022-03-11",WD_ACTUAL_DATE:"2022-02-16",MC_APPROVED:"2022-05-16",WD_TO_MC_WK:12,WD_TO_MC_DAYS:89,BAR:89,MC_TARGET_WD_DATE:"2022-02-25",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:222,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1A",SUBSYSTEM:"21-9350-9350-01",SUBSYSTEM_DESCR:"MS Ballast Tanks WBW6,7 and WBE6,7 and FPE1 andFPW1",WD_PLAN:"2022-02-12",WD_DELTA:0,WD_TIMES:1,MC_REFORECAST:"2022-02-26",WD_ACTUAL_DATE:"2022-02-12",MC_APPROVED:"2022-05-18",WD_TO_MC_WK:13,WD_TO_MC_DAYS:95,BAR:95,MC_TARGET_WD_DATE:"2022-02-12",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:223,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1",SUBSYSTEM:"21-8060-8062-01",SUBSYSTEM_DESCR:"Diesel Service Tanks - 21-T-806003and4 and Supply Pumps - 21-P-806001AandB",WD_PLAN:"2022-02-14",WD_DELTA:-2,WD_TIMES:1,MC_REFORECAST:"2022-02-28",WD_ACTUAL_DATE:"2022-02-12",MC_APPROVED:"2022-05-13",WD_TO_MC_WK:12,WD_TO_MC_DAYS:90,BAR:90,MC_TARGET_WD_DATE:"2022-02-14",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:224,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1A",SUBSYSTEM:"21-8100-8121-01",SUBSYSTEM_DESCR:"HULL Instrument Air Distribution",WD_PLAN:"2022-02-14",WD_DELTA:-6,WD_TIMES:1,MC_REFORECAST:"2022-02-28",WD_ACTUAL_DATE:"2022-02-08",MC_APPROVED:"2022-05-16",WD_TO_MC_WK:13,WD_TO_MC_DAYS:97,BAR:97,MC_TARGET_WD_DATE:"2022-02-14",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:225,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1",SUBSYSTEM:"21-8060-8061-03",SUBSYSTEM_DESCR:"Diesel Supply and Return ,Spill Back Headers to Service Tanks - 21-T-806003and4",WD_PLAN:"2022-02-11",WD_DELTA:-7,WD_TIMES:1,MC_REFORECAST:"2022-02-25",WD_ACTUAL_DATE:"2022-02-04",MC_APPROVED:"2022-05-14",WD_TO_MC_WK:14,WD_TO_MC_DAYS:99,BAR:99,MC_TARGET_WD_DATE:"2022-02-11",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:226,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P3",SUBSYSTEM:"21-8100-8110-01",SUBSYSTEM_DESCR:"HULL Plant Air Distribution",WD_PLAN:"2022-01-14",WD_DELTA:-2,WD_TIMES:1,MC_REFORECAST:"2022-01-28",WD_ACTUAL_DATE:"2022-01-12",MC_APPROVED:"2022-05-18",WD_TO_MC_WK:18,WD_TO_MC_DAYS:126,BAR:126,MC_TARGET_WD_DATE:"2022-01-14",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:227,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1",SUBSYSTEM:"21-6070-6071-06",SUBSYSTEM_DESCR:"FGS Multicore and Junction Box to Marshalling and System Cabinets in HER",WD_PLAN:"2022-02-10",WD_DELTA:-30,WD_TIMES:1,MC_REFORECAST:"2022-02-24",WD_ACTUAL_DATE:"2022-01-11",MC_APPROVED:"2022-05-27",WD_TO_MC_WK:19,WD_TO_MC_DAYS:136,BAR:136,MC_TARGET_WD_DATE:"2022-02-10",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:228,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1",SUBSYSTEM:"21-6700-6701-02",SUBSYSTEM_DESCR:"LQ Telecom UPS System B for HULL and LQ ,Incl Batteries, Breakers and Subdistribution Board",WD_PLAN:"2021-11-12",WD_DELTA:-3,WD_TIMES:1,MC_REFORECAST:"2021-11-26",WD_ACTUAL_DATE:"2021-11-09",MC_APPROVED:"2022-05-05",WD_TO_MC_WK:25,WD_TO_MC_DAYS:177,BAR:177,MC_TARGET_WD_DATE:"2021-11-12",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:229,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1A",SUBSYSTEM:"21-6700-6701-01",SUBSYSTEM_DESCR:"Main LQ UPS System ,Incl Batteries, Breakers and Subdistribution Board",WD_PLAN:"2021-10-16",WD_DELTA:-8,WD_TIMES:1,MC_REFORECAST:"2021-10-30",WD_ACTUAL_DATE:"2021-10-08",MC_APPROVED:"2022-05-05",WD_TO_MC_WK:29,WD_TO_MC_DAYS:209,BAR:209,MC_TARGET_WD_DATE:"2021-10-16",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:230,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1",SUBSYSTEM:"21-6600-6674-01",SUBSYSTEM_DESCR:"HULL Normal and Essential 690 V Swtchboard 21-ESB-667401",WD_PLAN:"2021-11-24",WD_DELTA:-97,WD_TIMES:1,MC_REFORECAST:"2021-12-08",WD_ACTUAL_DATE:"2021-08-19",MC_APPROVED:"2022-05-30",WD_TO_MC_WK:40,WD_TO_MC_DAYS:284,BAR:284,MC_TARGET_WD_DATE:"2021-11-24",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:231,YEAR:2022,MON:5,MONTH:"May",PRIORITY:"P1",SUBSYSTEM:"21-6600-6675-01",SUBSYSTEM_DESCR:"HULL Normal and Essential 400 V Switchboard 21-ESB-667601",WD_PLAN:"2021-10-06",WD_DELTA:-48,WD_TIMES:1,MC_REFORECAST:"2021-10-20",WD_ACTUAL_DATE:"2021-08-19",MC_APPROVED:"2022-05-30",WD_TO_MC_WK:40,WD_TO_MC_DAYS:284,BAR:284,MC_TARGET_WD_DATE:"2021-10-06",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:232,YEAR:2022,MON:4,MONTH:"April",PRIORITY:"P3",SUBSYSTEM:"21-8780-8780-02",SUBSYSTEM_DESCR:"Earthing LQ",WD_PLAN:"2021-11-13",WD_DELTA:235,WD_TIMES:3,MC_REFORECAST:"2021-11-27",WD_ACTUAL_DATE:"2022-07-06",MC_APPROVED:"2022-04-16",WD_TO_MC_WK:-11,WD_TO_MC_DAYS:-81,BAR:-81,MC_TARGET_WD_DATE:"2021-11-13",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:233,YEAR:2022,MON:4,MONTH:"April",PRIORITY:"P1",SUBSYSTEM:"21-9500-9570-01",SUBSYSTEM_DESCR:"Gyrocompass",WD_PLAN:"2022-04-22",WD_DELTA:0,WD_TIMES:1,MC_REFORECAST:"2022-05-06",WD_ACTUAL_DATE:"2022-04-22",MC_APPROVED:"2022-04-29",WD_TO_MC_WK:1,WD_TO_MC_DAYS:7,BAR:7,MC_TARGET_WD_DATE:"2022-04-22",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:234,YEAR:2022,MON:4,MONTH:"April",PRIORITY:"P1",SUBSYSTEM:"21-6070-6071-04",SUBSYSTEM_DESCR:"FGS Multicore and Junction Box to Marshalling and System Cabinets in LER",WD_PLAN:"2022-04-13",WD_DELTA:8,WD_TIMES:1,MC_REFORECAST:"2022-04-27",WD_ACTUAL_DATE:"2022-04-21",MC_APPROVED:"2022-04-25",WD_TO_MC_WK:0,WD_TO_MC_DAYS:4,BAR:4,MC_TARGET_WD_DATE:"2022-04-13",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:235,YEAR:2022,MON:4,MONTH:"April",PRIORITY:"P2",SUBSYSTEM:"21-6600-6676-03",SUBSYSTEM_DESCR:"Machinary Workshop Power Panel ,ROOM: M104 21-ELP-667636",WD_PLAN:"2022-04-19",WD_DELTA:0,WD_TIMES:1,MC_REFORECAST:"2022-05-03",WD_ACTUAL_DATE:"2022-04-19",MC_APPROVED:"2022-04-25",WD_TO_MC_WK:0,WD_TO_MC_DAYS:6,BAR:6,MC_TARGET_WD_DATE:"2022-04-19",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:236,YEAR:2022,MON:4,MONTH:"April",PRIORITY:"P1",SUBSYSTEM:"21-8020-8027-01",SUBSYSTEM_DESCR:"HULL Service Sea Water Booster Pumps - 21-P-802008AandB",WD_PLAN:"2022-04-13",WD_DELTA:6,WD_TIMES:1,MC_REFORECAST:"2022-04-27",WD_ACTUAL_DATE:"2022-04-19",MC_APPROVED:"2022-04-25",WD_TO_MC_WK:0,WD_TO_MC_DAYS:6,BAR:6,MC_TARGET_WD_DATE:"2022-04-13",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:237,YEAR:2022,MON:4,MONTH:"April",PRIORITY:"P2",SUBSYSTEM:"21-9380-9380-01",SUBSYSTEM_DESCR:"Daily bilge Tank 21-T-938001 and Pumps 21-P-938001AandB",WD_PLAN:"2022-04-09",WD_DELTA:9,WD_TIMES:1,MC_REFORECAST:"2022-04-23",WD_ACTUAL_DATE:"2022-04-18",MC_APPROVED:"2022-04-21",WD_TO_MC_WK:0,WD_TO_MC_DAYS:3,BAR:3,MC_TARGET_WD_DATE:"2022-04-09",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:238,YEAR:2022,MON:4,MONTH:"April",PRIORITY:"P2",SUBSYSTEM:"21-8020-8023-01",SUBSYSTEM_DESCR:"IGG Deck Seal Sea Water Pumps - 21-P802002AandB",WD_PLAN:"2022-04-09",WD_DELTA:7,WD_TIMES:1,MC_REFORECAST:"2022-04-23",WD_ACTUAL_DATE:"2022-04-16",MC_APPROVED:"2022-04-21",WD_TO_MC_WK:0,WD_TO_MC_DAYS:5,BAR:5,MC_TARGET_WD_DATE:"2022-04-09",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:239,YEAR:2022,MON:4,MONTH:"April",PRIORITY:"P2",SUBSYSTEM:"21-6600-6676-09",SUBSYSTEM_DESCR:"Normal Lighting Panel 08 ,ROOM: L225 21-ELP-667663",WD_PLAN:"2022-04-11",WD_DELTA:5,WD_TIMES:1,MC_REFORECAST:"2022-04-25",WD_ACTUAL_DATE:"2022-04-16",MC_APPROVED:"2022-04-22",WD_TO_MC_WK:0,WD_TO_MC_DAYS:6,BAR:6,MC_TARGET_WD_DATE:"2022-04-11",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:240,YEAR:2022,MON:4,MONTH:"April",PRIORITY:"P1",SUBSYSTEM:"21-6070-6072-01",SUBSYSTEM_DESCR:"Fire Addressable Panels - LQ ,CER and LQ Fire and Gas Addressable Loop-1",WD_PLAN:"2022-01-14",WD_DELTA:92,WD_TIMES:2,MC_REFORECAST:"2022-01-28",WD_ACTUAL_DATE:"2022-04-16",MC_APPROVED:"2022-04-21",WD_TO_MC_WK:0,WD_TO_MC_DAYS:5,BAR:5,MC_TARGET_WD_DATE:"2022-01-14",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:241,YEAR:2022,MON:4,MONTH:"April",PRIORITY:"P1",SUBSYSTEM:"21-6200-6210-08",SUBSYSTEM_DESCR:"PAGA Field Equipment LQ Levels 1 and 2",WD_PLAN:"2022-04-11",WD_DELTA:4,WD_TIMES:1,MC_REFORECAST:"2022-04-25",WD_ACTUAL_DATE:"2022-04-15",MC_APPROVED:"2022-04-21",WD_TO_MC_WK:0,WD_TO_MC_DAYS:6,BAR:6,MC_TARGET_WD_DATE:"2022-04-11",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:242,YEAR:2022,MON:4,MONTH:"April",PRIORITY:"P1A",SUBSYSTEM:"21-6030-6036-01",SUBSYSTEM_DESCR:"PDBs Inside HER",WD_PLAN:"2021-11-11",WD_DELTA:155,WD_TIMES:2,MC_REFORECAST:"2021-11-25",WD_ACTUAL_DATE:"2022-04-15",MC_APPROVED:"2022-04-19",WD_TO_MC_WK:0,WD_TO_MC_DAYS:4,BAR:4,MC_TARGET_WD_DATE:"2021-11-11",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:243,YEAR:2022,MON:4,MONTH:"April",PRIORITY:"P1",SUBSYSTEM:"21-8020-8025-01",SUBSYSTEM_DESCR:"HULL Service Sea Water Pumps - 21-P-802005AandB",WD_PLAN:"2022-02-26",WD_DELTA:48,WD_TIMES:2,MC_REFORECAST:"2022-03-12",WD_ACTUAL_DATE:"2022-04-15",MC_APPROVED:"2022-04-19",WD_TO_MC_WK:0,WD_TO_MC_DAYS:4,BAR:4,MC_TARGET_WD_DATE:"2022-02-26",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:244,YEAR:2022,MON:4,MONTH:"April",PRIORITY:"P2",SUBSYSTEM:"21-9380-9380-02",SUBSYSTEM_DESCR:"Daily Bilge Wells and Headers to Daily Bilge Tank",WD_PLAN:"2022-04-09",WD_DELTA:-10,WD_TIMES:1,MC_REFORECAST:"2022-04-23",WD_ACTUAL_DATE:"2022-03-30",MC_APPROVED:"2022-04-21",WD_TO_MC_WK:3,WD_TO_MC_DAYS:22,BAR:22,MC_TARGET_WD_DATE:"2022-04-09",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:245,YEAR:2022,MON:4,MONTH:"April",PRIORITY:"P2",SUBSYSTEM:"21-9921-9922-09",SUBSYSTEM_DESCR:"LQ Sick Bay Exhaust Fans, Ducting and Dampers",WD_PLAN:"2022-04-08",WD_DELTA:-10,WD_TIMES:1,MC_REFORECAST:"2022-04-22",WD_ACTUAL_DATE:"2022-03-29",MC_APPROVED:"2022-04-19",WD_TO_MC_WK:3,WD_TO_MC_DAYS:21,BAR:21,MC_TARGET_WD_DATE:"2022-04-08",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:246,YEAR:2022,MON:4,MONTH:"April",PRIORITY:"P1",SUBSYSTEM:"21-6070-6072-04",SUBSYSTEM_DESCR:"LQ Fire and Gas Addressable Loop-4",WD_PLAN:"2022-04-04",WD_DELTA:-6,WD_TIMES:1,MC_REFORECAST:"2022-04-18",WD_ACTUAL_DATE:"2022-03-29",MC_APPROVED:"2022-04-15",WD_TO_MC_WK:2,WD_TO_MC_DAYS:17,BAR:17,MC_TARGET_WD_DATE:"2022-04-04",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:247,YEAR:2022,MON:4,MONTH:"April",PRIORITY:"P2",SUBSYSTEM:"21-8000-8010-02",SUBSYSTEM_DESCR:"Chlorination Package",WD_PLAN:"2022-04-04",WD_DELTA:-10,WD_TIMES:1,MC_REFORECAST:"2022-04-18",WD_ACTUAL_DATE:"2022-03-25",MC_APPROVED:"2022-04-14",WD_TO_MC_WK:2,WD_TO_MC_DAYS:20,BAR:20,MC_TARGET_WD_DATE:"2022-04-04",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:248,YEAR:2022,MON:4,MONTH:"April",PRIORITY:"P1",SUBSYSTEM:"21-8050-8051-03",SUBSYSTEM_DESCR:"Marine Cooling Medium Distribution",WD_PLAN:"2022-04-05",WD_DELTA:-11,WD_TIMES:1,MC_REFORECAST:"2022-04-19",WD_ACTUAL_DATE:"2022-03-25",MC_APPROVED:"2022-04-15",WD_TO_MC_WK:3,WD_TO_MC_DAYS:21,BAR:21,MC_TARGET_WD_DATE:"2022-04-05",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:249,YEAR:2022,MON:4,MONTH:"April",PRIORITY:"P1",SUBSYSTEM:"21-6070-6072-05",SUBSYSTEM_DESCR:"LQ Fire and Gas Addressable Loop-5",WD_PLAN:"2022-04-04",WD_DELTA:-11,WD_TIMES:1,MC_REFORECAST:"2022-04-18",WD_ACTUAL_DATE:"2022-03-24",MC_APPROVED:"2022-04-15",WD_TO_MC_WK:3,WD_TO_MC_DAYS:22,BAR:22,MC_TARGET_WD_DATE:"2022-04-04",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:250,YEAR:2022,MON:4,MONTH:"April",PRIORITY:"P1",SUBSYSTEM:"21-6070-6072-02",SUBSYSTEM_DESCR:"LQ Fire and Gas Addressable Loop-2",WD_PLAN:"2022-04-04",WD_DELTA:-11,WD_TIMES:1,MC_REFORECAST:"2022-04-18",WD_ACTUAL_DATE:"2022-03-24",MC_APPROVED:"2022-04-15",WD_TO_MC_WK:3,WD_TO_MC_DAYS:22,BAR:22,MC_TARGET_WD_DATE:"2022-04-04",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:251,YEAR:2022,MON:4,MONTH:"April",PRIORITY:"P1",SUBSYSTEM:"21-8050-8051-02",SUBSYSTEM_DESCR:"Marine Cooling Medium Expansion Tank",WD_PLAN:"2022-04-05",WD_DELTA:-13,WD_TIMES:1,MC_REFORECAST:"2022-04-19",WD_ACTUAL_DATE:"2022-03-23",MC_APPROVED:"2022-04-16",WD_TO_MC_WK:3,WD_TO_MC_DAYS:24,BAR:24,MC_TARGET_WD_DATE:"2022-04-05",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:252,YEAR:2022,MON:4,MONTH:"April",PRIORITY:"P1",SUBSYSTEM:"21-8050-8051-01",SUBSYSTEM_DESCR:"Marine Cooling Medium Pumps - 21-P805002AandB",WD_PLAN:"2022-04-08",WD_DELTA:-17,WD_TIMES:1,MC_REFORECAST:"2022-04-22",WD_ACTUAL_DATE:"2022-03-22",MC_APPROVED:"2022-04-19",WD_TO_MC_WK:4,WD_TO_MC_DAYS:28,BAR:28,MC_TARGET_WD_DATE:"2022-04-08",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:253,YEAR:2022,MON:4,MONTH:"April",PRIORITY:"P1",SUBSYSTEM:"21-6070-6072-06",SUBSYSTEM_DESCR:"LQ Fire and Gas Addressable Loop-6",WD_PLAN:"2022-04-04",WD_DELTA:-17,WD_TIMES:1,MC_REFORECAST:"2022-04-18",WD_ACTUAL_DATE:"2022-03-18",MC_APPROVED:"2022-04-15",WD_TO_MC_WK:4,WD_TO_MC_DAYS:28,BAR:28,MC_TARGET_WD_DATE:"2022-04-04",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:254,YEAR:2022,MON:4,MONTH:"April",PRIORITY:"P1",SUBSYSTEM:"21-9921-9921-04",SUBSYSTEM_DESCR:"LQ Emergency SWB Room ACUsandACCUs and UCPs",WD_PLAN:"2022-04-06",WD_DELTA:-21,WD_TIMES:1,MC_REFORECAST:"2022-04-20",WD_ACTUAL_DATE:"2022-03-16",MC_APPROVED:"2022-04-14",WD_TO_MC_WK:4,WD_TO_MC_DAYS:29,BAR:29,MC_TARGET_WD_DATE:"2022-04-06",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:255,YEAR:2022,MON:4,MONTH:"April",PRIORITY:"P2",SUBSYSTEM:"21-8000-8012-04",SUBSYSTEM_DESCR:"Cold Potable Water Distribution to LQ",WD_PLAN:"2022-04-13",WD_DELTA:-29,WD_TIMES:1,MC_REFORECAST:"2022-04-27",WD_ACTUAL_DATE:"2022-03-15",MC_APPROVED:"2022-04-23",WD_TO_MC_WK:5,WD_TO_MC_DAYS:39,BAR:39,MC_TARGET_WD_DATE:"2022-04-13",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:256,YEAR:2022,MON:4,MONTH:"April",PRIORITY:"P1",SUBSYSTEM:"21-6030-6032-04",SUBSYSTEM_DESCR:"ICSS Workstations and Consoles - CCR",WD_PLAN:"2022-04-13",WD_DELTA:-33,WD_TIMES:1,MC_REFORECAST:"2022-04-27",WD_ACTUAL_DATE:"2022-03-11",MC_APPROVED:"2022-04-25",WD_TO_MC_WK:6,WD_TO_MC_DAYS:45,BAR:45,MC_TARGET_WD_DATE:"2022-04-13",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:257,YEAR:2022,MON:4,MONTH:"April",PRIORITY:"P2",SUBSYSTEM:"21-9921-9921-02",SUBSYSTEM_DESCR:"LQ Battery Exhaust Fans, Ducting and Dampers",WD_PLAN:"2022-04-21",WD_DELTA:-43,WD_TIMES:1,MC_REFORECAST:"2022-05-05",WD_ACTUAL_DATE:"2022-03-09",MC_APPROVED:"2022-04-28",WD_TO_MC_WK:7,WD_TO_MC_DAYS:50,BAR:50,MC_TARGET_WD_DATE:"2022-04-21",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:258,YEAR:2022,MON:4,MONTH:"April",PRIORITY:"P1",SUBSYSTEM:"21-9921-9921-06",SUBSYSTEM_DESCR:"LQ CER ACUsandACCUs and UCPs",WD_PLAN:"2022-04-07",WD_DELTA:-33,WD_TIMES:1,MC_REFORECAST:"2022-04-21",WD_ACTUAL_DATE:"2022-03-05",MC_APPROVED:"2022-04-15",WD_TO_MC_WK:5,WD_TO_MC_DAYS:41,BAR:41,MC_TARGET_WD_DATE:"2022-04-07",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:259,YEAR:2022,MON:4,MONTH:"April",PRIORITY:"P1",SUBSYSTEM:"21-9921-9921-05",SUBSYSTEM_DESCR:"LQ CCR ACUsandACCUs and UCPs",WD_PLAN:"2022-04-06",WD_DELTA:-37,WD_TIMES:1,MC_REFORECAST:"2022-04-20",WD_ACTUAL_DATE:"2022-02-28",MC_APPROVED:"2022-04-14",WD_TO_MC_WK:6,WD_TO_MC_DAYS:45,BAR:45,MC_TARGET_WD_DATE:"2022-04-06",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:260,YEAR:2022,MON:4,MONTH:"April",PRIORITY:"P1",SUBSYSTEM:"21-9921-9921-08",SUBSYSTEM_DESCR:"LQ Dining Room and Secondary Muster ACUsandACCUs and UCPs",WD_PLAN:"2022-04-07",WD_DELTA:-69,WD_TIMES:1,MC_REFORECAST:"2022-04-21",WD_ACTUAL_DATE:"2022-01-28",MC_APPROVED:"2022-04-15",WD_TO_MC_WK:11,WD_TO_MC_DAYS:77,BAR:77,MC_TARGET_WD_DATE:"2022-04-07",WD_ACTUAL:"DONE-PL RCVD"},
      {NO:261,YEAR:2022,MON:4,MONTH:"April",PRIORITY:"P1A",SUBSYSTEM:"21-6600-6660-01",SUBSYSTEM_DESCR:"Emergency 690 V Switchboard 21-ESB-666001",WD_PLAN:"2021-11-06",WD_DELTA:-29,WD_TIMES:1,MC_REFORECAST:"2021-11-20",WD_ACTUAL_DATE:"2021-10-08",MC_APPROVED:"2022-04-30",WD_TO_MC_WK:29,WD_TO_MC_DAYS:204,BAR:204,MC_TARGET_WD_DATE:"2021-11-06",WD_ACTUAL:"DONE-PL RCVD"}
]
}