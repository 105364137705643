import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 260,
      CanvasHeight: 300,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },
    DataItems:  [

      {CDATE:"2022-07-15",PACKAGE:"OVERALL",TITLE:"Project to Date",VALUE:5450448},
      {CDATE:"2022-07-15",PACKAGE:"OVERALL",TITLE:"Year to Date",VALUE:2851166},
      {CDATE:"2022-07-15",PACKAGE:"OVERALL",TITLE:"This Month",VALUE:269896},
      {CDATE:"2022-07-15",PACKAGE:"OVERALL",TITLE:"SEI Project to Date",VALUE:3097201},
      {CDATE:"2022-07-15",PACKAGE:"OVERALL",TITLE:"SEI This Month",VALUE:209702},
      {CDATE:"2022-07-15",PACKAGE:"OVERALL",TITLE:"TRI Free Hrs",VALUE:5450448}
    
    ],
}