import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 940,
      CanvasHeight: 460,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },
    // Queries: {
    //   SQL1: [
    //     {IG_NO:"IG-E2-001",LOCATIONS:"South BL gate of  Main Plant",COORDINATION:"X: 2514432.060     Y: 558266.500  ",REMARKS:"IP with HQGD/SHDI/GEG SHP steam/NG"},
    //   ]
    // },
    DataItems:  [
      {SEQ:1,DISC_CODE:"A",DISC_DESCR:"Architectural",NEW:0,DELETED:0,INPROGRESS:0,DELIVERED:0,CLOSED:0,REJECTED:0,RESUBMITTED:0,OVERDUE_IP:0,CRITICAL_IP:0},
      {SEQ:2,DISC_CODE:"B",DISC_DESCR:"Business and General",NEW:0,DELETED:0,INPROGRESS:0,DELIVERED:0,CLOSED:0,REJECTED:0,RESUBMITTED:0,OVERDUE_IP:0,CRITICAL_IP:0},
      {SEQ:3,DISC_CODE:"C",DISC_DESCR:"Civil and Infrastructure",NEW:0,DELETED:0,INPROGRESS:0,DELIVERED:0,CLOSED:0,REJECTED:0,RESUBMITTED:0,OVERDUE_IP:0,CRITICAL_IP:0},
      {SEQ:4,DISC_CODE:"D",DISC_DESCR:"Mechanical and Static Equipment",NEW:0,DELETED:0,INPROGRESS:0,DELIVERED:0,CLOSED:0,REJECTED:0,RESUBMITTED:0,OVERDUE_IP:0,CRITICAL_IP:0},
      {SEQ:5,DISC_CODE:"E",DISC_DESCR:"Electrical",NEW:0,DELETED:0,INPROGRESS:0,DELIVERED:0,CLOSED:0,REJECTED:0,RESUBMITTED:0,OVERDUE_IP:0,CRITICAL_IP:0},
      {SEQ:6,DISC_CODE:"F",DISC_DESCR:"Risk and Loss Prevention",NEW:0,DELETED:0,INPROGRESS:0,DELIVERED:0,CLOSED:0,REJECTED:0,RESUBMITTED:0,OVERDUE_IP:0,CRITICAL_IP:0},
      {SEQ:7,DISC_CODE:"G",DISC_DESCR:"Geotechnical and Geophysical",NEW:0,DELETED:0,INPROGRESS:0,DELIVERED:0,CLOSED:0,REJECTED:0,RESUBMITTED:0,OVERDUE_IP:0,CRITICAL_IP:0},
      {SEQ:8,DISC_CODE:"H",DISC_DESCR:"HVAC",NEW:0,DELETED:0,INPROGRESS:0,DELIVERED:0,CLOSED:0,REJECTED:0,RESUBMITTED:0,OVERDUE_IP:0,CRITICAL_IP:0},
      {SEQ:9,DISC_CODE:"I",DISC_DESCR:"Instrumentation and Metering",NEW:0,DELETED:0,INPROGRESS:0,DELIVERED:0,CLOSED:1,REJECTED:0,RESUBMITTED:0,OVERDUE_IP:0,CRITICAL_IP:0},
      {SEQ:10,DISC_CODE:"J",DISC_DESCR:"Marine",NEW:0,DELETED:0,INPROGRESS:0,DELIVERED:0,CLOSED:0,REJECTED:0,RESUBMITTED:0,OVERDUE_IP:0,CRITICAL_IP:0},
      {SEQ:11,DISC_CODE:"K",DISC_DESCR:"Construction, Transportation, and Logistics",NEW:0,DELETED:0,INPROGRESS:0,DELIVERED:0,CLOSED:0,REJECTED:0,RESUBMITTED:0,OVERDUE_IP:0,CRITICAL_IP:0},
      {SEQ:12,DISC_CODE:"L",DISC_DESCR:"Piping and General Layout",NEW:0,DELETED:0,INPROGRESS:0,DELIVERED:0,CLOSED:22,REJECTED:0,RESUBMITTED:0,OVERDUE_IP:0,CRITICAL_IP:0},
      {SEQ:13,DISC_CODE:"M",DISC_DESCR:"Rotating Machinery",NEW:0,DELETED:0,INPROGRESS:0,DELIVERED:0,CLOSED:0,REJECTED:0,RESUBMITTED:0,OVERDUE_IP:0,CRITICAL_IP:0},
      {SEQ:14,DISC_CODE:"N",DISC_DESCR:"Structural (Weight Control)",NEW:0,DELETED:0,INPROGRESS:0,DELIVERED:0,CLOSED:0,REJECTED:0,RESUBMITTED:0,OVERDUE_IP:0,CRITICAL_IP:0},
      {SEQ:15,DISC_CODE:"O",DISC_DESCR:"Operations Readiness",NEW:0,DELETED:0,INPROGRESS:0,DELIVERED:0,CLOSED:0,REJECTED:0,RESUBMITTED:0,OVERDUE_IP:0,CRITICAL_IP:0},
      {SEQ:16,DISC_CODE:"P",DISC_DESCR:"Process",NEW:0,DELETED:0,INPROGRESS:0,DELIVERED:0,CLOSED:0,REJECTED:0,RESUBMITTED:0,OVERDUE_IP:0,CRITICAL_IP:0},
      {SEQ:17,DISC_CODE:"Q",DISC_DESCR:"Quality",NEW:0,DELETED:0,INPROGRESS:0,DELIVERED:0,CLOSED:0,REJECTED:0,RESUBMITTED:0,OVERDUE_IP:0,CRITICAL_IP:0},
      {SEQ:18,DISC_CODE:"R",DISC_DESCR:"Regulatory, Environmental, and Socioeconomic",NEW:0,DELETED:0,INPROGRESS:0,DELIVERED:0,CLOSED:0,REJECTED:0,RESUBMITTED:0,OVERDUE_IP:0,CRITICAL_IP:0},
      {SEQ:19,DISC_CODE:"S",DISC_DESCR:"Safety, Health, and Security",NEW:0,DELETED:0,INPROGRESS:0,DELIVERED:0,CLOSED:0,REJECTED:0,RESUBMITTED:0,OVERDUE_IP:0,CRITICAL_IP:0},
      {SEQ:20,DISC_CODE:"T",DISC_DESCR:"Telecommunications",NEW:0,DELETED:0,INPROGRESS:0,DELIVERED:0,CLOSED:0,REJECTED:0,RESUBMITTED:0,OVERDUE_IP:0,CRITICAL_IP:0},
      {SEQ:21,DISC_CODE:"V",DISC_DESCR:"Contracting and Procurement",NEW:0,DELETED:0,INPROGRESS:0,DELIVERED:0,CLOSED:0,REJECTED:0,RESUBMITTED:0,OVERDUE_IP:0,CRITICAL_IP:0},
      {SEQ:22,DISC_CODE:"W",DISC_DESCR:"Systems Completions, Commissioning, and Start-Up",NEW:0,DELETED:0,INPROGRESS:0,DELIVERED:0,CLOSED:0,REJECTED:0,RESUBMITTED:0,OVERDUE_IP:0,CRITICAL_IP:0},
      {SEQ:23,DISC_CODE:"X",DISC_DESCR:"Materials, Corrosion, and Flow Assurance",NEW:0,DELETED:0,INPROGRESS:0,DELIVERED:0,CLOSED:0,REJECTED:0,RESUBMITTED:0,OVERDUE_IP:0,CRITICAL_IP:0},
      {SEQ:24,DISC_CODE:"Y",DISC_DESCR:"Pipelines, Umbilicals, Risers, and Flowlines",NEW:0,DELETED:0,INPROGRESS:0,DELIVERED:0,CLOSED:0,REJECTED:0,RESUBMITTED:0,OVERDUE_IP:0,CRITICAL_IP:0}
    ],
}