import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 330,
      CanvasHeight: 330,
      CanvasBorderWeight: 0.5,
      CanvasBorderColor: '#757575',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     

    DataItems: [
      { NO: 1, SUBSYSTEM: '6720-42', MC_PLAN: '2020-03-06', PA_TOTAL: 8, PA_ACTUAL: 4, PA_REMAIN: 4, PB_TOTAL: 268, PB_ACTUAL: 201, PB_REMAIN: 67, },
{ NO: 2, SUBSYSTEM: '8040-02', MC_PLAN: '2020-03-06', PA_TOTAL: 152, PA_ACTUAL: 116, PA_REMAIN: 36, PB_TOTAL: 150, PB_ACTUAL: 125, PB_REMAIN: 25, },
{ NO: 3, SUBSYSTEM: '8040-05', MC_PLAN: '2020-03-06', PA_TOTAL: 20, PA_ACTUAL: 7, PA_REMAIN: 13, PB_TOTAL: 58, PB_ACTUAL: 41, PB_REMAIN: 17, },
{ NO: 4, SUBSYSTEM: '6720-41', MC_PLAN: '2020-03-06', PA_TOTAL: 7, PA_ACTUAL: 2, PA_REMAIN: 5, PB_TOTAL: 52, PB_ACTUAL: 27, PB_REMAIN: 25, },
{ NO: 5, SUBSYSTEM: '6073-01', MC_PLAN: '2020-03-06', PA_TOTAL: 7, PA_ACTUAL: 0, PA_REMAIN: 7, PB_TOTAL: 14, PB_ACTUAL: 1, PB_REMAIN: 13, },
{ NO: 6, SUBSYSTEM: '8040-03', MC_PLAN: '2020-03-06', PA_TOTAL: 17, PA_ACTUAL: 8, PA_REMAIN: 9, PB_TOTAL: 46, PB_ACTUAL: 36, PB_REMAIN: 10, },
{ NO: 7, SUBSYSTEM: '8120-04', MC_PLAN: '2020-03-06', PA_TOTAL: 119, PA_ACTUAL: 114, PA_REMAIN: 5, PB_TOTAL: 71, PB_ACTUAL: 60, PB_REMAIN: 11, },
{ NO: 8, SUBSYSTEM: '6070-13', MC_PLAN: '2020-03-06', PA_TOTAL: 4, PA_ACTUAL: 2, PA_REMAIN: 2, PB_TOTAL: 14, PB_ACTUAL: 5, PB_REMAIN: 9, },
{ NO: 9, SUBSYSTEM: '6070-14', MC_PLAN: '2020-03-06', PA_TOTAL: 11, PA_ACTUAL: 10, PA_REMAIN: 1, PB_TOTAL: 11, PB_ACTUAL: 2, PB_REMAIN: 9, },
{ NO: 10, SUBSYSTEM: '6210-01', MC_PLAN: '2020-03-06', PA_TOTAL: 25, PA_ACTUAL: 20, PA_REMAIN: 5, PB_TOTAL: 15, PB_ACTUAL: 13, PB_REMAIN: 2, },
{ NO: 11, SUBSYSTEM: '9560-06', MC_PLAN: '2020-03-06', PA_TOTAL: 11, PA_ACTUAL: 5, PA_REMAIN: 6, PB_TOTAL: 0, PB_ACTUAL: 0, PB_REMAIN: 0, },
{ NO: 12, SUBSYSTEM: '6720-46', MC_PLAN: '2020-03-06', PA_TOTAL: 2, PA_ACTUAL: 2, PA_REMAIN: 0, PB_TOTAL: 7, PB_ACTUAL: 2, PB_REMAIN: 5, },
{ NO: 13, SUBSYSTEM: '8120-10', MC_PLAN: '2020-03-06', PA_TOTAL: 2, PA_ACTUAL: 1, PA_REMAIN: 1, PB_TOTAL: 28, PB_ACTUAL: 25, PB_REMAIN: 3, },
{ NO: 14, SUBSYSTEM: '6720-45', MC_PLAN: '2020-03-06', PA_TOTAL: 3, PA_ACTUAL: 3, PA_REMAIN: 0, PB_TOTAL: 11, PB_ACTUAL: 9, PB_REMAIN: 2, },
{ NO: 15, SUBSYSTEM: '8720-05', MC_PLAN: '2020-03-13', PA_TOTAL: 15, PA_ACTUAL: 6, PA_REMAIN: 9, PB_TOTAL: 198, PB_ACTUAL: 155, PB_REMAIN: 43, },

    ]
}