import JLibChart from '../../lib/jin/svgchartlib/01_Charts/00_Customs'
import JLibPage from '../../lib/jin/svgchartlib/02_PageComponents/00_Customs'

import JLibChartData from '../../lib/jin/svgchartlib/01_Charts/00_Customs/data'
import JLibPageData from '../../lib/jin/svgchartlib/02_PageComponents/00_Customs/data'

// Component svg2
import JLibV2Chart from '../../lib/jin/svgchartlib-v2/export/Component_index'
// China1 Project svg2
import JLibV2Page from '../../lib/jin/svgchartlib-v2/export/China1_index'

// Component Data svg2
import JLibV2ChartData from '../../lib/jin/svgchartlib-v2/export/Component_data'
// China1 Project Data svg2
import JLibV2PageData from '../../lib/jin/svgchartlib-v2/export/China1_data'



export default {
  Component: {
    ...JLibChart,
    ...JLibPage,
    ...JLibV2Page,
    ...JLibV2Chart,
  },
  Data: {
    ...JLibChartData,
    ...JLibPageData,
    ...JLibV2PageData,
    ...JLibV2ChartData,
  }
}

