export default {
  Canvas: {
    CanvasWidth: 1600,
    CanvasHeight: 1120,
    CanvasBorderWeight: 0.2,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',//0E1B2B
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 760,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },

  Queries: {
    SQL1:  []
  },

  Text: {
    Text: [
      { text: 'Note', x: 1350, y: 20, font: 'roboto', class: 'regular', size: 11, color: '#44A9DF', align: 'start' },
    ],
    Filter: [
      { name: 'MOD', x: 1350, y: 30, font: 'roboto', class: 'regular', size: 11, color: '#757575', align: 'start' },
    ],
    Sql: [
      {
        query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
        text: 'Data Source',
        x: 41,
        y: 115,
        font: 'roboto',
        style: 'regular',
        size: 10,
        color: '#757575',
        align: 'start'
      },
    ]
  },

  DataItems:  [{
    eqList: [
      {NO:238,TAG:"3011T021-I",TAG_DESCR:"MAIN WASHER INTERNAL",TAG_DESCR_SHORT:"3011T021-I / MAIN WASHER INTERNAL",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0103",MR_DESCR:"MAIN WASHER INTERNAL主洗塔内件",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",MR_PLAN:"2021-11-02",MR_FORECAST:"2021-11-02",MR_ACTUAL:"2021-11-02",LOA_PLAN:"2022-04-01",LOA_FORECAST:"2022-05-16",LOA_ACTUAL:"2022-05-16",PO_PLAN:"2022-05-13",PO_FORECAST:"2022-06-27",PO_ACTUAL:"2022-08-11",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:239,TAG:"3011T022-I",TAG_DESCR:"RECTIFIERAFTERWASHER INTERNAL",TAG_DESCR_SHORT:"3011T022-I / RECTIFIERAFTERWASHER INTERNAL",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0103",MR_DESCR:"RECTIFIER/AFTERWASHER INTERNAL精馏/后洗塔内件",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",MR_PLAN:"2021-11-02",MR_FORECAST:"2021-11-02",MR_ACTUAL:"2021-11-02",LOA_PLAN:"2022-04-01",LOA_FORECAST:"2022-05-16",LOA_ACTUAL:"2022-05-16",PO_PLAN:"2022-05-13",PO_FORECAST:"2022-06-27",PO_ACTUAL:"2022-08-11",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:240,TAG:"3012FIL031A",TAG_DESCR:"Acetylene Washer Bottom Filter",TAG_DESCR_SHORT:"3012FIL031A / Acetylene Washer Bottom Filter",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0501",MR_DESCR:"Acetylene Washer Bottom Filter 过滤器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-10",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-08-04",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-09-28",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:241,TAG:"3012FIL031B",TAG_DESCR:"Acetylene Washer Bottom Filter",TAG_DESCR_SHORT:"3012FIL031B / Acetylene Washer Bottom Filter",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0501",MR_DESCR:"Acetylene Washer Bottom Filter 过滤器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-10",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-08-04",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-09-28",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:242,TAG:"3012T031-I",TAG_DESCR:"DEGASSER INTERNAL",TAG_DESCR_SHORT:"3012T031-I / DEGASSER INTERNAL",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0103",MR_DESCR:"DEGASSER INTERNAL脱气塔内件",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",MR_PLAN:"2021-11-02",MR_FORECAST:"2021-11-02",MR_ACTUAL:"2021-11-02",LOA_PLAN:"2022-04-01",LOA_FORECAST:"2022-05-16",LOA_ACTUAL:"2022-05-16",PO_PLAN:"2022-05-13",PO_FORECAST:"2022-06-27",PO_ACTUAL:"2022-08-11",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:243,TAG:"3012T032-I",TAG_DESCR:"ACETYLENE WASHER INTERNAL",TAG_DESCR_SHORT:"3012T032-I / ACETYLENE WASHER INTERNAL",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0103",MR_DESCR:"ACETYLENE WASHER INTERNAL炔烃洗涤塔内件",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",MR_PLAN:"2021-11-02",MR_FORECAST:"2021-11-02",MR_ACTUAL:"2021-11-02",LOA_PLAN:"2022-04-01",LOA_FORECAST:"2022-05-16",LOA_ACTUAL:"2022-05-16",PO_PLAN:"2022-05-13",PO_FORECAST:"2022-06-27",PO_ACTUAL:"2022-08-11",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:244,TAG:"3012T033-I",TAG_DESCR:"SECONDARY ACETYLENE WASHER INTERNAL",TAG_DESCR_SHORT:"3012T033-I / SECONDARY ACETYLENE WASHER INTERNAL",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0103",MR_DESCR:"SECONDARY ACETYLENE WASHER INTERNAL第二炔烃洗涤塔内件",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",MR_PLAN:"2021-11-02",MR_FORECAST:"2021-11-02",MR_ACTUAL:"2021-11-02",LOA_PLAN:"2022-04-01",LOA_FORECAST:"2022-05-16",LOA_ACTUAL:"2022-05-16",PO_PLAN:"2022-05-13",PO_FORECAST:"2022-06-27",PO_ACTUAL:"2022-08-11",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:245,TAG:"3012T034-I",TAG_DESCR:"COOLING COLUMN INTERNAL",TAG_DESCR_SHORT:"3012T034-I / COOLING COLUMN INTERNAL",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0103",MR_DESCR:"COOLING COLUMN INTERNAL冷却塔内件",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",MR_PLAN:"2021-11-02",MR_FORECAST:"2021-11-02",MR_ACTUAL:"2021-11-02",LOA_PLAN:"2022-04-01",LOA_FORECAST:"2022-05-16",LOA_ACTUAL:"2022-05-16",PO_PLAN:"2022-05-13",PO_FORECAST:"2022-06-27",PO_ACTUAL:"2022-08-11",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:246,TAG:"3013T041-I",TAG_DESCR:"PROPYNE COLUMN INTERNAL",TAG_DESCR_SHORT:"3013T041-I / PROPYNE COLUMN INTERNAL",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0103",MR_DESCR:"PROPYNE COLUMN INTERNAL丙炔塔内件",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",MR_PLAN:"2021-11-02",MR_FORECAST:"2021-11-02",MR_ACTUAL:"2021-11-02",LOA_PLAN:"2022-04-01",LOA_FORECAST:"2022-05-16",LOA_ACTUAL:"2022-05-16",PO_PLAN:"2022-05-13",PO_FORECAST:"2022-06-27",PO_ACTUAL:"2022-08-11",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:247,TAG:"3013T045-I",TAG_DESCR:"BUTADIENE COLUMN INTERNAL",TAG_DESCR_SHORT:"3013T045-I / BUTADIENE COLUMN INTERNAL",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0103",MR_DESCR:"BUTADIENE COLUMN INTERNAL丁二烯塔内件",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",MR_PLAN:"2021-11-02",MR_FORECAST:"2021-11-02",MR_ACTUAL:"2021-11-02",LOA_PLAN:"2022-04-01",LOA_FORECAST:"2022-05-16",LOA_ACTUAL:"2022-05-16",PO_PLAN:"2022-05-13",PO_FORECAST:"2022-06-27",PO_ACTUAL:"2022-08-11",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:248,TAG:"3014P052A",TAG_DESCR:"Ejector Water Pump",TAG_DESCR_SHORT:"3014P052A / Ejector Water Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0621",MR_DESCR:"Ejector Water Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2021-12-16",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:249,TAG:"3014P052S",TAG_DESCR:"Ejector Water Pump",TAG_DESCR_SHORT:"3014P052S / Ejector Water Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0621",MR_DESCR:"Ejector Water Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2021-12-16",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:250,TAG:"3014T051-I",TAG_DESCR:"WATER WASHER INTERNAL",TAG_DESCR_SHORT:"3014T051-I / WATER WASHER INTERNAL",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0103",MR_DESCR:"WATER WASHER INTERNAL水洗塔内件",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",MR_PLAN:"2021-11-02",MR_FORECAST:"2021-11-02",MR_ACTUAL:"2021-11-02",LOA_PLAN:"2022-04-01",LOA_FORECAST:"2022-05-16",LOA_ACTUAL:"2022-05-16",PO_PLAN:"2022-05-13",PO_FORECAST:"2022-06-27",PO_ACTUAL:"2022-08-11",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:251,TAG:"3015FIL071A",TAG_DESCR:"Solvent Surge Tank Vent Canister",TAG_DESCR_SHORT:"3015FIL071A / Solvent Surge Tank Vent Canister",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0501",MR_DESCR:"Solvent Surge Tank Vent Canister",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-10",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-08-04",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-09-28",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:252,TAG:"3015FIL071B",TAG_DESCR:"Solvent Surge Tank Vent Canister",TAG_DESCR_SHORT:"3015FIL071B / Solvent Surge Tank Vent Canister",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0501",MR_DESCR:"Solvent Surge Tank Vent Canister",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-10",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-08-04",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-09-28",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:253,TAG:"3015P056A",TAG_DESCR:"BD Slops Pump",TAG_DESCR_SHORT:"3015P056A / BD Slops Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0620",MR_DESCR:"BD Slops Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",MR_PLAN:"2022-02-18",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2022-01-17",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:254,TAG:"3015P056S",TAG_DESCR:"BD Slops Pump",TAG_DESCR_SHORT:"3015P056S / BD Slops Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0620",MR_DESCR:"BD Slops Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",MR_PLAN:"2022-02-18",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2022-01-17",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:255,TAG:"3015PK073",TAG_DESCR:"BD INJECTION PACKAGE BD",TAG_DESCR_SHORT:"3015PK073 / BD INJECTION PACKAGE BD",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0610",MR_DESCR:"BD INJECTION PACKAGE BD注入系统",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",MR_PLAN:"2022-09-01",MR_FORECAST:"2022-09-01",MR_ACTUAL:"2022-08-09",LOA_PLAN:"2022-11-15",LOA_FORECAST:"2022-11-15",LOA_ACTUAL:"2022-12-27",PO_PLAN:"2022-12-27",PO_FORECAST:"2022-12-27",PO_ACTUAL:"2023-01-30",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:256,TAG:"3016T001-I",TAG_DESCR:"water wash column INTERNAL",TAG_DESCR_SHORT:"3016T001-I / water wash column INTERNAL",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0103",MR_DESCR:"water wash column INTERNAL水洗塔内件",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",MR_PLAN:"2021-11-02",MR_FORECAST:"2021-11-02",MR_ACTUAL:"2021-11-02",LOA_PLAN:"2022-04-01",LOA_FORECAST:"2022-05-16",LOA_ACTUAL:"2022-05-16",PO_PLAN:"2022-05-13",PO_FORECAST:"2022-06-27",PO_ACTUAL:"2022-08-11",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:257,TAG:"3042P001A",TAG_DESCR:"Storm Water Pump",TAG_DESCR_SHORT:"3042P001A / Storm Water Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0670",MR_DESCR:"Storm Water Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",MR_PLAN:"2022-03-28",MR_FORECAST:"2022-03-28",MR_ACTUAL:"2022-02-14",LOA_PLAN:"2022-07-26",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-06",PO_FORECAST:"2022-09-17",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:258,TAG:"3042P001B",TAG_DESCR:"Storm Water Pump",TAG_DESCR_SHORT:"3042P001B / Storm Water Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0670",MR_DESCR:"Storm Water Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",MR_PLAN:"2022-03-28",MR_FORECAST:"2022-03-28",MR_ACTUAL:"2022-02-14",LOA_PLAN:"2022-07-26",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-06",PO_FORECAST:"2022-09-17",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:259,TAG:"3042P002A",TAG_DESCR:"Process Waste Water Pump",TAG_DESCR_SHORT:"3042P002A / Process Waste Water Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0670",MR_DESCR:"Process Waste Water Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",MR_PLAN:"2022-03-28",MR_FORECAST:"2022-03-28",MR_ACTUAL:"2022-02-14",LOA_PLAN:"2022-07-26",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-06",PO_FORECAST:"2022-09-17",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:260,TAG:"3042P002B",TAG_DESCR:"Process Waste Water Pump",TAG_DESCR_SHORT:"3042P002B / Process Waste Water Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0670",MR_DESCR:"Process Waste Water Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",MR_PLAN:"2022-03-28",MR_FORECAST:"2022-03-28",MR_ACTUAL:"2022-02-14",LOA_PLAN:"2022-07-26",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-06",PO_FORECAST:"2022-09-17",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:261,TAG:"30P1236A",TAG_DESCR:"VCH / Heavies Pump计量泵",TAG_DESCR_SHORT:"30P1236A / VCH  Heavies Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0630",MR_DESCR:"VCH / Heavies Pump计量泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",CRITICAL:"NPO",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:262,TAG:"30P1236B",TAG_DESCR:"VCH / Heavies Pump计量泵",TAG_DESCR_SHORT:"30P1236B / VCH  Heavies Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0630",MR_DESCR:"VCH / Heavies Pump计量泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",CRITICAL:"NPO",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:263,TAG:"30P1571A",TAG_DESCR:"Soldium Nitrite Solution Dosing Pump",TAG_DESCR_SHORT:"30P1571A / Soldium Nitrite Solution Dosing Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0630",MR_DESCR:"Soldium Nitrite Solution Dosing Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",CRITICAL:"NPO",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:264,TAG:"30P1571B",TAG_DESCR:"Soldium Nitrite Solution Dosing Pump",TAG_DESCR_SHORT:"30P1571B / Soldium Nitrite Solution Dosing Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0630",MR_DESCR:"Soldium Nitrite Solution Dosing Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",CRITICAL:"NPO",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:265,TAG:"30P1572A",TAG_DESCR:"Soldium Nitrite Solution Dosing Pump",TAG_DESCR_SHORT:"30P1572A / Soldium Nitrite Solution Dosing Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0630",MR_DESCR:"Soldium Nitrite Solution Dosing Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",CRITICAL:"NPO",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:266,TAG:"30P1572B",TAG_DESCR:"Soldium Nitrite Solution Dosing Pump",TAG_DESCR_SHORT:"30P1572B / Soldium Nitrite Solution Dosing Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0630",MR_DESCR:"Soldium Nitrite Solution Dosing Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",CRITICAL:"NPO",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:267,TAG:"30P1577",TAG_DESCR:"TBC/Water Inhibitor Dosing Pump",TAG_DESCR_SHORT:"30P1577 / TBCWater Inhibitor Dosing Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0630",MR_DESCR:"TBC/Water Inhibitor Dosing Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",CRITICAL:"NPO",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:268,TAG:"30P1578",TAG_DESCR:"Antifoam Dosing Pump",TAG_DESCR_SHORT:"30P1578 / Antifoam Dosing Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0630",MR_DESCR:"Antifoam Dosing Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",CRITICAL:"NPO",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:188,SN:72,TAG:"3098D001A",TAG_DESCR:"Waste Gas Adsorption Drums",TAG_DESCR_SHORT:"3098D001A / Waste Gas Adsorption Drums",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0301",MR_DESCR:"Waste Gas Adsorption Drums",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:189,SN:73,TAG:"3098D001B",TAG_DESCR:"Waste Gas Adsorption Drums",TAG_DESCR_SHORT:"3098D001B / Waste Gas Adsorption Drums",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0301",MR_DESCR:"Waste Gas Adsorption Drums",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:190,SN:74,TAG:"3098D001A-new",TAG_DESCR_SHORT:"3098D001A-new / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"A",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:191,SN:75,TAG:"3098D001B-new",TAG_DESCR_SHORT:"3098D001B-new / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"A",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:192,SN:76,TAG:"3012C031",TAG_DESCR:"Recycle Gas Compressor",TAG_DESCR_SHORT:"3012C031 / Recycle Gas Compressor",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-LL01",MR_DESCR:"Recycle Gas Compressor循环气压缩机",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2021-07-23",MR_FORECAST:"2021-09-15",MR_ACTUAL:"2021-09-14",LOA_PLAN:"2022-01-04",LOA_FORECAST:"2021-12-31",LOA_ACTUAL:"2021-12-30",PO_PLAN:"2022-02-15",PO_FORECAST:"2022-01-28",PO_ACTUAL:"2022-02-22",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:193,SN:77,TAG:"3012T034",TAG_DESCR:"COOLING COLUMN",TAG_DESCR_SHORT:"3012T034 / COOLING COLUMN",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0102",MR_DESCR:"COOLING COLUMN冷却塔",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-11-18",MR_FORECAST:"2022-11-18",MR_ACTUAL:"2021-11-08",LOA_PLAN:"2022-03-05",LOA_FORECAST:"2022-03-21",LOA_ACTUAL:"2022-03-23",PO_PLAN:"2022-04-16",PO_FORECAST:"2022-05-02",PO_ACTUAL:"2022-04-13",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:194,SN:78,TAG:"3012E033",TAG_DESCR:"SHU 2nd stage vent condenser",TAG_DESCR_SHORT:"3012E033 / SHU 2nd stage vent condenser",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0401",MR_DESCR:"SHU 2nd stage vent condenser",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:195,SN:79,TAG:"3012T031",TAG_DESCR:"DEGASSER",TAG_DESCR_SHORT:"3012T031 / DEGASSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0102",MR_DESCR:"DEGASSER脱气塔",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-11-18",MR_FORECAST:"2022-11-18",MR_ACTUAL:"2021-11-08",LOA_PLAN:"2022-03-05",LOA_FORECAST:"2022-03-21",LOA_ACTUAL:"2022-03-23",PO_PLAN:"2022-04-16",PO_FORECAST:"2022-05-02",PO_ACTUAL:"2022-04-13",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:196,SN:80,TAG:"3011T022",TAG_DESCR:"RECTIFIERAFTERWASHER 1600",TAG_DESCR_SHORT:"3011T022 / RECTIFIERAFTERWASHER 1600",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0101",MR_DESCR:"RECTIFIER/AFTERWASHER精馏/后洗塔1600吊车",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2021-11-23",MR_FORECAST:"2021-11-23",MR_ACTUAL:"2021-11-02",LOA_PLAN:"2022-03-05",LOA_FORECAST:"2022-03-31",LOA_ACTUAL:"2022-03-23",PO_PLAN:"2022-04-16",PO_FORECAST:"2022-05-12",PO_ACTUAL:"2022-04-13",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:197,SN:81,TAG:"3011T021",TAG_DESCR:"MAIN WASHER 1600",TAG_DESCR_SHORT:"3011T021 / MAIN WASHER 1600",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0101",MR_DESCR:"MAIN WASHER主洗塔1600吊车",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2021-11-23",MR_FORECAST:"2021-11-23",MR_ACTUAL:"2021-11-02",LOA_PLAN:"2022-03-05",LOA_FORECAST:"2022-03-31",LOA_ACTUAL:"2022-03-23",PO_PLAN:"2022-04-16",PO_FORECAST:"2022-05-12",PO_ACTUAL:"2022-04-13",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:198,SN:82,TAG:"3012E038",TAG_DESCR:"water wash cooler",TAG_DESCR_SHORT:"3012E038 / water wash cooler",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0401",MR_DESCR:"water wash cooler",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:199,SN:83,TAG:"3012D038",TAG_DESCR:"C4 ACETYLENES ACCUMULATOR",TAG_DESCR_SHORT:"3012D038 / C4 ACETYLENES ACCUMULATOR",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0301",MR_DESCR:"C4 ACETYLENES ACCUMULATOR",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:200,SN:84,TAG:"3012T033",TAG_DESCR:"SHU 2nd stage reactor product cooler",TAG_DESCR_SHORT:"3012T033 / SHU 2nd stage reactor product cooler",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0401",MR_DESCR:"SHU 2nd stage reactor product cooler第二炔烃洗涤塔",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:201,SN:85,TAG:"3012E032",TAG_DESCR:"SHU 2nd stage reactor heater",TAG_DESCR_SHORT:"3012E032 / SHU 2nd stage reactor heater",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0401",MR_DESCR:"SHU 2nd stage reactor heater",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:202,SN:86,TAG:"3012T032",TAG_DESCR:"ACETYLENE WASHER",TAG_DESCR_SHORT:"3012T032 / ACETYLENE WASHER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0102",MR_DESCR:"ACETYLENE WASHER炔烃洗涤塔",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-11-18",MR_FORECAST:"2022-11-18",MR_ACTUAL:"2021-11-08",LOA_PLAN:"2022-03-05",LOA_FORECAST:"2022-03-21",LOA_ACTUAL:"2022-03-23",PO_PLAN:"2022-04-16",PO_FORECAST:"2022-05-02",PO_ACTUAL:"2022-04-13",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:203,SN:87,TAG:"30AS01",TAG_DESCR_SHORT:"30AS01 / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:204,SN:88,TAG:"3012E031",TAG_DESCR:"SHU 1st stage vent conderser",TAG_DESCR_SHORT:"3012E031 / SHU 1st stage vent conderser",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0401",MR_DESCR:"SHU 1st stage vent conderser",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:205,SN:89,TAG:"3012E034B",TAG_DESCR:"WATER AND SOLVENT COOLER",TAG_DESCR_SHORT:"3012E034B / WATER AND SOLVENT COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0401",MR_DESCR:"WATER AND SOLVENT COOLER废水和溶剂冷却器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:206,SN:90,TAG:"3012E034A",TAG_DESCR:"WATER AND SOLVENT COOLER",TAG_DESCR_SHORT:"3012E034A / WATER AND SOLVENT COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0401",MR_DESCR:"WATER AND SOLVENT COOLER废水和溶剂冷却器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:207,SN:91,TAG:"3012D036",TAG_DESCR:"VCHWATER SEPARATOR",TAG_DESCR_SHORT:"3012D036 / VCHWATER SEPARATOR",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0301",MR_DESCR:"VCH/WATER SEPARATOR",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:208,SN:92,TAG:"3011D024",TAG_DESCR:"AFTERWASHER ACCUMULATOR",TAG_DESCR_SHORT:"3011D024 / AFTERWASHER ACCUMULATOR",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0301",MR_DESCR:"AFTERWASHER ACCUMULATOR",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:209,SN:93,TAG:"3011E024",TAG_DESCR:"AFTERWASHER CONDENSER",TAG_DESCR_SHORT:"3011E024 / AFTERWASHER CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-SL01",MR_DESCR:"AFTERWASHER CONDENSER后洗塔冷凝器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-17",LOA_PLAN:"2022-04-28",LOA_FORECAST:"2022-04-03",LOA_ACTUAL:"2022-04-11",PO_PLAN:"2022-06-09",PO_FORECAST:"2022-06-02",PO_ACTUAL:"2022-05-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:210,SN:94,TAG:"3011E021A",TAG_DESCR:"MAIN WASHER CONDENSER",TAG_DESCR_SHORT:"3011E021A / MAIN WASHER CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0401",MR_DESCR:"MAIN WASHER CONDENSER主洗塔冷凝器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:211,SN:95,TAG:"3011E021B",TAG_DESCR:"MAIN WASHER CONDENSER",TAG_DESCR_SHORT:"3011E021B / MAIN WASHER CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0401",MR_DESCR:"MAIN WASHER CONDENSER主洗塔冷凝器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:212,SN:96,TAG:"3011D021",TAG_DESCR:"MAIN WASHER ACCUMULATOR",TAG_DESCR_SHORT:"3011D021 / MAIN WASHER ACCUMULATOR",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0301",MR_DESCR:"MAIN WASHER ACCUMULATOR",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:213,SN:97,TAG:"3011E030C",TAG_DESCR:"SOLVENT HEAT EXCHANGER",TAG_DESCR_SHORT:"3011E030C / SOLVENT HEAT EXCHANGER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0401",MR_DESCR:"SOLVENT HEAT EXCHANGER溶剂加热器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:214,SN:98,TAG:"3011E030B",TAG_DESCR:"SOLVENT HEAT EXCHANGER",TAG_DESCR_SHORT:"3011E030B / SOLVENT HEAT EXCHANGER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0401",MR_DESCR:"SOLVENT HEAT EXCHANGER溶剂加热器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:215,SN:99,TAG:"3011E030A",TAG_DESCR:"SOLVENT HEAT EXCHANGER",TAG_DESCR_SHORT:"3011E030A / SOLVENT HEAT EXCHANGER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0401",MR_DESCR:"SOLVENT HEAT EXCHANGER溶剂加热器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:216,SN:100,TAG:"3012P034A",TAG_DESCR:"Cooling Column Bottoms Pump",TAG_DESCR_SHORT:"3012P034A / Cooling Column Bottoms Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0621",MR_DESCR:"Cooling Column Bottoms Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2021-12-16",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:217,SN:101,TAG:"3012P034B",TAG_DESCR:"Cooling Column Bottoms Pump",TAG_DESCR_SHORT:"3012P034B / Cooling Column Bottoms Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0621",MR_DESCR:"Cooling Column Bottoms Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2021-12-16",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:218,SN:102,TAG:"3012P038A",TAG_DESCR:"BD HIGH SPEED PUMP BD C4 Acetylenes Pump",TAG_DESCR_SHORT:"3012P038A / BD HIGH SPEED PUMP BD C4 Acetylenes Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0640",MR_DESCR:"BD HIGH SPEED PUMP BD高速泵 C4 Acetylenes Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-02-23",MR_FORECAST:"2022-02-23",MR_ACTUAL:"2022-01-17",LOA_PLAN:"2022-06-23",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-08-04",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:219,SN:103,TAG:"3012P038B",TAG_DESCR:"BD HIGH SPEED PUMP BD C4 Acetylenes Pump",TAG_DESCR_SHORT:"3012P038B / BD HIGH SPEED PUMP BD C4 Acetylenes Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0640",MR_DESCR:"BD HIGH SPEED PUMP BD高速泵 C4 Acetylenes Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-02-23",MR_FORECAST:"2022-02-23",MR_ACTUAL:"2022-01-17",LOA_PLAN:"2022-06-23",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-08-04",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:220,SN:104,TAG:"3012P032A",TAG_DESCR:"Process Water Pump",TAG_DESCR_SHORT:"3012P032A / Process Water Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0621",MR_DESCR:"Process Water Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2021-12-16",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:221,SN:105,TAG:"3012P032B",TAG_DESCR:"Process Water Pump",TAG_DESCR_SHORT:"3012P032B / Process Water Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0621",MR_DESCR:"Process Water Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2021-12-16",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:222,SN:106,TAG:"3012P036A",TAG_DESCR:"VCH Heavies Pump",TAG_DESCR_SHORT:"3012P036A / VCH Heavies Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD - Rotating",MR_NO:"75231-P1-1277-RE-R-E-0630",MR_DESCR:"VCH/Heavies Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-03-28",MR_FORECAST:"2022-03-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-07-26",LOA_FORECAST:"2022-09-20",LOA_ACTUAL:"2022-09-15",PO_PLAN:"2022-09-06",PO_FORECAST:"2022-11-01",PO_ACTUAL:"2022-10-21",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:223,SN:107,TAG:"3012P036B",TAG_DESCR:"VCH Heavies Pump",TAG_DESCR_SHORT:"3012P036B / VCH Heavies Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD - Rotating",MR_NO:"75231-P1-1277-RE-R-E-0630",MR_DESCR:"VCH/Heavies Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-03-28",MR_FORECAST:"2022-03-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-07-26",LOA_FORECAST:"2022-09-20",LOA_ACTUAL:"2022-09-15",PO_PLAN:"2022-09-06",PO_FORECAST:"2022-11-01",PO_ACTUAL:"2022-10-21",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:224,SN:108,TAG:"3012P035A",TAG_DESCR:"Acetylene Washer Bottoms Pump",TAG_DESCR_SHORT:"3012P035A / Acetylene Washer Bottoms Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0621",MR_DESCR:"Acetylene Washer Bottoms Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2021-12-16",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:225,SN:109,TAG:"3012P035B",TAG_DESCR:"Acetylene Washer Bottoms Pump",TAG_DESCR_SHORT:"3012P035B / Acetylene Washer Bottoms Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0621",MR_DESCR:"Acetylene Washer Bottoms Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2021-12-16",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:226,SN:110,TAG:"3012P033A",TAG_DESCR:"Degasser Bottoms Pump",TAG_DESCR_SHORT:"3012P033A / Degasser Bottoms Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0620",MR_DESCR:"Degasser Bottoms Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-02-18",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2022-01-17",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:227,SN:111,TAG:"3012P033B",TAG_DESCR:"Degasser Bottoms Pump",TAG_DESCR_SHORT:"3012P033B / Degasser Bottoms Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0620",MR_DESCR:"Degasser Bottoms Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-02-18",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2022-01-17",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:228,SN:112,TAG:"3011P030A",TAG_DESCR:"Degasser Feed Pump",TAG_DESCR_SHORT:"3011P030A / Degasser Feed Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0620",MR_DESCR:"Degasser Feed Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-02-18",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2022-01-17",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:229,SN:113,TAG:"3011P030B",TAG_DESCR:"Degasser Feed Pump",TAG_DESCR_SHORT:"3011P030B / Degasser Feed Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0620",MR_DESCR:"Degasser Feed Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-02-18",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2022-01-17",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:230,SN:114,TAG:"3011P023A",TAG_DESCR:"Rectifier Bottoms Pump",TAG_DESCR_SHORT:"3011P023A / Rectifier Bottoms Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0620",MR_DESCR:"Rectifier Bottoms Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-02-18",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2022-01-17",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:231,SN:115,TAG:"3011P023B",TAG_DESCR:"Rectifier Bottoms Pump",TAG_DESCR_SHORT:"3011P023B / Rectifier Bottoms Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0620",MR_DESCR:"Rectifier Bottoms Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-02-18",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2022-01-17",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:232,SN:116,TAG:"3011P024A",TAG_DESCR:"Crude Butadiene Pump",TAG_DESCR_SHORT:"3011P024A / Crude Butadiene Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0650",MR_DESCR:"Crude Butadiene Pump 屏蔽泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-02-26",MR_FORECAST:"2022-02-26",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-06-26",LOA_FORECAST:"2022-07-04",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-08-07",PO_FORECAST:"2022-09-27",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:233,SN:117,TAG:"3011P024B",TAG_DESCR:"Crude Butadiene Pump",TAG_DESCR_SHORT:"3011P024B / Crude Butadiene Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0650",MR_DESCR:"Crude Butadiene Pump 屏蔽泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-02-26",MR_FORECAST:"2022-02-26",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-06-26",LOA_FORECAST:"2022-07-04",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-08-07",PO_FORECAST:"2022-09-27",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:234,SN:118,TAG:"3011P021A",TAG_DESCR:"Rafinate Pump",TAG_DESCR_SHORT:"3011P021A / Rafinate Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0620",MR_DESCR:"Rafinate Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-02-18",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2022-01-17",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:235,SN:119,TAG:"3011P021B",TAG_DESCR:"Rafinate Pump",TAG_DESCR_SHORT:"3011P021B / Rafinate Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0620",MR_DESCR:"Rafinate Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-02-18",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2022-01-17",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:236,SN:120,TAG:"3011P022A",TAG_DESCR:"Main Washer Bottoms Pump",TAG_DESCR_SHORT:"3011P022A / Main Washer Bottoms Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0620",MR_DESCR:"Main Washer Bottoms Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-02-18",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2022-01-17",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:237,SN:121,TAG:"3011P022B",TAG_DESCR:"Main Washer Bottoms Pump",TAG_DESCR_SHORT:"3011P022B / Main Washer Bottoms Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0620",MR_DESCR:"Main Washer Bottoms Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",MR_PLAN:"2022-02-18",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2022-01-17",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:117,SN:1,TAG:"3015P075",TAG_DESCR:"Solvent Surge Tank Pump",TAG_DESCR_SHORT:"3015P075 / Solvent Surge Tank Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0621",MR_DESCR:"Solvent Surge Tank Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"C",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2021-12-16",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:118,SN:2,TAG:"3013FIL041A",TAG_DESCR:"Propyne Column Bottoms Filter",TAG_DESCR_SHORT:"3013FIL041A / Propyne Column Bottoms Filter",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0501",MR_DESCR:"Propyne Column Bottoms Filter",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"C",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-10",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-08-04",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-09-28",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:119,SN:3,TAG:"3013FIL041B",TAG_DESCR:"Propyne Column Bottoms Filter",TAG_DESCR_SHORT:"3013FIL041B / Propyne Column Bottoms Filter",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0501",MR_DESCR:"Propyne Column Bottoms Filter",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"C",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-10",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-08-04",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-09-28",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:120,SN:4,TAG:"3013P041A",TAG_DESCR:"Propyne Column Reflux Pump",TAG_DESCR_SHORT:"3013P041A / Propyne Column Reflux Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0650",MR_DESCR:"Propyne Column Reflux Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"C",MR_PLAN:"2022-02-26",MR_FORECAST:"2022-02-26",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-06-26",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-08-07",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:121,SN:5,TAG:"3013P041B",TAG_DESCR:"Propyne Column Reflux Pump",TAG_DESCR_SHORT:"3013P041B / Propyne Column Reflux Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0650",MR_DESCR:"Propyne Column Reflux Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"C",MR_PLAN:"2022-02-26",MR_FORECAST:"2022-02-26",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-06-26",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-08-07",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:122,SN:6,TAG:"3013P045A",TAG_DESCR:"Butadiene Column Overhead Pump",TAG_DESCR_SHORT:"3013P045A / Butadiene Column Overhead Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0650",MR_DESCR:"Butadiene Column Overhead Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"C",MR_PLAN:"2022-02-26",MR_FORECAST:"2022-02-26",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-06-26",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-08-07",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:123,SN:7,TAG:"3013P045B",TAG_DESCR:"Butadiene Column Overhead Pump",TAG_DESCR_SHORT:"3013P045B / Butadiene Column Overhead Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0650",MR_DESCR:"Butadiene Column Overhead Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"C",MR_PLAN:"2022-02-26",MR_FORECAST:"2022-02-26",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-06-26",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-08-07",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:124,SN:8,TAG:"3014P051",TAG_DESCR:"Regenerated Solvent Pump",TAG_DESCR_SHORT:"3014P051 / Regenerated Solvent Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0621",MR_DESCR:"Regenerated Solvent Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"C",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2021-12-16",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:125,SN:9,TAG:"3014P052",TAG_DESCR_SHORT:"3014P052 / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"C",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:126,SN:10,TAG:"3015TK075",TAG_DESCR:"SOLVENT SURGE TANK",TAG_DESCR_SHORT:"3015TK075 / SOLVENT SURGE TANK",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0503",MR_DESCR:"SOLVENT SURGE TANK溶剂缓冲罐 储罐罐板",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"C",MR_PLAN:"2022-09-30",MR_FORECAST:"2022-09-30",LOA_PLAN:"2023-01-21",LOA_FORECAST:"2023-01-21",PO_PLAN:"2023-03-04",PO_FORECAST:"2023-03-04",CRITICAL:"NPO",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:127,SN:11,TAG:"3013T041",TAG_DESCR:"PROPYNE COLUMN",TAG_DESCR_SHORT:"3013T041 / PROPYNE COLUMN",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0102",MR_DESCR:"PROPYNE COLUMN丙炔塔",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"C",MR_PLAN:"2022-11-18",MR_FORECAST:"2022-11-18",MR_ACTUAL:"2021-11-08",LOA_PLAN:"2022-03-05",LOA_FORECAST:"2022-03-21",LOA_ACTUAL:"2022-03-23",PO_PLAN:"2022-04-16",PO_FORECAST:"2022-05-02",PO_ACTUAL:"2022-04-13",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:128,SN:12,TAG:"3013T045",TAG_DESCR:"BUTADIENE COLUMN",TAG_DESCR_SHORT:"3013T045 / BUTADIENE COLUMN",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0102",MR_DESCR:"BUTADIENE COLUMN丁二烯塔",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"C",MR_PLAN:"2022-11-18",MR_FORECAST:"2022-11-18",MR_ACTUAL:"2021-11-08",LOA_PLAN:"2022-03-05",LOA_FORECAST:"2022-03-21",LOA_ACTUAL:"2022-03-23",PO_PLAN:"2022-04-16",PO_FORECAST:"2022-05-02",PO_ACTUAL:"2022-04-13",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:129,SN:13,TAG:"3013D041",TAG_DESCR:"PROPYNE COLUMN ACCUMULATOR",TAG_DESCR_SHORT:"3013D041 / PROPYNE COLUMN ACCUMULATOR",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0301",MR_DESCR:"PROPYNE COLUMN ACCUMULATOR",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"C",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:130,SN:14,TAG:"3013E042",TAG_DESCR:"SOLVENT COOLER",TAG_DESCR_SHORT:"3013E042 / SOLVENT COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0401",MR_DESCR:"SOLVENT COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"C",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:131,SN:15,TAG:"3013E043",TAG_DESCR:"MAIN WASHER CONDENSER",TAG_DESCR_SHORT:"3013E043 / MAIN WASHER CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0401",MR_DESCR:"MAIN WASHER CONDENSER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"C",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:132,SN:16,TAG:"3013E048",TAG_DESCR:"SOLVENT HEAT EXCHANGER",TAG_DESCR_SHORT:"3013E048 / SOLVENT HEAT EXCHANGER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0401",MR_DESCR:"SOLVENT HEAT EXCHANGER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"C",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:133,SN:17,TAG:"3013E046",TAG_DESCR:"BUTADIENE COLUMN REBOILER",TAG_DESCR_SHORT:"3013E046 / BUTADIENE COLUMN REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0401",MR_DESCR:"BUTADIENE COLUMN REBOILER丁二烯塔再沸器器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"C",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:134,SN:18,TAG:"3013D045",TAG_DESCR:"BUTADIENE COLUMN ACCUMULATOR",TAG_DESCR_SHORT:"3013D045 / BUTADIENE COLUMN ACCUMULATOR",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0301",MR_DESCR:"BUTADIENE COLUMN ACCUMULATOR",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"C",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:135,SN:19,TAG:"3013E045A",TAG_DESCR:"BUTADIENE COLUMN CONDENSER",TAG_DESCR_SHORT:"3013E045A / BUTADIENE COLUMN CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-SL01",MR_DESCR:"BUTADIENE COLUMN CONDENSER丁二烯塔冷凝器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"C",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-17",LOA_PLAN:"2022-04-28",LOA_FORECAST:"2022-04-03",LOA_ACTUAL:"2022-04-11",PO_PLAN:"2022-06-09",PO_FORECAST:"2022-06-02",PO_ACTUAL:"2022-05-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:136,SN:20,TAG:"3013E045B",TAG_DESCR:"BUTADIENE COLUMN CONDENSER",TAG_DESCR_SHORT:"3013E045B / BUTADIENE COLUMN CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-SL01",MR_DESCR:"BUTADIENE COLUMN CONDENSER丁二烯塔冷凝器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"C",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-17",LOA_PLAN:"2022-04-28",LOA_FORECAST:"2022-04-03",LOA_ACTUAL:"2022-04-11",PO_PLAN:"2022-06-09",PO_FORECAST:"2022-06-02",PO_ACTUAL:"2022-05-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:137,SN:21,TAG:"3013E041",TAG_DESCR:"PROPYNE COLUMN CONDENSER",TAG_DESCR_SHORT:"3013E041 / PROPYNE COLUMN CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-SL01",MR_DESCR:"PROPYNE COLUMN CONDENSER丙炔塔冷凝器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"C",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-17",LOA_PLAN:"2022-04-28",LOA_FORECAST:"2022-04-03",LOA_ACTUAL:"2022-04-11",PO_PLAN:"2022-06-09",PO_FORECAST:"2022-06-02",PO_ACTUAL:"2022-05-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:138,SN:22,TAG:"3014D052",TAG_DESCR:"EJECTOR SEAL DRUM",TAG_DESCR_SHORT:"3014D052 / EJECTOR SEAL DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0301",MR_DESCR:"EJECTOR SEAL DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"C",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:139,SN:23,TAG:"3014D051",TAG_DESCR:"REGENERATED SOLVENT DRUM",TAG_DESCR_SHORT:"3014D051 / REGENERATED SOLVENT DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0301",MR_DESCR:"REGENERATED SOLVENT DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"C",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:140,SN:24,TAG:"3014D050B",TAG_DESCR:"NMP REGENERATION ACCUMULATOR",TAG_DESCR_SHORT:"3014D050B / NMP REGENERATION ACCUMULATOR",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-SL02",MR_DESCR:"NMP REGENERATION ACCUMULATOR溶剂再生罐",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"C",MR_PLAN:"2022-05-03",MR_FORECAST:"2022-05-03",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-12",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-09-23",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-22",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:141,SN:25,TAG:"3014D050A",TAG_DESCR:"NMP REGENERATION ACCUMULATOR",TAG_DESCR_SHORT:"3014D050A / NMP REGENERATION ACCUMULATOR",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-SL02",MR_DESCR:"NMP REGENERATION ACCUMULATOR溶剂再生罐",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"C",MR_PLAN:"2022-05-03",MR_FORECAST:"2022-05-03",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-12",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-09-23",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-22",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:142,SN:26,TAG:"3014MA050A",TAG_DESCR:"NMP Regeneration Accumulator Agitator",TAG_DESCR_SHORT:"3014MA050A / NMP Regeneration Accumulator Agitator",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-SL02",MR_DESCR:"NMP Regeneration Accumulator Agitator溶剂再生罐搅拌器（容器包）",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"C",MR_PLAN:"2022-05-03",MR_FORECAST:"2022-05-03",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-12",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-09-23",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-22",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:143,SN:27,TAG:"3014E051",TAG_DESCR:"WATER AND SOLVENT COOLER",TAG_DESCR_SHORT:"3014E051 / WATER AND SOLVENT COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0401",MR_DESCR:"WATER AND SOLVENT COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"C",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:144,SN:28,TAG:"3014T051",TAG_DESCR:"BUTADIENE COLUMN REBOILER",TAG_DESCR_SHORT:"3014T051 / BUTADIENE COLUMN REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0401",MR_DESCR:"BUTADIENE COLUMN REBOILER 水洗塔",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"C",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:145,SN:29,TAG:"3014X052",TAG_DESCR:"Steam Ejector Package",TAG_DESCR_SHORT:"3014X052 / Steam Ejector Package",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0691",MR_DESCR:"Steam Ejector Package 蒸汽喷射器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"C",MR_PLAN:"2022-03-08",MR_FORECAST:"2022-03-08",MR_ACTUAL:"2022-02-15",LOA_PLAN:"2022-06-21",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-08-02",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-08-29",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:146,SN:30,TAG:"3014MA050B",TAG_DESCR:"NMP Regeneration Accumulator Agitator",TAG_DESCR_SHORT:"3014MA050B / NMP Regeneration Accumulator Agitator",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-SL02",MR_DESCR:"NMP Regeneration Accumulator Agitator溶剂再生罐搅拌器（容器包）",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"C",MR_PLAN:"2022-05-03",MR_FORECAST:"2022-05-03",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-12",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-09-23",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-22",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:147,SN:31,TAG:"3011D010",TAG_DESCR:"FEED VAPORIZATION DRUM",TAG_DESCR_SHORT:"3011D010 / FEED VAPORIZATION DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0301",MR_DESCR:"FEED VAPORIZATION DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"C",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:148,SN:32,TAG:"3011E010A",TAG_DESCR:"SHU 1st stage reactor recycle product cooler",TAG_DESCR_SHORT:"3011E010A / SHU 1st stage reactor recycle product...",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0401",MR_DESCR:"SHU 1st stage reactor recycle product cooler第一蒸发器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"C",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:149,SN:33,TAG:"3011E011",TAG_DESCR:"SHU 1st stage reactor heater",TAG_DESCR_SHORT:"3011E011 / SHU 1st stage reactor heater",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0401",MR_DESCR:"SHU 1st stage reactor heater第二蒸发器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"C",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:150,SN:34,TAG:"3011E010B",TAG_DESCR:"SHU 1st stage reactor recycle product cooler",TAG_DESCR_SHORT:"3011E010B / SHU 1st stage reactor recycle product...",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0401",MR_DESCR:"SHU 1st stage reactor recycle product cooler第一蒸发器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"C",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:151,SN:35,TAG:"3011E020A",TAG_DESCR:"SOLVENT COOLER",TAG_DESCR_SHORT:"3011E020A / SOLVENT COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0401",MR_DESCR:"SOLVENT COOLER溶剂冷却器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"C",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:152,SN:36,TAG:"3011E020B",TAG_DESCR:"SOLVENT COOLER",TAG_DESCR_SHORT:"3011E020B / SOLVENT COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0401",MR_DESCR:"SOLVENT COOLER溶剂冷却器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"C",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:153,SN:37,TAG:"3015D055",TAG_DESCR:"BD UNIT FLARE KO DRUM",TAG_DESCR_SHORT:"3015D055 / BD UNIT FLARE KO DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0301",MR_DESCR:"BD UNIT FLARE KO DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"D",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:154,SN:38,TAG:"3015P073B",TAG_DESCR:"Antifoam Dosing Pump",TAG_DESCR_SHORT:"3015P073B / Antifoam Dosing Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD - Rotating",MR_NO:"TBD",MR_DESCR:"Antifoam Dosing Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"D",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:155,SN:39,TAG:"3015P073A",TAG_DESCR:"Antifoam Dosing Pump",TAG_DESCR_SHORT:"3015P073A / Antifoam Dosing Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD - Rotating",MR_NO:"TBD",MR_DESCR:"Antifoam Dosing Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"D",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:156,SN:40,TAG:"3015P072B",TAG_DESCR:"TBCWater Inhibitor Dosing Pump",TAG_DESCR_SHORT:"3015P072B / TBCWater Inhibitor Dosing Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD - Rotating",MR_NO:"75231-P1-1277-RE-R-E-0630",MR_DESCR:"TBC/Water Inhibitor Dosing Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"D",MR_PLAN:"2022-03-28",MR_FORECAST:"2022-03-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-07-26",LOA_FORECAST:"2022-09-20",LOA_ACTUAL:"2022-09-15",PO_PLAN:"2022-09-06",PO_FORECAST:"2022-11-01",PO_ACTUAL:"2022-10-21",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:157,SN:41,TAG:"3015P072A",TAG_DESCR:"TBCWater Inhibitor Dosing Pump",TAG_DESCR_SHORT:"3015P072A / TBCWater Inhibitor Dosing Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD - Rotating",MR_NO:"75231-P1-1277-RE-R-E-0630",MR_DESCR:"TBC/Water Inhibitor Dosing Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"D",MR_PLAN:"2022-03-28",MR_FORECAST:"2022-03-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-07-26",LOA_FORECAST:"2022-09-20",LOA_ACTUAL:"2022-09-15",PO_PLAN:"2022-09-06",PO_FORECAST:"2022-11-01",PO_ACTUAL:"2022-10-21",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:158,SN:42,TAG:"3015P071B",TAG_DESCR:"Soldium Nitrite Solution Dosing Pump",TAG_DESCR_SHORT:"3015P071B / Soldium Nitrite Solution Dosing Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD - Rotating",MR_NO:"75231-P1-1277-RE-R-E-0630",MR_DESCR:"Sodium Nitrite Solution Dosing Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"D",MR_PLAN:"2022-03-28",MR_FORECAST:"2022-03-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-07-26",LOA_FORECAST:"2022-09-20",LOA_ACTUAL:"2022-09-15",PO_PLAN:"2022-09-06",PO_FORECAST:"2022-11-01",PO_ACTUAL:"2022-10-21",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:159,SN:43,TAG:"3015P071A",TAG_DESCR:"Soldium Nitrite Solution Dosing Pump",TAG_DESCR_SHORT:"3015P071A / Soldium Nitrite Solution Dosing Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD - Rotating",MR_NO:"75231-P1-1277-RE-R-E-0630",MR_DESCR:"Sodium Nitrite Solution Dosing Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"D",MR_PLAN:"2022-03-28",MR_FORECAST:"2022-03-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-07-26",LOA_FORECAST:"2022-09-20",LOA_ACTUAL:"2022-09-15",PO_PLAN:"2022-09-06",PO_FORECAST:"2022-11-01",PO_ACTUAL:"2022-10-21",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:160,SN:44,TAG:"3015D071",TAG_DESCR:"SODIUM NITRITE SOLUTION DRUM",TAG_DESCR_SHORT:"3015D071 / SODIUM NITRITE SOLUTION DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0301",MR_DESCR:"SODIUM NITRITE SOLUTION DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"D",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:161,SN:45,TAG:"3015D072",TAG_DESCR:"TBCWATER INHIBITOR DRUM",TAG_DESCR_SHORT:"3015D072 / TBCWATER INHIBITOR DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0301",MR_DESCR:"TBC/WATER INHIBITOR DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"D",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:162,SN:46,TAG:"3015D073",TAG_DESCR_SHORT:"3015D073 / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"D",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:163,SN:47,TAG:"3015P076",TAG_DESCR:"Pit Rainwater Pump",TAG_DESCR_SHORT:"3015P076 / Pit Rainwater Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0670",MR_DESCR:"Pit Rainwater Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"D",MR_PLAN:"2022-03-28",MR_FORECAST:"2022-03-28",MR_ACTUAL:"2022-02-14",LOA_PLAN:"2022-07-26",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-06",PO_FORECAST:"2022-09-17",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:164,SN:48,TAG:"3015D056",TAG_DESCR:"BD SLOP DRUM",TAG_DESCR_SHORT:"3015D056 / BD SLOP DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0301",MR_DESCR:"BD SLOP DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"D",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:165,SN:49,TAG:"3016T001",TAG_DESCR:"water wash columnC4",TAG_DESCR_SHORT:"3016T001 / water wash columnC4",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0102",MR_DESCR:"water wash columnC4水洗塔",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"D",MR_PLAN:"2022-11-18",MR_FORECAST:"2022-11-18",MR_ACTUAL:"2021-11-08",LOA_PLAN:"2022-03-05",LOA_FORECAST:"2022-03-21",LOA_ACTUAL:"2022-03-23",PO_PLAN:"2022-04-16",PO_FORECAST:"2022-05-02",PO_ACTUAL:"2022-04-13",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:166,SN:50,TAG:"3016P001A",TAG_DESCR:"Waste water pumps",TAG_DESCR_SHORT:"3016P001A / Waste water pumps",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0621",MR_DESCR:"Waste water pumps",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"D",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2021-12-16",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:167,SN:51,TAG:"3016P001B",TAG_DESCR:"Waste water pumps",TAG_DESCR_SHORT:"3016P001B / Waste water pumps",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0621",MR_DESCR:"Waste water pumps",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"D",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2021-12-16",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:168,SN:52,TAG:"3016P002A",TAG_DESCR:"SHU 1st stage recycleproduct pumps",TAG_DESCR_SHORT:"3016P002A / SHU 1st stage recycleproduct pumps",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0620",MR_DESCR:"SHU 1st stage recycle/product pumps",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"D",MR_PLAN:"2022-02-18",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2022-01-17",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:169,SN:53,TAG:"3016P002B",TAG_DESCR:"SHU 1st stage recycleproduct pumps",TAG_DESCR_SHORT:"3016P002B / SHU 1st stage recycleproduct pumps",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0620",MR_DESCR:"SHU 1st stage recycle/product pumps",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"D",MR_PLAN:"2022-02-18",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2022-01-17",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:170,SN:54,TAG:"3016P003A",TAG_DESCR:"SHU 2st stage recycleproduct pumps",TAG_DESCR_SHORT:"3016P003A / SHU 2st stage recycleproduct pumps",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0620",MR_DESCR:"SHU 2st stage recycle/product pumps",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"D",MR_PLAN:"2022-02-18",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2022-01-17",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:171,SN:55,TAG:"3016P003B",TAG_DESCR:"SHU 2st stage recycleproduct pumps",TAG_DESCR_SHORT:"3016P003B / SHU 2st stage recycleproduct pumps",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-RE-R-E-0620",MR_DESCR:"SHU 2st stage recycle/product pumps",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"D",MR_PLAN:"2022-02-18",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2022-01-17",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:172,SN:56,TAG:"30AS02",TAG_DESCR_SHORT:"30AS02 / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"D",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:173,SN:57,TAG:"3016E001",TAG_DESCR:"RERUN TOWER",TAG_DESCR_SHORT:"3016E001 / RERUN TOWER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0401",MR_DESCR:"RERUN TOWER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"D",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:174,SN:58,TAG:"3016D003",TAG_DESCR:"SHU 1st stage reactor separator drum",TAG_DESCR_SHORT:"3016D003 / SHU 1st stage reactor separator drum",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0301",MR_DESCR:"SHU 1st stage reactor separator drum",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"D",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:175,SN:59,TAG:"3016FIL001B",TAG_DESCR:"C4 feed filters",TAG_DESCR_SHORT:"3016FIL001B / C4 feed filters",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0501",MR_DESCR:"C4 feed filters",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"D",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-10",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-08-04",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-09-28",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:176,SN:60,TAG:"3016FIL001A",TAG_DESCR:"C4 feed filters",TAG_DESCR_SHORT:"3016FIL001A / C4 feed filters",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0501",MR_DESCR:"C4 feed filters",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"D",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-10",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-08-04",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-09-28",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:177,SN:61,TAG:"3016E005",TAG_DESCR:"C4 feed filters",TAG_DESCR_SHORT:"3016E005 / C4 feed filters",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0401",MR_DESCR:"C4 feed filters",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"D",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:178,SN:62,TAG:"3016E003",TAG_DESCR:"Propyne Column Bottoms Filter",TAG_DESCR_SHORT:"3016E003 / Propyne Column Bottoms Filter",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0401",MR_DESCR:"Propyne Column Bottoms Filter",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"D",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:179,SN:63,TAG:"3016R001",TAG_DESCR:"SHU 1st stage reactor",TAG_DESCR_SHORT:"3016R001 / SHU 1st stage reactor",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0201",MR_DESCR:"SHU 1st stage reactor 一段加氢反应器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"D",MR_PLAN:"2022-03-30",MR_FORECAST:"2022-03-30",MR_ACTUAL:"2022-03-03",LOA_PLAN:"2022-07-24",LOA_FORECAST:"2022-07-31",LOA_ACTUAL:"2022-08-02",PO_PLAN:"2022-09-04",PO_FORECAST:"2022-09-11",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:180,SN:64,TAG:"3016R002",TAG_DESCR:"SHU 2nd stage reactor",TAG_DESCR_SHORT:"3016R002 / SHU 2nd stage reactor",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0201",MR_DESCR:"SHU 2nd stage reactor 二段加氢反应器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"D",MR_PLAN:"2022-03-30",MR_FORECAST:"2022-03-30",MR_ACTUAL:"2022-03-03",LOA_PLAN:"2022-07-24",LOA_FORECAST:"2022-07-31",LOA_ACTUAL:"2022-08-02",PO_PLAN:"2022-09-04",PO_FORECAST:"2022-09-11",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:181,SN:65,TAG:"3016E006",TAG_DESCR:"Solvent Surge Tank Vent Canister",TAG_DESCR_SHORT:"3016E006 / Solvent Surge Tank Vent Canister",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0401",MR_DESCR:"Solvent Surge Tank Vent Canister",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"D",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:182,SN:66,TAG:"3016E009",TAG_DESCR:"HD1 DEPENTANIZER",TAG_DESCR_SHORT:"3016E009 / HD1 DEPENTANIZER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0401",MR_DESCR:"HD1 DEPENTANIZER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"D",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:183,SN:67,TAG:"3016D005",TAG_DESCR:"SHU 2nd stage reactor separator drum",TAG_DESCR_SHORT:"3016D005 / SHU 2nd stage reactor separator drum",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0301",MR_DESCR:"SHU 2nd stage reactor separator drum",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"D",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:184,SN:68,TAG:"3016E004",TAG_DESCR:"ELECTRIC HEATER",TAG_DESCR_SHORT:"3016E004 / ELECTRIC HEATER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EE-R-E-4001",MR_DESCR:"ELECTRIC HEATER 电加热器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"D",MR_PLAN:"2022-06-28",MR_FORECAST:"2022-06-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-10-22",LOA_FORECAST:"2022-09-20",LOA_ACTUAL:"2022-09-15",PO_PLAN:"2022-12-03",PO_FORECAST:"2022-11-01",CRITICAL:"NON",STATUS:"LOA Issued",STATUS_CODE:"LOA",STATUS_SEQ:2},
      {NO:185,SN:69,TAG:"3016E008",TAG_DESCR:"SOLVENT SURGE TANK",TAG_DESCR_SHORT:"3016E008 / SOLVENT SURGE TANK",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0401",MR_DESCR:"SOLVENT SURGE TANK",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"D",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:186,SN:70,TAG:"3016E002",TAG_DESCR:"Acetylene Washer Bottom Filter",TAG_DESCR_SHORT:"3016E002 / Acetylene Washer Bottom Filter",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0401",MR_DESCR:"Acetylene Washer Bottom Filter",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"D",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:187,SN:71,TAG:"3016D001",TAG_DESCR:"COALESCER",TAG_DESCR_SHORT:"3016D001 / COALESCER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BD",MR_NO:"75231-P1-1277-EQ-R-E-0301",MR_DESCR:"C4 feed surge drum",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"D",MR_PLAN:"2022-05-20",MR_FORECAST:"2022-05-20",MR_ACTUAL:"2022-06-14",LOA_PLAN:"2022-10-10",LOA_FORECAST:"2022-10-14",LOA_ACTUAL:"2022-10-08",PO_PLAN:"2022-11-21",PO_FORECAST:"2022-11-25",CRITICAL:"NON",STATUS:"LOA Issued",STATUS_CODE:"LOA",STATUS_SEQ:2},
      {NO:355,TAG:"8110C001C-ST",TAG_DESCR:"FD FAN TURBINE",TAG_DESCR_SHORT:"8110C001C-ST / FD FAN TURBINE",PSRNUM:"P1_PSR",PROCESS_UNIT:"BoilerSystems-Rotating",MR_NO:"TBD",MR_DESCR:"FD FAN TURBINE",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:356,TAG:"8110C031A",TAG_DESCR:"Flue Gas Dilution Fan",TAG_DESCR_SHORT:"8110C031A / Flue Gas Dilution Fan",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-SL01",MR_DESCR:"Flue Gas Dilution Fan",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-16",LOA_PLAN:"2022-04-27",LOA_FORECAST:"2022-05-03",LOA_ACTUAL:"2022-05-05",PO_PLAN:"2022-06-08",PO_FORECAST:"2022-06-14",PO_ACTUAL:"2022-07-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:357,TAG:"8110C031B",TAG_DESCR:"Flue Gas Dilution Fan",TAG_DESCR_SHORT:"8110C031B / Flue Gas Dilution Fan",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-SL01",MR_DESCR:"Flue Gas Dilution Fan",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-16",LOA_PLAN:"2022-04-27",LOA_FORECAST:"2022-05-03",LOA_ACTUAL:"2022-05-05",PO_PLAN:"2022-06-08",PO_FORECAST:"2022-06-14",PO_ACTUAL:"2022-07-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:358,TAG:"8110C031C",TAG_DESCR:"Flue Gas Dilution Fan",TAG_DESCR_SHORT:"8110C031C / Flue Gas Dilution Fan",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-SL01",MR_DESCR:"Flue Gas Dilution Fan",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-16",LOA_PLAN:"2022-04-27",LOA_FORECAST:"2022-05-03",LOA_ACTUAL:"2022-05-05",PO_PLAN:"2022-06-08",PO_FORECAST:"2022-06-14",PO_ACTUAL:"2022-07-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:359,TAG:"8110C031D",TAG_DESCR:"Flue Gas Dilution Fan",TAG_DESCR_SHORT:"8110C031D / Flue Gas Dilution Fan",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-SL01",MR_DESCR:"Flue Gas Dilution Fan",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-16",LOA_PLAN:"2022-04-27",LOA_FORECAST:"2022-05-03",LOA_ACTUAL:"2022-05-05",PO_PLAN:"2022-06-08",PO_FORECAST:"2022-06-14",PO_ACTUAL:"2022-07-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:360,TAG:"8110C031E",TAG_DESCR:"Flue Gas Dilution Fan",TAG_DESCR_SHORT:"8110C031E / Flue Gas Dilution Fan",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-SL01",MR_DESCR:"Flue Gas Dilution Fan",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-16",LOA_PLAN:"2022-04-27",LOA_FORECAST:"2022-05-03",LOA_ACTUAL:"2022-05-05",PO_PLAN:"2022-06-08",PO_FORECAST:"2022-06-14",PO_ACTUAL:"2022-07-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:361,TAG:"8110C031F",TAG_DESCR:"Flue Gas Dilution Fan",TAG_DESCR_SHORT:"8110C031F / Flue Gas Dilution Fan",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-SL01",MR_DESCR:"Flue Gas Dilution Fan",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-16",LOA_PLAN:"2022-04-27",LOA_FORECAST:"2022-05-03",LOA_ACTUAL:"2022-05-05",PO_PLAN:"2022-06-08",PO_FORECAST:"2022-06-14",PO_ACTUAL:"2022-07-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:431,TAG:"8110D030",TAG_DESCR_SHORT:"8110D030 / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:365,TAG:"8110D031A",TAG_DESCR:"Ammonia Solution Storage Drum SCR",TAG_DESCR_SHORT:"8110D031A / Ammonia Solution Storage Drum SCR",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-SL01",MR_DESCR:"Ammonia Solution Storage Drum SCR脱硝系统包",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-16",LOA_PLAN:"2022-04-27",LOA_FORECAST:"2022-05-03",LOA_ACTUAL:"2022-05-05",PO_PLAN:"2022-06-08",PO_FORECAST:"2022-06-14",PO_ACTUAL:"2022-07-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:366,TAG:"8110D031B",TAG_DESCR:"Ammonia Solution Storage Drum SCR",TAG_DESCR_SHORT:"8110D031B / Ammonia Solution Storage Drum SCR",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-SL01",MR_DESCR:"Ammonia Solution Storage Drum SCR脱硝系统包",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-16",LOA_PLAN:"2022-04-27",LOA_FORECAST:"2022-05-03",LOA_ACTUAL:"2022-05-05",PO_PLAN:"2022-06-08",PO_FORECAST:"2022-06-14",PO_ACTUAL:"2022-07-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:367,TAG:"8110D032A",TAG_DESCR:"Ammonia Solution Evaporator",TAG_DESCR_SHORT:"8110D032A / Ammonia Solution Evaporator",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-SL01",MR_DESCR:"Ammonia Solution Evaporator",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-16",LOA_PLAN:"2022-04-27",LOA_FORECAST:"2022-05-03",LOA_ACTUAL:"2022-05-05",PO_PLAN:"2022-06-08",PO_FORECAST:"2022-06-14",PO_ACTUAL:"2022-07-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:368,TAG:"8110D032B",TAG_DESCR:"Ammonia Solution Evaporator",TAG_DESCR_SHORT:"8110D032B / Ammonia Solution Evaporator",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-SL01",MR_DESCR:"Ammonia Solution Evaporator",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-16",LOA_PLAN:"2022-04-27",LOA_FORECAST:"2022-05-03",LOA_ACTUAL:"2022-05-05",PO_PLAN:"2022-06-08",PO_FORECAST:"2022-06-14",PO_ACTUAL:"2022-07-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:369,TAG:"8110D032C",TAG_DESCR:"Ammonia Solution Evaporator",TAG_DESCR_SHORT:"8110D032C / Ammonia Solution Evaporator",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-SL01",MR_DESCR:"Ammonia Solution Evaporator",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-16",LOA_PLAN:"2022-04-27",LOA_FORECAST:"2022-05-03",LOA_ACTUAL:"2022-05-05",PO_PLAN:"2022-06-08",PO_FORECAST:"2022-06-14",PO_ACTUAL:"2022-07-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:373,TAG:"8110P031A",TAG_DESCR:"Ammonia Solution Discharge Pump",TAG_DESCR_SHORT:"8110P031A / Ammonia Solution Discharge Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-SL01",MR_DESCR:"Ammonia Solution Discharge Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-16",LOA_PLAN:"2022-04-27",LOA_FORECAST:"2022-05-03",LOA_ACTUAL:"2022-05-05",PO_PLAN:"2022-06-08",PO_FORECAST:"2022-06-14",PO_ACTUAL:"2022-07-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:374,TAG:"8110P031B",TAG_DESCR:"Ammonia Solution Discharge Pump",TAG_DESCR_SHORT:"8110P031B / Ammonia Solution Discharge Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-SL01",MR_DESCR:"Ammonia Solution Discharge Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-16",LOA_PLAN:"2022-04-27",LOA_FORECAST:"2022-05-03",LOA_ACTUAL:"2022-05-05",PO_PLAN:"2022-06-08",PO_FORECAST:"2022-06-14",PO_ACTUAL:"2022-07-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:375,TAG:"8110P032A",TAG_DESCR:"Ammonia Solution Metering Pump",TAG_DESCR_SHORT:"8110P032A / Ammonia Solution Metering Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-SL01",MR_DESCR:"Ammonia Solution Metering Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-16",LOA_PLAN:"2022-04-27",LOA_FORECAST:"2022-05-03",LOA_ACTUAL:"2022-05-05",PO_PLAN:"2022-06-08",PO_FORECAST:"2022-06-14",PO_ACTUAL:"2022-07-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:376,TAG:"8110P032B",TAG_DESCR:"Ammonia Solution Metering Pump",TAG_DESCR_SHORT:"8110P032B / Ammonia Solution Metering Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-SL01",MR_DESCR:"Ammonia Solution Metering Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-16",LOA_PLAN:"2022-04-27",LOA_FORECAST:"2022-05-03",LOA_ACTUAL:"2022-05-05",PO_PLAN:"2022-06-08",PO_FORECAST:"2022-06-14",PO_ACTUAL:"2022-07-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:377,TAG:"8110P032C",TAG_DESCR:"Ammonia Solution Metering Pump",TAG_DESCR_SHORT:"8110P032C / Ammonia Solution Metering Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-SL01",MR_DESCR:"Ammonia Solution Metering Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-16",LOA_PLAN:"2022-04-27",LOA_FORECAST:"2022-05-03",LOA_ACTUAL:"2022-05-05",PO_PLAN:"2022-06-08",PO_FORECAST:"2022-06-14",PO_ACTUAL:"2022-07-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:378,TAG:"8110P032D",TAG_DESCR:"Ammonia Solution Metering Pump",TAG_DESCR_SHORT:"8110P032D / Ammonia Solution Metering Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-SL01",MR_DESCR:"Ammonia Solution Metering Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-16",LOA_PLAN:"2022-04-27",LOA_FORECAST:"2022-05-03",LOA_ACTUAL:"2022-05-05",PO_PLAN:"2022-06-08",PO_FORECAST:"2022-06-14",PO_ACTUAL:"2022-07-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:379,TAG:"8110P033A",TAG_DESCR:"Waste Amine Pump",TAG_DESCR_SHORT:"8110P033A / Waste Amine Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-SL01",MR_DESCR:"Waste Amine Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-16",LOA_PLAN:"2022-04-27",LOA_FORECAST:"2022-05-03",LOA_ACTUAL:"2022-05-05",PO_PLAN:"2022-06-08",PO_FORECAST:"2022-06-14",PO_ACTUAL:"2022-07-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:380,TAG:"8110P033B",TAG_DESCR:"Waste Amine Pump",TAG_DESCR_SHORT:"8110P033B / Waste Amine Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-SL01",MR_DESCR:"Waste Amine Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-16",LOA_PLAN:"2022-04-27",LOA_FORECAST:"2022-05-03",LOA_ACTUAL:"2022-05-05",PO_PLAN:"2022-06-08",PO_FORECAST:"2022-06-14",PO_ACTUAL:"2022-07-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:381,TAG:"8110PK001",TAG_DESCR:"3x180th Boiler",TAG_DESCR_SHORT:"8110PK001 / 3x180th Boiler",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-LL01",MR_DESCR:"3x180t/h Boiler/工业炉",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",MR_PLAN:"2021-07-27",MR_FORECAST:"2021-08-15",MR_ACTUAL:"2021-08-10",LOA_PLAN:"2022-02-14",LOA_FORECAST:"2022-01-02",LOA_ACTUAL:"2021-12-31",PO_PLAN:"2022-03-28",PO_FORECAST:"2022-03-28",PO_ACTUAL:"2022-04-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:382,TAG:"8110PK002",TAG_DESCR:"SCR System",TAG_DESCR_SHORT:"8110PK002 / SCR System",PSRNUM:"P1_PSR",PROCESS_UNIT:"BoilerSystems-Package",MR_NO:"TBD",MR_DESCR:"SCR System",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:387,TAG:"8110S031",TAG_DESCR:"Ammonia Injection Grid",TAG_DESCR_SHORT:"8110S031 / Ammonia Injection Grid",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-SL01",MR_DESCR:"Ammonia Injection Grid",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-16",LOA_PLAN:"2022-04-27",LOA_FORECAST:"2022-05-03",LOA_ACTUAL:"2022-05-05",PO_PLAN:"2022-06-08",PO_FORECAST:"2022-06-14",PO_ACTUAL:"2022-07-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:388,TAG:"8110S032",TAG_DESCR:"Ammonia Injection Grid",TAG_DESCR_SHORT:"8110S032 / Ammonia Injection Grid",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-SL01",MR_DESCR:"Ammonia Injection Grid",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-16",LOA_PLAN:"2022-04-27",LOA_FORECAST:"2022-05-03",LOA_ACTUAL:"2022-05-05",PO_PLAN:"2022-06-08",PO_FORECAST:"2022-06-14",PO_ACTUAL:"2022-07-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:389,TAG:"8110S033",TAG_DESCR:"Ammonia Injection Grid",TAG_DESCR_SHORT:"8110S033 / Ammonia Injection Grid",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-SL01",MR_DESCR:"Ammonia Injection Grid",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-16",LOA_PLAN:"2022-04-27",LOA_FORECAST:"2022-05-03",LOA_ACTUAL:"2022-05-05",PO_PLAN:"2022-06-08",PO_FORECAST:"2022-06-14",PO_ACTUAL:"2022-07-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:391,TAG:"8310D004",TAG_DESCR:"Deoxidant Solution Tank",TAG_DESCR_SHORT:"8310D004 / Deoxidant Solution Tank",PSRNUM:"P1_PSR",PROCESS_UNIT:"BoilerSystems-Package",MR_NO:"TBD",MR_DESCR:"Deoxidant Solution Tank",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:392,TAG:"8310D005",TAG_DESCR:"Amine Solution Tank",TAG_DESCR_SHORT:"8310D005 / Amine Solution Tank",PSRNUM:"P1_PSR",PROCESS_UNIT:"BoilerSystems-Package",MR_NO:"TBD",MR_DESCR:"Amine Solution Tank",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:393,TAG:"8310D006",TAG_DESCR:"Phosphate Solution Tank",TAG_DESCR_SHORT:"8310D006 / Phosphate Solution Tank",PSRNUM:"P1_PSR",PROCESS_UNIT:"BoilerSystems-Package",MR_NO:"TBD",MR_DESCR:"Phosphate Solution Tank",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:394,TAG:"8310FCV3021A",TAG_DESCR:"SHP BFW Pump AutoCirc ValveIncluding Back Pressure Valve 8310PCV3021ABCD",TAG_DESCR_SHORT:"8310FCV3021A / SHP BFW Pump AutoCirc ValveIncludi...",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-0904",MR_DESCR:"SHP BFW Pump Auto-Circ. Valve(Including Back Pressure Valve 8310PCV3021A/B/C/D)",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",MR_PLAN:"2022-09-30",MR_FORECAST:"2022-09-30",MR_ACTUAL:"2022-08-16",LOA_PLAN:"2022-12-23",LOA_FORECAST:"2022-12-26",LOA_ACTUAL:"2022-12-23",PO_PLAN:"2023-02-03",PO_FORECAST:"2023-02-06",PO_ACTUAL:"2023-01-13",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:395,TAG:"8310FCV3021B",TAG_DESCR:"SHP BFW Pump AutoCirc ValveIncluding Back Pressure Valve 8310PCV3021ABCD",TAG_DESCR_SHORT:"8310FCV3021B / SHP BFW Pump AutoCirc ValveIncludi...",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-0904",MR_DESCR:"SHP BFW Pump Auto-Circ. Valve(Including Back Pressure Valve 8310PCV3021A/B/C/D)",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",MR_PLAN:"2022-09-30",MR_FORECAST:"2022-09-30",MR_ACTUAL:"2022-08-16",LOA_PLAN:"2022-12-23",LOA_FORECAST:"2022-12-26",LOA_ACTUAL:"2022-12-23",PO_PLAN:"2023-02-03",PO_FORECAST:"2023-02-06",PO_ACTUAL:"2023-01-13",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:396,TAG:"8310FCV3021C",TAG_DESCR:"SHP BFW Pump AutoCirc ValveIncluding Back Pressure Valve 8310PCV3021ABCD",TAG_DESCR_SHORT:"8310FCV3021C / SHP BFW Pump AutoCirc ValveIncludi...",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-0904",MR_DESCR:"SHP BFW Pump Auto-Circ. Valve(Including Back Pressure Valve 8310PCV3021A/B/C/D)",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",MR_PLAN:"2022-09-30",MR_FORECAST:"2022-09-30",MR_ACTUAL:"2022-08-16",LOA_PLAN:"2022-12-23",LOA_FORECAST:"2022-12-26",LOA_ACTUAL:"2022-12-23",PO_PLAN:"2023-02-03",PO_FORECAST:"2023-02-06",PO_ACTUAL:"2023-01-13",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:397,TAG:"8310FCV3021D",TAG_DESCR:"SHP BFW Pump AutoCirc ValveIncluding Back Pressure Valve 8310PCV3021ABCD",TAG_DESCR_SHORT:"8310FCV3021D / SHP BFW Pump AutoCirc ValveIncludi...",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-0904",MR_DESCR:"SHP BFW Pump Auto-Circ. Valve(Including Back Pressure Valve 8310PCV3021A/B/C/D)",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",MR_PLAN:"2022-09-30",MR_FORECAST:"2022-09-30",MR_ACTUAL:"2022-08-16",LOA_PLAN:"2022-12-23",LOA_FORECAST:"2022-12-26",LOA_ACTUAL:"2022-12-23",PO_PLAN:"2023-02-03",PO_FORECAST:"2023-02-06",PO_ACTUAL:"2023-01-13",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:398,TAG:"8310FCV3031A",TAG_DESCR:"HP BFW Pump AutoCirc ValveIncluding Back Pressure Valve 8310PCV3031AB",TAG_DESCR_SHORT:"8310FCV3031A / HP BFW Pump AutoCirc ValveIncludin...",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-0904",MR_DESCR:"HP BFW Pump Auto-Circ. Valve(Including Back Pressure Valve 8310PCV3031A/B)",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",MR_PLAN:"2022-09-30",MR_FORECAST:"2022-09-30",MR_ACTUAL:"2022-08-16",LOA_PLAN:"2022-12-23",LOA_FORECAST:"2022-12-26",LOA_ACTUAL:"2022-12-23",PO_PLAN:"2023-02-03",PO_FORECAST:"2023-02-06",PO_ACTUAL:"2023-01-13",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:399,TAG:"8310FCV3031B",TAG_DESCR:"HP BFW Pump AutoCirc ValveIncluding Back Pressure Valve 8310PCV3031AB",TAG_DESCR_SHORT:"8310FCV3031B / HP BFW Pump AutoCirc ValveIncludin...",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-0904",MR_DESCR:"HP BFW Pump Auto-Circ. Valve(Including Back Pressure Valve 8310PCV3031A/B)",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",MR_PLAN:"2022-09-30",MR_FORECAST:"2022-09-30",MR_ACTUAL:"2022-08-16",LOA_PLAN:"2022-12-23",LOA_FORECAST:"2022-12-26",LOA_ACTUAL:"2022-12-23",PO_PLAN:"2023-02-03",PO_FORECAST:"2023-02-06",PO_ACTUAL:"2023-01-13",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:400,TAG:"8310FCV3032A",TAG_DESCR:"LP BFW Pump AutoCirc ValveIncluding Back Pressure Valve 8310PCV3032AB",TAG_DESCR_SHORT:"8310FCV3032A / LP BFW Pump AutoCirc ValveIncludin...",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-0904",MR_DESCR:"LP BFW Pump Auto-Circ. Valve(Including Back Pressure Valve 8310PCV3032A/B)",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",MR_PLAN:"2022-09-30",MR_FORECAST:"2022-09-30",MR_ACTUAL:"2022-08-16",LOA_PLAN:"2022-12-23",LOA_FORECAST:"2022-12-26",LOA_ACTUAL:"2022-12-23",PO_PLAN:"2023-02-03",PO_FORECAST:"2023-02-06",PO_ACTUAL:"2023-01-13",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:401,TAG:"8310FCV3032B",TAG_DESCR:"LP BFW Pump AutoCirc ValveIncluding Back Pressure Valve 8310PCV3032AB",TAG_DESCR_SHORT:"8310FCV3032B / LP BFW Pump AutoCirc ValveIncludin...",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-0904",MR_DESCR:"LP BFW Pump Auto-Circ. Valve(Including Back Pressure Valve 8310PCV3032A/B)",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",MR_PLAN:"2022-09-30",MR_FORECAST:"2022-09-30",MR_ACTUAL:"2022-08-16",LOA_PLAN:"2022-12-23",LOA_FORECAST:"2022-12-26",LOA_ACTUAL:"2022-12-23",PO_PLAN:"2023-02-03",PO_FORECAST:"2023-02-06",PO_ACTUAL:"2023-01-13",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:407,TAG:"8310P002A",TAG_DESCR:"HP BFW Pump",TAG_DESCR_SHORT:"8310P002A / HP BFW Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"Boiler Systems",MR_NO:"TBD",MR_DESCR:"HP BFW Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:408,TAG:"8310P002A-ST",TAG_DESCR:"HP BFW PUMP TURBINE",TAG_DESCR_SHORT:"8310P002A-ST / HP BFW PUMP TURBINE",PSRNUM:"P1_PSR",PROCESS_UNIT:"BoilerSystems-Rotating",MR_NO:"TBD",MR_DESCR:"HP BFW PUMP TURBINE",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:409,TAG:"8310P002B",TAG_DESCR:"HP BFW Pump (motor)",TAG_DESCR_SHORT:"8310P002B / HP BFW Pump motor",PSRNUM:"P1_PSR",PROCESS_UNIT:"BoilerSystems-Rotating",MR_NO:"TBD",MR_DESCR:"HP BFW Pump (motor)",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:412,TAG:"8310P004A",TAG_DESCR:"Deoxidizer Solution Metering Pump",TAG_DESCR_SHORT:"8310P004A / Deoxidizer Solution Metering Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BoilerSystems-Package",MR_NO:"TBD",MR_DESCR:"Deoxidizer Solution Metering Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:413,TAG:"8310P004B",TAG_DESCR:"Deoxidizer Solution Metering Pump",TAG_DESCR_SHORT:"8310P004B / Deoxidizer Solution Metering Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BoilerSystems-Package",MR_NO:"TBD",MR_DESCR:"Deoxidizer Solution Metering Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:414,TAG:"8310P005A",TAG_DESCR:"Amine Solution Meteing Pump",TAG_DESCR_SHORT:"8310P005A / Amine Solution Meteing Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BoilerSystems-Package",MR_NO:"TBD",MR_DESCR:"Amine Solution Meteing Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:415,TAG:"8310P005B",TAG_DESCR:"Amine Solution Meteing Pump",TAG_DESCR_SHORT:"8310P005B / Amine Solution Meteing Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BoilerSystems-Package",MR_NO:"TBD",MR_DESCR:"Amine Solution Meteing Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:416,TAG:"8310P008A",TAG_DESCR:"Phosphate Solution Metering Pump",TAG_DESCR_SHORT:"8310P008A / Phosphate Solution Metering Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BoilerSystems-Package",MR_NO:"TBD",MR_DESCR:"Phosphate Solution Metering Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:417,TAG:"8310P008B",TAG_DESCR:"Phosphate Solution Metering Pump",TAG_DESCR_SHORT:"8310P008B / Phosphate Solution Metering Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BoilerSystems-Package",MR_NO:"TBD",MR_DESCR:"Phosphate Solution Metering Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:418,TAG:"8310P008C",TAG_DESCR:"Phosphate Solution Metering Pump",TAG_DESCR_SHORT:"8310P008C / Phosphate Solution Metering Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BoilerSystems-Package",MR_NO:"TBD",MR_DESCR:"Phosphate Solution Metering Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:419,TAG:"8310P008D",TAG_DESCR:"Phosphate Solution Metering Pump",TAG_DESCR_SHORT:"8310P008D / Phosphate Solution Metering Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BoilerSystems-Package",MR_NO:"TBD",MR_DESCR:"Phosphate Solution Metering Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:420,TAG:"8310P008E",TAG_DESCR:"Phosphate Solution Metering Pump",TAG_DESCR_SHORT:"8310P008E / Phosphate Solution Metering Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BoilerSystems-Package",MR_NO:"TBD",MR_DESCR:"Phosphate Solution Metering Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:421,TAG:"8310P008F",TAG_DESCR:"Phosphate Solution Metering Pump",TAG_DESCR_SHORT:"8310P008F / Phosphate Solution Metering Pump",PSRNUM:"P1_PSR",PROCESS_UNIT:"BoilerSystems-Package",MR_NO:"TBD",MR_DESCR:"Phosphate Solution Metering Pump",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:422,TAG:"8310PK001",TAG_DESCR:"DEAERATOR PACKAGE",TAG_DESCR_SHORT:"8310PK001 / DEAERATOR PACKAGE",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-0901",MR_DESCR:"DEAERATOR PACKAGE 除氧器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",MR_PLAN:"2022-05-08",MR_FORECAST:"2022-05-08",MR_ACTUAL:"2022-03-03",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-06-30",LOA_ACTUAL:"2022-06-29",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-08-11",PO_ACTUAL:"2022-08-29",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:390,TAG:"8210PK001",TAG_DESCR:"CONDENSATE WATER TREATMENT PACKAGE",TAG_DESCR_SHORT:"8210PK001 / CONDENSATE WATER TREATMENT PACKAGE",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1843-TM-R-E-0902",MR_DESCR:"CONDENSATE WATER TREATMENT PACKAGE 凝结水处理包",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",MR_PLAN:"2022-03-08",MR_FORECAST:"2022-03-08",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-06-26",LOA_FORECAST:"2022-06-01",LOA_ACTUAL:"2022-06-28",PO_PLAN:"2022-08-07",PO_FORECAST:"2022-09-15",PO_ACTUAL:"2022-09-08",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:426,TAG:"8710PK001",TAG_DESCR:"CHEMICAL WATER TREATMENT PACKAGE",TAG_DESCR_SHORT:"8710PK001 / CHEMICAL WATER TREATMENT PACKAGE",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1843-TM-R-E-0901",MR_DESCR:"CHEMICAL WATER TREATMENT PACKAGE 化学水处理包",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",MR_PLAN:"2022-03-08",MR_FORECAST:"2022-03-08",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-06-26",LOA_FORECAST:"2022-06-05",LOA_ACTUAL:"2022-06-28",PO_PLAN:"2022-08-07",PO_FORECAST:"2022-07-17",PO_ACTUAL:"2022-09-07",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:427,TAG:"8710PK002",TAG_DESCR:"AMMONIA ADDING PACKAGE",TAG_DESCR_SHORT:"8710PK002 / AMMONIA ADDING PACKAGE",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1843-TM-R-E-0903",MR_DESCR:"AMMONIA ADDING PACKAGE 组合式加氨装置包",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",MR_PLAN:"2022-05-30",MR_FORECAST:"2022-05-30",MR_ACTUAL:"2022-06-16",LOA_PLAN:"2022-09-20",LOA_FORECAST:"2022-12-18",LOA_ACTUAL:"2022-12-15",PO_PLAN:"2022-11-01",PO_FORECAST:"2023-01-29",CRITICAL:"NON",STATUS:"LOA Issued",STATUS_CODE:"LOA",STATUS_SEQ:2},
      {NO:386,SN:1,TAG:"8110S001",TAG_DESCR:"Stack",TAG_DESCR_SHORT:"8110S001 / Stack",PSRNUM:"P1_PSR",PROCESS_UNIT:"BoilerSystems-Others",MR_NO:"TBD",MR_DESCR:"Stack",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",AREA_UNIT:"A",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:383,SN:2,TAG:"8110R031",TAG_DESCR:"SCR REACTOR",TAG_DESCR_SHORT:"8110R031 / SCR REACTOR",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-SL01",MR_DESCR:"SCR REACTOR",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",AREA_UNIT:"A",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-16",LOA_PLAN:"2022-04-27",LOA_FORECAST:"2022-05-03",LOA_ACTUAL:"2022-05-05",PO_PLAN:"2022-06-08",PO_FORECAST:"2022-06-14",PO_ACTUAL:"2022-07-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:384,SN:3,TAG:"8110R032",TAG_DESCR:"SCR REACTOR",TAG_DESCR_SHORT:"8110R032 / SCR REACTOR",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-SL01",MR_DESCR:"SCR REACTOR",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",AREA_UNIT:"A",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-16",LOA_PLAN:"2022-04-27",LOA_FORECAST:"2022-05-03",LOA_ACTUAL:"2022-05-05",PO_PLAN:"2022-06-08",PO_FORECAST:"2022-06-14",PO_ACTUAL:"2022-07-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:385,SN:4,TAG:"8110R033",TAG_DESCR:"SCR REACTOR",TAG_DESCR_SHORT:"8110R033 / SCR REACTOR",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-SL01",MR_DESCR:"SCR REACTOR",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",AREA_UNIT:"A",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-16",LOA_PLAN:"2022-04-27",LOA_FORECAST:"2022-05-03",LOA_ACTUAL:"2022-05-05",PO_PLAN:"2022-06-08",PO_FORECAST:"2022-06-14",PO_ACTUAL:"2022-07-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:428,SN:5,TAG:"8110B001A",TAG_DESCR_SHORT:"8110B001A / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",AREA_UNIT:"A",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:429,SN:6,TAG:"8110B001B",TAG_DESCR_SHORT:"8110B001B / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",AREA_UNIT:"A",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:430,SN:7,TAG:"8110B001C",TAG_DESCR_SHORT:"8110B001C / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",AREA_UNIT:"A",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:352,SN:8,TAG:"8110C001A",TAG_DESCR:"Forced Draft Fan",TAG_DESCR_SHORT:"8110C001A / Forced Draft Fan",PSRNUM:"P1_PSR",PROCESS_UNIT:"Boiler Systems",MR_NO:"TBD",MR_DESCR:"Forced Draft Fan",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",AREA_UNIT:"A",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:353,SN:9,TAG:"8110C001B",TAG_DESCR:"Forced Draft Fan",TAG_DESCR_SHORT:"8110C001B / Forced Draft Fan",PSRNUM:"P1_PSR",PROCESS_UNIT:"Boiler Systems",MR_NO:"TBD",MR_DESCR:"Forced Draft Fan",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",AREA_UNIT:"A",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:354,SN:10,TAG:"8110C001C",TAG_DESCR:"Forced Draft Fan",TAG_DESCR_SHORT:"8110C001C / Forced Draft Fan",PSRNUM:"P1_PSR",PROCESS_UNIT:"Boiler Systems",MR_NO:"TBD",MR_DESCR:"Forced Draft Fan",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",AREA_UNIT:"A",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:433,SN:11,TAG:"8110ST001-2",TAG_DESCR_SHORT:"8110ST001-2 / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",AREA_UNIT:"A",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:435,SN:12,TAG:"8310D001",TAG_DESCR_SHORT:"8310D001 / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",AREA_UNIT:"A",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:434,SN:13,TAG:"8110ST002",TAG_DESCR_SHORT:"8110ST002 / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",AREA_UNIT:"A",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:432,SN:14,TAG:"8110SI001",TAG_DESCR_SHORT:"8110SI001 / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",AREA_UNIT:"A",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:423,SN:16,TAG:"8310PK002",TAG_DESCR:"DOSING SYSTEM PACKAGE",TAG_DESCR_SHORT:"8310PK002 / DOSING SYSTEM PACKAGE",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-0902",MR_DESCR:"DOSING SYSTEM PACKAGE 加药系统包",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",AREA_UNIT:"A",MR_PLAN:"2022-05-20",MR_FORECAST:"2022-05-20",MR_ACTUAL:"2022-06-16",LOA_PLAN:"2022-09-28",LOA_FORECAST:"2022-12-18",LOA_ACTUAL:"2022-12-15",PO_PLAN:"2022-11-09",PO_FORECAST:"2023-01-29",CRITICAL:"NON",STATUS:"LOA Issued",STATUS_CODE:"LOA",STATUS_SEQ:2},
      {NO:424,SN:17,TAG:"8310PK003",TAG_DESCR:"DOSING SYSTEM PACKAGE",TAG_DESCR_SHORT:"8310PK003 / DOSING SYSTEM PACKAGE",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-0902",MR_DESCR:"DOSING SYSTEM PACKAGE 加药系统包",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",AREA_UNIT:"A",MR_PLAN:"2022-05-20",MR_FORECAST:"2022-05-20",MR_ACTUAL:"2022-06-16",LOA_PLAN:"2022-09-29",LOA_FORECAST:"2022-12-18",LOA_ACTUAL:"2022-12-15",PO_PLAN:"2022-11-10",PO_FORECAST:"2023-01-29",CRITICAL:"NON",STATUS:"LOA Issued",STATUS_CODE:"LOA",STATUS_SEQ:2},
      {NO:425,SN:18,TAG:"8310PK004",TAG_DESCR:"DOSING SYSTEM PACKAGE",TAG_DESCR_SHORT:"8310PK004 / DOSING SYSTEM PACKAGE",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-TM-R-E-0902",MR_DESCR:"DOSING SYSTEM PACKAGE 加药系统包",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",AREA_UNIT:"A",MR_PLAN:"2022-05-20",MR_FORECAST:"2022-05-20",MR_ACTUAL:"2022-06-16",LOA_PLAN:"2022-09-30",LOA_FORECAST:"2022-12-18",LOA_ACTUAL:"2022-12-15",PO_PLAN:"2022-11-11",PO_FORECAST:"2023-01-29",CRITICAL:"NON",STATUS:"LOA Issued",STATUS_CODE:"LOA",STATUS_SEQ:2},
      {NO:437,SN:19,TAG:"8310P001A-ST-X1",TAG_DESCR_SHORT:"8310P001A-ST-X1 / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",AREA_UNIT:"A",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:402,SN:20,TAG:"8310P001A",TAG_DESCR:"SHP BFW PUMP",TAG_DESCR_SHORT:"8310P001A / SHP BFW PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-RE-R-E-SL01",MR_DESCR:"SHP BFW PUMP超高压锅炉给水泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",AREA_UNIT:"A",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-13",LOA_PLAN:"2022-04-13",LOA_FORECAST:"2022-07-04",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-05-25",PO_FORECAST:"2022-08-15",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:436,SN:21,TAG:"8310P001A-ST",TAG_DESCR_SHORT:"8310P001A-ST / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",AREA_UNIT:"A",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:441,SN:22,TAG:"8310SI002",TAG_DESCR_SHORT:"8310SI002 / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",AREA_UNIT:"A",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:440,SN:23,TAG:"8310SI001",TAG_DESCR_SHORT:"8310SI001 / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",AREA_UNIT:"A",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:403,SN:24,TAG:"8310P001B",TAG_DESCR:"SHP BFW PUMP",TAG_DESCR_SHORT:"8310P001B / SHP BFW PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-RE-R-E-SL01",MR_DESCR:"SHP BFW PUMP超高压锅炉给水泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",AREA_UNIT:"A",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-13",LOA_PLAN:"2022-04-13",LOA_FORECAST:"2022-07-04",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-05-25",PO_FORECAST:"2022-08-15",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:404,SN:25,TAG:"8310P001B-ST",TAG_DESCR:"SHP BFW PUMP TURBINE",TAG_DESCR_SHORT:"8310P001B-ST / SHP BFW PUMP TURBINE",PSRNUM:"P1_PSR",PROCESS_UNIT:"BoilerSystems-Rotating",MR_NO:"TBD",MR_DESCR:"SHP BFW PUMP TURBINE",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",AREA_UNIT:"A",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:405,SN:26,TAG:"8310P001C",TAG_DESCR:"SHP BFW PUMP",TAG_DESCR_SHORT:"8310P001C / SHP BFW PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-RE-R-E-SL01",MR_DESCR:"SHP BFW PUMP超高压锅炉给水泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",AREA_UNIT:"A",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-13",LOA_PLAN:"2022-04-13",LOA_FORECAST:"2022-07-04",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-05-25",PO_FORECAST:"2022-08-15",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:438,SN:27,TAG:"8310P001C-ST",TAG_DESCR_SHORT:"8310P001C-ST / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",AREA_UNIT:"A",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:406,SN:28,TAG:"8310P001D",TAG_DESCR:"SHP BFW PUMP",TAG_DESCR_SHORT:"8310P001D / SHP BFW PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-RE-R-E-SL01",MR_DESCR:"SHP BFW PUMP超高压锅炉给水泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",AREA_UNIT:"A",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-13",LOA_PLAN:"2022-04-13",LOA_FORECAST:"2022-07-04",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-05-25",PO_FORECAST:"2022-08-15",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:439,SN:29,TAG:"8310P001D-ST",TAG_DESCR_SHORT:"8310P001D-ST / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",AREA_UNIT:"A",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:410,SN:30,TAG:"8310P003A",TAG_DESCR:"LP BFW PUMP",TAG_DESCR_SHORT:"8310P003A / LP BFW PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-RE-R-E-0621",MR_DESCR:"二类离心泵 LP BFW PUMP低压锅炉给水泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:411,SN:31,TAG:"8310P003B",TAG_DESCR:"LP BFW PUMP",TAG_DESCR_SHORT:"8310P003B / LP BFW PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-RE-R-E-0621",MR_DESCR:"二类离心泵 LP BFW PUMP低压锅炉给水泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:370,SN:32,TAG:"8110P001A",TAG_DESCR:"Blowdown PUMP",TAG_DESCR_SHORT:"8110P001A / Blowdown PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-RE-R-E-0621",MR_DESCR:"Blowdown PUMP锅炉排污泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:371,SN:33,TAG:"8110P001B",TAG_DESCR:"Blowdown PUMP",TAG_DESCR_SHORT:"8110P001B / Blowdown PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-RE-R-E-0621",MR_DESCR:"Blowdown PUMP锅炉排污泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:372,SN:34,TAG:"8110P001C",TAG_DESCR:"Blowdown PUMP",TAG_DESCR_SHORT:"8110P001C / Blowdown PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-RE-R-E-0621",MR_DESCR:"Blowdown PUMP锅炉排污泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:362,SN:35,TAG:"8110D001",TAG_DESCR:"Continous Blowdown Drum",TAG_DESCR_SHORT:"8110D001 / Continous Blowdown Drum",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-EQ-R-E-0301",MR_DESCR:"Continous Blowdown Drum",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-13",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-09-24",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:363,SN:36,TAG:"8110D002",TAG_DESCR:"Intermittent Blowdown Drum",TAG_DESCR_SHORT:"8110D002 / Intermittent Blowdown Drum",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-EQ-R-E-0301",MR_DESCR:"Intermittent Blowdown Drum",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-13",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-09-24",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:364,SN:37,TAG:"8110D004",TAG_DESCR:"Boiler Fuel Gas KO Drum",TAG_DESCR_SHORT:"8110D004 / Boiler Fuel Gas KO Drum",PSRNUM:"P1_PSR",PROCESS_UNIT:"BOILER",MR_NO:"75231-P1-1848-EQ-R-E-0301",MR_DESCR:"Boiler Fuel Gas K.O. Drum",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-13",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-09-24",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:93,TAG:"3810E020H",TAG_DESCR:"RECOVERY COLUMN CONDENSER",TAG_DESCR_SHORT:"3810E020H / RECOVERY COLUMN CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0401",MR_DESCR:"RECOVERY COLUMN CONDENSER回收塔顶空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-12-02",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:94,TAG:"3810E020I",TAG_DESCR:"RECOVERY COLUMN CONDENSER",TAG_DESCR_SHORT:"3810E020I / RECOVERY COLUMN CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0401",MR_DESCR:"RECOVERY COLUMN CONDENSER回收塔顶空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-12-02",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:95,TAG:"3810E020J",TAG_DESCR:"RECOVERY COLUMN CONDENSER",TAG_DESCR_SHORT:"3810E020J / RECOVERY COLUMN CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0401",MR_DESCR:"RECOVERY COLUMN CONDENSER回收塔顶空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-12-02",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:96,TAG:"3810E020K",TAG_DESCR:"RECOVERY COLUMN CONDENSER",TAG_DESCR_SHORT:"3810E020K / RECOVERY COLUMN CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0401",MR_DESCR:"RECOVERY COLUMN CONDENSER回收塔顶空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-12-02",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:97,TAG:"3810E020L",TAG_DESCR:"RECOVERY COLUMN CONDENSER",TAG_DESCR_SHORT:"3810E020L / RECOVERY COLUMN CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0401",MR_DESCR:"RECOVERY COLUMN CONDENSER回收塔顶空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-12-02",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:98,TAG:"3810E020M",TAG_DESCR:"RECOVERY COLUMN CONDENSER",TAG_DESCR_SHORT:"3810E020M / RECOVERY COLUMN CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0401",MR_DESCR:"RECOVERY COLUMN CONDENSER回收塔顶空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-12-02",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:99,TAG:"3810E020N",TAG_DESCR:"RECOVERY COLUMN CONDENSER",TAG_DESCR_SHORT:"3810E020N / RECOVERY COLUMN CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0401",MR_DESCR:"RECOVERY COLUMN CONDENSER回收塔顶空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-12-02",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:100,TAG:"3810E020O",TAG_DESCR:"Recovery Column Condenser",TAG_DESCR_SHORT:"3810E020O / Recovery Column Condenser",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX - Heat Exchangers",MR_NO:"TBD",MR_DESCR:"Recovery Column Condenser",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:101,TAG:"3810E020P",TAG_DESCR:"Recovery Column Condenser",TAG_DESCR_SHORT:"3810E020P / Recovery Column Condenser",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX - Heat Exchangers",MR_NO:"TBD",MR_DESCR:"Recovery Column Condenser",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:102,TAG:"3810P001A",TAG_DESCR:"SULFOLANE SUMP PUMPS",TAG_DESCR_SHORT:"3810P001A / SULFOLANE SUMP PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0620",MR_DESCR:"SULFOLANE SUMP PUMPS地下溶剂泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",MR_PLAN:"2022-02-18",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2021-12-13",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:103,TAG:"3810P001B",TAG_DESCR:"SULFOLANE SUMP PUMPS",TAG_DESCR_SHORT:"3810P001B / SULFOLANE SUMP PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0620",MR_DESCR:"SULFOLANE SUMP PUMPS地下溶剂泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",MR_PLAN:"2022-02-18",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2021-12-13",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:104,TAG:"3810SC010",TAG_DESCR:"FRESH SOLVENT HEATER",TAG_DESCR_SHORT:"3810SC010 / FRESH SOLVENT HEATER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX - Heat Exchangers",MR_NO:"TBD",MR_DESCR:"FRESH SOLVENT HEATER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:105,TAG:"3810SC020",TAG_DESCR:"SOLVENT HEATER",TAG_DESCR_SHORT:"3810SC020 / SOLVENT HEATER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX - Heat Exchangers",MR_NO:"TBD",MR_DESCR:"SOLVENT HEATER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:106,TAG:"3810T010-I",TAG_DESCR:"EXTRACTIVE DISTILLATION COLUMN",TAG_DESCR_SHORT:"3810T010-I / EXTRACTIVE DISTILLATION COLUMN",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0102",MR_DESCR:"EXTRACTIVE DISTILLATION COLUMN抽提蒸馏塔内件",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",MR_PLAN:"2021-12-03",MR_FORECAST:"2021-12-03",MR_ACTUAL:"2021-11-26",LOA_PLAN:"2022-04-06",LOA_FORECAST:"2022-06-27",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-05-18",PO_FORECAST:"2022-09-25",PO_ACTUAL:"2022-09-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:107,TAG:"3810T020-I",TAG_DESCR:"RECOVERY COLUMN",TAG_DESCR_SHORT:"3810T020-I / RECOVERY COLUMN",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0102",MR_DESCR:"RECOVERY COLUMN回收塔内件",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",MR_PLAN:"2021-12-03",MR_FORECAST:"2021-12-03",MR_ACTUAL:"2021-11-26",LOA_PLAN:"2022-04-06",LOA_FORECAST:"2022-06-27",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-05-18",PO_FORECAST:"2022-09-25",PO_ACTUAL:"2022-09-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:108,TAG:"3820E010D",TAG_DESCR:"BENZENE COLUMN CONDENSER",TAG_DESCR_SHORT:"3820E010D / BENZENE COLUMN CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0401",MR_DESCR:"BENZENE COLUMN CONDENSER苯塔顶空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-12-02",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:109,TAG:"3820E010E",TAG_DESCR:"BENZENE COLUMN CONDENSER",TAG_DESCR_SHORT:"3820E010E / BENZENE COLUMN CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0401",MR_DESCR:"BENZENE COLUMN CONDENSER苯塔顶空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-12-02",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:110,TAG:"3820E010F",TAG_DESCR:"BENZENE COLUMN CONDENSER",TAG_DESCR_SHORT:"3820E010F / BENZENE COLUMN CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0401",MR_DESCR:"BENZENE COLUMN CONDENSER苯塔顶空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-12-02",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:111,TAG:"3820P014A",TAG_DESCR:"BENZENE COLUMN WASTE WATER PUMPS",TAG_DESCR_SHORT:"3820P014A / BENZENE COLUMN WASTE WATER PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0630",MR_DESCR:"BENZENE COLUMN WASTE WATER PUMPS 计量泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",MR_PLAN:"2022-03-28",MR_FORECAST:"2022-03-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-07-26",LOA_FORECAST:"2022-09-20",LOA_ACTUAL:"2022-09-15",PO_PLAN:"2022-09-06",PO_FORECAST:"2022-11-01",PO_ACTUAL:"2022-10-21",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:112,TAG:"3820P014B",TAG_DESCR:"BENZENE COLUMN WASTE WATER PUMPS",TAG_DESCR_SHORT:"3820P014B / BENZENE COLUMN WASTE WATER PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0630",MR_DESCR:"BENZENE COLUMN WASTE WATER PUMPS 计量泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",MR_PLAN:"2022-03-28",MR_FORECAST:"2022-03-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-07-26",LOA_FORECAST:"2022-09-20",LOA_ACTUAL:"2022-09-15",PO_PLAN:"2022-09-06",PO_FORECAST:"2022-11-01",PO_ACTUAL:"2022-10-21",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:114,TAG:"3820T020-I",TAG_DESCR:"TOLUENE COLUMN",TAG_DESCR_SHORT:"3820T020-I / TOLUENE COLUMN",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0102",MR_DESCR:"TOLUENE COLUMN甲苯塔内件",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",MR_PLAN:"2021-12-03",MR_FORECAST:"2021-12-03",MR_ACTUAL:"2021-11-26",LOA_PLAN:"2022-04-06",LOA_FORECAST:"2022-06-27",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-05-18",PO_FORECAST:"2022-09-25",PO_ACTUAL:"2022-09-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:115,TAG:"3842P001A",TAG_DESCR:"Domestic Waste Water Pumps",TAG_DESCR_SHORT:"3842P001A / Domestic Waste Water Pumps",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0670",MR_DESCR:"Domestic Waste Water Pumps",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",MR_PLAN:"2022-03-28",MR_FORECAST:"2022-03-28",MR_ACTUAL:"2022-02-14",LOA_PLAN:"2022-07-26",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-06",PO_FORECAST:"2022-09-17",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:116,TAG:"3842P001B",TAG_DESCR:"Domestic Waste Water Pumps",TAG_DESCR_SHORT:"3842P001B / Domestic Waste Water Pumps",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0670",MR_DESCR:"Domestic Waste Water Pumps",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",MR_PLAN:"2022-03-28",MR_FORECAST:"2022-03-28",MR_ACTUAL:"2022-02-14",LOA_PLAN:"2022-07-26",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-06",PO_FORECAST:"2022-09-17",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:113,TAG:"3820T010-I",TAG_DESCR:"BENZENE COLUMN",TAG_DESCR_SHORT:"3820T010-I / BENZENE COLUMN",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0102",MR_DESCR:"BENZENE COLUMN苯塔内件",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",MR_PLAN:"2021-12-03",MR_FORECAST:"2021-12-03",MR_ACTUAL:"2021-11-26",LOA_PLAN:"2022-04-06",LOA_FORECAST:"2022-06-27",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-05-18",PO_FORECAST:"2022-09-25",PO_ACTUAL:"2022-09-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1,SN:1,TAG:"3810TK010",TAG_DESCR:"FRESH SOLVENT TANK",TAG_DESCR_SHORT:"3810TK010 / FRESH SOLVENT TANK",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0502",MR_DESCR:"FRESH SOLVENT TANK湿溶剂罐 储罐罐板",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-09-30",MR_FORECAST:"2022-09-30",LOA_PLAN:"2023-01-21",LOA_FORECAST:"2023-01-21",PO_PLAN:"2023-03-04",PO_FORECAST:"2023-03-04",CRITICAL:"NPO",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:2,SN:2,TAG:"3810TK020",TAG_DESCR:"SOLVENT TANK",TAG_DESCR_SHORT:"3810TK020 / SOLVENT TANK",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0502",MR_DESCR:"SOLVENT TANK溶剂罐 储罐罐板",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-09-30",MR_FORECAST:"2022-09-30",LOA_PLAN:"2023-01-21",LOA_FORECAST:"2023-01-21",PO_PLAN:"2023-03-04",PO_FORECAST:"2023-03-04",CRITICAL:"NPO",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:3,SN:3,TAG:"3810FIL020A",TAG_DESCR:"FRESH SOLVENT TANK VENT FILTER",TAG_DESCR_SHORT:"3810FIL020A / FRESH SOLVENT TANK VENT FILTER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0501",MR_DESCR:"FRESH SOLVENT  TANK VENT FILTER新鲜溶剂罐排放过滤器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-22",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-08-04",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-09-15",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:4,SN:4,TAG:"3810FIL020B",TAG_DESCR:"FRESH SOLVENT TANK VENT FILTER",TAG_DESCR_SHORT:"3810FIL020B / FRESH SOLVENT TANK VENT FILTER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0501",MR_DESCR:"FRESH SOLVENT  TANK VENT FILTER新鲜溶剂罐排放过滤器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-22",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-08-04",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-09-15",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:5,SN:5,TAG:"3810FIL010A",TAG_DESCR:"PLANT INVENTORY TANK VENT FILTER",TAG_DESCR_SHORT:"3810FIL010A / PLANT INVENTORY TANK VENT FILTER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0501",MR_DESCR:"PLANT INVENTORY  TANK VENT FILTER湿溶剂罐排放过滤器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-04-08",MR_FORECAST:"2022-04-08",MR_ACTUAL:"2022-03-22",LOA_PLAN:"2022-08-12",LOA_FORECAST:"2022-08-12",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-09-23",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:6,SN:6,TAG:"3810FIL010B",TAG_DESCR:"PLANT INVENTORY TANK VENT FILTER",TAG_DESCR_SHORT:"3810FIL010B / PLANT INVENTORY TANK VENT FILTER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0501",MR_DESCR:"PLANT INVENTORY  TANK VENT FILTER湿溶剂罐排放过滤器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-04-08",MR_FORECAST:"2022-04-08",MR_ACTUAL:"2022-03-22",LOA_PLAN:"2022-08-12",LOA_FORECAST:"2022-08-12",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-09-23",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:7,SN:7,TAG:"3810P002",TAG_DESCR:"Solvent Transfer Pumps",TAG_DESCR_SHORT:"3810P002 / Solvent Transfer Pumps",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0621",MR_DESCR:"Solvent Transfer Pumps溶剂输送泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-04",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:8,SN:8,TAG:"3810E002",TAG_DESCR:"SOLVENT COOLER",TAG_DESCR_SHORT:"3810E002 / SOLVENT COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0403",MR_DESCR:"SOLVENT COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:9,SN:9,TAG:"3899P001",TAG_DESCR:"PIT PUMP",TAG_DESCR_SHORT:"3899P001 / PIT PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0670",MR_DESCR:"PIT PUMP",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-03-28",MR_FORECAST:"2022-03-28",MR_ACTUAL:"2022-02-14",LOA_PLAN:"2022-07-26",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-06",PO_FORECAST:"2022-09-17",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:10,SN:10,TAG:"3810D001",TAG_DESCR:"SULFOLANE SUMP",TAG_DESCR_SHORT:"3810D001 / SULFOLANE SUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0301",MR_DESCR:"SULFOLANE SUMP",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-16",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:11,SN:11,TAG:"3810D025",TAG_DESCR:"EJECTOR CONDENSATE DRUM",TAG_DESCR_SHORT:"3810D025 / EJECTOR CONDENSATE DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0301",MR_DESCR:"EJECTOR CONDENSATE DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-16",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:12,SN:12,TAG:"3810T020",TAG_DESCR:"RECOVERY COLUMN",TAG_DESCR_SHORT:"3810T020 / RECOVERY COLUMN",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0101",MR_DESCR:"RECOVERY COLUMN回收塔",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2021-12-16",MR_FORECAST:"2021-12-16",MR_ACTUAL:"2021-12-15",LOA_PLAN:"2022-04-07",LOA_FORECAST:"2022-05-19",LOA_ACTUAL:"2022-05-16",PO_PLAN:"2022-05-19",PO_FORECAST:"2022-06-30",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:13,SN:13,TAG:"3810E012",TAG_DESCR:"EXTRACTIVE DISTILLATION COLUMN REBOILER",TAG_DESCR_SHORT:"3810E012 / EXTRACTIVE DISTILLATION COLUMN REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0402",MR_DESCR:"（双相钢）EXTRACTIVE DISTILLATION COLUMN REBOILER抽提蒸馏塔重沸器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-04-13",MR_FORECAST:"2022-04-13",MR_ACTUAL:"2022-02-14",LOA_PLAN:"2022-08-11",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-22",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-08-17",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:14,SN:14,TAG:"3810E000",TAG_DESCR:"LEAN SOLVENTFEED EXCHANGER",TAG_DESCR_SHORT:"3810E000 / LEAN SOLVENTFEED EXCHANGER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0403",MR_DESCR:"LEAN SOLVENT-FEED EXCHANGER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:15,SN:15,TAG:"3810E001",TAG_DESCR:"LEAN SOLVENT COOLER",TAG_DESCR_SHORT:"3810E001 / LEAN SOLVENT COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0403",MR_DESCR:"LEAN SOLVENT COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:16,SN:16,TAG:"3810P000B",TAG_DESCR:"CHARGE PUMPS",TAG_DESCR_SHORT:"3810P000B / CHARGE PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0621",MR_DESCR:"CHARGE PUMPS进料泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:17,SN:17,TAG:"3810P000A",TAG_DESCR:"CHARGE PUMPS",TAG_DESCR_SHORT:"3810P000A / CHARGE PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0621",MR_DESCR:"CHARGE PUMPS进料泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:18,SN:18,TAG:"3810PK002",TAG_DESCR:"MEA INJECTION PACKAGE",TAG_DESCR_SHORT:"3810PK002 / MEA INJECTION PACKAGE",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0610",MR_DESCR:"MEA INJECTION PACKAGE单乙醇胺注入包",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-09-01",MR_FORECAST:"2022-09-01",MR_ACTUAL:"2022-08-11",LOA_PLAN:"2022-11-15",LOA_FORECAST:"2022-12-28",LOA_ACTUAL:"2022-12-27",PO_PLAN:"2022-12-27",PO_FORECAST:"2023-02-08",PO_ACTUAL:"2023-01-30",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:19,SN:19,TAG:"3810D000",TAG_DESCR:"FEED SURGE DRUM",TAG_DESCR_SHORT:"3810D000 / FEED SURGE DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0301",MR_DESCR:"FEED SURGE DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-16",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:20,SN:20,TAG:"3810E035",TAG_DESCR:"STRIPPING STEAM GENERATOR",TAG_DESCR_SHORT:"3810E035 / STRIPPING STEAM GENERATOR",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0403",MR_DESCR:"STRIPPING STEAM GENERATOR",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:21,SN:21,TAG:"3810D020",TAG_DESCR:"RECOVERY COLUMN RECEIVER",TAG_DESCR_SHORT:"3810D020 / RECOVERY COLUMN RECEIVER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0301",MR_DESCR:"RECOVERY COLUMN RECEIVER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-16",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:22,SN:22,TAG:"3810E032",TAG_DESCR:"SOLVENT REGENERATOR REBOILER",TAG_DESCR_SHORT:"3810E032 / SOLVENT REGENERATOR REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0301",MR_DESCR:"SOLVENT REGENERATOR REBOILER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-16",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:23,SN:23,TAG:"3810D030",TAG_DESCR:"SOLVENT REGENERATOR",TAG_DESCR_SHORT:"3810D030 / SOLVENT REGENERATOR",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0301",MR_DESCR:"SOLVENT REGENERATOR 容器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-16",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:24,SN:24,TAG:"3810E023",TAG_DESCR:"RECOVERY COLUMN TRIM CONDENSER",TAG_DESCR_SHORT:"3810E023 / RECOVERY COLUMN TRIM CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0403",MR_DESCR:"RECOVERY COLUMN TRIM CONDENSER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:25,SN:25,TAG:"3810J025A",TAG_DESCR:"RECOVERY COLUMN EJECTORS",TAG_DESCR_SHORT:"3810J025A / RECOVERY COLUMN EJECTORS",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-PE-R-E-0952",MR_DESCR:"RECOVERY COLUMN EJECTORS回收塔顶抽空器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-02-26",MR_FORECAST:"2022-02-26",MR_ACTUAL:"2022-01-04",LOA_PLAN:"2022-06-26",LOA_FORECAST:"2022-06-08",LOA_ACTUAL:"2022-06-10",PO_PLAN:"2022-08-07",PO_FORECAST:"2022-07-20",PO_ACTUAL:"2022-07-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:26,SN:26,TAG:"3810J025B",TAG_DESCR:"RECOVERY COLUMN EJECTORS",TAG_DESCR_SHORT:"3810J025B / RECOVERY COLUMN EJECTORS",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-PE-R-E-0952",MR_DESCR:"RECOVERY COLUMN EJECTORS回收塔顶抽空器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-02-26",MR_FORECAST:"2022-02-26",MR_ACTUAL:"2022-01-04",LOA_PLAN:"2022-06-26",LOA_FORECAST:"2022-06-08",LOA_ACTUAL:"2022-06-10",PO_PLAN:"2022-08-07",PO_FORECAST:"2022-07-20",PO_ACTUAL:"2022-07-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:27,SN:27,TAG:"3810E025",TAG_DESCR:"EJECTOR COOLER",TAG_DESCR_SHORT:"3810E025 / EJECTOR COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX - Heat Exchangers",MR_NO:"TBD",MR_DESCR:"EJECTOR COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:28,SN:28,TAG:"3810E022A",TAG_DESCR:"RECOVERY COLUMN REBOILERS",TAG_DESCR_SHORT:"3810E022A / RECOVERY COLUMN REBOILERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0101",MR_DESCR:"RECOVERY COLUMN REBOILERS回收塔重沸器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2021-12-16",MR_FORECAST:"2021-12-16",MR_ACTUAL:"2021-12-15",LOA_PLAN:"2022-04-07",LOA_FORECAST:"2022-05-19",LOA_ACTUAL:"2022-05-16",PO_PLAN:"2022-05-19",PO_FORECAST:"2022-06-30",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:29,SN:29,TAG:"3810E022B",TAG_DESCR:"RECOVERY COLUMN REBOILERS",TAG_DESCR_SHORT:"3810E022B / RECOVERY COLUMN REBOILERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0101",MR_DESCR:"RECOVERY COLUMN REBOILERS回收塔重沸器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2021-12-16",MR_FORECAST:"2021-12-16",MR_ACTUAL:"2021-12-15",LOA_PLAN:"2022-04-07",LOA_FORECAST:"2022-05-19",LOA_ACTUAL:"2022-05-16",PO_PLAN:"2022-05-19",PO_FORECAST:"2022-06-30",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:30,SN:30,TAG:"3810E011",TAG_DESCR:"EXTRACTIVE DISTILLATION COLUMN REBOILER PREHEATER",TAG_DESCR_SHORT:"3810E011 / EXTRACTIVE DISTILLATION COLUMN REBOILE...",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0402",MR_DESCR:"（双相钢）EXTRACTIVE DISTILLATION COLUMN REBOILER PREHEATER抽提蒸馏塔重沸预加热器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-04-13",MR_FORECAST:"2022-04-13",MR_ACTUAL:"2022-02-14",LOA_PLAN:"2022-08-11",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-22",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-08-17",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:31,SN:31,TAG:"3810D010",TAG_DESCR:"EXTRACTIVE DISTILLATION COLUMN RECEIVER",TAG_DESCR_SHORT:"3810D010 / EXTRACTIVE DISTILLATION COLUMN RECEIVER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0301",MR_DESCR:"EXTRACTIVE DISTILLATION COLUMN RECEIVER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-16",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:32,SN:32,TAG:"3810E013",TAG_DESCR:"RAFFINATE COOLER",TAG_DESCR_SHORT:"3810E013 / RAFFINATE COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0403",MR_DESCR:"RAFFINATE COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:33,SN:33,TAG:"3810PK015",TAG_DESCR:"ANTIFOAM INJECTION PACKAGE",TAG_DESCR_SHORT:"3810PK015 / ANTIFOAM INJECTION PACKAGE",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0610",MR_DESCR:"ANTI-FOAM INJECTION PACKAGE消泡剂注入包",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-09-01",MR_FORECAST:"2022-09-01",MR_ACTUAL:"2022-08-11",LOA_PLAN:"2022-11-15",LOA_FORECAST:"2022-12-28",LOA_ACTUAL:"2022-12-27",PO_PLAN:"2022-12-27",PO_FORECAST:"2023-02-08",PO_ACTUAL:"2023-01-30",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:34,SN:34,TAG:"3810T010",TAG_DESCR:"EXTRACTIVE DISTILLATION COLUMN",TAG_DESCR_SHORT:"3810T010 / EXTRACTIVE DISTILLATION COLUMN",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0101",MR_DESCR:"EXTRACTIVE DISTILLATION COLUMN抽提蒸馏塔",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2021-12-16",MR_FORECAST:"2021-12-16",MR_ACTUAL:"2021-12-15",LOA_PLAN:"2022-04-07",LOA_FORECAST:"2022-05-19",LOA_ACTUAL:"2022-05-16",PO_PLAN:"2022-05-19",PO_FORECAST:"2022-06-30",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:67,SN:67,TAG:"3810P024B",TAG_DESCR:"RECOVERY COLUMN OVERHEAD WATER PUMPS",TAG_DESCR_SHORT:"3810P024B / RECOVERY COLUMN OVERHEAD WATER PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0621",MR_DESCR:"RECOVERY COLUMN OVERHEAD WATER PUMPS回收塔顶水泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-04",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:68,SN:68,TAG:"3810P024A",TAG_DESCR:"RECOVERY COLUMN OVERHEAD WATER PUMPS",TAG_DESCR_SHORT:"3810P024A / RECOVERY COLUMN OVERHEAD WATER PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0621",MR_DESCR:"RECOVERY COLUMN OVERHEAD WATER PUMPS回收塔顶水泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-04",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:69,SN:69,TAG:"3810P025B",TAG_DESCR:"EJECTOR CONDENSATE PUMPS",TAG_DESCR_SHORT:"3810P025B / EJECTOR CONDENSATE PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0630",MR_DESCR:"EJECTOR CONDENSATE PUMPS计量泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-03-28",MR_FORECAST:"2022-03-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-07-26",LOA_FORECAST:"2022-09-20",LOA_ACTUAL:"2022-09-15",PO_PLAN:"2022-09-06",PO_FORECAST:"2022-11-01",PO_ACTUAL:"2022-10-21",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:70,SN:70,TAG:"3810P025A",TAG_DESCR:"EJECTOR CONDENSATE PUMPS",TAG_DESCR_SHORT:"3810P025A / EJECTOR CONDENSATE PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0630",MR_DESCR:"EJECTOR CONDENSATE PUMPS计量泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-03-28",MR_FORECAST:"2022-03-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-07-26",LOA_FORECAST:"2022-09-20",LOA_ACTUAL:"2022-09-15",PO_PLAN:"2022-09-06",PO_FORECAST:"2022-11-01",PO_ACTUAL:"2022-10-21",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:71,SN:71,TAG:"3810P023B",TAG_DESCR:"RECOVERY COLUMN OVERHEAD PUMPS",TAG_DESCR_SHORT:"3810P023B / RECOVERY COLUMN OVERHEAD PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0620",MR_DESCR:"RECOVERY COLUMN OVERHEAD PUMPS回收塔顶泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-02-18",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2021-12-13",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:72,SN:72,TAG:"3810P023A",TAG_DESCR:"RECOVERY COLUMN OVERHEAD PUMPS",TAG_DESCR_SHORT:"3810P023A / RECOVERY COLUMN OVERHEAD PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0620",MR_DESCR:"RECOVERY COLUMN OVERHEAD PUMPS回收塔顶泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-02-18",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2021-12-13",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:73,SN:73,TAG:"3810P020B",TAG_DESCR:"LEAN SOLVENT PUMPS",TAG_DESCR_SHORT:"3810P020B / LEAN SOLVENT PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0620",MR_DESCR:"LEAN SOLVENT PUMPS贫溶剂泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-02-18",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2021-12-13",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:74,SN:74,TAG:"3810P020A",TAG_DESCR:"LEAN SOLVENT PUMPS",TAG_DESCR_SHORT:"3810P020A / LEAN SOLVENT PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0620",MR_DESCR:"LEAN SOLVENT PUMPS贫溶剂泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-02-18",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2021-12-13",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:75,SN:75,TAG:"3810P014B",TAG_DESCR:"EXTRACTIVE DISTILLATION COLUMN OVERHEAD WATER PUMPS",TAG_DESCR_SHORT:"3810P014B / EXTRACTIVE DISTILLATION COLUMN OVERHE...",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0621",MR_DESCR:"EXTRACTIVE DISTILLATION COLUMN OVERHEAD WATER PUMPS抽提蒸馏塔顶水泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-04",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:76,SN:76,TAG:"3810P014A",TAG_DESCR:"EXTRACTIVE DISTILLATION COLUMN OVERHEAD WATER PUMPS",TAG_DESCR_SHORT:"3810P014A / EXTRACTIVE DISTILLATION COLUMN OVERHE...",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0621",MR_DESCR:"EXTRACTIVE DISTILLATION COLUMN OVERHEAD WATER PUMPS抽提蒸馏塔顶水泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-04",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:77,SN:77,TAG:"3810P013B",TAG_DESCR:"EXTRACTIVE DISTILLATION COLUMN OVERHEAD PUMPS",TAG_DESCR_SHORT:"3810P013B / EXTRACTIVE DISTILLATION COLUMN OVERHE...",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0621",MR_DESCR:"EXTRACTIVE DISTILLATION COLUMN OVERHEAD PUMPS抽提蒸馏塔顶泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-04",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:78,SN:78,TAG:"3810P013A",TAG_DESCR:"EXTRACTIVE DISTILLATION COLUMN OVERHEAD PUMPS",TAG_DESCR_SHORT:"3810P013A / EXTRACTIVE DISTILLATION COLUMN OVERHE...",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0621",MR_DESCR:"EXTRACTIVE DISTILLATION COLUMN OVERHEAD PUMPS抽提蒸馏塔顶泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-04",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:79,SN:79,TAG:"3810P011B",TAG_DESCR:"EXTRACTIVE DISTILLATION COLUMN REBOILER PUMPS",TAG_DESCR_SHORT:"3810P011B / EXTRACTIVE DISTILLATION COLUMN REBOIL...",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0621",MR_DESCR:"EXTRACTIVE DISTILLATION COLUMN REBOILER PUMPS抽提蒸馏塔重沸器泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-04",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:80,SN:80,TAG:"3810P011A",TAG_DESCR:"EXTRACTIVE DISTILLATION COLUMN REBOILER PUMPS",TAG_DESCR_SHORT:"3810P011A / EXTRACTIVE DISTILLATION COLUMN REBOIL...",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0621",MR_DESCR:"EXTRACTIVE DISTILLATION COLUMN REBOILER PUMPS抽提蒸馏塔重沸器泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-04",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:81,SN:81,TAG:"3810P010B",TAG_DESCR:"EXTRACTIVE DISTILLATION COLUMN BOTTOMS PUMPS",TAG_DESCR_SHORT:"3810P010B / EXTRACTIVE DISTILLATION COLUMN BOTTOM...",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0621",MR_DESCR:"EXTRACTIVE DISTILLATION COLUMN BOTTOMS PUMPS抽提蒸馏塔底泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-04",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:82,SN:82,TAG:"3810P010A",TAG_DESCR:"EXTRACTIVE DISTILLATION COLUMN BOTTOMS PUMPS",TAG_DESCR_SHORT:"3810P010A / EXTRACTIVE DISTILLATION COLUMN BOTTOM...",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0621",MR_DESCR:"EXTRACTIVE DISTILLATION COLUMN BOTTOMS PUMPS抽提蒸馏塔底泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-04",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:83,SN:83,TAG:"3810FIL000",TAG_DESCR:"LEAN SOLVENT FILTER",TAG_DESCR_SHORT:"3810FIL000 / LEAN SOLVENT FILTER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0501",MR_DESCR:"LEAN SOLVENT FILTER贫溶剂过滤器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",MR_PLAN:"2022-04-08",MR_FORECAST:"2022-04-08",MR_ACTUAL:"2022-03-22",LOA_PLAN:"2022-08-12",LOA_FORECAST:"2022-08-12",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-09-23",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:35,SN:35,TAG:"3820D000A",TAG_DESCR:"CLAY TREATERS",TAG_DESCR_SHORT:"3820D000A / CLAY TREATERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0301",MR_DESCR:"CLAY TREATERS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-16",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:36,SN:36,TAG:"3820D000B",TAG_DESCR:"CLAY TREATERS",TAG_DESCR_SHORT:"3820D000B / CLAY TREATERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0301",MR_DESCR:"CLAY TREATERS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-16",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:37,SN:37,TAG:"3820T010",TAG_DESCR:"BENZENE COLUMN",TAG_DESCR_SHORT:"3820T010 / BENZENE COLUMN",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0101",MR_DESCR:"BENZENE COLUMN苯塔",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2021-12-16",MR_FORECAST:"2021-12-16",MR_ACTUAL:"2021-12-15",LOA_PLAN:"2022-04-07",LOA_FORECAST:"2022-05-19",LOA_ACTUAL:"2022-05-16",PO_PLAN:"2022-05-19",PO_FORECAST:"2022-06-30",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:38,SN:38,TAG:"3820E021",TAG_DESCR:"TOLUENE COLUMN REBOILER",TAG_DESCR_SHORT:"3820E021 / TOLUENE COLUMN REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0403",MR_DESCR:"TOLUENE COLUMN REBOILER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:39,SN:39,TAG:"3820E023",TAG_DESCR:"TOLUENE PRODUCT COOLER",TAG_DESCR_SHORT:"3820E023 / TOLUENE PRODUCT COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0403",MR_DESCR:"TOLUENE PRODUCT COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:40,SN:40,TAG:"3820E022",TAG_DESCR:"TOLUENE COLUMN BOTTOMS COOLER",TAG_DESCR_SHORT:"3820E022 / TOLUENE COLUMN BOTTOMS COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0403",MR_DESCR:"TOLUENE COLUMN BOTTOMS COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:41,SN:41,TAG:"3820E012",TAG_DESCR:"BENZENE COLUMN AUXILIARY REBOILER",TAG_DESCR_SHORT:"3820E012 / BENZENE COLUMN AUXILIARY REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0403",MR_DESCR:"BENZENE COLUMN AUXILIARY REBOILER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:42,SN:42,TAG:"3820E015",TAG_DESCR:"BENZENE PRODUCT COOLER",TAG_DESCR_SHORT:"3820E015 / BENZENE PRODUCT COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0403",MR_DESCR:"BENZENE PRODUCT COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:43,SN:43,TAG:"3820D020",TAG_DESCR:"TOLUENE COLUMN RECEIVER",TAG_DESCR_SHORT:"3820D020 / TOLUENE COLUMN RECEIVER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0301",MR_DESCR:"TOLUENE COLUMN RECEIVER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-16",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:44,SN:44,TAG:"3820D010",TAG_DESCR:"BENZENE COLUMN RECEIVER",TAG_DESCR_SHORT:"3820D010 / BENZENE COLUMN RECEIVER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0301",MR_DESCR:"BENZENE COLUMN RECEIVER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-16",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:45,SN:45,TAG:"3820E010A",TAG_DESCR:"BENZENE COLUMN CONDENSER",TAG_DESCR_SHORT:"3820E010A / BENZENE COLUMN CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0401",MR_DESCR:"BENZENE COLUMN CONDENSER苯塔顶空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-12-02",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:46,SN:46,TAG:"3820E010B",TAG_DESCR:"BENZENE COLUMN CONDENSER",TAG_DESCR_SHORT:"3820E010B / BENZENE COLUMN CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0401",MR_DESCR:"BENZENE COLUMN CONDENSER苯塔顶空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-12-02",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:47,SN:47,TAG:"3820E010C",TAG_DESCR:"BENZENE COLUMN CONDENSER",TAG_DESCR_SHORT:"3820E010C / BENZENE COLUMN CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0401",MR_DESCR:"BENZENE COLUMN CONDENSER苯塔顶空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-12-02",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:48,SN:48,TAG:"3820E011",TAG_DESCR:"BENZENE COLUMN REBOILER",TAG_DESCR_SHORT:"3820E011 / BENZENE COLUMN REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0403",MR_DESCR:"BENZENE COLUMN REBOILER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:49,SN:49,TAG:"3820E001",TAG_DESCR:"CLAY TREATER FEED HEATER",TAG_DESCR_SHORT:"3820E001 / CLAY TREATER FEED HEATER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0403",MR_DESCR:"CLAY TREATER FEED HEATER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:50,SN:50,TAG:"3820E000B",TAG_DESCR:"CLAY TREATER FEED EXCHANGER",TAG_DESCR_SHORT:"3820E000B / CLAY TREATER FEED EXCHANGER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0403",MR_DESCR:"CLAY TREATER FEED EXCHANGER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:51,SN:51,TAG:"3820E000A",TAG_DESCR:"CLAY TREATER FEED EXCHANGER",TAG_DESCR_SHORT:"3820E000A / CLAY TREATER FEED EXCHANGER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0403",MR_DESCR:"CLAY TREATER FEED EXCHANGER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:52,SN:52,TAG:"3820T020",TAG_DESCR:"TOLUENE COLUMN",TAG_DESCR_SHORT:"3820T020 / TOLUENE COLUMN",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0101",MR_DESCR:"TOLUENE COLUMN甲苯塔",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2021-12-16",MR_FORECAST:"2021-12-16",MR_ACTUAL:"2021-12-15",LOA_PLAN:"2022-04-07",LOA_FORECAST:"2022-05-19",LOA_ACTUAL:"2022-05-16",PO_PLAN:"2022-05-19",PO_FORECAST:"2022-06-30",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:53,SN:53,TAG:"3897D001",TAG_DESCR:"BTX FLARE KNOCK OUT DRUM",TAG_DESCR_SHORT:"3897D001 / BTX FLARE KNOCK OUT DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0301",MR_DESCR:"BTX FLARE KNOCK OUT DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-16",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:54,SN:54,TAG:"3897E001",TAG_DESCR:"WARM BLOWDOWN COOLER",TAG_DESCR_SHORT:"3897E001 / WARM BLOWDOWN COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0403",MR_DESCR:"WARM BLOWDOWN COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:55,SN:55,TAG:"3897P001",TAG_DESCR:"BTX SLOPS PUMP",TAG_DESCR_SHORT:"3897P001 / BTX SLOPS PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0621",MR_DESCR:"BTX SLOPS PUMP火炬凝液泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-04",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:56,SN:56,TAG:"3898PK010",TAG_DESCR:"VOC Package",TAG_DESCR_SHORT:"3898PK010 / VOC Package",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX-Package",MR_NO:"TBD",MR_DESCR:"VOC Package",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:57,SN:57,TAG:"3820P010B",TAG_DESCR:"BENZENE COLUMN BOTTOMS PUMPS",TAG_DESCR_SHORT:"3820P010B / BENZENE COLUMN BOTTOMS PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0621",MR_DESCR:"BENZENE COLUMN BOTTOMS PUMPS苯塔底泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-04",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:58,SN:58,TAG:"3820P010A",TAG_DESCR:"BENZENE COLUMN BOTTOMS PUMPS",TAG_DESCR_SHORT:"3820P010A / BENZENE COLUMN BOTTOMS PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0621",MR_DESCR:"BENZENE COLUMN BOTTOMS PUMPS苯塔底泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-04",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:59,SN:59,TAG:"3820P015B",TAG_DESCR:"BENZENE PRODUCT PUMPS",TAG_DESCR_SHORT:"3820P015B / BENZENE PRODUCT PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0650",MR_DESCR:"BENZENE PRODUCT PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2022-03-28",MR_FORECAST:"2022-03-28",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-07-26",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-06",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:60,SN:60,TAG:"3820P015A",TAG_DESCR:"BENZENE PRODUCT PUMPS",TAG_DESCR_SHORT:"3820P015A / BENZENE PRODUCT PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0650",MR_DESCR:"BENZENE PRODUCT PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2022-03-28",MR_FORECAST:"2022-03-28",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-07-26",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-06",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:61,SN:61,TAG:"3820P023B",TAG_DESCR:"TOLUENE COLUMN OVERHEAD PUMPS",TAG_DESCR_SHORT:"3820P023B / TOLUENE COLUMN OVERHEAD PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0621",MR_DESCR:"TOLUENE COLUMN OVERHEAD PUMPS甲苯塔顶泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-04",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:62,SN:62,TAG:"3820P023A",TAG_DESCR:"TOLUENE COLUMN OVERHEAD PUMPS",TAG_DESCR_SHORT:"3820P023A / TOLUENE COLUMN OVERHEAD PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0621",MR_DESCR:"TOLUENE COLUMN OVERHEAD PUMPS甲苯塔顶泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-04",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:63,SN:63,TAG:"3820P013B",TAG_DESCR:"BENZENE COLUMN REFLUX PUMPS",TAG_DESCR_SHORT:"3820P013B / BENZENE COLUMN REFLUX PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0650",MR_DESCR:"BENZENE COLUMN REFLUX PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2022-03-28",MR_FORECAST:"2022-03-28",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-07-26",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-06",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:64,SN:64,TAG:"3820P013A",TAG_DESCR:"BENZENE COLUMN REFLUX PUMPS",TAG_DESCR_SHORT:"3820P013A / BENZENE COLUMN REFLUX PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0650",MR_DESCR:"BENZENE COLUMN REFLUX PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2022-03-28",MR_FORECAST:"2022-03-28",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-07-26",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-06",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:65,SN:65,TAG:"3820P020B",TAG_DESCR:"TOLUENE COLUMN BOTTOMS PUMPS",TAG_DESCR_SHORT:"3820P020B / TOLUENE COLUMN BOTTOMS PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0621",MR_DESCR:"TOLUENE COLUMN BOTTOMS PUMPS甲苯塔底泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-04",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:66,SN:66,TAG:"3820P020A",TAG_DESCR:"TOLUENE COLUMN BOTTOMS PUMPS",TAG_DESCR_SHORT:"3820P020A / TOLUENE COLUMN BOTTOMS PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-RE-R-E-0621",MR_DESCR:"TOLUENE COLUMN BOTTOMS PUMPS甲苯塔底泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-04",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:84,SN:84,TAG:"3810E020G",TAG_DESCR:"RECOVERY COLUMN CONDENSER",TAG_DESCR_SHORT:"3810E020G / RECOVERY COLUMN CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0401",MR_DESCR:"RECOVERY COLUMN CONDENSER回收塔顶空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-12-02",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:85,SN:85,TAG:"3810E020F",TAG_DESCR:"RECOVERY COLUMN CONDENSER",TAG_DESCR_SHORT:"3810E020F / RECOVERY COLUMN CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0401",MR_DESCR:"RECOVERY COLUMN CONDENSER回收塔顶空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-12-02",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:86,SN:86,TAG:"3810E020E",TAG_DESCR:"RECOVERY COLUMN CONDENSER",TAG_DESCR_SHORT:"3810E020E / RECOVERY COLUMN CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0401",MR_DESCR:"RECOVERY COLUMN CONDENSER回收塔顶空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-12-02",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:87,SN:87,TAG:"3810E020D",TAG_DESCR:"RECOVERY COLUMN CONDENSER",TAG_DESCR_SHORT:"3810E020D / RECOVERY COLUMN CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0401",MR_DESCR:"RECOVERY COLUMN CONDENSER回收塔顶空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-12-02",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:88,SN:88,TAG:"3810E020C",TAG_DESCR:"RECOVERY COLUMN CONDENSER",TAG_DESCR_SHORT:"3810E020C / RECOVERY COLUMN CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0401",MR_DESCR:"RECOVERY COLUMN CONDENSER回收塔顶空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-12-02",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:89,SN:89,TAG:"3810E020B",TAG_DESCR:"RECOVERY COLUMN CONDENSER",TAG_DESCR_SHORT:"3810E020B / RECOVERY COLUMN CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0401",MR_DESCR:"RECOVERY COLUMN CONDENSER回收塔顶空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-12-02",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:90,SN:90,TAG:"3810E020A",TAG_DESCR:"RECOVERY COLUMN CONDENSER",TAG_DESCR_SHORT:"3810E020A / RECOVERY COLUMN CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0401",MR_DESCR:"RECOVERY COLUMN CONDENSER回收塔顶空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-12-02",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:91,SN:91,TAG:"3810E010B",TAG_DESCR:"EXTRACTIVE DISTILLATION COLUMN CONDENSER",TAG_DESCR_SHORT:"3810E010B / EXTRACTIVE DISTILLATION COLUMN CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0401",MR_DESCR:"EXTRACTIVE DISTILLATION COLUMN CONDENSER抽提蒸馏塔顶空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-12-02",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:92,SN:92,TAG:"3810E010A",TAG_DESCR:"EXTRACTIVE DISTILLATION COLUMN CONDENSER",TAG_DESCR_SHORT:"3810E010A / EXTRACTIVE DISTILLATION COLUMN CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"BTX",MR_NO:"75231-P1-1113-EQ-R-E-0401",MR_DESCR:"EXTRACTIVE DISTILLATION COLUMN CONDENSER抽提蒸馏塔顶空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-12-02",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:340,TAG:"3222T001-I",TAG_DESCR:"DEBUTANIZER COLUMN INTERNAL",TAG_DESCR_SHORT:"3222T001-I / DEBUTANIZER COLUMN INTERNAL",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0104",MR_DESCR:"DEBUTANIZER COLUMN INTERNAL脱丁烷塔内件",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-12",MR_ACTUAL:"2021-12-28",LOA_PLAN:"2022-04-05",LOA_FORECAST:"2022-05-16",LOA_ACTUAL:"2022-05-16",PO_PLAN:"2022-05-17",PO_FORECAST:"2022-06-27",PO_ACTUAL:"2022-08-11",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:341,TAG:"3223T001-I",TAG_DESCR:"CD REACTION COLUMN 1",TAG_DESCR_SHORT:"3223T001-I / CD REACTION COLUMN 1",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0104",MR_DESCR:"CD REACTION COLUMN催化蒸馏反应塔（1）内件",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-12",MR_ACTUAL:"2021-12-28",LOA_PLAN:"2022-04-05",LOA_FORECAST:"2022-05-16",LOA_ACTUAL:"2022-05-16",PO_PLAN:"2022-05-17",PO_FORECAST:"2022-06-27",PO_ACTUAL:"2022-08-11",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:342,TAG:"3223T002-I",TAG_DESCR:"CD REACTION COLUMN 2",TAG_DESCR_SHORT:"3223T002-I / CD REACTION COLUMN 2",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0104",MR_DESCR:"CD REACTION COLUMN催化蒸馏反应塔（2）内件",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-12",MR_ACTUAL:"2021-12-28",LOA_PLAN:"2022-04-05",LOA_FORECAST:"2022-05-16",LOA_ACTUAL:"2022-05-16",PO_PLAN:"2022-05-17",PO_FORECAST:"2022-06-27",PO_ACTUAL:"2022-08-11",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:343,TAG:"3230T001-I",TAG_DESCR:"METHANOL EXTRACTION COLUMN INTERNAL",TAG_DESCR_SHORT:"3230T001-I / METHANOL EXTRACTION COLUMN INTERNAL",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0104",MR_DESCR:"METHANOL EXTRACTION COLUMN INTERNAL甲醇萃取塔内件",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-12",MR_ACTUAL:"2021-12-28",LOA_PLAN:"2022-04-05",LOA_FORECAST:"2022-05-16",LOA_ACTUAL:"2022-05-16",PO_PLAN:"2022-05-17",PO_FORECAST:"2022-06-27",PO_ACTUAL:"2022-08-11",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:344,TAG:"3230T002-I",TAG_DESCR:"METHANOL RECOVERY COLUMN INTERNAL",TAG_DESCR_SHORT:"3230T002-I / METHANOL RECOVERY COLUMN INTERNAL",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0104",MR_DESCR:"METHANOL RECOVERY COLUMN INTERNAL甲醇回收塔内件",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-12",MR_ACTUAL:"2021-12-28",LOA_PLAN:"2022-04-05",LOA_FORECAST:"2022-05-16",LOA_ACTUAL:"2022-05-16",PO_PLAN:"2022-05-17",PO_FORECAST:"2022-06-27",PO_ACTUAL:"2022-08-11",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:345,TAG:"3240T001-I",TAG_DESCR:"MTBEDIB SPLITTER INTERNALMTBEDIB",TAG_DESCR_SHORT:"3240T001-I / MTBEDIB SPLITTER INTERNALMTBEDIB",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0104",MR_DESCR:"MTBE/DIB SPLITTER INTERNALMTBE/DIB分离塔内件",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-12",MR_ACTUAL:"2021-12-28",LOA_PLAN:"2022-04-05",LOA_FORECAST:"2022-05-16",LOA_ACTUAL:"2022-05-16",PO_PLAN:"2022-05-17",PO_FORECAST:"2022-06-27",PO_ACTUAL:"2022-08-11",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:346,TAG:"3250PK001",TAG_DESCR:"Antioxidant Injection Package",TAG_DESCR_SHORT:"3250PK001 / Antioxidant Injection Package",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-RE-R-E-0610",MR_DESCR:"Antioxidant Injection Package消泡剂注入包",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",MR_PLAN:"2022-09-01",MR_FORECAST:"2022-09-01",MR_ACTUAL:"2022-08-09",LOA_PLAN:"2022-11-15",LOA_FORECAST:"2022-12-28",LOA_ACTUAL:"2022-12-27",PO_PLAN:"2022-12-27",PO_FORECAST:"2022-12-27",PO_ACTUAL:"2023-01-30",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:347,TAG:"3250T001-I",TAG_DESCR:"ISOOCTENE COLUMN INTERNAL",TAG_DESCR_SHORT:"3250T001-I / ISOOCTENE COLUMN INTERNAL",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0104",MR_DESCR:"ISO-OCTENE COLUMN INTERNAL异辛烯塔内件",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-12",MR_ACTUAL:"2021-12-28",LOA_PLAN:"2022-04-05",LOA_FORECAST:"2022-05-16",LOA_ACTUAL:"2022-05-16",PO_PLAN:"2022-05-17",PO_FORECAST:"2022-06-27",PO_ACTUAL:"2022-08-11",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:348,TAG:"3297P001A",TAG_DESCR:"SLOPS PUMPS",TAG_DESCR_SHORT:"3297P001A / SLOPS PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB - Rotating",MR_NO:"TBD",MR_DESCR:"SLOPS PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:349,TAG:"3297P001B",TAG_DESCR:"SLOPS PUMPS",TAG_DESCR_SHORT:"3297P001B / SLOPS PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB - Rotating",MR_NO:"TBD",MR_DESCR:"SLOPS PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:350,TAG:"3297P002A",TAG_DESCR:"METHANOL DRAIN DRUM PUMP",TAG_DESCR_SHORT:"3297P002A / METHANOL DRAIN DRUM PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB - Rotating",MR_NO:"TBD",MR_DESCR:"METHANOL DRAIN DRUM PUMP",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:351,TAG:"3297P002B",TAG_DESCR:"METHANOL DRAIN DRUM PUMP",TAG_DESCR_SHORT:"3297P002B / METHANOL DRAIN DRUM PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB - Rotating",MR_NO:"TBD",MR_DESCR:"METHANOL DRAIN DRUM PUMP",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:269,SN:1,TAG:"3222D001",TAG_DESCR:"DIBMTBE UNIT FEED HEATER",TAG_DESCR_SHORT:"3222D001 / DIBMTBE UNIT FEED HEATER",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0301",MR_DESCR:"DIB/MTBE UNIT FEED HEATER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-08-13",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-09-24",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:270,SN:2,TAG:"3223D001",TAG_DESCR:"DEBUTANIZER COLUMN FEEDBOTTOMS EXCHANGER",TAG_DESCR_SHORT:"3223D001 / DEBUTANIZER COLUMN FEEDBOTTOMS EXCHANGER",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0301",MR_DESCR:"DEBUTANIZER COLUMN FEED/BOTTOMS EXCHANGER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-08-13",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-09-24",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:271,SN:3,TAG:"3223E006",TAG_DESCR:"CD REACTION COLUMN TRIM CONDENSER",TAG_DESCR_SHORT:"3223E006 / CD REACTION COLUMN TRIM CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0403",MR_DESCR:"CD REACTION COLUMN TRIM CONDENSER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:272,SN:4,TAG:"3222E004",TAG_DESCR:"DEBUTANIZER COLUMN CONDENSER",TAG_DESCR_SHORT:"3222E004 / DEBUTANIZER COLUMN CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0403",MR_DESCR:"DEBUTANIZER COLUMN CONDENSER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:273,SN:5,TAG:"3223T001",TAG_DESCR:"CD REACTION COLUMN",TAG_DESCR_SHORT:"3223T001 / CD REACTION COLUMN",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0101",MR_DESCR:"CD REACTION COLUMN 催化蒸馏反应塔",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-01-08",MR_FORECAST:"2022-01-08",MR_ACTUAL:"2022-01-08",LOA_PLAN:"2022-04-30",LOA_FORECAST:"2022-05-19",LOA_ACTUAL:"2022-05-16",PO_PLAN:"2022-06-11",PO_FORECAST:"2022-06-30",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:274,SN:6,TAG:"3223E004",TAG_DESCR:"CD REACTION COLUMN REBOILER",TAG_DESCR_SHORT:"3223E004 / CD REACTION COLUMN REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0403",MR_DESCR:"CD REACTION COLUMN REBOILER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:275,SN:7,TAG:"3223T002",TAG_DESCR:"CD REACTION COLUMN",TAG_DESCR_SHORT:"3223T002 / CD REACTION COLUMN",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0101",MR_DESCR:"CD REACTION COLUMN 催化蒸馏反应塔",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-01-08",MR_FORECAST:"2022-01-08",MR_ACTUAL:"2022-01-08",LOA_PLAN:"2022-04-30",LOA_FORECAST:"2022-05-19",LOA_ACTUAL:"2022-05-16",PO_PLAN:"2022-06-11",PO_FORECAST:"2022-06-30",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:276,SN:8,TAG:"3223E007",TAG_DESCR:"MTBE PRODUCT COOLER",TAG_DESCR_SHORT:"3223E007 / MTBE PRODUCT COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0403",MR_DESCR:"MTBE PRODUCT COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:277,SN:9,TAG:"3222E002",TAG_DESCR:"DEBUTANIZER COLUMN REBOILER",TAG_DESCR_SHORT:"3222E002 / DEBUTANIZER COLUMN REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0403",MR_DESCR:"DEBUTANIZER COLUMN REBOILER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:278,SN:10,TAG:"3222T001",TAG_DESCR:"DEBUTANIZER COLUMN",TAG_DESCR_SHORT:"3222T001 / DEBUTANIZER COLUMN",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0102",MR_DESCR:"DEBUTANIZER COLUMN脱丁烷塔",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-01-08",MR_FORECAST:"2022-01-28",MR_ACTUAL:"2021-12-28",LOA_PLAN:"2022-04-30",LOA_FORECAST:"2022-05-19",LOA_ACTUAL:"2022-05-16",PO_PLAN:"2022-06-11",PO_FORECAST:"2022-06-30",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:279,SN:11,TAG:"3222E001",TAG_DESCR:"DEBUTANIZER COLUMN FEEDBOTTOMS EXCHANGER",TAG_DESCR_SHORT:"3222E001 / DEBUTANIZER COLUMN FEEDBOTTOMS EXCHANGER",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0403",MR_DESCR:"DEBUTANIZER COLUMN FEED/BOTTOMS EXCHANGER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:280,SN:12,TAG:"3223E001",TAG_DESCR:"DIBMTBE SECONDARY REACTOR FEED HEATERCOOLER",TAG_DESCR_SHORT:"3223E001 / DIBMTBE SECONDARY REACTOR FEED HEATERC...",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0403",MR_DESCR:"DIB/MTBE SECONDARY REACTOR FEED HEATER/COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:281,SN:13,TAG:"3223R001",TAG_DESCR:"SECONDARY REACTOR",TAG_DESCR_SHORT:"3223R001 / SECONDARY REACTOR",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0201",MR_DESCR:"SECONDARY REACTOR 二段反应器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-03-30",MR_FORECAST:"2022-03-30",MR_ACTUAL:"2022-03-18",LOA_PLAN:"2022-07-24",LOA_FORECAST:"2022-07-31",LOA_ACTUAL:"2022-08-03",PO_PLAN:"2022-09-04",PO_FORECAST:"2022-09-11",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:282,SN:14,TAG:"3221R001",TAG_DESCR:"PRIMARY REACTOR",TAG_DESCR_SHORT:"3221R001 / PRIMARY REACTOR",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0201",MR_DESCR:"PRIMARY REACTOR 一段反应器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-03-30",MR_FORECAST:"2022-03-30",MR_ACTUAL:"2022-03-18",LOA_PLAN:"2022-07-24",LOA_FORECAST:"2022-07-31",LOA_ACTUAL:"2022-08-03",PO_PLAN:"2022-09-04",PO_FORECAST:"2022-09-11",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:283,SN:15,TAG:"3221E002",TAG_DESCR:"板式换热器 DIB/MTBE REACTOR COOLERDIB/MTBE反应器冷却器",TAG_DESCR_SHORT:"3221E002 / DIBMTBE REACTOR COOLERDIBMTBE",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0402",MR_DESCR:"板式换热器 DIB/MTBE REACTOR COOLERDIB/MTBE反应器冷却器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",CRITICAL:"NPO",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:284,SN:16,TAG:"3221E001",TAG_DESCR:"CS DIBMTBE UNIT FEED HEATER",TAG_DESCR_SHORT:"3221E001 / CS DIBMTBE UNIT FEED HEATER",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0403",MR_DESCR:"管壳换热器一（CS） DIB/MTBE UNIT FEED HEATER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:285,SN:17,TAG:"3221P001A",TAG_DESCR:"TEMPERED WATER PUMPS",TAG_DESCR_SHORT:"3221P001A / TEMPERED WATER PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-RE-R-E-0621",MR_DESCR:"TEMPERED WATER PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:286,SN:18,TAG:"3221P001B",TAG_DESCR:"TEMPERED WATER PUMPS",TAG_DESCR_SHORT:"3221P001B / TEMPERED WATER PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-RE-R-E-0621",MR_DESCR:"TEMPERED WATER PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:287,SN:19,TAG:"3221FIL001A",TAG_DESCR:"DIBMTBE",TAG_DESCR_SHORT:"3221FIL001A / DIBMTBE",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0501",MR_DESCR:"DIB/MTBE装置进料过滤器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-04",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-15",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:288,SN:20,TAG:"3221FIL001B",TAG_DESCR:"DIBMTBE",TAG_DESCR_SHORT:"3221FIL001B / DIBMTBE",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0501",MR_DESCR:"DIB/MTBE装置进料过滤器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-04",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-15",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:289,SN:21,TAG:"3297P001",TAG_DESCR:"DIB SLOPS PUMP",TAG_DESCR_SHORT:"3297P001 / DIB SLOPS PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-RE-R-E-0621",MR_DESCR:"DIB SLOPS PUMP",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:290,SN:22,TAG:"3297FIL001",TAG_DESCR:"Intermittent Blowdown Drum",TAG_DESCR_SHORT:"3297FIL001 / Intermittent Blowdown Drum",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0501",MR_DESCR:"Intermittent Blowdown Drum",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-04",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-15",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:291,SN:23,TAG:"3297FIL002",TAG_DESCR:"Boiler Fuel Gas KO Drum",TAG_DESCR_SHORT:"3297FIL002 / Boiler Fuel Gas KO Drum",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0501",MR_DESCR:"Boiler Fuel Gas K.O. Drum",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-04",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-15",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:292,SN:24,TAG:"3297P002",TAG_DESCR:"METHANOL DRAIN DRUM PUMP",TAG_DESCR_SHORT:"3297P002 / METHANOL DRAIN DRUM PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-RE-R-E-0621",MR_DESCR:"METHANOL DRAIN DRUM PUMP",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:293,SN:25,TAG:"3297D002",TAG_DESCR:"CD REACTION COLUMN TRIM CONDENSER",TAG_DESCR_SHORT:"3297D002 / CD REACTION COLUMN TRIM CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0301",MR_DESCR:"CD REACTION COLUMN TRIM CONDENSER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-08-13",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-09-24",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:294,SN:26,TAG:"3297D001",TAG_DESCR:"CD REACTION COLUMN REBOILER",TAG_DESCR_SHORT:"3297D001 / CD REACTION COLUMN REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0301",MR_DESCR:"CD REACTION COLUMN REBOILER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-08-13",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-09-24",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:295,SN:27,TAG:"3297E001",TAG_DESCR:"WARM BLOWDOWN COOLER",TAG_DESCR_SHORT:"3297E001 / WARM BLOWDOWN COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0403",MR_DESCR:"WARM BLOWDOWN COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:296,SN:28,TAG:"3223P003A",TAG_DESCR:"REFLUX PUMPS",TAG_DESCR_SHORT:"3223P003A / REFLUX PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-RE-R-E-0621",MR_DESCR:"REFLUX PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:297,SN:29,TAG:"3223P003B",TAG_DESCR:"REFLUX PUMPS",TAG_DESCR_SHORT:"3223P003B / REFLUX PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-RE-R-E-0621",MR_DESCR:"REFLUX PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:298,SN:30,TAG:"3223P002A",TAG_DESCR:"CD REACTION COLUMN REFLUX PUMPS",TAG_DESCR_SHORT:"3223P002A / CD REACTION COLUMN REFLUX PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-RE-R-E-0621",MR_DESCR:"CD REACTION COLUMN REFLUX PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:299,SN:31,TAG:"3223P002B",TAG_DESCR:"CD REACTION COLUMN REFLUX PUMPS",TAG_DESCR_SHORT:"3223P002B / CD REACTION COLUMN REFLUX PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-RE-R-E-0621",MR_DESCR:"CD REACTION COLUMN REFLUX PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:300,SN:32,TAG:"3222P001A",TAG_DESCR:"DEBUTANIZER COLUMN REFLUX PUMPS",TAG_DESCR_SHORT:"3222P001A / DEBUTANIZER COLUMN REFLUX PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-RE-R-E-0621",MR_DESCR:"DEBUTANIZER COLUMN REFLUX PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:301,SN:33,TAG:"3222P001B",TAG_DESCR:"DEBUTANIZER COLUMN REFLUX PUMPS",TAG_DESCR_SHORT:"3222P001B / DEBUTANIZER COLUMN REFLUX PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-RE-R-E-0621",MR_DESCR:"DEBUTANIZER COLUMN REFLUX PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:302,SN:34,TAG:"3223E005",TAG_DESCR:"CD REACTION COLUMN CONDENSER",TAG_DESCR_SHORT:"3223E005 / CD REACTION COLUMN CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0401",MR_DESCR:"CD REACTION COLUMN CONDENSER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-26",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:303,SN:35,TAG:"3250E004",TAG_DESCR:"C12 PRODUCT COOLER",TAG_DESCR_SHORT:"3250E004 / C12 PRODUCT COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0401",MR_DESCR:"C12+ PRODUCT COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-26",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:304,SN:36,TAG:"3298E002",TAG_DESCR:"Exhaust Steam Condenser",TAG_DESCR_SHORT:"3298E002 / Exhaust Steam Condenser",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0401",MR_DESCR:"Exhaust Steam Condenser",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-26",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:305,SN:37,TAG:"3298E001",TAG_DESCR:"MP CONDENSATE COOLER",TAG_DESCR_SHORT:"3298E001 / MP CONDENSATE COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0401",MR_DESCR:"MP CONDENSATE COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-26",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:306,SN:38,TAG:"3230T001",TAG_DESCR:"METHANOL EXTRACTION COLUMN",TAG_DESCR_SHORT:"3230T001 / METHANOL EXTRACTION COLUMN",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0102",MR_DESCR:"METHANOL EXTRACTION COLUMN甲醇萃取塔",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-01-08",MR_FORECAST:"2022-01-28",MR_ACTUAL:"2021-12-28",LOA_PLAN:"2022-04-30",LOA_FORECAST:"2022-05-19",LOA_ACTUAL:"2022-05-16",PO_PLAN:"2022-06-11",PO_FORECAST:"2022-06-30",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:307,SN:39,TAG:"3230E001",TAG_DESCR:"METHANOL RECOVERY COLUMN FEEDBOTTOM EXCHANGER",TAG_DESCR_SHORT:"3230E001 / METHANOL RECOVERY COLUMN FEEDBOTTOM EX...",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0403",MR_DESCR:"METHANOL RECOVERY COLUMN FEED/BOTTOM EXCHANGER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:308,SN:40,TAG:"3230E004",TAG_DESCR:"RECYCLE WATER COOLER",TAG_DESCR_SHORT:"3230E004 / RECYCLE WATER COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0403",MR_DESCR:"RECYCLE WATER COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:309,SN:41,TAG:"3230E002",TAG_DESCR:"METHANOL RECOVERY COLUMN REBOILER",TAG_DESCR_SHORT:"3230E002 / METHANOL RECOVERY COLUMN REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0403",MR_DESCR:"METHANOL RECOVERY COLUMN REBOILER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:310,SN:42,TAG:"3230T002",TAG_DESCR:"METHANOL RECOVERY COLUMN",TAG_DESCR_SHORT:"3230T002 / METHANOL RECOVERY COLUMN",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0102",MR_DESCR:"METHANOL RECOVERY COLUMN甲醇回收塔",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-01-08",MR_FORECAST:"2022-01-28",MR_ACTUAL:"2021-12-28",LOA_PLAN:"2022-04-30",LOA_FORECAST:"2022-05-19",LOA_ACTUAL:"2022-05-16",PO_PLAN:"2022-06-11",PO_FORECAST:"2022-06-30",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:311,SN:43,TAG:"3240E002",TAG_DESCR:"MTBEDIB SPLITTER REBOILER",TAG_DESCR_SHORT:"3240E002 / MTBEDIB SPLITTER REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0403",MR_DESCR:"MTBE/DIB SPLITTER REBOILER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:312,SN:44,TAG:"3240T001",TAG_DESCR:"MTBEDIB SPLITTERMTBEDIB",TAG_DESCR_SHORT:"3240T001 / MTBEDIB SPLITTERMTBEDIB",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0102",MR_DESCR:"MTBE/DIB SPLITTERMTBE/DIB分离塔",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-01-08",MR_FORECAST:"2022-01-28",MR_ACTUAL:"2021-12-28",LOA_PLAN:"2022-04-30",LOA_FORECAST:"2022-05-19",LOA_ACTUAL:"2022-05-16",PO_PLAN:"2022-06-11",PO_FORECAST:"2022-06-30",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:313,SN:45,TAG:"3250T001",TAG_DESCR:"ISOOCTENE COLUMN",TAG_DESCR_SHORT:"3250T001 / ISOOCTENE COLUMN",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0102",MR_DESCR:"ISO-OCTENE COLUMN异辛烯塔",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-01-08",MR_FORECAST:"2022-01-28",MR_ACTUAL:"2021-12-28",LOA_PLAN:"2022-04-30",LOA_FORECAST:"2022-05-19",LOA_ACTUAL:"2022-05-16",PO_PLAN:"2022-06-11",PO_FORECAST:"2022-06-30",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:314,SN:46,TAG:"3298D002",TAG_DESCR:"METHANOL RECOVERY COLUMN FEEDBOTTOM EXCHANGER",TAG_DESCR_SHORT:"3298D002 / METHANOL RECOVERY COLUMN FEEDBOTTOM EX...",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0301",MR_DESCR:"METHANOL RECOVERY COLUMN FEED/BOTTOM EXCHANGER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-08-13",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-09-24",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:315,SN:47,TAG:"3298D001",TAG_DESCR:"MTBE PRODUCT COOLER",TAG_DESCR_SHORT:"3298D001 / MTBE PRODUCT COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0301",MR_DESCR:"MTBE PRODUCT COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-08-13",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-09-24",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:316,SN:48,TAG:"3250E002",TAG_DESCR:"ISOOCTENE COLUMN CONDENSER",TAG_DESCR_SHORT:"3250E002 / ISOOCTENE COLUMN CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0403",MR_DESCR:"ISO-OCTENE COLUMN CONDENSER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:317,SN:49,TAG:"3250D001",TAG_DESCR:"DIBMTBE SECONDARY REACTOR FEED HEATERCOOLER",TAG_DESCR_SHORT:"3250D001 / DIBMTBE SECONDARY REACTOR FEED HEATERC...",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0301",MR_DESCR:"DIB/MTBE SECONDARY REACTOR FEED HEATER/COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-08-13",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-09-24",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:318,SN:50,TAG:"3250E001",TAG_DESCR:"ISOOCTENE COLUMN REBOILER",TAG_DESCR_SHORT:"3250E001 / ISOOCTENE COLUMN REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0403",MR_DESCR:"ISO-OCTENE COLUMN REBOILER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:319,SN:51,TAG:"3250E003",TAG_DESCR:"C12 PRODUCT TRIM COOLER",TAG_DESCR_SHORT:"3250E003 / C12 PRODUCT TRIM COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0403",MR_DESCR:"C12+ PRODUCT TRIM COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:320,SN:52,TAG:"3240E001",TAG_DESCR:"MTBEDIB SPLITTER CONDENSER",TAG_DESCR_SHORT:"3240E001 / MTBEDIB SPLITTER CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0403",MR_DESCR:"MTBE/DIB SPLITTER CONDENSER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:321,SN:53,TAG:"3240D001",TAG_DESCR:"DEBUTANIZER COLUMN CONDENSER",TAG_DESCR_SHORT:"3240D001 / DEBUTANIZER COLUMN CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0301",MR_DESCR:"DEBUTANIZER COLUMN CONDENSER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-08-13",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-09-24",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:322,SN:54,TAG:"3230D001",TAG_DESCR:"DEBUTANIZER COLUMN REBOILER",TAG_DESCR_SHORT:"3230D001 / DEBUTANIZER COLUMN REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0301",MR_DESCR:"DEBUTANIZER COLUMN REBOILER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-08-13",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-09-24",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:323,SN:55,TAG:"3230E003",TAG_DESCR:"METHANOL RECOVERY COLUMN CONDENSER",TAG_DESCR_SHORT:"3230E003 / METHANOL RECOVERY COLUMN CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0404",MR_DESCR:"METHANOL RECOVERY COLUMN CONDENSER--甲醇回收塔冷凝器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-08-17",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:324,SN:56,TAG:"3230P002A",TAG_DESCR:"DIB HIGH SPEED PUMP DIB",TAG_DESCR_SHORT:"3230P002A / DIB HIGH SPEED PUMP DIB",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-RE-R-E-0640",MR_DESCR:"DIB HIGH SPEED PUMP DIB高速泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-02-23",MR_FORECAST:"2022-02-23",MR_ACTUAL:"2022-01-17",LOA_PLAN:"2022-06-23",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-08-04",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:325,SN:57,TAG:"3230P002B",TAG_DESCR:"DIB HIGH SPEED PUMP DIB",TAG_DESCR_SHORT:"3230P002B / DIB HIGH SPEED PUMP DIB",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-RE-R-E-0640",MR_DESCR:"DIB HIGH SPEED PUMP DIB高速泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-02-23",MR_FORECAST:"2022-02-23",MR_ACTUAL:"2022-01-17",LOA_PLAN:"2022-06-23",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-08-04",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:326,SN:58,TAG:"3230FIL001A",TAG_DESCR:"Continous Blowdown Drum",TAG_DESCR_SHORT:"3230FIL001A / Continous Blowdown Drum",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0501",MR_DESCR:"Continous Blowdown Drum",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-04",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-15",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:327,SN:59,TAG:"3230FIL001B",TAG_DESCR:"Continous Blowdown Drum",TAG_DESCR_SHORT:"3230FIL001B / Continous Blowdown Drum",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-EQ-R-E-0501",MR_DESCR:"Continous Blowdown Drum",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-01-26",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-04",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-15",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:328,SN:60,TAG:"3230P001A",TAG_DESCR:"DIB HIGH SPEED PUMP DIB",TAG_DESCR_SHORT:"3230P001A / DIB HIGH SPEED PUMP DIB",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-RE-R-E-0640",MR_DESCR:"DIB HIGH SPEED PUMP DIB高速泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-02-23",MR_FORECAST:"2022-02-23",MR_ACTUAL:"2022-01-17",LOA_PLAN:"2022-06-23",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-08-04",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:329,SN:61,TAG:"3230P001B",TAG_DESCR:"DIB HIGH SPEED PUMP DIB",TAG_DESCR_SHORT:"3230P001B / DIB HIGH SPEED PUMP DIB",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-RE-R-E-0640",MR_DESCR:"DIB HIGH SPEED PUMP DIB高速泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-02-23",MR_FORECAST:"2022-02-23",MR_ACTUAL:"2022-01-17",LOA_PLAN:"2022-06-23",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-08-04",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:330,SN:62,TAG:"3240P001A",TAG_DESCR:"DIB HIGH SPEED PUMP DIB",TAG_DESCR_SHORT:"3240P001A / DIB HIGH SPEED PUMP DIB",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-RE-R-E-0640",MR_DESCR:"DIB HIGH SPEED PUMP DIB高速泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-02-23",MR_FORECAST:"2022-02-23",MR_ACTUAL:"2022-01-17",LOA_PLAN:"2022-06-23",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-08-04",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:331,SN:63,TAG:"3240P001B",TAG_DESCR:"DIB HIGH SPEED PUMP DIB",TAG_DESCR_SHORT:"3240P001B / DIB HIGH SPEED PUMP DIB",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-RE-R-E-0640",MR_DESCR:"DIB HIGH SPEED PUMP DIB高速泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-02-23",MR_FORECAST:"2022-02-23",MR_ACTUAL:"2022-01-17",LOA_PLAN:"2022-06-23",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-08-04",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:332,SN:64,TAG:"3250P001A",TAG_DESCR:"ISOOCTENE COLUMN REFLUX PUMPS",TAG_DESCR_SHORT:"3250P001A / ISOOCTENE COLUMN REFLUX PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-RE-R-E-0621",MR_DESCR:"ISO-OCTENE COLUMN REFLUX PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:333,SN:65,TAG:"3250P001B",TAG_DESCR:"ISOOCTENE COLUMN REFLUX PUMPS",TAG_DESCR_SHORT:"3250P001B / ISOOCTENE COLUMN REFLUX PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-RE-R-E-0621",MR_DESCR:"ISO-OCTENE COLUMN REFLUX PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:334,SN:66,TAG:"3250P002A",TAG_DESCR:"C12 PRODUCT PUMPS",TAG_DESCR_SHORT:"3250P002A / C12 PRODUCT PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-RE-R-E-0621",MR_DESCR:"C12+ PRODUCT PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:335,SN:67,TAG:"3250P002B",TAG_DESCR:"C12 PRODUCT PUMPS",TAG_DESCR_SHORT:"3250P002B / C12 PRODUCT PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-RE-R-E-0621",MR_DESCR:"C12+ PRODUCT PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:336,SN:68,TAG:"3298P002A",TAG_DESCR:"LP CONDENSATE PUMP",TAG_DESCR_SHORT:"3298P002A / LP CONDENSATE PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-RE-R-E-0621",MR_DESCR:"LP CONDENSATE PUMP",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:337,SN:69,TAG:"3298P002B",TAG_DESCR:"LP CONDENSATE PUMP",TAG_DESCR_SHORT:"3298P002B / LP CONDENSATE PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-RE-R-E-0621",MR_DESCR:"LP CONDENSATE PUMP",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:338,SN:70,TAG:"3298P001A",TAG_DESCR:"MP CONDENSATE PUMP",TAG_DESCR_SHORT:"3298P001A / MP CONDENSATE PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-RE-R-E-0621",MR_DESCR:"MP CONDENSATE PUMP",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:339,SN:71,TAG:"3298P001B",TAG_DESCR:"MP CONDENSATE PUMP",TAG_DESCR_SHORT:"3298P001B / MP CONDENSATE PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"DIB",MR_NO:"75231-P1-1362-RE-R-E-0621",MR_DESCR:"MP CONDENSATE PUMP",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1939,TAG:"258003A",TAG_DESCR_SHORT:"258003A / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1940,TAG:"258003B",TAG_DESCR_SHORT:"258003B / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1936,TAG:"2580D003",TAG_DESCR:"Continous Blowdown Drum",TAG_DESCR_SHORT:"2580D003 / Continous Blowdown Drum",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0303",MR_DESCR:"Continous Blowdown Drum",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1937,TAG:"2580D004",TAG_DESCR:"Intermittent Blowdown Drum",TAG_DESCR_SHORT:"2580D004 / Intermittent Blowdown Drum",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0303",MR_DESCR:"Intermittent Blowdown Drum",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1906,TAG:"1011D101",TAG_DESCR:"Decoke Drums",TAG_DESCR_SHORT:"1011D101 / Decoke Drums",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL09",MR_DESCR:"Decoke Drums 清焦罐",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",MR_PLAN:"2022-02-26",MR_FORECAST:"2022-02-26",MR_ACTUAL:"2022-01-29",LOA_PLAN:"2022-07-01",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-08-12",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1907,TAG:"1011D201",TAG_DESCR:"Steam Drums",TAG_DESCR_SHORT:"1011D201 / Steam Drums",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL10",MR_DESCR:"Steam Drums 高压汽包",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",MR_PLAN:"2022-05-18",MR_FORECAST:"2022-05-18",MR_ACTUAL:"2022-04-22",LOA_PLAN:"2022-08-28",LOA_FORECAST:"2022-06-30",LOA_ACTUAL:"2022-06-29",PO_PLAN:"2022-10-09",PO_FORECAST:"2022-08-11",PO_ACTUAL:"2022-08-18",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1908,TAG:"1011E101",TAG_DESCR:"Secondary TLE",TAG_DESCR_SHORT:"1011E101 / Secondary TLE",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-LL11",MR_DESCR:"Secondary TLE",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",MR_PLAN:"2021-12-12",MR_FORECAST:"2021-12-12",MR_ACTUAL:"2021-11-15",LOA_PLAN:"2022-04-11",LOA_FORECAST:"2022-03-29",LOA_ACTUAL:"2022-03-16",PO_PLAN:"2022-05-23",PO_FORECAST:"2022-05-10",PO_ACTUAL:"2022-04-13",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1909,TAG:"1011F001",TAG_DESCR:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",TAG_DESCR_SHORT:"1011F001 / Convection Section ModuleRadiant Steel...",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-FU-R-E-SL02",MR_DESCR:"对流段模块及辐射段钢结构Convection Section Module&Radiant Steel Structure for  Olefins Cracking Furnaces RG/Convection Section Module&Radiant Steel Structure for  Olefins Cracking/ Convection Section Module&Radiant Steel Structure for  Olefins Cracking Furnaces LVN F",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",MR_PLAN:"2021-09-28",MR_FORECAST:"2021-11-09",MR_ACTUAL:"2021-11-02",LOA_PLAN:"2022-01-16",LOA_FORECAST:"2022-03-04",LOA_ACTUAL:"2022-03-10",PO_PLAN:"2022-02-27",PO_FORECAST:"2022-05-25",PO_ACTUAL:"2022-07-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1910,TAG:"1012D101",TAG_DESCR:"Decoke Drums",TAG_DESCR_SHORT:"1012D101 / Decoke Drums",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL09",MR_DESCR:"Decoke Drums 清焦罐",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",MR_PLAN:"2022-02-26",MR_FORECAST:"2022-02-26",MR_ACTUAL:"2022-01-29",LOA_PLAN:"2022-07-01",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-08-12",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1911,TAG:"1012D201",TAG_DESCR:"Steam Drums",TAG_DESCR_SHORT:"1012D201 / Steam Drums",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL10",MR_DESCR:"Steam Drums 高压汽包",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",MR_PLAN:"2022-05-18",MR_FORECAST:"2022-05-18",MR_ACTUAL:"2022-04-22",LOA_PLAN:"2022-08-28",LOA_FORECAST:"2022-06-30",LOA_ACTUAL:"2022-06-29",PO_PLAN:"2022-10-09",PO_FORECAST:"2022-08-11",PO_ACTUAL:"2022-08-18",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1938,TAG:"1012F001",TAG_DESCR:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",TAG_DESCR_SHORT:"1012F001 / Convection Section ModuleRadiant Steel...",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-FU-R-E-SL02",MR_DESCR:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",MR_PLAN:"2021-09-28",MR_FORECAST:"2021-11-09",MR_ACTUAL:"2021-11-02",LOA_PLAN:"2022-01-16",LOA_FORECAST:"2022-03-04",LOA_ACTUAL:"2022-03-10",PO_PLAN:"2022-02-27",PO_FORECAST:"2022-05-25",PO_ACTUAL:"2022-07-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1912,TAG:"1013D101",TAG_DESCR:"Decoke Drums",TAG_DESCR_SHORT:"1013D101 / Decoke Drums",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL09",MR_DESCR:"Decoke Drums 清焦罐",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",MR_PLAN:"2022-02-26",MR_FORECAST:"2022-02-26",MR_ACTUAL:"2022-01-29",LOA_PLAN:"2022-07-01",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-08-12",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1913,TAG:"1013D201",TAG_DESCR:"Steam Drums",TAG_DESCR_SHORT:"1013D201 / Steam Drums",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL10",MR_DESCR:"Steam Drums 高压汽包",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",MR_PLAN:"2022-05-18",MR_FORECAST:"2022-05-18",MR_ACTUAL:"2022-04-22",LOA_PLAN:"2022-08-28",LOA_FORECAST:"2022-06-30",LOA_ACTUAL:"2022-06-29",PO_PLAN:"2022-10-09",PO_FORECAST:"2022-08-11",PO_ACTUAL:"2022-08-18",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1914,TAG:"1013F001",TAG_DESCR:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",TAG_DESCR_SHORT:"1013F001 / Convection Section ModuleRadiant Steel...",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-FU-R-E-SL02",MR_DESCR:"对流段模块及辐射段钢结构Convection Section Module&Radiant Steel Structure for  Olefins Cracking Furnaces RG/Convection Section Module&Radiant Steel Structure for  Olefins Cracking/ Convection Section Module&Radiant Steel Structure for  Olefins Cracking Furnaces LVN F",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",MR_PLAN:"2021-09-28",MR_FORECAST:"2021-11-09",MR_ACTUAL:"2021-11-02",LOA_PLAN:"2022-01-16",LOA_FORECAST:"2022-03-04",LOA_ACTUAL:"2022-03-10",PO_PLAN:"2022-02-27",PO_FORECAST:"2022-05-25",PO_ACTUAL:"2022-07-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1915,TAG:"1014D101",TAG_DESCR:"Decoke Drums",TAG_DESCR_SHORT:"1014D101 / Decoke Drums",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL09",MR_DESCR:"Decoke Drums 清焦罐",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",MR_PLAN:"2022-02-26",MR_FORECAST:"2022-02-26",MR_ACTUAL:"2022-01-29",LOA_PLAN:"2022-07-01",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-08-12",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1916,TAG:"1014D201",TAG_DESCR:"Steam Drums",TAG_DESCR_SHORT:"1014D201 / Steam Drums",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL10",MR_DESCR:"Steam Drums 高压汽包",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",MR_PLAN:"2022-05-18",MR_FORECAST:"2022-05-18",MR_ACTUAL:"2022-04-22",LOA_PLAN:"2022-08-28",LOA_FORECAST:"2022-06-30",LOA_ACTUAL:"2022-06-29",PO_PLAN:"2022-10-09",PO_FORECAST:"2022-08-11",PO_ACTUAL:"2022-08-18",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1917,TAG:"1014F001",TAG_DESCR:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",TAG_DESCR_SHORT:"1014F001 / Convection Section ModuleRadiant Steel...",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-FU-R-E-SL02",MR_DESCR:"对流段模块及辐射段钢结构Convection Section Module&Radiant Steel Structure for  Olefins Cracking Furnaces RG/Convection Section Module&Radiant Steel Structure for  Olefins Cracking/ Convection Section Module&Radiant Steel Structure for  Olefins Cracking Furnaces LVN F",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",MR_PLAN:"2021-09-28",MR_FORECAST:"2021-11-09",MR_ACTUAL:"2021-11-02",LOA_PLAN:"2022-01-16",LOA_FORECAST:"2022-03-04",LOA_ACTUAL:"2022-03-10",PO_PLAN:"2022-02-27",PO_FORECAST:"2022-05-25",PO_ACTUAL:"2022-07-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1918,TAG:"1015D101",TAG_DESCR:"Decoke Drums",TAG_DESCR_SHORT:"1015D101 / Decoke Drums",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL09",MR_DESCR:"Decoke Drums 清焦罐",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",MR_PLAN:"2022-02-26",MR_FORECAST:"2022-02-26",MR_ACTUAL:"2022-01-29",LOA_PLAN:"2022-07-01",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-08-12",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1919,TAG:"1015D201",TAG_DESCR:"Steam Drums",TAG_DESCR_SHORT:"1015D201 / Steam Drums",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL10",MR_DESCR:"Steam Drums 高压汽包",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",MR_PLAN:"2022-05-18",MR_FORECAST:"2022-05-18",MR_ACTUAL:"2022-04-22",LOA_PLAN:"2022-08-28",LOA_FORECAST:"2022-06-30",LOA_ACTUAL:"2022-06-29",PO_PLAN:"2022-10-09",PO_FORECAST:"2022-08-11",PO_ACTUAL:"2022-08-18",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1920,TAG:"1015F001",TAG_DESCR:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",TAG_DESCR_SHORT:"1015F001 / Convection Section ModuleRadiant Steel...",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-FU-R-E-SL02",MR_DESCR:"对流段模块及辐射段钢结构Convection Section Module&Radiant Steel Structure for  Olefins Cracking Furnaces RG/Convection Section Module&Radiant Steel Structure for  Olefins Cracking/ Convection Section Module&Radiant Steel Structure for  Olefins Cracking Furnaces LVN F",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",MR_PLAN:"2021-09-28",MR_FORECAST:"2021-11-09",MR_ACTUAL:"2021-11-02",LOA_PLAN:"2022-01-16",LOA_FORECAST:"2022-03-04",LOA_ACTUAL:"2022-03-10",PO_PLAN:"2022-02-27",PO_FORECAST:"2022-05-25",PO_ACTUAL:"2022-07-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1921,TAG:"1016D101",TAG_DESCR:"Decoke Drums",TAG_DESCR_SHORT:"1016D101 / Decoke Drums",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL09",MR_DESCR:"Decoke Drums 清焦罐",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",MR_PLAN:"2022-02-26",MR_FORECAST:"2022-02-26",MR_ACTUAL:"2022-01-29",LOA_PLAN:"2022-07-01",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-08-12",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1922,TAG:"1016D201",TAG_DESCR:"Steam Drums",TAG_DESCR_SHORT:"1016D201 / Steam Drums",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL10",MR_DESCR:"Steam Drums 高压汽包",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",MR_PLAN:"2022-05-18",MR_FORECAST:"2022-05-18",MR_ACTUAL:"2022-04-22",LOA_PLAN:"2022-08-28",LOA_FORECAST:"2022-06-30",LOA_ACTUAL:"2022-06-29",PO_PLAN:"2022-10-09",PO_FORECAST:"2022-08-11",PO_ACTUAL:"2022-08-18",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1923,TAG:"1016F001",TAG_DESCR:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",TAG_DESCR_SHORT:"1016F001 / Convection Section ModuleRadiant Steel...",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-FU-R-E-SL02",MR_DESCR:"对流段模块及辐射段钢结构Convection Section Module&Radiant Steel Structure for  Olefins Cracking Furnaces RG/Convection Section Module&Radiant Steel Structure for  Olefins Cracking/ Convection Section Module&Radiant Steel Structure for  Olefins Cracking Furnaces LVN F",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",MR_PLAN:"2021-09-28",MR_FORECAST:"2021-11-09",MR_ACTUAL:"2021-11-02",LOA_PLAN:"2022-01-16",LOA_FORECAST:"2022-03-04",LOA_ACTUAL:"2022-03-10",PO_PLAN:"2022-02-27",PO_FORECAST:"2022-05-25",PO_ACTUAL:"2022-07-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1924,TAG:"1017D101",TAG_DESCR:"Decoke Drums",TAG_DESCR_SHORT:"1017D101 / Decoke Drums",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL09",MR_DESCR:"Decoke Drums 清焦罐",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",MR_PLAN:"2022-02-26",MR_FORECAST:"2022-02-26",MR_ACTUAL:"2022-01-29",LOA_PLAN:"2022-07-01",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-08-12",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1925,TAG:"1017D201",TAG_DESCR:"Steam Drums",TAG_DESCR_SHORT:"1017D201 / Steam Drums",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL10",MR_DESCR:"Steam Drums 高压汽包",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",MR_PLAN:"2022-05-18",MR_FORECAST:"2022-05-18",MR_ACTUAL:"2022-04-22",LOA_PLAN:"2022-08-28",LOA_FORECAST:"2022-06-30",LOA_ACTUAL:"2022-06-29",PO_PLAN:"2022-10-09",PO_FORECAST:"2022-08-11",PO_ACTUAL:"2022-08-18",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1926,TAG:"1017F001",TAG_DESCR:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",TAG_DESCR_SHORT:"1017F001 / Convection Section ModuleRadiant Steel...",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-FU-R-E-SL02",MR_DESCR:"对流段模块及辐射段钢结构Convection Section Module&Radiant Steel Structure for  Olefins Cracking Furnaces RG/Convection Section Module&Radiant Steel Structure for  Olefins Cracking/ Convection Section Module&Radiant Steel Structure for  Olefins Cracking Furnaces LVN F",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",MR_PLAN:"2021-09-28",MR_FORECAST:"2021-11-09",MR_ACTUAL:"2021-11-02",LOA_PLAN:"2022-01-16",LOA_FORECAST:"2022-03-04",LOA_ACTUAL:"2022-03-10",PO_PLAN:"2022-02-27",PO_FORECAST:"2022-05-25",PO_ACTUAL:"2022-07-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1927,TAG:"1018D101",TAG_DESCR:"Decoke Drums",TAG_DESCR_SHORT:"1018D101 / Decoke Drums",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL09",MR_DESCR:"Decoke Drums 清焦罐",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",MR_PLAN:"2022-02-26",MR_FORECAST:"2022-02-26",MR_ACTUAL:"2022-01-29",LOA_PLAN:"2022-07-01",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-08-12",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1928,TAG:"1018D201",TAG_DESCR:"Steam Drums",TAG_DESCR_SHORT:"1018D201 / Steam Drums",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL10",MR_DESCR:"Steam Drums 高压汽包",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",MR_PLAN:"2022-05-18",MR_FORECAST:"2022-05-18",MR_ACTUAL:"2022-04-22",LOA_PLAN:"2022-08-28",LOA_FORECAST:"2022-06-30",LOA_ACTUAL:"2022-06-29",PO_PLAN:"2022-10-09",PO_FORECAST:"2022-08-11",PO_ACTUAL:"2022-08-18",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1929,TAG:"1018F001",TAG_DESCR:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",TAG_DESCR_SHORT:"1018F001 / Convection Section ModuleRadiant Steel...",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-FU-R-E-SL02",MR_DESCR:"对流段模块及辐射段钢结构Convection Section Module&Radiant Steel Structure for  Olefins Cracking Furnaces RG/Convection Section Module&Radiant Steel Structure for  Olefins Cracking/ Convection Section Module&Radiant Steel Structure for  Olefins Cracking Furnaces LVN F",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",MR_PLAN:"2021-09-28",MR_FORECAST:"2021-11-09",MR_ACTUAL:"2021-11-02",LOA_PLAN:"2022-01-16",LOA_FORECAST:"2022-03-04",LOA_ACTUAL:"2022-03-10",PO_PLAN:"2022-02-27",PO_FORECAST:"2022-05-25",PO_ACTUAL:"2022-07-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1930,TAG:"1019D101",TAG_DESCR:"Decoke Drums",TAG_DESCR_SHORT:"1019D101 / Decoke Drums",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL09",MR_DESCR:"Decoke Drums 清焦罐",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",MR_PLAN:"2022-02-26",MR_FORECAST:"2022-02-26",MR_ACTUAL:"2022-01-29",LOA_PLAN:"2022-07-01",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-08-12",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1931,TAG:"1019D201",TAG_DESCR:"Steam Drums",TAG_DESCR_SHORT:"1019D201 / Steam Drums",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL10",MR_DESCR:"Steam Drums 高压汽包",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",MR_PLAN:"2022-05-18",MR_FORECAST:"2022-05-18",MR_ACTUAL:"2022-04-22",LOA_PLAN:"2022-08-28",LOA_FORECAST:"2022-06-30",LOA_ACTUAL:"2022-06-29",PO_PLAN:"2022-10-09",PO_FORECAST:"2022-08-11",PO_ACTUAL:"2022-08-18",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1932,TAG:"1019F001",TAG_DESCR:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",TAG_DESCR_SHORT:"1019F001 / Convection Section ModuleRadiant Steel...",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-FU-R-E-SL02",MR_DESCR:"对流段模块及辐射段钢结构Convection Section Module&Radiant Steel Structure for  Olefins Cracking Furnaces RG/Convection Section Module&Radiant Steel Structure for  Olefins Cracking/ Convection Section Module&Radiant Steel Structure for  Olefins Cracking Furnaces LVN F",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",MR_PLAN:"2021-09-28",MR_FORECAST:"2021-11-09",MR_ACTUAL:"2021-11-02",LOA_PLAN:"2022-01-16",LOA_FORECAST:"2022-03-04",LOA_ACTUAL:"2022-03-10",PO_PLAN:"2022-02-27",PO_FORECAST:"2022-05-25",PO_ACTUAL:"2022-07-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1933,TAG:"1020D101",TAG_DESCR:"Decoke Drums",TAG_DESCR_SHORT:"1020D101 / Decoke Drums",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL09",MR_DESCR:"Decoke Drums 清焦罐",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",MR_PLAN:"2022-02-26",MR_FORECAST:"2022-02-26",MR_ACTUAL:"2022-01-29",LOA_PLAN:"2022-07-01",LOA_FORECAST:"2022-08-13",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-08-12",PO_FORECAST:"2022-09-24",PO_ACTUAL:"2022-09-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1934,TAG:"1020D201",TAG_DESCR:"Steam Drums",TAG_DESCR_SHORT:"1020D201 / Steam Drums",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL10",MR_DESCR:"Steam Drums 高压汽包",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",MR_PLAN:"2022-05-18",MR_FORECAST:"2022-05-18",MR_ACTUAL:"2022-04-22",LOA_PLAN:"2022-08-28",LOA_FORECAST:"2022-06-30",LOA_ACTUAL:"2022-06-29",PO_PLAN:"2022-10-09",PO_FORECAST:"2022-08-11",PO_ACTUAL:"2022-08-18",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1935,TAG:"1020F001",TAG_DESCR:"Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces RGConvection Section ModuleRadiant Steel Structure for Olefins Cracking Convection Section ModuleRadiant Steel Structure for Olefins Cracking Furnaces LVN F",TAG_DESCR_SHORT:"1020F001 / Convection Section ModuleRadiant Steel...",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-FU-R-E-SL02",MR_DESCR:"对流段模块及辐射段钢结构Convection Section Module&Radiant Steel Structure for  Olefins Cracking Furnaces RG/Convection Section Module&Radiant Steel Structure for  Olefins Cracking/ Convection Section Module&Radiant Steel Structure for  Olefins Cracking Furnaces LVN F",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",MR_PLAN:"2021-09-28",MR_FORECAST:"2021-11-09",MR_ACTUAL:"2021-11-02",LOA_PLAN:"2022-01-16",LOA_FORECAST:"2022-03-04",LOA_ACTUAL:"2022-03-10",PO_PLAN:"2022-02-27",PO_FORECAST:"2022-05-25",PO_ACTUAL:"2022-07-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:532,TAG:"3610C001A",TAG_DESCR:"GHU COMRESSORS",TAG_DESCR_SHORT:"3610C001A / GHU COMRESSORS",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-RE-R-E-SL01",MR_DESCR:"GHU COMRESSORS 新氢压缩机/循环氢压缩机联合机组",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2022-02-27",MR_FORECAST:"2022-02-27",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-06-28",LOA_FORECAST:"2022-06-07",LOA_ACTUAL:"2022-06-10",PO_PLAN:"2022-08-09",PO_FORECAST:"2022-07-19",PO_ACTUAL:"2022-08-25",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:533,TAG:"3610C001B",TAG_DESCR:"GHU COMRESSORS",TAG_DESCR_SHORT:"3610C001B / GHU COMRESSORS",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-RE-R-E-SL01",MR_DESCR:"GHU COMRESSORS 新氢压缩机/循环氢压缩机联合机组",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2022-02-27",MR_FORECAST:"2022-02-27",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-06-28",LOA_FORECAST:"2022-06-07",LOA_ACTUAL:"2022-06-10",PO_PLAN:"2022-08-09",PO_FORECAST:"2022-07-19",PO_ACTUAL:"2022-08-25",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:581,TAG:"3640T001-I",TAG_DESCR:"HD2 DEPENTANIZER INTERNAL",TAG_DESCR_SHORT:"3640T001-I / HD2 DEPENTANIZER INTERNAL",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0102",MR_DESCR:"HD2 DEPENTANIZER INTERNAL二段加氢脱戊烷塔内件",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-15",LOA_PLAN:"2022-04-05",LOA_FORECAST:"2022-05-22",LOA_ACTUAL:"2022-05-28",PO_PLAN:"2022-05-17",PO_FORECAST:"2022-07-03",PO_ACTUAL:"2022-08-25",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:582,TAG:"3640T005-I",TAG_DESCR:"HD2 DEPENTANIZER SIDE STRIPPER INTERNAL",TAG_DESCR_SHORT:"3640T005-I / HD2 DEPENTANIZER SIDE STRIPPER INTERNAL",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0102",MR_DESCR:"HD2 DEPENTANIZER SIDE STRIPPER INTERNAL二段加氢脱戊烷塔侧线气提塔内件",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-15",LOA_PLAN:"2022-04-05",LOA_FORECAST:"2022-05-22",LOA_ACTUAL:"2022-05-28",PO_PLAN:"2022-05-17",PO_FORECAST:"2022-07-03",PO_ACTUAL:"2022-08-25",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:583,TAG:"3640T011-I",TAG_DESCR:"DETOLUENIZER INTERNAL",TAG_DESCR_SHORT:"3640T011-I / DETOLUENIZER INTERNAL",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0102",MR_DESCR:"DETOLUENIZER INTERNAL脱甲苯塔内件",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-15",LOA_PLAN:"2022-04-05",LOA_FORECAST:"2022-05-22",LOA_ACTUAL:"2022-05-28",PO_PLAN:"2022-05-17",PO_FORECAST:"2022-07-03",PO_ACTUAL:"2022-08-25",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:577,TAG:"3640E014A",TAG_DESCR:"DETOLUENIZER BOTTOM HD2 FEED EXCHANGER",TAG_DESCR_SHORT:"3640E014A / DETOLUENIZER BOTTOM HD2 FEED EXCHANGER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0403",MR_DESCR:"DETOLUENIZER BOTTOM / HD2 FEED EXCHANGER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-25",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:578,TAG:"3640E014B",TAG_DESCR:"DETOLUENIZER BOTTOM HD2 FEED EXCHANGER",TAG_DESCR_SHORT:"3640E014B / DETOLUENIZER BOTTOM HD2 FEED EXCHANGER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0403",MR_DESCR:"DETOLUENIZER BOTTOM / HD2 FEED EXCHANGER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-25",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:579,TAG:"3640M001",TAG_DESCR:"HD2 DEPENTANIZER / C5HD DESUPERHEATER",TAG_DESCR_SHORT:"3640M001 / HD2 DEPENTANIZER  C5HD DESUPERHEATER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU - Static",MR_NO:"TBD",MR_DESCR:"HD2 DEPENTANIZER / C5HD DESUPERHEATER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:580,TAG:"3640M005",TAG_DESCR:"HD2 DEPENTANIZER SIDE STRIPPER DESUPERHEATER",TAG_DESCR_SHORT:"3640M005 / HD2 DEPENTANIZER SIDE STRIPPER DESUPER...",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU - Static",MR_NO:"TBD",MR_DESCR:"HD2 DEPENTANIZER SIDE STRIPPER DESUPERHEATER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:564,TAG:"3630E002A",TAG_DESCR:"",TAG_DESCR_SHORT:"3630E002A / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0401",MR_DESCR:"二段加氢热分离空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-22",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:565,TAG:"3630E002B",TAG_DESCR:"",TAG_DESCR_SHORT:"3630E002B / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0401",MR_DESCR:"二段加氢热分离空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-22",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:566,TAG:"3630M001",TAG_DESCR:"SULFIDING AGENT DMDS INJECTION PACKAGEDMDS",TAG_DESCR_SHORT:"3630M001 / SULFIDING AGENT DMDS INJECTION PACKAGE...",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-RE-R-E-0610",MR_DESCR:"SULFIDING AGENT (DMDS) INJECTION PACKAGEDMDS注入包",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2022-09-01",MR_FORECAST:"2022-09-01",MR_ACTUAL:"2022-08-09",LOA_PLAN:"2022-11-15",LOA_FORECAST:"2022-12-28",LOA_ACTUAL:"2022-12-27",PO_PLAN:"2022-12-27",PO_FORECAST:"2023-02-08",PO_ACTUAL:"2023-01-30",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:567,TAG:"3640E001A",TAG_DESCR:"HD1 DEPENTANIZER FEEDRERUN TOWER BOTTOM EXCHANGER",TAG_DESCR_SHORT:"3640E001A / HD1 DEPENTANIZER FEEDRERUN TOWER BOTT...",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0401",MR_DESCR:"HD1 DEPENTANIZER FEED/RERUN TOWER BOTTOM EXCHANGER二段加氢脱戊烷塔空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-22",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:568,TAG:"3640E001B",TAG_DESCR:"HD1 DEPENTANIZER FEEDRERUN TOWER BOTTOM EXCHANGER",TAG_DESCR_SHORT:"3640E001B / HD1 DEPENTANIZER FEEDRERUN TOWER BOTT...",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0401",MR_DESCR:"HD1 DEPENTANIZER FEED/RERUN TOWER BOTTOM EXCHANGER二段加氢脱戊烷塔空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-22",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:569,TAG:"3640E001C",TAG_DESCR:"HD1 DEPENTANIZER FEEDRERUN TOWER BOTTOM EXCHANGER",TAG_DESCR_SHORT:"3640E001C / HD1 DEPENTANIZER FEEDRERUN TOWER BOTT...",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0401",MR_DESCR:"HD1 DEPENTANIZER FEED/RERUN TOWER BOTTOM EXCHANGER二段加氢脱戊烷塔空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-22",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:570,TAG:"3640E001D",TAG_DESCR:"HD1 DEPENTANIZER FEEDRERUN TOWER BOTTOM EXCHANGER",TAG_DESCR_SHORT:"3640E001D / HD1 DEPENTANIZER FEEDRERUN TOWER BOTT...",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0401",MR_DESCR:"HD1 DEPENTANIZER FEED/RERUN TOWER BOTTOM EXCHANGER二段加氢脱戊烷塔空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-22",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:571,TAG:"3640E011A",TAG_DESCR:"RERUN TOWER TRIM CONDENSER",TAG_DESCR_SHORT:"3640E011A / RERUN TOWER TRIM CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0401",MR_DESCR:"RERUN TOWER TRIM CONDENSER脱甲苯塔空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-22",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:572,TAG:"3640E011B",TAG_DESCR:"RERUN TOWER TRIM CONDENSER",TAG_DESCR_SHORT:"3640E011B / RERUN TOWER TRIM CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0401",MR_DESCR:"RERUN TOWER TRIM CONDENSER脱甲苯塔空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-22",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:573,TAG:"3640E011C",TAG_DESCR:"RERUN TOWER TRIM CONDENSER",TAG_DESCR_SHORT:"3640E011C / RERUN TOWER TRIM CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0401",MR_DESCR:"RERUN TOWER TRIM CONDENSER脱甲苯塔空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-22",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:574,TAG:"3640E011D",TAG_DESCR:"RERUN TOWER TRIM CONDENSER",TAG_DESCR_SHORT:"3640E011D / RERUN TOWER TRIM CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0401",MR_DESCR:"RERUN TOWER TRIM CONDENSER脱甲苯塔空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-22",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:575,TAG:"3640E011E",TAG_DESCR:"RERUN TOWER TRIM CONDENSER",TAG_DESCR_SHORT:"3640E011E / RERUN TOWER TRIM CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0401",MR_DESCR:"RERUN TOWER TRIM CONDENSER脱甲苯塔空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-22",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:576,TAG:"3640E011F",TAG_DESCR:"RERUN TOWER TRIM CONDENSER",TAG_DESCR_SHORT:"3640E011F / RERUN TOWER TRIM CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0401",MR_DESCR:"RERUN TOWER TRIM CONDENSER脱甲苯塔空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-22",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:535,TAG:"3610E003A",TAG_DESCR:"HD1 QUENCH COOLER",TAG_DESCR_SHORT:"3610E003A / HD1 QUENCH COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0401",MR_DESCR:"HD1 QUENCH COOLER一段加氢循环空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-22",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:536,TAG:"3610E003B",TAG_DESCR:"HD1 QUENCH COOLER",TAG_DESCR_SHORT:"3610E003B / HD1 QUENCH COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0401",MR_DESCR:"HD1 QUENCH COOLER一段加氢循环空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-22",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:537,TAG:"3610E003C",TAG_DESCR:"HD1 QUENCH COOLER",TAG_DESCR_SHORT:"3610E003C / HD1 QUENCH COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0401",MR_DESCR:"HD1 QUENCH COOLER一段加氢循环空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-22",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:538,TAG:"3610E003D",TAG_DESCR:"HD1 QUENCH COOLER",TAG_DESCR_SHORT:"3610E003D / HD1 QUENCH COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0401",MR_DESCR:"HD1 QUENCH COOLER一段加氢循环空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-22",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:539,TAG:"3610E003E",TAG_DESCR:"HD1 QUENCH COOLER",TAG_DESCR_SHORT:"3610E003E / HD1 QUENCH COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0401",MR_DESCR:"HD1 QUENCH COOLER一段加氢循环空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-22",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:540,TAG:"3610E003F",TAG_DESCR:"HD1 QUENCH COOLER",TAG_DESCR_SHORT:"3610E003F / HD1 QUENCH COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0401",MR_DESCR:"HD1 QUENCH COOLER一段加氢循环空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-22",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:534,TAG:"3610D004",TAG_DESCR:"SHP STEAM CONDENSATE FLASH DRUM",TAG_DESCR_SHORT:"3610D004 / SHP STEAM CONDENSATE FLASH DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU - Static",MR_NO:"TBD",MR_DESCR:"SHP STEAM CONDENSATE FLASH DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:541,TAG:"3610M002",TAG_DESCR:"CORROSION INHIBITOR INJECTION PACKAGE",TAG_DESCR_SHORT:"3610M002 / CORROSION INHIBITOR INJECTION PACKAGE",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-RE-R-E-0610",MR_DESCR:"CORROSION INHIBITOR INJECTION PACKAGE缓蚀剂注入包",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2022-09-01",MR_FORECAST:"2022-09-01",MR_ACTUAL:"2022-08-09",LOA_PLAN:"2022-11-15",LOA_FORECAST:"2022-12-28",LOA_ACTUAL:"2022-12-27",PO_PLAN:"2022-12-27",PO_FORECAST:"2023-02-08",PO_ACTUAL:"2023-01-30",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:543,TAG:"3620E001B",TAG_DESCR:"H2 MAKE UP COMPRESSOR SPILL BACK COOLER",TAG_DESCR_SHORT:"3620E001B / H2 MAKE UP COMPRESSOR SPILL BACK COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0401",MR_DESCR:"H2 MAKE UP COMPRESSOR SPILL BACK COOLER一段加氢脱戊烷塔空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-22",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:544,TAG:"3620E001C",TAG_DESCR:"H2 MAKE UP COMPRESSOR SPILL BACK COOLER",TAG_DESCR_SHORT:"3620E001C / H2 MAKE UP COMPRESSOR SPILL BACK COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0401",MR_DESCR:"H2 MAKE UP COMPRESSOR SPILL BACK COOLER一段加氢脱戊烷塔空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-22",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:545,TAG:"3620E001D",TAG_DESCR:"H2 MAKE UP COMPRESSOR SPILL BACK COOLER",TAG_DESCR_SHORT:"3620E001D / H2 MAKE UP COMPRESSOR SPILL BACK COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0401",MR_DESCR:"H2 MAKE UP COMPRESSOR SPILL BACK COOLER一段加氢脱戊烷塔空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-22",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:546,TAG:"3620E001E",TAG_DESCR:"H2 MAKE UP COMPRESSOR SPILL BACK COOLER",TAG_DESCR_SHORT:"3620E001E / H2 MAKE UP COMPRESSOR SPILL BACK COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0401",MR_DESCR:"H2 MAKE UP COMPRESSOR SPILL BACK COOLER一段加氢脱戊烷塔空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-22",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:542,TAG:"3620E001A",TAG_DESCR:"H2 MAKE UP COMPRESSOR SPILL BACK COOLER",TAG_DESCR_SHORT:"3620E001A / H2 MAKE UP COMPRESSOR SPILL BACK COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0401",MR_DESCR:"H2 MAKE UP COMPRESSOR SPILL BACK COOLER一段加氢脱戊烷塔空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-22",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:547,TAG:"3620E011A",TAG_DESCR:"HD1 DEPENTANIZER REBOILER",TAG_DESCR_SHORT:"3620E011A / HD1 DEPENTANIZER REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0401",MR_DESCR:"HD1 DEPENTANIZER REBOILER脱重塔空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-22",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:548,TAG:"3620E011B",TAG_DESCR:"HD1 DEPENTANIZER REBOILER",TAG_DESCR_SHORT:"3620E011B / HD1 DEPENTANIZER REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0401",MR_DESCR:"HD1 DEPENTANIZER REBOILER脱重塔空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-22",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:549,TAG:"3620E011C",TAG_DESCR:"HD1 DEPENTANIZER REBOILER",TAG_DESCR_SHORT:"3620E011C / HD1 DEPENTANIZER REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0401",MR_DESCR:"HD1 DEPENTANIZER REBOILER脱重塔空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-22",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:550,TAG:"3620E011D",TAG_DESCR:"HD1 DEPENTANIZER REBOILER",TAG_DESCR_SHORT:"3620E011D / HD1 DEPENTANIZER REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0401",MR_DESCR:"HD1 DEPENTANIZER REBOILER脱重塔空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-22",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:551,TAG:"3620E011E",TAG_DESCR:"HD1 DEPENTANIZER REBOILER",TAG_DESCR_SHORT:"3620E011E / HD1 DEPENTANIZER REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0401",MR_DESCR:"HD1 DEPENTANIZER REBOILER脱重塔空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-22",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:552,TAG:"3620E011F",TAG_DESCR:"HD1 DEPENTANIZER REBOILER",TAG_DESCR_SHORT:"3620E011F / HD1 DEPENTANIZER REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0401",MR_DESCR:"HD1 DEPENTANIZER REBOILER脱重塔空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-22",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:553,TAG:"3620E011G",TAG_DESCR:"HD1 DEPENTANIZER REBOILER",TAG_DESCR_SHORT:"3620E011G / HD1 DEPENTANIZER REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0401",MR_DESCR:"HD1 DEPENTANIZER REBOILER脱重塔空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-22",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:554,TAG:"3620E011H",TAG_DESCR:"HD1 DEPENTANIZER REBOILER",TAG_DESCR_SHORT:"3620E011H / HD1 DEPENTANIZER REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0401",MR_DESCR:"HD1 DEPENTANIZER REBOILER脱重塔空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-22",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:555,TAG:"3620E011I",TAG_DESCR:"HD1 DEPENTANIZER REBOILER",TAG_DESCR_SHORT:"3620E011I / HD1 DEPENTANIZER REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0401",MR_DESCR:"HD1 DEPENTANIZER REBOILER脱重塔空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-22",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:556,TAG:"3620E011J",TAG_DESCR:"HD1 DEPENTANIZER REBOILER",TAG_DESCR_SHORT:"3620E011J / HD1 DEPENTANIZER REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0401",MR_DESCR:"HD1 DEPENTANIZER REBOILER脱重塔空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-22",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:557,TAG:"3620E011K",TAG_DESCR:"HD1 DEPENTANIZER REBOILER",TAG_DESCR_SHORT:"3620E011K / HD1 DEPENTANIZER REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0401",MR_DESCR:"HD1 DEPENTANIZER REBOILER脱重塔空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-22",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:558,TAG:"3620E011L",TAG_DESCR:"HD1 DEPENTANIZER REBOILER",TAG_DESCR_SHORT:"3620E011L / HD1 DEPENTANIZER REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0401",MR_DESCR:"HD1 DEPENTANIZER REBOILER脱重塔空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-22",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:559,TAG:"3620E011M",TAG_DESCR:"HD1 DEPENTANIZER REBOILER",TAG_DESCR_SHORT:"3620E011M / HD1 DEPENTANIZER REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0401",MR_DESCR:"HD1 DEPENTANIZER REBOILER脱重塔空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-22",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:560,TAG:"3620E011N",TAG_DESCR:"HD1 DEPENTANIZER REBOILER",TAG_DESCR_SHORT:"3620E011N / HD1 DEPENTANIZER REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0401",MR_DESCR:"HD1 DEPENTANIZER REBOILER脱重塔空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-22",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:561,TAG:"3620M001",TAG_DESCR:"HD1 DEPENTANIZER / RERUN TOWER DESUPERHEATER",TAG_DESCR_SHORT:"3620M001 / HD1 DEPENTANIZER  RERUN TOWER DESUPERH...",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU - Static",MR_NO:"TBD",MR_DESCR:"HD1 DEPENTANIZER / RERUN TOWER DESUPERHEATER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:562,TAG:"3620T001-I",TAG_DESCR:"HD1 DEPENTANIZER INTERNAL",TAG_DESCR_SHORT:"3620T001-I / HD1 DEPENTANIZER INTERNAL",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0102",MR_DESCR:"HD1 DEPENTANIZER INTERNAL一段加氢脱戊烷塔内件",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-15",LOA_PLAN:"2022-04-05",LOA_FORECAST:"2022-05-22",LOA_ACTUAL:"2022-05-30",PO_PLAN:"2022-05-17",PO_FORECAST:"2022-07-03",PO_ACTUAL:"2022-08-25",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:563,TAG:"3620T011-I",TAG_DESCR:"RERUN TOWER INTERNAL",TAG_DESCR_SHORT:"3620T011-I / RERUN TOWER INTERNAL",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0102",MR_DESCR:"RERUN TOWER INTERNAL脱重塔内件",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-15",LOA_PLAN:"2022-04-05",LOA_FORECAST:"2022-05-22",LOA_ACTUAL:"2022-05-28",PO_PLAN:"2022-05-17",PO_FORECAST:"2022-07-03",PO_ACTUAL:"2022-08-25",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:442,SN:1,TAG:"3620E013A",TAG_DESCR:"RERUN TOWER REBOILERS",TAG_DESCR_SHORT:"3620E013A / RERUN TOWER REBOILERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0403",MR_DESCR:"RERUN TOWER REBOILERS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-25",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:443,SN:2,TAG:"3620E013B",TAG_DESCR:"RERUN TOWER REBOILERS",TAG_DESCR_SHORT:"3620E013B / RERUN TOWER REBOILERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0403",MR_DESCR:"RERUN TOWER REBOILERS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-25",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:444,SN:3,TAG:"3620E014",TAG_DESCR:"C10 PRODUCT COOLER",TAG_DESCR_SHORT:"3620E014 / C10 PRODUCT COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0403",MR_DESCR:"C10+ PRODUCT COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-25",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:445,SN:4,TAG:"3620D011",TAG_DESCR:"RERUN TOWER REFLUX DRUM",TAG_DESCR_SHORT:"3620D011 / RERUN TOWER REFLUX DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0301",MR_DESCR:"RERUN TOWER REFLUX DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-11",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:446,SN:5,TAG:"3620E012",TAG_DESCR:"RERUN TOWER TRIM CONDENSER",TAG_DESCR_SHORT:"3620E012 / RERUN TOWER TRIM CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0403",MR_DESCR:"RERUN TOWER TRIM CONDENSER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-25",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:447,SN:6,TAG:"3620E010B",TAG_DESCR:"HD1 DEPENTANIZER FEEDRERUN TOWER BOTTOM EXCHANGER",TAG_DESCR_SHORT:"3620E010B / HD1 DEPENTANIZER FEEDRERUN TOWER BOTT...",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0403",MR_DESCR:"HD1 DEPENTANIZER FEED/RERUN TOWER BOTTOM EXCHANGER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-25",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:448,SN:7,TAG:"3620E010A",TAG_DESCR:"HD1 DEPENTANIZER FEEDRERUN TOWER BOTTOM EXCHANGER",TAG_DESCR_SHORT:"3620E010A / HD1 DEPENTANIZER FEEDRERUN TOWER BOTT...",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0403",MR_DESCR:"HD1 DEPENTANIZER FEED/RERUN TOWER BOTTOM EXCHANGER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-25",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:449,SN:8,TAG:"3630E003",TAG_DESCR:"HD2 FEEDEFFLUENT EXCHANGERS",TAG_DESCR_SHORT:"3630E003 / HD2 FEEDEFFLUENT EXCHANGERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0403",MR_DESCR:"HD2 FEED/EFFLUENT EXCHANGERS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-25",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:450,SN:9,TAG:"3620M011",TAG_DESCR:"RERUN TOWER VACUUM PACKAGE",TAG_DESCR_SHORT:"3620M011 / RERUN TOWER VACUUM PACKAGE",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-RE-R-E-SL02",MR_DESCR:"RERUN TOWER VACUUM PACKAGE脱重塔真空泵/蒸汽喷射器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-03-08",MR_FORECAST:"2022-03-08",MR_ACTUAL:"2022-02-14",LOA_PLAN:"2022-07-01",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-08-12",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-08-29",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:451,SN:10,TAG:"3630D002",TAG_DESCR:"HD2 COLD SEPARATOR",TAG_DESCR_SHORT:"3630D002 / HD2 COLD SEPARATOR",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0301",MR_DESCR:"HD2 COLD SEPARATOR",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-11",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:452,SN:11,TAG:"3630E001A",TAG_DESCR:"ABC",TAG_DESCR_SHORT:"3630E001A / ABC",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU - Heat Exchangers",MR_NO:"75231-P1-1278-EQ-R-E-0402",MR_DESCR:"HD2 FEED/EFFLUENT EXCHANGERS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-04-20",LOA_PLAN:"2022-07-07",LOA_FORECAST:"2022-08-28",LOA_ACTUAL:"2022-08-29",PO_PLAN:"2022-08-18",PO_FORECAST:"2022-10-09",PO_ACTUAL:"2022-09-16",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:453,SN:12,TAG:"3630E001B",TAG_DESCR:"ABC",TAG_DESCR_SHORT:"3630E001B / ABC",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU - Heat Exchangers",MR_NO:"75231-P1-1278-EQ-R-E-0402",MR_DESCR:"HD2 FEED/EFFLUENT EXCHANGERS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-04-20",LOA_PLAN:"2022-07-07",LOA_FORECAST:"2022-08-28",LOA_ACTUAL:"2022-08-29",PO_PLAN:"2022-08-18",PO_FORECAST:"2022-10-09",PO_ACTUAL:"2022-09-16",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:454,SN:13,TAG:"3630E001C",TAG_DESCR:"ABC",TAG_DESCR_SHORT:"3630E001C / ABC",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU - Heat Exchangers",MR_NO:"75231-P1-1278-EQ-R-E-0402",MR_DESCR:"HD2 FEED/EFFLUENT EXCHANGERS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-04-20",LOA_PLAN:"2022-07-07",LOA_FORECAST:"2022-08-28",LOA_ACTUAL:"2022-08-29",PO_PLAN:"2022-08-18",PO_FORECAST:"2022-10-09",PO_ACTUAL:"2022-09-16",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:455,SN:14,TAG:"3630E001D",TAG_DESCR:"CrMoHD2 FEEDEFFLUENT EXCHANGERS DE",TAG_DESCR_SHORT:"3630E001D / CrMoHD2 FEEDEFFLUENT EXCHANGERS DE",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0402",MR_DESCR:"双相钢/CrMoHD2 FEED/EFFLUENT EXCHANGERS二段加氢进出料换热器D/E",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-04-20",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-08-31",LOA_ACTUAL:"2022-08-29",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-10-12",PO_ACTUAL:"2022-09-16",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:456,SN:15,TAG:"3630E001E",TAG_DESCR:"CrMoHD2 FEEDEFFLUENT EXCHANGERS DE",TAG_DESCR_SHORT:"3630E001E / CrMoHD2 FEEDEFFLUENT EXCHANGERS DE",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0402",MR_DESCR:"双相钢/CrMoHD2 FEED/EFFLUENT EXCHANGERS二段加氢进出料换热器D/E",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-04-20",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-08-31",LOA_ACTUAL:"2022-08-29",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-10-12",PO_ACTUAL:"2022-09-16",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:457,SN:16,TAG:"3630R001",TAG_DESCR:"HD2 REACTOR",TAG_DESCR_SHORT:"3630R001 / HD2 REACTOR",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0201",MR_DESCR:"HD2 REACTOR二段加氢反应器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-03-30",MR_FORECAST:"2022-03-30",MR_ACTUAL:"2022-02-07",LOA_PLAN:"2022-07-29",LOA_FORECAST:"2022-07-31",LOA_ACTUAL:"2022-07-31",PO_PLAN:"2022-09-09",PO_FORECAST:"2022-09-11",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:458,SN:17,TAG:"3630F001",TAG_DESCR:"HD2 HEATER",TAG_DESCR_SHORT:"3630F001 / HD2 HEATER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-FU-R-E-0801",MR_DESCR:"HD2 HEATER 加热炉",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-01-26",MR_FORECAST:"2022-01-26",MR_ACTUAL:"2022-01-21",LOA_PLAN:"2022-05-22",LOA_FORECAST:"2022-06-08",LOA_ACTUAL:"2022-06-17",PO_PLAN:"2022-07-03",PO_FORECAST:"2022-07-20",PO_ACTUAL:"2022-07-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:459,SN:18,TAG:"3630D001",TAG_DESCR:"HD2 HOT SEPARATOR",TAG_DESCR_SHORT:"3630D001 / HD2 HOT SEPARATOR",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0301",MR_DESCR:"HD2 HOT SEPARATOR",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-11",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:460,SN:19,TAG:"3630P003B",TAG_DESCR:"HD2 RECYCLE PUMPS",TAG_DESCR_SHORT:"3630P003B / HD2 RECYCLE PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-RE-R-E-0620",MR_DESCR:"HD2 RECYCLE PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-02-18",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:461,SN:20,TAG:"3630P003A",TAG_DESCR:"HD2 RECYCLE PUMPS",TAG_DESCR_SHORT:"3630P003A / HD2 RECYCLE PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-RE-R-E-0620",MR_DESCR:"HD2 RECYCLE PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-02-18",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:462,SN:21,TAG:"3630P002B",TAG_DESCR:"C10 PRODUCT PUMPS",TAG_DESCR_SHORT:"3630P002B / C10 PRODUCT PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-RE-R-E-0621",MR_DESCR:"C10+ PRODUCT PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:463,SN:22,TAG:"3630P002A",TAG_DESCR:"C10 PRODUCT PUMPS",TAG_DESCR_SHORT:"3630P002A / C10 PRODUCT PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-RE-R-E-0621",MR_DESCR:"C10+ PRODUCT PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:464,SN:23,TAG:"3630P001B",TAG_DESCR:"HD2 FEED PUMPS",TAG_DESCR_SHORT:"3630P001B / HD2 FEED PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-RE-R-E-0620",MR_DESCR:"HD2 FEED PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-02-18",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:465,SN:24,TAG:"3630P001A",TAG_DESCR:"HD2 FEED PUMPS",TAG_DESCR_SHORT:"3630P001A / HD2 FEED PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-RE-R-E-0620",MR_DESCR:"HD2 FEED PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-02-18",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:466,SN:25,TAG:"3620P011B",TAG_DESCR:"RERUN TOWER REFLUX PUMPS",TAG_DESCR_SHORT:"3620P011B / RERUN TOWER REFLUX PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-RE-R-E-0621",MR_DESCR:"RERUN TOWER REFLUX PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:467,SN:26,TAG:"3620P011A",TAG_DESCR:"RERUN TOWER REFLUX PUMPS",TAG_DESCR_SHORT:"3620P011A / RERUN TOWER REFLUX PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-RE-R-E-0621",MR_DESCR:"RERUN TOWER REFLUX PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:468,SN:27,TAG:"3620P012B",TAG_DESCR:"C10 PRODUCT PUMPS",TAG_DESCR_SHORT:"3620P012B / C10 PRODUCT PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-RE-R-E-0620",MR_DESCR:"C10+ PRODUCT PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-02-18",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:469,SN:28,TAG:"3620P012A",TAG_DESCR:"C10 PRODUCT PUMPS",TAG_DESCR_SHORT:"3620P012A / C10 PRODUCT PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-RE-R-E-0620",MR_DESCR:"C10+ PRODUCT PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-02-18",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:470,SN:29,TAG:"3620T011",TAG_DESCR:"HD1 COLD SEPARATOR",TAG_DESCR_SHORT:"3620T011 / HD1 COLD SEPARATOR",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0101",MR_DESCR:"HD1 COLD SEPARATOR脱重塔",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2021-11-30",MR_FORECAST:"2021-11-30",MR_ACTUAL:"2021-12-03",LOA_PLAN:"2022-03-05",LOA_FORECAST:"2022-03-21",LOA_ACTUAL:"2022-03-23",PO_PLAN:"2022-04-16",PO_FORECAST:"2022-05-02",PO_ACTUAL:"2022-04-13",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:471,SN:30,TAG:"3630C001B",TAG_DESCR:"GHU COMRESSORS",TAG_DESCR_SHORT:"3630C001B / GHU COMRESSORS",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-RE-R-E-SL01",MR_DESCR:"GHU COMRESSORS 新氢压缩机/循环氢压缩机联合机组",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-02-27",MR_FORECAST:"2022-02-27",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-06-28",LOA_FORECAST:"2022-06-07",LOA_ACTUAL:"2022-06-10",PO_PLAN:"2022-08-09",PO_FORECAST:"2022-07-19",PO_ACTUAL:"2022-08-25",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:472,SN:31,TAG:"3630C001A",TAG_DESCR:"GHU COMRESSORS",TAG_DESCR_SHORT:"3630C001A / GHU COMRESSORS",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-RE-R-E-SL01",MR_DESCR:"GHU COMRESSORS 新氢压缩机/循环氢压缩机联合机组",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-02-27",MR_FORECAST:"2022-02-27",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-06-28",LOA_FORECAST:"2022-06-07",LOA_ACTUAL:"2022-06-10",PO_PLAN:"2022-08-09",PO_FORECAST:"2022-07-19",PO_ACTUAL:"2022-08-25",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:473,SN:32,TAG:"3630E004",TAG_DESCR:"HD2 POST CONDENSER",TAG_DESCR_SHORT:"3630E004 / HD2 POST CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0403",MR_DESCR:"HD2 POST CONDENSER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-25",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:474,SN:33,TAG:"3630D004",TAG_DESCR:"RECYCLE GAS COMPRESSOR KO DRUM",TAG_DESCR_SHORT:"3630D004 / RECYCLE GAS COMPRESSOR KO DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0301",MR_DESCR:"RECYCLE GAS COMPRESSOR K.O DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-11",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:475,SN:34,TAG:"3610E011",TAG_DESCR:"H2 MAKE UP COMPRESSOR SPILL BACK COOLER",TAG_DESCR_SHORT:"3610E011 / H2 MAKE UP COMPRESSOR SPILL BACK COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0403",MR_DESCR:"H2 MAKE UP COMPRESSOR SPILL BACK COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-25",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:476,SN:35,TAG:"3640E011",TAG_DESCR:"DETOLUENIZER AIR CONDENSER",TAG_DESCR_SHORT:"3640E011 / DETOLUENIZER AIR CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU - Heat Exchangers",MR_NO:"TBD",MR_DESCR:"DETOLUENIZER AIR CONDENSER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:477,SN:36,TAG:"3640E001",TAG_DESCR:"HD2 DEPENTANIZER AIR CONDENSER",TAG_DESCR_SHORT:"3640E001 / HD2 DEPENTANIZER AIR CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU - Heat Exchangers",MR_NO:"TBD",MR_DESCR:"HD2 DEPENTANIZER AIR CONDENSER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:478,SN:37,TAG:"3620E011",TAG_DESCR:"RERUN TOWER AIR CONDENSER",TAG_DESCR_SHORT:"3620E011 / RERUN TOWER AIR CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU - Heat Exchangers",MR_NO:"TBD",MR_DESCR:"RERUN TOWER AIR CONDENSER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:479,SN:38,TAG:"3630E002",TAG_DESCR:"HD2 REACTOR EFFLUENT AIR CONDENSER",TAG_DESCR_SHORT:"3630E002 / HD2 REACTOR EFFLUENT AIR CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU - Heat Exchangers",MR_NO:"TBD",MR_DESCR:"HD2 REACTOR EFFLUENT AIR CONDENSER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:480,SN:39,TAG:"3610E003",TAG_DESCR:"HD1 RECYCLE AIR COOLER",TAG_DESCR_SHORT:"3610E003 / HD1 RECYCLE AIR COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU - Heat Exchangers",MR_NO:"TBD",MR_DESCR:"HD1 RECYCLE AIR COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:481,SN:40,TAG:"3620E001",TAG_DESCR:"HD1 DEPENTANIZER AIR CONDENSER",TAG_DESCR_SHORT:"3620E001 / HD1 DEPENTANIZER AIR CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU - Heat Exchangers",MR_NO:"TBD",MR_DESCR:"HD1 DEPENTANIZER AIR CONDENSER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:482,SN:41,TAG:"3697D001",TAG_DESCR:"GHU FLARE KO DRUM",TAG_DESCR_SHORT:"3697D001 / GHU FLARE KO DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0301",MR_DESCR:"GHU FLARE KO DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-11",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:483,SN:42,TAG:"3697P001",TAG_DESCR:"HD2 LDBSCN PUMPS",TAG_DESCR_SHORT:"3697P001 / HD2 LDBSCN PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-RE-R-E-0621",MR_DESCR:"HD2 LDBSCN PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:484,SN:43,TAG:"3697E001",TAG_DESCR:"GHU WARM BLOWDOWN COOLER",TAG_DESCR_SHORT:"3697E001 / GHU WARM BLOWDOWN COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0403",MR_DESCR:"GHU WARM BLOWDOWN COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-25",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:485,SN:44,TAG:"3610D001",TAG_DESCR:"FEED COALESCER",TAG_DESCR_SHORT:"3610D001 / FEED COALESCER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0301",MR_DESCR:"FEED COALESCER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-11",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:486,SN:45,TAG:"3610FIL001A",TAG_DESCR:"",TAG_DESCR_SHORT:"3610FIL001A / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0501",MR_DESCR:"进料过滤器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-11",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-08-04",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-09-15",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:487,SN:46,TAG:"3610FIL001B",TAG_DESCR:"",TAG_DESCR_SHORT:"3610FIL001B / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0501",MR_DESCR:"进料过滤器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-11",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-08-04",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-09-15",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:488,SN:47,TAG:"3620T001",TAG_DESCR:"HD1 HOT SEPARATOR",TAG_DESCR_SHORT:"3620T001 / HD1 HOT SEPARATOR",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0101",MR_DESCR:"HD1 HOT SEPARATOR一段加氢脱戊烷塔",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2021-11-30",MR_FORECAST:"2021-11-30",MR_ACTUAL:"2021-12-03",LOA_PLAN:"2022-03-05",LOA_FORECAST:"2022-03-21",LOA_ACTUAL:"2022-03-23",PO_PLAN:"2022-04-16",PO_FORECAST:"2022-05-02",PO_ACTUAL:"2022-04-13",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:489,SN:48,TAG:"3620E003",TAG_DESCR:"HD1 DEPENTANIZER REBOILER",TAG_DESCR_SHORT:"3620E003 / HD1 DEPENTANIZER REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0403",MR_DESCR:"HD1 DEPENTANIZER REBOILER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-25",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:490,SN:49,TAG:"3610E002",TAG_DESCR:"HD1 STARTUP HEATER",TAG_DESCR_SHORT:"3610E002 / HD1 STARTUP HEATER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0403",MR_DESCR:"HD1 START-UP HEATER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-25",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:491,SN:50,TAG:"3610E004",TAG_DESCR:"HD1 QUENCH COOLER",TAG_DESCR_SHORT:"3610E004 / HD1 QUENCH COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0403",MR_DESCR:"HD1 QUENCH COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-25",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:492,SN:51,TAG:"3620E002",TAG_DESCR:"CrMoHD1 DEPENTANIZER TRIM CONDENSER",TAG_DESCR_SHORT:"3620E002 / CrMoHD1 DEPENTANIZER TRIM CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0402",MR_DESCR:"双相钢/CrMoHD1 DEPENTANIZER TRIM CONDENSER一段加氢脱戊烷塔后冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-04-20",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-08-31",LOA_ACTUAL:"2022-08-29",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-10-12",PO_ACTUAL:"2022-09-16",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:493,SN:52,TAG:"3620D001",TAG_DESCR:"HD1 DEPENTANIZER REFLUX DRUM",TAG_DESCR_SHORT:"3620D001 / HD1 DEPENTANIZER REFLUX DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0301",MR_DESCR:"HD1 DEPENTANIZER REFLUX DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-11",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:494,SN:53,TAG:"3620E004",TAG_DESCR:"HD1 C5 CUT COOLER",TAG_DESCR_SHORT:"3620E004 / HD1 C5 CUT COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0403",MR_DESCR:"HD1 C5 CUT COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-25",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:495,SN:54,TAG:"3610E001",TAG_DESCR:"CS HD1 FEEDEFFLUENT EXCHANGER",TAG_DESCR_SHORT:"3610E001 / CS HD1 FEEDEFFLUENT EXCHANGER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0403",MR_DESCR:"管壳换热器二（CS） HD1 FEED/EFFLUENT EXCHANGER一段加氢进出料换热器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-25",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:496,SN:55,TAG:"3610E005",TAG_DESCR:"CrMoHD1 CONDENSER",TAG_DESCR_SHORT:"3610E005 / CrMoHD1 CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0402",MR_DESCR:"双相钢/CrMoHD1 CONDENSER一段加氢热分离冷却器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-04-20",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-08-31",LOA_ACTUAL:"2022-08-29",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-10-12",PO_ACTUAL:"2022-09-16",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:497,SN:56,TAG:"3610D003",TAG_DESCR:"HD1 COLD SEPARATOR",TAG_DESCR_SHORT:"3610D003 / HD1 COLD SEPARATOR",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0301",MR_DESCR:"HD1 COLD SEPARATOR",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-11",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:498,SN:57,TAG:"3610E010",TAG_DESCR:"CrMoHD1 CATALYST TREATMENT GAS HEATER SHP",TAG_DESCR_SHORT:"3610E010 / CrMoHD1 CATALYST TREATMENT GAS HEATER SHP",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0402",MR_DESCR:"双相钢/CrMoHD1 CATALYST TREATMENT GAS  HEATER（SHP）一段加氢催化剂处理气加热器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-04-20",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-08-31",LOA_ACTUAL:"2022-08-29",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-10-12",PO_ACTUAL:"2022-09-16",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:499,SN:58,TAG:"3640P012B",TAG_DESCR:"HDBSCN PRODUCT PUMPS",TAG_DESCR_SHORT:"3640P012B / HDBSCN PRODUCT PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-RE-R-E-0620",MR_DESCR:"HDBSCN PRODUCT PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-02-18",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:500,SN:59,TAG:"3640P012A",TAG_DESCR:"HDBSCN PRODUCT PUMPS",TAG_DESCR_SHORT:"3640P012A / HDBSCN PRODUCT PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-RE-R-E-0620",MR_DESCR:"HDBSCN PRODUCT PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-02-18",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:501,SN:60,TAG:"3640P011B",TAG_DESCR:"HD2 DEPENTANIZER REFLUX PUMPS",TAG_DESCR_SHORT:"3640P011B / HD2 DEPENTANIZER REFLUX PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-RE-R-E-0621",MR_DESCR:"HD2 DEPENTANIZER REFLUX PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:502,SN:61,TAG:"3640P011A",TAG_DESCR:"HD2 DEPENTANIZER REFLUX PUMPS",TAG_DESCR_SHORT:"3640P011A / HD2 DEPENTANIZER REFLUX PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-RE-R-E-0621",MR_DESCR:"HD2 DEPENTANIZER REFLUX PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:503,SN:62,TAG:"3640P001B",TAG_DESCR:"HD2 COLD RECYCLE PUMPS",TAG_DESCR_SHORT:"3640P001B / HD2 COLD RECYCLE PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-RE-R-E-0621",MR_DESCR:"HD2 COLD RECYCLE PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:504,SN:63,TAG:"3640P001A",TAG_DESCR:"HD2 COLD RECYCLE PUMPS",TAG_DESCR_SHORT:"3640P001A / HD2 COLD RECYCLE PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-RE-R-E-0621",MR_DESCR:"HD2 COLD RECYCLE PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:505,SN:64,TAG:"3640P005B",TAG_DESCR:"HD2 RECYCLE PUMPS",TAG_DESCR_SHORT:"3640P005B / HD2 RECYCLE PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-RE-R-E-0621",MR_DESCR:"HD2 RECYCLE PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:506,SN:65,TAG:"3640P005A",TAG_DESCR:"HD2 RECYCLE PUMPS",TAG_DESCR_SHORT:"3640P005A / HD2 RECYCLE PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-RE-R-E-0621",MR_DESCR:"HD2 RECYCLE PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:507,SN:66,TAG:"3610P002B",TAG_DESCR:"HD1 RECYCLE PUMPS",TAG_DESCR_SHORT:"3610P002B / HD1 RECYCLE PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-RE-R-E-0620",MR_DESCR:"HD1 RECYCLE PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-02-18",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:508,SN:67,TAG:"3610P002A",TAG_DESCR:"HD1 RECYCLE PUMPS",TAG_DESCR_SHORT:"3610P002A / HD1 RECYCLE PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-RE-R-E-0620",MR_DESCR:"HD1 RECYCLE PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-02-18",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:509,SN:68,TAG:"3699A001",TAG_DESCR_SHORT:"3699A001 / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:510,SN:69,TAG:"3620P002B",TAG_DESCR:"HD1 C5 CUT PUMPS",TAG_DESCR_SHORT:"3620P002B / HD1 C5 CUT PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-RE-R-E-0620",MR_DESCR:"HD1 C5 CUT PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-02-18",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:511,SN:70,TAG:"3620P002A",TAG_DESCR:"HD1 C5 CUT PUMPS",TAG_DESCR_SHORT:"3620P002A / HD1 C5 CUT PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-RE-R-E-0620",MR_DESCR:"HD1 C5 CUT PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-02-18",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:512,SN:71,TAG:"3620P001B",TAG_DESCR:"GHU HD1 DEPENTANIZER REFLUX PUMPS",TAG_DESCR_SHORT:"3620P001B / GHU HD1 DEPENTANIZER REFLUX PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-RE-R-E-0621",MR_DESCR:"GHU二类离心泵 HD1 DEPENTANIZER REFLUX PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:513,SN:72,TAG:"3620P001A",TAG_DESCR:"GHU HD1 DEPENTANIZER REFLUX PUMPS",TAG_DESCR_SHORT:"3620P001A / GHU HD1 DEPENTANIZER REFLUX PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-RE-R-E-0621",MR_DESCR:"GHU二类离心泵 HD1 DEPENTANIZER REFLUX PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:514,SN:73,TAG:"3698D001",TAG_DESCR:"HP STEAM CONDENSATE FLASH DRUM",TAG_DESCR_SHORT:"3698D001 / HP STEAM CONDENSATE FLASH DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0301",MR_DESCR:"HP STEAM CONDENSATE FLASH DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-11",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:515,SN:74,TAG:"3610P001B",TAG_DESCR:"HD1 FEED PUMPS",TAG_DESCR_SHORT:"3610P001B / HD1 FEED PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-RE-R-E-0620",MR_DESCR:"一类离心泵 HD1 FEED PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-02-18",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:516,SN:75,TAG:"3610P001A",TAG_DESCR:"HD1 FEED PUMPS",TAG_DESCR_SHORT:"3610P001A / HD1 FEED PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-RE-R-E-0620",MR_DESCR:"一类离心泵 HD1 FEED PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-02-18",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:517,SN:76,TAG:"3640T011",TAG_DESCR:"RERUN TOWER REFLUX DRUM",TAG_DESCR_SHORT:"3640T011 / RERUN TOWER REFLUX DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0101",MR_DESCR:"RERUN TOWER REFLUX DRUM脱甲苯塔",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2021-11-30",MR_FORECAST:"2021-11-30",MR_ACTUAL:"2021-12-03",LOA_PLAN:"2022-03-05",LOA_FORECAST:"2022-03-21",LOA_ACTUAL:"2022-03-23",PO_PLAN:"2022-04-16",PO_FORECAST:"2022-05-02",PO_ACTUAL:"2022-04-13",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:518,SN:77,TAG:"3640T001",TAG_DESCR:"HD1 DEPENTANIZER REFLUX DRUM",TAG_DESCR_SHORT:"3640T001 / HD1 DEPENTANIZER REFLUX DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0101",MR_DESCR:"HD1 DEPENTANIZER REFLUX DRUM二段加氢脱戊烷塔",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2021-11-30",MR_FORECAST:"2021-11-30",MR_ACTUAL:"2021-12-03",LOA_PLAN:"2022-03-05",LOA_FORECAST:"2022-03-21",LOA_ACTUAL:"2022-03-23",PO_PLAN:"2022-04-16",PO_FORECAST:"2022-05-02",PO_ACTUAL:"2022-04-13",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:519,SN:78,TAG:"3640E012",TAG_DESCR:"DETOLUENIZER REBOILER",TAG_DESCR_SHORT:"3640E012 / DETOLUENIZER REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0403",MR_DESCR:"DETOLUENIZER REBOILER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-25",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:520,SN:80,TAG:"3610R001",TAG_DESCR:"HD1 REACTOR",TAG_DESCR_SHORT:"3610R001 / HD1 REACTOR",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0201",MR_DESCR:"HD1 REACTOR一段加氢反应器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-03-30",MR_FORECAST:"2022-03-30",MR_ACTUAL:"2022-02-07",LOA_PLAN:"2022-07-29",LOA_FORECAST:"2022-07-31",LOA_ACTUAL:"2022-07-31",PO_PLAN:"2022-09-09",PO_FORECAST:"2022-09-11",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:521,SN:81,TAG:"3610D002",TAG_DESCR:"HD1 HOT SEPARATOR",TAG_DESCR_SHORT:"3610D002 / HD1 HOT SEPARATOR",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0301",MR_DESCR:"HD1 HOT SEPARATOR",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-11",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:522,SN:82,TAG:"3640T005",TAG_DESCR:"HD2 HOT SEPARATOR",TAG_DESCR_SHORT:"3640T005 / HD2 HOT SEPARATOR",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0101",MR_DESCR:"HD2 HOT SEPARATOR二段加氢脱戊烷塔侧线气提塔",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2021-11-30",MR_FORECAST:"2021-11-30",MR_ACTUAL:"2021-12-03",LOA_PLAN:"2022-03-05",LOA_FORECAST:"2022-03-21",LOA_ACTUAL:"2022-03-23",PO_PLAN:"2022-04-16",PO_FORECAST:"2022-05-02",PO_ACTUAL:"2022-04-13",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:523,SN:83,TAG:"3640E002",TAG_DESCR:"HD2 DEPENTANIZER TRIM CONDENSER",TAG_DESCR_SHORT:"3640E002 / HD2 DEPENTANIZER TRIM CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0403",MR_DESCR:"HD2 DEPENTANIZER TRIM CONDENSER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-25",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:524,SN:84,TAG:"3640E003",TAG_DESCR:"HD2 DEPENTANIZER REBOILER",TAG_DESCR_SHORT:"3640E003 / HD2 DEPENTANIZER REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0403",MR_DESCR:"HD2 DEPENTANIZER REBOILER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-25",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:525,SN:85,TAG:"3640E005",TAG_DESCR:"HD2 DEPENTANIZER SIDE STRIPPER REBOILER",TAG_DESCR_SHORT:"3640E005 / HD2 DEPENTANIZER SIDE STRIPPER REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0403",MR_DESCR:"HD2 DEPENTANIZER SIDE STRIPPER REBOILER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-25",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:526,SN:86,TAG:"3640E006",TAG_DESCR:"HD2 LDBSCN COOLER",TAG_DESCR_SHORT:"3640E006 / HD2 LDBSCN COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0403",MR_DESCR:"HD2 LDBSCN COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-25",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:527,SN:87,TAG:"3640E013",TAG_DESCR:"BTHC PRODUCT COOLER",TAG_DESCR_SHORT:"3640E013 / BTHC PRODUCT COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0403",MR_DESCR:"BTHC PRODUCT COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-25",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:528,SN:88,TAG:"3640E015",TAG_DESCR:"HDBSCN PRODUCT COOLER",TAG_DESCR_SHORT:"3640E015 / HDBSCN PRODUCT COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0403",MR_DESCR:"HDBSCN PRODUCT COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-25",LOA_PLAN:"2022-08-21",LOA_FORECAST:"2022-07-10",LOA_ACTUAL:"2022-07-20",PO_PLAN:"2022-10-02",PO_FORECAST:"2022-08-21",PO_ACTUAL:"2022-08-26",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:529,SN:89,TAG:"3640E014",TAG_DESCR:"DETOLUENIZER BOTTOM/HD2 FEED EXCHANGER",TAG_DESCR_SHORT:"3640E014 / DETOLUENIZER BOTTOMHD2 FEED EXCHANGER",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU - Heat Exchangers",MR_NO:"TBD",MR_DESCR:"DETOLUENIZER BOTTOM/HD2 FEED EXCHANGER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:530,SN:90,TAG:"3640D011",TAG_DESCR:"DETOLUENIZER REFLUX DRUM",TAG_DESCR_SHORT:"3640D011 / DETOLUENIZER REFLUX DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0301",MR_DESCR:"DETOLUENIZER REFLUX DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-11",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:531,SN:91,TAG:"3640D001",TAG_DESCR:"HD2 DEPENTANIZER REFLUX DRUM",TAG_DESCR_SHORT:"3640D001 / HD2 DEPENTANIZER REFLUX DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"GHU",MR_NO:"75231-P1-1278-EQ-R-E-0301",MR_DESCR:"HD2 DEPENTANIZER REFLUX DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-11",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1433,SN:1,TAG:"2544P015B",TAG_DESCR:"DEETHANIZER REFLUX PUMPS",TAG_DESCR_SHORT:"2544P015B / DEETHANIZER REFLUX PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0620",MR_DESCR:"DEETHANIZER REFLUX PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1434,SN:2,TAG:"2544P015A",TAG_DESCR:"DEETHANIZER REFLUX PUMPS",TAG_DESCR_SHORT:"2544P015A / DEETHANIZER REFLUX PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0620",MR_DESCR:"DEETHANIZER REFLUX PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1435,SN:3,TAG:"2544D015",TAG_DESCR:"DEETHANIZER REFLUX DRUM",TAG_DESCR_SHORT:"2544D015 / DEETHANIZER REFLUX DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0302",MR_DESCR:"DEETHANIZER REFLUX DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1436,SN:4,TAG:"2544E010B",TAG_DESCR:"DEETHANIZER REBOILER",TAG_DESCR_SHORT:"2544E010B / DEETHANIZER REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0408",MR_DESCR:"DEETHANIZER REBOILER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-19",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-30",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1437,SN:5,TAG:"2544E010A",TAG_DESCR:"DEETHANIZER REBOILER",TAG_DESCR_SHORT:"2544E010A / DEETHANIZER REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0408",MR_DESCR:"DEETHANIZER REBOILER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-19",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-30",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1438,SN:6,TAG:"2544E015",TAG_DESCR:"DEETHANIZER CONDENSER CIV",TAG_DESCR_SHORT:"2544E015 / DEETHANIZER CONDENSER CIV",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0402",MR_DESCR:"DEETHANIZER CONDENSER脱乙烷塔冷凝器 CIV板翅换热器（一）",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",MR_PLAN:"2021-11-18",MR_FORECAST:"2021-11-18",MR_ACTUAL:"2021-11-18",LOA_PLAN:"2022-03-10",LOA_FORECAST:"2022-03-18",LOA_ACTUAL:"2022-03-16",PO_PLAN:"2022-04-21",PO_FORECAST:"2022-04-29",PO_ACTUAL:"2022-04-02",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1439,SN:7,TAG:"2544P020B",TAG_DESCR:"DEETHANIZER BOTTOMS PUMPS",TAG_DESCR_SHORT:"2544P020B / DEETHANIZER BOTTOMS PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0620",MR_DESCR:"DEETHANIZER BOTTOMS PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1440,SN:8,TAG:"2544P020A",TAG_DESCR:"DEETHANIZER BOTTOMS PUMPS",TAG_DESCR_SHORT:"2544P020A / DEETHANIZER BOTTOMS PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0620",MR_DESCR:"DEETHANIZER BOTTOMS PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1441,SN:9,TAG:"2544T001",TAG_DESCR:"DEETHANIZER 304L SS 38 43",TAG_DESCR_SHORT:"2544T001 / DEETHANIZER 304L SS 38 43",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL05",MR_DESCR:"DEETHANIZER脱乙烷塔 （壳体304L SS、内径3.8、高度43、保冷）",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",MR_PLAN:"2021-11-23",MR_FORECAST:"2021-11-23",MR_ACTUAL:"2021-11-08",LOA_PLAN:"2022-02-18",LOA_FORECAST:"2022-02-03",LOA_ACTUAL:"2022-02-14",PO_PLAN:"2022-04-01",PO_FORECAST:"2022-03-17",PO_ACTUAL:"2022-03-18",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1442,SN:10,TAG:"2543PK051",TAG_DESCR:"PSA",TAG_DESCR_SHORT:"2543PK051 / PSA",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-PE-R-E-LL01",MR_DESCR:"PSA",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",MR_PLAN:"2021-12-09",MR_FORECAST:"2021-12-30",MR_ACTUAL:"2021-12-06",LOA_PLAN:"2022-03-01",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-04-12",PO_FORECAST:"2022-08-14",PO_ACTUAL:"2022-08-10",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1443,SN:11,TAG:"2540D080",TAG_DESCR:"EXPANDER SUCTION DRUM",TAG_DESCR_SHORT:"2540D080 / EXPANDER SUCTION DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0302",MR_DESCR:"EXPANDER SUCTION DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1444,SN:12,TAG:"2540E070",TAG_DESCR:"DEMETHANIZER BOTTOMS COOLER",TAG_DESCR_SHORT:"2540E070 / DEMETHANIZER BOTTOMS COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0403",MR_DESCR:"DEMETHANIZER BOTTOMS COOLER脱甲烷塔釜液冷却器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",MR_PLAN:"2021-11-18",MR_FORECAST:"2021-11-18",MR_ACTUAL:"2021-11-18",LOA_PLAN:"2022-03-10",LOA_FORECAST:"2022-03-18",LOA_ACTUAL:"2022-03-16",PO_PLAN:"2022-04-21",PO_FORECAST:"2022-04-29",PO_ACTUAL:"2022-04-02",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1445,SN:13,TAG:"2540D072",TAG_DESCR:"2540E070 1ST STAGE C3R FLASH DRUM",TAG_DESCR_SHORT:"2540D072 / 2540E070 1ST STAGE C3R FLASH DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0403",MR_DESCR:"2540E070 1ST STAGE C3R FLASH DRUM闪蒸罐",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",MR_PLAN:"2021-11-18",MR_FORECAST:"2021-11-18",MR_ACTUAL:"2021-11-18",LOA_PLAN:"2022-03-10",LOA_FORECAST:"2022-03-18",LOA_ACTUAL:"2022-03-16",PO_PLAN:"2022-04-21",PO_FORECAST:"2022-04-29",PO_ACTUAL:"2022-04-02",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1446,SN:14,TAG:"2540D001",TAG_DESCR_SHORT:"2540D001 / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1447,SN:15,TAG:"2540E001",TAG_DESCR:"DEMETH PREFRAC FEED COOLER NO 1 1",TAG_DESCR_SHORT:"2540E001 / DEMETH PREFRAC FEED COOLER NO 1 1",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0403",MR_DESCR:"板翅换热器及其附属设备 DEMETH PREFRAC FEED COOLER NO. 1预脱甲烷塔#1 进料冷却器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",MR_PLAN:"2021-11-18",MR_FORECAST:"2021-11-18",MR_ACTUAL:"2021-11-18",LOA_PLAN:"2022-03-10",LOA_FORECAST:"2022-03-18",LOA_ACTUAL:"2022-03-16",PO_PLAN:"2022-04-21",PO_FORECAST:"2022-04-29",PO_ACTUAL:"2022-04-02",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1448,SN:16,TAG:"2540E010",TAG_DESCR:"DEMETH PREFRAC REBOILER",TAG_DESCR_SHORT:"2540E010 / DEMETH PREFRAC REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0408",MR_DESCR:"DEMETH PREFRAC REBOILER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-05",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-16",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1449,SN:17,TAG:"2540T001",TAG_DESCR:"Demethanizer PrefractionatorSS 304LSS 304LSS 52 4865",TAG_DESCR_SHORT:"2540T001 / Demethanizer PrefractionatorSS 304LSS ...",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-LL04",MR_DESCR:"Demethanizer PrefractionatorSS /预脱甲烷塔（壳体304LSS,内件304LSS、内径5.2米，高48.65米）",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",MR_PLAN:"2021-07-20",MR_FORECAST:"2021-08-02",MR_ACTUAL:"2021-08-02",LOA_PLAN:"2021-10-30",LOA_FORECAST:"2021-10-22",LOA_ACTUAL:"2021-10-29",PO_PLAN:"2021-12-11",PO_FORECAST:"2021-12-03",PO_ACTUAL:"2021-11-08",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1450,SN:18,TAG:"2540D015",TAG_DESCR:"DEMETH PREFRAC REFLUX DRUM",TAG_DESCR_SHORT:"2540D015 / DEMETH PREFRAC REFLUX DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-LL04",MR_DESCR:"DEMETH PREFRAC REFLUX DRUM预脱甲烷塔回流罐",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",MR_PLAN:"2021-07-20",MR_FORECAST:"2021-08-02",MR_ACTUAL:"2021-08-02",LOA_PLAN:"2021-10-30",LOA_FORECAST:"2021-10-22",LOA_ACTUAL:"2021-10-29",PO_PLAN:"2021-12-11",PO_FORECAST:"2021-12-03",PO_ACTUAL:"2021-11-08",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1451,SN:19,TAG:"2540E015",TAG_DESCR:"CIK",TAG_DESCR_SHORT:"2540E015 / CIK",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-LL02",MR_DESCR:"CIK板翅换热器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",MR_PLAN:"2021-08-13",MR_FORECAST:"2021-09-30",MR_ACTUAL:"2021-09-26",LOA_PLAN:"2022-01-04",LOA_FORECAST:"2021-12-26",LOA_ACTUAL:"2021-12-24",PO_PLAN:"2022-02-15",PO_FORECAST:"2022-02-06",PO_ACTUAL:"2022-01-15",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1452,SN:20,TAG:"2540D030",TAG_DESCR:"DEMETHANIZER REFLUX DRUM1600 304L SS 21 33 304L SS 24 41",TAG_DESCR_SHORT:"2540D030 / DEMETHANIZER REFLUX DRUM1600 304L SS 2...",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL05",MR_DESCR:"DEMETHANIZER REFLUX DRUM1600吊车 （壳体304L SS、内径2.1、高度3.3、保冷）（壳体304L SS、内径2.4、高度4.1、保冷）",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",MR_PLAN:"2021-11-23",MR_FORECAST:"2021-11-23",MR_ACTUAL:"2021-11-08",LOA_PLAN:"2022-02-18",LOA_FORECAST:"2022-02-03",LOA_ACTUAL:"2022-02-14",PO_PLAN:"2022-04-01",PO_FORECAST:"2022-03-17",PO_ACTUAL:"2022-03-18",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1453,SN:21,TAG:"2540T030",TAG_DESCR:"RESIDUE GAS RECTIFIER 304L SS 21 172",TAG_DESCR_SHORT:"2540T030 / RESIDUE GAS RECTIFIER 304L SS 21 172",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL05",MR_DESCR:"RESIDUE GAS RECTIFIER   尾气精馏塔 （壳体304L SS、内径2.1、高度17.2、保冷）",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",MR_PLAN:"2021-11-23",MR_FORECAST:"2021-11-23",MR_ACTUAL:"2021-11-08",LOA_PLAN:"2022-02-18",LOA_FORECAST:"2022-02-03",LOA_ACTUAL:"2022-02-14",PO_PLAN:"2022-04-01",PO_FORECAST:"2022-03-17",PO_ACTUAL:"2022-03-18",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1454,SN:22,TAG:"2540C081",TAG_DESCR:"RESIDUE GAS EXPANDER RECOMPRESSOR",TAG_DESCR_SHORT:"2540C081 / RESIDUE GAS EXPANDER RECOMPRESSOR",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-LL01",MR_DESCR:"RESIDUE GAS EXPANDER / RECOMPRESSOR尾气膨胀机-再压缩机",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",MR_PLAN:"2021-07-23",MR_FORECAST:"2021-09-15",MR_ACTUAL:"2021-09-15",LOA_PLAN:"2022-01-04",LOA_FORECAST:"2022-01-01",LOA_ACTUAL:"2021-12-30",PO_PLAN:"2022-02-15",PO_FORECAST:"2022-02-28",PO_ACTUAL:"2022-03-11",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1455,SN:23,TAG:"2540E055",TAG_DESCR:"DEMETHANIZER FEED COOLER",TAG_DESCR_SHORT:"2540E055 / DEMETHANIZER FEED COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0403",MR_DESCR:"板翅换热器及其附属设备 DEMETHANIZER FEED COOLER脱甲烷塔进料冷却器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",MR_PLAN:"2021-11-18",MR_FORECAST:"2021-11-18",MR_ACTUAL:"2021-11-18",LOA_PLAN:"2022-03-10",LOA_FORECAST:"2022-03-18",LOA_ACTUAL:"2022-03-16",PO_PLAN:"2022-04-21",PO_FORECAST:"2022-04-29",PO_ACTUAL:"2022-04-02",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1456,SN:24,TAG:"2540D085",TAG_DESCR:"EXPANDER DISCHARGE DRUM",TAG_DESCR_SHORT:"2540D085 / EXPANDER DISCHARGE DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0302",MR_DESCR:"EXPANDER DISCHARGE DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1457,SN:25,TAG:"2540D002",TAG_DESCR:"DEMETH PREFRAC FEED SEPARATOR NO 1",TAG_DESCR_SHORT:"2540D002 / DEMETH PREFRAC FEED SEPARATOR NO 1",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0302",MR_DESCR:"DEMETH PREFRAC FEED SEPARATOR NO. 1",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1458,SN:26,TAG:"2540D003",TAG_DESCR:"DEMETH PREFRAC FEED SEPARATOR NO 2",TAG_DESCR_SHORT:"2540D003 / DEMETH PREFRAC FEED SEPARATOR NO 2",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0302",MR_DESCR:"DEMETH PREFRAC FEED SEPARATOR NO. 2",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1459,SN:27,TAG:"2540D004",TAG_DESCR:"DEMETH PREFRAC FEED SEPARATOR NO 3",TAG_DESCR_SHORT:"2540D004 / DEMETH PREFRAC FEED SEPARATOR NO 3",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0302",MR_DESCR:"DEMETH PREFRAC FEED SEPARATOR NO. 3",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1460,SN:28,TAG:"2540E060",TAG_DESCR:"DEMETHANIZER REBOILER",TAG_DESCR_SHORT:"2540E060 / DEMETHANIZER REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0403",MR_DESCR:"DEMETHANIZER REBOILER脱甲烷塔再沸器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",MR_PLAN:"2021-11-18",MR_FORECAST:"2021-11-18",MR_ACTUAL:"2021-11-18",LOA_PLAN:"2022-03-10",LOA_FORECAST:"2022-03-18",LOA_ACTUAL:"2022-03-16",PO_PLAN:"2022-04-21",PO_FORECAST:"2022-04-29",PO_ACTUAL:"2022-04-02",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1461,SN:29,TAG:"2540E002A",TAG_DESCR:"DEMETH PREFRAC COOLER NO. 2",TAG_DESCR_SHORT:"2540E002A / DEMETH PREFRAC COOLER NO 2",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR & BT1 - Heat Exchangers",MR_NO:"TBD",MR_DESCR:"DEMETH PREFRAC COOLER NO. 2",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1462,SN:30,TAG:"2540E002B",TAG_DESCR:"DEMETH PREFRAC COOLER NO. 2",TAG_DESCR_SHORT:"2540E002B / DEMETH PREFRAC COOLER NO 2",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR & BT1 - Heat Exchangers",MR_NO:"TBD",MR_DESCR:"DEMETH PREFRAC COOLER NO. 2",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1463,SN:31,TAG:"2540E002C",TAG_DESCR:"DEMETH PREFRAC COOLER NO. 2",TAG_DESCR_SHORT:"2540E002C / DEMETH PREFRAC COOLER NO 2",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR & BT1 - Heat Exchangers",MR_NO:"TBD",MR_DESCR:"DEMETH PREFRAC COOLER NO. 2",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1464,SN:32,TAG:"2540E003",TAG_DESCR:"C3R SUBCOOLER",TAG_DESCR_SHORT:"2540E003 / C3R SUBCOOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR&BT1-Package",MR_NO:"TBD",MR_DESCR:"C3R SUBCOOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1465,SN:33,TAG:"2540E030",TAG_DESCR:"RECTIFIER CONDENSER",TAG_DESCR_SHORT:"2540E030 / RECTIFIER CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR & BT1 - Heat Exchangers",MR_NO:"TBD",MR_DESCR:"RECTIFIER CONDENSER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1466,SN:34,TAG:"2540E040A",TAG_DESCR:"HYDROGEN CORE EXCHANGERS NO.1",TAG_DESCR_SHORT:"2540E040A / HYDROGEN CORE EXCHANGERS NO1",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR & BT1 - Heat Exchangers",MR_NO:"TBD",MR_DESCR:"HYDROGEN CORE EXCHANGERS NO.1",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1467,SN:35,TAG:"2540E040B",TAG_DESCR:"HYDROGEN CORE EXCHANGERS NO.2",TAG_DESCR_SHORT:"2540E040B / HYDROGEN CORE EXCHANGERS NO2",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR & BT1 - Heat Exchangers",MR_NO:"TBD",MR_DESCR:"HYDROGEN CORE EXCHANGERS NO.2",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1468,SN:36,TAG:"2540E065",TAG_DESCR:"DEMETHANIZER CONDENSER 1600T CIV",TAG_DESCR_SHORT:"2540E065 / DEMETHANIZER CONDENSER 1600T CIV",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0402",MR_DESCR:"DEMETHANIZER CONDENSER脱甲烷塔冷凝器/1600T吊车 CIV板翅换热器（一）",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",MR_PLAN:"2021-11-18",MR_FORECAST:"2021-11-18",MR_ACTUAL:"2021-11-18",LOA_PLAN:"2022-03-10",LOA_FORECAST:"2022-03-18",LOA_ACTUAL:"2022-03-16",PO_PLAN:"2022-04-21",PO_FORECAST:"2022-04-29",PO_ACTUAL:"2022-04-02",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1469,SN:37,TAG:"2540D065",TAG_DESCR:"DEMETHANIZER REFLUX DRUM1600 304L SS 21 33 304L SS 24 41",TAG_DESCR_SHORT:"2540D065 / DEMETHANIZER REFLUX DRUM1600 304L SS 2...",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL05",MR_DESCR:"DEMETHANIZER REFLUX DRUM1600吊车 （壳体304L SS、内径2.1、高度3.3、保冷）（壳体304L SS、内径2.4、高度4.1、保冷）",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",MR_PLAN:"2021-11-23",MR_FORECAST:"2021-11-23",MR_ACTUAL:"2021-11-08",LOA_PLAN:"2022-02-18",LOA_FORECAST:"2022-02-03",LOA_ACTUAL:"2022-02-14",PO_PLAN:"2022-04-01",PO_FORECAST:"2022-03-17",PO_ACTUAL:"2022-03-18",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1470,SN:38,TAG:"2540T051",TAG_DESCR:"DEMETHANIZER 1600 304L SS 34 5035",TAG_DESCR_SHORT:"2540T051 / DEMETHANIZER 1600 304L SS 34 5035",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL05",MR_DESCR:"DEMETHANIZER 脱甲烷塔1600吊车 （壳体304L SS、内径3.4、高度50.35、保冷）",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",MR_PLAN:"2021-11-23",MR_FORECAST:"2021-11-23",MR_ACTUAL:"2021-11-08",LOA_PLAN:"2022-02-18",LOA_FORECAST:"2022-02-03",LOA_ACTUAL:"2022-02-14",PO_PLAN:"2022-04-01",PO_FORECAST:"2022-03-17",PO_ACTUAL:"2022-03-18",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1471,SN:39,TAG:"2537DR050",TAG_DESCR:"CRACKED GAS SECONDARY DRYER",TAG_DESCR_SHORT:"2537DR050 / CRACKED GAS SECONDARY DRYER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR & BT1  - Static",MR_NO:"TBD",MR_DESCR:"CRACKED GAS SECONDARY DRYER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1472,SN:40,TAG:"2537E005",TAG_DESCR:"C2 HYDROG FEED HEATER",TAG_DESCR_SHORT:"2537E005 / C2 HYDROG FEED HEATER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0410",MR_DESCR:"C2 HYDROG FEED HEATER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-14",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-25",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1473,SN:41,TAG:"ARSINE",TAG_DESCR_SHORT:"ARSINE / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1474,SN:42,TAG:"2535E053",TAG_DESCR:"REGENERATION GAS FEED HEATER",TAG_DESCR_SHORT:"2535E053 / REGENERATION GAS FEED HEATER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0408",MR_DESCR:"REGENERATION GAS FEED HEATER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-05",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-16",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1475,SN:43,TAG:"2535E055",TAG_DESCR:"REGENERATION GAS EFFLUENT COOLER",TAG_DESCR_SHORT:"2535E055 / REGENERATION GAS EFFLUENT COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0409",MR_DESCR:"REGENERATION GAS EFFLUENT COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-14",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-25",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-07-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1476,SN:44,TAG:"2530E005",TAG_DESCR:"WASH WATER COOLER",TAG_DESCR_SHORT:"2530E005 / WASH WATER COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0410",MR_DESCR:"WASH WATER COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-09",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-20",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1477,SN:45,TAG:"2535E052",TAG_DESCR:"REGENERATION GAS FEEDEFFLUENT EXCH",TAG_DESCR_SHORT:"2535E052 / REGENERATION GAS FEEDEFFLUENT EXCH",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0410",MR_DESCR:"REGENERATION GAS FEED/EFFLUENT EXCH",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-14",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-25",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1478,SN:46,TAG:"2535E051",TAG_DESCR:"RESIDUE GAS COOLER",TAG_DESCR_SHORT:"2535E051 / RESIDUE GAS COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0409",MR_DESCR:"RESIDUE GAS COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-14",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-25",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-07-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1479,SN:47,TAG:"2535D050",TAG_DESCR:"REGENERATION GAS SEPARATOR",TAG_DESCR_SHORT:"2535D050 / REGENERATION GAS SEPARATOR",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0304",MR_DESCR:"REGENERATION GAS SEPARATOR再生气分液罐",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-09",LOA_PLAN:"2022-08-14",LOA_FORECAST:"2022-08-17",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-09-25",PO_FORECAST:"2022-09-28",PO_ACTUAL:"2022-08-29",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1480,SN:48,TAG:"2534TK005",TAG_DESCR:"TANK PLATE",TAG_DESCR_SHORT:"2534TK005 / TANK PLATE",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0507",MR_DESCR:"FRESH CAUSTIC DAY TANK",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-09-30",MR_FORECAST:"2022-09-30",LOA_PLAN:"2023-01-21",LOA_FORECAST:"2023-01-21",PO_PLAN:"2023-03-04",PO_FORECAST:"2023-03-04",CRITICAL:"NPO",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1481,SN:49,TAG:"2536D015",TAG_DESCR:"HP DEPROPANIZER REFLUX DRUM",TAG_DESCR_SHORT:"2536D015 / HP DEPROPANIZER REFLUX DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0302",MR_DESCR:"HP DEPROPANIZER REFLUX DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1482,SN:50,TAG:"2536E019",TAG_DESCR:"HP DEPROPANIZER CONDENSERx000D",TAG_DESCR_SHORT:"2536E019 / HP DEPROPANIZER CONDENSERx000D",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0405",MR_DESCR:"HP DEPROPANIZER CONDENSER_x000D_ 高通量",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-03-08",MR_FORECAST:"2022-03-08",MR_ACTUAL:"2022-01-29",LOA_PLAN:"2022-06-29",LOA_FORECAST:"2022-06-23",LOA_ACTUAL:"2022-06-21",PO_PLAN:"2022-08-10",PO_FORECAST:"2022-08-04",PO_ACTUAL:"2022-07-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1483,SN:51,TAG:"2555T051",TAG_DESCR:"C4CAUSTIC LIQUIDLIQUID EXTRACTOR KCS 27 275",TAG_DESCR_SHORT:"2555T051 / C4CAUSTIC LIQUIDLIQUID EXTRACTOR KCS 2...",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0101",MR_DESCR:"C4-CAUSTIC LIQUID/LIQUID EXTRACTOR （壳体KCS、内径2.7、高度27.5米",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2021-11-28",MR_FORECAST:"2021-11-28",MR_ACTUAL:"2021-11-24",LOA_PLAN:"2022-02-28",LOA_FORECAST:"2022-03-11",LOA_ACTUAL:"2022-03-23",PO_PLAN:"2022-04-11",PO_FORECAST:"2022-04-22",PO_ACTUAL:"2022-04-13",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1484,SN:52,TAG:"2534D050",TAG_DESCR:"SPENT CAUSTIC DEOILING DRUM",TAG_DESCR_SHORT:"2534D050 / SPENT CAUSTIC DEOILING DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0303",MR_DESCR:"SPENT CAUSTIC DEOILING DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1485,SN:53,TAG:"2555D065",TAG_DESCR:"MIXED C4S WATER WASH SETTLER",TAG_DESCR_SHORT:"2555D065 / MIXED C4S WATER WASH SETTLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0506",MR_DESCR:"MIXED C4S WATER WASH SETTLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-03-18",MR_FORECAST:"2022-03-18",MR_ACTUAL:"2022-02-07",LOA_PLAN:"2022-07-14",LOA_FORECAST:"2022-05-26",LOA_ACTUAL:"2022-05-25",PO_PLAN:"2022-08-25",PO_FORECAST:"2022-07-07",PO_ACTUAL:"2022-06-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1486,SN:54,TAG:"2534T031",TAG_DESCR:"CAUSTIC TOWER1600 KCS KCS 48 5445",TAG_DESCR_SHORT:"2534T031 / CAUSTIC TOWER1600 KCS KCS 48 5445",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-LL03",MR_DESCR:"CAUSTIC TOWER/1600吊车 碱洗塔（壳体KCS,内件KCS、内径4.8米，高54.45米 ）",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2021-07-10",MR_FORECAST:"2021-07-10",MR_ACTUAL:"2021-07-15",LOA_PLAN:"2021-10-30",LOA_FORECAST:"2021-10-19",LOA_ACTUAL:"2021-10-14",PO_PLAN:"2021-12-11",PO_FORECAST:"2021-11-30",PO_ACTUAL:"2021-11-08",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1487,SN:55,TAG:"2535D005",TAG_DESCR:"GAS DRYER FEED KO DRUM",TAG_DESCR_SHORT:"2535D005 / GAS DRYER FEED KO DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0304",MR_DESCR:"GAS DRYER FEED KO DRUM气相干燥器进料分 液罐（带内件）",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-09",LOA_PLAN:"2022-08-14",LOA_FORECAST:"2022-08-17",LOA_ACTUAL:"2022-08-13",PO_PLAN:"2022-09-25",PO_FORECAST:"2022-09-28",PO_ACTUAL:"2022-08-29",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1488,SN:56,TAG:"2535E005",TAG_DESCR:"GAS DRYER FEED CHILLERx000D",TAG_DESCR_SHORT:"2535E005 / GAS DRYER FEED CHILLERx000D",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0405",MR_DESCR:"GAS DRYER FEED CHILLER_x000D_",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-03-08",MR_FORECAST:"2022-03-08",MR_ACTUAL:"2022-01-29",LOA_PLAN:"2022-06-29",LOA_FORECAST:"2022-06-23",LOA_ACTUAL:"2022-06-21",PO_PLAN:"2022-08-10",PO_FORECAST:"2022-08-04",PO_ACTUAL:"2022-07-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1489,SN:57,TAG:"2535DR020A",TAG_DESCR:"CG LIQUID DRYERS",TAG_DESCR_SHORT:"2535DR020A / CG LIQUID DRYERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR & BT1  - Static",MR_NO:"TBD",MR_DESCR:"CG LIQUID DRYERS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1490,SN:58,TAG:"2535DR020B",TAG_DESCR:"CG LIQUID DRYERS",TAG_DESCR_SHORT:"2535DR020B / CG LIQUID DRYERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR & BT1  - Static",MR_NO:"TBD",MR_DESCR:"CG LIQUID DRYERS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1491,SN:59,TAG:"2536E016",TAG_DESCR:"HP DEPROPANIZER REFLUX CHILLER NO 1x000D",TAG_DESCR_SHORT:"2536E016 / HP DEPROPANIZER REFLUX CHILLER NO 1x000D",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0406",MR_DESCR:"HP DEPROPANIZER REFLUX CHILLER NO. 1_x000D_ 高通量",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-03-08",MR_FORECAST:"2022-03-08",MR_ACTUAL:"2022-01-29",LOA_PLAN:"2022-06-29",LOA_FORECAST:"2022-06-23",LOA_ACTUAL:"2022-06-21",PO_PLAN:"2022-08-10",PO_FORECAST:"2022-08-04",PO_ACTUAL:"2022-07-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1492,SN:60,TAG:"2536E017",TAG_DESCR:"HP DEPROPANIZER REFLUX CHILLER NO 2x000D",TAG_DESCR_SHORT:"2536E017 / HP DEPROPANIZER REFLUX CHILLER NO 2x000D",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0406",MR_DESCR:"HP DEPROPANIZER REFLUX CHILLER NO. 2_x000D_ 高通量",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-03-08",MR_FORECAST:"2022-03-08",MR_ACTUAL:"2022-01-29",LOA_PLAN:"2022-06-29",LOA_FORECAST:"2022-06-23",LOA_ACTUAL:"2022-06-21",PO_PLAN:"2022-08-10",PO_FORECAST:"2022-08-04",PO_ACTUAL:"2022-07-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1493,SN:61,TAG:"2536E018",TAG_DESCR:"HP DEPROPANIZER REFLUX CHILLER NO 3",TAG_DESCR_SHORT:"2536E018 / HP DEPROPANIZER REFLUX CHILLER NO 3",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0408",MR_DESCR:"HP DEPROPANIZER REFLUX CHILLER NO. 3",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-05",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-16",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1494,SN:62,TAG:"2555P018A",TAG_DESCR:"WASH WATER PUMPS",TAG_DESCR_SHORT:"2555P018A / WASH WATER PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0621",MR_DESCR:"WASH WATER PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1495,SN:63,TAG:"2555P018B",TAG_DESCR:"WASH WATER PUMPS",TAG_DESCR_SHORT:"2555P018B / WASH WATER PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0621",MR_DESCR:"WASH WATER PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1496,SN:64,TAG:"2534P060B",TAG_DESCR:"SPENT CAUSTIC DISCHARGE PUMPS",TAG_DESCR_SHORT:"2534P060B / SPENT CAUSTIC DISCHARGE PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0621",MR_DESCR:"SPENT CAUSTIC DISCHARGE PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1497,SN:65,TAG:"2534P060A",TAG_DESCR:"SPENT CAUSTIC DISCHARGE PUMPS",TAG_DESCR_SHORT:"2534P060A / SPENT CAUSTIC DISCHARGE PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0621",MR_DESCR:"SPENT CAUSTIC DISCHARGE PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1498,SN:66,TAG:"2534P005B",TAG_DESCR:"WAO FEED PUMPS",TAG_DESCR_SHORT:"2534P005B / WAO FEED PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0640",MR_DESCR:"WAO FEED PUMPS 高速泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-02-23",MR_FORECAST:"2022-02-23",MR_ACTUAL:"2022-01-29",LOA_PLAN:"2022-06-23",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-08-04",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1499,SN:67,TAG:"2534P005A",TAG_DESCR:"WAO FEED PUMPS",TAG_DESCR_SHORT:"2534P005A / WAO FEED PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0640",MR_DESCR:"WAO FEED PUMPS 高速泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-02-23",MR_FORECAST:"2022-02-23",MR_ACTUAL:"2022-01-29",LOA_PLAN:"2022-06-23",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-08-04",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1500,SN:68,TAG:"2535D015",TAG_DESCR:"CG LIQUID DRYER FEED COALESCER",TAG_DESCR_SHORT:"2535D015 / CG LIQUID DRYER FEED COALESCER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0506",MR_DESCR:"CG LIQUID DRYER FEED COALESCER 聚结器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-03-18",MR_FORECAST:"2022-03-18",MR_ACTUAL:"2022-02-07",LOA_PLAN:"2022-07-14",LOA_FORECAST:"2022-05-26",LOA_ACTUAL:"2022-05-25",PO_PLAN:"2022-08-25",PO_FORECAST:"2022-07-07",PO_ACTUAL:"2022-06-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1501,SN:69,TAG:"2535FIL015B",TAG_DESCR_SHORT:"2535FIL015B / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1502,SN:70,TAG:"2535FIL015A",TAG_DESCR_SHORT:"2535FIL015A / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1503,SN:71,TAG:"2535P015B",TAG_DESCR:"CG LIQUID DRYER FEED PUMPS",TAG_DESCR_SHORT:"2535P015B / CG LIQUID DRYER FEED PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0621",MR_DESCR:"CG LIQUID DRYER FEED PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1504,SN:72,TAG:"2535P015A",TAG_DESCR:"CG LIQUID DRYER FEED PUMPS",TAG_DESCR_SHORT:"2535P015A / CG LIQUID DRYER FEED PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0621",MR_DESCR:"CG LIQUID DRYER FEED PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1505,SN:73,TAG:"2534P012",TAG_DESCR:"INTERMEDIATE CAUSTIC CIRCULATING PUMPS",TAG_DESCR_SHORT:"2534P012 / INTERMEDIATE CAUSTIC CIRCULATING PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0621",MR_DESCR:"INTERMEDIATE CAUSTIC CIRCULATING PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1506,SN:74,TAG:"2534P010B",TAG_DESCR:"WEAK CAUSTIC CIRCULATING PUMP",TAG_DESCR_SHORT:"2534P010B / WEAK CAUSTIC CIRCULATING PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0621",MR_DESCR:"WEAK CAUSTIC CIRCULATING PUMP",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1507,SN:75,TAG:"2534P010A",TAG_DESCR:"WEAK CAUSTIC CIRCULATING PUMP",TAG_DESCR_SHORT:"2534P010A / WEAK CAUSTIC CIRCULATING PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0621",MR_DESCR:"WEAK CAUSTIC CIRCULATING PUMP",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1508,SN:76,TAG:"2534P015B",TAG_DESCR:"STRONG CAUSTIC CIRCULATING PUMP",TAG_DESCR_SHORT:"2534P015B / STRONG CAUSTIC CIRCULATING PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0621",MR_DESCR:"STRONG CAUSTIC CIRCULATING PUMP",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1509,SN:77,TAG:"2534P015A",TAG_DESCR:"STRONG CAUSTIC CIRCULATING PUMP",TAG_DESCR_SHORT:"2534P015A / STRONG CAUSTIC CIRCULATING PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0621",MR_DESCR:"STRONG CAUSTIC CIRCULATING PUMP",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1510,SN:78,TAG:"2534FIL010B",TAG_DESCR:"WEAK CAUSTIC STRAINER",TAG_DESCR_SHORT:"2534FIL010B / WEAK CAUSTIC STRAINER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0501",MR_DESCR:"WEAK CAUSTIC STRAINER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-08",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-04",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-28",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1511,SN:79,TAG:"2534FIL010A",TAG_DESCR:"WEAK CAUSTIC STRAINER",TAG_DESCR_SHORT:"2534FIL010A / WEAK CAUSTIC STRAINER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0501",MR_DESCR:"WEAK CAUSTIC STRAINER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-08",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-04",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-28",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1512,SN:80,TAG:"2534FIL050",TAG_DESCR:"WASH OIL CIRCULATING FILTERS",TAG_DESCR_SHORT:"2534FIL050 / WASH OIL CIRCULATING FILTERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0501",MR_DESCR:"WASH OIL CIRCULATING FILTERS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-08",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-04",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-28",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1513,SN:81,TAG:"2534P050B",TAG_DESCR:"WASH OIL CIRCULATING PUMPS",TAG_DESCR_SHORT:"2534P050B / WASH OIL CIRCULATING PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0621",MR_DESCR:"WASH OIL CIRCULATING PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1514,SN:82,TAG:"2534P050A",TAG_DESCR:"WASH OIL CIRCULATING PUMPS",TAG_DESCR_SHORT:"2534P050A / WASH OIL CIRCULATING PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0621",MR_DESCR:"WASH OIL CIRCULATING PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1515,SN:83,TAG:"2535DR010A",TAG_DESCR:"CRACKED GAS DRYERS",TAG_DESCR_SHORT:"2535DR010A / CRACKED GAS DRYERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR & BT1  - Static",MR_NO:"TBD",MR_DESCR:"CRACKED GAS DRYERS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1516,SN:84,TAG:"2535DR010B",TAG_DESCR:"CRACKED GAS DRYERS",TAG_DESCR_SHORT:"2535DR010B / CRACKED GAS DRYERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR & BT1  - Static",MR_NO:"TBD",MR_DESCR:"CRACKED GAS DRYERS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1517,SN:85,TAG:"2535DR010C",TAG_DESCR:"CRACKED GAS DRYERS",TAG_DESCR_SHORT:"2535DR010C / CRACKED GAS DRYERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR & BT1  - Static",MR_NO:"TBD",MR_DESCR:"CRACKED GAS DRYERS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1518,SN:86,TAG:"2537R001A",TAG_DESCR:"C2 HYDROG REACTORSC2 kCS 44 76",TAG_DESCR_SHORT:"2537R001A / C2 HYDROG REACTORSC2 kCS 44 76",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL08",MR_DESCR:"C2 HYDROG REACTORS/C2加氢反应器 （壳体kCS、内径4.4米、高度7.6米）",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-03-30",MR_FORECAST:"2022-03-30",MR_ACTUAL:"2022-02-07",LOA_PLAN:"2022-07-24",LOA_FORECAST:"2022-08-07",LOA_ACTUAL:"2022-08-10",PO_PLAN:"2022-09-04",PO_FORECAST:"2022-09-28",PO_ACTUAL:"2022-09-28",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1519,SN:87,TAG:"2537E010",TAG_DESCR:"C2 HYDROG 1ST STAGE INTERCOOLER",TAG_DESCR_SHORT:"2537E010 / C2 HYDROG 1ST STAGE INTERCOOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0410",MR_DESCR:"C2 HYDROG 1ST STAGE INTERCOOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-14",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-25",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1520,SN:88,TAG:"2537R001B",TAG_DESCR:"C2 HYDROG REACTORSC2 kCS 44 76",TAG_DESCR_SHORT:"2537R001B / C2 HYDROG REACTORSC2 kCS 44 76",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL08",MR_DESCR:"C2 HYDROG REACTORS/C2加氢反应器 （壳体kCS、内径4.4米、高度7.6米）",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-03-30",MR_FORECAST:"2022-03-30",MR_ACTUAL:"2022-02-07",LOA_PLAN:"2022-07-24",LOA_FORECAST:"2022-08-07",LOA_ACTUAL:"2022-08-10",PO_PLAN:"2022-09-04",PO_FORECAST:"2022-09-28",PO_ACTUAL:"2022-09-28",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1521,SN:89,TAG:"2537E015",TAG_DESCR:"C2 HYDROG 2ND STAGE INTERCOOLER",TAG_DESCR_SHORT:"2537E015 / C2 HYDROG 2ND STAGE INTERCOOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0410",MR_DESCR:"C2 HYDROG 2ND STAGE INTERCOOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-14",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-25",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1522,SN:90,TAG:"2537E018",TAG_DESCR:"C2 HYDROG THIRD STAGE HEATER",TAG_DESCR_SHORT:"2537E018 / C2 HYDROG THIRD STAGE HEATER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0410",MR_DESCR:"C2 HYDROG THIRD STAGE HEATER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-14",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-25",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1523,SN:91,TAG:"2537R001C",TAG_DESCR:"C2 HYDROG REACTORSC2 kCS 44 76",TAG_DESCR_SHORT:"2537R001C / C2 HYDROG REACTORSC2 kCS 44 76",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL08",MR_DESCR:"C2 HYDROG REACTORS/C2加氢反应器 （壳体kCS、内径4.4米、高度7.6米）",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-03-30",MR_FORECAST:"2022-03-30",MR_ACTUAL:"2022-02-07",LOA_PLAN:"2022-07-24",LOA_FORECAST:"2022-08-07",LOA_ACTUAL:"2022-08-10",PO_PLAN:"2022-09-04",PO_FORECAST:"2022-09-28",PO_ACTUAL:"2022-09-28",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1524,SN:92,TAG:"2537E020A",TAG_DESCR:"C2 HYDROG AFTERCOOLER",TAG_DESCR_SHORT:"2537E020A / C2 HYDROG AFTERCOOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0410",MR_DESCR:"C2 HYDROG AFTERCOOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-14",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-25",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1902,SN:470,TAG:"2534D060",TAG_DESCR:"SPENT CAUSTIC DEGASSING DRUM",TAG_DESCR_SHORT:"2534D060 / SPENT CAUSTIC DEGASSING DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0303",MR_DESCR:"SPENT CAUSTIC DEGASSING DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1903,SN:471,TAG:"2537E020B",TAG_DESCR:"C2 HYDROG AFTERCOOLER",TAG_DESCR_SHORT:"2537E020B / C2 HYDROG AFTERCOOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0410",MR_DESCR:"C2 HYDROG AFTERCOOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-14",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-25",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1525,SN:93,TAG:"2571D030",TAG_DESCR_SHORT:"2571D030 / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"C",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1526,SN:94,TAG:"2573R001A",TAG_DESCR:"WET AIR OXIDATION REACTORWAO Alloy600",TAG_DESCR_SHORT:"2573R001A / WET AIR OXIDATION REACTORWAO Alloy600",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL02",MR_DESCR:"WET AIR OXIDATION REACTOR/WAO反应器 Alloy600 设备",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"C",MR_PLAN:"2021-12-23",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2022-02-07",LOA_PLAN:"2022-04-23",LOA_FORECAST:"2022-07-01",LOA_ACTUAL:"2022-06-28",PO_PLAN:"2022-06-04",PO_FORECAST:"2022-08-12",PO_ACTUAL:"2022-08-12",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1527,SN:95,TAG:"2573R001B",TAG_DESCR:"WET AIR OXIDATION REACTORWAO Alloy600",TAG_DESCR_SHORT:"2573R001B / WET AIR OXIDATION REACTORWAO Alloy600",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL02",MR_DESCR:"WET AIR OXIDATION REACTOR/WAO反应器 Alloy600 设备",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"C",MR_PLAN:"2021-12-23",MR_FORECAST:"2022-02-18",MR_ACTUAL:"2022-02-07",LOA_PLAN:"2022-04-23",LOA_FORECAST:"2022-07-01",LOA_ACTUAL:"2022-06-28",PO_PLAN:"2022-06-04",PO_FORECAST:"2022-08-12",PO_ACTUAL:"2022-08-12",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1528,SN:96,TAG:"2573T015",TAG_DESCR:"WAO WASH WATER CONTACTORWAO KCS 13 73",TAG_DESCR_SHORT:"2573T015 / WAO WASH WATER CONTACTORWAO KCS 13 73",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0102",MR_DESCR:"WAO WASH WATER CONTACTORWAO水洗塔 （壳体KCS、内径1.3、高度7.3米）",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"C",MR_PLAN:"2021-11-28",MR_FORECAST:"2021-11-28",MR_ACTUAL:"2021-11-24",LOA_PLAN:"2022-02-28",LOA_FORECAST:"2022-03-11",LOA_ACTUAL:"2022-03-23",PO_PLAN:"2022-04-11",PO_FORECAST:"2022-04-22",PO_ACTUAL:"2022-04-13",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1529,SN:97,TAG:"2573E015",TAG_DESCR:"WAO WASH WATER CIRCULATING COOLER",TAG_DESCR_SHORT:"2573E015 / WAO WASH WATER CIRCULATING COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0409",MR_DESCR:"WAO WASH WATER CIRCULATING COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"C",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-14",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-25",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-07-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1530,SN:98,TAG:"2572P020A",TAG_DESCR:"WAO FEED PUMPS",TAG_DESCR_SHORT:"2572P020A / WAO FEED PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0620",MR_DESCR:"WAO FEED PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"C",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1531,SN:99,TAG:"2572P020B",TAG_DESCR:"WAO FEED PUMPS",TAG_DESCR_SHORT:"2572P020B / WAO FEED PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0620",MR_DESCR:"WAO FEED PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"C",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1532,SN:100,TAG:"2573P016A",TAG_DESCR:"TREATED SPENT CAUSTIC",TAG_DESCR_SHORT:"2573P016A / TREATED SPENT CAUSTIC",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0620",MR_DESCR:"TREATED SPENT CAUSTIC",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"C",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1533,SN:101,TAG:"2573P016B",TAG_DESCR:"TREATED SPENT CAUSTIC",TAG_DESCR_SHORT:"2573P016B / TREATED SPENT CAUSTIC",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0620",MR_DESCR:"TREATED SPENT CAUSTIC",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"C",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1534,SN:102,TAG:"2571P010A",TAG_DESCR:"SPENT CAUSTIC PUMPS",TAG_DESCR_SHORT:"2571P010A / SPENT CAUSTIC PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0621",MR_DESCR:"SPENT CAUSTIC PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"C",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1535,SN:103,TAG:"2571P010B",TAG_DESCR:"SPENT CAUSTIC PUMPS",TAG_DESCR_SHORT:"2571P010B / SPENT CAUSTIC PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0621",MR_DESCR:"SPENT CAUSTIC PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"C",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1536,SN:104,TAG:"2573P015B",TAG_DESCR:"WAO WASH WATER CIRCULATING PUMP",TAG_DESCR_SHORT:"2573P015B / WAO WASH WATER CIRCULATING PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0621",MR_DESCR:"WAO WASH WATER CIRCULATING PUMP",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"C",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1537,SN:105,TAG:"2573P015A",TAG_DESCR:"WAO WASH WATER CIRCULATING PUMP",TAG_DESCR_SHORT:"2573P015A / WAO WASH WATER CIRCULATING PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0621",MR_DESCR:"WAO WASH WATER CIRCULATING PUMP",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"C",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1538,SN:106,TAG:"2571P020",TAG_DESCR:"CAUSTIC DRAIN PUMP",TAG_DESCR_SHORT:"2571P020 / CAUSTIC DRAIN PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0621",MR_DESCR:"CAUSTIC DRAIN PUMP",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"C",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1539,SN:107,TAG:"2571D020",TAG_DESCR:"CAUSTIC DRAIN DRUM",TAG_DESCR_SHORT:"2571D020 / CAUSTIC DRAIN DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0303",MR_DESCR:"CAUSTIC DRAIN DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"C",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1540,SN:108,TAG:"2573D061",TAG_DESCR:"AGITATOR",TAG_DESCR_SHORT:"2573D061 / AGITATOR",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0305",MR_DESCR:"AGITATOR 搅拌器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"C",MR_PLAN:"2022-09-30",MR_FORECAST:"2022-09-30",MR_ACTUAL:"2022-09-02",LOA_PLAN:"2023-01-26",LOA_FORECAST:"2023-04-11",PO_PLAN:"2023-03-09",PO_FORECAST:"2023-05-23",CRITICAL:"NPO",STATUS:"MR Received",STATUS_CODE:"MR",STATUS_SEQ:1},
      {NO:1541,SN:109,TAG:"2573D060",TAG_DESCR:"SULFURIC ACID DRUM",TAG_DESCR_SHORT:"2573D060 / SULFURIC ACID DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0303",MR_DESCR:"SULFURIC ACID DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"C",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1542,SN:110,TAG:"2573P061A",TAG_DESCR_SHORT:"2573P061A / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"C",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1543,SN:111,TAG:"2573P061A",TAG_DESCR_SHORT:"2573P061A / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"C",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1544,SN:112,TAG:"2573E060",TAG_DESCR:"NEUTRALIZATION PRODUCT COOLER",TAG_DESCR_SHORT:"2573E060 / NEUTRALIZATION PRODUCT COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0408",MR_DESCR:"NEUTRALIZATION PRODUCT COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"C",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-05",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-16",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1545,SN:113,TAG:"2580PA001",TAG_DESCR:"DEAERATOR",TAG_DESCR_SHORT:"2580PA001 / DEAERATOR",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-TM-R-E-0901",MR_DESCR:"DEAERATOR 除氧器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"C",MR_PLAN:"2022-05-08",MR_FORECAST:"2022-05-08",MR_ACTUAL:"2022-03-03",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-06-30",LOA_ACTUAL:"2022-06-29",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-08-11",PO_ACTUAL:"2022-08-29",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1546,SN:114,TAG:"2571TK010",TAG_DESCR:"TANK PLATE",TAG_DESCR_SHORT:"2571TK010 / TANK PLATE",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0507",MR_DESCR:"SPENT CAUSTIC STORAGE TANK",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"C",MR_PLAN:"2022-09-30",MR_FORECAST:"2022-09-30",LOA_PLAN:"2023-01-21",LOA_FORECAST:"2023-01-21",PO_PLAN:"2023-03-04",PO_FORECAST:"2023-03-04",CRITICAL:"NPO",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1547,SN:115,TAG:"2580P001C",TAG_DESCR:"SHP BFW PUMP",TAG_DESCR_SHORT:"2580P001C / SHP BFW PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-SL02",MR_DESCR:"SHP BFW PUMP超高压锅炉给水泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"C",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-06",LOA_PLAN:"2022-04-13",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-05-25",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1548,SN:116,TAG:"2580P001B",TAG_DESCR:"SHP BFW PUMP",TAG_DESCR_SHORT:"2580P001B / SHP BFW PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-SL02",MR_DESCR:"SHP BFW PUMP超高压锅炉给水泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"C",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-06",LOA_PLAN:"2022-04-13",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-05-25",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1549,SN:117,TAG:"2580P001A",TAG_DESCR:"SHP BFW PUMP",TAG_DESCR_SHORT:"2580P001A / SHP BFW PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-SL02",MR_DESCR:"SHP BFW PUMP超高压锅炉给水泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"C",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-06",LOA_PLAN:"2022-04-13",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-05-25",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1550,SN:118,TAG:"2573D016",TAG_DESCR:"VENT GAS KO DRUM",TAG_DESCR_SHORT:"2573D016 / VENT GAS KO DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0303",MR_DESCR:"VENT GAS KO DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"C",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1551,SN:119,TAG:"2572D015",TAG_DESCR:"SPENT CAUSTIC STRIPPER OVHD DRUM",TAG_DESCR_SHORT:"2572D015 / SPENT CAUSTIC STRIPPER OVHD DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0303",MR_DESCR:"SPENT CAUSTIC STRIPPER OVHD DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"C",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1552,SN:120,TAG:"2573D015",TAG_DESCR:"WAO SPENT CAUSTIC FLASH DRUMWAO Alloy600",TAG_DESCR_SHORT:"2573D015 / WAO SPENT CAUSTIC FLASH DRUMWAO Alloy600",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL03",MR_DESCR:"WAO SPENT CAUSTIC FLASH DRUMWAO废碱闪蒸罐 Alloy600 设备（三）",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"C",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-06",LOA_PLAN:"2022-06-09",LOA_FORECAST:"2022-07-01",LOA_ACTUAL:"2022-06-28",PO_PLAN:"2022-07-21",PO_FORECAST:"2022-08-12",PO_ACTUAL:"2022-08-12",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1553,SN:121,TAG:"2572E005B",TAG_DESCR:"SPENT CAUSTIC FEED HEATER Alloy600",TAG_DESCR_SHORT:"2572E005B / SPENT CAUSTIC FEED HEATER Alloy600",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL04",MR_DESCR:"SPENT CAUSTIC FEED HEATER废碱进料加热器 Alloy600 设备",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"C",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-04",LOA_PLAN:"2022-06-09",LOA_FORECAST:"2022-07-01",LOA_ACTUAL:"2022-06-28",PO_PLAN:"2022-07-21",PO_FORECAST:"2022-08-12",PO_ACTUAL:"2022-08-12",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1554,SN:122,TAG:"2572E005A",TAG_DESCR:"SPENT CAUSTIC FEED HEATER Alloy600",TAG_DESCR_SHORT:"2572E005A / SPENT CAUSTIC FEED HEATER Alloy600",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL04",MR_DESCR:"SPENT CAUSTIC FEED HEATER废碱进料加热器 Alloy600 设备",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"C",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-04",LOA_PLAN:"2022-06-09",LOA_FORECAST:"2022-07-01",LOA_ACTUAL:"2022-06-28",PO_PLAN:"2022-07-21",PO_FORECAST:"2022-08-12",PO_ACTUAL:"2022-08-12",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1555,SN:123,TAG:"2573E016B",TAG_DESCR:"OXIDATE COOLER Alloy600",TAG_DESCR_SHORT:"2573E016B / OXIDATE COOLER Alloy600",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL04",MR_DESCR:"OXIDATE COOLER氧化物冷却器 Alloy600 设备",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"C",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-04",LOA_PLAN:"2022-06-09",LOA_FORECAST:"2022-07-01",LOA_ACTUAL:"2022-06-28",PO_PLAN:"2022-07-21",PO_FORECAST:"2022-08-12",PO_ACTUAL:"2022-08-12",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1556,SN:124,TAG:"2573E016A",TAG_DESCR:"OXIDATE COOLER Alloy600",TAG_DESCR_SHORT:"2573E016A / OXIDATE COOLER Alloy600",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL04",MR_DESCR:"OXIDATE COOLER氧化物冷却器 Alloy600 设备",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"C",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-04",LOA_PLAN:"2022-06-09",LOA_FORECAST:"2022-07-01",LOA_ACTUAL:"2022-06-28",PO_PLAN:"2022-07-21",PO_FORECAST:"2022-08-12",PO_ACTUAL:"2022-08-12",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1557,SN:125,TAG:"2571FIL012A",TAG_DESCR:"SPENT CAUSTIC PREFILTER",TAG_DESCR_SHORT:"2571FIL012A / SPENT CAUSTIC PREFILTER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0501",MR_DESCR:"SPENT CAUSTIC PRE-FILTER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"C",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-08",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-04",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-28",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1558,SN:126,TAG:"2571FIL012B",TAG_DESCR:"SPENT CAUSTIC PREFILTER",TAG_DESCR_SHORT:"2571FIL012B / SPENT CAUSTIC PREFILTER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0501",MR_DESCR:"SPENT CAUSTIC PRE-FILTER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"C",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-08",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-04",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-28",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1559,SN:127,TAG:"2572T001B",TAG_DESCR:"SPENT CAUSTIC STRIPPER Alloy600",TAG_DESCR_SHORT:"2572T001B / SPENT CAUSTIC STRIPPER Alloy600",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL01",MR_DESCR:"SPENT CAUSTIC STRIPPER废碱汽提塔 Alloy600 设备",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"C",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-01-15",MR_ACTUAL:"2022-01-04",LOA_PLAN:"2022-06-16",LOA_FORECAST:"2022-07-01",LOA_ACTUAL:"2022-06-28",PO_PLAN:"2022-07-28",PO_FORECAST:"2022-08-12",PO_ACTUAL:"2022-08-12",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1560,SN:128,TAG:"2572T001A",TAG_DESCR:"SPENT CAUSTIC STRIPPER Alloy600",TAG_DESCR_SHORT:"2572T001A / SPENT CAUSTIC STRIPPER Alloy600",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL01",MR_DESCR:"SPENT CAUSTIC STRIPPER废碱汽提塔 Alloy600 设备",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"C",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-01-15",MR_ACTUAL:"2022-01-04",LOA_PLAN:"2022-06-16",LOA_FORECAST:"2022-07-01",LOA_ACTUAL:"2022-06-28",PO_PLAN:"2022-07-28",PO_FORECAST:"2022-08-12",PO_ACTUAL:"2022-08-12",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1561,SN:129,TAG:"2522E015A",TAG_DESCR:"PRIMARY QUENCH WATER AIR COOLER",TAG_DESCR_SHORT:"2522E015A / PRIMARY QUENCH WATER AIR COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0401",MR_DESCR:"PRIMARY QUENCH WATER AIR COOLER一级急冷水空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-15",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1562,SN:130,TAG:"2522E015B",TAG_DESCR:"PRIMARY QUENCH WATER AIR COOLER",TAG_DESCR_SHORT:"2522E015B / PRIMARY QUENCH WATER AIR COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0401",MR_DESCR:"PRIMARY QUENCH WATER AIR COOLER一级急冷水空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-15",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1563,SN:131,TAG:"2522E015C",TAG_DESCR:"PRIMARY QUENCH WATER AIR COOLER",TAG_DESCR_SHORT:"2522E015C / PRIMARY QUENCH WATER AIR COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0401",MR_DESCR:"PRIMARY QUENCH WATER AIR COOLER一级急冷水空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-15",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1564,SN:132,TAG:"2522E015D",TAG_DESCR:"PRIMARY QUENCH WATER AIR COOLER",TAG_DESCR_SHORT:"2522E015D / PRIMARY QUENCH WATER AIR COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0401",MR_DESCR:"PRIMARY QUENCH WATER AIR COOLER一级急冷水空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-15",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1565,SN:133,TAG:"2522E015E",TAG_DESCR:"PRIMARY QUENCH WATER AIR COOLER",TAG_DESCR_SHORT:"2522E015E / PRIMARY QUENCH WATER AIR COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0401",MR_DESCR:"PRIMARY QUENCH WATER AIR COOLER一级急冷水空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-15",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1566,SN:134,TAG:"2522E015F",TAG_DESCR:"PRIMARY QUENCH WATER AIR COOLER",TAG_DESCR_SHORT:"2522E015F / PRIMARY QUENCH WATER AIR COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0401",MR_DESCR:"PRIMARY QUENCH WATER AIR COOLER一级急冷水空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-15",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1567,SN:135,TAG:"2522E015G",TAG_DESCR:"PRIMARY QUENCH WATER AIR COOLER",TAG_DESCR_SHORT:"2522E015G / PRIMARY QUENCH WATER AIR COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0401",MR_DESCR:"PRIMARY QUENCH WATER AIR COOLER一级急冷水空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-15",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1568,SN:136,TAG:"2522E015H",TAG_DESCR:"PRIMARY QUENCH WATER AIR COOLER",TAG_DESCR_SHORT:"2522E015H / PRIMARY QUENCH WATER AIR COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0401",MR_DESCR:"PRIMARY QUENCH WATER AIR COOLER一级急冷水空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-15",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1569,SN:137,TAG:"2522E015I",TAG_DESCR:"PRIMARY QUENCH WATER AIR COOLER",TAG_DESCR_SHORT:"2522E015I / PRIMARY QUENCH WATER AIR COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0401",MR_DESCR:"PRIMARY QUENCH WATER AIR COOLER一级急冷水空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-15",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1570,SN:138,TAG:"2522E015J",TAG_DESCR:"PRIMARY QUENCH WATER AIR COOLER",TAG_DESCR_SHORT:"2522E015J / PRIMARY QUENCH WATER AIR COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0401",MR_DESCR:"PRIMARY QUENCH WATER AIR COOLER一级急冷水空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-15",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1571,SN:139,TAG:"2522E015K",TAG_DESCR:"PRIMARY QUENCH WATER AIR COOLER",TAG_DESCR_SHORT:"2522E015K / PRIMARY QUENCH WATER AIR COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0401",MR_DESCR:"PRIMARY QUENCH WATER AIR COOLER一级急冷水空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-15",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1572,SN:140,TAG:"2565STP001A",TAG_DESCR_SHORT:"2565STP001A / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1573,SN:141,TAG:"2565STP001B",TAG_DESCR_SHORT:"2565STP001B / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1574,SN:142,TAG:"2565E002",TAG_DESCR:"ETHYLENE PRODUCT COOLER",TAG_DESCR_SHORT:"2565E002 / ETHYLENE PRODUCT COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0408",MR_DESCR:"ETHYLENE PRODUCT COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-05",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-16",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1575,SN:143,TAG:"2565E003",TAG_DESCR:"ETHYLENE RUNDOWN CONDENSERx000D",TAG_DESCR_SHORT:"2565E003 / ETHYLENE RUNDOWN CONDENSERx000D",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0405",MR_DESCR:"ETHYLENE RUNDOWN CONDENSER_x000D_ 高通量",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2022-03-08",MR_FORECAST:"2022-03-08",MR_ACTUAL:"2022-01-29",LOA_PLAN:"2022-06-29",LOA_FORECAST:"2022-06-23",LOA_ACTUAL:"2022-06-21",PO_PLAN:"2022-08-10",PO_FORECAST:"2022-08-04",PO_ACTUAL:"2022-07-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1576,SN:144,TAG:"2565D035",TAG_DESCR:"C2 REFRIG 4TH STAGE DRUM",TAG_DESCR_SHORT:"2565D035 / C2 REFRIG 4TH STAGE DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0302",MR_DESCR:"C2 REFRIG 4TH STAGE DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1577,SN:145,TAG:"2565D030",TAG_DESCR:"C2R 3RD STAGE SUCTION DRUM",TAG_DESCR_SHORT:"2565D030 / C2R 3RD STAGE SUCTION DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0301",MR_DESCR:"C2R 3RD STAGE SUCTION DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1578,SN:146,TAG:"2565C001-X1",TAG_DESCR_SHORT:"2565C001-X1 / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1579,SN:147,TAG:"2565E053",TAG_DESCR_SHORT:"2565E053 / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1580,SN:148,TAG:"2565E054",TAG_DESCR_SHORT:"2565E054 / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1581,SN:149,TAG:"2565E051",TAG_DESCR:"C2R COMPRESSOR SURFACE CONDENSER",TAG_DESCR_SHORT:"2565E051 / C2R COMPRESSOR SURFACE CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR & BT1 - Heat Exchangers",MR_NO:"TBD",MR_DESCR:"C2R COMPRESSOR SURFACE CONDENSER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1582,SN:150,TAG:"2565D020",TAG_DESCR:"C2R 2ND STAGE SUCTION DRUM",TAG_DESCR_SHORT:"2565D020 / C2R 2ND STAGE SUCTION DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0302",MR_DESCR:"C2R 2ND STAGE SUCTION DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1583,SN:151,TAG:"2565D010",TAG_DESCR:"C2R 1ST STAGE SUCTION DRUM",TAG_DESCR_SHORT:"2565D010 / C2R 1ST STAGE SUCTION DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0302",MR_DESCR:"C2R 1ST STAGE SUCTION DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1584,SN:152,TAG:"2565E010",TAG_DESCR:"LP ETHYLENE PRODUCT CHILLER NO 1 1",TAG_DESCR_SHORT:"2565E010 / LP ETHYLENE PRODUCT CHILLER NO 1 1",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0403",MR_DESCR:"LP ETHYLENE PRODUCT CHILLER NO. 1低压乙烯产品冷却器#1",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2021-11-18",MR_FORECAST:"2021-11-18",MR_ACTUAL:"2021-11-18",LOA_PLAN:"2022-03-10",LOA_FORECAST:"2022-03-18",LOA_ACTUAL:"2022-03-16",PO_PLAN:"2022-04-21",PO_FORECAST:"2022-04-29",PO_ACTUAL:"2022-04-02",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1585,SN:153,TAG:"2565C001",TAG_DESCR:"ETHYLENE REFRIGHEAT PUMP COMPRESSOR",TAG_DESCR_SHORT:"2565C001 / ETHYLENE REFRIGHEAT PUMP COMPRESSOR",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-UL01",MR_DESCR:"ETHYLENE REFRIG/HEAT PUMP COMPRESSOR乙烯热泵/制冷压缩机组",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2021-04-25",MR_FORECAST:"2021-05-14",MR_ACTUAL:"2021-05-14",LOA_PLAN:"2021-10-30",LOA_FORECAST:"2021-10-31",LOA_ACTUAL:"2021-10-29",PO_PLAN:"2021-12-11",PO_FORECAST:"2021-12-10",PO_ACTUAL:"2021-12-17",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1586,SN:154,TAG:"2580SI008",TAG_DESCR:"Silencer",TAG_DESCR_SHORT:"2580SI008 / Silencer",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR&BT1-Others",MR_NO:"TBD",MR_DESCR:"Silencer",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1587,SN:155,TAG:"2580SI015",TAG_DESCR_SHORT:"2580SI015 / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1588,SN:156,TAG:"2565TK052",TAG_DESCR_SHORT:"2565TK052 / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1589,SN:157,TAG:"2560STP001B",TAG_DESCR_SHORT:"2560STP001B / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1590,SN:158,TAG:"2560STP001A",TAG_DESCR_SHORT:"2560STP001A / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1591,SN:159,TAG:"2560C001-X1",TAG_DESCR_SHORT:"2560C001-X1 / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1592,SN:160,TAG:"2560E053",TAG_DESCR_SHORT:"2560E053 / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1593,SN:161,TAG:"2560E051",TAG_DESCR:"C3R COMPRESSOR SURFACE CONDENSER",TAG_DESCR_SHORT:"2560E051 / C3R COMPRESSOR SURFACE CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR & BT1 - Heat Exchangers",MR_NO:"TBD",MR_DESCR:"C3R COMPRESSOR SURFACE CONDENSER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1594,SN:161,TAG:"2560STE001",TAG_DESCR_SHORT:"2560STE001 / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1595,SN:163,TAG:"2560C001",TAG_DESCR:"PROPYLENE REFRIG COMPRESSOR",TAG_DESCR_SHORT:"2560C001 / PROPYLENE REFRIG COMPRESSOR",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-UL01",MR_DESCR:"PROPYLENE REFRIG COMPRESSOR 丙烯制冷压缩机",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2021-04-25",MR_FORECAST:"2021-05-14",MR_ACTUAL:"2021-05-14",LOA_PLAN:"2021-10-30",LOA_FORECAST:"2021-10-31",LOA_ACTUAL:"2021-10-29",PO_PLAN:"2021-12-11",PO_FORECAST:"2021-12-10",PO_ACTUAL:"2021-12-17",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1596,SN:164,TAG:"2580SI012",TAG_DESCR_SHORT:"2580SI012 / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1597,SN:165,TAG:"2560TK052",TAG_DESCR_SHORT:"2560TK052 / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1598,SN:166,TAG:"2580SI007",TAG_DESCR:"Silencer",TAG_DESCR_SHORT:"2580SI007 / Silencer",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR&BT1-Others",MR_NO:"TBD",MR_DESCR:"Silencer",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1599,SN:167,TAG:"2560E005D",TAG_DESCR:"C3 REFRIGERANT CONDENSERx000D",TAG_DESCR_SHORT:"2560E005D / C3 REFRIGERANT CONDENSERx000D",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0404",MR_DESCR:"C3 REFRIGERANT CONDENSER_x000D_ 管壳换热器-高冷凝",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2022-03-08",MR_FORECAST:"2022-03-08",MR_ACTUAL:"2022-01-29",LOA_PLAN:"2022-06-29",LOA_FORECAST:"2022-06-23",LOA_ACTUAL:"2022-06-17",PO_PLAN:"2022-08-10",PO_FORECAST:"2022-08-04",PO_ACTUAL:"2022-07-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1600,SN:168,TAG:"2560E005C",TAG_DESCR:"C3 REFRIGERANT CONDENSERx000D",TAG_DESCR_SHORT:"2560E005C / C3 REFRIGERANT CONDENSERx000D",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0404",MR_DESCR:"C3 REFRIGERANT CONDENSER_x000D_ 管壳换热器-高冷凝",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2022-03-08",MR_FORECAST:"2022-03-08",MR_ACTUAL:"2022-01-29",LOA_PLAN:"2022-06-29",LOA_FORECAST:"2022-06-23",LOA_ACTUAL:"2022-06-17",PO_PLAN:"2022-08-10",PO_FORECAST:"2022-08-04",PO_ACTUAL:"2022-07-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1601,SN:169,TAG:"2560E005B",TAG_DESCR:"C3 REFRIGERANT CONDENSERx000D",TAG_DESCR_SHORT:"2560E005B / C3 REFRIGERANT CONDENSERx000D",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0404",MR_DESCR:"C3 REFRIGERANT CONDENSER_x000D_ 管壳换热器-高冷凝",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2022-03-08",MR_FORECAST:"2022-03-08",MR_ACTUAL:"2022-01-29",LOA_PLAN:"2022-06-29",LOA_FORECAST:"2022-06-23",LOA_ACTUAL:"2022-06-17",PO_PLAN:"2022-08-10",PO_FORECAST:"2022-08-04",PO_ACTUAL:"2022-07-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1602,SN:170,TAG:"2560E005A",TAG_DESCR:"C3 REFRIGERANT CONDENSERx000D",TAG_DESCR_SHORT:"2560E005A / C3 REFRIGERANT CONDENSERx000D",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0404",MR_DESCR:"C3 REFRIGERANT CONDENSER_x000D_ 管壳换热器-高冷凝",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2022-03-08",MR_FORECAST:"2022-03-08",MR_ACTUAL:"2022-01-29",LOA_PLAN:"2022-06-29",LOA_FORECAST:"2022-06-23",LOA_ACTUAL:"2022-06-17",PO_PLAN:"2022-08-10",PO_FORECAST:"2022-08-04",PO_ACTUAL:"2022-07-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1603,SN:171,TAG:"2560D005",TAG_DESCR:"PROPYLENE REFRIGERANT RECEIVER",TAG_DESCR_SHORT:"2560D005 / PROPYLENE REFRIGERANT RECEIVER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0302",MR_DESCR:"PROPYLENE REFRIGERANT RECEIVER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1604,SN:172,TAG:"2560D040",TAG_DESCR:"C3 REFRIG 4TH STAGE SUCTION DRUM",TAG_DESCR_SHORT:"2560D040 / C3 REFRIG 4TH STAGE SUCTION DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0301",MR_DESCR:"C3 REFRIG 4TH STAGE SUCTION DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1605,SN:173,TAG:"2560D030",TAG_DESCR:"C3 REFRIG 3RD STAGE SUCTION DRUM",TAG_DESCR_SHORT:"2560D030 / C3 REFRIG 3RD STAGE SUCTION DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0301",MR_DESCR:"C3 REFRIG 3RD STAGE SUCTION DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1606,SN:174,TAG:"2560D020",TAG_DESCR:"C3 REFRIG 2ND STAGE SUCTION DRUM",TAG_DESCR_SHORT:"2560D020 / C3 REFRIG 2ND STAGE SUCTION DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0301",MR_DESCR:"C3 REFRIG 2ND STAGE SUCTION DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1607,SN:175,TAG:"2560D015",TAG_DESCR:"C3R BLOWDOWN DRUM",TAG_DESCR_SHORT:"2560D015 / C3R BLOWDOWN DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0302",MR_DESCR:"C3R BLOWDOWN DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1608,SN:176,TAG:"2560D010",TAG_DESCR:"C3 REFRIG 1ST STAGE SUCTION DRUM",TAG_DESCR_SHORT:"2560D010 / C3 REFRIG 1ST STAGE SUCTION DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0301",MR_DESCR:"C3 REFRIG 1ST STAGE SUCTION DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1609,SN:177,TAG:"2565E005A",TAG_DESCR_SHORT:"2565E005A / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1610,SN:178,TAG:"2565E005B",TAG_DESCR_SHORT:"2565E005B / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1611,SN:179,TAG:"2545T001",TAG_DESCR:"C2 Splitter 304LSS 304LSS 6 6972",TAG_DESCR_SHORT:"2545T001 / C2 Splitter 304LSS 304LSS 6 6972",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-LL04",MR_DESCR:"C2 Splitter/乙烯精馏塔（壳体304LSS,内件304LSS、内径6米，高69.72米）",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2021-07-20",MR_FORECAST:"2021-08-02",MR_ACTUAL:"2021-08-02",LOA_PLAN:"2021-10-30",LOA_FORECAST:"2021-10-22",LOA_ACTUAL:"2021-10-29",PO_PLAN:"2021-12-11",PO_FORECAST:"2021-12-03",PO_ACTUAL:"2021-11-08",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1612,SN:180,TAG:"2565D005",TAG_DESCR:"C2R SURGE DRUM",TAG_DESCR_SHORT:"2565D005 / C2R SURGE DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0302",MR_DESCR:"C2R SURGE DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1613,SN:181,TAG:"2545E012",TAG_DESCR:"C2 SPLITTER SIDE REBOILER",TAG_DESCR_SHORT:"2545E012 / C2 SPLITTER SIDE REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0403",MR_DESCR:"C2 SPLITTER SIDE REBOILER乙烯塔中沸器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2021-11-18",MR_FORECAST:"2021-11-18",MR_ACTUAL:"2021-11-18",LOA_PLAN:"2022-03-10",LOA_FORECAST:"2022-03-18",LOA_ACTUAL:"2022-03-16",PO_PLAN:"2022-04-21",PO_FORECAST:"2022-04-29",PO_ACTUAL:"2022-04-02",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1614,SN:182,TAG:"2545E010",TAG_DESCR:"C2 SPLITTER BOTTOMS REBOILER",TAG_DESCR_SHORT:"2545E010 / C2 SPLITTER BOTTOMS REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0403",MR_DESCR:"C2 SPLITTER BOTTOMS REBOILER乙烯塔再沸器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2021-11-18",MR_FORECAST:"2021-11-18",MR_ACTUAL:"2021-11-18",LOA_PLAN:"2022-03-10",LOA_FORECAST:"2022-03-18",LOA_ACTUAL:"2022-03-16",PO_PLAN:"2022-04-21",PO_FORECAST:"2022-04-29",PO_ACTUAL:"2022-04-02",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1615,SN:183,TAG:"2545P020A",TAG_DESCR:"ETHANE RECYCLE PUMPS",TAG_DESCR_SHORT:"2545P020A / ETHANE RECYCLE PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0620",MR_DESCR:"ETHANE RECYCLE PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1616,SN:184,TAG:"2545P020B",TAG_DESCR:"ETHANE RECYCLE PUMPS",TAG_DESCR_SHORT:"2545P020B / ETHANE RECYCLE PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0620",MR_DESCR:"ETHANE RECYCLE PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1617,SN:185,TAG:"2565E004",TAG_DESCR:"C2R DESUPERHEATER",TAG_DESCR_SHORT:"2565E004 / C2R DESUPERHEATER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0403",MR_DESCR:"C2R DESUPERHEATER乙烯冷剂脱过热器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2021-11-18",MR_FORECAST:"2021-11-18",MR_ACTUAL:"2021-11-18",LOA_PLAN:"2022-03-10",LOA_FORECAST:"2022-03-18",LOA_ACTUAL:"2022-03-16",PO_PLAN:"2022-04-21",PO_FORECAST:"2022-04-29",PO_ACTUAL:"2022-04-02",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1618,SN:186,TAG:"2565D015",TAG_DESCR:"C2R BLOWDOWN DRUM",TAG_DESCR_SHORT:"2565D015 / C2R BLOWDOWN DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0302",MR_DESCR:"C2R BLOWDOWN DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1619,SN:187,TAG:"2565E011",TAG_DESCR:"LP ETHYLENE PRODUCT CHILLER NO 2 2",TAG_DESCR_SHORT:"2565E011 / LP ETHYLENE PRODUCT CHILLER NO 2 2",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0403",MR_DESCR:"LP ETHYLENE PRODUCT CHILLER NO. 2低压乙烯产品冷却器#2",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",MR_PLAN:"2021-11-18",MR_FORECAST:"2021-11-18",MR_ACTUAL:"2021-11-18",LOA_PLAN:"2022-03-10",LOA_FORECAST:"2022-03-18",LOA_ACTUAL:"2022-03-16",PO_PLAN:"2022-04-21",PO_FORECAST:"2022-04-29",PO_ACTUAL:"2022-04-02",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1901,SN:469,TAG:"2560FT1005A",TAG_DESCR_SHORT:"2560FT1005A / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1620,SN:188,TAG:"2580D002",TAG_DESCR_SHORT:"2580D002 / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1621,SN:189,TAG:"2580D001",TAG_DESCR:"LP Flash Drum",TAG_DESCR_SHORT:"2580D001 / LP Flash Drum",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0303",MR_DESCR:"LP Flash Drum",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1622,SN:190,TAG:"2598D006",TAG_DESCR:"FUEL GAS KNOCKOUT DRUM",TAG_DESCR_SHORT:"2598D006 / FUEL GAS KNOCKOUT DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0303",MR_DESCR:"FUEL GAS KNOCKOUT DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1623,SN:191,TAG:"2522E015L",TAG_DESCR:"PRIMARY QUENCH WATER AIR COOLER",TAG_DESCR_SHORT:"2522E015L / PRIMARY QUENCH WATER AIR COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0401",MR_DESCR:"PRIMARY QUENCH WATER AIR COOLER一级急冷水空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-15",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1624,SN:192,TAG:"2522E015M",TAG_DESCR:"PRIMARY QUENCH WATER AIR COOLER",TAG_DESCR_SHORT:"2522E015M / PRIMARY QUENCH WATER AIR COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0401",MR_DESCR:"PRIMARY QUENCH WATER AIR COOLER一级急冷水空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-15",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1625,SN:193,TAG:"2522E015N",TAG_DESCR:"PRIMARY QUENCH WATER AIR COOLER",TAG_DESCR_SHORT:"2522E015N / PRIMARY QUENCH WATER AIR COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0401",MR_DESCR:"PRIMARY QUENCH WATER AIR COOLER一级急冷水空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-15",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1626,SN:194,TAG:"2522E015O",TAG_DESCR:"PRIMARY QUENCH WATER AIR COOLER",TAG_DESCR_SHORT:"2522E015O / PRIMARY QUENCH WATER AIR COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0401",MR_DESCR:"PRIMARY QUENCH WATER AIR COOLER一级急冷水空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-15",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1627,SN:195,TAG:"2522E015P",TAG_DESCR:"PRIMARY QUENCH WATER AIR COOLER",TAG_DESCR_SHORT:"2522E015P / PRIMARY QUENCH WATER AIR COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0401",MR_DESCR:"PRIMARY QUENCH WATER AIR COOLER一级急冷水空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-15",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1628,SN:196,TAG:"2522E015Q",TAG_DESCR:"PRIMARY QUENCH WATER AIR COOLER",TAG_DESCR_SHORT:"2522E015Q / PRIMARY QUENCH WATER AIR COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0401",MR_DESCR:"PRIMARY QUENCH WATER AIR COOLER一级急冷水空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-15",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1629,SN:197,TAG:"2522E015R",TAG_DESCR:"PRIMARY QUENCH WATER AIR COOLER",TAG_DESCR_SHORT:"2522E015R / PRIMARY QUENCH WATER AIR COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0401",MR_DESCR:"PRIMARY QUENCH WATER AIR COOLER一级急冷水空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-15",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1630,SN:198,TAG:"2522E015S",TAG_DESCR:"PRIMARY QUENCH WATER AIR COOLER",TAG_DESCR_SHORT:"2522E015S / PRIMARY QUENCH WATER AIR COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0401",MR_DESCR:"PRIMARY QUENCH WATER AIR COOLER一级急冷水空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-15",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1631,SN:199,TAG:"2522E015T",TAG_DESCR:"PRIMARY QUENCH WATER AIR COOLER",TAG_DESCR_SHORT:"2522E015T / PRIMARY QUENCH WATER AIR COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0401",MR_DESCR:"PRIMARY QUENCH WATER AIR COOLER一级急冷水空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-15",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1632,SN:200,TAG:"2522E015U",TAG_DESCR:"PRIMARY QUENCH WATER AIR COOLER",TAG_DESCR_SHORT:"2522E015U / PRIMARY QUENCH WATER AIR COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0401",MR_DESCR:"PRIMARY QUENCH WATER AIR COOLER一级急冷水空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-15",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1633,SN:201,TAG:"2522E015V",TAG_DESCR:"PRIMARY QUENCH WATER AIR COOLER",TAG_DESCR_SHORT:"2522E015V / PRIMARY QUENCH WATER AIR COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0401",MR_DESCR:"PRIMARY QUENCH WATER AIR COOLER一级急冷水空冷器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2021-12-02",MR_FORECAST:"2021-12-02",MR_ACTUAL:"2021-11-15",LOA_PLAN:"2022-04-10",LOA_FORECAST:"2022-05-01",LOA_ACTUAL:"2022-05-06",PO_PLAN:"2022-05-22",PO_FORECAST:"2022-06-12",PO_ACTUAL:"2022-08-01",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1634,SN:202,TAG:"2598D007",TAG_DESCR:"Fuel Gas Coalescer",TAG_DESCR_SHORT:"2598D007 / Fuel Gas Coalescer",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0505",MR_DESCR:"Fuel Gas Coalescer 燃料气聚结器/限制性名单",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-03-18",MR_FORECAST:"2022-03-18",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-09-10",LOA_FORECAST:"2022-07-17",LOA_ACTUAL:"2022-07-30",PO_PLAN:"2022-10-22",PO_FORECAST:"2022-08-28",PO_ACTUAL:"2022-08-25",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1635,SN:203,TAG:"2530D040",TAG_DESCR:"CG 4TH STAGE SUCTION DRUM",TAG_DESCR_SHORT:"2530D040 / CG 4TH STAGE SUCTION DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0301",MR_DESCR:"CG 4TH STAGE SUCTION DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1636,SN:204,TAG:"2530E030D",TAG_DESCR:"CG 3RD STAGE AFTERCOOLERS",TAG_DESCR_SHORT:"2530E030D / CG 3RD STAGE AFTERCOOLERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0407",MR_DESCR:"CG 3RD STAGE AFTERCOOLERS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-04-05",MR_FORECAST:"2022-04-05",MR_ACTUAL:"2022-03-14",LOA_PLAN:"2022-08-19",LOA_FORECAST:"2022-07-14",LOA_ACTUAL:"2022-07-08",PO_PLAN:"2022-09-30",PO_FORECAST:"2022-08-25",PO_ACTUAL:"2022-08-30",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1637,SN:205,TAG:"2530E030C",TAG_DESCR:"CG 3RD STAGE AFTERCOOLERS",TAG_DESCR_SHORT:"2530E030C / CG 3RD STAGE AFTERCOOLERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0407",MR_DESCR:"CG 3RD STAGE AFTERCOOLERS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-04-05",MR_FORECAST:"2022-04-05",MR_ACTUAL:"2022-03-14",LOA_PLAN:"2022-08-19",LOA_FORECAST:"2022-07-14",LOA_ACTUAL:"2022-07-08",PO_PLAN:"2022-09-30",PO_FORECAST:"2022-08-25",PO_ACTUAL:"2022-08-30",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1638,SN:206,TAG:"2530E030B",TAG_DESCR:"CG 3RD STAGE AFTERCOOLERS",TAG_DESCR_SHORT:"2530E030B / CG 3RD STAGE AFTERCOOLERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0407",MR_DESCR:"CG 3RD STAGE AFTERCOOLERS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-04-05",MR_FORECAST:"2022-04-05",MR_ACTUAL:"2022-03-14",LOA_PLAN:"2022-08-19",LOA_FORECAST:"2022-07-14",LOA_ACTUAL:"2022-07-08",PO_PLAN:"2022-09-30",PO_FORECAST:"2022-08-25",PO_ACTUAL:"2022-08-30",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1639,SN:207,TAG:"2530E030A",TAG_DESCR:"CG 3RD STAGE AFTERCOOLERS",TAG_DESCR_SHORT:"2530E030A / CG 3RD STAGE AFTERCOOLERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0407",MR_DESCR:"CG 3RD STAGE AFTERCOOLERS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-04-05",MR_FORECAST:"2022-04-05",MR_ACTUAL:"2022-03-14",LOA_PLAN:"2022-08-19",LOA_FORECAST:"2022-07-14",LOA_ACTUAL:"2022-07-08",PO_PLAN:"2022-09-30",PO_FORECAST:"2022-08-25",PO_ACTUAL:"2022-08-30",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1640,SN:208,TAG:"2530E040D",TAG_DESCR:"CG 4TH STAGE AFTERCOOLER",TAG_DESCR_SHORT:"2530E040D / CG 4TH STAGE AFTERCOOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0407",MR_DESCR:"CG 4TH STAGE AFTERCOOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-04-05",MR_FORECAST:"2022-04-05",MR_ACTUAL:"2022-03-14",LOA_PLAN:"2022-08-19",LOA_FORECAST:"2022-07-14",LOA_ACTUAL:"2022-07-08",PO_PLAN:"2022-09-30",PO_FORECAST:"2022-08-25",PO_ACTUAL:"2022-08-30",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1641,SN:209,TAG:"2530E040C",TAG_DESCR:"CG 4TH STAGE AFTERCOOLER",TAG_DESCR_SHORT:"2530E040C / CG 4TH STAGE AFTERCOOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0407",MR_DESCR:"CG 4TH STAGE AFTERCOOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-04-05",MR_FORECAST:"2022-04-05",MR_ACTUAL:"2022-03-14",LOA_PLAN:"2022-08-19",LOA_FORECAST:"2022-07-14",LOA_ACTUAL:"2022-07-08",PO_PLAN:"2022-09-30",PO_FORECAST:"2022-08-25",PO_ACTUAL:"2022-08-30",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1642,SN:210,TAG:"2530E040B",TAG_DESCR:"CG 4TH STAGE AFTERCOOLER",TAG_DESCR_SHORT:"2530E040B / CG 4TH STAGE AFTERCOOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0407",MR_DESCR:"CG 4TH STAGE AFTERCOOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-04-05",MR_FORECAST:"2022-04-05",MR_ACTUAL:"2022-03-14",LOA_PLAN:"2022-08-19",LOA_FORECAST:"2022-07-14",LOA_ACTUAL:"2022-07-08",PO_PLAN:"2022-09-30",PO_FORECAST:"2022-08-25",PO_ACTUAL:"2022-08-30",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1643,SN:211,TAG:"2530E040A",TAG_DESCR:"CG 4TH STAGE AFTERCOOLER",TAG_DESCR_SHORT:"2530E040A / CG 4TH STAGE AFTERCOOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0407",MR_DESCR:"CG 4TH STAGE AFTERCOOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-04-05",MR_FORECAST:"2022-04-05",MR_ACTUAL:"2022-03-14",LOA_PLAN:"2022-08-19",LOA_FORECAST:"2022-07-14",LOA_ACTUAL:"2022-07-08",PO_PLAN:"2022-09-30",PO_FORECAST:"2022-08-25",PO_ACTUAL:"2022-08-30",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1644,SN:212,TAG:"2530D045",TAG_DESCR:"CG 4TH STAGE DISCHARGE DRUM",TAG_DESCR_SHORT:"2530D045 / CG 4TH STAGE DISCHARGE DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0303",MR_DESCR:"CG 4TH STAGE DISCHARGE DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1645,SN:213,TAG:"2534E035",TAG_DESCR:"CAUSTIC TOWER FEED HEATER",TAG_DESCR_SHORT:"2534E035 / CAUSTIC TOWER FEED HEATER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0407",MR_DESCR:"CAUSTIC TOWER FEED HEATER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-04-05",MR_FORECAST:"2022-04-05",MR_ACTUAL:"2022-03-14",LOA_PLAN:"2022-08-19",LOA_FORECAST:"2022-07-14",LOA_ACTUAL:"2022-07-08",PO_PLAN:"2022-09-30",PO_FORECAST:"2022-08-25",PO_ACTUAL:"2022-08-30",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1646,SN:214,TAG:"2535FIL010",TAG_DESCR:"MERCURY ADSORBER FILTER",TAG_DESCR_SHORT:"2535FIL010 / MERCURY ADSORBER FILTER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0501",MR_DESCR:"MERCURY ADSORBER FILTER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-08",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-04",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-28",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1647,SN:215,TAG:"2535DR060",TAG_DESCR:"MERCURY ADSORBER",TAG_DESCR_SHORT:"2535DR060 / MERCURY ADSORBER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR & BT1  - Static",MR_NO:"TBD",MR_DESCR:"MERCURY ADSORBER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1648,SN:216,TAG:"2530E010D",TAG_DESCR:"CG 1ST STAGE AFTERCOOLERS",TAG_DESCR_SHORT:"2530E010D / CG 1ST STAGE AFTERCOOLERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0407",MR_DESCR:"CG 1ST STAGE AFTERCOOLERS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-04-05",MR_FORECAST:"2022-04-05",MR_ACTUAL:"2022-03-14",LOA_PLAN:"2022-08-19",LOA_FORECAST:"2022-07-14",LOA_ACTUAL:"2022-07-08",PO_PLAN:"2022-09-30",PO_FORECAST:"2022-08-25",PO_ACTUAL:"2022-08-30",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1649,SN:217,TAG:"2530E010C",TAG_DESCR:"CG 1ST STAGE AFTERCOOLERS",TAG_DESCR_SHORT:"2530E010C / CG 1ST STAGE AFTERCOOLERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0407",MR_DESCR:"CG 1ST STAGE AFTERCOOLERS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-04-05",MR_FORECAST:"2022-04-05",MR_ACTUAL:"2022-03-14",LOA_PLAN:"2022-08-19",LOA_FORECAST:"2022-07-14",LOA_ACTUAL:"2022-07-08",PO_PLAN:"2022-09-30",PO_FORECAST:"2022-08-25",PO_ACTUAL:"2022-08-30",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1650,SN:218,TAG:"2530E010B",TAG_DESCR:"CG 1ST STAGE AFTERCOOLERS",TAG_DESCR_SHORT:"2530E010B / CG 1ST STAGE AFTERCOOLERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0407",MR_DESCR:"CG 1ST STAGE AFTERCOOLERS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-04-05",MR_FORECAST:"2022-04-05",MR_ACTUAL:"2022-03-14",LOA_PLAN:"2022-08-19",LOA_FORECAST:"2022-07-14",LOA_ACTUAL:"2022-07-08",PO_PLAN:"2022-09-30",PO_FORECAST:"2022-08-25",PO_ACTUAL:"2022-08-30",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1651,SN:219,TAG:"2530E010A",TAG_DESCR:"CG 1ST STAGE AFTERCOOLERS",TAG_DESCR_SHORT:"2530E010A / CG 1ST STAGE AFTERCOOLERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0407",MR_DESCR:"CG 1ST STAGE AFTERCOOLERS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-04-05",MR_FORECAST:"2022-04-05",MR_ACTUAL:"2022-03-14",LOA_PLAN:"2022-08-19",LOA_FORECAST:"2022-07-14",LOA_ACTUAL:"2022-07-08",PO_PLAN:"2022-09-30",PO_FORECAST:"2022-08-25",PO_ACTUAL:"2022-08-30",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1652,SN:220,TAG:"2530D020",TAG_DESCR:"CG 2ND STAGE SUCTION DRUM",TAG_DESCR_SHORT:"2530D020 / CG 2ND STAGE SUCTION DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0301",MR_DESCR:"CG 2ND STAGE SUCTION DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1653,SN:221,TAG:"2530E020D",TAG_DESCR:"CG 2ND STAGE AFTERCOOLERS",TAG_DESCR_SHORT:"2530E020D / CG 2ND STAGE AFTERCOOLERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0407",MR_DESCR:"CG 2ND STAGE AFTERCOOLERS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-04-05",MR_FORECAST:"2022-04-05",MR_ACTUAL:"2022-03-14",LOA_PLAN:"2022-08-19",LOA_FORECAST:"2022-07-14",LOA_ACTUAL:"2022-07-08",PO_PLAN:"2022-09-30",PO_FORECAST:"2022-08-25",PO_ACTUAL:"2022-08-30",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1654,SN:222,TAG:"2530E020C",TAG_DESCR:"CG 2ND STAGE AFTERCOOLERS",TAG_DESCR_SHORT:"2530E020C / CG 2ND STAGE AFTERCOOLERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0407",MR_DESCR:"CG 2ND STAGE AFTERCOOLERS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-04-05",MR_FORECAST:"2022-04-05",MR_ACTUAL:"2022-03-14",LOA_PLAN:"2022-08-19",LOA_FORECAST:"2022-07-14",LOA_ACTUAL:"2022-07-08",PO_PLAN:"2022-09-30",PO_FORECAST:"2022-08-25",PO_ACTUAL:"2022-08-30",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1655,SN:223,TAG:"2530E020B",TAG_DESCR:"CG 2ND STAGE AFTERCOOLERS",TAG_DESCR_SHORT:"2530E020B / CG 2ND STAGE AFTERCOOLERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0407",MR_DESCR:"CG 2ND STAGE AFTERCOOLERS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-04-05",MR_FORECAST:"2022-04-05",MR_ACTUAL:"2022-03-14",LOA_PLAN:"2022-08-19",LOA_FORECAST:"2022-07-14",LOA_ACTUAL:"2022-07-08",PO_PLAN:"2022-09-30",PO_FORECAST:"2022-08-25",PO_ACTUAL:"2022-08-30",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1656,SN:224,TAG:"2530E020A",TAG_DESCR:"CG 2ND STAGE AFTERCOOLERS",TAG_DESCR_SHORT:"2530E020A / CG 2ND STAGE AFTERCOOLERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0407",MR_DESCR:"CG 2ND STAGE AFTERCOOLERS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-04-05",MR_FORECAST:"2022-04-05",MR_ACTUAL:"2022-03-14",LOA_PLAN:"2022-08-19",LOA_FORECAST:"2022-07-14",LOA_ACTUAL:"2022-07-08",PO_PLAN:"2022-09-30",PO_FORECAST:"2022-08-25",PO_ACTUAL:"2022-08-30",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1657,SN:225,TAG:"2530D030",TAG_DESCR:"CG 3RD STAGE SUCTION DRUM",TAG_DESCR_SHORT:"2530D030 / CG 3RD STAGE SUCTION DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0301",MR_DESCR:"CG 3RD STAGE SUCTION DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1658,SN:226,TAG:"2530P020B",TAG_DESCR:"CG 1ST STAGE HC CONDENSATE PUMPS",TAG_DESCR_SHORT:"2530P020B / CG 1ST STAGE HC CONDENSATE PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0620",MR_DESCR:"CG 1ST STAGE HC CONDENSATE PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1659,SN:227,TAG:"2530P020A",TAG_DESCR:"CG 1ST STAGE HC CONDENSATE PUMPS",TAG_DESCR_SHORT:"2530P020A / CG 1ST STAGE HC CONDENSATE PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0620",MR_DESCR:"CG 1ST STAGE HC CONDENSATE PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1660,SN:228,TAG:"2530P010B",TAG_DESCR:"CG 1ST STAGE CONDENSATE PUMPS",TAG_DESCR_SHORT:"2530P010B / CG 1ST STAGE CONDENSATE PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0621",MR_DESCR:"CG 1ST STAGE CONDENSATE PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1661,SN:229,TAG:"2530P010A",TAG_DESCR:"CG 1ST STAGE CONDENSATE PUMPS",TAG_DESCR_SHORT:"2530P010A / CG 1ST STAGE CONDENSATE PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0621",MR_DESCR:"CG 1ST STAGE CONDENSATE PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1662,SN:230,TAG:"2539PK090",TAG_DESCR_SHORT:"2539PK090 / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1663,SN:231,TAG:"2539PK080",TAG_DESCR:"NEUTRALIZING AMINE INJECTION PACKAGE",TAG_DESCR_SHORT:"2539PK080 / NEUTRALIZING AMINE INJECTION PACKAGE",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0610",MR_DESCR:"NEUTRALIZING AMINE INJECTION PACKAGE",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-09-01",MR_FORECAST:"2022-09-01",MR_ACTUAL:"2022-08-10",LOA_PLAN:"2022-11-15",LOA_FORECAST:"2022-12-28",LOA_ACTUAL:"2022-12-27",PO_PLAN:"2022-12-27",PO_FORECAST:"2023-02-08",PO_ACTUAL:"2023-01-30",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1664,SN:232,TAG:"2536P011A",TAG_DESCR:"HP DEPROPANIZER REBOILER COND PUMP",TAG_DESCR_SHORT:"2536P011A / HP DEPROPANIZER REBOILER COND PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0621",MR_DESCR:"HP DEPROPANIZER REBOILER COND PUMP",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1665,SN:233,TAG:"2536P011B",TAG_DESCR:"HP DEPROPANIZER REBOILER COND PUMP",TAG_DESCR_SHORT:"2536P011B / HP DEPROPANIZER REBOILER COND PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0621",MR_DESCR:"HP DEPROPANIZER REBOILER COND PUMP",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1666,SN:234,TAG:"2536T001",TAG_DESCR:"HP DEPROPANIZER 1600 LTCS 49 4425 TOP cold BTM hot",TAG_DESCR_SHORT:"2536T001 / HP DEPROPANIZER 1600 LTCS 49 4425 TOP ...",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL06",MR_DESCR:"HP DEPROPANIZER高压脱丙烷塔1600吊车 （壳体LTCS、内径4.9、高度44.25、TOP cold BTM hot）",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2021-09-13",MR_FORECAST:"2021-09-30",MR_ACTUAL:"2021-09-28",LOA_PLAN:"2022-01-30",LOA_FORECAST:"2021-12-24",LOA_ACTUAL:"2021-12-23",PO_PLAN:"2022-03-13",PO_FORECAST:"2022-02-14",PO_ACTUAL:"2021-12-24",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1667,SN:235,TAG:"2536E011A",TAG_DESCR:"HP DEPROPANIZER STEAM REBOILER",TAG_DESCR_SHORT:"2536E011A / HP DEPROPANIZER STEAM REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0408",MR_DESCR:"HP DEPROPANIZER STEAM REBOILER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-05",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-16",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1668,SN:236,TAG:"2536E011B",TAG_DESCR:"HP DEPROPANIZER STEAM REBOILER",TAG_DESCR_SHORT:"2536E011B / HP DEPROPANIZER STEAM REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0408",MR_DESCR:"HP DEPROPANIZER STEAM REBOILER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-05",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-16",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1669,SN:237,TAG:"2536E005A",TAG_DESCR:"HP DEPROP FEED OVERHEAD EXCHANGER",TAG_DESCR_SHORT:"2536E005A / HP DEPROP FEED OVERHEAD EXCHANGER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0408",MR_DESCR:"HP DEPROP FEED / OVERHEAD EXCHANGER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-05",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-16",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1670,SN:238,TAG:"2536E020",TAG_DESCR:"HP DEPROPANIZER BOTTOMS COOLER",TAG_DESCR_SHORT:"2536E020 / HP DEPROPANIZER BOTTOMS COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0408",MR_DESCR:"HP DEPROPANIZER BOTTOMS COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-05",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-16",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1671,SN:239,TAG:"2536E005B",TAG_DESCR:"HP DEPROP FEED OVERHEAD EXCHANGER",TAG_DESCR_SHORT:"2536E005B / HP DEPROP FEED OVERHEAD EXCHANGER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0408",MR_DESCR:"HP DEPROP FEED / OVERHEAD EXCHANGER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-05",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-16",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1672,SN:240,TAG:"2537E002",TAG_DESCR:"C2 HYDROGENATION FEED COOLER",TAG_DESCR_SHORT:"2537E002 / C2 HYDROGENATION FEED COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0410",MR_DESCR:"C2 HYDROGENATION FEED COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-14",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-25",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1673,SN:241,TAG:"2580SI002",TAG_DESCR:"Silencer",TAG_DESCR_SHORT:"2580SI002 / Silencer",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR&BT1-Others",MR_NO:"TBD",MR_DESCR:"Silencer",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1674,SN:242,TAG:"2580SI009",TAG_DESCR_SHORT:"2580SI009 / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1675,SN:243,TAG:"2580SI006",TAG_DESCR:"Silencer",TAG_DESCR_SHORT:"2580SI006 / Silencer",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR&BT1-Others",MR_NO:"TBD",MR_DESCR:"Silencer",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1676,SN:244,TAG:"2530TK052",TAG_DESCR_SHORT:"2530TK052 / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1677,SN:245,TAG:"2530E053",TAG_DESCR_SHORT:"2530E053 / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1678,SN:246,TAG:"2530E054",TAG_DESCR_SHORT:"2530E054 / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1679,SN:247,TAG:"2530P053B",TAG_DESCR_SHORT:"2530P053B / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1680,SN:248,TAG:"2530P053A",TAG_DESCR_SHORT:"2530P053A / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1681,SN:249,TAG:"2530E051",TAG_DESCR:"CGC SURFACE CONDENSER",TAG_DESCR_SHORT:"2530E051 / CGC SURFACE CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR & BT1 - Heat Exchangers",MR_NO:"TBD",MR_DESCR:"CGC SURFACE CONDENSER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1682,SN:250,TAG:"2530C001",TAG_DESCR:"CRACKED GAS COMPRESSOR",TAG_DESCR_SHORT:"2530C001 / CRACKED GAS COMPRESSOR",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-UL01",MR_DESCR:"CRACKED GAS COMPRESSOR 裂解气压缩机",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2021-04-25",MR_FORECAST:"2021-05-14",MR_ACTUAL:"2021-05-14",LOA_PLAN:"2021-10-30",LOA_FORECAST:"2021-10-31",LOA_ACTUAL:"2021-10-29",PO_PLAN:"2021-12-11",PO_FORECAST:"2021-12-10",PO_ACTUAL:"2021-12-17",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1683,SN:251,TAG:"2580E002",TAG_DESCR:"Vent Steam Exchanger",TAG_DESCR_SHORT:"2580E002 / Vent Steam Exchanger",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0410",MR_DESCR:"Vent Steam Exchanger",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-14",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-25",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1684,SN:252,TAG:"2580E003",TAG_DESCR:"LLP Polluted Condensate Exchanger",TAG_DESCR_SHORT:"2580E003 / LLP Polluted Condensate Exchanger",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0410",MR_DESCR:"LLP Polluted Condensate Exchanger",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-14",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-25",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1904,SN:472,TAG:"2536D011",TAG_DESCR:"HP DEPROPANIZER REBOILER COND POT",TAG_DESCR_SHORT:"2536D011 / HP DEPROPANIZER REBOILER COND POT",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0303",MR_DESCR:"HP DEPROPANIZER REBOILER COND POT",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1685,SN:253,TAG:"2590D001",TAG_DESCR:"COLD FLARE KNOCKOUT DRUM",TAG_DESCR_SHORT:"2590D001 / COLD FLARE KNOCKOUT DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0301",MR_DESCR:"COLD FLARE KNOCK-OUT DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1686,SN:254,TAG:"2590E002",TAG_DESCR:"COLD FLARE SUPERHEATER",TAG_DESCR_SHORT:"2590E002 / COLD FLARE SUPERHEATER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0407",MR_DESCR:"COLD FLARE SUPERHEATER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-04-05",MR_FORECAST:"2022-04-05",MR_ACTUAL:"2022-03-14",LOA_PLAN:"2022-08-19",LOA_FORECAST:"2022-07-14",LOA_ACTUAL:"2022-07-08",PO_PLAN:"2022-09-30",PO_FORECAST:"2022-08-25",PO_ACTUAL:"2022-08-30",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1687,SN:255,TAG:"2590E001",TAG_DESCR:"COLD FLARE BLOWDOWN VAPORIZER",TAG_DESCR_SHORT:"2590E001 / COLD FLARE BLOWDOWN VAPORIZER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0407",MR_DESCR:"COLD FLARE BLOWDOWN VAPORIZER 冷火炬汽化器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-04-05",MR_FORECAST:"2022-04-05",MR_ACTUAL:"2022-03-14",LOA_PLAN:"2022-08-19",LOA_FORECAST:"2022-07-14",LOA_ACTUAL:"2022-07-08",PO_PLAN:"2022-09-30",PO_FORECAST:"2022-08-25",PO_ACTUAL:"2022-08-30",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1688,SN:256,TAG:"2590P001A",TAG_DESCR:"METHANOL MAKEUP PUMPS",TAG_DESCR_SHORT:"2590P001A / METHANOL MAKEUP PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0630",MR_DESCR:"METHANOL MAKE-UP PUMPS 计量泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-03-28",MR_FORECAST:"2022-03-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-07-26",LOA_FORECAST:"2022-09-20",LOA_ACTUAL:"2022-09-15",PO_PLAN:"2022-09-06",PO_FORECAST:"2022-11-01",PO_ACTUAL:"2022-10-21",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1689,SN:257,TAG:"2590P001B",TAG_DESCR:"METHANOL MAKEUP PUMPS",TAG_DESCR_SHORT:"2590P001B / METHANOL MAKEUP PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0630",MR_DESCR:"METHANOL MAKE-UP PUMPS 计量泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-03-28",MR_FORECAST:"2022-03-28",MR_ACTUAL:"2022-02-21",LOA_PLAN:"2022-07-26",LOA_FORECAST:"2022-09-20",LOA_ACTUAL:"2022-09-15",PO_PLAN:"2022-09-06",PO_FORECAST:"2022-11-01",PO_ACTUAL:"2022-10-21",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1690,SN:258,TAG:"2590E003",TAG_DESCR:"METHANOL VAPORIZER",TAG_DESCR_SHORT:"2590E003 / METHANOL VAPORIZER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0407",MR_DESCR:"METHANOL VAPORIZER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-04-05",MR_FORECAST:"2022-04-05",MR_ACTUAL:"2022-03-14",LOA_PLAN:"2022-08-19",LOA_FORECAST:"2022-07-14",LOA_ACTUAL:"2022-07-08",PO_PLAN:"2022-09-30",PO_FORECAST:"2022-08-25",PO_ACTUAL:"2022-08-30",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1691,SN:259,TAG:"2536D061",TAG_DESCR:"LP DEPROPANIZER REBOILER COND POT",TAG_DESCR_SHORT:"2536D061 / LP DEPROPANIZER REBOILER COND POT",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0303",MR_DESCR:"LP DEPROPANIZER REBOILER COND POT",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1692,SN:260,TAG:"2536E061A",TAG_DESCR:"LP DEPROPANIZER STEAM REBOILER",TAG_DESCR_SHORT:"2536E061A / LP DEPROPANIZER STEAM REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0408",MR_DESCR:"LP DEPROPANIZER STEAM REBOILER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-05",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-16",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1693,SN:261,TAG:"2536T051",TAG_DESCR:"LP DEPROPANIZER 1600 LTCS 44 552 TOP cold BTM hot",TAG_DESCR_SHORT:"2536T051 / LP DEPROPANIZER 1600 LTCS 44 552 TOP c...",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL12",MR_DESCR:"LP DEPROPANIZER低压脱丙烷塔1600吊车（壳体LTCS、内径4.4、高度55.2、TOP cold BTM hot）",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2021-11-23",MR_FORECAST:"2021-11-23",MR_ACTUAL:"2021-11-05",LOA_PLAN:"2022-02-18",LOA_FORECAST:"2022-02-03",LOA_ACTUAL:"2022-02-14",PO_PLAN:"2022-04-01",PO_FORECAST:"2022-03-17",PO_ACTUAL:"2022-03-28",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1694,SN:262,TAG:"2536E061B",TAG_DESCR:"LP DEPROPANIZER STEAM REBOILER",TAG_DESCR_SHORT:"2536E061B / LP DEPROPANIZER STEAM REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0408",MR_DESCR:"LP DEPROPANIZER STEAM REBOILER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-05",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-16",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1695,SN:263,TAG:"2536P065A",TAG_DESCR:"LP DEPROPANIZER REFLUXPRODUCT PUMP",TAG_DESCR_SHORT:"2536P065A / LP DEPROPANIZER REFLUXPRODUCT PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0620",MR_DESCR:"LP DEPROPANIZER REFLUX/PRODUCT PUMP",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1696,SN:264,TAG:"2536D065",TAG_DESCR:"LP DEPROPANIZER REFLUX DRUM",TAG_DESCR_SHORT:"2536D065 / LP DEPROPANIZER REFLUX DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0302",MR_DESCR:"LP DEPROPANIZER REFLUX DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1697,SN:265,TAG:"2536P065B",TAG_DESCR:"LP DEPROPANIZER REFLUXPRODUCT PUMP",TAG_DESCR_SHORT:"2536P065B / LP DEPROPANIZER REFLUXPRODUCT PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0620",MR_DESCR:"LP DEPROPANIZER REFLUX/PRODUCT PUMP",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1698,SN:266,TAG:"2536E065",TAG_DESCR:"LP DEPROPANIZER CONDENSERx000D",TAG_DESCR_SHORT:"2536E065 / LP DEPROPANIZER CONDENSERx000D",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0406",MR_DESCR:"LP DEPROPANIZER CONDENSER_x000D_ 高通量",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-03-08",MR_FORECAST:"2022-03-08",MR_ACTUAL:"2022-01-29",LOA_PLAN:"2022-06-29",LOA_FORECAST:"2022-06-23",LOA_ACTUAL:"2022-06-21",PO_PLAN:"2022-08-10",PO_FORECAST:"2022-08-04",PO_ACTUAL:"2022-07-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1699,SN:267,TAG:"2554E010A",TAG_DESCR:"C3 STRIPPER QW REBOILERS",TAG_DESCR_SHORT:"2554E010A / C3 STRIPPER QW REBOILERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0408",MR_DESCR:"C3 STRIPPER QW REBOILERS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-19",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-30",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1700,SN:268,TAG:"2554P012A",TAG_DESCR:"C3 RECTIFIER TRANSFER PUMPS",TAG_DESCR_SHORT:"2554P012A / C3 RECTIFIER TRANSFER PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-SL01",MR_DESCR:"C3 RECTIFIER TRANSFER PUMPS 丙烯精馏塔釜输送泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2021-08-08",MR_FORECAST:"2021-09-30",MR_ACTUAL:"2021-09-30",LOA_PLAN:"2022-01-04",LOA_FORECAST:"2022-01-03",LOA_ACTUAL:"2021-12-30",PO_PLAN:"2022-02-15",PO_FORECAST:"2022-02-28",PO_ACTUAL:"2022-03-11",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1701,SN:269,TAG:"2554P012B",TAG_DESCR:"C3 RECTIFIER TRANSFER PUMPS",TAG_DESCR_SHORT:"2554P012B / C3 RECTIFIER TRANSFER PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-SL01",MR_DESCR:"C3 RECTIFIER TRANSFER PUMPS 丙烯精馏塔釜输送泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2021-08-08",MR_FORECAST:"2021-09-30",MR_ACTUAL:"2021-09-30",LOA_PLAN:"2022-01-04",LOA_FORECAST:"2022-01-03",LOA_ACTUAL:"2021-12-30",PO_PLAN:"2022-02-15",PO_FORECAST:"2022-02-28",PO_ACTUAL:"2022-03-11",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1702,SN:270,TAG:"2554T002",TAG_DESCR:"C3 RECTIFIER 2 LTCS CS87 8742",TAG_DESCR_SHORT:"2554T002 / C3 RECTIFIER 2 LTCS CS87 8742",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-LL05",MR_DESCR:"C3 RECTIFIER/丙烯精馏塔（2号丙烯塔） （壳体材质LTCS、内件材质CS,内径8.7米，高87.42米， ）",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2021-07-20",MR_FORECAST:"2021-08-02",MR_ACTUAL:"2021-08-02",LOA_PLAN:"2021-10-30",LOA_FORECAST:"2021-10-19",LOA_ACTUAL:"2021-10-14",PO_PLAN:"2021-12-11",PO_FORECAST:"2021-11-30",PO_ACTUAL:"2021-11-08",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1703,SN:271,TAG:"2554E012A",TAG_DESCR:"C3 RECTIFIER REBOILER",TAG_DESCR_SHORT:"2554E012A / C3 RECTIFIER REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0408",MR_DESCR:"C3 RECTIFIER REBOILER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-19",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-30",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1704,SN:272,TAG:"2554E012B",TAG_DESCR:"C3 RECTIFIER REBOILER",TAG_DESCR_SHORT:"2554E012B / C3 RECTIFIER REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0408",MR_DESCR:"C3 RECTIFIER REBOILER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-19",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-30",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1705,SN:273,TAG:"2554E010B",TAG_DESCR:"C3 STRIPPER QW REBOILERS",TAG_DESCR_SHORT:"2554E010B / C3 STRIPPER QW REBOILERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0408",MR_DESCR:"C3 STRIPPER QW REBOILERS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-19",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-30",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1706,SN:274,TAG:"2554E011",TAG_DESCR:"C3 STRIPPER STEAM REBOILER",TAG_DESCR_SHORT:"2554E011 / C3 STRIPPER STEAM REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0408",MR_DESCR:"C3 STRIPPER STEAM REBOILER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-19",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-30",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1707,SN:275,TAG:"2554T001",TAG_DESCR:"C3 STRIPPERC3 1 LTCS CS75 46",TAG_DESCR_SHORT:"2554T001 / C3 STRIPPERC3 1 LTCS CS75 46",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-LL05",MR_DESCR:"C3 STRIPPER/C3汽提塔（1号丙烯塔） （壳体材质LTCS、内件材质CS,内径7.5米，高46米 ）",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2021-07-20",MR_FORECAST:"2021-08-02",MR_ACTUAL:"2021-08-02",LOA_PLAN:"2021-10-30",LOA_FORECAST:"2021-10-19",LOA_ACTUAL:"2021-10-14",PO_PLAN:"2021-12-11",PO_FORECAST:"2021-11-30",PO_ACTUAL:"2021-11-08",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1708,SN:276,TAG:"2554E010C",TAG_DESCR:"C3 STRIPPER QW REBOILERS",TAG_DESCR_SHORT:"2554E010C / C3 STRIPPER QW REBOILERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0408",MR_DESCR:"C3 STRIPPER QW REBOILERS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-19",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-30",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1709,SN:277,TAG:"2554E010D",TAG_DESCR:"C3 STRIPPER QW REBOILERS",TAG_DESCR_SHORT:"2554E010D / C3 STRIPPER QW REBOILERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0408",MR_DESCR:"C3 STRIPPER QW REBOILERS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-19",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-30",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1710,SN:278,TAG:"2536P061B",TAG_DESCR:"LP DEPROPANIZER REBOILER COND PUMP",TAG_DESCR_SHORT:"2536P061B / LP DEPROPANIZER REBOILER COND PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0621",MR_DESCR:"LP DEPROPANIZER REBOILER COND PUMP",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1711,SN:279,TAG:"2536P061A",TAG_DESCR:"LP DEPROPANIZER REBOILER COND PUMP",TAG_DESCR_SHORT:"2536P061A / LP DEPROPANIZER REBOILER COND PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0621",MR_DESCR:"LP DEPROPANIZER REBOILER COND PUMP",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1712,SN:280,TAG:"2552DR001",TAG_DESCR:"ARSINE REMOVAL BED",TAG_DESCR_SHORT:"2552DR001 / ARSINE REMOVAL BED",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR & BT1  - Static",MR_NO:"TBD",MR_DESCR:"ARSINE REMOVAL BED",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1713,SN:281,TAG:"2552R001",TAG_DESCR:"C3 HYDROGENATION REACTORC3 LTCS 2 605",TAG_DESCR_SHORT:"2552R001 / C3 HYDROGENATION REACTORC3 LTCS 2 605",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL08",MR_DESCR:"C3 HYDROGENATION REACTOR/C3加氢反应器 （壳体LTCS、内径2米、高度6.05米）",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-03-30",MR_FORECAST:"2022-03-30",MR_ACTUAL:"2022-02-07",LOA_PLAN:"2022-07-24",LOA_FORECAST:"2022-08-07",LOA_ACTUAL:"2022-08-10",PO_PLAN:"2022-09-04",PO_FORECAST:"2022-09-28",PO_ACTUAL:"2022-09-28",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1714,SN:282,TAG:"2552E002",TAG_DESCR:"ELECTRIC HEATER",TAG_DESCR_SHORT:"2552E002 / ELECTRIC HEATER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EE-R-E-4001",MR_DESCR:"ELECTRIC HEATER 电加热器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-06-28",MR_FORECAST:"2022-06-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-10-22",LOA_FORECAST:"2022-09-20",LOA_ACTUAL:"2022-09-15",PO_PLAN:"2022-12-03",PO_FORECAST:"2022-11-01",CRITICAL:"NON",STATUS:"LOA Issued",STATUS_CODE:"LOA",STATUS_SEQ:2},
      {NO:1715,SN:283,TAG:"2552D001",TAG_DESCR:"C3 HYDROGENATION RECYCLE DRUM",TAG_DESCR_SHORT:"2552D001 / C3 HYDROGENATION RECYCLE DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0302",MR_DESCR:"C3 HYDROGENATION RECYCLE DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1716,SN:284,TAG:"2552E001",TAG_DESCR:"C3 HYDROGENATION AFTERCOOLER",TAG_DESCR_SHORT:"2552E001 / C3 HYDROGENATION AFTERCOOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0408",MR_DESCR:"C3 HYDROGENATION AFTERCOOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-19",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-30",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1717,SN:285,TAG:"2555E020",TAG_DESCR:"RAW PYGAS COOLER",TAG_DESCR_SHORT:"2555E020 / RAW PYGAS COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0407",MR_DESCR:"RAW PYGAS COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-04-05",MR_FORECAST:"2022-04-05",MR_ACTUAL:"2022-03-14",LOA_PLAN:"2022-08-19",LOA_FORECAST:"2022-07-14",LOA_ACTUAL:"2022-07-08",PO_PLAN:"2022-09-30",PO_FORECAST:"2022-08-25",PO_ACTUAL:"2022-08-30",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1718,SN:286,TAG:"2555T001",TAG_DESCR:"DEBUTANIZER KCS 35 3445",TAG_DESCR_SHORT:"2555T001 / DEBUTANIZER KCS 35 3445",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0101",MR_DESCR:"DEBUTANIZER脱丁烷塔 （壳体KCS、内径3.5、高度34.45米",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2021-11-28",MR_FORECAST:"2021-11-28",MR_ACTUAL:"2021-11-24",LOA_PLAN:"2022-02-28",LOA_FORECAST:"2022-03-11",LOA_ACTUAL:"2022-03-23",PO_PLAN:"2022-04-11",PO_FORECAST:"2022-04-22",PO_ACTUAL:"2022-04-13",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1719,SN:287,TAG:"2555E010A",TAG_DESCR:"DEBUTANIZER REBOILERS",TAG_DESCR_SHORT:"2555E010A / DEBUTANIZER REBOILERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0409",MR_DESCR:"DEBUTANIZER REBOILERS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-14",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-25",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-07-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1720,SN:288,TAG:"2555E010B",TAG_DESCR:"DEBUTANIZER REBOILERS",TAG_DESCR_SHORT:"2555E010B / DEBUTANIZER REBOILERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0409",MR_DESCR:"DEBUTANIZER REBOILERS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-14",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-25",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-07-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1721,SN:289,TAG:"2554E017",TAG_DESCR:"PROPYLENE PRODUCT COOLER",TAG_DESCR_SHORT:"2554E017 / PROPYLENE PRODUCT COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0408",MR_DESCR:"PROPYLENE PRODUCT COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-05",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-16",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1722,SN:290,TAG:"2254E015A",TAG_DESCR_SHORT:"2254E015A / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1723,SN:291,TAG:"2254E015B",TAG_DESCR_SHORT:"2254E015B / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1724,SN:292,TAG:"2254E015C",TAG_DESCR_SHORT:"2254E015C / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1725,SN:293,TAG:"2554E016",TAG_DESCR:"C3 RECTIFIER VENT CONDENSER",TAG_DESCR_SHORT:"2554E016 / C3 RECTIFIER VENT CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0408",MR_DESCR:"C3 RECTIFIER VENT CONDENSER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-05",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-16",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1726,SN:294,TAG:"2555E015",TAG_DESCR:"DEBUTANIZER CONDENSER",TAG_DESCR_SHORT:"2555E015 / DEBUTANIZER CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0410",MR_DESCR:"DEBUTANIZER CONDENSER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-14",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-25",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1727,SN:295,TAG:"2555D015",TAG_DESCR:"DEBUTANIZER REFLUX DRUM",TAG_DESCR_SHORT:"2555D015 / DEBUTANIZER REFLUX DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0303",MR_DESCR:"DEBUTANIZER REFLUX DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1728,SN:296,TAG:"2554D015",TAG_DESCR:"C3 RECTIFIER REFLUX DRUM",TAG_DESCR_SHORT:"2554D015 / C3 RECTIFIER REFLUX DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0301",MR_DESCR:"C3 RECTIFIER REFLUX DRUM 丙烯塔回流罐",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1729,SN:297,TAG:"2554P015A",TAG_DESCR:"C3 RECTIFIER REFLUX PUMPS",TAG_DESCR_SHORT:"2554P015A / C3 RECTIFIER REFLUX PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-SL01",MR_DESCR:"C3 RECTIFIER REFLUX PUMPS 丙烯精馏塔釜回流泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2021-08-08",MR_FORECAST:"2021-09-30",MR_ACTUAL:"2021-09-30",LOA_PLAN:"2022-01-04",LOA_FORECAST:"2022-01-03",LOA_ACTUAL:"2021-12-30",PO_PLAN:"2022-02-15",PO_FORECAST:"2022-02-28",PO_ACTUAL:"2022-03-11",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1730,SN:298,TAG:"2554P015B",TAG_DESCR:"C3 RECTIFIER REFLUX PUMPS",TAG_DESCR_SHORT:"2554P015B / C3 RECTIFIER REFLUX PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-SL01",MR_DESCR:"C3 RECTIFIER REFLUX PUMPS 丙烯精馏塔釜回流泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2021-08-08",MR_FORECAST:"2021-09-30",MR_ACTUAL:"2021-09-30",LOA_PLAN:"2022-01-04",LOA_FORECAST:"2022-01-03",LOA_ACTUAL:"2021-12-30",PO_PLAN:"2022-02-15",PO_FORECAST:"2022-02-28",PO_ACTUAL:"2022-03-11",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1731,SN:299,TAG:"2552P001A",TAG_DESCR:"C3 HYDROGENATION RECYCLE PUMP",TAG_DESCR_SHORT:"2552P001A / C3 HYDROGENATION RECYCLE PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0620",MR_DESCR:"C3 HYDROGENATION RECYCLE PUMP",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1732,SN:300,TAG:"2552P001B",TAG_DESCR:"C3 HYDROGENATION RECYCLE PUMP",TAG_DESCR_SHORT:"2552P001B / C3 HYDROGENATION RECYCLE PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0620",MR_DESCR:"C3 HYDROGENATION RECYCLE PUMP",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1733,SN:301,TAG:"2555P015A",TAG_DESCR:"DEBUTANIZER REFLUX PRODUCT PUMPS",TAG_DESCR_SHORT:"2555P015A / DEBUTANIZER REFLUX PRODUCT PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0620",MR_DESCR:"DEBUTANIZER REFLUX / PRODUCT PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1734,SN:302,TAG:"2555P015B",TAG_DESCR:"DEBUTANIZER REFLUX PRODUCT PUMPS",TAG_DESCR_SHORT:"2555P015B / DEBUTANIZER REFLUX PRODUCT PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0620",MR_DESCR:"DEBUTANIZER REFLUX / PRODUCT PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1735,SN:303,TAG:"2555P010A",TAG_DESCR:"DEBUTANIZER REBOILER CONDENSATE PUMPS",TAG_DESCR_SHORT:"2555P010A / DEBUTANIZER REBOILER CONDENSATE PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0621",MR_DESCR:"DEBUTANIZER REBOILER CONDENSATE PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1736,SN:304,TAG:"2555P010B",TAG_DESCR:"DEBUTANIZER REBOILER CONDENSATE PUMPS",TAG_DESCR_SHORT:"2555P010B / DEBUTANIZER REBOILER CONDENSATE PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0621",MR_DESCR:"DEBUTANIZER REBOILER CONDENSATE PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1737,SN:305,TAG:"2555P020A",TAG_DESCR:"DEBUTANIZER BOTTOMS PUMPS",TAG_DESCR_SHORT:"2555P020A / DEBUTANIZER BOTTOMS PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0620",MR_DESCR:"DEBUTANIZER BOTTOMS PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1738,SN:306,TAG:"2555P020B",TAG_DESCR:"DEBUTANIZER BOTTOMS PUMPS",TAG_DESCR_SHORT:"2555P020B / DEBUTANIZER BOTTOMS PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0620",MR_DESCR:"DEBUTANIZER BOTTOMS PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1905,SN:473,TAG:"2555D010",TAG_DESCR:"DEBUTANIZER REBOILER CONDENSATE DRUM",TAG_DESCR_SHORT:"2555D010 / DEBUTANIZER REBOILER CONDENSATE DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0303",MR_DESCR:"DEBUTANIZER REBOILER CONDENSATE DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1739,SN:307,TAG:"2522E020",TAG_DESCR:"PRIMARY QUENCH WATER TRIM COOLER",TAG_DESCR_SHORT:"2522E020 / PRIMARY QUENCH WATER TRIM COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0409",MR_DESCR:"PRIMARY QUENCH WATER TRIM COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-14",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-25",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-07-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1740,SN:308,TAG:"2522T001",TAG_DESCR:"QUENCH WATER TOWER KCS KCS 113 404",TAG_DESCR_SHORT:"2522T001 / QUENCH WATER TOWER KCS KCS 113 404",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-LL03",MR_DESCR:"QUENCH WATER TOWER 急冷水塔（壳体KCS,内件KCS、内径11.3米，高40.4米 ）",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2021-07-10",MR_FORECAST:"2021-07-10",MR_ACTUAL:"2021-07-15",LOA_PLAN:"2021-10-30",LOA_FORECAST:"2021-10-19",LOA_ACTUAL:"2021-10-14",PO_PLAN:"2021-12-11",PO_FORECAST:"2021-11-30",PO_ACTUAL:"2021-11-08",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1741,SN:309,TAG:"2522P025A",TAG_DESCR:"PRIMARY FRACTIONATOR REFLUX PUMPS",TAG_DESCR_SHORT:"2522P025A / PRIMARY FRACTIONATOR REFLUX PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0620",MR_DESCR:"PRIMARY FRACTIONATOR REFLUX PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1742,SN:310,TAG:"2522P025B",TAG_DESCR:"PRIMARY FRACTIONATOR REFLUX PUMPS",TAG_DESCR_SHORT:"2522P025B / PRIMARY FRACTIONATOR REFLUX PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0620",MR_DESCR:"PRIMARY FRACTIONATOR REFLUX PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1743,SN:311,TAG:"2522P020A",TAG_DESCR:"QUENCH WATER CIRCULATING PUMPS",TAG_DESCR_SHORT:"2522P020A / QUENCH WATER CIRCULATING PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-SL01",MR_DESCR:"QUENCH WATER CIRCULATING PUMPS 急冷水循环泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2021-08-08",MR_FORECAST:"2021-09-30",MR_ACTUAL:"2021-09-30",LOA_PLAN:"2022-01-04",LOA_FORECAST:"2022-01-03",LOA_ACTUAL:"2021-12-30",PO_PLAN:"2022-02-15",PO_FORECAST:"2022-02-28",PO_ACTUAL:"2022-03-11",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1744,SN:312,TAG:"2522P020B",TAG_DESCR:"QUENCH WATER CIRCULATING PUMPS",TAG_DESCR_SHORT:"2522P020B / QUENCH WATER CIRCULATING PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-SL01",MR_DESCR:"QUENCH WATER CIRCULATING PUMPS 急冷水循环泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2021-08-08",MR_FORECAST:"2021-09-30",MR_ACTUAL:"2021-09-30",LOA_PLAN:"2022-01-04",LOA_FORECAST:"2022-01-03",LOA_ACTUAL:"2021-12-30",PO_PLAN:"2022-02-15",PO_FORECAST:"2022-02-28",PO_ACTUAL:"2022-03-11",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1745,SN:313,TAG:"2522P020D",TAG_DESCR:"QUENCH WATER CIRCULATING PUMPS",TAG_DESCR_SHORT:"2522P020D / QUENCH WATER CIRCULATING PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-SL01",MR_DESCR:"QUENCH WATER CIRCULATING PUMPS 急冷水循环泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2021-08-08",MR_FORECAST:"2021-09-30",MR_ACTUAL:"2021-09-30",LOA_PLAN:"2022-01-04",LOA_FORECAST:"2022-01-03",LOA_ACTUAL:"2021-12-30",PO_PLAN:"2022-02-15",PO_FORECAST:"2022-02-28",PO_ACTUAL:"2022-03-11",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1746,SN:314,TAG:"2522P020C",TAG_DESCR:"QUENCH WATER CIRCULATING PUMPS",TAG_DESCR_SHORT:"2522P020C / QUENCH WATER CIRCULATING PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-SL01",MR_DESCR:"QUENCH WATER CIRCULATING PUMPS 急冷水循环泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2021-08-08",MR_FORECAST:"2021-09-30",MR_ACTUAL:"2021-09-30",LOA_PLAN:"2022-01-04",LOA_FORECAST:"2022-01-03",LOA_ACTUAL:"2021-12-30",PO_PLAN:"2022-02-15",PO_FORECAST:"2022-02-28",PO_ACTUAL:"2022-03-11",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1747,SN:315,TAG:"2522STP020B",TAG_DESCR_SHORT:"2522STP020B / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1748,SN:316,TAG:"2522STP020A",TAG_DESCR_SHORT:"2522STP020A / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1749,SN:317,TAG:"2522T051",TAG_DESCR:"DISTILLATE STRIPPER KCS 27 233",TAG_DESCR_SHORT:"2522T051 / DISTILLATE STRIPPER KCS 27 233",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0101",MR_DESCR:"DISTILLATE STRIPPER汽油汽提塔 （壳体KCS、内径2.7、高度23.3米",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2021-11-28",MR_FORECAST:"2021-11-28",MR_ACTUAL:"2021-11-24",LOA_PLAN:"2022-02-28",LOA_FORECAST:"2022-03-11",LOA_ACTUAL:"2022-03-23",PO_PLAN:"2022-04-11",PO_FORECAST:"2022-04-22",PO_ACTUAL:"2022-04-13",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1750,SN:318,TAG:"2522P070A",TAG_DESCR:"DISTILLATE STRIPPER BOTTOM PUMPS",TAG_DESCR_SHORT:"2522P070A / DISTILLATE STRIPPER BOTTOM PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0620",MR_DESCR:"DISTILLATE STRIPPER BOTTOM PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1751,SN:319,TAG:"2522P070B",TAG_DESCR:"DISTILLATE STRIPPER BOTTOM PUMPS",TAG_DESCR_SHORT:"2522P070B / DISTILLATE STRIPPER BOTTOM PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0620",MR_DESCR:"DISTILLATE STRIPPER BOTTOM PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1752,SN:320,TAG:"2526P021A",TAG_DESCR:"PROCESS WATER PURGE PUMP",TAG_DESCR_SHORT:"2526P021A / PROCESS WATER PURGE PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0621",MR_DESCR:"PROCESS WATER PURGE PUMP",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1753,SN:321,TAG:"2526D015",TAG_DESCR:"PW STRIPPER FEED HEATER COND POT",TAG_DESCR_SHORT:"2526D015 / PW STRIPPER FEED HEATER COND POT",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR & BT1  - Static",MR_NO:"TBD",MR_DESCR:"PW STRIPPER FEED HEATER COND POT",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1754,SN:322,TAG:"2526FIL005A",TAG_DESCR:"PROCESS WATER STRIPPER FEED FILTERS",TAG_DESCR_SHORT:"2526FIL005A / PROCESS WATER STRIPPER FEED FILTERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0501",MR_DESCR:"PROCESS WATER STRIPPER FEED FILTERS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-08",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-04",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-28",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1755,SN:323,TAG:"2526FIL005B",TAG_DESCR:"PROCESS WATER STRIPPER FEED FILTERS",TAG_DESCR_SHORT:"2526FIL005B / PROCESS WATER STRIPPER FEED FILTERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0501",MR_DESCR:"PROCESS WATER STRIPPER FEED FILTERS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-02-08",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-08-04",LOA_ACTUAL:"2022-08-16",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-09-28",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1756,SN:324,TAG:"2529PK020",TAG_DESCR:"NEUTRALIZATION AMINE INJECTION PACKAGE",TAG_DESCR_SHORT:"2529PK020 / NEUTRALIZATION AMINE INJECTION PACKAGE",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0610",MR_DESCR:"NEUTRALIZATION AMINE INJECTION PACKAGE",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2022-09-01",MR_FORECAST:"2022-09-01",MR_ACTUAL:"2022-08-10",LOA_PLAN:"2022-11-15",LOA_FORECAST:"2022-12-28",LOA_ACTUAL:"2022-12-27",PO_PLAN:"2022-12-27",PO_FORECAST:"2023-02-08",PO_ACTUAL:"2023-01-30",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1757,SN:325,TAG:"2529PK030",TAG_DESCR:"EMULSION BREAKER INJECTION PACKAGE",TAG_DESCR_SHORT:"2529PK030 / EMULSION BREAKER INJECTION PACKAGE",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0610",MR_DESCR:"EMULSION BREAKER INJECTION PACKAGE",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2022-09-01",MR_FORECAST:"2022-09-01",MR_ACTUAL:"2022-08-10",LOA_PLAN:"2022-11-15",LOA_FORECAST:"2022-12-28",LOA_ACTUAL:"2022-12-27",PO_PLAN:"2022-12-27",PO_FORECAST:"2023-02-08",PO_ACTUAL:"2023-01-30",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1758,SN:326,TAG:"2522E061A",TAG_DESCR:"DISTILLATE STRIPPER REBOILER",TAG_DESCR_SHORT:"2522E061A / DISTILLATE STRIPPER REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0409",MR_DESCR:"DISTILLATE STRIPPER REBOILER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-14",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-25",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-07-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1759,SN:327,TAG:"2526D006",TAG_DESCR:"PROCESS WATER PURGE DRUM",TAG_DESCR_SHORT:"2526D006 / PROCESS WATER PURGE DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0303",MR_DESCR:"PROCESS WATER PURGE DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1760,SN:328,TAG:"2522E061B",TAG_DESCR:"DISTILLATE STRIPPER REBOILER",TAG_DESCR_SHORT:"2522E061B / DISTILLATE STRIPPER REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0409",MR_DESCR:"DISTILLATE STRIPPER REBOILER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-14",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-25",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-07-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1761,SN:329,TAG:"2526E005",TAG_DESCR:"PROCESS WATER STRIPPER FEED HEATER",TAG_DESCR_SHORT:"2526E005 / PROCESS WATER STRIPPER FEED HEATER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0410",MR_DESCR:"PROCESS WATER STRIPPER FEED HEATER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-14",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-25",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1762,SN:330,TAG:"2526E006",TAG_DESCR:"PROCESS WATER PURGE CONDENSER",TAG_DESCR_SHORT:"2526E006 / PROCESS WATER PURGE CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0409",MR_DESCR:"PROCESS WATER PURGE CONDENSER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-14",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-25",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-07-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1763,SN:331,TAG:"2526D005",TAG_DESCR:"PROCESS WATER STRIPPER FEED COALESCER",TAG_DESCR_SHORT:"2526D005 / PROCESS WATER STRIPPER FEED COALESCER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0506",MR_DESCR:"PROCESS WATER STRIPPER FEED COALESCER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2022-03-18",MR_FORECAST:"2022-03-18",MR_ACTUAL:"2022-02-07",LOA_PLAN:"2022-07-14",LOA_FORECAST:"2022-05-26",LOA_ACTUAL:"2022-05-25",PO_PLAN:"2022-08-25",PO_FORECAST:"2022-07-07",PO_ACTUAL:"2022-06-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1764,SN:332,TAG:"2526E010A",TAG_DESCR:"PROCESS WATER STRIPPER REBOILER",TAG_DESCR_SHORT:"2526E010A / PROCESS WATER STRIPPER REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0410",MR_DESCR:"PROCESS WATER STRIPPER REBOILER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-14",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-25",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1765,SN:333,TAG:"2526T001",TAG_DESCR:"PROCESS WATER STRIPPER KCS 35 141",TAG_DESCR_SHORT:"2526T001 / PROCESS WATER STRIPPER KCS 35 141",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0101",MR_DESCR:"PROCESS WATER STRIPPER工艺水汽提塔 （壳体KCS、内径3.5、高度14.1米",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2021-11-28",MR_FORECAST:"2021-11-28",MR_ACTUAL:"2021-11-24",LOA_PLAN:"2022-02-28",LOA_FORECAST:"2022-03-11",LOA_ACTUAL:"2022-03-23",PO_PLAN:"2022-04-11",PO_FORECAST:"2022-04-22",PO_ACTUAL:"2022-04-13",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1766,SN:334,TAG:"2526E010B",TAG_DESCR:"PROCESS WATER STRIPPER REBOILER",TAG_DESCR_SHORT:"2526E010B / PROCESS WATER STRIPPER REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0410",MR_DESCR:"PROCESS WATER STRIPPER REBOILER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-14",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-25",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1767,SN:335,TAG:"2526P020A",TAG_DESCR:"DSG FEED PUMPS",TAG_DESCR_SHORT:"2526P020A / DSG FEED PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-SL02",MR_DESCR:"DSG FEED PUMPS稀释蒸汽给料泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-06",LOA_PLAN:"2022-04-13",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-07-06",PO_PLAN:"2022-05-25",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1768,SN:336,TAG:"2526P020B",TAG_DESCR:"DSG FEED PUMPS",TAG_DESCR_SHORT:"2526P020B / DSG FEED PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-SL02",MR_DESCR:"DSG FEED PUMPS稀释蒸汽给料泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2021-12-28",MR_FORECAST:"2021-12-28",MR_ACTUAL:"2021-12-06",LOA_PLAN:"2022-04-13",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-07-06",PO_PLAN:"2022-05-25",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1769,SN:337,TAG:"2529PK060",TAG_DESCR:"PWS PHOSPHATE INJECTION PACKAGE",TAG_DESCR_SHORT:"2529PK060 / PWS PHOSPHATE INJECTION PACKAGE",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0610",MR_DESCR:"PWS PHOSPHATE INJECTION PACKAGE",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2022-09-01",MR_FORECAST:"2022-09-01",MR_ACTUAL:"2022-08-10",LOA_PLAN:"2022-11-15",LOA_FORECAST:"2022-12-28",LOA_ACTUAL:"2022-12-27",PO_PLAN:"2022-12-27",PO_FORECAST:"2023-02-08",PO_ACTUAL:"2023-01-30",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1770,SN:338,TAG:"2521E025C",TAG_DESCR:"QO TRIM COOLER 6 CS",TAG_DESCR_SHORT:"2521E025C / QO TRIM COOLER 6 CS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0409",MR_DESCR:"QO TRIM COOLER 6) 管壳换热器六(CS)",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-14",LOA_FORECAST:"2022-06-24",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-25",PO_FORECAST:"2022-08-05",PO_ACTUAL:"2022-07-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1771,SN:339,TAG:"2521E025B",TAG_DESCR:"QO TRIM COOLER 6 CS",TAG_DESCR_SHORT:"2521E025B / QO TRIM COOLER 6 CS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0409",MR_DESCR:"QO TRIM COOLER 6) 管壳换热器六(CS)",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-14",LOA_FORECAST:"2022-06-24",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-25",PO_FORECAST:"2022-08-05",PO_ACTUAL:"2022-07-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1772,SN:340,TAG:"2521E025A",TAG_DESCR:"QO TRIM COOLER 6 CS",TAG_DESCR_SHORT:"2521E025A / QO TRIM COOLER 6 CS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0409",MR_DESCR:"QO TRIM COOLER 6) 管壳换热器六(CS)",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-14",LOA_FORECAST:"2022-06-24",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-25",PO_FORECAST:"2022-08-05",PO_ACTUAL:"2022-07-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1773,SN:341,TAG:"2521FIL022A",TAG_DESCR:"QUENCH OIL DISCHARGE STRAINERS",TAG_DESCR_SHORT:"2521FIL022A / QUENCH OIL DISCHARGE STRAINERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0504",MR_DESCR:"QUENCH OIL DISCHARGE STRAINERS急冷油旋液分离器静置器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-06-30",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-08-11",PO_ACTUAL:"2022-08-29",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1774,SN:342,TAG:"2521FIL022B",TAG_DESCR:"QUENCH OIL DISCHARGE STRAINERS",TAG_DESCR_SHORT:"2521FIL022B / QUENCH OIL DISCHARGE STRAINERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0504",MR_DESCR:"QUENCH OIL DISCHARGE STRAINERS急冷油旋液分离器静置器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-06-30",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-08-11",PO_ACTUAL:"2022-08-29",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1775,SN:343,TAG:"2521FIL022C",TAG_DESCR:"QUENCH OIL DISCHARGE STRAINERS",TAG_DESCR_SHORT:"2521FIL022C / QUENCH OIL DISCHARGE STRAINERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0504",MR_DESCR:"QUENCH OIL DISCHARGE STRAINERS急冷油旋液分离器静置器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-06-30",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-08-11",PO_ACTUAL:"2022-08-29",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1776,SN:344,TAG:"2521FIL021C",TAG_DESCR:"QUENCH OIL FILTERS",TAG_DESCR_SHORT:"2521FIL021C / QUENCH OIL FILTERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0504",MR_DESCR:"QUENCH OIL FILTERS急冷油旋液分离器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-06-30",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-08-11",PO_ACTUAL:"2022-08-29",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1777,SN:345,TAG:"2521FIL021B",TAG_DESCR:"QUENCH OIL FILTERS",TAG_DESCR_SHORT:"2521FIL021B / QUENCH OIL FILTERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0504",MR_DESCR:"QUENCH OIL FILTERS急冷油旋液分离器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-06-30",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-08-11",PO_ACTUAL:"2022-08-29",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1778,SN:346,TAG:"2521FIL021A",TAG_DESCR:"QUENCH OIL FILTERS",TAG_DESCR_SHORT:"2521FIL021A / QUENCH OIL FILTERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0504",MR_DESCR:"QUENCH OIL FILTERS急冷油旋液分离器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-06-30",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-08-11",PO_ACTUAL:"2022-08-29",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1779,SN:347,TAG:"2521P020AY",TAG_DESCR_SHORT:"2521P020AY / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1780,SN:348,TAG:"2521P020BY",TAG_DESCR_SHORT:"2521P020BY / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1781,SN:349,TAG:"2521P020A",TAG_DESCR:"QUENCH OIL CIRCULATING PUMPS",TAG_DESCR_SHORT:"2521P020A / QUENCH OIL CIRCULATING PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-LL02",MR_DESCR:"QUENCH OIL CIRCULATING PUMPS 急冷油循环泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2021-08-08",MR_FORECAST:"2021-09-30",MR_ACTUAL:"2021-09-27",LOA_PLAN:"2022-01-04",LOA_FORECAST:"2022-01-01",LOA_ACTUAL:"2021-12-30",PO_PLAN:"2022-02-15",PO_FORECAST:"2022-02-28",PO_ACTUAL:"2022-03-11",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1782,SN:350,TAG:"2521P020B",TAG_DESCR:"QUENCH OIL CIRCULATING PUMPS",TAG_DESCR_SHORT:"2521P020B / QUENCH OIL CIRCULATING PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-LL02",MR_DESCR:"QUENCH OIL CIRCULATING PUMPS 急冷油循环泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2021-08-08",MR_FORECAST:"2021-09-30",MR_ACTUAL:"2021-09-27",LOA_PLAN:"2022-01-04",LOA_FORECAST:"2022-01-01",LOA_ACTUAL:"2021-12-30",PO_PLAN:"2022-02-15",PO_FORECAST:"2022-02-28",PO_ACTUAL:"2022-03-11",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1783,SN:351,TAG:"2521P020C",TAG_DESCR:"QUENCH OIL CIRCULATING PUMPS",TAG_DESCR_SHORT:"2521P020C / QUENCH OIL CIRCULATING PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-LL02",MR_DESCR:"QUENCH OIL CIRCULATING PUMPS 急冷油循环泵",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2021-08-08",MR_FORECAST:"2021-09-30",MR_ACTUAL:"2021-09-27",LOA_PLAN:"2022-01-04",LOA_FORECAST:"2022-01-01",LOA_ACTUAL:"2021-12-30",PO_PLAN:"2022-02-15",PO_FORECAST:"2022-02-28",PO_ACTUAL:"2022-03-11",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1784,SN:352,TAG:"2521FIL023C",TAG_DESCR_SHORT:"2521FIL023C / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1785,SN:353,TAG:"2521FIL023B",TAG_DESCR_SHORT:"2521FIL023B / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1786,SN:354,TAG:"2521FIL023A",TAG_DESCR_SHORT:"2521FIL023A / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1787,SN:355,TAG:"2521FIL020C",TAG_DESCR:"QUENCH OIL SUCTION STRAINERS",TAG_DESCR_SHORT:"2521FIL020C / QUENCH OIL SUCTION STRAINERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0504",MR_DESCR:"QUENCH OIL SUCTION STRAINERS/急冷油循环泵入口过滤器  特殊过滤器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-06-30",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-08-11",PO_ACTUAL:"2022-08-29",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1788,SN:356,TAG:"2521FIL020B",TAG_DESCR:"QUENCH OIL SUCTION STRAINERS",TAG_DESCR_SHORT:"2521FIL020B / QUENCH OIL SUCTION STRAINERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0504",MR_DESCR:"QUENCH OIL SUCTION STRAINERS/急冷油循环泵入口过滤器  特殊过滤器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-06-30",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-08-11",PO_ACTUAL:"2022-08-29",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1789,SN:357,TAG:"2521FIL020A",TAG_DESCR:"QUENCH OIL SUCTION STRAINERS",TAG_DESCR_SHORT:"2521FIL020A / QUENCH OIL SUCTION STRAINERS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0504",MR_DESCR:"QUENCH OIL SUCTION STRAINERS/急冷油循环泵入口过滤器  特殊过滤器",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-26",LOA_FORECAST:"2022-06-30",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-10-07",PO_FORECAST:"2022-08-11",PO_ACTUAL:"2022-08-29",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1790,SN:358,TAG:"2598D002",TAG_DESCR:"UNDERGROUND QO DRAIN DRUM",TAG_DESCR_SHORT:"2598D002 / UNDERGROUND QO DRAIN DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0303",MR_DESCR:"UNDERGROUND QO DRAIN DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1791,SN:359,TAG:"2598P002",TAG_DESCR_SHORT:"2598P002 / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1792,SN:360,TAG:"2521T051",TAG_DESCR:"FO STRIPPER KCS 17 10",TAG_DESCR_SHORT:"2521T051 / FO STRIPPER KCS 17 10",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0101",MR_DESCR:"FO STRIPPER燃料油汽提塔 （壳体KCS、内径1.7、高度10米",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2021-11-28",MR_FORECAST:"2021-11-28",MR_ACTUAL:"2021-11-24",LOA_PLAN:"2022-02-28",LOA_FORECAST:"2022-03-31",LOA_ACTUAL:"2022-03-23",PO_PLAN:"2022-04-11",PO_FORECAST:"2022-05-12",PO_ACTUAL:"2022-04-13",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1793,SN:361,TAG:"2521T001",TAG_DESCR:"PRIMARY FRACTIONATOR KCS KCS 316316L SS 14 507",TAG_DESCR_SHORT:"2521T001 / PRIMARY FRACTIONATOR KCS KCS 316316L S...",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-LL03",MR_DESCR:"PRIMARY FRACTIONATOR 急冷油塔（壳体KCS,内件KCS 316/316L SS、内径14米，高50.7米）",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2021-07-10",MR_FORECAST:"2021-07-10",MR_ACTUAL:"2021-07-15",LOA_PLAN:"2021-10-30",LOA_FORECAST:"2021-10-19",LOA_ACTUAL:"2021-10-14",PO_PLAN:"2021-12-11",PO_FORECAST:"2021-11-30",PO_ACTUAL:"2021-11-08",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1794,SN:362,TAG:"2521T081",TAG_DESCR_SHORT:"2521T081 / ",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"TBD",MR_DESCR:"TBD",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",CRITICAL:"NMR",STATUS:"Not started",STATUS_CODE:"NOT",STATUS_SEQ:99},
      {NO:1795,SN:363,TAG:"2521P070A",TAG_DESCR:"FO STRIPPER BOTTOMS PUMPS",TAG_DESCR_SHORT:"2521P070A / FO STRIPPER BOTTOMS PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0621",MR_DESCR:"FO STRIPPER BOTTOMS PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1796,SN:364,TAG:"2521P070B",TAG_DESCR:"FO STRIPPER BOTTOMS PUMPS",TAG_DESCR_SHORT:"2521P070B / FO STRIPPER BOTTOMS PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0621",MR_DESCR:"FO STRIPPER BOTTOMS PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1899,SN:467,TAG:"2526P015A",TAG_DESCR:"PW STRIPPER FEED HEATER COND PUMP",TAG_DESCR_SHORT:"2526P015A / PW STRIPPER FEED HEATER COND PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0621",MR_DESCR:"PW STRIPPER FEED HEATER COND PUMP",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1900,SN:468,TAG:"2526P015B",TAG_DESCR:"PW STRIPPER FEED HEATER COND PUMP",TAG_DESCR_SHORT:"2526P015B / PW STRIPPER FEED HEATER COND PUMP",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0621",MR_DESCR:"PW STRIPPER FEED HEATER COND PUMP",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-19",LOA_FORECAST:"2022-07-03",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-31",PO_FORECAST:"2022-09-26",PO_ACTUAL:"2022-09-27",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1797,SN:365,TAG:"3410E010",TAG_DESCR:"C4 SPLITTER REBOILER",TAG_DESCR_SHORT:"3410E010 / C4 SPLITTER REBOILER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0410",MR_DESCR:"C4 SPLITTER REBOILER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"H",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-14",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-25",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1798,SN:366,TAG:"3410T001",TAG_DESCR:"C4 SPLITTER 1600 KCS 41 799",TAG_DESCR_SHORT:"3410T001 / C4 SPLITTER 1600 KCS 41 799",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL13",MR_DESCR:"C4 SPLITTER汽提塔1600吊车 （壳体KCS、内径4.1、高度79.9米",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"H",MR_PLAN:"2021-11-28",MR_FORECAST:"2021-11-28",MR_ACTUAL:"2021-10-19",LOA_PLAN:"2022-02-28",LOA_FORECAST:"2022-03-29",LOA_ACTUAL:"2022-03-23",PO_PLAN:"2022-04-11",PO_FORECAST:"2022-05-10",PO_ACTUAL:"2022-04-13",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1799,SN:367,TAG:"3410D015",TAG_DESCR:"C4 SPLITTER REFLUX DRUM",TAG_DESCR_SHORT:"3410D015 / C4 SPLITTER REFLUX DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0303",MR_DESCR:"C4 SPLITTER REFLUX DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"H",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1800,SN:368,TAG:"3410E015",TAG_DESCR:"C4 SPLITTER CONDENSERx000D",TAG_DESCR_SHORT:"3410E015 / C4 SPLITTER CONDENSERx000D",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0410",MR_DESCR:"C4 SPLITTER CONDENSER_x000D_ 推荐用螺纹管",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"H",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-14",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-25",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1801,SN:369,TAG:"3410E020",TAG_DESCR:"RAFF3 PRODUCT COOLER",TAG_DESCR_SHORT:"3410E020 / RAFF3 PRODUCT COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0410",MR_DESCR:"RAFF3 PRODUCT COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"H",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-09",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-20",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1802,SN:370,TAG:"3411E020",TAG_DESCR:"BUTENE1 PRODUCT COOLER",TAG_DESCR_SHORT:"3411E020 / BUTENE1 PRODUCT COOLER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0410",MR_DESCR:"BUTENE-1 PRODUCT COOLER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"H",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-09",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-20",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1803,SN:371,TAG:"3411E015",TAG_DESCR:"BUTENE1 SPLITTER CONDENSER",TAG_DESCR_SHORT:"3411E015 / BUTENE1 SPLITTER CONDENSER",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0410",MR_DESCR:"BUTENE-1 SPLITTER CONDENSER",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"H",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-09",LOA_FORECAST:"2022-06-25",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-20",PO_FORECAST:"2022-08-06",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1804,SN:372,TAG:"3411D015",TAG_DESCR:"BUTENE1 SPLITTER REFLUX DRUM",TAG_DESCR_SHORT:"3411D015 / BUTENE1 SPLITTER REFLUX DRUM",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0303",MR_DESCR:"BUTENE-1 SPLITTER REFLUX DRUM",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"H",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-07",LOA_PLAN:"2022-08-22",LOA_FORECAST:"2022-08-18",LOA_ACTUAL:"2022-08-17",PO_PLAN:"2022-10-03",PO_FORECAST:"2022-09-29",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1805,SN:373,TAG:"3411T001",TAG_DESCR:"BUTENE1 SPLITTER 1 1600 KCS 36 788",TAG_DESCR_SHORT:"3411T001 / BUTENE1 SPLITTER 1 1600 KCS 36 788",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-SL13",MR_DESCR:"BUTENE-1 SPLITTER丁烯1汽提塔1600吊车 （壳体KCS、内径3.6、高度78.8米）",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"H",MR_PLAN:"2021-11-28",MR_FORECAST:"2021-11-28",MR_ACTUAL:"2021-10-19",LOA_PLAN:"2022-02-28",LOA_FORECAST:"2022-03-29",LOA_ACTUAL:"2022-03-23",PO_PLAN:"2022-04-11",PO_FORECAST:"2022-05-10",PO_ACTUAL:"2022-04-13",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1806,SN:374,TAG:"3411E010",TAG_DESCR:"BUTENE1 SPLITTER REBOILERx000D",TAG_DESCR_SHORT:"3411E010 / BUTENE1 SPLITTER REBOILERx000D",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-EQ-R-E-0410",MR_DESCR:"BUTENE-1 SPLITTER REBOILER_x000D_",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"H",MR_PLAN:"2022-04-28",MR_FORECAST:"2022-04-28",MR_ACTUAL:"2022-03-02",LOA_PLAN:"2022-08-14",LOA_FORECAST:"2022-06-24",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-09-25",PO_FORECAST:"2022-08-05",PO_ACTUAL:"2022-08-05",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3},
      {NO:1807,SN:375,TAG:"3410P020A",TAG_DESCR:"C4 SPLITTER BOTTOMS PUMPS",TAG_DESCR_SHORT:"3410P020A / C4 SPLITTER BOTTOMS PUMPS",PSRNUM:"P1_PSR",PROCESS_UNIT:"OR",MR_NO:"75231-P1-1279-RE-R-E-0620",MR_DESCR:"C4 SPLITTER BOTTOMS PUMPS",DIMENSION:"TBD",WEIGHT:"TBD",LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"H",MR_PLAN:"2022-02-08",MR_FORECAST:"2022-02-08",MR_ACTUAL:"2022-01-10",LOA_PLAN:"2022-06-14",LOA_FORECAST:"2022-07-06",LOA_ACTUAL:"2022-06-30",PO_PLAN:"2022-07-26",PO_FORECAST:"2022-08-17",PO_ACTUAL:"2022-09-20",CRITICAL:"NON",STATUS:"PO Issued",STATUS_CODE:"PO",STATUS_SEQ:3}
      ],
    stageSummaryMain: `{
      "LV1": "MAINPLANT",
      "TOTAL": 268,
      "MR_PLAN": 240,
      "MR_ACTUAL": 0,
      "MR_DELTA": -240,
      "LOA_PLAN": 239,
      "LOA_ACTUAL": 240,
      "LOA_DELTA": 1,
      "PO_PLAN": 155,
      "PO_ACTUAL": 227,
      "PO_DELTA": 72,
      "DELIVERY_PLAN": 0,
      "DELIVERY_ACTUAL": 0,
      "DELIVERY_DELTA": 0,
      "INSTALL_PLAN": 0,
      "INSTALL_ACTUAL": 0,
      "INSTALL_DELTA": 0,
      "NMR_CNT": 0,
      "P2W_CNT": 230,
      "W2W_CNT": 13,
      "NMR_CNT":461,
      "NPO_CNT":17
    }`,
    stageSummaryPackage: [
      {LV1:"MAINPLANT",LV2:"P1",TOTAL:1091,MR_PLAN:929,MR_ACTUAL:923,MR_DELTA:-6,LOA_PLAN:929,LOA_ACTUAL:922,LOA_DELTA:-7,PO_PLAN:922,PO_ACTUAL:915,PO_DELTA:-7,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:0,W2W_CNT:0,NMR_CNT:154,NPO_CNT:15},
      {LV1:"MAINPLANT",LV2:"P2",TOTAL:849,MR_PLAN:542,MR_ACTUAL:542,MR_DELTA:0,LOA_PLAN:541,LOA_ACTUAL:539,LOA_DELTA:-2,PO_PLAN:542,PO_ACTUAL:539,PO_DELTA:-3,DELIVERY_PLAN:4,DELIVERY_ACTUAL:0,DELIVERY_DELTA:-4,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:84,W2W_CNT:327,NMR_CNT:307,NPO_CNT:2}
    ],
    stageSummaryArea: [
      {LV1:"MAINPLANT",LV2:"P1",LV3:"BD",TOTAL:152,MR_PLAN:130,MR_ACTUAL:0,MR_DELTA:-130,LOA_PLAN:133,LOA_ACTUAL:134,LOA_DELTA:1,PO_PLAN:79,PO_ACTUAL:125,PO_DELTA:46,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,NMR_CNT:0,P2W_CNT:124,W2W_CNT:11},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",TOTAL:116,MR_PLAN:110,MR_ACTUAL:0,MR_DELTA:-110,LOA_PLAN:106,LOA_ACTUAL:106,LOA_DELTA:0,PO_PLAN:76,PO_ACTUAL:102,PO_DELTA:26,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,NMR_CNT:0,P2W_CNT:106,W2W_CNT:2},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"BD",TOTAL:152,MR_PLAN:136,MR_ACTUAL:135,MR_DELTA:-1,LOA_PLAN:136,LOA_ACTUAL:135,LOA_DELTA:-1,PO_PLAN:135,PO_ACTUAL:133,PO_DELTA:-2,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:0,W2W_CNT:0,NMR_CNT:8,NPO_CNT:9},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",TOTAL:90,MR_PLAN:53,MR_ACTUAL:53,MR_DELTA:0,LOA_PLAN:53,LOA_ACTUAL:53,LOA_DELTA:0,PO_PLAN:53,PO_ACTUAL:49,PO_DELTA:-4,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:0,W2W_CNT:0,NMR_CNT:37,NPO_CNT:0},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",TOTAL:116,MR_PLAN:110,MR_ACTUAL:108,MR_DELTA:-2,LOA_PLAN:110,LOA_ACTUAL:108,LOA_DELTA:-2,PO_PLAN:108,PO_ACTUAL:108,PO_DELTA:0,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:0,W2W_CNT:0,NMR_CNT:6,NPO_CNT:2},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",TOTAL:83,MR_PLAN:78,MR_ACTUAL:78,MR_DELTA:0,LOA_PLAN:78,LOA_ACTUAL:78,LOA_DELTA:0,PO_PLAN:78,PO_ACTUAL:78,PO_DELTA:0,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:0,W2W_CNT:0,NMR_CNT:4,NPO_CNT:1},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",TOTAL:35,MR_PLAN:33,MR_ACTUAL:33,MR_DELTA:0,LOA_PLAN:33,LOA_ACTUAL:33,LOA_DELTA:0,PO_PLAN:33,PO_ACTUAL:33,PO_DELTA:0,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:0,W2W_CNT:0,NMR_CNT:2,NPO_CNT:0},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",TOTAL:142,MR_PLAN:130,MR_ACTUAL:130,MR_DELTA:0,LOA_PLAN:130,LOA_ACTUAL:130,LOA_DELTA:0,PO_PLAN:130,PO_ACTUAL:130,PO_DELTA:0,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:0,W2W_CNT:0,NMR_CNT:12,NPO_CNT:0},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"OR",TOTAL:473,MR_PLAN:389,MR_ACTUAL:386,MR_DELTA:-3,LOA_PLAN:389,LOA_ACTUAL:385,LOA_DELTA:-4,PO_PLAN:385,PO_ACTUAL:384,PO_DELTA:-1,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:0,W2W_CNT:0,NMR_CNT:85,NPO_CNT:3},
      {LV1:"MAINPLANT",LV2:"P2",LV3:"GPPE",TOTAL:270,MR_PLAN:123,MR_ACTUAL:123,MR_DELTA:0,LOA_PLAN:123,LOA_ACTUAL:123,LOA_DELTA:0,PO_PLAN:123,PO_ACTUAL:122,PO_DELTA:-1,DELIVERY_PLAN:2,DELIVERY_ACTUAL:0,DELIVERY_DELTA:-2,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:29,W2W_CNT:73,NMR_CNT:147,NPO_CNT:0},
      {LV1:"MAINPLANT",LV2:"P2",LV3:"LDPE",TOTAL:260,MR_PLAN:119,MR_ACTUAL:119,MR_DELTA:0,LOA_PLAN:119,LOA_ACTUAL:119,LOA_DELTA:0,PO_PLAN:119,PO_ACTUAL:119,PO_DELTA:0,DELIVERY_PLAN:2,DELIVERY_ACTUAL:0,DELIVERY_DELTA:-2,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:25,W2W_CNT:57,NMR_CNT:141,NPO_CNT:0},
      {LV1:"MAINPLANT",LV2:"P2",LV3:"PP",TOTAL:319,MR_PLAN:300,MR_ACTUAL:300,MR_DELTA:0,LOA_PLAN:299,LOA_ACTUAL:297,LOA_DELTA:-2,PO_PLAN:300,PO_ACTUAL:298,PO_DELTA:-2,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:30,W2W_CNT:197,NMR_CNT:19,NPO_CNT:2}
    ],
    stageSummaryEq: [
      {LV1:"MAINPLANT",LV2:"P1",LV3:"BD",TOTAL:31,MR_PLAN:23,MR_ACTUAL:23,MR_DELTA:0,LOA_PLAN:23,LOA_ACTUAL:23,LOA_DELTA:0,PO_PLAN:23,PO_ACTUAL:23,PO_DELTA:0,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:0,W2W_CNT:0,NMR_CNT:0,NPO_CNT:8},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"A",TOTAL:4,MR_PLAN:2,MR_ACTUAL:2,MR_DELTA:0,LOA_PLAN:2,LOA_ACTUAL:2,LOA_DELTA:0,PO_PLAN:2,PO_ACTUAL:2,PO_DELTA:0,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:0,W2W_CNT:0,NMR_CNT:2,NPO_CNT:0},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"B",TOTAL:46,MR_PLAN:45,MR_ACTUAL:45,MR_DELTA:0,LOA_PLAN:45,LOA_ACTUAL:45,LOA_DELTA:0,PO_PLAN:45,PO_ACTUAL:45,PO_DELTA:0,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:0,W2W_CNT:0,NMR_CNT:1,NPO_CNT:0},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"C",TOTAL:36,MR_PLAN:35,MR_ACTUAL:34,MR_DELTA:-1,LOA_PLAN:35,LOA_ACTUAL:34,LOA_DELTA:-1,PO_PLAN:34,PO_ACTUAL:34,PO_DELTA:0,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:0,W2W_CNT:0,NMR_CNT:1,NPO_CNT:1},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"BD",AREA_UNIT:"D",TOTAL:35,MR_PLAN:31,MR_ACTUAL:31,MR_DELTA:0,LOA_PLAN:31,LOA_ACTUAL:31,LOA_DELTA:0,PO_PLAN:31,PO_ACTUAL:29,PO_DELTA:-2,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:0,W2W_CNT:0,NMR_CNT:4,NPO_CNT:0},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",TOTAL:54,MR_PLAN:35,MR_ACTUAL:35,MR_DELTA:0,LOA_PLAN:35,LOA_ACTUAL:35,LOA_DELTA:0,PO_PLAN:35,PO_ACTUAL:34,PO_DELTA:-1,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:0,W2W_CNT:0,NMR_CNT:19,NPO_CNT:0},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",AREA_UNIT:"A",TOTAL:36,MR_PLAN:18,MR_ACTUAL:18,MR_DELTA:0,LOA_PLAN:18,LOA_ACTUAL:18,LOA_DELTA:0,PO_PLAN:18,PO_ACTUAL:15,PO_DELTA:-3,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:0,W2W_CNT:0,NMR_CNT:18,NPO_CNT:0},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",TOTAL:24,MR_PLAN:20,MR_ACTUAL:20,MR_DELTA:0,LOA_PLAN:20,LOA_ACTUAL:20,LOA_DELTA:0,PO_PLAN:20,PO_ACTUAL:20,PO_DELTA:0,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:0,W2W_CNT:0,NMR_CNT:4,NPO_CNT:0},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"A",TOTAL:51,MR_PLAN:50,MR_ACTUAL:48,MR_DELTA:-2,LOA_PLAN:50,LOA_ACTUAL:48,LOA_DELTA:-2,PO_PLAN:48,PO_ACTUAL:48,PO_DELTA:0,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:0,W2W_CNT:0,NMR_CNT:1,NPO_CNT:2},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",AREA_UNIT:"B",TOTAL:41,MR_PLAN:40,MR_ACTUAL:40,MR_DELTA:0,LOA_PLAN:40,LOA_ACTUAL:40,LOA_DELTA:0,PO_PLAN:40,PO_ACTUAL:40,PO_DELTA:0,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:0,W2W_CNT:0,NMR_CNT:1,NPO_CNT:0},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",TOTAL:12,MR_PLAN:8,MR_ACTUAL:8,MR_DELTA:0,LOA_PLAN:8,LOA_ACTUAL:8,LOA_DELTA:0,PO_PLAN:8,PO_ACTUAL:8,PO_DELTA:0,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:0,W2W_CNT:0,NMR_CNT:4,NPO_CNT:0},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",AREA_UNIT:"A",TOTAL:71,MR_PLAN:70,MR_ACTUAL:70,MR_DELTA:0,LOA_PLAN:70,LOA_ACTUAL:70,LOA_DELTA:0,PO_PLAN:70,PO_ACTUAL:70,PO_DELTA:0,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:0,W2W_CNT:0,NMR_CNT:0,NPO_CNT:1},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",TOTAL:35,MR_PLAN:33,MR_ACTUAL:33,MR_DELTA:0,LOA_PLAN:33,LOA_ACTUAL:33,LOA_DELTA:0,PO_PLAN:33,PO_ACTUAL:33,PO_DELTA:0,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:0,W2W_CNT:0,NMR_CNT:2,NPO_CNT:0},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",TOTAL:52,MR_PLAN:48,MR_ACTUAL:48,MR_DELTA:0,LOA_PLAN:48,LOA_ACTUAL:48,LOA_DELTA:0,PO_PLAN:48,PO_ACTUAL:48,PO_DELTA:0,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:0,W2W_CNT:0,NMR_CNT:4,NPO_CNT:0},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",AREA_UNIT:"A",TOTAL:90,MR_PLAN:82,MR_ACTUAL:82,MR_DELTA:0,LOA_PLAN:82,LOA_ACTUAL:82,LOA_DELTA:0,PO_PLAN:82,PO_ACTUAL:82,PO_DELTA:0,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:0,W2W_CNT:0,NMR_CNT:8,NPO_CNT:0},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"A",TOTAL:38,MR_PLAN:30,MR_ACTUAL:30,MR_DELTA:0,LOA_PLAN:30,LOA_ACTUAL:30,LOA_DELTA:0,PO_PLAN:30,PO_ACTUAL:30,PO_DELTA:0,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:0,W2W_CNT:0,NMR_CNT:8,NPO_CNT:0},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"B",TOTAL:56,MR_PLAN:47,MR_ACTUAL:46,MR_DELTA:-1,LOA_PLAN:47,LOA_ACTUAL:46,LOA_DELTA:-1,PO_PLAN:46,PO_ACTUAL:46,PO_DELTA:0,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:0,W2W_CNT:0,NMR_CNT:9,NPO_CNT:1},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"C",TOTAL:36,MR_PLAN:33,MR_ACTUAL:32,MR_DELTA:-1,LOA_PLAN:33,LOA_ACTUAL:31,LOA_DELTA:-2,PO_PLAN:31,PO_ACTUAL:31,PO_DELTA:0,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:0,W2W_CNT:0,NMR_CNT:3,NPO_CNT:2},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"D",TOTAL:60,MR_PLAN:39,MR_ACTUAL:39,MR_DELTA:0,LOA_PLAN:39,LOA_ACTUAL:39,LOA_DELTA:0,PO_PLAN:39,PO_ACTUAL:39,PO_DELTA:0,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:0,W2W_CNT:0,NMR_CNT:21,NPO_CNT:0},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"E",TOTAL:66,MR_PLAN:54,MR_ACTUAL:54,MR_DELTA:0,LOA_PLAN:54,LOA_ACTUAL:54,LOA_DELTA:0,PO_PLAN:54,PO_ACTUAL:54,PO_DELTA:0,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:0,W2W_CNT:0,NMR_CNT:12,NPO_CNT:0},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"F",TOTAL:55,MR_PLAN:51,MR_ACTUAL:51,MR_DELTA:0,LOA_PLAN:51,LOA_ACTUAL:51,LOA_DELTA:0,PO_PLAN:51,PO_ACTUAL:50,PO_DELTA:-1,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:0,W2W_CNT:0,NMR_CNT:4,NPO_CNT:0},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"G",TOTAL:60,MR_PLAN:50,MR_ACTUAL:50,MR_DELTA:0,LOA_PLAN:50,LOA_ACTUAL:50,LOA_DELTA:0,PO_PLAN:50,PO_ACTUAL:50,PO_DELTA:0,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:0,W2W_CNT:0,NMR_CNT:10,NPO_CNT:0},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"H",TOTAL:22,MR_PLAN:22,MR_ACTUAL:22,MR_DELTA:0,LOA_PLAN:22,LOA_ACTUAL:22,LOA_DELTA:0,PO_PLAN:22,PO_ACTUAL:22,PO_DELTA:0,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:0,W2W_CNT:0,NMR_CNT:0,NPO_CNT:0},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"I",TOTAL:53,MR_PLAN:45,MR_ACTUAL:45,MR_DELTA:0,LOA_PLAN:45,LOA_ACTUAL:45,LOA_DELTA:0,PO_PLAN:45,PO_ACTUAL:45,PO_DELTA:0,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:0,W2W_CNT:0,NMR_CNT:8,NPO_CNT:0},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"OR",AREA_UNIT:"J",TOTAL:27,MR_PLAN:18,MR_ACTUAL:17,MR_DELTA:-1,LOA_PLAN:18,LOA_ACTUAL:17,LOA_DELTA:-1,PO_PLAN:17,PO_ACTUAL:17,PO_DELTA:0,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:0,W2W_CNT:0,NMR_CNT:10,NPO_CNT:0},
      {LV1:"MAINPLANT",LV2:"P2",LV3:"GPPE",TOTAL:29,MR_PLAN:4,MR_ACTUAL:4,MR_DELTA:0,LOA_PLAN:4,LOA_ACTUAL:4,LOA_DELTA:0,PO_PLAN:4,PO_ACTUAL:4,PO_DELTA:0,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:1,W2W_CNT:3,NMR_CNT:25,NPO_CNT:0},
      {LV1:"MAINPLANT",LV2:"P2",LV3:"GPPE",AREA_UNIT:"A",TOTAL:28,MR_PLAN:28,MR_ACTUAL:28,MR_DELTA:0,LOA_PLAN:28,LOA_ACTUAL:28,LOA_DELTA:0,PO_PLAN:28,PO_ACTUAL:28,PO_DELTA:0,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:3,W2W_CNT:21,NMR_CNT:0,NPO_CNT:0},
      {LV1:"MAINPLANT",LV2:"P2",LV3:"GPPE",AREA_UNIT:"B",TOTAL:33,MR_PLAN:10,MR_ACTUAL:10,MR_DELTA:0,LOA_PLAN:10,LOA_ACTUAL:10,LOA_DELTA:0,PO_PLAN:10,PO_ACTUAL:10,PO_DELTA:0,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:1,W2W_CNT:8,NMR_CNT:23,NPO_CNT:0},
      {LV1:"MAINPLANT",LV2:"P2",LV3:"GPPE",AREA_UNIT:"C",TOTAL:3,MR_PLAN:2,MR_ACTUAL:2,MR_DELTA:0,LOA_PLAN:2,LOA_ACTUAL:2,LOA_DELTA:0,PO_PLAN:2,PO_ACTUAL:2,PO_DELTA:0,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:0,W2W_CNT:2,NMR_CNT:1,NPO_CNT:0},
      {LV1:"MAINPLANT",LV2:"P2",LV3:"GPPE",AREA_UNIT:"D",TOTAL:42,MR_PLAN:2,MR_ACTUAL:2,MR_DELTA:0,LOA_PLAN:2,LOA_ACTUAL:2,LOA_DELTA:0,PO_PLAN:2,PO_ACTUAL:2,PO_DELTA:0,DELIVERY_PLAN:0,DELIVERY_ACTUAL:0,DELIVERY_DELTA:0,INSTALL_PLAN:0,INSTALL_ACTUAL:0,INSTALL_DELTA:0,P2W_CNT:0,W2W_CNT:0,NMR_CNT:40,NPO_CNT:0}
    ]
  }],
}