import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1900,
      CanvasHeight: 1500,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,
      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },
    DataItems:  [
      {NO:1.1,GROUP:"ICSS Infrastructure for SAT commencement",SUBSYSTEM:"21-6030-6032-01",SUBSYSTEM_DESCR:"ICSS Interface Servers, Workstations & Network Switches CER",PRIORITY:"P1A",ROOM:"CER",SYS_TYPE:"ICSS",SUBSYSTEM_STATUS:"B ITR in progress",PUNCH_STATUS:"A",A_OV_TOT:276,A_OV_COM:247,A_OV_COM_LW:0,A_OV_COM_TW:59,A_OV_COM_CLIENT:35,A_OV_REM:29,A_OV_PROG:89.49,B_OV_TOT:20,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:20,B_OV_PROG:100,PA_OV_TOT:22,PA_OV_COM:2,PA_OV_REM:20,PA_OV_PROG:100},
      {NO:1.2,GROUP:"ICSS Infrastructure for SAT commencement",SUBSYSTEM:"21-6020-6021-02",SUBSYSTEM_DESCR:"PAS Marshalling - LQ (CER)",PRIORITY:"P1A",BATCH:"Priority Batch",LOSH:"Y",ROOM:"CER",SYS_TYPE:"PAS",SUBSYSTEM_STATUS:"B ITR in progress",PUNCH_STATUS:"A",A_OV_TOT:15,A_OV_COM:15,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:5,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:5,B_OV_PROG:0,PA_OV_TOT:7,PA_OV_COM:0,PA_OV_REM:7,PA_OV_PROG:0},
      {NO:1.3,GROUP:"ICSS Infrastructure for SAT commencement",SUBSYSTEM:"21-6070-6071-02",SUBSYSTEM_DESCR:"FGS Marshalling - LQ (CER)",PRIORITY:"P1A",LOSH:"Y",ROOM:"CER",SYS_TYPE:"FGS",SUBSYSTEM_STATUS:"B ITR in progress",PUNCH_STATUS:"A",A_OV_TOT:25,A_OV_COM:25,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0,PA_OV_TOT:7,PA_OV_COM:0,PA_OV_REM:7,PA_OV_PROG:0},
      {NO:1.4,GROUP:"ICSS Infrastructure for SAT commencement",SUBSYSTEM:"21-6010-6011-02",SUBSYSTEM_DESCR:"SIS Marshalling - LQ (CER)",PRIORITY:"P1A",LOSH:"Y",ROOM:"CER",SYS_TYPE:"SIS",SUBSYSTEM_STATUS:"B ITR in progress",PUNCH_STATUS:"A",A_OV_TOT:14,A_OV_COM:14,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,PA_OV_TOT:20,PA_OV_COM:0,PA_OV_REM:20,PA_OV_PROG:0},
      {NO:2.1,GROUP:"ICSS Infrastructure for SAT commencement",SUBSYSTEM:"21-6030-6032-03",SUBSYSTEM_DESCR:"ICSS Interface Servers, Workstations & Network Switches HER",PRIORITY:"P1A",LOSH:"Y",ROOM:"HER",SYS_TYPE:"ICSS",SUBSYSTEM_STATUS:"B ITR in progress",PUNCH_STATUS:"N",A_OV_TOT:131,A_OV_COM:112,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:5,A_OV_REM:19,A_OV_PROG:85.49,B_OV_TOT:11,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:11,B_OV_PROG:100,PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PA_OV_PROG:100},
      {NO:2.2,GROUP:"ICSS Infrastructure for SAT commencement",SUBSYSTEM:"21-6020-6021-01",SUBSYSTEM_DESCR:"PAS Marshalling - Machinery Space (HER)",PRIORITY:"P1A",BATCH:"Priority Batch",LOSH:"Y",ROOM:"HER",SYS_TYPE:"PAS",SUBSYSTEM_STATUS:"B ITR in progress",PUNCH_STATUS:"A",A_OV_TOT:46,A_OV_COM:44,A_OV_COM_LW:1,A_OV_COM_TW:1,A_OV_COM_CLIENT:4,A_OV_REM:2,A_OV_PROG:95.65,B_OV_TOT:11,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:11,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PA_OV_PROG:0},
      {NO:2.3,GROUP:"ICSS Infrastructure for SAT commencement",SUBSYSTEM:"21-6070-6071-01",SUBSYSTEM_DESCR:"FGS Marshalling - Machinery Space (HER)",PRIORITY:"P1A",LOSH:"Y",ROOM:"HER",SYS_TYPE:"FGS",SUBSYSTEM_STATUS:"B ITR in progress",PUNCH_STATUS:"A",A_OV_TOT:14,A_OV_COM:14,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,PA_OV_TOT:8,PA_OV_COM:0,PA_OV_REM:8,PA_OV_PROG:0},
      {NO:2.4,GROUP:"ICSS Infrastructure for SAT commencement",SUBSYSTEM:"21-6010-6011-01",SUBSYSTEM_DESCR:"SIS Marshalling - Machinery Space (HER)",PRIORITY:"P1A",LOSH:"Y",ROOM:"HER",SYS_TYPE:"SIS",SUBSYSTEM_STATUS:"B ITR in progress",PUNCH_STATUS:"A",A_OV_TOT:43,A_OV_COM:40,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:4,A_OV_REM:3,A_OV_PROG:93.02,B_OV_TOT:8,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:8,B_OV_PROG:0,PA_OV_TOT:30,PA_OV_COM:0,PA_OV_REM:30,PA_OV_PROG:0},
      {NO:3.1,GROUP:"ICSS Infrastructure for SAT commencement",SUBSYSTEM:"21-6030-6032-02",SUBSYSTEM_DESCR:"ICSS Interface Servers, Workstations & Network Switches LER",PRIORITY:"P1",LOSH:"Y",ROOM:"LER",SYS_TYPE:"ICSS",SUBSYSTEM_STATUS:"B ITR in progress",PUNCH_STATUS:"N",A_OV_TOT:12,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:12,A_OV_PROG:0,B_OV_TOT:8,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:8,B_OV_PROG:0},
      {NO:3.2,GROUP:"ICSS Infrastructure for SAT commencement",SUBSYSTEM:"21-6020-6021-03",SUBSYSTEM_DESCR:"PAS Marshalling - Topsides (ME05 / LER)",PRIORITY:"P1",ROOM:"LER",SYS_TYPE:"PAS",SUBSYSTEM_STATUS:"B ITR in progress",PUNCH_STATUS:"N",A_OV_TOT:121,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:121,A_OV_PROG:0,B_OV_TOT:7,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:7,B_OV_PROG:0},
      {NO:3.3,GROUP:"ICSS Infrastructure for SAT commencement",SUBSYSTEM:"21-6070-6071-03",SUBSYSTEM_DESCR:"FGS Marshalling - Topsides (ME05 / LER)",PRIORITY:"P1",LOSH:"Y",ROOM:"LER",SYS_TYPE:"FGS",SUBSYSTEM_STATUS:"B ITR in progress",PUNCH_STATUS:"N",A_OV_TOT:35,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:35,A_OV_PROG:0,B_OV_TOT:5,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:5,B_OV_PROG:0},
      {NO:3.4,GROUP:"ICSS Infrastructure for SAT commencement",SUBSYSTEM:"21-6010-6011-03",SUBSYSTEM_DESCR:"SIS Marshalling - Topsides (ME05 / LER)",PRIORITY:"P1",ROOM:"LER",SYS_TYPE:"SIS",SUBSYSTEM_STATUS:"B ITR in progress",PUNCH_STATUS:"N",A_OV_TOT:61,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:61,A_OV_PROG:0,B_OV_TOT:6,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:6,B_OV_PROG:0},
      {NO:4.1,GROUP:"ICSS Infrastructure for SAT commencement",SUBSYSTEM:"21-6030-6032-04",SUBSYSTEM_DESCR:"ICSS Workstations & Consoles - CCR",PRIORITY:"P1",ROOM:"CCR",SYS_TYPE:"ICSS",SUBSYSTEM_STATUS:"B ITR in progress",PUNCH_STATUS:"N",A_OV_TOT:66,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:66,A_OV_PROG:0,B_OV_TOT:13,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:13,B_OV_PROG:0},
      {NO:5.1,GROUP:"ICSS Infrastructure for SAT commencement",SUBSYSTEM:"21-6030-6032-05",SUBSYSTEM_DESCR:"ICSS Workstations & Consoles - Safe Haven/SOP",PRIORITY:"P1",ROOM:"SafeHaven & SOP",SYS_TYPE:"ICSS",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",A_OV_TOT:7,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:7,A_OV_PROG:0}
    ],
}