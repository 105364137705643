import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1610,
      CanvasHeight: 500,
      CanvasBorderWeight: 0.3,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 310,
      CanvasChartHeight: 270,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: 'Transparent',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },

    Text: {
      Text: [
        { text: 'Status by Level', x: 20, y: 10, font: 'roboto', class: 'regular', size: 13, color: '#000', align: 'start', direction: 'Horizontal' },
      ],
      Filter: [],
      Sql: []
    },

    DataItems:  [
      {GWBS_LEVEL:"DECK A",LV4:"LQ01",PO_TOTAL:14,PO_ACTUAL:12,FAT_TOTAL:14,FAT_ACTUAL:11,IRN_TOTAL:14,IRN_ACTUAL:11,ONSITE_TOTAL:14,ONSITE_ACTUAL:11,INST_TOTAL:14,INST_ACTUAL:0,NOT_PO_CNT:2,ETA_PAST_FROM_ROS:1,ETA_PAST_FROM_UPPERBLOCK:0},
      {GWBS_LEVEL:"LV1",LV4:"LQ02",PO_TOTAL:27,PO_ACTUAL:10,FAT_TOTAL:27,FAT_ACTUAL:3,IRN_TOTAL:27,IRN_ACTUAL:3,ONSITE_TOTAL:27,ONSITE_ACTUAL:2,INST_TOTAL:27,INST_ACTUAL:0,NOT_PO_CNT:17,ETA_PAST_FROM_ROS:6,ETA_PAST_FROM_UPPERBLOCK:0},
      {GWBS_LEVEL:"LV2",LV4:"LQ03",PO_TOTAL:22,PO_ACTUAL:18,FAT_TOTAL:22,FAT_ACTUAL:18,IRN_TOTAL:22,IRN_ACTUAL:18,ONSITE_TOTAL:22,ONSITE_ACTUAL:17,INST_TOTAL:22,INST_ACTUAL:0,NOT_PO_CNT:4,ETA_PAST_FROM_ROS:0,ETA_PAST_FROM_UPPERBLOCK:0},
      {GWBS_LEVEL:"LV3",LV4:"LQ04",PO_TOTAL:7,PO_ACTUAL:5,FAT_TOTAL:7,FAT_ACTUAL:5,IRN_TOTAL:7,IRN_ACTUAL:5,ONSITE_TOTAL:7,ONSITE_ACTUAL:5,INST_TOTAL:7,INST_ACTUAL:0,NOT_PO_CNT:2,ETA_PAST_FROM_ROS:0,ETA_PAST_FROM_UPPERBLOCK:0},
      {GWBS_LEVEL:"LV4",LV4:"LQ05",PO_TOTAL:1,PO_ACTUAL:0,FAT_TOTAL:1,FAT_ACTUAL:0,IRN_TOTAL:1,IRN_ACTUAL:0,ONSITE_TOTAL:1,ONSITE_ACTUAL:0,INST_TOTAL:1,INST_ACTUAL:0,NOT_PO_CNT:1,ETA_PAST_FROM_ROS:0,ETA_PAST_FROM_UPPERBLOCK:0},
      {GWBS_LEVEL:"LV5",LV4:"LQ06",PO_TOTAL:22,PO_ACTUAL:14,FAT_TOTAL:22,FAT_ACTUAL:14,IRN_TOTAL:22,IRN_ACTUAL:14,ONSITE_TOTAL:22,ONSITE_ACTUAL:10,INST_TOTAL:22,INST_ACTUAL:0,NOT_PO_CNT:8,ETA_PAST_FROM_ROS:2,ETA_PAST_FROM_UPPERBLOCK:0},
      {GWBS_LEVEL:"ROOF",LV4:"LQ07",PO_TOTAL:5,PO_ACTUAL:5,FAT_TOTAL:5,FAT_ACTUAL:5,IRN_TOTAL:5,IRN_ACTUAL:5,ONSITE_TOTAL:5,ONSITE_ACTUAL:5,INST_TOTAL:5,INST_ACTUAL:0,NOT_PO_CNT:0,ETA_PAST_FROM_ROS:0,ETA_PAST_FROM_UPPERBLOCK:0}
    ],

    Queries: {
      SQL1:  [
        {NO:667,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"1901",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
        {NO:668,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"1902",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
        {NO:669,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"1903",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
        {NO:670,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"1904",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
        {NO:671,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"1905",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
        {NO:672,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"1906",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
        {NO:673,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"1907",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
        {NO:674,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"1908",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
        {NO:675,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"1909",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"FABSTART",EREC:"Y"},
        {NO:676,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"2901",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
        {NO:677,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"2902",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
        {NO:678,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"2903",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
        {NO:679,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"2904",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
        {NO:680,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"2905",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
        {NO:681,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"2906",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
        {NO:682,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"2907",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
        {NO:683,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"2908",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
        {NO:684,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"3903",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
        {NO:685,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"3904",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
        {NO:686,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"3905",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
        {NO:687,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"3906",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
        {NO:688,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"3907",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
        {NO:689,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"3908",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
        {NO:690,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"4904",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
        {NO:691,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"4905",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
        {NO:692,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"4907",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
        {NO:693,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"4908",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
        {NO:694,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"5904",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
        {NO:695,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"5905",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
        {NO:696,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"5907",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
        {NO:697,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"6907",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
        {NO:698,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"7907",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
        {NO:699,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"8907",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
        {NO:700,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",BLOCK:"9907",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:601,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"DECK A",BLOCK:"901",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:602,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"DECK A",BLOCK:"902",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:603,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"DECK A",BLOCK:"903",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:604,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"DECK A",BLOCK:"904",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:605,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"DECK A",BLOCK:"905",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:606,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"DECK A",BLOCK:"906",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:607,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"DECK A",BLOCK:"907",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:608,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"DECK A",BLOCK:"908",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:609,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"DECK A",BLOCK:"909",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:610,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"DECK A",BLOCK:"991",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:611,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"DECK A",BLOCK:"992",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:612,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"DECK A",BLOCK:"993",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:613,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"DECK A",BLOCK:"994",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:614,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV1",BLOCK:"911",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:615,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV1",BLOCK:"912",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:616,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV1",BLOCK:"913",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:617,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV1",BLOCK:"914",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:618,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV1",BLOCK:"915",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:619,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV1",BLOCK:"916",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:620,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV1",BLOCK:"917",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:621,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV1",BLOCK:"918",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:622,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV2",BLOCK:"921",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:623,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV2",BLOCK:"922",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:624,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV2",BLOCK:"923",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:625,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV2",BLOCK:"924",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:626,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV2",BLOCK:"925",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:627,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV2",BLOCK:"926",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:628,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV2",BLOCK:"927",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:629,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV2",BLOCK:"928",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:630,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV2",BLOCK:"995",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:631,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV2",BLOCK:"996",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:632,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV3",BLOCK:"931",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:633,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV3",BLOCK:"932",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:634,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV3",BLOCK:"933",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:635,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV3",BLOCK:"934",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:636,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV3",BLOCK:"935",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:637,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV3",BLOCK:"936",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:638,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV3",BLOCK:"937",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:639,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV3",BLOCK:"938",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:640,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV3",BLOCK:"997",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
        {NO:701,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV3",BLOCK:"MS1",AREA:"Living Quarters",TYPE:"UNIT",EREC:"Y"},
        {NO:641,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV4",BLOCK:"941",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:642,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV4",BLOCK:"942",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:643,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV4",BLOCK:"943",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:644,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV4",BLOCK:"944",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:645,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV4",BLOCK:"945",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:646,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV4",BLOCK:"946",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:647,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV4",BLOCK:"947",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:648,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV4",BLOCK:"948",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:649,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV5",BLOCK:"951",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:650,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV5",BLOCK:"952",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:651,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV5",BLOCK:"953",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:652,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV5",BLOCK:"954",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:653,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV5",BLOCK:"955",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:654,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV5",BLOCK:"956",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:655,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV5",BLOCK:"957",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:656,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"LV5",BLOCK:"958",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:702,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"ROOF",BLOCK:"MS2",AREA:"Living Quarters",TYPE:"UNIT",EREC:"Y"},
        {NO:658,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"ROOF",BLOCK:"961",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:659,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"ROOF",BLOCK:"962",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:660,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"ROOF",BLOCK:"963",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:661,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"ROOF",BLOCK:"964",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:662,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"ROOF",BLOCK:"965",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:663,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"ROOF",BLOCK:"966",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:664,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"ROOF",BLOCK:"971",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"ERECTION",EREC:"Y"},
        {NO:665,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"ROOF",BLOCK:"972",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"PAINTING",EREC:"Y"},
        {NO:666,Lv1:"HULL",Lv2:"LQ",Lv3:"LQ00",Lv4:"ROOF",BLOCK:"973",AREA:"Living Quarters",TYPE:"UNIT",STATUS:"FABRICATION",EREC:"Y"},
        {NO:657,Lv1:"HULL",Lv2:"LQ",Lv3:"HD01",Lv4:"HD01",BLOCK:"959",AREA:"Helideck",TYPE:"UNIT",STATUS:"FABSTART",EREC:"Y"}
        ]
    }
}