import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 800,
      CanvasHeight: 420,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },
    // Queries: {
    //   SQL1: [
        
    //   ]
    // },
    DataItems:  [
      {IG_NO:"IG-E2-001",LOCATIONS:"DPRC Piperack at the east side of QM terminal",COORDINATION:"X: 2516231.384  Y: 557698.700  ",REMARKS:"Interface between SEI’s expansion in B+ area with buildings already handed-over to HCCP"},
    ],
}