import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 770,
      CanvasHeight: 400,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     

    DataItems: [
      { TYPE: 'WEEK', LINK1: null, DATE: '2020-02-28', DISC: 'LWK', TOTAL: null, ACTUAL: 153, REMAIN: null, PROG: null, },
      { TYPE: 'WEEK', LINK1: null, DATE: '2020-03-01', DISC: 'SUN', TOTAL: null, ACTUAL: 4, REMAIN: null, PROG: null, },
      { TYPE: 'WEEK', LINK1: null, DATE: '2020-02-29', DISC: 'SAT', TOTAL: null, ACTUAL: 6, REMAIN: null, PROG: null, },
      { TYPE: 'WEEK', LINK1: null, DATE: '2020-03-03', DISC: 'TUE', TOTAL: null, ACTUAL: 0, REMAIN: null, PROG: null, },
      { TYPE: 'WEEK', LINK1: null, DATE: '2020-03-05', DISC: 'TUE', TOTAL: null, ACTUAL: 0, REMAIN: null, PROG: null, },
      { TYPE: 'WEEK', LINK1: null, DATE: '2020-03-02', DISC: 'MON', TOTAL: null, ACTUAL: 0, REMAIN: null, PROG: null, },
      { TYPE: 'WEEK', LINK1: null, DATE: '2020-03-06', DISC: 'FRI', TOTAL: null, ACTUAL: 0, REMAIN: null, PROG: null, },
      { TYPE: 'WEEK', LINK1: null, DATE: '2020-03-04', DISC: 'WEN', TOTAL: null, ACTUAL: 0, REMAIN: null, PROG: null, },
      { TYPE: 'WEEK', LINK1: null, DATE: '2020-03-06', DISC: 'TWK', TOTAL: null, ACTUAL: 10, REMAIN: null, PROG: null, },
      { TYPE: 'PKG', LINK1: 'A-PI-00', DATE: '', DISC: 'Package Review', TOTAL: 5740, ACTUAL: 4983, REMAIN: 757, PROG: 86.81, },
      { TYPE: 'PKG', LINK1: 'A-PI-01', DATE: '', DISC: 'Piping Pre-Test Line Walk(A-PI-01)', TOTAL: 5760, ACTUAL: 4795, REMAIN: 965, PROG: 83.25, },
      { TYPE: 'PKG', LINK1: 'A-PI-02', DATE: '', DISC: 'Piping Flushing(A-PI-02)', TOTAL: 5760, ACTUAL: 3807, REMAIN: 1953, PROG: 66.09, },
      { TYPE: 'PKG', LINK1: 'A-PI-03', DATE: '', DISC: 'Piping Pressure Test(A-PI-03)', TOTAL: 5760, ACTUAL: 3749, REMAIN: 2011, PROG: 65.09, },
      { TYPE: 'PKG', LINK1: 'A-PI-04', DATE: '', DISC: 'Piping Cleanliness(A-PI-04)', TOTAL: 5760, ACTUAL: 3628, REMAIN: 2132, PROG: 62.99, },
      { TYPE: 'PKG', LINK1: 'A-PI-05', DATE: '', DISC: 'Test Pack Reinstatement(A-PI-05)', TOTAL: 5760, ACTUAL: 3167, REMAIN: 2593, PROG: 54.98, },
      { TYPE: 'PKG', LINK1: 'A-PI-06', DATE: '', DISC: 'Chemical Cleaning(A-PI-06)', TOTAL: 51, ACTUAL: 0, REMAIN: 51, PROG: 0, },
      { TYPE: 'PKG', LINK1: 'A-PI-07', DATE: '', DISC: 'Oil Flushing(A-PI-07)', TOTAL: 36, ACTUAL: 21, REMAIN: 15, PROG: 58.33, },
      { TYPE: 'PKG', LINK1: 'A-PI-09', DATE: '', DISC: 'Joint Integrity Certificate(A-PI-09)', TOTAL: 5760, ACTUAL: 2537, REMAIN: 3223, PROG: 44.05, },
      { TYPE: 'PKG', LINK1: 'A-PI-10', DATE: '', DISC: 'Insulation of Pipework(A-PI-10)', TOTAL: 92, ACTUAL: 0, REMAIN: 92, PROG: 0, },
      { TYPE: 'PKG', LINK1: 'A-PI-11', DATE: '', DISC: 'Paint of Piping System(A-PI-11)', TOTAL: 238, ACTUAL: 0, REMAIN: 238, PROG: 0, },
      { TYPE: 'PKG', LINK1: 'A-PI-12', DATE: '', DISC: 'Color Coding of Piping system(A-PI-12)', TOTAL: 238, ACTUAL: 0, REMAIN: 238, PROG: 0, },
    ],
}