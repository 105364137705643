import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1410,
      CanvasHeight: 1100,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

    Note: {
      NoteDisplay: 'N',
      NoteX: 633,
      NoteY: 358,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',
  
      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC'
    },

    Text: {
      Text: [
        { text: 'Weight : ', x:240, y: -20, font: 'roboto', class: 'regular', size: 11, color: '#BCBCBC', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: 240, y: -20, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'end' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: 240,
          y: -20,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'end'
        },
      ]
    },

    DataItems: [
      { No: 1, Title: 'Bypassing Safety Controls' },
      { No: 2, Title: 'Confined Space', SubTitle: 'Obtain authorisation before entering a confined space', Contents1: '• I confirm energy sources are isolated ', Contents2: '• I confirm the atmosphere has been tested and is monitored ', Contents3: '• I check and use my breathing apparatus when required ', Contents4: '• I confirm there is an attendant standing by ', Contents5: '• I confirm a rescue plan is in place ', Contents6: '• I obtain authorisation to enter', Contents1_Sub1: null, Contents1_Sub2: null, Contents1_Sub3: null, Contents1_Sub4: null, Contents2_Sub1: null, Contents2_Sub2: null, Contents2_Sub3: null, Contents3_Sub1: null, Contents3_Sub2: null, X: 387, Y: 140, },
      { No: 3, Title: 'Driving', SubTitle: 'Follow safe driving rules', Contents1: '• I always wear a seatbelt ', Contents2: '• I do not exceed the speed limit, and reduce my speed for road conditions ', Contents3: '• I do not use phones or operate devices while driving ', Contents4: '• I am fit, rested and fully alert while driving ', Contents5: '• I follow journey management requirements ', Contents6: null, Contents1_Sub1: null, Contents1_Sub2: null, Contents1_Sub3: null, Contents1_Sub4: null, Contents2_Sub1: null, Contents2_Sub2: null, Contents2_Sub3: null, Contents3_Sub1: null, Contents3_Sub2: null, X: 720, Y: 140, },
      { No: 4, Title: 'Energy Isolation', SubTitle: 'Verify isolation and zero energy before work begins', Contents1: '• I have identified all energy sources ', Contents2: '• I confirm that hazardous energy sources have been isolated, locked, and tagged ', Contents3: '• I have checked there is zero energy and tested for residual or stored energy', Contents4: null, Contents5: null, Contents6: null, Contents1_Sub1: null, Contents1_Sub2: null, Contents1_Sub3: null, Contents1_Sub4: null, Contents2_Sub1: null, Contents2_Sub2: null, Contents2_Sub3: null, Contents3_Sub1: null, Contents3_Sub2: null, X: 55, Y: 555, },
    ],
}