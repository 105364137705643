import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 2300,
    CanvasHeight: 980,
    CanvasBorderWeight: 0.05,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.1,

    CanvasChartX: 50,
    CanvasChartY: 50,
    CanvasChartWidth: 480,
    CanvasChartHeight: 250,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  DataItems:  [
    {SUBSYSTEM:"21-1001-1001-01",SUBSYSTEM_DESCR:"Annulus Riser vent Monitoring System",WD_PLAN:"2022-04-14",A_OV_TOT:92,A_OV_COM:4,A_OV_REM:88,A_OV_PROG:4.34,A_OV_STATUS:"less than 70%",A_PG:4.34,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-1010-1011-01",SUBSYSTEM_DESCR:"Flowline 1 and Receiver",WD_PLAN:"2022-04-14",A_OV_TOT:412,A_OV_COM:13,A_OV_REM:399,A_OV_PROG:3.15,A_OV_STATUS:"less than 70%",A_PG:3.15,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,ACT_TAG_VALID:227,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-1010-1012-01",SUBSYSTEM_DESCR:"Flowline 2 and Receiver",WD_PLAN:"2022-04-14",A_OV_TOT:399,A_OV_COM:12,A_OV_REM:387,A_OV_PROG:3,A_OV_STATUS:"less than 70%",A_PG:3,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:220,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-1090-1090-01",SUBSYSTEM_DESCR:"Production Control Umbilical",WD_PLAN:"2022-04-14",A_OV_TOT:9,A_OV_COM:0,A_OV_REM:9,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-1510-1510-01",SUBSYSTEM_DESCR:"MEG Riser and Pipeline System to DC1",WD_PLAN:"2022-04-14",A_OV_TOT:27,A_OV_COM:0,A_OV_REM:27,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-1600-1611-01",SUBSYSTEM_DESCR:"Gas Export Riser 1",WD_PLAN:"2022-04-14",A_OV_TOT:56,A_OV_COM:6,A_OV_REM:50,A_OV_PROG:10.71,A_OV_STATUS:"less than 70%",A_PG:10.71,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:28,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-1600-1612-01",SUBSYSTEM_DESCR:"Gas Export Riser 2",WD_PLAN:"2022-04-14",A_OV_TOT:62,A_OV_COM:6,A_OV_REM:56,A_OV_PROG:9.67,A_OV_STATUS:"less than 70%",A_PG:9.67,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:29,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-1911-1911-01",SUBSYSTEM_DESCR:"Hydraulic HPUs, RIOs and TUTUs",WD_PLAN:"2022-04-14",A_OV_TOT:66,A_OV_COM:1,A_OV_REM:65,A_OV_PROG:1.51,A_OV_STATUS:"less than 70%",A_PG:1.51,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:43,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-1931-1931-01",SUBSYSTEM_DESCR:"Subsea Control System ,MCS, SSIV, SPCU, EPU's, HVE JB's, LVE JB's and Subsea ICSS",WD_PLAN:"2022-03-03",A_OV_TOT:94,A_OV_COM:0,A_OV_REM:94,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:11,PB1_OV_COM:0,PB1_OV_REM:11,ACT_TAG_VALID:92,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-2200-2131-01",SUBSYSTEM_DESCR:"Slug Catcher 1 and Liquid Heater 1",WD_PLAN:"2022-04-21",A_OV_TOT:608,A_OV_COM:119,A_OV_REM:489,A_OV_PROG:19.57,A_OV_STATUS:"less than 70%",A_PG:19.57,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P3",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:307,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2200-2132-01",SUBSYSTEM_DESCR:"Slug Catcher 2 and Liquid heater 2",WD_PLAN:"2022-04-21",A_OV_TOT:598,A_OV_COM:108,A_OV_REM:490,A_OV_PROG:18.06,A_OV_STATUS:"less than 70%",A_PG:18.06,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P3",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:298,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2200-2221-01",SUBSYSTEM_DESCR:"MP Separator",WD_PLAN:"2022-06-30",A_OV_TOT:362,A_OV_COM:79,A_OV_REM:283,A_OV_PROG:21.82,A_OV_STATUS:"less than 70%",A_PG:21.82,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:175,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2200-2230-01",SUBSYSTEM_DESCR:"LP Separator",WD_PLAN:"2022-05-19",A_OV_TOT:319,A_OV_COM:78,A_OV_REM:241,A_OV_PROG:24.45,A_OV_STATUS:"less than 70%",A_PG:24.45,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:145,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2200-2230-02",SUBSYSTEM_DESCR:"Interstage Heaters",WD_PLAN:"2022-06-02",A_OV_TOT:146,A_OV_COM:36,A_OV_REM:110,A_OV_PROG:24.65,A_OV_STATUS:"less than 70%",A_PG:24.65,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:58,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2200-2240-01",SUBSYSTEM_DESCR:"LLP separator, Condensate Coolers and Condensate Coalescer Pumps",WD_PLAN:"2022-04-28",A_OV_TOT:455,A_OV_COM:82,A_OV_REM:373,A_OV_PROG:18.02,A_OV_STATUS:"less than 70%",A_PG:18.02,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:1,PA_OV_REM:2,ACT_TAG_VALID:204,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-2200-2240-03",SUBSYSTEM_DESCR:"Condensate Coalescer Filters",WD_PLAN:"2022-04-28",A_OV_TOT:145,A_OV_COM:51,A_OV_REM:94,A_OV_PROG:35.17,A_OV_STATUS:"less than 70%",A_PG:35.17,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:64,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2200-2240-04",SUBSYSTEM_DESCR:"Condensate Coalescer Package",WD_PLAN:"2022-04-28",A_OV_TOT:229,A_OV_COM:58,A_OV_REM:171,A_OV_PROG:25.32,A_OV_STATUS:"less than 70%",A_PG:25.32,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PB1_OV_TOT:3,PB1_OV_COM:3,PB1_OV_REM:0,ACT_TAG_VALID:104,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2200-2470-01",SUBSYSTEM_DESCR:"Warm Condensate Heater, Offspec Condensate Pump and Distribution",WD_PLAN:"2022-06-30",A_OV_TOT:268,A_OV_COM:35,A_OV_REM:233,A_OV_PROG:13.05,A_OV_STATUS:"less than 70%",A_PG:13.05,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:151,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2400-2440-01",SUBSYSTEM_DESCR:"Condensate Metering",WD_PLAN:"2022-05-26",A_OV_TOT:113,A_OV_COM:2,A_OV_REM:111,A_OV_PROG:1.76,A_OV_STATUS:"less than 70%",A_PG:1.76,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:6,PA_OV_COM:1,PA_OV_REM:5,PB1_OV_TOT:21,PB1_OV_COM:1,PB1_OV_REM:20,ACT_TAG_VALID:67,PUNCH_NUMBER_VALID:27,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-2400-2450-01",SUBSYSTEM_DESCR:"CFW1 Offspec Condensate Tank and Pumps ,Incl Washing Machine",WD_PLAN:"2022-02-10",A_OV_TOT:405,A_OV_COM:108,A_OV_REM:297,A_OV_PROG:26.66,A_OV_STATUS:"less than 70%",A_PG:26.66,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:170,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2400-2460-01",SUBSYSTEM_DESCR:"Condensate Offloading",WD_PLAN:"2022-05-26",A_OV_TOT:141,A_OV_COM:3,A_OV_REM:138,A_OV_PROG:2.12,A_OV_STATUS:"less than 70%",A_PG:2.12,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:6,PB1_OV_COM:0,PB1_OV_REM:6,ACT_TAG_VALID:80,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-2400-9360-01",SUBSYSTEM_DESCR:"Cargo Oil Washing Header and Distribution",WD_PLAN:"2022-01-13",A_OV_TOT:172,A_OV_COM:105,A_OV_REM:67,A_OV_PROG:61.04,A_OV_STATUS:"less than 70%",A_PG:61.04,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:135,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2400-9360-02",SUBSYSTEM_DESCR:"Condensate ImportandLoading Header",WD_PLAN:"2022-01-13",A_OV_TOT:119,A_OV_COM:43,A_OV_REM:76,A_OV_PROG:36.13,A_OV_STATUS:"less than 70%",A_PG:36.13,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:78,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2400-9360-03",SUBSYSTEM_DESCR:"Condensate Transfer Header",WD_PLAN:"2022-01-13",A_OV_TOT:449,A_OV_COM:133,A_OV_REM:316,A_OV_PROG:29.62,A_OV_STATUS:"less than 70%",A_PG:29.62,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:287,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2400-9360-04",SUBSYSTEM_DESCR:"Condensate ExportandOffloading Header",WD_PLAN:"2022-01-13",A_OV_TOT:298,A_OV_COM:91,A_OV_REM:207,A_OV_PROG:30.53,A_OV_STATUS:"less than 70%",A_PG:30.53,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:164,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2400-9361-01",SUBSYSTEM_DESCR:"COW1 Tank and Pumps ,Incl Washing Machine",WD_PLAN:"2021-11-11",A_OV_TOT:223,A_OV_COM:81,A_OV_REM:142,A_OV_PROG:36.32,A_OV_STATUS:"less than 70%",A_PG:36.32,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:96,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2400-9362-01",SUBSYSTEM_DESCR:"COW2 Tank and Pumps ,Incl Washing Machine",WD_PLAN:"2021-11-11",A_OV_TOT:224,A_OV_COM:80,A_OV_REM:144,A_OV_PROG:35.71,A_OV_STATUS:"less than 70%",A_PG:35.71,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:102,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2400-9363-01",SUBSYSTEM_DESCR:"COW3 Tank and Pumps ,Incl Washing Machine",WD_PLAN:"2021-11-11",A_OV_TOT:219,A_OV_COM:84,A_OV_REM:135,A_OV_PROG:38.35,A_OV_STATUS:"less than 70%",A_PG:38.35,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:98,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2400-9364-01",SUBSYSTEM_DESCR:"COW4 Tank and Pumps ,Incl Washing Machine",WD_PLAN:"2021-11-11",A_OV_TOT:218,A_OV_COM:92,A_OV_REM:126,A_OV_PROG:42.2,A_OV_STATUS:"less than 70%",A_PG:42.2,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:97,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2400-9365-01",SUBSYSTEM_DESCR:"COE1 Tank and Pumps ,Incl Washing Machine",WD_PLAN:"2022-01-13",A_OV_TOT:204,A_OV_COM:74,A_OV_REM:130,A_OV_PROG:36.27,A_OV_STATUS:"less than 70%",A_PG:36.27,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:89,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2400-9366-01",SUBSYSTEM_DESCR:"COE2 Tank and Pumps ,Incl Washing Machine",WD_PLAN:"2022-01-13",A_OV_TOT:208,A_OV_COM:75,A_OV_REM:133,A_OV_PROG:36.05,A_OV_STATUS:"less than 70%",A_PG:36.05,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:98,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2400-9367-01",SUBSYSTEM_DESCR:"COE3 Tank and Pumps ,Incl Washing Machine",WD_PLAN:"2022-01-13",A_OV_TOT:206,A_OV_COM:85,A_OV_REM:121,A_OV_PROG:41.26,A_OV_STATUS:"less than 70%",A_PG:41.26,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:96,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2400-9368-01",SUBSYSTEM_DESCR:"COE4 Tank and Pumps ,Incl Washing Machine",WD_PLAN:"2022-01-13",A_OV_TOT:209,A_OV_COM:86,A_OV_REM:123,A_OV_PROG:41.14,A_OV_STATUS:"less than 70%",A_PG:41.14,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:99,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2500-2510-01",SUBSYSTEM_DESCR:"Rich MEG Collection, Flash Drum and Coolers",WD_PLAN:"2022-05-19",A_OV_TOT:206,A_OV_COM:69,A_OV_REM:137,A_OV_PROG:33.49,A_OV_STATUS:"less than 70%",A_PG:33.49,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:104,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-2500-2510-02",SUBSYSTEM_DESCR:"MEG Transfer Header",WD_PLAN:"2021-12-09",A_OV_TOT:65,A_OV_COM:12,A_OV_REM:53,A_OV_PROG:18.46,A_OV_STATUS:"less than 70%",A_PG:18.46,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:39,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2500-2510-04",SUBSYSTEM_DESCR:"Salty Rich MEG Import Header",WD_PLAN:"2022-05-19",A_OV_TOT:68,A_OV_COM:26,A_OV_REM:42,A_OV_PROG:38.23,A_OV_STATUS:"less than 70%",A_PG:38.23,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:41,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2500-2510-05",SUBSYSTEM_DESCR:"Salty Rich MEG Export Header",WD_PLAN:"2022-05-19",A_OV_TOT:75,A_OV_COM:27,A_OV_REM:48,A_OV_PROG:36,A_OV_STATUS:"less than 70%",A_PG:36,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:47,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2500-2510-06",SUBSYSTEM_DESCR:"Rich MEG Header to MEG Regeneration",WD_PLAN:"2022-05-19",A_OV_TOT:47,A_OV_COM:14,A_OV_REM:33,A_OV_PROG:29.78,A_OV_STATUS:"less than 70%",A_PG:29.78,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2500-2511-01",SUBSYSTEM_DESCR:"MRW1 Rich MEG Storage and Pumps",WD_PLAN:"2022-02-03",A_OV_TOT:314,A_OV_COM:93,A_OV_REM:221,A_OV_PROG:29.61,A_OV_STATUS:"less than 70%",A_PG:29.61,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:151,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2500-2512-01",SUBSYSTEM_DESCR:"MSE1 Salty Rich MEG Storage and Pumps",WD_PLAN:"2021-12-30",A_OV_TOT:255,A_OV_COM:93,A_OV_REM:162,A_OV_PROG:36.47,A_OV_STATUS:"less than 70%",A_PG:36.47,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:114,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2500-2513-01",SUBSYSTEM_DESCR:"MSW2 Salty rich MEG storage and pumps",WD_PLAN:"2022-02-03",A_OV_TOT:195,A_OV_COM:72,A_OV_REM:123,A_OV_PROG:36.92,A_OV_STATUS:"less than 70%",A_PG:36.92,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:92,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2500-2514-01",SUBSYSTEM_DESCR:"MSE2 Salty Rich MEG Storage and Pumps",WD_PLAN:"2022-02-03",A_OV_TOT:212,A_OV_COM:91,A_OV_REM:121,A_OV_PROG:42.92,A_OV_STATUS:"less than 70%",A_PG:42.92,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:97,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2500-2530-01",SUBSYSTEM_DESCR:"MEG Regeneration",WD_PLAN:"2022-04-14",A_OV_TOT:245,A_OV_COM:20,A_OV_REM:225,A_OV_PROG:8.16,A_OV_STATUS:"less than 70%",A_PG:8.16,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:9,PA_OV_COM:0,PA_OV_REM:9,PB1_OV_TOT:13,PB1_OV_COM:0,PB1_OV_REM:13,ACT_TAG_VALID:157,PUNCH_NUMBER_VALID:22,PUNCH_TAG_VALID:12},
    {SUBSYSTEM:"21-2500-2530-02",SUBSYSTEM_DESCR:"Lean MEG from MEG Regeneration to Storage",WD_PLAN:"2022-04-28",A_OV_TOT:30,A_OV_COM:6,A_OV_REM:24,A_OV_PROG:20,A_OV_STATUS:"less than 70%",A_PG:20,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2500-2551-01",SUBSYSTEM_DESCR:"MLW1 Lean MEG Storage and Pumps",WD_PLAN:"2021-12-30",A_OV_TOT:311,A_OV_COM:107,A_OV_REM:204,A_OV_PROG:34.4,A_OV_STATUS:"less than 70%",A_PG:34.4,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:143,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2500-2552-01",SUBSYSTEM_DESCR:"MLE1 Lean MEG Storage and Pumps",WD_PLAN:"2021-12-30",A_OV_TOT:293,A_OV_COM:111,A_OV_REM:182,A_OV_PROG:37.88,A_OV_STATUS:"less than 70%",A_PG:37.88,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:147,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2500-2553-01",SUBSYSTEM_DESCR:"Lean MEG Filters and Break Tank",WD_PLAN:"2022-05-05",A_OV_TOT:455,A_OV_COM:181,A_OV_REM:274,A_OV_PROG:39.78,A_OV_STATUS:"less than 70%",A_PG:39.78,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:206,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2500-2553-02",SUBSYSTEM_DESCR:"Topsides Lean MEG Pumps and Distribution",WD_PLAN:"2022-05-05",A_OV_TOT:280,A_OV_COM:72,A_OV_REM:208,A_OV_PROG:25.71,A_OV_STATUS:"less than 70%",A_PG:25.71,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:147,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2500-2553-03",SUBSYSTEM_DESCR:"Subsea Lean MEG Pumps and Distribution",WD_PLAN:"2022-06-09",A_OV_TOT:309,A_OV_COM:63,A_OV_REM:246,A_OV_PROG:20.38,A_OV_STATUS:"less than 70%",A_PG:20.38,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:21,PA_OV_COM:20,PA_OV_REM:1,PB1_OV_TOT:3,PB1_OV_COM:1,PB1_OV_REM:2,ACT_TAG_VALID:202,PUNCH_NUMBER_VALID:24,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-2500-2562-01",SUBSYSTEM_DESCR:"MEG Transfer from Bunkering Station",WD_PLAN:"2021-12-23",A_OV_TOT:43,A_OV_COM:24,A_OV_REM:19,A_OV_PROG:55.81,A_OV_STATUS:"less than 70%",A_PG:55.81,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:30,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2600-2630-01",SUBSYSTEM_DESCR:"Methanol Storage and Pumps",WD_PLAN:"2021-12-09",A_OV_TOT:142,A_OV_COM:35,A_OV_REM:107,A_OV_PROG:24.64,A_OV_STATUS:"less than 70%",A_PG:24.64,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:59,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2600-2640-01",SUBSYSTEM_DESCR:"Methanol Filters and Break Tank",WD_PLAN:"2022-05-05",A_OV_TOT:311,A_OV_COM:109,A_OV_REM:202,A_OV_PROG:35.04,A_OV_STATUS:"less than 70%",A_PG:35.04,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:157,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2600-2640-02",SUBSYSTEM_DESCR:"Methanol HP Pumps",WD_PLAN:"2022-05-05",A_OV_TOT:144,A_OV_COM:8,A_OV_REM:136,A_OV_PROG:5.55,A_OV_STATUS:"less than 70%",A_PG:5.55,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:69,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2600-2640-03",SUBSYSTEM_DESCR:"Methanol MP Pumps",WD_PLAN:"2022-05-05",A_OV_TOT:194,A_OV_COM:61,A_OV_REM:133,A_OV_PROG:31.44,A_OV_STATUS:"less than 70%",A_PG:31.44,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:109,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2600-2640-04",SUBSYSTEM_DESCR:"Methanol HP Header",WD_PLAN:"2022-05-19",A_OV_TOT:30,A_OV_COM:0,A_OV_REM:30,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2600-2640-05",SUBSYSTEM_DESCR:"Methanol MP Header",WD_PLAN:"2022-05-19",A_OV_TOT:279,A_OV_COM:119,A_OV_REM:160,A_OV_PROG:42.65,A_OV_STATUS:"less than 70%",A_PG:42.65,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:182,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2600-2641-01",SUBSYSTEM_DESCR:"Methanol Subsea Pumps",WD_PLAN:"2022-04-28",A_OV_TOT:159,A_OV_COM:18,A_OV_REM:141,A_OV_PROG:11.32,A_OV_STATUS:"less than 70%",A_PG:11.32,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:78,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2600-2641-02",SUBSYSTEM_DESCR:"Methanol Subsea Distribution",WD_PLAN:"2022-05-05",A_OV_TOT:12,A_OV_COM:0,A_OV_REM:12,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2600-8510-01",SUBSYSTEM_DESCR:"Methanol Loading Header",WD_PLAN:"2022-06-23",A_OV_TOT:91,A_OV_COM:66,A_OV_REM:25,A_OV_PROG:72.52,A_OV_STATUS:"NA",A_PG:72.52,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:75,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-3040-3040-01",SUBSYSTEM_DESCR:"HPandMP Gas Compressors, UCP, VSD and Electrical ControlandSwitchboards",WD_PLAN:"2022-03-24",A_OV_TOT:190,A_OV_COM:16,A_OV_REM:174,A_OV_PROG:8.42,A_OV_STATUS:"less than 70%",A_PG:8.42,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:34,PA_OV_COM:13,PA_OV_REM:21,PB1_OV_TOT:5,PB1_OV_COM:0,PB1_OV_REM:5,ACT_TAG_VALID:140,PUNCH_NUMBER_VALID:39,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-3040-3040-02",SUBSYSTEM_DESCR:"HPandMP Gas Compressors Train Scrubbers, Aftercoolers and Process Lines",WD_PLAN:"2022-06-23",A_OV_TOT:632,A_OV_COM:167,A_OV_REM:465,A_OV_PROG:26.42,A_OV_STATUS:"less than 70%",A_PG:26.42,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:290,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-3040-3041-01",SUBSYSTEM_DESCR:"LPandLLP Gas Compressors , UCP, VSD and Electrical ControlandSwitchboards",WD_PLAN:"2022-03-24",A_OV_TOT:102,A_OV_COM:4,A_OV_REM:98,A_OV_PROG:3.92,A_OV_STATUS:"less than 70%",A_PG:3.92,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:7,PA_OV_COM:0,PA_OV_REM:7,PB1_OV_TOT:13,PB1_OV_COM:1,PB1_OV_REM:12,ACT_TAG_VALID:67,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-3040-3041-02",SUBSYSTEM_DESCR:"LPandLLP Gas Compressors Train Scrubbers, Aftercoolers and Process Lines",WD_PLAN:"2022-06-23",A_OV_TOT:530,A_OV_COM:151,A_OV_REM:379,A_OV_PROG:28.49,A_OV_STATUS:"less than 70%",A_PG:28.49,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:243,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-3330-3330-01",SUBSYSTEM_DESCR:"Process Gas, GasandGas Exchanger, TEX Scrubber and JT Valve",WD_PLAN:"2022-05-12",A_OV_TOT:634,A_OV_COM:209,A_OV_REM:425,A_OV_PROG:32.96,A_OV_STATUS:"less than 70%",A_PG:32.96,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:375,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-3330-3330-02",SUBSYSTEM_DESCR:"Gas Coalescer and LT Separator",WD_PLAN:"2022-04-28",A_OV_TOT:701,A_OV_COM:214,A_OV_REM:487,A_OV_PROG:30.52,A_OV_STATUS:"less than 70%",A_PG:30.52,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:401,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-3330-3330-03",SUBSYSTEM_DESCR:"Turbo Expander, Recompressor",WD_PLAN:"2022-04-28",A_OV_TOT:354,A_OV_COM:50,A_OV_REM:304,A_OV_PROG:14.12,A_OV_STATUS:"less than 70%",A_PG:14.12,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,PB1_OV_TOT:4,PB1_OV_COM:0,PB1_OV_REM:4,ACT_TAG_VALID:217,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-3330-3410-01",SUBSYSTEM_DESCR:"Gas Export Heaters",WD_PLAN:"2022-05-26",A_OV_TOT:128,A_OV_COM:24,A_OV_REM:104,A_OV_PROG:18.75,A_OV_STATUS:"less than 70%",A_PG:18.75,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:51,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-3330-3410-02",SUBSYSTEM_DESCR:"Export Gas Metering and Export Risers",WD_PLAN:"2022-05-12",A_OV_TOT:134,A_OV_COM:15,A_OV_REM:119,A_OV_PROG:11.19,A_OV_STATUS:"less than 70%",A_PG:11.19,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:63,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-3330-3410-03",SUBSYSTEM_DESCR:"Repressurisation Heater and Header",WD_PLAN:"2022-06-30",A_OV_TOT:134,A_OV_COM:22,A_OV_REM:112,A_OV_PROG:16.41,A_OV_STATUS:"less than 70%",A_PG:16.41,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,ACT_TAG_VALID:61,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-4000-4030-01",SUBSYSTEM_DESCR:"Produced Water Offspec Storage Tank and Pumps",WD_PLAN:"2022-02-10",A_OV_TOT:343,A_OV_COM:120,A_OV_REM:223,A_OV_PROG:34.98,A_OV_STATUS:"less than 70%",A_PG:34.98,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:179,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-4000-4030-02",SUBSYSTEM_DESCR:"Produced Water Offspec Header to Storage Tank",WD_PLAN:"2022-02-10",A_OV_TOT:48,A_OV_COM:15,A_OV_REM:33,A_OV_PROG:31.25,A_OV_STATUS:"less than 70%",A_PG:31.25,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-4000-4030-03",SUBSYSTEM_DESCR:"Produced Water Offspec Header to Produced Water Treatment and Bunkering Station",WD_PLAN:"2022-02-10",A_OV_TOT:62,A_OV_COM:25,A_OV_REM:37,A_OV_PROG:40.32,A_OV_STATUS:"less than 70%",A_PG:40.32,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:34,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-4000-4130-01",SUBSYSTEM_DESCR:"Deoiling Package ,Hydrocyclone + CFU",WD_PLAN:"2022-05-19",A_OV_TOT:214,A_OV_COM:21,A_OV_REM:193,A_OV_PROG:9.81,A_OV_STATUS:"less than 70%",A_PG:9.81,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:3,PA_OV_COM:1,PA_OV_REM:2,PB1_OV_TOT:4,PB1_OV_COM:0,PB1_OV_REM:4,ACT_TAG_VALID:88,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-4000-4130-02",SUBSYSTEM_DESCR:"Flocculent Injection Package",WD_PLAN:"2022-05-12",A_OV_TOT:143,A_OV_COM:43,A_OV_REM:100,A_OV_PROG:30.06,A_OV_STATUS:"less than 70%",A_PG:30.06,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:5,PB1_OV_COM:1,PB1_OV_REM:4,ACT_TAG_VALID:91,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-4000-4130-03",SUBSYSTEM_DESCR:"Demulsifier Injection Package",WD_PLAN:"2022-05-12",A_OV_TOT:12,A_OV_COM:0,A_OV_REM:12,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-4000-4130-04",SUBSYSTEM_DESCR:"Produced Water Polishing Package",WD_PLAN:"2022-05-12",A_OV_TOT:17,A_OV_COM:1,A_OV_REM:16,A_OV_PROG:5.88,A_OV_STATUS:"less than 70%",A_PG:5.88,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:8,PA_OV_COM:3,PA_OV_REM:5,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-4000-4150-01",SUBSYSTEM_DESCR:"Mercury Removal Package",WD_PLAN:"2022-05-12",A_OV_TOT:63,A_OV_COM:4,A_OV_REM:59,A_OV_PROG:6.34,A_OV_STATUS:"less than 70%",A_PG:6.34,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:3,PA_OV_COM:2,PA_OV_REM:1,PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-4000-4150-02",SUBSYSTEM_DESCR:"Treated Water Header and Discharge Caisson",WD_PLAN:"2022-05-12",A_OV_TOT:28,A_OV_COM:0,A_OV_REM:28,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5000-5010-01",SUBSYSTEM_DESCR:"HP Flare KO Drum and Pumps",WD_PLAN:"2022-06-16",A_OV_TOT:139,A_OV_COM:11,A_OV_REM:128,A_OV_PROG:7.91,A_OV_STATUS:"less than 70%",A_PG:7.91,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:69,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5000-5020-01",SUBSYSTEM_DESCR:"HP Flare Ignition, Metering and Tip",WD_PLAN:"2022-05-26",A_OV_TOT:103,A_OV_COM:2,A_OV_REM:101,A_OV_PROG:1.94,A_OV_STATUS:"less than 70%",A_PG:1.94,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:29,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5000-5030-01",SUBSYSTEM_DESCR:"Cold and Dry HP Flare Header",WD_PLAN:"2022-07-21",A_OV_TOT:209,A_OV_COM:56,A_OV_REM:153,A_OV_PROG:26.79,A_OV_STATUS:"less than 70%",A_PG:26.79,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:92,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5000-5030-02",SUBSYSTEM_DESCR:"Wet HP Flare Header",WD_PLAN:"2022-07-21",A_OV_TOT:22,A_OV_COM:3,A_OV_REM:19,A_OV_PROG:13.63,A_OV_STATUS:"less than 70%",A_PG:13.63,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5000-5030-03",SUBSYSTEM_DESCR:"HP liquid Flare Header",WD_PLAN:"2022-07-21",A_OV_TOT:237,A_OV_COM:79,A_OV_REM:158,A_OV_PROG:33.33,A_OV_STATUS:"less than 70%",A_PG:33.33,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:126,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5200-4340-01",SUBSYSTEM_DESCR:"Closed Drain Drum and Pumps",WD_PLAN:"2022-04-07",A_OV_TOT:128,A_OV_COM:27,A_OV_REM:101,A_OV_PROG:21.09,A_OV_STATUS:"less than 70%",A_PG:21.09,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,ACT_TAG_VALID:69,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-5200-4340-02",SUBSYSTEM_DESCR:"Closed Drain Header",WD_PLAN:"2022-05-12",A_OV_TOT:724,A_OV_COM:178,A_OV_REM:546,A_OV_PROG:24.58,A_OV_STATUS:"less than 70%",A_PG:24.58,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:255,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5200-4340-03",SUBSYSTEM_DESCR:"HULL Deck Equipment Drainage Header",WD_PLAN:"2022-05-26",A_OV_TOT:26,A_OV_COM:19,A_OV_REM:7,A_OV_PROG:73.07,A_OV_STATUS:"NA",A_PG:73.07,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:21,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5200-5210-01",SUBSYSTEM_DESCR:"LP Flare KO Drum and Pumps",WD_PLAN:"2022-05-26",A_OV_TOT:110,A_OV_COM:12,A_OV_REM:98,A_OV_PROG:10.9,A_OV_STATUS:"less than 70%",A_PG:10.9,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,ACT_TAG_VALID:49,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-5200-5220-01",SUBSYSTEM_DESCR:"LP Flare Metering and Tip",WD_PLAN:"2022-05-26",A_OV_TOT:70,A_OV_COM:2,A_OV_REM:68,A_OV_PROG:2.85,A_OV_STATUS:"less than 70%",A_PG:2.85,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5200-5230-01",SUBSYSTEM_DESCR:"LP Flare Header",WD_PLAN:"2022-07-21",A_OV_TOT:203,A_OV_COM:59,A_OV_REM:144,A_OV_PROG:29.06,A_OV_STATUS:"less than 70%",A_PG:29.06,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:100,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5400-5410-01",SUBSYSTEM_DESCR:"Vent Headers, Boom and Liquid Seal Systems",WD_PLAN:"2022-02-24",A_OV_TOT:301,A_OV_COM:78,A_OV_REM:223,A_OV_PROG:25.91,A_OV_STATUS:"less than 70%",A_PG:25.91,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:137,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-5400-5410-02",SUBSYSTEM_DESCR:"CO2 Snuffing System",WD_PLAN:"2022-07-07",A_OV_TOT:30,A_OV_COM:0,A_OV_REM:30,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5700-5710-01",SUBSYSTEM_DESCR:"Flare Gas Vapour Recovery Skid",WD_PLAN:"2022-05-26",A_OV_TOT:38,A_OV_COM:3,A_OV_REM:35,A_OV_PROG:7.89,A_OV_STATUS:"less than 70%",A_PG:7.89,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:5,PA_OV_COM:2,PA_OV_REM:3,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-5700-5730-01",SUBSYSTEM_DESCR:"HULL Tank Vapour Recovery Skid",WD_PLAN:"2022-05-26",A_OV_TOT:59,A_OV_COM:10,A_OV_REM:49,A_OV_PROG:16.94,A_OV_STATUS:"less than 70%",A_PG:16.94,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,ACT_TAG_VALID:32,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6010-6011-01",SUBSYSTEM_DESCR:"SIS Marshalling - Machinery Space ,HER",WD_PLAN:"2021-10-07",A_OV_TOT:44,A_OV_COM:43,A_OV_REM:1,A_OV_PROG:97.72,A_OV_STATUS:"greater than 95%",A_PG:97.72,RFI_ISSUED:"2021-10-25",WD_ACTUAL:"2021-10-27",PL_RECEIVED:"2021-10-27",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1A",PA_OV_TOT:30,PA_OV_COM:3,PA_OV_REM:27,PB1_OV_TOT:12,PB1_OV_COM:3,PB1_OV_REM:9,ACT_TAG_VALID:44,PUNCH_NUMBER_VALID:42,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-6010-6011-02",SUBSYSTEM_DESCR:"SIS Marshalling - LQ ,CER",WD_PLAN:"2021-10-14",A_OV_TOT:14,A_OV_COM:14,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-10-20",WD_ACTUAL:"2021-10-21",PL_RECEIVED:"2021-10-21",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"A",PRIORITY:"P1A",PA_OV_TOT:23,PA_OV_COM:14,PA_OV_REM:9,PB1_OV_TOT:4,PB1_OV_COM:1,PB1_OV_REM:3,ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:27,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6010-6011-03",SUBSYSTEM_DESCR:"SIS Marshalling - Topsides ,ME05 and LER",WD_PLAN:"2021-11-04",A_OV_TOT:22,A_OV_COM:0,A_OV_REM:22,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6010-6011-04",SUBSYSTEM_DESCR:"CAP Panel in CCR",WD_PLAN:"2021-11-11",A_OV_TOT:23,A_OV_COM:0,A_OV_REM:23,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:23,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6010-6011-05",SUBSYSTEM_DESCR:"SIS Multicore and Junction Box to Marshalling and System Cabinets in LER",A_OV_STATUS:"less than 70%",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"NA",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6010-6011-06",SUBSYSTEM_DESCR:"SIS Multicore and Junction Box to Marshalling and System Cabinets in CER",A_OV_STATUS:"less than 70%",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"NA",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6010-6011-07",SUBSYSTEM_DESCR:"SIS Multicore and Junction Box to Marshalling and System Cabinets in HER",WD_PLAN:"2021-10-14",A_OV_TOT:109,A_OV_COM:9,A_OV_REM:100,A_OV_PROG:8.25,A_OV_STATUS:"less than 70%",A_PG:8.25,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:5,PA_OV_COM:0,PA_OV_REM:5,PB1_OV_TOT:12,PB1_OV_COM:0,PB1_OV_REM:12,ACT_TAG_VALID:85,PUNCH_NUMBER_VALID:17,PUNCH_TAG_VALID:7},
    {SUBSYSTEM:"21-6010-6011-08",SUBSYSTEM_DESCR:"SIS Field Push Buttons",WD_PLAN:"2022-02-17",A_OV_TOT:91,A_OV_COM:0,A_OV_REM:91,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:47,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6010-6012-01",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables ME01",WD_PLAN:"2021-11-04",A_OV_TOT:12,A_OV_COM:0,A_OV_REM:12,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6010-6012-02",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables MW01",WD_PLAN:"2021-11-11",A_OV_TOT:18,A_OV_COM:0,A_OV_REM:18,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6010-6012-03",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables ME02",WD_PLAN:"2021-11-11",A_OV_TOT:18,A_OV_COM:0,A_OV_REM:18,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6010-6012-04",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables MW02",WD_PLAN:"2021-11-11",A_OV_TOT:24,A_OV_COM:0,A_OV_REM:24,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6010-6012-05",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables OFL00",WD_PLAN:"2022-04-07",A_OV_TOT:6,A_OV_COM:0,A_OV_REM:6,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6010-6012-06",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables MW04",WD_PLAN:"2021-11-18",A_OV_TOT:12,A_OV_COM:0,A_OV_REM:12,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6010-6012-07",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables MW05",WD_PLAN:"2021-12-16",A_OV_TOT:6,A_OV_COM:0,A_OV_REM:6,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6010-6012-08",SUBSYSTEM_DESCR:"SIS Remote IOs ,incl Junction Box and Multicore Cables ME04",WD_PLAN:"2021-11-18",A_OV_TOT:6,A_OV_COM:0,A_OV_REM:6,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6010-6012-09",SUBSYSTEM_DESCR:"SIF's for 1st Gas Start-up",WD_PLAN:"2022-07-28",A_OV_STATUS:"NA",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"NA",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6010-6012-10",SUBSYSTEM_DESCR:"SIF's Post 1st Gas Start-up",WD_PLAN:"2022-07-28",A_OV_STATUS:"NA",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"NA",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6020-6021-01",SUBSYSTEM_DESCR:"PAS Marshalling - Machinery Space ,HER",BATCH:"Priority Batch",WD_PLAN:"2021-09-16",A_OV_TOT:46,A_OV_COM:44,A_OV_REM:2,A_OV_PROG:95.65,A_OV_STATUS:"greater than 95%",A_PG:95.65,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1A",PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,PB1_OV_TOT:14,PB1_OV_COM:0,PB1_OV_REM:14,ACT_TAG_VALID:46,PUNCH_NUMBER_VALID:16,PUNCH_TAG_VALID:7},
    {SUBSYSTEM:"21-6020-6021-02",SUBSYSTEM_DESCR:"PAS Marshalling - LQ ,CER",BATCH:"Priority Batch",WD_PLAN:"2021-10-14",A_OV_TOT:15,A_OV_COM:15,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-10-21",WD_ACTUAL:"2021-10-22",PL_RECEIVED:"2021-10-22",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"A",PRIORITY:"P1A",PA_OV_TOT:8,PA_OV_COM:4,PA_OV_REM:4,PB1_OV_TOT:9,PB1_OV_COM:1,PB1_OV_REM:8,ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:17,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6020-6021-03",SUBSYSTEM_DESCR:"PAS Marshalling - Topsides ,ME05 and LER",WD_PLAN:"2021-11-04",A_OV_TOT:19,A_OV_COM:0,A_OV_REM:19,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:19,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6020-6021-04",SUBSYSTEM_DESCR:"PAS Multicore and Junction Box to Marshalling and System Cabinets in LER",A_OV_STATUS:"less than 70%",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"NA",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6020-6021-05",SUBSYSTEM_DESCR:"PAS Multicore and Junction Box to Marshalling and System Cabinets in CER",A_OV_STATUS:"less than 70%",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"NA",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6020-6021-06",SUBSYSTEM_DESCR:"PAS Multicore and Junction Box to Marshalling and System Cabinets in HER",WD_PLAN:"2021-10-14",A_OV_TOT:170,A_OV_COM:6,A_OV_REM:164,A_OV_PROG:3.52,A_OV_STATUS:"less than 70%",A_PG:3.52,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:4,PA_OV_COM:0,PA_OV_REM:4,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:134,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-6020-6022-01",SUBSYSTEM_DESCR:"PAS Remote IOs,incl Junction Box and Multicore Cables ME01",WD_PLAN:"2021-11-04",A_OV_TOT:18,A_OV_COM:0,A_OV_REM:18,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6020-6022-02",SUBSYSTEM_DESCR:"PAS Remote IOs ,incl Junction Box and Multicore Cables MW01",WD_PLAN:"2021-11-11",A_OV_TOT:30,A_OV_COM:0,A_OV_REM:30,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6020-6022-03",SUBSYSTEM_DESCR:"PAS Remote IOs ,incl Junction Box and Multicore Cables ME02",WD_PLAN:"2021-11-11",A_OV_TOT:24,A_OV_COM:0,A_OV_REM:24,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6020-6022-04",SUBSYSTEM_DESCR:"PAS Remote IOs ,incl Junction Box and Multicore Cables MW02",WD_PLAN:"2021-11-11",A_OV_TOT:30,A_OV_COM:0,A_OV_REM:30,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6020-6022-05",SUBSYSTEM_DESCR:"PAS Remote IOs ,incl Junction Box and Multicore Cables ME04",WD_PLAN:"2021-11-25",A_OV_TOT:12,A_OV_COM:0,A_OV_REM:12,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6020-6022-06",SUBSYSTEM_DESCR:"PAS Remote IOs ,incl Junction Box and Multicore Cables MW04",WD_PLAN:"2021-11-18",A_OV_TOT:7,A_OV_COM:0,A_OV_REM:7,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6020-6022-07",SUBSYSTEM_DESCR:"PAS Remote IOs ,incl Junction Box and Multicore Cables MW05",WD_PLAN:"2021-12-16",A_OV_TOT:30,A_OV_COM:0,A_OV_REM:30,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6020-6022-08",SUBSYSTEM_DESCR:"PAS Remote IOs ,incl Junction Box and Multicore Cables OFL00",WD_PLAN:"2022-04-07",A_OV_TOT:12,A_OV_COM:0,A_OV_REM:12,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6030-6031-01",SUBSYSTEM_DESCR:"Electrical Data Monitoring and Constrol System ,EDMCS and Power Management System ,PMS",WD_PLAN:"2021-12-16",A_OV_TOT:198,A_OV_COM:36,A_OV_REM:162,A_OV_PROG:18.18,A_OV_STATUS:"less than 70%",A_PG:18.18,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:12,PA_OV_COM:10,PA_OV_REM:2,PB1_OV_TOT:6,PB1_OV_COM:0,PB1_OV_REM:6,ACT_TAG_VALID:198,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:10},
    {SUBSYSTEM:"21-6030-6032-01",SUBSYSTEM_DESCR:"ICSS Interface Servers, Workstations and Network Switches CER",WD_PLAN:"2021-10-21",A_OV_TOT:278,A_OV_COM:261,A_OV_REM:17,A_OV_PROG:93.88,A_OV_STATUS:"greater than 90%",A_PG:93.88,RFI_ISSUED:"Done",WD_ACTUAL:"DONE-PL RCVD",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1A",PA_OV_TOT:30,PA_OV_COM:26,PA_OV_REM:4,PB1_OV_TOT:78,PB1_OV_COM:23,PB1_OV_REM:55,ACT_TAG_VALID:278,PUNCH_NUMBER_VALID:108,PUNCH_TAG_VALID:18},
    {SUBSYSTEM:"21-6030-6032-02",SUBSYSTEM_DESCR:"ICSS Interface Servers, Workstations and Network Switches LER",WD_PLAN:"2021-11-04",A_OV_TOT:193,A_OV_COM:0,A_OV_REM:193,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:13,PB1_OV_COM:0,PB1_OV_REM:13,ACT_TAG_VALID:193,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6030-6032-03",SUBSYSTEM_DESCR:"ICSS Interface Servers, Workstations and Network Switches HER",WD_PLAN:"2021-10-07",A_OV_TOT:141,A_OV_COM:116,A_OV_REM:25,A_OV_PROG:82.26,A_OV_STATUS:"NA",A_PG:82.26,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1A",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PB1_OV_TOT:8,PB1_OV_COM:2,PB1_OV_REM:6,ACT_TAG_VALID:141,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-6030-6032-04",SUBSYSTEM_DESCR:"ICSS Workstations and Consoles - CCR",WD_PLAN:"2021-10-28",A_OV_TOT:66,A_OV_COM:0,A_OV_REM:66,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:66,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6030-6032-05",SUBSYSTEM_DESCR:"ICSS Workstations and Consoles - Safe HavenandSOP",WD_PLAN:"2021-11-25",A_OV_TOT:7,A_OV_COM:0,A_OV_REM:7,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6030-6033-01",SUBSYSTEM_DESCR:"Intelligent Device Management System ,IDMS",A_OV_STATUS:"less than 70%",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"NA",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6030-6034-01",SUBSYSTEM_DESCR:"Machinery Protection System ,MPS",WD_PLAN:"2021-11-25",A_OV_TOT:16,A_OV_COM:0,A_OV_REM:16,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6030-6035-01",SUBSYSTEM_DESCR:"Condition and Performance Monitoring System ,CPMS",A_OV_TOT:1,A_OV_COM:0,A_OV_REM:1,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"B1",PRIORITY:"NA",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-6030-6036-01",SUBSYSTEM_DESCR:"PDBs Inside HER",BATCH:"Priority Batch",WD_PLAN:"2021-09-16",A_OV_TOT:16,A_OV_COM:8,A_OV_REM:8,A_OV_PROG:50,A_OV_STATUS:"less than 70%",A_PG:50,RFI_ISSUED:"2021-09-27",WD_ACTUAL:"2021-09-29",PL_RECEIVED:"2021-09-29",MC_SUBMITTED:"2021-11-16",MC_REJECTED:"Rejected",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1A",PA_OV_TOT:17,PA_OV_COM:17,PA_OV_REM:0,PB1_OV_TOT:9,PB1_OV_COM:3,PB1_OV_REM:6,ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:26,PUNCH_TAG_VALID:7},
    {SUBSYSTEM:"21-6030-6036-02",SUBSYSTEM_DESCR:"PDBs Inside CER",BATCH:"Priority Batch",WD_PLAN:"2021-09-30",A_OV_TOT:31,A_OV_COM:15,A_OV_REM:16,A_OV_PROG:48.38,A_OV_STATUS:"less than 70%",A_PG:48.38,RFI_ISSUED:"2021-10-19",WD_ACTUAL:"2021-10-21",PL_RECEIVED:"2021-10-21",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1A",PA_OV_TOT:23,PA_OV_COM:18,PA_OV_REM:5,PB1_OV_TOT:11,PB1_OV_COM:1,PB1_OV_REM:10,ACT_TAG_VALID:27,PUNCH_NUMBER_VALID:34,PUNCH_TAG_VALID:10},
    {SUBSYSTEM:"21-6030-6036-03",SUBSYSTEM_DESCR:"PDBs Inside CCR",A_OV_STATUS:"less than 70%",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"NA",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6030-6036-04",SUBSYSTEM_DESCR:"PDBs Inside ME05",WD_PLAN:"2021-11-04",A_OV_TOT:24,A_OV_COM:0,A_OV_REM:24,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:24,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6030-6036-05",SUBSYSTEM_DESCR:"PDBs Inside L5 Telecom Room",WD_PLAN:"2021-11-18",A_OV_TOT:10,A_OV_COM:4,A_OV_REM:6,A_OV_PROG:40,A_OV_STATUS:"less than 70%",A_PG:40,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6030-6037-01",SUBSYSTEM_DESCR:"Tank Gauging System Cabinets and Workstations",WD_PLAN:"2021-10-07",A_OV_TOT:27,A_OV_COM:9,A_OV_REM:18,A_OV_PROG:33.33,A_OV_STATUS:"less than 70%",A_PG:33.33,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:10,PB1_OV_COM:0,PB1_OV_REM:10,ACT_TAG_VALID:26,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6030-6038-01",SUBSYSTEM_DESCR:"Cargo Loading Computer and Workstation",WD_PLAN:"2022-02-10",A_OV_TOT:4,A_OV_COM:0,A_OV_REM:4,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6030-6039-01",SUBSYSTEM_DESCR:"BallastandVoid Gas Sampling",WD_PLAN:"2021-10-28",A_OV_TOT:64,A_OV_COM:14,A_OV_REM:50,A_OV_PROG:21.87,A_OV_STATUS:"less than 70%",A_PG:21.87,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:39,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6030-6040-01",SUBSYSTEM_DESCR:"IRP and Interface Instrumentation Cable - ME05 ,HV and LV Room",WD_PLAN:"2021-10-21",A_OV_TOT:79,A_OV_COM:0,A_OV_REM:79,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:79,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6030-6040-02",SUBSYSTEM_DESCR:"IRP and Interface Instrumentation Cable - LQ",WD_PLAN:"2021-09-16",A_OV_TOT:69,A_OV_COM:65,A_OV_REM:4,A_OV_PROG:94.2,A_OV_STATUS:"greater than 90%",A_PG:94.2,RFI_ISSUED:"2021-11-02",WD_ACTUAL:"2021-11-04",PL_RECEIVED:"2021-11-04",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1A",PA_OV_TOT:23,PA_OV_COM:9,PA_OV_REM:14,PB1_OV_TOT:32,PB1_OV_COM:5,PB1_OV_REM:27,PB3_OV_TOT:1,PB3_OV_COM:0,PB3_OV_REM:1,ACT_TAG_VALID:69,PUNCH_NUMBER_VALID:56,PUNCH_TAG_VALID:18},
    {SUBSYSTEM:"21-6030-6040-03",SUBSYSTEM_DESCR:"IRP and Interface Instrumentation Cable - MS03",WD_PLAN:"2021-09-16",A_OV_TOT:181,A_OV_COM:135,A_OV_REM:46,A_OV_PROG:74.58,A_OV_STATUS:"NA",A_PG:74.58,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1A",PA_OV_TOT:4,PA_OV_COM:0,PA_OV_REM:4,PB1_OV_TOT:28,PB1_OV_COM:0,PB1_OV_REM:28,ACT_TAG_VALID:181,PUNCH_NUMBER_VALID:32,PUNCH_TAG_VALID:15},
    {SUBSYSTEM:"21-6030-6041-01",SUBSYSTEM_DESCR:"PEMS System",WD_PLAN:"2021-10-21",A_OV_TOT:2,A_OV_COM:0,A_OV_REM:2,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:4,PB1_OV_COM:0,PB1_OV_REM:4,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6030-6042-01",SUBSYSTEM_DESCR:"Corrosion and Erosion Monitoring",WD_PLAN:"2022-01-27",A_OV_TOT:73,A_OV_COM:0,A_OV_REM:73,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,ACT_TAG_VALID:30,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6030-6043-01",SUBSYSTEM_DESCR:"FOPPs and Associated Fiber Cables to LER",WD_PLAN:"2022-01-27",A_OV_TOT:234,A_OV_COM:0,A_OV_REM:234,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:234,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6030-6043-02",SUBSYSTEM_DESCR:"FOPPs and Associated Fiber Cables to CER",WD_PLAN:"2021-10-28",A_OV_TOT:25,A_OV_COM:0,A_OV_REM:25,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6030-6043-03",SUBSYSTEM_DESCR:"FOPPs and Associated Fiber Cables to HER",WD_PLAN:"2021-11-18",A_OV_TOT:17,A_OV_COM:0,A_OV_REM:17,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6035-6035-01",SUBSYSTEM_DESCR:"Process CCTV Cameras, Consoles and Screens",WD_PLAN:"2021-10-28",A_OV_TOT:317,A_OV_COM:8,A_OV_REM:309,A_OV_PROG:2.52,A_OV_STATUS:"less than 70%",A_PG:2.52,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:5,PB1_OV_COM:0,PB1_OV_REM:5,ACT_TAG_VALID:242,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-6070-6071-01",SUBSYSTEM_DESCR:"FGS Marshalling - Machinery Space ,HER",WD_PLAN:"2021-10-07",A_OV_TOT:14,A_OV_COM:14,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-10-25",WD_ACTUAL:"2021-10-27",PL_RECEIVED:"2021-10-27",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"A",PRIORITY:"P1A",PA_OV_TOT:8,PA_OV_COM:0,PA_OV_REM:8,PB1_OV_TOT:10,PB1_OV_COM:1,PB1_OV_REM:9,ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6070-6071-02",SUBSYSTEM_DESCR:"FGS Marshalling - LQ ,CER",WD_PLAN:"2021-10-14",A_OV_TOT:27,A_OV_COM:27,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-10-20",WD_ACTUAL:"2021-10-21",PL_RECEIVED:"2021-10-21",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"A",PRIORITY:"P1A",PA_OV_TOT:22,PA_OV_COM:12,PA_OV_REM:10,PB1_OV_TOT:11,PB1_OV_COM:1,PB1_OV_REM:10,ACT_TAG_VALID:27,PUNCH_NUMBER_VALID:33,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6070-6071-03",SUBSYSTEM_DESCR:"FGS Marshalling - Topsides ,ME05 and LER",WD_PLAN:"2021-11-04",A_OV_TOT:8,A_OV_COM:0,A_OV_REM:8,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6070-6071-04",SUBSYSTEM_DESCR:"FGS Multicore and Junction Box to Marshalling and System Cabinets in LER",WD_PLAN:"2021-11-04",A_OV_TOT:9,A_OV_COM:0,A_OV_REM:9,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6070-6071-05",SUBSYSTEM_DESCR:"FGS Multicore and Junction Box to Marshalling and System Cabinets in CER",WD_PLAN:"2021-11-18",A_OV_TOT:25,A_OV_COM:4,A_OV_REM:21,A_OV_PROG:16,A_OV_STATUS:"less than 70%",A_PG:16,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,ACT_TAG_VALID:24,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6070-6071-06",SUBSYSTEM_DESCR:"FGS Multicore and Junction Box to Marshalling and System Cabinets in HER",WD_PLAN:"2021-10-14",A_OV_TOT:10,A_OV_COM:2,A_OV_REM:8,A_OV_PROG:20,A_OV_STATUS:"less than 70%",A_PG:20,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6070-6072-01",SUBSYSTEM_DESCR:"Fire Addressable Panels - LQ ,CER and LQ Fire and Gas Addressable Loop-1",WD_PLAN:"2021-09-30",A_OV_TOT:397,A_OV_COM:101,A_OV_REM:296,A_OV_PROG:25.44,A_OV_STATUS:"less than 70%",A_PG:25.44,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:6,PB1_OV_COM:0,PB1_OV_REM:6,ACT_TAG_VALID:275,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-6070-6072-02",SUBSYSTEM_DESCR:"LQ Fire and Gas Addressable Loop-2",WD_PLAN:"2021-11-18",A_OV_TOT:370,A_OV_COM:89,A_OV_REM:281,A_OV_PROG:24.05,A_OV_STATUS:"less than 70%",A_PG:24.05,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,ACT_TAG_VALID:254,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6070-6072-03",SUBSYSTEM_DESCR:"LQ Fire and Gas Addressable Loop-3",WD_PLAN:"2021-11-25",A_OV_TOT:281,A_OV_COM:77,A_OV_REM:204,A_OV_PROG:27.4,A_OV_STATUS:"less than 70%",A_PG:27.4,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:6,PB1_OV_COM:0,PB1_OV_REM:6,ACT_TAG_VALID:203,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-6070-6072-04",SUBSYSTEM_DESCR:"LQ Fire and Gas Addressable Loop-4",WD_PLAN:"2021-11-25",A_OV_TOT:271,A_OV_COM:103,A_OV_REM:168,A_OV_PROG:38,A_OV_STATUS:"less than 70%",A_PG:38,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:14,PB1_OV_COM:0,PB1_OV_REM:14,ACT_TAG_VALID:186,PUNCH_NUMBER_VALID:14,PUNCH_TAG_VALID:10},
    {SUBSYSTEM:"21-6070-6072-05",SUBSYSTEM_DESCR:"LQ Fire and Gas Addressable Loop-5",WD_PLAN:"2021-11-25",A_OV_TOT:326,A_OV_COM:122,A_OV_REM:204,A_OV_PROG:37.42,A_OV_STATUS:"less than 70%",A_PG:37.42,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:11,PB1_OV_COM:0,PB1_OV_REM:11,ACT_TAG_VALID:211,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:8},
    {SUBSYSTEM:"21-6070-6072-06",SUBSYSTEM_DESCR:"LQ Fire and Gas Addressable Loop-6",WD_PLAN:"2021-11-25",A_OV_TOT:319,A_OV_COM:51,A_OV_REM:268,A_OV_PROG:15.98,A_OV_STATUS:"less than 70%",A_PG:15.98,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:4,PB1_OV_COM:0,PB1_OV_REM:4,ACT_TAG_VALID:218,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6070-6072-07",SUBSYSTEM_DESCR:"LQ Fire and Gas Addressable Loop-7",A_OV_STATUS:"less than 70%",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"NA",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6070-6072-08",SUBSYSTEM_DESCR:"Fire Addressable Panels - Machinery Space ,HER and Machinery Space Fire and Gas Addressable Loop-1",WD_PLAN:"2021-10-07",A_OV_TOT:279,A_OV_COM:203,A_OV_REM:76,A_OV_PROG:72.75,A_OV_STATUS:"NA",A_PG:72.75,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,PB1_OV_TOT:32,PB1_OV_COM:0,PB1_OV_REM:32,ACT_TAG_VALID:196,PUNCH_NUMBER_VALID:34,PUNCH_TAG_VALID:15},
    {SUBSYSTEM:"21-6070-6072-09",SUBSYSTEM_DESCR:"Machinery Space Fire and Gas  Addressable Loop-2",WD_PLAN:"2021-12-09",A_OV_TOT:261,A_OV_COM:207,A_OV_REM:54,A_OV_PROG:79.31,A_OV_STATUS:"NA",A_PG:79.31,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:3,PA_OV_COM:1,PA_OV_REM:2,PB1_OV_TOT:27,PB1_OV_COM:0,PB1_OV_REM:27,ACT_TAG_VALID:181,PUNCH_NUMBER_VALID:30,PUNCH_TAG_VALID:11},
    {SUBSYSTEM:"21-6070-6072-10",SUBSYSTEM_DESCR:"Machinery Space Fire and Gas  Addressable Loop-3",WD_PLAN:"2021-12-09",A_OV_TOT:66,A_OV_COM:41,A_OV_REM:25,A_OV_PROG:62.12,A_OV_STATUS:"less than 70%",A_PG:62.12,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:5,PB1_OV_COM:0,PB1_OV_REM:5,ACT_TAG_VALID:44,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6070-6072-11",SUBSYSTEM_DESCR:"Fire Addressable Panels  and - Addressable Fire and Gas Detectors - Topsides ME05",WD_PLAN:"2021-10-28",A_OV_TOT:304,A_OV_COM:0,A_OV_REM:304,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:209,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6070-6073-01",SUBSYSTEM_DESCR:"HSSD - Machinery Space ,HER",WD_PLAN:"2021-10-07",A_OV_TOT:8,A_OV_COM:0,A_OV_REM:8,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6070-6073-02",SUBSYSTEM_DESCR:"HSSD - Machinery Space ,L3 Electrical room",WD_PLAN:"2022-01-13",A_OV_TOT:16,A_OV_COM:0,A_OV_REM:16,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6070-6073-03",SUBSYSTEM_DESCR:"HSSD - LQ ,HULL Level Electrical rooms",WD_PLAN:"2021-12-23",A_OV_TOT:8,A_OV_COM:0,A_OV_REM:8,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6070-6073-04",SUBSYSTEM_DESCR:"HSSD - LQ ,CCR",WD_PLAN:"2021-10-28",A_OV_TOT:4,A_OV_COM:0,A_OV_REM:4,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6070-6073-05",SUBSYSTEM_DESCR:"HSSD - LQ ,CER",WD_PLAN:"2021-10-28",A_OV_TOT:12,A_OV_COM:0,A_OV_REM:12,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6070-6073-06",SUBSYSTEM_DESCR:"HSSD - LQ ,L5 telecom and UPS rooms",WD_PLAN:"2021-10-28",A_OV_TOT:8,A_OV_COM:0,A_OV_REM:8,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6070-6073-07",SUBSYSTEM_DESCR:"HSSD - Topsides ,ME05 and LER",WD_PLAN:"2021-11-04",A_OV_TOT:36,A_OV_COM:0,A_OV_REM:36,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:27,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6070-6074-01",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables ME01",WD_PLAN:"2021-11-04",A_OV_TOT:22,A_OV_COM:0,A_OV_REM:22,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6070-6074-02",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables MW01",WD_PLAN:"2021-11-11",A_OV_TOT:22,A_OV_COM:0,A_OV_REM:22,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6070-6074-03",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables ME02",WD_PLAN:"2021-11-11",A_OV_TOT:18,A_OV_COM:0,A_OV_REM:18,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6070-6074-04",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables MW02",WD_PLAN:"2021-11-11",A_OV_TOT:27,A_OV_COM:0,A_OV_REM:27,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:23,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6070-6074-05",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables ME04",WD_PLAN:"2021-11-25",A_OV_TOT:12,A_OV_COM:0,A_OV_REM:12,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6070-6074-06",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables MW04",WD_PLAN:"2021-11-18",A_OV_TOT:16,A_OV_COM:0,A_OV_REM:16,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:13,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6070-6074-07",SUBSYSTEM_DESCR:"FGS Remote IOs ,incl Junction Box and Multicore Cables MW05",WD_PLAN:"2021-12-16",A_OV_TOT:12,A_OV_COM:0,A_OV_REM:12,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6100-6110-01",SUBSYSTEM_DESCR:"Topsides Fire Water Ring Main, Hydrant and Jockey Pumps",WD_PLAN:"2022-02-03",A_OV_TOT:331,A_OV_COM:13,A_OV_REM:318,A_OV_PROG:3.92,A_OV_STATUS:"less than 70%",A_PG:3.92,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:1,PA_OV_REM:2,ACT_TAG_VALID:75,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6100-6110-02",SUBSYSTEM_DESCR:"Accommodation Firewater and Hydrants",WD_PLAN:"2021-12-16",A_OV_TOT:80,A_OV_COM:9,A_OV_REM:71,A_OV_PROG:11.25,A_OV_STATUS:"less than 70%",A_PG:11.25,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6100-6110-03",SUBSYSTEM_DESCR:"HULL Fire Water Ring Main and Hydrants",WD_PLAN:"2022-01-06",A_OV_TOT:31,A_OV_COM:0,A_OV_REM:31,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6100-6111-01",SUBSYSTEM_DESCR:"Fire Water Pump A ,21-SPF-611101",WD_PLAN:"2022-03-17",A_OV_TOT:149,A_OV_COM:16,A_OV_REM:133,A_OV_PROG:10.73,A_OV_STATUS:"less than 70%",A_PG:10.73,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,ACT_TAG_VALID:77,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6100-6111-02",SUBSYSTEM_DESCR:"Fire Water Pump B ,21-SPF-611201",WD_PLAN:"2022-03-17",A_OV_TOT:150,A_OV_COM:21,A_OV_REM:129,A_OV_PROG:14,A_OV_STATUS:"less than 70%",A_PG:14,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:78,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6100-6111-03",SUBSYSTEM_DESCR:"Fire Water Pump C ,21-SPF-611301",WD_PLAN:"2022-03-17",A_OV_TOT:152,A_OV_COM:8,A_OV_REM:144,A_OV_PROG:5.26,A_OV_STATUS:"less than 70%",A_PG:5.26,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:0,PA_OV_REM:3,ACT_TAG_VALID:70,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6100-6121-01",SUBSYSTEM_DESCR:"HULL Water Mist System",WD_PLAN:"2021-10-28",A_OV_TOT:101,A_OV_COM:19,A_OV_REM:82,A_OV_PROG:18.81,A_OV_STATUS:"less than 70%",A_PG:18.81,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:9,PA_OV_COM:5,PA_OV_REM:4,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:60,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6100-6140-01",SUBSYSTEM_DESCR:"Foam Package",WD_PLAN:"2021-11-11",A_OV_TOT:76,A_OV_COM:28,A_OV_REM:48,A_OV_PROG:36.84,A_OV_STATUS:"less than 70%",A_PG:36.84,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:11,PA_OV_COM:10,PA_OV_REM:1,ACT_TAG_VALID:35,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6100-6140-02",SUBSYSTEM_DESCR:"Foam Ring Main",WD_PLAN:"2022-02-24",A_OV_TOT:78,A_OV_COM:50,A_OV_REM:28,A_OV_PROG:64.1,A_OV_STATUS:"less than 70%",A_PG:64.1,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:56,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6100-6140-03",SUBSYSTEM_DESCR:"Helideck Foam Package",WD_PLAN:"2022-02-24",A_OV_TOT:28,A_OV_COM:0,A_OV_REM:28,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6100-6150-01",SUBSYSTEM_DESCR:"Topside Modules Deluged Systems",WD_PLAN:"2022-02-24",A_OV_TOT:346,A_OV_COM:29,A_OV_REM:317,A_OV_PROG:8.38,A_OV_STATUS:"less than 70%",A_PG:8.38,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P3",PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,ACT_TAG_VALID:102,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-6100-6150-02",SUBSYSTEM_DESCR:"Topside Modules water deluged systems",A_OV_STATUS:"less than 70%",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"NA",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6100-6150-03",SUBSYSTEM_DESCR:"HULL Deck Deluged Systems",WD_PLAN:"2022-02-24",A_OV_TOT:425,A_OV_COM:59,A_OV_REM:366,A_OV_PROG:13.88,A_OV_STATUS:"less than 70%",A_PG:13.88,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P3",PB1_OV_TOT:15,PB1_OV_COM:0,PB1_OV_REM:15,ACT_TAG_VALID:118,PUNCH_NUMBER_VALID:15,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-6170-6170-01",SUBSYSTEM_DESCR:"FF Portable Equipment ,extinguishers, etc … - HULL",WD_PLAN:"2022-04-14",A_OV_TOT:4,A_OV_COM:0,A_OV_REM:4,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6170-6170-02",SUBSYSTEM_DESCR:"FF Portable Equipment ,extinguishers, etc … - LQ",WD_PLAN:"2022-03-24",A_OV_TOT:8,A_OV_COM:0,A_OV_REM:8,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6170-6170-03",SUBSYSTEM_DESCR:"FF Portable Equipment ,extinguishers, etc … - Topsides",WD_PLAN:"2022-07-07",A_OV_TOT:17,A_OV_COM:0,A_OV_REM:17,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6200-6210-01",SUBSYSTEM_DESCR:"PAGA System Cabinets and Central Equipment",WD_PLAN:"2021-11-18",A_OV_TOT:51,A_OV_COM:3,A_OV_REM:48,A_OV_PROG:5.88,A_OV_STATUS:"less than 70%",A_PG:5.88,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:4,PB1_OV_COM:0,PB1_OV_REM:4,ACT_TAG_VALID:49,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6200-6210-02",SUBSYSTEM_DESCR:"PAGA Field Equipment ME05andMW05",WD_PLAN:"2022-04-07",A_OV_TOT:731,A_OV_COM:0,A_OV_REM:731,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:498,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6200-6210-03",SUBSYSTEM_DESCR:"PAGA Field Equipment ME02andMW02",WD_PLAN:"2022-05-05",A_OV_TOT:764,A_OV_COM:0,A_OV_REM:764,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:520,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6200-6210-04",SUBSYSTEM_DESCR:"PAGA Field Equipment ME04andMW04",WD_PLAN:"2022-03-31",A_OV_TOT:366,A_OV_COM:0,A_OV_REM:366,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:252,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6200-6210-05",SUBSYSTEM_DESCR:"PAGA Field Equipment ME01andMW01",WD_PLAN:"2022-05-05",A_OV_TOT:972,A_OV_COM:0,A_OV_REM:972,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:660,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6200-6210-06",SUBSYSTEM_DESCR:"PAGA Field Equipment Topsides HULL Deck",WD_PLAN:"2022-05-19",A_OV_TOT:772,A_OV_COM:1,A_OV_REM:771,A_OV_PROG:0.12,A_OV_STATUS:"less than 70%",A_PG:0.12,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:522,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6200-6210-07",SUBSYSTEM_DESCR:"PAGA Field Equipment LQ HULL Deck A",WD_PLAN:"2022-05-12",A_OV_TOT:594,A_OV_COM:43,A_OV_REM:551,A_OV_PROG:7.23,A_OV_STATUS:"less than 70%",A_PG:7.23,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:4,PA_OV_COM:0,PA_OV_REM:4,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,ACT_TAG_VALID:398,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-6200-6210-08",SUBSYSTEM_DESCR:"PAGA Field Equipment LQ Levels 1 and 2",WD_PLAN:"2022-02-03",A_OV_TOT:624,A_OV_COM:147,A_OV_REM:477,A_OV_PROG:23.55,A_OV_STATUS:"less than 70%",A_PG:23.55,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:3,PA_OV_COM:1,PA_OV_REM:2,PB1_OV_TOT:8,PB1_OV_COM:0,PB1_OV_REM:8,ACT_TAG_VALID:420,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:9},
    {SUBSYSTEM:"21-6200-6210-09",SUBSYSTEM_DESCR:"PAGA Field Equipment LQ Levels 3 and 4",WD_PLAN:"2022-01-13",A_OV_TOT:1332,A_OV_COM:1007,A_OV_REM:325,A_OV_PROG:75.6,A_OV_STATUS:"NA",A_PG:75.6,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:5,PA_OV_COM:1,PA_OV_REM:4,PB1_OV_TOT:26,PB1_OV_COM:0,PB1_OV_REM:26,ACT_TAG_VALID:892,PUNCH_NUMBER_VALID:31,PUNCH_TAG_VALID:25},
    {SUBSYSTEM:"21-6200-6210-10",SUBSYSTEM_DESCR:"PAGA Field Equipment LQ Level 5 and LQ Roof",WD_PLAN:"2022-01-13",A_OV_TOT:368,A_OV_COM:33,A_OV_REM:335,A_OV_PROG:8.96,A_OV_STATUS:"less than 70%",A_PG:8.96,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,ACT_TAG_VALID:248,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6200-6210-11",SUBSYSTEM_DESCR:"PAGA Field Equipment Machinery Space 1st and 2nd Levels",WD_PLAN:"2021-12-23",A_OV_TOT:370,A_OV_COM:291,A_OV_REM:79,A_OV_PROG:78.64,A_OV_STATUS:"NA",A_PG:78.64,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:12,PB1_OV_COM:0,PB1_OV_REM:12,ACT_TAG_VALID:248,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-6200-6210-12",SUBSYSTEM_DESCR:"PAGA Field Equipment Machinery Space 3rd Level",WD_PLAN:"2021-12-23",A_OV_TOT:250,A_OV_COM:196,A_OV_REM:54,A_OV_PROG:78.4,A_OV_STATUS:"NA",A_PG:78.4,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:10,PB1_OV_COM:0,PB1_OV_REM:10,ACT_TAG_VALID:168,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:8},
    {SUBSYSTEM:"21-6200-6224-01",SUBSYSTEM_DESCR:"Digital UHF Network",WD_PLAN:"2021-12-30",A_OV_TOT:237,A_OV_COM:4,A_OV_REM:233,A_OV_PROG:1.68,A_OV_STATUS:"less than 70%",A_PG:1.68,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:237,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6200-6225-01",SUBSYSTEM_DESCR:"VHF ,AM Aeronautical Radio",WD_PLAN:"2021-12-16",A_OV_TOT:22,A_OV_COM:2,A_OV_REM:20,A_OV_PROG:9.09,A_OV_STATUS:"less than 70%",A_PG:9.09,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6200-6226-01",SUBSYSTEM_DESCR:"Non-Directional Beacon ,NDB",WD_PLAN:"2021-12-16",A_OV_TOT:21,A_OV_COM:0,A_OV_REM:21,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6200-6227-01",SUBSYSTEM_DESCR:"Crane Radio System",WD_PLAN:"2022-04-07",A_OV_TOT:44,A_OV_COM:0,A_OV_REM:44,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:44,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6200-6228-01",SUBSYSTEM_DESCR:"VHF ,FM Marine Radio",WD_PLAN:"2021-12-16",A_OV_TOT:20,A_OV_COM:0,A_OV_REM:20,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6300-6320-01",SUBSYSTEM_DESCR:"Lifeboats",WD_PLAN:"2022-03-24",A_OV_TOT:63,A_OV_COM:5,A_OV_REM:58,A_OV_PROG:7.93,A_OV_STATUS:"less than 70%",A_PG:7.93,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:45,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6300-6330-01",SUBSYSTEM_DESCR:"Escape Chutes",WD_PLAN:"2022-04-07",A_OV_TOT:6,A_OV_COM:0,A_OV_REM:6,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6400-6450-01",SUBSYSTEM_DESCR:"Life Saving Portable Equipment ,Live Rafts ,davit type, BA sets, Buoys, Survival Suits, etc … - HULL",WD_PLAN:"2022-05-19",A_OV_TOT:44,A_OV_COM:0,A_OV_REM:44,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6400-6450-02",SUBSYSTEM_DESCR:"Life Saving Portable Equipment ,Live Rafts ,davit type, BA sets, Buoys, Survival Suits, etc … - LQ",WD_PLAN:"2022-04-07",A_OV_TOT:8,A_OV_COM:0,A_OV_REM:8,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6400-6450-03",SUBSYSTEM_DESCR:"Life Saving Portable Equipment ,Live Rafts ,davit type, BA sets, Buoys, Survival Suits, etc … - Topsides",WD_PLAN:"2022-07-21",A_OV_TOT:4,A_OV_COM:0,A_OV_REM:4,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6500-6510-01",SUBSYSTEM_DESCR:"Navigation Lights Control Panel and Associated Lanterns andFoghorns Circuits",WD_PLAN:"2021-10-28",A_OV_TOT:74,A_OV_COM:0,A_OV_REM:74,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:11,PA_OV_COM:9,PA_OV_REM:2,ACT_TAG_VALID:54,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-6500-6520-02",SUBSYSTEM_DESCR:"Helideck Lights Control Panel and Associated Beacons",WD_PLAN:"2021-10-28",A_OV_TOT:218,A_OV_COM:52,A_OV_REM:166,A_OV_PROG:23.85,A_OV_STATUS:"less than 70%",A_PG:23.85,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:14,PA_OV_COM:13,PA_OV_REM:1,ACT_TAG_VALID:153,PUNCH_NUMBER_VALID:14,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6500-6550-01",SUBSYSTEM_DESCR:"RADAR Vessel Early Warning System",WD_PLAN:"2021-12-02",A_OV_TOT:58,A_OV_COM:2,A_OV_REM:56,A_OV_PROG:3.44,A_OV_STATUS:"less than 70%",A_PG:3.44,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:58,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6500-6556-01",SUBSYSTEM_DESCR:"Automatic Identification System",WD_PLAN:"2021-12-23",A_OV_TOT:4,A_OV_COM:0,A_OV_REM:4,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6600-6610-01",SUBSYSTEM_DESCR:"Emergency Generator 21-G-661001",BATCH:"Priority Batch",WD_PLAN:"2021-10-21",A_OV_TOT:165,A_OV_COM:18,A_OV_REM:147,A_OV_PROG:10.9,A_OV_STATUS:"less than 70%",A_PG:10.9,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:5,PA_OV_COM:1,PA_OV_REM:4,PB1_OV_TOT:19,PB1_OV_COM:1,PB1_OV_REM:18,ACT_TAG_VALID:132,PUNCH_NUMBER_VALID:24,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-6600-6624-01",SUBSYSTEM_DESCR:"LQ Emergency Lighting Panel and Circuits ,Room: LH91 21-ELP-662460",WD_PLAN:"2021-09-16",A_OV_TOT:151,A_OV_COM:0,A_OV_REM:151,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:128,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6600-6624-02",SUBSYSTEM_DESCR:"LQ Emergency Lighting Panel and Circuits ,Room: L224 21-ELP-662461",WD_PLAN:"2021-09-09",A_OV_TOT:103,A_OV_COM:4,A_OV_REM:99,A_OV_PROG:3.88,A_OV_STATUS:"less than 70%",A_PG:3.88,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:6,PB1_OV_COM:1,PB1_OV_REM:5,ACT_TAG_VALID:82,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6600-6624-03",SUBSYSTEM_DESCR:"LQ Emergency Lighting Panel and Circuits ,Room: L320 21-ELP-662462",WD_PLAN:"2021-09-09",A_OV_TOT:77,A_OV_COM:17,A_OV_REM:60,A_OV_PROG:22.07,A_OV_STATUS:"less than 70%",A_PG:22.07,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:2,PA_OV_REM:1,PB1_OV_TOT:4,PB1_OV_COM:1,PB1_OV_REM:3,ACT_TAG_VALID:60,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6600-6624-04",SUBSYSTEM_DESCR:"LQ Emergency Lighting Panel and Circuits ,Room: L501 21-ELP-662463",WD_PLAN:"2021-10-28",A_OV_TOT:157,A_OV_COM:13,A_OV_REM:144,A_OV_PROG:8.28,A_OV_STATUS:"less than 70%",A_PG:8.28,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:132,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6600-6624-05",SUBSYSTEM_DESCR:"HULL Emergency Lighting Panel and Circuits ,Room: M104 21-ELP-662401",WD_PLAN:"2021-09-09",A_OV_TOT:68,A_OV_COM:36,A_OV_REM:32,A_OV_PROG:52.94,A_OV_STATUS:"less than 70%",A_PG:52.94,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:7,PA_OV_COM:7,PA_OV_REM:0,PB1_OV_TOT:48,PB1_OV_COM:1,PB1_OV_REM:47,ACT_TAG_VALID:39,PUNCH_NUMBER_VALID:55,PUNCH_TAG_VALID:19},
    {SUBSYSTEM:"21-6600-6640-01",SUBSYSTEM_DESCR:"LQandHULL Emergency Lighting and small power 400V board 21-EDB-662401 ,in LQ",WD_PLAN:"2021-09-09",A_OV_TOT:41,A_OV_COM:41,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-11-09",WD_ACTUAL:"2021-11-11",PL_RECEIVED:"2021-11-11",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:15,PA_OV_COM:12,PA_OV_REM:3,PB1_OV_TOT:8,PB1_OV_COM:6,PB1_OV_REM:2,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:41,PUNCH_NUMBER_VALID:24,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6600-6640-02",SUBSYSTEM_DESCR:"Topsides Emergency Lighting and small power 400V board 21-EDB-662402 ,in LER",WD_PLAN:"2021-10-14",A_OV_TOT:2691,A_OV_COM:13,A_OV_REM:2678,A_OV_PROG:0.48,A_OV_STATUS:"less than 70%",A_PG:0.48,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:2635,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6600-6640-03",SUBSYSTEM_DESCR:"Sub Distribution Auxiliary Panel 01 ,ROOM: LH16 21-EDB-667671",WD_PLAN:"2021-09-09",A_OV_TOT:5,A_OV_COM:0,A_OV_REM:5,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:2,PA_OV_REM:1,PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6600-6640-04",SUBSYSTEM_DESCR:"Sub Distribution Auxiliary Panel 03 ,ROOM: M10621-EDB-662433",WD_PLAN:"2021-09-16",A_OV_TOT:3,A_OV_COM:3,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:5,PA_OV_COM:4,PA_OV_REM:1,PB1_OV_TOT:6,PB1_OV_COM:3,PB1_OV_REM:3,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6600-6640-05",SUBSYSTEM_DESCR:"Panel Control For External Lighting - 21-ECP-667680",WD_PLAN:"2021-09-16",A_OV_TOT:10,A_OV_COM:1,A_OV_REM:9,A_OV_PROG:10,A_OV_STATUS:"less than 70%",A_PG:10,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6600-6660-01",SUBSYSTEM_DESCR:"Emergency 690 V Switchboard 21-ESB-666001",BATCH:"Priority Batch",WD_PLAN:"2021-09-16",A_OV_TOT:237,A_OV_COM:219,A_OV_REM:18,A_OV_PROG:92.4,A_OV_STATUS:"greater than 90%",A_PG:92.4,RFI_ISSUED:"2021-10-07",WD_ACTUAL:"2021-10-08",PL_RECEIVED:"2021-10-08",MC_SUBMITTED:"2021-10-27",MC_APPROVED:"2021-10-30",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B2",PRIORITY:"P1A",PA_OV_TOT:10,PA_OV_COM:10,PA_OV_REM:0,PB1_OV_TOT:7,PB1_OV_COM:7,PB1_OV_REM:0,PB2_OV_TOT:37,PB2_OV_COM:4,PB2_OV_REM:33,ACT_TAG_VALID:237,PUNCH_NUMBER_VALID:54,PUNCH_TAG_VALID:16},
    {SUBSYSTEM:"21-6600-6670-01",SUBSYSTEM_DESCR:"Key service Generator 21-G-667001",WD_PLAN:"2021-11-04",A_OV_TOT:166,A_OV_COM:69,A_OV_REM:97,A_OV_PROG:41.56,A_OV_STATUS:"less than 70%",A_PG:41.56,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1A",PA_OV_TOT:35,PA_OV_COM:18,PA_OV_REM:17,PB1_OV_TOT:15,PB1_OV_COM:1,PB1_OV_REM:14,ACT_TAG_VALID:123,PUNCH_NUMBER_VALID:50,PUNCH_TAG_VALID:10},
    {SUBSYSTEM:"21-6600-6670-02",SUBSYSTEM_DESCR:"Key service Generator 21-G-667002",WD_PLAN:"2021-11-04",A_OV_TOT:162,A_OV_COM:64,A_OV_REM:98,A_OV_PROG:39.5,A_OV_STATUS:"less than 70%",A_PG:39.5,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1A",PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,PB1_OV_TOT:11,PB1_OV_COM:0,PB1_OV_REM:11,ACT_TAG_VALID:119,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-6600-6673-01",SUBSYSTEM_DESCR:"Essential 6.6 kV Switchboard 21-ESB-667301",WD_PLAN:"2021-09-30",A_OV_TOT:292,A_OV_COM:189,A_OV_REM:103,A_OV_PROG:64.72,A_OV_STATUS:"less than 70%",A_PG:64.72,RFI_ISSUED:"2021-11-06",WD_ACTUAL:"2021-11-09",PL_RECEIVED:"2021-11-09",MC_SUBMITTED:"2021-11-18",MC_APPROVED:"2021-11-20",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:11,PA_OV_COM:11,PA_OV_REM:0,PB1_OV_TOT:41,PB1_OV_COM:5,PB1_OV_REM:36,ACT_TAG_VALID:292,PUNCH_NUMBER_VALID:52,PUNCH_TAG_VALID:16},
    {SUBSYSTEM:"21-6600-6674-01",SUBSYSTEM_DESCR:"HULL Normal and Essential 690 V Swtchboard 21-ESB-667401",BATCH:"Priority Batch",WD_PLAN:"2021-09-09",A_OV_TOT:323,A_OV_COM:315,A_OV_REM:8,A_OV_PROG:97.52,A_OV_STATUS:"greater than 95%",A_PG:97.52,RFI_ISSUED:"2021-08-18",WD_ACTUAL:"2021-08-19",PL_RECEIVED:"2021-08-19",MC_SUBMITTED:"2021-09-29",MC_APPROVED:"2021-09-29",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:9,PA_OV_COM:9,PA_OV_REM:0,PB1_OV_TOT:31,PB1_OV_COM:8,PB1_OV_REM:23,PB2_OV_TOT:12,PB2_OV_COM:11,PB2_OV_REM:1,ACT_TAG_VALID:323,PUNCH_NUMBER_VALID:52,PUNCH_TAG_VALID:8},
    {SUBSYSTEM:"21-6600-6674-02",SUBSYSTEM_DESCR:"Topside Essential 690 V Switchboard 21-ESB-667402",WD_PLAN:"2021-10-14",A_OV_TOT:233,A_OV_COM:133,A_OV_REM:100,A_OV_PROG:57.08,A_OV_STATUS:"less than 70%",A_PG:57.08,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:22,PB1_OV_COM:3,PB1_OV_REM:19,ACT_TAG_VALID:233,PUNCH_NUMBER_VALID:22,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6600-6675-01",SUBSYSTEM_DESCR:"HULL Normal and Essential 400 V Switchboard 21-ESB-667601",BATCH:"Priority Batch",WD_PLAN:"2021-09-09",A_OV_TOT:57,A_OV_COM:54,A_OV_REM:3,A_OV_PROG:94.73,A_OV_STATUS:"greater than 90%",A_PG:94.73,RFI_ISSUED:"2021-08-18",WD_ACTUAL:"2021-08-19",PL_RECEIVED:"2021-08-19",MC_SUBMITTED:"2021-09-30",MC_APPROVED:"2021-10-20",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:8,PA_OV_COM:8,PA_OV_REM:0,PB1_OV_TOT:22,PB1_OV_COM:6,PB1_OV_REM:16,PB2_OV_TOT:7,PB2_OV_COM:7,PB2_OV_REM:0,ACT_TAG_VALID:57,PUNCH_NUMBER_VALID:37,PUNCH_TAG_VALID:7},
    {SUBSYSTEM:"21-6600-6675-02",SUBSYSTEM_DESCR:"Topsides Normal and Essential lighting and Small Power 400V 21-EDB-667602",WD_PLAN:"2021-10-14",A_OV_TOT:184,A_OV_COM:0,A_OV_REM:184,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:2,PB1_OV_COM:2,PB1_OV_REM:0,ACT_TAG_VALID:137,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6600-6675-03",SUBSYSTEM_DESCR:"Topsides Normal and Essential lighting and Small Power 400V 21-EDB-667603",WD_PLAN:"2021-10-14",A_OV_TOT:2434,A_OV_COM:15,A_OV_REM:2419,A_OV_PROG:0.61,A_OV_STATUS:"less than 70%",A_PG:0.61,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:15,PB1_OV_COM:0,PB1_OV_REM:15,ACT_TAG_VALID:2371,PUNCH_NUMBER_VALID:15,PUNCH_TAG_VALID:9},
    {SUBSYSTEM:"21-6600-6675-04",SUBSYSTEM_DESCR:"Topsides MOV and Convenience Outlets 400V 21-EDB-667604",WD_PLAN:"2021-10-14",A_OV_TOT:219,A_OV_COM:0,A_OV_REM:219,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:193,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6600-6675-05",SUBSYSTEM_DESCR:"Topsides Misc Feeders and Auxiliaries 400V 21-EDB-667606",WD_PLAN:"2021-10-14",A_OV_TOT:27,A_OV_COM:0,A_OV_REM:27,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:27,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6600-6675-06",SUBSYSTEM_DESCR:"Sub Distribution Auxiliary Panel 02 ,ROOM: M303 21-EDB-667635",WD_PLAN:"2021-09-16",A_OV_TOT:3,A_OV_COM:3,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-08-25",WD_ACTUAL:"2021-08-27",PL_RECEIVED:"2021-08-27",MC_SUBMITTED:"2021-10-12",MC_APPROVED:"2021-10-28",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:13,PA_OV_COM:13,PA_OV_REM:0,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6600-6676-01",SUBSYSTEM_DESCR:"Normal Lighting Panel 01 ,ROOM: M103 21-ELP-667630",WD_PLAN:"2021-09-09",A_OV_TOT:52,A_OV_COM:38,A_OV_REM:14,A_OV_PROG:73.07,A_OV_STATUS:"NA",A_PG:73.07,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:39,PB1_OV_COM:1,PB1_OV_REM:38,ACT_TAG_VALID:28,PUNCH_NUMBER_VALID:39,PUNCH_TAG_VALID:16,COMMENTS:"Y"},
    {SUBSYSTEM:"21-6600-6676-02",SUBSYSTEM_DESCR:"Normal Lighting Panel 02 ,ROOM: M303 21-ELP-667631",WD_PLAN:"2021-09-16",A_OV_TOT:50,A_OV_COM:50,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-10-11",WD_ACTUAL:"2021-10-13",PL_RECEIVED:"2021-10-13",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:31,PA_OV_COM:26,PA_OV_REM:5,PB1_OV_TOT:14,PB1_OV_COM:10,PB1_OV_REM:4,ACT_TAG_VALID:28,PUNCH_NUMBER_VALID:45,PUNCH_TAG_VALID:21},
    {SUBSYSTEM:"21-6600-6676-03",SUBSYSTEM_DESCR:"Machinary Workshop Power Panel ,ROOM: M104 21-ELP-667636",WD_PLAN:"2021-09-16",A_OV_TOT:43,A_OV_COM:5,A_OV_REM:38,A_OV_PROG:11.62,A_OV_STATUS:"less than 70%",A_PG:11.62,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:43,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6600-6676-04",SUBSYSTEM_DESCR:"LQ Small Power for LQ Aux Eqt ,ROOM: LH05 21-ELP-667672",WD_PLAN:"2021-09-16",A_OV_TOT:133,A_OV_COM:6,A_OV_REM:127,A_OV_PROG:4.51,A_OV_STATUS:"less than 70%",A_PG:4.51,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:2,PB1_OV_COM:1,PB1_OV_REM:1,ACT_TAG_VALID:133,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6600-6676-05",SUBSYSTEM_DESCR:"LQ Galley Panel 01 ,ROOM: L210 21-ELP-667673",WD_PLAN:"2021-10-07",A_OV_TOT:4,A_OV_COM:4,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-11-03",WD_ACTUAL:"2021-11-04",PL_RECEIVED:"2021-11-04",MC_SUBMITTED:"2021-11-12",MC_REJECTED:"Rejected",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:17,PA_OV_COM:11,PA_OV_REM:6,PB1_OV_TOT:7,PB1_OV_COM:1,PB1_OV_REM:6,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:24,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6600-6676-06",SUBSYSTEM_DESCR:"Normal Lighting Panel 05 ,ROOM: LH05 21-ELP-667660",WD_PLAN:"2021-09-30",A_OV_TOT:73,A_OV_COM:1,A_OV_REM:72,A_OV_PROG:1.36,A_OV_STATUS:"less than 70%",A_PG:1.36,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:58,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6600-6676-07",SUBSYSTEM_DESCR:"Normal Lighting Panel 06 ,ROOM: LA17 21-ELP-667661",WD_PLAN:"2021-10-07",A_OV_TOT:94,A_OV_COM:2,A_OV_REM:92,A_OV_PROG:2.12,A_OV_STATUS:"less than 70%",A_PG:2.12,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PB1_OV_TOT:2,PB1_OV_COM:1,PB1_OV_REM:1,ACT_TAG_VALID:68,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6600-6676-08",SUBSYSTEM_DESCR:"Normal Lighting Panel 07 ,ROOM: L112 21-ELP-667662",WD_PLAN:"2021-09-30",A_OV_TOT:43,A_OV_COM:4,A_OV_REM:39,A_OV_PROG:9.3,A_OV_STATUS:"less than 70%",A_PG:9.3,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:4,PB1_OV_COM:1,PB1_OV_REM:3,ACT_TAG_VALID:23,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6600-6676-09",SUBSYSTEM_DESCR:"Normal Lighting Panel 08 ,ROOM: L225 21-ELP-667663",WD_PLAN:"2021-09-30",A_OV_TOT:60,A_OV_COM:2,A_OV_REM:58,A_OV_PROG:3.33,A_OV_STATUS:"less than 70%",A_PG:3.33,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:46,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6600-6676-10",SUBSYSTEM_DESCR:"Normal Lighting Panel 09 ,ROOM: L345 21-ELP-667664",WD_PLAN:"2021-09-30",A_OV_TOT:159,A_OV_COM:44,A_OV_REM:115,A_OV_PROG:27.67,A_OV_STATUS:"less than 70%",A_PG:27.67,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PB1_OV_TOT:9,PB1_OV_COM:1,PB1_OV_REM:8,ACT_TAG_VALID:81,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-6600-6676-11",SUBSYSTEM_DESCR:"Normal Lighting Panel 10 ,ROOM: L445 21-ELP-667665",WD_PLAN:"2021-09-30",A_OV_TOT:174,A_OV_COM:34,A_OV_REM:140,A_OV_PROG:19.54,A_OV_STATUS:"less than 70%",A_PG:19.54,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:95,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6600-6676-12",SUBSYSTEM_DESCR:"Normal Lighting Panel 11 ,ROOM: L591 21-ELP-667666",WD_PLAN:"2021-09-30",A_OV_TOT:80,A_OV_COM:1,A_OV_REM:79,A_OV_PROG:1.25,A_OV_STATUS:"less than 70%",A_PG:1.25,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:66,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6600-6676-13",SUBSYSTEM_DESCR:"LQ Laundry Panel 01 ,ROOM: L115 21-ELP-667674",WD_PLAN:"2021-10-21",A_OV_TOT:4,A_OV_COM:4,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-08-27",WD_ACTUAL:"2021-08-31",PL_RECEIVED:"2021-08-31",MC_SUBMITTED:"2021-10-12",MC_APPROVED:"2021-10-29",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:7,PA_OV_COM:7,PA_OV_REM:0,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6600-6676-14",SUBSYSTEM_DESCR:"LQ Laboratory Distribution Board ,ROOM: LA04 21-ELP-667675",WD_PLAN:"2021-10-07",A_OV_TOT:4,A_OV_COM:4,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-11-06",WD_ACTUAL:"2021-11-08",PL_RECEIVED:"2021-11-08",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:8,PA_OV_COM:8,PA_OV_REM:0,PB1_OV_TOT:9,PB1_OV_COM:1,PB1_OV_REM:8,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:17,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6600-6676-15",SUBSYSTEM_DESCR:"LQ Laundry Panel 02 ,ROOM: L115 21-ELP-667677",WD_PLAN:"2021-10-21",A_OV_TOT:4,A_OV_COM:4,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-10-07",WD_ACTUAL:"2021-10-08",PL_RECEIVED:"2021-10-08",MC_SUBMITTED:"2021-10-20",MC_APPROVED:"2021-10-28",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6600-6676-16",SUBSYSTEM_DESCR:"LQ Galley Panel 02 ,ROOM: L220 21-ELP-667678",WD_PLAN:"2021-10-14",A_OV_TOT:4,A_OV_COM:4,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"NA",A_PG:100,RFI_ISSUED:"2021-11-03",WD_ACTUAL:"2021-11-04",PL_RECEIVED:"2021-11-04",MC_SUBMITTED:"2021-11-12",MC_REJECTED:"Rejected",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:13,PA_OV_COM:9,PA_OV_REM:4,PB1_OV_TOT:6,PB1_OV_COM:1,PB1_OV_REM:5,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:19,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6625-8725-01",SUBSYSTEM_DESCR:"Normal and Essential Heat Trace 400V Topsides 21-EDB-667605",WD_PLAN:"2021-10-28",A_OV_TOT:4,A_OV_COM:0,A_OV_REM:4,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6700-6701-01",SUBSYSTEM_DESCR:"Main LQ UPS System ,Incl Batteries, Breakers and Subdistribution Board",BATCH:"Priority Batch",WD_PLAN:"2021-09-16",A_OV_TOT:46,A_OV_COM:36,A_OV_REM:10,A_OV_PROG:78.26,A_OV_STATUS:"NA",A_PG:78.26,RFI_ISSUED:"2021-10-07",WD_ACTUAL:"2021-10-08",PL_RECEIVED:"2021-10-08",MC_SUBMITTED:"2021-10-27",MC_APPROVED:"2021-10-30",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P1A",PA_OV_TOT:31,PA_OV_COM:31,PA_OV_REM:0,PB1_OV_TOT:53,PB1_OV_COM:28,PB1_OV_REM:25,PB2_OV_TOT:1,PB2_OV_COM:0,PB2_OV_REM:1,ACT_TAG_VALID:42,PUNCH_NUMBER_VALID:85,PUNCH_TAG_VALID:17},
    {SUBSYSTEM:"21-6700-6701-02",SUBSYSTEM_DESCR:"LQ Telecom UPS System B for HULL and LQ ,Incl Batteries, Breakers and Subdistribution Board",WD_PLAN:"2021-09-16",A_OV_TOT:20,A_OV_COM:15,A_OV_REM:5,A_OV_PROG:75,A_OV_STATUS:"NA",A_PG:75,RFI_ISSUED:"2021-11-08",WD_ACTUAL:"2021-11-09",PL_RECEIVED:"2021-11-09",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:5,PA_OV_COM:5,PA_OV_REM:0,PB1_OV_TOT:23,PB1_OV_COM:13,PB1_OV_REM:10,PB2_OV_TOT:2,PB2_OV_COM:0,PB2_OV_REM:2,ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:30,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-6700-6701-03",SUBSYSTEM_DESCR:"LQ Telecom UPS System A for Topsides ,Incl Batteries, Breakers and Subdistribution Board",WD_PLAN:"2021-11-25",A_OV_TOT:20,A_OV_COM:0,A_OV_REM:20,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PB1_OV_TOT:13,PB1_OV_COM:12,PB1_OV_REM:1,ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:15,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-6700-6701-04",SUBSYSTEM_DESCR:"LQ NavAids UPS System ,Incl Batteries, Breakers and Subdistribution Board",A_OV_STATUS:"less than 70%",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"NA",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6700-6702-01",SUBSYSTEM_DESCR:"Topside Main UPS System ,Incl Batteries, Breakers and Subdistribution Board",WD_PLAN:"2021-10-21",A_OV_TOT:40,A_OV_COM:0,A_OV_REM:40,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:4,PA_OV_COM:0,PA_OV_REM:4,PB1_OV_TOT:8,PB1_OV_COM:6,PB1_OV_REM:2,ACT_TAG_VALID:38,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-6700-6702-02",SUBSYSTEM_DESCR:"Subsea UPS System ,Incl Batteries, Breakers, Voltage Stabilisers and Subdistribution Board",WD_PLAN:"2021-12-30",A_OV_TOT:28,A_OV_COM:0,A_OV_REM:28,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:4,PA_OV_COM:0,PA_OV_REM:4,PB1_OV_TOT:6,PB1_OV_COM:4,PB1_OV_REM:2,ACT_TAG_VALID:26,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-6800-6830-01",SUBSYSTEM_DESCR:"Fire and Gas Detecion - LQ",WD_PLAN:"2021-12-09",A_OV_TOT:296,A_OV_COM:65,A_OV_REM:231,A_OV_PROG:21.95,A_OV_STATUS:"less than 70%",A_PG:21.95,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:148,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6830-02",SUBSYSTEM_DESCR:"Magnetic Door Holder  - LQ",WD_PLAN:"2022-02-03",A_OV_TOT:172,A_OV_COM:2,A_OV_REM:170,A_OV_PROG:1.16,A_OV_STATUS:"less than 70%",A_PG:1.16,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:172,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6800-6840-01",SUBSYSTEM_DESCR:"Fire Zone 6840-1 - Power Generation Module-MW04 ,Open Area",WD_PLAN:"2021-11-18",A_OV_TOT:98,A_OV_COM:10,A_OV_REM:88,A_OV_PROG:10.2,A_OV_STATUS:"less than 70%",A_PG:10.2,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:53,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6840-02",SUBSYSTEM_DESCR:"Fire Zone 6840-2 - Power Generation Module- ME04 ,Open Area",WD_PLAN:"2021-11-18",A_OV_TOT:101,A_OV_COM:8,A_OV_REM:93,A_OV_PROG:7.92,A_OV_STATUS:"less than 70%",A_PG:7.92,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:55,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6840-03",SUBSYSTEM_DESCR:"Fire Zone 6840-3 - Gas Turbine Generator Package 21-Z-861101",WD_PLAN:"2021-11-11",A_OV_TOT:2,A_OV_COM:0,A_OV_REM:2,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6840-04",SUBSYSTEM_DESCR:"Fire Zone 6840-4 - Gas Turbine Generator Package 21-Z-861201",WD_PLAN:"2021-11-11",A_OV_TOT:2,A_OV_COM:0,A_OV_REM:2,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6840-05",SUBSYSTEM_DESCR:"Fire Zone 6840-5 - Gas Turbine Generator Package 21-Z-861301",WD_PLAN:"2021-12-02",A_OV_TOT:2,A_OV_COM:0,A_OV_REM:2,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6840-06",SUBSYSTEM_DESCR:"Fire Zone 6840-6 - Gas Turbine Generator Package 21-Z-861401",WD_PLAN:"2021-12-02",A_OV_TOT:2,A_OV_COM:0,A_OV_REM:2,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6840-07",SUBSYSTEM_DESCR:"Fire Zone 6840-7 - Utility Module–MW05",WD_PLAN:"2021-12-16",A_OV_TOT:138,A_OV_COM:10,A_OV_REM:128,A_OV_PROG:7.24,A_OV_STATUS:"less than 70%",A_PG:7.24,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:77,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6840-08",SUBSYSTEM_DESCR:"Fire Zone 6840-8and9 -Pedestal Cranes ,WestandEast",WD_PLAN:"2022-04-07",A_OV_TOT:44,A_OV_COM:2,A_OV_REM:42,A_OV_PROG:4.54,A_OV_STATUS:"less than 70%",A_PG:4.54,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:28,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6840-09",SUBSYSTEM_DESCR:"Fire Zone 6840-10 - Laydown Area",A_OV_STATUS:"less than 70%",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"NA",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6841-01",SUBSYSTEM_DESCR:"Fire Zone 6841-0 - Reception Facilities and Production Separation Module - MW01",WD_PLAN:"2022-05-19",A_OV_TOT:384,A_OV_COM:41,A_OV_REM:343,A_OV_PROG:10.67,A_OV_STATUS:"less than 70%",A_PG:10.67,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:209,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6841-02",SUBSYSTEM_DESCR:"Fire Zone 6841-1 - Flash Gas Compression Module - ME01",WD_PLAN:"2021-12-09",A_OV_TOT:261,A_OV_COM:31,A_OV_REM:230,A_OV_PROG:11.87,A_OV_STATUS:"less than 70%",A_PG:11.87,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:140,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6841-03",SUBSYSTEM_DESCR:"Fire Zone 6841-2 - Gas Dewpointing andMEG and Methanol injection and Fuel gas Module - MW02",WD_PLAN:"2021-12-09",A_OV_TOT:355,A_OV_COM:47,A_OV_REM:308,A_OV_PROG:13.23,A_OV_STATUS:"less than 70%",A_PG:13.23,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:188,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6841-04",SUBSYSTEM_DESCR:"Fire Zone 6841-3 - Condensate stabilization and MEG regeneration and Produced Water treatment Module  - ME02",WD_PLAN:"2022-06-23",A_OV_TOT:307,A_OV_COM:21,A_OV_REM:286,A_OV_PROG:6.84,A_OV_STATUS:"less than 70%",A_PG:6.84,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:174,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6841-07",SUBSYSTEM_DESCR:"Fire Zone 6841-6 - Aft Platform on Deck A",WD_PLAN:"2022-05-26",A_OV_TOT:59,A_OV_COM:7,A_OV_REM:52,A_OV_PROG:11.86,A_OV_STATUS:"less than 70%",A_PG:11.86,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,ACT_TAG_VALID:30,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6800-6841-08",SUBSYSTEM_DESCR:"Fire Zone 6841-7 - Piperack Between MW01 and ME01",WD_PLAN:"2022-06-23",A_OV_TOT:6,A_OV_COM:0,A_OV_REM:6,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6841-09",SUBSYSTEM_DESCR:"Fire Zone 6841-8 - Piperack Between MW02 and ME02",WD_PLAN:"2022-06-02",A_OV_TOT:3,A_OV_COM:0,A_OV_REM:3,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-01",SUBSYSTEM_DESCR:"Fire Zone 6842-0 - Cargo Tank Top ,Rich Meg, Methanol, Salty Rich MEG 2, Offspec Condensate, Slop, Wax Inhibitor, Produced Water Storage Tanks and Flare Facilities Area, Including Marine Piperack",WD_PLAN:"2022-01-06",A_OV_TOT:185,A_OV_COM:20,A_OV_REM:165,A_OV_PROG:10.81,A_OV_STATUS:"less than 70%",A_PG:10.81,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:97,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-02",SUBSYSTEM_DESCR:"Fire Zone 6842-1 - Cargo Tank Top ,Condensate Tank 1 and 2 and Closed Drain Facilities",WD_PLAN:"2022-01-06",A_OV_TOT:135,A_OV_COM:12,A_OV_REM:123,A_OV_PROG:8.88,A_OV_STATUS:"less than 70%",A_PG:8.88,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:75,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-03",SUBSYSTEM_DESCR:"Fire Zone 6842-2 - Cargo Tank Top ,Salty Rich MEG Tanks 3and4",WD_PLAN:"2022-01-06",A_OV_TOT:143,A_OV_COM:13,A_OV_REM:130,A_OV_PROG:9.09,A_OV_STATUS:"less than 70%",A_PG:9.09,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:80,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-04",SUBSYSTEM_DESCR:"Fire Zone 6842-3 - Cargo Tank Top ,Condensate Tank 3 and 4",WD_PLAN:"2022-01-06",A_OV_TOT:86,A_OV_COM:4,A_OV_REM:82,A_OV_PROG:4.65,A_OV_STATUS:"less than 70%",A_PG:4.65,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:48,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-05",SUBSYSTEM_DESCR:"Fire Zone 6842-4 - Cargo Tank Top ,Condensate Tank 5 and 6",WD_PLAN:"2022-01-06",A_OV_TOT:124,A_OV_COM:9,A_OV_REM:115,A_OV_PROG:7.25,A_OV_STATUS:"less than 70%",A_PG:7.25,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:69,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-06",SUBSYSTEM_DESCR:"Fire Zone 6842-5 - Cargo Tank Top ,Condensate Tank 7 and 8",WD_PLAN:"2022-01-06",A_OV_TOT:159,A_OV_COM:9,A_OV_REM:150,A_OV_PROG:5.66,A_OV_STATUS:"less than 70%",A_PG:5.66,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:88,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-07",SUBSYSTEM_DESCR:"Fire Zone 6842-6 - Cargo Tank Top ,Lean MEG Storage Tanks",WD_PLAN:"2022-01-06",A_OV_TOT:118,A_OV_COM:8,A_OV_REM:110,A_OV_PROG:6.77,A_OV_STATUS:"less than 70%",A_PG:6.77,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:62,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-08",SUBSYSTEM_DESCR:"Fire Zone 6842-7 - Fire Water Pump Enclosure 611101",WD_PLAN:"2022-02-10",A_OV_STATUS:"NA",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-09",SUBSYSTEM_DESCR:"Fire Zone 6842-8 - Fire Water Pump Enclosure 611201",WD_PLAN:"2022-02-10",A_OV_STATUS:"NA",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-10",SUBSYSTEM_DESCR:"Fire Zone 6842-9 - Fire Water Pump Enclosure 611301",WD_PLAN:"2022-02-10",A_OV_STATUS:"NA",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-11",SUBSYSTEM_DESCR:"Fire Zone 6843-0 - West Riser Balcony Area",WD_PLAN:"2022-04-14",A_OV_TOT:25,A_OV_COM:2,A_OV_REM:23,A_OV_PROG:8,A_OV_STATUS:"less than 70%",A_PG:8,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-12",SUBSYSTEM_DESCR:"Fire Zone 6843-1 - East Riser Balcony Area",A_OV_STATUS:"less than 70%",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"NA",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-13",SUBSYSTEM_DESCR:"Fire Zone 6843-3 - Emergency Diesel Generators",WD_PLAN:"2021-10-07",A_OV_STATUS:"NA",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1,COMMENTS:"Y"},
    {SUBSYSTEM:"21-6800-6842-15",SUBSYSTEM_DESCR:"Fire Zone 6843-4-  PaintStore",A_OV_STATUS:"less than 70%",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"NA",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6842-16",SUBSYSTEM_DESCR:"Fire Zone 6850 - General Topsides Area",WD_PLAN:"2022-07-07",A_OV_TOT:35,A_OV_COM:2,A_OV_REM:33,A_OV_PROG:5.71,A_OV_STATUS:"less than 70%",A_PG:5.71,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:23,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6844-01",SUBSYSTEM_DESCR:"Fire and Gas Detecion - Machinery Space",WD_PLAN:"2021-12-30",A_OV_TOT:68,A_OV_COM:0,A_OV_REM:68,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:37,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6850-01",SUBSYSTEM_DESCR:"Fire and Gas Detecion - ME05",WD_PLAN:"2021-10-28",A_OV_TOT:80,A_OV_COM:18,A_OV_REM:62,A_OV_PROG:22.5,A_OV_STATUS:"less than 70%",A_PG:22.5,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:40,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1,COMMENTS:"Y"},
    {SUBSYSTEM:"21-6800-6853-01",SUBSYSTEM_DESCR:"Fire Zone 6853 - Temporary Refuge in Forecastle Area",A_OV_STATUS:"less than 70%",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"NA",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6800-6854-01",SUBSYSTEM_DESCR:"Fire Zone 6854 - Transit Refuge Area",WD_PLAN:"2022-03-24",A_OV_TOT:27,A_OV_COM:6,A_OV_REM:21,A_OV_PROG:22.22,A_OV_STATUS:"less than 70%",A_PG:22.22,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8000-8010-01",SUBSYSTEM_DESCR:"Potable Water Maker and Rehardener",WD_PLAN:"2021-10-21",A_OV_TOT:151,A_OV_COM:37,A_OV_REM:114,A_OV_PROG:24.5,A_OV_STATUS:"less than 70%",A_PG:24.5,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:24,PA_OV_COM:15,PA_OV_REM:9,PB1_OV_TOT:17,PB1_OV_COM:2,PB1_OV_REM:15,ACT_TAG_VALID:86,PUNCH_NUMBER_VALID:41,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-8000-8010-02",SUBSYSTEM_DESCR:"Chlorination Package",WD_PLAN:"2022-01-13",A_OV_TOT:21,A_OV_COM:3,A_OV_REM:18,A_OV_PROG:14.28,A_OV_STATUS:"less than 70%",A_PG:14.28,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8000-8011-01",SUBSYSTEM_DESCR:"Potable Water Tanks and Pumps",WD_PLAN:"2021-10-21",A_OV_TOT:240,A_OV_COM:79,A_OV_REM:161,A_OV_PROG:32.91,A_OV_STATUS:"less than 70%",A_PG:32.91,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:2,PA_OV_REM:1,ACT_TAG_VALID:110,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8000-8011-02",SUBSYSTEM_DESCR:"Fresh Water Bunkering Header",WD_PLAN:"2021-10-21",A_OV_TOT:36,A_OV_COM:11,A_OV_REM:25,A_OV_PROG:30.55,A_OV_STATUS:"less than 70%",A_PG:30.55,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8000-8012-01",SUBSYSTEM_DESCR:"UV Sterilizer",WD_PLAN:"2021-12-09",A_OV_TOT:37,A_OV_COM:8,A_OV_REM:29,A_OV_PROG:21.62,A_OV_STATUS:"less than 70%",A_PG:21.62,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,ACT_TAG_VALID:21,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-8000-8012-02",SUBSYSTEM_DESCR:"Main Distribution and Potable Water Cooler",WD_PLAN:"2021-10-21",A_OV_TOT:83,A_OV_COM:28,A_OV_REM:55,A_OV_PROG:33.73,A_OV_STATUS:"less than 70%",A_PG:33.73,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:6,PA_OV_COM:6,PA_OV_REM:0,ACT_TAG_VALID:41,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8000-8012-03",SUBSYSTEM_DESCR:"Cold Potable Water Distribution to HULL and LQ",WD_PLAN:"2021-12-23",A_OV_TOT:225,A_OV_COM:82,A_OV_REM:143,A_OV_PROG:36.44,A_OV_STATUS:"less than 70%",A_PG:36.44,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:133,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8000-8012-04",SUBSYSTEM_DESCR:"Cold Potable Water Distribution to LQ return to Hull MS",WD_PLAN:"2022-01-06",A_OV_TOT:32,A_OV_COM:9,A_OV_REM:23,A_OV_PROG:28.12,A_OV_STATUS:"less than 70%",A_PG:28.12,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8000-8012-05",SUBSYSTEM_DESCR:"Cold Potable Water Distribution to Topsides ,Safety Showers and Eye Baths",WD_PLAN:"2022-06-09",A_OV_TOT:198,A_OV_COM:108,A_OV_REM:90,A_OV_PROG:54.54,A_OV_STATUS:"less than 70%",A_PG:54.54,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:128,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8000-8013-01",SUBSYSTEM_DESCR:"Hot Water Calorifiers",WD_PLAN:"2022-01-13",A_OV_TOT:80,A_OV_COM:12,A_OV_REM:68,A_OV_PROG:15,A_OV_STATUS:"less than 70%",A_PG:15,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:22,PA_OV_COM:14,PA_OV_REM:8,PB1_OV_TOT:4,PB1_OV_COM:0,PB1_OV_REM:4,ACT_TAG_VALID:27,PUNCH_NUMBER_VALID:26,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-8000-8013-02",SUBSYSTEM_DESCR:"Hot Water Distribution and Recirculation Pumps",WD_PLAN:"2022-01-13",A_OV_TOT:270,A_OV_COM:50,A_OV_REM:220,A_OV_PROG:18.51,A_OV_STATUS:"less than 70%",A_PG:18.51,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,ACT_TAG_VALID:123,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8000-8014-01",SUBSYSTEM_DESCR:"Fresh Service Water Tank and Pumps",WD_PLAN:"2021-10-28",A_OV_TOT:159,A_OV_COM:65,A_OV_REM:94,A_OV_PROG:40.88,A_OV_STATUS:"less than 70%",A_PG:40.88,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,ACT_TAG_VALID:66,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8000-8014-02",SUBSYSTEM_DESCR:"Fresh Service Water Distribution to HULL",WD_PLAN:"2021-12-09",A_OV_TOT:15,A_OV_COM:1,A_OV_REM:14,A_OV_PROG:6.66,A_OV_STATUS:"less than 70%",A_PG:6.66,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8000-8014-03",SUBSYSTEM_DESCR:"Fresh Service Water Distribution to Topsides",WD_PLAN:"2021-12-09",A_OV_TOT:721,A_OV_COM:608,A_OV_REM:113,A_OV_PROG:84.32,A_OV_STATUS:"NA",A_PG:84.32,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:630,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8000-8014-04",SUBSYSTEM_DESCR:"Fresh Service Water Distribution to Water Mist System 21-SWM-613001",WD_PLAN:"2021-10-21",A_OV_TOT:13,A_OV_COM:3,A_OV_REM:10,A_OV_PROG:23.07,A_OV_STATUS:"less than 70%",A_PG:23.07,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8000-8014-05",SUBSYSTEM_DESCR:"Fresh Service Water Distribution to LQ",WD_PLAN:"2021-11-11",A_OV_TOT:75,A_OV_COM:31,A_OV_REM:44,A_OV_PROG:41.33,A_OV_STATUS:"less than 70%",A_PG:41.33,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8020-8020-01",SUBSYSTEM_DESCR:"Marine Sea Water Sea Suction Tube and Filter 21-F-802001",WD_PLAN:"2022-01-13",A_OV_TOT:46,A_OV_COM:12,A_OV_REM:34,A_OV_PROG:26.08,A_OV_STATUS:"less than 70%",A_PG:26.08,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8020-8020-02",SUBSYSTEM_DESCR:"Marine Sea Water Sea Suction Tube and Filter 21-F-802002",WD_PLAN:"2022-01-13",A_OV_TOT:46,A_OV_COM:13,A_OV_REM:33,A_OV_PROG:28.26,A_OV_STATUS:"less than 70%",A_PG:28.26,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-8020-8020-03",SUBSYSTEM_DESCR:"Marine Sea Water Main Distribution Header",WD_PLAN:"2021-10-21",A_OV_TOT:36,A_OV_COM:0,A_OV_REM:36,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1A",ACT_TAG_VALID:21,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8020-8021-01",SUBSYSTEM_DESCR:"IGG Scrubber Cooling Sea Water Pumps - 21-P802003AandB",WD_PLAN:"2021-12-23",A_OV_TOT:77,A_OV_COM:0,A_OV_REM:77,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:38,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8020-8021-02",SUBSYSTEM_DESCR:"IGG Scrubber Cooling Sea Water Distribution",WD_PLAN:"2021-12-23",A_OV_TOT:27,A_OV_COM:0,A_OV_REM:27,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8020-8023-01",SUBSYSTEM_DESCR:"IGG Deck Seal Sea Water Pumps - 21-P802002AandB",WD_PLAN:"2021-12-23",A_OV_TOT:77,A_OV_COM:7,A_OV_REM:70,A_OV_PROG:9.09,A_OV_STATUS:"less than 70%",A_PG:9.09,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:34,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8020-8023-02",SUBSYSTEM_DESCR:"IGG Deck Seal Sea Water Distribution",WD_PLAN:"2021-12-23",A_OV_TOT:13,A_OV_COM:0,A_OV_REM:13,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8020-8024-01",SUBSYSTEM_DESCR:"HULL Sea Water Cooling Pumps - 21-P-802004AandB",WD_PLAN:"2021-10-14",A_OV_TOT:71,A_OV_COM:11,A_OV_REM:60,A_OV_PROG:15.49,A_OV_STATUS:"less than 70%",A_PG:15.49,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:32,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8020-8024-02",SUBSYSTEM_DESCR:"HULL Sea Water Cooling Header and Cooling Water Exchangers - 21-H-805001AandB",WD_PLAN:"2021-10-14",A_OV_TOT:53,A_OV_COM:4,A_OV_REM:49,A_OV_PROG:7.54,A_OV_STATUS:"less than 70%",A_PG:7.54,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:24,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8020-8025-01",SUBSYSTEM_DESCR:"HULL Service Sea Water Pumps - 21-P-802005AandB",WD_PLAN:"2021-11-18",A_OV_TOT:98,A_OV_COM:5,A_OV_REM:93,A_OV_PROG:5.1,A_OV_STATUS:"less than 70%",A_PG:5.1,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:3,PA_OV_COM:1,PA_OV_REM:2,ACT_TAG_VALID:46,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8020-8026-01",SUBSYSTEM_DESCR:"HULL Service Sea Water to HVAC Cooling",WD_PLAN:"2021-10-14",A_OV_TOT:84,A_OV_COM:4,A_OV_REM:80,A_OV_PROG:4.76,A_OV_STATUS:"less than 70%",A_PG:4.76,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:32,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8020-8027-01",SUBSYSTEM_DESCR:"HULL Service Sea Water Booster Pumps - 21-P-802008AandB",WD_PLAN:"2021-10-21",A_OV_TOT:105,A_OV_COM:13,A_OV_REM:92,A_OV_PROG:12.38,A_OV_STATUS:"less than 70%",A_PG:12.38,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",PA_OV_TOT:4,PA_OV_COM:4,PA_OV_REM:0,ACT_TAG_VALID:39,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8020-8027-02",SUBSYSTEM_DESCR:"HULL Service Sea Water to Electrochlorination Package",WD_PLAN:"2022-01-13",A_OV_TOT:12,A_OV_COM:0,A_OV_REM:12,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8020-8027-03",SUBSYSTEM_DESCR:"HULL Service Sea Water to Potable Water Package",WD_PLAN:"2021-10-28",A_OV_TOT:11,A_OV_COM:0,A_OV_REM:11,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8020-8029-01",SUBSYSTEM_DESCR:"KSG Cooling Sea Water Pumps 21-P-802009AandB and Distribution",WD_PLAN:"2021-10-21",A_OV_TOT:97,A_OV_COM:10,A_OV_REM:87,A_OV_PROG:10.3,A_OV_STATUS:"less than 70%",A_PG:10.3,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1A",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:51,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8040-8040-01",SUBSYSTEM_DESCR:"Heating Medium ,Hot Water Expansion Tank",WD_PLAN:"2022-02-10",A_OV_TOT:118,A_OV_COM:13,A_OV_REM:105,A_OV_PROG:11.01,A_OV_STATUS:"less than 70%",A_PG:11.01,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:47,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8040-8040-02",SUBSYSTEM_DESCR:"Heating Medium ,Hot Water Pumps and Loop and Distribution to Exchangers",WD_PLAN:"2022-02-10",A_OV_TOT:814,A_OV_COM:144,A_OV_REM:670,A_OV_PROG:17.69,A_OV_STATUS:"less than 70%",A_PG:17.69,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:5,PA_OV_COM:4,PA_OV_REM:1,ACT_TAG_VALID:344,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-8040-8040-03",SUBSYSTEM_DESCR:"WHRU 1",A_OV_STATUS:"less than 70%",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"NA",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-8040-8040-04",SUBSYSTEM_DESCR:"WHRU 2",A_OV_STATUS:"less than 70%",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"NA",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8040-8040-05",SUBSYSTEM_DESCR:"WHRU 3",A_OV_STATUS:"less than 70%",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"NA",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8040-8040-06",SUBSYSTEM_DESCR:"WHRU 4",A_OV_STATUS:"less than 70%",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"NA",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8050-8051-01",SUBSYSTEM_DESCR:"Marine Cooling Medium Pumps - 21-P805002AandB",WD_PLAN:"2021-10-07",A_OV_TOT:48,A_OV_COM:11,A_OV_REM:37,A_OV_PROG:22.91,A_OV_STATUS:"less than 70%",A_PG:22.91,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,ACT_TAG_VALID:23,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8050-8051-02",SUBSYSTEM_DESCR:"Marine Cooling Medium Expansion Tank",WD_PLAN:"2021-10-07",A_OV_TOT:55,A_OV_COM:24,A_OV_REM:31,A_OV_PROG:43.63,A_OV_STATUS:"less than 70%",A_PG:43.63,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8050-8051-03",SUBSYSTEM_DESCR:"Marine Cooling Medium Distribution",WD_PLAN:"2021-10-07",A_OV_TOT:110,A_OV_COM:37,A_OV_REM:73,A_OV_PROG:33.63,A_OV_STATUS:"less than 70%",A_PG:33.63,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:45,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8050-8052-01",SUBSYSTEM_DESCR:"Topisdes Cooling Medium Pumps - 21-P805001AandB",WD_PLAN:"2022-02-10",A_OV_TOT:147,A_OV_COM:12,A_OV_REM:135,A_OV_PROG:8.16,A_OV_STATUS:"less than 70%",A_PG:8.16,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:9,PA_OV_COM:5,PA_OV_REM:4,PB1_OV_TOT:3,PB1_OV_COM:1,PB1_OV_REM:2,ACT_TAG_VALID:81,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-8050-8052-02",SUBSYSTEM_DESCR:"Start-up Cooling Medium Pumps - 21-P805003",WD_PLAN:"2022-02-10",A_OV_TOT:55,A_OV_COM:5,A_OV_REM:50,A_OV_PROG:9.09,A_OV_STATUS:"less than 70%",A_PG:9.09,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:23,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8050-8052-03",SUBSYSTEM_DESCR:"Topsides Cooling Medium Expansion Tank",WD_PLAN:"2022-02-10",A_OV_TOT:129,A_OV_COM:35,A_OV_REM:94,A_OV_PROG:27.13,A_OV_STATUS:"less than 70%",A_PG:27.13,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:62,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8050-8052-04",SUBSYSTEM_DESCR:"Topsides Cooling Medium Distribution and Exchangers",WD_PLAN:"2022-02-10",A_OV_TOT:617,A_OV_COM:134,A_OV_REM:483,A_OV_PROG:21.71,A_OV_STATUS:"less than 70%",A_PG:21.71,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:262,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8060-8061-01",SUBSYSTEM_DESCR:"Diesel Bunker Header",WD_PLAN:"2021-10-07",A_OV_TOT:63,A_OV_COM:32,A_OV_REM:31,A_OV_PROG:50.79,A_OV_STATUS:"less than 70%",A_PG:50.79,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:40,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8060-8061-02",SUBSYSTEM_DESCR:"Diesel Storage Tanks - 21-T-806001and2 and Purifier Pumps - 21-P-806002AandB",WD_PLAN:"2021-10-14",A_OV_TOT:178,A_OV_COM:42,A_OV_REM:136,A_OV_PROG:23.59,A_OV_STATUS:"less than 70%",A_PG:23.59,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:5,PA_OV_COM:4,PA_OV_REM:1,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:66,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-8060-8061-03",SUBSYSTEM_DESCR:"Diesel Supply and Return ,Spill Back Headers to Service Tanks - 21-T-806003and4",WD_PLAN:"2021-10-14",A_OV_TOT:99,A_OV_COM:29,A_OV_REM:70,A_OV_PROG:29.29,A_OV_STATUS:"less than 70%",A_PG:29.29,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:39,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8060-8062-01",SUBSYSTEM_DESCR:"Diesel Service Tanks - 21-T-806003and4 and Supply Pumps - 21-P-806001AandB",WD_PLAN:"2021-10-21",A_OV_TOT:257,A_OV_COM:91,A_OV_REM:166,A_OV_PROG:35.4,A_OV_STATUS:"less than 70%",A_PG:35.4,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,ACT_TAG_VALID:114,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-8060-8062-02",SUBSYSTEM_DESCR:"Purifier Package",WD_PLAN:"2021-10-07",A_OV_TOT:32,A_OV_COM:10,A_OV_REM:22,A_OV_PROG:31.25,A_OV_STATUS:"less than 70%",A_PG:31.25,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:17,PA_OV_COM:15,PA_OV_REM:2,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8060-8063-01",SUBSYSTEM_DESCR:"Diesel Distribution Header to Users",WD_PLAN:"2022-02-10",A_OV_TOT:164,A_OV_COM:20,A_OV_REM:144,A_OV_PROG:12.19,A_OV_STATUS:"less than 70%",A_PG:12.19,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:46,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8090-8090-01",SUBSYSTEM_DESCR:"Sewage Treatment Package",WD_PLAN:"2021-12-09",A_OV_TOT:21,A_OV_COM:3,A_OV_REM:18,A_OV_PROG:14.28,A_OV_STATUS:"less than 70%",A_PG:14.28,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:16,PA_OV_COM:11,PA_OV_REM:5,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:19,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8090-8090-02",SUBSYSTEM_DESCR:"Sewage Collection and Discharge Headers",WD_PLAN:"2021-12-09",A_OV_TOT:396,A_OV_COM:93,A_OV_REM:303,A_OV_PROG:23.48,A_OV_STATUS:"less than 70%",A_PG:23.48,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:106,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8100-8110-01",SUBSYSTEM_DESCR:"HULL Plant Air Distribution",WD_PLAN:"2021-12-30",A_OV_TOT:431,A_OV_COM:371,A_OV_REM:60,A_OV_PROG:86.07,A_OV_STATUS:"NA",A_PG:86.07,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:355,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8100-8111-01",SUBSYSTEM_DESCR:"Topsides Plant Air Distribution",WD_PLAN:"2022-07-14",A_OV_TOT:112,A_OV_COM:21,A_OV_REM:91,A_OV_PROG:18.75,A_OV_STATUS:"less than 70%",A_PG:18.75,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:37,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8100-8120-01",SUBSYSTEM_DESCR:"HULL Air Compressor 21-Z-812010",BATCH:"Priority Batch",WD_PLAN:"2021-10-07",A_OV_TOT:55,A_OV_COM:29,A_OV_REM:26,A_OV_PROG:52.72,A_OV_STATUS:"less than 70%",A_PG:52.72,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:9,PA_OV_COM:6,PA_OV_REM:3,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,ACT_TAG_VALID:35,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:7},
    {SUBSYSTEM:"21-8100-8120-02",SUBSYSTEM_DESCR:"HULL Air Drier 21-Z-812020",WD_PLAN:"2021-10-21",A_OV_TOT:25,A_OV_COM:15,A_OV_REM:10,A_OV_PROG:60,A_OV_STATUS:"less than 70%",A_PG:60,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8100-8120-03",SUBSYSTEM_DESCR:"HULL Air Receiver 21-V-812002, Main header and Controls",WD_PLAN:"2021-10-07",A_OV_TOT:71,A_OV_COM:40,A_OV_REM:31,A_OV_PROG:56.33,A_OV_STATUS:"less than 70%",A_PG:56.33,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1A",ACT_TAG_VALID:27,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8100-8121-01",SUBSYSTEM_DESCR:"HULL Instrument Air Distribution",WD_PLAN:"2021-10-14",A_OV_TOT:582,A_OV_COM:203,A_OV_REM:379,A_OV_PROG:34.87,A_OV_STATUS:"less than 70%",A_PG:34.87,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1A",ACT_TAG_VALID:302,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8100-8122-01",SUBSYSTEM_DESCR:"Topside Air Compressor 21-Z-811101",WD_PLAN:"2022-02-03",A_OV_TOT:59,A_OV_COM:7,A_OV_REM:52,A_OV_PROG:11.86,A_OV_STATUS:"less than 70%",A_PG:11.86,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:6,PB1_OV_COM:0,PB1_OV_REM:6,PB3_OV_TOT:1,PB3_OV_COM:0,PB3_OV_REM:1,ACT_TAG_VALID:41,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8100-8122-02",SUBSYSTEM_DESCR:"Topside Air Compressor 21-Z-811201",WD_PLAN:"2022-02-03",A_OV_TOT:37,A_OV_COM:7,A_OV_REM:30,A_OV_PROG:18.91,A_OV_STATUS:"less than 70%",A_PG:18.91,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:5,PA_OV_COM:1,PA_OV_REM:4,PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,ACT_TAG_VALID:19,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8100-8122-03",SUBSYSTEM_DESCR:"Topside Air Compressor 21-Z-811301",WD_PLAN:"2022-02-03",A_OV_TOT:37,A_OV_COM:7,A_OV_REM:30,A_OV_PROG:18.91,A_OV_STATUS:"less than 70%",A_PG:18.91,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:19,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8100-8122-04",SUBSYSTEM_DESCR:"Topside Air Driers 21-Z-812001",WD_PLAN:"2022-02-03",A_OV_TOT:46,A_OV_COM:21,A_OV_REM:25,A_OV_PROG:45.65,A_OV_STATUS:"less than 70%",A_PG:45.65,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:6,PB1_OV_COM:0,PB1_OV_REM:6,ACT_TAG_VALID:29,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8100-8122-05",SUBSYSTEM_DESCR:"Topside Air Receiver 21-V-812001, Main header and Controls",WD_PLAN:"2022-01-27",A_OV_TOT:126,A_OV_COM:44,A_OV_REM:82,A_OV_PROG:34.92,A_OV_STATUS:"less than 70%",A_PG:34.92,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:61,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8100-8123-01",SUBSYSTEM_DESCR:"Topsides Instrument Air Distribution",WD_PLAN:"2022-06-02",A_OV_TOT:709,A_OV_COM:355,A_OV_REM:354,A_OV_PROG:50.07,A_OV_STATUS:"less than 70%",A_PG:50.07,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:456,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8130-8130-01",SUBSYSTEM_DESCR:"Fuel Gas KO Drum",WD_PLAN:"2022-05-05",A_OV_TOT:193,A_OV_COM:42,A_OV_REM:151,A_OV_PROG:21.76,A_OV_STATUS:"less than 70%",A_PG:21.76,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:79,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8130-8130-02",SUBSYSTEM_DESCR:"Fuel Gas Superheaters",WD_PLAN:"2022-04-14",A_OV_TOT:112,A_OV_COM:18,A_OV_REM:94,A_OV_PROG:16.07,A_OV_STATUS:"less than 70%",A_PG:16.07,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:61,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8130-8131-01",SUBSYSTEM_DESCR:"Fuel Gas Blanketing Distribution in HULL",WD_PLAN:"2021-11-11",A_OV_TOT:261,A_OV_COM:142,A_OV_REM:119,A_OV_PROG:54.4,A_OV_STATUS:"less than 70%",A_PG:54.4,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:188,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8130-8131-02",SUBSYSTEM_DESCR:"Fuel Gas Filters and Main Header",WD_PLAN:"2022-05-19",A_OV_TOT:131,A_OV_COM:57,A_OV_REM:74,A_OV_PROG:43.51,A_OV_STATUS:"less than 70%",A_PG:43.51,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:68,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8130-8131-03",SUBSYSTEM_DESCR:"LP Fuel Gas Distribution Topsides",WD_PLAN:"2022-05-19",A_OV_TOT:357,A_OV_COM:91,A_OV_REM:266,A_OV_PROG:25.49,A_OV_STATUS:"less than 70%",A_PG:25.49,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:160,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8130-8131-04",SUBSYSTEM_DESCR:"Fuel Gas Distribution to GTGs",WD_PLAN:"2022-05-19",A_OV_TOT:92,A_OV_COM:41,A_OV_REM:51,A_OV_PROG:44.56,A_OV_STATUS:"less than 70%",A_PG:44.56,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:58,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8150-8150-01",SUBSYSTEM_DESCR:"Nitrogen Generator and Receiver",WD_PLAN:"2022-02-17",A_OV_TOT:123,A_OV_COM:49,A_OV_REM:74,A_OV_PROG:39.83,A_OV_STATUS:"less than 70%",A_PG:39.83,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:1,PA_OV_REM:2,PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:69,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8150-8150-02",SUBSYSTEM_DESCR:"Topside Nitrogen Distribution",WD_PLAN:"2022-03-31",A_OV_TOT:678,A_OV_COM:404,A_OV_REM:274,A_OV_PROG:59.58,A_OV_STATUS:"less than 70%",A_PG:59.58,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:467,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8150-8150-03",SUBSYSTEM_DESCR:"HULL Nitrogen Distribution",A_OV_STATUS:"less than 70%",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"NA",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8160-8160-01",SUBSYSTEM_DESCR:"Inert Gas Generator",WD_PLAN:"2022-05-26",A_OV_TOT:148,A_OV_COM:1,A_OV_REM:147,A_OV_PROG:0.67,A_OV_STATUS:"less than 70%",A_PG:0.67,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:5,PA_OV_COM:1,PA_OV_REM:4,PB1_OV_TOT:6,PB1_OV_COM:0,PB1_OV_REM:6,ACT_TAG_VALID:82,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-8160-8160-03",SUBSYSTEM_DESCR:"IGG Header",WD_PLAN:"2022-05-12",A_OV_TOT:291,A_OV_COM:166,A_OV_REM:125,A_OV_PROG:57.04,A_OV_STATUS:"less than 70%",A_PG:57.04,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:211,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8310-01",SUBSYSTEM_DESCR:"Corrosion Inhibitor Tank and Pumps",WD_PLAN:"2022-03-31",A_OV_TOT:57,A_OV_COM:11,A_OV_REM:46,A_OV_PROG:19.29,A_OV_STATUS:"less than 70%",A_PG:19.29,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:6,PA_OV_COM:3,PA_OV_REM:3,PB1_OV_TOT:4,PB1_OV_COM:0,PB1_OV_REM:4,ACT_TAG_VALID:36,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8300-8310-02",SUBSYSTEM_DESCR:"Corrosion Inhibitor Distribution",WD_PLAN:"2022-05-12",A_OV_TOT:56,A_OV_COM:31,A_OV_REM:25,A_OV_PROG:55.35,A_OV_STATUS:"less than 70%",A_PG:55.35,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:36,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8320-01",SUBSYSTEM_DESCR:"Scale Inhibitor Tank and Pumps Topsides",WD_PLAN:"2022-03-31",A_OV_TOT:44,A_OV_COM:15,A_OV_REM:29,A_OV_PROG:34.09,A_OV_STATUS:"less than 70%",A_PG:34.09,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:30,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8320-02",SUBSYSTEM_DESCR:"Scale Inhibitor Distribution Subsea",WD_PLAN:"2022-05-26",A_OV_TOT:42,A_OV_COM:19,A_OV_REM:23,A_OV_PROG:45.23,A_OV_STATUS:"less than 70%",A_PG:45.23,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:24,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8320-03",SUBSYSTEM_DESCR:"Scale Inhibitor Distribution Topsides",WD_PLAN:"2022-05-26",A_OV_TOT:123,A_OV_COM:68,A_OV_REM:55,A_OV_PROG:55.28,A_OV_STATUS:"less than 70%",A_PG:55.28,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:78,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8320-04",SUBSYSTEM_DESCR:"Scale Inhibitor Tank and Pumps Subsea",WD_PLAN:"2022-03-31",A_OV_TOT:18,A_OV_COM:0,A_OV_REM:18,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8300-8330-01",SUBSYSTEM_DESCR:"Wax Inhibitor Loading Header",WD_PLAN:"2022-06-02",A_OV_TOT:44,A_OV_COM:12,A_OV_REM:32,A_OV_PROG:27.27,A_OV_STATUS:"less than 70%",A_PG:27.27,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:23,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8330-02",SUBSYSTEM_DESCR:"Wax Inhibitor Storage and Pumps",WD_PLAN:"2022-02-03",A_OV_TOT:156,A_OV_COM:39,A_OV_REM:117,A_OV_PROG:25,A_OV_STATUS:"less than 70%",A_PG:25,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:68,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8331-01",SUBSYSTEM_DESCR:"Wax Inhibitor Filtration",WD_PLAN:"2022-05-12",A_OV_TOT:51,A_OV_COM:7,A_OV_REM:44,A_OV_PROG:13.72,A_OV_STATUS:"less than 70%",A_PG:13.72,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:19,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8331-02",SUBSYSTEM_DESCR:"Wax Inhibitor Break Tank and Injection Pumps",WD_PLAN:"2022-04-21",A_OV_TOT:386,A_OV_COM:158,A_OV_REM:228,A_OV_PROG:40.93,A_OV_STATUS:"less than 70%",A_PG:40.93,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,ACT_TAG_VALID:210,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8300-8332-01",SUBSYSTEM_DESCR:"Wax Inhibitor Injection Subsea Phase 1",WD_PLAN:"2022-04-21",A_OV_TOT:27,A_OV_COM:9,A_OV_REM:18,A_OV_PROG:33.33,A_OV_STATUS:"less than 70%",A_PG:33.33,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8340-01",SUBSYSTEM_DESCR:"Biocide Tank and Pumps",WD_PLAN:"2022-03-31",A_OV_TOT:70,A_OV_COM:19,A_OV_REM:51,A_OV_PROG:27.14,A_OV_STATUS:"less than 70%",A_PG:27.14,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:14,PA_OV_COM:7,PA_OV_REM:7,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:38,PUNCH_NUMBER_VALID:15,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8300-8340-02",SUBSYSTEM_DESCR:"Biocide Distribution HULL and Topsides",WD_PLAN:"2022-05-12",A_OV_TOT:294,A_OV_COM:198,A_OV_REM:96,A_OV_PROG:67.34,A_OV_STATUS:"less than 70%",A_PG:67.34,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:225,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8350-01",SUBSYSTEM_DESCR:"Antifoam Tank and Pumps",WD_PLAN:"2022-03-31",A_OV_TOT:34,A_OV_COM:9,A_OV_REM:25,A_OV_PROG:26.47,A_OV_STATUS:"less than 70%",A_PG:26.47,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8350-02",SUBSYSTEM_DESCR:"Antifoam Distribution",WD_PLAN:"2022-06-02",A_OV_TOT:81,A_OV_COM:44,A_OV_REM:37,A_OV_PROG:54.32,A_OV_STATUS:"less than 70%",A_PG:54.32,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:51,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8360-01",SUBSYSTEM_DESCR:"Emulsion Breaker Tank and Pumps",WD_PLAN:"2022-03-31",A_OV_TOT:47,A_OV_COM:11,A_OV_REM:36,A_OV_PROG:23.4,A_OV_STATUS:"less than 70%",A_PG:23.4,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:28,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8360-02",SUBSYSTEM_DESCR:"Emulsion Breaker Distribution",WD_PLAN:"2022-03-31",A_OV_TOT:84,A_OV_COM:47,A_OV_REM:37,A_OV_PROG:55.95,A_OV_STATUS:"less than 70%",A_PG:55.95,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:54,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8370-01",SUBSYSTEM_DESCR:"MEG PH Stabilizer Tank and Pumps",WD_PLAN:"2022-03-31",A_OV_TOT:55,A_OV_COM:12,A_OV_REM:43,A_OV_PROG:21.81,A_OV_STATUS:"less than 70%",A_PG:21.81,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:37,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8370-02",SUBSYSTEM_DESCR:"MEG PH Stabilizer Distribution",WD_PLAN:"2022-06-02",A_OV_TOT:35,A_OV_COM:16,A_OV_REM:19,A_OV_PROG:45.71,A_OV_STATUS:"less than 70%",A_PG:45.71,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8375-01",SUBSYSTEM_DESCR:"MEG Antifoam Tank and Pumps",WD_PLAN:"2022-03-31",A_OV_TOT:21,A_OV_COM:13,A_OV_REM:8,A_OV_PROG:61.9,A_OV_STATUS:"less than 70%",A_PG:61.9,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8375-02",SUBSYSTEM_DESCR:"MEG Antifoam Distribution",WD_PLAN:"2022-06-02",A_OV_TOT:82,A_OV_COM:45,A_OV_REM:37,A_OV_PROG:54.87,A_OV_STATUS:"less than 70%",A_PG:54.87,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:52,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8380-01",SUBSYSTEM_DESCR:"Spare and Future Tanks",WD_PLAN:"2022-03-31",A_OV_TOT:4,A_OV_COM:0,A_OV_REM:4,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8400-8410-01",SUBSYSTEM_DESCR:"O2 Scavanger Tank and Pumps",WD_PLAN:"2022-03-31",A_OV_TOT:48,A_OV_COM:16,A_OV_REM:32,A_OV_PROG:33.33,A_OV_STATUS:"less than 70%",A_PG:33.33,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:28,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8400-8410-02",SUBSYSTEM_DESCR:"O2 Scavanger Distribution HULL and Topsides",WD_PLAN:"2022-03-31",A_OV_TOT:108,A_OV_COM:76,A_OV_REM:32,A_OV_PROG:70.37,A_OV_STATUS:"NA",A_PG:70.37,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:84,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8600-8611-01",SUBSYSTEM_DESCR:"GTG1 and WHRU1",WD_PLAN:"2022-02-03",A_OV_TOT:487,A_OV_COM:116,A_OV_REM:371,A_OV_PROG:23.81,A_OV_STATUS:"less than 70%",A_PG:23.81,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:10,PB1_OV_COM:0,PB1_OV_REM:10,ACT_TAG_VALID:321,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-8600-8612-01",SUBSYSTEM_DESCR:"GTG2 and WHRU2",WD_PLAN:"2022-02-03",A_OV_TOT:493,A_OV_COM:130,A_OV_REM:363,A_OV_PROG:26.36,A_OV_STATUS:"less than 70%",A_PG:26.36,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:328,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8600-8613-01",SUBSYSTEM_DESCR:"GTG3 and WHRU3",WD_PLAN:"2021-12-16",A_OV_TOT:488,A_OV_COM:129,A_OV_REM:359,A_OV_PROG:26.43,A_OV_STATUS:"less than 70%",A_PG:26.43,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,ACT_TAG_VALID:327,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8600-8614-01",SUBSYSTEM_DESCR:"GTG4 and WHRU4",WD_PLAN:"2021-12-16",A_OV_TOT:491,A_OV_COM:125,A_OV_REM:366,A_OV_PROG:25.45,A_OV_STATUS:"less than 70%",A_PG:25.45,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:322,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8700-8760-01",SUBSYSTEM_DESCR:"Topside Normal 690 V Swicthboard 21-ESB-876001",WD_PLAN:"2021-10-21",A_OV_TOT:152,A_OV_COM:83,A_OV_REM:69,A_OV_PROG:54.6,A_OV_STATUS:"less than 70%",A_PG:54.6,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:5,PB1_OV_COM:2,PB1_OV_REM:3,ACT_TAG_VALID:152,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8700-8770-01",SUBSYSTEM_DESCR:"Topsides Main Normal HV Switchboard 6.6kV 21-ESB-877001",WD_PLAN:"2021-10-21",A_OV_TOT:103,A_OV_COM:19,A_OV_REM:84,A_OV_PROG:18.44,A_OV_STATUS:"less than 70%",A_PG:18.44,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:8,PA_OV_COM:6,PA_OV_REM:2,PB1_OV_TOT:10,PB1_OV_COM:0,PB1_OV_REM:10,ACT_TAG_VALID:103,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8780-8780-01",SUBSYSTEM_DESCR:"Earthing HULL",WD_PLAN:"2022-04-21",A_OV_TOT:28,A_OV_COM:2,A_OV_REM:26,A_OV_PROG:7.14,A_OV_STATUS:"less than 70%",A_PG:7.14,RFI_ISSUED:"2021-09-23",WD_ACTUAL:"2021-09-24",PL_RECEIVED:"2021-09-24",MC_SUBMITTED:"2021-09-29",MC_APPROVED:"2021-09-29",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:4,PA_OV_COM:1,PA_OV_REM:3,PB1_OV_TOT:7,PB1_OV_COM:1,PB1_OV_REM:6,ACT_TAG_VALID:28,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8780-8780-02",SUBSYSTEM_DESCR:"Earthing LQ",WD_PLAN:"2022-04-14",A_OV_TOT:12,A_OV_COM:1,A_OV_REM:11,A_OV_PROG:8.33,A_OV_STATUS:"less than 70%",A_PG:8.33,RFI_ISSUED:"2021-10-22",WD_ACTUAL:"2021-10-23",PL_RECEIVED:"2021-10-23",MC_SUBMITTED:"2021-11-01",MC_APPROVED:"2021-11-05",MC_STATUS:"Partial MC Complete",SUBSYSTEM_STATUS:"Partial MC Complete",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:5,PA_OV_COM:4,PA_OV_REM:1,PB1_OV_TOT:7,PB1_OV_COM:0,PB1_OV_REM:7,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8780-8780-03",SUBSYSTEM_DESCR:"Earthing Topsides",WD_PLAN:"2022-07-21",A_OV_TOT:57,A_OV_COM:0,A_OV_REM:57,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:57,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8800-8800-01",SUBSYSTEM_DESCR:"Ses Water Lift Pump A",WD_PLAN:"2022-03-31",A_OV_TOT:55,A_OV_COM:4,A_OV_REM:51,A_OV_PROG:7.27,A_OV_STATUS:"less than 70%",A_PG:7.27,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:28,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8800-8800-02",SUBSYSTEM_DESCR:"Ses Water Lift Pump B",WD_PLAN:"2022-03-31",A_OV_TOT:55,A_OV_COM:4,A_OV_REM:51,A_OV_PROG:7.27,A_OV_STATUS:"less than 70%",A_PG:7.27,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:28,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8800-8800-03",SUBSYSTEM_DESCR:"Ses Water Lift Pump C",WD_PLAN:"2022-03-31",A_OV_TOT:55,A_OV_COM:4,A_OV_REM:51,A_OV_PROG:7.27,A_OV_STATUS:"less than 70%",A_PG:7.27,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:28,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8800-8800-04",SUBSYSTEM_DESCR:"Sea Water Filter and Distribution Header",WD_PLAN:"2022-03-31",A_OV_TOT:104,A_OV_COM:4,A_OV_REM:100,A_OV_PROG:3.84,A_OV_STATUS:"less than 70%",A_PG:3.84,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,ACT_TAG_VALID:43,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8800-8800-05",SUBSYSTEM_DESCR:"Sea Water Cooling Supply to Exchangers and Discharge Header",WD_PLAN:"2022-04-28",A_OV_TOT:124,A_OV_COM:11,A_OV_REM:113,A_OV_PROG:8.87,A_OV_STATUS:"less than 70%",A_PG:8.87,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:29,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8800-8805-01",SUBSYSTEM_DESCR:"Electrochlorination Unit",WD_PLAN:"2022-03-31",A_OV_TOT:127,A_OV_COM:6,A_OV_REM:121,A_OV_PROG:4.72,A_OV_STATUS:"less than 70%",A_PG:4.72,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P3",PA_OV_TOT:4,PA_OV_COM:4,PA_OV_REM:0,PB1_OV_TOT:7,PB1_OV_COM:4,PB1_OV_REM:3,ACT_TAG_VALID:94,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-8800-8805-02",SUBSYSTEM_DESCR:"Hypochlorite Distribution Topsides",WD_PLAN:"2022-04-28",A_OV_TOT:118,A_OV_COM:14,A_OV_REM:104,A_OV_PROG:11.86,A_OV_STATUS:"less than 70%",A_PG:11.86,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:44,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9130-9130-01",SUBSYSTEM_DESCR:"ME01 Area Completion",WD_PLAN:"2022-07-21",A_OV_TOT:44,A_OV_COM:7,A_OV_REM:37,A_OV_PROG:15.9,A_OV_STATUS:"less than 70%",A_PG:15.9,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:19,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9130-9130-02",SUBSYSTEM_DESCR:"ME02 Area Completion",WD_PLAN:"2022-07-14",A_OV_TOT:22,A_OV_COM:6,A_OV_REM:16,A_OV_PROG:27.27,A_OV_STATUS:"less than 70%",A_PG:27.27,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9130-9130-03",SUBSYSTEM_DESCR:"ME03 Area Completion",WD_PLAN:"2022-07-14",A_OV_STATUS:"NA",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9130-9130-04",SUBSYSTEM_DESCR:"ME04 Area Completion",WD_PLAN:"2022-07-07",A_OV_TOT:19,A_OV_COM:4,A_OV_REM:15,A_OV_PROG:21.05,A_OV_STATUS:"less than 70%",A_PG:21.05,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9130-9130-05",SUBSYSTEM_DESCR:"ME05 Area Completion",WD_PLAN:"2022-07-07",A_OV_TOT:49,A_OV_COM:3,A_OV_REM:46,A_OV_PROG:6.12,A_OV_STATUS:"less than 70%",A_PG:6.12,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9130-9130-06",SUBSYSTEM_DESCR:"MW01 Area Completion",WD_PLAN:"2022-07-21",A_OV_TOT:56,A_OV_COM:8,A_OV_REM:48,A_OV_PROG:14.28,A_OV_STATUS:"less than 70%",A_PG:14.28,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9130-9130-07",SUBSYSTEM_DESCR:"MW02 Area Completion",WD_PLAN:"2022-07-21",A_OV_TOT:43,A_OV_COM:8,A_OV_REM:35,A_OV_PROG:18.6,A_OV_STATUS:"less than 70%",A_PG:18.6,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9130-9130-08",SUBSYSTEM_DESCR:"MW03 Area Completion",WD_PLAN:"2022-07-14",A_OV_STATUS:"NA",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9130-9130-09",SUBSYSTEM_DESCR:"MW04 Area Completion",WD_PLAN:"2022-07-07",A_OV_TOT:20,A_OV_COM:4,A_OV_REM:16,A_OV_PROG:20,A_OV_STATUS:"less than 70%",A_PG:20,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9130-9130-10",SUBSYSTEM_DESCR:"MW05 Area Completion",WD_PLAN:"2022-07-14",A_OV_TOT:28,A_OV_COM:8,A_OV_REM:20,A_OV_PROG:28.57,A_OV_STATUS:"less than 70%",A_PG:28.57,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9130-9130-11",SUBSYSTEM_DESCR:"A Deck Laydown Area Completion",WD_PLAN:"2022-07-14",A_OV_TOT:16,A_OV_COM:0,A_OV_REM:16,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9130-9130-12",SUBSYSTEM_DESCR:"KO Drum Area Completion",WD_PLAN:"2022-07-14",A_OV_TOT:5,A_OV_COM:0,A_OV_REM:5,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9130-9130-13",SUBSYSTEM_DESCR:"Offloading Area Completion",WD_PLAN:"2022-05-26",A_OV_STATUS:"NA",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9130-9130-14",SUBSYSTEM_DESCR:"Flare Area Completion",WD_PLAN:"2022-07-21",A_OV_TOT:21,A_OV_COM:1,A_OV_REM:20,A_OV_PROG:4.76,A_OV_STATUS:"less than 70%",A_PG:4.76,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9130-9130-15",SUBSYSTEM_DESCR:"Central Pipe Rack Area Completion",WD_PLAN:"2022-06-30",A_OV_TOT:67,A_OV_COM:20,A_OV_REM:47,A_OV_PROG:29.85,A_OV_STATUS:"less than 70%",A_PG:29.85,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:45,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9130-9130-16",SUBSYSTEM_DESCR:"Safe Haven Area Completion",WD_PLAN:"2022-03-17",A_OV_STATUS:"NA",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9220-9220-01",SUBSYSTEM_DESCR:"LQ Main Deck and Mezzanine",WD_PLAN:"2022-02-10",A_OV_TOT:147,A_OV_COM:0,A_OV_REM:147,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:38,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9220-9220-02",SUBSYSTEM_DESCR:"LQ A Deck - Area Completion",WD_PLAN:"2022-02-17",A_OV_TOT:208,A_OV_COM:52,A_OV_REM:156,A_OV_PROG:25,A_OV_STATUS:"less than 70%",A_PG:25,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:55,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9220-9220-03",SUBSYSTEM_DESCR:"LQ Deck 1 - Area Completion",WD_PLAN:"2022-02-17",A_OV_TOT:167,A_OV_COM:48,A_OV_REM:119,A_OV_PROG:28.74,A_OV_STATUS:"less than 70%",A_PG:28.74,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:41,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-9220-9220-04",SUBSYSTEM_DESCR:"LQ Deck 2 - Area Completion",WD_PLAN:"2022-02-17",A_OV_TOT:207,A_OV_COM:33,A_OV_REM:174,A_OV_PROG:15.94,A_OV_STATUS:"less than 70%",A_PG:15.94,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:50,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9220-9220-05",SUBSYSTEM_DESCR:"LQ Deck 3 - Area Completion",WD_PLAN:"2022-02-17",A_OV_TOT:252,A_OV_COM:79,A_OV_REM:173,A_OV_PROG:31.34,A_OV_STATUS:"less than 70%",A_PG:31.34,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:71,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9220-9220-06",SUBSYSTEM_DESCR:"LQ Deck 4 - Area Completion",WD_PLAN:"2022-02-17",A_OV_TOT:256,A_OV_COM:85,A_OV_REM:171,A_OV_PROG:33.2,A_OV_STATUS:"less than 70%",A_PG:33.2,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:72,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9220-9220-07",SUBSYSTEM_DESCR:"LQ Deck 5 - Area Completion",WD_PLAN:"2022-02-24",A_OV_TOT:146,A_OV_COM:28,A_OV_REM:118,A_OV_PROG:19.17,A_OV_STATUS:"less than 70%",A_PG:19.17,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:35,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9220-9220-09",SUBSYSTEM_DESCR:"LQ Roof Deck and External  - Area Completion",WD_PLAN:"2022-02-24",A_OV_TOT:187,A_OV_COM:150,A_OV_REM:37,A_OV_PROG:80.21,A_OV_STATUS:"NA",A_PG:80.21,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:54,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9220-9220-11",SUBSYSTEM_DESCR:"Helideck - Area Completion",WD_PLAN:"2022-02-24",A_OV_TOT:6,A_OV_COM:3,A_OV_REM:3,A_OV_PROG:50,A_OV_STATUS:"less than 70%",A_PG:50,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P3",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-9220-9224-01",SUBSYSTEM_DESCR:"Entertainment System",WD_PLAN:"2021-12-02",A_OV_TOT:892,A_OV_COM:0,A_OV_REM:892,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:892,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9310-9310-01",SUBSYSTEM_DESCR:"HULL Main Deck Area Completion",WD_PLAN:"2022-02-17",A_OV_TOT:258,A_OV_COM:229,A_OV_REM:29,A_OV_PROG:88.75,A_OV_STATUS:"NA",A_PG:88.75,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:86,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9310-9310-02",SUBSYSTEM_DESCR:"Machinery Space Area Completion",WD_PLAN:"2022-03-03",A_OV_TOT:207,A_OV_COM:130,A_OV_REM:77,A_OV_PROG:62.8,A_OV_STATUS:"less than 70%",A_PG:62.8,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:52,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9310-9310-03",SUBSYSTEM_DESCR:"HULL Forward Area Completion",WD_PLAN:"2022-03-10",A_OV_TOT:729,A_OV_COM:511,A_OV_REM:218,A_OV_PROG:70.09,A_OV_STATUS:"NA",A_PG:70.09,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:233,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9310-9310-04",SUBSYSTEM_DESCR:"HULL After Area Completion",WD_PLAN:"2022-03-10",A_OV_TOT:599,A_OV_COM:558,A_OV_REM:41,A_OV_PROG:93.15,A_OV_STATUS:"greater than 90%",A_PG:93.15,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:192,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9310-9310-05",SUBSYSTEM_DESCR:"Riser Balcony Area Completion",WD_PLAN:"2022-03-17",A_OV_TOT:341,A_OV_COM:306,A_OV_REM:35,A_OV_PROG:89.73,A_OV_STATUS:"NA",A_PG:89.73,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:98,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9330-9330-01",SUBSYSTEM_DESCR:"Main Ballast Tanks WBE1 to 5, APE 1, APW 1 and WBW 1 to 5",WD_PLAN:"2022-02-24",A_OV_TOT:240,A_OV_COM:112,A_OV_REM:128,A_OV_PROG:46.66,A_OV_STATUS:"less than 70%",A_PG:46.66,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1A",ACT_TAG_VALID:110,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9330-9330-02",SUBSYSTEM_DESCR:"Main Ballast Ring Main",WD_PLAN:"2022-02-24",A_OV_TOT:305,A_OV_COM:31,A_OV_REM:274,A_OV_PROG:10.16,A_OV_STATUS:"less than 70%",A_PG:10.16,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1A",ACT_TAG_VALID:134,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9330-9330-03",SUBSYSTEM_DESCR:"Ballast Pump 21-P-935001 A",WD_PLAN:"2022-02-24",A_OV_TOT:107,A_OV_COM:6,A_OV_REM:101,A_OV_PROG:5.6,A_OV_STATUS:"less than 70%",A_PG:5.6,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1A",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:48,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-9330-9330-04",SUBSYSTEM_DESCR:"Ballast Pump 21-P-935001 B",WD_PLAN:"2022-02-24",A_OV_TOT:107,A_OV_COM:3,A_OV_REM:104,A_OV_PROG:2.8,A_OV_STATUS:"less than 70%",A_PG:2.8,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1A",ACT_TAG_VALID:48,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9330-9330-05",SUBSYSTEM_DESCR:"Ballast Pump 21-P-935001 C",WD_PLAN:"2022-02-24",A_OV_TOT:107,A_OV_COM:3,A_OV_REM:104,A_OV_PROG:2.8,A_OV_STATUS:"less than 70%",A_PG:2.8,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1A",ACT_TAG_VALID:48,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9330-9330-06",SUBSYSTEM_DESCR:"Ballast Pump 21-P-935001 D",WD_PLAN:"2022-02-17",A_OV_TOT:109,A_OV_COM:12,A_OV_REM:97,A_OV_PROG:11,A_OV_STATUS:"less than 70%",A_PG:11,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1A",ACT_TAG_VALID:50,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9330-9333-01",SUBSYSTEM_DESCR:"Cargo and Ballast Pumps HPU and UCP",WD_PLAN:"2021-11-25",A_OV_TOT:721,A_OV_COM:78,A_OV_REM:643,A_OV_PROG:10.81,A_OV_STATUS:"less than 70%",A_PG:10.81,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1A",PA_OV_TOT:20,PA_OV_COM:4,PA_OV_REM:16,PB1_OV_TOT:28,PB1_OV_COM:0,PB1_OV_REM:28,ACT_TAG_VALID:292,PUNCH_NUMBER_VALID:48,PUNCH_TAG_VALID:18},
    {SUBSYSTEM:"21-9330-9334-01",SUBSYSTEM_DESCR:"HULL Valves HPU",WD_PLAN:"2021-11-11",A_OV_TOT:85,A_OV_COM:0,A_OV_REM:85,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"B1",PRIORITY:"P1A",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:78,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9340-9341-01",SUBSYSTEM_DESCR:"Forward Mooring System and HPU",WD_PLAN:"2022-04-07",A_OV_TOT:40,A_OV_COM:1,A_OV_REM:39,A_OV_PROG:2.5,A_OV_STATUS:"less than 70%",A_PG:2.5,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:4,PB1_OV_COM:1,PB1_OV_REM:3,ACT_TAG_VALID:28,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-9340-9341-02",SUBSYSTEM_DESCR:"Forward Mooring Fairleads, Sheaves and Accessories Mounted on HULL",WD_PLAN:"2022-04-07",A_OV_TOT:141,A_OV_COM:36,A_OV_REM:105,A_OV_PROG:25.53,A_OV_STATUS:"less than 70%",A_PG:25.53,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,ACT_TAG_VALID:68,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9340-9343-01",SUBSYSTEM_DESCR:"Aft Mooring System and HPU",WD_PLAN:"2022-04-07",A_OV_TOT:44,A_OV_COM:0,A_OV_REM:44,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:4,PB1_OV_COM:1,PB1_OV_REM:3,ACT_TAG_VALID:32,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-9340-9343-02",SUBSYSTEM_DESCR:"Aft Mooring Fairleads, Sheaves and Accessories Mounted on HULL",WD_PLAN:"2022-04-07",A_OV_TOT:136,A_OV_COM:30,A_OV_REM:106,A_OV_PROG:22.05,A_OV_STATUS:"less than 70%",A_PG:22.05,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:8,PA_OV_COM:0,PA_OV_REM:8,ACT_TAG_VALID:65,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9350-9350-01",SUBSYSTEM_DESCR:"MS Ballast Tanks WBW6,7 and WBE6,7 and FPE1 andFPW1",WD_PLAN:"2021-12-02",A_OV_TOT:115,A_OV_COM:43,A_OV_REM:72,A_OV_PROG:37.39,A_OV_STATUS:"less than 70%",A_PG:37.39,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1A",ACT_TAG_VALID:50,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9350-9350-02",SUBSYSTEM_DESCR:"MS Ballast Ring Main",WD_PLAN:"2021-11-25",A_OV_TOT:79,A_OV_COM:0,A_OV_REM:79,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1A",ACT_TAG_VALID:48,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9350-9350-04",SUBSYSTEM_DESCR:"MS Ballast Pump 21-P-935002A",WD_PLAN:"2021-11-25",A_OV_TOT:122,A_OV_COM:8,A_OV_REM:114,A_OV_PROG:6.55,A_OV_STATUS:"less than 70%",A_PG:6.55,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:70,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9350-9350-05",SUBSYSTEM_DESCR:"MS Ballast Pump 21-P-935002B",WD_PLAN:"2021-11-25",A_OV_TOT:81,A_OV_COM:4,A_OV_REM:77,A_OV_PROG:4.93,A_OV_STATUS:"less than 70%",A_PG:4.93,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,ACT_TAG_VALID:43,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9370-9370-01",SUBSYSTEM_DESCR:"Slops Tank 21-T-937001 and Pumps 21-P-937001and3",WD_PLAN:"2022-02-10",A_OV_TOT:324,A_OV_COM:69,A_OV_REM:255,A_OV_PROG:21.29,A_OV_STATUS:"less than 70%",A_PG:21.29,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:143,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9370-9371-01",SUBSYSTEM_DESCR:"Slops Tank 21-T-937002 and Pumps 21-P-937002and4",WD_PLAN:"2021-12-09",A_OV_TOT:361,A_OV_COM:75,A_OV_REM:286,A_OV_PROG:20.77,A_OV_STATUS:"less than 70%",A_PG:20.77,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:152,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9370-9372-01",SUBSYSTEM_DESCR:"Hazardous Open Drain Header to Slops Tanks",WD_PLAN:"2022-02-03",A_OV_TOT:457,A_OV_COM:124,A_OV_REM:333,A_OV_PROG:27.13,A_OV_STATUS:"less than 70%",A_PG:27.13,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:127,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9370-9372-02",SUBSYSTEM_DESCR:"Non Hazardous Open Drain Header to Slops Tanks",WD_PLAN:"2022-02-03",A_OV_TOT:275,A_OV_COM:32,A_OV_REM:243,A_OV_PROG:11.63,A_OV_STATUS:"less than 70%",A_PG:11.63,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:50,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9370-9372-04",SUBSYSTEM_DESCR:"Slops Heaters 21-H-937001 AandB",WD_PLAN:"2022-02-03",A_OV_TOT:116,A_OV_COM:13,A_OV_REM:103,A_OV_PROG:11.2,A_OV_STATUS:"less than 70%",A_PG:11.2,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:45,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9370-9372-07",SUBSYSTEM_DESCR:"Slops Water to Export Line Flushing",WD_PLAN:"2022-02-03",A_OV_TOT:159,A_OV_COM:19,A_OV_REM:140,A_OV_PROG:11.94,A_OV_STATUS:"less than 70%",A_PG:11.94,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:75,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9370-9372-08",SUBSYSTEM_DESCR:"COW Hot Sea Water Filter 21-F-937001",WD_PLAN:"2022-01-13",A_OV_TOT:40,A_OV_COM:4,A_OV_REM:36,A_OV_PROG:10,A_OV_STATUS:"less than 70%",A_PG:10,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:13,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9370-9372-09",SUBSYSTEM_DESCR:"Slops Polishing and ODMS ,Oil Discharge Monitoring Units",WD_PLAN:"2021-12-16",A_OV_TOT:98,A_OV_COM:2,A_OV_REM:96,A_OV_PROG:2.04,A_OV_STATUS:"less than 70%",A_PG:2.04,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:17,PA_OV_COM:4,PA_OV_REM:13,ACT_TAG_VALID:55,PUNCH_NUMBER_VALID:17,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9370-9372-10",SUBSYSTEM_DESCR:"Treated Slops Header to Overboard",WD_PLAN:"2021-12-16",A_OV_TOT:21,A_OV_COM:1,A_OV_REM:20,A_OV_PROG:4.76,A_OV_STATUS:"less than 70%",A_PG:4.76,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9370-9372-11",SUBSYSTEM_DESCR:"LQ Open Drain Collection",WD_PLAN:"2022-04-07",A_OV_TOT:144,A_OV_COM:54,A_OV_REM:90,A_OV_PROG:37.5,A_OV_STATUS:"less than 70%",A_PG:37.5,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:84,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9370-9372-12",SUBSYSTEM_DESCR:"HULL Open Drain Collection",WD_PLAN:"2022-03-10",A_OV_TOT:69,A_OV_COM:51,A_OV_REM:18,A_OV_PROG:73.91,A_OV_STATUS:"NA",A_PG:73.91,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:54,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9380-9380-01",SUBSYSTEM_DESCR:"Daily bilge Tank 21-T-938001 and Pumps 21-P-938001AandB",WD_PLAN:"2022-01-06",A_OV_TOT:111,A_OV_COM:40,A_OV_REM:71,A_OV_PROG:36.03,A_OV_STATUS:"less than 70%",A_PG:36.03,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:47,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9380-9380-02",SUBSYSTEM_DESCR:"Daily Bilge Wells and Headers to Daily Bilge Tank",WD_PLAN:"2022-01-06",A_OV_TOT:249,A_OV_COM:86,A_OV_REM:163,A_OV_PROG:34.53,A_OV_STATUS:"less than 70%",A_PG:34.53,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:95,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9380-9380-03",SUBSYSTEM_DESCR:"Bilge Water from MS Bilge Collection Header",WD_PLAN:"2021-12-30",A_OV_TOT:200,A_OV_COM:107,A_OV_REM:93,A_OV_PROG:53.5,A_OV_STATUS:"less than 70%",A_PG:53.5,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:133,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9380-9380-04",SUBSYSTEM_DESCR:"Sludge from Diesel Purifier",WD_PLAN:"2021-10-07",A_OV_TOT:8,A_OV_COM:6,A_OV_REM:2,A_OV_PROG:75,A_OV_STATUS:"NA",A_PG:75,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9380-9380-05",SUBSYSTEM_DESCR:"Diesel Oil from Diesel Storage Tanks",WD_PLAN:"2021-10-14",A_OV_TOT:8,A_OV_COM:4,A_OV_REM:4,A_OV_PROG:50,A_OV_STATUS:"less than 70%",A_PG:50,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9380-9380-06",SUBSYSTEM_DESCR:"Daily Bilge for CFC8 and Pump 21-P-938003",WD_PLAN:"2021-12-30",A_OV_TOT:59,A_OV_COM:12,A_OV_REM:47,A_OV_PROG:20.33,A_OV_STATUS:"less than 70%",A_PG:20.33,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:23,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9380-9380-07",SUBSYSTEM_DESCR:"Daily Bilge for CFC5 and CFC6 and Pump 21-P-938004",WD_PLAN:"2022-01-06",A_OV_TOT:101,A_OV_COM:26,A_OV_REM:75,A_OV_PROG:25.74,A_OV_STATUS:"less than 70%",A_PG:25.74,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:43,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9380-9380-08",SUBSYSTEM_DESCR:"Daily Bilge for CFC7 and Pump 21-P-938005",WD_PLAN:"2022-01-06",A_OV_TOT:70,A_OV_COM:20,A_OV_REM:50,A_OV_PROG:28.57,A_OV_STATUS:"less than 70%",A_PG:28.57,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:29,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9380-9380-09",SUBSYSTEM_DESCR:"Daily Bilge for CFC1 and Pump 21-P-938006",WD_PLAN:"2022-01-06",A_OV_TOT:69,A_OV_COM:19,A_OV_REM:50,A_OV_PROG:27.53,A_OV_STATUS:"less than 70%",A_PG:27.53,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9380-9381-01",SUBSYSTEM_DESCR:"Main bilge Pumps 21-P-938002AandB and Headers",WD_PLAN:"2022-01-06",A_OV_TOT:99,A_OV_COM:24,A_OV_REM:75,A_OV_PROG:24.24,A_OV_STATUS:"less than 70%",A_PG:24.24,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:49,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9380-9381-02",SUBSYSTEM_DESCR:"Main bilge Water Header to Overboard",WD_PLAN:"2022-01-06",A_OV_TOT:6,A_OV_COM:3,A_OV_REM:3,A_OV_PROG:50,A_OV_STATUS:"less than 70%",A_PG:50,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9380-9382-01",SUBSYSTEM_DESCR:"Emergency Bilge well ,SB middle and header",WD_PLAN:"2022-01-06",A_OV_TOT:11,A_OV_COM:3,A_OV_REM:8,A_OV_PROG:27.27,A_OV_STATUS:"less than 70%",A_PG:27.27,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9390-9390-01",SUBSYSTEM_DESCR:"Bunker Station and HPU",WD_PLAN:"2022-04-07",A_OV_TOT:22,A_OV_COM:4,A_OV_REM:18,A_OV_PROG:18.18,A_OV_STATUS:"less than 70%",A_PG:18.18,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:4,PA_OV_COM:0,PA_OV_REM:4,PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-9500-9514-01",SUBSYSTEM_DESCR:"Telephone System - LQ",WD_PLAN:"2021-12-16",A_OV_TOT:346,A_OV_COM:3,A_OV_REM:343,A_OV_PROG:0.86,A_OV_STATUS:"less than 70%",A_PG:0.86,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,ACT_TAG_VALID:342,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9500-9514-02",SUBSYSTEM_DESCR:"Telephone System - Machinery Space and Topsides",WD_PLAN:"2021-12-16",A_OV_TOT:184,A_OV_COM:0,A_OV_REM:184,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:147,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9500-9515-01",SUBSYSTEM_DESCR:"Hot line Telephones",WD_PLAN:"2021-12-16",A_OV_TOT:15,A_OV_COM:0,A_OV_REM:15,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9500-9525-01",SUBSYSTEM_DESCR:"Microwave Line of Sight",WD_PLAN:"2021-12-30",A_OV_TOT:20,A_OV_COM:0,A_OV_REM:20,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9500-9527-01",SUBSYSTEM_DESCR:"INMARSAT Terminal and Portable Sat Phones",WD_PLAN:"2021-12-23",A_OV_TOT:13,A_OV_COM:0,A_OV_REM:13,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:13,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9500-9534-01",SUBSYSTEM_DESCR:"Structured Cabling System - LQ Deck-A",WD_PLAN:"2021-11-18",A_OV_TOT:346,A_OV_COM:0,A_OV_REM:346,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:346,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9500-9534-02",SUBSYSTEM_DESCR:"Structured Cabling System - LQ Levels 1,2 and 3",WD_PLAN:"2021-11-18",A_OV_TOT:307,A_OV_COM:0,A_OV_REM:307,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:307,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9500-9534-03",SUBSYSTEM_DESCR:"Structured Cabling System - LQ Levels 4 and 5",WD_PLAN:"2021-11-18",A_OV_TOT:244,A_OV_COM:0,A_OV_REM:244,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:244,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9500-9534-04",SUBSYSTEM_DESCR:"Structured Cabling System - Machinery Space",WD_PLAN:"2021-11-18",A_OV_TOT:20,A_OV_COM:0,A_OV_REM:20,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9500-9534-05",SUBSYSTEM_DESCR:"Structured Cabling System - ME05",WD_PLAN:"2021-12-02",A_OV_TOT:18,A_OV_COM:0,A_OV_REM:18,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9500-9535-01",SUBSYSTEM_DESCR:"FPSO Fibre Optic Cable",WD_PLAN:"2021-11-18",A_OV_TOT:11,A_OV_COM:0,A_OV_REM:11,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9500-9535-02",SUBSYSTEM_DESCR:"Subsea FO Termination Unit Cables and Junction Box",WD_PLAN:"2021-12-23",A_OV_TOT:3,A_OV_COM:0,A_OV_REM:3,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9500-9536-01",SUBSYSTEM_DESCR:"LANandWAN ,Data Network Infrastructure",WD_PLAN:"2021-12-09",A_OV_TOT:541,A_OV_COM:6,A_OV_REM:535,A_OV_PROG:1.1,A_OV_STATUS:"less than 70%",A_PG:1.1,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,ACT_TAG_VALID:489,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-9500-9539-01",SUBSYSTEM_DESCR:"Video Conferencing and Collaboration System",WD_PLAN:"2021-12-23",A_OV_TOT:75,A_OV_COM:0,A_OV_REM:75,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:75,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9500-9544-01",SUBSYSTEM_DESCR:"Marine and Security CCTV - LQ",WD_PLAN:"2021-12-02",A_OV_TOT:164,A_OV_COM:16,A_OV_REM:148,A_OV_PROG:9.75,A_OV_STATUS:"less than 70%",A_PG:9.75,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,ACT_TAG_VALID:141,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-9500-9544-02",SUBSYSTEM_DESCR:"Marine and Security CCTV - Topside HULL Deck",WD_PLAN:"2022-02-24",A_OV_TOT:211,A_OV_COM:0,A_OV_REM:211,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:165,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9500-9554-01",SUBSYSTEM_DESCR:"Meterological METOCEAN System",WD_PLAN:"2021-12-02",A_OV_TOT:61,A_OV_COM:0,A_OV_REM:61,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:53,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9500-9555-01",SUBSYSTEM_DESCR:"Berthing Aids and Approach System",WD_PLAN:"2021-12-02",A_OV_TOT:52,A_OV_COM:0,A_OV_REM:52,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,ACT_TAG_VALID:44,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9500-9557-01",SUBSYSTEM_DESCR:"Global Maritime Distress and Safety System",WD_PLAN:"2022-02-10",A_OV_TOT:64,A_OV_COM:0,A_OV_REM:64,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:64,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9500-9564-01",SUBSYSTEM_DESCR:"Access Control System and Emergency Mustering System",WD_PLAN:"2021-12-02",A_OV_TOT:616,A_OV_COM:4,A_OV_REM:612,A_OV_PROG:0.64,A_OV_STATUS:"less than 70%",A_PG:0.64,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PB1_OV_TOT:3,PB1_OV_COM:1,PB1_OV_REM:2,ACT_TAG_VALID:616,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-9500-9565-01",SUBSYSTEM_DESCR:"Ship Security Alert System ,SSAS",WD_PLAN:"2021-12-23",A_OV_TOT:16,A_OV_COM:2,A_OV_REM:14,A_OV_PROG:12.5,A_OV_STATUS:"less than 70%",A_PG:12.5,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9500-9570-01",SUBSYSTEM_DESCR:"Gyrocompass",WD_PLAN:"2021-12-30",A_OV_TOT:19,A_OV_COM:1,A_OV_REM:18,A_OV_PROG:5.26,A_OV_STATUS:"less than 70%",A_PG:5.26,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:19,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9500-9570-02",SUBSYSTEM_DESCR:"D-GPS System",WD_PLAN:"2021-12-30",A_OV_TOT:11,A_OV_COM:0,A_OV_REM:11,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9500-9570-03",SUBSYSTEM_DESCR:"Hand held satellite phone",WD_PLAN:"2021-12-23",A_OV_TOT:11,A_OV_COM:0,A_OV_REM:11,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9500-9570-04",SUBSYSTEM_DESCR:"Video Wall System",WD_PLAN:"2021-12-30",A_OV_TOT:40,A_OV_COM:0,A_OV_REM:40,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:40,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9500-9574-01",SUBSYSTEM_DESCR:"Network Management System",WD_PLAN:"2021-12-30",A_OV_TOT:11,A_OV_COM:0,A_OV_REM:11,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9500-9575-01",SUBSYSTEM_DESCR:"Digital Voice Recorder",WD_PLAN:"2021-12-30",A_OV_TOT:16,A_OV_COM:0,A_OV_REM:16,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9810-9811-01",SUBSYSTEM_DESCR:"FPSO West Crane",WD_PLAN:"2022-04-07",A_OV_TOT:193,A_OV_COM:134,A_OV_REM:59,A_OV_PROG:69.43,A_OV_STATUS:"less than 70%",A_PG:69.43,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:32,PA_OV_COM:0,PA_OV_REM:32,PB1_OV_TOT:26,PB1_OV_COM:0,PB1_OV_REM:26,ACT_TAG_VALID:189,PUNCH_NUMBER_VALID:58,PUNCH_TAG_VALID:30},
    {SUBSYSTEM:"21-9810-9812-01",SUBSYSTEM_DESCR:"FPSO East Crane",WD_PLAN:"2022-04-07",A_OV_TOT:191,A_OV_COM:131,A_OV_REM:60,A_OV_PROG:68.58,A_OV_STATUS:"less than 70%",A_PG:68.58,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:26,PA_OV_COM:0,PA_OV_REM:26,PB1_OV_TOT:17,PB1_OV_COM:0,PB1_OV_REM:17,ACT_TAG_VALID:187,PUNCH_NUMBER_VALID:43,PUNCH_TAG_VALID:31},
    {SUBSYSTEM:"21-9820-9821-01",SUBSYSTEM_DESCR:"Overhead Cranes",A_OV_STATUS:"less than 70%",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"NA",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9820-9822-01",SUBSYSTEM_DESCR:"LQ Personnel Lift",WD_PLAN:"2022-02-17",A_OV_TOT:93,A_OV_COM:0,A_OV_REM:93,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:86,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-9820-9823-01",SUBSYSTEM_DESCR:"LQ Goods and Material Lift",WD_PLAN:"2022-02-03",A_OV_TOT:100,A_OV_COM:0,A_OV_REM:100,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:0,PA_OV_REM:3,ACT_TAG_VALID:90,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-9820-9824-01",SUBSYSTEM_DESCR:"Riser and Umbilical Pull in Winch",WD_PLAN:"2022-04-14",A_OV_TOT:13,A_OV_COM:0,A_OV_REM:13,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-9820-9824-02",SUBSYSTEM_DESCR:"Miscellaneous Lifting Beams, Davits, Hoist and Padeyes HULL",WD_PLAN:"2022-03-24",A_OV_TOT:181,A_OV_COM:71,A_OV_REM:110,A_OV_PROG:39.22,A_OV_STATUS:"less than 70%",A_PG:39.22,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:181,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9820-9824-03",SUBSYSTEM_DESCR:"Miscellaneous Lifting Beams, Davits, Hoist and Padeyes LQ",WD_PLAN:"2022-03-24",A_OV_TOT:51,A_OV_COM:3,A_OV_REM:48,A_OV_PROG:5.88,A_OV_STATUS:"less than 70%",A_PG:5.88,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:51,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9820-9824-04",SUBSYSTEM_DESCR:"Miscellaneous Lifting Beams, Davits, Hoist and Padeyes Topsides",WD_PLAN:"2022-04-14",A_OV_TOT:307,A_OV_COM:87,A_OV_REM:220,A_OV_PROG:28.33,A_OV_STATUS:"less than 70%",A_PG:28.33,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:307,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9840-9841-01",SUBSYSTEM_DESCR:"Workshops",WD_PLAN:"2022-01-06",A_OV_TOT:8,A_OV_COM:0,A_OV_REM:8,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"B1",PRIORITY:"P3",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-9840-9842-01",SUBSYSTEM_DESCR:"Laboratory",WD_PLAN:"2022-01-06",A_OV_TOT:20,A_OV_COM:0,A_OV_REM:20,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:19,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9840-9843-01",SUBSYSTEM_DESCR:"Medical Suite",WD_PLAN:"2022-02-17",A_OV_TOT:2,A_OV_COM:0,A_OV_REM:2,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9840-9844-01",SUBSYSTEM_DESCR:"Galley",WD_PLAN:"2022-02-17",A_OV_TOT:64,A_OV_COM:5,A_OV_REM:59,A_OV_PROG:7.81,A_OV_STATUS:"less than 70%",A_PG:7.81,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:11,PA_OV_COM:0,PA_OV_REM:11,ACT_TAG_VALID:56,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9840-9845-01",SUBSYSTEM_DESCR:"Laundry",WD_PLAN:"2022-02-17",A_OV_TOT:17,A_OV_COM:0,A_OV_REM:17,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9840-9846-01",SUBSYSTEM_DESCR:"Hospital and Galley Call Alarm System",WD_PLAN:"2022-01-13",A_OV_TOT:49,A_OV_COM:0,A_OV_REM:49,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:49,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9921-9921-01",SUBSYSTEM_DESCR:"LQ Emergency Pressurization Fans",WD_PLAN:"2021-12-02",A_OV_TOT:72,A_OV_COM:0,A_OV_REM:72,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,ACT_TAG_VALID:64,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-9921-9921-02",SUBSYSTEM_DESCR:"LQ Battery Exhaust Fans, Ducting and Dampers",WD_PLAN:"2021-12-09",A_OV_TOT:77,A_OV_COM:3,A_OV_REM:74,A_OV_PROG:3.89,A_OV_STATUS:"less than 70%",A_PG:3.89,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:64,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9921-9921-03",SUBSYSTEM_DESCR:"LQ Extracts, Ducting and Damper",WD_PLAN:"2021-12-23",A_OV_TOT:231,A_OV_COM:21,A_OV_REM:210,A_OV_PROG:9.09,A_OV_STATUS:"less than 70%",A_PG:9.09,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:10,PA_OV_COM:0,PA_OV_REM:10,ACT_TAG_VALID:197,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-9921-9921-04",SUBSYSTEM_DESCR:"LQ Emergency SWB Room ACUsandACCUs and UCPs",WD_PLAN:"2021-09-16",A_OV_TOT:82,A_OV_COM:5,A_OV_REM:77,A_OV_PROG:6.09,A_OV_STATUS:"less than 70%",A_PG:6.09,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,ACT_TAG_VALID:44,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9921-9921-05",SUBSYSTEM_DESCR:"LQ CCR ACUsandACCUs and UCPs",WD_PLAN:"2022-01-13",A_OV_TOT:61,A_OV_COM:0,A_OV_REM:61,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:33,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9921-9921-06",SUBSYSTEM_DESCR:"LQ CER ACUsandACCUs and UCPs",WD_PLAN:"2021-10-07",A_OV_TOT:64,A_OV_COM:1,A_OV_REM:63,A_OV_PROG:1.56,A_OV_STATUS:"less than 70%",A_PG:1.56,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,ACT_TAG_VALID:36,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-9921-9921-07",SUBSYSTEM_DESCR:"LQ Secondary ICC ACUsandACCUs and UCPs",WD_PLAN:"2021-12-16",A_OV_TOT:88,A_OV_COM:12,A_OV_REM:76,A_OV_PROG:13.63,A_OV_STATUS:"less than 70%",A_PG:13.63,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:6,PB1_OV_COM:0,PB1_OV_REM:6,ACT_TAG_VALID:66,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-9921-9921-08",SUBSYSTEM_DESCR:"LQ Dining Room and Secondary Muster ACUsandACCUs and UCPs",WD_PLAN:"2021-12-09",A_OV_TOT:78,A_OV_COM:7,A_OV_REM:71,A_OV_PROG:8.97,A_OV_STATUS:"less than 70%",A_PG:8.97,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:4,PB1_OV_COM:0,PB1_OV_REM:4,ACT_TAG_VALID:45,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-9921-9921-09",SUBSYSTEM_DESCR:"LQ Telecom Room ACUsandACCUs and UCPs",WD_PLAN:"2021-12-30",A_OV_TOT:40,A_OV_COM:0,A_OV_REM:40,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,ACT_TAG_VALID:21,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9921-9922-01",SUBSYSTEM_DESCR:"LQ HVAC UCPs, EDBs, VSDs",WD_PLAN:"2021-12-02",A_OV_TOT:201,A_OV_COM:69,A_OV_REM:132,A_OV_PROG:34.32,A_OV_STATUS:"less than 70%",A_PG:34.32,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:6,PA_OV_COM:1,PA_OV_REM:5,PB1_OV_TOT:26,PB1_OV_COM:2,PB1_OV_REM:24,ACT_TAG_VALID:201,PUNCH_NUMBER_VALID:32,PUNCH_TAG_VALID:16},
    {SUBSYSTEM:"21-9921-9922-02",SUBSYSTEM_DESCR:"LQ HVAC AHUs, Ducting and Dampers",WD_PLAN:"2021-12-02",A_OV_TOT:1804,A_OV_COM:510,A_OV_REM:1294,A_OV_PROG:28.27,A_OV_STATUS:"less than 70%",A_PG:28.27,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:49,PA_OV_COM:0,PA_OV_REM:49,PB1_OV_TOT:28,PB1_OV_COM:0,PB1_OV_REM:28,ACT_TAG_VALID:1663,PUNCH_NUMBER_VALID:77,PUNCH_TAG_VALID:38},
    {SUBSYSTEM:"21-9921-9922-03",SUBSYSTEM_DESCR:"LQ HVAC Extracts, Ducting and Dampers",WD_PLAN:"2021-12-02",A_OV_TOT:614,A_OV_COM:292,A_OV_REM:322,A_OV_PROG:47.55,A_OV_STATUS:"less than 70%",A_PG:47.55,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:15,PA_OV_COM:0,PA_OV_REM:15,PB1_OV_TOT:5,PB1_OV_COM:0,PB1_OV_REM:5,ACT_TAG_VALID:598,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:11},
    {SUBSYSTEM:"21-9921-9922-04",SUBSYSTEM_DESCR:"LQ Galley AHUs, Ducting and Dampers",WD_PLAN:"2021-12-09",A_OV_TOT:170,A_OV_COM:20,A_OV_REM:150,A_OV_PROG:11.76,A_OV_STATUS:"less than 70%",A_PG:11.76,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:9,PA_OV_COM:0,PA_OV_REM:9,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:148,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-9921-9922-05",SUBSYSTEM_DESCR:"LQ General Exhaust Air, Ducting and Dampers",WD_PLAN:"2021-12-23",A_OV_TOT:165,A_OV_COM:34,A_OV_REM:131,A_OV_PROG:20.6,A_OV_STATUS:"less than 70%",A_PG:20.6,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:0,PA_OV_REM:3,PB1_OV_TOT:5,PB1_OV_COM:0,PB1_OV_REM:5,ACT_TAG_VALID:156,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-9921-9922-06",SUBSYSTEM_DESCR:"LQ Galley Hood Exhaust Fans, Ducting and Dampers and Galley extinguishing system",WD_PLAN:"2021-12-23",A_OV_TOT:132,A_OV_COM:3,A_OV_REM:129,A_OV_PROG:2.27,A_OV_STATUS:"less than 70%",A_PG:2.27,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:0,PA_OV_REM:3,ACT_TAG_VALID:119,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-9921-9922-07",SUBSYSTEM_DESCR:"LQ Toilet Exhaust Fans, Ducting and Dampers",WD_PLAN:"2021-12-02",A_OV_TOT:166,A_OV_COM:55,A_OV_REM:111,A_OV_PROG:33.13,A_OV_STATUS:"less than 70%",A_PG:33.13,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:4,PA_OV_COM:0,PA_OV_REM:4,PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,ACT_TAG_VALID:163,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-9921-9922-08",SUBSYSTEM_DESCR:"LQ Laundry Exhaust Fans, Ducting and Dampers",WD_PLAN:"2021-12-02",A_OV_TOT:68,A_OV_COM:8,A_OV_REM:60,A_OV_PROG:11.76,A_OV_STATUS:"less than 70%",A_PG:11.76,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:56,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9921-9922-09",SUBSYSTEM_DESCR:"LQ Sick Bay Exhaust Fans, Ducting and Dampers",WD_PLAN:"2021-12-23",A_OV_TOT:77,A_OV_COM:12,A_OV_REM:65,A_OV_PROG:15.58,A_OV_STATUS:"less than 70%",A_PG:15.58,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,ACT_TAG_VALID:73,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-9921-9922-10",SUBSYSTEM_DESCR:"LQ Chemical Exhaust Fans, Ducting and Dampers",A_OV_STATUS:"less than 70%",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"NA",ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9921-9922-11",SUBSYSTEM_DESCR:"LQ Laboratory Exhaust Fans, Ducting and Dampers",WD_PLAN:"2021-12-23",A_OV_TOT:72,A_OV_COM:11,A_OV_REM:61,A_OV_PROG:15.27,A_OV_STATUS:"less than 70%",A_PG:15.27,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:58,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-9921-9922-12",SUBSYSTEM_DESCR:"LQ Provision Plant Exhaust Fans, Ducting and Dampers",WD_PLAN:"2021-12-02",A_OV_TOT:54,A_OV_COM:2,A_OV_REM:52,A_OV_PROG:3.7,A_OV_STATUS:"less than 70%",A_PG:3.7,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:48,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-9921-9922-13",SUBSYSTEM_DESCR:"HULL Purge",WD_PLAN:"2021-12-16",A_OV_TOT:19,A_OV_COM:1,A_OV_REM:18,A_OV_PROG:5.26,A_OV_STATUS:"less than 70%",A_PG:5.26,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:19,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9921-9922-14",SUBSYSTEM_DESCR:"LQ Paint Store Supply Fans, Ducting and Dampers",WD_PLAN:"2022-02-10",A_OV_TOT:113,A_OV_COM:0,A_OV_REM:113,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:3,PA_OV_COM:0,PA_OV_REM:3,ACT_TAG_VALID:84,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9921-9922-15",SUBSYSTEM_DESCR:"LQ Dining Room Exhaust Fan, Ducting and Dampers",WD_PLAN:"2021-12-09",A_OV_TOT:36,A_OV_COM:4,A_OV_REM:32,A_OV_PROG:11.11,A_OV_STATUS:"less than 70%",A_PG:11.11,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:35,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9921-9927-01",SUBSYSTEM_DESCR:"Cool Store System in Galley",WD_PLAN:"2021-12-02",A_OV_TOT:154,A_OV_COM:4,A_OV_REM:150,A_OV_PROG:2.59,A_OV_STATUS:"less than 70%",A_PG:2.59,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:8,PA_OV_COM:0,PA_OV_REM:8,PB1_OV_TOT:4,PB1_OV_COM:3,PB1_OV_REM:1,ACT_TAG_VALID:132,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-9950-9950-01",SUBSYSTEM_DESCR:"Tertiary Refuge Air Intake, Ducting and Dampers",WD_PLAN:"2022-03-03",A_OV_TOT:77,A_OV_COM:0,A_OV_REM:77,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:63,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9950-9950-02",SUBSYSTEM_DESCR:"Tertiary Refuge ACUsandACCUs and UCPs",WD_PLAN:"2022-03-17",A_OV_TOT:88,A_OV_COM:0,A_OV_REM:88,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:80,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9970-9970-01",SUBSYSTEM_DESCR:"HULL Emergency Pressurisation Fans",WD_PLAN:"2021-11-04",A_OV_TOT:87,A_OV_COM:22,A_OV_REM:65,A_OV_PROG:25.28,A_OV_STATUS:"less than 70%",A_PG:25.28,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PB1_OV_TOT:5,PB1_OV_COM:0,PB1_OV_REM:5,ACT_TAG_VALID:78,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:7},
    {SUBSYSTEM:"21-9970-9971-01",SUBSYSTEM_DESCR:"HULL HVAC UCPs, EDBs, VSDs",WD_PLAN:"2021-10-07",A_OV_TOT:100,A_OV_COM:39,A_OV_REM:61,A_OV_PROG:39,A_OV_STATUS:"less than 70%",A_PG:39,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:27,PB1_OV_COM:1,PB1_OV_REM:26,ACT_TAG_VALID:99,PUNCH_NUMBER_VALID:28,PUNCH_TAG_VALID:16},
    {SUBSYSTEM:"21-9970-9971-02",SUBSYSTEM_DESCR:"HULL Machinery Space Supply Fans , Ducting and Dampers",WD_PLAN:"2021-11-18",A_OV_TOT:127,A_OV_COM:64,A_OV_REM:63,A_OV_PROG:50.39,A_OV_STATUS:"less than 70%",A_PG:50.39,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,ACT_TAG_VALID:122,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-9970-9971-03",SUBSYSTEM_DESCR:"HULL Machinery Space Exhaust Fans , Ducting and Dampers",WD_PLAN:"2021-11-18",A_OV_TOT:163,A_OV_COM:54,A_OV_REM:109,A_OV_PROG:33.12,A_OV_STATUS:"less than 70%",A_PG:33.12,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:9,PA_OV_COM:0,PA_OV_REM:9,PB1_OV_TOT:4,PB1_OV_COM:0,PB1_OV_REM:4,ACT_TAG_VALID:157,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-9970-9971-04",SUBSYSTEM_DESCR:"KSG Room Supply Fans, Ducting and Dampers",WD_PLAN:"2021-10-28",A_OV_TOT:184,A_OV_COM:38,A_OV_REM:146,A_OV_PROG:20.65,A_OV_STATUS:"less than 70%",A_PG:20.65,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,ACT_TAG_VALID:167,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9970-9971-05",SUBSYSTEM_DESCR:"KSG Room Exhaust Fans, Ducting and Dampers",WD_PLAN:"2021-10-28",A_OV_TOT:80,A_OV_COM:29,A_OV_REM:51,A_OV_PROG:36.25,A_OV_STATUS:"less than 70%",A_PG:36.25,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,ACT_TAG_VALID:78,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-9970-9971-06",SUBSYSTEM_DESCR:"HULL Chilled Water System and UCP",WD_PLAN:"2021-12-09",A_OV_TOT:660,A_OV_COM:180,A_OV_REM:480,A_OV_PROG:27.27,A_OV_STATUS:"less than 70%",A_PG:27.27,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:17,PA_OV_COM:0,PA_OV_REM:17,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:476,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:8},
    {SUBSYSTEM:"21-9970-9971-07",SUBSYSTEM_DESCR:"HULL Chiller Exhaust Fans, Ducting and Dampers",WD_PLAN:"2021-11-04",A_OV_TOT:59,A_OV_COM:9,A_OV_REM:50,A_OV_PROG:15.25,A_OV_STATUS:"less than 70%",A_PG:15.25,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,ACT_TAG_VALID:58,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9970-9971-08",SUBSYSTEM_DESCR:"HULL Machinery Space AHU Air Supply",WD_PLAN:"2021-11-04",A_OV_TOT:423,A_OV_COM:81,A_OV_REM:342,A_OV_PROG:19.14,A_OV_STATUS:"less than 70%",A_PG:19.14,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:11,PA_OV_COM:2,PA_OV_REM:9,PB1_OV_TOT:7,PB1_OV_COM:0,PB1_OV_REM:7,ACT_TAG_VALID:379,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:6},
    {SUBSYSTEM:"21-9970-9971-09",SUBSYSTEM_DESCR:"HULL Machinery Space AHU Recirculation Air",WD_PLAN:"2021-11-04",A_OV_TOT:227,A_OV_COM:66,A_OV_REM:161,A_OV_PROG:29.07,A_OV_STATUS:"less than 70%",A_PG:29.07,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:4,PB1_OV_COM:0,PB1_OV_REM:4,ACT_TAG_VALID:216,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-9970-9971-10",SUBSYSTEM_DESCR:"HULL Diesel Oil Pump Room Supply Fans, Ducting and Dampers",WD_PLAN:"2021-10-21",A_OV_TOT:81,A_OV_COM:27,A_OV_REM:54,A_OV_PROG:33.33,A_OV_STATUS:"less than 70%",A_PG:33.33,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:4,PB1_OV_COM:0,PB1_OV_REM:4,ACT_TAG_VALID:73,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-9970-9971-11",SUBSYSTEM_DESCR:"HULL Diesel Oil Pump Room Exhaust Fans, Ducting and Dampers",WD_PLAN:"2021-09-30",A_OV_TOT:72,A_OV_COM:24,A_OV_REM:48,A_OV_PROG:33.33,A_OV_STATUS:"less than 70%",A_PG:33.33,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:5,PB1_OV_COM:0,PB1_OV_REM:5,ACT_TAG_VALID:67,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-9970-9971-12",SUBSYSTEM_DESCR:"HULL Machinery Space HPU AHU, Ducting and Damper",WD_PLAN:"2021-11-04",A_OV_TOT:117,A_OV_COM:59,A_OV_REM:58,A_OV_PROG:50.42,A_OV_STATUS:"less than 70%",A_PG:50.42,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:5,PA_OV_COM:0,PA_OV_REM:5,PB1_OV_TOT:8,PB1_OV_COM:0,PB1_OV_REM:8,ACT_TAG_VALID:113,PUNCH_NUMBER_VALID:13,PUNCH_TAG_VALID:9},
    {SUBSYSTEM:"21-9976-9976-01",SUBSYSTEM_DESCR:"Topsides Emergency Pressurisation Fans",WD_PLAN:"2021-11-25",A_OV_TOT:76,A_OV_COM:3,A_OV_REM:73,A_OV_PROG:3.94,A_OV_STATUS:"less than 70%",A_PG:3.94,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:4,PB1_OV_COM:3,PB1_OV_REM:1,ACT_TAG_VALID:58,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-9976-9976-02",SUBSYSTEM_DESCR:"Topsides Battery Exhaust Fans, Ducting and Dampers",WD_PLAN:"2021-11-25",A_OV_TOT:67,A_OV_COM:2,A_OV_REM:65,A_OV_PROG:2.98,A_OV_STATUS:"less than 70%",A_PG:2.98,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PB1_OV_TOT:3,PB1_OV_COM:3,PB1_OV_REM:0,ACT_TAG_VALID:52,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9976-9976-03",SUBSYSTEM_DESCR:"Emergency Room ACUsandACCUs and UCPs",WD_PLAN:"2021-11-25",A_OV_TOT:58,A_OV_COM:1,A_OV_REM:57,A_OV_PROG:1.72,A_OV_STATUS:"less than 70%",A_PG:1.72,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:5,PA_OV_COM:3,PA_OV_REM:2,PB1_OV_TOT:2,PB1_OV_COM:1,PB1_OV_REM:1,ACT_TAG_VALID:48,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-9976-9977-01",SUBSYSTEM_DESCR:"Topsides HVAC UCPs, EDBs, VSDs",WD_PLAN:"2021-11-25",A_OV_TOT:36,A_OV_COM:0,A_OV_REM:36,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:5,PB1_OV_COM:0,PB1_OV_REM:5,ACT_TAG_VALID:36,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9976-9977-02",SUBSYSTEM_DESCR:"Topsides HVAC AHUs, Ducting and Dampers",WD_PLAN:"2021-11-25",A_OV_TOT:510,A_OV_COM:74,A_OV_REM:436,A_OV_PROG:14.5,A_OV_STATUS:"less than 70%",A_PG:14.5,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:6,PA_OV_COM:5,PA_OV_REM:1,PB1_OV_TOT:4,PB1_OV_COM:2,PB1_OV_REM:2,ACT_TAG_VALID:418,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-9976-9977-03",SUBSYSTEM_DESCR:"Topsides HVAC Extracts, Ducting and Dampers",WD_PLAN:"2021-11-25",A_OV_TOT:180,A_OV_COM:8,A_OV_REM:172,A_OV_PROG:4.44,A_OV_STATUS:"less than 70%",A_PG:4.44,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:150,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1}
    ],
  Queries: {
    SQL1: [
      { DESC: 'DataSource SPCS Data 03-Mar-20' }
    ],
    SQL2: [
      { CDATE: '2021-11-25' }
    ],
    // Data table & Line chart Data
    SQL3:  [
      {WD_PLAN:"2021-09-09",WK_NO:"W2021_36",PLAN_CUM:82643,ACTUAL_CUM:14398,REMAIN_CUM:68245,CUR_PLAN_CUM:719,CUR_ACTUAL_CUM:411,CUR_REMAIN_CUM:308,MC_TOTAL:568,MC_ACTUAL:0,PA_OV_REM:575,CUR_PA_OV_REM:68,PB1_OV_REM:309,PB2_OV_REM:19,PB3_OV_REM:1},
      {WD_PLAN:"2021-09-16",WK_NO:"W2021_37",PLAN_CUM:82830,ACTUAL_CUM:14631,REMAIN_CUM:68199,CUR_PLAN_CUM:1804,CUR_ACTUAL_CUM:822,CUR_REMAIN_CUM:982,MC_TOTAL:568,MC_ACTUAL:0,PA_OV_REM:586,CUR_PA_OV_REM:79,PB1_OV_REM:331,PB2_OV_REM:19,PB3_OV_REM:1},
      {WD_PLAN:"2021-09-23",WK_NO:"W2021_38",PLAN_CUM:83616,ACTUAL_CUM:15477,REMAIN_CUM:68139,CUR_PLAN_CUM:1804,CUR_ACTUAL_CUM:822,CUR_REMAIN_CUM:982,MC_TOTAL:568,MC_ACTUAL:0,PA_OV_REM:597,CUR_PA_OV_REM:79,PB1_OV_REM:364,PB2_OV_REM:19,PB3_OV_REM:1},
      {WD_PLAN:"2021-09-30",WK_NO:"W2021_39",PLAN_CUM:83971,ACTUAL_CUM:16776,REMAIN_CUM:67195,CUR_PLAN_CUM:3150,CUR_ACTUAL_CUM:1056,CUR_REMAIN_CUM:2094,MC_TOTAL:560,MC_ACTUAL:2,PA_OV_REM:578,CUR_PA_OV_REM:28,PB1_OV_REM:360,PB2_OV_REM:19,PB3_OV_REM:1},
      {WD_PLAN:"2021-10-07",WK_NO:"W2021_40",PLAN_CUM:84307,ACTUAL_CUM:16863,REMAIN_CUM:67444,CUR_PLAN_CUM:4354,CUR_ACTUAL_CUM:1475,CUR_REMAIN_CUM:2879,MC_TOTAL:560,MC_ACTUAL:2,PA_OV_REM:586,CUR_PA_OV_REM:51,PB1_OV_REM:587,PB2_OV_REM:33,PB3_OV_REM:1},
      {WD_PLAN:"2021-10-14",WK_NO:"W2021_41",PLAN_CUM:84725,ACTUAL_CUM:17620,REMAIN_CUM:67105,CUR_PLAN_CUM:11576,CUR_ACTUAL_CUM:1971,CUR_REMAIN_CUM:9605,MC_TOTAL:560,MC_ACTUAL:2,PA_OV_REM:596,CUR_PA_OV_REM:74,PB1_OV_REM:687,PB2_OV_REM:34,PB3_OV_REM:1},
      {WD_PLAN:"2021-10-21",WK_NO:"W2021_42",PLAN_CUM:84988,ACTUAL_CUM:18026,REMAIN_CUM:66962,CUR_PLAN_CUM:13476,CUR_ACTUAL_CUM:2491,CUR_REMAIN_CUM:10985,MC_TOTAL:560,MC_ACTUAL:3,PA_OV_REM:651,CUR_PA_OV_REM:165,PB1_OV_REM:701,PB2_OV_REM:34,PB3_OV_REM:1},
      {WD_PLAN:"2021-10-28",WK_NO:"W2021_43",PLAN_CUM:85425,ACTUAL_CUM:18960,REMAIN_CUM:66465,CUR_PLAN_CUM:15315,CUR_ACTUAL_CUM:2860,CUR_REMAIN_CUM:12455,MC_TOTAL:560,MC_ACTUAL:5,PA_OV_REM:723,CUR_PA_OV_REM:265,PB1_OV_REM:721,PB2_OV_REM:19,PB3_OV_REM:1},
      {WD_PLAN:"2021-11-04",WK_NO:"W2021_44",PLAN_CUM:85883,ACTUAL_CUM:19955,REMAIN_CUM:65928,CUR_PLAN_CUM:17019,CUR_ACTUAL_CUM:3603,CUR_REMAIN_CUM:13416,MC_TOTAL:560,MC_ACTUAL:8,PA_OV_REM:675,CUR_PA_OV_REM:267,PB1_OV_REM:734,PB2_OV_REM:19,PB3_OV_REM:1},
      {WD_PLAN:"2021-11-11",WK_NO:"W2021_45",PLAN_CUM:86618,ACTUAL_CUM:20409,REMAIN_CUM:66209,CUR_PLAN_CUM:18687,CUR_ACTUAL_CUM:3839,CUR_REMAIN_CUM:14848,MC_TOTAL:560,MC_ACTUAL:9,PA_OV_REM:695,CUR_PA_OV_REM:292,PB1_OV_REM:943,PB2_OV_REM:20,PB3_OV_REM:2},
      {WD_PLAN:"2021-11-18",WK_NO:"W2021_46",PLAN_CUM:87138,ACTUAL_CUM:21424,REMAIN_CUM:65714,CUR_PLAN_CUM:20583,CUR_ACTUAL_CUM:4514,CUR_REMAIN_CUM:16069,MC_TOTAL:560,MC_ACTUAL:9,PA_OV_REM:691,CUR_PA_OV_REM:286,PB1_OV_REM:1127,PB2_OV_REM:42,PB3_OV_REM:2},
      {WD_PLAN:"2021-11-25",WK_NO:"W2021_47",PLAN_CUM:87782,ACTUAL_CUM:22426,REMAIN_CUM:65356,CUR_PLAN_CUM:23888,CUR_ACTUAL_CUM:5313,CUR_REMAIN_CUM:18575,MC_TOTAL:560,MC_ACTUAL:10,PA_OV_REM:634,CUR_PA_OV_REM:237,PB1_OV_REM:1226,PB2_OV_REM:38,PB3_OV_REM:2},
      {WD_PLAN:"2021-12-02",WK_NO:"W2021_48"},
      {WD_PLAN:"2021-12-09",WK_NO:"W2021_49"},
      {WD_PLAN:"2021-12-16",WK_NO:"W2021_50"},
      {WD_PLAN:"2021-12-23",WK_NO:"W2021_51"},
      {WD_PLAN:"2021-12-30",WK_NO:"W2021_52"},
      {WD_PLAN:"2022-01-06",WK_NO:"W2022_01"},
      {WD_PLAN:"2022-01-13",WK_NO:"W2022_02"},
      {WD_PLAN:"2022-01-20",WK_NO:"W2022_03"},
      {WD_PLAN:"2022-01-27",WK_NO:"W2022_04"},
      {WD_PLAN:"2022-02-03",WK_NO:"W2022_05"},
      {WD_PLAN:"2022-02-10",WK_NO:"W2022_06"},
      {WD_PLAN:"2022-02-17",WK_NO:"W2022_07"},
      {WD_PLAN:"2022-02-24",WK_NO:"W2022_08"},
      {WD_PLAN:"2022-03-03",WK_NO:"W2022_09"},
      {WD_PLAN:"2022-03-10",WK_NO:"W2022_10"},
      {WD_PLAN:"2022-03-17",WK_NO:"W2022_11"},
      {WD_PLAN:"2022-03-24",WK_NO:"W2022_12"},
      {WD_PLAN:"2022-03-31",WK_NO:"W2022_13"},
      {WD_PLAN:"2022-04-07",WK_NO:"W2022_14"},
      {WD_PLAN:"2022-04-14",WK_NO:"W2022_15"},
      {WD_PLAN:"2022-04-21",WK_NO:"W2022_16"},
      {WD_PLAN:"2022-04-28",WK_NO:"W2022_17"},
      {WD_PLAN:"2022-05-05",WK_NO:"W2022_18"},
      {WD_PLAN:"2022-05-12",WK_NO:"W2022_19"},
      {WD_PLAN:"2022-05-19",WK_NO:"W2022_20"},
      {WD_PLAN:"2022-05-26",WK_NO:"W2022_21"},
      {WD_PLAN:"2022-06-02",WK_NO:"W2022_22"},
      {WD_PLAN:"2022-06-09",WK_NO:"W2022_23"},
      {WD_PLAN:"2022-06-16",WK_NO:"W2022_24"},
      {WD_PLAN:"2022-06-23",WK_NO:"W2022_25"},
      {WD_PLAN:"2022-06-30",WK_NO:"W2022_26"},
      {WD_PLAN:"2022-07-07",WK_NO:"W2022_27"},
      {WD_PLAN:"2022-07-14",WK_NO:"W2022_28"},
      {WD_PLAN:"2022-07-21",WK_NO:"W2022_29"},
      {WD_PLAN:"2022-07-28",WK_NO:"W2022_30"},
      {WD_PLAN:"2022-08-04",WK_NO:"W2022_31"},
      {WD_PLAN:"2022-08-11",WK_NO:"W2022_32"},
      {WD_PLAN:"2022-08-18",WK_NO:"W2022_33"}
      ],
    SQL4: [
      {TOTAL:577,NOTSTARTED:100,INPROGRESS:220,AITR_COMPLETED:170,RFI_ISSUED:299, WD_COMPLETED:200,MC_COMPLETED:250,MC_APPROVED:190,MC_REJECTED:300}
    ],
    SQL5: [
      {CUTOFF:"2021-08-05",WK_NO:"W2021_31",TOTAL:41334,REMAIN:37130},
      {CUTOFF:"2021-07-29",WK_NO:"W2021_30",TOTAL:41334,REMAIN:37583},
      {CUTOFF:"2021-08-12",WK_NO:"W2021_32",TOTAL:41334,REMAIN:36979},
      {CUTOFF:"2021-08-19",WK_NO:"W2021_33",TOTAL:41334,REMAIN:36663},
      {CUTOFF:"2021-08-26",WK_NO:"W2021_34",TOTAL:41334,REMAIN:36533},
      {CUTOFF:"2021-09-09",WK_NO:"W2021_36",TOTAL:40608,REMAIN:36321},
      {CUTOFF:"2021-09-02",WK_NO:"W2021_35",TOTAL:41334,REMAIN:36388},
      {CUTOFF:"2021-09-23",WK_NO:"W2021_38",TOTAL:39518,REMAIN:35211},
      {CUTOFF:"2021-09-16",WK_NO:"W2021_37",TOTAL:39518,REMAIN:35636},
      {CUTOFF:"2021-10-07",WK_NO:"W2021_40",TOTAL:37005,REMAIN:34289},
      {CUTOFF:"2021-10-21",WK_NO:"W2021_42",TOTAL:27744,REMAIN:33638},
      {CUTOFF:"2021-09-30",WK_NO:"W2021_39",TOTAL:38217,REMAIN:34561},
      {CUTOFF:"2021-11-04",WK_NO:"W2021_44",TOTAL:24331,REMAIN:32678},
      {CUTOFF:"2021-10-14",WK_NO:"W2021_41",TOTAL:29763,REMAIN:33922},
      {CUTOFF:"2021-11-18",WK_NO:"W2021_46",TOTAL:20697,REMAIN:31534},
      {CUTOFF:"2021-10-28",WK_NO:"W2021_43",TOTAL:25907,REMAIN:33301},
      {CUTOFF:"2021-12-02",WK_NO:"W2021_48",TOTAL:12530,REMAIN:30492},
      {CUTOFF:"2021-12-16",WK_NO:"W2021_50",TOTAL:5688,WKS_TREND:29732,'4WKS':506},
      {CUTOFF:"2021-12-30",WK_NO:"W2021_52",TOTAL:1717,WKS_TREND:28720,'4WKS':506},
      {CUTOFF:"2021-11-11",WK_NO:"W2021_45",TOTAL:22715,REMAIN:32099},
      {CUTOFF:"2021-11-25",WK_NO:"W2021_47",TOTAL:17604,REMAIN:31018},
      {CUTOFF:"2021-12-09",WK_NO:"W2021_49",TOTAL:7970,REMAIN:30238,WKS_TREND:30238},
      {CUTOFF:"2021-12-23",WK_NO:"W2021_51",TOTAL:3763,WKS_TREND:29226,'4WKS':506},
      {CUTOFF:"2022-01-06",WK_NO:"W2022_01",TOTAL:0,WKS_TREND:28214,'4WKS':506}
    ]
  }
}