import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 404,
      CanvasHeight: 150,
      CanvasBorderWeight: 1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 300,
      CanvasChartHeight: 300,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#fff',
      CanvasChartFillColor: '#fff',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },


    DataItems: [
      { NO: '36', RFS: '2020', MOD: '42M078', PAU: 'M078-A', CERT: 'RFSC', SHIP: 'Okpo-27', SHIP_NAME: 'CY2', SHIP_DATE: '2019-11-27', LO_PLAN: '2019-12-14', LO_ACTUAL: '2019-12-14', SA_PLAN: '2019-12-21', SA_ACTUAL: '2019-12-21', },
      { NO: '31', RFS: '2020', MOD: '42M078', PAU: 'M078-B', CERT: 'RFSC', SHIP: 'Okpo-27', SHIP_NAME: 'CY2', SHIP_DATE: '2019-11-27', LO_PLAN: '2019-12-13', LO_ACTUAL: '2019-12-13', SA_PLAN: '2019-12-21', SA_ACTUAL: '2019-12-21', },
      { NO: '33', RFS: '2020', MOD: '41M008', PAU: 'M008-A', CERT: 'RFSC', SHIP: 'Okpo-28', SHIP_NAME: 'KES2', SHIP_DATE: '2019-12-20', LO_PLAN: '2019-12-24', LO_ACTUAL: '2019-12-24', SA_PLAN: '2019-12-30', SA_ACTUAL: '2019-12-30', },
      { NO: '8', RFS: '2020', MOD: '41M008', PAU: 'M008-B', CERT: 'RFSC', SHIP: 'Okpo-28', SHIP_NAME: 'KES2', SHIP_DATE: '2019-12-20', LO_PLAN: '2019-12-23', LO_ACTUAL: '2019-12-23', SA_PLAN: '2019-12-30', SA_ACTUAL: '2019-12-30', },
      { NO: '12', RFS: '2020', MOD: '42M057', PAU: 'M057-A', CERT: 'RFSC', SHIP: 'Okpo-28', SHIP_NAME: 'KES2', SHIP_DATE: '2019-12-20', LO_PLAN: '2019-12-22', LO_ACTUAL: '2019-12-22', SA_PLAN: '2019-12-30', SA_ACTUAL: '2019-12-30', },
      { NO: '43', RFS: '2020', MOD: '42M057', PAU: 'M057-B', CERT: 'RFSC', SHIP: 'Okpo-29', SHIP_NAME: 'HJP', SHIP_DATE: '2019-12-25', LO_PLAN: '2019-12-26', LO_ACTUAL: '2019-12-26', SA_PLAN: '2019-12-31', SA_ACTUAL: '2019-12-31', },
      { NO: '52', RFS: '2020', MOD: '42M057', PAU: 'M057-C', CERT: 'RFSC', SHIP: 'Okpo-29', SHIP_NAME: 'HJP', SHIP_DATE: '2019-12-25', LO_PLAN: '2019-12-27', LO_ACTUAL: '2019-12-27', SA_PLAN: '2019-12-31', SA_ACTUAL: '2019-12-31', },
      { NO: '1', RFS: '2020', MOD: '42M065', PAU: 'M065-A', CERT: 'RFSC', SHIP: 'Okpo-30', SHIP_NAME: 'XHK', SHIP_DATE: '2020-01-12', LO_PLAN: '2020-01-15', LO_ACTUAL: '2020-01-15', SA_PLAN: '2020-01-21', SA_ACTUAL: '2020-01-21', },
      { NO: '17', RFS: '2020', MOD: '42M075', PAU: 'M075-A', CERT: 'RFSC', SHIP: 'Okpo-30', SHIP_NAME: 'XHK', SHIP_DATE: '2020-01-12', LO_PLAN: '2020-01-15', LO_ACTUAL: '2020-01-15', SA_PLAN: '2020-01-21', SA_ACTUAL: '2020-01-21', },
      { NO: '5', RFS: '2020', MOD: '42M075', PAU: 'M075-B', CERT: 'RFSC', SHIP: 'Okpo-30', SHIP_NAME: 'XHK', SHIP_DATE: '2020-01-12', LO_PLAN: '2020-01-14', LO_ACTUAL: '2020-01-14', SA_PLAN: '2020-01-21', SA_ACTUAL: '2020-01-21', },
      { NO: '14', RFS: '2020', MOD: '42M075', PAU: 'M075-C', CERT: 'RFSC', SHIP: 'Okpo-30', SHIP_NAME: 'XHK', SHIP_DATE: '2020-01-12', LO_PLAN: '2020-01-14', LO_ACTUAL: '2020-01-14', SA_PLAN: '2020-01-21', SA_ACTUAL: '2020-01-21', },
      { NO: '56', RFS: '2020', MOD: '41M010', PAU: 'M010-A', CERT: 'RFSC', SHIP: 'Okpo-31', SHIP_NAME: 'YMTO', SHIP_DATE: '2020-01-09', LO_PLAN: '2020-01-11', LO_ACTUAL: '2020-01-11', SA_PLAN: '2020-01-22', SA_ACTUAL: '2020-01-22', },
      { NO: '42', RFS: '2020', MOD: '41M010', PAU: 'M010-B', CERT: 'RFSC', SHIP: 'Okpo-31', SHIP_NAME: 'YMTO', SHIP_DATE: '2020-01-09', LO_PLAN: '2020-01-10', LO_ACTUAL: '2020-01-10', SA_PLAN: '2020-01-22', SA_ACTUAL: '2020-01-22', },
      { NO: '34', RFS: '2020', MOD: '42M065', PAU: 'M065-B', CERT: 'RFSC', SHIP: 'Okpo-31', SHIP_NAME: 'YMTO', SHIP_DATE: '2020-01-16', LO_PLAN: '2020-01-18', LO_ACTUAL: '2020-01-18', SA_PLAN: '2020-01-22', SA_ACTUAL: '2020-01-22', },
      { NO: '41', RFS: '2020', MOD: '42M065', PAU: 'M065-C', CERT: 'RFSC', SHIP: 'Okpo-31', SHIP_NAME: 'YMTO', SHIP_DATE: '2020-01-16', LO_PLAN: '2020-01-17', LO_ACTUAL: '2020-01-17', SA_PLAN: '2020-01-22', SA_ACTUAL: '2020-01-22', },
      { NO: '23', RFS: '2020', MOD: '42M054', PAU: 'M054-C', CERT: 'RFD', SHIP: 'Okpo-32', SHIP_NAME: 'AB7CD8', SHIP_DATE: '2020-01-29', LO_PLAN: '2020-01-31', LO_ACTUAL: null, SA_PLAN: '2020-02-07', SA_ACTUAL: null, },
      { NO: '44', RFS: '2020', MOD: '42M056', PAU: 'M056-B', CERT: 'IN PROGRESS', SHIP: 'Okpo-32', SHIP_NAME: 'AB7CD8', SHIP_DATE: '2020-01-29', LO_PLAN: '2020-01-30', LO_ACTUAL: null, SA_PLAN: '2020-02-07', SA_ACTUAL: null, },
      { NO: '16', RFS: '2020', MOD: '41M011', PAU: 'M011-A', CERT: 'RFL', SHIP: 'Okpo-33', SHIP_NAME: 'TAK', SHIP_DATE: '2020-02-01', LO_PLAN: '2020-02-04', LO_ACTUAL: null, SA_PLAN: '2020-02-11', SA_ACTUAL: null, },
      { NO: '20', RFS: '2020', MOD: '41M011', PAU: 'M011-B', CERT: 'RFL', SHIP: 'Okpo-33', SHIP_NAME: 'TAK', SHIP_DATE: '2020-02-01', LO_PLAN: '2020-02-04', LO_ACTUAL: null, SA_PLAN: '2020-02-11', SA_ACTUAL: null, },
      { NO: '6', RFS: '2020', MOD: '42M056', PAU: 'M056-A', CERT: 'IN PROGRESS', SHIP: 'Okpo-33', SHIP_NAME: 'TAK', SHIP_DATE: '2020-02-01', LO_PLAN: '2020-02-03', LO_ACTUAL: null, SA_PLAN: '2020-02-11', SA_ACTUAL: null, },
      { NO: '54', RFS: '2020', MOD: '42M056', PAU: 'M056-C', CERT: 'IN PROGRESS', SHIP: 'Okpo-33', SHIP_NAME: 'TAK', SHIP_DATE: '2020-02-01', LO_PLAN: '2020-02-03', LO_ACTUAL: null, SA_PLAN: '2020-02-11', SA_ACTUAL: null, },
      { NO: '3', RFS: '2020', MOD: '42M055', PAU: 'M055-B', CERT: 'RFD', SHIP: 'Okpo-34', SHIP_NAME: 'DEB2', SHIP_DATE: '2020-02-08', LO_PLAN: '2020-02-11', LO_ACTUAL: null, SA_PLAN: '2020-02-18', SA_ACTUAL: null, },
      { NO: '13', RFS: '2020', MOD: '42M055', PAU: 'M055-C', CERT: 'RFD', SHIP: 'Okpo-34', SHIP_NAME: 'DEB2', SHIP_DATE: '2020-02-08', LO_PLAN: '2020-02-10', LO_ACTUAL: null, SA_PLAN: '2020-02-18', SA_ACTUAL: null, },
      { NO: '35', RFS: '2020', MOD: '41M009', PAU: 'M009-A', CERT: 'IN PROGRESS', SHIP: 'Okpo-35', SHIP_NAME: 'KSK', SHIP_DATE: '2020-02-13', LO_PLAN: '2020-02-15', LO_ACTUAL: null, SA_PLAN: '2020-02-22', SA_ACTUAL: null, },
      { NO: '49', RFS: '2020', MOD: '41M009', PAU: 'M009-B', CERT: 'IN PROGRESS', SHIP: 'Okpo-35', SHIP_NAME: 'KSK', SHIP_DATE: '2020-02-13', LO_PLAN: '2020-02-14', LO_ACTUAL: null, SA_PLAN: '2020-02-22', SA_ACTUAL: null, },
      { NO: '4', RFS: '2020', MOD: '42M054', PAU: 'M054-A', CERT: 'RFD', SHIP: 'Okpo-36', SHIP_NAME: 'ZYK', SHIP_DATE: '2020-02-21', LO_PLAN: '2020-02-22', LO_ACTUAL: null, SA_PLAN: '2020-03-01', SA_ACTUAL: null, },
      { NO: '30', RFS: '2020', MOD: '42M054', PAU: 'M054-B', CERT: 'RFD', SHIP: 'Okpo-36', SHIP_NAME: 'ZYK', SHIP_DATE: '2020-02-21', LO_PLAN: '2020-02-22', LO_ACTUAL: null, SA_PLAN: '2020-03-01', SA_ACTUAL: null, },
      { NO: '51', RFS: '2020', MOD: '42M055', PAU: 'M055-A', CERT: 'RFD', SHIP: 'Okpo-36', SHIP_NAME: 'ZYK', SHIP_DATE: '2020-02-21', LO_PLAN: '2020-02-23', LO_ACTUAL: null, SA_PLAN: '2020-03-01', SA_ACTUAL: null, },
      { NO: '24', RFS: '2020', MOD: '47M265', PAU: 'M265-A', CERT: 'NOT STARTED', SHIP: 'Okpo-37', SHIP_NAME: 'XYK', SHIP_DATE: '2020-02-26', LO_PLAN: '2020-03-01', LO_ACTUAL: null, SA_PLAN: '2020-03-08', SA_ACTUAL: null, },
      { NO: '58', RFS: '2020', MOD: '41M801', PAU: 'M801-A', CERT: 'IN PROGRESS', SHIP: 'Okpo-37', SHIP_NAME: 'XYK', SHIP_DATE: '2020-02-26', LO_PLAN: '2020-03-01', LO_ACTUAL: null, SA_PLAN: '2020-03-08', SA_ACTUAL: null, },
      { NO: '15', RFS: '2020', MOD: '41M801', PAU: 'M801-B', CERT: 'IN PROGRESS', SHIP: 'Okpo-37', SHIP_NAME: 'XYK', SHIP_DATE: '2020-02-26', LO_PLAN: '2020-02-29', LO_ACTUAL: null, SA_PLAN: '2020-03-08', SA_ACTUAL: null, },
      { NO: '9', RFS: '2020', MOD: '41M801', PAU: 'M801-C', CERT: 'IN PROGRESS', SHIP: 'Okpo-37', SHIP_NAME: 'XYK', SHIP_DATE: '2020-02-26', LO_PLAN: '2020-02-29', LO_ACTUAL: null, SA_PLAN: '2020-03-08', SA_ACTUAL: null, },
      { NO: '46', RFS: '2020', MOD: '41M007', PAU: 'M007-A', CERT: 'IN PROGRESS', SHIP: 'Okpo-38', SHIP_NAME: 'DBG3', SHIP_DATE: '2020-03-16', LO_PLAN: '2020-03-18', LO_ACTUAL: null, SA_PLAN: '2020-03-26', SA_ACTUAL: null, },
      { NO: '26', RFS: '2020', MOD: '41M007', PAU: 'M007-B', CERT: 'IN PROGRESS', SHIP: 'Okpo-38', SHIP_NAME: 'DBG3', SHIP_DATE: '2020-03-16', LO_PLAN: '2020-03-17', LO_ACTUAL: null, SA_PLAN: '2020-03-26', SA_ACTUAL: null, },
      { NO: '48', RFS: '2020', MOD: '47M266', PAU: 'M266-A', CERT: 'NOT STARTED', SHIP: 'Okpo-38', SHIP_NAME: 'DBG3', SHIP_DATE: '2020-03-16', LO_PLAN: '2020-03-19', LO_ACTUAL: null, SA_PLAN: '2020-03-26', SA_ACTUAL: null, },
      { NO: '53', RFS: '2020', MOD: '41M006', PAU: 'M006-A', CERT: 'IN PROGRESS', SHIP: 'Okpo-39', SHIP_NAME: 'XRK', SHIP_DATE: '2020-03-24', LO_PLAN: '2020-03-28', LO_ACTUAL: null, SA_PLAN: '2020-04-12', SA_ACTUAL: null, },
      { NO: '21', RFS: '2020', MOD: '41M006', PAU: 'M006-B', CERT: 'IN PROGRESS', SHIP: 'Okpo-39', SHIP_NAME: 'XRK', SHIP_DATE: '2020-03-24', LO_PLAN: '2020-03-28', LO_ACTUAL: null, SA_PLAN: '2020-04-12', SA_ACTUAL: null, },
      { NO: '28', RFS: '2020', MOD: '41M006', PAU: 'M006-C', CERT: 'IN PROGRESS', SHIP: 'Okpo-39', SHIP_NAME: 'XRK', SHIP_DATE: '2020-03-24', LO_PLAN: '2020-03-27', LO_ACTUAL: null, SA_PLAN: '2020-04-12', SA_ACTUAL: null, },
      { NO: '10', RFS: '2020', MOD: '47M268', PAU: 'M268-A', CERT: 'NOT STARTED', SHIP: 'Okpo-39', SHIP_NAME: 'XRK', SHIP_DATE: '2020-03-24', LO_PLAN: '2020-03-27', LO_ACTUAL: null, SA_PLAN: '2020-04-12', SA_ACTUAL: null, },
      { NO: '11', RFS: '2020', MOD: '41M001', PAU: 'M001-B', CERT: 'NOT STARTED', SHIP: 'Okpo-40', SHIP_NAME: 'XHK', SHIP_DATE: '2020-05-11', LO_PLAN: '2020-05-14', LO_ACTUAL: null, SA_PLAN: '2020-05-21', SA_ACTUAL: null, },
      { NO: '2', RFS: '2020', MOD: '41M001', PAU: 'M001-C', CERT: 'NOT STARTED', SHIP: 'Okpo-40', SHIP_NAME: 'XHK', SHIP_DATE: '2020-05-11', LO_PLAN: '2020-05-14', LO_ACTUAL: null, SA_PLAN: '2020-05-21', SA_ACTUAL: null, },
      { NO: '50', RFS: '2020', MOD: '47M267', PAU: 'M267-A', CERT: 'NOT STARTED', SHIP: 'Okpo-40', SHIP_NAME: 'XHK', SHIP_DATE: '2020-05-11', LO_PLAN: '2020-05-13', LO_ACTUAL: null, SA_PLAN: '2020-05-21', SA_ACTUAL: null, },
      { NO: '22', RFS: '2020', MOD: '47M267', PAU: 'M267-B', CERT: 'NOT STARTED', SHIP: 'Okpo-40', SHIP_NAME: 'XHK', SHIP_DATE: '2020-05-11', LO_PLAN: '2020-05-13', LO_ACTUAL: null, SA_PLAN: '2020-05-21', SA_ACTUAL: null, },
      { NO: '27', RFS: '2020', MOD: '41M004', PAU: 'M004-B', CERT: 'NOT STARTED', SHIP: 'Okpo-42', SHIP_NAME: 'CY1', SHIP_DATE: '2020-05-19', LO_PLAN: '2020-05-23', LO_ACTUAL: null, SA_PLAN: '2020-05-29', SA_ACTUAL: null, },
      { NO: '18', RFS: '2020', MOD: '41M004', PAU: 'M004-C', CERT: 'NOT STARTED', SHIP: 'Okpo-42', SHIP_NAME: 'CY1', SHIP_DATE: '2020-05-19', LO_PLAN: '2020-05-22', LO_ACTUAL: null, SA_PLAN: '2020-05-29', SA_ACTUAL: null, },
      { NO: '40', RFS: '2020', MOD: '41M001', PAU: 'M001-A', CERT: 'NOT STARTED', SHIP: 'Okpo-41', SHIP_NAME: 'CY2', SHIP_DATE: '2020-05-25', LO_PLAN: '2020-05-28', LO_ACTUAL: null, SA_PLAN: '2020-06-04', SA_ACTUAL: null, },
      { NO: '45', RFS: '2020', MOD: '41M004', PAU: 'M004-A', CERT: 'NOT STARTED', SHIP: 'Okpo-41', SHIP_NAME: 'CY2', SHIP_DATE: '2020-05-25', LO_PLAN: '2020-05-27', LO_ACTUAL: null, SA_PLAN: '2020-06-04', SA_ACTUAL: null, },
      { NO: '47', RFS: '2020', MOD: '41M003', PAU: 'M003-B', CERT: 'NOT STARTED', SHIP: 'Okpo-43', SHIP_NAME: 'ZYK', SHIP_DATE: '2020-06-05', LO_PLAN: '2020-06-09', LO_ACTUAL: null, SA_PLAN: '2020-06-16', SA_ACTUAL: null, },
      { NO: '55', RFS: '2020', MOD: '41M003', PAU: 'M003-C', CERT: 'NOT STARTED', SHIP: 'Okpo-43', SHIP_NAME: 'ZYK', SHIP_DATE: '2020-06-05', LO_PLAN: '2020-06-08', LO_ACTUAL: null, SA_PLAN: '2020-06-16', SA_ACTUAL: null, },
      { NO: '39', RFS: '2020', MOD: '47M261', PAU: 'M261-B', CERT: 'NOT STARTED', SHIP: 'Okpo-43', SHIP_NAME: 'ZYK', SHIP_DATE: '2020-06-05', LO_PLAN: '2020-06-08', LO_ACTUAL: null, SA_PLAN: '2020-06-16', SA_ACTUAL: null, },
      { NO: '32', RFS: '2020', MOD: '47M261', PAU: 'M261-C', CERT: 'NOT STARTED', SHIP: 'Okpo-43', SHIP_NAME: 'ZYK', SHIP_DATE: '2020-06-05', LO_PLAN: '2020-06-07', LO_ACTUAL: null, SA_PLAN: '2020-06-16', SA_ACTUAL: null, },
      { NO: '29', RFS: '2020', MOD: '47M261', PAU: 'M261-A', CERT: 'NOT STARTED', SHIP: 'Okpo-44', SHIP_NAME: 'AB7CD8', SHIP_DATE: '2020-06-17', LO_PLAN: '2020-06-20', LO_ACTUAL: null, SA_PLAN: '2020-06-30', SA_ACTUAL: null, },
      { NO: '57', RFS: '2020', MOD: '47M262', PAU: 'M262-A', CERT: 'NOT STARTED', SHIP: 'Okpo-44', SHIP_NAME: 'AB7CD8', SHIP_DATE: '2020-06-17', LO_PLAN: '2020-06-23', LO_ACTUAL: null, SA_PLAN: '2020-06-30', SA_ACTUAL: null, },
      { NO: '19', RFS: '2020', MOD: '47M262', PAU: 'M262-B', CERT: 'NOT STARTED', SHIP: 'Okpo-44', SHIP_NAME: 'AB7CD8', SHIP_DATE: '2020-06-17', LO_PLAN: '2020-06-21', LO_ACTUAL: null, SA_PLAN: '2020-06-30', SA_ACTUAL: null, },
      { NO: '25', RFS: '2020', MOD: '47M262', PAU: 'M262-C', CERT: 'NOT STARTED', SHIP: 'Okpo-44', SHIP_NAME: 'AB7CD8', SHIP_DATE: '2020-06-17', LO_PLAN: '2020-06-22', LO_ACTUAL: null, SA_PLAN: '2020-06-30', SA_ACTUAL: null, },
      { NO: '38', RFS: '2020', MOD: '41M003', PAU: 'M003-A', CERT: 'NOT STARTED', SHIP: 'Okpo-45', SHIP_NAME: 'XYK', SHIP_DATE: '2020-06-24', LO_PLAN: '2020-06-26', LO_ACTUAL: null, SA_PLAN: '2020-07-04', SA_ACTUAL: null, },
      { NO: '7', RFS: '2020', MOD: '47M263', PAU: 'M263-A', CERT: 'NOT STARTED', SHIP: 'Okpo-45', SHIP_NAME: 'XYK', SHIP_DATE: '2020-06-24', LO_PLAN: '2020-06-27', LO_ACTUAL: null, SA_PLAN: '2020-07-04', SA_ACTUAL: null, },
      { NO: '37', RFS: '2020', MOD: '47M263', PAU: 'M263-B', CERT: 'NOT STARTED', SHIP: 'Okpo-45', SHIP_NAME: 'XYK', SHIP_DATE: '2020-06-24', LO_PLAN: '2020-06-27', LO_ACTUAL: null, SA_PLAN: '2020-07-04', SA_ACTUAL: null, },

    ]
}