import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 600,
      CanvasHeight: 250,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     

    DataItems: [
      {TYPE: 'ORCs', TOTAL: 1444,    ACTUAL: 210,    REMAIN: 1234,   PROG: 75.5, },
    ],


    Queries: {
      SQL1: [
        { NO: 1,   RTP: 'Battery Autonomy Tes',                                                         FORECAST: '2018-05-05',},
        { NO: 2,   RTP: 'Fast Rescue Boat & Davit weight Tes',                                          FORECAST: '2018-05-05',},
        { NO: 3,   RTP: 'LQ Elevator',                                                                  FORECAST: '2018-05-05',},
        { NO: 5,   RTP: 'Nav-Aids & Fog Horn 24V DC Battery Charger System VBCE 651001 & VBCE-651002',  FORECAST: '2018-05-05',},
        { NO: 4,   RTP: 'Hull Columns Elevators',                                                       FORECAST: '2018-05-05',},
        
      ],

    },
}