import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 900,
      CanvasHeight: 330,
      CanvasBorderWeight: 0.5,
      CanvasBorderColor: '#757575',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },

    DataItems: [
      {GWBS_LV1:"TOPSIDES",WK_NO:"W2022_09",CUTOFF:"2022-03-03",PLAN:786,ACTUAL:1071,COSCO:1038},
      {GWBS_LV1:"TOPSIDES",WK_NO:"W2022_10",CUTOFF:"2022-03-10",PLAN:786,ACTUAL:1082,COSCO:1060},
      {GWBS_LV1:"TOPSIDES",WK_NO:"W2022_11",CUTOFF:"2022-03-17",PLAN:786,COSCO:1084},
      {GWBS_LV1:"TOPSIDES",WK_NO:"W2022_12",CUTOFF:"2022-03-24",PLAN:786,COSCO:1083},
      {GWBS_LV1:"TOPSIDES",WK_NO:"W2022_13",CUTOFF:"2022-03-31",PLAN:786,COSCO:1089},
      {GWBS_LV1:"TOPSIDES",WK_NO:"W2022_14",CUTOFF:"2022-04-07",PLAN:741,COSCO:1039},
      {GWBS_LV1:"TOPSIDES",WK_NO:"W2022_15",CUTOFF:"2022-04-14",PLAN:741,COSCO:1005},
      {GWBS_LV1:"TOPSIDES",WK_NO:"W2022_16",CUTOFF:"2022-04-21",PLAN:741,COSCO:992},
      {GWBS_LV1:"TOPSIDES",WK_NO:"W2022_17",CUTOFF:"2022-04-28",PLAN:741,COSCO:991}
    ],

}