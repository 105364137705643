import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 400,
      CanvasHeight: 200,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: '', x: 20, y: 0, font: 'roboto', class: 'regular', size: 13, color: '#000', align: 'start', direction: 'Horizontal' },
      ],
      Filter: [],
      Sql: []
    },
    DataItems: [
      {title:"SH No.",value:"21-1090"},
      {title:"SH Description",value:"Production control umbilical"},
      {title:"RFDC No.",value:"21-1090-1090"},
      {title:"RFDC Description",value:"Production control umbilical"},
      {title:"RFDC Plan",value:"2022-07-28"},
      {title:"RFDC Actual",value:"TEST"},
      {title:"RFDC Status",value:"Not Started"}
    ],
    
}