import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 500,
      CanvasHeight: 800,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Weight : ', x:240, y: -20, font: 'roboto', class: 'regular', size: 11, color: '#BCBCBC', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: 240, y: -20, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'end' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: 240,
          y: -20,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'end'
        },
      ]
    },
    DataItems: [
      // {GWBS_LV3:"IG90",WCCODE:"13820",DISC_DESCR:"Piping",ITEM:"Support",TOTAL:3107,COMPLETED:110,REMAIN:3107,PROG:100},
      // {GWBS_LV3:"IG90",WCCODE:"13420",DISC_DESCR:"Piping",ITEM:"Spool",UOM:"DIA",TOTAL:1642,COMPLETED:0,REMAIN:1642,PROG:70},
      // {GWBS_LV3:"IG90",WCCODE:"15120",DISC_DESCR:"Instrument",ITEM:"Cable Pulling",UOM:"LM",TOTAL:19500,COMPLETED:0,REMAIN:19500,PROG:30},
      // {GWBS_LV3:"IG90",WCCODE:"15130",DISC_DESCR:"Instrument",ITEM:"Cable Termination",UOM:"U",TOTAL:152,COMPLETED:0,REMAIN:152,PROG:0} , 
    
      { CATEGORY: 'Piling', BIDSECTION: 'S1', TOTAL: 1, COMPLETE: 1, CONTRACTOR: 'Zhongji Construction', },
      { CATEGORY: 'Piling', BIDSECTION: 'S2', TOTAL: 27, COMPLETE: 27, CONTRACTOR: 'Chemical Geotech', },
      { CATEGORY: 'Piling', BIDSECTION: 'S3', TOTAL: 6, COMPLETE: 6, CONTRACTOR: 'Wuhan Surveying-Geotech', },
      { CATEGORY: 'Piling', BIDSECTION: 'S4', TOTAL: 15, COMPLETE: 15, CONTRACTOR: 'Beijing New Oriental ', },
      { CATEGORY: 'Piling', BIDSECTION: 'S5', TOTAL: 9, COMPLETE: 9, CONTRACTOR: 'Zhejiang Emax ', },
      { CATEGORY: 'Piling', BIDSECTION: 'S6', TOTAL: 1, COMPLETE: 1, CONTRACTOR: 'Beijing New Oriental ', },
      { CATEGORY: 'Piling', BIDSECTION: 'S7', TOTAL: 1, COMPLETE: 1, CONTRACTOR: 'Zhongkan Metallurgical ', },
      { CATEGORY: 'Piling', BIDSECTION: 'S8', TOTAL: 1, COMPLETE: 1, CONTRACTOR: 'North Industries', },
      { CATEGORY: 'Piling Test', BIDSECTION: 'S1', TOTAL: 16, COMPLETE: 16, CONTRACTOR: 'Baoding Shihua', },
      { CATEGORY: 'Piling Test', BIDSECTION: 'S2', TOTAL: 35, COMPLETE: 35, CONTRACTOR: 'Baoding Jingcheng', },
      { CATEGORY: 'Piling Test', BIDSECTION: 'S3', TOTAL: 11, COMPLETE: 11, CONTRACTOR: 'Hubei MCC', },
    ],
}