import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 600,
      CanvasHeight: 200,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     

    DataItems: [
      { SYSTEM: 'BallastSystem',     TOTAL: 33, ACTUAL: 22,},
      { SYSTEM: 'FireWater System',  TOTAL: 33, ACTUAL: 22,},
      { SYSTEM: 'Water Mist',        TOTAL: 33, ACTUAL: 33,},
      { SYSTEM: 'Helideck Form',     TOTAL: 33, ACTUAL: 22,}
    ],

}