import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 700,
    CanvasHeight: 200,
    CanvasBorderWeight: 0.1,
    CanvasBorderColor: '#e0e0e0',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.2,

    CanvasChartAreaAllowed: 'Y',
    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 760,
    CanvasChartHeight: 240,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: 'Transparent',
    CanvasChartFillColor: 'Transparent',
    CanvasChartOpacity: 0.02,
    CanvasChartSpace: 20,

    CanvasFontFamily: 'roboto'
  },
  Text: {
    Text: [
      { text: '', x:20, y: 10, font: 'roboto', class: 'regular', size: 12, color: '#424242', align: 'start' },
    ],
    Filter: [
      { name: 'MOD'   , x: -50, y: 20, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'start' },
    ],
    Sql: [
      {
        query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
        text: 'Data Source',
        x: -30,
        y: 20,
        font: 'roboto',
        style: 'regular',
        size: 11,
        color: '#44A9DF',
        align: 'start'
      },
    ]
  },
  Queries: {
    SQL1: [
      // {TOTAL:18567,COMPLETE:6091,REMAIN:12476,PROG:32.8}
    ],
    SQL2:  [
      {WK_NO:"W2022_23"}
    ],
  },
  DataItems: [
    {STEP_STATUS:"ADM-01 Mar",ACT_WK:1,CAUTION_WK:0,REJECT_WK:0},
    {STEP_STATUS:"ADM-22 Feb",ACT_WK:1,CAUTION_WK:0,REJECT_WK:0},
    {STEP_STATUS:"ADM-15 Feb",ACT_WK:1,CAUTION_WK:0,REJECT_WK:1},
    {STEP_STATUS:"ADM-01 Feb",ACT_WK:1,CAUTION_WK:0,REJECT_WK:0},
    {STEP_STATUS:"CIV-29 Mar",ACT_WK:12,CAUTION_WK:2,REJECT_WK:0},
    {STEP_STATUS:"CIV-22 Mar",ACT_WK:19,CAUTION_WK:3,REJECT_WK:0},
    {STEP_STATUS:"CIV-15 Mar",ACT_WK:15,CAUTION_WK:1,REJECT_WK:0},
    {STEP_STATUS:"CIV-08 Mar",ACT_WK:19,CAUTION_WK:4,REJECT_WK:1},
    {STEP_STATUS:"COA-29 Mar",ACT_WK:3,CAUTION_WK:0,REJECT_WK:0},
    {STEP_STATUS:"COA-22 Mar",ACT_WK:9,CAUTION_WK:0,REJECT_WK:0},
    {STEP_STATUS:"COA-15 Mar",ACT_WK:7,CAUTION_WK:0,REJECT_WK:0},
    {STEP_STATUS:"COA-08 Mar",ACT_WK:13,CAUTION_WK:2,REJECT_WK:3},
    {STEP_STATUS:"ELE-29 Mar",ACT_WK:1,CAUTION_WK:0,REJECT_WK:0},
    {STEP_STATUS:"ELE-22 Mar",ACT_WK:1,CAUTION_WK:0,REJECT_WK:0},
    {STEP_STATUS:"ELE-08 Mar",ACT_WK:2,CAUTION_WK:0,REJECT_WK:0},
    {STEP_STATUS:"ELE-01 Mar",ACT_WK:1,CAUTION_WK:0,REJECT_WK:0},
    {STEP_STATUS:"INS-29 Mar",ACT_WK:1,CAUTION_WK:0,REJECT_WK:0},
    {STEP_STATUS:"INS-22 Mar",ACT_WK:1,CAUTION_WK:0,REJECT_WK:0},
    {STEP_STATUS:"INS-15 Mar",ACT_WK:1,CAUTION_WK:0,REJECT_WK:0},
    {STEP_STATUS:"INS-08 Mar",ACT_WK:1,CAUTION_WK:0,REJECT_WK:0},
    {STEP_STATUS:"MEC-29 Mar",ACT_WK:3,CAUTION_WK:0,REJECT_WK:0},
    {STEP_STATUS:"MEC-22 Mar",ACT_WK:3,CAUTION_WK:0,REJECT_WK:0},
    {STEP_STATUS:"MEC-15 Mar",ACT_WK:2,CAUTION_WK:0,REJECT_WK:0},
    {STEP_STATUS:"MEC-08 Mar",ACT_WK:2,CAUTION_WK:0,REJECT_WK:0},
    {STEP_STATUS:"NDE-29 Mar",ACT_WK:4,CAUTION_WK:0,REJECT_WK:0},
    {STEP_STATUS:"NDE-22 Mar",ACT_WK:4,CAUTION_WK:0,REJECT_WK:0},
    {STEP_STATUS:"NDE-15 Mar",ACT_WK:4,CAUTION_WK:0,REJECT_WK:0},
    {STEP_STATUS:"NDE-08 Mar",ACT_WK:3,CAUTION_WK:0,REJECT_WK:0},
    {STEP_STATUS:"PIP-29 Mar",ACT_WK:7,CAUTION_WK:0,REJECT_WK:0},
    {STEP_STATUS:"PIP-22 Mar",ACT_WK:8,CAUTION_WK:0,REJECT_WK:0},
    {STEP_STATUS:"PIP-15 Mar",ACT_WK:7,CAUTION_WK:1,REJECT_WK:0},
    {STEP_STATUS:"PIP-08 Mar",ACT_WK:7,CAUTION_WK:0,REJECT_WK:0},
    {STEP_STATUS:"STR-29 Mar",ACT_WK:2,CAUTION_WK:0,REJECT_WK:0},
    {STEP_STATUS:"STR-22 Mar",ACT_WK:4,CAUTION_WK:1,REJECT_WK:0},
    {STEP_STATUS:"STR-15 Mar",ACT_WK:4,CAUTION_WK:2,REJECT_WK:0},
    {STEP_STATUS:"STR-08 Mar",ACT_WK:2,CAUTION_WK:1,REJECT_WK:0}
  ],
}