import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1800,
      CanvasHeight: 600,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#e0e0e0',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 380,
      CanvasChartHeight: 280,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },
    Queries: {},
    DataItems: [
      {CDATE:"2022-03-04",ACTUAL_CUM:1394,ACTUAL_DAILY:96},
      {CDATE:"2022-03-05",ACTUAL_CUM:1497,ACTUAL_DAILY:103},
      {CDATE:"2022-03-07",ACTUAL_CUM:1497,ACTUAL_DAILY:0},
      {CDATE:"2022-03-08",ACTUAL_CUM:1541,ACTUAL_DAILY:44},
      {CDATE:"2022-03-09",ACTUAL_CUM:1616,ACTUAL_DAILY:75},
      {CDATE:"2022-03-10",ACTUAL_CUM:1616,ACTUAL_DAILY:0},
      {CDATE:"2022-03-11",ACTUAL_CUM:1805,ACTUAL_DAILY:189,PLAN_CUM:1805},
      {CDATE:"2022-03-17",WK_NO:"W2022_11",PLAN_CUM:2042},
      {CDATE:"2022-03-24",WK_NO:"W2022_12",PLAN_CUM:2279},
      {CDATE:"2022-03-31",WK_NO:"W2022_13",PLAN_CUM:2516},
      {CDATE:"2022-04-07",WK_NO:"W2022_14",PLAN_CUM:2753},
      {CDATE:"2022-04-14",WK_NO:"W2022_15",PLAN_CUM:2990},
      {CDATE:"2022-04-21",WK_NO:"W2022_16",PLAN_CUM:3227},
      {CDATE:"2022-04-28",WK_NO:"W2022_17",PLAN_CUM:3464},
      {CDATE:"2022-05-05",WK_NO:"W2022_18",PLAN_CUM:3701},
      {CDATE:"2022-05-12",WK_NO:"W2022_19",PLAN_CUM:3938},
      {CDATE:"2022-05-19",WK_NO:"W2022_20",PLAN_CUM:4175},
      {CDATE:"2022-05-26",WK_NO:"W2022_21",PLAN_CUM:4412},
      {CDATE:"2022-06-02",WK_NO:"W2022_22",PLAN_CUM:4649},
      {CDATE:"2022-06-09",WK_NO:"W2022_23",PLAN_CUM:4886},
      {CDATE:"2022-06-16",WK_NO:"W2022_24",PLAN_CUM:5123},
      {CDATE:"2022-06-23",WK_NO:"W2022_25",PLAN_CUM:5360},
      {CDATE:"2022-06-30",WK_NO:"W2022_26",PLAN_CUM:5597},
      {CDATE:"2022-07-07",WK_NO:"W2022_27",PLAN_CUM:5834},
      {CDATE:"2022-07-14",WK_NO:"W2022_28",PLAN_CUM:6071},
      {CDATE:"2022-07-21",WK_NO:"W2022_29",PLAN_CUM:6308}
    ],
}