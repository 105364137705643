import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 700,
      CanvasHeight: 320,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#e0e0e0',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 380,
      CanvasChartHeight: 280,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Support Fabrication', x:20, y: -20, font: 'roboto', class: 'regular', size: 12, color: '#424242', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: -50, y: -20, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'start' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: -30,
          y: -20,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'start'
        },
      ]
    },
    Queries: {
      // SQL1: [
      //   {PLAN:100,ACTUAL:99.99,DELTA:-0.01000000000000512}
      // ],
    },
    DataItems: [
      {CDATE:"2021-11-16",PLAN_CUM:5.8,ACTUAL_CUM:5.8,PLAN_WK:4.2,ACTUAL_WK:5.2},
      {CDATE:"2021-12-16",PLAN_CUM:7,ACTUAL_CUM:7.1,PLAN_WK:1.2,ACTUAL_WK:1.3},
      {CDATE:"2022-01-16",PLAN_CUM:8.1,ACTUAL_CUM:8.2,PLAN_WK:1.1,ACTUAL_WK:1},
      {CDATE:"2022-02-16",PLAN_CUM:9,ACTUAL_CUM:8.9,PLAN_WK:0.8,ACTUAL_WK:0.7},
      {CDATE:"2022-03-16",PLAN_CUM:10.5,ACTUAL_CUM:9.9,PLAN_WK:1.4,ACTUAL_WK:1},
      {CDATE:"2022-04-16",PLAN_CUM:11.9,ACTUAL_CUM:11.4,PLAN_WK:1.3,ACTUAL_WK:1.5},
      {CDATE:"2022-05-16",PLAN_CUM:13.8,ACTUAL_CUM:13.4,PLAN_WK:1.8,ACTUAL_WK:1.9},
      {CDATE:"2022-06-16",PLAN_CUM:15.8,ACTUAL_CUM:15.4,PLAN_WK:2,ACTUAL_WK:2},
      {CDATE:"2022-07-16",PLAN_CUM:18.5,PLAN_WK:2.7},
      {CDATE:"2022-08-16",PLAN_CUM:21.1,PLAN_WK:2.5},
      {CDATE:"2022-09-16",PLAN_CUM:24,PLAN_WK:2.9},
      {CDATE:"2022-10-16",PLAN_CUM:27,PLAN_WK:2.9},
      {CDATE:"2022-11-16",PLAN_CUM:30.3,PLAN_WK:3.2},
      {CDATE:"2022-12-16",PLAN_CUM:34.1,PLAN_WK:3.7},
      {CDATE:"2023-01-16",PLAN_CUM:37.4,PLAN_WK:3.3},
      {CDATE:"2023-02-16",PLAN_CUM:41,PLAN_WK:3.5},
      {CDATE:"2023-03-16",PLAN_CUM:45.8,PLAN_WK:4.8},
      {CDATE:"2023-04-16",PLAN_CUM:51.8,PLAN_WK:5.9},
      {CDATE:"2023-05-16",PLAN_CUM:56.4,PLAN_WK:4.5},
      {CDATE:"2023-06-16",PLAN_CUM:62.5,PLAN_WK:6.1},
      {CDATE:"2023-07-16",PLAN_CUM:67.1,PLAN_WK:4.6},
      {CDATE:"2023-08-16",PLAN_CUM:71.4,PLAN_WK:4.2}
    ],
}