import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1500,
      CanvasHeight: 420,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#e0e0e0',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 380,
      CanvasChartHeight: 280,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Support Fabrication', x:20, y: -20, font: 'roboto', class: 'regular', size: 12, color: '#424242', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: -50, y: -20, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'start' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: -30,
          y: -20,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'start'
        },
      ]
    },
    Queries: {
      // SQL1: [
      //   {PLAN:100,ACTUAL:99.99,DELTA:-0.01000000000000512}
      // ],
    },
    DataItems: [
      // {WK_NO:"W2021_37",CUM_PLAN_PROG:100,CUM_ACTUAL_PROG:100,WK_PLAN_QTY:0,WK_ACTUAL_QTY:0,CUM_PLAN_QTY:77264.70999999999,CUM_ACTUAL_QTY:77264.70999999999,WK_PLAN_PROG:0,WK_ACTUAL_PROG:0},
      // {WK_NO:"W2021_38",CUM_PLAN_PROG:100,CUM_ACTUAL_PROG:100,WK_PLAN_QTY:0,WK_ACTUAL_QTY:0,CUM_PLAN_QTY:77264.70999999999,CUM_ACTUAL_QTY:77264.70999999999,WK_PLAN_PROG:0,WK_ACTUAL_PROG:0},
      // {WK_NO:"W2021_39",CUM_PLAN_PROG:100,CUM_ACTUAL_PROG:100,WK_PLAN_QTY:0,WK_ACTUAL_QTY:0,CUM_PLAN_QTY:77264.70999999999,CUM_ACTUAL_QTY:77264.70999999999,WK_PLAN_PROG:0,WK_ACTUAL_PROG:0},
      // {WK_NO:"W2021_40",CUM_PLAN_PROG:100,CUM_ACTUAL_PROG:100,WK_PLAN_QTY:0,WK_ACTUAL_QTY:0,CUM_PLAN_QTY:77264.70999999999,CUM_ACTUAL_QTY:77264.70999999999,WK_PLAN_PROG:0,WK_ACTUAL_PROG:0},
      // {WK_NO:"W2021_41",CUM_PLAN_PROG:100,CUM_ACTUAL_PROG:100,WK_PLAN_QTY:0,WK_ACTUAL_QTY:0,CUM_PLAN_QTY:77264.70999999999,CUM_ACTUAL_QTY:77264.70999999999,WK_PLAN_PROG:0,WK_ACTUAL_PROG:0},
      // {WK_NO:"W2021_42",CUM_PLAN_PROG:100,CUM_ACTUAL_PROG:100,WK_PLAN_QTY:0,WK_ACTUAL_QTY:0,CUM_PLAN_QTY:77264.70999999999,CUM_ACTUAL_QTY:77264.70999999999,WK_PLAN_PROG:0,WK_ACTUAL_PROG:0},
      // {WK_NO:"W2021_43",CUM_PLAN_PROG:100,CUM_ACTUAL_PROG:100,WK_PLAN_QTY:0,WK_ACTUAL_QTY:0,CUM_PLAN_QTY:77264.70999999999,CUM_ACTUAL_QTY:77264.70999999999,WK_PLAN_PROG:0,WK_ACTUAL_PROG:0},
      // {WK_NO:"W2021_44",CUM_PLAN_PROG:100,CUM_ACTUAL_PROG:100,WK_PLAN_QTY:0,WK_ACTUAL_QTY:0,CUM_PLAN_QTY:77264.70999999999,CUM_ACTUAL_QTY:77264.70999999999,WK_PLAN_PROG:0,WK_ACTUAL_PROG:0},
      // {WK_NO:"W2021_45",CUM_PLAN_PROG:100,CUM_ACTUAL_PROG:100,WK_PLAN_QTY:0,WK_ACTUAL_QTY:0,CUM_PLAN_QTY:77264.70999999999,CUM_ACTUAL_QTY:77264.70999999999,WK_PLAN_PROG:0,WK_ACTUAL_PROG:0},
      // {WK_NO:"W2021_46",CUM_PLAN_PROG:100,CUM_ACTUAL_PROG:100,WK_PLAN_QTY:0,WK_ACTUAL_QTY:0,CUM_PLAN_QTY:77264.70999999999,CUM_ACTUAL_QTY:77264.70999999999,WK_PLAN_PROG:0,WK_ACTUAL_PROG:0,WKS4_TREND:100},
      // {WK_NO:"W2021_47",CUM_PLAN_PROG:100,WK_PLAN_QTY:0,CUM_PLAN_QTY:77264.70999999999,WK_PLAN_PROG:0,WKS4_TREND:100},
      // {WK_NO:"W2021_48",CUM_PLAN_PROG:100,WK_PLAN_QTY:0,CUM_PLAN_QTY:77264.70999999999,WK_PLAN_PROG:0,WKS4_TREND:100},
      // {WK_NO:"W2021_49",CUM_PLAN_PROG:100,WK_PLAN_QTY:0,CUM_PLAN_QTY:77264.70999999999,WK_PLAN_PROG:0,WKS4_TREND:100},
      // {WK_NO:"W2021_50",CUM_PLAN_PROG:100,WK_PLAN_QTY:0,CUM_PLAN_QTY:77264.70999999999,WK_PLAN_PROG:0,WKS4_TREND:100}
    
      {NAME:"ITB",CUTOFF:"2022-01-05",WK_NO:"W2022_01",PLAN_WK:0,ACTUAL_WK:0,PLAN_CUM:0,ACTUAL_CUM:0},
      {NAME:"ITB",CUTOFF:"2022-01-12",WK_NO:"W2022_02",PLAN_WK:0,ACTUAL_WK:0,PLAN_CUM:0,ACTUAL_CUM:0},
      {NAME:"ITB",CUTOFF:"2022-01-19",WK_NO:"W2022_03",PLAN_WK:0,ACTUAL_WK:0,PLAN_CUM:0,ACTUAL_CUM:0},
      {NAME:"ITB",CUTOFF:"2022-01-26",WK_NO:"W2022_04",PLAN_WK:0,ACTUAL_WK:0,PLAN_CUM:0,ACTUAL_CUM:0},
      {NAME:"ITB",CUTOFF:"2022-02-02",WK_NO:"W2022_05",PLAN_WK:0,ACTUAL_WK:0,PLAN_CUM:0,ACTUAL_CUM:0},
      {NAME:"ITB",CUTOFF:"2022-02-09",WK_NO:"W2022_06",PLAN_WK:0,ACTUAL_WK:0,PLAN_CUM:0,ACTUAL_CUM:0},
      {NAME:"ITB",CUTOFF:"2022-02-16",WK_NO:"W2022_07",PLAN_WK:1,ACTUAL_WK:1,PLAN_CUM:1,ACTUAL_CUM:1},
      {NAME:"ITB",CUTOFF:"2022-02-23",WK_NO:"W2022_08",PLAN_WK:0,ACTUAL_WK:0,PLAN_CUM:1,ACTUAL_CUM:1},
      {NAME:"ITB",CUTOFF:"2022-03-02",WK_NO:"W2022_09",PLAN_WK:0,ACTUAL_WK:0,PLAN_CUM:1,ACTUAL_CUM:1},
      {NAME:"ITB",CUTOFF:"2022-03-09",WK_NO:"W2022_10",PLAN_WK:1,ACTUAL_WK:1,PLAN_CUM:2,ACTUAL_CUM:2},
      {NAME:"ITB",CUTOFF:"2022-03-16",WK_NO:"W2022_11",PLAN_WK:0,ACTUAL_WK:0,PLAN_CUM:2,ACTUAL_CUM:2},
      {NAME:"ITB",CUTOFF:"2022-03-23",WK_NO:"W2022_12",PLAN_WK:0,ACTUAL_WK:0,PLAN_CUM:2,ACTUAL_CUM:2},
      {NAME:"ITB",CUTOFF:"2022-03-30",WK_NO:"W2022_13",PLAN_WK:0,ACTUAL_WK:0,PLAN_CUM:2,ACTUAL_CUM:2},
      {NAME:"ITB",CUTOFF:"2022-04-06",WK_NO:"W2022_14",PLAN_WK:2,ACTUAL_WK:2,PLAN_CUM:4,ACTUAL_CUM:4},
      {NAME:"ITB",CUTOFF:"2022-04-13",WK_NO:"W2022_15",PLAN_WK:0,ACTUAL_WK:0,PLAN_CUM:4,ACTUAL_CUM:4},
      {NAME:"ITB",CUTOFF:"2022-04-20",WK_NO:"W2022_16",PLAN_WK:3,ACTUAL_WK:3,PLAN_CUM:7,ACTUAL_CUM:7},
      {NAME:"ITB",CUTOFF:"2022-04-27",WK_NO:"W2022_17",PLAN_WK:2,ACTUAL_WK:2,PLAN_CUM:9,ACTUAL_CUM:9},
      {NAME:"ITB",CUTOFF:"2022-05-04",WK_NO:"W2022_18",PLAN_WK:0,ACTUAL_WK:0,PLAN_CUM:9,ACTUAL_CUM:9},
      {NAME:"ITB",CUTOFF:"2022-05-11",WK_NO:"W2022_19",PLAN_WK:5,ACTUAL_WK:5,PLAN_CUM:14,ACTUAL_CUM:14},
      {NAME:"ITB",CUTOFF:"2022-05-18",WK_NO:"W2022_20",PLAN_WK:0,ACTUAL_WK:0,PLAN_CUM:14,ACTUAL_CUM:14},
      {NAME:"ITB",CUTOFF:"2022-05-25",WK_NO:"W2022_21",PLAN_WK:5,PLAN_CUM:19},
      {NAME:"ITB",CUTOFF:"2022-06-01",WK_NO:"W2022_22",PLAN_WK:0,PLAN_CUM:19},
      {NAME:"ITB",CUTOFF:"2022-06-08",WK_NO:"W2022_23",PLAN_WK:0,PLAN_CUM:19},
      {NAME:"ITB",CUTOFF:"2022-06-15",WK_NO:"W2022_24",PLAN_WK:0,PLAN_CUM:19},
      {NAME:"ITB",CUTOFF:"2022-06-22",WK_NO:"W2022_25",PLAN_WK:0,PLAN_CUM:19},
      {NAME:"ITB",CUTOFF:"2022-06-29",WK_NO:"W2022_26",PLAN_WK:0,PLAN_CUM:19},
      {NAME:"ITB",CUTOFF:"2022-07-06",WK_NO:"W2022_27",PLAN_WK:0,PLAN_CUM:19},
      {NAME:"ITB",CUTOFF:"2022-07-13",WK_NO:"W2022_28",PLAN_WK:1,PLAN_CUM:20},
      {NAME:"ITB",CUTOFF:"2022-07-20",WK_NO:"W2022_29",PLAN_WK:0,PLAN_CUM:20},
      {NAME:"ITB",CUTOFF:"2022-07-27",WK_NO:"W2022_30",PLAN_WK:0,PLAN_CUM:20},
      {NAME:"ITB",CUTOFF:"2022-08-03",WK_NO:"W2022_31",PLAN_WK:0,PLAN_CUM:20},
      {NAME:"ITB",CUTOFF:"2022-08-10",WK_NO:"W2022_32",PLAN_WK:0,PLAN_CUM:20},
      {NAME:"ITB",CUTOFF:"2022-08-17",WK_NO:"W2022_33",PLAN_WK:0,PLAN_CUM:20},
      {NAME:"ITB",CUTOFF:"2022-08-24",WK_NO:"W2022_34",PLAN_WK:0,PLAN_CUM:20},
      {NAME:"ITB",CUTOFF:"2022-08-31",WK_NO:"W2022_35",PLAN_WK:0,PLAN_CUM:20},
      {NAME:"ITB",CUTOFF:"2022-09-07",WK_NO:"W2022_36",PLAN_WK:0,PLAN_CUM:20},
      {NAME:"ITB",CUTOFF:"2022-09-14",WK_NO:"W2022_37",PLAN_WK:0,PLAN_CUM:20},
      {NAME:"ITB",CUTOFF:"2022-09-21",WK_NO:"W2022_38",PLAN_WK:0,PLAN_CUM:20},
      {NAME:"ITB",CUTOFF:"2022-09-28",WK_NO:"W2022_39",PLAN_WK:0,PLAN_CUM:20},
      {NAME:"ITB",CUTOFF:"2022-10-05",WK_NO:"W2022_40",PLAN_WK:0,PLAN_CUM:20}
    ],
}