import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 2300,
    CanvasHeight: 980,
    CanvasBorderWeight: 0.05,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.1,

    CanvasChartX: 50,
    CanvasChartY: 50,
    CanvasChartWidth: 480,
    CanvasChartHeight: 250,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  DataItems:  [
    {SUBSYSTEM:"21-1001-1001-01",SUBSYSTEM_DESCR:"Annulus Riser vent Monitoring System",TOTAL:3,COMPLETED:0,REMAIN:3,MC_PLAN:"2022-04-28",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-1010-1011-01",SUBSYSTEM_DESCR:"Flowline 1 & Receiver",TOTAL:16,COMPLETED:0,REMAIN:16,MC_PLAN:"2022-04-28",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-1010-1012-01",SUBSYSTEM_DESCR:"Flowline 2 & Receiver",TOTAL:15,COMPLETED:0,REMAIN:15,MC_PLAN:"2022-04-28",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-1510-1510-01",SUBSYSTEM_DESCR:"MEG Riser & Pipeline System to DC1",TOTAL:2,COMPLETED:0,REMAIN:2,MC_PLAN:"2022-04-28",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-1600-1611-01",SUBSYSTEM_DESCR:"Gas Export Riser 1",TOTAL:1,COMPLETED:0,REMAIN:1,MC_PLAN:"2022-04-28",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-1600-1612-01",SUBSYSTEM_DESCR:"Gas Export Riser 2",TOTAL:2,COMPLETED:0,REMAIN:2,MC_PLAN:"2022-04-28",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2200-2131-01",SUBSYSTEM_DESCR:"Slug Catcher 1 & Liquid Heater 1",TOTAL:28,COMPLETED:0,REMAIN:28,MC_PLAN:"2022-05-05",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"B1",PRIORITY:"P3",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:28,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2200-2132-01",SUBSYSTEM_DESCR:"Slug Catcher 2 & Liquid heater 2",TOTAL:27,COMPLETED:0,REMAIN:27,MC_PLAN:"2022-05-05",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"B1",PRIORITY:"P3",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:27,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2200-2221-01",SUBSYSTEM_DESCR:"MP Separator",TOTAL:19,COMPLETED:0,REMAIN:19,MC_PLAN:"2022-07-14",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:19,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2200-2230-01",SUBSYSTEM_DESCR:"LP Separator",TOTAL:14,COMPLETED:0,REMAIN:14,MC_PLAN:"2022-06-02",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2200-2230-02",SUBSYSTEM_DESCR:"Interstage Heaters",TOTAL:12,COMPLETED:0,REMAIN:12,MC_PLAN:"2022-06-16",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2200-2240-01",SUBSYSTEM_DESCR:"LLP separator, Condensate Coolers & Condensate Coalescer Pumps",TOTAL:12,COMPLETED:0,REMAIN:12,MC_PLAN:"2022-05-12",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:0,PA_OV_REM:3,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-2200-2240-03",SUBSYSTEM_DESCR:"Condensate Coalescer Filters",TOTAL:9,COMPLETED:0,REMAIN:9,MC_PLAN:"2022-05-12",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2200-2240-04",SUBSYSTEM_DESCR:"Condensate Coalescer Package",TOTAL:10,COMPLETED:0,REMAIN:10,MC_PLAN:"2022-05-12",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",PB1_OV_TOT:3,PB1_OV_COM:3,PB1_OV_REM:0,ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2200-2470-01",SUBSYSTEM_DESCR:"Warm Condensate Heater, Offspec Condensate Pump & Distribution",TOTAL:11,COMPLETED:0,REMAIN:11,MC_PLAN:"2022-07-14",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2400-2440-01",SUBSYSTEM_DESCR:"Condensate Metering",TOTAL:2,COMPLETED:0,REMAIN:2,MC_PLAN:"2022-06-09",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:6,PA_OV_COM:0,PA_OV_REM:6,PB1_OV_TOT:11,PB1_OV_COM:0,PB1_OV_REM:11,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:17,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2400-2450-01",SUBSYSTEM_DESCR:"CFW1 Offspec Condensate Tank & Pumps (Incl Washing Machine)",TOTAL:26,COMPLETED:0,REMAIN:26,MC_PLAN:"2022-02-24",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:15.8,PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:26,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2400-2460-01",SUBSYSTEM_DESCR:"Condensate Offloading",TOTAL:1,COMPLETED:0,REMAIN:1,MC_PLAN:"2022-06-09",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2400-9360-01",SUBSYSTEM_DESCR:"Cargo Oil Washing Header & Distribution",TOTAL:3,COMPLETED:0,REMAIN:3,MC_PLAN:"2022-01-27",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2400-9360-02",SUBSYSTEM_DESCR:"Condensate Import/Loading Header",TOTAL:2,COMPLETED:0,REMAIN:2,MC_PLAN:"2022-01-27",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2400-9360-03",SUBSYSTEM_DESCR:"Condensate Transfer Header",TOTAL:5,COMPLETED:0,REMAIN:5,MC_PLAN:"2022-01-27",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2400-9360-04",SUBSYSTEM_DESCR:"Condensate Export/Offloading Header",TOTAL:8,COMPLETED:0,REMAIN:8,MC_PLAN:"2022-01-27",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2400-9361-01",SUBSYSTEM_DESCR:"COW1 Tank & Pumps (Incl Washing Machine)",TOTAL:12,COMPLETED:0,REMAIN:12,MC_PLAN:"2021-11-25",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:26.7,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2400-9362-01",SUBSYSTEM_DESCR:"COW2 Tank & Pumps (Incl Washing Machine)",TOTAL:12,COMPLETED:0,REMAIN:12,MC_PLAN:"2021-11-25",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:26.7,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2400-9363-01",SUBSYSTEM_DESCR:"COW3 Tank & Pumps (Incl Washing Machine)",TOTAL:12,COMPLETED:0,REMAIN:12,MC_PLAN:"2021-11-25",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:22.2,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2400-9364-01",SUBSYSTEM_DESCR:"COW4 Tank & Pumps (Incl Washing Machine)",TOTAL:12,COMPLETED:0,REMAIN:12,MC_PLAN:"2021-11-25",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:31.1,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2400-9365-01",SUBSYSTEM_DESCR:"COE1 Tank & Pumps (Incl Washing Machine)",TOTAL:11,COMPLETED:0,REMAIN:11,MC_PLAN:"2022-01-27",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:22,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2400-9366-01",SUBSYSTEM_DESCR:"COE2 Tank & Pumps (Incl Washing Machine)",TOTAL:11,COMPLETED:0,REMAIN:11,MC_PLAN:"2022-01-27",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:24.4,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2400-9367-01",SUBSYSTEM_DESCR:"COE3 Tank & Pumps (Incl Washing Machine)",TOTAL:11,COMPLETED:0,REMAIN:11,MC_PLAN:"2022-01-27",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:24.4,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2400-9368-01",SUBSYSTEM_DESCR:"COE4 Tank & Pumps (Incl Washing Machine)",TOTAL:11,COMPLETED:0,REMAIN:11,MC_PLAN:"2022-01-27",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:29.3,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2500-2510-01",SUBSYSTEM_DESCR:"Rich MEG Collection, Flash Drum & Coolers",TOTAL:12,COMPLETED:0,REMAIN:12,MC_PLAN:"2022-06-02",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2500-2510-02",SUBSYSTEM_DESCR:"MEG Transfer Header",TOTAL:2,COMPLETED:0,REMAIN:2,MC_PLAN:"2021-12-23",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2500-2510-04",SUBSYSTEM_DESCR:"Salty Rich MEG Import Header",TOTAL:4,COMPLETED:0,REMAIN:4,MC_PLAN:"2022-06-02",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2500-2510-05",SUBSYSTEM_DESCR:"Salty Rich MEG Export Header",TOTAL:2,COMPLETED:0,REMAIN:2,MC_PLAN:"2022-06-02",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2500-2510-06",SUBSYSTEM_DESCR:"Rich MEG Header to MEG Regeneration",TOTAL:4,COMPLETED:0,REMAIN:4,MC_PLAN:"2022-06-02",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2500-2511-01",SUBSYSTEM_DESCR:"MRW1 Rich MEG Storage & Pumps",TOTAL:16,COMPLETED:0,REMAIN:16,MC_PLAN:"2022-02-17",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:3.3,PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2500-2512-01",SUBSYSTEM_DESCR:"MSE1 Salty Rich MEG Storage & Pumps",TOTAL:16,COMPLETED:0,REMAIN:16,MC_PLAN:"2022-01-13",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:16.4,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2500-2513-01",SUBSYSTEM_DESCR:"MSW2 Salty rich MEG storage & pumps",TOTAL:10,COMPLETED:0,REMAIN:10,MC_PLAN:"2022-02-17",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:10,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2500-2514-01",SUBSYSTEM_DESCR:"MSE2 Salty Rich MEG Storage & Pumps",TOTAL:11,COMPLETED:0,REMAIN:11,MC_PLAN:"2022-02-17",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:27.3,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:11,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2500-2530-01",SUBSYSTEM_DESCR:"MEG Regeneration",TOTAL:5,COMPLETED:0,REMAIN:5,MC_PLAN:"2022-04-28",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:8,PA_OV_COM:0,PA_OV_REM:8,PB1_OV_TOT:6,PB1_OV_COM:0,PB1_OV_REM:6,ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:14,PUNCH_TAG_VALID:10},
    {SUBSYSTEM:"21-2500-2530-02",SUBSYSTEM_DESCR:"Lean MEG from MEG Regeneration to Storage",TOTAL:1,COMPLETED:0,REMAIN:1,MC_PLAN:"2022-05-12",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2500-2551-01",SUBSYSTEM_DESCR:"MLW1 Lean MEG Storage & Pumps",TOTAL:18,COMPLETED:0,REMAIN:18,MC_PLAN:"2022-01-13",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:17.4,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2500-2552-01",SUBSYSTEM_DESCR:"MLE1 Lean MEG Storage & Pumps",TOTAL:16,COMPLETED:0,REMAIN:16,MC_PLAN:"2022-01-13",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:13.1,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2500-2553-01",SUBSYSTEM_DESCR:"Lean MEG Filters & Break Tank",TOTAL:28,COMPLETED:0,REMAIN:28,MC_PLAN:"2022-05-19",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:1.8,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:28,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2500-2553-02",SUBSYSTEM_DESCR:"Topsides Lean MEG Pumps & Distribution",TOTAL:8,COMPLETED:0,REMAIN:8,MC_PLAN:"2022-05-19",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-2500-2553-03",SUBSYSTEM_DESCR:"Subsea Lean MEG Pumps & Distribution",TOTAL:7,COMPLETED:0,REMAIN:7,MC_PLAN:"2022-06-23",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:21,PA_OV_COM:10,PA_OV_REM:11,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:22,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-2500-2562-01",SUBSYSTEM_DESCR:"MEG Transfer from Bunkering Station",TOTAL:2,COMPLETED:0,REMAIN:2,MC_PLAN:"2022-01-06",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2600-2630-01",SUBSYSTEM_DESCR:"Methanol Storage & Pumps",TOTAL:8,COMPLETED:0,REMAIN:8,MC_PLAN:"2021-12-23",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2600-2640-01",SUBSYSTEM_DESCR:"Methanol Filters & Break Tank",TOTAL:14,COMPLETED:0,REMAIN:14,MC_PLAN:"2022-05-19",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2600-2640-02",SUBSYSTEM_DESCR:"Methanol HP Pumps",TOTAL:3,COMPLETED:0,REMAIN:3,MC_PLAN:"2022-05-19",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2600-2640-03",SUBSYSTEM_DESCR:"Methanol MP Pumps",TOTAL:3,COMPLETED:0,REMAIN:3,MC_PLAN:"2022-05-19",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2600-2640-04",SUBSYSTEM_DESCR:"Methanol HP Header",TOTAL:4,COMPLETED:0,REMAIN:4,MC_PLAN:"2022-06-02",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2600-2640-05",SUBSYSTEM_DESCR:"Methanol MP Header",TOTAL:6,COMPLETED:0,REMAIN:6,MC_PLAN:"2022-06-02",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2600-2641-01",SUBSYSTEM_DESCR:"Methanol Subsea Pumps",TOTAL:3,COMPLETED:0,REMAIN:3,MC_PLAN:"2022-05-12",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2600-2641-02",SUBSYSTEM_DESCR:"Methanol Subsea Distribution",TOTAL:2,COMPLETED:0,REMAIN:2,MC_PLAN:"2022-05-19",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-2600-8510-01",SUBSYSTEM_DESCR:"Methanol Loading Header",TOTAL:2,COMPLETED:0,REMAIN:2,MC_PLAN:"2022-07-07",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-3040-3040-01",SUBSYSTEM_DESCR:"HP/MP Gas Compressors, UCP, VSD & Electrical Control/Switchboards",TOTAL:6,COMPLETED:0,REMAIN:6,MC_PLAN:"2022-04-07",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:33,PA_OV_COM:4,PA_OV_REM:29,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:33,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-3040-3040-02",SUBSYSTEM_DESCR:"HP/MP Gas Compressors Train Scrubbers, Aftercoolers & Process Lines",TOTAL:34,COMPLETED:0,REMAIN:34,MC_PLAN:"2022-07-07",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:34,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-3040-3041-01",SUBSYSTEM_DESCR:"LP/LLP Gas Compressors , UCP, VSD & Electrical Control/Switchboards",TOTAL:3,COMPLETED:0,REMAIN:3,MC_PLAN:"2022-04-07",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:6,PA_OV_COM:0,PA_OV_REM:6,PB1_OV_TOT:9,PB1_OV_COM:0,PB1_OV_REM:9,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:15,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-3040-3041-02",SUBSYSTEM_DESCR:"LP/LLP Gas Compressors Train Scrubbers, Aftercoolers & Process Lines",TOTAL:31,COMPLETED:0,REMAIN:31,MC_PLAN:"2022-07-07",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:31,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-3330-3330-01",SUBSYSTEM_DESCR:"Process Gas, Gas/Gas Exchanger, TEX Scrubber & JT Valve",TOTAL:24,COMPLETED:0,REMAIN:24,MC_PLAN:"2022-05-26",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:24,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-3330-3330-02",SUBSYSTEM_DESCR:"Gas Coalescer & LT Separator",TOTAL:20,COMPLETED:0,REMAIN:20,MC_PLAN:"2022-05-12",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-3330-3330-03",SUBSYSTEM_DESCR:"Turbo Expander, Recompressor",TOTAL:4,COMPLETED:0,REMAIN:4,MC_PLAN:"2022-05-12",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-3330-3410-01",SUBSYSTEM_DESCR:"Gas Export Heaters",TOTAL:10,COMPLETED:0,REMAIN:10,MC_PLAN:"2022-06-09",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-3330-3410-02",SUBSYSTEM_DESCR:"Export Gas Metering & Export Risers",TOTAL:4,COMPLETED:0,REMAIN:4,MC_PLAN:"2022-05-26",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-3330-3410-03",SUBSYSTEM_DESCR:"Repressurisation Heater & Header",TOTAL:9,COMPLETED:0,REMAIN:9,MC_PLAN:"2022-07-14",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-4000-4030-01",SUBSYSTEM_DESCR:"Produced Water Offspec Storage Tank & Pumps",TOTAL:17,COMPLETED:0,REMAIN:17,MC_PLAN:"2022-02-24",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-4000-4030-02",SUBSYSTEM_DESCR:"Produced Water Offspec Header to Storage Tank",TOTAL:6,COMPLETED:0,REMAIN:6,MC_PLAN:"2022-02-24",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-4000-4030-03",SUBSYSTEM_DESCR:"Produced Water Offspec Header to Produced Water Treatment / Bunkering Station",TOTAL:5,COMPLETED:0,REMAIN:5,MC_PLAN:"2022-02-24",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-4000-4130-01",SUBSYSTEM_DESCR:"Deoiling Package (Hydrocyclone + CFU)",TOTAL:13,COMPLETED:0,REMAIN:13,MC_PLAN:"2022-06-02",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:3,PA_OV_COM:1,PA_OV_REM:2,PB1_OV_TOT:4,PB1_OV_COM:0,PB1_OV_REM:4,ACT_TAG_VALID:13,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-4000-4130-02",SUBSYSTEM_DESCR:"Flocculent Injection Package",TOTAL:4,COMPLETED:0,REMAIN:4,MC_PLAN:"2022-05-26",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:5,PB1_OV_COM:0,PB1_OV_REM:5,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-4000-4150-01",SUBSYSTEM_DESCR:"Mercury Removal Package",TOTAL:6,COMPLETED:0,REMAIN:6,MC_PLAN:"2022-05-26",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:3,PA_OV_COM:2,PA_OV_REM:1,PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-4000-4150-02",SUBSYSTEM_DESCR:"Treated Water Header & Discharge Caisson",TOTAL:3,COMPLETED:0,REMAIN:3,MC_PLAN:"2022-05-26",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5000-5010-01",SUBSYSTEM_DESCR:"HP Flare KO Drum & Pumps",TOTAL:1,COMPLETED:0,REMAIN:1,MC_PLAN:"2022-06-30",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5000-5020-01",SUBSYSTEM_DESCR:"HP Flare Ignition, Metering & Tip",TOTAL:3,COMPLETED:0,REMAIN:3,MC_PLAN:"2022-06-09",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5000-5030-01",SUBSYSTEM_DESCR:"Cold & Dry HP Flare Header",TOTAL:17,COMPLETED:0,REMAIN:17,MC_PLAN:"2022-08-04",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5000-5030-02",SUBSYSTEM_DESCR:"Wet HP Flare Header",TOTAL:2,COMPLETED:0,REMAIN:2,MC_PLAN:"2022-08-04",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5000-5030-03",SUBSYSTEM_DESCR:"HP liquid Flare Header",TOTAL:16,COMPLETED:0,REMAIN:16,MC_PLAN:"2022-08-04",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5200-4340-01",SUBSYSTEM_DESCR:"Closed Drain Drum & Pumps",TOTAL:3,COMPLETED:0,REMAIN:3,MC_PLAN:"2022-04-21",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-5200-4340-02",SUBSYSTEM_DESCR:"Closed Drain Header",TOTAL:83,COMPLETED:0,REMAIN:83,MC_PLAN:"2022-05-26",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:83,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5200-4340-03",SUBSYSTEM_DESCR:"HULL Deck Equipment Drainage Header",TOTAL:1,COMPLETED:0,REMAIN:1,MC_PLAN:"2022-06-09",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5200-5210-01",SUBSYSTEM_DESCR:"LP Flare KO Drum & Pumps",TOTAL:1,COMPLETED:0,REMAIN:1,MC_PLAN:"2022-06-09",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-5200-5230-01",SUBSYSTEM_DESCR:"LP Flare Header",TOTAL:13,COMPLETED:0,REMAIN:13,MC_PLAN:"2022-08-04",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:13,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5400-5410-01",SUBSYSTEM_DESCR:"Vent Headers, Boom & Liquid Seal Systems",TOTAL:22,COMPLETED:0,REMAIN:22,MC_PLAN:"2022-03-10",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-5400-5410-02",SUBSYSTEM_DESCR:"CO2 Snuffing System",TOTAL:3,COMPLETED:0,REMAIN:3,MC_PLAN:"2022-07-21",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-5700-5710-01",SUBSYSTEM_DESCR:"Flare Gas Vapour Recovery Skid",TOTAL:2,COMPLETED:0,REMAIN:2,MC_PLAN:"2022-06-09",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:5,PA_OV_COM:2,PA_OV_REM:3,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-5700-5730-01",SUBSYSTEM_DESCR:"HULL Tank Vapour Recovery Skid",TOTAL:2,COMPLETED:0,REMAIN:2,MC_PLAN:"2022-06-09",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6030-6037-01",SUBSYSTEM_DESCR:"Tank Gauging System Cabinets & Workstations",TOTAL:10,COMPLETED:0,REMAIN:10,MC_PLAN:"2021-10-21",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:15,PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6030-6039-01",SUBSYSTEM_DESCR:"Ballast/Void Gas Sampling",TOTAL:5,COMPLETED:0,REMAIN:5,MC_PLAN:"2021-11-11",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:10,PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6100-6110-01",SUBSYSTEM_DESCR:"Topsides Fire Water Ring Main, Hydrant & Jockey Pumps",TOTAL:31,COMPLETED:0,REMAIN:31,MC_PLAN:"2022-02-17",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:0,PA_OV_REM:3,ACT_TAG_VALID:31,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6100-6110-02",SUBSYSTEM_DESCR:"Accommodation Firewater & Hydrants",TOTAL:13,COMPLETED:0,REMAIN:13,MC_PLAN:"2021-12-30",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:8.2,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:13,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6100-6110-03",SUBSYSTEM_DESCR:"HULL Fire Water Ring Main & Hydrants",TOTAL:6,COMPLETED:0,REMAIN:6,MC_PLAN:"2022-01-20",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6100-6111-01",SUBSYSTEM_DESCR:"Fire Water Pump A (21-SPF-611101)",TOTAL:3,COMPLETED:0,REMAIN:3,MC_PLAN:"2022-03-31",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-6100-6111-02",SUBSYSTEM_DESCR:"Fire Water Pump B (21-SPF-611201)",TOTAL:3,COMPLETED:0,REMAIN:3,MC_PLAN:"2022-03-31",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:16.7,PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6100-6111-03",SUBSYSTEM_DESCR:"Fire Water Pump C (21-SPF-611301)",TOTAL:1,COMPLETED:0,REMAIN:1,MC_PLAN:"2022-03-31",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:0,PA_OV_REM:3,ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6100-6121-01",SUBSYSTEM_DESCR:"HULL Water Mist System",TOTAL:6,COMPLETED:0,REMAIN:6,MC_PLAN:"2021-11-11",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:16.7,PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:7,PA_OV_COM:0,PA_OV_REM:7,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6100-6140-01",SUBSYSTEM_DESCR:"Foam Package",TOTAL:6,COMPLETED:0,REMAIN:6,MC_PLAN:"2021-11-25",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:25,PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:10,PA_OV_COM:0,PA_OV_REM:10,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-6100-6140-02",SUBSYSTEM_DESCR:"Foam Ring Main",TOTAL:3,COMPLETED:0,REMAIN:3,MC_PLAN:"2022-03-10",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-6100-6150-01",SUBSYSTEM_DESCR:"Topside Modules Deluged Systems",TOTAL:17,COMPLETED:0,REMAIN:17,MC_PLAN:"2022-03-10",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"B1",PRIORITY:"P3",PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-6100-6150-03",SUBSYSTEM_DESCR:"HULL Deck Deluged Systems",TOTAL:36,COMPLETED:0,REMAIN:36,MC_PLAN:"2022-03-10",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"B1",PRIORITY:"P3",PB1_OV_TOT:15,PB1_OV_COM:0,PB1_OV_REM:15,ACT_TAG_VALID:36,PUNCH_NUMBER_VALID:15,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-6600-6610-01",SUBSYSTEM_DESCR:"Emergency Generator 21-G-661001",BATCH:"Priority Batch",TOTAL:1,COMPLETED:0,REMAIN:1,MC_PLAN:"2021-11-04",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:3,PA_OV_COM:0,PA_OV_REM:3,PB1_OV_TOT:9,PB1_OV_COM:0,PB1_OV_REM:9,ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-6600-6670-01",SUBSYSTEM_DESCR:"Key service Generator 21-G-667001",TOTAL:6,COMPLETED:0,REMAIN:6,MC_PLAN:"2021-11-18",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P1A",PA_OV_TOT:29,PA_OV_COM:10,PA_OV_REM:19,PB1_OV_TOT:4,PB1_OV_COM:0,PB1_OV_REM:4,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:33,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-6600-6670-02",SUBSYSTEM_DESCR:"Key service Generator 21-G-667002",TOTAL:6,COMPLETED:0,REMAIN:6,MC_PLAN:"2021-11-18",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"B1",PRIORITY:"P1A",PB1_OV_TOT:6,PB1_OV_COM:0,PB1_OV_REM:6,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8000-8010-01",SUBSYSTEM_DESCR:"Potable Water Maker & Rehardener",TOTAL:8,COMPLETED:0,REMAIN:8,MC_PLAN:"2021-11-04",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:25,PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:23,PA_OV_COM:12,PA_OV_REM:11,PB1_OV_TOT:8,PB1_OV_COM:0,PB1_OV_REM:8,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:31,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8000-8010-02",SUBSYSTEM_DESCR:"Chlorination Package",TOTAL:1,COMPLETED:0,REMAIN:1,MC_PLAN:"2022-01-27",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8000-8011-01",SUBSYSTEM_DESCR:"Potable Water Tanks & Pumps",TOTAL:18,COMPLETED:2,REMAIN:16,MC_PLAN:"2021-11-04",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"In Progress",TEST_PACKAGE_PROG:26.1,PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:0,PA_OV_REM:3,ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8000-8011-02",SUBSYSTEM_DESCR:"Fresh Water Bunkering Header",TOTAL:3,COMPLETED:0,REMAIN:3,MC_PLAN:"2021-11-04",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8000-8012-01",SUBSYSTEM_DESCR:"UV Sterilizer",TOTAL:1,COMPLETED:0,REMAIN:1,MC_PLAN:"2021-12-23",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-8000-8012-02",SUBSYSTEM_DESCR:"Main Distribution & Potable Water Cooler",TOTAL:7,COMPLETED:0,REMAIN:7,MC_PLAN:"2021-11-04",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:32,PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:6,PA_OV_COM:5,PA_OV_REM:1,ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8000-8012-03",SUBSYSTEM_DESCR:"Cold Potable Water Distribution to HULL & LQ",TOTAL:15,COMPLETED:0,REMAIN:15,MC_PLAN:"2022-01-06",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:24.6,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8000-8012-04",SUBSYSTEM_DESCR:"Cold Potable Water Distribution to LQ return to Hull MS",TOTAL:3,COMPLETED:1,REMAIN:2,MC_PLAN:"2022-01-20",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"In Progress",TEST_PACKAGE_PROG:44.4,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8000-8012-05",SUBSYSTEM_DESCR:"Cold Potable Water Distribution to Topsides (Safety Showers & Eye Baths)",TOTAL:9,COMPLETED:0,REMAIN:9,MC_PLAN:"2022-06-23",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:6.1,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8000-8013-01",SUBSYSTEM_DESCR:"Hot Water Calorifiers",TOTAL:6,COMPLETED:0,REMAIN:6,MC_PLAN:"2022-01-27",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:8.3,PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:21,PA_OV_COM:0,PA_OV_REM:21,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:22,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8000-8013-02",SUBSYSTEM_DESCR:"Hot Water Distribution & Recirculation Pumps",TOTAL:22,COMPLETED:1,REMAIN:21,MC_PLAN:"2022-01-27",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"In Progress",TEST_PACKAGE_PROG:16.5,PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8000-8014-01",SUBSYSTEM_DESCR:"Fresh Service Water Tank & Pumps",TOTAL:14,COMPLETED:2,REMAIN:12,MC_PLAN:"2021-11-11",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"In Progress",TEST_PACKAGE_PROG:45.3,PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8000-8014-02",SUBSYSTEM_DESCR:"Fresh Service Water Distribution to HULL",TOTAL:2,COMPLETED:0,REMAIN:2,MC_PLAN:"2021-12-23",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8000-8014-03",SUBSYSTEM_DESCR:"Fresh Service Water Distribution to Topsides",TOTAL:15,COMPLETED:0,REMAIN:15,MC_PLAN:"2021-12-23",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8000-8014-04",SUBSYSTEM_DESCR:"Fresh Service Water Distribution to Water Mist System 21-SWM-613001",TOTAL:3,COMPLETED:0,REMAIN:3,MC_PLAN:"2021-11-04",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:22.2,PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8000-8014-05",SUBSYSTEM_DESCR:"Fresh Service Water Distribution to LQ",TOTAL:12,COMPLETED:1,REMAIN:11,MC_PLAN:"2021-11-25",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"In Progress",TEST_PACKAGE_PROG:35.6,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8020-8020-01",SUBSYSTEM_DESCR:"Marine Sea Water Sea Suction Tube & Filter 21-F-802001",TOTAL:5,COMPLETED:1,REMAIN:4,MC_PLAN:"2022-01-27",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"In Progress",TEST_PACKAGE_PROG:47.1,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8020-8020-02",SUBSYSTEM_DESCR:"Marine Sea Water Sea Suction Tube & Filter 21-F-802002",TOTAL:5,COMPLETED:1,REMAIN:4,MC_PLAN:"2022-01-27",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"In Progress",TEST_PACKAGE_PROG:47.1,PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-8020-8020-03",SUBSYSTEM_DESCR:"Marine Sea Water Main Distribution Header",TOTAL:2,COMPLETED:0,REMAIN:2,MC_PLAN:"2021-11-04",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P1A",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8020-8021-01",SUBSYSTEM_DESCR:"IGG Scrubber Cooling Sea Water Pumps - 21-P802003A/B",TOTAL:4,COMPLETED:0,REMAIN:4,MC_PLAN:"2022-01-06",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8020-8021-02",SUBSYSTEM_DESCR:"IGG Scrubber Cooling Sea Water Distribution",TOTAL:4,COMPLETED:0,REMAIN:4,MC_PLAN:"2022-01-06",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8020-8023-01",SUBSYSTEM_DESCR:"IGG Deck Seal Sea Water Pumps - 21-P802002A/B",TOTAL:4,COMPLETED:0,REMAIN:4,MC_PLAN:"2022-01-06",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8020-8023-02",SUBSYSTEM_DESCR:"IGG Deck Seal Sea Water Distribution",TOTAL:3,COMPLETED:0,REMAIN:3,MC_PLAN:"2022-01-06",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8020-8024-01",SUBSYSTEM_DESCR:"HULL Sea Water Cooling Pumps - 21-P-802004A/B",TOTAL:4,COMPLETED:0,REMAIN:4,MC_PLAN:"2021-10-28",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8020-8024-02",SUBSYSTEM_DESCR:"HULL Sea Water Cooling Header & Cooling Water Exchangers - 21-H-805001A/B",TOTAL:5,COMPLETED:0,REMAIN:5,MC_PLAN:"2021-10-28",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8020-8025-01",SUBSYSTEM_DESCR:"HULL Service Sea Water Pumps - 21-P-802005A/B",TOTAL:6,COMPLETED:0,REMAIN:6,MC_PLAN:"2021-12-02",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8020-8026-01",SUBSYSTEM_DESCR:"HULL Service Sea Water to HVAC Cooling",TOTAL:8,COMPLETED:0,REMAIN:8,MC_PLAN:"2021-10-28",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8020-8027-01",SUBSYSTEM_DESCR:"HULL Service Sea Water Booster Pumps - 21-P-802008A/B",TOTAL:7,COMPLETED:0,REMAIN:7,MC_PLAN:"2021-11-04",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:8,PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:4,PA_OV_COM:0,PA_OV_REM:4,ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8020-8027-02",SUBSYSTEM_DESCR:"HULL Service Sea Water to Electrochlorination Package",TOTAL:2,COMPLETED:0,REMAIN:2,MC_PLAN:"2022-01-27",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8020-8027-03",SUBSYSTEM_DESCR:"HULL Service Sea Water to Potable Water Package",TOTAL:2,COMPLETED:0,REMAIN:2,MC_PLAN:"2021-11-11",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8020-8029-01",SUBSYSTEM_DESCR:"KSG Cooling Sea Water Pumps 21-P-802009A/B & Distribution",TOTAL:5,COMPLETED:1,REMAIN:4,MC_PLAN:"2021-11-04",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"In Progress",TEST_PACKAGE_PROG:23.5,PUNCH_STATUS:"N",PRIORITY:"P1A",ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8040-8040-01",SUBSYSTEM_DESCR:"Heating Medium (Hot Water) Expansion Tank",TOTAL:6,COMPLETED:0,REMAIN:6,MC_PLAN:"2022-02-24",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8040-8040-02",SUBSYSTEM_DESCR:"Heating Medium (Hot Water) Pumps & Loop / Distribution to Exchangers",TOTAL:45,COMPLETED:0,REMAIN:45,MC_PLAN:"2022-02-24",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:5,PA_OV_COM:0,PA_OV_REM:5,ACT_TAG_VALID:45,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-8050-8051-01",SUBSYSTEM_DESCR:"Marine Cooling Medium Pumps - 21-P805002A/B",TOTAL:2,COMPLETED:0,REMAIN:2,MC_PLAN:"2021-10-21",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8050-8051-02",SUBSYSTEM_DESCR:"Marine Cooling Medium Expansion Tank",TOTAL:5,COMPLETED:2,REMAIN:3,MC_PLAN:"2021-10-21",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"In Progress",TEST_PACKAGE_PROG:60,PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8050-8051-03",SUBSYSTEM_DESCR:"Marine Cooling Medium Distribution",TOTAL:9,COMPLETED:0,REMAIN:9,MC_PLAN:"2021-10-21",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:6.1,PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8050-8052-01",SUBSYSTEM_DESCR:"Topisdes Cooling Medium Pumps - 21-P805001A/B",TOTAL:7,COMPLETED:0,REMAIN:7,MC_PLAN:"2022-02-24",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:9,PA_OV_COM:0,PA_OV_REM:9,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-8050-8052-02",SUBSYSTEM_DESCR:"Start-up Cooling Medium Pumps - 21-P805003",TOTAL:4,COMPLETED:0,REMAIN:4,MC_PLAN:"2022-02-24",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8050-8052-03",SUBSYSTEM_DESCR:"Topsides Cooling Medium Expansion Tank",TOTAL:6,COMPLETED:0,REMAIN:6,MC_PLAN:"2022-02-24",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8050-8052-04",SUBSYSTEM_DESCR:"Topsides Cooling Medium Distribution & Exchangers",TOTAL:37,COMPLETED:0,REMAIN:37,MC_PLAN:"2022-02-24",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:37,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8060-8061-01",SUBSYSTEM_DESCR:"Diesel Bunker Header",TOTAL:3,COMPLETED:0,REMAIN:3,MC_PLAN:"2021-10-21",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8060-8061-02",SUBSYSTEM_DESCR:"Diesel Storage Tanks - 21-T-806001/2 & Purifier Pumps - 21-P-806002A/B",TOTAL:13,COMPLETED:0,REMAIN:13,MC_PLAN:"2021-10-28",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:14.3,PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:4,PA_OV_COM:0,PA_OV_REM:4,ACT_TAG_VALID:13,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8060-8061-03",SUBSYSTEM_DESCR:"Diesel Supply & Return (Spill Back) Headers to Service Tanks - 21-T-806003/4",TOTAL:5,COMPLETED:0,REMAIN:5,MC_PLAN:"2021-10-28",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:11.8,PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8060-8062-01",SUBSYSTEM_DESCR:"Diesel Service Tanks - 21-T-806003/4 & Supply Pumps - 21-P-806001A/B",TOTAL:16,COMPLETED:0,REMAIN:16,MC_PLAN:"2021-11-04",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:21.3,PUNCH_STATUS:"N",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8060-8062-02",SUBSYSTEM_DESCR:"Purifier Package",TOTAL:2,COMPLETED:0,REMAIN:2,MC_PLAN:"2021-10-21",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:40,PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:17,PA_OV_COM:14,PA_OV_REM:3,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8060-8063-01",SUBSYSTEM_DESCR:"Diesel Distribution Header to Users",TOTAL:17,COMPLETED:0,REMAIN:17,MC_PLAN:"2022-02-24",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:3.1,PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8090-8090-02",SUBSYSTEM_DESCR:"Sewage Collection & Discharge Headers",TOTAL:51,COMPLETED:0,REMAIN:51,MC_PLAN:"2021-12-23",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:15.7,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:51,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8100-8110-01",SUBSYSTEM_DESCR:"HULL Plant Air Distribution",TOTAL:16,COMPLETED:1,REMAIN:15,MC_PLAN:"2022-01-13",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"In Progress",TEST_PACKAGE_PROG:37.7,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8100-8111-01",SUBSYSTEM_DESCR:"Topsides Plant Air Distribution",TOTAL:14,COMPLETED:0,REMAIN:14,MC_PLAN:"2022-07-28",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8100-8120-01",SUBSYSTEM_DESCR:"HULL Air Compressor 21-Z-812010",BATCH:"Priority Batch",TOTAL:2,COMPLETED:2,REMAIN:0,MC_PLAN:"2021-10-21",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Completed",TEST_PACKAGE_PROG:100,PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:6,PA_OV_COM:6,PA_OV_REM:0,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:4},
    {SUBSYSTEM:"21-8100-8120-02",SUBSYSTEM_DESCR:"HULL Air Drier 21-Z-812020",TOTAL:2,COMPLETED:2,REMAIN:0,MC_PLAN:"2021-11-04",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Completed",TEST_PACKAGE_PROG:100,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8100-8120-03",SUBSYSTEM_DESCR:"HULL Air Receiver 21-V-812002, Main header & Controls",TOTAL:7,COMPLETED:1,REMAIN:6,MC_PLAN:"2021-10-21",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"In Progress",TEST_PACKAGE_PROG:44,PUNCH_STATUS:"N",PRIORITY:"P1A",ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8100-8121-01",SUBSYSTEM_DESCR:"HULL Instrument Air Distribution",TOTAL:40,COMPLETED:0,REMAIN:40,MC_PLAN:"2021-10-28",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:6.4,PUNCH_STATUS:"N",PRIORITY:"P1A",ACT_TAG_VALID:40,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8100-8122-01",SUBSYSTEM_DESCR:"Topside Air Compressor 21-Z-811101",TOTAL:2,COMPLETED:0,REMAIN:2,MC_PLAN:"2022-02-17",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",PB3_OV_TOT:1,PB3_OV_COM:0,PB3_OV_REM:1,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8100-8122-02",SUBSYSTEM_DESCR:"Topside Air Compressor 21-Z-811201",TOTAL:2,COMPLETED:0,REMAIN:2,MC_PLAN:"2022-02-17",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:5,PA_OV_COM:0,PA_OV_REM:5,PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8100-8122-03",SUBSYSTEM_DESCR:"Topside Air Compressor 21-Z-811301",TOTAL:2,COMPLETED:0,REMAIN:2,MC_PLAN:"2022-02-17",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8100-8122-04",SUBSYSTEM_DESCR:"Topside Air Driers 21-Z-812001",TOTAL:2,COMPLETED:0,REMAIN:2,MC_PLAN:"2022-02-17",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:7,PB1_OV_COM:0,PB1_OV_REM:7,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:7,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8100-8122-05",SUBSYSTEM_DESCR:"Topside Air Receiver 21-V-812001, Main header & Controls",TOTAL:8,COMPLETED:0,REMAIN:8,MC_PLAN:"2022-02-10",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:6.9,PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8100-8123-01",SUBSYSTEM_DESCR:"Topsides Instrument Air Distribution",TOTAL:13,COMPLETED:0,REMAIN:13,MC_PLAN:"2022-06-16",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:13,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8130-8130-01",SUBSYSTEM_DESCR:"Fuel Gas KO Drum",TOTAL:8,COMPLETED:0,REMAIN:8,MC_PLAN:"2022-05-19",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8130-8130-02",SUBSYSTEM_DESCR:"Fuel Gas Superheaters",TOTAL:8,COMPLETED:0,REMAIN:8,MC_PLAN:"2022-04-28",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8130-8131-01",SUBSYSTEM_DESCR:"Fuel Gas Blanketing Distribution in HULL",TOTAL:8,COMPLETED:0,REMAIN:8,MC_PLAN:"2021-11-25",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8130-8131-02",SUBSYSTEM_DESCR:"Fuel Gas Filters & Main Header",TOTAL:3,COMPLETED:0,REMAIN:3,MC_PLAN:"2022-06-02",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8130-8131-03",SUBSYSTEM_DESCR:"LP Fuel Gas Distribution Topsides",TOTAL:19,COMPLETED:0,REMAIN:19,MC_PLAN:"2022-06-02",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:19,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8130-8131-04",SUBSYSTEM_DESCR:"Fuel Gas Distribution to GTGs",TOTAL:2,COMPLETED:0,REMAIN:2,MC_PLAN:"2022-06-02",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8150-8150-01",SUBSYSTEM_DESCR:"Nitrogen Generator & Receiver",TOTAL:7,COMPLETED:0,REMAIN:7,MC_PLAN:"2022-03-03",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8150-8150-02",SUBSYSTEM_DESCR:"Topside Nitrogen Distribution",TOTAL:26,COMPLETED:0,REMAIN:26,MC_PLAN:"2022-04-14",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:26,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8160-8160-01",SUBSYSTEM_DESCR:"Inert Gas Generator",TOTAL:2,COMPLETED:0,REMAIN:2,MC_PLAN:"2022-06-09",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:5,PA_OV_COM:0,PA_OV_REM:5,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8160-8160-03",SUBSYSTEM_DESCR:"IGG Header",TOTAL:10,COMPLETED:0,REMAIN:10,MC_PLAN:"2022-05-26",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8310-01",SUBSYSTEM_DESCR:"Corrosion Inhibitor Tank & Pumps",TOTAL:3,COMPLETED:0,REMAIN:3,MC_PLAN:"2022-04-14",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:6,PA_OV_COM:1,PA_OV_REM:5,PB1_OV_TOT:4,PB1_OV_COM:0,PB1_OV_REM:4,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8300-8310-02",SUBSYSTEM_DESCR:"Corrosion Inhibitor Distribution",TOTAL:4,COMPLETED:0,REMAIN:4,MC_PLAN:"2022-05-26",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8320-01",SUBSYSTEM_DESCR:"Scale Inhibitor Tank & Pumps Topsides",TOTAL:3,COMPLETED:0,REMAIN:3,MC_PLAN:"2022-04-14",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8320-02",SUBSYSTEM_DESCR:"Scale Inhibitor Distribution Subsea",TOTAL:3,COMPLETED:0,REMAIN:3,MC_PLAN:"2022-06-09",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8320-03",SUBSYSTEM_DESCR:"Scale Inhibitor Distribution Topsides",TOTAL:9,COMPLETED:0,REMAIN:9,MC_PLAN:"2022-06-09",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8320-04",SUBSYSTEM_DESCR:"Scale Inhibitor Tank & Pumps Subsea",TOTAL:1,COMPLETED:0,REMAIN:1,MC_PLAN:"2022-04-14",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8300-8330-01",SUBSYSTEM_DESCR:"Wax Inhibitor Loading Header",TOTAL:3,COMPLETED:0,REMAIN:3,MC_PLAN:"2022-06-16",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8330-02",SUBSYSTEM_DESCR:"Wax Inhibitor Storage & Pumps",TOTAL:9,COMPLETED:0,REMAIN:9,MC_PLAN:"2022-02-17",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8331-01",SUBSYSTEM_DESCR:"Wax Inhibitor Filtration",TOTAL:2,COMPLETED:0,REMAIN:2,MC_PLAN:"2022-05-26",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8331-02",SUBSYSTEM_DESCR:"Wax Inhibitor Break Tank & Injection Pumps",TOTAL:12,COMPLETED:0,REMAIN:12,MC_PLAN:"2022-05-05",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8300-8332-01",SUBSYSTEM_DESCR:"Wax Inhibitor Injection Subsea Phase 1",TOTAL:1,COMPLETED:0,REMAIN:1,MC_PLAN:"2022-05-05",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8340-01",SUBSYSTEM_DESCR:"Biocide Tank & Pumps",TOTAL:5,COMPLETED:0,REMAIN:5,MC_PLAN:"2022-04-14",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:14,PA_OV_COM:5,PA_OV_REM:9,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:15,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8300-8340-02",SUBSYSTEM_DESCR:"Biocide Distribution HULL & Topsides",TOTAL:6,COMPLETED:0,REMAIN:6,MC_PLAN:"2022-05-26",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8350-01",SUBSYSTEM_DESCR:"Antifoam Tank & Pumps",TOTAL:2,COMPLETED:0,REMAIN:2,MC_PLAN:"2022-04-14",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8350-02",SUBSYSTEM_DESCR:"Antifoam Distribution",TOTAL:6,COMPLETED:0,REMAIN:6,MC_PLAN:"2022-06-16",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8360-01",SUBSYSTEM_DESCR:"Emulsion Breaker Tank & Pumps",TOTAL:4,COMPLETED:0,REMAIN:4,MC_PLAN:"2022-04-14",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8360-02",SUBSYSTEM_DESCR:"Emulsion Breaker Distribution",TOTAL:7,COMPLETED:0,REMAIN:7,MC_PLAN:"2022-04-14",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8370-01",SUBSYSTEM_DESCR:"MEG PH Stabilizer Tank & Pumps",TOTAL:3,COMPLETED:0,REMAIN:3,MC_PLAN:"2022-04-14",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8370-02",SUBSYSTEM_DESCR:"MEG PH Stabilizer Distribution",TOTAL:4,COMPLETED:0,REMAIN:4,MC_PLAN:"2022-06-16",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8375-01",SUBSYSTEM_DESCR:"MEG Antifoam Tank & Pumps",TOTAL:3,COMPLETED:0,REMAIN:3,MC_PLAN:"2022-04-14",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8300-8375-02",SUBSYSTEM_DESCR:"MEG Antifoam Distribution",TOTAL:5,COMPLETED:0,REMAIN:5,MC_PLAN:"2022-06-16",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8400-8410-01",SUBSYSTEM_DESCR:"O2 Scavanger Tank & Pumps",TOTAL:4,COMPLETED:0,REMAIN:4,MC_PLAN:"2022-04-14",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8400-8410-02",SUBSYSTEM_DESCR:"O2 Scavanger Distribution HULL & Topsides",TOTAL:3,COMPLETED:0,REMAIN:3,MC_PLAN:"2022-04-14",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8600-8611-01",SUBSYSTEM_DESCR:"GTG 1",TOTAL:17,COMPLETED:0,REMAIN:17,MC_PLAN:"2022-02-17",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:23.5,PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:2},
    {SUBSYSTEM:"21-8600-8612-01",SUBSYSTEM_DESCR:"GTG 2",TOTAL:17,COMPLETED:0,REMAIN:17,MC_PLAN:"2022-02-17",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:26.5,PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8600-8613-01",SUBSYSTEM_DESCR:"GTG 3",TOTAL:16,COMPLETED:0,REMAIN:16,MC_PLAN:"2021-12-30",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:37.5,PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,ACT_TAG_VALID:16,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8600-8614-01",SUBSYSTEM_DESCR:"GTG 4",TOTAL:17,COMPLETED:0,REMAIN:17,MC_PLAN:"2021-12-30",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:38.2,PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8800-8800-01",SUBSYSTEM_DESCR:"Ses Water Lift Pump A",TOTAL:1,COMPLETED:0,REMAIN:1,MC_PLAN:"2022-04-14",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8800-8800-02",SUBSYSTEM_DESCR:"Ses Water Lift Pump B",TOTAL:1,COMPLETED:0,REMAIN:1,MC_PLAN:"2022-04-14",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8800-8800-03",SUBSYSTEM_DESCR:"Ses Water Lift Pump C",TOTAL:1,COMPLETED:0,REMAIN:1,MC_PLAN:"2022-04-14",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8800-8800-04",SUBSYSTEM_DESCR:"Sea Water Filter & Distribution Header",TOTAL:5,COMPLETED:0,REMAIN:5,MC_PLAN:"2022-04-14",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-8800-8800-05",SUBSYSTEM_DESCR:"Sea Water Cooling Supply to Exchangers & Discharge Header",TOTAL:15,COMPLETED:0,REMAIN:15,MC_PLAN:"2022-05-12",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-8800-8805-01",SUBSYSTEM_DESCR:"Electrochlorination Unit",TOTAL:3,COMPLETED:0,REMAIN:3,MC_PLAN:"2022-04-14",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:4,PA_OV_COM:3,PA_OV_REM:1,PB1_OV_TOT:6,PB1_OV_COM:1,PB1_OV_REM:5,ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:10,PUNCH_TAG_VALID:3},
    {SUBSYSTEM:"21-8800-8805-02",SUBSYSTEM_DESCR:"Hypochlorite Distribution Topsides",TOTAL:7,COMPLETED:0,REMAIN:7,MC_PLAN:"2022-05-12",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:16,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9330-9330-01",SUBSYSTEM_DESCR:"Main Ballast Tanks WBE1 to 5, APE 1, APW 1 & WBW 1 to 5",TOTAL:14,COMPLETED:0,REMAIN:14,MC_PLAN:"2022-03-10",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:39.3,PUNCH_STATUS:"N",PRIORITY:"P1A",ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9330-9330-02",SUBSYSTEM_DESCR:"Main Ballast Ring Main",TOTAL:15,COMPLETED:0,REMAIN:15,MC_PLAN:"2022-03-10",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:28.1,PUNCH_STATUS:"N",PRIORITY:"P1A",ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9330-9330-03",SUBSYSTEM_DESCR:"Ballast Pump 21-P-935001 A",TOTAL:6,COMPLETED:0,REMAIN:6,MC_PLAN:"2022-03-10",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P1A",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-9330-9330-04",SUBSYSTEM_DESCR:"Ballast Pump 21-P-935001 B",TOTAL:6,COMPLETED:0,REMAIN:6,MC_PLAN:"2022-03-10",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P1A",ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9330-9330-05",SUBSYSTEM_DESCR:"Ballast Pump 21-P-935001 C",TOTAL:6,COMPLETED:0,REMAIN:6,MC_PLAN:"2022-03-10",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P1A",ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9330-9330-06",SUBSYSTEM_DESCR:"Ballast Pump 21-P-935001 D",TOTAL:6,COMPLETED:0,REMAIN:6,MC_PLAN:"2022-03-03",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P1A",ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9330-9333-01",SUBSYSTEM_DESCR:"Cargo & Ballast Pumps HPU & UCP",TOTAL:18,COMPLETED:0,REMAIN:18,MC_PLAN:"2021-12-09",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:2.8,PUNCH_STATUS:"A",PRIORITY:"P1A",PA_OV_TOT:15,PA_OV_COM:0,PA_OV_REM:15,PB1_OV_TOT:5,PB1_OV_COM:0,PB1_OV_REM:5,ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:20,PUNCH_TAG_VALID:5},
    {SUBSYSTEM:"21-9350-9350-01",SUBSYSTEM_DESCR:"MS Ballast Tanks WBW6,7 / WBE6,7 / FPE1 /FPW1",TOTAL:7,COMPLETED:0,REMAIN:7,MC_PLAN:"2021-12-16",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:7.1,PUNCH_STATUS:"N",PRIORITY:"P1A",ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9350-9350-02",SUBSYSTEM_DESCR:"MS Ballast Ring Main",TOTAL:2,COMPLETED:0,REMAIN:2,MC_PLAN:"2021-12-09",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P1A",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9350-9350-04",SUBSYSTEM_DESCR:"MS Ballast Pump 21-P-935002A",TOTAL:5,COMPLETED:0,REMAIN:5,MC_PLAN:"2021-12-09",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9350-9350-05",SUBSYSTEM_DESCR:"MS Ballast Pump 21-P-935002B",TOTAL:5,COMPLETED:0,REMAIN:5,MC_PLAN:"2021-12-09",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9370-9370-01",SUBSYSTEM_DESCR:"Slops Tank 21-T-937001 & Pumps 21-P-937001/3",TOTAL:17,COMPLETED:0,REMAIN:17,MC_PLAN:"2022-02-24",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9370-9371-01",SUBSYSTEM_DESCR:"Slops Tank 21-T-937002 & Pumps 21-P-937002/4",TOTAL:17,COMPLETED:0,REMAIN:17,MC_PLAN:"2021-12-23",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:17,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9370-9372-01",SUBSYSTEM_DESCR:"Hazardous Open Drain Header to Slops Tanks",TOTAL:51,COMPLETED:0,REMAIN:51,MC_PLAN:"2022-02-17",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:1,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:51,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9370-9372-02",SUBSYSTEM_DESCR:"Non Hazardous Open Drain Header to Slops Tanks",TOTAL:38,COMPLETED:0,REMAIN:38,MC_PLAN:"2022-02-17",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:7.9,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:38,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9370-9372-04",SUBSYSTEM_DESCR:"Slops Heaters 21-H-937001 A/B",TOTAL:9,COMPLETED:0,REMAIN:9,MC_PLAN:"2022-02-17",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9370-9372-07",SUBSYSTEM_DESCR:"Slops Water to Export Line Flushing",TOTAL:6,COMPLETED:0,REMAIN:6,MC_PLAN:"2022-02-17",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0},
    {SUBSYSTEM:"21-9370-9372-08",SUBSYSTEM_DESCR:"COW Hot Sea Water Filter 21-F-937001",TOTAL:6,COMPLETED:0,REMAIN:6,MC_PLAN:"2022-01-27",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P1",ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9370-9372-09",SUBSYSTEM_DESCR:"Slops Polishing & ODMS (Oil Discharge Monitoring) Units",TOTAL:4,COMPLETED:0,REMAIN:4,MC_PLAN:"2021-12-30",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:16,PA_OV_COM:0,PA_OV_REM:16,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:16,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9370-9372-10",SUBSYSTEM_DESCR:"Treated Slops Header to Overboard",TOTAL:1,COMPLETED:0,REMAIN:1,MC_PLAN:"2021-12-30",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9370-9372-11",SUBSYSTEM_DESCR:"LQ Open Drain Collection",TOTAL:12,COMPLETED:0,REMAIN:12,MC_PLAN:"2022-04-21",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:4.2,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9370-9372-12",SUBSYSTEM_DESCR:"HULL Open Drain Collection",TOTAL:3,COMPLETED:0,REMAIN:3,MC_PLAN:"2022-03-24",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P3",ACT_TAG_VALID:3,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9380-9380-01",SUBSYSTEM_DESCR:"Daily bilge Tank 21-T-938001 & Pumps 21-P-938001A/B",TOTAL:9,COMPLETED:0,REMAIN:9,MC_PLAN:"2022-01-20",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:16.7,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:9,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9380-9380-02",SUBSYSTEM_DESCR:"Daily Bilge Wells & Headers to Daily Bilge Tank",TOTAL:22,COMPLETED:0,REMAIN:22,MC_PLAN:"2022-01-20",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:25,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:22,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9380-9380-03",SUBSYSTEM_DESCR:"Bilge Water from MS Bilge Collection Header",TOTAL:13,COMPLETED:0,REMAIN:13,MC_PLAN:"2022-01-13",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:7.7,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:13,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9380-9380-04",SUBSYSTEM_DESCR:"Sludge from Diesel Purifier",TOTAL:2,COMPLETED:0,REMAIN:2,MC_PLAN:"2021-10-21",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9380-9380-05",SUBSYSTEM_DESCR:"Diesel Oil from Diesel Storage Tanks",TOTAL:2,COMPLETED:0,REMAIN:2,MC_PLAN:"2021-10-28",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9380-9380-06",SUBSYSTEM_DESCR:"Daily Bilge for CFC8 & Pump 21-P-938003",TOTAL:4,COMPLETED:0,REMAIN:4,MC_PLAN:"2022-01-13",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9380-9380-07",SUBSYSTEM_DESCR:"Daily Bilge for CFC5 & CFC6 & Pump 21-P-938004",TOTAL:6,COMPLETED:0,REMAIN:6,MC_PLAN:"2022-01-20",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:8.3,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9380-9380-08",SUBSYSTEM_DESCR:"Daily Bilge for CFC7 & Pump 21-P-938005",TOTAL:5,COMPLETED:0,REMAIN:5,MC_PLAN:"2022-01-20",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:10,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:5,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9380-9380-09",SUBSYSTEM_DESCR:"Daily Bilge for CFC1 & Pump 21-P-938006",TOTAL:6,COMPLETED:0,REMAIN:6,MC_PLAN:"2022-01-20",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:8.3,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9380-9381-01",SUBSYSTEM_DESCR:"Main bilge Pumps 21-P-938002A/B & Headers",TOTAL:6,COMPLETED:0,REMAIN:6,MC_PLAN:"2022-01-20",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:16.7,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9380-9381-02",SUBSYSTEM_DESCR:"Main bilge Water Header to Overboard",TOTAL:1,COMPLETED:0,REMAIN:1,MC_PLAN:"2022-01-20",MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9380-9382-01",SUBSYSTEM_DESCR:"Emergency Bilge well (SB middle) & header",TOTAL:1,COMPLETED:0,REMAIN:1,MC_PLAN:"2022-01-20",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:50,PUNCH_STATUS:"N",PRIORITY:"P2",ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1},
    {SUBSYSTEM:"21-9390-9390-01",SUBSYSTEM_DESCR:"Bunker Station & HPU",TOTAL:1,COMPLETED:0,REMAIN:1,MC_PLAN:"2022-04-21",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:0,PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:3,PA_OV_COM:0,PA_OV_REM:3,PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0,ACT_TAG_VALID:1,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:1},
    {SUBSYSTEM:"21-9970-9971-06",SUBSYSTEM_DESCR:"HULL Chilled Water System & UCP",TOTAL:20,COMPLETED:0,REMAIN:20,MC_PLAN:"2021-12-23",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",TEST_PACKAGE_STATUS:"Not Started",TEST_PACKAGE_PROG:17.5,PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:17,PA_OV_COM:0,PA_OV_REM:17,ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:17,PUNCH_TAG_VALID:7}
    ],
  Queries: {
    SQL1: [
      { DESC: 'DataSource SPCS Data 03-Mar-20' }
    ],
    SQL2: [
      { CDATE: '2021-10-21' }
    ],
    SQL3: [
      {MC_PLAN:"2021-10-21",A_T:42,A_A:5,A_O:37,PA_O:6},
      {MC_PLAN:"2021-10-28",A_T:77,A_A:0,A_O:77,PA_O:4},
      {MC_PLAN:"2021-11-04",A_T:72,A_A:5,A_O:67,PA_O:22},
      {MC_PLAN:"2021-11-11",A_T:27,A_A:2,A_O:25,PA_O:9},
      {MC_PLAN:"2021-11-18",A_T:12,A_A:0,A_O:12,PA_O:19},
      {MC_PLAN:"2021-11-25",A_T:74,A_A:1,A_O:73,PA_O:10},
      {MC_PLAN:"2021-12-02",A_T:6,A_A:0,A_O:6,PA_O:2},
      {MC_PLAN:"2021-12-09",A_T:30,A_A:0,A_O:30,PA_O:15},
      {MC_PLAN:"2021-12-16",A_T:7,A_A:0,A_O:7},
      {MC_PLAN:"2021-12-23",A_T:116,A_A:0,A_O:116,PA_O:19},
      {MC_PLAN:"2021-12-30",A_T:51,A_A:0,A_O:51,PA_O:16},
      {MC_PLAN:"2022-01-06",A_T:32,A_A:0,A_O:32,PA_O:1},
      {MC_PLAN:"2022-01-13",A_T:83,A_A:1,A_O:82},
      {MC_PLAN:"2022-01-20",A_T:65,A_A:1,A_O:64},
      {MC_PLAN:"2022-01-27",A_T:109,A_A:3,A_O:106,PA_O:23},
      {MC_PLAN:"2022-02-03"},
      {MC_PLAN:"2022-02-10",A_T:8,A_A:0,A_O:8},
      {MC_PLAN:"2022-02-17",A_T:223,A_A:0,A_O:223,PA_O:10},
      {MC_PLAN:"2022-02-24",A_T:193,A_A:0,A_O:193,PA_O:15},
      {MC_PLAN:"2022-03-03",A_T:13,A_A:0,A_O:13,PA_O:0},
      {MC_PLAN:"2022-03-10",A_T:125,A_A:0,A_O:125,PA_O:2},
      {MC_PLAN:"2022-03-17"},
      {MC_PLAN:"2022-03-24",A_T:3,A_A:0,A_O:3},
      {MC_PLAN:"2022-03-31",A_T:7,A_A:0,A_O:7,PA_O:4},
      {MC_PLAN:"2022-04-07",A_T:9,A_A:0,A_O:9,PA_O:35},
      {MC_PLAN:"2022-04-14",A_T:75,A_A:0,A_O:75,PA_O:18},
      {MC_PLAN:"2022-04-21",A_T:16,A_A:0,A_O:16,PA_O:4},
      {MC_PLAN:"2022-04-28",A_T:52,A_A:0,A_O:52,PA_O:10},
      {MC_PLAN:"2022-05-05",A_T:68,A_A:0,A_O:68,PA_O:2},
      {MC_PLAN:"2022-05-12",A_T:81,A_A:0,A_O:81,PA_O:5},
      {MC_PLAN:"2022-05-19",A_T:66,A_A:0,A_O:66,PA_O:1},
      {MC_PLAN:"2022-05-26",A_T:146,A_A:0,A_O:146,PA_O:1},
      {MC_PLAN:"2022-06-02",A_T:83,A_A:0,A_O:83,PA_O:5},
      {MC_PLAN:"2022-06-09",A_T:36,A_A:0,A_O:36,PA_O:16},
      {MC_PLAN:"2022-06-16",A_T:43,A_A:0,A_O:43},
      {MC_PLAN:"2022-06-23",A_T:16,A_A:0,A_O:16,PA_O:11},
      {MC_PLAN:"2022-06-30",A_T:1,A_A:0,A_O:1},
      {MC_PLAN:"2022-07-07",A_T:67,A_A:0,A_O:67,PA_O:3},
      {MC_PLAN:"2022-07-14",A_T:39,A_A:0,A_O:39,PA_O:4},
      {MC_PLAN:"2022-07-21",A_T:3,A_A:0,A_O:3},
      {MC_PLAN:"2022-07-28",A_T:14,A_A:0,A_O:14},
      {MC_PLAN:"2022-08-04",A_T:48,A_A:0,A_O:48},
      {MC_PLAN:"2022-08-11"}
      ],
    SQL4: [
      {CUTOFF:"2018-06-21",WK_NO:"W2018_25"},
      {CUTOFF:"2018-06-28",WK_NO:"W2018_26"},
      {CUTOFF:"2018-07-05",WK_NO:"W2018_27"},
      {CUTOFF:"2018-07-12",WK_NO:"W2018_28"},
      {CUTOFF:"2018-07-19",WK_NO:"W2018_29"},
      {CUTOFF:"2018-07-26",WK_NO:"W2018_30"},
      {CUTOFF:"2018-08-02",WK_NO:"W2018_31"},
      {CUTOFF:"2018-08-09",WK_NO:"W2018_32"},
      {CUTOFF:"2018-08-16",WK_NO:"W2018_33"},
      {CUTOFF:"2018-08-23",WK_NO:"W2018_34"},
      {CUTOFF:"2018-08-30",WK_NO:"W2018_35"},
      {CUTOFF:"2018-09-06",WK_NO:"W2018_36"},
      {CUTOFF:"2018-09-13",WK_NO:"W2018_37"},
      {CUTOFF:"2018-09-20",WK_NO:"W2018_38"},
      {CUTOFF:"2018-09-27",WK_NO:"W2018_39"},
      {CUTOFF:"2018-10-04",WK_NO:"W2018_40"},
      {CUTOFF:"2018-10-11",WK_NO:"W2018_41"},
      {CUTOFF:"2018-10-18",WK_NO:"W2018_42"},
      {CUTOFF:"2018-10-25",WK_NO:"W2018_43"},
      {CUTOFF:"2018-11-01",WK_NO:"W2018_44"},
      {CUTOFF:"2018-11-08",WK_NO:"W2018_45"},
      {CUTOFF:"2018-11-15",WK_NO:"W2018_46"},
      {CUTOFF:"2018-11-22",WK_NO:"W2018_47"},
      {CUTOFF:"2018-11-29",WK_NO:"W2018_48"},
      {CUTOFF:"2018-12-06",WK_NO:"W2018_49"},
      {CUTOFF:"2018-12-13",WK_NO:"W2018_50"},
      {CUTOFF:"2018-12-20",WK_NO:"W2018_51"},
      {CUTOFF:"2018-12-27",WK_NO:"W2018_52"},
      {CUTOFF:"2019-01-03",WK_NO:"W2019_01"},
      {CUTOFF:"2019-01-10",WK_NO:"W2019_02"},
      {CUTOFF:"2019-01-17",WK_NO:"W2019_03"},
      {CUTOFF:"2019-01-24",WK_NO:"W2019_04"},
      {CUTOFF:"2019-01-31",WK_NO:"W2019_05"},
      {CUTOFF:"2019-02-07",WK_NO:"W2019_06"},
      {CUTOFF:"2019-02-14",WK_NO:"W2019_07"},
      {CUTOFF:"2019-02-21",WK_NO:"W2019_08"},
      {CUTOFF:"2019-02-28",WK_NO:"W2019_09"},
      {CUTOFF:"2019-03-07",WK_NO:"W2019_10"},
      {CUTOFF:"2019-03-14",WK_NO:"W2019_11"},
      {CUTOFF:"2019-03-21",WK_NO:"W2019_12"},
      {CUTOFF:"2019-03-28",WK_NO:"W2019_13"},
      {CUTOFF:"2019-04-04",WK_NO:"W2019_14"},
      {CUTOFF:"2019-04-11",WK_NO:"W2019_15"},
      {CUTOFF:"2019-04-18",WK_NO:"W2019_16"},
      {CUTOFF:"2019-04-25",WK_NO:"W2019_17"},
      {CUTOFF:"2019-05-02",WK_NO:"W2019_18"},
      {CUTOFF:"2019-05-09",WK_NO:"W2019_19"},
      {CUTOFF:"2019-05-16",WK_NO:"W2019_20"},
      {CUTOFF:"2019-05-23",WK_NO:"W2019_21"},
      {CUTOFF:"2019-05-30",WK_NO:"W2019_22"},
      {CUTOFF:"2019-06-06",WK_NO:"W2019_23"},
      {CUTOFF:"2019-06-13",WK_NO:"W2019_24"},
      {CUTOFF:"2019-06-20",WK_NO:"W2019_25"},
      {CUTOFF:"2019-06-27",WK_NO:"W2019_26"},
      {CUTOFF:"2019-07-04",WK_NO:"W2019_27"},
      {CUTOFF:"2019-07-11",WK_NO:"W2019_28"},
      {CUTOFF:"2019-07-18",WK_NO:"W2019_29"},
      {CUTOFF:"2019-07-25",WK_NO:"W2019_30"},
      {CUTOFF:"2019-08-01",WK_NO:"W2019_31"},
      {CUTOFF:"2019-08-08",WK_NO:"W2019_32"},
      {CUTOFF:"2019-08-15",WK_NO:"W2019_33"},
      {CUTOFF:"2019-08-22",WK_NO:"W2019_34"},
      {CUTOFF:"2019-08-29",WK_NO:"W2019_35"},
      {CUTOFF:"2019-09-05",WK_NO:"W2019_36"},
      {CUTOFF:"2019-09-12",WK_NO:"W2019_37"},
      {CUTOFF:"2019-09-19",WK_NO:"W2019_38"},
      {CUTOFF:"2019-09-26",WK_NO:"W2019_39"},
      {CUTOFF:"2019-10-03",WK_NO:"W2019_40"},
      {CUTOFF:"2019-10-10",WK_NO:"W2019_41"},
      {CUTOFF:"2019-10-17",WK_NO:"W2019_42"},
      {CUTOFF:"2019-10-24",WK_NO:"W2019_43"},
      {CUTOFF:"2019-10-31",WK_NO:"W2019_44"},
      {CUTOFF:"2019-11-07",WK_NO:"W2019_45"},
      {CUTOFF:"2019-11-14",WK_NO:"W2019_46"},
      {CUTOFF:"2019-11-21",WK_NO:"W2019_47"},
      {CUTOFF:"2019-11-28",WK_NO:"W2019_48"},
      {CUTOFF:"2019-12-05",WK_NO:"W2019_49"},
      {CUTOFF:"2019-12-12",WK_NO:"W2019_50"},
      {CUTOFF:"2019-12-19",WK_NO:"W2019_51"},
      {CUTOFF:"2019-12-26",WK_NO:"W2019_52"},
      {CUTOFF:"2020-01-02",WK_NO:"W2020_01"},
      {CUTOFF:"2020-01-09",WK_NO:"W2020_02"},
      {CUTOFF:"2020-01-16",WK_NO:"W2020_03"},
      {CUTOFF:"2020-01-23",WK_NO:"W2020_04"},
      {CUTOFF:"2020-01-30",WK_NO:"W2020_05"},
      {CUTOFF:"2020-02-06",WK_NO:"W2020_06"},
      {CUTOFF:"2020-02-13",WK_NO:"W2020_07"},
      {CUTOFF:"2020-02-20",WK_NO:"W2020_08"},
      {CUTOFF:"2020-02-27",WK_NO:"W2020_09"},
      {CUTOFF:"2020-03-05",WK_NO:"W2020_10"},
      {CUTOFF:"2020-03-12",WK_NO:"W2020_11"},
      {CUTOFF:"2020-03-19",WK_NO:"W2020_12"},
      {CUTOFF:"2020-03-26",WK_NO:"W2020_13"},
      {CUTOFF:"2020-04-02",WK_NO:"W2020_14"},
      {CUTOFF:"2020-04-09",WK_NO:"W2020_15"},
      {CUTOFF:"2020-04-16",WK_NO:"W2020_16"},
      {CUTOFF:"2020-04-23",WK_NO:"W2020_17"},
      {CUTOFF:"2020-04-30",WK_NO:"W2020_18"},
      {CUTOFF:"2020-05-07",WK_NO:"W2020_19"},
      {CUTOFF:"2020-05-14",WK_NO:"W2020_20"},
      {CUTOFF:"2020-05-21",WK_NO:"W2020_21"},
      {CUTOFF:"2020-05-28",WK_NO:"W2020_22"},
      {CUTOFF:"2020-06-04",WK_NO:"W2020_23"},
      {CUTOFF:"2020-06-11",WK_NO:"W2020_24"},
      {CUTOFF:"2020-06-18",WK_NO:"W2020_25"},
      {CUTOFF:"2020-06-25",WK_NO:"W2020_26"},
      {CUTOFF:"2020-07-02",WK_NO:"W2020_27"},
      {CUTOFF:"2020-07-09",WK_NO:"W2020_28"},
      {CUTOFF:"2020-07-16",WK_NO:"W2020_29"},
      {CUTOFF:"2020-07-23",WK_NO:"W2020_30"},
      {CUTOFF:"2020-07-30",WK_NO:"W2020_31"},
      {CUTOFF:"2020-08-06",WK_NO:"W2020_32"},
      {CUTOFF:"2020-08-13",WK_NO:"W2020_33"},
      {CUTOFF:"2020-08-20",WK_NO:"W2020_34"},
      {CUTOFF:"2020-08-27",WK_NO:"W2020_35"},
      {CUTOFF:"2020-09-03",WK_NO:"W2020_36"},
      {CUTOFF:"2020-09-10",WK_NO:"W2020_37"},
      {CUTOFF:"2020-09-17",WK_NO:"W2020_38"},
      {CUTOFF:"2020-09-24",WK_NO:"W2020_39"},
      {CUTOFF:"2020-10-01",WK_NO:"W2020_40"},
      {CUTOFF:"2020-10-08",WK_NO:"W2020_41"},
      {CUTOFF:"2020-10-15",WK_NO:"W2020_42"},
      {CUTOFF:"2020-10-22",WK_NO:"W2020_43"},
      {CUTOFF:"2020-10-29",WK_NO:"W2020_44"},
      {CUTOFF:"2020-11-05",WK_NO:"W2020_45"},
      {CUTOFF:"2020-11-12",WK_NO:"W2020_46"},
      {CUTOFF:"2020-11-19",WK_NO:"W2020_47"},
      {CUTOFF:"2020-11-26",WK_NO:"W2020_48"},
      {CUTOFF:"2020-12-03",WK_NO:"W2020_49"},
      {CUTOFF:"2020-12-10",WK_NO:"W2020_50"},
      {CUTOFF:"2020-12-17",WK_NO:"W2020_51"},
      {CUTOFF:"2020-12-24",WK_NO:"W2020_52"},
      {CUTOFF:"2020-12-31",WK_NO:"W2020_53"},
      {CUTOFF:"2021-01-07",WK_NO:"W2021_01"},
      {CUTOFF:"2021-01-14",WK_NO:"W2021_02"},
      {CUTOFF:"2021-01-21",WK_NO:"W2021_03"},
      {CUTOFF:"2021-01-28",WK_NO:"W2021_04"},
      {CUTOFF:"2021-02-04",WK_NO:"W2021_05"},
      {CUTOFF:"2021-02-11",WK_NO:"W2021_06"},
      {CUTOFF:"2021-02-18",WK_NO:"W2021_07"},
      {CUTOFF:"2021-02-25",WK_NO:"W2021_08"},
      {CUTOFF:"2021-03-04",WK_NO:"W2021_09"},
      {CUTOFF:"2021-03-11",WK_NO:"W2021_10"},
      {CUTOFF:"2021-03-18",WK_NO:"W2021_11"},
      {CUTOFF:"2021-03-25",WK_NO:"W2021_12"},
      {CUTOFF:"2021-04-01",WK_NO:"W2021_13"},
      {CUTOFF:"2021-04-08",WK_NO:"W2021_14"},
      {CUTOFF:"2021-04-15",WK_NO:"W2021_15"},
      {CUTOFF:"2021-04-22",WK_NO:"W2021_16"},
      {CUTOFF:"2021-04-29",WK_NO:"W2021_17"},
      {CUTOFF:"2021-05-06",WK_NO:"W2021_18"},
      {CUTOFF:"2021-05-13",WK_NO:"W2021_19"},
      {CUTOFF:"2021-05-20",WK_NO:"W2021_20"},
      {CUTOFF:"2021-05-27",WK_NO:"W2021_21"},
      {CUTOFF:"2021-06-03",WK_NO:"W2021_22"},
      {CUTOFF:"2021-06-10",WK_NO:"W2021_23"},
      {CUTOFF:"2021-06-17",WK_NO:"W2021_24"},
      {CUTOFF:"2021-06-24",WK_NO:"W2021_25"},
      {CUTOFF:"2021-07-01",WK_NO:"W2021_26"},
      {CUTOFF:"2021-07-08",WK_NO:"W2021_27"},
      {CUTOFF:"2021-07-15",WK_NO:"W2021_28"},
      {CUTOFF:"2021-07-22",WK_NO:"W2021_29"},
      {CUTOFF:"2021-07-29",WK_NO:"W2021_30"},
      {CUTOFF:"2021-08-05",WK_NO:"W2021_31"},
      {CUTOFF:"2021-08-12",WK_NO:"W2021_32"},
      {CUTOFF:"2021-08-19",WK_NO:"W2021_33"},
      {CUTOFF:"2021-08-26",WK_NO:"W2021_34"},
      {CUTOFF:"2021-09-02",WK_NO:"W2021_35"},
      {CUTOFF:"2021-09-09",WK_NO:"W2021_36"},
      {CUTOFF:"2021-09-16",WK_NO:"W2021_37"},
      {CUTOFF:"2021-09-23",WK_NO:"W2021_38"},
      {CUTOFF:"2021-09-30",WK_NO:"W2021_39"},
      {CUTOFF:"2021-10-07",WK_NO:"W2021_40"},
      {CUTOFF:"2021-10-14",WK_NO:"W2021_41"},
      {CUTOFF:"2021-10-21",WK_NO:"W2021_42"},
      {CUTOFF:"2021-10-28",WK_NO:"W2021_43"},
      {CUTOFF:"2021-11-04",WK_NO:"W2021_44"},
      {CUTOFF:"2021-11-11",WK_NO:"W2021_45"},
      {CUTOFF:"2021-11-18",WK_NO:"W2021_46"},
      {CUTOFF:"2021-11-25",WK_NO:"W2021_47"},
      {CUTOFF:"2021-12-02",WK_NO:"W2021_48"},
      {CUTOFF:"2021-12-09",WK_NO:"W2021_49"},
      {CUTOFF:"2021-12-16",WK_NO:"W2021_50"},
      {CUTOFF:"2021-12-23",WK_NO:"W2021_51"},
      {CUTOFF:"2021-12-30",WK_NO:"W2021_52"},
      {CUTOFF:"2022-01-06",WK_NO:"W2022_01"},
      {CUTOFF:"2022-01-13",WK_NO:"W2022_02"},
      {CUTOFF:"2022-01-20",WK_NO:"W2022_03"},
      {CUTOFF:"2022-01-27",WK_NO:"W2022_04"},
      {CUTOFF:"2022-02-03",WK_NO:"W2022_05"},
      {CUTOFF:"2022-02-10",WK_NO:"W2022_06"},
      {CUTOFF:"2022-02-17",WK_NO:"W2022_07"},
      {CUTOFF:"2022-02-24",WK_NO:"W2022_08"},
      {CUTOFF:"2022-03-03",WK_NO:"W2022_09"},
      {CUTOFF:"2022-03-10",WK_NO:"W2022_10"},
      {CUTOFF:"2022-03-17",WK_NO:"W2022_11"},
      {CUTOFF:"2022-03-24",WK_NO:"W2022_12"},
      {CUTOFF:"2022-03-31",WK_NO:"W2022_13"},
      {CUTOFF:"2022-04-07",WK_NO:"W2022_14"},
      {CUTOFF:"2022-04-14",WK_NO:"W2022_15"},
      {CUTOFF:"2022-04-21",WK_NO:"W2022_16"},
      {CUTOFF:"2022-04-28",WK_NO:"W2022_17"},
      {CUTOFF:"2022-05-05",WK_NO:"W2022_18"},
      {CUTOFF:"2022-05-12",WK_NO:"W2022_19"},
      {CUTOFF:"2022-05-19",WK_NO:"W2022_20"},
      {CUTOFF:"2022-05-26",WK_NO:"W2022_21"},
      {CUTOFF:"2022-06-02",WK_NO:"W2022_22"},
      {CUTOFF:"2022-06-09",WK_NO:"W2022_23"},
      {CUTOFF:"2022-06-16",WK_NO:"W2022_24"},
      {CUTOFF:"2022-06-23",WK_NO:"W2022_25"},
      {CUTOFF:"2022-06-30",WK_NO:"W2022_26"},
      {CUTOFF:"2022-07-07",WK_NO:"W2022_27"},
      {CUTOFF:"2022-07-14",WK_NO:"W2022_28"},
      {CUTOFF:"2022-07-21",WK_NO:"W2022_29"},
      {CUTOFF:"2022-07-28",WK_NO:"W2022_30"},
      {CUTOFF:"2022-08-04",WK_NO:"W2022_31"},
      {CUTOFF:"2022-08-11",WK_NO:"W2022_32"},
      {CUTOFF:"2022-08-18",WK_NO:"W2022_33"},
      {CUTOFF:"2022-08-25",WK_NO:"W2022_34"},
      {CUTOFF:"2022-09-01",WK_NO:"W2022_35"},
      {CUTOFF:"2022-09-08",WK_NO:"W2022_36"},
      {CUTOFF:"2022-09-15",WK_NO:"W2022_37"},
      {CUTOFF:"2022-09-22",WK_NO:"W2022_38"},
      {CUTOFF:"2022-09-29",WK_NO:"W2022_39"},
      {CUTOFF:"2022-10-06",WK_NO:"W2022_40"},
      {CUTOFF:"2022-10-13",WK_NO:"W2022_41"},
      {CUTOFF:"2022-10-20",WK_NO:"W2022_42"},
      {CUTOFF:"2022-10-27",WK_NO:"W2022_43"},
      {CUTOFF:"2022-11-03",WK_NO:"W2022_44"},
      {CUTOFF:"2022-11-10",WK_NO:"W2022_45"},
      {CUTOFF:"2022-11-17",WK_NO:"W2022_46"},
      {CUTOFF:"2022-11-24",WK_NO:"W2022_47"},
      {CUTOFF:"2022-12-01",WK_NO:"W2022_48"},
      {CUTOFF:"2022-12-08",WK_NO:"W2022_49"},
      {CUTOFF:"2022-12-15",WK_NO:"W2022_50"},
      {CUTOFF:"2022-12-22",WK_NO:"W2022_51"},
      {CUTOFF:"2022-12-29",WK_NO:"W2022_52"}
    ],
    SQL5: [
      {TOTAL:242,NOTSTARTED:229,INPROGRESS:11,COMPLETED:2}
    ],
    SQL6: [
      {CUTOFF:"2021-07-30",WK_NO:"W2021_30",TOTAL:15342,REMAIN:16199,WKS_TREND:10000},
      {CUTOFF:"2021-08-06",WK_NO:"W2021_31",TOTAL:14223,REMAIN:16047,WKS_TREND:10000},
      {CUTOFF:"2021-09-03",WK_NO:"W2021_35",TOTAL:3762,WKS_TREND:10000},
      {CUTOFF:"2021-09-10",WK_NO:"W2021_36",TOTAL:2638,WKS_TREND:10000},
      {CUTOFF:"2021-10-01",WK_NO:"W2021_39",TOTAL:0,WKS_TREND:10000},
      {CUTOFF:"2021-08-13",WK_NO:"W2021_32",TOTAL:13115,REMAIN:15886,WKS_TREND:10000},
      {CUTOFF:"2021-08-20",WK_NO:"W2021_33",TOTAL:6861,REMAIN:6861,WKS_TREND:10000},
      {CUTOFF:"2021-08-27",WK_NO:"W2021_34",TOTAL:5320,REMAIN:15724,WKS_TREND:10000},
      {CUTOFF:"2021-09-17",WK_NO:"W2021_37",TOTAL:1393,WKS_TREND:10000},
      {CUTOFF:"2021-09-24",WK_NO:"W2021_38",TOTAL:974,WKS_TREND:10000}
    ]
  }
}