import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 700,
      CanvasHeight: 330,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Support Fabrication', x:20, y: -20, font: 'roboto', class: 'regular', size: 12, color: '#424242', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: -50, y: -20, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'start' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: -30,
          y: -20,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'start'
        },
      ]
    },
    Queries: {
      // SQL1: [
      //   {ORIGINATING_COMPANY:"E2 - Sinopec Engineering Incorporation",ISSUED:124,RESPONDED:104,OPEN:15,CANCELLED:5},
      //   {ORIGINATING_COMPANY:"EM - ExxonMobil Chemical",ISSUED:230,RESPONDED:179,OPEN:46,CANCELLED:5},
      //   {ORIGINATING_COMPANY:"Total",ISSUED:354,RESPONDED:283,OPEN:61,CANCELLED:10}
      // ],
    },
    DataItems:    [
      {COLLECTION:"P0 - General",ISSUED:70,RESPONDED:64,OPEN:6,CANCELLED:0},
      {COLLECTION:"P1 - Olefins",ISSUED:87,RESPONDED:74,OPEN:11,CANCELLED:2},
      {COLLECTION:"P2 - Polymers, P3 - Infrastructures",ISSUED:2,RESPONDED:2,OPEN:0,CANCELLED:0},
      {COLLECTION:"P2 - Polymers",ISSUED:104,RESPONDED:88,OPEN:14,CANCELLED:2},
      {COLLECTION:"P3 - Infrastructures",ISSUED:43,RESPONDED:37,OPEN:6,CANCELLED:0},
      {COLLECTION:"NA",ISSUED:48,RESPONDED:18,OPEN:24,CANCELLED:6},
      {COLLECTION:"Total",ISSUED:354,RESPONDED:283,OPEN:61,CANCELLED:10}

    ],
}