import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1250,
      CanvasHeight: 800,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Weight : ', x:240, y: -20, font: 'roboto', class: 'regular', size: 11, color: '#BCBCBC', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: 240, y: -20, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'end' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: 240,
          y: -20,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'end'
        },
      ]
    },
    DataItems: [
      // {CONTENT_OF_BID_SECTION:"P1",STATUS:"COMPLETE",BID_SECTION_PACK:"S1",BID_SECTION_DESCR:"OR",TOTAL:1,ITB_COMPLETE:1,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:1,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"10th Company (STCC)"},
      // {CONTENT_OF_BID_SECTION:"P1",STATUS:"COMPLETE",BID_SECTION_PACK:"S2",BID_SECTION_DESCR:"Co-products and Boiler",TOTAL:6,ITB_COMPLETE:6,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:6,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"SNEI"},
      // {CONTENT_OF_BID_SECTION:"P2",STATUS:"COMPLETE",BID_SECTION_PACK:"S3",BID_SECTION_DESCR:"LDPE",TOTAL:1,ITB_COMPLETE:1,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:1,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"SNEI"},
      // {CONTENT_OF_BID_SECTION:"P2",STATUS:"COMPLETE",BID_SECTION_PACK:"S4",BID_SECTION_DESCR:"PP, #1 Packaging and WH",TOTAL:5,ITB_COMPLETE:5,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:5,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"5th Company"},
      // {CONTENT_OF_BID_SECTION:"P2",STATUS:"COMPLETE",BID_SECTION_PACK:"S5",BID_SECTION_DESCR:"GPPE, #2 Packaging and WH",TOTAL:6,ITB_COMPLETE:6,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:6,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"4th Company"},
      // {CONTENT_OF_BID_SECTION:"P3",STATUS:"COMPLETE",BID_SECTION_PACK:"S9",BID_SECTION_DESCR:"220KV Sub",TOTAL:1,ITB_COMPLETE:1,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:1,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"Hainan Electric"},
      // {CONTENT_OF_BID_SECTION:"P3",STATUS:"COMPLETE",BID_SECTION_PACK:"S12",BID_SECTION_DESCR:"Cooling Water 1 and 2",TOTAL:4,ITB_COMPLETE:4,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:4,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"5th Company"},
      // {CONTENT_OF_BID_SECTION:"P3",STATUS:"COMPLETE",BID_SECTION_PACK:"S13",BID_SECTION_DESCR:"Ethylene, Pressurized Tank Farm",TOTAL:9,ITB_COMPLETE:9,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:9,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"5th Company"},
      // {CONTENT_OF_BID_SECTION:"P3",STATUS:"COMPLETE",BID_SECTION_PACK:"S16",BID_SECTION_DESCR:"Ground Flare, Elevated Flare",TOTAL:3,ITB_COMPLETE:3,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:3,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"SYPEC"},
      // {CONTENT_OF_BID_SECTION:"P3",STATUS:"COMPLETE",BID_SECTION_PACK:"S18",BID_SECTION_DESCR:"Overall Interconn. A.G1",TOTAL:5,ITB_COMPLETE:5,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:5,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"5th Company"},
      // {CONTENT_OF_BID_SECTION:"P3",STATUS:"COMPLETE",BID_SECTION_PACK:"S19",BID_SECTION_DESCR:"Overall Interconn. A.G2",TOTAL:2,ITB_COMPLETE:2,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:2,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"13th CNCEC"},
      // {CONTENT_OF_BID_SECTION:"P3",STATUS:"ONTRACK",BID_SECTION_PACK:"S8",BID_SECTION_DESCR:"Building Décoration",TOTAL:1,ITB_COMPLETE:0,ITB_ONTRACK:1,ITB_BEHINDPLAN:0,LOA_COMPLETE:0,LOA_ONTRACK:1,LOA_BEHINDPLAN:0,STATUS_COLOR:"#FFF176"},
      // {CONTENT_OF_BID_SECTION:"P3",STATUS:"ONTRACK",BID_SECTION_PACK:"S10",BID_SECTION_DESCR:"Factory Greening",TOTAL:1,ITB_COMPLETE:0,ITB_ONTRACK:1,ITB_BEHINDPLAN:0,LOA_COMPLETE:0,LOA_ONTRACK:1,LOA_BEHINDPLAN:0,STATUS_COLOR:"#FFF176"},
      // {CONTENT_OF_BID_SECTION:"P3",STATUS:"ONTRACK",BID_SECTION_PACK:"S11",BID_SECTION_DESCR:"Chemical WH, Maint. Workshop",TOTAL:6,ITB_COMPLETE:0,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:0,LOA_ONTRACK:6,LOA_BEHINDPLAN:0,STATUS_COLOR:"#FFF176"},
      // {CONTENT_OF_BID_SECTION:"P3",STATUS:"BEHINDPLAN",BID_SECTION_PACK:"S6",BID_SECTION_DESCR:"B+Area (Management Building, CCB and Lab)",TOTAL:7,ITB_COMPLETE:7,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:0,LOA_ONTRACK:0,LOA_BEHINDPLAN:7,STATUS_COLOR:"#EF9A9A"},
      // {CONTENT_OF_BID_SECTION:"P3",STATUS:"BEHINDPLAN",BID_SECTION_PACK:"S7",BID_SECTION_DESCR:"Admin Building",TOTAL:2,ITB_COMPLETE:2,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:0,LOA_ONTRACK:0,LOA_BEHINDPLAN:2,STATUS_COLOR:"#EF9A9A"},
      // {CONTENT_OF_BID_SECTION:"P3",STATUS:"BEHINDPLAN",BID_SECTION_PACK:"S14",BID_SECTION_DESCR:"Atmospheric Tank Farm",TOTAL:8,ITB_COMPLETE:8,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:0,LOA_ONTRACK:0,LOA_BEHINDPLAN:8,STATUS_COLOR:"#EF9A9A"},
      // {CONTENT_OF_BID_SECTION:"P3",STATUS:"BEHINDPLAN",BID_SECTION_PACK:"S15",BID_SECTION_DESCR:"Intermediate Tank Farm 1, 2",TOTAL:3,ITB_COMPLETE:3,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:0,LOA_ONTRACK:0,LOA_BEHINDPLAN:3,STATUS_COLOR:"#EF9A9A"},
      // {CONTENT_OF_BID_SECTION:"P3",STATUS:"BEHINDPLAN",BID_SECTION_PACK:"S17",BID_SECTION_DESCR:"WWT Pre-treatment",TOTAL:5,ITB_COMPLETE:5,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:0,LOA_ONTRACK:0,LOA_BEHINDPLAN:5,STATUS_COLOR:"#EF9A9A"},
      // {CONTENT_OF_BID_SECTION:"Other",STATUS:"COMPLETE",BID_SECTION_PACK:"S20",BID_SECTION_DESCR:"Heavy Lifting",TOTAL:1,ITB_COMPLETE:1,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:1,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"Sinopec Heavy Lifting "},
      // {CONTENT_OF_BID_SECTION:"Other",STATUS:"BEHINDPLAN",BID_SECTION_PACK:"S21",BID_SECTION_DESCR:"TCF",TOTAL:1,ITB_COMPLETE:1,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:0,LOA_ONTRACK:0,LOA_BEHINDPLAN:1,STATUS_COLOR:"#EF9A9A"}
    
      {CONTENT_OF_BID_SECTION:"P1",STATUS:"COMPLETE",BID_SECTION_PACK:"S1",BID_SECTION_DESCR:"OR",TOTAL:1,ITB_COMPLETE:1,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:1,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"Sinopec Tenth Construction Company"},
      {CONTENT_OF_BID_SECTION:"P1",STATUS:"COMPLETE",BID_SECTION_PACK:"S2",BID_SECTION_DESCR:"Co-products and Boiler",TOTAL:6,ITB_COMPLETE:6,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:6,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"Sinopec Nanjing Engineering Co. Ltd"},
      {CONTENT_OF_BID_SECTION:"P1",STATUS:"COMPLETE",BID_SECTION_PACK:"S13",BID_SECTION_DESCR:"Ethylene, Pressurized Tank Farm",TOTAL:9,ITB_COMPLETE:9,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:9,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"Sinopec Fifth Construction Company"},
      {CONTENT_OF_BID_SECTION:"P1",STATUS:"COMPLETE",BID_SECTION_PACK:"S14",BID_SECTION_DESCR:"Atmospheric Tank Farm",TOTAL:8,ITB_COMPLETE:8,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:8,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"China Chemical Construction No. 7 Company"},
      {CONTENT_OF_BID_SECTION:"P1",STATUS:"COMPLETE",BID_SECTION_PACK:"S15",BID_SECTION_DESCR:"Intermediate Tank Farm 1, 2",TOTAL:3,ITB_COMPLETE:3,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:3,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"Jiangsu Weida Construction Group Co. Ltd."},
      {CONTENT_OF_BID_SECTION:"P2",STATUS:"COMPLETE",BID_SECTION_PACK:"S3",BID_SECTION_DESCR:"LDPE",TOTAL:1,ITB_COMPLETE:1,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:1,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"Sinopec Nanjing Engineering Co. Ltd"},
      {CONTENT_OF_BID_SECTION:"P2",STATUS:"COMPLETE",BID_SECTION_PACK:"S4",BID_SECTION_DESCR:"PP, #1 Packaging and WH",TOTAL:5,ITB_COMPLETE:5,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:5,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"Sinopec Fifth Construction Company"},
      {CONTENT_OF_BID_SECTION:"P2",STATUS:"COMPLETE",BID_SECTION_PACK:"S5",BID_SECTION_DESCR:"GPPE, #2 Packaging and WH",TOTAL:6,ITB_COMPLETE:6,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:6,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"Sinopec Fourth Construction Company"},
      {CONTENT_OF_BID_SECTION:"P3",STATUS:"COMPLETE",BID_SECTION_PACK:"S9",BID_SECTION_DESCR:"220KV Sub",TOTAL:1,ITB_COMPLETE:1,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:1,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"Hainan Electric"},
      {CONTENT_OF_BID_SECTION:"P3",STATUS:"COMPLETE",BID_SECTION_PACK:"S11",BID_SECTION_DESCR:"Chemical WH, Maint. Workshop",TOTAL:6,ITB_COMPLETE:0,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:6,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"Huizhou Dong Jiang"},
      {CONTENT_OF_BID_SECTION:"P3",STATUS:"COMPLETE",BID_SECTION_PACK:"S12",BID_SECTION_DESCR:"Cooling Water 1 and 2",TOTAL:4,ITB_COMPLETE:4,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:4,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"Sinopec Fifth Construction Company"},
      {CONTENT_OF_BID_SECTION:"P3",STATUS:"COMPLETE",BID_SECTION_PACK:"S16",BID_SECTION_DESCR:"Ground Flare, Elevated Flare",TOTAL:3,ITB_COMPLETE:3,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:3,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"Shandong ShengYue Petrochemical Engineering Construction Co. Ltd."},
      {CONTENT_OF_BID_SECTION:"P3",STATUS:"COMPLETE",BID_SECTION_PACK:"S17",BID_SECTION_DESCR:"WWT Pre-treatment",TOTAL:5,ITB_COMPLETE:5,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:5,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"China National Chemical Engineering Co. Ltd. 13th Company"},
      {CONTENT_OF_BID_SECTION:"P3",STATUS:"COMPLETE",BID_SECTION_PACK:"S18",BID_SECTION_DESCR:"Overall Interconn. A.G1",TOTAL:5,ITB_COMPLETE:5,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:5,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"Sinopec Fifth Construction Company"},
      {CONTENT_OF_BID_SECTION:"P3",STATUS:"COMPLETE",BID_SECTION_PACK:"S19",BID_SECTION_DESCR:"Overall Interconn. A.G2",TOTAL:2,ITB_COMPLETE:2,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:2,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"Shanxi Petroleum"},
      {CONTENT_OF_BID_SECTION:"P3",STATUS:"ONTRACK",BID_SECTION_PACK:"S8",BID_SECTION_DESCR:"Building Décoration",TOTAL:1,ITB_COMPLETE:0,ITB_ONTRACK:1,ITB_BEHINDPLAN:0,LOA_COMPLETE:0,LOA_ONTRACK:1,LOA_BEHINDPLAN:0,STATUS_COLOR:"#FFF176",AWARD_SUBCONTRACTOR:""},
      {CONTENT_OF_BID_SECTION:"P3",STATUS:"ONTRACK",BID_SECTION_PACK:"S10",BID_SECTION_DESCR:"Factory Greening",TOTAL:1,ITB_COMPLETE:0,ITB_ONTRACK:1,ITB_BEHINDPLAN:0,LOA_COMPLETE:0,LOA_ONTRACK:1,LOA_BEHINDPLAN:0,STATUS_COLOR:"#FFF176",AWARD_SUBCONTRACTOR:""},
      {CONTENT_OF_BID_SECTION:"P3",STATUS:"BEHINDPLAN",BID_SECTION_PACK:"S6",BID_SECTION_DESCR:"B+Area (Management Building, CCB and Lab)",TOTAL:7,ITB_COMPLETE:7,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:0,LOA_ONTRACK:0,LOA_BEHINDPLAN:7,STATUS_COLOR:"#EF9A9A",AWARD_SUBCONTRACTOR:""},
      {CONTENT_OF_BID_SECTION:"P3",STATUS:"BEHINDPLAN",BID_SECTION_PACK:"S7",BID_SECTION_DESCR:"Admin Building",TOTAL:2,ITB_COMPLETE:2,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:0,LOA_ONTRACK:0,LOA_BEHINDPLAN:2,STATUS_COLOR:"#EF9A9A",AWARD_SUBCONTRACTOR:""},
      {CONTENT_OF_BID_SECTION:"Other",STATUS:"COMPLETE",BID_SECTION_PACK:"S20",BID_SECTION_DESCR:"Heavy Lifting",TOTAL:1,ITB_COMPLETE:1,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:1,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"Sinopec Heavy Lifting"},
      {CONTENT_OF_BID_SECTION:"Other",STATUS:"BEHINDPLAN",BID_SECTION_PACK:"S21",BID_SECTION_DESCR:"TCF",TOTAL:1,ITB_COMPLETE:1,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:0,LOA_ONTRACK:0,LOA_BEHINDPLAN:1,STATUS_COLOR:"#EF9A9A",AWARD_SUBCONTRACTOR:""}
    ],
}