import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 1500,
    CanvasHeight: 1150,
    CanvasBorderWeight: 0.1,
    CanvasBorderColor: '#E0E0E0',
    CanvasFillColor: '#ffffff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1710,
    CanvasChartHeight: 1255,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  Note: {
    NoteDisplay: 'N',
    NoteX: 633,
    NoteY: 358,
    NoteAlign: 'start',
    NoteTitleText: 'Notes.',
    NoteTitleFont: 'roboto',
    NoteTitleStyle: 'regular',
    NoteTitleSize: 11,
    NoteTitleColor: '#757575',
    NoteContentsText: 'Note Contents Here!',
    NoteContentsFont: 'roboto',
    NoteContentsStyle: 'regular',
    NoteContentsSize: 10,
    NoteContentsColor: '#BDBCBC'
  },
  Text: {
    Text: [
    ],
    Filter: [
      
    ],
    Sql: [
      
    ]
  },
  Queries: {
    SQL1: [
      {CODE:"Previous_TEN Procurement_1",MONTH:"Previous",CATEGORY:"TEN Procurement",SEQ:1,ITEM:"Delivered Shanghai",ITEM_REV:"C",ITEM_DUE_DATE:"2021-09-15T00:00:00"},
      {CODE:"Previous_TEN Procurement_1",MONTH:"Previous",CATEGORY:"TEN Procurement",SEQ:1,ITEM:"Delivered Shanghai",ITEM_REV:"2",ITEM_DUE_DATE:"2021-07-14T00:00:00"},
      {CODE:"Previous_TEN Procurement_1",MONTH:"Previous",CATEGORY:"TEN Procurement",SEQ:1,ITEM:"Delivered Shanghai",ITEM_REV:"1",ITEM_DUE_DATE:"2021-05-01T00:00:00"},
      {CODE:"Previous_COSCO Procurement_2",MONTH:"Previous",CATEGORY:"COSCO Procurement",SEQ:2,ITEM:"Hull & LQ HVAC UCP manufacturing complete",ITEM_REV:"C",ITEM_DUE_DATE:"2021-06-30T00:00:00"},
      {CODE:"Previous_COSCO Procurement_2",MONTH:"Previous",CATEGORY:"COSCO Procurement",SEQ:2,ITEM:"Hull & LQ HVAC UCP manufacturing complete",ITEM_REV:"1",ITEM_DUE_DATE:"2021-06-24T00:00:00"},
      {CODE:"Previous_COSCO Procurement_3",MONTH:"Previous",CATEGORY:"COSCO Procurement",SEQ:3,ITEM:"Instrumentaed valves 65% delivered",ITEM_REV:"C",ITEM_DUE_DATE:"2021-07-31T00:00:00"},
      {CODE:"Previous_COSCO Procurement_3",MONTH:"Previous",CATEGORY:"COSCO Procurement",SEQ:3,ITEM:"Instrumentaed valves 65% delivered",ITEM_REV:"1",ITEM_DUE_DATE:"2021-05-31T00:00:00"},
      {CODE:"Previous_COSCO Procurement_4",MONTH:"Previous",CATEGORY:"COSCO Procurement",SEQ:4,ITEM:"Spool shop dwgs (17029)",ITEM_REV:"C",ITEM_DUE_DATE:"2021-05-28T00:00:00"},
      {CODE:"Previous_COSCO Procurement_4",MONTH:"Previous",CATEGORY:"COSCO Procurement",SEQ:4,ITEM:"Spool shop dwgs (17029)",ITEM_REV:"2",ITEM_DUE_DATE:"2021-05-24T00:00:00"},
      {CODE:"Previous_COSCO Procurement_4",MONTH:"Previous",CATEGORY:"COSCO Procurement",SEQ:4,ITEM:"Spool shop dwgs (17029)",ITEM_REV:"1",ITEM_DUE_DATE:"2021-04-24T00:00:00"},
      {CODE:"Previous_COSCO Procurement_5",MONTH:"Previous",CATEGORY:"COSCO Procurement",SEQ:5,ITEM:"Ex-works vertical pumps 2nd batch (ballast, service, scrubber)",ITEM_REV:"C",ITEM_DUE_DATE:"2021-06-15T00:00:00"},
      {CODE:"Previous_COSCO Procurement_5",MONTH:"Previous",CATEGORY:"COSCO Procurement",SEQ:5,ITEM:"Ex-works vertical pumps 2nd batch (ballast, service, scrubber)",ITEM_REV:"4",ITEM_DUE_DATE:"2021-05-31T00:00:00"},
      {CODE:"Previous_COSCO Procurement_5",MONTH:"Previous",CATEGORY:"COSCO Procurement",SEQ:5,ITEM:"Ex-works vertical pumps 2nd batch (ballast, service, scrubber)",ITEM_REV:"3",ITEM_DUE_DATE:"2021-05-25T00:00:00"},
      {CODE:"Previous_COSCO Procurement_5",MONTH:"Previous",CATEGORY:"COSCO Procurement",SEQ:5,ITEM:"Ex-works vertical pumps 2nd batch (ballast, service, scrubber)",ITEM_REV:"2",ITEM_DUE_DATE:"2021-05-19T00:00:00"},
      {CODE:"Previous_COSCO Procurement_5",MONTH:"Previous",CATEGORY:"COSCO Procurement",SEQ:5,ITEM:"Ex-works vertical pumps 2nd batch (ballast, service, scrubber)",ITEM_REV:"1",ITEM_DUE_DATE:"2021-04-30T00:00:00"},
      {CODE:"Previous_TEN Engineering_6",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:6,ITEM:"60% model punch close-out-10 points remain open",ITEM_REV:"C",ITEM_DUE_DATE:"2021-06-28T00:00:00"},
      {CODE:"Previous_TEN Engineering_6",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:6,ITEM:"60% model punch close-out-10 points remain open",ITEM_REV:"3",ITEM_DUE_DATE:"2021-05-20T00:00:00"},
      {CODE:"Previous_TEN Engineering_6",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:6,ITEM:"60% model punch close-out-10 points remain open",ITEM_REV:"2",ITEM_DUE_DATE:"2021-04-15T00:00:00"},
      {CODE:"Previous_TEN Engineering_6",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:6,ITEM:"60% model punch close-out-10 points remain open",ITEM_REV:"1",ITEM_DUE_DATE:"2021-10-30T00:00:00"},
      {CODE:"Previous_TEN Engineering_7",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:7,ITEM:"Engineering Completion",ITEM_REV:"C",ITEM_DUE_DATE:"2021-06-17T00:00:00"},
      {CODE:"Previous_TEN Engineering_8",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:8,ITEM:"Engineering Completion",ITEM_REV:"C",ITEM_DUE_DATE:"2021-07-01T00:00:00"},
      {CODE:"Previous_COSCO Enineering_9",MONTH:"Previous",CATEGORY:"COSCO Enineering",SEQ:9,ITEM:"MS Spool dwgs issued",ITEM_REV:"C",ITEM_DUE_DATE:"2021-06-14T00:00:00"},
      {CODE:"Previous_COSCO Enineering_9",MONTH:"Previous",CATEGORY:"COSCO Enineering",SEQ:9,ITEM:"MS Spool dwgs issued",ITEM_REV:"2",ITEM_DUE_DATE:"2021-05-14T00:00:00"},
      {CODE:"Previous_COSCO Enineering_9",MONTH:"Previous",CATEGORY:"COSCO Enineering",SEQ:9,ITEM:"MS Spool dwgs issued",ITEM_REV:"1",ITEM_DUE_DATE:"2021-10-15T00:00:00"},
      {CODE:"Previous_COSCO Enineering_10",MONTH:"Previous",CATEGORY:"COSCO Enineering",SEQ:10,ITEM:"Spool shop dwgs (17029)",ITEM_REV:"C",ITEM_DUE_DATE:"2021-07-30T00:00:00"},
      {CODE:"Previous_COSCO Enineering_11",MONTH:"Previous",CATEGORY:"COSCO Enineering",SEQ:11,ITEM:"MS: 90% Model review close-out",ITEM_REV:"C",ITEM_DUE_DATE:"2021-06-30T00:00:00"},
      {CODE:"Previous_Hull & LQ Construction_12",MONTH:"Previous",CATEGORY:"Hull & LQ Construction",SEQ:12,ITEM:"LQ: Start Lifting foundation support helideck",ITEM_REV:"C",ITEM_DUE_DATE:"2021-06-14T00:00:00"},
      {CODE:"Previous_Hull & LQ Construction_12",MONTH:"Previous",CATEGORY:"Hull & LQ Construction",SEQ:12,ITEM:"LQ: Start Lifting foundation support helideck",ITEM_REV:"3",ITEM_DUE_DATE:"2021-06-05T00:00:00"},
      {CODE:"Previous_Hull & LQ Construction_12",MONTH:"Previous",CATEGORY:"Hull & LQ Construction",SEQ:12,ITEM:"LQ: Start Lifting foundation support helideck",ITEM_REV:"2",ITEM_DUE_DATE:"2021-05-28T00:00:00"},
      {CODE:"Previous_Hull & LQ Construction_12",MONTH:"Previous",CATEGORY:"Hull & LQ Construction",SEQ:12,ITEM:"LQ: Start Lifting foundation support helideck",ITEM_REV:"1",ITEM_DUE_DATE:"2021-05-13T00:00:00"},
      {CODE:"Previous_Hull & LQ Construction_13",MONTH:"Previous",CATEGORY:"Hull & LQ Construction",SEQ:13,ITEM:"All MS distribution board in position(IRP panels)",ITEM_REV:"C",ITEM_DUE_DATE:"2021-06-15T00:00:00"},
      {CODE:"Previous_Hull & LQ Construction_13",MONTH:"Previous",CATEGORY:"Hull & LQ Construction",SEQ:13,ITEM:"All MS distribution board in position(IRP panels)",ITEM_REV:"1",ITEM_DUE_DATE:"2021-05-26T00:00:00"}
    ],
  SQL2: [
    {CODE:"Previous_TEN Procurement_1",MONTH:"Previous",CATEGORY:"TEN Procurement",SEQ:1,ITEM:"Delivered Shanghai",SUBITEM:"Vapor reco. Pck",SUBITEM_REV:"C",SUBITEM_DUE_DATE:"2021-09-15T00:00:00"},
    {CODE:"Previous_TEN Procurement_1",MONTH:"Previous",CATEGORY:"TEN Procurement",SEQ:1,ITEM:"Delivered Shanghai",SUBITEM:"Vapor reco. Pck",SUBITEM_REV:"1",SUBITEM_DUE_DATE:"2021-05-01T00:00:00"},
    {CODE:"Previous_TEN Procurement_1",MONTH:"Previous",CATEGORY:"TEN Procurement",SEQ:1,ITEM:"Delivered Shanghai",SUBITEM:"LLP Flash Gas Comp",SUBITEM_REV:"C",SUBITEM_DUE_DATE:"2021-07-09T00:00:00"},
    {CODE:"Previous_TEN Procurement_1",MONTH:"Previous",CATEGORY:"TEN Procurement",SEQ:1,ITEM:"Delivered Shanghai",SUBITEM:"LLP Flash Gas Comp",SUBITEM_REV:"2",SUBITEM_DUE_DATE:"2021-05-29T00:00:00"},
    {CODE:"Previous_TEN Procurement_1",MONTH:"Previous",CATEGORY:"TEN Procurement",SEQ:1,ITEM:"Delivered Shanghai",SUBITEM:"LLP Flash Gas Comp",SUBITEM_REV:"1",SUBITEM_DUE_DATE:"2021-05-03T00:00:00"},
    {CODE:"Previous_COSCO Procurement_2",MONTH:"Previous",CATEGORY:"COSCO Procurement",SEQ:2,ITEM:"Hull & LQ HVAC UCP manufacturing complete",SUBITEM:"Hull UCP",SUBITEM_REV:"C",SUBITEM_DUE_DATE:"2021-06-30T00:00:00"},
    {CODE:"Previous_COSCO Procurement_2",MONTH:"Previous",CATEGORY:"COSCO Procurement",SEQ:2,ITEM:"Hull & LQ HVAC UCP manufacturing complete",SUBITEM:"Hull UCP",SUBITEM_REV:"2",SUBITEM_DUE_DATE:"2021-05-28T00:00:00"},
    {CODE:"Previous_COSCO Procurement_2",MONTH:"Previous",CATEGORY:"COSCO Procurement",SEQ:2,ITEM:"Hull & LQ HVAC UCP manufacturing complete",SUBITEM:"Hull UCP",SUBITEM_REV:"1",SUBITEM_DUE_DATE:"2021-05-03T00:00:00"},
    {CODE:"Previous_COSCO Procurement_2",MONTH:"Previous",CATEGORY:"COSCO Procurement",SEQ:2,ITEM:"Hull & LQ HVAC UCP manufacturing complete",SUBITEM:"LQ UCP",SUBITEM_REV:"C",SUBITEM_DUE_DATE:"2021-06-24T00:00:00"},
    {CODE:"Previous_COSCO Procurement_4",MONTH:"Previous",CATEGORY:"COSCO Procurement",SEQ:4,ITEM:"Spool shop dwgs (17029)",SUBITEM:"HD Hydraulic(500)",SUBITEM_REV:"C",SUBITEM_DUE_DATE:"2021-05-28T00:00:00"},
    {CODE:"Previous_COSCO Procurement_4",MONTH:"Previous",CATEGORY:"COSCO Procurement",SEQ:4,ITEM:"Spool shop dwgs (17029)",SUBITEM:"HD Hydraulic(500)",SUBITEM_REV:"2",SUBITEM_DUE_DATE:"2021-05-24T00:00:00"},
    {CODE:"Previous_COSCO Procurement_4",MONTH:"Previous",CATEGORY:"COSCO Procurement",SEQ:4,ITEM:"Spool shop dwgs (17029)",SUBITEM:"HD Hydraulic(500)",SUBITEM_REV:"1",SUBITEM_DUE_DATE:"2021-04-29T00:00:00"},
    {CODE:"Previous_TEN Engineering_7",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:7,ITEM:"Engineering Completion",SUBITEM:"ESPS report approved",SUBITEM_REV:"C",SUBITEM_DUE_DATE:"2021-06-17T00:00:00"},
    {CODE:"Previous_TEN Engineering_7",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:7,ITEM:"Engineering Completion",SUBITEM:"ESPS report approved",SUBITEM_REV:"8",SUBITEM_DUE_DATE:"2021-05-21T00:00:00"},
    {CODE:"Previous_TEN Engineering_7",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:7,ITEM:"Engineering Completion",SUBITEM:"ESPS report approved",SUBITEM_REV:"7",SUBITEM_DUE_DATE:"2021-04-29T00:00:00"},
    {CODE:"Previous_TEN Engineering_7",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:7,ITEM:"Engineering Completion",SUBITEM:"ESPS report approved",SUBITEM_REV:"6",SUBITEM_DUE_DATE:"2021-04-15T00:00:00"},
    {CODE:"Previous_TEN Engineering_7",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:7,ITEM:"Engineering Completion",SUBITEM:"ESPS report approved",SUBITEM_REV:"5",SUBITEM_DUE_DATE:"2021-04-08T00:00:00"},
    {CODE:"Previous_TEN Engineering_7",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:7,ITEM:"Engineering Completion",SUBITEM:"ESPS report approved",SUBITEM_REV:"4",SUBITEM_DUE_DATE:"2021-04-02T00:00:00"},
    {CODE:"Previous_TEN Engineering_7",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:7,ITEM:"Engineering Completion",SUBITEM:"ESPS report approved",SUBITEM_REV:"3",SUBITEM_DUE_DATE:"2021-03-25T00:00:00"},
    {CODE:"Previous_TEN Engineering_7",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:7,ITEM:"Engineering Completion",SUBITEM:"ESPS report approved",SUBITEM_REV:"2",SUBITEM_DUE_DATE:"2021-03-18T00:00:00"},
    {CODE:"Previous_TEN Engineering_7",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:7,ITEM:"Engineering Completion",SUBITEM:"ESPS report approved",SUBITEM_REV:"1",SUBITEM_DUE_DATE:"2021-02-12T00:00:00"},
    {CODE:"Previous_TEN Engineering_7",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:7,ITEM:"Engineering Completion",SUBITEM:"eHAZOP actions closed",SUBITEM_REV:"C",SUBITEM_DUE_DATE:"2021-06-17T00:00:00"},
    {CODE:"Previous_TEN Engineering_7",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:7,ITEM:"Engineering Completion",SUBITEM:"eHAZOP actions closed",SUBITEM_REV:"7",SUBITEM_DUE_DATE:"2021-05-28T00:00:00"},
    {CODE:"Previous_TEN Engineering_7",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:7,ITEM:"Engineering Completion",SUBITEM:"eHAZOP actions closed",SUBITEM_REV:"6",SUBITEM_DUE_DATE:"2021-05-20T00:00:00"},
    {CODE:"Previous_TEN Engineering_7",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:7,ITEM:"Engineering Completion",SUBITEM:"eHAZOP actions closed",SUBITEM_REV:"5",SUBITEM_DUE_DATE:"2021-05-11T00:00:00"},
    {CODE:"Previous_TEN Engineering_7",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:7,ITEM:"Engineering Completion",SUBITEM:"eHAZOP actions closed",SUBITEM_REV:"4",SUBITEM_DUE_DATE:"2021-04-29T00:00:00"},
    {CODE:"Previous_TEN Engineering_7",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:7,ITEM:"Engineering Completion",SUBITEM:"eHAZOP actions closed",SUBITEM_REV:"3",SUBITEM_DUE_DATE:"2021-04-08T00:00:00"},
    {CODE:"Previous_TEN Engineering_7",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:7,ITEM:"Engineering Completion",SUBITEM:"eHAZOP actions closed",SUBITEM_REV:"2",SUBITEM_DUE_DATE:"2021-03-31T00:00:00"},
    {CODE:"Previous_TEN Engineering_7",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:7,ITEM:"Engineering Completion",SUBITEM:"eHAZOP actions closed",SUBITEM_REV:"1",SUBITEM_DUE_DATE:"2021-02-26T00:00:00"},
    {CODE:"Previous_TEN Engineering_8",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:8,ITEM:"Engineering Completion",SUBITEM:"Alarm Resp. Man. Approved",SUBITEM_REV:"C",SUBITEM_DUE_DATE:"2021-07-01T00:00:00"},
    {CODE:"Previous_TEN Engineering_8",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:8,ITEM:"Engineering Completion",SUBITEM:"Alarm Resp. Man. Approved",SUBITEM_REV:"6",SUBITEM_DUE_DATE:"2021-06-14T00:00:00"},
    {CODE:"Previous_TEN Engineering_8",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:8,ITEM:"Engineering Completion",SUBITEM:"Alarm Resp. Man. Approved",SUBITEM_REV:"5",SUBITEM_DUE_DATE:"2021-05-31T00:00:00"},
    {CODE:"Previous_TEN Engineering_8",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:8,ITEM:"Engineering Completion",SUBITEM:"Alarm Resp. Man. Approved",SUBITEM_REV:"4",SUBITEM_DUE_DATE:"2021-04-30T00:00:00"},
    {CODE:"Previous_TEN Engineering_8",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:8,ITEM:"Engineering Completion",SUBITEM:"Alarm Resp. Man. Approved",SUBITEM_REV:"3",SUBITEM_DUE_DATE:"2021-04-08T00:00:00"},
    {CODE:"Previous_TEN Engineering_8",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:8,ITEM:"Engineering Completion",SUBITEM:"Alarm Resp. Man. Approved",SUBITEM_REV:"2",SUBITEM_DUE_DATE:"2021-03-25T00:00:00"},
    {CODE:"Previous_TEN Engineering_8",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:8,ITEM:"Engineering Completion",SUBITEM:"Alarm Resp. Man. Approved",SUBITEM_REV:"1",SUBITEM_DUE_DATE:"2021-03-22T00:00:00"},
    {CODE:"Previous_TEN Engineering_8",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:8,ITEM:"Engineering Completion",SUBITEM:"90% model tag items closed",SUBITEM_REV:"C",SUBITEM_DUE_DATE:"2021-06-28T00:00:00"},
    {CODE:"Previous_TEN Engineering_8",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:8,ITEM:"Engineering Completion",SUBITEM:"90% model tag items closed",SUBITEM_REV:"2",SUBITEM_DUE_DATE:"2021-05-31T00:00:00"},
    {CODE:"Previous_TEN Engineering_8",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:8,ITEM:"Engineering Completion",SUBITEM:"90% model tag items closed",SUBITEM_REV:"1",SUBITEM_DUE_DATE:"2021-03-26T00:00:00"},
    {CODE:"Previous_TEN Engineering_8",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:8,ITEM:"Engineering Completion",SUBITEM:"All T/S HAZOP actions closed",SUBITEM_REV:"C",SUBITEM_DUE_DATE:"2021-06-28T00:00:00"},
    {CODE:"Previous_TEN Engineering_8",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:8,ITEM:"Engineering Completion",SUBITEM:"All T/S HAZOP actions closed",SUBITEM_REV:"2",SUBITEM_DUE_DATE:"2021-05-30T00:00:00"},
    {CODE:"Previous_TEN Engineering_8",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:8,ITEM:"Engineering Completion",SUBITEM:"All T/S HAZOP actions closed",SUBITEM_REV:"1",SUBITEM_DUE_DATE:"2021-03-26T00:00:00"},
    {CODE:"Previous_TEN Engineering_8",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:8,ITEM:"Engineering Completion",SUBITEM:"Final LOPA report issued",SUBITEM_REV:"C",SUBITEM_DUE_DATE:"2021-05-15T00:00:00"},
    {CODE:"Previous_TEN Engineering_8",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:8,ITEM:"Engineering Completion",SUBITEM:"Final LOPA report issued",SUBITEM_STATUS:"Completed",SUBITEM_REV:"1",SUBITEM_DUE_DATE:"2021-03-26T00:00:00"},
    {CODE:"Previous_COSCO Enineering_9",MONTH:"Previous",CATEGORY:"COSCO Enineering",SEQ:9,ITEM:"MS Spool dwgs issued",SUBITEM:"50 (fire damper, removal spools)",SUBITEM_STATUS:"Completed",SUBITEM_REV:"C",SUBITEM_DUE_DATE:"2021-03-29T00:00:00"},
    {CODE:"Previous_COSCO Enineering_9",MONTH:"Previous",CATEGORY:"COSCO Enineering",SEQ:9,ITEM:"MS Spool dwgs issued",SUBITEM:"Ballast syst. (line modified)",SUBITEM_REV:"C",SUBITEM_DUE_DATE:"2021-06-14T00:00:00"},
    {CODE:"Previous_COSCO Enineering_9",MONTH:"Previous",CATEGORY:"COSCO Enineering",SEQ:9,ITEM:"MS Spool dwgs issued",SUBITEM:"Ballast syst. (line modified)",SUBITEM_REV:"1",SUBITEM_DUE_DATE:"2021-05-14T00:00:00"},
    {CODE:"Previous_COSCO Enineering_10",MONTH:"Previous",CATEGORY:"COSCO Enineering",SEQ:10,ITEM:"Spool shop dwgs (17029)",SUBITEM:"Issued : 11863"},
    {CODE:"Previous_COSCO Enineering_10",MONTH:"Previous",CATEGORY:"COSCO Enineering",SEQ:10,ITEM:"Spool shop dwgs (17029)",SUBITEM:"Hull Deck (2700)",SUBITEM_REV:"C",SUBITEM_DUE_DATE:"2021-06-29T00:00:00"},
    {CODE:"Previous_COSCO Enineering_10",MONTH:"Previous",CATEGORY:"COSCO Enineering",SEQ:10,ITEM:"Spool shop dwgs (17029)",SUBITEM:"Hull Deck (2700)",SUBITEM_REV:"3",SUBITEM_DUE_DATE:"2021-04-24T00:00:00"},
    {CODE:"Previous_COSCO Enineering_10",MONTH:"Previous",CATEGORY:"COSCO Enineering",SEQ:10,ITEM:"Spool shop dwgs (17029)",SUBITEM:"Hull Deck (2700)",SUBITEM_REV:"2",SUBITEM_DUE_DATE:"2021-04-01T00:00:00"},
    {CODE:"Previous_COSCO Enineering_10",MONTH:"Previous",CATEGORY:"COSCO Enineering",SEQ:10,ITEM:"Spool shop dwgs (17029)",SUBITEM:"Hull Deck (2700)",SUBITEM_REV:"1",SUBITEM_DUE_DATE:"2021-03-30T00:00:00"},
    {CODE:"Previous_COSCO Enineering_10",MONTH:"Previous",CATEGORY:"COSCO Enineering",SEQ:10,ITEM:"Spool shop dwgs (17029)",SUBITEM:"HD Deluge (2300)",SUBITEM_STATUS:"Qidong-Due Date",SUBITEM_REV:"C",SUBITEM_DUE_DATE:"2021-04-25T00:00:00"},
    {CODE:"Previous_COSCO Enineering_10",MONTH:"Previous",CATEGORY:"COSCO Enineering",SEQ:10,ITEM:"Spool shop dwgs (17029)",SUBITEM:"A Deck Deluge FWD part",SUBITEM_REV:"C",SUBITEM_DUE_DATE:"2021-06-30T00:00:00"},
    {CODE:"Previous_COSCO Enineering_10",MONTH:"Previous",CATEGORY:"COSCO Enineering",SEQ:10,ITEM:"Spool shop dwgs (17029)",SUBITEM:"A Deck Deluge FWD part",SUBITEM_REV:"1",SUBITEM_DUE_DATE:"2021-06-15T00:00:00"},
    {CODE:"Previous_COSCO Enineering_10",MONTH:"Previous",CATEGORY:"COSCO Enineering",SEQ:10,ITEM:"Spool shop dwgs (17029)",SUBITEM:"A Deck Deluge AFT part",SUBITEM_REV:"C",SUBITEM_DUE_DATE:"2021-07-30T00:00:00"},
    {CODE:"Previous_COSCO Enineering_10",MONTH:"Previous",CATEGORY:"COSCO Enineering",SEQ:10,ITEM:"Spool shop dwgs (17029)",SUBITEM:"A Deck Deluge AFT part",SUBITEM_REV:"1",SUBITEM_DUE_DATE:"2021-07-15T00:00:00"},
    {CODE:"Previous_COSCO Enineering_10",MONTH:"Previous",CATEGORY:"COSCO Enineering",SEQ:10,ITEM:"Spool shop dwgs (17029)",SUBITEM:"MS & LQ",SUBITEM_REV:"C",SUBITEM_DUE_DATE:"2021-06-14T00:00:00"},
    {CODE:"Previous_COSCO Enineering_10",MONTH:"Previous",CATEGORY:"COSCO Enineering",SEQ:10,ITEM:"Spool shop dwgs (17029)",SUBITEM:"MS & LQ",SUBITEM_REV:"1",SUBITEM_DUE_DATE:"2021-05-14T00:00:00"},
    {CODE:"Previous_COSCO Enineering_11",MONTH:"Previous",CATEGORY:"COSCO Enineering",SEQ:11,ITEM:"MS: 90% Model review close-out",SUBITEM:"11 2 items",SUBITEM_REV:"C",SUBITEM_DUE_DATE:"2021-06-30T00:00:00"},
    {CODE:"Previous_COSCO Enineering_11",MONTH:"Previous",CATEGORY:"COSCO Enineering",SEQ:11,ITEM:"MS: 90% Model review close-out",SUBITEM:"11 2 items",SUBITEM_REV:"4",SUBITEM_DUE_DATE:"2021-05-26T00:00:00"},
    {CODE:"Previous_COSCO Enineering_11",MONTH:"Previous",CATEGORY:"COSCO Enineering",SEQ:11,ITEM:"MS: 90% Model review close-out",SUBITEM:"11 2 items",SUBITEM_REV:"3",SUBITEM_DUE_DATE:"2021-04-30T00:00:00"},
    {CODE:"Previous_COSCO Enineering_11",MONTH:"Previous",CATEGORY:"COSCO Enineering",SEQ:11,ITEM:"MS: 90% Model review close-out",SUBITEM:"11 2 items",SUBITEM_REV:"2",SUBITEM_DUE_DATE:"2021-04-15T00:00:00"},
    {CODE:"Previous_COSCO Enineering_11",MONTH:"Previous",CATEGORY:"COSCO Enineering",SEQ:11,ITEM:"MS: 90% Model review close-out",SUBITEM:"11 2 items",SUBITEM_REV:"1",SUBITEM_DUE_DATE:"2021-03-31T00:00:00"},
    {CODE:"Previous_COSCO Enineering_11",MONTH:"Previous",CATEGORY:"COSCO Enineering",SEQ:11,ITEM:"MS: 90% Model review close-out",SUBITEM:"1 item (water mist pckg handling procedure)",SUBITEM_REV:"C",SUBITEM_DUE_DATE:"2021-06-30T00:00:00"}
    ],
  SQL3:[
    // {MONTH:"0.Previous"},
    // {MONTH:"1.October"},
    // {MONTH:"2.November"},
    {MONTH:"3.December"}
  ],
  },
  DataItems: [
    {CODE:"Previous_TEN Procurement_1",MONTH:"Previous",CATEGORY:"TEN Procurement",SEQ:1,ITEM:"Delivered Shanghai"},
    {CODE:"Previous_COSCO Procurement_2",MONTH:"Previous",CATEGORY:"COSCO Procurement",SEQ:2,ITEM:"Hull & LQ HVAC UCP manufacturing complete"},
    {CODE:"Previous_COSCO Procurement_3",MONTH:"Previous",CATEGORY:"COSCO Procurement",SEQ:3,ITEM:"Instrumentaed valves 65% delivered"},
    {CODE:"Previous_COSCO Procurement_4",MONTH:"Previous",CATEGORY:"COSCO Procurement",SEQ:4,ITEM:"Spool shop dwgs (17029)",ITEM_STATUS:"Completed"},
    {CODE:"Previous_COSCO Procurement_5",MONTH:"Previous",CATEGORY:"COSCO Procurement",SEQ:5,ITEM:"Ex-works vertical pumps 2nd batch (ballast, service, scrubber)",ITEM_STATUS:"Qidong-Due Date"},
    {CODE:"Previous_TEN Engineering_6",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:6,ITEM:"60% model punch close-out-10 points remain open"},
    {CODE:"Previous_TEN Engineering_7",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:7,ITEM:"Engineering Completion"},
    {CODE:"Previous_TEN Engineering_8",MONTH:"Previous",CATEGORY:"TEN Engineering",SEQ:8,ITEM:"Engineering Completion"},
    {CODE:"Previous_COSCO Enineering_9",MONTH:"Previous",CATEGORY:"COSCO Enineering",SEQ:9,ITEM:"MS Spool dwgs issued"},
    {CODE:"Previous_COSCO Enineering_10",MONTH:"Previous",CATEGORY:"COSCO Enineering",SEQ:10,ITEM:"Spool shop dwgs (17029)"},
    {CODE:"Previous_COSCO Enineering_11",MONTH:"Previous",CATEGORY:"COSCO Enineering",SEQ:11,ITEM:"MS: 90% Model review close-out"},
    {CODE:"Previous_Hull & LQ Construction_12",MONTH:"Previous",CATEGORY:"Hull & LQ Construction",SEQ:12,ITEM:"LQ: Start Lifting foundation support helideck",ITEM_STATUS:"Completed"},
    {CODE:"Previous_Hull & LQ Construction_13",MONTH:"Previous",CATEGORY:"Hull & LQ Construction",SEQ:13,ITEM:"All MS distribution board in position(IRP panels)",ITEM_STATUS:"Qidong-Due Date"}
    ],
}