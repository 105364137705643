import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1600,
      CanvasHeight: 1600,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1787,
      CanvasChartHeight: 1050,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Note', x: 1350, y: -20, font: 'roboto', class: 'regular', size: 11, color: '#44A9DF', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: 1350, y: -30, font: 'roboto', class: 'regular', size: 11, color: '#757575', align: 'start' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: 1350,
          y: -50,
          font: 'roboto',
          style: 'regular',
          size: 10,
          color: '#757575',
          align: 'start'
        },
      ]
    },
    Queries: {
      SQL1:[
        {TOTAL:21,COMPLETE:4,ONTRACK:17,BEHINDPLAN:0}
      ],
      SQL2:[
        {BID_SECTION_PACK:"S1",QUALITY_STATION_AREA:"Area1",JIANLI_SUPERVISION_WORK_PACKAGE:"WP1",AWARD_SUBCONTRACTOR_CODE:"STCC"},
        {BID_SECTION_PACK:"S10",QUALITY_STATION_AREA:"Area4",JIANLI_SUPERVISION_WORK_PACKAGE:"WP6",AWARD_SUBCONTRACTOR_CODE:""},
        {BID_SECTION_PACK:"S11",QUALITY_STATION_AREA:"Area4",JIANLI_SUPERVISION_WORK_PACKAGE:"WP6",AWARD_SUBCONTRACTOR_CODE:"HZDJ"},
        {BID_SECTION_PACK:"S12",QUALITY_STATION_AREA:"Area1",JIANLI_SUPERVISION_WORK_PACKAGE:"WP5",AWARD_SUBCONTRACTOR_CODE:"SFCC"},
        {BID_SECTION_PACK:"S13",QUALITY_STATION_AREA:"Area1",JIANLI_SUPERVISION_WORK_PACKAGE:"WP8",AWARD_SUBCONTRACTOR_CODE:"SFCC"},
        {BID_SECTION_PACK:"S14",QUALITY_STATION_AREA:"Area1",JIANLI_SUPERVISION_WORK_PACKAGE:"WP8",AWARD_SUBCONTRACTOR_CODE:"CC7"},
        {BID_SECTION_PACK:"S15",QUALITY_STATION_AREA:"Area1",JIANLI_SUPERVISION_WORK_PACKAGE:"WP8",AWARD_SUBCONTRACTOR_CODE:"WD"},
        {BID_SECTION_PACK:"S16",QUALITY_STATION_AREA:"Area1",JIANLI_SUPERVISION_WORK_PACKAGE:"WP8",AWARD_SUBCONTRACTOR_CODE:"SYPEC"},
        {BID_SECTION_PACK:"S17",QUALITY_STATION_AREA:"Area1",JIANLI_SUPERVISION_WORK_PACKAGE:"WP8",AWARD_SUBCONTRACTOR_CODE:"CNCEC"},
        {BID_SECTION_PACK:"S18",QUALITY_STATION_AREA:"Area4",JIANLI_SUPERVISION_WORK_PACKAGE:"WP7",AWARD_SUBCONTRACTOR_CODE:"SFCC"},
        {BID_SECTION_PACK:"S19",QUALITY_STATION_AREA:"Area4",JIANLI_SUPERVISION_WORK_PACKAGE:"WP4",AWARD_SUBCONTRACTOR_CODE:"SPC"},
        {BID_SECTION_PACK:"S19",QUALITY_STATION_AREA:"Area4",JIANLI_SUPERVISION_WORK_PACKAGE:"WP6",AWARD_SUBCONTRACTOR_CODE:"SPC"},
        {BID_SECTION_PACK:"S2",QUALITY_STATION_AREA:"Area1",JIANLI_SUPERVISION_WORK_PACKAGE:"WP2",AWARD_SUBCONTRACTOR_CODE:"SNEI"},
        {BID_SECTION_PACK:"S20",QUALITY_STATION_AREA:"Area4",JIANLI_SUPERVISION_WORK_PACKAGE:"WP6",AWARD_SUBCONTRACTOR_CODE:"SHL"},
        {BID_SECTION_PACK:"S21",QUALITY_STATION_AREA:"Area4",JIANLI_SUPERVISION_WORK_PACKAGE:"WP6",AWARD_SUBCONTRACTOR_CODE:"GDMY"},
        {BID_SECTION_PACK:"S3",QUALITY_STATION_AREA:"Area1",JIANLI_SUPERVISION_WORK_PACKAGE:"WP3",AWARD_SUBCONTRACTOR_CODE:"SNEI"},
        {BID_SECTION_PACK:"S4",QUALITY_STATION_AREA:"Area1",JIANLI_SUPERVISION_WORK_PACKAGE:"WP4",AWARD_SUBCONTRACTOR_CODE:"SFCC"},
        {BID_SECTION_PACK:"S5",QUALITY_STATION_AREA:"Area1",JIANLI_SUPERVISION_WORK_PACKAGE:"WP4",AWARD_SUBCONTRACTOR_CODE:"FCC"},
        {BID_SECTION_PACK:"S6",QUALITY_STATION_AREA:"Area2",JIANLI_SUPERVISION_WORK_PACKAGE:"WP6",AWARD_SUBCONTRACTOR_CODE:"GDHJ"},
        {BID_SECTION_PACK:"S7",QUALITY_STATION_AREA:"Area2",JIANLI_SUPERVISION_WORK_PACKAGE:"WP6",AWARD_SUBCONTRACTOR_CODE:"ZGLY"},
        {BID_SECTION_PACK:"S8",QUALITY_STATION_AREA:"Area4",JIANLI_SUPERVISION_WORK_PACKAGE:"WP6",AWARD_SUBCONTRACTOR_CODE:""},
        {BID_SECTION_PACK:"S9",QUALITY_STATION_AREA:"Area3",JIANLI_SUPERVISION_WORK_PACKAGE:"WP7",AWARD_SUBCONTRACTOR_CODE:"HNEDI"}
      ],
    },
    DataItems: [
      {BID_SECTION_PACK:"S1",CONTENT_OF_BID_SECTION:"P1",BID_SECTION_DESCR:"OR",TOTAL:1,ITB_COMPLETE:1,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:1,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS:"COMPLETE",STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"10th Company (STCC)",AWARD_SUBCONTRACTOR_CODE:"10TH_SNEI",ISSUE_ITB_PLAN:"ITB Complete",LOA_PLAN:"LOA Complete",TYPE:"UNIT"},
      {BID_SECTION_PACK:"S2",CONTENT_OF_BID_SECTION:"P1",BID_SECTION_DESCR:"Co-products and Boiler",TOTAL:6,ITB_COMPLETE:6,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:6,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS:"COMPLETE",STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"SNEI",AWARD_SUBCONTRACTOR_CODE:"SNEI",ISSUE_ITB_PLAN:"ITB Complete",LOA_PLAN:"LOA Complete",TYPE:"UNIT"},
      {BID_SECTION_PACK:"S3",CONTENT_OF_BID_SECTION:"P2",BID_SECTION_DESCR:"LDPE",TOTAL:1,ITB_COMPLETE:1,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:1,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS:"COMPLETE",STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"SNEI",AWARD_SUBCONTRACTOR_CODE:"SNEI",ISSUE_ITB_PLAN:"ITB Complete",LOA_PLAN:"LOA Complete",TYPE:"UNIT"},
      {BID_SECTION_PACK:"S4",CONTENT_OF_BID_SECTION:"P2",BID_SECTION_DESCR:"PP, #1 Packaging and WH",TOTAL:5,ITB_COMPLETE:5,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:5,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS:"COMPLETE",STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"5th Company",AWARD_SUBCONTRACTOR_CODE:"5TH",ISSUE_ITB_PLAN:"ITB Complete",LOA_PLAN:"LOA Complete",TYPE:"UNIT"},
      {BID_SECTION_PACK:"S5",CONTENT_OF_BID_SECTION:"P2",BID_SECTION_DESCR:"GPPE, #2 Packaging and WH",TOTAL:6,ITB_COMPLETE:6,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:6,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS:"COMPLETE",STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"4th Company",AWARD_SUBCONTRACTOR_CODE:"4TH",ISSUE_ITB_PLAN:"ITB Complete",LOA_PLAN:"LOA Complete",TYPE:"UNIT"},
      {BID_SECTION_PACK:"S6",CONTENT_OF_BID_SECTION:"P3",BID_SECTION_DESCR:"B+Area (Management Building, CCB and Lab)",TOTAL:7,ITB_COMPLETE:7,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:0,LOA_ONTRACK:0,LOA_BEHINDPLAN:7,STATUS:"BEHINDPLAN",STATUS_COLOR:"#EF9A9A",ISSUE_ITB_PLAN:"ITB Complete",LOA_PLAN:"LOA FC : 2022-06-16",TYPE:"UNIT"},
      {BID_SECTION_PACK:"S7",CONTENT_OF_BID_SECTION:"P3",BID_SECTION_DESCR:"Admin Building",TOTAL:2,ITB_COMPLETE:2,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:0,LOA_ONTRACK:0,LOA_BEHINDPLAN:2,STATUS:"BEHINDPLAN",STATUS_COLOR:"#EF9A9A",ISSUE_ITB_PLAN:"ITB Complete",LOA_PLAN:"LOA FC : 2022-06-16",TYPE:"UNIT"},
      {BID_SECTION_PACK:"S8",CONTENT_OF_BID_SECTION:"P3",BID_SECTION_DESCR:"Building Décoration",TOTAL:1,ITB_COMPLETE:0,ITB_ONTRACK:1,ITB_BEHINDPLAN:0,LOA_COMPLETE:0,LOA_ONTRACK:1,LOA_BEHINDPLAN:0,STATUS:"ONTRACK",STATUS_COLOR:"#FFF176",ISSUE_ITB_PLAN:"ITB FC : 2022-07-10",LOA_PLAN:"LOA FC : 2022-08-10",TYPE:"UNIT"},
      {BID_SECTION_PACK:"S9",CONTENT_OF_BID_SECTION:"P3",BID_SECTION_DESCR:"220KV Sub",TOTAL:1,ITB_COMPLETE:1,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:1,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS:"COMPLETE",STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"Hainan Electric",AWARD_SUBCONTRACTOR_CODE:"HE",ISSUE_ITB_PLAN:"ITB Complete",LOA_PLAN:"LOA Complete",TYPE:"UNIT"},
      {BID_SECTION_PACK:"S10",CONTENT_OF_BID_SECTION:"P3",BID_SECTION_DESCR:"Factory Greening",TOTAL:1,ITB_COMPLETE:0,ITB_ONTRACK:1,ITB_BEHINDPLAN:0,LOA_COMPLETE:0,LOA_ONTRACK:1,LOA_BEHINDPLAN:0,STATUS:"ONTRACK",STATUS_COLOR:"#FFF176",ISSUE_ITB_PLAN:"ITB FC : 2023-03-30",LOA_PLAN:"LOA FC : 2023-04-30",TYPE:"UNIT"},
      {BID_SECTION_PACK:"S11",CONTENT_OF_BID_SECTION:"P3",BID_SECTION_DESCR:"Chemical WH, Maint. Workshop",TOTAL:6,ITB_COMPLETE:0,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:0,LOA_ONTRACK:6,LOA_BEHINDPLAN:0,STATUS:"ONTRACK",STATUS_COLOR:"#FFF176",ISSUE_ITB_PLAN:"ITB FC : 2022-05-25",LOA_PLAN:"LOA FC : 2022-06-20",TYPE:"UNIT"},
      {BID_SECTION_PACK:"S12",CONTENT_OF_BID_SECTION:"P3",BID_SECTION_DESCR:"Cooling Water 1 and 2",TOTAL:4,ITB_COMPLETE:4,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:4,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS:"COMPLETE",STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"5th Company",AWARD_SUBCONTRACTOR_CODE:"5TH",ISSUE_ITB_PLAN:"ITB Complete",LOA_PLAN:"LOA Complete",TYPE:"UNIT"},
      {BID_SECTION_PACK:"S13",CONTENT_OF_BID_SECTION:"P3",BID_SECTION_DESCR:"Ethylene, Pressurized Tank Farm",TOTAL:9,ITB_COMPLETE:9,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:9,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS:"COMPLETE",STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"5th Company",AWARD_SUBCONTRACTOR_CODE:"5TH",ISSUE_ITB_PLAN:"ITB Complete",LOA_PLAN:"LOA Complete",TYPE:"UNIT"},
      {BID_SECTION_PACK:"S14",CONTENT_OF_BID_SECTION:"P3",BID_SECTION_DESCR:"Atmospheric Tank Farm",TOTAL:8,ITB_COMPLETE:8,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:0,LOA_ONTRACK:0,LOA_BEHINDPLAN:8,STATUS:"BEHINDPLAN",STATUS_COLOR:"#EF9A9A",ISSUE_ITB_PLAN:"ITB Complete",LOA_PLAN:"LOA FC : 2022-06-27",TYPE:"UNIT"},
      {BID_SECTION_PACK:"S15",CONTENT_OF_BID_SECTION:"P3",BID_SECTION_DESCR:"Intermediate Tank Farm 1, 2",TOTAL:3,ITB_COMPLETE:3,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:0,LOA_ONTRACK:0,LOA_BEHINDPLAN:3,STATUS:"BEHINDPLAN",STATUS_COLOR:"#EF9A9A",ISSUE_ITB_PLAN:"ITB Complete",LOA_PLAN:"LOA FC : 2022-06-27",TYPE:"UNIT"},
      {BID_SECTION_PACK:"S16",CONTENT_OF_BID_SECTION:"P3",BID_SECTION_DESCR:"Ground Flare, Elevated Flare",TOTAL:3,ITB_COMPLETE:3,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:3,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS:"COMPLETE",STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"SYPEC",AWARD_SUBCONTRACTOR_CODE:"SYPEC",ISSUE_ITB_PLAN:"ITB Complete",LOA_PLAN:"LOA Complete",TYPE:"UNIT"},
      {BID_SECTION_PACK:"S17",CONTENT_OF_BID_SECTION:"P3",BID_SECTION_DESCR:"WWT Pre-treatment",TOTAL:5,ITB_COMPLETE:5,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:0,LOA_ONTRACK:0,LOA_BEHINDPLAN:5,STATUS:"BEHINDPLAN",STATUS_COLOR:"#EF9A9A",AWARD_SUBCONTRACTOR:"13TH_CNCEC",AWARD_SUBCONTRACTOR_CODE:"13TH_CNCEC",ISSUE_ITB_PLAN:"ITB Complete",LOA_PLAN:"LOA FC : 2022-07-04",TYPE:"UNIT"},
      {BID_SECTION_PACK:"S18",CONTENT_OF_BID_SECTION:"P3",BID_SECTION_DESCR:"Overall Interconn. A.G1",TOTAL:5,ITB_COMPLETE:5,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:5,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS:"COMPLETE",STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"5th Company",AWARD_SUBCONTRACTOR_CODE:"5TH",ISSUE_ITB_PLAN:"ITB Complete",LOA_PLAN:"LOA Complete",TYPE:"UNIT"},
      {BID_SECTION_PACK:"S19",CONTENT_OF_BID_SECTION:"P3",BID_SECTION_DESCR:"Overall Interconn. A.G2",TOTAL:2,ITB_COMPLETE:2,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:2,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS:"COMPLETE",STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"Shanxi Petroleum",AWARD_SUBCONTRACTOR_CODE:"SP",ISSUE_ITB_PLAN:"ITB Complete",LOA_PLAN:"LOA Complete",TYPE:"UNIT"},
      {BID_SECTION_PACK:"S20",CONTENT_OF_BID_SECTION:"Overall",BID_SECTION_DESCR:"Heavy Lifting",TOTAL:1,ITB_COMPLETE:1,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:1,LOA_ONTRACK:0,LOA_BEHINDPLAN:0,STATUS:"COMPLETE",STATUS_COLOR:"#AED581",AWARD_SUBCONTRACTOR:"Sinopec Heavy Lifting ",AWARD_SUBCONTRACTOR_CODE:"HL",ISSUE_ITB_PLAN:"ITB Complete",LOA_PLAN:"LOA Complete",TYPE:"UNIT"},
      {BID_SECTION_PACK:"S21",CONTENT_OF_BID_SECTION:"CO-TCF",BID_SECTION_DESCR:"TCF",TOTAL:1,ITB_COMPLETE:1,ITB_ONTRACK:0,ITB_BEHINDPLAN:0,LOA_COMPLETE:0,LOA_ONTRACK:0,LOA_BEHINDPLAN:1,STATUS:"BEHINDPLAN",STATUS_COLOR:"#EF9A9A",ISSUE_ITB_PLAN:"ITB Complete",LOA_PLAN:"LOA FC : 2022-06-13",TYPE:"UNIT"}]
}