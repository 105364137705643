import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 350,
    CanvasHeight: 150,
    CanvasBorderWeight: 0.3,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.2,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 310,
    CanvasChartHeight: 270,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: 'Transparent',
    CanvasChartFillColor: 'Transparent',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },

  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  Queries: {
    SQL1:  [
      {
        FILTERSTRING: "OFF",
        WIDTH:100,
        GAB:30,
        TITLE: "A Check Sheets",
        TITLE1: "Total",
        TITLE2: "Open",
        TITLE3: "Closed",
        COL1: "TOTAL",
        COL2: "OPEN",
        COL3: "CLOSED",
        VALUE1: "",
        VALUE2: "OPEN_PROG",
        VALUE3: "CLOSED_PROG",
        FCOLOR1: "#BDBCBC",
        FCOLOR2: "#F44336",
        FCOLOR3: "#82B22E",
        TCOLOR1: "#818181",
        TCOLOR2: "#D50000",
        TCOLOR3: "#2E7D32",
        FILTER1: " [CATEGORY] = ''A'' ",
        FILTER2: " [CATEGORY] = ''A'' AND [STATUS_CODE] = ''C'' ",
        FILTER3: " [CATEGORY] = ''A'' AND [STATUS_CODE] != ''C'' ",
      },
    ],
  },

  DataItems: [
    {CATEGORY:"A",TOTAL:4513,OPEN:953,CLOSED:3560,OPEN_PROG:21.1,CLOSED_PROG:78.9}
  ]
}