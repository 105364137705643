import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 400,
      CanvasHeight: 300,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },
    DataItems:    [
      {PACKAGE_A:"P0",MDRCOUNT:1,MRCOUNT:1},
      {PACKAGE_A:"P1",MDRCOUNT:5,MRCOUNT:88},
      {PACKAGE_A:"P2",MDRCOUNT:4,MRCOUNT:59},
      {PACKAGE_A:"P3",MDRCOUNT:9,MRCOUNT:86},
      {PACKAGE_A:"Total",MDRCOUNT:19,MRCOUNT:234}
    ],
}