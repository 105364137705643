import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 600,
    CanvasHeight: 280,
    CanvasBorderWeight: 0.3,
    CanvasBorderColor: 'Transparent',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.2,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 310,
    CanvasChartHeight: 270,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: 'Transparent',
    CanvasChartFillColor: 'Transparent',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },

    // ----------------------------------------------- NOTE
  Note: {
    NoteDisplay: 'N',
    NoteX: 50,
    NoteY: 420,
    NoteAlign: 'start',
    NoteTitleText: 'Notes.',
    NoteTitleFont: 'roboto',
    NoteTitleStyle: 'regular',
    NoteTitleSize: 11,
    NoteTitleColor: '#757575',

    NoteContentsText: 'Note Contents Here!',
    NoteContentsFont: 'roboto',
    NoteContentsStyle: 'regular',
    NoteContentsSize: 10,
    NoteContentsColor: '#BDBCBC',
  },

  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  DataItems: [
    // {PHASE_SEQ:6,PHASE:"6.Construction - Hull",DISC:"ARCHITECTURE",PLAN_A18_WK:1.9,PLAN_A24_WK:3.8,ACTUAL_WK:0,ACTUAL_CERT_WK:0,VAR_WK:-3.8,PRE_PLAN_CUM:26.3,PLAN_CUM:27.4,ACTUAL_CUM:3.5,ACTUAL_CERT_CUM:0,VAR_CUM:-23.9},
    // {PHASE_SEQ:6,PHASE:"6.Construction - Hull",DISC:"ELECTRICAL",PLAN_A18_WK:0.7,PLAN_A24_WK:1.8,ACTUAL_WK:6.1,ACTUAL_CERT_WK:0,VAR_WK:4.3,PRE_PLAN_CUM:33.6,PLAN_CUM:26.7,ACTUAL_CUM:30.2,ACTUAL_CERT_CUM:7.4,VAR_CUM:3.5},
    // {PHASE_SEQ:6,PHASE:"6.Construction - Hull",DISC:"FIRE & SAFETY",PLAN_A18_WK:0,PLAN_A24_WK:22.5,ACTUAL_WK:0,ACTUAL_CERT_WK:0,VAR_WK:-22.5,PRE_PLAN_CUM:0,PLAN_CUM:38.5,ACTUAL_CUM:0,ACTUAL_CERT_CUM:0,VAR_CUM:-38.5},
    // {PHASE_SEQ:6,PHASE:"6.Construction - Hull",DISC:"HVAC",PLAN_A18_WK:2.5,PLAN_A24_WK:2.6,ACTUAL_WK:0,ACTUAL_CERT_WK:0,VAR_WK:-2.5,PRE_PLAN_CUM:81.5,PLAN_CUM:54.1,ACTUAL_CUM:12.5,ACTUAL_CERT_CUM:2.8,VAR_CUM:-41.6},
    // {PHASE_SEQ:6,PHASE:"6.Construction - Hull",DISC:"INSTRUMENT",PLAN_A18_WK:0.7,PLAN_A24_WK:0.1,ACTUAL_WK:0,ACTUAL_CERT_WK:0,VAR_WK:-0.1,PRE_PLAN_CUM:2.1,PLAN_CUM:1.5,ACTUAL_CUM:0,ACTUAL_CERT_CUM:0,VAR_CUM:-1.5},
    // {PHASE_SEQ:6,PHASE:"6.Construction - Hull",DISC:"INSULATION",PLAN_A18_WK:0.1,PLAN_A24_WK:0,ACTUAL_WK:0,ACTUAL_CERT_WK:0,VAR_WK:0,PRE_PLAN_CUM:0.3,PLAN_CUM:0,ACTUAL_CUM:0,ACTUAL_CERT_CUM:0,VAR_CUM:0},
    // {PHASE_SEQ:6,PHASE:"6.Construction - Hull",DISC:"MECHANICAL",PLAN_A18_WK:3.3,PLAN_A24_WK:2.1,ACTUAL_WK:0,ACTUAL_CERT_WK:0,VAR_WK:-2.1,PRE_PLAN_CUM:29,PLAN_CUM:17.3,ACTUAL_CUM:0,ACTUAL_CERT_CUM:0,VAR_CUM:-17.3},
    // {PHASE_SEQ:6,PHASE:"6.Construction - Hull",DISC:"PAINTING",PLAN_A18_WK:4.1,PLAN_A24_WK:0.8,ACTUAL_WK:0.1,ACTUAL_CERT_WK:0,VAR_WK:-0.6,PRE_PLAN_CUM:50.6,PLAN_CUM:31.5,ACTUAL_CUM:20.4,ACTUAL_CERT_CUM:17.7,VAR_CUM:-11.1},
    // {PHASE_SEQ:6,PHASE:"6.Construction - Hull",DISC:"PIPING",PLAN_A18_WK:1.4,PLAN_A24_WK:1.6,ACTUAL_WK:0.8,ACTUAL_CERT_WK:0,VAR_WK:-0.8,PRE_PLAN_CUM:66.4,PLAN_CUM:37.2,ACTUAL_CUM:9.5,ACTUAL_CERT_CUM:6.8,VAR_CUM:-27.7},
    // {PHASE_SEQ:6,PHASE:"6.Construction - Hull",DISC:"STRUCTURE",PLAN_A18_WK:0.5,PLAN_A24_WK:0.7,ACTUAL_WK:0.5,ACTUAL_CERT_WK:0.1,VAR_WK:-0.2,PRE_PLAN_CUM:95.9,PLAN_CUM:88.2,ACTUAL_CUM:76.7,ACTUAL_CERT_CUM:64.6,VAR_CUM:-11.5},
    // {PHASE_SEQ:6,PHASE:"6.Construction - Hull",DISC:"Total",PLAN_A18_WK:1.4,PLAN_A24_WK:1.1,ACTUAL_WK:0.6,ACTUAL_CERT_WK:0.1,VAR_WK:-0.5,PRE_PLAN_CUM:74.9,PLAN_CUM:58.9,ACTUAL_CUM:42.9,ACTUAL_CERT_CUM:34.9,VAR_CUM:-16}
  
    {PHASE_SEQ:4,PHASE:"6.Integration",DISC:"Electrical",PLAN_WK:2.45,ACTUAL_WK:0.79,ACTUAL_CERT_WK:3.63,VAR_WK:-1.65,PLAN_CUM:50.48,ACTUAL_CUM:50.4,ACTUAL_CERT_CUM:3.67,VAR_CUM:-0.08},
{PHASE_SEQ:4,PHASE:"6.Integration",DISC:"Fire & Safety",PLAN_WK:0,ACTUAL_WK:0,ACTUAL_CERT_WK:0,VAR_WK:0,PLAN_CUM:0,ACTUAL_CUM:0,ACTUAL_CERT_CUM:0,VAR_CUM:0},
{PHASE_SEQ:4,PHASE:"6.Integration",DISC:"Instrument",PLAN_WK:0.94,ACTUAL_WK:3.58,ACTUAL_CERT_WK:0.06,VAR_WK:2.64,PLAN_CUM:36.33,ACTUAL_CUM:37.25,ACTUAL_CERT_CUM:0.06,VAR_CUM:0.91},
{PHASE_SEQ:4,PHASE:"6.Integration",DISC:"Insulation",PLAN_WK:0,ACTUAL_WK:0,ACTUAL_CERT_WK:0,VAR_WK:0,PLAN_CUM:0,ACTUAL_CUM:0,ACTUAL_CERT_CUM:0,VAR_CUM:0},
{PHASE_SEQ:4,PHASE:"6.Integration",DISC:"Painting",PLAN_WK:1.01,ACTUAL_WK:2.47,ACTUAL_CERT_WK:0,VAR_WK:1.45,PLAN_CUM:15.61,ACTUAL_CUM:14.27,ACTUAL_CERT_CUM:1.33,VAR_CUM:-1.33},
{PHASE_SEQ:4,PHASE:"6.Integration",DISC:"Piping",PLAN_WK:2.45,ACTUAL_WK:2.15,ACTUAL_CERT_WK:0.29,VAR_WK:-0.29,PLAN_CUM:48.95,ACTUAL_CUM:56.81,ACTUAL_CERT_CUM:23.57,VAR_CUM:7.85},
{PHASE_SEQ:4,PHASE:"6.Integration",DISC:"Structure",PLAN_WK:1.85,ACTUAL_WK:0,ACTUAL_CERT_WK:0,VAR_WK:-1.85,PLAN_CUM:95.31,ACTUAL_CUM:79.18,ACTUAL_CERT_CUM:41.12,VAR_CUM:-16.13},
{PHASE_SEQ:4,PHASE:"6.Integration",DISC:"Total",PLAN_WK:1.79,ACTUAL_WK:1.95,ACTUAL_CERT_WK:0.45,VAR_WK:0.15,PLAN_CUM:48.7,ACTUAL_CUM:48.48,ACTUAL_CERT_CUM:16.04,VAR_CUM:-0.21}
  
  ]
}