import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1700,
      CanvasHeight: 150,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },
    DataItems:    [
      {
        EST_DOCS:4310,EST_HOURS:89454,
        EAR_TARGET:9061,EAR_ACTUAL:11413,
        COM_TARGET:10.1,COM_ACTUAL:12.8,COM_DELATA:2.6,
        PWK_TARGET:3.9,PWK_ACTUAL:0
      }
      ],
    Queries: {
      // prev week
      SQL1: [
        {ISSUE:"IFR",TARGET:1159,COMPLETED:1321,DELATA:162,TOGO:2989},
        {ISSUE:"IFU",TARGET:39,COMPLETED:77,DELATA:38,TOGO:4233}
      ],
      
      // present week
      SQL2: [
        {ISSUE:"IFR",TARGET:1221,COMPLETED:1321,DELTA:100,OVERDUE:7,AHEAD:111,TOGO:2989},
        {ISSUE:"IFU",TARGET:39,COMPLETED:77,DELTA:38,OVERDUE:2,AHEAD:40,TOGO:4233}
      ]
    }
}