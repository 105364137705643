import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1200,
      CanvasHeight: 240,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     

    DataItems: [
      { TYPE: 'MC',   DATE: '43889',  DISC: 'LWK',          TOTAL: null,    ACTUAL: 3426,   REMAIN: null,   PROG: null, },
      { TYPE: 'MC',   DATE: '43890',  DISC: 'SAT',          TOTAL: null,    ACTUAL: 411,    REMAIN: null,   PROG: null, },
      { TYPE: 'MC',   DATE: '43891',  DISC: 'SUN',          TOTAL: null,    ACTUAL: 95,     REMAIN: null,   PROG: null, },
      { TYPE: 'MC',   DATE: '43892',  DISC: 'MON',          TOTAL: null,    ACTUAL: 0,      REMAIN: null,   PROG: null, },
      { TYPE: 'MC',   DATE: '43893',  DISC: 'TUE',          TOTAL: null,    ACTUAL: 0,      REMAIN: null,   PROG: null, },
      { TYPE: 'MC',   DATE: '43894',  DISC: 'WEN',          TOTAL: null,    ACTUAL: 0,      REMAIN: null,   PROG: null, },
      { TYPE: 'MC',   DATE: '43895',  DISC: 'TUE',          TOTAL: null,    ACTUAL: 0,      REMAIN: null,   PROG: null, },
      { TYPE: 'MC',   DATE: '43896',  DISC: 'FRI',          TOTAL: null,    ACTUAL: 0,      REMAIN: null,   PROG: null, },
      { TYPE: 'MC',   DATE: '43896',  DISC: 'TWK',          TOTAL: null,    ACTUAL: 506,    REMAIN: null,   PROG: null, },

      { TYPE: 'RFDC', DATE: '43889',  DISC: 'LWK',          TOTAL: null,    ACTUAL: 3426,   REMAIN: null,   PROG: null, },
      { TYPE: 'RFDC', DATE: '43890',  DISC: 'SAT',          TOTAL: null,    ACTUAL: 411,    REMAIN: null,   PROG: null, },
      { TYPE: 'RFDC', DATE: '43891',  DISC: 'SUN',          TOTAL: null,    ACTUAL: 95,     REMAIN: null,   PROG: null, },
      { TYPE: 'RFDC', DATE: '43892',  DISC: 'MON',          TOTAL: null,    ACTUAL: 0,      REMAIN: null,   PROG: null, },
      { TYPE: 'RFDC', DATE: '43893',  DISC: 'TUE',          TOTAL: null,    ACTUAL: 0,      REMAIN: null,   PROG: null, },
      { TYPE: 'RFDC', DATE: '43894',  DISC: 'WEN',          TOTAL: null,    ACTUAL: 0,      REMAIN: null,   PROG: null, },
      { TYPE: 'RFDC', DATE: '43895',  DISC: 'TUE',          TOTAL: null,    ACTUAL: 0,      REMAIN: null,   PROG: null, },
      { TYPE: 'RFDC', DATE: '43896',  DISC: 'FRI',          TOTAL: null,    ACTUAL: 0,      REMAIN: null,   PROG: null, },
      { TYPE: 'RFDC', DATE: '43896',  DISC: 'TWK',          TOTAL: null,    ACTUAL: 506,    REMAIN: null,   PROG: null, },
    ],

    Queries: {
      SQL1: [
        { TYPE: 'MC',   PLAN: '2018-05-05', FORECAST: '2018-05-05', TOTAL: 1444,    ACTUAL: 210,    REMAIN: 1234,   PROG: 14.54, },
        { TYPE: 'RFDC', PLAN: '2018-05-05', FORECAST: '2018-05-05', TOTAL: 43742,   ACTUAL: 25361,  REMAIN: 18381,  PROG: 57.98, },
        { TYPE: 'ORC',  PLAN: '2018-05-05', FORECAST: '2018-05-05', TOTAL: 43,      ACTUAL: 25   ,  REMAIN: 18,     PROG: 57,    },
      ],

      SQL2: [
        { TYPE: 'MC', WEEK: 12},
        { TYPE: 'MC', WEEK: 10},
        { TYPE: 'MC', WEEK: 9 },
        { TYPE: 'MC', WEEK: 5 },
        { TYPE: 'MC', WEEK: 0 },
        { TYPE: 'MC', WEEK: 0 },
        { TYPE: 'MC', WEEK: 0 },
        { TYPE: 'MC', WEEK: 2 },
        { TYPE: 'MC', WEEK: 3 },
        { TYPE: 'MC', WEEK: 7 },
        { TYPE: 'MC', WEEK: 5 },
        { TYPE: 'MC', WEEK: 11},

        { TYPE: 'RFDC', WEEK: 12},
        { TYPE: 'RFDC', WEEK: 10},
        { TYPE: 'RFDC', WEEK: 9 },
        { TYPE: 'RFDC', WEEK: 5 },
        { TYPE: 'RFDC', WEEK: 0 },
        { TYPE: 'RFDC', WEEK: 0 },
        { TYPE: 'RFDC', WEEK: 0 },
        { TYPE: 'RFDC', WEEK: 2 },
        { TYPE: 'RFDC', WEEK: 3 },
        { TYPE: 'RFDC', WEEK: 7 },
        { TYPE: 'RFDC', WEEK: 5 },
        { TYPE: 'RFDC', WEEK: 11},
      ],
    },


}