export default {
    Canvas: {
      CanvasWidth: 840,
      CanvasHeight: 430,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 840,
      CanvasChartHeight: 430,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        {text:'',x: 70,y: 42,font: 'roboto',size: 14,}
      ],
      Filter: [],
      Sql: [
        {
          query: "SELECT 'Datasource: Shop Drawing Index 04-Oct-20' TITLE", 
          text: 'Datasource: Shop Drawing Index 04-Oct-20',
          x: 70,
          y: -60,
          font: 'roboto',
          style: 'regular',
          size: 10,
          color: '#757575',
          align: 'start'
        },
      ]
    },
    DataItems: [
      {WK_NO:"W2022_06",CUTOFF:"2022-02-11",PLAN_CUM:22.2,ACTUAL_CUM:1.07,PLAN_WK:3.18,ACTUAL_WK:0.09},
      {WK_NO:"W2022_07",CUTOFF:"2022-02-18",PLAN_CUM:23.94,ACTUAL_CUM:1.3,PLAN_WK:1.74,ACTUAL_WK:0.23},
      {WK_NO:"W2022_08",CUTOFF:"2022-02-25",PLAN_CUM:28.71,ACTUAL_CUM:1.62,PLAN_WK:4.77,ACTUAL_WK:0.32},
      {WK_NO:"W2022_09",CUTOFF:"2022-03-04",PLAN_CUM:31.12,ACTUAL_CUM:2.41,PLAN_WK:2.42,ACTUAL_WK:0.79},
      {WK_NO:"W2022_10",CUTOFF:"2022-03-11",PLAN_CUM:33.68,ACTUAL_CUM:4.08,PLAN_WK:2.56,ACTUAL_WK:1.67},
      {WK_NO:"W2022_11",CUTOFF:"2022-03-18",PLAN_CUM:34.8,ACTUAL_CUM:4.61,PLAN_WK:1.12,ACTUAL_WK:0.53},
      {WK_NO:"W2022_12",CUTOFF:"2022-03-25",PLAN_CUM:36.65,ACTUAL_CUM:5.94,PLAN_WK:1.85,ACTUAL_WK:1.33},
      {WK_NO:"W2022_13",CUTOFF:"2022-04-01",PLAN_CUM:38.89,ACTUAL_CUM:5.94,PLAN_WK:2.24,ACTUAL_WK:0},
      {WK_NO:"W2022_14",CUTOFF:"2022-04-08",PLAN_CUM:42.05,ACTUAL_CUM:5.94,PLAN_WK:3.16,ACTUAL_WK:0},
      {WK_NO:"W2022_15",CUTOFF:"2022-04-15",PLAN_CUM:43.74,PLAN_WK:1.69},
      {WK_NO:"W2022_16",CUTOFF:"2022-04-22",PLAN_CUM:46.35,PLAN_WK:2.61},
      {WK_NO:"W2022_17",CUTOFF:"2022-04-29",PLAN_CUM:48.46,PLAN_WK:2.12},
      {WK_NO:"W2022_18",CUTOFF:"2022-05-06",PLAN_CUM:50.39,PLAN_WK:1.93},
      {WK_NO:"W2022_19",CUTOFF:"2022-05-13",PLAN_CUM:52.95,PLAN_WK:2.56},
      {WK_NO:"W2022_20",CUTOFF:"2022-05-20",PLAN_CUM:53.8,PLAN_WK:0.85},
      {WK_NO:"W2022_21",CUTOFF:"2022-05-27",PLAN_CUM:56.59,PLAN_WK:2.78}
    ],    
    Queries: {
      // Table Headers
      SQL1: [
      ],       
      SQL2: [
        {TOTAL: 164, OPEN: 164, BP_REVIEW: 27, TFMC_DEV: 46, },
      ],       
    },
}