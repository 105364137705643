import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1400,
      CanvasHeight: 2000, //SCROLL 했을때 320
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,
      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },
    DataItems:  [
      {TYPE:"1.Priority MC Complete",NO:1,PRIORITY:"P1",MODULE:"Hull",SUBSYSTEM:"21-6600-6674-01",SUBSYSTEM_DESCR:"HULL Normal & Essential 690 V Swtchboard 21-ESB-667401",MC_PLAN:"2021-09-21",A_OV_TOT:323,A_OV_COM:315,A_OV_REM:8,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:97.52,PA_OV_TOT:9,PA_OV_COM:9,PA_OV_REM:0,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:100,MC_STATUS:"MC Approved Partially"},
      {TYPE:"1.Priority MC Complete",NO:2,PRIORITY:"P1",MODULE:"Hull",SUBSYSTEM:"21-6600-6675-01",SUBSYSTEM_DESCR:"HULL Normal & Essential 400 V Switchboard 21-ESB-667601",MC_PLAN:"2021-09-23",A_OV_TOT:57,A_OV_COM:54,A_OV_REM:3,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:94.73,PA_OV_TOT:8,PA_OV_COM:8,PA_OV_REM:0,PA_OV_COM_LW:1,PA_OV_COM_TW:0,PA_OV_PROG:100,MC_STATUS:"MC Approved Partially"},
      {TYPE:"2.MC 7 Days Look Ahead",NO:7,PRIORITY:"P1",MODULE:"Topsides",SUBSYSTEM:"21-6600-6640-02",SUBSYSTEM_DESCR:"Topsides Emergency Lighting & small power 400V board 21-EDB-662402 (in LER)",MC_PLAN:"2021-10-23",A_OV_TOT:2692,A_OV_COM:0,A_OV_REM:2692,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:0,PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:0,MC_STATUS:"Not Started"},
      {TYPE:"2.MC 7 Days Look Ahead",NO:10,PRIORITY:"P2",MODULE:"Topsides",SUBSYSTEM:"21-6600-6675-03",SUBSYSTEM_DESCR:"Topsides Normal / Essential lighting & Small Power 400V 21-EDB-667603",MC_PLAN:"2021-10-23",A_OV_TOT:2436,A_OV_COM:0,A_OV_REM:2436,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:0,MC_STATUS:"Not Started"},
      {TYPE:"2.MC 7 Days Look Ahead",NO:8,PRIORITY:"P1",MODULE:"Topsides",SUBSYSTEM:"21-6600-6674-02",SUBSYSTEM_DESCR:"Topside Essential 690 V Switchboard 21-ESB-667402",MC_PLAN:"2021-10-23",A_OV_TOT:233,A_OV_COM:0,A_OV_REM:233,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:0,MC_STATUS:"Not Started"},
      {TYPE:"2.MC 7 Days Look Ahead",NO:11,PRIORITY:"P1",MODULE:"Topsides",SUBSYSTEM:"21-6600-6675-04",SUBSYSTEM_DESCR:"Topsides MOV & Convenience Outlets 400V 21-EDB-667604",MC_PLAN:"2021-10-23",A_OV_TOT:219,A_OV_COM:0,A_OV_REM:219,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:0,MC_STATUS:"Not Started"},
      {TYPE:"2.MC 7 Days Look Ahead",NO:9,PRIORITY:"P2",MODULE:"Topsides",SUBSYSTEM:"21-6600-6675-02",SUBSYSTEM_DESCR:"Topsides Normal / Essential lighting & Small Power 400V 21-EDB-667602",MC_PLAN:"2021-10-23",A_OV_TOT:184,A_OV_COM:0,A_OV_REM:184,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:0,PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:0,MC_STATUS:"Not Started"},
      {TYPE:"2.MC 7 Days Look Ahead",NO:12,PRIORITY:"P1",MODULE:"Topsides",SUBSYSTEM:"21-6600-6675-05",SUBSYSTEM_DESCR:"Topsides Misc Feeders / Auxiliaries 400V 21-EDB-667606",MC_PLAN:"2021-10-23",A_OV_TOT:27,A_OV_COM:0,A_OV_REM:27,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:0,MC_STATUS:"Not Started"},
      {TYPE:"2.MC 7 Days Look Ahead",NO:19,PRIORITY:"P1A",MODULE:"Hull",SUBSYSTEM:"21-8100-8121-01",SUBSYSTEM_DESCR:"HULL Instrument Air Distribution",MC_PLAN:"2021-10-25",A_OV_TOT:560,A_OV_COM:202,A_OV_REM:358,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:36.07,MC_STATUS:"A ITR in progress"},
      {TYPE:"2.MC 7 Days Look Ahead",NO:17,PRIORITY:"P1",MODULE:"Hull",SUBSYSTEM:"21-8060-8061-02",SUBSYSTEM_DESCR:"Diesel Storage Tanks - 21-T-806001/2 & Purifier Pumps - 21-P-806002A/B",MC_PLAN:"2021-10-25",A_OV_TOT:178,A_OV_COM:36,A_OV_REM:142,A_OV_COM_LW:2,A_OV_COM_TW:0,A_OV_PROG:20.22,PA_OV_TOT:4,PA_OV_COM:0,PA_OV_REM:4,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:0,MC_STATUS:"A ITR in progress"},
      {TYPE:"2.MC 7 Days Look Ahead",NO:14,PRIORITY:"P1",MODULE:"Hull",SUBSYSTEM:"21-8020-8024-01",SUBSYSTEM_DESCR:"HULL Sea Water Cooling Pumps - 21-P-802004A/B",MC_PLAN:"2021-10-25",A_OV_TOT:71,A_OV_COM:2,A_OV_REM:69,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:2.81,MC_STATUS:"A ITR in progress"},
      {TYPE:"2.MC 7 Days Look Ahead",NO:15,PRIORITY:"P1",MODULE:"Hull",SUBSYSTEM:"21-8020-8024-02",SUBSYSTEM_DESCR:"HULL Sea Water Cooling Header & Cooling Water Exchangers - 21-H-805001A/B",MC_PLAN:"2021-10-25",A_OV_TOT:53,A_OV_COM:1,A_OV_REM:52,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:1.88,MC_STATUS:"A ITR in progress"},
      {TYPE:"2.MC 7 Days Look Ahead",NO:18,PRIORITY:"P1",MODULE:"Hull",SUBSYSTEM:"21-8060-8061-03",SUBSYSTEM_DESCR:"Diesel Supply & Return (Spill Back) Headers to Service Tanks - 21-T-806003/4",MC_PLAN:"2021-10-25",A_OV_TOT:56,A_OV_COM:28,A_OV_REM:28,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:50,MC_STATUS:"A ITR in progress"},
      {TYPE:"2.MC 7 Days Look Ahead",NO:20,PRIORITY:"P2",MODULE:"Hull",SUBSYSTEM:"21-9380-9380-05",SUBSYSTEM_DESCR:"Diesel Oil from Diesel Storage Tanks",MC_PLAN:"2021-10-25",A_OV_TOT:8,A_OV_COM:1,A_OV_REM:7,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:12.5,MC_STATUS:"A ITR in progress"},
      {TYPE:"2.MC 7 Days Look Ahead",NO:1,PRIORITY:"P1A",MODULE:"LQ",SUBSYSTEM:"21-6010-6011-02",SUBSYSTEM_DESCR:"SIS Marshalling & System Cabinets - LQ (CER)",MC_PLAN:"2021-10-25",A_OV_TOT:17,A_OV_COM:17,A_OV_REM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:100,PA_OV_TOT:16,PA_OV_COM:0,PA_OV_REM:16,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:0,MC_STATUS:"WD Completed"},
      {TYPE:"2.MC 7 Days Look Ahead",NO:3,PRIORITY:"P1A",MODULE:"LQ",SUBSYSTEM:"21-6020-6021-02",SUBSYSTEM_DESCR:"PAS Marshalling & System Cabinets - LQ (CER)",MC_PLAN:"2021-10-25",A_OV_TOT:68,A_OV_COM:68,A_OV_REM:0,A_OV_COM_LW:2,A_OV_COM_TW:0,A_OV_PROG:100,PA_OV_TOT:17,PA_OV_COM:0,PA_OV_REM:17,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:0,MC_STATUS:"WD Completed"},
      {TYPE:"2.MC 7 Days Look Ahead",NO:5,PRIORITY:"P1A",MODULE:"LQ",SUBSYSTEM:"21-6070-6071-02",SUBSYSTEM_DESCR:"FGS Marshalling & System Cabinets - LQ (CER)",MC_PLAN:"2021-10-25",A_OV_TOT:25,A_OV_COM:25,A_OV_REM:0,A_OV_COM_LW:4,A_OV_COM_TW:0,A_OV_PROG:100,PA_OV_TOT:7,PA_OV_COM:0,PA_OV_REM:7,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:0,MC_STATUS:"WD Completed"},
      {TYPE:"2.MC 7 Days Look Ahead",NO:4,PRIORITY:"P1",MODULE:"Hull",SUBSYSTEM:"21-6020-6021-06",SUBSYSTEM_DESCR:"PAS Multicore & Junction Box to Marshalling / System Cabinets in HER",MC_PLAN:"2021-10-26",A_OV_TOT:145,A_OV_COM:0,A_OV_REM:145,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:0,MC_STATUS:"Not Started"},
      {TYPE:"2.MC 7 Days Look Ahead",NO:16,PRIORITY:"P1",MODULE:"Hull",SUBSYSTEM:"21-8020-8026-01",SUBSYSTEM_DESCR:"HULL Service Sea Water to HVAC Cooling",MC_PLAN:"2021-10-27",A_OV_TOT:84,A_OV_COM:1,A_OV_REM:83,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:1.19,MC_STATUS:"A ITR in progress"},
      {TYPE:"2.MC 7 Days Look Ahead",NO:6,PRIORITY:"P1",MODULE:"Hull",SUBSYSTEM:"21-6070-6071-06",SUBSYSTEM_DESCR:"FGS Multicore & Junction Box to Marshalling / System Cabinets in HER",MC_PLAN:"2021-10-27",A_OV_TOT:13,A_OV_COM:0,A_OV_REM:13,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:0,MC_STATUS:"Not Started"},
      {TYPE:"2.MC 7 Days Look Ahead",NO:2,PRIORITY:"P1",MODULE:"Hull",SUBSYSTEM:"21-6010-6011-07",SUBSYSTEM_DESCR:"SIS Multicore & Junction Box to Marshalling / System Cabinets in HER",MC_PLAN:"2021-10-28",A_OV_TOT:105,A_OV_COM:0,A_OV_REM:105,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:0,MC_STATUS:"Not Started"},
      {TYPE:"2.MC 7 Days Look Ahead",NO:13,PRIORITY:"P2",MODULE:"LQ",SUBSYSTEM:"21-6600-6676-16",SUBSYSTEM_DESCR:"LQ Galley Panel 02 (ROOM: L220) 21-ELP-667678",MC_PLAN:"2021-10-28",A_OV_TOT:4,A_OV_COM:0,A_OV_REM:4,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:0,PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:0,MC_STATUS:"Not Started"},
      {TYPE:"3.Priority MC Overdue",NO:4,PRIORITY:"P1A",MODULE:"LQ",SUBSYSTEM:"21-6600-6660-01",SUBSYSTEM_DESCR:"Emergency 690 V Switchboard 21-ESB-666001",MC_PLAN:"2021-09-27",A_OV_TOT:233,A_OV_COM:209,A_OV_REM:24,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:89.69,PA_OV_TOT:12,PA_OV_COM:10,PA_OV_REM:2,PA_OV_COM_LW:0,PA_OV_COM_TW:8,PA_OV_PROG:83.33,MC_STATUS:"WD Completed"},
      {TYPE:"3.Priority MC Overdue",NO:2,PRIORITY:"P1A",MODULE:"Hull",SUBSYSTEM:"21-6030-6036-01",SUBSYSTEM_DESCR:"PDBs Inside HER",MC_PLAN:"2021-09-28",A_OV_TOT:15,A_OV_COM:9,A_OV_REM:6,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:60,PA_OV_TOT:18,PA_OV_COM:6,PA_OV_REM:12,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:33.33,MC_STATUS:"WD Completed"},
      {TYPE:"3.Priority MC Overdue",NO:1,PRIORITY:"P1A",MODULE:"Hull",SUBSYSTEM:"21-6020-6021-01",SUBSYSTEM_DESCR:"PAS Marshalling & System Cabinets - Machinery Space (HER)",MC_PLAN:"2021-09-28",A_OV_TOT:108,A_OV_COM:107,A_OV_REM:1,A_OV_COM_LW:0,A_OV_COM_TW:1,A_OV_PROG:99.07,PA_OV_TOT:4,PA_OV_COM:1,PA_OV_REM:3,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:25,MC_STATUS:"RFI Issued"},
      {TYPE:"3.Priority MC Overdue",NO:5,PRIORITY:"P1A",MODULE:"LQ",SUBSYSTEM:"21-6700-6701-01",SUBSYSTEM_DESCR:"Main LQ UPS System (Incl Batteries, Breakers & Subdistribution Board)",MC_PLAN:"2021-09-30",A_OV_TOT:36,A_OV_COM:36,A_OV_REM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:100,PA_OV_TOT:31,PA_OV_COM:29,PA_OV_REM:2,PA_OV_COM_LW:0,PA_OV_COM_TW:26,PA_OV_PROG:93.54,MC_STATUS:"WD Completed"},
      {TYPE:"3.Priority MC Overdue",NO:3,PRIORITY:"P1A",MODULE:"LQ",SUBSYSTEM:"21-6030-6036-02",SUBSYSTEM_DESCR:"PDBs Inside CER",MC_PLAN:"2021-10-13",A_OV_TOT:33,A_OV_COM:14,A_OV_REM:19,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:42.42,PA_OV_TOT:28,PA_OV_COM:0,PA_OV_REM:28,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:0,MC_STATUS:"WD Completed"},
      {TYPE:"3.Priority MC Overdue",NO:6,PRIORITY:"P2",MODULE:"Hull",SUBSYSTEM:"21-8100-8120-01",SUBSYSTEM_DESCR:"HULL Air Compressor 21-Z-811002",MC_PLAN:"2021-10-15",A_OV_TOT:55,A_OV_COM:23,A_OV_REM:32,A_OV_COM_LW:4,A_OV_COM_TW:0,A_OV_PROG:41.81,PA_OV_TOT:6,PA_OV_COM:6,PA_OV_REM:0,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:100,MC_STATUS:"A ITR in progress"},
      {TYPE:"4.MC Overdue(except Priority)",NO:11,PRIORITY:"P2",MODULE:"LQ",SUBSYSTEM:"21-6600-6624-02",SUBSYSTEM_DESCR:"LQ Emergency Lighting Panel & Circuits (Room: L224) 21-ELP-662461",MC_PLAN:"2021-09-19",A_OV_TOT:103,A_OV_COM:0,A_OV_REM:103,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:0,PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:50,MC_STATUS:"Not Started"},
      {TYPE:"4.MC Overdue(except Priority)",NO:12,PRIORITY:"P2",MODULE:"LQ",SUBSYSTEM:"21-6600-6624-03",SUBSYSTEM_DESCR:"LQ Emergency Lighting Panel & Circuits (Room: L320) 21-ELP-662462",MC_PLAN:"2021-09-19",A_OV_TOT:77,A_OV_COM:0,A_OV_REM:77,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:0,PA_OV_TOT:3,PA_OV_COM:2,PA_OV_REM:1,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:66.66,MC_STATUS:"Not Started"},
      {TYPE:"4.MC Overdue(except Priority)",NO:14,PRIORITY:"P1",MODULE:"LQ",SUBSYSTEM:"21-6600-6640-01",SUBSYSTEM_DESCR:"LQ/HULL Emergency Lighting & small power 400V board 21-EDB-662401 (in LQ)",MC_PLAN:"2021-09-20",A_OV_TOT:41,A_OV_COM:15,A_OV_REM:26,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:36.58,PA_OV_TOT:5,PA_OV_COM:1,PA_OV_REM:4,PA_OV_COM_LW:0,PA_OV_COM_TW:1,PA_OV_PROG:20,MC_STATUS:"A ITR in progress"},
      {TYPE:"4.MC Overdue(except Priority)",NO:15,PRIORITY:"P2",MODULE:"LQ",SUBSYSTEM:"21-6600-6640-03",SUBSYSTEM_DESCR:"Sub Distribution Auxiliary Panel 01 (ROOM: LH16) 21-EDB-667671",MC_PLAN:"2021-09-20",A_OV_TOT:2,A_OV_COM:0,A_OV_REM:2,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:0,PA_OV_TOT:4,PA_OV_COM:0,PA_OV_REM:4,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:0,MC_STATUS:"Not Started"},
      {TYPE:"4.MC Overdue(except Priority)",NO:13,PRIORITY:"P2",MODULE:"Hull",SUBSYSTEM:"21-6600-6624-05",SUBSYSTEM_DESCR:"HULL Emergency Lighting Panel & Circuits (Room: M104) 21-ELP-662401",MC_PLAN:"2021-09-23",A_OV_TOT:68,A_OV_COM:33,A_OV_REM:35,A_OV_COM_LW:0,A_OV_COM_TW:4,A_OV_PROG:48.52,PA_OV_TOT:6,PA_OV_COM:1,PA_OV_REM:5,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:16.66,MC_STATUS:"A ITR in progress"},
      {TYPE:"4.MC Overdue(except Priority)",NO:19,PRIORITY:"P2",MODULE:"Hull",SUBSYSTEM:"21-6600-6676-01",SUBSYSTEM_DESCR:"Normal Lighting Panel 01 (ROOM: M103) 21-ELP-667630",MC_PLAN:"2021-09-23",A_OV_TOT:52,A_OV_COM:34,A_OV_REM:18,A_OV_COM_LW:10,A_OV_COM_TW:2,A_OV_PROG:65.38,PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:0,MC_STATUS:"A ITR in progress"},
      {TYPE:"4.MC Overdue(except Priority)",NO:10,PRIORITY:"P2",MODULE:"LQ",SUBSYSTEM:"21-6600-6624-01",SUBSYSTEM_DESCR:"LQ Emergency Lighting Panel & Circuits (Room: LH91) 21-ELP-662460",MC_PLAN:"2021-09-24",A_OV_TOT:151,A_OV_COM:0,A_OV_REM:151,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:0,PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:50,MC_STATUS:"Not Started"},
      {TYPE:"4.MC Overdue(except Priority)",NO:41,PRIORITY:"P1",MODULE:"LQ",SUBSYSTEM:"21-9921-9921-04",SUBSYSTEM_DESCR:"LQ Emergency SWB Room ACUs/ACCUs & UCPs",MC_PLAN:"2021-09-28",A_OV_TOT:49,A_OV_COM:0,A_OV_REM:49,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:0,MC_STATUS:"Not Started"},
      {TYPE:"4.MC Overdue(except Priority)",NO:21,PRIORITY:"P2",MODULE:"Hull",SUBSYSTEM:"21-6600-6676-03",SUBSYSTEM_DESCR:"Machinary Workshop Power Panel (ROOM: M104) 21-ELP-667636",MC_PLAN:"2021-09-28",A_OV_TOT:43,A_OV_COM:3,A_OV_REM:40,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:6.97,PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:0,MC_STATUS:"A ITR in progress"},
      {TYPE:"4.MC Overdue(except Priority)",NO:4,PRIORITY:"P1A",MODULE:"LQ",SUBSYSTEM:"21-6030-6040-02",SUBSYSTEM_DESCR:"IRP & Interface Instrumentation Cable - LQ",MC_PLAN:"2021-09-28",A_OV_TOT:67,A_OV_COM:47,A_OV_REM:20,A_OV_COM_LW:4,A_OV_COM_TW:19,A_OV_PROG:70.14,PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:0,MC_STATUS:"A ITR in progress"},
      {TYPE:"4.MC Overdue(except Priority)",NO:22,PRIORITY:"P2",MODULE:"LQ",SUBSYSTEM:"21-6600-6676-04",SUBSYSTEM_DESCR:"LQ Small Power for LQ Aux Eqt (ROOM: LH05) 21-ELP-667672",MC_PLAN:"2021-09-29",A_OV_TOT:133,A_OV_COM:0,A_OV_REM:133,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:0,PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:0,MC_STATUS:"Not Started"},
      {TYPE:"4.MC Overdue(except Priority)",NO:20,PRIORITY:"P2",MODULE:"Hull",SUBSYSTEM:"21-6600-6676-02",SUBSYSTEM_DESCR:"Normal Lighting Panel 02 (ROOM: M303) 21-ELP-667631",MC_PLAN:"2021-09-29",A_OV_TOT:50,A_OV_COM:50,A_OV_REM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:100,PA_OV_TOT:31,PA_OV_COM:14,PA_OV_REM:17,PA_OV_COM_LW:0,PA_OV_COM_TW:14,PA_OV_PROG:45.16,MC_STATUS:"WD Completed"},
      {TYPE:"4.MC Overdue(except Priority)",NO:5,PRIORITY:"P1A",MODULE:"Hull",SUBSYSTEM:"21-6030-6040-03",SUBSYSTEM_DESCR:"IRP & Interface Instrumentation Cable - MS03",MC_PLAN:"2021-09-30",A_OV_TOT:180,A_OV_COM:68,A_OV_REM:112,A_OV_COM_LW:9,A_OV_COM_TW:58,A_OV_PROG:37.77,MC_STATUS:"A ITR in progress"},
      {TYPE:"4.MC Overdue(except Priority)",NO:32,PRIORITY:"P1",MODULE:"LQ",SUBSYSTEM:"21-6700-6701-02",SUBSYSTEM_DESCR:"LQ Telecom UPS System B for HULL & LQ (Incl Batteries, Breakers & Subdistribution Board)",MC_PLAN:"2021-09-30",A_OV_TOT:15,A_OV_COM:5,A_OV_REM:10,A_OV_COM_LW:0,A_OV_COM_TW:4,A_OV_PROG:33.33,PA_OV_TOT:3,PA_OV_COM:0,PA_OV_REM:3,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:0,MC_STATUS:"A ITR in progress"},
      {TYPE:"4.MC Overdue(except Priority)",NO:17,PRIORITY:"P2",MODULE:"Hull",SUBSYSTEM:"21-6600-6640-05",SUBSYSTEM_DESCR:"Panel Control For External Lighting - 21-ECP-667680",MC_PLAN:"2021-09-30",A_OV_TOT:6,A_OV_COM:0,A_OV_REM:6,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:0,MC_STATUS:"Not Started"},
      {TYPE:"4.MC Overdue(except Priority)",NO:16,PRIORITY:"P2",MODULE:"Hull",SUBSYSTEM:"21-6600-6640-04",SUBSYSTEM_DESCR:"Sub Distribution Auxiliary Panel 03 (ROOM: M106)21-EDB-662433",MC_PLAN:"2021-09-30",A_OV_TOT:3,A_OV_COM:0,A_OV_REM:3,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:0,PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:0,MC_STATUS:"Not Started"},
      {TYPE:"4.MC Overdue(except Priority)",NO:29,PRIORITY:"P2",MODULE:"LQ",SUBSYSTEM:"21-6600-6676-11",SUBSYSTEM_DESCR:"Normal Lighting Panel 10 (ROOM: L445) 21-ELP-667665",MC_PLAN:"2021-10-08",A_OV_TOT:174,A_OV_COM:0,A_OV_REM:174,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:0,PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:0,MC_STATUS:"Not Started"},
      {TYPE:"4.MC Overdue(except Priority)",NO:28,PRIORITY:"P2",MODULE:"LQ",SUBSYSTEM:"21-6600-6676-10",SUBSYSTEM_DESCR:"Normal Lighting Panel 09 (ROOM: L345) 21-ELP-667664",MC_PLAN:"2021-10-08",A_OV_TOT:159,A_OV_COM:4,A_OV_REM:155,A_OV_COM_LW:4,A_OV_COM_TW:0,A_OV_PROG:2.51,PA_OV_TOT:3,PA_OV_COM:0,PA_OV_REM:3,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:0,MC_STATUS:"A ITR in progress"},
      {TYPE:"4.MC Overdue(except Priority)",NO:30,PRIORITY:"P2",MODULE:"LQ",SUBSYSTEM:"21-6600-6676-12",SUBSYSTEM_DESCR:"Normal Lighting Panel 11 (ROOM: L591) 21-ELP-667666",MC_PLAN:"2021-10-08",A_OV_TOT:80,A_OV_COM:0,A_OV_REM:80,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:0,PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:50,MC_STATUS:"Not Started"},
      {TYPE:"4.MC Overdue(except Priority)",NO:27,PRIORITY:"P2",MODULE:"LQ",SUBSYSTEM:"21-6600-6676-09",SUBSYSTEM_DESCR:"Normal Lighting Panel 08 (ROOM: L225) 21-ELP-667663",MC_PLAN:"2021-10-08",A_OV_TOT:60,A_OV_COM:0,A_OV_REM:60,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:0,PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:0,MC_STATUS:"Not Started"},
      {TYPE:"4.MC Overdue(except Priority)",NO:26,PRIORITY:"P2",MODULE:"LQ",SUBSYSTEM:"21-6600-6676-08",SUBSYSTEM_DESCR:"Normal Lighting Panel 07 (ROOM: L112) 21-ELP-667662",MC_PLAN:"2021-10-08",A_OV_TOT:43,A_OV_COM:1,A_OV_REM:42,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_PROG:2.32,PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:0,MC_STATUS:"A ITR in progress"},
      {TYPE:"4.MC Overdue(except Priority)",NO:7,PRIORITY:"P1",MODULE:"LQ",SUBSYSTEM:"21-6070-6072-01",SUBSYSTEM_DESCR:"Fire Addressable Panels - LQ (CER) & LQ F&G Addressable Loop-1",MC_PLAN:"2021-10-11",A_OV_TOT:398,A_OV_COM:84,A_OV_REM:314,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:21.1,PA_OV_TOT:11,PA_OV_COM:0,PA_OV_REM:11,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:0,MC_STATUS:"A ITR in progress"},
      {TYPE:"4.MC Overdue(except Priority)",NO:24,PRIORITY:"P2",MODULE:"LQ",SUBSYSTEM:"21-6600-6676-06",SUBSYSTEM_DESCR:"Normal Lighting Panel 05 (ROOM: LH05) 21-ELP-667660",MC_PLAN:"2021-10-11",A_OV_TOT:73,A_OV_COM:0,A_OV_REM:73,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:0,PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:0,MC_STATUS:"Not Started"},
      {TYPE:"4.MC Overdue(except Priority)",NO:44,PRIORITY:"P1",MODULE:"Hull",SUBSYSTEM:"21-9970-9971-11",SUBSYSTEM_DESCR:"HULL Diesel Oil Pump Room Exhaust Fans, Ducting & Dampers",MC_PLAN:"2021-10-11",A_OV_TOT:71,A_OV_COM:12,A_OV_REM:59,A_OV_COM_LW:4,A_OV_COM_TW:0,A_OV_PROG:16.9,MC_STATUS:"A ITR in progress"},
      {TYPE:"4.MC Overdue(except Priority)",NO:18,PRIORITY:"P1",MODULE:"Hull",SUBSYSTEM:"21-6600-6673-01",SUBSYSTEM_DESCR:"Essential 6.6 kV Switchboard 21-ESB-667301",MC_PLAN:"2021-10-13",A_OV_TOT:292,A_OV_COM:183,A_OV_REM:109,A_OV_COM_LW:30,A_OV_COM_TW:8,A_OV_PROG:62.67,PA_OV_TOT:7,PA_OV_COM:3,PA_OV_REM:4,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:42.85,MC_STATUS:"A ITR in progress"},
      {TYPE:"4.MC Overdue(except Priority)",NO:25,PRIORITY:"P2",MODULE:"LQ",SUBSYSTEM:"21-6600-6676-07",SUBSYSTEM_DESCR:"Normal Lighting Panel 06 (ROOM: LA17) 21-ELP-667661",MC_PLAN:"2021-10-15",A_OV_TOT:94,A_OV_COM:0,A_OV_REM:94,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:0,PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:0,MC_STATUS:"Not Started"},
      {TYPE:"4.MC Overdue(except Priority)",NO:39,PRIORITY:"P1A",MODULE:"Hull",SUBSYSTEM:"21-8100-8120-03",SUBSYSTEM_DESCR:"HULL Air Receiver 21-V-812002, Main header & Controls",MC_PLAN:"2021-10-15",A_OV_TOT:71,A_OV_COM:27,A_OV_REM:44,A_OV_COM_LW:0,A_OV_COM_TW:3,A_OV_PROG:38.02,MC_STATUS:"A ITR in progress"},
      {TYPE:"4.MC Overdue(except Priority)",NO:34,PRIORITY:"P1",MODULE:"Hull",SUBSYSTEM:"21-8050-8051-01",SUBSYSTEM_DESCR:"Marine Cooling Medium Pumps - 21-P805002A/B",MC_PLAN:"2021-10-15",A_OV_TOT:48,A_OV_COM:7,A_OV_REM:41,A_OV_COM_LW:0,A_OV_COM_TW:1,A_OV_PROG:14.58,PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:0,MC_STATUS:"A ITR in progress"},
      {TYPE:"4.MC Overdue(except Priority)",NO:37,PRIORITY:"P1",MODULE:"Hull",SUBSYSTEM:"21-8060-8061-01",SUBSYSTEM_DESCR:"Diesel Bunker Header",MC_PLAN:"2021-10-15",A_OV_TOT:63,A_OV_COM:32,A_OV_REM:31,A_OV_COM_LW:0,A_OV_COM_TW:3,A_OV_PROG:50.79,MC_STATUS:"A ITR in progress"},
      {TYPE:"4.MC Overdue(except Priority)",NO:38,PRIORITY:"P1",MODULE:"Hull",SUBSYSTEM:"21-8060-8062-02",SUBSYSTEM_DESCR:"Purifier Package",MC_PLAN:"2021-10-15",A_OV_TOT:32,A_OV_COM:8,A_OV_REM:24,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:25,PA_OV_TOT:17,PA_OV_COM:15,PA_OV_REM:2,PA_OV_COM_LW:0,PA_OV_COM_TW:1,PA_OV_PROG:88.23,MC_STATUS:"A ITR in progress"},
      {TYPE:"4.MC Overdue(except Priority)",NO:2,PRIORITY:"P1A",MODULE:"Hull",SUBSYSTEM:"21-6030-6032-03",SUBSYSTEM_DESCR:"ICSS Interface Servers, Workstations & Network Switches HER",MC_PLAN:"2021-10-15",A_OV_TOT:56,A_OV_COM:48,A_OV_REM:8,A_OV_COM_LW:3,A_OV_COM_TW:0,A_OV_PROG:85.71,PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:0,MC_STATUS:"A ITR in progress"},
      {TYPE:"4.MC Overdue(except Priority)",NO:31,PRIORITY:"P2",MODULE:"LQ",SUBSYSTEM:"21-6600-6676-14",SUBSYSTEM_DESCR:"LQ Laboratory Distribution Board (ROOM: LA04) 21-ELP-667675",MC_PLAN:"2021-10-15",A_OV_TOT:4,A_OV_COM:0,A_OV_REM:4,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:0,PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:0,MC_STATUS:"Not Started"},
      {TYPE:"4.MC Overdue(except Priority)",NO:40,PRIORITY:"P2",MODULE:"Hull",SUBSYSTEM:"21-9380-9380-04",SUBSYSTEM_DESCR:"Sludge from Diesel Purifier",MC_PLAN:"2021-10-15",A_OV_TOT:8,A_OV_COM:4,A_OV_REM:4,A_OV_COM_LW:0,A_OV_COM_TW:3,A_OV_PROG:50,MC_STATUS:"A ITR in progress"},
      {TYPE:"4.MC Overdue(except Priority)",NO:1,PRIORITY:"P1A",MODULE:"Hull",SUBSYSTEM:"21-6010-6011-01",SUBSYSTEM_DESCR:"SIS Marshalling & System Cabinets - Machinery Space (HER)",MC_PLAN:"2021-10-15",A_OV_TOT:43,A_OV_COM:40,A_OV_REM:3,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_PROG:93.02,PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:0,MC_STATUS:"WD Completed"},
      {TYPE:"4.MC Overdue(except Priority)",NO:23,PRIORITY:"P2",MODULE:"LQ",SUBSYSTEM:"21-6600-6676-05",SUBSYSTEM_DESCR:"LQ Galley Panel 01 (ROOM: L210) 21-ELP-667673",MC_PLAN:"2021-10-15",A_OV_TOT:4,A_OV_COM:3,A_OV_REM:1,A_OV_COM_LW:0,A_OV_COM_TW:3,A_OV_PROG:75,PA_OV_TOT:3,PA_OV_COM:1,PA_OV_REM:2,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:33.33,MC_STATUS:"A ITR in progress"},
      {TYPE:"4.MC Overdue(except Priority)",NO:6,PRIORITY:"P1A",MODULE:"Hull",SUBSYSTEM:"21-6070-6071-01",SUBSYSTEM_DESCR:"FGS Marshalling & System Cabinets - Machinery Space (HER)",MC_PLAN:"2021-10-15",A_OV_TOT:14,A_OV_COM:14,A_OV_REM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:100,PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:0,MC_STATUS:"WD Completed"},
      {TYPE:"4.MC Overdue(except Priority)",NO:33,PRIORITY:"P2",MODULE:"LQ",SUBSYSTEM:"21-6800-6842-13",SUBSYSTEM_DESCR:"Fire Zone 6843-3 - Emergency Diesel Generators",MC_PLAN:"2021-10-17",MC_STATUS:"Not Started"},
      {TYPE:"4.MC Overdue(except Priority)",NO:43,PRIORITY:"P1",MODULE:"Hull",SUBSYSTEM:"21-9970-9971-01",SUBSYSTEM_DESCR:"HULL HVAC UCPs, EDBs, VSDs",MC_PLAN:"2021-10-18",A_OV_TOT:100,A_OV_COM:9,A_OV_REM:91,A_OV_COM_LW:0,A_OV_COM_TW:9,A_OV_PROG:9,MC_STATUS:"A ITR in progress"},
      {TYPE:"4.MC Overdue(except Priority)",NO:36,PRIORITY:"P1",MODULE:"Hull",SUBSYSTEM:"21-8050-8051-03",SUBSYSTEM_DESCR:"Marine Cooling Medium Distribution",MC_PLAN:"2021-10-18",A_OV_TOT:110,A_OV_COM:21,A_OV_REM:89,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:19.09,PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:0,MC_STATUS:"A ITR in progress"},
      {TYPE:"4.MC Overdue(except Priority)",NO:8,PRIORITY:"P1",MODULE:"Hull",SUBSYSTEM:"21-6070-6072-08",SUBSYSTEM_DESCR:"Fire Addressable Panels - Machinery Space (HER) & Machinery Space F&G Addressable Loop-1",MC_PLAN:"2021-10-18",A_OV_TOT:276,A_OV_COM:194,A_OV_REM:82,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:70.28,PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:100,MC_STATUS:"A ITR in progress"},
      {TYPE:"4.MC Overdue(except Priority)",NO:3,PRIORITY:"P1",MODULE:"Hull",SUBSYSTEM:"21-6030-6037-01",SUBSYSTEM_DESCR:"Tank Gauging System Cabinets & Workstations",MC_PLAN:"2021-10-18",A_OV_TOT:149,A_OV_COM:77,A_OV_REM:72,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:51.67,MC_STATUS:"A ITR in progress"},
      {TYPE:"4.MC Overdue(except Priority)",NO:35,PRIORITY:"P1",MODULE:"Hull",SUBSYSTEM:"21-8050-8051-02",SUBSYSTEM_DESCR:"Marine Cooling Medium Expansion Tank",MC_PLAN:"2021-10-18",A_OV_TOT:55,A_OV_COM:22,A_OV_REM:33,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:40,MC_STATUS:"A ITR in progress"},
      {TYPE:"4.MC Overdue(except Priority)",NO:9,PRIORITY:"P1",MODULE:"Hull",SUBSYSTEM:"21-6070-6073-01",SUBSYSTEM_DESCR:"HSSD - Machinery Space (HER)",MC_PLAN:"2021-10-18",A_OV_TOT:8,A_OV_COM:0,A_OV_REM:8,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:0,MC_STATUS:"Not Started"},
      {TYPE:"4.MC Overdue(except Priority)",NO:42,PRIORITY:"P1",MODULE:"LQ",SUBSYSTEM:"21-9921-9921-06",SUBSYSTEM_DESCR:"LQ CER ACUs/ACCUs & UCPs",MC_PLAN:"2021-10-19",A_OV_TOT:26,A_OV_COM:0,A_OV_REM:26,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_PROG:0,PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PA_OV_COM_LW:0,PA_OV_COM_TW:0,PA_OV_PROG:0,MC_STATUS:"Not Started"}
    ],
}