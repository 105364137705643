import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 2300,
    CanvasHeight: 980,
    CanvasBorderWeight: 0.05,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 50,
    CanvasChartY: 50,
    CanvasChartWidth: 480,
    CanvasChartHeight: 250,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  DataItems:   [
    {SH:"21-2200",SH_DESCR:"Produced Condensate handling",RFDC:"21-2200-2221",RFDC_DESCR:"MP separator",RFDC_PLAN:"2022-07-14",RFDC_STATUS:"Not Started",PUNCH_STATUS:"B2",B_OV_TOT:50,B_OV_COM:0,B_OV_REM:50,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:1,PA_OV_REM:0},
    {SH:"21-2200",SH_DESCR:"Produced Condensate handling",RFDC:"21-2200-2240",RFDC_DESCR:"LLP separator, condensate treatment & coalescer|recycle pumps",RFDC_PLAN:"2022-05-26",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:111,B_OV_COM:0,B_OV_REM:111,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:5,PA_OV_REM:4,PB1_OV_TOT:6,PB1_OV_REM:3},
    {SH:"21-2200",SH_DESCR:"Produced Condensate handling",RFDC:"21-2200-2470",RFDC_DESCR:"Warm condensate heater & distribution & offspec condensate pump",RFDC_PLAN:"2022-07-28",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:81,B_OV_COM:0,B_OV_REM:81,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:3,PA_OV_REM:1,PB1_OV_TOT:1,PB1_OV_REM:0},
    {SH:"21-2400",SH_DESCR:"Condensate Storage & Export",RFDC:"21-2400-2450",RFDC_DESCR:"CFW1 Off-spec condensate tank & pumps (incl washing machine)",RFDC_PLAN:"2022-07-07",RFDC_STATUS:"In progress",PUNCH_STATUS:"A",B_OV_TOT:44,B_OV_COM:1,B_OV_REM:43,B_OV_PROG:2.27,B_OV_STATUS:"less than 50%",B_PG:2.27,PA_OV_TOT:5,PA_OV_REM:5,PB1_OV_TOT:7,PB1_OV_REM:5},
    {SH:"21-2400",SH_DESCR:"Condensate Storage & Export",RFDC:"21-2400-2460",RFDC_DESCR:"Condensate Offloading",RFDC_PLAN:"2022-06-23",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:88,B_OV_COM:0,B_OV_REM:88,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:2,PA_OV_REM:2,PB1_OV_TOT:7,PB1_OV_REM:2},
    {SH:"21-2400",SH_DESCR:"Condensate Storage & Export",RFDC:"21-2400-9362",RFDC_DESCR:"COW2 tank & pumps (incl washing machine)",RFDC_PLAN:"2022-06-16",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:24,B_OV_COM:0,B_OV_REM:24,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:2,PA_OV_REM:2,PB1_OV_TOT:2,PB1_OV_REM:2},
    {SH:"21-2400",SH_DESCR:"Condensate Storage & Export",RFDC:"21-2400-9363",RFDC_DESCR:"COW3 tank & pumps (incl washing machine)",RFDC_PLAN:"2022-06-16",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:23,B_OV_COM:0,B_OV_REM:23,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:4,PA_OV_REM:3,PB1_OV_TOT:1,PB1_OV_REM:1},
    {SH:"21-2400",SH_DESCR:"Condensate Storage & Export",RFDC:"21-2400-9364",RFDC_DESCR:"COW4 tank & pumps (incl washing machine)",RFDC_PLAN:"2022-06-09",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:23,B_OV_COM:0,B_OV_REM:23,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:6,PA_OV_REM:6,PB1_OV_TOT:1,PB1_OV_REM:1},
    {SH:"21-2400",SH_DESCR:"Condensate Storage & Export",RFDC:"21-2400-9365",RFDC_DESCR:"COE1 tank & pumps (incl washing machine)",RFDC_PLAN:"2022-07-07",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:23,B_OV_COM:0,B_OV_REM:23,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:1,PA_OV_REM:1,PB1_OV_TOT:6,PB1_OV_REM:3},
    {SH:"21-2400",SH_DESCR:"Condensate Storage & Export",RFDC:"21-2400-9366",RFDC_DESCR:"COE2 tank & pumps (incl washing machine)",RFDC_PLAN:"2022-06-23",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:24,B_OV_COM:0,B_OV_REM:24,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:4,PA_OV_REM:4,PB1_OV_TOT:3,PB1_OV_REM:3},
    {SH:"21-2400",SH_DESCR:"Condensate Storage & Export",RFDC:"21-2400-9367",RFDC_DESCR:"COE3 tank & pumps (incl washing machine)",RFDC_PLAN:"2022-06-16",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:23,B_OV_COM:0,B_OV_REM:23,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:4,PA_OV_REM:4,PB1_OV_TOT:3,PB1_OV_REM:3},
    {SH:"21-2400",SH_DESCR:"Condensate Storage & Export",RFDC:"21-2400-9368",RFDC_DESCR:"COE4 tank & pumps (incl washing machine)",RFDC_PLAN:"2022-06-09",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:23,B_OV_COM:0,B_OV_REM:23,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:8,PA_OV_REM:8,PB1_OV_TOT:3,PB1_OV_REM:3},
    {SH:"21-2500",SH_DESCR:"MEG |Recovery and Injection",RFDC:"21-2500-2511",RFDC_DESCR:"MRW1 Rich MEG storage & pumps",RFDC_PLAN:"2022-06-30",RFDC_STATUS:"In progress",PUNCH_STATUS:"A",B_OV_TOT:47,B_OV_COM:2,B_OV_REM:45,B_OV_PROG:4.25,B_OV_STATUS:"less than 50%",B_PG:4.25,PA_OV_TOT:1,PA_OV_REM:1,PB1_OV_TOT:5,PB1_OV_REM:0},
    {SH:"21-2500",SH_DESCR:"MEG |Recovery and Injection",RFDC:"21-2500-2512",RFDC_DESCR:"MSE1 salty rich MEG storage & pumps",RFDC_PLAN:"2022-07-07",RFDC_STATUS:"In progress",PUNCH_STATUS:"A",B_OV_TOT:26,B_OV_COM:2,B_OV_REM:24,B_OV_PROG:7.69,B_OV_STATUS:"less than 50%",B_PG:7.69,PA_OV_TOT:1,PA_OV_REM:1,PB1_OV_TOT:4,PB1_OV_REM:1},
    {SH:"21-2500",SH_DESCR:"MEG |Recovery and Injection",RFDC:"21-2500-2513",RFDC_DESCR:"MSW2 Salty rich MEG storage & pumps",RFDC_PLAN:"2022-06-30",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:20,B_OV_COM:0,B_OV_REM:20,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:10,PA_OV_REM:10},
    {SH:"21-2500",SH_DESCR:"MEG |Recovery and Injection",RFDC:"21-2500-2514",RFDC_DESCR:"MSE2 Salty rich MEG storage & pumps",RFDC_PLAN:"2022-06-23",RFDC_STATUS:"Not Started",PUNCH_STATUS:"B1",B_OV_TOT:20,B_OV_COM:0,B_OV_REM:20,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PB1_OV_TOT:3,PB1_OV_REM:3},
    {SH:"21-2500",SH_DESCR:"MEG |Recovery and Injection",RFDC:"21-2500-2552",RFDC_DESCR:"MLE1 Lean MEG storage & pumps",RFDC_PLAN:"2022-06-02",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:35,B_OV_COM:0,B_OV_REM:35,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:15,PA_OV_REM:14,PB1_OV_TOT:4,PB1_OV_REM:4},
    {SH:"21-2500",SH_DESCR:"MEG |Recovery and Injection",RFDC:"21-2500-2553",RFDC_DESCR:"Lean MEG filtration & distribution to topsides & subsea",RFDC_PLAN:"2022-05-26",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:179,B_OV_COM:0,B_OV_REM:179,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:28,PA_OV_REM:6,PB1_OV_TOT:9,PB1_OV_REM:8},
    {SH:"21-2600",SH_DESCR:"Methanol Storage, pumping and injection",RFDC:"21-2600-2630",RFDC_DESCR:"Methanol Storage Pumps and Header",RFDC_PLAN:"2022-06-23",RFDC_STATUS:"In progress",PUNCH_STATUS:"B2",B_OV_TOT:29,B_OV_COM:2,B_OV_REM:27,B_OV_PROG:6.89,B_OV_STATUS:"less than 50%",B_PG:6.89,PB1_OV_TOT:7,PB1_OV_REM:0},
    {SH:"21-2600",SH_DESCR:"Methanol Storage, pumping and injection",RFDC:"21-2600-2640",RFDC_DESCR:"Topsides Methanol filters, break tank, injection pumps & distribution",RFDC_PLAN:"2022-06-16",RFDC_STATUS:"Not Started",PUNCH_STATUS:"B2",B_OV_TOT:139,B_OV_COM:0,B_OV_REM:139,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0},
    {SH:"21-2600",SH_DESCR:"Methanol Storage, pumping and injection",RFDC:"21-2600-2641",RFDC_DESCR:"Subsea Methanol pumps & Dist",RFDC_PLAN:"2022-05-19",RFDC_STATUS:"Not Started",PUNCH_STATUS:"B1",B_OV_TOT:41,B_OV_COM:0,B_OV_REM:41,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PB1_OV_TOT:1,PB1_OV_REM:1},
    {SH:"21-3040",SH_DESCR:"Flash Gas",RFDC:"21-3040-3041",RFDC_DESCR:"LP|LLP Gas compression train",RFDC_PLAN:"2022-06-23",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:209,B_OV_COM:0,B_OV_REM:209,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:7,PA_OV_REM:6,PB1_OV_TOT:14,PB1_OV_REM:12},
    {SH:"21-3330",SH_DESCR:"Process Gas",RFDC:"21-3330-3330",RFDC_DESCR:"Process gas dewpointing unit",RFDC_PLAN:"2022-06-02",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:332,B_OV_COM:0,B_OV_REM:332,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:3,PA_OV_REM:2,PB1_OV_TOT:9,PB1_OV_REM:8},
    {SH:"21-3330",SH_DESCR:"Process Gas",RFDC:"21-3330-3410",RFDC_DESCR:"Process gas export & repressurization header",RFDC_PLAN:"2022-07-14",RFDC_STATUS:"Not Started",PUNCH_STATUS:"B1",B_OV_TOT:70,B_OV_COM:0,B_OV_REM:70,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:1,PA_OV_REM:0,PB1_OV_TOT:4,PB1_OV_REM:3},
    {SH:"21-4000",SH_DESCR:"Produced Water",RFDC:"21-4000-4030",RFDC_DESCR:"Produced water storage, pumps and headers",RFDC_PLAN:"2022-07-14",RFDC_STATUS:"In progress",PUNCH_STATUS:"A",B_OV_TOT:44,B_OV_COM:1,B_OV_REM:43,B_OV_PROG:2.27,B_OV_STATUS:"less than 50%",B_PG:2.27,PA_OV_TOT:7,PA_OV_REM:7,PB1_OV_TOT:9,PB1_OV_REM:1},
    {SH:"21-4000",SH_DESCR:"Produced Water",RFDC:"21-4000-4130",RFDC_DESCR:"Produced water oil removal system",RFDC_PLAN:"2022-06-23",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:108,B_OV_COM:0,B_OV_REM:108,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:13,PA_OV_REM:5,PB1_OV_TOT:11,PB1_OV_REM:9},
    {SH:"21-5000",SH_DESCR:"HP Flare",RFDC:"21-5000-5010",RFDC_DESCR:"HP Flare KO Drum & Pumps",RFDC_PLAN:"2022-05-26",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:51,B_OV_COM:0,B_OV_REM:51,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:2,PA_OV_REM:2},
    {SH:"21-5000",SH_DESCR:"HP Flare",RFDC:"21-5000-5020",RFDC_DESCR:"HP Flare ignition, metering & Tip",RFDC_PLAN:"2022-06-09",RFDC_STATUS:"Not Started",PUNCH_STATUS:"N",B_OV_TOT:24,B_OV_COM:0,B_OV_REM:24,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0},
    {SH:"21-5200",SH_DESCR:"Closed Drain| LP Flare",RFDC:"21-5200-4340",RFDC_DESCR:"Closed drain system",RFDC_PLAN:"2022-06-23",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:50,B_OV_COM:0,B_OV_REM:50,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:3,PA_OV_REM:2},
    {SH:"21-5200",SH_DESCR:"Closed Drain| LP Flare",RFDC:"21-5200-5210",RFDC_DESCR:"LP Flare KO Drum & Pumps",RFDC_PLAN:"2022-06-16",RFDC_STATUS:"Not Started",PUNCH_STATUS:"N",B_OV_TOT:35,B_OV_COM:0,B_OV_REM:35,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:1,PA_OV_REM:0},
    {SH:"21-5200",SH_DESCR:"Closed Drain| LP Flare",RFDC:"21-5200-5220",RFDC_DESCR:"LP Flare ignition, metering & Tip",RFDC_PLAN:"2022-06-09",RFDC_STATUS:"Not Started",PUNCH_STATUS:"N",B_OV_TOT:10,B_OV_COM:0,B_OV_REM:10,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0},
    {SH:"21-5700",SH_DESCR:"Vapour Recovery",RFDC:"21-5700-5710",RFDC_DESCR:"Flare Gas Vapour recovery skid",RFDC_PLAN:"2022-06-30",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:29,B_OV_COM:0,B_OV_REM:29,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:7,PA_OV_REM:4,PB1_OV_TOT:1,PB1_OV_REM:1},
    {SH:"21-5700",SH_DESCR:"Vapour Recovery",RFDC:"21-5700-5730",RFDC_DESCR:"Hull Tank Vapour Recovery skid",RFDC_PLAN:"2022-06-16",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:55,B_OV_COM:0,B_OV_REM:55,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:2,PA_OV_REM:2,PB1_OV_TOT:2,PB1_OV_REM:1},
    {SH:"21-6010",SH_DESCR:"Safety Instrumented Systems (SIS)",RFDC:"21-6010-6011",RFDC_DESCR:"SIS rooms cabinets, CAP panel, multicores & JBs",RFDC_PLAN:"2022-02-17",RFDC_STATUS:"In progress",PUNCH_STATUS:"B1",B_OV_TOT:138,B_OV_COM:86,B_OV_REM:52,B_OV_PROG:62.31,B_OV_STATUS:"less than 50%",B_PG:62.31,PA_OV_TOT:91,PA_OV_REM:0,PB1_OV_TOT:90,PB1_OV_REM:8},
    {SH:"21-6020",SH_DESCR:"Process Automation System (PAS)",RFDC:"21-6020-6021",RFDC_DESCR:"PAS rooms cabinets, multicores & JBs",RFDC_PLAN:"2022-02-10",RFDC_STATUS:"In progress",PUNCH_STATUS:"B1",B_OV_TOT:16,B_OV_COM:13,B_OV_REM:3,B_OV_PROG:81.25,B_OV_STATUS:"less than 50%",B_PG:81.25,PA_OV_TOT:64,PA_OV_REM:0,PB1_OV_TOT:84,PB1_OV_REM:6},
    {SH:"21-6030",SH_DESCR:"Integrated Control and Safety System (ICSS)",RFDC:"21-6030-6031",RFDC_DESCR:"Electrical data monitoring & constrol system (EDMCS) & power management system (PMS)",RFDC_PLAN:"2022-03-17",RFDC_STATUS:"In progress",PUNCH_STATUS:"A",B_OV_TOT:42,B_OV_COM:1,B_OV_REM:41,B_OV_PROG:2.38,B_OV_STATUS:"less than 50%",B_PG:2.38,PA_OV_TOT:17,PA_OV_REM:4,PB1_OV_TOT:17,PB1_OV_REM:7},
    {SH:"21-6030",SH_DESCR:"Integrated Control and Safety System (ICSS)",RFDC:"21-6030-6032",RFDC_DESCR:"ICSS Interface Servers",RFDC_PLAN:"2021-12-02",RFDC_STATUS:"In progress",PUNCH_STATUS:"A",B_OV_TOT:91,B_OV_COM:36,B_OV_REM:55,B_OV_PROG:39.56,B_OV_STATUS:"less than 50%",B_PG:39.56,PA_OV_TOT:53,PA_OV_REM:12,PB1_OV_TOT:272,PB1_OV_REM:134},
    {SH:"21-6030",SH_DESCR:"Integrated Control and Safety System (ICSS)",RFDC:"21-6030-6034",RFDC_DESCR:"Remote performance management system (RPM)",RFDC_PLAN:"2022-03-10",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:13,B_OV_COM:0,B_OV_REM:13,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:8,PA_OV_REM:8,PB1_OV_TOT:3,PB1_OV_REM:2},
    {SH:"21-6030",SH_DESCR:"Integrated Control and Safety System (ICSS)",RFDC:"21-6030-6035",RFDC_DESCR:"Condition & performance monitoring systems",RFDC_PLAN:"2022-04-14",RFDC_STATUS:"Not Started",PUNCH_STATUS:"N",B_OV_STATUS:"NA"},
    {SH:"21-6030",SH_DESCR:"Integrated Control and Safety System (ICSS)",RFDC:"21-6030-6036",RFDC_DESCR:"Instrumentation UPS distribution",RFDC_PLAN:"2021-12-09",RFDC_STATUS:"In progress",PUNCH_STATUS:"B1",B_OV_TOT:1952,B_OV_COM:463,B_OV_REM:1489,B_OV_PROG:23.71,B_OV_STATUS:"less than 50%",B_PG:23.71,PA_OV_TOT:68,PA_OV_REM:0,PB1_OV_TOT:181,PB1_OV_REM:73},
    {SH:"21-6030",SH_DESCR:"Integrated Control and Safety System (ICSS)",RFDC:"21-6030-6037",RFDC_DESCR:"Tank Gauging System cabinets & workstations",RFDC_PLAN:"2022-04-21",RFDC_STATUS:"In progress",PUNCH_STATUS:"B2",B_OV_TOT:12,B_OV_COM:5,B_OV_REM:7,B_OV_PROG:41.66,B_OV_STATUS:"less than 50%",B_PG:41.66,PA_OV_TOT:11,PA_OV_REM:0,PB1_OV_TOT:27,PB1_OV_REM:0},
    {SH:"21-6030",SH_DESCR:"Integrated Control and Safety System (ICSS)",RFDC:"21-6030-6038",RFDC_DESCR:"Cargo loading computer | workstation",RFDC_PLAN:"2022-05-26",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:5,B_OV_COM:0,B_OV_REM:5,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:3,PA_OV_REM:3,PB1_OV_TOT:2,PB1_OV_REM:0},
    {SH:"21-6035",SH_DESCR:"Process CCTV",RFDC:"21-6035-6035",RFDC_DESCR:"Process CCTV",RFDC_PLAN:"2022-05-12",RFDC_STATUS:"Not Started",PUNCH_STATUS:"N",B_OV_TOT:13,B_OV_COM:0,B_OV_REM:13,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PB1_OV_TOT:5,PB1_OV_REM:0},
    {SH:"21-6070",SH_DESCR:"Fire & Gas (FGS)",RFDC:"21-6070-6071",RFDC_DESCR:"FGS rooms cabinets, multicores & JBs",RFDC_PLAN:"2022-02-17",RFDC_STATUS:"In progress",PUNCH_STATUS:"B1",B_OV_TOT:14,B_OV_COM:8,B_OV_REM:6,B_OV_PROG:57.14,B_OV_STATUS:"less than 50%",B_PG:57.14,PA_OV_TOT:66,PA_OV_REM:0,PB1_OV_TOT:68,PB1_OV_REM:6},
    {SH:"21-6070",SH_DESCR:"Fire & Gas (FGS)",RFDC:"21-6070-6072",RFDC_DESCR:"Fire adressable panels",RFDC_PLAN:"2022-02-24",RFDC_STATUS:"In progress",PUNCH_STATUS:"A",B_OV_TOT:802,B_OV_COM:233,B_OV_REM:569,B_OV_PROG:29.05,B_OV_STATUS:"less than 50%",B_PG:29.05,PA_OV_TOT:135,PA_OV_REM:1,PB1_OV_TOT:323,PB1_OV_REM:12},
    {SH:"21-6070",SH_DESCR:"Fire & Gas (FGS)",RFDC:"21-6070-6073",RFDC_DESCR:"HSSD system",RFDC_PLAN:"2022-03-24",RFDC_STATUS:"In progress",PUNCH_STATUS:"A",B_OV_TOT:23,B_OV_COM:14,B_OV_REM:9,B_OV_PROG:60.86,B_OV_STATUS:"less than 50%",B_PG:60.86,PA_OV_TOT:85,PA_OV_REM:3,PB1_OV_TOT:105,PB1_OV_REM:2},
    {SH:"21-6100",SH_DESCR:"Fire Protection",RFDC:"21-6100-6110",RFDC_DESCR:"Fire water Ring Main, hydrant and Jockey pumps",RFDC_PLAN:"2022-05-05",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:26,B_OV_COM:0,B_OV_REM:26,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:8,PA_OV_REM:1,PB1_OV_TOT:15,PB1_OV_REM:1},
    {SH:"21-6100",SH_DESCR:"Fire Protection",RFDC:"21-6100-6111",RFDC_DESCR:"Fire water pumps",RFDC_PLAN:"2022-05-26",RFDC_STATUS:"In progress",PUNCH_STATUS:"A",B_OV_TOT:454,B_OV_COM:3,B_OV_REM:451,B_OV_PROG:0.66,B_OV_STATUS:"less than 50%",B_PG:0.66,PA_OV_TOT:6,PA_OV_REM:2,PB1_OV_TOT:13,PB1_OV_REM:6},
    {SH:"21-6100",SH_DESCR:"Fire Protection",RFDC:"21-6100-6121",RFDC_DESCR:"Water mist systems",RFDC_PLAN:"2022-03-03",RFDC_STATUS:"In progress",PUNCH_STATUS:"B2",B_OV_TOT:96,B_OV_COM:80,B_OV_REM:16,B_OV_PROG:83.33,B_OV_STATUS:"less than 50%",B_PG:83.33,PA_OV_TOT:36,PA_OV_REM:0,PB1_OV_TOT:17,PB1_OV_REM:0},
    {SH:"21-6100",SH_DESCR:"Fire Protection",RFDC:"21-6100-6140",RFDC_DESCR:"Foam Systems",RFDC_PLAN:"2022-06-09",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:30,B_OV_COM:0,B_OV_REM:30,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:17,PA_OV_REM:2,PB1_OV_TOT:11,PB1_OV_REM:0},
    {SH:"21-6100",SH_DESCR:"Fire Protection",RFDC:"21-6100-6150",RFDC_DESCR:"Deluge Systems",RFDC_PLAN:"2022-06-16",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:140,B_OV_COM:0,B_OV_REM:140,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:20,PA_OV_REM:8,PB1_OV_TOT:38,PB1_OV_REM:38},
    {SH:"21-6200",SH_DESCR:"Operations Critical Telecom",RFDC:"21-6200-6210",RFDC_DESCR:"Public Address and General Alarm System",RFDC_PLAN:"2022-02-24",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:18,B_OV_COM:0,B_OV_REM:18,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:384,PA_OV_REM:64,PB1_OV_TOT:273,PB1_OV_REM:39},
    {SH:"21-6200",SH_DESCR:"Operations Critical Telecom",RFDC:"21-6200-6224",RFDC_DESCR:"Digital UHF Network",RFDC_PLAN:"2022-04-28",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:14,B_OV_COM:0,B_OV_REM:14,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:10,PA_OV_REM:5,PB1_OV_TOT:4,PB1_OV_REM:2},
    {SH:"21-6200",SH_DESCR:"Operations Critical Telecom",RFDC:"21-6200-6225",RFDC_DESCR:"VHF (AM) Aeronautical Radio",RFDC_PLAN:"2022-07-14",RFDC_STATUS:"Not Started",PUNCH_STATUS:"B2",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PB1_OV_TOT:2,PB1_OV_REM:0},
    {SH:"21-6200",SH_DESCR:"Operations Critical Telecom",RFDC:"21-6200-6227",RFDC_DESCR:"Crane Radio System",RFDC_PLAN:"2022-08-04",RFDC_STATUS:"Not Started",PUNCH_STATUS:"N",B_OV_STATUS:"NA"},
    {SH:"21-6200",SH_DESCR:"Operations Critical Telecom",RFDC:"21-6200-6228",RFDC_DESCR:"VHF (FM) Marine Radio",RFDC_PLAN:"2022-07-07",RFDC_STATUS:"Not Started",PUNCH_STATUS:"N",B_OV_STATUS:"NA"},
    {SH:"21-6300",SH_DESCR:"Personnel Protection Evacuation",RFDC:"21-6300-6320",RFDC_DESCR:"Lifeboats",RFDC_PLAN:"2022-06-09",RFDC_STATUS:"Not Started",PUNCH_STATUS:"N",B_OV_TOT:15,B_OV_COM:0,B_OV_REM:15,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:2,PA_OV_REM:0,PB1_OV_TOT:4,PB1_OV_REM:0},
    {SH:"21-6400",SH_DESCR:"Personnel Safety",RFDC:"21-6400-6450",RFDC_DESCR:"Life saving portable equipment",RFDC_PLAN:"2022-08-11",RFDC_STATUS:"Not Started",PUNCH_STATUS:"N",B_OV_TOT:3,B_OV_COM:0,B_OV_REM:3,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0},
    {SH:"21-6500",SH_DESCR:"Asset protection",RFDC:"21-6500-6510",RFDC_DESCR:"Navigation lights control panel and associated lanterns |foghorns circuits",RFDC_PLAN:"2022-06-02",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:9,B_OV_COM:0,B_OV_REM:9,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:11,PA_OV_REM:2},
    {SH:"21-6500",SH_DESCR:"Asset protection",RFDC:"21-6500-6520",RFDC_DESCR:"Helideck lights control panel and associated beacons",RFDC_PLAN:"2022-05-26",RFDC_STATUS:"Not Started",PUNCH_STATUS:"B2",B_OV_TOT:16,B_OV_COM:0,B_OV_REM:16,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:19,PA_OV_REM:0},
    {SH:"21-6500",SH_DESCR:"Asset protection",RFDC:"21-6500-6550",RFDC_DESCR:"Radar Early Warning System",RFDC_PLAN:"2022-05-05",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:8,B_OV_COM:0,B_OV_REM:8,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:2,PA_OV_REM:1,PB1_OV_TOT:2,PB1_OV_REM:0},
    {SH:"21-6600",SH_DESCR:"Emergency & Essential Power",RFDC:"21-6600-6610",RFDC_DESCR:"Emergency power generation",RFDC_PLAN:"2022-04-21",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:186,B_OV_COM:0,B_OV_REM:186,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:38,PA_OV_REM:13,PB1_OV_TOT:43,PB1_OV_REM:14},
    {SH:"21-6600",SH_DESCR:"Emergency & Essential Power",RFDC:"21-6600-6624",RFDC_DESCR:"Emergency Lighting and Small Power",RFDC_PLAN:"2022-04-07",RFDC_STATUS:"In progress",PUNCH_STATUS:"A",B_OV_TOT:148,B_OV_COM:44,B_OV_REM:104,B_OV_PROG:29.72,B_OV_STATUS:"less than 50%",B_PG:29.72,PA_OV_TOT:47,PA_OV_REM:1,PB1_OV_TOT:104,PB1_OV_REM:4},
    {SH:"21-6600",SH_DESCR:"Emergency & Essential Power",RFDC:"21-6600-6640",RFDC_DESCR:"Emergency Power Distribution400|440 Vac",RFDC_PLAN:"2022-02-17",RFDC_STATUS:"In progress",PUNCH_STATUS:"A",B_OV_TOT:166,B_OV_COM:71,B_OV_REM:95,B_OV_PROG:42.77,B_OV_STATUS:"less than 50%",B_PG:42.77,PA_OV_TOT:80,PA_OV_REM:36,PB1_OV_TOT:49,PB1_OV_REM:20},
    {SH:"21-6600",SH_DESCR:"Emergency & Essential Power",RFDC:"21-6600-6670",RFDC_DESCR:"Essential Power Generation",RFDC_PLAN:"2022-03-17",RFDC_STATUS:"In progress",PUNCH_STATUS:"B1",B_OV_TOT:198,B_OV_COM:192,B_OV_REM:6,B_OV_PROG:96.96,B_OV_STATUS:"less than 50%",B_PG:96.96,PA_OV_TOT:113,PA_OV_REM:0,PB1_OV_TOT:152,PB1_OV_REM:2},
    {SH:"21-6600",SH_DESCR:"Emergency & Essential Power",RFDC:"21-6600-6673",RFDC_DESCR:"Essential Power Distribution 6.6kV",RFDC_PLAN:"2021-11-25",RFDC_STATUS:"In progress",PUNCH_STATUS:"A",B_OV_TOT:117,B_OV_COM:61,B_OV_REM:56,B_OV_PROG:52.13,B_OV_STATUS:"less than 50%",B_PG:52.13,PA_OV_TOT:22,PA_OV_REM:2,PB1_OV_TOT:36,PB1_OV_REM:1},
    {SH:"21-6600",SH_DESCR:"Emergency & Essential Power",RFDC:"21-6600-6675",RFDC_DESCR:"Essential Power Distribution 400|440Vac",RFDC_PLAN:"2021-10-21",RFDC_STATUS:"In progress",PUNCH_STATUS:"A",B_OV_TOT:373,B_OV_COM:81,B_OV_REM:292,B_OV_PROG:21.71,B_OV_STATUS:"less than 50%",B_PG:21.71,PA_OV_TOT:51,PA_OV_REM:22,PB1_OV_TOT:59,PB1_OV_REM:40},
    {SH:"21-6600",SH_DESCR:"Emergency & Essential Power",RFDC:"21-6600-6676",RFDC_DESCR:"Normal & Essential Lighting and Small Power",RFDC_PLAN:"2021-12-16",RFDC_STATUS:"In progress",PUNCH_STATUS:"B1",B_OV_TOT:685,B_OV_COM:337,B_OV_REM:348,B_OV_PROG:49.19,B_OV_STATUS:"less than 50%",B_PG:49.19,PA_OV_TOT:139,PA_OV_REM:0,PB1_OV_TOT:184,PB1_OV_REM:8},
    {SH:"21-6700",SH_DESCR:"UPS | Battery power",RFDC:"21-6700-6701",RFDC_DESCR:"LQ UPSs, batteries & subdistribution boards",RFDC_PLAN:"2021-11-11",RFDC_STATUS:"In progress",PUNCH_STATUS:"B1",B_OV_TOT:36,B_OV_COM:7,B_OV_REM:29,B_OV_PROG:19.44,B_OV_STATUS:"less than 50%",B_PG:19.44,PA_OV_TOT:49,PA_OV_REM:0,PB1_OV_TOT:104,PB1_OV_REM:4},
    {SH:"21-6700",SH_DESCR:"UPS | Battery power",RFDC:"21-6700-6702",RFDC_DESCR:"Topsides UPSs, batteries, voltage stabilisers & subdistribution boards",RFDC_PLAN:"2022-02-17",RFDC_STATUS:"Not Started",PUNCH_STATUS:"B1",B_OV_TOT:47,B_OV_COM:0,B_OV_REM:47,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:9,PA_OV_REM:0,PB1_OV_TOT:82,PB1_OV_REM:20},
    {SH:"21-8000",SH_DESCR:"Potable & service water",RFDC:"21-8000-8010",RFDC_DESCR:"Potable water generation",RFDC_PLAN:"2022-06-09",RFDC_STATUS:"In progress",PUNCH_STATUS:"A",B_OV_TOT:110,B_OV_COM:4,B_OV_REM:106,B_OV_PROG:3.63,B_OV_STATUS:"less than 50%",B_PG:3.63,PA_OV_TOT:45,PA_OV_REM:4,PB1_OV_TOT:34,PB1_OV_REM:11},
    {SH:"21-8000",SH_DESCR:"Potable & service water",RFDC:"21-8000-8011",RFDC_DESCR:"Potable water storage, loading & pumps",RFDC_PLAN:"2022-06-09",RFDC_STATUS:"In progress",PUNCH_STATUS:"B1",B_OV_TOT:31,B_OV_COM:4,B_OV_REM:27,B_OV_PROG:12.9,B_OV_STATUS:"less than 50%",B_PG:12.9,PA_OV_TOT:25,PA_OV_REM:0,PB1_OV_TOT:39,PB1_OV_REM:8},
    {SH:"21-8000",SH_DESCR:"Potable & service water",RFDC:"21-8000-8012",RFDC_DESCR:"Main & Cold potable water distribution",RFDC_PLAN:"2022-06-23",RFDC_STATUS:"In progress",PUNCH_STATUS:"B1",B_OV_TOT:36,B_OV_COM:15,B_OV_REM:21,B_OV_PROG:41.66,B_OV_STATUS:"less than 50%",B_PG:41.66,PA_OV_TOT:23,PA_OV_REM:0,PB1_OV_TOT:50,PB1_OV_REM:23},
    {SH:"21-8000",SH_DESCR:"Potable & service water",RFDC:"21-8000-8013",RFDC_DESCR:"Hot water generation & distribution",RFDC_PLAN:"2022-06-23",RFDC_STATUS:"In progress",PUNCH_STATUS:"A",B_OV_TOT:42,B_OV_COM:2,B_OV_REM:40,B_OV_PROG:4.76,B_OV_STATUS:"less than 50%",B_PG:4.76,PA_OV_TOT:36,PA_OV_REM:13,PB1_OV_TOT:23,PB1_OV_REM:15},
    {SH:"21-8000",SH_DESCR:"Potable & service water",RFDC:"21-8000-8014",RFDC_DESCR:"Service water storage, pumps & distribution",RFDC_PLAN:"2022-04-14",RFDC_STATUS:"In progress",PUNCH_STATUS:"B1",B_OV_TOT:36,B_OV_COM:23,B_OV_REM:13,B_OV_PROG:63.88,B_OV_STATUS:"less than 50%",B_PG:63.88,PA_OV_TOT:30,PA_OV_REM:0,PB1_OV_TOT:46,PB1_OV_REM:2},
    {SH:"21-8020",SH_DESCR:"Marine Seawater system",RFDC:"21-8020-8021",RFDC_DESCR:"IGG Scrubber Cooling Seawater",RFDC_PLAN:"2022-05-12",RFDC_STATUS:"Not Started",PUNCH_STATUS:"B1",B_OV_TOT:27,B_OV_COM:0,B_OV_REM:27,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:7,PA_OV_REM:0,PB1_OV_TOT:27,PB1_OV_REM:7},
    {SH:"21-8020",SH_DESCR:"Marine Seawater system",RFDC:"21-8020-8023",RFDC_DESCR:"Deck Seal Water Pumps and Distribution",RFDC_PLAN:"2022-05-26",RFDC_STATUS:"In progress",PUNCH_STATUS:"B1",B_OV_TOT:26,B_OV_COM:10,B_OV_REM:16,B_OV_PROG:38.46,B_OV_STATUS:"less than 50%",B_PG:38.46,PA_OV_TOT:2,PA_OV_REM:0,PB1_OV_TOT:27,PB1_OV_REM:5},
    {SH:"21-8020",SH_DESCR:"Marine Seawater system",RFDC:"21-8020-8024",RFDC_DESCR:"Hull Seawater Cooling, Pumps, Header and Exchangers",RFDC_PLAN:"2022-03-17",RFDC_STATUS:"In progress",PUNCH_STATUS:"B1",B_OV_TOT:39,B_OV_COM:32,B_OV_REM:7,B_OV_PROG:82.05,B_OV_STATUS:"less than 50%",B_PG:82.05,PA_OV_TOT:15,PA_OV_REM:0,PB1_OV_TOT:56,PB1_OV_REM:1},
    {SH:"21-8020",SH_DESCR:"Marine Seawater system",RFDC:"21-8020-8025",RFDC_DESCR:"Hull Service Seawater Pumps",RFDC_PLAN:"2022-04-07",RFDC_STATUS:"In progress",PUNCH_STATUS:"B1",B_OV_TOT:28,B_OV_COM:11,B_OV_REM:17,B_OV_PROG:39.28,B_OV_STATUS:"less than 50%",B_PG:39.28,PA_OV_TOT:17,PA_OV_REM:0,PB1_OV_TOT:47,PB1_OV_REM:13},
    {SH:"21-8020",SH_DESCR:"Marine Seawater system",RFDC:"21-8020-8027",RFDC_DESCR:"Hull Seawater Booster Pumps & distribution",RFDC_PLAN:"2022-03-24",RFDC_STATUS:"In progress",PUNCH_STATUS:"B1",B_OV_TOT:30,B_OV_COM:2,B_OV_REM:28,B_OV_PROG:6.66,B_OV_STATUS:"less than 50%",B_PG:6.66,PA_OV_TOT:24,PA_OV_REM:0,PB1_OV_TOT:43,PB1_OV_REM:1},
    {SH:"21-8020",SH_DESCR:"Marine Seawater system",RFDC:"21-8020-8029",RFDC_DESCR:"Sea water Cooling to KSGs",RFDC_PLAN:"2022-03-10",RFDC_STATUS:"In progress",PUNCH_STATUS:"B2",B_OV_TOT:26,B_OV_COM:24,B_OV_REM:2,B_OV_PROG:92.3,B_OV_STATUS:"less than 50%",B_PG:92.3,PA_OV_TOT:10,PA_OV_REM:0,PB1_OV_TOT:46,PB1_OV_REM:0},
    {SH:"21-8040",SH_DESCR:"Heating Medium (hot water)",RFDC:"21-8040-8040",RFDC_DESCR:"Heating Medium (hot water)",RFDC_PLAN:"2022-04-07",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:122,B_OV_COM:0,B_OV_REM:122,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:3,PA_OV_REM:3,PB1_OV_TOT:1,PB1_OV_REM:1},
    {SH:"21-8050",SH_DESCR:"Cooling Medium",RFDC:"21-8050-8051",RFDC_DESCR:"Marine cooling medium",RFDC_PLAN:"2022-03-17",RFDC_STATUS:"In progress",PUNCH_STATUS:"B2",B_OV_TOT:35,B_OV_COM:29,B_OV_REM:6,B_OV_PROG:82.85,B_OV_STATUS:"less than 50%",B_PG:82.85,PA_OV_TOT:29,PA_OV_REM:0,PB1_OV_TOT:55,PB1_OV_REM:0},
    {SH:"21-8050",SH_DESCR:"Cooling Medium",RFDC:"21-8050-8052",RFDC_DESCR:"Topsides cooling medium",RFDC_PLAN:"2022-03-31",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:126,B_OV_COM:0,B_OV_REM:126,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:14,PA_OV_REM:8,PB1_OV_TOT:5,PB1_OV_REM:4},
    {SH:"21-8060",SH_DESCR:"Diesel",RFDC:"21-8060-8061",RFDC_DESCR:"Diesel bunker, storage & pumps",RFDC_PLAN:"2022-03-10",RFDC_STATUS:"In progress",PUNCH_STATUS:"B2",B_OV_TOT:41,B_OV_COM:32,B_OV_REM:9,B_OV_PROG:78.04,B_OV_STATUS:"less than 50%",B_PG:78.04,PA_OV_TOT:30,PA_OV_REM:0,PB1_OV_TOT:67,PB1_OV_REM:0},
    {SH:"21-8060",SH_DESCR:"Diesel",RFDC:"21-8060-8062",RFDC_DESCR:"Diesel purifier, service tanks & pumps",RFDC_PLAN:"2022-02-24",RFDC_STATUS:"In progress",PUNCH_STATUS:"A",B_OV_TOT:60,B_OV_COM:51,B_OV_REM:9,B_OV_PROG:85,B_OV_STATUS:"less than 50%",B_PG:85,PA_OV_TOT:46,PA_OV_REM:1,PB1_OV_TOT:94,PB1_OV_REM:0},
    {SH:"21-8090",SH_DESCR:"Drains & Sewage",RFDC:"21-8090-8090",RFDC_DESCR:"Sewage system equipment & headers",RFDC_PLAN:"2022-05-05",RFDC_STATUS:"In progress",PUNCH_STATUS:"A",B_OV_TOT:80,B_OV_COM:1,B_OV_REM:79,B_OV_PROG:1.25,B_OV_STATUS:"less than 50%",B_PG:1.25,PA_OV_TOT:22,PA_OV_REM:7,PB1_OV_TOT:8,PB1_OV_REM:7},
    {SH:"21-8100",SH_DESCR:"Gaseous Utility Fluids",RFDC:"21-8100-8120",RFDC_DESCR:"Instrument Air production Hull",RFDC_PLAN:"2022-05-05",RFDC_STATUS:"In progress",PUNCH_STATUS:"B1",B_OV_TOT:79,B_OV_COM:35,B_OV_REM:44,B_OV_PROG:44.3,B_OV_STATUS:"less than 50%",B_PG:44.3,PA_OV_TOT:27,PA_OV_REM:0,PB1_OV_TOT:57,PB1_OV_REM:5},
    {SH:"21-8100",SH_DESCR:"Gaseous Utility Fluids",RFDC:"21-8100-8122",RFDC_DESCR:"Instrument Air production Topsides",RFDC_PLAN:"2022-03-31",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:235,B_OV_COM:0,B_OV_REM:235,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:8,PA_OV_REM:7,PB1_OV_TOT:17,PB1_OV_REM:15},
    {SH:"21-8130",SH_DESCR:"Fuel Gas",RFDC:"21-8130-8130",RFDC_DESCR:"Fuel gas",RFDC_PLAN:"2022-05-19",RFDC_STATUS:"Not Started",PUNCH_STATUS:"B2",B_OV_TOT:44,B_OV_COM:0,B_OV_REM:44,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0},
    {SH:"21-8150",SH_DESCR:"Nitrogen",RFDC:"21-8150-8150",RFDC_DESCR:"Nitrogen",RFDC_PLAN:"2022-05-26",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:48,B_OV_COM:0,B_OV_REM:48,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:10,PA_OV_REM:8,PB1_OV_TOT:1,PB1_OV_REM:0},
    {SH:"21-8160",SH_DESCR:"Inert Gas Package",RFDC:"21-8160-8160",RFDC_DESCR:"Inert gas packge",RFDC_PLAN:"2022-06-30",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:107,B_OV_COM:0,B_OV_REM:107,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:16,PA_OV_REM:15,PB1_OV_TOT:5,PB1_OV_REM:4},
    {SH:"21-8300",SH_DESCR:"Chemicals _ Production Fluids",RFDC:"21-8300-8310",RFDC_DESCR:"Corrosion inhibitor package & distribution",RFDC_PLAN:"2022-06-09",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:39,B_OV_COM:0,B_OV_REM:39,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:11,PA_OV_REM:6,PB1_OV_TOT:5,PB1_OV_REM:1},
    {SH:"21-8300",SH_DESCR:"Chemicals _ Production Fluids",RFDC:"21-8300-8320",RFDC_DESCR:"Scale inhibitor package & distribution",RFDC_PLAN:"2022-06-09",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:78,B_OV_COM:0,B_OV_REM:78,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:10,PA_OV_REM:9},
    {SH:"21-8300",SH_DESCR:"Chemicals _ Production Fluids",RFDC:"21-8300-8330",RFDC_DESCR:"Wax inhibitor storage, pumps loading a main header",RFDC_PLAN:"2022-06-30",RFDC_STATUS:"In progress",PUNCH_STATUS:"B2",B_OV_TOT:29,B_OV_COM:2,B_OV_REM:27,B_OV_PROG:6.89,B_OV_STATUS:"less than 50%",B_PG:6.89,PB1_OV_TOT:8,PB1_OV_REM:0},
    {SH:"21-8300",SH_DESCR:"Chemicals _ Production Fluids",RFDC:"21-8300-8331",RFDC_DESCR:"Wax inhibitor filtration, injection pumps and spill back header",RFDC_PLAN:"2022-05-26",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:51,B_OV_COM:0,B_OV_REM:51,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:4,PA_OV_REM:2},
    {SH:"21-8300",SH_DESCR:"Chemicals _ Production Fluids",RFDC:"21-8300-8332",RFDC_DESCR:"Wax inhibitor injection subsea",RFDC_PLAN:"2022-06-09",RFDC_STATUS:"Not Started",PUNCH_STATUS:"N",B_OV_TOT:3,B_OV_COM:0,B_OV_REM:3,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0},
    {SH:"21-8300",SH_DESCR:"Chemicals _ Production Fluids",RFDC:"21-8300-8340",RFDC_DESCR:"Biocide package & distribution",RFDC_PLAN:"2022-06-09",RFDC_STATUS:"Not Started",PUNCH_STATUS:"B1",B_OV_TOT:33,B_OV_COM:0,B_OV_REM:33,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:14,PA_OV_REM:0,PB1_OV_TOT:1,PB1_OV_REM:1},
    {SH:"21-8300",SH_DESCR:"Chemicals _ Production Fluids",RFDC:"21-8300-8350",RFDC_DESCR:"Antifoam package & distribution",RFDC_PLAN:"2022-06-16",RFDC_STATUS:"Not Started",PUNCH_STATUS:"N",B_OV_TOT:35,B_OV_COM:0,B_OV_REM:35,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0},
    {SH:"21-8300",SH_DESCR:"Chemicals _ Production Fluids",RFDC:"21-8300-8360",RFDC_DESCR:"Emulsion breaker package & distribution",RFDC_PLAN:"2022-05-19",RFDC_STATUS:"Not Started",PUNCH_STATUS:"B2",B_OV_TOT:36,B_OV_COM:0,B_OV_REM:36,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0},
    {SH:"21-8300",SH_DESCR:"Chemicals _ Production Fluids",RFDC:"21-8300-8370",RFDC_DESCR:"MEG PH stabilizer",RFDC_PLAN:"2022-06-16",RFDC_STATUS:"Not Started",PUNCH_STATUS:"B2",B_OV_TOT:31,B_OV_COM:0,B_OV_REM:31,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0},
    {SH:"21-8300",SH_DESCR:"Chemicals _ Production Fluids",RFDC:"21-8300-8375",RFDC_DESCR:"MEG Antifoam",RFDC_PLAN:"2022-04-14",RFDC_STATUS:"Not Started",PUNCH_STATUS:"B2",B_OV_TOT:31,B_OV_COM:0,B_OV_REM:31,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0},
    {SH:"21-8400",SH_DESCR:"Chemicals - Water Treatment",RFDC:"21-8400-8410",RFDC_DESCR:"Oxygen scavenger package & distribution",RFDC_PLAN:"2022-05-19",RFDC_STATUS:"Not Started",PUNCH_STATUS:"B2",B_OV_TOT:31,B_OV_COM:0,B_OV_REM:31,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0},
    {SH:"21-8600",SH_DESCR:"Main 6,6kV Power generation",RFDC:"21-8600-8611",RFDC_DESCR:"Main Power Generation 1",RFDC_PLAN:"2022-06-09",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:251,B_OV_COM:0,B_OV_REM:251,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:9,PA_OV_REM:6,PB1_OV_TOT:12,PB1_OV_REM:10},
    {SH:"21-8600",SH_DESCR:"Main 6,6kV Power generation",RFDC:"21-8600-8612",RFDC_DESCR:"Main Power Generation 2",RFDC_PLAN:"2022-06-16",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:250,B_OV_COM:0,B_OV_REM:250,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:7,PA_OV_REM:6,PB1_OV_TOT:2,PB1_OV_REM:0},
    {SH:"21-8600",SH_DESCR:"Main 6,6kV Power generation",RFDC:"21-8600-8613",RFDC_DESCR:"Main Power Generation 3",RFDC_PLAN:"2022-06-23",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:248,B_OV_COM:0,B_OV_REM:248,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:6,PA_OV_REM:5,PB1_OV_TOT:2,PB1_OV_REM:0},
    {SH:"21-8600",SH_DESCR:"Main 6,6kV Power generation",RFDC:"21-8600-8614",RFDC_DESCR:"Main Power Generation 4",RFDC_PLAN:"2022-06-30",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:248,B_OV_COM:0,B_OV_REM:248,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:7,PA_OV_REM:6,PB1_OV_TOT:2,PB1_OV_REM:0},
    {SH:"21-8700",SH_DESCR:"Main Power distribution",RFDC:"21-8700-8770",RFDC_DESCR:"Normal Power Distribution 6.6 kV",RFDC_PLAN:"2022-03-03",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:72,B_OV_COM:0,B_OV_REM:72,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:13,PA_OV_REM:3,PB1_OV_TOT:12,PB1_OV_REM:10},
    {SH:"21-8800",SH_DESCR:"Topside Seawater system",RFDC:"21-8800-8800",RFDC_DESCR:"Sea water system (Topsides)",RFDC_PLAN:"2022-07-14",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:84,B_OV_COM:0,B_OV_REM:84,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:3,PA_OV_REM:2,PB1_OV_TOT:5,PB1_OV_REM:5},
    {SH:"21-8800",SH_DESCR:"Topside Seawater system",RFDC:"21-8800-8805",RFDC_DESCR:"Electrochlorination system",RFDC_PLAN:"2022-06-02",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:100,B_OV_COM:0,B_OV_REM:100,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:7,PA_OV_REM:3,PB1_OV_TOT:26,PB1_OV_REM:8},
    {SH:"21-9220",SH_DESCR:"Main Accommodation",RFDC:"21-9220-9224",RFDC_DESCR:"Entertainment System",RFDC_PLAN:"2022-06-02",RFDC_STATUS:"Not Started",PUNCH_STATUS:"B1",B_OV_TOT:4,B_OV_COM:0,B_OV_REM:4,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PB1_OV_TOT:5,PB1_OV_REM:2},
    {SH:"21-9330",SH_DESCR:"Main Ballast System",RFDC:"21-9330-9330",RFDC_DESCR:"Main Ballast System",RFDC_PLAN:"2022-05-05",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:122,B_OV_COM:0,B_OV_REM:122,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:1,PA_OV_REM:1,PB1_OV_TOT:1,PB1_OV_REM:1},
    {SH:"21-9330",SH_DESCR:"Main Ballast System",RFDC:"21-9330-9333",RFDC_DESCR:"Cargo, Ballast HPU System & UCP",RFDC_PLAN:"2022-03-24",RFDC_STATUS:"In progress",PUNCH_STATUS:"B2",B_OV_TOT:249,B_OV_COM:188,B_OV_REM:61,B_OV_PROG:75.5,B_OV_STATUS:"less than 50%",B_PG:75.5,PA_OV_TOT:67,PA_OV_REM:0,PB1_OV_TOT:102,PB1_OV_REM:0},
    {SH:"21-9330",SH_DESCR:"Main Ballast System",RFDC:"21-9330-9334",RFDC_DESCR:"Marine valves HPU System",RFDC_PLAN:"2022-02-24",RFDC_STATUS:"In progress",PUNCH_STATUS:"B1",B_OV_TOT:49,B_OV_COM:41,B_OV_REM:8,B_OV_PROG:83.67,B_OV_STATUS:"less than 50%",B_PG:83.67,PA_OV_TOT:39,PA_OV_REM:0,PB1_OV_TOT:39,PB1_OV_REM:1},
    {SH:"21-9340",SH_DESCR:"Mooring System",RFDC:"21-9340-9341",RFDC_DESCR:"Forward mooring system",RFDC_PLAN:"2022-06-23",RFDC_STATUS:"In progress",PUNCH_STATUS:"A",B_OV_TOT:151,B_OV_COM:8,B_OV_REM:143,B_OV_PROG:5.29,B_OV_STATUS:"less than 50%",B_PG:5.29,PA_OV_TOT:4,PA_OV_REM:1,PB1_OV_TOT:6,PB1_OV_REM:0},
    {SH:"21-9340",SH_DESCR:"Mooring System",RFDC:"21-9340-9343",RFDC_DESCR:"Aft mooring system",RFDC_PLAN:"2021-05-27",RFDC_STATUS:"In progress",PUNCH_STATUS:"A",B_OV_TOT:146,B_OV_COM:8,B_OV_REM:138,B_OV_PROG:5.47,B_OV_STATUS:"less than 50%",B_PG:5.47,PA_OV_TOT:6,PA_OV_REM:1,PB1_OV_TOT:6,PB1_OV_REM:1},
    {SH:"21-9350",SH_DESCR:"Ballast System Marine",RFDC:"21-9350-9350",RFDC_DESCR:"Ballast System Marine",RFDC_PLAN:"2022-03-03",RFDC_STATUS:"In progress",PUNCH_STATUS:"B1",B_OV_TOT:74,B_OV_COM:47,B_OV_REM:27,B_OV_PROG:63.51,B_OV_STATUS:"less than 50%",B_PG:63.51,PA_OV_TOT:30,PA_OV_REM:0,PB1_OV_TOT:131,PB1_OV_REM:2},
    {SH:"21-9370",SH_DESCR:"Slop Oil System",RFDC:"21-9370-9370",RFDC_DESCR:"Slop Tank T-937001 and Pumps",RFDC_PLAN:"2022-05-12",RFDC_STATUS:"Not Started",PUNCH_STATUS:"B2",B_OV_TOT:38,B_OV_COM:0,B_OV_REM:38,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:1,PA_OV_REM:0},
    {SH:"21-9370",SH_DESCR:"Slop Oil System",RFDC:"21-9370-9371",RFDC_DESCR:"Slop Tank T-937002 and Pumps",RFDC_PLAN:"2022-05-12",RFDC_STATUS:"Not Started",PUNCH_STATUS:"B2",B_OV_TOT:37,B_OV_COM:0,B_OV_REM:37,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0},
    {SH:"21-9380",SH_DESCR:"Bilge System",RFDC:"21-9380-9380",RFDC_DESCR:"Daily Bilge System",RFDC_PLAN:"2022-03-10",RFDC_STATUS:"In progress",PUNCH_STATUS:"A",B_OV_TOT:74,B_OV_COM:24,B_OV_REM:50,B_OV_PROG:32.43,B_OV_STATUS:"less than 50%",B_PG:32.43,PA_OV_TOT:29,PA_OV_REM:1,PB1_OV_TOT:30,PB1_OV_REM:4},
    {SH:"21-9380",SH_DESCR:"Bilge System",RFDC:"21-9380-9381",RFDC_DESCR:"Main Bilge System",RFDC_PLAN:"2022-05-19",RFDC_STATUS:"In progress",PUNCH_STATUS:"A",B_OV_TOT:28,B_OV_COM:6,B_OV_REM:22,B_OV_PROG:21.42,B_OV_STATUS:"less than 50%",B_PG:21.42,PA_OV_TOT:13,PA_OV_REM:4,PB1_OV_TOT:15,PB1_OV_REM:6},
    {SH:"21-9390",SH_DESCR:"Bunkering Station",RFDC:"21-9390-9390",RFDC_DESCR:"Bunkering Station",RFDC_PLAN:"2022-06-30",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:17,B_OV_COM:0,B_OV_REM:17,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:4,PA_OV_REM:2,PB1_OV_TOT:1,PB1_OV_REM:0},
    {SH:"21-9500",SH_DESCR:"Telecom infrastructures",RFDC:"21-9500-9514",RFDC_DESCR:"Telephones and Intercoms",RFDC_PLAN:"2022-04-28",RFDC_STATUS:"Not Started",PUNCH_STATUS:"B2",B_OV_TOT:5,B_OV_COM:0,B_OV_REM:5,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:1,PA_OV_REM:0,PB1_OV_TOT:9,PB1_OV_REM:0},
    {SH:"21-9500",SH_DESCR:"Telecom infrastructures",RFDC:"21-9500-9525",RFDC_DESCR:"Microwave LOS",RFDC_PLAN:"2022-06-23",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:4,B_OV_COM:0,B_OV_REM:4,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:1,PA_OV_REM:1,PB1_OV_TOT:3,PB1_OV_REM:0},
    {SH:"21-9500",SH_DESCR:"Telecom infrastructures",RFDC:"21-9500-9527",RFDC_DESCR:"Inmarsat",RFDC_PLAN:"2022-07-07",RFDC_STATUS:"Not Started",PUNCH_STATUS:"B2",B_OV_STATUS:"NA"},
    {SH:"21-9500",SH_DESCR:"Telecom infrastructures",RFDC:"21-9500-9536",RFDC_DESCR:"LAN | WAN",RFDC_PLAN:"2022-03-10",RFDC_STATUS:"Not Started",PUNCH_STATUS:"B1",B_OV_TOT:44,B_OV_COM:0,B_OV_REM:44,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:26,PA_OV_REM:0,PB1_OV_TOT:24,PB1_OV_REM:3},
    {SH:"21-9500",SH_DESCR:"Telecom infrastructures",RFDC:"21-9500-9539",RFDC_DESCR:"Video Conferencing",RFDC_PLAN:"2022-07-07",RFDC_STATUS:"Not Started",PUNCH_STATUS:"N",B_OV_TOT:2,B_OV_COM:0,B_OV_REM:2,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0},
    {SH:"21-9500",SH_DESCR:"Telecom infrastructures",RFDC:"21-9500-9544",RFDC_DESCR:"Marine and Security CCTV",RFDC_PLAN:"2022-05-05",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:10,B_OV_COM:0,B_OV_REM:10,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:3,PA_OV_REM:1,PB1_OV_TOT:11,PB1_OV_REM:0},
    {SH:"21-9500",SH_DESCR:"Telecom infrastructures",RFDC:"21-9500-9554",RFDC_DESCR:"Meteoroligical and Metocean System",RFDC_PLAN:"2022-05-26",RFDC_STATUS:"Not Started",PUNCH_STATUS:"B2",B_OV_TOT:7,B_OV_COM:0,B_OV_REM:7,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PB1_OV_TOT:2,PB1_OV_REM:0},
    {SH:"21-9500",SH_DESCR:"Telecom infrastructures",RFDC:"21-9500-9555",RFDC_DESCR:"Berthing Aid Approach System",RFDC_PLAN:"2022-06-16",RFDC_STATUS:"Not Started",PUNCH_STATUS:"B1",B_OV_TOT:5,B_OV_COM:0,B_OV_REM:5,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PB1_OV_TOT:3,PB1_OV_REM:1},
    {SH:"21-9500",SH_DESCR:"Telecom infrastructures",RFDC:"21-9500-9557",RFDC_DESCR:"GMDSS",RFDC_PLAN:"2022-06-30",RFDC_STATUS:"Not Started",PUNCH_STATUS:"N",B_OV_TOT:4,B_OV_COM:0,B_OV_REM:4,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PB1_OV_TOT:3,PB1_OV_REM:0},
    {SH:"21-9500",SH_DESCR:"Telecom infrastructures",RFDC:"21-9500-9564",RFDC_DESCR:"Access Control System",RFDC_PLAN:"2022-05-05",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:13,B_OV_COM:0,B_OV_REM:13,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:36,PA_OV_REM:3,PB1_OV_TOT:36,PB1_OV_REM:1},
    {SH:"21-9500",SH_DESCR:"Telecom infrastructures",RFDC:"21-9500-9565",RFDC_DESCR:"Ship security alert",RFDC_PLAN:"2022-07-07",RFDC_STATUS:"Not Started",PUNCH_STATUS:"N",B_OV_TOT:3,B_OV_COM:0,B_OV_REM:3,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:1,PA_OV_REM:0,PB1_OV_TOT:1,PB1_OV_REM:0},
    {SH:"21-9500",SH_DESCR:"Telecom infrastructures",RFDC:"21-9500-9570",RFDC_DESCR:"Misc Telecoms",RFDC_PLAN:"2022-03-31",RFDC_STATUS:"Not Started",PUNCH_STATUS:"B1",B_OV_TOT:6,B_OV_COM:0,B_OV_REM:6,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:13,PA_OV_REM:0,PB1_OV_TOT:2,PB1_OV_REM:1},
    {SH:"21-9500",SH_DESCR:"Telecom infrastructures",RFDC:"21-9500-9574",RFDC_DESCR:"Network management System",RFDC_PLAN:"2022-03-17",RFDC_STATUS:"Not Started",PUNCH_STATUS:"B2",B_OV_TOT:30,B_OV_COM:0,B_OV_REM:30,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:15,PA_OV_REM:0,PB1_OV_TOT:2,PB1_OV_REM:0},
    {SH:"21-9500",SH_DESCR:"Telecom infrastructures",RFDC:"21-9500-9575",RFDC_DESCR:"Digital Voice recorder",RFDC_PLAN:"2022-04-07",RFDC_STATUS:"Not Started",PUNCH_STATUS:"B1",B_OV_TOT:3,B_OV_COM:0,B_OV_REM:3,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:8,PA_OV_REM:0,PB1_OV_TOT:6,PB1_OV_REM:2},
    {SH:"21-9810",SH_DESCR:"Pedestal cranes",RFDC:"21-9810-9811",RFDC_DESCR:"FPSO West Crane",RFDC_PLAN:"2022-05-26",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:10,B_OV_COM:0,B_OV_REM:10,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:31,PA_OV_REM:29,PB1_OV_TOT:25,PB1_OV_REM:12},
    {SH:"21-9810",SH_DESCR:"Pedestal cranes",RFDC:"21-9810-9812",RFDC_DESCR:"FPSO East Crane",RFDC_PLAN:"2022-05-26",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:10,B_OV_COM:0,B_OV_REM:10,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:25,PA_OV_REM:25,PB1_OV_TOT:17,PB1_OV_REM:8},
    {SH:"21-9820",SH_DESCR:"Lifting equipment",RFDC:"21-9820-9822",RFDC_DESCR:"Passenger Lift",RFDC_PLAN:"2022-05-05",RFDC_STATUS:"Not Started",PUNCH_STATUS:"B1",B_OV_TOT:6,B_OV_COM:0,B_OV_REM:6,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:1,PA_OV_REM:0,PB1_OV_TOT:1,PB1_OV_REM:1},
    {SH:"21-9820",SH_DESCR:"Lifting equipment",RFDC:"21-9820-9823",RFDC_DESCR:"Goods Lift",RFDC_PLAN:"2022-05-26",RFDC_STATUS:"Not Started",PUNCH_STATUS:"B2",B_OV_TOT:6,B_OV_COM:0,B_OV_REM:6,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:4,PA_OV_REM:0},
    {SH:"21-9820",SH_DESCR:"Lifting equipment",RFDC:"21-9820-9824",RFDC_DESCR:"Miscellaneous Lifting Beams, Davits, Hoist, winches and Padeyes",RFDC_PLAN:"2022-06-02",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:111,B_OV_COM:0,B_OV_REM:111,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:5,PA_OV_REM:4},
    {SH:"21-9840",SH_DESCR:"Workshops, Medic suite, Laboratory, Galley & Laundry",RFDC:"21-9840-9841",RFDC_DESCR:"Workshop",RFDC_PLAN:"2022-06-23",RFDC_STATUS:"Not Started",PUNCH_STATUS:"B2",B_OV_TOT:5,B_OV_COM:0,B_OV_REM:5,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PB1_OV_TOT:1,PB1_OV_REM:0},
    {SH:"21-9840",SH_DESCR:"Workshops, Medic suite, Laboratory, Galley & Laundry",RFDC:"21-9840-9842",RFDC_DESCR:"Laboratory",RFDC_PLAN:"2022-06-30",RFDC_STATUS:"Not Started",PUNCH_STATUS:"N",B_OV_TOT:9,B_OV_COM:0,B_OV_REM:9,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0},
    {SH:"21-9840",SH_DESCR:"Workshops, Medic suite, Laboratory, Galley & Laundry",RFDC:"21-9840-9843",RFDC_DESCR:"Medical Suite",RFDC_PLAN:"2022-06-23",RFDC_STATUS:"Not Started",PUNCH_STATUS:"N",B_OV_TOT:1,B_OV_COM:0,B_OV_REM:1,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0},
    {SH:"21-9840",SH_DESCR:"Workshops, Medic suite, Laboratory, Galley & Laundry",RFDC:"21-9840-9844",RFDC_DESCR:"Galley",RFDC_PLAN:"2022-06-09",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:48,B_OV_COM:0,B_OV_REM:48,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:18,PA_OV_REM:5,PB1_OV_TOT:6,PB1_OV_REM:3},
    {SH:"21-9840",SH_DESCR:"Workshops, Medic suite, Laboratory, Galley & Laundry",RFDC:"21-9840-9845",RFDC_DESCR:"Laundry",RFDC_PLAN:"2022-06-30",RFDC_STATUS:"Not Started",PUNCH_STATUS:"N",B_OV_TOT:12,B_OV_COM:0,B_OV_REM:12,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PB1_OV_TOT:1,PB1_OV_REM:0},
    {SH:"21-9921",SH_DESCR:"HVAC LQ",RFDC:"21-9921-9921",RFDC_DESCR:"Emergency LQ HVAC",RFDC_PLAN:"2022-04-14",RFDC_STATUS:"In progress",PUNCH_STATUS:"A",B_OV_TOT:361,B_OV_COM:19,B_OV_REM:342,B_OV_PROG:5.26,B_OV_STATUS:"less than 50%",B_PG:5.26,PA_OV_TOT:157,PA_OV_REM:4,PB1_OV_TOT:287,PB1_OV_REM:19},
    {SH:"21-9921",SH_DESCR:"HVAC LQ",RFDC:"21-9921-9922",RFDC_DESCR:"Normal | Essential LQ HVAC",RFDC_PLAN:"2022-03-24",RFDC_STATUS:"In progress",PUNCH_STATUS:"A",B_OV_TOT:1223,B_OV_COM:89,B_OV_REM:1134,B_OV_PROG:7.27,B_OV_STATUS:"less than 50%",B_PG:7.27,PA_OV_TOT:165,PA_OV_REM:24,PB1_OV_TOT:474,PB1_OV_REM:39},
    {SH:"21-9921",SH_DESCR:"HVAC LQ",RFDC:"21-9921-9927",RFDC_DESCR:"Cool Stores",RFDC_PLAN:"2022-06-09",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:50,B_OV_COM:0,B_OV_REM:50,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:17,PA_OV_REM:5,PB1_OV_TOT:35,PB1_OV_REM:6},
    {SH:"21-9950",SH_DESCR:"HVAC Tertiary Refuge",RFDC:"21-9950-9950",RFDC_DESCR:"HVAC Tertiary Refuge",RFDC_PLAN:"2022-07-14",RFDC_STATUS:"Not Started",PUNCH_STATUS:"N",B_OV_TOT:75,B_OV_COM:0,B_OV_REM:75,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0},
    {SH:"21-9970",SH_DESCR:"HVAC Hull",RFDC:"21-9970-9970",RFDC_DESCR:"Emergency HULL HVAC",RFDC_PLAN:"2022-03-17",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:28,B_OV_COM:0,B_OV_REM:28,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:8,PA_OV_REM:4,PB1_OV_TOT:26,PB1_OV_REM:2},
    {SH:"21-9970",SH_DESCR:"HVAC Hull",RFDC:"21-9970-9971",RFDC_DESCR:"Normal | Essential HULL HVAC",RFDC_PLAN:"2022-01-27",RFDC_STATUS:"In progress",PUNCH_STATUS:"A",B_OV_TOT:885,B_OV_COM:411,B_OV_REM:474,B_OV_PROG:46.44,B_OV_STATUS:"less than 50%",B_PG:46.44,PA_OV_TOT:146,PA_OV_REM:9,PB1_OV_TOT:468,PB1_OV_REM:27},
    {SH:"21-9976",SH_DESCR:"HVAC Topsides",RFDC:"21-9976-9976",RFDC_DESCR:"Emergency Topsides HVAC",RFDC_PLAN:"2022-04-07",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:97,B_OV_COM:0,B_OV_REM:97,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:21,PA_OV_REM:10,PB1_OV_TOT:24,PB1_OV_REM:17},
    {SH:"21-9976",SH_DESCR:"HVAC Topsides",RFDC:"21-9976-9977",RFDC_DESCR:"Normal | Essential Topsides HVAC",RFDC_PLAN:"2022-03-17",RFDC_STATUS:"Not Started",PUNCH_STATUS:"A",B_OV_TOT:195,B_OV_COM:0,B_OV_REM:195,B_OV_PROG:0,B_OV_STATUS:"less than 50%",B_PG:0,PA_OV_TOT:16,PA_OV_REM:4,PB1_OV_TOT:13,PB1_OV_REM:7}
    ],
  Queries: {
    SQL1: [
      { DESC: 'DataSource SPCS Data 03-Mar-20' }
    ],
    SQL2: [
      { CDATE: '2021-11-25' }
    ],
    // Data table & Line chart Data
    SQL3:  [
      {RFDC_PLAN:"2021-05-27",B_T:146,B_A:8,B_O:138,B_PG:5.48,PA_O:1,PB1_O:1,PB2_O:4},
      {RFDC_PLAN:"2021-06-03"},
      {RFDC_PLAN:"2021-06-10"},
      {RFDC_PLAN:"2021-06-17"},
      {RFDC_PLAN:"2021-06-24"},
      {RFDC_PLAN:"2021-07-01"},
      {RFDC_PLAN:"2021-07-08"},
      {RFDC_PLAN:"2021-07-15"},
      {RFDC_PLAN:"2021-07-22"},
      {RFDC_PLAN:"2021-07-29"},
      {RFDC_PLAN:"2021-08-05"},
      {RFDC_PLAN:"2021-08-12"},
      {RFDC_PLAN:"2021-08-19"},
      {RFDC_PLAN:"2021-08-26"},
      {RFDC_PLAN:"2021-09-02"},
      {RFDC_PLAN:"2021-09-09"},
      {RFDC_PLAN:"2021-09-16"},
      {RFDC_PLAN:"2021-09-23"},
      {RFDC_PLAN:"2021-09-30"},
      {RFDC_PLAN:"2021-10-07"},
      {RFDC_PLAN:"2021-10-14"},
      {RFDC_PLAN:"2021-10-21",B_T:373,B_A:81,B_O:292,B_PG:21.72,PA_O:2,PB1_O:41,PB2_O:30},
      {RFDC_PLAN:"2021-10-28"},
      {RFDC_PLAN:"2021-11-04"},
      {RFDC_PLAN:"2021-11-11",B_T:36,B_A:7,B_O:29,B_PG:19.44,PA_O:1,PB1_O:4,PB2_O:1,PB3_O:0},
      {RFDC_PLAN:"2021-11-18"},
      {RFDC_PLAN:"2021-11-25",B_T:117,B_A:58,B_O:59,B_PG:49.57,PA_O:0,PB1_O:1,PB2_O:9},
      {RFDC_PLAN:"2021-12-02",B_T:91,B_A:36,B_O:55,B_PG:39.56,PA_O:3,PB1_O:134,PB2_O:82},
      {RFDC_PLAN:"2021-12-09",B_T:1952,B_A:398,B_O:1554,B_PG:20.39,PA_O:0,PB1_O:90,PB2_O:4},
      {RFDC_PLAN:"2021-12-16",B_T:685,B_A:321,B_O:364,B_PG:46.86,PA_O:0,PB1_O:9,PB2_O:71,PB3_O:0},
      {RFDC_PLAN:"2021-12-23"},
      {RFDC_PLAN:"2021-12-30"},
      {RFDC_PLAN:"2022-01-06"},
      {RFDC_PLAN:"2022-01-13"},
      {RFDC_PLAN:"2022-01-20"},
      {RFDC_PLAN:"2022-01-27",B_T:885,B_A:397,B_O:488,B_PG:44.86,PA_O:1,PB1_O:18,PB2_O:207},
      {RFDC_PLAN:"2022-02-03"},
      {RFDC_PLAN:"2022-02-10",B_T:16,B_A:13,B_O:3,B_PG:81.25,PA_O:0,PB1_O:6,PB2_O:10},
      {RFDC_PLAN:"2022-02-17",B_T:365,B_A:165,B_O:200,B_PG:45.21,PA_O:5,PB1_O:57,PB2_O:81},
      {RFDC_PLAN:"2022-02-24",B_T:928,B_A:323,B_O:605,B_PG:34.81,PA_O:59,PB1_O:51,PB2_O:287,PB3_O:1},
      {RFDC_PLAN:"2022-03-03",B_T:242,B_A:125,B_O:117,B_PG:51.65,PA_O:0,PB1_O:18,PB2_O:105,PB3_O:0},
      {RFDC_PLAN:"2022-03-10",B_T:198,B_A:60,B_O:138,B_PG:30.3,PA_O:3,PB1_O:13,PB2_O:237},
      {RFDC_PLAN:"2022-03-17",B_T:567,B_A:234,B_O:333,B_PG:41.27,PA_O:1,PB1_O:29,PB2_O:167},
      {RFDC_PLAN:"2022-03-24",B_T:1526,B_A:228,B_O:1298,B_PG:14.94,PA_O:29,PB1_O:44,PB2_O:284,PB3_O:0},
      {RFDC_PLAN:"2022-03-31",B_T:367,B_A:0,B_O:367,B_PG:0,PA_O:14,PB1_O:19,PB2_O:36},
      {RFDC_PLAN:"2022-04-07",B_T:400,B_A:46,B_O:354,B_PG:11.5,PA_O:15,PB1_O:41,PB2_O:120},
      {RFDC_PLAN:"2022-04-14",B_T:428,B_A:24,B_O:404,B_PG:5.61,PA_O:0,PB1_O:27,PB2_O:244},
      {RFDC_PLAN:"2022-04-21",B_T:198,B_A:5,B_O:193,B_PG:2.53,PA_O:14,PB1_O:14,PB2_O:26},
      {RFDC_PLAN:"2022-04-28",B_T:19,B_A:0,B_O:19,B_PG:0,PA_O:1,PB1_O:0,PB2_O:5},
      {RFDC_PLAN:"2022-05-05",B_T:344,B_A:18,B_O:326,B_PG:5.23,PA_O:5,PB1_O:16,PB2_O:58,PB3_O:2},
      {RFDC_PLAN:"2022-05-12",B_T:115,B_A:0,B_O:115,B_PG:0,PA_O:1,PB1_O:8,PB2_O:7},
      {RFDC_PLAN:"2022-05-19",B_T:180,B_A:2,B_O:178,B_PG:1.11,PA_O:3,PB1_O:7,PB2_O:15},
      {RFDC_PLAN:"2022-05-26",B_T:974,B_A:2,B_O:972,B_PG:0.21,PA_O:69,PB1_O:44,PB2_O:83},
      {RFDC_PLAN:"2022-06-02",B_T:591,B_A:0,B_O:591,B_PG:0,PA_O:23,PB1_O:23,PB2_O:45},
      {RFDC_PLAN:"2022-06-09",B_T:768,B_A:8,B_O:760,B_PG:1.04,PA_O:39,PB1_O:45,PB2_O:77,PB3_O:8},
      {RFDC_PLAN:"2022-06-16",B_T:760,B_A:0,B_O:760,B_PG:0,PA_O:19,PB1_O:46,PB2_O:18,PB3_O:8},
      {RFDC_PLAN:"2022-06-23",B_T:1012,B_A:27,B_O:985,B_PG:2.67,PA_O:31,PB1_O:42,PB2_O:110},
      {RFDC_PLAN:"2022-06-30",B_T:522,B_A:4,B_O:518,B_PG:0.77,PA_O:32,PB1_O:5,PB2_O:14,PB3_O:3},
      {RFDC_PLAN:"2022-07-07",B_T:98,B_A:3,B_O:95,B_PG:3.06,PA_O:4,PB1_O:9,PB2_O:4},
      {RFDC_PLAN:"2022-07-14",B_T:325,B_A:1,B_O:324,B_PG:0.31,PA_O:9,PB1_O:6,PB2_O:10},
      {RFDC_PLAN:"2022-07-21"},
      {RFDC_PLAN:"2022-07-28",B_T:4205,B_A:499,B_O:3706,B_PG:11.87,PA_O:93,PB1_O:135,PB2_O:328,PB3_O:6},
      {RFDC_PLAN:"2022-08-04"},
      {RFDC_PLAN:"2022-08-11",B_T:3,B_A:0,B_O:3,B_PG:0},
      {RFDC_PLAN:"2022-08-18"}
      ],
    SQL4: [
      {TOTAL:220,NOT_STARTED:162,IN_PROGRESS:58,BITR_COMPLETED:0,PARTIAL_RFDC_COMPLETE:0,FULL_RFDC_COMPLETE:0}
    ],
    SQL5: [
      {CUTOFF:"2018-06-21",WK_NO:"W2018_25"},
      {CUTOFF:"2018-06-28",WK_NO:"W2018_26"},
      {CUTOFF:"2018-07-05",WK_NO:"W2018_27"},
      {CUTOFF:"2018-07-12",WK_NO:"W2018_28"},
      {CUTOFF:"2018-07-19",WK_NO:"W2018_29"},
      {CUTOFF:"2018-07-26",WK_NO:"W2018_30"},
      {CUTOFF:"2018-08-02",WK_NO:"W2018_31"},
      {CUTOFF:"2018-08-09",WK_NO:"W2018_32"},
      {CUTOFF:"2018-08-16",WK_NO:"W2018_33"},
      {CUTOFF:"2018-08-23",WK_NO:"W2018_34"},
      {CUTOFF:"2018-08-30",WK_NO:"W2018_35"},
      {CUTOFF:"2018-09-06",WK_NO:"W2018_36"},
      {CUTOFF:"2018-09-13",WK_NO:"W2018_37"},
      {CUTOFF:"2018-09-20",WK_NO:"W2018_38"},
      {CUTOFF:"2018-09-27",WK_NO:"W2018_39"},
      {CUTOFF:"2018-10-04",WK_NO:"W2018_40"},
      {CUTOFF:"2018-10-11",WK_NO:"W2018_41"},
      {CUTOFF:"2018-10-18",WK_NO:"W2018_42"},
      {CUTOFF:"2018-10-25",WK_NO:"W2018_43"},
      {CUTOFF:"2018-11-01",WK_NO:"W2018_44"},
      {CUTOFF:"2018-11-08",WK_NO:"W2018_45"},
      {CUTOFF:"2018-11-15",WK_NO:"W2018_46"},
      {CUTOFF:"2018-11-22",WK_NO:"W2018_47"},
      {CUTOFF:"2018-11-29",WK_NO:"W2018_48"},
      {CUTOFF:"2018-12-06",WK_NO:"W2018_49"},
      {CUTOFF:"2018-12-13",WK_NO:"W2018_50"},
      {CUTOFF:"2018-12-20",WK_NO:"W2018_51"},
      {CUTOFF:"2018-12-27",WK_NO:"W2018_52"},
      {CUTOFF:"2019-01-03",WK_NO:"W2019_01"},
      {CUTOFF:"2019-01-10",WK_NO:"W2019_02"},
      {CUTOFF:"2019-01-17",WK_NO:"W2019_03"},
      {CUTOFF:"2019-01-24",WK_NO:"W2019_04"},
      {CUTOFF:"2019-01-31",WK_NO:"W2019_05"},
      {CUTOFF:"2019-02-07",WK_NO:"W2019_06"},
      {CUTOFF:"2019-02-14",WK_NO:"W2019_07"},
      {CUTOFF:"2019-02-21",WK_NO:"W2019_08"},
      {CUTOFF:"2019-02-28",WK_NO:"W2019_09"},
      {CUTOFF:"2019-03-07",WK_NO:"W2019_10"},
      {CUTOFF:"2019-03-14",WK_NO:"W2019_11"},
      {CUTOFF:"2019-03-21",WK_NO:"W2019_12"},
      {CUTOFF:"2019-03-28",WK_NO:"W2019_13"},
      {CUTOFF:"2019-04-04",WK_NO:"W2019_14"},
      {CUTOFF:"2019-04-11",WK_NO:"W2019_15"},
      {CUTOFF:"2019-04-18",WK_NO:"W2019_16"},
      {CUTOFF:"2019-04-25",WK_NO:"W2019_17"},
      {CUTOFF:"2019-05-02",WK_NO:"W2019_18"},
      {CUTOFF:"2019-05-09",WK_NO:"W2019_19"},
      {CUTOFF:"2019-05-16",WK_NO:"W2019_20"},
      {CUTOFF:"2019-05-23",WK_NO:"W2019_21"},
      {CUTOFF:"2019-05-30",WK_NO:"W2019_22"},
      {CUTOFF:"2019-06-06",WK_NO:"W2019_23"},
      {CUTOFF:"2019-06-13",WK_NO:"W2019_24"},
      {CUTOFF:"2019-06-20",WK_NO:"W2019_25"},
      {CUTOFF:"2019-06-27",WK_NO:"W2019_26"},
      {CUTOFF:"2019-07-04",WK_NO:"W2019_27"},
      {CUTOFF:"2019-07-11",WK_NO:"W2019_28"},
      {CUTOFF:"2019-07-18",WK_NO:"W2019_29"},
      {CUTOFF:"2019-07-25",WK_NO:"W2019_30"},
      {CUTOFF:"2019-08-01",WK_NO:"W2019_31"},
      {CUTOFF:"2019-08-08",WK_NO:"W2019_32"},
      {CUTOFF:"2019-08-15",WK_NO:"W2019_33"},
      {CUTOFF:"2019-08-22",WK_NO:"W2019_34"},
      {CUTOFF:"2019-08-29",WK_NO:"W2019_35"},
      {CUTOFF:"2019-09-05",WK_NO:"W2019_36"},
      {CUTOFF:"2019-09-12",WK_NO:"W2019_37"},
      {CUTOFF:"2019-09-19",WK_NO:"W2019_38"},
      {CUTOFF:"2019-09-26",WK_NO:"W2019_39"},
      {CUTOFF:"2019-10-03",WK_NO:"W2019_40"},
      {CUTOFF:"2019-10-10",WK_NO:"W2019_41"},
      {CUTOFF:"2019-10-17",WK_NO:"W2019_42"},
      {CUTOFF:"2019-10-24",WK_NO:"W2019_43"},
      {CUTOFF:"2019-10-31",WK_NO:"W2019_44"},
      {CUTOFF:"2019-11-07",WK_NO:"W2019_45"},
      {CUTOFF:"2019-11-14",WK_NO:"W2019_46"},
      {CUTOFF:"2019-11-21",WK_NO:"W2019_47"},
      {CUTOFF:"2019-11-28",WK_NO:"W2019_48"},
      {CUTOFF:"2019-12-05",WK_NO:"W2019_49"},
      {CUTOFF:"2019-12-12",WK_NO:"W2019_50"},
      {CUTOFF:"2019-12-19",WK_NO:"W2019_51"},
      {CUTOFF:"2019-12-26",WK_NO:"W2019_52"},
      {CUTOFF:"2020-01-02",WK_NO:"W2020_01"},
      {CUTOFF:"2020-01-09",WK_NO:"W2020_02"},
      {CUTOFF:"2020-01-16",WK_NO:"W2020_03"},
      {CUTOFF:"2020-01-23",WK_NO:"W2020_04"},
      {CUTOFF:"2020-01-30",WK_NO:"W2020_05"},
      {CUTOFF:"2020-02-06",WK_NO:"W2020_06"},
      {CUTOFF:"2020-02-13",WK_NO:"W2020_07"},
      {CUTOFF:"2020-02-20",WK_NO:"W2020_08"},
      {CUTOFF:"2020-02-27",WK_NO:"W2020_09"},
      {CUTOFF:"2020-03-05",WK_NO:"W2020_10"},
      {CUTOFF:"2020-03-12",WK_NO:"W2020_11"},
      {CUTOFF:"2020-03-19",WK_NO:"W2020_12"},
      {CUTOFF:"2020-03-26",WK_NO:"W2020_13"},
      {CUTOFF:"2020-04-02",WK_NO:"W2020_14"},
      {CUTOFF:"2020-04-09",WK_NO:"W2020_15"},
      {CUTOFF:"2020-04-16",WK_NO:"W2020_16"},
      {CUTOFF:"2020-04-23",WK_NO:"W2020_17"},
      {CUTOFF:"2020-04-30",WK_NO:"W2020_18"},
      {CUTOFF:"2020-05-07",WK_NO:"W2020_19"},
      {CUTOFF:"2020-05-14",WK_NO:"W2020_20"},
      {CUTOFF:"2020-05-21",WK_NO:"W2020_21"},
      {CUTOFF:"2020-05-28",WK_NO:"W2020_22"},
      {CUTOFF:"2020-06-04",WK_NO:"W2020_23"},
      {CUTOFF:"2020-06-11",WK_NO:"W2020_24"},
      {CUTOFF:"2020-06-18",WK_NO:"W2020_25"},
      {CUTOFF:"2020-06-25",WK_NO:"W2020_26"},
      {CUTOFF:"2020-07-02",WK_NO:"W2020_27"},
      {CUTOFF:"2020-07-09",WK_NO:"W2020_28"},
      {CUTOFF:"2020-07-16",WK_NO:"W2020_29"},
      {CUTOFF:"2020-07-23",WK_NO:"W2020_30"},
      {CUTOFF:"2020-07-30",WK_NO:"W2020_31"},
      {CUTOFF:"2020-08-06",WK_NO:"W2020_32"},
      {CUTOFF:"2020-08-13",WK_NO:"W2020_33"},
      {CUTOFF:"2020-08-20",WK_NO:"W2020_34"},
      {CUTOFF:"2020-08-27",WK_NO:"W2020_35"},
      {CUTOFF:"2020-09-03",WK_NO:"W2020_36"},
      {CUTOFF:"2020-09-10",WK_NO:"W2020_37"},
      {CUTOFF:"2020-09-17",WK_NO:"W2020_38"},
      {CUTOFF:"2020-09-24",WK_NO:"W2020_39"},
      {CUTOFF:"2020-10-01",WK_NO:"W2020_40"},
      {CUTOFF:"2020-10-08",WK_NO:"W2020_41"},
      {CUTOFF:"2020-10-15",WK_NO:"W2020_42"},
      {CUTOFF:"2020-10-22",WK_NO:"W2020_43"},
      {CUTOFF:"2020-10-29",WK_NO:"W2020_44"},
      {CUTOFF:"2020-11-05",WK_NO:"W2020_45"},
      {CUTOFF:"2020-11-12",WK_NO:"W2020_46"},
      {CUTOFF:"2020-11-19",WK_NO:"W2020_47"},
      {CUTOFF:"2020-11-26",WK_NO:"W2020_48"},
      {CUTOFF:"2020-12-03",WK_NO:"W2020_49"},
      {CUTOFF:"2020-12-10",WK_NO:"W2020_50"},
      {CUTOFF:"2020-12-17",WK_NO:"W2020_51"},
      {CUTOFF:"2020-12-24",WK_NO:"W2020_52"},
      {CUTOFF:"2020-12-31",WK_NO:"W2020_53"},
      {CUTOFF:"2021-01-07",WK_NO:"W2021_01"},
      {CUTOFF:"2021-01-14",WK_NO:"W2021_02"},
      {CUTOFF:"2021-01-21",WK_NO:"W2021_03"},
      {CUTOFF:"2021-01-28",WK_NO:"W2021_04"},
      {CUTOFF:"2021-02-04",WK_NO:"W2021_05"},
      {CUTOFF:"2021-02-11",WK_NO:"W2021_06"},
      {CUTOFF:"2021-02-18",WK_NO:"W2021_07"},
      {CUTOFF:"2021-02-25",WK_NO:"W2021_08"},
      {CUTOFF:"2021-03-04",WK_NO:"W2021_09"},
      {CUTOFF:"2021-03-11",WK_NO:"W2021_10"},
      {CUTOFF:"2021-03-18",WK_NO:"W2021_11"},
      {CUTOFF:"2021-03-25",WK_NO:"W2021_12"},
      {CUTOFF:"2021-04-01",WK_NO:"W2021_13"},
      {CUTOFF:"2021-04-08",WK_NO:"W2021_14"},
      {CUTOFF:"2021-04-15",WK_NO:"W2021_15"},
      {CUTOFF:"2021-04-22",WK_NO:"W2021_16"},
      {CUTOFF:"2021-04-29",WK_NO:"W2021_17"},
      {CUTOFF:"2021-05-06",WK_NO:"W2021_18"},
      {CUTOFF:"2021-05-13",WK_NO:"W2021_19"},
      {CUTOFF:"2021-05-20",WK_NO:"W2021_20"},
      {CUTOFF:"2021-05-27",WK_NO:"W2021_21"},
      {CUTOFF:"2021-06-03",WK_NO:"W2021_22"},
      {CUTOFF:"2021-06-10",WK_NO:"W2021_23"},
      {CUTOFF:"2021-06-17",WK_NO:"W2021_24"},
      {CUTOFF:"2021-06-24",WK_NO:"W2021_25"},
      {CUTOFF:"2021-07-01",WK_NO:"W2021_26"},
      {CUTOFF:"2021-07-08",WK_NO:"W2021_27"},
      {CUTOFF:"2021-07-15",WK_NO:"W2021_28"},
      {CUTOFF:"2021-07-22",WK_NO:"W2021_29"},
      {CUTOFF:"2021-07-29",WK_NO:"W2021_30"},
      {CUTOFF:"2021-08-05",WK_NO:"W2021_31"},
      {CUTOFF:"2021-08-12",WK_NO:"W2021_32"},
      {CUTOFF:"2021-08-19",WK_NO:"W2021_33"},
      {CUTOFF:"2021-08-26",WK_NO:"W2021_34"},
      {CUTOFF:"2021-09-02",WK_NO:"W2021_35"},
      {CUTOFF:"2021-09-09",WK_NO:"W2021_36"},
      {CUTOFF:"2021-09-16",WK_NO:"W2021_37"},
      {CUTOFF:"2021-09-23",WK_NO:"W2021_38"},
      {CUTOFF:"2021-09-30",WK_NO:"W2021_39"},
      {CUTOFF:"2021-10-07",WK_NO:"W2021_40"},
      {CUTOFF:"2021-10-14",WK_NO:"W2021_41"},
      {CUTOFF:"2021-10-21",WK_NO:"W2021_42"},
      {CUTOFF:"2021-10-28",WK_NO:"W2021_43"},
      {CUTOFF:"2021-11-04",WK_NO:"W2021_44"},
      {CUTOFF:"2021-11-11",WK_NO:"W2021_45"},
      {CUTOFF:"2021-11-18",WK_NO:"W2021_46"},
      {CUTOFF:"2021-11-25",WK_NO:"W2021_47"},
      {CUTOFF:"2021-12-02",WK_NO:"W2021_48"},
      {CUTOFF:"2021-12-09",WK_NO:"W2021_49"},
      {CUTOFF:"2021-12-16",WK_NO:"W2021_50"},
      {CUTOFF:"2021-12-23",WK_NO:"W2021_51"},
      {CUTOFF:"2021-12-30",WK_NO:"W2021_52"},
      {CUTOFF:"2022-01-06",WK_NO:"W2022_01"},
      {CUTOFF:"2022-01-13",WK_NO:"W2022_02"},
      {CUTOFF:"2022-01-20",WK_NO:"W2022_03"},
      {CUTOFF:"2022-01-27",WK_NO:"W2022_04"},
      {CUTOFF:"2022-02-03",WK_NO:"W2022_05"},
      {CUTOFF:"2022-02-10",WK_NO:"W2022_06"},
      {CUTOFF:"2022-02-17",WK_NO:"W2022_07"},
      {CUTOFF:"2022-02-24",WK_NO:"W2022_08"},
      {CUTOFF:"2022-03-03",WK_NO:"W2022_09"},
      {CUTOFF:"2022-03-10",WK_NO:"W2022_10"},
      {CUTOFF:"2022-03-17",WK_NO:"W2022_11"},
      {CUTOFF:"2022-03-24",WK_NO:"W2022_12"},
      {CUTOFF:"2022-03-31",WK_NO:"W2022_13"},
      {CUTOFF:"2022-04-07",WK_NO:"W2022_14"},
      {CUTOFF:"2022-04-14",WK_NO:"W2022_15"},
      {CUTOFF:"2022-04-21",WK_NO:"W2022_16"},
      {CUTOFF:"2022-04-28",WK_NO:"W2022_17"},
      {CUTOFF:"2022-05-05",WK_NO:"W2022_18"},
      {CUTOFF:"2022-05-12",WK_NO:"W2022_19"},
      {CUTOFF:"2022-05-19",WK_NO:"W2022_20"},
      {CUTOFF:"2022-05-26",WK_NO:"W2022_21"},
      {CUTOFF:"2022-06-02",WK_NO:"W2022_22"},
      {CUTOFF:"2022-06-09",WK_NO:"W2022_23"},
      {CUTOFF:"2022-06-16",WK_NO:"W2022_24"},
      {CUTOFF:"2022-06-23",WK_NO:"W2022_25"},
      {CUTOFF:"2022-06-30",WK_NO:"W2022_26"},
      {CUTOFF:"2022-07-07",WK_NO:"W2022_27"},
      {CUTOFF:"2022-07-14",WK_NO:"W2022_28"},
      {CUTOFF:"2022-07-21",WK_NO:"W2022_29"},
      {CUTOFF:"2022-07-28",WK_NO:"W2022_30"},
      {CUTOFF:"2022-08-04",WK_NO:"W2022_31"},
      {CUTOFF:"2022-08-11",WK_NO:"W2022_32"},
      {CUTOFF:"2022-08-18",WK_NO:"W2022_33"},
      {CUTOFF:"2022-08-25",WK_NO:"W2022_34"},
      {CUTOFF:"2022-09-01",WK_NO:"W2022_35"},
      {CUTOFF:"2022-09-08",WK_NO:"W2022_36"},
      {CUTOFF:"2022-09-15",WK_NO:"W2022_37"},
      {CUTOFF:"2022-09-22",WK_NO:"W2022_38"},
      {CUTOFF:"2022-09-29",WK_NO:"W2022_39"},
      {CUTOFF:"2022-10-06",WK_NO:"W2022_40"},
      {CUTOFF:"2022-10-13",WK_NO:"W2022_41"},
      {CUTOFF:"2022-10-20",WK_NO:"W2022_42"},
      {CUTOFF:"2022-10-27",WK_NO:"W2022_43"},
      {CUTOFF:"2022-11-03",WK_NO:"W2022_44"},
      {CUTOFF:"2022-11-10",WK_NO:"W2022_45"},
      {CUTOFF:"2022-11-17",WK_NO:"W2022_46"},
      {CUTOFF:"2022-11-24",WK_NO:"W2022_47"},
      {CUTOFF:"2022-12-01",WK_NO:"W2022_48"},
      {CUTOFF:"2022-12-08",WK_NO:"W2022_49"},
      {CUTOFF:"2022-12-15",WK_NO:"W2022_50"},
      {CUTOFF:"2022-12-22",WK_NO:"W2022_51"},
      {CUTOFF:"2022-12-29",WK_NO:"W2022_52"},
      {CUTOFF:"2023-01-05",WK_NO:"W2023_01"},
      {CUTOFF:"2023-01-12",WK_NO:"W2023_02"},
      {CUTOFF:"2023-01-19",WK_NO:"W2023_03"},
      {CUTOFF:"2023-01-26",WK_NO:"W2023_04"},
      {CUTOFF:"2023-02-02",WK_NO:"W2023_05"},
      {CUTOFF:"2023-02-09",WK_NO:"W2023_06"},
      {CUTOFF:"2023-02-16",WK_NO:"W2023_07"},
      {CUTOFF:"2023-02-23",WK_NO:"W2023_08"},
      {CUTOFF:"2023-03-02",WK_NO:"W2023_09"},
      {CUTOFF:"2023-03-09",WK_NO:"W2023_10"},
      {CUTOFF:"2023-03-16",WK_NO:"W2023_11"},
      {CUTOFF:"2023-03-23",WK_NO:"W2023_12"},
      {CUTOFF:"2023-03-30",WK_NO:"W2023_13"}
      ],
  }
}