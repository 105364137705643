import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 2100,
    CanvasHeight: 600,
    CanvasBorderWeight: 0.05,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.1,

    CanvasChartX: 50,
    CanvasChartY: 50,
    CanvasChartWidth: 480,
    CanvasChartHeight: 250,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  DataItems:    [
    {SUBSYSTEM:"21-6010-6011-02",SUBSYSTEM_DESCR:"SIS Marshalling - LQ (CER)",WD_PLAN:"2021-10-14",A_OV_TOT:14,A_OV_COM:4,A_OV_REM:10,A_OV_PROG:28.57,A_OV_STATUS:"less than 70%",A_PG:28.57,RFI_ISSUED:"Done",WD_ACTUAL:"DONE-PL RCVD",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1A",PA_OV_TOT:23,PA_OV_COM:2,PA_OV_REM:21,PB1_OV_TOT:4,PB1_OV_COM:1,PB1_OV_REM:3},
    {SUBSYSTEM:"21-6010-6011-07",SUBSYSTEM_DESCR:"SIS Multicore & Junction Box to Marshalling / System Cabinets in HER",WD_PLAN:"2021-10-14",A_OV_TOT:105,A_OV_COM:4,A_OV_REM:101,A_OV_PROG:3.8,A_OV_STATUS:"less than 70%",A_PG:3.8,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:5,PB1_OV_COM:0,PB1_OV_REM:5},
    {SUBSYSTEM:"21-6020-6021-02",SUBSYSTEM_DESCR:"PAS Marshalling - LQ (CER)",BATCH:"Priority Batch",WD_PLAN:"2021-10-14",A_OV_TOT:15,A_OV_COM:11,A_OV_REM:4,A_OV_PROG:73.33,A_OV_STATUS:"greater than 70%",A_PG:73.33,RFI_ISSUED:"Done",WD_ACTUAL:"DONE-PL RCVD",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1A",PA_OV_TOT:8,PA_OV_COM:1,PA_OV_REM:7,PB1_OV_TOT:9,PB1_OV_COM:1,PB1_OV_REM:8},
    {SUBSYSTEM:"21-6020-6021-06",SUBSYSTEM_DESCR:"PAS Multicore & Junction Box to Marshalling / System Cabinets in HER",WD_PLAN:"2021-10-14",A_OV_TOT:145,A_OV_COM:5,A_OV_REM:140,A_OV_PROG:3.44,A_OV_STATUS:"less than 70%",A_PG:3.44,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6070-6071-02",SUBSYSTEM_DESCR:"FGS Marshalling - LQ (CER)",WD_PLAN:"2021-10-14",A_OV_TOT:27,A_OV_COM:6,A_OV_REM:21,A_OV_PROG:22.22,A_OV_STATUS:"less than 70%",A_PG:22.22,RFI_ISSUED:"Done",WD_ACTUAL:"DONE-PL RCVD",MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1A",PA_OV_TOT:22,PA_OV_COM:3,PA_OV_REM:19,PB1_OV_TOT:11,PB1_OV_COM:0,PB1_OV_REM:11},
    {SUBSYSTEM:"21-6070-6071-06",SUBSYSTEM_DESCR:"FGS Multicore & Junction Box to Marshalling / System Cabinets in HER",WD_PLAN:"2021-10-14",A_OV_TOT:10,A_OV_COM:2,A_OV_REM:8,A_OV_PROG:20,A_OV_STATUS:"less than 70%",A_PG:20,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3},
    {SUBSYSTEM:"21-6600-6640-02",SUBSYSTEM_DESCR:"Topsides Emergency Lighting & small power 400V board 21-EDB-662402 (in LER)",WD_PLAN:"2021-10-14",A_OV_TOT:2692,A_OV_COM:0,A_OV_REM:2692,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1},
    {SUBSYSTEM:"21-6600-6674-02",SUBSYSTEM_DESCR:"Topside Essential 690 V Switchboard 21-ESB-667402",WD_PLAN:"2021-10-14",A_OV_TOT:233,A_OV_COM:133,A_OV_REM:100,A_OV_PROG:57.08,A_OV_STATUS:"less than 70%",A_PG:57.08,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:18,PB1_OV_COM:3,PB1_OV_REM:15},
    {SUBSYSTEM:"21-6600-6675-02",SUBSYSTEM_DESCR:"Topsides Normal / Essential lighting & Small Power 400V 21-EDB-667602",WD_PLAN:"2021-10-14",A_OV_TOT:184,A_OV_COM:0,A_OV_REM:184,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:2,PB1_OV_COM:2,PB1_OV_REM:0},
    {SUBSYSTEM:"21-6600-6675-03",SUBSYSTEM_DESCR:"Topsides Normal / Essential lighting & Small Power 400V 21-EDB-667603",WD_PLAN:"2021-10-14",A_OV_TOT:2436,A_OV_COM:0,A_OV_REM:2436,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P2"},
    {SUBSYSTEM:"21-6600-6675-04",SUBSYSTEM_DESCR:"Topsides MOV & Convenience Outlets 400V 21-EDB-667604",WD_PLAN:"2021-10-14",A_OV_TOT:219,A_OV_COM:0,A_OV_REM:219,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6600-6675-05",SUBSYSTEM_DESCR:"Topsides Misc Feeders / Auxiliaries 400V 21-EDB-667606",WD_PLAN:"2021-10-14",A_OV_TOT:27,A_OV_COM:0,A_OV_REM:27,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6600-6676-16",SUBSYSTEM_DESCR:"LQ Galley Panel 02 (ROOM: L220) 21-ELP-667678",WD_PLAN:"2021-10-14",A_OV_TOT:4,A_OV_COM:4,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"greater than 90%",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"DONE-PL RCVD",MC_SUBMITTED:"077333C-TEN-BP-TN-009",MC_STATUS:"A ITR Completed",SUBSYSTEM_STATUS:"A ITR Completed",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:18,PB1_OV_COM:1,PB1_OV_REM:17},
    {SUBSYSTEM:"21-8020-8024-01",SUBSYSTEM_DESCR:"HULL Sea Water Cooling Pumps - 21-P-802004A/B",WD_PLAN:"2021-10-14",A_OV_TOT:71,A_OV_COM:2,A_OV_REM:69,A_OV_PROG:2.81,A_OV_STATUS:"less than 70%",A_PG:2.81,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-8020-8024-02",SUBSYSTEM_DESCR:"HULL Sea Water Cooling Header & Cooling Water Exchangers - 21-H-805001A/B",WD_PLAN:"2021-10-14",A_OV_TOT:53,A_OV_COM:1,A_OV_REM:52,A_OV_PROG:1.88,A_OV_STATUS:"less than 70%",A_PG:1.88,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-8020-8026-01",SUBSYSTEM_DESCR:"HULL Service Sea Water to HVAC Cooling",WD_PLAN:"2021-10-14",A_OV_TOT:84,A_OV_COM:3,A_OV_REM:81,A_OV_PROG:3.57,A_OV_STATUS:"less than 70%",A_PG:3.57,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-8060-8061-02",SUBSYSTEM_DESCR:"Diesel Storage Tanks - 21-T-806001/2 & Purifier Pumps - 21-P-806002A/B",WD_PLAN:"2021-10-14",A_OV_TOT:178,A_OV_COM:40,A_OV_REM:138,A_OV_PROG:22.47,A_OV_STATUS:"less than 70%",A_PG:22.47,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:5,PA_OV_COM:3,PA_OV_REM:2},
    {SUBSYSTEM:"21-8060-8061-03",SUBSYSTEM_DESCR:"Diesel Supply & Return (Spill Back) Headers to Service Tanks - 21-T-806003/4",WD_PLAN:"2021-10-14",A_OV_TOT:69,A_OV_COM:31,A_OV_REM:38,A_OV_PROG:44.92,A_OV_STATUS:"less than 70%",A_PG:44.92,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-8100-8121-01",SUBSYSTEM_DESCR:"HULL Instrument Air Distribution",WD_PLAN:"2021-10-14",A_OV_TOT:566,A_OV_COM:204,A_OV_REM:362,A_OV_PROG:36.04,A_OV_STATUS:"less than 70%",A_PG:36.04,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1A"},
    {SUBSYSTEM:"21-9380-9380-05",SUBSYSTEM_DESCR:"Diesel Oil from Diesel Storage Tanks",WD_PLAN:"2021-10-14",A_OV_TOT:8,A_OV_COM:4,A_OV_REM:4,A_OV_PROG:50,A_OV_STATUS:"less than 70%",A_PG:50,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2"},
    {SUBSYSTEM:"21-6030-6032-01",SUBSYSTEM_DESCR:"ICSS Interface Servers, Workstations & Network Switches CER",WD_PLAN:"2021-10-21",A_OV_TOT:281,A_OV_COM:134,A_OV_REM:147,A_OV_PROG:47.68,A_OV_STATUS:"less than 70%",A_PG:47.68,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1A",PA_OV_TOT:28,PA_OV_COM:10,PA_OV_REM:18,PB1_OV_TOT:41,PB1_OV_COM:4,PB1_OV_REM:37},
    {SUBSYSTEM:"21-6030-6040-01",SUBSYSTEM_DESCR:"IRP & Interface Instrumentation Cable - ME05 (HV & LV Room)",WD_PLAN:"2021-10-21",A_OV_TOT:79,A_OV_COM:0,A_OV_REM:79,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6030-6041-01",SUBSYSTEM_DESCR:"PEMS System",WD_PLAN:"2021-10-21",A_OV_TOT:2,A_OV_COM:0,A_OV_REM:2,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1},
    {SUBSYSTEM:"21-6600-6610-01",SUBSYSTEM_DESCR:"Emergency Generator 21-G-661001",BATCH:"Priority Batch",WD_PLAN:"2021-10-21",A_OV_TOT:163,A_OV_COM:18,A_OV_REM:145,A_OV_PROG:11.04,A_OV_STATUS:"less than 70%",A_PG:11.04,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:5,PA_OV_COM:1,PA_OV_REM:4,PB1_OV_TOT:11,PB1_OV_COM:0,PB1_OV_REM:11},
    {SUBSYSTEM:"21-6600-6676-13",SUBSYSTEM_DESCR:"LQ Laundry Panel 01 (ROOM: L115) 21-ELP-667674",WD_PLAN:"2021-10-21",A_OV_TOT:4,A_OV_COM:4,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"greater than 90%",A_PG:100,RFI_ISSUED:"2021-08-27",WD_ACTUAL:"2021-08-31",PL_RECEIVED:"2021-08-31",MC_SUBMITTED:"077333C-TEN-BP-TN-005",MC_APPROVED:"2021-10-29",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:7,PA_OV_COM:7,PA_OV_REM:0},
    {SUBSYSTEM:"21-6600-6676-15",SUBSYSTEM_DESCR:"LQ Laundry Panel 02 (ROOM: L115) 21-ELP-667677",WD_PLAN:"2021-10-21",A_OV_TOT:4,A_OV_COM:4,A_OV_REM:0,A_OV_PROG:100,A_OV_STATUS:"greater than 90%",A_PG:100,RFI_ISSUED:"Done",WD_ACTUAL:"DONE-PL RCVD",MC_SUBMITTED:"077333C-TEN-BP-TN-006",MC_APPROVED:"2021-10-28",MC_STATUS:"Full MC Complete",SUBSYSTEM_STATUS:"Full MC Complete",PUNCH_STATUS:"B1",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3},
    {SUBSYSTEM:"21-6700-6702-01",SUBSYSTEM_DESCR:"Topside Main UPS System (Incl Batteries, Breakers & Subdistribution Board)",WD_PLAN:"2021-10-21",A_OV_TOT:40,A_OV_COM:0,A_OV_REM:40,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:4,PA_OV_COM:0,PA_OV_REM:4,PB1_OV_TOT:8,PB1_OV_COM:6,PB1_OV_REM:2},
    {SUBSYSTEM:"21-8000-8010-01",SUBSYSTEM_DESCR:"Potable Water Maker & Rehardener",WD_PLAN:"2021-10-21",A_OV_TOT:151,A_OV_COM:34,A_OV_REM:117,A_OV_PROG:22.51,A_OV_STATUS:"less than 70%",A_PG:22.51,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:23,PA_OV_COM:15,PA_OV_REM:8,PB1_OV_TOT:8,PB1_OV_COM:2,PB1_OV_REM:6},
    {SUBSYSTEM:"21-8000-8011-01",SUBSYSTEM_DESCR:"Potable Water Tanks & Pumps",WD_PLAN:"2021-10-21",A_OV_TOT:240,A_OV_COM:76,A_OV_REM:164,A_OV_PROG:31.66,A_OV_STATUS:"less than 70%",A_PG:31.66,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:2,PA_OV_REM:1},
    {SUBSYSTEM:"21-8000-8011-02",SUBSYSTEM_DESCR:"Fresh Water Bunkering Header",WD_PLAN:"2021-10-21",A_OV_TOT:36,A_OV_COM:11,A_OV_REM:25,A_OV_PROG:30.55,A_OV_STATUS:"less than 70%",A_PG:30.55,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3"},
    {SUBSYSTEM:"21-8000-8012-02",SUBSYSTEM_DESCR:"Main Distribution & Potable Water Cooler",WD_PLAN:"2021-10-21",A_OV_TOT:83,A_OV_COM:28,A_OV_REM:55,A_OV_PROG:33.73,A_OV_STATUS:"less than 70%",A_PG:33.73,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PA_OV_TOT:6,PA_OV_COM:6,PA_OV_REM:0},
    {SUBSYSTEM:"21-8000-8014-04",SUBSYSTEM_DESCR:"Fresh Service Water Distribution to Water Mist System 21-SWM-613001",WD_PLAN:"2021-10-21",A_OV_TOT:13,A_OV_COM:3,A_OV_REM:10,A_OV_PROG:23.07,A_OV_STATUS:"less than 70%",A_PG:23.07,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-8020-8020-03",SUBSYSTEM_DESCR:"Marine Sea Water Main Distribution Header",WD_PLAN:"2021-10-21",A_OV_TOT:36,A_OV_COM:1,A_OV_REM:35,A_OV_PROG:2.77,A_OV_STATUS:"less than 70%",A_PG:2.77,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1A"},
    {SUBSYSTEM:"21-8020-8027-01",SUBSYSTEM_DESCR:"HULL Service Sea Water Booster Pumps - 21-P-802008A/B",WD_PLAN:"2021-10-21",A_OV_TOT:99,A_OV_COM:14,A_OV_REM:85,A_OV_PROG:14.14,A_OV_STATUS:"less than 70%",A_PG:14.14,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",PA_OV_TOT:4,PA_OV_COM:4,PA_OV_REM:0},
    {SUBSYSTEM:"21-8020-8029-01",SUBSYSTEM_DESCR:"KSG Cooling Sea Water Pumps 21-P-802009A/B & Distribution",WD_PLAN:"2021-10-21",A_OV_TOT:97,A_OV_COM:10,A_OV_REM:87,A_OV_PROG:10.3,A_OV_STATUS:"less than 70%",A_PG:10.3,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1A",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1},
    {SUBSYSTEM:"21-8060-8062-01",SUBSYSTEM_DESCR:"Diesel Service Tanks - 21-T-806003/4 & Supply Pumps - 21-P-806001A/B",WD_PLAN:"2021-10-21",A_OV_TOT:251,A_OV_COM:87,A_OV_REM:164,A_OV_PROG:34.66,A_OV_STATUS:"less than 70%",A_PG:34.66,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1},
    {SUBSYSTEM:"21-8100-8120-02",SUBSYSTEM_DESCR:"HULL Air Drier 21-Z-812020",WD_PLAN:"2021-10-21",A_OV_TOT:25,A_OV_COM:15,A_OV_REM:10,A_OV_PROG:60,A_OV_STATUS:"less than 70%",A_PG:60,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2"},
    {SUBSYSTEM:"21-8700-8760-01",SUBSYSTEM_DESCR:"Topside Normal 690 V Swicthboard 21-ESB-876001",WD_PLAN:"2021-10-21",A_OV_TOT:152,A_OV_COM:83,A_OV_REM:69,A_OV_PROG:54.6,A_OV_STATUS:"less than 70%",A_PG:54.6,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:5,PB1_OV_COM:2,PB1_OV_REM:3},
    {SUBSYSTEM:"21-8700-8770-01",SUBSYSTEM_DESCR:"Topsides Main Normal HV Switchboard 6.6kV 21-ESB-877001",WD_PLAN:"2021-10-21",A_OV_TOT:103,A_OV_COM:19,A_OV_REM:84,A_OV_PROG:18.44,A_OV_STATUS:"less than 70%",A_PG:18.44,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:8,PA_OV_COM:6,PA_OV_REM:2,PB1_OV_TOT:10,PB1_OV_COM:0,PB1_OV_REM:10},
    {SUBSYSTEM:"21-9970-9971-10",SUBSYSTEM_DESCR:"HULL Diesel Oil Pump Room Supply Fans, Ducting & Dampers",WD_PLAN:"2021-10-21",A_OV_TOT:80,A_OV_COM:24,A_OV_REM:56,A_OV_PROG:30,A_OV_STATUS:"less than 70%",A_PG:30,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3},
    {SUBSYSTEM:"21-6030-6032-04",SUBSYSTEM_DESCR:"ICSS Workstations & Consoles - CCR",WD_PLAN:"2021-10-28",A_OV_TOT:66,A_OV_COM:0,A_OV_REM:66,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6030-6039-01",SUBSYSTEM_DESCR:"Ballast/Void Gas Sampling",WD_PLAN:"2021-10-28",A_OV_TOT:59,A_OV_COM:12,A_OV_REM:47,A_OV_PROG:20.33,A_OV_STATUS:"less than 70%",A_PG:20.33,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1},
    {SUBSYSTEM:"21-6030-6043-02",SUBSYSTEM_DESCR:"FOPPs & Associated Fiber Cables to CER",WD_PLAN:"2021-10-28",A_OV_TOT:25,A_OV_COM:0,A_OV_REM:25,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6035-6035-01",SUBSYSTEM_DESCR:"Process CCTV Cameras, Consoles & Screens",WD_PLAN:"2021-10-28",A_OV_TOT:317,A_OV_COM:4,A_OV_REM:313,A_OV_PROG:1.26,A_OV_STATUS:"less than 70%",A_PG:1.26,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:5,PB1_OV_COM:0,PB1_OV_REM:5},
    {SUBSYSTEM:"21-6070-6072-11",SUBSYSTEM_DESCR:"Fire Addressable Panels  and - Addressable F&G Detectors - Topsides ME05",WD_PLAN:"2021-10-28",A_OV_TOT:304,A_OV_COM:0,A_OV_REM:304,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6070-6073-04",SUBSYSTEM_DESCR:"HSSD - LQ (CCR)",WD_PLAN:"2021-10-28",A_OV_TOT:4,A_OV_COM:0,A_OV_REM:4,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6070-6073-05",SUBSYSTEM_DESCR:"HSSD - LQ (CER)",WD_PLAN:"2021-10-28",A_OV_TOT:12,A_OV_COM:0,A_OV_REM:12,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6070-6073-06",SUBSYSTEM_DESCR:"HSSD - LQ (L5 telecom & UPS rooms)",WD_PLAN:"2021-10-28",A_OV_TOT:8,A_OV_COM:0,A_OV_REM:8,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6100-6121-01",SUBSYSTEM_DESCR:"HULL Water Mist System",WD_PLAN:"2021-10-28",A_OV_TOT:97,A_OV_COM:18,A_OV_REM:79,A_OV_PROG:18.55,A_OV_STATUS:"less than 70%",A_PG:18.55,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:7,PA_OV_COM:5,PA_OV_REM:2},
    {SUBSYSTEM:"21-6500-6510-01",SUBSYSTEM_DESCR:"Navigation Lights Control Panel & Associated Lanterns /Foghorns Circuits",WD_PLAN:"2021-10-28",A_OV_TOT:78,A_OV_COM:0,A_OV_REM:78,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:11,PA_OV_COM:8,PA_OV_REM:3},
    {SUBSYSTEM:"21-6500-6520-02",SUBSYSTEM_DESCR:"Helideck Lights Control Panel & Associated Beacons",WD_PLAN:"2021-10-28",A_OV_TOT:219,A_OV_COM:52,A_OV_REM:167,A_OV_PROG:23.74,A_OV_STATUS:"less than 70%",A_PG:23.74,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:14,PA_OV_COM:11,PA_OV_REM:3},
    {SUBSYSTEM:"21-6600-6624-04",SUBSYSTEM_DESCR:"LQ Emergency Lighting Panel & Circuits (Room: L501) 21-ELP-662463",WD_PLAN:"2021-10-28",A_OV_TOT:157,A_OV_COM:12,A_OV_REM:145,A_OV_PROG:7.64,A_OV_STATUS:"less than 70%",A_PG:7.64,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PB1_OV_TOT:1,PB1_OV_COM:1,PB1_OV_REM:0},
    {SUBSYSTEM:"21-6625-8725-01",SUBSYSTEM_DESCR:"Normal / Essential Heat Trace 400V Topsides 21-EDB-667605",WD_PLAN:"2021-10-28",A_OV_TOT:4,A_OV_COM:0,A_OV_REM:4,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P2"},
    {SUBSYSTEM:"21-6800-6850-01",SUBSYSTEM_DESCR:"Fire & Gas Detecion - ME05",WD_PLAN:"2021-10-28",A_OV_TOT:80,A_OV_COM:18,A_OV_REM:62,A_OV_PROG:22.5,A_OV_STATUS:"less than 70%",A_PG:22.5,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-8000-8014-01",SUBSYSTEM_DESCR:"Fresh Service Water Tank & Pumps",WD_PLAN:"2021-10-28",A_OV_TOT:159,A_OV_COM:65,A_OV_REM:94,A_OV_PROG:40.88,A_OV_STATUS:"less than 70%",A_PG:40.88,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0},
    {SUBSYSTEM:"21-8020-8027-03",SUBSYSTEM_DESCR:"HULL Service Sea Water to Potable Water Package",WD_PLAN:"2021-10-28",A_OV_TOT:11,A_OV_COM:1,A_OV_REM:10,A_OV_PROG:9.09,A_OV_STATUS:"less than 70%",A_PG:9.09,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-9970-9971-04",SUBSYSTEM_DESCR:"KSG Room Supply Fans, Ducting & Dampers",WD_PLAN:"2021-10-28",A_OV_TOT:184,A_OV_COM:25,A_OV_REM:159,A_OV_PROG:13.58,A_OV_STATUS:"less than 70%",A_PG:13.58,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2},
    {SUBSYSTEM:"21-9970-9971-05",SUBSYSTEM_DESCR:"KSG Room Exhaust Fans, Ducting & Dampers",WD_PLAN:"2021-10-28",A_OV_TOT:80,A_OV_COM:23,A_OV_REM:57,A_OV_PROG:28.74,A_OV_STATUS:"less than 70%",A_PG:28.74,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2},
    {SUBSYSTEM:"21-6010-6011-03",SUBSYSTEM_DESCR:"SIS Marshalling - Topsides (ME05 / LER)",WD_PLAN:"2021-11-04",A_OV_TOT:61,A_OV_COM:0,A_OV_REM:61,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6010-6012-01",SUBSYSTEM_DESCR:"SIS Remote IOs (incl Junction Box & Multicore Cables) ME01",WD_PLAN:"2021-11-04",A_OV_TOT:12,A_OV_COM:0,A_OV_REM:12,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6020-6021-03",SUBSYSTEM_DESCR:"PAS Marshalling - Topsides (ME05 / LER)",WD_PLAN:"2021-11-04",A_OV_TOT:121,A_OV_COM:0,A_OV_REM:121,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6020-6022-01",SUBSYSTEM_DESCR:"PAS Remote IOs(incl Junction Box & Multicore Cables) ME01",WD_PLAN:"2021-11-04",A_OV_TOT:18,A_OV_COM:0,A_OV_REM:18,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6030-6032-02",SUBSYSTEM_DESCR:"ICSS Interface Servers, Workstations & Network Switches LER",WD_PLAN:"2021-11-04",A_OV_TOT:25,A_OV_COM:0,A_OV_REM:25,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6030-6036-04",SUBSYSTEM_DESCR:"PDBs Inside ME05",WD_PLAN:"2021-11-04",A_OV_TOT:24,A_OV_COM:0,A_OV_REM:24,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6070-6071-03",SUBSYSTEM_DESCR:"FGS Marshalling - Topsides (ME05 / LER)",WD_PLAN:"2021-11-04",A_OV_TOT:35,A_OV_COM:0,A_OV_REM:35,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6070-6071-04",SUBSYSTEM_DESCR:"FGS Multicore & Junction Box to Marshalling / System Cabinets in LER",WD_PLAN:"2021-11-04",A_OV_TOT:9,A_OV_COM:0,A_OV_REM:9,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6070-6073-07",SUBSYSTEM_DESCR:"HSSD - Topsides (ME05 / LER)",WD_PLAN:"2021-11-04",A_OV_TOT:36,A_OV_COM:0,A_OV_REM:36,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6070-6074-01",SUBSYSTEM_DESCR:"FGS Remote IOs (incl Junction Box & Multicore Cables) ME01",WD_PLAN:"2021-11-04",A_OV_TOT:22,A_OV_COM:0,A_OV_REM:22,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6600-6670-01",SUBSYSTEM_DESCR:"Key service Generator 21-G-667001",WD_PLAN:"2021-11-04",A_OV_TOT:166,A_OV_COM:66,A_OV_REM:100,A_OV_PROG:39.75,A_OV_STATUS:"less than 70%",A_PG:39.75,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1A",PA_OV_TOT:33,PA_OV_COM:13,PA_OV_REM:20,PB1_OV_TOT:10,PB1_OV_COM:0,PB1_OV_REM:10},
    {SUBSYSTEM:"21-6600-6670-02",SUBSYSTEM_DESCR:"Key service Generator 21-G-667002",WD_PLAN:"2021-11-04",A_OV_TOT:162,A_OV_COM:64,A_OV_REM:98,A_OV_PROG:39.5,A_OV_STATUS:"less than 70%",A_PG:39.5,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1A",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PB1_OV_TOT:11,PB1_OV_COM:0,PB1_OV_REM:11},
    {SUBSYSTEM:"21-9970-9970-01",SUBSYSTEM_DESCR:"HULL Emergency Pressurisation Fans",WD_PLAN:"2021-11-04",A_OV_TOT:180,A_OV_COM:71,A_OV_REM:109,A_OV_PROG:39.44,A_OV_STATUS:"less than 70%",A_PG:39.44,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P3",PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2},
    {SUBSYSTEM:"21-9970-9971-07",SUBSYSTEM_DESCR:"HULL Chiller Exhaust Fans, Ducting & Dampers",WD_PLAN:"2021-11-04",A_OV_TOT:58,A_OV_COM:3,A_OV_REM:55,A_OV_PROG:5.17,A_OV_STATUS:"less than 70%",A_PG:5.17,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2},
    {SUBSYSTEM:"21-9970-9971-08",SUBSYSTEM_DESCR:"HULL Machinery Space AHU Air Supply",WD_PLAN:"2021-11-04",A_OV_TOT:425,A_OV_COM:51,A_OV_REM:374,A_OV_PROG:12,A_OV_STATUS:"less than 70%",A_PG:12,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:10,PA_OV_COM:1,PA_OV_REM:9,PB1_OV_TOT:4,PB1_OV_COM:0,PB1_OV_REM:4},
    {SUBSYSTEM:"21-9970-9971-09",SUBSYSTEM_DESCR:"HULL Machinery Space AHU Recirculation Air",WD_PLAN:"2021-11-04",A_OV_TOT:227,A_OV_COM:44,A_OV_REM:183,A_OV_PROG:19.38,A_OV_STATUS:"less than 70%",A_PG:19.38,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:4,PB1_OV_COM:0,PB1_OV_REM:4},
    {SUBSYSTEM:"21-9970-9971-12",SUBSYSTEM_DESCR:"HULL Machinery Space HPU AHU, Ducting & Damper",WD_PLAN:"2021-11-04",A_OV_TOT:117,A_OV_COM:58,A_OV_REM:59,A_OV_PROG:49.57,A_OV_STATUS:"less than 70%",A_PG:49.57,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:5,PA_OV_COM:0,PA_OV_REM:5,PB1_OV_TOT:8,PB1_OV_COM:0,PB1_OV_REM:8},
    {SUBSYSTEM:"21-2400-9361-01",SUBSYSTEM_DESCR:"COW1 Tank & Pumps (Incl Washing Machine)",WD_PLAN:"2021-11-11",A_OV_TOT:223,A_OV_COM:80,A_OV_REM:143,A_OV_PROG:35.87,A_OV_STATUS:"less than 70%",A_PG:35.87,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2"},
    {SUBSYSTEM:"21-2400-9362-01",SUBSYSTEM_DESCR:"COW2 Tank & Pumps (Incl Washing Machine)",WD_PLAN:"2021-11-11",A_OV_TOT:224,A_OV_COM:79,A_OV_REM:145,A_OV_PROG:35.26,A_OV_STATUS:"less than 70%",A_PG:35.26,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2"},
    {SUBSYSTEM:"21-2400-9363-01",SUBSYSTEM_DESCR:"COW3 Tank & Pumps (Incl Washing Machine)",WD_PLAN:"2021-11-11",A_OV_TOT:219,A_OV_COM:77,A_OV_REM:142,A_OV_PROG:35.15,A_OV_STATUS:"less than 70%",A_PG:35.15,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2"},
    {SUBSYSTEM:"21-2400-9364-01",SUBSYSTEM_DESCR:"COW4 Tank & Pumps (Incl Washing Machine)",WD_PLAN:"2021-11-11",A_OV_TOT:218,A_OV_COM:90,A_OV_REM:128,A_OV_PROG:41.28,A_OV_STATUS:"less than 70%",A_PG:41.28,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2"},
    {SUBSYSTEM:"21-6010-6011-04",SUBSYSTEM_DESCR:"CAP Panel in CCR",WD_PLAN:"2021-11-11",A_OV_TOT:23,A_OV_COM:0,A_OV_REM:23,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6010-6012-02",SUBSYSTEM_DESCR:"SIS Remote IOs (incl Junction Box & Multicore Cables) MW01",WD_PLAN:"2021-11-11",A_OV_TOT:18,A_OV_COM:0,A_OV_REM:18,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6010-6012-03",SUBSYSTEM_DESCR:"SIS Remote IOs (incl Junction Box & Multicore Cables) ME02",WD_PLAN:"2021-11-11",A_OV_TOT:18,A_OV_COM:0,A_OV_REM:18,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6010-6012-04",SUBSYSTEM_DESCR:"SIS Remote IOs (incl Junction Box & Multicore Cables) MW02",WD_PLAN:"2021-11-11",A_OV_TOT:24,A_OV_COM:0,A_OV_REM:24,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6020-6022-02",SUBSYSTEM_DESCR:"PAS Remote IOs (incl Junction Box & Multicore Cables) MW01",WD_PLAN:"2021-11-11",A_OV_TOT:30,A_OV_COM:0,A_OV_REM:30,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6020-6022-03",SUBSYSTEM_DESCR:"PAS Remote IOs (incl Junction Box & Multicore Cables) ME02",WD_PLAN:"2021-11-11",A_OV_TOT:24,A_OV_COM:0,A_OV_REM:24,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6020-6022-04",SUBSYSTEM_DESCR:"PAS Remote IOs (incl Junction Box & Multicore Cables) MW02",WD_PLAN:"2021-11-11",A_OV_TOT:30,A_OV_COM:0,A_OV_REM:30,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6070-6074-02",SUBSYSTEM_DESCR:"FGS Remote IOs (incl Junction Box & Multicore Cables) MW01",WD_PLAN:"2021-11-11",A_OV_TOT:22,A_OV_COM:0,A_OV_REM:22,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6070-6074-03",SUBSYSTEM_DESCR:"FGS Remote IOs (incl Junction Box & Multicore Cables) ME02",WD_PLAN:"2021-11-11",A_OV_TOT:18,A_OV_COM:0,A_OV_REM:18,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6070-6074-04",SUBSYSTEM_DESCR:"FGS Remote IOs (incl Junction Box & Multicore Cables) MW02",WD_PLAN:"2021-11-11",A_OV_TOT:27,A_OV_COM:0,A_OV_REM:27,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6100-6140-01",SUBSYSTEM_DESCR:"Foam Package",WD_PLAN:"2021-11-11",A_OV_TOT:76,A_OV_COM:28,A_OV_REM:48,A_OV_PROG:36.84,A_OV_STATUS:"less than 70%",A_PG:36.84,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:11,PA_OV_COM:9,PA_OV_REM:2},
    {SUBSYSTEM:"21-6800-6840-03",SUBSYSTEM_DESCR:"Fire Zone 6840-3 - Gas Turbine Generator Package 21-Z-861101",WD_PLAN:"2021-11-11",A_OV_TOT:2,A_OV_COM:0,A_OV_REM:2,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6800-6840-04",SUBSYSTEM_DESCR:"Fire Zone 6840-4 - Gas Turbine Generator Package 21-Z-861201",WD_PLAN:"2021-11-11",A_OV_TOT:2,A_OV_COM:0,A_OV_REM:2,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-8000-8014-05",SUBSYSTEM_DESCR:"Fresh Service Water Distribution to LQ",WD_PLAN:"2021-11-11",A_OV_TOT:75,A_OV_COM:31,A_OV_REM:44,A_OV_PROG:41.33,A_OV_STATUS:"less than 70%",A_PG:41.33,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2"},
    {SUBSYSTEM:"21-8130-8131-01",SUBSYSTEM_DESCR:"Fuel Gas Blanketing Distribution in HULL",WD_PLAN:"2021-11-11",A_OV_TOT:261,A_OV_COM:142,A_OV_REM:119,A_OV_PROG:54.4,A_OV_STATUS:"less than 70%",A_PG:54.4,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3"},
    {SUBSYSTEM:"21-9330-9334-01",SUBSYSTEM_DESCR:"HULL Valves HPU",WD_PLAN:"2021-11-11",A_OV_TOT:80,A_OV_COM:0,A_OV_REM:80,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1A"},
    {SUBSYSTEM:"21-6010-6012-06",SUBSYSTEM_DESCR:"SIS Remote IOs (incl Junction Box & Multicore Cables) MW04",WD_PLAN:"2021-11-18",A_OV_TOT:12,A_OV_COM:0,A_OV_REM:12,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6010-6012-08",SUBSYSTEM_DESCR:"SIS Remote IOs (incl Junction Box & Multicore Cables) ME04",WD_PLAN:"2021-11-18",A_OV_TOT:6,A_OV_COM:0,A_OV_REM:6,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6020-6022-06",SUBSYSTEM_DESCR:"PAS Remote IOs (incl Junction Box & Multicore Cables) MW04",WD_PLAN:"2021-11-18",A_OV_TOT:7,A_OV_COM:0,A_OV_REM:7,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6030-6036-05",SUBSYSTEM_DESCR:"PDBs Inside L5 Telecom Room",WD_PLAN:"2021-11-18",A_OV_TOT:10,A_OV_COM:2,A_OV_REM:8,A_OV_PROG:20,A_OV_STATUS:"less than 70%",A_PG:20,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6030-6043-03",SUBSYSTEM_DESCR:"FOPPs & Associated Fiber Cables to HER",WD_PLAN:"2021-11-18",A_OV_TOT:21,A_OV_COM:0,A_OV_REM:21,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6070-6071-05",SUBSYSTEM_DESCR:"FGS Multicore & Junction Box to Marshalling / System Cabinets in CER",WD_PLAN:"2021-11-18",A_OV_TOT:25,A_OV_COM:3,A_OV_REM:22,A_OV_PROG:12,A_OV_STATUS:"less than 70%",A_PG:12,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2},
    {SUBSYSTEM:"21-6070-6072-02",SUBSYSTEM_DESCR:"LQ F&G Addressable Loop-2",WD_PLAN:"2021-11-18",A_OV_TOT:370,A_OV_COM:88,A_OV_REM:282,A_OV_PROG:23.78,A_OV_STATUS:"less than 70%",A_PG:23.78,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6070-6074-06",SUBSYSTEM_DESCR:"FGS Remote IOs (incl Junction Box & Multicore Cables) MW04",WD_PLAN:"2021-11-18",A_OV_TOT:16,A_OV_COM:0,A_OV_REM:16,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6200-6210-01",SUBSYSTEM_DESCR:"PAGA System Cabinets & Central Equipment",WD_PLAN:"2021-11-18",A_OV_TOT:52,A_OV_COM:1,A_OV_REM:51,A_OV_PROG:1.92,A_OV_STATUS:"less than 70%",A_PG:1.92,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6800-6840-01",SUBSYSTEM_DESCR:"Fire Zone 6840-1 - Power Generation Module-MW04 (Open Area)",WD_PLAN:"2021-11-18",A_OV_TOT:98,A_OV_COM:10,A_OV_REM:88,A_OV_PROG:10.2,A_OV_STATUS:"less than 70%",A_PG:10.2,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6800-6840-02",SUBSYSTEM_DESCR:"Fire Zone 6840-2 - Power Generation Module- ME04 (Open Area)",WD_PLAN:"2021-11-18",A_OV_TOT:101,A_OV_COM:8,A_OV_REM:93,A_OV_PROG:7.92,A_OV_STATUS:"less than 70%",A_PG:7.92,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-8020-8025-01",SUBSYSTEM_DESCR:"HULL Service Sea Water Pumps - 21-P-802005A/B",WD_PLAN:"2021-11-18",A_OV_TOT:98,A_OV_COM:3,A_OV_REM:95,A_OV_PROG:3.06,A_OV_STATUS:"less than 70%",A_PG:3.06,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2},
    {SUBSYSTEM:"21-9500-9534-01",SUBSYSTEM_DESCR:"Structured Cabling System - LQ Deck-A",WD_PLAN:"2021-11-18",A_OV_TOT:349,A_OV_COM:0,A_OV_REM:349,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-9500-9534-02",SUBSYSTEM_DESCR:"Structured Cabling System - LQ Levels 1,2 & 3",WD_PLAN:"2021-11-18",A_OV_TOT:307,A_OV_COM:0,A_OV_REM:307,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-9500-9534-03",SUBSYSTEM_DESCR:"Structured Cabling System - LQ Levels 4 & 5",WD_PLAN:"2021-11-18",A_OV_TOT:244,A_OV_COM:0,A_OV_REM:244,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-9500-9534-04",SUBSYSTEM_DESCR:"Structured Cabling System - Machinery Space",WD_PLAN:"2021-11-18",A_OV_TOT:20,A_OV_COM:0,A_OV_REM:20,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-9500-9535-01",SUBSYSTEM_DESCR:"FPSO Fibre Optic Cable",WD_PLAN:"2021-11-18",A_OV_TOT:11,A_OV_COM:0,A_OV_REM:11,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-9970-9971-02",SUBSYSTEM_DESCR:"HULL Machinery Space Supply Fans , Ducting & Dampers",WD_PLAN:"2021-11-18",A_OV_TOT:34,A_OV_COM:2,A_OV_REM:32,A_OV_PROG:5.88,A_OV_STATUS:"less than 70%",A_PG:5.88,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3"},
    {SUBSYSTEM:"21-9970-9971-03",SUBSYSTEM_DESCR:"HULL Machinery Space Exhaust Fans , Ducting & Dampers",WD_PLAN:"2021-11-18",A_OV_TOT:161,A_OV_COM:48,A_OV_REM:113,A_OV_PROG:29.81,A_OV_STATUS:"less than 70%",A_PG:29.81,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:9,PA_OV_COM:0,PA_OV_REM:9,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1},
    {SUBSYSTEM:"21-6020-6022-05",SUBSYSTEM_DESCR:"PAS Remote IOs (incl Junction Box & Multicore Cables) ME04",WD_PLAN:"2021-11-25",A_OV_TOT:12,A_OV_COM:0,A_OV_REM:12,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6030-6032-05",SUBSYSTEM_DESCR:"ICSS Workstations & Consoles - Safe Haven/SOP",WD_PLAN:"2021-11-25",A_OV_TOT:7,A_OV_COM:0,A_OV_REM:7,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6030-6034-01",SUBSYSTEM_DESCR:"Machinery Protection System (MPS)",WD_PLAN:"2021-11-25",A_OV_TOT:16,A_OV_COM:0,A_OV_REM:16,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6070-6072-03",SUBSYSTEM_DESCR:"LQ F&G Addressable Loop-3",WD_PLAN:"2021-11-25",A_OV_TOT:281,A_OV_COM:75,A_OV_REM:206,A_OV_PROG:26.69,A_OV_STATUS:"less than 70%",A_PG:26.69,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2},
    {SUBSYSTEM:"21-6070-6072-04",SUBSYSTEM_DESCR:"LQ F&G Addressable Loop-4",WD_PLAN:"2021-11-25",A_OV_TOT:271,A_OV_COM:90,A_OV_REM:181,A_OV_PROG:33.21,A_OV_STATUS:"less than 70%",A_PG:33.21,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:8,PB1_OV_COM:0,PB1_OV_REM:8},
    {SUBSYSTEM:"21-6070-6072-05",SUBSYSTEM_DESCR:"LQ F&G Addressable Loop-5",WD_PLAN:"2021-11-25",A_OV_TOT:326,A_OV_COM:34,A_OV_REM:292,A_OV_PROG:10.42,A_OV_STATUS:"less than 70%",A_PG:10.42,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6070-6072-06",SUBSYSTEM_DESCR:"LQ F&G Addressable Loop-6",WD_PLAN:"2021-11-25",A_OV_TOT:319,A_OV_COM:45,A_OV_REM:274,A_OV_PROG:14.1,A_OV_STATUS:"less than 70%",A_PG:14.1,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1},
    {SUBSYSTEM:"21-6070-6074-05",SUBSYSTEM_DESCR:"FGS Remote IOs (incl Junction Box & Multicore Cables) ME04",WD_PLAN:"2021-11-25",A_OV_TOT:12,A_OV_COM:0,A_OV_REM:12,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6700-6701-03",SUBSYSTEM_DESCR:"LQ Telecom UPS System A for Topsides (Incl Batteries, Breakers & Subdistribution Board)",WD_PLAN:"2021-11-25",A_OV_TOT:20,A_OV_COM:0,A_OV_REM:20,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2,PB1_OV_TOT:13,PB1_OV_COM:10,PB1_OV_REM:3},
    {SUBSYSTEM:"21-9330-9333-01",SUBSYSTEM_DESCR:"Cargo & Ballast Pumps HPU & UCP",WD_PLAN:"2021-11-25",A_OV_TOT:649,A_OV_COM:67,A_OV_REM:582,A_OV_PROG:10.32,A_OV_STATUS:"less than 70%",A_PG:10.32,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1A",PA_OV_TOT:16,PA_OV_COM:1,PA_OV_REM:15,PB1_OV_TOT:14,PB1_OV_COM:0,PB1_OV_REM:14},
    {SUBSYSTEM:"21-9350-9350-02",SUBSYSTEM_DESCR:"MS Ballast Ring Main",WD_PLAN:"2021-11-25",A_OV_TOT:79,A_OV_COM:0,A_OV_REM:79,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1A"},
    {SUBSYSTEM:"21-9350-9350-04",SUBSYSTEM_DESCR:"MS Ballast Pump 21-P-935002A",WD_PLAN:"2021-11-25",A_OV_TOT:119,A_OV_COM:3,A_OV_REM:116,A_OV_PROG:2.52,A_OV_STATUS:"less than 70%",A_PG:2.52,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-9350-9350-05",SUBSYSTEM_DESCR:"MS Ballast Pump 21-P-935002B",WD_PLAN:"2021-11-25",A_OV_TOT:80,A_OV_COM:4,A_OV_REM:76,A_OV_PROG:5,A_OV_STATUS:"less than 70%",A_PG:5,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-9976-9976-01",SUBSYSTEM_DESCR:"Topsides Emergency Pressurisation Fans",WD_PLAN:"2021-11-25",A_OV_TOT:75,A_OV_COM:3,A_OV_REM:72,A_OV_PROG:4,A_OV_STATUS:"less than 70%",A_PG:4,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P2",PB1_OV_TOT:4,PB1_OV_COM:3,PB1_OV_REM:1},
    {SUBSYSTEM:"21-9976-9976-02",SUBSYSTEM_DESCR:"Topsides Battery Exhaust Fans, Ducting & Dampers",WD_PLAN:"2021-11-25",A_OV_TOT:65,A_OV_COM:2,A_OV_REM:63,A_OV_PROG:3.07,A_OV_STATUS:"less than 70%",A_PG:3.07,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2",PB1_OV_TOT:3,PB1_OV_COM:3,PB1_OV_REM:0},
    {SUBSYSTEM:"21-9976-9976-03",SUBSYSTEM_DESCR:"Emergency Room ACUs/ACCUs & UCPs",WD_PLAN:"2021-11-25",A_OV_TOT:58,A_OV_COM:1,A_OV_REM:57,A_OV_PROG:1.72,A_OV_STATUS:"less than 70%",A_PG:1.72,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:5,PA_OV_COM:3,PA_OV_REM:2,PB1_OV_TOT:2,PB1_OV_COM:1,PB1_OV_REM:1},
    {SUBSYSTEM:"21-9976-9977-01",SUBSYSTEM_DESCR:"Topsides HVAC UCPs, EDBs, VSDs",WD_PLAN:"2021-11-25",A_OV_TOT:37,A_OV_COM:0,A_OV_REM:37,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-9976-9977-02",SUBSYSTEM_DESCR:"Topsides HVAC AHUs, Ducting & Dampers",WD_PLAN:"2021-11-25",A_OV_TOT:513,A_OV_COM:67,A_OV_REM:446,A_OV_PROG:13.06,A_OV_STATUS:"less than 70%",A_PG:13.06,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:6,PA_OV_COM:5,PA_OV_REM:1,PB1_OV_TOT:4,PB1_OV_COM:2,PB1_OV_REM:2},
    {SUBSYSTEM:"21-9976-9977-03",SUBSYSTEM_DESCR:"Topsides HVAC Extracts, Ducting & Dampers",WD_PLAN:"2021-11-25",A_OV_TOT:178,A_OV_COM:8,A_OV_REM:170,A_OV_PROG:4.49,A_OV_STATUS:"less than 70%",A_PG:4.49,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2"},
    {SUBSYSTEM:"21-6500-6550-01",SUBSYSTEM_DESCR:"RADAR Vessel Early Warning System",WD_PLAN:"2021-12-02",A_OV_TOT:58,A_OV_COM:2,A_OV_REM:56,A_OV_PROG:3.44,A_OV_STATUS:"less than 70%",A_PG:3.44,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6800-6840-05",SUBSYSTEM_DESCR:"Fire Zone 6840-5 - Gas Turbine Generator Package 21-Z-861301",WD_PLAN:"2021-12-02",A_OV_TOT:2,A_OV_COM:0,A_OV_REM:2,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6800-6840-06",SUBSYSTEM_DESCR:"Fire Zone 6840-6 - Gas Turbine Generator Package 21-Z-861401",WD_PLAN:"2021-12-02",A_OV_TOT:2,A_OV_COM:0,A_OV_REM:2,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-9220-9224-01",SUBSYSTEM_DESCR:"Entertainment System",WD_PLAN:"2021-12-02",A_OV_TOT:892,A_OV_COM:0,A_OV_REM:892,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-9350-9350-01",SUBSYSTEM_DESCR:"MS Ballast Tanks WBW6,7 / WBE6,7 / FPE1 /FPW1",WD_PLAN:"2021-12-02",A_OV_TOT:115,A_OV_COM:42,A_OV_REM:73,A_OV_PROG:36.52,A_OV_STATUS:"less than 70%",A_PG:36.52,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1A"},
    {SUBSYSTEM:"21-9500-9534-05",SUBSYSTEM_DESCR:"Structured Cabling System - ME05",WD_PLAN:"2021-12-02",A_OV_TOT:18,A_OV_COM:0,A_OV_REM:18,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-9500-9544-01",SUBSYSTEM_DESCR:"Marine & Security CCTV - LQ",WD_PLAN:"2021-12-02",A_OV_TOT:162,A_OV_COM:4,A_OV_REM:158,A_OV_PROG:2.46,A_OV_STATUS:"less than 70%",A_PG:2.46,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3},
    {SUBSYSTEM:"21-9500-9554-01",SUBSYSTEM_DESCR:"Meterological METOCEAN System",WD_PLAN:"2021-12-02",A_OV_TOT:61,A_OV_COM:0,A_OV_REM:61,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-9500-9555-01",SUBSYSTEM_DESCR:"Berthing Aids & Approach System",WD_PLAN:"2021-12-02",A_OV_TOT:52,A_OV_COM:0,A_OV_REM:52,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-9500-9564-01",SUBSYSTEM_DESCR:"Access Control System & Emergency Mustering System",WD_PLAN:"2021-12-02",A_OV_TOT:579,A_OV_COM:4,A_OV_REM:575,A_OV_PROG:0.69,A_OV_STATUS:"less than 70%",A_PG:0.69,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:3,PB1_OV_COM:1,PB1_OV_REM:2},
    {SUBSYSTEM:"21-9921-9921-01",SUBSYSTEM_DESCR:"LQ Emergency Pressurization Fans",WD_PLAN:"2021-12-02",A_OV_TOT:70,A_OV_COM:0,A_OV_REM:70,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2},
    {SUBSYSTEM:"21-9921-9922-01",SUBSYSTEM_DESCR:"LQ HVAC UCPs, EDBs, VSDs",WD_PLAN:"2021-12-02",A_OV_TOT:187,A_OV_COM:34,A_OV_REM:153,A_OV_PROG:18.18,A_OV_STATUS:"less than 70%",A_PG:18.18,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:5,PA_OV_COM:0,PA_OV_REM:5,PB1_OV_TOT:10,PB1_OV_COM:1,PB1_OV_REM:9},
    {SUBSYSTEM:"21-9921-9922-02",SUBSYSTEM_DESCR:"LQ HVAC AHUs, Ducting & Dampers",WD_PLAN:"2021-12-02",A_OV_TOT:1801,A_OV_COM:471,A_OV_REM:1330,A_OV_PROG:26.15,A_OV_STATUS:"less than 70%",A_PG:26.15,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:32,PA_OV_COM:0,PA_OV_REM:32,PB1_OV_TOT:12,PB1_OV_COM:0,PB1_OV_REM:12},
    {SUBSYSTEM:"21-9921-9922-03",SUBSYSTEM_DESCR:"LQ HVAC Extracts, Ducting & Dampers",WD_PLAN:"2021-12-02",A_OV_TOT:614,A_OV_COM:272,A_OV_REM:342,A_OV_PROG:44.29,A_OV_STATUS:"less than 70%",A_PG:44.29,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:5,PA_OV_COM:0,PA_OV_REM:5,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1},
    {SUBSYSTEM:"21-9921-9922-07",SUBSYSTEM_DESCR:"LQ Toilet Exhaust Fans, Ducting & Dampers",WD_PLAN:"2021-12-02",A_OV_TOT:166,A_OV_COM:53,A_OV_REM:113,A_OV_PROG:31.92,A_OV_STATUS:"less than 70%",A_PG:31.92,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:3,PA_OV_COM:0,PA_OV_REM:3,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1},
    {SUBSYSTEM:"21-9921-9922-08",SUBSYSTEM_DESCR:"LQ Laundry Exhaust Fans, Ducting & Dampers",WD_PLAN:"2021-12-02",A_OV_TOT:68,A_OV_COM:8,A_OV_REM:60,A_OV_PROG:11.76,A_OV_STATUS:"less than 70%",A_PG:11.76,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2"},
    {SUBSYSTEM:"21-9921-9922-12",SUBSYSTEM_DESCR:"LQ Provision Plant Exhaust Fans, Ducting & Dampers",WD_PLAN:"2021-12-02",A_OV_TOT:54,A_OV_COM:2,A_OV_REM:52,A_OV_PROG:3.7,A_OV_STATUS:"less than 70%",A_PG:3.7,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1},
    {SUBSYSTEM:"21-9921-9927-01",SUBSYSTEM_DESCR:"Cool Store System in Galley",WD_PLAN:"2021-12-02",A_OV_TOT:145,A_OV_COM:1,A_OV_REM:144,A_OV_PROG:0.68,A_OV_STATUS:"less than 70%",A_PG:0.68,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:8,PA_OV_COM:0,PA_OV_REM:8},
    {SUBSYSTEM:"21-2500-2510-02",SUBSYSTEM_DESCR:"MEG Transfer Header",WD_PLAN:"2021-12-09",A_OV_TOT:65,A_OV_COM:12,A_OV_REM:53,A_OV_PROG:18.46,A_OV_STATUS:"less than 70%",A_PG:18.46,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2"},
    {SUBSYSTEM:"21-2600-2630-01",SUBSYSTEM_DESCR:"Methanol Storage & Pumps",WD_PLAN:"2021-12-09",A_OV_TOT:142,A_OV_COM:30,A_OV_REM:112,A_OV_PROG:21.12,A_OV_STATUS:"less than 70%",A_PG:21.12,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2"},
    {SUBSYSTEM:"21-6070-6072-09",SUBSYSTEM_DESCR:"Machinery Space F&G  Addressable Loop-2",WD_PLAN:"2021-12-09",A_OV_TOT:261,A_OV_COM:205,A_OV_REM:56,A_OV_PROG:78.54,A_OV_STATUS:"greater than 70%",A_PG:78.54,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:3,PA_OV_COM:0,PA_OV_REM:3,PB1_OV_TOT:20,PB1_OV_COM:0,PB1_OV_REM:20},
    {SUBSYSTEM:"21-6070-6072-10",SUBSYSTEM_DESCR:"Machinery Space F&G  Addressable Loop-3",WD_PLAN:"2021-12-09",A_OV_TOT:66,A_OV_COM:41,A_OV_REM:25,A_OV_PROG:62.12,A_OV_STATUS:"less than 70%",A_PG:62.12,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:5,PB1_OV_COM:0,PB1_OV_REM:5},
    {SUBSYSTEM:"21-6800-6830-01",SUBSYSTEM_DESCR:"Fire & Gas Detecion - LQ",WD_PLAN:"2021-12-09",A_OV_TOT:296,A_OV_COM:65,A_OV_REM:231,A_OV_PROG:21.95,A_OV_STATUS:"less than 70%",A_PG:21.95,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6800-6841-02",SUBSYSTEM_DESCR:"Fire Zone 6841-1 - Flash Gas Compression Module - ME01",WD_PLAN:"2021-12-09",A_OV_TOT:261,A_OV_COM:30,A_OV_REM:231,A_OV_PROG:11.49,A_OV_STATUS:"less than 70%",A_PG:11.49,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3"},
    {SUBSYSTEM:"21-6800-6841-03",SUBSYSTEM_DESCR:"Fire Zone 6841-2 - Gas Dewpointing /MEG & Methanol injection / Fuel gas Module - MW02",WD_PLAN:"2021-12-09",A_OV_TOT:355,A_OV_COM:46,A_OV_REM:309,A_OV_PROG:12.95,A_OV_STATUS:"less than 70%",A_PG:12.95,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3"},
    {SUBSYSTEM:"21-8000-8012-01",SUBSYSTEM_DESCR:"UV Sterilizer",WD_PLAN:"2021-12-09",A_OV_TOT:37,A_OV_COM:8,A_OV_REM:29,A_OV_PROG:21.62,A_OV_STATUS:"less than 70%",A_PG:21.62,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:2,PA_OV_COM:0,PA_OV_REM:2},
    {SUBSYSTEM:"21-8000-8014-02",SUBSYSTEM_DESCR:"Fresh Service Water Distribution to HULL",WD_PLAN:"2021-12-09",A_OV_TOT:15,A_OV_COM:1,A_OV_REM:14,A_OV_PROG:6.66,A_OV_STATUS:"less than 70%",A_PG:6.66,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2"},
    {SUBSYSTEM:"21-8000-8014-03",SUBSYSTEM_DESCR:"Fresh Service Water Distribution to Topsides",WD_PLAN:"2021-12-09",A_OV_TOT:714,A_OV_COM:607,A_OV_REM:107,A_OV_PROG:85.01,A_OV_STATUS:"greater than 80%",A_PG:85.01,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2"},
    {SUBSYSTEM:"21-8090-8090-01",SUBSYSTEM_DESCR:"Sewage Treatment Package",WD_PLAN:"2021-12-09",A_OV_TOT:21,A_OV_COM:3,A_OV_REM:18,A_OV_PROG:14.28,A_OV_STATUS:"less than 70%",A_PG:14.28,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:16,PA_OV_COM:5,PA_OV_REM:11,PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2},
    {SUBSYSTEM:"21-8090-8090-02",SUBSYSTEM_DESCR:"Sewage Collection & Discharge Headers",WD_PLAN:"2021-12-09",A_OV_TOT:396,A_OV_COM:93,A_OV_REM:303,A_OV_PROG:23.48,A_OV_STATUS:"less than 70%",A_PG:23.48,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3"},
    {SUBSYSTEM:"21-9370-9371-01",SUBSYSTEM_DESCR:"Slops Tank 21-T-937002 & Pumps 21-P-937002/4",WD_PLAN:"2021-12-09",A_OV_TOT:361,A_OV_COM:67,A_OV_REM:294,A_OV_PROG:18.55,A_OV_STATUS:"less than 70%",A_PG:18.55,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-9500-9536-01",SUBSYSTEM_DESCR:"LAN/WAN (Data Network Infrastructure)",WD_PLAN:"2021-12-09",A_OV_TOT:544,A_OV_COM:4,A_OV_REM:540,A_OV_PROG:0.73,A_OV_STATUS:"less than 70%",A_PG:0.73,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3},
    {SUBSYSTEM:"21-9921-9921-02",SUBSYSTEM_DESCR:"LQ Battery Exhaust Fans, Ducting & Dampers",WD_PLAN:"2021-12-09",A_OV_TOT:75,A_OV_COM:2,A_OV_REM:73,A_OV_PROG:2.66,A_OV_STATUS:"less than 70%",A_PG:2.66,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1},
    {SUBSYSTEM:"21-9921-9921-08",SUBSYSTEM_DESCR:"LQ Dining Room / Secondary Muster ACUs/ACCUs & UCPs",WD_PLAN:"2021-12-09",A_OV_TOT:78,A_OV_COM:0,A_OV_REM:78,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-9921-9922-04",SUBSYSTEM_DESCR:"LQ Galley AHUs, Ducting & Dampers",WD_PLAN:"2021-12-09",A_OV_TOT:168,A_OV_COM:18,A_OV_REM:150,A_OV_PROG:10.71,A_OV_STATUS:"less than 70%",A_PG:10.71,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:8,PA_OV_COM:0,PA_OV_REM:8},
    {SUBSYSTEM:"21-9921-9922-15",SUBSYSTEM_DESCR:"LQ Dining Room Exhaust Fan, Ducting & Dampers",WD_PLAN:"2021-12-09",A_OV_TOT:36,A_OV_COM:4,A_OV_REM:32,A_OV_PROG:11.11,A_OV_STATUS:"less than 70%",A_PG:11.11,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2"},
    {SUBSYSTEM:"21-9970-9971-06",SUBSYSTEM_DESCR:"HULL Chilled Water System & UCP",WD_PLAN:"2021-12-09",A_OV_TOT:656,A_OV_COM:180,A_OV_REM:476,A_OV_PROG:27.43,A_OV_STATUS:"less than 70%",A_PG:27.43,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P3",PA_OV_TOT:17,PA_OV_COM:0,PA_OV_REM:17,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1},
    {SUBSYSTEM:"21-6010-6012-07",SUBSYSTEM_DESCR:"SIS Remote IOs (incl Junction Box & Multicore Cables) MW05",WD_PLAN:"2021-12-16",A_OV_TOT:6,A_OV_COM:0,A_OV_REM:6,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6020-6022-07",SUBSYSTEM_DESCR:"PAS Remote IOs (incl Junction Box & Multicore Cables) MW05",WD_PLAN:"2021-12-16",A_OV_TOT:30,A_OV_COM:0,A_OV_REM:30,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6030-6031-01",SUBSYSTEM_DESCR:"Electrical Data Monitoring & Constrol System (EDMCS) & Power Management System (PMS)",WD_PLAN:"2021-12-16",A_OV_TOT:199,A_OV_COM:34,A_OV_REM:165,A_OV_PROG:17.08,A_OV_STATUS:"less than 70%",A_PG:17.08,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:12,PA_OV_COM:10,PA_OV_REM:2,PB1_OV_TOT:6,PB1_OV_COM:0,PB1_OV_REM:6},
    {SUBSYSTEM:"21-6070-6074-07",SUBSYSTEM_DESCR:"FGS Remote IOs (incl Junction Box & Multicore Cables) MW05",WD_PLAN:"2021-12-16",A_OV_TOT:12,A_OV_COM:0,A_OV_REM:12,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6100-6110-02",SUBSYSTEM_DESCR:"Accommodation Firewater & Hydrants",WD_PLAN:"2021-12-16",A_OV_TOT:80,A_OV_COM:9,A_OV_REM:71,A_OV_PROG:11.25,A_OV_STATUS:"less than 70%",A_PG:11.25,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2"},
    {SUBSYSTEM:"21-6200-6225-01",SUBSYSTEM_DESCR:"VHF (AM) Aeronautical Radio",WD_PLAN:"2021-12-16",A_OV_TOT:22,A_OV_COM:0,A_OV_REM:22,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6200-6226-01",SUBSYSTEM_DESCR:"Non-Directional Beacon (NDB)",WD_PLAN:"2021-12-16",A_OV_TOT:21,A_OV_COM:0,A_OV_REM:21,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6200-6228-01",SUBSYSTEM_DESCR:"VHF (FM) Marine Radio",WD_PLAN:"2021-12-16",A_OV_TOT:20,A_OV_COM:0,A_OV_REM:20,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-6800-6840-07",SUBSYSTEM_DESCR:"Fire Zone 6840-7 - Utility Module–MW05",WD_PLAN:"2021-12-16",A_OV_TOT:138,A_OV_COM:10,A_OV_REM:128,A_OV_PROG:7.24,A_OV_STATUS:"less than 70%",A_PG:7.24,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-8600-8613-01",SUBSYSTEM_DESCR:"GTG3 & WHRU3",WD_PLAN:"2021-12-16",A_OV_TOT:490,A_OV_COM:130,A_OV_REM:360,A_OV_PROG:26.53,A_OV_STATUS:"less than 70%",A_PG:26.53,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3},
    {SUBSYSTEM:"21-8600-8614-01",SUBSYSTEM_DESCR:"GTG4 & WHRU4",WD_PLAN:"2021-12-16",A_OV_TOT:493,A_OV_COM:126,A_OV_REM:367,A_OV_PROG:25.55,A_OV_STATUS:"less than 70%",A_PG:25.55,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"B1",PRIORITY:"P1",PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1},
    {SUBSYSTEM:"21-9370-9372-09",SUBSYSTEM_DESCR:"Slops Polishing & ODMS (Oil Discharge Monitoring) Units",WD_PLAN:"2021-12-16",A_OV_TOT:89,A_OV_COM:2,A_OV_REM:87,A_OV_PROG:2.24,A_OV_STATUS:"less than 70%",A_PG:2.24,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P2",PA_OV_TOT:17,PA_OV_COM:4,PA_OV_REM:13},
    {SUBSYSTEM:"21-9370-9372-10",SUBSYSTEM_DESCR:"Treated Slops Header to Overboard",WD_PLAN:"2021-12-16",A_OV_TOT:21,A_OV_COM:1,A_OV_REM:20,A_OV_PROG:4.76,A_OV_STATUS:"less than 70%",A_PG:4.76,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P3"},
    {SUBSYSTEM:"21-9500-9514-01",SUBSYSTEM_DESCR:"Telephone System - LQ",WD_PLAN:"2021-12-16",A_OV_TOT:347,A_OV_COM:2,A_OV_REM:345,A_OV_PROG:0.57,A_OV_STATUS:"less than 70%",A_PG:0.57,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"A",PRIORITY:"P1",PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2},
    {SUBSYSTEM:"21-9500-9514-02",SUBSYSTEM_DESCR:"Telephone System - Machinery Space & Topsides",WD_PLAN:"2021-12-16",A_OV_TOT:184,A_OV_COM:0,A_OV_REM:184,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-9500-9515-01",SUBSYSTEM_DESCR:"Hot line Telephones",WD_PLAN:"2021-12-16",A_OV_TOT:15,A_OV_COM:0,A_OV_REM:15,A_OV_PROG:0,A_OV_STATUS:"less than 70%",A_PG:0,MC_STATUS:"Not Started",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-9921-9921-07",SUBSYSTEM_DESCR:"LQ Secondary ICC ACUs/ACCUs & UCPs",WD_PLAN:"2021-12-16",A_OV_TOT:88,A_OV_COM:1,A_OV_REM:87,A_OV_PROG:1.13,A_OV_STATUS:"less than 70%",A_PG:1.13,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P1"},
    {SUBSYSTEM:"21-9921-9922-13",SUBSYSTEM_DESCR:"HULL Purge",WD_PLAN:"2021-12-16",A_OV_TOT:17,A_OV_COM:1,A_OV_REM:16,A_OV_PROG:5.88,A_OV_STATUS:"less than 70%",A_PG:5.88,MC_STATUS:"A ITR in progress",SUBSYSTEM_STATUS:"A ITR in progress",PUNCH_STATUS:"N",PRIORITY:"P2"}
    ],
  Queries: {
    SQL1: [
      { DESC: 'DataSource SPCS Data 03-Mar-20' }
    ],
    SQL2: [
      { CDATE: '2021-10-07' }
    ],
    SQL3: [
      {WD_PLAN:"2021-09-09",A_T:719,A_A:411,A_C:397,A_O:308,A_PG:57.16,A_CUM:411,A_CUM_PG:0.5,PLAN_CUM:65233,ACTUAL_CUM:68508,PA_O:15,PB1_O:110,PB2_O:19,PB3_O:0},
      {WD_PLAN:"2021-09-16",A_T:1085,A_A:411,A_C:277,A_O:674,A_PG:37.88,A_CUM:822,A_CUM_PG:0.99,PLAN_CUM:67518,ACTUAL_CUM:67528,PA_O:20,PB1_O:134,PB2_O:14,PB3_O:0},
      {WD_PLAN:"2021-09-23",PLAN_CUM:67307,ACTUAL_CUM:67838},
      {WD_PLAN:"2021-09-30",A_T:1346,A_A:234,A_C:120,A_O:1112,A_PG:17.38,A_CUM:1056,A_CUM_PG:1.28,PLAN_CUM:66468,ACTUAL_CUM:67195,PA_O:9,PB1_O:27,PB2_O:0,PB3_O:0},
      {WD_PLAN:"2021-10-07",A_T:1204,A_A:412,A_C:70,A_O:792,A_PG:34.22,A_CUM:1468,A_CUM_PG:1.77,PLAN_CUM:65830,ACTUAL_CUM:67425,PA_O:12,PB1_O:23,PB2_O:0,PB3_O:0},
      {WD_PLAN:"2021-10-14",A_T:7273,A_A:373,A_C:19,A_O:6900,A_PG:5.13,A_CUM:1841,A_CUM_PG:2.22,PLAN_CUM:64949,PA_O:7,PB1_O:14,PB2_O:0,PB3_O:0},
      {WD_PLAN:"2021-10-21",A_T:1712,A_A:268,A_C:83,A_O:1444,A_PG:15.65,A_CUM:2109,A_CUM_PG:2.55,PLAN_CUM:63954,PA_O:31,PB1_O:46,PB2_O:0,PB3_O:0},
      {WD_PLAN:"2021-10-28",A_T:1983,A_A:136,A_C:88,A_O:1847,A_PG:6.86,A_CUM:2245,A_CUM_PG:2.71,PLAN_CUM:62909,PA_O:24,PB1_O:5,PB2_O:0,PB3_O:0},
      {WD_PLAN:"2021-11-04",A_T:1769,A_A:158,A_C:28,A_O:1611,A_PG:8.93,A_CUM:2403,A_CUM_PG:2.9,PLAN_CUM:61680,PA_O:41,PB1_O:12,PB2_O:0,PB3_O:0},
      {WD_PLAN:"2021-11-11",A_T:1596,A_A:492,A_C:63,A_O:1104,A_PG:30.83,A_CUM:2895,A_CUM_PG:3.5,PLAN_CUM:60344,PA_O:10,PB1_O:1,PB2_O:0,PB3_O:0},
      {WD_PLAN:"2021-11-18",A_T:1988,A_A:78,A_C:0,A_O:1910,A_PG:3.92,A_CUM:2973,A_CUM_PG:3.59,PLAN_CUM:58904,PA_O:11,PB1_O:0,PB2_O:0,PB3_O:0},
      {WD_PLAN:"2021-11-25",A_T:2944,A_A:266,A_C:3,A_O:2678,A_PG:9.04,A_CUM:3239,A_CUM_PG:3.91,PLAN_CUM:57363,PA_O:22,PB1_O:12,PB2_O:0,PB3_O:0},
      {WD_PLAN:"2021-12-02",A_T:5230,A_A:389,A_C:48,A_O:4841,A_PG:7.44,A_CUM:3628,A_CUM_PG:4.38,PLAN_CUM:55722,PA_O:51,PB1_O:1,PB2_O:0,PB3_O:0},
      {WD_PLAN:"2021-12-09",A_T:4390,A_A:1198,A_C:82,A_O:3192,A_PG:27.29,A_CUM:4826,A_CUM_PG:5.83,PLAN_CUM:53983,PA_O:45,PB1_O:14,PB2_O:0,PB3_O:0},
      {WD_PLAN:"2021-12-16",A_T:2316,A_A:263,A_C:59,A_O:2053,A_PG:11.36,A_CUM:5089,A_CUM_PG:6.15,PLAN_CUM:52150,PA_O:20,PB1_O:10,PB2_O:0,PB3_O:0},
      {WD_PLAN:"2021-12-23",A_T:1857,A_A:577,A_C:197,A_O:1280,A_PG:31.07,A_CUM:5666,A_CUM_PG:6.84,PLAN_CUM:50451,PA_O:5,PB1_O:20,PB2_O:0,PB3_O:0},
      {WD_PLAN:"2021-12-30",A_T:1985,A_A:734,A_C:57,A_O:1251,A_PG:36.98,A_CUM:6400,A_CUM_PG:7.73,PLAN_CUM:48771,PA_O:6,PB1_O:4,PB2_O:0,PB3_O:0},
      {WD_PLAN:"2022-01-06",A_T:1729,A_A:187,A_C:58,A_O:1542,A_PG:10.82,A_CUM:6587,A_CUM_PG:7.96,PLAN_CUM:47164,PA_O:0,PB1_O:1,PB2_O:0,PB3_O:0},
      {WD_PLAN:"2022-01-13",A_T:4102,A_A:1441,A_C:572,A_O:2661,A_PG:35.13,A_CUM:8028,A_CUM_PG:9.7,PLAN_CUM:45521,PA_O:26,PB1_O:17,PB2_O:0,PB3_O:0},
      {WD_PLAN:"2022-01-20",PLAN_CUM:43845},
      {WD_PLAN:"2022-01-27",A_T:433,A_A:31,A_C:2,A_O:402,A_PG:7.16,A_CUM:8059,A_CUM_PG:9.74,PLAN_CUM:42099},
      {WD_PLAN:"2022-02-03",A_T:3927,A_A:596,A_C:68,A_O:3331,A_PG:15.18,A_CUM:8655,A_CUM_PG:10.46,PLAN_CUM:40287,PA_O:13,PB1_O:13,PB2_O:0,PB3_O:1},
      {WD_PLAN:"2022-02-10",A_T:2978,A_A:474,A_C:28,A_O:2504,A_PG:15.92,A_CUM:9129,A_CUM_PG:11.03,PLAN_CUM:38486,PA_O:13,PB1_O:3,PB2_O:0,PB3_O:0},
      {WD_PLAN:"2022-02-17",A_T:1834,A_A:417,A_C:82,A_O:1417,A_PG:22.74,A_CUM:9546,A_CUM_PG:11.53,PLAN_CUM:36561,PA_O:12,PB1_O:1,PB2_O:0,PB3_O:0},
      {WD_PLAN:"2022-02-24",A_T:2172,A_A:482,A_C:100,A_O:1690,A_PG:22.19,A_CUM:10028,A_CUM_PG:12.11,PLAN_CUM:34614,PA_O:3,PB1_O:19,PB2_O:0,PB3_O:0},
      {WD_PLAN:"2022-03-03",A_T:366,A_A:122,A_C:25,A_O:244,A_PG:33.33,A_CUM:10150,A_CUM_PG:12.26,PLAN_CUM:32502,PA_O:1,PB1_O:11,PB2_O:0,PB3_O:0},
      {WD_PLAN:"2022-03-10",A_T:1398,A_A:1067,A_C:288,A_O:331,A_PG:76.32,A_CUM:11217,A_CUM_PG:13.55,PLAN_CUM:30363},
      {WD_PLAN:"2022-03-17",A_T:739,A_A:310,A_C:11,A_O:429,A_PG:41.95,A_CUM:11527,A_CUM_PG:13.92,PLAN_CUM:28142,PA_O:4,PB1_O:2,PB2_O:0,PB3_O:0},
      {WD_PLAN:"2022-03-24",A_T:612,A_A:89,A_C:66,A_O:523,A_PG:14.54,A_CUM:11616,A_CUM_PG:14.03,PLAN_CUM:25895,PA_O:35,PB1_O:9,PB2_O:0,PB3_O:0},
      {WD_PLAN:"2022-03-31",A_T:1857,A_A:540,A_C:6,A_O:1317,A_PG:29.08,A_CUM:12156,A_CUM_PG:14.68,PLAN_CUM:23612,PA_O:19,PB1_O:10,PB2_O:0,PB3_O:0},
      {WD_PLAN:"2022-04-07",A_T:1852,A_A:209,A_C:125,A_O:1643,A_PG:11.29,A_CUM:12365,A_CUM_PG:14.94,PLAN_CUM:21366,PA_O:71,PB1_O:20,PB2_O:0,PB3_O:0},
      {WD_PLAN:"2022-04-14",A_T:1715,A_A:44,A_C:17,A_O:1671,A_PG:2.57,A_CUM:12409,A_CUM_PG:14.99,PLAN_CUM:19302,PA_O:12,PB1_O:6,PB2_O:0,PB3_O:0},
      {WD_PLAN:"2022-04-21",A_T:1489,A_A:195,A_C:7,A_O:1294,A_PG:13.1,A_CUM:12604,A_CUM_PG:15.23,PLAN_CUM:17193,PA_O:2,PB1_O:4,PB2_O:0,PB3_O:0},
      {WD_PLAN:"2022-04-28",A_T:2065,A_A:286,A_C:17,A_O:1779,A_PG:13.85,A_CUM:12890,A_CUM_PG:15.57,PLAN_CUM:15226,PA_O:5,PB1_O:1,PB2_O:0,PB3_O:0},
      {WD_PLAN:"2022-05-05",A_T:3044,A_A:261,A_C:4,A_O:2783,A_PG:8.57,A_CUM:13151,A_CUM_PG:15.89,PLAN_CUM:13403,PA_O:1,PB1_O:0,PB2_O:0,PB3_O:0},
      {WD_PLAN:"2022-05-12",A_T:2805,A_A:722,A_C:10,A_O:2083,A_PG:25.74,A_CUM:13873,A_CUM_PG:16.76,PLAN_CUM:11725,PA_O:6,PB1_O:3,PB2_O:0,PB3_O:0},
      {WD_PLAN:"2022-05-19",A_T:2729,A_A:371,A_C:7,A_O:2358,A_PG:13.59,A_CUM:14244,A_CUM_PG:17.21,PLAN_CUM:10023,PA_O:4,PB1_O:0,PB2_O:0,PB3_O:0},
      {WD_PLAN:"2022-05-26",A_T:840,A_A:84,A_C:0,A_O:756,A_PG:10,A_CUM:14328,A_CUM_PG:17.31,PLAN_CUM:8501,PA_O:17,PB1_O:12,PB2_O:0,PB3_O:0},
      {WD_PLAN:"2022-06-02",A_T:863,A_A:381,A_C:0,A_O:482,A_PG:44.15,A_CUM:14709,A_CUM_PG:17.77,PLAN_CUM:7023},
      {WD_PLAN:"2022-06-09",A_T:417,A_A:104,A_C:2,A_O:313,A_PG:24.94,A_CUM:14813,A_CUM_PG:17.89,PLAN_CUM:5739,PA_O:12,PB1_O:1,PB2_O:0,PB3_O:0},
      {WD_PLAN:"2022-06-16",A_T:106,A_A:4,A_C:0,A_O:102,A_PG:3.77,A_CUM:14817,A_CUM_PG:17.9,PLAN_CUM:4646},
      {WD_PLAN:"2022-06-23",A_T:1526,A_A:274,A_C:4,A_O:1252,A_PG:17.96,A_CUM:15091,A_CUM_PG:18.23,PLAN_CUM:3742,PA_O:3,PB1_O:1,PB2_O:0,PB3_O:0},
      {WD_PLAN:"2022-06-30",A_T:715,A_A:97,A_C:6,A_O:618,A_PG:13.57,A_CUM:15188,A_CUM_PG:18.35,PLAN_CUM:3023,PA_O:4,PB1_O:1,PB2_O:0,PB3_O:0},
      {WD_PLAN:"2022-07-07",A_T:152,A_A:9,A_C:3,A_O:143,A_PG:5.92,A_CUM:15197,A_CUM_PG:18.36,PLAN_CUM:2305},
      {WD_PLAN:"2022-07-14",A_T:144,A_A:22,A_C:3,A_O:122,A_PG:15.28,A_CUM:15219,A_CUM_PG:18.38,PLAN_CUM:1770},
      {WD_PLAN:"2022-07-21",A_T:775,A_A:177,A_C:6,A_O:598,A_PG:22.84,A_CUM:15396,A_CUM_PG:18.6,PLAN_CUM:1416},
      {WD_PLAN:"2022-07-28",PLAN_CUM:1241}
      ],
    SQL4: [
      {CUTOFF:"2018-06-22",WK_NO:"W2018_25"},
      {CUTOFF:"2018-06-29",WK_NO:"W2018_26"},
      {CUTOFF:"2018-07-06",WK_NO:"W2018_27"},
      {CUTOFF:"2018-07-13",WK_NO:"W2018_28"},
      {CUTOFF:"2018-07-20",WK_NO:"W2018_29"},
      {CUTOFF:"2018-07-27",WK_NO:"W2018_30"},
      {CUTOFF:"2018-08-03",WK_NO:"W2018_31"},
      {CUTOFF:"2018-08-10",WK_NO:"W2018_32"},
      {CUTOFF:"2018-08-17",WK_NO:"W2018_33"},
      {CUTOFF:"2018-08-24",WK_NO:"W2018_34"},
      {CUTOFF:"2018-08-31",WK_NO:"W2018_35"},
      {CUTOFF:"2018-09-07",WK_NO:"W2018_36"},
      {CUTOFF:"2018-09-14",WK_NO:"W2018_37"},
      {CUTOFF:"2018-09-21",WK_NO:"W2018_38"},
      {CUTOFF:"2018-09-28",WK_NO:"W2018_39"},
      {CUTOFF:"2018-10-05",WK_NO:"W2018_40"},
      {CUTOFF:"2018-10-12",WK_NO:"W2018_41"},
      {CUTOFF:"2018-10-19",WK_NO:"W2018_42"},
      {CUTOFF:"2018-10-26",WK_NO:"W2018_43"},
      {CUTOFF:"2018-11-02",WK_NO:"W2018_44"},
      {CUTOFF:"2018-11-09",WK_NO:"W2018_45"},
      {CUTOFF:"2018-11-16",WK_NO:"W2018_46"},
      {CUTOFF:"2018-11-23",WK_NO:"W2018_47"},
      {CUTOFF:"2018-11-30",WK_NO:"W2018_48"},
      {CUTOFF:"2018-12-07",WK_NO:"W2018_49"},
      {CUTOFF:"2018-12-14",WK_NO:"W2018_50"},
      {CUTOFF:"2018-12-21",WK_NO:"W2018_51"},
      {CUTOFF:"2018-12-28",WK_NO:"W2018_52"},
      {CUTOFF:"2019-01-04",WK_NO:"W2019_01"},
      {CUTOFF:"2019-01-11",WK_NO:"W2019_02"},
      {CUTOFF:"2019-01-18",WK_NO:"W2019_03"},
      {CUTOFF:"2019-01-25",WK_NO:"W2019_04"},
      {CUTOFF:"2019-02-01",WK_NO:"W2019_05"},
      {CUTOFF:"2019-02-08",WK_NO:"W2019_06"},
      {CUTOFF:"2019-02-15",WK_NO:"W2019_07"},
      {CUTOFF:"2019-02-22",WK_NO:"W2019_08"},
      {CUTOFF:"2019-03-01",WK_NO:"W2019_09"},
      {CUTOFF:"2019-03-08",WK_NO:"W2019_10"},
      {CUTOFF:"2019-03-15",WK_NO:"W2019_11"},
      {CUTOFF:"2019-03-22",WK_NO:"W2019_12"},
      {CUTOFF:"2019-03-29",WK_NO:"W2019_13"},
      {CUTOFF:"2019-04-05",WK_NO:"W2019_14"},
      {CUTOFF:"2019-04-12",WK_NO:"W2019_15"},
      {CUTOFF:"2019-04-19",WK_NO:"W2019_16"},
      {CUTOFF:"2019-04-26",WK_NO:"W2019_17"},
      {CUTOFF:"2019-05-03",WK_NO:"W2019_18"},
      {CUTOFF:"2019-05-10",WK_NO:"W2019_19"},
      {CUTOFF:"2019-05-17",WK_NO:"W2019_20"},
      {CUTOFF:"2019-05-24",WK_NO:"W2019_21"},
      {CUTOFF:"2019-05-31",WK_NO:"W2019_22"},
      {CUTOFF:"2019-06-07",WK_NO:"W2019_23"},
      {CUTOFF:"2019-06-14",WK_NO:"W2019_24"},
      {CUTOFF:"2019-06-21",WK_NO:"W2019_25"},
      {CUTOFF:"2019-06-28",WK_NO:"W2019_26"},
      {CUTOFF:"2019-07-05",WK_NO:"W2019_27"},
      {CUTOFF:"2019-07-12",WK_NO:"W2019_28"},
      {CUTOFF:"2019-07-19",WK_NO:"W2019_29"},
      {CUTOFF:"2019-07-26",WK_NO:"W2019_30"},
      {CUTOFF:"2019-08-02",WK_NO:"W2019_31"},
      {CUTOFF:"2019-08-09",WK_NO:"W2019_32"},
      {CUTOFF:"2019-08-16",WK_NO:"W2019_33"},
      {CUTOFF:"2019-08-23",WK_NO:"W2019_34"},
      {CUTOFF:"2019-08-30",WK_NO:"W2019_35"},
      {CUTOFF:"2019-09-06",WK_NO:"W2019_36"},
      {CUTOFF:"2019-09-13",WK_NO:"W2019_37"},
      {CUTOFF:"2019-09-20",WK_NO:"W2019_38"},
      {CUTOFF:"2019-09-27",WK_NO:"W2019_39"},
      {CUTOFF:"2019-10-04",WK_NO:"W2019_40"},
      {CUTOFF:"2019-10-11",WK_NO:"W2019_41"},
      {CUTOFF:"2019-10-18",WK_NO:"W2019_42"},
      {CUTOFF:"2019-10-25",WK_NO:"W2019_43"},
      {CUTOFF:"2019-11-01",WK_NO:"W2019_44"},
      {CUTOFF:"2019-11-08",WK_NO:"W2019_45"},
      {CUTOFF:"2019-11-15",WK_NO:"W2019_46"},
      {CUTOFF:"2019-11-22",WK_NO:"W2019_47"},
      {CUTOFF:"2019-11-29",WK_NO:"W2019_48"},
      {CUTOFF:"2019-12-06",WK_NO:"W2019_49"},
      {CUTOFF:"2019-12-13",WK_NO:"W2019_50"},
      {CUTOFF:"2019-12-20",WK_NO:"W2019_51"},
      {CUTOFF:"2019-12-27",WK_NO:"W2019_52"},
      {CUTOFF:"2020-01-03",WK_NO:"W2020_01"},
      {CUTOFF:"2020-01-10",WK_NO:"W2020_02"},
      {CUTOFF:"2020-01-17",WK_NO:"W2020_03"},
      {CUTOFF:"2020-01-24",WK_NO:"W2020_04"},
      {CUTOFF:"2020-01-31",WK_NO:"W2020_05"},
      {CUTOFF:"2020-02-07",WK_NO:"W2020_06"},
      {CUTOFF:"2020-02-14",WK_NO:"W2020_07"},
      {CUTOFF:"2020-02-21",WK_NO:"W2020_08"},
      {CUTOFF:"2020-02-28",WK_NO:"W2020_09"},
      {CUTOFF:"2020-03-06",WK_NO:"W2020_10"},
      {CUTOFF:"2020-03-13",WK_NO:"W2020_11"},
      {CUTOFF:"2020-03-20",WK_NO:"W2020_12"},
      {CUTOFF:"2020-03-27",WK_NO:"W2020_13"},
      {CUTOFF:"2020-04-03",WK_NO:"W2020_14"},
      {CUTOFF:"2020-04-10",WK_NO:"W2020_15"},
      {CUTOFF:"2020-04-17",WK_NO:"W2020_16"},
      {CUTOFF:"2020-04-24",WK_NO:"W2020_17"},
      {CUTOFF:"2020-05-01",WK_NO:"W2020_18"},
      {CUTOFF:"2020-05-08",WK_NO:"W2020_19"},
      {CUTOFF:"2020-05-15",WK_NO:"W2020_20"},
      {CUTOFF:"2020-05-22",WK_NO:"W2020_21"},
      {CUTOFF:"2020-05-29",WK_NO:"W2020_22"},
      {CUTOFF:"2020-06-05",WK_NO:"W2020_23"},
      {CUTOFF:"2020-06-12",WK_NO:"W2020_24"},
      {CUTOFF:"2020-06-19",WK_NO:"W2020_25"},
      {CUTOFF:"2020-06-26",WK_NO:"W2020_26"},
      {CUTOFF:"2020-07-03",WK_NO:"W2020_27"},
      {CUTOFF:"2020-07-10",WK_NO:"W2020_28"},
      {CUTOFF:"2020-07-17",WK_NO:"W2020_29"},
      {CUTOFF:"2020-07-24",WK_NO:"W2020_30"},
      {CUTOFF:"2020-07-31",WK_NO:"W2020_31"},
      {CUTOFF:"2020-08-07",WK_NO:"W2020_32"},
      {CUTOFF:"2020-08-14",WK_NO:"W2020_33"},
      {CUTOFF:"2020-08-21",WK_NO:"W2020_34"},
      {CUTOFF:"2020-08-28",WK_NO:"W2020_35"},
      {CUTOFF:"2020-09-04",WK_NO:"W2020_36"},
      {CUTOFF:"2020-09-11",WK_NO:"W2020_37"},
      {CUTOFF:"2020-09-18",WK_NO:"W2020_38"},
      {CUTOFF:"2020-09-25",WK_NO:"W2020_39"},
      {CUTOFF:"2020-10-02",WK_NO:"W2020_40"},
      {CUTOFF:"2020-10-09",WK_NO:"W2020_41"},
      {CUTOFF:"2020-10-16",WK_NO:"W2020_42"},
      {CUTOFF:"2020-10-23",WK_NO:"W2020_43"},
      {CUTOFF:"2020-10-30",WK_NO:"W2020_44"},
      {CUTOFF:"2020-11-06",WK_NO:"W2020_45"},
      {CUTOFF:"2020-11-13",WK_NO:"W2020_46"},
      {CUTOFF:"2020-11-20",WK_NO:"W2020_47"},
      {CUTOFF:"2020-11-27",WK_NO:"W2020_48"},
      {CUTOFF:"2020-12-04",WK_NO:"W2020_49"},
      {CUTOFF:"2020-12-11",WK_NO:"W2020_50"},
      {CUTOFF:"2020-12-18",WK_NO:"W2020_51"},
      {CUTOFF:"2020-12-25",WK_NO:"W2020_52"},
      {CUTOFF:"2021-01-01",WK_NO:"W2020_53"},
      {CUTOFF:"2021-01-08",WK_NO:"W2021_01"},
      {CUTOFF:"2021-01-15",WK_NO:"W2021_02"},
      {CUTOFF:"2021-01-22",WK_NO:"W2021_03"},
      {CUTOFF:"2021-01-29",WK_NO:"W2021_04"},
      {CUTOFF:"2021-02-05",WK_NO:"W2021_05"},
      {CUTOFF:"2021-02-12",WK_NO:"W2021_06"},
      {CUTOFF:"2021-02-19",WK_NO:"W2021_07"},
      {CUTOFF:"2021-02-26",WK_NO:"W2021_08"},
      {CUTOFF:"2021-03-05",WK_NO:"W2021_09"},
      {CUTOFF:"2021-03-12",WK_NO:"W2021_10"},
      {CUTOFF:"2021-03-19",WK_NO:"W2021_11"},
      {CUTOFF:"2021-03-26",WK_NO:"W2021_12"},
      {CUTOFF:"2021-04-02",WK_NO:"W2021_13"},
      {CUTOFF:"2021-04-09",WK_NO:"W2021_14"},
      {CUTOFF:"2021-04-16",WK_NO:"W2021_15"},
      {CUTOFF:"2021-04-23",WK_NO:"W2021_16"},
      {CUTOFF:"2021-04-30",WK_NO:"W2021_17"},
      {CUTOFF:"2021-05-07",WK_NO:"W2021_18"},
      {CUTOFF:"2021-05-14",WK_NO:"W2021_19"},
      {CUTOFF:"2021-05-21",WK_NO:"W2021_20"},
      {CUTOFF:"2021-05-28",WK_NO:"W2021_21"},
      {CUTOFF:"2021-06-04",WK_NO:"W2021_22"},
      {CUTOFF:"2021-06-11",WK_NO:"W2021_23"},
      {CUTOFF:"2021-06-18",WK_NO:"W2021_24"},
      {CUTOFF:"2021-06-25",WK_NO:"W2021_25"},
      {CUTOFF:"2021-07-02",WK_NO:"W2021_26"},
      {CUTOFF:"2021-07-09",WK_NO:"W2021_27"},
      {CUTOFF:"2021-07-16",WK_NO:"W2021_28"},
      {CUTOFF:"2021-07-23",WK_NO:"W2021_29"},
      {CUTOFF:"2021-07-30",WK_NO:"W2021_30"},
      {CUTOFF:"2021-08-06",WK_NO:"W2021_31"},
      {CUTOFF:"2021-08-13",WK_NO:"W2021_32"},
      {CUTOFF:"2021-08-20",WK_NO:"W2021_33"},
      {CUTOFF:"2021-08-27",WK_NO:"W2021_34"},
      {CUTOFF:"2021-09-03",WK_NO:"W2021_35"},
      {CUTOFF:"2021-09-10",WK_NO:"W2021_36"},
      {CUTOFF:"2021-09-17",WK_NO:"W2021_37"},
      {CUTOFF:"2021-09-24",WK_NO:"W2021_38"},
      {CUTOFF:"2021-10-01",WK_NO:"W2021_39"},
      {CUTOFF:"2021-10-08",WK_NO:"W2021_40"},
      {CUTOFF:"2021-10-15",WK_NO:"W2021_41"},
      {CUTOFF:"2021-10-22",WK_NO:"W2021_42"},
      {CUTOFF:"2021-10-29",WK_NO:"W2021_43"},
      {CUTOFF:"2021-11-05",WK_NO:"W2021_44"},
      {CUTOFF:"2021-11-12",WK_NO:"W2021_45"},
      {CUTOFF:"2021-11-19",WK_NO:"W2021_46"},
      {CUTOFF:"2021-11-26",WK_NO:"W2021_47"},
      {CUTOFF:"2021-12-03",WK_NO:"W2021_48"},
      {CUTOFF:"2021-12-10",WK_NO:"W2021_49"},
      {CUTOFF:"2021-12-17",WK_NO:"W2021_50"},
      {CUTOFF:"2021-12-24",WK_NO:"W2021_51"},
      {CUTOFF:"2021-12-31",WK_NO:"W2021_52"},
      {CUTOFF:"2022-01-07",WK_NO:"W2022_01"},
      {CUTOFF:"2022-01-14",WK_NO:"W2022_02"},
      {CUTOFF:"2022-01-21",WK_NO:"W2022_03"},
      {CUTOFF:"2022-01-28",WK_NO:"W2022_04"},
      {CUTOFF:"2022-02-04",WK_NO:"W2022_05"},
      {CUTOFF:"2022-02-11",WK_NO:"W2022_06"},
      {CUTOFF:"2022-02-18",WK_NO:"W2022_07"},
      {CUTOFF:"2022-02-25",WK_NO:"W2022_08"},
      {CUTOFF:"2022-03-04",WK_NO:"W2022_09"},
      {CUTOFF:"2022-03-11",WK_NO:"W2022_10"},
      {CUTOFF:"2022-03-18",WK_NO:"W2022_11"},
      {CUTOFF:"2022-03-25",WK_NO:"W2022_12"},
      {CUTOFF:"2022-04-01",WK_NO:"W2022_13"},
      {CUTOFF:"2022-04-08",WK_NO:"W2022_14"},
      {CUTOFF:"2022-04-15",WK_NO:"W2022_15"}
    ],
    SQL5: [
      {TOTAL:577,NOTSTARTED:100,INPROGRESS:220,AITR_COMPLETED:170,RFI_ISSUED:299, WD_COMPLETED:200,MC_COMPLETED:250,MC_APPROVED:190,MC_REJECTED:300}
    ],
    SQL6: [
      {CUTOFF:"2021-10-14",WK_NO:"W2021_41",TOTAL:24039,REMAIN:30076},
      {CUTOFF:"2021-10-21",WK_NO:"W2021_42",TOTAL:22100,REMAIN:29835},
      {CUTOFF:"2021-10-28",WK_NO:"W2021_43",TOTAL:20236,REMAIN:29570},
      {CUTOFF:"2021-11-04",WK_NO:"W2021_44",TOTAL:18538,REMAIN:28946},
      {CUTOFF:"2021-11-11",WK_NO:"W2021_45",TOTAL:16924,REMAIN:28371},
      {CUTOFF:"2021-11-18",WK_NO:"W2021_46",TOTAL:14982,REMAIN:28334,WKS_TREND:28334},
      {CUTOFF:"2021-11-25",WK_NO:"W2021_47",TOTAL:11865,WKS_TREND:27957,'4WKS':377},
      {CUTOFF:"2021-12-02",WK_NO:"W2021_48",TOTAL:6819,WKS_TREND:27580,'4WKS':377},
      {CUTOFF:"2021-12-09",WK_NO:"W2021_49",TOTAL:2272,WKS_TREND:27203,'4WKS':377},
      {CUTOFF:"2021-12-16",WK_NO:"W2021_50",TOTAL:0,WKS_TREND:26826,'4WKS':377}
    ]
  }
}