export default {
  Canvas: {
    CanvasWidth: 840,
    CanvasHeight: 500,
    CanvasBorderWeight: 0.3,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.2,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 310,
    CanvasChartHeight: 270,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: 'Transparent',
    CanvasChartFillColor: 'Transparent',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  Text: {
    Text: [],
    Filter: [],
    Sql: [
      {
        query: "SELECT 'Datasource: Shop Drawing Index 04-Oct-20' TITLE", 
        text: 'Datasource: Shop Drawing Index 04-Oct-20',
        x: 10,
        y: 10,
        font: 'roboto',
        style: 'regular',
        size: 12,
        color: '#757575',
        align: 'start'
      },
    ]
  },
  DataItems: [
    {NO:1,DISC:"HSE Design"         ,DOC_CLIENT_REF:"MS002-SE-PID-012-61501-005",DOC_DESCR:"Piping and Instrumentation Diagram - Deluge System - Topside ME01 Piping and Instrumentation Diagram - Deluge System - Topside ME01",REV_DATE:"2020-01-30",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0014.nsf/DOOF_rsAllUNID/70563B2F6313C8C7C12584FC003502BA?openDocument"},
    {NO:2,DISC:"Offshore Structure" ,DOC_CLIENT_REF:"MS002-ST-SPD-012-01050-002",DOC_DESCR:"Module ME01 - Primary Structure - Deck B - Typical Details Sheet 2",REV_DATE:"2020-01-17",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0014.nsf/DOOF_rsAllUNID/DCB07B2D55C6DD6AC12584F50026BF06?openDocument"},
    {NO:3,DISC:"Offshore Structure" ,DOC_CLIENT_REF:"MS002-ST-SPD-012-01050-003",DOC_DESCR:"Module ME01 - Primary Structure - Deck B - Typical Details Sheet 3",REV_DATE:"2020-01-17",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0014.nsf/DOOF_rsAllUNID/DBE047B874FA3509C12584F5002A4551?openDocument"},
    {NO:4,DISC:"Offshore Structure" ,DOC_CLIENT_REF:"MS002-ST-SPD-012-01060-001",DOC_DESCR:"Module ME01 - Primary Structure - Deck C - Typical Details Sheet 1",REV_DATE:"2020-01-17",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0014.nsf/DOOF_rsAllUNID/FE75C82FAD8E8227C12584F5002ACC6E?openDocument"},
    {NO:5,DISC:"Offshore Structure" ,DOC_CLIENT_REF:"MS002-ST-SPD-012-01060-002",DOC_DESCR:"Module ME01 - Primary Structure - Deck C - Typical Details Sheet 2",REV_DATE:"2020-01-17",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0014.nsf/DOOF_rsAllUNID/46F02063A69BC75FC12584F5002B7E44?openDocument"},
    {NO:6,DISC:"Offshore Structure" ,DOC_CLIENT_REF:"MS002-ST-SPD-012-01060-003",DOC_DESCR:"Module ME01 - Primary Structure - Deck C - Typical Details Sheet 3",REV_DATE:"2020-01-17",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0014.nsf/DOOF_rsAllUNID/E077D0A3115E8CD0C12584F5002BDF6D?openDocument"},
  ],
  Queries: {
    SQL1: [
      {TOTAL:483,NOTSTARTED:10,STARTED:113,ISSUED:360}
    ]
  }
}
