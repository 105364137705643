import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 900,
      CanvasHeight: 550,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },
    DataItems: [
      {SUBSYSTEM:"21-8000-8013-01",NO:1,TEST_PACKAGE:"TP-MS00-21-8000-8013-01-DO-4051",GWBS_LV3:"HL03",MC_STATUS:"A ITR in progress",TEST_PACKAGE_PDF:"Y"},
      {SUBSYSTEM:"21-8000-8013-01",NO:2,TEST_PACKAGE:"TP-MS00-21-8000-8013-01-WP-0177",GWBS_LV3:"HL03",P01A:"2021-06-24",P03A:"2021-06-24",MC_STATUS:"A ITR in progress",TEST_PACKAGE_PDF:"Y"},
      {SUBSYSTEM:"21-8000-8013-01",NO:3,TEST_PACKAGE:"TP-MS00-21-8000-8013-01-WP-4049",GWBS_LV3:"HL03",MC_STATUS:"A ITR in progress",TEST_PACKAGE_PDF:"Y"},
      {SUBSYSTEM:"21-8000-8013-01",NO:4,TEST_PACKAGE:"TP-MS00-21-8000-8013-01-WP-4048",GWBS_LV3:"HL03",MC_STATUS:"A ITR in progress",TEST_PACKAGE_PDF:"Y"},
      {SUBSYSTEM:"21-8000-8013-01",NO:5,TEST_PACKAGE:"TP-MS00-21-8000-8013-01-WP-4050",GWBS_LV3:"HL03",MC_STATUS:"A ITR in progress",TEST_PACKAGE_PDF:"Y"},
      {SUBSYSTEM:"21-8000-8013-01",NO:6,TEST_PACKAGE:"TP-MS00-21-8000-8013-01-DO-4052",GWBS_LV3:"HL03",MC_STATUS:"A ITR in progress",TEST_PACKAGE_PDF:"Y"},
      
      {SUBSYSTEM:"21-8000-8013-01",NO:1,TEST_PACKAGE:"TP-MS00-21-8000-8013-01-DO-4051",GWBS_LV3:"HL03",MC_STATUS:"A ITR in progress",TEST_PACKAGE_PDF:"Y"},
      {SUBSYSTEM:"21-8000-8013-01",NO:2,TEST_PACKAGE:"TP-MS00-21-8000-8013-01-WP-0177",GWBS_LV3:"HL03",P01A:"2021-06-24",P03A:"2021-06-24",MC_STATUS:"A ITR in progress",TEST_PACKAGE_PDF:"Y"},
      {SUBSYSTEM:"21-8000-8013-01",NO:3,TEST_PACKAGE:"TP-MS00-21-8000-8013-01-WP-4049",GWBS_LV3:"HL03",MC_STATUS:"A ITR in progress",TEST_PACKAGE_PDF:"Y"},
      {SUBSYSTEM:"21-8000-8013-01",NO:4,TEST_PACKAGE:"TP-MS00-21-8000-8013-01-WP-4048",GWBS_LV3:"HL03",MC_STATUS:"A ITR in progress",TEST_PACKAGE_PDF:"Y"},
      {SUBSYSTEM:"21-8000-8013-01",NO:5,TEST_PACKAGE:"TP-MS00-21-8000-8013-01-WP-4050",GWBS_LV3:"HL03",MC_STATUS:"A ITR in progress",TEST_PACKAGE_PDF:"Y"},
      {SUBSYSTEM:"21-8000-8013-01",NO:6,TEST_PACKAGE:"TP-MS00-21-8000-8013-01-DO-4052",GWBS_LV3:"HL03",MC_STATUS:"A ITR in progress",TEST_PACKAGE_PDF:"Y"},
      
      {SUBSYSTEM:"21-8000-8013-01",NO:1,TEST_PACKAGE:"TP-MS00-21-8000-8013-01-DO-4051",GWBS_LV3:"HL03",MC_STATUS:"A ITR in progress",TEST_PACKAGE_PDF:"Y"},
      {SUBSYSTEM:"21-8000-8013-01",NO:2,TEST_PACKAGE:"TP-MS00-21-8000-8013-01-WP-0177",GWBS_LV3:"HL03",P01A:"2021-06-24",P03A:"2021-06-24",MC_STATUS:"A ITR in progress",TEST_PACKAGE_PDF:"Y"},
      {SUBSYSTEM:"21-8000-8013-01",NO:3,TEST_PACKAGE:"TP-MS00-21-8000-8013-01-WP-4049",GWBS_LV3:"HL03",MC_STATUS:"A ITR in progress",TEST_PACKAGE_PDF:"Y"},
      {SUBSYSTEM:"21-8000-8013-01",NO:4,TEST_PACKAGE:"TP-MS00-21-8000-8013-01-WP-4048",GWBS_LV3:"HL03",MC_STATUS:"A ITR in progress",TEST_PACKAGE_PDF:"Y"},
      {SUBSYSTEM:"21-8000-8013-01",NO:5,TEST_PACKAGE:"TP-MS00-21-8000-8013-01-WP-4050",GWBS_LV3:"HL03",MC_STATUS:"A ITR in progress",TEST_PACKAGE_PDF:"Y"},
      {SUBSYSTEM:"21-8000-8013-01",NO:6,TEST_PACKAGE:"TP-MS00-21-8000-8013-01-DO-4052",GWBS_LV3:"HL03",MC_STATUS:"A ITR in progress",TEST_PACKAGE_PDF:"Y"}
      ,
      
      {SUBSYSTEM:"21-8000-8013-01",NO:1,TEST_PACKAGE:"TP-MS00-21-8000-8013-01-DO-4051",GWBS_LV3:"HL03",MC_STATUS:"A ITR in progress",TEST_PACKAGE_PDF:"Y"},
      {SUBSYSTEM:"21-8000-8013-01",NO:2,TEST_PACKAGE:"TP-MS00-21-8000-8013-01-WP-0177",GWBS_LV3:"HL03",P01A:"2021-06-24",P03A:"2021-06-24",MC_STATUS:"A ITR in progress",TEST_PACKAGE_PDF:"Y"},
      {SUBSYSTEM:"21-8000-8013-01",NO:3,TEST_PACKAGE:"TP-MS00-21-8000-8013-01-WP-4049",GWBS_LV3:"HL03",MC_STATUS:"A ITR in progress",TEST_PACKAGE_PDF:"Y"},
      {SUBSYSTEM:"21-8000-8013-01",NO:4,TEST_PACKAGE:"TP-MS00-21-8000-8013-01-WP-4048",GWBS_LV3:"HL03",MC_STATUS:"A ITR in progress",TEST_PACKAGE_PDF:"Y"},
      {SUBSYSTEM:"21-8000-8013-01",NO:5,TEST_PACKAGE:"TP-MS00-21-8000-8013-01-WP-4050",GWBS_LV3:"HL03",MC_STATUS:"A ITR in progress",TEST_PACKAGE_PDF:"Y"},
      {SUBSYSTEM:"21-8000-8013-01",NO:6,TEST_PACKAGE:"TP-MS00-21-8000-8013-01-DO-4052",GWBS_LV3:"HL03",MC_STATUS:"A ITR in progress",TEST_PACKAGE_PDF:"Y"}


    ]
  ,
}