import __C from '../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 1600,
    CanvasHeight: 2000,
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#000',
    CanvasFillColor: 'white',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1600,
    CanvasChartHeight: 1000,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: 'Transparent',
    CanvasChartFillColor: 'Transparent',
    CanvasChartOpacity: 1,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  Note: {
    NoteDisplay: 'N',
    NoteX: 633,
    NoteY: 358,
    NoteAlign: 'start',
    NoteTitleText: 'Notes.',
    NoteTitleFont: 'roboto',
    NoteTitleStyle: 'regular',
    NoteTitleSize: 11,
    NoteTitleColor: '#757575',

    NoteContentsText: 'Note Contents Here!',
    NoteContentsFont: 'roboto',
    NoteContentsStyle: 'regular',
    NoteContentsSize: 10,
    NoteContentsColor: '#BDBCBC'
  },
  
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  Queries: {
    SQL1:  [
      {
        START_DATE:"2021-04-01",
        END_DATE:"2025-08-31",
        CUTOFF:"2023-01-01",
      }
    ],
    
    SQL2: [
      { ACTIVITY: "Overall", LEV: 1, MILESTONE: "G3FID", PLAN: "2021-10-15" },
      { ACTIVITY: "Overall", LEV: 2, MILESTONE: "30 MR", PLAN: "2022-01-01" },
      {
        ACTIVITY: "Overall",
        LEV: 1,
        MILESTONE: "BED Complete",
        PLAN: "2022-02-01",
      },
      { ACTIVITY: "Overall", LEV: 1, MILESTONE: "60 MR", PLAN: "2022-11-01" },
      { ACTIVITY: "Overall", LEV: 1, MILESTONE: "90 MR", PLAN: "2023-05-01" },
      { ACTIVITY: "Overall", LEV: 1, MILESTONE: "Main SS MC", PLAN: "2023-09-01" },
      { ACTIVITY: "Overall", LEV: 1, MILESTONE: "Power On", PLAN: "2023-11-01" },
      {
        ACTIVITY: "Overall",
        LEV: 1,
        MILESTONE: "Incentive MC",
        PLAN: "2024-10-01",
      },
      { ACTIVITY: "Overall", LEV: 2, MILESTONE: "Tgt MC", PLAN: "2024-12-01" },
      { ACTIVITY: "Overall", LEV: 1, MILESTONE: "Tgt SU", PLAN: "2025-01-01" },
      {
        ACTIVITY: "Overall",
        LEV: 2,
        MILESTONE: "Tgt Full Rates",
        PLAN: "2025-02-01",
      },
      { ACTIVITY: "Overall", LEV: 2, MILESTONE: "P50 SU", PLAN: "2025-08-01" },
      {
        ACTIVITY: "Olefins Recovery",
        LEV: 1,
        MILESTONE: "30 MR",
        PLAN: "2021-12-15",
      },
      {
        ACTIVITY: "Olefins Recovery",
        LEV: 1,
        MILESTONE: "60 MR",
        PLAN: "2022-10-15",
      },
      {
        ACTIVITY: "Olefins Recovery",
        LEV: 1,
        MILESTONE: "Heavy lift",
        PLAN: "2022-12-01",
      },
      {
        ACTIVITY: "Olefins Recovery",
        LEV: 1,
        MILESTONE: "90 MR",
        PLAN: "2023-05-01",
      },
      {
        ACTIVITY: "Olefins Recovery",
        LEV: 1,
        MILESTONE: "CGC Onsite",
        PLAN: "2023-10-01",
      },
      {
        ACTIVITY: "Olefins Recovery",
        LEV: 1,
        MILESTONE: "CGC MC",
        PLAN: "2024-08-01",
      },
      { ACTIVITY: "Olefins Recovery", LEV: 1, MILESTONE: "MC", PLAN: "2024-10-01" },
      {
        ACTIVITY: "Olefins Recovery",
        LEV: 1,
        MILESTONE: "OnSpec C2",
        PLAN: "2025-01-01",
      },
      {
        ACTIVITY: "Olefins Furnaces",
        LEV: 1,
        MILESTONE: "30 MR",
        PLAN: "2022-01-15",
      },
      {
        ACTIVITY: "Olefins Furnaces",
        LEV: 1,
        MILESTONE: "60 MR",
        PLAN: "2022-10-01",
      },
      {
        ACTIVITY: "Olefins Furnaces",
        LEV: 1,
        MILESTONE: "90 MR",
        PLAN: "2023-04-01",
      },
      {
        ACTIVITY: "Olefins Furnaces",
        LEV: 1,
        MILESTONE: "Last TLE Onsite",
        PLAN: "2023-08-15",
      },
      { ACTIVITY: "Olefins Furnaces", LEV: 1, MILESTONE: "MC", PLAN: "2024-10-01" },
      {
        ACTIVITY: "Olefins Co-Products",
        LEV: 1,
        MILESTONE: "30 MR",
        PLAN: "2021-12-15",
      },
      {
        ACTIVITY: "Olefins Co-Products",
        LEV: 1,
        MILESTONE: "60 MR",
        PLAN: "2022-11-01",
      },
      {
        ACTIVITY: "Olefins Co-Products",
        LEV: 1,
        MILESTONE: "Heavy Lifts Prgm",
        PLAN: "2023-02-15",
      },
      {
        ACTIVITY: "Olefins Co-Products",
        LEV: 1,
        MILESTONE: "90 MR",
        PLAN: "2023-06-01",
      },
      {
        ACTIVITY: "Olefins Co-Products",
        LEV: 1,
        MILESTONE: "BDGHU MC",
        PLAN: "2024-09-01",
      },
      {
        ACTIVITY: "Olefins Co-Products",
        LEV: 1,
        MILESTONE: "MC",
        PLAN: "2024-10-01",
      },
      {
        ACTIVITY: "Olefins Co-Products",
        LEV: 1,
        MILESTONE: "Rampup",
        PLAN: "2025-02-15",
      },
      {
        ACTIVITY: "Olefins Associated Utilities",
        LEV: 1,
        MILESTONE: "30 MR",
        PLAN: "2022-04-01",
      },
      {
        ACTIVITY: "Olefins Associated Utilities",
        LEV: 1,
        MILESTONE: "60 MR",
        PLAN: "2022-10-15",
      },
      {
        ACTIVITY: "Olefins Associated Utilities",
        LEV: 1,
        MILESTONE: "90 MR",
        PLAN: "2023-02-15",
      },
      {
        ACTIVITY: "Olefins Associated Utilities",
        LEV: 1,
        MILESTONE: "Last Boiler Pkg Onsite",
        PLAN: "2023-06-01",
      },
      {
        ACTIVITY: "Olefins Associated Utilities",
        LEV: 1,
        MILESTONE: "MC",
        PLAN: "2024-04-01",
      },
      {
        ACTIVITY: "Olefins Associated Utilities",
        LEV: 1,
        MILESTONE: "SU",
        PLAN: "2024-06-01",
      },
      {
        ACTIVITY: "Polymers - GPPE (2 Trains)",
        LEV: 1,
        MILESTONE: "30 MR",
        PLAN: "2021-12-15",
      },
      {
        ACTIVITY: "Polymers - GPPE (2 Trains)",
        LEV: 1,
        MILESTONE: "60 MR",
        PLAN: "2022-10-01",
      },
      {
        ACTIVITY: "Polymers - GPPE (2 Trains)",
        LEV: 1,
        MILESTONE: "90 MR",
        PLAN: "2023-04-01",
      },
      {
        ACTIVITY: "Polymers - GPPE (2 Trains)",
        LEV: 1,
        MILESTONE: "1Extr ExWks",
        PLAN: "2023-07-15",
      },
      {
        ACTIVITY: "Polymers - GPPE (2 Trains)",
        LEV: 1,
        MILESTONE: "2Extr ExWks",
        PLAN: "2023-10-15",
      },
      {
        ACTIVITY: "Polymers - GPPE (2 Trains)",
        LEV: 1,
        MILESTONE: "FIN MC",
        PLAN: "2024-06-01",
      },
      {
        ACTIVITY: "Polymers - GPPE (2 Trains)",
        LEV: 1,
        MILESTONE: "FIN2",
        PLAN: "2024-07-01",
      },
      {
        ACTIVITY: "Polymers - GPPE (2 Trains)",
        LEV: 1,
        MILESTONE: "MC",
        PLAN: "2024-08-01",
      },
      {
        ACTIVITY: "Polymers - PP (2 Trains)",
        LEV: 1,
        MILESTONE: "30 MR",
        PLAN: "2021-12-15",
      },
      {
        ACTIVITY: "Polymers - PP (2 Trains)",
        LEV: 1,
        MILESTONE: "60 MR",
        PLAN: "2022-10-15",
      },
      {
        ACTIVITY: "Polymers - PP (2 Trains)",
        LEV: 1,
        MILESTONE: "90 MR",
        PLAN: "2023-03-01",
      },
      {
        ACTIVITY: "Polymers - PP (2 Trains)",
        LEV: 1,
        MILESTONE: "Extr ExWks",
        PLAN: "2023-08-15",
      },
      {
        ACTIVITY: "Polymers - PP (2 Trains)",
        LEV: 1,
        MILESTONE: "FIN MC",
        PLAN: "2024-06-01",
      },
      {
        ACTIVITY: "Polymers - PP (2 Trains)",
        LEV: 1,
        MILESTONE: "MC",
        PLAN: "2024-07-01",
      },
      {
        ACTIVITY: "Polymers - LDPE (1 Trains)",
        LEV: 1,
        MILESTONE: "30 MR",
        PLAN: "2022-01-15",
      },
      {
        ACTIVITY: "Polymers - LDPE (1 Trains)",
        LEV: 1,
        MILESTONE: "60 MR",
        PLAN: "2022-11-01",
      },
      {
        ACTIVITY: "Polymers - LDPE (1 Trains)",
        LEV: 1,
        MILESTONE: "90 MR",
        PLAN: "2023-04-01",
      },
      {
        ACTIVITY: "Polymers - LDPE (1 Trains)",
        LEV: 1,
        MILESTONE: "Maj Eqpt Onsite",
        PLAN: "2024-01-01",
      },
      {
        ACTIVITY: "Polymers - LDPE (1 Trains)",
        LEV: 1,
        MILESTONE: "MC",
        PLAN: "2024-09-01",
      },
      {
        ACTIVITY: "Polymers - LDPE (1 Trains)",
        LEV: 1,
        MILESTONE: "Rampup",
        PLAN: "2025-02-15",
      },
      {
        ACTIVITY: "Warehouse & Packaging",
        LEV: 1,
        MILESTONE: "60 MR",
        PLAN: "2022-12-01",
      },
      {
        ACTIVITY: "Warehouse & Packaging",
        LEV: 1,
        MILESTONE: "90 MR",
        PLAN: "2023-03-01",
      },
      {
        ACTIVITY: "Warehouse & Packaging",
        LEV: 1,
        MILESTONE: "ASRS onsite",
        PLAN: "2023-04-01",
      },
      {
        ACTIVITY: "Warehouse & Packaging",
        LEV: 1,
        MILESTONE: "WHSE MC",
        PLAN: "2024-03-01",
      },
      {
        ACTIVITY: "Warehouse & Packaging",
        LEV: 1,
        MILESTONE: "PBL MC",
        PLAN: "2024-04-01",
      },
      {
        ACTIVITY: "Warehouse & Packaging",
        LEV: 1,
        MILESTONE: "Tested",
        PLAN: "2024-07-01",
      },
      {
        ACTIVITY: "Warehouse & Packaging",
        LEV: 1,
        MILESTONE: "35",
        PLAN: "2024-10-15",
      },
      {
        ACTIVITY: "Warehouse & Packaging",
        LEV: 1,
        MILESTONE: "55",
        PLAN: "2024-11-15",
      },
      {
        ACTIVITY: "Pipe Rack Network Distribution",
        LEV: 1,
        MILESTONE: "East PL",
        PLAN: "2024-01-01",
      },
      {
        ACTIVITY: "Pipe Rack Network Distribution",
        LEV: 1,
        MILESTONE: "Stm Hdrs",
        PLAN: "2024-04-01",
      },
      {
        ACTIVITY: "Pipe Rack Network Distribution",
        LEV: 1,
        MILESTONE: "MC",
        PLAN: "2024-07-01",
      },
      {
        ACTIVITY: "Main Sub-Station",
        LEV: 1,
        MILESTONE: "Award",
        PLAN: "2022-04-01",
      },
      { ACTIVITY: "Main Sub-Station", LEV: 1, MILESTONE: "MC", PLAN: "2023-09-01" },
      {
        ACTIVITY: "Main Sub-Station",
        LEV: 1,
        MILESTONE: "Power On",
        PLAN: "2023-11-01",
      },
      { ACTIVITY: "Utilities", LEV: 1, MILESTONE: "30 MR", PLAN: "2022-03-15" },
      { ACTIVITY: "Utilities", LEV: 1, MILESTONE: "60 MR", PLAN: "2022-10-01" },
      { ACTIVITY: "Utilities", LEV: 1, MILESTONE: "90 MR", PLAN: "2023-01-01" },
      { ACTIVITY: "Utilities", LEV: 1, MILESTONE: "FW Pmp", PLAN: "2023-10-01" },
      { ACTIVITY: "Utilities", LEV: 1, MILESTONE: "SW Basin", PLAN: "2023-12-01" },
      { ACTIVITY: "Utilities", LEV: 1, MILESTONE: "WWT", PLAN: "2023-12-15" },
      { ACTIVITY: "Utilities", LEV: 1, MILESTONE: "Demin", PLAN: "2024-01-01" },
      { ACTIVITY: "Utilities", LEV: 1, MILESTONE: "CWS", PLAN: "2024-03-01" },
      { ACTIVITY: "Utilities", LEV: 1, MILESTONE: "EFlare", PLAN: "2024-04-01" },
      {
        ACTIVITY: "Utilities",
        LEV: 1,
        MILESTONE: "GFlareFoam Stn",
        PLAN: "2024-06-01",
      },
      { ACTIVITY: "Utilities", LEV: 1, MILESTONE: "MC", PLAN: "2024-08-01" },
      { ACTIVITY: "Tank Farm", LEV: 1, MILESTONE: "30 MR", PLAN: "2022-04-15" },
      { ACTIVITY: "Tank Farm", LEV: 1, MILESTONE: "60 MR", PLAN: "2022-11-01" },
      {
        ACTIVITY: "Tank Farm",
        LEV: 1,
        MILESTONE: "BOG Comp Del",
        PLAN: "2023-01-15",
      },
      { ACTIVITY: "Tank Farm", LEV: 1, MILESTONE: "90 MR", PLAN: "2023-03-01" },
      { ACTIVITY: "Tank Farm", LEV: 1, MILESTONE: "Pmp Del", PLAN: "2023-05-15" },
      {
        ACTIVITY: "Tank Farm",
        LEV: 1,
        MILESTONE: "1st Bund MC",
        PLAN: "2024-04-01",
      },
      { ACTIVITY: "Tank Farm", LEV: 1, MILESTONE: "Cryo Tks", PLAN: "2024-06-01" },
      {
        ACTIVITY: "Tank Farm",
        LEV: 1,
        MILESTONE: "Last Bund MC",
        PLAN: "2024-08-01",
      },
      {
        ACTIVITY: "Buildings",
        LEV: 1,
        MILESTONE: "DCSSIS Framework",
        PLAN: "2022-05-01",
      },
      { ACTIVITY: "Buildings", LEV: 1, MILESTONE: "DCSSIS PO", PLAN: "2022-09-01" },
      {
        ACTIVITY: "Buildings",
        LEV: 1,
        MILESTONE: "DCSSIS Del",
        PLAN: "2023-04-15",
      },
      { ACTIVITY: "Buildings", LEV: 1, MILESTONE: "Area B", PLAN: "2023-09-01" },
      { ACTIVITY: "Buildings", LEV: 1, MILESTONE: "CCB", PLAN: "2023-10-01" },
      {
        ACTIVITY: "Buildings",
        LEV: 1,
        MILESTONE: "Maint Whse",
        PLAN: "2023-12-01",
      },
      { ACTIVITY: "Buildings", LEV: 1, MILESTONE: "AOB B", PLAN: "2024-01-01" },
      { ACTIVITY: "Buildings", LEV: 1, MILESTONE: "Lab MC", PLAN: "2024-03-01" },
      {
        ACTIVITY: "Overall",
        LEV: 2,
        MILESTONE: "Jetty Term RFU",
        PLAN: "2024-07-01",
      },
      {
        ACTIVITY: "Overall",
        LEV: 1,
        MILESTONE: "SS Pipeline RFU",
        PLAN: "2024-09-01",
      },
      {
        ACTIVITY: "Marine Off-loading Facility (MOF)",
        LEV: 1,
        MILESTONE: "HHR RFU",
        PLAN: "2022-06-01",
      },
      {
        ACTIVITY: "Marine Off-loading Facility (MOF)",
        LEV: 1,
        MILESTONE: "MC",
        PLAN: "2022-08-01",
      },
      {
        ACTIVITY: "Marine Off-loading Facility (MOF)",
        LEV: 1,
        MILESTONE: "Accept",
        PLAN: "2022-09-01",
      },
      {
        ACTIVITY: "Marine Off-loading Facility (MOF)",
        LEV: 1,
        MILESTONE: "MOF AvailDomestic",
        PLAN: "2022-11-01",
      },
      {
        ACTIVITY: "Marine Off-loading Facility (MOF)",
        LEV: 1,
        MILESTONE: "RFU Intl",
        PLAN: "2023-04-01",
      },
      {
        ACTIVITY: "Petrochemical Jetty",
        LEV: 1,
        MILESTONE: "Commence Constr",
        PLAN: "2022-03-15",
      },
      {
        ACTIVITY: "Petrochemical Jetty",
        LEV: 1,
        MILESTONE: "MC",
        PLAN: "2024-02-01",
      },
      {
        ACTIVITY: "Petrochemical Jetty",
        LEV: 1,
        MILESTONE: "Accept",
        PLAN: "2024-03-01",
      },
      {
        ACTIVITY: "Petrochemical Jetty",
        LEV: 1,
        MILESTONE: "RFUD",
        PLAN: "2024-07-01",
      },
      {
        ACTIVITY: "Petrochemical Jetty",
        LEV: 1,
        MILESTONE: "RFUI",
        PLAN: "2024-10-01",
      },
      {
        ACTIVITY: "Terminal (Incl. Pipelines_LVN Tanks)",
        LEV: 1,
        MILESTONE: "Commence Constr",
        PLAN: "2022-05-01",
      },
      {
        ACTIVITY: "Terminal (Incl. Pipelines_LVN Tanks)",
        LEV: 1,
        MILESTONE: "MC",
        PLAN: "2024-01-01",
      },
      {
        ACTIVITY: "Terminal (Incl. Pipelines_LVN Tanks)",
        LEV: 1,
        MILESTONE: "Term RFU",
        PLAN: "2024-06-01",
      },
      {
        ACTIVITY: "Sub-Sea Pipeline",
        LEV: 1,
        MILESTONE: "Constr OnS",
        PLAN: "2023-02-01",
      },
      {
        ACTIVITY: "Sub-Sea Pipeline",
        LEV: 1,
        MILESTONE: "Constr PL OffS",
        PLAN: "2023-03-01",
      },
      {
        ACTIVITY: "Sub-Sea Pipeline",
        LEV: 1,
        MILESTONE: "PL MC",
        PLAN: "2023-12-01",
      },
      {
        ACTIVITY: "Sub-Sea Pipeline",
        LEV: 1,
        MILESTONE: "MBZ MC",
        PLAN: "2024-04-01",
      },
      {
        ACTIVITY: "Sub-Sea Pipeline",
        LEV: 1,
        MILESTONE: "Accept",
        PLAN: "2024-05-01",
      },
      {
        ACTIVITY: "Sub-Sea Pipeline",
        LEV: 1,
        MILESTONE: "Pipeline RFU",
        PLAN: "2024-09-01",
      },
      {
        ACTIVITY: "220KV Power Supply (ChongWen Feeder)",
        LEV: 1,
        MILESTONE: "ChongWen",
        PLAN: "2023-08-01",
      },
      {
        ACTIVITY: "220KV Power Supply (ChongWen Feeder)",
        LEV: 1,
        MILESTONE: "Zhong Qu",
        PLAN: "2024-01-01",
      },
      {
        ACTIVITY: "N2 Pipelines (LP_HP) Supply (Linde)",
        LEV: 1,
        MILESTONE: "MC",
        PLAN: "2023-12-01",
      },
      {
        ACTIVITY: "N2 Pipelines (LP_HP) Supply (Linde)",
        LEV: 1,
        MILESTONE: "RFU",
        PLAN: "2024-01-01",
      },
      { ACTIVITY: "QY WWT Facs", LEV: 1, MILESTONE: "MC", PLAN: "2023-09-01" },
      { ACTIVITY: "QY WWT Facs", LEV: 1, MILESTONE: "RFU", PLAN: "2024-01-01" },
      { ACTIVITY: "GEG Natural Gas", LEV: 1, MILESTONE: "MC", PLAN: "2023-11-01" },
      { ACTIVITY: "GEG Natural Gas", LEV: 1, MILESTONE: "RFU", PLAN: "2023-12-01" },
      {
        ACTIVITY: "GEG Pipeline Steam Supply",
        LEV: 1,
        MILESTONE: "MC",
        PLAN: "2024-01-01",
      },
      {
        ACTIVITY: "GEG Pipeline Steam Supply",
        LEV: 1,
        MILESTONE: "RFU",
        PLAN: "2024-03-01",
      },
      {
        ACTIVITY: "CSPC_CNOOC Lines (H2_C2_C3_C4_ANO)",
        LEV: 1,
        MILESTONE: "MC",
        PLAN: "2024-06-01",
      },
      {
        ACTIVITY: "CSPC_CNOOC Lines (H2_C2_C3_C4_ANO)",
        LEV: 1,
        MILESTONE: "RFU",
        PLAN: "2024-07-01",
      },
      {
        ACTIVITY: "3rd Party Jetty (Huade)",
        LEV: 1,
        MILESTONE: "MC",
        PLAN: "2024-04-01",
      },
      {
        ACTIVITY: "Dayawan Technology Center (DTC)",
        LEV: 1,
        MILESTONE: "MC",
        PLAN: "2024-10-01",
      },
      {
        ACTIVITY: "Dayawan Technology Center (DTC)",
        LEV: 1,
        MILESTONE: "SU",
        PLAN: "2025-01-01",
      },
      {
        ACTIVITY: "LPG Logistic Face (JOVO)",
        LEV: 1,
        MILESTONE: "MC",
        PLAN: "2025-08-15",
      },
    
    ]
  },

  DataItems: [
      {
        NO: 1,
        ACT_GROUP: "OLIFINS PROJECTS",
        ACTIVITY: "Olefins Recovery",
        FSR_SD: "2021-04-01",
        FSR_FD: "2022-02-01",
        EXE_SD: "2022-02-01",
        EXE_FD: "2024-10-01",
        CSU_SD: "2024-10-01",
        CSU_FD: "2025-01-01",
      },
      {
        NO: 2,
        ACT_GROUP: "OLIFINS PROJECTS",
        ACTIVITY: "Olefins Furnaces",
        FSR_SD: "2021-04-01",
        FSR_FD: "2022-02-01",
        EXE_SD: "2022-02-01",
        EXE_FD: "2024-10-01",
        CSU_SD: "2024-10-01",
        CSU_FD: "2025-01-01",
      },
      {
        NO: 3,
        ACT_GROUP: "OLIFINS PROJECTS",
        ACTIVITY: "Olefins Co-Products",
        FSR_SD: "2021-04-01",
        FSR_FD: "2022-02-01",
        EXE_SD: "2022-02-01",
        EXE_FD: "2024-09-01",
        CSU_SD: "2024-09-01",
        CSU_FD: "2025-02-15",
      },
      {
        NO: 4,
        ACT_GROUP: "OLIFINS PROJECTS",
        ACTIVITY: "Olefins Associated Utilities",
        FSR_SD: "2021-04-01",
        FSR_FD: "2022-02-01",
        EXE_SD: "2022-02-01",
        EXE_FD: "2024-04-01",
        CSU_SD: "2024-04-01",
        CSU_FD: "2024-07-01",
      },
      {
        NO: 5,
        ACT_GROUP: "PLYMERS PROJECTS",
        ACTIVITY: "Polymers - GPPE (2 Trains)",
        FSR_SD: "2021-04-01",
        FSR_FD: "2022-02-01",
        EXE_SD: "2022-02-01",
        EXE_FD: "2024-06-01",
        CSU_SD: "2024-06-01",
        CSU_FD: "2024-12-15",
      },
      {
        NO: 6,
        ACT_GROUP: "PLYMERS PROJECTS",
        ACTIVITY: "Polymers - PP (2 Trains)",
        FSR_SD: "2021-04-01",
        FSR_FD: "2022-02-01",
        EXE_SD: "2022-02-01",
        EXE_FD: "2024-06-01",
        CSU_SD: "2024-06-01",
        CSU_FD: "2025-01-15",
      },
      {
        NO: 7,
        ACT_GROUP: "PLYMERS PROJECTS",
        ACTIVITY: "Polymers - LDPE (1 Trains)",
        FSR_SD: "2021-04-01",
        FSR_FD: "2022-02-01",
        EXE_SD: "2022-02-01",
        EXE_FD: "2024-09-01",
        CSU_SD: "2024-09-01",
        CSU_FD: "2025-02-15",
      },
      {
        NO: 8,
        ACT_GROUP: "PLYMERS PROJECTS",
        ACTIVITY: "Warehouse & Packaging",
        FSR_SD: "2021-04-01",
        FSR_FD: "2022-02-01",
        EXE_SD: "2022-02-01",
        EXE_FD: "2024-03-01",
        CSU_SD: "2024-03-01",
        CSU_FD: "2024-11-15",
      },
      {
        NO: 9,
        ACT_GROUP: "INFRASTRUCTURE & UTILITIES PROJECTS",
        ACTIVITY: "Pipe Rack Network Distribution",
        FSR_SD: "2021-04-01",
        FSR_FD: "2022-02-01",
        EXE_SD: "2022-02-01",
        EXE_FD: "2024-07-01",
      },
      {
        NO: 10,
        ACT_GROUP: "INFRASTRUCTURE & UTILITIES PROJECTS",
        ACTIVITY: "Main Sub-Station",
        FSR_SD: "2021-04-01",
        FSR_FD: "2022-02-01",
        EXE_SD: "2022-02-01",
        EXE_FD: "2023-09-01",
        CSU_SD: "2023-09-01",
        CSU_FD: "2024-11-01",
      },
      {
        NO: 11,
        ACT_GROUP: "INFRASTRUCTURE & UTILITIES PROJECTS",
        ACTIVITY: "Utilities",
        FSR_SD: "2021-04-01",
        FSR_FD: "2022-02-01",
        EXE_SD: "2022-02-01",
        EXE_FD: "2024-08-01",
      },
      {
        NO: 12,
        ACT_GROUP: "INFRASTRUCTURE & UTILITIES PROJECTS",
        ACTIVITY: "Tank Farm",
        FSR_SD: "2021-04-01",
        FSR_FD: "2022-02-01",
        EXE_SD: "2022-02-01",
        EXE_FD: "2024-08-01",
      },
      {
        NO: 13,
        ACT_GROUP: "INFRASTRUCTURE & UTILITIES PROJECTS",
        ACTIVITY: "Buildings",
        FSR_SD: "2021-04-01",
        FSR_FD: "2022-02-01",
        EXE_SD: "2022-02-01",
        EXE_FD: "2024-03-01",
      },
      {
        NO: 14,
        ACT_GROUP: "OFFPLOTS PROJECT",
        ACTIVITY: "Marine Off-loading Facility (MOF)",
        EXE_SD: "2021-04-01",
        EXE_FD: "2022-09-01",
        CSU_SD: "2022-09-01",
        CSU_FD: "2023-04-01",
      },
      {
        NO: 15,
        ACT_GROUP: "OFFPLOTS PROJECT",
        ACTIVITY: "Petrochemical Jetty",
        EXE_SD: "2021-07-01",
        EXE_FD: "2024-02-01",
        CSU_SD: "2024-02-01",
        CSU_FD: "2024-07-01",
      },
      {
        NO: 16,
        ACT_GROUP: "OFFPLOTS PROJECT",
        ACTIVITY: "Terminal (Incl. Pipelines_LVN Tanks)",
        FSR_SD: "2021-04-01",
        FSR_FD: "2021-12-01",
        EXE_SD: "2021-12-01",
        EXE_FD: "2024-01-01",
        CSU_SD: "2024-01-01",
        CSU_FD: "2024-06-01",
      },
      {
        NO: 17,
        ACT_GROUP: "OFFPLOTS PROJECT",
        ACTIVITY: "Sub-Sea Pipeline",
        FSR_SD: "2021-11-01",
        FSR_FD: "2022-06-01",
        EXE_SD: "2022-07-01",
        EXE_FD: "2023-12-01",
        CSU_SD: "2023-12-01",
        CSU_FD: "2024-09-01",
      },
      {
        NO: 18,
        ACT_GROUP: "INTERFACE PROJECTS",
        ACTIVITY: "220KV Power Supply (ChongWen Feeder)",
        FSR_SD: "2021-12-01",
        FSR_FD: "2022-04-01",
        EXE_SD: "2022-05-01",
        EXE_FD: "2023-08-01",
      },
      {
        NO: 19,
        ACT_GROUP: "INTERFACE PROJECTS",
        ACTIVITY: "Dayawan Pipe Rack",
        EXE_SD: "2022-06-01",
        EXE_FD: "2023-07-01",
      },
      {
        NO: 20,
        ACT_GROUP: "INTERFACE PROJECTS",
        ACTIVITY: "N2 Pipelines (LP_HP) Supply (Linde)",
        EXE_SD: "2022-06-01",
        EXE_FD: "2023-12-01",
        CSU_SD: "2023-12-01",
        CSU_FD: "2024-01-01",
      },
      {
        NO: 21,
        ACT_GROUP: "INTERFACE PROJECTS",
        ACTIVITY: "QY WWT Facs",
        FSR_SD: "2021-07-01",
        FSR_FD: "2022-01-01",
        EXE_SD: "2022-01-01",
        EXE_FD: "2023-09-01",
        CSU_SD: "2023-09-01",
        CSU_FD: "2024-01-01",
      },
      {
        NO: 22,
        ACT_GROUP: "INTERFACE PROJECTS",
        ACTIVITY: "GEG Natural Gas",
        EXE_SD: "2022-04-01",
        EXE_FD: "2023-11-01",
        CSU_SD: "2023-11-01",
        CSU_FD: "2023-12-01",
      },
      {
        NO: 23,
        ACT_GROUP: "INTERFACE PROJECTS",
        ACTIVITY: "GEG Pipeline Steam Supply",
        FSR_SD: "2021-06-15",
        FSR_FD: "2021-12-01",
        EXE_SD: "2021-12-01",
        EXE_FD: "2024-01-01",
        CSU_SD: "2024-01-01",
        CSU_FD: "2024-03-01",
      },
      {
        NO: 24,
        ACT_GROUP: "INTERFACE PROJECTS",
        ACTIVITY: "CSPC_CNOOC Lines (H2_C2_C3_C4_ANO)",
        EXE_SD: "2022-07-01",
        EXE_FD: "2024-06-01",
        CSU_SD: "2024-06-01",
        CSU_FD: "2024-07-01",
      },
      {
        NO: 25,
        ACT_GROUP: "INTERFACE PROJECTS",
        ACTIVITY: "3rd Party Jetty (Huade)",
        EXE_SD: "2022-01-01",
        EXE_FD: "2024-04-01",
      },
      {
        NO: 26,
        ACT_GROUP: "INTERFACE PROJECTS",
        ACTIVITY: "Dayawan Technology Center (DTC)",
        FSR_SD: "2022-02-01",
        FSR_FD: "2022-07-01",
        EXE_SD: "2022-07-01",
        EXE_FD: "2024-10-01",
        CSU_SD: "2024-10-01",
        CSU_FD: "2025-01-01",
      },
      {
        NO: 27,
        ACT_GROUP: "INTERFACE PROJECTS",
        ACTIVITY: "LPG Logistic Face (JOVO)",
        EXE_SD: "2022-09-01",
        EXE_FD: "2025-08-15",
      },
  ],
  
  
  
  
}