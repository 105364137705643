import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 780,
      CanvasHeight: 300,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },
    
    DataItems:  [
      {IDX:160,PARENT_ID:0,USER_ID:"peterkim",COMMENT:"태그없애고 테스트태그없애고 테스트태그없애고 테스트태그없애고 테스트태그없애고 테스트태그없애고 테스트태그없애고 테스트태그없애고 테스트태그없애고 테스트태그없애고 테스트태그없애고 테스트태그없애고 테스트태그없애고 테스트태그없애고 테스트",CREATED_DATE:"2022-11-28T17:17:34.713",EXT_COMMENT:"Peter Kim",FORECAST_DATE:"2022-08-09",EXT3:"",EXT4:"",EXT5:""},
      {IDX:161,PARENT_ID:0,USER_ID:"peterkim",COMMENT:"<p>sdfgsdfgsfdgsfdg</p>",CREATED_DATE:"2022-11-28T17:17:34.713",EXT_COMMENT:"Peter Kim",FORECAST_DATE:"2022-08-09",EXT3:"",EXT4:"",EXT5:""},
      {IDX:162,PARENT_ID:0,USER_ID:"peterkim",COMMENT:"<p>sdfgsdfgsfdgsfdg</p>",CREATED_DATE:"2022-11-28T17:17:34.713",EXT_COMMENT:"Peter Kim",FORECAST_DATE:"2022-08-09",EXT3:"",EXT4:"",EXT5:""},
      {IDX:163,PARENT_ID:0,USER_ID:"peterkim",COMMENT:"<p>sdfgsdfgsfdgsfdg</p>",CREATED_DATE:"2022-11-28T17:17:34.713",EXT_COMMENT:"Peter Kim",FORECAST_DATE:"2022-08-09",EXT3:"",EXT4:"",EXT5:""},
      {IDX:164,PARENT_ID:0,USER_ID:"peterkim",COMMENT:"<p>sdfgsdfgsfdgsfdg</p>",CREATED_DATE:"2022-11-28T17:17:34.713",EXT_COMMENT:"Peter Kim",FORECAST_DATE:"2022-08-09",EXT3:"",EXT4:"",EXT5:""},
      {IDX:165,PARENT_ID:0,USER_ID:"peterkim",COMMENT:"<p>sdfgsdfgsfdgsfdg</p>",CREATED_DATE:"2022-11-28T17:17:34.713",EXT_COMMENT:"Peter Kim",FORECAST_DATE:"2022-08-09",EXT3:"",EXT4:"",EXT5:""},

    ]
}