import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 945,
      CanvasHeight: 25,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: 'Transparent',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

    Text: {
      Text: [
        { text: 'OGM Status', x: 20, y: 0, font: 'roboto', class: 'regular', size: 13, color: '#000', align: 'start', direction: 'Horizontal' },
      ],
      Filter: [],
      Sql: []
    },

    DataItems: [],
}