import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1500,
      CanvasHeight: 1050,
      CanvasBorderWeight: 0.2,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 1,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Note', x: 1350, y: 20, font: 'roboto', class: 'regular', size: 11, color: '#44A9DF', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: 1350, y: 30, font: 'roboto', class: 'regular', size: 11, color: '#757575', align: 'start' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: 32,
          y: 90,
          font: 'roboto',
          style: 'regular',
          size: 10,
          color: '#757575',
          align: 'start'
        },
      ]
    },
    Queries: {
      // Status of Blocks
      SQL1: [
        { No: "01", Title: "01.Temp supports", Cutoff: "2019-09-09" },
        { No: "02", Title: "02.The Bottom Mega Blocks", Cutoff: "2019-12-14" },
        { No: "03", Title: "03.Middle Mega block", Cutoff: "2020-01-10" },
        { No: "04", Title: "04.Bulkhead blocks", Cutoff: "2020-04-17" },
        { No: "05", Title: "05.Lower starboard and portside Mega blocks", Cutoff: "2020-08-24" },
        { No: "06", Title: "06.Upper starboard and portside Mega blocks", Cutoff: "2020-10-08" },
        { No: "07", Title: "07.Main deck mega blocks", Cutoff: "2020-11-12" },
        { No: "08", Title: "08.Main Hull", Cutoff: "2020-11-30" },
        { No: "09", Title: "09.Structure Items", Cutoff: "2020-12-13" },
        { No: "10", Title: "10.The FWD part Items will lifting", Cutoff: "2020-12-13" },
        { No: "11", Title: "11.Open the Drydock door", Cutoff: "2020-12-19" },
        { No: "12", Title: "12.FWD part undocking.", Cutoff: "2020-12-19" },
        { No: "13", Title: "13.AFT part moved in the dock", Cutoff: "2021-01-03" },
        { No: "14", Title: "14.The mooring balcony and riser items", Cutoff: "2021-01-23" },
        { No: "15", Title: "15.AFT part undocking.", Cutoff: "2021-02-16" },
        { No: "16", Title: "16.AFT and FWD hull are towed to mating dock", Cutoff: "2021-02-16" },
        { No: "17", Title: "17.Arrived to the mating dock", Cutoff: "2021-02-16" },
        { No: "18", Title: "18.Complete positioning docking in water", Cutoff: "2021-02-17" },
        { No: "19", Title: "19.Strcuture welding and external painting", Cutoff: "2021-02-19" },
        { No: "20", Title: "20.The docking project is over, Undocking", Cutoff: "2021-03-06" },
        { No: "21", Title: "21.Towing the FPSO to COSCO Qidong", Cutoff: "2021-03-10" },
        { No: "22", Title: "22.The LQ erection on the slipway", Cutoff: "2021-03-11" },
        { No: "23", Title: "23.Helideck installation", Cutoff: "2021-04-09" },
        { No: "24", Title: "24.Laydown lifting by gantry crane", Cutoff: "2021-05-01" },
        { No: "25", Title: "25.Piperack lifting on board", Cutoff: "2021-06-15" },
        { No: "26", Title: "26.Modules - ME04, ME05, MW04", Cutoff: "2021-06-30" },
        { No: "27", Title: "27.Module - MW05", Cutoff: "2021-09-15" },
        { No: "28", Title: "28.Modules - MW01, MW02, ME01, ME02", Cutoff: "2021-10-15" },
        { No: "29", Title: "29.Flare tower Erection", Cutoff: "2021-11-01" },
        { No: "30", Title: "30.Integration and MC", Cutoff: "2022-02-20" },
        { No: "31", Title: "31.Completion", Cutoff: "2022-06-30" }
      ],
    },
    DataItems: [
      { text: 'Module', x: 450, y: 20, font: 'roboto', class: 'regular', size: 20, color: '#44A9DF', align: 'start' },
    ],
    
}