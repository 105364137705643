import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 520,
      CanvasHeight: 170,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Piping Test Packages', x:40, y: 10, font: 'roboto', class: 'regular', size: 12, color: '#424242', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: -50, y: 20, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'start' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: -30,
          y: 20,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'start'
        },
      ]
    },
    DataItems: [
      {NO:1,QCF:"P01A",QCF_DESCR:"Pre-Test Line Walk",TOTAL:3,COMPLETED:3,REMAIN:0,PROG:100},
      {NO:2,QCF:"P03A",QCF_DESCR:"Piping Pressure Test Checksheet",TOTAL:3,COMPLETED:0,REMAIN:3,PROG:0},
      {NO:3,QCF:"IC01A",QCF_DESCR:"Piping / Equipment Internal Cleaning Inspection",TOTAL:3,COMPLETED:0,REMAIN:3,PROG:0},
      {NO:4,QCF:"P05A",QCF_DESCR:"Test Pack Reinstatement",TOTAL:3,COMPLETED:0,REMAIN:3,PROG:0},
      {NO:5,QCF:"P06A",QCF_DESCR:"Joint Integrity Certificate",TOTAL:3,COMPLETED:0,REMAIN:3,PROG:0}
    ],
}