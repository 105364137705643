
export default {
  Canvas: {
    CanvasWidth: 1600,
    CanvasHeight: 1200,
    CanvasBorderWeight: 0.2,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 760,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },

  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  Queries: {
    SQL1:  [],
  },
  
  DataItems: [
    { no: 1,   system: 'KMS-TBD1',  type: 'KEY_MILESTONE', name: 'Substation Energized', },
    { no: 2,   system: '600',       type: 'BUILDING',      name: 'Sub 50SS04', },
    { no: 3,   system: 'KMS-TBD2',  type: 'KEY_MILESTONE', name: 'RIE Commd', },
    { no: 4,   system: '738-000',   type: 'BUILDING',      name: 'RIE', },
    { no: 5,   system: '738',       type: 'UTILITY',       name: 'Distributed Control System (DCS)', },
    { no: 6,   system: '739',       type: 'UTILITY',       name: 'SIS/BMS/HIPPS/TMO', },
    { no: 7,   system: 'TEMP2',     type: 'TEMP',          name: 'TEMP-Hoses / JO for IA', },
    { no: 8,   system: 'TEMP3',     type: 'TEMP',          name: 'Hoses/ JO for CW flushing', },
    { no: 9,   system: 'JPE-296',   type: 'TEMP',          name: 'WWTP from P3', },
    { no: 10,  system: 'TEMP1',     type: 'INTERFACES',    name: 'TEMP-Potable Water', },
    { no: 11,  system: 'KM-P2-01',  type: 'KEY_MILESTONE', name: 'IA & CW Comm', },
    { no: 12,  system: '404',       type: 'UTILITY',       name: 'Instrument Air System', },
    { no: 13,  system: '405',       type: 'UTILITY',       name: 'Cooling Water System', },
    { no: 14,  system: '436',       type: 'UTILITY',       name: 'Storm Water Pond', },
    { no: 15,  system: '420',       type: 'UTILITY',       name: 'Clean Waste Water', },
    { no: 16,  system: '606',       type: 'UTILITY',       name: 'Operator Shelter', },
    { no: 17,  system: 'LRN-343',   type: 'KEY_MILESTONE', name: 'Stm Blows Completed', },
    { no: 18,  system: '508-2',     type: 'UTILITY',       name: 'LP Steam', },
    { no: 19,  system: '507-1',     type: 'UTILITY',       name: 'MP Steam', },
    { no: 20,  system: '506-1',     type: 'UTILITY',       name: 'HP Steam', },
    { no: 21,  system: 'D-227',     type: 'TEMP',          name: 'Silencers, etc.', },
    { no: 22,  system: 'R-901',     type: 'INTERFACES',    name: 'P3 Tank (clean & pres.)', },
    { no: 23,  system: 'KMS-TBD3',  type: 'KEY_MILESTONE', name: 'Dryer Loading Completed', },
    { no: 24,  system: 'TEMP4',     type: 'TEMP',          name: 'Cat Loading Contractor', },
    { no: 25,  system: '424',       type: 'UTILITY',       name: 'Potable Water', },
    { no: 26,  system: '208',       type: 'UTILITY',       name: 'GPPE LPPN', },
    { no: 27,  system: '207',       type: 'UTILITY',       name: 'GPPE HPPN', },
    { no: 28,  system: '204',       type: 'PROCESS',       name: 'CA 1 Purification', },
    { no: 29,  system: '205',       type: 'PROCESS',       name: 'CA 2 Purification', },
    { no: 30,  system: '201',       type: 'PROCESS',       name: 'Ethylene Purification', },
    { no: 31,  system: '202',       type: 'PROCESS',       name: 'Hexene Purification', },
    { no: 32,  system: '203',       type: 'PROCESS',       name: 'Butene Purification', },
    { no: 33,  system: '504',       type: 'UTILITY',       name: 'Safety Shower / Eyewash', },
    { no: 34,  system: 'KMS-TBD4',  type: 'KEY_MILESTONE', name: 'N2/H2 Introduction', },
    { no: 35,  system: '407',       type: 'UTILITY',       name: 'LP Nitrogen Import/Distrib.', },
    { no: 36,  system: 'G-252',     type: 'INTERFACES',    name: 'N2 Available @ BL from P3', },
    { no: 37,  system: '143',       type: 'UTILITY',       name: 'Hydrogen', },
    { no: 38,  system: 'KQ-257',    type: 'INTERFACES',    name: 'PP H2 (Back-Up)', },
    { no: 39,  system: '412',       type: 'UTILITY',       name: 'Firewater System', },
    { no: 40,  system: 'XXX ',      type: 'INTERFACES',    name: 'Fire Water', },
    { no: 41,  system: '406-1',     type: 'UTILITY',       name: 'Industrial Water System', },
    { no: 42,  system: 'S-962',     type: 'INTERFACES',    name: 'Feed Tank Inv. (P3)', },
    { no: 43,  system: 'KM-P2-12',  type: 'KEY_MILESTONE', name: 'GPPE 1: Regen of Purification completed', },
    { no: 44,  system: '435',       type: 'UTILITY',       name: 'LP Flare (Elevated Flare)', },
    { no: 45,  system: 'KM-P2-13',  type: 'KEY_MILESTONE', name: 'GPPE 1: Ready for Ethylene Introduction', },
    { no: 46,  system: '435-01',    type: 'UTILITY',       name: 'LP Flare (Ground)', },
    { no: 47,  system: 'KMS-TBD6',  type: 'KEY_MILESTONE', name: 'Cycle Gas Comp. Motor Solo', },
    { no: 48,  system: '210',       type: 'PROCESS',       name: 'Cycle Gas Loop', },
    { no: 49,  system: '209',       type: 'PROCESS',       name: 'Purge Header', },
    { no: 50,  system: 'KMS-TBD7',  type: 'KEY_MILESTONE', name: 'GPPE1: Cycle Gas Comp. N2 Run', },
    { no: 51,  system: '207-1',     type: 'UTILITY',       name: 'GPPE HPPN', },
    { no: 52,  system: '435-02',    type: 'UTILITY',       name: 'LP Flare (Elevated Flare)', },
    { no: 53,  system: 'Process',   type: 'TEMP',          name: 'Silencer', },
    { no: 54,  system: 'KMS-TBD8',  type: 'KEY_MILESTONE', name: 'GPPE1: VRC Run-in w/Air completed', },
    { no: 55,  system: '217',       type: 'PROCESS',       name: 'Vent Gas Recovery', },
    { no: 56,  system: '217-1',     type: 'PROCESS',       name: 'VGR: Membranes', },
    { no: 57,  system: '217-2',     type: 'PROCESS',       name: 'RecoveTEMP Liquids Column', },
    { no: 58,  system: '218',       type: 'PROCESS',       name: 'Propylene Loop', },
    { no: 59,  system: 'G-367',     type: 'INTERFACES',    name: 'PP from Olefins', },
    { no: 60,  system: 'KM-P2-14',  type: 'KEY_MILESTONE', name: 'GPPE 1: Reactor Wall Treatment & Reinstatement work', },
    { no: 61,  system: '302',       type: 'PROCESS',       name: 'Ethylene', },
    { no: 62,  system: '216',       type: 'PROCESS',       name: 'IPDS', },
    { no: 63,  system: 'L-358',     type: 'UTILITY',       name: 'MP Steam (Cooling Water) - Heat Rx walls', },
    { no: 64,  system: 'KM-P2-15',  type: 'KEY_MILESTONE', name: 'GPPE 1: Start up', },
    { no: 65,  system: '213',       type: 'PROCESS',       name: 'GPPE TEAL', },
    { no: 66,  system: '307',       type: 'INTERFACES',    name: 'PP TEAL', },
    { no: 67,  system: '215',       type: 'PROCESS',       name: 'Kill System', },
    { no: 68,  system: '211',       type: 'PROCESS',       name: 'GPPE Ziegler-Natta catalyst', },
    { no: 69,  system: '219-1',     type: 'PROCESS',       name: 'Resin Degassing', },
    { no: 70,  system: '214',       type: 'PROCESS',       name: 'CA Slurry', },
    { no: 71,  system: '508',       type: 'UTILITY',       name: 'Steam (for purge bin)', },
    { no: 72,  system: 'KMS-TBD5',  type: 'KEY_MILESTONE', name: 'Cycle Gas Cooler Swap Complete', },
    { no: 73,  system: 'PLB-430',   type: 'TEMP',          name: 'Cycle Gas Cooler Swap', },
    { no: 74,  system: 'VBG-929',   type: 'TEMP',          name: 'Cycle Gas Cooler Clean (L-2)', },
    { no: 75,  system: 'R-633',     type: 'INTERFACES',    name: 'Cleaning Contractor', },
    { no: 76,  system: 'KM-yy',     type: 'KEY_MILESTONE', name: 'GPPE2: Extruder Test Run Comp.', },
    { no: 77,  system: 'KM-P2-18',  type: 'KEY_MILESTONE', name: 'GPPE 2: Start up', },
    { no: 78,  system: '212',       type: 'PROCESS',       name: 'Metallocene Catalyst Feed', },
    { no: 79,  system: 'KM-P2-10',  type: 'KEY_MILESTONE', name: 'GPPE 1: Extruder DA/DS Motor Solo Run completed', },
    { no: 80,  system: '232-1',     type: 'PROCESS',       name: 'DS/DA LO System To be ready', },
    { no: 81,  system: 'EZ-747',    type: 'PROCESS',       name: 'Flushing Bldg Ready', },
    { no: 82,  system: 'YM-330',    type: 'INTERFACES',    name: 'PP RTO', },
    { no: 83,  system: 'KMS-TBD9',  type: 'KEY_MILESTONE', name: 'Granules Conveying Test Completed', },
    { no: 84,  system: '221',       type: 'PROCESS',       name: 'Seed Bed Bin System', },
    { no: 85,  system: '237',       type: 'PROCESS',       name: 'GPPE Thermal Oxidizer Feed', },
    { no: 86,  system: '220',       type: 'PROCESS',       name: 'GPPE Granules Conveying', },
    { no: 87,  system: 'HQR-250',   type: 'PROCESS',       name: 'Granule Conveying Comp. (N2)', },
    { no: 88,  system: '407-1',     type: 'UTILITY',       name: 'LP Nitrogen Import / Distr.', },
    { no: 89,  system: 'TEMP6',     type: 'TEMP',          name: 'TEMP: Facilities to transfer granules', },
    { no: 90,  system: 'KMS-TBD10', type: 'KEY_MILESTONE', name: 'PCW Test Completed', },
    { no: 91,  system: '233',       type: 'PROCESS',       name: 'Pellet Water & Drying System', },
    { no: 92,  system: '513',       type: 'UTILITY',       name: 'Demin Water', },
    { no: 93,  system: '232-8',     type: 'PROCESS',       name: 'GPPE Extruder System - Pelletizer', },
    { no: 94,  system: 'TEMP7',     type: 'TEMP',          name: 'Barrel Pump (Capital Spare)', },
    { no: 95,  system: 'TEMP8',     type: 'TEMP',          name: 'Barrel Pump Swap w/ Gear Pump', },
    { no: 96,  system: '420-1',     type: 'UTILITY',       name: 'Clean Waste Water', },
    { no: 97,  system: '436-1',     type: 'UTILITY',       name: 'Storm Water Pond', },
    { no: 98,  system: 'KM-P2-09',  type: 'KEY_MILESTONE', name: 'GPPE 1: Pellet Conveying Test Completed', },
    { no: 99,  system: '234',       type: 'PROCESS',       name: 'GPPE Pellet Conveying', },
    { no: 100, system: '234-1',     type: 'PROCESS',       name: 'GPPE Pellet Conveying - Elutriator', },
    { no: 101, system: '234-2',     type: 'PROCESS',       name: 'GPPE Pellet', },
    { no: 102, system: 'TEMP9',     type: 'TEMP',          name: 'Facilities to Transfer Pellets', },
    { no: 103, system: '407-2',     type: 'UTILITY',       name: 'LP Nitrogen', },
    { no: 104, system: 'KMS-TBD11', type: 'KEY_MILESTONE', name: 'Add. Convey and Fd. to Extruder Completed', },
    { no: 105, system: '226',       type: 'PROCESS',       name: 'Zinc Oxide Additive', },
    { no: 106, system: '225',       type: 'PROCESS',       name: 'Antiblock for GPPE', },
    { no: 107, system: '224',       type: 'PROCESS',       name: 'IRGAFOS-168', },
    { no: 108, system: '227',       type: 'PROCESS',       name: 'SLIP Additive', },
    { no: 109, system: '228',       type: 'PROCESS',       name: 'Zinc Stearate Industrial Water System', },
    { no: 110, system: '229',       type: 'PROCESS',       name: 'PPA Import / Distr.', },
    { no: 111, system: '230',       type: 'PROCESS',       name: 'Additives Conveying Air to', },
    { no: 112, system: '231',       type: 'PROCESS',       name: 'Additives Dust Collection Syst.', },
    { no: 113, system: '407-4',     type: 'UTILITY',       name: 'LP Nitrogen Import / Distr.', },
    { no: 114, system: 'LS-421',    type: 'PROCESS',       name: 'Additives Warehouse Ready', },
    { no: 115, system: 'RDC-454',   type: 'KEY_MILESTONE', name: 'Additives Liquid Conveying', },
    { no: 116, system: '508-1',     type: 'UTILITY',       name: 'LP Steam', },
    { no: 117, system: 'N-281',     type: 'UTILITY',       name: 'Electrical HT', },
    { no: 118, system: 'SMT-757',   type: 'TEMP',          name: 'Elec Heat Tracing', },
    { no: 119, system: '222   ',    type: 'PROCESS',       name: 'IRGANOX-1076 (Liquid) Feed', },
    { no: 120, system: '223    ',   type: 'PROCESS',       name: 'Secondary Antiox. (Liquid)', },
    { no: 121, system: '407-3',     type: 'UTILITY',       name: 'LP Nitrogen Import / Distr.', },
    { no: 122, system: 'KMS-TBD13', type: 'KEY_MILESTONE', name: 'Gear Pump Run Completed', },
    { no: 123, system: '232-3',     type: 'PROCESS',       name: 'GPPE Extruder System - HO', },
    { no: 124, system: '232-4',     type: 'PROCESS',       name: 'GPPE Extruder System - Hydraulic Oil', },
    { no: 125, system: '232-5',     type: 'PROCESS',       name: 'GPPE Extruder System - GB LO', },
    { no: 126, system: '406',       type: 'UTILITY',       name: 'Industrial Water System', },
    { no: 127, system: 'Q-394',     type: 'UTILITY',       name: 'Molten Polymers Collection Sump', },
    { no: 128, system: '507',       type: 'UTILITY',       name: 'MP Steam', },
    { no: 129, system: '506',       type: 'UTILITY',       name: 'HP Steam', },
    { no: 130, system: 'KMS-TBD12', type: 'KEY_MILESTONE', name: 'GPPE1: Extruder Test Run Completed', },
  ],
}