import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1650,
      CanvasHeight: 2000,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,
      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },
    DataItems:  [
      {NO:1,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-6100-6111-01",SUBSYSTEM_DESCR:"Fire Water Pump A ,21-SPF-611101",PRIORITY:"P2",SYS_TYPE:"CER",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",A_OV_TOT:48,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:48,A_OV_PROG:0,B_OV_TOT:113,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:113,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PA_OV_PROG:100,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,ROW_NO:1,COL_NO:2,SCOPE:"TEN Scope"},
      {NO:1,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-6100-6111-02",SUBSYSTEM_DESCR:"Fire Water Pump B ,21-SPF-611201",PRIORITY:"P2",LOSH:"Y",SYS_TYPE:"CER",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",A_OV_TOT:45,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:45,A_OV_PROG:0,B_OV_TOT:117,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:117,B_OV_PROG:0,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ROW_NO:1,COL_NO:3,SCOPE:"TEN Scope"},
      {NO:1,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-6100-6111-03",SUBSYSTEM_DESCR:"Fire Water Pump C ,21-SPF-611301",PRIORITY:"P2",SYS_TYPE:"CER",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"A",A_OV_TOT:47,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:47,A_OV_PROG:0,B_OV_TOT:111,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:111,B_OV_PROG:0,PA_OV_TOT:3,PA_OV_COM:0,PA_OV_REM:3,PA_OV_PROG:0,ROW_NO:1,COL_NO:4,SCOPE:"TEN Scope"},
      {NO:1,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-6600-6610-01",SUBSYSTEM_DESCR:"Emergency Generator 21-G-661001",PRIORITY:"P1",BATCH:"Incentive 1",LOSH:"Y",SYS_TYPE:"CER",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"A",A_OV_TOT:135,A_OV_COM:34,A_OV_COM_LW:11,A_OV_COM_TW:0,A_OV_COM_CLIENT:14,A_OV_REM:101,A_OV_PROG:25.18,B_OV_TOT:149,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:149,B_OV_PROG:0,PA_OV_TOT:5,PA_OV_COM:1,PA_OV_REM:4,PA_OV_PROG:20,PB1_OV_TOT:19,PB1_OV_COM:1,PB1_OV_REM:18,ROW_NO:1,COL_NO:1,SCOPE:"TEN Scope"},
      {NO:1,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-6030-6038-01",SUBSYSTEM_DESCR:"Cargo Loading Computer and Workstation",PRIORITY:"P1",SYS_TYPE:"CER",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",A_OV_TOT:3,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:3,A_OV_PROG:0,ROW_NO:1,COL_NO:3,SCOPE:"COSCO Scope"},
      {NO:1,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-6030-6039-01",SUBSYSTEM_DESCR:"BallastandVoid Gas Sampling",PRIORITY:"P1",LOSH:"Y",SYS_TYPE:"CER",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"A",A_OV_TOT:22,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:22,A_OV_PROG:0,B_OV_TOT:66,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:66,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PA_OV_PROG:0,ROW_NO:1,COL_NO:4,SCOPE:"COSCO Scope"},
      {NO:1,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-6070-6072-01",SUBSYSTEM_DESCR:"Fire Addressable Panels - LQ ,CER and LQ Fire and Gas Addressable Loop-1",PRIORITY:"P1",BATCH:"Incentive 2",LOSH:"Y",SYS_TYPE:"CER",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"N",A_OV_TOT:289,A_OV_COM:25,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:2,A_OV_REM:264,A_OV_PROG:8.65,B_OV_TOT:92,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:92,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PA_OV_PROG:100,PB1_OV_TOT:6,PB1_OV_COM:0,PB1_OV_REM:6,ROW_NO:1,COL_NO:1,SCOPE:"COSCO Scope"},
      {NO:1,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-9921-9922-01",SUBSYSTEM_DESCR:"LQ HVAC UCPs, EDBs, VSDs",PRIORITY:"P1",SYS_TYPE:"CER",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"A",A_OV_TOT:195,A_OV_COM:107,A_OV_COM_LW:6,A_OV_COM_TW:18,A_OV_COM_CLIENT:39,A_OV_REM:88,A_OV_PROG:54.87,B_OV_TOT:78,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:78,B_OV_PROG:0,PA_OV_TOT:10,PA_OV_COM:4,PA_OV_REM:6,PA_OV_PROG:40,PB1_OV_TOT:31,PB1_OV_COM:12,PB1_OV_REM:19,ROW_NO:1,COL_NO:2,SCOPE:"COSCO Scope"},
      {NO:2,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-9970-9971-01",SUBSYSTEM_DESCR:"HULL HVAC UCPs, EDBs, VSDs",PRIORITY:"P1",BATCH:"Incentive 2",SYS_TYPE:"HER",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"A",A_OV_TOT:99,A_OV_COM:40,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:35,A_OV_REM:59,A_OV_PROG:40.4,B_OV_TOT:48,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:48,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_COM:0,PA_OV_REM:1,PA_OV_PROG:0,PB1_OV_TOT:27,PB1_OV_COM:6,PB1_OV_REM:21,ROW_NO:1,COL_NO:3,SCOPE:"All COSCO Scope"},
      {NO:2,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-6030-6037-01",SUBSYSTEM_DESCR:"Tank Gauging System Cabinets and Workstations",PRIORITY:"P1",LOSH:"Y",SYS_TYPE:"HER",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"N",A_OV_TOT:21,A_OV_COM:7,A_OV_COM_LW:2,A_OV_COM_TW:0,A_OV_COM_CLIENT:2,A_OV_REM:14,A_OV_PROG:33.33,B_OV_TOT:7,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:7,B_OV_PROG:0,PB1_OV_TOT:10,PB1_OV_COM:0,PB1_OV_REM:10,ROW_NO:2,COL_NO:3,SCOPE:"All COSCO Scope"},
      {NO:2,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-9330-9333-01",SUBSYSTEM_DESCR:"Cargo and Ballast Pumps HPU and UCP",PRIORITY:"P1A",BATCH:"Incentive 2",SYS_TYPE:"HER",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"A",A_OV_TOT:161,A_OV_COM:55,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:29,A_OV_REM:106,A_OV_PROG:34.16,B_OV_TOT:171,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:171,B_OV_PROG:0,PA_OV_TOT:21,PA_OV_COM:10,PA_OV_REM:11,PA_OV_PROG:47.61,PB1_OV_TOT:28,PB1_OV_COM:5,PB1_OV_REM:23,ROW_NO:1,COL_NO:4,SCOPE:"All COSCO Scope"},
      {NO:2,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-6070-6072-08",SUBSYSTEM_DESCR:"Fire Addressable Panels - Machinery Space ,HER and Machinery Space Fire and Gas Addressable Loop-1",PRIORITY:"P1",BATCH:"Incentive 2",SYS_TYPE:"HER",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"N",A_OV_TOT:203,A_OV_COM:136,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:58,A_OV_REM:67,A_OV_PROG:66.99,B_OV_TOT:88,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:88,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PA_OV_PROG:100,PB1_OV_TOT:33,PB1_OV_COM:3,PB1_OV_REM:30,ROW_NO:1,COL_NO:1,SCOPE:"All COSCO Scope"},
      {NO:2,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-6600-6670-01",SUBSYSTEM_DESCR:"Key service Generator 21-G-667001",PRIORITY:"P1A",BATCH:"Incentive 2",LOSH:"Y",SYS_TYPE:"HER",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"A",A_OV_TOT:62,A_OV_COM:45,A_OV_COM_LW:2,A_OV_COM_TW:1,A_OV_COM_CLIENT:26,A_OV_REM:17,A_OV_PROG:72.58,B_OV_TOT:134,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:134,B_OV_PROG:0,PA_OV_TOT:36,PA_OV_COM:22,PA_OV_REM:14,PA_OV_PROG:61.11,PB1_OV_TOT:23,PB1_OV_COM:10,PB1_OV_REM:13,ROW_NO:2,COL_NO:1,SCOPE:"All COSCO Scope"},
      {NO:2,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-6600-6670-02",SUBSYSTEM_DESCR:"Key service Generator 21-G-667002",PRIORITY:"P1A",BATCH:"Incentive 2",LOSH:"Y",SYS_TYPE:"HER",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"N",A_OV_TOT:56,A_OV_COM:42,A_OV_COM_LW:3,A_OV_COM_TW:0,A_OV_COM_CLIENT:26,A_OV_REM:14,A_OV_PROG:75,B_OV_TOT:110,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:110,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_COM:2,PA_OV_REM:0,PA_OV_PROG:100,PB1_OV_TOT:18,PB1_OV_COM:9,PB1_OV_REM:9,ROW_NO:2,COL_NO:2,SCOPE:"All COSCO Scope"},
      {NO:2,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-8000-8010-01",SUBSYSTEM_DESCR:"Potable Water Maker",PRIORITY:"P2",LOSH:"Y",SYS_TYPE:"HER",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"A",A_OV_TOT:43,A_OV_COM:6,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:6,A_OV_REM:37,A_OV_PROG:13.95,B_OV_TOT:74,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:74,B_OV_PROG:0,PA_OV_TOT:24,PA_OV_COM:15,PA_OV_REM:9,PA_OV_PROG:62.5,PB1_OV_TOT:17,PB1_OV_COM:2,PB1_OV_REM:15,ROW_NO:2,COL_NO:4,SCOPE:"All COSCO Scope"},
      {NO:2,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-8000-8010-02",SUBSYSTEM_DESCR:"Chlorination Package",PRIORITY:"P2",LOSH:"Y",SYS_TYPE:"HER",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",A_OV_TOT:5,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:5,A_OV_PROG:0,B_OV_TOT:7,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:7,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PA_OV_PROG:100,ROW_NO:3,COL_NO:1,SCOPE:"All COSCO Scope"},
      {NO:2,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-8000-8012-01",SUBSYSTEM_DESCR:"Rehardener and UV Sterilizer",PRIORITY:"P2",LOSH:"Y",SYS_TYPE:"HER",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"A",A_OV_TOT:12,A_OV_COM:1,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:11,A_OV_PROG:8.33,B_OV_TOT:9,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:9,B_OV_PROG:0,PA_OV_TOT:4,PA_OV_COM:0,PA_OV_REM:4,PA_OV_PROG:0,ROW_NO:3,COL_NO:2,SCOPE:"All COSCO Scope"},
      {NO:2,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-8000-8013-01",SUBSYSTEM_DESCR:"Hot Water Calorifiers",PRIORITY:"P2",LOSH:"Y",SYS_TYPE:"HER",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"A",A_OV_TOT:17,A_OV_COM:6,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:4,A_OV_REM:11,A_OV_PROG:35.29,B_OV_TOT:26,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:26,B_OV_PROG:0,PA_OV_TOT:22,PA_OV_COM:16,PA_OV_REM:6,PA_OV_PROG:72.72,PB1_OV_TOT:4,PB1_OV_COM:2,PB1_OV_REM:2,ROW_NO:3,COL_NO:3,SCOPE:"All COSCO Scope"},
      {NO:2,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-8090-8090-01",SUBSYSTEM_DESCR:"Sewage Treatment Package",PRIORITY:"P2",SYS_TYPE:"HER",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"A",A_OV_TOT:10,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:10,A_OV_PROG:0,B_OV_TOT:24,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:24,B_OV_PROG:0,PA_OV_TOT:16,PA_OV_COM:13,PA_OV_REM:3,PA_OV_PROG:81.25,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,ROW_NO:3,COL_NO:4,SCOPE:"All COSCO Scope"},
      {NO:2,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-8100-8120-01",SUBSYSTEM_DESCR:"HULL Air Compressor 21-Z-811002",PRIORITY:"P2",BATCH:"Incentive 1",LOSH:"Y",SYS_TYPE:"HER",SUBSYSTEM_STATUS:"Loop Test Prerequisites in progress",PUNCH_STATUS:"A",A_OV_TOT:24,A_OV_COM:14,A_OV_COM_LW:1,A_OV_COM_TW:1,A_OV_COM_CLIENT:9,A_OV_REM:10,A_OV_PROG:58.33,B_OV_TOT:44,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:44,B_OV_PROG:0,PA_OV_TOT:11,PA_OV_COM:9,PA_OV_REM:2,PA_OV_PROG:81.81,PB1_OV_TOT:10,PB1_OV_COM:2,PB1_OV_REM:8,ROW_NO:1,COL_NO:2,SCOPE:"All COSCO Scope"},
      {NO:3,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-8100-8122-01",SUBSYSTEM_DESCR:"Topside Air Compressor 21-Z-811101",PRIORITY:"P2",LOSH:"Y",SYS_TYPE:"LER",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",A_OV_TOT:35,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:35,A_OV_PROG:0,B_OV_TOT:48,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:48,B_OV_PROG:0,PB1_OV_TOT:6,PB1_OV_COM:0,PB1_OV_REM:6,PB3_OV_TOT:1,PB3_OV_COM:0,PB3_OV_REM:1,ROW_NO:1,COL_NO:2,SCOPE:"All TEN Scope"},
      {NO:3,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-8100-8122-02",SUBSYSTEM_DESCR:"Topside Air Compressor 21-Z-811201",PRIORITY:"P2",LOSH:"Y",SYS_TYPE:"LER",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"A",A_OV_TOT:13,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:13,A_OV_PROG:0,B_OV_TOT:48,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:48,B_OV_PROG:0,PA_OV_TOT:5,PA_OV_COM:1,PA_OV_REM:4,PA_OV_PROG:20,PB1_OV_TOT:2,PB1_OV_COM:0,PB1_OV_REM:2,ROW_NO:1,COL_NO:3,SCOPE:"All TEN Scope"},
      {NO:3,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-8100-8122-03",SUBSYSTEM_DESCR:"Topside Air Compressor 21-Z-811301",PRIORITY:"P2",LOSH:"Y",SYS_TYPE:"LER",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",A_OV_TOT:13,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:13,A_OV_PROG:0,B_OV_TOT:48,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:48,B_OV_PROG:0,ROW_NO:1,COL_NO:4,SCOPE:"All TEN Scope"},
      {NO:3,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-8100-8122-04",SUBSYSTEM_DESCR:"Topside Air Driers 21-Z-812001",PRIORITY:"P2",LOSH:"Y",SYS_TYPE:"LER",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",A_OV_TOT:9,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:9,A_OV_PROG:0,B_OV_TOT:24,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:24,B_OV_PROG:0,PB1_OV_TOT:6,PB1_OV_COM:0,PB1_OV_REM:6,ROW_NO:2,COL_NO:1,SCOPE:"All TEN Scope"},
      {NO:3,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-8160-8160-01",SUBSYSTEM_DESCR:"Inert Gas Generator",PRIORITY:"P2",LOSH:"Y",SYS_TYPE:"LER",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"A",A_OV_TOT:66,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:66,A_OV_PROG:0,B_OV_TOT:59,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:59,B_OV_PROG:0,PA_OV_TOT:5,PA_OV_COM:1,PA_OV_REM:4,PA_OV_PROG:20,PB1_OV_TOT:6,PB1_OV_COM:0,PB1_OV_REM:6,ROW_NO:4,COL_NO:1,SCOPE:"All TEN Scope"},
      {NO:3,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-8600-8611-01",SUBSYSTEM_DESCR:"GTG 1 and WHRU1",PRIORITY:"P1",LOSH:"Y",SYS_TYPE:"LER",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",A_OV_TOT:223,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:223,A_OV_PROG:0,B_OV_TOT:194,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:194,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_COM:1,PA_OV_REM:0,PA_OV_PROG:100,PB1_OV_TOT:10,PB1_OV_COM:0,PB1_OV_REM:10,ROW_NO:3,COL_NO:1,SCOPE:"All TEN Scope"},
      {NO:3,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-8600-8612-01",SUBSYSTEM_DESCR:"GTG 2 and WHRU2",PRIORITY:"P1",LOSH:"Y",SYS_TYPE:"LER",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",A_OV_TOT:219,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:219,A_OV_PROG:0,B_OV_TOT:196,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:196,B_OV_PROG:0,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ROW_NO:3,COL_NO:2,SCOPE:"All TEN Scope"},
      {NO:3,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-8600-8613-01",SUBSYSTEM_DESCR:"GTG 3 and WHRU3",PRIORITY:"P1",LOSH:"Y",SYS_TYPE:"LER",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",A_OV_TOT:224,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:224,A_OV_PROG:0,B_OV_TOT:193,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:193,B_OV_PROG:0,PB1_OV_TOT:3,PB1_OV_COM:0,PB1_OV_REM:3,ROW_NO:3,COL_NO:3,SCOPE:"All TEN Scope"},
      {NO:3,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-8600-8614-01",SUBSYSTEM_DESCR:"GTG 4 and WHRU4",PRIORITY:"P1",LOSH:"Y",SYS_TYPE:"LER",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",A_OV_TOT:227,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:227,A_OV_PROG:0,B_OV_TOT:195,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:195,B_OV_PROG:0,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ROW_NO:3,COL_NO:4,SCOPE:"All TEN Scope"},
      {NO:3,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-8800-8805-01",SUBSYSTEM_DESCR:"Electrochlorination Unit",PRIORITY:"P3",LOSH:"Y",SYS_TYPE:"LER",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",A_OV_TOT:85,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:85,A_OV_PROG:0,B_OV_TOT:54,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:54,B_OV_PROG:0,PA_OV_TOT:4,PA_OV_COM:4,PA_OV_REM:0,PA_OV_PROG:100,PB1_OV_TOT:7,PB1_OV_COM:4,PB1_OV_REM:3,ROW_NO:2,COL_NO:4,SCOPE:"All TEN Scope"},
      {NO:3,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-6070-6072-11",SUBSYSTEM_DESCR:"Fire Addressable Panels  and - Addressable Fire and Gas Detectors - Topsides ME05",PRIORITY:"P1",BATCH:"Incentive 2",SYS_TYPE:"LER",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",A_OV_TOT:225,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:225,A_OV_PROG:0,B_OV_TOT:79,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:79,B_OV_PROG:0,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ROW_NO:1,COL_NO:1,SCOPE:"All TEN Scope"},
      {NO:3,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-2400-2440-01",SUBSYSTEM_DESCR:"Condensate Metering",PRIORITY:"P3",LOSH:"Y",SYS_TYPE:"LER",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"A",A_OV_TOT:59,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:59,A_OV_PROG:0,B_OV_TOT:70,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:70,B_OV_PROG:0,PA_OV_TOT:6,PA_OV_COM:1,PA_OV_REM:5,PA_OV_PROG:16.66,PB1_OV_TOT:21,PB1_OV_COM:1,PB1_OV_REM:20,ROW_NO:5,COL_NO:2,SCOPE:"All TEN Scope"},
      {NO:3,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-2400-2460-01",SUBSYSTEM_DESCR:"Condensate Offloading",PRIORITY:"P2",LOSH:"Y",SYS_TYPE:"LER",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",A_OV_TOT:76,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:76,A_OV_PROG:0,B_OV_TOT:66,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:66,B_OV_PROG:0,PB1_OV_TOT:6,PB1_OV_COM:0,PB1_OV_REM:6,ROW_NO:5,COL_NO:3,SCOPE:"All TEN Scope"},
      {NO:3,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-3040-3040-01",SUBSYSTEM_DESCR:"HPandMP Gas Compressors, UCP, VSD and Electrical ControlandSwitchboards",PRIORITY:"P3",LOSH:"Y",SYS_TYPE:"LER",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"A",A_OV_TOT:113,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:113,A_OV_PROG:0,B_OV_TOT:197,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:197,B_OV_PROG:0,PA_OV_TOT:34,PA_OV_COM:13,PA_OV_REM:21,PA_OV_PROG:38.23,PB1_OV_TOT:5,PB1_OV_COM:0,PB1_OV_REM:5,ROW_NO:4,COL_NO:4,SCOPE:"All TEN Scope"},
      {NO:3,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-3040-3041-01",SUBSYSTEM_DESCR:"LPandLLP Gas Compressors , UCP, VSD and Electrical ControlandSwitchboards",PRIORITY:"P3",LOSH:"Y",SYS_TYPE:"LER",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"A",A_OV_TOT:65,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:65,A_OV_PROG:0,B_OV_TOT:131,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:131,B_OV_PROG:0,PA_OV_TOT:7,PA_OV_COM:0,PA_OV_REM:7,PA_OV_PROG:0,PB1_OV_TOT:13,PB1_OV_COM:1,PB1_OV_REM:12,ROW_NO:4,COL_NO:3,SCOPE:"All TEN Scope"},
      {NO:3,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-3330-3330-03",SUBSYSTEM_DESCR:"Turbo Expander, Recompressor",PRIORITY:"P3",LOSH:"Y",SYS_TYPE:"LER",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"A",A_OV_TOT:219,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:219,A_OV_PROG:0,B_OV_TOT:99,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:99,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_COM:1,PA_OV_REM:1,PA_OV_PROG:50,PB1_OV_TOT:4,PB1_OV_COM:0,PB1_OV_REM:4,ROW_NO:4,COL_NO:2,SCOPE:"All TEN Scope"},
      {NO:3,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-3330-3410-02",SUBSYSTEM_DESCR:"Export Gas Metering and Export Risers",PRIORITY:"P2",LOSH:"Y",SYS_TYPE:"LER",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",A_OV_TOT:85,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:85,A_OV_PROG:0,B_OV_TOT:23,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:23,B_OV_PROG:0,ROW_NO:5,COL_NO:1,SCOPE:"All TEN Scope"},
      {NO:3,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-6030-6034-01",SUBSYSTEM_DESCR:"Machinery Protection System ,MPS",PRIORITY:"P1",SYS_TYPE:"LER",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",A_OV_TOT:21,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:21,A_OV_PROG:0,B_OV_TOT:8,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:8,B_OV_PROG:0,PB1_OV_TOT:1,PB1_OV_COM:0,PB1_OV_REM:1,ROW_NO:2,COL_NO:3,SCOPE:"All TEN Scope"},
      {NO:3,GROUP:"P3 Package Infrastructure for Loop Test Commencement & P3 SAT Completion",SUBSYSTEM:"21-9976-9977-01",SUBSYSTEM_DESCR:"Topsides HVAC UCPs, EDBs, VSDs",PRIORITY:"P1",BATCH:"Incentive 2",LOSH:"Y",SYS_TYPE:"LER",SUBSYSTEM_STATUS:"Not Started",PUNCH_STATUS:"N",A_OV_TOT:27,A_OV_COM:0,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:27,A_OV_PROG:0,B_OV_TOT:37,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:37,B_OV_PROG:0,PB1_OV_TOT:5,PB1_OV_COM:0,PB1_OV_REM:5,ROW_NO:2,COL_NO:2,SCOPE:"All TEN Scope"}
      ],
}