import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 280,
      CanvasHeight: 260,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

    
    DataItems: [
    ],
    

    Queries: {
      // ITR A Overall
      SQL1: [
        {TOTAL:57,ACTUAL:0,SIGNED: 80,REMAIN:57,PROG:0}
      ],
      // Punch Status
      SQL2:  [
        {CAT:"A",TOTAL:602,ACTUAL:20,REMAIN:582,PROG:3.32},
        {CAT:"B1",TOTAL:103,ACTUAL:1,REMAIN:102,PROG:0.97}
      ],
      // QCF
      SQL3: [
        {TOTAL:24,ACTUAL:0,SIGNED: 99,REMAIN:24,PROG:0}
      ],
      // Vendor
      SQL4: [
        {TOTAL:24,ACTUAL:0,SIGNED: 99,REMAIN:24,PROG:0}
      ]
    },
}