import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 860,
      CanvasHeight: 310,
      CanvasBorderWeight: 0,
      CanvasBorderColor: 'Transparent',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 860,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: 'Transparent',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },

    DataItems: [
      // C10UH
      {activity: 'Steel Cutting',         planStart: '2018-11-12',  planFinish: '2018-12-25', actualStart: '2018-11-15',  actualFinish: '2018-12-25', planProg: 100, actualProg: 100},
      {activity: 'Block Assembly',        planStart: '2018-11-23',  planFinish: '2019-02-20', actualStart: '2018-11-23',  actualFinish: '2019-02-20', planProg: 100, actualProg: 100},
      {activity: 'Block Painting & PFP',  planStart: '2019-03-13',  planFinish: '2019-04-01', actualStart: '2019-03-13',  actualFinish: '2019-04-01', planProg: 100, actualProg: 100},
      {activity: 'Block Erection',        planStart: '2019-07-01',  planFinish: '2019-07-01', actualStart: '2019-06-27',  actualFinish: '2019-06-27', planProg: 100, actualProg: 100},
    
    ],
}