import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 450,
      CanvasHeight: 280,
      CanvasBorderWeight: 0.5,
      CanvasBorderColor: '#E0E0E0',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },


    DataItems: [
      {MONTH:"June",TOTAL:10,WEEKS2:4,WEEKS4:6,WEEKS6:8,WEEKS8:3,WEEKS10:9,WEEKS12:3,OVER_12WEEKS:5}
    ],
}