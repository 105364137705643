import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 840,
    CanvasHeight: 500,
    CanvasBorderWeight: 0.3,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.2,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 310,
    CanvasChartHeight: 270,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: 'Transparent',
    CanvasChartFillColor: 'Transparent',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  Text: {
    Text: [],
    Filter: [],
    Sql: [
      {
        query: "SELECT 'Datasource: Shop Drawing Index 04-Oct-20' TITLE", 
        text: 'Datasource: Shop Drawing Index 04-Oct-20',
        x: 10,
        y: 10,
        font: 'roboto',
        style: 'regular',
        size: 12,
        color: '#757575',
        align: 'start'
      },
    ]
  },
  DataItems: [
    {NO:1,REV:"C01",DISC:"E",DWG_NO:"N999-ODCS-MS02D-E-FM-1202",DWG_DESCR:"MS BOTTOM LEVEL FALSE FLOOR DRAWING OF ELECTRICAL EQUIPMENT SEAT 机舱底部升高平台电气设备支架制作图",START_PLAN:"2020-02-17",START_ACTUAL:"2020-02-17",ISSUED_PLAN:"2020-02-17",ISSUED_ACTUAL:"2020-02-17",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/A16223F33CACCF0EC12585130007FA8B?openDocument"},
{NO:2,REV:"C01",DISC:"E",DWG_NO:"N999-ODCS-MS02D-E-FM-1201",DWG_DESCR:"MS BOTTOM LEVEL FALSE FLOOR DRAWING OF TRAY SUPPORT&PENETRATION 机舱底部升高平台电缆贯通件及托架支撑件制作图",START_PLAN:"2020-02-17",START_ACTUAL:"2020-02-17",ISSUED_PLAN:"2020-02-17",ISSUED_ACTUAL:"2020-02-17",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/872810BE2E64E24FC12585130007BB76?openDocument"},
{NO:3,REV:"C02",DISC:"E",DWG_NO:"N999-ODCS-MS02-E-CR-4304",DWG_DESCR:"MS BOTTOM LEVEL AREA CABLE LIST 区电缆手册",START_PLAN:"2021-02-18",START_ACTUAL:"2021-02-18",ISSUED_PLAN:"2021-02-18",ISSUED_ACTUAL:"2021-02-18",LINK:"http://xdb-backend002.apps.technipenergies.com/Projectd/077333c001/pj0077333c0018.nsf/DOOF_rsAllUNID/947E3D6C2B72EB1FC12586810003A3B9?openDocument"},
{NO:4,REV:"C02",DISC:"E",DWG_NO:"N999-ODCS-MS02-E-FM-1201",DWG_DESCR:"MS BOTTOM LEVEL AREA DRAWING OF TRAY SUPPORT&PENETRATION 区域电缆贯通件及托架支撑件制作图",START_PLAN:"2020-01-20",START_ACTUAL:"2020-01-20",ISSUED_PLAN:"2020-01-20",ISSUED_ACTUAL:"2020-01-20",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/395666F904580188C12584F6000D65F4?openDocument"},
{NO:5,REV:"C02",DISC:"E",DWG_NO:"N999-ODCS-MS02U-E-FM-1202",DWG_DESCR:"MS BOTTOM LEVEL UP AREA DRAWING OF ELECTRICAL EQUIPMENT SEAT 机舱底部反顶电气设备支架制作图",START_PLAN:"2020-03-16",START_ACTUAL:"2020-03-16",ISSUED_PLAN:"2020-03-16",ISSUED_ACTUAL:"2020-03-16",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/D158776608A8DC1DC125852D002FCB49?openDocument"},
{NO:6,REV:"C01",DISC:"L",DWG_NO:"N999-ODCS-MS02-L-FM-3003",DWG_DESCR:"MS02 BLOCK PIPING SUPPORT DRAWING MS02分段管支架制作图",START_PLAN:"2020-07-25",START_ACTUAL:"2020-07-25",ISSUED_PLAN:"2020-07-25",ISSUED_ACTUAL:"2020-07-25",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/4CCBA5DE874B60C4C12585B50003A81D?openDocument"},
{NO:7,REV:"C01",DISC:"O",DWG_NO:"N999-ODCS-MS02-O-IM-4302",DWG_DESCR:"MS02 Area Outfitting Installation DWG",START_PLAN:"2020-06-30",START_ACTUAL:"2020-06-30",ISSUED_PLAN:"2020-06-30",ISSUED_ACTUAL:"2020-06-30",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/6DE6B39A690F8790C125859800307F6A?openDocument"},
{NO:8,REV:"C01",DISC:"O",DWG_NO:"N999-ODCS-MS02-O-IM-4304",DWG_DESCR:"MS002 Area Outfitting Installation DWG",START_PLAN:"2020-10-23",START_ACTUAL:"2020-10-23",ISSUED_PLAN:"2020-10-23",ISSUED_ACTUAL:"2020-10-23",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/8CC014B725500E6EC125860F00257048?openDocument"},
{NO:9,REV:"C01",DISC:"V",DWG_NO:"N999-ODCS-MS02-V-FM-2102",DWG_DESCR:"MS02 AREA VENTILATION SUPPORTS FABRICATION DRAWING",START_PLAN:"2020-01-20",START_ACTUAL:"2020-01-20",ISSUED_PLAN:"2020-01-20",ISSUED_ACTUAL:"2020-01-20",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/8BBAFD1381DE7887C12584F5000C9D8F?openDocument"},
{NO:10,REV:"C01",DISC:"V",DWG_NO:"N999-ODCS-MS02-V-FM-2103",DWG_DESCR:"MS02 AREA VENTILATION DUCTS PENETRATION FABRICATION DRAWING",START_PLAN:"2020-01-21",START_ACTUAL:"2020-01-21",ISSUED_PLAN:"2020-01-21",ISSUED_ACTUAL:"2020-01-21",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/1A042E2E749C6D3EC12584F6000AD21C?openDocument"},
{NO:11,REV:"C02",DISC:"V",DWG_NO:"N999-ODCS-MS02-V-IM-3301",DWG_DESCR:"MS02 AREA VENTILATION DUCTS ARRANGEMENT DRAWING",START_PLAN:"2021-01-08",START_ACTUAL:"2021-01-08",ISSUED_PLAN:"2021-01-08",ISSUED_ACTUAL:"2021-01-08",LINK:"http://xdb-backend002.apps.technipfmc.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/4F2C9C3C1B3B6D5BC1258657002F6675?openDocument"},
{NO:12,REV:"C01",DISC:"E",DWG_NO:"N999-ODCS-MS02-E-EP-4306",DWG_DESCR:"MS BOTTOM LEVEL AREA CABLE ROUTING DRAWING 区敷线图",START_PLAN:"2021-01-04",START_ACTUAL:"2021-01-04",ISSUED_PLAN:"2021-01-04",ISSUED_ACTUAL:"2021-01-04",LINK:"http://xdb-backend002.apps.technipfmc.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/8AA29AB5256A1BD3C125865300336B45?openDocument"},
{NO:13,REV:"C03",DISC:"E",DWG_NO:"N999-ODCS-MS02-E-FM-4201",DWG_DESCR:"MS02 AREA DRAWING OF TRAY SUPPORT&PENETRATION 区域电缆贯通件及托架支撑件制作图",START_PLAN:"2021-02-03",START_ACTUAL:"2021-02-03",ISSUED_PLAN:"2021-02-03",ISSUED_ACTUAL:"2021-02-03",LINK:"http://xdb-backend002.apps.technipenergies.com/Projectd/077333c001/pj0077333c0018.nsf/DOOF_rsAllUNID/53533119448A2B26C1258672000EDAB1?openDocument"},
{NO:14,REV:"C03",DISC:"E",DWG_NO:"N999-ODCS-MS02-E-FM-4202",DWG_DESCR:"MS02 AREA DRAWING OF ELECTRICAL EQUIPMENT SEAT 区域电气设备支架制作图",START_PLAN:"2021-02-03",START_ACTUAL:"2021-02-03",ISSUED_PLAN:"2021-02-03",ISSUED_ACTUAL:"2021-02-03",LINK:"http://xdb-backend002.apps.technipenergies.com/Projectd/077333c001/pj0077333c0018.nsf/DOOF_rsAllUNID/C332E0001DB9136FC1258672000F06DB?openDocument"},
{NO:15,REV:"C02",DISC:"E",DWG_NO:"N999-ODCS-MS02-E-IM-4303",DWG_DESCR:"MS BOTTOM LEVEL AREA DRAWING OF ELECTRICAL EQUIPMENT ARRANGEMENT 区域电气铁舾件安装图",START_PLAN:"2020-03-20",START_ACTUAL:"2020-03-20",ISSUED_PLAN:"2020-03-20",ISSUED_ACTUAL:"2020-03-20",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/33307E9990E7D567C1258531002F6700?openDocument"},
{NO:16,REV:"C01",DISC:"L",DWG_NO:"N999-ODCS-MS02-L-MP-3004-01",DWG_DESCR:"MS02 AREA PIPING SUPPORT PALLET LIST MS02区域管支架托盘表",START_PLAN:"2020-09-27",START_ACTUAL:"2020-09-27",ISSUED_PLAN:"2020-09-27",ISSUED_ACTUAL:"2020-09-27",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/1FF5954A642B960EC12585FF00142641?openDocument"},
{NO:17,REV:"C02",DISC:"O",DWG_NO:"N999-ODCS-MS02-O-IM-4401",DWG_DESCR:"MS02 Area Outfitting Installation DWG-EW01",START_PLAN:"2020-05-29",START_ACTUAL:"2020-05-29",ISSUED_PLAN:"2020-05-29",ISSUED_ACTUAL:"2020-05-29",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/C34BDD8EF502BC38C125857700133126?openDocument"},
{NO:18,REV:"C01",DISC:"E",DWG_NO:"N999-ODCS-MS02-E-EE-4305",DWG_DESCR:"MS BOTTOM LEVEL AREA EQUIPMENT LIST 区设备清单",START_PLAN:"2021-01-04",START_ACTUAL:"2021-01-04",ISSUED_PLAN:"2021-01-04",ISSUED_ACTUAL:"2021-01-04",LINK:"http://xdb-backend002.apps.technipfmc.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/D940A34E912B95ABC125865300333AD9?openDocument"},
{NO:19,REV:"C01",DISC:"L",DWG_NO:"N999-ODCS-MS02-L-FM-3001",DWG_DESCR:"MS02 BLOCK PIPING SPOOLS DRAWING MS02分段管子制作图",START_PLAN:"2020-08-19",START_ACTUAL:"2020-08-19",ISSUED_PLAN:"2020-08-19",ISSUED_ACTUAL:"2020-08-19",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333c001/pj0077333c0018.nsf/DOOF_rsAllUNID/67E8B3A08FB10F8BC12585C9002A6DFF?openDocument"},
{NO:20,REV:"C01",DISC:"L",DWG_NO:"N999-ODCS-MS02-L-IM-3005-01",DWG_DESCR:"MS02 AREA PIPING ARRANGEMENT DRAWING MS02区域管子布置图",START_PLAN:"2020-09-27",START_ACTUAL:"2020-09-27",ISSUED_PLAN:"2020-09-27",ISSUED_ACTUAL:"2020-09-27",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/181D9131C46BB54EC12585FF0013E9AD?openDocument"},
{NO:21,REV:"C01",DISC:"L",DWG_NO:"N999-ODCS-MS02-L-PP-3006",DWG_DESCR:"MS02 BLOCK PIPING PENETRATION PLAN MS02分段管子开孔图",START_PLAN:"2020-08-26",START_ACTUAL:"2020-08-26",ISSUED_PLAN:"2020-08-26",ISSUED_ACTUAL:"2020-08-26",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/C23234CAA81714D4C12585D2000EBA3E?openDocument"},
{NO:22,REV:"C02",DISC:"E",DWG_NO:"N999-ODCS-MS02U-E-FM-1201",DWG_DESCR:"MS BOTTOM LEVEL UP AREA DRAWING OF TRAY SUPPORT&PENETRATION 机舱底部反顶电缆贯通件及托架支撑件制作图",START_PLAN:"2020-03-16",START_ACTUAL:"2020-03-16",ISSUED_PLAN:"2020-03-16",ISSUED_ACTUAL:"2020-03-16",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/6AA240CED6E8BC8FC125852D002F86FA?openDocument"},
{NO:23,REV:"C03",DISC:"E",DWG_NO:"N999-ODCS-MS02-E-IM-4307",DWG_DESCR:"MS02 AREA DRAWING OF ELECTRICAL EQUIPMENT ARRANGEMENT 区域电气铁舾件安装图",START_PLAN:"2021-02-07",START_ACTUAL:"2021-02-07",ISSUED_PLAN:"2021-02-07",ISSUED_ACTUAL:"2021-02-07",LINK:"http://xdb-backend002.apps.technipenergies.com/Projectd/077333c001/pj0077333c0018.nsf/DOOF_rsAllUNID/F7918D948BC042ABC1258676000CE7BD?openDocument"},
{NO:24,REV:"C02",DISC:"E",DWG_NO:"N999-ODCS-MS02D-E-IM-4303",DWG_DESCR:"MS BOTTOM LEVEL FALSE FLOOR DRAWING OF ELECTRICAL EQUIPMENT ARRANGEMENT 机舱底部升高平台电气铁舾件安装图",START_PLAN:"2020-03-30",START_ACTUAL:"2020-03-30",ISSUED_PLAN:"2020-03-30",ISSUED_ACTUAL:"2020-03-30",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/D4F17036621580BDC125853B002BB581?openDocument"},
{NO:25,REV:"C01",DISC:"L",DWG_NO:"N999-ODCS-MS02-L-FM-3001-01",DWG_DESCR:"MS02 AREA PIPING SPOOLS DRAWING MS02区域管子制作图",START_PLAN:"2020-09-16",START_ACTUAL:"2020-09-16",ISSUED_PLAN:"2020-09-16",ISSUED_ACTUAL:"2020-09-16",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/0EDE9740EB59BA92C12585ED002EAA03?openDocument"},
{NO:26,REV:"C01",DISC:"L",DWG_NO:"N999-ODCS-MS02-L-FM-3003-01",DWG_DESCR:"MS02 AREA PIPING SUPPORT DRAWING MS02区域管支架制作图",START_PLAN:"2020-09-27",START_ACTUAL:"2020-09-27",ISSUED_PLAN:"2020-09-27",ISSUED_ACTUAL:"2020-09-27",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/64386D59BD165504C12585FF0013B00A?openDocument"},
{NO:27,REV:"C01",DISC:"L",DWG_NO:"N999-ODCS-MS02-L-IM-3005",DWG_DESCR:"MS02 BLOCK PIPING ARRANGEMENT DRAWING MS02分段管子布置图",START_PLAN:"2020-08-12",START_ACTUAL:"2020-08-12",ISSUED_PLAN:"2020-08-12",ISSUED_ACTUAL:"2020-08-12",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/6375A515BAD7FD42C12585C3002E792A?openDocument"},
{NO:28,REV:"C01",DISC:"L",DWG_NO:"N999-ODCS-MS02-L-MP-3002",DWG_DESCR:"MS02 BLOCK PIPING PALLET LIST MS02分段管子托盘表",START_PLAN:"2020-08-19",START_ACTUAL:"2020-08-19",ISSUED_PLAN:"2020-08-19",ISSUED_ACTUAL:"2020-08-19",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333c001/pj0077333c0018.nsf/DOOF_rsAllUNID/6A7625FB3F9149FCC12585C9002B7DF7?openDocument"},
{NO:29,REV:"C01",DISC:"L",DWG_NO:"N999-ODCS-MS02-L-MP-3004",DWG_DESCR:"MS02 BLOCK PIPING SUPPORT PALLET LIST MS02分段管支架托盘表",START_PLAN:"2020-07-25",START_ACTUAL:"2020-07-25",ISSUED_PLAN:"2020-07-25",ISSUED_ACTUAL:"2020-07-25",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/B0A00A6092D4A587C12585B50003FFAF?openDocument"},
{NO:30,REV:"C01",DISC:"O",DWG_NO:"N999-ODCS-MS02-O-IM-4301",DWG_DESCR:"MS02 Area Outfitting Installation DWG-ME01",START_PLAN:"2020-04-29",START_ACTUAL:"2020-04-29",ISSUED_PLAN:"2020-04-29",ISSUED_ACTUAL:"2020-04-29",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/7680143A09C9EAA8C125855A002B0B67?openDocument"},
{NO:31,REV:"C01",DISC:"L",DWG_NO:"N999-ODCS-131-L-FM-2003",DWG_DESCR:"131 BLOCK PIPING SUPPORT DRAWING 131分段管支架制作图",START_PLAN:"2019-09-27",START_ACTUAL:"2019-09-27",ISSUED_PLAN:"2019-09-27",ISSUED_ACTUAL:"2019-09-27",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/854F472623C2ABA2C12584840011AC7B?openDocument"},
{NO:32,REV:"C03",DISC:"L",DWG_NO:"N999-ODCS-131-L-MP-2002",DWG_DESCR:"131 BLOCK PIPING PALLET LIST 131分段管子托盘表",START_PLAN:"2020-01-08",START_ACTUAL:"2020-01-08",ISSUED_PLAN:"2020-01-08",ISSUED_ACTUAL:"2020-01-08",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/3EA63D5A4268E6B7C12584EA002AAD5B?openDocument"},
{NO:33,REV:"C01",DISC:"O",DWG_NO:"N999-ODCS-131-O-IM-1101",DWG_DESCR:"131 Block Assembly Outfitting Installation DWG",START_PLAN:"2019-09-06",START_ACTUAL:"2019-09-06",ISSUED_PLAN:"2019-09-06",ISSUED_ACTUAL:"2019-09-06",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/571F2E502B284520C12584700009BC2B?openDocument"},
{NO:34,REV:"C05",DISC:"E",DWG_NO:"N999-ODCS-WB02-E-IM-2303",DWG_DESCR:"WB02 BLOCK DRAWING OF ELECTRICAL EQUIPMENT ARRANGEMENT 分段电气铁舾件安装图",START_PLAN:"2021-03-04",START_ACTUAL:"2021-03-04",ISSUED_PLAN:"2021-03-04",ISSUED_ACTUAL:"2021-03-04",LINK:"http://xdb-backend002.apps.technipenergies.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/D46F822107ACD75BC12586920001B56E?openDocument"},
{NO:35,REV:"C02",DISC:"W",DWG_NO:"N999-PM-155-W-WE-0001",DWG_DESCR:"155 Block Scaffold Part Arrangement",START_PLAN:"2019-11-22",START_ACTUAL:"2019-11-22",ISSUED_PLAN:"2019-11-22",ISSUED_ACTUAL:"2019-11-22",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/46B67FADBE7A70C6C12584BE00102B75?openDocument"},
{NO:36,REV:"C01",DISC:"L",DWG_NO:"N999-ODCS-155-L-FM-2003",DWG_DESCR:"155 BLOCK PIPING SUPPORT DRAWING 155分段管支架制作图",START_PLAN:"2019-09-26",START_ACTUAL:"2019-09-26",ISSUED_PLAN:"2019-09-26",ISSUED_ACTUAL:"2019-09-26",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/D20099481C3602DBC1258484001C6297?openDocument"},
{NO:37,REV:"C01",DISC:"H",DWG_NO:"N999-ODCS-155-H-BD-1101",DWG_DESCR:"155 BLOCK BLOCK PARTS DETAILS & TRACEABILITY RECORD",START_PLAN:"2019-09-12",START_ACTUAL:"2019-09-12",ISSUED_PLAN:"2019-09-12",ISSUED_ACTUAL:"2019-09-12",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/C519BEB790213E90C1258473001092B2?openDocument"},
{NO:38,REV:"C01",DISC:"H",DWG_NO:"N999-ODCS-155-H-BH-1101",DWG_DESCR:"155 BLOCK MATERIAL LIST",START_PLAN:"2019-09-12",START_ACTUAL:"2019-09-12",ISSUED_PLAN:"2019-09-12",ISSUED_ACTUAL:"2019-09-12",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/CDE200848C72B6F0C12584730010FFC8?openDocument"},
{NO:39,REV:"C01",DISC:"H",DWG_NO:"N999-ODCS-155-H-BL-1101",DWG_DESCR:"155 BLOCK SUB ASSEMBLY",START_PLAN:"2019-09-12",START_ACTUAL:"2019-09-12",ISSUED_PLAN:"2019-09-12",ISSUED_ACTUAL:"2019-09-12",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/AF7C32B5ADFB5C2FC125847300115F55?openDocument"},
{NO:40,REV:"C01",DISC:"H",DWG_NO:"N999-ODCS-155-H-BF-1101",DWG_DESCR:"155 BLOCK PLATES JOINT DRAWING",START_PLAN:"2019-09-12",START_ACTUAL:"2019-09-12",ISSUED_PLAN:"2019-09-12",ISSUED_ACTUAL:"2019-09-12",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/E4B35113D5B4E48BC12584730010BC4F?openDocument"},
{NO:41,REV:"C01",DISC:"H",DWG_NO:"N999-ODCS-155-H-BJ-1101",DWG_DESCR:"155 BLOCK ADVANCE TIGHTNESS TEST",START_PLAN:"2019-09-12",START_ACTUAL:"2019-09-12",ISSUED_PLAN:"2019-09-12",ISSUED_ACTUAL:"2019-09-12",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/3FDD7EE3380A1007C125847300111E7E?openDocument"},
{NO:42,REV:"C01",DISC:"O",DWG_NO:"N999-ODCS-155-O-IM-1101",DWG_DESCR:"155 Block Assembly Outfitting Installation DWG",START_PLAN:"2019-10-23",START_ACTUAL:"2019-10-23",ISSUED_PLAN:"2019-10-23",ISSUED_ACTUAL:"2019-10-23",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/CBCCCBED78CE7D0FC125849D00383F27?openDocument"},
{NO:43,REV:"C01",DISC:"O",DWG_NO:"N999-ODCS-155-O-IM-2301",DWG_DESCR:"155 Block Pre-Outfitting Installation DWG",START_PLAN:"2019-11-25",START_ACTUAL:"2019-11-25",ISSUED_PLAN:"2019-11-25",ISSUED_ACTUAL:"2019-11-25",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/3FDA37853C8B30CAC12584C000035D12?openDocument"},
{NO:44,REV:"C01",DISC:"W",DWG_NO:"N999-ODCS-155-W-WE-0001",DWG_DESCR:"155 Block Scaffold Part Arrangement",START_PLAN:"2019-10-29",START_ACTUAL:"2019-10-29",ISSUED_PLAN:"2019-10-29",ISSUED_ACTUAL:"2019-10-29",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/E17065910D0AEA47C12584A4002D0B1D?openDocument"},
{NO:45,REV:"C01",DISC:"H",DWG_NO:"N999-ODCS-155-H-BC-1101",DWG_DESCR:"155 BLOCK PLATE NESTING",START_PLAN:"2019-09-12",START_ACTUAL:"2019-09-12",ISSUED_PLAN:"2019-09-12",ISSUED_ACTUAL:"2019-09-12",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/5EF0A370E7B89652C125847300107366?openDocument"},
{NO:46,REV:"C01",DISC:"H",DWG_NO:"N999-ODCS-155-H-BM-1101",DWG_DESCR:"155 BLOCK PLATE NESTING FOR T BAR",START_PLAN:"2019-09-03",START_ACTUAL:"2019-09-03",ISSUED_PLAN:"2019-09-03",ISSUED_ACTUAL:"2019-09-03",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/D68F7CDCB5B9FCDBC125846A002F3EEE?openDocument"},
{NO:47,REV:"C01",DISC:"L",DWG_NO:"N999-ODCS-155-L-MP-2004",DWG_DESCR:"155 BLOCK PIPING SUPPORT PALLET LIST 155分段管支架托盘表",START_PLAN:"2019-09-26",START_ACTUAL:"2019-09-26",ISSUED_PLAN:"2019-09-26",ISSUED_ACTUAL:"2019-09-26",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/644E018B1AA6EB76C1258484001C9DF9?openDocument"},
{NO:48,REV:"C01",DISC:"C",DWG_NO:"N999-ODCS-155-C-CG-4101",DWG_DESCR:"Preparation & Coating Graph for 155 Block",START_PLAN:"2020-01-03",START_ACTUAL:"2020-01-03",ISSUED_PLAN:"2020-01-03",ISSUED_ACTUAL:"2020-01-03",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/6FBD2C40AE7888F2C12584E7001FD0BA?openDocument"},
{NO:49,REV:"C01",DISC:"H",DWG_NO:"N999-ODCS-155-H-BA-1101",DWG_DESCR:"155 BLOCK CONSTRUCTION",START_PLAN:"2019-09-12",START_ACTUAL:"2019-09-12",ISSUED_PLAN:"2019-09-12",ISSUED_ACTUAL:"2019-09-12",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/A6E2242FBA4AC8F7C125847300102817?openDocument"},
{NO:50,REV:"C01",DISC:"H",DWG_NO:"N999-ODCS-155-H-BK-1101",DWG_DESCR:"155 BLOCK WELDING MATERIAL LIST",START_PLAN:"2019-09-12",START_ACTUAL:"2019-09-12",ISSUED_PLAN:"2019-09-12",ISSUED_ACTUAL:"2019-09-12",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/EA237909AC8F4506C125847300113BBB?openDocument"},
{NO:51,REV:"C01",DISC:"L",DWG_NO:"N999-ODCS-155-L-PP-2006",DWG_DESCR:"155 BLOCK PIPING PENETRATION PLAN 155分段管子开孔图",START_PLAN:"2019-09-26",START_ACTUAL:"2019-09-26",ISSUED_PLAN:"2019-09-26",ISSUED_ACTUAL:"2019-09-26",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/A5AD7553607CEAC6C1258484002B0F7F?openDocument"},
{NO:52,REV:"C02",DISC:"H",DWG_NO:"N999-ODCS-155-H-WT-1101",DWG_DESCR:"155 BLOCK WELDING MAP AND NDT PLAN",START_PLAN:"2019-10-11",START_ACTUAL:"2019-10-11",ISSUED_PLAN:"2019-10-11",ISSUED_ACTUAL:"2019-10-11",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/C5D4137926E0F51DC125849B0009578B?openDocument"},
{NO:53,REV:"C01",DISC:"L",DWG_NO:"N999-ODCS-155-L-IM-2005",DWG_DESCR:"155 BLOCK PIPING ARRANGEMENT DRAWING 155分段管子布置图",START_PLAN:"2019-09-29",START_ACTUAL:"2019-09-29",ISSUED_PLAN:"2019-09-29",ISSUED_ACTUAL:"2019-09-29",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/243DFA4D6CAB51E1C12584850004B031?openDocument"},
{NO:54,REV:"C01",DISC:"O",DWG_NO:"N999-ODCS-155-O-IM-4301",DWG_DESCR:"155 Block AP-Outfitting Installation DWG",START_PLAN:"2020-04-08",START_ACTUAL:"2020-04-08",ISSUED_PLAN:"2020-04-08",ISSUED_ACTUAL:"2020-04-08",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/49C9F02A41E25A86C125854500050FAA?openDocument"},
{NO:55,REV:"C01",DISC:"C",DWG_NO:"N999-ODCS-155-C-CG-2001",DWG_DESCR:"Preparation & Coating Graph for Outfittings of 155 Block",START_PLAN:"2020-01-03",START_ACTUAL:"2020-01-03",ISSUED_PLAN:"2020-01-03",ISSUED_ACTUAL:"2020-01-03",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/8F923224E8E176C2C12584E7001F9575?openDocument"},
{NO:56,REV:"C01",DISC:"H",DWG_NO:"N999-ODCS-155-H-BB-1101",DWG_DESCR:"155 BLOCK PROFILE CUTTING SKETCH",START_PLAN:"2019-09-12",START_ACTUAL:"2019-09-12",ISSUED_PLAN:"2019-09-12",ISSUED_ACTUAL:"2019-09-12",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/7553ADD50284B473C125847300104A24?openDocument"},
{NO:57,REV:"C01",DISC:"H",DWG_NO:"N999-ODCS-155-H-BG-1101",DWG_DESCR:"155 BLOCK BENDING DRAWING",START_PLAN:"2019-09-12",START_ACTUAL:"2019-09-12",ISSUED_PLAN:"2019-09-12",ISSUED_ACTUAL:"2019-09-12",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/A8DB3E2A2CB02FD4C12584730010DA8A?openDocument"},
{NO:58,REV:"C01",DISC:"H",DWG_NO:"N999-ODCS-155-H-BN-1101",DWG_DESCR:"155 BLOCK T-Bar PROFILE CUTTING SKETCH",START_PLAN:"2019-09-03",START_ACTUAL:"2019-09-03",ISSUED_PLAN:"2019-09-03",ISSUED_ACTUAL:"2019-09-03",LINK:"http://xdb-backend002.exnet.technip.com/Projectd/077333C001/pj0077333c0018.nsf/DOOF_rsAllUNID/B4AF4CD52170D5A8C125846A002F77BB?openDocument"},
],
  Queries: {
    SQL1: [
      {TOTAL:483,NOTSTARTED:10,STARTED:113,ISSUED:360}
    ]
  }
}