import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 290,
      CanvasHeight: 330,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

    DataItems: [
      {DISC:"MEC",TOTAL:735,ACTUAL:16,REMAIN:719,PROG:2.2},
      {DISC:"PIP",TOTAL:148,ACTUAL:0,REMAIN:148,PROG:0},
      {DISC:"VEND",TOTAL:27,ACTUAL:22,REMAIN:5,PROG:81.5}
    ],

    Queries: {
      // Rundown ITR A
      SQL1: [      
        // { CDATE: '2020-02-14', CUM_REM: 234, CUM_COM: 62, PERIOD: 2, },
        // { CDATE: '2020-02-21', CUM_REM: 234, CUM_COM: 62, PERIOD: null, },
        // { CDATE: '2020-02-28', CUM_REM: 232, CUM_COM: 64, PERIOD: 2, },
        // { CDATE: '2020-03-06', CUM_REM: 231, CUM_COM: 65, PERIOD: 1, }, 
        {WK4:0}
      ],
    },
}