import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 2300,
    CanvasHeight: 980,
    CanvasBorderWeight: 0.05,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.1,

    CanvasChartX: 50,
    CanvasChartY: 50,
    CanvasChartWidth: 480,
    CanvasChartHeight: 250,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  DataItems:   [
    {SUBSYSTEM:"21-6600-6674-01",SUBSYSTEM_DESCR:"HULL Normal and Essential 690 V Swtchboard 21-ESB-667401",SUBSYSTEM_STATUS:"Subsystems with Partial MC Complete",PUNCH_STATUS:"N",MC_PLAN:"2021-09-30",A_OV_TOT:286,A_OV_COM:286,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:282,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:392,B_OV_COM:259,B_OV_COM_CLIENT:0,B_OV_REM:133,B_OV_PROG:66.07,PA_OV_TOT:9,PA_OV_REM:0,PB1_OV_TOT:12,PB1_OV_REM:3,PB2_OV_TOT:34,PB2_OV_REM:3,PB3_OV_TOT:3,PB3_OV_REM:3,ACT_TAG_VALID:286,PUNCH_NUMBER_VALID:58,PUNCH_TAG_VALID:11,KSG_TARGET:"Partial",KSG_TARGET_CODE:"P"},
    {SUBSYSTEM:"21-6600-6676-02",SUBSYSTEM_DESCR:"Normal Lighting Panel 02 ,ROOM: M303 21-ELP-667631",SUBSYSTEM_STATUS:"Subsystems with Full MC Complete",PUNCH_STATUS:"N",MC_PLAN:"2021-10-14",A_OV_TOT:50,A_OV_COM:50,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:50,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:43,B_OV_COM:31,B_OV_COM_CLIENT:0,B_OV_REM:12,B_OV_PROG:72.09,PA_OV_TOT:31,PA_OV_REM:0,PB1_OV_TOT:14,PB1_OV_REM:3,PB3_OV_TOT:1,PB3_OV_REM:1,ACT_TAG_VALID:28,PUNCH_NUMBER_VALID:46,PUNCH_TAG_VALID:21,KSG_TARGET:"Full",KSG_TARGET_CODE:"F"},
    {SUBSYSTEM:"21-6600-6675-01",SUBSYSTEM_DESCR:"HULL Normal and Essential 400 V Switchboard 21-ESB-667601",SUBSYSTEM_STATUS:"Subsystems with Partial MC Complete",PUNCH_STATUS:"N",MC_PLAN:"2021-10-21",A_OV_TOT:52,A_OV_COM:52,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:49,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:116,B_OV_COM:73,B_OV_COM_CLIENT:0,B_OV_REM:43,B_OV_PROG:62.93,PA_OV_TOT:8,PA_OV_REM:0,PB1_OV_TOT:9,PB1_OV_REM:1,PB2_OV_TOT:22,PB2_OV_REM:2,PB3_OV_TOT:1,PB3_OV_REM:1,ACT_TAG_VALID:52,PUNCH_NUMBER_VALID:40,PUNCH_TAG_VALID:8,KSG_TARGET:"Partial",KSG_TARGET_CODE:"P"},
    {SUBSYSTEM:"21-6600-6660-01",SUBSYSTEM_DESCR:"Emergency 690 V Switchboard 21-ESB-666001",SUBSYSTEM_STATUS:"Subsystems with Partial MC Complete",PUNCH_STATUS:"N",MC_PLAN:"2021-11-04",A_OV_TOT:219,A_OV_COM:219,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:218,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:313,B_OV_COM:204,B_OV_COM_CLIENT:0,B_OV_REM:109,B_OV_PROG:65.17,PA_OV_TOT:10,PA_OV_REM:0,PB1_OV_TOT:9,PB1_OV_REM:2,PB2_OV_TOT:37,PB2_OV_REM:6,ACT_TAG_VALID:219,PUNCH_NUMBER_VALID:56,PUNCH_TAG_VALID:16,KSG_TARGET:"Partial",KSG_TARGET_CODE:"P"},
    {SUBSYSTEM:"21-6030-6036-01",SUBSYSTEM_DESCR:"PDBs Inside HER",SUBSYSTEM_STATUS:"Subsystems with Partial MC Complete",PUNCH_STATUS:"N",MC_PLAN:"2021-11-25",A_OV_TOT:7,A_OV_COM:7,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:6,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:114,B_OV_COM:16,B_OV_COM_CLIENT:0,B_OV_REM:98,B_OV_PROG:14.03,PA_OV_TOT:18,PA_OV_REM:0,PB1_OV_TOT:12,PB1_OV_REM:8,PB2_OV_TOT:1,PB2_OV_REM:1,ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:31,PUNCH_TAG_VALID:8,KSG_TARGET:"Partial",KSG_TARGET_CODE:"P"},
    {SUBSYSTEM:"21-6030-6036-02",SUBSYSTEM_DESCR:"PDBs Inside CER",SUBSYSTEM_STATUS:"Subsystems with Partial MC Complete",PUNCH_STATUS:"N",MC_PLAN:"2021-11-25",A_OV_TOT:6,A_OV_COM:6,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:6,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:213,B_OV_COM:21,B_OV_COM_CLIENT:0,B_OV_REM:192,B_OV_PROG:9.85,PA_OV_TOT:20,PA_OV_REM:0,PB1_OV_TOT:16,PB1_OV_REM:3,PB2_OV_TOT:2,PB2_OV_REM:2,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:38,PUNCH_TAG_VALID:10,KSG_TARGET:"Partial",KSG_TARGET_CODE:"P"},
    {SUBSYSTEM:"21-6600-6673-01",SUBSYSTEM_DESCR:"Essential 6.6 kV Switchboard 21-ESB-667301",SUBSYSTEM_STATUS:"Subsystems with Partial MC Complete",PUNCH_STATUS:"N",MC_PLAN:"2021-11-25",A_OV_TOT:189,A_OV_COM:189,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:181,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:116,B_OV_COM:58,B_OV_COM_CLIENT:0,B_OV_REM:58,B_OV_PROG:50,PA_OV_TOT:11,PA_OV_REM:0,PB1_OV_TOT:42,PB1_OV_REM:19,PB3_OV_TOT:3,PB3_OV_REM:3,ACT_TAG_VALID:189,PUNCH_NUMBER_VALID:56,PUNCH_TAG_VALID:16,KSG_TARGET:"Partial",KSG_TARGET_CODE:"P"},
    {SUBSYSTEM:"21-6600-6640-01",SUBSYSTEM_DESCR:"LQandHULL Emergency Lighting and small power 400V board 21-EDB-662401 ,in LQ",SUBSYSTEM_STATUS:"Subsystems with Full MC Complete",PUNCH_STATUS:"N",MC_PLAN:"2021-12-02",A_OV_TOT:41,A_OV_COM:41,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:41,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:96,B_OV_COM:57,B_OV_COM_CLIENT:0,B_OV_REM:39,B_OV_PROG:59.37,PA_OV_TOT:16,PA_OV_REM:0,PB1_OV_TOT:8,PB1_OV_REM:1,PB2_OV_TOT:1,PB2_OV_REM:0,ACT_TAG_VALID:41,PUNCH_NUMBER_VALID:25,PUNCH_TAG_VALID:3,KSG_TARGET:"Full",KSG_TARGET_CODE:"F"},
    {SUBSYSTEM:"21-9380-9380-04",SUBSYSTEM_DESCR:"Sludge from Diesel Purifier",SUBSYSTEM_STATUS:"Subsystems with Full MC Complete",PUNCH_STATUS:"N",MC_PLAN:"2021-12-23",A_OV_PROG:100,PA_OV_TOT:2,PA_OV_REM:0,PB1_OV_TOT:1,PB1_OV_REM:1,ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:1,KSG_TARGET:"Excluded",KSG_TARGET_CODE:"E"},
    {SUBSYSTEM:"21-6030-6040-02",SUBSYSTEM_DESCR:"IRP and Interface Instrumentation Cable - LQ",SUBSYSTEM_STATUS:"Subsystems with Full MC Complete",PUNCH_STATUS:"N",MC_PLAN:"2021-12-30",A_OV_TOT:71,A_OV_COM:71,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:31,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,PA_OV_TOT:21,PA_OV_REM:0,PB1_OV_TOT:34,PB1_OV_REM:18,PB3_OV_TOT:1,PB3_OV_REM:0,ACT_TAG_VALID:71,PUNCH_NUMBER_VALID:56,PUNCH_TAG_VALID:18,KSG_TARGET:"Partial",KSG_TARGET_CODE:"P"},
    {SUBSYSTEM:"21-8000-8014-04",SUBSYSTEM_DESCR:"Fresh Service Water Distribution to Water Mist System 21-SWM-613001",SUBSYSTEM_STATUS:"Subsystems with Full MC Complete",PUNCH_STATUS:"N",MC_PLAN:"2022-01-06",A_OV_PROG:100,PB1_OV_TOT:1,PB1_OV_REM:1,ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0,KSG_TARGET:"Excluded",KSG_TARGET_CODE:"E"},
    {SUBSYSTEM:"21-6010-6011-07",SUBSYSTEM_DESCR:"SIS Multicore and Junction Box to Marshalling and System Cabinets in HER",SUBSYSTEM_STATUS:"Subsystems with Partial MC Complete",PUNCH_STATUS:"N",MC_PLAN:"2022-01-27",A_OV_TOT:18,A_OV_COM:14,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:4,A_OV_PROG:77.77,PA_OV_TOT:9,PA_OV_REM:0,PB1_OV_TOT:18,PB1_OV_REM:10,ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:27,PUNCH_TAG_VALID:15,KSG_TARGET:"Partial",KSG_TARGET_CODE:"P"},
    {SUBSYSTEM:"21-6030-6037-01",SUBSYSTEM_DESCR:"Tank Gauging System Cabinets and Workstations",SUBSYSTEM_STATUS:"Subsystems with Full MC Complete",PUNCH_STATUS:"N",MC_PLAN:"2022-01-27",A_OV_TOT:25,A_OV_COM:25,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:2,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:12,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:12,B_OV_PROG:0,PA_OV_TOT:11,PA_OV_REM:0,PB1_OV_TOT:38,PB1_OV_REM:22,PB2_OV_TOT:4,PB2_OV_REM:3,ACT_TAG_VALID:21,PUNCH_NUMBER_VALID:53,PUNCH_TAG_VALID:16,KSG_TARGET:"Full",KSG_TARGET_CODE:"F"},
    {SUBSYSTEM:"21-6030-6040-03",SUBSYSTEM_DESCR:"IRP and Interface Instrumentation Cable - MS03",SUBSYSTEM_STATUS:"Subsystems with Full MC Complete",PUNCH_STATUS:"N",MC_PLAN:"2022-01-27",A_OV_TOT:181,A_OV_COM:181,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:60,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0,PA_OV_TOT:16,PA_OV_REM:0,PB1_OV_TOT:51,PB1_OV_REM:23,ACT_TAG_VALID:181,PUNCH_NUMBER_VALID:67,PUNCH_TAG_VALID:21,KSG_TARGET:"Partial",KSG_TARGET_CODE:"P"},
    {SUBSYSTEM:"21-8020-8024-01",SUBSYSTEM_DESCR:"HULL Sea Water Cooling Pumps - 21-P-802004AandB",SUBSYSTEM_STATUS:"Subsystems with No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-01-27",A_OV_PROG:100,B_OV_TOT:28,B_OV_COM:8,B_OV_COM_CLIENT:0,B_OV_REM:20,B_OV_PROG:28.57,PB1_OV_TOT:29,PB1_OV_REM:13,PB2_OV_TOT:8,PB2_OV_REM:8,ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:37,PUNCH_TAG_VALID:15,KSG_TARGET:"Excluded",KSG_TARGET_CODE:"E"},
    {SUBSYSTEM:"21-9921-9921-06",SUBSYSTEM_DESCR:"LQ CER ACUsandACCUs and UCPs",SUBSYSTEM_STATUS:"Subsystems with No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-01-27",A_OV_PROG:100,B_OV_TOT:55,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:55,B_OV_PROG:0,PA_OV_TOT:12,PA_OV_REM:10,PB1_OV_TOT:42,PB1_OV_REM:30,ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:54,PUNCH_TAG_VALID:16,KSG_TARGET:"Excluded",KSG_TARGET_CODE:"E"},
    {SUBSYSTEM:"21-9970-9971-01",SUBSYSTEM_DESCR:"HULL HVAC UCPs, EDBs",SUBSYSTEM_STATUS:"Subsystems with Full MC Complete",PUNCH_STATUS:"N",MC_PLAN:"2022-01-27",A_OV_TOT:48,A_OV_COM:48,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:16,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:59,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:59,B_OV_PROG:0,PA_OV_TOT:18,PA_OV_REM:0,PB1_OV_TOT:23,PB1_OV_REM:12,ACT_TAG_VALID:47,PUNCH_NUMBER_VALID:45,PUNCH_TAG_VALID:13,KSG_TARGET:"Partial",KSG_TARGET_CODE:"P"},
    {SUBSYSTEM:"21-6020-6021-06",SUBSYSTEM_DESCR:"PAS Multicore and Junction Box to Marshalling and System Cabinets in HER",SUBSYSTEM_STATUS:"Subsystems with Full MC Complete",PUNCH_STATUS:"N",MC_PLAN:"2022-02-03",A_OV_TOT:26,A_OV_COM:26,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:6,A_OV_REM:0,A_OV_PROG:100,PA_OV_TOT:19,PA_OV_REM:0,PB1_OV_TOT:20,PB1_OV_REM:16,ACT_TAG_VALID:26,PUNCH_NUMBER_VALID:39,PUNCH_TAG_VALID:33,KSG_TARGET:"Partial",KSG_TARGET_CODE:"P"},
    {SUBSYSTEM:"21-6030-6043-03",SUBSYSTEM_DESCR:"FOPPs and Associated Fiber Cables to HER",SUBSYSTEM_STATUS:"Subsystems with MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-02-03",A_OV_TOT:21,A_OV_COM:11,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:10,A_OV_PROG:52.38,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:0,PB1_OV_TOT:13,PB1_OV_REM:13,ACT_TAG_VALID:21,PUNCH_NUMBER_VALID:14,PUNCH_TAG_VALID:6,KSG_TARGET:"Partial",KSG_TARGET_CODE:"P"},
    {SUBSYSTEM:"21-6600-6676-01",SUBSYSTEM_DESCR:"Normal Lighting Panel 01 ,ROOM: M103 21-ELP-667630",SUBSYSTEM_STATUS:"Subsystems with Partial MC Complete",PUNCH_STATUS:"N",MC_PLAN:"2022-02-03",A_OV_TOT:49,A_OV_COM:49,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:44,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:47,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:47,B_OV_PROG:0,PA_OV_TOT:3,PA_OV_REM:0,PB1_OV_TOT:46,PB1_OV_REM:11,ACT_TAG_VALID:26,PUNCH_NUMBER_VALID:49,PUNCH_TAG_VALID:18,KSG_TARGET:"Full",KSG_TARGET_CODE:"F"},
    {SUBSYSTEM:"21-8100-8110-01",SUBSYSTEM_DESCR:"HULL Plant Air Distribution",SUBSYSTEM_STATUS:"Subsystems with Partial MC Complete",PUNCH_STATUS:"N",MC_PLAN:"2022-02-03",A_OV_TOT:37,A_OV_COM:37,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:18,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:1,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:1,B_OV_PROG:0,PB1_OV_TOT:4,PB1_OV_REM:4,PB2_OV_TOT:14,PB2_OV_REM:14,ACT_TAG_VALID:7,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:0,KSG_TARGET:"Partial",KSG_TARGET_CODE:"P"},
    {SUBSYSTEM:"21-8100-8120-01",SUBSYSTEM_DESCR:"HULL Air Compressor 21-Z-811002",SUBSYSTEM_STATUS:"Subsystems with No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-02-03",A_OV_PROG:100,B_OV_TOT:60,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:60,B_OV_PROG:0,PA_OV_TOT:13,PA_OV_REM:0,PB1_OV_TOT:29,PB1_OV_REM:16,PB3_OV_TOT:1,PB3_OV_REM:1,ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:43,PUNCH_TAG_VALID:14,KSG_TARGET:"Excluded",KSG_TARGET_CODE:"E"},
    {SUBSYSTEM:"21-8100-8120-03",SUBSYSTEM_DESCR:"HULL Air Receiver 21-V-812002, Main header and Controls",SUBSYSTEM_STATUS:"Subsystems with No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-02-03",A_OV_PROG:100,B_OV_TOT:7,B_OV_COM:2,B_OV_COM_CLIENT:0,B_OV_REM:5,B_OV_PROG:28.57,PA_OV_TOT:5,PA_OV_REM:0,PB1_OV_TOT:16,PB1_OV_REM:3,ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:21,PUNCH_TAG_VALID:7,KSG_TARGET:"Excluded",KSG_TARGET_CODE:"E"},
    {SUBSYSTEM:"21-6020-6021-01",SUBSYSTEM_DESCR:"PAS Marshalling - Machinery Space ,HER",SUBSYSTEM_STATUS:"Subsystems with MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-02-10",A_OV_TOT:45,A_OV_COM:45,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:4,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:9,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:9,B_OV_PROG:0,PA_OV_TOT:20,PA_OV_REM:4,PB1_OV_TOT:20,PB1_OV_REM:2,ACT_TAG_VALID:45,PUNCH_NUMBER_VALID:40,PUNCH_TAG_VALID:9,KSG_TARGET:"Partial",KSG_TARGET_CODE:"P"},
    {SUBSYSTEM:"21-6020-6021-02",SUBSYSTEM_DESCR:"PAS Marshalling - LQ ,CER",SUBSYSTEM_STATUS:"Subsystems with MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-02-10",A_OV_TOT:15,A_OV_COM:15,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:4,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:4,B_OV_PROG:0,PA_OV_TOT:11,PA_OV_REM:0,PB1_OV_TOT:10,PB1_OV_REM:1,ACT_TAG_VALID:15,PUNCH_NUMBER_VALID:21,PUNCH_TAG_VALID:3,KSG_TARGET:"Partial",KSG_TARGET_CODE:"P"},
    {SUBSYSTEM:"21-6070-6071-01",SUBSYSTEM_DESCR:"FGS Marshalling - Machinery Space ,HER",SUBSYSTEM_STATUS:"Subsystems with MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-02-10",A_OV_TOT:14,A_OV_COM:14,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:7,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:7,B_OV_PROG:0,PA_OV_TOT:12,PA_OV_REM:1,PB1_OV_TOT:10,PB1_OV_REM:0,ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:22,PUNCH_TAG_VALID:2,KSG_TARGET:"Partial",KSG_TARGET_CODE:"P"},
    {SUBSYSTEM:"21-6070-6071-02",SUBSYSTEM_DESCR:"FGS Marshalling - LQ ,CER",SUBSYSTEM_STATUS:"Subsystems with MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-02-10",A_OV_TOT:29,A_OV_COM:29,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:2,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:5,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:5,B_OV_PROG:0,PA_OV_TOT:26,PA_OV_REM:0,PB1_OV_TOT:14,PB1_OV_REM:2,PB2_OV_TOT:2,PB2_OV_REM:2,ACT_TAG_VALID:29,PUNCH_NUMBER_VALID:42,PUNCH_TAG_VALID:3,KSG_TARGET:"Partial",KSG_TARGET_CODE:"P"},
    {SUBSYSTEM:"21-6070-6071-06",SUBSYSTEM_DESCR:"FGS Multicore and Junction Box to Marshalling and System Cabinets in HER",SUBSYSTEM_STATUS:"Subsystems with MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-02-10",A_OV_TOT:6,A_OV_COM:6,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:0,A_OV_PROG:100,PA_OV_TOT:5,PA_OV_REM:0,PB1_OV_TOT:10,PB1_OV_REM:8,PB2_OV_TOT:1,PB2_OV_REM:1,ACT_TAG_VALID:6,PUNCH_NUMBER_VALID:16,PUNCH_TAG_VALID:7,KSG_TARGET:"Partial",KSG_TARGET_CODE:"P"},
    {SUBSYSTEM:"21-8060-8062-02",SUBSYSTEM_DESCR:"Purifier Package",SUBSYSTEM_STATUS:"Subsystems with MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-02-10",A_OV_TOT:28,A_OV_COM:27,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:14,A_OV_REM:1,A_OV_PROG:96.42,B_OV_TOT:12,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:12,B_OV_PROG:0,PA_OV_TOT:23,PA_OV_REM:0,PB1_OV_TOT:16,PB1_OV_REM:11,PB2_OV_TOT:7,PB2_OV_REM:7,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:46,PUNCH_TAG_VALID:10,KSG_TARGET:"Excluded",KSG_TARGET_CODE:"E"},
    {SUBSYSTEM:"21-8100-8120-02",SUBSYSTEM_DESCR:"HULL Air Drier 21-Z-812020",SUBSYSTEM_STATUS:"Subsystems with No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-02-10",A_OV_PROG:100,B_OV_TOT:9,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:9,B_OV_PROG:0,PA_OV_TOT:8,PA_OV_REM:1,PB1_OV_TOT:13,PB1_OV_REM:5,PB2_OV_TOT:4,PB2_OV_REM:1,ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:25,PUNCH_TAG_VALID:9,KSG_TARGET:"Excluded",KSG_TARGET_CODE:"E"},
    {SUBSYSTEM:"21-9330-9334-01",SUBSYSTEM_DESCR:"HULL Valves HPU",SUBSYSTEM_STATUS:"Subsystems with MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-02-10",A_OV_TOT:32,A_OV_COM:32,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:44,B_OV_COM:1,B_OV_COM_CLIENT:0,B_OV_REM:43,B_OV_PROG:2.27,PA_OV_TOT:15,PA_OV_REM:1,PB1_OV_TOT:34,PB1_OV_REM:15,PB2_OV_TOT:2,PB2_OV_REM:1,ACT_TAG_VALID:26,PUNCH_NUMBER_VALID:81,PUNCH_TAG_VALID:20,KSG_TARGET:"Partial",KSG_TARGET_CODE:"P"},
    {SUBSYSTEM:"21-9970-9971-04",SUBSYSTEM_DESCR:"KSG Room Supply Fans, Ducting and Dampers",SUBSYSTEM_STATUS:"Subsystems with MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-02-10",A_OV_TOT:263,A_OV_COM:181,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:47,A_OV_REM:82,A_OV_PROG:68.82,B_OV_TOT:70,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:70,B_OV_PROG:0,PA_OV_TOT:8,PA_OV_REM:5,PB1_OV_TOT:83,PB1_OV_REM:65,PB2_OV_TOT:1,PB2_OV_REM:1,ACT_TAG_VALID:223,PUNCH_NUMBER_VALID:92,PUNCH_TAG_VALID:36,KSG_TARGET:"Partial",KSG_TARGET_CODE:"P"},
    {SUBSYSTEM:"21-6030-6032-01",SUBSYSTEM_DESCR:"ICSS Interface Servers, Workstations and Network Switches CER",SUBSYSTEM_STATUS:"Subsystems with Partial MC Complete",PUNCH_STATUS:"N",MC_PLAN:"2022-02-17",A_OV_TOT:266,A_OV_COM:266,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:70,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:31,B_OV_COM:12,B_OV_COM_CLIENT:0,B_OV_REM:19,B_OV_PROG:38.7,PA_OV_TOT:32,PA_OV_REM:0,PB1_OV_TOT:199,PB1_OV_REM:141,PB2_OV_TOT:1,PB2_OV_REM:1,ACT_TAG_VALID:261,PUNCH_NUMBER_VALID:224,PUNCH_TAG_VALID:19,KSG_TARGET:"Full",KSG_TARGET_CODE:"F"},
    {SUBSYSTEM:"21-6030-6032-03",SUBSYSTEM_DESCR:"ICSS Interface Servers, Workstations and Network Switches HER",SUBSYSTEM_STATUS:"Subsystems with Partial MC Complete",PUNCH_STATUS:"A",MC_PLAN:"2022-02-17",A_OV_TOT:133,A_OV_COM:133,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:14,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:16,B_OV_COM:8,B_OV_COM_CLIENT:0,B_OV_REM:8,B_OV_PROG:50,PA_OV_TOT:6,PA_OV_REM:3,PB1_OV_TOT:71,PB1_OV_REM:63,PB2_OV_TOT:1,PB2_OV_REM:1,ACT_TAG_VALID:133,PUNCH_NUMBER_VALID:78,PUNCH_TAG_VALID:15,KSG_TARGET:"Full",KSG_TARGET_CODE:"F"},
    {SUBSYSTEM:"21-6070-6071-05",SUBSYSTEM_DESCR:"FGS Multicore and Junction Box to Marshalling and System Cabinets in CER",SUBSYSTEM_STATUS:"Subsystems with MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-02-17",A_OV_TOT:46,A_OV_COM:46,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:0,A_OV_PROG:100,PA_OV_TOT:14,PA_OV_REM:0,PB1_OV_TOT:23,PB1_OV_REM:7,ACT_TAG_VALID:46,PUNCH_NUMBER_VALID:37,PUNCH_TAG_VALID:24,KSG_TARGET:"Partial",KSG_TARGET_CODE:"P"},
    {SUBSYSTEM:"21-6600-6670-01",SUBSYSTEM_DESCR:"Key service Generator 21-G-667001",SUBSYSTEM_STATUS:"Subsystems with MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-02-17",A_OV_TOT:142,A_OV_COM:128,A_OV_COM_LW:8,A_OV_COM_TW:1,A_OV_COM_CLIENT:52,A_OV_REM:14,A_OV_PROG:90.14,B_OV_TOT:161,B_OV_COM:2,B_OV_COM_CLIENT:0,B_OV_REM:159,B_OV_PROG:1.24,PA_OV_TOT:69,PA_OV_REM:2,PB1_OV_TOT:84,PB1_OV_REM:59,PB2_OV_TOT:28,PB2_OV_REM:27,PB3_OV_TOT:1,PB3_OV_REM:1,ACT_TAG_VALID:97,PUNCH_NUMBER_VALID:182,PUNCH_TAG_VALID:29,KSG_TARGET:"Full",KSG_TARGET_CODE:"F"},
    {SUBSYSTEM:"21-6600-6670-02",SUBSYSTEM_DESCR:"Key service Generator 21-G-667002",SUBSYSTEM_STATUS:"Subsystems with MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-02-17",A_OV_TOT:136,A_OV_COM:119,A_OV_COM_LW:9,A_OV_COM_TW:0,A_OV_COM_CLIENT:52,A_OV_REM:17,A_OV_PROG:87.5,B_OV_TOT:136,B_OV_COM:2,B_OV_COM_CLIENT:0,B_OV_REM:134,B_OV_PROG:1.47,PA_OV_TOT:46,PA_OV_REM:1,PB1_OV_TOT:73,PB1_OV_REM:53,PB2_OV_TOT:7,PB2_OV_REM:4,PB3_OV_TOT:4,PB3_OV_REM:4,ACT_TAG_VALID:91,PUNCH_NUMBER_VALID:130,PUNCH_TAG_VALID:22,KSG_TARGET:"Full",KSG_TARGET_CODE:"F"},
    {SUBSYSTEM:"21-8000-8014-01",SUBSYSTEM_DESCR:"Fresh Service Water Tank and Pumps",SUBSYSTEM_STATUS:"Subsystems with No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-02-17",A_OV_PROG:100,B_OV_TOT:20,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:20,B_OV_PROG:0,PA_OV_TOT:6,PA_OV_REM:4,PB1_OV_TOT:23,PB1_OV_REM:16,PB2_OV_TOT:10,PB2_OV_REM:10,ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:39,PUNCH_TAG_VALID:10,KSG_TARGET:"Excluded",KSG_TARGET_CODE:"E"},
    {SUBSYSTEM:"21-8020-8029-01",SUBSYSTEM_DESCR:"KSG Cooling Sea Water Pumps 21-P-802009AandB and Distribution",SUBSYSTEM_STATUS:"Subsystems with MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-02-17",A_OV_TOT:95,A_OV_COM:82,A_OV_COM_LW:14,A_OV_COM_TW:0,A_OV_COM_CLIENT:28,A_OV_REM:13,A_OV_PROG:86.31,B_OV_TOT:21,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:21,B_OV_PROG:0,PA_OV_TOT:3,PA_OV_REM:0,PB1_OV_TOT:25,PB1_OV_REM:17,PB2_OV_TOT:6,PB2_OV_REM:6,ACT_TAG_VALID:49,PUNCH_NUMBER_VALID:34,PUNCH_TAG_VALID:12,KSG_TARGET:"Full",KSG_TARGET_CODE:"F"},
    {SUBSYSTEM:"21-8060-8061-01",SUBSYSTEM_DESCR:"Diesel Bunker Header",SUBSYSTEM_STATUS:"Subsystems with No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-02-17",A_OV_PROG:100,B_OV_TOT:4,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:4,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:2,PB1_OV_TOT:1,PB1_OV_REM:1,PB2_OV_TOT:2,PB2_OV_REM:2,ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:1,KSG_TARGET:"Excluded",KSG_TARGET_CODE:"E"},
    {SUBSYSTEM:"21-8060-8061-02",SUBSYSTEM_DESCR:"Diesel Storage Tanks - 21-T-806001and2 and Purifier Pumps - 21-P-806002AandB",SUBSYSTEM_STATUS:"Subsystems with MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-02-17",A_OV_TOT:212,A_OV_COM:168,A_OV_COM_LW:2,A_OV_COM_TW:3,A_OV_COM_CLIENT:49,A_OV_REM:44,A_OV_PROG:79.24,B_OV_TOT:27,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:27,B_OV_PROG:0,PA_OV_TOT:8,PA_OV_REM:0,PB1_OV_TOT:43,PB1_OV_REM:40,PB2_OV_TOT:21,PB2_OV_REM:19,ACT_TAG_VALID:72,PUNCH_NUMBER_VALID:90,PUNCH_TAG_VALID:16,KSG_TARGET:"Partial",KSG_TARGET_CODE:"P"},
    {SUBSYSTEM:"21-8060-8061-03",SUBSYSTEM_DESCR:"Diesel Supply and Return ,Spill Back Headers to Service Tanks - 21-T-806003and4",SUBSYSTEM_STATUS:"Subsystems with MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-02-17",A_OV_TOT:7,A_OV_COM:6,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:2,A_OV_REM:1,A_OV_PROG:85.71,PB1_OV_TOT:2,PB1_OV_REM:2,PB2_OV_TOT:1,PB2_OV_REM:1,ACT_TAG_VALID:2,PUNCH_NUMBER_VALID:3,PUNCH_TAG_VALID:0,KSG_TARGET:"Excluded",KSG_TARGET_CODE:"E"},
    {SUBSYSTEM:"21-8060-8062-01",SUBSYSTEM_DESCR:"Diesel Service Tanks - 21-T-806003and4 and Supply Pumps - 21-P-806001AandB",SUBSYSTEM_STATUS:"Subsystems with MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-02-17",A_OV_TOT:268,A_OV_COM:212,A_OV_COM_LW:3,A_OV_COM_TW:3,A_OV_COM_CLIENT:67,A_OV_REM:56,A_OV_PROG:79.1,B_OV_TOT:40,B_OV_COM:3,B_OV_COM_CLIENT:0,B_OV_REM:37,B_OV_PROG:7.5,PA_OV_TOT:11,PA_OV_REM:1,PB1_OV_TOT:63,PB1_OV_REM:52,PB2_OV_TOT:19,PB2_OV_REM:17,ACT_TAG_VALID:92,PUNCH_NUMBER_VALID:107,PUNCH_TAG_VALID:30,KSG_TARGET:"Full",KSG_TARGET_CODE:"F"},
    {SUBSYSTEM:"21-8780-8780-01",SUBSYSTEM_DESCR:"Earthing HULL",SUBSYSTEM_STATUS:"Subsystems with Partial MC Complete",PUNCH_STATUS:"N",MC_PLAN:"2022-02-17",A_OV_TOT:14,A_OV_COM:3,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:3,A_OV_REM:11,A_OV_PROG:21.42,PA_OV_TOT:5,PA_OV_REM:0,PB1_OV_TOT:17,PB1_OV_REM:3,ACT_TAG_VALID:14,PUNCH_NUMBER_VALID:22,PUNCH_TAG_VALID:2,KSG_TARGET:"Partial",KSG_TARGET_CODE:"P"},
    {SUBSYSTEM:"21-9921-9922-01",SUBSYSTEM_DESCR:"LQ HVAC UCPs, EDBs",SUBSYSTEM_STATUS:"Subsystems with MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-02-17",A_OV_TOT:66,A_OV_COM:66,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:19,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:79,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:79,B_OV_PROG:0,PA_OV_TOT:15,PA_OV_REM:5,PB1_OV_TOT:46,PB1_OV_REM:33,ACT_TAG_VALID:66,PUNCH_NUMBER_VALID:65,PUNCH_TAG_VALID:11,KSG_TARGET:"Partial",KSG_TARGET_CODE:"P"},
    {SUBSYSTEM:"21-6100-6121-01",SUBSYSTEM_DESCR:"HULL Water Mist System",SUBSYSTEM_STATUS:"Subsystems with No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-02-24",A_OV_PROG:100,B_OV_TOT:93,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:93,B_OV_PROG:0,PA_OV_TOT:14,PA_OV_REM:2,PB1_OV_TOT:17,PB1_OV_REM:13,PB3_OV_TOT:1,PB3_OV_REM:1,ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:32,PUNCH_TAG_VALID:12,KSG_TARGET:"Excluded",KSG_TARGET_CODE:"E"},
    {SUBSYSTEM:"21-6600-6676-06",SUBSYSTEM_DESCR:"Normal Lighting Panel 05 ,ROOM: LH05 21-ELP-667660",SUBSYSTEM_STATUS:"Subsystems with MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-02-24",A_OV_TOT:77,A_OV_COM:1,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:76,A_OV_PROG:1.29,B_OV_TOT:48,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:48,B_OV_PROG:0,PB1_OV_TOT:1,PB1_OV_REM:0,ACT_TAG_VALID:62,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:1,KSG_TARGET:"Full",KSG_TARGET_CODE:"F"},
    {SUBSYSTEM:"21-6600-6676-07",SUBSYSTEM_DESCR:"Normal Lighting Panel 06 ,ROOM: LA17 21-ELP-667661",SUBSYSTEM_STATUS:"Subsystems with MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-02-24",A_OV_TOT:68,A_OV_COM:2,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:2,A_OV_REM:66,A_OV_PROG:2.94,B_OV_TOT:68,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:68,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:0,PB1_OV_TOT:2,PB1_OV_REM:0,ACT_TAG_VALID:56,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:2,KSG_TARGET:"Full",KSG_TARGET_CODE:"F"},
    {SUBSYSTEM:"21-6600-6676-08",SUBSYSTEM_DESCR:"Normal Lighting Panel 07 ,ROOM: L112 21-ELP-667662",SUBSYSTEM_STATUS:"Subsystems with No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-02-24",A_OV_PROG:100,B_OV_TOT:51,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:51,B_OV_PROG:0,PB1_OV_TOT:12,PB1_OV_REM:10,ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:5,KSG_TARGET:"Full",KSG_TARGET_CODE:"F"},
    {SUBSYSTEM:"21-8020-8020-03",SUBSYSTEM_DESCR:"Marine Sea Water Main Distribution Header",SUBSYSTEM_STATUS:"Subsystems with MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-02-24",A_OV_TOT:36,A_OV_COM:29,A_OV_COM_LW:3,A_OV_COM_TW:0,A_OV_COM_CLIENT:6,A_OV_REM:7,A_OV_PROG:80.55,B_OV_TOT:6,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:6,B_OV_PROG:0,PB1_OV_TOT:4,PB1_OV_REM:4,ACT_TAG_VALID:21,PUNCH_NUMBER_VALID:4,PUNCH_TAG_VALID:1,KSG_TARGET:"Full",KSG_TARGET_CODE:"F"},
    {SUBSYSTEM:"21-8100-8121-01",SUBSYSTEM_DESCR:"HULL Instrument Air Distribution",SUBSYSTEM_STATUS:"Subsystems with MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-02-24",A_OV_TOT:259,A_OV_COM:236,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:77,A_OV_REM:23,A_OV_PROG:91.11,B_OV_TOT:5,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:5,B_OV_PROG:0,PA_OV_TOT:11,PA_OV_REM:5,PB1_OV_TOT:24,PB1_OV_REM:22,PB2_OV_TOT:4,PB2_OV_REM:4,ACT_TAG_VALID:57,PUNCH_NUMBER_VALID:39,PUNCH_TAG_VALID:7,KSG_TARGET:"Partial",KSG_TARGET_CODE:"P"},
    {SUBSYSTEM:"21-9350-9350-01",SUBSYSTEM_DESCR:"MS Ballast Tanks WBW6,7 and WBE6,7 and FPE1 andFPW1",SUBSYSTEM_STATUS:"Subsystems with MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-02-24",A_OV_TOT:26,A_OV_COM:22,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:10,A_OV_REM:4,A_OV_PROG:84.61,B_OV_TOT:12,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:12,B_OV_PROG:0,PA_OV_TOT:5,PA_OV_REM:4,PB1_OV_TOT:4,PB1_OV_REM:1,ACT_TAG_VALID:8,PUNCH_NUMBER_VALID:9,PUNCH_TAG_VALID:1,KSG_TARGET:"Partial",KSG_TARGET_CODE:"P"},
    {SUBSYSTEM:"21-9350-9350-04",SUBSYSTEM_DESCR:"MS Ballast Pump 21-P-935002A",SUBSYSTEM_STATUS:"Subsystems with MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-02-24",A_OV_TOT:46,A_OV_COM:34,A_OV_COM_LW:9,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:12,A_OV_PROG:73.91,B_OV_TOT:17,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:17,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:0,PB1_OV_TOT:20,PB1_OV_REM:17,ACT_TAG_VALID:24,PUNCH_NUMBER_VALID:21,PUNCH_TAG_VALID:6,KSG_TARGET:"Partial",KSG_TARGET_CODE:"P"},
    {SUBSYSTEM:"21-9350-9350-05",SUBSYSTEM_DESCR:"MS Ballast Pump 21-P-935002B",SUBSYSTEM_STATUS:"Subsystems with MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-02-24",A_OV_TOT:42,A_OV_COM:26,A_OV_COM_LW:8,A_OV_COM_TW:0,A_OV_COM_CLIENT:1,A_OV_REM:16,A_OV_PROG:61.9,B_OV_TOT:14,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:14,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:0,PB1_OV_TOT:16,PB1_OV_REM:12,ACT_TAG_VALID:20,PUNCH_NUMBER_VALID:18,PUNCH_TAG_VALID:7,KSG_TARGET:"Partial",KSG_TARGET_CODE:"P"},
    {SUBSYSTEM:"21-6030-6031-01",SUBSYSTEM_DESCR:"Electrical Data Monitoring and Constrol System ,EDMCS and Power Management System ,PMS",SUBSYSTEM_STATUS:"Subsystems with No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-03-03",A_OV_PROG:100,B_OV_TOT:39,B_OV_COM:1,B_OV_COM_CLIENT:0,B_OV_REM:38,B_OV_PROG:2.56,PA_OV_TOT:14,PA_OV_REM:3,PB1_OV_TOT:15,PB1_OV_REM:9,ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:29,PUNCH_TAG_VALID:15,KSG_TARGET:"Excluded",KSG_TARGET_CODE:"E"},
    {SUBSYSTEM:"21-6030-6043-02",SUBSYSTEM_DESCR:"FOPPs and Associated Fiber Cables to CER",SUBSYSTEM_STATUS:"Subsystems with MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-03-03",A_OV_TOT:32,A_OV_COM:15,A_OV_COM_LW:6,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:17,A_OV_PROG:46.87,B_OV_TOT:4,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:4,B_OV_PROG:0,PB1_OV_TOT:2,PB1_OV_REM:0,ACT_TAG_VALID:32,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:2,KSG_TARGET:"Partial",KSG_TARGET_CODE:"P"},
    {SUBSYSTEM:"21-6700-6701-01",SUBSYSTEM_DESCR:"Main LQ UPS System ,Incl Batteries, Breakers and Subdistribution Board",SUBSYSTEM_STATUS:"Subsystems with Full MC Complete",PUNCH_STATUS:"N",MC_PLAN:"2022-03-03",A_OV_TOT:42,A_OV_COM:38,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:37,A_OV_REM:4,A_OV_PROG:90.47,B_OV_TOT:12,B_OV_COM:6,B_OV_COM_CLIENT:0,B_OV_REM:6,B_OV_PROG:50,PA_OV_TOT:31,PA_OV_REM:0,PB1_OV_TOT:54,PB1_OV_REM:2,PB2_OV_TOT:1,PB2_OV_REM:0,ACT_TAG_VALID:38,PUNCH_NUMBER_VALID:86,PUNCH_TAG_VALID:18,KSG_TARGET:"Full",KSG_TARGET_CODE:"F"},
    {SUBSYSTEM:"21-9380-9380-01",SUBSYSTEM_DESCR:"Daily bilge Tank 21-T-938001 and Pumps 21-P-938001AandB",SUBSYSTEM_STATUS:"Subsystems with MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-03-03",A_OV_TOT:4,A_OV_COM:4,A_OV_COM_LW:1,A_OV_COM_TW:0,A_OV_COM_CLIENT:3,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:13,B_OV_COM:4,B_OV_COM_CLIENT:0,B_OV_REM:9,B_OV_PROG:30.76,PA_OV_TOT:2,PA_OV_REM:2,PB1_OV_TOT:9,PB1_OV_REM:7,ACT_TAG_VALID:4,PUNCH_NUMBER_VALID:11,PUNCH_TAG_VALID:5,KSG_TARGET:"Excluded",KSG_TARGET_CODE:"E"},
    {SUBSYSTEM:"21-9380-9380-02",SUBSYSTEM_DESCR:"Daily Bilge Wells and Headers to Daily Bilge Tank",SUBSYSTEM_STATUS:"Subsystems with No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-03-03",A_OV_PROG:100,B_OV_TOT:17,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:17,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:1,PB1_OV_TOT:4,PB1_OV_REM:4,ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:1,KSG_TARGET:"Excluded",KSG_TARGET_CODE:"E"},
    {SUBSYSTEM:"21-9380-9380-03",SUBSYSTEM_DESCR:"Bilge Water from MS Bilge Collection Header",SUBSYSTEM_STATUS:"Subsystems with No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-03-03",A_OV_PROG:100,B_OV_TOT:2,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:2,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:1,ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:1,PUNCH_TAG_VALID:0,KSG_TARGET:"Excluded",KSG_TARGET_CODE:"E"},
    {SUBSYSTEM:"21-9921-9921-04",SUBSYSTEM_DESCR:"LQ Emergency SWB Room ACUsandACCUs and UCPs",SUBSYSTEM_STATUS:"Subsystems with No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-03-03",A_OV_PROG:100,B_OV_TOT:47,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:47,B_OV_PROG:0,PA_OV_TOT:12,PA_OV_REM:9,PB1_OV_TOT:38,PB1_OV_REM:28,ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:50,PUNCH_TAG_VALID:8,KSG_TARGET:"Excluded",KSG_TARGET_CODE:"E"},
    {SUBSYSTEM:"21-9970-9970-01",SUBSYSTEM_DESCR:"HULL Emergency Pressurisation Fans",SUBSYSTEM_STATUS:"Subsystems with No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-03-03",A_OV_PROG:100,B_OV_TOT:25,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:25,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:0,PB1_OV_TOT:29,PB1_OV_REM:18,ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:31,PUNCH_TAG_VALID:18,KSG_TARGET:"Excluded",KSG_TARGET_CODE:"E"},
    {SUBSYSTEM:"21-9970-9971-02",SUBSYSTEM_DESCR:"HULL Machinery Space Supply Fans , Ducting and Dampers",SUBSYSTEM_STATUS:"Subsystems with No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-03-03",A_OV_PROG:100,B_OV_TOT:60,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:60,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:1,PB1_OV_TOT:31,PB1_OV_REM:19,ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:33,PUNCH_TAG_VALID:19,KSG_TARGET:"Excluded",KSG_TARGET_CODE:"E"},
    {SUBSYSTEM:"21-9970-9971-08",SUBSYSTEM_DESCR:"HULL Machinery Space AHU Air Supply",SUBSYSTEM_STATUS:"Subsystems with No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-03-03",A_OV_PROG:100,B_OV_TOT:146,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:146,B_OV_PROG:0,PA_OV_TOT:19,PA_OV_REM:8,PB1_OV_TOT:76,PB1_OV_REM:34,ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:95,PUNCH_TAG_VALID:45,KSG_TARGET:"Excluded",KSG_TARGET_CODE:"E"},
    {SUBSYSTEM:"21-9970-9971-10",SUBSYSTEM_DESCR:"HULL Diesel Oil Pump Room Supply Fans, Ducting and Dampers",SUBSYSTEM_STATUS:"Subsystems with No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-03-03",A_OV_PROG:100,B_OV_TOT:23,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:23,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:1,PB1_OV_TOT:33,PB1_OV_REM:14,ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:35,PUNCH_TAG_VALID:17,KSG_TARGET:"Excluded",KSG_TARGET_CODE:"E"},
    {SUBSYSTEM:"21-9970-9971-11",SUBSYSTEM_DESCR:"HULL Diesel Oil Pump Room Exhaust Fans, Ducting and Dampers",SUBSYSTEM_STATUS:"Subsystems with No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-03-03",A_OV_PROG:100,B_OV_TOT:19,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:19,B_OV_PROG:0,PA_OV_TOT:2,PA_OV_REM:1,PB1_OV_TOT:37,PB1_OV_REM:20,ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:39,PUNCH_TAG_VALID:16,KSG_TARGET:"Excluded",KSG_TARGET_CODE:"E"},
    {SUBSYSTEM:"21-9350-9350-02",SUBSYSTEM_DESCR:"MS Ballast Ring Main",SUBSYSTEM_STATUS:"Subsystems with MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-03-10",A_OV_TOT:46,A_OV_COM:22,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:7,A_OV_REM:24,A_OV_PROG:47.82,B_OV_TOT:14,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:14,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:0,PB1_OV_TOT:11,PB1_OV_REM:10,ACT_TAG_VALID:25,PUNCH_NUMBER_VALID:12,PUNCH_TAG_VALID:4,KSG_TARGET:"Full",KSG_TARGET_CODE:"F"},
    {SUBSYSTEM:"21-8020-8020-01",SUBSYSTEM_DESCR:"Marine Sea Water Sea Suction Tube and Filter 21-F-802001",SUBSYSTEM_STATUS:"Subsystems with MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-03-17",A_OV_TOT:39,A_OV_COM:31,A_OV_COM_LW:3,A_OV_COM_TW:0,A_OV_COM_CLIENT:7,A_OV_REM:8,A_OV_PROG:79.48,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0,PB1_OV_TOT:8,PB1_OV_REM:6,ACT_TAG_VALID:12,PUNCH_NUMBER_VALID:8,PUNCH_TAG_VALID:4,KSG_TARGET:"Full",KSG_TARGET_CODE:"F"},
    {SUBSYSTEM:"21-6600-6624-05",SUBSYSTEM_DESCR:"HULL Emergency Lighting Panel and Circuits ,Room: M104 21-ELP-662401",SUBSYSTEM_STATUS:"Subsystems with MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-03-31",A_OV_TOT:54,A_OV_COM:44,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:35,A_OV_REM:10,A_OV_PROG:81.48,B_OV_TOT:31,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:31,B_OV_PROG:0,PA_OV_TOT:7,PA_OV_REM:0,PB1_OV_TOT:56,PB1_OV_REM:35,ACT_TAG_VALID:30,PUNCH_NUMBER_VALID:63,PUNCH_TAG_VALID:20,KSG_TARGET:"Full",KSG_TARGET_CODE:"F"},
    {SUBSYSTEM:"21-8020-8020-02",SUBSYSTEM_DESCR:"Marine Sea Water Sea Suction Tube and Filter 21-F-802002",SUBSYSTEM_STATUS:"Subsystems with MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-03-31",A_OV_TOT:32,A_OV_COM:25,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:8,A_OV_REM:7,A_OV_PROG:78.12,B_OV_TOT:3,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:3,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:0,PB1_OV_TOT:5,PB1_OV_REM:4,ACT_TAG_VALID:10,PUNCH_NUMBER_VALID:6,PUNCH_TAG_VALID:4,KSG_TARGET:"Full",KSG_TARGET_CODE:"F"},
    {SUBSYSTEM:"21-8020-8024-02",SUBSYSTEM_DESCR:"HULL Sea Water Cooling Header and Cooling Water Exchangers - 21-H-805001AandB",SUBSYSTEM_STATUS:"Subsystems with MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-03-31",A_OV_TOT:40,A_OV_COM:30,A_OV_COM_LW:2,A_OV_COM_TW:0,A_OV_COM_CLIENT:14,A_OV_REM:10,A_OV_PROG:75,B_OV_TOT:4,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:4,B_OV_PROG:0,PB1_OV_TOT:5,PB1_OV_REM:4,ACT_TAG_VALID:18,PUNCH_NUMBER_VALID:5,PUNCH_TAG_VALID:3,KSG_TARGET:"Full",KSG_TARGET_CODE:"F"},
    {SUBSYSTEM:"21-9921-9921-02",SUBSYSTEM_DESCR:"LQ Battery Exhaust Fans, Ducting and Dampers",SUBSYSTEM_STATUS:"Subsystems with No MC Started",PUNCH_STATUS:"A",MC_PLAN:"2022-03-31",A_OV_PROG:100,B_OV_TOT:18,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:18,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:1,PB1_OV_TOT:15,PB1_OV_REM:4,ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:16,PUNCH_TAG_VALID:14,KSG_TARGET:"Excluded",KSG_TARGET_CODE:"E"},
    {SUBSYSTEM:"21-9970-9971-05",SUBSYSTEM_DESCR:"KSG Room Exhaust Fans, Ducting and Dampers",SUBSYSTEM_STATUS:"Subsystems with Full MC Complete",PUNCH_STATUS:"N",MC_PLAN:"2022-04-07",A_OV_TOT:115,A_OV_COM:115,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:43,A_OV_REM:0,A_OV_PROG:100,B_OV_TOT:32,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:32,B_OV_PROG:0,PA_OV_TOT:7,PA_OV_REM:0,PB1_OV_TOT:42,PB1_OV_REM:27,PB2_OV_TOT:1,PB2_OV_REM:1,ACT_TAG_VALID:108,PUNCH_NUMBER_VALID:50,PUNCH_TAG_VALID:24,KSG_TARGET:"Partial",KSG_TARGET_CODE:"P"},
    {SUBSYSTEM:"21-6600-6624-01",SUBSYSTEM_DESCR:"LQ Emergency Lighting Panel and Circuits ,Room: LH91 21-ELP-662460",SUBSYSTEM_STATUS:"Subsystems with MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-05",A_OV_TOT:68,A_OV_COM:2,A_OV_COM_LW:0,A_OV_COM_TW:0,A_OV_COM_CLIENT:0,A_OV_REM:66,A_OV_PROG:2.94,B_OV_TOT:31,B_OV_COM:0,B_OV_COM_CLIENT:0,B_OV_REM:31,B_OV_PROG:0,PA_OV_TOT:1,PA_OV_REM:0,PB1_OV_TOT:1,PB1_OV_REM:0,ACT_TAG_VALID:49,PUNCH_NUMBER_VALID:2,PUNCH_TAG_VALID:1,KSG_TARGET:"Full",KSG_TARGET_CODE:"F"},
    {SUBSYSTEM:"COM-MN-002",SUBSYSTEM_DESCR:"Temp Cooling Water",SUBSYSTEM_STATUS:"Subsystems with No MC Started",PUNCH_STATUS:"N",MC_PLAN:"2022-05-05",A_OV_PROG:100,ACT_TAG_VALID:-1,PUNCH_NUMBER_VALID:-1,PUNCH_TAG_VALID:-1,KSG_TARGET:"Partial",KSG_TARGET_CODE:"P"}
    ],
  Queries: {
    SQL1: [
      { DESC: 'DataSource SPCS Data 03-Mar-20' }
    ],
    SQL2: [
      { CDATE: '2021-10-21' }
    ],
    SQL3: [
      // Table
      {MC_PLAN:"2021-09-30",A_T:572,A_A:572,A_O:0,B_T:784,B_A:518,B_O:266},
      {MC_PLAN:"2021-10-07"},
      {MC_PLAN:"2021-10-14",A_T:100,A_A:100,A_O:0,B_T:86,B_A:62,B_O:24},
      {MC_PLAN:"2021-10-21",A_T:104,A_A:104,A_O:0,B_T:232,B_A:146,B_O:86},
      {MC_PLAN:"2021-10-28"},
      {MC_PLAN:"2021-11-04",A_T:438,A_A:438,A_O:0,B_T:626,B_A:408,B_O:218},
      {MC_PLAN:"2021-11-11"},
      {MC_PLAN:"2021-11-18"},
      {MC_PLAN:"2021-11-25",A_T:404,A_A:404,A_O:0,B_T:886,B_A:190,B_O:696},
      {MC_PLAN:"2021-12-02",A_T:82,A_A:82,A_O:0,B_T:192,B_A:114,B_O:78},
      {MC_PLAN:"2021-12-09"},
      {MC_PLAN:"2021-12-16"},
      {MC_PLAN:"2021-12-23"},
      {MC_PLAN:"2021-12-30",A_T:142,A_A:142,A_O:0,B_T:4,B_A:0,B_O:4},
      {MC_PLAN:"2022-01-06"},
      {MC_PLAN:"2022-01-13"},
      {MC_PLAN:"2022-01-20"},
      {MC_PLAN:"2022-01-27",A_T:544,A_A:536,A_O:8,B_T:314,B_A:16,B_O:298},
      {MC_PLAN:"2022-02-03",A_T:266,A_A:246,A_O:20,B_T:234,B_A:4,B_O:230},
      {MC_PLAN:"2022-02-10",A_T:864,A_A:698,A_O:166,B_T:320,B_A:2,B_O:318},
      {MC_PLAN:"2022-02-17",A_T:2770,A_A:2458,A_O:312,B_T:1070,B_A:54,B_O:1016},
      {MC_PLAN:"2022-02-24",A_T:1108,A_A:700,A_O:408,B_T:628,B_A:0,B_O:628},
      {MC_PLAN:"2022-03-03",A_T:156,A_A:114,A_O:42,B_T:814,B_A:22,B_O:792},
      {MC_PLAN:"2022-03-10",A_T:92,A_A:44,A_O:48,B_T:28,B_A:0,B_O:28},
      {MC_PLAN:"2022-03-17",A_T:78,A_A:62,A_O:16,B_T:6,B_A:0,B_O:6},
      {MC_PLAN:"2022-03-24"},
      {MC_PLAN:"2022-03-31",A_T:252,A_A:198,A_O:54,B_T:112,B_A:0,B_O:112},
      {MC_PLAN:"2022-04-07",A_T:230,A_A:230,A_O:0,B_T:64,B_A:0,B_O:64},
      {MC_PLAN:"2022-04-14"},
      {MC_PLAN:"2022-04-21"},
      {MC_PLAN:"2022-04-28"},
      {MC_PLAN:"2022-05-05",A_T:136,A_A:4,A_O:132,B_T:62,B_A:0,B_O:62},
      {MC_PLAN:"2022-05-12"}
      ],
    SQL4: [
      {CUTOFF:"2018-06-21",WK_NO:"W2018_25"},
      {CUTOFF:"2018-06-28",WK_NO:"W2018_26"},
      {CUTOFF:"2018-07-05",WK_NO:"W2018_27"},
      {CUTOFF:"2018-07-12",WK_NO:"W2018_28"},
      {CUTOFF:"2018-07-19",WK_NO:"W2018_29"},
      {CUTOFF:"2018-07-26",WK_NO:"W2018_30"},
      {CUTOFF:"2018-08-02",WK_NO:"W2018_31"},
      {CUTOFF:"2018-08-09",WK_NO:"W2018_32"},
      {CUTOFF:"2018-08-16",WK_NO:"W2018_33"},
      {CUTOFF:"2018-08-23",WK_NO:"W2018_34"},
      {CUTOFF:"2018-08-30",WK_NO:"W2018_35"},
      {CUTOFF:"2018-09-06",WK_NO:"W2018_36"},
      {CUTOFF:"2018-09-13",WK_NO:"W2018_37"},
      {CUTOFF:"2018-09-20",WK_NO:"W2018_38"},
      {CUTOFF:"2018-09-27",WK_NO:"W2018_39"},
      {CUTOFF:"2018-10-04",WK_NO:"W2018_40"},
      {CUTOFF:"2018-10-11",WK_NO:"W2018_41"},
      {CUTOFF:"2018-10-18",WK_NO:"W2018_42"},
      {CUTOFF:"2018-10-25",WK_NO:"W2018_43"},
      {CUTOFF:"2018-11-01",WK_NO:"W2018_44"},
      {CUTOFF:"2018-11-08",WK_NO:"W2018_45"},
      {CUTOFF:"2018-11-15",WK_NO:"W2018_46"},
      {CUTOFF:"2018-11-22",WK_NO:"W2018_47"},
      {CUTOFF:"2018-11-29",WK_NO:"W2018_48"},
      {CUTOFF:"2018-12-06",WK_NO:"W2018_49"},
      {CUTOFF:"2018-12-13",WK_NO:"W2018_50"},
      {CUTOFF:"2018-12-20",WK_NO:"W2018_51"},
      {CUTOFF:"2018-12-27",WK_NO:"W2018_52"},
      {CUTOFF:"2019-01-03",WK_NO:"W2019_01"},
      {CUTOFF:"2019-01-10",WK_NO:"W2019_02"},
      {CUTOFF:"2019-01-17",WK_NO:"W2019_03"},
      {CUTOFF:"2019-01-24",WK_NO:"W2019_04"},
      {CUTOFF:"2019-01-31",WK_NO:"W2019_05"},
      {CUTOFF:"2019-02-07",WK_NO:"W2019_06"},
      {CUTOFF:"2019-02-14",WK_NO:"W2019_07"},
      {CUTOFF:"2019-02-21",WK_NO:"W2019_08"},
      {CUTOFF:"2019-02-28",WK_NO:"W2019_09"},
      {CUTOFF:"2019-03-07",WK_NO:"W2019_10"},
      {CUTOFF:"2019-03-14",WK_NO:"W2019_11"},
      {CUTOFF:"2019-03-21",WK_NO:"W2019_12"},
      {CUTOFF:"2019-03-28",WK_NO:"W2019_13"},
      {CUTOFF:"2019-04-04",WK_NO:"W2019_14"},
      {CUTOFF:"2019-04-11",WK_NO:"W2019_15"},
      {CUTOFF:"2019-04-18",WK_NO:"W2019_16"},
      {CUTOFF:"2019-04-25",WK_NO:"W2019_17"},
      {CUTOFF:"2019-05-02",WK_NO:"W2019_18"},
      {CUTOFF:"2019-05-09",WK_NO:"W2019_19"},
      {CUTOFF:"2019-05-16",WK_NO:"W2019_20"},
      {CUTOFF:"2019-05-23",WK_NO:"W2019_21"},
      {CUTOFF:"2019-05-30",WK_NO:"W2019_22"},
      {CUTOFF:"2019-06-06",WK_NO:"W2019_23"},
      {CUTOFF:"2019-06-13",WK_NO:"W2019_24"},
      {CUTOFF:"2019-06-20",WK_NO:"W2019_25"},
      {CUTOFF:"2019-06-27",WK_NO:"W2019_26"},
      {CUTOFF:"2019-07-04",WK_NO:"W2019_27"},
      {CUTOFF:"2019-07-11",WK_NO:"W2019_28"},
      {CUTOFF:"2019-07-18",WK_NO:"W2019_29"},
      {CUTOFF:"2019-07-25",WK_NO:"W2019_30"},
      {CUTOFF:"2019-08-01",WK_NO:"W2019_31"},
      {CUTOFF:"2019-08-08",WK_NO:"W2019_32"},
      {CUTOFF:"2019-08-15",WK_NO:"W2019_33"},
      {CUTOFF:"2019-08-22",WK_NO:"W2019_34"},
      {CUTOFF:"2019-08-29",WK_NO:"W2019_35"},
      {CUTOFF:"2019-09-05",WK_NO:"W2019_36"},
      {CUTOFF:"2019-09-12",WK_NO:"W2019_37"},
      {CUTOFF:"2019-09-19",WK_NO:"W2019_38"},
      {CUTOFF:"2019-09-26",WK_NO:"W2019_39"},
      {CUTOFF:"2019-10-03",WK_NO:"W2019_40"},
      {CUTOFF:"2019-10-10",WK_NO:"W2019_41"},
      {CUTOFF:"2019-10-17",WK_NO:"W2019_42"},
      {CUTOFF:"2019-10-24",WK_NO:"W2019_43"},
      {CUTOFF:"2019-10-31",WK_NO:"W2019_44"},
      {CUTOFF:"2019-11-07",WK_NO:"W2019_45"},
      {CUTOFF:"2019-11-14",WK_NO:"W2019_46"},
      {CUTOFF:"2019-11-21",WK_NO:"W2019_47"},
      {CUTOFF:"2019-11-28",WK_NO:"W2019_48"},
      {CUTOFF:"2019-12-05",WK_NO:"W2019_49"},
      {CUTOFF:"2019-12-12",WK_NO:"W2019_50"},
      {CUTOFF:"2019-12-19",WK_NO:"W2019_51"},
      {CUTOFF:"2019-12-26",WK_NO:"W2019_52"},
      {CUTOFF:"2020-01-02",WK_NO:"W2020_01"},
      {CUTOFF:"2020-01-09",WK_NO:"W2020_02"},
      {CUTOFF:"2020-01-16",WK_NO:"W2020_03"},
      {CUTOFF:"2020-01-23",WK_NO:"W2020_04"},
      {CUTOFF:"2020-01-30",WK_NO:"W2020_05"},
      {CUTOFF:"2020-02-06",WK_NO:"W2020_06"},
      {CUTOFF:"2020-02-13",WK_NO:"W2020_07"},
      {CUTOFF:"2020-02-20",WK_NO:"W2020_08"},
      {CUTOFF:"2020-02-27",WK_NO:"W2020_09"},
      {CUTOFF:"2020-03-05",WK_NO:"W2020_10"},
      {CUTOFF:"2020-03-12",WK_NO:"W2020_11"},
      {CUTOFF:"2020-03-19",WK_NO:"W2020_12"},
      {CUTOFF:"2020-03-26",WK_NO:"W2020_13"},
      {CUTOFF:"2020-04-02",WK_NO:"W2020_14"},
      {CUTOFF:"2020-04-09",WK_NO:"W2020_15"},
      {CUTOFF:"2020-04-16",WK_NO:"W2020_16"},
      {CUTOFF:"2020-04-23",WK_NO:"W2020_17"},
      {CUTOFF:"2020-04-30",WK_NO:"W2020_18"},
      {CUTOFF:"2020-05-07",WK_NO:"W2020_19"},
      {CUTOFF:"2020-05-14",WK_NO:"W2020_20"},
      {CUTOFF:"2020-05-21",WK_NO:"W2020_21"},
      {CUTOFF:"2020-05-28",WK_NO:"W2020_22"},
      {CUTOFF:"2020-06-04",WK_NO:"W2020_23"},
      {CUTOFF:"2020-06-11",WK_NO:"W2020_24"},
      {CUTOFF:"2020-06-18",WK_NO:"W2020_25"},
      {CUTOFF:"2020-06-25",WK_NO:"W2020_26"},
      {CUTOFF:"2020-07-02",WK_NO:"W2020_27"},
      {CUTOFF:"2020-07-09",WK_NO:"W2020_28"},
      {CUTOFF:"2020-07-16",WK_NO:"W2020_29"},
      {CUTOFF:"2020-07-23",WK_NO:"W2020_30"},
      {CUTOFF:"2020-07-30",WK_NO:"W2020_31"},
      {CUTOFF:"2020-08-06",WK_NO:"W2020_32"},
      {CUTOFF:"2020-08-13",WK_NO:"W2020_33"},
      {CUTOFF:"2020-08-20",WK_NO:"W2020_34"},
      {CUTOFF:"2020-08-27",WK_NO:"W2020_35"},
      {CUTOFF:"2020-09-03",WK_NO:"W2020_36"},
      {CUTOFF:"2020-09-10",WK_NO:"W2020_37"},
      {CUTOFF:"2020-09-17",WK_NO:"W2020_38"},
      {CUTOFF:"2020-09-24",WK_NO:"W2020_39"},
      {CUTOFF:"2020-10-01",WK_NO:"W2020_40"},
      {CUTOFF:"2020-10-08",WK_NO:"W2020_41"},
      {CUTOFF:"2020-10-15",WK_NO:"W2020_42"},
      {CUTOFF:"2020-10-22",WK_NO:"W2020_43"},
      {CUTOFF:"2020-10-29",WK_NO:"W2020_44"},
      {CUTOFF:"2020-11-05",WK_NO:"W2020_45"},
      {CUTOFF:"2020-11-12",WK_NO:"W2020_46"},
      {CUTOFF:"2020-11-19",WK_NO:"W2020_47"},
      {CUTOFF:"2020-11-26",WK_NO:"W2020_48"},
      {CUTOFF:"2020-12-03",WK_NO:"W2020_49"},
      {CUTOFF:"2020-12-10",WK_NO:"W2020_50"},
      {CUTOFF:"2020-12-17",WK_NO:"W2020_51"},
      {CUTOFF:"2020-12-24",WK_NO:"W2020_52"},
      {CUTOFF:"2020-12-31",WK_NO:"W2020_53"},
      {CUTOFF:"2021-01-07",WK_NO:"W2021_01"},
      {CUTOFF:"2021-01-14",WK_NO:"W2021_02"},
      {CUTOFF:"2021-01-21",WK_NO:"W2021_03"},
      {CUTOFF:"2021-01-28",WK_NO:"W2021_04"},
      {CUTOFF:"2021-02-04",WK_NO:"W2021_05"},
      {CUTOFF:"2021-02-11",WK_NO:"W2021_06"},
      {CUTOFF:"2021-02-18",WK_NO:"W2021_07"},
      {CUTOFF:"2021-02-25",WK_NO:"W2021_08"},
      {CUTOFF:"2021-03-04",WK_NO:"W2021_09"},
      {CUTOFF:"2021-03-11",WK_NO:"W2021_10"},
      {CUTOFF:"2021-03-18",WK_NO:"W2021_11"},
      {CUTOFF:"2021-03-25",WK_NO:"W2021_12"},
      {CUTOFF:"2021-04-01",WK_NO:"W2021_13"},
      {CUTOFF:"2021-04-08",WK_NO:"W2021_14"},
      {CUTOFF:"2021-04-15",WK_NO:"W2021_15"},
      {CUTOFF:"2021-04-22",WK_NO:"W2021_16"},
      {CUTOFF:"2021-04-29",WK_NO:"W2021_17"},
      {CUTOFF:"2021-05-06",WK_NO:"W2021_18"},
      {CUTOFF:"2021-05-13",WK_NO:"W2021_19"},
      {CUTOFF:"2021-05-20",WK_NO:"W2021_20"},
      {CUTOFF:"2021-05-27",WK_NO:"W2021_21"},
      {CUTOFF:"2021-06-03",WK_NO:"W2021_22"},
      {CUTOFF:"2021-06-10",WK_NO:"W2021_23"},
      {CUTOFF:"2021-06-17",WK_NO:"W2021_24"},
      {CUTOFF:"2021-06-24",WK_NO:"W2021_25"},
      {CUTOFF:"2021-07-01",WK_NO:"W2021_26"},
      {CUTOFF:"2021-07-08",WK_NO:"W2021_27"},
      {CUTOFF:"2021-07-15",WK_NO:"W2021_28"},
      {CUTOFF:"2021-07-22",WK_NO:"W2021_29"},
      {CUTOFF:"2021-07-29",WK_NO:"W2021_30"},
      {CUTOFF:"2021-08-05",WK_NO:"W2021_31"},
      {CUTOFF:"2021-08-12",WK_NO:"W2021_32"},
      {CUTOFF:"2021-08-19",WK_NO:"W2021_33"},
      {CUTOFF:"2021-08-26",WK_NO:"W2021_34"},
      {CUTOFF:"2021-09-02",WK_NO:"W2021_35"},
      {CUTOFF:"2021-09-09",WK_NO:"W2021_36"},
      {CUTOFF:"2021-09-16",WK_NO:"W2021_37"},
      {CUTOFF:"2021-09-23",WK_NO:"W2021_38"},
      {CUTOFF:"2021-09-30",WK_NO:"W2021_39"},
      {CUTOFF:"2021-10-07",WK_NO:"W2021_40"},
      {CUTOFF:"2021-10-14",WK_NO:"W2021_41"},
      {CUTOFF:"2021-10-21",WK_NO:"W2021_42"},
      {CUTOFF:"2021-10-28",WK_NO:"W2021_43"},
      {CUTOFF:"2021-11-04",WK_NO:"W2021_44"},
      {CUTOFF:"2021-11-11",WK_NO:"W2021_45"},
      {CUTOFF:"2021-11-18",WK_NO:"W2021_46"},
      {CUTOFF:"2021-11-25",WK_NO:"W2021_47"},
      {CUTOFF:"2021-12-02",WK_NO:"W2021_48"},
      {CUTOFF:"2021-12-09",WK_NO:"W2021_49"},
      {CUTOFF:"2021-12-16",WK_NO:"W2021_50"},
      {CUTOFF:"2021-12-23",WK_NO:"W2021_51"},
      {CUTOFF:"2021-12-30",WK_NO:"W2021_52"},
      {CUTOFF:"2022-01-06",WK_NO:"W2022_01"},
      {CUTOFF:"2022-01-13",WK_NO:"W2022_02"},
      {CUTOFF:"2022-01-20",WK_NO:"W2022_03"},
      {CUTOFF:"2022-01-27",WK_NO:"W2022_04"},
      {CUTOFF:"2022-02-03",WK_NO:"W2022_05"},
      {CUTOFF:"2022-02-10",WK_NO:"W2022_06"},
      {CUTOFF:"2022-02-17",WK_NO:"W2022_07"},
      {CUTOFF:"2022-02-24",WK_NO:"W2022_08"},
      {CUTOFF:"2022-03-03",WK_NO:"W2022_09"},
      {CUTOFF:"2022-03-10",WK_NO:"W2022_10"},
      {CUTOFF:"2022-03-17",WK_NO:"W2022_11"},
      {CUTOFF:"2022-03-24",WK_NO:"W2022_12"},
      {CUTOFF:"2022-03-31",WK_NO:"W2022_13"},
      {CUTOFF:"2022-04-07",WK_NO:"W2022_14"},
      {CUTOFF:"2022-04-14",WK_NO:"W2022_15"},
      {CUTOFF:"2022-04-21",WK_NO:"W2022_16"},
      {CUTOFF:"2022-04-28",WK_NO:"W2022_17"},
      {CUTOFF:"2022-05-05",WK_NO:"W2022_18"},
      {CUTOFF:"2022-05-12",WK_NO:"W2022_19"},
      {CUTOFF:"2022-05-19",WK_NO:"W2022_20"},
      {CUTOFF:"2022-05-26",WK_NO:"W2022_21"},
      {CUTOFF:"2022-06-02",WK_NO:"W2022_22"},
      {CUTOFF:"2022-06-09",WK_NO:"W2022_23"},
      {CUTOFF:"2022-06-16",WK_NO:"W2022_24"},
      {CUTOFF:"2022-06-23",WK_NO:"W2022_25"},
      {CUTOFF:"2022-06-30",WK_NO:"W2022_26"},
      {CUTOFF:"2022-07-07",WK_NO:"W2022_27"},
      {CUTOFF:"2022-07-14",WK_NO:"W2022_28"},
      {CUTOFF:"2022-07-21",WK_NO:"W2022_29"},
      {CUTOFF:"2022-07-28",WK_NO:"W2022_30"},
      {CUTOFF:"2022-08-04",WK_NO:"W2022_31"},
      {CUTOFF:"2022-08-11",WK_NO:"W2022_32"},
      {CUTOFF:"2022-08-18",WK_NO:"W2022_33"},
      {CUTOFF:"2022-08-25",WK_NO:"W2022_34"},
      {CUTOFF:"2022-09-01",WK_NO:"W2022_35"},
      {CUTOFF:"2022-09-08",WK_NO:"W2022_36"},
      {CUTOFF:"2022-09-15",WK_NO:"W2022_37"},
      {CUTOFF:"2022-09-22",WK_NO:"W2022_38"},
      {CUTOFF:"2022-09-29",WK_NO:"W2022_39"},
      {CUTOFF:"2022-10-06",WK_NO:"W2022_40"},
      {CUTOFF:"2022-10-13",WK_NO:"W2022_41"},
      {CUTOFF:"2022-10-20",WK_NO:"W2022_42"},
      {CUTOFF:"2022-10-27",WK_NO:"W2022_43"},
      {CUTOFF:"2022-11-03",WK_NO:"W2022_44"},
      {CUTOFF:"2022-11-10",WK_NO:"W2022_45"},
      {CUTOFF:"2022-11-17",WK_NO:"W2022_46"},
      {CUTOFF:"2022-11-24",WK_NO:"W2022_47"},
      {CUTOFF:"2022-12-01",WK_NO:"W2022_48"},
      {CUTOFF:"2022-12-08",WK_NO:"W2022_49"},
      {CUTOFF:"2022-12-15",WK_NO:"W2022_50"},
      {CUTOFF:"2022-12-22",WK_NO:"W2022_51"},
      {CUTOFF:"2022-12-29",WK_NO:"W2022_52"}
    ],
    SQL5: [ // donut
      {TOTAL:150,NOTSTARTED:64,APROGRESS:64,PAR_MC:24,FULL_MC:22}
    ],
    SQL6: [ // chart - ITR A
      {CATEGORY:"ITR-A",TOTAL:4169,COMPLETED:3566,REMAIN:603,PROG:85.5}
    ],
    SQL7: [ // chart - WD
      {CATEGORY:"WD",DISC:"WD",TOTAL:75,ACTUAL:40,REMAIN:35,PROG:53.3}
    ],
    SQL8: [ // chart - Punch A
      {CATEGORY:"PUNCHA",TOTAL:739,ACTUAL:660,REMAIN:79,PROG:89.3}
    ],
    SQL9: [ // chart - B ITR
      {CATEGORY:"ITR-B",TOTAL:3231,COMPLETED:768,REMAIN:2463,PROG:23.8}
    ],
    SQL10: [ // week data ITR A
      {DATE:"2022-02-10",CUTOFF:"2022-02-10",LAST_WK:68,FRI:0,SAT:0,SUN:1,MON:6,TUE:84,WED:0,THU:0,THIS_WK:91}
    ],
    SQL11: [ // week data ITR B
      {DATE:"2022-02-10",CUTOFF:"2022-02-10",LAST_WK:68,FRI:0,SAT:0,SUN:1,MON:6,TUE:84,WED:0,THU:0,THIS_WK:91}
    ],
  }
}