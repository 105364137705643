
export default {
  Canvas: {
    CanvasWidth: 1300,
    CanvasHeight: 3950,
    CanvasBorderWeight: 0.2,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',//0E1B2B
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 760,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#fff',
    CanvasChartOpacity: 1,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },


  Text: {
  },
  

  DataItems: [
    { SCOPE: 'P1', UNIT: 'Boiler & Furnace System Owner', UNIT_NO: 'Fengshan Liu',                 UNIT_DESC: '', SSMCC_TOT: 10, SSMCC_PLN: 0, SSMCC_WD: 0,  SSMCC_ACT: 0, SSMCC_REM: 10, SSMCC_PRO: 0,    MIN_PLAN: '2024-02-28', MAX_PLAN: '2024-09-30', A_TOT: 43449, A_ACT: 3073,  A_REM: 40376, A_PRO: 7.1,  B_TOT: 13198, B_ACT: 3,    B_REM: 13195, B_PRO: 0,    C_TOT: 237, C_ACT: 0,   C_REM: 237, C_PRO: 0,   AP_TOT: 355,  AP_ACT: 52,   AP_REM: 303, AP_PRO: 14.6, BP_TOT: 104,  BP_ACT: 9,    BP_REM: 95,  BP_PRO: 8.7,  CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0,  CP_PRO: 0, },
    { SCOPE: 'P1', UNIT: 'CGC System Owner',              UNIT_NO: 'KT Lim / Alvin Jia',           UNIT_DESC: '', SSMCC_TOT: 5,  SSMCC_PLN: 0, SSMCC_WD: 0,  SSMCC_ACT: 0, SSMCC_REM: 5,  SSMCC_PRO: 0,    MIN_PLAN: '2024-07-31', MAX_PLAN: '2024-09-20', A_TOT: 13943, A_ACT: 382,   A_REM: 13561, A_PRO: 2.7,  B_TOT: 4250,  B_ACT: 0,    B_REM: 4250,  B_PRO: 0,    C_TOT: 54,  C_ACT: 0,   C_REM: 54,  C_PRO: 0,   AP_TOT: 35,   AP_ACT: 6,    AP_REM: 29,  AP_PRO: 17.1, BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0,  CP_PRO: 0, },
    { SCOPE: 'P3', UNIT: 'Cooling Water System Owner',    UNIT_NO: 'Ralph P (CW1) / Victor (CW2)', UNIT_DESC: '', SSMCC_TOT: 2,  SSMCC_PLN: 0, SSMCC_WD: 0,  SSMCC_ACT: 0, SSMCC_REM: 2,  SSMCC_PRO: 0,    MIN_PLAN: '2024-02-15', MAX_PLAN: '2024-03-15', A_TOT: 5586,  A_ACT: 230,   A_REM: 5356,  A_PRO: 4.1,  B_TOT: 1901,  B_ACT: 0,    B_REM: 1901,  B_PRO: 0,    C_TOT: 61,  C_ACT: 0,   C_REM: 61,  C_PRO: 0,   AP_TOT: 4,    AP_ACT: 0,    AP_REM: 4,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0,  CP_PRO: 0, },
    { SCOPE: 'P1', UNIT: 'Co-Product System Owner',       UNIT_NO: 'Vivien Cheng',                 UNIT_DESC: '', SSMCC_TOT: 6,  SSMCC_PLN: 0, SSMCC_WD: 0,  SSMCC_ACT: 0, SSMCC_REM: 6,  SSMCC_PRO: 0,    MIN_PLAN: '2024-08-20', MAX_PLAN: '2024-09-30', A_TOT: 19076, A_ACT: 650,   A_REM: 18426, A_PRO: 3.4,  B_TOT: 5065,  B_ACT: 0,    B_REM: 5065,  B_PRO: 0,    C_TOT: 67,  C_ACT: 0,   C_REM: 67,  C_PRO: 0,   AP_TOT: 32,   AP_ACT: 7,    AP_REM: 25,  AP_PRO: 21.9, BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0,  CP_PRO: 0, },
    { SCOPE: 'P2', UNIT: 'Finishing System Owner',        UNIT_NO: 'Stan Shermer',                 UNIT_DESC: '', SSMCC_TOT: 10, SSMCC_PLN: 0, SSMCC_WD: 0,  SSMCC_ACT: 0, SSMCC_REM: 10, SSMCC_PRO: 0,    MIN_PLAN: '2024-05-20', MAX_PLAN: '2024-06-30', A_TOT: 25831, A_ACT: 244,   A_REM: 25587, A_PRO: 0.9,  B_TOT: 7311,  B_ACT: 0,    B_REM: 7311,  B_PRO: 0,    C_TOT: 333, C_ACT: 0,   C_REM: 333, C_PRO: 0,   AP_TOT: 45,   AP_ACT: 2,    AP_REM: 43,  AP_PRO: 4.4,  BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0,  CP_PRO: 0, },
    { SCOPE: 'P3', UNIT: 'Flare System Owner',            UNIT_NO: 'Raymond',                      UNIT_DESC: '', SSMCC_TOT: 4,  SSMCC_PLN: 0, SSMCC_WD: 0,  SSMCC_ACT: 0, SSMCC_REM: 4,  SSMCC_PRO: 0,    MIN_PLAN: '2024-03-31', MAX_PLAN: '2024-08-09', A_TOT: 7162,  A_ACT: 70,    A_REM: 7092,  A_PRO: 1,    B_TOT: 1941,  B_ACT: 6,    B_REM: 1935,  B_PRO: 0.3,  C_TOT: 75,  C_ACT: 0,   C_REM: 75,  C_PRO: 0,   AP_TOT: 9,    AP_ACT: 1,    AP_REM: 8,   AP_PRO: 11.1, BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0,  CP_PRO: 0, },
    { SCOPE: 'P2', UNIT: 'GPPE System Owner',             UNIT_NO: 'Song Jianfeng',                UNIT_DESC: '', SSMCC_TOT: 12, SSMCC_PLN: 0, SSMCC_WD: 0,  SSMCC_ACT: 0, SSMCC_REM: 12, SSMCC_PRO: 0,    MIN_PLAN: '2024-02-29', MAX_PLAN: '2024-07-31', A_TOT: 15351, A_ACT: 371,   A_REM: 14980, A_PRO: 2.4,  B_TOT: 3894,  B_ACT: 2,    B_REM: 3892,  B_PRO: 0.1,  C_TOT: 51,  C_ACT: 0,   C_REM: 51,  C_PRO: 0,   AP_TOT: 147,  AP_ACT: 3,    AP_REM: 144, AP_PRO: 2,    BP_TOT: 1,    BP_ACT: 0,    BP_REM: 1,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0,  CP_PRO: 0, },
    { SCOPE: 'P2', UNIT: 'LDPE System Owner',             UNIT_NO: 'Deng Mengdong',                UNIT_DESC: '', SSMCC_TOT: 13, SSMCC_PLN: 0, SSMCC_WD: 0,  SSMCC_ACT: 0, SSMCC_REM: 13, SSMCC_PRO: 0,    MIN_PLAN: '2024-03-31', MAX_PLAN: '2024-08-30', A_TOT: 10619, A_ACT: 398,   A_REM: 10221, A_PRO: 3.7,  B_TOT: 2848,  B_ACT: 2,    B_REM: 2846,  B_PRO: 0.1,  C_TOT: 94,  C_ACT: 0,   C_REM: 94,  C_PRO: 0,   AP_TOT: 50,   AP_ACT: 0,    AP_REM: 50,  AP_PRO: 0,    BP_TOT: 41,   BP_ACT: 0,    BP_REM: 41,  BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0,  CP_PRO: 0, },
    { SCOPE: 'P1', UNIT: 'P1 Building System Owner',      UNIT_NO: 'TBC',                          UNIT_DESC: '', SSMCC_TOT: 11, SSMCC_PLN: 3, SSMCC_WD: 4,  SSMCC_ACT: 4, SSMCC_REM: 7,  SSMCC_PRO: 36.4, MIN_PLAN: '2023-10-30', MAX_PLAN: '2024-09-30', A_TOT: 9177,  A_ACT: 3242,  A_REM: 5935,  A_PRO: 35.3, B_TOT: 7903,  B_ACT: 1230, B_REM: 6673,  B_PRO: 15.6, C_TOT: 45,  C_ACT: 0,   C_REM: 45,  C_PRO: 0,   AP_TOT: 389,  AP_ACT: 372,  AP_REM: 17,  AP_PRO: 95.6, BP_TOT: 340,  BP_ACT: 242,  BP_REM: 98,  BP_PRO: 71.2, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0,  CP_PRO: 0, },
    { SCOPE: 'P2', UNIT: 'P2 Building System Owner',      UNIT_NO: 'TBC',                          UNIT_DESC: '', SSMCC_TOT: 11, SSMCC_PLN: 3, SSMCC_WD: 5,  SSMCC_ACT: 3, SSMCC_REM: 8,  SSMCC_PRO: 27.3, MIN_PLAN: '2023-12-05', MAX_PLAN: '2024-07-31', A_TOT: 16575, A_ACT: 12618, A_REM: 3957,  A_PRO: 76.1, B_TOT: 10974, B_ACT: 1983, B_REM: 8991,  B_PRO: 18.1, C_TOT: 57,  C_ACT: 0,   C_REM: 57,  C_PRO: 0,   AP_TOT: 1502, AP_ACT: 1182, AP_REM: 320, AP_PRO: 78.7, BP_TOT: 885,  BP_ACT: 527,  BP_REM: 358, BP_PRO: 59.5, CP_TOT: 33, CP_ACT: 31, CP_REM: 2,  CP_PRO: 93.9, },
    { SCOPE: 'P3', UNIT: 'P3 Building System Owner',      UNIT_NO: 'TBC',                          UNIT_DESC: '', SSMCC_TOT: 35, SSMCC_PLN: 9, SSMCC_WD: 12, SSMCC_ACT: 8, SSMCC_REM: 27, SSMCC_PRO: 22.9, MIN_PLAN: '2023-09-05', MAX_PLAN: '2024-06-30', A_TOT: 26417, A_ACT: 16447, A_REM: 9970,  A_PRO: 62.3, B_TOT: 14743, B_ACT: 3741, B_REM: 11002, B_PRO: 25.4, C_TOT: 206, C_ACT: 101, C_REM: 105, C_PRO: 49,  AP_TOT: 1682, AP_ACT: 1287, AP_REM: 395, AP_PRO: 76.5, BP_TOT: 3710, BP_ACT: 3045, BP_REM: 665, BP_PRO: 82.1, CP_TOT: 42, CP_ACT: 24, CP_REM: 18, CP_PRO: 57.1, },
    { SCOPE: 'P2', UNIT: 'PBL / Utility System Owner',    UNIT_NO: 'Jiang Zerun',                  UNIT_DESC: '', SSMCC_TOT: 6,  SSMCC_PLN: 0, SSMCC_WD: 0,  SSMCC_ACT: 0, SSMCC_REM: 6,  SSMCC_PRO: 0,    MIN_PLAN: '2024-03-31', MAX_PLAN: '2024-07-31', A_TOT: 19339, A_ACT: 182,   A_REM: 19157, A_PRO: 0.9,  B_TOT: 4681,  B_ACT: 0,    B_REM: 4681,  B_PRO: 0,    C_TOT: 29,  C_ACT: 0,   C_REM: 29,  C_PRO: 0,   AP_TOT: 42,   AP_ACT: 16,   AP_REM: 26,  AP_PRO: 38.1, BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0,  CP_PRO: 0, },
    { SCOPE: 'P2', UNIT: 'PP System Owner',               UNIT_NO: 'Lin Zhen',                     UNIT_DESC: '', SSMCC_TOT: 22, SSMCC_PLN: 0, SSMCC_WD: 0,  SSMCC_ACT: 0, SSMCC_REM: 22, SSMCC_PRO: 0,    MIN_PLAN: '2024-02-29', MAX_PLAN: '2024-07-01', A_TOT: 14781, A_ACT: 206,   A_REM: 14575, A_PRO: 1.4,  B_TOT: 4249,  B_ACT: 0,    B_REM: 4249,  B_PRO: 0,    C_TOT: 122, C_ACT: 0,   C_REM: 122, C_PRO: 0,   AP_TOT: 143,  AP_ACT: 2,    AP_REM: 141, AP_PRO: 1.4,  BP_TOT: 1,    BP_ACT: 0,    BP_REM: 1,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0,  CP_PRO: 0, },
    { SCOPE: 'P1', UNIT: 'Tank Farm System Owner',        UNIT_NO: 'Liu Wei',                      UNIT_DESC: '', SSMCC_TOT: 13, SSMCC_PLN: 0, SSMCC_WD: 0,  SSMCC_ACT: 0, SSMCC_REM: 13, SSMCC_PRO: 0,    MIN_PLAN: '2024-04-30', MAX_PLAN: '2024-08-09', A_TOT: 15665, A_ACT: 315,   A_REM: 15350, A_PRO: 2,    B_TOT: 4584,  B_ACT: 67,   B_REM: 4517,  B_PRO: 1.5,  C_TOT: 212, C_ACT: 0,   C_REM: 212, C_PRO: 0,   AP_TOT: 101,  AP_ACT: 2,    AP_REM: 99,  AP_PRO: 2,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0,  CP_PRO: 0, },
    { SCOPE: 'P3', UNIT: 'Tank Farm System Owner',        UNIT_NO: 'Liu Wei',                      UNIT_DESC: '', SSMCC_TOT: 5,  SSMCC_PLN: 0, SSMCC_WD: 0,  SSMCC_ACT: 0, SSMCC_REM: 5,  SSMCC_PRO: 0,    MIN_PLAN: '2024-03-30', MAX_PLAN: '2024-08-09', A_TOT: 401,   A_ACT: 27,    A_REM: 374,   A_PRO: 6.7,  B_TOT: 227,   B_ACT: 0,    B_REM: 227,   B_PRO: 0,    C_TOT: 8,   C_ACT: 0,   C_REM: 8,   C_PRO: 0,   AP_TOT: 4,    AP_ACT: 0,    AP_REM: 4,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0,  CP_PRO: 0, },
    { SCOPE: 'P3', UNIT: 'Telecom Overall System Owner',  UNIT_NO: 'James Keller',                 UNIT_DESC: '', SSMCC_TOT: 3,  SSMCC_PLN: 3, SSMCC_WD: 3,  SSMCC_ACT: 3, SSMCC_REM: 0,  SSMCC_PRO: 100,  MIN_PLAN: '2023-12-25', MAX_PLAN: '2024-01-23', A_TOT: 234,   A_ACT: 224,   A_REM: 10,    A_PRO: 95.7, B_TOT: 75,    B_ACT: 7,    B_REM: 68,    B_PRO: 9.3,  C_TOT: 3,   C_ACT: 0,   C_REM: 3,   C_PRO: 0,   AP_TOT: 43,   AP_ACT: 42,   AP_REM: 1,   AP_PRO: 97.7, BP_TOT: 41,   BP_ACT: 17,   BP_REM: 24,  BP_PRO: 41.5, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0,  CP_PRO: 0, },
    { SCOPE: 'P3', UNIT: 'Utility System Owner',          UNIT_NO: 'Gao Xu',                       UNIT_DESC: '', SSMCC_TOT: 5,  SSMCC_PLN: 1, SSMCC_WD: 1,  SSMCC_ACT: 0, SSMCC_REM: 5,  SSMCC_PRO: 0,    MIN_PLAN: '2024-01-15', MAX_PLAN: '2024-03-30', A_TOT: 4542,  A_ACT: 583,   A_REM: 3959,  A_PRO: 12.8, B_TOT: 1225,  B_ACT: 4,    B_REM: 1221,  B_PRO: 0.3,  C_TOT: 21,  C_ACT: 0,   C_REM: 21,  C_PRO: 0,   AP_TOT: 214,  AP_ACT: 27,   AP_REM: 187, AP_PRO: 12.6, BP_TOT: 174,  BP_ACT: 6,    BP_REM: 168, BP_PRO: 3.4,  CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0,  CP_PRO: 0, },
    { SCOPE: 'P1', UNIT: 'Water System Owner',            UNIT_NO: 'Yao Jialong',                  UNIT_DESC: '', SSMCC_TOT: 3,  SSMCC_PLN: 0, SSMCC_WD: 0,  SSMCC_ACT: 0, SSMCC_REM: 3,  SSMCC_PRO: 0,    MIN_PLAN: '2024-06-10', MAX_PLAN: '2024-08-30', A_TOT: 392,   A_ACT: 14,    A_REM: 378,   A_PRO: 3.6,  B_TOT: 134,   B_ACT: 0,    B_REM: 134,   B_PRO: 0,    C_TOT: 14,  C_ACT: 0,   C_REM: 14,  C_PRO: 0,   AP_TOT: 0,    AP_ACT: 0,    AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0,  CP_PRO: 0, },
    { SCOPE: 'P3', UNIT: 'Water System Owner',            UNIT_NO: 'Yao Jialong',                  UNIT_DESC: '', SSMCC_TOT: 6,  SSMCC_PLN: 3, SSMCC_WD: 4,  SSMCC_ACT: 4, SSMCC_REM: 2,  SSMCC_PRO: 66.7, MIN_PLAN: '2024-01-15', MAX_PLAN: '2024-02-28', A_TOT: 1908,  A_ACT: 1479,  A_REM: 429,   A_PRO: 77.5, B_TOT: 828,   B_ACT: 47,   B_REM: 781,   B_PRO: 5.7,  C_TOT: 67,  C_ACT: 1,   C_REM: 66,  C_PRO: 1.5, AP_TOT: 500,  AP_ACT: 489,  AP_REM: 11,  AP_PRO: 97.8, BP_TOT: 470,  BP_ACT: 298,  BP_REM: 172, BP_PRO: 63.4, CP_TOT: 4,  CP_ACT: 0,  CP_REM: 4,  CP_PRO: 0, },
    ],


  Queries: {
    SQL1: [ 
      { UNIT: 'Boiler & Furnace System Owner', SUBSYS_NO: '127901', CSTD_PLAN: '2024-09-25', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 24543, A_ACT: 40,   A_REM: 24503, A_PRO: 0.2,  B_TOT: 6304, B_ACT: 1,    B_REM: 6303, B_PRO: 0,    C_TOT: 41,  C_ACT: 0,   C_REM: 41,  C_PRO: 0,   AP_TOT: 48,  AP_ACT: 1,   AP_REM: 47,  AP_PRO: 2.1,  BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Boiler & Furnace System Owner', SUBSYS_NO: '127902', CSTD_PLAN: '2024-09-10', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 4026,  A_ACT: 225,  A_REM: 3801,  A_PRO: 5.6,  B_TOT: 1289, B_ACT: 2,    B_REM: 1287, B_PRO: 0.2,  C_TOT: 36,  C_ACT: 0,   C_REM: 36,  C_PRO: 0,   AP_TOT: 11,  AP_ACT: 5,   AP_REM: 6,   AP_PRO: 45.5, BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Boiler & Furnace System Owner', SUBSYS_NO: '127905', CSTD_PLAN: '2024-08-25', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 3016,  A_ACT: 143,  A_REM: 2873,  A_PRO: 4.7,  B_TOT: 888,  B_ACT: 0,    B_REM: 888,  B_PRO: 0,    C_TOT: 13,  C_ACT: 0,   C_REM: 13,  C_PRO: 0,   AP_TOT: 12,  AP_ACT: 4,   AP_REM: 8,   AP_PRO: 33.3, BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Boiler & Furnace System Owner', SUBSYS_NO: '127908', CSTD_PLAN: '2024-08-20', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 925,   A_ACT: 21,   A_REM: 904,   A_PRO: 2.3,  B_TOT: 316,  B_ACT: 0,    B_REM: 316,  B_PRO: 0,    C_TOT: 9,   C_ACT: 0,   C_REM: 9,   C_PRO: 0,   AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100,  BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Boiler & Furnace System Owner', SUBSYS_NO: '127909', CSTD_PLAN: '2024-09-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 590,   A_ACT: 21,   A_REM: 569,   A_PRO: 3.6,  B_TOT: 174,  B_ACT: 0,    B_REM: 174,  B_PRO: 0,    C_TOT: 4,   C_ACT: 0,   C_REM: 4,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Boiler & Furnace System Owner', SUBSYS_NO: '184300', CSTD_PLAN: '2024-02-28', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 375,   A_ACT: 53,   A_REM: 322,   A_PRO: 14.1, B_TOT: 71,   B_ACT: 0,    B_REM: 71,   B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 121, AP_ACT: 52,  AP_REM: 69,  AP_PRO: 43,   BP_TOT: 48,   BP_ACT: 8,    BP_REM: 40,  BP_PRO: 16.7, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Boiler & Furnace System Owner', SUBSYS_NO: '184301', CSTD_PLAN: '2024-02-28', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 2537,  A_ACT: 1736, A_REM: 801,   A_PRO: 68.4, B_TOT: 1138, B_ACT: 0,    B_REM: 1138, B_PRO: 0,    C_TOT: 50,  C_ACT: 0,   C_REM: 50,  C_PRO: 0,   AP_TOT: 144, AP_ACT: 2,   AP_REM: 142, AP_PRO: 1.4,  BP_TOT: 28,   BP_ACT: 1,    BP_REM: 27,  BP_PRO: 3.6,  CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Boiler & Furnace System Owner', SUBSYS_NO: '184302', CSTD_PLAN: '2024-02-28', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 1168,  A_ACT: 762,  A_REM: 406,   A_PRO: 65.2, B_TOT: 433,  B_ACT: 0,    B_REM: 433,  B_PRO: 0,    C_TOT: 16,  C_ACT: 0,   C_REM: 16,  C_PRO: 0,   AP_TOT: 97,  AP_ACT: 47,  AP_REM: 50,  AP_PRO: 48.5, BP_TOT: 52,   BP_ACT: 4,    BP_REM: 48,  BP_PRO: 7.7,  CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Boiler & Furnace System Owner', SUBSYS_NO: '184303', CSTD_PLAN: '2024-03-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 183,   A_ACT: 20,   A_REM: 163,   A_PRO: 10.9, B_TOT: 79,   B_ACT: 0,    B_REM: 79,   B_PRO: 0,    C_TOT: 5,   C_ACT: 0,   C_REM: 5,   C_PRO: 0,   AP_TOT: 7,   AP_ACT: 0,   AP_REM: 7,   AP_PRO: 0,    BP_TOT: 1,    BP_ACT: 0,    BP_REM: 1,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Boiler & Furnace System Owner', SUBSYS_NO: '184800', CSTD_PLAN: '2024-03-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 6086,  A_ACT: 52,   A_REM: 6034,  A_PRO: 0.9,  B_TOT: 2506, B_ACT: 0,    B_REM: 2506, B_PRO: 0,    C_TOT: 63,  C_ACT: 0,   C_REM: 63,  C_PRO: 0,   AP_TOT: 22,  AP_ACT: 6,   AP_REM: 16,  AP_PRO: 27.3, BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'CGC System Owner',              SUBSYS_NO: '127906', CSTD_PLAN: '2024-09-12', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 446,   A_ACT: 8,    A_REM: 438,   A_PRO: 1.8,  B_TOT: 175,  B_ACT: 0,    B_REM: 175,  B_PRO: 0,    C_TOT: 2,   C_ACT: 0,   C_REM: 2,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'CGC System Owner',              SUBSYS_NO: '127907', CSTD_PLAN: '2024-09-15', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 923,   A_ACT: 46,   A_REM: 877,   A_PRO: 5,    B_TOT: 279,  B_ACT: 0,    B_REM: 279,  B_PRO: 0,    C_TOT: 11,  C_ACT: 0,   C_REM: 11,  C_PRO: 0,   AP_TOT: 2,   AP_ACT: 0,   AP_REM: 2,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'CGC System Owner',              SUBSYS_NO: '127900', CSTD_PLAN: '2024-09-20', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 1999,  A_ACT: 0,    A_REM: 1999,  A_PRO: 0,    B_TOT: 525,  B_ACT: 0,    B_REM: 525,  B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 2,   AP_ACT: 0,   AP_REM: 2,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'CGC System Owner',              SUBSYS_NO: '127903', CSTD_PLAN: '2024-07-31', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 5487,  A_ACT: 237,  A_REM: 5250,  A_PRO: 4.3,  B_TOT: 1697, B_ACT: 0,    B_REM: 1697, B_PRO: 0,    C_TOT: 30,  C_ACT: 0,   C_REM: 30,  C_PRO: 0,   AP_TOT: 14,  AP_ACT: 1,   AP_REM: 13,  AP_PRO: 7.1,  BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'CGC System Owner',              SUBSYS_NO: '127904', CSTD_PLAN: '2024-08-20', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 5088,  A_ACT: 91,   A_REM: 4997,  A_PRO: 1.8,  B_TOT: 1574, B_ACT: 0,    B_REM: 1574, B_PRO: 0,    C_TOT: 11,  C_ACT: 0,   C_REM: 11,  C_PRO: 0,   AP_TOT: 57,  AP_ACT: 6,   AP_REM: 51,  AP_PRO: 10.5, BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Co-Product System Owner',       SUBSYS_NO: '127700', CSTD_PLAN: '2024-08-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 5742,  A_ACT: 164,  A_REM: 5578,  A_PRO: 2.9,  B_TOT: 1639, B_ACT: 0,    B_REM: 1639, B_PRO: 0,    C_TOT: 29,  C_ACT: 0,   C_REM: 29,  C_PRO: 0,   AP_TOT: 20,  AP_ACT: 3,   AP_REM: 17,  AP_PRO: 15,   BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Co-Product System Owner',       SUBSYS_NO: '127742', CSTD_PLAN: '2024-08-20', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 129,   A_ACT: 4,    A_REM: 125,   A_PRO: 3.1,  B_TOT: 42,   B_ACT: 0,    B_REM: 42,   B_PRO: 0,    C_TOT: 5,   C_ACT: 0,   C_REM: 5,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Co-Product System Owner',       SUBSYS_NO: '111300', CSTD_PLAN: '2024-09-20', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 3149,  A_ACT: 157,  A_REM: 2992,  A_PRO: 5,    B_TOT: 924,  B_ACT: 0,    B_REM: 924,  B_PRO: 0,    C_TOT: 6,   C_ACT: 0,   C_REM: 6,   C_PRO: 0,   AP_TOT: 14,  AP_ACT: 0,   AP_REM: 14,  AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Co-Product System Owner',       SUBSYS_NO: '111342', CSTD_PLAN: '2024-09-10', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 41,    A_ACT: 2,    A_REM: 39,    A_PRO: 4.9,  B_TOT: 12,   B_ACT: 0,    B_REM: 12,   B_PRO: 0,    C_TOT: 2,   C_ACT: 0,   C_REM: 2,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Co-Product System Owner',       SUBSYS_NO: '127800', CSTD_PLAN: '2024-08-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 6899,  A_ACT: 147,  A_REM: 6752,  A_PRO: 2.1,  B_TOT: 1598, B_ACT: 0,    B_REM: 1598, B_PRO: 0,    C_TOT: 17,  C_ACT: 0,   C_REM: 17,  C_PRO: 0,   AP_TOT: 4,   AP_ACT: 4,   AP_REM: 0,   AP_PRO: 100,  BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Co-Product System Owner',       SUBSYS_NO: '136200', CSTD_PLAN: '2024-09-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 3116,  A_ACT: 176,  A_REM: 2940,  A_PRO: 5.6,  B_TOT: 850,  B_ACT: 0,    B_REM: 850,  B_PRO: 0,    C_TOT: 8,   C_ACT: 0,   C_REM: 8,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Cooling Water System Owner',    SUBSYS_NO: '180200', CSTD_PLAN: '2024-02-15', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 3435,  A_ACT: 136,  A_REM: 3299,  A_PRO: 4,    B_TOT: 1174, B_ACT: 0,    B_REM: 1174, B_PRO: 0,    C_TOT: 40,  C_ACT: 0,   C_REM: 40,  C_PRO: 0,   AP_TOT: 2,   AP_ACT: 0,   AP_REM: 2,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Cooling Water System Owner',    SUBSYS_NO: '280200', CSTD_PLAN: '2024-03-15', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 2172,  A_ACT: 94,   A_REM: 2078,  A_PRO: 4.3,  B_TOT: 727,  B_ACT: 0,    B_REM: 727,  B_PRO: 0,    C_TOT: 21,  C_ACT: 0,   C_REM: 21,  C_PRO: 0,   AP_TOT: 2,   AP_ACT: 0,   AP_REM: 2,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Finishing System Owner',        SUBSYS_NO: '121304', CSTD_PLAN: '2024-05-20', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 6579,  A_ACT: 57,   A_REM: 6522,  A_PRO: 0.9,  B_TOT: 2012, B_ACT: 0,    B_REM: 2012, B_PRO: 0,    C_TOT: 90,  C_ACT: 0,   C_REM: 90,  C_PRO: 0,   AP_TOT: 17,  AP_ACT: 2,   AP_REM: 15,  AP_PRO: 11.8, BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Finishing System Owner',        SUBSYS_NO: '121306', CSTD_PLAN: '2024-05-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 1211,  A_ACT: 61,   A_REM: 1150,  A_PRO: 5,    B_TOT: 446,  B_ACT: 0,    B_REM: 446,  B_PRO: 0,    C_TOT: 18,  C_ACT: 0,   C_REM: 18,  C_PRO: 0,   AP_TOT: 4,   AP_ACT: 0,   AP_REM: 4,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Finishing System Owner',        SUBSYS_NO: '121307', CSTD_PLAN: '2024-05-24', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 632,   A_ACT: 18,   A_REM: 614,   A_PRO: 2.8,  B_TOT: 112,  B_ACT: 0,    B_REM: 112,  B_PRO: 0,    C_TOT: 4,   C_ACT: 0,   C_REM: 4,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Finishing System Owner',        SUBSYS_NO: '121308', CSTD_PLAN: '2024-05-26', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 2326,  A_ACT: 0,    A_REM: 2326,  A_PRO: 0,    B_TOT: 645,  B_ACT: 0,    B_REM: 645,  B_PRO: 0,    C_TOT: 10,  C_ACT: 0,   C_REM: 10,  C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Finishing System Owner',        SUBSYS_NO: '121405', CSTD_PLAN: '2024-06-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 1768,  A_ACT: 21,   A_REM: 1747,  A_PRO: 1.2,  B_TOT: 526,  B_ACT: 0,    B_REM: 526,  B_PRO: 0,    C_TOT: 32,  C_ACT: 0,   C_REM: 32,  C_PRO: 0,   AP_TOT: 8,   AP_ACT: 0,   AP_REM: 8,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Finishing System Owner',        SUBSYS_NO: '121413', CSTD_PLAN: '2024-06-28', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 2696,  A_ACT: 57,   A_REM: 2639,  A_PRO: 2.1,  B_TOT: 639,  B_ACT: 0,    B_REM: 639,  B_PRO: 0,    C_TOT: 17,  C_ACT: 0,   C_REM: 17,  C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Finishing System Owner',        SUBSYS_NO: '121414', CSTD_PLAN: '2024-06-26', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 663,   A_ACT: 0,    A_REM: 663,   A_PRO: 0,    B_TOT: 134,  B_ACT: 0,    B_REM: 134,  B_PRO: 0,    C_TOT: 3,   C_ACT: 0,   C_REM: 3,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Finishing System Owner',        SUBSYS_NO: '121903', CSTD_PLAN: '2024-05-31', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 7335,  A_ACT: 18,   A_REM: 7317,  A_PRO: 0.2,  B_TOT: 1799, B_ACT: 0,    B_REM: 1799, B_PRO: 0,    C_TOT: 138, C_ACT: 0,   C_REM: 138, C_PRO: 0,   AP_TOT: 16,  AP_ACT: 0,   AP_REM: 16,  AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Finishing System Owner',        SUBSYS_NO: '121904', CSTD_PLAN: '2024-05-31', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 1812,  A_ACT: 12,   A_REM: 1800,  A_PRO: 0.7,  B_TOT: 666,  B_ACT: 0,    B_REM: 666,  B_PRO: 0,    C_TOT: 15,  C_ACT: 0,   C_REM: 15,  C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Finishing System Owner',        SUBSYS_NO: '121905', CSTD_PLAN: '2024-05-26', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 809,   A_ACT: 0,    A_REM: 809,   A_PRO: 0,    B_TOT: 332,  B_ACT: 0,    B_REM: 332,  B_PRO: 0,    C_TOT: 6,   C_ACT: 0,   C_REM: 6,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Flare System Owner',            SUBSYS_NO: '179600', CSTD_PLAN: '2024-08-09', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 140,   A_ACT: 0,    A_REM: 140,   A_PRO: 0,    B_TOT: 52,   B_ACT: 0,    B_REM: 52,   B_PRO: 0,    C_TOT: 6,   C_ACT: 0,   C_REM: 6,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Flare System Owner',            SUBSYS_NO: '178600', CSTD_PLAN: '2024-03-31', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 750,   A_ACT: 40,   A_REM: 710,   A_PRO: 5.3,  B_TOT: 255,  B_ACT: 5,    B_REM: 250,  B_PRO: 2,    C_TOT: 11,  C_ACT: 0,   C_REM: 11,  C_PRO: 0,   AP_TOT: 9,   AP_ACT: 1,   AP_REM: 8,   AP_PRO: 11.1, BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Flare System Owner',            SUBSYS_NO: '178900', CSTD_PLAN: '2024-04-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 4133,  A_ACT: 30,   A_REM: 4103,  A_PRO: 0.7,  B_TOT: 1153, B_ACT: 1,    B_REM: 1152, B_PRO: 0.1,  C_TOT: 48,  C_ACT: 0,   C_REM: 48,  C_PRO: 0,   AP_TOT: 9,   AP_ACT: 0,   AP_REM: 9,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Flare System Owner',            SUBSYS_NO: '178903', CSTD_PLAN: '2024-04-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 2139,  A_ACT: 0,    A_REM: 2139,  A_PRO: 0,    B_TOT: 481,  B_ACT: 0,    B_REM: 481,  B_PRO: 0,    C_TOT: 10,  C_ACT: 0,   C_REM: 10,  C_PRO: 0,   AP_TOT: 3,   AP_ACT: 0,   AP_REM: 3,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'GPPE System Owner',             SUBSYS_NO: '121342', CSTD_PLAN: '2024-05-04', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 82,    A_ACT: 6,    A_REM: 76,    A_PRO: 7.3,  B_TOT: 42,   B_ACT: 0,    B_REM: 42,   B_PRO: 0,    C_TOT: 4,   C_ACT: 0,   C_REM: 4,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'GPPE System Owner',             SUBSYS_NO: '121311', CSTD_PLAN: '2024-06-17', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 4305,  A_ACT: 30,   A_REM: 4275,  A_PRO: 0.7,  B_TOT: 973,  B_ACT: 1,    B_REM: 972,  B_PRO: 0.1,  C_TOT: 15,  C_ACT: 0,   C_REM: 15,  C_PRO: 0,   AP_TOT: 49,  AP_ACT: 1,   AP_REM: 48,  AP_PRO: 2,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'GPPE System Owner',             SUBSYS_NO: '121312', CSTD_PLAN: '2024-07-31', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 1806,  A_ACT: 27,   A_REM: 1779,  A_PRO: 1.5,  B_TOT: 381,  B_ACT: 0,    B_REM: 381,  B_PRO: 0,    C_TOT: 5,   C_ACT: 0,   C_REM: 5,   C_PRO: 0,   AP_TOT: 15,  AP_ACT: 0,   AP_REM: 15,  AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'GPPE System Owner',             SUBSYS_NO: '121313', CSTD_PLAN: '2024-05-22', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 375,   A_ACT: 16,   A_REM: 359,   A_PRO: 4.3,  B_TOT: 101,  B_ACT: 0,    B_REM: 101,  B_PRO: 0,    C_TOT: 2,   C_ACT: 0,   C_REM: 2,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'GPPE System Owner',             SUBSYS_NO: '121321', CSTD_PLAN: '2024-07-31', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 3865,  A_ACT: 19,   A_REM: 3846,  A_PRO: 0.5,  B_TOT: 883,  B_ACT: 1,    B_REM: 882,  B_PRO: 0.1,  C_TOT: 16,  C_ACT: 0,   C_REM: 16,  C_PRO: 0,   AP_TOT: 50,  AP_ACT: 1,   AP_REM: 49,  AP_PRO: 2,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'GPPE System Owner',             SUBSYS_NO: '121322', CSTD_PLAN: '2024-07-31', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 1579,  A_ACT: 28,   A_REM: 1551,  A_PRO: 1.8,  B_TOT: 314,  B_ACT: 0,    B_REM: 314,  B_PRO: 0,    C_TOT: 4,   C_ACT: 0,   C_REM: 4,   C_PRO: 0,   AP_TOT: 13,  AP_ACT: 0,   AP_REM: 13,  AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'GPPE System Owner',             SUBSYS_NO: '121323', CSTD_PLAN: '2024-06-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 398,   A_ACT: 14,   A_REM: 384,   A_PRO: 3.5,  B_TOT: 112,  B_ACT: 0,    B_REM: 112,  B_PRO: 0,    C_TOT: 2,   C_ACT: 0,   C_REM: 2,   C_PRO: 0,   AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100,  BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'GPPE System Owner',             SUBSYS_NO: '121305', CSTD_PLAN: '2024-06-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 138,   A_ACT: 10,   A_REM: 128,   A_PRO: 7.2,  B_TOT: 42,   B_ACT: 0,    B_REM: 42,   B_PRO: 0,    C_TOT: 2,   C_ACT: 0,   C_REM: 2,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'GPPE System Owner',             SUBSYS_NO: '121300', CSTD_PLAN: '2024-05-04', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 450,   A_ACT: 133,  A_REM: 317,   A_PRO: 29.6, B_TOT: 492,  B_ACT: 0,    B_REM: 492,  B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 4,   AP_ACT: 0,   AP_REM: 4,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'GPPE System Owner',             SUBSYS_NO: '121301', CSTD_PLAN: '2024-02-29', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 557,   A_ACT: 2,    A_REM: 555,   A_PRO: 0.4,  B_TOT: 123,  B_ACT: 0,    B_REM: 123,  B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 2,   AP_ACT: 0,   AP_REM: 2,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'GPPE System Owner',             SUBSYS_NO: '121302', CSTD_PLAN: '2024-05-15', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 1353,  A_ACT: 51,   A_REM: 1302,  A_PRO: 3.8,  B_TOT: 307,  B_ACT: 0,    B_REM: 307,  B_PRO: 0,    C_TOT: 1,   C_ACT: 0,   C_REM: 1,   C_PRO: 0,   AP_TOT: 5,   AP_ACT: 0,   AP_REM: 5,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'GPPE System Owner',             SUBSYS_NO: '121303', CSTD_PLAN: '2024-05-15', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 443,   A_ACT: 35,   A_REM: 408,   A_PRO: 7.9,  B_TOT: 124,  B_ACT: 0,    B_REM: 124,  B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 10,  AP_ACT: 0,   AP_REM: 10,  AP_PRO: 0,    BP_TOT: 1,    BP_ACT: 0,    BP_REM: 1,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'LDPE System Owner',             SUBSYS_NO: '121400', CSTD_PLAN: '2024-03-31', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 900,   A_ACT: 25,   A_REM: 875,   A_PRO: 2.8,  B_TOT: 291,  B_ACT: 0,    B_REM: 291,  B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 2,   AP_ACT: 0,   AP_REM: 2,   AP_PRO: 0,    BP_TOT: 7,    BP_ACT: 0,    BP_REM: 7,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'LDPE System Owner',             SUBSYS_NO: '121401', CSTD_PLAN: '2024-04-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 2990,  A_ACT: 146,  A_REM: 2844,  A_PRO: 4.9,  B_TOT: 888,  B_ACT: 2,    B_REM: 886,  B_PRO: 0.2,  C_TOT: 25,  C_ACT: 0,   C_REM: 25,  C_PRO: 0,   AP_TOT: 6,   AP_ACT: 0,   AP_REM: 6,   AP_PRO: 0,    BP_TOT: 4,    BP_ACT: 0,    BP_REM: 4,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'LDPE System Owner',             SUBSYS_NO: '121402', CSTD_PLAN: '2024-08-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 1039,  A_ACT: 0,    A_REM: 1039,  A_PRO: 0,    B_TOT: 106,  B_ACT: 0,    B_REM: 106,  B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 18,  AP_ACT: 0,   AP_REM: 18,  AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'LDPE System Owner',             SUBSYS_NO: '121403', CSTD_PLAN: '2024-06-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 596,   A_ACT: 35,   A_REM: 561,   A_PRO: 5.9,  B_TOT: 106,  B_ACT: 0,    B_REM: 106,  B_PRO: 0,    C_TOT: 1,   C_ACT: 0,   C_REM: 1,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 2,    BP_ACT: 0,    BP_REM: 2,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'LDPE System Owner',             SUBSYS_NO: '121404', CSTD_PLAN: '2024-04-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 458,   A_ACT: 20,   A_REM: 438,   A_PRO: 4.4,  B_TOT: 87,   B_ACT: 0,    B_REM: 87,   B_PRO: 0,    C_TOT: 1,   C_ACT: 0,   C_REM: 1,   C_PRO: 0,   AP_TOT: 2,   AP_ACT: 0,   AP_REM: 2,   AP_PRO: 0,    BP_TOT: 1,    BP_ACT: 0,    BP_REM: 1,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'LDPE System Owner',             SUBSYS_NO: '121406', CSTD_PLAN: '2024-04-20', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 347,   A_ACT: 0,    A_REM: 347,   A_PRO: 0,    B_TOT: 142,  B_ACT: 0,    B_REM: 142,  B_PRO: 0,    C_TOT: 10,  C_ACT: 0,   C_REM: 10,  C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'LDPE System Owner',             SUBSYS_NO: '121407', CSTD_PLAN: '2024-07-25', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 1269,  A_ACT: 49,   A_REM: 1220,  A_PRO: 3.9,  B_TOT: 383,  B_ACT: 0,    B_REM: 383,  B_PRO: 0,    C_TOT: 12,  C_ACT: 0,   C_REM: 12,  C_PRO: 0,   AP_TOT: 6,   AP_ACT: 0,   AP_REM: 6,   AP_PRO: 0,    BP_TOT: 23,   BP_ACT: 0,    BP_REM: 23,  BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'LDPE System Owner',             SUBSYS_NO: '121408', CSTD_PLAN: '2024-04-29', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 606,   A_ACT: 8,    A_REM: 598,   A_PRO: 1.3,  B_TOT: 207,  B_ACT: 0,    B_REM: 207,  B_PRO: 0,    C_TOT: 8,   C_ACT: 0,   C_REM: 8,   C_PRO: 0,   AP_TOT: 9,   AP_ACT: 0,   AP_REM: 9,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'LDPE System Owner',             SUBSYS_NO: '121409', CSTD_PLAN: '2024-07-20', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 246,   A_ACT: 12,   A_REM: 234,   A_PRO: 4.9,  B_TOT: 45,   B_ACT: 0,    B_REM: 45,   B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 1,    BP_ACT: 0,    BP_REM: 1,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'LDPE System Owner',             SUBSYS_NO: '121410', CSTD_PLAN: '2024-06-22', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 589,   A_ACT: 56,   A_REM: 533,   A_PRO: 9.5,  B_TOT: 153,  B_ACT: 0,    B_REM: 153,  B_PRO: 0,    C_TOT: 14,  C_ACT: 0,   C_REM: 14,  C_PRO: 0,   AP_TOT: 4,   AP_ACT: 0,   AP_REM: 4,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'LDPE System Owner',             SUBSYS_NO: '121411', CSTD_PLAN: '2024-06-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 870,   A_ACT: 36,   A_REM: 834,   A_PRO: 4.1,  B_TOT: 245,  B_ACT: 0,    B_REM: 245,  B_PRO: 0,    C_TOT: 16,  C_ACT: 0,   C_REM: 16,  C_PRO: 0,   AP_TOT: 3,   AP_ACT: 0,   AP_REM: 3,   AP_PRO: 0,    BP_TOT: 3,    BP_ACT: 0,    BP_REM: 3,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'LDPE System Owner',             SUBSYS_NO: '121412', CSTD_PLAN: '2024-07-28', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 581,   A_ACT: 2,    A_REM: 579,   A_PRO: 0.3,  B_TOT: 141,  B_ACT: 0,    B_REM: 141,  B_PRO: 0,    C_TOT: 3,   C_ACT: 0,   C_REM: 3,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'LDPE System Owner',             SUBSYS_NO: '121442', CSTD_PLAN: '2024-04-09', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 128,   A_ACT: 9,    A_REM: 119,   A_PRO: 7,    B_TOT: 54,   B_ACT: 0,    B_REM: 54,   B_PRO: 0,    C_TOT: 4,   C_ACT: 0,   C_REM: 4,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P1 Building System Owner',      SUBSYS_NO: '127791', CSTD_PLAN: '2024-06-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 909,   A_ACT: 0,    A_REM: 909,   A_PRO: 0,    B_TOT: 251,  B_ACT: 0,    B_REM: 251,  B_PRO: 0,    C_TOT: 1,   C_ACT: 0,   C_REM: 1,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P1 Building System Owner',      SUBSYS_NO: '127792', CSTD_PLAN: '2024-06-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 678,   A_ACT: 136,  A_REM: 542,   A_PRO: 20.1, B_TOT: 1550, B_ACT: 0,    B_REM: 1550, B_PRO: 0,    C_TOT: 9,   C_ACT: 0,   C_REM: 9,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P1 Building System Owner',      SUBSYS_NO: '111394', CSTD_PLAN: '2024-09-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 309,   A_ACT: 7,    A_REM: 302,   A_PRO: 2.3,  B_TOT: 105,  B_ACT: 0,    B_REM: 105,  B_PRO: 0,    C_TOT: 1,   C_ACT: 0,   C_REM: 1,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P1 Building System Owner',      SUBSYS_NO: '184891', CSTD_PLAN: '2023-10-30', CSTD_WALKDOWN: '2023-10-30', CSTD_ACTUAL: '2023-10-30', A_TOT: 922,   A_ACT: 789,  A_REM: 133,   A_PRO: 85.6, B_TOT: 357,  B_ACT: 120,  B_REM: 237,  B_PRO: 33.6, C_TOT: 1,   C_ACT: 0,   C_REM: 1,   C_PRO: 0,   AP_TOT: 123, AP_ACT: 111, AP_REM: 12,  AP_PRO: 90.2, BP_TOT: 114,  BP_ACT: 63,   BP_REM: 51,  BP_PRO: 55.3, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P1 Building System Owner',      SUBSYS_NO: '184892', CSTD_PLAN: '2023-10-30', CSTD_WALKDOWN: '2023-10-30', CSTD_ACTUAL: '2023-10-30', A_TOT: 706,   A_ACT: 583,  A_REM: 123,   A_PRO: 82.6, B_TOT: 605,  B_ACT: 484,  B_REM: 121,  B_PRO: 80,   C_TOT: 5,   C_ACT: 0,   C_REM: 5,   C_PRO: 0,   AP_TOT: 80,  AP_ACT: 80,  AP_REM: 0,   AP_PRO: 100,  BP_TOT: 73,   BP_ACT: 60,   BP_REM: 13,  BP_PRO: 82.2, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P1 Building System Owner',      SUBSYS_NO: '127982', CSTD_PLAN: '2024-06-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 766,   A_ACT: 6,    A_REM: 760,   A_PRO: 0.8,  B_TOT: 1068, B_ACT: 0,    B_REM: 1068, B_PRO: 0,    C_TOT: 7,   C_ACT: 0,   C_REM: 7,   C_PRO: 0,   AP_TOT: 6,   AP_ACT: 6,   AP_REM: 0,   AP_PRO: 100,  BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P1 Building System Owner',      SUBSYS_NO: '127991', CSTD_PLAN: '2024-06-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 1835,  A_ACT: 6,    A_REM: 1829,  A_PRO: 0.3,  B_TOT: 606,  B_ACT: 0,    B_REM: 606,  B_PRO: 0,    C_TOT: 1,   C_ACT: 0,   C_REM: 1,   C_PRO: 0,   AP_TOT: 56,  AP_ACT: 0,   AP_REM: 56,  AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P1 Building System Owner',      SUBSYS_NO: '127992', CSTD_PLAN: '2024-05-17', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 859,   A_ACT: 8,    A_REM: 851,   A_PRO: 0.9,  B_TOT: 1536, B_ACT: 0,    B_REM: 1536, B_PRO: 0,    C_TOT: 9,   C_ACT: 0,   C_REM: 9,   C_PRO: 0,   AP_TOT: 3,   AP_ACT: 3,   AP_REM: 0,   AP_PRO: 100,  BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P1 Building System Owner',      SUBSYS_NO: '170091', CSTD_PLAN: '2024-01-29', CSTD_WALKDOWN: '2024-01-29', CSTD_ACTUAL: '2024-01-29', A_TOT: 1025,  A_ACT: 983,  A_REM: 42,    A_PRO: 95.9, B_TOT: 336,  B_ACT: 0,    B_REM: 336,  B_PRO: 0,    C_TOT: 1,   C_ACT: 0,   C_REM: 1,   C_PRO: 0,   AP_TOT: 86,  AP_ACT: 84,  AP_REM: 2,   AP_PRO: 97.7, BP_TOT: 55,   BP_ACT: 38,   BP_REM: 17,  BP_PRO: 69.1, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P1 Building System Owner',      SUBSYS_NO: '170092', CSTD_PLAN: '2024-01-31', CSTD_WALKDOWN: '2023-12-30', CSTD_ACTUAL: '2023-12-30', A_TOT: 732,   A_ACT: 686,  A_REM: 46,    A_PRO: 93.7, B_TOT: 884,  B_ACT: 626,  B_REM: 258,  B_PRO: 70.8, C_TOT: 5,   C_ACT: 0,   C_REM: 5,   C_PRO: 0,   AP_TOT: 91,  AP_ACT: 88,  AP_REM: 3,   AP_PRO: 96.7, BP_TOT: 98,   BP_ACT: 83,   BP_REM: 15,  BP_PRO: 84.7, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P1 Building System Owner',      SUBSYS_NO: '277082', CSTD_PLAN: '2024-03-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 413,   A_ACT: 38,   A_REM: 375,   A_PRO: 9.2,  B_TOT: 605,  B_ACT: 0,    B_REM: 605,  B_PRO: 0,    C_TOT: 5,   C_ACT: 0,   C_REM: 5,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P2 Building System Owner',      SUBSYS_NO: '186000', CSTD_PLAN: '2023-12-05', CSTD_WALKDOWN: '2023-11-27', CSTD_ACTUAL: '2023-12-27', A_TOT: 6275,  A_ACT: 5758, A_REM: 517,   A_PRO: 91.8, B_TOT: 2949, B_ACT: 1188, B_REM: 1761, B_PRO: 40.3, C_TOT: 5,   C_ACT: 0,   C_REM: 5,   C_PRO: 0,   AP_TOT: 731, AP_ACT: 577, AP_REM: 154, AP_PRO: 78.9, BP_TOT: 369,  BP_ACT: 270,  BP_REM: 99,  BP_PRO: 73.2, CP_TOT: 30, CP_ACT: 30, CP_REM: 0, CP_PRO: 100, },
      { UNIT: 'P2 Building System Owner',      SUBSYS_NO: '121332', CSTD_PLAN: '2024-05-18', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 128,   A_ACT: 9,    A_REM: 119,   A_PRO: 7,    B_TOT: 102,  B_ACT: 0,    B_REM: 102,  B_PRO: 0,    C_TOT: 1,   C_ACT: 0,   C_REM: 1,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P2 Building System Owner',      SUBSYS_NO: '121391', CSTD_PLAN: '2024-02-16', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 879,   A_ACT: 252,  A_REM: 627,   A_PRO: 28.7, B_TOT: 369,  B_ACT: 0,    B_REM: 369,  B_PRO: 0,    C_TOT: 1,   C_ACT: 0,   C_REM: 1,   C_PRO: 0,   AP_TOT: 35,  AP_ACT: 14,  AP_REM: 21,  AP_PRO: 40,   BP_TOT: 23,   BP_ACT: 13,   BP_REM: 10,  BP_PRO: 56.5, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P2 Building System Owner',      SUBSYS_NO: '121392', CSTD_PLAN: '2024-01-31', CSTD_WALKDOWN: '2024-01-29', CSTD_ACTUAL: '2024-01-29', A_TOT: 2378,  A_ACT: 2242, A_REM: 136,   A_PRO: 94.3, B_TOT: 2354, B_ACT: 0,    B_REM: 2354, B_PRO: 0,    C_TOT: 18,  C_ACT: 0,   C_REM: 18,  C_PRO: 0,   AP_TOT: 298, AP_ACT: 261, AP_REM: 37,  AP_PRO: 87.6, BP_TOT: 150,  BP_ACT: 41,   BP_REM: 109, BP_PRO: 27.3, CP_TOT: 1,  CP_ACT: 0,  CP_REM: 1, CP_PRO: 0, },
      { UNIT: 'P2 Building System Owner',      SUBSYS_NO: '121415', CSTD_PLAN: '2024-07-31', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 42,    A_ACT: 0,    A_REM: 42,    A_PRO: 0,    B_TOT: 28,   B_ACT: 0,    B_REM: 28,   B_PRO: 0,    C_TOT: 1,   C_ACT: 0,   C_REM: 1,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P2 Building System Owner',      SUBSYS_NO: '121491', CSTD_PLAN: '2023-12-31', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 1313,  A_ACT: 27,   A_REM: 1286,  A_PRO: 2.1,  B_TOT: 588,  B_ACT: 0,    B_REM: 588,  B_PRO: 0,    C_TOT: 1,   C_ACT: 0,   C_REM: 1,   C_PRO: 0,   AP_TOT: 16,  AP_ACT: 15,  AP_REM: 1,   AP_PRO: 93.8, BP_TOT: 34,   BP_ACT: 30,   BP_REM: 4,   BP_PRO: 88.2, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P2 Building System Owner',      SUBSYS_NO: '121492', CSTD_PLAN: '2023-12-30', CSTD_WALKDOWN: '2023-12-31', CSTD_ACTUAL: '2023-12-31', A_TOT: 1784,  A_ACT: 1683, A_REM: 101,   A_PRO: 94.3, B_TOT: 1597, B_ACT: 806,  B_REM: 791,  B_PRO: 50.5, C_TOT: 11,  C_ACT: 0,   C_REM: 11,  C_PRO: 0,   AP_TOT: 162, AP_ACT: 141, AP_REM: 21,  AP_PRO: 87,   BP_TOT: 191,  BP_ACT: 111,  BP_REM: 80,  BP_PRO: 58.1, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P2 Building System Owner',      SUBSYS_NO: '121931', CSTD_PLAN: '2024-07-01', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 34,    A_ACT: 2,    A_REM: 32,    A_PRO: 5.9,  B_TOT: 7,    B_ACT: 0,    B_REM: 7,    B_PRO: 0,    C_TOT: 1,   C_ACT: 0,   C_REM: 1,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P2 Building System Owner',      SUBSYS_NO: '121932', CSTD_PLAN: '2024-07-01', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 145,   A_ACT: 10,   A_REM: 135,   A_PRO: 6.9,  B_TOT: 94,   B_ACT: 0,    B_REM: 94,   B_PRO: 0,    C_TOT: 1,   C_ACT: 0,   C_REM: 1,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P2 Building System Owner',      SUBSYS_NO: '121991', CSTD_PLAN: '2024-02-16', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 924,   A_ACT: 114,  A_REM: 810,   A_PRO: 12.3, B_TOT: 324,  B_ACT: 0,    B_REM: 324,  B_PRO: 0,    C_TOT: 1,   C_ACT: 0,   C_REM: 1,   C_PRO: 0,   AP_TOT: 13,  AP_ACT: 2,   AP_REM: 11,  AP_PRO: 15.4, BP_TOT: 9,    BP_ACT: 4,    BP_REM: 5,   BP_PRO: 44.4, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P2 Building System Owner',      SUBSYS_NO: '121992', CSTD_PLAN: '2024-01-31', CSTD_WALKDOWN: '2024-01-29', CSTD_ACTUAL: '2024-01-29', A_TOT: 2673,  A_ACT: 2548, A_REM: 125,   A_PRO: 95.3, B_TOT: 2547, B_ACT: 0,    B_REM: 2547, B_PRO: 0,    C_TOT: 16,  C_ACT: 0,   C_REM: 16,  C_PRO: 0,   AP_TOT: 247, AP_ACT: 172, AP_REM: 75,  AP_PRO: 69.6, BP_TOT: 112,  BP_ACT: 58,   BP_REM: 54,  BP_PRO: 51.8, CP_TOT: 2,  CP_ACT: 1,  CP_REM: 1, CP_PRO: 50, },
      { UNIT: 'P3 Building System Owner',      SUBSYS_NO: '154000', CSTD_PLAN: '2024-01-31', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 6285,  A_ACT: 5187, A_REM: 1098,  A_PRO: 82.5, B_TOT: 3475, B_ACT: 0,    B_REM: 3475, B_PRO: 0,    C_TOT: 14,  C_ACT: 0,   C_REM: 14,  C_PRO: 0,   AP_TOT: 544, AP_ACT: 263, AP_REM: 281, AP_PRO: 48.3, BP_TOT: 425,  BP_ACT: 198,  BP_REM: 227, BP_PRO: 46.6, CP_TOT: 4,  CP_ACT: 4,  CP_REM: 0, CP_PRO: 100, },
      { UNIT: 'P3 Building System Owner',      SUBSYS_NO: '154001', CSTD_PLAN: '2024-01-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 2,     A_ACT: 0,    A_REM: 2,     A_PRO: 0,    B_TOT: 0,    B_ACT: 0,    B_REM: 0,    B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P3 Building System Owner',      SUBSYS_NO: '150400', CSTD_PLAN: '2024-03-20', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 611,   A_ACT: 0,    A_REM: 611,   A_PRO: 0,    B_TOT: 265,  B_ACT: 0,    B_REM: 265,  B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P3 Building System Owner',      SUBSYS_NO: '150401', CSTD_PLAN: '2024-03-20', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 21,    A_ACT: 0,    A_REM: 21,    A_PRO: 0,    B_TOT: 1,    B_ACT: 0,    B_REM: 1,    B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P3 Building System Owner',      SUBSYS_NO: '150402', CSTD_PLAN: '2024-03-20', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 20,    A_ACT: 0,    A_REM: 20,    A_PRO: 0,    B_TOT: 4,    B_ACT: 0,    B_REM: 4,    B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P3 Building System Owner',      SUBSYS_NO: '150403', CSTD_PLAN: '2024-03-20', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 20,    A_ACT: 0,    A_REM: 20,    A_PRO: 0,    B_TOT: 4,    B_ACT: 0,    B_REM: 4,    B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P3 Building System Owner',      SUBSYS_NO: '150404', CSTD_PLAN: '2024-03-20', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 20,    A_ACT: 0,    A_REM: 20,    A_PRO: 0,    B_TOT: 4,    B_ACT: 0,    B_REM: 4,    B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P3 Building System Owner',      SUBSYS_NO: '150405', CSTD_PLAN: '2024-03-20', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 24,    A_ACT: 0,    A_REM: 24,    A_PRO: 0,    B_TOT: 1,    B_ACT: 0,    B_REM: 1,    B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P3 Building System Owner',      SUBSYS_NO: '150406', CSTD_PLAN: '2024-03-20', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 24,    A_ACT: 0,    A_REM: 24,    A_PRO: 0,    B_TOT: 1,    B_ACT: 0,    B_REM: 1,    B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P3 Building System Owner',      SUBSYS_NO: '151000', CSTD_PLAN: '2024-06-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 109,   A_ACT: 6,    A_REM: 103,   A_PRO: 5.5,  B_TOT: 33,   B_ACT: 0,    B_REM: 33,   B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P3 Building System Owner',      SUBSYS_NO: '152000', CSTD_PLAN: '2024-02-29', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 4391,  A_ACT: 707,  A_REM: 3684,  A_PRO: 16.1, B_TOT: 2077, B_ACT: 0,    B_REM: 2077, B_PRO: 0,    C_TOT: 5,   C_ACT: 0,   C_REM: 5,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P3 Building System Owner',      SUBSYS_NO: '152004', CSTD_PLAN: '2024-02-29', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 24,    A_ACT: 6,    A_REM: 18,    A_PRO: 25,   B_TOT: 9,    B_ACT: 0,    B_REM: 9,    B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P3 Building System Owner',      SUBSYS_NO: '152014', CSTD_PLAN: '2024-02-29', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 26,    A_ACT: 6,    A_REM: 20,    A_PRO: 23.1, B_TOT: 10,   B_ACT: 0,    B_REM: 10,   B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P3 Building System Owner',      SUBSYS_NO: '152200', CSTD_PLAN: '2024-02-20', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 1499,  A_ACT: 13,   A_REM: 1486,  A_PRO: 0.9,  B_TOT: 745,  B_ACT: 0,    B_REM: 745,  B_PRO: 0,    C_TOT: 4,   C_ACT: 0,   C_REM: 4,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P3 Building System Owner',      SUBSYS_NO: '152300', CSTD_PLAN: '2024-01-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 332,   A_ACT: 289,  A_REM: 43,    A_PRO: 87,   B_TOT: 103,  B_ACT: 0,    B_REM: 103,  B_PRO: 0,    C_TOT: 1,   C_ACT: 0,   C_REM: 1,   C_PRO: 0,   AP_TOT: 113, AP_ACT: 103, AP_REM: 10,  AP_PRO: 91.2, BP_TOT: 20,   BP_ACT: 5,    BP_REM: 15,  BP_PRO: 25,   CP_TOT: 1,  CP_ACT: 0,  CP_REM: 1, CP_PRO: 0, },
      { UNIT: 'P3 Building System Owner',      SUBSYS_NO: '152400', CSTD_PLAN: '2024-02-28', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 1335,  A_ACT: 67,   A_REM: 1268,  A_PRO: 5,    B_TOT: 709,  B_ACT: 1,    B_REM: 708,  B_PRO: 0.1,  C_TOT: 7,   C_ACT: 0,   C_REM: 7,   C_PRO: 0,   AP_TOT: 2,   AP_ACT: 0,   AP_REM: 2,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P3 Building System Owner',      SUBSYS_NO: '152700', CSTD_PLAN: '2024-03-31', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 6,     A_ACT: 0,    A_REM: 6,     A_PRO: 0,    B_TOT: 0,    B_ACT: 0,    B_REM: 0,    B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P3 Building System Owner',      SUBSYS_NO: '181200', CSTD_PLAN: '2023-12-05', CSTD_WALKDOWN: '2023-11-27', CSTD_ACTUAL: '2023-12-13', A_TOT: 1221,  A_ACT: 1108, A_REM: 113,   A_PRO: 90.7, B_TOT: 756,  B_ACT: 556,  B_REM: 200,  B_PRO: 73.5, C_TOT: 9,   C_ACT: 0,   C_REM: 9,   C_PRO: 0,   AP_TOT: 117, AP_ACT: 92,  AP_REM: 25,  AP_PRO: 78.6, BP_TOT: 210,  BP_ACT: 93,   BP_REM: 117, BP_PRO: 44.3, CP_TOT: 15, CP_ACT: 8,  CP_REM: 7, CP_PRO: 53.3, },
      { UNIT: 'P3 Building System Owner',      SUBSYS_NO: '182200', CSTD_PLAN: '2024-01-20', CSTD_WALKDOWN: '2024-01-22', CSTD_ACTUAL: '2024-01-22', A_TOT: 200,   A_ACT: 197,  A_REM: 3,     A_PRO: 98.5, B_TOT: 58,   B_ACT: 0,    B_REM: 58,   B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 52,  AP_ACT: 52,  AP_REM: 0,   AP_PRO: 100,  BP_TOT: 14,   BP_ACT: 0,    BP_REM: 14,  BP_PRO: 0,    CP_TOT: 2,  CP_ACT: 0,  CP_REM: 2, CP_PRO: 0, },
      { UNIT: 'P3 Building System Owner',      SUBSYS_NO: '182492', CSTD_PLAN: '2023-12-30', CSTD_WALKDOWN: '2024-01-19', CSTD_ACTUAL: '2024-01-19', A_TOT: 508,   A_ACT: 457,  A_REM: 51,    A_PRO: 90,   B_TOT: 595,  B_ACT: 13,   B_REM: 582,  B_PRO: 2.2,  C_TOT: 5,   C_ACT: 0,   C_REM: 5,   C_PRO: 0,   AP_TOT: 81,  AP_ACT: 75,  AP_REM: 6,   AP_PRO: 92.6, BP_TOT: 92,   BP_ACT: 58,   BP_REM: 34,  BP_PRO: 63,   CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P3 Building System Owner',      SUBSYS_NO: '183000', CSTD_PLAN: '2023-09-05', CSTD_WALKDOWN: '2023-09-05', CSTD_ACTUAL: '2023-09-05', A_TOT: 4740,  A_ACT: 4733, A_REM: 7,     A_PRO: 99.9, B_TOT: 2172, B_ACT: 2095, B_REM: 77,   B_PRO: 96.5, C_TOT: 102, C_ACT: 101, C_REM: 1,   C_PRO: 99,  AP_TOT: 328, AP_ACT: 328, AP_REM: 0,   AP_PRO: 100,  BP_TOT: 2413, BP_ACT: 2404, BP_REM: 9,   BP_PRO: 99.6, CP_TOT: 17, CP_ACT: 10, CP_REM: 7, CP_PRO: 58.8, },
      { UNIT: 'P3 Building System Owner',      SUBSYS_NO: '183100', CSTD_PLAN: '2023-12-15', CSTD_WALKDOWN: '2023-11-25', CSTD_ACTUAL: '2023-12-15', A_TOT: 632,   A_ACT: 580,  A_REM: 52,    A_PRO: 91.8, B_TOT: 317,  B_ACT: 233,  B_REM: 84,   B_PRO: 73.5, C_TOT: 5,   C_ACT: 0,   C_REM: 5,   C_PRO: 0,   AP_TOT: 71,  AP_ACT: 71,  AP_REM: 0,   AP_PRO: 100,  BP_TOT: 77,   BP_ACT: 63,   BP_REM: 14,  BP_PRO: 81.8, CP_TOT: 3,  CP_ACT: 2,  CP_REM: 1, CP_PRO: 66.7, },
      { UNIT: 'P3 Building System Owner',      SUBSYS_NO: '178992', CSTD_PLAN: '2024-02-28', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 455,   A_ACT: 400,  A_REM: 55,    A_PRO: 87.9, B_TOT: 580,  B_ACT: 0,    B_REM: 580,  B_PRO: 0,    C_TOT: 5,   C_ACT: 0,   C_REM: 5,   C_PRO: 0,   AP_TOT: 4,   AP_ACT: 0,   AP_REM: 4,   AP_PRO: 0,    BP_TOT: 32,   BP_ACT: 0,    BP_REM: 32,  BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P3 Building System Owner',      SUBSYS_NO: '150200', CSTD_PLAN: '2024-03-20', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 124,   A_ACT: 0,    A_REM: 124,   A_PRO: 0,    B_TOT: 14,   B_ACT: 0,    B_REM: 14,   B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P3 Building System Owner',      SUBSYS_NO: '178000', CSTD_PLAN: '2024-03-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 266,   A_ACT: 3,    A_REM: 263,   A_PRO: 1.1,  B_TOT: 103,  B_ACT: 0,    B_REM: 103,  B_PRO: 0,    C_TOT: 3,   C_ACT: 0,   C_REM: 3,   C_PRO: 0,   AP_TOT: 3,   AP_ACT: 0,   AP_REM: 3,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P3 Building System Owner',      SUBSYS_NO: '178001', CSTD_PLAN: '2024-03-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 245,   A_ACT: 0,    A_REM: 245,   A_PRO: 0,    B_TOT: 70,   B_ACT: 0,    B_REM: 70,   B_PRO: 0,    C_TOT: 1,   C_ACT: 0,   C_REM: 1,   C_PRO: 0,   AP_TOT: 1,   AP_ACT: 0,   AP_REM: 1,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P3 Building System Owner',      SUBSYS_NO: '180600', CSTD_PLAN: '2024-02-28', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 536,   A_ACT: 86,   A_REM: 450,   A_PRO: 16,   B_TOT: 273,  B_ACT: 0,    B_REM: 273,  B_PRO: 0,    C_TOT: 26,  C_ACT: 0,   C_REM: 26,  C_PRO: 0,   AP_TOT: 2,   AP_ACT: 0,   AP_REM: 2,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P3 Building System Owner',      SUBSYS_NO: '180292', CSTD_PLAN: '2023-12-30', CSTD_WALKDOWN: '2024-01-17', CSTD_ACTUAL: '2024-01-17', A_TOT: 641,   A_ACT: 634,  A_REM: 7,     A_PRO: 98.9, B_TOT: 866,  B_ACT: 442,  B_REM: 424,  B_PRO: 51,   C_TOT: 7,   C_ACT: 0,   C_REM: 7,   C_PRO: 0,   AP_TOT: 89,  AP_ACT: 83,  AP_REM: 6,   AP_PRO: 93.3, BP_TOT: 93,   BP_ACT: 63,   BP_REM: 30,  BP_PRO: 67.7, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P3 Building System Owner',      SUBSYS_NO: '185392', CSTD_PLAN: '2023-12-30', CSTD_WALKDOWN: '2024-01-03', CSTD_ACTUAL: '2024-01-03', A_TOT: 514,   A_ACT: 508,  A_REM: 6,     A_PRO: 98.8, B_TOT: 523,  B_ACT: 382,  B_REM: 141,  B_PRO: 73,   C_TOT: 5,   C_ACT: 0,   C_REM: 5,   C_PRO: 0,   AP_TOT: 81,  AP_ACT: 77,  AP_REM: 4,   AP_PRO: 95.1, BP_TOT: 78,   BP_ACT: 46,   BP_REM: 32,  BP_PRO: 59,   CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P3 Building System Owner',      SUBSYS_NO: '185394', CSTD_PLAN: '2024-01-15', CSTD_WALKDOWN: '2024-01-30', CSTD_ACTUAL: '2024-01-30', A_TOT: 533,   A_ACT: 515,  A_REM: 18,    A_PRO: 96.6, B_TOT: 189,  B_ACT: 2,    B_REM: 187,  B_PRO: 1.1,  C_TOT: 1,   C_ACT: 0,   C_REM: 1,   C_PRO: 0,   AP_TOT: 68,  AP_ACT: 60,  AP_REM: 8,   AP_PRO: 88.2, BP_TOT: 65,   BP_ACT: 27,   BP_REM: 38,  BP_PRO: 41.5, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P3 Building System Owner',      SUBSYS_NO: '252701', CSTD_PLAN: '2024-03-31', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 5,     A_ACT: 0,    A_REM: 5,     A_PRO: 0,    B_TOT: 2,    B_ACT: 0,    B_REM: 2,    B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P3 Building System Owner',      SUBSYS_NO: '252702', CSTD_PLAN: '2024-03-31', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 5,     A_ACT: 0,    A_REM: 5,     A_PRO: 0,    B_TOT: 0,    B_ACT: 0,    B_REM: 0,    B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P3 Building System Owner',      SUBSYS_NO: '252703', CSTD_PLAN: '2024-03-30', CSTD_WALKDOWN: '2024-01-29', CSTD_ACTUAL: '2024-01-29', A_TOT: 9,     A_ACT: 9,    A_REM: 0,     A_PRO: 100,  B_TOT: 2,    B_ACT: 0,    B_REM: 2,    B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 7,   AP_ACT: 7,   AP_REM: 0,   AP_PRO: 100,  BP_TOT: 8,    BP_ACT: 6,    BP_REM: 2,   BP_PRO: 75,   CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P3 Building System Owner',      SUBSYS_NO: '280292', CSTD_PLAN: '2023-12-30', CSTD_WALKDOWN: '2024-01-07', CSTD_ACTUAL: '2024-01-07', A_TOT: 460,   A_ACT: 454,  A_REM: 6,     A_PRO: 98.7, B_TOT: 541,  B_ACT: 29,   B_REM: 512,  B_PRO: 5.4,  C_TOT: 5,   C_ACT: 0,   C_REM: 5,   C_PRO: 0,   AP_TOT: 72,  AP_ACT: 70,  AP_REM: 2,   AP_PRO: 97.2, BP_TOT: 98,   BP_ACT: 63,   BP_REM: 35,  BP_PRO: 64.3, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'P3 Building System Owner',      SUBSYS_NO: '280294', CSTD_PLAN: '2024-02-29', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 574,   A_ACT: 535,  A_REM: 39,    A_PRO: 93.2, B_TOT: 241,  B_ACT: 0,    B_REM: 241,  B_PRO: 0,    C_TOT: 1,   C_ACT: 0,   C_REM: 1,   C_PRO: 0,   AP_TOT: 49,  AP_ACT: 21,  AP_REM: 28,  AP_PRO: 42.9, BP_TOT: 86,   BP_ACT: 30,   BP_REM: 56,  BP_PRO: 34.9, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'PBL / Utility System Owner',    SUBSYS_NO: '152800', CSTD_PLAN: '2024-03-31', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 40,    A_ACT: 0,    A_REM: 40,    A_PRO: 0,    B_TOT: 21,   B_ACT: 0,    B_REM: 21,   B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'PBL / Utility System Owner',    SUBSYS_NO: '152801', CSTD_PLAN: '2024-07-31', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 6383,  A_ACT: 22,   A_REM: 6361,  A_PRO: 0.3,  B_TOT: 1799, B_ACT: 0,    B_REM: 1799, B_PRO: 0,    C_TOT: 9,   C_ACT: 0,   C_REM: 9,   C_PRO: 0,   AP_TOT: 20,  AP_ACT: 11,  AP_REM: 9,   AP_PRO: 55,   BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'PBL / Utility System Owner',    SUBSYS_NO: '152802', CSTD_PLAN: '2024-07-31', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 6468,  A_ACT: 149,  A_REM: 6319,  A_PRO: 2.3,  B_TOT: 1392, B_ACT: 0,    B_REM: 1392, B_PRO: 0,    C_TOT: 9,   C_ACT: 0,   C_REM: 9,   C_PRO: 0,   AP_TOT: 16,  AP_ACT: 5,   AP_REM: 11,  AP_PRO: 31.3, BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'PBL / Utility System Owner',    SUBSYS_NO: '152803', CSTD_PLAN: '2024-05-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 2839,  A_ACT: 2,    A_REM: 2837,  A_PRO: 0.1,  B_TOT: 408,  B_ACT: 0,    B_REM: 408,  B_PRO: 0,    C_TOT: 1,   C_ACT: 0,   C_REM: 1,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'PBL / Utility System Owner',    SUBSYS_NO: '152804', CSTD_PLAN: '2024-04-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 2927,  A_ACT: 6,    A_REM: 2921,  A_PRO: 0.2,  B_TOT: 620,  B_ACT: 0,    B_REM: 620,  B_PRO: 0,    C_TOT: 1,   C_ACT: 0,   C_REM: 1,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'PBL / Utility System Owner',    SUBSYS_NO: '152805', CSTD_PLAN: '2024-04-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 694,   A_ACT: 3,    A_REM: 691,   A_PRO: 0.4,  B_TOT: 441,  B_ACT: 0,    B_REM: 441,  B_PRO: 0,    C_TOT: 9,   C_ACT: 0,   C_REM: 9,   C_PRO: 0,   AP_TOT: 6,   AP_ACT: 0,   AP_REM: 6,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'PP System Owner',               SUBSYS_NO: '121942', CSTD_PLAN: '2024-05-04', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 81,    A_ACT: 0,    A_REM: 81,    A_PRO: 0,    B_TOT: 39,   B_ACT: 0,    B_REM: 39,   B_PRO: 0,    C_TOT: 4,   C_ACT: 0,   C_REM: 4,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'PP System Owner',               SUBSYS_NO: '121900', CSTD_PLAN: '2024-07-01', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 981,   A_ACT: 4,    A_REM: 977,   A_PRO: 0.4,  B_TOT: 901,  B_ACT: 0,    B_REM: 901,  B_PRO: 0,    C_TOT: 2,   C_ACT: 0,   C_REM: 2,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'PP System Owner',               SUBSYS_NO: '121901', CSTD_PLAN: '2024-02-29', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 229,   A_ACT: 0,    A_REM: 229,   A_PRO: 0,    B_TOT: 46,   B_ACT: 0,    B_REM: 46,   B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'PP System Owner',               SUBSYS_NO: '121902', CSTD_PLAN: '2024-06-15', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 411,   A_ACT: 5,    A_REM: 406,   A_PRO: 1.2,  B_TOT: 91,   B_ACT: 0,    B_REM: 91,   B_PRO: 0,    C_TOT: 9,   C_ACT: 0,   C_REM: 9,   C_PRO: 0,   AP_TOT: 12,  AP_ACT: 0,   AP_REM: 12,  AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'PP System Owner',               SUBSYS_NO: '121906', CSTD_PLAN: '2024-06-27', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 362,   A_ACT: 1,    A_REM: 361,   A_PRO: 0.3,  B_TOT: 80,   B_ACT: 0,    B_REM: 80,   B_PRO: 0,    C_TOT: 4,   C_ACT: 0,   C_REM: 4,   C_PRO: 0,   AP_TOT: 2,   AP_ACT: 0,   AP_REM: 2,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'PP System Owner',               SUBSYS_NO: '121907', CSTD_PLAN: '2024-06-21', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 827,   A_ACT: 10,   A_REM: 817,   A_PRO: 1.2,  B_TOT: 258,  B_ACT: 0,    B_REM: 258,  B_PRO: 0,    C_TOT: 19,  C_ACT: 0,   C_REM: 19,  C_PRO: 0,   AP_TOT: 22,  AP_ACT: 0,   AP_REM: 22,  AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'PP System Owner',               SUBSYS_NO: '121908', CSTD_PLAN: '2024-06-25', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 724,   A_ACT: 8,    A_REM: 716,   A_PRO: 1.1,  B_TOT: 238,  B_ACT: 0,    B_REM: 238,  B_PRO: 0,    C_TOT: 6,   C_ACT: 0,   C_REM: 6,   C_PRO: 0,   AP_TOT: 21,  AP_ACT: 0,   AP_REM: 21,  AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'PP System Owner',               SUBSYS_NO: '121909', CSTD_PLAN: '2024-05-16', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 410,   A_ACT: 2,    A_REM: 408,   A_PRO: 0.5,  B_TOT: 139,  B_ACT: 0,    B_REM: 139,  B_PRO: 0,    C_TOT: 5,   C_ACT: 0,   C_REM: 5,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'PP System Owner',               SUBSYS_NO: '121911', CSTD_PLAN: '2024-06-06', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 417,   A_ACT: 11,   A_REM: 406,   A_PRO: 2.6,  B_TOT: 121,  B_ACT: 0,    B_REM: 121,  B_PRO: 0,    C_TOT: 2,   C_ACT: 0,   C_REM: 2,   C_PRO: 0,   AP_TOT: 1,   AP_ACT: 0,   AP_REM: 1,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'PP System Owner',               SUBSYS_NO: '121912', CSTD_PLAN: '2024-06-26', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 458,   A_ACT: 7,    A_REM: 451,   A_PRO: 1.5,  B_TOT: 143,  B_ACT: 0,    B_REM: 143,  B_PRO: 0,    C_TOT: 8,   C_ACT: 0,   C_REM: 8,   C_PRO: 0,   AP_TOT: 3,   AP_ACT: 0,   AP_REM: 3,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'PP System Owner',               SUBSYS_NO: '121913', CSTD_PLAN: '2024-06-01', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 1240,  A_ACT: 17,   A_REM: 1223,  A_PRO: 1.4,  B_TOT: 272,  B_ACT: 0,    B_REM: 272,  B_PRO: 0,    C_TOT: 8,   C_ACT: 0,   C_REM: 8,   C_PRO: 0,   AP_TOT: 5,   AP_ACT: 0,   AP_REM: 5,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'PP System Owner',               SUBSYS_NO: '121914', CSTD_PLAN: '2024-06-11', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 3028,  A_ACT: 33,   A_REM: 2995,  A_PRO: 1.1,  B_TOT: 808,  B_ACT: 0,    B_REM: 808,  B_PRO: 0,    C_TOT: 23,  C_ACT: 0,   C_REM: 23,  C_PRO: 0,   AP_TOT: 31,  AP_ACT: 2,   AP_REM: 29,  AP_PRO: 6.5,  BP_TOT: 1,    BP_ACT: 0,    BP_REM: 1,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'PP System Owner',               SUBSYS_NO: '121915', CSTD_PLAN: '2024-02-29', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 199,   A_ACT: 1,    A_REM: 198,   A_PRO: 0.5,  B_TOT: 29,   B_ACT: 0,    B_REM: 29,   B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'PP System Owner',               SUBSYS_NO: '121916', CSTD_PLAN: '2024-06-16', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 1254,  A_ACT: 25,   A_REM: 1229,  A_PRO: 2,    B_TOT: 239,  B_ACT: 0,    B_REM: 239,  B_PRO: 0,    C_TOT: 8,   C_ACT: 0,   C_REM: 8,   C_PRO: 0,   AP_TOT: 8,   AP_ACT: 0,   AP_REM: 8,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'PP System Owner',               SUBSYS_NO: '121917', CSTD_PLAN: '2024-06-21', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 210,   A_ACT: 4,    A_REM: 206,   A_PRO: 1.9,  B_TOT: 53,   B_ACT: 0,    B_REM: 53,   B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'PP System Owner',               SUBSYS_NO: '121921', CSTD_PLAN: '2024-06-16', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 410,   A_ACT: 19,   A_REM: 391,   A_PRO: 4.6,  B_TOT: 124,  B_ACT: 0,    B_REM: 124,  B_PRO: 0,    C_TOT: 3,   C_ACT: 0,   C_REM: 3,   C_PRO: 0,   AP_TOT: 1,   AP_ACT: 0,   AP_REM: 1,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'PP System Owner',               SUBSYS_NO: '121922', CSTD_PLAN: '2024-06-29', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 438,   A_ACT: 18,   A_REM: 420,   A_PRO: 4.1,  B_TOT: 70,   B_ACT: 0,    B_REM: 70,   B_PRO: 0,    C_TOT: 6,   C_ACT: 0,   C_REM: 6,   C_PRO: 0,   AP_TOT: 3,   AP_ACT: 0,   AP_REM: 3,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'PP System Owner',               SUBSYS_NO: '121923', CSTD_PLAN: '2024-06-15', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 1216,  A_ACT: 15,   A_REM: 1201,  A_PRO: 1.2,  B_TOT: 268,  B_ACT: 0,    B_REM: 268,  B_PRO: 0,    C_TOT: 6,   C_ACT: 0,   C_REM: 6,   C_PRO: 0,   AP_TOT: 8,   AP_ACT: 0,   AP_REM: 8,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'PP System Owner',               SUBSYS_NO: '121924', CSTD_PLAN: '2024-06-17', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 837,   A_ACT: 11,   A_REM: 826,   A_PRO: 1.3,  B_TOT: 141,  B_ACT: 0,    B_REM: 141,  B_PRO: 0,    C_TOT: 4,   C_ACT: 0,   C_REM: 4,   C_PRO: 0,   AP_TOT: 22,  AP_ACT: 0,   AP_REM: 22,  AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'PP System Owner',               SUBSYS_NO: '121925', CSTD_PLAN: '2024-02-29', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 224,   A_ACT: 1,    A_REM: 223,   A_PRO: 0.4,  B_TOT: 25,   B_ACT: 0,    B_REM: 25,   B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'PP System Owner',               SUBSYS_NO: '121926', CSTD_PLAN: '2024-06-19', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 527,   A_ACT: 10,   A_REM: 517,   A_PRO: 1.9,  B_TOT: 102,  B_ACT: 0,    B_REM: 102,  B_PRO: 0,    C_TOT: 5,   C_ACT: 0,   C_REM: 5,   C_PRO: 0,   AP_TOT: 4,   AP_ACT: 0,   AP_REM: 4,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'PP System Owner',               SUBSYS_NO: '121927', CSTD_PLAN: '2024-06-23', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 306,   A_ACT: 4,    A_REM: 302,   A_PRO: 1.3,  B_TOT: 62,   B_ACT: 0,    B_REM: 62,   B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Tank Farm System Owner',        SUBSYS_NO: '124700', CSTD_PLAN: '2024-06-19', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 1304,  A_ACT: 64,   A_REM: 1240,  A_PRO: 4.9,  B_TOT: 345,  B_ACT: 6,    B_REM: 339,  B_PRO: 1.7,  C_TOT: 9,   C_ACT: 0,   C_REM: 9,   C_PRO: 0,   AP_TOT: 12,  AP_ACT: 0,   AP_REM: 12,  AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Tank Farm System Owner',        SUBSYS_NO: '166800', CSTD_PLAN: '2024-03-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 214,   A_ACT: 21,   A_REM: 193,   A_PRO: 9.8,  B_TOT: 102,  B_ACT: 0,    B_REM: 102,  B_PRO: 0,    C_TOT: 6,   C_ACT: 0,   C_REM: 6,   C_PRO: 0,   AP_TOT: 5,   AP_ACT: 0,   AP_REM: 5,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Tank Farm System Owner',        SUBSYS_NO: '168000', CSTD_PLAN: '2024-07-31', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 949,   A_ACT: 6,    A_REM: 943,   A_PRO: 0.6,  B_TOT: 268,  B_ACT: 4,    B_REM: 264,  B_PRO: 1.5,  C_TOT: 9,   C_ACT: 0,   C_REM: 9,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Tank Farm System Owner',        SUBSYS_NO: '170000', CSTD_PLAN: '2024-06-19', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 217,   A_ACT: 9,    A_REM: 208,   A_PRO: 4.1,  B_TOT: 99,   B_ACT: 0,    B_REM: 99,   B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Tank Farm System Owner',        SUBSYS_NO: '170100', CSTD_PLAN: '2024-06-27', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 2993,  A_ACT: 0,    A_REM: 2993,  A_PRO: 0,    B_TOT: 750,  B_ACT: 11,   B_REM: 739,  B_PRO: 1.5,  C_TOT: 22,  C_ACT: 0,   C_REM: 22,  C_PRO: 0,   AP_TOT: 16,  AP_ACT: 2,   AP_REM: 14,  AP_PRO: 12.5, BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Tank Farm System Owner',        SUBSYS_NO: '170300', CSTD_PLAN: '2024-04-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 1846,  A_ACT: 44,   A_REM: 1802,  A_PRO: 2.4,  B_TOT: 513,  B_ACT: 12,   B_REM: 501,  B_PRO: 2.3,  C_TOT: 39,  C_ACT: 0,   C_REM: 39,  C_PRO: 0,   AP_TOT: 15,  AP_ACT: 0,   AP_REM: 15,  AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Tank Farm System Owner',        SUBSYS_NO: '170800', CSTD_PLAN: '2024-04-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 1582,  A_ACT: 24,   A_REM: 1558,  A_PRO: 1.5,  B_TOT: 484,  B_ACT: 6,    B_REM: 478,  B_PRO: 1.2,  C_TOT: 32,  C_ACT: 0,   C_REM: 32,  C_PRO: 0,   AP_TOT: 9,   AP_ACT: 0,   AP_REM: 9,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Tank Farm System Owner',        SUBSYS_NO: '177100', CSTD_PLAN: '2024-06-19', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 1540,  A_ACT: 6,    A_REM: 1534,  A_PRO: 0.4,  B_TOT: 513,  B_ACT: 3,    B_REM: 510,  B_PRO: 0.6,  C_TOT: 19,  C_ACT: 0,   C_REM: 19,  C_PRO: 0,   AP_TOT: 4,   AP_ACT: 0,   AP_REM: 4,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Tank Farm System Owner',        SUBSYS_NO: '181301', CSTD_PLAN: '2024-04-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 40,    A_ACT: 0,    A_REM: 40,    A_PRO: 0,    B_TOT: 9,    B_ACT: 0,    B_REM: 9,    B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Tank Farm System Owner',        SUBSYS_NO: '181302', CSTD_PLAN: '2024-08-09', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 52,    A_ACT: 3,    A_REM: 49,    A_PRO: 5.8,  B_TOT: 43,   B_ACT: 0,    B_REM: 43,   B_PRO: 0,    C_TOT: 1,   C_ACT: 0,   C_REM: 1,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Tank Farm System Owner',        SUBSYS_NO: '181303', CSTD_PLAN: '2024-06-19', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 52,    A_ACT: 3,    A_REM: 49,    A_PRO: 5.8,  B_TOT: 43,   B_ACT: 0,    B_REM: 43,   B_PRO: 0,    C_TOT: 1,   C_ACT: 0,   C_REM: 1,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Tank Farm System Owner',        SUBSYS_NO: '181304', CSTD_PLAN: '2024-08-09', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 43,    A_ACT: 0,    A_REM: 43,    A_PRO: 0,    B_TOT: 30,   B_ACT: 0,    B_REM: 30,   B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Tank Farm System Owner',        SUBSYS_NO: '277100', CSTD_PLAN: '2024-08-09', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 1058,  A_ACT: 49,   A_REM: 1009,  A_PRO: 4.6,  B_TOT: 383,  B_ACT: 4,    B_REM: 379,  B_PRO: 1,    C_TOT: 23,  C_ACT: 0,   C_REM: 23,  C_PRO: 0,   AP_TOT: 13,  AP_ACT: 0,   AP_REM: 13,  AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Tank Farm System Owner',        SUBSYS_NO: '370100', CSTD_PLAN: '2024-06-10', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 263,   A_ACT: 1,    A_REM: 262,   A_PRO: 0.4,  B_TOT: 72,   B_ACT: 3,    B_REM: 69,   B_PRO: 4.2,  C_TOT: 10,  C_ACT: 0,   C_REM: 10,  C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Tank Farm System Owner',        SUBSYS_NO: '377100', CSTD_PLAN: '2024-08-09', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 607,   A_ACT: 9,    A_REM: 598,   A_PRO: 1.5,  B_TOT: 187,  B_ACT: 3,    B_REM: 184,  B_PRO: 1.6,  C_TOT: 14,  C_ACT: 0,   C_REM: 14,  C_PRO: 0,   AP_TOT: 2,   AP_ACT: 0,   AP_REM: 2,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Tank Farm System Owner',        SUBSYS_NO: '270100', CSTD_PLAN: '2024-06-19', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 396,   A_ACT: 8,    A_REM: 388,   A_PRO: 2,    B_TOT: 74,   B_ACT: 1,    B_REM: 73,   B_PRO: 1.4,  C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Tank Farm System Owner',        SUBSYS_NO: '270300', CSTD_PLAN: '2024-04-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 2806,  A_ACT: 76,   A_REM: 2730,  A_PRO: 2.7,  B_TOT: 792,  B_ACT: 14,   B_REM: 778,  B_PRO: 1.8,  C_TOT: 35,  C_ACT: 0,   C_REM: 35,  C_PRO: 0,   AP_TOT: 30,  AP_ACT: 0,   AP_REM: 30,  AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Tank Farm System Owner',        SUBSYS_NO: '277000', CSTD_PLAN: '2024-08-09', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 250,   A_ACT: 19,   A_REM: 231,   A_PRO: 7.6,  B_TOT: 104,  B_ACT: 0,    B_REM: 104,  B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Telecom Overall System Owner',  SUBSYS_NO: '187501', CSTD_PLAN: '2023-12-25', CSTD_WALKDOWN: '2023-12-25', CSTD_ACTUAL: '2023-12-25', A_TOT: 127,   A_ACT: 117,  A_REM: 10,    A_PRO: 92.1, B_TOT: 39,   B_ACT: 5,    B_REM: 34,   B_PRO: 12.8, C_TOT: 1,   C_ACT: 0,   C_REM: 1,   C_PRO: 0,   AP_TOT: 27,  AP_ACT: 26,  AP_REM: 1,   AP_PRO: 96.3, BP_TOT: 12,   BP_ACT: 3,    BP_REM: 9,   BP_PRO: 25,   CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Telecom Overall System Owner',  SUBSYS_NO: '281001', CSTD_PLAN: '2024-01-23', CSTD_WALKDOWN: '2024-01-05', CSTD_ACTUAL: '2024-01-05', A_TOT: 51,    A_ACT: 51,   A_REM: 0,     A_PRO: 100,  B_TOT: 18,   B_ACT: 2,    B_REM: 16,   B_PRO: 11.1, C_TOT: 1,   C_ACT: 0,   C_REM: 1,   C_PRO: 0,   AP_TOT: 6,   AP_ACT: 6,   AP_REM: 0,   AP_PRO: 100,  BP_TOT: 13,   BP_ACT: 4,    BP_REM: 9,   BP_PRO: 30.8, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Telecom Overall System Owner',  SUBSYS_NO: '181001', CSTD_PLAN: '2024-01-20', CSTD_WALKDOWN: '2024-01-15', CSTD_ACTUAL: '2024-01-15', A_TOT: 56,    A_ACT: 56,   A_REM: 0,     A_PRO: 100,  B_TOT: 18,   B_ACT: 0,    B_REM: 18,   B_PRO: 0,    C_TOT: 1,   C_ACT: 0,   C_REM: 1,   C_PRO: 0,   AP_TOT: 10,  AP_ACT: 10,  AP_REM: 0,   AP_PRO: 100,  BP_TOT: 16,   BP_ACT: 10,   BP_REM: 6,   BP_PRO: 62.5, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Utility System Owner',          SUBSYS_NO: '150300', CSTD_PLAN: '2024-03-10', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 1009,  A_ACT: 18,   A_REM: 991,   A_PRO: 1.8,  B_TOT: 503,  B_ACT: 0,    B_REM: 503,  B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 19,   BP_ACT: 0,    BP_REM: 19,  BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Utility System Owner',          SUBSYS_NO: '179000', CSTD_PLAN: '2024-03-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 330,   A_ACT: 93,   A_REM: 237,   A_PRO: 28.2, B_TOT: 38,   B_ACT: 0,    B_REM: 38,   B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 26,  AP_ACT: 0,   AP_REM: 26,  AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Utility System Owner',          SUBSYS_NO: '179100', CSTD_PLAN: '2024-03-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 2365,  A_ACT: 53,   A_REM: 2312,  A_PRO: 2.2,  B_TOT: 253,  B_ACT: 2,    B_REM: 251,  B_PRO: 0.8,  C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 15,  AP_ACT: 2,   AP_REM: 13,  AP_PRO: 13.3, BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Utility System Owner',          SUBSYS_NO: '185300', CSTD_PLAN: '2024-01-15', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 801,   A_ACT: 416,  A_REM: 385,   A_PRO: 51.9, B_TOT: 428,  B_ACT: 2,    B_REM: 426,  B_PRO: 0.5,  C_TOT: 21,  C_ACT: 0,   C_REM: 21,  C_PRO: 0,   AP_TOT: 173, AP_ACT: 27,  AP_REM: 146, AP_PRO: 15.6, BP_TOT: 155,  BP_ACT: 7,    BP_REM: 148, BP_PRO: 4.5,  CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Utility System Owner',          SUBSYS_NO: '190400', CSTD_PLAN: '2024-03-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 37,    A_ACT: 0,    A_REM: 37,    A_PRO: 0,    B_TOT: 3,    B_ACT: 0,    B_REM: 3,    B_PRO: 0,    C_TOT: 0,   C_ACT: 0,   C_REM: 0,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Water System Owner',            SUBSYS_NO: '381006', CSTD_PLAN: '2024-02-28', CSTD_WALKDOWN: '2024-01-26', CSTD_ACTUAL: '2024-01-26', A_TOT: 171,   A_ACT: 138,  A_REM: 33,    A_PRO: 80.7, B_TOT: 96,   B_ACT: 0,    B_REM: 96,   B_PRO: 0,    C_TOT: 12,  C_ACT: 0,   C_REM: 12,  C_PRO: 0,   AP_TOT: 28,  AP_ACT: 23,  AP_REM: 5,   AP_PRO: 82.1, BP_TOT: 25,   BP_ACT: 4,    BP_REM: 21,  BP_PRO: 16,   CP_TOT: 1,  CP_ACT: 0,  CP_REM: 1, CP_PRO: 0, },
      { UNIT: 'Water System Owner',            SUBSYS_NO: '281000', CSTD_PLAN: '2024-01-23', CSTD_WALKDOWN: '2024-01-15', CSTD_ACTUAL: '2024-01-15', A_TOT: 315,   A_ACT: 307,  A_REM: 8,     A_PRO: 97.5, B_TOT: 151,  B_ACT: 28,   B_REM: 123,  B_PRO: 18.5, C_TOT: 10,  C_ACT: 0,   C_REM: 10,  C_PRO: 0,   AP_TOT: 272, AP_ACT: 269, AP_REM: 3,   AP_PRO: 98.9, BP_TOT: 232,  BP_ACT: 138,  BP_REM: 94,  BP_PRO: 59.5, CP_TOT: 2,  CP_ACT: 0,  CP_REM: 2, CP_PRO: 0, },
      { UNIT: 'Water System Owner',            SUBSYS_NO: '182400', CSTD_PLAN: '2024-01-31', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 796,   A_ACT: 536,  A_REM: 260,   A_PRO: 67.3, B_TOT: 312,  B_ACT: 1,    B_REM: 311,  B_PRO: 0.3,  C_TOT: 23,  C_ACT: 1,   C_REM: 22,  C_PRO: 4.3, AP_TOT: 5,   AP_ACT: 0,   AP_REM: 5,   AP_PRO: 0,    BP_TOT: 1,    BP_ACT: 0,    BP_REM: 1,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Water System Owner',            SUBSYS_NO: '181000', CSTD_PLAN: '2024-01-20', CSTD_WALKDOWN: '2024-01-24', CSTD_ACTUAL: '2024-01-24', A_TOT: 510,   A_ACT: 502,  A_REM: 8,     A_PRO: 98.4, B_TOT: 235,  B_ACT: 19,   B_REM: 216,  B_PRO: 8.1,  C_TOT: 18,  C_ACT: 0,   C_REM: 18,  C_PRO: 0,   AP_TOT: 181, AP_ACT: 181, AP_REM: 0,   AP_PRO: 100,  BP_TOT: 194,  BP_ACT: 146,  BP_REM: 48,  BP_PRO: 75.3, CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Water System Owner',            SUBSYS_NO: '180401', CSTD_PLAN: '2024-01-15', CSTD_WALKDOWN: '2024-01-15', CSTD_ACTUAL: '2024-01-15', A_TOT: 59,    A_ACT: 51,   A_REM: 8,     A_PRO: 86.4, B_TOT: 16,   B_ACT: 3,    B_REM: 13,   B_PRO: 18.8, C_TOT: 2,   C_ACT: 0,   C_REM: 2,   C_PRO: 0,   AP_TOT: 20,  AP_ACT: 16,  AP_REM: 4,   AP_PRO: 80,   BP_TOT: 18,   BP_ACT: 10,   BP_REM: 8,   BP_PRO: 55.6, CP_TOT: 1,  CP_ACT: 0,  CP_REM: 1, CP_PRO: 0, },
      { UNIT: 'Water System Owner',            SUBSYS_NO: '180402', CSTD_PLAN: '2024-06-10', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 95,    A_ACT: 2,    A_REM: 93,    A_PRO: 2.1,  B_TOT: 32,   B_ACT: 0,    B_REM: 32,   B_PRO: 0,    C_TOT: 2,   C_ACT: 0,   C_REM: 2,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Water System Owner',            SUBSYS_NO: '180403', CSTD_PLAN: '2024-08-09', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 146,   A_ACT: 0,    A_REM: 146,   A_PRO: 0,    B_TOT: 46,   B_ACT: 0,    B_REM: 46,   B_PRO: 0,    C_TOT: 4,   C_ACT: 0,   C_REM: 4,   C_PRO: 0,   AP_TOT: 1,   AP_ACT: 0,   AP_REM: 1,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Water System Owner',            SUBSYS_NO: '180404', CSTD_PLAN: '2024-02-28', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 47,    A_ACT: 2,    A_REM: 45,    A_PRO: 4.3,  B_TOT: 18,   B_ACT: 0,    B_REM: 18,   B_PRO: 0,    C_TOT: 2,   C_ACT: 0,   C_REM: 2,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      { UNIT: 'Water System Owner',            SUBSYS_NO: '127942', CSTD_PLAN: '2024-08-30', CSTD_WALKDOWN: undefined,       CSTD_ACTUAL: undefined,       A_TOT: 151,   A_ACT: 12,   A_REM: 139,   A_PRO: 7.9,  B_TOT: 56,   B_ACT: 0,    B_REM: 56,   B_PRO: 0,    C_TOT: 8,   C_ACT: 0,   C_REM: 8,   C_PRO: 0,   AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0, },
      
      
  ],

  SQL2: [
    { ITEM: 'A', UNIT: 'Boiler & Furnace System Owner',  SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 44,   REMAIN_CUM: 2171,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'Boiler & Furnace System Owner',  SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 54,   REMAIN_CUM: 2117,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'Boiler & Furnace System Owner',  SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 60,   REMAIN_CUM: 2057,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'Boiler & Furnace System Owner',  SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 88,   REMAIN_CUM: 1969,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'Boiler & Furnace System Owner',  SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 56,   REMAIN_CUM: 1913,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'Boiler & Furnace System Owner',  SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 16,   REMAIN_CUM: 1897,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'Boiler & Furnace System Owner',  SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 7,    REMAIN_CUM: 1890,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'Boiler & Furnace System Owner',  SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 7,    REMAIN_CUM: 1883,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'Boiler & Furnace System Owner',  SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 15,   REMAIN_CUM: 1868,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'Boiler & Furnace System Owner',  SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 1,    REMAIN_CUM: 1867,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'Boiler & Furnace System Owner',  SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 6,    REMAIN_CUM: 1861,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'Boiler & Furnace System Owner',  SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 4,    REMAIN_CUM: 1857,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'Boiler & Furnace System Owner',  SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 2,    REMAIN_CUM: 1855,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'Boiler & Furnace System Owner',  SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 5,    REMAIN_CUM: 1850,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'Boiler & Furnace System Owner',  SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 3,    REMAIN_CUM: 1847,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'Boiler & Furnace System Owner',  SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 12,   REMAIN_CUM: 1835,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'Boiler & Furnace System Owner',  SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 1,    REMAIN_CUM: 1834,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'Boiler & Furnace System Owner',  SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 2,    REMAIN_CUM: 1832,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'Boiler & Furnace System Owner',  SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 4,    REMAIN_CUM: 1828,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'Boiler & Furnace System Owner',  SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 8,    REMAIN_CUM: 1820,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'Boiler & Furnace System Owner',  SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 4,    REMAIN_CUM: 1816,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'Boiler & Furnace System Owner',  SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 2,    REMAIN_CUM: 1814,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'Boiler & Furnace System Owner',  SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 12,   REMAIN_CUM: 1802,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'Boiler & Furnace System Owner',  SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 9,    REMAIN_CUM: 1793,  TOTAL: 2895, },
    { ITEM: 'A', UNIT: 'CGC System Owner',               SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 8,    REMAIN_CUM: 12548, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CGC System Owner',               SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 1,    REMAIN_CUM: 12547, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CGC System Owner',               SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 17,   REMAIN_CUM: 12530, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CGC System Owner',               SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 47,   REMAIN_CUM: 12483, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CGC System Owner',               SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 17,   REMAIN_CUM: 12466, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CGC System Owner',               SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 37,   REMAIN_CUM: 12429, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CGC System Owner',               SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 13,   REMAIN_CUM: 12416, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CGC System Owner',               SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 48,   REMAIN_CUM: 12368, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CGC System Owner',               SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 22,   REMAIN_CUM: 12346, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CGC System Owner',               SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 327,  REMAIN_CUM: 12019, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CGC System Owner',               SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 222,  REMAIN_CUM: 11797, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CGC System Owner',               SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 9,    REMAIN_CUM: 11788, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CGC System Owner',               SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 5,    REMAIN_CUM: 11783, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CGC System Owner',               SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 25,   REMAIN_CUM: 11758, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CGC System Owner',               SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 17,   REMAIN_CUM: 11741, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CGC System Owner',               SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 10,   REMAIN_CUM: 11731, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CGC System Owner',               SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 32,   REMAIN_CUM: 11699, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CGC System Owner',               SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 43,   REMAIN_CUM: 11656, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CGC System Owner',               SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 72,   REMAIN_CUM: 11584, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CGC System Owner',               SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 184,  REMAIN_CUM: 11400, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CGC System Owner',               SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 173,  REMAIN_CUM: 11227, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CGC System Owner',               SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 27,   REMAIN_CUM: 11200, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CGC System Owner',               SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 34,   REMAIN_CUM: 11166, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'CGC System Owner',               SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 34,   REMAIN_CUM: 11132, TOTAL: 13991, },
    { ITEM: 'A', UNIT: 'Cooling Water System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 1,    REMAIN_CUM: 1433,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'Cooling Water System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 10,   REMAIN_CUM: 1423,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'Cooling Water System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 5,    REMAIN_CUM: 1418,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'Cooling Water System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 13,   REMAIN_CUM: 1405,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'Cooling Water System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 7,    REMAIN_CUM: 1398,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'Cooling Water System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 11,   REMAIN_CUM: 1387,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'Cooling Water System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 6,    REMAIN_CUM: 1381,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'Cooling Water System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 13,   REMAIN_CUM: 1368,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'Cooling Water System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 2,    REMAIN_CUM: 1366,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'Cooling Water System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 5,    REMAIN_CUM: 1361,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'Cooling Water System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 3,    REMAIN_CUM: 1358,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'Cooling Water System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 2,    REMAIN_CUM: 1356,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'Cooling Water System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 11,   REMAIN_CUM: 1345,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'Cooling Water System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 10,   REMAIN_CUM: 1335,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'Cooling Water System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 9,    REMAIN_CUM: 1326,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'Cooling Water System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 0,    REMAIN_CUM: 1326,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'Cooling Water System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 1,    REMAIN_CUM: 1325,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'Cooling Water System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 8,    REMAIN_CUM: 1317,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'Cooling Water System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 13,   REMAIN_CUM: 1304,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'Cooling Water System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 5,    REMAIN_CUM: 1299,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'Cooling Water System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 2,    REMAIN_CUM: 1297,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'Cooling Water System Owner',     SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 11,   REMAIN_CUM: 1286,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'Cooling Water System Owner',     SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 16,   REMAIN_CUM: 1270,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'Cooling Water System Owner',     SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 7,    REMAIN_CUM: 1263,  TOTAL: 1659, },
    { ITEM: 'A', UNIT: 'Co-Product System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 19,   REMAIN_CUM: 7636,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'Co-Product System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 45,   REMAIN_CUM: 7591,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'Co-Product System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 37,   REMAIN_CUM: 7554,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'Co-Product System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 59,   REMAIN_CUM: 7495,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'Co-Product System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 75,   REMAIN_CUM: 7420,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'Co-Product System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 75,   REMAIN_CUM: 7345,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'Co-Product System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 112,  REMAIN_CUM: 7233,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'Co-Product System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 31,   REMAIN_CUM: 7202,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'Co-Product System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 14,   REMAIN_CUM: 7188,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'Co-Product System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 57,   REMAIN_CUM: 7131,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'Co-Product System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 66,   REMAIN_CUM: 7065,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'Co-Product System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 68,   REMAIN_CUM: 6997,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'Co-Product System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 123,  REMAIN_CUM: 6874,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'Co-Product System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 154,  REMAIN_CUM: 6720,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'Co-Product System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 168,  REMAIN_CUM: 6552,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'Co-Product System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 99,   REMAIN_CUM: 6453,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'Co-Product System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 58,   REMAIN_CUM: 6395,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'Co-Product System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 140,  REMAIN_CUM: 6255,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'Co-Product System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 140,  REMAIN_CUM: 6115,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'Co-Product System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 221,  REMAIN_CUM: 5894,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'Co-Product System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 207,  REMAIN_CUM: 5687,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'Co-Product System Owner',        SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 167,  REMAIN_CUM: 5520,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'Co-Product System Owner',        SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 238,  REMAIN_CUM: 5282,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'Co-Product System Owner',        SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 15,   REMAIN_CUM: 5267,  TOTAL: 9002, },
    { ITEM: 'A', UNIT: 'Finishing System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 153,  REMAIN_CUM: 33668, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'Finishing System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 66,   REMAIN_CUM: 33602, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'Finishing System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 92,   REMAIN_CUM: 33510, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'Finishing System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 66,   REMAIN_CUM: 33444, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'Finishing System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 76,   REMAIN_CUM: 33368, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'Finishing System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 205,  REMAIN_CUM: 33163, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'Finishing System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 160,  REMAIN_CUM: 33003, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'Finishing System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 448,  REMAIN_CUM: 32555, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'Finishing System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 822,  REMAIN_CUM: 31733, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'Finishing System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 328,  REMAIN_CUM: 31405, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'Finishing System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 92,   REMAIN_CUM: 31313, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'Finishing System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 47,   REMAIN_CUM: 31266, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'Finishing System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 63,   REMAIN_CUM: 31203, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'Finishing System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 109,  REMAIN_CUM: 31094, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'Finishing System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 61,   REMAIN_CUM: 31033, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'Finishing System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 100,  REMAIN_CUM: 30933, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'Finishing System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 114,  REMAIN_CUM: 30819, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'Finishing System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 113,  REMAIN_CUM: 30706, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'Finishing System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 44,   REMAIN_CUM: 30662, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'Finishing System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 82,   REMAIN_CUM: 30580, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'Finishing System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 24,   REMAIN_CUM: 30556, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'Finishing System Owner',         SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 25,   REMAIN_CUM: 30531, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'Finishing System Owner',         SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 149,  REMAIN_CUM: 30382, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'Finishing System Owner',         SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 59,   REMAIN_CUM: 30323, TOTAL: 36136, },
    { ITEM: 'A', UNIT: 'Flare System Owner',             SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 22,   REMAIN_CUM: 7500,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'Flare System Owner',             SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 20,   REMAIN_CUM: 7480,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'Flare System Owner',             SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 15,   REMAIN_CUM: 7465,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'Flare System Owner',             SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 12,   REMAIN_CUM: 7453,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'Flare System Owner',             SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 21,   REMAIN_CUM: 7432,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'Flare System Owner',             SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 51,   REMAIN_CUM: 7381,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'Flare System Owner',             SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 36,   REMAIN_CUM: 7345,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'Flare System Owner',             SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 61,   REMAIN_CUM: 7284,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'Flare System Owner',             SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 80,   REMAIN_CUM: 7204,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'Flare System Owner',             SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 337,  REMAIN_CUM: 6867,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'Flare System Owner',             SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 62,   REMAIN_CUM: 6805,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'Flare System Owner',             SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 10,   REMAIN_CUM: 6795,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'Flare System Owner',             SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 18,   REMAIN_CUM: 6777,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'Flare System Owner',             SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 25,   REMAIN_CUM: 6752,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'Flare System Owner',             SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 45,   REMAIN_CUM: 6707,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'Flare System Owner',             SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 21,   REMAIN_CUM: 6686,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'Flare System Owner',             SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 31,   REMAIN_CUM: 6655,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'Flare System Owner',             SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 34,   REMAIN_CUM: 6621,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'Flare System Owner',             SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 47,   REMAIN_CUM: 6574,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'Flare System Owner',             SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 9,    REMAIN_CUM: 6565,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'Flare System Owner',             SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 15,   REMAIN_CUM: 6550,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'Flare System Owner',             SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 6,    REMAIN_CUM: 6544,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'Flare System Owner',             SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 8,    REMAIN_CUM: 6536,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'Flare System Owner',             SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 16,   REMAIN_CUM: 6520,  TOTAL: 8353, },
    { ITEM: 'A', UNIT: 'GPPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 81,   REMAIN_CUM: 2817,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'GPPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 3,    REMAIN_CUM: 2814,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'GPPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 14,   REMAIN_CUM: 2800,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'GPPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 9,    REMAIN_CUM: 2791,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'GPPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 8,    REMAIN_CUM: 2783,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'GPPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 29,   REMAIN_CUM: 2754,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'GPPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 21,   REMAIN_CUM: 2733,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'GPPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 42,   REMAIN_CUM: 2691,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'GPPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 14,   REMAIN_CUM: 2677,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'GPPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 56,   REMAIN_CUM: 2621,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'GPPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 42,   REMAIN_CUM: 2579,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'GPPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 29,   REMAIN_CUM: 2550,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'GPPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 14,   REMAIN_CUM: 2536,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'GPPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 17,   REMAIN_CUM: 2519,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'GPPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 5,    REMAIN_CUM: 2514,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'GPPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 6,    REMAIN_CUM: 2508,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'GPPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 8,    REMAIN_CUM: 2500,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'GPPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 33,   REMAIN_CUM: 2467,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'GPPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 17,   REMAIN_CUM: 2450,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'GPPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 41,   REMAIN_CUM: 2409,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'GPPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 13,   REMAIN_CUM: 2396,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'GPPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 32,   REMAIN_CUM: 2364,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'GPPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 93,   REMAIN_CUM: 2271,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'GPPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 20,   REMAIN_CUM: 2251,  TOTAL: 3455, },
    { ITEM: 'A', UNIT: 'LDPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 48,   REMAIN_CUM: 677,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'LDPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 65,   REMAIN_CUM: 612,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'LDPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 94,   REMAIN_CUM: 518,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'LDPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 51,   REMAIN_CUM: 467,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'LDPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 76,   REMAIN_CUM: 391,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'LDPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 46,   REMAIN_CUM: 345,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'LDPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 32,   REMAIN_CUM: 313,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'LDPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 14,   REMAIN_CUM: 299,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'LDPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 14,   REMAIN_CUM: 285,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'LDPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 42,   REMAIN_CUM: 243,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'LDPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 16,   REMAIN_CUM: 227,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'LDPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 1,    REMAIN_CUM: 226,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'LDPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 0,    REMAIN_CUM: 226,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'LDPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 12,   REMAIN_CUM: 214,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'LDPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 6,    REMAIN_CUM: 208,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'LDPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 5,    REMAIN_CUM: 203,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'LDPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 6,    REMAIN_CUM: 197,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'LDPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 12,   REMAIN_CUM: 185,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'LDPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 0,    REMAIN_CUM: 185,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'LDPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 2,    REMAIN_CUM: 183,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'LDPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 0,    REMAIN_CUM: 183,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'LDPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 36,   REMAIN_CUM: 147,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'LDPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 6,    REMAIN_CUM: 141,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'LDPE System Owner',              SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 6,    REMAIN_CUM: 135,   TOTAL: 793, },
    { ITEM: 'A', UNIT: 'P1 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 0,    REMAIN_CUM: 516,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'P1 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 0,    REMAIN_CUM: 516,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'P1 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 1,    REMAIN_CUM: 515,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'P1 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 18,   REMAIN_CUM: 497,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'P1 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 18,   REMAIN_CUM: 479,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'P1 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 6,    REMAIN_CUM: 473,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'P1 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 0,    REMAIN_CUM: 473,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'P1 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 17,   REMAIN_CUM: 456,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'P1 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 8,    REMAIN_CUM: 448,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'P1 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 155,  REMAIN_CUM: 293,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'P1 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 34,   REMAIN_CUM: 259,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'P1 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 44,   REMAIN_CUM: 215,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'P1 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 11,   REMAIN_CUM: 204,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'P1 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 8,    REMAIN_CUM: 196,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'P1 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 1,    REMAIN_CUM: 195,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'P1 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 0,    REMAIN_CUM: 195,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'P1 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 34,   REMAIN_CUM: 161,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'P1 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 0,    REMAIN_CUM: 161,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'P1 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 36,   REMAIN_CUM: 125,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'P1 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 0,    REMAIN_CUM: 125,   TOTAL: 550, },
    { ITEM: 'A', UNIT: 'P1 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 40,   REMAIN_CUM: 85,    TOTAL: 550, },
    { ITEM: 'A', UNIT: 'P1 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 0,    REMAIN_CUM: 85,    TOTAL: 550, },
    { ITEM: 'A', UNIT: 'P1 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 0,    REMAIN_CUM: 85,    TOTAL: 550, },
    { ITEM: 'A', UNIT: 'P1 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 0,    REMAIN_CUM: 85,    TOTAL: 550, },
    { ITEM: 'A', UNIT: 'P2 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 5,    REMAIN_CUM: 672,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'P2 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 0,    REMAIN_CUM: 672,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'P2 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 7,    REMAIN_CUM: 665,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'P2 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 0,    REMAIN_CUM: 665,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'P2 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 3,    REMAIN_CUM: 662,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'P2 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 6,    REMAIN_CUM: 656,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'P2 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 0,    REMAIN_CUM: 656,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'P2 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 11,   REMAIN_CUM: 645,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'P2 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 0,    REMAIN_CUM: 645,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'P2 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 0,    REMAIN_CUM: 645,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'P2 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 0,    REMAIN_CUM: 645,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'P2 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 9,    REMAIN_CUM: 636,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'P2 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 1,    REMAIN_CUM: 635,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'P2 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 2,    REMAIN_CUM: 633,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'P2 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 4,    REMAIN_CUM: 629,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'P2 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 0,    REMAIN_CUM: 629,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'P2 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 1,    REMAIN_CUM: 628,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'P2 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 2,    REMAIN_CUM: 626,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'P2 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 2,    REMAIN_CUM: 624,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'P2 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 0,    REMAIN_CUM: 624,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'P2 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 0,    REMAIN_CUM: 624,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'P2 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 2,    REMAIN_CUM: 622,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'P2 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 0,    REMAIN_CUM: 622,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'P2 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 2,    REMAIN_CUM: 620,   TOTAL: 802, },
    { ITEM: 'A', UNIT: 'P3 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 17,   REMAIN_CUM: 4862,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'P3 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 9,    REMAIN_CUM: 4853,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'P3 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 18,   REMAIN_CUM: 4835,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'P3 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 73,   REMAIN_CUM: 4762,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'P3 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 63,   REMAIN_CUM: 4699,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'P3 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 14,   REMAIN_CUM: 4685,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'P3 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 72,   REMAIN_CUM: 4613,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'P3 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 48,   REMAIN_CUM: 4565,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'P3 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 10,   REMAIN_CUM: 4555,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'P3 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 18,   REMAIN_CUM: 4537,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'P3 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 33,   REMAIN_CUM: 4504,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'P3 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 39,   REMAIN_CUM: 4465,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'P3 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 34,   REMAIN_CUM: 4431,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'P3 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 47,   REMAIN_CUM: 4384,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'P3 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 172,  REMAIN_CUM: 4212,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'P3 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 153,  REMAIN_CUM: 4059,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'P3 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 107,  REMAIN_CUM: 3952,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'P3 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 101,  REMAIN_CUM: 3851,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'P3 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 72,   REMAIN_CUM: 3779,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'P3 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 32,   REMAIN_CUM: 3747,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'P3 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 12,   REMAIN_CUM: 3735,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'P3 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 4,    REMAIN_CUM: 3731,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'P3 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 12,   REMAIN_CUM: 3719,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'P3 Building System Owner',       SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 6,    REMAIN_CUM: 3713,  TOTAL: 5313, },
    { ITEM: 'A', UNIT: 'PBL / Utility System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 6,    REMAIN_CUM: 1637,  TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'PBL / Utility System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 5,    REMAIN_CUM: 1632,  TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'PBL / Utility System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 3,    REMAIN_CUM: 1629,  TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'PBL / Utility System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 4,    REMAIN_CUM: 1625,  TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'PBL / Utility System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 6,    REMAIN_CUM: 1619,  TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'PBL / Utility System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 24,   REMAIN_CUM: 1595,  TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'PBL / Utility System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 21,   REMAIN_CUM: 1574,  TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'PBL / Utility System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 126,  REMAIN_CUM: 1448,  TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'PBL / Utility System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 100,  REMAIN_CUM: 1348,  TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'PBL / Utility System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 108,  REMAIN_CUM: 1240,  TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'PBL / Utility System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 135,  REMAIN_CUM: 1105,  TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'PBL / Utility System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 84,   REMAIN_CUM: 1021,  TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'PBL / Utility System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 145,  REMAIN_CUM: 876,   TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'PBL / Utility System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 52,   REMAIN_CUM: 824,   TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'PBL / Utility System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 1,    REMAIN_CUM: 823,   TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'PBL / Utility System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 7,    REMAIN_CUM: 816,   TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'PBL / Utility System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 2,    REMAIN_CUM: 814,   TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'PBL / Utility System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 1,    REMAIN_CUM: 813,   TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'PBL / Utility System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 1,    REMAIN_CUM: 812,   TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'PBL / Utility System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 18,   REMAIN_CUM: 794,   TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'PBL / Utility System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 0,    REMAIN_CUM: 794,   TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'PBL / Utility System Owner',     SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 2,    REMAIN_CUM: 792,   TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'PBL / Utility System Owner',     SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 3,    REMAIN_CUM: 789,   TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'PBL / Utility System Owner',     SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 8,    REMAIN_CUM: 781,   TOTAL: 1954, },
    { ITEM: 'A', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 0,    REMAIN_CUM: 51,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 0,    REMAIN_CUM: 51,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 0,    REMAIN_CUM: 51,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 0,    REMAIN_CUM: 51,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 0,    REMAIN_CUM: 51,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 7,    REMAIN_CUM: 44,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 5,    REMAIN_CUM: 39,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 1,    REMAIN_CUM: 38,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 0,    REMAIN_CUM: 38,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 5,    REMAIN_CUM: 33,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 5,    REMAIN_CUM: 28,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 0,    REMAIN_CUM: 28,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 0,    REMAIN_CUM: 28,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 0,    REMAIN_CUM: 28,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 2,    REMAIN_CUM: 26,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 0,    REMAIN_CUM: 26,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 0,    REMAIN_CUM: 26,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 0,    REMAIN_CUM: 26,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 0,    REMAIN_CUM: 26,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 10,   REMAIN_CUM: 16,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 1,    REMAIN_CUM: 15,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 0,    REMAIN_CUM: 15,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 0,    REMAIN_CUM: 15,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 0,    REMAIN_CUM: 15,    TOTAL: 51, },
    { ITEM: 'A', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 0,    REMAIN_CUM: 96,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 0,    REMAIN_CUM: 96,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 12,   REMAIN_CUM: 84,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 0,    REMAIN_CUM: 84,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 0,    REMAIN_CUM: 84,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 0,    REMAIN_CUM: 84,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 0,    REMAIN_CUM: 84,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 0,    REMAIN_CUM: 84,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 0,    REMAIN_CUM: 84,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 0,    REMAIN_CUM: 84,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 5,    REMAIN_CUM: 79,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 1,    REMAIN_CUM: 78,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 0,    REMAIN_CUM: 78,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 0,    REMAIN_CUM: 78,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 2,    REMAIN_CUM: 76,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 2,    REMAIN_CUM: 74,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 0,    REMAIN_CUM: 74,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 10,   REMAIN_CUM: 64,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 0,    REMAIN_CUM: 64,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 0,    REMAIN_CUM: 64,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 5,    REMAIN_CUM: 59,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 4,    REMAIN_CUM: 55,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 0,    REMAIN_CUM: 55,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 0,    REMAIN_CUM: 55,    TOTAL: 104, },
    { ITEM: 'A', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 1,    REMAIN_CUM: 4028,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 0,    REMAIN_CUM: 4028,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 11,   REMAIN_CUM: 4017,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 6,    REMAIN_CUM: 4011,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 1,    REMAIN_CUM: 4010,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 5,    REMAIN_CUM: 4005,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 18,   REMAIN_CUM: 3987,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 1,    REMAIN_CUM: 3986,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 9,    REMAIN_CUM: 3977,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 3,    REMAIN_CUM: 3974,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 1,    REMAIN_CUM: 3973,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 8,    REMAIN_CUM: 3965,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 10,   REMAIN_CUM: 3955,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 6,    REMAIN_CUM: 3949,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 5,    REMAIN_CUM: 3944,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 4,    REMAIN_CUM: 3940,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 16,   REMAIN_CUM: 3924,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 10,   REMAIN_CUM: 3914,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 11,   REMAIN_CUM: 3903,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 33,   REMAIN_CUM: 3870,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 15,   REMAIN_CUM: 3855,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 11,   REMAIN_CUM: 3844,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 6,    REMAIN_CUM: 3838,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 15,   REMAIN_CUM: 3823,  TOTAL: 4408, },
    { ITEM: 'A', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 1,    REMAIN_CUM: 2138,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 0,    REMAIN_CUM: 2138,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 1,    REMAIN_CUM: 2137,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 5,    REMAIN_CUM: 2132,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 2,    REMAIN_CUM: 2130,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 0,    REMAIN_CUM: 2130,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 13,   REMAIN_CUM: 2117,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 5,    REMAIN_CUM: 2112,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 4,    REMAIN_CUM: 2108,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 9,    REMAIN_CUM: 2099,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 6,    REMAIN_CUM: 2093,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 22,   REMAIN_CUM: 2071,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 3,    REMAIN_CUM: 2068,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 0,    REMAIN_CUM: 2068,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 5,    REMAIN_CUM: 2063,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 0,    REMAIN_CUM: 2063,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 2,    REMAIN_CUM: 2061,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 0,    REMAIN_CUM: 2061,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 3,    REMAIN_CUM: 2058,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 12,   REMAIN_CUM: 2046,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 1,    REMAIN_CUM: 2045,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 1,    REMAIN_CUM: 2044,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 6,    REMAIN_CUM: 2038,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 9,    REMAIN_CUM: 2029,  TOTAL: 2647, },
    { ITEM: 'A', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 589,  REMAIN_CUM: 97542, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 427,  REMAIN_CUM: 97115, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 618,  REMAIN_CUM: 96497, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 582,  REMAIN_CUM: 95915, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 544,  REMAIN_CUM: 95371, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 613,  REMAIN_CUM: 94758, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 595,  REMAIN_CUM: 94163, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 965,  REMAIN_CUM: 93198, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 1206, REMAIN_CUM: 91992, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 1529, REMAIN_CUM: 90463, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 927,  REMAIN_CUM: 89536, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 549,  REMAIN_CUM: 88987, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 536,  REMAIN_CUM: 88451, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 660,  REMAIN_CUM: 87791, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 605,  REMAIN_CUM: 87186, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 493,  REMAIN_CUM: 86693, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 497,  REMAIN_CUM: 86196, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 733,  REMAIN_CUM: 85463, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 735,  REMAIN_CUM: 84728, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 922,  REMAIN_CUM: 83806, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 614,  REMAIN_CUM: 83192, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 424,  REMAIN_CUM: 82768, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 782,  REMAIN_CUM: 81986, TOTAL: 115185, },
    { ITEM: 'A', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 363,  REMAIN_CUM: 81623, TOTAL: 115185, },
    { ITEM: 'B', UNIT: 'Boiler & Furnace System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 12,   REMAIN_CUM: 884,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'Boiler & Furnace System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 14,   REMAIN_CUM: 870,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'Boiler & Furnace System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 28,   REMAIN_CUM: 842,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'Boiler & Furnace System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 49,   REMAIN_CUM: 793,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'Boiler & Furnace System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 72,   REMAIN_CUM: 721,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'Boiler & Furnace System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 1,    REMAIN_CUM: 720,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'Boiler & Furnace System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 7,    REMAIN_CUM: 713,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'Boiler & Furnace System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 1,    REMAIN_CUM: 712,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'Boiler & Furnace System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 15,   REMAIN_CUM: 697,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'Boiler & Furnace System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 3,    REMAIN_CUM: 694,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'Boiler & Furnace System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 1,    REMAIN_CUM: 693,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'Boiler & Furnace System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 3,    REMAIN_CUM: 690,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'Boiler & Furnace System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 0,    REMAIN_CUM: 690,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'Boiler & Furnace System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 0,    REMAIN_CUM: 690,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'Boiler & Furnace System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 2,    REMAIN_CUM: 688,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'Boiler & Furnace System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 3,    REMAIN_CUM: 685,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'Boiler & Furnace System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 0,    REMAIN_CUM: 685,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'Boiler & Furnace System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 0,    REMAIN_CUM: 685,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'Boiler & Furnace System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 0,    REMAIN_CUM: 685,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'Boiler & Furnace System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 0,    REMAIN_CUM: 685,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'Boiler & Furnace System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 6,    REMAIN_CUM: 679,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'Boiler & Furnace System Owner',         SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 0,    REMAIN_CUM: 679,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'Boiler & Furnace System Owner',         SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 1,    REMAIN_CUM: 678,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'Boiler & Furnace System Owner',         SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 1,    REMAIN_CUM: 677,   TOTAL: 1110, },
    { ITEM: 'B', UNIT: 'CGC System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 0,    REMAIN_CUM: 3846,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CGC System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 1,    REMAIN_CUM: 3845,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CGC System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 3,    REMAIN_CUM: 3842,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CGC System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 8,    REMAIN_CUM: 3834,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CGC System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 2,    REMAIN_CUM: 3832,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CGC System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 6,    REMAIN_CUM: 3826,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CGC System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 1,    REMAIN_CUM: 3825,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CGC System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 6,    REMAIN_CUM: 3819,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CGC System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 4,    REMAIN_CUM: 3815,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CGC System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 1,    REMAIN_CUM: 3814,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CGC System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 7,    REMAIN_CUM: 3807,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CGC System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 1,    REMAIN_CUM: 3806,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CGC System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 4,    REMAIN_CUM: 3802,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CGC System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 1,    REMAIN_CUM: 3801,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CGC System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 4,    REMAIN_CUM: 3797,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CGC System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 20,   REMAIN_CUM: 3777,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CGC System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 1,    REMAIN_CUM: 3776,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CGC System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 7,    REMAIN_CUM: 3769,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CGC System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 34,   REMAIN_CUM: 3735,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CGC System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 52,   REMAIN_CUM: 3683,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CGC System Owner',        SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 80,   REMAIN_CUM: 3603,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CGC System Owner',        SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 13,   REMAIN_CUM: 3590,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CGC System Owner',        SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 15,   REMAIN_CUM: 3575,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CGC System Owner',        SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 11,   REMAIN_CUM: 3564,  TOTAL: 4454, },
    { ITEM: 'B', UNIT: 'CGC System Owner SS',     SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 0,    REMAIN_CUM: 954,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CGC System Owner SS',     SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 0,    REMAIN_CUM: 954,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CGC System Owner SS',     SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 0,    REMAIN_CUM: 954,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CGC System Owner SS',     SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 0,    REMAIN_CUM: 954,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CGC System Owner SS',     SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 0,    REMAIN_CUM: 954,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CGC System Owner SS',     SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 0,    REMAIN_CUM: 954,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CGC System Owner SS',     SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 0,    REMAIN_CUM: 954,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CGC System Owner SS',     SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 0,    REMAIN_CUM: 954,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CGC System Owner SS',     SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 0,    REMAIN_CUM: 954,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CGC System Owner SS',     SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 0,    REMAIN_CUM: 954,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CGC System Owner SS',     SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 0,    REMAIN_CUM: 954,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CGC System Owner SS',     SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 0,    REMAIN_CUM: 954,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CGC System Owner SS',     SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 0,    REMAIN_CUM: 954,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CGC System Owner SS',     SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 0,    REMAIN_CUM: 954,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CGC System Owner SS',     SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 0,    REMAIN_CUM: 954,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CGC System Owner SS',     SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 0,    REMAIN_CUM: 954,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CGC System Owner SS',     SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 1,    REMAIN_CUM: 953,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CGC System Owner SS',     SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 1,    REMAIN_CUM: 952,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CGC System Owner SS',     SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 0,    REMAIN_CUM: 952,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CGC System Owner SS',     SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 0,    REMAIN_CUM: 952,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CGC System Owner SS',     SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 6,    REMAIN_CUM: 946,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CGC System Owner SS',     SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 2,    REMAIN_CUM: 944,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CGC System Owner SS',     SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 1,    REMAIN_CUM: 943,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'CGC System Owner SS',     SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 0,    REMAIN_CUM: 943,   TOTAL: 1224, },
    { ITEM: 'B', UNIT: 'Cooling Water System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 6,    REMAIN_CUM: 725,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'Cooling Water System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 5,    REMAIN_CUM: 720,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'Cooling Water System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 3,    REMAIN_CUM: 717,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'Cooling Water System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 8,    REMAIN_CUM: 709,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'Cooling Water System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 7,    REMAIN_CUM: 702,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'Cooling Water System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 6,    REMAIN_CUM: 696,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'Cooling Water System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 4,    REMAIN_CUM: 692,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'Cooling Water System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 7,    REMAIN_CUM: 685,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'Cooling Water System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 1,    REMAIN_CUM: 684,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'Cooling Water System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 0,    REMAIN_CUM: 684,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'Cooling Water System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 2,    REMAIN_CUM: 682,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'Cooling Water System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 3,    REMAIN_CUM: 679,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'Cooling Water System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 8,    REMAIN_CUM: 671,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'Cooling Water System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 16,   REMAIN_CUM: 655,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'Cooling Water System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 6,    REMAIN_CUM: 649,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'Cooling Water System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 0,    REMAIN_CUM: 649,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'Cooling Water System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 7,    REMAIN_CUM: 642,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'Cooling Water System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 6,    REMAIN_CUM: 636,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'Cooling Water System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 16,   REMAIN_CUM: 620,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'Cooling Water System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 3,    REMAIN_CUM: 617,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'Cooling Water System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 3,    REMAIN_CUM: 614,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'Cooling Water System Owner',         SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 7,    REMAIN_CUM: 607,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'Cooling Water System Owner',         SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 8,    REMAIN_CUM: 599,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'Cooling Water System Owner',         SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 3,    REMAIN_CUM: 596,   TOTAL: 900, },
    { ITEM: 'B', UNIT: 'Co-Product System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 10,   REMAIN_CUM: 2473,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'Co-Product System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 13,   REMAIN_CUM: 2460,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'Co-Product System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 23,   REMAIN_CUM: 2437,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'Co-Product System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 46,   REMAIN_CUM: 2391,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'Co-Product System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 17,   REMAIN_CUM: 2374,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'Co-Product System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 9,    REMAIN_CUM: 2365,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'Co-Product System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 10,   REMAIN_CUM: 2355,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'Co-Product System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 13,   REMAIN_CUM: 2342,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'Co-Product System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 10,   REMAIN_CUM: 2332,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'Co-Product System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 12,   REMAIN_CUM: 2320,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'Co-Product System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 24,   REMAIN_CUM: 2296,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'Co-Product System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 32,   REMAIN_CUM: 2264,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'Co-Product System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 68,   REMAIN_CUM: 2196,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'Co-Product System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 55,   REMAIN_CUM: 2141,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'Co-Product System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 34,   REMAIN_CUM: 2107,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'Co-Product System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 28,   REMAIN_CUM: 2079,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'Co-Product System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 35,   REMAIN_CUM: 2044,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'Co-Product System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 40,   REMAIN_CUM: 2004,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'Co-Product System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 48,   REMAIN_CUM: 1956,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'Co-Product System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 66,   REMAIN_CUM: 1890,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'Co-Product System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 45,   REMAIN_CUM: 1845,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'Co-Product System Owner',         SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 15,   REMAIN_CUM: 1830,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'Co-Product System Owner',         SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 5,    REMAIN_CUM: 1825,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'Co-Product System Owner',         SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 1,    REMAIN_CUM: 1824,  TOTAL: 2860, },
    { ITEM: 'B', UNIT: 'Finishing System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 55,   REMAIN_CUM: 8303,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'Finishing System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 14,   REMAIN_CUM: 8289,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'Finishing System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 16,   REMAIN_CUM: 8273,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'Finishing System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 13,   REMAIN_CUM: 8260,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'Finishing System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 21,   REMAIN_CUM: 8239,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'Finishing System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 23,   REMAIN_CUM: 8216,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'Finishing System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 30,   REMAIN_CUM: 8186,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'Finishing System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 48,   REMAIN_CUM: 8138,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'Finishing System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 13,   REMAIN_CUM: 8125,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'Finishing System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 13,   REMAIN_CUM: 8112,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'Finishing System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 14,   REMAIN_CUM: 8098,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'Finishing System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 21,   REMAIN_CUM: 8077,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'Finishing System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 24,   REMAIN_CUM: 8053,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'Finishing System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 32,   REMAIN_CUM: 8021,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'Finishing System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 32,   REMAIN_CUM: 7989,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'Finishing System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 83,   REMAIN_CUM: 7906,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'Finishing System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 45,   REMAIN_CUM: 7861,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'Finishing System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 55,   REMAIN_CUM: 7806,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'Finishing System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 25,   REMAIN_CUM: 7781,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'Finishing System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 16,   REMAIN_CUM: 7765,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'Finishing System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 21,   REMAIN_CUM: 7744,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'Finishing System Owner',      SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 10,   REMAIN_CUM: 7734,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'Finishing System Owner',      SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 35,   REMAIN_CUM: 7699,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'Finishing System Owner',      SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 24,   REMAIN_CUM: 7675,  TOTAL: 9245, },
    { ITEM: 'B', UNIT: 'Flare System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 5,    REMAIN_CUM: 2061,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'Flare System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 5,    REMAIN_CUM: 2056,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'Flare System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 8,    REMAIN_CUM: 2048,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'Flare System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 11,   REMAIN_CUM: 2037,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'Flare System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 9,    REMAIN_CUM: 2028,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'Flare System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 3,    REMAIN_CUM: 2025,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'Flare System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 9,    REMAIN_CUM: 2016,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'Flare System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 16,   REMAIN_CUM: 2000,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'Flare System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 21,   REMAIN_CUM: 1979,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'Flare System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 17,   REMAIN_CUM: 1962,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'Flare System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 4,    REMAIN_CUM: 1958,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'Flare System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 3,    REMAIN_CUM: 1955,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'Flare System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 8,    REMAIN_CUM: 1947,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'Flare System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 10,   REMAIN_CUM: 1937,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'Flare System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 11,   REMAIN_CUM: 1926,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'Flare System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 26,   REMAIN_CUM: 1900,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'Flare System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 12,   REMAIN_CUM: 1888,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'Flare System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 20,   REMAIN_CUM: 1868,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'Flare System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 10,   REMAIN_CUM: 1858,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'Flare System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 6,    REMAIN_CUM: 1852,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'Flare System Owner',      SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 1,    REMAIN_CUM: 1851,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'Flare System Owner',      SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 1,    REMAIN_CUM: 1850,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'Flare System Owner',      SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 9,    REMAIN_CUM: 1841,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'Flare System Owner',      SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 10,   REMAIN_CUM: 1831,  TOTAL: 2397, },
    { ITEM: 'B', UNIT: 'GPPE System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 8,    REMAIN_CUM: 1254,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'GPPE System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 1,    REMAIN_CUM: 1253,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'GPPE System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 6,    REMAIN_CUM: 1247,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'GPPE System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 3,    REMAIN_CUM: 1244,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'GPPE System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 5,    REMAIN_CUM: 1239,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'GPPE System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 3,    REMAIN_CUM: 1236,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'GPPE System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 6,    REMAIN_CUM: 1230,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'GPPE System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 7,    REMAIN_CUM: 1223,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'GPPE System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 9,    REMAIN_CUM: 1214,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'GPPE System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 9,    REMAIN_CUM: 1205,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'GPPE System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 26,   REMAIN_CUM: 1179,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'GPPE System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 18,   REMAIN_CUM: 1161,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'GPPE System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 15,   REMAIN_CUM: 1146,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'GPPE System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 6,    REMAIN_CUM: 1140,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'GPPE System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 4,    REMAIN_CUM: 1136,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'GPPE System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 5,    REMAIN_CUM: 1131,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'GPPE System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 8,    REMAIN_CUM: 1123,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'GPPE System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 13,   REMAIN_CUM: 1110,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'GPPE System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 11,   REMAIN_CUM: 1099,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'GPPE System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 10,   REMAIN_CUM: 1089,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'GPPE System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 1,    REMAIN_CUM: 1088,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'GPPE System Owner',          SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 1,    REMAIN_CUM: 1087,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'GPPE System Owner',          SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 8,    REMAIN_CUM: 1079,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'GPPE System Owner',          SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 15,   REMAIN_CUM: 1064,  TOTAL: 1576, },
    { ITEM: 'B', UNIT: 'LDPE System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 0,    REMAIN_CUM: 154,   TOTAL: 160, },
    { ITEM: 'B', UNIT: 'LDPE System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 0,    REMAIN_CUM: 154,   TOTAL: 160, },
    { ITEM: 'B', UNIT: 'LDPE System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 10,   REMAIN_CUM: 144,   TOTAL: 160, },
    { ITEM: 'B', UNIT: 'LDPE System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 3,    REMAIN_CUM: 141,   TOTAL: 160, },
    { ITEM: 'B', UNIT: 'LDPE System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 2,    REMAIN_CUM: 139,   TOTAL: 160, },
    { ITEM: 'B', UNIT: 'LDPE System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 6,    REMAIN_CUM: 133,   TOTAL: 160, },
    { ITEM: 'B', UNIT: 'LDPE System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 16,   REMAIN_CUM: 117,   TOTAL: 160, },
    { ITEM: 'B', UNIT: 'LDPE System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 4,    REMAIN_CUM: 113,   TOTAL: 160, },
    { ITEM: 'B', UNIT: 'LDPE System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 9,    REMAIN_CUM: 104,   TOTAL: 160, },
    { ITEM: 'B', UNIT: 'LDPE System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 22,   REMAIN_CUM: 82,    TOTAL: 160, },
    { ITEM: 'B', UNIT: 'LDPE System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 2,    REMAIN_CUM: 80,    TOTAL: 160, },
    { ITEM: 'B', UNIT: 'LDPE System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 1,    REMAIN_CUM: 79,    TOTAL: 160, },
    { ITEM: 'B', UNIT: 'LDPE System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 0,    REMAIN_CUM: 79,    TOTAL: 160, },
    { ITEM: 'B', UNIT: 'LDPE System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 0,    REMAIN_CUM: 79,    TOTAL: 160, },
    { ITEM: 'B', UNIT: 'LDPE System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 12,   REMAIN_CUM: 67,    TOTAL: 160, },
    { ITEM: 'B', UNIT: 'LDPE System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 10,   REMAIN_CUM: 57,    TOTAL: 160, },
    { ITEM: 'B', UNIT: 'LDPE System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 2,    REMAIN_CUM: 55,    TOTAL: 160, },
    { ITEM: 'B', UNIT: 'LDPE System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 0,    REMAIN_CUM: 55,    TOTAL: 160, },
    { ITEM: 'B', UNIT: 'LDPE System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 12,   REMAIN_CUM: 43,    TOTAL: 160, },
    { ITEM: 'B', UNIT: 'LDPE System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 0,    REMAIN_CUM: 43,    TOTAL: 160, },
    { ITEM: 'B', UNIT: 'LDPE System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 0,    REMAIN_CUM: 43,    TOTAL: 160, },
    { ITEM: 'B', UNIT: 'LDPE System Owner',     SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 0,    REMAIN_CUM: 43,    TOTAL: 160, },
    { ITEM: 'B', UNIT: 'LDPE System Owner',     SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 2,    REMAIN_CUM: 41,    TOTAL: 160, },
    { ITEM: 'B', UNIT: 'LDPE System Owner',     SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 0,    REMAIN_CUM: 41,    TOTAL: 160, },
    { ITEM: 'B', UNIT: 'P1 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 0,    REMAIN_CUM: 115,   TOTAL: 121, },
    { ITEM: 'B', UNIT: 'P1 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 0,    REMAIN_CUM: 115,   TOTAL: 121, },
    { ITEM: 'B', UNIT: 'P1 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 0,    REMAIN_CUM: 115,   TOTAL: 121, },
    { ITEM: 'B', UNIT: 'P1 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 0,    REMAIN_CUM: 115,   TOTAL: 121, },
    { ITEM: 'B', UNIT: 'P1 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 2,    REMAIN_CUM: 113,   TOTAL: 121, },
    { ITEM: 'B', UNIT: 'P1 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 0,    REMAIN_CUM: 113,   TOTAL: 121, },
    { ITEM: 'B', UNIT: 'P1 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 1,    REMAIN_CUM: 112,   TOTAL: 121, },
    { ITEM: 'B', UNIT: 'P1 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 0,    REMAIN_CUM: 112,   TOTAL: 121, },
    { ITEM: 'B', UNIT: 'P1 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 0,    REMAIN_CUM: 112,   TOTAL: 121, },
    { ITEM: 'B', UNIT: 'P1 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 5,    REMAIN_CUM: 107,   TOTAL: 121, },
    { ITEM: 'B', UNIT: 'P1 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 0,    REMAIN_CUM: 107,   TOTAL: 121, },
    { ITEM: 'B', UNIT: 'P1 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 15,   REMAIN_CUM: 92,    TOTAL: 121, },
    { ITEM: 'B', UNIT: 'P1 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 30,   REMAIN_CUM: 62,    TOTAL: 121, },
    { ITEM: 'B', UNIT: 'P1 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 0,    REMAIN_CUM: 62,    TOTAL: 121, },
    { ITEM: 'B', UNIT: 'P1 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 0,    REMAIN_CUM: 62,    TOTAL: 121, },
    { ITEM: 'B', UNIT: 'P1 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 0,    REMAIN_CUM: 62,    TOTAL: 121, },
    { ITEM: 'B', UNIT: 'P1 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 0,    REMAIN_CUM: 62,    TOTAL: 121, },
    { ITEM: 'B', UNIT: 'P1 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 0,    REMAIN_CUM: 62,    TOTAL: 121, },
    { ITEM: 'B', UNIT: 'P1 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 11,   REMAIN_CUM: 51,    TOTAL: 121, },
    { ITEM: 'B', UNIT: 'P1 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 0,    REMAIN_CUM: 51,    TOTAL: 121, },
    { ITEM: 'B', UNIT: 'P1 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 0,    REMAIN_CUM: 51,    TOTAL: 121, },
    { ITEM: 'B', UNIT: 'P1 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 0,    REMAIN_CUM: 51,    TOTAL: 121, },
    { ITEM: 'B', UNIT: 'P1 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 0,    REMAIN_CUM: 51,    TOTAL: 121, },
    { ITEM: 'B', UNIT: 'P1 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 0,    REMAIN_CUM: 51,    TOTAL: 121, },
    { ITEM: 'B', UNIT: 'P2 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 0,    REMAIN_CUM: 98,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'P2 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 0,    REMAIN_CUM: 98,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'P2 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 0,    REMAIN_CUM: 98,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'P2 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 0,    REMAIN_CUM: 98,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'P2 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 1,    REMAIN_CUM: 97,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'P2 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 1,    REMAIN_CUM: 96,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'P2 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 0,    REMAIN_CUM: 96,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'P2 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 0,    REMAIN_CUM: 96,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'P2 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 0,    REMAIN_CUM: 96,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'P2 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 0,    REMAIN_CUM: 96,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'P2 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 0,    REMAIN_CUM: 96,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'P2 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 4,    REMAIN_CUM: 92,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'P2 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 0,    REMAIN_CUM: 92,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'P2 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 0,    REMAIN_CUM: 92,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'P2 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 0,    REMAIN_CUM: 92,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'P2 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 0,    REMAIN_CUM: 92,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'P2 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 1,    REMAIN_CUM: 91,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'P2 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 4,    REMAIN_CUM: 87,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'P2 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 0,    REMAIN_CUM: 87,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'P2 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 0,    REMAIN_CUM: 87,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'P2 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 0,    REMAIN_CUM: 87,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'P2 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 2,    REMAIN_CUM: 85,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'P2 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 0,    REMAIN_CUM: 85,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'P2 Building System Owner',     SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 0,    REMAIN_CUM: 85,    TOTAL: 98, },
    { ITEM: 'B', UNIT: 'P3 Building System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 2,    REMAIN_CUM: 1416,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'P3 Building System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 0,    REMAIN_CUM: 1416,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'P3 Building System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 1,    REMAIN_CUM: 1415,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'P3 Building System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 1,    REMAIN_CUM: 1414,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'P3 Building System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 11,   REMAIN_CUM: 1403,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'P3 Building System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 4,    REMAIN_CUM: 1399,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'P3 Building System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 2,    REMAIN_CUM: 1397,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'P3 Building System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 5,    REMAIN_CUM: 1392,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'P3 Building System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 6,    REMAIN_CUM: 1386,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'P3 Building System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 5,    REMAIN_CUM: 1381,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'P3 Building System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 13,   REMAIN_CUM: 1368,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'P3 Building System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 16,   REMAIN_CUM: 1352,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'P3 Building System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 23,   REMAIN_CUM: 1329,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'P3 Building System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 16,   REMAIN_CUM: 1313,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'P3 Building System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 33,   REMAIN_CUM: 1280,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'P3 Building System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 50,   REMAIN_CUM: 1230,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'P3 Building System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 36,   REMAIN_CUM: 1194,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'P3 Building System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 59,   REMAIN_CUM: 1135,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'P3 Building System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 27,   REMAIN_CUM: 1108,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'P3 Building System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 9,    REMAIN_CUM: 1099,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'P3 Building System Owner',         SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 7,    REMAIN_CUM: 1092,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'P3 Building System Owner',         SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 1,    REMAIN_CUM: 1091,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'P3 Building System Owner',         SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 1,    REMAIN_CUM: 1090,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'P3 Building System Owner',         SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 1,    REMAIN_CUM: 1089,  TOTAL: 1584, },
    { ITEM: 'B', UNIT: 'PBL / Utility System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 0,    REMAIN_CUM: 537,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'PBL / Utility System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 0,    REMAIN_CUM: 537,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'PBL / Utility System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 0,    REMAIN_CUM: 537,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'PBL / Utility System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 0,    REMAIN_CUM: 537,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'PBL / Utility System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 0,    REMAIN_CUM: 537,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'PBL / Utility System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 0,    REMAIN_CUM: 537,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'PBL / Utility System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 6,    REMAIN_CUM: 531,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'PBL / Utility System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 25,   REMAIN_CUM: 506,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'PBL / Utility System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 10,   REMAIN_CUM: 496,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'PBL / Utility System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 20,   REMAIN_CUM: 476,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'PBL / Utility System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 32,   REMAIN_CUM: 444,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'PBL / Utility System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 44,   REMAIN_CUM: 400,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'PBL / Utility System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 75,   REMAIN_CUM: 325,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'PBL / Utility System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 4,    REMAIN_CUM: 321,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'PBL / Utility System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 0,    REMAIN_CUM: 321,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'PBL / Utility System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 1,    REMAIN_CUM: 320,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'PBL / Utility System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 1,    REMAIN_CUM: 319,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'PBL / Utility System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 0,    REMAIN_CUM: 319,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'PBL / Utility System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 0,    REMAIN_CUM: 319,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'PBL / Utility System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 0,    REMAIN_CUM: 319,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'PBL / Utility System Owner',       SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 0,    REMAIN_CUM: 319,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'PBL / Utility System Owner',       SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 0,    REMAIN_CUM: 319,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'PBL / Utility System Owner',       SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 0,    REMAIN_CUM: 319,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'PBL / Utility System Owner',       SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 4,    REMAIN_CUM: 315,   TOTAL: 625, },
    { ITEM: 'B', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 0,    REMAIN_CUM: 78,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 0,    REMAIN_CUM: 78,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 0,    REMAIN_CUM: 78,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 0,    REMAIN_CUM: 78,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 0,    REMAIN_CUM: 78,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 5,    REMAIN_CUM: 73,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 5,    REMAIN_CUM: 68,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 0,    REMAIN_CUM: 68,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 0,    REMAIN_CUM: 68,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 9,    REMAIN_CUM: 59,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 8,    REMAIN_CUM: 51,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 0,    REMAIN_CUM: 51,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 0,    REMAIN_CUM: 51,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 10,   REMAIN_CUM: 41,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 0,    REMAIN_CUM: 41,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 1,    REMAIN_CUM: 40,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 0,    REMAIN_CUM: 40,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 0,    REMAIN_CUM: 40,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 0,    REMAIN_CUM: 40,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 0,    REMAIN_CUM: 40,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 1,    REMAIN_CUM: 39,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 0,    REMAIN_CUM: 39,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 3,    REMAIN_CUM: 36,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'PP System Owner', SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 0,    REMAIN_CUM: 36,    TOTAL: 78, },
    { ITEM: 'B', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 0,    REMAIN_CUM: 81,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 0,    REMAIN_CUM: 81,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 0,    REMAIN_CUM: 81,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 0,    REMAIN_CUM: 81,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 11,   REMAIN_CUM: 70,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 0,    REMAIN_CUM: 70,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 7,    REMAIN_CUM: 63,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 0,    REMAIN_CUM: 63,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 0,    REMAIN_CUM: 63,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 6,    REMAIN_CUM: 57,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 9,    REMAIN_CUM: 48,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 0,    REMAIN_CUM: 48,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 0,    REMAIN_CUM: 48,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 13,   REMAIN_CUM: 35,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 0,    REMAIN_CUM: 35,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 5,    REMAIN_CUM: 30,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 0,    REMAIN_CUM: 30,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 0,    REMAIN_CUM: 30,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 0,    REMAIN_CUM: 30,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 1,    REMAIN_CUM: 29,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 0,    REMAIN_CUM: 29,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 0,    REMAIN_CUM: 29,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 3,    REMAIN_CUM: 26,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'Tank Farm System Owner', SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 0,    REMAIN_CUM: 26,    TOTAL: 81, },
    { ITEM: 'B', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 1,    REMAIN_CUM: 945,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 1,    REMAIN_CUM: 944,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 0,    REMAIN_CUM: 944,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 0,    REMAIN_CUM: 944,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 0,    REMAIN_CUM: 944,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 0,    REMAIN_CUM: 944,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 1,    REMAIN_CUM: 943,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 0,    REMAIN_CUM: 943,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 1,    REMAIN_CUM: 942,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 2,    REMAIN_CUM: 940,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 2,    REMAIN_CUM: 938,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 0,    REMAIN_CUM: 938,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 5,    REMAIN_CUM: 933,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 1,    REMAIN_CUM: 932,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 0,    REMAIN_CUM: 932,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 0,    REMAIN_CUM: 932,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 0,    REMAIN_CUM: 932,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 6,    REMAIN_CUM: 926,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 5,    REMAIN_CUM: 921,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 9,    REMAIN_CUM: 912,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 2,    REMAIN_CUM: 910,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 0,    REMAIN_CUM: 910,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 0,    REMAIN_CUM: 910,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'Telecom Overall System Owner',    SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 0,    REMAIN_CUM: 910,   TOTAL: 1100, },
    { ITEM: 'B', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 1,    REMAIN_CUM: 245,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 1,    REMAIN_CUM: 244,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 0,    REMAIN_CUM: 244,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 0,    REMAIN_CUM: 244,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 0,    REMAIN_CUM: 244,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 0,    REMAIN_CUM: 244,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 0,    REMAIN_CUM: 244,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 0,    REMAIN_CUM: 244,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 1,    REMAIN_CUM: 243,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 0,    REMAIN_CUM: 243,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 0,    REMAIN_CUM: 243,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 1,    REMAIN_CUM: 242,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 1,    REMAIN_CUM: 241,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 0,    REMAIN_CUM: 241,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 1,    REMAIN_CUM: 240,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 1,    REMAIN_CUM: 239,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 1,    REMAIN_CUM: 238,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 0,    REMAIN_CUM: 238,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 2,    REMAIN_CUM: 236,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 3,    REMAIN_CUM: 233,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 0,    REMAIN_CUM: 233,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 0,    REMAIN_CUM: 233,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 0,    REMAIN_CUM: 233,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'Utility System Owner',    SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 0,    REMAIN_CUM: 233,   TOTAL: 315, },
    { ITEM: 'B', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-08-11', CLOSED_WK: 188,  REMAIN_CUM: 28981, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-08-18', CLOSED_WK: 119,  REMAIN_CUM: 28862, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-08-25', CLOSED_WK: 167,  REMAIN_CUM: 28695, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-09-01', CLOSED_WK: 201,  REMAIN_CUM: 28494, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-09-08', CLOSED_WK: 245,  REMAIN_CUM: 28249, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-09-15', CLOSED_WK: 108,  REMAIN_CUM: 28141, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-09-22', CLOSED_WK: 140,  REMAIN_CUM: 28001, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-09-29', CLOSED_WK: 190,  REMAIN_CUM: 27811, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-10-06', CLOSED_WK: 142,  REMAIN_CUM: 27669, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-10-13', CLOSED_WK: 157,  REMAIN_CUM: 27512, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-10-20', CLOSED_WK: 196,  REMAIN_CUM: 27316, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-10-27', CLOSED_WK: 217,  REMAIN_CUM: 27099, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-11-03', CLOSED_WK: 307,  REMAIN_CUM: 26792, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-11-10', CLOSED_WK: 292,  REMAIN_CUM: 26500, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-11-17', CLOSED_WK: 182,  REMAIN_CUM: 26318, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-11-24', CLOSED_WK: 291,  REMAIN_CUM: 26027, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-12-01', CLOSED_WK: 203,  REMAIN_CUM: 25824, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-12-08', CLOSED_WK: 296,  REMAIN_CUM: 25528, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-12-15', CLOSED_WK: 293,  REMAIN_CUM: 25235, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-12-22', CLOSED_WK: 251,  REMAIN_CUM: 24984, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2023-12-29', CLOSED_WK: 262,  REMAIN_CUM: 24722, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2024-01-05', CLOSED_WK: 60,   REMAIN_CUM: 24662, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2024-01-12', CLOSED_WK: 106,  REMAIN_CUM: 24556, TOTAL: 35784, },
    { ITEM: 'B', UNIT: 'Water System Owner',          SUBSYS_NO: 'OV', CDATE: '2024-01-19', CLOSED_WK: 85,   REMAIN_CUM: 24471, TOTAL: 35784, },
    ]

  },
}