import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1600,
      CanvasHeight: 1120,
      CanvasBorderWeight: 1,
      CanvasBorderColor: '#f5f5f5',
      CanvasFillColor: '#f5f5f5',
      CanvasOpacity: 1,

      CanvasChartX: 15,
      CanvasChartY: 15,
      CanvasChartWidth: 1560,
      CanvasChartHeight: 1080,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#f5f5f5',
      CanvasChartFillColor: '#f5f5f5',
      CanvasChartOpacity: 1,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
      CanvasChartAreaAllowed: 'Y'
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: [
        {
          query: "SELECT 'Datasource: Shop Drawing Index 04-Oct-20' TITLE", 
          text: 'Datasource: Shop Drawing Index 04-Mar-22',
          x: 10,
          y: 10,
          font: 'roboto',
          style: 'regular',
          size: 12,
          color: '#757575',
          align: 'start'
        },
      ]
    },
    Queries: {
      // Status of Blocks
      SQL1:[
        {TITLE1:"Construction Progress by Discipline",TITLE2:"",PACKAGE:"P1",VISIBLE:"false"},
        {TITLE1:"Construction Progress by Discipline",TITLE2:"",PACKAGE:"P2",VISIBLE:"true"},
        {TITLE1:"Construction Progress by Discipline",TITLE2:"sasasa",PACKAGE:"P3",VISIBLE:"true"}],
      SQL2:[
        {ITEM:"Piling",PACKAGE:"P2",WEIGHT:1.5,PLAN_CUM:100,ACTUAL_CUM:100,CUM_VAR:0,PLAN_MTH:0,ACTUAL_MTH:0,MONTH_VAR:0,DISC_SEQ:10},
        {ITEM:"Piling",PACKAGE:"P1",WEIGHT:6.1,PLAN_CUM:100,ACTUAL_CUM:100,CUM_VAR:0,PLAN_MTH:0,ACTUAL_MTH:0,MONTH_VAR:0,DISC_SEQ:10},
        {ITEM:"Piling",PACKAGE:"P3",WEIGHT:18.1,PLAN_CUM:100,ACTUAL_CUM:100,CUM_VAR:0,PLAN_MTH:0,ACTUAL_MTH:0,MONTH_VAR:0,DISC_SEQ:10},
        {ITEM:"UG",PACKAGE:"P3",WEIGHT:6,PLAN_CUM:94.5,ACTUAL_CUM:92.6,CUM_VAR:-1.9,PLAN_MTH:1.8,ACTUAL_MTH:1.6,MONTH_VAR:-0.2,DISC_SEQ:11},
        {ITEM:"UG",PACKAGE:"P2",WEIGHT:2.5,PLAN_CUM:97.4,ACTUAL_CUM:62.9,CUM_VAR:-34.5,PLAN_MTH:8.9,ACTUAL_MTH:4.9,MONTH_VAR:-4,DISC_SEQ:11},
        {ITEM:"UG",PACKAGE:"P1",WEIGHT:1.9,PLAN_CUM:100,ACTUAL_CUM:98.8,CUM_VAR:-1.2,PLAN_MTH:0,ACTUAL_MTH:0.5,MONTH_VAR:0.5,DISC_SEQ:11},
        {ITEM:"FireProofing",PACKAGE:"P3",WEIGHT:1,PLAN_CUM:10.8,ACTUAL_CUM:0,CUM_VAR:-10.8,PLAN_MTH:7,ACTUAL_MTH:0,MONTH_VAR:-7,DISC_SEQ:12},
        {ITEM:"FireProofing",PACKAGE:"P1",WEIGHT:2.4,PLAN_CUM:0.1,ACTUAL_CUM:0,CUM_VAR:-0.1,PLAN_MTH:0.1,ACTUAL_MTH:0,MONTH_VAR:-0.1,DISC_SEQ:12},
        {ITEM:"FireProofing",PACKAGE:"P2",WEIGHT:0.7,PLAN_CUM:0,ACTUAL_CUM:0,CUM_VAR:0,PLAN_MTH:0,ACTUAL_MTH:0,MONTH_VAR:0,DISC_SEQ:12},
        {ITEM:"Civil",PACKAGE:"P3",WEIGHT:36.2,PLAN_CUM:88.5,ACTUAL_CUM:89,CUM_VAR:0.5,PLAN_MTH:4.8,ACTUAL_MTH:4.2,MONTH_VAR:-0.6,DISC_SEQ:15},
        {ITEM:"Civil",PACKAGE:"P2",WEIGHT:58.5,PLAN_CUM:77.7,ACTUAL_CUM:73,CUM_VAR:-4.7,PLAN_MTH:8,ACTUAL_MTH:7.6,MONTH_VAR:-0.4,DISC_SEQ:15},
        {ITEM:"Civil",PACKAGE:"P1",WEIGHT:23.5,PLAN_CUM:94.8,ACTUAL_CUM:90.1,CUM_VAR:-4.7,PLAN_MTH:4.7,ACTUAL_MTH:3.2,MONTH_VAR:-1.5,DISC_SEQ:15},
        {ITEM:"Decoration",PACKAGE:"P3",WEIGHT:2.1,PLAN_CUM:16.9,ACTUAL_CUM:25.8,CUM_VAR:8.9,PLAN_MTH:2,ACTUAL_MTH:-12.8,MONTH_VAR:-14.8,DISC_SEQ:18},
        {ITEM:"Steel",PACKAGE:"P1",WEIGHT:4.5,PLAN_CUM:48.9,ACTUAL_CUM:55.4,CUM_VAR:6.5,PLAN_MTH:12.8,ACTUAL_MTH:20.1,MONTH_VAR:7.3,DISC_SEQ:20},
        {ITEM:"Steel",PACKAGE:"P2",WEIGHT:2.1,PLAN_CUM:40.3,ACTUAL_CUM:26.6,CUM_VAR:-13.7,PLAN_MTH:13.7,ACTUAL_MTH:14.1,MONTH_VAR:0.4,DISC_SEQ:20},
        {ITEM:"Steel",PACKAGE:"P3",WEIGHT:3,PLAN_CUM:87,ACTUAL_CUM:71,CUM_VAR:-16,PLAN_MTH:15.5,ACTUAL_MTH:11.2,MONTH_VAR:-4.3,DISC_SEQ:20},
        {ITEM:"Equipment",PACKAGE:"P3",WEIGHT:10.6,PLAN_CUM:41.8,ACTUAL_CUM:39.1,CUM_VAR:-2.7,PLAN_MTH:12.4,ACTUAL_MTH:9.7,MONTH_VAR:-2.7,DISC_SEQ:30},
        {ITEM:"Equipment",PACKAGE:"P2",WEIGHT:6.4,PLAN_CUM:8.9,ACTUAL_CUM:7.7,CUM_VAR:-1.2,PLAN_MTH:6.5,ACTUAL_MTH:2,MONTH_VAR:-4.5,DISC_SEQ:30},
        {ITEM:"Equipment",PACKAGE:"P1",WEIGHT:10.8,PLAN_CUM:44.5,ACTUAL_CUM:42.8,CUM_VAR:-1.7,PLAN_MTH:8.4,ACTUAL_MTH:6.5,MONTH_VAR:-1.9,DISC_SEQ:30},
        {ITEM:"Piping",PACKAGE:"P1",WEIGHT:27.3,PLAN_CUM:4.8,ACTUAL_CUM:3.4,CUM_VAR:-1.4,PLAN_MTH:3.7,ACTUAL_MTH:2.5,MONTH_VAR:-1.2,DISC_SEQ:40},
        {ITEM:"Piping",PACKAGE:"P3",WEIGHT:6.8,PLAN_CUM:17.2,ACTUAL_CUM:4,CUM_VAR:-13.2,PLAN_MTH:9.6,ACTUAL_MTH:3,MONTH_VAR:-6.6,DISC_SEQ:40},
        {ITEM:"Piping",PACKAGE:"P2",WEIGHT:7.1,PLAN_CUM:10.3,ACTUAL_CUM:0.7,CUM_VAR:-9.6,PLAN_MTH:4.9,ACTUAL_MTH:0.7,MONTH_VAR:-4.2,DISC_SEQ:40},
        {ITEM:"Electrical",PACKAGE:"P3",WEIGHT:4.2,PLAN_CUM:40.7,ACTUAL_CUM:24.2,CUM_VAR:-16.5,PLAN_MTH:8.5,ACTUAL_MTH:5.2,MONTH_VAR:-3.3,DISC_SEQ:50},
        {ITEM:"Electrical",PACKAGE:"P2",WEIGHT:4.4,PLAN_CUM:23.3,ACTUAL_CUM:13.4,CUM_VAR:-9.9,PLAN_MTH:6.3,ACTUAL_MTH:3.8,MONTH_VAR:-2.5,DISC_SEQ:50},
        {ITEM:"Electrical",PACKAGE:"P1",WEIGHT:5.1,PLAN_CUM:21.6,ACTUAL_CUM:26.7,CUM_VAR:5.1,PLAN_MTH:1.8,ACTUAL_MTH:2,MONTH_VAR:0.2,DISC_SEQ:50},
        {ITEM:"Telecom",PACKAGE:"P1",WEIGHT:0.4,PLAN_CUM:0.7,ACTUAL_CUM:0,CUM_VAR:-0.7,PLAN_MTH:0.1,ACTUAL_MTH:0,MONTH_VAR:-0.1,DISC_SEQ:51},
        {ITEM:"Telecom",PACKAGE:"P2",WEIGHT:0.8,PLAN_CUM:0.9,ACTUAL_CUM:0,CUM_VAR:-0.9,PLAN_MTH:0.5,ACTUAL_MTH:0,MONTH_VAR:-0.5,DISC_SEQ:51},
        {ITEM:"Telecom",PACKAGE:"P3",WEIGHT:1,PLAN_CUM:32.8,ACTUAL_CUM:15,CUM_VAR:-17.8,PLAN_MTH:3.7,ACTUAL_MTH:0.6,MONTH_VAR:-3.1,DISC_SEQ:51},
        {ITEM:"Instrument",PACKAGE:"P3",WEIGHT:1.6,PLAN_CUM:8.2,ACTUAL_CUM:1.8,CUM_VAR:-6.4,PLAN_MTH:3,ACTUAL_MTH:1.1,MONTH_VAR:-1.9,DISC_SEQ:60},
        {ITEM:"Coating",PACKAGE:"P2",WEIGHT:2.9,PLAN_CUM:34.4,ACTUAL_CUM:44,CUM_VAR:9.6,PLAN_MTH:8.8,ACTUAL_MTH:12.5,MONTH_VAR:3.7,DISC_SEQ:60},
        {ITEM:"Instrument",PACKAGE:"P2",WEIGHT:5.3,PLAN_CUM:0.5,ACTUAL_CUM:0,CUM_VAR:-0.5,PLAN_MTH:0.4,ACTUAL_MTH:0,MONTH_VAR:-0.4,DISC_SEQ:60},
        {ITEM:"Coating",PACKAGE:"P1",WEIGHT:6.8,PLAN_CUM:5.3,ACTUAL_CUM:18.4,CUM_VAR:13.1,PLAN_MTH:2.5,ACTUAL_MTH:10.8,MONTH_VAR:8.3,DISC_SEQ:60},
        {ITEM:"Instrument",PACKAGE:"P1",WEIGHT:7.4,PLAN_CUM:0.3,ACTUAL_CUM:0,CUM_VAR:-0.3,PLAN_MTH:0.3,ACTUAL_MTH:0,MONTH_VAR:-0.3,DISC_SEQ:60},
        {ITEM:"Coating",PACKAGE:"P3",WEIGHT:4.5,PLAN_CUM:39.4,ACTUAL_CUM:24.6,CUM_VAR:-14.8,PLAN_MTH:16.7,ACTUAL_MTH:10.7,MONTH_VAR:-6,DISC_SEQ:60},
        {ITEM:"Insulation",PACKAGE:"P3",WEIGHT:0.7,PLAN_CUM:0.7,ACTUAL_CUM:0,CUM_VAR:-0.7,PLAN_MTH:0.5,ACTUAL_MTH:0,MONTH_VAR:-0.5,DISC_SEQ:70},
        {ITEM:"Insulation",PACKAGE:"P1",WEIGHT:3.7,PLAN_CUM:0,ACTUAL_CUM:0,CUM_VAR:0,PLAN_MTH:0,ACTUAL_MTH:0,MONTH_VAR:0,DISC_SEQ:70},
        {ITEM:"Insulation",PACKAGE:"P2",WEIGHT:0.6,PLAN_CUM:0,ACTUAL_CUM:0,CUM_VAR:0,PLAN_MTH:0,ACTUAL_MTH:0,MONTH_VAR:0,DISC_SEQ:70},
        {ITEM:"Enviroment",PACKAGE:"P2",WEIGHT:0.3,PLAN_CUM:0.9,ACTUAL_CUM:0,CUM_VAR:-0.9,PLAN_MTH:0.9,ACTUAL_MTH:0,MONTH_VAR:-0.9,DISC_SEQ:90},
        {ITEM:"VendorInstallation",PACKAGE:"P2",WEIGHT:6.8,PLAN_CUM:0.1,ACTUAL_CUM:2.8,CUM_VAR:2.7,PLAN_MTH:0.1,ACTUAL_MTH:2.8,MONTH_VAR:2.7,DISC_SEQ:95},
        {ITEM:"Overall",PACKAGE:"P3",WEIGHT:95.8,PLAN_CUM:68.4,ACTUAL_CUM:65.3,CUM_VAR:-3.1,PLAN_MTH:5.6,ACTUAL_MTH:3.6,MONTH_VAR:-2,DISC_SEQ:99},
        {ITEM:"Overall",PACKAGE:"P2",WEIGHT:100,PLAN_CUM:53.6,ACTUAL_CUM:48.9,CUM_VAR:-4.7,PLAN_MTH:6.6,ACTUAL_MTH:5.7,MONTH_VAR:-0.9,DISC_SEQ:99},
        {ITEM:"Overall",PACKAGE:"P1",WEIGHT:100,PLAN_CUM:40.1,ACTUAL_CUM:39.8,CUM_VAR:-0.3,PLAN_MTH:3.9,ACTUAL_MTH:3.9,MONTH_VAR:0,DISC_SEQ:99}
        // { LV1: 'HCCP', LV2: 'P3', LV3: 'OVERALL', DISC: 'Painting', PLAN: 78.68, ACTUAL: 100, DELTA: 5, },
        // { LV1: 'HCCP', LV2: 'OVERALL', LV3: 'OVERALL', DISC: 'Instrument', PLAN: 100, ACTUAL: 97.68, DELTA: -2.22, },
        // { LV1: 'HCCP', LV2: 'P1', LV3: 'OVERALL', DISC: 'Insulation', PLAN: 93.74, ACTUAL: 100, DELTA: 0.13, },
        // { LV1: 'HCCP', LV2: 'P2', LV3: 'OVERALL', DISC: 'Mechanical', PLAN: 100, ACTUAL: 99.2, DELTA: -0.79, },
        // { LV1: 'HCCP', LV2: 'P1', LV3: 'OR', DISC: 'Electrical', PLAN: 100, ACTUAL: 0, DELTA: 0, },
        // { LV1: 'HCCP', LV2: 'P1', LV3: 'BD', DISC: 'Fire & Safety', PLAN: 99.98, ACTUAL: 76.17, DELTA: -23.02, },
        // { LV1: 'HCCP', LV2: 'P1', LV3: 'DIB', DISC: 'Painting', PLAN: 99.45, ACTUAL: 68.59, DELTA: -29.05, },
        // { LV1: 'HCCP', LV2: 'P1', LV3: 'GHU', DISC: 'Structure', PLAN: 100, ACTUAL: 0, DELTA: -0.48, },
        // { LV1: 'HCCP', LV2: 'P1', LV3: 'BTX', DISC: 'Painting', PLAN: 100, ACTUAL: 84.09, DELTA: -15.42, },
        // { LV1: 'HCCP', LV2: 'P1', LV3: 'BOILER', DISC: 'Painting', PLAN: 97.99, ACTUAL: 0, DELTA: -5.75, },
        // { LV1: 'HCCP', LV2: 'P1', LV3: 'DW', DISC: 'Piping', PLAN: 100, ACTUAL: 98.27, DELTA: -1.72, },
        // { LV1: 'HCCP', LV2: 'P2', LV3: 'GPPE', DISC: 'Electrical', PLAN: 100, ACTUAL: 0, DELTA: -100, },
        // { LV1: 'HCCP', LV2: 'P2', LV3: 'LDPE', DISC: 'Fire & Safety', PLAN: 100, ACTUAL: 0, DELTA: 0, },
        // { LV1: 'HCCP', LV2: 'P2', LV3: 'PP', DISC: 'HVAC', PLAN: 96.18, ACTUAL: 89.51, DELTA: -10.47, },
        // { LV1: 'HCCP', LV2: 'P2', LV3: 'PBL', DISC: 'Piping', PLAN: 33.82, ACTUAL: 0, DELTA: -19.88, },
        // { LV1: 'HCCP', LV2: 'P3', LV3: 'OGL', DISC: 'Structure', PLAN: 100, ACTUAL: 95.64, DELTA: -3.87, },
        // { LV1: 'HCCP', LV2: 'P3', LV3: 'MF', DISC: 'HVAC', PLAN: 7.68, ACTUAL: 96.99, DELTA: -2.45, },
        // { LV1: 'HCCP', LV2: 'P3', LV3: 'STF', DISC: 'Mechanical', PLAN: 100, ACTUAL: 12.92, DELTA: -68.59, },
        // { LV1: 'HCCP', LV2: 'P3', LV3: 'WFS', DISC: 'Painting', PLAN: 93.74, ACTUAL: 91, DELTA: -8.99, },
        // { LV1: 'HCCP', LV2: 'P3', LV3: 'EIT', DISC: 'Structure', PLAN: 100, ACTUAL: 98.7, DELTA: -1.29, },
        // { LV1: 'HCCP', LV2: 'P3', LV3: 'EPF', DISC: 'Mechanical', PLAN: 100, ACTUAL: 94.17, DELTA: -5.82, },
        // { LV1: 'UG', LV2: 'OVERALL', LV3: 'OVERALL', DISC: 'Structure', PLAN: 99.98, ACTUAL: 63.39, DELTA: -36.6, },
        // { LV1: 'UG', LV2: 'AB', LV3: 'OVERALL', DISC: 'Painting', PLAN: 99.45, ACTUAL: 0, DELTA: 0, },
        // { LV1: 'UG', LV2: 'C', LV3: 'OVERALL', DISC: 'Fire & Safety', PLAN: 100, ACTUAL: 99.22, DELTA: 1.41, },
        // { LV1: 'MOF', LV2: 'OVERALL', LV3: 'OVERALL', DISC: 'Instrument', PLAN: 100, ACTUAL: 100, DELTA: 0, },
        // { LV1: 'JETTY', LV2: 'OVERALL', LV3: 'OVERALL', DISC: 'Electrical', PLAN: 97.99, ACTUAL: 97.66, DELTA: 0.35, },
        // { LV1: 'TERMINAL', LV2: 'OVERALL', LV3: 'OVERALL', DISC: 'Insulation', PLAN: 100, ACTUAL: 99.75, DELTA: -0.24, },
        // { LV1: 'PIPELINE', LV2: 'OVERALL', LV3: 'OVERALL', DISC: 'Painting', PLAN: 100, ACTUAL: 98.39, DELTA: -1.6, },
        // { LV1: 'HUADE', LV2: 'OVERALL', LV3: 'OVERALL', DISC: 'Fire & Safety', PLAN: 100, ACTUAL: 83.46, DELTA: -13.29, },
        ],
    },
    DataItems: [
      
      {LV1:"HCCP",LV2:"OVERALL",LV3:"OVERALL",LV3_DESCR:"HCCP",OV_PLAN_PROG:15.84602304099164,OV_ACTUAL_PROG:15.45738956186637,OV_DELTA:-0.3886334791252697,ENG_PLAN_PROG:0,ENG_ACTUAL_PROG:0,ENG_DELTA:0,PRO_PLAN_PROG:0,PRO_ACTUAL_PROG:0,PRO_DELTA:0,CON_PLAN_PROG:0,CON_ACTUAL_PROG:0,CON_DELTA:0},
      {LV1:"HCCP",LV2:"MAINPLANT",LV3:"OVERALL",LV3_DESCR:"HCC Main Plant",OV_PLAN_PROG:15.37274227858511,OV_ACTUAL_PROG:15.28900457510189,OV_DELTA:-0.08373770348322651,ENG_PLAN_PROG:22.720728239183,ENG_ACTUAL_PROG:22.72370400464571,ENG_DELTA:0.002975765462704061,PRO_PLAN_PROG:21.19353762521808,PRO_ACTUAL_PROG:20.87672201604792,PRO_DELTA:-0.3168156091701668,CON_PLAN_PROG:4.548664878951542,CON_ACTUAL_PROG:4.621433697171129,CON_DELTA:0.07276881821958714},
      {LV1:"HCCP",LV2:"OP",LV3:"OVERALL",LV3_DESCR:"Offplots",OV_PLAN_PROG:20.10554990265037,OV_ACTUAL_PROG:16.97285444274671,OV_DELTA:-3.132695459903662,ENG_PLAN_PROG:0,ENG_ACTUAL_PROG:0,ENG_DELTA:0,PRO_PLAN_PROG:0,PRO_ACTUAL_PROG:0,PRO_DELTA:0,CON_PLAN_PROG:0,CON_ACTUAL_PROG:0,CON_DELTA:0},
      {LV1:"MAINPLANT",LV2:"OVERALL",LV3:"OVERALL",LV3_DESCR:"HCC Main Plant",OV_PLAN_PROG:15.37274227858511,OV_ACTUAL_PROG:15.28900457510189,OV_DELTA:-0.08373770348322651,ENG_PLAN_PROG:22.720728239183,ENG_ACTUAL_PROG:22.72370400464571,ENG_DELTA:0.002975765462704061,PRO_PLAN_PROG:21.19353762521808,PRO_ACTUAL_PROG:20.87672201604792,PRO_DELTA:-0.3168156091701668,CON_PLAN_PROG:4.548664878951542,CON_ACTUAL_PROG:4.621433697171129,CON_DELTA:0.07276881821958714},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"OVERALL",LV3_DESCR:"Package 1",OV_PLAN_PROG:0,OV_ACTUAL_PROG:0,OV_DELTA:0,ENG_PLAN_PROG:25.98771853062008,ENG_ACTUAL_PROG:26.20880523343397,ENG_DELTA:0.2210867028138992,PRO_PLAN_PROG:17.09446876558948,PRO_ACTUAL_PROG:17.58812398686581,PRO_DELTA:0.4936552212763345,CON_PLAN_PROG:2.877854567307693,CON_ACTUAL_PROG:2.75,CON_DELTA:-0.1278545673076925},
      {LV1:"MAINPLANT",LV2:"P2",LV3:"OVERALL",LV3_DESCR:"Package 2",OV_PLAN_PROG:0,OV_ACTUAL_PROG:0,OV_DELTA:0,ENG_PLAN_PROG:24.5770631817995,ENG_ACTUAL_PROG:23.7,ENG_DELTA:-0.8770631817994996,PRO_PLAN_PROG:17.57834179611401,PRO_ACTUAL_PROG:17.84,PRO_DELTA:0.2616582038859896,CON_PLAN_PROG:0.4326450344149459,CON_ACTUAL_PROG:1.36,CON_DELTA:0.9273549655850539},
      {LV1:"MAINPLANT",LV2:"P3",LV3:"OVERALL",LV3_DESCR:"Package 3",OV_PLAN_PROG:0,OV_ACTUAL_PROG:0,OV_DELTA:0,ENG_PLAN_PROG:23.05872143017434,ENG_ACTUAL_PROG:22.13,ENG_DELTA:-0.9287214301743418,PRO_PLAN_PROG:22.36127017178553,PRO_ACTUAL_PROG:21.58,PRO_DELTA:-0.781270171785529,CON_PLAN_PROG:7.731065973862384,CON_ACTUAL_PROG:7.430000000000001,CON_DELTA:-0.3010659738623831},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"OR",LV3_DESCR:"Olefins Recovery Unit",OV_PLAN_PROG:0,OV_ACTUAL_PROG:0,OV_DELTA:0,ENG_PLAN_PROG:0,ENG_ACTUAL_PROG:0,ENG_DELTA:0,PRO_PLAN_PROG:0,PRO_ACTUAL_PROG:0,PRO_DELTA:0,CON_PLAN_PROG:0,CON_ACTUAL_PROG:0,CON_DELTA:0},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"BD",LV3_DESCR:"Butadiene Unit",OV_PLAN_PROG:0,OV_ACTUAL_PROG:0,OV_DELTA:0,ENG_PLAN_PROG:0,ENG_ACTUAL_PROG:0,ENG_DELTA:0,PRO_PLAN_PROG:0,PRO_ACTUAL_PROG:0,PRO_DELTA:0,CON_PLAN_PROG:0,CON_ACTUAL_PROG:0,CON_DELTA:0},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"DIB",LV3_DESCR:"Di-Isobutylene Unit",OV_PLAN_PROG:0,OV_ACTUAL_PROG:0,OV_DELTA:0,ENG_PLAN_PROG:0,ENG_ACTUAL_PROG:0,ENG_DELTA:0,PRO_PLAN_PROG:0,PRO_ACTUAL_PROG:0,PRO_DELTA:0,CON_PLAN_PROG:0,CON_ACTUAL_PROG:0,CON_DELTA:0},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"GHU",LV3_DESCR:"Gasoline Hydrotreating Unit",OV_PLAN_PROG:0,OV_ACTUAL_PROG:0,OV_DELTA:0,ENG_PLAN_PROG:0,ENG_ACTUAL_PROG:0,ENG_DELTA:0,PRO_PLAN_PROG:0,PRO_ACTUAL_PROG:0,PRO_DELTA:0,CON_PLAN_PROG:0,CON_ACTUAL_PROG:0,CON_DELTA:0},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"BTX",LV3_DESCR:"Benzene Toluene Unit",OV_PLAN_PROG:0,OV_ACTUAL_PROG:0,OV_DELTA:0,ENG_PLAN_PROG:0,ENG_ACTUAL_PROG:0,ENG_DELTA:0,PRO_PLAN_PROG:0,PRO_ACTUAL_PROG:0,PRO_DELTA:0,CON_PLAN_PROG:0,CON_ACTUAL_PROG:0,CON_DELTA:0},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"BOILER",LV3_DESCR:"Boiler Unit",OV_PLAN_PROG:0,OV_ACTUAL_PROG:0,OV_DELTA:0,ENG_PLAN_PROG:0,ENG_ACTUAL_PROG:0,ENG_DELTA:0,PRO_PLAN_PROG:0,PRO_ACTUAL_PROG:0,PRO_DELTA:0,CON_PLAN_PROG:0,CON_ACTUAL_PROG:0,CON_DELTA:0},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"DW",LV3_DESCR:"Demineralized Water Unit",OV_PLAN_PROG:0,OV_ACTUAL_PROG:0,OV_DELTA:0,ENG_PLAN_PROG:0,ENG_ACTUAL_PROG:0,ENG_DELTA:0,PRO_PLAN_PROG:0,PRO_ACTUAL_PROG:0,PRO_DELTA:0,CON_PLAN_PROG:0,CON_ACTUAL_PROG:0,CON_DELTA:0},
      {LV1:"MAINPLANT",LV2:"P1",LV3:"FUR",LV3_DESCR:"Furnaces",OV_PLAN_PROG:0,OV_ACTUAL_PROG:0,OV_DELTA:0,ENG_PLAN_PROG:0,ENG_ACTUAL_PROG:0,ENG_DELTA:0,PRO_PLAN_PROG:0,PRO_ACTUAL_PROG:0,PRO_DELTA:0,CON_PLAN_PROG:0,CON_ACTUAL_PROG:0,CON_DELTA:0},
      {LV1:"MAINPLANT",LV2:"P2",LV3:"GPPE",LV3_DESCR:"Gas Phase Polyethylene Unit",OV_PLAN_PROG:0,OV_ACTUAL_PROG:0,OV_DELTA:0,ENG_PLAN_PROG:0,ENG_ACTUAL_PROG:0,ENG_DELTA:0,PRO_PLAN_PROG:0,PRO_ACTUAL_PROG:0,PRO_DELTA:0,CON_PLAN_PROG:0,CON_ACTUAL_PROG:0,CON_DELTA:0},
      {LV1:"MAINPLANT",LV2:"P2",LV3:"LDPE",LV3_DESCR:"Low Density Polyethylene Unit",OV_PLAN_PROG:0,OV_ACTUAL_PROG:0,OV_DELTA:0,ENG_PLAN_PROG:0,ENG_ACTUAL_PROG:0,ENG_DELTA:0,PRO_PLAN_PROG:0,PRO_ACTUAL_PROG:0,PRO_DELTA:0,CON_PLAN_PROG:0,CON_ACTUAL_PROG:0,CON_DELTA:0},
      {LV1:"MAINPLANT",LV2:"P2",LV3:"PP",LV3_DESCR:"Propylene Polymerization Unit",OV_PLAN_PROG:0,OV_ACTUAL_PROG:0,OV_DELTA:0,ENG_PLAN_PROG:0,ENG_ACTUAL_PROG:0,ENG_DELTA:0,PRO_PLAN_PROG:0,PRO_ACTUAL_PROG:0,PRO_DELTA:0,CON_PLAN_PROG:0,CON_ACTUAL_PROG:0,CON_DELTA:0},
      {LV1:"MAINPLANT",LV2:"P2",LV3:"PBL",LV3_DESCR:"Polymer Bagging and Logistics",OV_PLAN_PROG:0,OV_ACTUAL_PROG:0,OV_DELTA:0,ENG_PLAN_PROG:0,ENG_ACTUAL_PROG:0,ENG_DELTA:0,PRO_PLAN_PROG:0,PRO_ACTUAL_PROG:0,PRO_DELTA:0,CON_PLAN_PROG:0,CON_ACTUAL_PROG:0,CON_DELTA:0},
      {LV1:"MAINPLANT",LV2:"P3",LV3:"OGL",LV3_DESCR:"Overall General Layout",OV_PLAN_PROG:0,OV_ACTUAL_PROG:0,OV_DELTA:0,ENG_PLAN_PROG:0,ENG_ACTUAL_PROG:0,ENG_DELTA:0,PRO_PLAN_PROG:0,PRO_ACTUAL_PROG:0,PRO_DELTA:0,CON_PLAN_PROG:0,CON_ACTUAL_PROG:0,CON_DELTA:0},
      {LV1:"MAINPLANT",LV2:"P3",LV3:"MF",LV3_DESCR:"Management Facilities",OV_PLAN_PROG:0,OV_ACTUAL_PROG:0,OV_DELTA:0,ENG_PLAN_PROG:0,ENG_ACTUAL_PROG:0,ENG_DELTA:0,PRO_PLAN_PROG:0,PRO_ACTUAL_PROG:0,PRO_DELTA:0,CON_PLAN_PROG:0,CON_ACTUAL_PROG:0,CON_DELTA:0},
      {LV1:"MAINPLANT",LV2:"P3",LV3:"STF",LV3_DESCR:"Storage and Transportation Facilities",OV_PLAN_PROG:0,OV_ACTUAL_PROG:0,OV_DELTA:0,ENG_PLAN_PROG:0,ENG_ACTUAL_PROG:0,ENG_DELTA:0,PRO_PLAN_PROG:0,PRO_ACTUAL_PROG:0,PRO_DELTA:0,CON_PLAN_PROG:0,CON_ACTUAL_PROG:0,CON_DELTA:0},
      {LV1:"MAINPLANT",LV2:"P3",LV3:"WFS",LV3_DESCR:"Watersupply and Fire-fighting System",OV_PLAN_PROG:0,OV_ACTUAL_PROG:0,OV_DELTA:0,ENG_PLAN_PROG:0,ENG_ACTUAL_PROG:0,ENG_DELTA:0,PRO_PLAN_PROG:0,PRO_ACTUAL_PROG:0,PRO_DELTA:0,CON_PLAN_PROG:0,CON_ACTUAL_PROG:0,CON_DELTA:0},
      {LV1:"MAINPLANT",LV2:"P3",LV3:"EIT",LV3_DESCR:"Electrical, Instrument and Telecom System",OV_PLAN_PROG:0,OV_ACTUAL_PROG:0,OV_DELTA:0,ENG_PLAN_PROG:0,ENG_ACTUAL_PROG:0,ENG_DELTA:0,PRO_PLAN_PROG:0,PRO_ACTUAL_PROG:0,PRO_DELTA:0,CON_PLAN_PROG:0,CON_ACTUAL_PROG:0,CON_DELTA:0},
      {LV1:"MAINPLANT",LV2:"P3",LV3:"EPF",LV3_DESCR:"Environmental Protection Facilities",OV_PLAN_PROG:0,OV_ACTUAL_PROG:0,OV_DELTA:0,ENG_PLAN_PROG:0,ENG_ACTUAL_PROG:0,ENG_DELTA:0,PRO_PLAN_PROG:0,PRO_ACTUAL_PROG:0,PRO_DELTA:0,CON_PLAN_PROG:0,CON_ACTUAL_PROG:0,CON_DELTA:0},
      {LV1:"OP",LV2:"UG",LV3:"OVERALL",LV3_DESCR:"Under Ground",OV_PLAN_PROG:0,OV_ACTUAL_PROG:0,OV_DELTA:0,ENG_PLAN_PROG:0,ENG_ACTUAL_PROG:0,ENG_DELTA:0,PRO_PLAN_PROG:0,PRO_ACTUAL_PROG:0,PRO_DELTA:0,CON_PLAN_PROG:0,CON_ACTUAL_PROG:0,CON_DELTA:0},
      {LV1:"OP",LV2:"UG",LV3:"AB",LV3_DESCR:"Area AB Under Ground",OV_PLAN_PROG:0,OV_ACTUAL_PROG:0,OV_DELTA:0,ENG_PLAN_PROG:0,ENG_ACTUAL_PROG:0,ENG_DELTA:0,PRO_PLAN_PROG:0,PRO_ACTUAL_PROG:0,PRO_DELTA:0,CON_PLAN_PROG:0,CON_ACTUAL_PROG:0,CON_DELTA:0},
      {LV1:"OP",LV2:"UG",LV3:"C",LV3_DESCR:"Area C Under Ground",OV_PLAN_PROG:0,OV_ACTUAL_PROG:0,OV_DELTA:0,ENG_PLAN_PROG:0,ENG_ACTUAL_PROG:0,ENG_DELTA:0,PRO_PLAN_PROG:0,PRO_ACTUAL_PROG:0,PRO_DELTA:0,CON_PLAN_PROG:0,CON_ACTUAL_PROG:0,CON_DELTA:0},
      {LV1:"OP",LV2:"MOF",LV3:"OVERALL",LV3_DESCR:"Materials Offload Facility ",OV_PLAN_PROG:0,OV_ACTUAL_PROG:0,OV_DELTA:0,ENG_PLAN_PROG:0,ENG_ACTUAL_PROG:0,ENG_DELTA:0,PRO_PLAN_PROG:0,PRO_ACTUAL_PROG:0,PRO_DELTA:0,CON_PLAN_PROG:0,CON_ACTUAL_PROG:0,CON_DELTA:0},
      {LV1:"OP",LV2:"JETTY",LV3:"OVERALL",LV3_DESCR:"Jetty",OV_PLAN_PROG:0,OV_ACTUAL_PROG:0,OV_DELTA:0,ENG_PLAN_PROG:0,ENG_ACTUAL_PROG:0,ENG_DELTA:0,PRO_PLAN_PROG:0,PRO_ACTUAL_PROG:0,PRO_DELTA:0,CON_PLAN_PROG:0,CON_ACTUAL_PROG:0,CON_DELTA:0},
      {LV1:"OP",LV2:"TERMINAL",LV3:"OVERALL",LV3_DESCR:"Product Terminal",OV_PLAN_PROG:0,OV_ACTUAL_PROG:0,OV_DELTA:0,ENG_PLAN_PROG:0,ENG_ACTUAL_PROG:0,ENG_DELTA:0,PRO_PLAN_PROG:0,PRO_ACTUAL_PROG:0,PRO_DELTA:0,CON_PLAN_PROG:0,CON_ACTUAL_PROG:0,CON_DELTA:0},
      {LV1:"OP",LV2:"PIPELINE",LV3:"OVERALL",LV3_DESCR:"Pipe Line",OV_PLAN_PROG:0,OV_ACTUAL_PROG:0,OV_DELTA:0,ENG_PLAN_PROG:0,ENG_ACTUAL_PROG:0,ENG_DELTA:0,PRO_PLAN_PROG:0,PRO_ACTUAL_PROG:0,PRO_DELTA:0,CON_PLAN_PROG:0,CON_ACTUAL_PROG:0,CON_DELTA:0},
      {LV1:"OP",LV2:"MBZ",LV3:"OVERALL",LV3_DESCR:"Huade Island Terminal",OV_PLAN_PROG:0,OV_ACTUAL_PROG:0,OV_DELTA:0,ENG_PLAN_PROG:0,ENG_ACTUAL_PROG:0,ENG_DELTA:0,PRO_PLAN_PROG:0,PRO_ACTUAL_PROG:0,PRO_DELTA:0,CON_PLAN_PROG:0,CON_ACTUAL_PROG:0,CON_DELTA:0}
    
    
    ],
}