import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 200,
      CanvasHeight: 200,
      CanvasBorderWeight: 0.5,
      CanvasBorderColor: '#ccc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Summary', x:10, y: 0, font: 'roboto', class: 'regular', size: 12, color: '#424242', align: 'start' },
      ],
      Filter: [],
      Sql: []
    },
    DataItems: [
      {CATEGORY:"A",DISC:"ELE",TOTAL:220,ACTUAL:59,REMAIN:161,PROG:26.82},
      {CATEGORY:"A",DISC:"HVAC",TOTAL:60,ACTUAL:1,REMAIN:59,PROG:1.67},
      {CATEGORY:"A",DISC:"INT",TOTAL:152,ACTUAL:35,REMAIN:117,PROG:23.03},
      {CATEGORY:"A",DISC:"MEC",TOTAL:278,ACTUAL:38,REMAIN:240,PROG:13.67},
      {CATEGORY:"A",DISC:"OTH",TOTAL:2,ACTUAL:0,REMAIN:2,PROG:0},
      {CATEGORY:"A",DISC:"PIP",TOTAL:1,ACTUAL:0,REMAIN:1,PROG:0},
      {CATEGORY:"A",DISC:"Overall",TOTAL:713,ACTUAL:133,REMAIN:580,PROG:18.65},
      // {CATEGORY:"B1",DISC:"ELE",TOTAL:330,ACTUAL:62,REMAIN:268,PROG:18.79},
      // {CATEGORY:"B1",DISC:"HVAC",TOTAL:1,ACTUAL:0,REMAIN:1,PROG:0},
      // {CATEGORY:"B1",DISC:"INT",TOTAL:119,ACTUAL:10,REMAIN:109,PROG:8.4},
      // {CATEGORY:"B1",DISC:"MEC",TOTAL:41,ACTUAL:0,REMAIN:41,PROG:0},
      // {CATEGORY:"B1",DISC:"OTH",TOTAL:1,ACTUAL:0,REMAIN:1,PROG:0},
      // {CATEGORY:"B1",DISC:"Overall",TOTAL:492,ACTUAL:72,REMAIN:420,PROG:14.63},
      // {CATEGORY:"B2",DISC:"ELE",TOTAL:19,ACTUAL:0,REMAIN:19,PROG:0},
      // {CATEGORY:"B2",DISC:"Overall",TOTAL:19,ACTUAL:0,REMAIN:19,PROG:0},
      // {CATEGORY:"B3",DISC:"INT",TOTAL:1,ACTUAL:0,REMAIN:1,PROG:0},
      // {CATEGORY:"B3",DISC:"Overall",TOTAL:1,ACTUAL:0,REMAIN:1,PROG:0}
    ],
}