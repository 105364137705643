import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 780,
      CanvasHeight: 400,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },
    DataItems:  [
      {ITEM:"Concrete",UOM:"m3",TOTAL:137281.3,PLAN_WK:1864,ACTUAL_WK:970,PLAN_CUM:119921.3,ACTUAL_CUM:100796.3,DELTA_CUM:-19124.99999999999,PROG:73.4,ITEM_SEQ:1},
      {ITEM:"Piling ",UOM:"PCS",TOTAL:5348,PLAN_WK:0,ACTUAL_WK:0,PLAN_CUM:5348,ACTUAL_CUM:5348,DELTA_CUM:0,PROG:100,ITEM_SEQ:3},
      {ITEM:"Equipment Installation",UOM:"Package",TOTAL:36708.4,PLAN_WK:842,ACTUAL_WK:2015,PLAN_CUM:35340.4,ACTUAL_CUM:28873.4,DELTA_CUM:-6467,PROG:78.7,ITEM_SEQ:2}
    
    ],
}