import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 700,
      CanvasHeight: 300,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },
    DataItems:    [
      {DISC:"B-Business and General",ISSUED:225,RESPONDED:187,OPEN:31,CANCELLED:7},
      {DISC:"D-Mechanical and Static Equipment",ISSUED:10,RESPONDED:7,OPEN:2,CANCELLED:1},
      {DISC:"E-Electrical",ISSUED:2,RESPONDED:2,OPEN:0,CANCELLED:0},
      {DISC:"I-Instrumentation and Metering",ISSUED:24,RESPONDED:19,OPEN:5,CANCELLED:0},
      {DISC:"K-Construction, Transportation, and Logistics",ISSUED:1,RESPONDED:1,OPEN:0,CANCELLED:0},
      {DISC:"L-Piping and General Layout",ISSUED:8,RESPONDED:8,OPEN:0,CANCELLED:0},
      {DISC:"M-Rotating Machinery",ISSUED:4,RESPONDED:3,OPEN:1,CANCELLED:0},
      {DISC:"P-Process",ISSUED:34,RESPONDED:18,OPEN:15,CANCELLED:1},
      {DISC:"Q-Quality",ISSUED:3,RESPONDED:1,OPEN:2,CANCELLED:0},
      {DISC:"R-Regulatory, Environmental, and Socioeconomic",ISSUED:5,RESPONDED:4,OPEN:1,CANCELLED:0},
      {DISC:"T-Telecommunications",ISSUED:1,RESPONDED:1,OPEN:0,CANCELLED:0},
      {DISC:"V-Contracting and Procurement",ISSUED:36,RESPONDED:31,OPEN:4,CANCELLED:1},
      {DISC:"X-Materials, Corrosion, and Flow Assurance",ISSUED:1,RESPONDED:1,OPEN:0,CANCELLED:0},
      {DISC:"Total",ISSUED:354,RESPONDED:283,OPEN:61,CANCELLED:10}
    ],
}