import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 390,
      CanvasHeight: 280,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 390,
      CanvasChartHeight: 280,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },

    DataItems: [
      {NO:4,LV1:"TOPSIDES",LV2:"TOPSIDES MODULES",PLAN:49.11,ACTUAL:31.65,ACTUAL_CERT:15.33,DELTA:-17.46,PLAN_WK:2.68,ACTUAL_WK:1.62,ACTUAL_CERT_WK:1.3,DELTA_WK:-1.06}
    ],
}