import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 300,
      CanvasHeight: 220,
      CanvasBorderWeight: 0.3,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 310,
      CanvasChartHeight: 270,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: 'Transparent',
      CanvasChartFillColor: 'Transparent',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },

     // ----------------------------------------------- NOTE
    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },
    Queries: {
      SQL1:  [
        {SEVERITY:"L5",SEVERITY_DESCR:"L5 - Multiple",ACTUAL:0}
      ],
    },
    DataItems: [
      {SEVERITY:"L5",SEVERITY_DESCR:"L5 - Multiple",ACTUAL:0},
      {SEVERITY:"L4",SEVERITY_DESCR:"L4- Single",ACTUAL:0,POTENTIAL:0},
      {SEVERITY:"L3",SEVERITY_DESCR:"L3 - Life Altering",ACTUAL:0,POTENTIAL:0},
      {SEVERITY:"L2",SEVERITY_DESCR:"L2 - Moderate",ACTUAL:0,POTENTIAL:6},
      {SEVERITY:"L1",SEVERITY_DESCR:"L1 - Minor",ACTUAL:0,POTENTIAL:5},
      {SEVERITY:"L0",SEVERITY_DESCR:"L0 - No Hurt",ACTUAL:11,POTENTIAL:0}

    ],
}