const AreaProgress = [
  
  { TITLE: 'HULL'              , P_PG: 99.99, A_PG: 99.99, D_PG: 0,     bColor: '#FFE000'},
  { TITLE: 'TOPSIDE'                , P_PG: 99.52, A_PG: 99.52, D_PG: 0,     bColor: '#CFCD1A'},
  { TITLE: 'MOORING'                , P_PG: 83.6,  A_PG: 83.42, D_PG: -0.18, bColor: '#7BAF30'},
  { TITLE: 'MECH. COMPLETION'  , P_PG: 72.51, A_PG: 72.23, D_PG: -0.28, bColor: '#2F993D'},
  { TITLE: 'PRE-COMMISSIONING'      , P_PG: 44.99, A_PG: 45.08, D_PG: 0.09,  bColor: '#F7BACF'},

  
]


export default AreaProgress