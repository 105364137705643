import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 500,
      CanvasHeight: 320,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

    DataItems: [
    {DISC:"ELE",DISC_DESCR:"Electrical",TOTAL:1775,OPEN:938,CLOSED:837,PROG:47.2,VOL:51.8,VOL1:51.8},
    {DISC:"INT",DISC_DESCR:"Instrumemt",TOTAL:692,OPEN:471,CLOSED:221,PROG:31.9,VOL:26,VOL1:26},
    {DISC:"MEC",DISC_DESCR:"Mechanical",TOTAL:348,OPEN:193,CLOSED:155,PROG:44.5,VOL:10.7,VOL1:10.7},
    {DISC:"HVAC",DISC_DESCR:"HVAC",TOTAL:199,OPEN:192,CLOSED:7,PROG:3.5,VOL:10.6,VOL1:10.6},
    {DISC:"PIP",DISC_DESCR:"Piping",TOTAL:10,OPEN:10,CLOSED:0,PROG:0,VOL:0.6,VOL1:1},
    {DISC:"OTH",DISC_DESCR:"Other",TOTAL:8,OPEN:8,CLOSED:0,PROG:0,VOL:0.4,VOL1:1}
  ],
}