import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1540,
      CanvasHeight: 400,
      CanvasBorderWeight: 1,
      CanvasBorderColor: '#ddd',
      CanvasFillColor: '#f5f5f5',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 10,
      CanvasChartHeight: 10,
      CanvasChartBorderWeight: 1,
      CanvasChartBorderColor: '#000',
      CanvasChartFillColor: '#000',
      CanvasChartOpacity: 1,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },


    DataItems: [
      { MOD: '42M078', PAU: 'M078-B', CDATE: '2019-12-21', CERT: 'RFSC', SHIP: 'Okpo-27', SHIP_NAME: 'CY2', SHIP_DATE: '2019-11-23', LO_PLAN: '2019-12-13', LO_ACTUAL: '2019-12-13', SA_PLAN: '2019-12-26', SA_ACTUAL: '2019-12-21', },
      { MOD: '42M078', PAU: 'M078-A', CDATE: '2019-12-21', CERT: 'RFSC', SHIP: 'Okpo-27', SHIP_NAME: 'CY2', SHIP_DATE: '2019-11-25', LO_PLAN: '2019-12-14', LO_ACTUAL: '2019-12-14', SA_PLAN: '2019-12-26', SA_ACTUAL: '2019-12-21', },
      { MOD: '42M057', PAU: 'M057-A', CDATE: '2019-12-30', CERT: 'RFSC', SHIP: 'Okpo-28', SHIP_NAME: 'KES2', SHIP_DATE: '2019-11-09', LO_PLAN: '2019-12-10', LO_ACTUAL: '2019-12-22', SA_PLAN: '2019-12-20', SA_ACTUAL: '2019-12-30', },
      { MOD: '41M008', PAU: 'M008-B', CDATE: '2019-12-30', CERT: 'RFSC', SHIP: 'Okpo-28', SHIP_NAME: 'KES2', SHIP_DATE: '2019-11-30', LO_PLAN: '2019-12-18', LO_ACTUAL: '2019-12-23', SA_PLAN: '2019-12-31', SA_ACTUAL: '2019-12-30', },
      { MOD: '41M008', PAU: 'M008-A', CDATE: '2019-12-30', CERT: 'RFSC', SHIP: 'Okpo-28', SHIP_NAME: 'KES2', SHIP_DATE: '2019-12-02', LO_PLAN: '2019-12-19', LO_ACTUAL: '2019-12-24', SA_PLAN: '2019-12-31', SA_ACTUAL: '2019-12-30', },
      { MOD: '42M057', PAU: 'M057-C', CDATE: '2019-12-31', CERT: 'RFSC', SHIP: 'Okpo-29', SHIP_NAME: 'HJP', SHIP_DATE: '2019-11-06', LO_PLAN: '2019-12-08', LO_ACTUAL: '2019-12-27', SA_PLAN: '2019-12-20', SA_ACTUAL: '2019-12-31', },
      { MOD: '42M057', PAU: 'M057-B', CDATE: '2019-12-31', CERT: 'RFSC', SHIP: 'Okpo-29', SHIP_NAME: 'HJP', SHIP_DATE: '2019-11-08', LO_PLAN: '2019-12-09', LO_ACTUAL: '2019-12-26', SA_PLAN: '2019-12-20', SA_ACTUAL: '2019-12-31', },
      { MOD: '42M075', PAU: 'M075-C', CDATE: '2020-01-21', CERT: 'RFSC', SHIP: 'Okpo-30', SHIP_NAME: 'XHK', SHIP_DATE: '2019-11-14', LO_PLAN: '2019-12-29', LO_ACTUAL: '2020-01-14', SA_PLAN: '2020-01-11', SA_ACTUAL: '2020-01-21', },
      { MOD: '42M075', PAU: 'M075-B', CDATE: '2020-01-21', CERT: 'RFSC', SHIP: 'Okpo-30', SHIP_NAME: 'XHK', SHIP_DATE: '2019-11-16', LO_PLAN: '2019-12-30', LO_ACTUAL: '2020-01-14', SA_PLAN: '2020-01-11', SA_ACTUAL: '2020-01-21', },
      { MOD: '42M075', PAU: 'M075-A', CDATE: '2020-01-21', CERT: 'RFSC', SHIP: 'Okpo-30', SHIP_NAME: 'XHK', SHIP_DATE: '2019-11-17', LO_PLAN: '2019-12-31', LO_ACTUAL: '2020-01-15', SA_PLAN: '2020-01-11', SA_ACTUAL: '2020-01-21', },
      { MOD: '42M065', PAU: 'M065-A', CDATE: '2020-01-21', CERT: 'RFSC', SHIP: 'Okpo-30', SHIP_NAME: 'XHK', SHIP_DATE: '2019-12-17', LO_PLAN: '2019-12-28', LO_ACTUAL: '2020-01-15', SA_PLAN: '2020-01-08', SA_ACTUAL: '2020-01-21', },
      { MOD: '41M010', PAU: 'M010-B', CDATE: '2020-01-22', CERT: 'RFSC', SHIP: 'Okpo-31', SHIP_NAME: 'YMTO', SHIP_DATE: '2019-12-06', LO_PLAN: '2020-01-08', LO_ACTUAL: '2020-01-10', SA_PLAN: '2020-01-18', SA_ACTUAL: '2020-01-22', },
      { MOD: '41M010', PAU: 'M010-A', CDATE: '2020-01-22', CERT: 'RFSC', SHIP: 'Okpo-31', SHIP_NAME: 'YMTO', SHIP_DATE: '2019-12-09', LO_PLAN: '2020-01-09', LO_ACTUAL: '2020-01-11', SA_PLAN: '2020-01-18', SA_ACTUAL: '2020-01-22', },
      { MOD: '42M065', PAU: 'M065-C', CDATE: '2020-01-22', CERT: 'RFSC', SHIP: 'Okpo-31', SHIP_NAME: 'YMTO', SHIP_DATE: '2019-12-14', LO_PLAN: '2019-12-26', LO_ACTUAL: '2020-01-17', SA_PLAN: '2020-01-08', SA_ACTUAL: '2020-01-22', },
      { MOD: '42M065', PAU: 'M065-B', CDATE: '2020-01-22', CERT: 'RFSC', SHIP: 'Okpo-31', SHIP_NAME: 'YMTO', SHIP_DATE: '2019-12-16', LO_PLAN: '2019-12-27', LO_ACTUAL: '2020-01-18', SA_PLAN: '2020-01-08', SA_ACTUAL: '2020-01-22', },
      { MOD: '42M056', PAU: 'M056-B', CDATE: '2020-02-07', CERT: 'IN PROGRESS', SHIP: 'Okpo-32', SHIP_NAME: 'DBG7', SHIP_DATE: '2019-12-24', LO_PLAN: '2020-01-21', LO_ACTUAL: '', SA_PLAN: '2020-02-06', SA_ACTUAL: '', },
      { MOD: '42M054', PAU: 'M054-C', CDATE: '2020-02-07', CERT: 'RFD', SHIP: 'Okpo-32', SHIP_NAME: 'DBG7', SHIP_DATE: '2019-12-30', LO_PLAN: '2020-02-03', LO_ACTUAL: '', SA_PLAN: '2020-02-14', SA_ACTUAL: '', },
      { MOD: '42M056', PAU: 'M056-C', CDATE: '2020-02-11', CERT: 'IN PROGRESS', SHIP: 'Okpo-33', SHIP_NAME: 'TAK', SHIP_DATE: '2019-12-22', LO_PLAN: '2020-01-20', LO_ACTUAL: '', SA_PLAN: '2020-02-06', SA_ACTUAL: '', },
      { MOD: '42M056', PAU: 'M056-A', CDATE: '2020-02-11', CERT: 'IN PROGRESS', SHIP: 'Okpo-33', SHIP_NAME: 'TAK', SHIP_DATE: '2019-12-25', LO_PLAN: '2020-01-28', LO_ACTUAL: '', SA_PLAN: '2020-02-06', SA_ACTUAL: '', },
      { MOD: '41M011', PAU: 'M011-B', CDATE: '2020-02-11', CERT: 'RFL', SHIP: 'Okpo-33', SHIP_NAME: 'TAK', SHIP_DATE: '2020-01-04', LO_PLAN: '2020-01-22', LO_ACTUAL: '', SA_PLAN: '2020-02-04', SA_ACTUAL: '', },
      { MOD: '41M011', PAU: 'M011-A', CDATE: '2020-02-11', CERT: 'RFL', SHIP: 'Okpo-33', SHIP_NAME: 'TAK', SHIP_DATE: '2020-01-06', LO_PLAN: '2020-01-23', LO_ACTUAL: '', SA_PLAN: '2020-02-04', SA_ACTUAL: '', },
      { MOD: '42M055', PAU: 'M055-C', CDATE: '2020-02-18', CERT: 'RFD', SHIP: 'Okpo-34', SHIP_NAME: 'DEB2', SHIP_DATE: '2020-01-11', LO_PLAN: '2020-02-11', LO_ACTUAL: '', SA_PLAN: '2020-02-23', SA_ACTUAL: '', },
      { MOD: '42M055', PAU: 'M055-B', CDATE: '2020-02-18', CERT: 'RFD', SHIP: 'Okpo-34', SHIP_NAME: 'DEB2', SHIP_DATE: '2020-01-13', LO_PLAN: '2020-02-12', LO_ACTUAL: '', SA_PLAN: '2020-02-23', SA_ACTUAL: '', },
      { MOD: '41M009', PAU: 'M009-B', CDATE: '2020-02-22', CERT: 'IN PROGRESS', SHIP: 'Okpo-35', SHIP_NAME: 'KSK', SHIP_DATE: '2020-02-04', LO_PLAN: '2020-02-16', LO_ACTUAL: '', SA_PLAN: '2020-02-27', SA_ACTUAL: '', },
      { MOD: '41M009', PAU: 'M009-A', CDATE: '2020-02-22', CERT: 'IN PROGRESS', SHIP: 'Okpo-35', SHIP_NAME: 'KSK', SHIP_DATE: '2020-02-06', LO_PLAN: '2020-02-17', LO_ACTUAL: '', SA_PLAN: '2020-02-27', SA_ACTUAL: '', },
      { MOD: '42M054', PAU: 'M054-B', CDATE: '2020-03-01', CERT: 'RFD', SHIP: 'Okpo-36', SHIP_NAME: 'ZYK', SHIP_DATE: '2020-01-01', LO_PLAN: '2020-02-04', LO_ACTUAL: '', SA_PLAN: '2020-02-14', SA_ACTUAL: '', },
      { MOD: '42M054', PAU: 'M054-A', CDATE: '2020-03-01', CERT: 'RFD', SHIP: 'Okpo-36', SHIP_NAME: 'ZYK', SHIP_DATE: '2020-01-02', LO_PLAN: '2020-02-05', LO_ACTUAL: '', SA_PLAN: '2020-02-14', SA_ACTUAL: '', },
      { MOD: '42M055', PAU: 'M055-A', CDATE: '2020-03-01', CERT: 'RFD', SHIP: 'Okpo-36', SHIP_NAME: 'ZYK', SHIP_DATE: '2020-01-14', LO_PLAN: '2020-02-13', LO_ACTUAL: '', SA_PLAN: '2020-02-23', SA_ACTUAL: '', },
      { MOD: '41M801', PAU: 'M801-C', CDATE: '2020-03-08', CERT: 'IN PROGRESS', SHIP: 'Okpo-37', SHIP_NAME: 'XYK', SHIP_DATE: '2020-02-10', LO_PLAN: '2020-02-23', LO_ACTUAL: '', SA_PLAN: '2020-03-06', SA_ACTUAL: '', },
      { MOD: '41M801', PAU: 'M801-B', CDATE: '2020-03-08', CERT: 'IN PROGRESS', SHIP: 'Okpo-37', SHIP_NAME: 'XYK', SHIP_DATE: '2020-02-12', LO_PLAN: '2020-02-24', LO_ACTUAL: '', SA_PLAN: '2020-03-06', SA_ACTUAL: '', },
      { MOD: '41M801', PAU: 'M801-A', CDATE: '2020-03-08', CERT: 'IN PROGRESS', SHIP: 'Okpo-37', SHIP_NAME: 'XYK', SHIP_DATE: '2020-02-13', LO_PLAN: '2020-02-25', LO_ACTUAL: '', SA_PLAN: '2020-03-06', SA_ACTUAL: '', },
      { MOD: '47M265', PAU: 'M265-A', CDATE: '2020-03-08', CERT: 'NOT STARTED', SHIP: 'Okpo-37', SHIP_NAME: 'XYK', SHIP_DATE: '2020-02-29', LO_PLAN: '2020-03-01', LO_ACTUAL: '', SA_PLAN: '2020-03-10', SA_ACTUAL: '', },
      { MOD: '41M007', PAU: 'M007-B', CDATE: '2020-03-26', CERT: 'IN PROGRESS', SHIP: 'Okpo-38', SHIP_NAME: 'DBG3', SHIP_DATE: '2020-03-05', LO_PLAN: '2020-03-17', LO_ACTUAL: '', SA_PLAN: '2020-03-28', SA_ACTUAL: '', },
      { MOD: '41M007', PAU: 'M007-A', CDATE: '2020-03-26', CERT: 'IN PROGRESS', SHIP: 'Okpo-38', SHIP_NAME: 'DBG3', SHIP_DATE: '2020-03-07', LO_PLAN: '2020-03-18', LO_ACTUAL: '', SA_PLAN: '2020-03-28', SA_ACTUAL: '', },
      { MOD: '47M266', PAU: 'M266-A', CDATE: '2020-03-26', CERT: 'NOT STARTED', SHIP: 'Okpo-38', SHIP_NAME: 'DBG3', SHIP_DATE: '2020-03-13', LO_PLAN: '2020-03-20', LO_ACTUAL: '', SA_PLAN: '2020-03-29', SA_ACTUAL: '', },
      { MOD: '41M006', PAU: 'M006-C', CDATE: '2020-04-12', CERT: 'IN PROGRESS', SHIP: 'Okpo-39', SHIP_NAME: 'XRK', SHIP_DATE: '2020-02-23', LO_PLAN: '2020-03-26', LO_ACTUAL: '', SA_PLAN: '2020-04-07', SA_ACTUAL: '', },
      { MOD: '41M006', PAU: 'M006-B', CDATE: '2020-04-12', CERT: 'IN PROGRESS', SHIP: 'Okpo-39', SHIP_NAME: 'XRK', SHIP_DATE: '2020-02-25', LO_PLAN: '2020-03-27', LO_ACTUAL: '', SA_PLAN: '2020-04-07', SA_ACTUAL: '', },
      { MOD: '41M006', PAU: 'M006-A', CDATE: '2020-04-12', CERT: 'IN PROGRESS', SHIP: 'Okpo-39', SHIP_NAME: 'XRK', SHIP_DATE: '2020-02-26', LO_PLAN: '2020-03-28', LO_ACTUAL: '', SA_PLAN: '2020-04-07', SA_ACTUAL: '', },
      { MOD: '47M268', PAU: 'M268-A', CDATE: '2020-04-12', CERT: 'NOT STARTED', SHIP: 'Okpo-39', SHIP_NAME: 'XRK', SHIP_DATE: '2020-03-17', LO_PLAN: '2020-04-04', LO_ACTUAL: '', SA_PLAN: '2020-04-13', SA_ACTUAL: '', },
      { MOD: '41M001', PAU: 'M001-C', CDATE: '2020-05-21', CERT: 'NOT STARTED', SHIP: 'Okpo-40', SHIP_NAME: 'XHK', SHIP_DATE: '2020-04-11', LO_PLAN: '2020-05-13', LO_ACTUAL: '', SA_PLAN: '2020-05-25', SA_ACTUAL: '', },
      { MOD: '41M001', PAU: 'M001-B', CDATE: '2020-05-21', CERT: 'NOT STARTED', SHIP: 'Okpo-40', SHIP_NAME: 'XHK', SHIP_DATE: '2020-04-13', LO_PLAN: '2020-05-14', LO_ACTUAL: '', SA_PLAN: '2020-05-25', SA_ACTUAL: '', },
      { MOD: '47M267', PAU: 'M267-B', CDATE: '2020-05-21', CERT: 'NOT STARTED', SHIP: 'Okpo-40', SHIP_NAME: 'XHK', SHIP_DATE: '2020-04-27', LO_PLAN: '2020-05-09', LO_ACTUAL: '', SA_PLAN: '2020-05-20', SA_ACTUAL: '', },
      { MOD: '47M267', PAU: 'M267-A', CDATE: '2020-05-21', CERT: 'NOT STARTED', SHIP: 'Okpo-40', SHIP_NAME: 'XHK', SHIP_DATE: '2020-04-29', LO_PLAN: '2020-05-10', LO_ACTUAL: '', SA_PLAN: '2020-05-20', SA_ACTUAL: '', },
      { MOD: '41M001', PAU: 'M001-A', CDATE: '2020-06-04', CERT: 'NOT STARTED', SHIP: 'Okpo-41', SHIP_NAME: 'CY2', SHIP_DATE: '2020-04-14', LO_PLAN: '2020-05-15', LO_ACTUAL: '', SA_PLAN: '2020-05-25', SA_ACTUAL: '', },
      { MOD: '41M004', PAU: 'M004-A', CDATE: '2020-06-04', CERT: 'NOT STARTED', SHIP: 'Okpo-41', SHIP_NAME: 'CY2', SHIP_DATE: '2020-04-22', LO_PLAN: '2020-05-24', LO_ACTUAL: '', SA_PLAN: '2020-06-02', SA_ACTUAL: '', },
      { MOD: '41M004', PAU: 'M004-C', CDATE: '2020-05-29', CERT: 'NOT STARTED', SHIP: 'Okpo-42', SHIP_NAME: 'CY1', SHIP_DATE: '2020-04-19', LO_PLAN: '2020-05-22', LO_ACTUAL: '', SA_PLAN: '2020-06-02', SA_ACTUAL: '', },
      { MOD: '41M004', PAU: 'M004-B', CDATE: '2020-05-29', CERT: 'NOT STARTED', SHIP: 'Okpo-42', SHIP_NAME: 'CY1', SHIP_DATE: '2020-04-21', LO_PLAN: '2020-05-23', LO_ACTUAL: '', SA_PLAN: '2020-06-02', SA_ACTUAL: '', },
      { MOD: '47M261', PAU: 'M261-C', CDATE: '2020-06-16', CERT: 'NOT STARTED', SHIP: 'Okpo-43', SHIP_NAME: 'ZYK', SHIP_DATE: '2020-05-04', LO_PLAN: '2020-06-05', LO_ACTUAL: '', SA_PLAN: '2020-06-17', SA_ACTUAL: '', },
      { MOD: '47M261', PAU: 'M261-B', CDATE: '2020-06-16', CERT: 'NOT STARTED', SHIP: 'Okpo-43', SHIP_NAME: 'ZYK', SHIP_DATE: '2020-05-06', LO_PLAN: '2020-06-06', LO_ACTUAL: '', SA_PLAN: '2020-06-17', SA_ACTUAL: '', },
      { MOD: '41M003', PAU: 'M003-C', CDATE: '2020-06-16', CERT: 'NOT STARTED', SHIP: 'Okpo-43', SHIP_NAME: 'ZYK', SHIP_DATE: '2020-05-12', LO_PLAN: '2020-06-14', LO_ACTUAL: '', SA_PLAN: '2020-06-25', SA_ACTUAL: '', },
      { MOD: '41M003', PAU: 'M003-B', CDATE: '2020-06-16', CERT: 'NOT STARTED', SHIP: 'Okpo-43', SHIP_NAME: 'ZYK', SHIP_DATE: '2020-05-14', LO_PLAN: '2020-06-15', LO_ACTUAL: '', SA_PLAN: '2020-06-25', SA_ACTUAL: '', },
      { MOD: '47M261', PAU: 'M261-A', CDATE: '2020-06-30', CERT: 'NOT STARTED', SHIP: 'Okpo-44', SHIP_NAME: 'DBG7', SHIP_DATE: '2020-05-07', LO_PLAN: '2020-06-07', LO_ACTUAL: '', SA_PLAN: '2020-06-17', SA_ACTUAL: '', },
      { MOD: '47M262', PAU: 'M262-C', CDATE: '2020-06-30', CERT: 'NOT STARTED', SHIP: 'Okpo-44', SHIP_NAME: 'DBG7', SHIP_DATE: '2020-05-20', LO_PLAN: '2020-06-18', LO_ACTUAL: '', SA_PLAN: '2020-07-01', SA_ACTUAL: '', },
      { MOD: '47M262', PAU: 'M262-B', CDATE: '2020-06-30', CERT: 'NOT STARTED', SHIP: 'Okpo-44', SHIP_NAME: 'DBG7', SHIP_DATE: '2020-05-22', LO_PLAN: '2020-06-19', LO_ACTUAL: '', SA_PLAN: '2020-07-01', SA_ACTUAL: '', },
      { MOD: '47M262', PAU: 'M262-A', CDATE: '2020-06-30', CERT: 'NOT STARTED', SHIP: 'Okpo-44', SHIP_NAME: 'DBG7', SHIP_DATE: '2020-05-23', LO_PLAN: '2020-06-20', LO_ACTUAL: '', SA_PLAN: '2020-07-01', SA_ACTUAL: '', },
      { MOD: '41M003', PAU: 'M003-A', CDATE: '2020-07-04', CERT: 'NOT STARTED', SHIP: 'Okpo-45', SHIP_NAME: 'XYK', SHIP_DATE: '2020-05-15', LO_PLAN: '2020-06-16', LO_ACTUAL: '', SA_PLAN: '2020-06-25', SA_ACTUAL: '', },
      { MOD: '47M263', PAU: 'M263-B', CDATE: '2020-07-04', CERT: 'NOT STARTED', SHIP: 'Okpo-45', SHIP_NAME: 'XYK', SHIP_DATE: '2020-05-28', LO_PLAN: '2020-06-09', LO_ACTUAL: '', SA_PLAN: '2020-06-20', SA_ACTUAL: '', },
      { MOD: '47M263', PAU: 'M263-A', CDATE: '2020-07-04', CERT: 'NOT STARTED', SHIP: 'Okpo-45', SHIP_NAME: 'XYK', SHIP_DATE: '2020-05-30', LO_PLAN: '2020-06-10', LO_ACTUAL: '', SA_PLAN: '2020-06-20', SA_ACTUAL: '', }]
}