import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1000,
      CanvasHeight: 400,
      CanvasBorderWeight: 0.5,
      CanvasBorderColor: '#E0E0E0',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1000,
      CanvasChartHeight: 450,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: '', x:20, y: 10, font: 'roboto', class: 'regular', size: 12, color: '#424242', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: -50, y: 20, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'start' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: -30,
          y: 20,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'start'
        },
      ]
    },

    DataItems: [
      {REVIEWER:"Kurt J Vosburg",DAYLATE:68},
      {REVIEWER:"Jianwen Wen",DAYLATE:66},
      {REVIEWER:"Kristofor Markway",DAYLATE:66},
      {REVIEWER:"Chunlu Sun, Fei Gao, Jingran Li, Yanping Fei, Ying Li,  emhcc dcc",DAYLATE:63},
      {REVIEWER:"Sunil Ramsinghani",DAYLATE:48},
      {REVIEWER:"Wei Ching Soh",DAYLATE:24},
      {REVIEWER:"Kin Tien Lim",DAYLATE:22},
      {REVIEWER:"Guohao Chen, Na Shen, Qian Wang, Xiru Ran, Zhen Li,  emhcc dcc",DAYLATE:20},
      {REVIEWER:"Soyuz Jeyakumar",DAYLATE:18},
      {REVIEWER:"Yingjun Du",DAYLATE:14},
      {REVIEWER:"Thomas Schulz",DAYLATE:14},
      {REVIEWER:"Andrew Begley",DAYLATE:14},
      {REVIEWER:"Lei Zhang, Mrs Yongli Yu,  emhcc dcc",DAYLATE:14},
      {REVIEWER:"Jonas Heyns",DAYLATE:13},
      {REVIEWER:"Shouhua Zou",DAYLATE:11},
      {REVIEWER:"Raina Shahrir",DAYLATE:10},
      {REVIEWER:"Franc Hong Fu",DAYLATE:9},
      {REVIEWER:"Laura Shan Liu",DAYLATE:8},
      {REVIEWER:"Thierry Polchlopek",DAYLATE:8},
      {REVIEWER:"Si yuan Guo",DAYLATE:8},
      {REVIEWER:"Yean Yi Cherrie Chung",DAYLATE:8},
      {REVIEWER:"Xu Gao",DAYLATE:8},
      {REVIEWER:"Zhi zhong Su",DAYLATE:7},
      {REVIEWER:"Martin Tan",DAYLATE:7},
      {REVIEWER:"Frankie Verluyten",DAYLATE:6},
      {REVIEWER:"Fei Gao,  Guohao Chen, Hong Yu, Jiang Zhu, Zhen Li,  emhcc dcc",DAYLATE:6},
      {REVIEWER:"Dongsheng Niu",DAYLATE:5},
      {REVIEWER:"Gopal Ramireddi",DAYLATE:4},
      {REVIEWER:"Andrew Hudson",DAYLATE:4},
      {REVIEWER:"Chuan Yu Zhang",DAYLATE:4},
      {REVIEWER:"Edward Wang",DAYLATE:4},
      {REVIEWER:"Jay Westcott",DAYLATE:4},
      {REVIEWER:"Fei Gao,  Guohao Chen, Zhen Li,  emhcc dcc",DAYLATE:4},
      {REVIEWER:"Ravi NP",DAYLATE:4},
      {REVIEWER:"Xiuwei Bao",DAYLATE:4},
      {REVIEWER:"Shu Yan Quek",DAYLATE:3},
      {REVIEWER:"Danilo Jr Bantiles",DAYLATE:2},
      {REVIEWER:"John T Prince",DAYLATE:2},
      {REVIEWER:"Lian Joo Tay",DAYLATE:2},
      {REVIEWER:"Roy Moussa",DAYLATE:2},
      {REVIEWER:"Xiatian Huang",DAYLATE:1},
      {REVIEWER:"Xiangyang Song",DAYLATE:1},
      {REVIEWER:"Xiangbin Li - Qingdao Yueyang Engineering Consulting Co.Ltd.",DAYLATE:1},
      {REVIEWER:"Willis Spencer",DAYLATE:1},
      {REVIEWER:"Wenhong Ning",DAYLATE:1},
      {REVIEWER:"Qun Han",DAYLATE:1},
      {REVIEWER:"Pu Zhang",DAYLATE:1},
      {REVIEWER:"Ket Oon Chris Tan",DAYLATE:1},
      {REVIEWER:"Kelly Gu",DAYLATE:1},
      {REVIEWER:"Juping Alex Yu",DAYLATE:1},
      {REVIEWER:"Jian Xiao",DAYLATE:1},
      {REVIEWER:"Jialong Yao",DAYLATE:1},
      {REVIEWER:"Glenn Chan",DAYLATE:1},
      {REVIEWER:"Jason Grubbs",DAYLATE:1},
      {REVIEWER:"Howard Crossley - Simon Carves Engineering Ltd",DAYLATE:1},
      {REVIEWER:"hongbin cheng - Qingdao Yueyang Engineering Consulting Co.Ltd.",DAYLATE:1},
      {REVIEWER:"Diwaker Singh",DAYLATE:1},
      {REVIEWER:"Carl Paul Dales",DAYLATE:1}
    ],
}