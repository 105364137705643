import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1440,
      CanvasHeight: 940,
      CanvasBorderWeight: 1,
      CanvasBorderColor: '#f5f5f5',
      CanvasFillColor: '#f5f5f5',
      CanvasOpacity: 1,

      CanvasChartX: 20,
      CanvasChartY: 20,
      CanvasChartWidth: 1400,
      CanvasChartHeight: 900,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#f5f5f5',
      CanvasChartFillColor: '#f5f5f5',
      CanvasChartOpacity: 1,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
      CanvasChartAreaAllowed: 'Y'
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: [
        {
          query: "SELECT 'Datasource: Shop Drawing Index 04-Oct-20' TITLE", 
          text: 'Datasource: Shop Drawing Index 04-Oct-20',
          x: 10,
          y: 10,
          font: 'roboto',
          style: 'regular',
          size: 12,
          color: '#757575',
          align: 'start'
        },
      ]
    },
    Queries: {
      // Status of Blocks
      SQL1:[
        {CODE:"Shop_P",ITEM:"Painting Shop",LV1:"TOPSIDES",LV2:"COMMON",LV3:"RC02",BLOCK:"RC22",PLAN:100,ACTUAL:90.5,DELTA:-9.5,DELTA_CODE:"DELAY"},
        {CODE:"Shop_P",ITEM:"Painting Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",BLOCK:"W5C2",PLAN:100,ACTUAL:65,DELTA:-35,DELTA_CODE:"DELAY"},
        {CODE:"Shop_P",ITEM:"Painting Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",BLOCK:"E2C",PLAN:68.4,ACTUAL:83.2,DELTA:14.8,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_P",ITEM:"Painting Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",BLOCK:"W5M1",PLAN:83.7,ACTUAL:90.48,DELTA:6.780000000000001,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_P",ITEM:"Painting Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",BLOCK:"E5E",PLAN:100,ACTUAL:99.05,DELTA:-0.9500000000000028,DELTA_CODE:"DELAY"},
        {CODE:"Shop_P",ITEM:"Painting Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",BLOCK:"W2C2",PLAN:100,ACTUAL:99.2,DELTA:-0.7999999999999972,DELTA_CODE:"DELAY"},
        {CODE:"Shop_P",ITEM:"Painting Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW04",BLOCK:"W4B",PLAN:91.9,ACTUAL:99.99,DELTA:8.08999999999999,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_P",ITEM:"Painting Shop",LV1:"TOPSIDES",LV2:"COMMON",LV3:"RC02",BLOCK:"RC21",PLAN:100,ACTUAL:87.64,DELTA:-12.36,DELTA_CODE:"DELAY"},
        {CODE:"Shop_P",ITEM:"Painting Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",BLOCK:"W2D",PLAN:65.9,ACTUAL:9.25,DELTA:-56.65000000000001,DELTA_CODE:"DELAY"},
        {CODE:"Shop_P",ITEM:"Painting Shop",LV1:"TOPSIDES",LV2:"COMMON",LV3:"RC01",BLOCK:"RC12",PLAN:100,ACTUAL:98.79,DELTA:-1.209999999999994,DELTA_CODE:"DELAY"},
        {CODE:"Shop_P",ITEM:"Painting Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",BLOCK:"W1D",PLAN:31.1,ACTUAL:65.98,DELTA:34.88,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_P",ITEM:"Painting Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",BLOCK:"W2C1",PLAN:100,ACTUAL:97.59,DELTA:-2.409999999999997,DELTA_CODE:"DELAY"},
        {CODE:"Shop_P",ITEM:"Painting Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",BLOCK:"W5D",PLAN:65,ACTUAL:81,DELTA:16,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_P",ITEM:"Painting Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",BLOCK:"E1D",PLAN:21.4,ACTUAL:33.5,DELTA:12.1,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_P",ITEM:"Painting Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",BLOCK:"W1B2",PLAN:100,ACTUAL:99.99,DELTA:-0.01000000000000512,DELTA_CODE:"DELAY"},
        {CODE:"Shop_E",ITEM:"Erection Area",LV1:"HULL",LV2:"HULL-AFT",LV3:"HL02",BLOCK:"12A",ACTUAL:0.01,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_E",ITEM:"Erection Area",LV1:"HULL",LV2:"HULL-COMMON",LV3:"MD01-2",BLOCK:"731",ACTUAL:57.5,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_E",ITEM:"Erection Area",LV1:"HULL",LV2:"HULL-COMMON",LV3:"MD01-2",BLOCK:"721",ACTUAL:57.5,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_E",ITEM:"Erection Area",LV1:"HULL",LV2:"HULL-FOR",LV3:"HL03",BLOCK:"26F",ACTUAL:0.01,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_E",ITEM:"Erection Area",LV1:"HULL",LV2:"LQ",LV3:"HD01",BLOCK:"959",PLAN:100,ACTUAL:1,DELTA:-99,DELTA_CODE:"DELAY"},
        {CODE:"Shop_E",ITEM:"Erection Area",LV1:"HULL",LV2:"HULL-FOR",LV3:"HL03",BLOCK:"63F",ACTUAL:0.01,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_E",ITEM:"Erection Area",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",BLOCK:"W5C2",PLAN:0,ACTUAL:66,DELTA:66,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_E",ITEM:"Erection Area",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",BLOCK:"W1B1",PLAN:100,ACTUAL:94,DELTA:-6,DELTA_CODE:"DELAY"},
        {CODE:"Shop_E",ITEM:"Erection Area",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",BLOCK:"E1B1",PLAN:100,ACTUAL:78.4,DELTA:-21.59999999999999,DELTA_CODE:"DELAY"},
        {CODE:"Shop_E",ITEM:"Erection Area",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",BLOCK:"W5B2",PLAN:100,ACTUAL:98.79,DELTA:-1.209999999999994,DELTA_CODE:"DELAY"},
        {CODE:"Shop_E",ITEM:"Erection Area",LV1:"TOPSIDES",LV2:"COMMON",LV3:"RC03",BLOCK:"RC32",PLAN:100,ACTUAL:64.75,DELTA:-35.25,DELTA_CODE:"DELAY"},
        {CODE:"Shop_E",ITEM:"Erection Area",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",BLOCK:"W5C1",PLAN:0,ACTUAL:66,DELTA:66,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_E",ITEM:"Erection Area",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",BLOCK:"E1B2",PLAN:100,ACTUAL:78.4,DELTA:-21.59999999999999,DELTA_CODE:"DELAY"},
        {CODE:"Shop_E",ITEM:"Erection Area",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",BLOCK:"E2B",PLAN:74.5,ACTUAL:94,DELTA:19.5,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_E",ITEM:"Erection Area",LV1:"TOPSIDES",LV2:"COMMON",LV3:"RC02",BLOCK:"RC21",PLAN:100,ACTUAL:34.99,DELTA:-65.00999999999999,DELTA_CODE:"DELAY"},
        {CODE:"Shop_E",ITEM:"Erection Area",LV1:"TOPSIDES",LV2:"COMMON",LV3:"RC05",BLOCK:"RC52",PLAN:100,ACTUAL:42.68,DELTA:-57.32,DELTA_CODE:"DELAY"},
        {CODE:"Shop_E",ITEM:"Erection Area",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",BLOCK:"W1B2",PLAN:100,ACTUAL:94,DELTA:-6,DELTA_CODE:"DELAY"},
        {CODE:"Shop_E",ITEM:"Erection Area",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",BLOCK:"W5B1",PLAN:100,ACTUAL:98.79,DELTA:-1.209999999999994,DELTA_CODE:"DELAY"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"HULL",LV2:"HULL-AFT",LV3:"RB01-02",BLOCK:"0009",ACTUAL:98,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"HULL",LV2:"HULL-AFT",LV3:"RB01-02",BLOCK:"0010",ACTUAL:98,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"HULL",LV2:"HULL-AFT",LV3:"RB01-02",BLOCK:"0008",ACTUAL:98,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"COMMON",LV3:"FL01",BLOCK:"FL1B",ACTUAL:97.23,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"COMMON",LV3:"FL01",BLOCK:"FL1C",ACTUAL:87.59,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",BLOCK:"E2A1",PLAN:100,ACTUAL:99.99,DELTA:-0.01000000000000512,DELTA_CODE:"DELAY"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"COMMON",LV3:"KO01",BLOCK:"K1C1",PLAN:92,ACTUAL:95.25,DELTA:3.25,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"COMMON",LV3:"KO01",BLOCK:"K1C3",PLAN:94,ACTUAL:95.91,DELTA:1.909999999999997,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"COMMON",LV3:"LD00",BLOCK:"LDA1",PLAN:92.1,ACTUAL:95.79,DELTA:3.690000000000012,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",BLOCK:"W1C1",PLAN:100,ACTUAL:99.4,DELTA:-0.5999999999999943,DELTA_CODE:"DELAY"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",BLOCK:"E2C",PLAN:88.8,ACTUAL:98.92,DELTA:10.12000000000001,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",BLOCK:"W1A1",PLAN:100,ACTUAL:99.98,DELTA:-0.01999999999999602,DELTA_CODE:"DELAY"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW04",BLOCK:"W4ST",PLAN:100,ACTUAL:99.99,DELTA:-0.01000000000000512,DELTA_CODE:"DELAY"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",BLOCK:"E1B1",PLAN:100,ACTUAL:99.99,DELTA:-0.01000000000000512,DELTA_CODE:"DELAY"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",BLOCK:"E4ST",PLAN:100,ACTUAL:99.99,DELTA:-0.01000000000000512,DELTA_CODE:"DELAY"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"COMMON",LV3:"FL01",BLOCK:"FL1A",ACTUAL:99.6,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"COMMON",LV3:"KO01",BLOCK:"K1C2",PLAN:87.5,ACTUAL:94.82,DELTA:7.319999999999993,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"COMMON",LV3:"RC01",BLOCK:"RC11",PLAN:100,ACTUAL:99.98,DELTA:-0.01999999999999602,DELTA_CODE:"DELAY"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",BLOCK:"W1C2",PLAN:100,ACTUAL:98.9,DELTA:-1.099999999999994,DELTA_CODE:"DELAY"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",BLOCK:"E1B2",PLAN:100,ACTUAL:99.99,DELTA:-0.01000000000000512,DELTA_CODE:"DELAY"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",BLOCK:"E2B",PLAN:97.1,ACTUAL:92.49,DELTA:-4.609999999999999,DELTA_CODE:"DELAY"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"COMMON",LV3:"LD00",BLOCK:"LDA2",PLAN:70.3,ACTUAL:99.38,DELTA:29.08,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",BLOCK:"W1M1",ACTUAL:87.52,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",BLOCK:"W2D",PLAN:94.3,ACTUAL:83.01,DELTA:-11.28999999999999,DELTA_CODE:"DELAY"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"COMMON",LV3:"LD00",BLOCK:"LDA3",PLAN:90.1,ACTUAL:97.78,DELTA:7.680000000000007,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",BLOCK:"W1D",PLAN:81.8,ACTUAL:87.29,DELTA:5.490000000000009,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",BLOCK:"W1E",PLAN:84.1,ACTUAL:77.94,DELTA:-6.159999999999997,DELTA_CODE:"DELAY"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",BLOCK:"W2C1",PLAN:100,ACTUAL:99.57,DELTA:-0.4300000000000068,DELTA_CODE:"DELAY"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",BLOCK:"E2D",PLAN:80.2,ACTUAL:98.68,DELTA:18.48,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_F",ITEM:"Fabrication Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",BLOCK:"E5B",PLAN:100,ACTUAL:99.09,DELTA:-0.9099999999999966,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"HULL",LV2:"HULL-AFT",LV3:"RB01-02",BLOCK:"0009",ACTUAL:98,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"HULL",LV2:"HULL-AFT",LV3:"RB01-02",BLOCK:"0010",ACTUAL:98,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"HULL",LV2:"HULL-AFT",LV3:"RB01-02",BLOCK:"0008",ACTUAL:98,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",BLOCK:"E1A2",PLAN:100,ACTUAL:99.84,DELTA:-0.1599999999999966,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",BLOCK:"E4C",PLAN:100,ACTUAL:98.75,DELTA:-1.25,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"COMMON",LV3:"FL01",BLOCK:"FL1B",ACTUAL:28.74,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"COMMON",LV3:"FL01",BLOCK:"FL1C",ACTUAL:23.08,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",BLOCK:"W5C2",PLAN:100,ACTUAL:99.59,DELTA:-0.4099999999999966,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",BLOCK:"E2M1",PLAN:100,ACTUAL:99.4,DELTA:-0.5999999999999943,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",BLOCK:"E4B",PLAN:100,ACTUAL:99.69,DELTA:-0.3100000000000023,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",BLOCK:"E5A2",PLAN:100,ACTUAL:99.99,DELTA:-0.01000000000000512,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"COMMON",LV3:"KO01",BLOCK:"K1C1",PLAN:25.9,ACTUAL:64.34,DELTA:38.44000000000001,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"COMMON",LV3:"KO01",BLOCK:"K1C3",PLAN:10.8,ACTUAL:33,DELTA:22.2,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"COMMON",LV3:"LD00",BLOCK:"LDA1",PLAN:73.7,ACTUAL:60.72,DELTA:-12.98,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",BLOCK:"W1C1",PLAN:100,ACTUAL:99.08,DELTA:-0.9200000000000017,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",BLOCK:"W2B1",PLAN:100,ACTUAL:99.54,DELTA:-0.4599999999999938,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",BLOCK:"E2A2",PLAN:100,ACTUAL:99.54,DELTA:-0.4599999999999938,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",BLOCK:"E2C",PLAN:100,ACTUAL:99.67,DELTA:-0.3299999999999983,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",BLOCK:"W1B1",PLAN:100,ACTUAL:99.99,DELTA:-0.01000000000000512,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",BLOCK:"W5M1",PLAN:100,ACTUAL:99.68,DELTA:-0.3199999999999932,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",BLOCK:"E1B1",PLAN:100,ACTUAL:98.79,DELTA:-1.209999999999994,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",BLOCK:"E1C1",PLAN:100,ACTUAL:99.83,DELTA:-0.1700000000000017,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",BLOCK:"E5E",PLAN:100,ACTUAL:99.79,DELTA:-0.2099999999999938,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",BLOCK:"W2C2",PLAN:100,ACTUAL:99.24,DELTA:-0.7600000000000051,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW04",BLOCK:"W4B",PLAN:100,ACTUAL:99.54,DELTA:-0.4599999999999938,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",BLOCK:"W5B2",PLAN:100,ACTUAL:99.39,DELTA:-0.6099999999999994,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"COMMON",LV3:"FL01",BLOCK:"FL1A",ACTUAL:20.69,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"COMMON",LV3:"KO01",BLOCK:"K1C2",PLAN:19.6,ACTUAL:53.7,DELTA:34.1,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",BLOCK:"W1C2",PLAN:100,ACTUAL:95.93,DELTA:-4.069999999999993,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",BLOCK:"W5A2",PLAN:100,ACTUAL:99.75,DELTA:-0.25,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",BLOCK:"W5C1",PLAN:100,ACTUAL:99.7,DELTA:-0.2999999999999972,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",BLOCK:"E1B2",PLAN:100,ACTUAL:99.85,DELTA:-0.1500000000000057,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"COMMON",LV3:"FR01",BLOCK:"FR1",PLAN:99,ACTUAL:54.13,DELTA:-44.87,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"COMMON",LV3:"LD00",BLOCK:"LDA2",PLAN:12.7,ACTUAL:53.93,DELTA:41.23,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",BLOCK:"W2D",PLAN:100,ACTUAL:91.4,DELTA:-8.599999999999994,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"COMMON",LV3:"LD00",BLOCK:"LDA3",PLAN:17.1,ACTUAL:65.97,DELTA:48.87,DELTA_CODE:"AHEAD"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",BLOCK:"W1D",PLAN:100,ACTUAL:97.04,DELTA:-2.959999999999994,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",BLOCK:"W1E",PLAN:54.1,ACTUAL:38.49,DELTA:-15.61,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",BLOCK:"W2C1",PLAN:100,ACTUAL:99.25,DELTA:-0.75,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",BLOCK:"W5A1",PLAN:100,ACTUAL:99.68,DELTA:-0.3199999999999932,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",BLOCK:"W5D",PLAN:100,ACTUAL:99.39,DELTA:-0.6099999999999994,DELTA_CODE:"DELAY"},
        {CODE:"Shop_A",ITEM:"Assembly Shop",LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",BLOCK:"W5B1",PLAN:100,ACTUAL:98.99,DELTA:-1.010000000000005,DELTA_CODE:"DELAY"}
      ],
      SQL2:[
        {LV1:"TOPSIDE",LV2:"COMMON",LV3:"FL01",TEXT1:"Lifting Plan",TEXT1_DATE:"12-Oct-21",TEXT2:"OGM Goal",TEXT2_PROG:99.94},
        {LV1:"HULL",LV2:"COMMON",LV3:"HU00"},
        {LV1:"TOPSIDE",LV2:"MODULES",LV3:"ME01",TEXT1:"Lifting Plan",TEXT1_DATE:"30-Nov-21",TEXT2:"OGM Goal",TEXT2_PROG:90.14},
        {LV1:"TOPSIDE",LV2:"COMMON",LV3:"RC05",TEXT1:"Lifting Plan",TEXT1_DATE:"07-Aug-21",TEXT2:"OGM Goal",TEXT2_PROG:100},
        {LV1:"TOPSIDE",LV2:"COMMON",LV3:"RCxx",TEXT1:"Lifting Plan",TEXT1_DATE:"07-Aug-21",TEXT2:"OGM Goal",TEXT2_PROG:100},
        {LV1:"HULL",LV2:"HULL-AFT",LV3:"OVERALL",TEXT1:"Undocking",TEXT1_DATE:"19-Feb-21",TEXT2:"OGM Goal",TEXT2_PROG:79.31},
        {LV1:"HULL",LV2:"COMMON",LV3:"MD01-2"},
        {LV1:"TOPSIDE",LV2:"COMMON",LV3:"OVERALL",TEXT1_DATE:"07-Aug-21",TEXT2_PROG:62.66},
        {LV1:"TOPSIDE",LV2:"COMMON",LV3:"RC03",TEXT1:"Lifting Plan",TEXT1_DATE:"07-Aug-21",TEXT2:"OGM Goal",TEXT2_PROG:100},
        {LV1:"TOPSIDE",LV2:"COMMON",LV3:"TSRC06",TEXT1:"Lifting Plan",TEXT1_DATE:"07-Aug-21",TEXT2:"OGM Goal",TEXT2_PROG:100},
        {LV1:"TOPSIDE",LV2:"INTEGRATION",LV3:"IG0X"},
        {LV1:"TOPSIDE",LV2:"INTEGRATION",LV3:"IG2X"},
        {LV1:"TOPSIDE",LV2:"COMMON",LV3:"KO01",TEXT1:"Lifting Plan",TEXT1_DATE:"16-Aug-21",TEXT2:"OGM Goal",TEXT2_PROG:52.71},
        {LV1:"TOPSIDE",LV2:"MODULES",LV3:"MW05",TEXT1:"Lifting Plan",TEXT1_DATE:"27-Oct-21",TEXT2:"OGM Goal",TEXT2_PROG:91.99},
        {LV1:"HULL",LV2:"HULL-FOR",LV3:"OVERALL",TEXT1:"Undocking",TEXT1_DATE:"19-Dec-20",TEXT2:"OGM Goal",TEXT2_PROG:71.75},
        {LV1:"HULL",LV2:"HULL-FOR",LV3:"HL03",TEXT1:"Undocking",TEXT1_DATE:"19-Dec-20",TEXT2:"OGM Goal",TEXT2_PROG:71.75},
        {LV1:"TOPSIDE",LV2:"MODULES",LV3:"M000"},
        {LV1:"TOPSIDE",LV2:"MODULES",LV3:"ME02",TEXT1:"Lifting Plan",TEXT1_DATE:"29-Nov-21",TEXT2:"OGM Goal",TEXT2_PROG:89.01},
        {LV1:"HULL",LV2:"LQ",LV3:"OVERALL",TEXT1:"Lifting Plan",TEXT1_DATE:"16-Mar-21",TEXT2:"OGM Goal",TEXT2_PROG:69.06},
        {LV1:"TOPSIDE",LV2:"INTEGRATION",LV3:"IG3X"},
        {LV1:"TOPSIDE",LV2:"COMMON",LV3:"LD00DA"},
        {LV1:"TOPSIDE",LV2:"MODULES",LV3:"ME05",TEXT1:"Lifting Plan",TEXT1_DATE:"30-Aug-21",TEXT2:"OGM Goal",TEXT2_PROG:99.61},
        {LV1:"TOPSIDE",LV2:"MODULES",LV3:"MW02",TEXT1:"Lifting Plan",TEXT1_DATE:"29-Nov-21",TEXT2:"OGM Goal",TEXT2_PROG:91.06},
        {LV1:"TOPSIDE",LV2:"MODULES",LV3:"MW04",TEXT1:"Lifting Plan",TEXT1_DATE:"03-Sep-21",TEXT2:"OGM Goal",TEXT2_PROG:97.53},
        {LV1:"TOPSIDE",LV2:"COMMON",LV3:"TSRC01",TEXT1:"Lifting Plan",TEXT1_DATE:"07-Aug-21",TEXT2:"OGM Goal",TEXT2_PROG:100},
        {LV1:"HULL",LV2:"HULL-AFT",LV3:"HL02",TEXT1:"Undocking",TEXT1_DATE:"19-Feb-21",TEXT2:"OGM Goal",TEXT2_PROG:79.31},
        {LV1:"TOPSIDE",LV2:"COMMON",LV3:"PR00"},
        {LV1:"TOPSIDE",LV2:"COMMON",LV3:"RC01",TEXT1:"Lifting Plan",TEXT1_DATE:"07-Aug-21",TEXT2:"OGM Goal",TEXT2_PROG:100},
        {LV1:"TOPSIDE",LV2:"OVERALL",TEXT1:"1st. Lifting Plan",TEXT1_DATE:"31-Aug-21",TEXT2:"OGM Goal",TEXT2_PROG:69.6},
        {LV1:"MOORING",LV2:"OVERALL",LV3:"OVERALL"},
        {LV1:"HULL",LV2:"COMMON",LV3:"OVERALL",TEXT1:"Target",TEXT2:"OGM Goal"},
        {LV1:"TOPSIDE",LV2:"MODULES",LV3:"OVERALL",TEXT1:"1st. Lifting Plan",TEXT1_DATE:"31-Aug-21",TEXT2:"OGM Goal",TEXT2_PROG:79.6},
        {LV1:"TOPSIDE",LV2:"COMMON",LV3:"RC02",TEXT1:"Lifting Plan",TEXT1_DATE:"07-Aug-21",TEXT2:"OGM Goal",TEXT2_PROG:100},
        {LV1:"TOPSIDE",LV2:"COMMON",LV3:"TSRC02",TEXT1:"Lifting Plan",TEXT1_DATE:"07-Aug-21",TEXT2:"OGM Goal",TEXT2_PROG:100},
        {LV1:"TOPSIDE",LV2:"COMMON",LV3:"TSRC04",TEXT1:"Lifting Plan",TEXT1_DATE:"07-Aug-21",TEXT2:"OGM Goal",TEXT2_PROG:100},
        {LV1:"MOORING",LV2:"MOORING"},
        {LV1:"PROJECT",LV2:"CONSTRUCTION"},
        {LV1:"PROJECT",LV2:"OVERALL"},
        {LV1:"TOPSIDE",LV2:"INTEGRATION"},
        {LV1:"HULL",LV2:"OVERALL",TEXT1:"1st. Undocking",TEXT1_DATE:"19-Dec-20",TEXT2:"OGM Goal",TEXT2_PROG:64.1},
        {LV1:"HULL",LV2:"LQ",LV3:"HD01",TEXT1:"Lifting Plan",TEXT1_DATE:"21-May-21",TEXT2:"OGM Goal",TEXT2_PROG:60.27},
        {LV1:"HULL",LV2:"LQ",LV3:"LQ00",TEXT1:"Lifting Plan",TEXT1_DATE:"16-Mar-21",TEXT2:"OGM Goal",TEXT2_PROG:69.06},
        {LV1:"TOPSIDE",LV2:"MODULES",LV3:"ME04",TEXT1:"Lifting Plan",TEXT1_DATE:"02-Sep-21",TEXT2:"OGM Goal",TEXT2_PROG:96.78},
        {LV1:"TOPSIDE",LV2:"MODULES",LV3:"MW01",TEXT1:"Lifting Plan",TEXT1_DATE:"29-Nov-21",TEXT2:"OGM Goal",TEXT2_PROG:91.15},
        {LV1:"TOPSIDE",LV2:"COMMON",LV3:"RC04",TEXT1:"Lifting Plan",TEXT1_DATE:"07-Aug-21",TEXT2:"OGM Goal",TEXT2_PROG:100},
        {LV1:"TOPSIDE",LV2:"COMMON",LV3:"TSRC03",TEXT1:"Lifting Plan",TEXT1_DATE:"07-Aug-21",TEXT2:"OGM Goal",TEXT2_PROG:100},
        {LV1:"TOPSIDE",LV2:"COMMON",LV3:"TSRC05",TEXT1:"Lifting Plan",TEXT1_DATE:"07-Aug-21",TEXT2:"OGM Goal",TEXT2_PROG:100}
      ],
      SQL3:[
        {CODE:"Shop_F",ITEM:"Fabrication Shop",PLAN:83.74,ACTUAL:83.94,DELTA:0.2,TOTAL:810,INPROGRESS:184,COMPLETED:389,NOTSTARTED:237},
        {CODE:"Shop_A",ITEM:"Assembly Shop",PLAN:70.67,ACTUAL:70.59,DELTA:-0.07,TOTAL:810,INPROGRESS:165,COMPLETED:385,NOTSTARTED:260},
        {CODE:"Shop_P",ITEM:"Painting Shop",PLAN:58.56,ACTUAL:56.43,DELTA:-2.13,TOTAL:810,INPROGRESS:8,COMPLETED:333,NOTSTARTED:469},
        {CODE:"Shop_E",ITEM:"Erection Area",PLAN:33.71,ACTUAL:32.98,DELTA:-0.73,TOTAL:485,INPROGRESS:21,COMPLETED:153,NOTSTARTED:311},
        {CODE:"Area_T",ITEM:"Topsides Area",PLAN:5.17,ACTUAL:5.06,DELTA:-0.11,OGM_GOAL:88},
        {CODE:"Area_M",ITEM:"TS Modules",PLAN:8.17,ACTUAL:5.06,DELTA:-0.11,OGM_GOAL:88},
        {CODE:"Area_C",ITEM:"TS Common",PLAN:5.17,ACTUAL:5.06,DELTA:-0.11,OGM_GOAL:88},
        {CODE:"Area_L",ITEM:"LQ Aera",PLAN:31.97,ACTUAL:22.27,DELTA:-9.69,OGM_GOAL:82},
        {CODE:"Area_H",ITEM:"HULL Area",PLAN:49.92,ACTUAL:51,DELTA:1.07,OGM_GOAL:78}
      ],
      SQL4: [
        {LV1:"HULL",LV2:"HULL-COMMON",LV3:"MD01-2",DISC:"Insulation",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"HULL",LV2:"HULL-FOR",LV3:"HL03",DISC:"Instrument",PLAN:61.19,ACTUAL:7.91,DELTA:-53.27},
        {LV1:"HULL",LV2:"HULL-FOR",LV3:"HL03",DISC:"Insulation",PLAN:9.87,ACTUAL:0,DELTA:-9.87},
        {LV1:"HULL",LV2:"LQ",LV3:"OVERALL",DISC:"Mechanical",PLAN:69.44,ACTUAL:27.02,DELTA:-42.42},
        {LV1:"TOPSIDES",LV2:"COMMON",LV3:"KO01",DISC:"Electrical",PLAN:22.37,ACTUAL:0,DELTA:-22.37},
        {LV1:"TOPSIDES",LV2:"COMMON",LV3:"KO01",DISC:"Fire & Safety",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",DISC:"Painting",PLAN:70.39,ACTUAL:39.81,DELTA:-30.58},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",DISC:"Structure",PLAN:92.2,ACTUAL:84.21,DELTA:-7.98},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",DISC:"Painting",PLAN:59.51,ACTUAL:27.11,DELTA:-32.39},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW04",DISC:"Painting",PLAN:89.24,ACTUAL:61.36,DELTA:-27.87},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW04",DISC:"Piping",PLAN:85.82,ACTUAL:40.72,DELTA:-45.1},
        {LV1:"INTEGRATION",LV2:"OVERALL",LV3:"OVERALL",DISC:"Electrical",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"HULL",LV2:"OVERALL",LV3:"OVERALL",DISC:"Fire & Safety",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"HULL",LV2:"OVERALL",LV3:"OVERALL",DISC:"HVAC",PLAN:98.75,ACTUAL:82.94,DELTA:-15.8},
        {LV1:"INTEGRATION",LV2:"OVERALL",LV3:"OVERALL",DISC:"Piping",PLAN:0.78,ACTUAL:12.47,DELTA:11.69},
        {LV1:"INTEGRATION",LV2:"OVERALL",LV3:"OVERALL",DISC:"Structure",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"OVERALL",DISC:"HVAC",PLAN:88.55,ACTUAL:53.49,DELTA:-35.05},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"OVERALL",DISC:"Mechanical",PLAN:87.08,ACTUAL:41.22,DELTA:-45.86},
        {LV1:"TOPSIDES",LV2:"COMMON",LV3:"OVERALL",DISC:"Painting",PLAN:76.33,ACTUAL:26.32,DELTA:-50},
        {LV1:"HULL",LV2:"HULL-AFT",LV3:"HL02",DISC:"Structure",PLAN:100,ACTUAL:99.97,DELTA:-0.02},
        {LV1:"HULL",LV2:"HULL-COMMON",LV3:"MD01-2",DISC:"Mechanical",PLAN:90.05,ACTUAL:58.56,DELTA:-31.48},
        {LV1:"HULL",LV2:"HULL-COMMON",LV3:"MD01-2",DISC:"Structure",PLAN:98.83,ACTUAL:84.9,DELTA:-13.93},
        {LV1:"HULL",LV2:"LQ",LV3:"OVERALL",DISC:"Painting",PLAN:99.81,ACTUAL:84.41,DELTA:-15.4},
        {LV1:"INTEGRATION",LV2:"INTEGRATION",LV3:"IG90",DISC:"Fire & Safety",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"INTEGRATION",LV2:"INTEGRATION",LV3:"IG90",DISC:"Instrument",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"M000",DISC:"Electrical",PLAN:52.47,ACTUAL:0,DELTA:-52.47},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"M000",DISC:"Insulation",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"M000",DISC:"Painting",PLAN:55.68,ACTUAL:5.07,DELTA:-50.6},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",DISC:"Fire & Safety",PLAN:5.74,ACTUAL:0,DELTA:-5.74},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",DISC:"Instrument",PLAN:44.49,ACTUAL:0,DELTA:-44.49},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",DISC:"Insulation",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"INTEGRATION",LV2:"OVERALL",LV3:"OVERALL",DISC:"Fire & Safety",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"TOPSIDES",LV2:"OVERALL",LV3:"OVERALL",DISC:"Mechanical",PLAN:79.22,ACTUAL:35.38,DELTA:-43.83},
        {LV1:"TOPSIDES",LV2:"COMMON",LV3:"OVERALL",DISC:"Insulation",PLAN:69.8,ACTUAL:0,DELTA:-69.8},
        {LV1:"TOPSIDES",LV2:"COMMON",LV3:"OVERALL",DISC:"Piping",PLAN:62.1,ACTUAL:14.88,DELTA:-47.22},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"OVERALL",DISC:"Piping",PLAN:46.32,ACTUAL:25.18,DELTA:-21.13},
        {LV1:"HULL",LV2:"HULL-AFT",LV3:"HL02",DISC:"Instrument",PLAN:64.53,ACTUAL:0.18,DELTA:-64.35},
        {LV1:"HULL",LV2:"HULL-AFT",LV3:"RB01-02",DISC:"Structure",PLAN:100,ACTUAL:99.88,DELTA:-0.11},
        {LV1:"HULL",LV2:"HULL-COMMON",LV3:"MD01-2",DISC:"Instrument",PLAN:9.35,ACTUAL:0.44,DELTA:-8.91},
        {LV1:"HULL",LV2:"LQ",LV3:"OVERALL",DISC:"Architecture",PLAN:69.1,ACTUAL:35.43,DELTA:-33.67},
        {LV1:"TOPSIDES",LV2:"COMMON",LV3:"FL01",DISC:"Electrical",PLAN:11.85,ACTUAL:0,DELTA:-11.85},
        {LV1:"TOPSIDES",LV2:"COMMON",LV3:"KO01",DISC:"Mechanical",PLAN:37.99,ACTUAL:2.33,DELTA:-35.65},
        {LV1:"TOPSIDES",LV2:"COMMON",LV3:"KO01",DISC:"Painting",PLAN:22.33,ACTUAL:10.28,DELTA:-12.05},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",DISC:"Fire & Safety",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",DISC:"Mechanical",PLAN:66.14,ACTUAL:49.17,DELTA:-16.97},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",DISC:"Instrument",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",DISC:"Painting",PLAN:98.92,ACTUAL:77.76,DELTA:-21.15},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",DISC:"Piping",PLAN:68.66,ACTUAL:27.99,DELTA:-40.67},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",DISC:"Structure",PLAN:97.54,ACTUAL:92.96,DELTA:-4.57},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",DISC:"Fire & Safety",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",DISC:"Mechanical",PLAN:78.75,ACTUAL:82.03,DELTA:3.27},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",DISC:"Fire & Safety",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW04",DISC:"Fire & Safety",PLAN:4.81,ACTUAL:0,DELTA:-4.81},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW04",DISC:"Mechanical",PLAN:99.02,ACTUAL:54.01,DELTA:-45.01},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",DISC:"Fire & Safety",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"TOPSIDES",LV2:"OVERALL",LV3:"OVERALL",DISC:"HVAC",PLAN:88.55,ACTUAL:53.49,DELTA:-35.05},
        {LV1:"INTEGRATION",LV2:"OVERALL",LV3:"OVERALL",DISC:"Instrument",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"TOPSIDES",LV2:"OVERALL",LV3:"OVERALL",DISC:"Instrument",PLAN:5.37,ACTUAL:0,DELTA:-5.37},
        {LV1:"TOPSIDES",LV2:"COMMON",LV3:"OVERALL",DISC:"Electrical",PLAN:49.5,ACTUAL:1.08,DELTA:-48.41},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"OVERALL",DISC:"Electrical",PLAN:34.62,ACTUAL:1.75,DELTA:-32.86},
        {LV1:"TOPSIDES",LV2:"COMMON",LV3:"OVERALL",DISC:"Mechanical",PLAN:34.38,ACTUAL:2.11,DELTA:-32.27},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"OVERALL",DISC:"Structure",PLAN:80.79,ACTUAL:73.29,DELTA:-7.5},
        {LV1:"HULL",LV2:"HULL-AFT",LV3:"HL02",DISC:"Painting",PLAN:49.24,ACTUAL:72.79,DELTA:23.55},
        {LV1:"HULL",LV2:"LQ",LV3:"OVERALL",DISC:"Instrument",PLAN:76.7,ACTUAL:32.74,DELTA:-43.96},
        {LV1:"INTEGRATION",LV2:"INTEGRATION",LV3:"IG90",DISC:"Piping",PLAN:0.78,ACTUAL:12.47,DELTA:11.69},
        {LV1:"TOPSIDES",LV2:"COMMON",LV3:"FL01",DISC:"Insulation",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"TOPSIDES",LV2:"COMMON",LV3:"FL01",DISC:"Piping",PLAN:30.37,ACTUAL:2.62,DELTA:-27.75},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"M000",DISC:"Structure",PLAN:69.41,ACTUAL:40.96,DELTA:-28.44},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",DISC:"Electrical",PLAN:12.66,ACTUAL:2.42,DELTA:-10.24},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",DISC:"Fire & Safety",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",DISC:"Piping",PLAN:45.19,ACTUAL:29.38,DELTA:-15.8},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",DISC:"Structure",PLAN:81.92,ACTUAL:73.68,DELTA:-8.23},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",DISC:"Instrument",PLAN:0.32,ACTUAL:0,DELTA:-0.32},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",DISC:"Instrument",PLAN:0.25,ACTUAL:0,DELTA:-0.25},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW04",DISC:"Instrument",PLAN:36.69,ACTUAL:0,DELTA:-36.69},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",DISC:"Instrument",PLAN:0.03,ACTUAL:0,DELTA:-0.03},
        {LV1:"TOPSIDES",LV2:"OVERALL",LV3:"OVERALL",DISC:"Fire & Safety",PLAN:1.53,ACTUAL:0,DELTA:-1.53},
        {LV1:"HULL",LV2:"OVERALL",LV3:"OVERALL",DISC:"Instrument",PLAN:47.92,ACTUAL:11.93,DELTA:-35.99},
        {LV1:"TOPSIDES",LV2:"OVERALL",LV3:"OVERALL",DISC:"Insulation",PLAN:6.25,ACTUAL:0,DELTA:-6.25},
        {LV1:"INTEGRATION",LV2:"OVERALL",LV3:"OVERALL",DISC:"Painting",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"TOPSIDES",LV2:"OVERALL",LV3:"OVERALL",DISC:"Piping",PLAN:49.54,ACTUAL:23.08,DELTA:-26.45},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"OVERALL",DISC:"Insulation",PLAN:0.66,ACTUAL:0,DELTA:-0.66},
        {LV1:"HULL",LV2:"HULL-AFT",LV3:"HL02",DISC:"Piping",PLAN:97.72,ACTUAL:79.45,DELTA:-18.26},
        {LV1:"HULL",LV2:"HULL-COMMON",LV3:"MD01-2",DISC:"Fire & Safety",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"HULL",LV2:"LQ",LV3:"OVERALL",DISC:"Piping",PLAN:89.61,ACTUAL:72.66,DELTA:-16.95},
        {LV1:"INTEGRATION",LV2:"INTEGRATION",LV3:"IG90",DISC:"Insulation",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"INTEGRATION",LV2:"INTEGRATION",LV3:"IG90",DISC:"Painting",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"INTEGRATION",LV2:"INTEGRATION",LV3:"IG90",DISC:"Structure",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"TOPSIDES",LV2:"COMMON",LV3:"FL01",DISC:"Mechanical",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"TOPSIDES",LV2:"COMMON",LV3:"FL01",DISC:"Painting",PLAN:67.24,ACTUAL:0.35,DELTA:-66.89},
        {LV1:"TOPSIDES",LV2:"COMMON",LV3:"FL01",DISC:"Structure",PLAN:70.97,ACTUAL:46.42,DELTA:-24.55},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",DISC:"Instrument",PLAN:0.1,ACTUAL:0,DELTA:-0.09},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",DISC:"Insulation",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",DISC:"Painting",PLAN:62.1,ACTUAL:43.97,DELTA:-18.12},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",DISC:"Electrical",PLAN:75.77,ACTUAL:13.01,DELTA:-62.75},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",DISC:"Electrical",PLAN:14.83,ACTUAL:0.54,DELTA:-14.28},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",DISC:"Electrical",PLAN:8.5,ACTUAL:1.25,DELTA:-7.24},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW04",DISC:"Electrical",PLAN:55.79,ACTUAL:2.48,DELTA:-53.3},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",DISC:"Electrical",PLAN:15.76,ACTUAL:2.64,DELTA:-13.11},
        {LV1:"HULL",LV2:"OVERALL",LV3:"OVERALL",DISC:"Architecture",PLAN:71.42,ACTUAL:34.64,DELTA:-36.78},
        {LV1:"INTEGRATION",LV2:"OVERALL",LV3:"OVERALL",DISC:"Insulation",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"OVERALL",DISC:"Fire & Safety",PLAN:1.59,ACTUAL:0,DELTA:-1.59},
        {LV1:"TOPSIDES",LV2:"COMMON",LV3:"OVERALL",DISC:"Structure",PLAN:80.15,ACTUAL:67.85,DELTA:-12.3},
        {LV1:"HULL",LV2:"HULL-COMMON",LV3:"HU00",DISC:"Structure",PLAN:92.88,ACTUAL:60.01,DELTA:-32.86},
        {LV1:"HULL",LV2:"HULL-FOR",LV3:"HL03",DISC:"Mechanical",PLAN:94.24,ACTUAL:70.34,DELTA:-23.89},
        {LV1:"HULL",LV2:"HULL-FOR",LV3:"HL03",DISC:"Structure",PLAN:99.98,ACTUAL:99.93,DELTA:-0.04},
        {LV1:"HULL",LV2:"LQ",LV3:"OVERALL",DISC:"Electrical",PLAN:84.12,ACTUAL:53.18,DELTA:-30.94},
        {LV1:"HULL",LV2:"LQ",LV3:"OVERALL",DISC:"HVAC",PLAN:97.19,ACTUAL:80.2,DELTA:-16.99},
        {LV1:"HULL",LV2:"LQ",LV3:"OVERALL",DISC:"Insulation",PLAN:70.7,ACTUAL:0,DELTA:-70.7},
        {LV1:"TOPSIDES",LV2:"COMMON",LV3:"RCxx",DISC:"Structure",PLAN:97.5,ACTUAL:91.02,DELTA:-6.47},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"M000",DISC:"Fire & Safety",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"M000",DISC:"Instrument",PLAN:0.15,ACTUAL:0,DELTA:-0.15},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"M000",DISC:"Piping",PLAN:23.71,ACTUAL:12.8,DELTA:-10.91},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",DISC:"Mechanical",PLAN:82.57,ACTUAL:55,DELTA:-27.57},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",DISC:"Electrical",PLAN:51.46,ACTUAL:1.73,DELTA:-49.73},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",DISC:"Insulation",PLAN:9.64,ACTUAL:0,DELTA:-9.64},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",DISC:"Instrument",PLAN:67.18,ACTUAL:0,DELTA:-67.18},
        {LV1:"HULL",LV2:"OVERALL",LV3:"OVERALL",DISC:"Structure",PLAN:99.76,ACTUAL:98.32,DELTA:-1.44},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"OVERALL",DISC:"Architecture",PLAN:85.37,ACTUAL:14.35,DELTA:-71.02},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"OVERALL",DISC:"Painting",PLAN:68.78,ACTUAL:35.34,DELTA:-33.44},
        {LV1:"HULL",LV2:"HULL-AFT",LV3:"HL02",DISC:"Electrical",PLAN:100,ACTUAL:98.61,DELTA:-1.38},
        {LV1:"HULL",LV2:"HULL-AFT",LV3:"RB01-02",DISC:"Painting",PLAN:100,ACTUAL:91.26,DELTA:-8.73},
        {LV1:"HULL",LV2:"HULL-COMMON",LV3:"MD01-2",DISC:"Electrical",PLAN:61.51,ACTUAL:10.02,DELTA:-51.48},
        {LV1:"HULL",LV2:"HULL-COMMON",LV3:"MD01-2",DISC:"Painting",PLAN:54.86,ACTUAL:18.02,DELTA:-36.83},
        {LV1:"HULL",LV2:"HULL-FOR",LV3:"HL03",DISC:"Architecture",PLAN:79.4,ACTUAL:31.91,DELTA:-47.48},
        {LV1:"HULL",LV2:"HULL-FOR",LV3:"HL03",DISC:"Electrical",PLAN:89.23,ACTUAL:73.33,DELTA:-15.9},
        {LV1:"HULL",LV2:"HULL-FOR",LV3:"HL03",DISC:"HVAC",PLAN:100,ACTUAL:85.13,DELTA:-14.86},
        {LV1:"HULL",LV2:"LQ",LV3:"OVERALL",DISC:"Structure",PLAN:99.13,ACTUAL:95.97,DELTA:-3.16},
        {LV1:"TOPSIDES",LV2:"COMMON",LV3:"FL01",DISC:"Fire & Safety",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"TOPSIDES",LV2:"COMMON",LV3:"FL01",DISC:"Instrument",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"TOPSIDES",LV2:"COMMON",LV3:"KO01",DISC:"Instrument",PLAN:0,ACTUAL:0.01,DELTA:0.01},
        {LV1:"TOPSIDES",LV2:"COMMON",LV3:"KO01",DISC:"Insulation",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"TOPSIDES",LV2:"COMMON",LV3:"KO01",DISC:"Piping",PLAN:21.49,ACTUAL:5.62,DELTA:-15.87},
        {LV1:"TOPSIDES",LV2:"COMMON",LV3:"RCxx",DISC:"Electrical",PLAN:95.92,ACTUAL:2.86,DELTA:-93.06},
        {LV1:"TOPSIDES",LV2:"COMMON",LV3:"RCxx",DISC:"Insulation",PLAN:89.42,ACTUAL:0,DELTA:-89.42},
        {LV1:"TOPSIDES",LV2:"COMMON",LV3:"RCxx",DISC:"Piping",PLAN:99.96,ACTUAL:26.46,DELTA:-73.49},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"M000",DISC:"Mechanical",PLAN:86.9,ACTUAL:1.52,DELTA:-85.38},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",DISC:"Mechanical",PLAN:99.03,ACTUAL:42.66,DELTA:-56.37},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",DISC:"Mechanical",PLAN:84.52,ACTUAL:77.95,DELTA:-6.57},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",DISC:"Mechanical",PLAN:73.89,ACTUAL:46.19,DELTA:-27.7},
        {LV1:"HULL",LV2:"OVERALL",LV3:"OVERALL",DISC:"Electrical",PLAN:86.57,ACTUAL:64.07,DELTA:-22.49},
        {LV1:"HULL",LV2:"OVERALL",LV3:"OVERALL",DISC:"Mechanical",PLAN:78.76,ACTUAL:50.65,DELTA:-28.11},
        {LV1:"TOPSIDES",LV2:"OVERALL",LV3:"OVERALL",DISC:"Painting",PLAN:69.87,ACTUAL:34.04,DELTA:-35.83},
        {LV1:"TOPSIDES",LV2:"COMMON",LV3:"OVERALL",DISC:"Instrument",PLAN:0,ACTUAL:0.01,DELTA:0.01},
        {LV1:"HULL",LV2:"HULL-AFT",LV3:"RB01-02",DISC:"Mechanical",PLAN:27,ACTUAL:24.19,DELTA:-2.8},
        {LV1:"HULL",LV2:"HULL-COMMON",LV3:"MD01-2",DISC:"Piping",PLAN:63.89,ACTUAL:36.27,DELTA:-27.61},
        {LV1:"HULL",LV2:"HULL-FOR",LV3:"HL03",DISC:"Painting",PLAN:95.32,ACTUAL:81.8,DELTA:-13.51},
        {LV1:"HULL",LV2:"HULL-FOR",LV3:"HL03",DISC:"Piping",PLAN:96.4,ACTUAL:70.26,DELTA:-26.14},
        {LV1:"INTEGRATION",LV2:"INTEGRATION",LV3:"IG90",DISC:"Electrical",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"TOPSIDES",LV2:"COMMON",LV3:"KO01",DISC:"Structure",PLAN:35,ACTUAL:48.68,DELTA:13.67},
        {LV1:"TOPSIDES",LV2:"COMMON",LV3:"RCxx",DISC:"Painting",PLAN:98.85,ACTUAL:54.74,DELTA:-44.11},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",DISC:"Insulation",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",DISC:"Piping",PLAN:42.37,ACTUAL:27.11,DELTA:-15.26},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",DISC:"Structure",PLAN:75.94,ACTUAL:71.11,DELTA:-4.83},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",DISC:"Electrical",PLAN:13.5,ACTUAL:0.22,DELTA:-13.28},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",DISC:"Painting",PLAN:89.77,ACTUAL:59.87,DELTA:-29.9},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",DISC:"Piping",PLAN:83.36,ACTUAL:39.91,DELTA:-43.44},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",DISC:"Architecture",PLAN:85.37,ACTUAL:14.35,DELTA:-71.02},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",DISC:"Fire & Safety",PLAN:7.8,ACTUAL:0,DELTA:-7.8},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",DISC:"HVAC",PLAN:88.55,ACTUAL:53.49,DELTA:-35.05},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",DISC:"Insulation",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",DISC:"Piping",PLAN:45.69,ACTUAL:19.41,DELTA:-26.28},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",DISC:"Structure",PLAN:73.84,ACTUAL:66.93,DELTA:-6.9},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",DISC:"Insulation",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",DISC:"Painting",PLAN:74.42,ACTUAL:33.43,DELTA:-40.99},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",DISC:"Piping",PLAN:49.19,ACTUAL:28.54,DELTA:-20.64},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",DISC:"Structure",PLAN:78.95,ACTUAL:74.04,DELTA:-4.9},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW04",DISC:"Insulation",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW04",DISC:"Structure",PLAN:95.08,ACTUAL:87.03,DELTA:-8.05},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",DISC:"Insulation",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",DISC:"Painting",PLAN:59.07,ACTUAL:42.51,DELTA:-16.55},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",DISC:"Piping",PLAN:60.34,ACTUAL:32.6,DELTA:-27.74},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",DISC:"Structure",PLAN:80.46,ACTUAL:76.8,DELTA:-3.65},
        {LV1:"TOPSIDES",LV2:"OVERALL",LV3:"OVERALL",DISC:"Architecture",PLAN:85.37,ACTUAL:14.35,DELTA:-71.02},
        {LV1:"TOPSIDES",LV2:"OVERALL",LV3:"OVERALL",DISC:"Electrical",PLAN:35.58,ACTUAL:1.71,DELTA:-33.87},
        {LV1:"HULL",LV2:"OVERALL",LV3:"OVERALL",DISC:"Insulation",PLAN:32.03,ACTUAL:0,DELTA:-32.03},
        {LV1:"HULL",LV2:"OVERALL",LV3:"OVERALL",DISC:"Painting",PLAN:76.96,ACTUAL:75.65,DELTA:-1.3},
        {LV1:"HULL",LV2:"OVERALL",LV3:"OVERALL",DISC:"Piping",PLAN:80.93,ACTUAL:55.9,DELTA:-25.03},
        {LV1:"TOPSIDES",LV2:"OVERALL",LV3:"OVERALL",DISC:"Structure",PLAN:80.7,ACTUAL:72.54,DELTA:-8.16},
        {LV1:"TOPSIDES",LV2:"COMMON",LV3:"OVERALL",DISC:"Fire & Safety",PLAN:0,ACTUAL:0,DELTA:0},
        {LV1:"TOPSIDES",LV2:"MODULES",LV3:"OVERALL",DISC:"Instrument",PLAN:5.41,ACTUAL:0,DELTA:-5.41}
      ],
      SQL5: [
        {NO:1,LV1:"Project",LV2:"Construction",PLAN:50.7,ACTUAL:47.14,ACTUAL_CERT:33.81,DELTA:-3.55,PLAN_WK:1.25,ACTUAL_WK:0.7,ACTUAL_CERT_WK:0.31,DELTA_WK:-0.54}
      ],
      SQL6:[
        { GWBS_LV1: 'HULL & LQ', DATAURL: '', DIMENTION: '8272,3782', PATH: '/shared/gallery/photo/photo-gallery-1604561073.jpeg' },
        { GWBS_LV1: 'LQ'       , DATAURL: '', DIMENTION: '3024,4032', PATH: '/shared/gallery/photo/photo-gallery-1602470089.jpeg' },
        { GWBS_LV1: 'TOPSIDES' , DATAURL: 'test', DIMENTION: '3024,4032', PATH: '/shared/gallery/photo/photo-gallery-1604565816.jpeg' },
        { GWBS_LV1: 'TOPSIDES COMMON' , DATAURL: 'test', DIMENTION: '3024,4032', PATH: '/shared/gallery/photo/photo-gallery-1604565816.jpeg' },
        { GWBS_LV1: 'TOPSIDES MODULES' , DATAURL: 'test', DIMENTION: '3024,4032', PATH: '/shared/gallery/photo/photo-gallery-1604565816.jpeg' },
      ]
    },
    DataItems: [
      {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME02",LV3_DESCR:"MEG & Fule Gas",PLAN_PROG:50.86,ACTUAL_PROG:38.33,DELTA:-12.53,PLAN_PROG_WK:2.53,ACTUAL_PROG_WK:1.18,DELTA_WK:-1.35,FAB_START_TOTAL:7,FAB_START_ACTUAL:7,FAB_COMP_TOTAL:7,FAB_COMP_ACTUAL:7,PAINTING_TOTAL:7,PAINTING_ACTUAL:5,BLOCKASSY_TOTAL:7,BLOCKASSY_ACTUAL:4,EREC_TOTAL:7,EREC_ACTUAL:4},
      {LV1:"HULL",LV2:"LQ",LV3:"OVERALL",PLAN_PROG:87.96,ACTUAL_PROG:65.54,DELTA:-22.41,PLAN_PROG_WK:1.66,ACTUAL_PROG_WK:1.52,DELTA_WK:-0.14,FAB_START_TOTAL:100,FAB_START_ACTUAL:100,FAB_COMP_TOTAL:100,FAB_COMP_ACTUAL:100,PAINTING_TOTAL:100,PAINTING_ACTUAL:100,BLOCKASSY_TOTAL:0,BLOCKASSY_ACTUAL:0,EREC_TOTAL:102,EREC_ACTUAL:99},
      {LV1:"HULL",LV2:"OVERALL",LV3:"OVERALL",PLAN_PROG:87.67,ACTUAL_PROG:80.63,DELTA:-7.04,PLAN_PROG_WK:1.31,ACTUAL_PROG_WK:1.71,DELTA_WK:0.4,FAB_START_TOTAL:490,FAB_START_ACTUAL:490,FAB_COMP_TOTAL:490,FAB_COMP_ACTUAL:490,PAINTING_TOTAL:490,PAINTING_ACTUAL:490,BLOCKASSY_TOTAL:123,BLOCKASSY_ACTUAL:122,EREC_TOTAL:359,EREC_ACTUAL:350},
      {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME01",LV3_DESCR:"Flash Gas Comp.",PLAN_PROG:51.57,ACTUAL_PROG:39.03,DELTA:-12.53,PLAN_PROG_WK:2.3,ACTUAL_PROG_WK:1.33,DELTA_WK:-0.96,FAB_START_TOTAL:10,FAB_START_ACTUAL:10,FAB_COMP_TOTAL:10,FAB_COMP_ACTUAL:10,PAINTING_TOTAL:10,PAINTING_ACTUAL:8,BLOCKASSY_TOTAL:10,BLOCKASSY_ACTUAL:5,EREC_TOTAL:10,EREC_ACTUAL:4},
      {LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW01",LV3_DESCR:"Recpt. Facil. & Prod. Sepn.",PLAN_PROG:52.09,ACTUAL_PROG:34.79,DELTA:-17.29,PLAN_PROG_WK:2.9,ACTUAL_PROG_WK:3.18,DELTA_WK:0.27,FAB_START_TOTAL:12,FAB_START_ACTUAL:11,FAB_COMP_TOTAL:12,FAB_COMP_ACTUAL:11,PAINTING_TOTAL:12,PAINTING_ACTUAL:8,BLOCKASSY_TOTAL:12,BLOCKASSY_ACTUAL:5,EREC_TOTAL:12,EREC_ACTUAL:4},
      {LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW04",LV3_DESCR:"Power Gen.",PLAN_PROG:83.03,ACTUAL_PROG:53.62,DELTA:-29.4,PLAN_PROG_WK:3.28,ACTUAL_PROG_WK:1.05,DELTA_WK:-2.22,FAB_START_TOTAL:7,FAB_START_ACTUAL:6,FAB_COMP_TOTAL:7,FAB_COMP_ACTUAL:6,PAINTING_TOTAL:7,PAINTING_ACTUAL:5,BLOCKASSY_TOTAL:7,BLOCKASSY_ACTUAL:4,EREC_TOTAL:7,EREC_ACTUAL:3},
      {LV1:"HULL",LV2:"HULL-AFT",LV3:"HL02",LV3_DESCR:"Hull AFT SHIP",PLAN_PROG:83.78,ACTUAL_PROG:89.56,DELTA:5.77,PLAN_PROG_WK:1.24,ACTUAL_PROG_WK:1.69,DELTA_WK:0.44,FAB_START_TOTAL:144,FAB_START_ACTUAL:144,FAB_COMP_TOTAL:144,FAB_COMP_ACTUAL:144,PAINTING_TOTAL:144,PAINTING_ACTUAL:144,BLOCKASSY_TOTAL:50,BLOCKASSY_ACTUAL:50,EREC_TOTAL:89,EREC_ACTUAL:86},
      {LV1:"TOPSIDES",LV2:"OVERALL",LV3:"OVERALL",PLAN_PROG:58.82,ACTUAL_PROG:35.99,DELTA:-22.83,PLAN_PROG_WK:2.67,ACTUAL_PROG_WK:1.76,DELTA_WK:-0.91,FAB_START_TOTAL:78,FAB_START_ACTUAL:73,FAB_COMP_TOTAL:78,FAB_COMP_ACTUAL:73,PAINTING_TOTAL:78,PAINTING_ACTUAL:59,BLOCKASSY_TOTAL:78,BLOCKASSY_ACTUAL:40,EREC_TOTAL:78,EREC_ACTUAL:34},
      {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME04",LV3_DESCR:"Power Gen.",PLAN_PROG:80.74,ACTUAL_PROG:51.19,DELTA:-29.54,PLAN_PROG_WK:3.73,ACTUAL_PROG_WK:0.68,DELTA_WK:-3.05,FAB_START_TOTAL:7,FAB_START_ACTUAL:6,FAB_COMP_TOTAL:7,FAB_COMP_ACTUAL:6,PAINTING_TOTAL:7,PAINTING_ACTUAL:5,BLOCKASSY_TOTAL:7,BLOCKASSY_ACTUAL:4,EREC_TOTAL:7,EREC_ACTUAL:3},
      {LV1:"HULL",LV2:"HULL-FOR",LV3:"HL03",LV3_DESCR:"Hull FOR SHIP",PLAN_PROG:96.34,ACTUAL_PROG:86.3,DELTA:-10.04,PLAN_PROG_WK:1.3,ACTUAL_PROG_WK:1.89,DELTA_WK:0.58,FAB_START_TOTAL:180,FAB_START_ACTUAL:180,FAB_COMP_TOTAL:180,FAB_COMP_ACTUAL:180,PAINTING_TOTAL:180,PAINTING_ACTUAL:180,BLOCKASSY_TOTAL:60,BLOCKASSY_ACTUAL:59,EREC_TOTAL:102,EREC_ACTUAL:101},
      {LV1:"TOPSIDES",LV2:"MODULES",LV3:"ME05",LV3_DESCR:"E/I Bldg.",PLAN_PROG:90.62,ACTUAL_PROG:61.45,DELTA:-29.16,PLAN_PROG_WK:3.45,ACTUAL_PROG_WK:2.82,DELTA_WK:-0.62,FAB_START_TOTAL:7,FAB_START_ACTUAL:7,FAB_COMP_TOTAL:7,FAB_COMP_ACTUAL:7,PAINTING_TOTAL:7,PAINTING_ACTUAL:6,BLOCKASSY_TOTAL:7,BLOCKASSY_ACTUAL:6,EREC_TOTAL:7,EREC_ACTUAL:6},
      {LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW02",LV3_DESCR:"HC Dewpt. & Conden. Stabil.",PLAN_PROG:56.09,ACTUAL_PROG:39.08,DELTA:-17.01,PLAN_PROG_WK:2.49,ACTUAL_PROG_WK:2.02,DELTA_WK:-0.47,FAB_START_TOTAL:11,FAB_START_ACTUAL:10,FAB_COMP_TOTAL:11,FAB_COMP_ACTUAL:10,PAINTING_TOTAL:11,PAINTING_ACTUAL:9,BLOCKASSY_TOTAL:11,BLOCKASSY_ACTUAL:5,EREC_TOTAL:11,EREC_ACTUAL:4},
      {LV1:"TOPSIDES",LV2:"MODULES",LV3:"MW05",LV3_DESCR:"Utility",PLAN_PROG:58.94,ACTUAL_PROG:44.09,DELTA:-14.84,PLAN_PROG_WK:3.05,ACTUAL_PROG_WK:2.27,DELTA_WK:-0.78,FAB_START_TOTAL:10,FAB_START_ACTUAL:10,FAB_COMP_TOTAL:10,FAB_COMP_ACTUAL:10,PAINTING_TOTAL:10,PAINTING_ACTUAL:9,BLOCKASSY_TOTAL:10,BLOCKASSY_ACTUAL:7,EREC_TOTAL:10,EREC_ACTUAL:6},
      {LV1:"TOPSIDES",LV2:"MODULES",LV3:"OVERALL",PLAN_PROG:58.94,ACTUAL_PROG:44.09,DELTA:-14.84,PLAN_PROG_WK:3.05,ACTUAL_PROG_WK:2.27,DELTA_WK:-0.78,FAB_START_TOTAL:10,FAB_START_ACTUAL:10,FAB_COMP_TOTAL:10,FAB_COMP_ACTUAL:10,PAINTING_TOTAL:10,PAINTING_ACTUAL:9,BLOCKASSY_TOTAL:10,BLOCKASSY_ACTUAL:7,EREC_TOTAL:10,EREC_ACTUAL:6},
      {LV1:"TOPSIDES",LV2:"COMMON" ,LV3:"OVERALL",PLAN_PROG:58.94,ACTUAL_PROG:44.09,DELTA:-14.84,PLAN_PROG_WK:3.05,ACTUAL_PROG_WK:2.27,DELTA_WK:-0.78,FAB_START_TOTAL:10,FAB_START_ACTUAL:10,FAB_COMP_TOTAL:10,FAB_COMP_ACTUAL:10,PAINTING_TOTAL:10,PAINTING_ACTUAL:9,BLOCKASSY_TOTAL:10,BLOCKASSY_ACTUAL:7,EREC_TOTAL:10,EREC_ACTUAL:6},
      {LV1:"TOPSIDES",LV2:"COMMON",LV3:"KO01",LV3_DESCR:"Utility",PLAN_PROG:58.94,ACTUAL_PROG:44.09,DELTA:-14.84,PLAN_PROG_WK:3.05,ACTUAL_PROG_WK:2.27,DELTA_WK:-0.78,FAB_START_TOTAL:10,FAB_START_ACTUAL:10,FAB_COMP_TOTAL:10,FAB_COMP_ACTUAL:10,PAINTING_TOTAL:10,PAINTING_ACTUAL:9,BLOCKASSY_TOTAL:10,BLOCKASSY_ACTUAL:7,EREC_TOTAL:10,EREC_ACTUAL:6},
      {LV1:"TOPSIDES",LV2:"COMMON",LV3:"FL01",LV3_DESCR:"Utility",PLAN_PROG:58.94,ACTUAL_PROG:44.09,DELTA:-14.84,PLAN_PROG_WK:3.05,ACTUAL_PROG_WK:2.27,DELTA_WK:-0.78,FAB_START_TOTAL:10,FAB_START_ACTUAL:10,FAB_COMP_TOTAL:10,FAB_COMP_ACTUAL:10,PAINTING_TOTAL:10,PAINTING_ACTUAL:9,BLOCKASSY_TOTAL:10,BLOCKASSY_ACTUAL:7,EREC_TOTAL:10,EREC_ACTUAL:6},
      {LV1:"TOPSIDES",LV2:"COMMON",LV3:"RCxx",LV3_DESCR:"Utility",PLAN_PROG:58.94,ACTUAL_PROG:44.09,DELTA:-14.84,PLAN_PROG_WK:3.05,ACTUAL_PROG_WK:2.27,DELTA_WK:-0.78,FAB_START_TOTAL:10,FAB_START_ACTUAL:10,FAB_COMP_TOTAL:10,FAB_COMP_ACTUAL:10,PAINTING_TOTAL:10,PAINTING_ACTUAL:9,BLOCKASSY_TOTAL:10,BLOCKASSY_ACTUAL:7,EREC_TOTAL:10,EREC_ACTUAL:6}
    ],
}