import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 460,
      CanvasHeight: 280,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

    Note: {
      NoteDisplay: 'Y',
      NoteX: 633,
      NoteY: 358,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',
  
      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC'
    },

    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },

    DataItems: [
      { TOTAL: 2492, ISSUED_IFC: 717, NEED_ISSUE: 40, NO_NEEDED_IFC: 1735,}
      // { TOTAL: 1444, NOTSTARTED: 430, DELETED: 1099, VOID: 119, REVIEW: 1436, AFC: 549 }
    ],
}