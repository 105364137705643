import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 1920,
    CanvasHeight: 400,
    CanvasBorderWeight: 1,
    CanvasBorderColor: '#000',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.2,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 860,
    CanvasChartHeight: 310,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: 'Transparent',
    CanvasChartFillColor: 'Transparent',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },

  // ----------------------------------------------- Filter Text
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },
  // -----------------------------------------------------------
  
  Queries: {
    SQL1:  [{
      START_DATE:"2021-04-01",
      END_DATE:"2024-09-30",
      CUTOFF:"2023-01-01",
      PACKAGES: [
        {LEVEL:0,P6_LV1:"P1"}
      ],
      AREAS: [
        {LEVEL:1,P6_LV1:"P1",P6_LV2:"DEM"},
        {LEVEL:1,P6_LV1:"P1",P6_LV2:"BOILER"},
        {LEVEL:1,P6_LV1:"P1",P6_LV2:"FUR"},
        {LEVEL:1,P6_LV1:"P1",P6_LV2:"OR"},
        {LEVEL:1,P6_LV1:"P1",P6_LV2:"BD"},
        {LEVEL:1,P6_LV1:"P1",P6_LV2:"BTX"},
        {LEVEL:1,P6_LV1:"P1",P6_LV2:"DIB"},
        {LEVEL:1,P6_LV1:"P1",P6_LV2:"GHU"}
      ],
      PHASES: [
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Construction"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Engineering"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Procurement"},
        {LEVEL:2,P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Procurement"}
      ]
    }
  ]
  },
  DataItems:  [
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P1",P6_LV5:"Civil",LEVEL:"0",ITEM_DESC:"CIVIL",MS_PLAN:"2021-09-18",MS_ACT:"2021-09-18",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P1",P6_LV5:"Mechanical",LEVEL:"0",ITEM_DESC:"MECH",MS_PLAN:"2021-05-21",MS_ACT:"2021-05-21",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P1",P6_LV5:"Piping",LEVEL:"0",ITEM_DESC:"PIPE",MS_PLAN:"2021-11-27",MS_ACT:"2021-11-27",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P1",P6_LV5:"Structure",LEVEL:"0",ITEM_DESC:"STL",MS_PLAN:"2022-02-07",MS_ACT:"2022-02-07",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P1",P6_LV5:"Electrical",LEVEL:"0",ITEM_DESC:"ELECT",MS_PLAN:"2022-02-07",MS_ACT:"2022-02-07",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P1",P6_LV5:"MC",LEVEL:"0",ITEM_DESC:"MC",MS_PLAN:"2024-10-31",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {ACTIVITY_ID:"P1",ACTIVITY_NAME:"P1",BL0_SD:"2021-05-21",BL0_FD:"2024-10-12",PLAN_SD:"2021-05-21",PLAN_FD:"2024-09-30",ACT_SD:"2021-05-21",P6_LV1:"P1",LEVEL:"0",ITEM_DESC:"Overall",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"Engineering",ACTIVITY_NAME:"Engineering",BL0_SD:"2021-05-21",BL0_FD:"2024-05-27",PLAN_SD:"2021-05-21",PLAN_FD:"2024-06-11",ACT_SD:"2021-05-21",P6_LV1:"P1",LEVEL:"0",ITEM_DESC:"Engineering",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"Procurement",ACTIVITY_NAME:"Procurement",BL0_SD:"2021-06-01",BL0_FD:"2024-07-26",PLAN_SD:"2021-06-01",PLAN_FD:"2024-06-22",ACT_SD:"2021-06-01",P6_LV1:"P1",LEVEL:"0",ITEM_DESC:"Procurement",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"Construction",ACTIVITY_NAME:"Construction",BL0_SD:"2021-12-24",BL0_FD:"2024-10-12",PLAN_SD:"2021-12-24",PLAN_FD:"2024-09-30",ACT_SD:"2021-12-24",P6_LV1:"P1",LEVEL:"0",ITEM_DESC:"Construction",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV5_SEQ:99,ITEM_SEQ:5},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P1",P6_LV2:"DEM",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2024-03-21",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P1",P6_LV2:"DEM",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2022-02-07",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P1",P6_LV2:"DEM",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-02-07",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P1",P6_LV2:"DEM",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2021-09-29",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P1",P6_LV2:"DEM",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-02-07",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P1",P6_LV2:"DEM",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-05-17",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {BL0_SD:"2021-09-29",BL0_FD:"2024-03-21",PLAN_SD:"2021-09-29",PLAN_FD:"2024-03-16",ACT_SD:"2021-09-29",P6_LV1:"P1",P6_LV2:"DEM",LEVEL:"1",ITEM_DESC:"Overall",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"DEM",ACTIVITY_NAME:"DEM",BL0_SD:"2021-09-29",BL0_FD:"2023-06-16",PLAN_SD:"2021-09-29",PLAN_FD:"2023-07-07",ACT_SD:"2021-09-29",P6_LV1:"P1",P6_LV2:"DEM",LEVEL:"1",ITEM_DESC:"Engineering",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"DEM",ACTIVITY_NAME:"DEM",BL0_SD:"2021-12-12",BL0_FD:"2023-08-01",PLAN_SD:"2021-12-12",PLAN_FD:"2023-08-30",ACT_SD:"2021-12-12",P6_LV1:"P1",P6_LV2:"DEM",LEVEL:"1",ITEM_DESC:"Procurement",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"DEM",ACTIVITY_NAME:"DEM",BL0_SD:"2022-04-30",BL0_FD:"2024-03-21",PLAN_SD:"2022-04-29",PLAN_FD:"2024-03-16",ACT_SD:"2022-04-29",P6_LV1:"P1",P6_LV2:"DEM",LEVEL:"1",ITEM_DESC:"Construction",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV5_SEQ:99,ITEM_SEQ:5},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-12-20",BL0_FD:"2023-05-05",PLAN_SD:"2021-12-20",PLAN_FD:"2023-07-07",ACT_SD:"2021-12-20",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-02-07",BL0_FD:"2022-07-26",PLAN_SD:"2022-02-07",PLAN_FD:"2022-12-24",ACT_SD:"2022-02-07",ACT_FD:"2022-12-24",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-05-17",BL0_FD:"2022-12-21",PLAN_SD:"2022-03-14",PLAN_FD:"2023-03-10",ACT_SD:"2022-03-14",ACT_FD:"2023-01-17",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-02-07",BL0_FD:"2023-06-02",PLAN_SD:"2022-02-07",PLAN_FD:"2023-07-07",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-09-29",BL0_FD:"2023-05-19",PLAN_SD:"2021-09-29",PLAN_FD:"2023-06-14",ACT_SD:"2021-09-29",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-02-07",BL0_FD:"2023-06-16",PLAN_SD:"2022-02-07",PLAN_FD:"2023-06-23",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-08-10",BL0_FD:"2023-06-02",PLAN_SD:"2022-08-10",PLAN_FD:"2023-06-12",ACT_SD:"2022-08-10",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Engineering",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-03-07",BL0_FD:"2023-06-02",PLAN_SD:"2022-03-07",PLAN_FD:"2023-06-28",ACT_SD:"2022-03-07",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-10-19",BL0_FD:"2022-10-19",PLAN_SD:"2022-11-18",PLAN_FD:"2023-01-20",ACT_SD:"2022-11-18",ACT_FD:"2023-01-20",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Procurement",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV3_SEQ:3,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-04-30",BL0_FD:"2023-08-01",PLAN_SD:"2022-04-30",PLAN_FD:"2023-08-30",ACT_SD:"2022-04-30",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-12-12",BL0_FD:"2023-06-15",PLAN_SD:"2021-12-12",PLAN_FD:"2023-07-25",ACT_SD:"2021-12-12",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-08-05",BL0_FD:"2023-02-16",PLAN_SD:"2022-05-07",PLAN_FD:"2023-08-13",ACT_SD:"2022-05-07",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-04-02",BL0_FD:"2023-08-01",PLAN_SD:"2022-04-02",PLAN_FD:"2023-08-22",ACT_SD:"2022-04-02",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-04-30",BL0_FD:"2022-11-11",PLAN_SD:"2022-04-29",PLAN_FD:"2023-03-20",ACT_SD:"2022-04-29",ACT_FD:"2022-12-15",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV3_SEQ:5,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-12-21",BL0_FD:"2023-08-01",PLAN_SD:"2023-02-21",PLAN_FD:"2023-09-30",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Construction",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV3_SEQ:5,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-11-10",BL0_FD:"2024-02-19",PLAN_SD:"2022-11-25",PLAN_FD:"2024-03-16",ACT_SD:"2022-11-25",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV3_SEQ:5,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2023-02-10",BL0_FD:"2023-05-05",PLAN_SD:"2023-03-31",PLAN_FD:"2023-08-30",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV3_SEQ:5,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2023-08-25",BL0_FD:"2024-02-21",PLAN_SD:"2023-08-03",PLAN_FD:"2024-02-20",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV3_SEQ:5,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-08-02",BL0_FD:"2024-01-06",PLAN_SD:"2023-07-17",PLAN_FD:"2024-01-31",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV3_SEQ:5,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Painting",ACTIVITY_NAME:"Painting",BL0_SD:"2023-09-16",BL0_FD:"2023-09-16",PLAN_SD:"2023-10-08",PLAN_FD:"2023-12-10",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Construction",P6_LV5:"Painting",LEVEL:"2",ITEM_DESC:"Painting",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV3_SEQ:5,P6_LV5_SEQ:50,ITEM_SEQ:50},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",BL0_SD:"2024-02-19",BL0_FD:"2024-03-21",PLAN_SD:"2024-02-16",PLAN_FD:"2024-03-16",P6_LV1:"P1",P6_LV2:"DEM",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:110,P6_LV3_SEQ:5,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2022-01-30",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-02-07",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2021-06-15",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-01-05",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-02-07",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2024-04-05",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {BL0_SD:"2021-06-15",BL0_FD:"2024-04-04",PLAN_SD:"2021-06-15",PLAN_FD:"2024-04-01",ACT_SD:"2021-06-15",P6_LV1:"P1",P6_LV2:"BOILER",LEVEL:"1",ITEM_DESC:"Overall",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"BOILER",ACTIVITY_NAME:"BOILER",BL0_SD:"2021-06-15",BL0_FD:"2023-06-15",PLAN_SD:"2021-06-15",PLAN_FD:"2023-07-05",ACT_SD:"2021-06-15",P6_LV1:"P1",P6_LV2:"BOILER",LEVEL:"1",ITEM_DESC:"Engineering",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"BOILER",ACTIVITY_NAME:"BOILER",BL0_SD:"2021-10-08",BL0_FD:"2023-08-19",PLAN_SD:"2021-10-08",PLAN_FD:"2023-10-18",ACT_SD:"2021-10-08",P6_LV1:"P1",P6_LV2:"BOILER",LEVEL:"1",ITEM_DESC:"Procurement",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"BOILER",ACTIVITY_NAME:"BOILER",BL0_SD:"2022-07-01",BL0_FD:"2024-04-04",PLAN_SD:"2022-07-11",PLAN_FD:"2024-04-01",ACT_SD:"2022-07-11",P6_LV1:"P1",P6_LV2:"BOILER",LEVEL:"1",ITEM_DESC:"Construction",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV5_SEQ:99,ITEM_SEQ:5},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-12-06",BL0_FD:"2023-04-28",PLAN_SD:"2021-12-06",PLAN_FD:"2023-06-30",ACT_SD:"2021-12-06",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-01-30",BL0_FD:"2022-08-24",PLAN_SD:"2022-01-30",PLAN_FD:"2022-12-24",ACT_SD:"2022-01-30",ACT_FD:"2022-12-24",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-03-21",BL0_FD:"2023-03-08",PLAN_SD:"2022-03-21",PLAN_FD:"2023-05-19",ACT_SD:"2022-03-21",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Engineering",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-02-07",BL0_FD:"2022-12-13",PLAN_SD:"2022-02-07",PLAN_FD:"2023-03-10",ACT_SD:"2022-02-07",ACT_FD:"2023-01-18",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-01-05",BL0_FD:"2023-06-05",PLAN_SD:"2022-01-05",PLAN_FD:"2023-06-28",ACT_SD:"2022-01-05",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-06-15",BL0_FD:"2023-05-05",PLAN_SD:"2021-06-15",PLAN_FD:"2023-06-30",ACT_SD:"2021-06-15",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-02-07",BL0_FD:"2023-06-15",PLAN_SD:"2022-02-07",PLAN_FD:"2023-07-05",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-06-01",BL0_FD:"2023-05-22",PLAN_SD:"2022-05-30",PLAN_FD:"2023-05-25",ACT_SD:"2022-05-30",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Engineering",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-02-18",BL0_FD:"2023-06-05",PLAN_SD:"2022-02-18",PLAN_FD:"2023-06-27",ACT_SD:"2022-02-18",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-09-17",BL0_FD:"2022-09-17",PLAN_SD:"2022-10-31",PLAN_FD:"2023-03-07",ACT_SD:"2022-10-31",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Procurement",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV3_SEQ:3,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-04-30",BL0_FD:"2023-08-19",PLAN_SD:"2022-04-30",PLAN_FD:"2023-09-30",ACT_SD:"2022-04-30",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-10-08",BL0_FD:"2023-06-30",PLAN_SD:"2021-10-08",PLAN_FD:"2023-09-15",ACT_SD:"2021-10-08",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-08-04",BL0_FD:"2023-04-16",PLAN_SD:"2022-07-18",PLAN_FD:"2023-10-18",ACT_SD:"2022-07-18",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-03-18",BL0_FD:"2023-07-20",PLAN_SD:"2022-03-18",PLAN_FD:"2023-08-12",ACT_SD:"2022-03-18",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-07-01",BL0_FD:"2022-09-25",PLAN_SD:"2022-07-11",PLAN_FD:"2023-04-14",ACT_SD:"2022-07-11",ACT_FD:"2023-01-22",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV3_SEQ:5,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-09-21",BL0_FD:"2023-05-22",PLAN_SD:"2022-08-12",PLAN_FD:"2023-05-27",ACT_SD:"2022-08-12",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Construction",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV3_SEQ:5,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-12-21",BL0_FD:"2023-03-01",PLAN_SD:"2023-02-11",PLAN_FD:"2023-05-06",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Construction",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV3_SEQ:5,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-11-22",BL0_FD:"2024-03-07",PLAN_SD:"2022-11-28",PLAN_FD:"2024-03-30",ACT_SD:"2022-11-28",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV3_SEQ:5,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2023-03-31",BL0_FD:"2023-11-22",PLAN_SD:"2023-04-01",PLAN_FD:"2024-01-24",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV3_SEQ:5,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2023-05-31",BL0_FD:"2024-02-19",PLAN_SD:"2023-06-16",PLAN_FD:"2024-03-27",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV3_SEQ:5,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-06-29",BL0_FD:"2024-03-11",PLAN_SD:"2023-06-22",PLAN_FD:"2024-03-26",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV3_SEQ:5,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",BL0_SD:"2024-03-07",BL0_FD:"2024-04-04",PLAN_SD:"2024-03-07",PLAN_FD:"2024-04-01",P6_LV1:"P1",P6_LV2:"BOILER",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:120,P6_LV3_SEQ:5,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P1",P6_LV2:"FUR",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2021-11-26",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P1",P6_LV2:"FUR",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-05-25",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P1",P6_LV2:"FUR",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2021-06-18",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P1",P6_LV2:"FUR",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2021-11-27",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P1",P6_LV2:"FUR",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-02-07",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P1",P6_LV2:"FUR",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2024-10-31",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {BL0_SD:"2021-06-18",BL0_FD:"2024-09-05",PLAN_SD:"2021-06-18",PLAN_FD:"2024-09-30",ACT_SD:"2021-06-18",P6_LV1:"P1",P6_LV2:"FUR",LEVEL:"1",ITEM_DESC:"Overall",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"FUR",ACTIVITY_NAME:"FUR",BL0_SD:"2021-09-01",BL0_FD:"2023-10-09",PLAN_SD:"2021-09-01",PLAN_FD:"2023-10-20",ACT_SD:"2021-09-01",P6_LV1:"P1",P6_LV2:"FUR",LEVEL:"1",ITEM_DESC:"Engineering",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"FUR",ACTIVITY_NAME:"FUR",BL0_SD:"2021-06-18",BL0_FD:"2024-01-24",PLAN_SD:"2021-06-18",PLAN_FD:"2024-02-16",ACT_SD:"2021-06-18",P6_LV1:"P1",P6_LV2:"FUR",LEVEL:"1",ITEM_DESC:"Procurement",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"FUR",ACTIVITY_NAME:"FUR",BL0_SD:"2021-12-24",BL0_FD:"2024-09-05",PLAN_SD:"2021-12-24",PLAN_FD:"2024-09-30",ACT_SD:"2021-12-24",P6_LV1:"P1",P6_LV2:"FUR",LEVEL:"1",ITEM_DESC:"Construction",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV5_SEQ:99,ITEM_SEQ:5},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2022-01-29",BL0_FD:"2023-08-15",PLAN_SD:"2022-01-29",PLAN_FD:"2023-10-13",ACT_SD:"2022-01-29",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2021-11-26",BL0_FD:"2022-07-25",PLAN_SD:"2021-11-26",PLAN_FD:"2022-11-27",ACT_SD:"2021-11-26",ACT_FD:"2022-11-27",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-08-29",BL0_FD:"2023-04-25",PLAN_SD:"2022-07-29",PLAN_FD:"2023-06-23",ACT_SD:"2022-07-29",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Engineering",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-02-07",BL0_FD:"2023-04-25",PLAN_SD:"2022-02-07",PLAN_FD:"2023-07-13",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-01-25",BL0_FD:"2023-09-04",PLAN_SD:"2022-01-25",PLAN_FD:"2023-10-13",ACT_SD:"2022-01-25",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-09-01",BL0_FD:"2023-08-16",PLAN_SD:"2021-09-01",PLAN_FD:"2023-09-12",ACT_SD:"2021-09-01",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-05-25",BL0_FD:"2023-09-10",PLAN_SD:"2022-05-25",PLAN_FD:"2023-09-29",ACT_SD:"2022-05-25",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-02-16",BL0_FD:"2023-10-09",PLAN_SD:"2022-02-16",PLAN_FD:"2023-10-20",ACT_SD:"2022-02-16",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-08-17",BL0_FD:"2022-10-01",PLAN_SD:"2022-08-17",PLAN_FD:"2023-09-09",ACT_SD:"2022-08-17",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Procurement",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV3_SEQ:3,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2021-11-27",BL0_FD:"2023-12-04",PLAN_SD:"2021-11-27",PLAN_FD:"2024-02-16",ACT_SD:"2021-11-27",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-06-18",BL0_FD:"2024-01-24",PLAN_SD:"2021-06-18",PLAN_FD:"2023-12-30",ACT_SD:"2021-06-18",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-07-26",BL0_FD:"2023-09-30",PLAN_SD:"2022-07-26",PLAN_FD:"2024-01-05",ACT_SD:"2022-07-26",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-03-30",BL0_FD:"2024-01-22",PLAN_SD:"2023-03-30",PLAN_FD:"2024-01-29",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2021-12-24",BL0_FD:"2022-10-01",PLAN_SD:"2021-12-24",PLAN_FD:"2023-02-20",ACT_SD:"2021-12-24",ACT_FD:"2022-10-30",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV3_SEQ:5,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-10-08",BL0_FD:"2023-09-18",PLAN_SD:"2022-10-06",PLAN_FD:"2024-09-30",ACT_SD:"2022-10-06",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Construction",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV3_SEQ:5,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-08-03",BL0_FD:"2024-07-27",PLAN_SD:"2022-08-25",PLAN_FD:"2024-09-28",ACT_SD:"2022-08-25",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV3_SEQ:5,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-09-30",BL0_FD:"2024-04-28",PLAN_SD:"2022-10-05",PLAN_FD:"2024-03-12",ACT_SD:"2022-10-05",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV3_SEQ:5,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-08-30",BL0_FD:"2024-08-06",PLAN_SD:"2022-08-23",PLAN_FD:"2024-09-25",ACT_SD:"2022-08-23",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV3_SEQ:5,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-05-29",BL0_FD:"2024-09-05",PLAN_SD:"2023-06-08",PLAN_FD:"2024-09-20",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV3_SEQ:5,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",PLAN_FD:"2024-09-30",P6_LV1:"P1",P6_LV2:"FUR",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:130,P6_LV3_SEQ:5,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P1",P6_LV2:"OR",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2024-10-31",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P1",P6_LV2:"OR",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2021-09-18",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P1",P6_LV2:"OR",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-02-07",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P1",P6_LV2:"OR",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2021-05-21",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P1",P6_LV2:"OR",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2021-12-02",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P1",P6_LV2:"OR",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-02-07",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {BL0_SD:"2021-05-21",BL0_FD:"2024-09-14",PLAN_SD:"2021-05-21",PLAN_FD:"2024-09-25",ACT_SD:"2021-05-21",P6_LV1:"P1",P6_LV2:"OR",LEVEL:"1",ITEM_DESC:"Overall",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"OR",ACTIVITY_NAME:"OR",BL0_SD:"2021-05-21",BL0_FD:"2023-09-13",PLAN_SD:"2021-05-21",PLAN_FD:"2023-10-12",ACT_SD:"2021-05-21",P6_LV1:"P1",P6_LV2:"OR",LEVEL:"1",ITEM_DESC:"Engineering",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"OR",ACTIVITY_NAME:"OR",BL0_SD:"2021-06-01",BL0_FD:"2024-04-24",PLAN_SD:"2021-06-01",PLAN_FD:"2024-06-18",ACT_SD:"2021-06-01",P6_LV1:"P1",P6_LV2:"OR",LEVEL:"1",ITEM_DESC:"Procurement",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"OR",ACTIVITY_NAME:"OR",BL0_SD:"2022-01-04",BL0_FD:"2024-09-14",PLAN_SD:"2022-01-04",PLAN_FD:"2024-09-25",ACT_SD:"2022-01-04",P6_LV1:"P1",P6_LV2:"OR",LEVEL:"1",ITEM_DESC:"Construction",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV5_SEQ:99,ITEM_SEQ:5},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-12-02",BL0_FD:"2023-07-27",PLAN_SD:"2021-12-02",PLAN_FD:"2023-10-12",ACT_SD:"2021-12-02",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2021-09-18",BL0_FD:"2022-11-08",PLAN_SD:"2021-09-18",PLAN_FD:"2023-03-27",ACT_SD:"2021-09-18",ACT_FD:"2023-01-18",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-03-15",BL0_FD:"2022-10-21",PLAN_SD:"2022-03-15",PLAN_FD:"2023-05-25",ACT_SD:"2022-03-15",ACT_FD:"2023-01-19",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Engineering",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-02-07",BL0_FD:"2023-02-09",PLAN_SD:"2022-02-07",PLAN_FD:"2023-05-08",ACT_SD:"2022-02-07",ACT_FD:"2023-01-29",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2021-12-02",BL0_FD:"2023-09-12",PLAN_SD:"2021-12-02",PLAN_FD:"2023-09-06",ACT_SD:"2021-12-02",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-05-21",BL0_FD:"2023-07-27",PLAN_SD:"2021-05-21",PLAN_FD:"2023-07-21",ACT_SD:"2021-05-21",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-05-31",BL0_FD:"2023-07-27",PLAN_SD:"2022-05-31",PLAN_FD:"2023-08-24",ACT_SD:"2022-05-31",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Engineering",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-02-07",BL0_FD:"2023-09-13",PLAN_SD:"2022-02-07",PLAN_FD:"2023-09-25",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-01-30",BL0_FD:"2023-09-12",PLAN_SD:"2022-01-30",PLAN_FD:"2023-10-12",ACT_SD:"2022-01-30",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2023-01-06",BL0_FD:"2023-06-10",PLAN_SD:"2022-09-15",PLAN_FD:"2023-06-16",ACT_SD:"2022-09-15",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Procurement",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:3,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-01-30",BL0_FD:"2024-04-05",PLAN_SD:"2022-01-30",PLAN_FD:"2024-03-25",ACT_SD:"2022-01-30",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-06-01",BL0_FD:"2023-12-31",PLAN_SD:"2021-06-01",PLAN_FD:"2023-12-06",ACT_SD:"2021-06-01",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-03-08",BL0_FD:"2024-04-24",PLAN_SD:"2022-03-08",PLAN_FD:"2024-06-18",ACT_SD:"2022-03-08",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-12-16",BL0_FD:"2023-07-17",PLAN_SD:"2023-03-30",PLAN_FD:"2023-07-27",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Procurement",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-03-02",BL0_FD:"2024-03-27",PLAN_SD:"2022-03-02",PLAN_FD:"2024-03-19",ACT_SD:"2022-03-02",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-01-04",BL0_FD:"2023-08-28",PLAN_SD:"2022-01-04",PLAN_FD:"2023-10-17",ACT_SD:"2022-01-04",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:5,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2023-03-29",BL0_FD:"2023-11-01",PLAN_SD:"2023-02-03",PLAN_FD:"2023-12-15",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Construction",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:5,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-06-01",BL0_FD:"2024-08-19",PLAN_SD:"2022-05-08",PLAN_FD:"2024-09-25",ACT_SD:"2022-05-08",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:5,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-10-01",BL0_FD:"2024-05-09",PLAN_SD:"2022-10-27",PLAN_FD:"2024-08-02",ACT_SD:"2022-10-27",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:5,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-09-23",BL0_FD:"2024-09-14",PLAN_SD:"2022-10-28",PLAN_FD:"2024-09-02",ACT_SD:"2022-10-28",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:5,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2023-07-24",BL0_FD:"2023-07-24",PLAN_SD:"2023-07-28",PLAN_FD:"2023-09-23",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Construction",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:5,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-11-08",BL0_FD:"2024-08-14",PLAN_SD:"2023-10-16",PLAN_FD:"2024-09-18",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:5,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Painting",ACTIVITY_NAME:"Painting",BL0_SD:"2024-03-19",BL0_FD:"2024-05-31",PLAN_SD:"2024-02-22",PLAN_FD:"2024-07-16",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Construction",P6_LV5:"Painting",LEVEL:"2",ITEM_DESC:"Painting",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:5,P6_LV5_SEQ:50,ITEM_SEQ:50},
    {ACTIVITY_ID:"Insulation",ACTIVITY_NAME:"Insulation",BL0_SD:"2024-01-22",BL0_FD:"2024-06-12",PLAN_SD:"2024-01-04",PLAN_FD:"2024-07-25",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Construction",P6_LV5:"Insulation",LEVEL:"2",ITEM_DESC:"Insulation",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:5,P6_LV5_SEQ:55,ITEM_SEQ:55},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",BL0_SD:"2024-03-25",BL0_FD:"2024-08-06",PLAN_SD:"2024-01-30",PLAN_FD:"2024-09-25",P6_LV1:"P1",P6_LV2:"OR",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:140,P6_LV3_SEQ:5,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P1",P6_LV2:"BD",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2024-10-31",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P1",P6_LV2:"BD",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2021-12-01",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P1",P6_LV2:"BD",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-03-16",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P1",P6_LV2:"BD",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2021-08-03",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P1",P6_LV2:"BD",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-02-07",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P1",P6_LV2:"BD",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-02-21",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {BL0_SD:"2021-08-01",BL0_FD:"2024-09-30",PLAN_SD:"2021-08-01",PLAN_FD:"2024-08-30",ACT_SD:"2021-08-01",P6_LV1:"P1",P6_LV2:"BD",LEVEL:"1",ITEM_DESC:"Overall",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"BD",ACTIVITY_NAME:"BD",BL0_SD:"2021-08-01",BL0_FD:"2024-05-27",PLAN_SD:"2021-08-01",PLAN_FD:"2024-06-11",ACT_SD:"2021-08-01",P6_LV1:"P1",P6_LV2:"BD",LEVEL:"1",ITEM_DESC:"Engineering",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"BD",ACTIVITY_NAME:"BD",BL0_SD:"2022-02-07",BL0_FD:"2024-07-26",PLAN_SD:"2021-11-11",PLAN_FD:"2024-06-22",ACT_SD:"2021-11-11",P6_LV1:"P1",P6_LV2:"BD",LEVEL:"1",ITEM_DESC:"Procurement",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"BD",ACTIVITY_NAME:"BD",BL0_SD:"2022-02-14",BL0_FD:"2024-09-30",PLAN_SD:"2022-02-14",PLAN_FD:"2024-08-30",ACT_SD:"2022-02-14",P6_LV1:"P1",P6_LV2:"BD",LEVEL:"1",ITEM_DESC:"Construction",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV5_SEQ:99,ITEM_SEQ:5},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-08-01",BL0_FD:"2023-09-07",PLAN_SD:"2021-08-01",PLAN_FD:"2023-11-21",ACT_SD:"2021-08-01",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2021-12-01",BL0_FD:"2023-05-11",PLAN_SD:"2022-02-07",PLAN_FD:"2023-03-13",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-03-16",BL0_FD:"2023-07-07",PLAN_SD:"2022-03-16",PLAN_FD:"2023-07-26",ACT_SD:"2022-03-16",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Engineering",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-02-21",BL0_FD:"2023-04-27",PLAN_SD:"2022-02-21",PLAN_FD:"2023-07-12",ACT_SD:"2022-02-21",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-02-07",BL0_FD:"2024-01-15",PLAN_SD:"2022-02-07",PLAN_FD:"2024-03-07",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-08-03",BL0_FD:"2023-07-21",PLAN_SD:"2021-08-03",PLAN_FD:"2023-08-23",ACT_SD:"2021-08-03",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-03-16",BL0_FD:"2024-05-27",PLAN_SD:"2022-03-16",PLAN_FD:"2024-06-11",ACT_SD:"2022-03-16",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-12-06",BL0_FD:"2023-11-13",PLAN_SD:"2022-11-17",PLAN_FD:"2023-12-15",ACT_SD:"2022-11-17",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Engineering",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-03-07",BL0_FD:"2023-12-11",PLAN_SD:"2022-03-07",PLAN_FD:"2024-01-17",ACT_SD:"2022-03-07",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-11-23",BL0_FD:"2023-05-20",PLAN_SD:"2022-11-17",PLAN_FD:"2023-05-09",ACT_SD:"2022-11-17",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Procurement",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:3,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-03-19",BL0_FD:"2024-05-20",PLAN_SD:"2022-03-19",PLAN_FD:"2024-06-01",ACT_SD:"2022-03-19",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-02-07",BL0_FD:"2024-02-17",PLAN_SD:"2021-11-11",PLAN_FD:"2024-02-05",ACT_SD:"2021-11-11",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2023-06-06",BL0_FD:"2024-07-26",PLAN_SD:"2022-08-27",PLAN_FD:"2024-06-22",ACT_SD:"2022-08-27",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2023-05-20",BL0_FD:"2024-03-04",PLAN_SD:"2023-05-19",PLAN_FD:"2024-03-25",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Procurement",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-05-28",BL0_FD:"2024-03-17",PLAN_SD:"2022-06-08",PLAN_FD:"2024-03-29",ACT_SD:"2022-06-08",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-02-14",BL0_FD:"2024-04-16",PLAN_SD:"2022-02-14",PLAN_FD:"2024-07-13",ACT_SD:"2022-02-14",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:5,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2023-03-30",BL0_FD:"2023-11-01",PLAN_SD:"2023-04-04",PLAN_FD:"2024-01-23",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Construction",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:5,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-07-06",BL0_FD:"2024-09-30",PLAN_SD:"2022-10-08",PLAN_FD:"2024-08-30",ACT_SD:"2022-10-08",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:5,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2023-02-07",BL0_FD:"2024-01-16",PLAN_SD:"2022-12-28",PLAN_FD:"2024-03-05",ACT_SD:"2022-12-28",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:5,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2023-10-13",BL0_FD:"2024-09-27",PLAN_SD:"2023-03-01",PLAN_FD:"2024-08-24",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:5,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2024-03-26",BL0_FD:"2024-09-26",PLAN_SD:"2024-03-25",PLAN_FD:"2024-08-28",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Construction",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:5,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-07-22",BL0_FD:"2024-09-27",PLAN_SD:"2023-06-26",PLAN_FD:"2024-08-27",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:5,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Painting",ACTIVITY_NAME:"Painting",BL0_SD:"2023-03-30",BL0_FD:"2024-08-02",PLAN_SD:"2023-05-26",PLAN_FD:"2024-08-23",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Construction",P6_LV5:"Painting",LEVEL:"2",ITEM_DESC:"Painting",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:5,P6_LV5_SEQ:50,ITEM_SEQ:50},
    {ACTIVITY_ID:"Insulation",ACTIVITY_NAME:"Insulation",BL0_SD:"2023-09-05",BL0_FD:"2024-09-20",PLAN_SD:"2023-08-18",PLAN_FD:"2024-08-13",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Construction",P6_LV5:"Insulation",LEVEL:"2",ITEM_DESC:"Insulation",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:5,P6_LV5_SEQ:55,ITEM_SEQ:55},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",BL0_SD:"2024-06-18",BL0_FD:"2024-06-18",PLAN_SD:"2024-06-30",PLAN_FD:"2024-08-30",P6_LV1:"P1",P6_LV2:"BD",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:150,P6_LV3_SEQ:5,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P1",P6_LV2:"BTX",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2024-10-31",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P1",P6_LV2:"BTX",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2021-12-28",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P1",P6_LV2:"BTX",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-02-07",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P1",P6_LV2:"BTX",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2021-11-15",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P1",P6_LV2:"BTX",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-02-07",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P1",P6_LV2:"BTX",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-02-21",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {BL0_SD:"2021-09-16",BL0_FD:"2024-10-10",PLAN_SD:"2021-11-15",PLAN_FD:"2024-09-30",ACT_SD:"2021-11-15",P6_LV1:"P1",P6_LV2:"BTX",LEVEL:"1",ITEM_DESC:"Overall",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"BTX",ACTIVITY_NAME:"BTX",BL0_SD:"2021-09-16",BL0_FD:"2024-03-14",PLAN_SD:"2021-11-15",PLAN_FD:"2024-05-10",ACT_SD:"2021-11-15",P6_LV1:"P1",P6_LV2:"BTX",LEVEL:"1",ITEM_DESC:"Engineering",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"BTX",ACTIVITY_NAME:"BTX",BL0_SD:"2022-02-07",BL0_FD:"2024-05-16",PLAN_SD:"2022-02-07",PLAN_FD:"2024-05-22",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"BTX",LEVEL:"1",ITEM_DESC:"Procurement",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"BTX",ACTIVITY_NAME:"BTX",BL0_SD:"2022-02-14",BL0_FD:"2024-10-10",PLAN_SD:"2022-02-14",PLAN_FD:"2024-09-30",ACT_SD:"2022-02-14",P6_LV1:"P1",P6_LV2:"BTX",LEVEL:"1",ITEM_DESC:"Construction",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV5_SEQ:99,ITEM_SEQ:5},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-09-16",BL0_FD:"2023-09-15",PLAN_SD:"2021-12-16",PLAN_FD:"2023-10-18",ACT_SD:"2021-12-16",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2021-12-28",BL0_FD:"2023-05-08",PLAN_SD:"2022-02-07",PLAN_FD:"2023-03-03",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Architecture",ACTIVITY_NAME:"Architecture",BL0_SD:"2022-02-07",BL0_FD:"2023-07-10",PLAN_SD:"2022-02-07",PLAN_FD:"2023-07-14",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Engineering",P6_LV5:"Architecture",LEVEL:"2",ITEM_DESC:"Architecture",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV5_SEQ:20,ITEM_SEQ:20},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-02-21",BL0_FD:"2023-04-18",PLAN_SD:"2022-02-21",PLAN_FD:"2023-07-12",ACT_SD:"2022-02-21",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-02-07",BL0_FD:"2023-11-15",PLAN_SD:"2022-02-07",PLAN_FD:"2023-12-29",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-11-15",BL0_FD:"2023-09-15",PLAN_SD:"2021-11-15",PLAN_FD:"2023-06-15",ACT_SD:"2021-11-15",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2022-10-10",BL0_FD:"2023-09-11",PLAN_SD:"2022-11-07",PLAN_FD:"2023-10-27",ACT_SD:"2022-11-07",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Engineering",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-02-07",BL0_FD:"2024-03-14",PLAN_SD:"2022-02-07",PLAN_FD:"2024-05-10",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-03-07",BL0_FD:"2023-12-11",PLAN_SD:"2022-03-07",PLAN_FD:"2023-12-29",ACT_SD:"2022-03-07",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-11-12",BL0_FD:"2023-05-24",PLAN_SD:"2022-11-19",PLAN_FD:"2023-05-09",ACT_SD:"2022-11-19",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Procurement",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:3,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-03-19",BL0_FD:"2024-03-22",PLAN_SD:"2022-03-19",PLAN_FD:"2024-01-28",ACT_SD:"2022-03-19",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-02-07",BL0_FD:"2024-02-02",PLAN_SD:"2022-02-07",PLAN_FD:"2024-03-06",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-07-06",BL0_FD:"2024-05-16",PLAN_SD:"2022-07-20",PLAN_FD:"2024-05-22",ACT_SD:"2022-07-20",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2023-03-21",BL0_FD:"2023-12-30",PLAN_SD:"2023-05-11",PLAN_FD:"2024-01-30",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Procurement",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-05-28",BL0_FD:"2024-03-17",PLAN_SD:"2022-06-08",PLAN_FD:"2024-03-16",ACT_SD:"2022-06-08",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-02-14",BL0_FD:"2024-04-19",PLAN_SD:"2022-02-14",PLAN_FD:"2024-07-12",ACT_SD:"2022-02-14",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:5,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2023-03-20",BL0_FD:"2023-11-04",PLAN_SD:"2023-05-15",PLAN_FD:"2024-01-22",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Construction",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:5,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-07-21",BL0_FD:"2024-10-10",PLAN_SD:"2022-10-10",PLAN_FD:"2024-09-30",ACT_SD:"2022-10-10",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:5,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2023-03-27",BL0_FD:"2024-01-16",PLAN_SD:"2023-02-18",PLAN_FD:"2024-02-05",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:5,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-11-21",BL0_FD:"2024-08-13",PLAN_SD:"2022-12-09",PLAN_FD:"2024-09-26",ACT_SD:"2022-12-09",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:5,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"HVAC",ACTIVITY_NAME:"HVAC",BL0_SD:"2024-01-24",BL0_FD:"2024-08-01",PLAN_SD:"2024-01-30",PLAN_FD:"2024-08-30",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Construction",P6_LV5:"HVAC",LEVEL:"2",ITEM_DESC:"HVAC",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:5,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-07-12",BL0_FD:"2024-10-10",PLAN_SD:"2023-06-26",PLAN_FD:"2024-09-24",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:5,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Painting",ACTIVITY_NAME:"Painting",BL0_SD:"2023-03-20",BL0_FD:"2024-08-20",PLAN_SD:"2023-05-15",PLAN_FD:"2024-08-06",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Construction",P6_LV5:"Painting",LEVEL:"2",ITEM_DESC:"Painting",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:5,P6_LV5_SEQ:50,ITEM_SEQ:50},
    {ACTIVITY_ID:"Insulation",ACTIVITY_NAME:"Insulation",BL0_SD:"2023-09-19",BL0_FD:"2024-08-15",PLAN_SD:"2023-09-28",PLAN_FD:"2024-07-20",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Construction",P6_LV5:"Insulation",LEVEL:"2",ITEM_DESC:"Insulation",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:5,P6_LV5_SEQ:55,ITEM_SEQ:55},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",BL0_SD:"2024-05-21",BL0_FD:"2024-05-21",PLAN_SD:"2024-07-13",PLAN_FD:"2024-09-30",P6_LV1:"P1",P6_LV2:"BTX",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:160,P6_LV3_SEQ:5,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P1",P6_LV2:"DIB",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2021-12-28",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P1",P6_LV2:"DIB",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-03-07",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P1",P6_LV2:"DIB",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2021-11-15",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P1",P6_LV2:"DIB",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-02-07",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P1",P6_LV2:"DIB",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-02-21",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P1",P6_LV2:"DIB",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2024-10-31",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {BL0_SD:"2021-09-16",BL0_FD:"2024-10-10",PLAN_SD:"2021-11-15",PLAN_FD:"2024-09-30",ACT_SD:"2021-11-15",P6_LV1:"P1",P6_LV2:"DIB",LEVEL:"1",ITEM_DESC:"Overall",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"DIB",ACTIVITY_NAME:"DIB",BL0_SD:"2021-09-16",BL0_FD:"2024-03-14",PLAN_SD:"2021-11-15",PLAN_FD:"2024-05-31",ACT_SD:"2021-11-15",P6_LV1:"P1",P6_LV2:"DIB",LEVEL:"1",ITEM_DESC:"Engineering",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"DIB",ACTIVITY_NAME:"DIB",BL0_SD:"2022-02-07",BL0_FD:"2024-05-16",PLAN_SD:"2022-02-07",PLAN_FD:"2024-06-12",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"DIB",LEVEL:"1",ITEM_DESC:"Procurement",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"DIB",ACTIVITY_NAME:"DIB",BL0_SD:"2022-02-14",BL0_FD:"2024-10-10",PLAN_SD:"2022-02-14",PLAN_FD:"2024-09-30",ACT_SD:"2022-02-14",P6_LV1:"P1",P6_LV2:"DIB",LEVEL:"1",ITEM_DESC:"Construction",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV5_SEQ:99,ITEM_SEQ:5},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-09-16",BL0_FD:"2023-09-15",PLAN_SD:"2021-12-16",PLAN_FD:"2023-11-15",ACT_SD:"2021-12-16",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2021-12-28",BL0_FD:"2023-05-22",PLAN_SD:"2022-02-07",PLAN_FD:"2023-03-10",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-02-21",BL0_FD:"2023-04-18",PLAN_SD:"2022-02-21",PLAN_FD:"2023-07-12",ACT_SD:"2022-02-21",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-02-07",BL0_FD:"2023-11-15",PLAN_SD:"2022-02-07",PLAN_FD:"2024-01-17",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-11-15",BL0_FD:"2023-09-15",PLAN_SD:"2021-11-15",PLAN_FD:"2023-07-27",ACT_SD:"2021-11-15",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-03-07",BL0_FD:"2024-03-14",PLAN_SD:"2022-03-07",PLAN_FD:"2024-05-31",ACT_SD:"2022-03-07",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-03-07",BL0_FD:"2023-12-11",PLAN_SD:"2022-03-07",PLAN_FD:"2024-01-17",ACT_SD:"2022-03-07",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-11-12",BL0_FD:"2023-05-24",PLAN_SD:"2022-11-18",PLAN_FD:"2023-05-09",ACT_SD:"2022-11-18",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Procurement",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV3_SEQ:3,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-03-19",BL0_FD:"2024-03-22",PLAN_SD:"2022-03-19",PLAN_FD:"2024-04-04",ACT_SD:"2022-03-19",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-02-07",BL0_FD:"2024-03-12",PLAN_SD:"2022-02-07",PLAN_FD:"2024-03-06",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-08-24",BL0_FD:"2024-05-16",PLAN_SD:"2022-08-27",PLAN_FD:"2024-06-12",ACT_SD:"2022-08-27",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-05-28",BL0_FD:"2024-03-17",PLAN_SD:"2022-05-28",PLAN_FD:"2024-03-29",ACT_SD:"2022-05-28",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-02-14",BL0_FD:"2024-04-19",PLAN_SD:"2022-02-14",PLAN_FD:"2024-07-06",ACT_SD:"2022-02-14",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV3_SEQ:5,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2023-03-20",BL0_FD:"2023-11-04",PLAN_SD:"2023-05-08",PLAN_FD:"2024-01-16",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Construction",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV3_SEQ:5,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-07-21",BL0_FD:"2024-10-10",PLAN_SD:"2022-10-10",PLAN_FD:"2024-09-30",ACT_SD:"2022-10-10",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV3_SEQ:5,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2023-03-27",BL0_FD:"2024-01-16",PLAN_SD:"2023-03-27",PLAN_FD:"2024-02-05",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV3_SEQ:5,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2023-01-11",BL0_FD:"2024-08-13",PLAN_SD:"2023-04-06",PLAN_FD:"2024-09-26",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV3_SEQ:5,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-07-12",BL0_FD:"2024-09-28",PLAN_SD:"2023-07-12",PLAN_FD:"2024-09-30",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV3_SEQ:5,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Painting",ACTIVITY_NAME:"Painting",BL0_SD:"2023-03-20",BL0_FD:"2024-08-20",PLAN_SD:"2023-05-10",PLAN_FD:"2024-08-15",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Construction",P6_LV5:"Painting",LEVEL:"2",ITEM_DESC:"Painting",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV3_SEQ:5,P6_LV5_SEQ:50,ITEM_SEQ:50},
    {ACTIVITY_ID:"Insulation",ACTIVITY_NAME:"Insulation",BL0_SD:"2023-09-08",BL0_FD:"2024-08-15",PLAN_SD:"2023-09-08",PLAN_FD:"2024-07-30",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Construction",P6_LV5:"Insulation",LEVEL:"2",ITEM_DESC:"Insulation",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV3_SEQ:5,P6_LV5_SEQ:55,ITEM_SEQ:55},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",BL0_SD:"2024-05-21",BL0_FD:"2024-05-21",PLAN_SD:"2024-07-20",PLAN_FD:"2024-09-30",P6_LV1:"P1",P6_LV2:"DIB",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:170,P6_LV3_SEQ:5,P6_LV5_SEQ:60,ITEM_SEQ:60},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",P6_LV1:"P1",P6_LV2:"GHU",P6_LV5:"Civil",LEVEL:"1",ITEM_DESC:"CIVIL",MS_PLAN:"2021-12-28",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV5_SEQ:15,ITEM_SEQ:0},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",P6_LV1:"P1",P6_LV2:"GHU",P6_LV5:"Electrical",LEVEL:"1",ITEM_DESC:"ELECT",MS_PLAN:"2022-02-07",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV5_SEQ:40,ITEM_SEQ:0},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",P6_LV1:"P1",P6_LV2:"GHU",P6_LV5:"Mechanical",LEVEL:"1",ITEM_DESC:"MECH",MS_PLAN:"2021-11-15",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV5_SEQ:35,ITEM_SEQ:0},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",P6_LV1:"P1",P6_LV2:"GHU",P6_LV5:"Piping",LEVEL:"1",ITEM_DESC:"PIPE",MS_PLAN:"2022-02-07",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV5_SEQ:30,ITEM_SEQ:0},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",P6_LV1:"P1",P6_LV2:"GHU",P6_LV5:"Structure",LEVEL:"1",ITEM_DESC:"STL",MS_PLAN:"2022-02-21",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV5_SEQ:25,ITEM_SEQ:0},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",P6_LV1:"P1",P6_LV2:"GHU",P6_LV5:"MC",LEVEL:"1",ITEM_DESC:"MC",MS_PLAN:"2024-10-31",ACTIVITY_TYPE:"MS",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV5_SEQ:60,ITEM_SEQ:0},
    {BL0_SD:"2021-09-16",BL0_FD:"2024-10-12",PLAN_SD:"2021-11-15",PLAN_FD:"2024-08-30",ACT_SD:"2021-11-15",P6_LV1:"P1",P6_LV2:"GHU",LEVEL:"1",ITEM_DESC:"Overall",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV5_SEQ:99,ITEM_SEQ:1},
    {ACTIVITY_ID:"GHU",ACTIVITY_NAME:"GHU",BL0_SD:"2021-09-16",BL0_FD:"2024-03-14",PLAN_SD:"2021-11-15",PLAN_FD:"2024-05-10",ACT_SD:"2021-11-15",P6_LV1:"P1",P6_LV2:"GHU",LEVEL:"1",ITEM_DESC:"Engineering",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV5_SEQ:99,ITEM_SEQ:2},
    {ACTIVITY_ID:"GHU",ACTIVITY_NAME:"GHU",BL0_SD:"2022-02-07",BL0_FD:"2024-05-16",PLAN_SD:"2022-02-07",PLAN_FD:"2024-05-22",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"GHU",LEVEL:"1",ITEM_DESC:"Procurement",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV5_SEQ:99,ITEM_SEQ:3},
    {ACTIVITY_ID:"GHU",ACTIVITY_NAME:"GHU",BL0_SD:"2022-02-14",BL0_FD:"2024-10-12",PLAN_SD:"2022-02-14",PLAN_FD:"2024-08-30",ACT_SD:"2022-02-14",P6_LV1:"P1",P6_LV2:"GHU",LEVEL:"1",ITEM_DESC:"Construction",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV5_SEQ:99,ITEM_SEQ:5},
    {ACTIVITY_ID:"Process",ACTIVITY_NAME:"Process",BL0_SD:"2021-09-16",BL0_FD:"2023-09-29",PLAN_SD:"2021-12-16",PLAN_FD:"2023-11-08",ACT_SD:"2021-12-16",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Engineering",P6_LV5:"Process",LEVEL:"2",ITEM_DESC:"Process",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV5_SEQ:10,ITEM_SEQ:10},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2021-12-28",BL0_FD:"2023-04-25",PLAN_SD:"2022-02-07",PLAN_FD:"2023-03-13",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Engineering",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-02-21",BL0_FD:"2023-04-18",PLAN_SD:"2022-02-21",PLAN_FD:"2023-07-12",ACT_SD:"2022-02-21",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Engineering",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-02-07",BL0_FD:"2023-11-01",PLAN_SD:"2022-02-07",PLAN_FD:"2024-01-17",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Engineering",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2021-11-15",BL0_FD:"2023-09-29",PLAN_SD:"2021-11-15",PLAN_FD:"2023-07-27",ACT_SD:"2021-11-15",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Engineering",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-02-07",BL0_FD:"2024-03-14",PLAN_SD:"2022-02-07",PLAN_FD:"2024-05-10",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Engineering",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-03-07",BL0_FD:"2023-12-11",PLAN_SD:"2022-03-07",PLAN_FD:"2024-01-17",ACT_SD:"2022-03-07",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Engineering",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2022-11-12",BL0_FD:"2023-05-24",PLAN_SD:"2022-11-18",PLAN_FD:"2023-05-09",ACT_SD:"2022-11-18",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Procurement",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV3_SEQ:3,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-03-19",BL0_FD:"2024-03-08",PLAN_SD:"2022-03-19",PLAN_FD:"2024-03-21",ACT_SD:"2022-03-19",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Procurement",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV3_SEQ:3,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2022-02-07",BL0_FD:"2024-03-13",PLAN_SD:"2022-02-07",PLAN_FD:"2024-02-26",ACT_SD:"2022-02-07",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Procurement",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV3_SEQ:3,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2022-08-24",BL0_FD:"2024-05-16",PLAN_SD:"2022-08-24",PLAN_FD:"2024-05-22",ACT_SD:"2022-08-24",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Procurement",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV3_SEQ:3,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2022-05-28",BL0_FD:"2024-03-16",PLAN_SD:"2022-05-28",PLAN_FD:"2024-03-29",ACT_SD:"2022-05-28",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Procurement",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV3_SEQ:3,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Civil",ACTIVITY_NAME:"Civil",BL0_SD:"2022-02-14",BL0_FD:"2024-04-19",PLAN_SD:"2022-02-14",PLAN_FD:"2024-06-08",ACT_SD:"2022-02-14",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Construction",P6_LV5:"Civil",LEVEL:"2",ITEM_DESC:"Civil",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV3_SEQ:5,P6_LV5_SEQ:15,ITEM_SEQ:15},
    {ACTIVITY_ID:"Structure",ACTIVITY_NAME:"Structure",BL0_SD:"2023-03-20",BL0_FD:"2023-11-04",PLAN_SD:"2023-05-15",PLAN_FD:"2023-12-15",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Construction",P6_LV5:"Structure",LEVEL:"2",ITEM_DESC:"Structure",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV3_SEQ:5,P6_LV5_SEQ:25,ITEM_SEQ:25},
    {ACTIVITY_ID:"Piping",ACTIVITY_NAME:"Piping",BL0_SD:"2022-07-21",BL0_FD:"2024-10-09",PLAN_SD:"2022-10-10",PLAN_FD:"2024-08-30",ACT_SD:"2022-10-10",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Construction",P6_LV5:"Piping",LEVEL:"2",ITEM_DESC:"Piping",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV3_SEQ:5,P6_LV5_SEQ:30,ITEM_SEQ:30},
    {ACTIVITY_ID:"Mechanical",ACTIVITY_NAME:"Mechanical",BL0_SD:"2023-03-13",BL0_FD:"2024-01-16",PLAN_SD:"2023-03-13",PLAN_FD:"2024-02-05",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Construction",P6_LV5:"Mechanical",LEVEL:"2",ITEM_DESC:"Mechanical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV3_SEQ:5,P6_LV5_SEQ:35,ITEM_SEQ:35},
    {ACTIVITY_ID:"Electrical",ACTIVITY_NAME:"Electrical",BL0_SD:"2023-01-11",BL0_FD:"2024-10-10",PLAN_SD:"2023-01-11",PLAN_FD:"2024-08-20",ACT_SD:"2023-01-11",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Construction",P6_LV5:"Electrical",LEVEL:"2",ITEM_DESC:"Electrical",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV3_SEQ:5,P6_LV5_SEQ:40,ITEM_SEQ:40},
    {ACTIVITY_ID:"Instrumentation",ACTIVITY_NAME:"Instrumentation",BL0_SD:"2023-07-12",BL0_FD:"2024-10-12",PLAN_SD:"2023-07-12",PLAN_FD:"2024-08-22",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Construction",P6_LV5:"Instrumentation",LEVEL:"2",ITEM_DESC:"Instrumentation",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV3_SEQ:5,P6_LV5_SEQ:45,ITEM_SEQ:45},
    {ACTIVITY_ID:"Painting",ACTIVITY_NAME:"Painting",BL0_SD:"2023-03-20",BL0_FD:"2024-08-20",PLAN_SD:"2023-05-15",PLAN_FD:"2024-08-08",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Construction",P6_LV5:"Painting",LEVEL:"2",ITEM_DESC:"Painting",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV3_SEQ:5,P6_LV5_SEQ:50,ITEM_SEQ:50},
    {ACTIVITY_ID:"Insulation",ACTIVITY_NAME:"Insulation",BL0_SD:"2023-08-08",BL0_FD:"2024-08-15",PLAN_SD:"2023-08-31",PLAN_FD:"2024-07-30",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Construction",P6_LV5:"Insulation",LEVEL:"2",ITEM_DESC:"Insulation",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV3_SEQ:5,P6_LV5_SEQ:55,ITEM_SEQ:55},
    {ACTIVITY_ID:"MC",ACTIVITY_NAME:"MC",BL0_SD:"2024-07-12",BL0_FD:"2024-07-12",PLAN_SD:"2024-07-10",PLAN_FD:"2024-08-30",P6_LV1:"P1",P6_LV2:"GHU",P6_LV3:"Construction",P6_LV5:"MC",LEVEL:"2",ITEM_DESC:"MC",ACTIVITY_TYPE:"TASK",P6_LV1_SEQ:1,P6_LV2_SEQ:180,P6_LV3_SEQ:5,P6_LV5_SEQ:60,ITEM_SEQ:60}
  ]
  
}