import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1400,
      CanvasHeight: 200,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 260,
      CanvasChartHeight: 310,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },
    DataItems:  [
      {TQ_GROUP:"Total",ISSUED:354,RESPONDED:283,OPEN:61,OPEN_NOT_OVERDUE:37,OPEN_OVERDUE:24,CANCELLED:10},
      {TQ_GROUP:"EM to SEI",ISSUED:229,RESPONDED:179,OPEN:44,OPEN_NOT_OVERDUE:22,OPEN_OVERDUE:22,CANCELLED:6},
      {TQ_GROUP:"SEI to EM",ISSUED:125,RESPONDED:104,OPEN:17,OPEN_NOT_OVERDUE:15,OPEN_OVERDUE:2,CANCELLED:4}
      // ,{ITEM:"Piling",ITEM_SEQ:1,SITE:"Main Plant",PACKAGE:"P1",PACKAGE_SEQ:1,AREA:"Furnace and Olefinovery",AREA_SEQ:1,UOM:"fcgc sgds zdhg d ",CUTOFF_DAY:"Friday",TOTAL:2223387,CDATE:"2022-07-29",PLAN_CUM:1444416,PLAN_WK:144556,ACTUAL_CUM:4555445,ACTUAL_WK:4444532,REMAIN:2942,DELTA_WK:-33455,DELTA_CUM:472779,PROG:50}
    
    ],
}