import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 580,
      CanvasHeight: 160,
      CanvasBorderWeight: 0.1,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 580,
      CanvasChartHeight: 160,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

    Text: {
      Text: [],
      Filter: [],
      Sql: []
    },

    DataItems: [
      {NO:1,GWBS_LV3:"ME01",BLOCK:"E1A",ITEM_SEQ:1,ITEM:"Pre-Fabrication",PLAN_SD:"2020-03-29",PLAN_FD:"2021-03-17",PLAN_PROG:100,ACTUAL_SD:"2020-03-29",ACTUAL_PROG:97.27,DELTA:-2.73},
      {NO:2,GWBS_LV3:"ME01",BLOCK:"E1A",ITEM_SEQ:2,ITEM:"Block Assembly BP",PLAN_SD:"2020-07-29",PLAN_FD:"2021-01-15",PLAN_PROG:100,ACTUAL_SD:"2020-07-29",ACTUAL_PROG:99.64,DELTA:-0.35},
      {NO:3,GWBS_LV3:"ME01",BLOCK:"E1A",ITEM_SEQ:3,ITEM:"Block Painting",PLAN_SD:"2021-01-16",PLAN_FD:"2021-02-23",PLAN_PROG:75.41,ACTUAL_SD:"2020-11-24",ACTUAL_PROG:66.95,DELTA:-8.45},
      {NO:4,GWBS_LV3:"ME01",BLOCK:"E1A",ITEM_SEQ:4,ITEM:"Block Assembly AP",PLAN_SD:"2021-03-22",PLAN_FD:"2021-04-06"},
      {NO:5,GWBS_LV3:"ME01",BLOCK:"E1A",ITEM_SEQ:5,ITEM:"Module Assembly",PLAN_SD:"2021-02-24",PLAN_FD:"2021-02-24",PLAN_PROG:0,ACTUAL_PROG:0,DELTA:0}
    ],


}