import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 600,
      CanvasHeight: 380,
      CanvasBorderWeight: 0.5,
      CanvasBorderColor: '#757575',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },

    Queries: {
      // filter
      SQL1:  [
        {
          FILTERSTRING: "OFF",
          TITLE: "A Check Sheets",
          TITLE1: "Total",
          TITLE2: "Complete",
          TITLE3: "Remain",
          FILTER1: " [CATEGORY] = ''A'' ",
          FILTER2: " [CATEGORY] = ''A'' AND [STATUS_CODE] = ''C'' ",
          FILTER3: " [CATEGORY] = ''A'' AND [STATUS_CODE] != ''C'' ",
        },
      ],
    },
  

    DataItems: [
      { TYPE: 'A',    DATE: null,     DISC: 'A',     DISC_SHORT1: 'A-Architectural',      TOTAL: 1444,    ACTUAL: 210,    REMAIN: 1234,   PROG: 14.54, },
      { TYPE: 'A',    DATE: null,     DISC: 'E',     DISC_SHORT1: 'E-Electrical',         TOTAL: 43742,   ACTUAL: 25361,  REMAIN: 18381,  PROG: 57.98, },
      { TYPE: 'A',    DATE: null,     DISC: 'H',     DISC_SHORT1: 'H-HVAC',               TOTAL: 6104,    ACTUAL: 4272,   REMAIN: 1832,   PROG: 69.99, },
      { TYPE: 'A',    DATE: null,     DISC: 'I',     DISC_SHORT1: 'I-Instrument',         TOTAL: 54380,   ACTUAL: 32939,  REMAIN: 21441,  PROG: 60.57, },
      { TYPE: 'A',    DATE: null,     DISC: 'M',     DISC_SHORT1: 'M-Rotating',           TOTAL: 6512,    ACTUAL: 3533,   REMAIN: 2979,   PROG: 54.25, },
      { TYPE: 'A',    DATE: null,     DISC: 'P',     DISC_SHORT1: 'P-Process',            TOTAL: 35215,   ACTUAL: 21704,  REMAIN: 13511,  PROG: 61.63, },
      { TYPE: 'A',    DATE: null,     DISC: 'S',     DISC_SHORT1: 'S-Safety',             TOTAL: 915,     ACTUAL: 40,     REMAIN: 875,    PROG: 4.37, },
      { TYPE: 'A',    DATE: null,     DISC: 'T',     DISC_SHORT1: 'T-Telecommunications', TOTAL: 8391,    ACTUAL: 3566,   REMAIN: 4825,   PROG: 42.5, },
      { TYPE: 'A',    DATE: null,     DISC: 'Overall',     DISC_SHORT1: 'Overall',              TOTAL: 156703,  ACTUAL: 91625,  REMAIN: 65078,  PROG: 58.47, },
      { TYPE: 'WEEK', DATE: '43889',  DISC: 'LWK',   DISC_SHORT1: 'LWK',                  TOTAL: null,    ACTUAL: 3426,   REMAIN: null,   PROG: null, },
      { TYPE: 'WEEK', DATE: '43890',  DISC: 'SAT',   DISC_SHORT1: 'SAT',                  TOTAL: null,    ACTUAL: 411,    REMAIN: null,   PROG: null, },
      { TYPE: 'WEEK', DATE: '43891',  DISC: 'SUN',   DISC_SHORT1: 'SUN',                  TOTAL: null,    ACTUAL: 95,     REMAIN: null,   PROG: null, },
      { TYPE: 'WEEK', DATE: '43892',  DISC: 'MON',   DISC_SHORT1: 'MON',                  TOTAL: null,    ACTUAL: 0,      REMAIN: null,   PROG: null, },
      { TYPE: 'WEEK', DATE: '43893',  DISC: 'TUE',   DISC_SHORT1: 'TUE',                  TOTAL: null,    ACTUAL: 0,      REMAIN: null,   PROG: null, },
      { TYPE: 'WEEK', DATE: '43894',  DISC: 'WEN',   DISC_SHORT1: 'WEN',                  TOTAL: null,    ACTUAL: 0,      REMAIN: null,   PROG: null, },
      { TYPE: 'WEEK', DATE: '43895',  DISC: 'TUE',   DISC_SHORT1: 'TUE',                  TOTAL: null,    ACTUAL: 0,      REMAIN: null,   PROG: null, },
      { TYPE: 'WEEK', DATE: '43896',  DISC: 'FRI',   DISC_SHORT1: 'FRI',                  TOTAL: null,    ACTUAL: 0,      REMAIN: null,   PROG: null, },
      { TYPE: 'WEEK', DATE: '43896',  DISC: 'TWK',   DISC_SHORT1: 'TWK',                  TOTAL: null,    ACTUAL: 506,    REMAIN: null,   PROG: null, },
    ],
}