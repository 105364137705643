import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 550,
      CanvasHeight: 160,
      CanvasBorderWeight: 0,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    
    DataItems:  [
      {TOTAL:23,NOTSTARTED:0,INPROGRESS:13,AITR_COMPLETED:2,PARTIAL_MC_COMPLETE:2,FULL_MC_COMPLETE:6}
    ]
    
}