const AreaProgress = [
  
  {NO:3,TITLE:"MOORING",P_PG:0,A_PG:0,D_PG:0,bColor:"#F3F3F2"},
  {NO:1,TITLE:"HULL",P_PG:95.94,A_PG:94.8,D_PG:-1.14,bColor:"#FFE000"},
  {NO:2,TITLE:"TOPSIDE",P_PG:88.71,A_PG:86.67,D_PG:-2.03,bColor:"#CFCD1A"},
  {NO:4,TITLE:"MECH. COMPLETION",P_PG:33.2,A_PG:32.5,D_PG:-0.6,bColor:"#2F993D"},
  {NO:5,TITLE:"COMMISSIONING",P_PG:3.7,A_PG:3.7,D_PG:0,bColor:"#F7BACF"}

  
]


export default AreaProgress