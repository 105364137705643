import __C from '../../../../../../includes/primitives/_constant_'

export default {
  Canvas: {
    CanvasWidth: 400,
    CanvasHeight: 260,
    CanvasBorderWeight: 0.1,
    CanvasBorderColor: '#e0e0e0',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.2,

    CanvasChartAreaAllowed: 'Y',
    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 400,
    CanvasChartHeight: 260,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: 'Transparent',
    CanvasChartFillColor: 'Transparent',
    CanvasChartOpacity: 0.02,
    CanvasChartSpace: 20,

    CanvasFontFamily: 'roboto'
  },
  Text: {
    Text: [
      { text: '', x:20, y: 10, font: 'roboto', class: 'regular', size: 12, color: '#424242', align: 'start' },
    ],
    Filter: [
      { name: 'MOD'   , x: -50, y: 20, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'start' },
    ],
    Sql: [
      {
        query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
        text: 'Data Source',
        x: -30,
        y: 20,
        font: 'roboto',
        style: 'regular',
        size: 11,
        color: '#44A9DF',
        align: 'start'
      },
    ]
  },
  Queries: {
    SQL1: [
      // {TOTAL:18567,COMPLETE:6091,REMAIN:12476,PROG:32.8}
    ],
    SQL2:  [
      {WK_NO:"W2022_23"}
    ],
  },
  DataItems: [
    {REVIEW_STATUS:"REVIEW STATUS - 3",SEI:197,SUPPLIER:795},
    {REVIEW_STATUS:"REVIEW STATUS - 4",SEI:220,SUPPLIER:64}
  ],
}