import __C from '../../../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 1300,
      CanvasHeight: 100,
      CanvasBorderWeight: 0.5,
      CanvasBorderColor: '#ccc',
      CanvasFillColor: 'Transparent',
      CanvasOpacity: 0.2,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 1300,
      CanvasChartHeight: 760,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    Text: {
      Text: [
        { text: 'Weight : ', x:20, y: 0, font: 'roboto', class: 'regular', size: 11, color: '#BCBCBC', align: 'start' },
      ],
      Filter: [
        { name: 'MOD'   , x: 20, y: 0, font: 'roboto', class: 'regular', size: 12, color: '#313131', align: 'end' },
      ],
      Sql: [
        {
          query: "SELECT 'PBF COMPESSOR AIR COOLER - TRAIN 4' TITLE", 
          text: 'Data Source',
          x: 20,
          y: 0,
          font: 'roboto',
          style: 'regular',
          size: 11,
          color: '#44A9DF',
          align: 'end'
        },
      ]
    },
    DataItems: [
      {title:"W2021_52",WD_handover:7,WD_excluded_A_ITR:0,WD_excluded_A_Punch:0},
      {title:"W2022_01",WD_handover:3,WD_excluded_A_ITR:0,WD_excluded_A_Punch:0},
      {title:"W2022_02",WD_handover:14,WD_excluded_A_ITR:0,WD_excluded_A_Punch:0},
      {title:"W2022_03",WD_handover:6,WD_excluded_A_ITR:0,WD_excluded_A_Punch:0},
      {title:"W2022_04",WD_handover:12,WD_excluded_A_ITR:0,WD_excluded_A_Punch:0},
      {title:"W2022_05",WD_handover:5,WD_excluded_A_ITR:0,WD_excluded_A_Punch:0},
      {title:"W2022_06",WD_handover:3,WD_excluded_A_ITR:1,WD_excluded_A_Punch:1},
      {title:"W2022_07",WD_handover:13,WD_excluded_A_ITR:11,WD_excluded_A_Punch:10},
      {title:"W2022_08",WD_handover:2,WD_excluded_A_ITR:0,WD_excluded_A_Punch:1},
      {title:"W2022_09",WD_handover:6,WD_excluded_A_ITR:4,WD_excluded_A_Punch:5},
      {title:"W2022_10",WD_handover:8,WD_excluded_A_ITR:6,WD_excluded_A_Punch:6},
      {title:"W2022_11",WD_handover:10,WD_excluded_A_ITR:3,WD_excluded_A_Punch:4},
      {title:"W2022_12",WD_handover:15,WD_excluded_A_ITR:6,WD_excluded_A_Punch:8},
      {title:"W2022_13",WD_handover:9,WD_excluded_A_ITR:7,WD_excluded_A_Punch:6},
      {title:"W2022_14",WD_handover:0,WD_excluded_A_ITR:0,WD_excluded_A_Punch:0},
      {title:"W2022_15",WD_handover:1,WD_excluded_A_ITR:1,WD_excluded_A_Punch:1},
      {title:"W2022_16",WD_handover:22,WD_excluded_A_ITR:9,WD_excluded_A_Punch:12},
      {title:"W2022_17",WD_handover:15,WD_excluded_A_ITR:12,WD_excluded_A_Punch:7},
      {title:"W2022_18",WD_handover:20,WD_excluded_A_ITR:16,WD_excluded_A_Punch:13},
      {title:"W2022_19",WD_handover:30,WD_excluded_A_ITR:25,WD_excluded_A_Punch:17},
      {title:"W2022_20",WD_handover:25,WD_excluded_A_ITR:19,WD_excluded_A_Punch:17},
      {title:"W2022_21",WD_handover:21,WD_excluded_A_ITR:14,WD_excluded_A_Punch:14},
      {title:"W2022_22",WD_handover:39,WD_excluded_A_ITR:34,WD_excluded_A_Punch:35},
      {title:"W2022_23",WD_handover:20,WD_excluded_A_ITR:13,WD_excluded_A_Punch:11}
      ]
    }