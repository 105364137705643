
export default {
  Canvas: {
    CanvasWidth: 1300,
    CanvasHeight: 900, // 2100
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 900,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#000',
    CanvasChartOpacity: 1,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  DataItems: [
  { CWA_CODE: '111300',    SYS_OWNER: 'Co-Product System Owner',       PLAN: '2024-09-20', ACTUAL: null,  A_SYS_QTY: 54,  ASH_TOT: 3280,  ASH_ACT: 291,  ASH_REM: 2989,  ASH_PRO: 8.9,  ASH_STATUS: 'INPROGRESS', AP_TOT: 34,  AP_ACT: 6,   AP_REM: 28,  AP_PRO: 17.6, AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 968,  BSH_ACT: 0,    BSH_REM: 968,  BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 6,   CSH_ACT: 0,   CSH_REM: 6,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Benzene Toluene Battery Limit 745', },
  { CWA_CODE: '111342',    SYS_OWNER: 'Co-Product System Owner',       PLAN: '2024-09-10', ACTUAL: null,  A_SYS_QTY: 4,   ASH_TOT: 33,    ASH_ACT: 17,   ASH_REM: 16,    ASH_PRO: 51.5, ASH_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 12,   BSH_ACT: 0,    BSH_REM: 12,   BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 2,   CSH_ACT: 0,   CSH_REM: 2,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Benzene Toluene Sanitary Sewage Sump 745', },
  { CWA_CODE: '111394',    SYS_OWNER: 'P1 Building System Owner',      PLAN: '2024-09-30', ACTUAL: null,  A_SYS_QTY: 15,  ASH_TOT: 346,   ASH_ACT: 12,   ASH_REM: 334,   ASH_PRO: 3.5,  ASH_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 128,  BSH_ACT: 0,    BSH_REM: 128,  BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 1,   CSH_ACT: 0,   CSH_REM: 1,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Benzene Toluene Coproduct Operator Shelter 745', },
  { CWA_CODE: '121300',    SYS_OWNER: 'GPPE System Owner',             PLAN: '2024-07-31', ACTUAL: null,  A_SYS_QTY: 22,  ASH_TOT: 596,   ASH_ACT: 137,  ASH_REM: 459,   ASH_PRO: 23,   ASH_STATUS: 'INPROGRESS', AP_TOT: 37,  AP_ACT: 4,   AP_REM: 33,  AP_PRO: 10.8, AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 261,  BSH_ACT: 1,    BSH_REM: 260,  BSH_PRO: 0.4,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'GPPE Battery limit 7350 GPPE', },
  { CWA_CODE: '121301',    SYS_OWNER: 'GPPE System Owner',             PLAN: '2024-05-30', ACTUAL: null,  A_SYS_QTY: 46,  ASH_TOT: 602,   ASH_ACT: 16,   ASH_REM: 586,   ASH_PRO: 2.7,  ASH_STATUS: 'INPROGRESS', AP_TOT: 7,   AP_ACT: 0,   AP_REM: 7,   AP_PRO: 0,    AP_STATUS: 'NOTSTARTED',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 134,  BSH_ACT: 0,    BSH_REM: 134,  BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'GPPE Interconnecting Pipe Rack 7350 GPPE', },
  { CWA_CODE: '121302',    SYS_OWNER: 'GPPE System Owner',             PLAN: '2024-05-18', ACTUAL: null,  A_SYS_QTY: 36,  ASH_TOT: 947,   ASH_ACT: 418,  ASH_REM: 529,   ASH_PRO: 44.1, ASH_STATUS: 'INPROGRESS', AP_TOT: 31,  AP_ACT: 0,   AP_REM: 31,  AP_PRO: 0,    AP_STATUS: 'NOTSTARTED',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 300,  BSH_ACT: 2,    BSH_REM: 298,  BSH_PRO: 0.7,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 1,   CSH_ACT: 0,   CSH_REM: 1,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'GPPE Feed And Utility Area A 7350 GPPE A', },
  { CWA_CODE: '121303',    SYS_OWNER: 'GPPE System Owner',             PLAN: '2024-05-15', ACTUAL: null,  A_SYS_QTY: 19,  ASH_TOT: 342,   ASH_ACT: 170,  ASH_REM: 172,   ASH_PRO: 49.7, ASH_STATUS: 'INPROGRESS', AP_TOT: 17,  AP_ACT: 0,   AP_REM: 17,  AP_PRO: 0,    AP_STATUS: 'NOTSTARTED',    BP_TOT: 1,    BP_ACT: 0,    BP_REM: 1,   BP_PRO: 0,    BP_STATUS: 'NOTSTARTED',    BSH_TOT: 124,  BSH_ACT: 2,    BSH_REM: 122,  BSH_PRO: 1.6,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'GPPE Feed And Utility Area B 7350 GPPE B', },
  { CWA_CODE: '121304',    SYS_OWNER: 'Finishing System Owner',        PLAN: '2024-05-22', ACTUAL: null,  A_SYS_QTY: 112, ASH_TOT: 5275,  ASH_ACT: 169,  ASH_REM: 5106,  ASH_PRO: 3.2,  ASH_STATUS: 'INPROGRESS', AP_TOT: 54,  AP_ACT: 11,  AP_REM: 43,  AP_PRO: 20.4, AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 2430, BSH_ACT: 25,   BSH_REM: 2405, BSH_PRO: 1,    BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 88,  CSH_ACT: 0,   CSH_REM: 88,  CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'GPPE Extrusion Building 7350 GPPE', },
  { CWA_CODE: '121305',    SYS_OWNER: 'GPPE System Owner',             PLAN: '2024-06-30', ACTUAL: null,  A_SYS_QTY: 11,  ASH_TOT: 136,   ASH_ACT: 43,   ASH_REM: 93,    ASH_PRO: 31.6, ASH_STATUS: 'INPROGRESS', AP_TOT: 5,   AP_ACT: 0,   AP_REM: 5,   AP_PRO: 0,    AP_STATUS: 'NOTSTARTED',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 42,   BSH_ACT: 0,    BSH_REM: 42,   BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 2,   CSH_ACT: 0,   CSH_REM: 2,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'GPPE Thermal Oxidizer Blower Area 7350 GPPE', },
  { CWA_CODE: '121306',    SYS_OWNER: 'Finishing System Owner',        PLAN: '2024-05-30', ACTUAL: null,  A_SYS_QTY: 48,  ASH_TOT: 1221,  ASH_ACT: 187,  ASH_REM: 1034,  ASH_PRO: 15.3, ASH_STATUS: 'INPROGRESS', AP_TOT: 10,  AP_ACT: 4,   AP_REM: 6,   AP_PRO: 40,   AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 296,  BSH_ACT: 4,    BSH_REM: 292,  BSH_PRO: 1.4,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 18,  CSH_ACT: 0,   CSH_REM: 18,  CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'GPPE Pellet Dryers Classifiers And Surge Bin Area 7350 GPPE', },
  { CWA_CODE: '121307',    SYS_OWNER: 'Finishing System Owner',        PLAN: '2024-04-25', ACTUAL: null,  A_SYS_QTY: 21,  ASH_TOT: 280,   ASH_ACT: 260,  ASH_REM: 20,    ASH_PRO: 92.9, ASH_STATUS: 'INPROGRESS', AP_TOT: 91,  AP_ACT: 69,  AP_REM: 22,  AP_PRO: 75.8, AP_STATUS: 'INPROGRESS',    BP_TOT: 83,   BP_ACT: 40,   BP_REM: 43,  BP_PRO: 48.2, BP_STATUS: 'INPROGRESS',    BSH_TOT: 196,  BSH_ACT: 0,    BSH_REM: 196,  BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 4,   CSH_ACT: 0,   CSH_REM: 4,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'GPPE Pellet Transfer Compressor Area 7350 GPPE', },
  { CWA_CODE: '121308',    SYS_OWNER: 'Finishing System Owner',        PLAN: '2024-05-30', ACTUAL: null,  A_SYS_QTY: 37,  ASH_TOT: 2305,  ASH_ACT: 123,  ASH_REM: 2182,  ASH_PRO: 5.3,  ASH_STATUS: 'INPROGRESS', AP_TOT: 30,  AP_ACT: 22,  AP_REM: 8,   AP_PRO: 73.3, AP_STATUS: 'INPROGRESS',    BP_TOT: 18,   BP_ACT: 2,    BP_REM: 16,  BP_PRO: 11.1, BP_STATUS: 'INPROGRESS',    BSH_TOT: 683,  BSH_ACT: 0,    BSH_REM: 683,  BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 10,  CSH_ACT: 0,   CSH_REM: 10,  CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'GPPE Elutriator And Pellet Silos Area 7350 GPPE', },
  { CWA_CODE: '121311',    SYS_OWNER: 'GPPE System Owner',             PLAN: '2024-06-17', ACTUAL: null,  A_SYS_QTY: 72,  ASH_TOT: 3932,  ASH_ACT: 268,  ASH_REM: 3664,  ASH_PRO: 6.8,  ASH_STATUS: 'INPROGRESS', AP_TOT: 76,  AP_ACT: 9,   AP_REM: 67,  AP_PRO: 11.8, AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 984,  BSH_ACT: 3,    BSH_REM: 981,  BSH_PRO: 0.3,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 13,  CSH_ACT: 0,   CSH_REM: 13,  CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'GPPE Reaction And Purge Bin Area Of Line 1 7350 GPPE 1', },
  { CWA_CODE: '121312',    SYS_OWNER: 'GPPE System Owner',             PLAN: '2024-07-31', ACTUAL: null,  A_SYS_QTY: 36,  ASH_TOT: 1710,  ASH_ACT: 147,  ASH_REM: 1563,  ASH_PRO: 8.6,  ASH_STATUS: 'INPROGRESS', AP_TOT: 26,  AP_ACT: 8,   AP_REM: 18,  AP_PRO: 30.8, AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 435,  BSH_ACT: 4,    BSH_REM: 431,  BSH_PRO: 0.9,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 1,   CSH_ACT: 0,   CSH_REM: 1,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'GPPE Vent Gas Recovery And Propylene Refrigeration Area Of Line 1 7350 GPPE 1', },
  { CWA_CODE: '121313',    SYS_OWNER: 'GPPE System Owner',             PLAN: '2024-04-25', ACTUAL: null,  A_SYS_QTY: 12,  ASH_TOT: 201,   ASH_ACT: 170,  ASH_REM: 31,    ASH_PRO: 84.6, ASH_STATUS: 'INPROGRESS', AP_TOT: 105, AP_ACT: 57,  AP_REM: 48,  AP_PRO: 54.3, AP_STATUS: 'INPROGRESS',    BP_TOT: 23,   BP_ACT: 4,    BP_REM: 19,  BP_PRO: 17.4, BP_STATUS: 'INPROGRESS',    BSH_TOT: 101,  BSH_ACT: 0,    BSH_REM: 101,  BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 2,   CSH_ACT: 0,   CSH_REM: 2,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'GPPE Granules Conveying System Of Line 1 7350 GPPE 1', },
  { CWA_CODE: '121321',    SYS_OWNER: 'GPPE System Owner',             PLAN: '2024-07-31', ACTUAL: null,  A_SYS_QTY: 64,  ASH_TOT: 3513,  ASH_ACT: 238,  ASH_REM: 3275,  ASH_PRO: 6.8,  ASH_STATUS: 'INPROGRESS', AP_TOT: 77,  AP_ACT: 9,   AP_REM: 68,  AP_PRO: 11.7, AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 893,  BSH_ACT: 3,    BSH_REM: 890,  BSH_PRO: 0.3,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 14,  CSH_ACT: 0,   CSH_REM: 14,  CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'GPPE Reaction And Purge Bin Area Of Line 2 7350 GPPE 2', },
  { CWA_CODE: '121322',    SYS_OWNER: 'GPPE System Owner',             PLAN: '2024-07-31', ACTUAL: null,  A_SYS_QTY: 34,  ASH_TOT: 1497,  ASH_ACT: 154,  ASH_REM: 1343,  ASH_PRO: 10.3, ASH_STATUS: 'INPROGRESS', AP_TOT: 21,  AP_ACT: 7,   AP_REM: 14,  AP_PRO: 33.3, AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 367,  BSH_ACT: 2,    BSH_REM: 365,  BSH_PRO: 0.5,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 1,   CSH_ACT: 0,   CSH_REM: 1,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'GPPE Vent Gas Recovery And Propylene Refrigeration Area Of Line 2 7350 GPPE 2', },
  { CWA_CODE: '121323',    SYS_OWNER: 'GPPE System Owner',             PLAN: '2024-04-25', ACTUAL: null,  A_SYS_QTY: 16,  ASH_TOT: 229,   ASH_ACT: 201,  ASH_REM: 28,    ASH_PRO: 87.8, ASH_STATUS: 'INPROGRESS', AP_TOT: 89,  AP_ACT: 55,  AP_REM: 34,  AP_PRO: 61.8, AP_STATUS: 'INPROGRESS',    BP_TOT: 31,   BP_ACT: 9,    BP_REM: 22,  BP_PRO: 29,   BP_STATUS: 'INPROGRESS',    BSH_TOT: 114,  BSH_ACT: 0,    BSH_REM: 114,  BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 2,   CSH_ACT: 0,   CSH_REM: 2,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'GPPE Granules Conveying System Of Line 2 7350 GPPE 2', },
  { CWA_CODE: '121332',    SYS_OWNER: 'P2 Building System Owner',      PLAN: '2024-06-01', ACTUAL: null,  A_SYS_QTY: 14,  ASH_TOT: 198,   ASH_ACT: 20,   ASH_REM: 178,   ASH_PRO: 10.1, ASH_STATUS: 'INPROGRESS', AP_TOT: 2,   AP_ACT: 0,   AP_REM: 2,   AP_PRO: 0,    AP_STATUS: 'NOTSTARTED',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 122,  BSH_ACT: 0,    BSH_REM: 122,  BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 1,   CSH_ACT: 0,   CSH_REM: 1,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'GPPE CHEMICAL STORAGE 7350 GPPE', },
  { CWA_CODE: '121342',    SYS_OWNER: 'GPPE System Owner',             PLAN: '2024-03-19', ACTUAL: '2024-03-19', A_SYS_QTY: 13,  ASH_TOT: 57,    ASH_ACT: 53,   ASH_REM: 4,     ASH_PRO: 93,   ASH_STATUS: 'TURNOVER',   AP_TOT: 37,  AP_ACT: 32,  AP_REM: 5,   AP_PRO: 86.5, AP_STATUS: 'TURNOVER',      BP_TOT: 48,   BP_ACT: 36,   BP_REM: 12,  BP_PRO: 75,   BP_STATUS: 'TURNOVER',      BSH_TOT: 39,   BSH_ACT: 20,   BSH_REM: 19,   BSH_PRO: 51.3, BSH_STATUS: 'TURNOVER',      CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 4,   CSH_ACT: 0,   CSH_REM: 4,   CSH_PRO: 0, CSH_STATUS: 'TURNOVER', CWA_DESC: 'GPPE Waste WaterStorm Water Area 7350 GPPE', },
  { CWA_CODE: '121391',    SYS_OWNER: 'P2 Building System Owner',      PLAN: '2024-02-22', ACTUAL: '2024-02-22', A_SYS_QTY: 33,  ASH_TOT: 885,   ASH_ACT: 800,  ASH_REM: 85,    ASH_PRO: 90.4, ASH_STATUS: 'TURNOVER',   AP_TOT: 209, AP_ACT: 135, AP_REM: 74,  AP_PRO: 64.6, AP_STATUS: 'TURNOVER',      BP_TOT: 76,   BP_ACT: 58,   BP_REM: 18,  BP_PRO: 76.3, BP_STATUS: 'TURNOVER',      BSH_TOT: 383,  BSH_ACT: 196,  BSH_REM: 187,  BSH_PRO: 51.2, BSH_STATUS: 'TURNOVER',      CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 1,   CSH_ACT: 0,   CSH_REM: 1,   CSH_PRO: 0, CSH_STATUS: 'TURNOVER', CWA_DESC: 'GPPE RIE 5 7350 GPPE GPPE RIE 5', },
  { CWA_CODE: '121392',    SYS_OWNER: 'P2 Building System Owner',      PLAN: '2024-01-29', ACTUAL: '2024-01-29', A_SYS_QTY: 56,  ASH_TOT: 2380,  ASH_ACT: 2343, ASH_REM: 37,    ASH_PRO: 98.4, ASH_STATUS: 'TURNOVER',   AP_TOT: 317, AP_ACT: 306, AP_REM: 11,  AP_PRO: 96.5, AP_STATUS: 'TURNOVER',      BP_TOT: 182,  BP_ACT: 132,  BP_REM: 50,  BP_PRO: 72.5, BP_STATUS: 'TURNOVER',      BSH_TOT: 2291, BSH_ACT: 1745, BSH_REM: 546,  BSH_PRO: 76.2, BSH_STATUS: 'TURNOVER',      CP_TOT: 1,  CP_ACT: 0,  CP_REM: 1, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 18,  CSH_ACT: 11,  CSH_REM: 7,   CSH_PRO: 61.1, CSH_STATUS: 'TURNOVER', CWA_DESC: 'GPPE Substation 7350 GPPE', },
  { CWA_CODE: '121400',    SYS_OWNER: 'LDPE System Owner',             PLAN: '2024-05-30', ACTUAL: null,  A_SYS_QTY: 66,  ASH_TOT: 1129,  ASH_ACT: 75,   ASH_REM: 1054,  ASH_PRO: 6.6,  ASH_STATUS: 'INPROGRESS', AP_TOT: 29,  AP_ACT: 1,   AP_REM: 28,  AP_PRO: 3.4,  AP_STATUS: 'INPROGRESS',    BP_TOT: 7,    BP_ACT: 0,    BP_REM: 7,   BP_PRO: 0,    BP_STATUS: 'NOTSTARTED',    BSH_TOT: 409,  BSH_ACT: 1,    BSH_REM: 408,  BSH_PRO: 0.2,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'LDPE Battery Limit 50 LDPE', },
  { CWA_CODE: '121401-01', SYS_OWNER: 'LDPE System Owner',             PLAN: '2024-05-30', ACTUAL: null,  A_SYS_QTY: 39,  ASH_TOT: 1127,  ASH_ACT: 38,   ASH_REM: 1089,  ASH_PRO: 3.4,  ASH_STATUS: 'INPROGRESS', AP_TOT: 2,   AP_ACT: 1,   AP_REM: 1,   AP_PRO: 50,   AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 85,   BSH_ACT: 2,    BSH_REM: 83,   BSH_PRO: 2.4,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 4,   CSH_ACT: 0,   CSH_REM: 4,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'LDPE PP Compressor', },
  { CWA_CODE: '121401-02', SYS_OWNER: 'LDPE System Owner',             PLAN: '2024-07-30', ACTUAL: null,  A_SYS_QTY: 5,   ASH_TOT: 70,    ASH_ACT: 0,    ASH_REM: 70,    ASH_PRO: 0,    ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 0,    BSH_ACT: 0,    BSH_REM: 0,    BSH_PRO: 0,    BSH_STATUS: 'NOTREGISTERED', CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'LDPE Compressor IP Piping', },
  { CWA_CODE: '121401-03', SYS_OWNER: 'LDPE System Owner',             PLAN: '2024-10-31', ACTUAL: null,  A_SYS_QTY: 12,  ASH_TOT: 1151,  ASH_ACT: 5,    ASH_REM: 1146,  ASH_PRO: 0.4,  ASH_STATUS: 'INPROGRESS', AP_TOT: 5,   AP_ACT: 2,   AP_REM: 3,   AP_PRO: 40,   AP_STATUS: 'INPROGRESS',    BP_TOT: 4,    BP_ACT: 0,    BP_REM: 4,   BP_PRO: 0,    BP_STATUS: 'NOTSTARTED',    BSH_TOT: 737,  BSH_ACT: 0,    BSH_REM: 737,  BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 5,   CSH_ACT: 0,   CSH_REM: 5,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'LDPE 2nd Compressor Piping Installation', },
  { CWA_CODE: '121402',    SYS_OWNER: 'LDPE System Owner',             PLAN: '2024-08-30', ACTUAL: null,  A_SYS_QTY: 29,  ASH_TOT: 1457,  ASH_ACT: 360,  ASH_REM: 1097,  ASH_PRO: 24.7, ASH_STATUS: 'INPROGRESS', AP_TOT: 19,  AP_ACT: 0,   AP_REM: 19,  AP_PRO: 0,    AP_STATUS: 'NOTSTARTED',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 157,  BSH_ACT: 0,    BSH_REM: 157,  BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'LDPE Reaction Area 50', },
  { CWA_CODE: '121403',    SYS_OWNER: 'LDPE System Owner',             PLAN: '2024-06-30', ACTUAL: null,  A_SYS_QTY: 29,  ASH_TOT: 602,   ASH_ACT: 101,  ASH_REM: 501,   ASH_PRO: 16.8, ASH_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    AP_STATUS: 'NOTREGISTERED', BP_TOT: 2,    BP_ACT: 0,    BP_REM: 2,   BP_PRO: 0,    BP_STATUS: 'NOTSTARTED',    BSH_TOT: 116,  BSH_ACT: 1,    BSH_REM: 115,  BSH_PRO: 0.9,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 1,   CSH_ACT: 0,   CSH_REM: 1,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'LDPE Hp Recycle Area 50', },
  { CWA_CODE: '121404',    SYS_OWNER: 'LDPE System Owner',             PLAN: '2024-04-30', ACTUAL: null,  A_SYS_QTY: 18,  ASH_TOT: 372,   ASH_ACT: 165,  ASH_REM: 207,   ASH_PRO: 44.4, ASH_STATUS: 'INPROGRESS', AP_TOT: 2,   AP_ACT: 1,   AP_REM: 1,   AP_PRO: 50,   AP_STATUS: 'INPROGRESS',    BP_TOT: 1,    BP_ACT: 0,    BP_REM: 1,   BP_PRO: 0,    BP_STATUS: 'NOTSTARTED',    BSH_TOT: 83,   BSH_ACT: 0,    BSH_REM: 83,   BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 1,   CSH_ACT: 0,   CSH_REM: 1,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'LDPE Purge Gas Area 50', },
  { CWA_CODE: '121405',    SYS_OWNER: 'Finishing System Owner',        PLAN: '2024-06-30', ACTUAL: null,  A_SYS_QTY: 52,  ASH_TOT: 1761,  ASH_ACT: 100,  ASH_REM: 1661,  ASH_PRO: 5.7,  ASH_STATUS: 'INPROGRESS', AP_TOT: 9,   AP_ACT: 0,   AP_REM: 9,   AP_PRO: 0,    AP_STATUS: 'NOTSTARTED',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 468,  BSH_ACT: 2,    BSH_REM: 466,  BSH_PRO: 0.4,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 23,  CSH_ACT: 0,   CSH_REM: 23,  CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'LDPE Extrusion Area 50', },
  { CWA_CODE: '121406',    SYS_OWNER: 'LDPE System Owner',             PLAN: '2024-04-20', ACTUAL: '2024-04-29', A_SYS_QTY: 7,   ASH_TOT: 56,    ASH_ACT: 56,   ASH_REM: 0,     ASH_PRO: 100,  ASH_STATUS: 'TURNOVER',   AP_TOT: 47,  AP_ACT: 32,  AP_REM: 15,  AP_PRO: 68.1, AP_STATUS: 'TURNOVER',      BP_TOT: 40,   BP_ACT: 20,   BP_REM: 20,  BP_PRO: 50,   BP_STATUS: 'TURNOVER',      BSH_TOT: 77,   BSH_ACT: 15,   BSH_REM: 62,   BSH_PRO: 19.5, BSH_STATUS: 'TURNOVER',      CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 9,   CSH_ACT: 0,   CSH_REM: 9,   CSH_PRO: 0, CSH_STATUS: 'TURNOVER', CWA_DESC: 'LDPE Compressor Lubrication Cooling Oil 50', },
  { CWA_CODE: '121407',    SYS_OWNER: 'LDPE System Owner',             PLAN: '2024-07-25', ACTUAL: null,  A_SYS_QTY: 33,  ASH_TOT: 1709,  ASH_ACT: 197,  ASH_REM: 1512,  ASH_PRO: 11.5, ASH_STATUS: 'INPROGRESS', AP_TOT: 12,  AP_ACT: 9,   AP_REM: 3,   AP_PRO: 75,   AP_STATUS: 'INPROGRESS',    BP_TOT: 23,   BP_ACT: 0,    BP_REM: 23,  BP_PRO: 0,    BP_STATUS: 'NOTSTARTED',    BSH_TOT: 368,  BSH_ACT: 4,    BSH_REM: 364,  BSH_PRO: 1.1,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 6,   CSH_ACT: 0,   CSH_REM: 6,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'LDPE Initiator Mixing 50', },
  { CWA_CODE: '121408',    SYS_OWNER: 'LDPE System Owner',             PLAN: '2024-05-18', ACTUAL: null,  A_SYS_QTY: 17,  ASH_TOT: 263,   ASH_ACT: 141,  ASH_REM: 122,   ASH_PRO: 53.6, ASH_STATUS: 'INPROGRESS', AP_TOT: 18,  AP_ACT: 6,   AP_REM: 12,  AP_PRO: 33.3, AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 244,  BSH_ACT: 3,    BSH_REM: 241,  BSH_PRO: 1.2,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 7,   CSH_ACT: 0,   CSH_REM: 7,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'LDPE Chilled Water 50', },
  { CWA_CODE: '121409',    SYS_OWNER: 'LDPE System Owner',             PLAN: '2024-07-20', ACTUAL: null,  A_SYS_QTY: 22,  ASH_TOT: 255,   ASH_ACT: 25,   ASH_REM: 230,   ASH_PRO: 9.8,  ASH_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    AP_STATUS: 'NOTREGISTERED', BP_TOT: 1,    BP_ACT: 0,    BP_REM: 1,   BP_PRO: 0,    BP_STATUS: 'NOTSTARTED',    BSH_TOT: 43,   BSH_ACT: 0,    BSH_REM: 43,   BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'LDPE Flare KnockOut 50', },
  { CWA_CODE: '121410',    SYS_OWNER: 'LDPE System Owner',             PLAN: '2024-06-22', ACTUAL: null,  A_SYS_QTY: 26,  ASH_TOT: 598,   ASH_ACT: 139,  ASH_REM: 459,   ASH_PRO: 23.2, ASH_STATUS: 'INPROGRESS', AP_TOT: 4,   AP_ACT: 4,   AP_REM: 0,   AP_PRO: 100,  AP_STATUS: 'COMPLETED',     BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 147,  BSH_ACT: 13,   BSH_REM: 134,  BSH_PRO: 8.8,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 14,  CSH_ACT: 0,   CSH_REM: 14,  CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'LDPE Utility Water Area 50', },
  { CWA_CODE: '121411',    SYS_OWNER: 'LDPE System Owner',             PLAN: '2024-06-30', ACTUAL: null,  A_SYS_QTY: 26,  ASH_TOT: 900,   ASH_ACT: 126,  ASH_REM: 774,   ASH_PRO: 14,   ASH_STATUS: 'INPROGRESS', AP_TOT: 9,   AP_ACT: 9,   AP_REM: 0,   AP_PRO: 100,  AP_STATUS: 'COMPLETED',     BP_TOT: 3,    BP_ACT: 0,    BP_REM: 3,   BP_PRO: 0,    BP_STATUS: 'NOTSTARTED',    BSH_TOT: 245,  BSH_ACT: 13,   BSH_REM: 232,  BSH_PRO: 5.3,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 10,  CSH_ACT: 0,   CSH_REM: 10,  CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'LDPE Modifier and Solvent System 50', },
  { CWA_CODE: '121412',    SYS_OWNER: 'LDPE System Owner',             PLAN: '2024-07-28', ACTUAL: null,  A_SYS_QTY: 16,  ASH_TOT: 599,   ASH_ACT: 12,   ASH_REM: 587,   ASH_PRO: 2,    ASH_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 143,  BSH_ACT: 0,    BSH_REM: 143,  BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 3,   CSH_ACT: 0,   CSH_REM: 3,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'LDPE Vent Gas Treatment Package 50 RTO', },
  { CWA_CODE: '121413',    SYS_OWNER: 'Finishing System Owner',        PLAN: '2024-06-28', ACTUAL: null,  A_SYS_QTY: 42,  ASH_TOT: 2475,  ASH_ACT: 307,  ASH_REM: 2168,  ASH_PRO: 12.4, ASH_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 598,  BSH_ACT: 2,    BSH_REM: 596,  BSH_PRO: 0.3,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 16,  CSH_ACT: 0,   CSH_REM: 16,  CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'LDPE Pellet Degassing Silos 50', },
  { CWA_CODE: '121414',    SYS_OWNER: 'Finishing System Owner',        PLAN: '2024-06-26', ACTUAL: null,  A_SYS_QTY: 20,  ASH_TOT: 646,   ASH_ACT: 1,    ASH_REM: 645,   ASH_PRO: 0.2,  ASH_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 143,  BSH_ACT: 0,    BSH_REM: 143,  BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 4,   CSH_ACT: 0,   CSH_REM: 4,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'LDPE Blend And Bagging Silos 50', },
  { CWA_CODE: '121415',    SYS_OWNER: 'P2 Building System Owner',      PLAN: '2024-09-30', ACTUAL: null,  A_SYS_QTY: 12,  ASH_TOT: 192,   ASH_ACT: 0,    ASH_REM: 192,   ASH_PRO: 0,    ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 44,   BSH_ACT: 0,    BSH_REM: 44,   BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 1,   CSH_ACT: 0,   CSH_REM: 1,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'LDPE At LineLAB 50', },
  { CWA_CODE: '121442',    SYS_OWNER: 'LDPE System Owner',             PLAN: '2024-04-25', ACTUAL: '2024-04-29', A_SYS_QTY: 11,  ASH_TOT: 87,    ASH_ACT: 83,   ASH_REM: 4,     ASH_PRO: 95.4, ASH_STATUS: 'TURNOVER',   AP_TOT: 33,  AP_ACT: 22,  AP_REM: 11,  AP_PRO: 66.7, AP_STATUS: 'TURNOVER',      BP_TOT: 35,   BP_ACT: 2,    BP_REM: 33,  BP_PRO: 5.7,  BP_STATUS: 'TURNOVER',      BSH_TOT: 47,   BSH_ACT: 11,   BSH_REM: 36,   BSH_PRO: 23.4, BSH_STATUS: 'TURNOVER',      CP_TOT: 1,  CP_ACT: 0,  CP_REM: 1, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 4,   CSH_ACT: 0,   CSH_REM: 4,   CSH_PRO: 0, CSH_STATUS: 'TURNOVER', CWA_DESC: 'LDPE Sump Area 50', },
  { CWA_CODE: '121491',    SYS_OWNER: 'P2 Building System Owner',      PLAN: '2024-03-07', ACTUAL: '2024-03-07', A_SYS_QTY: 30,  ASH_TOT: 902,   ASH_ACT: 845,  ASH_REM: 57,    ASH_PRO: 93.7, ASH_STATUS: 'TURNOVER',   AP_TOT: 168, AP_ACT: 88,  AP_REM: 80,  AP_PRO: 52.4, AP_STATUS: 'TURNOVER',      BP_TOT: 91,   BP_ACT: 61,   BP_REM: 30,  BP_PRO: 67,   BP_STATUS: 'TURNOVER',      BSH_TOT: 592,  BSH_ACT: 160,  BSH_REM: 432,  BSH_PRO: 27,   BSH_STATUS: 'TURNOVER',      CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 1,   CSH_ACT: 0,   CSH_REM: 1,   CSH_PRO: 0, CSH_STATUS: 'TURNOVER', CWA_DESC: 'LDPE RIE 3 50', },
  { CWA_CODE: '121492',    SYS_OWNER: 'P2 Building System Owner',      PLAN: '2023-12-31', ACTUAL: '2023-12-31', A_SYS_QTY: 43,  ASH_TOT: 1783,  ASH_ACT: 1730, ASH_REM: 53,    ASH_PRO: 97,   ASH_STATUS: 'TURNOVER',   AP_TOT: 164, AP_ACT: 153, AP_REM: 11,  AP_PRO: 93.3, AP_STATUS: 'TURNOVER',      BP_TOT: 198,  BP_ACT: 147,  BP_REM: 51,  BP_PRO: 74.2, BP_STATUS: 'TURNOVER',      BSH_TOT: 1614, BSH_ACT: 1307, BSH_REM: 307,  BSH_PRO: 81,   BSH_STATUS: 'TURNOVER',      CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 11,  CSH_ACT: 5,   CSH_REM: 6,   CSH_PRO: 45.5, CSH_STATUS: 'TURNOVER', CWA_DESC: 'LDPE Substation 50 LDPE', },
  { CWA_CODE: '121900',    SYS_OWNER: 'PP System Owner',               PLAN: '2024-07-01', ACTUAL: null,  A_SYS_QTY: 49,  ASH_TOT: 1179,  ASH_ACT: 12,   ASH_REM: 1167,  ASH_PRO: 1,    ASH_STATUS: 'INPROGRESS', AP_TOT: 53,  AP_ACT: 5,   AP_REM: 48,  AP_PRO: 9.4,  AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 409,  BSH_ACT: 0,    BSH_REM: 409,  BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'PP Battery Limit 48475 PP', },
  { CWA_CODE: '121901',    SYS_OWNER: 'PP System Owner',               PLAN: '2024-05-30', ACTUAL: null,  A_SYS_QTY: 34,  ASH_TOT: 202,   ASH_ACT: 0,    ASH_REM: 202,   ASH_PRO: 0,    ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 78,   BSH_ACT: 0,    BSH_REM: 78,   BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'PP Battery Limit Pipe Rack 48475', },
  { CWA_CODE: '121902',    SYS_OWNER: 'PP System Owner',               PLAN: '2024-06-15', ACTUAL: null,  A_SYS_QTY: 20,  ASH_TOT: 481,   ASH_ACT: 61,   ASH_REM: 420,   ASH_PRO: 12.7, ASH_STATUS: 'INPROGRESS', AP_TOT: 12,  AP_ACT: 0,   AP_REM: 12,  AP_PRO: 0,    AP_STATUS: 'NOTSTARTED',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 124,  BSH_ACT: 0,    BSH_REM: 124,  BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 3,   CSH_ACT: 0,   CSH_REM: 3,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'PP TEAL Facility Area 48475 PP', },
  { CWA_CODE: '121903',    SYS_OWNER: 'Finishing System Owner',        PLAN: '2024-05-31', ACTUAL: null,  A_SYS_QTY: 130, ASH_TOT: 7450,  ASH_ACT: 162,  ASH_REM: 7288,  ASH_PRO: 2.2,  ASH_STATUS: 'INPROGRESS', AP_TOT: 55,  AP_ACT: 12,  AP_REM: 43,  AP_PRO: 21.8, AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 2727, BSH_ACT: 18,   BSH_REM: 2709, BSH_PRO: 0.7,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 119, CSH_ACT: 0,   CSH_REM: 119, CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'PP Granules Transfer and Extrusion Building 48475 PP', },
  { CWA_CODE: '121904',    SYS_OWNER: 'Finishing System Owner',        PLAN: '2024-05-31', ACTUAL: null,  A_SYS_QTY: 23,  ASH_TOT: 1766,  ASH_ACT: 40,   ASH_REM: 1726,  ASH_PRO: 2.3,  ASH_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 654,  BSH_ACT: 4,    BSH_REM: 650,  BSH_PRO: 0.6,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 15,  CSH_ACT: 0,   CSH_REM: 15,  CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'PP Pellet Silos Area 48475 PP', },
  { CWA_CODE: '121905',    SYS_OWNER: 'Finishing System Owner',        PLAN: '2024-05-26', ACTUAL: null,  A_SYS_QTY: 28,  ASH_TOT: 885,   ASH_ACT: 90,   ASH_REM: 795,   ASH_PRO: 10.2, ASH_STATUS: 'INPROGRESS', AP_TOT: 11,  AP_ACT: 5,   AP_REM: 6,   AP_PRO: 45.5, AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 373,  BSH_ACT: 0,    BSH_REM: 373,  BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 8,   CSH_ACT: 0,   CSH_REM: 8,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'PP Pellet Elutriation and Bagging Area 48475 PP', },
  { CWA_CODE: '121906',    SYS_OWNER: 'PP System Owner',               PLAN: '2024-06-27', ACTUAL: null,  A_SYS_QTY: 12,  ASH_TOT: 139,   ASH_ACT: 25,   ASH_REM: 114,   ASH_PRO: 18,   ASH_STATUS: 'INPROGRESS', AP_TOT: 2,   AP_ACT: 2,   AP_REM: 0,   AP_PRO: 100,  AP_STATUS: 'COMPLETED',     BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 79,   BSH_ACT: 0,    BSH_REM: 79,   BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 2,   CSH_ACT: 0,   CSH_REM: 2,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'PP Hydrogen Compressor Unit 48475 PP', },
  { CWA_CODE: '121907',    SYS_OWNER: 'PP System Owner',               PLAN: '2024-06-21', ACTUAL: null,  A_SYS_QTY: 24,  ASH_TOT: 792,   ASH_ACT: 43,   ASH_REM: 749,   ASH_PRO: 5.4,  ASH_STATUS: 'INPROGRESS', AP_TOT: 33,  AP_ACT: 4,   AP_REM: 29,  AP_PRO: 12.1, AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 265,  BSH_ACT: 0,    BSH_REM: 265,  BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 14,  CSH_ACT: 0,   CSH_REM: 14,  CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'PP OilGrease Storage and Mixing Propylene Dryer Area 48475 PP', },
  { CWA_CODE: '121908',    SYS_OWNER: 'PP System Owner',               PLAN: '2024-06-25', ACTUAL: null,  A_SYS_QTY: 24,  ASH_TOT: 282,   ASH_ACT: 27,   ASH_REM: 255,   ASH_PRO: 9.6,  ASH_STATUS: 'INPROGRESS', AP_TOT: 21,  AP_ACT: 0,   AP_REM: 21,  AP_PRO: 0,    AP_STATUS: 'NOTSTARTED',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 228,  BSH_ACT: 5,    BSH_REM: 223,  BSH_PRO: 2.2,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 6,   CSH_ACT: 0,   CSH_REM: 6,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'PP Oil Treatment Chilled Water and Steam Condensate Drum Area 48475 PP', },
  { CWA_CODE: '121909',    SYS_OWNER: 'PP System Owner',               PLAN: '2024-05-16', ACTUAL: null,  A_SYS_QTY: 15,  ASH_TOT: 540,   ASH_ACT: 7,    ASH_REM: 533,   ASH_PRO: 1.3,  ASH_STATUS: 'INPROGRESS', AP_TOT: 6,   AP_ACT: 0,   AP_REM: 6,   AP_PRO: 0,    AP_STATUS: 'NOTSTARTED',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 161,  BSH_ACT: 0,    BSH_REM: 161,  BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 5,   CSH_ACT: 0,   CSH_REM: 5,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'PP Regenerative Thermal Oxidizer Area 48475 PP RTO', },
  { CWA_CODE: '121911',    SYS_OWNER: 'PP System Owner',               PLAN: '2024-06-06', ACTUAL: null,  A_SYS_QTY: 24,  ASH_TOT: 399,   ASH_ACT: 41,   ASH_REM: 358,   ASH_PRO: 10.3, ASH_STATUS: 'INPROGRESS', AP_TOT: 3,   AP_ACT: 1,   AP_REM: 2,   AP_PRO: 33.3, AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 120,  BSH_ACT: 2,    BSH_REM: 118,  BSH_PRO: 1.7,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 2,   CSH_ACT: 0,   CSH_REM: 2,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'PP CPP 1 Propylene Feed and Steam Condensate Area 48475 PP1', },
  { CWA_CODE: '121912',    SYS_OWNER: 'PP System Owner',               PLAN: '2024-06-26', ACTUAL: null,  A_SYS_QTY: 22,  ASH_TOT: 462,   ASH_ACT: 23,   ASH_REM: 439,   ASH_PRO: 5,    ASH_STATUS: 'INPROGRESS', AP_TOT: 3,   AP_ACT: 1,   AP_REM: 2,   AP_PRO: 33.3, AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 150,  BSH_ACT: 5,    BSH_REM: 145,  BSH_PRO: 3.3,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 2,   CSH_ACT: 0,   CSH_REM: 2,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'PP CPP 1 Catalyst Preparation and Metering Area 48475 PP1', },
  { CWA_CODE: '121913',    SYS_OWNER: 'PP System Owner',               PLAN: '2024-06-01', ACTUAL: null,  A_SYS_QTY: 30,  ASH_TOT: 1202,  ASH_ACT: 47,   ASH_REM: 1155,  ASH_PRO: 3.9,  ASH_STATUS: 'INPROGRESS', AP_TOT: 7,   AP_ACT: 3,   AP_REM: 4,   AP_PRO: 42.9, AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 345,  BSH_ACT: 6,    BSH_REM: 339,  BSH_PRO: 1.7,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 7,   CSH_ACT: 0,   CSH_REM: 7,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'PP CPP 1 Bulk Polymerization Area 48475 PP1', },
  { CWA_CODE: '121914',    SYS_OWNER: 'PP System Owner',               PLAN: '2024-06-11', ACTUAL: null,  A_SYS_QTY: 62,  ASH_TOT: 2973,  ASH_ACT: 147,  ASH_REM: 2826,  ASH_PRO: 4.9,  ASH_STATUS: 'INPROGRESS', AP_TOT: 52,  AP_ACT: 16,  AP_REM: 36,  AP_PRO: 30.8, AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 883,  BSH_ACT: 12,   BSH_REM: 871,  BSH_PRO: 1.4,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 20,  CSH_ACT: 0,   CSH_REM: 20,  CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'PP CPP 1 Gas Phase Reactors Polymer Degassing and Granules Drying Area 48475 PP1', },
  { CWA_CODE: '121915',    SYS_OWNER: 'PP System Owner',               PLAN: '2024-05-30', ACTUAL: null,  A_SYS_QTY: 31,  ASH_TOT: 237,   ASH_ACT: 4,    ASH_REM: 233,   ASH_PRO: 1.7,  ASH_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 79,   BSH_ACT: 0,    BSH_REM: 79,   BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'PP CPP 1 Polymerization Area Pipe Rack 48475 PP1', },
  { CWA_CODE: '121916',    SYS_OWNER: 'PP System Owner',               PLAN: '2024-06-16', ACTUAL: null,  A_SYS_QTY: 24,  ASH_TOT: 1406,  ASH_ACT: 88,   ASH_REM: 1318,  ASH_PRO: 6.3,  ASH_STATUS: 'INPROGRESS', AP_TOT: 10,  AP_ACT: 8,   AP_REM: 2,   AP_PRO: 80,   AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 339,  BSH_ACT: 2,    BSH_REM: 337,  BSH_PRO: 0.6,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 8,   CSH_ACT: 0,   CSH_REM: 8,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'PP CPP 1 TEAL Scrubber and Recycle Gas Compressor Area 48475 PP1TEAL', },
  { CWA_CODE: '121917',    SYS_OWNER: 'PP System Owner',               PLAN: '2024-06-21', ACTUAL: null,  A_SYS_QTY: 20,  ASH_TOT: 195,   ASH_ACT: 20,   ASH_REM: 175,   ASH_PRO: 10.3, ASH_STATUS: 'INPROGRESS', AP_TOT: 2,   AP_ACT: 0,   AP_REM: 2,   AP_PRO: 0,    AP_STATUS: 'NOTSTARTED',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 56,   BSH_ACT: 0,    BSH_REM: 56,   BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'PP CPP 1 Blowdown Area 48475 PP1', },
  { CWA_CODE: '121921',    SYS_OWNER: 'PP System Owner',               PLAN: '2024-06-16', ACTUAL: null,  A_SYS_QTY: 24,  ASH_TOT: 385,   ASH_ACT: 51,   ASH_REM: 334,   ASH_PRO: 13.2, ASH_STATUS: 'INPROGRESS', AP_TOT: 3,   AP_ACT: 1,   AP_REM: 2,   AP_PRO: 33.3, AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 123,  BSH_ACT: 1,    BSH_REM: 122,  BSH_PRO: 0.8,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 1,   CSH_ACT: 0,   CSH_REM: 1,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'PP CPP 2 Propylene Feed Area 48475 PP2', },
  { CWA_CODE: '121922',    SYS_OWNER: 'PP System Owner',               PLAN: '2024-06-29', ACTUAL: null,  A_SYS_QTY: 28,  ASH_TOT: 412,   ASH_ACT: 30,   ASH_REM: 382,   ASH_PRO: 7.3,  ASH_STATUS: 'INPROGRESS', AP_TOT: 3,   AP_ACT: 1,   AP_REM: 2,   AP_PRO: 33.3, AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 129,  BSH_ACT: 2,    BSH_REM: 127,  BSH_PRO: 1.6,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 2,   CSH_ACT: 0,   CSH_REM: 2,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'PP CPP 2 Catalyst Preparation and Metering Area 48475 PP2', },
  { CWA_CODE: '121923',    SYS_OWNER: 'PP System Owner',               PLAN: '2024-06-15', ACTUAL: null,  A_SYS_QTY: 29,  ASH_TOT: 1162,  ASH_ACT: 54,   ASH_REM: 1108,  ASH_PRO: 4.6,  ASH_STATUS: 'INPROGRESS', AP_TOT: 10,  AP_ACT: 6,   AP_REM: 4,   AP_PRO: 60,   AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 312,  BSH_ACT: 2,    BSH_REM: 310,  BSH_PRO: 0.6,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 5,   CSH_ACT: 0,   CSH_REM: 5,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'PP CPP 2 Bulk Polymerization Area 48475 PP2', },
  { CWA_CODE: '121924',    SYS_OWNER: 'PP System Owner',               PLAN: '2024-06-17', ACTUAL: null,  A_SYS_QTY: 27,  ASH_TOT: 832,   ASH_ACT: 48,   ASH_REM: 784,   ASH_PRO: 5.8,  ASH_STATUS: 'INPROGRESS', AP_TOT: 41,  AP_ACT: 13,  AP_REM: 28,  AP_PRO: 31.7, AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 231,  BSH_ACT: 3,    BSH_REM: 228,  BSH_PRO: 1.3,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'PP CPP 2 Polymer Degassing and Granules Drying Area 48475 PP2', },
  { CWA_CODE: '121925',    SYS_OWNER: 'PP System Owner',               PLAN: '2024-05-30', ACTUAL: null,  A_SYS_QTY: 28,  ASH_TOT: 245,   ASH_ACT: 1,    ASH_REM: 244,   ASH_PRO: 0.4,  ASH_STATUS: 'INPROGRESS', AP_TOT: 2,   AP_ACT: 0,   AP_REM: 2,   AP_PRO: 0,    AP_STATUS: 'NOTSTARTED',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 68,   BSH_ACT: 0,    BSH_REM: 68,   BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'PP CPP 2 Polymerization Area Pipe Rack 48475 PP2', },
  { CWA_CODE: '121926',    SYS_OWNER: 'PP System Owner',               PLAN: '2024-06-19', ACTUAL: null,  A_SYS_QTY: 18,  ASH_TOT: 547,   ASH_ACT: 19,   ASH_REM: 528,   ASH_PRO: 3.5,  ASH_STATUS: 'INPROGRESS', AP_TOT: 4,   AP_ACT: 4,   AP_REM: 0,   AP_PRO: 100,  AP_STATUS: 'COMPLETED',     BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 178,  BSH_ACT: 1,    BSH_REM: 177,  BSH_PRO: 0.6,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 4,   CSH_ACT: 0,   CSH_REM: 4,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'PP CPP 2 TEAL Scrubber and Recycle Gas Compressor Area 48475 PP2TEAL', },
  { CWA_CODE: '121927',    SYS_OWNER: 'PP System Owner',               PLAN: '2024-06-23', ACTUAL: null,  A_SYS_QTY: 21,  ASH_TOT: 288,   ASH_ACT: 13,   ASH_REM: 275,   ASH_PRO: 4.5,  ASH_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 0,   AP_REM: 1,   AP_PRO: 0,    AP_STATUS: 'NOTSTARTED',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 70,   BSH_ACT: 0,    BSH_REM: 70,   BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'PP CPP 2 Blowdown and Ethylene Compressor Area 48475 PP2', },
  { CWA_CODE: '121931',    SYS_OWNER: 'P2 Building System Owner',      PLAN: '2024-07-01', ACTUAL: null,  A_SYS_QTY: 6,   ASH_TOT: 30,    ASH_ACT: 26,   ASH_REM: 4,     ASH_PRO: 86.7, ASH_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 9,    BSH_ACT: 0,    BSH_REM: 9,    BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 1,   CSH_ACT: 0,   CSH_REM: 1,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'PP New LubeWaste Oil Drum Storage 48475 PP', },
  { CWA_CODE: '121932',    SYS_OWNER: 'P2 Building System Owner',      PLAN: '2024-06-01', ACTUAL: null,  A_SYS_QTY: 13,  ASH_TOT: 211,   ASH_ACT: 10,   ASH_REM: 201,   ASH_PRO: 4.7,  ASH_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 120,  BSH_ACT: 0,    BSH_REM: 120,  BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 1,   CSH_ACT: 0,   CSH_REM: 1,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'PP Chemical Storage 48475 PP', },
  { CWA_CODE: '121942',    SYS_OWNER: 'PP System Owner',               PLAN: '2024-04-20', ACTUAL: '2024-04-24', A_SYS_QTY: 9,   ASH_TOT: 103,   ASH_ACT: 97,   ASH_REM: 6,     ASH_PRO: 94.2, ASH_STATUS: 'TURNOVER',   AP_TOT: 51,  AP_ACT: 43,  AP_REM: 8,   AP_PRO: 84.3, AP_STATUS: 'TURNOVER',      BP_TOT: 46,   BP_ACT: 15,   BP_REM: 31,  BP_PRO: 32.6, BP_STATUS: 'TURNOVER',      BSH_TOT: 41,   BSH_ACT: 7,    BSH_REM: 34,   BSH_PRO: 17.1, BSH_STATUS: 'TURNOVER',      CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 4,   CSH_ACT: 0,   CSH_REM: 4,   CSH_PRO: 0, CSH_STATUS: 'TURNOVER', CWA_DESC: 'PP Waste WaterStorm Water Area 48475 PP', },
  { CWA_CODE: '121991',    SYS_OWNER: 'P2 Building System Owner',      PLAN: '2024-02-27', ACTUAL: '2024-02-27', A_SYS_QTY: 29,  ASH_TOT: 937,   ASH_ACT: 899,  ASH_REM: 38,    ASH_PRO: 95.9, ASH_STATUS: 'TURNOVER',   AP_TOT: 94,  AP_ACT: 83,  AP_REM: 11,  AP_PRO: 88.3, AP_STATUS: 'TURNOVER',      BP_TOT: 63,   BP_ACT: 44,   BP_REM: 19,  BP_PRO: 69.8, BP_STATUS: 'TURNOVER',      BSH_TOT: 360,  BSH_ACT: 79,   BSH_REM: 281,  BSH_PRO: 21.9, BSH_STATUS: 'TURNOVER',      CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 1,   CSH_ACT: 0,   CSH_REM: 1,   CSH_PRO: 0, CSH_STATUS: 'TURNOVER', CWA_DESC: 'PP RIE 4 48475 PPRIE 4', },
  { CWA_CODE: '121992',    SYS_OWNER: 'P2 Building System Owner',      PLAN: '2024-01-29', ACTUAL: '2024-01-29', A_SYS_QTY: 51,  ASH_TOT: 2671,  ASH_ACT: 2578, ASH_REM: 93,    ASH_PRO: 96.5, ASH_STATUS: 'TURNOVER',   AP_TOT: 246, AP_ACT: 217, AP_REM: 29,  AP_PRO: 88.2, AP_STATUS: 'TURNOVER',      BP_TOT: 124,  BP_ACT: 90,   BP_REM: 34,  BP_PRO: 72.6, BP_STATUS: 'TURNOVER',      BSH_TOT: 2626, BSH_ACT: 1913, BSH_REM: 713,  BSH_PRO: 72.8, BSH_STATUS: 'TURNOVER',      CP_TOT: 2,  CP_ACT: 2,  CP_REM: 0, CP_PRO: 100,  CP_STATUS: 'TURNOVER',      CSH_TOT: 16,  CSH_ACT: 8,   CSH_REM: 8,   CSH_PRO: 50, CSH_STATUS: 'TURNOVER', CWA_DESC: 'PP Substation 48475 PP', },
  { CWA_CODE: '124700',    SYS_OWNER: 'Tank Farm System Owner',        PLAN: '2024-06-19', ACTUAL: null,  A_SYS_QTY: 31,  ASH_TOT: 1369,  ASH_ACT: 134,  ASH_REM: 1235,  ASH_PRO: 9.8,  ASH_STATUS: 'INPROGRESS', AP_TOT: 20,  AP_ACT: 12,  AP_REM: 8,   AP_PRO: 60,   AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 400,  BSH_ACT: 6,    BSH_REM: 394,  BSH_PRO: 1.5,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 4,   CSH_ACT: 0,   CSH_REM: 4,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Infra Propylene Tank Farm', },
  { CWA_CODE: '127700',    SYS_OWNER: 'Co-Product System Owner',       PLAN: '2024-08-30', ACTUAL: null,  A_SYS_QTY: 83,  ASH_TOT: 6155,  ASH_ACT: 678,  ASH_REM: 5477,  ASH_PRO: 11,   ASH_STATUS: 'INPROGRESS', AP_TOT: 141, AP_ACT: 16,  AP_REM: 125, AP_PRO: 11.3, AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 1753, BSH_ACT: 0,    BSH_REM: 1753, BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 29,  CSH_ACT: 0,   CSH_REM: 29,  CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Butadiene Battery Limit 27', },
  { CWA_CODE: '127742',    SYS_OWNER: 'Co-Product System Owner',       PLAN: '2024-08-20', ACTUAL: null,  A_SYS_QTY: 7,   ASH_TOT: 130,   ASH_ACT: 47,   ASH_REM: 83,    ASH_PRO: 36.2, ASH_STATUS: 'INPROGRESS', AP_TOT: 3,   AP_ACT: 0,   AP_REM: 3,   AP_PRO: 0,    AP_STATUS: 'NOTSTARTED',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 50,   BSH_ACT: 0,    BSH_REM: 50,   BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 5,   CSH_ACT: 0,   CSH_REM: 5,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Butadiene CoProduct Waste Water Sump 27', },
  { CWA_CODE: '127791',    SYS_OWNER: 'P1 Building System Owner',      PLAN: '2024-06-30', ACTUAL: null,  A_SYS_QTY: 21,  ASH_TOT: 706,   ASH_ACT: 167,  ASH_REM: 539,   ASH_PRO: 23.7, ASH_STATUS: 'INPROGRESS', AP_TOT: 3,   AP_ACT: 0,   AP_REM: 3,   AP_PRO: 0,    AP_STATUS: 'NOTSTARTED',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 123,  BSH_ACT: 0,    BSH_REM: 123,  BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 1,   CSH_ACT: 0,   CSH_REM: 1,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Butadiene RIE 2 27 RIE2', },
  { CWA_CODE: '127792',    SYS_OWNER: 'P1 Building System Owner',      PLAN: '2024-06-30', ACTUAL: null,  A_SYS_QTY: 36,  ASH_TOT: 1273,  ASH_ACT: 849,  ASH_REM: 424,   ASH_PRO: 66.7, ASH_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 1610, BSH_ACT: 0,    BSH_REM: 1610, BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 9,   CSH_ACT: 0,   CSH_REM: 9,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Butadiene Coproduct Substation 27', },
  { CWA_CODE: '127800',    SYS_OWNER: 'Co-Product System Owner',       PLAN: '2024-08-30', ACTUAL: null,  A_SYS_QTY: 80,  ASH_TOT: 7257,  ASH_ACT: 220,  ASH_REM: 7037,  ASH_PRO: 3,    ASH_STATUS: 'INPROGRESS', AP_TOT: 102, AP_ACT: 6,   AP_REM: 96,  AP_PRO: 5.9,  AP_STATUS: 'INPROGRESS',    BP_TOT: 1,    BP_ACT: 0,    BP_REM: 1,   BP_PRO: 0,    BP_STATUS: 'NOTSTARTED',    BSH_TOT: 1703, BSH_ACT: 0,    BSH_REM: 1703, BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 11,  CSH_ACT: 0,   CSH_REM: 11,  CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Gasoline Hydrotreating Battery Limit 120', },
  { CWA_CODE: '127900',    SYS_OWNER: 'CGC System Owner',              PLAN: '2024-09-20', ACTUAL: null,  A_SYS_QTY: 88,  ASH_TOT: 2097,  ASH_ACT: 3,    ASH_REM: 2094,  ASH_PRO: 0.1,  ASH_STATUS: 'INPROGRESS', AP_TOT: 57,  AP_ACT: 2,   AP_REM: 55,  AP_PRO: 3.5,  AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 530,  BSH_ACT: 28,   BSH_REM: 502,  BSH_PRO: 5.3,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'Olefins Battery Limit 160', },
  { CWA_CODE: '127901',    SYS_OWNER: 'Boiler & Furnace System Owner', PLAN: '2024-09-25', ACTUAL: null,  A_SYS_QTY: 253, ASH_TOT: 27646, ASH_ACT: 68,   ASH_REM: 27578, ASH_PRO: 0.2,  ASH_STATUS: 'INPROGRESS', AP_TOT: 142, AP_ACT: 17,  AP_REM: 125, AP_PRO: 12,   AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 6367, BSH_ACT: 107,  BSH_REM: 6260, BSH_PRO: 1.7,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 31,  CSH_ACT: 0,   CSH_REM: 31,  CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Olefins Furnaces Area 160', },
  { CWA_CODE: '127902',    SYS_OWNER: 'Boiler & Furnace System Owner', PLAN: '2024-09-10', ACTUAL: null,  A_SYS_QTY: 87,  ASH_TOT: 5121,  ASH_ACT: 327,  ASH_REM: 4794,  ASH_PRO: 6.4,  ASH_STATUS: 'INPROGRESS', AP_TOT: 150, AP_ACT: 8,   AP_REM: 142, AP_PRO: 5.3,  AP_STATUS: 'INPROGRESS',    BP_TOT: 5,    BP_ACT: 0,    BP_REM: 5,   BP_PRO: 0,    BP_STATUS: 'NOTSTARTED',    BSH_TOT: 1299, BSH_ACT: 42,   BSH_REM: 1257, BSH_PRO: 3.2,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 31,  CSH_ACT: 0,   CSH_REM: 31,  CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Olefins Quench Area 160', },
  { CWA_CODE: '127903',    SYS_OWNER: 'CGC System Owner',              PLAN: '2024-09-30', ACTUAL: null,  A_SYS_QTY: 106, ASH_TOT: 7342,  ASH_ACT: 367,  ASH_REM: 6975,  ASH_PRO: 5,    ASH_STATUS: 'INPROGRESS', AP_TOT: 169, AP_ACT: 12,  AP_REM: 157, AP_PRO: 7.1,  AP_STATUS: 'INPROGRESS',    BP_TOT: 17,   BP_ACT: 0,    BP_REM: 17,  BP_PRO: 0,    BP_STATUS: 'NOTSTARTED',    BSH_TOT: 1746, BSH_ACT: 49,   BSH_REM: 1697, BSH_PRO: 2.8,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 27,  CSH_ACT: 0,   CSH_REM: 27,  CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Olefins Compression Area 160', },
  { CWA_CODE: '127904',    SYS_OWNER: 'CGC System Owner',              PLAN: '2024-09-20', ACTUAL: null,  A_SYS_QTY: 99,  ASH_TOT: 6880,  ASH_ACT: 359,  ASH_REM: 6521,  ASH_PRO: 5.2,  ASH_STATUS: 'INPROGRESS', AP_TOT: 251, AP_ACT: 67,  AP_REM: 184, AP_PRO: 26.7, AP_STATUS: 'INPROGRESS',    BP_TOT: 7,    BP_ACT: 0,    BP_REM: 7,   BP_PRO: 0,    BP_STATUS: 'NOTSTARTED',    BSH_TOT: 1668, BSH_ACT: 40,   BSH_REM: 1628, BSH_PRO: 2.4,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 7,   CSH_ACT: 0,   CSH_REM: 7,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Olefins Cold Fraction Area 160', },
  { CWA_CODE: '127905',    SYS_OWNER: 'Boiler & Furnace System Owner', PLAN: '2024-08-25', ACTUAL: null,  A_SYS_QTY: 61,  ASH_TOT: 3905,  ASH_ACT: 352,  ASH_REM: 3553,  ASH_PRO: 9,    ASH_STATUS: 'INPROGRESS', AP_TOT: 88,  AP_ACT: 11,  AP_REM: 77,  AP_PRO: 12.5, AP_STATUS: 'INPROGRESS',    BP_TOT: 26,   BP_ACT: 0,    BP_REM: 26,  BP_PRO: 0,    BP_STATUS: 'NOTSTARTED',    BSH_TOT: 920,  BSH_ACT: 30,   BSH_REM: 890,  BSH_PRO: 3.3,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 13,  CSH_ACT: 0,   CSH_REM: 13,  CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Olefins Hot Fraction Area 160', },
  { CWA_CODE: '127906',    SYS_OWNER: 'CGC System Owner',              PLAN: '2024-09-12', ACTUAL: null,  A_SYS_QTY: 11,  ASH_TOT: 380,   ASH_ACT: 26,   ASH_REM: 354,   ASH_PRO: 6.8,  ASH_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    AP_STATUS: 'NOTREGISTERED', BP_TOT: 1,    BP_ACT: 0,    BP_REM: 1,   BP_PRO: 0,    BP_STATUS: 'NOTSTARTED',    BSH_TOT: 184,  BSH_ACT: 2,    BSH_REM: 182,  BSH_PRO: 1.1,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 1,   CSH_ACT: 0,   CSH_REM: 1,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Olefins PSA Unit 160 PSA', },
  { CWA_CODE: '127907',    SYS_OWNER: 'CGC System Owner',              PLAN: '2024-09-15', ACTUAL: null,  A_SYS_QTY: 30,  ASH_TOT: 1414,  ASH_ACT: 150,  ASH_REM: 1264,  ASH_PRO: 10.6, ASH_STATUS: 'INPROGRESS', AP_TOT: 38,  AP_ACT: 0,   AP_REM: 38,  AP_PRO: 0,    AP_STATUS: 'NOTSTARTED',    BP_TOT: 3,    BP_ACT: 0,    BP_REM: 3,   BP_PRO: 0,    BP_STATUS: 'NOTSTARTED',    BSH_TOT: 286,  BSH_ACT: 0,    BSH_REM: 286,  BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 11,  CSH_ACT: 0,   CSH_REM: 11,  CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Olefins Spent Caustic Area 160', },
  { CWA_CODE: '127908',    SYS_OWNER: 'Boiler & Furnace System Owner', PLAN: '2024-08-20', ACTUAL: null,  A_SYS_QTY: 32,  ASH_TOT: 1163,  ASH_ACT: 47,   ASH_REM: 1116,  ASH_PRO: 4,    ASH_STATUS: 'INPROGRESS', AP_TOT: 6,   AP_ACT: 1,   AP_REM: 5,   AP_PRO: 16.7, AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 335,  BSH_ACT: 0,    BSH_REM: 335,  BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 8,   CSH_ACT: 0,   CSH_REM: 8,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Olefins Steam Condensate Area 160', },
  { CWA_CODE: '127909',    SYS_OWNER: 'Boiler & Furnace System Owner', PLAN: '2024-09-30', ACTUAL: null,  A_SYS_QTY: 32,  ASH_TOT: 738,   ASH_ACT: 38,   ASH_REM: 700,   ASH_PRO: 5.1,  ASH_STATUS: 'INPROGRESS', AP_TOT: 8,   AP_ACT: 0,   AP_REM: 8,   AP_PRO: 0,    AP_STATUS: 'NOTSTARTED',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 184,  BSH_ACT: 5,    BSH_REM: 179,  BSH_PRO: 2.7,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 4,   CSH_ACT: 0,   CSH_REM: 4,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Olefins Benzene Removal Area 160', },
  { CWA_CODE: '127942',    SYS_OWNER: 'Water System Owner',            PLAN: '2024-08-30', ACTUAL: null,  A_SYS_QTY: 12,  ASH_TOT: 168,   ASH_ACT: 9,    ASH_REM: 159,   ASH_PRO: 5.4,  ASH_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 66,   BSH_ACT: 0,    BSH_REM: 66,   BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 8,   CSH_ACT: 0,   CSH_REM: 8,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Olefins Waste Water Sump 160', },
  { CWA_CODE: '127982',    SYS_OWNER: 'P1 Building System Owner',      PLAN: '2024-06-30', ACTUAL: null,  A_SYS_QTY: 35,  ASH_TOT: 856,   ASH_ACT: 780,  ASH_REM: 76,    ASH_PRO: 91.1, ASH_STATUS: 'INPROGRESS', AP_TOT: 46,  AP_ACT: 15,  AP_REM: 31,  AP_PRO: 32.6, AP_STATUS: 'INPROGRESS',    BP_TOT: 49,   BP_ACT: 6,    BP_REM: 43,  BP_PRO: 12.2, BP_STATUS: 'INPROGRESS',    BSH_TOT: 1074, BSH_ACT: 1,    BSH_REM: 1073, BSH_PRO: 0.1,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 7,   CSH_ACT: 0,   CSH_REM: 7,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Olefins 2nd Substation 160', },
  { CWA_CODE: '127991',    SYS_OWNER: 'P1 Building System Owner',      PLAN: '2024-06-30', ACTUAL: null,  A_SYS_QTY: 28,  ASH_TOT: 1433,  ASH_ACT: 1034, ASH_REM: 399,   ASH_PRO: 72.2, ASH_STATUS: 'INPROGRESS', AP_TOT: 66,  AP_ACT: 24,  AP_REM: 42,  AP_PRO: 36.4, AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 400,  BSH_ACT: 0,    BSH_REM: 400,  BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 1,   CSH_ACT: 0,   CSH_REM: 1,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Olefins RIE1 160 RIE1', },
  { CWA_CODE: '127992',    SYS_OWNER: 'P1 Building System Owner',      PLAN: '2024-04-30', ACTUAL: '2024-04-28', A_SYS_QTY: 34,  ASH_TOT: 1024,  ASH_ACT: 990,  ASH_REM: 34,    ASH_PRO: 96.7, ASH_STATUS: 'TURNOVER',   AP_TOT: 52,  AP_ACT: 51,  AP_REM: 1,   AP_PRO: 98.1, AP_STATUS: 'TURNOVER',      BP_TOT: 45,   BP_ACT: 36,   BP_REM: 9,   BP_PRO: 80,   BP_STATUS: 'TURNOVER',      BSH_TOT: 1561, BSH_ACT: 0,    BSH_REM: 1561, BSH_PRO: 0,    BSH_STATUS: 'TURNOVER',      CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 9,   CSH_ACT: 0,   CSH_REM: 9,   CSH_PRO: 0, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Olefins 1st Substation 160', },
  { CWA_CODE: '136200',    SYS_OWNER: 'Co-Product System Owner',       PLAN: '2024-09-30', ACTUAL: null,  A_SYS_QTY: 59,  ASH_TOT: 3474,  ASH_ACT: 315,  ASH_REM: 3159,  ASH_PRO: 9.1,  ASH_STATUS: 'INPROGRESS', AP_TOT: 14,  AP_ACT: 0,   AP_REM: 14,  AP_PRO: 0,    AP_STATUS: 'NOTSTARTED',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 897,  BSH_ACT: 0,    BSH_REM: 897,  BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 8,   CSH_ACT: 0,   CSH_REM: 8,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'DiIsobutylene Battery Limit 155DIB', },
  { CWA_CODE: '150200',    SYS_OWNER: 'P3 Building System Owner',      PLAN: '2024-09-30', ACTUAL: null,  A_SYS_QTY: 4,   ASH_TOT: 48,    ASH_ACT: 0,    ASH_REM: 48,    ASH_PRO: 0,    ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 1132, BSH_ACT: 253,  BSH_REM: 879,  BSH_PRO: 22.3, BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'Infra Overall Grading Layout Landscaping Road layout', },
  { CWA_CODE: '150300',    SYS_OWNER: 'Utility System Owner',          PLAN: '2024-04-30', ACTUAL: null,  A_SYS_QTY: 8,   ASH_TOT: 557,   ASH_ACT: 197,  ASH_REM: 360,   ASH_PRO: 35.4, ASH_STATUS: 'INPROGRESS', AP_TOT: 29,  AP_ACT: 2,   AP_REM: 27,  AP_PRO: 6.9,  AP_STATUS: 'INPROGRESS',    BP_TOT: 46,   BP_ACT: 1,    BP_REM: 45,  BP_PRO: 2.2,  BP_STATUS: 'INPROGRESS',    BSH_TOT: 502,  BSH_ACT: 10,   BSH_REM: 492,  BSH_PRO: 2,    BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'Mini area code for Main UG Piping for whole Plant', },
  { CWA_CODE: '150400',    SYS_OWNER: 'P3 Building System Owner',      PLAN: '2024-09-30', ACTUAL: null,  A_SYS_QTY: 71,  ASH_TOT: 665,   ASH_ACT: 0,    ASH_REM: 665,   ASH_PRO: 0,    ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 279,  BSH_ACT: 0,    BSH_REM: 279,  BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'Infra Fence for all area P1P2P3 P1P2P3', },
  { CWA_CODE: '150401',    SYS_OWNER: 'P3 Building System Owner',      PLAN: '2024-09-30', ACTUAL: null,  A_SYS_QTY: 11,  ASH_TOT: 233,   ASH_ACT: 0,    ASH_REM: 233,   ASH_PRO: 0,    ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 51,   BSH_ACT: 0,    BSH_REM: 51,   BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'Infra Gate and Gate house 1 1', },
  { CWA_CODE: '150402',    SYS_OWNER: 'P3 Building System Owner',      PLAN: '2024-09-30', ACTUAL: null,  A_SYS_QTY: 8,   ASH_TOT: 130,   ASH_ACT: 0,    ASH_REM: 130,   ASH_PRO: 0,    ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 25,   BSH_ACT: 0,    BSH_REM: 25,   BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'Infra Gate and Gate house 2 2', },
  { CWA_CODE: '150403',    SYS_OWNER: 'P3 Building System Owner',      PLAN: '2024-09-30', ACTUAL: null,  A_SYS_QTY: 7,   ASH_TOT: 166,   ASH_ACT: 0,    ASH_REM: 166,   ASH_PRO: 0,    ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 36,   BSH_ACT: 0,    BSH_REM: 36,   BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'Infra Gate and Gate house 3 3', },
  { CWA_CODE: '150404',    SYS_OWNER: 'P3 Building System Owner',      PLAN: '2024-09-30', ACTUAL: null,  A_SYS_QTY: 7,   ASH_TOT: 158,   ASH_ACT: 0,    ASH_REM: 158,   ASH_PRO: 0,    ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 36,   BSH_ACT: 0,    BSH_REM: 36,   BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'Infra Gate and Gate house 4 4', },
  { CWA_CODE: '150405',    SYS_OWNER: 'P3 Building System Owner',      PLAN: '2024-09-30', ACTUAL: null,  A_SYS_QTY: 7,   ASH_TOT: 84,    ASH_ACT: 0,    ASH_REM: 84,    ASH_PRO: 0,    ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 2,    BSH_ACT: 0,    BSH_REM: 2,    BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'Infra Gate and Gate house 5 5', },
  { CWA_CODE: '150406',    SYS_OWNER: 'P3 Building System Owner',      PLAN: '2024-09-30', ACTUAL: null,  A_SYS_QTY: 7,   ASH_TOT: 204,   ASH_ACT: 0,    ASH_REM: 204,   ASH_PRO: 0,    ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 37,   BSH_ACT: 0,    BSH_REM: 37,   BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'Infra Gate and Gate house 6 6', },
  { CWA_CODE: '151000',    SYS_OWNER: 'P3 Building System Owner',      PLAN: '2024-06-30', ACTUAL: null,  A_SYS_QTY: 13,  ASH_TOT: 84,    ASH_ACT: 6,    ASH_REM: 78,    ASH_PRO: 7.1,  ASH_STATUS: 'INPROGRESS', AP_TOT: 8,   AP_ACT: 0,   AP_REM: 8,   AP_PRO: 0,    AP_STATUS: 'NOTSTARTED',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 96,   BSH_ACT: 26,   BSH_REM: 70,   BSH_PRO: 27.1, BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'Infra Common Area of Management Facilities B B', },
  { CWA_CODE: '152000',    SYS_OWNER: 'P3 Building System Owner',      PLAN: '2024-04-25', ACTUAL: null,  A_SYS_QTY: 26,  ASH_TOT: 4307,  ASH_ACT: 4240, ASH_REM: 67,    ASH_PRO: 98.4, ASH_STATUS: 'INPROGRESS', AP_TOT: 142, AP_ACT: 116, AP_REM: 26,  AP_PRO: 81.7, AP_STATUS: 'INPROGRESS',    BP_TOT: 247,  BP_ACT: 189,  BP_REM: 58,  BP_PRO: 76.5, BP_STATUS: 'INPROGRESS',    BSH_TOT: 2132, BSH_ACT: 38,   BSH_REM: 2094, BSH_PRO: 1.8,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 6,   CSH_ACT: 1,   CSH_REM: 5,   CSH_PRO: 16.7, CSH_STATUS: 'INPROGRESS', CWA_DESC: 'Infra Central Laboratory and Environmental Monitoring Station', },
  { CWA_CODE: '152004',    SYS_OWNER: 'P3 Building System Owner',      PLAN: '2024-04-25', ACTUAL: null,  A_SYS_QTY: 4,   ASH_TOT: 15,    ASH_ACT: 11,   ASH_REM: 4,     ASH_PRO: 73.3, ASH_STATUS: 'INPROGRESS', AP_TOT: 19,  AP_ACT: 17,  AP_REM: 2,   AP_PRO: 89.5, AP_STATUS: 'INPROGRESS',    BP_TOT: 19,   BP_ACT: 13,   BP_REM: 6,   BP_PRO: 68.4, BP_STATUS: 'INPROGRESS',    BSH_TOT: 9,    BSH_ACT: 0,    BSH_REM: 9,    BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'Infra Environmental Monitoring station1 Elevated Flare area 1', },
  { CWA_CODE: '152014',    SYS_OWNER: 'P3 Building System Owner',      PLAN: '2024-03-23', ACTUAL: '2024-03-23', A_SYS_QTY: 5,   ASH_TOT: 23,    ASH_ACT: 23,   ASH_REM: 0,     ASH_PRO: 100,  ASH_STATUS: 'TURNOVER',   AP_TOT: 10,  AP_ACT: 10,  AP_REM: 0,   AP_PRO: 100,  AP_STATUS: 'TURNOVER',      BP_TOT: 12,   BP_ACT: 11,   BP_REM: 1,   BP_PRO: 91.7, BP_STATUS: 'TURNOVER',      BSH_TOT: 22,   BSH_ACT: 13,   BSH_REM: 9,    BSH_PRO: 59.1, BSH_STATUS: 'TURNOVER',      CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Infra Environmental Monitoring station2 B South area 2B', },
  { CWA_CODE: '152200',    SYS_OWNER: 'P3 Building System Owner',      PLAN: '2024-04-30', ACTUAL: null,  A_SYS_QTY: 26,  ASH_TOT: 1391,  ASH_ACT: 386,  ASH_REM: 1005,  ASH_PRO: 27.7, ASH_STATUS: 'INPROGRESS', AP_TOT: 58,  AP_ACT: 0,   AP_REM: 58,  AP_PRO: 0,    AP_STATUS: 'NOTSTARTED',    BP_TOT: 53,   BP_ACT: 0,    BP_REM: 53,  BP_PRO: 0,    BP_STATUS: 'NOTSTARTED',    BSH_TOT: 772,  BSH_ACT: 0,    BSH_REM: 772,  BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 4,   CSH_ACT: 0,   CSH_REM: 4,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Infra Maintenance Workshop', },
  { CWA_CODE: '152300',    SYS_OWNER: 'P3 Building System Owner',      PLAN: '2024-01-31', ACTUAL: '2024-01-31', A_SYS_QTY: 17,  ASH_TOT: 337,   ASH_ACT: 306,  ASH_REM: 31,    ASH_PRO: 90.8, ASH_STATUS: 'TURNOVER',   AP_TOT: 111, AP_ACT: 109, AP_REM: 2,   AP_PRO: 98.2, AP_STATUS: 'TURNOVER',      BP_TOT: 24,   BP_ACT: 6,    BP_REM: 18,  BP_PRO: 25,   BP_STATUS: 'TURNOVER',      BSH_TOT: 124,  BSH_ACT: 0,    BSH_REM: 124,  BSH_PRO: 0,    BSH_STATUS: 'TURNOVER',      CP_TOT: 1,  CP_ACT: 0,  CP_REM: 1, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 1,   CSH_ACT: 0,   CSH_REM: 1,   CSH_PRO: 0, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Infra Warehouse Management Room and Spare Parts Warehouse', },
  { CWA_CODE: '152400',    SYS_OWNER: 'P3 Building System Owner',      PLAN: '2024-04-12', ACTUAL: '2024-04-12', A_SYS_QTY: 27,  ASH_TOT: 1246,  ASH_ACT: 1198, ASH_REM: 48,    ASH_PRO: 96.1, ASH_STATUS: 'TURNOVER',   AP_TOT: 89,  AP_ACT: 86,  AP_REM: 3,   AP_PRO: 96.6, AP_STATUS: 'TURNOVER',      BP_TOT: 222,  BP_ACT: 45,   BP_REM: 177, BP_PRO: 20.3, BP_STATUS: 'TURNOVER',      BSH_TOT: 735,  BSH_ACT: 614,  BSH_REM: 121,  BSH_PRO: 83.5, BSH_STATUS: 'TURNOVER',      CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 7,   CSH_ACT: 2,   CSH_REM: 5,   CSH_PRO: 28.6, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Infra Chemical Warehouses', },
  { CWA_CODE: '152406',    SYS_OWNER: 'LDPE System Owner',             PLAN: '2024-06-30', ACTUAL: null,  A_SYS_QTY: 9,   ASH_TOT: 183,   ASH_ACT: 5,    ASH_REM: 178,   ASH_PRO: 2.7,  ASH_STATUS: 'INPROGRESS', AP_TOT: 4,   AP_ACT: 0,   AP_REM: 4,   AP_PRO: 0,    AP_STATUS: 'NOTSTARTED',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 67,   BSH_ACT: 1,    BSH_REM: 66,   BSH_PRO: 1.5,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'Chemical Warehouse No 6 LDPE Area 6 LDPE', },
  { CWA_CODE: '152700',    SYS_OWNER: 'P3 Building System Owner',      PLAN: '2024-04-30', ACTUAL: null,  A_SYS_QTY: 3,   ASH_TOT: 16,    ASH_ACT: 2,    ASH_REM: 14,    ASH_PRO: 12.5, ASH_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 1,   AP_REM: 0,   AP_PRO: 100,  AP_STATUS: 'COMPLETED',     BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 0,    BSH_ACT: 0,    BSH_REM: 0,    BSH_PRO: 0,    BSH_STATUS: 'NOTREGISTERED', CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'Infra Main plant Dispatch Area', },
  { CWA_CODE: '152800',    SYS_OWNER: 'PBL / Utility System Owner',    PLAN: '2024-05-30', ACTUAL: null,  A_SYS_QTY: 12,  ASH_TOT: 40,    ASH_ACT: 0,    ASH_REM: 40,    ASH_PRO: 0,    ASH_STATUS: 'NOTSTARTED', AP_TOT: 18,  AP_ACT: 0,   AP_REM: 18,  AP_PRO: 0,    AP_STATUS: 'NOTSTARTED',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 21,   BSH_ACT: 0,    BSH_REM: 21,   BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'PBL PBL Battery Limit', },
  { CWA_CODE: '152801',    SYS_OWNER: 'PBL / Utility System Owner',    PLAN: '2024-07-31', ACTUAL: null,  A_SYS_QTY: 108, ASH_TOT: 6846,  ASH_ACT: 3516, ASH_REM: 3330,  ASH_PRO: 51.4, ASH_STATUS: 'INPROGRESS', AP_TOT: 95,  AP_ACT: 35,  AP_REM: 60,  AP_PRO: 36.8, AP_STATUS: 'INPROGRESS',    BP_TOT: 69,   BP_ACT: 18,   BP_REM: 51,  BP_PRO: 26.1, BP_STATUS: 'INPROGRESS',    BSH_TOT: 1933, BSH_ACT: 0,    BSH_REM: 1933, BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 21,  CSH_ACT: 0,   CSH_REM: 21,  CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'PBL No1 Warehouse 1', },
  { CWA_CODE: '152802',    SYS_OWNER: 'PBL / Utility System Owner',    PLAN: '2024-07-31', ACTUAL: null,  A_SYS_QTY: 111, ASH_TOT: 6523,  ASH_ACT: 1964, ASH_REM: 4559,  ASH_PRO: 30.1, ASH_STATUS: 'INPROGRESS', AP_TOT: 20,  AP_ACT: 5,   AP_REM: 15,  AP_PRO: 25,   AP_STATUS: 'INPROGRESS',    BP_TOT: 2,    BP_ACT: 0,    BP_REM: 2,   BP_PRO: 0,    BP_STATUS: 'NOTSTARTED',    BSH_TOT: 1548, BSH_ACT: 0,    BSH_REM: 1548, BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 20,  CSH_ACT: 0,   CSH_REM: 20,  CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'PBL No2 Warehouse 2', },
  { CWA_CODE: '152803',    SYS_OWNER: 'PBL / Utility System Owner',    PLAN: '2024-05-30', ACTUAL: null,  A_SYS_QTY: 66,  ASH_TOT: 3414,  ASH_ACT: 2578, ASH_REM: 836,   ASH_PRO: 75.5, ASH_STATUS: 'INPROGRESS', AP_TOT: 46,  AP_ACT: 21,  AP_REM: 25,  AP_PRO: 45.7, AP_STATUS: 'INPROGRESS',    BP_TOT: 105,  BP_ACT: 57,   BP_REM: 48,  BP_PRO: 54.3, BP_STATUS: 'INPROGRESS',    BSH_TOT: 465,  BSH_ACT: 0,    BSH_REM: 465,  BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 2,   CSH_ACT: 0,   CSH_REM: 2,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'PBL No1 Package Plant 1', },
  { CWA_CODE: '152804',    SYS_OWNER: 'PBL / Utility System Owner',    PLAN: '2024-05-30', ACTUAL: null,  A_SYS_QTY: 58,  ASH_TOT: 3616,  ASH_ACT: 2455, ASH_REM: 1161,  ASH_PRO: 67.9, ASH_STATUS: 'INPROGRESS', AP_TOT: 119, AP_ACT: 110, AP_REM: 9,   AP_PRO: 92.4, AP_STATUS: 'INPROGRESS',    BP_TOT: 204,  BP_ACT: 154,  BP_REM: 50,  BP_PRO: 75.5, BP_STATUS: 'INPROGRESS',    BSH_TOT: 721,  BSH_ACT: 0,    BSH_REM: 721,  BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 2,   CSH_ACT: 0,   CSH_REM: 2,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'PBL No2 Package Plant 2', },
  { CWA_CODE: '152805',    SYS_OWNER: 'PBL / Utility System Owner',    PLAN: '2024-05-15', ACTUAL: null,  A_SYS_QTY: 44,  ASH_TOT: 683,   ASH_ACT: 435,  ASH_REM: 248,   ASH_PRO: 63.7, ASH_STATUS: 'INPROGRESS', AP_TOT: 204, AP_ACT: 42,  AP_REM: 162, AP_PRO: 20.6, AP_STATUS: 'INPROGRESS',    BP_TOT: 106,  BP_ACT: 5,    BP_REM: 101, BP_PRO: 4.7,  BP_STATUS: 'INPROGRESS',    BSH_TOT: 445,  BSH_ACT: 0,    BSH_REM: 445,  BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 10,  CSH_ACT: 0,   CSH_REM: 10,  CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'PBL Auxiliary Facility', },
  { CWA_CODE: '154000',    SYS_OWNER: 'P3 Building System Owner',      PLAN: '2024-03-13', ACTUAL: '2024-03-13', A_SYS_QTY: 29,  ASH_TOT: 6294,  ASH_ACT: 6293, ASH_REM: 1,     ASH_PRO: 100,  ASH_STATUS: 'TURNOVER',   AP_TOT: 607, AP_ACT: 606, AP_REM: 1,   AP_PRO: 99.8, AP_STATUS: 'TURNOVER',      BP_TOT: 569,  BP_ACT: 562,  BP_REM: 7,   BP_PRO: 98.8, BP_STATUS: 'TURNOVER',      BSH_TOT: 3847, BSH_ACT: 3847, BSH_REM: 0,    BSH_PRO: 100,  BSH_STATUS: 'TURNOVER',      CP_TOT: 4,  CP_ACT: 4,  CP_REM: 0, CP_PRO: 100,  CP_STATUS: 'TURNOVER',      CSH_TOT: 11,  CSH_ACT: 11,  CSH_REM: 0,   CSH_PRO: 100, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Infra Administration Office Building B B', },
  { CWA_CODE: '154001',    SYS_OWNER: 'P3 Building System Owner',      PLAN: '2024-06-30', ACTUAL: null,  A_SYS_QTY: 1,   ASH_TOT: 2,     ASH_ACT: 0,    ASH_REM: 2,     ASH_PRO: 0,    ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 0,    BSH_ACT: 0,    BSH_REM: 0,    BSH_PRO: 0,    BSH_STATUS: 'NOTREGISTERED', CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'Infra Connecting Corridor Overhead Bridge', },
  { CWA_CODE: '166800',    SYS_OWNER: 'Tank Farm System Owner',        PLAN: '2024-04-30', ACTUAL: null,  A_SYS_QTY: 19,  ASH_TOT: 218,   ASH_ACT: 139,  ASH_REM: 79,    ASH_PRO: 63.8, ASH_STATUS: 'INPROGRESS', AP_TOT: 11,  AP_ACT: 1,   AP_REM: 10,  AP_PRO: 9.1,  AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 89,   BSH_ACT: 0,    BSH_REM: 89,   BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 6,   CSH_ACT: 0,   CSH_REM: 6,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Infra Ammonia Water Tank Farm', },
  { CWA_CODE: '168000',    SYS_OWNER: 'Tank Farm System Owner',        PLAN: '2024-07-31', ACTUAL: null,  A_SYS_QTY: 36,  ASH_TOT: 1064,  ASH_ACT: 8,    ASH_REM: 1056,  ASH_PRO: 0.8,  ASH_STATUS: 'INPROGRESS', AP_TOT: 8,   AP_ACT: 0,   AP_REM: 8,   AP_PRO: 0,    AP_STATUS: 'NOTSTARTED',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 280,  BSH_ACT: 4,    BSH_REM: 276,  BSH_PRO: 1.4,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 4,   CSH_ACT: 0,   CSH_REM: 4,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Infra Naphtha Tank Farm', },
  { CWA_CODE: '170000',    SYS_OWNER: 'Tank Farm System Owner',        PLAN: '2024-06-19', ACTUAL: null,  A_SYS_QTY: 6,   ASH_TOT: 175,   ASH_ACT: 10,   ASH_REM: 165,   ASH_PRO: 5.7,  ASH_STATUS: 'INPROGRESS', AP_TOT: 8,   AP_ACT: 0,   AP_REM: 8,   AP_PRO: 0,    AP_STATUS: 'NOTSTARTED',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 94,   BSH_ACT: 0,    BSH_REM: 94,   BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'Infra Pressurized Tank Farm Common Area', },
  { CWA_CODE: '170091',    SYS_OWNER: 'P1 Building System Owner',      PLAN: '2024-01-29', ACTUAL: '2024-01-29', A_SYS_QTY: 25,  ASH_TOT: 1037,  ASH_ACT: 987,  ASH_REM: 50,    ASH_PRO: 95.2, ASH_STATUS: 'TURNOVER',   AP_TOT: 88,  AP_ACT: 84,  AP_REM: 4,   AP_PRO: 95.5, AP_STATUS: 'TURNOVER',      BP_TOT: 57,   BP_ACT: 39,   BP_REM: 18,  BP_PRO: 68.4, BP_STATUS: 'TURNOVER',      BSH_TOT: 348,  BSH_ACT: 299,  BSH_REM: 49,   BSH_PRO: 85.9, BSH_STATUS: 'TURNOVER',      CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 1,   CSH_ACT: 0,   CSH_REM: 1,   CSH_PRO: 0, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Infra RIE 7 RIE 7', },
  { CWA_CODE: '170092',    SYS_OWNER: 'P1 Building System Owner',      PLAN: '2023-12-30', ACTUAL: '2023-12-30', A_SYS_QTY: 33,  ASH_TOT: 838,   ASH_ACT: 817,  ASH_REM: 21,    ASH_PRO: 97.5, ASH_STATUS: 'TURNOVER',   AP_TOT: 91,  AP_ACT: 91,  AP_REM: 0,   AP_PRO: 100,  AP_STATUS: 'TURNOVER',      BP_TOT: 98,   BP_ACT: 84,   BP_REM: 14,  BP_PRO: 85.7, BP_STATUS: 'TURNOVER',      BSH_TOT: 900,  BSH_ACT: 844,  BSH_REM: 56,   BSH_PRO: 93.8, BSH_STATUS: 'TURNOVER',      CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 5,   CSH_ACT: 5,   CSH_REM: 0,   CSH_PRO: 100, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Infra Tank Farm 1st Substation', },
  { CWA_CODE: '170100',    SYS_OWNER: 'Tank Farm System Owner',        PLAN: '2024-06-27', ACTUAL: null,  A_SYS_QTY: 44,  ASH_TOT: 2915,  ASH_ACT: 143,  ASH_REM: 2772,  ASH_PRO: 4.9,  ASH_STATUS: 'INPROGRESS', AP_TOT: 39,  AP_ACT: 7,   AP_REM: 32,  AP_PRO: 17.9, AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 771,  BSH_ACT: 13,   BSH_REM: 758,  BSH_PRO: 1.7,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 14,  CSH_ACT: 0,   CSH_REM: 14,  CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Infra Ethylene Cryogenic Tank Farm', },
  { CWA_CODE: '170300',    SYS_OWNER: 'Tank Farm System Owner',        PLAN: '2024-05-30', ACTUAL: null,  A_SYS_QTY: 37,  ASH_TOT: 1679,  ASH_ACT: 189,  ASH_REM: 1490,  ASH_PRO: 11.3, ASH_STATUS: 'INPROGRESS', AP_TOT: 23,  AP_ACT: 3,   AP_REM: 20,  AP_PRO: 13,   AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 573,  BSH_ACT: 12,   BSH_REM: 561,  BSH_PRO: 2.1,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 24,  CSH_ACT: 0,   CSH_REM: 24,  CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Infra Pressurized Tank Farm 1 1', },
  { CWA_CODE: '170800',    SYS_OWNER: 'Tank Farm System Owner',        PLAN: '2024-06-30', ACTUAL: null,  A_SYS_QTY: 45,  ASH_TOT: 1587,  ASH_ACT: 111,  ASH_REM: 1476,  ASH_PRO: 7,    ASH_STATUS: 'INPROGRESS', AP_TOT: 17,  AP_ACT: 5,   AP_REM: 12,  AP_PRO: 29.4, AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 490,  BSH_ACT: 6,    BSH_REM: 484,  BSH_PRO: 1.2,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 19,  CSH_ACT: 0,   CSH_REM: 19,  CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Infra Mogas Tank Farm', },
  { CWA_CODE: '177100',    SYS_OWNER: 'Tank Farm System Owner',        PLAN: '2024-06-19', ACTUAL: null,  A_SYS_QTY: 45,  ASH_TOT: 1643,  ASH_ACT: 80,   ASH_REM: 1563,  ASH_PRO: 4.9,  ASH_STATUS: 'INPROGRESS', AP_TOT: 10,  AP_ACT: 4,   AP_REM: 6,   AP_PRO: 40,   AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 527,  BSH_ACT: 3,    BSH_REM: 524,  BSH_PRO: 0.6,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 12,  CSH_ACT: 0,   CSH_REM: 12,  CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Infra Intermediate Tank Farm 1 1', },
  { CWA_CODE: '178000',    SYS_OWNER: 'P3 Building System Owner',      PLAN: '2024-06-30', ACTUAL: null,  A_SYS_QTY: 23,  ASH_TOT: 229,   ASH_ACT: 44,   ASH_REM: 185,   ASH_PRO: 19.2, ASH_STATUS: 'INPROGRESS', AP_TOT: 10,  AP_ACT: 4,   AP_REM: 6,   AP_PRO: 40,   AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 97,   BSH_ACT: 0,    BSH_REM: 97,   BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'Infra LoadingUnloading Station', },
  { CWA_CODE: '178001',    SYS_OWNER: 'P3 Building System Owner',      PLAN: '2024-06-30', ACTUAL: null,  A_SYS_QTY: 18,  ASH_TOT: 309,   ASH_ACT: 25,   ASH_REM: 284,   ASH_PRO: 8.1,  ASH_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 0,   AP_REM: 1,   AP_PRO: 0,    AP_STATUS: 'NOTSTARTED',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 107,  BSH_ACT: 0,    BSH_REM: 107,  BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 1,   CSH_ACT: 0,   CSH_REM: 1,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Infra LoadingUnloading Station Building', },
  { CWA_CODE: '178600',    SYS_OWNER: 'Flare System Owner',            PLAN: '2024-05-15', ACTUAL: null,  A_SYS_QTY: 20,  ASH_TOT: 595,   ASH_ACT: 561,  ASH_REM: 34,    ASH_PRO: 94.3, ASH_STATUS: 'INPROGRESS', AP_TOT: 128, AP_ACT: 15,  AP_REM: 113, AP_PRO: 11.7, AP_STATUS: 'INPROGRESS',    BP_TOT: 149,  BP_ACT: 13,   BP_REM: 136, BP_PRO: 8.7,  BP_STATUS: 'INPROGRESS',    BSH_TOT: 240,  BSH_ACT: 5,    BSH_REM: 235,  BSH_PRO: 2.1,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 3,  CP_ACT: 0,  CP_REM: 3, CP_PRO: 0,    CP_STATUS: 'NOTSTARTED',    CSH_TOT: 10,  CSH_ACT: 0,   CSH_REM: 10,  CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Infra Elevated Flare', },
  { CWA_CODE: '178900',    SYS_OWNER: 'Flare System Owner',            PLAN: '2024-05-30', ACTUAL: null,  A_SYS_QTY: 66,  ASH_TOT: 2526,  ASH_ACT: 398,  ASH_REM: 2128,  ASH_PRO: 15.8, ASH_STATUS: 'INPROGRESS', AP_TOT: 19,  AP_ACT: 0,   AP_REM: 19,  AP_PRO: 0,    AP_STATUS: 'NOTSTARTED',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 1166, BSH_ACT: 1,    BSH_REM: 1165, BSH_PRO: 0.1,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 42,  CSH_ACT: 0,   CSH_REM: 42,  CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Infra Ground Flare', },
  { CWA_CODE: '178903',    SYS_OWNER: 'Flare System Owner',            PLAN: '2024-05-30', ACTUAL: null,  A_SYS_QTY: 24,  ASH_TOT: 1336,  ASH_ACT: 118,  ASH_REM: 1218,  ASH_PRO: 8.8,  ASH_STATUS: 'INPROGRESS', AP_TOT: 3,   AP_ACT: 0,   AP_REM: 3,   AP_PRO: 0,    AP_STATUS: 'NOTSTARTED',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 471,  BSH_ACT: 0,    BSH_REM: 471,  BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 7,   CSH_ACT: 0,   CSH_REM: 7,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Infra Flare Gas Recovery Unit', },
  { CWA_CODE: '178992',    SYS_OWNER: 'P3 Building System Owner',      PLAN: '2024-03-18', ACTUAL: '2024-03-18', A_SYS_QTY: 29,  ASH_TOT: 444,   ASH_ACT: 429,  ASH_REM: 15,    ASH_PRO: 96.6, ASH_STATUS: 'TURNOVER',   AP_TOT: 66,  AP_ACT: 65,  AP_REM: 1,   AP_PRO: 98.5, AP_STATUS: 'TURNOVER',      BP_TOT: 54,   BP_ACT: 47,   BP_REM: 7,   BP_PRO: 87,   BP_STATUS: 'TURNOVER',      BSH_TOT: 581,  BSH_ACT: 534,  BSH_REM: 47,   BSH_PRO: 91.9, BSH_STATUS: 'TURNOVER',      CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 5,   CSH_ACT: 5,   CSH_REM: 0,   CSH_PRO: 100, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Infra Ground Flare substation', },
  { CWA_CODE: '179000',    SYS_OWNER: 'Utility System Owner',          PLAN: '2024-05-30', ACTUAL: null,  A_SYS_QTY: 17,  ASH_TOT: 335,   ASH_ACT: 244,  ASH_REM: 91,    ASH_PRO: 72.8, ASH_STATUS: 'INPROGRESS', AP_TOT: 71,  AP_ACT: 11,  AP_REM: 60,  AP_PRO: 15.5, AP_STATUS: 'INPROGRESS',    BP_TOT: 64,   BP_ACT: 11,   BP_REM: 53,  BP_PRO: 17.2, BP_STATUS: 'INPROGRESS',    BSH_TOT: 38,   BSH_ACT: 0,    BSH_REM: 38,   BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'Overall Level1 Pipe Rack 2 2', },
  { CWA_CODE: '179100',    SYS_OWNER: 'Utility System Owner',          PLAN: '2024-05-30', ACTUAL: null,  A_SYS_QTY: 69,  ASH_TOT: 2473,  ASH_ACT: 690,  ASH_REM: 1783,  ASH_PRO: 27.9, ASH_STATUS: 'INPROGRESS', AP_TOT: 155, AP_ACT: 26,  AP_REM: 129, AP_PRO: 16.8, AP_STATUS: 'INPROGRESS',    BP_TOT: 125,  BP_ACT: 2,    BP_REM: 123, BP_PRO: 1.6,  BP_STATUS: 'INPROGRESS',    BSH_TOT: 235,  BSH_ACT: 2,    BSH_REM: 233,  BSH_PRO: 0.9,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'Overall Level1 Pipe Rack 7 7', },
  { CWA_CODE: '179600',    SYS_OWNER: 'Flare System Owner',            PLAN: '2024-08-09', ACTUAL: null,  A_SYS_QTY: 17,  ASH_TOT: 164,   ASH_ACT: 0,    ASH_REM: 164,   ASH_PRO: 0,    ASH_STATUS: 'NOTSTARTED', AP_TOT: 6,   AP_ACT: 0,   AP_REM: 6,   AP_PRO: 0,    AP_STATUS: 'NOTSTARTED',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 51,   BSH_ACT: 0,    BSH_REM: 51,   BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 5,   CSH_ACT: 0,   CSH_REM: 5,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Infra Vapor Gas Recovery Unit', },
  { CWA_CODE: '180200',    SYS_OWNER: 'Cooling Water System Owner',    PLAN: '2024-04-15', ACTUAL: '2024-04-29', A_SYS_QTY: 29,  ASH_TOT: 1882,  ASH_ACT: 1873, ASH_REM: 9,     ASH_PRO: 99.5, ASH_STATUS: 'TURNOVER',   AP_TOT: 306, AP_ACT: 304, AP_REM: 2,   AP_PRO: 99.3, AP_STATUS: 'TURNOVER',      BP_TOT: 356,  BP_ACT: 179,  BP_REM: 177, BP_PRO: 50.3, BP_STATUS: 'TURNOVER',      BSH_TOT: 1027, BSH_ACT: 273,  BSH_REM: 754,  BSH_PRO: 26.6, BSH_STATUS: 'TURNOVER',      CP_TOT: 3,  CP_ACT: 0,  CP_REM: 3, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 38,  CSH_ACT: 3,   CSH_REM: 35,  CSH_PRO: 7.9, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Infra Cooling Water System 1', },
  { CWA_CODE: '180292',    SYS_OWNER: 'P3 Building System Owner',      PLAN: '2024-01-17', ACTUAL: '2024-01-17', A_SYS_QTY: 32,  ASH_TOT: 876,   ASH_ACT: 873,  ASH_REM: 3,     ASH_PRO: 99.7, ASH_STATUS: 'TURNOVER',   AP_TOT: 89,  AP_ACT: 89,  AP_REM: 0,   AP_PRO: 100,  AP_STATUS: 'TURNOVER',      BP_TOT: 97,   BP_ACT: 76,   BP_REM: 21,  BP_PRO: 78.4, BP_STATUS: 'TURNOVER',      BSH_TOT: 879,  BSH_ACT: 865,  BSH_REM: 14,   BSH_PRO: 98.4, BSH_STATUS: 'TURNOVER',      CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 7,   CSH_ACT: 7,   CSH_REM: 0,   CSH_PRO: 100, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Infra Cooling Water System1 Substation', },
  { CWA_CODE: '180401',    SYS_OWNER: 'Water System Owner',            PLAN: '2024-01-15', ACTUAL: '2024-01-15', A_SYS_QTY: 6,   ASH_TOT: 60,    ASH_ACT: 51,   ASH_REM: 9,     ASH_PRO: 85,   ASH_STATUS: 'TURNOVER',   AP_TOT: 22,  AP_ACT: 19,  AP_REM: 3,   AP_PRO: 86.4, AP_STATUS: 'TURNOVER',      BP_TOT: 18,   BP_ACT: 15,   BP_REM: 3,   BP_PRO: 83.3, BP_STATUS: 'TURNOVER',      BSH_TOT: 16,   BSH_ACT: 14,   BSH_REM: 2,    BSH_PRO: 87.5, BSH_STATUS: 'TURNOVER',      CP_TOT: 1,  CP_ACT: 0,  CP_REM: 1, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 2,   CSH_ACT: 2,   CSH_REM: 0,   CSH_PRO: 100, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Infra Waste Water Pump Station 1 B Area 1', },
  { CWA_CODE: '180402',    SYS_OWNER: 'Water System Owner',            PLAN: '2024-06-10', ACTUAL: null,  A_SYS_QTY: 8,   ASH_TOT: 71,    ASH_ACT: 7,    ASH_REM: 64,    ASH_PRO: 9.9,  ASH_STATUS: 'INPROGRESS', AP_TOT: 2,   AP_ACT: 0,   AP_REM: 2,   AP_PRO: 0,    AP_STATUS: 'NOTSTARTED',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 27,   BSH_ACT: 0,    BSH_REM: 27,   BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 2,   CSH_ACT: 0,   CSH_REM: 2,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Infra Waste Water Pump Station 2 Hexene1 Tank Farm 2', },
  { CWA_CODE: '180403',    SYS_OWNER: 'Water System Owner',            PLAN: '2024-08-09', ACTUAL: null,  A_SYS_QTY: 9,   ASH_TOT: 137,   ASH_ACT: 32,   ASH_REM: 105,   ASH_PRO: 23.4, ASH_STATUS: 'INPROGRESS', AP_TOT: 4,   AP_ACT: 1,   AP_REM: 3,   AP_PRO: 25,   AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 45,   BSH_ACT: 0,    BSH_REM: 45,   BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 4,   CSH_ACT: 0,   CSH_REM: 4,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Infra Waste Water Pump Station 3 Intermediate Tank Farm 1 3', },
  { CWA_CODE: '180404',    SYS_OWNER: 'Water System Owner',            PLAN: '2024-03-25', ACTUAL: '2024-03-24', A_SYS_QTY: 6,   ASH_TOT: 40,    ASH_ACT: 40,   ASH_REM: 0,     ASH_PRO: 100,  ASH_STATUS: 'TURNOVER',   AP_TOT: 16,  AP_ACT: 16,  AP_REM: 0,   AP_PRO: 100,  AP_STATUS: 'TURNOVER',      BP_TOT: 27,   BP_ACT: 2,    BP_REM: 25,  BP_PRO: 7.4,  BP_STATUS: 'TURNOVER',      BSH_TOT: 21,   BSH_ACT: 10,   BSH_REM: 11,   BSH_PRO: 47.6, BSH_STATUS: 'TURNOVER',      CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 2,   CSH_ACT: 2,   CSH_REM: 0,   CSH_PRO: 100, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Infra Waste Water Pump Station 4 Chemical Warehouse 4', },
  { CWA_CODE: '180600',    SYS_OWNER: 'P3 Building System Owner',      PLAN: '2024-03-29', ACTUAL: '2024-03-29', A_SYS_QTY: 16,  ASH_TOT: 517,   ASH_ACT: 505,  ASH_REM: 12,    ASH_PRO: 97.7, ASH_STATUS: 'TURNOVER',   AP_TOT: 121, AP_ACT: 119, AP_REM: 2,   AP_PRO: 98.3, AP_STATUS: 'TURNOVER',      BP_TOT: 82,   BP_ACT: 45,   BP_REM: 37,  BP_PRO: 54.9, BP_STATUS: 'TURNOVER',      BSH_TOT: 228,  BSH_ACT: 186,  BSH_REM: 42,   BSH_PRO: 81.6, BSH_STATUS: 'TURNOVER',      CP_TOT: 2,  CP_ACT: 0,  CP_REM: 2, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 25,  CSH_ACT: 22,  CSH_REM: 3,   CSH_PRO: 88, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Infra Storm Water Pump Station and Accident Buffer Basin', },
  { CWA_CODE: '181000',    SYS_OWNER: 'Water System Owner',            PLAN: '2024-01-24', ACTUAL: '2024-01-24', A_SYS_QTY: 17,  ASH_TOT: 508,   ASH_ACT: 507,  ASH_REM: 1,     ASH_PRO: 99.8, ASH_STATUS: 'TURNOVER',   AP_TOT: 189, AP_ACT: 188, AP_REM: 1,   AP_PRO: 99.5, AP_STATUS: 'TURNOVER',      BP_TOT: 197,  BP_ACT: 156,  BP_REM: 41,  BP_PRO: 79.2, BP_STATUS: 'TURNOVER',      BSH_TOT: 228,  BSH_ACT: 213,  BSH_REM: 15,   BSH_PRO: 93.4, BSH_STATUS: 'TURNOVER',      CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 23,  CSH_ACT: 23,  CSH_REM: 0,   CSH_PRO: 100, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Infra Water and Firewater Pump Station 1', },
  { CWA_CODE: '181001',    SYS_OWNER: 'Telecom Overall System Owner',  PLAN: '2024-01-15', ACTUAL: '2024-01-15', A_SYS_QTY: 7,   ASH_TOT: 56,    ASH_ACT: 56,   ASH_REM: 0,     ASH_PRO: 100,  ASH_STATUS: 'TURNOVER',   AP_TOT: 10,  AP_ACT: 10,  AP_REM: 0,   AP_PRO: 100,  AP_STATUS: 'TURNOVER',      BP_TOT: 16,   BP_ACT: 10,   BP_REM: 6,   BP_PRO: 62.5, BP_STATUS: 'TURNOVER',      BSH_TOT: 15,   BSH_ACT: 15,   BSH_REM: 0,    BSH_PRO: 100,  BSH_STATUS: 'TURNOVER',      CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 1,   CSH_ACT: 1,   CSH_REM: 0,   CSH_PRO: 100, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Infra Telecom equipment room Water and Firewater Pump Station1 area', },
  { CWA_CODE: '181200',    SYS_OWNER: 'P3 Building System Owner',      PLAN: '2023-12-13', ACTUAL: '2023-12-13', A_SYS_QTY: 31,  ASH_TOT: 1218,  ASH_ACT: 1198, ASH_REM: 20,    ASH_PRO: 98.4, ASH_STATUS: 'TURNOVER',   AP_TOT: 120, AP_ACT: 117, AP_REM: 3,   AP_PRO: 97.5, AP_STATUS: 'TURNOVER',      BP_TOT: 219,  BP_ACT: 197,  BP_REM: 22,  BP_PRO: 90,   BP_STATUS: 'TURNOVER',      BSH_TOT: 754,  BSH_ACT: 741,  BSH_REM: 13,   BSH_PRO: 98.3, BSH_STATUS: 'TURNOVER',      CP_TOT: 15, CP_ACT: 13, CP_REM: 2, CP_PRO: 86.7, CP_STATUS: 'TURNOVER',      CSH_TOT: 9,   CSH_ACT: 7,   CSH_REM: 2,   CSH_PRO: 77.8, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Infra FF Gas Protection Station', },
  { CWA_CODE: '181301',    SYS_OWNER: 'Tank Farm System Owner',        PLAN: '2024-04-30', ACTUAL: '2024-04-30', A_SYS_QTY: 5,   ASH_TOT: 26,    ASH_ACT: 26,   ASH_REM: 0,     ASH_PRO: 100,  ASH_STATUS: 'TURNOVER',   AP_TOT: 15,  AP_ACT: 15,  AP_REM: 0,   AP_PRO: 100,  AP_STATUS: 'TURNOVER',      BP_TOT: 29,   BP_ACT: 9,    BP_REM: 20,  BP_PRO: 31,   BP_STATUS: 'TURNOVER',      BSH_TOT: 8,    BSH_ACT: 0,    BSH_REM: 8,    BSH_PRO: 0,    BSH_STATUS: 'TURNOVER',      CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Infra Foam Station 1 Ethylene Cryogenic Tank 1', },
  { CWA_CODE: '181302',    SYS_OWNER: 'Tank Farm System Owner',        PLAN: '2024-08-09', ACTUAL: null,  A_SYS_QTY: 8,   ASH_TOT: 50,    ASH_ACT: 7,    ASH_REM: 43,    ASH_PRO: 14,   ASH_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 35,   BSH_ACT: 0,    BSH_REM: 35,   BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 1,   CSH_ACT: 0,   CSH_REM: 1,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Infra Foam Station 2 Intermediate Tank Farm 1 2', },
  { CWA_CODE: '181303',    SYS_OWNER: 'Tank Farm System Owner',        PLAN: '2024-06-19', ACTUAL: null,  A_SYS_QTY: 7,   ASH_TOT: 50,    ASH_ACT: 3,    ASH_REM: 47,    ASH_PRO: 6,    ASH_STATUS: 'INPROGRESS', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 35,   BSH_ACT: 0,    BSH_REM: 35,   BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 1,   CSH_ACT: 0,   CSH_REM: 1,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Infra Foam Station 3 Naphtha Tank Farm 3', },
  { CWA_CODE: '181304',    SYS_OWNER: 'Tank Farm System Owner',        PLAN: '2024-08-09', ACTUAL: null,  A_SYS_QTY: 8,   ASH_TOT: 40,    ASH_ACT: 0,    ASH_REM: 40,    ASH_PRO: 0,    ASH_STATUS: 'NOTSTARTED', AP_TOT: 1,   AP_ACT: 0,   AP_REM: 1,   AP_PRO: 0,    AP_STATUS: 'NOTSTARTED',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 23,   BSH_ACT: 0,    BSH_REM: 23,   BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'Infra Foam Station 4 Naphtha Tank Farm 4', },
  { CWA_CODE: '182200',    SYS_OWNER: 'P3 Building System Owner',      PLAN: '2024-01-22', ACTUAL: '2024-01-22', A_SYS_QTY: 15,  ASH_TOT: 200,   ASH_ACT: 197,  ASH_REM: 3,     ASH_PRO: 98.5, ASH_STATUS: 'TURNOVER',   AP_TOT: 52,  AP_ACT: 52,  AP_REM: 0,   AP_PRO: 100,  AP_STATUS: 'TURNOVER',      BP_TOT: 15,   BP_ACT: 0,    BP_REM: 15,  BP_PRO: 0,    BP_STATUS: 'TURNOVER',      BSH_TOT: 67,   BSH_ACT: 48,   BSH_REM: 19,   BSH_PRO: 71.6, BSH_STATUS: 'TURNOVER',      CP_TOT: 2,  CP_ACT: 0,  CP_REM: 2, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Infra Hazardous waste temporary warehouse', },
  { CWA_CODE: '182400',    SYS_OWNER: 'Water System Owner',            PLAN: '2024-04-12', ACTUAL: '2024-04-12', A_SYS_QTY: 24,  ASH_TOT: 763,   ASH_ACT: 761,  ASH_REM: 2,     ASH_PRO: 99.7, ASH_STATUS: 'TURNOVER',   AP_TOT: 856, AP_ACT: 853, AP_REM: 3,   AP_PRO: 99.6, AP_STATUS: 'TURNOVER',      BP_TOT: 210,  BP_ACT: 136,  BP_REM: 74,  BP_PRO: 64.8, BP_STATUS: 'TURNOVER',      BSH_TOT: 296,  BSH_ACT: 204,  BSH_REM: 92,   BSH_PRO: 68.9, BSH_STATUS: 'TURNOVER',      CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 23,  CSH_ACT: 17,  CSH_REM: 6,   CSH_PRO: 73.9, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Infra Wastewater PreTreatment Unit', },
  { CWA_CODE: '182492',    SYS_OWNER: 'P3 Building System Owner',      PLAN: '2024-01-19', ACTUAL: '2024-01-19', A_SYS_QTY: 28,  ASH_TOT: 503,   ASH_ACT: 491,  ASH_REM: 12,    ASH_PRO: 97.6, ASH_STATUS: 'TURNOVER',   AP_TOT: 86,  AP_ACT: 78,  AP_REM: 8,   AP_PRO: 90.7, AP_STATUS: 'TURNOVER',      BP_TOT: 93,   BP_ACT: 66,   BP_REM: 27,  BP_PRO: 71,   BP_STATUS: 'TURNOVER',      BSH_TOT: 606,  BSH_ACT: 571,  BSH_REM: 35,   BSH_PRO: 94.2, BSH_STATUS: 'TURNOVER',      CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 5,   CSH_ACT: 5,   CSH_REM: 0,   CSH_PRO: 100, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Infra Wastewater PreTreatment Unit Substation', },
  { CWA_CODE: '183000',    SYS_OWNER: 'P3 Building System Owner',      PLAN: '2023-09-05', ACTUAL: '2023-09-05', A_SYS_QTY: 55,  ASH_TOT: 4740,  ASH_ACT: 4736, ASH_REM: 4,     ASH_PRO: 99.9, ASH_STATUS: 'TURNOVER',   AP_TOT: 328, AP_ACT: 328, AP_REM: 0,   AP_PRO: 100,  AP_STATUS: 'TURNOVER',      BP_TOT: 2416, BP_ACT: 2410, BP_REM: 6,   BP_PRO: 99.8, BP_STATUS: 'TURNOVER',      BSH_TOT: 2175, BSH_ACT: 2156, BSH_REM: 19,   BSH_PRO: 99.1, BSH_STATUS: 'TURNOVER',      CP_TOT: 17, CP_ACT: 10, CP_REM: 7, CP_PRO: 58.8, CP_STATUS: 'TURNOVER',      CSH_TOT: 101, CSH_ACT: 101, CSH_REM: 0,   CSH_PRO: 100, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Infra Main Substation', },
  { CWA_CODE: '183100',    SYS_OWNER: 'P3 Building System Owner',      PLAN: '2023-12-15', ACTUAL: '2023-12-15', A_SYS_QTY: 29,  ASH_TOT: 629,   ASH_ACT: 599,  ASH_REM: 30,    ASH_PRO: 95.2, ASH_STATUS: 'TURNOVER',   AP_TOT: 74,  AP_ACT: 71,  AP_REM: 3,   AP_PRO: 95.9, AP_STATUS: 'TURNOVER',      BP_TOT: 77,   BP_ACT: 71,   BP_REM: 6,   BP_PRO: 92.2, BP_STATUS: 'TURNOVER',      BSH_TOT: 318,  BSH_ACT: 308,  BSH_REM: 10,   BSH_PRO: 96.9, BSH_STATUS: 'TURNOVER',      CP_TOT: 3,  CP_ACT: 2,  CP_REM: 1, CP_PRO: 66.7, CP_STATUS: 'TURNOVER',      CSH_TOT: 6,   CSH_ACT: 6,   CSH_REM: 0,   CSH_PRO: 100, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Infra Area B Substation B', },
  { CWA_CODE: '184300',    SYS_OWNER: 'Boiler & Furnace System Owner', PLAN: '2024-03-06', ACTUAL: '2024-03-06', A_SYS_QTY: 22,  ASH_TOT: 362,   ASH_ACT: 345,  ASH_REM: 17,    ASH_PRO: 95.3, ASH_STATUS: 'TURNOVER',   AP_TOT: 381, AP_ACT: 362, AP_REM: 19,  AP_PRO: 95,   AP_STATUS: 'TURNOVER',      BP_TOT: 214,  BP_ACT: 162,  BP_REM: 52,  BP_PRO: 75.7, BP_STATUS: 'TURNOVER',      BSH_TOT: 66,   BSH_ACT: 48,   BSH_REM: 18,   BSH_PRO: 72.7, BSH_STATUS: 'TURNOVER',      CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Demineralized Water Unit Battery Limit', },
  { CWA_CODE: '184301',    SYS_OWNER: 'Boiler & Furnace System Owner', PLAN: '2024-03-06', ACTUAL: '2024-03-06', A_SYS_QTY: 42,  ASH_TOT: 2333,  ASH_ACT: 2326, ASH_REM: 7,     ASH_PRO: 99.7, ASH_STATUS: 'TURNOVER',   AP_TOT: 353, AP_ACT: 352, AP_REM: 1,   AP_PRO: 99.7, AP_STATUS: 'TURNOVER',      BP_TOT: 122,  BP_ACT: 68,   BP_REM: 54,  BP_PRO: 55.7, BP_STATUS: 'TURNOVER',      BSH_TOT: 1003, BSH_ACT: 872,  BSH_REM: 131,  BSH_PRO: 86.9, BSH_STATUS: 'TURNOVER',      CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 44,  CSH_ACT: 41,  CSH_REM: 3,   CSH_PRO: 93.2, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Demineralized Water Unit Chemical Water Treatment', },
  { CWA_CODE: '184302',    SYS_OWNER: 'Boiler & Furnace System Owner', PLAN: '2024-03-06', ACTUAL: '2024-03-06', A_SYS_QTY: 24,  ASH_TOT: 1116,  ASH_ACT: 1114, ASH_REM: 2,     ASH_PRO: 99.8, ASH_STATUS: 'TURNOVER',   AP_TOT: 194, AP_ACT: 194, AP_REM: 0,   AP_PRO: 100,  AP_STATUS: 'TURNOVER',      BP_TOT: 84,   BP_ACT: 34,   BP_REM: 50,  BP_PRO: 40.5, BP_STATUS: 'TURNOVER',      BSH_TOT: 418,  BSH_ACT: 394,  BSH_REM: 24,   BSH_PRO: 94.3, BSH_STATUS: 'TURNOVER',      CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 14,  CSH_ACT: 14,  CSH_REM: 0,   CSH_PRO: 100, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Demineralized Water Unit Condensate Water Treatment', },
  { CWA_CODE: '184303',    SYS_OWNER: 'Boiler & Furnace System Owner', PLAN: '2024-04-13', ACTUAL: '2024-04-18', A_SYS_QTY: 5,   ASH_TOT: 164,   ASH_ACT: 162,  ASH_REM: 2,     ASH_PRO: 98.8, ASH_STATUS: 'TURNOVER',   AP_TOT: 47,  AP_ACT: 44,  AP_REM: 3,   AP_PRO: 93.6, AP_STATUS: 'TURNOVER',      BP_TOT: 65,   BP_ACT: 20,   BP_REM: 45,  BP_PRO: 30.8, BP_STATUS: 'TURNOVER',      BSH_TOT: 70,   BSH_ACT: 31,   BSH_REM: 39,   BSH_PRO: 44.3, BSH_STATUS: 'TURNOVER',      CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Demineralized Water Unit Acid Caustic Station', },
  { CWA_CODE: '184800',    SYS_OWNER: 'Boiler & Furnace System Owner', PLAN: '2024-04-25', ACTUAL: null,  A_SYS_QTY: 113, ASH_TOT: 4445,  ASH_ACT: 3687, ASH_REM: 758,   ASH_PRO: 82.9, ASH_STATUS: 'INPROGRESS', AP_TOT: 662, AP_ACT: 433, AP_REM: 229, AP_PRO: 65.4, AP_STATUS: 'INPROGRESS',    BP_TOT: 237,  BP_ACT: 124,  BP_REM: 113, BP_PRO: 52.3, BP_STATUS: 'INPROGRESS',    BSH_TOT: 2618, BSH_ACT: 2,    BSH_REM: 2616, BSH_PRO: 0.1,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 46,  CSH_ACT: 0,   CSH_REM: 46,  CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Boiler Unit Boiler Battery Limit', },
  { CWA_CODE: '184891',    SYS_OWNER: 'P1 Building System Owner',      PLAN: '2023-10-30', ACTUAL: '2023-10-30', A_SYS_QTY: 25,  ASH_TOT: 899,   ASH_ACT: 807,  ASH_REM: 92,    ASH_PRO: 89.8, ASH_STATUS: 'TURNOVER',   AP_TOT: 123, AP_ACT: 121, AP_REM: 2,   AP_PRO: 98.4, AP_STATUS: 'TURNOVER',      BP_TOT: 120,  BP_ACT: 63,   BP_REM: 57,  BP_PRO: 52.5, BP_STATUS: 'TURNOVER',      BSH_TOT: 380,  BSH_ACT: 295,  BSH_REM: 85,   BSH_PRO: 77.6, BSH_STATUS: 'TURNOVER',      CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 1,   CSH_ACT: 0,   CSH_REM: 1,   CSH_PRO: 0, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Boiler Unit Boiler RIE6 RIE6', },
  { CWA_CODE: '184892',    SYS_OWNER: 'P1 Building System Owner',      PLAN: '2023-10-30', ACTUAL: '2023-10-30', A_SYS_QTY: 28,  ASH_TOT: 706,   ASH_ACT: 616,  ASH_REM: 90,    ASH_PRO: 87.3, ASH_STATUS: 'TURNOVER',   AP_TOT: 80,  AP_ACT: 80,  AP_REM: 0,   AP_PRO: 100,  AP_STATUS: 'TURNOVER',      BP_TOT: 73,   BP_ACT: 60,   BP_REM: 13,  BP_PRO: 82.2, BP_STATUS: 'TURNOVER',      BSH_TOT: 610,  BSH_ACT: 565,  BSH_REM: 45,   BSH_PRO: 92.6, BSH_STATUS: 'TURNOVER',      CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 5,   CSH_ACT: 5,   CSH_REM: 0,   CSH_PRO: 100, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Boiler Unit Boiler Unit Substation', },
  { CWA_CODE: '185300',    SYS_OWNER: 'Utility System Owner',          PLAN: '2024-03-07', ACTUAL: '2024-03-06', A_SYS_QTY: 27,  ASH_TOT: 774,   ASH_ACT: 765,  ASH_REM: 9,     ASH_PRO: 98.8, ASH_STATUS: 'TURNOVER',   AP_TOT: 389, AP_ACT: 343, AP_REM: 46,  AP_PRO: 88.2, AP_STATUS: 'TURNOVER',      BP_TOT: 311,  BP_ACT: 150,  BP_REM: 161, BP_PRO: 48.2, BP_STATUS: 'TURNOVER',      BSH_TOT: 471,  BSH_ACT: 384,  BSH_REM: 87,   BSH_PRO: 81.5, BSH_STATUS: 'TURNOVER',      CP_TOT: 1,  CP_ACT: 0,  CP_REM: 1, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 18,  CSH_ACT: 7,   CSH_REM: 11,  CSH_PRO: 38.9, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Infra Air Compression Station', },
  { CWA_CODE: '185392',    SYS_OWNER: 'P3 Building System Owner',      PLAN: '2024-01-03', ACTUAL: '2024-01-03', A_SYS_QTY: 31,  ASH_TOT: 528,   ASH_ACT: 527,  ASH_REM: 1,     ASH_PRO: 99.8, ASH_STATUS: 'TURNOVER',   AP_TOT: 83,  AP_ACT: 81,  AP_REM: 2,   AP_PRO: 97.6, AP_STATUS: 'TURNOVER',      BP_TOT: 81,   BP_ACT: 57,   BP_REM: 24,  BP_PRO: 70.4, BP_STATUS: 'TURNOVER',      BSH_TOT: 526,  BSH_ACT: 512,  BSH_REM: 14,   BSH_PRO: 97.3, BSH_STATUS: 'TURNOVER',      CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 5,   CSH_ACT: 5,   CSH_REM: 0,   CSH_PRO: 100, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Infra Air Compression Station Substation', },
  { CWA_CODE: '185394',    SYS_OWNER: 'P3 Building System Owner',      PLAN: '2024-01-30', ACTUAL: '2024-01-30', A_SYS_QTY: 18,  ASH_TOT: 533,   ASH_ACT: 523,  ASH_REM: 10,    ASH_PRO: 98.1, ASH_STATUS: 'TURNOVER',   AP_TOT: 70,  AP_ACT: 65,  AP_REM: 5,   AP_PRO: 92.9, AP_STATUS: 'TURNOVER',      BP_TOT: 72,   BP_ACT: 40,   BP_REM: 32,  BP_PRO: 55.6, BP_STATUS: 'TURNOVER',      BSH_TOT: 188,  BSH_ACT: 178,  BSH_REM: 10,   BSH_PRO: 94.7, BSH_STATUS: 'TURNOVER',      CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 1,   CSH_ACT: 0,   CSH_REM: 1,   CSH_PRO: 0, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Infra Air Compression Station Operator Shelter', },
  { CWA_CODE: '186000',    SYS_OWNER: 'P2 Building System Owner',      PLAN: '2023-12-27', ACTUAL: '2023-12-27', A_SYS_QTY: 45,  ASH_TOT: 6292,  ASH_ACT: 5944, ASH_REM: 348,   ASH_PRO: 94.5, ASH_STATUS: 'TURNOVER',   AP_TOT: 745, AP_ACT: 661, AP_REM: 84,  AP_PRO: 88.7, AP_STATUS: 'TURNOVER',      BP_TOT: 395,  BP_ACT: 301,  BP_REM: 94,  BP_PRO: 76.2, BP_STATUS: 'TURNOVER',      BSH_TOT: 2610, BSH_ACT: 2270, BSH_REM: 340,  BSH_PRO: 87,   BSH_STATUS: 'TURNOVER',      CP_TOT: 30, CP_ACT: 30, CP_REM: 0, CP_PRO: 100,  CP_STATUS: 'TURNOVER',      CSH_TOT: 6,   CSH_ACT: 4,   CSH_REM: 2,   CSH_PRO: 66.7, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Infra Control Center Building', },
  { CWA_CODE: '187501',    SYS_OWNER: 'Telecom Overall System Owner',  PLAN: '2023-12-25', ACTUAL: '2023-12-25', A_SYS_QTY: 14,  ASH_TOT: 130,   ASH_ACT: 122,  ASH_REM: 8,     ASH_PRO: 93.8, ASH_STATUS: 'TURNOVER',   AP_TOT: 28,  AP_ACT: 26,  AP_REM: 2,   AP_PRO: 92.9, AP_STATUS: 'TURNOVER',      BP_TOT: 12,   BP_ACT: 3,    BP_REM: 9,   BP_PRO: 25,   BP_STATUS: 'TURNOVER',      BSH_TOT: 37,   BSH_ACT: 30,   BSH_REM: 7,    BSH_PRO: 81.1, BSH_STATUS: 'TURNOVER',      CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 1,   CSH_ACT: 0,   CSH_REM: 1,   CSH_PRO: 0, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Radio Antenna tower incl Telecom equipment room', },
  { CWA_CODE: '190400',    SYS_OWNER: 'Utility System Owner',          PLAN: '2024-04-25', ACTUAL: null,  A_SYS_QTY: 1,   ASH_TOT: 31,    ASH_ACT: 14,   ASH_REM: 17,    ASH_PRO: 45.2, ASH_STATUS: 'INPROGRESS', AP_TOT: 18,  AP_ACT: 5,   AP_REM: 13,  AP_PRO: 27.8, AP_STATUS: 'INPROGRESS',    BP_TOT: 12,   BP_ACT: 2,    BP_REM: 10,  BP_PRO: 16.7, BP_STATUS: 'INPROGRESS',    BSH_TOT: 3,    BSH_ACT: 0,    BSH_REM: 3,    BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'Infra Eastward Pipeline', },
  { CWA_CODE: '252701',    SYS_OWNER: 'P3 Building System Owner',      PLAN: '2024-05-30', ACTUAL: null,  A_SYS_QTY: 2,   ASH_TOT: 4,     ASH_ACT: 3,    ASH_REM: 1,     ASH_PRO: 75,   ASH_STATUS: 'INPROGRESS', AP_TOT: 7,   AP_ACT: 2,   AP_REM: 5,   AP_PRO: 28.6, AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 2,    BSH_ACT: 0,    BSH_REM: 2,    BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'Infra Truck Scale1 BaggingPPLDPE 1 1', },
  { CWA_CODE: '252702',    SYS_OWNER: 'P3 Building System Owner',      PLAN: '2024-05-30', ACTUAL: null,  A_SYS_QTY: 2,   ASH_TOT: 4,     ASH_ACT: 0,    ASH_REM: 4,     ASH_PRO: 0,    ASH_STATUS: 'NOTSTARTED', AP_TOT: 0,   AP_ACT: 0,   AP_REM: 0,   AP_PRO: 0,    AP_STATUS: 'NOTREGISTERED', BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 0,    BSH_ACT: 0,    BSH_REM: 0,    BSH_PRO: 0,    BSH_STATUS: 'NOTREGISTERED', CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'Infra Truck Scale2 BaggingGPPE 2 2', },
  { CWA_CODE: '252703',    SYS_OWNER: 'P3 Building System Owner',      PLAN: '2024-01-29', ACTUAL: '2024-01-29', A_SYS_QTY: 2,   ASH_TOT: 9,     ASH_ACT: 9,    ASH_REM: 0,     ASH_PRO: 100,  ASH_STATUS: 'TURNOVER',   AP_TOT: 7,   AP_ACT: 7,   AP_REM: 0,   AP_PRO: 100,  AP_STATUS: 'TURNOVER',      BP_TOT: 8,    BP_ACT: 6,    BP_REM: 2,   BP_PRO: 75,   BP_STATUS: 'TURNOVER',      BSH_TOT: 2,    BSH_ACT: 0,    BSH_REM: 2,    BSH_PRO: 0,    BSH_STATUS: 'TURNOVER',      CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Infra Truck Scale3 Chemical Warehouse 3', },
  { CWA_CODE: '270100',    SYS_OWNER: 'Tank Farm System Owner',        PLAN: '2024-06-19', ACTUAL: null,  A_SYS_QTY: 27,  ASH_TOT: 440,   ASH_ACT: 55,   ASH_REM: 385,   ASH_PRO: 12.5, ASH_STATUS: 'INPROGRESS', AP_TOT: 7,   AP_ACT: 0,   AP_REM: 7,   AP_PRO: 0,    AP_STATUS: 'NOTSTARTED',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 90,   BSH_ACT: 1,    BSH_REM: 89,   BSH_PRO: 1.1,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'Infra Offspec Ethylene Tank Farm', },
  { CWA_CODE: '270300',    SYS_OWNER: 'Tank Farm System Owner',        PLAN: '2024-05-30', ACTUAL: null,  A_SYS_QTY: 40,  ASH_TOT: 2147,  ASH_ACT: 173,  ASH_REM: 1974,  ASH_PRO: 8.1,  ASH_STATUS: 'INPROGRESS', AP_TOT: 36,  AP_ACT: 24,  AP_REM: 12,  AP_PRO: 66.7, AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 849,  BSH_ACT: 14,   BSH_REM: 835,  BSH_PRO: 1.6,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 21,  CSH_ACT: 0,   CSH_REM: 21,  CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Infra Pressurized Tank Farm 2 2', },
  { CWA_CODE: '277000',    SYS_OWNER: 'Tank Farm System Owner',        PLAN: '2024-08-09', ACTUAL: null,  A_SYS_QTY: 8,   ASH_TOT: 173,   ASH_ACT: 22,   ASH_REM: 151,   ASH_PRO: 12.7, ASH_STATUS: 'INPROGRESS', AP_TOT: 1,   AP_ACT: 0,   AP_REM: 1,   AP_PRO: 0,    AP_STATUS: 'NOTSTARTED',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 104,  BSH_ACT: 0,    BSH_REM: 104,  BSH_PRO: 0,    BSH_STATUS: 'NOTSTARTED',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 0,   CSH_ACT: 0,   CSH_REM: 0,   CSH_PRO: 0, CSH_STATUS: 'NOTREGISTERED', CWA_DESC: 'Infra Atmospheric Tank Farm Common Area', },
  { CWA_CODE: '277082',    SYS_OWNER: 'P1 Building System Owner',      PLAN: '2024-03-25', ACTUAL: '2024-03-25', A_SYS_QTY: 28,  ASH_TOT: 528,   ASH_ACT: 496,  ASH_REM: 32,    ASH_PRO: 93.9, ASH_STATUS: 'TURNOVER',   AP_TOT: 52,  AP_ACT: 50,  AP_REM: 2,   AP_PRO: 96.2, AP_STATUS: 'TURNOVER',      BP_TOT: 79,   BP_ACT: 37,   BP_REM: 42,  BP_PRO: 46.8, BP_STATUS: 'TURNOVER',      BSH_TOT: 645,  BSH_ACT: 542,  BSH_REM: 103,  BSH_PRO: 84,   BSH_STATUS: 'TURNOVER',      CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 5,   CSH_ACT: 4,   CSH_REM: 1,   CSH_PRO: 80, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Infra Tank Farm 2nd Substation', },
  { CWA_CODE: '277100',    SYS_OWNER: 'Tank Farm System Owner',        PLAN: '2024-08-09', ACTUAL: null,  A_SYS_QTY: 37,  ASH_TOT: 1141,  ASH_ACT: 78,   ASH_REM: 1063,  ASH_PRO: 6.8,  ASH_STATUS: 'INPROGRESS', AP_TOT: 20,  AP_ACT: 6,   AP_REM: 14,  AP_PRO: 30,   AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 385,  BSH_ACT: 4,    BSH_REM: 381,  BSH_PRO: 1,    BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 14,  CSH_ACT: 0,   CSH_REM: 14,  CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Infra Intermediate Tank Farm 2 2', },
  { CWA_CODE: '280200',    SYS_OWNER: 'Cooling Water System Owner',    PLAN: '2024-04-15', ACTUAL: '2024-04-29', A_SYS_QTY: 25,  ASH_TOT: 1133,  ASH_ACT: 1128, ASH_REM: 5,     ASH_PRO: 99.6, ASH_STATUS: 'TURNOVER',   AP_TOT: 179, AP_ACT: 179, AP_REM: 0,   AP_PRO: 100,  AP_STATUS: 'TURNOVER',      BP_TOT: 274,  BP_ACT: 165,  BP_REM: 109, BP_PRO: 60.2, BP_STATUS: 'TURNOVER',      BSH_TOT: 695,  BSH_ACT: 9,    BSH_REM: 686,  BSH_PRO: 1.3,  BSH_STATUS: 'TURNOVER',      CP_TOT: 4,  CP_ACT: 0,  CP_REM: 4, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 21,  CSH_ACT: 0,   CSH_REM: 21,  CSH_PRO: 0, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Infra Cooling Water System 2', },
  { CWA_CODE: '280292',    SYS_OWNER: 'P3 Building System Owner',      PLAN: '2024-01-07', ACTUAL: '2024-01-07', A_SYS_QTY: 27,  ASH_TOT: 598,   ASH_ACT: 597,  ASH_REM: 1,     ASH_PRO: 99.8, ASH_STATUS: 'TURNOVER',   AP_TOT: 72,  AP_ACT: 72,  AP_REM: 0,   AP_PRO: 100,  AP_STATUS: 'TURNOVER',      BP_TOT: 98,   BP_ACT: 80,   BP_REM: 18,  BP_PRO: 81.6, BP_STATUS: 'TURNOVER',      BSH_TOT: 534,  BSH_ACT: 508,  BSH_REM: 26,   BSH_PRO: 95.1, BSH_STATUS: 'TURNOVER',      CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 5,   CSH_ACT: 5,   CSH_REM: 0,   CSH_PRO: 100, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Infra Cooling Water System2 Substation', },
  { CWA_CODE: '280294',    SYS_OWNER: 'P3 Building System Owner',      PLAN: '2024-02-08', ACTUAL: '2024-02-08', A_SYS_QTY: 20,  ASH_TOT: 572,   ASH_ACT: 568,  ASH_REM: 4,     ASH_PRO: 99.3, ASH_STATUS: 'TURNOVER',   AP_TOT: 46,  AP_ACT: 36,  AP_REM: 10,  AP_PRO: 78.3, AP_STATUS: 'TURNOVER',      BP_TOT: 98,   BP_ACT: 54,   BP_REM: 44,  BP_PRO: 55.1, BP_STATUS: 'TURNOVER',      BSH_TOT: 254,  BSH_ACT: 151,  BSH_REM: 103,  BSH_PRO: 59.4, BSH_STATUS: 'TURNOVER',      CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 1,   CSH_ACT: 0,   CSH_REM: 1,   CSH_PRO: 0, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Infra Operator Shelter', },
  { CWA_CODE: '281000',    SYS_OWNER: 'Water System Owner',            PLAN: '2024-01-15', ACTUAL: '2024-01-15', A_SYS_QTY: 14,  ASH_TOT: 316,   ASH_ACT: 308,  ASH_REM: 8,     ASH_PRO: 97.5, ASH_STATUS: 'TURNOVER',   AP_TOT: 280, AP_ACT: 269, AP_REM: 11,  AP_PRO: 96.1, AP_STATUS: 'TURNOVER',      BP_TOT: 232,  BP_ACT: 179,  BP_REM: 53,  BP_PRO: 77.2, BP_STATUS: 'TURNOVER',      BSH_TOT: 148,  BSH_ACT: 142,  BSH_REM: 6,    BSH_PRO: 95.9, BSH_STATUS: 'TURNOVER',      CP_TOT: 2,  CP_ACT: 0,  CP_REM: 2, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 14,  CSH_ACT: 12,  CSH_REM: 2,   CSH_PRO: 85.7, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Infra Water and Firewater Pump Station 2', },
  { CWA_CODE: '281001',    SYS_OWNER: 'Telecom Overall System Owner',  PLAN: '2024-01-05', ACTUAL: '2024-01-05', A_SYS_QTY: 8,   ASH_TOT: 51,    ASH_ACT: 51,   ASH_REM: 0,     ASH_PRO: 100,  ASH_STATUS: 'TURNOVER',   AP_TOT: 6,   AP_ACT: 6,   AP_REM: 0,   AP_PRO: 100,  AP_STATUS: 'TURNOVER',      BP_TOT: 13,   BP_ACT: 4,    BP_REM: 9,   BP_PRO: 30.8, BP_STATUS: 'TURNOVER',      BSH_TOT: 14,   BSH_ACT: 14,   BSH_REM: 0,    BSH_PRO: 100,  BSH_STATUS: 'TURNOVER',      CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 1,   CSH_ACT: 0,   CSH_REM: 1,   CSH_PRO: 0, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Infra Telecom equipment room Water and Firewater Pump Station2 area', },
  { CWA_CODE: '370100',    SYS_OWNER: 'Tank Farm System Owner',        PLAN: '2024-06-10', ACTUAL: null,  A_SYS_QTY: 17,  ASH_TOT: 235,   ASH_ACT: 19,   ASH_REM: 216,   ASH_PRO: 8.1,  ASH_STATUS: 'INPROGRESS', AP_TOT: 4,   AP_ACT: 0,   AP_REM: 4,   AP_PRO: 0,    AP_STATUS: 'NOTSTARTED',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 74,   BSH_ACT: 3,    BSH_REM: 71,   BSH_PRO: 4.1,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 8,   CSH_ACT: 0,   CSH_REM: 8,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Infra Hexene1 Tank Farm 1', },
  { CWA_CODE: '377100',    SYS_OWNER: 'Tank Farm System Owner',        PLAN: '2024-08-09', ACTUAL: null,  A_SYS_QTY: 30,  ASH_TOT: 677,   ASH_ACT: 15,   ASH_REM: 662,   ASH_PRO: 2.2,  ASH_STATUS: 'INPROGRESS', AP_TOT: 6,   AP_ACT: 2,   AP_REM: 4,   AP_PRO: 33.3, AP_STATUS: 'INPROGRESS',    BP_TOT: 0,    BP_ACT: 0,    BP_REM: 0,   BP_PRO: 0,    BP_STATUS: 'NOTREGISTERED', BSH_TOT: 191,  BSH_ACT: 3,    BSH_REM: 188,  BSH_PRO: 1.6,  BSH_STATUS: 'INPROGRESS',    CP_TOT: 0,  CP_ACT: 0,  CP_REM: 0, CP_PRO: 0,    CP_STATUS: 'NOTREGISTERED', CSH_TOT: 9,   CSH_ACT: 0,   CSH_REM: 9,   CSH_PRO: 0, CSH_STATUS: 'NOTSTARTED', CWA_DESC: 'Infra Intermediate Tank Farm 3 3', },
  { CWA_CODE: '381006',    SYS_OWNER: 'Water System Owner',            PLAN: '2024-01-26', ACTUAL: '2024-01-26', A_SYS_QTY: 11,  ASH_TOT: 169,   ASH_ACT: 156,  ASH_REM: 13,    ASH_PRO: 92.3, ASH_STATUS: 'TURNOVER',   AP_TOT: 33,  AP_ACT: 23,  AP_REM: 10,  AP_PRO: 69.7, AP_STATUS: 'TURNOVER',      BP_TOT: 35,   BP_ACT: 6,    BP_REM: 29,  BP_PRO: 17.1, BP_STATUS: 'TURNOVER',      BSH_TOT: 89,   BSH_ACT: 68,   BSH_REM: 21,   BSH_PRO: 76.4, BSH_STATUS: 'TURNOVER',      CP_TOT: 1,  CP_ACT: 0,  CP_REM: 1, CP_PRO: 0,    CP_STATUS: 'TURNOVER',      CSH_TOT: 12,  CSH_ACT: 11,  CSH_REM: 1,   CSH_PRO: 91.7, CSH_STATUS: 'TURNOVER', CWA_DESC: 'Infra Firewater Pump Station B B', },
  
  ],

  Queries: {

    SQL1: [ // Timeline
      {
        X:                      40,
        Y:                      800,
        LENGTH:                 1200,
        CUTOFF:                 '2023-10-25',
        START:                  '2023-05-01', 
        END:                    '2025-01-01',
        REF_DATE:               'PLAN', // <---
        FONT:                   'roboto',

        MONTH_HEIGHT:           20,
        MONTH_LINE:             15,
        MONTH_STROKE_WIDTH:     0.5,
        MONTH_STROKE_COLOR:     '#BCBCBC',
        MONTH_TEXT_SIZE:        10,
        MONTH_TEXT_COLOR:       '#333',

        YEAR_HEIGHT:            16,
        YEAR_LINE:              20,
        YEAR_STROKE_WIDTH:      0.5,
        YEAR_STROKE_COLOR:      '#BCBCBC',
        YEAR_TEXT_SIZE:         11,
        YEAR_TEXT_COLOR:        '#000',
      }
    ],


    // Skyline
    SQL2: [ 
      {
        TITLE_MAIN:             '6 Digit Code Skyline Chart',
        TITLE_SUB:              'Turnover Status',
        REF_COLUMN:             'CWA_CODE', // <---

        BOX_FONT:               'roboto',
        BOX_HEIGHT:             13,
        BOX_GAP:                2, 
        BOX_RADIUS:             0,
        BOX_TEXT_SIZE:          9,
        BOX_HOVER_SCALE:        1.4,

        // Legends 
        LEGEND_VISIBLE:         'Y',
        LEGEND_X:               45,
        LEGEND_Y:               95,  

        // Search
        SEARCH_X:               45,
        SEARCH_Y:               210.5,
        SEARCH_VISIBLE:         'Y',
        SEARCH_NOTE:            'Enter the KMS/LMS number',
      }
    ],


    // Checksheet
    SQL3: [ 
      {
        SHEET_PREFIX:             'ASH', // <---
        PUNCH_PREFIX:             'AP', // <---
        LEGEND_TITLE:             'A Check Sheets',
        LEGEND_TITLE2:            'A Check Sheets',
        // Package
        PKG_VISIBLE:              'N',
        PKG_REF_COLUMN:           'PACKAGE',
        PKG_NAME:                 ' All / P1 / P2 / P3 ',
        PKG_STATUS:               ' ALL / P1 / P2 / P3 ',

        // CHeck Sheet
        SHEET_TITLE:              ' Total    / Not Started  / In Progress / Completed  / Turn-Over   ',
        SHEET_STATUS:             ' TOTAL    / NOTSTARTED   / INPROGRESS  / COMPLETED  / TURNOVER       ',
        SHEET_TYPE:               '          /              / PRO         /            /             ',
        SHEET_CSTD:               '          /              /             /             ASH_STATUS ',
        SHEET_COLOR:              ' #fff     / #fff         / #FEED57     / #CDDC37    / #83D2F5 ',
        SHEET_FONT_WEIGHT:        ' regular  / regular      / regular     / regular    / regular     ',
        SHEET_FONT_COLOR:         ' #000     / #000         / #000        / #fff       / #000        ',
        SHEET_STROKE_WIDTH:       ' 0.5      / 0.5          / 0.5         / 0.5        / 0.5         ',
        SHEET_STROKE_COLOR:       ' #bcbcbc  / #bcbcbc      / #bcbcbc     / #bcbcbc    / #bcbcbc        ',
        SHEET_OPACITY:            ' 1        / 1            / .75         / 1          / 1           ',

        // Punch
        PUNCH_NAME:                 ' None  / Created / Not Started / Opened / Opened < 10 / Cleared 100% ',
        PUNCH_STATUS:               ' NONE  / CREATE  / NOTSTARTED  / OPENED / REM-10      / CLEARED      ',
      }
    ],


    // Detail infomation Box
    SQL4: [ 
      {
        DETAIL_VISIBLE:           'Y',
        DETAIL_ONOFF:             'ON',
        DETAIL_X:                 45,
        DETAIL_Y:                 300,
        DETAIL_WIDTH:             300,
        DETAIL_TEXT_SIZE:         10,
        DETAIL_LINEHEIGHT:        18,
        
        DETAIL_CODE:              ' CWA_CODE     / PLAN       / ACTUAL      / CWA_DESC  / ASH         / BSH        / CSH        / AP        / BP       / CP       ',
        DETAIL_NAME:              ' 6-Digit-Code:    / Target: / Actual: / Description: / A-Sheet:  / B-Sheet: / C-Sheet: / Punch-A:  / Punch-B: / Punch-C: ',
        DETAIL_TYPE:              ' TEXT     / TEXT             / TEXT             / TRIM         / BAR       / BAR      / BAR      / BAR       / BAR      / BAR      ',
        DETAIL_VALUE_COLOR:       ' #757575  / #757575          / #757575          / #757575      / #83D2F5   / #83D2F5  / #83D2F5  / #F7BACF   / #F7BACF  / #F7BACF  ',
        DETAIL_BAR_COLOR:         ' #44A9DF  / gray             / gray             / gray         / #83D2F5   / #83D2F5  / #83D2F5  / #F7BACF   / #F7BACF  / #F7BACF  ',
        DETAIL_TITLE_WIDTH:       75,
        DETAIL_TITLE_COLOR:       'gray',
      }
    ],




    
  }
}